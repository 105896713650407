import {ENTITY_NAME_PRH_REFEICOES} from './pRHRefeicoes.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonPRHRefeicoes} from './jsonPRHRefeicoes.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_PRH_REFEICOES: IEntityDefinition<IJsonPRHRefeicoes> = {
  name: ENTITY_NAME_PRH_REFEICOES,
  icon: 'fa-question-circle-o',
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  searchPlaceholder: 'prhrefeicoes.pesquisa',
  metadata: {
    keyName: 'codRefeicao',
    descriptionName: 'codRefeicao',
    fields: [
      {name: 'codRefeicao', width: '120px', caption: 'prhrefeicoes.fields.CodRefeicao', placeholder: 'prhrefeicoes.fields.CodRefeicaoPlaceholder'},
      {name: 'codEmp', caption: 'prhrefeicoes.fields.CODEMP', placeholder: 'prhrefeicoes.fields.CODEMPPlaceholder'},
      {name: 'codPRHRelEmentas', caption: 'prhrefeicoes.fields.codPRHRelEmentas', placeholder: 'prhrefeicoes.fields.codPRHRelEmentasPlaceholder'}
    ],
    order: 'codRefeicao',
    searchFields: 'codRefeicao,codEmp,codPRHRelEmentas'
  },
  autocomplete: {
    rowTemplate: '{{codRefeicao}} - {{codEmp}}',
    output: 'codEmp',
    searchFields: 'CodRefeicao,codEmp'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.COLABORADOR],
        pageTitle: 'global.menu.prhrefeicoes'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.COLABORADOR],
        pageTitle: 'global.menu.prhrefeicoes'
      }
    }
  }
};
