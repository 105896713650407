import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {ENTITY_NAME_TAXAS_IVA, IJsonTaxasIva} from '../../taxasIva.entity.interface';
import {minDateCG} from '../../../../../common/utils/utils';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {HttpResponse} from '@angular/common/http';
import {IApiQueryResponse} from '../../../../services/api/api.service.interface';

@Component({
  selector: 'criar-taxaiva-modal',
  templateUrl: './criarTaxaIva.modal.component.html'
})
export class CriarTaxaIvaModalComponent extends CGModalComponent<IJsonTaxasIva> implements OnInit {
  @Input() public codIVA: number;

  public taxaIva: IJsonTaxasIva;
  public disableDataRef: boolean;
  public type: EEntityStateDetailType;
  public taxasIVA: Array<IJsonTaxasIva>;
  public definition: IDevExpressDataGrid;

  private _serviceTaxasIva: IEntityService<IJsonTaxasIva>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this._serviceTaxasIva = this._entityServiceBuilder.build(ENTITY_NAME_TAXAS_IVA);
    this.type = EEntityStateDetailType.NEW;
    this.taxaIva = {
      codIVA: this.codIVA,
      taxa: null,
      dataAltLegal: null,
      idTaxasIVA: null,
      observacao: null
    };
    this.definition = {
      columns: [
        {dataField: 'dataAltLegal', dataType: 'date', caption: 'taxasiva.fields.dataAltLegal'},
        {dataField: 'taxa', dataType: 'string', caption: 'taxasiva.fields.taxa'},
        {dataField: 'observacao', dataType: 'string', caption: 'taxasiva.fields.observacao'}
      ],
      dataSource: new CustomStore({
        load: () => this._getTaxasIVA()
      }),
      hoverStateEnabled: true,
      remoteOperations: false
    };
  }

  public close(): Promise<void> {
    return this._serviceTaxasIva.post({body: this.taxaIva}).then(() => {
      this._plAlertService.success(this._translateService.instant('taxasiva.criada', {taxaIva: this.taxaIva.taxa}));
      super.close(this.taxaIva);
    });
  }

  private _getTaxasIVA(): Promise<Array<IJsonTaxasIva>> {
    return this._serviceTaxasIva.query({pesquisa: `codIVA=${this.codIVA}`}).then((response: HttpResponse<IApiQueryResponse<IJsonTaxasIva>>) => {
      this.taxasIVA = response.body.list;
      if (this.taxasIVA.length === 0) {
        this.disableDataRef = true;
        this.taxaIva.dataAltLegal = minDateCG();
      }
      return this.taxasIVA;
    });
  }
}
