<div class="apura-invent-perma-config-contas-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h5 class="modal-title" [translate]="'apurainventperma.modal.configContas.title'"></h5>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <pl-form>
          <pl-navigator contentMaxHeight="80vh">
            <pl-navigator-item caption="apurainventperma.modal.configContas.mercadorias.title" [order]="1">
              <div *plNavigatorItemContent>
                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.mercadorias.contaDeCompras'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="mercadoriasContaDeCompras"
                      [model]="configModal.mercadorias"
                      (selectedKeyChange)="configModal.mercadorias.nContaComprasTransferenciaParaMercadorias = $event"
                      (evtSelectedDescriptionChanged)="configModal.mercadorias.nomeContaComprasTransferenciaParaMercadorias = $event"
                      [fieldsMap]="{nConta: 'nContaComprasTransferenciaParaMercadorias', nome: 'nomeContaComprasTransferenciaParaMercadorias'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.mercadorias.contaDeCompras'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.mercadorias.contaDeMercadorias'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="mercadoriasContaDeMercadorias"
                      [model]="configModal.mercadorias"
                      (selectedKeyChange)="configModal.mercadorias.nContaMercadoriasTransferenciaCompras = $event"
                      (evtSelectedDescriptionChanged)="configModal.mercadorias.nomeContaMercadoriasTransferenciaCompras = $event"
                      [fieldsMap]="{nConta: 'nContaMercadoriasTransferenciaCompras', nome: 'nomeContaMercadoriasTransferenciaCompras'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.mercadorias.contaDeMercadorias'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.mercadorias.contaDeConsumo'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="mercadoriasContaDeConsumo"
                      [model]="configModal.mercadorias"
                      (selectedKeyChange)="configModal.mercadorias.nContaConsumoMercadorias = $event"
                      (evtSelectedDescriptionChanged)="configModal.mercadorias.nomeContaConsumoMercadorias = $event"
                      [fieldsMap]="{nConta: 'nContaConsumoMercadorias', nome: 'nomeContaConsumoMercadorias'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.mercadorias.contaDeConsumo'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.mercadorias.contaDeTransferencia'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="mercadoriasCcontaDeTransferencia"
                      [model]="configModal.mercadorias"
                      (selectedKeyChange)="configModal.mercadorias.nContaTranferenciaRegularizacaoMercadorias = $event"
                      (evtSelectedDescriptionChanged)="configModal.mercadorias.nomeContaTranferenciaRegularizacaoMercadorias = $event"
                      [fieldsMap]="{nConta: 'nContaTranferenciaRegularizacaoMercadorias', nome: 'nomeContaTranferenciaRegularizacaoMercadorias'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.mercadorias.contaDeTransferencia'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-navigator-item>

            <pl-navigator-item caption="apurainventperma.modal.configContas.materiaPrima.title" [order]="2">
              <div *plNavigatorItemContent>
                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiaPrima.contaComprasTransferenciaParaMateriasPrimas'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiaPrimaContaComprasTransferenciaParaMateriasPrimas"
                      [model]="configModal.materiaPrima"
                      (selectedKeyChange)="configModal.materiaPrima.nContaComprasTransferenciaParaMateriasPrimas = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiaPrima.nomeContaComprasTransferenciaParaMateriasPrimas = $event"
                      [fieldsMap]="{nConta: 'nContaComprasTransferenciaParaMateriasPrimas', nome: 'nomeContaComprasTransferenciaParaMateriasPrimas'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiaPrima.contaComprasTransferenciaParaMateriasPrimas'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiaPrima.contaMateriasPrimasParaTransferenciaCompras'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiaPrimanContaMateriasPrimasParaTransferenciaCompras"
                      [model]="configModal.materiaPrima"
                      (selectedKeyChange)="configModal.materiaPrima.nContaMateriasPrimasParaTransferenciaCompras = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiaPrima.nomeContaMateriasPrimasParaTransferenciaCompras = $event"
                      [fieldsMap]="{nConta: 'nContaMateriasPrimasParaTransferenciaCompras', nome: 'nomeContaMateriasPrimasParaTransferenciaCompras'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiaPrima.contaMateriasPrimasParaTransferenciaCompras'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiaPrima.contaConsumoMateriasPrimas'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiaPrimanContaConsumoMateriasPrimas"
                      [model]="configModal.materiaPrima"
                      (selectedKeyChange)="configModal.materiaPrima.nContaConsumoMateriasPrimas = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiaPrima.nomeContaConsumoMateriasPrimas = $event"
                      [fieldsMap]="{nConta: 'nContaConsumoMateriasPrimas', nome: 'nomeContaConsumoMateriasPrimas'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiaPrima.contaConsumoMateriasPrimas'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiaPrima.contaMateriasPrimasParaRegularizacao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiaPrimanContaMateriasPrimasParaRegularizacao"
                      [model]="configModal.materiaPrima"
                      (selectedKeyChange)="configModal.materiaPrima.nContaMateriasPrimasParaRegularizacao = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiaPrima.nomeContaMateriasPrimasParaRegularizacao = $event"
                      [fieldsMap]="{nConta: 'nContaMateriasPrimasParaRegularizacao', nome: 'nomeContaMateriasPrimasParaRegularizacao'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiaPrima.contaMateriasPrimasParaRegularizacao'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-navigator-item>

            <pl-navigator-item caption="apurainventperma.modal.configContas.materiasSubsidiarias.title" [order]="3">
              <div *plNavigatorItemContent>
                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiasSubsidiarias.contaComprasTransferenciaParaMateriasSubsidiarias'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiasSubsidiariasContaComprasTransferenciaParaMateriasSubsidiarias"
                      [model]="configModal.materiasSubsidiarias"
                      (selectedKeyChange)="configModal.materiasSubsidiarias.nContaComprasTransferenciaParaMateriasSubsidiarias = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiasSubsidiarias.nomeContaComprasTransferenciaParaMateriasSubsidiarias = $event"
                      [fieldsMap]="{nConta: 'nContaComprasTransferenciaParaMateriasSubsidiarias', nome: 'nomeContaComprasTransferenciaParaMateriasSubsidiarias'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiasSubsidiarias.contaComprasTransferenciaParaMateriasSubsidiarias'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiasSubsidiarias.contaMateriasSubsidiariasParaTransferenciaCompras'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiasSubsidiariasContaMateriasSubsidiariasParaTransferenciaCompras"
                      [model]="configModal.materiasSubsidiarias"
                      (selectedKeyChange)="configModal.materiasSubsidiarias.nContaMateriasSubsidiariasParaTransferenciaCompras = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiasSubsidiarias.nomeContaMateriasSubsidiariasParaTransferenciaCompras = $event"
                      [fieldsMap]="{nConta: 'nContaMateriasSubsidiariasParaTransferenciaCompras', nome: 'nomeContaMateriasSubsidiariasParaTransferenciaCompras'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiasSubsidiarias.contaMateriasSubsidiariasParaTransferenciaCompras'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiasSubsidiarias.contaConsumoMateriasSubsidiarias'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiasSubsidiariasContaConsumoMateriasSubsidiarias"
                      [model]="configModal.materiasSubsidiarias"
                      (selectedKeyChange)="configModal.materiasSubsidiarias.nContaConsumoMateriasSubsidiarias = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiasSubsidiarias.nomeContaConsumoMateriasSubsidiarias = $event"
                      [fieldsMap]="{nConta: 'nContaConsumoMateriasSubsidiarias', nome: 'nomeContaConsumoMateriasSubsidiarias'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiasSubsidiarias.contaConsumoMateriasSubsidiarias'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiasSubsidiarias.contaMateriasSubsidiariasParaRegularizacao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiasSubsidiariasContaMateriasSubsidiariasParaRegularizacao"
                      [model]="configModal.materiasSubsidiarias"
                      (selectedKeyChange)="configModal.materiasSubsidiarias.nContaMateriasSubsidiariasParaRegularizacao = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiasSubsidiarias.nomeContaMateriasSubsidiariasParaRegularizacao = $event"
                      [fieldsMap]="{nConta: 'nContaMateriasSubsidiariasParaRegularizacao', nome: 'nomeContaMateriasSubsidiariasParaRegularizacao'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiasSubsidiarias.contaMateriasSubsidiariasParaRegularizacao'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-navigator-item>

            <pl-navigator-item caption="apurainventperma.modal.configContas.embalagens.title" [order]="4">
              <div *plNavigatorItemContent>
                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.embalagens.contaComprasTransferenciaParaMateriaisEmbalagens'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="embalagensnContaComprasTransferenciaParaMateriaisEmbalagens"
                      [model]="configModal.embalagens"
                      (selectedKeyChange)="configModal.embalagens.nContaComprasTransferenciaParaMateriaisEmbalagens = $event"
                      (evtSelectedDescriptionChanged)="configModal.embalagens.nomeContaComprasTransferenciaParaMateriaisEmbalagens = $event"
                      [fieldsMap]="{nConta: 'nContaComprasTransferenciaParaMateriaisEmbalagens', nome: 'nomeContaComprasTransferenciaParaMateriaisEmbalagens'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.embalagens.contaComprasTransferenciaParaMateriaisEmbalagens'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.embalagens.contaMateriaisEmbalagensParaTransferenciaCompras'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="embalagenscontaMateriaisEmbalagensParaTransferenciaCompras"
                      [model]="configModal.embalagens"
                      (selectedKeyChange)="configModal.embalagens.nContaMateriaisEmbalagensParaTransferenciaCompras = $event"
                      (evtSelectedDescriptionChanged)="configModal.embalagens.nomeContaMateriaisEmbalagensParaTransferenciaCompras = $event"
                      [fieldsMap]="{nConta: 'nContaMateriaisEmbalagensParaTransferenciaCompras', nome: 'nomeContaMateriaisEmbalagensParaTransferenciaCompras'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.embalagens.contaMateriaisEmbalagensParaTransferenciaCompras'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.embalagens.contaConsumoEmbalagensParaConsumo'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="embalagensnContaConsumoEmbalagensParaConsumo"
                      [model]="configModal.embalagens"
                      (selectedKeyChange)="configModal.embalagens.nContaConsumoEmbalagensParaConsumo = $event"
                      (evtSelectedDescriptionChanged)="configModal.embalagens.nomeContaConsumoEmbalagensParaConsumo = $event"
                      [fieldsMap]="{nConta: 'nContaConsumoEmbalagensParaConsumo', nome: 'nomeContaConsumoEmbalagensParaConsumo'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.embalagens.contaConsumoEmbalagensParaConsumo'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.embalagens.contaMateriaisEmbalagensParaRegularizacao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="embalagensnContaMateriaisEmbalagensParaRegularizacao"
                      [model]="configModal.embalagens"
                      (selectedKeyChange)="configModal.embalagens.nContaMateriaisEmbalagensParaRegularizacao = $event"
                      (evtSelectedDescriptionChanged)="configModal.embalagens.nomeContaMateriaisEmbalagensParaRegularizacao = $event"
                      [fieldsMap]="{nConta: 'nContaMateriaisEmbalagensParaRegularizacao', nome: 'nomeContaMateriaisEmbalagensParaRegularizacao'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.embalagens.contaMateriaisEmbalagensParaRegularizacao'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-navigator-item>

            <pl-navigator-item caption="apurainventperma.modal.configContas.materiaisDiversos.title" [order]="5">
              <div *plNavigatorItemContent>
                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiaisDiversos.contaComprasTransferenciaParaMateriaisDiversos'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiaisDiversosnContaComprasTransferenciaParaMateriaisDiversos"
                      [model]="configModal.materiaisDiversos"
                      (selectedKeyChange)="configModal.materiaisDiversos.nContaComprasTransferenciaParaMateriaisDiversos = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiaisDiversos.nomeContaComprasTransferenciaParaMateriaisDiversos = $event"
                      [fieldsMap]="{nConta: 'nContaComprasTransferenciaParaMateriaisDiversos', nome: 'nomeContaComprasTransferenciaParaMateriaisDiversos'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiaisDiversos.contaComprasTransferenciaParaMateriaisDiversos'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiaisDiversos.contaMateriaisDiversosParaTransferenciaCompras'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiaisDiversosnContaMateriaisDiversosParaTransferenciaCompras"
                      [model]="configModal.materiaisDiversos"
                      (selectedKeyChange)="configModal.materiaisDiversos.nContaMateriaisDiversosParaTransferenciaCompras = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiaisDiversos.nomeContaMateriaisDiversosParaTransferenciaCompras = $event"
                      [fieldsMap]="{nConta: 'nContaMateriaisDiversosParaTransferenciaCompras', nome: 'nomeContaMateriaisDiversosParaTransferenciaCompras'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiaisDiversos.contaMateriaisDiversosParaTransferenciaCompras'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiaisDiversos.contaConsumoMateriaisDiversosParaConsumo'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiaisDiversosnContaConsumoMateriaisDiversosParaConsumo"
                      [model]="configModal.materiaisDiversos"
                      (selectedKeyChange)="configModal.materiaisDiversos.nContaConsumoMateriaisDiversosParaConsumo = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiaisDiversos.nomeContaConsumoMateriaisDiversosParaConsumo = $event"
                      [fieldsMap]="{nConta: 'nContaConsumoMateriaisDiversosParaConsumo', nome: 'nomeContaConsumoMateriaisDiversosParaConsumo'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiaisDiversos.contaConsumoMateriaisDiversosParaConsumo'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiaisDiversos.contaMateriaisDiversosParaRegularizacao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiaisDiversosnContaMateriaisDiversosParaRegularizacao"
                      [model]="configModal.materiaisDiversos"
                      (selectedKeyChange)="configModal.materiaisDiversos.nContaMateriaisDiversosParaRegularizacao = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiaisDiversos.nomeContaMateriaisDiversosParaRegularizacao = $event"
                      [fieldsMap]="{nConta: 'nContaMateriaisDiversosParaRegularizacao', nome: 'nomeContaMateriaisDiversosParaRegularizacao'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiaisDiversos.contaMateriaisDiversosParaRegularizacao'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-navigator-item>

            <pl-navigator-item caption="apurainventperma.modal.configContas.materiasTransito.title" [order]="6">
              <div *plNavigatorItemContent>
                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiasTransito.contaComprasTransferenciaParaMateriasTransito'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiasTransitonContaComprasTransferenciaParaMateriasTransito"
                      [model]="configModal.materiasTransito"
                      (selectedKeyChange)="configModal.materiasTransito.nContaComprasTransferenciaParaMateriasTransito = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiasTransito.nomeContaComprasTransferenciaParaMateriasTransito = $event"
                      [fieldsMap]="{nConta: 'nContaComprasTransferenciaParaMateriasTransito', nome: 'nomeContaComprasTransferenciaParaMateriasTransito'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiasTransito.contaComprasTransferenciaParaMateriasTransito'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiasTransito.contaMateriasTransitoParaTransferenciaCompras'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiasTransitonContaMateriasTransitoParaTransferenciaCompras"
                      [model]="configModal.materiasTransito"
                      (selectedKeyChange)="configModal.materiasTransito.nContaMateriasTransitoParaTransferenciaCompras = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiasTransito.nomeContaMateriasTransitoParaTransferenciaCompras = $event"
                      [fieldsMap]="{nConta: 'nContaMateriasTransitoParaTransferenciaCompras', nome: 'nomeContaMateriasTransitoParaTransferenciaCompras'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiasTransito.contaMateriasTransitoParaTransferenciaCompras'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiasTransito.contaConsumoMateriasTransitoParaConsumo'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiasTransitonContaConsumoMateriasTransitoParaConsumo"
                      [model]="configModal.materiasTransito"
                      (selectedKeyChange)="configModal.materiasTransito.nContaConsumoMateriasTransitoParaConsumo = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiasTransito.nomeContaConsumoMateriasTransitoParaConsumo = $event"
                      [fieldsMap]="{nConta: 'nContaConsumoMateriasTransitoParaConsumo', nome: 'nomeContaConsumoMateriasTransitoParaConsumo'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiasTransito.contaConsumoMateriasTransitoParaConsumo'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.materiasTransito.contaMateriasTransitoParaRegularizacao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="materiasTransitonContaMateriasTransitoParaRegularizacao"
                      [model]="configModal.materiasTransito"
                      (selectedKeyChange)="configModal.materiasTransito.nContaMateriasTransitoParaRegularizacao = $event"
                      (evtSelectedDescriptionChanged)="configModal.materiasTransito.nomeContaMateriasTransitoParaRegularizacao = $event"
                      [fieldsMap]="{nConta: 'nContaMateriasTransitoParaRegularizacao', nome: 'nomeContaMateriasTransitoParaRegularizacao'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.materiasTransito.contaMateriasTransitoParaRegularizacao'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-navigator-item>

            <pl-navigator-item caption="apurainventperma.modal.configContas.produtosAcabados.title" [order]="7">
              <div *plNavigatorItemContent>
                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.produtosAcabados.contaVariacaoProducaoParaProdutosAcabados'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="produtosAcabadosnContaVariacaoProducaoParaProdutosAcabados"
                      [model]="configModal.produtosAcabados"
                      (selectedKeyChange)="configModal.produtosAcabados.nContaVariacaoProducaoParaProdutosAcabados = $event"
                      (evtSelectedDescriptionChanged)="configModal.produtosAcabados.nomeContaVariacaoProducaoParaProdutosAcabados = $event"
                      [fieldsMap]="{nConta: 'nContaVariacaoProducaoParaProdutosAcabados', nome: 'nomeContaVariacaoProducaoParaProdutosAcabados'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.produtosAcabados.contaVariacaoProducaoParaProdutosAcabados'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.produtosAcabados.contaRegularizacaoExistenciaParaProdutosAcabados'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="produtosAcabadosnContaRegularizacaoExistenciaParaProdutosAcabados"
                      [model]="configModal.produtosAcabados"
                      (selectedKeyChange)="configModal.produtosAcabados.nContaRegularizacaoExistenciaParaProdutosAcabados = $event"
                      (evtSelectedDescriptionChanged)="configModal.produtosAcabados.nomeContaRegularizacaoExistenciaParaProdutosAcabados = $event"
                      [fieldsMap]="{nConta: 'nContaRegularizacaoExistenciaParaProdutosAcabados', nome: 'nomeContaRegularizacaoExistenciaParaProdutosAcabados'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.produtosAcabados.contaRegularizacaoExistenciaParaProdutosAcabados'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-navigator-item>

            <pl-navigator-item caption="apurainventperma.modal.configContas.subProdutos.title" [order]="8">
              <div *plNavigatorItemContent>
                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.subProdutos.contaVariacaoProducaoParaSubprodutos'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="subProdutosnContaVariacaoProducaoParaSubprodutos"
                      [model]="configModal.subProdutos"
                      (selectedKeyChange)="configModal.subProdutos.nContaVariacaoProducaoParaSubprodutos = $event"
                      (evtSelectedDescriptionChanged)="configModal.subProdutos.nomeContaVariacaoProducaoParaSubprodutos = $event"
                      [fieldsMap]="{nConta: 'nContaVariacaoProducaoParaSubprodutos', nome: 'nomeContaVariacaoProducaoParaSubprodutos'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.subProdutos.contaVariacaoProducaoParaSubprodutos'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.subProdutos.contaRegularizacaoExistenciaParaSubprodutos'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="subProdutosnContaRegularizacaoExistenciaParaSubprodutos"
                      [model]="configModal.subProdutos"
                      (selectedKeyChange)="configModal.subProdutos.nContaRegularizacaoExistenciaParaSubprodutos = $event"
                      (evtSelectedDescriptionChanged)="configModal.subProdutos.nomeContaRegularizacaoExistenciaParaSubprodutos = $event"
                      [fieldsMap]="{nConta: 'nContaRegularizacaoExistenciaParaSubprodutos', nome: 'nomeContaRegularizacaoExistenciaParaSubprodutos'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.subProdutos.contaRegularizacaoExistenciaParaSubprodutos'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-navigator-item>

            <pl-navigator-item caption="apurainventperma.modal.configContas.produtosTrabalhosEmCurso.title" [order]="9">
              <div *plNavigatorItemContent>
                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.produtosTrabalhosEmCurso.contaVariacaoProducaoParaProdutosTrabalhosEmCurso'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="produtosTrabalhosEmCursonContaVariacaoProducaoParaProdutosTrabalhosEmCurso"
                      [model]="configModal.produtosTrabalhosEmCurso"
                      (selectedKeyChange)="configModal.produtosTrabalhosEmCurso.nContaVariacaoProducaoParaProdutosTrabalhosEmCurso = $event"
                      (evtSelectedDescriptionChanged)="configModal.produtosTrabalhosEmCurso.nomeContaVariacaoProducaoParaProdutosTrabalhosEmCurso = $event"
                      [fieldsMap]="{nConta: 'nContaVariacaoProducaoParaProdutosTrabalhosEmCurso', nome: 'nomeContaVariacaoProducaoParaProdutosTrabalhosEmCurso'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.produtosTrabalhosEmCurso.contaVariacaoProducaoParaProdutosTrabalhosEmCurso'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.produtosTrabalhosEmCurso.contaRegularizacaoExistenciaParaProdutosTrabalhosEmCurso'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="produtosTrabalhosEmCursonContaRegularizacaoExistenciaParaProdutosTrabalhosEmCurso"
                      [model]="configModal.produtosTrabalhosEmCurso"
                      (selectedKeyChange)="configModal.produtosTrabalhosEmCurso.nContaRegularizacaoExistenciaParaProdutosTrabalhosEmCurso = $event"
                      (evtSelectedDescriptionChanged)="configModal.produtosTrabalhosEmCurso.nomeContaRegularizacaoExistenciaParaProdutosTrabalhosEmCurso = $event"
                      [fieldsMap]="{nConta: 'nContaRegularizacaoExistenciaParaProdutosTrabalhosEmCurso', nome: 'nomeContaRegularizacaoExistenciaParaProdutosTrabalhosEmCurso'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.produtosTrabalhosEmCurso.contaRegularizacaoExistenciaParaProdutosTrabalhosEmCurso'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-navigator-item>

            <pl-navigator-item caption="apurainventperma.modal.configContas.activosBiologicosCompras.title" [order]="10">
              <div *plNavigatorItemContent>
                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.activosBiologicosCompras.contaComprasTransferenciaParaActivosBiologicosConsumo'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="activosBiologicosComprasnContaComprasTransferenciaParaActivosBiologicosConsumo"
                      [model]="configModal.activosBiologicosCompras"
                      (selectedKeyChange)="configModal.activosBiologicosCompras.nContaComprasTransferenciaParaActivosBiologicosConsumo = $event"
                      (evtSelectedDescriptionChanged)="configModal.activosBiologicosCompras.nomeContaComprasTransferenciaParaActivosBiologicosConsumo = $event"
                      [fieldsMap]="{nConta: 'nContaComprasTransferenciaParaActivosBiologicosConsumo', nome: 'nomeContaComprasTransferenciaParaActivosBiologicosConsumo'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.activosBiologicosCompras.contaComprasTransferenciaParaActivosBiologicosConsumo'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.activosBiologicosCompras.contaActivosBiologicosConsumoParaTransferenciaCompras'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="activosBiologicosComprasnContaActivosBiologicosConsumoParaTransferenciaCompras"
                      [model]="configModal.activosBiologicosCompras"
                      (selectedKeyChange)="configModal.activosBiologicosCompras.nContaActivosBiologicosConsumoParaTransferenciaCompras = $event"
                      (evtSelectedDescriptionChanged)="configModal.activosBiologicosCompras.nomeContaActivosBiologicosConsumoParaTransferenciaCompras = $event"
                      [fieldsMap]="{nConta: 'nContaActivosBiologicosConsumoParaTransferenciaCompras', nome: 'nomeContaActivosBiologicosConsumoParaTransferenciaCompras'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.activosBiologicosCompras.contaActivosBiologicosConsumoParaTransferenciaCompras'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.activosBiologicosCompras.contaConsumoActivosBiologicosConsumoParaConsumo'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="activosBiologicosComprasnContaConsumoActivosBiologicosConsumoParaConsumo"
                      [model]="configModal.activosBiologicosCompras"
                      (selectedKeyChange)="configModal.activosBiologicosCompras.nContaConsumoActivosBiologicosConsumoParaConsumo = $event"
                      (evtSelectedDescriptionChanged)="configModal.activosBiologicosCompras.nomeContaConsumoActivosBiologicosConsumoParaConsumo = $event"
                      [fieldsMap]="{nConta: 'nContaConsumoActivosBiologicosConsumoParaConsumo', nome: 'nomeContaConsumoActivosBiologicosConsumoParaConsumo'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.activosBiologicosCompras.contaConsumoActivosBiologicosConsumoParaConsumo'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.activosBiologicosCompras.contaActivosBiologicosConsumoParaRegularizacao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="activosBiologicosComprasnContaActivosBiologicosConsumoParaRegularizacao"
                      [model]="configModal.activosBiologicosCompras"
                      (selectedKeyChange)="configModal.activosBiologicosCompras.nContaActivosBiologicosConsumoParaRegularizacao = $event"
                      (evtSelectedDescriptionChanged)="configModal.activosBiologicosCompras.nomeContaActivosBiologicosConsumoParaRegularizacao = $event"
                      [fieldsMap]="{nConta: 'nContaActivosBiologicosConsumoParaRegularizacao', nome: 'nomeContaActivosBiologicosConsumoParaRegularizacao'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.activosBiologicosCompras.contaActivosBiologicosConsumoParaRegularizacao'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-navigator-item>

            <pl-navigator-item caption="apurainventperma.modal.configContas.activosBiologicosProducao.title" [order]="11">
              <div *plNavigatorItemContent>
                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.activosBiologicosProducao.contaExistenciaActivosBiologicosProducao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="activosBiologicosProducaonContaExistenciaActivosBiologicosProducao"
                      [model]="configModal.activosBiologicosProducao"
                      (selectedKeyChange)="configModal.activosBiologicosProducao.nContaExistenciaActivosBiologicosProducao = $event"
                      (evtSelectedDescriptionChanged)="configModal.activosBiologicosProducao.nomeContaExistenciaActivosBiologicosProducao = $event"
                      [fieldsMap]="{nConta: 'nContaExistenciaActivosBiologicosProducao', nome: 'nomeContaExistenciaActivosBiologicosProducao'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.activosBiologicosProducao.contaExistenciaActivosBiologicosProducao'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.activosBiologicosProducao.contaVariacaoProducaoParaActivosBiologicosProducao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="activosBiologicosProducaonContaVariacaoProducaoParaActivosBiologicosProducao"
                      [model]="configModal.activosBiologicosProducao"
                      (selectedKeyChange)="configModal.activosBiologicosProducao.nContaVariacaoProducaoParaActivosBiologicosProducao = $event"
                      (evtSelectedDescriptionChanged)="configModal.activosBiologicosProducao.nomeContaVariacaoProducaoParaActivosBiologicosProducao = $event"
                      [fieldsMap]="{nConta: 'nContaVariacaoProducaoParaActivosBiologicosProducao', nome: 'nomeContaVariacaoProducaoParaActivosBiologicosProducao'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.activosBiologicosProducao.contaVariacaoProducaoParaActivosBiologicosProducao'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'apurainventperma.modal.configContas.activosBiologicosProducao.contaRegularizacaoExistenciaParaActivosBiologicosProducao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="activosBiologicosProducaonContaRegularizacaoExistenciaParaActivosBiologicosProducao"
                      [model]="configModal.activosBiologicosProducao"
                      (selectedKeyChange)="configModal.activosBiologicosProducao.nContaRegularizacaoExistenciaParaActivosBiologicosProducao = $event"
                      (evtSelectedDescriptionChanged)="configModal.activosBiologicosProducao.nomeContaRegularizacaoExistenciaParaActivosBiologicosProducao = $event"
                      [fieldsMap]="{nConta: 'nContaRegularizacaoExistenciaParaActivosBiologicosProducao', nome: 'nomeContaRegularizacaoExistenciaParaActivosBiologicosProducao'}"
                      [outputKey]="outputKeyPocs"
                      [properties]="{placeholder: 'apurainventperma.modal.configContas.activosBiologicosProducao.contaRegularizacaoExistenciaParaActivosBiologicosProducao'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </pl-navigator-item>
          </pl-navigator>
        </pl-form>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-info action-dismiss" (evtClicked)="reporValoresOriginais()">
      <i class="fa fa-fw fa-undo"></i>&nbsp;<span [translate]="'global.btn.replenishOriginalValues'"></span>
    </pl-button>

    <pl-button klass="btn-success action-dismiss" [onClick]="close" [disabled]="closeDisabled"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
