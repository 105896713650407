<div class="rhmanutencao entity-detail-form" [plPromise]="promise">
  <pl-tabs>
    <pl-tab>
      <div *plTabTitle>
        <span [translate]="'rhmanutencao.steps.cessacaocontrato'"></span>
      </div>

      <div *plTabContent>
        <pl-form>
          <div class="card">
            <div class="card-header">
              <div class="card-title" [translate]="'rhmanutencao.titles.abcessaconttrab'"></div>
            </div>

            <div class="card-body">
              <pl-group>
                <pl-group>
                  <label [translate]="'rhmanutencao.fields.nCodAbonoIndemnizacaoCaducidade'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="abonos"
                      attrName="nCodAbonoIndemnizacaoCaducidade"
                      [model]="cessacaoContrato"
                      (selectedKeyChange)="cessacaoContrato.nCodAbonoIndemnizacaoCaducidade = $event"
                      (evtSelectedDescriptionChanged)="cessacaoContrato.nomeNCodAbonoIndemnizacaoCaducidade = $event"
                      [fieldsMap]="{codAbDesc: 'nCodAbonoIndemnizacaoCaducidade', designacaoBreve: 'nomeNCodAbonoIndemnizacaoCaducidade'}"
                      [rowTemplate]="outputAbdes"
                      [output]="outputAbdes"
                      [filter]="filterCaducidade">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'rhmanutencao.fields.nCodAbonoIndemnizacaoDespedimento'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="abonos"
                      attrName="nCodAbonoIndemnizacaoDespedimento"
                      [model]="cessacaoContrato"
                      (selectedKeyChange)="cessacaoContrato.nCodAbonoIndemnizacaoDespedimento = $event"
                      (evtSelectedDescriptionChanged)="cessacaoContrato.nomeNCodAbonoIndemnizacaoDespedimento = $event"
                      [fieldsMap]="{codAbDesc: 'nCodAbonoIndemnizacaoDespedimento', designacaoBreve: 'nomeNCodAbonoIndemnizacaoDespedimento'}"
                      [rowTemplate]="outputAbdes"
                      [output]="outputAbdes"
                      [filter]="filterDespedimento">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <label [translate]="'rhmanutencao.fields.nCodAbonoIndemnizacaoGlobal'"></label>
                <edit>
                  <entity-autocomplete
                    entity="abonos"
                    attrName="nCodAbonoIndemnizacaoGlobal"
                    [model]="cessacaoContrato"
                    (selectedKeyChange)="cessacaoContrato.nCodAbonoIndemnizacaoGlobal = $event"
                    (evtSelectedDescriptionChanged)="cessacaoContrato.nomeNCodAbonoIndemnizacaoGlobal = $event"
                    [fieldsMap]="{codAbDesc: 'nCodAbonoIndemnizacaoGlobal', designacaoBreve: 'nomeNCodAbonoIndemnizacaoGlobal'}"
                    [rowTemplate]="outputAbdes"
                    [output]="outputAbdes"
                    [filter]="filterGlobal">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </div>
          </div>

          <div class="card mt-4 mb-4">
            <div class="card-header">
              <div class="card-title" [translate]="'rhmanutencao.titles.abpropanoatual'"></div>
            </div>

            <div class="card-body">
              <pl-group>
                <label [translate]="'rhmanutencao.fields.nCodAbonoSubFeriasProp'"></label>
                <edit>
                  <entity-autocomplete
                    entity="abonos"
                    attrName="nCodAbonoSubFeriasProp"
                    [model]="cessacaoContrato"
                    (selectedKeyChange)="cessacaoContrato.nCodAbonoSubFeriasProp = $event"
                    (evtSelectedDescriptionChanged)="cessacaoContrato.nomeNCodAbonoSubFeriasProp = $event"
                    [fieldsMap]="{codAbDesc: 'nCodAbonoSubFeriasProp', designacaoBreve: 'nomeNCodAbonoSubFeriasProp'}"
                    [rowTemplate]="outputAbdes"
                    [output]="outputAbdes"
                    [filter]="filterSubFerias">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'rhmanutencao.fields.nCodAbonoFeriasProp'"></label>
                <edit>
                  <entity-autocomplete
                    entity="abonos"
                    attrName="nCodAbonoFeriasProp"
                    [model]="cessacaoContrato"
                    (selectedKeyChange)="cessacaoContrato.nCodAbonoFeriasProp = $event"
                    (evtSelectedDescriptionChanged)="cessacaoContrato.nomeNCodAbonoFeriasProp = $event"
                    [fieldsMap]="{codAbDesc: 'nCodAbonoFeriasProp', designacaoBreve: 'nomeNCodAbonoFeriasProp'}"
                    [rowTemplate]="outputAbdes"
                    [output]="outputAbdes"
                    [filter]="filterFerias">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </div>
          </div>

          <pl-group>
            <pl-group>
              <label [translate]="'rhmanutencao.fields.situacaoCessacao'"></label>
              <edit>
                <entity-autocomplete
                  entity="situacaoempregado"
                  attrName="situacaoCessacao"
                  [model]="cessacaoContrato"
                  (selectedKeyChange)="cessacaoContrato.situacaoCessacao = $event"
                  (evtSelectedDescriptionChanged)="cessacaoContrato.nomeSituacaoCessacao = $event"
                  [fieldsMap]="{nSitEmpreg: 'situacaoCessacao', designaBreve: 'nomeSituacaoCessacao'}">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'rhmanutencao.fields.motivoCessacao'"></label>
              <edit>
                <entity-autocomplete
                  entity="tiposregistocadastro"
                  attrName="motivoCessacao"
                  [model]="cessacaoContrato"
                  (selectedKeyChange)="cessacaoContrato.motivoCessacao = $event"
                  (evtSelectedDescriptionChanged)="cessacaoContrato.nomeMotivoCessacao = $event"
                  [fieldsMap]="{nMotivoMov: 'motivoCessacao', designaBreve: 'nomeMotivoCessacao'}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
        </pl-form>
      </div>
    </pl-tab>
    <pl-tab>
      <div *plTabTitle>
        <span [translate]="'rhmanutencao.tabs.vencimentos'"></span>
      </div>
      <div *plTabContent>
        <div class="card">
          <h6 class="card-header p-1" [translate]="'rhmanutencao.rmmg.title'"></h6>
          <div class="card-body">
            <span [translate]="'rhmanutencao.rmmg.info'"></span>
            <div class="entity-detail-form">
              <pl-form [properties]="{orientation: 'horizontal'}">
                <pl-group>
                  <pl-group>
                    <label [translate]="'rhmanutencao.rmmg.fields.dataAtualizacao'"></label>
                    <edit>
                      <pl-edit-datepicker attrName="dataAtualizacao" [model]="configRMMG.dataAtualizacao" [properties]="{readonly: true}"></pl-edit-datepicker>
                    </edit>
                  </pl-group>
                  <pl-group></pl-group>
                  <pl-group></pl-group>
                </pl-group>
                <pl-group>
                  <pl-edit type="checkbox" attrName="funcionarios" [model]="true" [properties]="{label: 'Funcionarios'}"></pl-edit>
                  <pl-edit
                    type="checkbox"
                    attrName="gerente"
                    [model]="configRMMG.gerenteSelecionado"
                    (modelChange)="onGerentesSelecionadoChanged($event)"
                    [properties]="{label: 'rhmanutencao.rmmg.fields.gerente'}"
                    plAutoFocus></pl-edit>
                </pl-group>
                <pl-tooltip [config]="{text: tooltipProcessarMsg, disabled: !processarRMMGDisabled}">
                  <button *plTooltipContent type="button" class="btn btn-success" [click]="fnProcessRMMG" [disabled]="processarRMMGDisabled" plPromise>
                    <i class="fa fa-fw fa-gears"></i>&nbsp;<span [translate]="'global.btn.process'"></span>
                  </button>
                </pl-tooltip>
              </pl-form>
            </div>
          </div>
        </div>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
