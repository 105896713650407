<div class="pl-cookies-consent" [class.pl-cookies-consent-open]="detailsOpen">
  <div class="pl-cookies-consent-header">
    <h4 [plTranslate]="locale.header"></h4>
    <div class="pl-cookies-consent-header-content">
      <ng-template *ngIf="hideHeader !== true" [ngTemplateOutlet]="templateHeader?.templateRef"></ng-template>
    </div>
  </div>

  <div class="pl-cookies-consent-global-consent">
    <div class="pl-cookies-consent-global-ok">
      <button type="button" class="btn btn-sm btn-success" plPromise [plTranslate]="locale.ok" [click]="consent"></button>
    </div>

    <div class="pl-cookies-consent-global-items-wrapper">
      <div class="pl-cookies-consent-global-items">
        <div *ngIf="hideNecessary !== true" class="pl-cookies-consent-global-item">
          <pl-edit-checkbox [model]="true" [label]="captionNecessary" [properties]="propertiesNecessary"></pl-edit-checkbox>
        </div>

        <div *ngIf="hidePreferences !== true" class="pl-cookies-consent-global-item">
          <pl-edit-checkbox [model]="cookies.preferences.accepted" [label]="captionPreferences" (modelChange)="setAcceptedPreferences($event)"></pl-edit-checkbox>
        </div>

        <div *ngIf="hideStatistics !== true" class="pl-cookies-consent-global-item">
          <pl-edit-checkbox [model]="cookies.statistics.accepted" [label]="captionStatistics" (modelChange)="setAcceptedStatistics($event)"></pl-edit-checkbox>
        </div>

        <div *ngIf="hideMarketing !== true" class="pl-cookies-consent-global-item">
          <pl-edit-checkbox [model]="cookies.marketing.accepted" [label]="captionMarketing" (modelChange)="setAcceptedMarketing($event)"></pl-edit-checkbox>
        </div>
      </div>

      <div *ngIf="hideDetails !== true" class="pl-cookies-consent-global-toggle-details">
        <button type="button" class="btn btn-light" (click)="toggleDetails()">
          <ng-container [ngSwitch]="detailsOpen">
            <span *ngSwitchCase="false" [plTranslate]="locale.showDetails"></span>
            <span *ngSwitchCase="true" [plTranslate]="locale.hideDetails"></span>
          </ng-container>
          <i class="fa fa-fw fa-angle-down" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </div>

  <pl-animation-collapse [collapsed]="!detailsOpen" [animationDuration]="animationDuration">
    <div class="pl-cookies-consent-details">
      <pl-tabs>
        <pl-tab id="consent-groups" [caption]="captionDeclaration">
          <div *plTabContent>
            <pl-navpill>
              <pl-navpill-panel *ngIf="hideNecessary !== true" id="necessary">
                <div *plNavPillPanelTitle>{{ captionNecessary }} ({{ cookies.necessary.items.length }})</div>
                <div *plNavPillPanelContent>
                  <div class="pl-cookies-consent-details-group-summary" [plTranslate]="locale.necessaryDescription"></div>
                  <ng-container *ngTemplateOutlet="templateGroupCookies; context: {cookies: cookies.necessary.items}"></ng-container>
                </div>
              </pl-navpill-panel>

              <pl-navpill-panel *ngIf="hidePreferences !== true" id="preferences">
                <div *plNavPillPanelTitle>{{ captionPreferences }} ({{ cookies.preferences.items.length }})</div>
                <div *plNavPillPanelContent>
                  <div class="pl-cookies-consent-details-group-summary" [plTranslate]="locale.preferencesDescription"></div>
                  <ng-container *ngTemplateOutlet="templateGroupCookies; context: {cookies: cookies.preferences.items}"></ng-container>
                </div>
              </pl-navpill-panel>

              <pl-navpill-panel *ngIf="hideStatistics !== true" id="statistics">
                <div *plNavPillPanelTitle>{{ captionStatistics }} ({{ cookies.statistics.items.length }})</div>
                <div *plNavPillPanelContent>
                  <div class="pl-cookies-consent-details-group-summary" [plTranslate]="locale.statisticsDescription"></div>
                  <ng-container *ngTemplateOutlet="templateGroupCookies; context: {cookies: cookies.statistics.items}"></ng-container>
                </div>
              </pl-navpill-panel>

              <pl-navpill-panel *ngIf="hideMarketing !== true" id="marketing">
                <div *plNavPillPanelTitle>{{ captionMarketing }} ({{ cookies.marketing.items.length }})</div>
                <div *plNavPillPanelContent>
                  <div class="pl-cookies-consent-details-group-summary" [plTranslate]="locale.marketingDescription"></div>
                  <ng-container *ngTemplateOutlet="templateGroupCookies; context: {cookies: cookies.marketing.items}"></ng-container>
                </div>
              </pl-navpill-panel>

              <pl-navpill-panel *ngIf="hideUnclassified !== true" id="unclassified">
                <div *plNavPillPanelTitle>{{ captionUnclassified }} ({{ cookies.unclassified.items.length }})</div>
                <div *plNavPillPanelContent>
                  <div class="pl-cookies-consent-details-group-summary" [plTranslate]="locale.unclassifiedDescription"></div>
                  <ng-container *ngTemplateOutlet="templateGroupCookies; context: {cookies: cookies.unclassified.items}"></ng-container>
                </div>
              </pl-navpill-panel>
            </pl-navpill>
          </div>
        </pl-tab>

        <pl-tab id="about" [caption]="captionAbout">
          <div *plTabContent>
            <ng-container *ngTemplateOutlet="templateAbout?.templateRef"></ng-container>
          </div>
        </pl-tab>
      </pl-tabs>

      <div *ngIf="hideFooter !== true && !!captionFooter" class="pl-cookies-consent-details-last-updated">
        <hr />
        <div>{{ captionFooter }}</div>
      </div>
    </div>
  </pl-animation-collapse>
</div>

<ng-template #templateGroupCookies let-cookies="cookies">
  <ng-container [ngSwitch]="!!cookies.length">
    <div *ngSwitchCase="false">
      <hr />
      <div [plTranslate]="locale.emptyGroup"></div>
    </div>

    <div *ngSwitchCase="true" class="table-responsive">
      <table class="table table-sm table-cookies-consent">
        <thead class="thead-light">
          <tr>
            <th [plTranslate]="locale.tableHeaderName"></th>
            <th [plTranslate]="locale.tableHeaderProvider"></th>
            <th [plTranslate]="locale.tableHeaderPurpose"></th>
            <th [plTranslate]="locale.tableHeaderExpiresOn"></th>
            <th [plTranslate]="locale.tableHeaderType"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cookie of cookies">
            <td>{{ cookie.name }}</td>
            <td>{{ cookie.provider }}</td>
            <td [ngSwitch]="!!cookie.purposeHyperlink">
              <div *ngSwitchDefault [plTranslate]="cookie.purpose"></div>
              <div *ngSwitchCase="true">
                <a rel="noopener noreferrer" [attr.href]="cookie.purposeHyperlink" [plTranslate]="cookie.purpose"></a>
              </div>
            </td>
            <td [ngSwitch]="cookie.expires">
              <div *ngSwitchDefault [plTranslate]="locale.persistent"></div>
              <!-- eslint-disable @angular-eslint/template/no-call-expression -->
              <div *ngSwitchCase="true">{{ prettyExpireOn(cookie.expireTime) }}</div>
            </td>
            <td>{{ cookie.type }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</ng-template>
