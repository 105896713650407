<div class="pl-scheduler">
  <div *ngIf="showTitle && (templateTitle || data?.title)" class="pl-scheduler-title">
    <ng-container *ngTemplateOutlet="templateTitle || templateTitleDefault"></ng-container>
  </div>

  <div class="pl-scheduler-content">
    <div class="pl-scheduler-header">
      <div class="pl-scheduler-header-label">
        <ng-container *ngIf="showLabel">
          <ng-container *ngTemplateOutlet="templateLabel || templateLabelDefault"></ng-container>
        </ng-container>
      </div>

      <ng-container *ngFor="let dataset of data?.datasets; index as datasetIndex; count as total; first as isFirst; last as isLast; even as isEven; odd as isOdd">
        <div class="pl-scheduler-header-item" [class.pl-scheduler-header-item-highlight]="dataset.highlight">
          <ng-container
            *ngTemplateOutlet="
              templateDataset || templateDatasetDefault;
              context: {
                $implicit: dataset,
                item: dataset,
                index: datasetIndex,
                count: total,
                first: isFirst,
                last: isLast,
                even: isEven,
                odd: isOdd
              }
            "></ng-container>
        </div>

        <div
          *ngIf="detailActive && datasetDetailActive && datasetIndex === selectedRange.datasetEnd && selectedRange.datasetItemEnd > -1"
          class="pl-scheduler-header-item pl-scheduler-header-item-detail"
          [style.height.px]="schedulerHeaderItemDetailHeight">
          <ng-container
            *ngTemplateOutlet="
              templateDatasetHeaderDetail;
              context: {
                $implicit: dataset,
                dataset: dataset
              }
            "></ng-container>
        </div>
      </ng-container>
    </div>

    <div class="pl-scheduler-datasets" [class.pl-scheduler-datasets-range-select]="rangeSelect">
      <div class="pl-scheduler-dataset-row pl-scheduler-datasets-header">
        <div
          *ngFor="let header of data?.header; index as headerIndex; count as total; first as isFirst; last as isLast; even as isEven; odd as isOdd"
          class="pl-scheduler-dataset-item"
          [ngClass]="header.cssClass">
          <ng-container
            *ngTemplateOutlet="
              templateHeader || templateHeaderDefault;
              context: {
                $implicit: header,
                item: header,
                index: headerIndex,
                count: total,
                first: isFirst,
                last: isLast,
                even: isEven,
                odd: isOdd
              }
            "></ng-container>
          <div class="pl-scheduler-dataset-item-styler"></div>
        </div>
      </div>

      <ng-container *ngFor="let dataset of data?.datasets; index as datasetIndex">
        <div class="pl-scheduler-dataset-row pl-scheduler-dataset">
          <div
            *ngFor="let datasetItem of dataset.items; index as datasetItemIndex; count as total; first as isFirst; last as isLast; even as isEven; odd as isOdd"
            class="pl-scheduler-dataset-item"
            [ngClass]="datasetItem.cssClass"
            [class.pl-scheduler-dataset-item-selectable]="datasetItem.selectable"
            [class.pl-scheduler-dataset-item-active]="datasetItem.active"
            [class.pl-scheduler-dataset-item-select-start]="detailActive && datasetDetailActive && datasetIndex === selectedRange.datasetStart && datasetItemIndex === selectedRange.datasetItemStart"
            [class.pl-scheduler-dataset-item-select-include]="
              detailActive &&
              datasetDetailActive &&
              datasetIndex >= selectedRange.datasetStart &&
              datasetIndex <= selectedRange.datasetEnd &&
              (datasetIndex !== selectedRange.datasetStart || datasetItemIndex > selectedRange.datasetItemStart) &&
              (datasetIndex !== selectedRange.datasetEnd || datasetItemIndex < selectedRange.datasetItemEnd)
            "
            [class.pl-scheduler-dataset-item-select-end]="detailActive && datasetDetailActive && datasetIndex === selectedRange.datasetEnd && datasetItemIndex === selectedRange.datasetItemEnd"
            [class.pl-scheduler-dataset-item-detail-active]="
              detailActive &&
              datasetDetailActive &&
              datasetIndex >= selectedRange.datasetStart &&
              datasetIndex <= selectedRange.datasetEnd &&
              (datasetIndex !== selectedRange.datasetStart || datasetItemIndex >= selectedRange.datasetItemStart) &&
              (datasetIndex !== selectedRange.datasetEnd || datasetItemIndex <= selectedRange.datasetItemEnd)
            "
            (mousedown)="$event.stopPropagation(); onCellMousedown(dataset, datasetItem, datasetIndex, datasetItemIndex)"
            (mouseenter)="$event.stopPropagation(); onCellMouseEnter(dataset, datasetItem, datasetIndex, datasetItemIndex)"
            (mouseup)="$event.stopPropagation(); onCellMouseup()"
            (click)="cellClick(dataset, datasetItem, datasetIndex, datasetItemIndex)">
            <ng-container
              *ngTemplateOutlet="
                templateDatasetItem || templateDatasetItemDefault;
                context: {
                  $implicit: datasetItem,
                  item: datasetItem,
                  dataset: dataset,
                  index: datasetItemIndex,
                  count: total,
                  first: isFirst,
                  last: isLast,
                  even: isEven,
                  odd: isOdd
                }
              "></ng-container>
            <div class="pl-scheduler-dataset-item-styler"></div>
          </div>
        </div>

        <div
          *ngIf="detailActive && datasetDetailActive && datasetIndex === selectedRange.datasetEnd && selectedRange.datasetItemEnd > -1"
          #schedulerDatasetDetail
          class="pl-scheduler-dataset-row pl-scheduler-dataset-detail">
          <ng-container
            *ngTemplateOutlet="
              templateDatasetDetail || templateDatasetDetailDefault;
              context: {
                datasets: activeDatasets,
                dataset: activeDatasets[0],
                datasetsItems: activeDatasetsItems,
                datasetItem: activeDatasetsItems[0]
              }
            "></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #templateTitleDefault>{{ data?.title }}</ng-template>

<ng-template #templateLabelDefault>{{ data?.label }}</ng-template>

<ng-template #templateHeaderDefault let-header="item" let-index="index" let-count="count" let-first="first" let-last="last" let-even="even" let-odd="odd">
  <span>{{ header.label }}</span>
</ng-template>

<ng-template #templateDatasetDefault let-dataset="item" let-index="index" let-count="count" let-first="first" let-last="last" let-even="even" let-odd="odd">
  <span>{{ dataset.label }}</span>
</ng-template>

<ng-template #templateDatasetItemDefault let-datasetItem="item" let-dataset="dataset" let-index="index" let-count="count" let-first="first" let-last="last" let-even="even" let-odd="odd">
  <span>{{ datasetItem.label }}</span>
</ng-template>

<ng-template #templateDatasetDetailDefault let-datasets="datasets" let-dataset="dataset" let-datasetsItems="datasetsItems" let-datasetItem="datasetItem">
  <span>{{ dataset.label }}</span>
</ng-template>
