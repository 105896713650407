import {TDate} from '../../../common/dates';

export interface IJsonTaxasIva {
  idTaxasIVA: number;
  codIVA: number;
  taxa: number;
  dataAltLegal: TDate;
  observacao: number;
}

export const ENTITY_NAME_TAXAS_IVA = 'taxasiva';
