import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_ATIVOS_VENDA} from './ativosVenda.module.interface';
import {AtivosVendaModuleComponent} from './components/ativosVenda.module.component';
import {AtivosVendaService} from './ativosVenda.module.service';
import {HttpResponse} from '@angular/common/http';
import {IJsonAtivoVenda} from './jsonAtivosVenda.module.interface';

export const MODULE_ATIVOS_VENDA: IModuleDefinition = {
  name: MODULE_NAME_ATIVOS_VENDA,
  state: {
    url: `/${MODULE_NAME_ATIVOS_VENDA}`,
    component: AtivosVendaModuleComponent,
    data: {
      roles: [ROLE.ATIVOS],
      pageTitle: 'global.menu.ativosvenda',
      icon: 'fa-pencil'
    },
    resolve: [
      {
        provide: 'ativosporvender',
        deps: [AtivosVendaService],
        useFactory: (ativosVendaService: AtivosVendaService): Promise<Array<IJsonAtivoVenda>> => {
          return ativosVendaService.getAtivosPorVender().then((response: HttpResponse<Array<IJsonAtivoVenda>>) => response.body);
        }
      }
    ],
    params: {
      isfromativos: {
        type: 'bool',
        value: false
      }
    }
  }
};
