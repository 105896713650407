import {merge} from 'lodash-es';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {copy, IPlTooltipConfig, KEYCODES, PlAlertService, PlI18nService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {DocContabilidadeService} from '../../docContabilidade.service';
import {focusElement} from '../../../../../../../../common/utils/element.utils';
import {ICGConfigContabilidade} from '../../../../../../../services/config/config.service.interface';
import {
  EDocContabilidadeRetencaoDispoRendimento,
  IDocContabilidade,
  IDocContabilidadeLinha,
  IDocContabilidadeRetencao,
  RADIO_GROUP_RETENCAO_DISPONIBILIDADE_RENDIMENTO
} from '../../../../docsContabilidade.interface';
import {round} from '../../../../../../../../common/utils/utils';
import {ERendiTipoDeclaracao} from '../../../../../../../entities/rendis/jsonRendi.entity.interface';
import {IRadioGroup} from '../../../../../../../../common/interfaces/interfaces';
import {EClassificacaoControlo} from '../../../../../../../../common/enums/contabilidade.enums';
import {EMPTY_GUID} from '../../../../../../../../config/constants';

@Component({
  selector: 'docscontabilidade-edit-retencao-modal',
  templateUrl: './docsContabilidade.edit.retencao.modal.component.html'
})
export class DocsContabilidadeEditRetencaoModalComponent extends CGModalComponent<IDocContabilidadeRetencao> implements OnInit {
  @Input() public linha: IDocContabilidadeLinha;
  @Input() public doc: IDocContabilidade;

  public readonly tiposDeclaracoes: typeof ERendiTipoDeclaracao;
  public readonly radioGroupDisponibilidadeRendimento: IRadioGroup<EDocContabilidadeRetencaoDispoRendimento>;
  public form: UntypedFormGroup;
  public model: IDocContabilidadeRetencao;
  public tooltipRetencaoDeduzida: IPlTooltipConfig;

  private readonly _config: ICGConfigContabilidade;

  constructor(
    protected readonly _injector: Injector,
    private readonly _docContabilidadeService: DocContabilidadeService,
    private readonly _plI18nService: PlI18nService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.tiposDeclaracoes = ERendiTipoDeclaracao;
    this.radioGroupDisponibilidadeRendimento = copy(RADIO_GROUP_RETENCAO_DISPONIBILIDADE_RENDIMENTO);
    this.radioGroupDisponibilidadeRendimento.events = {
      beforeChange: (newValue: EDocContabilidadeRetencaoDispoRendimento): Promise<void> => this._validateDispoRendimento(newValue)
    };
    this._config = merge({diferencaIva: 1, moeda: '€'}, this._configService.configurations.contabilidade);
  }

  public ngOnInit(): void {
    if (!this.linha.entNIF.txRet.taxaRet) {
      this.linha.entNIF.txRet.taxaRet = 0;
    }
    this.model = {
      valorTributavel: this.linha.valor,
      valorRetido: round((this.linha.valor * this.linha.entNIF.txRet.taxaRet) / 100, this._config.decimais.valor),
      dispoRendimento: EDocContabilidadeRetencaoDispoRendimento.Imediata,
      contaCorrenteTemRetencaoDeduzida: true,
      numeroGuiaPagamento: ''
    };
    this.tooltipRetencaoDeduzida = {
      text: 'docscontabilidade.retencao.disabledRetencaoDeduzida',
      disabled: false,
      placement: 'right'
    };
    for (const linha of this.doc.linhas) {
      if (linha.poc?.cc) {
        this.tooltipRetencaoDeduzida.disabled = true;
        break;
      }
    }

    setTimeout(() => {
      const element = this._element.querySelector<HTMLElement>('input[name="valorTributavel"]');
      focusElement(element);
    });
  }

  public close(): void {
    if (this.form.valid) {
      super.close(this.model);
    }
  }

  public getTitle(): string {
    return this._plI18nService.formatPercent(this.linha.entNIF.txRet.taxaRet);
  }

  public valorTributavelChanged(value: number = 0): void {
    this.model.valorTributavel = value || 0;
    this.model.valorRetido = round((this.model.valorTributavel * this.linha.entNIF.txRet.taxaRet) / 100, this._config.decimais.valor);
  }

  public valorRetidoChanged(value: number = 0): void {
    this.model.valorRetido = value || 0;
    if (this.linha.entNIF.txRet.taxaRet) {
      this.model.valorTributavel = round((this.model.valorRetido * 100) / this.linha.entNIF.txRet.taxaRet, this._config.decimais.valor);
    }
  }

  public changeValorTaxa(event?: KeyboardEvent): Promise<void> {
    if (event && event.type === 'keydown' && event.key !== KEYCODES.ENTER) {
      return Promise.resolve();
    }
    if (event) {
      // Prevent plFormNavigation from messing up UX
      event.preventDefault();
      event.stopPropagation();
    }
    return new Promise<void>((resolve) => {
      this._docContabilidadeService
        .editValorModal(this.model, 'valorRetido', 'docscontabilidade.montanteRetido')
        .then((response: IDocContabilidadeRetencao) => {
          this.model = response;
          focusElement(<HTMLElement>event.target);
        })
        .finally(resolve);
    });
  }

  private _validateDispoRendimento(value: EDocContabilidadeRetencaoDispoRendimento): Promise<void> {
    let temContaCorrente = false;
    for (const linha of this.doc.linhas) {
      if (
        linha.nContrib === this.doc.nContribuinte &&
        linha.classificControlo !== EClassificacaoControlo.BaseTributavel &&
        linha.poc.cc &&
        (linha.nLanc === linha.nLancImput || linha.nLancImput === EMPTY_GUID)
      ) {
        temContaCorrente = true;
        break;
      }
    }
    if (value === EDocContabilidadeRetencaoDispoRendimento.NoPagamento && !temContaCorrente) {
      this._plAlertService.error('docscontabilidade.retencao.messages.needCCBefore');
      return Promise.reject();
    }
    return Promise.resolve();
  }
}
