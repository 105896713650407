export class NoAuthorityError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'NoAuthorityError';
  }
}

export class BlockedPluginError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'BlockedPluginError';
  }
}
