import {Component, Injector} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {BancosExtratoService} from '../../service/bancosExtrato.module.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {
  EConciliacaoBancariaBancosTreeViewerNodeType,
  IConciliacaoBancariaBancosTreeViewerItemData
} from '../../../../components/conciliacaobancaria/bancostreeviewer/conciliacaobancaria.bancos.treeviewer.component.interface';
import {IBancosExtratoGetTransactionsByConciliacaoBancariaResult} from '../../bancosExtrato.module.interface';
import {IConciliacaoBancariaBancoCab, MODULE_NAME_CONCILIACAO_BANCARIA} from '../../../portalcontabilidade/conciliacaobancaria/conciliacaoBancaria.module.interface';
import {IJsonBancosExtratoGetTransactionsByConciliacaoBancariaResult} from '../../jsonBancosExtrato.module.interface';
import {ITreeItem} from '../../../../components/treeviewer/treeviewer.interface';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';

@Component({
  selector: 'bancos-extrato-module-import-by-reconciliation-modal',
  templateUrl: './bancosExtrato.importbyreconciliation.modal.component.html'
})
export class BancosExtratoModuleImportByReconciliationModalComponent extends CGModalComponent<IBancosExtratoGetTransactionsByConciliacaoBancariaResult> {
  public selectedBanco: IConciliacaoBancariaBancoCab;
  public promise: Promise<void>;

  private _maintenanceInstanceConciliacaoBancaria: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _bancosExtratoService: BancosExtratoService
  ) {
    super(_injector);
  }

  public close(): Promise<void> {
    if (this.promise) {
      return this.promise;
    }
    if (!this.selectedBanco) {
      return Promise.resolve();
    }
    this.disableClose();
    this.promise = this._bancosExtratoService
      .getTransactionsByConciliacaoBancaria(this.selectedBanco.concilBancoCabID)
      .then((response: HttpResponse<IJsonBancosExtratoGetTransactionsByConciliacaoBancariaResult>) => {
        this.enableClose();
        super.close({
          ...response.body,
          banco: this.selectedBanco
        });
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  public changedSelectedNode(node: ITreeItem<IConciliacaoBancariaBancosTreeViewerItemData>): void {
    this.selectedBanco = node?.nodeValue.nodeType === EConciliacaoBancariaBancosTreeViewerNodeType.Item ? node.nodeValue : undefined;
  }

  public readonly fnMaintenanceConciliacaoBancaria = (): Promise<void> => this._maintenanceConciliacaoBancaria();

  private _maintenanceConciliacaoBancaria(): Promise<void> {
    if (!this._maintenanceInstanceConciliacaoBancaria) {
      this._maintenanceInstanceConciliacaoBancaria = this._moduleMaintenanceService.build(MODULE_NAME_CONCILIACAO_BANCARIA);
    }
    return this._maintenanceInstanceConciliacaoBancaria.maintenance();
  }
}
