import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_RESIDENCIA_FISCAL} from './residenciaFiscal.datasource.interface';
import {EResidenciaFiscal} from '../../../modules/portalrh/dgempsfull/jsonDGEMPFull.interface';
import {IDataSource} from '../../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_RESIDENCIA_FISCAL: IDataSource<EResidenciaFiscal> = deepFreeze({
  name: DATA_SOURCE_NAME_RESIDENCIA_FISCAL,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EResidenciaFiscal.ResidePT, name: 'dgemps.taxResidence.0', label: 'dgemps.taxResidence.0'},
    {value: EResidenciaFiscal.NaoResidePTAuferemEntidadeUnica, name: 'dgemps.taxResidence.1', label: 'dgemps.taxResidence.1'},
    {value: EResidenciaFiscal.NaoResidePTAuferemMaisQueUmaEntidade, name: 'dgemps.taxResidence.2', label: 'dgemps.taxResidence.2'}
  ]
});
