import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {IPlToolbarItem} from 'pl-comps-angular';
import {hasAuthority} from '../../../../../common/utils/roles.utils';
import {IDevExpressDataGridEventOnCellClick} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {ROLE} from '../../../../services/role.const';
import {MODULE_NAME_GESTAO_FICHEIROS_SEPA} from '../../../../modules/gestaoficheirossepa/gestaoFicheirosSepa.module.interface';
import {IJsonPagamento, IJsonPagamentoCab} from '../../jsonPagamento.entity.interface';
import {IPagamentoCabWithData} from '../../pagamentos.entity.interface';

@Component({
  selector: 'pagamentos-list',
  templateUrl: './pagamentos.entity.list.component.html'
})
export class PagamentosListComponent extends ModuloEntityListComponent<IJsonPagamentoCab> implements OnInit {
  @Input() public cgStoreUrlBackOffice: string;

  public comercialAdvanced: boolean;
  public licencaStoreModePublic: boolean;
  public portalName: string;

  private readonly _btnGestaoSepa: IPlToolbarItem;
  private readonly _maintenanceGestaoFicheirosSepa: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this._maintenanceGestaoFicheirosSepa = this._moduleMaintenanceService.build(MODULE_NAME_GESTAO_FICHEIROS_SEPA);
    this.licencaStoreModePublic = this.configurations.licenca.modoCGOn;
    this.portalName = this._translateService.instant('portals.items.erp')?.toLowerCase();

    this.comercialAdvanced = false;
    this._btnGestaoSepa = {
      id: 'btn-gestao-sepa',
      caption: 'pagamentos.list.gestaoFicheirosSepa',
      type: 'button',
      class: 'btn-info',
      disabled: true,
      order: 131,
      iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
      click: () => this._showGestaoFicheirosSEPA(),
      tooltip: {
        disabled: false
      }
    };

    this.comercialAdvanced = hasAuthority(this.session, ROLE.ERPADVANCED);
    this._btnGestaoSepa.disabled = !this.comercialAdvanced;
    this._btnGestaoSepa.tooltip.disabled = this.comercialAdvanced;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnGestaoSepa);
  }

  public onDetail({data}: IDevExpressDataGridEventOnCellClick<IPagamentoCabWithData>): Promise<void> {
    return this.service.get<IJsonPagamento>({id: data.extPocCabID, params: {nconta: data.nConta}}).then((response: HttpResponse<IJsonPagamento>) => {
      data._pagamento = response.body;
    });
  }

  @ViewChild('templateTooltipGestaoSepa')
  public set templateTooltipGestaoSepa(value: TemplateRef<void>) {
    this._btnGestaoSepa.tooltip.templateRef = value;
  }

  private _showGestaoFicheirosSEPA(): Promise<void> {
    return this._maintenanceGestaoFicheirosSepa.maintenance();
  }
}
