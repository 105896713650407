import {Component, Injector, Input, OnInit} from '@angular/core';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {RhProcSegSocialConsultEnviosService} from '../../rhProcCegCocialConsultEnvios.module.service';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'modal-consultenvios-impresumo',
  templateUrl: './consultEnvios.impResumo.modal.component.html'
})
export class ConsultEnviosImpResumoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public consultIDS: Array<string>;

  public reportModel: IJsonReport;
  public pdfUrl: string;
  public nibADebitar: string;
  public reportType: string;

  public pdfBlob: Blob;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _rhProcSegSocialConsultEnviosService: RhProcSegSocialConsultEnviosService
  ) {
    super(_injector);
    this.nibADebitar = '';
  }

  public ngOnInit(): void {
    this.reportType = EReport.RHProceSegSocialMapacxRe;
    this._loadDefaultReport().then(() => {
      this.loadPdf();
    });
  }

  public onReportChange(report: IJsonReport): void {
    this.reportModel = report;
    this.loadPdf();
  }

  public loadPdf(): void {
    this._rhProcSegSocialConsultEnviosService.getPdfUrl(this.reportModel.name, this.nibADebitar, this.consultIDS).then((response: HttpResponse<Blob>) => {
      this.pdfBlob = response.body;
    });
  }

  private _loadDefaultReport(): Promise<void> {
    this.reportModel = {
      name: this._configService.configurations.rh.proceSegSocial.reports.MapacxRE.reportImpressao,
      title: this._configService.configurations.rh.proceSegSocial.reports.MapacxRE.reportImpressao
    };
    return this._reportsRegistryService
      .get(EReport.RHProceSegSocialMapacxRe)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          let foundDefault = false;
          for (const report of reports) {
            if (report.name.toUpperCase() === this.reportModel.name.toUpperCase()) {
              this.reportModel = {name: report.name, title: report.title};
              foundDefault = true;
              break;
            }
          }

          if (!foundDefault) {
            this.reportModel = {
              name: reports[0].name,
              title: reports[0].title
            };
          }
        }
      });
  }
}
