import {ProviderLike} from '@uirouter/core';
import {HttpResponse} from '@angular/common/http';
import {ImportadorEfaturaService} from './importadorEfatura.service';
import {EFATURA_TIPO_FATURA_TODOS, IEFaturaTipoFatura} from './importadorEfatura.service.interface';

export const RESOLVER_EFATURA_TIPOS_FATURA: ProviderLike = {
  provide: 'tiposFatura',
  deps: [ImportadorEfaturaService],
  useFactory: (importadorEfaturaService: ImportadorEfaturaService) =>
    importadorEfaturaService.getAtTiposList().then((response: HttpResponse<Array<IEFaturaTipoFatura>>) => [{...EFATURA_TIPO_FATURA_TODOS}, ...response.body])
};
