import type {EventEmitter, QueryList} from '@angular/core';
import type {AbstractControl, FormGroupDirective} from '@angular/forms';
import type {IPlEditBaseComponent, IPlEditBaseComponentOptions, IPlEditComponent} from './edit.component.interface';
import {PlEditInputGroupDirective} from '../generic/inputgroup/edit.input.group.directive';

export abstract class PlEditToken<T> implements IPlEditComponent<T> {
  public model: T;
  public attrName: string;
  public inputClass: string;
  public properties: IPlEditBaseComponentOptions<T>;
  public modelChange: EventEmitter<T>;
  public options: IPlEditBaseComponentOptions<T>;
  public itemGroups: QueryList<PlEditInputGroupDirective>;

  public abstract render(value: T): void;

  public abstract addComponent(component: IPlEditBaseComponent<T>): void;

  public abstract removeComponent(): void;

  public abstract updateComponent(properties: IPlEditBaseComponentOptions<T>): void;

  public abstract setHasError(hasError: boolean): void;

  public abstract addControl(name: string, control: AbstractControl): void;

  public abstract removeControl(name: string): void;

  public abstract valueChanged(value: unknown, fieldName: string): void;

  public abstract get ngFormInstance(): FormGroupDirective;

  public abstract set ngFormInstance(value: FormGroupDirective);
}
