import {HttpErrorResponse} from '@angular/common/http';
import {RedirectToResult} from '@uirouter/core';
import {Ng2StateDeclaration} from '@uirouter/angular';
import {Logger} from 'pl-comps-angular';
import {ConfigSiteService} from '../../../services/configsite.service';
import {MillenniumComponent} from './millennium.state.component';
import {RESOLVER_RECAPTCHA_CONFIG} from '../../../services/recaptcha/recaptcha.router';
import {STATE_NAME_LOGIN} from '../../account/login/login.state.interface';
import {STATE_NAME_PARTNER_MILLENNIUM} from './millennium.state.interface';
import {STATE_NAME_PARTNERS} from '../partners.state.interface';

export const STATE_PARTNER_MILLENNIUM: Ng2StateDeclaration = {
  parent: STATE_NAME_PARTNERS,
  name: STATE_NAME_PARTNER_MILLENNIUM,
  url: '/millennium/:token',
  component: MillenniumComponent,
  data: {
    pageTitle: 'partners.millennium.title'
  },
  params: {
    token: {
      type: 'string',
      dynamic: true
    }
  },
  redirectTo: redirectToFnStatePartnerMillennium,
  resolve: [RESOLVER_RECAPTCHA_CONFIG, {provide: 'targetOrigin', deps: [Logger, ConfigSiteService], useFactory: resolverPartnerMillenniumTargetOriginFactory}]
};

export function redirectToFnStatePartnerMillennium(): RedirectToResult {
  // Only allow when opened by iframe
  if (window.top === window.self) {
    return STATE_NAME_LOGIN;
  }
  return undefined;
}

export function resolverPartnerMillenniumTargetOriginFactory(logger: Logger, configSiteService: ConfigSiteService): Promise<string> {
  return configSiteService.partnerMillenniumTargetOrigin().catch((reason: HttpErrorResponse) => {
    logger.error(reason);
    return '';
  });
}
