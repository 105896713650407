<div class="contabilidade-digital-doc-viewer cg-pdf-hover-actions" [class.cg-pdf-hover-actions-hide-on-empty]="hideToolbarOnEmptyDoc">
  <cg-pdf-viewer
    [toolbarInstanceId]="toolbarInstanceId"
    [pdfSrc]="pdfUrl"
    [externalLinkTarget]="externalLinkTarget"
    [filename]="filename"
    [page]="page"
    [renderText]="renderText"
    [renderTextMode]="renderTextMode"
    [rotation]="rotation"
    [showAll]="showAll"
    [showBorders]="showBorders"
    [stickToPage]="stickToPage"
    [zoomLevel]="zoomLevel"
    [callback]="callbackPdf"
    (pageChange)="changedPage($event)"
    (zoomLevelChange)="changedZoomLevel($event)"
    (evtConfigureToolbar)="configureToolbar($event)"
    (evtStatus)="changedPdfStatus($event)"
    (evtAfterLoadComplete)="afterLoadComplete()">
  </cg-pdf-viewer>

  <div
    class="contabilidade-digital-doc-ndocumento"
    *ngIf="nDocumento && showNDocumento"
    [translate]="'arquivodigital.docviewerrecolha.text.documento'"
    [translateParams]="{nDocumento: nDocumento}"></div>
</div>
