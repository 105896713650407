import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_ROLES} from '../../../../../entities/roles/roles.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IEmpregado} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IUtilizadoresUserRole} from '../../../../utilizadores/utilizadores.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {THttpQueryResponse} from '../../../../../services/api/api.service.interface';
import {ROLE} from '../../../../../services/role.const';
import {isArray, isDefined, isObject} from 'pl-comps-angular';

@Component({
  selector: 'choose-roles-modal',
  templateUrl: './chooseRoles.modal.component.html'
})
export class ChooseRolesModalComponent extends CGModalComponent<Array<IUtilizadoresUserRole>> implements OnInit {
  @Input() public empregado: IEmpregado;
  @Input() public selectedDefaultRoles: Array<string>;

  public roles: Array<IUtilizadoresUserRole>;
  public rolesSelected: Array<IUtilizadoresUserRole>;
  public rolesList: Array<IUtilizadoresUserRole>;
  public lastSelected: Array<IUtilizadoresUserRole>;

  private readonly _serviceRoles: IEntityService<IUtilizadoresUserRole>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.roles = [];
    this.rolesSelected = [];
    this.rolesList = [];
    this.lastSelected = [];

    this._serviceRoles = this._entityServiceBuilder.build<IUtilizadoresUserRole>(ENTITY_NAME_ROLES);
  }

  public ngOnInit(): void {
    this._serviceRoles.query().then((response: THttpQueryResponse<IUtilizadoresUserRole>) => {
      for (const role of response.body.list) {
        if (role.role === ROLE.COLABORADOR || role.role === ROLE.GESTORSERVICO || role.role === ROLE.GESTORRH) {
          this.rolesList.push(role);
        }
        if (isArray(role.depends) && role.depends.length) {
          if (isDefined(role.depends.find((value) => value === ROLE.COLABORADOR || value === ROLE.GESTORSERVICO || value === ROLE.GESTORRH))) {
            this.rolesList.push(role);
          }
        }
        if (this.selectedDefaultRoles.includes(role.role)) {
          this.rolesSelected.push(role);
        }
      }
      this.roles = this.rolesList.slice();
      this._filterAvailableRoles();
    });
  }

  public selectedRole(selected: Array<IUtilizadoresUserRole>): void {
    function find(name) {
      return (row) => row.role === name;
    }

    this.lastSelected = [];

    for (let item of selected) {
      if (!isObject(item)) {
        item = this.rolesList.find(find(item));
      }
      if (isArray(item.depends) && item.depends.length) {
        for (const row of item.depends) {
          const role: IUtilizadoresUserRole = this.rolesList.find(find(row));
          const inSelected = this.rolesSelected.find(find(row));
          if (role && !inSelected) {
            this.rolesSelected.push(role);
            const sourceIndex = this.roles.findIndex(find(role.role));
            if (sourceIndex !== -1) {
              this.roles.splice(sourceIndex, 1);
            }
            this.lastSelected.push(item);
            this.selectedRole([role]);
          }
        }
      }
    }
  }

  public removeRole(removed: Array<IUtilizadoresUserRole>): void {
    function find(name) {
      return (row) => row.role === name;
    }

    for (let item of removed) {
      if (!isObject(item)) {
        item = this.rolesList.find(find(item));
      }
      if (isArray(item.depends) && item.depends.length) {
        for (const row of item.depends) {
          const role: IUtilizadoresUserRole = this.rolesList.find(find(row));
          if (role) {
            const sourceIndex = this.roles.findIndex(find(role.role));
            this.rolesSelected.splice(sourceIndex);
            if (sourceIndex !== -1) {
              this.roles.splice(sourceIndex, 1);
            }
          }
        }
      }
    }
  }

  private _filterAvailableRoles(): void {
    for (const role of this.roles) {
      role.hidden = role.description.startsWith(ROLE.API);
      if (role.role === ROLE.REGEX_ROLE) {
        role.hidden = true;
      }
    }
  }
}
