import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_COMUNICACAO_AT, ETipoComunicacaoAt} from './tiposComunicacaoAt.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPOS_COMUNICACAO_AT: IDataSource<ETipoComunicacaoAt> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_COMUNICACAO_AT,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{label}}',
    output: 'label'
  },
  data: [
    {value: ETipoComunicacaoAt.Manual, label: 'tipoComunicacaoAt.data.manual'},
    {value: ETipoComunicacaoAt.EmTempoReal, label: 'tipoComunicacaoAt.data.emTempoReal'}
  ]
});
