import {ENTITY_NAME_PRH_MENUS} from './prhMenus.module.interface';
import {IEntityDefinition} from '../../../../components/entity/entity.definition.interface';
import {IJsonPRHMenu} from '../../../../interfaces/jsonPRHMenu.interface';
import {MenuseditComponent} from './components/menusEdit.module.component';
import {ROLE} from '../../../../services/role.const';

export const ENTITY_PRH_MENUS: IEntityDefinition<IJsonPRHMenu> = {
  name: ENTITY_NAME_PRH_MENUS,
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  searchPlaceholder: 'prhmenus.pesquisa',
  metadata: {
    keyName: 'codMenu',
    descriptionName: 'titulo',
    fields: [
      {name: 'codMenu', width: '120px', caption: 'prhmenus.fields.codMenu', placeholder: 'prhmenus.fields.codMenuPlaceholder', validators: {min: 1}, visible: false},
      {name: 'titulo', caption: 'prhmenus.fields.titulo', placeholder: 'prhmenus.fields.tituloPlaceholder', validators: {required: true, maxlength: 100}},
      {name: 'descricao', caption: 'prhmenus.fields.descricao', placeholder: 'prhmenus.fields.descricaoPlaceholder', validators: {maxlength: 250}},
      {name: 'activo', type: 'boolean', caption: 'global.text.active', placeholder: 'global.text.active'}
    ],
    order: 'codMenu desc',
    searchFields: 'codMenu,titulo,descricao'
  },
  autocomplete: {
    rowTemplate: '{{codMenu}} - {{titulo}}',
    output: 'titulo',
    searchFields: 'codMenu,titulo,descricao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.GESTOREMENTAS],
        pageTitle: 'global.menu.prhmenus'
      }
    }
  },
  detail: {
    state: {
      component: MenuseditComponent
    }
  }
};
