import {firstValueFrom} from 'rxjs';
import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {EDelphiNumberTypes, IPlToolbarItem, KEYCODES} from 'pl-comps-angular';
import {EDGEMPSTipoExportacao, ENTITY_NAME_DGEMPS_FULL, IDGEMPSFullEntityService, IDGEMPSFullGetListagemMapasFichaParams} from '../../../portalrh/dgempsfull/dgempsFull.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {maxDateCG, minDateCG} from '../../../../../common/utils/utils';
import {ModuloComponent} from '../../../../components/module/module.component';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';

const BTN_PROCESS_PDF = 'processarPDf';

@Component({
  selector: 'module-impdadosemp',
  templateUrl: './impDadosEmp.module.component.html'
})
export class ImpDadosEmpModuleComponent extends ModuloComponent implements OnInit {
  @Input() public report: IJsonReport;

  public header: IDGEMPSFullGetListagemMapasFichaParams;
  public pdfUrl: string;

  private readonly _serviceDGEMPS: IDGEMPSFullEntityService;
  private readonly _btnProcessPdf: IPlToolbarItem;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.header = {
      deCod: 0,
      atecod: EDelphiNumberTypes.MaxSmallInt,
      deNome: '',
      ateNome: 'ZZZZ',
      deIdade: 16,
      ateIdade: 100,
      deSituacao: '0001',
      ateSituacao: '9999',
      deCategoria: '',
      ateCategoria: 'ZZZZ',
      deDepartamento: 0,
      ateDepartamento: EDelphiNumberTypes.MaxSmallInt,
      deDtNasc: minDateCG(),
      deDtValCt: minDateCG(),
      deDtValBI: minDateCG(),
      ateDtNasc: maxDateCG(),
      ateDtValCt: maxDateCG(),
      ateDtValBI: maxDateCG(),
      reportName: undefined,
      tipoExportacao: EDGEMPSTipoExportacao.PDF
    };
    this._serviceDGEMPS = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS_FULL);
    this._btnProcessPdf = {
      id: BTN_PROCESS_PDF,
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-file-pdf-o fa-fw"></i>',
      class: 'btn-success',
      caption: 'global.btn.processPDF',
      click: () => this._previsualizar()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnProcessPdf);
  }

  public readonly fnKeydownProcessar = (value: string, event: KeyboardEvent): void => {
    this._keydownProcessar(event);
  };

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _keydownProcessar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      this.toolbar.focusItem(BTN_PROCESS_PDF);
    }
  }

  private async _previsualizar(): Promise<void> {
    this.header.reportName = this.report?.name;
    this.pdfUrl = await firstValueFrom(this._serviceDGEMPS.getListagemMapasFichaParamsUrl(this.header));
    if (this.pdfUrl?.length) {
      this._cardPanel.collapse();
    }
  }
}
