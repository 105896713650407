import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ConciliacaoBancariaService} from '../../conciliacaoBancaria.module.service';
import {PlI18nService} from 'pl-comps-angular';

@Component({
  selector: 'conciliacao-bancaria-saldo-detalhes-modal',
  templateUrl: './conciliacaoBancaria.saldo.detalhes.modal.component.html'
})
export class ConciliacaoBancariaSaldoDetalhesModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public nConta: string;
  @Input() public periodo: string;
  @Input() public decimais: number;
  @Input() public conciliado: boolean;

  public saldoInicialCG: number;
  public saldoFinalCG: number;
  public saldoInicialBanco: number;
  public saldoFinalBanco: number;
  public saldoFinalBancoCalculado: number;
  public diferencaConciliacao: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _conciliacaoBancariaService: ConciliacaoBancariaService,
    private readonly _plI18nService: PlI18nService
  ) {
    super(_injector);
    this.saldoInicialCG = 0;
    this.saldoFinalCG = 0;
    this.saldoInicialBanco = 0;
    this.saldoFinalBanco = 0;
    this.saldoFinalBancoCalculado = 0;
    this.diferencaConciliacao = 0;
    this.conciliado = false;
  }

  public ngOnInit(): void {
    this._conciliacaoBancariaService.obterSaldos(this.nConta, this.periodo).then((response) => {
      this.saldoInicialCG = response.saldoInicialCG;
      this.saldoFinalCG = response.saldoFinalCG;
      this.saldoInicialBanco = response.saldoInicialBanco;
      this.saldoFinalBanco = response.saldoFinalBanco;
      this.saldoFinalBancoCalculado = response.saldoFinalBancoCalculado;
      this.diferencaConciliacao = response.diferencaConciliacao;
    });
  }

  public getSaldoString(value: number): string {
    const dcString = value >= 0 ? 'CRE' : 'DEB';
    return `${this._plI18nService.formatCurrency(Math.abs(value), this.decimais)} <small>${dcString}</small>`;
  }
}
