<div class="cg-user-skin-chooser cg-user-chooser">
  <label for="cg-user-skin-chooser-select-{{ count }}">
    <i class="cg-user-chooser-icon" [cgcIcon]="theme.icon"></i>
    <select id="cg-user-skin-chooser-select-{{ count }}" class="form-select cg-user-chooser-select" [ngModel]="theme" (ngModelChange)="changedTheme($event)">
      <option *ngFor="let themeItem of themes" [ngValue]="themeItem" [selected]="themeItem === theme">
        <span [translate]="'components.usernav.theme'"></span>&nbsp;<span>{{ themeItem.title | translate | lowercase }}</span>
      </option>
    </select>
  </label>
</div>
