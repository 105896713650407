import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonTipoArtigo} from './jsonTipoArtigo.entity.interface';
import {ENTITY_NAME_TIPOS_ARTIGO} from './tiposArtigo.entity.interface';
import {DATA_SOURCE_NAME_CATEGORIA_PRODUTO} from '../../datasources/categoriaproduto/categoriaProduto.datasource.interface';

export const ENTITY_TIPOS_ARTIGO: IEntityDefinition<IJsonTipoArtigo> = {
  name: ENTITY_NAME_TIPOS_ARTIGO,
  roles: [ROLE.ERP, ROLE.ATIVOS],
  searchPlaceholder: 'tiposartigo.pesquisa',
  metadata: {
    keyName: 'codTpArt',
    fields: [
      {
        name: 'codTpArt',
        type: 'cginteger',
        width: '120px',
        caption: 'tiposartigo.fields.codTpArt',
        placeholder: 'tiposartigo.fields.CodPlaceholder',
        validators: {required: true, min: 1, max: 2147483647}
      },
      {name: 'nome', caption: 'tiposartigo.fields.nome', placeholder: 'tiposartigo.fields.nomePlaceholder', validators: {required: true}},
      {
        name: 'productCategory',
        caption: 'tiposartigo.fields.productCategory',
        placeholder: 'tiposartigo.fields.productCategoryPlaceholder',
        type: DATA_SOURCE_NAME_CATEGORIA_PRODUTO,
        validators: {required: true}
      }
    ],
    order: 'nome',
    searchFields: 'codTpArt,nome',
    listFields: 'codTpArt,nome'
  },
  autocomplete: {
    rowTemplate: '{{codTpArt}} - {{nome}}',
    output: 'nome',
    searchFields: 'codTpArt,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.tiposartigo'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.tiposartigo'
      }
    }
  }
};
