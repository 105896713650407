import {JSONSchema} from '@ngx-pwa/local-storage';
import {
  IJsonPreDefinidoContab,
  IJsonPreDefinidoContabItemVisibilidadeProperties,
  IJsonPreDefinidoContabLinha,
  IJsonPreDefinidoContabLinhaDescricao,
  IJsonPreDefinidoContabLinhaValor
} from './jsonPreDefinidosContab.module.interface';
import {SCHEMA_BOOLEAN, SCHEMA_INTEGER, SCHEMA_NUMBER, SCHEMA_STRING} from '../../../../../common/schemas';

export enum EContabilidadePredefinidosOperation {
  NEW,
  NEW_BASE_DOC,
  NEW_BASE_PREDEFINIDO,
  ADVANCED,
  EDIT,
  DELETE
}

export enum EContabilidadePredefinidosStep {
  Operation = 'operation',
  Basic = 'basic',
  Header = 'header',
  Lines = 'lines',
  Preview = 'preview',
  Companies = 'companies'
}

export type TPreDefinidoContabFormulaField = 'conta' | 'valor' | 'valorIva' | 'nContribuinte' | 'descricao' | 'nDocExterno' | 'descritivo' | 'dataDoc' | 'dataVencimento' | 'nomeConta';

export interface IPreDefinidoStateParams {
  operation?: EContabilidadePredefinidosOperation;
  docContabilidade?: string;
  editPreDefinidosID?: number;
}

export interface IPreDefinidoContab extends IJsonPreDefinidoContab {
  linhas: Array<IPreDefinidoContabLinha>;
}

export interface IPreDefinidoContabLinha extends IJsonPreDefinidoContabLinha {
  valor: IPreDefinidoContabLinhaValor;
  descricao: IPreDefinidoContabLinhaDescricao;
  _index?: number;
  _id?: number;
  _selected?: boolean;
}

export interface IPreDefinidoContabLinhaValor extends IJsonPreDefinidoContabLinhaValor {
  visibleValorFormula?: string;
}

export interface IPreDefinidoContabLinhaDescricao extends IJsonPreDefinidoContabLinhaDescricao {
  visibleValorFormula?: string;
}

export interface IPreDefinidosOperation {
  value: EContabilidadePredefinidosOperation;
  label: string;
}

export const MODULE_NAME_CONTABILIDADE_PREDEFINIDOS = 'predefinidoscontabilidade';

export const ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS = MODULE_NAME_CONTABILIDADE_PREDEFINIDOS;

export const CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES: Readonly<IJsonPreDefinidoContabItemVisibilidadeProperties> = Object.freeze<IJsonPreDefinidoContabItemVisibilidadeProperties>({
  visible: true,
  readonly: false,
  tabStop: true,
  tabOrder: -1
});

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const CONTABILIDADE_PREDEFINIDOS_DEFAULT_ORDEM_COLUNAS: ReadonlyArray<number> = Object.freeze<Array<number>>([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);

const schemaJsonPreDefinidoContabItemVisibilidadeProperties: JSONSchema = {
  type: 'object',
  properties: {
    visible: SCHEMA_BOOLEAN,
    readonly: SCHEMA_BOOLEAN,
    tabStop: SCHEMA_BOOLEAN,
    tabOrder: SCHEMA_INTEGER
  }
};

const schemaJsonPredefinidoContabCabPeriodo: JSONSchema = {
  type: 'object',
  properties: {
    tipoValorPeriodo: {
      type: 'number'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabDiario: JSONSchema = {
  type: 'object',
  properties: {
    tipoValorDiario: SCHEMA_INTEGER,
    valorFixo: SCHEMA_INTEGER,
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabItemDefault: JSONSchema = {
  type: 'object',
  properties: {
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabDataDoc: JSONSchema = {
  type: 'object',
  properties: {
    origem: {
      type: 'number'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabDataVencimento: JSONSchema = {
  type: 'object',
  properties: {
    origem: {
      type: 'number'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabDataLancamento: JSONSchema = {
  type: 'object',
  properties: {
    origem: {
      type: 'string'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabNDocExterno: JSONSchema = {
  type: 'object',
  properties: {
    incrementaAutomaticamente: {
      type: 'boolean'
    },
    valorFixo: {
      type: 'string'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabDescricao: JSONSchema = {
  type: 'object',
  properties: {
    tipoValorDescricao: {
      type: 'number'
    },
    valorFixo: {
      type: 'string'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabDescritivo: JSONSchema = {
  type: 'object',
  properties: {
    tipoValorDescritivo: {
      type: 'number'
    },
    valorFixo: {
      type: 'number'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabMoeda: JSONSchema = {
  type: 'object',
  properties: {
    tipoValorMoeda: {
      type: 'number'
    },
    valorFixo: {
      type: 'number'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabecalho: JSONSchema = {
  type: 'object',
  properties: {
    periodo: schemaJsonPredefinidoContabCabPeriodo,
    diario: schemaJsonPreDefinidoContabCabDiario,
    nDocInterno: schemaJsonPreDefinidoContabItemDefault,
    dataDoc: schemaJsonPreDefinidoContabCabDataDoc,
    dataVencimento: schemaJsonPreDefinidoContabCabDataVencimento,
    dataLancamento: schemaJsonPreDefinidoContabCabDataLancamento,
    nContribuinte: schemaJsonPreDefinidoContabItemDefault,
    nDocExterno: schemaJsonPreDefinidoContabCabNDocExterno,
    descricao: schemaJsonPreDefinidoContabCabDescricao,
    descritivo: schemaJsonPreDefinidoContabCabDescritivo,
    moeda: schemaJsonPreDefinidoContabCabMoeda
  }
};

const schemaJsonPreDefinidoContabEmpresasSelecionadas: JSONSchema = {
  type: 'array',
  items: SCHEMA_STRING
};

const schemaJsonPreDefinidoContabOrdemColunas: JSONSchema = {
  type: 'array',
  items: SCHEMA_INTEGER
};

const schemaJsonPreDefinidoContabLinhaContaBaseTribProperties: JSONSchema = {
  type: 'object',
  properties: {
    codRepCC: {
      type: 'number'
    }
  }
};

const schemaJsonPreDefinidoContabLinhaContaCorrenteProperties: JSONSchema = {
  type: 'object',
  properties: {
    temImputacao: {
      type: 'boolean'
    },
    vaiImputarALinha: {
      type: 'number'
    }
  }
};

const schemaJsonPreDefinidoContabLinhaConta: JSONSchema = {
  type: 'object',
  properties: {
    tipoConta: {
      type: 'number'
    },
    valorFixo: {
      type: 'string'
    },
    dC: {
      type: 'number'
    },
    radical: {
      type: 'string'
    },
    baseTributavelProperties: schemaJsonPreDefinidoContabLinhaContaBaseTribProperties,
    contaCorrenteProperties: schemaJsonPreDefinidoContabLinhaContaCorrenteProperties,
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabLinhaValor: JSONSchema = {
  type: 'object',
  properties: {
    tipoValor: {
      type: 'number'
    },
    valorFixo: {
      type: 'number'
    },
    valorFormula: {
      type: 'string'
    },
    visibleValorFormula: {
      type: 'string'
    },
    baseTributavelProperties: schemaJsonPreDefinidoContabLinhaContaBaseTribProperties,
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabLinhaDescricao: JSONSchema = {
  type: 'object',
  properties: {
    tipoValorDescricao: {
      type: 'number'
    },
    valorFixo: {
      type: 'number'
    },
    valorFormula: {
      type: 'string'
    },
    visibleValorFormula: {
      type: 'string'
    },
    baseTributavelProperties: schemaJsonPreDefinidoContabLinhaContaBaseTribProperties,
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

export const schemaJsonPreDefinidoContabLinha: JSONSchema = {
  type: 'object',
  properties: {
    _index: {
      type: 'number'
    },
    conta: schemaJsonPreDefinidoContabLinhaConta,
    valor: schemaJsonPreDefinidoContabLinhaValor,
    valorIva: schemaJsonPreDefinidoContabItemDefault,
    descricao: schemaJsonPreDefinidoContabLinhaDescricao,
    nContribuinte: schemaJsonPreDefinidoContabItemDefault,
    nDocExterno: schemaJsonPreDefinidoContabCabNDocExterno,
    descritivo: schemaJsonPreDefinidoContabCabDescritivo,
    dataDoc: schemaJsonPreDefinidoContabCabDataDoc,
    dataVencimento: schemaJsonPreDefinidoContabCabDataVencimento,
    nomeConta: schemaJsonPreDefinidoContabItemDefault
  }
};

const schemaJsonPreDefinidoContabLinhas: JSONSchema = {
  type: 'array',
  items: schemaJsonPreDefinidoContabLinha
};

export const schemaJsonPreDefinidoContab: JSONSchema = {
  type: 'object',
  properties: {
    preDefinidosID: SCHEMA_NUMBER,
    descricao: SCHEMA_STRING,
    revision: SCHEMA_NUMBER,
    cgBanking: SCHEMA_BOOLEAN,
    cabecalho: schemaJsonPreDefinidoContabCabecalho,
    linhas: schemaJsonPreDefinidoContabLinhas,
    empresasSelecionadas: schemaJsonPreDefinidoContabEmpresasSelecionadas,
    ordemColunas: schemaJsonPreDefinidoContabOrdemColunas
  }
};
