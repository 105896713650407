import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_DGEMPS_FULL, IDGEMPSFullEntityService} from '../../../../portalrh/dgempsfull/dgempsFull.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IJsonDGEMPFull} from '../../../../portalrh/dgempsfull/jsonDGEMPFull.interface';
import {ConfigsPortalColaboradorModuleService} from '../configs.portalcolaborador.module.service';
import {isDefinedNotNull, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {IJsonRHConflitosConfigColaboradorItem, IJsonRHConflitosCreateConflitoData} from '../configs.portalcolaborador.module.interface';

@Component({
  selector: 'rhconflitos-conflito-modal',
  templateUrl: './rhconflitos.conflito.modal.component.html'
})
export class RhConflitosConflitoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public conflitosList: Array<IJsonRHConflitosConfigColaboradorItem>;
  public model: IJsonRHConflitosCreateConflitoData;
  public dgempsDisponiveis: Array<IJsonDGEMPFull>;
  public dgempsSelecionados: Array<IJsonDGEMPFull>;
  public readonly templateMultiSelectDgEmp: string = '{{codEmp}} - {{nome}}';
  private readonly _serviceDGEMPS: IDGEMPSFullEntityService;
  private _dgempsList: Array<IJsonDGEMPFull>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _rhConflitosModuleService: ConfigsPortalColaboradorModuleService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._dgempsList = [];
    this.dgempsSelecionados = [];
    this.dgempsDisponiveis = [];
    this.model = {
      codEmp: undefined,
      withList: []
    };
    this._serviceDGEMPS = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS_FULL);
  }

  public ngOnInit(): void {
    this._serviceDGEMPS.query().then((response) => {
      this._dgempsList = response.body.list;
    });
  }

  public colaborador1Changed(): void {
    this.dgempsDisponiveis = this._dgempsList.filter((item) => {
      const existe = isDefinedNotNull(this.conflitosList.find((c) => c.idColaborador1 === this.model.codEmp && c.idColaborador2 === item.codEmp));
      return item.codEmp !== this.model.codEmp && !existe;
    });
    this.model.withList = [];
  }

  public readonly fnSave = (): Promise<void> => this._save();

  private _save(): Promise<void> {
    if (isUndefinedOrNull(this.model.codEmp)) {
      this._plAlertService.error('configsErp.items.portalColaborador.conflitos.temQueSelecionarColaborador1');
      return Promise.reject();
    }
    if (!this.dgempsSelecionados.length) {
      this._plAlertService.error('configsErp.items.portalColaborador.conflitos.temQueSelecionarPeloMenosUmColaborador');
      return Promise.reject();
    }
    this.model.withList = this.dgempsSelecionados.map((item) => item.codEmp);
    return this._rhConflitosModuleService.createConflito(this.model).then(() => {
      this.close();
    });
  }
}
