import {Observable} from 'rxjs';
import type {FileListPipelineHandler, IFileListTransformer} from './filelist.pipeline.interface';

export class FileListTransformerHandler implements FileListPipelineHandler {
  constructor(private readonly _next: FileListPipelineHandler, private readonly _transformer: IFileListTransformer) {}

  public handle(value: Array<File>, properties?: any): Observable<Array<File>> {
    return this._transformer.transform(value, this._next, properties);
  }
}
