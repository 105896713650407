import {Component, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {isNumber} from '../common/utilities/utilities';

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 100;

@Component({
  selector: 'pl-progress',
  templateUrl: './progress.component.html',
  standalone: false
})
export class PlProgressComponent implements OnChanges {
  @Input() public value: number;
  @Input() public min: number;
  @Input() public max: number;
  @Input() public theme: 'bg-success' | 'bg-info' | 'bg-warning' | 'bg-danger';

  private readonly _element: HTMLElement;

  constructor(private readonly _elementRef: ElementRef<HTMLElement>) {
    this.min = DEFAULT_MIN;
    this.max = DEFAULT_MAX;
    this._element = this._elementRef.nativeElement;
  }

  public ngOnChanges({min, max, value}: SimpleChanges): void {
    if (min) {
      this.min = isNumber(min.currentValue) ? min.currentValue : DEFAULT_MIN;
    }
    if (max) {
      this.max = isNumber(max.currentValue) ? max.currentValue : DEFAULT_MAX;
    }
    if (value) {
      let val: number = value.currentValue;
      if (isNumber(val)) {
        if (val < this.min) {
          val = this.min;
          this.value = val;
        }
        if (val > this.max) {
          val = this.max;
          this.value = val;
        }
      }
    }
  }

  public get width(): number {
    return this._element.offsetWidth;
  }
}
