<div class="concilmovab">
  <pl-group>
    <pl-group>
      <label [translate]="'concilmovab.conta'"></label>
      <edit>
        <entity-autocomplete
          entity="pocs"
          attrName="conta"
          [model]="conta"
          (selectedKeyChange)="conta.nConta = $event"
          (evtSelectedDescriptionChanged)="conta.nome = $event; pesquisaListaMovimentos()"
          [output]="pocsOutput"
          [filter]="filterMovimento"
          [fieldsMap]="{nConta: 'nConta', nome: 'nome'}"
          plAutoFocus>
          <div *inputGroup="{klass: 'hollow-form toolbar-action-previous-next'}">
            <div class="btn-group p-0">
              <button type="button" class="btn btn-primary btn-xs" (click)="findContaMovimento('previous')">
                <pl-tooltip [config]="{text: 'global.btn.previous', placement: 'left-bottom bottom-left', container: 'body'}">
                  <i *plTooltipContent class="fa fa-chevron-circle-left fa-fw" aria-hidden="true"></i>
                </pl-tooltip>
              </button>
              <button type="button" class="btn btn-primary btn-xs" (click)="findContaMovimento('next')">
                <pl-tooltip [config]="{text: 'global.btn.next', placement: 'left-bottom', container: 'body'}">
                  <i *plTooltipContent class="fa fa-chevron-circle-right fa-fw" aria-hidden="true"></i>
                </pl-tooltip>
              </button>
            </div>
          </div>
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <label>
        <span [translate]="'concilmovab.table.periodo'"></span>
        <pl-tooltip class="tooltip-info-label" [config]="{text: 'concilmovab.searchlimiteperiodomaximo', placement: ['bottom', 'left']}">
          <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
        </pl-tooltip>
      </label>
      <edit>
        <entity-autocomplete
          entity="periodos"
          attrName="periodo"
          [model]="periodoAte"
          [rowTemplate]="periodosRowTemplate"
          (selectedKeyChange)="periodoAte = $event; pesquisaListaMovimentos()"
          [fieldsMap]="{periodo: 'periodoAte', nome: 'nome'}"
          output="periodo">
        </entity-autocomplete>
      </edit>
    </pl-group>
  </pl-group>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="concilMovab"
    [dataSource]="dataGridDefinition.dataSource"
    (onInitialized)="onInitialized($event)"
    (onCellPrepared)="onCellPrepared($event)"
    (onContentReady)="onContentReady()"
    (onKeyDown)="onKeyDown($event)"
    (onCellClick)="onCellClick($event)">
    <div *dxTemplate="let cell of 'editCellTemplateAJustificar'">
      <pl-edit type="number" [attrName]="'aImputar' + cell.rowIndex" [model]="cell.data.aImputar" (modelChange)="aImputarChanged(cell, $event)" [properties]="propertiesInput">
        <div *inputGroup="{klass: 'hollow-form'}">
          <button type="button" class="btn btn-success btn-xs" (click)="justificarValorCell(cell)">
            <pl-tooltip [config]="{text: 'concilmovab.btn.justificarvalor', placement: 'top', container: 'body', tooltipClass: 'tooltip-success'}">
              <i *plTooltipContent class="fa fa-plus fa-fw" aria-hidden="true"></i>
            </pl-tooltip>
          </button>
        </div>
      </pl-edit>
    </div>
  </dx-data-grid>

  <div class="doccontabilidade-totais">
    <div class="row justify-content-end">
      <div class="col-sm-12 col-lg-5 mt-4">
        <dx-data-grid [cgDxDataGrid]="dataGridTotais" [dataSource]="totaisData"></dx-data-grid>
      </div>
    </div>
  </div>
</div>
