import {STATE_NAME_OAUTH2} from '../oauth2.state.interface';

export interface IOAuth2SAFEStateParams {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  token_type: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  expires_in: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  access_token: string;
  state: string;
}

export interface IOAuth2SAFEStoredState {
  stateName: string;
  nomeDaConta: string;
  safetyKey: string;
  obterAtributos: boolean;
  ambientePreProducao: boolean;
}

export interface IOAuth2SAFEState {
  stateID: string;
  redirectUrl: string;
}

export const STATE_NAME_OAUTH2_SAFE = `${STATE_NAME_OAUTH2}.safe`;

export const STATE_URL_OAUTH2_SAFE = 'safe';
