<div class="pl-ui-tree-drag">
  <div *ngIf="internalUITree && internalUITree.length > 0" class="pl-ui-tree-nodes">
    <pl-ui-tree-drag-node
      *ngFor="let node of internalUITree; let nodeIndex = index"
      class="d-block pl-ui-tree-node"
      [node]="node"
      [nodeIndex]="nodeIndex"
      [parentArray]="internalUITree"
      [templateContent]="templateContent || templateContentDirective"
      [disabled]="disabled"
      [draggable]="!disabled"
      (dragstart)="dragStart($event, internalUITree, nodeIndex)"
      (evtDragStart)="dragStart($event.event, $event.sourceArray, $event.index)"
      (evtDragOver)="dragOver($event.event, $event.targetArray, $event.index)"
      (evtRemoveNode)="removeNode($event)">
    </pl-ui-tree-drag-node>
  </div>
</div>
