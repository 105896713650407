import {Injector} from '@angular/core';

export type TInjectorCallable = (...args: Array<unknown>) => unknown;

export type TInjectorResolvable = Array<Function | TInjectorCallable> | TInjectorCallable;

// eslint-disable-next-line consistent-this,@typescript-eslint/no-explicit-any
export function invoke(injector: Injector, fn: TInjectorResolvable, self?: unknown): any {
  const last: number = fn.length - 1;
  const injectables: Array<unknown> = [];
  for (let i = 0; i < last; i++) {
    const currentArg = fn[i];
    if (currentArg) {
      injectables.push(injector.get<unknown>(currentArg));
    }
  }
  const fnToCall: TInjectorCallable = <TInjectorCallable>fn[last];
  return fnToCall?.apply(self, injectables);
}
