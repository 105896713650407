import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {inject} from '@angular/core';
import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {TestsService} from '../core/services/tests/tests.service';

export function interceptorTestsHelper(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const testsService: TestsService = inject(TestsService);
  testsService.incrementPendingRequests();
  return next(request).pipe(
    tap({
      error: () => {
        testsService.decrementPendingRequests();
      },
      complete: () => {
        testsService.decrementPendingRequests();
      }
    })
  );
}
