import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {isObject} from 'pl-comps-angular';
import {DATA_SOURCE_RESIDENCIA_FISCAL} from '../../../../../datasources/dhemp/residenciafiscal/residenciaFiscal.datasource';
import {EResidenciaFiscal} from '../../../../portalrh/dgempsfull/jsonDGEMPFull.interface';
import {ETipoCategoriaDesc} from '../../../../../datasources/tipocategoriadesc/tipoCategoriaDesc.datasource.interface';
import {ETipoCategoria} from '../../../../../datasources/tipocategoria/tipoCategoria.datasource.interface';
import {IDataSourceItem} from '../../../../../components/datasource/datasources.interface';
import {IJsonConfigRHDadosFicha} from '../../../jsonRHConfiguracoes.module.interface';
import {DATA_SOURCE_NAME_CONTINENTES} from '../../../../../datasources/continentes/continentes.datasource.interface';

@Component({
  selector: 'rh-dados-config-ficha',
  templateUrl: './rhDadosConfigFicha.component.html'
})
export class RHDadosConfigFichaComponent implements OnChanges {
  @Input() public model: IJsonConfigRHDadosFicha;
  @Input() public readonly: boolean;
  @Input() public required: boolean;
  @Output() public readonly modelChange: EventEmitter<IJsonConfigRHDadosFicha>;
  @Output() public readonly evtFormInstanceChanged: EventEmitter<UntypedFormGroup>;

  public readonly dataSourceNameContinentes: string = DATA_SOURCE_NAME_CONTINENTES;
  public readonly groupResidenciaFiscal: ReadonlyArray<IDataSourceItem<EResidenciaFiscal>>;
  public readonly outputAbdes: string;
  public readonly outputMotReg: string;
  public readonly filterVencimento: string;
  public readonly filterSubsAlimentacao: string;
  public readonly filterSubsFerias: string;
  public readonly filterSubsNatal: string;
  public readonly filterDiuturnidades: string;
  public readonly filterIRS: string;
  public readonly filterFerias: string;

  private _formInstance: UntypedFormGroup;

  constructor() {
    this.readonly = false;
    this.required = false;
    this.modelChange = new EventEmitter<IJsonConfigRHDadosFicha>();
    this.evtFormInstanceChanged = new EventEmitter<UntypedFormGroup>();
    this.groupResidenciaFiscal = DATA_SOURCE_RESIDENCIA_FISCAL.data;
    this.outputAbdes = '{{nCodABDESC}} - {{designacaoBreve}}';
    this.outputMotReg = '{{nMotivoMov}} - {{designaCompl}}';
    this.filterVencimento = `grupoAbonoCategoria=${ETipoCategoria.VENCIMENTO}`;
    this.filterSubsAlimentacao = `grupoAbonoCategoria=${ETipoCategoria.SUB_REFEICAO}|grupoAbonoCategoria=${ETipoCategoria.TicketValesRefeicao}`;
    this.filterSubsFerias = `grupoAbonoCategoria=${ETipoCategoria.SUB_FERIAS}`;
    this.filterSubsNatal = `grupoAbonoCategoria=${ETipoCategoria.SUB_NATAL}`;
    this.filterDiuturnidades = `grupoAbonoCategoria=${ETipoCategoria.DIUTURNIDADE}`;
    this.filterIRS = `grupoDescontoCategoria=${ETipoCategoriaDesc.IRS}`;
    this.filterFerias = `grupoAbonoCategoria=${ETipoCategoria.FERIAS_PAGAS}`;
  }

  public ngOnChanges({model}: SimpleChanges): void {
    if (model) {
      const modelValue: IJsonConfigRHDadosFicha = model.currentValue;
      if (!isObject(modelValue)) {
        this._setModel({
          designaBreveABDiuturnidades: undefined,
          designaBreveABSubAlimentacao: undefined,
          designaBreveABSubFerias: undefined,
          designaBreveABSubNatal: undefined,
          designaBreveABVencimento: undefined,
          designaBreveDescIRS: undefined,
          designaBreveMotivoRegisto: undefined,
          designaComplMotivoRegisto: undefined,
          designaBreveSituacaoEmprego: undefined,
          designaBreveTipoHorarioTrabalho: undefined,
          localTrabalho: undefined,
          motivoRegisto: undefined,
          nCodABDiuturnidades: undefined,
          nCodABSubAlimentacao: undefined,
          nCodABSubFerias: undefined,
          nCodABSubNatal: undefined,
          nCodABVencimento: undefined,
          nCodDescIRS: undefined,
          nDiasTemDireitoFerias: undefined,
          nDiasTemDireitoNatal: undefined,
          nMaxDiasUteisFerias: undefined,
          nMaxDiasUteisNatal: undefined,
          nomeLocalTrabalho: undefined,
          residenciaFiscal: undefined,
          situacaoEmprego: undefined,
          tipoHorarioTrabalho: undefined,
          nCodABFerias: undefined,
          designaBreveABFerias: undefined,
          temRendJovensTrab: false,
          dtIniRendJovensTrab: undefined,
          temRendResNaoHabit: false,
          dtIniRendResNaoHabit: undefined,
          localObterRendimento: undefined
        });
      }
    }
  }

  public changedFormInstance(value: UntypedFormGroup): void {
    this._formInstance = value;
    this.evtFormInstanceChanged.emit(this._formInstance);
  }

  private _setModel(value: IJsonConfigRHDadosFicha): void {
    this.model = value;
    this.modelChange.emit(this.model);
  }
}
