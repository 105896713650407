import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_VALOR_UNITARIO_RECOLHER, ETipoValorUnitarioRecolher} from './tipoValorUnitarioRecolher.datasource.interface';

export const DATA_SOURCE_TIPOS_VALOR_UNITARIO_RECOLHER: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_VALOR_UNITARIO_RECOLHER,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoValorUnitarioRecolher.Indiferente, name: 'tipoValorUnitarioRecolher.data.Indiferente'},
    {value: ETipoValorUnitarioRecolher.ValorVariavel, name: 'tipoValorUnitarioRecolher.data.ValorVariavel'},
    {value: ETipoValorUnitarioRecolher.ValorFixo, name: 'tipoValorUnitarioRecolher.data.ValorFixo'},
    {value: ETipoValorUnitarioRecolher.REmuneracaoHora, name: 'tipoValorUnitarioRecolher.data.REmuneracaoHora'},
    {value: ETipoValorUnitarioRecolher.REmuneracaoHoraBase, name: 'tipoValorUnitarioRecolher.data.REmuneracaoHoraBase'},
    {value: ETipoValorUnitarioRecolher.VencimentoBase, name: 'tipoValorUnitarioRecolher.data.VencimentoBase'},
    {value: ETipoValorUnitarioRecolher.REmuneracaoMensalTotal, name: 'tipoValorUnitarioRecolher.data.REmuneracaoMensalTotal'},
    {value: ETipoValorUnitarioRecolher.RemuneracaoPorDiaUtil, name: 'tipoValorUnitarioRecolher.data.RemuneracaoPorDiaUtil'},
    {value: ETipoValorUnitarioRecolher.REmuneracaoPorDiaBase30, name: 'tipoValorUnitarioRecolher.data.REmuneracaoPorDiaBase30'},
    {value: ETipoValorUnitarioRecolher.REmuneracaoMensalSubsidioBase22, name: 'tipoValorUnitarioRecolher.data.REmuneracaoMensalSubsidioBase22'},
    {value: ETipoValorUnitarioRecolher.REmuneracaoMensalSubsidioBase30, name: 'tipoValorUnitarioRecolher.data.REmuneracaoMensalSubsidioBase30'},
    {value: ETipoValorUnitarioRecolher.REmuneracaoHora2, name: 'tipoValorUnitarioRecolher.data.REmuneracaoHora2'}
  ]
});
