import {IEntityService} from '../../services/entity/entity.service.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonFimPeriodo} from './jsonFimPeriodoEnc.entity.interface';
import {IJsonPeriodo} from '../periodos/jsonPeriodo.entity.interface';
import {IJsonString} from '../../../common/interfaces/json';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface IFimPeriodoEncEntityService extends IEntityService<IJsonFimPeriodo> {
  postPerioDisponivel(listperios: Array<IJsonPeriodo>, periodo: string): TServiceResponse<IJsonString>;
}

export interface IFimPeriodoEncEntity extends IEntityDefinition<IJsonFimPeriodo, IFimPeriodoEncEntityService> {}

export const ENTITY_NAME_FIM_PERIODO_ENC = 'fimperiodoenc';
