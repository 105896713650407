import {findLastIndex} from 'lodash-es';
import {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IPlToolbarItem, KEYCODES, PlAlertService, toInteger} from 'pl-comps-angular';
import {ContabilidadeEstatisticaService} from '../../contabilidadeEstatistica.module.service';
import {ENTITY_NAME_PERIODOS, IPeriodosEntityService} from '../../../../../entities/periodos/periodos.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IReportInstance} from '../../../../../components/reports/input/reports.input.component.interface';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {ETipoContaContabilidade} from '../../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IJsonPeriodo} from '../../../../../entities/periodos/jsonPeriodo.entity.interface';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';
import {THttpQueryResponse} from '../../../../../services/api/api.service.interface';
import {IBalancetesAnaliticaFilters} from '../contabilidade.balancetes.analitica.module.interface';
import {EPcaContabilidadeServiceOptionsDefaultYear, IPcaContabilidadeServiceRefreshedEvent} from '../../../../portalclientaccounts/contabilidade/pca.contabilidade.module.service.interface';
import {PcaContabilidadeService} from '../../../../portalclientaccounts/contabilidade/pca.contabilidade.module.service';
import {IJsonBalanceteAno} from '../../balancetes/jsonContabilidade.balancetes.module.interface';
import {UntypedFormGroup} from '@angular/forms';

const BTN_PROCESSPDF_ID = 'processarPDF';

@Component({
  selector: 'contabilidade-balancetes-analitica',
  templateUrl: './contabilidade.balancetes.analitica.module.component.html'
})
export class ContabilidadeBalancetesAnaliticaComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public readonly mascarasAnalitica: string;
  public readonly outputPeriodo: string;
  public readonly filterPocs: string;

  public formGroup: UntypedFormGroup;
  public anosSource: Array<number>;
  public filterPeriodos: string;
  public filters: IBalancetesAnaliticaFilters;
  public report: IJsonReport;
  public pdfUrl: string;

  public visibleCCusto: boolean;
  public visibleZona: boolean;
  public visibleDepartamento: boolean;
  public visibleSubDepart: boolean;
  public visibleFamilia: boolean;
  public visibleGrFamilia: boolean;
  public visibleSubFamilia: boolean;
  public visibleCodContab: boolean;
  public visibleTipoArtigo: boolean;
  public visibleClasse: boolean;
  public visibleCategoria: boolean;
  public visibleVendedor: boolean;
  public visibleProcesso: boolean;

  private readonly _subscriptionOnRefresh: Subscription;
  private readonly _entityPeriodos: IPeriodosEntityService;
  private readonly _btnProcessPDF: IPlToolbarItem;
  private _periodos: Array<IJsonPeriodo>;
  private _reportInstance: IReportInstance;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _contabilidadeEstatisticaService: ContabilidadeEstatisticaService,
    private readonly _plAlertService: PlAlertService,
    private readonly _pcaContabilidadeService: PcaContabilidadeService
  ) {
    super(_injector);
    this.outputPeriodo = '{{periodo}} - {{nome}}';
    this.filterPocs = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.anosSource = [];
    this.filters = {
      nContaCGeralDe: '31',
      nContaCGeralAte: '899999999999',
      periodoDe: '',
      periodoDeNome: '',
      periodoAte: '',
      periodoAteNome: '',
      cCustoDe: '',
      cCustoAte: '9999999999',
      zonaDe: 0,
      zonaAte: 32767,
      departamentoDe: 0,
      departamentoAte: 32767,
      subDepartDe: '',
      subDepartAte: 'ZZZZ',
      familiaDe: 0,
      familiaAte: 2147483647,
      grFamiliaDe: 0,
      grFamiliaAte: 2147483647,
      subFamiliaDe: 0,
      subFamiliaAte: 2147483647,
      codContabDe: '',
      codContabAte: 'ZZZZ',
      tipoArtigoDe: 0,
      tipoArtigoAte: 2147483647,
      classeDe: 0,
      classeAte: 2147483647,
      categoriaDe: 0,
      categoriaAte: 2147483647,
      vendedorDe: 0,
      vendedorAte: 32767,
      processoDe: '',
      processoAte: 'ZZZZZZZZZZ',
      contasClasse: true,
      contasRazao: true,
      contasMovimento: true,
      contasSubTotal: true,
      ano: ''
    };
    this.report = {title: '', name: ''};
    this._entityPeriodos = this._entityServiceBuilder.build<IJsonPeriodo, IPeriodosEntityService>(ENTITY_NAME_PERIODOS);
    this._btnProcessPDF = {
      id: BTN_PROCESSPDF_ID,
      order: 3,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      class: 'btn-success',
      caption: 'global.btn.processPDF',
      disabled: false,
      click: () => {
        this._processarPDF();
      }
    };
    this._periodos = [];

    this._subscriptionOnRefresh = this._pcaContabilidadeService.onRefresh().subscribe((event: IPcaContabilidadeServiceRefreshedEvent) => {
      this._onNotify(event);
    });
    Promise.all([this._loadReport(), this._loadPeriodos(this.configurations.empresa.anoEmCursoIRC)]);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._evaluateMascaraAnalitca();
    this.toolbar.addButton(this._btnProcessPDF);
    this._pcaContabilidadeService
      .init(this.toolbar, {
        showDropdownPeriodos: false,
        showCaptionEncerrado: true,
        defaultYear: EPcaContabilidadeServiceOptionsDefaultYear.First
      })
      .then(() => {
        this.anosSource = this._pcaContabilidadeService.anos.map<number>(({ano}: IJsonBalanceteAno) => ano);
      });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionOnRefresh.unsubscribe();
  }

  public changedDePeriodo(value: string): void {
    this.filters.periodoDe = value;
    this.filters.periodoDeNome = this._getDescriptionFromPeriodo(this.filters.periodoDe);
    this.filters = {...this.filters};
    if (this.filters.periodoDe && this.filters.periodoAte) {
      this._checkPeriodos();
    }
  }

  public changedAtePeriodo(value: string): void {
    this.filters.periodoAte = value;
    const periodoDe: number = toInteger(this.filters.periodoDe);
    const periodoAte: number = toInteger(this.filters.periodoAte);
    if (periodoAte < periodoDe) {
      this.filters.periodoDe = value;
    }
    this.filters.periodoDeNome = this._getDescriptionFromPeriodo(this.filters.periodoDe);
    this.filters.periodoAteNome = this._getDescriptionFromPeriodo(this.filters.periodoAte);
    this.filters = {...this.filters};
    if (this.filters.periodoDe && this.filters.periodoAte) {
      this._checkPeriodos();
    }
  }

  public readonly fnKeydownProcessar = (value: string, event: KeyboardEvent): void => {
    this._keydownProcessar(event);
  };

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _onNotify({ano}: IPcaContabilidadeServiceRefreshedEvent): void {
    this.filters.ano = String(ano.ano);
    this._evaluateFilterPeriodos(ano.ano);
    this._loadPeriodos(ano.ano);
  }

  private _evaluateFilterPeriodos(ano: number): void {
    let filterDePeriodo = `periodo=%${ano}%`;
    if (ano === this.configurations.empresa.anoEmCursoIRC) {
      filterDePeriodo += `|periodo=%${ano + 1}%`;
    } else if (ano === this.configurations.empresa.anoEmCursoIRC + 1) {
      filterDePeriodo += `|periodo=%${this.configurations.empresa.anoEmCursoIRC}%`;
    } else {
      filterDePeriodo = `loadAllAnos=1&(${filterDePeriodo})`;
    }
    this.filterPeriodos = filterDePeriodo;
  }

  private _loadReport(): Promise<void> {
    this._reportInstance = this._reportsRegistryService.get(EReport.BalancetesAnalitica);
    return this._reportInstance.query().then((reports: Array<IJsonReport>) => {
      if (reports.length) {
        this.report = reports[0];
      }
    });
  }

  private _loadPeriodos(ano: number): Promise<void> {
    return this._entityPeriodos.query({pesquisa: this.filterPeriodos}).then((response: THttpQueryResponse<IJsonPeriodo>) => {
      this._periodos = response.body.list;
      if (this._periodos.length) {
        this.filters.periodoDe = this._periodos[0].periodo;
        this.filters.periodoDeNome = this._periodos[0].nome;
        if (ano === this.configurations.empresa.anoEmCursoIRC) {
          const index: number = findLastIndex(this._periodos, (item: IJsonPeriodo) => item.periodo.startsWith(String(ano)));
          if (index > -1) {
            this.filters.periodoAte = this._periodos[index].periodo;
            this.filters.periodoAteNome = this._periodos[index].nome;
          }
        } else {
          this.filters.periodoAte = this._periodos[this._periodos.length - 1].periodo;
          this.filters.periodoAteNome = this._periodos[this._periodos.length - 1].nome;
        }
        this.changedDePeriodo(this.filters.periodoDe);
      }
    });
  }

  private _processarPDF(): void {
    const tiposConta: Array<ETipoContaContabilidade> = [];
    if (this.filters.contasMovimento) {
      tiposConta.push(ETipoContaContabilidade.Movimento);
    }
    if (this.filters.contasSubTotal) {
      tiposConta.push(ETipoContaContabilidade.SubTotal);
    }
    if (this.filters.contasRazao) {
      tiposConta.push(ETipoContaContabilidade.Razao);
    }
    if (this.filters.contasClasse) {
      tiposConta.push(ETipoContaContabilidade.Classe);
    }

    if (!this._checkInputFields()) {
      return;
    }

    this._contabilidadeEstatisticaService
      .getBalancetesAnaliticaUrl(
        this.filters.nContaCGeralDe,
        this.filters.nContaCGeralAte,
        this.filters.periodoDe,
        this.filters.periodoAte,
        this.filters.cCustoDe,
        this.filters.cCustoAte,
        this.filters.zonaDe,
        this.filters.zonaAte,
        this.filters.departamentoDe,
        this.filters.departamentoAte,
        this.filters.subDepartDe,
        this.filters.subDepartAte,
        this.filters.familiaDe,
        this.filters.familiaAte,
        this.filters.grFamiliaDe,
        this.filters.grFamiliaAte,
        this.filters.subFamiliaDe,
        this.filters.subFamiliaAte,
        this.filters.codContabDe,
        this.filters.codContabAte,
        this.filters.tipoArtigoDe,
        this.filters.tipoArtigoAte,
        this.filters.classeDe,
        this.filters.classeAte,
        this.filters.categoriaDe,
        this.filters.categoriaAte,
        this.filters.vendedorDe,
        this.filters.vendedorAte,
        this.filters.processoDe,
        this.filters.processoAte,
        this.filters.ano,
        tiposConta,
        this.report.name
      )
      .subscribe((url: string) => {
        this.pdfUrl = undefined;
        setTimeout(() => {
          this.pdfUrl = url;
        });
      });

    this._cardPanel.collapse();
  }

  private _getDescriptionFromPeriodo(periodo: string): string {
    const item: IJsonPeriodo = this._periodos.find((periodoItem: IJsonPeriodo) => periodoItem.periodo === periodo);
    return item?.nome;
  }

  private _checkPeriodos(): Promise<unknown> {
    if (this.filters.periodoDe && this.filters.periodoAte) {
      return this._entityPeriodos.obterAnoDosPeriodos(this.filters.periodoDe, this.filters.periodoAte);
    }
    return Promise.resolve();
  }

  private _keydownProcessar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      this.toolbar.focusItem(BTN_PROCESSPDF_ID);
    }
  }

  private _evaluateMascaraAnalitca(): void {
    this.visibleCCusto = this.mascarasAnalitica.includes('C') || this.mascarasAnalitica.includes('10');
    this.visibleZona = this.mascarasAnalitica.includes('Z') || this.mascarasAnalitica.includes('11');
    this.visibleDepartamento = this.mascarasAnalitica.includes('D') || this.mascarasAnalitica.includes('12');
    this.visibleSubDepart = this.mascarasAnalitica.includes('S') || this.mascarasAnalitica.includes('13');
    this.visibleFamilia = this.mascarasAnalitica.includes('F') || this.mascarasAnalitica.includes('14');
    this.visibleGrFamilia = this.mascarasAnalitica.includes('G') || this.mascarasAnalitica.includes('15');
    this.visibleSubFamilia = this.mascarasAnalitica.includes('K') || this.mascarasAnalitica.includes('16');
    this.visibleCodContab = this.mascarasAnalitica.includes('B') || this.mascarasAnalitica.includes('17');
    this.visibleTipoArtigo = this.mascarasAnalitica.includes('T') || this.mascarasAnalitica.includes('18');
    this.visibleClasse = this.mascarasAnalitica.includes('X') || this.mascarasAnalitica.includes('19');
    this.visibleCategoria = this.mascarasAnalitica.includes('Y') || this.mascarasAnalitica.includes('20');
    this.visibleVendedor = this.mascarasAnalitica.includes('V') || this.mascarasAnalitica.includes('21');
    this.visibleProcesso = this.mascarasAnalitica.includes('P') || this.mascarasAnalitica.includes('22');
  }

  private _checkInputFields(): boolean {
    if (!this.report) {
      this._plAlertService.error('balancetesanalitica.erros.listagemNaoSelec');
      return false;
    }
    if (this.filters.periodoDe > this.filters.periodoAte) {
      this._plAlertService.error('docscontabilidade.erros.periodoFinalInferiorInicial');
      return false;
    }
    if (this.filters.nContaCGeralDe > this.filters.nContaCGeralAte) {
      this._plAlertService.error('balancetesanalitica.erros.contaFinalInferiorInicial');
      return false;
    }
    if (this.filters.cCustoDe > this.filters.cCustoAte) {
      this._plAlertService.error('balancetesanalitica.erros.custoFinalInferiorInicial');
      return false;
    }
    if (this.filters.zonaDe > this.filters.zonaAte) {
      this._plAlertService.error('balancetesanalitica.erros.zonaFinalInferiorInicial');
      return false;
    }
    if (this.filters.departamentoDe > this.filters.departamentoAte) {
      this._plAlertService.error('balancetesanalitica.erros.depFinalInferiorInicial');
      return false;
    }
    if (this.filters.subDepartDe > this.filters.subDepartAte) {
      this._plAlertService.error('balancetesanalitica.erros.subDepFinalInferiorInicial');
      return false;
    }
    if (this.filters.familiaDe > this.filters.familiaAte) {
      this._plAlertService.error('balancetesanalitica.erros.famFinalInferiorInicial');
      return false;
    }
    if (this.filters.grFamiliaDe > this.filters.grFamiliaAte) {
      this._plAlertService.error('balancetesanalitica.erros.grFamFinalInferiorInicial');
      return false;
    }
    if (this.filters.subFamiliaDe > this.filters.subFamiliaAte) {
      this._plAlertService.error('balancetesanalitica.erros.subFamFinalInferiorInicial');
      return false;
    }
    if (this.filters.codContabDe > this.filters.codContabAte) {
      this._plAlertService.error('balancetesanalitica.erros.codContabFinalInferiorInicial');
      return false;
    }
    if (this.filters.tipoArtigoDe > this.filters.tipoArtigoAte) {
      this._plAlertService.error('balancetesanalitica.erros.tipoArtFinalInferiorInicial');
      return false;
    }
    if (this.filters.classeDe > this.filters.classeAte) {
      this._plAlertService.error('balancetesanalitica.erros.classeFinalInferiorInicial');
      return false;
    }
    if (this.filters.categoriaDe > this.filters.categoriaAte) {
      this._plAlertService.error('balancetesanalitica.erros.catFinalInferiorInicial');
      return false;
    }
    if (this.filters.vendedorDe > this.filters.vendedorAte) {
      this._plAlertService.error('balancetesanalitica.erros.vendFinalInferiorInicial');
      return false;
    }
    if (this.filters.processoDe > this.filters.processoAte) {
      this._plAlertService.error('balancetesanalitica.erros.procFinalInferiorInicial');
      return false;
    }
    return true;
  }
}
