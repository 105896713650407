export enum ESimpleLoginType {
  AT,
  SSD,
  VIACTT,
  EFATURA
}

export interface ISimpleLoginCredentials {
  username: string;
  password: string;
}
