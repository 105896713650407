import {EnvironmentProviders, makeEnvironmentProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDropdownModule, NgbTimepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ColorPickerModule} from 'ngx-color-picker';
import {CGC_EDIT_COMPONENTS, CGC_EDIT_MAPS} from './edit.di';
import {EDelphiNumberTypes} from '../common/constants';
import {IPlEditRegisteredComponent, IPlEditRegisteredMap} from './edit.interface';
import {isArray} from '../common/utilities/utilities';
import {PlAnimationModule} from '../animation/animation.module';
import {PlAutoFocusModule} from '../autofocus/autofocus.module';
import {PlDatepickerModule} from '../datepicker/datepicker.module';
import {PlDelegateEventModule} from '../delegateevent/delegateevent.module';
import {PlLifecycleModule} from '../lifecycle/lifecycle.module';
import {PlLoggerModule} from '../logger/logger.module';
import {PlNavigationModule} from '../navigation/navigation.module';
import {PlNavWizardModule} from '../navwizard/navwizard.module';
import {PlPipesModule} from '../pipes/pipes.module';
import {PlPromisesModule} from '../promises/promises.module';
import {PlTooltipModule} from '../tooltip/tooltip.module';
import {PlTranslateModule} from '../translate/translate.module';

import {PlEditAutocompleteComponent} from './components/autocomplete/edit.autocomplete.component';
import {PlEditAutocompleteDropdownContentDirective} from './components/autocomplete/edit.autocomplete.dropdown.content.directive';
import {PlEditCheckboxComponent} from './components/checkbox/edit.checkbox.component';
import {PlEditColorPickerComponent} from './components/colorpicker/edit.colorpicker.component';
import {PlEditComponent} from './component/edit.component';
import {PlEditDatepickerComponent} from './components/datepicker/edit.datepicker.component';
import {PlEditEmailComponent} from './components/email/edit.email.component';
import {PlEditFormComponent} from './components/form/form.component';
import {PlEditGroupActionsComponent} from './components/group/components/edit.group.actions.directive';
import {PlEditGroupComponent} from './components/group/edit.group.component';
import {PlEditGroupEditComponent} from './components/group/components/edit.group.edit.directive';
import {PlEditGroupLabelComponent} from './components/group/components/edit.group.label.directive';
import {PlEditInputEventsHandlerDirective} from './generic/input/edit.input.eventshandler.directive';
import {PlEditInputGroupDirective} from './generic/inputgroup/edit.input.group.directive';
import {PlEditMaskComponent} from './components/mask/edit.mask.component';
import {PlEditMultiSelectComponent} from './components/multiselect/edit.multiselect.component';
import {PlEditNumberComponent} from './components/number/edit.number.component';
import {PlEditPasswordComponent} from './components/password/edit.password.component';
import {PlEditPhoneComponent} from './components/phone/edit.phone.component';
import {PlEditRadioComponent} from './components/radio/edit.radio.component';
import {PlEditRadioGroupComponent} from './components/radiogroup/edit.radio.group.component';
import {PlEditSelectComponent} from './components/select/edit.select.component';
import {PlEditSwitchComponent} from './components/switch/edit.switch.component';
import {PlEditSwitchGroupComponent} from './components/switchgroup/edit.switch.group.component';
import {PlEditTextareaComponent} from './components/textarea/edit.textarea.component';
import {PlEditTextComponent} from './components/text/edit.text.component';
import {PlEditTimePickerComponent} from './components/timepicker/edit.timepicker.component';
import {PlFilterPanelComponent} from '../filterpanel/component/filter.panel.component';
import {PlFormDebounceDirective} from './components/form/form.debounce.directive';
import {PlInlineEditComponent} from './components/inline/inline.edit.component';
import {PlMessagesComponent} from './components/messages/messages.component';
import {PlRowSelectComponent} from './components/rowselect/edit.rowselect.component';

export * from './edit.di';
export * from './edit.interface';
export * from './edit.registry.service';
export * from './component/edit.component.interface';
export * from './component/edit.component';
export * from './components/autocomplete/edit.autocomplete.component';
export * from './components/autocomplete/edit.autocomplete.component.interface';
export * from './components/autocomplete/edit.autocomplete.dropdown.content.directive';
export * from './components/checkbox/edit.checkbox.component';
export * from './components/checkbox/edit.checkbox.component.interface';
export * from './components/colorpicker/edit.colorpicker.component';
export * from './components/colorpicker/edit.colorpicker.component.interface';
export * from './components/datepicker/edit.datepicker.component';
export * from './components/datepicker/edit.datepicker.component.interface';
export * from './components/email/edit.email.component';
export * from './components/form/form.component';
export * from './components/form/form.debounce.directive';
export * from './components/form/form.interface';
export * from './components/group/components/edit.group.actions.directive';
export * from './components/group/components/edit.group.edit.directive';
export * from './components/group/components/edit.group.label.directive';
export * from './components/group/edit.group.component';
export * from './components/group/edit.group.interface';
export * from './components/group/edit.group.token';
export * from './components/inline/inline.edit.component';
export * from './components/inline/inline.edit.component.interface';
export * from './components/mask/edit.mask.component.interface';
export * from './components/mask/edit.mask.component';
export * from './components/messages/messages.component';
export * from './components/messages/messages.component.interface';
export * from './components/multiselect/edit.multiselect.component';
export * from './components/multiselect/edit.multiselect.component.interface';
export * from './components/number/edit.number.component';
export * from './components/number/edit.number.component.interface';
export * from './components/password/edit.password.component';
export * from './components/phone/edit.phone.component';
export * from './components/radio/edit.radio.component';
export * from './components/radio/edit.radio.component.interface';
export * from './components/radiogroup/edit.radio.group.component';
export * from './components/rowselect/edit.rowselect.component';
export * from './components/rowselect/edit.rowselect.component.interface';
export * from './components/select/edit.select.component';
export * from './components/select/edit.select.component.interface';
export * from './components/switch/edit.switch.component';
export * from './components/switch/edit.switch.component.interface';
export * from './components/switchgroup/edit.switch.group.component';
export * from './components/text/edit.text.component';
export * from './components/textarea/edit.textarea.component';
export * from './components/timepicker/edit.timepicker.component';
export * from './generic/edit.base.component';
export * from './generic/input/edit.input.component';
export * from './generic/input/edit.input.dropdown.component';
export * from './generic/input/edit.input.dropdown.interface';
export * from './generic/input/edit.input.eventshandler.directive';
export * from './generic/input/edit.input.eventshandler.directive.interface';
export * from './generic/input/edit.input.type.component';
export * from './generic/inputgroup/edit.input.group.directive.interface';
export * from './generic/inputgroup/edit.input.group.directive';
export * from './generic/inputgroup/edit.inputs.group.component';
export * from '../filterpanel/component/filter.panel.component.interface';
export * from '../filterpanel/component/filter.panel.component';
export * from '../filterpanel/filter.panel.adapter.interface';
export * from '../filterpanel/filter.panel.adapter';
export * from '../filterpanel/filter.panel.interface';
export * from '../filterpanel/filter.panel.utilities';

const editComponents: Array<IPlEditRegisteredComponent> = [
  {name: 'autocomplete', component: PlEditAutocompleteComponent},
  {name: 'checkbox', component: PlEditCheckboxComponent},
  {name: 'colorpicker', component: PlEditColorPickerComponent},
  {name: 'datepicker', component: PlEditDatepickerComponent},
  {name: 'email', component: PlEditEmailComponent},
  {name: 'mask', component: PlEditMaskComponent},
  {name: 'multiselect', component: PlEditMultiSelectComponent},
  {name: 'number', component: PlEditNumberComponent},
  {name: 'password', component: PlEditPasswordComponent},
  {name: 'phone', component: PlEditPhoneComponent},
  {name: 'radio', component: PlEditRadioComponent},
  {name: 'radiogroup', component: PlEditRadioGroupComponent},
  {name: 'rowselect', component: PlRowSelectComponent},
  {name: 'select', component: PlEditSelectComponent},
  {name: 'switch', component: PlEditSwitchComponent},
  {name: 'switchgroup', component: PlEditSwitchGroupComponent},
  {name: 'text', component: PlEditTextComponent},
  {name: 'textarea', component: PlEditTextareaComponent},
  {name: 'timepicker', component: PlEditTimePickerComponent}
];

const editMaps: Array<IPlEditRegisteredMap> = [
  {target: 'colorpicker', alias: 'color'},
  {target: 'datepicker', alias: 'date'},
  {target: 'number', alias: 'currency'},
  {target: 'number', alias: 'double', defaultProperties: {decimalsLimit: 2}},
  {target: 'number', alias: 'integer', defaultProperties: {decimalsLimit: 0}},
  {target: 'integer', alias: 'smallint', defaultProperties: {validators: {min: {value: EDelphiNumberTypes.MinSmallInt}, max: {value: EDelphiNumberTypes.MaxSmallInt}}}},
  {target: 'phone', alias: 'phonenumber'},
  {target: 'phone', alias: 'telephone'},
  {target: 'switch', alias: 'bool'},
  {target: 'switch', alias: 'boolean'},
  {target: 'switchgroup', alias: 'boolgroup'},
  {target: 'switchgroup', alias: 'booleangroup'},
  {target: 'text', alias: 'string'},
  {target: 'timepicker', alias: 'time'}
];

export const PROVIDER_CGC_EDIT_COMPONENTS: EnvironmentProviders = provideEditComponents();

export const PROVIDER_CGC_EDIT_MAPS: EnvironmentProviders = provideEditMaps();

export function provideEditComponents(components?: Array<IPlEditRegisteredComponent>): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: CGC_EDIT_COMPONENTS,
      multi: true,
      useValue: isArray(components) ? components : editComponents
    }
  ]);
}

export function provideEditMaps(maps?: Array<IPlEditRegisteredMap>): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: CGC_EDIT_MAPS,
      multi: true,
      useValue: isArray(maps) ? maps : editMaps
    }
  ]);
}

const DECLARATIONS = [
  PlEditAutocompleteComponent,
  PlEditAutocompleteDropdownContentDirective,
  PlEditCheckboxComponent,
  PlEditColorPickerComponent,
  PlEditComponent,
  PlEditDatepickerComponent,
  PlEditEmailComponent,
  PlEditFormComponent,
  PlEditGroupActionsComponent,
  PlEditGroupComponent,
  PlEditGroupEditComponent,
  PlEditGroupLabelComponent,
  PlEditInputEventsHandlerDirective,
  PlEditInputGroupDirective,
  PlEditMaskComponent,
  PlEditMultiSelectComponent,
  PlEditNumberComponent,
  PlEditPasswordComponent,
  PlEditPhoneComponent,
  PlEditRadioComponent,
  PlEditRadioGroupComponent,
  PlEditSelectComponent,
  PlEditSwitchComponent,
  PlEditSwitchGroupComponent,
  PlEditTextareaComponent,
  PlEditTextComponent,
  PlEditTimePickerComponent,
  PlFilterPanelComponent,
  PlFormDebounceDirective,
  PlInlineEditComponent,
  PlMessagesComponent,
  PlRowSelectComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // Dependant of #EC-12439
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    InfiniteScrollModule,
    NgbDropdownModule,
    NgbTimepickerModule,
    ColorPickerModule,
    PlAnimationModule,
    PlAutoFocusModule,
    PlDatepickerModule,
    PlDelegateEventModule,
    PlLifecycleModule,
    PlLoggerModule,
    PlNavigationModule,
    PlNavWizardModule,
    PlPipesModule,
    PlPromisesModule,
    PlTooltipModule,
    PlTranslateModule
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlEditModule {}
