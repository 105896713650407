import {Injectable} from '@angular/core';
import {DATA_SOURCE_GRUPO_DOC} from '../../datasources/grupodoc/grupoDoc.datasource';
import {EGrupoDoc, EGrupoDocType, IGrupoDoc} from '../../datasources/grupodoc/grupoDoc.datasource.interface';

@Injectable({
  providedIn: 'root'
})
export class GrupoDocService {
  private readonly _list: ReadonlyArray<IGrupoDoc>;

  constructor() {
    this._list = DATA_SOURCE_GRUPO_DOC.data;
  }

  public getGrupoDoc(id: EGrupoDoc): IGrupoDoc {
    return this._list.find((grupoDoc: IGrupoDoc) => grupoDoc.id === id);
  }

  public isVenda(grupoDoc: EGrupoDoc): boolean {
    return this.grupoDocIsType(grupoDoc, EGrupoDocType.Venda);
  }

  public isCompra(grupoDoc: EGrupoDoc): boolean {
    return this.grupoDocIsType(grupoDoc, EGrupoDocType.Compra);
  }

  public isEntradaDiversa(grupoDoc: EGrupoDoc): boolean {
    return this.grupoDocIsType(grupoDoc, EGrupoDocType.EntradasDiversas);
  }

  public isSaidaDiversa(grupoDoc: EGrupoDoc): boolean {
    return this.grupoDocIsType(grupoDoc, EGrupoDocType.SaidasDiversas);
  }

  public grupoDocIsType(grupoDoc: EGrupoDoc, type: EGrupoDocType): boolean {
    const grupo: IGrupoDoc = this.getGrupoDoc(grupoDoc);
    if (grupo) {
      return grupo.tipo === type;
    }
    return false;
  }
}
