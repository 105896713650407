import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {AtivosAquisicaoContabService} from '../ativosAquisicaoContab.module.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IAquisicaoAdicionaDocumento, IAtivoAquisicaoPesquisaContab} from '../ativosAquisicaoContab.module.interface';
import {IJsonAquisicaoContabAdicionaDoc, IJsonAquisicaoContabDoc, IJsonAquisicaoContabResAddDoc} from '../jsonAtivosAquisicaoContab.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonDocContabilidade} from '../../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';

@Component({
  selector: 'modal-ativos-aquisicao-contab',
  templateUrl: './ativosAquisicaoContab.modal.component.html'
})
export class AtivosAquisicaoContabModalComponent extends CGModalComponent<IJsonAquisicaoContabResAddDoc> implements OnInit {
  @Input() public docsAddedList: Array<IJsonAquisicaoContabAdicionaDoc>;
  @Input() public docsList: Array<IJsonAquisicaoContabDoc>;
  @Input() public aquisicaoContabList: Array<IAtivoAquisicaoPesquisaContab>;
  @Input() public empresaTemContabilidadeDigital: boolean;

  public readonly dataGridDefinition: IDevExpressDataGrid<IAtivoAquisicaoPesquisaContab, string>;

  public nLancsList: Array<string>;
  public promise: Promise<void>;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private readonly _mapDocsContabilidade: Map<string, IJsonDocContabilidade>;

  private _dataGridInstance: dxDataGrid<IAtivoAquisicaoPesquisaContab, string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosAquisicaoContabService: AtivosAquisicaoContabService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._mapDocsContabilidade = new Map<string, IJsonDocContabilidade>();
    this.dataGridDefinition = {
      keyExpr: 'nLanc',
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'ativosaquisicaocontab.table.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'ativosaquisicaocontab.table.nome', width: 200},
        {dataField: 'periodo', dataType: 'string', caption: 'ativosaquisicaocontab.table.periodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'ativosaquisicaocontab.table.nDiario'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'ativosaquisicaocontab.table.nDocInterno'},
        {dataField: 'dCDescription', dataType: 'number', caption: 'ativosaquisicaocontab.table.dCDescription'},
        {dataField: 'valor', dataType: 'double', caption: 'ativosaquisicaocontab.table.valor'},
        {dataField: 'valorCalculado', dataType: 'double', caption: 'ativosaquisicaocontab.table.valorCalculado'}
      ],
      columnHidingEnabled: false,
      export: {filename: 'ativosaquisicaocontab.modal.ativoaquisicaocontabmodaltitle'},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocContabilidade'},
      remoteOperations: false,
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'}
    };
    this.aquisicaoContabList = [];
    this.nLancsList = [];
    this.searchAquisicaoContab = this.searchAquisicaoContab.bind(this);
  }

  public ngOnInit(): void {
    this.nLancsList = this.docsList.map((documento: IJsonAquisicaoContabDoc) => documento.nLanc);
  }

  public close(): Promise<void> {
    if (this.promise) {
      return this.promise;
    }

    this.disableClose();
    const adicionarDocumentos: IAquisicaoAdicionaDocumento = this._parseSelectedItemsToDocumentosAdicionar();
    this.promise = this._ativosAquisicaoContabService
      .adicionarDocumento(adicionarDocumentos)
      .then((response: HttpResponse<IJsonAquisicaoContabResAddDoc>) => {
        for (const documento of response.body.listaDocumentos) {
          const linhaFound: IAtivoAquisicaoPesquisaContab = this.aquisicaoContabList.find((linha: IAtivoAquisicaoPesquisaContab) => linha.extPocCabID === documento.extPocCabID);
          documento.nLanc = linhaFound.nLanc;
          documento.nConta = linhaFound.nConta;
        }
        super.close(response.body);
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
      })
      .finally(() => {
        this.enableClose();
        this.promise = undefined;
      });
    return this.promise;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IAtivoAquisicaoPesquisaContab, string>): void {
    this._dataGridInstance = component;
  }

  public async onSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IAtivoAquisicaoPesquisaContab, string>): Promise<void> {
    // to add the selected lines with the same extPocCabId
    const keys: Array<string> = [];
    event.selectedRowsData.forEach((selectedRow: IAtivoAquisicaoPesquisaContab) => {
      const linhas: Array<IAtivoAquisicaoPesquisaContab> = this.aquisicaoContabList.filter((documento: IAtivoAquisicaoPesquisaContab) => documento.extPocCabID === selectedRow.extPocCabID);
      const nLancsKeys: Array<string> = linhas.map((linha: IAtivoAquisicaoPesquisaContab) => linha.nLanc);
      keys.push(...nLancsKeys);
    });
    let uniqueKeys: Array<string> = Array.from(new Set(keys));

    // to remove the selected lines with the same extPocCabId
    event.currentDeselectedRowKeys.forEach((deselectedRow: string) => {
      const rowIndex: number = this._dataGridInstance.getRowIndexByKey(deselectedRow);
      const rowData: IAtivoAquisicaoPesquisaContab = this.aquisicaoContabList[rowIndex];
      const extPocCabID: string = rowData.extPocCabID;

      this.aquisicaoContabList.forEach((linha: IAtivoAquisicaoPesquisaContab) => {
        if (linha.extPocCabID === extPocCabID) {
          uniqueKeys = uniqueKeys.filter((key: string) => key !== linha.nLanc);
        }
      });
    });
    await this._dataGridInstance.selectRows(uniqueKeys, false);
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IAtivoAquisicaoPesquisaContab, string>): void {
    devExpressDataGridExpandDetailHandler(event, async () => {
      const extPocCabID: string = event.data.extPocCabID;
      let docContabilidade: IJsonDocContabilidade = this._mapDocsContabilidade.get(extPocCabID);
      if (!docContabilidade) {
        const response: HttpResponse<IJsonDocContabilidade> = await this._serviceDocsContabilidade.get({id: extPocCabID});
        docContabilidade = response.body;
        this._mapDocsContabilidade.set(extPocCabID, docContabilidade);
      }
      if (!event.data._docContabilidade) {
        event.data._docContabilidade = docContabilidade;
        if (this.empresaTemContabilidadeDigital) {
          event.data._contabDigitalDoc = {
            extPocCabID: docContabilidade.extPocCabID,
            periodo: docContabilidade.periodo,
            nDiario: docContabilidade.nDiario,
            nDocInterno: docContabilidade.nDocInterno,
            dataDoc: docContabilidade.dataDoc
          };
        }
      }
    });
  }

  public async searchAquisicaoContab(): Promise<void> {
    const list: HttpResponse<Array<IAtivoAquisicaoPesquisaContab>> = await this._ativosAquisicaoContabService.getPesquisaAquisicaoContab();
    const selectedKeys: Array<string> = this.nLancsList.slice();
    this._mapDocsContabilidade.clear();

    if (list?.body) {
      this.aquisicaoContabList = list.body;
      this.nLancsList = selectedKeys;
    }
  }

  private _parseSelectedItemsToDocumentosAdicionar(): IAquisicaoAdicionaDocumento {
    const selectedLinhas: Array<IJsonAquisicaoContabDoc> = [];
    for (const documento of this.aquisicaoContabList) {
      if (this.nLancsList.includes(documento.nLanc) && selectedLinhas.findIndex((item: IJsonAquisicaoContabDoc) => item.extPocCabID === documento.extPocCabID) === -1) {
        selectedLinhas.push({
          extPocCabID: documento.extPocCabID,
          nDiario: documento.nDiario,
          nDocInterno: documento.nDocInterno,
          periodo: documento.periodo,
          nLanc: documento.nLanc,
          nConta: documento.nConta,
          valor: documento.valor,
          dCDescription: documento.dCDescription
        });
      }
    }
    return {
      listaContas: this.docsAddedList,
      listaDocumentos: this.docsList,
      listaDocumentosAdicionar: selectedLinhas
    };
  }
}
