import {Subscription} from 'rxjs';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Transition} from '@uirouter/core';
import {fromJson, isEmpty, isObject, Logger, toJson} from 'pl-comps-angular';
import {AppService} from '../../../services/app/app.service';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {
  EPartnerMillenniumAssociateError,
  EPartnerMillenniumEventMBCPToMe,
  EPartnerMillenniumEventMeToMBCP,
  IPartnerMillenniumFormValue,
  IPartnerMillenniumMessageMBCPToMe,
  IPartnerMillenniumMessageMeToMBCP,
  IPartnerMillenniumStateParams
} from './millennium.state.interface';
import {formGroupHasError} from '../../../../common/utils/utils';
import {ICGExceptionError} from '../../../components/exceptions/exceptions.service.interface';
import {IJsonApiPartner} from '../../../services/partners/partners.interface';
import {IJsonRecaptchaConfig} from '../../../interfaces/jsonConfigValue.interface';
import {PartnerMillenniumService} from '../../../services/partners/millennium/partnermillennium.service';

@Component({
  selector: 'partner-millennium',
  templateUrl: './millennium.state.component.html'
})
export class MillenniumComponent implements OnInit, OnDestroy {
  @Input() public recaptchaConfig: IJsonRecaptchaConfig;
  @Input() public targetOrigin: string;

  public readonly form: UntypedFormGroup;
  public recaptchaActive: boolean;
  public globalLoading: boolean;
  public submitted: boolean;
  public disabled: boolean;
  public errorMessage: string;
  public promise: Promise<void>;

  private _subscriptionValueChanges: Subscription;
  private _subscriptionGlobalLoading: Subscription;

  constructor(
    private readonly _transition: Transition,
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly _logger: Logger,
    private readonly _appService: AppService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _partnerMillenniumService: PartnerMillenniumService
  ) {
    this.recaptchaActive = true;
    this.form = this._formBuilder.group({
      username: this._formBuilder.control('', {updateOn: 'blur', validators: [Validators.required]}),
      password: this._formBuilder.control('', {updateOn: 'blur', validators: [Validators.required]})
    });
    this.globalLoading = false;
    this.submitted = false;
  }

  public ngOnInit(): void {
    this.recaptchaActive = this.recaptchaConfig.active;
    if (this.recaptchaActive) {
      this.form.addControl('recaptcha', this._formBuilder.control('', [Validators.required]));
    }
    if (!this.targetOrigin) {
      this.form.disable();
      this.disabled = true;
      this._setErrorMessage('partners.millennium.erros.invalidTargetOrigin');
    } else {
      this.disabled = false;
      window.addEventListener<'message'>('message', this._fnOnReceivedMessage, {passive: true});
      this._subscriptionValueChanges = this.form.valueChanges.subscribe((value: IPartnerMillenniumFormValue) => {
        this._onValueChanged(value);
      });
      this._subscriptionGlobalLoading = this._appService.globalLoading().subscribe((value: boolean) => {
        this.globalLoading = value;
      });
    }
  }

  public ngOnDestroy(): void {
    window.removeEventListener<'message'>('message', this._fnOnReceivedMessage);
    if (this._subscriptionValueChanges) {
      this._subscriptionValueChanges.unsubscribe();
    }
    if (this._subscriptionGlobalLoading) {
      this._subscriptionGlobalLoading.unsubscribe();
    }
  }

  public hasError(control: string, error?: string): boolean {
    return formGroupHasError(this.form, control, error);
  }

  private _onReceivedMessage(event: MessageEvent): void {
    if (event.origin !== this.targetOrigin) {
      return;
    }
    try {
      const message: IPartnerMillenniumMessageMBCPToMe = fromJson<IPartnerMillenniumMessageMBCPToMe>(event.data);
      if (isObject(message) && !isEmpty(message.message)) {
        switch (message.message) {
          case EPartnerMillenniumEventMBCPToMe.SubmitCredentials:
            this._onSubmittedCredentials();
            break;
        }
      }
    } catch (exception: unknown) {
      this._logger.error(exception);
    }
  }

  private _onValueChanged(value: IPartnerMillenniumFormValue): void {
    const invalidUsername = isEmpty(value.username);
    const invalidPassword = isEmpty(value.password);
    const invalidRecaptcha = this.recaptchaActive && isEmpty(value.recaptcha);
    if (!invalidUsername && !invalidPassword && !invalidRecaptcha) {
      this._notifyParent({message: EPartnerMillenniumEventMeToMBCP.CredentialsTyped});
    }
  }

  private _notifyParent(message: Partial<IPartnerMillenniumMessageMeToMBCP>): void {
    window.parent.postMessage(toJson(message), this.targetOrigin);
  }

  private _onSubmittedCredentials(): void {
    if (this.disabled) {
      return;
    }
    this._setErrorMessage(undefined);
    this.submitted = true;
    const {username, password, recaptcha}: IPartnerMillenniumFormValue = this.form.value;
    const invalidUsername = isEmpty(username);
    const invalidPassword = isEmpty(password);
    const invalidRecaptcha = this.recaptchaActive && isEmpty(recaptcha);
    if (invalidUsername || invalidPassword || invalidRecaptcha) {
      return;
    }
    const params: IPartnerMillenniumStateParams = <IPartnerMillenniumStateParams>this._transition.params();
    const {token} = params;
    const promise: Promise<void> = this._partnerMillenniumService
      .associate({username: username, password: password, jwt: token}, {reportExceptions: false})
      .then((response: HttpResponse<IJsonApiPartner>) => {
        this._submitSuccess(response.body);
      })
      .catch((error: HttpErrorResponse) => {
        if (this.recaptchaActive) {
          this.form.get('recaptcha').setValue('');
        }
        this._handleErrorResponse(error);
      });
    this._appService.setGlobalLoading(promise);
  }

  private _submitSuccess(partner: IJsonApiPartner): void {
    /* eslint-disable @typescript-eslint/naming-convention */
    this._notifyParent({
      message: EPartnerMillenniumEventMeToMBCP.C4TokensDelivered,
      access_token: partner.access_token,
      refresh_token: partner.refresh_token,
      error: undefined
    });
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  private _handleErrorResponse(response: HttpErrorResponse): void {
    let message = '';
    const exception: ICGExceptionError = this._cgExceptionService.get(response);
    if (exception.message) {
      message = exception.message;
      if (exception.class) {
        let error: EPartnerMillenniumAssociateError = EPartnerMillenniumAssociateError.InternalServerError;
        switch (exception.class) {
          case 'EPartnerAssociateInvalidToken':
            message = 'partners.millennium.erros.partnerAssociateInvalidToken';
            error = EPartnerMillenniumAssociateError.InvalidRequest;
            break;
          case 'EPartnerAssociateUserNotFound':
            message = 'partners.millennium.erros.partnerAssociateUserNotFound';
            error = EPartnerMillenniumAssociateError.AccessDenied;
            break;
          case 'EPartnerAssociateInvalidNif':
            message = 'partners.millennium.erros.noCompaniesWereFoundWithTheProvi';
            error = EPartnerMillenniumAssociateError.UnauthorizedUser;
            break;
          case 'EPartnerAssociateAlreadyAssociated':
            message = 'partners.millennium.erros.partnerIsActivePleaseSetUnactive';
            error = EPartnerMillenniumAssociateError.UnauthorizedUser;
            break;
        }
        this._notifyParent({message: EPartnerMillenniumEventMeToMBCP.C4OauthError, error: error});
      }
    }
    this._setErrorMessage(message);
  }

  private _setErrorMessage(value: string): void {
    this.errorMessage = value;
  }

  private readonly _fnOnReceivedMessage = (event: MessageEvent): void => {
    this._onReceivedMessage(event);
  };
}
