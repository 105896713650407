import {HttpResponse} from '@angular/common/http';
import {Component, Injector, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {merge, orderBy} from 'lodash-es';
import {copy, IPlToolbarItem, isArray, isDefined, isDefinedNotNull, isObject, PlAlertService, PlI18nService} from 'pl-comps-angular';
import {TDate} from '../../../../../../common/dates';
import {EEntityStateDetailType} from '../../../../../../common/utils/entity.state.utils';
import {
  IDevExpressDataGrid,
  IDevExpressDataGridColumnCustomizeTextCellInfo,
  TDevExpressDataGridColumnCalculateCellValueFn,
  TDevExpressDataGridColumnCustomizeTextFn
} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {ModuloEntityDetailComponent} from '../../../../../components/module/entitydetail/module.entitydetail.component';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../../config/constants';
import {DATA_SOURCE_MOEDA_NORMAS_ISO} from '../../../../../datasources/moedanormasiso/moedaNormasIso.datasource';
import {IMoedaNormasISO} from '../../../../../datasources/moedanormasiso/moedaNormasIso.datasource.interface';
import {IJsonMoedaCambio, IMoeda, IMoedaCambio} from '../jsonMoeda.interface';
import {MoedaEditCambioModalComponent} from '../modals/cambio/moeda.edit.cambio.modal.component';
import {IMoedaEntityService} from '../moeda.entity.interface';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import moment, {MomentInput} from 'moment';

@Component({
  selector: 'moeda-edit-component',
  templateUrl: './moeda.edit.component.html'
})
export class MoedaEditComponent extends ModuloEntityDetailComponent<IMoeda, IMoedaEntityService> implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public cambio: IMoedaCambio;
  public formMain: UntypedFormGroup;
  public formActive: UntypedFormGroup;
  public dataRefUpdated: boolean;
  public dataReferenciaDisabledDates: Array<MomentInput>;
  public nDecimaisDisabled: boolean;

  private readonly _btnChooseTemplate: IPlToolbarItem;
  private readonly _templatesMoeda: ReadonlyArray<IMoedaNormasISO>;

  private _cambioField: IMoedaCambio;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _plI18nService: PlI18nService
  ) {
    super(_injector);
    this.dataRefUpdated = false;
    this._btnChooseTemplate = {
      id: 'moedachoosetemplate',
      type: 'button',
      order: this.btnSave.order + 1,
      class: 'btn-info',
      iconLeft: '<i class="fa fa-clone"></i>&nbsp;',
      caption: 'components.cgmodal.choosecountry.btn',
      click: () => this._chooseTemplate()
    };
    this._templatesMoeda = DATA_SOURCE_MOEDA_NORMAS_ISO.data;

    this.dataGridDefinition = {
      columns: [
        {
          dataField: 'dataReferencia',
          dataType: 'date',
          caption: 'moeda.fields.dataReferencia',
          customizeText: this._fnCustomizeDataRef,
          allowSorting: false
        },
        {
          dataField: 'cambioMoedEmpEmEstr',
          dataType: 'number',
          caption: 'moeda.titles.cambioMoedaEmpresaEmMoedaEstrangeira',
          calculateCellValue: this._fnCalculateCambioMoedEmpEmEstr,
          allowSorting: false
        },
        {
          dataField: 'cambioMoedEstrEmEmp',
          dataType: 'number',
          caption: 'moeda.titles.cambioMoedaEstrangeiraEmMoedaEmpresa',
          calculateCellValue: this._fnCalculateCambioMoedaEstrangeiraEmMoedaEmpresa,
          allowSorting: false
        },
        {
          dataField: 'active',
          dataType: 'string',
          caption: '',
          customizeText: this._fnCustomizeActive,
          allowSorting: false,
          allowFiltering: false
        },
        {type: 'buttons', cellTemplate: 'cellTemplateBtn', headerCellTemplate: 'headerCellTemplateBtn'}
      ],
      dataSource: new CustomStore({
        key: 'idMoedaCambios',
        load: () => this._getCambiosMoedaTableSource()
      }),
      allowColumnReordering: false,
      toolbar: {visible: false}
    };
    this.dataReferenciaDisabledDates = [];
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.model = {
      codMoeda: undefined,
      nomeMoeda: '',
      abreviaturaMoeda: '',
      extenso1Unidade: '',
      extensoNUnidades: '',
      extensoCentimos: '',
      nDecimais: 2,
      cambios: [
        {
          idMoedaCambios: -1,
          codMoeda: undefined,
          dataReferencia: moment(),
          cambioMoedEmpEmEstr: undefined,
          nDecimaisCambio1: 0,
          cambioMoedEstrEmEmp: undefined,
          nDecimaisCambio2: 0
        }
      ],
      ...this.model
    };

    this._cambioField = {
      idMoedaCambios: -1,
      codMoeda: this.model.codMoeda,
      dataReferencia: moment(),
      cambioMoedEmpEmEstr: 1,
      nDecimaisCambio1: 2,
      cambioMoedEstrEmEmp: 1,
      nDecimaisCambio2: 2
    };

    this.cambio = copy(this._cambioField);
    this.cambio.dataReferencia = moment();

    this._handleCambios();
    this.nDecimaisDisabled = this.model.codMoeda === this.configurations.empresa.codMoeda && this.configurations.empresa.abreviaturaMoeda === 'EUR';
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    if (!isArray(this.model.cambios)) {
      this.model.cambios = [];
    }
    return super.onUpdate(stateType).then(() => {
      switch (stateType) {
        case EEntityStateDetailType.NEW:
        case EEntityStateDetailType.EDIT:
          this.toolbar.addButton(this._btnChooseTemplate);
          break;
        default:
          this.toolbar.removeButton(this._btnChooseTemplate);
          break;
      }
    });
  }

  public addCambio(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(MoedaEditCambioModalComponent, {size: 'md'});
    const componentInstance: MoedaEditCambioModalComponent = modalInstance.componentInstance;
    const model = this._cambioField;
    const index = this.model.cambios.findIndex((item) => moment(item.dataReferencia).isSame(moment(), 'd'));
    if (index > -1) {
      model.dataReferencia = undefined;
    }
    componentInstance.model = model;
    componentInstance.list = this.model.cambios;
    return modalInstance.result.then((response) => {
      if (isObject(response)) {
        this.model.cambios.push(copy(response));
        this._updateDisabledDate();
        this._dataGridInstance.refresh();
      }
    });
  }

  public editCambio(item: IMoedaCambio): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(MoedaEditCambioModalComponent, {size: 'md'});
    const componentInstance: MoedaEditCambioModalComponent = modalInstance.componentInstance;
    componentInstance.model = item;
    componentInstance.list = this.model.cambios;
    componentInstance.onEdit = true;
    return modalInstance.result.then((response) => {
      if (isObject(response)) {
        merge(this.model.cambios[item._selfIndex], response);
        this._updateDisabledDate();
        this._dataGridInstance.refresh();
      }
    });
  }

  public removeCambio(item: IMoedaCambio): void {
    this.model.cambios.splice(item._selfIndex, 1);
    this._dataGridInstance.refresh();
  }

  public save(): Promise<IMoeda> {
    if (this.formMain.invalid || this.formActive.invalid) {
      const errorMessage = this.type === EEntityStateDetailType.NEW ? this._translateService.instant('moeda.errorOnNew') : this._translateService.instant('moeda.error', {id: this.model.codMoeda});
      this._plAlertService.error(errorMessage);
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    this.cambio.codMoeda = this.model.codMoeda;
    const cambio: IMoedaCambio = this.model.cambios.find((cambioItem: IMoedaCambio) => this._compareDates(cambioItem.dataReferencia, this.cambio.dataReferencia));
    if (cambio) {
      merge(cambio, this.cambio);
    } else {
      this.model.cambios.push(this.cambio);
    }
    return super.save().then((response) => {
      this.model = response;
      this.cambio = copy(this._cambioField);
      this.cambio.dataReferencia = moment();
      this._handleCambios();
      return this.model;
    });
  }

  public changedDate(value: TDate): void {
    this.cambio.dataReferencia = value;
    if (isDefinedNotNull(this.cambio.dataReferencia)) {
      const cambio: IMoedaCambio = this.model.cambios.find((cambioItem: IMoedaCambio) => this._compareDates(cambioItem.dataReferencia, this.cambio.dataReferencia));
      if (cambio) {
        this.cambio = copy(cambio);
        this.cambio.idMoedaCambios = -1;
      }
    }
  }

  public updateCambio(): Promise<void> {
    const forceUpdate: boolean = this.cambio.idMoedaCambios !== -1 && moment(this.cambio.dataReferencia).isSame(moment(), 'd');
    return this.service.updateCambio(this.cambio.idMoedaCambios, this.cambio, forceUpdate).then((response: HttpResponse<IJsonMoedaCambio>) => {
      this.cambio = {...response.body};
      this.dataRefUpdated = true;
    });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  private _compareDates(a: TDate, b: TDate): boolean {
    return moment(a).isSame(b, 'date');
  }

  private _handleCambios(): void {
    if (this.type !== EEntityStateDetailType.NEW) {
      this.model.cambios = orderBy(this.model.cambios, 'dataReferencia', 'desc');
      if (this.model.cambios.length) {
        this.cambio = copy(this.model.cambios[0]);
        this.model.cambios[0].active = true;
      }
    }
    this._updateCambiosIndex();
    this._updateDisabledDate();
  }

  private _chooseTemplate(): Promise<void> {
    return this._cgModalService.showChooseCountry().then((response) => {
      const moeda = this._templatesMoeda.find((moedaItem) => moedaItem.codigo === response.abrevMoeda);
      if (isDefined(moeda)) {
        this.model.nomeMoeda = moeda.nomeMoeda;
        this.model.abreviaturaMoeda = moeda.codigo;
        this.model.extenso1Unidade = moeda.extensoUnidade;
        this.model.extensoNUnidades = moeda.extensoNUnidades;
        this.model.extensoCentimos = moeda.extensoSubUnidade;
      } else {
        this._plAlertService.warning('moeda.invalidTemplate');
      }
    });
  }

  private _customizeDataRef(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row' && cellInfo.value) {
      return this._plI18nService.formatDate(cellInfo.value);
    }
    return cellInfo.valueText;
  }

  private _customizeActive(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row' && cellInfo.value) {
      return '(Activo)';
    }
    return '';
  }

  private _calculateCambioMoedEmpEmEstr(rowData: IMoedaCambio): string {
    const decimals = rowData.nDecimaisCambio1;
    const ndecimais = isDefined(decimals) ? decimals : this._configService.configurations.contabilidade.decimais.valor;
    return this._plI18nService.formatCurrency(rowData.cambioMoedEmpEmEstr, ndecimais);
  }

  private _calculateCambioMoedaEstrangeiraEmMoedaEmpresa(rowData: IMoedaCambio): string {
    const decimals = rowData.nDecimaisCambio2;
    const ndecimais = isDefined(decimals) ? decimals : this._configService.configurations.contabilidade.decimais.valor;
    return this._plI18nService.formatCurrency(rowData.cambioMoedEstrEmEmp, ndecimais);
  }

  private _getCambiosMoedaTableSource(): Array<IMoedaCambio> {
    this._updateCambiosIndex();
    return this.model.cambios;
  }

  private _updateCambiosIndex(): void {
    this.model.cambios.forEach((item, index) => {
      item._selfIndex = index;
    });
  }

  private _updateDisabledDate(): void {
    this.dataReferenciaDisabledDates = this.model.cambios.map((item: IMoedaCambio) => moment(item.dataReferencia));
  }

  private readonly _fnCustomizeDataRef: TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => this._customizeDataRef(cellInfo);
  private readonly _fnCustomizeActive: TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => this._customizeActive(cellInfo);

  private readonly _fnCalculateCambioMoedEmpEmEstr: TDevExpressDataGridColumnCalculateCellValueFn<IMoedaCambio> = (rowData: IMoedaCambio) => this._calculateCambioMoedEmpEmEstr(rowData);

  private readonly _fnCalculateCambioMoedaEstrangeiraEmMoedaEmpresa: TDevExpressDataGridColumnCalculateCellValueFn<IMoedaCambio> = (rowData: IMoedaCambio) =>
    this._calculateCambioMoedaEstrangeiraEmMoedaEmpresa(rowData);
}
