import {TDate} from '../../../../common/dates';

export const MODULE_NAME_RH_MANUTENCAO_CAMPOS = 'rhmanutencaocampos';

export enum ERHManutencaoCamposEntityMapEnum {
  Funcao = 'funcoes',
  Departamento = 'depto',
  NSubDe = 'subde',
  NZona = 'zonas',
  CCUSTO = 'ccustos',
  NPOCRemuLiq = 'pocs',
  Seccao = 'seccoes',
  ClasseTrab = 'cltrb'
}

export enum ERHManutencaoCamposEntityMapModelValueEnum {
  Funcao = 'nFuncao',
  Departamento = 'nDepto',
  NSubDe = 'nSubDe',
  NZona = 'nZona',
  CCUSTO = 'nCCusto',
  NPOCRemuLiq = 'nConta',
  Seccao = 'nSeccao',
  ClasseTrab = 'nclasTrab'
}

export interface IRHManutencaoCamposField {
  nome: string;
  descricao: string;
}

export interface IRHManutencaoCamposFieldSave extends IRHManutencaoCamposField {
  novoValor: string | number;
}

export interface IRHManutencaoCamposProceItem {
  key: string;
  codEmp: number;
  nome: string;
  nProcessamento: number;
  dataProcessamento: TDate;
  funcao: string;
  nZona: number;
  seccao: string;
  ccusto: string;
  nPOCRemuLiq: string;
  departamento: number;
  classeTrab: string;
  nSubDe: string;
  funcaoString: string;
  nZonaString: string;
  seccaoString: string;
  ccustoString: string;
  nPOCRemuLiqString: string;
  departamentoString: string;
  classeTrabString: string;
  nSubDeString: string;
  funcaoHstEmpr: string;
  nZonaHstEmpr: number;
  seccaoHstEmpr: string;
  ccustoHstEmpr: string;
  nPOCRemuLiqHstEmpr: string;
  departamentoHstEmpr: number;
  classeTrabHstEmpr: string;
  nSubDeHstEmpr: string;
  funcaoHstEmprString: string;
  nZonaHstEmprString: string;
  seccaoHstEmprString: string;
  ccustoHstEmprString: string;
  nPOCRemuLiqHstEmprString: string;
  departamentoHstEmprString: string;
  classeTrabHstEmprString: string;
  nSubDeHstEmprString: string;
}

export interface IRHManutencaoCamposSaveData {
  dataDe: TDate;
  dataAte: TDate;
  selProceKeys: Array<string>;
  newFields: Array<IRHManutencaoCamposFieldSave>;
}

export interface IRHManutencaoCamposSummaryItem {
  ico: boolean;
  descricaoErro: string;
  codEmp: string;
  nomeEmp: string;
  nProcessamento: string;
}

export interface IRHManutencaoCamposUIFields {
  [key: string]: {
    fieldName: string;
    checked: boolean;
    value: unknown;
    entityName: string;
    entityModel: unknown;
    entityLabel: string;
  };
}
