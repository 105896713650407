import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonTiposContrato} from './jsonTiposContrato.entity.interface';
import {ENTITY_NAME_QPTCO} from '../qptco/qptco.entity.interface';
import {ENTITY_NAME_TIPOS_CONTRATO} from './tiposContrato.entity.interface';

export const ENTITY_TIPOS_CONTRATO: IEntityDefinition<IJsonTiposContrato> = {
  name: ENTITY_NAME_TIPOS_CONTRATO,
  roles: [ROLE.RH],
  searchPlaceholder: 'tiposcontrato.pesquisa',
  metadata: {
    keyName: 'nTipoContrato',
    fields: [
      {name: 'nTipoContrato', width: '200px', caption: 'tiposcontrato.fields.nTipoContrato', validators: {required: true, maxlength: 4}},
      {name: 'designacaoBreve', caption: 'tiposcontrato.fields.designacaoBreve', validators: {required: true}},
      {name: 'designacaoCompleta', caption: 'tiposcontrato.fields.designacaoCompleta', validators: {required: true}},
      {name: 'codTipCtQPessoal', visible: false},
      {
        name: 'nomeTipCtQPessoal',
        caption: 'tiposcontrato.fields.codTipCtQPessoal',
        entity: {
          name: ENTITY_NAME_QPTCO,
          keyTarget: 'codTipCtQPessoal',
          outputTarget: 'nomeTipCtQPessoal'
        },
        validators: {required: true}
      },
      {name: 'naoEnviaparaDMR', type: 'boolean', caption: 'tiposcontrato.fields.naoEnviaparaDMR'}
    ],
    order: 'nTipoContrato',
    searchFields: 'nTipoContrato,designacaoBreve'
  },
  autocomplete: {
    rowTemplate: '{{nTipoContrato}} - {{designacaoBreve}}',
    output: 'designacaoBreve',
    searchFields: 'nTipoContrato,designacaoBreve'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.tiposcontrato'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.tiposcontrato'
      }
    }
  }
};
