import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_DMRF} from './dmrf.module.interface';
import {DmrfModuleComponent} from './components/dmrf.module.component';

export const MODULE_DMRF: IModuleDefinition = {
  name: MODULE_NAME_DMRF,
  state: {
    url: `/${MODULE_NAME_DMRF}`,
    component: DmrfModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.ERP],
      sidebarTitle: 'portals.sidebar.modules.dmrf'
    }
  }
};
