import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {TDate} from '../../../../common/dates';
import {IEntityDefinition} from '../../../components/entity/entity.definition.interface';
import {IDGEMPSEntityService} from '../../../entities/dgemps/dgemps.entity.interface';
import {IApiRequestConfig, IApiRequestConfigWithBody, TServiceResponse} from '../../../services/api/api.service.interface';
import {TEntityServiceRequestData, TEntityServiceRequestDataWithBody} from '../../../services/entity/entity.service.interface';
import {IJsonDGEMPVencimentoSimulacao} from '../../colaboradores/simulavalorvencimento/simulaValorVencimento.module.interface';
import {ERHRegistarEmpregadosSSEmpSegSocialVinculo} from '../rhregistarempregadosss/rhRegistarEmpregadosSS.module.interface';
import {IJsonDGEMPComentario, IJsonDGEMPDadosDefeitoTabelaIRS, IJsonDGEMPFull, IJsonDGEMPInconsistenciasTabelaIRS, IJsonDGEMPSelecaoTabelaIRS, IJsonDHEMP} from './jsonDGEMPFull.interface';

export enum EDGEMPSSaveCadastroMode {
  None,
  NewCadastro,
  UpdateLatestCadastro
}

export enum EDGEMPSTipoExportacao {
  PDF,
  Excel
}

export enum ERHLocalTributacaoRendimento {
  RHLTRNone,
  RHLTRContinente,
  RHLTRRegiaoAcores,
  RHLTRRegiaoMadeira
}

export enum ERHSituacaoCivilTitular {
  RHSCTNone,
  RHSCTNaoCasado,
  RHSCTCasado1Titular,
  RHSCTCasado2Titulares
}

export enum ERHNaturezaRendimento {
  RHNRNone,
  RHNRDependente,
  RHNRPensoes
}

export enum ERHTipoDeficiencia {
  RHTDNone,
  RHTDDeficienciaGeral,
  RHTDDeficienciaForcasArmadas
}

export enum ERHDependentes {
  RHDSemDependentes,
  RHDComDependentes
}

export interface IDGEMPSFullEntityService extends IDGEMPSEntityService<IJsonDGEMPFull> {
  getFull(config: TEntityServiceRequestData): TServiceResponse<IJsonDGEMPFull>;

  postFull(config: IApiRequestConfigWithBody<IJsonDGEMPFull>): TServiceResponse<IJsonDGEMPFull>;

  postComentarioDGEMP(config: IApiRequestConfigWithBody<IJsonDGEMPComentario>): TServiceResponse<Array<IJsonDGEMPComentario>>;

  putFull(config: TEntityServiceRequestDataWithBody<IJsonDGEMPFull>): TServiceResponse<IJsonDGEMPFull>;

  putComentarioDGEMP(config: TEntityServiceRequestDataWithBody<IJsonDGEMPComentario>): TServiceResponse<Array<IJsonDGEMPComentario>>;

  deleteFull(config: TEntityServiceRequestData): TServiceResponse<void | IJsonDGEMPFull>;

  deleteComentarioDGEMP(config: TEntityServiceRequestData): TServiceResponse<void | IJsonDGEMPFull>;

  simulateValorVencimento(id: string | number, config?: IApiRequestConfig): TServiceResponse<IJsonDGEMPVencimentoSimulacao>;

  simulateTaxaMarginal(codEmp: string | number, taxa: number, dataSimulacao: TDate, config?: IApiRequestConfig): TServiceResponse<IDGEMPSFullTaxaMarginalSimulador>;

  getEmpregadoByNISS(niss: string): TServiceResponse<IJsonDGEMPFull>;

  getListagemMapasFichaParamsUrl(params: IDGEMPSFullGetListagemMapasFichaParams): Observable<string>;

  getListagemMapasFichaParams(params: IDGEMPSFullGetListagemMapasFichaParams): TServiceResponse<Blob>;

  getCodigoTabelaIRS(params: IJsonDGEMPSelecaoTabelaIRS): TServiceResponse<IJsonDGEMPSelecaoTabelaIRS>;

  getDadosDefeitoTabelaIRS(temdescirstxfixa: boolean, percdesctaxafixairs: number, deficienteforcasarma: boolean): TServiceResponse<IJsonDGEMPDadosDefeitoTabelaIRS>;

  getInconsistenciasTabelaIRS(): TServiceResponse<IJsonDGEMPInconsistenciasTabelaIRS>;

  setMostrarNotificacaoInconsistenciasTabelaIRS(mostrarNotificacao: boolean): TServiceResponse<void>;
}

export interface IDGEMPSFullEntity extends IEntityDefinition<IJsonDGEMPFull, IDGEMPSFullEntityService> {}

export interface IDGEMPSFullEstadoSSModel {
  infoEstadoSegSocial: ERHRegistarEmpregadosSSEmpSegSocialVinculo;
  infoEmpregadoRegistado: string;
}

export interface IDGEMPSFullTaxaMarginalSimulador {
  infoCalculos: string;
  taxaMarginal: number;
}

export interface IDGEMPSFullGetListagemMapasFichaParams {
  deCod?: number;
  atecod?: number;
  deNome?: string;
  ateNome?: string;
  deIdade?: number;
  ateIdade?: number;
  deSituacao?: string;
  ateSituacao?: string;
  deCategoria?: string;
  ateCategoria?: string;
  deDepartamento?: number;
  ateDepartamento?: number;
  deDtNasc?: TDate;
  deDtValCt?: TDate;
  deDtValBI?: TDate;
  ateDtNasc?: TDate;
  ateDtValCt?: TDate;
  ateDtValBI?: TDate;
  reportName?: string;
  tipoExportacao?: EDGEMPSTipoExportacao;
}

export interface IRHDataSourceItem<T> {
  value: T;
  label: string;
}

export interface IDHEMP extends IJsonDHEMP {
  localTributacaoRendiStr?: string;
  naturezaRendimentoStr?: string;
  situacaoCivilTitularStr?: string;
}

export interface IDGEMPFull extends IJsonDGEMPFull {
  dhemp: IDHEMP;
}

export function dataSourceRHLocalTributacaoRendimento(translate: TranslateService): ReadonlyArray<IRHDataSourceItem<ERHLocalTributacaoRendimento>> {
  return [
    {value: ERHLocalTributacaoRendimento.RHLTRNone, label: translate.instant('dgempsfull.enumStr.rhLocalTributacaoRendimento.rhltrNone')},
    {value: ERHLocalTributacaoRendimento.RHLTRContinente, label: translate.instant('dgempsfull.enumStr.rhLocalTributacaoRendimento.rhltrContinente')},
    {value: ERHLocalTributacaoRendimento.RHLTRRegiaoAcores, label: translate.instant('dgempsfull.enumStr.rhLocalTributacaoRendimento.rhltrRegiaoAcores')},
    {value: ERHLocalTributacaoRendimento.RHLTRRegiaoMadeira, label: translate.instant('dgempsfull.enumStr.rhLocalTributacaoRendimento.rhltrRegiaoMadeira')}
  ];
}

export function dataSourceRHSituacaoCivilTitular(translate: TranslateService): ReadonlyArray<IRHDataSourceItem<ERHSituacaoCivilTitular>> {
  return [
    {value: ERHSituacaoCivilTitular.RHSCTNone, label: translate.instant('dgempsfull.enumStr.rhSituacaoCivilTitular.rhsctNone')},
    {value: ERHSituacaoCivilTitular.RHSCTNaoCasado, label: translate.instant('dgempsfull.enumStr.rhSituacaoCivilTitular.rhsctNaoCasado')},
    {value: ERHSituacaoCivilTitular.RHSCTCasado1Titular, label: translate.instant('dgempsfull.enumStr.rhSituacaoCivilTitular.rhsctCasado1Titular')},
    {value: ERHSituacaoCivilTitular.RHSCTCasado2Titulares, label: translate.instant('dgempsfull.enumStr.rhSituacaoCivilTitular.rhsctCasado2Titulares')}
  ];
}

export function dataSourceRHNaturezaRendimento(translate: TranslateService): ReadonlyArray<IRHDataSourceItem<ERHNaturezaRendimento>> {
  return [
    {value: ERHNaturezaRendimento.RHNRNone, label: translate.instant('dgempsfull.enumStr.rhNaturezaRendimento.rhnrNone')},
    {value: ERHNaturezaRendimento.RHNRDependente, label: translate.instant('dgempsfull.enumStr.rhNaturezaRendimento.rhnrDependente')},
    {value: ERHNaturezaRendimento.RHNRPensoes, label: translate.instant('dgempsfull.enumStr.rhNaturezaRendimento.rhnrPensoes')}
  ];
}

export function dataSourceRHTipoDeficiencia(translate: TranslateService): ReadonlyArray<IRHDataSourceItem<ERHTipoDeficiencia>> {
  return [
    {value: ERHTipoDeficiencia.RHTDNone, label: translate.instant('dgempsfull.enumStr.rhTipoDeficiencia.rhtdNone')},
    {value: ERHTipoDeficiencia.RHTDDeficienciaGeral, label: translate.instant('dgempsfull.enumStr.rhTipoDeficiencia.rhtdDeficienciaGeral')},
    {value: ERHTipoDeficiencia.RHTDDeficienciaForcasArmadas, label: translate.instant('dgempsfull.enumStr.rhTipoDeficiencia.rhtdDeficienciaForcasArmadas')}
  ];
}

export function dataSourceRHDependentes(translate: TranslateService): ReadonlyArray<IRHDataSourceItem<ERHDependentes>> {
  return [
    {value: ERHDependentes.RHDSemDependentes, label: translate.instant('dgempsfull.enumStr.rhDependentes.rhdSemDependentes')},
    {value: ERHDependentes.RHDComDependentes, label: translate.instant('dgempsfull.enumStr.rhDependentes.rhdComDependentes')}
  ];
}

export const ENTITY_NAME_DGEMPS_FULL = 'dgempsfull';
