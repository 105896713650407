export enum ETipoComCategoria {
  None,
  Sistema,
  Utilizador
}

export interface IJsonComCategoria {
  comCategoriaId: string;
  nome: string;
  tipo: number;
  cor: string;
  todasEmpresas: boolean;
  nEmpresa: string;
}
