import {uniq} from 'lodash-es';
import {Draft, produce} from 'immer';
import moment, {Moment, unitOfTime} from 'moment';
import {BehaviorSubject, combineLatest, firstValueFrom, from, Observable, Subscription, throwError, timer} from 'rxjs';
import {catchError, finalize, map, mergeMap, pairwise, startWith, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {isArray, isBoolean, isNumber, isObject, Logger, skipIf} from 'pl-comps-angular';
import {AppService} from '../app/app.service';
import {CGLocalStorageService} from '../storage/localstorage.service';
import {ConfigService} from '../config/config.service';
import {cyrb53, extractFromCData} from '../../../common/utils/utils';
import {EAppLaunchMode} from '../../../common/site';
import {
  ENotificationSourceId,
  ENotificationType,
  INotification,
  INotificationsStates,
  INotificationsUI,
  NOTIFICATION_CENTER_ENABLED,
  notificationsToMap,
  SCHEMA_NOTIFICATIONS_STATES,
  sortNotifications,
  TNotifications
} from './notificationcenter.service.interface';
import {IAppStatus} from '../app/app.service.interface';
import {ICGConfigurations} from '../config/config.service.interface';
import {Writeable} from '../../../common/interfaces/interfaces';

const KEY_NOTIFICATIONS_STATES = 'notificationcenter.states';
const PERIOD = 60000;
const RSS_URL = 'https://www.centralgest.com/rss/feed-cloud.php';
const MOST_RECENT_TIMESTAMP_GRANULARITY: unitOfTime.StartOf = 'date';

@Injectable({
  providedIn: 'root'
})
export class NotificationCenterService implements OnDestroy {
  private readonly _mostRecentTimestamps: Map<ENotificationSourceId, Moment>;
  private readonly _subjectNotifications: BehaviorSubject<ReadonlyMap<string, INotification>>;
  private readonly _subjectLastFetchTimestamp: BehaviorSubject<Moment>;
  private readonly _subscriptionRefreshNotifications: Subscription;
  private _observableNotifications: Observable<TNotifications>;
  private _observableFetchNofications: Observable<TNotifications>;
  private _observableNewNotifications: Observable<TNotifications>;
  private _observableNewNotificationsCount: Observable<number>;
  private _observableOldNotifications: Observable<TNotifications>;
  private _notifications: TNotifications;
  private _readNofications: ReadonlySet<string>;
  private _visitedNofications: ReadonlySet<string>;
  private _storeModePublic: boolean;

  constructor(
    private readonly _logger: Logger,
    private readonly _httpClient: HttpClient,
    private readonly _appService: AppService,
    private readonly _cgLocalStorageService: CGLocalStorageService,
    private readonly _configService: ConfigService
  ) {
    this._mostRecentTimestamps = new Map<ENotificationSourceId, Moment>();
    this._subjectNotifications = new BehaviorSubject<ReadonlyMap<string, INotification>>(undefined);
    this._subjectLastFetchTimestamp = new BehaviorSubject<Moment>(undefined);
    if (NOTIFICATION_CENTER_ENABLED) {
      this._subscriptionRefreshNotifications = timer(0, PERIOD)
        .pipe(mergeMap(() => this._appService.status()))
        .pipe(skipIf((status: IAppStatus) => status.launchMode === EAppLaunchMode.Hybrid || status.launchMode === EAppLaunchMode.HybridPartial))
        .pipe(mergeMap(() => this.notifications(true)))
        .subscribe();
    }
  }

  public ngOnDestroy(): void {
    if (this._subscriptionRefreshNotifications) {
      this._subscriptionRefreshNotifications.unsubscribe();
    }
    this._subjectNotifications.complete();
  }

  public notifications(force: boolean = false): Observable<TNotifications> {
    if (!force && isObject(this._subjectNotifications.value)) {
      if (!this._observableNotifications) {
        this._observableNotifications = this._subjectNotifications.asObservable().pipe(map<ReadonlyMap<string, INotification>, TNotifications>(() => this._notifications));
      }
      return this._observableNotifications;
    }
    if (!this._observableFetchNofications) {
      this._observableFetchNofications = from<Promise<Array<INotification>>>(this._getNotifications())
        .pipe(
          finalize(() => {
            this._observableFetchNofications = undefined;
          })
        )
        .pipe(
          mergeMap<Array<INotification>, Observable<TNotifications>>((notifications: Array<INotification>) => {
            const notificationsMap = new Map<string, INotification>(notifications.map<[string, INotification]>((notification: INotification) => [notification.id, notification]));
            this._setNotifications(produce(notificationsMap, (draft) => draft));
            return this.notifications();
          })
        )
        .pipe(
          catchError((reason: unknown) => {
            this._observableFetchNofications = undefined;
            return throwError(() => reason);
          })
        );
    }
    return this._observableFetchNofications;
  }

  public newNotifications(force: boolean = false): Observable<TNotifications> {
    if (!this._observableNewNotifications) {
      this._observableNewNotifications = this.notifications(force)
        .pipe(startWith([]))
        .pipe(pairwise())
        .pipe(
          map<[TNotifications, TNotifications], TNotifications>(([previous, current]: [TNotifications, TNotifications]) => {
            const previousIds: Array<string> = previous.map<string>((notification: INotification) => notification.id);
            return current.filter((notification: INotification) => {
              if (notification.visited || notification.read || previousIds.includes(notification.id)) {
                return false;
              }
              // Handle most recent timestamp by sourceId (avoids showing older edited notifications as new ones)
              const mostRecentTimeStamp: Moment = this._mostRecentTimestamps.get(notification.sourceId);
              return !mostRecentTimeStamp || moment(notification.date).isSameOrAfter(mostRecentTimeStamp, MOST_RECENT_TIMESTAMP_GRANULARITY);
            });
          })
        );
    }
    return this._observableNewNotifications;
  }

  public newNotificationsCount(force: boolean = false): Observable<number> {
    if (!this._observableNewNotificationsCount) {
      this._observableNewNotificationsCount = this.notifications(force).pipe(
        map<TNotifications, number>((notifications: TNotifications) => {
          return notifications.filter((notification: INotification) => !notification.visited && !notification.read).length;
        })
      );
    }
    return this._observableNewNotificationsCount;
  }

  public oldNotifications(force: boolean = false): Observable<TNotifications> {
    if (!this._observableOldNotifications) {
      this._observableOldNotifications = this.newNotifications(force).pipe(
        map((newNotifications: TNotifications) => {
          const newIds: Array<string> = newNotifications.map<string>((notification: INotification) => notification.id);
          return this._notifications.filter((notification: INotification) => !newIds.includes(notification.id));
        })
      );
    }
    return this._observableOldNotifications;
  }

  public markNotificationAsVisited(notificationId: string | Array<string>): Promise<void> {
    if (!isArray(notificationId)) {
      notificationId = [notificationId];
    }
    return this._updateNotifications(notificationId, (notification: Draft<INotification>) => {
      notification.visited = true;
    });
  }

  public markNotificationAsRead(notificationId: string | Array<string>): Promise<void> {
    if (!isArray(notificationId)) {
      notificationId = [notificationId];
    }
    return this._updateNotifications(notificationId, (notification: Draft<INotification>) => {
      notification.read = true;
    });
  }

  public notificationsUI(notificationsUI: INotificationsUI): Observable<INotificationsUI> {
    return combineLatest([this.notifications(), this.newNotifications(), this.oldNotifications()]).pipe(
      map(([updatedNotifications, updatedNewNotifications, updatedOldNotifications]: [TNotifications, TNotifications, TNotifications]) => {
        const notificationsMap: Map<string, INotification> = notificationsToMap(updatedNotifications);
        let newNotifications: Array<INotification>;
        let oldNotifications: Array<INotification>;
        if (!notificationsUI.notificationsNew.length) {
          newNotifications = updatedNewNotifications.slice();
          oldNotifications = updatedOldNotifications.slice();
        } else {
          const newNotificationsIds: Array<string> = uniq(notificationsUI.notificationsNew.concat(updatedNewNotifications).map<string>((notification: INotification) => notification.id));
          newNotifications = newNotificationsIds.map((notificationId: string) => notificationsMap.get(notificationId));
          oldNotifications = uniq(
            notificationsUI.notificationsOld
              .concat(updatedOldNotifications)
              .map((notification: INotification) => notification.id)
              .filter((notificationId: string) => !newNotificationsIds.includes(notificationId))
          ).map((notificationId: string) => notificationsMap.get(notificationId));
        }
        return {
          notificationsNew: Object.freeze(sortNotifications(newNotifications)),
          notificationsOld: Object.freeze(sortNotifications(oldNotifications))
        };
      })
    );
  }

  public async markNotificationsUIAsVisited(notificationsUI: INotificationsUI): Promise<void> {
    const toMarkAsVisited: Array<string> = notificationsUI.notificationsNew
      .concat(notificationsUI.notificationsOld)
      .filter((notification: INotification) => !notification.visited)
      .map((notification: INotification) => notification.id);
    if (toMarkAsVisited.length) {
      try {
        await this.markNotificationAsVisited(toMarkAsVisited);
      } catch (reason) {
        this._logger.error(reason);
      }
    }
  }

  private async _getNotifications(): Promise<Array<INotification>> {
    await this._loadNotificationsStates();
    if (!isBoolean(this._storeModePublic)) {
      const configurations: ICGConfigurations = await firstValueFrom(this._configService.configurationsAsObservable().pipe(take(1)));
      this._storeModePublic = configurations.licenca.storeModePublic;
    }
    const notificationsAggregate: Array<Array<INotification>> = await Promise.all<Array<INotification>>(NOTIFICATION_CENTER_ENABLED ? [this._getNotificationsFromRSS()] : []);
    this._subjectLastFetchTimestamp.next(moment());
    // Combine all notification arrays into a single array
    let notifications: Array<INotification> = notificationsAggregate.reduce((accumulator: Array<INotification>, aggregate: Array<INotification>) => accumulator.concat(aggregate), []);
    // Sort notifications by date
    notifications = sortNotifications(notifications);
    // Evaluate sourceId's timestamps
    if (this._subjectNotifications.value) {
      this._evaluateMostRecentTimestamps(notifications);
    }
    return notifications;
  }

  private async _getNotificationsFromRSS(): Promise<Array<INotification>> {
    const responseBody: string = await firstValueFrom(this._httpClient.get(RSS_URL, {responseType: 'text'})).catch((reason: unknown) => {
      this._logger.error(reason);
      return '';
    });
    if (!responseBody) {
      return [];
    }
    const xml: Document = new window.DOMParser().parseFromString(responseBody, 'text/xml');
    const items: Array<Element> = Array.from(xml.querySelectorAll('item'));
    const itemsType: WeakMap<Element, ENotificationType> = new WeakMap<Element, ENotificationType>();
    return items
      .filter((item: Element) => {
        let type: ENotificationType;
        const categories: Array<string> = extractFromCData(item.querySelector('category')?.textContent).split(',') ?? [];
        for (const category of categories) {
          if (category === 'Cloud Privada') {
            if (!this._storeModePublic) {
              type = ENotificationType.News;
            }
          } else if (category === 'Cloud Pública') {
            if (this._storeModePublic) {
              type = ENotificationType.News;
            }
          } else if (category === 'Cloud Pública Manutenção') {
            if (this._storeModePublic) {
              type = ENotificationType.Maintenance;
            }
          }
          if (isNumber(type)) {
            break;
          }
        }
        if (isNumber(type)) {
          itemsType.set(item, type);
          return true;
        }
        return false;
      })
      .map<INotification>((item: Element) => {
        const title: string = extractFromCData(item.querySelector('title')?.textContent) ?? '';
        const description: string = item.querySelector('description')?.textContent ?? '';
        const content: string = item.querySelector('content')?.textContent ?? '';
        const href: string = item.querySelector('guid')?.textContent ?? '';
        const date: string = item.querySelector('data_pub')?.textContent ?? '';
        const icon: string = item.querySelector('image')?.textContent ?? '';
        const id: string = cyrb53(`${title}.${content}.${href}`); //generated hash from string
        const type: ENotificationType = itemsType.get(item);
        const notification: INotification = {
          id: id,
          sourceId: ENotificationSourceId.RSS,
          type: type,
          title: title,
          description: description,
          content: content,
          icon: icon,
          visited: false,
          read: false,
          date: moment(date).toJSON(),
          href: href
        };
        this._evaluateNotificationState(notification);
        return Object.freeze(notification);
      });
  }

  private async _loadNotificationsStates(): Promise<void> {
    let states: INotificationsStates = await firstValueFrom(this._cgLocalStorageService.getItem<INotificationsStates>(KEY_NOTIFICATIONS_STATES, SCHEMA_NOTIFICATIONS_STATES));
    if (!states) {
      states = {
        lastFetchTimestamp: moment(),
        visited: [],
        read: []
      };
    }
    this._subjectLastFetchTimestamp.next(moment(states.lastFetchTimestamp));
    this._readNofications = new Set<string>(states.read);
    this._visitedNofications = new Set<string>(states.visited);
  }

  private async _saveNotificationsStates(): Promise<void> {
    const states: INotificationsStates = {
      lastFetchTimestamp: this._subjectLastFetchTimestamp.value.toJSON(),
      read: Array.from(this._readNofications.values()),
      visited: Array.from(this._visitedNofications.values())
    };
    await firstValueFrom(this._cgLocalStorageService.setItem(KEY_NOTIFICATIONS_STATES, states, SCHEMA_NOTIFICATIONS_STATES));
  }

  private _updateNotifications(notificationsId: Array<string>, updateCallback: (notification: Draft<INotification>) => void): Promise<void> {
    let changedVisitedNofications: boolean;
    let changedReadNofications: boolean;
    const updatedNotifications: ReadonlyMap<string, INotification> = produce(this._subjectNotifications.value, (notifications: Map<string, Draft<INotification>>) => {
      for (const notificationId of notificationsId) {
        if (!this._subjectNotifications.value.has(notificationId)) {
          throw new Error(`Notification with id "${notificationId}" was not found.`);
        }
        const notification: Draft<INotification> = notifications.get(notificationId);
        updateCallback(notification);
        changedVisitedNofications = notification.visited && !this._visitedNofications.has(notification.id);
        changedReadNofications = notification.read && !this._readNofications.has(notification.id);
        if (changedVisitedNofications) {
          this._addVisitedNotification(notification.id);
        }
        if (changedReadNofications) {
          this._addReadNotification(notification.id);
        }
      }
    });
    this._setNotifications(updatedNotifications);
    if (changedVisitedNofications || changedReadNofications) {
      return this._saveNotificationsStates();
    }
    return Promise.resolve();
  }

  private _evaluateNotificationState(notification: Writeable<INotification>): void {
    notification.read = this._readNofications.has(notification.id);
    notification.visited = this._visitedNofications.has(notification.id);
  }

  private _evaluateMostRecentTimestamps(notifications: Array<INotification>): void {
    for (const notification of notifications) {
      const sourceIdTimestamp: Moment = this._mostRecentTimestamps.get(notification.sourceId);
      const notificationTimestamp: Moment = moment(notification.date);
      if (!sourceIdTimestamp || notificationTimestamp.isAfter(sourceIdTimestamp, MOST_RECENT_TIMESTAMP_GRANULARITY)) {
        this._mostRecentTimestamps.set(notification.sourceId, notificationTimestamp);
      }
    }
  }

  private _addReadNotification(notificationId: string): void {
    this._readNofications = produce(this._readNofications, (readNofications: Draft<ReadonlySet<string>>) => {
      readNofications.add(notificationId);
    });
  }

  private _addVisitedNotification(notificationId: string): void {
    this._visitedNofications = produce(this._visitedNofications, (visitedNotifications: Draft<ReadonlySet<string>>) => {
      visitedNotifications.add(notificationId);
    });
  }

  private _setNotifications(notifications: ReadonlyMap<string, INotification>): void {
    this._notifications = produce(Array.from(notifications.values()), (draft: Draft<TNotifications>) => draft);
    this._subjectNotifications.next(notifications);
  }
}
