<div class="modal-header ativosimpressao-modal">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'ativosimpressao.modal.ativoimpressaomodaltitle'"></h5>
</div>

<div class="modal-body">
  <pl-form [submit]="processar" plPromise>
    <pl-group>
      <pl-group plAutoFocus>
        <label [translate]="'global.text.listing'"></label>
        <edit>
          <pl-edit type="reports_ativos" attrName="listagem" [(model)]="reportModel"></pl-edit>
        </edit>
      </pl-group>
      <pl-group class="align-self-end">
        <edit>
          <pl-button type="submit" klass="btn btn-primary btn-sm"> <i class="fa fa-fw fa-search"></i><span [translate]="'global.btn.preview'"></span> </pl-button>
        </edit>
      </pl-group>
    </pl-group>
  </pl-form>

  <br />

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light action-dismiss" (evtClicked)="close()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'ativosimpressao.btn.ok'"></span></pl-button>
</div>
