import {Component, Injector, Input} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {ContabilidadeDigitalService} from '../../../../services/contabilidadedigital/contabilidadedigital.service';

@Component({
  selector: 'contabilidadedigital-remove-pages-modal',
  templateUrl: './contabilidadedigital.removepages.modal.component.html'
})
export class ContabilidadeDigitalRemovePagesModalComponent extends CGModalComponent<void> {
  @Input() public docId: string;
  public page: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService
  ) {
    super(_injector);
    this.page = '1';
  }

  public close(): Promise<void> {
    if (!this.page) {
      return Promise.resolve();
    }
    return this._contabilidadeDigitalService.documents.removePages(this.docId, this.page).then(() => {
      this._plAlertService.success('arquivodigital.removepagesmodal.success');
      super.close();
    });
  }
}
