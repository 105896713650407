import {merge} from 'lodash-es';
import {BehaviorSubject, Observable} from 'rxjs';
import {Inject, Injectable, Renderer2, RendererFactory2, ViewEncapsulation} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {deepFreeze} from '../common/utilities/utilities';
import type {IPlPromisesServiceConfiguration} from './promises.service.interface';

@Injectable({
  providedIn: 'root'
})
export class PlPromiseService {
  private readonly _defaultConfig: IPlPromisesServiceConfiguration;
  private readonly _subjectConfig: BehaviorSubject<IPlPromisesServiceConfiguration>;
  private _observableConfig: Observable<IPlPromisesServiceConfiguration>;

  constructor(
    @Inject(DOCUMENT) document: any,
    private readonly _rendererFactory: RendererFactory2
  ) {
    const renderer: Renderer2 = this._rendererFactory.createRenderer(document?.body, {
      id: 'pl-promise-service',
      encapsulation: ViewEncapsulation.Emulated,
      styles: [],
      data: {}
    });
    const ELEMENT_PROMISES: HTMLElement = renderer.createElement('div');
    renderer.addClass(ELEMENT_PROMISES, 'pl-promise-spinner');
    const ELEMENT_SPINNER: HTMLElement = renderer.createElement('div');
    renderer.addClass(ELEMENT_SPINNER, 'pl-spinner-3dots-bounce1');
    renderer.appendChild(ELEMENT_PROMISES, ELEMENT_SPINNER.cloneNode(true));
    renderer.appendChild(ELEMENT_PROMISES, ELEMENT_SPINNER.cloneNode(true));
    renderer.appendChild(ELEMENT_PROMISES, ELEMENT_SPINNER.cloneNode(true));
    const ELEMENT_OVERLAY: HTMLElement = renderer.createElement('div');
    renderer.addClass(ELEMENT_OVERLAY, 'pl-promise-overlay');
    this._defaultConfig = {
      addClassToTargetOnly: false,
      dim: false,
      dimClass: 'pl-promise-dim',
      disableBtn: true,
      loadingClass: 'pl-promise-loading',
      minDuration: undefined,
      overlay: false,
      templates: {
        promises: ELEMENT_PROMISES,
        overlay: ELEMENT_OVERLAY
      }
    };
    this._subjectConfig = new BehaviorSubject<IPlPromisesServiceConfiguration>(this._defaultConfig);
  }

  public get(): Observable<IPlPromisesServiceConfiguration> {
    if (!this._observableConfig) {
      this._observableConfig = this._subjectConfig.asObservable();
    }
    return this._observableConfig;
  }

  public set(newConfig: Partial<IPlPromisesServiceConfiguration>): void {
    this._subjectConfig.next(deepFreeze(merge({}, this._defaultConfig, this._subjectConfig.getValue(), newConfig)));
  }
}
