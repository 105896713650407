import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {IJsonRecibo} from '../../../entities/recibos/jsonRecibo.entity.interface';

@Component({
  selector: 'ddsepa-recibo-modal',
  templateUrl: './debDiretosSEPA.recibo.modal.component.html'
})
export class DebDiretosSEPAReciboModalComponent extends CGModalComponent<void> {
  @Input() public recibo: IJsonRecibo;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.recibo = {
      cab: {
        extPocCabID: '',
        data: undefined,
        abreviaturaMoeda: undefined,
        codMoeda: undefined,
        email: '',
        nConta: '',
        nif: '',
        nome: '',
        numero: '',
        descricao: '',
        nDocAsStr: '',
        origem: 0,
        stampUpdate: undefined,
        temRetencao: false,
        total: 0,
        totalReceber: 0,
        totalRetencao: 0,
        totalDescontos: 0,
        valor: 0,
        valorEntregueCredito: 0
      },
      linhas: [],
      meiosPagamento: [],
      percDescSobreValorIVA: undefined
    };
  }
}
