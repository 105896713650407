import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {FimAnoComponent} from './components/fimAno.module.component';
import {ROLE} from '../../../services/role.const';

export const MODULE_FIM_ANO: IModuleDefinition = {
  name: 'fimano',
  state: {
    url: '/fimano',
    component: FimAnoComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.fimano'
    }
  }
};
