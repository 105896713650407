import {Component, Injector, Input, OnInit} from '@angular/core';
import {merge} from 'lodash-es';
import {isEmpty, isObject, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EFEAEstado, IJsonFaturacaoPublicaConfigEstado} from '../../jsonFaturacaoPublica.module.interface';
import {IMetodoSourceEstadosConta} from '../../faturacaoPublica.module.interface';
import {IJsonClifo} from '../../../../entities/clifos/jsonClifo.entity.interface';

@Component({
  selector: 'modal-faturacaopublica-config-novoestado',
  templateUrl: './faturacaoPublica.config.novoEstado.modal.component.html'
})
export class FaturacaopublicaConfigNovoestadoModalComponent extends CGModalComponent<IJsonFaturacaoPublicaConfigEstado> implements OnInit {
  @Input() public fatPubConfEstadoConta: IJsonFaturacaoPublicaConfigEstado;

  public readonly metodoSourceEstadosConta: Array<IMetodoSourceEstadosConta>;
  public readonly mtdOutput: string;

  public partialClifo: Partial<IJsonClifo>;
  public estadoConta: IJsonFaturacaoPublicaConfigEstado;
  public nomeEstadosConta: string;
  public isEdit: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.metodoSourceEstadosConta = [
      {
        valueMetodo: EFEAEstado.Received,
        nameMetodo: 'faturacaopublica.estado.received'
      },
      {
        valueMetodo: EFEAEstado.Paid,
        nameMetodo: 'faturacaopublica.estado.paid'
      }
    ];
    this.mtdOutput = '{{nameMetodo}}';
  }

  public ngOnInit(): void {
    this.estadoConta = merge({conta: '', estado: EFEAEstado.Received}, this.fatPubConfEstadoConta);
    this.partialClifo = {nConta: this.estadoConta.conta};
    const estado = this.estadoConta.estado === EFEAEstado.Received ? 'faturacaopublica.estado.received' : 'faturacaopublica.estado.paid';
    this.nomeEstadosConta = this._plTranslateService.translate(estado);
    this.isEdit = !isEmpty(this.estadoConta.conta);
  }

  public close(): void {
    if (isEmpty(this.estadoConta.conta)) {
      this._plAlertService.error('faturacaopublica.messages.contavazia');
      return;
    }
    if (this.estadoConta.estado !== EFEAEstado.Received && this.estadoConta.estado !== EFEAEstado.Paid) {
      this._plAlertService.error('faturacaopublica.messages.estadodesc');
      return;
    }
    super.close(this.estadoConta);
  }

  public metodoEstadosContaChanged(value: {inputValue: string; item: IMetodoSourceEstadosConta}): void {
    if (isObject(value.item)) {
      this.estadoConta.estado = value.item.valueMetodo;
      this.nomeEstadosConta = value.item.nameMetodo;
    }
  }
}
