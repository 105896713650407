import {deepFreeze} from '../../utilities/utilities';
import type {IPlLocale} from '../locales.interface';

/* eslint-disable @typescript-eslint/naming-convention */

const locale: IPlLocale = deepFreeze<IPlLocale>({
  autocomplete: {
    textSearching: 'searching...',
    textEmpty: 'no results',
    textNotFound: 'not found'
  },
  btn: {
    goBack: 'Previous',
    goForward: 'Next',
    finalize: 'Finish',
    submit: 'Submit',
    search: 'Search',
    ok: 'OK',
    cancel: 'Cancel',
    close: 'Close',
    apply: 'Apply',
    addFilter: 'Add filter'
  },
  calendar: {
    views: {
      month: {
        items: 'Items'
      },
      year: {
        months: 'Months'
      }
    },
    detailsBar: {
      simultaneousEvents: 'Simultaneous Events'
    },
    sidebar: {
      filter: 'Filter'
    }
  },
  datepicker: {
    nextCentury: 'Next century',
    nextDecade: 'Next decade',
    nextMillennium: 'Next millennium',
    nextMonth: 'Next month',
    nextYear: 'Next year',
    previousCentury: 'Previous century',
    previousDecade: 'Previous decade',
    previousMillennium: 'Previous millennium',
    previousMonth: 'Previous month',
    previousYear: 'Previous year',
    toggleContext: 'Toggle view',
    validators: {
      minimumDate: 'This field cannot have a date before {{value}} ',
      maximumDate: 'This field cannot have a date greater than {{value}}'
    }
  },
  mimeTypes: {
    'application/epub+zip': 'EPUB',
    'application/gzip': 'GZip',
    'application/java-archive': 'JAVA',
    'application/json': 'JSON',
    'application/ld+json': 'JSON (LD format)',
    'application/msword': 'DOC (Word)',
    'application/octet-stream': 'BIN',
    'application/ogg': 'OGG',
    'application/pdf': 'PDF',
    'application/php': 'PHP',
    'application/rtf': 'RTF',
    'application/vnd.amazon.ebook': 'AZW',
    'application/vnd.apple.installer+xml': 'MPKG',
    'application/vnd.mozilla.xul+xml': 'XUL',
    'application/vnd.ms-excel': 'XLS (Excel)',
    'application/vnd.ms-fontobject': 'EOT',
    'application/vnd.ms-powerpoint': 'PPT (Powerpoint)',
    'application/vnd.oasis.opendocument.presentation': 'ODP',
    'application/vnd.oasis.opendocument.spreadsheet': 'ODS',
    'application/vnd.oasis.opendocument.text': 'ODT',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPT (Powerpoint)',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLS (Excel)',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOC (Word)',
    'application/vnd.visio': 'VSD',
    'application/x-7z-compressed': '7z',
    'application/x-abiword': 'ABW',
    'application/x-bzip': 'BZ',
    'application/x-bzip2': 'BZ2',
    'application/x-csh': 'CSH',
    'application/x-freearc': 'ARC',
    'application/x-rar-compressed': 'RAR',
    'application/x-sh': 'SH',
    'application/x-shockwave-flash': 'SWF',
    'application/x-tar': 'TAR',
    'application/xhtml+xml': 'XHTML',
    'application/zip': 'ZIP',
    'audio/aac': 'AAC',
    'audio/midi': 'MID(I)',
    'audio/mpeg': 'MP3',
    'audio/ogg': 'OGG',
    'audio/wav': 'WAV',
    'audio/webm': 'WEBM',
    'font/otf': 'OTF',
    'font/ttf': 'TTF',
    'font/woff': 'WOFF',
    'font/woff2': 'WOFF2',
    'image/*': 'images',
    'image/bmp': 'BMP',
    'image/gif': 'GIF',
    'image/jpeg': 'JPG/JPEG',
    'image/png': 'PNG',
    'image/svg+xml': 'SVG',
    'image/tiff': 'TIFF',
    'image/vnd.microsoft.icon': 'ICON',
    'image/webp': 'WEBP',
    'image/x-icon': 'ICON',
    'text/calendar': 'ICS',
    'text/css': 'CSS',
    'text/csv': 'CSV',
    'text/html': 'HTML',
    'text/javascript': 'JS',
    'text/plain': 'TXT',
    'text/xml': 'XML',
    'video/3gpp': '3GP',
    'video/3gpp2': '3G2',
    'video/mp2t': 'TS',
    'video/mpeg': 'MPEG',
    'video/ogg': 'OGV',
    'video/webm': 'WEBM',
    'video/x-msvideo': 'AVI'
  },
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December'
  },
  placeholders: {
    search: 'Search in menu...',
    tableGroupBy: 'Drag a column header here to group by that column'
  },
  plColorPicker: {
    presetLabel: 'Preset colors',
    okButtonText: 'OK',
    cancelButtonText: 'Cancel',
    addColorButtonText: 'Add color',
    presetEmptyMessage: 'No colors added'
  },
  plCookiesConsent: {
    header: 'This website uses cookies',
    footer: 'Cookie declaration last updated on {{value}}',
    necessaryTitle: 'Necessary',
    necessaryDescription:
      'Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.',
    preferencesTitle: 'Preferences',
    preferencesDescription:
      'Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.',
    statisticsTitle: 'Statistics',
    statisticsDescription: 'Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.',
    marketingTitle: 'Marketing',
    marketingDescription:
      'Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.',
    unclassifiedTitle: 'Unclassified',
    unclassifiedDescription: 'Unclassified cookies are cookies that we are in the process of classifying, together with the providers of individual cookies.',
    showDetails: 'Show details',
    hideDetails: 'Hide details',
    ok: 'OK',
    declaration: 'Cookie declaration',
    about: 'About cookies',
    tableHeaderName: 'Name',
    tableHeaderProvider: 'Provider',
    tableHeaderPurpose: 'Purpose',
    tableHeaderExpiresOn: 'Expiry',
    tableHeaderType: 'Type',
    session: 'Session',
    persistent: 'Persistent',
    emptyGroup: 'We do not use cookies of this type.',
    invalidExpireTime: 'Invalid value'
  },
  plDashboard: {
    emptyData: "There's no data to show",
    total: 'Total'
  },
  plMediaDevicesCameraCaptureImage: {
    imageContentAlt: 'Picture preview',
    titleChange: 'Change image',
    titleTabUpload: 'Upload image',
    titleTabCapture: 'Capture image',
    textChange: 'Change',
    textRemove: 'Remove',
    textDisabledTabCapture: 'Your device does not support image capturing.',
    textRetry: 'Retry',
    textCapture: 'Capture',
    textPause: 'Pause',
    textResume: 'Resume',
    errorAbortError: 'An unknown problem has occurred which prevented the camera device from being used.',
    errorNotAllowedError: 'The requested camera device cannot be used at this time.',
    errorNotFoundError: 'No camera device has been found.',
    errorNotReadableError: 'A hardware error occurred which prevented access to the camera device.',
    errorOverconstrainedError: 'No camera device has been found which met the necessary criteria.',
    errorSecurityError: 'Camera capture support has been disabled.',
    errorTypeError: 'An unknown problem has occurred which prevented the camera device from being used.',
    errorTimedOutError: 'The request for necessary permissions has timed out.',
    errorUnavailableMediaDevices: 'Your device does not support image capturing.'
  },
  plMultiSelect: {
    available: 'Available',
    selected: 'Selected'
  },
  plNavbar: {
    toggleMenu: 'Toggle menu'
  },
  plPdf: {
    loadError: 'Error reading the document',
    noDocument: 'No document loaded',
    renderNotSupported: 'Your document has been processed. Please click "Download" to get the document.',
    pages: 'Pages',
    zoomAuto: 'Auto zoom',
    zoomPageActual: 'Real size',
    zoomPageFit: 'Fit to page',
    zoomPageWidth: 'Fit to width',
    zoomIn: 'Zoom in',
    zoomOut: 'Zoom out',
    presentationMode: 'Switch to presentation mode',
    download: 'Download',
    print: 'Print',
    loading: 'Loading document...'
  },
  plSelect: {
    clearAllText: 'Clear All',
    loadingText: 'Loading...',
    notFoundText: 'No items found'
  },
  plSideBar: {
    toggleSideBar: 'Toggle sidebar'
  },
  text: {
    today: 'Today',
    day: 'Day',
    days: 'Days',
    week: 'Week',
    weeks: 'Weeks',
    month: 'Month',
    months: 'Months',
    year: 'Year',
    years: 'Years',
    weekend: 'Weekend',
    weekends: 'Weekends',
    lines: 'Lines',
    close: 'Close',
    now: 'Now',
    clear: 'Clear',
    open: 'Open',
    initialize: 'Initializing',
    noFilters: 'No available filters',
    activeFilters: 'Active filters',
    clearFilters: 'Clear active filters',
    true: 'True',
    false: 'False',
    and: 'and',
    or: 'or',
    of: 'of'
  },
  uploader: {
    defaultMessage: 'Drop files here to upload',
    fileTooBig: 'File is too big, maximum allowed size is: <strong>{{maxFilesize}} MB</strong>.',
    invalidFileType: "You can't upload files of this type.",
    cancelUpload: 'Cancel upload',
    uploadCanceled: 'Upload canceled.',
    removeAllFiles: 'Remove all',
    removeFile: 'Remove',
    maxFileExceeded: 'You can not upload any more files. Only one file is permitted.',
    maxFilesExceeded: 'You can not upload any more files. Only up to {{maxFiles}} files are permitted.',
    acceptedFileType: 'Only {{acceptedFiles}} file type is supported.',
    acceptedFileTypes: 'Only {{acceptedFiles}} file types are supported.',
    maximumFileSize: 'Maximum file size is {{maximumFileSize}}.',
    maximumPerFileSize: 'Maximum size per file is {{maximumFileSize}}.',
    dropHere: 'drop files here',
    clickToUpload: 'click to upload',
    files: 'Files',
    uploadAll: 'Upload all',
    upload: 'Upload',
    rejected: 'File was reject',
    retry: 'Retry upload',
    uploadSuccess: 'Upload successful',
    uploadError: 'There was an error while uploading',
    units: {b: 'B', kb: 'KB', mb: 'MB', gb: 'GB', tb: 'TB', pb: 'PB', eb: 'EB', zb: 'ZB', yb: 'YB'}
  },
  validators: {
    date: 'Invalid date',
    datetimelocal: 'Invalid date',
    email: 'Invalid email',
    equals: 'The value of this field must equal the value of the field {{value}}',
    max: "This field can't have a value greater than {{value}}",
    maxlength: 'This field has a maximum length of {{value}} characters',
    maxSelected: 'This field can not have more than {{value}} selected element(s)',
    min: "This field can't be less than {{value}}",
    minlength: 'This field has a minimum length of {{value}} characters',
    minSelected: 'This field must have at least {{value}} selected element(s)',
    month: 'Invalid month',
    number: 'Invalid number',
    pattern: 'Invalid field',
    required: 'This field is required',
    time: 'Invalid field',
    url: 'Invalid field',
    week: 'Invalid field'
  },
  weekdays: {
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday'
  }
});

/* eslint-enable @typescript-eslint/naming-convention */

export default locale;
