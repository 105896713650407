import {Subscription} from 'rxjs';
import {AfterContentChecked, AfterViewInit, ChangeDetectorRef, ContentChildren, Directive, ElementRef, Injector, Input, OnDestroy, QueryList, Renderer2, TemplateRef} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IPlFormatConfig, Logger, PlDocumentService} from 'pl-comps-angular';
import {AppService} from '../../../services/app/app.service';
import {CGModalContentDirective} from './cgmodal.content.directive';
import {CGModalHeaderDirective} from './cgmodal.header.directive';
import {ConfigService} from '../../../services/config/config.service';
import {IActivePortal} from '../../../services/portals/portals.service.interface';
import {ICGConfigurations} from '../../../services/config/config.service.interface';
import {ICGModalComponent, ICGModalOptions} from './cgmodal.interface';
import {PortalService} from '../../../services/portal/portal.service';

@Directive({
  selector: 'cg-modal'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class CGModalComponent<T> implements AfterContentChecked, AfterViewInit, OnDestroy, ICGModalComponent {
  @Input() public modalOptions: ICGModalOptions<T>;
  public templateHeader: TemplateRef<unknown>;
  public templateContent: TemplateRef<unknown>;

  protected readonly _modalInstance: NgbActiveModal;
  protected readonly _changeDetectorRef: ChangeDetectorRef;
  protected readonly _document: Document;
  protected readonly _renderer: Renderer2;
  protected readonly _logger: Logger;
  protected readonly _appService: AppService;
  protected readonly _configService: ConfigService;

  protected readonly _element: HTMLElement;

  @ContentChildren(CGModalHeaderDirective, {descendants: false}) private readonly _templateHeader: QueryList<CGModalHeaderDirective>;
  @ContentChildren(CGModalContentDirective, {descendants: false}) private readonly _templateContent: QueryList<CGModalContentDirective>;
  private readonly _destroyedController: AbortController;
  private readonly _plDocumentService: PlDocumentService;
  private readonly _portalService: PortalService;
  private readonly _elementRef: ElementRef<HTMLElement>;
  private readonly _subscriptionConfigurations: Subscription;
  private readonly _subscriptionIsMobile: Subscription;
  private readonly _subscriptionAppPortal: Subscription;
  private readonly _subscriptionPageUnload: Subscription;
  private readonly _subscriptionFormat: Subscription;
  private _closeDisabled: boolean;
  private _configurations: ICGConfigurations;
  private _isMobile: boolean;
  private _currentPortal: IActivePortal;
  private _format: IPlFormatConfig;

  constructor(protected readonly _injector: Injector) {
    this._destroyedController = new AbortController();
    this._modalInstance = this._injector.get<NgbActiveModal>(NgbActiveModal);
    this._changeDetectorRef = this._injector.get<ChangeDetectorRef>(ChangeDetectorRef);
    this._document = this._injector.get<Document>(DOCUMENT);
    this._renderer = this._injector.get<Renderer2>(Renderer2);
    this._logger = this._injector.get<Logger>(Logger);
    this._configService = this._injector.get<ConfigService>(ConfigService);
    this._plDocumentService = this._injector.get<PlDocumentService>(PlDocumentService);
    this._appService = this._injector.get<AppService>(AppService);
    this._portalService = this._injector.get<PortalService>(PortalService);
    this._elementRef = this._injector.get<ElementRef<HTMLElement>>(ElementRef);
    this._element = this._elementRef.nativeElement;
    this._closeDisabled = false;
    this.close = this.close.bind(this);
    this.dismiss = this.dismiss.bind(this);
    this._onChangedConfigurations = this._onChangedConfigurations.bind(this);
    this._subscriptionConfigurations = this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      this._configurations = configurations;
      this._onChangedConfigurations();
    });
    this._subscriptionIsMobile = this._plDocumentService.isMobile().subscribe((isMobile: boolean) => {
      this.setIsMobile(isMobile);
    });
    this._subscriptionAppPortal = this._portalService.getAppPortal().subscribe((portal: IActivePortal) => {
      this._currentPortal = portal;
    });
    this._subscriptionPageUnload = this._appService.onPageUnload().subscribe(this._onPageUnload.bind(this));
    this._subscriptionFormat = this._appService.format().subscribe((format: IPlFormatConfig) => {
      this._format = format;
    });
  }

  public ngAfterContentChecked(): void {
    let templateRefHeader: TemplateRef<unknown>;
    let templateRefContent: TemplateRef<unknown>;

    const header: CGModalHeaderDirective = this._templateHeader.first;
    if (header?.templateRef) {
      templateRefHeader = header.templateRef;
    } else if (this.modalOptions) {
      templateRefHeader = this.modalOptions.templateRefHeader;
    }
    this.templateHeader = templateRefHeader;

    const content: CGModalContentDirective = this._templateContent.first;
    if (content?.templateRef) {
      templateRefContent = content.templateRef;
    } else if (this.modalOptions) {
      templateRefContent = this.modalOptions.templateRefContent;
    }
    this.templateContent = templateRefContent;
  }

  public ngAfterViewInit(): void {
    this._changeDetectorRef.detectChanges();
    // Fix modal-content scrolling
    const modalBody = this._element.querySelector<HTMLElement>('.modal-body');
    if (modalBody) {
      let element: HTMLElement = modalBody.parentElement;
      while (element) {
        if (element.classList.contains('modal-content')) {
          break;
        }
        this._renderer.addClass(element, 'component-host-scrollable');
        element = element.parentElement;
      }
    }
  }

  public ngOnDestroy(): void {
    this._destroyedController.abort('Aborted asynchronous operation because the modal component was destroyed before it was completed.');
    this._subscriptionConfigurations.unsubscribe();
    this._subscriptionIsMobile.unsubscribe();
    this._subscriptionAppPortal.unsubscribe();
    this._subscriptionPageUnload.unsubscribe();
    this._subscriptionFormat.unsubscribe();
  }

  public close(result?: T): void | Promise<void> {
    this._modalInstance.close(result);
  }

  public dismiss(reason?: unknown): void | Promise<void> {
    this._modalInstance.dismiss(reason);
  }

  public closeWithArgs(result: T): () => Promise<void> {
    return () => Promise.resolve(this.close(result));
  }

  public dismissWithArgs(reason: unknown): () => Promise<void> {
    return () => Promise.resolve(this.dismiss(reason));
  }

  public beforeDismiss(): boolean | Promise<boolean> {
    return true;
  }

  public enableClose(): void {
    this._closeDisabled = false;
  }

  public disableClose(): void {
    this._closeDisabled = true;
  }

  public setIsMobile(value: boolean): void {
    this._isMobile = value;
  }

  public get destroyed(): boolean {
    return this.abortSignal.aborted;
  }

  public get abortSignal(): AbortSignal {
    return this._destroyedController.signal;
  }

  public get closeDisabled(): boolean {
    return this._closeDisabled;
  }

  public get configurations(): ICGConfigurations {
    return this._configurations;
  }

  public get isMobile(): boolean {
    return this._isMobile;
  }

  public get currentPortal(): IActivePortal {
    return this._currentPortal;
  }

  public get format(): IPlFormatConfig {
    return this._format;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected _onChangedConfigurations(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected _onPageUnload(): void {}
}
