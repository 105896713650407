import moment, {Moment} from 'moment';
import {EFaturaComponent} from './components/eFatura.module.component';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {MODULE_NAME_EFATURA} from './eFatura.module.interface';

const TODAY: Moment = moment();

export const MODULE_EFATURA: IModuleDefinition = {
  name: MODULE_NAME_EFATURA,
  state: {
    url: '/efatura?{year}&{month}',
    component: EFaturaComponent,
    params: {
      year: {
        type: 'int',
        value: TODAY.year(),
        squash: false,
        dynamic: true
      },
      month: {
        type: 'int',
        value: TODAY.month() + 1,
        squash: false,
        dynamic: true
      }
    },
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.efatura',
      icon: 'fa-file-o'
    }
  }
};
