import {TDate} from '../../../../common/dates';
import {
  IAnexoAQuadro4ListaAB,
  IAnexoAQuadro4ListaC,
  IAnexoAQuadro4ListaD,
  IAnexoDBeneficioMontante,
  IAnexoDItem041,
  IAnexoDItem0710,
  IAnexoDItem0710A,
  IAnexoDItem071Aand073Aand074A,
  IAnexoDItem071and073and074,
  IAnexoDItem076,
  IAnexoDItem076A,
  IAnexoDItem077,
  IAnexoDItem078,
  IAnexoDItem078A1,
  IAnexoDItem078A2,
  IAnexoDItem078A3,
  IAnexoDItem079,
  IAnexoDItem08,
  IAnexoEQuadro3Lista,
  IAnexoFQuadro6ListaA,
  IAnexoFQuadro6ListaB,
  IAnexoGQuadro3Lista,
  IAnexoGQuadro4Lista,
  IModelo22AnexoDItem121,
  IModelo22AnexoDItem122,
  IModelo22ItemPeriodoMontante,
  IRostoItemNIFValorPeriodo,
  IRostoItemValorNIF,
  IRostoQuadro14List
} from './modelo22.module.interface';

export enum EArrayIds {
  Tabela907,
  Tabela03,
  Tabela604,
  Tabela605
}

export interface IJsonModelo22RostoItemValorNIF {
  valor: number;
  nif: number;
}

export interface IJsonModelo22RostoItemPeriodoMontante {
  periodo: number;
  montante: number;
}

export interface IJsonModelo22RostoItemNIFValorPeriodo {
  valor: number;
  nif: number;
  periodo: number;
}

export interface IJsonModelo22RostoQuadro14Item {
  tipoRendimentos: string;
  fraccaoImposto: number;
  impostoPago: number;
  saldoNaoDeduzido: number;
  creditoDeImposto: number;
  codPais: string;
  saldoTransita: number;
  deducaoEfetuada: number;
  saldoCaducado: number;
  periodo: number;
  paisComCDT: boolean;
}

export interface IJsonModelo22FicheiroRegisto {
  tipoRegisto: string;
  detalhe: string;
}

export interface IJsonModelo22AnexoAItem04AB {
  distrito: string;
  taxaDerama: number;
  massaSalarial: number;
  racioReparticao: number;
  derramaCalculada: number;
}

export interface IJsonModelo22AnexoAItem04C {
  distrito: string;
  taxaDerama: number;
  massaSalarial: number;
  primeiroAno: boolean;
  areaInstalacao: number;
  potencia: number;
  totalProduzido: number;
  racioMunicipio: number;
  derramaCalculada: number;
}

export interface IJsonModelo22AnexoAItem04D {
  distrito: string;
  taxaDerama: number;
  massaSalarial: number;
  primeiroAno: boolean;
  areaInstalacao: number;
  racioMunicipio: number;
  derramaCalculada: number;
  producao: number;
}

export interface IJsonModelo22AnexoDBeneficioMontante {
  montante: number;
  codBeneficio: number;
}

export interface IJsonModelo22AnexoDItem041 extends IJsonModelo22AnexoDBeneficioMontante {
  nif: number;
}

export interface IJsonModelo22AnexoDItem071and073and074 {
  diploma: number;
  nif: number;
  periodo: number;
  saldoCaducado: number;
  saldoNaoDeduzido: number;
  dotacaoDoPeriodo: number;
  deducaoNoPeriodo: number;
  saldoQueTransita: number;
}

export interface IJsonModelo22AnexoDItem071Aand073Aand074A {
  saldoQueTransita: number;
  diploma: number;
  deducaoUtilizada: number;
  saldoNaoDeduzido: number;
  dotacaoDoPeriodo: number;
  periodo: number;
  saldoCaducado: number;
}

export interface IJsonModelo22AnexoDItem076 {
  saldoQueTransita: number;
  deducaoDoPeriodo: number;
  nif: number;
  saldoNaoDeduzido: number;
  dotacaoDoPeriodo: number;
  periodo: number;
}

export interface IJsonModelo22AnexoDItem076A {
  saldoQueTransita: number;
  deducaoUtilizada: number;
  saldoNaoDeduzido: number;
  dotacaoDoPeriodo: number;
  periodo: number;
}

export interface IJsonModelo22AnexoDItem079 {
  nIdentificacao: string;
  dataInicio: TDate;
  dataConclusao: TDate;
  saldoNaoDeduzido: number;
  valorIncentivo: number;
  deducaoDoPeriodo: number;
  saldoQueTransita: number;
  valorAReembolsar: number;
  valorARepor: number;
}

export interface IJsonModelo22AnexoDItem0710 {
  nIdentificacao: string;
  saldoNaoDeduzido: number;
  montanteBeneficio: number;
  deducaoDoPeriodo: number;
  saldoQueTransita: number;
}

export interface IJsonModelo22AnexoDItem0710A {
  saldoNaoDeduzido: number;
  montanteBeneficio: number;
  deducaoDoPeriodo: number;
  saldoQueTransita: number;
}

export interface IJsonModelo22AnexoDItem077 {
  nif: number;
  diploma: number;
  periodo: number;
  saldo: number;
  dotacao: number;
}

export interface IJsonModelo22AnexoDItem078 {
  codigoBeneficio: number;
  regiao: number;
  cae: string;
  montanteDasAplicacoes: number;
  irc: number;
  imiIMTSelo: number;
  naoFiscais: number;
  total: number;
}

export interface IJsonModelo22AnexoDItem078A1 {
  numeroLinha: number;
  tipo: string;
  numProjecto: string;
  dataInicio: TDate;
  dataFim: TDate;
  tipologia: string;
  identificacao: string;
  regiao: string;
  cae: string;
  montante: number;
  montanteActualizado: number;
}

export interface IJsonModelo22AnexoDItem078A2 {
  numeroLinha: number;
  aplicacoesMontante: number;
  aplicacoesActualizado: number;
  financeiroUsufruido: number;
  financeiroActualizado: number;
  ircUtilizado: number;
  ircActualizado: number;
  imiUtilizado: number;
  imiActualizado: number;
  imtUtilizado: number;
  seloUtilizado: number;
  montanteTotal: number;
}

export interface IJsonModelo22AnexoDItem078A3 {
  numeroLinha: number;
  aplicacoesMontante: number;
  financeiroMontante: number;
  ircMontante: number;
  imiMontante: number;
  imtMontante: number;
  seloMontante: number;
  montanteTotal: number;
  intensidade: number;
  montanteAInscrever: number;
}

export interface IJsonModelo22AnexoDItem08 {
  tipoDonativo: string;
  nif: number;
  valor: number;
}

export interface IJsonModelo22AnexoDItem122 {
  nif: number;
  percentagem: number;
  periodo: number;
  prejuizosSaldo: number;
  prejuizosMontante: number;
}

export interface IJsonModelo22AnexoDItem121 extends IJsonModelo22AnexoDItem122 {
  data: TDate;
}

export interface IJsonModelo22AnexoEList {
  rendimentos: number;
  rendimentosT: number;
  materiaColectavel: number;
}

export interface IJsonModelo22AnexoFList6A {
  codFreguesia: string;
  tipo: string;
  artigo: string;
  fraccao: string;
  dataAquisicao: TDate;
  dataAlienacao: TDate;
  montanteRealizado: number;
  montanteRegimeTransitorio: number;
}

export interface IJsonModelo22AnexoFList6B {
  dataAlienacao: TDate;
  designacao: string;
  dataAquisicao: TDate;
  montante: number;
  imposto: number;
}

export interface IJsonModelo22AnexoGList03 {
  periodo: number;
  montanteDedutiveis: number;
  montanteDeduzidos: number;
  saldo: number;
}

export interface IJsonModelo22AnexoGList04 {
  nIdentificacao: string;
  paisDeRegisto: string;
  regime: string;
  paisDeGestao: string;
  percTripulantes: number;
  arqueacao: number;
  percRendimentos: number;
  numeroDias: number;
  reducaoMateria: number;
  materiaColectavel: number;
}

export interface IJsonModelo22RostoQuadro01 {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  campo1_De: TDate;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  campo1_Ate: TDate;
  campo2: number;
}

export interface IJsonModelo22RostoQuadro02 {
  campo1: string;
  campo2: number;
  cgM22IRCServFinanSede: string;
  cgM22IRCServFinanDirecao: string;
}

/* eslint-disable @typescript-eslint/naming-convention */

export interface IJsonModelo22RostoQuadro03 {
  campo2: number;
  campo3: number;
  campo3A: number;
  campo3A5: boolean;
  campo3A6: boolean;
  campo3B_1: boolean;
  campo3C_1: boolean;
  campo4_10: boolean;
  campo4_11: boolean;
  campo4_12: boolean;
  campo4_13: boolean;
  campo4_1a8: Array<number>;
  campo4_9: number;
  campo4A: number;
  cgM22IRCNomeSujPass: string;
}

export interface IJsonModelo22RostoQuadro04 {
  campo1: number;
  campo1_Data: TDate;
  campo2_11: TDate;
  campo2_1a5e7: Array<number>;
  campo2_6: TDate;
  campo2_8: TDate;
  campo2_9e10: number;
  campo3_1: boolean;
  campo3_2: boolean;
  campo3_3: boolean;
  campo3_4: boolean;
  campo3_5: boolean;
  campo3_6: boolean;
  campo3_7: boolean;
}

/* eslint-enable @typescript-eslint/naming-convention */

export interface IJsonModelo22RostoQuadro05 {
  campo1: number;
  campo2: number;
  campo3: TDate;
  campo4: number;
  campo5: TDate;
  campo6: TDate;
}

export interface IJsonModelo22RostoQuadro07 {
  campo701: number;
  campo702: number;
  campo703: number;
  campo704: number;
  campo705: number;
  campo706: number;
  campo707: number;
  campo708: number;
  campo709: number;
  campo710: number;
  campo711: number;
  campo712: number;
  campo713: number;
  campo714: number;
  campo715: number;
  campo716: number;
  campo717: number;
  campo718: number;
  campo719: number;
  campo720: number;
  campo721: number;
  campo722: number;
  campo723: number;
  campo724: number;
  campo725: number;
  campo726: number;
  campo727: number;
  campo728: number;
  campo729: number;
  campo730: number;
  campo731: number;
  campo732: number;
  campo733: number;
  campo734: number;
  campo735: number;
  campo736: number;
  campo737: number;
  campo738: number;
  campo739: number;
  campo740: number;
  campo741: number;
  campo742: number;
  campo743: number;
  campo744: number;
  campo745: number;
  campo746: number;
  campo747: number;
  campo748: number;
  campo749: number;
  campo750: number;
  campo751: number;
  campo752: number;
  campo753: number;
  campo754: number;
  campo755: number;
  campo756: number;
  campo757: number;
  campo758: number;
  campo759: number;
  campo760: number;
  campo761: number;
  campo762: number;
  campo763: number;
  campo764: number;
  campo765: number;
  campo766: number;
  campo767: number;
  campo768: number;
  campo769: number;
  campo770: number;
  campo771: number;
  campo772: number;
  campo773: number;
  campo774: number;
  campo775: number;
  campo776: number;
  campo777: number;
  campo778: number;
  campo779: number;
  campo780: number;
  campo781: number;
  campo782: number;
  campo783: number;
  campo784: number;
  campo785: number;
  campo786: number;
  campo787: number;
  campo788: number;
  campo789: number;
  campo790: number;
  campo791: number;
  campo792: number;
  campo793: number;
  campo794: number;
  campo795: number;
  campo796: number;
  campo797: number;
  campo798: number;
  campo799: number;
  campo800: number;
  campo801: number;
  campo802: number;
  campo803: number;
  campo804: number;
}

export interface IJsonModelo22RostoQuadro07Desativado {
  campo201: number;
  campo202: number;
  campo203: number;
  campo204: number;
  campo205: number;
  campo206: number;
  campo207: number;
  campo208: number;
  campo209: number;
  campo210: number;
  campo211: number;
  campo212: number;
  campo213: number;
  campo214: number;
  campo215: number;
  campo216: number;
  campo217: number;
  campo218: number;
  campo220: number;
  campo222: number;
  campo223: number;
  campo224: number;
  campo251: number;
  campo252: number;
  campo253: number;
  campo254: number;
  campo225: number;
  campo226: number;
  campo227: number;
  campo228: number;
  campo229: number;
  campo230: number;
  campo255: number;
  campo256: number;
  campo257: number;
  campo258: number;
  campo259: number;
  campo270: number;
  campo271: number;
  campo231: number;
  campo232: number;
  campo233: number;
  campo234: number;
  campo235: number;
  campo236: number;
  campo237: number;
  campo238: number;
  campo239: number;
  campo240: number;
  campo272: number;
  campo273: number;
  campo274: number;
  campo275: number;
  campo276: number;
  campo277: number;
}

export interface IJsonModelo22RostoQuadro08 {
  campo242: boolean;
  campo245: boolean;
  campo269: boolean;
  campo246: boolean;
  campo248: boolean;
  campo249: boolean;
  campo260: boolean;
  campo262: boolean;
  campo263: boolean;
  campo264: boolean;
  campo265: boolean;
  campo266: boolean;
  campo267: boolean;
  campo268: boolean;
  campo247: boolean;
  campo270: boolean;
}

export interface IJsonModelo22RostoQuadro09 {
  campo396List: Array<IRostoItemNIFValorPeriodo>;
  campo398List: Array<IRostoItemNIFValorPeriodo>;
  campo309List: Array<IModelo22ItemPeriodoMontante>;
  campo320List: Array<IModelo22ItemPeriodoMontante>;
  campo331List: Array<IModelo22ItemPeriodoMontante>;
  campo397AList: Array<IRostoItemNIFValorPeriodo>;
  campo397BList: Array<IRostoItemNIFValorPeriodo>;
  campo309BList: Array<IRostoItemNIFValorPeriodo>;
  campo300: number;
  campo301: number;
  campo302: number;
  campo303: number;
  campo309: number;
  campo310: number;
  campo311: number;
  campo312: number;
  campo313: number;
  campo314: number;
  campo320: number;
  campo321: number;
  campo322: number;
  campo323: number;
  campo324: number;
  campo325: number;
  campo331: number;
  campo332: number;
  campo333: number;
  campo336: number;
  campo346: number;
  campo376: number;
  campo380: number;
  campo381: number;
  campo382: number;
  campo383: number;
  campo384: number;
  campo385: number;
  campo386: number;
  campo387: number;
  campo388: number;
  campo389: number;
  campo390: number;
  campo391: number;
  campo392: number;
  campo393: number;
  campo394: number;
  campo395: number;
  campo397: number;
  campo398: number;
  campo398NIF: number;
  campo399: number;
  campo400: number;
  campo401: number;
  campo407: number;
  campo408: number;
  campo409: number;
  campo500: number;
}

export interface IJsonModelo22RostoQuadro10 {
  campo347A: number;
  campo347B: number;
  campo348: number;
  campo349: number;
  campo350: number;
  campo351: number;
  campo353: number;
  campo355: number;
  campo356: number;
  campo357: number;
  campo358: number;
  campo359: number;
  campo360: number;
  campo361: number;
  campo362: number;
  campo363: number;
  campo364: number;
  campo365: number;
  campo366: number;
  campo367: number;
  campo368: number;
  campo369: number;
  campo370: number;
  campo371: number;
  campo372: number;
  campo373: number;
  campo374: number;
  campo375: number;
  campo378: number;
  campo379: number;
  campo470: number;
  taxaDerrama: number;
  consideraPrejuizoFiscal: boolean;
  consideraPrejuizoFiscalChanged: boolean;
}

export interface IJsonModelo22RostoQuadro10A {
  campo366A: number;
  campo366B: number;
}

export interface IJsonModelo22RostoQuadro10B {
  campo1a3: number;
  campo377: number;
  campo377A: number;
  campo377B: number;
  campo4: TDate;
  campo430: number;
  campo431: number;
}

export interface IJsonModelo22RostoQuadro11 {
  campo410: number;
  campo411: number;
  campo416: number;
  campo418: TDate;
  campo423: boolean;
  campo429: boolean;
  campo455: boolean;
}

export interface IJsonModelo22RostoQuadro11A {
  campo460: number;
  campo461: number;
  campo462: number;
  campo463: number;
  campo464: number;
  campo465: TDate;
}

export interface IJsonModelo22RostoQuadro11B {
  campo1: number;
  campo10: boolean;
  campo2: number;
  campo22: number;
  campo3: number;
  campo4: number;
  campo5: number;
  campo67: string;
  campo8: boolean;
  campo9: boolean;
}

export interface IJsonModelo22RostoQuadro12 {
  list: Array<IRostoItemValorNIF>;
}

export interface IJsonModelo22RostoQuadro13 {
  campo414: number;
  campo415: number;
  campo417: number;
  campo420: number;
  campo421: number;
  campo422: number;
  campo424: number;
  campo425: number;
  campo426: number;
  campo427: number;
  campo428: number;
  campo432: number;
  campo433: number;
  campo434: number;
  campo435: number;
  campo436: number;
  campo437: number;
  campo438: number;
  campo439: number;
  campo456: number;
  campoIniciouAtividade: number;
  campo13e3: TDate;
}

export interface IJsonModelo22RostoQuadro13A {
  campo440: number;
  campo441: number;
  campo442: number;
  campo443: number;
  campo444: number;
  campo445: number;
  campo446: number;
  campo447: number;
  campo448: number;
  campo449: number;
  campo450: number;
  campo451: number;
  campo452: number;
  campo453: number;
  campo454: number;
  campo457: number;
}

export interface IJsonModelo22RostoQuadro14 {
  list: Array<IRostoQuadro14List>;
  totalCampo3: number;
  totalCampo6: number;
  totalCampo7: number;
  totalCampo8: number;
  totalCampo10: number;
  totalComCDTCampo3: number;
  totalComCDTCampo6: number;
  totalComCDTCampo7: number;
  totalComCDTCampo8: number;
  totalComCDTCampo10: number;
  totalSemCDTCampo3: number;
  totalSemCDTCampo6: number;
  totalSemCDTCampo7: number;
  totalSemCDTCampo8: number;
  totalSemCDTCampo10: number;
}

export interface IJsonModelo22Rosto {
  numeroDeRegistos: number;
  anoDaDeclaracao: number;
  regimeTributacaoIsencaoDefinitiva: boolean;
  regimeTributacaoIsencaoTemporaria: boolean;
  regimeTributacaoSimplificado: boolean;
  tipoSujeitoPassivoResidenteQueExerce: boolean;
  tipoSujeitoPassivoResidenteQueNaoExerce: boolean;
  tipoSujeitoPassivoNaoResidenteComEstabelecimento: boolean;
  tipoSujeitoPassivoNaoResidenteSemEstabelecimento: boolean;
  empresaClassificadaComoPME: boolean;
  optouPelasTaxasDoRegimeGeral: boolean;
  temAnexoA: boolean;
  temAnexoB: boolean;
  temAnexoC: boolean;
  temAnexoD: boolean;
  temAnexoE: boolean;
  temAnexoF: boolean;
  temAnexoG: boolean;
  taxaPrimeiroEscalao: number;
  taxaSegundoEscalao: number;
  limitePrimeiroEscalao: number;
  regimeGeral: number;
  regimeGeralAtePrimeiroEscalao: number;
  regimeGeralMaiorPrimeiroEscalao: number;
  regimeReducao: number;
  regimeSimplificado: number;
  quadro01: IJsonModelo22RostoQuadro01;
  quadro02: IJsonModelo22RostoQuadro02;
  quadro03: IJsonModelo22RostoQuadro03;
  quadro04: IJsonModelo22RostoQuadro04;
  quadro05: IJsonModelo22RostoQuadro05;
  quadro07: IJsonModelo22RostoQuadro07;
  quadro07Desativado: IJsonModelo22RostoQuadro07Desativado;
  quadro08: IJsonModelo22RostoQuadro08;
  quadro09: IJsonModelo22RostoQuadro09;
  quadro10: IJsonModelo22RostoQuadro10;
  quadro10A: IJsonModelo22RostoQuadro10A;
  quadro10B: IJsonModelo22RostoQuadro10B;
  quadro11: IJsonModelo22RostoQuadro11;
  quadro11A: IJsonModelo22RostoQuadro11A;
  quadro11B: IJsonModelo22RostoQuadro11B;
  quadro12: IJsonModelo22RostoQuadro12;
  quadro13: IJsonModelo22RostoQuadro13;
  quadro13A: IJsonModelo22RostoQuadro13A;
  quadro14: IJsonModelo22RostoQuadro14;
  listaDeErros: Array<string>;
}

export interface IJsonModelo22AnexoAQuadro03 {
  campo01: number;
  campo02: number;
  campo03: number;
  campo04: boolean;
  campo05: number;
  campo06: number;
  campo07: number;
  campo08: number;
  campo09: number;
  campo10: number;
}

export interface IJsonModelo22AnexoAQuadro04 {
  listaA: Array<IAnexoAQuadro4ListaAB>;
  listaB: Array<IAnexoAQuadro4ListaAB>;
  listaC: Array<IAnexoAQuadro4ListaC>;
  listaD: Array<IAnexoAQuadro4ListaD>;
}

export interface IJsonModelo22AnexoA {
  quadro01: number;
  quadro02: number;
  quadro03: IJsonModelo22AnexoAQuadro03;
  quadro04: IJsonModelo22AnexoAQuadro04;
  numeroDeRegistos: number;
  anoDaDeclaracao: number;
  listaDeErros: Array<string>;
}

export interface IJsonModelo22AnexoCQuadro04 {
  campo6: number;
  campo6A: number;
  campo8B: number;
  campo9B: number;
  campo8C: number;
  campo10B: number;
  campo9C: number;
  campo10C: number;
  campo8A: number;
  campo9A: number;
  campo10A: number;
  campo7B: number;
  campo7C: number;
  campo7A: number;
}

export interface IJsonModelo22AnexoCQuadro05 {
  campo21: number;
  campo12: number;
  campo13: number;
  campo11: number;
  campo14: number;
  campo15: number;
}

export interface IJsonModelo22AnexoCQuadro06 {
  campo18B: number;
  campo19B: number;
  campo18A: number;
  campo16: number;
  campo19A: number;
  campo20B: number;
  campo20A: number;
  campo17B: number;
  campo17A: number;
}

export interface IJsonModelo22AnexoCQuadro06A {
  campo22: number;
  campo22A: number;
  campo22B: number;
  campo23A: number;
  campo23B: number;
  campo24A: number;
  campo24B: number;
}

export interface IJsonModelo22AnexoCQuadro07 {
  campo1: number;
  campo2A: number;
  campo2B: number;
  campo3A: number;
  campo3B: number;
  campo4A: number;
  campo4B: number;
  campo5A: number;
  campo5B: number;
}

export interface IJsonModelo22AnexoC {
  quadro01: number;
  quadro02: number;
  quadro04: IJsonModelo22AnexoCQuadro04;
  quadro05: IJsonModelo22AnexoCQuadro05;
  quadro06: IJsonModelo22AnexoCQuadro06;
  quadro06A: IJsonModelo22AnexoCQuadro06A;
  quadro07: IJsonModelo22AnexoCQuadro07;
  numeroDeRegistos: number;
  anoDaDeclaracao: number;
  listaDeErros: Array<string>;
}

export interface IJsonTModelo22AnexoDQuadro03 {
  campo301: number;
  campo302: number;
  campo303: number;
  campo304: number;
  campo305: number;
  campo306: number;
  campo307: number;
  campo308: number;
  campo309: number;
  campo310: number;
  campo311: number;
  campo312: number;
  campo313: number;
  campo314: number;
  campo315: number;
  campo316: number;
  campo317: number;
  campo031AList: Array<IAnexoDBeneficioMontante>;
  campo031BList: Array<IAnexoDBeneficioMontante>;
  campo032AList: Array<IAnexoDBeneficioMontante>;
  campo032BList: Array<IAnexoDBeneficioMontante>;
}

export interface IJsonTModelo22AnexoDQuadro04 {
  campo401: number;
  campo402: number;
  campo403: number;
  campo404: number;
  campo405: number;
  campo406: number;
  campo407: number;
  campo408: number;
  campo409: number;
  campo410: number;
  campo411: number;
  campo412: number;
  campo413: number;
  campo414: number;
  campo415: number;
  campo416: number;
  campo417: number;
  campo418: number;
  campo419: number;
  campo420: number;
  campo421: number;
  campo422: number;
  campo423: number;
  campo424: number;
  campo425: number;
  campo426: number;
  campo427: number;
  campo428: number;
  campo429: number;
  campo430: number;
  campo431: number;
  campo432: number;
  campo433: number;
  campo434: number;
  campo435: number;
  campo436: number;
  campo437: number;
  campo438: number;
  campo439: number;
  campo04AList: Array<IAnexoDBeneficioMontante>;
  campo04B12: boolean;
  campo04B3: number;
  campo04B4: number;
  campo04B5: number;
  campo041List: Array<IAnexoDItem041>;
}

export interface IJsonTModelo22AnexoDQuadro05 {
  campo501: number;
  campo502: number;
}

export interface IJsonTModelo22AnexoDQuadro06 {
  campo601: TDate;
  campo602: number;
  campo603: number;
  campo603List: Array<IModelo22ItemPeriodoMontante>;
  campo604List: Array<number>;
  campo605List: Array<number>;
  campo606: number;
  campo607: number;
  campo608: number;
  campo609: number;
  campo610: number;
  campo611: number;
  campo612: number;
  campo613: number;
  campo614: number;
  campo615: number;
  campo616: number;
  campo617: number;
  campo618: number;
}

export interface ICampo604List {
  id: number;
  cod: number;
}

export interface ICampo605List {
  id: number;
  cod: number;
}

export interface IJsonTModelo22AnexoDQuadro07 {
  campo071List: Array<IAnexoDItem071and073and074>;
  campo071AList: Array<IAnexoDItem071Aand073Aand074A>;
  campo705: number;
  campo706: number;
  campo707: number;
  campo708: number;
  campo073List: Array<IAnexoDItem071and073and074>;
  campo073AList: Array<IAnexoDItem071Aand073Aand074A>;
  campo074List: Array<IAnexoDItem071and073and074>;
  campo074AList: Array<IAnexoDItem071Aand073Aand074A>;
  campo076List: Array<IAnexoDItem076>;
  campo076AList: Array<IAnexoDItem076A>;
  campo079List: Array<IAnexoDItem079>;
  campo079A1: number;
  campo079A2: number;
  campo079A3: number;
  campo079A4: number;
  campo079A5: number;
  campo079A6: number;
  campo079A7: number;
  campo079A8: number;
  campo079A9: number;
  campo0710A: number;
  campo0710B: number;
  campo0710List: Array<IAnexoDItem0710>;
  campo0710AList: Array<IAnexoDItem0710A>;
  campo717: number;
  campo718: number;
  campo719: number;
  campo720: number;
  campo721: number;
  campo726: number;
  campo727: number;
  campo728: number;
  campo077List: Array<IAnexoDItem077>;
  campo078List: Array<IAnexoDItem078>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  campo078_12: boolean;
  campo078A1List: Array<IAnexoDItem078A1>;
  campo078A2List: Array<IAnexoDItem078A2>;
  campo078A3List: Array<IAnexoDItem078A3>;
}

export interface IJsonTModelo22AnexoDQuadro08 {
  lista: Array<IAnexoDItem08>;
}

export interface IJsonTModelo22AnexoDQuadro09 {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  campo901_1: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  campo901_2: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  campo902_1: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  campo902_2: number;
  campo903: number;
  campo904: number;
  campo904A: number;
  campo904B: number;
  campo904C: number;
  campo904D: number;
  campo904E: number;
  campo904F: number;
  campo904G: number;
  campo904H: number;
  campo904I: number;
  campo904J: number;
  campo904K: number;
  campo905: number;
  campo906: number;
  campo907List: Array<number>;
}

export interface ICampo907NifList {
  id: number;
  nif: number;
}

export interface IJsonTModelo22AnexoDQuadro10 {
  campo1001: number;
  campo1002: number;
  campo1003: number;
  campo1004: number;
  campo1005: number;
  campo1006: number;
  campo1007: number;
  campo1008: number;
  campo1009: number;
  campo1010: number;
  campo1011: number;
  campo1012: number;
  campo1013: number;
  campo1014: number;
  campo1015: number;
  campo1016: number;
}

export interface IJsonTModelo22AnexoDQuadro11 {
  campo1111: number;
  campo1112: number;
  campo1113: number;
  campo1114: number;
}

export interface IJsonTModelo22AnexoDQuadro11A {
  campo3List: Array<number>;
}

export interface ICampo03NifList {
  id: number;
  nif: number;
}

export interface IJsonTModelo22AnexoDQuadro12 {
  campo122: boolean;
  lista121: Array<IModelo22AnexoDItem121>;
  lista122: Array<IModelo22AnexoDItem122>;
}

export interface IJsonModelo22AnexoD {
  quadro01: number;
  quadro02: number;
  quadro03: IJsonTModelo22AnexoDQuadro03;
  quadro04: IJsonTModelo22AnexoDQuadro04;
  quadro05: IJsonTModelo22AnexoDQuadro05;
  quadro06: IJsonTModelo22AnexoDQuadro06;
  quadro07: IJsonTModelo22AnexoDQuadro07;
  quadro08: IJsonTModelo22AnexoDQuadro08;
  quadro09: IJsonTModelo22AnexoDQuadro09;
  quadro10: IJsonTModelo22AnexoDQuadro10;
  quadro11: IJsonTModelo22AnexoDQuadro11;
  quadro11A: IJsonTModelo22AnexoDQuadro11A;
  quadro12: IJsonTModelo22AnexoDQuadro12;
  numeroDeRegistos: number;
  anoDaDeclaracao: number;
  taxaBF: number;
  listaDeErros: Array<string>;
}

export interface IJsonModelo22AnexoEQuadro03 {
  campo01: number;
  campo02: number;
  campo03: number;
  campo04: number;
  campo05: number;
  campo0621List: Array<IAnexoEQuadro3Lista>;
  campo07: number;
  campo08: number;
  campo09: number;
  campo10: number;
  campo11: number;
  campo12: number;
  campo13: number;
  campo14: number;
  campo15: number;
  campo16: number;
  campo17: number;
  campo18: number;
  campo19: number;
  campo20: number;
  campo22: number;
  campo23: number;
  campo24: number;
  campo25: number;
  campo26: number;
  campo27: number;
  campo28: number;
  campo29: number;
  campo30: number;
  campo31: number;
  campo32: number;
  campo33: number;
  campo34: number;
  campo40: number;
  campo41: number;
  campo42: number;
  campo35: number;
  campo36: number;
  campo37: number;
  campo38: number;
  campo39: number;
  campo46: number;
  campo06: number;
}

export interface IJsonModelo22AnexoE {
  quadro01: number;
  quadro02: number;
  quadro03: IJsonModelo22AnexoEQuadro03;
  quadro04: TDate;
  numeroDeRegistos: number;
  anoDaDeclaracao: number;
  listaDeErros: Array<string>;
}

export interface IJsonModelo22AnexoFQuadro03 {
  campo01: number;
  campo02: number;
  campo03: number;
  campo04: number;
  campo05: number;
  campo06: number;
  campo07: number;
  campo08: number;
  campo09: number;
  campo10: number;
  campo11: number;
  campo12: number;
  campo13: number;
  campo14: number;
  campo15: number;
  campo16: number;
  campo17: number;
  campo18: number;
  campo19: number;
}

export interface IJsonModelo22AnexoFQuadro04 {
  campo01: number;
  campo02: number;
  campo03: number;
  campo04: number;
  campo05: number;
}

export interface IJsonModelo22AnexoFQuadro05 {
  campo01: number;
  campo02: number;
  campo03: number;
  campo04: number;
}

export interface IJsonModelo22AnexoFQuadro06 {
  lista6A: Array<IAnexoFQuadro6ListaA>;
  campoA09: number;
  campoA10: number;
  lista6B: Array<IAnexoFQuadro6ListaB>;
  campoB06: number;
}

export interface IJsonModelo22AnexoF {
  quadro01: number;
  quadro02: number;
  quadro03: IJsonModelo22AnexoFQuadro03;
  quadro04: IJsonModelo22AnexoFQuadro04;
  quadro05: IJsonModelo22AnexoFQuadro05;
  quadro06: IJsonModelo22AnexoFQuadro06;
  numeroDeRegistos: number;
  anoDaDeclaracao: number;
  listaDeErros: Array<string>;
}

export interface IJsonModelo22AnexoGQuadro03 {
  campo01: number;
  campo02: number;
  campo03: number;
  lista032: Array<IAnexoGQuadro3Lista>;
  campo10: number;
}

export interface IJsonModelo22AnexoGQuadro04 {
  lista: Array<IAnexoGQuadro4Lista>;
  campo11: number;
}

export interface IJsonModelo22AnexoGQuadro05 {
  campo01: number;
  campo02: number;
  campo03: number;
  campo04: number;
}

export interface IJsonModelo22AnexoGQuadro06 {
  campo01: TDate;
  campo0203: boolean;
}

export interface IJsonModelo22AnexoG {
  quadro01: number;
  quadro02: number;
  quadro03: IJsonModelo22AnexoGQuadro03;
  quadro04: IJsonModelo22AnexoGQuadro04;
  quadro05: IJsonModelo22AnexoGQuadro05;
  quadro06: IJsonModelo22AnexoGQuadro06;
  numeroDeRegistos: number;
  anoDaDeclaracao: number;
  listaDeErros: Array<string>;
}

export interface IJsonModelo22 {
  anoDaDeclaracao: number;
  existeGravacaoRosto: boolean;
  existeGravacaoAnexoA: boolean;
  existeGravacaoAnexoC: boolean;
  existeGravacaoAnexoD: boolean;
  existeGravacaoAnexoE: boolean;
  existeGravacaoAnexoF: boolean;
  existeGravacaoAnexoG: boolean;
  rosto: IJsonModelo22Rosto;
  anexoA: IJsonModelo22AnexoA;
  anexoC: IJsonModelo22AnexoC;
  anexoD: IJsonModelo22AnexoD;
  anexoE: IJsonModelo22AnexoE;
  anexoF: IJsonModelo22AnexoF;
  anexoG: IJsonModelo22AnexoG;
  listaDeErros: Array<string>;
}

export interface IJsonModelo22PrejuizosFiscaisDeducoes {
  prejuizosFiscaisDeID: string;
  anoPrejuizoFiscal: number;
  anoDeducao: number;
  valorDeducao: number;
}

export interface IJsonModelo22PrejuizosFiscais {
  ano: number;
  valorPrejuizoFiscal: number;
  valorDeduzido: number;
  valorPorDeduzir: number;
  deducoes: Array<IJsonModelo22PrejuizosFiscaisDeducoes>;
}

export interface IJsonModelo22Prejuizos {
  prejuizos: Array<IJsonModelo22PrejuizosFiscais>;
  modelo22: IJsonModelo22;
}

export interface IJsonModelo22ContasDef {
  nContaDe: string;
  nContaAte: string;
  perioAte: string;
  sinal: boolean;
  valor: number;
}

export interface IJsonModelo22CompDerrama {
  ano: number;
  codConcelho: number;
  nomeConcelho: string;
  postosTrabalhoCriados: number;
  volumeNegocios: number;
  investimentoNoAno: number;
  anoFixacaoDaSede: number;
  temSedeNoConcelho: boolean;
  microEmpresa: boolean;
  pequenaEmpresa: boolean;
  mediaEmpresa: boolean;
  acrescimoTrabalhadores: boolean;
  postosTrabalhoCriados2019: boolean;
  postosTrabalhoCriados2020: boolean;
  listaTipos: Array<number>;
}

export interface IJsonModelo22ConfigWS {
  passwordCC: string;
  usernameCC: string;
  servicoInvocadoPorCC: boolean;
  password: string;
  ccComPoderesDeclarativosContribuinte: boolean;
  username: string;
  isConfigured: boolean;
}

export interface IJsonModelo22SubmeterResponse {
  isOk: boolean;
  temApenasAvisos: boolean;
  message: string;
}
