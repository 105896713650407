<div class="analiticaconfig row">
  <div class="col-12 col-sm-6">
    <pl-group>
      <label [translate]="'analiticaconfig.novoRadical'"></label>
      <edit>
        <pl-edit
          type="cginteger"
          attrName="novoRadical"
          [model]="radicalInt"
          (modelChange)="radicalInt = $event"
          [properties]="{validate: false, placeholder: 'analiticaconfig.novoRadicalPlaceholder'}">
        </pl-edit>
      </edit>
      <actions>
        <button
          type="button"
          class="btn btn-primary btn-sm action-add-line"
          [attr.title]="'analiticaconfig.messages.campoRadicalVazio' | translate"
          [disabled]="!radicalInt && radicalInt !== 0"
          (click)="addLine()">
          <i class="fa fa-fw fa-plus"></i>
        </button>
      </actions>
    </pl-group>

    <dx-data-grid [cgDxDataGrid]="dataGridDefinitionRadicais" cgDxDataGridInstanceName="listaRadicais" [dataSource]="dataSourceRadicalList">
      <div *dxTemplate="let item of 'actions'" class="text-center">
        <button type="button" class="btn btn-danger btn-xs action-remove-line" [click]="fnDeleteLine(item.rowIndex)" plPromise>
          <i class="fa fa-fw fa-minus"></i>
        </button>
      </div>
    </dx-data-grid>
  </div>

  <div class="col-12 col-sm-6">
    <pl-group>
      <label [translate]="'analiticaconfig.novoRadicalExcluido'"></label>
      <edit>
        <pl-edit
          type="cginteger"
          attrName="novoRadicalExcluido"
          [model]="radicalExcluidoInt"
          (modelChange)="radicalExcluidoInt = $event"
          [properties]="{validate: false, placeholder: 'analiticaconfig.novoRadicalExcluidoPlaceholder'}">
        </pl-edit>
      </edit>
      <actions>
        <button
          type="button"
          class="btn btn-primary btn-sm action-add-line"
          [attr.title]="'analiticaconfig.messages.campoRadicalExcluidoVazio' | translate"
          [disabled]="!radicalExcluidoInt && radicalExcluidoInt !== 0"
          (click)="addLineExcluido()">
          <i class="fa fa-fw fa-plus"></i>
        </button>
      </actions>
    </pl-group>

    <dx-data-grid [cgDxDataGrid]="dataGridDefinitionRadicaisExcluido" cgDxDataGridInstanceName="listaRadicaisExcluidos" [dataSource]="dataSourceRadicalExcluidoList">
      <div *dxTemplate="let item of 'actions'" class="text-center">
        <button type="button" class="btn btn-danger btn-xs action-remove-line" [click]="fnDeleteLineExcluido(item.rowIndex)" plPromise>
          <i class="fa fa-fw fa-minus"></i>
        </button>
      </div>
    </dx-data-grid>
  </div>
</div>
