<div class="pl-datepicker">
  <div class="controls">
    <div class="left">
      <i class="fa fa-fw fa-angle-double-left pl-datepicker-btn pl-datepicker-btn-previous-double" [attr.title]="titleDoublePreviousContext" (click)="doublePreviousContext()"></i>
      <i class="fa fa-fw fa-angle-left pl-datepicker-btn pl-datepicker-btn-previous" [attr.title]="titlePreviousContext" (click)="previousContext()"></i>
    </div>

    <span class="date-context" [class.date-context-active]="state !== states.Decade" [attr.title]="titleContext" (click)="toggleContext()">{{ formattedContext }}</span>

    <div class="right">
      <i class="fa fa-fw fa-angle-right pl-datepicker-btn pl-datepicker-btn-next" [attr.title]="titleNextContext" (click)="nextContext()"></i>
      <i class="fa fa-fw fa-angle-double-right pl-datepicker-btn pl-datepicker-btn-next-double" [attr.title]="titleDoubleNextContext" (click)="doubleNextContext()"></i>
    </div>
  </div>

  <ng-container [ngSwitch]="state">
    <div *ngSwitchCase="states.Decade" class="calendar-decades">
      <div
        *ngFor="let decade of decades"
        class="calendar-decade"
        [ngClass]="{disabled: decade.disabled, current: decade.current, selected: decade.selected}"
        (click)="$event.preventDefault(); selectDecade(decade)">
        <span>{{ decade.decadeStr }}</span>
      </div>
    </div>

    <div *ngSwitchCase="states.Year" class="calendar-years">
      <div *ngFor="let year of years" class="calendar-year" [ngClass]="{disabled: year.disabled, current: year.current, selected: year.selected}" (click)="$event.preventDefault(); selectYear(year)">
        <span>{{ year.yearStr }}</span>
      </div>
    </div>

    <div *ngSwitchCase="states.Month" class="calendar-months">
      <div
        *ngFor="let month of months"
        class="calendar-month"
        [ngClass]="{disabled: month.disabled, current: month.current, selected: month.selected}"
        [attr.title]="month.monthStr"
        (click)="$event.preventDefault(); selectMonth(month)">
        <span>{{ month.monthStrShort }}</span>
      </div>
    </div>

    <ng-container *ngSwitchDefault>
      <div class="calendar-days-names">
        <span *ngFor="let dayName of dayNamesShort; index as i" class="calendar-days-name" [attr.title]="dayNames[i]">{{ dayName }}</span>
      </div>

      <div class="calendar-days">
        <div
          *ngFor="let day of days"
          class="calendar-day"
          [ngClass]="{disabled: day.disabled, current: day.current, selected: day.selected, marked: day.marked}"
          (click)="$event.preventDefault(); selectDay(day)">
          <span>{{ day.day }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div *ngIf="showActions && showActionToday && allowActionToday" class="pl-datepicker-actions">
    <button *ngIf="showActionToday && allowActionToday" type="button" class="btn btn-primary btn-sm pl-datepicker-action-today" [attr.title]="formattedToday" (click)="today()">
      <i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;<span>{{ locale.text.today }}</span>
    </button>
  </div>
</div>
