import {Component, Injector, Input} from '@angular/core';
import {IPlTableDefinition, IPlTableOptions, PlTableComponent} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'contab-svat-balancete-modal',
  templateUrl: './contabilidade.svat.balancete.modal.component.html'
})
export class ContabilidadeSvatBalanceteModalComponent extends CGModalComponent<void> {
  @Input() public modalTitle: string;
  @Input() public rubricaTitle: string;
  @Input() public tableDef: IPlTableDefinition;
  @Input() public tableSource: PlTableComponent['source'];
  @Input() public tableOptions: IPlTableOptions;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.modalTitle = '';
    this.rubricaTitle = '';
    this.tableDef = {
      fields: []
    };
    this.tableSource = [];
    this.tableOptions = {};
  }
}
