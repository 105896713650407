import moment, {Moment} from 'moment';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {copy, EMonth, IPlDynamicVisualsSecondaryClickAction, IPlToolbarMenuItem, isArray, isDefined, isObject, isString, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGCardPanelComponent} from '../../../components/cg/cardpanel/cardpanel.component';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {ConciliacaoEFaturasModuleConfigModalComponent} from './modal/config/conciliacaoEFaturas.module.config.modal';
import {ConciliacaoEFaturasModulePesquisaDocPossivelModalComponent} from './modal/pesquisadocpossivel/conciliacaoEFaturas.module.pesquisaDocPossivel.modal';
import {ConciliacaoEFaturasModuleViewDocModalComponent} from './modal/viewdoc/conciliacaoEFaturas.module.viewDoc.modal';
import {ConciliacaoEFaturasService} from './service/conciliacaoEFaturas.service';
import {devExpressDataGridExpandDetailHandler} from '../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {EFATURA_TIPO_FATURA_TODOS, IEFaturaDocDetails, IEFaturaDocumento, IEFaturaDocumentoLinha, IEFaturaTipoFatura} from '../importadorefatura/service/importadorEfatura.service.interface';
import {ENTITY_NAME_FORNECEDORES} from '../../../entities/clifos/clifos.entity.interface';
import {
  ETipoItemJaLancado,
  IConciliacaoEFaturasConnectDocument,
  IConciliacaoEFaturasFilter,
  IConciliacaoEFaturasFilterTipo,
  IVerificaEFaturaItem,
  IVerificaEFaturaSource,
  TABLE_LEGEND_DOCUMENTOS_JA_LANACADOS,
  TABLE_LEGEND_DOCUMENTOS_SEM_RELACAO
} from './conciliacaoEFaturas.module.interface';
import {EXCEPTION_VERIFICA_EFATURAS_CALL_LOGIN} from '../../../../common/exceptions';
import {IContabilidadeExtratosGridParams, MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID} from '../estatistica/extratosgrid/contabilidade.extratosGrid.module.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnInitialized
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonClifo} from '../../../entities/clifos/jsonClifo.entity.interface';
import {IJsonEfaturaConfig} from '../../../interfaces/jsonImportadorEfatura.interface';
import {IModuleMaintenanceInstance} from '../../../components/entity/maintenance/module/module.maintenance.interface';
import {IPesquisaDocsLinhaDocContabilidadeCab} from '../../../components/contabilidade/pesquisaDocsLinha/pesquisaDocsLinha.component.interface';
import {ModuleMaintenanceService} from '../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloComponent} from '../../../components/module/module.component';
import {SimpleLoginModalComponent} from '../../simplelogin/modals/simpleLogin.modal.component';
import {TDate} from '../../../../common/dates';
import {ConfigEfaturaDocPorLancSupplierModalComponent} from '../../configEfaturaDocPorLancar/modals/supplier/configEfaturaDocPorLanc.supplier.modal.component';
import {DevExpressDataGridUIService} from '../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {ICGExceptionError} from '../../../components/exceptions/exceptions.service.interface';
import {TTableLegend} from '../../../components/tablelegend/tablelegend.component.interface';
import {EDataGridLegendCellColors} from '../../../components/devexpress/datagrid/configuration/devexpress.datagrid.configuration';

const EFATURA_TAB_ID = 'tabDocsEFatura';
const JA_LANCADOS_TAB_ID = 'tabJaLancados';

@Component({
  selector: 'module-conciliacao-efaturas',
  templateUrl: './conciliacaoEFaturas.module.component.html'
})
export class ConciliacaoEFaturasModuleComponent extends ModuloComponent implements OnInit {
  @Input() public tiposFatura: Array<IEFaturaTipoFatura>;
  @Input() public empresaTemContabilidadeDigital: boolean;

  public entityFornecedoresName: string;
  public showOnlyWithoutPossibleDocs: boolean;
  public isAssociarTodosPossiveisVisible: boolean;
  public tableSource: Array<IVerificaEFaturaItem>;
  public tableSourceLancIgnored: Array<IVerificaEFaturaItem>;
  public filters: IConciliacaoEFaturasFilter;
  public definitionsDataGrid: {
    mainDef: IDevExpressDataGrid<IVerificaEFaturaItem, string>;
    tableLancIgnored: IDevExpressDataGrid<IVerificaEFaturaItem, IVerificaEFaturaItem>;
    detalhes: IDevExpressDataGrid<IEFaturaDocumentoLinha, IEFaturaDocumentoLinha>;
    possiveisDocs: IDevExpressDataGrid<IConciliacaoEFaturasConnectDocument, IConciliacaoEFaturasConnectDocument>;
    semelhantesDocs: IDevExpressDataGrid<IConciliacaoEFaturasConnectDocument, IConciliacaoEFaturasConnectDocument>;
  };
  public mainDataGridInstanceName: string;
  public jaLancDataGridInstanceName: string;
  public activeTabId: string;
  public efaturaTabId: string;
  public jaLancadosTabId: string;
  public documentosSemRelacaoDataGridLegend: TTableLegend;
  public documentosJaLancadosDataGridLegend: TTableLegend;

  private readonly _maintenanceExtratosGrid: IModuleMaintenanceInstance;
  private _verificaEFaturaSource: IVerificaEFaturaSource;
  private _bkpTableSource: Array<IVerificaEFaturaItem>;
  private _cardPanel: CGCardPanelComponent;
  private _dataGridJaLancInstance: dxDataGrid<IVerificaEFaturaItem, IVerificaEFaturaItem>;
  private _dataGridInstance: dxDataGrid<IVerificaEFaturaItem, string>;
  private _listContainDocsPossiveis: boolean;
  private _firstCall: boolean;
  private _fetch: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _conciliacaoEFaturasService: ConciliacaoEFaturasService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService
  ) {
    super(_injector);
    this.search = this.search.bind(this);
    this._maintenanceExtratosGrid = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID);
    this.entityFornecedoresName = ENTITY_NAME_FORNECEDORES;
    this.mainDataGridInstanceName = this.instanceName;
    this.jaLancDataGridInstanceName = `${this.instanceName}_JALANC`;
    this.efaturaTabId = EFATURA_TAB_ID;
    this.jaLancadosTabId = JA_LANCADOS_TAB_ID;
    this.activeTabId = EFATURA_TAB_ID;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._fetch = false;
    this._firstCall = true;
    this.tableSource = [];
    this.tableSourceLancIgnored = [];
    this._bkpTableSource = [];
    this.showOnlyWithoutPossibleDocs = false;
    this.isAssociarTodosPossiveisVisible = false;
    this._listContainDocsPossiveis = false;
    this.documentosSemRelacaoDataGridLegend = TABLE_LEGEND_DOCUMENTOS_SEM_RELACAO;
    this.documentosJaLancadosDataGridLegend = TABLE_LEGEND_DOCUMENTOS_JA_LANACADOS;

    if (!isArray(this.tiposFatura)) {
      this.tiposFatura = [{...EFATURA_TIPO_FATURA_TODOS}];
    }

    const fromDate: Moment = moment().startOf('year');
    const toDate: Moment = fromDate.clone().month(EMonth.February).endOf('month');
    this.filters = {
      fromDate: fromDate,
      toDate: toDate,
      tipo: this.tiposFatura[0],
      nifFornecedor: '',
      ...this.filters
    };

    this.tableSource = [];

    this.definitionsDataGrid = {
      mainDef: {
        keyExpr: 'numerodocumento',
        columns: [
          {dataField: 'nifEmitente', caption: 'conciliacaoEFaturas.fields.nifEmitente'},
          {dataField: 'nomeEmitente', caption: 'conciliacaoEFaturas.fields.nomeEmitente', width: 250},
          {dataField: 'numerodocumento', caption: 'conciliacaoEFaturas.fields.numerodocumento', width: 140},
          {
            dataField: 'dataEmissaoDocumento',
            caption: 'conciliacaoEFaturas.fields.dataEmissaoDocumento',
            dataType: 'date',
            width: 120
          },
          {
            dataField: 'tipoDocumentoAbbr',
            caption: 'conciliacaoEFaturas.fields.tipoDocumentoDesc',
            hidingPriority: 1,
            width: 90,
            alignment: 'center'
          },
          {
            dataField: 'valorTotalBaseTributavel',
            caption: 'conciliacaoEFaturas.fields.valorTotalBaseTributavel',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          {
            dataField: 'valorTotalIva',
            caption: 'conciliacaoEFaturas.fields.valorTotalIva',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          {
            dataField: 'valorTotal',
            caption: 'conciliacaoEFaturas.fields.valorTotal',
            dataType: 'number',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          {
            dataField: 'numPossiveisDocs',
            caption: 'conciliacaoEFaturas.fields.numPossiveisDocsAbbr',
            dataType: 'number',
            alignment: 'center',
            cellTemplate: 'numPossiveisDocsTmpl',
            width: 130
          },
          {
            dataField: 'numSemelhantesDocs',
            caption: 'conciliacaoEFaturas.fields.numSemelhantesDocsAbbr',
            dataType: 'number',
            alignment: 'center',
            cellTemplate: 'numSemelhantesDocsTmpl',
            width: 130
          },
          {
            dataField: 'docDigital',
            caption: 'conciliacaoEFaturas.fields.docDigitalAbbr',
            dataType: 'number',
            alignment: 'center',
            cellTemplate: 'docDigitalTmpl',
            visible: this.empresaTemContabilidadeDigital,
            showInColumnChooser: this.empresaTemContabilidadeDigital
          },
          {
            dataField: 'vf_actions',
            caption: ' ',
            cellTemplate: 'actions',
            allowHiding: false,
            allowSearch: false,
            allowReordering: false,
            allowSorting: false,
            allowFiltering: false,
            allowResizing: false,
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowFixing: false,
            allowEditing: false,
            allowExporting: false
          }
        ],
        masterDetail: {
          enabled: true,
          template: 'detail'
        },
        paging: {
          pageSize: 50
        }
      },
      tableLancIgnored: {
        columns: [
          {
            dataField: 'vf_actions',
            caption: ' ',
            cellTemplate: 'lancIgnoredActions',
            allowHiding: false,
            allowSearch: false,
            allowReordering: false,
            allowSorting: false,
            allowFiltering: false,
            allowResizing: false,
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowFixing: false,
            allowEditing: false,
            allowExporting: false
          },
          {dataField: 'documentoLancado', caption: 'conciliacaoEFaturas.fields.documentoLancado'},
          {dataField: 'nifEmitente', caption: 'conciliacaoEFaturas.fields.nifEmitente'},
          {dataField: 'nomeEmitente', caption: 'conciliacaoEFaturas.fields.nomeEmitente', width: 250},
          {dataField: 'numerodocumento', caption: 'conciliacaoEFaturas.fields.numerodocumento', width: 140},
          {dataField: 'dataEmissaoDocumento', caption: 'conciliacaoEFaturas.fields.dataEmissaoDocumento', dataType: 'date'},
          {dataField: 'tipoDocumentoDesc', caption: 'conciliacaoEFaturas.fields.tipoDocumentoDesc', hidingPriority: 2},
          {dataField: 'estadoBeneficioDesc', caption: 'conciliacaoEFaturas.fields.estadoBeneficioDesc', hidingPriority: 1},
          {dataField: 'actividadeProfDesc', caption: 'conciliacaoEFaturas.fields.actividadeProfDesc', hidingPriority: 0},
          {
            dataField: 'valorTotalBaseTributavel',
            caption: 'conciliacaoEFaturas.fields.valorTotalBaseTributavel',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          {
            dataField: 'valorTotalIva',
            caption: 'conciliacaoEFaturas.fields.valorTotalIva',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          {
            dataField: 'valorTotal',
            caption: 'conciliacaoEFaturas.fields.valorTotal',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          {
            dataField: 'docDigital',
            caption: 'conciliacaoEFaturas.fields.docDigitalAbbr',
            dataType: 'number',
            alignment: 'center',
            cellTemplate: 'docDigitalTmpl',
            visible: this.empresaTemContabilidadeDigital,
            showInColumnChooser: this.empresaTemContabilidadeDigital
          },
          {
            dataField: 'vf_verDocLink',
            caption: ' ',
            cellTemplate: 'lancIgnoredLinkVerDoc',
            allowHiding: false,
            allowSearch: false,
            allowReordering: false,
            allowSorting: false,
            allowFiltering: false,
            allowResizing: false,
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowFixing: false,
            allowEditing: false,
            allowExporting: false
          }
        ],
        paging: {
          pageSize: 50
        }
      },
      detalhes: {
        filterRow: {
          visible: false
        },
        headerFilter: {
          visible: false
        },
        searchPanel: {
          visible: false
        },
        columns: [
          {
            dataField: 'valorBaseTributavel',
            caption: 'conciliacaoEFaturas.fields.baseTributavel',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          {
            dataField: 'taxaIva',
            caption: 'conciliacaoEFaturas.fields.taxa',
            format: "#0.##'%'",
            alignment: 'right'
          },
          {
            dataField: 'valorIva',
            caption: 'conciliacaoEFaturas.fields.valorTotalIva',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          {
            dataField: 'valorTotal',
            caption: 'conciliacaoEFaturas.fields.total',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          }
        ],
        pager: {
          visible: false
        },
        paging: {
          pageSize: 20
        }
      },
      possiveisDocs: {
        columns: [
          {
            dataField: 'vf_link',
            cellTemplate: 'posLinkAssociar',
            caption: ' ',
            allowHiding: false,
            allowSearch: false,
            allowReordering: false,
            allowSorting: false,
            allowFiltering: false,
            allowResizing: false,
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowFixing: false,
            allowEditing: false,
            allowExporting: false
          },
          {dataField: 'nDocInterno', caption: 'conciliacaoEFaturas.fields.nDocInterno'},
          {dataField: 'nDocExterno', caption: 'conciliacaoEFaturas.fields.nDocExterno'},
          {
            dataField: 'valor',
            caption: 'conciliacaoEFaturas.fields.valor',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          {dataField: 'dataDocExterno', caption: 'conciliacaoEFaturas.fields.dataDocExterno', dataType: 'date'},
          {
            dataField: 'vf_actions',
            cellTemplate: 'posActions',
            caption: ' ',
            allowHiding: false,
            allowSearch: false,
            allowReordering: false,
            allowSorting: false,
            allowFiltering: false,
            allowResizing: false,
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowFixing: false,
            allowEditing: false,
            allowExporting: false
          }
        ],
        paging: {
          pageSize: 20
        }
      },
      semelhantesDocs: {
        columns: [
          {
            dataField: 'vf_link',
            cellTemplate: 'semDocsLinkAssociar',
            caption: ' ',
            allowHiding: false,
            allowSearch: false,
            allowReordering: false,
            allowSorting: false,
            allowFiltering: false,
            allowResizing: false,
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowFixing: false,
            allowEditing: false,
            allowExporting: false
          },
          {dataField: 'nDocInterno', caption: 'conciliacaoEFaturas.fields.nDocInterno'},
          {dataField: 'nDocExterno', caption: 'conciliacaoEFaturas.fields.nDocExterno'},
          {
            dataField: 'valor',
            caption: 'conciliacaoEFaturas.fields.valor',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          {dataField: 'dataDocExterno', caption: 'conciliacaoEFaturas.fields.dataDocExterno', dataType: 'date'},
          {
            dataField: 'vf_actions',
            cellTemplate: 'semDocsActions',
            caption: ' ',
            allowHiding: false,
            allowSearch: false,
            allowReordering: false,
            allowSorting: false,
            allowFiltering: false,
            allowResizing: false,
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowFixing: false,
            allowEditing: false,
            allowExporting: false
          }
        ],
        paging: {
          pageSize: 20
        }
      }
    };

    const momObj: Moment = moment(this._configService.configurations.empresa.periodo, 'YYYYMMD');
    if (isDefined(momObj) && momObj.isValid()) {
      this.filters.fromDate = momObj.clone().startOf('month');
      this._fillToDate();
    }

    const btnConfigEntidades: IPlToolbarMenuItem = {
      id: 'configEntidade',
      type: 'button',
      caption: 'importadorEfatura.configPreDefinidosTitle',
      click: () => this.openConfigPreDefinidosSupplierModal()
    };

    this.toolbar.addButton({
      id: 'configPreDefinidosTbBtn',
      type: 'dropdown-split',
      order: 5,
      iconLeft: '<i class="fa fa-fw fa-cog"></i>&nbsp;',
      class: 'btn-light',
      caption: 'global.btn.config',
      click: () => this._openConfig(),
      menu: [btnConfigEntidades]
    });
  }

  public loadAtTipos(): void {
    this._conciliacaoEFaturasService.getAtTiposList().then((response: HttpResponse<Array<IEFaturaTipoFatura>>) => {
      if (response.body?.length) {
        this.tiposFatura = [{id: -1, tipo: 'TODOS'}, ...response.body];
      }
    });
  }

  public changeFornecedor(fornecedor: IJsonClifo | string): void {
    this.filters.nifFornecedor = isString(fornecedor) ? fornecedor : fornecedor?.nContribuint;
  }

  public async pesquisar(fetch: boolean = false): Promise<void> {
    if (!this._checkInputFields()) {
      await Promise.reject();
    }
    this._dataGridInstance?.beginCustomLoading(undefined);
    this._dataGridJaLancInstance?.beginCustomLoading(undefined);
    this._firstCall = false;
    this._fetch = fetch;
    this._verificaEFaturaSource = await this.getTableSources();
    this.showOnlyWithoutPossibleDocs = false;
    this._bkpTableSource = [];
    this._evaluateTableSourceMain();
    this.tableSourceLancIgnored = this._verificaEFaturaSource.listLancadosIgnorados;
    this._dataGridInstance?.endCustomLoading();
    this._dataGridJaLancInstance?.endCustomLoading();
  }

  public async getTableSources(): Promise<IVerificaEFaturaSource> {
    if (this._firstCall || (!this._fetch && !this.tableSource.length)) {
      return {list: [], total: 0, listLancadosIgnorados: [], cache: false};
    }
    if (!this.tableSource.length || this._fetch) {
      const tipo: IConciliacaoEFaturasFilterTipo = isDefined(this.filters.tipo) ? this.filters.tipo : undefined;
      try {
        const response: HttpResponse<IVerificaEFaturaSource> = await this._conciliacaoEFaturasService.getAtList(this.filters.fromDate, this.filters.toDate, tipo, this.filters.nifFornecedor);
        return response.body;
      } catch (reason) {
        const cgException: ICGExceptionError = this._cgExceptionService.get(reason);
        if (cgException.class === EXCEPTION_VERIFICA_EFATURAS_CALL_LOGIN) {
          this._cgModalService.show(SimpleLoginModalComponent, {size: 'sm', backdrop: 'static'}).then(() => {
            this._plAlertService.success('simpleLogin.messages.successLogin');
            this.pesquisar(true);
          });
        } else {
          this._plAlertService.error(cgException.message);
        }
        return {list: [], total: 0, listLancadosIgnorados: []};
      }
    }
    return {list: this.tableSource, total: this.tableSource.length, listLancadosIgnorados: this.tableSourceLancIgnored, cache: true};
  }

  public async search(): Promise<void> {
    try {
      await this.pesquisar();
      if (this.tableSource?.length) {
        this._cardPanel.collapse();
      } else {
        this._cardPanel.focusFirstElement();
        this._plAlertService.info('global.text.searchNoData');
      }
    } catch (e) {
      this._cardPanel.focusFirstElement();
    }
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized<IVerificaEFaturaItem, string>): void {
    this._dataGridInstance = component;
  }

  public onDetalhesDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized, item: IVerificaEFaturaItem): void {
    item.detalhesDataGridInstance = component;
  }

  public onJaLancDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized<IVerificaEFaturaItem, IVerificaEFaturaItem>): void {
    this._dataGridJaLancInstance = component;
  }

  public onDataGridCellClick(event: IDevExpressDataGridEventOnCellClick<IVerificaEFaturaItem, string>): void {
    if (event.rowType === 'data' && event.column.dataField !== 'vf_actions') {
      devExpressDataGridExpandDetailHandler(event, () => this._onRowExpand(event.data));
    }
  }

  public onDataGridContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IEFaturaDocumento, string>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(event.row.data);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public switchChange(value: boolean): void {
    this.showOnlyWithoutPossibleDocs = value;
    if (this._firstCall) {
      return;
    }
    this._evaluateTableSourceMain();
  }

  public async associarDoc(item: IConciliacaoEFaturasConnectDocument): Promise<void> {
    await this._associar(item.periodo, item.nDiario, item.nDocInterno, item.nDocExterno, item.nifEmitente, item.numerodocumento);
  }

  public async associarDocSemAlterarDocExterno(item: IConciliacaoEFaturasConnectDocument): Promise<void> {
    await this._associarSemAlterarDocExterno(item.nifEmitente, item.numerodocumento, item.periodo, item.nDiario, item.nDocInterno, item.ano);
  }

  public async associarContabDoc(doc: IEFaturaDocumento, item: IPesquisaDocsLinhaDocContabilidadeCab): Promise<void> {
    await this._associar(item.periodo, item.nDiario, item.nDocInterno, item.documentoExterno, doc.nifEmitente, doc.numerodocumento);
  }

  public async extratoConta(item: IEFaturaDocumento): Promise<void> {
    const response: HttpResponse<string> = await this._conciliacaoEFaturasService.obterContaDoNif(item.nifEmitente);
    const nconta: string = response.body;
    let params: IContabilidadeExtratosGridParams;
    if (nconta.length) {
      params = {nConta: nconta};
    }
    await this._maintenanceExtratosGrid.maintenance({params: params});
  }

  public async ignoreDoc(item: IEFaturaDocumento): Promise<void> {
    await this._conciliacaoEFaturasService.ignorarDocumento(item.nifEmitente, item.numerodocumento);
    this._plAlertService.success(this._plTranslateService.translate('conciliacaoEFaturas.messages.docIgnoradoSuccessMsg', {ndoc: item.numerodocumento}));
    await this.pesquisar(true);
  }

  public async ignoreFornecedor(item: IEFaturaDocumento): Promise<void> {
    await this._conciliacaoEFaturasService.ignorarSempreFornecedor(item.nifEmitente, item.nomeEmitente);
    this._plAlertService.success(
      this._plTranslateService.translate('conciliacaoEFaturas.messages.fornecedorIgnoradoSuccessMsg', {
        nif: item.nifEmitente,
        nome: item.nomeEmitente
      })
    );
    await this.pesquisar(true);
  }

  public async openConfigPreDefinidosSupplierModal(item: IEFaturaDocumento = undefined): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConfigEfaturaDocPorLancSupplierModalComponent, {size: 'lg'});
    const componentInstance: ConfigEfaturaDocPorLancSupplierModalComponent = modalInstance.componentInstance;
    componentInstance.nContribuinte = item ? item.nifEmitente : '';
    componentInstance.nomeFornecedor = item ? item.nomeEmitente : '';
    await modalInstance.result;
    await this.pesquisar(true);
  }

  public viewDoc(item: IConciliacaoEFaturasConnectDocument): void {
    const modalRef = this._cgModalService.showVanilla(ConciliacaoEFaturasModuleViewDocModalComponent);
    const componentInstance: ConciliacaoEFaturasModuleViewDocModalComponent = modalRef.componentInstance;
    componentInstance.dirtyDoc = item;
  }

  public async removeAssociacao(item: IEFaturaDocumento): Promise<void> {
    await this._conciliacaoEFaturasService.removeAssociacao(item.nifEmitente, item.numerodocumento);
    this._plAlertService.success(this._plTranslateService.translate('conciliacaoEFaturas.messages.docRemAssociacaoSuccessMsg', {ndoc: item.numerodocumento}));
    await this.pesquisar(true);
  }

  public async deixarIgnorarDoc(item: IEFaturaDocumento): Promise<void> {
    await this._conciliacaoEFaturasService.deixarIgnorarDoc(item.nifEmitente, item.numerodocumento);
    this._plAlertService.success(this._plTranslateService.translate('conciliacaoEFaturas.messages.docRemIgnoringSuccessMsg', {ndoc: item.numerodocumento}));
    await this.pesquisar(true);
  }

  public async deixarIgnorarFornecedor(item: IEFaturaDocumento): Promise<void> {
    await this._conciliacaoEFaturasService.deixarIgnorarFornecedor(item.nifEmitente);
    this._plAlertService.success(this._plTranslateService.translate('conciliacaoEFaturas.messages.docRemIgnoringFornecedorSuccessMsg', {nif: item.nifEmitente}));
    await this.pesquisar(true);
  }

  public openPesquisaDocPossivelModal(item: IEFaturaDocumento): void {
    const modalRef = this._cgModalService.showVanilla(ConciliacaoEFaturasModulePesquisaDocPossivelModalComponent);
    const componentInstance: ConciliacaoEFaturasModulePesquisaDocPossivelModalComponent = modalRef.componentInstance;
    componentInstance.doc = item;
    modalRef.result.then((assocDoc: IPesquisaDocsLinhaDocContabilidadeCab) => {
      this.associarContabDoc(item, assocDoc);
    });
  }

  public isIgnoradoPorFornecedor(item: IVerificaEFaturaItem): boolean {
    return item.tipoItemJaLancado !== ETipoItemJaLancado.IgnoradoPorFornecedor;
  }

  public isAssociadoSemNDocExterno(item: IVerificaEFaturaItem): boolean {
    return item.tipoItemJaLancado !== ETipoItemJaLancado.AssociadoSemNDocExterno;
  }

  public isIgnorado(item: IVerificaEFaturaItem): boolean {
    return item.tipoItemJaLancado !== ETipoItemJaLancado.Ignorado;
  }

  public isLigacaoDiretaNIFNDocExterno(item: IVerificaEFaturaItem): boolean {
    return item.tipoItemJaLancado === ETipoItemJaLancado.LigacaoDiretaNIFNDocExterno;
  }

  public fromDateChanged(value: TDate): void {
    this.filters.fromDate = moment(value);
    this._fillToDate();
  }

  public associarTodosPossiveis(): void {
    if (this.tableSource.length === 0) {
      this._cgModalService.showOkCancel('conciliacaoEFaturas.associarTodosPossiveisNoSourceTitle', 'conciliacaoEFaturas.associarTodosPossiveisNoSourceMsg', {size: 'md'});
      return;
    }
    this._cgModalService.showOkCancel('global.text.attention', 'conciliacaoEFaturas.associarTodosPossiveisPromptMsg', {size: 'md'}).then(() => {
      const list = Array<IConciliacaoEFaturasConnectDocument>();
      for (const item of this.tableSource) {
        if (item.possiveisDocs.length === 1) {
          const listItem: IConciliacaoEFaturasConnectDocument = {
            ano: item.possiveisDocs[0].ano,
            dataDocExterno: item.possiveisDocs[0].dataDocExterno,
            extPocCabID: item.possiveisDocs[0].extPocCabID,
            nDiario: item.possiveisDocs[0].nDiario,
            nDocExterno: item.possiveisDocs[0].nDocExterno,
            nDocInterno: item.possiveisDocs[0].nDocInterno,
            nifEmitente: item.possiveisDocs[0].nifEmitente,
            numerodocumento: item.possiveisDocs[0].numerodocumento,
            periodo: item.possiveisDocs[0].periodo,
            valor: item.possiveisDocs[0].valor
          };
          list.push(listItem);
        }
      }
      if (list.length === 0) {
        this._cgModalService.showOkCancel('conciliacaoEFaturas.associarTodosPossiveisNoSourceTitle', 'conciliacaoEFaturas.associarTodosPossiveisNoSourceMsg', {size: 'md'});
      } else {
        this._conciliacaoEFaturasService.associarTodosDocComUmDocPossivel(list).then(() => {
          this._plAlertService.success(this._plTranslateService.translate('conciliacaoEFaturas.messages.docsAssociadoSuccessMsg'));
          this.pesquisar(true);
        });
      }
    });
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IVerificaEFaturaItem, string>): void {
    if (event.rowType === 'header') {
      let element;
      switch (event.column.dataField) {
        case 'numPossiveisDocs':
          element = event.cellElement.querySelector('.dx-datagrid-text-content');
          if (element) {
            element.setAttribute('title', this._translateService.instant('conciliacaoEFaturas.fields.numPossiveisDocs'));
          }
          break;
        case 'numSemelhantesDocs':
          element = event.cellElement.querySelector('.dx-datagrid-text-content');
          if (element) {
            element.setAttribute('title', this._translateService.instant('conciliacaoEFaturas.fields.numSemelhantesDocs'));
          }
          break;
      }
    } else if (event.rowType === 'data' && event.data.numPossiveisDocs === 0) {
      event.cellElement.classList.add(EDataGridLegendCellColors.RedLegendColor);
    }
  }

  public changedTab(): void {
    if (this._cardPanel.collapsed) {
      this._cardPanel.expand();
    }
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _fillToDate(): void {
    this.filters.toDate = moment(this.filters.fromDate).clone().endOf('month');
  }

  private async _associar(periodo: string, nDiario: number, nDocInterno: string, nDocExterno: string, nif: string, nDocumento: string): Promise<void> {
    await this._conciliacaoEFaturasService.associar(periodo, nDiario, nDocInterno, nDocExterno, nif, nDocumento);
    this._plAlertService.success(this._plTranslateService.translate('conciliacaoEFaturas.messages.docAssociadoSuccessMsg', {ndoc: nDocumento}));
    await this.pesquisar(true);
  }

  private async _associarSemAlterarDocExterno(nif: string, nDocumento: string, periodo: string, nDiario: number, nDocInterno: string, ano: number): Promise<void> {
    await this._conciliacaoEFaturasService.associarSemAlterarDocExterno(nif, nDocumento, periodo, nDiario, nDocInterno, ano);
    this._plAlertService.success(this._plTranslateService.translate('conciliacaoEFaturas.messages.docAssociadoSuccessMsg', {ndoc: nDocumento}));
    await this.pesquisar(true);
  }

  private async _getDocDetails(item: IEFaturaDocumento): Promise<Array<IEFaturaDocumentoLinha>> {
    const response: HttpResponse<IEFaturaDocDetails> = await this._conciliacaoEFaturasService.getDocDetails(item.idDocumento, item.dataEmissaoDocumento);
    return response.body.list;
  }

  private _evaluateTableSourceMain(): void {
    if (!this.showOnlyWithoutPossibleDocs && this._bkpTableSource.length) {
      this.isAssociarTodosPossiveisVisible = this._listContainDocsPossiveis;
      this.tableSource = copy(this._bkpTableSource);
      return;
    }

    if (this._verificaEFaturaSource.list) {
      const source: Array<IVerificaEFaturaItem> = [];
      this.isAssociarTodosPossiveisVisible = false;
      for (const item of this._verificaEFaturaSource.list) {
        if (this.showOnlyWithoutPossibleDocs && item.possiveisDocs.length) {
          continue;
        }
        if (item.possiveisDocs.length) {
          this.isAssociarTodosPossiveisVisible = true;
        }
        item.numPossiveisDocs = item.possiveisDocs.length;
        item.numSemelhantesDocs = item.semelhantesDocs.length;
        item.rowClass = item.possiveisDocs.length === 0 ? EDataGridLegendCellColors.RedLegendColor : '';

        for (const doc of item.possiveisDocs) {
          doc.link = '<div class="hyperlink badge bg-success hyperlink-associar">Associar</div>';
        }

        for (const doc of item.semelhantesDocs) {
          doc.link = '<div class="hyperlink badge bg-success hyperlink-associar">Associar</div>';
        }

        item.detalhesSource = [];

        source.push(item);
      }
      this.tableSource = source;
      if (!this._verificaEFaturaSource.cache) {
        this._listContainDocsPossiveis = this.isAssociarTodosPossiveisVisible;
        this._bkpTableSource = source;
      }
    }
  }

  private async _onRowExpand(item: IVerificaEFaturaItem): Promise<void> {
    if (item.detalhesSource?.length) {
      return;
    }
    item.detalhesSource = await this._getDocDetails(item);
  }

  private async _openConfig(): Promise<void> {
    const response: HttpResponse<IJsonEfaturaConfig> = await this._conciliacaoEFaturasService.getConfigs();
    const modalInstance = this._cgModalService.showVanilla(ConciliacaoEFaturasModuleConfigModalComponent, {size: 'md'});
    const componentInstance: ConciliacaoEFaturasModuleConfigModalComponent = modalInstance.componentInstance;
    componentInstance.config = response.body;
  }

  private _generateSecondaryClickActions(item: IEFaturaDocumento): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'conciliacaoEFaturas.btn.dropdown.ignorarDocumento',
        icon: 'fa-ban',
        click: () => this.ignoreDoc(item)
      },
      {
        caption: 'conciliacaoEFaturas.btn.dropdown.ignorarSempreTodosDocumentos',
        icon: 'fa-ban',
        click: () => this.ignoreFornecedor(item)
      },
      {
        caption: 'conciliacaoEFaturas.btn.dropdown.extratosConta',
        icon: 'fa-file-text-o',
        click: () => this.extratoConta(item)
      },
      {
        caption: 'conciliacaoEFaturas.btn.dropdown.configSupplier',
        icon: 'fa-cog',
        click: () => this.openConfigPreDefinidosSupplierModal(item)
      }
    ];
  }

  private _checkInputFields(): boolean {
    if (this.filters.fromDate > this.filters.toDate) {
      this._plAlertService.error(this._translateService.instant('conciliacaoEFaturas.error.dataFinalInferiorInicial'));
      return false;
    }
    return true;
  }
}
