import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlTooltipConfig, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {CGModalService} from '../../../cg/modal/cgmodal.service';
import {ContabilidadeDigitalService} from '../../../../services/contabilidadedigital/contabilidadedigital.service';
import {IJsonContabDigitalConfigs, IJsonContabDigitalPreDefinido, IJsonDocDigitalizado} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {Subscription} from 'rxjs';
import {IContabDigitalPreDefinidoConfig, IDocDigitalizado} from '../../../../modules/contabilidadedigital/documentosdigitais/contabDigital.documentosDigitais.module.interface';

@Component({
  selector: 'contabilidadedigital-pre-definidos-config-modal',
  templateUrl: './contabilidadedigital.predefinidosconfig.modal.component.html'
})
export class ContabilidadeDigitalPreDefinidosConfigModalComponent extends CGModalComponent<IContabDigitalPreDefinidoConfig> implements OnInit, OnDestroy {
  @Input() public nif: string;
  @Input() public nomeFornecedor: string;
  @Input() public preDefinido: IJsonContabDigitalPreDefinido;
  @Input() public docs: Array<IDocDigitalizado>;

  public readonly tooltipInfoEfatura: IPlTooltipConfig;

  public modoLancarDocsAutoDisponivel: boolean;
  public promiseNIF: Promise<void>;

  private readonly _subscriptionContabDigitalConfigs: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService
  ) {
    super(_injector);
    this.tooltipInfoEfatura = {text: 'arquivodigital.predefinidosconfig.efaturainfo', placement: 'bottom', container: 'body'};
    this.nif = '';
    this.nomeFornecedor = '';
    this._subscriptionContabDigitalConfigs = this._contabilidadeDigitalService.configs.getConfigs().subscribe((contabDigitalConfigs: IJsonContabDigitalConfigs) => {
      this.modoLancarDocsAutoDisponivel = contabDigitalConfigs.modoLancarDocsAutoDisponivel;
    });
  }

  public ngOnInit(): void {
    this.preDefinido = {
      fatura: undefined,
      faturaSimplificada: undefined,
      faturaRecibo: undefined,
      notaDebito: undefined,
      notaCredito: undefined,
      vendaDinheiro: undefined,
      talaoVenda: undefined,
      talaoDevolucao: undefined,
      alienacaoAtivos: undefined,
      devolucaoAtivos: undefined,
      premio: undefined,
      estorno: undefined,
      imputacaoSeguradoras: undefined,
      imputacaoSegLider: undefined,
      recibo: undefined,
      pagamentoEstado: undefined,
      levantamento: undefined,
      pagamento: undefined,
      deposito: undefined,
      cheque: undefined,
      transferencia: undefined,
      nomeFatura: undefined,
      nomeFaturaSimplificada: undefined,
      nomeFaturaRecibo: undefined,
      nomeNotaDebito: undefined,
      nomeNotaCredito: undefined,
      nomeVendaDinheiro: undefined,
      nomeTalaoVenda: undefined,
      nomeTalaoDevolucao: undefined,
      nomeAlienacaoAtivos: undefined,
      nomeDevolucaoAtivos: undefined,
      nomePremio: undefined,
      nomeEstorno: undefined,
      nomeImputacaoSeguradoras: undefined,
      nomeImputacaoSegLider: undefined,
      nomeRecibo: undefined,
      nomePagamentoEstado: undefined,
      nomeLevantamento: undefined,
      nomePagamento: undefined,
      nomeDeposito: undefined,
      nomeCheque: undefined,
      nomeTransferencia: undefined,
      eFaturaFatura: false,
      eFaturaFaturaSimplificada: false,
      eFaturaFaturaRecibo: false,
      eFaturaNotaDebito: false,
      eFaturaNotaCredito: false,
      eFaturaVendaDinheiro: false,
      lancaDocsAuto: false,
      lancaDocsAutoCaption: undefined,
      ...this.preDefinido
    };
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionContabDigitalConfigs.unsubscribe();
  }

  public async close(): Promise<void> {
    if (
      this.preDefinido.notaCredito &&
      (this.preDefinido.notaCredito === this.preDefinido.fatura ||
        this.preDefinido.notaCredito === this.preDefinido.faturaRecibo ||
        this.preDefinido.notaCredito === this.preDefinido.notaDebito ||
        this.preDefinido.notaCredito === this.preDefinido.faturaSimplificada ||
        this.preDefinido.notaCredito === this.preDefinido.vendaDinheiro ||
        this.preDefinido.notaCredito === this.preDefinido.talaoVenda)
    ) {
      await this._cgModalService.showOkCancel('global.text.confirmation', 'arquivodigital.predefinidosconfig.predefnotacredito');
    }
    const docs: Array<IDocDigitalizado> = [];
    for (const doc of this.docs) {
      if (doc.nif === this.nif) {
        docs.push(doc);
      }
    }
    const docsIDs: Array<string> = docs.map((doc: IDocDigitalizado) => doc.docOCRCabID);
    const response: HttpResponse<Array<IJsonDocDigitalizado>> = await this._contabilidadeDigitalService.documents.savePreDefinidosConfig({
      nif: this.nif,
      docsIDs: docsIDs,
      preDefinido: this.preDefinido
    });
    this._plAlertService.success('arquivodigital.predefinidosconfig.success');
    super.close({docsPreDefinidoReload: response.body, docs: docs});
  }

  public lancaDocsAutoInfo(): void {
    this._cgModalService.showOkCancel('arquivodigital.predefinidosconfig.lancfaturahinttitle', 'arquivodigital.predefinidosconfig.lancfaturahintinfo', {showCancelBtn: false, size: 'md'});
  }

  public nifChanged(nif: string): Promise<void> {
    this.nif = nif;
    this.promiseNIF = this._contabilidadeDigitalService.documents
      .defaultConfigPreDefinidos(this.nif)
      .then((response: HttpResponse<IJsonContabDigitalPreDefinido>) => {
        this.preDefinido = response.body;
      })
      .finally(() => {
        this.promiseNIF = undefined;
      });
    return this.promiseNIF;
  }
}
