import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_REFEICOES} from './tiposRefeicoes.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPOS_REFEICOES: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_REFEICOES,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'tiposRefeicoes.data.notDefined'},
    {value: 1, name: 'tiposRefeicoes.data.launch'},
    {value: 2, name: 'tiposRefeicoes.data.lightLaunch'},
    {value: 3, name: 'tiposRefeicoes.data.dinner'},
    {value: 4, name: 'tiposRefeicoes.data.lightDinner'}
  ]
});
