<div class="notification-center-item" [class.minimalistic]="minimalistic" [ngSwitch]="minimalistic || mobile">
  <a *ngSwitchCase="true" class="notification-center-item-href" [attr.href]="notification.href" target="_blank" (click)="readNotification(notification)">
    <div class="notification-center-item-texts">
      <div class="notification-title">{{ notification.title }}</div>
      <div class="notification-description">{{ notification.description }}</div>
      <div class="notification-date">{{ notification.date | cgDateFromNow }}</div>
    </div>

    <div class="notification-center-item-badge">
      <div class="blue-badge" *ngIf="!notification.read"></div>
    </div>
  </a>

  <ng-container *ngSwitchCase="false">
    <a class="notification-center-item-href" *ngIf="notification.icon" [attr.href]="notification.href" target="_blank" rel="noopener noreferrer" (click)="readNotification(notification)">
      <img class="notification-icon" [src]="notification.icon" alt="{{ 'notificationcenter.image' | translate }} {{ notification.title }}" />
    </a>

    <div class="notification-center-item-texts">
      <a class="notification-title notification-center-item-href" [attr.href]="notification.href" target="_blank" rel="noopener noreferrer" (click)="readNotification(notification)">{{
        notification.title
      }}</a>
      <div class="notification-description">{{ notification.description }}</div>
      <div class="notification-date">{{ notification.date | cgDateFromNow }}</div>
    </div>

    <div class="notification-center-item-badge">
      <div class="blue-badge" *ngIf="!notification.read"></div>
    </div>
  </ng-container>
</div>
