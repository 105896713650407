import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {InventariosStockComponent} from './components/inventariosStock.module.component';
import {MODULE_NAME_INVENTARIOS} from './inventarios.module.interface';
import {ROLE} from '../../services/role.const';

export const MODULE_INVENTARIOS: IModuleDefinition = {
  name: MODULE_NAME_INVENTARIOS,
  nameAlias: ['inventariosModule'],
  state: {
    url: '/inventarios',
    component: InventariosStockComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.inventarios'
    }
  }
};
