import {NgModule} from '@angular/core';
import {CGCTinyMCEComponent} from './editor/tinymce.editor.component';

export * from './tinymce.di';
export * from './tinymce.interface';
export * from './config/tinymce.config.service';
export * from './editor/tinymce.editor.component';
export * from './events/tinymce.events.interface';
export * from './events/tinymce.events';
export * from './utils/tinymce.utils';

const DECLARATIONS = [CGCTinyMCEComponent];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlTinyMCEModule {}
