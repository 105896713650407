<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h4 class="modal-title" [translate]="'generateAbdes.title'" [translateParams]="{abonoDesconto: titleAbnDesc}"></h4>
</div>

<div class="modal-body">
  <div class="entity-detail-form">
    <pl-nav-wizard
      #navWizard="navWizard"
      [definition]="navWizardDefinition"
      [callback]="navWizardCallback"
      [beforeStepChange]="fnBeforeStepChange"
      [onFinalize]="fnGenerateABDES"
      [hideFooter]="true"
      [destroyOnHide]="false">
      <pl-nav-wizard-step caption="generateAbdes.tipoConfig" icon="fa-cog">
        <pl-form *plNavWizardStepContent (formInstanceChange)="formTipoConfig = $event">
          <label translate="generateAbdes.criarNovoAbonoDesconto" [translateParams]="{abonoDesconto: titleAbnDesc}"></label>
          <pl-edit-radio
            attrName="tipoConfigAbDescPreConfigurado"
            [(model)]="tipoConfigAbDescPreConfigurado"
            [radioValue]="true"
            [label]="'generateAbdes.fields.abonoDescontoPreConfigurado' | translate: {abonoDesconto: titleAbnDesc}"></pl-edit-radio>
          <pl-edit-radio
            attrName="tipoConfigAbDescPreConfigurado"
            [(model)]="tipoConfigAbDescPreConfigurado"
            [radioValue]="false"
            [label]="'generateAbdes.fields.abonoDescontoJaExistente' | translate: {abonoDesconto: titleAbnDesc}"></pl-edit-radio>
        </pl-form>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step [caption]="'generateAbdes.abonoDesconto' | translate: {abonoDesconto: titleAbnDesc}" icon="fa-edit">
        <pl-form *plNavWizardStepContent (formInstanceChange)="formPerfil = $event">
          <fieldset class="mb-3">
            <legend [translate]="'generateAbdes.selecionarAbonoDescontoPretendido'" [translateParams]="{abonoDesconto: titleAbnDesc}"></legend>
            <pl-group *ngIf="tipoConfigAbDescPreConfigurado">
              <label [translate]="'generateAbdes.fields.tipo'"></label>
              <edit>
                <pl-edit attrName="tipo" [type]="tipoAbonoAgrupadoType" [model]="tipo" (modelChange)="fnTipoChange($event)" [properties]="{disallowClear: true}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group *ngIf="tipoConfigAbDescPreConfigurado">
              <label [translate]="'generateAbdes.fields.perfilAbDesc'" [translateParams]="{abonoDesconto: titleAbnDesc}"></label>
              <edit>
                <pl-autocomplete
                  attrName="perfilSistemaAbdes"
                  [source]="listaPerfilSistemaAbdes"
                  [model]="selectedPerfilSistemaAbdes"
                  [rowTemplate]="perfilSistemaAbdesTemplate"
                  [output]="perfilSistemaAbdesTemplate"
                  (evtSelected)="fnPerfilSistemaAbdesChanged($event)"
                  [properties]="{validators: {required: {value: true}}}">
                </pl-autocomplete>
              </edit>
            </pl-group>

            <pl-group *ngIf="!tipoConfigAbDescPreConfigurado">
              <label [translate]="'abdes.fields.codAbDesc'"></label>
              <edit>
                <entity-autocomplete
                  attrName="codAbDesc"
                  [entity]="codAbDescEntity"
                  [outputKey]="'codAbDesc'"
                  [outputDescription]="'designacaoBreve'"
                  (evtSelected)="fnCodAbDescChanged($event)"
                  [properties]="{allowInvalid: false, allowEmpty: false, showMaintenanceEditAction: false, validators: {required: {value: true}}}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </fieldset>

          <pl-group>
            <label [translate]="'generateAbdes.fields.nome'"></label>
            <edit>
              <pl-edit-text attrName="nome" [(model)]="nome" [properties]="{validators: {required: {value: true}}}"></pl-edit-text>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'generateAbdes.fields.descricao'"></label>
            <edit>
              <pl-edit-text attrName="descricao" [(model)]="descricao" [properties]="{validators: {required: {value: true}}}"></pl-edit-text>
            </edit>
          </pl-group>

          <pl-group *ngIf="ausenciaVisible">
            <label [translate]="'generateAbdes.fields.ausencia'"></label>
            <edit>
              <pl-edit attrName="ausencia" type="tiposAusencias" [(model)]="ausencia" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <pl-group *ngIf="valorLimiteIncVisible">
              <label [translate]="'abdes.fields.valorLimIncidencia'"></label>
              <edit>
                <pl-edit-number attrName="valorLimIncidencia" [(model)]="valorLimiteInc" [properties]="{disabled: selectedPerfilSistemaAbdes?.bloqueiaLimitesIncidencia}"></pl-edit-number>
              </edit>
            </pl-group>

            <pl-group *ngIf="valorUnitVisible">
              <label [translate]="'abdes.fields.valorFixo'"></label>
              <edit>
                <pl-edit-number attrName="valorUnitVisible" [(model)]="valorUnit"></pl-edit-number>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group *ngIf="percAumentHorExtraVisible">
            <label [translate]="'generateAbdes.fields.percAumentHExtra'"></label>
            <edit>
              <pl-edit-number attrName="percAumentHorExtraVisible" [(model)]="percAumentHorExtra"></pl-edit-number>
            </edit>
          </pl-group>
        </pl-form>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </div>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-nav-wizard-actions [navWizardInstance]="navWizard"></pl-nav-wizard-actions>
  <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
</div>
