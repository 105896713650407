{
    "es": {
        "Yes": "Sí",
        "No": "No",
        "Cancel": "Cancelar",
        "CheckState": "Check state",
        "Close": "Cerca",
        "Clear": "Limpiar",
        "Done": "Hecho",
        "Loading": "Cargando...",
        "Select": "Seleccionar...",
        "Search": "Buscar",
        "Back": "Volver",
        "OK": "Aceptar",

        "dxCollectionWidget-noDataText": "Sin datos para mostrar",

        "dxDropDownEditor-selectLabel": "Seleccionar",

        "validation-required": "Obligatorio",
        "validation-required-formatted": "{0} es obligatorio",
        "validation-numeric": "Valor debe ser un número",
        "validation-numeric-formatted": "{0} debe ser un número",
        "validation-range": "Valor fuera de rango",
        "validation-range-formatted": "{0} fuera de rango",
        "validation-stringLength": "El tamaño del valor es incorrecto",
        "validation-stringLength-formatted": "El tamaño de {0} es incorrecto",
        "validation-custom": "Valor inválido",
        "validation-custom-formatted": "{0} inválido",
        "validation-async": "Valor inválido",
        "validation-async-formatted": "{0} inválido",
        "validation-compare": "Valores no coinciden",
        "validation-compare-formatted": "{0} no coinciden",
        "validation-pattern": "Valor no coincide con el patrón",
        "validation-pattern-formatted": "{0} no coincide con el patrón",
        "validation-email": "Email inválido",
        "validation-email-formatted": "{0} inválido",
        "validation-mask": "Valor inválido",

        "dxLookup-searchPlaceholder": "Cantidad mínima de caracteres: {0}",

        "dxList-pullingDownText": "Desliza hacia abajo para actualizar...",
        "dxList-pulledDownText": "Suelta para actualizar...",
        "dxList-refreshingText": "Actualizando...",
        "dxList-pageLoadingText": "Cargando...",
        "dxList-nextButtonText": "Más",
        "dxList-selectAll": "Seleccionar Todo",
        "dxList-listAriaLabel": "Items",
        "dxList-listAriaLabel-deletable": "Deletable items",
        "dxListEditDecorator-delete": "Eliminar",
        "dxListEditDecorator-more": "Más",
        "dxList-selectAll-indeterminate": "Half-checked",
        "dxList-selectAll-checked": "Checked",
        "dxList-selectAll-notChecked": "Not checked",

        "dxScrollView-pullingDownText": "Desliza hacia abajo para actualizar...",
        "dxScrollView-pulledDownText": "Suelta para actualizar...",
        "dxScrollView-refreshingText": "Actualizando...",
        "dxScrollView-reachBottomText": "Cargando...",

        "dxDateBox-simulatedDataPickerTitleTime": "Seleccione hora",
        "dxDateBox-simulatedDataPickerTitleDate": "Seleccione fecha",
        "dxDateBox-simulatedDataPickerTitleDateTime": "Seleccione fecha y hora",
        "dxDateBox-validation-datetime": "Valor debe ser una fecha u hora",

        "dxDateRangeBox-invalidStartDateMessage": "Start value must be a date",
        "dxDateRangeBox-invalidEndDateMessage": "End value must be a date",
        "dxDateRangeBox-startDateOutOfRangeMessage": "Start date is out of range",
        "dxDateRangeBox-endDateOutOfRangeMessage": "End date is out of range",
        "dxDateRangeBox-startDateLabel": "Fecha inicial",
        "dxDateRangeBox-endDateLabel": "Fecha final",

        "dxFileUploader-selectFile": "Seleccionar archivo",
        "dxFileUploader-dropFile": "o arrastre un archivo aquí",
        "dxFileUploader-bytes": "bytes",
        "dxFileUploader-kb": "KB",
        "dxFileUploader-Mb": "MB",
        "dxFileUploader-Gb": "GB",
        "dxFileUploader-upload": "Subir",
        "dxFileUploader-uploaded": "Subido",
        "dxFileUploader-readyToUpload": "Listo para subir",
        "dxFileUploader-uploadAbortedMessage": "Upload cancelled",
        "dxFileUploader-uploadFailedMessage": "Falla ao subir",
        "dxFileUploader-invalidFileExtension": "Tipo de archivo no está permitido",
        "dxFileUploader-invalidMaxFileSize": "Archivo es muy grande",
        "dxFileUploader-invalidMinFileSize": "Archivo es muy pequeño",

        "dxRangeSlider-ariaFrom": "Desde",
        "dxRangeSlider-ariaTill": "Hasta",
        "dxSwitch-switchedOnText": "ENCENDIDO",
        "dxSwitch-switchedOffText": "APAGADO",

        "dxForm-optionalMark": "opcional",
        "dxForm-requiredMessage": "{0} es obligatorio",

        "dxNumberBox-invalidValueMessage": "Valor debe ser un número",
        "dxNumberBox-noDataText": "Sin datos",

        "dxDataGrid-emptyHeaderWithColumnChooserText": "Use {0} to display columns",
        "dxDataGrid-emptyHeaderWithGroupPanelText": "Drag a column from the group panel here",
        "dxDataGrid-emptyHeaderWithColumnChooserAndGroupPanelText": "Use {0} or drag a column from the group panel",
        "dxDataGrid-emptyHeaderColumnChooserText": "column chooser",
        "dxDataGrid-columnChooserTitle": "Selector de Columnas",
        "dxDataGrid-columnChooserEmptyText": "Arrastra una columna aquí para ocultarla",
        "dxDataGrid-groupContinuesMessage": "Continúa en la página siguiente",
        "dxDataGrid-groupContinuedMessage": "Continuación de la página anterior",
        "dxDataGrid-groupHeaderText": "Agrupar por esta columna",
        "dxDataGrid-ungroupHeaderText": "Desagrupar",
        "dxDataGrid-ungroupAllText": "Desagrupar Todo",
        "dxDataGrid-editingEditRow": "Modificar",
        "dxDataGrid-editingSaveRowChanges": "Guardar",
        "dxDataGrid-editingCancelRowChanges": "Cancelar",
        "dxDataGrid-editingDeleteRow": "Eliminar",
        "dxDataGrid-editingUndeleteRow": "Recuperar",
        "dxDataGrid-editingConfirmDeleteMessage": "¿Está seguro que desea eliminar este registro?",
        "dxDataGrid-validationCancelChanges": "Cancelar cambios",
        "dxDataGrid-groupPanelEmptyText": "Arrastra una columna aquí para agrupar por ella",
        "dxDataGrid-noDataText": "Sin datos",
        "dxDataGrid-searchPanelPlaceholder": "Buscar...",
        "dxDataGrid-filterRowShowAllText": "(Todos)",
        "dxDataGrid-filterRowResetOperationText": "Reestablecer",
        "dxDataGrid-filterRowOperationEquals": "Igual",
        "dxDataGrid-filterRowOperationNotEquals": "No es igual",
        "dxDataGrid-filterRowOperationLess": "Menor que",
        "dxDataGrid-filterRowOperationLessOrEquals": "Menor o igual a",
        "dxDataGrid-filterRowOperationGreater": "Mayor que",
        "dxDataGrid-filterRowOperationGreaterOrEquals": "Mayor o igual a",
        "dxDataGrid-filterRowOperationStartsWith": "Empieza con",
        "dxDataGrid-filterRowOperationContains": "Contiene",
        "dxDataGrid-filterRowOperationNotContains": "No contiene",
        "dxDataGrid-filterRowOperationEndsWith": "Termina con",
        "dxDataGrid-filterRowOperationBetween": "Entre",
        "dxDataGrid-filterRowOperationBetweenStartText": "Inicio",
        "dxDataGrid-filterRowOperationBetweenEndText": "Fin",
        "dxDataGrid-ariaSearchBox": "Search box",
        "dxDataGrid-applyFilterText": "Filtrar",
        "dxDataGrid-trueText": "verdadero",
        "dxDataGrid-falseText": "falso",
        "dxDataGrid-sortingAscendingText": "Orden Ascendente",
        "dxDataGrid-sortingDescendingText": "Orden Descendente",
        "dxDataGrid-sortingClearText": "Limpiar Ordenamiento",
        "dxDataGrid-ariaNotSortedColumn": "Not sorted column",
        "dxDataGrid-ariaSortedAscendingColumn": "Column sorted in ascending order",
        "dxDataGrid-ariaSortedDescendingColumn": "Column sorted in descending order",
        "dxDataGrid-ariaSortIndex": "Sort index {0}",
        "dxDataGrid-editingSaveAllChanges": "Guardar cambios",
        "dxDataGrid-editingCancelAllChanges": "Descartar cambios",
        "dxDataGrid-editingAddRow": "Agregar una fila",
        "dxDataGrid-summaryMin": "Mín: {0}",
        "dxDataGrid-summaryMinOtherColumn": "Mín de {1} es {0}",
        "dxDataGrid-summaryMax": "Máx: {0}",
        "dxDataGrid-summaryMaxOtherColumn": "Máx de {1} es {0}",
        "dxDataGrid-summaryAvg": "Prom: {0}",
        "dxDataGrid-summaryAvgOtherColumn": "Prom de {1} es {0}",
        "dxDataGrid-summarySum": "Suma: {0}",
        "dxDataGrid-summarySumOtherColumn": "Suma de {1} es {0}",
        "dxDataGrid-summaryCount": "Cantidad: {0}",
        "dxDataGrid-columnFixingFix": "Anclar",
        "dxDataGrid-columnFixingUnfix": "Desanclar",
        "dxDataGrid-columnFixingLeftPosition": "A la izquierda",
        "dxDataGrid-columnFixingRightPosition": "A la derecha",
        "dxDataGrid-exportTo": "Exportar",
        "dxDataGrid-exportToExcel": "Exportar a archivo Excel",
        "dxDataGrid-exporting": "Exportar...",
        "dxDataGrid-excelFormat": "Archivo Excel",
        "dxDataGrid-selectedRows": "Filas seleccionadas",
        "dxDataGrid-exportSelectedRows": "Exportar filas seleccionadas a {0}",
        "dxDataGrid-exportAll": "Exportar todo a {0}",
        "dxDataGrid-headerFilterLabel": "Filter options",
        "dxDataGrid-headerFilterIndicatorLabel": "Show filter options for column '{0}'",
        "dxDataGrid-headerFilterEmptyValue": "(Vacio)",
        "dxDataGrid-headerFilterOK": "Aceptar",
        "dxDataGrid-headerFilterCancel": "Cancelar",
        "dxDataGrid-ariaAdaptiveCollapse": "Hide additional data",
        "dxDataGrid-ariaAdaptiveExpand": "Display additional data",
        "dxDataGrid-ariaColumn": "Columna",
        "dxDataGrid-ariaColumnHeader": "Column header",
        "dxDataGrid-ariaValue": "Valor",
        "dxDataGrid-ariaError": "Error",
        "dxDataGrid-ariaRevertButton": "Press Escape to discard the changes",
        "dxDataGrid-ariaFilterCell": "Celda de filtro",
        "dxDataGrid-ariaCollapse": "Colapsar",
        "dxDataGrid-ariaModifiedCell": "Modified",
        "dxDataGrid-ariaDeletedCell": "Deleted",
        "dxDataGrid-ariaEditableCell": "Editable",
        "dxDataGrid-ariaExpand": "Expandir",
        "dxDataGrid-ariaCollapsedRow": "Collapsed row",
        "dxDataGrid-ariaExpandedRow": "Expanded row",
        "dxDataGrid-ariaDataGrid": "Tabla de datos",
        "dxDataGrid-ariaSearchInGrid": "Buscar en la tabla de datos",
        "dxDataGrid-ariaSelectAll": "Seleccionar todo",
        "dxDataGrid-ariaSelectRow": "Seleccionar fila",
        "dxDataGrid-ariaToolbar": "Data grid toolbar",
        "dxDataGrid-ariaEditForm": "Edit form",
        "dxDataGrid-filterBuilderPopupTitle": "Constructor de filtro",
        "dxDataGrid-filterPanelCreateFilter": "Crear filtro",
        "dxDataGrid-filterPanelClearFilter": "Limpiar filtro",
        "dxDataGrid-filterPanelFilterEnabledHint": "Habilitar filtro",
        "dxDataGrid-masterDetail": "Cell with details",

        "dxTreeList-ariaTreeList": "Tree list with {0} rows and {1} columns",
        "dxTreeList-ariaSearchInGrid": "Search in the tree list",
        "dxTreeList-ariaToolbar": "Tree list toolbar",
        "dxTreeList-editingAddRowToNode": "Añadir",

        "dxPager-infoText": "Página {0} de {1} ({2} ítems)",
        "dxPager-pagesCountText": "de",
        "dxPager-pageSize": "Items per page: {0}",
        "dxPager-pageSizesAllText": "Todos",
        "dxPager-page": "Page {0}",
        "dxPager-prevPage": "Previous Page",
        "dxPager-nextPage": "Next Page",
        "dxPager-ariaLabel": "Page Navigation",
        "dxPager-ariaPageSize": "Page size",
        "dxPager-ariaPageNumber": "Page number",

        "dxPivotGrid-grandTotal": "Gran Total",
        "dxPivotGrid-total": "{0} Total",
        "dxPivotGrid-fieldChooserTitle": "Selector de Campos",
        "dxPivotGrid-showFieldChooser": "Mostrar Selector de Campos",
        "dxPivotGrid-expandAll": "Expandir Todo",
        "dxPivotGrid-collapseAll": "Colapsar Todo",
        "dxPivotGrid-sortColumnBySummary": "Ordenar \"{0}\" por esta columna",
        "dxPivotGrid-sortRowBySummary": "Ordenar \"{0}\" por esta fila",
        "dxPivotGrid-removeAllSorting": "Remover ordenamiento",
        "dxPivotGrid-dataNotAvailable": "N/A",
        "dxPivotGrid-rowFields": "Campos de fila",
        "dxPivotGrid-columnFields": "Campos de columna",
        "dxPivotGrid-dataFields": "Campos de dato",
        "dxPivotGrid-filterFields": "Campos de filtro",
        "dxPivotGrid-allFields": "Todos los campos",
        "dxPivotGrid-columnFieldArea": "Arrastra campos de columna aquí",
        "dxPivotGrid-dataFieldArea": "Arrastra campos de dato aquí",
        "dxPivotGrid-rowFieldArea": "Arrastra campos de fila aquí",
        "dxPivotGrid-filterFieldArea": "Arrastra campos de filtro aquí",

        "dxScheduler-editorLabelTitle": "Asunto",
        "dxScheduler-editorLabelStartDate": "Fecha inicial",
        "dxScheduler-editorLabelEndDate": "Fecha final",
        "dxScheduler-editorLabelDescription": "Descripción",
        "dxScheduler-editorLabelRecurrence": "Repetir",

        "dxScheduler-openAppointment": "Abrir cita",

        "dxScheduler-recurrenceNever": "Nunca",
        "dxScheduler-recurrenceMinutely": "Minutely",
        "dxScheduler-recurrenceHourly": "Hourly",
        "dxScheduler-recurrenceDaily": "Diario",
        "dxScheduler-recurrenceWeekly": "Semanal",
        "dxScheduler-recurrenceMonthly": "Mensual",
        "dxScheduler-recurrenceYearly": "Anual",

        "dxScheduler-recurrenceRepeatEvery": "Cada",
        "dxScheduler-recurrenceRepeatOn": "Repeat On",
        "dxScheduler-recurrenceEnd": "Terminar repetición",
        "dxScheduler-recurrenceAfter": "Después",
        "dxScheduler-recurrenceOn": "En",

        "dxScheduler-recurrenceRepeatMinutely": "minute(s)",
        "dxScheduler-recurrenceRepeatHourly": "hour(s)",
        "dxScheduler-recurrenceRepeatDaily": "día(s)",
        "dxScheduler-recurrenceRepeatWeekly": "semana(s)",
        "dxScheduler-recurrenceRepeatMonthly": "mes(es)",
        "dxScheduler-recurrenceRepeatYearly": "año(s)",

        "dxScheduler-switcherDay": "Día",
        "dxScheduler-switcherWeek": "Semana",
        "dxScheduler-switcherWorkWeek": "Semana Laboral",
        "dxScheduler-switcherMonth": "Mes",

        "dxScheduler-switcherAgenda": "Agenda",

        "dxScheduler-switcherTimelineDay": "Línea de tiempo Día",
        "dxScheduler-switcherTimelineWeek": "Línea de tiempo Semana",
        "dxScheduler-switcherTimelineWorkWeek": "Línea de tiempo Semana Laboral",
        "dxScheduler-switcherTimelineMonth": "Línea de tiempo Mes",

        "dxScheduler-recurrenceRepeatOnDate": "en la fecha",
        "dxScheduler-recurrenceRepeatCount": "ocurrencia(s)",
        "dxScheduler-allDay": "Todo el día",

        "dxScheduler-confirmRecurrenceEditTitle": "Edit Recurring Appointment",
        "dxScheduler-confirmRecurrenceDeleteTitle": "Delete Recurring Appointment",

        "dxScheduler-confirmRecurrenceEditMessage": "¿Quiere modificar solo esta cita o toda la serie?",
        "dxScheduler-confirmRecurrenceDeleteMessage": "¿Quiere eliminar solo esta cita o toda la serie?",

        "dxScheduler-confirmRecurrenceEditSeries": "Modificar serie",
        "dxScheduler-confirmRecurrenceDeleteSeries": "Eliminar serie",
        "dxScheduler-confirmRecurrenceEditOccurrence": "Modificar cita",
        "dxScheduler-confirmRecurrenceDeleteOccurrence": "Eliminar cita",

        "dxScheduler-noTimezoneTitle": "Sin zona horaria",
        "dxScheduler-moreAppointments": "{0} más",

        "dxCalendar-currentDay": "Today",
        "dxCalendar-currentMonth": "Current month",
        "dxCalendar-currentYear": "Current year",
        "dxCalendar-currentYearRange": "Current year range",
        "dxCalendar-todayButtonText": "Hoy",
        "dxCalendar-ariaWidgetName": "Calendario",
        "dxCalendar-previousMonthButtonLabel": "Previous month",
        "dxCalendar-previousYearButtonLabel": "Previous year",
        "dxCalendar-previousDecadeButtonLabel": "Previous decade",
        "dxCalendar-previousCenturyButtonLabel": "Previous century",
        "dxCalendar-nextMonthButtonLabel": "Next month",
        "dxCalendar-nextYearButtonLabel": "Next year",
        "dxCalendar-nextDecadeButtonLabel": "Next decade",
        "dxCalendar-nextCenturyButtonLabel": "Next century",
        "dxCalendar-captionMonthLabel": "Month selection",
        "dxCalendar-captionYearLabel": "Year selection",
        "dxCalendar-captionDecadeLabel": "Decade selection",
        "dxCalendar-captionCenturyLabel": "Century selection",
        "dxCalendar-selectedDate": "The selected date is {0}",
        "dxCalendar-selectedDateRange": "The selected date range is from {0} to {1}",

        "dxColorView-ariaRed": "Rojo",
        "dxColorView-ariaGreen": "Verde",
        "dxColorView-ariaBlue": "Azul",
        "dxColorView-ariaAlpha": "Transparencia",
        "dxColorView-ariaHex": "Código del color",

        "dxTagBox-selected": "{0} seleccionado",
        "dxTagBox-allSelected": "Todos seleccionados ({0})",
        "dxTagBox-moreSelected": "{0} más",
        "dxTagBox-tagRoleDescription": "Tag. Press the delete button to remove this tag",

        "vizExport-printingButtonText": "Imprimir",
        "vizExport-titleMenuText": "Exportar/Imprimir",
        "vizExport-exportButtonText": "Archivo {0}",

        "dxFilterBuilder-and": "Y",
        "dxFilterBuilder-or": "O",
        "dxFilterBuilder-notAnd": "NO Y",
        "dxFilterBuilder-notOr": "NO O",
        "dxFilterBuilder-addCondition": "Añadir condición",
        "dxFilterBuilder-addGroup": "Añadir Grupo",
        "dxFilterBuilder-enterValueText": "<rellene con un valor>",
        "dxFilterBuilder-filterOperationEquals": "Igual",
        "dxFilterBuilder-filterOperationNotEquals": "Diferente",
        "dxFilterBuilder-filterOperationLess": "Menos que",
        "dxFilterBuilder-filterOperationLessOrEquals": "Menor o igual que",
        "dxFilterBuilder-filterOperationGreater": "Más grande que",
        "dxFilterBuilder-filterOperationGreaterOrEquals": "Mayor o igual que",
        "dxFilterBuilder-filterOperationStartsWith": "Comienza con",
        "dxFilterBuilder-filterOperationContains": "Contiene",
        "dxFilterBuilder-filterOperationNotContains": "No contiene",
        "dxFilterBuilder-filterOperationEndsWith": "Termina con",
        "dxFilterBuilder-filterOperationIsBlank": "Vacío",
        "dxFilterBuilder-filterOperationIsNotBlank": "No vacío",
        "dxFilterBuilder-filterOperationBetween": "Entre",
        "dxFilterBuilder-filterOperationAnyOf": "Alguno de",
        "dxFilterBuilder-filterOperationNoneOf": "Ningún de",

        "dxHtmlEditor-dialogColorCaption": "Cambiar el color de la fuente",
        "dxHtmlEditor-dialogBackgroundCaption": "Cambiar el color de fondo",
        "dxHtmlEditor-dialogLinkCaption": "Añadir enlace",
        "dxHtmlEditor-dialogLinkUrlField": "URL",
        "dxHtmlEditor-dialogLinkTextField": "Texto",
        "dxHtmlEditor-dialogLinkTargetField": "Abrir enlace en nueva ventana",
        "dxHtmlEditor-dialogImageCaption": "Añadir imagen",
        "dxHtmlEditor-dialogImageUrlField": "URL",
        "dxHtmlEditor-dialogImageAltField": "Texto alternativo",
        "dxHtmlEditor-dialogImageWidthField": "Anchura (px)",
        "dxHtmlEditor-dialogImageHeightField": "Altura (px)",
        "dxHtmlEditor-dialogInsertTableRowsField": "Rows",
        "dxHtmlEditor-dialogInsertTableColumnsField": "Columns",
        "dxHtmlEditor-dialogInsertTableCaption": "Insert Table",
        "dxHtmlEditor-dialogUpdateImageCaption": "Update Image",
        "dxHtmlEditor-dialogImageUpdateButton": "Update",
        "dxHtmlEditor-dialogImageAddButton": "Add",
        "dxHtmlEditor-dialogImageSpecifyUrl": "From the Web",
        "dxHtmlEditor-dialogImageSelectFile": "From This Device",
        "dxHtmlEditor-dialogImageKeepAspectRatio": "Keep Aspect Ratio",
        "dxHtmlEditor-dialogImageEncodeToBase64": "Encode to Base64",
        "dxHtmlEditor-heading": "Encabezamiento",
        "dxHtmlEditor-normalText": "Texto normal",
        "dxHtmlEditor-background": "Background Color",
        "dxHtmlEditor-bold": "Bold",
        "dxHtmlEditor-color": "Font Color",
        "dxHtmlEditor-font": "Font",
        "dxHtmlEditor-italic": "Italic",
        "dxHtmlEditor-link": "Add Link",
        "dxHtmlEditor-image": "Add Image",
        "dxHtmlEditor-size": "Size",
        "dxHtmlEditor-strike": "Strikethrough",
        "dxHtmlEditor-subscript": "Subscript",
        "dxHtmlEditor-superscript": "Superscript",
        "dxHtmlEditor-underline": "Underline",
        "dxHtmlEditor-blockquote": "Blockquote",
        "dxHtmlEditor-header": "Header",
        "dxHtmlEditor-increaseIndent": "Increase Indent",
        "dxHtmlEditor-decreaseIndent": "Decrease Indent",
        "dxHtmlEditor-orderedList": "Ordered List",
        "dxHtmlEditor-bulletList": "Bullet List",
        "dxHtmlEditor-alignLeft": "Align Left",
        "dxHtmlEditor-alignCenter": "Align Center",
        "dxHtmlEditor-alignRight": "Align Right",
        "dxHtmlEditor-alignJustify": "Align Justify",
        "dxHtmlEditor-codeBlock": "Code Block",
        "dxHtmlEditor-variable": "Add Variable",
        "dxHtmlEditor-undo": "Undo",
        "dxHtmlEditor-redo": "Redo",
        "dxHtmlEditor-clear": "Clear Formatting",
        "dxHtmlEditor-insertTable": "Insert Table",
        "dxHtmlEditor-insertHeaderRow": "Insert Header Row",
        "dxHtmlEditor-insertRowAbove": "Insert Row Above",
        "dxHtmlEditor-insertRowBelow": "Insert Row Below",
        "dxHtmlEditor-insertColumnLeft": "Insert Column Left",
        "dxHtmlEditor-insertColumnRight": "Insert Column Right",
        "dxHtmlEditor-deleteColumn": "Delete Column",
        "dxHtmlEditor-deleteRow": "Delete Row",
        "dxHtmlEditor-deleteTable": "Delete Table",
        "dxHtmlEditor-cellProperties": "Cell Properties",
        "dxHtmlEditor-tableProperties": "Table Properties",
        "dxHtmlEditor-insert": "Insert",
        "dxHtmlEditor-delete": "Delete",
        "dxHtmlEditor-border": "Border",
        "dxHtmlEditor-style": "Style",
        "dxHtmlEditor-width": "Width",
        "dxHtmlEditor-height": "Height",
        "dxHtmlEditor-borderColor": "Color",
        "dxHtmlEditor-borderWidth": "Border Width",
        "dxHtmlEditor-tableBackground": "Background",
        "dxHtmlEditor-dimensions": "Dimensions",
        "dxHtmlEditor-alignment": "Alignment",
        "dxHtmlEditor-horizontal": "Horizontal",
        "dxHtmlEditor-vertical": "Vertical",
        "dxHtmlEditor-paddingVertical": "Vertical Padding",
        "dxHtmlEditor-paddingHorizontal": "Horizontal Padding",
        "dxHtmlEditor-pixels": "Pixels",
        "dxHtmlEditor-list": "List",
        "dxHtmlEditor-ordered": "Ordered",
        "dxHtmlEditor-bullet": "Bullet",
        "dxHtmlEditor-align": "Align",
        "dxHtmlEditor-center": "Center",
        "dxHtmlEditor-left": "Left",
        "dxHtmlEditor-right": "Right",
        "dxHtmlEditor-indent": "Indent",
        "dxHtmlEditor-justify": "Justify",
        "dxHtmlEditor-borderStyleNone": "none",
        "dxHtmlEditor-borderStyleHidden": "hidden",
        "dxHtmlEditor-borderStyleDotted": "dotted",
        "dxHtmlEditor-borderStyleDashed": "dashed",
        "dxHtmlEditor-borderStyleSolid": "solid",
        "dxHtmlEditor-borderStyleDouble": "double",
        "dxHtmlEditor-borderStyleGroove": "groove",
        "dxHtmlEditor-borderStyleRidge": "ridge",
        "dxHtmlEditor-borderStyleInset": "inset",
        "dxHtmlEditor-borderStyleOutset": "outset",

        "dxFileManager-newDirectoryName": "Sin título",
        "dxFileManager-rootDirectoryName": "Archivos",
        "dxFileManager-errorNoAccess": "Acceso denegado. La operación no se puede completar.",
        "dxFileManager-errorDirectoryExistsFormat": "Carpeta {0} ya existe.",
        "dxFileManager-errorFileExistsFormat": "Archivo {0} ya existe.",
        "dxFileManager-errorFileNotFoundFormat": "Archivo {0} no encontrado.",
        "dxFileManager-errorDirectoryNotFoundFormat": "Directory '{0}' not found.",
        "dxFileManager-errorWrongFileExtension": "File extension is not allowed.",
        "dxFileManager-errorMaxFileSizeExceeded": "File size exceeds the maximum allowed size.",
        "dxFileManager-errorInvalidSymbols": "This name contains invalid characters.",
        "dxFileManager-errorDefault": "Error no especificado",
        "dxFileManager-errorDirectoryOpenFailed": "The directory cannot be opened",

        "dxFileManager-commandCreate": "New directory",
        "dxFileManager-commandRename": "Rename",
        "dxFileManager-commandMove": "Move to",
        "dxFileManager-commandCopy": "Copy to",
        "dxFileManager-commandDelete": "Delete",
        "dxFileManager-commandDownload": "Download",
        "dxFileManager-commandUpload": "Upload files",
        "dxFileManager-commandRefresh": "Refresh",
        "dxFileManager-commandThumbnails": "Thumbnails View",
        "dxFileManager-commandDetails": "Details View",
        "dxFileManager-commandClearSelection": "Clear selection",
        "dxFileManager-commandShowNavPane": "Toggle navigation pane",

        "dxFileManager-dialogDirectoryChooserMoveTitle": "Move to",
        "dxFileManager-dialogDirectoryChooserMoveButtonText": "Move",
        "dxFileManager-dialogDirectoryChooserCopyTitle": "Copy to",
        "dxFileManager-dialogDirectoryChooserCopyButtonText": "Copy",
        "dxFileManager-dialogRenameItemTitle": "Rename",
        "dxFileManager-dialogRenameItemButtonText": "Save",
        "dxFileManager-dialogCreateDirectoryTitle": "New directory",
        "dxFileManager-dialogCreateDirectoryButtonText": "Create",
        "dxFileManager-dialogDeleteItemTitle": "Delete",
        "dxFileManager-dialogDeleteItemButtonText": "Delete",
        "dxFileManager-dialogDeleteItemSingleItemConfirmation": "Are you sure you want to delete {0}?",
        "dxFileManager-dialogDeleteItemMultipleItemsConfirmation": "Are you sure you want to delete {0} items?",
        "dxFileManager-dialogButtonCancel": "Cancel",

        "dxFileManager-editingCreateSingleItemProcessingMessage": "Creating a directory inside {0}",
        "dxFileManager-editingCreateSingleItemSuccessMessage": "Created a directory inside {0}",
        "dxFileManager-editingCreateSingleItemErrorMessage": "Directory was not created",
        "dxFileManager-editingCreateCommonErrorMessage": "Directory was not created",

        "dxFileManager-editingRenameSingleItemProcessingMessage": "Renaming an item inside {0}",
        "dxFileManager-editingRenameSingleItemSuccessMessage": "Renamed an item inside {0}",
        "dxFileManager-editingRenameSingleItemErrorMessage": "Item was not renamed",
        "dxFileManager-editingRenameCommonErrorMessage": "Item was not renamed",

        "dxFileManager-editingDeleteSingleItemProcessingMessage": "Deleting an item from {0}",
        "dxFileManager-editingDeleteMultipleItemsProcessingMessage": "Deleting {0} items from {1}",
        "dxFileManager-editingDeleteSingleItemSuccessMessage": "Deleted an item from {0}",
        "dxFileManager-editingDeleteMultipleItemsSuccessMessage": "Deleted {0} items from {1}",
        "dxFileManager-editingDeleteSingleItemErrorMessage": "Item was not deleted",
        "dxFileManager-editingDeleteMultipleItemsErrorMessage": "{0} items were not deleted",
        "dxFileManager-editingDeleteCommonErrorMessage": "Some items were not deleted",

        "dxFileManager-editingMoveSingleItemProcessingMessage": "Moving an item to {0}",
        "dxFileManager-editingMoveMultipleItemsProcessingMessage": "Moving {0} items to {1}",
        "dxFileManager-editingMoveSingleItemSuccessMessage": "Moved an item to {0}",
        "dxFileManager-editingMoveMultipleItemsSuccessMessage": "Moved {0} items to {1}",
        "dxFileManager-editingMoveSingleItemErrorMessage": "Item was not moved",
        "dxFileManager-editingMoveMultipleItemsErrorMessage": "{0} items were not moved",
        "dxFileManager-editingMoveCommonErrorMessage": "Some items were not moved",

        "dxFileManager-editingCopySingleItemProcessingMessage": "Copying an item to {0}",
        "dxFileManager-editingCopyMultipleItemsProcessingMessage": "Copying {0} items to {1}",
        "dxFileManager-editingCopySingleItemSuccessMessage": "Copied an item to {0}",
        "dxFileManager-editingCopyMultipleItemsSuccessMessage": "Copied {0} items to {1}",
        "dxFileManager-editingCopySingleItemErrorMessage": "Item was not copied",
        "dxFileManager-editingCopyMultipleItemsErrorMessage": "{0} items were not copied",
        "dxFileManager-editingCopyCommonErrorMessage": "Some items were not copied",

        "dxFileManager-editingUploadSingleItemProcessingMessage": "Uploading an item to {0}",
        "dxFileManager-editingUploadMultipleItemsProcessingMessage": "Uploading {0} items to {1}",
        "dxFileManager-editingUploadSingleItemSuccessMessage": "Uploaded an item to {0}",
        "dxFileManager-editingUploadMultipleItemsSuccessMessage": "Uploaded {0} items to {1}",
        "dxFileManager-editingUploadSingleItemErrorMessage": "Item was not uploaded",
        "dxFileManager-editingUploadMultipleItemsErrorMessage": "{0} items were not uploaded",
        "dxFileManager-editingUploadCanceledMessage": "Canceled",

        "dxFileManager-editingDownloadSingleItemErrorMessage": "Item was not downloaded",
        "dxFileManager-editingDownloadMultipleItemsErrorMessage": "{0} items were not downloaded",

        "dxFileManager-listDetailsColumnCaptionName": "Name",
        "dxFileManager-listDetailsColumnCaptionDateModified": "Date Modified",
        "dxFileManager-listDetailsColumnCaptionFileSize": "File Size",

        "dxFileManager-listThumbnailsTooltipTextSize": "Size",
        "dxFileManager-listThumbnailsTooltipTextDateModified": "Date Modified",

        "dxFileManager-notificationProgressPanelTitle": "Progress",
        "dxFileManager-notificationProgressPanelEmptyListText": "No operations",
        "dxFileManager-notificationProgressPanelOperationCanceled": "Canceled",

        "dxDiagram-categoryGeneral": "General",
        "dxDiagram-categoryFlowchart": "Diagrama de flujo",
        "dxDiagram-categoryOrgChart": "Organigrama",
        "dxDiagram-categoryContainers": "Contenedores",
        "dxDiagram-categoryCustom": "Personalizado",

        "dxDiagram-commandExportToSvg": "Exportar a SVG",
        "dxDiagram-commandExportToPng": "Exportar a PNG",
        "dxDiagram-commandExportToJpg": "Exportar a JPG",
        "dxDiagram-commandUndo": "Deshacer",
        "dxDiagram-commandRedo": "Rehacer",
        "dxDiagram-commandFontName": "Nombre de fuente",
        "dxDiagram-commandFontSize": "Tamaño de fuente",
        "dxDiagram-commandBold": "Negrita",
        "dxDiagram-commandItalic": "Cursiva",
        "dxDiagram-commandUnderline": "Subrayado",
        "dxDiagram-commandTextColor": "Color de fuente",
        "dxDiagram-commandLineColor": "Color de línea",
        "dxDiagram-commandLineWidth": "Ancho de línea",
        "dxDiagram-commandLineStyle": "Estilo de línea",
        "dxDiagram-commandLineStyleSolid": "Sólido",
        "dxDiagram-commandLineStyleDotted": "De puntos",
        "dxDiagram-commandLineStyleDashed": "De guiones",
        "dxDiagram-commandFillColor": "Color de relleno",
        "dxDiagram-commandAlignLeft": "Alinear a la izquierda",
        "dxDiagram-commandAlignCenter": "Alinear al centro",
        "dxDiagram-commandAlignRight": "Alinear a la derecha",
        "dxDiagram-commandConnectorLineType": "Tipo de línea de conector",
        "dxDiagram-commandConnectorLineStraight": "Recto",
        "dxDiagram-commandConnectorLineOrthogonal": "Ortogonal",
        "dxDiagram-commandConnectorLineStart": "Conector de inicio de línea",
        "dxDiagram-commandConnectorLineEnd": "Conector de final de línea",
        "dxDiagram-commandConnectorLineNone": "Ninguno",
        "dxDiagram-commandConnectorLineArrow": "Flecha",
        "dxDiagram-commandFullscreen": "Pantalla completa",
        "dxDiagram-commandUnits": "Unidades",
        "dxDiagram-commandPageSize": "Tamaño de página",
        "dxDiagram-commandPageOrientation": "Orientación de página",
        "dxDiagram-commandPageOrientationLandscape": "Horizontal",
        "dxDiagram-commandPageOrientationPortrait": "Vertical",
        "dxDiagram-commandPageColor": "Color de página",
        "dxDiagram-commandShowGrid": "Mostrar cuadrícula",
        "dxDiagram-commandSnapToGrid": "Ajustar a la cuadrícula",
        "dxDiagram-commandGridSize": "Tamaño de cuadrícula",
        "dxDiagram-commandZoomLevel": "Nivel de zoom",
        "dxDiagram-commandAutoZoom": "Zoom automático",
        "dxDiagram-commandFitToContent": "Ajustar al contenido",
        "dxDiagram-commandFitToWidth": "Ajustar al ancho",
        "dxDiagram-commandAutoZoomByContent": "Zoom automático por contenido",
        "dxDiagram-commandAutoZoomByWidth": "Zoom automático por ancho",
        "dxDiagram-commandSimpleView": "Vista Simple",
        "dxDiagram-commandCut": "Cortar",
        "dxDiagram-commandCopy": "Copiar",
        "dxDiagram-commandPaste": "Pegar",
        "dxDiagram-commandSelectAll": "Seleccionar todo",
        "dxDiagram-commandDelete": "Eliminar",
        "dxDiagram-commandBringToFront": "Traer al frente",
        "dxDiagram-commandSendToBack": "Enviar al fondo",
        "dxDiagram-commandLock": "Bloquear",
        "dxDiagram-commandUnlock": "Desbloquear",
        "dxDiagram-commandInsertShapeImage": "Insertar imagen...",
        "dxDiagram-commandEditShapeImage": "Cambiar imagen...",
        "dxDiagram-commandDeleteShapeImage": "Eliminar imagen",
        "dxDiagram-commandLayoutLeftToRight": "De izquierda a derecha",
        "dxDiagram-commandLayoutRightToLeft": "De derecha a izquierda",
        "dxDiagram-commandLayoutTopToBottom": "De arriba a abajo",
        "dxDiagram-commandLayoutBottomToTop": "De abajo a arriba",

        "dxDiagram-unitIn": "pulg.",
        "dxDiagram-unitCm": "cm",
        "dxDiagram-unitPx": "px",

        "dxDiagram-dialogButtonOK": "Aceptar",
        "dxDiagram-dialogButtonCancel": "Cancelar",
        "dxDiagram-dialogInsertShapeImageTitle": "Insertar Imagen",
        "dxDiagram-dialogEditShapeImageTitle": "Cambiar Imagen",
        "dxDiagram-dialogEditShapeImageSelectButton": "Seleccionar imagen",
        "dxDiagram-dialogEditShapeImageLabelText": "o colocar el archivo aquí",

        "dxDiagram-uiExport": "Exportar",
        "dxDiagram-uiProperties": "Propiedades",
        "dxDiagram-uiSettings": "Configuración",
        "dxDiagram-uiShowToolbox": "Cuadro de herramientas",
        "dxDiagram-uiSearch": "Buscar",
        "dxDiagram-uiStyle": "Estilo",
        "dxDiagram-uiLayout": "Diseño",
        "dxDiagram-uiLayoutTree": "Árbol",
        "dxDiagram-uiLayoutLayered": "Capas",
        "dxDiagram-uiDiagram": "Diagrama",
        "dxDiagram-uiText": "Texto",
        "dxDiagram-uiObject": "Objeto",
        "dxDiagram-uiConnector": "Conector",
        "dxDiagram-uiPage": "Página",

        "dxDiagram-shapeText": "Texto",
        "dxDiagram-shapeRectangle": "Rectángulo",
        "dxDiagram-shapeEllipse": "Elipse",
        "dxDiagram-shapeCross": "Cruz",
        "dxDiagram-shapeTriangle": "Triángulo",
        "dxDiagram-shapeDiamond": "Rombo",
        "dxDiagram-shapeHeart": "Corazón",
        "dxDiagram-shapePentagon": "Pentágono",
        "dxDiagram-shapeHexagon": "Hexágono",
        "dxDiagram-shapeOctagon": "Octágono",
        "dxDiagram-shapeStar": "Estrella",
        "dxDiagram-shapeArrowLeft": "Flecha izquierda",
        "dxDiagram-shapeArrowUp": "Flecha arriba",
        "dxDiagram-shapeArrowRight": "Flecha derecha",
        "dxDiagram-shapeArrowDown": "Flecha abajo",
        "dxDiagram-shapeArrowUpDown": "Flecha arriba/abajo",
        "dxDiagram-shapeArrowLeftRight": "Flecha izquierda/derecha",
        "dxDiagram-shapeProcess": "Proceso",
        "dxDiagram-shapeDecision": "Decisión",
        "dxDiagram-shapeTerminator": "Terminador",
        "dxDiagram-shapePredefinedProcess": "Proceso predefinido",
        "dxDiagram-shapeDocument": "Documento",
        "dxDiagram-shapeMultipleDocuments": "Varios documentos",
        "dxDiagram-shapeManualInput": "Entrada manual",
        "dxDiagram-shapePreparation": "Preparación",
        "dxDiagram-shapeData": "Datos",
        "dxDiagram-shapeDatabase": "Base de datos",
        "dxDiagram-shapeHardDisk": "Disco duro",
        "dxDiagram-shapeInternalStorage": "Almacenamiento interno",
        "dxDiagram-shapePaperTape": "Cinta de papel",
        "dxDiagram-shapeManualOperation": "Operación manual",
        "dxDiagram-shapeDelay": "Retraso",
        "dxDiagram-shapeStoredData": "Datos almacenados",
        "dxDiagram-shapeDisplay": "Pantalla",
        "dxDiagram-shapeMerge": "Combinar",
        "dxDiagram-shapeConnector": "Conector",
        "dxDiagram-shapeOr": "O",
        "dxDiagram-shapeSummingJunction": "Unión en Y",
        "dxDiagram-shapeContainerDefaultText": "Contenedor",
        "dxDiagram-shapeVerticalContainer": "Contenedor vertical",
        "dxDiagram-shapeHorizontalContainer": "Contenedor horizontal",
        "dxDiagram-shapeCardDefaultText": "Nombre de persona",
        "dxDiagram-shapeCardWithImageOnLeft": "Tarjeta con imagen a la izquierda",
        "dxDiagram-shapeCardWithImageOnTop": "Tarjeta con imagen en la parte superior",
        "dxDiagram-shapeCardWithImageOnRight": "Tarjeta con imagen a la derecha",

        "dxGantt-dialogTitle": "Title",
        "dxGantt-dialogStartTitle": "Start",
        "dxGantt-dialogEndTitle": "End",
        "dxGantt-dialogProgressTitle": "Progress",
        "dxGantt-dialogResourcesTitle": "Resources",
        "dxGantt-dialogResourceManagerTitle": "Resource Manager",
        "dxGantt-dialogTaskDetailsTitle": "Task Details",
        "dxGantt-dialogEditResourceListHint": "Edit Resource List",
        "dxGantt-dialogEditNoResources": "No resources",
        "dxGantt-dialogButtonAdd": "Add",
        "dxGantt-contextMenuNewTask": "New Task",
        "dxGantt-contextMenuNewSubtask": "New Subtask",
        "dxGantt-contextMenuDeleteTask": "Delete Task",
        "dxGantt-contextMenuDeleteDependency": "Delete Dependency",
        "dxGantt-dialogTaskDeleteConfirmation": "Deleting a task also deletes all its dependencies and subtasks. Are you sure you want to delete this task?",
        "dxGantt-dialogDependencyDeleteConfirmation": "Are you sure you want to delete the dependency from the task?",
        "dxGantt-dialogResourcesDeleteConfirmation": "Deleting a resource also deletes it from tasks to which this resource is assigned. Are you sure you want to delete these resources? Resources: {0}",
        "dxGantt-dialogConstraintCriticalViolationMessage": "The task you are attempting to move is linked to a second task by a dependency relation. This change would conflict with dependency rules. How would you like to proceed?",
        "dxGantt-dialogConstraintViolationMessage": "The task you are attempting to move is linked to a second task by a dependency relation. How would you like to proceed?",
        "dxGantt-dialogCancelOperationMessage": "Cancel the operation",
        "dxGantt-dialogDeleteDependencyMessage": "Delete the dependency",
        "dxGantt-dialogMoveTaskAndKeepDependencyMessage": "Move the task and keep the dependency",
        "dxGantt-dialogConstraintCriticalViolationSeveralTasksMessage": "The task you are attempting to move is linked to another tasks by dependency relations. This change would conflict with dependency rules. How would you like to proceed?",
        "dxGantt-dialogConstraintViolationSeveralTasksMessage": "The task you are attempting to move is linked to another tasks by dependency relations. How would you like to proceed?",
        "dxGantt-dialogDeleteDependenciesMessage": "Delete the dependency relations",
        "dxGantt-dialogMoveTaskAndKeepDependenciesMessage": "Move the task and keep the dependencies",
        "dxGantt-undo": "Undo",
        "dxGantt-redo": "Redo",
        "dxGantt-expandAll": "Expand All",
        "dxGantt-collapseAll": "Collapse All",
        "dxGantt-addNewTask": "Add New Task",
        "dxGantt-deleteSelectedTask": "Delete Selected Task",
        "dxGantt-zoomIn": "Zoom In",
        "dxGantt-zoomOut": "Zoom Out",
        "dxGantt-fullScreen": "Full Screen",
        "dxGantt-quarter": "Q{0}",
        "dxGantt-sortingAscendingText": "Orden Ascendente",
        "dxGantt-sortingDescendingText": "Orden Descendente",
        "dxGantt-sortingClearText": "Limpiar Ordenamiento",
        "dxGantt-showResources": "Mostrar Recursos",
        "dxGantt-showDependencies": "Mostrar Dependencias",
        "dxGantt-dialogStartDateValidation": "La fecha de inicio debe ser anterior {0}",
        "dxGantt-dialogEndDateValidation": "La fecha de finalización debe ser posterior {0}",

        "dxGallery-itemName": "Gallery item",

        "dxMultiView-elementAriaRoleDescription": "MultiView",
        "dxMultiView-elementAriaLabel": "Use the arrow keys or swipe to navigate between views",
        "dxMultiView-itemAriaRoleDescription": "View",
        "dxMultiView-itemAriaLabel": "{0} of {1}",

        "dxSplitter-resizeHandleAriaLabel": "Split bar"
    }
}
