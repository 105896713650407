import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonAtivosAbate} from './jsonAtivosAbate.module.interface';
import {IJsonAtivos} from '../../../entities/ativos/interfaces/jsonAtivos.entity.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AtivosAbateService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/ativosabate`;
  }

  public getAtivosPorAbater(): TServiceResponse<IJsonAtivosAbate> {
    return this._apiService.get<IJsonAtivosAbate>({
      url: `${this._path}/ativosporabater`
    });
  }

  public getRadicalAbate(): TServiceResponse<IJsonAtivosAbate> {
    return this._apiService.get<IJsonAtivosAbate>({
      url: `${this._path}/radicalabate`
    });
  }

  public postRegistaAbate(abate: IJsonAtivosAbate): TServiceResponse<IJsonAtivos> {
    return this._apiService.post<IJsonAtivos, IJsonAtivosAbate>({
      url: `${this._path}/registaabate`,
      body: abate
    });
  }
}
