import {Injectable} from '@angular/core';
import {copy, isArray, isDefined, isObject, isString, Logger} from 'pl-comps-angular';
import {IModule, IModuleDefinition} from './module.definition.interface';

@Injectable({
  providedIn: 'root'
})
export class ModuleRegistryService {
  private readonly _modules: Map<string, IModule>;
  private readonly _modulesAliasMap: Map<string, IModule>;
  private readonly _modulesNames: Set<string>;
  private readonly _modulesAliases: Set<string>;

  constructor(private readonly _logger: Logger) {
    this._modules = new Map<string, IModule>();
    this._modulesAliasMap = new Map<string, IModule>();
    this._modulesNames = new Set<string>();
    this._modulesAliases = new Set<string>();
  }

  public register(module: IModuleDefinition): void {
    if (!isObject(module)) {
      return;
    }
    const moduleName: string = module.name;
    if (this._modulesAliases.has(moduleName)) {
      this._logger.error(`Module name [${moduleName}] conflicts with a registered alias, skipping`);
      return;
    }
    const moduleToAdd: IModule = {...module, caption: undefined};
    moduleToAdd.caption = () => this._caption(moduleToAdd);
    if (isArray(moduleToAdd.nameAlias)) {
      for (const alias of moduleToAdd.nameAlias) {
        if (this._modulesNames.has(alias)) {
          this._logger.error(`Module alias [${alias}] conflicts with a registered module name, skipping`);
          continue;
        }
        if (this._modulesAliases.has(alias)) {
          this._logger.error(`Module alias [${alias}] is already registered, skipping`);
          continue;
        }
        this._modulesAliases.add(alias);
        this._modulesAliasMap.set(alias, moduleToAdd);
      }
    }
    if (!isObject(moduleToAdd.state.data)) {
      moduleToAdd.state.data = {};
    }
    if (!moduleToAdd.state.data.pageTitle) {
      moduleToAdd.state.data.pageTitle = `global.menu.${moduleToAdd.name}`;
    }
    if (!moduleToAdd.state.data.sidebarTitle) {
      moduleToAdd.state.data.sidebarTitle = moduleToAdd.state.data.pageTitle;
    }
    this._modules.set(moduleName, moduleToAdd);
  }

  public get(name: string, errorOnNotFound: boolean = true, clone: boolean = true): IModule {
    let modulo: IModule = this._modules.get(name);
    if (!modulo) {
      modulo = this._modulesAliasMap.get(name);
    }
    if (errorOnNotFound && !modulo) {
      this._logger.error(`Module [${name}] is not registered`);
    }
    return isDefined(modulo) ? (clone !== false ? copy(modulo) : modulo) : undefined;
  }

  public getAll(clone: boolean = true): Array<IModule> {
    const modules = [];
    this._modules.forEach((module: IModule) => {
      modules.push(clone !== false ? copy(module) : module);
    });
    return modules;
  }

  private _caption(moduleOrName: string | IModule): string {
    const module: IModule = isString(moduleOrName) ? this.get(moduleOrName, false, false) : moduleOrName;
    return module ? module.state.data.pageTitle : undefined;
  }
}
