<div class="dx-context-menu dx-menu-base" [ngClass]="cssClass" role="menu">
  <div class="dx-submenu">
    <ul class="dx-menu-items-container" role="none">
      <ng-container *ngFor="let action of actions" [ngSwitch]="action.divider">
        <li *ngSwitchDefault class="dx-menu-item-wrapper" role="none">
          <div
            class="dx-item dx-menu-item"
            [class.dx-menu-item-has-text]="action.caption"
            [class.dx-menu-item-has-icon]="hasIcon"
            [class.dx-state-disabled]="action.disabled"
            (mouseenter)="onMouseEnterMenuItem($event)"
            (mouseleave)="onMouseLeaveMenuItem($event)"
            (click)="clickedAction(action, $event)"
            role="menuitem">
            <div class="dx-item-content dx-menu-item-content">
              <i *ngIf="hasIcon" class="dx-icon fa fa-fw" [ngClass]="action.icon" aria-hidden="true"></i>
              <span class="dx-menu-item-text" [plTranslate]="action.caption"></span>
            </div>
          </div>
        </li>

        <li class="dx-menu-separator" *ngSwitchCase="true"></li>
      </ng-container>
    </ul>
  </div>
</div>
