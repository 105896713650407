<div class="docsComerciaisConfigNomeExportFile">
  <fieldset class="mb-2">
    <legend>
      <span class="me-1" [translate]="'configNomeExportFile.caption.groupConfigurador'"></span
      ><i (click)="toggleCollapse()" [ngClass]="collapsedMasks ? 'fa fa-fw fa-caret-right' : 'fa fa-fw fa-caret-down'"></i>
    </legend>
    <pl-animation-collapse [collapsed]="collapsedMasks">
      <pl-group>
        <pl-group>
          <label [translate]="'configNomeExportFile.caption.filesType'"></label>
          <edit>
            <pl-autocomplete
              [source]="exportMaskNameFilesTypeList"
              [model]="model.tipo"
              (evtSelected)="exportMaskNameFilesTypeChanged($event.item)"
              [rowTemplate]="outputExportMaskNameFilesTypeList"
              [output]="outputExportMaskNameFilesTypeList"
              plAutoFocus>
            </pl-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'configNomeExportFile.caption.nDocFa'"></label>
          <edit>
            <entity-autocomplete
              entity="docfas"
              [model]="modelDocfa"
              (modelChange)="nDocfaChanged($event)"
              [filter]="nDocfaFilter"
              [properties]="{disabled: model.tipo?.tipo !== maskTiposEnum.Faturacao}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'configNomeExportFile.caption.fieldType'"></label>
          <edit>
            <pl-autocomplete
              [source]="exportMaskNameFilesTypeFieldList"
              [model]="model.tipoCampo"
              [rowTemplate]="rowExportMaskNameFilesTypeFieldList"
              [output]="outputExportMaskNameFilesTypeFieldList"
              (evtSelected)="exportMaskNameFilesTypeFieldChanged($event.item)"
              [properties]="{disabled: (model.tipo?.tipo === maskTiposEnum.Faturacao && modelDocfa.nDocFa === 0) || exportMaskNameFilesTypeFieldList?.length === 0}">
            </pl-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label translate="configNomeExportFile.caption.valor"></label>
          <edit>
            <pl-edit type="text" [(model)]="model.tipoConfig.value" [properties]="{disabled: model.tipoCampo?.tipoCampo !== maskFieldsEnum.CaracterFixo}"></pl-edit>
          </edit>
          <actions>
            <pl-button attrName="btnAdicionar" klass="btn-success" [onClick]="adicionarMascara" [disabled]="model.tipoCampo?.tipoCampo === maskTiposEnum.None">
              <i class="fa fa-fw fa-plus-circle"></i><span [translate]="'global.btn.add'"></span>
            </pl-button>
          </actions>
        </pl-group>
      </pl-group>
      <dx-data-grid [cgDxDataGrid]="dataGridDefinitionMasks" [dataSource]="dataSourceMasks">
        <div *dxTemplate="let item of 'actionsTemplate'">
          <dx-button class="dx-button-icon-fontawesome" [hint]="'global.btn.delete' | translate" (click)="deleteMascara(item.rowIndex)" icon="fa fa-fw fa-eraser" [stylingMode]="'text'"></dx-button>
        </div>
      </dx-data-grid>

      <div style="display: flex; align-items: center; gap: 15px; background: #f6f6f6; margin: 15px -0.65rem -0.55rem; padding: 0.7rem">
        <span *ngIf="previewStr">
          <span style="font-weight: bold" class="me-2"><span [translate]="'configNomeExportFile.caption.mascaraLabel'"></span>:</span><span style="color: #8547e6">{{ previewStr }}</span>
        </span>
        <pl-button style="margin-left: auto" klass="btn-sm btn-primary" [onClick]="save"><i class="fa fa-fw fa-floppy-o"></i><span [translate]="'global.btn.save'"></span></pl-button>
      </div>
    </pl-animation-collapse>
  </fieldset>

  <h4 style="font-size: 16px; color: #2196f3; margin: 1em 0" [translate]="'configNomeExportFile.caption.groupConfigs'"></h4>

  <dx-data-grid [cgDxDataGrid]="dataGridDefinitionConfig" [dataSource]="dataSourceConfig">
    <div *dxTemplate="let item of 'actionsTemplate'">
      <dx-button class="dx-button-icon-fontawesome" [hint]="'global.btn.edit' | translate" (click)="editarConfig(item.data)" icon="fa fa-fw fa-pencil" [stylingMode]="'text'"></dx-button>
      <dx-button class="dx-button-icon-fontawesome" [hint]="'global.btn.delete' | translate" (click)="deleteConfig(item.rowIndex)" icon="fa fa-fw fa-eraser" [stylingMode]="'text'"></dx-button>
    </div>
  </dx-data-grid>

  <div style="padding-top: 5px; display: none">
    <div class="card">
      <div class="card-header inline-block">
        <span [translate]="'configNomeExportFile.caption.groupConfigs'"></span>
      </div>
      <dx-data-grid [cgDxDataGrid]="dataGridDefinitionConfig" [dataSource]="dataSourceConfig">
        <div *dxTemplate="let item of 'actionsTemplate'">
          <dx-button class="dx-button-icon-fontawesome" [hint]="'global.btn.edit' | translate" (click)="editarConfig(item.data)" icon="fa fa-fw fa-pencil" [stylingMode]="'text'"></dx-button>
          <dx-button class="dx-button-icon-fontawesome" [hint]="'global.btn.delete' | translate" (click)="deleteConfig(item.rowIndex)" icon="fa fa-fw fa-eraser" [stylingMode]="'text'"></dx-button>
        </div>
      </dx-data-grid>
    </div>
  </div>
</div>
