import {RawParams} from '@uirouter/core';

export interface ILoadmoduleStateParams {
  moduleName: string;
  moduleParams: RawParams;
  loaded: boolean;
}

export const STATE_NAME_LOAD_MODULE = 'loadmodule';

export const STATE_URL_LOAD_MODULE = STATE_NAME_LOAD_MODULE;
