import {ICGEnumeratorMap, ICGEnumeratorMapWithFilter} from '../../../../common/interfaces/interfaces';
import {ICGUserAccessEvtChanged} from '../accesses/utilizadores.accesses.interface';
import {IEmpresa} from '../../../entities/empresas/empresas.entity.interface';
import {IJsonConfigERP} from '../../../entities/configserp/jsonConfigERP.entity.interface';
import {ROLE} from '../../../services/role.const';
import {IJsonPortal} from '../../../entities/portal/jsonPortal.entity.interface';

export enum EPoliciesCategory {
  Comercial = 'erp',
  Contabilidade = 'contabilidade',
  Ativos = 'ativos',
  Addons = 'addons'
}

export interface IUserInstallation {
  centralGestId: number;
  centralGestName: string;
  centralGestCaption: string;
  userId: number;
  dataSource: IUserInstallationDataSource;
  empresas: Array<IUserInstallationEmpresa>;
  empresasLoading: boolean;
  accesses: ICGEnumeratorMap<string, IUserInstallationAccess>;
  policies: ICGEnumeratorMap<string, IUserInstallationPolicy>;
  policiesNeedLoading: boolean;
  showPolicies: boolean;
}

export interface IUserInstallationDataSource {
  filterValue: string;
  pageIndex: number;
  pageSize: number;
}

export interface IUserInstallationAccess extends ICGEnumeratorMapWithFilter<string, IUserInstallationAccessCompany> {
  portal: string | ROLE;
  portalIncludes: Array<IJsonPortal>;
  name: string;
  selectedAll: boolean;
  indeterminate: boolean;
}

export interface IUserInstallationAccessCompany extends IUserInstallationEmpresa {
  selected: boolean;
  indeterminate: boolean;
  ativo: boolean;
  nUtilizador: number;
  nomeUtilizador: string;
  codEmpregado: number;
  nomeEmpregado: string;
}

export interface IUserInstallationEmpresa extends IEmpresa {
  centralGestId: number;
  centralGestName: string;
}

export interface IUserInstallationPolicy {
  centralGestId: number;
  centralGestName: string;
  nEmpresa: string;
  name: string;
  visible: boolean;
  codEmp: number;
  nomeEmpregado: string;
  groupsVisibility: Map<EPoliciesCategory, boolean>;
  configsErp: Array<IUserInstallationPolicyConfigERP>;
  configsErpMap: ICGEnumeratorMap<string, IUserInstallationPolicyConfigERP>;
  loadingAccessesErrored: boolean;
}

export interface IUserInstallationPolicyConfigERP<T = unknown> extends IJsonConfigERP<T> {
  originalValue: T;
  indeterminate: boolean;
}

export interface IUserInstallationEventChanged {
  installation: IUserInstallation;
}

export interface IUserInstallationEventChangedFilterValue extends IUserInstallationEventChanged {
  value: string;
  access: IUserInstallationAccess;
}

export interface IUserInstallationEventChangedSelectAllCompanies extends IUserInstallationEventChanged {
  value: boolean;
  access: IUserInstallationAccess;
}

export interface IUserInstallationEventChangedSelectCompany extends IUserInstallationEventChangedSelectAllCompanies {
  company: IUserInstallationAccessCompany;
  evaluate: boolean;
}

export interface IUserInstallationEventChangedConfigsErpValue extends IUserInstallationEventChanged {
  nEmpresa: string;
}

export interface IUserInstallationEventChangedConfigErpValue extends IUserInstallationEventChangedConfigsErpValue {
  event: ICGUserAccessEvtChanged;
}

export interface IUserInstallationPolicyLoadingError {
  nEmpresa: string;
  message: string;
}

export function emptyUserInstallation(): IUserInstallation {
  return {
    centralGestId: 0,
    centralGestName: '',
    centralGestCaption: '',
    userId: 0,
    dataSource: {
      filterValue: undefined,
      pageIndex: undefined,
      pageSize: undefined
    },
    empresas: [],
    empresasLoading: false,
    accesses: {
      keys: [],
      values: new Map<string, IUserInstallationAccess>()
    },
    policies: {
      keys: [],
      values: new Map<string, IUserInstallationPolicy>()
    },
    policiesNeedLoading: true,
    showPolicies: false
  };
}

const POLICIES_PREFIX = 'acessos.erpcloud';
const POLICIES_GROUP_ADDONS = `${POLICIES_PREFIX}.addons`;
const POLICIES_GROUP_COMERCIAL = `${POLICIES_PREFIX}.gestaocomercial.documentos`;
const POLICIES_GROUP_CONTABILIDADE = `${POLICIES_PREFIX}.contabilidade`;
const POLICIES_GROUP_ATIVOS = `${POLICIES_PREFIX}.ativos`;

export function evaluateConfigERPCategory(configErpName: string): EPoliciesCategory | undefined {
  if (configErpName.startsWith(POLICIES_GROUP_ADDONS)) {
    return EPoliciesCategory.Addons;
  }
  if (configErpName.startsWith(POLICIES_GROUP_COMERCIAL)) {
    return EPoliciesCategory.Comercial;
  }
  if (configErpName.startsWith(POLICIES_GROUP_CONTABILIDADE)) {
    return EPoliciesCategory.Contabilidade;
  }
  if (configErpName.startsWith(POLICIES_GROUP_ATIVOS)) {
    return EPoliciesCategory.Ativos;
  }
  return undefined;
}
