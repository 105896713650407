<div
  *ngIf="!options.readonly"
  class="input-form"
  [ngClass]="{'input-group pl-input-group': options.raw === false && (hasGroup || showClear), 'input-focus': inputFocused, 'input-disabled': inputElement.disabled}"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()">
  <input
    #inputElement
    type="text"
    class="form-control"
    inputmode="numeric"
    autocapitalize="off"
    autocomplete="off"
    editEventsHandlerIgnore="focus,blur"
    [formControl]="formControl"
    [attr.name]="attrName"
    [attr.placeholder]="placeholder"
    [ngClass]="inputClass"
    [maxlength]="validate ? options.validators.maxlength?.value : undefined"
    [minlength]="validate ? options.validators.minlength?.value : undefined"
    [pattern]="validate ? options.validators.pattern?.value : undefined"
    [required]="validate && options.validators.required?.value"
    [editEventsHandler]="options.events"
    [editEventsHandlerValue]="value"
    (focus)="onInputFocus($event)"
    (blur)="onInputBlur($event)" />
  <button *ngIf="showClear" type="button" class="btn hollow-form" tabindex="-1" (click)="clearViewValue()">
    <i class="pl-icon-close" aria-hidden="true"></i>
  </button>
  <ng-container *ngIf="hasGroup && options.raw !== true">
    <button
      *ngFor="let itemGroup of itemGroups"
      #buttonElement
      type="button"
      class="btn"
      plDelegateEventDownwards
      [ngClass]="itemGroup.klass"
      [class.raw]="options.raw || itemGroup.raw"
      [disabled]="itemGroup.disabled ?? options.disabled ?? false"
      [attr.tabindex]="itemGroup.tabindex"
      [attr.title]="itemGroup.title"
      [plPromise]="itemGroup.promise">
      <ng-container *ngTemplateOutlet="itemGroup.templateRef; context: {button: buttonElement, isMouseIn: isMouseIn}"></ng-container>
    </button>
  </ng-container>
</div>

<pl-messages *ngIf="validate" [instance]="self" [formControlInstance]="formControl" [modelValue]="value" [validate]="validate" [validators]="validators" [ngFormInstance]="ngFormInstance">
</pl-messages>

<span *ngIf="options.readonly" class="form-control-align">{{ formControl.value }}</span>
