import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_QPNJ} from './qpnj.entity.interface';
import {IJsonQPNJ} from './jsonqpnj.entity.interface';

export const ENTITY_QPNJ: IEntityDefinition<IJsonQPNJ> = {
  name: ENTITY_NAME_QPNJ,
  roles: [ROLE.RH],
  searchPlaceholder: 'qpnj.pesquisa',
  metadata: {
    keyName: 'codNatJuridica',
    fields: [
      {
        name: 'codNatJuridica',
        caption: 'qpnj.fields.codNatJuridica',
        placeholder: 'qpnj.fields.codNatJuridicaPlaceholder',
        validators: {required: true, maxlength: 2}
      },
      {name: 'nome', caption: 'qpnj.fields.nome', placeholder: 'qpnj.fields.nomePlaceholder', validators: {required: true, maxlength: 60}}
    ],
    order: 'codNatJuridica',
    searchFields: 'codNatJuridica,nome',
    listFields: 'codNatJuridica,nome'
  },
  autocomplete: {
    rowTemplate: '{{codNatJuridica}} - {{nome}}',
    output: 'nome',
    searchFields: 'codNatJuridica,nome'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    search: true,
    delete: false,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.qpnj'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.qpnj'
      }
    }
  }
};
