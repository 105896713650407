import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonPlanoContasAlternativoLin} from '../../jsonPlanoContasAlternativo.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {ENTITY_NAME_PLANOS_CONTAS_ALTERNATIVOS, IPlanosContasAlternativosEntityService} from '../../planosContasAlternativos.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IPlUploadCallback, IPlUploadFile, PlAlertService} from 'pl-comps-angular';

@Component({
  selector: 'planos-contas-alternativos-import-modal',
  templateUrl: './planosContasAlternativos.import.modal.component.html'
})
export class PlanosContasAlternativosImportModalComponent extends CGModalComponent<Array<IJsonPlanoContasAlternativoLin>> {
  @Input() public pocAltCabID: string;

  public readonly callback: IPlUploadCallback;

  private readonly _servicePlanosContasAlt: IPlanosContasAlternativosEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._servicePlanosContasAlt = this._entityServiceBuilder.build(ENTITY_NAME_PLANOS_CONTAS_ALTERNATIVOS);
    this.callback = {};
  }

  public close(): Promise<void> {
    const files: Array<IPlUploadFile> = this.callback.getAcceptedFiles();
    if (!files.length) {
      this._plAlertService.error('planoscontasalternativos.messages.errorFiles');
      return Promise.resolve();
    }
    return this._servicePlanosContasAlt.importPlanoAlt(this.pocAltCabID, files[0].file).then((response: HttpResponse<Array<IJsonPlanoContasAlternativoLin>>) => {
      this._plAlertService.success('planoscontasalternativos.messages.importPlanoAlt');
      super.close(response.body);
    });
  }
}
