import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {
  IIntegracaoSalariosConfig,
  IIntegracaoSalariosError,
  IIntegracaoSalariosProc,
  IIntegracaoSalariosProcessData,
  IIntegracaoSalariosProcessResult,
  IIntegracaoSalariosStatus
} from './integracaoSalarios.module.interface';
import {TDate} from '../../../../common/dates';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class IntegracaoSalariosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${_apiService.path.restapi}/rhintegracaosalarios`;
  }

  public getJobStatus(): Promise<IIntegracaoSalariosStatus> {
    return new Promise<IIntegracaoSalariosStatus>((resolve, reject) => {
      this._apiService
        .get<IIntegracaoSalariosStatus>({
          url: `${this._path}/status`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public stopJob(): TServiceResponse<void> {
    return this._apiService.post({
      url: this.stopJobUrl(),
      body: null
    });
  }

  public stopJobUrl(): string {
    return `${this._path}/stop-job`;
  }

  public getConfig(nEmpresa: string): Promise<IIntegracaoSalariosConfig> {
    return new Promise<IIntegracaoSalariosConfig>((resolve, reject) => {
      this._apiService
        .get<IIntegracaoSalariosConfig>({
          url: `${this._path}/config`,
          params: {nEmpresa: nEmpresa}
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public saveConfig(nEmpresa: string, config: IIntegracaoSalariosConfig): Promise<IIntegracaoSalariosConfig> {
    return new Promise<IIntegracaoSalariosConfig>((resolve, reject) => {
      this._apiService
        .post<IIntegracaoSalariosConfig>({
          url: `${this._path}/config`,
          params: {nEmpresa: nEmpresa},
          body: config
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public loadProcs(nEmpresa: string, tipoIntegracao: number, tipoProce: number, dataAte: TDate): Promise<Array<IIntegracaoSalariosProc>> {
    return new Promise<Array<IIntegracaoSalariosProc>>((resolve, reject) => {
      this._apiService
        .get<Array<IIntegracaoSalariosProc>>({
          url: `${this._path}/load-procs`,
          params: {
            nEmpresa: nEmpresa,
            tipoIntegracao: tipoIntegracao,
            tipoProce: tipoProce,
            dataAte: dataAte
          }
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public loadProcsForRemove(nEmpresa: string, tipoIntegracao: number, tipoProce: number, codEmpDe: number, codEmpAte: number, dataDe: TDate, dataAte: TDate): Promise<Array<IIntegracaoSalariosProc>> {
    return new Promise<Array<IIntegracaoSalariosProc>>((resolve, reject) => {
      this._apiService
        .get<Array<IIntegracaoSalariosProc>>({
          url: `${this._path}/load-procs-for-remove`,
          params: {
            nEmpresa: nEmpresa,
            tipoIntegracao: tipoIntegracao,
            tipoProce: tipoProce,
            codEmpDe: codEmpDe,
            codEmpAte: codEmpAte,
            dataDe: dataDe,
            dataAte: dataAte
          }
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public process(nEmpresa: string, simulator: boolean, data: IIntegracaoSalariosProcessData): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/process`,
      params: {nEmpresa: nEmpresa, simulator: simulator},
      body: data,
      reportExceptions: false
    });
  }

  public getProcessResults(nEmpresa: string): Promise<Array<IIntegracaoSalariosProcessResult>> {
    return new Promise<Array<IIntegracaoSalariosProcessResult>>((resolve, reject) => {
      this._apiService
        .get<Array<IIntegracaoSalariosProcessResult>>({
          url: `${this._path}/process-results`,
          params: {nEmpresa: nEmpresa},
          reportExceptions: false
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public removerIntegracao(nEmpresa: string, tipoIntegracao: number, selProcs: Array<string>): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/remover-integracao`,
      params: {nEmpresa: nEmpresa, tipoIntegracao: tipoIntegracao},
      body: selProcs,
      reportExceptions: false
    });
  }

  public getIntegSalariosErrors(nEmpresa: string): Promise<Array<IIntegracaoSalariosError>> {
    return new Promise<Array<IIntegracaoSalariosError>>((resolve, reject) => {
      this._apiService
        .get<Array<IIntegracaoSalariosError>>({
          url: `${this._path}/integ-errors`,
          params: {nEmpresa: nEmpresa},
          reportExceptions: false
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }
}
