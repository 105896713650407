<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'arquivodigital.docviewerrecolha.attachexistingdocmodal.title'"></h4>
</div>

<div class="modal-body">
  <div class="contabilidadedigital-genericviewer-current-folder">
    <nav aria-label="contabilidadedigital-genericviewer-current-folder">
      <ol class="breadcrumb">
        <li class="breadcrumb-item contabilidadedigital-genericviewer-current-folder-label">
          <i class="fa fa-folder" aria-hidden="true"></i>&nbsp;<span [translate]="'arquivodigital.genericviewer.text.currentFolder'"></span>:
        </li>
        <li class="breadcrumb-item contabilidadedigital-genericviewer-current-folder-item" *ngFor="let folderName of foldersNamePath">{{ folderName }}</li>
      </ol>
    </nav>
  </div>

  <hr />

  <pl-upload
    [autoProcessQueue]="false"
    [hideActionCancel]="true"
    [hideActionRetry]="true"
    [hideActionUploadAll]="true"
    [hideActionUpload]="true"
    [maxFiles]="1"
    [maxFileSize]="maxFileSize"
    [resizeTargetSize]="maxFileSize"
    resizeMethod="contain"
    [resizeBeforeAccept]="true"
    [uploadMultiple]="false"
    (evtAcceptedFile)="acceptedFile($event)"
    acceptedFiles=".pdf,.png,.jpg">
  </pl-upload>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light action-dismiss" plAutoFocus (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
</div>
