import {IEntityAutocompleteFieldsMap} from '../../../components/cg/autocomplete/entity.autocomplete.component.interface';
import {IEntityDefinition} from '../../../components/entity/entity.definition.interface';
import {ICGExceptionError} from '../../../components/exceptions/exceptions.service.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IJsonContaAnalitica, IJsonDocContabilidade, IJsonDocContabilidadeLinha, IJsonRegularizacaoCampo40} from './jsonDocContabilidade.interface';
import {IRestCommand} from './restDocsContabilidadeCommands.interface';
import {ETipoCalculoDiferimento} from '../../diferimentos/jsonCalculoDiferimentos.interface';
import {IRadioGroup} from '../../../../common/interfaces/interfaces';
import {deepFreeze} from 'pl-comps-angular';

export enum EDocContabilidadeCommandType {
  AddLinha = 'AddLinha',
  AnularDiferimento = 'AnularDiferimento',
  CodMoedaChange = 'CodMoedaChange',
  ContribuinteChange = 'ContribuinteChange',
  DataDocChange = 'DataDocChange',
  DataLancamentoChange = 'DataLancamentoChange',
  DataVencimentoChange = 'DataVencimentoChange',
  DescricaoChange = 'DescricaoChange',
  FirstDoc = 'FirstDoc',
  InitDocumento = 'InitDocumento',
  LastDoc = 'LastDoc',
  LinhaAdicionarContasAnalitica = 'linhaAdicionarContasAnalitica',
  LinhaAdicionarRegularizacaoCampo40 = 'linhaAdicionarRegularizacaoCampo40',
  LinhaCambioChange = 'LinhaCambioChange',
  LinhaContaChange = 'linhaContaChange',
  LinhaContribuinteChange = 'linhaContribuinteChange',
  LinhaDelete = 'linhaDelete',
  LinhaDataDocChange = 'LinhaDataDocChange',
  LinhaEfetuarRetencao = 'linhaEfetuarRetencao',
  LinhaGenerateContaAnalitica = 'linhaGenerateContaAnalitica',
  LinhaImputaValores = 'linhaImputaValores',
  LinhaObterMovAb = 'linhaObterMovAb',
  LinhaObterSaldoConta = 'linhaObterSaldoConta',
  LinhaValorChange = 'linhaValorChange',
  LinhaValorIvaChange = 'linhaValorIvaChange',
  LinhaValorMEChange = 'LinhaValorMEChange',
  NDescritivoChange = 'NDescritivoChange',
  NDocExternoChange = 'NDocExternoChange',
  NextDoc = 'NextDoc',
  NovoByDocOCRCabID = 'NovoByDocOCRCabID',
  NovoByPredefinido = 'NovoByPredefinido',
  NovoByPredefinidoID = 'NovoByPredefinidoID',
  NovoDocumento = 'NovoDocumento',
  PerguntaCC = 'PerguntaCC',
  PreviousDoc = 'PreviousDoc',
  RegistaNif = 'RegistaNif',
  SimulaGravacao = 'SimulaGravacao'
}

export enum EDocContabilidadeRetencaoDispoRendimento {
  Imediata,
  NoPagamento
}

export type TDocContabilidadeCommandRawResult<T extends IRestCommand = IRestCommand> = Promise<T>;

export type TDocContabilidadeCommandResult = Promise<IDocContabilidade>;

export interface IDocsContabilidadeEntityService extends IEntityService<IJsonDocContabilidade> {
  sendEmail(extpoccabID: string, email: string, periodo: string): TServiceResponse<IJsonDocContabilidade>;

  sendCommand<T = IRestCommand>(id: string, commandName: string, command: IRestCommand): TServiceResponse<T>;

  podeUltrapassarToleranciaIVA(nDiario: number): Promise<boolean>;
}

export interface IDocsContabilidadeEntity extends IEntityDefinition<IJsonDocContabilidade, IDocsContabilidadeEntityService> {}

export interface IDocsContabilidadeEditCCustoType {
  label: string;
  entity: string;
  keyTarget: string;
  description: string;
  condition: string;
  fieldsMap: IEntityAutocompleteFieldsMap;
}

export interface IContaAnalitica extends IJsonContaAnalitica {
  mascara: string;
  descricaoMascara: string;
  linhasCCusto: Array<IJsonContaAnalitica>;
  contaGerada: string;
  valorRegistado: number;
  temCCusto: boolean;
  temZona: boolean;
  temDepartamento: boolean;
  temSubDepartamento: boolean;
  temFamilia: boolean;
  temGrandeFamilia: boolean;
  temSubFamilia: boolean;
  temCodContabilistico: boolean;
  temTipoArtigo: boolean;
  temClasse: boolean;
  temCategoria: boolean;
  temVendedor: boolean;
  temProcesso: boolean;
  artigocategoriaNome?: string;
  artigoclasseNome?: string;
  ccustoNome?: string;
  codcontabilisticoNome?: string;
  contaNome?: string;
  departamentoNome?: string;
  familiaNome?: string;
  grandefamiliaNome?: string;
  processoNome?: string;
  subdepartamentoNome?: string;
  subfamiliaNome?: string;
  tipoartigoNome?: string;
  vendedorNome?: string;
  zonaNome?: string;
}

export interface IDocContabilidadeCCustoResult {
  contaAnalitica: IContaAnalitica;
  docContabilidade: IDocContabilidade;
}

export interface IDocContabilidadeRetencao {
  valorTributavel: number;
  valorRetido: number;
  dispoRendimento: EDocContabilidadeRetencaoDispoRendimento;
  contaCorrenteTemRetencaoDeduzida: boolean;
  numeroGuiaPagamento: string;
}

export interface IDocContabilidade extends IJsonDocContabilidade {
  linhas: Array<IDocContabilidadeLinha>;
  nDocExterno?: string;
  totalCredidoBaseTributavel?: number;
  totalCredidoIva?: number;
  totalCreditoMoedaCorrente?: number;
  totalCreditoMoedaEstrangeira?: number;
  totalDebitoBaseTributavel?: number;
  totalDebitoIva?: number;
  totalDebitoMoedaCorrente?: number;
  totalDebitoMoedaEstrangeira?: number;
  _invalidDataLancamento?: boolean;
  _invalidNif?: boolean;
  _hasLinhasImputadas?: boolean;
}

export interface IDocContabilidadeLinha extends IJsonDocContabilidadeLinha {
  _index?: number;
  _invalidNContaDebito?: boolean;
  _invalidNContaCredito?: boolean;
  _invalidNif?: boolean;
  _invalidDataDoc?: boolean;
  _valorOriginal?: number;
  _valorTaxaOriginal?: number;
  _deletable?: boolean;
  _isImputacao?: boolean;
  _imputaDocPrincipal?: boolean;
  _predefinidoFake?: boolean;
  _extPocCabImput?: string;
  _nDocImput?: string;
  _promiseLinhaImputada?: Promise<unknown>;
  _showBtnImputacao?: boolean;
}

export interface IRegularizacaoCampo40 extends IJsonRegularizacaoCampo40 {
  valorRetido?: number;
  _valorRetidoOriginal?: number;
}

export interface IDocContabilidadeException extends ICGExceptionError {
  docContabilidade: IDocContabilidade;
  linhaNLanc: string;
}

export interface IDocsContabilidadeStateParams {
  redirect: boolean;
}

export interface IDocsContabilidadePostParams {
  anexarGDocId?: string;
  anexarGDocFolderId?: string;
  tipoDiferimento?: ETipoCalculoDiferimento;
}

export const ENTITY_NAME_DOCS_CONTABILIDADE = 'docscontabilidade';

export const RADIO_GROUP_RETENCAO_DISPONIBILIDADE_RENDIMENTO: IRadioGroup<EDocContabilidadeRetencaoDispoRendimento> = deepFreeze<IRadioGroup<EDocContabilidadeRetencaoDispoRendimento>>({
  groupItems: [
    {value: EDocContabilidadeRetencaoDispoRendimento.Imediata, label: 'docscontabilidade.retencao.imediata'},
    {value: EDocContabilidadeRetencaoDispoRendimento.NoPagamento, label: 'docscontabilidade.retencao.noPagamento'}
  ]
});

export function validateBtnImputacao(documento: IDocContabilidade, linha: IDocContabilidadeLinha): boolean {
  return !documento.linhas.some((linhaDocumento: IDocContabilidadeLinha) => linhaDocumento.nLanc === linha.nLancImput);
}
