import {BancosExtratoModuleComponent} from './components/bancosExtrato.module.component';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {MODULE_NAME_BANCOS_EXTRATO, MODULE_NAME_BANCOS_EXTRATO_SIMPLE} from './bancosExtrato.module.interface';
import {RESOLVER_CG_BANKING_ACESSO, RESOLVER_CONFIGS_BANCOS_EXTRATO, RESOLVER_EMPRESA_CGON_CG_BANKING_EXPIRED, RESOLVER_EMPRESA_TEM_CG_BANKING} from './bancosExtrato.module.router';
import {RESOLVER_CG_STORE_URL_BACKOFFICE} from '../../../config/uirouter/uirouter.resolvers';
import {RESOLVER_CONTABILIDADE_DIGITAL, RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL} from '../../services/contabilidadedigital/contabilidadedigital.router.interface';
import {RESOLVER_LICENCA_STORE_MODE_PUBLIC} from '../../services/config/config.service.router';
import {ROLE} from '../../services/role.const';

export const MODULE_BANCOS_EXTRATO: IModuleDefinition = generateModuleBancosExtrato(false);

export const MODULE_BANCOS_EXTRATO_SIMPLE: IModuleDefinition = generateModuleBancosExtrato(true);

function generateModuleBancosExtrato(simpleMode: boolean): IModuleDefinition {
  const resolve: IModuleDefinition['state']['resolve'] = [
    {provide: 'simpleMode', useFactory: () => simpleMode},
    RESOLVER_LICENCA_STORE_MODE_PUBLIC,
    RESOLVER_EMPRESA_TEM_CG_BANKING,
    RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL,
    RESOLVER_EMPRESA_CGON_CG_BANKING_EXPIRED,
    RESOLVER_CG_BANKING_ACESSO,
    RESOLVER_CG_STORE_URL_BACKOFFICE
  ];
  if (!simpleMode) {
    resolve.push(RESOLVER_CONTABILIDADE_DIGITAL, RESOLVER_CONFIGS_BANCOS_EXTRATO);
  }
  return {
    name: !simpleMode ? MODULE_NAME_BANCOS_EXTRATO : MODULE_NAME_BANCOS_EXTRATO_SIMPLE,
    state: {
      url: `/${!simpleMode ? MODULE_NAME_BANCOS_EXTRATO : MODULE_NAME_BANCOS_EXTRATO_SIMPLE}`,
      component: BancosExtratoModuleComponent,
      data: {
        roles: !simpleMode ? [ROLE.CONTABILIDADE] : [ROLE.ERP, ROLE.FINANCEIRO],
        icon: 'fa-credit-card-alt',
        pageTitle: !simpleMode ? 'global.menu.bancosextrato' : 'global.menu.bancosextratosimple'
      },
      params: {
        tinkLink: {
          type: 'any',
          value: null,
          dynamic: true
        }
      },
      resolve: resolve
    }
  };
}
