<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'docscomerciais.auxiliares.anexarDocumento'"></h4>
</div>

<div class="modal-body entity-detail-form">
  <div class="form-group">
    <gdocs-viewer [folderId]="folderId" [showTreeViewer]="false" [treeCollapsed]="false" [params]="params" (evtAddingFile)="onGDocsViewerAddingFile($event)" [tipoDocEntity]="tipoDocEntity">
    </gdocs-viewer>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-close"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
