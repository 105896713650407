<div class="adiantamentos">
  <pl-alert type="warning" *ngIf="!configAdiantamentos.nArtigoAdiantamento" [closeable]="false">
    <div class="d-flex align-content-center">
      <i class="fa fa-exclamation-circle"></i>&nbsp;
      <span class="hyperlink hyperlink-text-color-white" [translate]="'adiantamentos.messages.notConfigured'" (click)="autoConfigModal()"></span>
    </div>
  </pl-alert>

  <pl-tabs [activeId]="activeTabId" (activeIdChange)="activeTabId = $event; evaluateDatagridDefinition()">
    <pl-tab [id]="adiantamentosTabID.PorRegularizar">
      <div *plTabTitle><i class="fa fa-fw fa-chain-broken" aria-hidden="true"></i>&nbsp;<span [translate]="'adiantamentos.tabs.porRegularizar'"></span></div>
      <div *plTabContent>
        <ng-container *ngTemplateOutlet="templateCardPanel"></ng-container>
        <ng-container *ngTemplateOutlet="templateDataGrid; context: {dataSource: dataSourcePorRegularizar, dataGridinstanceName: 'porRegularizar'}"></ng-container>
      </div>
    </pl-tab>
    <pl-tab [id]="adiantamentosTabID.Regularizado">
      <div *plTabTitle><i class="fa fa-fw fa-link" aria-hidden="true"></i>&nbsp;<span [translate]="'adiantamentos.tabs.regularizado'"></span></div>
      <div *plTabContent>
        <ng-container *ngTemplateOutlet="templateCardPanel"></ng-container>
        <ng-container *ngTemplateOutlet="templateDataGrid; context: {dataSource: dataSourceRegularizado, dataGridinstanceName: 'regularizado'}"></ng-container>
      </div>
    </pl-tab>
  </pl-tabs>
</div>

<ng-template #templateCardPanel>
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="pesquisar" plPromise>
        <pl-group *ngIf="modoRegularizacao">
          <label [translate]="clifoCaption"></label>
          <edit>
            <entity-autocomplete
              [entity]="moduleClientes ? 'clientes' : 'fornecedores'"
              attrName="clifos"
              [model]="headerAdiantamentos"
              (selectedKeyChange)="headerAdiantamentos.clifoDe = $event"
              (evtSelectedDescriptionChanged)="headerAdiantamentos.nomeClifo = $event"
              [fieldsMap]="{nConta: 'clifoDe', nome: 'nomeClifo'}"
              [properties]="{disabled: true}"
              [output]="clifosOutput">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="!modoRegularizacao">
          <pl-group>
            <label [translate]="clifoCaption"></label>
            <edit>
              <entity-autocomplete
                [entity]="moduleClientes ? 'clientes' : 'fornecedores'"
                attrName="clifos"
                [selectedKey]="headerAdiantamentos.clifoDe"
                (selectedKeyChange)="headerAdiantamentos.clifoDe = $event; headerAdiantamentos.clifoAte = $event"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete [entity]="moduleClientes ? 'clientes' : 'fornecedores'" attrName="clifos" [(selectedKey)]="headerAdiantamentos.clifoAte" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <fieldset class="mb-2">
          <legend [translate]="'adiantamentos.tabs.pesquisaDatas'"></legend>

          <pl-group *ngIf="activeTabId === adiantamentosTabID.PorRegularizar">
            <edit>
              <pl-edit type="radiogroup" attrName="radioModeFunc" [(model)]="headerAdiantamentos.modeFunc" [properties]="radioGroupTemplateModoFunc"></pl-edit>
            </edit>
          </pl-group>

          <pl-group *ngIf="headerAdiantamentos.modeFunc === modoFuncionamento.Intervalo">
            <pl-group>
              <label [translate]="'adiantamentos.tabs.fields.dateDocTo'"></label>
              <edit>
                <pl-edit type="date" attrName="dataDe" [(model)]="headerAdiantamentos.dataDe"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <pl-edit type="date" attrName="dataAte" [(model)]="headerAdiantamentos.dataAte" [properties]="{events: {keydown: fnKeydownLastDate}}"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group *ngIf="headerAdiantamentos.modeFunc === modoFuncionamento.DataReferencia">
            <label [translate]="'adiantamentos.tabs.fields.dataRef'"></label>
            <edit>
              <pl-edit type="date" attrName="dataRef" [(model)]="headerAdiantamentos.dataRef" [properties]="{events: {keydown: fnKeydownLastDate}}"></pl-edit>
            </edit>
          </pl-group>
        </fieldset>

        <pl-button type="submit" klass="btn btn-sm btn-primary"><i class="fa fa-fw fa-search"></i><span [translate]="'global.btn.search'"></span></pl-button>
      </pl-form>
    </div>
  </cg-card-panel>
</ng-template>

<ng-template #templateDataGrid let-dataSource="dataSource" let-dataGridinstanceName="dataGridinstanceName">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    [cgDxDataGridInstanceName]="dataGridinstanceName"
    [dataSource]="dataSource"
    (onInitialized)="onInitialized($event)"
    (onCellPrepared)="onCellPrepared($event)"
    (onCellClick)="onCellClick($event)"
    (onSaved)="onSaved($event)">
    <div *dxTemplate="let item of 'detailTemplateDocComercial'">
      <documento-faturacao [doc]="item.data._doc"></documento-faturacao>
    </div>

    <div *dxTemplate="let item of 'cellTemplateActions'">
      <div ngbDropdown container="body" class="col-main-table-actions">
        <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-cog"></i></button>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" [translate]="'adiantamentos.btns.rastreabilidade'" (click)="openRastreabilidade(item.data)"></button>
        </div>
      </div>
    </div>
  </dx-data-grid>
</ng-template>
