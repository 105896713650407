<div class="cct-edit">
  <entity-detail entityName="cct" [(model)]="model" [type]="type" [maintenanceMode]="maintenanceMode" [callback]="callback" [formInstance]="formInstance" (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{disabled: type === stateTypes.DETAIL}" (formInstanceChange)="formInstance = $event">
        <pl-group>
          <pl-group>
            <label [translate]="'cct.fields.ncct'"></label>
            <edit>
              <pl-edit type="cgsmallint" attrName="ncct" [(model)]="model.ncct" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'cct.fields.designaBreve'"></label>
            <edit>
              <pl-edit type="text" attrName="designaBreve" [(model)]="model.designaBreve" [properties]="{validators: {required: {value: true}, maxlength: {value: 20}}}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'cct.fields.nome'"></label>
          <edit>
            <pl-edit type="text" attrName="nome" [(model)]="model.nome" [properties]="{validators: {maxlength: {value: 60}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'cct.fields.dtBolTrab'"></label>
            <edit>
              <pl-edit type="date" attrName="dtBolTrab" [(model)]="model.dtBolTrab"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'cct.fields.dtIniValidad'"></label>
            <edit>
              <pl-edit type="date" attrName="dtIniValidad" [(model)]="model.dtIniValidad"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'cct.fields.numBolTrab'"></label>
            <edit>
              <pl-edit type="number" attrName="numBolTrab" [(model)]="model.numBolTrab"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'cct.fields.mediaTabelas'"></label>
            <edit>
              <pl-edit type="number" attrName="mediaTabelas" [(model)]="model.mediaTabelas"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'cct.fields.codIRCT'"></label>
            <edit>
              <entity-autocomplete
                entity="qpirt"
                attrName="codIRCT"
                [model]="model"
                [selectedKey]="model.codIRCT"
                (selectedKeyChange)="model.codIRCT = $event"
                output="codirct"
                [customActions]="customActionsQPIRCT"
                [fieldsMap]="{codirct: 'codIRCT'}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'cct.fields.aplicabilidadeIRCT'"></label>
            <edit>
              <pl-autocomplete
                [source]="dtAplicabilidadeIRCT"
                [model]="model.nomeAplicabilidadeIRCT"
                [rowTemplate]="aplicabilidadeIRCTTemplate"
                (evtSelected)="aplicabilidadeChanged($event)"
                [validateFn]="fnAplicabilidadeValidate"
                [allowEmpty]="false"
                [allowInvalid]="false"
                [properties]="{validators: {required: {value: true}}}"
                attrName="aplicaIRCT"
                output="nome">
              </pl-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
