import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlPromisesModule} from '../promises/promises.module';
import {PlTooltipModule} from '../tooltip/tooltip.module';

import {PlButtonComponent} from './button.component';

export * from './button.component.interface';
export * from './button.component';

const DECLARATIONS = [
  PlButtonComponent
];

@NgModule({
  imports: [
    CommonModule,
    PlPromisesModule,
    PlTooltipModule
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlButtonModule {
}
