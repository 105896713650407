import {Component, Injector, OnInit} from '@angular/core';
import {ClifosEditComponent} from '../../edit/clifos.entity.edit.component';
import {copy, IPlFormDefinition, IPlFormTemplate} from 'pl-comps-angular';
import {EConfigOptionsInstanceName} from '../../../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../../../config/constants';
import {ConfigOptionsService} from '../../../../../services/config/options/config.options.service';
import {EEntityStateDetailType} from '../../../../../../common/utils/entity.state.utils';
import {EntityMaintenanceService} from '../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {AuthService} from '../../../../../services/auth/auth.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'outros-devedores-credores-edit',
  templateUrl: './outrosDevedoresCredores.entity.edit.component.html'
})
export class OutrosDevedoresCredoresEditComponent extends ClifosEditComponent implements OnInit {
  public readonly fieldsDocsComerciais: string;
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public formDefinition: IPlFormDefinition;
  public formTemplate: IPlFormTemplate;
  public optionShowZona: boolean;
  public optionShowVendedor: boolean;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _configOptionsService: ConfigOptionsService,
    protected readonly _entityMaintenanceService: EntityMaintenanceService,
    protected readonly _authService: AuthService,
    protected readonly _translateService: TranslateService
  ) {
    super(_injector, _configOptionsService, _entityMaintenanceService, _authService, _translateService);
    this.fieldsDocsComerciais = 'nDoc,dataDoc,nif,estado,porPagar,totalDocumentoME,abreviaturaMoeda';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.formDefinition = this.entity[this.type].definition;
    this.formTemplate = copy(this.entity[this.type].template);
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    return super.onUpdate(stateType).then(() => {
      this.formDefinition = this.entity[stateType].definition;
      this.formTemplate = copy(this.entity[stateType].template);
    });
  }
}
