import {Component, Injector, Input, OnInit} from '@angular/core';
import {isEmpty} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocContabilidade, IDocsContabilidadeEntityService} from '../../../docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IJsonDocContabilidade} from '../../../docscontabilidade/jsonDocContabilidade.interface';

@Component({
  selector: 'conciliacao-bancaria-view-doc-modal',
  templateUrl: './conciliacaoBancaria.viewDoc.modal.component.html'
})
export class ConciliacaoBancariaViewDocModalComponent extends CGModalComponent<boolean> implements OnInit {
  @Input() public expPocCabId: string;

  public modalTitle: string;
  public doc: IDocContabilidade;
  public promise: Promise<void>;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private _isChanged: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._isChanged = false;
  }

  public readonly fnOnDocChanged: () => void = () => {
    this._fnOnDocChanged();
  };

  public ngOnInit(): void {
    if (!isEmpty(this.expPocCabId)) {
      this.promise = this._serviceDocsContabilidade.get({id: this.expPocCabId}).then((response) => {
        this.doc = response.body;
      });
    }
  }

  public dismiss(): void {
    this.close(this._isChanged);
  }

  private _fnOnDocChanged(): void {
    this._isChanged = true;
  }
}
