<div class="notification-center-module">
  <div class="notification-center-new-notifications" *ngIf="filteredNewNotifications.length > 0">
    <div class="notification-center-notifications-title" [translate]="'notificationcenter.new'"></div>
    <div class="notification-center-notifications">
      <notification-item
        *ngFor="let notification of filteredNewNotifications; trackBy: notificationsTrackByFn"
        [notification]="notification"
        [mobile]="mobile"
        (evtSelectedNotification)="selectedNotification(notification)">
      </notification-item>
    </div>
  </div>

  <div class="notification-center-old-notifications" *ngIf="filteredOldNotifications.length > 0">
    <div class="notification-center-notifications-title" [translate]="'notificationcenter.old'"></div>
    <div class="notification-center-notifications">
      <notification-item
        *ngFor="let notification of filteredOldNotifications; trackBy: notificationsTrackByFn"
        [notification]="notification"
        [mobile]="mobile"
        (evtSelectedNotification)="selectedNotification(notification)">
      </notification-item>
    </div>
  </div>

  <div class="notification-center-no-notifications" *ngIf="!filteredNewNotifications.length && !filteredOldNotifications.length">
    <notification-center-no-notifications [minimalistic]="false"></notification-center-no-notifications>
  </div>
</div>
