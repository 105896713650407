import DataSource from 'devextreme/data/data_source';
import dxList, {InitializedEvent} from 'devextreme/ui/list';
import {Component, Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PlAlertService} from 'pl-comps-angular';
import {AsyncArrayStore} from '../../../../components/devexpress/datalayer/stores/asyncarray/devexpress.datalayer.async.array.store';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_PORTALS, evaluatePortalTitle, IPortalEntityService, sortPortals} from '../../portal.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IJsonPortal, IJsonPortalSortItem} from '../../jsonPortal.entity.interface';
import {IPortalSortItem} from './portals.sort.modal.component.interface';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';

@Component({
  selector: 'portals-sort-modal',
  templateUrl: './portals.sort.modal.component.html'
})
export class PortalsSortModalComponent extends CGModalComponent<void> {
  public readonly dataSource: DataSource<IPortalSortItem, string>;
  public loading: boolean;

  private readonly _servicePortals: IPortalEntityService;
  private _changedSortItems: boolean;
  private _listInstance: dxList<IPortalSortItem, string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _alertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.loading = true;
    this._servicePortals = this._entityServiceBuilder.build<IJsonPortal, IPortalEntityService>(ENTITY_NAME_PORTALS);
    this.dataSource = new DataSource<IPortalSortItem, string>({
      store: new AsyncArrayStore<IPortalSortItem, string>({
        injector: this._injector,
        key: 'url',
        load: async (): Promise<Array<IPortalSortItem>> => {
          this.loading = true;
          try {
            const response: THttpQueryResponse<IJsonPortal> = await this._servicePortals.query();
            const portals: Array<IJsonPortal> = sortPortals(response.body.list);
            return portals.map<IPortalSortItem>((portal: IJsonPortal, index: number): IPortalSortItem => {
              return {
                url: portal.url,
                sortOrder: portal.sortOrder,
                text: evaluatePortalTitle(portal, this._translateService),
                index: index,
                disableUp: index === 0,
                disableDown: index === portals.length - 1
              };
            });
          } finally {
            this.loading = false;
          }
        }
      })
    });
    this._changedSortItems = false;
  }

  public async close(): Promise<void> {
    if (this.loading) {
      return;
    }
    if (!this._changedSortItems) {
      super.close();
      return;
    }

    const listSortItems: Array<IPortalSortItem> = this._listInstance.option('items');
    const sortItems: Array<IJsonPortalSortItem> = listSortItems.map((sortItem: IPortalSortItem): IJsonPortalSortItem => {
      return {url: sortItem.url, sortOrder: sortItem.sortOrder};
    });

    this.disableClose();
    try {
      if (sortItems.length) {
        await this._servicePortals.sortPortals(sortItems);
      }
      this._alertService.success('portals.modals.sort.success');
      this.enableClose();
      super.close();
    } catch (reason) {
      this.enableClose();
      this._logger.error(reason);
    }
  }

  public onListInitialized(event: InitializedEvent<IPortalSortItem, string>): void {
    this._listInstance = event.component;
  }

  public onListItemReordered(): void {
    this._changedSortItems = true;
    const listSortItems: Array<IPortalSortItem> = this._listInstance.option('items');
    for (let i = 0; i < listSortItems.length; i++) {
      const sortItem: IPortalSortItem = listSortItems[i];
      sortItem.sortOrder = i + 1;
      sortItem.index = i;
      sortItem.disableUp = i === 0;
      sortItem.disableDown = i === listSortItems.length - 1;
    }
  }

  public reorderItem(itemIndex: number, toIndex: number): () => Promise<void> {
    return async () => {
      await this._listInstance.reorderItem(itemIndex, toIndex);
    };
  }
}
