import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {IJsonAtivosImport, IJsonAtivosImportAjustes, IJsonAtivosImportAndListCalc, IJsonAtivosImportConsistenciasContas, IJsonAtivosImportTaxa} from './jsonAtivosImport.entity.interface';
import {IAtivosImportStatus} from './ativosImport.entity.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AtivosImportService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/ativosimport`;
  }

  public getAtivosPorImportar(value: boolean): TServiceResponse<Array<IJsonAtivosImport>> {
    return this._apiService.get<Array<IJsonAtivosImport>>({
      url: this._path,
      params: {
        ativosporimportar: value
      }
    });
  }

  public getValorTaxas(codAmortizacao: string): TServiceResponse<IJsonAtivosImportTaxa> {
    return this._apiService.get<IJsonAtivosImportTaxa>({
      url: `${this._path}/calctaxas`,
      params: {
        codamortizacao: codAmortizacao
      }
    });
  }

  public getAjustesNasContas(): TServiceResponse<Array<IJsonAtivosImportAjustes>> {
    return this._apiService.get<Array<IJsonAtivosImportAjustes>>({
      url: `${this._path}/ajustesnascontas`
    });
  }

  public getExcelCriarFolhaUrl(lista: string): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: `${this._path}/excelcriarfolha`,
      responseType: 'blob',
      params: {
        colunas: lista
      }
    });
  }

  public getExcelImportar(): Observable<string> {
    return of(`${this._path}/excelimportar`);
  }

  public postAceitarCalculos(selectedImportList: Array<IJsonAtivosImport>): TServiceResponse<Array<IJsonAtivosImport>> {
    return this._apiService.post<Array<IJsonAtivosImport>>({
      url: `${this._path}/aceitarcalculos`,
      body: selectedImportList
    });
  }

  public postCalculoBaseAnual(selectedImportList: Array<IJsonAtivosImport>): TServiceResponse<Array<IJsonAtivosImport>> {
    return this._apiService.post<Array<IJsonAtivosImport>>({
      url: `${this._path}/calculobaseanual`,
      body: selectedImportList
    });
  }

  public postCalculoInicioUtilizacao(selectedImportList: Array<IJsonAtivosImport>): TServiceResponse<Array<IJsonAtivosImport>> {
    return this._apiService.post<Array<IJsonAtivosImport>>({
      url: `${this._path}/calculoinicioutilizacao`,
      body: selectedImportList
    });
  }

  public postAtribuirValoresManualmenteSemArredondamento(selectedImportList: Array<IJsonAtivosImport>): TServiceResponse<Array<IJsonAtivosImport>> {
    return this._apiService.post<Array<IJsonAtivosImport>>({
      url: `${this._path}/atribuivaloresmanualsemarredondamento`,
      body: selectedImportList
    });
  }

  public postGuardaAtribuirValoresManualmente(selectedImportList: IJsonAtivosImportAndListCalc): TServiceResponse<IJsonAtivosImport> {
    return this._apiService.post<IJsonAtivosImport, IJsonAtivosImportAndListCalc>({
      url: `${this._path}/guardavaloresmanualmente`,
      body: selectedImportList
    });
  }

  public postAplicarPerc(selectedImportList: IJsonAtivosImportAndListCalc): TServiceResponse<IJsonAtivosImportAndListCalc> {
    return this._apiService.post<IJsonAtivosImportAndListCalc>({
      url: `${this._path}/aplicarperc`,
      body: selectedImportList
    });
  }

  public postAplicarPercFiscal(selectedImportList: IJsonAtivosImportAndListCalc): TServiceResponse<IJsonAtivosImportAndListCalc> {
    return this._apiService.post<IJsonAtivosImportAndListCalc>({
      url: `${this._path}/aplicarpercfiscal`,
      body: selectedImportList
    });
  }

  public postOnChangeAtivosImportDep(selectedImportList: IJsonAtivosImportAndListCalc, dadosiguais: boolean): TServiceResponse<IJsonAtivosImportAndListCalc> {
    return this._apiService.post<IJsonAtivosImportAndListCalc>({
      url: `${this._path}/onchangeativosimportdep`,
      body: selectedImportList,
      params: {dadosiguais: dadosiguais}
    });
  }

  public postDistribuirValorAtribuidoPelosAnosAnteriores(selectedImportList: Array<IJsonAtivosImport>): TServiceResponse<Array<IJsonAtivosImport>> {
    return this._apiService.post<Array<IJsonAtivosImport>>({
      url: `${this._path}/valoratribuidopelosanosanteriores`,
      body: selectedImportList
    });
  }

  public postAtribuirPercentagemDeReducao(
    selectedImportList: Array<IJsonAtivosImport>,
    onlyToImport: boolean,
    todosano: boolean,
    ano: number,
    perc: number
  ): TServiceResponse<Array<IJsonAtivosImport>> {
    return this._apiService.post<Array<IJsonAtivosImport>>({
      url: `${this._path}/atribuirpercentagemdereducao`,
      body: selectedImportList,
      params: {
        soporimportar: onlyToImport,
        todosanos: todosano,
        ano: ano,
        perc: perc
      }
    });
  }

  public postLoadAtribuirValoresManualmente(selectedImportList: Array<IJsonAtivosImport>): TServiceResponse<IJsonAtivosImportAndListCalc> {
    return this._apiService.post<IJsonAtivosImportAndListCalc, IJsonAtivosImport>({
      url: `${this._path}/loadvaloresmanualmente`,
      body: selectedImportList[0]
    });
  }

  public postCriarAtivosGuardar(selectedImportList: Array<IJsonAtivosImport>): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/criarativosguardar`,
      body: selectedImportList
    });
  }

  public deleteLimpaValoresAtribuidosManualmente(aimport: IJsonAtivosImport): TServiceResponse<IJsonAtivosImport> {
    return this._apiService.delete<IJsonAtivosImport>({
      url: `${this._path}/limpavaloresatribuidosmanualmente`,
      body: aimport
    });
  }

  public apagaAtivo(ids: Array<string>): TServiceResponse<void> {
    return this._apiService.delete({url: this._path, body: ids});
  }

  public getAtivosImportStatus(): TServiceResponse<IAtivosImportStatus> {
    return this._apiService.get<IAtivosImportStatus>({url: `${this._path}/ativosimportstatus`});
  }

  public getAtivosImportResults(): TServiceResponse<Array<IJsonAtivosImportConsistenciasContas>> {
    return this._apiService.get<Array<IJsonAtivosImportConsistenciasContas>>({url: `${this._path}/ativosimportresults`});
  }

  public stopJobAtivosImport(): TServiceResponse<void> {
    return this._apiService.get({url: this.stopJobAtivosImportUrl()});
  }

  public stopJobAtivosImportUrl(): string {
    return `${this._path}/ativosimportstop`;
  }
}
