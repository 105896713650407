import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EExtratosDTTipo, IExtratosDTStateParams} from '../extratosDT.module.interface';

@Component({
  selector: 'modal-extratosdt',
  templateUrl: './extratosDT.modal.component.html'
})
export class ExtratosDTModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public tipo: string;
  @Input() public params: IExtratosDTStateParams;
  @Input() public readonlyConta: boolean;

  public moduleType: EExtratosDTTipo;
  public empresaAnoEmCursoIRC: number;

  constructor(protected readonly _injector: Injector) {
    super(_injector);

    this.moduleType = EExtratosDTTipo.Geral;
  }

  public ngOnInit(): void {
    if (this.tipo === 'cliente') {
      this.moduleType = EExtratosDTTipo.Clientes;
    }
    if (this.tipo === 'fornecedor') {
      this.moduleType = EExtratosDTTipo.Fornecedores;
    }
    this.empresaAnoEmCursoIRC = this.configurations.empresa.anoEmCursoIRC;
  }
}
