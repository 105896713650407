import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {IContabilidadeExtratosGrid, IContabilidadeExtratosGridModel} from './extratosgrid/contabilidade.extratosGrid.module.interface';
import {IJsonBalanceteAno, IJsonBalanceteCompl} from './balancetes/jsonContabilidade.balancetes.module.interface';
import {IJsonExtratosDT} from './extratosdt/jsonExtratosDT.module.interface';
import {IJsonString} from '../../../../common/interfaces/json';
import {IJsonEmailClifo} from '../../../components/emailsCliFo/jsonEmailCliFo.interface';
import {TDate} from '../../../../common/dates';
import {IApiQueryResponse, TServiceQueryResponse, TServiceResponse} from '../../../services/api/api.service.interface';
import {ETipoContaContabilidade} from '../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IJsonConfigMapaExploracao, IJsonMapaExploracao} from './mapaexploracao/jsonMapaExploracao.module.interface';

@Injectable({
  providedIn: 'root'
})
export class ContabilidadeEstatisticaService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = this._apiService.path.restapi;
  }

  public getBalancetesUrl(
    doPeriodo: string,
    atePeriodo: string,
    deConta: string,
    ateConta: string,
    contasRazao: boolean,
    contasClasse: boolean,
    contasSubTotal: boolean,
    contasMovimento: boolean,
    contasCorrentes: boolean,
    escondeContasSaldoZero: boolean,
    reportName: string,
    anosComparativos: Array<number>,
    tipoExportacao: number,
    deCCusto: string,
    ateCCusto: string,
    calculaSaldosIniciaisAnoNMaisUm: boolean,
    escondeRubricasSemMovimento: boolean,
    planoaltid: string
  ): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/balancetes`, {
      doPeriodo: doPeriodo,
      atePeriodo: atePeriodo,
      deConta: deConta,
      ateConta: ateConta,
      contasRazao: contasRazao,
      contasClasse: contasClasse,
      contasSubTotal: contasSubTotal,
      contasMovimento: contasMovimento,
      contasCorrentes: contasCorrentes,
      escondeContasSaldoZero: escondeContasSaldoZero,
      reportName: reportName,
      anosComparativos: anosComparativos,
      tipoExportacao: tipoExportacao,
      deCCusto: deCCusto,
      ateCCusto: ateCCusto,
      calculaSaldosIniciaisAnoNMaisUm: calculaSaldosIniciaisAnoNMaisUm,
      esconderubricassemmovimento: escondeRubricasSemMovimento,
      planoaltid: planoaltid
    });
  }

  public exportBalancetesGabmov(
    doPeriodo: string,
    atePeriodo: string,
    deConta: string,
    ateConta: string,
    contasRazao: boolean,
    contasClasse: boolean,
    contasSubTotal: boolean,
    contasMovimento: boolean,
    contasCorrentes: boolean,
    escondeContasSaldoZero: boolean,
    deCCusto: string,
    ateCCusto: string,
    calculaSaldosIniciaisAnoNMaisUm: boolean,
    escondeRubricasSemMovimento: boolean
  ): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: `${this._path}/balancetes/gabmov`,
      responseType: 'blob',
      params: {
        doPeriodo: doPeriodo,
        atePeriodo: atePeriodo,
        deConta: deConta,
        ateConta: ateConta,
        contasRazao: contasRazao,
        contasClasse: contasClasse,
        contasSubTotal: contasSubTotal,
        contasMovimento: contasMovimento,
        contasCorrentes: contasCorrentes,
        escondeContasSaldoZero: escondeContasSaldoZero,
        deCCusto: deCCusto,
        ateCCusto: ateCCusto,
        calculaSaldosIniciaisAnoNMaisUm: calculaSaldosIniciaisAnoNMaisUm,
        esconderubricassemmovimento: escondeRubricasSemMovimento
      }
    });
  }

  public exportBalancetesDefir(
    doPeriodo: string,
    atePeriodo: string,
    deConta: string,
    ateConta: string,
    contasRazao: boolean,
    contasClasse: boolean,
    contasSubTotal: boolean,
    contasMovimento: boolean,
    contasCorrentes: boolean,
    escondeContasSaldoZero: boolean,
    deCCusto: string,
    ateCCusto: string,
    calculaSaldosIniciaisAnoNMaisUm: boolean,
    escondeRubricasSemMovimento: boolean
  ): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: `${this._path}/balancetes/defir`,
      responseType: 'blob',
      params: {
        doPeriodo: doPeriodo,
        atePeriodo: atePeriodo,
        deConta: deConta,
        ateConta: ateConta,
        contasRazao: contasRazao,
        contasClasse: contasClasse,
        contasSubTotal: contasSubTotal,
        contasMovimento: contasMovimento,
        contasCorrentes: contasCorrentes,
        escondeContasSaldoZero: escondeContasSaldoZero,
        deCCusto: deCCusto,
        ateCCusto: ateCCusto,
        calculaSaldosIniciaisAnoNMaisUm: calculaSaldosIniciaisAnoNMaisUm,
        esconderubricassemmovimento: escondeRubricasSemMovimento
      }
    });
  }

  public getBalancetesAnaliticaUrl(
    nContaCGeralDe: string,
    nContaCGeralAte: string,
    periodoDe: string,
    periodoAte: string,
    cCustoDe: string,
    cCustoAte: string,
    zonaDe: number,
    zonaAte: number,
    departamentoDe: number,
    departamentoAte: number,
    subDepartDe: string,
    subDepartAte: string,
    familiaDe: number,
    familiaAte: number,
    grFamiliaDe: number,
    grFamiliaAte: number,
    subFamiliaDe: number,
    subFamiliaAte: number,
    codContabDe: string,
    codContabAte: string,
    tipoArtigoDe: number,
    tipoArtigoAte: number,
    classeDe: number,
    classeAte: number,
    categoriaDe: number,
    categoriaAte: number,
    vendedorDe: number,
    vendedorAte: number,
    processoDe: string,
    processoAte: string,
    ano: string,
    tiposConta: Array<ETipoContaContabilidade>,
    reportName: string
  ): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/balancetesanalitica/report`, {
      ncontacgeralde: nContaCGeralDe,
      ncontacgeralate: nContaCGeralAte,
      periodode: periodoDe,
      periodoate: periodoAte,
      ccustode: cCustoDe,
      ccustoate: cCustoAte,
      zonade: zonaDe,
      zonaate: zonaAte,
      departamentode: departamentoDe,
      departamentoate: departamentoAte,
      subdepartde: subDepartDe,
      subdepartate: subDepartAte,
      familiade: familiaDe,
      familiaate: familiaAte,
      grfamiliade: grFamiliaDe,
      grfamiliaate: grFamiliaAte,
      subfamiliade: subFamiliaDe,
      subfamiliaate: subFamiliaAte,
      codcontabde: codContabDe,
      codcontabate: codContabAte,
      tipoartigode: tipoArtigoDe,
      tipoartigoate: tipoArtigoAte,
      classede: classeDe,
      classeate: classeAte,
      categoriade: categoriaDe,
      categoriaate: categoriaAte,
      vendedorde: vendedorDe,
      vendedorate: vendedorAte,
      processode: processoDe,
      processoate: processoAte,
      anos: ano,
      tiposconta: tiposConta,
      reportname: reportName
    });
  }

  public getMascarasAnalitica(): TServiceResponse<IJsonString> {
    return this._apiService.get<IJsonString>({url: `${this._path}/balancetesanalitica/mascarasanalitca`});
  }

  public getExtratosUrl(deConta: string, ateConta: string, periodo: string, reportName: string, tipoExportacao: number): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/extratos`, {
      deConta: deConta,
      ateConta: ateConta,
      periodo: periodo,
      reportName: reportName,
      tipoExportacao: tipoExportacao
    });
  }

  public getExtratosPorContaUrl(nConta: string, dePeriodo: string, atePeriodo: string, deNDiario: number, ateNDiario: number, reportName: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/extratos/pdfpconta`, {
      nConta: nConta,
      dePeriodo: dePeriodo,
      atePeriodo: atePeriodo,
      dendiario: deNDiario,
      atendiario: ateNDiario,
      reportName: reportName
    });
  }

  public getExtratosDeContasUrl(nContaDe: string, nContaAte: string, dePeriodo: string, atePeriodo: string, reportName: string, tipoExportacao: number): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/extratos/extratosdecontas`, {
      nContaDe: nContaDe,
      nContaAte: nContaAte,
      dePeriodo: dePeriodo,
      atePeriodo: atePeriodo,
      reportName: reportName,
      tipoExportacao: tipoExportacao
    });
  }

  public sendMailExtratosPorConta(
    dePeriodo: string,
    atePeriodo: string,
    deNDiario: number,
    ateNDiario: number,
    reportName: string,
    emailContent: IJsonEmailClifo
  ): TServiceResponse<Array<IJsonBalanceteAno>> {
    return this._apiService.get<Array<IJsonBalanceteAno>>({
      url: `${this._path}/extratos/pdfpconta/sendmail`,
      params: {
        nConta: emailContent.nConta,
        dePeriodo: dePeriodo,
        atePeriodo: atePeriodo,
        dendiario: deNDiario,
        atendiario: ateNDiario,
        reportName: reportName,
        email: emailContent.emails,
        subject: emailContent.subject,
        body: emailContent.body
      }
    });
  }

  public getExtratosPorContaEmailContent(nConta: string): TServiceResponse<IJsonEmailClifo> {
    return this._apiService.get<IJsonEmailClifo>({url: `${this._path}/extratos/pdfpconta/emailscontent`, params: {nconta: nConta}});
  }

  public getMovimentosEmAbertoUrl(deConta: string, ateConta: string, periodo: string, reportName: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/movimentosemaberto/pdf`, {
      deConta: deConta,
      ateConta: ateConta,
      periodo: periodo,
      reportName: reportName
    });
  }

  public getImpDecPeUrl(dePeriodo: string, atePeriodo: string, deCampo: string, ateCampo: string, reportName: string, imprimirCriterios: boolean): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/impdecpe`, {
      dePeriodo: dePeriodo,
      atePeriodo: atePeriodo,
      deCampo: deCampo,
      ateCampo: ateCampo,
      reportName: reportName,
      imprimirCriterios: imprimirCriterios
    });
  }

  public extratos(extratosGrid: IContabilidadeExtratosGridModel, search: string, order: string): TServiceQueryResponse<IContabilidadeExtratosGrid> {
    return this._apiService.get<IApiQueryResponse<IContabilidadeExtratosGrid>>({
      url: `${this._path}/extratos/list`,
      params: {
        deConta: extratosGrid.nConta,
        ateConta: extratosGrid.nConta,
        dePeriodo: extratosGrid.dePeriodo,
        atePeriodo: extratosGrid.atePeriodo,
        dendiario: extratosGrid.deNDiario,
        atendiario: extratosGrid.ateNDiario,
        deNContrib: undefined,
        ateNContrib: undefined,
        pesquisa: search,
        ordena: order
      }
    });
  }

  public getContaAfterOrBefore(nconta: string, findnext: boolean): TServiceResponse<IJsonString> {
    return this._apiService.get<IJsonString>({
      url: `${this._path}/extratos/findafterorbefore`,
      params: {
        nconta: nconta,
        findnext: findnext
      }
    });
  }

  public getMapaExploracao(dePeriodo: string, atePeriodo: string): TServiceQueryResponse<IJsonMapaExploracao> {
    return this._apiService.get<IApiQueryResponse<IJsonMapaExploracao>>({
      url: `${this._path}/officereporting/mapaexploracao/json`,
      params: {
        periodoDe: dePeriodo,
        periodoAte: atePeriodo
      }
    });
  }

  public getMapaExploracaoXlsUrl(dePeriodo: string, atePeriodo: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/officereporting/mapaexploracao/xls`, {
      periodoDe: dePeriodo,
      periodoAte: atePeriodo
    });
  }

  public getConfigMapaExploracao(): TServiceResponse<IJsonConfigMapaExploracao> {
    return this._apiService.get<IJsonConfigMapaExploracao>({url: `${this._path}/officereporting/config/mapaexploracao`});
  }

  public saveConfigMapaExploracao(config: IJsonConfigMapaExploracao): TServiceResponse<IJsonConfigMapaExploracao> {
    return this._apiService.post<IJsonConfigMapaExploracao>({url: `${this._path}/officereporting/config/mapaexploracao`, body: config});
  }

  public getBalancetesGrid(
    doPeriodo: string,
    atePeriodo: string,
    deConta: string,
    ateConta: string,
    contasRazao: boolean,
    contasClasse: boolean,
    contasSubTotal: boolean,
    contasMovimento: boolean,
    contasCorrentes: boolean,
    escondeContasSaldoZero: boolean,
    anosComparativos: string,
    deCCusto: string,
    ateCCusto: string,
    pesq: string,
    calculaSaldosIniciaisAnoNMaisUm: boolean,
    escondeRubricasSemMovimento: boolean,
    planoaltid: string
  ): TServiceResponse<Array<IJsonBalanceteCompl>> {
    return this._apiService.get<Array<IJsonBalanceteCompl>>({
      url: `${this._path}/balancetes/grelha`,
      params: {
        doPeriodo: doPeriodo,
        atePeriodo: atePeriodo,
        deConta: deConta,
        ateConta: ateConta,
        contasRazao: contasRazao,
        contasClasse: contasClasse,
        contasSubTotal: contasSubTotal,
        contasMovimento: contasMovimento,
        contasCorrentes: contasCorrentes,
        escondeContasSaldoZero: escondeContasSaldoZero,
        anosComparativos: anosComparativos,
        deCCusto: deCCusto,
        ateCCusto: ateCCusto,
        pesq: pesq,
        calculaSaldosIniciaisAnoNMaisUm: calculaSaldosIniciaisAnoNMaisUm,
        esconderubricassemmovimento: escondeRubricasSemMovimento,
        planoaltid: planoaltid
      }
    });
  }

  public getDocDigitalUrl(docDigitalGDocID: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/extratos/docdigital`, {docDigitalGDocID: docDigitalGDocID});
  }

  public getExtratosDT(nConta: string, deData: TDate, ateData: TDate): TServiceQueryResponse<IJsonExtratosDT> {
    return this._apiService.get<IApiQueryResponse<IJsonExtratosDT>>({
      url: `${this._path}/extratosDT`,
      params: {
        nConta: nConta,
        deData: deData,
        ateData: ateData
      }
    });
  }

  public getExtratosDTPdf(rptName: string, nConta: string, deData: TDate, ateData: TDate): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/extratosDT/report`, {
      rptname: rptName,
      nconta: nConta,
      dedata: deData,
      atedata: ateData
    });
  }

  public getRadicalConta(moduleName: string): TServiceResponse<string> {
    return this._apiService.get<string>({url: `${this._path}/extratosDT/radicalconta`, params: {module: moduleName}});
  }
}
