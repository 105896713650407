import {TDate} from '../../../../../common/dates';
import {TTableLegend} from '../../../../components/tablelegend/tablelegend.component.interface';
import {deepFreeze} from 'pl-comps-angular';

export enum EFamiliasDataGridLegendColors {
  LinhaNaoConfiged = 'linha-nao-configed',
  CampoEdit = 'campo-edit'
}

export interface IExportacaoComprasFilters {
  datade: TDate;
  dataate: TDate;
  documentosporexportar?: boolean;
}

export interface IExportacaoComprasConfigFamilias {
  nFamiliaCG: number;
  nomeFamiliaCG: string;
  idGeo?: string;
  nomeFamiliaGeo?: string;
  operacaoGeo?: string;
}

export interface IExportacaoComprasDocumentos {
  id: number;
  name?: string;
  desc?: string;
}

export interface IExportacaoComprasTipoDocumentos {
  id: number;
  name: string;
  desc: string;
}

export const TABLE_LEGEND_FAMILIAS: TTableLegend = deepFreeze([
  {
    caption: 'exportacaoCompras.config.familiasDataGrid.legend.naoConfiged',
    badgeCSSClass: EFamiliasDataGridLegendColors.LinhaNaoConfiged
  },
  {
    caption: 'exportacaoCompras.config.familiasDataGrid.legend.campoEdit',
    badgeCSSClass: EFamiliasDataGridLegendColors.CampoEdit
  }
]);

export const MODULE_NAME_EXPORTACAO_COMPRAS = 'exportacaocompras';
