import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {isArray, isBoolean, PlAlertService, PlI18nService} from 'pl-comps-angular';
import {CGModalService} from '../../cg/modal/cgmodal.service';
import {EGestaoDGEMPSType, IGestaoDGEMPSEvent} from '../gestaodgemps.interface';
import {ENTITY_NAME_PRH_TAREFAS_CAB, IPRHTarefasCabEntityService} from '../../../entities/prhtarefascab/pRHTarefasCab.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {gestaoDGEMPSEvaluateEventCSSClassOutline} from '../gestaodgemps.utilities';
import {GestaoDGEMPSEventHistoryModalComponent} from '../modals/eventhistory/gestaodgemps.eventhistory.modal.component';
import {GestaoDGEMPSMotivoModalComponent} from '../modals/motivo/gestaodgemps.motivo.modal.component';
import {GestaoDGEMPSService} from '../gestaodgemps.service';
import {
  IGestaoDGEMPSEventsDetailAddEvent,
  IGestaoDGEMPSEventsDetailEvent,
  IGestaoDGEMPSEventsDetailGroup,
  IGestaoDGEMPSEventsDetailSetMotivoModalParams
} from './gestaodgemps.eventsdetail.component.interface';
import {IJsonPRHTarefaCab} from '../../../entities/prhtarefascab/jsonPRHTarefaCab.entity.interface';
import moment, {Moment} from 'moment';

@Component({
  selector: 'gestao-dgemps-events-detail',
  templateUrl: './gestaodgemps.eventsdetail.component.html'
})
export class GestaoDGEMPSEventsDetailComponent implements OnInit, OnChanges {
  @Input() public type: EGestaoDGEMPSType;
  @Input() public manager: boolean;
  @Input() public codEmpLanca: number;
  @Input() public start: Moment;
  @Input() public end: Moment;
  @Input() public events: Array<IGestaoDGEMPSEventsDetailEvent>;
  @Input() public working: boolean;
  @Output() public readonly evtRefresh: EventEmitter<void>;
  @Output() public readonly evtAddEvent: EventEmitter<IGestaoDGEMPSEventsDetailAddEvent>;
  @Output() public readonly evtCloseDetail: EventEmitter<void>;

  public readonly types: typeof EGestaoDGEMPSType;
  public title: string;
  public groups: Array<IGestaoDGEMPSEventsDetailGroup>;
  public allowIntegraAll: boolean;
  public allowApproveAll: boolean;
  public allowRejectAll: boolean;
  public allowRewindAll: boolean;
  public allowDeleteAll: boolean;
  public allowSetMotivoAll: boolean;
  public promiseAttachment: Promise<void>;

  private readonly _servicePRHTarefasCab: IPRHTarefasCabEntityService;
  private readonly _defaultMotivoRewind: string;
  private _defaultMotivoScheduled: string;
  private _defaultMotivoApprove: string;
  private _defaultMotivoReject: string;
  private _defaultMotivoIntegrar: string;
  private _motivoGlobal: string;
  private _executing: number;

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plI18nService: PlI18nService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgModalService: CGModalService,
    private readonly _gestaoDGEMPSService: GestaoDGEMPSService
  ) {
    this.evtRefresh = new EventEmitter<void>();
    this.evtAddEvent = new EventEmitter<IGestaoDGEMPSEventsDetailAddEvent>();
    this.evtCloseDetail = new EventEmitter<void>();
    this.types = EGestaoDGEMPSType;
    this.groups = [];
    this.allowIntegraAll = false;
    this.allowApproveAll = false;
    this.allowRejectAll = false;
    this.allowRewindAll = false;
    this.allowDeleteAll = false;
    this.allowSetMotivoAll = false;
    this._servicePRHTarefasCab = this._entityServiceBuilder.build<IJsonPRHTarefaCab, IPRHTarefasCabEntityService>(ENTITY_NAME_PRH_TAREFAS_CAB);
    this._executing = 0;
  }

  public ngOnInit(): void {
    this._handleChanges();
    this._evaluateTitle();
    this._evaluateGroups();
  }

  public ngOnChanges({type, start, end, events}: SimpleChanges): void {
    if (type && !type.isFirstChange()) {
      this._changedType();
    }
    const changedStart: boolean = start && !start.isFirstChange();
    const changedEnd: boolean = end && !end.isFirstChange();
    if (changedStart || changedEnd) {
      if (changedStart) {
        this._changedStart(start.currentValue);
      }
      if (changedEnd) {
        this._changedEnd(end.currentValue);
      }
      this._evaluateTitle();
    }
    if (events && !events.isFirstChange()) {
      this._changedEvents();
    }
  }

  public addEvent(group: IGestaoDGEMPSEventsDetailGroup): void {
    this.evtAddEvent.emit({
      codEmp: group.codEmp,
      startDate: this.start,
      endDate: this.end,
      group: group
    });
  }

  public closeDetail(): void {
    this.evtCloseDetail.emit();
  }

  public changedEventAttachment(event: IGestaoDGEMPSEvent, nativeEvent: Event): void {
    if (this.type !== EGestaoDGEMPSType.Faltas || event.status.integrado) {
      return;
    }
    const inputElement = <HTMLInputElement>nativeEvent.target;
    if (inputElement.files.length) {
      this._addAttachment(event, inputElement.files[0]).finally(() => {
        inputElement.value = '';
      });
    }
  }

  public readonly fnIntegraAll = (): Promise<void> => this._integraAll();

  public readonly fnApproveAll = (): Promise<void> => this._approveAll();

  public readonly fnRejectAll = (): Promise<void> => this._rejectAll();

  public readonly fnRewindAll = (): Promise<void> => this._rewindAll();

  public readonly fnDeleteAll = (): Promise<void> => this._deleteAll();

  public readonly fnSetMotivoAll = (): Promise<void> => this._setMotivoModal({global: true});

  public readonly fnIntegraAllGroup = (group: IGestaoDGEMPSEventsDetailGroup) => (): Promise<void> => this._integraAllGroup(group);

  public readonly fnApproveAllGroup = (group: IGestaoDGEMPSEventsDetailGroup) => (): Promise<void> => this._approveAllGroup(group);

  public readonly fnRejectAllGroup = (group: IGestaoDGEMPSEventsDetailGroup) => (): Promise<void> => this._rejectAllGroup(group);

  public readonly fnRewindAllGroup = (group: IGestaoDGEMPSEventsDetailGroup) => (): Promise<void> => this._rewindAllGroup(group);

  public readonly fnDeleteAllGroup = (group: IGestaoDGEMPSEventsDetailGroup) => (): Promise<void> => this._deleteAllGroup(group);

  public readonly fnSetMotivoGroup = (group: IGestaoDGEMPSEventsDetailGroup) => (): Promise<void> => this._setMotivoModal({group: group});

  public readonly fnApproveEvent = (event: IGestaoDGEMPSEventsDetailEvent) => (): Promise<void> => this._approveEvent(event);

  public readonly fnRejectEvent = (event: IGestaoDGEMPSEventsDetailEvent) => (): Promise<void> => this._rejectEvent(event);

  public readonly fnRewindEvent = (event: IGestaoDGEMPSEventsDetailEvent) => (): Promise<void> => this._rewindEvent(event);

  public readonly fnDeleteEvent = (event: IGestaoDGEMPSEventsDetailEvent) => (): Promise<void> => this._deleteEvent(event, true);

  public readonly fnDeleteEventAttachment = (event: IGestaoDGEMPSEventsDetailEvent) => (): Promise<void> => this._deleteAttachment(event);

  public readonly fnEventHistory = (event: IGestaoDGEMPSEventsDetailEvent) => (): Promise<void> => this._eventHistory(event);

  public get executing(): boolean {
    return this._executing > 0 || this.working;
  }

  private _handleChanges(): void {
    this._changedType();
    this._changedStart();
    this._changedEnd();
    this._changedEvents();
  }

  private _changedType(value: EGestaoDGEMPSType = this.type): void {
    this.type = value;
    switch (this.type) {
      case EGestaoDGEMPSType.Abonos:
        this._defaultMotivoScheduled = this._translateService.instant('gestaodgemps.abonos.motivos.marcar');
        this._defaultMotivoApprove = this._translateService.instant('gestaodgemps.abonos.motivos.aprovar');
        this._defaultMotivoReject = this._translateService.instant('gestaodgemps.abonos.motivos.rejeitar');
        this._defaultMotivoIntegrar = this._translateService.instant('gestaodgemps.abonos.motivos.integrar');
        break;
      case EGestaoDGEMPSType.Faltas:
        this._defaultMotivoScheduled = this._translateService.instant('gestaodgemps.faltas.motivos.marcar');
        this._defaultMotivoApprove = this._translateService.instant('gestaodgemps.faltas.motivos.aprovar');
        this._defaultMotivoReject = this._translateService.instant('gestaodgemps.faltas.motivos.rejeitar');
        this._defaultMotivoIntegrar = this._translateService.instant('gestaodgemps.faltas.motivos.integrar');
        break;
      case EGestaoDGEMPSType.Ferias:
        this._defaultMotivoScheduled = this._translateService.instant('gestaodgemps.ferias.motivos.marcar');
        this._defaultMotivoApprove = this._translateService.instant('gestaodgemps.ferias.motivos.aprovar');
        this._defaultMotivoReject = this._translateService.instant('gestaodgemps.ferias.motivos.rejeitar');
        this._defaultMotivoIntegrar = this._translateService.instant('gestaodgemps.ferias.motivos.integrar');
        break;
    }
  }

  private _changedStart(value: Moment = this.start): void {
    this.start = moment.isMoment(value) ? value : undefined;
  }

  private _changedEnd(value: Moment = this.end): void {
    this.end = moment.isMoment(value) ? value : undefined;
  }

  private _changedEvents(value: Array<IGestaoDGEMPSEventsDetailEvent> = this.events): void {
    this.events = isArray(value) ? this.events : [];
    this._evaluateGroups();
  }

  private _evaluateTitle(): void {
    const range: boolean = this.start && this.end && !this.start.isSame(this.end, 'date');
    let title: string;
    switch (this.type) {
      case EGestaoDGEMPSType.Abonos:
        title = !range ? 'gestaodgemps.details.titleAbonos' : 'gestaodgemps.details.titleRangeAbonos';
        break;
      case EGestaoDGEMPSType.Faltas:
        title = !range ? 'gestaodgemps.details.titleFaltas' : 'gestaodgemps.details.titleRangeFaltas';
        break;
      case EGestaoDGEMPSType.Ferias:
        title = !range ? 'gestaodgemps.details.titleFerias' : 'gestaodgemps.details.titleRangeFerias';
        break;
    }
    this.title = this._translateService.instant(
      title,
      !range ? {date: this._plI18nService.formatDate(this.start)} : {start: this._plI18nService.formatDate(this.start), end: this._plI18nService.formatDate(this.end)}
    );
  }

  private _evaluateGroups(): void {
    this.allowIntegraAll = false;
    this.allowApproveAll = false;
    this.allowRejectAll = false;
    this.allowRewindAll = false;
    this.allowDeleteAll = false;
    this.allowSetMotivoAll = false;
    const groupsMap: Map<number, IGestaoDGEMPSEventsDetailGroup> = new Map<number, IGestaoDGEMPSEventsDetailGroup>();
    let allApproved = true;
    let allRejected = true;
    for (const event of this.events) {
      let group = groupsMap.get(event.codEmp);
      if (!group) {
        group = {
          codEmp: event.codEmp,
          nomeEmp: event.nomeEmp,
          allowAdd: true,
          allowIntegraAll: false,
          allowApproveAll: false,
          allowRejectAll: false,
          allowRewindAll: false,
          allowDeleteAll: false,
          allowSetMotivoAll: false,
          motivo: '',
          events: []
        };
        groupsMap.set(event.codEmp, group);
      }
      group.events.push(event);

      if (!isBoolean(event.defaultMotivo)) {
        event.defaultMotivo = false;
      }
      if (!isBoolean(event.editingMotivo)) {
        event.editingMotivo = false;
      }

      let statusMessage = '';
      const gender: 'male' | 'female' = this.type === EGestaoDGEMPSType.Abonos ? 'male' : 'female';
      if (event.status.gozado) {
        statusMessage = `gestaodgemps.text.status.${gender}.gozado`;
      } else if (event.status.integrado) {
        statusMessage = `gestaodgemps.text.status.${gender}.integrado`;
      } else if (event.status.rejeitado) {
        statusMessage = `gestaodgemps.text.status.${gender}.rejeitado`;
      } else if (event.status.aprovado) {
        statusMessage = `gestaodgemps.text.status.${gender}.aprovado`;
      } else if (event.status.aprovadoParcialmente) {
        statusMessage = `gestaodgemps.text.status.${gender}.aprovadoParcialmente`;
      } else if (event.status.pendente) {
        statusMessage = `gestaodgemps.text.status.${gender}.marcado`;
      }
      event.statusMessage = statusMessage ? this._translateService.instant(statusMessage) : statusMessage;
      event.statusCSSClass = gestaoDGEMPSEvaluateEventCSSClassOutline(event.status);

      if (event.allDay) {
        group.allowAdd = false;
      }

      if (event.status.allowApproveReject) {
        allApproved = false;
        allRejected = false;
        if (!this.allowRewindAll) {
          this.allowRewindAll = true;
        }
        if (!group.allowApproveAll) {
          group.allowApproveAll = true;
        }
        if (!group.allowRejectAll) {
          group.allowRejectAll = true;
        }
        if (!group.allowSetMotivoAll) {
          group.allowSetMotivoAll = true;
        }
        if (event.status.aprovado) {
          if (!this.allowIntegraAll) {
            this.allowIntegraAll = true;
          }
          if (!group.allowIntegraAll) {
            group.allowIntegraAll = true;
          }
        }
      }

      if (event.status.rewindable) {
        if (!this.allowRewindAll) {
          this.allowRewindAll = true;
        }
        if (!group.allowRewindAll) {
          group.allowRewindAll = true;
        }
      }

      if (event.status.desmarcavel) {
        if (!this.allowDeleteAll) {
          this.allowDeleteAll = true;
        }
        if (!group.allowDeleteAll) {
          group.allowDeleteAll = true;
        }
      }

      if (
        event.motivo === this._defaultMotivoScheduled ||
        event.motivo === this._defaultMotivoRewind ||
        event.motivo === this._defaultMotivoApprove ||
        event.motivo === this._defaultMotivoReject ||
        event.motivo === this._defaultMotivoIntegrar
      ) {
        event.defaultMotivo = true;
      }
    }
    this.groups = Array.from(groupsMap.values());
    this.allowApproveAll = !allApproved;
    this.allowRejectAll = !allRejected;
  }

  private _integraAll(): Promise<void> {
    return this._cgModalService.showOkCancel('gestaodgemps.text.integraAll', 'gestaodgemps.text.confirm.integraAll', {size: 'md'}).then(() => this._integraAllEvents(this.events));
  }

  private _approveAll(): Promise<void> {
    return this._cgModalService.showOkCancel('gestaodgemps.text.approveAll', 'gestaodgemps.text.confirm.approveAll', {size: 'md'}).then(() => this._approveAllEvents(this.events));
  }

  private _rejectAll(): Promise<void> {
    return this._cgModalService.showOkCancel('gestaodgemps.text.rejectAll', 'gestaodgemps.text.confirm.rejectAll', {size: 'md'}).then(() => this._rejectAllEvents(this.events));
  }

  private _rewindAll(): Promise<void> {
    return this._cgModalService.showOkCancel('gestaodgemps.text.rewindAll', 'gestaodgemps.text.confirm.rewindAll', {size: 'md'}).then(() => this._rewindAllEvents(this.events));
  }

  private _deleteAll(): Promise<void> {
    return this._cgModalService.showOkCancel('gestaodgemps.text.markOffAll', 'gestaodgemps.text.confirm.markOffAll', {size: 'md'}).then(() => this._deleteAllEvents(this.events));
  }

  private _integraAllGroup(group: IGestaoDGEMPSEventsDetailGroup): Promise<void> {
    const message = this._translateService.instant('gestaodgemps.text.confirm.integraAllGroup', {
      codEmp: group.codEmp,
      nomeEmp: group.nomeEmp
    });
    return this._cgModalService.showOkCancel('gestaodgemps.text.integraAllGroup', message, {size: 'md'}).then(() => this._integraAllEvents(group.events));
  }

  private _approveAllGroup(group: IGestaoDGEMPSEventsDetailGroup): Promise<void> {
    const message = this._translateService.instant('gestaodgemps.text.confirm.approveAllGroup', {
      codEmp: group.codEmp,
      nomeEmp: group.nomeEmp
    });
    return this._cgModalService.showOkCancel('gestaodgemps.text.approveAllGroup', message, {size: 'md'}).then(() => this._approveAllEvents(group.events));
  }

  private _rejectAllGroup(group: IGestaoDGEMPSEventsDetailGroup): Promise<void> {
    const message = this._translateService.instant('gestaodgemps.text.confirm.rejectAllGroup', {
      codEmp: group.codEmp,
      nomeEmp: group.nomeEmp
    });
    return this._cgModalService.showOkCancel('gestaodgemps.text.rejectAll', message, {size: 'md'}).then(() => this._rejectAllEvents(group.events));
  }

  private _rewindAllGroup(group: IGestaoDGEMPSEventsDetailGroup): Promise<void> {
    const message = this._translateService.instant('gestaodgemps.text.confirm.rewindAllGroup', {
      codEmp: group.codEmp,
      nomeEmp: group.nomeEmp
    });
    return this._cgModalService.showOkCancel('gestaodgemps.text.rewindAll', message, {size: 'md'}).then(() => this._rewindAllEvents(group.events));
  }

  private _deleteAllGroup(group: IGestaoDGEMPSEventsDetailGroup): Promise<void> {
    const message = this._translateService.instant('gestaodgemps.text.confirm.markOffAllGroup', {
      codEmp: group.codEmp,
      nomeEmp: group.nomeEmp
    });
    return this._cgModalService.showOkCancel('gestaodgemps.text.markOffAll', message, {size: 'md'}).then(() => this._deleteAllEvents(group.events));
  }

  private _integraAllEvents(events: Array<IGestaoDGEMPSEventsDetailEvent>): Promise<void> {
    events = events.filter((event: IGestaoDGEMPSEventsDetailEvent) => event.status.aprovado);
    return this._executeOnEvents((event: IGestaoDGEMPSEventsDetailEvent) => this._approveEvent(event, false), events);
  }

  private _approveAllEvents(events: Array<IGestaoDGEMPSEventsDetailEvent>): Promise<void> {
    events = events.filter((event: IGestaoDGEMPSEventsDetailEvent) => event.status.allowApproveReject);
    return this._executeOnEvents((event: IGestaoDGEMPSEventsDetailEvent) => this._approveEvent(event, false), events);
  }

  private _rejectAllEvents(events: Array<IGestaoDGEMPSEventsDetailEvent>): Promise<void> {
    events = events.filter((event: IGestaoDGEMPSEventsDetailEvent) => event.status.allowApproveReject);
    return this._executeOnEvents((event: IGestaoDGEMPSEventsDetailEvent) => this._rejectEvent(event, false), events);
  }

  private async _rewindAllEvents(events: Array<IGestaoDGEMPSEventsDetailEvent>): Promise<void> {
    if (events.length > 1) {
      const posicao: number = events[0].posicao;
      let showWarning = false;
      for (const event of events.slice(1)) {
        if (event.posicao !== posicao) {
          showWarning = true;
          break;
        }
      }
      if (showWarning) {
        await this._cgModalService.showOkCancel('gestaodgemps.text.rewind', 'gestaodgemps.text.warning.rewindAllPosicao', {size: 'md'});
      }
    }
    events = events.filter((event: IGestaoDGEMPSEventsDetailEvent) => event.status.rewindable);
    return this._executeOnEvents((event: IGestaoDGEMPSEventsDetailEvent) => this._rewindEvent(event, false), events);
  }

  private _deleteAllEvents(events: Array<IGestaoDGEMPSEventsDetailEvent>): Promise<void> {
    events = events.filter((event: IGestaoDGEMPSEventsDetailEvent) => event.status.desmarcavel);
    return this._executeOnEvents((event: IGestaoDGEMPSEventsDetailEvent) => this._deleteEvent(event, false, false), events);
  }

  private _approveEvent(event: IGestaoDGEMPSEventsDetailEvent, refresh: boolean = true): Promise<void> {
    event.promiseApprove = this._servicePRHTarefasCab
      .mudarPosicao(event.idTarefaCab, this.codEmpLanca, event.editableMotivo)
      .then(() => {
        if (refresh) {
          this._refreshEvents();
        }
      })
      .finally(() => {
        event.promiseApprove = undefined;
      });
    this._addExecutionTask(event.promiseApprove);
    return event.promiseApprove;
  }

  private _rejectEvent(event: IGestaoDGEMPSEventsDetailEvent, refresh: boolean = true): Promise<void> {
    event.promiseReject = this._servicePRHTarefasCab
      .rejeitar(event.idTarefaCab, this.codEmpLanca, event.editableMotivo)
      .then(() => {
        if (refresh) {
          this._refreshEvents();
        }
      })
      .finally(() => {
        event.promiseReject = undefined;
      });
    this._addExecutionTask(event.promiseReject);
    return event.promiseReject;
  }

  private _rewindEvent(event: IGestaoDGEMPSEventsDetailEvent, refresh: boolean = true): Promise<void> {
    event.promiseRewind = this._servicePRHTarefasCab
      .retrocedePosicao(event.idTarefaCab, this.codEmpLanca, event.editableMotivo)
      .then(() => {
        if (refresh) {
          this._refreshEvents();
        }
      })
      .finally(() => {
        event.promiseRewind = undefined;
      });
    this._addExecutionTask(event.promiseRewind);
    return event.promiseRewind;
  }

  private _deleteEvent(event: IGestaoDGEMPSEventsDetailEvent, prompt: boolean, refresh: boolean = true): Promise<void> {
    event.promiseDelete = prompt ? this._gestaoDGEMPSService.desmarcarTarefa(this.type, event) : this._gestaoDGEMPSService.deleteTarefa(event);
    event.promiseDelete
      .then(() => {
        if (refresh) {
          this._refreshEvents();
        }
      })
      .finally(() => {
        event.promiseDelete = undefined;
      });
    this._addExecutionTask(event.promiseDelete);
    return event.promiseDelete;
  }

  private _addAttachment(event: IGestaoDGEMPSEvent, file: Blob): Promise<void> {
    if (this.type !== EGestaoDGEMPSType.Faltas) {
      return Promise.resolve();
    }
    this.promiseAttachment = this._gestaoDGEMPSService.servicePRHTarefasCab
      .uploadDoc(event.idTarefaCab, file)
      .then(() => undefined)
      .then(() => {
        this._plAlertService.success(!event.docID ? 'gestaodgemps.text.success.addAttachment' : 'gestaodgemps.text.success.replaceAttachment');
        this._refreshEvents();
      });
    this._addExecutionTask(this.promiseAttachment);
    return this.promiseAttachment;
  }

  private _deleteAttachment(event: IGestaoDGEMPSEventsDetailEvent): Promise<void> {
    if (this.type !== EGestaoDGEMPSType.Faltas || !event.docID) {
      return Promise.resolve();
    }
    const task: Promise<void> = this._cgModalService.showOkCancel('gestaodgemps.text.deleteAttachment', 'gestaodgemps.text.confirm.deleteAttachment', {size: 'md'}).then(() => {
      const downloadUrl: string = event.downloadUrl;
      event.downloadUrl = undefined;
      this.promiseAttachment = this._gestaoDGEMPSService.servicePRHTarefasCab
        .deleteTarefaDoc(event.idTarefaCab)
        .then(() => {
          this._plAlertService.success('gestaodgemps.text.success.deleteAttachment');
          this._refreshEvents();
        })
        .catch(() => {
          event.downloadUrl = downloadUrl;
        });
      return this.promiseAttachment;
    });
    this._addExecutionTask(task);
    return task;
  }

  private _executeOnEvents(action: (event: IGestaoDGEMPSEventsDetailEvent) => Promise<void>, events: Array<IGestaoDGEMPSEventsDetailEvent>): Promise<void> {
    let successfulExecuted = false;
    const fnExecute = (): Promise<void> => {
      const currentEvent: IGestaoDGEMPSEventsDetailEvent = events.shift();
      if (!currentEvent) {
        if (successfulExecuted) {
          this._refreshEvents();
        }
        return Promise.resolve();
      }
      return action(currentEvent)
        .then(() => {
          if (!successfulExecuted) {
            successfulExecuted = true;
          }
          return fnExecute();
        })
        .catch(() => fnExecute());
    };
    const task: Promise<void> = fnExecute();
    this._addExecutionTask(task);
    return task;
  }

  private _eventHistory(event: IGestaoDGEMPSEventsDetailEvent): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(GestaoDGEMPSEventHistoryModalComponent, {size: 'lg'});
    const componentInstance: GestaoDGEMPSEventHistoryModalComponent = modalInstance.componentInstance;
    componentInstance.event = event;
    return modalInstance.result;
  }

  private _setMotivoModal(params: IGestaoDGEMPSEventsDetailSetMotivoModalParams): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(GestaoDGEMPSMotivoModalComponent, {size: 'lg'});
    const componentInstance: GestaoDGEMPSMotivoModalComponent = modalInstance.componentInstance;
    componentInstance.motivo = params.group ? params.group.motivo : params.global ? this._motivoGlobal : '';
    componentInstance.global = params.global;
    componentInstance.group = params.group;
    return modalInstance.result.then((motivo: string) => {
      const events: Array<IGestaoDGEMPSEventsDetailEvent> = (params.global ? this.events : params.group ? params.group.events : []).filter(
        (event: IGestaoDGEMPSEventsDetailEvent) => event.status.allowApproveReject
      );
      for (const event of events) {
        event.editableMotivo = motivo;
      }
      if (params.global) {
        this._motivoGlobal = motivo;
      }
    });
  }

  private _refreshEvents(): void {
    this.evtRefresh.emit();
  }

  private _addExecutionTask(task: Promise<void>): void {
    this._executing++;
    task.finally(() => {
      this._executing--;
    });
  }
}
