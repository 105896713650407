import {round} from '../../../common/utils/utils';
import {CUSTO_MEDIO_PONDERADO, FIFO} from '../../datasources/criteriodisponiveis/criterioDisponiveis.datasource.interface';
import {IJsonDocSatEncomLin} from './jsonEncomenda.module.interface';

export function temLinhasComLotes(linhas: Array<IJsonDocSatEncomLin>, isCompra: boolean): boolean {
  return linhas.findIndex((item: IJsonDocSatEncomLin) => item.ligacaoQtd > 0 && item.temLote && (item.codValoriz === CUSTO_MEDIO_PONDERADO || (item.codValoriz === FIFO && isCompra)) === true) > -1;
}

export function preencheAutomaticamenteLotesNaLinha(linha: IJsonDocSatEncomLin, nDecimais: number): void {
  const qtdADistribuir = linha.ligacaoQtd;

  let qtdFACDistribuida = 0;
  let qtdSatLista = 0;

  for (const lote of linha.lotes) {
    const oldSelected = linha.qtd1;

    //quantidade que é suporto colocar na lista (caso normal)
    let tempQtd = round(lote.qtd + qtdADistribuir - qtdFACDistribuida, nDecimais);

    //verificar se a quantidade que vou colocar na linha ultrapassa a quantidade disponivel
    if (tempQtd > lote.qtdStock) {
      tempQtd = lote.qtdStock;
    }

    //se já distribui tudo... vou ver se há quantidades distribuidas a mais
    if (qtdFACDistribuida >= qtdADistribuir) {
      tempQtd = qtdFACDistribuida >= qtdADistribuir ? round(linha.ligacaoQtd - qtdFACDistribuida, nDecimais) : lote.qtd;

      //caso em que existe mais quantidade seleccionada do que qtd fac, reduz para a qtd fac
      if (tempQtd > lote.qtdStock) {
        tempQtd = lote.qtdStock;
      }

      //caso em que já existe mais quantidade distribuida do que era suposto distribuir
      if (qtdFACDistribuida + tempQtd >= linha.ligacaoQtd) {
        tempQtd = round(linha.ligacaoQtd - qtdFACDistribuida, nDecimais);
      }
    }

    //já existe mais quantidade satisfeita na lista do que era suporto distribuir..
    //reduz para a quantidade em falta
    if (qtdSatLista >= linha.ligacaoQtd) {
      tempQtd = round(linha.ligacaoQtd - qtdSatLista, nDecimais);
      //caso em que existe mais quantidade seleccionada do que qtd fac, reduz para a qtd fac
      if (tempQtd > lote.qtd) {
        tempQtd = lote.qtdStock;
      }

      //não pode ser negativa
      if (tempQtd < 0) {
        tempQtd = 0;
      }
    }

    lote.qtd = tempQtd; //coloca a nova quantidade na linha
    qtdSatLista = round(qtdSatLista + lote.qtd, nDecimais); //soma quantidade total seleccionada na lista
    qtdFACDistribuida = round(tempQtd - oldSelected, nDecimais); //soma quantidade distribuida
  }
}
