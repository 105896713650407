<pl-form [(formInstance)]="form" [properties]="{orientation: 'horizontal'}" (evtSubmitted)="close()">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="promise" (click)="dismiss()"></button>

    <h4 class="modal-title" [translate]="'docscontabilidade.moedaestrangeira.title'"></h4>

    <i class="fa fa-spin fa-circle-o-notch" *ngIf="promise"></i>
  </div>

  <div class="modal-body entity-detail-form" [plPromise]="promise">
    <pl-group>
      <label [translate]="'docscontabilidade.moedaestrangeira.conta'"></label>
      <edit>
        <div class="form-control-align">{{ conta }}</div>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'docscontabilidade.moedaestrangeira.moeda'"></label>
      <edit>
        <div class="form-control-align">{{ nomeMoeda }}</div>
      </edit>
    </pl-group>

    <pl-group [properties]="{disabled: isLocked(types.ValorCambio)}">
      <label [translate]="'docscontabilidade.moedaestrangeira.cambioME'"></label>
      <edit>
        <pl-edit
          type="number"
          [model]="linha.cambio"
          (modelChange)="changedCambio($event)"
          [properties]="{modelOptions: {updateOn: 'blur'}, decimalsLimit: doc.nDecimaisCambio}"
          plAutoFocus
          [plAutoFocusDisabled]="isLocked(types.ValorCambio)">
        </pl-edit>
      </edit>
      <actions>
        <button type="button" class="btn btn-sm" [ngClass]="getBtnClass(types.ValorCambio)" [disabled]="promise" (click)="doLock(types.ValorCambio)">
          <i class="fa fa-fw" [ngClass]="getLockClass(types.ValorCambio)"></i>
        </button>
      </actions>
    </pl-group>

    <pl-group [properties]="{disabled: isLocked(types.ValorME)}">
      <label [translate]="'docscontabilidade.moedaestrangeira.valorME'"></label>
      <edit>
        <pl-edit
          type="number"
          [model]="linha.valorME"
          (modelChange)="changedValorME($event)"
          [properties]="{modelOptions: {updateOn: 'blur'}, decimalsLimit: doc.nDecimaisValorME}"
          plAutoFocus
          [plAutoFocusDisabled]="!isLocked(types.ValorCambio) && isLocked(types.ValorME)">
        </pl-edit>
      </edit>
      <actions>
        <button type="button" class="btn btn-sm" [ngClass]="getBtnClass(types.ValorME)" [disabled]="promise" (click)="doLock(types.ValorME)">
          <i class="fa fa-fw" [ngClass]="getLockClass(types.ValorME)"></i>
        </button>
      </actions>
    </pl-group>

    <pl-group [properties]="{disabled: isLocked(types.Valor)}">
      <label [translate]="'docscontabilidade.moedaestrangeira.valorNacional'"></label>
      <edit>
        <pl-edit type="number" [model]="linha.valor" (modelChange)="changedValor($event)" [properties]="{modelOptions: {updateOn: 'blur'}, decimalsLimit: doc.nDecimaisValor}"></pl-edit>
      </edit>
      <actions>
        <button type="button" class="btn btn-sm" [ngClass]="getBtnClass(types.Valor)" [disabled]="promise" (click)="doLock(types.Valor)">
          <i class="fa fa-fw" [ngClass]="getLockClass(types.Valor)"></i>
        </button>
      </actions>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn-primary action-save"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.apply'"></span></pl-button>

    <pl-button type="button" klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</pl-form>
