import {copy, isArray, isObject, isString, isUndefinedOrNull} from './utilities';
import type {TNgClassSupportedTypes} from '../angular.interface';

export function ngClassAdd(klasses: TNgClassSupportedTypes, klass: string): TNgClassSupportedTypes {
  if (isUndefinedOrNull(klasses)) {
    klasses = '';
  }
  if (isString(klasses)) {
    klasses = !klasses ? [] : klasses.split(' ');
  }
  if (isArray(klasses)) {
    klasses = klasses.slice();
    if (!klasses.includes(klass)) {
      klasses.push(klass);
    }
  } else if (isObject(klasses)) {
    klasses = copy(klasses);
    if (!Object.prototype.hasOwnProperty.call(<object>klasses, klass)) {
      (<object>klasses)[klass] = true;
    }
  }
  return klasses;
}

export function ngClassRemove(klasses: TNgClassSupportedTypes, klass: string): TNgClassSupportedTypes {
  if (isUndefinedOrNull(klasses)) {
    klasses = '';
  }
  if (isString(klasses)) {
    klasses = !klasses ? [] : klasses.split(' ');
  }
  if (isArray(klasses)) {
    klasses = klasses.slice();
    const klassIndex: number = klasses.indexOf(klass);
    if (klassIndex > -1) {
      klasses.splice(klassIndex, 1);
    }
  } else if (isObject(klasses)) {
    klasses = copy(klasses);
    if (Object.prototype.hasOwnProperty.call(<object>klasses, klass)) {
      delete (<object>klasses)[klass];
    }
  }
  return klasses;
}

export function ngClassContains(klasses: TNgClassSupportedTypes, klass: string): boolean {
  if (isUndefinedOrNull(klasses)) {
    return false;
  }
  if (isString(klasses)) {
    return klasses.split(' ').includes(klass);
  }
  if (isArray(klasses)) {
    return klasses.includes(klass);
  } else if (isObject(klasses)) {
    return Object.prototype.hasOwnProperty.call(<object>klasses, klass) && Boolean(klasses[klass]);
  }
  return false;
}
