import {uniq} from 'lodash-es';
import {Subject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {Component, Injector, OnDestroy, OnInit, TrackByFunction} from '@angular/core';
import {EScreenSize, IPlToolbarItem, IPlToolbarMenuItem, isArray, isNumber} from 'pl-comps-angular';
import {
  ENotificationType,
  INotification,
  INotificationsUI,
  notificationMarkAsVisitedDelay,
  notificationsToMap,
  notificationsTrackBy,
  sortNotifications,
  TNotifications
} from '../../../services/notificationcenter/notificationcenter.service.interface';
import {
  INotificationCenterCategory,
  INotificationCenterStateParams,
  NOTIFICATION_CENTER_CATEGORIES,
  notificationCenterActivateCategory,
  notificationCenterDeactivateCategory
} from '../notificationCenter.module.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {NotificationCenterService} from '../../../services/notificationcenter/notificationcenter.service';

const TOOLBAR_GROUP_ID = 'notificationcenterstate';

@Component({
  selector: 'notification-center-state',
  templateUrl: './notificationCenter.module.component.html'
})
export class NotificationCenterModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly notifications: INotificationsUI;
  public readonly notificationsTrackByFn: TrackByFunction<INotification>;
  public categories: ReadonlyArray<INotificationCenterCategory>;
  public filteredNewNotifications: TNotifications;
  public filteredOldNotifications: TNotifications;
  public mobile: boolean;

  private readonly _subjectDestroyed: Subject<void>;
  private readonly _toolbarCategories: IPlToolbarItem<ENotificationType>;
  private readonly _subscriptionWindowWidth: Subscription;
  private _subscriptionNotifications: Subscription;
  private _category: ENotificationType;

  constructor(
    protected readonly _injector: Injector,
    private readonly _notificationService: NotificationCenterService
  ) {
    super(_injector);
    this.notifications = {
      notificationsNew: [],
      notificationsOld: []
    };
    this.notificationsTrackByFn = notificationsTrackBy;
    this.categories = [];
    this.filteredNewNotifications = [];
    this.filteredOldNotifications = [];
    this.mobile = false;
    this._subjectDestroyed = new Subject<void>();
    this._toolbarCategories = {
      type: 'dropdown',
      groupId: TOOLBAR_GROUP_ID,
      id: 'categories',
      order: 1,
      class: 'btn-primary',
      iconLeft: '<i class="fa fa-fw fa-filter"></i>',
      menu: []
    };
    this._subscriptionWindowWidth = this._plDocumentService.windowWidth().subscribe((windowWidth: number) => {
      this.mobile = windowWidth < EScreenSize.SMALL;
    });
    this._notificationService
      .notifications()
      .pipe(take(1))
      .subscribe((notifications: TNotifications) => {
        this._evaluateCategoriesVisibility(notifications);
        const params: INotificationCenterStateParams = this._transition.params();
        let firstTime: boolean = isArray(params.newNotificationsIds) && params.newNotificationsIds.length > 0;
        this._subscriptionNotifications = this._notificationService.notificationsUI(this.notifications).subscribe((notificationsUI: INotificationsUI) => {
          this.notifications.notificationsNew = notificationsUI.notificationsNew;
          this.notifications.notificationsOld = notificationsUI.notificationsOld;
          this.dropdownActions.visible = this.notifications.notificationsNew.length > 0 || this.notifications.notificationsOld.length > 0;

          // Combine param notifications with new notications on the first time
          if (firstTime) {
            firstTime = false;
            const notificationsMap: Map<string, INotification> = notificationsToMap(notifications);
            const notificationsNewIds: Array<string> = uniq(this.notifications.notificationsNew.map((notification: INotification) => notification.id).concat(params.newNotificationsIds));
            this.notifications.notificationsNew = Object.freeze(sortNotifications(notificationsNewIds.map<INotification>((notificationId: string) => notificationsMap.get(notificationId))));
            this.notifications.notificationsOld = Object.freeze(this.notifications.notificationsOld.filter((notification: INotification) => !notificationsNewIds.includes(notification.id)));
          }

          // Filter notifications by category
          this._filterNotifications();

          notificationMarkAsVisitedDelay(this._subjectDestroyed).subscribe(() => {
            this._notificationService.markNotificationsUIAsVisited(this.notifications).catch((reason: unknown) => {
              this._logger.error(reason);
            });
          });
        });
      });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._toolbarCategories);
    this.dropdownActions.order = this._toolbarCategories.order + 1;
    this.dropdownActions.menu = [
      {
        caption: 'notificationcenter.actions.readAll',
        click: () => this._readAllNotifications()
      }
    ];
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subjectDestroyed.next();
    this._subjectDestroyed.complete();
    this._subscriptionWindowWidth.unsubscribe();
    if (this._subscriptionNotifications) {
      this._subscriptionNotifications.unsubscribe();
    }
  }

  public selectedNotification(notification: INotification): void {
    this._notificationService.markNotificationAsRead(notification.id).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  private _evaluateCategoriesVisibility(notifications: TNotifications): void {
    const notificationTypes: Array<ENotificationType> = uniq(notifications.map<ENotificationType>((notification: INotification) => notification.type));
    this.categories = NOTIFICATION_CENTER_CATEGORIES.filter((category: INotificationCenterCategory) => {
      return category.type === ENotificationType.All || notificationTypes.includes(category.type);
    });
    this._toolbarCategories.visible = this.categories.length > 2;
    this._toolbarCategories.menu = this.categories.map<IPlToolbarMenuItem<ENotificationType>>((category: INotificationCenterCategory) => {
      return {
        caption: category.caption,
        data: category.type,
        click: (menuItem: IPlToolbarMenuItem<ENotificationType>) => {
          this._setToolbarCategory(menuItem.data);
        }
      };
    });
    const category: ENotificationType = this.categories.find((categoryItem: INotificationCenterCategory) => categoryItem.active)?.type ?? ENotificationType.All;
    this._setToolbarCategory(category);
  }

  private _filterNotifications(): void {
    const filterFn = (notifications: TNotifications): TNotifications => {
      if (this._category === ENotificationType.All) {
        return notifications;
      }
      return notifications.filter((notification: INotification) => notification.type === this._category);
    };
    this.filteredNewNotifications = filterFn(this.notifications.notificationsNew);
    this.filteredOldNotifications = filterFn(this.notifications.notificationsOld);
  }

  private _setToolbarCategory(category: ENotificationType): void {
    this._setCategory(category);
    this._toolbarCategories.caption = `notificationcenter.categories.${this._category}`;
  }

  private _setCategory(type: ENotificationType): void {
    if (isNumber(this._category)) {
      if (type === this._category) {
        return;
      }
      this.categories = notificationCenterDeactivateCategory(this.categories, this._category);
    }
    this.categories = notificationCenterActivateCategory(this.categories, type);
    this._category = type;
    this._filterNotifications();
  }

  private _readAllNotifications(): Promise<void> {
    const unreadNotificationsNew: Array<INotification> = this.notifications.notificationsNew.filter((item: INotification) => !item.read);
    const unreadNotificationsOld: Array<INotification> = this.notifications.notificationsOld.filter((item: INotification) => !item.read);
    const unreadNotificationsIds: Array<string> = unreadNotificationsNew.concat(unreadNotificationsOld).map((notification: INotification) => notification.id);
    return this._notificationService.markNotificationAsRead(unreadNotificationsIds).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }
}
