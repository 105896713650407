import {HttpResponse} from '@angular/common/http';
import {EReport} from '../../../entities/reports/reports.interface';
import {IJsonReport} from '../../../entities/reports/jsonReport.interface';
import {IJsonRHImpVenctHeader} from './jsonRHImpVenct.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_IMPVENCT} from './rhImpVenct.module.interface';
import {ReportsRegistryService} from '../../../components/reports/reports.registry.service';
import {RhImpVenctModuleComponent} from './components/rhImpVenct.module.component';
import {RhImpVenctService} from './rhImpVenct.module.service';
import {ROLE} from '../../../services/role.const';

export const MODULE_RH_IMPVENCT: IModuleDefinition = {
  name: MODULE_NAME_RH_IMPVENCT,
  state: {
    url: `/${MODULE_NAME_RH_IMPVENCT}`,
    component: RhImpVenctModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhimpvenct'
    },
    resolve: [
      {
        provide: 'header',
        deps: [RhImpVenctService],
        useFactory: (rhImpVenctService: RhImpVenctService): Promise<IJsonRHImpVenctHeader> => {
          return rhImpVenctService.getLoadDefaultHeader().then((response: HttpResponse<IJsonRHImpVenctHeader>) => response.body);
        }
      },
      {
        provide: 'defaultReport',
        deps: [ReportsRegistryService],
        useFactory: (reportsRegistryService: ReportsRegistryService): Promise<IJsonReport> => {
          return reportsRegistryService
            .get(EReport.RHImpVenct)
            .query({pagina: 1, porpagina: 1})
            .then((reports: Array<IJsonReport>) => {
              if (reports.length) {
                return reports[0];
              }
              return undefined;
            });
        }
      }
    ]
  }
};
