import {IEntityService} from '../../services/entity/entity.service.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ILoginWsSS} from '../../modules/segsocial/loginWsSS.module.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface IWsSSLoginEntityService extends IEntityService<ILoginWsSS> {
  getFor(nEmpresa: string): TServiceResponse<ILoginWsSS>;

  postFor(nEmpresa: string, credentials: ILoginWsSS): TServiceResponse<ILoginWsSS>;
}

export interface IIWsSSLoginEntity extends IEntityDefinition<ILoginWsSS, IWsSSLoginEntityService> {}

export const ENTITY_NAME_WS_SS_LOGIN = 'wssslogin';
