<div class="entity-detail-form">
  <pl-form>
    <pl-group>
      <label [translate]="'global.text.listing'"></label>
      <edit>
        <pl-edit type="reports_extratos" attrName="listagem" [(model)]="reportModel" plAutoFocus></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'components.contabilidade.listagens.fromAccount'"></label>
        <edit>
          <entity-autocomplete entity="pocs" attrName="daConta" [selectedKey]="deConta" (selectedKeyChange)="deContaChanged($event)" output="key"></entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'global.text.to'"></label>
        <edit>
          <entity-autocomplete entity="pocs" attrName="ateConta" [selectedKey]="ateConta" (selectedKeyChange)="ateConta = $event; filtersChanged()" output="key"></entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'components.contabilidade.listagens.toPeriod'"></label>
        <edit>
          <entity-autocomplete
            entity="periodos"
            attrName="atePeriodo"
            [model]="periodo"
            (modelChange)="periodoChanged($event)"
            [filter]="'loadAllAnos=' + loadAllAnos + '&loadOnlyAno=' + loadOnlyAno"
            [properties]="{events: {keydown: fnKeydownProcessar}}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>
  </pl-form>
</div>

<br />

<cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
