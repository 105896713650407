<div class="modelo22-anexoF">
  <pl-form>
    <pl-navigator contentMaxHeight="80vh">
      <pl-navigator-item caption="modelo22.anexoF.quadro03.title">
        <div *plNavigatorItemCaption><span class="number-title-box">03</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoF.quadro03.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field1'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">1</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoFQuadro03Campo01"
                    [model]="anexoF.quadro03.campo01"
                    (modelChange)="anexoF.quadro03.campo01 = $event; calcCamp17and18()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoF.quadro03.acrescer'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field2'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">2</div>
                  <pl-edit type="currency" attrName="anexoFQuadro03Campo02" [model]="anexoF.quadro03.campo02" (modelChange)="anexoF.quadro03.campo02 = $event; calcCamp8()" [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field3'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">3</div>
                  <pl-edit type="currency" attrName="anexoFQuadro03Campo03" [model]="anexoF.quadro03.campo03" (modelChange)="anexoF.quadro03.campo03 = $event; calcCamp8()" [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field4'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">4</div>
                  <pl-edit type="currency" attrName="anexoFQuadro03Campo04" [model]="anexoF.quadro03.campo04" (modelChange)="anexoF.quadro03.campo04 = $event; calcCamp8()" [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field5'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">5</div>
                  <pl-edit type="currency" attrName="anexoFQuadro03Campo05" [model]="anexoF.quadro03.campo05" (modelChange)="anexoF.quadro03.campo05 = $event; calcCamp8()" [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field6'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">6</div>
                  <pl-edit type="currency" attrName="anexoFQuadro03Campo06" [model]="anexoF.quadro03.campo06" (modelChange)="anexoF.quadro03.campo06 = $event; calcCamp8()" [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field19'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">19</div>
                  <pl-edit type="currency" attrName="anexoFQuadro03Campo19" [model]="anexoF.quadro03.campo19" (modelChange)="anexoF.quadro03.campo19 = $event; calcCamp8()" [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">7</div>
                  <pl-edit type="currency" attrName="anexoFQuadro03Campo7" [model]="anexoF.quadro03.campo07" (modelChange)="anexoF.quadro03.campo07 = $event; calcCamp8()" [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field8'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">8</div>
                  <pl-edit type="currency" attrName="anexoFQuadro03Campo8" [model]="anexoF.quadro03.campo08" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoF.quadro03.deduzir'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field9'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">9</div>
                  <pl-edit type="currency" attrName="anexoFQuadro03Campo9" [model]="anexoF.quadro03.campo09" (modelChange)="anexoF.quadro03.campo09 = $event; calcCamp16()" [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field10'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">10</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoFQuadro03Campo10"
                    [model]="anexoF.quadro03.campo10"
                    (modelChange)="anexoF.quadro03.campo10 = $event; calcCamp16()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field11'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">11</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoFQuadro03Campo11"
                    [model]="anexoF.quadro03.campo11"
                    (modelChange)="anexoF.quadro03.campo11 = $event; calcCamp16()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field12'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">12</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoFQuadro03Campo12"
                    [model]="anexoF.quadro03.campo12"
                    (modelChange)="anexoF.quadro03.campo12 = $event; calcCamp16()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field13'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">13</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoFQuadro03Campo13"
                    [model]="anexoF.quadro03.campo13"
                    (modelChange)="anexoF.quadro03.campo13 = $event; calcCamp16()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field14'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">14</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoFQuadro03Campo14"
                    [model]="anexoF.quadro03.campo14"
                    (modelChange)="anexoF.quadro03.campo14 = $event; calcCamp16()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">15</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoFQuadro03Campo15"
                    [model]="anexoF.quadro03.campo15"
                    (modelChange)="anexoF.quadro03.campo15 = $event; calcCamp16()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field16'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">16</div>
                  <pl-edit type="currency" attrName="anexoFQuadro03Campo16" [model]="anexoF.quadro03.campo16" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field17'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">17</div>
                  <pl-edit type="currency" attrName="anexoFQuadro03Campo17" [model]="anexoF.quadro03.campo17" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro03.field18'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">18</div>
                  <pl-edit type="currency" attrName="anexoFQuadro03Campo18" [model]="anexoF.quadro03.campo18" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoF.quadro04.title">
        <div *plNavigatorItemCaption><span class="number-title-box">04</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoF.quadro04.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro04.field1'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">1</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoFQuadro04Campo1"
                    [model]="anexoF.quadro04.campo01"
                    (modelChange)="anexoF.quadro04.campo01 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro04.field2'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">2</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoFQuadro04Campo2"
                    [model]="anexoF.quadro04.campo02"
                    (modelChange)="anexoF.quadro04.campo02 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro04.field3'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">3</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoFQuadro04Campo3"
                    [model]="anexoF.quadro04.campo03"
                    (modelChange)="anexoF.quadro04.campo03 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro04.field4'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">4</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoFQuadro04Campo4"
                    [model]="anexoF.quadro04.campo04"
                    (modelChange)="anexoF.quadro04.campo04 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro04.field5'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">5</div>
                  <pl-edit type="currency" attrName="anexoFQuadro04Campo5" [model]="anexoF.quadro04.campo05" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoF.quadro05.title">
        <div *plNavigatorItemCaption><span class="number-title-box">05</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoF.quadro05.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro05.field1'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">1</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoFQuadro05Campo1"
                    [model]="anexoF.quadro05.campo01"
                    (modelChange)="anexoF.quadro05.campo01 = $event; calcQuadro5()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div><label class="fw-bold" [translate]="'modelo22.anexoF.quadro05.desc'"></label></div>

            <div class="row ms-2">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro05.field2'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">2</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoFQuadro05Campo2"
                    [model]="anexoF.quadro05.campo02"
                    (modelChange)="anexoF.quadro05.campo02 = $event; calcQuadro5()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row ms-2">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro05.field3'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">3</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoFQuadro05Campo3"
                    [model]="anexoF.quadro05.campo03"
                    (modelChange)="anexoF.quadro05.campo03 = $event; calcQuadro5()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro05.field4'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">4</div>
                  <pl-edit type="currency" attrName="anexoFQuadro05Campo4" [model]="anexoF.quadro05.campo04" [properties]="{disabled: true}" [inputClass]="'text-right'"></pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoF.quadro06.title">
        <div *plNavigatorItemCaption><span class="number-title-box">06</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoF.quadro06.title'"></span></div>

        <div *plNavigatorItemContent>
          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">A</div>
              <label [translate]="'modelo22.anexoF.quadro06.descA'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro6A" cgDxDataGridInstanceName="quadro6A" [dataSource]="dataGridDefinitionQuadro6A.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtnQuadro6A'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoFQuadro6(item.rowIndex, 'A')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>
            </div>
          </section>

          <div class="inputs-currency-inline mt-4">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro06.field9'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">9</div>
                  <pl-edit type="currency" attrName="anexoFQuadro06Campo9" [model]="anexoF.quadro06.campoA09" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro06.field10'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">10</div>
                  <pl-edit type="currency" attrName="anexoFQuadro06Campo10" [model]="anexoF.quadro06.campoA10" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>
          </div>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">B</div>
              <label [translate]="'modelo22.anexoF.quadro06.descB'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro6B" cgDxDataGridInstanceName="quadro6B" [dataSource]="dataGridDefinitionQuadro6B.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtnQuadro6B'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoFQuadro6(item.rowIndex, 'B')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>
            </div>
          </section>

          <div class="inputs-currency-inline mt-4">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoF.quadro06.field6'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">6</div>
                  <pl-edit type="currency" attrName="anexoFQuadro06Campo6" [model]="anexoF.quadro06.campoB06" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>
    </pl-navigator>
  </pl-form>
</div>
