import moment from 'moment';
import {PlAlertService} from 'pl-comps-angular';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {ENTITY_NAME_TIRS, IDataEscalao, ITIRSDT, ITIRSEntityService} from '../../tirs.entity.interface';
import {IApiRequestConfig, IApiRequestConfigWithBody} from '../../../../services/api/api.service.interface';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnInitNewRow} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonDate} from '../../../../../common/interfaces/json';
import {IJsonTirs, IJsonTIRSDT} from '../../jsonTirs.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';

@Component({
  selector: 'tirs-edit',
  templateUrl: './tirs.entity.edit.component.html'
})
export class TIRSEditComponent extends ModuloEntityDetailComponent<IJsonTirs> implements OnInit, OnDestroy {
  @Input() public datasEscaloes: Array<IDataEscalao>;

  public definitionEscaloesIRS: IDevExpressDataGrid<ITIRSDT, ITIRSDT>;
  public tableIRSDT: Array<ITIRSDT>;
  public currentDataTaxaIrs: IDataEscalao;

  private readonly _serviceTirs: ITIRSEntityService;
  private _dataGridInstanceEscaloesIRS: dxDataGrid<IJsonTIRSDT, IJsonTIRSDT>;
  private _isTabelaNova: boolean;
  private _isDuplicate: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.datasEscaloes = [];
    this.tableIRSDT = [];
    this.currentDataTaxaIrs = undefined;
    this._isTabelaNova = false;
    this._isDuplicate = false;
    this._serviceTirs = this._entityServiceBuilder.build<IJsonTirs, ITIRSEntityService>(ENTITY_NAME_TIRS);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const stateCameFromDetail: boolean = this._transition.from().params.tirstype?.value === EEntityStateDetailType.DETAIL;
    if (this.model.nTabIrs || stateCameFromDetail) {
      if (stateCameFromDetail) {
        this._isDuplicate = true;
      }
      this.tableIRSDT = this.model.irsDT.slice();
      this._serviceTirs.distinctDatasEscaloes(this.model.nTabIrs).then(async (response: HttpResponse<Array<IJsonDate>>) => {
        this.datasEscaloes = response.body;
        await this._buildDataEscaloesAndSelectCurrent();
      });
      this._checkTableCodeStartsWith(true);
    } else {
      this.model = {
        nTabIrs: '',
        designaBreve: '',
        designaCompl: '',
        dataVigor: moment(),
        dtulTabela: moment(),
        tabelaOficial: undefined,
        irsDT: []
      };
      this.currentDataTaxaIrs = {
        value: moment(),
        label: moment().format(this.format.momentDate)
      };
    }

    this.definitionEscaloesIRS = {
      height: '57vh',
      columns: [
        {dataField: 'valorILIQ', dataType: 'double', caption: 'tirs.table.ateRemunMes'},
        {dataField: 'percDesc0DEP', dataType: 'double', caption: '0', visible: !this._isTabelaNova},
        {dataField: 'percDesc1DEP', dataType: 'double', caption: '1', visible: !this._isTabelaNova},
        {dataField: 'percDesc2DEP', dataType: 'double', caption: '2', visible: !this._isTabelaNova},
        {dataField: 'percDesc3DEP', dataType: 'double', caption: '3', visible: !this._isTabelaNova},
        {dataField: 'percDesc4DEP', dataType: 'double', caption: '4', visible: !this._isTabelaNova},
        {dataField: 'percDesc5DEP', dataType: 'double', caption: 'tirs.table.maiorigual5', visible: !this._isTabelaNova},
        {dataField: 'valorAbater5DEP', dataType: 'double', caption: 'tirs.table.vabater5', visible: !this._isTabelaNova},
        {dataField: 'valorAbater4DEP', dataType: 'double', caption: 'tirs.table.vabater4', visible: !this._isTabelaNova},
        {dataField: 'valorAbater3DEP', dataType: 'double', caption: 'tirs.table.vabater3', visible: !this._isTabelaNova},
        {dataField: 'valorAbater2DEP', dataType: 'double', caption: 'tirs.table.vabater2', visible: !this._isTabelaNova},
        {dataField: 'valorAbater1DEP', dataType: 'double', caption: 'tirs.table.vabater1', visible: !this._isTabelaNova},
        {dataField: 'valorAbater0DEP', dataType: 'double', caption: 'tirs.table.vabater0', visible: !this._isTabelaNova},
        {dataField: 'taxaMarginalMaxima', dataType: 'double', caption: 'tirs.table.taxaMarginalMaxima', visible: this._isTabelaNova},
        {dataField: 'parcelaAbaterFormulaInfo', dataType: 'string', caption: 'tirs.table.parcelaAbaterFormulaInfo', visible: this._isTabelaNova},
        {dataField: 'parcelaAbaterDepende', dataType: 'number', caption: 'tirs.table.parcelaAbaterDepende', visible: this._isTabelaNova},
        {dataField: 'txEfetivaMensalEscal', dataType: 'string', caption: 'tirs.table.txEfetivaMensalEscal', visible: this._isTabelaNova},
        {dataField: 'nTabIRS', dataType: 'string', visible: false},
        {dataField: 'tabIRSDT', dataType: 'date', visible: false}
      ],
      paging: {enabled: false},
      pager: {visible: false},
      searchPanel: {visible: false},
      filterRow: {visible: false},
      export: {enabled: false},
      columnChooser: {enabled: false},
      allowColumnReordering: false,
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      columnFixing: {enabled: false},
      hoverStateEnabled: true,
      columnHidingEnabled: false,
      editing: {
        mode: 'cell',
        allowAdding: false,
        allowUpdating: false,
        allowDeleting: false,
        newRowPosition: 'last'
      }
    };
  }

  public async onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    await super.onUpdate(stateType);
    if (this._dataGridInstanceEscaloesIRS) {
      this._checkStateEditing();
    }
  }

  public async save(config?: IApiRequestConfigWithBody<IJsonTirs>): Promise<IJsonTirs> {
    if (this.model.tabelaOficial) {
      this._plAlertService.error('tirs.messages.naoPodeAtualizarTabelasSistema');
      return Promise.reject();
    }

    await this._dataGridInstanceEscaloesIRS.saveEditData();
    this.model.irsDT = <Array<IJsonTIRSDT>>await this._dataGridInstanceEscaloesIRS.getDataSource().store().load();
    await this._updateDataGridValues();
    return super.save({
      body: this.model,
      ...config
    });
  }

  public delete(config?: IApiRequestConfig): Promise<void> {
    if (this.model.tabelaOficial) {
      this._plAlertService.error('tirs.messages.naoPodeRemoverTabelasSistema');
      return Promise.reject();
    }

    return super.delete(config);
  }

  public onDataGridInitializedEscaloesIRS(event: IDevExpressDataGridEventOnInitialized<IJsonTIRSDT, IJsonTIRSDT>): void {
    this._dataGridInstanceEscaloesIRS = event.component;
    this._checkStateEditing();
  }

  public onDataGridNewRow(event: IDevExpressDataGridEventOnInitNewRow<IJsonTIRSDT, IJsonTIRSDT>): void {
    event.data.nTabIRS = this.model.nTabIrs;
    event.data.tabIRSDT = this.model.dataVigor;
    event.data.valorAbater0DEP = 0;
    event.data.valorILIQ = 0;
    event.data.percDesc0DEP = 0;
    event.data.percDesc1DEP = 0;
    event.data.percDesc2DEP = 0;
    event.data.percDesc3DEP = 0;
    event.data.percDesc4DEP = 0;
    event.data.percDesc5DEP = 0;
    event.data.valorAbater0DEP = 0;
    event.data.valorAbater1DEP = 0;
    event.data.valorAbater2DEP = 0;
    event.data.valorAbater3DEP = 0;
    event.data.valorAbater4DEP = 0;
    event.data.valorAbater5DEP = 0;
  }

  public nTabIrsChanged(value: string): void {
    this.model.nTabIrs = value;
    this._checkTableCodeStartsWith(false);
    this._evaluateDatagridColumns();
  }

  public async dateDropDownChanged(date: IDataEscalao): Promise<void> {
    this.model.dataVigor = date.value;
    this._dataGridInstanceEscaloesIRS.beginCustomLoading(undefined);
    try {
      const response: HttpResponse<Array<IJsonTIRSDT>> = await this._serviceTirs.tableIRSEscaloesByDate(this.model.nTabIrs, this.model.dataVigor);
      this.tableIRSDT = response.body;
    } finally {
      this._dataGridInstanceEscaloesIRS.endCustomLoading();
    }
  }

  private _checkStateEditing(): void {
    this._dataGridInstanceEscaloesIRS.option('editing.allowAdding', this.type !== this.stateTypes.DETAIL && !this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.option('editing.allowDeleting', this.type !== this.stateTypes.DETAIL && !this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.option('editing.allowUpdating', this.type !== this.stateTypes.DETAIL && !this._isTabelaNova);
  }

  private _checkTableCodeStartsWith(isFirstTime: boolean): void {
    const isTabelaNovaCopy: boolean = this._isTabelaNova;
    this._isTabelaNova = this.model.nTabIrs?.toUpperCase().startsWith('A') || this.model.nTabIrs?.toUpperCase().startsWith('C') || this.model.nTabIrs?.toUpperCase().startsWith('M');
    if (isTabelaNovaCopy !== this._isTabelaNova && !isFirstTime) {
      if (!this._isDuplicate) {
        this.tableIRSDT = [];
      }
    }
  }

  private _evaluateDatagridColumns(): void {
    this._dataGridInstanceEscaloesIRS.columnOption('percDesc0DEP', 'visible', !this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('percDesc1DEP', 'visible', !this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('percDesc2DEP', 'visible', !this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('percDesc3DEP', 'visible', !this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('percDesc4DEP', 'visible', !this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('percDesc5DEP', 'visible', !this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('valorAbater5DEP', 'visible', !this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('valorAbater4DEP', 'visible', !this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('valorAbater3DEP', 'visible', !this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('valorAbater2DEP', 'visible', !this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('valorAbater1DEP', 'visible', !this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('valorAbater0DEP', 'visible', !this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('taxaMarginalMaxima', 'visible', this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('parcelaAbaterFormulaInfo', 'visible', this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('parcelaAbaterDepende', 'visible', this._isTabelaNova);
    this._dataGridInstanceEscaloesIRS.columnOption('txEfetivaMensalEscal', 'visible', this._isTabelaNova);
    this._checkStateEditing();
  }

  private _updateDataGridValues(): Promise<void> {
    this.model.irsDT.forEach((item: IJsonTIRSDT) => {
      item.nTabIRS = this.model.nTabIrs;
    });
    return Promise.resolve();
  }

  private async _buildDataEscaloesAndSelectCurrent(): Promise<void> {
    for (const item of this.datasEscaloes) {
      item.label = moment(item.value).format(this.format.momentDate);
    }
    const lastDataEscalao: IDataEscalao = this.datasEscaloes[this.datasEscaloes.length - 1];
    this.currentDataTaxaIrs = lastDataEscalao;
    await this.dateDropDownChanged(lastDataEscalao);
  }
}
