<pl-form class="trdocs-linhas-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'trDocsCabsWizard.captions.transformacaoDocumentosErros'"></h4>
  </div>

  <div class="modal-body">
    <dx-data-grid [cgDxDataGrid]="dataGridResumoErrosDefinition" [dataSource]="dataGridResumoErrosDefinition.dataSource"></dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-sm btn-light action-dismiss" (click)="close()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</pl-form>
