import {TDate} from '../../../../common/dates';
import {IJsonDocContabilidade} from '../docscontabilidade/jsonDocContabilidade.interface';

export enum EATFaturasRecibosVerdesSituacao {
  Default,
  Emitido,
  Anulado,
  SemPreenchimento
}

export enum EATFaturasRecibosVerdesTipo {
  Default,
  FaturaRecibo,
  FaturaReciboAtoIsolado,
  Fatura,
  FaturaAtoIsolado,
  Recibo,
  ReciboAtoIsolado
}

export enum EATFaturasRecibosVerdesJaLancado {
  None,
  Ignorado,
  IgnoradoPorNif,
  LigacaoDiretaNIFNDocExterno,
  AssociadoSemNDocExterno
}

export interface IJsonFaturasRecibosVerdesTaxasIva {
  taxa: number;
  taxaString: string;
  valorBase: number;
  valorBaseString: string;
  valorIva: number;
  valorIvaString: string;
  valorRemanescente: number;
  valorRemanescenteString: string;
  motivoIsencao: string;
}

export interface IJsonFaturasRecibosVerdesSimpleItem {
  numDocumento: number;
  situacao: string;
  tipoDocumento: string;
  dataEmissao: TDate;
  atividadePrestador: string;
  nifAdquirente: string;
  nomeAdquirente: string;
  dataTransmissao: TDate;
  observacoes: string;
  importanciaRecebida: number;
  valorBase: number;
  regimeIVA: string;
  regimeIVADescr: string;
  valorIVA: number;
  incidenciaIRS: string;
  retencaoIRS: string;
  valorIRS: number;
  valorImpostoSelo: number;
  cobradoATituloDescr: string;
  valorIRSDescr: string;
  taxaIVA: number;
  nifOutro: string;
  numeroUnico: string;
  taxasIva: Array<IJsonFaturasRecibosVerdesTaxasIva>;
}

export interface IJsonFaturasRecibosVerdesItem extends IJsonFaturasRecibosVerdesSimpleItem {
  tipoItemJaLancado: EATFaturasRecibosVerdesJaLancado;
  isDeAnoEncerrado: boolean;
  documentoLancado: string;
  extPocCabID: string;
}

export interface IJsonFRVErro {
  errorType: number;
  errorMessage: string;
}

export interface IJsonFRVImportDoc extends IJsonFaturasRecibosVerdesItem {
  preDefinidoId: number;
  preDefinidoStr: string;
  erro: string;
  contaCorrente: string;
  podeGenNovaCC: boolean;
  listaErros: Array<IJsonFRVErro>;
  //    FregistadoPorStr: string;
}

export interface IJsonFRVImportDocApiList {
  list: Array<IJsonFRVImportDoc>;
  listLancadosIgnorados: Array<IJsonFRVImportDoc>;
}

export interface IJsonFRVConfig {
  preDefinidoId: number;
  preDefinidoStr: string;
}

export interface IJsonFRVDocContabilidade {
  doc: IJsonDocContabilidade;
  errors: Array<IJsonFRVErro>;
}

export interface IJsonFRVDoc extends IJsonFaturasRecibosVerdesSimpleItem {
  preDefinidoId: number;
  preDefinidoStr: string;
  erro: string;
  contaCorrente: string;
  listaErros: Array<IJsonFRVErro>;
}

export interface IJsonFRVNIFConfig {
  nif: string;
  nome: string;
  usaSempreRecolha: boolean;
  preDefinido: number;
  contaCorrente: string;
  preDefinidoStr: string;
  contaCorrenteStr: string;
}

export interface IJsonFRVNIFConfigSave {
  nifConfig: IJsonFRVNIFConfig;
  docsList: Array<IJsonFRVImportDoc>;
}
