<div class="contabilidade-balancetes-analitica entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <pl-group>
          <label [translate]="'global.text.listing'"></label>
          <edit>
            <pl-edit [type]="'reports_balancetes_analitica'" attrName="listagem" [(model)]="report"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'balancetesanalitica.periodoDe'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="periodoDe"
                [model]="filters"
                (selectedKeyChange)="changedDePeriodo($event)"
                [filter]="filterPeriodos"
                [fieldsMap]="{nome: 'periodoDeNome', periodo: 'periodoDe'}"
                [output]="outputPeriodo"
                outputDescription="nome"
                [helperMode]="true"
                plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="periodoAte"
                [model]="filters"
                (selectedKeyChange)="changedAtePeriodo($event)"
                [filter]="filterPeriodos"
                [fieldsMap]="{nome: 'periodoAteNome', periodo: 'periodoAte'}"
                [output]="outputPeriodo"
                outputDescription="nome"
                [helperMode]="true">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'balancetesanalitica.nContaCGeralDe'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                attrName="nContaCGeralDe"
                [selectedKey]="filters.nContaCGeralDe"
                [filter]="filterPocs"
                [helperMode]="true"
                (selectedKeyChange)="filters.nContaCGeralDe = $event"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                attrName="nContaCGeralAte"
                [selectedKey]="filters.nContaCGeralAte"
                [filter]="filterPocs"
                [helperMode]="true"
                (selectedKeyChange)="filters.nContaCGeralAte = $event"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleCCusto">
            <label [translate]="'balancetesanalitica.cCustoDe'"></label>
            <edit>
              <entity-autocomplete entity="ccustos" attrName="deCCusto" [(selectedKey)]="filters.cCustoDe" [helperMode]="true" output="key" [filter]="'encerrado=0'"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleCCusto">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="ccustos"
                attrName="ateCCusto"
                [(selectedKey)]="filters.cCustoAte"
                [helperMode]="true"
                output="key"
                [filter]="'encerrado=0'"
                [properties]="{events: {keydown: fnKeydownProcessar}}"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group *ngIf="visibleZona || visibleDepartamento">
          <pl-group *ngIf="visibleZona">
            <label [translate]="'balancetesanalitica.zonaDe'"></label>
            <edit>
              <entity-autocomplete entity="zonas" attrName="zonaDe" [(selectedKey)]="filters.zonaDe" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleZona">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="zonas" attrName="zonaAte" [(selectedKey)]="filters.zonaAte" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleDepartamento">
            <label [translate]="'balancetesanalitica.departamentoDe'"></label>
            <edit>
              <entity-autocomplete entity="depto" attrName="departamentoDe" [(selectedKey)]="filters.departamentoDe" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleDepartamento">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="depto" attrName="departamentoAte" [(selectedKey)]="filters.departamentoAte" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group *ngIf="visibleSubDepart || visibleFamilia">
          <pl-group *ngIf="visibleSubDepart">
            <label [translate]="'balancetesanalitica.subDepartDe'"></label>
            <edit>
              <entity-autocomplete entity="subde" attrName="subDepartDe" [(selectedKey)]="filters.subDepartDe" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleSubDepart">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="subde" attrName="subDepartAte" [(selectedKey)]="filters.subDepartAte" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleFamilia">
            <label [translate]="'balancetesanalitica.familiaDe'"></label>
            <edit>
              <entity-autocomplete entity="familias" attrName="familiaDe" [(selectedKey)]="filters.familiaDe" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleFamilia">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="familias" attrName="familiaAte" [(selectedKey)]="filters.familiaAte" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group *ngIf="visibleGrFamilia || visibleSubFamilia">
          <pl-group *ngIf="visibleGrFamilia">
            <label [translate]="'balancetesanalitica.grFamiliaDe'"></label>
            <edit>
              <entity-autocomplete entity="grandesfamilias" attrName="grFamiliaDe" [(selectedKey)]="filters.grFamiliaDe" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleGrFamilia">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="grandesfamilias" attrName="grFamiliaAte" [(selectedKey)]="filters.grFamiliaAte" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleSubFamilia">
            <label [translate]="'balancetesanalitica.subFamiliaDe'"></label>
            <edit>
              <entity-autocomplete entity="subfamilias" attrName="subFamiliaDe" [(selectedKey)]="filters.subFamiliaDe" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleSubFamilia">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="subfamilias" attrName="subFamiliaAte" [(selectedKey)]="filters.subFamiliaAte" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group *ngIf="visibleCodContab || visibleTipoArtigo">
          <pl-group *ngIf="visibleCodContab">
            <label [translate]="'balancetesanalitica.codContabDe'"></label>
            <edit>
              <entity-autocomplete entity="codigoscontabilisticos" attrName="codContabDe" [(selectedKey)]="filters.codContabDe" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleCodContab">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="codigoscontabilisticos" attrName="codContabAte" [(selectedKey)]="filters.codContabAte" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleTipoArtigo">
            <label [translate]="'balancetesanalitica.tipoArtigoDe'"></label>
            <edit>
              <entity-autocomplete entity="tiposartigo" attrName="tipoArtigoDe" [(selectedKey)]="filters.tipoArtigoDe" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleTipoArtigo">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="tiposartigo" attrName="tipoArtigoAte" [(selectedKey)]="filters.tipoArtigoAte" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group *ngIf="visibleClasse || visibleCategoria">
          <pl-group *ngIf="visibleClasse">
            <label [translate]="'balancetesanalitica.classeDe'"></label>
            <edit>
              <entity-autocomplete entity="artigoclasses" attrName="classeDe" [(selectedKey)]="filters.classeDe" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleClasse">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="artigoclasses" attrName="classeAte" [(selectedKey)]="filters.classeAte" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleCategoria">
            <label [translate]="'balancetesanalitica.categoriaDe'"></label>
            <edit>
              <entity-autocomplete entity="categoriaartigo" attrName="categoriaDe" [(selectedKey)]="filters.categoriaDe" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleCategoria">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="categoriaartigo" attrName="categoriaAte" [(selectedKey)]="filters.categoriaAte" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group *ngIf="visibleVendedor || visibleProcesso">
          <pl-group *ngIf="visibleVendedor">
            <label [translate]="'balancetesanalitica.vendedorDe'"></label>
            <edit>
              <entity-autocomplete entity="vendedores" attrName="vendedorDe" [(selectedKey)]="filters.vendedorDe" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleVendedor">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="vendedores" attrName="vendedorAte" [(selectedKey)]="filters.vendedorAte" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleProcesso">
            <label [translate]="'balancetesanalitica.processoDe'"></label>
            <edit>
              <entity-autocomplete entity="processos" attrName="processoDe" [(selectedKey)]="filters.processoDe" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group *ngIf="visibleProcesso">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="processos" attrName="processoAte" [(selectedKey)]="filters.processoAte" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <label [translate]="'components.contabilidade.balancetes.accountTypes'"></label>
          <edit>
            <pl-edit type="checkbox" class="me-3" attrName="tipoContaRazao" [(model)]="filters.contasRazao" [properties]="{label: 'tipoConta.data.reason'}"></pl-edit>
            <pl-edit type="checkbox" class="me-3" attrName="tipoContaClasse" [(model)]="filters.contasClasse" [properties]="{label: 'tipoConta.data.class'}"></pl-edit>
            <pl-edit type="checkbox" class="me-3" attrName="tipoContaSubTotal" [(model)]="filters.contasSubTotal" [properties]="{label: 'tipoConta.data.subtotal'}"></pl-edit>
            <pl-edit type="checkbox" class="me-3" attrName="tipoContaMovimento" [(model)]="filters.contasMovimento" [properties]="{label: 'tipoConta.data.movement'}"></pl-edit>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
