import {ContabilidadeDocsNaoSaldadosComponent} from './components/contabilidade.docsNaoSaldados.module.component';
import {ENTITY_NAME_PERIODOS} from '../../../../entities/periodos/periodos.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonPeriodo} from '../../../../entities/periodos/jsonPeriodo.entity.interface';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ROLE} from '../../../../services/role.const';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';

export const MODULE_PCA_MANUTENCAO_DOCS_NAO_SALDADOS: IModuleDefinition = {
  name: 'documentossnaosaldados',
  state: {
    url: '/documentossnaosaldados',
    component: ContabilidadeDocsNaoSaldadosComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'docsnaosaldados.title'
    },
    params: {
      model: {
        type: 'json',
        value: null
      },
      documentos: {
        type: 'json',
        value: null
      }
    },
    resolve: [
      {
        provide: 'periodos',
        deps: [EntityServiceBuilder],
        useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonPeriodo>> => {
          const servicePeriodos: IEntityService<IJsonPeriodo> = entityServiceBuilder.build<IJsonPeriodo>(ENTITY_NAME_PERIODOS);
          return servicePeriodos.query().then((response: THttpQueryResponse<IJsonPeriodo>) => response.body.list);
        }
      }
    ]
  }
};
