import {CGModalComponent} from '../../../../cg/modal/cgmodal.component';
import {Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {ReportsRegistryService} from '../../../../reports/reports.registry.service';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {isEmpty, isObject, KEYCODES} from 'pl-comps-angular';
import {EncomendasService} from '../../../../../modules/encomendas/service/encomendas.service';
import {EEstadoEncomendas, IClifosSourceItem, IHeaderGestaoEncomendas, RADIO_GROUP_ESTADO_ENCOMENDAS_TEMPLATE} from '../../../../../modules/encomendas/encomendas.module.interface';
import {IJsonDocfa} from '../../../../../entities/docfas/jsonDocFa.entity.interface';
import {EGrupoDoc} from '../../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../../../entities/docfas/docFas.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {HttpResponse} from '@angular/common/http';
import {IApiQueryResponse} from '../../../../../services/api/api.service.interface';
import {IJsonArmazem} from '../../../../../entities/armazens/jsonArmazem.entity.interface';
import {ENTITY_NAME_ARMAZENS} from '../../../../../entities/armazens/armazens.entity.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IRadioGroup} from '../../../../../../common/interfaces/interfaces';
import {elementAddEventListener, focusElement} from '../../../../../../common/utils/element.utils';
import {TClifos} from '../../../../../entities/clifos/clifos.entity.interface';

@Component({
  selector: 'encomendas-impressao-modal',
  templateUrl: './encomendas.impressao.modal.component.html'
})
export class EncomendasImpressaoModalComponent extends CGModalComponent<void> implements OnInit, OnDestroy {
  @Input() public grupo: EGrupoDoc;
  @Input() public grupoClifos: TClifos;
  @Input() public clifoDescription: string;
  @Input() public header: IHeaderGestaoEncomendas;
  @Input() public clifosSource: Array<IClifosSourceItem>;
  @Input() public showFilter: boolean;

  public readonly radioGroupTemplateEstado: IRadioGroup<EEstadoEncomendas>;
  public readonly docFaTemplate: string;
  public readonly armazemTemplate: string;
  public readonly outputClifos: string;

  public clifoSource: IClifosSourceItem;
  public reportModel: IJsonReport;
  public armazensSource: Array<IJsonArmazem>;
  public docFaListSource: Array<IJsonDocfa>;
  public docFaSource: Array<IJsonDocfa>;
  public armazemSource: Array<IJsonArmazem>;
  public pdfSrc: Blob;
  public modeClifo: boolean;

  private readonly _docFaService: IDocFasEntityService;
  private readonly _armazemService: IEntityService<IJsonArmazem>;

  private _btnActionPreviewElement: HTMLElement;
  private _deRegisterElementEstado: Function;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _encomendasService: EncomendasService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._docFaService = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
    this._armazemService = this._entityServiceBuilder.build(ENTITY_NAME_ARMAZENS);
    this.docFaTemplate = '{{nDocFa}} - {{nome}}';
    this.armazemTemplate = '{{nArmazem}} - {{nome}}';
    this.outputClifos = '{{nConta}} - {{nome}}';
    this.grupoClifos = 'clifos';
    this.docFaListSource = [];
    this.docFaSource = [];
    this.armazemSource = [];
    this.clifosSource = [];
    this.radioGroupTemplateEstado = RADIO_GROUP_ESTADO_ENCOMENDAS_TEMPLATE;
  }

  public ngOnInit(): void {
    this.modeClifo = !isEmpty(this.header.nConta);
    this._getListSources();
    this._loadDefaultReport();

    this.header.nContaDe = !isEmpty(this.header.nConta)
      ? this.header.nConta
      : this.grupo === EGrupoDoc.EncomendasFornecedores
        ? this._configService.configurations.clientesFornecedores.radicalFornecedores
        : this._configService.configurations.clientesFornecedores.radicalClientes;

    this.header.nContaAte = !isEmpty(this.header.nConta)
      ? this.header.nConta
      : this.grupo === EGrupoDoc.EncomendasClientes
        ? `${this._configService.configurations.clientesFornecedores.radicalClientes}9999999`
        : `${this._configService.configurations.clientesFornecedores.radicalFornecedores}9999999`;
  }

  public ngOnDestroy(): void {
    if (this._deRegisterElementEstado) {
      this._deRegisterElementEstado();
    }
  }

  public previsualizar(): void {
    const armazens = this.header.armazens.map((value: IJsonArmazem) => value.nArmazem).join(',');
    const docfaList = this.header.docfaList.map((value: IJsonDocfa) => value.nDocFa).join(',');

    this.pdfSrc = undefined;

    this._encomendasService
      .getPdf({
        ncontade: this.header.nContaDe,
        ncontaate: this.header.nContaAte,
        datadocde: this.header.dataDocDe,
        datadocate: this.header.dataDocAte,
        dataentregaprevde: this.header.dataPrevEntregaDe,
        dataentregaprevate: this.header.dataPrevEntregaAte,
        estadoencomendas: this.header.estadoEncomendas,
        armazens: armazens,
        docfalist: docfaList,
        reportname: this.reportModel.name
      })
      .then((response: HttpResponse<Blob>) => {
        this.pdfSrc = response.body;
      });
  }

  public armazensChanged(armazens: Array<IJsonArmazem | string>): void {
    if (!armazens && armazens.length === 0) {
      this.header.armazens = [];
      return;
    }

    for (const armazem of armazens) {
      if (isObject(armazem)) {
        const armazemItem: IJsonArmazem = this.header.armazens.find((item: IJsonArmazem) => item.nArmazem === (<IJsonArmazem>armazem).nArmazem);
        if (!armazemItem) {
          this.header.armazens.push(<IJsonArmazem>armazem);
        }
      }
    }
  }

  public docfaListChanged(docfaList: Array<IJsonDocfa | string>): void {
    if (!docfaList || docfaList.length === 0) {
      this.header.docfaList = [];
      return;
    }

    for (const docfa of docfaList) {
      if (isObject(docfa)) {
        const docfaItem: IJsonDocfa = this.header.docfaList.find((item: IJsonDocfa) => item.nDocFa === (<IJsonDocfa>docfa).nDocFa);
        if (!docfaItem) {
          this.header.docfaList.push(<IJsonDocfa>docfa);
        }
      }
    }
  }

  public clifoChanged(item: IClifosSourceItem): void {
    this.header.nConta = !item ? '' : item.nConta;
    this.header.nomeConta = !item ? '' : item.nome;
  }

  @ViewChild('elementEstado', {read: ElementRef})
  public set elementEstado(elementRef: ElementRef<HTMLElement>) {
    if (!elementRef?.nativeElement) {
      return;
    }
    const radio: HTMLElement = elementRef.nativeElement.querySelector('pl-edit-radio:last-of-type [tabindex="0"]');
    if (radio) {
      if (this._deRegisterElementEstado) {
        this._deRegisterElementEstado();
      }
      this._deRegisterElementEstado = elementAddEventListener(
        radio,
        'keydown',
        (event: KeyboardEvent) => {
          this._focusBtnPreview(event);
        },
        {passive: true}
      );
    }
  }

  @ViewChild('btnActionPreview', {read: ElementRef})
  public set btnActionPreview(value: ElementRef<HTMLElement>) {
    this._btnActionPreviewElement = value?.nativeElement;
  }

  private _loadDefaultReport(): void {
    this._reportsRegistryService
      .get(EReport.Encomendas)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.reportModel = reports[0];
        }
      });
  }

  private _focusBtnPreview(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      setTimeout(() => {
        focusElement(this._btnActionPreviewElement);
      });
    }
  }

  private async _getListSources(): Promise<void> {
    const search =
      this.grupo === EGrupoDoc.EncomendasClientes
        ? `grupoDocfa=${EGrupoDoc.EncomendasClientes}`
        : this.grupo === EGrupoDoc.EncomendasFornecedores
          ? `grupoDocfa=${EGrupoDoc.EncomendasFornecedores}`
          : `grupoDocfa=${EGrupoDoc.EncomendasClientes}|grupoDocfa=${EGrupoDoc.EncomendasFornecedores}`;

    await this._docFaService.query({pesquisa: search}).then((response: HttpResponse<IApiQueryResponse<IJsonDocfa>>) => {
      this.docFaListSource = response.body.list;
    });

    await this._armazemService.query().then((response: HttpResponse<IApiQueryResponse<IJsonArmazem>>) => {
      this.armazensSource = response.body.list;
    });
  }
}
