import {Component, Injector, Input, OnInit} from '@angular/core';
import {isEmpty, isNumber, isObject, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {IAnexoDItem078} from '../../../../modelo22.module.interface';
import {IDataSourceItem} from '../../../../../../../components/datasource/datasources.interface';
import {IJsonModelo22AnexoD} from '../../../../jsonModelo22.module.interface';
import moment from 'moment';
import {TDate} from '../../../../../../../../common/dates';

@Component({
  selector: 'modal-modelo22-anexod-quadro7-tabelas78',
  templateUrl: './modelo22.anexoD.quadro7.tabelas78.modal.component.html'
})
export class Modelo22AnexoDQuadro7Tabelas78ModalComponent extends CGModalComponent<IJsonModelo22AnexoD> implements OnInit {
  @Input() public newLineFor: string;
  @Input() public anexoD: IJsonModelo22AnexoD;
  public readonly dsTemplate: string;

  public promise: Promise<void>;
  public dsBeneficio: Array<IDataSourceItem<number>>;
  public dsRegiao78: Array<IDataSourceItem<number>>;
  public dsRegiao78A1: Array<IDataSourceItem<string>>;
  public dsTipo: Array<IDataSourceItem<string>>;
  public dsTipologia: Array<IDataSourceItem<string>>;
  public dsIdentificacao: Array<IDataSourceItem<string>>;
  public is78: boolean;
  public is78A1: boolean;
  public is78A2: boolean;
  public is78A3: boolean;

  public codigoBeneficioView: string;
  public regiaoView: string;
  public tipoView: string;
  public tipologiaView: string;
  public identificacaoView: string;

  public codigoBeneficio: number;
  public regiao: number;
  public regiao78A1: string;
  public cae: string;
  public montanteDasAplicacoes: number;
  public irc: number;
  public imiIMTSelo: number;
  public naoFiscais: number;
  public total: number;
  public numeroLinha: number;
  public tipo: string;
  public numProjecto: string;
  public dataInicio: TDate;
  public dataFim: TDate;
  public tipologia: string;
  public identificacao: string;
  public montante: number;
  public montanteActualizado: number;
  public aplicacoesMontante: number;
  public aplicacoesActualizado: number;
  public financeiroUsufruido: number;
  public financeiroActualizado: number;
  public ircUtilizado: number;
  public ircActualizado: number;
  public imiUtilizado: number;
  public imiActualizado: number;
  public imtUtilizado: number;
  public seloUtilizado: number;
  public financeiroMontante: number;
  public ircMontante: number;
  public imiMontante: number;
  public imtMontante: number;
  public seloMontante: number;
  public intensidade: number;
  public montanteAInscrever: number;
  public montanteTotal: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dsTemplate = '{{value}} - {{name}}';
    this.dsBeneficio = [];
    this.dsRegiao78 = [];
    this.dsRegiao78A1 = [];
    this.dsTipo = [];
    this.dsTipologia = [];
    this.dsIdentificacao = [];
    this.codigoBeneficioView = '';
    this.regiaoView = '';
    this.tipoView = '';
    this.tipologiaView = '';
    this.identificacaoView = '';
    this.codigoBeneficio = undefined;
    this.regiao = undefined;
    this.regiao78A1 = '';
    this.cae = '';
    this.montanteDasAplicacoes = 0;
    this.irc = 0;
    this.imiIMTSelo = 0;
    this.naoFiscais = 0;
    this.total = 0;
    this.numeroLinha = 0;
    this.tipo = '';
    this.numProjecto = '';
    this.dataInicio = moment();
    this.dataFim = moment();
    this.tipologia = '';
    this.identificacao = '';
    this.montante = 0;
    this.montanteActualizado = 0;
    this.aplicacoesMontante = 0;
    this.aplicacoesActualizado = 0;
    this.financeiroUsufruido = 0;
    this.financeiroActualizado = 0;
    this.ircUtilizado = 0;
    this.ircActualizado = 0;
    this.imiUtilizado = 0;
    this.imiActualizado = 0;
    this.imtUtilizado = 0;
    this.seloUtilizado = 0;
    this.financeiroMontante = 0;
    this.ircMontante = 0;
    this.imiMontante = 0;
    this.imtMontante = 0;
    this.seloMontante = 0;
    this.intensidade = 0;
    this.montanteAInscrever = 0;
    this.montanteTotal = 0;
  }

  public ngOnInit(): void {
    this.is78 = this.newLineFor === '78';
    this.is78A1 = this.newLineFor === '78A1';
    this.is78A2 = this.newLineFor === '78A2';
    this.is78A3 = this.newLineFor === '78A3';
    this._setBeneficioDS();
    this._setRegiaoDS();
    this._setTipoDS();
    this._setTipologiaDS();
    this._setIdentificacaoDS();
  }

  public evtBeneficioDSChanged(value: {inputValue: string; item: IDataSourceItem<number>}): void {
    if (isObject(value.item)) {
      this.codigoBeneficioView = `${value.item.value} - ${value.item.name}`;
      this.codigoBeneficio = value.item.value;
    }
  }

  public evtRegiao78DSChanged(value: {inputValue: string; item: IDataSourceItem<number>}): void {
    if (isObject(value.item)) {
      this.regiaoView = `${value.item.value} - ${value.item.name}`;
      this.regiao = value.item.value;
    }
  }

  public evtRegiao78A1DSChanged(value: {inputValue: string; item: IDataSourceItem<string>}): void {
    if (isObject(value.item)) {
      this.regiaoView = `${value.item.value} - ${value.item.name}`;
      this.regiao78A1 = value.item.value;
    }
  }

  public evtTipoDSChanged(value: {inputValue: string; item: IDataSourceItem<string>}): void {
    if (isObject(value.item)) {
      this.tipoView = `${value.item.value} - ${value.item.name}`;
      this.tipo = value.item.value;
    }
  }

  public evtTipologiaDSChanged(value: {inputValue: string; item: IDataSourceItem<string>}): void {
    if (isObject(value.item)) {
      this.tipologiaView = `${value.item.value} - ${value.item.name}`;
      this.tipologia = value.item.value;
    }
  }

  public evtIdentificacaoDSChanged(value: {inputValue: string; item: IDataSourceItem<string>}): void {
    if (isObject(value.item)) {
      this.identificacaoView = `${value.item.value} - ${value.item.name}`;
      this.identificacao = value.item.value;
    }
  }

  public close(): void {
    if (this.is78) {
      if (!isNumber(this.regiao) || this.regiao === 0) {
        this._plAlertService.error('modelo22.messages.regiaovazio');
        return;
      }
      if (isEmpty(this.cae)) {
        this._plAlertService.error('modelo22.messages.caevazio');
        return;
      }

      if (this.anexoD.quadro07.campo078List.findIndex((value: IAnexoDItem078) => value.regiao === this.regiao && value.cae === this.cae) !== -1) {
        this._plAlertService.error('modelo22.messages.regeiaocaeAlredyExists');
        return;
      }

      this.anexoD.quadro07.campo078List.push({
        codigoBeneficio: this.codigoBeneficio,
        regiao: this.regiao,
        cae: this.cae,
        montanteDasAplicacoes: this.montanteDasAplicacoes,
        irc: this.irc,
        imiIMTSelo: this.imiIMTSelo,
        naoFiscais: this.naoFiscais,
        total: this.total
      });
      super.close(this.anexoD);
    }
    if (this.is78A1) {
      if (isEmpty(this.tipo)) {
        this._plAlertService.error('modelo22.messages.tipovazio');
        return;
      }
      if (isEmpty(this.numProjecto)) {
        this._plAlertService.error('modelo22.messages.numProjectovazio');
        return;
      }

      this.anexoD.quadro07.campo078A1List.push({
        numeroLinha: this.numeroLinha,
        tipo: this.tipo,
        numProjecto: this.numProjecto,
        dataInicio: this.dataInicio,
        dataFim: this.dataFim,
        tipologia: this.tipologia,
        identificacao: this.identificacao,
        regiao: this.regiao78A1,
        cae: this.cae,
        montante: this.montante,
        montanteActualizado: this.montanteActualizado
      });
      super.close(this.anexoD);
    }
    if (this.is78A2) {
      this.anexoD.quadro07.campo078A2List.push({
        numeroLinha: this.numeroLinha,
        aplicacoesMontante: this.aplicacoesMontante,
        aplicacoesActualizado: this.aplicacoesActualizado,
        financeiroUsufruido: this.financeiroUsufruido,
        financeiroActualizado: this.financeiroActualizado,
        ircUtilizado: this.ircUtilizado,
        ircActualizado: this.ircActualizado,
        imiUtilizado: this.imiUtilizado,
        imiActualizado: this.imiActualizado,
        imtUtilizado: this.imtUtilizado,
        seloUtilizado: this.seloUtilizado,
        montanteTotal: this.montanteTotal
      });
      super.close(this.anexoD);
    }
    if (this.is78A3) {
      this.anexoD.quadro07.campo078A3List.push({
        numeroLinha: this.numeroLinha,
        aplicacoesMontante: this.aplicacoesMontante,
        financeiroMontante: this.financeiroMontante,
        ircMontante: this.ircMontante,
        imiMontante: this.imiMontante,
        imtMontante: this.imtMontante,
        seloMontante: this.seloMontante,
        montanteTotal: this.montanteTotal,
        intensidade: this.intensidade,
        montanteAInscrever: this.montanteAInscrever
      });
      super.close(this.anexoD);
    }
  }

  private _setBeneficioDS(): void {
    if (this.is78) {
      this.dsBeneficio.push(
        {value: 71, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.beneficio.71')},
        {value: 741, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.beneficio.741')},
        {value: 742, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.beneficio.742')},
        {value: 743, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.beneficio.743')},
        {value: 727, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.beneficio.727')}
      );
    }
  }

  private _setRegiaoDS(): void {
    if (this.is78) {
      this.dsRegiao78.push(
        {value: 1, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78.1')},
        {value: 2, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78.2')},
        {value: 3, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78.3')},
        {value: 4, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78.4')},
        {value: 5, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78.5')},
        {value: 6, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78.6')},
        {value: 7, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78.7')},
        {value: 8, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78.8')},
        {value: 10, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78.11')},
        {value: 11, name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78.10')}
      );
    }

    if (this.is78A1) {
      this.dsRegiao78A1.push(
        {value: 'PT111', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT111')},
        {value: 'PT112', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT112')},
        {value: 'PT119', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT119')},
        {value: 'PT11A', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT11A')},
        {value: 'PT11B', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT11B')},
        {value: 'PT11C', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT11C')},
        {value: 'PT11D', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT11D')},
        {value: 'PT11E', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT11E')},
        {value: 'PT16B', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT16B')},
        {value: 'PT16D', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT16D')},
        {value: 'PT16E', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT16E')},
        {value: 'PT16F', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT16F')},
        {value: 'PT16G', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT16G')},
        {value: 'PT16H', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT16H')},
        {value: 'PT16I', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT16I')},
        {value: 'PT16J', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT16J')},
        {value: 'PT1109', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT1109')},
        {value: 'PT1107', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT1107')},
        {value: 'PT1114', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT1114')},
        {value: 'PT111127', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT111127')},
        {value: 'PT172', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT172')},
        {value: 'PT181', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT181')},
        {value: 'PT184', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT184')},
        {value: 'PT185', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT185')},
        {value: 'PT186', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT186')},
        {value: 'PT187', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT187')},
        {value: 'PT150', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT150')},
        {value: 'PT200', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT200')},
        {value: 'PT300', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.regiao78A1.PT300')}
      );
    }
  }

  private _setTipoDS(): void {
    if (this.is78A1) {
      this.dsTipo.push(
        {value: 'D', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.tipo.D')},
        {value: 'U', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.tipo.U')}
      );
    }
  }

  private _setTipologiaDS(): void {
    if (this.is78A1) {
      this.dsTipologia.push(
        {value: '001', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.tipologia.001')},
        {value: '002', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.tipologia.002')},
        {value: '003', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.tipologia.003')},
        {value: '004', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.tipologia.004')}
      );
    }
  }

  private _setIdentificacaoDS(): void {
    if (this.is78A1) {
      this.dsIdentificacao.push(
        {value: '01', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.01')},
        {value: '02', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.02')},
        {value: '03', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.03')},
        {value: '04', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.04')},
        {value: '05', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.05')},
        {value: '06', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.06')},
        {value: '07', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.07')},
        {value: '08', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.08')},
        {value: '09', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.09')},
        {value: '10', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.10')},
        {value: '11', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.11')},
        {value: '12', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.12')},
        {value: '13', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.13')},
        {value: '14', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.14')},
        {value: '15', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.15')},
        {value: '99', name: this._plTranslateService.translate('modelo22.modal.anexod.quadro7.tabelas78.datasources.identificacao.99')}
      );
    }
  }
}
