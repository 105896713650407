import {Component, Injector, OnInit} from '@angular/core';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonTiposRegistoCadastro} from '../../jsonTiposRegistoCadastro.entity';
import {ITree, ITreeItem} from '../../../../components/treeviewer/treeviewer.interface';
import {TIPOS_REGISTO_CADASTRO_TREE, VALORES_DE_MOTIVOQPESSPOAL} from '../../tiposRegistoCadastro.entity.interface';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';

const INDEX_TIPO_CLASSE_A = 0;
const INDEX_TIPO_CLASSE_B = 1;
const INDEX_TIPO_CLASSE_C = 2;
const INDEX_TIPO_CLASSE_D = 3;
const INDEX_TIPO_CLASSE_E = 4;
const INDEX_TIPO_CLASSE_F = 5;

@Component({
  selector: 'tiposregistocadastro-edit',
  templateUrl: './tiposRegistoCadastro.entity.edit.component.html'
})
export class TiposRegistoCadastroEditComponent extends ModuloEntityDetailComponent<IJsonTiposRegistoCadastro> implements OnInit {
  public readonly tiposRegistoCadastroTree: ITree;

  public infbalancosocialSelected: ITreeItem;
  public valuesMQPessoal: Array<string>;
  public selectedName: string;
  public selectedId: string;
  public disabledMQPessoal: boolean;
  public motivoSaida: boolean;

  private _firstTime: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.disabledMQPessoal = true;
    this.motivoSaida = false;
    this.selectedName = '';
    this.selectedId = '';
    this.tiposRegistoCadastroTree = TIPOS_REGISTO_CADASTRO_TREE;
    this.valuesMQPessoal = VALORES_DE_MOTIVOQPESSPOAL;
    this.infbalancosocialSelected = {
      nodeText: '',
      nodeIcon: '',
      nodeId: '',
      collapsed: false,
      selectable: false,
      nodeClass: undefined,
      childNodes: []
    };
    this._firstTime = true;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.model) {
      this.model = {
        codQPMOT: '',
        designaBreve: '',
        designaCompl: '',
        idMotMV: undefined,
        nMotivoMov: '',
        tipoClasseA: undefined,
        tipoClasseB: undefined,
        tipoClasseC: undefined,
        tipoClasseD: undefined,
        tipoClasseE: undefined,
        tipoClasseF: undefined,
        nomeCodQPMOT: ''
      };
    } else {
      this.selectedId = this._modelToValue(this.model);
      this._setSelectableTiposRegistoCadastroTree(this.tiposRegistoCadastroTree.nodes, this.selectedId);
    }
    this._checkItemSelectedToCodMQPessoal(this.selectedId);
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    return super.onUpdate(stateType).then(() => {
      this._setSelectableTiposRegistoCadastroTree(this.tiposRegistoCadastroTree.nodes);
    });
  }

  public tiposRegistoCadastroChanged(item: ITreeItem): void {
    if (this.infbalancosocialSelected !== item && !this._firstTime) {
      this.model.codQPMOT = '';
      this.model.nomeCodQPMOT = '';
    }
    this._firstTime = false;
    this.infbalancosocialSelected = item;
    this.selectedName = item.nodeId ? item.nodeText : '';
    this._checkItemSelectedToCodMQPessoal(<string>item.nodeId);
    if (this.infbalancosocialSelected.nodeId) {
      this._valueToModel(<string>this.infbalancosocialSelected.nodeId);
    }
  }

  private _setSelectableTiposRegistoCadastroTree(treeItems: Array<ITreeItem>, selectedId?: string): void {
    for (const treeItem of treeItems) {
      if (!treeItem.nodeId) {
        treeItem.selectable = false;
      }
      if (selectedId && treeItem.nodeId === selectedId) {
        this._checkItemSelectedToCodMQPessoal(treeItem.nodeId);
        this.infbalancosocialSelected = treeItem;
        this.selectedName = treeItem.nodeId ? treeItem.nodeText : '';
      }
      if (treeItem.childNodes) {
        this._setSelectableTiposRegistoCadastroTree(treeItem.childNodes, selectedId);
      }
    }
  }

  private _modelToValue(model: IJsonTiposRegistoCadastro): string {
    return `${model.tipoClasseA},${model.tipoClasseB},${model.tipoClasseC},${model.tipoClasseD},${model.tipoClasseE},${model.tipoClasseF}`;
  }

  private _valueToModel(value: string): void {
    const model: Array<string> = value.split(',');
    this.model.tipoClasseA = Number(model[INDEX_TIPO_CLASSE_A]);
    this.model.tipoClasseB = Number(model[INDEX_TIPO_CLASSE_B]);
    this.model.tipoClasseC = Number(model[INDEX_TIPO_CLASSE_C]);
    this.model.tipoClasseD = Number(model[INDEX_TIPO_CLASSE_D]);
    this.model.tipoClasseE = Number(model[INDEX_TIPO_CLASSE_E]);
    this.model.tipoClasseF = Number(model[INDEX_TIPO_CLASSE_F]);

    this.motivoSaida = this.model.tipoClasseA === 1 && this.model.tipoClasseB === 2;
  }

  private _checkItemSelectedToCodMQPessoal(id: string): boolean {
    this.disabledMQPessoal = false;
    for (const value of this.valuesMQPessoal) {
      if (value === id) {
        this.disabledMQPessoal = true;
        break;
      }
    }
    return this.disabledMQPessoal;
  }
}
