{
	"abdes": {
		"title_detail": "Allocation/réduction {{id}}",
		"title_new": "Nouvelle allocation/remise",
		"title_plural": "Allocations/réductions",
		"pesquisa": "A chercher",
		"saved": "Allocation/remise {{id}}, enregistrée avec succès.",
		"error": "Allocation/remise {{id}}, ne peut pas être enregistrée.",
		"deleted": "Allocation/réduction {{id}}, supprimée avec succès.",
		"fields": {
			"abonoDesc": "Allocation / Remise",
			"codAbDesc": "Code",
			"designacaoBreve": "Nom",
			"designacaoCompleta": "Description",
			"valorFixo": "Valeur unitaire",
			"valorLimIncidencia": "Limiter l’incidence",
			"tipoArredondamento": "Arrondi",
			"procNatal": "Taux de rétention",
			"taxaRetencao": "Taux de rétention",
			"procFerias": "",
			"mapaSeguro": "Carte sécurisée",
			"mapaSindic": "Carte syndicale",
			"colQuadroP": "",
			"colMapaCX": "",
			"nCodABDESC": "",
			"entiSuporte": "Entité",
			"contaMovDeb": "Allocations et rabais aux employés - Compte de débit",
			"contaMovCred": "Allocations et rabais aux employés - Compte de crédit",
			"calcCustHora": "Contribue au calcul du prix/coût horaire",
			"coefHExtra": "% d'augmentation des heures supplémentaires",
			"horaDiaValor": "",
			"tipoHoraExtr": "",
			"diaHoraExtra": "",
			"grupoAbono": "Groupe",
			"grupoDesconto": "Groupe",
			"naoImpDecIRS114": "N'imprime pas la déclaration",
			"tipoValorUnitRecolha": "Type de collecte",
			"percLimValorHExtra": "% Valeur limite H. rapports supplémentaires.",
			"codCGASit": "Code d'état",
			"contaEncDEB": "Charges employeur/entreprise - Compte débiteur",
			"contaEncCRE": "Charges employeur/entreprise - Compte de crédit",
			"percInciSegSocial": "(%) Incidence",
			"tipoRendimento": "Type de rendement.",
			"codSSCol": "code rem.",
			"codRubrOrcAbon": "",
			"codRubrOrcEnc": "",
			"abonoDescDesativado": "Inactif",
			"tipoDescontoProporcionalABDES": "Type de remise proportionnelle",
			"nHorasDescontoProporcional": "N° d'heures",
			"sPReducaoRemunatoria": "Il y a une réduction de salaire",
			"nHorasDiaProvDesc": "Nombre d'heures par jour pour perte de l'indemnité de repas",
			"perctDescSeguro": "Pourcentage payé pour l'assurance",
			"usaSPReducaoRemuneratoria": "Travailleurs de la fonction publique - Bénéficier d'une réduction de salaire",
			"tem": "Il a",
			"irs": "IRS",
			"segsocial": "Sécurité sociale",
			"perfilSistema": "Profil système"
		},
		"virtualFields": {
			"imprimeDec119": "Imprimer en décembre.",
			"enviaAT": "Envoyer à AT"
		},
		"tabIntegracaoCruzamento": "Intégration / Croisements",
		"tabOutrosDados": "Autres données",
		"naotem": "Il n'a pas",
		"abono": "Allocation",
		"abonos": "Allocations",
		"desconto": "Rabais",
		"descontos": "Réductions",
		"messages": {
			"abdescSystemLocked": "Profil système.",
			"abdescSystemUnLocked": "Profil modifiable.",
			"perfilSistema": "Profil système."
		},
		"unlockPrompt": {
			"title": "Profil système",
			"message": "<p>Voulez-vous déverrouiller tous les champs gérés par le système, selon le profil ''{{perfilSistemaAbdesNome}}'', pour modifier les valeurs ?</p> <p>Lorsque vous déverrouillerez les paramètres, le système cesser de mettre à jour en conséquence ces champs automatiquement, si des changements surviennent dans le profil ''{{perfilSistemaAbdesNome}}'' (exemple : changements de législation).</p> <p>Il est recommandé de toujours les utiliser."
		}
	},
	"descontos": {
		"title_detail": "Remise {{id}}",
		"title_new": "Nouvelle remise",
		"title_plural": "Réductions",
		"pesquisa": "A chercher",
		"saved": "Remise {{id}}, enregistrée avec succès.",
		"error": "Remise {{id}}, ne peut pas être enregistrée.",
		"deleted": "Remise {{id}}, supprimée avec succès.",
		"actions": "Générer une nouvelle remise"
	},
	"abonos": {
		"title_detail": "Allocation {{id}}",
		"title_new": "Nouvelle allocation",
		"title_plural": "Allocations",
		"pesquisa": "A chercher",
		"saved": "Allocation {{id}}, enregistrée avec succès.",
		"error": "L'allocation {{id}} ne peut pas être enregistrée.",
		"deleted": "Allocation {{id}}, supprimée avec succès.",
		"actions": "Générer une nouvelle allocation"
	},
	"about": {
		"title": "À propos de CentralGest Cloud",
		"licenca": {
			"licId": "Numéro de licence",
			"nome": "Nom",
			"descricao": "Autorisé à",
			"nUtilizadores": "Nombre d'utilisateurs",
			"portal": "Portail {{name}}"
		},
		"erp": {
			"versao": "Version ERP",
			"dataVersao": "Date de version",
			"emManutencao": "En maintenance"
		},
		"user": {
			"roles": "Accès utilisateur"
		}
	},
	"account": {
		"messages": {
			"error": {
				"invalidRecaptcha": "Veuillez cocher la case pour confirmer qu'il ne s'agit pas d'une machine.",
				"invalidSecurePassword": "Le mot de passe n'est pas valide, il doit contenir au moins 8 caractères, un chiffre, des lettres minuscules et majuscules.",
				"notAuthorized": "N'a pas accès à la plateforme."
			}
		}
	},
	"login": {
		"title": "Authentifier",
		"header": "Connectez-vous pour commencer à utiliser CentralGest Cloud.",
		"form": {
			"password": "Mot de passe",
			"password.placeholder": "Mot de passe",
			"button": "Authentifier",
			"resetpassword": "J'ai oublié mon mot de passe"
		},
		"messages": {
			"error": {
				"authentication": "L'authentification a échoué !",
				"invalidCredentials": "Entrez votre nom d'utilisateur et votre mot de passe."
			}
		}
	},
	"resetpassword": {
		"success": "Veuillez vérifier votre boîte e-mail",
		"title": "Récupérez votre mot de passe",
		"message": "Veuillez entrer votre e-mail ou votre nom d'utilisateur.<br/>Vous recevrez bientôt un message dans votre e-mail avec des informations pour modifier votre mot de passe.",
		"email.placeholder": "E-mail ou nom d'utilisateur",
		"action": {
			"back": "Pour revenir",
			"resetpassword": "Récupérez votre mot de passe"
		}
	},
	"changepassword": {
		"title": "Changez votre mot de passe",
		"message": "Veuillez saisir votre nouveau mot de passe.",
		"success": "Votre mot de passe a été modifié.",
		"action": {
			"back": "Pour revenir",
			"changepassword": "Changer le mot de passe"
		},
		"erro": {
			"minlength": "Le mot de passe doit comporter 5 caractères ou plus !",
			"repetirinvalido": "Le mot de passe ne correspond pas"
		},
		"placeholder": {
			"password": "Mot de passe",
			"confirmPassword": "Répéter le mot de passe"
		}
	},
	"changefirm": {
		"fields": {
			"nEmpresa": "numéro d'entreprise",
			"nomeEmpresa": "Nom de l'entreprise"
		}
	},
	"acessos": {
		"erpcloud": {
			"addons": {
				"bankingAutomation": {
					"acesso": "Accès à l'automatisation bancaire"
				}
			},
			"ativos": {
				"fichas": {
					"visualizarFichas": "Consulter les fiches d'actifs",
					"criarFichas": "Créer des fiches d'actifs",
					"editarFichas": "Modifier les fiches d'actifs",
					"apagarFichas": "Supprimer les fiches d'actifs",
					"operacoesAbatesVendas": "Opérations d'abattage et de vente",
					"calcularAnularDepreciacoes": "Calculer et amortir l'amortissement"
				}
			},
			"contabilidade": {
				"documentos": {
					"criarDocumentos": "Créer des documents",
					"editarDocumentos": "Modifier des documents",
					"anularDocumentos": "Annuler des documents",
					"visualizarDocumentos": "Afficher les documents"
				}
			},
			"gestaocomercial": {
				"editarContaArtigoFamilia": "Vous pouvez modifier le compte pour la vente et l'achat d'articles et de familles",
				"editarMeioPagamento": "Vous pouvez modifier les modes de paiement",
				"documentos": {
					"comprasefetivas": {
						"title": "Achats réels"
					},
					"encomendasclientes": {
						"title": "Commandes clients"
					},
					"encomendasfornecedores": {
						"title": "Commandes fournisseurs"
					},
					"guiastransporte": {
						"title": "Guides de transport"
					},
					"guiastransportefornecedores": {
						"title": "Fournisseurs de guides de transport"
					},
					"propostasclientes": {
						"title": "Propositions clients"
					},
					"vendasefetivas": {
						"title": "Ventes efficaces"
					},
					"entradasdiversas": {
						"title": "Entrées diverses"
					},
					"saidasdiversas": {
						"title": "Sorties diverses"
					},
					"propostasfornecedores": {
						"title": "Propositions de fournisseurs"
					}
				}
			}
		},
		"manager": {
			"generic": {
				"view": "Voir",
				"add": "Pour créer",
				"edit": "Modifier",
				"delete": "Annuler"
			},
			"addons": {
				"title": "Modules complémentaires"
			},
			"ativos": {
				"title": "Actifs"
			},
			"contabilidade": {
				"title": "Comptabilité"
			},
			"gestaoComercial": {
				"title": "Commercial"
			}
		}
	},
	"acrescimosencargosferias": {
		"porEmpresa": "Par entreprise",
		"multiEmpresa": "Multi-entreprise",
		"fields": {
			"empregado": "Employé",
			"totaisEncargos": "Frais totaux",
			"codEmpregado": "Employé",
			"nomeEmpregado": "Nom",
			"dataAdmissao": "Date d'admission",
			"nMesesTrabalhados": "Nombre de mois de travail.",
			"ccusto": "C. Coût",
			"nCodRepCC": "Panne",
			"estadoID": "État",
			"vBaseEncargosFerias": "Base de tarification des vacances",
			"valorAcertoManual": "Réglage manuel",
			"valorSubFerias": "Sous.",
			"valorFerias": "Vacances",
			"valorEncargosSS": "Lundi social.",
			"valorEncargoAcTrab": "Colombie-Britannique",
			"descrBaseEncFerias": "Informations",
			"dados": "Données",
			"sel": "Sel.",
			"nEmpresa": "Entreprise",
			"nomeEmpresa": "Nom de l'entreprise",
			"tipoID": "Taper",
			"descricaoErro": "Informations",
			"dataIntegracao": "Intégration des dates"
		},
		"cab": {
			"totalEncAnuais": "Charges annuelles totales par salarié",
			"tipo": "Taper",
			"acTrabalho": "%Ac.",
			"estado": "État",
			"dataInt": "Intégration des dates",
			"tiposProce": {
				"tipoproce0": "",
				"tipoproce1": "Annuel",
				"tipoproce2": "Mensuel"
			}
		},
		"modals": {
			"config": {
				"title": "Configuration des cumuls de frais de séjour",
				"contasDebito": "Comptes de débit",
				"contasCredito": "Comptes de crédit",
				"percActTrabalho": "% Sec. accidents du travail",
				"diario": "Tous les jours",
				"descritivo": "Descriptif",
				"pocSubFerias": "Sous.",
				"pocFerias": "Vacances",
				"pocEncargosSegSocial": "Frais sec.",
				"pocEncargosActTrabalho": "Charges acides.",
				"pocOutrosEncargos": "Autres frais",
				"tipo": "Taper",
				"percSeguradora": "% Sec. accidents du travail",
				"dadosProce": "Données à traiter",
				"intContab": "Intégration comptable"
			},
			"resumoAnual": {
				"title": "Charges annuelles",
				"totaisAnual": "Totaux annuels",
				"encargos": "Frais",
				"acertoManual": "Réglage manuel",
				"subferias": "Sous.",
				"ferias": "Vacances",
				"encSegSocial": "Frais sec.",
				"encAcTrab": "Charges AC.",
				"segSocial": "Lundi social.",
				"acTrab": "Colombie-Britannique",
				"outrosEnc": "Autres frais",
				"custosEmp": "Coûts de l'entreprise",
				"totaisAno": "Totaux annuels"
			},
			"empresas": {
				"title": "Tarifs majorés pour les vacances multi-entreprises",
				"nEmpresa": "Entreprise",
				"nomeEmpresa": "Nom de l'entreprise",
				"tipo": "Taper",
				"percSeguradora": "%Ac."
			},
			"multiempresadetail": {
				"title": "Frais de vacances supplémentaires"
			},
			"print": {
				"title": "Impression des régularisations pour les frais de vacances",
				"deCodEmp": "code employé",
				"deCCusto": "centre de coûts",
				"deReparticao": "Répartition de"
			}
		},
		"tipoAcrescimoEncFeriasSource": {
			"anual": "Annuel",
			"mensal": "Mensuel"
		},
		"estadoRegEmp": {
			"novo": "Nouveau",
			"ok": "D'ACCORD",
			"alterado": "Données modifiées",
			"apagado": "A quitté l'entreprise"
		},
		"estadoAcrescimoEncFerias": {
			"processado": "Traité",
			"integrado": "Intégré",
			"comInconsistencias": "Avec des incohérences",
			"proceInteg": "Traité et intégré",
			"proceNotInteg": "Traité et non intégré"
		},
		"integContabMultiErrors": {
			"title": "Rapport d'intégration comptable",
			"naoInteg": "Non intégré",
			"integ": "Intégré"
		},
		"btns": {
			"geraEncargos": "Générer des frais",
			"removeEncargos": "Supprimer les frais",
			"resumoAnual": "Sommaire annuel",
			"editCab": "Modifier l'en-tête",
			"empregado": "Employé",
			"assistente": "Assistant",
			"lancarDocumento": "Publier un document",
			"apagarDocumento": "Supprimer le document",
			"lancarDocumentoMulti": "Publier un document",
			"apagarDocumentoMulti": "Supprimer le document",
			"verificarInconsistenciasMulti": "Vérifier les incohérences",
			"apagaEncargosMulti": "Retirer",
			"details": "Détails",
			"integraContab": "Intégration comptable",
			"consultarLanc": "Consulter les versions"
		},
		"messages": {
			"naotemencargos": "Il n'y a pas de frais",
			"naoSelEmp": "Vous devez sélectionner au moins une entreprise.",
			"docsIntegSuccess": "Documents intégrés avec succès",
			"docsDeletedSuccess": "Documents intégrés supprimés avec succès"
		}
	},
	"adiantamentos": {
		"cliente": "Client",
		"fornecedor": "Fournisseur",
		"tabs": {
			"porRegularizar": "A régulariser",
			"regularizado": "Régularisé",
			"fields": {
				"documentoStr": "Document",
				"origemDocStr": "Origine",
				"nClifo": "Compte",
				"nomeClifo": "Nom du compte",
				"liquido": "Liquide",
				"valorIva": "T.V.A.",
				"porRegularizar": "A régulariser",
				"estadoStr": "État",
				"valorRegularizar": "A régulariser",
				"dateDocTo": "Date du document.",
				"dataRef": "Date de référence",
				"intervalo": "Intervalle",
				"datarefradio": "Date de référence",
				"modofunc": "Mode de fonctionnement",
				"dataUltRegular": "Dernière date",
				"valorRegularizadoAData": "Valeur ajustée à ce jour réf.",
				"estadoNaDataRefStr": "Etat à date réf."
			},
			"pesquisaDatas": "Recherche par dates",
			"pesquisaIntervalo": "Rechercher des acomptes entre dates",
			"pesquisaNaData": "Statut de recherche des avances à ce jour"
		},
		"modals": {
			"familia": "Famille",
			"nArtigoAdiantamento": "Article avancé",
			"nArtigoRegAdiantamento": "Article sur le règlement anticipé",
			"autoConfig": {
				"title": "Gestion des avances - Paramétrage automatique",
				"steps": {
					"intro": {
						"title": "Introduction",
						"desc": "Assistant de configuration automatique de la gestion des avances"
					},
					"familiaBase": {
						"title": "Famille de base",
						"desc": "Famille de base pour la création d'acomptes",
						"assistente1": "Cet assistant créera",
						"assistente2": "Famille d'articles",
						"assistente3": "Article sur le règlement anticipé",
						"assistente4": "Article avancé",
						"familiaEmpty": "Vous devez sélectionner une famille."
					},
					"familiaArtigos": {
						"title": "Famille et articles",
						"desc": "Famille et articles qui seront créés"
					}
				}
			},
			"config": {
				"title": "Paramètres d'acompte",
				"desc": "Il est nécessaire de paramétrer les rubriques qui seront utilisées par le module de gestion des avances"
			},
			"registar": {
				"title": "S'inscrire à l'avance",
				"emitAdiant": "Émission anticipée",
				"emitAdiantDoc": "Délivrance de l'acompte relatif au document {{doc}}",
				"taxaIva": "Taux de TVA",
				"valorSIva": "Valeur sans TVA",
				"valorCIva": "Valeur avec TVA",
				"codiva": "Code TVA"
			},
			"print": {
				"title": "Impression des avances"
			}
		},
		"messages": {
			"notConfigured": "Le système n'est pas encore configuré pour utiliser la gestion des acomptes!",
			"criaConfigTooltip": "Les paramètres nécessaires seront créés.",
			"noDocSel": "Vous n'avez sélectionné aucun document à régulariser.",
			"configDocFields": "Pour créer un nouveau document, vous devez configurer les champs.",
			"valueInvalid": "La valeur doit être supérieure à zéro.",
			"clifoNaoSel": "Vous devez insérer un {{clip}} pour régulariser les avances.",
			"clifoNaoSelReg": "Vous devez insérer un {{clip}} pour enregistrer l'avance.",
			"sistemNotConfigured": "Vous devez configurer le système pour utiliser la gestion des acomptes.",
			"docHasAdiant": "Le document contient déjà une ligne d'acompte.",
			"docHasRegAdiant": "Le document contient déjà une ligne de régularisation d'acompte."
		},
		"btns": {
			"autoConfig": "Configurer automatiquement",
			"regularizar": "Régulariser",
			"rastreabilidade": "Traçabilité"
		}
	},
	"amalia": {
		"title": "Amélie",
		"text": {
			"promptPlaceholder": "Demandez-moi n'importe quoi...",
			"openFaq": "Voir sur le site Internet",
			"unsupported": "Pas encore pris en charge : {{message}}"
		},
		"actions": {
			"premade": {
				"balanceteMesAnterior": "Bilan du mois précédent",
				"possoUsarTelemovel": "Puis-je l'utiliser sur mon téléphone portable ?",
				"currentTime": "Quelle heure est-il?",
				"configurarProRata": "Comment configurer le prorata ?",
				"comoAtribuirPaisCliente": "Comment attribuer des parents à un client ?"
			}
		}
	},
	"amortizacao": {
		"add": "Pour ajouter",
		"title_detail": "Code d'amortissement {{id}}",
		"title_new": "Nouveau code d'amortissement",
		"title_plural": "Codes d'amortissement",
		"pesquisa": "A chercher",
		"saved": "Code d'amortissement {{id}}, enregistré avec succès.",
		"error": "Code d'amortissement {{id}}, ne peut pas être enregistré.",
		"deleted": "Code d'amortissement {{id}}, supprimé avec succès",
		"fields": {
			"nImtab": "Code.",
			"nImtabPlaceholder": "Code d'amortissement",
			"nome": "Nom",
			"nomePlaceholder": "Nom de l'amortissement",
			"tipo": "Taper",
			"tabela": "Tableau",
			"divisao": "Division",
			"grupo": "Groupe",
			"alinea": "Indiquer",
			"artigo": "Article",
			"keyValue": "Valeur clé",
			"valor1": "Plafond d'impôt",
			"valor2": "Limite comptable",
			"valor3": "Taux",
			"dataInicio": "Date de début"
		}
	},
	"analisadorTes": {
		"btns": {
			"toolbarShortcuts": "Raccourcis",
			"toolbarConfig": "Paramètres",
			"toolbarPocMenu": "Clients et fournisseurs",
			"syncToolbar": "Synchroniser",
			"collapse": "Effondrement",
			"expand": "Développer",
			"collapseAll": "Tout réduire",
			"expandAll": "Tout développer",
			"pesquisar": "A chercher",
			"editarPrevisaoRecebimento": "Modifier la prévision de réception",
			"alterarDataTesouraria": "Changer la date de trésorerie",
			"removerDocumentoTesouraria": "Supprimer le document de la trésorerie",
			"toolbarPreviTes": "Lancement manuel",
			"toolbarPreviTesAuto": "Lancement automatique",
			"extratoConta": "Relevé de compte",
			"toolbarPrevTesObras": "Importateur de plannings financiers de travaux",
			"criarTesRubricas": "Créer une rubrique",
			"editarRubrica": "Modifier la rubrique",
			"pagamento": "Paiement",
			"recebimento": "Reçu",
			"verDocumentoContabilidade": "Afficher le document en comptabilité",
			"verDocumentoGestComercial": "Afficher le document en gestion commerciale",
			"removerTodosDocumentosDaConta": "Supprimer tous les documents du compte"
		},
		"messages": {
			"syncDados": "Synchronisation des données...",
			"deleteTesLancoMessage": "Êtes-vous sûr de vouloir supprimer le dossier de trésorerie ?",
			"datasAlteradasComSucesso": "Les nouvelles dates de trésorerie ont été enregistrées avec succès.",
			"temACertezaQueQuerEliminarTodosNConta": "Êtes-vous sûr de vouloir supprimer tous les documents de trésorerie du compte « {{nConta}} » ?",
			"aDataNaoPodeSerUmDiaDeFimDeSemana": "La date ne peut pas être un jour de week-end.",
			"aDataNaoPodeSerInferiorADataAtual": "La date ne peut pas être inférieure à la date du jour!"
		},
		"strings": {
			"saldoInicial": "Solde d'ouverture",
			"saldoFinal": "Solde final",
			"configModalTitle": "Paramètres",
			"tabLotesTitle": "Paiements groupés",
			"manual": "Manuel",
			"contabilidade": "Comptabilité",
			"cgBanking": "CG Banque",
			"origemSaldoInicial": "Origine du solde d'ouverture",
			"intervaloContas": "Plage de comptes",
			"contaDe": "compte",
			"intervaloContasBancarias": "Plage de comptes bancaires",
			"importaAutomaticamente": "Importe automatiquement",
			"rubricaParaRecEmLote": "Poste pour réceptions de lots",
			"rubricaParaPagEmLote": "Cap sur les paiements groupés",
			"periodicidadeAnalise": "Fréquence d'analyse",
			"periodicidadeAnaliseMobile": "Périodicité",
			"updateSaldoInicial": "Mettre à jour le solde d'ouverture",
			"valor": "Valeur",
			"dataUltimaAtualizacaoNota": "Notez la date de la dernière mise à jour",
			"diaria": "Tous les jours",
			"semanal": "Hebdomadaire",
			"mensal": "Mensuel",
			"rubrica": "Rubrique",
			"descricao": "Description",
			"documento": "Document",
			"nConta": "Numéro de compte",
			"nomeConta": "Nom du compte",
			"novaData": "Nouvelle date",
			"valoresAnt": "Valeurs avec date de trésorerie précédente",
			"novaDataTesParaTodos": "Nouvelle date de trésorerie pour tout le monde"
		}
	},
	"analiticaconfig": {
		"radicaisList": "Liste des radicaux",
		"radicaisExcluidoList": "Liste des radicaux exclus",
		"novoRadical": "Nouveau radical",
		"novoRadicalPlaceholder": "Saisissez les comptes du grand livre avec analyses, par exemple : 31",
		"novoRadicalExcluido": "Nouveau radical supprimé",
		"novoRadicalExcluidoPlaceholder": "Saisissez les comptes du grand livre sans analyses, par exemple 3112.",
		"messages": {
			"radicalJaExistente": "Le radical inséré existe déjà dans la liste.",
			"radicalExcluidoJaExistente": "La tige supprimée insérée existe déjà dans la liste.",
			"apagarItem": "Supprimer la ligne",
			"desejaApagarItem": "Voulez-vous supprimer la ligne ?",
			"successfullySaved": "Modifications enregistrées avec succès.",
			"campoRadicalVazio": "Le champ radical ne peut pas être vide.",
			"campoRadicalExcluidoVazio": "Le champ radical supprimé ne peut pas être vide."
		}
	},
	"anosFaturacao": {
		"pesquisa": "A chercher"
	},
	"aplicabilidadeIRCT": {
		"data": {
			"filiacao": "Adhésion",
			"portariaExt": "Ordonnance de prolongation",
			"escolha": "Choix",
			"actoGest": "Acte de gestion",
			"sem": "Aucune applicabilité",
			"auto": "Automatique"
		}
	},
	"application": {
		"alt": {
			"logo": "Logo de l'application",
			"companyLogo": "Logo de l'entreprise"
		},
		"versionUpdate": {
			"title": "Nouvelle version disponible",
			"description": "Une nouvelle version de l'application est disponible, merci",
			"reload": "Cliquez ici pour mettre à jour l'application"
		},
		"maintenance": {
			"title": "Système en maintenance",
			"description": "Le système est en cours de maintenance.",
			"endedTitle": "Maintenance du système terminée",
			"endedDescription": "Vous pouvez fermer cet avis et reprendre votre travail.",
			"lastChecked": "Dernière vérification : {{time}}"
		},
		"disconnected": {
			"title": "Communication avec le serveur perdue",
			"description": "La communication avec le serveur rencontre des problèmes.",
			"restoredTitle": "Communication avec le serveur rétablie",
			"restoredDescription": "La communication avec le serveur a été rétablie.",
			"offline": "Il est déconnecté du serveur."
		}
	},
	"apurainventperma": {
		"title": "Calcul mensuel du stock permanent",
		"module": {
			"steps": {
				"inventario": "Inventaire de fin de période",
				"documents": "Documents de dédouanement à générer",
				"preview": "Aperçu du document"
			},
			"datagrids": {
				"infoApuramento": {
					"periodo": "Période",
					"apuramentoEfetuado": "Déstockage effectué",
					"existeDocumento": "Il y a un document",
					"temErros": "Il a des erreurs"
				},
				"contas": {
					"codConta": "Code de compte",
					"designacao": "Désignation",
					"masterDetail": {
						"nConta": "Numéro de compte",
						"nome": "Nom",
						"valor": "Valeur"
					}
				},
				"previsualDocumentos": {
					"extPocCabID": "ID PocCab",
					"periodo": "Période",
					"nContaDebito": "Dette",
					"nContaCredito": "Crédit",
					"valor": "Valeur",
					"descricao": "Description"
				}
			}
		},
		"modal": {
			"configContas": {
				"title": "Configuration des comptes de liquidation",
				"mercadorias": {
					"title": "Marchandises",
					"contaDeCompras": "Transférer les comptes d'achat de marchandises",
					"contaDeMercadorias": "Comptes de transfert d'achat de marchandises",
					"contaDeConsumo": "Compte de consommation de biens",
					"contaDeTransferencia": "Compte de transfert de règlement de marchandises"
				},
				"materiaPrima": {
					"title": "Matières premières",
					"contaComprasTransferenciaParaMateriasPrimas": "Transfert de compte d'achat de matières premières",
					"contaMateriasPrimasParaTransferenciaCompras": "Compte matières premières pour transfert d'achats",
					"contaConsumoMateriasPrimas": "Compte de consommation de matières premières",
					"contaMateriasPrimasParaRegularizacao": "Les matières premières comptent pour la régularisation"
				},
				"materiasSubsidiarias": {
					"title": "Questions subsidiaires",
					"contaComprasTransferenciaParaMateriasSubsidiarias": "Compte d'achat de transfert pour les questions subsidiaires",
					"contaMateriasSubsidiariasParaTransferenciaCompras": "Compte de matières subsidiaires pour le transfert des achats",
					"contaConsumoMateriasSubsidiarias": "Compte de consommation de matières filiale",
					"contaMateriasSubsidiariasParaRegularizacao": "Les matières subsidiaires comptent pour la régularisation"
				},
				"embalagens": {
					"title": "Matériaux d'emballage",
					"contaComprasTransferenciaParaMateriaisEmbalagens": "Transferts de comptes d'achat pour les matériaux d'emballage",
					"contaMateriaisEmbalagensParaTransferenciaCompras": "Les matériaux d'emballage représentent les transferts d'achats",
					"contaConsumoEmbalagensParaConsumo": "Compte de consommation des emballages de consommation",
					"contaMateriaisEmbalagensParaRegularizacao": "Comptabilisation des matériaux d'emballage pour régularisation"
				},
				"materiaisDiversos": {
					"title": "Matériaux divers",
					"contaComprasTransferenciaParaMateriaisDiversos": "Transferts de compte d'achat pour matériels divers",
					"contaMateriaisDiversosParaTransferenciaCompras": "Compte de matières diverses pour les transferts d'achats",
					"contaConsumoMateriaisDiversosParaConsumo": "Compte de consommation de matières diverses",
					"contaMateriaisDiversosParaRegularizacao": "Facture de matières diverses pour régularisation"
				},
				"materiasTransito": {
					"title": "Affaires en transit",
					"contaComprasTransferenciaParaMateriasTransito": "Compte d'achat de transferts pour matériels divers",
					"contaMateriasTransitoParaTransferenciaCompras": "Compte de matières en transit pour les transferts d'achats",
					"contaConsumoMateriasTransitoParaConsumo": "Compte de consommation des matières en transit",
					"contaMateriasTransitoParaRegularizacao": "Comptabilisation des matières en transit pour régularisation"
				},
				"produtosAcabados": {
					"title": "Produits finis",
					"contaVariacaoProducaoParaProdutosAcabados": "Compte d'écart de production pour les produits finis",
					"contaRegularizacaoExistenciaParaProdutosAcabados": "Compte de régularisation d'existence des produits finis"
				},
				"subProdutos": {
					"title": "Sous-produits",
					"contaVariacaoProducaoParaSubprodutos": "Compte d'écarts de production pour les sous-produits",
					"contaRegularizacaoExistenciaParaSubprodutos": "Compte de régularisation d'existence des sous-produits"
				},
				"produtosTrabalhosEmCurso": {
					"title": "Produits et travaux en cours",
					"contaVariacaoProducaoParaProdutosTrabalhosEmCurso": "Tenir compte des variations dans la production des produits et des travaux en cours",
					"contaRegularizacaoExistenciaParaProdutosTrabalhosEmCurso": "Régularisation de compte d'existence de produits et travaux en cours"
				},
				"activosBiologicosCompras": {
					"title": "Acheter des actifs biologiques",
					"contaComprasTransferenciaParaActivosBiologicosConsumo": "Compte d'achats de transferts pour actifs de consommation biologique",
					"contaActivosBiologicosConsumoParaTransferenciaCompras": "Comptabiliser les actifs biologiques de consommation pour les transferts d'achat",
					"contaConsumoActivosBiologicosConsumoParaConsumo": "Compte de consommation d'actifs de consommation biologiques",
					"contaActivosBiologicosConsumoParaRegularizacao": "Comptabilisation des actifs biologiques de consommation pour régularisation"
				},
				"activosBiologicosProducao": {
					"title": "Actifs de production biologique",
					"contaExistenciaActivosBiologicosProducao": "Compte d'existence des actifs de production biologique",
					"contaVariacaoProducaoParaActivosBiologicosProducao": "Comptabilise la variation de production pour la production d'actifs biologiques",
					"contaRegularizacaoExistenciaParaActivosBiologicosProducao": "Régularisation de compte d'existence d'actifs de production biologique"
				}
			}
		},
		"btns": {
			"configContas": "Configuration du compte"
		},
		"actions": {
			"verDocumentoApuramento": "Voir le document de dédouanement pour cette période",
			"apagarDocumentoDeApuramento": "Supprimer le document de dédouanement"
		},
		"menagens": {
			"valoresOriginaisRepostos": "Les valeurs d'origine ont été réinitialisées avec succès",
			"savedSuccess": "La configuration du compte a été enregistrée avec succès",
			"apuramentoJaExiste": "Le dédouanement a déjà été effectué",
			"periodoAlreadyHasApuramento": "Vous avez déjà effectué le calcul mensuel du stock permanent pour la période '{{période}}'.",
			"documentosGravadosSucesso": "Documents enregistrés avec succès",
			"apagarDocumentoApuramentoTitle": "Supprimer le document de dédouanement",
			"apagarDocumentoApuramentoMessage": "Souhaitez-vous vraiment supprimer le document de calcul pour la période {{période}} ?",
			"naoDeveFazerOApuramentoDeInventario": "Le calcul mensuel de l'inventaire permanent en période 12 doit être fait à l'aide de l'application de calcul des résultats."
		}
	},
	"apuraiva": {
		"success": "Apurement de la TVA terminé avec succès",
		"promptAlreadyExistsTitle": "Le calcul de la TVA a déjà été effectué",
		"promptAlreadyExistsMessage": "Le calcul de la TVA pour la période \"{{période}}\" a déjà été effectué.",
		"errorShortValue": "A {{value}} supérieur à la valeur calculée",
		"errorExtraValue": "Il reste {{value}} pour terminer le décompte",
		"saveTitle": "Apurement de la TVA",
		"saveMessage": "Êtes-vous sûr de vouloir terminer le calcul de la TVA ?",
		"docsporintegrar": "Il existe des documents à inclure dans les comptes relatifs à la période de TVA en cours de calcul.",
		"saveSeeDoc": "Enregistrer et afficher le document généré",
		"errorInvalidPeriodoMensal": "La période {{period}} n'est pas valide dans la liste des périodes mensuelles",
		"errorInvalidPeriodoTrimestral": "La période {{période}} n'est pas valide dans la liste des périodes trimestrielles",
		"apurado": "déterminé",
		"steps": {
			"filter": "Filtre d'apurement de la TVA",
			"apuramento": "Calcul de la TVA dans la période",
			"fimperiodo": "Fin de période comptable"
		},
		"fields": {
			"descriptive": "Descriptif",
			"period": "Période",
			"periodCurrent": "Période actuelle",
			"periodNext": "Nouvelle période",
			"diarios": "Agendas à fermer",
			"ivaDedutivel": "Montant de TVA déductible",
			"ivaLiquidado": "Montant de TVA payé",
			"ivaApurado": "Montant calculé",
			"aFavorDaEmpresa": "Destinataire",
			"aFavorDaEmpresa0": "En faveur de l'entreprise",
			"aFavorDaEmpresa1": "En faveur de l'État",
			"ivaRegFavEmpresa": "Paiements mensuels/trimestriels en faveur de l'entreprise",
			"ivaRegFavEstado": "Mensuel/trimestriel en faveur de l'État",
			"ivaRegCalculoProRata": "Annuel sur base du calcul du prorata définitif",
			"ivaVariacoesProRata": "Annuel en raison des variations du prorata définitif",
			"ivaRegComunicadasPeloSIVA": "En faveur de l'entreprise communiquée par SIVA",
			"ivaRegOutrasAnuais": "Autres régularisations annuelles",
			"saldoAnterApuramento": "Autorisation précédente",
			"saldoAnterIVAAPagar": "TVA à payer",
			"saldoAnterIVAARecuperar": "TVA à récupérer",
			"saldoAnterIVAAReembolsar": "TVA à rembourser",
			"ivaAPagar": "TVA à payer",
			"ivaARecuperar": "TVA à récupérer",
			"ivaAReembolsar": "TVA à rembourser"
		},
		"titles": {
			"regularizations": "Régularisations",
			"details": "Informations détaillées sur le dédouanement",
			"information": "Informations sur les soldes précédents",
			"destination": "Destination des valeurs calculées",
			"tabDestination": "Destination du calcul et soldes précédents",
			"tabDetails": "Détails du dédouanement de la TVA",
			"change": "Changement de période comptable"
		}
	},
	"apuramentoresultados": {
		"title_complex": "Calcul des résultats nets - {{année}}",
		"title_complex_apurado": "Calcul des résultats nets - {{année}} (calculé)",
		"pesquisa": "A chercher",
		"success": "Calcul des résultats effectué avec succès",
		"deleted": "Calcul des résultats supprimé avec succès",
		"beforeDeleteModalMessage": "Etes-vous sûr de vouloir supprimer la tabulation des résultats ?",
		"promptAlreadyExistsTitle": "Le calcul des résultats a déjà été effectué",
		"promptAlreadyExistsMessage": "Le calcul des résultats pour l'année \"{{year}}\" a déjà été effectué.",
		"anoapuramento": "Année de calcul : {{year}}",
		"tipoinvpermanente": "Type d'inventaire perpétuel",
		"steps": {
			"variables": "Variables de jeu",
			"documents": "Documents de dédouanement à générer",
			"preview": "Aperçu du document",
			"finished": "Fini"
		},
		"fields": {
			"codConta": "Code de compte",
			"nConta": "Numéro de compte",
			"nomeConta": "Désignation",
			"nome": "Nom",
			"valor": "Valeur",
			"descritivo": "Descriptif",
			"dataDoc": "Date du document",
			"description": "Description"
		},
		"titles": {
			"generatedDocs": "Documents générés"
		},
		"errorInvalidData": "Veuillez remplir les champs pour traiter",
		"errorMissingProcess": "Veuillez traiter les données avant de les enregistrer",
		"toolbar": {
			"analise": "Analyse",
			"infoSaldosInv": "Informations sur les soldes des comptes de stocks",
			"docsSaldosErrados": "Documents incompatibles avec inv.",
			"docsContasInventario": "Documents avec entrées manuelles dans les comptes de stocks",
			"delete": "Supprimer l'autorisation"
		},
		"modal": {
			"saldoscontinv": {
				"title": "Informations sur les soldes des comptes de stocks",
				"periodo": "Période",
				"compras": "Achats",
				"produtos": "Produits",
				"fields": {
					"nomeConta": "",
					"saldoInicial": "Solde d'ouverture",
					"compras": "Achats illiquides",
					"devolucao": "Retour",
					"descontos": "Réductions",
					"totCompras": "Total des achats",
					"comprasTrf": "Shopping trf.",
					"difCompras": "Diff.",
					"saldoFinal": "Solde final",
					"regularizacoesRegistadas": "Dossiers enregistrés",
					"regularizacoesTrfConsumo": "trf reg.",
					"difRegularizacoes": "Diff.",
					"consumo": "Consommation",
					"consCalculado": "Inconvénients.",
					"difConsumo": "Diff.",
					"regularizacoesTrfVariacao": "trf reg.",
					"difRegularizacoesProducao": "Diff.",
					"variacaoProducao": "Variation de production",
					"varProducaoCalc": "Variation de production calculée",
					"difProducao": "Diff."
				}
			},
			"docscontasinventario": {
				"naoExistemDocumentosComMovimenta": "Il n'y a aucun document avec le mouvement des comptes d'inventaire.",
				"naoExistemDocumentosComSaldosDeC": "Il n'y a aucun document avec des soldes de comptes de stock erronés.",
				"title": "Documents avec entrées manuelles dans les comptes de stocks",
				"titleIcons": "Documents incompatibles avec inv.",
				"fields": {
					"inconsistenciasDocApu": "Incohérence",
					"periodo": "Période",
					"nDiario": "Tous les jours",
					"nDocinterno": "Numéro du document"
				}
			}
		}
	},
	"arclis": {
		"title_detail": "Article client/fournisseur",
		"title_new": "Nouvel article client/fournisseur",
		"title_plural": "Articles clients/fournisseurs",
		"pesquisa": "A chercher",
		"saved": "Article client/fournisseur enregistré avec succès.",
		"error": "L'article client/fournisseur ne peut pas être sauvegardé.",
		"deleted": "Article client/fournisseur supprimé avec succès.",
		"deleteModalText": "Supprimer l'enregistrement \"{{nClifoArtigo}}\" ?",
		"fields": {
			"nClifoArtigo": "Code article CLI.",
			"nArtigo": "Code article",
			"nConta": "Client/fournisseur",
			"descricaoFornecedor": "Description de l'article CLI."
		},
		"btn": {
			"newArtigo": "Nouveau"
		},
		"list": {
			"actions": {
				"editar": "Modifier"
			}
		}
	},
	"areaRegionalTaxa": {
		"pesquisa": "Zones fiscales régionales",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"continent": "Continent",
			"madeira": "Bois",
			"acores": "Açores"
		}
	},
	"areasRegionais": {
		"data": {
			"0": "Continent",
			"1": "Bois",
			"2": "Açores",
			"continente": "Continent",
			"madeira": "Bois",
			"acores": "Açores"
		}
	},
	"areasRegionaisControloIVA": {
		"data": {
			"0": "N / A",
			"1": "Continent",
			"2": "Bois",
			"3": "Açores",
			"notApplicable": "N / A",
			"continente": "Continent",
			"madeira": "Bois",
			"acores": "Açores"
		}
	},
	"armazens": {
		"title_detail": "Entrepôt {{id}}",
		"title_new": "Nouvel entrepôt",
		"title_plural": "Entrepôts",
		"pesquisa": "A chercher",
		"saved": "Entrepôt {{id}}, enregistré avec succès.",
		"error": "Stockage {{id}}, ne peut pas être stocké.",
		"deleted": "Entrepôt {{id}}, supprimé avec succès.",
		"fields": {
			"nArmazem": "Code.",
			"nArmazemPlaceholder": "Code entrepôt",
			"nome": "Nom",
			"nomePlaceholder": "Nom de l'entrepôt",
			"morada1": "Ménage",
			"morada1Placeholder": "Adresse de l'entrepôt",
			"morada2": "Ménage",
			"codPostal": "Code Postal",
			"codPostalPlaceholder": "Code postal de l'entrepôt",
			"nomeCPostal": "Emplacement",
			"nomeCPostalPlaceholder": "Emplacement de l'entrepôt",
			"codPais": "Code du pays",
			"codPaisPlaceholder": "Pays de l'entrepôt",
			"nomePais": "Pays",
			"nomePaisPlaceholder": "Pays de l'entrepôt",
			"nTelefone": "Téléphone",
			"nTelefonePlaceholder": "Téléphone d'entrepôt",
			"nFax": "Fax",
			"nFaxPlaceholder": "Télécopieur d'entrepôt"
		}
	},
	"arquivodigital": {
		"errors": {
			"licenseNotActivated": "L'entreprise actuelle n'a pas activé la licence de fichier numérique.",
			"licenseNotActivatedCGOn": "L'entreprise actuelle n'a pas activé la licence d'archives numériques.<br/>Veuillez vous rendre dans la <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\">store</a> CentralGest Cloud et activer ou renouveler la licence de comptabilité numérique."
		},
		"gestaodocsdigitalizados": {
			"actions": {
				"obterDadosEFatura": "Obtenez les données du document sélectionné à partir d'eFatura (nécessite le NIF et la date du document renseignés)",
				"atribuirTipoDocumento": "Attribuer un type de document",
				"unirDocs": "Fusionner les documents sélectionnés",
				"separarDoc": "Pages de documents séparées",
				"copiarDoc": "Copier le document",
				"removePages": "Supprimer des pages du document",
				"apagar": "Supprimer le(s) document(s) sélectionné(s)",
				"removeassociation": "Supprimer l'association avec les documents comptables",
				"verDoc": "Consulter la pièce comptable",
				"novaContaCC": "Créer un nouveau compte courant",
				"exportarArquivo": "Exporter un fichier numérique",
				"adicionardoc": "Ajouter des documents",
				"mudardocpasta": "Modifier le(s) dossier(s) sélectionné(s)",
				"reaplicarocr": "Réappliquer l'OCR au document",
				"predefinidos": "Configuration prédéfinie pour l'entité",
				"docsContabSemContabDig": "Documents comptables sans documents numériques",
				"robo": "Lancer des documents à l'aide de CentralGest AI Robot",
				"extratoConta": "Consulter le relevé de compte",
				"associardocsauto": "Associer automatiquement tous les documents",
				"associardocsincluidoanuladosauto": "Associer automatiquement à tous les documents, y compris les documents annulés",
				"possiveisDocsRepetidos": "Afficher les documents en double possibles"
			},
			"pesqavancada": {
				"title": "Recherche avancée",
				"nifDe": "NIF de",
				"docExternoDe": "Document externe.",
				"dataDocExternoDe": "Date du document.",
				"dataRececaoDe": "Date de réception de",
				"mostrarDocClassificados": "Afficher également les documents déjà classés",
				"mostraApenasLancRobot": "Afficher uniquement les documents déjà classés par le robot"
			},
			"table": {
				"fields": {
					"folder": "Dossier",
					"nif": "FNI",
					"entidade": "Entité",
					"temNIFEmpresa": "Possède un NIF d'entreprise",
					"invoiceTypeStr": "Type de document",
					"nDocumento": "Document externe.",
					"totalBase": "Assiette totale",
					"totalIVA": "TVA totale",
					"pasta": "Dossier",
					"ficheiro": "Déposer",
					"fiscalmenteRelevante": "Fiscalement pertinent",
					"dataRececao": "Date de réception",
					"classficadoPor": "Trié par",
					"ndocContabilidade": "Doc n°",
					"ndocumentoSAFT": "Numéro de document SAFT",
					"temNIFEmpresamin": "Possède un NIF d'entreprise",
					"invoiceTypeStrmin": "Type de document.",
					"fiscalmenteRelevantemin": "Fiscalement pertinent",
					"classficadoPormin": "Classe.",
					"ndocContabilidademin": "Doc n°",
					"ndocumentoSAFTmin": "Doc n°",
					"preDefenidosID": "Prédéfini",
					"actions": {
						"adicionardoc": "Ajouter des documents",
						"mudardocpasta": "Modifier le(s) dossier(s) sélectionné(s)",
						"reaplicarocr": "Réappliquer l'OCR au document",
						"obterDadosEFatura": "Obtenir des données à partir de documents eFatura",
						"atribuirTipoDocumento": "Attribuer un type de document",
						"unirDocs": "Fusionner les documents sélectionnés",
						"separarDoc": "Document séparé",
						"copiarDoc": "Copier le document",
						"removePages": "Supprimer des pages",
						"apagar": "Supprimer le(s) document(s) sélectionné(s)",
						"verDoc": "Consulter la pièce comptable",
						"novaContaCC": "Créer un nouveau compte courant"
					},
					"docSemelhante": {
						"nDocumento": "Numéro du document",
						"nDocExterno": "Doc n°",
						"nContrib": "Numéro de contribuable",
						"descricao": "Description",
						"dataDocExt": "Date du document",
						"docspossiveis": "Documents possibles à associer"
					}
				},
				"havechanges": "Certaines modifications ne sont pas encore enregistrées."
			},
			"legend": {
				"error": "Valeur non obtenue ou erronée",
				"warning": "Valeur obtenue mais à revoir",
				"waitingocr": "Document sans OCR effectué",
				"comdocparaassociar": "Avec pièce comptable à associer",
				"docassociado": "Déjà associé à la pièce comptable"
			},
			"messages": {
				"confirmDelete": "Êtes-vous sûr de vouloir supprimer le document sélectionné ?",
				"confirmDeletePlural": "Êtes-vous sûr de vouloir supprimer les documents sélectionnés ?",
				"exitTitle": "Voulez-vous quitter sans enregistrer les modifications ?",
				"exitMessage": "En cliquant sur OK, vous quitterez sans enregistrer et toutes les modifications seront supprimées.",
				"updateWithChangesTitle": "Voulez-vous actualiser les données sans enregistrer les modifications ?",
				"updateWithChangesMessage": "Cliquer sur OK actualisera les données sans enregistrer et toutes les modifications seront effacées.",
				"naotemalteracoes": "Il n’y a aucune modification à enregistrer.",
				"docvalidadoefatura": "Document trouvé et validé par eFatura.",
				"docQR": "Document téléchargé par QR Code",
				"contabDigitalLancadoRoboSuccess": "Documents sélectionnés publiés avec succès",
				"lancrobotdoc": "Document {{nom de fichier}}.",
				"deleteAttachment": {
					"titleDelete": "Supprimer un document numérique",
					"titleRemoveAssociation": "Supprimer l'association d'une pièce comptable",
					"messageRemoveAssociation": "Souhaitez-vous supprimer l'association de ce document numérique avec la pièce comptable ?<br/>Lorsque vous supprimez l'association, le document redevient disponible dans la gestion des documents numériques comme non classé."
				},
				"modal": {
					"treedocsnaoclassificados": {
						"escolhapastaadd": "Choisissez le dossier dans lequel vous souhaitez ajouter les documents",
						"errorMoveDocSameFolder": "Vous ne pouvez pas déplacer le document vers le même dossier où il se trouve."
					},
					"adicionardocumentos": {
						"escolhadocumentos": "Choisissez le(s) document(s)",
						"success": "Documents ajoutés avec succès !"
					},
					"export": {
						"title": "Exporter le fichier numérique",
						"periodoDe": "période de",
						"diarioDe": "agenda",
						"nifDe": "NIF de",
						"exportaNaoClassificados": "Exporte uniquement les documents non classifiés",
						"exportaApenasRelevantes": "Exporte uniquement les documents fiscalement pertinents",
						"exportar": "Exporter",
						"folder": "Dossier",
						"searchAllFolders": "Rechercher tous les dossiers",
						"searchExportFolder": "Rechercher le dossier à exporter"
					},
					"exportfolder": {
						"documentosClassificados": "Documents classifiés",
						"documentosNaoClassificados": "Documents non classifiés"
					}
				},
				"docsassociados": "Document(s) associé(s) avec succès.",
				"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
				"leavePromptMessage": "En cliquant sur OUI, vous quitterez et supprimerez le fichier généré."
			},
			"alerts": {
				"deletesuccess": "Document(s) supprimé(s) avec succès !",
				"associacaoremoved": "Association de document(s) supprimée(s) avec succès !",
				"selsamefolder": "Vous devez sélectionner des documents dans le même dossier."
			},
			"btn": {
				"alterapasta": "Changer de dossier",
				"refresh": "Actualiser les données",
				"associar": "Associé"
			},
			"export": {
				"processLabel": "Exporter : Préparation du fichier...",
				"processDownloadTooltip": "Téléchargez le fichier",
				"downloadText": "Télécharger",
				"zipReadyText": "Le fichier est prêt.",
				"downloadError": "Une erreur s'est produite lors du téléchargement du fichier!",
				"jobTimeoutModalTitle": "Tâche",
				"jobTimeoutModalMessage": "La tâche a expiré.",
				"fileNotProcessed": "Nous sommes désolés, mais pour une raison quelconque, le fichier n'a pas été généré."
			},
			"errors": {
				"addFilesDisallowed": "Votre licence de module d'archives numériques ne vous permet actuellement pas d'ajouter d'autres documents numériques.<br/>Veuillez vous rendre sur la <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\"> boutique Cloud CentralGest< /a> et activez ou renouvelez la licence du fichier numérique."
			},
			"docscontabsemdocdigital": {
				"title": "Documents comptables sans documents numériques",
				"header": {
					"periodo": "Période",
					"diario": "Tous les jours"
				},
				"table": {
					"periodo": "Période",
					"nDiario": "Tous les jours",
					"nDocInterno": "N° de document interne.",
					"debito": "Dette",
					"credito": "Crédit",
					"nDocExterno": "N° de document externe",
					"dataDoc": "Date du document.",
					"dataDocExterno": "Date du document.",
					"descritivo": "Descriptif",
					"descricao": "Description",
					"contrib": "Numéro de contribuable",
					"contaCorrente": "Numéro de compte",
					"nomeContaCorrente": "Nom"
				}
			}
		},
		"genericviewer": {
			"text": {
				"currentFolder": "Dossier actuel",
				"root": "Source"
			}
		},
		"treeviewer": {
			"actions": {
				"addDoc": "Ajouter des documents à ce dossier",
				"captureDoc": "Capturer le document et l'ajouter au dossier actuel",
				"deleteDocs": "Supprimer tous les documents de ce dossier",
				"deleteDoc": "Supprimer le document"
			}
		},
		"flatviewer": {
			"titles": {
				"folders": "Dossiers",
				"files": "Fichiers",
				"empty": "(Vide)"
			}
		},
		"docviewer": {
			"actions": {
				"zoomIn": "Zoomer",
				"zoomOut": "Zoom arrière",
				"rotateLeft": "Faire pivoter à gauche",
				"rotateRight": "Faire pivoter à droite"
			}
		},
		"docviewerrecolha": {
			"title": {
				"location": "Emplacement",
				"attachment": "Annexe",
				"addDocs": "Ajouter des documents",
				"addDocsCurrentFolder": "Ajouter des documents au dossier actuel",
				"addDocsWithPath": "Ajouter des documents au dossier actuel ({{folderPath}})",
				"attachDoc": "Joindre un document numérique",
				"chooseFolder": "Choisir un dossier",
				"scannedDocumentManagement": "Gestion des documents numérisés",
				"config": "Paramètres",
				"deleteAttachment": "Supprimer la pièce jointe",
				"download": "Document de transfert",
				"noAttachment": "Aucun document numérique",
				"attachmentNotFound": "Document numérique introuvable",
				"attachmentFound": "Document numérique trouvé",
				"attachmentPossiblyFound": "Nous avons trouvé un éventuel document numérique, veuillez vérifier si le document numérique suggéré correspond au document que vous publiez",
				"attachmentMultipleFound": "Nous avons trouvé plusieurs documents numériques possibles, veuillez vérifier si le document numérique suggéré correspond au document que vous publiez",
				"linhasIVA": {
					"valorBase": "Incidence",
					"valorIVA": "T.V.A.",
					"tipoTaxa": "Type de frais",
					"areaRegional": "Zone régionale",
					"taxa": "Taux"
				},
				"totais": {
					"baseTributavel": "Base imposable",
					"totalIVA": "TVA totale",
					"valorRetencao": "Valeur de rétention"
				}
			},
			"navigation": {
				"selectFirstAttachment": "Aller à la première pièce jointe",
				"selectPreviousAttachment": "Précédent",
				"selectNextAttachment": "Suivant",
				"selectLastAttachment": "Aller à la dernière pièce jointe"
			},
			"actions": {
				"attachExistingDoc": "Joindre un document existant en comptabilité numérique au document en cours",
				"attachNewDoc": "Ajouter un nouveau document dans la comptabilité numérique et le joindre au document actuel",
				"attachVerbete": "Ajouter une entrée au document numérique",
				"deleteAttachment": {
					"titleDelete": "Supprimer un document numérique",
					"titleRemoveAssociation": "Supprimer l'association d'une pièce comptable",
					"messageDelete": "Êtes-vous sûr de vouloir supprimer le document ?<br/><span class=\"text-danger\">Attention :</span> si le document a été envoyé par votre client des services comptables lors de sa suppression, vous devrez le demander en vouloir.",
					"messageRemoveAssociation": "Souhaitez-vous supprimer l'association de ce document numérique avec la pièce comptable ?<br/>Lorsque vous supprimez l'association, le document redevient disponible dans la gestion des documents numériques comme non classé."
				}
			},
			"text": {
				"warningHasDigitalDoc": "Le document \"{{periodo}}.{{nDiario}}.{{nDocInterno}}\" est déjà associé à au moins un document numérique.<br/>Êtes-vous sûr de vouloir également joindre un autre document numérique ?",
				"successAttachDoc": "Document numérique joint avec succès",
				"successAttachVerbete": "Entrée ajoutée avec succès au document numérique",
				"warningDateMismatch": "Vous joignez un document numérique qui a été paramétré avec la date \"{{dataDoc}}\" et votre document comptable a la date \"{{dataDocDigital}}\".<br/>Etes-vous sûr de vouloir continuer ?",
				"valuesSource": {
					"0": "Lecture OCR",
					"1": "Lecture de codes à barres",
					"2": "Facture électronique"
				},
				"documento": "Document : {{nDocument}}",
				"emptyLocation": "Cliquez ici pour choisir les dossiers de documents comptables numériques.",
				"emptyAttachments1": "Aucun document dans le dossier \"{{location}}\".",
				"emptyAttachments2": "Cliquez ici pour changer de dossier."
			},
			"choosefoldermodal": {
				"title": "Choisir des dossiers de documents",
				"titleFileRequired": "Choisir un document numérique"
			},
			"attachexistingdocmodal": {
				"title": "Joindre un nouveau document"
			},
			"helptopicsmodal": {
				"faq": {
					"basicConcepts": "Concepts de base des archives numériques",
					"addDocuments": "Ajouter des documents numériques aux archives numériques",
					"showHideColumns": "Afficher et masquer les colonnes dans la grille du document numérique",
					"setFileFolders": "Définir des dossiers de fichiers numériques",
					"moveAttachmentToAnotherFolder": "Déplacer le document numérique vers un autre dossier",
					"createCheckingAccountFromAttachment": "Créer un compte courant à partir du document numérique",
					"speedUpManualAccountingRecordingWithAttachmentData": "Accélérez l'enregistrement comptable manuel avec les données des documents numériques",
					"addDocumentsThroughPreDefined": "Publier des documents numériques en comptabilité via des"
				}
			}
		},
		"clientconnect": {
			"actions": {
				"addDocs": "Ajouter des documents au dossier actuel",
				"captureDoc": "Capturer le document et l'ajouter au dossier actuel",
				"deleteDoc": {
					"btn": "Supprimer le document",
					"title": "Supprimer le document « {{name}} » ?",
					"message": "Êtes-vous sûr de vouloir supprimer le document sélectionné ?"
				}
			},
			"tooltips": {
				"addDocs": "Veuillez sélectionner un dossier valide pour ajouter des documents",
				"captureDoc": "Veuillez sélectionner un dossier valide pour capturer un document",
				"deleteDoc": "Veuillez sélectionner un document pour le supprimer"
			},
			"text": {
				"successDeleteDoc": "Document supprimé avec succès"
			},
			"adddocsmodal": {
				"title": "Ajouter des documents",
				"sendDocs": "Envoyer des fichiers",
				"success": "Documents ajoutés avec succès"
			},
			"deletedocsmodal": {
				"title": "Supprimer des documents",
				"message": "Êtes-vous sûr de vouloir supprimer des documents du dossier « {{path}} » ?"
			}
		},
		"activatelicensemodal": {
			"text": {
				"total": "Nombre total d'entreprises : {{total}}",
				"totalConsumed": "Nombre total d'entreprises déjà ajoutées : {{totalConsumed}}",
				"totalToConsume": "Nombre total d'entreprises que vous pouvez encore ajouter : {{totalToConsume}}"
			},
			"types": {
				"0": {
					"title": "Activer le fichier numérique dans l'entreprise \"{{nEmpresa}} - {{nomeEmpresa}}\"",
					"license1": "Si vous cliquez sur « Oui », le système ajoutera une nouvelle société à votre licence de fichier numérique et il ne sera pas possible d'inverser la situation par la suite.",
					"license2": "Pour le moment, vous pouvez toujours ajouter {{totalToConsume}} entreprises à votre licence d'archives numériques.",
					"dadosLicenca": "Données de licence de fichier numérique",
					"limitReached": "Vous ne pouvez pas ajouter d'autres sociétés à votre licence d'archives numériques, car vous avez déjà enregistré des sociétés « {{consumed}} » « {{total}} ».",
					"errorCGOnMode": "Vous ne pouvez pas activer le fichier numérique, car il s'agit d'une société CGOn."
				},
				"1": {
					"title": "Activer la connexion client dans l'entreprise \"{{nCompany}} - {{nomeEmpresa}}\"",
					"license1": "Si vous cliquez sur « Oui », le système ajoutera une nouvelle entreprise à votre licence de connexion client et il ne sera pas possible d'inverser la situation ultérieurement.",
					"license2": "À l'heure actuelle, vous pouvez toujours ajouter {{totalToConsume}} entreprises à votre licence de connexion client.",
					"dadosLicenca": "Détails de la licence de connexion client",
					"limitReached": "Vous ne pouvez pas ajouter d'autres sociétés à votre licence de connexion client, car vous avez déjà enregistré des sociétés « {{consumed}} » « {{total}} ».",
					"errorCGOnMode": "Vous ne pouvez pas activer la connexion client, car il s'agit d'une société CGOn."
				}
			}
		},
		"atribuirtipodocumentomodal": {
			"title": "Indiquez le type de document que vous souhaitez attribuer",
			"label": "Type de document",
			"required": "Vous devez indiquer le type de document souhaité.",
			"success": "Type de document attribué avec succès."
		},
		"checkfolderscreatedmodal": {
			"title": "Initialisation du fichier numérique",
			"message": "Veuillez patienter pendant l'initialisation des archives numériques."
		},
		"copiardocmodal": {
			"title": "Copier le document",
			"body": "Êtes-vous sûr de vouloir créer une copie du document « {{name}} » ?",
			"success": "Copie du document créée avec succès.<br/>Nom du fichier : \"{{name}}\"."
		},
		"novacontaccmodal": {
			"title": "Créer un nouveau compte courant",
			"fields": {
				"radical": "Radical",
				"nConta": "Numéro de compte à créer",
				"nome": "Nom"
			},
			"successModal": {
				"title": "Afficher le profil {{type}}",
				"message": "Le compte courant de {{type}} a été créé avec succès.<br/>Voulez-vous modifier son compte ?",
				"types": {
					"fornecedor": "fournisseur",
					"cliente": "client",
					"outroDevedorCredor": "un autre débiteur/créancier"
				}
			}
		},
		"removepagesmodal": {
			"title": "Indiquez la ou les pages que vous souhaitez supprimer",
			"label": "Page(s) à supprimer",
			"help1": "Si vous souhaitez supprimer plusieurs pages, séparez-les par une virgule ou indiquez une plage.",
			"help2": "Par exemple: 1,4,5 - supprime les pages 1, 4 et 5.",
			"help3": "Par exemple : 6-10 - supprime les pages 6, 7, 8, 9 et 10.",
			"success": "Page(s) supprimée(s) avec succès."
		},
		"separardocmodal": {
			"title": "Document séparé",
			"body1": "Souhaitez-vous conserver le document original ?",
			"success": "Document séparé avec succès !"
		},
		"unirdocsmodal": {
			"title": "Fusionner des documents",
			"body1": "En effectuant cette opération vous fusionnerez le(s) document(s)",
			"body2": "Vers le document",
			"body3": "Êtes-vous sûr de vouloir continuer ?",
			"success": "Les documents ont été fusionnés avec succès."
		},
		"predefinidosconfig": {
			"title": "Paramètres prédéfinis par type NIF/Document",
			"fatura": "Facture",
			"faturaSimplificada": "Facture simplifiée",
			"faturaRecibo": "Facture/Reçu",
			"notaDebito": "Note de débit",
			"notaCredito": "note de crédit",
			"vendaDinheiro": "Vendre au comptant",
			"talaoVenda": "Bon de vente",
			"talaoDevolucao": "Bon de retour",
			"alienacaoAtivos": "Cession d'actifs",
			"devolucaoAtivos": "Restitution des actifs",
			"premio": "Prix ​​ou reçu du prix",
			"estorno": "Remboursement ou reçu de remboursement",
			"imputacaoSeguradoras": "Imputation aux coassureurs",
			"imputacaoSegLider": "Imputation à co-sec.",
			"recibo": "Reçu",
			"pagamentoEstado": "Paiement à l'État",
			"levantamento": "Enquête",
			"pagamento": "Paiement",
			"deposito": "Dépôt",
			"cheque": "Vérifier",
			"transferencia": "Transfert",
			"efaturainfo": "Cette valeur a été obtenue à partir des paramètres eFatura pour ce contribuable.",
			"success": "Configuration enregistrée avec succès.",
			"predefnotacredito": "La valeur par défaut des notes de crédit ne doit pas être la même que celle des autres types de documents.",
			"warningEFatura": "*Si vous souhaitez publier des documents via eFatura, vous n'avez pas besoin de configurer ceux prédéfinis",
			"lancfaturahint": "Cliquez pour obtenir de l'aide sur cette fonctionnalité",
			"lancfaturahinttitle": "Lancements automatiques par Robot",
			"lancfaturahintinfo": "Si vous activez les écritures Robot dans cette entité, le système enregistrera automatiquement les pièces en comptabilité après leur ajout.",
			"nif": "Contribuable",
			"nomeFornecedor": "Nom du fournisseur"
		},
		"configs": {
			"tabs": {
				"defgerais": {
					"title": "Paramètres généraux",
					"carddefgerais": "Paramètres généraux",
					"cardverbete": "Saisie de documents",
					"cardconfigpastas": "Configuration des dossiers"
				},
				"efatura": {
					"title": "Facture électronique",
					"comprasefatura": "Achats par facture électronique",
					"defoticocaracteres": "Paramètres de reconnaissance optique de caractères (OCR)"
				},
				"lancautorobo": {
					"title": "Lancements automatiques par Robot",
					"lancaDocsAuto": "Effectue automatiquement les publications Robot pour tous les documents après leur ajout",
					"origemPeriodoLancAuto": "Origine de la période en lancements par Robot ou par suggestion",
					"tabelaempresa": "Tableau de l'entreprise",
					"datadoc": "Date du document",
					"message1": "Si vous activez les écritures robot pour tous les documents, le système enregistrera automatiquement les documents en comptabilité après leur ajout.",
					"message2": "Le système pourra comptabiliser des documents avec un code barre ou qui existent dans eFatura et pour lesquels il trouve un modèle de comptabilisation préalablement réalisé en comptabilité pour le NIF du document et avec les mêmes taux de TVA.",
					"message3": "Au fur et à mesure que vous publiez des pièces comptables numériques, le système apprend à effectuer les écritures pour vous.",
					"message4": "Vous pouvez également activer cette fonctionnalité entité par entité dans la grille du document numérique en cliquant avec le bouton droit de la souris sur la ligne de l'entité souhaitée puis sur configuration de l'entité."
				},
				"opcoesocr": {
					"titleSimple": "Options de ROC",
					"title": "Options OCR (reconnaissance optique de caractères)",
					"cardtitle": "Les contribuables doivent séparer les documents à l'aide du QR Code (par exemple les factures EDP ou Via Verde)",
					"removePaginasBrancoAuto": "Supprime automatiquement les pages vierges (cette fonctionnalité ne doit être utilisée que si vous utilisez un scanner recommandé par CentralGest)",
					"table": {
						"nif": "Numéro de contribuable",
						"descricao": "Nom",
						"addedpviaverde": "Ajouter EDP/Via Verde",
						"addedp": "Ajouter une informatique",
						"addviaverde": "Ajouter Via Verde"
					}
				}
			},
			"title": "Paramétrage de la comptabilité numérique",
			"success": "Configuration enregistrée avec succès",
			"fields": {
				"anexaVerbete": "Ajouter une entrée aux documents numérisés",
				"dataLancamIgualDataDoc": "À la date de comptabilisation de la collecte égale à la date du document lors de l'obtention des données du document.",
				"abreSempreVisualizador": "Lors de l'ouverture d'une collection, il affiche une comptabilité numérique",
				"ordenacaoVisualizador": "Commande de documents",
				"usaEFaturaOCR": "Utilise les données eFatura (obtient de meilleurs résultats lors du remplissage des totaux)",
				"recolhaAnexaAuto": "Lors de la collecte, il joint automatiquement un document.",
				"pasta": "Dossier",
				"nomePasta": "Nom du dossier",
				"nDiario": "Tous les jours",
				"nDescritivo": "Descriptif",
				"visivel": "Visible",
				"searchEFaturaSoNdocParcial": "Lors de la recherche de documents en mode eFatura, seuls les documents possibles avec un numéro de document similaire sont affichés.",
				"searchEFaturaSoMesmoDiario": "Lors de la recherche de documents en mode eFatura, il affiche uniquement les documents possibles avec le même journal entre le dossier prédéfini et le dossier de documents.",
				"entidadeHabitual": "Entité habituelle",
				"nCodRepcc": "Distribution c.",
				"usaDiarioConfigSugestLancam": "Dans la suggestion de lancement, utilisez les agendas configurés dans les dossiers",
				"eFaturaOCR": "Utiliser eFatura OCR",
				"fazOCR": "Effectue l'OCR",
				"sugereBanking": "Proposer en banque",
				"nDocfaFT": "Facture de crédit",
				"nDocfaFR": "Facture/Reçu",
				"nDocfaNC": "note de crédit",
				"nDocfaNCPagaNoAto": "Numéro de crédit d'achat payé immédiatement",
				"nCCusto": "centre de coûts",
				"nRefProcesso": "Processus"
			},
			"actions": {
				"obterChaveSincro": "Obtenez la clé de synchronisation de connexion client de cette société",
				"ativarClientConnect": "Activer la connexion client dans cette entreprise"
			},
			"messages": {
				"clientConnectAlreadyActivated": "Cette entreprise a déjà une connexion client active",
				"activatedClientConnect": "Client Connect activé avec succès dans votre entreprise",
				"opcocraddednif": "Numéro de contribuable ajouté à la liste avec succès",
				"opcocraddednifedp": "Nombre de contribuables EDP ajoutés avec succès à la liste",
				"opcocraddednifviaverde": "Numéro de contribuable Via Verde ajouté avec succès à la liste",
				"opcocranifalreadyexists": "Le numéro de contribuable existe déjà dans la liste",
				"repccinfo": "Code de répartition du centre de coûts (peut être utilisé pour lancer automatiquement un centre de coûts en fonction d'une suggestion de lancement de Robot)"
			},
			"configPastas": {
				"title": "Configuration des dossiers"
			},
			"licencas": {
				"message": "'Si vous cliquez sur \"Oui\", le système ajoutera une nouvelle entreprise à votre licence de comptabilité numérique Client Connect, et il ne sera pas possible d'inverser la situation ultérieurement.<br/>À ce stade, vous pouvez toujours ajouter à votre client connectez les sociétés sous licence de comptabilité numérique {{nCompaniesAdd}}."
			},
			"synchronizationKey": {
				"title": "Clé de synchronisation pour Client Connect",
				"key": "Clé de synchronisation"
			},
			"tipoOrdenacaoVisualizador": {
				"0": "Numéro de contribuable |",
				"1": "Dates |",
				"2": "Date de réception",
				"3": "Nom |",
				"4": "Dates |",
				"5": "Numérotation des dossiers",
				"nifData": "Numéro de contribuable |",
				"dataNif": "Dates |",
				"dataRececao": "Date de réception",
				"nomeData": "Nom |",
				"dataNome": "Dates |",
				"nomeFicheiro": "Numérotation des dossiers"
			}
		}
	},
	"artars": {
		"title_detail": "Article d'entrepôt",
		"title_new": "Nouvel article d'entrepôt",
		"title_plural": "Articles d'entrepôt",
		"pesquisa": "A chercher",
		"saved": "Article d'entrepôt, stocké avec succès.",
		"error": "Article d'entrepôt, ne peut pas être stocké.",
		"deleted": "Article d'entrepôt, supprimé avec succès.",
		"fields": {
			"nArtigo": "Numéro d'article",
			"nArmazem": "Numéro d'entrepôt.",
			"stockMinimo": "Stock minimum",
			"stockMaxIMO": "Stock maximum",
			"stockReposic": "Réapprovisionnement des stocks",
			"qtdMinEncome": "Qté min.",
			"qtdMaxEncome": "Quantité maximale.",
			"qtdRepEncome": "Quantité représentant.",
			"despPorEncom": "Frais",
			"txCustoPosse": "Taux de coût de possession",
			"nClifoHabitual": "Numéro de cliph habituel",
			"nDiasValidade": "Nombre de jours de validité",
			"nSectorLocal": "Secteur local n°",
			"qtdLimArmazenamento": "Quantité limitée.",
			"localExcedentes": "Emplacement excédentaire",
			"stockAlerta": "Alerte stock"
		}
	},
	"artigoclasses": {
		"title_detail": "Classe d'articles {{id}}",
		"title_new": "Nouvelle classe d'articles",
		"title_plural": "Classes d'articles",
		"pesquisa": "A chercher",
		"saved": "Classe d'article {{id}}, enregistrée avec succès.",
		"error": "Classe d'article {{id}}, ne peut pas être enregistrée.",
		"fields": {
			"classe": "Code.",
			"classePlaceholder": "Code de classe d'article",
			"descricao": "Description",
			"descricaoPlaceholder": "Description de la classe d'articles"
		}
	},
	"artigos": {
		"title_detail": "Article {{id}}",
		"title_new": "Nouvel article",
		"title_plural": "Articles",
		"pesquisa": "A chercher",
		"saved": "Article {{id}}, enregistré avec succès",
		"error": "L'article {{id}} ne peut pas être enregistré.",
		"deleted": "Article {{id}}, supprimé avec succès",
		"texto_livre": "Texte libre",
		"fields": {
			"nArtigo": "Code article",
			"nArtigoPlaceholder": "Le code article, s'il n'est pas renseigné, sera généré automatiquement.",
			"nome": "Nom de l'article",
			"nomePlaceholder": "Nom de l'article",
			"nFamilia": "numéro de famille",
			"nFamiliaPlaceholder": "code de famille",
			"nomeFamilia": "Famille",
			"nomeFamiliaPlaceholder": "Nom de famille",
			"nTpArt": "Taper",
			"nTpArtPlaceholder": "Type d'article",
			"nomeTpArt": "Type d'article",
			"nomeTpArtPlaceholder": "Nom du type d'article",
			"nGrFamilia": "Famille Gr.",
			"nGrFamiliaPlaceholder": "Grande famille",
			"nomeGrFamilia": "Famille Gr.",
			"nomeGrFamiliaPlaceholder": "Grand nom de famille",
			"nSubFa": "Sous-famille",
			"nSubFaPlaceholder": "sous-famille",
			"nomeSubFa": "Sous-famille",
			"nomeSubFaPlaceholder": "Nom de sous-famille",
			"nomeUnidadeMov": "Unité",
			"nomeUnidadeMovPlaceholder": "Unité de mouvement",
			"qtdPorUnid1": "Qté.",
			"qtdPorUnid1Placeholder": "Quantité par unité",
			"movimStock": "Déplacer le stock",
			"movimStockPlaceholder": "Déplacer le stock",
			"codvaloriz": "Évaluation",
			"codvalorizPlaceholder": "Évaluation",
			"nomeIvaVenda": "Vente TVA",
			"nomeIvaVendaPlaceholder": "Nom de TVA",
			"taxaIvaVenda": "Taux de TVA",
			"taxaIvaVendaPlaceholder": "Taux de TVA",
			"nomeIvaCompra": "Achat avec TVA",
			"nomeIvaCompraPlaceholder": "Nom TVA achat",
			"taxaIvaCompra": "Taux de TVA à l'achat",
			"taxaIvaCompraPlaceholder": "Taux de TVA à l'achat",
			"precoSemIva": "Prix ​​sans TVA",
			"precoSemIvaPlaceholder": "Prix ​​sans TVA",
			"precoComIva": "Prix ​​avec TVA",
			"precoComIvaPlaceholder": "Prix ​​avec TVA",
			"qtdTotal": "Quantité en stock",
			"qtdTotalPlaceholder": "Quantité totale en stock",
			"temRetencaoNaFonte": "A une retenue à la source",
			"ncontapoc": "Numéro de compte de vente",
			"nContaPocCompra": "Numéro de compte d'achat",
			"grupoartigo": "Propriété",
			"artDesactivado": "Article désactivé",
			"artBloqueado": "Article bloqué",
			"categoria": "Catégorie",
			"precoBaseCusto": "Prix ​​de base",
			"precoBaseCustoPlaceholder": "Prix ​​de base",
			"nArmazem": "Code entrepôt",
			"nomeArmazem": "Nom de l'entrepôt",
			"qtd": "Stock actuel",
			"qtdStockPotencial": "Stock potentiel",
			"contaExt": "Compte externe",
			"artigoExt": "Code article externe",
			"descContaExt": "Description du compte externe",
			"precoCompUlt": "Valeur du dernier achat",
			"precoCompUltPlaceholder": "Valeur du dernier achat",
			"prVendaUlti": "Dernière valeur de vente",
			"prVendaUltiPlaceholder": "Dernière valeur de vente",
			"dataPrCompra": "Date du dernier achat",
			"dataPrVendUl": "Date de la dernière vente",
			"naoPermiteExistNeg": "Ne permet pas de stock négatif",
			"temLote": "Il y a beaucoup",
			"temDataValidade": "Date d'expiration",
			"diasValidade": "Nombre de jours de validité",
			"diasValidadePlaceholder": "Nombre de jours de validité",
			"segNArtigo": "Codes à barres",
			"segNome": "Description alternative",
			"qtdStockEncCliente": "Commande",
			"qtdStockEncForn": "Commande",
			"qtdTotalStockPotencial": "Stock potentiel",
			"qtdTotalStockEncCliente": "Commande",
			"qtdTotalStockEncForn": "Commande",
			"taxaIvaTip": "{{fee}} % de frais",
			"qtdStockMinimo": "Stock minimum",
			"qtdStockMaximo": "Stock maximum",
			"qtdAdd": "Qté."
		},
		"btn": {
			"buttonOptions": "Possibilités",
			"verContas": "Affiche les comptes de vente et d'achat",
			"verPrecoBaseCusto": "Affiche le prix de base",
			"verCategoria": "Afficher la catégorie",
			"esconderContas": "Masquer les comptes",
			"mostraTodas": "Tous",
			"extratoArtigo": "Extrait d'article",
			"verPropriedade": "Afficher la propriété",
			"verApenasDesativado": "Voir désactivé uniquement",
			"verApenasBloqueado": "Voir bloqué uniquement"
		},
		"message": {
			"diferefamilia": "Le numéro de compte dans cet article diffère du numéro de compte dans la famille.",
			"mostraTodas": "Afficher tous les comptes d'intégration possibles",
			"contaPocFormadaVenda": "Le compte d'intégration à constituer sera éventuellement {{nAccount}}.",
			"contaPocFormadaCompra": "Le compte d'intégration à constituer sera éventuellement {{nAccount}}.",
			"contaPocFormadaMovimento": "Le compte d'intégration à créer sera {{nAccount}}.",
			"infofield": "Ce champ est uniquement à titre informatif.",
			"extratoArtigoTooltip": "Voir l'extrait de l'article"
		},
		"actions": {
			"familias": "Entretien familial"
		},
		"datasource": {
			"grupoartigo": {
				"Geral": "Général",
				"Mercadoria": "Marchandises",
				"Produto": "Produit",
				"MateriaPrima": "Matière première",
				"Servico": "Service",
				"ServicoExterno": "Prestation externe",
				"Componente": "Composant",
				"Equipamento": "Équipement",
				"Ferramenta": "Outil",
				"MaoDeObra": "Travail",
				"ArtigoReferencia": "Article de référence",
				"MaterialSubsidiario": "Matériel subsidiaire",
				"Embalagem": "Conditionnement",
				"Veiculo": "Véhicule",
				"Motorista": "Conducteur",
				"TipoTarefa": "Type de tâche",
				"TarefaEspecifica": "Tâche spécifique",
				"TarefaGeral": "Tâche générale",
				"Transportadora": "Transporteur",
				"MaquinaMotriz": "Machine à conduire",
				"Configuracao": "Paramètres",
				"ArtigoTara": "Article sur la tare",
				"Infraestrutura": "Infrastructure",
				"Utensilio": "Ustensile",
				"ParteInfraestrutura": "Partie infrastructure",
				"Adiantamentos": "Avances",
				"AlienacaoDeImobilizado": "Cession d'immobilisations"
			}
		},
		"navigator": {
			"tabs": {
				"ficha": "Enregistrer",
				"extrato": "Extrait",
				"anexos": "Pièces jointes",
				"lotes": "Beaucoup",
				"estatistica": "Statistique"
			},
			"groups": {
				"indentificacao": "Identification",
				"classificadores": "Classificateurs",
				"precos": "Tarifs",
				"stocks": "actions",
				"classificacao": "Impôts et autres",
				"clifo": "Client / Fournisseur",
				"contab": "Comptabilité"
			}
		},
		"text": {
			"detalheArmazem": "Détail par entrepôt",
			"tipoprod": "Type de produit (inventaire) : {{cod}} - {{desc}}",
			"codBarras": "EAN13 - Identifiant unique lu à l'aide d'un scanner adapté à la lecture.",
			"artigoBloqueado": "Normalement utilisé pour bloquer temporairement.",
			"artigoDesativado": "Normalement utilisé pour bloquer définitivement.",
			"familia": "Classificateur qui permet d'initialiser différents champs sur le formulaire d'article.",
			"stockPotencial": "Obtenu de la manière suivante: Stock - Enc.",
			"lotesBloqueadosMovStock": "Beaucoup d'articles bloqués car le stock ne bouge pas",
			"lotesBloqueadosLicencaPremium": "Lot bloqué car l'article ne bouge pas en stock",
			"fileNamePicture": "Image_{{nArticle}}",
			"deletePicture": "Supprimer l'image"
		}
	},
	"assistenteconfigportais": {
		"pages": {
			"empresa": "Configuration de l'entreprise",
			"utilizador": "Configuration utilisateur",
			"empregado": "Configuration des employés",
			"email": "Paramètres de messagerie par entreprise"
		},
		"fields": {
			"portal": "Sélectionnez le portail",
			"operacao": "Sélectionnez l'opération",
			"novoutilizador": "Nouvel utilisateur",
			"nomeutilizador": "Nom d'utilisateur",
			"nome": "Nom",
			"apelido": "Nom de famille",
			"email": "E-mail",
			"enviaEmail": "Envoyer un email avec mot de passe",
			"codempregado": "Code employé",
			"nomeempregado": "Nom de l'employé",
			"addempregado": "Employé associé",
			"contaemail": "Compte",
			"password": "Mot de passe",
			"smtp": "SMTP",
			"portasmtp": "Port SMTP",
			"ssl": "SSL",
			"empresa": "Entreprise",
			"addempresa": "Ajouter une entreprise",
			"removeempresa": "Supprimer l'entreprise",
			"utilizador": "Utilisateur"
		},
		"opcoes": {
			"selecionarportal": "Sélectionnez un portail",
			"operacoesportal": "Sélectionnez une opération"
		},
		"outros": {
			"empresa": "Entreprise",
			"utilizador": "Utilisateur",
			"gabinete": "Armoire"
		},
		"info": {
			"naocriautilizador": "Ne créera pas de nouvel utilisateur",
			"savesucess": "Utilisateur créé avec succès",
			"saveerror": "Erreur lors de la création de l'utilisateur",
			"empresagabinetenaoselec": "Il n’y a pas d’ébénisterie sélectionnée!",
			"empresaclientenaoselec": "Il n'y a pas d'entreprise cliente sélectionnée !",
			"empresanaoselec": "Il n’y a aucune entreprise à sélectionner ou aucune n’est sélectionnée !",
			"associateempsucess": "Employé associé avec succès",
			"associateemperror": "Erreur lors de l'association d'un employé à un utilisateur",
			"utilizadornaosel": "Aucun utilisateur n'est sélectionné",
			"empresanaosel": "Aucune entreprise n'est sélectionnée",
			"empregadonaosel": "Il n'y a aucun employé sélectionné",
			"naotemempresasparaconfigurar": "L'utilisateur a déjà le(s) employé(s) associé(s) à la ou aux entreprises.",
			"empresaClienteConfigurada": "L'entreprise cliente a été configurée avec succès",
			"empresaClienteConfigError": "Erreur lors de la configuration de l'entreprise cliente",
			"contaemailnula": "Le compte email doit être renseigné",
			"passwordnula": "Le mot de passe du compte email doit être renseigné",
			"smtpnulo": "SMTP doit être complété",
			"portasmtpnula": "Le port SMTP doit être renseigné",
			"emailconfigsavesucess": "E-mail configuré avec succès",
			"emailconfigsaveerror": "Erreur lors de la configuration de la messagerie",
			"utilizadorexistentesuccess": "L'utilisateur a été mis à jour avec succès"
		},
		"operations": {
			"myAccounting": {
				"addUser": "Ajouter un utilisateur au portail",
				"addEmpresa": "Rendre votre entreprise disponible sur le portail",
				"associarEmpregado": "Associer un employé à un utilisateur sur le portail"
			},
			"portalclientconnect": {
				"createUser": "Créer un nouvel utilisateur sur le portail",
				"addUser": "Ajouter un utilisateur existant au portail"
			}
		},
		"steps": {
			"stepGabinete": "Armoire",
			"stepEmpresa": "Entreprise",
			"stepUtilizador": "Utilisateur",
			"stepEmpregado": "Employé",
			"stepEmail": "E-mail"
		},
		"tiposEmail": {
			"gmail": "Gmail",
			"hotmail": "Hotmail",
			"outro": "Autre"
		}
	},
	"atFRV": {
		"tabDocsEFaturaCaption": "Reçus verts délivrés",
		"tabJaLancadosCaption": "Recettes déjà inscrites en comptabilité ou ignorées",
		"viewDocModalTitle": "Vue du document {{ndoc}}",
		"viewDocModalTitleSimple": "Aperçu du document",
		"extratosModalTitle": "Relevés de compte",
		"expandAllBtn": "Tout développer",
		"cantSelectRowDueInvalidPredefinido": "Vous ne pouvez pas sélectionner l'enregistrement car il n'est pas prédéfini.",
		"cantSelectRowsDueInvalidPredefinido": "Les enregistrements sans défaut n'ont pas été sélectionnés !",
		"selectAll": "Tout sélectionner",
		"lancdocserie": "Documents de lancement en série",
		"createDoc": "Créer un document",
		"messages": {
			"docIgnoradoSuccessMsg": "Le reçu {{ndoc}} a été ajouté à la liste des ignorés.",
			"nifIgnoradoSuccessMsg": "{{nif}} - {{name}} a été ajouté à la liste des ignorés.",
			"docRemIgnoringSuccessMsg": "Le reçu {{ndoc}} a été supprimé de la liste des ignorés.",
			"docRemIgnoringFornecedorSuccessMsg": "Le contributeur {{nif}} a été supprimé de la liste des ignorés."
		},
		"fields": {
			"documentoLancado": "Numéro du document",
			"nifAdquirente": "FNI",
			"nomeAdquirente": "Nom",
			"numDocumento": "Numéro de reçu.",
			"dataEmissao": "Date d'émission",
			"tipoDocumento": "Taper",
			"situacao": "Situation",
			"atividadePrestador": "Activité du fournisseur",
			"observacoes": "Observations",
			"importanciaRecebida": "Importance reçue",
			"valorBase": "Valeur de base",
			"valorIVA": "Valeur TVA",
			"valorIRS": "Valeur IRS",
			"valorImpostoSelo": "Valeur du droit de timbre",
			"taxaIVA": "Taux de TVA",
			"preDefinido": "Prédéfini",
			"numeroUnico": "Numéro de reçu."
		},
		"btn": {
			"dropdown": {
				"extratosConta": "Relevés de compte",
				"ignorarSempreTodosDocumentos": "Ignorez toujours tous les reçus de ce contribuable",
				"ignorarDocumento": "Ignorer ce reçu",
				"nifConfig": "Configurer NIF",
				"gerarNovaCC": "Générer un nouveau compte courant",
				"pdf": "Afficher le reçu PDF"
			}
		},
		"tabJaLancadosCaptionContainer": {
			"table": {
				"badges": {
					"naoIgnorar": "N'ignore pas",
					"remover": "Retirer"
				}
			},
			"legends": {
				"documentoIgnorado": "Reçu ignoré",
				"documentoFornecedorIgnorado": "Document contribuable ignoré"
			}
		},
		"formFields": {
			"dataDe": "Date de",
			"tipo": "Taper",
			"situacao": "Situation",
			"placeholder": {
				"contribuinte": "Numéro de contribuable"
			}
		},
		"configModalTitle": "Configuration prédéfinie",
		"reciboVerdeLabel": "",
		"novacontaccmodal": {
			"title": "Créer un nouveau compte courant",
			"fields": {
				"radical": "Radical",
				"nConta": "Numéro de compte à créer",
				"nome": "Nom"
			},
			"successModal": {
				"title": "Voir le profil du fournisseur",
				"message": "Compte courant fournisseur créé avec succès.<br/>Voulez-vous modifier le compte ?"
			}
		},
		"modal": {
			"nifConfig": {
				"confPreDefinido": "Configuration prédéfinie par contribuable",
				"contaCorrente": "Compte courant à utiliser"
			}
		}
	},
	"ativos": {
		"title_detail": "Actif {{id}}",
		"title_new": "Nouvel actif",
		"title_plural": "Actifs",
		"pesquisa": "A chercher",
		"saved": "{{id}} actif, enregistré avec succès",
		"error": "{{id}} actif, ne peut pas être enregistré.",
		"deleted": "{{id}} actif, supprimé avec succès",
		"fields": {
			"codAtivo": "Code",
			"refExterna": "Réf. externe",
			"designacao": "Désignation",
			"estado": "État",
			"dataEstado": "Date d'état",
			"viatLigArtigo11DR": "Véhicule léger - Article 11 DR",
			"codAtivoPai": "Code actif parent",
			"documentoDeAquisicao": "Document d'acquisition",
			"origem": "Origine",
			"ignoraImpostosDiferidos": "Ignore la comptabilité des impôts différés.",
			"header": {
				"estado": "État:",
				"valorAquisicao": "Valeur d'acquisition",
				"depAcumulada": "Dépôt accumulé.",
				"valorLiquido": "Valeur nette",
				"diario": "Tous les jours",
				"periodo": "Période",
				"ndocinterno": "Doc n°",
				"contabTaxaAmortiza": "Taux d'amortissement comptable",
				"fiscalTaxaAmortiza": "Taux d'amortissement fiscal",
				"contabBaseCalculo": "Base de calcul comptable",
				"fiscalBaseCalculo": "Base de calcul de l'impôt"
			},
			"totals": {
				"estado": "État",
				"qtdEstado": "",
				"totalValAquisicao": "",
				"totalDepAcumulada": "",
				"totalValLiquido": ""
			}
		},
		"modal": {
			"btn": {
				"marcarGR": "Pour marquer",
				"desmarcarGR": "Décochez"
			},
			"abatedoativo": "Radiation d'actifs",
			"registodevendaativo": "Enregistrement de la vente d'actifs",
			"radicalAbate": "Numéro de compte d'abattage",
			"dataAbate": "Date d'abattage",
			"grMarcar": "Marquer l'actif comme une réparation majeure",
			"grDesmarcar": "Désélectionner un actif comme étant une réparation majeure"
		},
		"header": {
			"estado": "État:",
			"valorAquisicao": "Valeur d'achat:",
			"depAcumulada": "Dépôt accumulé :",
			"valorLiquido": "Valeur nette :"
		},
		"estado": {
			"0": "Normale",
			"1": "Abattu ({{date}})",
			"2": "Vendu ({{date}})",
			"3": "Fractionner ({{date}})"
		},
		"titles": {
			"dadosDoAtivo": "Données sur les actifs",
			"dadosDoFornecedor": "Détails du fournisseur",
			"dadosContabilisticos": "Données comptables",
			"dadosFiscais": "Données fiscales",
			"contas": "Comptes",
			"outrosDados": "Autres données",
			"impostosDiferidos": "Impôts différés",
			"classificadores": "Classificateurs",
			"grandeReparacao": "Actif parent"
		},
		"toolbar": {
			"acontecimentos": "Opérations",
			"dropdownDeprPlan": "Amortissement et planification",
			"dropdownRegContab": "Comptabilité",
			"tooltipAtivoAbateVendido": "Actif déjà radié/vendu",
			"dropdownConsulta": "Consultation",
			"tooltipAtivoEstadoNormal": "Le bien doit être dans l’état normal.",
			"tooltipOpJustoValorABP": "L'actif doit être produit biologiquement et dans un état normal."
		},
		"actions": {
			"abate": "Enregistrer l'abattage",
			"venda": "Enregistrer la vente",
			"planning": "Planification",
			"depreciacoes": "Dépréciations",
			"depreciacoesDtl": "Amortissements détaillés",
			"regcontab": "Registres comptables",
			"mudarcontaativo": "Changer de compte d'actif",
			"mudarcontadepexerc": "Changer de service de compte.",
			"mudarcontadepacumu": "Changer de service de compte.",
			"mudastaxasdep": "Modifier les taux d'amortissement",
			"notacredito": "Remise (Avis de crédit)",
			"divisao": "Répartition des actifs",
			"ajustedepantigas": "Ajuster l'amortissement accumulé les années précédentes",
			"imprimeativo": "Imprimer la fiche d'actif",
			"reavaliacoesNaoLegais": "Réévaluations non légales",
			"aumentoifrs16": "Augmentation de valeur en IFRS16",
			"opjustovalorabp": "Option juste valeur dans ABP",
			"grandereparacao": "Réparation majeure (Marquer/Décocher)"
		},
		"steps": {
			"aquisicaoAtivo": "Acquisition d'actifs",
			"dadosContabilisticosDadosFiscais": "Données comptables et données fiscales",
			"contasPlanoOutrosDados": "Comptes de régime et autres données",
			"classificadoresDoAtivo": "Classificateurs d'actifs",
			"planningAmortizacoes": "Planification de l'amortissement",
			"ativoDepreciacoesDetalhadas": "Amortissements détaillés",
			"ativoDepreciacoes": "Dépréciations",
			"pesquisaContabilidade": "Registres comptables"
		},
		"enum": {
			"origem": {
				"0": "Acquisition",
				"1": "Conversion",
				"2": "Comptabilité",
				"3": "Importation Excel",
				"4": "Importation manuelle",
				"5": "Répartition des actifs",
				"6": "Réévaluations non légales",
				"7": "Augmentation de valeur selon IFRS 16",
				"8": "Opération à la juste valeur",
				"9": "Des investissements en cours"
			}
		},
		"table": {
			"dadosContabilisticos": "Données comptables",
			"dadosFiscais": "Données fiscales",
			"depreciacaocontabilistica": "Amortissement comptable",
			"depreciacaofiscalaceite": "Amortissement fiscal accepté",
			"taxascontab": "Frais de comptabilité",
			"taxasfiscal": "Taux d'imposition",
			"limiteminimofiscal": "Plafond d'impôt minimum",
			"limitemaximofiscal": "Plafond d'imposition maximum",
			"perdasimparidadecontabilisticas": "Pertes de valeur comptables",
			"perdasimparidadefiscais": "Pertes de valeur fiscales",
			"valoresperdidosfiscais": "Taxer les valeurs perdues",
			"taxasperdidasfiscais": "Frais de perte fiscale",
			"depreciacoesnaoaceites": "Amortissement non accepté",
			"imparidadesnaoaceites": "Déficiences non acceptées",
			"depreciacoesarecuperar": "Amortissement à récupérer",
			"depreciacoesrecuperadas": "Amortissements récupérés",
			"imparidadesrecuperadasnoperiodo": "Dépréciations récupérées sur la période",
			"acrescemodelo22": "S'ajoute au modèle 22",
			"deduzmodelo22": "Déductions au modèle 22",
			"valortributavelativosimpostosdiferidos": "Valeur imposable des actifs d'impôts différés",
			"valortributaveldopassivoimpostosdiferidos": "Valeur imposable des impôts différés passifs",
			"valornaoaceitefiscalmenteporreavaliacoes": "Valeur non acceptée fiscalement en raison de réévaluations",
			"impostosdiferidos": "Impôts différés",
			"rendimentos": "Revenu de subvention",
			"impostosdifSubsidios": "Impôts sur les subventions différés",
			"tipoOperacao": "Opération",
			"ndiario": "Tous les jours",
			"ndocInterno": "Doc n°",
			"dataDoc": "Date de sortie",
			"dataDocExterno": "Date du document.",
			"periodo": "Période",
			"depreciacoes": "Dépréciations",
			"body": {
				"mesde": "Mois de",
				"mesate": "Mois jusqu'à",
				"anoEconomico": "Année économique",
				"valorAquisicao": "Valeur d'acquisition",
				"valorResidual": "Valeur résiduelle",
				"taxa": "Taux",
				"redTaxa": "%rouge.",
				"depreciacao": "Dépréciation",
				"depreciacoes": {
					"anodoubledot": "Année:",
					"contabtaxamedia": "Taux de répartition moyen",
					"fiscaltaxamedia": "Taux d'imposition moyen",
					"periodo": "Période",
					"periodoano": "Période d'année",
					"periodomes": "Période de mois",
					"depmes": "Dans le mois",
					"depmesaquisicao": "Dans le mois de l'acquisition",
					"depmesreavalicao": "Dans le mois de la réévaluation",
					"depacumano": "Dans l'année",
					"depacumant": "L'année précédente",
					"taxa": "Taux",
					"percreducao": "% de réduction",
					"reforco": "Renforcement",
					"taxairc": "Taux IRC"
				}
			}
		},
		"configuracoes": {
			"tab": {
				"title": {
					"aquisicao": "Acquisition",
					"impostosDiferidos": "Impôts différés",
					"depreciacoes": "Dépréciations",
					"ajustes": "Ajustements",
					"errosTitle": "Erreurs d’amortissement des années précédentes",
					"vendaTitle1": "Facture de vente",
					"vendaTitle2": "Valeur brute et amortissement cumulé",
					"abate": "Abattage",
					"contabilidadeanaliticatitle": "Radicaux soumis à la comptabilité analytique",
					"reavaliacoesnaolegais": "Réévaluations non légales",
					"impdifreavaliacoesnaolegais": "Impôts différés sur réévaluations non statutaires",
					"operacoesjustovalor": "Opérations à la juste valeur",
					"impdifoperacaojustovalor": "Impôts différés sur transactions en juste valeur"
				},
				"diversos": "Plusieurs",
				"contasDepreciacao": "Comptes d'amortissement",
				"aquisicao": "Acquisition",
				"depreciacoes": "Dépréciations",
				"ajustes": "Ajustements",
				"erros": "Erreurs",
				"venda": "Vente",
				"abate": "Abattage",
				"regraAbateVenda": "Règles d'abattage/vente",
				"contabilidadeAnalitica": "Comptabilité analytique",
				"classificadores": "Classificateurs",
				"reavaliacoesnaolegais": "Réévaluations non légales",
				"ativosbioprod": "Actifs de production biologique (ABP)",
				"contasInvestCurso": "Comptes d'investissement en cours"
			},
			"btn": {
				"adicionar": "Pour ajouter",
				"guardar": "Sauvegarder",
				"cancelar": "Annuler"
			},
			"messages": {
				"realizadacomsucesso": "Opération réalisée avec succès.",
				"grcodativoempty": "Le code.",
				"radicalRequired": "Le radical ne peut pas être vide.",
				"radicalInvalid": "Radical n'autorise que les caractères numériques.",
				"radicalJaExistente": "Le radical inséré est déjà configuré.",
				"contaImobilizadoRequired": "Le radical du compte d'actif ne peut pas être vide.",
				"contaImobilizadoInvalid": "Le radical de compte d'actifs autorise uniquement les caractères numériques.",
				"contaImobilizadoJaExiste": "Le compte d'actif renseigné existe déjà.",
				"pocInvalid": "Le compte d'amortissement ou de dépréciation n'existe pas.",
				"successfullySaved": "Modifications enregistrées avec succès.",
				"ativoguardadosuccess": "Actif enregistré avec succès.",
				"contaTransicaoInvalid": "Le compte de transition n'est pas valide."
			},
			"radiooptions": {
				"ativoperiodo": "Comptabilise un document pour chaque bien, dans chaque période",
				"ativoperiodoult": "Lance un document pour chaque bien, en une seule période (dernière)",
				"ativosperiodo": "Lance un document avec tous les actifs, dans chaque période",
				"ativosperiodoult": "Lancer un document avec tous les actifs, en une seule période (dernière)"
			},
			"trataAtivosNoCentralGest": "Traite les actifs dans CentralGest",
			"radicaisContasImobilizado": "Radicaux actifs",
			"radicalIVA": "TVA radicale",
			"taxaIRCEstimada": "Taux IRC estimé",
			"codDiario": "Code journal",
			"codDescritivo": "Code descriptif",
			"radicaisFornecedorAquisicao": "Fournisseurs radicaux",
			"impostosDiferidosContaPassivo": "Code de compte passif",
			"impostosDiferidosContaAjustamentos": "Code de compte des ajustements",
			"impostosDiferidosContaImpostoDiferido": "Code de compte Imp.",
			"mostraContabilidadeConversao": "Dans la maintenance des actifs, affiche les relations de conversion de l'ancienne structure",
			"depreciacaoDataEncerramento": "Date de clôture",
			"contaContrapartida": "Code de compte de compensation",
			"contaMaisValia": "Compte de plus-value",
			"contaMenosValia": "Compte de perte en capital",
			"vendaRadicalMaisValiaFatura": "Une valeur ajoutée radicale",
			"vendaRadicalMenosValiaFatura": "Valeur radicale moins",
			"abateRadical": "Massacre radical",
			"ccusto": "Code C. Coût",
			"zona": "Zone",
			"departamento": "Département",
			"subdepartamento": "Sous-département",
			"tipoartigo": "Type d'article",
			"grandefamilia": "Grande famille",
			"familia": "Famille",
			"subfamilia": "Sous-famille",
			"contabilidade": "Comptabilité",
			"classe": "Classe",
			"categoria": "Catégorie",
			"vendedor": "Vendeur",
			"processo": "Processus",
			"dataLimiteParaCalculoContabilisticoIgualFiscalTrue": "Date limite de calcul comptable, identique à la date fiscale (dernier jour du mois précédent)",
			"dataLimiteParaCalculoContabilisticoIgualFiscalFalse": "La date limite de calcul comptable est la veille de l’abattage/vente",
			"contabilidadeAnaliticaUsa": "Utiliser la comptabilité analytique dans l'intégration des actifs",
			"contabilidadeAnaliticaMascara": "Masque d'analyse",
			"analiticaContaContraPartida": "Compte de compensation",
			"radicaisSujeitosList": "Liste des radicaux",
			"novoRadicalSujeito": "Nouveau radical",
			"contaImobilizado": "Compte d'immobilisations",
			"contaDepreciacao": "Compte d'amortissement",
			"contaDepreciacaoAcumulada": "Compte d'amortissement cumulé",
			"infodep": "Il y a déjà des amortissements effectués pour l'année en cours.",
			"depreciacaoSoRegistaNaContabilidadeEmPeriodoAposAquisicao": "Uniquement enregistré en Comptabilité dans une période égale ou supérieure à l'acquisition",
			"depreciacoesDoAnoPesquisaPeloEstadoDepreciacoes": "Rechercher les amortissements annuels par statut d'amortissement",
			"nContaContrapartida": "Numéro de compte de compensation",
			"nContaPassivo": "Numéro de compte de passif",
			"nContaAjustamentos": "Numéro de décompte des ajustements",
			"radicalabp": "ABP radical",
			"nContaParaReducao": "Numéro de compte pour réduction",
			"nContaParaAumento": "Numéro de compte pour augmentation",
			"nContaAtivo": "Numéro de compte actif",
			"cursoRadicalInvestimentos": "Des investissements radicaux en cours",
			"cursoRadicalAtivo": "Radical du compte d’actifs",
			"cursoContaTransicao": "Compte de transition"
		},
		"verificacaoDeConsistenciaVenda": {
			"error": "Il existe des documents de vente d'actifs dans les comptes, sans rapport avec les actifs.<br>Enregistrez les ventes respectives.",
			"btn": {
				"redirectToVendaSerie": "Cliquez ici pour être redirigé vers les ventes d'actifs."
			}
		},
		"listTotals": {
			"totalativos": "Nombre total d'actifs",
			"totalvalaquisicao": "Valeur totale d'acquisition",
			"totaldepacumulada": "Total du département.",
			"totalvalliquido": "Valeur nette totale",
			"resumo": "Résumé",
			"estado": "État"
		},
		"aumentoifrs16": {
			"title": "Augmentation de valeur en IFRS16",
			"initdescription": "[Augmentation de valeur] -",
			"info": "Cette action permet de réaliser la hausse d’IFRS 16.",
			"data": "Date d'augmentation",
			"valor": "Augmenter la valeur",
			"designacao": "Nouvelle désignation d'actif",
			"messages": {
				"descempty": "La désignation ne peut pas être nulle!",
				"invalidvalue": "Le montant de l'augmentation n'est pas valable !",
				"invaliddate": "La date de la plus-value doit être postérieure au début de l’année en cours !",
				"registedsuccess": "Augmentation de la valeur enregistrée."
			}
		},
		"ativosopjustovalorabp": {
			"title": "Opération à la juste valeur sur actifs de production biologique (ABP)",
			"dataOperacao": "Date d'opération",
			"valorOperacao": "Valeur d'opération",
			"temcertezaOPJustoValor": "Avez-vous l’intention de réaliser l’opération à la juste valeur ?",
			"successOPJustoValor": "Transaction en juste valeur réalisée avec succès"
		},
		"messages": {
			"existemmaisativoscontab": "Le bien est issu de la comptabilité.<br/>En le supprimant, vous éliminerez tous les autres qui résultent du même document.<br/>Êtes-vous sûr de vouloir continuer ?",
			"existemmaisativosdivididos": "Êtes-vous sûr de vouloir supprimer l'actif ?<br/>Il s'agit d'un actif résultant d'une division.<br/>La suppression de cet actif éliminera tous les actifs résultant de la division.<br/>L'actif qui a été divisé reviendra à l'état normal."
		}
	},
	"ativosreavaliacoesnaolegais": {
		"titleList": "Réévaluations non légales",
		"title": "Réévaluation non juridique",
		"ativoparcial": "Actif {{codAtivo}} partiellement déprécié.",
		"ativototaldep": "Actif {{codAtivo}} entièrement déprécié.",
		"vidaUtilDefinidaEDiferente": "La durée d'utilité définie est différente de celle de l'actif circulant.",
		"temACertezaQuePretendeRealizarAR": "Etes-vous sûr de vouloir procéder à la réévaluation non légale du bien ?",
		"success": "Réévaluation non judiciaire réalisée avec succès.",
		"jahadepreciacoes": "Il y a déjà des dépréciations faites dans l’année pour le bien !",
		"fields": {
			"dataReavNaoLegal": "Date de réévaluation",
			"vidaUtilMeses": "Durée de vie utile (mois)",
			"vidaUtilAnos": "Durée de vie utile (années)",
			"aumentoValor": "Augmentation de la valeur"
		}
	},
	"ativosAquisicao": {
		"title_detail": "Acquisition {{id}}",
		"title_new": "Nouvelle acquisition",
		"title_plural": "Acquisitions",
		"pesquisa": "A chercher",
		"saved": "Acquisition {{id}}, enregistrée avec succès",
		"error": "Acquisition {{id}}, ne peut pas être enregistrée.",
		"deleted": "Acquisition {{id}}, supprimée avec succès",
		"warn": {
			"tooltipTaxa": "Tarif différent du décret réglementaire!",
			"baseFiscDifContab": "Base de calcul de l'impôt autre que comptable",
			"confirmBaseFiscDifContab": "Base de calcul de l'impôt autre que comptable !<br>Voulez-vous continuer ?"
		},
		"fields": {
			"nMapaAmortizacao": "Numéro de plan d'amortissement",
			"codAmortizacao": "Code d'amortissement",
			"dataAquisicao": "Date d'acquisition",
			"dataUtilizacao": "Date d'utilisation",
			"quantidade": "Nombre d'unités (Qté)",
			"grandeReparacao": "C'est une grosse réparation (d'un autre actif)",
			"usado": "Utilisé",
			"nDocExterno": "Doc n°",
			"dataDocExterno": "Date du document.",
			"nContaFornecedor": "Numéro de compte fournisseur",
			"nomeFornecedor": "Nom du fournisseur",
			"contabValorAquisicao": "Valeur d'acquisition",
			"contabTaxaAmortiza": "Taux d'amortissement",
			"contabNumAnosUteis": "Nombre d'années de travail",
			"contabPercReducao": "Perc.",
			"contabValorResidual": "Valeur résiduelle",
			"contabDataDispUso": "Date de disponibilité pour utilisation",
			"contabBaseCalculo": "Base de calcul",
			"contabMetodoCalculo": "Méthode de calcul",
			"fiscalValorAquisicao": "Valeur d'acquisition",
			"fiscalTaxaAmortiza": "Taux d'amortissement",
			"fiscalNumAnosUteis": "Nombre d'années de travail",
			"fiscalPercReducao": "Perc.",
			"fiscalValorResidual": "Valeur résiduelle",
			"fiscalDataInicioUtil": "Date de début d'utilisation",
			"fiscalBaseCalculo": "Base de calcul",
			"fiscalMetodoCalculo": "Méthode de calcul",
			"nContaDoAtivo": "Numéro de compte d'actif",
			"nContaAmortExerc": "N° de compte d'amortissement",
			"nContaAmortAcumu": "N° de compte d'amortissement",
			"nContaIVADedutivel": "Numéro de compte TVA déductible",
			"codIVAAquisicao": "Code TVA d'achat",
			"iVADeduzido": "TVA déduite",
			"codRubOrcam": "Code de ligne budgétaire",
			"nCodRepCC": "Code de distribution",
			"nCCusto": "Centre de coûts n°",
			"nZona": "Zone",
			"nDepto": "Département",
			"nSubde": "Sous-département",
			"tipoArtigo": "Type d'article",
			"nGrFamilia": "Grande famille",
			"nFamilia": "Famille",
			"nSubfa": "Sous-famille",
			"codContab": "Code comptable",
			"classe": "Classe",
			"categoria": "Catégorie",
			"nVendedor": "Vendeur",
			"nRefProcesso": "Processus",
			"nSeccao": "Section"
		},
		"radioOptions": {
			"baseAnual": "Base annuelle",
			"inicioUtilizacao": "Dès le début de l'utilisation",
			"quotasConstantes": "Quotas constants",
			"quotasDecrescentes": "Actions en baisse"
		},
		"tooltip": {
			"contabDataDispUso": "Saisissez le jour 1 du mois de début d'utilisation"
		}
	},
	"ativosabate": {
		"ativosporabater": "Actifs à radier",
		"dataAbate": "Date d'abattage",
		"radicalAbate": "Numéro de compte d'abattage",
		"selecionarativos": "Sélectionnez les actifs à radier",
		"message": {
			"success": "Un massacre enregistré!"
		},
		"table": {
			"codAtivo": "Code actif",
			"designacao": "Désignation",
			"refExterna": "Réf. externe",
			"dataAquisicao": "Dt.",
			"valorAtivoBrutoContab": "Actif brut",
			"valorDepreciacoesAcumuladasContab": "Département accumulé.",
			"ativosporabater": "Actifs à radier",
			"ativosaabater": "Actifs à radier"
		},
		"btn": {
			"realizarabates": "Effectuer des tueries"
		}
	},
	"ativosajustedepantigas": {
		"title": "Ajustement des amortissements cumulés des années précédentes",
		"temcerteza": "Etes-vous sûr de vouloir enregistrer les amortissements manquants des années précédentes et enregistrer la valeur en tant qu'ajustements dans la Comptabilité ?",
		"infoajustes": "Cette fonctionnalité enregistrera les amortissements manquants des années précédentes et publiera la valeur sous forme d'ajustements dans la comptabilité.",
		"message": {
			"success": "Correction d'amortissement enregistrée avec succès !",
			"haveDepEmFalta": "Il ne manque aucun amortissement des années précédentes."
		},
		"fields": {
			"periodo": "Période de libération pour les ajustements",
			"valoremfalta": "Valeur manquante"
		}
	},
	"ativosaquisicaocontab": {
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;'",
			"exitMessage": "En cliquant sur OK, vous quitterez sans enregistrer le document et toutes les modifications que vous avez apportées à ce document seront supprimées."
		},
		"header": {
			"fornecedor": "Fournisseur",
			"totalIva": "TVA totale sur les documents",
			"valorAssociado": "Valeur d'acquisition déjà associée aux actifs existants",
			"total": "Total"
		},
		"btn": {
			"pesquisa": "Rechercher une pièce comptable",
			"adiciona": "Ajouter une autre pièce comptable",
			"guardarAtivos": "Sauvegarder"
		},
		"message": {
			"ativoguardado": "Actif enregistré avec succès !",
			"confirmacao": "Enregistrement de l'acquisition d'actifs",
			"desejaregistaraquisicao": "Voulez-vous enregistrer l’acquisition du bien ?"
		},
		"table": {
			"nLanc": "nLanc",
			"nConta": "Numéro de compte",
			"nome": "Nom",
			"valor": "Valeur d'acquisition",
			"valorCalculado": "Déjà associé",
			"valorCalculado2": "En nouveaux actifs",
			"periodo": "Période",
			"nDiario": "Numéro quotidien",
			"nDocInterno": "Doc n°",
			"dCDescription": "Déb./Cré",
			"nContaDebito": "Dette",
			"nContaCredito": "Crédit",
			"descricao": "Description",
			"designacao": "Désignation",
			"codAtivo": "Code.",
			"nContaDoAtivo": "Numéro de compte d'actif",
			"contabValorAquisicao": "Valeur"
		},
		"tooltip": {
			"novoativo": "Nouvel actif",
			"removedoc": "Supprimer le document",
			"valorEsgotado": "Le prix d'achat de ce compte a déjà été épuisé !",
			"removeAtivo": "Supprimer l'élément"
		},
		"error": {
			"contabValorAquisicaoSup": "La valeur d'acquisition ne peut pas être supérieure à la valeur restante !",
			"fiscalValorAquisicaoSup": "La valeur fiscale d’acquisition ne peut être supérieure à la valeur comptable!",
			"invalidStep": "Il y a des champs qui ne sont pas renseignés ou contiennent une erreur !",
			"somainvalida": "La somme des valeurs d'acquisition {{valorAssociado}} ne coïncide pas avec le total de la doc.",
			"temdeselecionarum": "Il faut sélectionner une acquisition !",
			"naoMesmosRadicais": "Les lignes sélectionnées doivent avoir le même radical !"
		},
		"half": {
			"contasAquisicao": "Comptes d'acquisition",
			"ativos": "Actifs"
		},
		"modal": {
			"ativoaquisicaocontabmodaltitle": "Recherche d'acquisition en comptabilité",
			"saldosIniciais": "Rechercher les soldes d'ouverture"
		}
	},
	"ativosaquisicaoinvestemcurso": {
		"tabs": {
			"aquisicaoPorConta": "Acquisition sur compte",
			"aquisicaoPorDocumento": "Acquisition par document"
		},
		"infocontainvest": "Informations sur le compte d'investissement courant",
		"contasAquisicaoCurso": "Comptes d'acquisition en cours",
		"fields": {
			"nconta": "Compte",
			"saldo": "Solde {{année}}",
			"saldo2": "Solde {{année prochaine}}",
			"transferido": "Transféré",
			"codAtivo": "Code.",
			"designacao": "Description",
			"nContaDoAtivo": "Numéro de compte d'actif",
			"contabValorAquisicao": "Valeur"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;'",
			"exitMessage": "En cliquant sur OK, vous quitterez sans enregistrer le document et toutes les modifications que vous avez apportées à ce document seront supprimées.",
			"desejaregistaraquisicao": "Voulez-vous enregistrer l’acquisition du bien ?",
			"ativoguardado": "Actif(s) enregistré(s) avec succès !"
		},
		"messages": {
			"invalidDateNewAtivo": "La date d'acquisition doit être dans l'année : {{année}} ou {{année suivante}}",
			"invalidvalue": "La valeur ne peut pas être supérieure à : {{value}}",
			"nContaEmpty": "Vous devez rechercher et sélectionner un compte acquéreur !",
			"saldoTranferidoCompleto": "Solde du compte déjà associé aux actifs.",
			"desejaApagarDados": "Changer d'onglet changera la méthode de recherche et effacera les données saisies"
		},
		"tooltip": {
			"novoativo": "Nouvel actif",
			"removedoc": "Supprimer le document",
			"valorEsgotado": "Le prix d'achat de ce compte a déjà été épuisé !",
			"removeAtivo": "Supprimer l'élément"
		},
		"modal": {
			"title": "Recherche continue de comptes d'investissement",
			"fields": {
				"nConta": "Numéro de compte",
				"nome": "Nom",
				"virtualDouble01": "Solde {{année}}",
				"virtualDouble02": "Solde {{année prochaine}}"
			}
		},
		"btns": {
			"search": "Rechercher des comptes de placement en cours"
		}
	},
	"ativosaquisicaoinvestemcursopordoc": {
		"infocontainvest": "Informations sur le compte d'investissement courant par document",
		"listadocs": "Liste des documents",
		"documentos": "Documents",
		"ativos": "Actifs",
		"ativosFieldset": "Liste des actifs à créer",
		"fields": {
			"radical": "Radical",
			"valor": "Valeur",
			"transferido": "Montant transféré",
			"codAtivo": "Code.",
			"designacao": "Description",
			"nContaDoAtivo": "Numéro de compte d'actif",
			"contabValorAquisicao": "Valeur"
		},
		"prompt": {
			"desejaregistaraquisicao": "Voulez-vous enregistrer l’acquisition du bien ?",
			"ativoguardado": "Actif(s) enregistré(s) avec succès !"
		},
		"messages": {
			"invalidvalue": "La valeur ne peut pas être supérieure à : {{value}}",
			"docsEmpty": "Vous devez ajouter au moins un document!",
			"nextStepAtivosList": "Pour ajouter des ressources, cliquez sur le bouton '+' dans la grille 'Liste des ressources à créer'"
		},
		"tooltip": {
			"novoativo": "Nouvel actif",
			"removeAtivo": "Supprimer l'élément"
		},
		"btn": {
			"search": "Rechercher des documents d'investissement en cours",
			"searchMobile": "Rechercher des documents"
		}
	},
	"ativoscoeficientecm": {
		"header": {
			"anoPesquisa": "Année de mise à jour"
		},
		"btn": {
			"pesquisa": "Recherche",
			"grava": "Pour enregistrer"
		},
		"table": {
			"ativo": "Données sur les actifs",
			"coeficientes": "Coefficients",
			"maismenosvalias": "Plus ou moins de valeur",
			"sel": "Sel.",
			"codativo": "Code actif",
			"designacao": "Désignation",
			"dataaquisicao": "Date d'acquisition",
			"dataavenda": "Date de vente",
			"valor": "Valeur",
			"coefatual": "Coef.",
			"coefcorrigido": "Coef.",
			"contab": "Comptabilité",
			"fiscal": "Impôt",
			"fiscalcorrig": "Taxe corrigée",
			"emptyline": "Aucune donnée"
		},
		"message": {
			"temcerteza": "Souhaitez-vous mettre à jour le coefficient sur les biens sélectionnés ?",
			"success": "Les ressources ont été mises à jour avec succès !"
		}
	},
	"ativosconsistencia": {
		"tab": {
			"ativosconsistenciaContab": "Comptabilité sans lien avec les actifs",
			"ativosconsistenciaResumo": "Sommaire par compte",
			"ativosconsistenciaAnaliseConta": "Analyse par compte",
			"ativosconsistenciaPeriodoDiferente": "Actifs avec une période différente",
			"ativosconsistenciaAnosErrados": "Actifs avec la mauvaise année",
			"ativosconsistenciaVendaDiferente": "Ventes au cours des différentes années",
			"ativosconsistenciaDetalhe": "Détail par actif",
			"ativosconsistenciaIgnorados": "Documents ignorés de la relation avec les actifs",
			"ativosajustesgerados": "Ajustements générés",
			"resumoerros": "Résumé des erreurs"
		},
		"table": {
			"codErro": "Code.",
			"nConta": "Numéro de compte",
			"nomeConta": "Nom du compte",
			"periodo": "Période",
			"nDiario": "Tous les jours",
			"nDocInterno": "Doc n°",
			"valorContabilidade": "Valeur comptable",
			"dCAsStr": "Débit/Crédit",
			"valorAtivos": "Valeur des actifs",
			"diferenca": "Différence",
			"contabValorAquisicao": "Valeur comptable",
			"debito": "Dette",
			"credito": "Crédit",
			"nContaDebito": "Dette",
			"nContaCredito": "Crédit",
			"descricao": "Description",
			"valor": "Valeur",
			"tipo0": "Type: actif brut",
			"tipo1": "Type : amortissement cumulé",
			"tipo2": "Type : exercice d’amortissement",
			"tipo3": "Type: autres"
		},
		"analiseconta": {
			"ano": "Année économique",
			"registoMes": "Jusqu'à la période"
		},
		"btn": {
			"lancadifajustes": "Comptabiliser les différences comme ajustements",
			"pesquisa": "A chercher",
			"ignorardocumentossel": "Ignorer les documents sélectionnés",
			"retiradocignoradossel": "Supprimer les documents sélectionnés",
			"tipo": {
				"ativobruto": "Type : actif brut",
				"depreciacaoAcumulada": "Type : amortissement cumulé",
				"depreciacaoExercicio": "Type : exercice d’amortissement",
				"outros": "Type: autres"
			},
			"accao": "Actes",
			"dropdown": {
				"listaativos": "Actifs du compte",
				"ignorardocumento": "Ignorer le document",
				"apagarIgnorados": "Supprimer les documents de ceux ignorés",
				"planocontas": "Plan comptable",
				"extratocontas": "Relevé de compte",
				"aquisicaobydocumento": "Nouvelle acquisition à partir d'un document"
			}
		},
		"modal": {
			"listaativostitle": "Actifs appartenant au compte",
			"periodotitle": "Période de publication des ajustements",
			"periodo": "Période",
			"table": {
				"codAtivo": "Code",
				"designacao": "Désignation",
				"dataAquisicao": "Date d'acquisition",
				"virtualValor": "Valeur"
			}
		},
		"message": {
			"temcertezaignora": "Êtes-vous sûr de vouloir ignorer les documents de liste d'actifs sélectionnés ?",
			"temcertezaretira": "Êtes-vous sûr de vouloir supprimer les documents sélectionnés de ceux ignorés dans la liste des actifs ?",
			"temcertezaajustes": "Êtes-vous sûr de vouloir publier les différences sous forme d'ajustements ?",
			"successLanca": "Différences lancées avec succès !",
			"semerros": "Aucune erreur",
			"comerros": "Il a des erreurs"
		}
	},
	"ativosdepreciacao": {
		"header": {
			"anoDeCalculo": "Année économique à laquelle le calcul se réfère",
			"codAtivoDe": "Code actif",
			"dataAquisicaoDe": "Date d'acquisition",
			"pesquisaAnosAnteriores": "Recherche de biens dépréciés les années précédentes",
			"alertativosdataposterior": "Il existe des actifs dont la date de début d'amortissement est postérieure à la date d'acquisition."
		},
		"btn": {
			"pesquisar": "A chercher",
			"calcular": "Amortissement record",
			"anulaCalculo": "Annuler les amortissements sélectionnés",
			"consultar": "Consulter"
		},
		"tab": {
			"ativosDepreciadosEmAnoAnterior": "Actifs dépréciés les années précédentes",
			"ativosDepreciadosNoAno": "Actifs dépréciés dans l'année",
			"ativosPorDepreciarNoAno": "Actifs à amortir dans l'année",
			"registoMes": "S'inscrire par période"
		},
		"table": {
			"title": {
				"ultimaDepreciacao": "Dernier amortissement",
				"depreciacaoContabilisticos": "Amortissement comptable",
				"depreciacaoFiscal": "Amortissement fiscal"
			},
			"subtitle": {
				"sel": "Sel.",
				"atCodAtivo": "Code actif",
				"atDesignacao": "Désignation",
				"atDataAquisicao": "Date d'acquisition",
				"atEstadoSTR": "État",
				"atDataAbate": "Date d'abattage",
				"atRefExterna": "Réf. externe",
				"ultPeriodo": "Période",
				"valorAquisicaoComResidual": "Valeur d'acquisition",
				"taxaAmortiza": "Taux d'amortissement",
				"percReducao": "% de réduction",
				"valorAnualJaRegistado": "Déjà inscrit",
				"valorAnual": "Valeur à déprécier"
			}
		},
		"message": {
			"calculado": "Actifs non amortis calculés avec succès.",
			"calculoAnulado": "Actifs à amortir amortis avec succès.",
			"percentAbove": "Il existe des pourcentages de réduction de 100 %.",
			"jaforamdepreciadostodosano": "Tous les actifs de l'année ont déjà été dépréciés.",
			"hasujeitosadepreciacaoano": "Il existe des actifs soumis à amortissement pour l'année.",
			"naohanenhumsujeitodepreciacaoano": "Il n’y a aucun actif soumis à amortissement pour l’année."
		},
		"title": {
			"titlePercentAbove": "Calcul des actifs à amortir"
		},
		"tooltip": {
			"selecionarumadep": "Vous devez sélectionner au moins un amortissement!"
		},
		"modal": {
			"dataspostetitle": "Biens avec une date de début d'amortissement très supérieure à la date d'acquisition",
			"table": {
				"codAtivo": "Code",
				"designacao": "Désignation",
				"contabBaseCalculoStr": "Méthode de calcul",
				"dataAquisicao": "Date d'acquisition",
				"contabDataDispUso": "Date de disponibilité"
			}
		}
	},
	"ativosdivisao": {
		"message": {
			"temcerteza": "Etes-vous sûr de vouloir diviser l'actif ?",
			"success": "Actif divisé avec succès !",
			"info": "Cette action permet de diviser un bien en 2. <br> Le bien existant sera mis hors service, et 2 nouveaux seront créés, descendants de l'actuel, qui hériteront de toutes les caractéristiques (dates, acquisition, amortissement)."
		},
		"title": {
			"divisao": "Répartition des actifs"
		},
		"header": {
			"unidades": "Nombre d'unités/qté",
			"valoraquisicao": "Valeur d'acquisition",
			"depacumu": "Dépôt accumulé.",
			"valorliquido": "Valeur nette"
		},
		"fields": {
			"designacao": "Désignation",
			"datadivisao": "Date de division"
		}
	},
	"ativosestado": {
		"data": {
			"normal": "Normale",
			"abatido": "Abattu",
			"vendido": "Vendu",
			"dividido": "Divisé"
		}
	},
	"ativosgeramapasauto": {
		"tableDefinition": {
			"codAtivo": "Code actif",
			"designacao": "Désignation",
			"estadoStr": "État",
			"dataAquisicao": "Date d'acquisition",
			"dataAbateVenda": "Date d'abattage",
			"nMapaAmortizacao": "Carte",
			"naturezaDosAtivosStr": "Nature"
		},
		"btn": {
			"pesquisar": "A chercher",
			"geraativos": "Générer des cartes"
		},
		"tab": {
			"bens": "Marchandises",
			"mapasarquivados": "Cartes archivées"
		},
		"nav": {
			"mapa": "Carte"
		},
		"modal": {
			"jaexistemmapas": "Il existe déjà des cartes générées pour l'année.",
			"jaexistemmapastext": "Voulez-vous continuer et remplacer les cartes existantes ?"
		},
		"message": {
			"mapasgerados": "Cartes générées",
			"benspesquisados": "Marchandises recherchées avec succès.",
			"errorFolderMessage": "Il n'y a pas de cartes archivées ou elles n'ont pas encore été générées."
		}
	},
	"ativoshistorico": {
		"table": {
			"tipoAcontecimentoSTR": "Taper",
			"dataReferencia": "Date de référence",
			"nomeUtilizador": "Utilisateur",
			"stampInsert": "Date/heure d'inscription",
			"observacoes": "Observations"
		},
		"title": {
			"historico": "Histoire"
		}
	},
	"ativosimport": {
		"title_detail": "Actif importé \"{{id}}\"",
		"title_new": "Nouvelle importation",
		"title_plural": "Importations",
		"saved": "Importez {{id}}, enregistré avec succès.",
		"error": "Importer {{id}}, ne peut pas être enregistré.",
		"deleted": "Importer {{id}}, supprimé avec succès",
		"deletedsuccess": "Élément supprimé avec succès",
		"calculoaceite": "Calculs acceptés avec succès",
		"calculobaseanual": "Calculs de base annuels effectués avec succès",
		"calculoinicioutilizacao": "Calculs de mise en service réalisés avec succès",
		"valoreslimposcomsucesso": "Les valeurs attribuées manuellement ont été effacées avec succès",
		"atpercred": "Les valeurs attribuées manuellement ont été effacées avec succès",
		"valsemarredondanual": "Montants sans arrondi annuel distribués avec succès",
		"valoranosanterior": "Valeur attribuée par les années précédentes distribuée avec succès",
		"pesquisa": "Recherche",
		"onlyByImportar": "Affiche uniquement les actifs à importer",
		"table": {
			"semdados": "Aucune donnée",
			"header": {
				"aquisicao": "Acquisition",
				"depacumucontab": "Amortissement cumulé comptable",
				"depacumufiscais": "Amortissement cumulé fiscal",
				"decregulamentar": "Doc. Réglementaire.",
				"contas": "Comptes",
				"associacaocontab": "association comptable",
				"sel": "Sel."
			},
			"estado0": "Normale",
			"estado1": "Abattu",
			"estado2": "Vendu",
			"estado3": "Partager",
			"baseCalculo0": "Base annuelle",
			"baseCalculo1": "Dès le début de l'utilisation",
			"metodoCalculo0": "Quotas constants",
			"metodoCalculo1": "Actions en baisse"
		},
		"fields": {
			"importado": "Importé",
			"codAtivo": "Code actif",
			"designacao": "Désignation",
			"refExterna": "Réf. externe",
			"usado": "Utilisé",
			"estado": "État",
			"dataAbate": "Date d'abattage",
			"valorVenda": "Valeur des ventes",
			"dataAquisicao": "Date d'acquisition",
			"valorAquisicao": "Valeur d'acquisition comptable.",
			"valorAquisicaoFiscal": "Valeur fiscale d'acquisition",
			"mapa": "Carte",
			"dtInicioUtilizacao": "Dt.",
			"baseParaCalculo": "Base de calcul",
			"metodoParaCalculo": "Méthode de calcul",
			"dtUltProcessamento": "Dt.",
			"valoresManuais": "Valeurs manuelles",
			"depAcumulada": "Dépôt accumulé.",
			"depExercicio": "Département d'exercice",
			"percReducao": "% de réduction",
			"depCalculada": "Dép. calculé.",
			"diferenca": "Différence",
			"codDR": "Code DR",
			"viatLigArtigo11DR": "Véhicule léger - Art. 11.º DR",
			"taxaContab": "Frais de comptabilité.",
			"taxaFiscal": "Taux d'imposition",
			"ncontaAtivo": "Compte actif",
			"nomeDaContaDoAtivo": "Nom du compte d'actif",
			"ncontaFornecedor": "Compte fournisseur",
			"nomeDaContaDoFornecedor": "Nom du compte fournisseur",
			"periodo": "Période",
			"diario": "Tous les jours",
			"nDocInterno": "Doc n°",
			"quantidade": "Montant"
		},
		"form": {
			"designacao": "Désignation",
			"refExterna": "Réf. externe",
			"dataAquisicao": "Date d'acquisition",
			"dataInicioUtiliza": "Date de début d'utilisation",
			"valorAquisicao": "Valeur d'acquisition",
			"valorAquisicaoFiscal": "Valeur fiscale d'acquisition",
			"codAmortizacao": "Code d'amortissement",
			"valorResidual": "Valeur résiduelle",
			"nMapaAmortizacao": "Carte",
			"viatLigArtigo11DR": "Véhicule léger - Art. 11.º DR",
			"usado": "Utilisé",
			"taxaAmortizacao": "Frais de comptabilité",
			"percReducao": "% de réduction comptable.",
			"fiscalTaxaAmortiza": "Taux d'imposition",
			"fiscalPercReducao": "% de réduction d'impôt",
			"nContaDoAtivo": "Numéro de compte d'actif",
			"nContaFornecedor": "Numéro de compte fournisseur",
			"baseCalculo": "Base de calcul",
			"metodoCalculo": "Méthode de calcul",
			"estado": "État",
			"dataUltProcessamento": "Dernier traitement",
			"depreciacaoAcumulada": "Amortissement cumulé comptable",
			"depreciacaoExercicio": "Département d'exercice",
			"fiscalDepAcumulada": "Amortissement cumulé fiscal",
			"fiscalDepExercicio": "Exercice du département.",
			"dataAbate": "Date d'abattage/vente",
			"valorVenda": "Valeur des ventes",
			"quantidade": "Montant"
		},
		"radioOptions": {
			"normal": "Normale",
			"abatido": "Abattu",
			"vendido": "Vendu",
			"quotasConstantes": "Quotas constants",
			"quotasDecrescentes": "Actions en baisse",
			"inicioUtilizacao": "Dès le début de l'utilisation",
			"baseAnual": "Base annuelle"
		},
		"steps": {
			"aquisicaoAtivo": "Acquisition",
			"contas": "Comptes",
			"depreciacoes": "Amortissement cumulé"
		},
		"tabs": {
			"porimportar": "Pour l'importation",
			"importados": "Importé"
		},
		"btn": {
			"excel": "Exceller",
			"apagasel": "Supprimer la sélection",
			"delativossel": "Supprimer le diablotin.",
			"delativosselimportados": "Supprimer les actifs importés sel.",
			"dropdown": {
				"editar": "Modifier",
				"aceitarcalculos": "Accepter les calculs",
				"alterarmetodocalculo": "Changer de méthode de calcul",
				"atribuivaloranualmanual": "Attribuer manuellement des valeurs annuelles",
				"distribuivalorsemarredond": "Distribuer les valeurs sans arrondi annuel",
				"distribuivaloranosanterior": "Répartir la valeur attribuée aux années précédentes",
				"atribuipercredu": "Attribuer un pourcentage de réduction",
				"limpavaloratribuid": "Efface les valeurs attribuées manuellement",
				"abrirdoccontab": "Ouvrir une pièce comptable",
				"apagaativo": "Supprimer l'importation d'éléments",
				"associarativos": "Actifs associés",
				"alterarmetodocalculoanual": "Changement de méthode de calcul - base annuelle",
				"alterarmetodocalculoinicio": "Changer de méthode de calcul - début d'utilisation",
				"apagaativoimport": "Supprimer l'élément importé"
			},
			"actions": {
				"criafolhaimportar": "Créer une feuille pour l'importation",
				"importarficheiro": "Importer le fichier"
			}
		},
		"modal": {
			"contasinconsistencias": "Comptes avec des différences entre le grand livre et les actifs",
			"tipo": "Taper",
			"ajustescontastitle": "Documents de régularisation générés",
			"criaficheiroexcel": "Création de fichier Excel",
			"importaficheiro": "Importation de fichiers Excel",
			"ficheirocriadocomsucesso": "Fichier Excel créé avec succès",
			"atpercreducao": "Attribuer un pourcentage de réduction",
			"dadoscontab": "Données comptables",
			"dadosfiscais": "Données fiscales",
			"atvaloranualman": "Attribuer des valeurs manuellement",
			"valoresemcadano": "Valeurs chaque année",
			"difcontab": "Différence comptable",
			"diffiscal": "Différence fiscale",
			"folhaimport": "Création de fichier Excel",
			"colunasfich": "Colonnes du fichier",
			"reavaliacoes": "Réévaluations",
			"filesuccess": "Fichier créé avec succès !",
			"fields": {
				"taxaamort": "Taux d'amortissement",
				"amortacumu": "Amortissement cumulé",
				"percred": "% de réduction",
				"valoraquisicao": "Valeur d'acquisition",
				"inseridomanual": "(Saisi manuellement)",
				"aplicarred": "Appliquer le % de réduction",
				"dadosfiscaisiguais": "Données fiscales égales aux données comptables",
				"todosanos": "Chaque année",
				"perc": "Pourcentage de réduction",
				"nConta": "Numéro de compte",
				"nomeConta": "Nom du compte",
				"valorContabilidade": "Valeur comptable",
				"valorAtivos": "Valeur des actifs",
				"diferenca": "Différence",
				"periodo": "Période",
				"ndiario": "Tous les jours",
				"ndocInterno": "Doc n°"
			},
			"btn": {
				"aplicarpercred": "Appliquer le % de réduction",
				"excel": "Exceller",
				"importarativos": "Importer des actifs",
				"inconsistenciabtn": "Comptabiliser les différences comme ajustements",
				"fechar": "Pour fermer",
				"actions": {
					"criafolhaimportar": "Créer une feuille pour l'importation",
					"importarficheiro": "Importer le fichier"
				}
			},
			"table": {
				"depcontab": "Amortissement comptable",
				"ajuste": "Ajustement",
				"depfiscal": "Amortissement fiscal"
			},
			"msg": {
				"ativosimportados": "Actifs importés!",
				"avisoinconsistenciasfirst": "Il existe des comptes avec des différences entre la comptabilité et l'actif.",
				"avisoinconsistenciassecond": "Si vous le souhaitez, vous pouvez comptabiliser les différences sous forme d'ajustements.",
				"ajustesnascontas": "Actifs importés et ajustements des comptes enregistrés.",
				"desejaimportar": "Voulez-vous importer les actifs ?",
				"haativosdataaquisicao": "Il existe des actifs dont la date d'acquisition se situe dans l'année en cours.",
				"haativoserro": "Certains actifs contiennent des erreurs.",
				"aprepararimportacao": "Préparation du processus d'importation..."
			},
			"camposexport": {
				"basecalculo": "Base de calcul",
				"designacao": "Désignation",
				"mapa": "Carte",
				"CodAmortizacao": "Code d'amortissement",
				"ViatLigArt11DR": "Véhicule léger - Art. 11.º DR",
				"TaxaAmortizacao": "Taux de Dép.",
				"FiscalTaxaAmortizacao": "Taux de Dép.",
				"DataAquisicao": "Date d'acquisition",
				"DataInicioUtiliza": "Date de début d'utilisation",
				"NContaFornecedor": "Numéro de compte fournisseur",
				"ValorAquisicao": "Valeur d'acquisition",
				"ValorAquisicaoFiscal": "Valeur fiscale d'acquisition",
				"NContaDoAtivo": "Numéro de compte actif",
				"PercReducao": "Perc.",
				"FiscalPercReducao": "Perc.",
				"BaseCalculo": "Base de calcul",
				"MetodoCalculo": "Méthode de calcul",
				"DepreciacaoAcumulada": "Amortissement cumulé",
				"DepreciacaoExercicio": "Amortissement pour l'année",
				"FiscalDepreciacaoAcumulada": "Département des impôts accumulés",
				"FiscalDepreciacaoExercicio": "Département de l’année fiscale",
				"DataUltProcessamento": "Dernière date",
				"Usado": "Utilisé",
				"Qtd": "Montant",
				"RefExterna": "Réf. externe",
				"Estado": "État",
				"DataAbate": "Date d'abattage/vente",
				"ValorVenda": "Valeur de vente",
				"ValorResidual": "Valeur résiduelle",
				"NCCusto": "C. Coût",
				"NZona": "Zone",
				"NDepto": "Département",
				"NSubde": "Sous-département",
				"TipoArtigo": "Type d'article",
				"NGrFamilia": "Grande famille",
				"NFamilia": "Famille",
				"NSubfa": "Sous-famille",
				"CodContab": "Code comptable",
				"Classe": "Classe",
				"Categoria": "Catégorie",
				"NVendedor": "Vendeur",
				"NRefProcesso": "Processus",
				"NSeccao": "Section",
				"DL126": "DL126/77",
				"DL24": "DL 24/82",
				"DL219": "DL 219/82",
				"DL399G": "DL399G/84",
				"DL118B": "DL118B/86",
				"DL111": "DL 111/88",
				"DL49": "DL 49/91",
				"DL264": "DL 264/92",
				"DL31": "DL 31/98"
			},
			"importProgressTitle": "Importer"
		},
		"messages": {
			"temQueTerValorAtribuido": "L'année {{ultYear}} doit avoir une valeur attribuée.",
			"temqueselecionarativo": "Vous devez sélectionner au moins un actif."
		},
		"jobImportTimeoutModalTitle": "Importer",
		"jobImportTimeoutModalMessage": "La tâche a expiré."
	},
	"ativosimpressao": {
		"header": {
			"codAtivoDe": "Code actif",
			"nContaDe": "Numéro de compte",
			"nCCustoDe": "N° de centre de coûts",
			"codAmortDe": "Code d'amortissement",
			"codMapaDe": "code de la carte",
			"dataAquisicaoDe": "Date d'acquisition",
			"dataAbateDe": "Date d'abattage",
			"situacao": "Situation",
			"datareferencia": "Date de référence (pour l'état des actifs et l'analyse de la dépréciation)",
			"pesqavancada": "Filtre avancé",
			"estadoUso": "Statut d'utilisation"
		},
		"radioOptions": {
			"todos": "Normal et abattu/vendu dans l'année",
			"dividido": "Divisé",
			"normal": "Normale",
			"abatido": "Abattu",
			"vendido": "Vendu",
			"indiferente": "N'importe quel état",
			"usado": "Utilisé",
			"novo": "Nouveau"
		},
		"table": {
			"aquisicao": "Acquisition",
			"classificadores": "Classificateurs",
			"contas": "Comptes",
			"dadoscontabilisticos": "Données comptables",
			"dadosfiscais": "Données fiscales",
			"abatevenda": "Abattage/Vente",
			"limiteminimofiscal": "Plafond d'impôt minimum",
			"perdasimparidadecontabilistica": "Pertes de valeur comptables",
			"perdasimparidadefiscais": "Pertes de valeur fiscales",
			"valoresperdidosfiscais": "Taxer les valeurs perdues",
			"taxasperdidasfiscais": "Frais de perte fiscale",
			"depreciacoesnaoaceites": "Amortissements non acceptés",
			"imparidadesnaoaceites": "Déficiences non acceptées",
			"depreciacoesarecuperar": "Amortissement à récupérer",
			"depreciacoesrecuperadas": "Amortissements récupérés",
			"imparidadesrecuperadasnoperiodo": "Dépréciations récupérées sur la période",
			"acrescemodelo22": "S'ajoute au modèle 22",
			"deduzmodelo22": "Déductions au modèle 22",
			"valortributavelativosporimpos": "Valeur imposable de l'actif d'impôt différé",
			"valortributaveldopassivoporimpos": "Valeur imposable des impôts différés passifs",
			"valornaoaceitefiscalmenteporreavaliacoes": "Valeur non fiscalement acceptée en raison de réévaluations",
			"impostosdiferidos": "Impôts différés",
			"rendimentosdossubsidios": "Revenu de subvention",
			"impostosdifSubsidios": "Impôts différés sur les subventions",
			"semdados": "Aucune donnée",
			"sub": {
				"codativo": "Code actif",
				"designacao": "Désignation",
				"estado": "État",
				"refexterna": "Réf. externe",
				"usado": "Utilisé",
				"valoraquisicaocontabilisticocomr": "Valeur d'acquisition comptable, avec valeur résiduelle",
				"dataaquisicao": "Date d'acquisition",
				"mapa": "Carte",
				"coddecregulamentar": "Code décembre.",
				"nomeDecRegulamentar": "Nom déc.",
				"taxaDoDecRegulamentar": "Tarif décembre.",
				"viatLigArtigo11": "Viat.",
				"codAtivoPai": "Code actif parent",
				"numContaAtivo": "Numéro de compte actif",
				"numContaDepAcum": "Numéro de compte Dép.",
				"numContaDepExerc": "Numéro de compte Dép.",
				"valAquisicao": "Valeur d'acquisition",
				"valResidual": "Valeur résiduelle",
				"taxaDepreciacaoAdoptada": "Taux d'amortissement adopté",
				"reducao": "Réduction",
				"depNoAno": "Département dans l'année",
				"depAcumNoAnoAnterior": "Accum.",
				"depAcumTotal": "Accum.",
				"valor": "Valeur",
				"coefCorrecaoMonetaria": "Coef.",
				"contaVenda": "Compte de vente",
				"maisMenosValiaContabilistica": "Plus/Moins de valeur comptable",
				"maisMenosValiaFiscal": "Plus/Moins de valeur fiscale",
				"noAno": "Dans l'année",
				"acumNoAnoAnterior": "Accum.",
				"acumTotal": "Accum.",
				"taxaIRC": "Taux IRC",
				"noAnoAnterior": "L'année précédente",
				"situacaoa": "Situation à",
				"situacaoAtDataSTR": "Situation à {{dataReferencia}}",
				"doccontabilidade": "Document comptable (acquisition)",
				"codProcesso": "Code de processus",
				"nomeProcesso": "Nom du processus",
				"codCCusto": "Code C. Coût",
				"nomeCCusto": "Nom C. Coût",
				"metodoCalculo": "Méthode de calcul",
				"baseCalculo": "Base de calcul"
			}
		},
		"btn": {
			"pesquisar": "Afficher en grille",
			"previsualizar": "Aperçu",
			"processar": "Processus",
			"ok": "Pour fermer",
			"dropdown": {
				"depreciacoes": "Consulter les amortissements",
				"fichaativo": "Fiche d'actif",
				"extaquisicao": "Relevé de compte d'acquisition",
				"extexercicio": "Relevé de compte du département.",
				"extacumulada": "Relevé de compte du département.",
				"doccontab": "Document comptable."
			}
		},
		"modal": {
			"ativoimpressaomodaltitle": "Impression d'éléments",
			"ativoimpressaodepmodaltitle": "Détail de la dépréciation des actifs",
			"depresumo": "Résumé",
			"depDetalhe": "Détail",
			"ativoimpressaodocmodaltitle": "Document comptable."
		}
	},
	"ativosmapasoficiais": {
		"header": {
			"codAtivoDe": "Code actif",
			"nContaDe": "Numéro de compte",
			"nCCustoDe": "N° de centre de coûts",
			"codAmortDe": "Code d'amortissement",
			"nMapa": "Carte",
			"dataAquisicaoDe": "Date d'acquisition",
			"dataAbateDe": "Date d'abattage",
			"anoEconomico": "Année économique",
			"situacao": "Situation",
			"registoMes": "Mois",
			"natureza": "Nature"
		},
		"radioOptions": {
			"todos": "Normal et abattu/vendu dans l'année",
			"normal": "Normale",
			"abatido": "Abattu",
			"vendido": "Vendu",
			"qualquer": "N'importe quelle méthode",
			"quotasConstantes": "Quotas constants",
			"quotasDecrescentes": "Actions en baisse",
			"indiferente": "N'importe quel état",
			"usado": "Utilisé",
			"novo": "Nouveau"
		},
		"btn": {
			"previsualizar": "Aperçu",
			"processar": "Processus",
			"ok": "Pour fermer"
		},
		"print": {
			"ativoimpressaomodaltitle": "Impression d'éléments",
			"metodos": "Méthodes",
			"estadouso": "État"
		},
		"natureza": {
			"qualquer": "N'importe quelle nature",
			"tangiveis": "Immobilisations corporelles",
			"intangiveis": "Actifs incorporels",
			"bioNaoConsumiveis": "Actifs biologiques non consommables",
			"propInvetimento": "Propriétés d'investissement"
		},
		"card": {
			"selecao": "Sélection"
		},
		"modal": {
			"title": "Atouts - Carte officielle"
		}
	},
	"ativosmudarcontaativo": {
		"temcerteza": "Êtes-vous sûr de vouloir modifier le compte d'actif ?",
		"title": {
			"mudarcontaativo": "Changer de compte d'actif"
		},
		"fields": {
			"ncontanovo": "Nouveau numéro de compte",
			"ncontaatual": "Numéro de compte courant"
		},
		"message": {
			"success": "Le compte d'actif a été modifié avec succès !"
		}
	},
	"ativosmudarcontadep": {
		"temcerteza": "Etes-vous sûr de vouloir changer de compte ?",
		"title": {
			"mudarcontadepexerc": "Modifier le compte d'amortissement de l'exercice",
			"mudarcontadepacumu": "Modifier le compte d'amortissement de l'exercice"
		},
		"fields": {
			"ncontanovo": "Nouveau numéro de compte",
			"ncontaatual": "Numéro de compte courant"
		},
		"message": {
			"successExercicio": "Le compte d'amortissement de l'année a été modifié avec succès !",
			"successAcumulado": "Le compte d'amortissement cumulé a été modifié avec succès !",
			"jahadep": "Il y a déjà des amortissements constatés pour l'année en cours, il n'est pas possible de changer de compte!",
			"contaVazio": "Le numéro de compte ne peut pas être vide."
		}
	},
	"ativosmudartaxasdep": {
		"temcerteza": "Etes-vous sûr de vouloir modifier les taux d'amortissement ?",
		"title": {
			"mudartaxasdep": "Modifier les taux d'amortissement",
			"depcontab": "Amortissements comptables",
			"depfiscal": "Amortissements fiscaux"
		},
		"fields": {
			"taxanovo": "Nouveau tarif",
			"taxaatual": "Tarif actuel"
		},
		"message": {
			"success": "Les taux d'amortissement ont changé avec succès !"
		}
	},
	"ativosnotacredito": {
		"title": "Remise sur les actifs",
		"header": {
			"fornecedor": "Fournisseur",
			"valoraquisicao": "Valeur d'acquisition",
			"valornotacredito": "Valeur de l'avoir",
			"novovaloraquisicao": "Nouvelle valeur d'acquisition"
		},
		"btn": {
			"pesquisanota": "Rechercher un avoir"
		},
		"table": {
			"nContaDebito": "Dette",
			"nContaCredito": "Crédit",
			"valor": "Valeur",
			"descricao": "Description"
		},
		"modaltable": {
			"nConta": "Numéro de compte",
			"nome": "Nom",
			"valor": "Valeur d'acquisition",
			"valorCalculado": "Déjà associé",
			"valorCalculado2": "En nouveaux actifs",
			"periodo": "Période",
			"nDiario": "Numéro quotidien",
			"nDocInterno": "Doc n°",
			"dCDescription": "Déb./Cré",
			"nContaDebito": "Dette",
			"nContaCredito": "Crédit",
			"descricao": "Description",
			"designacao": "Désignation",
			"codAtivo": "Code.",
			"nContaDoAtivo": "Numéro de compte d'actif",
			"contabValorAquisicao": "Valeur"
		},
		"message": {
			"temdeselecionarum": "Vous devez sélectionner une réduction !",
			"temcerteza": "Etes-vous sûr de vouloir associer l'avoir et réduire la valeur nette du bien ?",
			"success": "Remise associée avec succès!"
		},
		"submodal": {
			"title": "Rechercher un avoir"
		}
	},
	"ativosvenda": {
		"ativosporvender": "Actifs à vendre",
		"datavenda": "Date de vente",
		"selecionarativos": "Sélectionnez les actifs à vendre",
		"distribuicaovalor": "Répartition de la valeur de vente entre les actifs",
		"radiooptions": {
			"liquido": "Proportionnel à la valeur nette",
			"bruto": "Proportionnel à la valeur brute",
			"manual": "Attribuer manuellement"
		},
		"message": {
			"success": "Vente enregistrée!",
			"selecionarfatura": "Vous devez sélectionner une vente",
			"docnaosaldado": "La pièce comptable sélectionnée n'est pas équilibrée !"
		},
		"steps": {
			"tabeladocumentos": "Recherche de nouvelles ventes en comptabilité",
			"detalhesparavenda": "Valeurs et comptes de la vente de l'actif"
		},
		"card": {
			"valorsemiva": "Valeur sans TVA",
			"valordoiva": "Valeur TVA",
			"total": "Total",
			"docliente": "Du client",
			"maismenosvalia": "Plus/Moins de valeur",
			"iva": "T.V.A.",
			"contas": "Comptes",
			"valores": "Valeurs",
			"coef": "Coef."
		},
		"table": {
			"codAtivo": "Code actif",
			"designacao": "Désignation",
			"refExterna": "Réf. externe",
			"dataAquisicao": "Dt.",
			"valorAtivoBrutoContab": "Actif brut",
			"valorDepreciacoesAcumuladasContab": "Département accumulé.",
			"ativosporvender": "Actifs à vendre",
			"ativosavender": "Actifs à vendre",
			"valorVenda": "Valeur des ventes"
		},
		"tooltip": {
			"valorNaoValido": "La somme des valeurs de vente des actifs n'est pas égale à la valeur du document !"
		},
		"btn": {
			"realizarvenda": "Faire une vente",
			"pesquisafaturavenda": "Rechercher une facture de vente"
		},
		"error": {
			"invalidStep": "Vous devez sélectionner un document pour enregistrer la vente !"
		}
	},
	"auditlogin": {
		"fields": {
			"username": "Nom d'utilisateur",
			"centralGestName": "Installation",
			"cgId": "Code d'installation",
			"ip": "IP",
			"action": "Action",
			"stampInsert": "Date",
			"nEmpresa": "numéro d'entreprise",
			"nomeEmpresa": "Nom de l'entreprise"
		}
	},
	"contratosservicos": {
		"title_detail": "Alliance {{id}}",
		"title_new": "Nouvelle Alliance",
		"title_plural": "Alliances",
		"pesquisa": "A chercher",
		"saved": "Covenant {{id}}, enregistré avec succès",
		"error": "Covenant {{id}}, ne peut pas être sauvegardé.",
		"deleted": "Covenant {{id}}, supprimé avec succès",
		"fields": {
			"cab": {
				"refCtrCab": "Code de réception",
				"nClasseCtr": "Classe",
				"classeCtrNome": "nom de classe",
				"nClasseCtrPlaceholder": "Catégorie d'allocation",
				"nTipoContrato": "Taper",
				"tipoContratoNome": "Tapez le nom",
				"nTipoContratoPlaceholder": "Type de contrat",
				"nContrato": "Numéro de reçu",
				"nContratoPlaceholder": "Numéro de reçu",
				"verContrato": "Version",
				"verContratoPlaceHolder": "Version du reçu",
				"nContratoAsString": "Engagement",
				"nContratoSemVersaoAsString": "Engagement",
				"descricao": "Description",
				"descricaoPlaceHolder": "Description de l'accord",
				"nClifo": "Client",
				"nClifoPlaceHolder": "Client",
				"clifoNome": "Nom du client",
				"idIdeChav": "Groupe de comptes",
				"idIdeChavPlaceHolder": "Groupe de comptes",
				"dataCtr": "Date de paiement",
				"dataCtrPlaceHolder": "Date de paiement",
				"dataInicio": "Date de début",
				"dataInicioPlaceHolder": "Date de début",
				"dataFim": "Date de fin",
				"dataFimPlaceHolder": "Date de fin",
				"estado": "État",
				"estadoPlaceHolder": "Code d'état",
				"ccusto": "centre de coûts",
				"ccustoPlaceHolder": "Code du centre de coûts",
				"nRefProcesso": "Processus",
				"nRefProcessoPlaceHolder": "Code de processus",
				"codMoeda": "Pièce de monnaie",
				"codMoedaPlaceHolder": "Code devise",
				"refExterna": "Référence externe",
				"terminado": "Fini",
				"totalLiquido": "Valeur",
				"valorGlobal": "Valeur globale",
				"codCondComerciais": "État commercial",
				"codCondComerciaisPlaceHolder": "Code des conditions commerciales",
				"estadoContrato": "État",
				"estadoContratoPlaceHolder": "Statut du contrat"
			},
			"linhas": {
				"nArtigo": "Code article",
				"nomeArtigo": "Description",
				"qtd1": "Quantité",
				"prVenda": "Unité Pr.",
				"d1": "D1",
				"d2": "D2",
				"d3": "D3",
				"desconto": "Rabais",
				"d4": "D4",
				"d5": "J5",
				"d6": "D6",
				"codIva": "Code TVA",
				"taxaIva": "Taux de TVA",
				"nArmazem": "Stockage",
				"cunimo": "Articles.",
				"qtdPUnid": "Qté / Unités",
				"nVendedor": "Vendeur",
				"ccusto": "centre de coûts",
				"nZona": "Zone",
				"nDepto": "Département",
				"nSubde": "Sous-département",
				"qtd1Fact": "Quantité facturée",
				"nRefProcesso": "Processus",
				"estado": "État",
				"obs": "Observations",
				"valorLiquido": "Liquide"
			}
		},
		"tabs": {
			"linhas": "Lignes"
		},
		"config": {
			"verGrupoConta": "Afficher le compte du groupe",
			"verMoeda": "Afficher la devise",
			"verRefExterna": "Afficher la référence externe",
			"verCondComerciais": "Afficher les conditions de trading",
			"verVendedor": "Afficher le vendeur",
			"verNCCusto": "Afficher le centre de coûts",
			"verD1": "Afficher D1",
			"verArmazem": "Afficher l'entrepôt",
			"verUnidadeMovimento": "Afficher l'unité de mouvement"
		},
		"errors": {
			"notSelected": "Il n'y a aucun contrat sélectionné",
			"terminado": "L'accord {{avenca}} est déjà terminé",
			"notTerminado": "L'accord {{avenca}} n'est pas terminé"
		},
		"btn": {
			"configurar": "Configurer"
		},
		"info": {
			"terminado": "Fini",
			"reabrir": "Réouvrir le contrat",
			"terminar": "Fin du contrat",
			"apagar": "Supprimer le contrat"
		},
		"message": {
			"temCertezaReabrir": "Êtes-vous sûr de vouloir rouvrir l'accord {{avenca}} ?",
			"temCertezaTerminar": "Êtes-vous sûr de vouloir mettre fin à l'accord {{avenca}} ?",
			"temCertezaApagar": "Êtes-vous sûr de vouloir supprimer l'avenca {{avenca}} ?"
		},
		"modulos": {
			"classes": "Classes d'alliance",
			"tipos": "Types d'engagements",
			"faturacao": "Facturation"
		},
		"modal": {
			"faturacaoAuto": {
				"title": "Facturation automatique",
				"cards": {
					"configurar": "Type de contrat à configurer"
				}
			}
		}
	},
	"avisoscobranca": {
		"fields": {
			"clifoNaoPodeFacturar": "Je ne peux pas facturer",
			"dataDocDe": "Date du document.",
			"dataPrimeiroVencimento": "1ère échéance",
			"dataUltimoAvisoProc": "Dernière date/heure",
			"dataUltimoComentario": "Dernière date",
			"dataUltimoRecebimento": "Dernière date",
			"dataVencimento": "Date d'échéance",
			"dataVencimentoDe": "Date d'échéance de",
			"dividaVencida": "Dette en souffrance",
			"emailDestino": "E-mail",
			"grupoConta": "Compte de groupe",
			"grupoContaStr": "Description gr.",
			"nCondPagamDe": "Numéro de condition de paiement",
			"nConta": "Numéro de compte",
			"nContaDe": "Numéro de compte",
			"nContaNome": "Nom",
			"nDiario": "Tous les jours",
			"nDiasUltimoRecebimento": "Dernier nombre de jours",
			"nDocExterno": "Doc n°",
			"nDocInterno": "Numéro du document",
			"nDocsPorRegularizar": "Nombre de documents à régulariser",
			"nVendedorDe": "Numéro du vendeur de",
			"observacoes": "Observations",
			"periodo": "Période",
			"ultimoComentario": "Dernier commentaire",
			"valor": "Valeur",
			"valorCredito": "Crédit",
			"valorDebito": "Dette",
			"valorPago": "Montant payé",
			"valorPorPagar": "Montant à payer"
		},
		"btn": {
			"previsualizar": "Aperçu",
			"sendMail": "Envoyer un e-mail",
			"pesqinterrompido": "Recherche avec traitement interrompu"
		},
		"modal": {
			"avisoscobranca": "Avis de facturation",
			"avisoscobrancaemail": "Envoyer par e-mail les avis de facturation sélectionnés",
			"avisoscobrancaAlterarEmailProntual": "Changer d'e-mail de temps en temps",
			"erros": {
				"title": "Liste des erreurs",
				"noEmails": "Aucun compte n'a été trouvé avec des transactions en attente pour lesquelles une adresse e-mail est configurée"
			},
			"emissora": {
				"configuradaEasyPay": "L'émetteur de référence MB est configuré: EasyPay",
				"defaultReport": "Rapport par défaut",
				"desativado": "Désactivé",
				"easypay": "EasyPayOnline",
				"emissaorefmb": "Émission de référence Multibanco",
				"emissora": "Diffuseur",
				"emitirAvisosCobranca": "Souhaitez-vous émettre des références MB dans les avis de facturation ?",
				"naoExisteConfig": "Il n’y a pas d’émetteur de référence MB actif",
				"title": "Configuration des avis de facturation"
			}
		},
		"messages": {
			"erroRadicais": "Vous ne pouvez rechercher des clients que dans ce module (Comptes commençant par 22).",
			"naoselconta": "Vous n'avez sélectionné aucun compte",
			"estadochangedsuccess": "Le statut a été modifié avec succès !",
			"procseminterrompido": "Il existe des enregistrements avec un traitement interrompu.",
			"contaSemMail1": "Les comptes sélectionnés suivants n'ont pas d'adresse e-mail attribuée :",
			"contaSemMail2": "Comptez-vous continuer ?",
			"allContasSemMail": "Aucune adresse e-mail de compte n'est configurée",
			"savedsuccessfully": "Données enregistrées avec succès !",
			"emailSentSuccessfully": "E-mail envoyé avec succès."
		},
		"actions": {
			"cliente": "Client",
			"extratoconta": "Relevé de compte",
			"movaberto": "Mouvements ouverts",
			"previsualiza": "Aperçu de la liste",
			"naopodefaturar": "Changer le statut du client en \"Impossible de facturer\"",
			"podefaturar": "Changer le statut du client en « Peut facturer »",
			"doccontab": "Afficher le document.",
			"doccomercial": "Afficher le document.",
			"alterarEmailPontual": "Changer d'e-mail de temps en temps"
		},
		"config": {
			"showVendedor": "Afficher le vendeur",
			"showCondPagamento": "Afficher les conditions de paiement"
		},
		"datagrid": {
			"legend": {
				"campoAlterado": "Champ modifié occasionnellement"
			}
		}
	},
	"balancetes": {
		"title": "Bilans",
		"fields": {
			"doPeriodo": "période de",
			"deConta": "compte",
			"deCCusto": "C. Coût de",
			"planoAlt": "Plan alternatif"
		},
		"naoTemTipoConta": "Vous devez sélectionner au moins un type de compte à traiter !",
		"btn": {
			"processar": "Traiter en PDF",
			"processarexcel": "Traiter et exporter Excel",
			"processargrelha": "Processus",
			"exportardefir": "Exporter le défir",
			"exportargabmov": "Exporter Gabmov",
			"dropdown": {
				"planocontas": "Plan comptable",
				"extratosgrid": "Relevés de compte",
				"historicoextratos": "Historique des relevés de compte",
				"clifos": "Clients et fournisseurs",
				"movaberto": "Mouvements ouverts",
				"extratoPlanoAlt": "Extraits de plans alternatifs",
				"acumuPlanoAlt": "Accumulé du plan alternatif"
			}
		},
		"messages": {
			"withoutData": "Aucune donnée à présenter!"
		},
		"modal": {
			"title": "Balance de vérification comptable",
			"tipoperfil": "Profil",
			"pesq": "Recherche",
			"pesquisar": "A chercher",
			"table": {
				"empresa": "{{nempresa}} - {{descempresa}}",
				"descano": "{{year}} - {{periodDesc}} ({{fromperiod}} à {{untilperiod}})",
				"conta": "Compte",
				"movperiodo": "Mouvements de la période",
				"movacumu": "Mouvements accumulés",
				"total": "Totaux",
				"semdados": "Aucune donnée",
				"header": {
					"nconta": "Numéro de compte",
					"nome": "Nom",
					"debito": "Dette",
					"credito": "Crédit",
					"saldodebito": "Solde débiteur",
					"saldocredito": "Solde créditeur",
					"saldogeral": "Bilan général"
				}
			},
			"tipocontacontab": {
				"0": "Mouvement",
				"1": "Total",
				"2": "Raison",
				"3": "Classe"
			}
		},
		"tipoperfil": {
			"data": {
				"todas": "Toutes les colonnes",
				"saldo": "Colonnes de solde"
			}
		},
		"tab": {
			"reportpdf": "PDF",
			"reportgrelha": "Gril",
			"grelhaprocessar": "Il faut traiter le grill !"
		}
	},
	"balancetesanalitica": {
		"periodoDe": "période de",
		"nContaCGeralDe": "compte",
		"cCustoDe": "C. Coût de",
		"zonaDe": "Zone",
		"departamentoDe": "Département de",
		"subDepartDe": "Sous.",
		"familiaDe": "famille de",
		"grFamiliaDe": "Grande famille de",
		"subFamiliaDe": "Sous.",
		"codContabDe": "code comptable de",
		"tipoArtigoDe": "Type d'article",
		"classeDe": "Classe de",
		"categoriaDe": "Catégorie de",
		"vendedorDe": "Vendeur",
		"processoDe": "Processus"
	},
	"bancos": {
		"title_detail": "Banque {{id}}",
		"title_new": "Nouvelle banque",
		"title_plural": "Identification bancaire",
		"pesquisa": "A chercher",
		"saved": "Identification bancaire {{id}}, enregistrée avec succès.",
		"error": "L'identification bancaire {{id}} ne peut pas être enregistrée.",
		"deleted": "ID bancaire {{id}}, supprimé avec succès",
		"fields": {
			"codBanco": "Code",
			"nome": "Nom",
			"designaBreve": "Brève désignation",
			"idInterBancaria": "Identification interbancaire",
			"nomeInterBancaria": "Nom de l'identification interbancaire",
			"codSwift": "Code rapide"
		}
	},
	"bancosextrato": {
		"config": {
			"autoSuggestDoc": "Suggérer automatiquement la version"
		},
		"fields": {
			"bankAccount": "Compte bancaire",
			"description": "Description",
			"amount": "Valeur",
			"predefinido": "Pré-défini",
			"observacoes": "Observations",
			"saldo": "Solde courant",
			"dataSyncDados": "Date de synchronisation",
			"nConta": "Numéro de compte",
			"nomeBanco": "Nom de la banque",
			"iban": "IBAN",
			"visivel": "Visible",
			"diarioRecebimentos": "Journal des recettes",
			"diarioPagamentos": "Paiements quotidiens"
		},
		"actions": {
			"refreshAccount": "Faire une demande de mise à jour de votre solde et de votre relevé de compte bancaire (effectué automatiquement toutes les 6 heures)",
			"editBankAccount": "Modifier le compte bancaire",
			"addBankAccount": "Ajouter des comptes bancaires d'une nouvelle banque",
			"importByReconciliation": "Importer un état de rapprochement bancaire",
			"search": "A chercher",
			"viewDocument": "Afficher le document",
			"ignoreDocuments": "Ignorer les lignes de relevé sélectionnées",
			"stopIgnoreDocuments": "Arrêtez d'ignorer la ligne de déclaration sélectionnée",
			"addPredefDesc": "Attribuer une description prédéfinie ou un compte par relevé",
			"editPredefDesc": "Modifier une affectation ou un compte prédéfini par description de relevé",
			"removePredefDesc": "Supprimer une affectation ou un compte prédéfini par description de relevé",
			"mergeMode": "Joindre les lignes d'instruction en mode complet",
			"fullExtract": "Voir l'extrait complet (montre également déjà lancé et ignoré)",
			"doReceipt": "Faire un reçu",
			"doPayment": "Effectuer le paiement",
			"configurations": "Configuration générale",
			"suggestDoc": "Suggérer une version",
			"lancarDocs": "Publier des documents",
			"lancarDocsEmSerie": "Documents de lancement en série",
			"selectAll": "Tout sélectionner",
			"selectAllMesmoPredefinido": "Sélectionnez des lignes avec le même prédéfini",
			"selectAllComPredefinido": "Sélectionnez toutes les lignes qui ont une configuration prédéfinie ou de compte",
			"selectAllComDocDigital": "Sélectionne tous les documents avec le document numérique correspondant",
			"selectAllMesmaConfgPorDesc": "Sélectionne toutes les lignes avec la même configuration par description",
			"deselectAll": "Effacer la sélection",
			"seeSimilarPostedDocs": "Voir les documents similaires déjà publiés"
		},
		"actionssimple": {
			"addPredefDesc": "Attribuer un compte par description du relevé",
			"editPredefDesc": "Modifier l'imputation par description du relevé",
			"removePredefDesc": "Supprimer l'imputation par description du relevé"
		},
		"text": {
			"noLicense": "L'entreprise actuelle n'a pas activé la licence d'automatisation bancaire.",
			"accessDenied": "Vous n'avez pas les autorisations pour accéder à l'automatisation bancaire.",
			"accessDeniedStore": "Vous n'avez pas les autorisations pour accéder à l'automatisation bancaire, veuillez contacter votre responsable pour gérer l'accès.",
			"activate": "Cliquez ici pour activer.",
			"selectedTransactions": "Opérations sélectionnées",
			"confirmEditBankAccount": "Ce compte bancaire n'a pas encore de compte bancaire du plan comptable attribué.<br/>Voulez-vous l'attribuer maintenant ?",
			"confirmLancarDocsEmSerie": "Etes-vous sûr de vouloir publier les documents sélectionnés ?",
			"confirmLancarDocsEmSerieContabilidadeDigital": "Vous avez choisi de déposer des documents en série dans une entreprise disposant d'archives numériques.<br/>Le système joindra automatiquement les documents numériques proposés, veuillez vérifier s'ils sont correctement classés.<br/>Etes-vous sûr de vouloir publier le documents sélectionnés ?",
			"successRefreshBankAccount": "La demande d'actualisation du solde et du relevé de compte bancaire a été effectuée avec succès.",
			"successUpdateBankAccount": "Compte bancaire mis à jour avec succès.",
			"successAddBankAccount": "Compte bancaire ajouté avec succès.",
			"successLancarDocsEmSerie": "Documents lancés avec succès.",
			"successIgnoreDocuments": "Les lignes de déclaration ont été ignorées avec succès.",
			"successStopIgnoreDocuments": "La ligne de déclaration a été ignorée avec succès.",
			"confirmIgnoreDocumentsTitle": "Ignorer les lignes de relevé sélectionnées",
			"confirmIgnoreDocumentsMessage": "Êtes-vous sûr de vouloir ignorer les lignes de relevé sélectionnées ?",
			"confirmDeletePredefDescTitle": "Supprimer la configuration par description de l'instruction",
			"confirmDeletePredefDescMessage": "Êtes-vous sûr de vouloir supprimer la configuration par description d'instruction ?",
			"deletePredefDesc": "Configuration par description d'extrait supprimée avec succès.",
			"promptPrintReceipt": "Voulez-vous imprimer le reçu?",
			"promptPrintPayment": "Voulez-vous imprimer le paiement?",
			"bankAccountConsentExpiredTitle": "Les identifiants pour accéder à la banque sont expirés",
			"bankAccountConsentExpiredMessage": "L'identifiant pour accéder à la banque a expiré.",
			"infoEditBankAccountDiarioInheritance": "*Si vous ne configurez pas les journaux, les journaux de la configuration générale seront utilisés"
		},
		"legend": {
			"lancado": "Déjà publié",
			"ignorado": "Ignoré",
			"comDocumentoSemelhante": "Il existe un document déjà publié à cette date avec la même valeur",
			"comConfig": "Avec configuration prédéfinie ou compte"
		},
		"legendsimple": {
			"comConfig": "Avec configuration de compte"
		},
		"estado": {
			"0": "Déclaration à publier",
			"1": "Extrait publié",
			"2": "Extrait ignoré",
			"3": "Extrait à paraître avec doc.",
			"naoLancado": "Déclaration à publier",
			"lancado": "Extrait publié",
			"ignorado": "Extrait ignoré",
			"naoLancadoComDocPossivel": "Extrait à paraître avec doc."
		},
		"docDigital": {
			"0": "Aucun document possible",
			"1": "Avec un éventuel document",
			"2": "Avec plusieurs documents possibles",
			"naoTem": "Aucun document possible",
			"tem": "Avec un éventuel document",
			"varios": "Avec plusieurs documents possibles"
		},
		"dataRegistoRecebimentosPagamentos": {
			"label": "Date de sortie des reçus et paiements",
			"dataAtual": "Date actuelle",
			"dataMovimentoBancario": "Date du mouvement bancaire"
		},
		"errors": {
			"licenseNotActivatedCGOn": "L'entreprise actuelle n'a pas activé la licence d'automatisation bancaire.<br/>Veuillez vous rendre dans la <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\">boutique</a> CentralGest Cloud et activer ou renouveler la licence d'automatisation bancaire.",
			"licenseCGOnExpired": "Votre licence d'automatisation bancaire a expiré.",
			"activateCGBankingLimitReached": "Vous ne pouvez pas ajouter plus d'entreprises à votre licence d'automatisation bancaire, car vous avez déjà des entreprises \"{{consommées}}\" \"{{total}}\" enregistrées.",
			"activateCGBankingCGOnMode": "Vous ne pouvez pas activer l'automatisation bancaire, car il s'agit d'une société CGOn.",
			"emptyNewBankAccountUrl": "Impossible d'obtenir l'adresse pour ajouter un nouveau compte bancaire.",
			"emptyRenewBankAccountUrl": "Impossible d'obtenir l'adresse pour renouveler les informations d'identification du compte bancaire.",
			"newBankAccount": "Impossible d'ajouter le compte bancaire : \"{{error}}\".",
			"emptyBankAccount": "Vous devez indiquer un compte bancaire.",
			"emptyDocs": "Vous n'avez sélectionné aucun document à publier."
		},
		"errorsTinkLink": {
			"BAD_REQUEST": "L'URL du lien Tink est mal configurée",
			"AUTHENTICATION_ERROR": "Vous n'avez pas réussi à vous authentifier auprès de l'institution financière",
			"INTERNAL_ERROR": "Erreur interne dans le service Tink",
			"TEMPORARY_ERROR": "Erreur temporaire dans le service Tink."
		},
		"errorsUnifiedPost": {
			"BAD_REQUEST": "L'URL UnifiedPost est mal configurée",
			"AUTHENTICATION_ERROR": "Vous n'avez pas réussi à vous authentifier auprès de l'institution financière",
			"INTERNAL_ERROR": "Erreur interne dans le service UnifiedPost",
			"TEMPORARY_ERROR": "Erreur temporaire dans le service UnifiedPost."
		},
		"configmodal": {
			"title": "Configuration générale",
			"success": "Configuration enregistrée avec succès",
			"fields": {
				"descricao": "Description par défaut",
				"movAbAuto": "Ouvre automatiquement les transactions en attente lors du passage à l'enregistrement suivant",
				"showDescritivo": "Champ de description visible"
			}
		},
		"activatelicensemodal": {
			"title": "Activer l'automatisation bancaire dans l'entreprise \"{{nEmpresa}} - {{nomeEmpresa}}\"",
			"message1": "Si vous cliquez sur « Oui », le système ajoutera une nouvelle entreprise à votre licence d'automatisation bancaire et il ne sera pas possible d'inverser la situation par la suite.",
			"message2": "À l'heure actuelle, vous pouvez toujours ajouter {{totalToConsume}} entreprises à votre licence d'automatisation bancaire.",
			"dadosLicenca": "Données de licence d'automatisation bancaire",
			"total": "Nombre total d'entreprises : {{total}}",
			"totalConsumed": "Nombre total d'entreprises déjà ajoutées : {{totalConsumed}}",
			"totalToConsume": "Nombre total d'entreprises que vous pouvez encore ajouter : {{totalToConsume}}"
		},
		"authorizationsmodal": {
			"title": "Consentements",
			"fields": {
				"externalId": "Identifiant",
				"accountsId": "Identifiant du compte",
				"credentialsId": "Identifiant des informations d'identification",
				"providerName": "Nom du fournisseur",
				"status": "Statut",
				"errorMessage": "Message",
				"errorType": "Taper",
				"errorReason": "Raison",
				"statusUpdated": "Date",
				"sessionExpiryDate": "Date d'expiration"
			},
			"text": {
				"deleteConsent": "Supprimer le consentement",
				"deleteConsentMessage": "Etes-vous sûr de vouloir supprimer ce consentement ?<br/>Vous devrez donner un nouveau consentement après avoir effectué cette opération.",
				"successDeleteConsent": "Consentement supprimé avec succès"
			}
		},
		"docssemelhantesmodal": {
			"title": "Documents similaires déjà comptabilisés en comptabilité",
			"fields": {
				"periodo": "Période",
				"diario": "Tous les jours",
				"nDocInterno": "Numéro du document",
				"nDocExterno": "Doc n°",
				"dataLancamento": "Date de sortie",
				"dataDocExterno": "Date du document",
				"dc": "ANNONCE",
				"valor": "Valeur",
				"descricao": "Description",
				"contaCorrente": "Compte courant"
			},
			"text": {
				"mostrarOutrasDatas": "Afficher les documents avec la même valeur mais publiés avec des dates différentes"
			},
			"actions": {
				"connect": "Associé"
			}
		},
		"editbankaccountmodal": {
			"title": "Modification du compte bancaire \"{{nameBank}}\"",
			"fields": {
				"descricao": "Description",
				"nomeBanco": "Nom de la banque",
				"iban": "IBAN",
				"visivel": "Visible",
				"conta": "Compte bancaire"
			},
			"text": {
				"mostrarOutrasDatas": "Afficher les documents avec la même valeur mais publiés avec des dates différentes"
			},
			"actions": {
				"connect": "Associé"
			}
		},
		"unifiedPostModal": {
			"title": "Ajouter un compte",
			"fields": {
				"iban": "IBAN",
				"nometitular": "Nom du titulaire"
			}
		},
		"importbyreconciliationmodal": {
			"title": "Choisir quel rapprochement bancaire importer"
		},
		"movabmodal": {
			"titleReceipt": "Reçus à remettre au client",
			"titlePayment": "Paiements à effectuer au fournisseur",
			"save": "Registre",
			"fields": {
				"nContaClient": "Compte client",
				"nContaSupplier": "Compte fournisseur",
				"valorExtrato": "Valeur du mouvement bancaire",
				"valorSelecionado": "Valeur sélectionnée",
				"nDocumento": "Document",
				"descricao": "Description",
				"nDocExterno": "Doc n°",
				"dataDoc": "Date du document.",
				"dataVencimento": "Date d'expiration",
				"dc": "Débit/Crédit",
				"valor": "Valeur",
				"valorPago": "Montant payé",
				"valorPorPagar": "Montant à payer",
				"valorAReceber": "Montant à recevoir",
				"valorAPagar": "Montant à payer",
				"valorDesconto": "Rabais",
				"data": "Date"
			},
			"text": {
				"receberTudoReceipt": "Tout recevoir (raccourci \"+\" ou \"F9\")",
				"receberTudoPayment": "Payer tout (raccourci \"+\" ou \"F9\")",
				"limparValor": "Effacer la valeur",
				"successReceipt": "Reçu enregistré avec succès",
				"successPayment": "Paiement enregistré avec succès"
			},
			"errors": {
				"recPagAmmountNoMatch": "La valeur sélectionnée \"{{valorSelected}}\" est différente de la valeur de la transaction bancaire \"{{amount}}\"."
			}
		},
		"predefdescmodal": {
			"title": "Attribuer une description prédéfinie ou un compte par relevé",
			"titlesimple": "Attribuer un compte par description du relevé",
			"fields": {
				"descriptionKey": "La description contient",
				"conta": "Compte de compensation",
				"predefinido": "Prédéfini"
			}
		},
		"simplemodefilter": {
			"filter": "Filtre",
			"onlyentradas": "Voir les entrées uniquement",
			"onlysaidas": "Voir les sorties uniquement",
			"onlynaoLancado": "Afficher uniquement les documents inédits",
			"onlylancado": "Voir uniquement les documents déjà publiés"
		}
	},
	"blockedPlugin": {
		"text": {
			"noAccessModule": "Vous n'avez pas accès à ce module en raison de :",
			"noAccess": "Cette fonctionnalité n'est pas disponible pour les raisons suivantes :",
			"noLicenseSingle": "Votre licence CentralGest doit avoir le composant {{portals}} actif.",
			"noLicenseMulti": "Votre licence CentralGest doit avoir l'un des composants suivants actifs : {{portals}}.",
			"noLicenseStoreMode": "L'entreprise actuelle ne dispose pas d'une licence {{portals}} active ou elle est expirée.<br/>Veuillez vous rendre sur <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\" CentralGest Cloud >stocker</a> et activer la licence requise.",
			"requiredRolesSingle": "Ce module nécessite un accès au portail {{portals}}.",
			"requiredRolesMulti": "Ce module nécessite l'accès aux portails {{portals}}."
		}
	},
	"cae": {
		"fields": {
			"codCAE": "Code",
			"nome": "Nom",
			"codSubSec": "Code de sous-section"
		}
	},
	"calcPrVenda": {
		"pesquisa": "Types de calcul",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"marginOnSellingPrice": "Marge sur prix de vente",
			"marginOnPurchasePrice": "Marge sur prix d'achat"
		}
	},
	"calculodiferimentos": {
		"intervalo": "Intervalle",
		"filtrosCalculo": "Filtres de calcul",
		"fields": {
			"valor": "Valeur",
			"dataini": "Date de début",
			"datafim": "Date de fin",
			"tipodiferimento": "Méthode",
			"tipoDataRefDiferimento": "Date de calcul"
		},
		"metodos": {
			"anual": "Annuel",
			"mensal": "Mensuel"
		},
		"tipoDataRef": {
			"dataDoDocumento": "Date du document",
			"dataDoLancamento": "Date de sortie"
		},
		"success": "Calcul effectué avec succès",
		"linhas": {
			"dataDoc": "Date du document.",
			"ano": "Année civile",
			"numeroDias": "Nombre de jours",
			"valor": "Valeur"
		},
		"btn": {
			"lancacontabilidade": "Appliquer au document"
		},
		"messages": {
			"naobalanceado": "Le document n'est pas équilibré.",
			"semcontaparadif": "Il n'a pas été possible de trouver un compte avec un montant à ajouter/différer."
		},
		"modals": {
			"config": {
				"title": "Paramétrage des régularisations et des reports",
				"cardrendimentos": "Revenu",
				"cardgastos": "Dépenses",
				"fields": {
					"novoRadical": "Nouveau radical",
					"radicalrendimentos": "Radical",
					"contaAcrescimos": "Compte de régularisation",
					"contaDiferimentos": "Reports de comptes",
					"radicalgastos": "Radical",
					"contadif": "Compte de report",
					"contaacresc": "Compte de régularisation",
					"calculo": "Calcul",
					"nDiario": "Numéro de journal",
					"descritivoAnulacaoInicial": "Description pour l'annulation de la version initiale",
					"descritivo": "Description de la ou des valeurs à différer",
					"descritivoAcrescimos": "Description de la ou des valeurs à ajouter"
				},
				"messages": {
					"radicalJaExistente": "Le radical inséré existe déjà dans la liste.",
					"apagarItem": "Supprimer la ligne",
					"desejaApagarItem": "Voulez-vous supprimer la ligne ?",
					"successfullySaved": "Modifications enregistrées avec succès.",
					"campoRadicalVazio": "Le champ radical ne peut pas être vide.",
					"nContaDiferimentosEmpty": "Le compte de report ne peut pas être vide.",
					"nContaAcrescimosEmpty": "Le compte de régularisations ne peut pas être vide.",
					"nDiarioEmpty": "Le journal ne peut pas être vide.",
					"descritivoEmpty": "Le descriptif ne peut pas être vide."
				}
			},
			"info": {
				"title": "Informations sur les régularisations et les reports",
				"infodetalhada": "Informations détaillées",
				"infomes": "Informations par mois",
				"exportDetalhes": "Informations détaillées sur le report",
				"exportTotais": "Informations de report par mois",
				"fields": {
					"nDocumento": "Numéro du document",
					"nConta": "Numéro de compte",
					"nomeConta": "Nom",
					"valorDebito": "Dette",
					"valorCredito": "Crédit",
					"descricao": "Description",
					"valor": "Valeur",
					"valorAcrescimoNoMes": "Ajout",
					"valorDiferimentoNoMes": "Report",
					"saldoAcrescimoNoMes": "Solde d'accumulation",
					"saldoDiferimentoNoMes": "Solde de report"
				}
			}
		}
	},
	"diferimentosglobal": {
		"diferimentos": "Reports",
		"acrescimos": "Ajouts",
		"acrescimosDiferimentos": "Accumulations et reports",
		"docsselmes": "Documents dans le mois sélectionné",
		"docscontribuem": "Documents qui contribuent au solde du mois sélectionné",
		"fields": {
			"nDocumento": "Numéro du document",
			"valor": "Valeur",
			"descricao": "Description"
		},
		"infoAction": "Informations sur les régularisations et les reports"
	},
	"mapadiferimentosacrescimos": {
		"diferimentos": "Reports",
		"acrescimos": "Ajouts",
		"diferimento": "Report",
		"acrescimo": "Ajout",
		"acrescimosDiferimentos": "Accumulations et reports",
		"anon": "Année {{année}}",
		"fields": {
			"nDocumento": "Document",
			"descricaoDoc": "Description",
			"nomeConta": "Nom du compte",
			"nConta": "Compte",
			"dataInicio": "Date de début",
			"dataFim": "Date de fin",
			"dias": "Jours",
			"valor": "Valeur",
			"valorAno1N": "Année précédente",
			"valorAnoN": "Année totale",
			"valorAnoN1": "L'année prochaine",
			"nDocExterno": "Document externe",
			"dataDocExterno": "Date du document.",
			"nomeDescriti": "Descriptif"
		},
		"tableTitle": "Carte de {{tipoOperacao}} de {{tipodiferimento}}",
		"tipoOperacao": "Type de spécialisation",
		"tipodiferimento": "Nature",
		"btn": {
			"infoglobaldiferimentosacrescimos": "Reports/ajouts d’informations globales"
		}
	},
	"tipoDiferimento": {
		"naoDefinido": "Dépenses et revenus",
		"rendimento": "Performance",
		"gasto": "Dépensé"
	},
	"calendarios": {
		"title_detail": "Calendrier {{id}}",
		"title_plural": "Calendriers",
		"pesquisa": "A chercher",
		"fields": {
			"calenCabID": "Code.",
			"nome": "Nom",
			"sabadosDiaUtil": "Le samedi comme jour ouvrable",
			"domingosDiaUtil": "Le dimanche comme jour ouvrable",
			"naoTemFeriados": "Il n'y a pas de vacances"
		}
	},
	"cargveic": {
		"title_detail": "Véhicule",
		"title_new": "Véhicule neuf",
		"title_plural": "Véhicules",
		"pesquisa": "A chercher",
		"saved": "Véhicule stocké avec succès.",
		"error": "Le véhicule ne peut pas être stocké.",
		"deleted": "Véhicule effacé avec succès.",
		"deleteModalText": "Supprimer le véhicule avec le numéro d'immatriculation \"{{matricula}}\" ?",
		"reboqueJaExiste": "La bande-annonce portant le numéro d'enregistrement \"{{matricula}}\" existe déjà.",
		"reboques": "Remorques",
		"adicionarReboque": "Ajouter une bande-annonce",
		"fields": {
			"matricula": "Inscription",
			"reboque": "Bande-annonce",
			"desativado": "Désactivé",
			"nConta": "Numéro de client",
			"pesoTara": "Tare du véhicule",
			"nomeTranspor": "Nom du transporteur",
			"nomeViatura": "Nom du véhicule",
			"modelo": "Modèle",
			"motor": "Moteur",
			"marca": "Marque",
			"chassi": "Châssis",
			"kilometros": "Kilomètres",
			"matriculaReboque": "Immatriculation de la remorque"
		},
		"btn": {
			"newArtigo": "Nouveau"
		},
		"list": {
			"actions": {
				"editar": "Modifier"
			}
		},
		"messages": {
			"temAlteracoes": "Certaines modifications ne sont pas encore enregistrées.",
			"apagarReboque": "Supprimer la bande-annonce"
		}
	},
	"categoriaartigo": {
		"title_detail": "Article de catégorie {{id}}",
		"title_new": "Article de nouvelle catégorie",
		"title_plural": "Catégories Article",
		"pesquisa": "A chercher",
		"saved": "Article de catégorie {{id}}, enregistré avec succès",
		"error": "L'article de catégorie {{id}} ne peut pas être enregistré.",
		"deleted": "Article de catégorie {{id}}, supprimé avec succès",
		"fields": {
			"categoria": "Catégorie",
			"descricao": "Description",
			"categoriaPlaceholder": "Catégorie",
			"descricaoPlaceholder": "Description"
		}
	},
	"categoriaProdutos": {
		"pesquisa": "Catégorie Produits",
		"fields": {
			"value": "Code",
			"name": "Description"
		},
		"data": {
			"mercadorias": "M - Marchandises",
			"materiasPrimas": "P - Matières premières, accessoires et consommables",
			"produtosAcabados": "A - Produits finis et intermédiaires",
			"subprodutos": "S - Sous-produits, déchets et débris",
			"produtosTrabalhosEmCurso": "T - Produits et travaux en cours",
			"naoAplicaveis": "N/A - Sans objet",
			"ativosBiologicos": "B - Actifs biologiques"
		}
	},
	"categorias": {
		"title_detail": "Catégorie {{id}}",
		"title_new": "Nouvelle catégorie",
		"title_plural": "Catégories",
		"pesquisa": "recherche",
		"deleted": "Catégorie {{id}}, supprimée avec succès",
		"saved": "Catégorie {{id}}, enregistrée avec succès",
		"error": "Catégorie {{id}}, non enregistrée.",
		"fields": {
			"nCateg": "Numéro de catégorie",
			"designaBreve": "Désignation",
			"designaCompl": "Désignation complète",
			"codCategoriaMT": "Code catégorie IRCT/DGEEP",
			"nomeCategoria": "Nom de la catégorie IRCT/DGEEP",
			"codIrct": "Code IRCT",
			"nomeIrct": "Nom de l'IRCT"
		}
	},
	"cct": {
		"title_detail": "Convention collective de travail {{id}}",
		"title_new": "Nouvelle convention collective de travail",
		"title_plural": "Contrats collectifs de travail",
		"pesquisa": "A chercher",
		"saved": "Convention collective de travail {{id}}, enregistrée avec succès.",
		"error": "La convention collective de travail {{id}} ne peut pas être enregistrée.",
		"deleted": "Convention collective de travail {{id}}, supprimée avec succès",
		"fields": {
			"ncct": "Code CCT",
			"designaBreve": "Brève description",
			"nome": "Conception.",
			"numBolTrab": "Numéro du bulletin d'emploi",
			"dtBolTrab": "Date du bulletin d'emploi",
			"mediaTabelas": "Moyenne du tableau",
			"dtIniValidad": "Date de début de validité",
			"codIRCT": "Code IRCT pour le personnel",
			"aplicabilidadeIRCT": "Applicabilité de l’IRCT"
		},
		"actions": {
			"taboficialirct": "Tableaux officiels du personnel de l'IRCT"
		}
	},
	"ccustos": {
		"title_detail": "Centre de coûts {{id}}",
		"title_new": "Nouveau centre de coûts",
		"title_plural": "Centre de coûts",
		"pesquisa": "A chercher",
		"saved": "Centre de coûts {{id}}, enregistré avec succès",
		"deleted": "Centre de coûts {{id}}, supprimé avec succès",
		"error": "Centre de coûts {{id}}, ne peut pas être enregistré.",
		"fields": {
			"nCCusto": "C. Coût",
			"nCCustoPlaceholder": "Code du centre de coûts",
			"nome": "Nom",
			"nomePlaceholder": "Nom du centre de coûts",
			"encerrado": "Fermé"
		}
	},
	"cgasi": {
		"title_detail": "Code de situation de la Caixa Geral de Aposentações {{id}}",
		"title_new": "Nouveau code de situation pour la Caixa Geral de Aposentações",
		"title_plural": "Codes de situation de la Caixa Geral de Aposentações",
		"pesquisa": "A chercher",
		"saved": "Code d'état Caixa Geral de Aposentações {{id}}, enregistré avec succès.",
		"error": "Le code d'état de la Caixa Geral de Aposentações {{id}} ne peut pas être enregistré.",
		"deleted": "Code d'état Caixa Geral de Aposentações {{id}}, supprimé avec succès.",
		"fields": {
			"codCGASit": "Code de statut CGA",
			"nomeCGASit": "Description",
			"tipoCodCGASit": "Type de code",
			"obs": "Observations"
		}
	},
	"changeArtigosFieldsInSerie": {
		"modalTitle": "Modifications des champs en série",
		"strings": {
			"pesquisa": "Recherche",
			"atualizacao": "Mise à jour",
			"relatorio": "Rapport",
			"artigosSelecionados": "Articles sélectionnés",
			"artigos": "Articles",
			"camposAAtualizar": "Champs à modifier",
			"anterior": "Valeur précédente",
			"novo": "Nouvelle valeur",
			"camposAlterados": "Champs modifiés",
			"calculoPreco": "Calculer le prix",
			"precoComIva": "Prix ​​TTC",
			"precoSemIva": "Prix ​​hors TVA"
		},
		"temSelecionarPeloMenosUmArtigo": "Vous devez sélectionner au moins un actif.",
		"valorCampoNaoPodeSerNulo": "La Valeur du champ {{campo}} à modifier ne peut pas être nulle ou vide !",
		"naoSelecinouNenhumCampoParaAlterar": "Vous n'avez sélectionné aucun champ à modifier"
	},
	"classescontratos": {
		"title_detail": "Classe de contrat {{id}}",
		"title_new": "Nouvelle classe de contrat",
		"title_plural": "Catégories de contrats",
		"pesquisa": "A chercher",
		"saved": "Classe de contrat {{id}}, enregistrée avec succès",
		"error": "Classe de contrat {{id}}, ne peut pas être enregistrée.",
		"deleted": "Classe de contrat {{id}}, supprimée avec succès",
		"fields": {
			"nClasseCtr": "Code",
			"nClasseCtrPlaceholder": "Code de classe de contrat",
			"nomeClasseCtr": "Nom",
			"nomeClasseCtrPlaceholder": "Nom de la classe de contrat",
			"classeTipo": "Classe de type",
			"classeTipoPlaceholder": "Classe de type de contrat"
		}
	},
	"classescontratosservicos": {
		"title_detail": "Classe Covenante {{id}}",
		"title_new": "Nouvelle catégorie d'accord",
		"title_plural": "Classes d'alliance",
		"pesquisa": "A chercher",
		"saved": "Classe Covenant {{id}}, enregistrée avec succès",
		"error": "La classe Covenant {{id}} ne peut pas être enregistrée.",
		"deleted": "Classe Covenant {{id}}, supprimée avec succès",
		"fields": {
			"nClasseCtr": "Code",
			"nClasseCtrPlaceholder": "Code de classe de paiement, s'il n'est pas renseigné, il sera généré automatiquement.",
			"nomeClasseCtr": "Nom",
			"nomeClasseCtrPlaceholder": "Nom de la classe de contrat"
		}
	},
	"classescaixa": {
		"title_detail": "Classe de boîte {{id}}",
		"title_new": "Nouvelle classe au comptant",
		"title_plural": "Cours en espèces",
		"pesquisa": "A chercher",
		"saved": "Classe de boîte {{id}}, enregistrée avec succès",
		"error": "Classe de boîte {{id}}, ne peut pas être enregistrée.",
		"deleted": "Classe de boîte {{id}}, supprimée avec succès",
		"fields": {
			"nClasseCaixa": "Code de classe de boîte",
			"designacaoBreve": "Brève désignation",
			"percentPatr": "Pourcentage d'employeur",
			"percentEmpr": "Pourcentage employé",
			"naoProcSubNatal": "Ne traite pas les sous-marins.",
			"naoProcSubFerias": "Ne traite pas les sous-marins.",
			"ncrss": "Centre RSS n°",
			"tipoRegime": "Type de régime",
			"descricao": "Description",
			"taxas": "Frais",
			"config": "Paramètres",
			"naoVaiSegSocial": "N'envoie pas de données au segment.",
			"percentPATR": "Employeur",
			"percentEMPR": "Employé",
			"percenttitle": "% de cotisations",
			"codIntegracaotitle": "Intégration comptable",
			"limites": "Plafonds de cotisations mensuelles en nombre de Smic",
			"codIntegracao": "Code d'intégration",
			"temLimiteMaximo": "A une limite maximale",
			"limMaxNSalariosMin": "Pas de sel.",
			"temLimiteMinimo": "A une limite minimale",
			"limMinNSalariosMin": "Pas de sel."
		}
	},
	"tipoRegime": {
		"empregados": "Employés",
		"gerenteMOE": "Gestionnaires/MEO",
		"primeiroEmprego": "1er emploi",
		"pensionistas": "Retraités",
		"deficientes": "Désactivé",
		"trabEntSFimLuc": "Travail.",
		"trabOutros": "Autres emplois",
		"praticantesDesportivos": "Praticiens sportifs professionnels",
		"servicoDomesticoConvencional": "Service domestique - Rémunération conventionnelle",
		"servicoDomesticoReal": "Service domestique - Salaire réel"
	},
	"clicls": {
		"title_detail": "Classe client/fournisseur {{id}}",
		"title_new": "Nouvelle classe de client/fournisseur",
		"title_plural": "Classes de clients/fournisseurs",
		"pesquisa": "A chercher",
		"saved": "Classe client/fournisseur {{id}}, enregistrée avec succès.",
		"error": "Classe client/fournisseur {{id}}, ne peut pas être enregistrée.",
		"deleted": "Classe client/fournisseur {{id}}, supprimée avec succès.",
		"fields": {
			"codigo": "Code",
			"descricao": "Description"
		}
	},
	"clifoExtraValueTipos": {
		"fields": {
			"tipo": "Taper",
			"descricao": "Description",
			"natureza": "Nature",
			"codigo": "Code"
		}
	},
	"clifoExtraValues": {
		"save": "Enregistré avec succès !",
		"errorEmailVazio": "L'email ne peut pas être nul.",
		"errorEmailJaExiste": "L'e-mail existe déjà.",
		"errorEmailInvalid": "L'email doit être valide.",
		"modal": {
			"title": "Ajouter un e-mail",
			"editTitle": "Modifier les e-mails ({{title}})",
			"table": {
				"fields": {
					"email": "E-mail"
				}
			}
		},
		"modalConfirmDelete": {
			"title": "Attention!",
			"message": "Êtes-vous sûr de vouloir supprimer cet enregistrement ?"
		},
		"fields": {
			"valor": "E-mail",
			"codigo": "Taper"
		},
		"table": {
			"descricao": "Type d'e-mail",
			"valor": "Valeur"
		},
		"btn": {
			"adicionar": "Pour ajouter",
			"remover": "Retirer",
			"addEmail": "Ajouter un e-mail",
			"new": "Nouveau"
		},
		"tooltips": {
			"addNewTypeEmail": "Ajouter un type d'e-mail",
			"editTypeEmail": "Modifier le type d'e-mail",
			"removeTypeEmail": "Supprimer le type d'e-mail"
		}
	},
	"clifos": {
		"pesquisa": "A chercher",
		"tab": {
			"ficha": "Enregistrer",
			"dadosconta": "Détails du compte",
			"extratos": "Extraits",
			"artigos": "Articles",
			"anexos": "Pièces jointes",
			"encomendas": "Ordres"
		},
		"title_detail": "Client/Fournisseur {{id}}",
		"title_new": "Nouveau client/fournisseur",
		"title_plural": "Clients / Fournisseurs",
		"saved": "Client/Fournisseur {{id}}, enregistré avec succès",
		"error": "Client/Fournisseur {{id}}, ne peut pas être enregistré.",
		"deleted": "Troisième {{id}}, supprimé avec succès",
		"navigation": {
			"tooltipFirst": "D'abord",
			"tooltipPrevious": "Précédent",
			"tooltipNext": "Suivant",
			"tooltipLast": "Dernier"
		},
		"groups": {
			"indentificacao": "Identification",
			"estadoAtVies": "Statut dans VIES",
			"dadosprincipais": "Données principales",
			"dadosfinan": "Données financières",
			"condECaract": "Caractéristiques",
			"classificadores": "Classificateurs",
			"moradasAlt": "Adresses alternatives",
			"tiposEmail": "Types d'e-mails"
		},
		"fields": {
			"nConta": "Numéro de compte",
			"nContaPlaceholder": "Numéro de compte",
			"nIdAltern": "ID alternatif.",
			"nIdAlternPlaceholder": "Numéro d'identification alternatif",
			"nome": "Nom",
			"nomePlaceholder": "Nom",
			"nContribuint": "Numéro de contribuable",
			"nContribuintPlaceholder": "Numéro de contribuable",
			"rua": "Route",
			"ruaPlaceholder": "Route",
			"codPostal": "Code Postal",
			"codPostalPlaceholder": "Code postal",
			"localidade": "Emplacement",
			"localidadePlaceholder": "Emplacement",
			"telefone": "Téléphone",
			"telefonePlaceholder": "Téléphone",
			"fax": "Fax",
			"faxPlaceholder": "Fax",
			"nomePais": "Pays",
			"nomePaisPlaceholder": "Pays",
			"email": "E-mail",
			"emailPlaceholder": "E-mail",
			"codRet": "Code de rétention",
			"codRetPlaceholder": "Code de taux de rétention",
			"situacaoActual": "Situation actuelle",
			"desactivado": "Désactivé",
			"naoPodeFacturar": "Je ne peux pas facturer",
			"codCondComerciais": "Cond.",
			"codCondComerciaisPlaceholder": "Code des conditions commerciales",
			"codPagamento": "Cond.",
			"codPagamentoPlaceholder": "Code condition de paiement",
			"observacoes": "Observations",
			"observacoes2": "Observations (suite)",
			"idSubGrContaCorrente": "La nature compte",
			"idSubGrContaCorrentePlaceholder": "L'enregistrement sélectionné génère un numéro de compte selon la nature",
			"iban": "IBAN",
			"ibanPlaceholder": "IBAN",
			"swift": "RAPIDE",
			"swiftPlaceholder": "RAPIDE",
			"nomeMoeda": "Code devise de facturation",
			"nomeMoedaPlaceholder": "Code devise de facturation",
			"facPublicaModelo": "Modèle EDI / Facturation électronique publique",
			"idideChav": "Groupe de comptes",
			"idideChavPlacholder": "Groupe de comptes",
			"nTxtCabeca": "En-tête de l'avis de facturation",
			"nTxtCabecaPlacholder": "En-tête de l'avis de facturation",
			"nTxtPE": "Pied de page de l'avis de facturation",
			"nTxtPEPlacholder": "Pied de page de l'avis de facturation",
			"codZona": "Zone",
			"codVendedor": "Vendeur",
			"ruaCont": "Rue (suite)",
			"telemovel": "Téléphone portable",
			"telemovelPlaceholder": "Téléphone portable",
			"wwwSite": "Page web",
			"wwwSitePlaceholder": "Page web",
			"utilizaIVAFichaClifo": "Appliquer le taux de TVA sur le formulaire",
			"codIva": "Taux de TVA",
			"obrigaNumRequisicao": "Nécessite « Demande »",
			"classeClifo": "Classe",
			"situacaoComercial": "Situation commerciale",
			"nifSituacaoAtDescricao": "À",
			"nifSituacaoViesDescricao": "BIAIS",
			"autoFaturacao": "Autofacturation",
			"textoFaturacao": "Notes de facturation",
			"sector": "Secteur"
		},
		"errors": {
			"contaNaoExiste": "Le compte n'existe pas",
			"nifInvalido": "NIF invalide !"
		},
		"avisodadosrepetidosmodal": {
			"title": "Client/Fournisseur avec données répétées",
			"bodyMessage": "Il existe déjà des clients/fournisseurs portant le même numéro de contribuable ou le même nom."
		},
		"anexos": "Pièces jointes",
		"selectNavTipoEmail": "Accédez aux types d'e-mails",
		"text": {
			"iban": "Obligatoire pour les paiements SEPA aux fournisseurs",
			"modeloFatPublic": "Le comportement de facturation change selon le modèle utilisé: Manuel - CIUS-PT / Webservice - Saphety et FEAP",
			"aplicaTaxaIva": "Ignore le taux de TVA de l'article.",
			"codRetencao": "Informations de facturation supplémentaires",
			"codMoeda": "Informations complémentaires sur la facturation.",
			"textoFaturacao": "Texte visible lors de la délivrance des documents."
		},
		"btn": {
			"validaNif": "Valider le NIF dans VIES",
			"btnNewMoral": "Nouveau"
		},
		"nifInvalidoModal": {
			"title": "NIF invalide",
			"bodyMessage": "Le NIF {{nContribuint}} n'est pas valide.<br/>Voulez-vous quand même enregistrer le NIF comme invalide/inexistant ?"
		}
	},
	"clientes": {
		"pesquisa": "A chercher",
		"tab": "Enregistrer",
		"title_detail": "Client {{id}}",
		"title_new": "Nouveau client",
		"title_plural": "Clients",
		"title_singular": "Client",
		"saved": "Client {{id}}, enregistré avec succès",
		"error": "Le client {{id}} ne peut pas être enregistré.",
		"deleted": "Client {{id}}, supprimé avec succès",
		"showZona": "Afficher le champ de zone",
		"showDesativados": "Afficher uniquement les personnes handicapées",
		"showVendedor": "Afficher le champ du vendeur",
		"showSetor": "Affiche le champ du secteur"
	},
	"fornecedores": {
		"pesquisa": "A chercher",
		"tab": "Enregistrer",
		"title_detail": "Fournisseur {{id}}",
		"title_new": "Nouveau fournisseur",
		"title_plural": "Fournisseurs",
		"title_singular": "Fournisseur",
		"saved": "Fournisseur {{id}}, enregistré avec succès",
		"error": "Le fournisseur {{id}} ne peut pas être enregistré.",
		"deleted": "Fournisseur {{id}}, supprimé avec succès",
		"showDesativados": "Afficher uniquement les personnes handicapées"
	},
	"outrosdevedorescredores": {
		"pesquisa": "A chercher",
		"title_detail": "Autre débiteur/créancier {{id}}",
		"title_new": "Nouveau débiteur/créancier",
		"title_plural": "Débiteurs/créanciers",
		"title_singular": "Débiteur/créancier",
		"saved": "Débiteur/Créancier {{id}}, enregistré avec succès",
		"error": "Débiteur/Créancier {{id}}, ne peut pas être enregistré.",
		"deleted": "Débiteur/Créancier {{id}}, supprimé avec succès"
	},
	"modal": {
		"txtFaturacao": {
			"title": "Notes de facturation"
		}
	},
	"cltrb": {
		"title_detail": "Catégorie d'emploi {{id}}",
		"title_new": "Nouvelle catégorie d'emplois",
		"title_plural": "Catégories d'emplois",
		"pesquisa": "A chercher",
		"saved": "Classe d'emploi {{id}}, enregistrée avec succès.",
		"error": "La classe d'emploi {{id}} ne peut pas être enregistrée.",
		"deleted": "Classe d'emploi {{id}}, supprimée avec succès.",
		"fields": {
			"nclasTrab": "Code de classe",
			"designaBreve": "Brève désignation",
			"designaCompl": "Désignation complète"
		}
	},
	"codigoscontabilisticos": {
		"title_detail": "Code comptable {{id}}",
		"title_new": "Nouveau code comptable",
		"title_plural": "Codes comptables",
		"pesquisa": "A chercher",
		"saved": "Code comptable {{id}}, enregistré avec succès.",
		"error": "Le code comptable {{id}} ne peut pas être enregistré.",
		"deleted": "Code comptable {{id}}, supprimé avec succès.",
		"fields": {
			"codcontab": "Code",
			"nome": "Nom"
		},
		"placeholders": {
			"codcontab": "Code comptable",
			"nome": "Nom du code comptable"
		}
	},
	"codpostais": {
		"title_detail": "Code postal {{id}}",
		"title_new": "Nouveau code postal",
		"title_plural": "Codes postaux",
		"pesquisa": "A chercher",
		"saved": "Code postal {{id}}, enregistré avec succès",
		"error": "Le code postal {{id}} ne peut pas être enregistré.",
		"deleted": "Code postal {{id}}, supprimé avec succès",
		"fields": {
			"codPostal": "Code Postal",
			"codPostalPlaceholder": "Code postal",
			"localidade": "Emplacement",
			"localidadePlaceholder": "Localité du code postal",
			"codPais": "Code du pays",
			"nomePais": "Pays"
		}
	},
	"colaboradores": {
		"pesquisa": "A chercher",
		"title": "Collaborateurs",
		"sentSuccess": "Envoyé avec succès",
		"uploadSuccess": "Fichier téléchargé avec succès",
		"uploadError": "Une erreur s'est produite lors de la tentative de chargement du fichier",
		"comunicacoesinternas": {
			"titlenew": "Nouvelle communication interne",
			"title": "Communication interne",
			"fields": {
				"tipoComunicacao": "Type de communication",
				"titulo": "Titre",
				"texto": "Texte"
			},
			"you": "Toi",
			"confirmDeleteMessage": "Êtes-vous sûr de vouloir supprimer la communication ?",
			"anexo": "Annexe"
		},
		"docsplata": {
			"title": "Documents.",
			"text": {
				"servicos": "Services",
				"servico": "Service",
				"papeis": "Papiers",
				"papel": "Papier"
			},
			"modal": {
				"title": "Nouveau document de la plateforme RH",
				"titulo": "Titre",
				"dataValidade": "Date d'expiration",
				"errorFiles": "Vous devez télécharger au moins un fichier.",
				"errorServicosSelected": "Vous devez choisir au moins un service auquel le document sera associé.",
				"errorPapeisSelected": "Vous devez choisir au moins un rôle auquel le document sera associé."
			}
		},
		"board": {
			"fields": {
				"servico": "Service",
				"dadosColaborador": "Données des collaborateurs",
				"colaborador": "Collaborateur",
				"saldoFerias": "Solde de vacances",
				"feriasGozadas": "Des vacances appréciées",
				"faltas": "Fautes",
				"horasSemanais": "Horaires hebdomadaires",
				"localTrabalho": "Lieu de travail",
				"tipoHorario": "Type d'horaire",
				"tipoContrato": "Type de contrat",
				"validadeContrato": "Validité du contrat",
				"centroCusto": "centre de coûts",
				"reparticao": "Panne",
				"ferias": "Vacances",
				"abonos": "Allocations"
			},
			"msg": {
				"nenhumColabSelected": "Aucun collaborateur sélectionné"
			}
		}
	},
	"comcategorias": {
		"title_detail": "Catégorie Communication",
		"title_new": "Nouvelle catégorie de communication",
		"title_plural": "Catégories de communications",
		"pesquisa": "A chercher",
		"saved": "Catégorie de communication, enregistrée avec succès.",
		"error": "Catégorie de communication, ne peut pas être enregistré.",
		"deleted": "Catégorie de communication, supprimée avec succès.",
		"categoriaSistema": "Les catégories système ne peuvent pas être modifiées",
		"fields": {
			"nome": "Catégorie",
			"nEmpresa": "Numéro d'entreprise",
			"tipo": "Taper",
			"cor": "Couleur",
			"todasEmpresas": "Disponible pour toutes les entreprises"
		},
		"tipoComCategorias": {
			"data": {
				"none": "Non défini",
				"sistema": "Système",
				"utilizador": "Utilisateur"
			}
		}
	},
	"companystatus": {
		"header": "La société \"<em>{{nCompany}} - {{NameCompany}}\"</em> est inaccessible en raison de la planification ou de l'exécution de la tâche suivante.",
		"status": "État",
		"tasks": {
			"ECGOnFimAno": {
				"title": "Fin d'année",
				"state": {
					"fasInactive": "Inactif",
					"fasScheduled": "Programmé",
					"fasStarted": "En cours d'exécution...",
					"fasEnded": "Fini"
				}
			}
		},
		"text": {
			"back": "Pour revenir",
			"description": "Tâche demandée dans <em>\"{{requested}}\"</em> par l'utilisateur <em>\"{{user}}\"</em> pour la société <em>\"{{company}}\"</em> .",
			"scheduled": "Début prévu à <em>\"{{scheduled}}\"</em>."
		},
		"cancel": {
			"title": "Annuler le rendez-vous",
			"message": "Êtes-vous sûr de vouloir déplanifier la tâche « {{taskName}} » ?"
		}
	},
	"components": {
		"usernav": {
			"name": "Bonjour, {{name}} !",
			"store": "Gérez vos forfaits et vos entreprises",
			"showRecentCompanies": "Afficher les entreprises récentes",
			"hideRecentCompanies": "Masquer les entreprises récentes",
			"theme": "Thème"
		},
		"cgmodal": {
			"choosecountry": {
				"title": "Sélectionnez un pays",
				"btn": "Copier le modèle",
				"codigo": "Code.",
				"designacaoPT": "Désignation (PT)",
				"codigoAlpha2": "ISO3166 Alpha-2",
				"codigoAlpha3": "ISO3166 Alpha-3",
				"designacao": "Langue du prénom"
			}
		},
		"filter": {
			"advancedFilter": "Filtre avancé"
		},
		"multiselect": {
			"available": "Disponible",
			"selected": "Choisi"
		},
		"devextreme": {
			"datagrid": {
				"preferences": "Paramètres",
				"statestoring": {
					"clear": "Effacer les paramètres"
				}
			}
		},
		"contabilidade": {
			"listagens": {
				"account": "Compte",
				"fromAccount": "compte",
				"fromContrib": "Du contribuable",
				"fromPeriod": "période de",
				"fromdiary": "agenda",
				"toPeriod": "Période jusqu'à"
			},
			"balancetes": {
				"accountTypes": "Types de comptes",
				"yearsToCompare": "Années à comparer",
				"hideAccountsZeroBalance": "Masquer les comptes avec un solde nul",
				"calculaSaldosIniciaisAnoNMaisUm": "Calculer les soldes d'ouverture de {{nextYear}} avec l'année {{year}} ouverte"
			},
			"extratosgrid": {
				"contaInvalida": "Champ de compte invalide.",
				"linhas": {
					"nConta": "Compte",
					"nomeConta": "Nom du compte",
					"nDocExterno": "Document externe.",
					"dataVenc": "Date d'expiration",
					"descricao": "Description",
					"valorDebito": "Montant du débit",
					"valorCredito": "Valeur du crédit",
					"porPagar": "Non rémunéré",
					"dataDoc": "Date du document.",
					"moeda": "Pièce de monnaie",
					"nCCusto": "Coût NC",
					"valorDebitoSegMoeda": "Montant du débit sec.",
					"valorCreditoSegMoeda": "Valeur du crédit sec.",
					"cambioSegMoeda": "Échange lundi.",
					"descritivo": "Descriptif",
					"perioDiarioDoc": "Numéro du document",
					"temDocDigital": "Document numérique",
					"stampInsert": "Date d'insertion",
					"periodo": "Période",
					"diario": "Tous les jours",
					"ndocInterno": "Doc. interne."
				},
				"options": {
					"showDiarios": "Afficher les journaux"
				},
				"btn": {
					"processar": "Processus",
					"showpdf": "Traiter le PDF",
					"sendMail": "Envoyer un e-mail"
				}
			}
		},
		"keybindingshelper": {
			"header": "Touches de raccourci"
		},
		"configOptions": {
			"title": "Possibilités",
			"revertToDefault": "Revenir aux options par défaut"
		},
		"genericImporter": {
			"title": "{{type}} importer",
			"steps": {
				"downloadModel": {
					"title": "Télécharger le modèle",
					"content": {
						"1": "Bienvenue dans l'assistant d'importation {{type}}.",
						"2": "Veuillez cliquer sur le bouton ci-dessous pour télécharger le modèle d'importation {{type}}.",
						"3": "Après avoir téléchargé le fichier, remplissez-le avec les données que vous souhaitez importer.",
						"4": "Attention: Vous devez disposer d'un logiciel d'édition de tableur."
					}
				},
				"uploadModel": {
					"title": "Télécharger le modèle terminé",
					"content": {
						"1": "Remplissez le fichier avec les données que vous souhaitez importer.",
						"2": "Après avoir rempli le fichier, téléchargez-le pour continuer l'importation."
					}
				},
				"analyzeErrors": {
					"title": "Analyser les erreurs",
					"content": {
						"2": "Pour analyser le fichier contenant des erreurs, téléchargez-le en cliquant sur le bouton ci-dessous.",
						"1.1": "Au moins une erreur s'est produite",
						"1.2": "lors de l'importation de {{type}}."
					}
				},
				"success": {
					"title": "Finition",
					"content": {
						"1": "L'importation de {{type}} s'est terminée avec succès.",
						"2": "Cliquez sur \"Terminer\" pour terminer le processus d'importation {{type}}."
					}
				}
			},
			"types": {
				"0": "articles",
				"1": "clients",
				"2": "fournisseurs",
				"3": "autres débiteurs/créanciers",
				"4": "collaborateurs",
				"5": "comptes courants clients",
				"6": "comptes courants fournisseurs",
				"7": "matrice de conversion"
			},
			"actions": {
				"downloadModel": "Télécharger le modèle d'importation",
				"downloadErrors": "Télécharger le fichier avec des erreurs d'importation",
				"retry": "Essayer à nouveau",
				"import": "Importer"
			},
			"errors": {
				"requiredUploadModel": "Pour continuer l'importation, vous devez télécharger le fichier contenant les données que vous souhaitez importer."
			}
		},
		"scheduler": {
			"text": {
				"colaborador": "Collaborateur"
			}
		},
		"refeicoesscheduler": {
			"header": {
				"previous": "Précédent",
				"today": "Aujourd'hui",
				"next": "Suivant"
			}
		},
		"panelmultipleselectionexecute": {
			"labels": {
				"selectedDocs": "Documents sélectionnés"
			},
			"actions": {
				"lancarDocs": "Publier des documents",
				"lancarDoc": "Publier un document"
			}
		},
		"userSatisfaction": {
			"suggestions": "Commentaires et suggestions",
			"feedback": "Donnez-nous votre avis"
		}
	},
	"concelhos": {
		"title_detail": "Conseil {{id}}",
		"title_new": "Nouvelle municipalité",
		"title_plural": "Comtés",
		"pesquisa": "A chercher",
		"saved": "Municipalité {{id}}, enregistrée avec succès",
		"error": "Le conseil {{id}} ne peut pas être enregistré.",
		"deleted": "Conseil {{id}}, supprimé avec succès",
		"fields": {
			"codConcelho": "Code départemental",
			"nomeConcelho": "Nom de la commune",
			"codDistrito": "Code de district",
			"nomeDistrito": "District",
			"taxaDerrama": "Le taux coule",
			"codConcelhoAT": "Comté d'AT"
		},
		"cardderrama": "Calcul du taux de coulée",
		"modal": {
			"title": "Calcul du taux de coulée",
			"editardados": "Modifier les données",
			"fields": {
				"tipoDadosSTR": "Taper",
				"valor": "Valeur",
				"condicaoCumprida": "D'ACCORD",
				"tipoTaxaSTR": "Type de tarif",
				"taxa": "Taux",
				"ambito": "Portée"
			}
		}
	},
	"conciliacaoBancaria": {
		"mostrarLinhasConciliadasLabel": "Afficher les lignes correspondantes",
		"addDocNaoConciliadosTitle": "Ajouter des documents non rapprochés des périodes précédentes",
		"addExtradoBancario": "Pour ajouter",
		"addLinhaExtradoBancario": "Ajouter une entrée en comptabilité et banque",
		"addLancPrimeiraConciliacaoExtradoBancario": "Ajouter une première entrée de rapprochement bancaire",
		"addExtradoBancarioBtn": "Pour ajouter",
		"novaConciliacao": "Nouvelle conciliation",
		"printConciliacao": "Imprimer le rapprochement",
		"print": "Imprimer",
		"editarConciliacao": "Modifier le rapprochement",
		"refreshConciliacao": "Mettre à jour le rapprochement",
		"eliminaConciliacao": "Éliminer",
		"terminarConcilidacao": "Finition",
		"terminadaConcilidacao": "Conciliation terminée.",
		"terminarConcilidacaoModalMsg": "Êtes-vous sûr de vouloir mettre fin à la conciliation ?",
		"eliminaConciliacaoModalMsg": "Êtes-vous sûr de vouloir supprimer la correspondance \"{{name}}\" ?",
		"eliminaTodasConciliacoesModalMsg": "Êtes-vous sûr de vouloir supprimer tous les rapprochements pour le compte « {{conta}} » ?",
		"selectConciliacaoMessage": "Sélectionnez la banque que vous souhaitez rapprocher ou créez un nouveau rapprochement bancaire.",
		"seraLancadaNaConta": "La nouvelle ligne sera publiée sur le compte",
		"seraCriadaNovaConta": "Un nouveau compte sera créé",
		"searchByValue": "Rechercher par valeur...",
		"addDocsNaoConciliadosPeriodoAnt": "Ajouter des documents non rapprochés des périodes précédentes",
		"addDocsNaoConciliadosPeriodoAntBtn": "Ajouter des documents",
		"naoExistemDocs": "Il n'y a aucun document non rapproché des périodes précédentes",
		"totalSelecionado": "Total sélectionné",
		"hideTreeText": "Masquer l'arbre",
		"showTreeText": "Afficher l'arborescence",
		"removeTodasLinhasExtratoBancoTitle": "Pour éteindre",
		"removeTodasLinhasExtratoBancoMsg": "Êtes-vous sûr de vouloir supprimer toutes les lignes ?",
		"extratoContabilidadeTitle": "Extrait comptable",
		"extratoBancoTitle": "Relevé bancaire",
		"viewDocTooltip": "Voir le communiqué",
		"viewDocModalTitle": "Afficher le document",
		"associarExtratoTooltip": "Déclaration associée",
		"associarLinhasDosExtratosEmAmbosLadosTooltip": "Associe les lignes d'extraction sélectionnées des deux côtés",
		"removeLinhaExtratoBancoTooltip": "Supprimer la ligne",
		"eliminarTodasConciliacoesBanco": "Supprimer tous les rapprochements bancaires",
		"lancamentoContab": "Écriture comptable",
		"printModalTitle": "Imprimer",
		"deNConta": "Numéro de compte",
		"dePeriodo": "période de",
		"applyFilters": "Appliquer des filtres",
		"saldoExtratoBancarioPerspetivaBancoTitle": "Solde du relevé bancaire du point de vue de la banque",
		"periodoNaoEValidoDeveIndicarOPeriodpSeg": "La période n'est pas valide, vous devez indiquer la période suivant le dernier rapprochement",
		"legendaLabel": "Légende",
		"adicionarLinhaPrimeiraConcilExtratoCG": "Ajouter la première ligne de rapprochement à l'état comptable",
		"adicionarDocsPrimeiraConcilExtratoCG": "Ajouter les premiers documents de rapprochement",
		"importaDocsPendentesConcilAntiga": "Importer les documents en attente dans l'ancienne comptabilité de rapprochement",
		"extratoContaPendentes": "Extrait de compte en attente",
		"importaDocsPendentesBancoConcilAntiga": "Importer les documents en attente dans l'ancienne banque de conciliation",
		"importarExtratoBancario": "Importer un relevé bancaire",
		"adicionarLancamentoBanco": "Ajouter une entrée bancaire",
		"conciliarAutomaticamente": "Réconcilier automatiquement",
		"addLinhaPrimeiraConcilExtratoCGModalTitle": "Ajouter la première ligne de rapprochement au compte comptable",
		"editLinhaPrimeiraConcilExtratoCGModalTitle": "Modifier la première ligne de rapprochement vers le compte comptable",
		"lancNaContaContabilidadeNaPerspetivaEmpresaTitle": "Entrée dans le compte comptable du point de vue de l’entreprise",
		"tipoMovimentoNaPerspectivaEmpresaTitle": "Type de mouvement du point de vue de l'entreprise",
		"oValorMovimentoNaoPodeSer0ouInferior": "La valeur du mouvement ne peut pas être inférieure ou égale à 0.",
		"editLanc": "Modifier la version",
		"removeLinha": "Supprimer la ligne",
		"desassociarLinha": "Dissocier la ligne",
		"editLinhaExtradoBancarioModalTitle": "Modifier la ligne du relevé bancaire",
		"extPocItemNotFound": "Écriture comptable introuvable",
		"adicionarDocsExtratoBancarioAContabilidadeModalTitle": "Ajouter des documents de relevé bancaire à la comptabilité",
		"maisInfoSobreSaldos": "Cliquez pour voir plus de détails sur les soldes",
		"viewSaldoDetalhesModalTitle": "Détails du solde de rapprochement",
		"saldoContabilidade": "Soldes comptables",
		"saldoBanco": "Soldes bancaires",
		"associarTodoExtratoCGAoExtradoDoBanco": "Les lignes qui ne sont pas rapprochées dans le relevé de l'entreprise seront créées dans le relevé bancaire et automatiquement rapprochées.",
		"removerTodasLinhasExtrato": "Supprimer toutes les lignes du relevé bancaire",
		"temQueSelecionarLinhas": "Vous devez sélectionner des lignes pour effectuer l'opération.",
		"sAntesDeProsseguirDeveVerificarSe": "Avant de continuer, vous devez vérifier si la dernière réconciliation (Ancien Module) est correcte.",
		"s01Contrapartida2": "{{radical}}{{nconta}} - Pendentifs non classés.",
		"adicionarLinhaExtratoBancario": "Ajouter une ligne au relevé bancaire",
		"adicionarDocsDoExtratoBancarioAContabilidade": "Ajouter des documents de relevé bancaire à la comptabilité",
		"saldosNaPerspetivaDoBanco": "Les soldes du point de vue de la banque",
		"import": "Importer un extrait",
		"noFiles": "Sélectionnez le fichier à traiter...",
		"importTab": "Importateur",
		"importResultsTab": "Résultats",
		"osSaldosDoExtratoBancarioImporta": "Les soldes sur le relevé bancaire importé ne correspondent pas aux soldes indiqués dans le rapprochement.",
		"adicionarAContabilidade": "Ajouter à la comptabilité",
		"ordenarPorDataDocExt": "Trier par date du document.",
		"ordenarPorValor": "Trier par valeur",
		"ordenarPorData": "Trier par date",
		"ordenarPorDescricao": "Trier par description",
		"ordenarPorNDoc": "Trier par numéro de document",
		"sMsgOQueFazercomVariaslinhas": "Cliquez sur Oui pour rapprocher les lignes sélectionnées et afficher une seule ligne de sommation côté banque.",
		"conciliadoLabel": "Réconcilié",
		"configImportModalTitle": "Configuration du modèle d'importation de relevé",
		"configDebitoCreditoNaPerspectivaBancoTitle": "Configuration du débit et du crédit du point de vue de la banque",
		"comoEObtidoValorMovimentoNoExtrato": "Comment est obtenue la valeur du mouvement dans la déclaration",
		"configImportTooltip": "Configurer l'importation",
		"estaNaPropriaColunaLabel": "C'est dans la colonne elle-même",
		"estaEmColunasSeparadasLabel": "C'est dans des colonnes séparées",
		"estEmUmaColunaENoutraColunaLabel": "Il est dans une colonne et l'identifiant est dans une autre colonne.",
		"removeTemplatePromptMsg": "Êtes-vous sûr de vouloir supprimer le modèle ?",
		"templateSavedMsg": "Le modèle a été enregistré avec succès",
		"templateRemovedMsg": "Le modèle a été supprimé avec succès",
		"outrosanos": "Autres années",
		"contaPendentesBanco": "Compte bancaire impayé",
		"configContaPendentesBanco": "Créer un compte bancaire impayé",
		"radicalContaGuardadoComSucesso": "Le radical du compte a été enregistré avec succès",
		"fields": {
			"nconta": "Compte",
			"periodo": "Période",
			"dataInicio": "Date de début",
			"dataFim": "Date de fin",
			"saldoInicialBanco": "Solde d'ouverture en banque",
			"saldoFinalBanco": "Solde final en banque",
			"saldoInicial": "Solde d'ouverture",
			"saldoFinal": "Solde final",
			"saldoFinalCalc": "Solde final calculé",
			"dc": "ANNONCE",
			"valor": "Valeur",
			"descricao": "Description",
			"conciliado": "Réconcilié",
			"tipo": "Taper",
			"radicalConta": "Compte radical",
			"contaBanco": "Compte bancaire",
			"diario": "Tous les jours",
			"descritivo": "Descriptif",
			"tipoMovimento": "Type de mouvement du point de vue de la banque",
			"ndoc": "Doc n°",
			"nDocExterno": "Ext. doc n°",
			"dataLacam": "Date de lancement",
			"dataDocExt": "Date du document.",
			"nSeq": "Séq.",
			"stampUpdate": "Temps concis.",
			"diferencaConciliacao": "Différence avec la conciliation",
			"valorSemDC": "Valeur non signée",
			"paramImport": "Paramètres d'importation",
			"message": "Message",
			"template": "Modèle",
			"numPrimeiraLinhaDados": "N° de la 1ère ligne de données",
			"colunaDataMovimento": "Colonne date de mouvement",
			"colunaDataValor": "Colonne de valeur de date",
			"colunaDescrição": "Colonne de description",
			"colunaSaldo": "Colonne Solde",
			"colunaValorDoMovimento": "Colonne de valeur de mouvement",
			"seValorNegativoInverteSinalELancaA": "Si une valeur négative inverse le signe et lance le",
			"seValorPositivoNaoInverteSinalELancaA": "Si une valeur positive n'inverse pas le signe et lance le",
			"colunaValorADebito": "Colonne Montant du débit",
			"colunaValorACredito": "Colonne Montant du crédit",
			"inverteSinalDoValorADebito": "Inverse le signe du montant débité",
			"colunaDoTipoDeMovimento": "Colonne Type de mouvement",
			"caraterParaDebito": "Caractère de débit",
			"caraterParaCredito": "Caractère pour le crédit"
		},
		"config": {
			"showColumnDescricao": "Afficher la colonne \"Description\" dans la grille des états comptables",
			"showColumnNDocExt": "Afficher la colonne \"Numéro de document externe\" dans la grille des états comptables",
			"showColumnTipo": "Afficher la colonne \"Type\" dans la grille des relevés bancaires",
			"showColumnSaldo": "Afficher la colonne \"Solde\" dans la grille des relevés bancaires",
			"showColumnStampUpdate": "Afficher la colonne \"Délai de rapprochement\" dans la grille des relevés bancaires",
			"showColumnDataLanc": "Afficher la colonne \"Date de lancement\" dans la grille des états comptables",
			"showColumnData": "Afficher la colonne \"Date\" dans la grille des états comptables",
			"showColumnValorSemDC": "Afficher la colonne \"Valeur sans signe\" dans la grille des états comptables",
			"showColumnBancoValorSemDC": "Afficher la colonne \"Montant sans signe\" dans la grille des relevés bancaires",
			"showColumnDC": "Afficher la colonne \"DC\" dans la grille des états comptables",
			"showColumnBancoDC": "Afficher la colonne \"DC\" dans la grille des relevés bancaires"
		},
		"btns": {
			"toolbarConciliacaoBtn": "Actions de conciliation",
			"selecionados": "Choisi...",
			"toolbarShortcuts": "Raccourcis",
			"toolbarBancoPoc": "Voir le plan de compte bancaire",
			"toolbarPesqDocs": "Recherche de documents"
		},
		"messages": {
			"deleteSelectedLine": "Voulez-vous supprimer la ligne sélectionnée ?"
		},
		"tabMainTitle": "Conciliation",
		"tabAttachmentsTitle": "Pièces jointes",
		"fromTo": "{{de}} à {{à}}"
	},
	"conciliacaoEFaturas": {
		"tabDocsEFaturaCaption": "Documents dans e-Fatura non liés à la comptabilité",
		"tabJaLancadosCaption": "Documents déjà saisis en comptabilité ou ignorés",
		"viewDocModalTitle": "Vue du document {{ndoc}}",
		"viewDocModalTitleSimple": "Aperçu du document",
		"extratosModalTitle": "Relevés de compte",
		"pesquisaDocPossivelModalTitle": "Recherche de documents comptables",
		"expandAllBtn": "Tout développer",
		"associarTodosPossiveis": "Associer des documents à 1 document possible",
		"associarTodosPossiveisPromptMsg": "Cette opération associera automatiquement les documents e-Fatura à un document éventuel.<br>Vous devez toujours vérifier document par document si le document éventuel associé correspond au document e-Fatura.<br><br>Etes-vous sûr de vouloir continuer?",
		"associarTodosPossiveisNoSourceTitle": "Association automatique...",
		"associarTodosPossiveisNoSourceMsg": "Vous n'avez aucun document ou vous avez des documents avec plus d'un document possible.",
		"associarDocSemAlterarDocExterno": "Associer un document sans modifier un document externe",
		"associarDocComAlteracaoDocExterno": "Associer un document à une modification de document externe",
		"verDocumento": "Afficher le document",
		"deixarIgnorarDocumento": "Arrêtez d'ignorer ce document",
		"deixarIgnorarPorFornecedor": "Arrêtez d'ignorer les documents de ce fournisseur",
		"removeAssociacao": "Supprimer l'association de ce document",
		"withoutPossibleDocs": "Aucun document possible",
		"filtropesq": "Recherche",
		"messages": {
			"docAssociadoSuccessMsg": "Le document {{ndoc}} a été lié.",
			"docsAssociadoSuccessMsg": "Tous les documents avec 1 document possible ont été associés avec succès.",
			"docIgnoradoSuccessMsg": "Le document {{ndoc}} a été ajouté à la liste des ignorés.",
			"fornecedorIgnoradoSuccessMsg": "Fournisseur {{nif}} - {{name}} a été ajouté à la liste des ignorés.",
			"docRemAssociacaoSuccessMsg": "L'association du document {{ndoc}} a été supprimée",
			"docRemIgnoringSuccessMsg": "Le document {{ndoc}} a été supprimé de la liste des ignorés.",
			"docRemIgnoringFornecedorSuccessMsg": "Le fournisseur {{nif}} a été supprimé de la liste des ignorés.",
			"docDigital1": "Avec doc.",
			"docDigital2": "Avec doc."
		},
		"fields": {
			"numPossiveisDocs": "Nombre de documents possibles",
			"numPossiveisDocsAbbr": "Possible",
			"numSemelhantesDocs": "Nombre de documents similaires",
			"numSemelhantesDocsAbbr": "Similaire",
			"documentoLancado": "Numéro du document",
			"nifEmitente": "FNI",
			"nomeEmitente": "Nom",
			"numerodocumento": "Doc n°",
			"dataEmissaoDocumento": "Date d'émission",
			"tipoDocumentoDesc": "Taper",
			"estadoBeneficioDesc": "Situation",
			"actividadeProfDesc": "Champ d'activité",
			"valorTotalBaseTributavel": "Base imposable",
			"valorTotalIva": "Valeur TVA",
			"valorTotal": "Valeur totale",
			"baseTributavel": "Base imposable",
			"taxa": "Base tarifaire",
			"impostoSelo": "Taxe de timbre",
			"total": "Valeur totale",
			"nDocInterno": "Numéro du document",
			"nDocExterno": "Doc n°",
			"valor": "Valeur",
			"dataDocExterno": "Date du document.",
			"docDigitalAbbr": "Document numérique"
		},
		"btn": {
			"dropdown": {
				"extratosConta": "Relevés de compte",
				"ignorarSempreTodosDocumentos": "Ignorez toujours tous les documents de ce fournisseur",
				"ignorarDocumento": "Ignorer ce document",
				"configSupplier": "Configurer le fournisseur"
			}
		},
		"tabJaLancadosCaptionContainer": {
			"table": {
				"badges": {
					"ligacaoDireta": "Appel direct",
					"remover": "Retirer",
					"naoIgnorar": "N'ignore pas"
				}
			},
			"legends": {
				"documentoIgnorado": "Document ignoré",
				"associadoDocExtDiferente": "Associé au doc ​​no.",
				"documentoFornecedorIgnorado": "Document fournisseur ignoré"
			}
		},
		"tabDocsEFaturaCaptionContainer": {
			"mostradocpossiveis": "Afficher seulement aucun document possible",
			"tooltip": {
				"associarAutTodosPossiveis": "Associez automatiquement tous les documents à 1 document possible."
			}
		},
		"formFields": {
			"tipo": "Taper",
			"fornecedor": "Fournisseur",
			"placeholder": {
				"contribuinte": "Numéro de contribuable"
			}
		},
		"modal": {
			"config": {
				"title": "Configuration du rapprochement des factures électroniques",
				"diariosList": "Liste des journaux à supprimer",
				"fields": {
					"nCaracteresAValidar": "Nombre de caractères Doc. Externe à valider:",
					"filtraPorDataDocExterno": "Les données du document externe contribuent aux documents possibles",
					"diarioAExcluir": "Journal à supprimer",
					"addDiarioAExcluir": "Ajouter un journal à supprimer",
					"diario": "Tous les jours",
					"nomeDiario": "Nom"
				},
				"messages": {
					"diarioAlreadyInList": "Le journal sélectionné existe déjà dans la liste."
				}
			}
		}
	},
	"concilmovab": {
		"title": "Justification des mouvements ouverts",
		"conta": "Compte",
		"deconta": "compte",
		"table": {
			"periodo": "Période",
			"nDiario": "Numéro quotidien",
			"nDoc": "Document",
			"nDocExterno": "Ext Doc.",
			"descricao": "Description",
			"dataDocExt": "Date du document.",
			"isDocumentoOrigemRegimeDeIvaDeCaixa": "Régime de TVA en espèces",
			"valor": "Valeur",
			"porPagar": "Non rémunéré",
			"dc": "ANNONCE",
			"aImputar": "Pour justifier",
			"dataVenc": "Maturité",
			"moeda": "Pièce de monnaie",
			"valorActualAImputar": "Compte valeur actuelle",
			"montanteAReter": "Montant à retenir"
		},
		"totals": {
			"valajustificar": "Des valeurs à justifier",
			"totconta": "Totaux du compte"
		},
		"messages": {
			"success": "Un massacre enregistré!",
			"saldomovinvalido": "Le solde des mouvements doit être 0 pour réaliser cette opération !",
			"jobTimeoutModalTitle": "Justifier automatiquement les valeurs en série",
			"jobTimeoutModalMessage": "La tâche a expiré.",
			"aprepararjustvalautoserie": "Préparer automatiquement le processus de justification de la valeur en série...",
			"aefetuarjustvalautoserie": "Traitement automatique des justifications de valeurs en série...",
			"successjustvalautoserie": "Justifications de valeurs en série automatiquement traitées avec succès !",
			"noValjustauto": "Il n'y a pas de valeurs à justifier.",
			"valjustautosuccess": "Des valeurs automatiquement justifiées !",
			"savjustautosuccess": "Mouvements automatiquement justifiés enregistrés avec succès !",
			"desejaefetuarjustmovi": "Vous souhaitez justifier des mouvements ouverts pour les valeurs saisies ?",
			"valimputnaopodsermaior": "Le montant à imputer ne peut pas être supérieur au montant à payer !"
		},
		"modal": {
			"justemseriecontas": "Justifier automatiquement les valeurs en série",
			"limiteperiodomaximo": "Limite la période maximale."
		},
		"btn": {
			"justDropdownTitle": "Justifier les valeurs",
			"justvalauto": "Justifier automatiquement les valeurs",
			"justvalautoserie": "Justifier automatiquement les valeurs en série",
			"justificarvalor": "Justifier la valeur",
			"justserie": "Justifier en série"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
		"leavePromptMessage": "En cliquant sur OUI, vous quitterez et supprimerez toutes les données de traitement.",
		"searchlimiteperiodomaximo": "Limite la période de recherche maximale."
	},
	"condicoescomerciais": {
		"title_detail": "Condition commerciale {{id}}",
		"title_new": "État commercial neuf",
		"title_plural": "Conditions commerciales",
		"pesquisa": "recherche",
		"saved": "Condition commerciale {{id}}, enregistrée avec succès.",
		"error": "Condition commerciale {{id}}, ne peut pas être enregistrée.",
		"deleted": "Condition commerciale {{id}}, supprimée avec succès.",
		"fields": {
			"codCondComerciais": "Code",
			"codCondComerciaisPlaceholder": "code condition commerciale",
			"descricao": "Description",
			"descricaoPlaceholder": "description",
			"nomeCondPagamento": "Conditions de paiement",
			"nomeCondPagamentoPlaceholder": "condition de paiement",
			"numDiasDataVenc": "Nombre de jours",
			"numDiasDataVencPlaceholder": "Nombre de jours",
			"tipoResumoMensal": "Type de résumé mensuel",
			"tipoResumoMensalPlaceholder": "type de résumé mensuel",
			"origemCondPagamento": "Condition de paiement, provient du client",
			"origemDataVencimento": "Nombre de jours et type de récapitulatif mensuel, provient de la condition de paiement"
		}
	},
	"condicoespagamento": {
		"title_detail": "Conditions de paiement {{id}}",
		"title_new": "Nouvelle condition de paiement",
		"title_plural": "Conditions de paiement",
		"pesquisa": "recherche",
		"saved": "Condition de paiement {{id}}, enregistrée avec succès.",
		"error": "La condition de paiement {{id}} ne peut pas être enregistrée.",
		"deleted": "Condition de paiement {{id}}, supprimée avec succès.",
		"fields": {
			"nCondPag": "Code.",
			"nCondPagPlaceholder": "Code condition de paiement",
			"descricao": "Description",
			"descricaoPlaceholder": "Description",
			"nDiasPP": "Nombre de jours PP",
			"nDiasPPPlaceholder": "Nombre de jours PP",
			"percDesconto": "Perc.",
			"percDescontoPlaceholder": "Pourcentage de remise",
			"numDiasDataVenc": "Nombre de jours (Expiration)",
			"numDiasDataVencholder": "Nombre de jours dus",
			"tipoResumoMensal": "Type de résumé mensuel",
			"tipoResumoMensalPlaceholder": "Type de résumé mensuel"
		}
	},
	"configEfaturaDocPorLanc": {
		"success": "Les paramètres ont été enregistrés avec succès",
		"fields": {
			"fatura": "Facture",
			"faturaRecibo": "Facture/Reçu",
			"notaDebito": "Note de débit",
			"notaCredito": "note de crédit",
			"reciboVerde": "Reçu vert électronique",
			"faturaSimplificada": "Facture simplifiée",
			"docConferencia": "Document de conférence",
			"faturaEstrangeiro": "Facture émise à l'étranger"
		},
		"text": {
			"mesmoPredefinido": {
				"title": "Erreur de configuration possible",
				"message": "La valeur par défaut des <strong>notes de crédit</strong> ne doit pas être la même que celle des autres types de documents."
			},
			"maintenancePreDefinidos": "Maintien d'une comptabilité prédéfinie"
		},
		"global": {
			"title": "Configuration du document pour la validation de la facture électronique",
			"fields": {
				"autoFixATDocWithDiffOneCent": "Corrige automatiquement les documents avec une différence de 1 centime",
				"criterioOrdenacao": "Ordre de grille",
				"mostraDocsFornIgnorados": "Affiche les documents des fournisseurs ignorés lors du rapprochement",
				"posicionaAutoNextDoc": "Se positionne automatiquement dans le document suivant après la validation du document"
			},
			"criterioOrdenacao": {
				"dataNome": "Date de facture |",
				"nomeData": "Nom du fournisseur |"
			}
		},
		"supplier": {
			"title": "Paramètres spécifiques au fournisseur",
			"fields": {
				"contaCorrente": "Compte courant à utiliser"
			}
		}
	},
	"configsERPCheck": {
		"state": {
			"load": "Chargement des validations",
			"genDefaultData": "Valider et générer des données par défaut",
			"validate": "Validations",
			"validating": "A valider",
			"repair": "A réparer",
			"finished": "Validation terminée"
		},
		"text": {
			"correction": "Correction"
		},
		"actions": {
			"validate": "Valider",
			"validateAll": "Valider tout",
			"repair": "Réparation",
			"repairAll": "Réparer tout"
		},
		"validateAllModal": {
			"title": "Générer les données par défaut de l'entreprise",
			"message": "Je souhaite générer par défaut les données de l'entreprise (types de documents, familles, TVA, types d'articles, etc.)"
		}
	},
	"configsErp": {
		"title_detail": "Valeur {{id}}",
		"title_plural": "Paramètres ERP",
		"pesquisa": "A chercher",
		"fields": {
			"name": "Nom",
			"description": "Description",
			"value": "Valeur",
			"readOnly": "Lecture seule"
		},
		"items": {
			"updated": "Valeur mise à jour",
			"clientesFornecedoresTitle": "Clients/Fournisseurs",
			"clientesFornecedores": {
				"contaIdentificacaoAlternativaTitle": "Compte d’identification client alternatif",
				"contaIdentificacaoAlternativaFornecedoresTitle": "Compte d'identification du fournisseur alternatif"
			},
			"contabilidadeTitle": "Comptabilité",
			"contabilidade": {
				"recibosTitle": "Recettes",
				"recibos": {
					"aplicaPercDescontoSobreValorIVATitle": "Appliquer % de réduction sur la valeur avec TVA",
					"reportImpressaoTitle": "Imprimer le rapport",
					"diarioLancamentosTitle": "Numéro quotidien",
					"descritivoLancamentosTitle": "Numéro descriptif.",
					"contaPocDescontProntoPagamentoTitle": "Numéro de compte de réduction POC",
					"meioPagamentoOmissaoTitle": "Mode de paiement par défaut"
				},
				"pagamentosTitle": "Paiements",
				"pagamentos": {
					"reportImpressaoTitle": "Imprimer le rapport",
					"diarioLancamentosTitle": "Numéro quotidien",
					"descritivoLancamentosTitle": "Numéro descriptif.",
					"contaPocDescontProntoPagamentoTitle": "Numéro de compte de réduction POC",
					"meioPagamentoOmissaoTitle": "Mode de paiement par défaut"
				},
				"decimaisTitle": "Décimales",
				"decimais": {
					"valorTitle": "Nombres décimaux pour la valeur",
					"descontoTitle": "Nombres décimaux pour remise",
					"percDescontoTitle": "Nombres décimaux pour le pourcentage de remise"
				},
				"balancetesTitle": "Bilans",
				"balancetes": {
					"reportImpressaoTitle": "Imprimer le rapport"
				},
				"extratosTitle": "Extraits",
				"extratos": {
					"reportImpressaoTitle": "Imprimer le rapport"
				},
				"movimentosemabertoTitle": "Mouvements ouverts",
				"movimentosemaberto": {
					"reportImpressaoTitle": "Imprimer le rapport"
				},
				"apuraivaTitle": "Apurement de la TVA",
				"documentosTitle": "Documents",
				"reparticaoccTitle": "Ventilations par centre de coûts",
				"reparticaocc": {
					"usadataTitle": "Utiliser les ventilations par date"
				},
				"listagemdiariosTitle": "Liste des agendas"
			},
			"documentosComerciaisTitle": "Documents commerciaux",
			"documentosComerciais": {
				"tiposTitle": "Types de documents",
				"tipos": {
					"outros": {
						"documentoPorDefeitoTitle": "Document par défaut",
						"visivelTitle": "Visible",
						"groupProperty": "autres"
					},
					"comprasEfectivasTitle": "Achats réels",
					"comprasEfectivas": {
						"documentoPorDefeitoTitle": "Document par défaut",
						"groupProperty": "achats efficaces"
					},
					"consultasaFornecedoresTitle": "Demandes de renseignements des fournisseurs",
					"consultasaFornecedores": {
						"documentoPorDefeitoTitle": "Document par défaut",
						"groupProperty": "consultationsfournisseurs",
						"otherTitle": "Budgets pour les fournisseurs"
					},
					"encomendasClientesTitle": "Commandes clients",
					"encomendasClientes": {
						"documentoPorDefeitoTitle": "Document par défaut",
						"groupProperty": "commandesClients"
					},
					"encomendasFornecedoresTitle": "Commandes fournisseurs",
					"encomendasFornecedores": {
						"documentoPorDefeitoTitle": "Document par défaut",
						"groupProperty": "commandesFournisseurs"
					},
					"folhasdeObraTitle": "Feuilles de travail",
					"folhasdeObra": {
						"documentoPorDefeitoTitle": "Document par défaut"
					},
					"guiasTransporteRemessaTitle": "Guides de transport maritime",
					"guiasTransporteRemessa": {
						"documentoPorDefeitoTitle": "Document par défaut",
						"groupProperty": "guidesTransportExpédition"
					},
					"propostasaClientesTitle": "Propositions aux clients",
					"propostasaClientes": {
						"documentoPorDefeitoTitle": "Document par défaut",
						"groupProperty": "propositionClients",
						"otherTitle": "Budgets pour les clients"
					},
					"reservasClientesTitle": "Réservations clients",
					"reservasClientes": {
						"documentoPorDefeitoTitle": "Document par défaut"
					},
					"trfArmazensEntradasTitle": "Trf.",
					"trfArmazensEntradas": {
						"documentoPorDefeitoTitle": "Document par défaut"
					},
					"trfArmazensSaidasTitle": "Trf.",
					"trfArmazensSaidas": {
						"documentoPorDefeitoTitle": "Document par défaut"
					},
					"vendasEfectivasTitle": "Ventes efficaces",
					"vendasEfectivas": {
						"documentoPorDefeitoTitle": "Document par défaut",
						"groupProperty": "ventes efficaces"
					},
					"consignacoesClientesTitle": "Envois clients",
					"consignacoesClientes": {
						"documentoPorDefeitoTitle": "Document par défaut"
					},
					"consignacoesFornecedoresTitle": "Envois fournisseurs",
					"consignacoesFornecedores": {
						"documentoPorDefeitoTitle": "Document par défaut"
					},
					"recibosClientesTitle": "Reçus clients",
					"recibosClientes": {
						"documentoPorDefeitoTitle": "Document par défaut"
					},
					"pagamentosFornecedoresTitle": "Paiements fournisseurs",
					"pagamentosFornecedores": {
						"documentoPorDefeitoTitle": "Document par défaut"
					},
					"noneTitle": "Aucune information",
					"none": {
						"documentoPorDefeitoTitle": "Document par défaut"
					},
					"planoProducaoTitle": "Plan de production",
					"planoProducao": {
						"documentoPorDefeitoTitle": "Document par défaut"
					},
					"preEncomendasClientesTitle": "Précommandes clients",
					"preEncomendasClientes": {
						"documentoPorDefeitoTitle": "Document par défaut"
					},
					"custosAdicionaisTitle": "Frais supplémentaires",
					"custosAdicionais": {
						"documentoPorDefeitoTitle": "Document par défaut"
					},
					"notaCreditoTitle": "note de crédit",
					"notaCredito": {
						"documentoPorDefeitoTitle": "Document par défaut"
					},
					"guiasTransporteFornecedoresTitle": "Fournisseurs de guides de transport",
					"guiasTransporteFornecedores": {
						"documentoPorDefeitoTitle": "Document par défaut",
						"groupProperty": "guidesTransportFournisseurs"
					},
					"entradasDiversasTitle": "Entrées diverses",
					"entradasDiversas": {
						"documentoPorDefeitoTitle": "Document par défaut",
						"groupProperty": "entréesDivers"
					},
					"saidasDiversasTitle": "Diverses sorties",
					"saidasDiversas": {
						"documentoPorDefeitoTitle": "Document par défaut",
						"groupProperty": "sortiesDivers"
					}
				}
			},
			"validacaoDasConfiguracoesTitle": "Validation des paramètres",
			"validacaoDasConfiguracoes": {
				"state": {
					"Load": "Chargement des validations",
					"GenDefaultData": "Valider et générer des données par défaut",
					"Validate": "Validations",
					"Validating": "A valider",
					"Repair": "A réparer",
					"Finished": "Validation terminée"
				},
				"errors": {
					"GenDefaultDataError": "Erreur lors de la génération des données par défaut"
				}
			},
			"empresaTitle": "Entreprise",
			"empresa": {
				"nomeTitle": "Nom de l'entreprise",
				"islockedTitle": "Entreprise bloquée",
				"motivoislockedTitle": "Raison pour laquelle l'entreprise a été bloquée",
				"verificacaoEfetuadaTitle": "Configuration vérifiée",
				"verificacaoNaoEfetuadaError": "Les paramètres de cette entreprise n'ont pas encore été vérifiés.",
				"periodoIvaTrimestralTitle": "Période de TVA trimestrielle"
			},
			"licencaTitle": "Licence",
			"licenca": {
				"licIdTitle": "Numéro de licence CentralGest",
				"nomeTitle": "Nom de la licence",
				"descricaoTitle": "Descriptif de la licence",
				"nempresasTitle": "Nombre maximum d'entreprises",
				"nutilizadoresTitle": "Nombre maximum d'utilisateurs",
				"portalcolaboradorTitle": "Portail collaboratif"
			},
			"erpTitle": "CentralGest",
			"erp": {
				"versaoTitle": "Version Cloud CentralGest",
				"dataversaoTitle": "Date de version de CentralGest Cloud",
				"emManutencaoTitle": "ERP en maintenance"
			},
			"rhTitle": "HEURE",
			"rh": {
				"refeicoesTitle": "Repas",
				"refeicoes": {
					"numMaxTitle": "Nombre maximum"
				}
			},
			"pcaTitle": "Ma comptabilité",
			"portalColaborador": {
				"title": "Portail collaboratif",
				"notificacao": "Notification",
				"desativado": "Désactivé",
				"diaria": "Tous les jours",
				"diaDaSemana": "Jour de la semaine",
				"hora": "Temps",
				"configGuardadaComSucesso": "La configuration des notifications du portail des employés a été enregistrée avec succès.",
				"conflitos": {
					"title": "Conflits",
					"colaboradores": "Collaborateurs",
					"permite": "Permet",
					"naoPermite": "Ne permet pas",
					"permiteMarcarFeriasComConflitos": "Vous permet de réserver des vacances avec des conflits",
					"fields": {
						"nEmpresa": "numéro d'entreprise",
						"nomeEmpresa": "Nom de l'entreprise",
						"colaborador1": "Collaborateur 1",
						"colaborador2": "Collaborateur 2"
					},
					"aplicarGeralEmTodas": "S'applique généralement à tous",
					"tooltips": {
						"aplicarGeralEmTodas": "Appliquer la configuration générale à toutes les entreprises",
						"apagarTodosConflitosDoEmpregado": "Supprimer tous les conflits d'employés"
					},
					"alteracoesNaoGuardadas": "Certaines modifications ne sont pas encore enregistrées.",
					"descartarAlteracoes": "Ignorer les modifications",
					"criarConflito": "Créer un conflit",
					"temQueSelecionarColaborador1": "Vous devez sélectionner le collaborateur 1",
					"temQueSelecionarPeloMenosUmColaborador": "Vous devez sélectionner au moins un collaborateur",
					"temACertezaApagarTodosConflitosDoEmpregado": "Êtes-vous sûr de vouloir supprimer tous les conflits pour l'employé « {{employé}} » ?",
					"temACertezaApagarEsteConflito": "Êtes-vous sûr de vouloir supprimer ce conflit ?",
					"colaborador1": "Collaborateur 1"
				},
				"prhtipocom": {
					"temACertezaApagarEsteTipoCom": "Êtes-vous sûr de vouloir supprimer ce type de communication ?",
					"criarTipoCom": "Créer un type de communication"
				}
			}
		},
		"check": {
			"disabledValidate": "Le processus de réparation est en cours, veuillez patienter.",
			"disabledRepair": "Le processus de validation est en cours, veuillez patienter."
		},
		"groups": {
			"comercial": {
				"requiredDefaultDoc": "Il est obligatoire d'avoir un document par défaut sélectionné"
			},
			"pca": {
				"optionsPeriodo": {
					"todos": "Toutes les périodes",
					"atePeriodoAtivoEmpresa": "Jusqu'à la période d'activité de l'entreprise",
					"ateUltimoPeriodoEncerrado": "Jusqu'à la dernière période clôturée"
				}
			}
		}
	},
	"configsSite": {
		"title_detail": "Valeur {{id}}",
		"title_plural": "Paramètres de messagerie",
		"pesquisa": "A chercher",
		"fields": {
			"configId": "Nom",
			"value": "Valeur"
		},
		"saved": "Configuration enregistrée.",
		"error": "Les paramètres de messagerie ne peuvent pas être enregistrés.",
		"testMail": "Tester le courrier",
		"configs": {
			"database.version": "Version de la base de données",
			"mail.smtp.adminemail": "E-mail de l'administrateur",
			"mail.smtp.siteemail": "Site Web de courrier électronique",
			"mail.smtp.smtppassword": "Mot de passe SMTP",
			"mail.smtp.smtpport": "Port SMTP",
			"mail.smtp.smtpserver": "Serveur SMTP",
			"mail.smtp.smtpuser": "Utilisateur SMTP",
			"mail.smtp.smtpusessl": "SMTP utilise SSL",
			"termsRGPDVersion": "Version des termes du RGPD"
		}
	},
	"contabAdvancedOperations": {
		"fields": {
			"dePeriodo": "période de",
			"deNDiario": "agenda",
			"deNDoc": "Numéro de document de",
			"periodoDestino": "Période cible",
			"diarioDestino": "Journal cible",
			"listaDocsNaoPermitidos": "Documents non modifiables",
			"listaDocsPermitidos": "Documents modifiables"
		},
		"list": {
			"fields": {
				"nPeriodo": "Période",
				"nDiario": "Tous les jours",
				"nDoc": "Numéro du document",
				"tipoErroNaoPermitido": "Raison",
				"seleccionado": "Sélection",
				"processado": "Traité"
			}
		},
		"messages": {
			"warning": "Lors de la réalisation de cette opération, tous les documents sélectionnés seront annulés et des copies d'entre eux seront créées dans la période {{période}}.",
			"warningAnular": "Lors de la réalisation de cette opération, tous les documents sélectionnés seront annulés.",
			"temQueSeleccionar": "Vous devez sélectionner au moins un document",
			"success": "Opération réalisée avec succès"
		},
		"fieldsAnulado": {
			"listaDocsNaoPermitidos": "Documents non annulables",
			"listaDocsPermitidos": "Documents modifiables"
		}
	},
	"continentes": {
		"pesquisa": "Continents",
		"fields": {
			"codContinente": "Indicatif du continent",
			"nomeContinente": "Nom du continent"
		},
		"data": {
			"portugal": "Continent",
			"acores": "Région des Açores",
			"madeira": "Région de Madère",
			"estrangeiro": "Étranger",
			"outros": "Autres"
		}
	},
	"contratos": {
		"title_detail": "Contrat {{id}}",
		"title_new": "Nouveau contrat",
		"title_plural": "Contrats",
		"pesquisa": "A chercher",
		"saved": "Contrat {{id}}, enregistré avec succès",
		"error": "Le contrat {{id}} ne peut pas être enregistré.",
		"deleted": "Contrat {{id}}, supprimé avec succès",
		"fields": {
			"cab": {
				"refCtrCab": "Code du contrat",
				"nClasseCtr": "Classe",
				"nClasseCtrPlaceholder": "Code de classe de contrat",
				"nTipoContrato": "Taper",
				"nTipoContratoPlaceholder": "Type de contrat",
				"nContrato": "Numéro de contrat",
				"nContratoPlaceholder": "Numéro de contrat",
				"verContrato": "Version",
				"verContratoPlaceHolder": "Version du contrat",
				"nContratoAsString": "Contracter",
				"nContratoSemVersaoAsString": "Contracter",
				"descricao": "Description",
				"descricaoPlaceHolder": "Description du contrat",
				"nClifo": "Client / Fournisseur",
				"nClifoPlaceHolder": "Client / Fournisseur",
				"clifoNome": "Nom du client",
				"idIdeChav": "Groupe de comptes",
				"idIdeChavPlaceHolder": "Groupe de comptes",
				"dataCtr": "Date du contrat",
				"dataCtrPlaceHolder": "Date du contrat",
				"dataInicio": "Date de début",
				"dataInicioPlaceHolder": "Date de début",
				"dataFim": "Date de fin",
				"dataFimPlaceHolder": "Date de fin",
				"estado": "État",
				"estadoPlaceHolder": "Code d'état",
				"ccusto": "centre de coûts",
				"ccustoPlaceHolder": "Code du centre de coûts",
				"nRefProcesso": "Processus",
				"nRefProcessoPlaceHolder": "Code de processus",
				"codMoeda": "Pièce de monnaie",
				"codMoedaPlaceHolder": "Code devise",
				"refExterna": "Référence externe",
				"terminado": "Fini",
				"totalLiquido": "Valeur",
				"valorGlobal": "Valeur globale",
				"codCondComerciais": "État commercial",
				"codCondComerciaisPlaceHolder": "Code des conditions commerciales"
			},
			"linhas": {
				"nArtigo": "Code article",
				"nomeArtigo": "Description",
				"qtd1": "Quantité",
				"prVenda": "Unité Pr.",
				"d1": "D1",
				"d2": "D2",
				"d3": "D3",
				"desconto": "Rabais",
				"d4": "D4",
				"d5": "J5",
				"d6": "D6",
				"codIva": "Code TVA",
				"nArmazem": "Stockage",
				"cunimo": "Articles.",
				"qtdPUnid": "Qté./Unité.",
				"nVendedor": "Vendeur",
				"ccusto": "centre de coûts",
				"nZona": "Zone",
				"nDepto": "Département",
				"nSubde": "Sous-département",
				"qtd1Fact": "Quantité facturée",
				"nRefProcesso": "Processus",
				"estado": "État",
				"obs": "Observations",
				"valorLiquido": "Liquide",
				"taxaIva": "Taux de TVA"
			}
		},
		"detail": {
			"cab": {
				"dataCtr": "Date du contrat",
				"nClifo": "Client / Fournisseur",
				"dataFim": "Date de fin",
				"dataInicio": "Date de début",
				"nContribuinte": "Numéro de contribuable",
				"codPostal": "Code Postal"
			}
		},
		"tabs": {
			"linhas": "Lignes"
		},
		"config": {
			"verGrupoConta": "Afficher le compte du groupe",
			"verMoeda": "Afficher la devise",
			"verRefExterna": "Afficher la référence externe",
			"verCondComerciais": "Afficher les conditions de trading",
			"verVendedor": "Afficher le vendeur",
			"verNCCusto": "Afficher le centre de coûts",
			"verD1": "Afficher D1",
			"verArmazem": "Afficher l'entrepôt",
			"verUnidadeMovimento": "Afficher l'unité de mouvement"
		},
		"totais": {
			"resumo": "Résumé"
		},
		"print": {
			"config": {
				"showClasses": "Afficher le filtre de classe"
			},
			"filters": {
				"classes": "Cours",
				"declifo": "Client de",
				"dencontrato": "numéro de contrat",
				"dtdedatactr": "Date du contrat",
				"dtdefim": "Date de fin de",
				"dtdeini": "Date de début de",
				"tipos": "Types de contrat"
			}
		},
		"errors": {
			"artigoBloqueadoDesativado": "L'article {{nArtigo}} est bloqué ou désactivé !",
			"notSelected": "Il n'y a aucun contrat sélectionné",
			"terminado": "Le contrat {{contract}} est déjà terminé",
			"notTerminado": "Le contrat {{contract}} n'est pas résilié",
			"reabrir": "Réouverture annulée",
			"terminar": "Terminer l'action annulée",
			"apagar": "Action d'effacement annulée"
		},
		"btn": {
			"editar": "Modifier",
			"reabrir": "Rouvrir",
			"terminar": "Finition",
			"apagar": "Pour éteindre"
		},
		"info": {
			"terminado": "Fini",
			"reabrir": "Réouvrir le contrat",
			"terminar": "Fin du contrat",
			"apagar": "Supprimer le contrat"
		},
		"list": {
			"config": {
				"showNaoAtivosOnly": "Voir uniquement terminé et expiré"
			}
		}
	},
	"contratostiposervicos": {
		"title_detail": "Type d'alliance {{id}}",
		"title_new": "Nouveau type d'accord",
		"title_plural": "Types d'engagements",
		"pesquisa": "A chercher",
		"saved": "Type d'alliance {{id}}, enregistré avec succès",
		"error": "Le type d'alliance {{id}} ne peut pas être enregistré.",
		"deleted": "Type d'alliance {{id}}, supprimé avec succès",
		"fields": {
			"id": "Code du type de contrat de classe",
			"nClasseCtr": "Code",
			"nClasseCtrPlaceholder": "Code de classe de réception",
			"classeTipo": "Classe de type",
			"classeTipoPlaceholder": "Classe de type de contrat",
			"nTipoContrato": "Numéro du type d'accord",
			"nTipoContratoPlaceholder": "Type de contrat, s'il n'est pas renseigné, il sera généré automatiquement.",
			"nome": "Nom",
			"nomePlaceholder": "Nom du type de contrat",
			"periocidade": "Périodicité",
			"periocidadePlaceholder": "Fréquence du contrat",
			"nDocfa": "Type de document",
			"nDocfaPlaceholder": "Type de document",
			"nNumer": "Numérotage",
			"nNumerPlaceholder": "Numérotation des types de documents",
			"nomeClasse": "Classe",
			"nomeClassePlaceholder": "Classe payante",
			"processaDiferimento": "Report du processus",
			"faturacaomes": "Facturation mensuelle",
			"docComercialAssinar": "Signer",
			"docComercialEncerrar": "Signer/Fermer",
			"txtContrato": "Observations",
			"variables": "Variables",
			"proceAuto": "Traitement automatique",
			"procAutoTipo": "Planification",
			"procAutoDiaFixo": "Jour fixe",
			"procAutoEnviaMailTitle": "Le traitement sera exécuté pendant la journée spécifiée",
			"procAutoEnviaMail": "Envoyer automatiquement un e-mail au client",
			"proAutoEmailResponsavel": "E-mail responsable"
		},
		"showFaturacaoMes": "Affiche la facturation pour le mois",
		"actions": {
			"nnumer": "Générer automatiquement un nouveau numéro",
			"decrementafaturacaomes": "Diminuer le mois de facturation",
			"incrementafaturacaomes": "Augmenter le mois de facturation"
		},
		"infoFaturacaoMes": {
			"info": "La facturation et la prestation de service se réfèrent au même mois.",
			"infoPositiveValue": "Exemple : la facturation du mois d'août concerne une prestation déjà fournie le mois de {{month}}.",
			"infoNegativeValue": "Exemple : la facturation du mois d'août concerne une prestation à fournir le mois suivant de {{month}}."
		},
		"messages": {
			"emailNotification": "Envoi d'une notification avec rapport de traitement",
			"txtContrato": "Notes visibles sur la facture"
		},
		"datasource": {
			"none": "Inactif",
			"primeiroDiaUtil": "1er jour ouvrable",
			"ultimoDiaUtil": "Dernier jour ouvrable",
			"primeiroDiaDoPeriodo": "1er jour des règles",
			"ultimoDiaPeriodo": "Dernier jour de la période",
			"diaFixo": "Jour fixe"
		}
	},
	"contratostipos": {
		"title_detail": "Type de contrat {{id}}",
		"title_new": "Nouveau type de contrat",
		"title_plural": "Types de contrats",
		"pesquisa": "A chercher",
		"saved": "Type de contrat {{id}}, enregistré avec succès",
		"error": "Le type de contrat {{id}} ne peut pas être enregistré.",
		"deleted": "Type de contrat {{id}}, supprimé avec succès",
		"fields": {
			"id": "Code du type de contrat de classe",
			"nClasseCtr": "Code",
			"nClasseCtrPlaceholder": "Code de classe de contrat",
			"classeTipo": "Classe de type",
			"classeTipoPlaceholder": "Classe de type de contrat",
			"nTipoContrato": "Type de contrat",
			"nTipoContratoPlaceholder": "Type de contrat",
			"nome": "Nom",
			"nomePlaceholder": "Nom du type de contrat",
			"periocidade": "Périodicité",
			"periocidadePlaceholder": "Périodicité du type de contrat",
			"nDocfa": "Type de document",
			"nDocfaPlaceholder": "Type de document",
			"nNumer": "Numérotage",
			"nNumerPlaceholder": "Numérotation des types de documents",
			"nomeClasse": "Classe",
			"nomeClassePlaceholder": "Classe de type de contrat"
		}
	},
	"controloIVA": {
		"title_detail": "Contrôle de la TVA {{id}}",
		"title_new": "Nouveau code.",
		"title_plural": "Contrôle de la TVA",
		"pesquisa": "A chercher",
		"saved": "Code de contrôle TVA {{id}}, enregistré avec succès.",
		"error": "Le code de contrôle TVA {{id}} ne peut pas être enregistré.",
		"deleted": "Code de contrôle de TVA {{id}}, supprimé avec succès.",
		"fields": {
			"codControloIVA": "Code de contrôle TVA",
			"descAbreviada": "Description",
			"descCompleta": "Descriptif complet",
			"tipoBem": "Type de bien",
			"tipoTaxa": "Type de frais",
			"tipoOperacao": "Type d'opération",
			"tipoLiquidacao": "Type de règlement",
			"tipoTransmissaoAct": "Type de transmission active",
			"tipoTransmissaoPas": "Type de transmission.",
			"mercado": "Marché",
			"areaRegional": "Zone régionale",
			"inversaoSujPassivo": "Inversion sale",
			"tipoDeducao": "Type de déduction",
			"decPERBASETRIBUT": "Code décembre.",
			"decANUBASETRIBUT": "Code décembre.",
			"codIVA": "Code TVA",
			"nContaIVADEDLIQREG": "Compte TVA nette",
			"nContaVTNAODEDUT": "Compte VT non déductible",
			"nContaIVANAODEDUT": "Compte TVA non déductible",
			"nContaVTNDEDPROR": "Compte VT non enregistré.",
			"nContaIVANDEDPROR": "Compte TVA non déduit.",
			"nContaVTLIQUIDRCH": "Compte 99 val.",
			"nContaIVALIQUIDRCH": "Compte 24 net TVA.",
			"decPERIVADEDLIQREG": "",
			"decPERVTNAODEDUT": "",
			"decPERIVANAODEDUT": "",
			"decPERVTNDEDPROR": "",
			"decPERIVANDEDPROR": "",
			"decPERVTLIQUIDRCH": "Code décembre.",
			"decPERIVALIQUIDRCH": "",
			"decANUIVADEDLIQREG": "",
			"decANUVTNAODEDUT": "",
			"decANUIVANAODEDUT": "",
			"decANUVTNDEDPROR": "",
			"decANUIVANDEDPROR": "",
			"decANUVTLIQUIDRCH": "",
			"decANUIVALIQUIDRCH": "",
			"tipoValidacaoNIF": "Type de validation NIF",
			"usaVTNAODEDUTOrigem": "Utiliser le compte d'origine",
			"usaIVANAODEDUTOrigem": "Utiliser le compte d'origine",
			"usaVTNDEDPROROrigem": "Utiliser le compte d'origine",
			"usaIVANDEDPROROrigem": "Utiliser le compte d'origine",
			"isRegimeIvaCaixa": "Régime de TVA en espèces",
			"regularizacoesC40": "Champ 40 type de régularisation",
			"regularizacoesC41": "Champ 41 type de régularisation",
			"regularizacoesC40RCH": "Champ 40 type de régularisation",
			"regularizacoesC41RCH": "Champ 41 type de régularisation",
			"codIVANome": "Code TVA",
			"decPERBASETRIBUTNome": "Code décembre.",
			"decANUBASETRIBUTNome": "Code décembre.",
			"nCONTAIVADEDLIQREGNome": "Compte TVA nette",
			"nCONTAVTNAODEDUTNome": "Compte VT non déductible",
			"nCONTAIVANAODEDUTNome": "Compte TVA non déductible",
			"nCONTAVTNDEDPRORNome": "Compte VT non donné au prorata",
			"nCONTAIVANDEDPRORNome": "Compte TVA non déduit.",
			"nCONTAVTLIQUIDRCHNome": "Compte 99 val.",
			"nCONTAIVALIQUIDRCHNome": "Compte 24 net TVA.",
			"tipoRegularizacaoCampo40": "Champ 40 type de régularisation",
			"tipoRegularizacaoCampo41": "Champ 41 type de régularisation",
			"nContaIvaCativo": "Compte TVA captif",
			"tipoRegularizacao": "Type de régularisation",
			"declaracaoPerio": "Le champ de déclaration périodique : {{info}}"
		},
		"steps": {
			"operacaoIVA": "Type d'opération TVA",
			"camposIVA": "Champs et comptes TVA liés à la base fiscale"
		},
		"helper": {
			"taxaAtual": "Tarif actuel",
			"percDedGasoleo": "Perc.",
			"percDedProRata": "Perc.",
			"decPeriodicaIva": "Arrêté périodique TVA",
			"decAnualIva": "Arrêté annuel TVA"
		},
		"btn": {
			"controloIVAEntreEmpresas": "Copier le contrôle de la TVA pour les entreprises"
		}
	},
	"controlosIvaEntreEmpresas": {
		"navwizard": {
			"controlosIva": "Contrôles TVA",
			"empresas": "Entreprises",
			"erros": "Erreurs/Avertissements",
			"final": "Fin"
		},
		"grid": {
			"fields": {
				"nEmpresa": "Code de l'entreprise",
				"nomeEmpresa": "Nom de l'entreprise",
				"codControloIVA": "Code de contrôle TVA",
				"nomeControloIVA": "Nom du contrôle TVA",
				"erros": "Erreurs/Avertissements",
				"copiar": "Ignorer les avertissements et copier"
			}
		},
		"confirmacaoOkCancel": {
			"message": "Etes-vous sûr de vouloir copier les codes de contrôle TVA sélectionnés pour les entreprises ?"
		},
		"success": "Copie effectuée avec succès"
	},
	"conttrabbaselegal": {
		"title_detail": "Base juridique du contrat de travail {{id}}",
		"title_new": "Nouvelle base juridique pour les contrats de travail",
		"title_plural": "Bases juridiques des contrats de travail",
		"pesquisa": "A chercher",
		"saved": "Base juridique du contrat de travail {{id}}, enregistrée avec succès.",
		"error": "La base juridique du contrat de travail {{id}} ne peut pas être enregistrée.",
		"deleted": "Base juridique du contrat de travail {{id}}, supprimée avec succès.",
		"fields": {
			"contTrabBaseLegal": "Code de base juridique",
			"tipoContrato": "Type de contrat",
			"descricao": "Description",
			"duracaoMinima": "Nombre minimum de jours",
			"duracaoMaxima": "Nombre de jours de durée maximale (renouvellement ind.)",
			"duracaoMaxPrimEmpreg": "Nombre de jours de durée maximale premier emp.",
			"dilacaoNovoContrato": "Nombre de jours de prolongation pour nouveau contrat",
			"numRenovNormal": "Renouvellement n°",
			"numRenovExcepcionais": "Renouvellement n°",
			"passagemSemTermo": "Nombre de jours après la date du den., devient effectif",
			"contTBLNovaSeq": "Nouveau code juridique après les renouvellements",
			"periodoExpNormalInd": "Normale",
			"periodoExpTecnicoInd": "Durée indéterminée technique",
			"periodoExpSuperioInd": "Durée indéterminée supérieure",
			"periodoExpTermoCerto": "Terme correct",
			"preAvisoDenunNormal": "Normale",
			"preAvisoDenunTecnico": "Durée indéterminée technique",
			"preAvisoDenunSuperio": "Durée indéterminée technique",
			"preAvisoDenunTermoC": "Terme correct",
			"feriasPorMesCompl": "Vacances par mois complet",
			"feriasMaximo": "Vacances maximales",
			"aviDesvincTrab": "Avis de fin de travail.",
			"aviCaducidadeTrab": "Avis de fin de travail",
			"aviCaducidadeEmpresa": "Avis d'expiration de l'entreprise",
			"compCaducidadeEmpre": "Indemnité d'expiration"
		}
	},
	"conttrabcab": {
		"title_detail": "Contrat de travail {{id}}",
		"title_new": "Nouveau contrat de travail",
		"title_plural": "Contrats de travail",
		"pesquisa": "A chercher",
		"saved": "Contrat de travail {{id}}, enregistré avec succès.",
		"error": "Contrat de travail {{id}}, ne peut pas être enregistré.",
		"deleted": "Contrat de travail {{id}}, supprimé avec succès.",
		"fields": {
			"nContrato": "Numéro de contrat",
			"codEmp": "Code employé",
			"descricao": "Description",
			"nCodBaseLegalContr": "Code de base juridique",
			"codEstado": "État",
			"vigor": "En vigueur",
			"dataCelebracaoContr": "Date de célébration",
			"dataInicioTrab": "Date de début des travaux",
			"dataCessacao": "Date de résiliation",
			"actContratada": "Activité sous contrat",
			"retribuicao": "Châtiment",
			"localTrab": "Lieu de travail",
			"perioNormalTrab": "Période normale de travail",
			"termo": "Indication des conditions stipulées",
			"motivoTermo": "Raison justifiant le terme",
			"codContratoMotivo": "Code motif",
			"observacoes": "Observations"
		}
	},
	"conttrabmotivo": {
		"title_detail": "Raison du contrat de travail {{id}}",
		"title_new": "Nouveau motif de contrat de travail",
		"title_plural": "Raisons du contrat de travail",
		"pesquisa": "A chercher",
		"saved": "Raison du contrat de travail {{id}}, enregistré avec succès.",
		"error": "Le motif du contrat de travail {{id}} ne peut pas être enregistré.",
		"deleted": "Raison du contrat de travail {{id}}, supprimé avec succès.",
		"fields": {
			"codContTrabMotivo": "Code motif du contrat de travail",
			"descrBreve": "Brève description",
			"descrCompleta": "Descriptif complet",
			"propriedadeMotivo": "Propriété"
		}
	},
	"cookies": {
		"consent": {
			"header": "<p>CentralGest Cloud utilise des cookies pour personnaliser le contenu de l'application.</p><p>Avant de commencer <strong>nous avons besoin de votre consentement</strong> pour stocker des cookies sur votre appareil.</p>",
			"about": {
				"1": "Les cookies sont de petits fichiers texte utilisés par l'application pour rendre l'expérience utilisateur plus efficace.",
				"2": "La loi prévoit que nous pouvons stocker des cookies sur votre appareil s'ils sont strictement nécessaires au fonctionnement normal de l'application.",
				"3": "CentralGest Cloud utilise différents types de cookies, dont certains sont placés par des services externes apparaissant sur nos pages."
			}
		},
		"purpose": {
			"x-auth-token": "Identifie l'utilisateur et lui permet de l'authentifier sur le serveur",
			"reCaptcha": "reCAPTCHA protège l'application contre le spam et d'autres types d'abus automatisés"
		}
	},
	"crabd": {
		"title_detail": "Franchissement de l'allocation/remise {{id}}",
		"title_new": "Nouveau croisement d’allocation/remise",
		"title_plural": "Franchissement des allocations/remises",
		"pesquisa": "A chercher",
		"deleted": "Traversée supprimée avec succès.",
		"saved": "Traversée enregistrée avec succès.",
		"error": "Le croisement ne peut pas être sauvegardé.",
		"updated": "Crossover mis à jour avec succès.",
		"fields": {
			"tipoAbDescOrigem": "Taper",
			"codOrigem": "Allocation / Remise",
			"nCodOrigem": "Code",
			"tipoAbDescDestino": "Allocation / Remise",
			"codDestino": "Allocation / Remise",
			"nCodDestino": "Code",
			"entSuporteEmpregado": "Employé",
			"entSuportePatronato": "Patronage",
			"incidenciaEmpregado": "(%) Incidence",
			"incidenciaPatronato": "(%) Incidence",
			"tipoExcecao": "Type d'exception",
			"categoriaAbDescDestino": "Catégorie",
			"excecoes": "Exceptions"
		},
		"tableFields": {
			"crabdList": "Liste des passages",
			"categoria": "Catégorie",
			"cruzamento": "Passage",
			"empregado": "Employé",
			"patronato": "Patronage",
			"abonoDesconto": "ANNONCE",
			"desconto": "Rabais",
			"cod": "Code",
			"nome": "Nom",
			"cat": "chat",
			"excecao": "Il y a une exception"
		},
		"removeCruzamento": "Êtes-vous sûr de vouloir supprimer l'intersection ?",
		"cruzdelsuccess": "Crossover supprimé avec succès."
	},
	"crabonos": {
		"title_detail": "Traversée d'Abono {{id}}",
		"title_new": "Nouveau passage à niveau des allocations",
		"title_plural": "Passages de franchise",
		"pesquisa": "A chercher",
		"deleted": "Traversée supprimée avec succès.",
		"saved": "Traversée enregistrée avec succès.",
		"error": "Le croisement ne peut pas être sauvegardé."
	},
	"crdescontos": {
		"title_detail": "Passage à prix réduit {{id}}",
		"title_new": "Nouveau crossover à prix réduit",
		"title_plural": "Croix à prix réduit",
		"pesquisa": "A chercher",
		"deleted": "Traversée supprimée avec succès.",
		"saved": "Traversée enregistrée avec succès.",
		"error": "Le croisement ne peut pas être sauvegardé."
	},
	"criterioDisponiveis": {
		"pesquisa": "Critères disponibles",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"weightedAverageCost": "Coût moyen pondéré",
			"lifo": "Lifo (dernier entré, premier sorti)",
			"fifo": "Fifo (premier entré, premier sorti)",
			"standardCost": "Coût standard",
			"averageCost": "Coût moyen",
			"specificLot": "Lot spécifique"
		}
	},
	"crneg": {
		"title_detail": "Employé {{id}} Exception de passage à niveau",
		"title_new": "Nouvelle exception de passage à niveau",
		"title_plural": "Exceptions de croisement",
		"pesquisa": "A chercher",
		"deleted": "L'exception de croisement a été effacée avec succès.",
		"saved": "Exception de croisement enregistrée avec succès.",
		"error": "L’exception de croisement ne peut pas être enregistrée.",
		"fields": {
			"nCodEmp": "Code employé",
			"nomeEmp": "Nom de l'employé",
			"nCodOrigem": "Code source",
			"nCodDestino": "code de destination",
			"entSuporte": "Entité de support"
		},
		"modalCRNEGTitle": "Indiquez les employés à inclure"
	},
	"crss": {
		"title_detail": "Régime de sécurité sociale {{id}}",
		"title_new": "Nouveau régime de sécurité sociale",
		"title_plural": "Sécurité sociale",
		"listRegimes": "Régimes de sécurité sociale",
		"pesquisa": "A chercher",
		"deleted": "Régime de sécurité sociale supprimé avec succès.",
		"saved": "Régime de sécurité sociale {{id}}, enregistré avec succès.",
		"error": "Le régime de sécurité sociale {{id}} ne peut pas être enregistré.",
		"nifentidades": "NIF des entités",
		"fields": {
			"ncrss": "Code CRSS",
			"nome": "Nom",
			"designaBreve": "Brève désignation",
			"morada": "Ménage",
			"localidade": "Emplacement",
			"npostal": "Code postal",
			"telefone": "Téléphone",
			"fax": "Fax",
			"nbenefCaixa": "Numéro d’identification",
			"crssSedeSocial": "CRSS du SIÈGE",
			"nifSegSocial": "NIF sec.",
			"nifCGA": "FNI CGA",
			"nifADSE": "NIF ADSE"
		},
		"table": {
			"nclcx": "Classe de boîte",
			"nomeregime": "Nom du régime",
			"percentPatr": "% contribution.",
			"percentEmpr": "% contribution.",
			"codIntegracao": "Code d'intégration",
			"naoVaiSegSocial": "Envoyer des données à SS"
		}
	},
	"dashboards": {
		"erp": {
			"pageTitle": {
				"vendas": "Chiffre d'affaires",
				"tesourariaLiquida": "Trésorerie liquide",
				"rendimentos": "Revenu",
				"gastosCompras": "Frais d'achats"
			},
			"vendas": {
				"ano": "{{année}} ventes",
				"dia": "Ventes du jour",
				"mes": "Ventes du mois",
				"vendasAno": "Ventes de l'année",
				"anoTop": "Cinq articles les plus vendus en {{year}}"
			},
			"clientes": {
				"title": "Clients",
				"ano": "Nouveaux clients à partir de {{year}}",
				"dia": "Nouveaux clients du jour",
				"mes": "Nouveaux clients du mois",
				"clientesAno": "Nouveaux clients de l'année",
				"anoTop": "Top 5 des plus gros clients de {{year}}",
				"legendaClienteAno": "{{year}} clients"
			},
			"table": {
				"nome": "Nom",
				"valor": "Valeur",
				"percentagem": "Pourcentage",
				"legends": {
					"vendasAno": "Ventes par mois",
					"tesourariaLiquidaDisponibilidade": "Disponibilité",
					"tesourariaLiquidaFinanciamento": "Financement"
				}
			},
			"compras": {
				"title": "Achats",
				"ano": "Achats à partir de {{year}}",
				"dia": "Les achats du jour",
				"mes": "Achats du mois",
				"anoTop": "Top 5 des articles achetés en {{year}}"
			},
			"fornecedores": {
				"title": "Fournisseurs",
				"ano": "Nouveaux fournisseurs à partir de {{year}}",
				"dia": "Nouveaux fournisseurs du jour",
				"mes": "Nouveaux fournisseurs du mois",
				"anoTop": "Top 5 des plus grands fournisseurs de {{year}}"
			},
			"comprasVendas": {
				"title": "Ventes et achats",
				"titleParam": "{{year}} ventes et achats",
				"legendas": {
					"vendas": "Ventes",
					"compras": "Achats",
					"diference": "Différence"
				}
			}
		},
		"financeiro": {
			"tableContasAOrdem": {
				"title": "Connectez-vous aux banques en temps réel",
				"descricao": "Description",
				"nomeBanco": "Nom de la banque",
				"iban": "IBAN",
				"saldoAtual": "Solde courant"
			},
			"tableValoresEmAberto": {
				"title": "Montants totaux ouverts",
				"descricao": "Description",
				"valorEmAberto": "Valeur ouverte",
				"prazoMedio": "Durée moyenne (jours)"
			},
			"receberPagarTables": {
				"titleReceber": "Montants à recevoir des clients",
				"titlePagar": "Montants à payer aux fournisseurs",
				"titleTableReceber": "Top 5 des clients ayant des créances",
				"titleTablePagar": "Top 5 des fournisseurs ayant des montants à payer",
				"naoVencido": "Non expiré",
				"vencido": "En retard"
			}
		},
		"rh": {
			"tiles": {
				"funcionariosAtivos": "Salariés actifs",
				"entradas": "Entrées",
				"saidas": "Sorties",
				"subtitleMeses": "{{months}} derniers mois",
				"tempoInteiro": "À temps plein",
				"tempoParcial": "Temps partiel"
			},
			"graficos": {
				"funcionariosPorGenero": "Salariés par sexe",
				"funcionariosPorDepartamento": "Employés par département",
				"funcionariosPorIdade": "Employés par âge",
				"funcionariosPorTipoContrato": "Salariés par type de contrat",
				"custosFuncionarios": {
					"title": "Dépenses mensuelles",
					"vencimentosFaltas": "Salaires - Absences",
					"subRefeicao": "Sous.",
					"impostos": "Impôts"
				}
			}
		},
		"ativos": {
			"bensAtivos": "Actifs actifs",
			"nFichasAtivos": "Puces",
			"nAquisicoesAno": "Acquisitions",
			"nVendasAbatesAno": "Ventes et abattages",
			"valorAquisicao": "Valeur d'acquisition",
			"depreciacoesAcumuladas": "Amortissement accumulé.",
			"valorAtual": "Valeur actuelle",
			"pie": {
				"ativosTangiveis": "Immobilisations corporelles",
				"ativosInTangiveis": "Immobilisations incorporelles / Prop.",
				"nFichas": "Nombre de jetons : {{chips}}"
			},
			"table": {
				"topAtivos": "Top 10 - Valeur d'acquisition",
				"codAmortizacao": "Code décembre.",
				"nomeAmortizacao": "Description",
				"nFichas": "Nombre de jetons",
				"valorAq": "Valeur d'acquisition",
				"valorDep": "Valeur d'amortissement",
				"valorLiq": "Valeur nette",
				"percentDep": "% déprécié"
			},
			"bar": {
				"title": "Amortissements mensuels",
				"depMonth": "Amortissements par mois",
				"depAcumulado": "Amortissement cumulé"
			},
			"radical": {
				"rdl431": "Terres et ressources naturelles",
				"rdl432": "Bâtiments et autres constructions",
				"rdl433": "Équipement de base",
				"rdl434": "Matériel de transport",
				"rdl435": "Matériel administratif",
				"rdl436": "Équipement biologique",
				"rdl437": "Autres immobilisations corporelles",
				"rdl441": "Bonne volonté",
				"rdl442": "Projets de développement",
				"rdl443": "programmes informatiques",
				"rdl444": "Propriété industrielle",
				"rdl445": "Autres actifs incorporels",
				"rdl446": "Autres actifs incorporels",
				"rdl447": "Autres actifs incorporels",
				"rdl421": "Terres et ressources naturelles",
				"rdl422": "Bâtiments et autres constructions",
				"rdl423": "Autres immeubles de placement",
				"rdl424": "Autres immeubles de placement",
				"rdl425": "Autres immeubles de placement",
				"rdl426": "Autres immeubles de placement",
				"rdl427": "Autres immeubles de placement"
			},
			"messages": {
				"contab": "Il existe des acquisitions faites en comptabilité qui ne sont pas liées au patrimoine!",
				"contabLink": "Créer un formulaire à partir d'un enregistrement comptable",
				"investEmCurso": "Il y a des investissements en cours sans enregistrement créé.",
				"investEmCursoLink": "Créer un formulaire basé sur les investissements en cours"
			}
		}
	},
	"datavalor": {
		"title_detail": "Valeur de la date {{id}}",
		"title_new": "Nouvelle date de valeur",
		"title_plural": "Dates de valeur",
		"pesquisa": "A chercher",
		"saved": "Valeur des données {{id}}, enregistrée avec succès.",
		"error": "La valeur de date {{id}} ne peut pas être enregistrée.",
		"deleted": "Valeur de date {{id}}, supprimée avec succès",
		"fields": {
			"tipo": "Taper",
			"keyValue": "Valeur clé",
			"valor1": "Plafond d'impôt",
			"valor2": "Limite comptable",
			"valor3": "Taux",
			"dataInicio": "Date de début"
		}
	},
	"debDiretosSEPA": {
		"buttons": {
			"toolbarEmissao": "Émission",
			"toolbarImportar": "Importer",
			"clientesAderentes": "Clients abonnés",
			"notificar": "Notifier",
			"imprimir": "Imprimer",
			"consultaFicheirosSEPA": "Consultation de dossier SEPA"
		},
		"fields": {
			"tipo": "Taper",
			"valor": "Valeur",
			"digitos": "Nombre de chiffres",
			"estado": "État",
			"meioPagamento": "Demi-paiement",
			"iban": "IBAN",
			"idDebitoDireto": "identifiant de prélèvement automatique",
			"exampleIdDebito": "Ex : PTPTXXZZZXXXXXXXXXX",
			"nConta": "Numéro de compte",
			"contaBanco": "Compte bancaire",
			"nDiario": "Numéro quotidien",
			"diario": "Tous les jours",
			"nDescritivo": "Numéro descriptif.",
			"descritivo": "Descriptif",
			"nome": "Nom",
			"emailDD": "Prélèvements par email",
			"nAutorizacao": "Numéro d'autorisation",
			"inicio": "Commencer",
			"fim": "Fin",
			"contrato": "Contracter",
			"firstEnviado": "FRST Envoyé",
			"codMeioPagamento": "Code.",
			"dataInicio": "Date de début",
			"contaIntermedia": "Compte intermédiaire",
			"calendario": "Calendrier",
			"docFaturacao": "Document de facturation",
			"dataDoc": "Date du document.",
			"docContabilidade": "Document comptable.",
			"docContabilidadeLiquidacao": "Document comptable de règlement",
			"valorDD": "Valeur de la dette.",
			"erroLiquidacao": "Erreur de règlement",
			"dataHora": "Date/Heure",
			"utilizador": "Utilisateur",
			"operacao": "Opération",
			"observacao": "Observation",
			"nDocFa": "Type de document.",
			"nNumer": "Numéro de série.",
			"nDocumento": "Numéro du document",
			"documento": "Document",
			"valorEmAberto": "Valeur ouverte",
			"enviadoEmail": "Notification par courrier électronique",
			"xmlGerado": "XML généré",
			"titularIBAN": "Titulaire d'un IBAN",
			"tipoFicheiro": "Type de fichier",
			"nLoteFicheiro": "Lot de fichiers",
			"nLotePagamento": "Lot de paiement",
			"firstAsSTR": "Séq.",
			"nRegistos": "Nombre d'enregistrements",
			"fileName": "Déposer",
			"campo": "Champ",
			"mensagem": "Message",
			"notificado": "Notifié",
			"liquidacao": "Liquidation",
			"devolucao": "Retour",
			"comErro": "Avec erreur",
			"valorTotal": "Valeur totale",
			"nErroCG": "Nombre d'erreurs CG",
			"importValorImputado": "Valeur imputée",
			"valorLiquidacao": "Valeur de règlement",
			"recibo": "Reçu",
			"autorizacao": "Autorisation",
			"dataLiquidacao": "Date de règlement",
			"valorDoc": "Valeur du document."
		},
		"strings": {
			"tabConfigGerais": "Configuration.",
			"tabContasCredoras": "Comptes de crédit",
			"tabTiposDocumento": "Types de documents",
			"tabClientes": "Clients",
			"naoTemAcessoEstaConfiguracao": "Vous n'avez pas accès à cette configuration.",
			"dataVencimento": "Date d'expiration",
			"nDiasPrimeiraComunicacao": "Nombre de jours 1ère communication",
			"seguintes": "Suivant",
			"dataLiquidacao": "Date de règlement",
			"chaveNumeroAutorizacao": "Clé du numéro d'autorisation",
			"exemploChave": "Exemple clé",
			"modalTitleConfiguracao": "Prélèvements SEPA - Paramétrage",
			"semDebitosDiretos": "Pas de prélèvements automatiques",
			"comDebitosDiretos": "Avec prélèvements automatiques",
			"modalTitleContaCredora": "Prélèvements SEPA - Compte créditeur",
			"modalTitleConfigClientes": "Prélèvements SEPA - Clients abonnés",
			"integracaoEmContabilidade": "Intégration comptable",
			"oFormatoIDDebitoDiretoErrado": "Le format du « Direct Debit ID » ne suit pas l’exemple.",
			"haCamposNaoPreenchidos": "Certains champs non remplis peuvent provoquer des erreurs à l'avenir.",
			"titularConta": "Titulaire du compte",
			"nomeFichaCliente": "Nom de l'enregistrement client",
			"outroNome": "Un autre nom",
			"ibanCredor": "IBAN (créancier)",
			"contratos": "Contrats",
			"contrato": "Contracter",
			"autorizacao": "Autorisation",
			"consultar": "Consulter",
			"temSelecionarConta": "Vous devez sélectionner le compte",
			"jaFoiEnviadaPrimeiraComunicacaoFRST": "La première communication a déjà été envoyée (FRST)",
			"emailDebitosDiretosObrigatorio": "L’e-mail pour les prélèvements automatiques est obligatoire.",
			"ibanClienteObrigatorio": "L'IBAN du client est obligatoire.",
			"nContaObrigatorio": "Le numéro de compte est obligatoire.",
			"nAutorizacaoDDObrigatorioGerarAutomaticamente": "Le numéro d’autorisation de prélèvement est obligatoire.",
			"dataInicioNaoPodeSerSuperiorDataFim": "La date de début ne peut pas être supérieure à la date de fin",
			"naoAnexouAutorizacaoPertendeContinuar": "N'a pas joint l'autorisation pour les prélèvements automatiques.",
			"manual": "Manuel",
			"auto": "Soi",
			"descricao": "Description",
			"documento": "Document",
			"documentoAutorizacao": "Document d'autorisation",
			"dataParaLiquidacaoSeraADataVencDocumento": "La date de règlement sera la date d'échéance du document.",
			"dataParaLiquidacaoSeraCalcPelosDiasConfig": "La date de règlement sera calculée en fonction des jours configurés.",
			"dataParaLiquidacaoSeraCalcPelosDiasConfig2": "Attention: Les jours indiqués sont recommandés par les établissements bancaires.",
			"contaIntermediaParaLiquidacao": "Compte intermédiaire pour le règlement",
			"configSaved": "Les paramètres de prélèvement SEPA ont été enregistrés avec succès.",
			"configClientesSaved": "Les paramètres du client ont été enregistrés avec succès.",
			"configClienteDeleted": "Le client a été supprimé avec succès.",
			"codMeioPagamentoExisteNaLista1": "Le code demi-paiement sélectionné existe déjà dans la configuration.",
			"codMeioPagamentoExisteNaLista2": "Si vous enregistrez, cela mettra à jour l’enregistrement existant.",
			"ficheirosEmitidos": "Fichiers émis",
			"porLiquidar": "A régler",
			"liquidado": "Réglé",
			"emitido": "Émis",
			"estado": "État",
			"valor": "Valeur",
			"disponivelParaNovaEmissao": "Disponible pour un nouveau numéro",
			"documentoFaturacao": "Document (facturation)",
			"reciboLiquidacao": "Reçu (règlement)",
			"cliente": "Client",
			"historico": "Histoire",
			"logModalTitle": "Journal des documents {{ndoc}}",
			"anexos": "Pièces jointes",
			"comentarios": "Commentaires",
			"ddsepaEmissaoPorContaCorrente": "Prélèvements SEPA - Émission via compte courant",
			"gerarFicheiro": "Générer un fichier",
			"gerar": "Pour générer",
			"resumo": "Résumé",
			"notificacao": "Notification",
			"resumoPorContaCorrente": "Synthèse par compte courant",
			"registos": "Enregistrements",
			"dataVencimentoAte": "Date d'échéance avant",
			"dataLiquidacaoFirst": "Date de règlement (1ère fois)",
			"ficheirosGerados": "Fichiers générés",
			"errosEAvisos": "Erreurs et avertissements",
			"notificarDevedores": "Informer les débiteurs",
			"dadosAvancados": "Données avancées",
			"apagado": "Désactivé",
			"alterado": "Modifié",
			"novo": "Nouveau",
			"contaCredora": "compte de crédit",
			"impressaoEmissoesModalTitle": "Impression des émissions de domiciliation - SEPA",
			"importadorModalTitle": "Prélèvements SEPA - Importer",
			"processarFicheiro": "Traitement du dossier...",
			"importarFicheiro": "Importation du fichier...",
			"dados": "Données",
			"resumoLiquidacao": "Résumé du règlement",
			"processadosComSucesso": "Traité avec succès",
			"dadosDoFicheiro": "Données du fichier",
			"errosImportacao": "Erreurs d'importation",
			"importadosComSucesso": "Importé avec succès",
			"importadosComErro": "Importé avec erreur",
			"ficheiroProcessadoComSucesso": "Fichier traité avec succès",
			"resumoProcessamento": "Traitement récapitulatif",
			"liquidados": "Réglé",
			"devolvidos": "Retourné",
			"naoLiquidados": "Non liquidé",
			"imputado": "Imputé",
			"liquidadoSemImputacao": "Réglé sans imputation",
			"devolvido": "Retourné",
			"naoLiquidado": "Non liquidé",
			"valores": "Valeurs",
			"nDeRegistos": "Nombre d'enregistrements",
			"erroLiquidacao": "Erreur de règlement",
			"nDocumentos": "#Documents",
			"dataEmissao": "Date d'émission",
			"porNotificar": "En notifiant",
			"mostrar": "Montrer",
			"xNaoTemAutorizacaoAnexada": "{{count}} client(s) n'ont pas d'autorisation associée.",
			"obterAutorizacao": "Obtenir l'autorisation",
			"mostrarTodosOsRegistos": "{{count}} client(s) configuré(s).",
			"dataLiquidacaoFRST": "Date de règlement (1ère fois)",
			"dataLiquidacaoRCUR": "Date de règlement (suivante)"
		},
		"messages": {
			"temCertezaMarcarRegistosSelDisponiveisParaEmissao": "Etes-vous sûr de vouloir marquer les enregistrements sélectionnés comme disponibles pour une nouvelle émission ?",
			"temCertezaMarcarRegistoDisponiveisParaEmissao": "Etes-vous sûr de vouloir marquer l'enregistrement comme disponible pour une nouvelle émission ?",
			"semRegistosSelecionados": "Vous devez sélectionner au moins un enregistrement",
			"contaCredoraObrigatorio": "Le compte crédit est obligatoire",
			"nAutorizacaoObrigatorio": "Le numéro d'autorisation est obligatoire",
			"desejaImprimir": "Voulez-vous imprimer?",
			"devedoresNotificadosSucesso": "{{count}} débiteurs ont été informés avec succès.",
			"noFiles": "Sélectionnez le fichier à importer",
			"existemNdocumentosDisponiveisParaEmissao": "Il y a {{count}} documents disponibles pour émission.",
			"naoTemAutorizacaoAnexada": "Aucune autorisation n’est jointe.",
			"alteracaoMeioPagamentoMsg": "Tous les clients avec prélèvement automatique associés au mode de paiement {{codMeioPagamento}} ({{nomeMeioPagamento}}) sont désormais associés au nouveau mode de paiement indiqué et à l'IBAN correspondant.",
			"alteracaoIbanMsg": "Le mode de paiement {{codMeioPagamento}} ({{nomeMeioPagamento}}) avec l'IBAN \"{{iban}}\" sera mis à jour avec le nouvel IBAN indiqué.",
			"clienteComDDAlterados": "{{countClientes}} client(s) avec prélèvement automatique sur le compte créancier avec IBAN : \"{{iban}}\" auront désormais l'IBAN : \"{{newIban}}\" comme compte créancier.",
			"emailEnviadoComSucesso": "L'e-mail a été envoyé avec succès.",
			"emailEnviadoComErro": "Une erreur s'est produite lors de l'envoi de l'e-mail.",
			"temACertezaQuePertendeEnviarEmail": "Etes-vous sûr de vouloir envoyer l'e-mail avec le formulaire d'adhésion au prélèvement automatique ?",
			"temDocumentosPorLiquidarAteDataIni": "Vous avez {{count}} documents à régler avant la date de début.",
			"filtarRegistosQueNaoTemAutorizacao": "Afficher les clients pour lesquels aucune autorisation n’est jointe.",
			"temACertezaQuePertendeAnularFichEmitido": "Etes-vous sûr de vouloir annuler le fichier émis : {{loteFicheiro}} ?",
			"haErrosNosDocumentos": "Il y a des erreurs dans les documents d'importation.",
			"naoHaNenhumDadoParaImportar": "Il n'y a aucune donnée à importer.",
			"soPodeAnexarPdf": "Le type de fichier n'est pas autorisé.",
			"mostrarTodosOsClientes": "Afficher tous les clients avec et sans autorisation ci-jointe.",
			"naoPodeGerarFichComValorTotalZero": "Vous ne pouvez pas générer le fichier avec une valeur totale de 0.",
			"codMeioPagamentoObrigatorio": "Le code."
		},
		"tooltips": {
			"geradoAutomatico": "Généré automatiquement",
			"atencaoSoFicaraoDisponiveisDataDocSuperiorADataIni": "Attention : Seules les dettes dont la date du document est supérieure à la date de début seront disponibles.",
			"ibanDiffDoMeioPagamento": "L'IBAN \"{{iban}}\" est différent de l'IBAN \"{{ibanMeioPagamento}}\" du moyen de paiement {{codMeioPagamento}} ({{nomeMeioPagamento}})."
		},
		"tipoChaveAutorizacao": {
			"texto": "Texte",
			"conta": "Compte",
			"classeContrato": "Classe de contrat",
			"tipoContrato": "Type de contrat",
			"nContrato": "Numéro de contrat",
			"ibanCredor": "IBAN créancier",
			"ibanCliente": "IBAN client",
			"dia": "Jour",
			"hora": "Temps",
			"minutos": "Minutes",
			"sequencial": "Séquentiel"
		}
	},
	"declaracaoiva": {
		"configured": "<i class='fa fa-check' aria-hidden='true'></i> Le service est déjà configuré.",
		"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> Le service n'est pas configuré.",
		"btns": {
			"getXML": "Obtenir le fichier",
			"getRecapitulativaXML": "Obtenir le fichier récapitulatif",
			"btnValidarNaAT": "Valider sur AT",
			"btnConfiguracao": "Paramètres",
			"repor": "Réinitialiser la déclaration",
			"previsualizar": "Aperçu",
			"balancete": "Bilan",
			"documentosFiscais": "Documents fiscaux",
			"entregarDeclaracao": "Livrer",
			"configATLogin": "ÀConfiguration"
		},
		"tooltips": {
			"btnGuardarNotValid": "Pour sauvegarder le tableau 4, il doit être dûment complété",
			"btnGuardar": "La déclaration doit être annulée pour pouvoir enregistrer à nouveau",
			"btnGetXML": "La déclaration doit être sauvegardée pour obtenir la déclaration xml",
			"btnGetRecapitulativaXML": "La déclaration doit être sauvegardée pour obtenir la déclaration récapitulative xml",
			"validarNaAT": "La déclaration doit être sauvegardée pour pouvoir effectuer des opérations sur l'AT",
			"guardarPrimeiroParaRealizarOperacao": "La déclaration doit être sauvegardée pour pouvoir réaliser cette opération"
		},
		"messages": {
			"successValidarNaAt": "La déclaration ne contient pas d'erreurs et/ou d'avertissements.",
			"successSubmeterNaAt": "La déclaration périodique de TVA a été soumise avec succès via le webservice AT.",
			"help": "<strong>S'il est nécessaire d'apporter des modifications à la déclaration de TVA, vous devez :</strong></br>1 - Exporter le fichier xml en cliquant sur le bouton \"Obtenir le fichier xml\" ;</br>2 - Accéder le site Web de l'Autorité Taxe : <a class=\"text-white\" href=\"https://iva.portaldasfinancas.gov.pt/dpiva/portal/entregar-declaracao\"><strong> lien",
			"submitAtPromptMessage": "Êtes-vous sûr de vouloir soumettre la déclaration",
			"anuladasuccess": "Déclaration annulée!",
			"temDeGuardar": "Vous devez sauvegarder les données pour pouvoir créer le fichier",
			"savedsucessfully": "Déclaration enregistrée",
			"errorTextDataGridRowNotOk": "Toutes les données de ligne doivent être renseignées",
			"entreguesuccess": "La déclaration récapitulative de TVA a été soumise avec succès",
			"validateOperacoesSedeValoresIguais": "Les valeurs des champs 63 et 64 de {{nomeAnexo}} ne correspondent pas aux valeurs des champs {{campo1}} et {{campo2}} de l'annexe du siège."
		},
		"showmessage": {
			"title": "Ne plus montrer",
			"message": "\"Ok\" si vous ne voulez plus voir ce message !"
		},
		"text": {
			"attachment": "Annexe",
			"saved": "enregistré"
		},
		"tabs": {
			"anexos": "Pièces jointes",
			"rosto": "Affronter",
			"anexoRContinente": "Continent de l’Annexe R",
			"anexoRAcores": "Annexe R Açores",
			"anexoRMadeira": "Bois Annexe R",
			"anexoCampo40": "Champ annexe 40",
			"anexoCampo41": "Champ annexe 41",
			"adicionarAnexo": "Ajouter une pièce jointe",
			"sede": "(Soif)"
		},
		"anexos": {
			"comum": {
				"sim": "Oui",
				"nao": "Non"
			},
			"rosto": {
				"title": "Commencer",
				"quadro0": {
					"desc1": "Identification du contribuable",
					"nif": "Numéro d'identification fiscale",
					"locSede": "Emplacement du siège social",
					"desc2": "Identification de la déclaration",
					"periodo": "Période actuelle",
					"prazodecl": "Date limite de déclaration",
					"dentroprazo": "Dans les délais",
					"foraprazo": "Obsolète",
					"desc3": "Opérations dans un espace autre que le siège",
					"continente": "Continent",
					"acores": "Açores",
					"madeira": "Bois",
					"desc4A": "État récapitulatif",
					"check4A": "Indiquez si, au cours de la période de référence, vous avez soumis une déclaration sommaire",
					"desc5": "Aucune opération",
					"check5": "Si pendant la période à laquelle se rapporte la déclaration, vous n'avez pas réalisé d'opérations passives ou actives qui devraient être incluses dans le tableau 06, cochez dans ce tableau et passez au tableau 20.",
					"desc20": "Expert-comptable",
					"nifcert": "NIF d'expert-comptable"
				}
			},
			"anexoR": {
				"extratodecperio": "Extrait de champ de la déclaration périodique.",
				"valorReembolsoSolicitado": "Vous avez demandé un remboursement de TVA, mais vous n'avez pas encore traité les relations de remboursement.",
				"quadro6": {
					"title": "Autorisation",
					"desc": "Avez-vous réalisé des opérations de cette nature dans lesquelles, en tant qu'acheteur, vous avez payé la taxe ?",
					"point1": "À quoi font référence les paragraphes a), b) et c) de l’article 42 du CIVA ?",
					"point2": "A quoi font référence les alinéas f) et g) du n° 3 de l'article 3 et les alinéas a) et b) du n° 2 de l'article 4 du CIVA ?",
					"desc1": "Transfert de biens et prestation de services effectués sur lesquels la taxe a été payée",
					"basetribut": "Base imposable",
					"impfavorestado": "Taxe en faveur de l'État",
					"field1e2": "À tarif réduit",
					"field5e6": "Au tarif intermédiaire",
					"field3e4": "Au tarif normal",
					"isentaounaotrib": "Exonéré ou non imposé",
					"field7": "Transferts intracommunautaires de biens et services mentionnés dans les états récapitulatifs",
					"field8": "Opérations donnant droit à déduction",
					"field9": "Opérations n'ouvrant pas droit à déduction",
					"desc2": "Acquisitions intracommunautaires de biens et opérations assimilées",
					"field12e13": "dont l'impôt a été payé par le déclarant",
					"field14": "Couvert par les articles 15 du CIVA ou RITI",
					"field15": "Couvert par les paragraphes 3, 4 et 5 de l'article 22 du RITI",
					"field10e11": "Totaux",
					"desc3": "Services effectués par des contribuables d'autres États membres dont l'impôt a été payé par le déclarant",
					"desc4": "Importations de marchandises dont la taxe a été payée par le déclarant",
					"field62": "Assiette fiscale totale",
					"desc5": "Déductible d'impôt",
					"impostfavSujPassivo": "Taxe en faveur de la saleté.",
					"field20": "Actifs non courants (immobilisations)",
					"inventarios": "Inventaires (stocks)",
					"field21": "À tarif réduit",
					"field23": "Au tarif intermédiaire",
					"field22": "Au tarif normal",
					"field24": "Autres biens et services",
					"desc6": "Régularisations mensuelles/trimestrielles et annuelles",
					"desc6NotSedeAnexoTotalPassivo": "Impôt total en faveur du contribuable",
					"desc6NotSedeAnexoTotalImposto": "Impôt total en faveur de l'Etat",
					"desc7": "Excédent à déclarer de la période précédente",
					"field61": "Champ 96 de la déclaration précédente - paragraphe 4 de l'article 22.",
					"desc8": "Annexe R (1) Opérations dans un espace autre que le quartier général",
					"impdedutivel": "Déductible d'impôt",
					"impliquidado": "Impôt payé",
					"desc9": "Annexe R (2) Opérations dans un espace autre que le quartier général",
					"desc10": "Régularisations en faveur de l'assujetti communiquées par DS Cobranças (Mod. - BH008)",
					"desc11": "Totaux",
					"field90": "Assiette fiscale totale",
					"field91": "Impôt total en faveur du contribuable",
					"field92": "Impôt total en faveur de l'Etat",
					"field93": "Impôt à verser à l'Etat",
					"field94": "Crédit d'impôt récupérable",
					"field95": "Je demande un remboursement",
					"field96": "Franchise à déclarer"
				},
				"quadro6A": {
					"title": "Développement",
					"descA": "Opérations situées au Portugal dans lesquelles, en tant qu'acquéreur, vous avez payé la TVA due",
					"efetuadentresident": "Réalisé par des entités résidant en",
					"basetrib": "Base imposable",
					"field97": "Pays de la Communauté",
					"field98": "Territoires tiers",
					"descB": "Opérations pour lesquelles vous avez payé la TVA due en appliquant la règle d'inversion de l'assujetti",
					"field99": "Or",
					"field100": "Acquisitions de biens immobiliers sans exemption d'exonération",
					"field101": "Restes",
					"field102": "Services de construction civile",
					"field105": "Émissions de gaz à effet de serre",
					"field107": "Acquisition de liège et autres produits d'origine forestière",
					"descC": "Les opérations visées aux alinéas f) et g) du n° 3 de l'article 3 et aux alinéas a) et b) du n° 2 de l'article 4 du CIVA.",
					"field103": "Si vous avez réalisé des opérations de cette nature, indiquez leur valeur",
					"descD": "Opérations visées aux alinéas a), b) et c) de l'article 42 du CIVA",
					"field104": "Si vous avez réalisé des opérations de cette nature, indiquez leur valeur",
					"soma6A": "Somme du tableau 06-A",
					"desc10": "Les opérations visées aux alinéas f) et g) du n° 3 de l'article 3 et aux alinéas a) et b) du n° 2 de l'art.",
					"field264": "Si oui, indiquez le montant de la taxe payée, sans préjudice qu'il apparaisse dans les champs 2, 6 ou 4 de Q.06."
				},
				"modal": {
					"title": "Extrait du champ de déclaration périodique - Champ {{field}}",
					"fields": {
						"periodo": "Période",
						"nDiario": "Numéro quotidien",
						"nDocInterno": "Numéro du document",
						"nConta": "Numéro de compte",
						"nContrib": "Numéro de contribuable",
						"valorDebito": "Montant du débit",
						"valorCredito": "Valeur du crédit",
						"codIva": "Code TVA",
						"areaRegionalStr": "Zone régionale TVA",
						"nCampoOficial": "Terrain officiel",
						"contaOrigem": "Compte de base imposable",
						"nomeContaOrigem": "Nom de la base imposable",
						"valorBase": "Valeur de base"
					}
				}
			},
			"anexoCampo40": {
				"title": "Régularisations",
				"desc1": "Régularisations en faveur du contribuable",
				"desc2": "Régularisations dont le crédit n'excède pas 750 € TVA incluse",
				"baseincidenciareg": "Base d'incidence de la régularisation",
				"ivareg": "TVA régularisée",
				"field3e4": "La valeur du crédit ne dépasse pas 750 €",
				"desc3": "Autres régularisations",
				"field3Ae4A": "Régularisations visées aux articles 23 à 26",
				"field3Be4B": "Autres régularisations (Ex: fusion)",
				"desc4": "Valeur totale du champ 40",
				"desc5": "Certification par l'Auditeur Officiel (ROC)",
				"certirevisoroficialcontas": "Certification par le commissaire aux comptes",
				"nifroc": "Numéro d'identification fiscale du ROC",
				"modal": {
					"roc": {
						"title": "Numéro d'identification fiscale du ROC",
						"naopodeservazio": "Le numéro d’identification fiscale ROC ne peut pas être vide.",
						"jaexiste": "Le numéro d’identification fiscale ROC est déjà renseigné."
					}
				}
			},
			"anexoCampo41": {
				"title": "Régularisations",
				"desc1": "Des régularisations en faveur de l'État",
				"desc2": "Autres régularisations",
				"baseincidenciareg": "Base d'incidence de la régularisation",
				"ivareg": "TVA régularisée",
				"field3Ae4A": "Régularisations visées aux articles 23 à 26",
				"field3Be4B": "Autres régularisations",
				"field3Ce4C": "Article 6 du décret-loi n° 198/90 du 19 juin",
				"field3De4D": "Décret-loi n° 19/2017 du 14 février",
				"desc3": "Valeur totale du champ 41"
			},
			"table40e41": {
				"fields": {
					"tipoRegularizacaoCampo": "Type de régularisation",
					"nif": "Numéro de contribuable",
					"nPedido": "Numéro de commande",
					"baseIncidencia": "Base d'incidence de la régularisation",
					"ivaRegularizado": "TVA régularisée",
					"dataEmissao": "Date d'émission"
				}
			}
		},
		"recapitulativa": {
			"title": "Déclaration récapitulative de TVA",
			"table": {
				"paisDestinoStr": "Pays de destination (2)",
				"nContribuinte": "NIF de l'acquéreur (3)",
				"valorOperacoes": "Valeur en EURO (4)",
				"tipoOperacaoStr": "Type d'opération (5)"
			},
			"quadros": {
				"quadro1": {
					"title": "Image 01",
					"desc1": "Identification du contribuable",
					"nif": "Numéro d'identification fiscale"
				},
				"quadro2": {
					"title": "Cadre 02",
					"desc": "Type de déclaration",
					"primeira": "D'abord",
					"substituicao": "Remplacement",
					"desc1": "Y a-t-il eu un changement dans la fréquence d'expédition de trimestrielle à mensuelle ?",
					"field21": "Modification de la fréquence d'expédition de trimestrielle à mensuelle",
					"desc21": "Le montant mensuel remplace-t-il complètement le montant trimestriel déclaré précédemment ou non ?",
					"field22": "Aucune opération intracommunautaire au cours de la période concernée",
					"field23": "Autres changements dans les opérations intracommunautaires",
					"field24": "Transferts de marchandises en consignation",
					"field241": "Aucun changement dans la période respective",
					"field2411": "Aucun transfert de marchandises en consignation déclarées",
					"field2412": "Avec transferts de marchandises en consignation déclarés",
					"field242": "Avec des changements dans la période respective",
					"field243": "Déclarer pour la première fois au cours de la période concernée"
				},
				"quadro3": {
					"title": "Tableau 03",
					"desc": "Période à laquelle il se rapporte",
					"mensal": "Mensuel",
					"trimestral": "Trimestriel",
					"mesincltrimestre": "Mois inclus dans le trimestre"
				},
				"quadro45": {
					"title": "Tableau 04/05",
					"desc4": "Description des opérations intracommunautaires",
					"modalTitle": "Description des opérations intracommunautaires",
					"modalmessages": {
						"tipoopvazio": "Le type d'opération ne peut pas être vide.",
						"paisdestvazio": "Le pays de destination ne peut pas être vide."
					},
					"table": {
						"prefpaisdest": "Préfixe du pays de destination (2)",
						"nif": "NIF de l'acquéreur (3)",
						"valor": "Valeur (4)",
						"tipoop": "Type d'opération (5)"
					},
					"desc5": "Somme des valeurs déclarées dans le tableau 4 (regroupées par type d'opération)",
					"field10": "Somme (uniquement des valeurs relatives aux opérations typées dans la colonne 5 avec 1)",
					"field11": "Ventes totales de moyens de transport neufs aux particuliers et moyens assimilés en provenance des autres Etats membres",
					"field17": "Somme (uniquement des valeurs relatives aux opérations indiquées dans la colonne 5 avec 4)",
					"field18": "Somme (uniquement des valeurs relatives aux opérations indiquées dans la colonne 5 avec 5)",
					"field19": "Total (10+11+18)"
				},
				"quadro6": {
					"title": "Cadre 06",
					"desc6": "Description des transferts de marchandises en consignation",
					"table": {
						"prefpaisdest": "Préfixe du pays de destination (2)",
						"nif": "NIF du bénéficiaire (3)",
						"nifOriginal": "NIF du destinataire initial (4)",
						"codigo": "Code (5)"
					}
				}
			}
		}
	},
	"demfinsnc": {
		"fields": {
			"processafluxo": "Traiter les flux de trésorerie",
			"vercomdados": "Afficher uniquement les éléments avec des valeurs",
			"processaIntercalares": "Traiter les démos intermédiaires",
			"variaveisapuramento": "Variables de dédouanement provisoires",
			"periointercalartable": "Inventaire final de la période intermédiaire de l'année \"{{year}}\""
		},
		"tabs": {
			"balancoTitle": "Équilibre",
			"demresultNatTitle": "DR par nature",
			"demresultFuncTitle": "DR par fonctions",
			"demfluxocaixaTitle": "État des flux de trésorerie",
			"demAltCPTitle": "DACP {{dataDemAltCP}}",
			"demAltCP2Title": "DACP {{dataDemAltCP2}}",
			"intContaTitle": "Plages de comptes",
			"balanceteTitle": "Bilan",
			"fluxosCaixaTitle": "Flux de trésorerie",
			"table": {
				"nContaDe": "Numéro de compte",
				"nContaAte": "Le nombre compte jusqu'à",
				"mesPeriodo": "Période de mois",
				"nConta": "Numéro de compte",
				"nomeConta": "Nom",
				"designacao": "Désignation",
				"codFluxo": "Code.",
				"parent": {
					"parent0": "Actif",
					"parent1": "Équité",
					"parent2": "Passif"
				}
			},
			"capitalRealizado": "Capital",
			"accoesQuotasProprias": "Actes",
			"premiosDeEmissao": "Récompenses",
			"reservasLegais": "Réservations",
			"outrasReservas": "Autres",
			"resultadosTransitados": "Résultats",
			"ajustamentosEmActivosFinanceirosOutrasVariacoes": "Ajustements",
			"excedentesDeRevalorizacao": "Surplus",
			"resultadoLiquidoDoPeriodo": "Résultat",
			"outrosInstrumentosCapProprio": "Autres"
		},
		"radio": {
			"sncncrf": "SNC-NCRF",
			"microentidade": "Microentités"
		},
		"btn": {
			"notas": "Remarques",
			"valdefeito": "Valeurs par défaut",
			"previsualizargrid": "Passer sur un grill",
			"demfinsncsaved": "Versions Dém.",
			"repvaloriginal": "Réinitialiser toutes les valeurs d'origine",
			"periodo": "Période intérimaire :",
			"anexoRelatorioGestao": "Annexe / Rapport de gestion"
		},
		"modal": {
			"title": "Notes sur les états financiers",
			"titlelistsaved": "Versions des états financiers enregistrées",
			"titleobs": "Indiquer les observations",
			"titlepreviewpdf": "SNC états financiers",
			"obs": "Observations",
			"badgecaptionsaved": "État financier sélectionné",
			"tabs": {
				"table": {
					"rubrica": "Rubriques",
					"notas": "Remarques"
				},
				"balanco": {
					"title": "Équilibre",
					"tab": {
						"titles": {
							"passivo": "Passif"
						},
						"ativonaocorrente": "Actif non courant",
						"ativocorrente": "Actif actuel",
						"capitalproprio": "Équité",
						"passivonaocorrente": "Passifs non courants",
						"passivocorrente": "Passifs courants"
					}
				},
				"demresult": {
					"title": "Compte de résultat",
					"pornaturezas": "Par nature",
					"porfuncoes": "Par fonctions"
				},
				"capproprioperio": {
					"title": "Capitaux propres de la période",
					"demaltcptproprio": "Dém. des variations des capitaux propres de la période"
				},
				"fluxocaixa": {
					"title": "Flux de trésorerie",
					"atvop": "Activités opérationnelles",
					"atvinvest": "Activités d'investissement",
					"atvfin": "Activités de financement"
				}
			},
			"listsaved": {
				"hora": "Temps",
				"obs": "Observations"
			},
			"message": {
				"valdef": "<b>Attention :</b> Si vous poursuivez cette opération, vous réinitialiserez les valeurs par défaut.",
				"delidsaved": "Supprimer l'état financier précédemment enregistré ?",
				"badgecaptioncellmanuallyedited": "Cellule modifiée manuellement par l'utilisateur",
				"delversaodemfinant": "La version des états financiers a été supprimée avec succès !",
				"savedemfin": "Les états financiers de SNC ont été sauvegardés avec succès !"
			}
		},
		"messages": {
			"periodoempty": "La période ne peut pas être vide.",
			"notastemdeserumnumero": "Les notes doivent être un nombre"
		},
		"language": {
			"language0": "portugais",
			"language1": "Anglais",
			"idioma": "Langue : {{langue}}"
		}
	},
	"depto": {
		"title_detail": "Département {{id}}",
		"title_new": "Nouveau département",
		"title_plural": "Départements",
		"pesquisa": "A chercher",
		"saved": "Département {{id}}, enregistré avec succès.",
		"error": "Le département {{id}} ne peut pas être enregistré.",
		"fields": {
			"nDepto": "Code.",
			"nDeptoPlaceholder": "Code du département",
			"nome": "Nom",
			"nomePlaceholder": "Nom du département"
		}
	},
	"descritivos": {
		"title_detail": "Description comptable {{id}}",
		"title_new": "Nouvelle description comptable",
		"title_plural": "Descriptions comptables",
		"pesquisa": "A chercher",
		"saved": "Description comptable {{id}}, enregistrée avec succès.",
		"error": "Description comptable {{id}}, ne peut pas être enregistrée.",
		"deleted": "Description comptable {{id}}, supprimée avec succès.",
		"fields": {
			"nDescrit": "Code.",
			"nDescritPlaceholder": "Code de description comptable",
			"nome": "Nom",
			"nomePlaceholder": "Nom de la description comptable",
			"descricao": "Description",
			"descricaoPlaceholder": "Description de la description comptable",
			"tipoDescritivo": "Taper",
			"tipoDescritivoPlaceholder": "Type de description comptable"
		}
	},
	"dgemps": {
		"title_detail": "Contributeur {{id}}",
		"title_new": "Nouveau collaborateur",
		"title_plural": "Collaborateurs",
		"pesquisa": "A chercher",
		"saved": "L'employé {{id}} a été enregistré avec succès",
		"error": "L'employé {{id}} ne peut pas être enregistré.",
		"fields": {
			"apelido": "Nom de famille",
			"apelidoPlaceholder": "Nom de famille de l'employé",
			"codEmp": "Collaboration.",
			"codEmpPlaceholder": "Code employé",
			"codPostal": "Code Postal",
			"codPostalPlaceholder": "Code postal de l'employé",
			"codservicoprh": "Service",
			"codservicoprhPlaceholder": "Code de service",
			"email": "E-mail",
			"emailPlaceholder": "Courriel de l'employé",
			"formRemun": "Forme de rémunération",
			"formRemunPlaceholder": "Forme de rémunération",
			"local": "Emplacement",
			"masterDetail": {
				"antigo": "Ancienne valeur",
				"campo": "Champ",
				"novo": "Nouvelle valeur"
			},
			"morada": "Ménage",
			"moradaPlaceholder": "Adresse de l'employé",
			"nTelefone": "N° de téléphone",
			"nTelefonePlaceholder": "Numéro de téléphone de l'employé",
			"nome": "Nom",
			"nomeCategoria": "Catégorie",
			"nomeCategoriaPlaceHolder": "Catégorie",
			"nomeCompleto": "Nom et prénom",
			"nomeLocalida": "Emplacement",
			"nomePlaceholder": "Nom du collaborateur",
			"nomelocalidaPlaceholder": "Emplacement des employés",
			"operacao": "Opération",
			"rua": "Route",
			"ruaPlaceholder": "Rue des employés",
			"telemovelPart1": "Téléphone portable",
			"telemovelpart1Placeholder": "Téléphone portable de l'employé",
			"tipoRefeição": "Type de repas"
		},
		"recibos": {
			"title": "Fiche de paie",
			"fields": {
				"nrecibo": "Numéro de reçu.",
				"nprocessamento": "Numéro de traitement.",
				"dataprocessamento": "Date de traitement",
				"datarecibo": "Date de réception",
				"lido": "Lire"
			}
		},
		"sexes": {
			"0": "Féminin",
			"1": "Masculin",
			"2": "N / A"
		},
		"taxResidence": {
			"0": "Résident sur le territoire portugais",
			"1": "Non-résident qui gagne un revenu auprès d'un seul employeur",
			"2": "Non-résident qui gagne un revenu de plus d'un employeur"
		},
		"workRegime": {
			"0": "Privé",
			"1": "Fonction publique"
		},
		"salaryCalculationMethod": {
			"0": "N / A",
			"1": "Par salaire de base",
			"2": "Pour les heures de travail"
		},
		"twelfthsCalculationType": {
			"0": "Sous-marins.",
			"1": "Allocation de Noël",
			"2": "Allocation de vacances",
			"3": "N'effectue pas de traitement"
		},
		"twelfthsValuesType": {
			"0": "0",
			"50": "50",
			"100": "100"
		},
		"home": {
			"altDeFichasPorAprov": "Il y a des modifications aux dossiers des employés à approuver",
			"pedidosFeriasPorAprov": "Vous avez des demandes de vacances non approuvées pour le(s) service(s)",
			"faltasPorAprov": "Il y a des absences à approuver dans le(s) service(s)",
			"abonosPorAprov": "Vous disposez d'indemnités à approuver pour le(s) service(s)",
			"intFerias": "Pause vacances",
			"intFaltas": "Intervalle de faute",
			"recibos": "Recettes",
			"comunicacoes": "Communications",
			"ferias": "Vacances",
			"feriasMarc": "Vacances programmées",
			"feriasAprov": "Jours fériés approuvés",
			"feriasReje": "Vacances refusées",
			"faltas": "Fautes",
			"faltasMarc": "Fautes signalées",
			"faltasAprov": "Fautes approuvées",
			"faltasReje": "Fautes rejetées",
			"aprovadas": "Approuvé",
			"restantes": "Restant",
			"marcadas": "Marqué",
			"rejeitadas": "Rejeté"
		}
	},
	"dgempsfull": {
		"title_detail": "Contributeur {{id}}",
		"title_new": "Nouveau collaborateur",
		"title_plural": "Collaborateurs",
		"pesquisa": "A chercher",
		"saved": "L'employé {{id}} a été enregistré avec succès",
		"error": "L'employé {{id}} ne peut pas être enregistré.",
		"deleted": "Le collaborateur {{id}} a été supprimé avec succès",
		"btn": {
			"configWSS": "Configuration WSS"
		},
		"tabelaIRSColaboradoresPorValidar": "Merci de valider le tableau IRS des salariés:",
		"fields": {
			"morada": "Route",
			"moradaPlaceholder": "Rue des employés",
			"nTelefone": "Téléphone",
			"nTelefonePlaceholder": "Numéro de téléphone de l'employé",
			"dtMotMov": "Dernière mise à jour d'inscription",
			"dtMotMovPlaceholder": "Date d'inscription des employés",
			"situacao": "Situation",
			"situacaoPlaceholder": "Statut d'employé",
			"sexo": "Sexe",
			"dtNascimento": "Date de naissance",
			"dtNascimentoPlaceholder": "Date de naissance de l'employé",
			"nacionalidad": "Nationalité",
			"nacionalidadPlaceholder": "Nationalité du salarié",
			"concelho": "Comté",
			"concelhoPlaceholder": "Commune du collaborateur",
			"freguesia": "Paroisse",
			"freguesiaPlaceholder": "Paroisse du collaborateur",
			"nbIdentidade": "Numéro de carte de citoyen",
			"nbIdentidadePlaceholder": "Numéro de carte de citoyen de l'employé",
			"dtBiValidade": "Validité de la carte de citoyen",
			"dtBiValidadePlaceholder": "Date d'expiration de la carte de citoyen/carte d'identité",
			"numSegSoc": "NISS",
			"numSegSocPlaceholder": "Numéro d'identification de sécurité sociale du salarié",
			"numContrib": "FNI",
			"numContribPlaceholder": "Numéro d'identification fiscale du salarié",
			"nCartaoTicketRef": "Numéro de carte repas",
			"nCartaoTicketRefPlaceholder": "Numéro de carte repas de l'employé",
			"banco": "Banque des employés",
			"iban": "IBAN employé",
			"swift": "SWIFT pour les employés",
			"codNHABQPessoal": "Niveau de qualification",
			"codNHABQPessoalPlaceholder": "Niveau de qualification des employés",
			"dtAdmEmpresa": "Date d'admission",
			"dtAdmEmpresaPlaceholder": "Date d'admission des employés",
			"vencimBase": "Salaire de base",
			"vencimBasePlaceholder": "Salaire de base des employés",
			"nDiuturnidad": "Nombre de périodes d'ancienneté",
			"valorUnDiutu": "Valeur de chaque indemnité d'ancienneté",
			"horasDia": "Heures de travail par jour",
			"horasDiaPlaceholder": "Heures de travail par jour",
			"horasSemana": "Heures de travail par semaine",
			"horasSemanaPlaceholder": "Heures de travail par semaine",
			"horasMes": "Heures de travail par mois",
			"horasMesPlaceholder": "Heures de travail par mois",
			"remunHora": "Salaire horaire",
			"temSubAlim": "Bénéficie d'une subvention",
			"valorSubAlim": "Valeur quotidienne",
			"temPreProcessamentoSubAlimen": "A un prétraitement",
			"valorSFerias": "Montant de l'indemnité de vacances",
			"valorSFeriasPlaceholder": "Valeur de l'indemnité de vacances du salarié",
			"valorSNatal": "Montant de l'allocation de Noël",
			"valorSNatalPlaceholder": "Valeur de la prime de Noël du salarié",
			"metodPaga": "Méthode de calcul du salaire",
			"divisaoTrab": "Division (durée) du travail",
			"divisaoTrabPlaceholder": "Division (durée) du travail des salariés",
			"tipoContrato": "Type de contrat",
			"tipoContratoPlaceholder": "Type de contrat de travail",
			"dtIniContrat": "Date de signature",
			"dtIniContratPlaceholder": "Date de signature de l'employé",
			"dtFimContrat": "Date de renouvellement du contrat",
			"dtFimContratPlaceholder": "Date de renouvellement du contrat du salarié",
			"dataEfetividade": "Date d'entrée en vigueur",
			"dataEfetividadePlaceholder": "Date d'effet du contrat du salarié",
			"temSegurAcTr": "Avez-vous une assurance contre les accidents du travail ?",
			"nSeguradAcTr": "Assureur accidents du travail",
			"nSeguradAcTrPlaceholder": "Compagnie d'assurance contre les accidents du travail",
			"caixaPrev": "Centre d'inscription à la sécurité sociale",
			"caixaPrevPlaceholder": "Centre d'inscription à la sécurité sociale des salariés",
			"classeCaixa": "Régime de sécurité sociale",
			"classeCaixaPlaceholder": "Régime de sécurité sociale des salariés",
			"nTabIrs": "Tableau IRS",
			"nTabIrsPlaceholder": "Tableau IRS des employés",
			"numDepend": "Nombre de personnes à charge sans handicap",
			"numDependPlaceholder": "Nombre total de personnes à charge non handicapées du salarié",
			"numDependDef": "Nombre de personnes à charge handicapées",
			"numDependDefPlaceholder": "Nombre total de personnes handicapées à la charge du salarié",
			"numDependDefLabel": "Nombre de personnes handicapées à charge avec une invalidité permanente égale ou supérieure à 60 %",
			"regimeTrabalho": "Régime de travail",
			"tipoCalcDuodecimos": "Calcul de la valeur",
			"calcDuodecVariavel": "Calcul des douzièmes sur des valeurs variables",
			"eExResidente": "Fiscalité des anciens résidents",
			"eExResidenteLabel": "Les assujettis devenus résidents fiscaux en application des alinéas 1 et 2 de l'article 16 du CIRS",
			"nPocRemuLiq": "Compte comptable où est comptabilisé le montant à recevoir",
			"classeTrab": "Classe de travail",
			"classeTrabPlaceholder": "Classe de travail des employés",
			"funcao": "Fonction",
			"funcaoPlaceholder": "Rôle du collaborateur",
			"seccao": "Opération/section",
			"seccaoPlaceholder": "Section exploitation/employé",
			"nZona": "Zone",
			"nZonaPlaceholder": "Espace employé",
			"departamento": "Département",
			"departamentoPlaceholder": "Département des employés",
			"nSubDe": "Sous-département",
			"nSubDePlaceholder": "Sous-service des employés",
			"ccusto": "centre de coûts",
			"ccustoPlaceholder": "Centre de coûts du personnel",
			"nCodRepCC": "Répartition des points personnels",
			"nCodRepCCPlaceholder": "Répartition des points personnels des employés",
			"categoria": "Catégorie",
			"categoriaPlaceholder": "Catégorie d'employé",
			"profissao": "Profession",
			"profissaoPlaceholder": "Métier du salarié",
			"sitProfissao": "Statut professionnel",
			"sitProfissaoPlaceholder": "Statut professionnel du salarié",
			"codcct": "Contrat collectif de travail",
			"codcctPlaceholder": "Contrat collectif de travail des salariés",
			"nivQualific": "Niveau de qualification",
			"nivQualificPlaceholder": "Niveau de qualification des employés",
			"motivoRegisto": "Raison de la dernière mise à jour",
			"situacaoEmprego": "Statut d'employé",
			"residenciaFiscal": "Résidence fiscale",
			"nCodABVencimento": "Maturité",
			"nCodABSubAlimentacao": "Allocation alimentaire",
			"nCodABDiuturnidades": "Indemnités d'ancienneté",
			"nCodABSubFerias": "Allocation de vacances",
			"nCodABSubNatal": "Allocation de Noël",
			"nCodABFerias": "Vacances",
			"nCodDescIRS": "IRS",
			"codRepFinancas": "Bureau des impôts",
			"codRepFinancasPlaceholder": "Bureau des impôts",
			"conjugeIncapacidad60": "Conjoint avec un handicap égal ou supérieur à 60 %",
			"temDescIRSTxFixa": "Appliquer la réduction forfaitaire IRS",
			"percDescTaxaFixaIRS": "Taux fixe IRS (taux marginal)",
			"deficienteForcasArmadas": "Forces armées handicapées",
			"naoEfetuaFCT": "Fonds de compensation",
			"naoEfetuaFCTLabel": "Ne calcule pas les fonds d'indemnisation",
			"sindicato": "Union",
			"ncartsindicato": "Numéro de carte syndicale",
			"temRendJovensTrab": "Bénéficie d'une exonération partielle du revenu",
			"temRendResNaoHabit": "Les résidents non habituels sont exonérés",
			"dtInicio": "Date de début",
			"crlCalcLiquido": "Calcule le revenu net",
			"crlValorLiquido": "Valeur nette",
			"crlValorLiquidoEmMe": "Valeur nette en devises",
			"crlAbonoLiquido": "Allocation",
			"nDiasFeriasAnoAnteri": "Jours de vacances l'année dernière",
			"duodecimosConfigEmpresa": "Douzièmes configurés par société",
			"usaDuodecimosPorEmp": "Utiliser des douzièmes par employé",
			"duodecimosSubNatal": "Sous.",
			"duodecimosSubFerias": "Sous.",
			"temRedIrsCredHabitac": "Applique la réduction aux personnes bénéficiant d'un crédit au logement pour leur propre logement et leur logement permanent (mesure en vigueur uniquement en 2023)",
			"temRedCtrArrendameHP": "Appliquer une réduction d'objet pour les titulaires de contrats de location/crédit pour un logement permanent",
			"localTributacaoRendi": "Résidence",
			"situacaoCivilTitular": "Situation",
			"naturezaRendimento": "Type de rendement"
		},
		"groups": {
			"estadoSS": {
				"title": "Sécurité sociale de l'État",
				"naoVerificado": "Non vérifié",
				"verificar": "Pour vérifier",
				"registar": "Registre",
				"consultar": "Consulter"
			},
			"addressContacts": {
				"title": "Adresse et contacts"
			},
			"identification": {
				"title": "Identification"
			},
			"bankData": {
				"title": "Coordonnées bancaires"
			},
			"taxData": {
				"title": "Données fiscales",
				"sections": {
					"irs": "IRS",
					"socialSecurity": "Sécurité sociale",
					"financas": "Finance",
					"rendJovensTrab": "Exonération partielle des revenus du travail gagnés par les contribuables âgés de 18 à 26 ans",
					"titCredHab": "Réduction des retenues à la source pour les titulaires de contrats",
					"temRendResNaoHabit": "Résident non habituel - Revenus exonérés du régime fiscal pendant 10 ans"
				}
			},
			"contractualData": {
				"title": "Données contractuelles",
				"sections": {
					"timeAndPlaceOfWork": "Horaires et lieu de travail",
					"remuneration": "Rémunération",
					"calcPorRendLiq": "Calcul par résultat net"
				}
			},
			"professionalData": {
				"title": "Détails professionnels"
			},
			"subsidies": {
				"title": "Subventions et assurances",
				"sections": {
					"food": "Allocation alimentaire",
					"vacations": "Allocation de vacances",
					"christmas": "Allocation de Noël",
					"workAccidentInsurance": "Assurance accidents du travail"
				}
			},
			"twelfths": {
				"title": "Douzièmes",
				"sections": {
					"rulesProcessingTwelfths": "Règles de traitement des douzièmes"
				}
			},
			"integration": {
				"title": "Intégration",
				"sections": {
					"integrationGeneralAccounting": "Intégration à la comptabilité générale",
					"integrationAnalyticalAccounting": "Intégration à la comptabilité analytique"
				}
			},
			"otherData": {
				"title": "Autres données",
				"sections": {
					"allowanceCodesEmployeeWageProcessing": "Codes d'allocation à utiliser dans le traitement des salaires des employés",
					"discountCodeEmployeeWage": "Code de réduction à utiliser dans le traitement des salaires des employés",
					"comments": "Commentaires"
				}
			}
		},
		"actions": {
			"calculateRemunHora": "Calculer le salaire horaire d'un employé",
			"applySuggestedRemunHora": "Appliquer le salaire horaire proposé",
			"simula": "Simuler",
			"selecaoTabelaIRS": "Sel."
		},
		"text": {
			"datasHistoricoDadosCaption": "Historique des inscriptions",
			"datasHistoricoDadosCurrent": "en vigueur",
			"fileNamePicture": "Photo_{{codeEmp}}",
			"configurarOutrosDados1": "Il semble que vous n'ayez pas encore configuré les données par défaut dans le dossier de l'employé.",
			"configurarOutrosDados2": "fais-le maintenant",
			"oldRecordWarning": "<strong>Attention :</strong> vous consultez un enregistrement d'historique de données.",
			"total": "Total : {{value}} (salaire horaire suggéré)",
			"configWSS": "Configurer l'accès au système de webservice de la sécurité sociale",
			"percDescTaxaFixaIRSShowWarning": "Un \"{{description}}\" comporte des frais juridiques minimum de {{value}}.",
			"naoResidente": "Non-résident",
			"residenteNaoHabitual": "Résident non habituel"
		},
		"titles": {
			"abono": "{{designation}} : {{calculatedvalue}} = ({{value}} × {{months}}) / ({{hoursWeek}} × {{weeks}})",
			"abonoCruzamento": "{{designation}} : {{valeur calculée}} = (({{value}} × ({{percentage}} / 100) × {{months}}) / ({{hoursWeek}} × {{weeks}} )",
			"valorCalculado": "Valeur calculée",
			"valorDesignacao": "Valeur {{désignation}}",
			"nDiuturnidades": "Nombre de périodes d'ancienneté",
			"percentagem": "Pourcentage",
			"meses": "Mois",
			"horasSemana": "Nombre d'heures par semaine",
			"semanas": "Nombre de semaines par an"
		},
		"errors": {
			"invalidNumSegSoc": "Numéro d'identification de sécurité sociale invalide",
			"invalidDtFimContrat": "La date de fin du contrat est antérieure à la date de début du contrat",
			"invalidDTNascimento": "La date de naissance n'est pas valide!"
		},
		"deletePrompt": {
			"title": "Supprimer le collaborateur {{id}} ?",
			"message": "Comment comptez-vous supprimer le collaborateur ?",
			"lastOnly": "Uniquement l'inscription en cours (la plus récente)",
			"all": "Toute votre histoire",
			"invalid": "Veuillez sélectionner la manière dont vous souhaitez supprimer le collaborateur"
		},
		"saveCadastro": {
			"title": "Enregistrer l'inscription",
			"message": "Les données d'inscription ont été modifiées.",
			"newCadastro": "Créer un nouvel enregistrement dans le registre",
			"updateLatestCadastro": "Mettre à jour le dernier enregistrement dans le registre",
			"dataRegisto": "Sélectionnez la date d'inscription"
		},
		"prompt": {
			"exitMessage": "Êtes-vous sûr de vouloir quitter sans enregistrer les données modifiées ?",
			"inconsistenciasTabelaIRSNotifCloseMessage": "Voulez-vous masquer définitivement l’alerte de validation de la table IRS aux employés ?"
		},
		"comments": {
			"modalTitle": "Entretien des commentaires",
			"title": "Titre",
			"text": "Texte",
			"user": "Utilisateur"
		},
		"modalSimularTaxaFixaIrs": {
			"modalTitle": "Simulateur de taux marginal IRS",
			"taxaEfetiva": "Tarif mensuel effectif",
			"infoModal": "Lors du calcul, le taux marginal sera affiché en tenant compte des différents types de rémunération définis pour le salarié (salaire, indemnités d'ancienneté, indemnité de repas, données fixes).",
			"taxaMarginal": "Taux marginal",
			"semDados": "Aucune donnée à présenter",
			"infoCalculos": "Informations sur les calculs",
			"taxaNaoValida": "Le taux mensuel effectif doit être compris entre 0% et 100%",
			"dataSimulacao": "Date de simulation"
		},
		"modalSelecaoTabelaIRS": {
			"modalTitle": "Sélection des tables IRS",
			"fields": {
				"dataEmVigor": "Date d'entrée en vigueur",
				"residencia": "Résidence",
				"situacao": "Situation",
				"tipoRendimento": "Type de rendement",
				"declaranteDeficiente": "Déclarant handicapé",
				"dependentes": "Personnes à charge",
				"dependentesDeficiente": "Personnes à charge handicapées"
			},
			"groupSelection": {
				"title": "Sélection des tables IRS"
			},
			"groupSelected": {
				"title": "Avec les données sélectionnées, le tableau à appliquer est :"
			},
			"tabelaDesignacaoIRS": "Tableau : {{Désignation IRS}}.",
			"tabelaNaoDeterminada": "Tableau IRS non déterminé.",
			"parcelaAbaterDepende": "Portion par personne à charge : {{parcelaAbaterDepende}}."
		},
		"enumStr": {
			"rhLocalTributacaoRendimento": {
				"rhltrNone": "Sans objet",
				"rhltrContinente": "Continent",
				"rhltrRegiaoAcores": "Région des Açores",
				"rhltrRegiaoMadeira": "Région de Madère"
			},
			"rhNaturezaRendimento": {
				"rhnrNone": "Sans objet",
				"rhnrDependente": "Dépendant",
				"rhnrPensoes": "Retraites"
			},
			"rhTipoDeficiencia": {
				"rhtdNone": "Sans objet",
				"rhtdDeficienciaGeral": "Invalidité générale",
				"rhtdDeficienciaForcasArmadas": "Forces armées handicapées"
			},
			"rhSituacaoCivilTitular": {
				"rhsctNone": "Sans objet",
				"rhsctNaoCasado": "Pas marié",
				"rhsctCasado1Titular": "Marié 1 Titulaire",
				"rhsctCasado2Titulares": "Marié 2 Titulaires"
			},
			"rhDependentes": {
				"rhdSemDependentes": "Aucune personne à charge",
				"rhdComDependentes": "Avec personnes à charge"
			}
		},
		"gridIRSDTEmVigor": {
			"fields": {
				"valorILIQ": "Jusqu'au renum.",
				"taxaMarginalMaxima": "Taux marginal maximum",
				"parcelaAbaterDepende": "Part des personnes à charge (€)",
				"txEfetivaMensalEscal": "Il y a une limite de niveau",
				"parcelaAbaterFormulaInfo": "Part à déduire (€)"
			}
		}
	},
	"impdadosemp": {
		"filtropesq": "Recherche",
		"previsualizar": "Aperçu",
		"deleteFoto": "Supprimer la photo",
		"fields": {
			"deCod": "Code employé",
			"deNome": "Nom de",
			"deIdade": "Âge de",
			"deSituacao": "Situation de",
			"deCategoria": "Catégorie de",
			"deDepartamento": "Département de",
			"deDtNasc": "Date de naissance",
			"deDtValCt": "Date d'expiration",
			"deDtValBI": "Date d'expiration"
		}
	},
	"diarios": {
		"title_detail": "Journal comptable {{id}}",
		"title_new": "Nouveau journal comptable",
		"title_plural": "Journaux comptables",
		"pesquisa": "A chercher",
		"saved": "Journal comptable {{id}}, enregistré avec succès.",
		"error": "Le journal comptable {{id}}, ne peut pas être sauvegardé.",
		"deleted": "Journal comptable {{id}}, supprimé avec succès.",
		"fields": {
			"nDiario": "Code.",
			"nDiarioPlaceholder": "Code du journal comptable",
			"nome": "Nom",
			"nomePlaceholder": "Nom du journal comptable",
			"formaIncrementoNDoc": "Formulaire d'augmentation doc no.",
			"formaIncrementoNDocPlaceholder": "Formulaire d'incrémentation",
			"aberturaContab": "Journal d'ouverture comptable (ne comptabilise pas la TVA)",
			"aberturaContabPlaceHolder": "Journal d'ouverture comptable"
		}
	},
	"distritos": {
		"title_detail": "Quartier {{id}}",
		"title_new": "Nouveau quartier",
		"title_plural": "Quartiers",
		"pesquisa": "A chercher",
		"saved": "District {{id}}, enregistré avec succès",
		"error": "Le district {{id}} ne peut pas être enregistré.",
		"deleted": "District {{id}}, supprimé avec succès",
		"fields": {
			"codDistrito": "Code de district",
			"nomeDistrito": "Nom du district",
			"localObtencaoRendimentos": "Lieu gagnant un revenu",
			"codContinente": "Continent",
			"codPais": "Code du pays",
			"nomePais": "Pays"
		}
	},
	"divisoesTrabalho": {
		"title_detail": "Division du travail {{id}}",
		"title_new": "Nouvelle division du travail",
		"title_plural": "Divisions du travail",
		"pesquisa": "A chercher",
		"saved": "Division du travail {{id}}, enregistrée avec succès.",
		"error": "La division du travail {{id}} ne peut pas être enregistrée.",
		"deleted": "Division du travail {{id}}, supprimée avec succès.",
		"fields": {
			"nDivisao": "Code.",
			"designacaoBreve": "Brève désignation",
			"designacaoCompleta": "Désignation complète",
			"codDuracaoQPessoal": "Code.",
			"tipo": "Type de temps"
		}
	},
	"divisTipo": {
		"0": "Inconnu",
		"1": "À temps plein",
		"2": "Temps partiel"
	},
	"dmrf": {
		"frestran": "Retenues sur les non-résidents",
		"edit": "Modifier",
		"delete": "Pour éteindre",
		"newModalTitle": "Créer un enregistrement",
		"editModalTitle": "Modifier l'enregistrement",
		"summaryTitle": "Remplir le formulaire de retenue d'impôt IRC/IRS",
		"fields": {
			"nContrib": "Numéro de contribuable",
			"nDocExterno": "Doc n°",
			"zona": "Zone",
			"rubrica": "Titre",
			"retencao": "Rétention"
		},
		"messages": {
			"contribrequired": "Vous devez saisir un numéro de contribuable!",
			"savedSuccess": "Inscription enregistrée avec succès",
			"promptDeleteRecord": "Etes-vous sûr de vouloir supprimer l'enregistrement ?",
			"promptDeleteDeclaracao": "Êtes-vous sûr de vouloir supprimer la déclaration ?",
			"erroExport": "Une erreur s'est produite lors de l'export du fichier de déclaration.",
			"promptSendMessage": "Cette opération enregistrera les données et soumettra la déclaration de retenue IRC / IRS sur le portail financier (AT).<br/>Êtes-vous sûr de vouloir continuer ?",
			"sendSuccess": "Le fichier de déclaration a été envoyé avec succès.",
			"sending": "Envoi... veuillez patienter un instant.",
			"exporting": "Exportation... veuillez patienter un instant.",
			"saving": "Sauvegarde... veuillez patienter un instant.",
			"deleting": "Suppression... veuillez patienter un instant.",
			"deletingRecord": "Suppression de l'inscription... veuillez patienter un instant.",
			"valueRetNegative": "Valeur de rétention négative.",
			"valueRetNull": "Valeur de rétention nulle."
		},
		"toolbar": {
			"deletePeriodo": "Annuler la déclaration",
			"refresh": "Obtenir des données",
			"save": "Enregistrer la déclaration",
			"export": "Générer un fichier",
			"send": "Envoyer la déclaration"
		},
		"rubrica": {
			"102": "102 : IRS – Entreprises et professionnels",
			"103": "103 : IRS – Retraites",
			"104": "104: IRS – Propriété",
			"105": "105 : IRS – Capital – Titres – Entités émettrices",
			"106": "106 : IRS – Capital – Titres – Enregistrement, dépositaire et autres entités",
			"107": "107 : IRS – Capital – Intérêts sur les dépôts à vue ou à terme",
			"108": "108 : IRS – Capital – Autres revenus",
			"109": "109 : IRS - Prix pour jeux, loteries, tombolas, paris mutuels, tirages au sort, concours",
			"110": "110 : IRS – Rémunérations et autres augmentations d’actifs",
			"112": "112 : IRS – Supplément extraordinaire",
			"114": "114 : IRS – Surtaxe extraordinaire – Cat. H",
			"115": "115 : IRS – Autres revenus du capital soumis aux taux prévus à l'article 71 du CIRS",
			"116": "116 : IRS – Revenu.",
			"117": "117 : IRS – Revenu.",
			"118": "118 : IRS - Capital-Titres représentatifs de dette (D.L. 193/2005 du 7 novembre)",
			"199": "199 : IRS – Intérêts compensatoires",
			"201": "201 : IRC - Bâtiments",
			"202": "202 : IRC - Commissions d'intermédiation sur tous contrats et prestations fournies",
			"203": "203 : IRC – Capital – Titres – Entités émettrices",
			"204": "204 : IRC - Capital - Titres - Entités d'enregistrement ou de dépositaire",
			"205": "205 : IRC – Capital – Intérêts des dépôts à court ou à terme",
			"206": "206 : IRC - Capital - Autres revenus",
			"207": "207 : IRC - Prix de jeux, loteries, tirages au sort, paris mutuels, tirages au sort, concours",
			"208": "208 : IRC - Rémunération des membres des organes statutaires des personnes morales",
			"209": "209 : IRC – Fonds d’investissement – ​​Revenus.",
			"210": "210 : IRC - Rescue Uni.",
			"211": "211 : IRC - Organisation collective Inv.",
			"212": "212 : IRC - Org. Durée déterminée collective - Reg Tran.",
			"213": "213 : IRC - Org. Durée déterminée collective - Reg Tran.",
			"299": "299 : IRC - Intérêts compensatoires",
			"000": ""
		},
		"zona": {
			"desconhecido": "Inconnu",
			"continente": "Continent",
			"madeira": "Bois",
			"acores": "Açores"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
		"leavePromptMessage": "En cliquant sur OUI, vous quitterez et supprimerez toutes les données de traitement."
	},
	"docfas": {
		"title_detail": "Type de document {{id}}",
		"title_new": "Nouveau type de document",
		"title_plural": "Type de document",
		"pesquisa": "A chercher",
		"saved": "Type de document {{id}}, enregistré avec succès",
		"error": "Type de document {{id}}, ne peut pas être enregistré.",
		"documentoGlobal": "Document global",
		"entregaEfectivaSobreDocumentoGlobal": "Livraison efficace sur Global Document",
		"loginAT": "AT Webservice - Communication série",
		"accoes": "Actes",
		"autofaturacaoComAcordo": "Auto-facturation pour les fournisseurs avec accord",
		"autofaturacaoSemAcordo": "Auto-facturation pour les fournisseurs sans accord",
		"fields": {
			"nDocFa": "Nombre",
			"nome": "Nom",
			"descricao": "Description",
			"codigoSAFTTipoDoc": "SAFT - type de document",
			"identificaCarga": "Identifie la cargaison",
			"faturaComIvaIncluido": "Facture avec TVA incluse",
			"integraStocks": "Intègre les stocks",
			"integraNaContabilidade": "Intégrer",
			"integraEmContasCorrentes": "S'intègre aux comptes courants",
			"reportDefeito": "Rapport par défaut",
			"tipoMovimentoStock": "Mouvement de stock",
			"grupoDocfa": "Groupe",
			"fluxoMonetario": "Flux d'argent",
			"nDocfaDestino": "Type de document.",
			"faturacaoPublica": "Facturation publique",
			"obrigatorioMeiosPagam": "Utiliser les moyens de paiement",
			"descricaoSAFTTipoDoc": "Description SAFT - Type de document.",
			"codigoSAFTTipoDocDescStr": "SAFT - Type de document",
			"entregaEfetivaSobreDocGlobal": "Livraison efficace sur un document global",
			"sujeitoAHASHStr": "Signature",
			"cae": "CAE",
			"nViasDefeito": "Nombre de copies par défaut",
			"tipoMovimento": "Type de mouvement",
			"movimentaStock": "Mouvement des stocks",
			"diario": "Tous les jours",
			"descriti": "Descriptif"
		},
		"list": {
			"actions": {
				"new": "Nouveau basé sur ceci"
			}
		},
		"captions": {
			"definicoes": "Paramètres commerciaux et d'intégration",
			"numeracoes": "Série",
			"dadosAT": "Données AT",
			"reports": "Rapports",
			"dadosGerais": "Données générales",
			"integraContabilidade": "Intégration comptable"
		},
		"warnings": {
			"canceladaNovaSerie": "Création de la nouvelle série annulée",
			"novaserie": "Nouvelle série",
			"addnovaserie": "Ajouter une nouvelle série",
			"novaSerieAdd": "Nouvelle série ajoutée avec succès",
			"temAlteracoes": "Certaines modifications ne sont pas encore enregistrées."
		},
		"errors": {
			"tipoDocNotExist": "Le type de document n'existe pas",
			"erroNovaSerie": "Erreur lors de la création d'une nouvelle série",
			"autofaturacaoNome": "Le type de document est l'auto-facturation, ce qui nécessite que le terme « auto-facturation ou auto-facturation » soit inclus dans le nom.",
			"soPodeSelecionarTpDocQuePertencemAoGrupo": "Vous ne pouvez sélectionner que les types de documents appartenant au groupe",
			"autoFaturacaoInvalido": "Le type de documents appartient au Groupe, mais possède un champ \"autoFaturacao\" invalide."
		},
		"tooltip": {
			"cae": "Champ non obligatoire."
		},
		"messages": {
			"semMovimentoStock": "Aucun mouvement",
			"entradaStock": "Entrée de stock",
			"saidaStock": "En rupture de stock"
		},
		"modal": {
			"new": {
				"cardBase": "Base du nouveau type de document",
				"cardNew": "Nouveau type de document",
				"title": "Assistant de création d'un nouveau type de document",
				"nDocfaBase": "Document de base",
				"nDocFaPlaceHolder": "S'il n'est pas renseigné, il génère automatiquement",
				"saftTipoDoc": "SAFT - Type de document",
				"fatura": "Facture",
				"faturaRecibo": "Facture-Reçu"
			}
		},
		"modals": {
			"comunicacaoSeriesAT": "Communication série vers AT"
		},
		"reports": {
			"fields": {
				"visible": "Disponible",
				"defaultReport": "Par défaut",
				"nome": "Nom",
				"descricao": "Description",
				"tipoDescricao": "Taper"
			},
			"descricao": {
				"system": "Système",
				"user": "Utilisateur",
				"userEmpresa": "Utilisateur / Entreprise"
			}
		}
	},
	"docfasNums": {
		"title_detail": "Identifiant du type de document de série {{id}}",
		"title_new": "Nouvelle série de types de documents",
		"title_plural": "Série de types de documents",
		"pesquisa": "A chercher",
		"saved": "Série de types de documents {{id}}, enregistrée avec succès",
		"error": "La série de types de documents {{id}} ne peut pas être enregistrée.",
		"fields": {
			"idDocfaNum": "ID de série",
			"nDocfa": "Type de document",
			"nNumer": "Numéro de série.",
			"nNumerPlaceholder": "Le numéro de série, s'il n'est pas renseigné, sera généré automatiquement.",
			"num": "Doc n°",
			"descNumer": "Description",
			"numDraft": "Doc n°",
			"nConta": "Numéro de compte",
			"encerrado": "Fermé",
			"atcud": "AT - Code de validation",
			"visivelERPCloud": "Nuage visible",
			"codLocalEmissao": "Lieu de délivrance",
			"atEstadoSerieDescricao": "Statut en AT",
			"paraTodosDoGrupo": "Créer pour tous les types de documents du groupe {{GroupName}}"
		},
		"auxiliares": {
			"novaserie": "Nouvelle série",
			"addnovaserie": "Ajouter une nouvelle série",
			"novaSerieAdd": "Nouvelle série ajoutée avec succès"
		},
		"errors": {
			"SerietipoDocNotExist": "La série de types de documents n'existe pas",
			"serieNaoPodeSerNula": "La série de types de documents ne peut pas être nulle",
			"seriesTipoDocumentoNaoDaParaComunicarAT": "Le type de document n'est pas soumis à signature et ne peut donc pas être communiqué à AT.",
			"seriesTipoDocumentoNaoDaParaVerLogAT": "Le type de document n'est pas soumis à signature et ne dispose donc pas de journal de communication à l'AT",
			"seriesTipoDocumentoNaoDaParaConsultarComunicacaoAT": "Le type de document n'est pas soumis à signature, il n'est donc pas possible de consulter la communication à AT",
			"atAFEnquadramentoRequired": "L'encadrement est obligatoire",
			"nContaRequired": "Le numéro de compte est obligatoire"
		},
		"btn": {
			"comunicarSeries": "Communiquer les séries de facturation à AT",
			"logSeries": "LOG de communication de la série vers AT",
			"consultarSeries": "Consulter la série sur l'AT"
		},
		"modal": {
			"comunicacao": {
				"title": "Communication des séries de facturation à AT",
				"selecionar": "Sélectionner",
				"enquadramentoDesperdicios": "Ligne i) du paragraphe 1 de l'article 2 du CIVA - Secteur des déchets, déchets et débris recyclables",
				"enquadramentoCortica": "Ligne m) du paragraphe 1 de l'article 2 du CIVA - Acheteurs de liège, de bois, de pommes de pin et de pignons de pin en coques",
				"atSeriesLinkTooltip": "Inscription des séries sur le portail finance",
				"fields": {
					"serie": "À la série",
					"tipoSerie": "Type de série",
					"classeDoc": "Classe de documents",
					"tipoDoc": "Type de document",
					"numInicialSeq": "Début de séquence",
					"dataInicioPrevUtiliz": "Début d’utilisation prévu",
					"seqUltimoDocEmitido": "Dernier doc.",
					"codValidacaoSerie": "Code de validation (ATCUD)",
					"estado": "Etat de la série",
					"meioProcessamento": "Support de traitement",
					"numCertificado": "Numéro de certificat",
					"nifFornecedor": "NIF du fournisseur",
					"enquadramento": "Cadre"
				},
				"btn": {
					"finalizar": "Fin de série",
					"registarWebService": "Communiquer via un webservice",
					"registarManual": "Communiquer manuellement"
				},
				"finalizar": {
					"title": "Finaliser la communication de la série",
					"justificacao": "Justification",
					"erroJustificacaoVazia": "La justification ne peut pas être nulle."
				},
				"autoFaturacaoComAcordoMessage1": "Série autofacturée avec accord",
				"autoFaturacaoComAcordoMessage2": "Pour activer une série d'autofacturation avec accord de la part de votre fournisseur national ou étranger, vous devez d'abord le faire sur le portail financier dans la zone appropriée, en saisissant les données que nous présentons.",
				"autoFaturacaoComAcordoMessage3": "Après vous être inscrit sur le portail, vous devez saisir dans ce formulaire l'ATCUD et le NIF du fournisseur et effectuer l'enregistrement.",
				"autoFaturacaoSemAcordoMessage1": "Série d'autofacturation sans accord",
				"autoFaturacaoSemAcordoMessage2": "Pour activer une série d'auto-facturation sans accord de la part de votre fournisseur selon le cadre prévu, vous devez d'abord le faire sur le portail financier dans la zone appropriée, en saisissant les données que nous présentons.",
				"autoFaturacaoSemAcordoMessage3": "Après vous être inscrit sur le portail, vous devez saisir l'ATCUD et le cadre dans ce formulaire, et effectuer l'enregistrement."
			},
			"consulta": {
				"fields": {
					"serie": "Série",
					"tipoSerie": "Type de série",
					"classeDoc": "Cours de doc.",
					"tipoDoc": "Type de document.",
					"numInicialSeq": "Numéro initial",
					"dataInicioPrevUtiliz": "Date de début",
					"seqUltimoDocEmitido": "Dernier non.",
					"meioProcessamento": "Support de traitement",
					"numCertSWFatur": "Numéro de certificat logiciel",
					"codValidacaoSerie": "Code de validation (ATCUD)",
					"dataRegisto": "Date d'inscription",
					"estado": "État",
					"dataEstado": "Date d'état",
					"nifComunicou": "Communication du FIV"
				}
			},
			"logs": {
				"title": "",
				"fields": {
					"atDataInicioPrev": "Date/Heure",
					"operacaoSTR": "Opération",
					"respostaCodigo": "Code de réponse",
					"respostaMensagem": "Message de réponse",
					"codValidacaoSerie": "Code de validation de série",
					"atTipoSerie": "Type de série",
					"atTipoSerieDescricao": "Type de série (description)",
					"atClasseDoc": "Cours de doc.",
					"atClasseDocDescricao": "Cours de doc.",
					"atTipoDocSTR": "Type de document.",
					"atEstadoSerie": "Série d'état",
					"atEstadoSerieDescricao": "Statut de la série (description)"
				}
			},
			"seriesPorComunicarAT": {
				"title": "Communication de séries documentaires à AT",
				"fields": {
					"nDocfa": "Type de document.",
					"nNumer": "Numéro de série.",
					"nome": "Nom du type de document.",
					"tipoDocSaft": "Type SAFT",
					"numAtual": "Numérotation actuelle",
					"numInicial": "Numérotation initiale",
					"dataInicio": "Date de début"
				},
				"btn": {
					"comunicarseriesAT": "Communique via un service Web"
				}
			}
		}
	},
	"docfasnumscomuncicacaologin": {
		"title": "Connexion Série Communication",
		"saveTitle": "Sauvegarder",
		"fields": {
			"username": "Nom d'utilisateur (NIF/ID utilisateur)",
			"password": "Mot de passe"
		},
		"messages": {
			"success": "Connectez-vous avec succès.",
			"errorUsernameEmpty": "Nom d'utilisateur vide.",
			"errorPasswordEmpty": "Mot de passe vide.",
			"noPermissions": "Attention : Pour modifier les valeurs, vous devez disposer des autorisations d'administrateur !"
		}
	},
	"configNomeExportFile": {
		"caption": {
			"filesType": "Type de configuration",
			"nDocFa": "Type de document",
			"groupConfigurador": "Configurateur",
			"fieldType": "Type de champ",
			"valor": "Valeur",
			"groupConfigs": "Paramètres de nom de fichier",
			"mascaraLabel": "Masque"
		},
		"grids": {
			"ordem": "Commande",
			"tipo": "Taper",
			"valor": "Valeur",
			"doc": "Document"
		},
		"apagarModal": {
			"title": "Configuration claire",
			"message": "Êtes-vous sûr de vouloir supprimer la configuration \"{{configuracao}}\" ?"
		},
		"messages": {
			"erroMaskLength": "Aucun masque n'est configuré.",
			"erroNDocFa": "Vous devez sélectionner un type de document.",
			"erroFieldType": "Vous devez sélectionner un type de champ.",
			"erroValorNulo": "La valeur ne peut pas être nulle.",
			"erroValorInvalido": "La valeur contient des caractères non valides.",
			"deleteSucesso": "Configuration supprimée avec succès."
		}
	},
	"docsComerciaisEstatisticas": {
		"title_plural": "Déclarations de ventes",
		"pesquisa": "A chercher",
		"verMaisFiltros": "Voir plus de filtres",
		"verMaisFiltrosHide": "Masquer les filtres",
		"tabs": {
			"cubo": "Cube"
		},
		"fields": {
			"nDocStr": "Doc.",
			"nArtigo": "Article",
			"artigo": "Article",
			"nArtigoPivot": "Numéro d'article",
			"nomeArtigo": "Nom de l'article",
			"nConta": "Numéro de compte",
			"contaNome": "Nom",
			"nif": "NIF",
			"dataDoc": "Date du document.",
			"liquido": "Liquide",
			"valorIva": "Valeur TVA",
			"sinal": "Signal",
			"liquidoMovimento": "Mouvement liquide.",
			"valorIvaMovimento": "TVA mobilière.",
			"totalMovimento": "Mouvement total",
			"qtd1": "Qté.",
			"prVendaQtd1": "Prix ​​unitaire.",
			"d1": "Desc.",
			"totalDocumento": "Doc. totale.",
			"totalLiquido": "Total net",
			"totalIliquido": "Totalement illiquide.",
			"totalIva": "TVA totale",
			"totalDesconto": "Remise totale.",
			"taxaRet": "Taux de rétention",
			"retencaoIncidencia": "Incidence rec.",
			"retencaoValor": "Valeur rectangulaire.",
			"nFactFornec": "Document externe.",
			"dataDocExterno": "Date du document.",
			"nVendedor": "code vendeur",
			"nomeVendedor": "Vendeur",
			"nDocfa": "Code de type de document.",
			"docfaNome": "Type de document",
			"nnumer": "Série Doc.",
			"nDepart": "code gr.",
			"nomeDepart": "Grande famille",
			"nFamil": "Code famille",
			"nomeFamilia": "Famille",
			"nSubfa": "Code sous-famille",
			"nomeSubFamilia": "sous-famille",
			"tipoArtigo": "Code du type d'article",
			"nomeTipoArtigo": "Type d'article",
			"nCCusto": "Code C. Coût",
			"centroCusto": "centre de coûts",
			"nRefProcesso": "Code de processus",
			"nomeProcesso": "Processus",
			"totalDocumentoMovimento": "Doc. totale.",
			"totalLiquidoMovimento": "Total net",
			"totalIliquidoMovimento": "Totalement illiquide.",
			"totalIvaMovimento": "TVA totale du déménagement.",
			"totalDescontoMovimento": "Remise totale.",
			"valorEmAberto": "Valeur ouverte",
			"estado": "État",
			"sectorId": "Code secteur",
			"nomeSector": "Secteur"
		},
		"caption": {
			"ncontade": "Numéro de compte",
			"nartigode": "Code article",
			"tipoartigode": "Type d'article",
			"ngrfamiliade": "Grande famille de",
			"nfamiliade": "famille de",
			"nsubfamiliade": "Sous-famille de",
			"classede": "Classe de",
			"categoriade": "Catégorie de",
			"datade": "Date du document.",
			"nvendedorde": "Vendeur",
			"codzonade": "Zone",
			"ccustode": "C. Coût de",
			"nrefprocessode": "Numéro de processus",
			"artigoCaption": "Article",
			"clienteCaption": "Client",
			"docCaption": "Document",
			"socommovimstock": "Uniquement les articles qui bougent du stock",
			"docFa": "Types de documents",
			"obter": "Pour obtenir",
			"printModalTitle": "Statistiques de gestion commerciale",
			"ididechavde": "groupe de comptes",
			"cliclde": "Classe de"
		},
		"info": {
			"erroDataDeMaiorQueAte": "La date de début est supérieure à la date de fin",
			"docPago": "Payé",
			"docPagoParcial": "Partiellement payé",
			"docPorPagar": "Non rémunéré"
		},
		"configs": {
			"showFilterTipoArtigo": "Afficher le filtre par type d'article",
			"showFilterGrFamilia": "Afficher le filtre par famille nombreuse",
			"showFilterFamilia": "Afficher le filtre par famille",
			"showFilterSubFamilia": "Afficher le filtre par sous-famille",
			"showFilterClasseArtigo": "Afficher le filtre par classe d'article",
			"showFilterCategoriaArtigo": "Afficher le filtre par catégorie d'article",
			"showFilterSoComMovimStock": "Afficher filtrer uniquement les articles qui déplacent des stocks",
			"showFilterConta": "Afficher le filtre par compte",
			"showFilterVendedor": "Afficher le filtre par vendeur",
			"showFilterZona": "Afficher le filtre par zone",
			"showFilterCentroCusto": "Afficher le filtre par centre de coûts",
			"showFilterProcesso": "Afficher le filtre par processus",
			"showFilterDocfaList": "Afficher le filtre par type de document",
			"showFilterNArtigo": "Afficher le filtre pour l'article",
			"showFilterClasse": "Afficher le filtre par classe",
			"showFilterGrupoConta": "Afficher le filtre par groupe de comptes"
		},
		"errors": {
			"naoExisteTipoDocSelecionado": "Aucun type de document n'est sélectionné.",
			"naoExisteTipoDocSelecionadoCheckOpcoes": "Aucun type de document n'est sélectionné."
		}
	},
	"docsPartilhados": {
		"title_detail": "Document de la plateforme RH {{id}}",
		"title_new": "Nouveau document de la plateforme RH",
		"title_plural": "Documents de la plateforme RH",
		"pesquisa": "A chercher",
		"saved": "Document de la plateforme RH {{id}}, enregistré avec succès",
		"error": "Le document de la plateforme RH {{id}} ne peut pas être enregistré.",
		"fields": {
			"cab": {
				"docId": "Identifiant du document.",
				"nome": "Nom",
				"horaCriacaoReg": "Temps",
				"visualizado": "Vu",
				"tamanho": "Taille",
				"extensao": "Extension"
			}
		},
		"docs": {
			"title": "Document"
		},
		"uploadmodal": {
			"titlenew": "Envoyer des fichiers au comptable",
			"dataRef": "Date de référence du dossier"
		},
		"info": {
			"apagardocumentos": "Supprimer des documents",
			"temCertezaApagarDocumentos": "Êtes-vous sûr de vouloir supprimer les documents sélectionnés ?",
			"emailParaGabSucess": "E-mail envoyé au bureau avec succès",
			"fileUploadSuccess": "Fichier téléchargé avec succès"
		},
		"toolbar": {
			"year": "Année : {{année}}",
			"month": "Mois : {{mois}}"
		},
		"btn": {
			"enviarFicheiros": "Envoyer des fichiers"
		}
	},
	"guias": {
		"title_detail": "Guider {{id}}",
		"title_new": "Nouvel onglet",
		"title_plural": "Guides",
		"saved": "Le guide {{id}} a été enregistré avec succès.",
		"error": "Le guide ne peut pas être enregistré.",
		"toolbar": {
			"btnConfigAt": ""
		}
	},
	"vendas": {
		"title_detail": "Vendre {{id}}",
		"title_new": "Nouvelle vente",
		"title_plural": "Ventes",
		"saved": "Vendez le {{id}} enregistré avec succès.",
		"error": "La vente ne peut pas être enregistrée."
	},
	"compras": {
		"title_detail": "Acheter {{id}}",
		"title_new": "Nouvel achat",
		"title_plural": "Achats",
		"saved": "Achetez {{id}} enregistré avec succès.",
		"error": "L'achat ne peut pas être enregistré."
	},
	"entradasdiversas": {
		"title_detail": "Entrée Diverse {{id}}",
		"title_new": "Nouvelle entrée Divers",
		"title_plural": "Entrées diverses",
		"saved": "Divers Entrée {{id}} enregistrée avec succès.",
		"error": "Divers L'entrée ne peut pas être enregistrée."
	},
	"saidasdiversas": {
		"title_detail": "Sortie Diverse {{id}}",
		"title_new": "Nouvelle production diversifiée",
		"title_plural": "Sorties diverses",
		"saved": "Divers Sortie {{id}} enregistrée avec succès.",
		"error": "Les sorties diverses ne peuvent pas être enregistrées."
	},
	"propostasaclientes": {
		"title_detail": "Proposition/budget {{id}}",
		"title_new": "Nouvelle proposition/budget",
		"title_plural": "Propositions aux Clients",
		"saved": "Proposition au client {{id}} enregistrée avec succès.",
		"error": "La proposition client ne peut pas être enregistrée."
	},
	"encomendasclientes": {
		"title_detail": "Commande client {{id}}",
		"title_new": "Nouvelle commande client",
		"title_plural": "Commandes clients",
		"saved": "La commande client {{id}} a été enregistrée avec succès.",
		"error": "La commande client ne peut pas être enregistrée."
	},
	"encomendasfornecedores": {
		"title_detail": "Commander le fournisseur {{id}}",
		"title_new": "Nouvelle commande fournisseur",
		"title_plural": "Commandes Fournisseurs",
		"saved": "Le fournisseur de commande {{id}} a été enregistré avec succès.",
		"error": "La commande fournisseur ne peut pas être enregistrée."
	},
	"guiasfornecedores": {
		"title_detail": "Onglet {{id}}",
		"title_new": "Nouvel onglet",
		"title_plural": "Guides",
		"saved": "Le guide {{id}} a été enregistré avec succès.",
		"error": "Le guide ne peut pas être enregistré.",
		"toolbar": {
			"btnConfigAt": "Connexion AT pour communication des documents de transport"
		}
	},
	"comprasautofaturacao": {
		"title_detail": "Document à facturation automatique {{id}}",
		"title_new": "Nouveau doc.",
		"title_plural": "Autofacturation",
		"saved": "Le document d'autofacturation {{id}} a été enregistré avec succès.",
		"error": "Le doc."
	},
	"consultasafornecedores": {
		"title_detail": "Budget {{id}}",
		"title_new": "Nouveau budget",
		"title_plural": "Budgets",
		"saved": "Budget {{id}} enregistré avec succès.",
		"error": "Le budget ne peut pas être sauvegardé."
	},
	"docscomerciais": {
		"addLine": "Ajouter une ligne",
		"title_detail": "Document {{id}}",
		"title_new": "Nouveau document",
		"title_plural": "Documents commerciaux",
		"pesquisa": "A chercher",
		"saved": "Le document commercial {{id}} a été enregistré avec succès.",
		"error": "Le Document Commercial ne peut pas être sauvegardé.",
		"fields": {
			"nDocFa": "Type de document.",
			"nNumer": "Série",
			"nDocumento": "Numéro du document",
			"nDocumentoDraft": "Numéro du projet de document",
			"nDoc": "Doc n°",
			"nDocFilter": "Numéro de document (Ex.: 1.23.10)",
			"nome": {
				"clifos": "Nom",
				"clientes": "Client",
				"fornecedores": "Fournisseur"
			},
			"nConta": "Compte",
			"nif": "Contribuable",
			"estado": "État",
			"porPagar": "Non rémunéré",
			"porReceber": "Pour recevoir",
			"stampUpdate": "Dernier.",
			"modoEnvio": "Mode d'envoi",
			"estadoEnvioStr": "Statut d'expédition",
			"nomeMoeda": "Pièce de monnaie",
			"transformado": "Transformé",
			"nFactFornec": "Document externe.",
			"dataDocExterno": "Date du document.",
			"observacoes": "Observations",
			"anulado": "Annulé",
			"dataVenc": "Date d'expiration",
			"textoLivre": "Texte libre"
		},
		"toolbar": {
			"importDoc": "Importer un document",
			"meiosPagamento": "Modes de paiement",
			"calcular": "Calculer",
			"arredondamentoManual": "Arrondi manuel",
			"alterarCamposSerie": "Changer les champs en série",
			"rastreabilidade": "Traçabilité",
			"preView": "Aperçu/Brouillon",
			"salvageLastDoc": "Récupérer le dernier document",
			"guardar": "Sauvegarder",
			"guardarRascunho": "Enregistrer le brouillon",
			"finalizarDocumento": "Finaliser le document",
			"transformacaoDocumentos": "Transformation de documents",
			"arquivoDigital": "Archives numériques",
			"configNameFileExport": "Nom des fichiers à exporter",
			"faturacaoPublica": "Facturation électronique publique/EDI"
		},
		"doc": {
			"cab": {
				"nDocFa": "Type de document.",
				"nNumer": "Série",
				"dataDoc": "Date du document.",
				"nDocumento": "Numéro du document",
				"nDocumentoDraft": "Doc n°",
				"nome": "Nom",
				"totalLiquido": "Incidence",
				"totalIva": "T.V.A.",
				"totalDesconto": "Réductions",
				"totalIliquido": "Total",
				"taxaRet": "Taux",
				"retencaoIncidencia": "Incidence",
				"retencaoValor": "Valeur",
				"observacoes": "Observations",
				"nFactFornec": "Document externe.",
				"dataDocExterno": "Date du document.",
				"dataInicTran": "Date de début du transport",
				"horaInicTran": "Heure de début du transport",
				"estadoEnvioStr": "Statut d'expédition",
				"atDocCodeID": "ÀCode",
				"nCCusto": "Centre de coûts n°",
				"dataVenc": "Date d'échéance",
				"codMoeda": "Code devise",
				"nomeMoeda": "Pièce de monnaie",
				"cambio": "Échange",
				"nRefProcesso": "Processus",
				"comunicadoAoEFatura": "Statut d'expédition",
				"tipoComunicacao": "Mode d'envoi",
				"ctRefExterna": "Contracter",
				"acordoQuadro": "Accord-cadre",
				"compromisso": "Engagement",
				"nRequisicao": "Numéro de demande",
				"nDocGlobalOrigem": "Doc mondial.",
				"nCondPagam": "Conditions de paiement"
			},
			"linhas": {
				"nArtigo": "Article",
				"nome": "Nom",
				"qtd": "Qté.",
				"qtdStock": "Quantité en stock",
				"preco": "Prix",
				"codIva": "Code TVA",
				"taxaIva": "% TVA",
				"desconto": "Rabais %",
				"d1": "Desc.",
				"d2": "Desc.",
				"d3": "Desc.",
				"d4": "Desc.",
				"d5": "Desc.",
				"d6": "Desc.",
				"valorComIva": "Valeur TTC",
				"nCCusto": "C. Coût",
				"valorIva": "Valeur TVA",
				"nRefProcesso": "Processus",
				"nArmazem": "Stockage",
				"nVendedor": "Vendeur",
				"codUnidMov": "Articles.",
				"valorSemIVA": "Valeur hors TVA",
				"unidMovAbrev": "Articles.",
				"liquido": "Liquide",
				"descEmValor": "Desc.",
				"dataEntregaPrev": "Date de livraison prévue",
				"nSeq": "Séq."
			},
			"tabela": {
				"tooltipCellNomeArtigo": "Ajouter une description"
			},
			"iva": {
				"taxa": "Taux",
				"incidencia": "Incidence",
				"valorIva": "Valeur TVA"
			},
			"clifo": {
				"nome": "Nom",
				"nContribuint": "FNI",
				"rua": "Route",
				"codPostal": "Code Postal",
				"localidade": "Emplacement",
				"telefone": "Téléphone",
				"email": "E-mail"
			},
			"moradaCarga": {
				"rua": "Route",
				"codPostal": "Code Postal",
				"localidade": "Emplacement",
				"observacoes": "Note.",
				"morada": "Rue (suite)",
				"codPais": "Code du pays"
			},
			"moradaDescarga": {
				"rua": "Route",
				"codPostal": "Code Postal",
				"localidade": "Emplacement",
				"observacoes": "Note.",
				"morada": "Rue (suite)",
				"codPais": "Code du pays",
				"moralId": "Adresse alternative"
			},
			"transportador": {
				"nGuiaTransporte": "Guide des transports n°.",
				"dataGuiaTransporte": "Date indicative",
				"matricula": "Inscription",
				"matriculaReboque": "Immatriculation de la remorque",
				"nomeTransportador": "Nom du transporteur",
				"nomeViatura": "Nom du véhicule",
				"nomeMotorista": "Nom du pilote",
				"moradaMotorista": "Adresse du chauffeur",
				"codPostalMotorista": "Code postal du conducteur",
				"codPaisMotorista": "Code pays du conducteur"
			},
			"novo": {
				"cliente": "Client",
				"observacoes": "Observations",
				"docExterno": "Document externe."
			}
		},
		"info": {
			"comunicacaoFaturaAT": "Facture communiquée à AT avec succès.",
			"comunicacaoDocTransporteAT": "Document de transport communiqué avec succès à AT.",
			"inserirCodComunicacaoAT": "Code téléphonique saisi avec succès.",
			"anularDocumento": "Document annulé avec succès.",
			"notaCreditoSucesso": "Retour effectué avec succès.",
			"comunicadoAoEFatura": "Document communiqué à AT, annulation non autorisée via Retour",
			"comunicadoAT": "Instruction AT",
			"transformarDocumento": "Document transformé avec succès.",
			"duplicarDocumento": "Document dupliqué avec succès.",
			"copiarLinhas": "Lignes de document copiées avec succès.",
			"meiosPagamentoVazios": "Valeur du document non affectée aux moyens de paiement",
			"meiosPagamentoOk": "Modes de paiement attribués",
			"encerrarDocumento": "Document complété avec succès",
			"desmarcadoDocTransformado": "Document marqué comme non transformé",
			"transformarLinhasDocumento": "Lignes de document transformées avec succès."
		},
		"erros": {
			"comunicacaoAT": "Erreur de communication avec AT.",
			"notselected": "Aucun document sélectionné.",
			"emptycodAT": "Code AT vide.",
			"anularDocumento": "Erreur lors de l'annulation du document.",
			"emptyMotivo": "Motif de l'annulation vide.",
			"inserirCodComunicacaoAT": "Erreur lors de la saisie du code AT.",
			"notaCreditoErro": "Erreur lors d'un retour.",
			"emptyMotivoNotaCredito": "Motif du retour vide.",
			"anulacaoFisica": "Annulation physique",
			"transformarDocumento": "Erreur lors de la transformation du document.",
			"docJaTransformado": "Le document a déjà été transformé.",
			"docAnulado": "Le document est annulé.",
			"naoCriouPastaDoc": "Erreur lors de la création du dossier de documents dans le dossier de gestion commerciale",
			"anexarGDoc": "Erreur lors de la pièce jointe du ou des fichiers",
			"duplicarDocumentoErro": "Erreur lors de la duplication du document",
			"copiarLinhas": "Erreur lors de la copie des lignes",
			"duplicarDocumentoNDocFaNulo": "Le type de document ne peut pas être nul",
			"duplicarDocumentoNContaNulo": "Le numéro de compte ne peut pas être nul",
			"docSemLinhas": "Le document n'a pas de lignes",
			"docSemValorLinhasMP": "Le document n'a aucune valeur à distribuer via les moyens de paiement",
			"meiosPagam": "Erreur lors de l'affichage des modes de paiement",
			"editarDocumento": "Erreur lors de la modification du document",
			"encerrarDocumento": "Erreur lors de la finalisation du document",
			"desmarcadoDocTransformado": "Erreur marquant le document comme non transformé",
			"moralInvalidSelected": "Vous n'avez pas sélectionné d'adresse alternative existante.",
			"tipoDocNaoIntegraStock": "Le type de document n'est pas inclus dans les stocks.",
			"noSelArtigos": "Vous devez sélectionner au moins un élément.",
			"notaCreditoSemCliente": "Un {{clip}} est requis pour établir un avoir.",
			"reAbreDocumento": "Erreur lors de la réouverture du document",
			"anularDocumentoComNotaCredito": "Vous ne pouvez pas annuler un document, car il existe un document rectificatif."
		},
		"auxiliares": {
			"motivoNotaCredito": "Raison du retour",
			"motivo": "Raison",
			"motivoTitle": "Raison de l'annulation",
			"anular": "Annuler",
			"anexarDocumento": "Joindre un document",
			"duplicarDocumento": "Document en double",
			"copiarLinhasDocumento": "Copier des lignes",
			"meiosPagamento": "Modes de paiement",
			"documento": "Document",
			"copiaDocumentoLinhas": "Copie du document/des lignes",
			"importarLinhasTemplate": "Lignes d'importation – Modèle",
			"calcular": "Calculer",
			"arredondamentoManual": "Arrondi manuel",
			"alterarCamposSerie": "Changer les champs en série",
			"rastreabilidade": "Traçabilité",
			"gestaoEncomendas": "Commandes en attente",
			"selecionarDocumento": "Sélectionner un document",
			"saldoEmAberto": "Solde ouvert",
			"anularDocumento": "Annuler le document",
			"tooltips": {
				"addTextoLivre": "Ajouter du texte libre",
				"copiaDocumentoLinhasTooltip": "Permet de dupliquer un document ou de copier uniquement les lignes",
				"importarLinhasTemplateTooltip": "Vous permet d'importer un modèle préparé pour une feuille de calcul",
				"pesquisaDocsGlobais": "Recherche globale de documents",
				"limparCampo": "Effacer le champ",
				"obterUltimoDocumentoGlobalCriado": "Récupère le dernier document global créé",
				"atualizarCambio": "Mettre à jour le taux de change pour la date du document",
				"comprasDataDoc": "Date d'enregistrement dans le système",
				"comprasNDocExterno": "Référence externe imprimée sur le document",
				"comprasDataDocExterno": "Date externe imprimée sur le document"
			},
			"recibosPagamRelComDocSeraoAnulados": "Les reçus/paiements liés au document à annuler seront également annulés.",
			"recibosPagamRelComDocFinalizadoSeraoAnulados": "Les reçus/paiements liés au document final à éditer seront annulés.",
			"nDocAsString": "Numéro du document",
			"docFinalizadoApenasEditarCamposNaoComunicEFatura": "Le document est au statut Finalisé, il est uniquement permis d'éditer les champs non communiqués dans eFatura."
		},
		"codATPerPhone": "À la communication documentaire",
		"markAsCompleted": "Marquer comme \"Terminé\"",
		"configs": {
			"showObservacoes": "Afficher les observations",
			"showDocExterno": "Afficher le document.",
			"showDataDoc": "Afficher la date du document.",
			"showDataDocExterno": "Afficher la date du document.",
			"showDataVenc": "Afficher la date d'échéance",
			"showCCusto": "Afficher le centre de coûts",
			"showMoeda": "Afficher la devise",
			"showNRefProcesso": "Afficher le processus",
			"showDescontos": "Afficher les colonnes de remise",
			"showNDoc": "Afficher le numéro du document",
			"showNArmazem": "Afficher l'entrepôt",
			"showCodUnidMov": "Afficher l'unité de mouvement",
			"showValorCIva": "Afficher la valeur TVA comprise",
			"showValorSIva": "Afficher la valeur hors TVA",
			"showNVendedor": "Afficher le vendeur",
			"showNNumer": "Afficher la série",
			"showNRequisicao": "Afficher le numéro de demande",
			"showLiquido": "Afficher le liquide",
			"showNDocExterno": "Afficher le document n°",
			"showD2": "Afficher la remise 2",
			"showD3": "Afficher la réduction 3",
			"showDescEmValor": "Afficher la description.",
			"showDiferimentos": "Afficher les reports",
			"showPerguntaSeFinalizaDoc": "Afficher la confirmation d'achèvement",
			"obtemDadosDocDigital": "Obtient les données du document numérique"
		},
		"actions": {
			"emitirRecibo": "Émettre un reçu",
			"pdf": "PDF",
			"duplicarDoc": "Document en double.",
			"comunicarDocAt": "Communiquer AT",
			"editarDocumento": "Modifier",
			"encerrarDocumento": "Finition",
			"artigos": "Ouvrir l'article",
			"rastreabilidade": "Traçabilité",
			"lotes": "Voir les lots",
			"adiantRegDocAtual": "Avances - S'inscrire dans le doc.",
			"adiantRegDocNew": "Avances - Inscrivez-vous dans un nouveau document.",
			"adiantRegDoc": "S'inscrire à l'avance",
			"adiantConsult": "Régulariser les avances",
			"movimentosEmAberto": "Mouvements ouverts",
			"extratoConta": "Relevé de compte",
			"txtFaturacao": "Notes de facturation",
			"emitirPagamento": "Émettre le paiement"
		},
		"btn": {
			"importDoc": "Importer un document",
			"meiosPagam": "Modes de paiement",
			"addMeiosPagam": "Ajouter des modes de paiement",
			"closeMeiosPagam": "Fermer les modes de paiement",
			"calculate": "Calculer",
			"arredManual": "Arrondi manuel",
			"altCamposSerie": "Changer les champs en série",
			"rastreabilidade": "Traçabilité",
			"save": "Sauvegarder",
			"saveDraft": "Esquisser",
			"savePdf": "PDF",
			"anexarDoc": "Attacher",
			"cancelar": "Annuler",
			"gestaoDocsEditaveis": "Gestion des documents modifiables",
			"encerrarDocumentos": "Finaliser les documents",
			"anularDocumentos": "Annuler des documents",
			"diferimentos": "Reports",
			"finalizar": "Finition",
			"anular": "Annuler",
			"gestaoRascunhos": "Gestion des projets"
		},
		"tabs": {
			"carga": "Charger",
			"descarga": "Décharge",
			"transportadora": "Transporteur",
			"faturacaoPublica": "Facturation publique",
			"linhas": "Lignes",
			"documentos": "Documents",
			"docsEditaveis": "Documents modifiables",
			"gestaoenc": "Pendentifs",
			"porProcessar": "À traiter",
			"processado": "Traité",
			"transporte": "Transport",
			"anexos": "Pièces jointes",
			"finalizados": "Fini",
			"rascunho": "En brouillon"
		},
		"modals": {
			"saveDoc": "Enregistrer le document",
			"gestaoDocsEditaveis": "Gestion des documents modifiables",
			"saveMessageOkCancel": "Cette action est irréversible.",
			"newItem": "Nouvel article",
			"createNewItem": "L'article n'existe pas.",
			"info": {
				"naoMostraSaveMsgOkCancel": "Ne plus afficher cette question"
			},
			"anexosPromptSaveDraft": "Pour joindre le fichier, vous devez enregistrer le document.",
			"precoUnitario": {
				"modalTitle": "Calculer le prix",
				"strings": {
					"artigo": "Article",
					"taxa": "Taux",
					"qtd": "Montant",
					"precoUnitarioSemIva": "Prix ​​hors TVA",
					"precoUnitarioComIva": "Prix ​​TTC"
				}
			}
		},
		"questions": {
			"confirmSave": "Êtes-vous sûr de vouloir enregistrer le document ?"
		},
		"legend": {
			"anulado": "Annulé"
		},
		"importadorLinhas": {
			"modalTitle": "Importateur de lignes",
			"extraFields": {
				"nomeartigo": "Description de l'article",
				"cunimo": "Unité de mouvement",
				"narmazem": "Stockage",
				"nvendedor": "Vendeur",
				"nccusto": "centre de coûts",
				"nrefprocesso": "Processus"
			},
			"steps": {
				"welcome": {
					"title": "Accueillir",
					"text1": "Bienvenue dans l'assistant d'importation de lignes.",
					"text2": "Peut sélectionner des champs supplémentaires pour l'exportation"
				},
				"downloadModel": {
					"title": "Télécharger le modèle",
					"text1": "Veuillez cliquer sur le bouton ci-dessous pour télécharger le modèle d'importation de ligne.",
					"text2": "Après avoir téléchargé le fichier, remplissez-le avec les données que vous souhaitez importer.",
					"text3": "Attention : Vous devez disposer d'un logiciel d'édition de tableur.",
					"downloadButton": "Télécharger le modèle d'importation"
				},
				"uploadModel": {
					"title": "Télécharger le modèle terminé",
					"text1": "Remplissez le fichier avec les données que vous souhaitez importer.",
					"text2": "Après avoir rempli le fichier, téléchargez-le pour continuer l'importation."
				},
				"resumo": {
					"title": "Résumé",
					"text1": "Importation de ligne terminée.",
					"text2": "{{total}} lignes ont été importées.",
					"text3": "Au moins une erreur s'est produite lors de l'importation de lignes",
					"listaErros": "Liste des erreurs"
				}
			},
			"promptMsg": "Il a des données d'importation en mémoire."
		},
		"artigosAdvancedSearch": {
			"title": "Recherche avancée d'articles"
		},
		"clifo": {
			"action": {
				"newContaCorrente": "Un nouveau compte courant sera créé",
				"newContaOcasional": "Un nouveau compte occasionnel sera créé",
				"contaCorrente": "Compte courant",
				"contaOcasional": "Compte occasionnel"
			}
		}
	},
	"encomendas": {
		"title": "Ordres",
		"btn": {
			"initProce": "Processus"
		},
		"header": {
			"clientes": "Clients",
			"artigos": "Articles",
			"dataDoc": "Date du document",
			"dataPrevEnt": "Date de livraison prévue",
			"clifoPlaceHolder": "Sélectionnez un client/fournisseur à traiter",
			"fornecedorPlaceHolder": "Sélectionnez un fournisseur à traiter",
			"clientePlaceHolder": "Sélectionnez un client à traiter"
		},
		"table": {
			"nDoc": "N° de commande",
			"dataDoc": "Date du document.",
			"nArtigo": "Code article",
			"nomeArtigo": "Article",
			"nClifo": "Numéro de compte",
			"nomeClifo": "Compte",
			"nArmaz": "Stockage",
			"nomeArmaz": "Nom de l'entrepôt",
			"prVendaQtd1": "Prix",
			"qtdStockNoArmazemOrigem": "Action",
			"qtd1": "Commandé",
			"qtd1Fact": "Traité",
			"ligacaoQtd": "Traitement",
			"dataEntregaPrev": "Date de livraison prévue",
			"nRequisicao": "Demande",
			"nRefProcesso": "Processus",
			"nCCusto": "centre de coûts"
		},
		"badges": {
			"partSatif": "Partiellement traité",
			"totalSatif": "Entièrement traité",
			"linhaTextLivre": "Ligne avec texte libre",
			"satisfMais": "Surtraité"
		},
		"actions": {
			"satTotal": "Quantité totale (F9)",
			"satTotalArtigo": "Quantité totale dans toutes les lignes (Article)",
			"satTotalDocumento": "Quantité totale sur toutes les lignes (Document)",
			"satLinhaSemFat": "Ligne de traitement sans facturation (F7)",
			"limparQtdSat": "Effacer la quantité à traiter",
			"limparQtdSatTodas": "Effacer la quantité à traiter sur toutes les lignes",
			"selecionarClifo": "Sélectionnez {{cliph}}",
			"verDocumento": "Afficher le document",
			"satisfClifo": "Processus {{clip}}",
			"showArtigos": "Afficher les articles",
			"showArmazens": "Montre les entrepôts",
			"showDocFas": "Afficher les types de documents",
			"verDadosClifo": "Vue traitée à partir de {{clifo}}",
			"showTextoLivre": "Afficher le texte libre"
		},
		"modal": {
			"btns": {
				"finalize": "Finition",
				"finalizePreviewDoc": "Terminer et visualiser le document",
				"finalizeEditarDoc": "Finaliser et modifier le document",
				"editDoc": "Modifier le document",
				"preencherAuto": "Remplissage automatique des lots",
				"lotesOk": "D'ACCORD"
			},
			"processamento": {
				"title": "Traitement des commandes",
				"steps": {
					"ident": "Identification",
					"carga": "Charger",
					"descarga": "Décharge",
					"lote": "Beaucoup",
					"preview": "Résumé"
				},
				"fields": {
					"nDocFa": "Type de document",
					"nNumer": "Série",
					"cliente": "Client",
					"fornecedor": "Fournisseur",
					"clifos": "Clients/Fournisseurs",
					"dataDoc": "Date du document",
					"dataInicTran": "Transport à domicile",
					"morada": "Ménage",
					"codPostal": "Code postal",
					"localidade": "Emplacement",
					"codPais": "Pays",
					"moradaAlt": "Adresse alternative",
					"observacoes": "Observations",
					"horaInicTran": "Heure de début du transport"
				},
				"messages": {
					"proceSuccess": "Traitement terminé avec succès.",
					"atribuirLotes": "Vous devez affecter des lots aux lignes contenant des articles avec des lots afin de simuler.",
					"notAutoSelLotesEntradaStock": "Il n'est pas possible de sélectionner automatiquement des lots dans une entrée de stock.",
					"naoTemLinhas": "Il n'y a aucune ligne à traiter.",
					"naoTemLinhasComLotes": "Il n’y a pas de lignes contenant des lots à remplir automatiquement."
				}
			},
			"documento": {
				"title": "Document"
			},
			"impressao": {
				"title": "Imprimer",
				"fields": {
					"dataDocDe": "Date du document.",
					"dataEntregaPrevDe": "Date prévue",
					"estadoEncomendas": "Statut de la commande",
					"armazens": "Entrepôts",
					"docfaclist": "Types de documents"
				},
				"estado": {
					"soPendentes": "À traiter",
					"soSatisfeitas": "Traité"
				}
			},
			"simulacao": {
				"title": "Simulation",
				"fields": {
					"nConta": "Numéro de compte",
					"nome": "Nom",
					"totalIva": "T.V.A.",
					"totalLiquido": "Liquide"
				}
			},
			"satisfazerclifo": {
				"title": "Commandes en attente"
			},
			"lotes": {
				"title": "Sélection de lots",
				"messages": {
					"existemLinhasSemLotesAtribuidos": "Il y a des lots sans quantité à traiter pour la ou les commandes",
					"qtdLotesNaoEIgualLinha": "La quantité ({{qtdLotes}}) de l'article {{nArtigo}} répartie entre les lots n'est pas la même que celle de la ligne : {{ligacaoQtd}}."
				}
			}
		},
		"messages": {
			"semLinhasSati": "Il n’y a aucune quantité à traiter pour la ou les commandes.",
			"atribuicaoDeLotesCancelada": "Affectation par lots dans le traitement des commandes annulée.",
			"processamentoLotesEncomendaFalhou": "Le traitement par lots des commandes a échoué.",
			"infoProcessamentoClifo": "Il faut rechercher puis sélectionner un {{clifo}} ci-dessous ou dans la grille pour traiter les commandes",
			"linhasComValorNulo": "Il y a des lignes avec une valeur nulle dans la quantité à traiter"
		},
		"errors": {
			"clientenaoselecionado": "Entrez {{clifo}} pour traiter les commandes en attente",
			"valorAProcessarNulo": "La valeur à traiter ne peut pas être nulle"
		},
		"keyboardShortcuts": {
			"processarTotalmente": "Saisissez la quantité totale à traiter à partir de la ligne de commande",
			"processarLinhaSemFaturar": "Traiter la ligne de commande sans facturation"
		}
	},
	"documentoFaturacao": {
		"btn": {
			"communicateFaturaAT": "Communiquer AT",
			"communicateDocTransporteAT": "Communiquer doc.",
			"insertATCode": "Insérez le code.",
			"notaCredito": "Retour",
			"anular": "Annuler",
			"transformarDoc": "Transformer le document.",
			"transformarLinhasDoc": "Transformer les lignes",
			"pdf": "PDF",
			"opcoes": "Possibilités",
			"anexos": "Pièces jointes",
			"duplicarDoc": "Document en double.",
			"copiarLinhas": "Copiez les lignes de document.",
			"meiosPagam": "Modes de paiement",
			"editarDocumento": "Modifier",
			"encerrarDocumento": "Finition",
			"desmarcadoDocTransformado": "Désélectionner transformé"
		},
		"modals": {
			"encerrar": {
				"title": "Finaliser le document",
				"caracteres": "Caractères : {{numChar}}/50"
			},
			"questions": {
				"confirmEncerrar": "Êtes-vous sûr de vouloir terminer le document par le numéro {{id}} ?"
			}
		},
		"summary": "Résumé",
		"retention": "Rétention"
	},
	"grupoDoc": {
		"outros": "Autres",
		"comprasEfetivas": "Achats réels",
		"consultasAFornecedores": "Demandes de renseignements des fournisseurs",
		"encomendasClientes": "Commandes clients",
		"encomendasFornecedores": "Commandes fournisseurs",
		"folhasDeObra": "Feuilles de travail",
		"guiasTransporteRemessa": "Guides de transport maritime",
		"propostasAClientes": "Propositions aux clients",
		"reservasClientes": "Réservations clients",
		"trfArmazensEntradas": "Trf.",
		"trfArmazensSaidas": "Trf.",
		"vendasEfectivas": "Ventes efficaces",
		"consignacoesClientes": "Envois clients",
		"consignacoesFornecedores": "Envois fournisseurs",
		"recibosClientes": "Reçus clients",
		"pagamentosFornecedores": "Paiements fournisseurs",
		"none": "Aucune information",
		"planoProducao": "Plan de production",
		"preEncomendasClientes": "Précommandes clients",
		"custosAdicionais": "Frais supplémentaires",
		"notaCredito": "note de crédit",
		"guiasTransporteFornecedores": "Fournisseurs de guides de transport",
		"entradasDiversas": "Entrées diverses",
		"saidasDiversas": "Diverses sorties"
	},
	"arredondamentoManual": {
		"title": "Document commercial - Arrondi manuel",
		"gridHeader": {
			"documento": "Document",
			"arredondamento": "Arrondi"
		},
		"gridFields": {
			"codIva": "Code TVA",
			"taxaIva": "Taux",
			"prVenda1": "Incidence",
			"valorIva": "T.V.A.",
			"arredLiquidoManualEditavel": "Incidence",
			"arredIVAManualEditavel": "TVA arrondie",
			"incidenciaTotalEditavel": "Incidence",
			"ivaTotalEditavel": "TVA totale"
		},
		"messages": {
			"warningMaxValue": "L'arrondi prévu dépasse absolument le maximum autorisé ({{value}})",
			"totalIvaLiquido": "Arrondi manuel : Net {{totalLiquido}} et TVA {{totalIva}}",
			"totalLiquido": "Arrondi manuel : Net {{totalNet}}",
			"totalIva": "Arrondi manuel : TVA {{totalIva}}"
		},
		"resumo": {
			"totalIva": "TVA totale",
			"totalIncidencia": "Incidence totale"
		}
	},
	"alterarCamposSerie": {
		"title": "Sélectionnez les champs à modifier"
	},
	"gestaoDocsComerciais": {
		"title": "Gestion des projets de documents",
		"filters": {
			"nDocFa": "Type de document.",
			"deDataDoc": "Date du document."
		},
		"fields": {
			"mensagemErro": "Message"
		},
		"messages": {
			"encerrarFullSuccess": "Les documents ont tous été complétés avec succès.",
			"encerrarSuccessWithErrors": "Les {{nDocsSuccess}} des {{nDocs}} sélectionnés ont été finalisés.",
			"encerrarError": "Une erreur s'est produite lors de la finalisation des documents, aucun document n'a été clôturé.",
			"anularFullSuccess": "Les documents ont tous été annulés avec succès.",
			"anularSuccessWithErrors": "{{nDocsSuccess}} des {{nDocs}} sélectionnés ont été annulés.",
			"anularError": "Une erreur s'est produite lors de l'annulation des documents, aucun document n'a été clôturé.",
			"anularExistemDocumentosEncerrados": "Il existe des documents fermés, comptez-vous procéder à leur annulation ?",
			"existemDocumentosEncerrados": "Certains documents sélectionnés sont déjà fermés.",
			"naoExistemDocsSelecionados": "Aucun document n'est sélectionné.",
			"artigoNaoExiste": "L'article n'existe pas.",
			"anularAviso": "Cette action est irréversible."
		},
		"legend": {
			"documentoComErro": "Document avec erreur"
		}
	},
	"pesquisaDocumentos": {
		"title": "Recherche de documents"
	},
	"selecaoLotes": {
		"title": "Sélection de lots",
		"tooltips": {
			"lote": "Beaucoup"
		}
	},
	"devolucao": {
		"caption": {
			"artigoQuantidade": "Retour d'article",
			"artigoValor": "Remise sur les articles",
			"documentoValor": "Remise sur les documents",
			"documentoExterno": "Externe",
			"stepTipo": "Taper",
			"documentoDestino": "Document cible",
			"emQuantidade": "En quantité",
			"emValor": "En valeur",
			"tipoDocOrigem": "Type de document source",
			"docExternoOrigem": "Document externe (source)",
			"dataDocExternoOrigem": "Date du document externe (origine)",
			"tipoDocumento": "Type de document",
			"devolucao": "Retour",
			"deArtigo": "Article par",
			"docExternoPlaceholder": "Exemple : FT 001/1",
			"artigoDesconto": "Article en réduction",
			"valorDocExterno": "Valeur totale",
			"adicionar": "Pour ajouter",
			"dataDoc": "Date du document.",
			"desdeDataDoc": "Date du document.",
			"quantidade": "Montant",
			"codIva": "Code TVA"
		},
		"messages": {
			"sucesso": "Renvoyez le document généré avec succès.",
			"escolherTipo": "Choisissez le type de retour que vous souhaitez effectuer",
			"erroTipoDoc": "Vous devez sélectionner un type de document source.",
			"erroDocExterno": "Un document externe (origine) est obligatoire.",
			"erroMotivo": "Vous devez indiquer le motif du retour.",
			"erroDocExternoValor": "Le montant à restituer doit être supérieur à zéro.",
			"erroLinhas": "Aucune ligne n'est sélectionnée.",
			"erroArtigoDevolucao": "Vous devez indiquer un article de retour.",
			"erroValor": "La valeur à renvoyer ne peut pas être 0.",
			"erroValorSuperior": "La valeur insérée à régulariser dépasse la valeur à régulariser (À régulariser : {{byRevolver}} | Inséré : {{aRevolver}}).",
			"devolucaoEditar": "Ce document contient déjà des lignes de retour.<br>Choisissez l'option que vous souhaitez effectuer."
		},
		"table": {
			"facliId": "FacliId",
			"nDoc": "Document",
			"porDevolver": "Pour le retour",
			"aDevolver": "A retourner"
		},
		"tooltip": {
			"devolveTudo": "Renvoyer tout",
			"limpaTudo": "Effacer toutes les valeurs renvoyées",
			"limpaDevolvido": "Effacer le montant retourné",
			"adicionarMotivo": "Ajouter à la liste des raisons",
			"artigoQuantidade": "Utilisé pour effectuer des retours de quantité de produits ou de marchandises",
			"documentoValor": "Permet de faire une remise sur un ou plusieurs documents",
			"artigoValor": "Utilisé pour faire une remise sur un article spécifique",
			"documentoExterno": "Utilisé pour régulariser un document qui n'est pas présent dans le système"
		},
		"placeholder": {
			"motivo": "Tapez ou recherchez",
			"artigoParaDesconto": "Article en réduction"
		}
	},
	"gruposdocfas": {
		"configuracoes": {
			"title": "Paramètres",
			"modofuncionamento": {
				"label": "Mode de fonctionnement",
				"options": {
					"processo": "Processus",
					"centrocusto": "centre de coûts"
				}
			},
			"gastos": {
				"title": "Dépenses",
				"estimado": {
					"gridtitle": "Estimé"
				},
				"real": {
					"gridtitle": "Réel"
				}
			},
			"rendimentos": {
				"title": "Revenu",
				"estimado": {
					"gridtitle": "Estimé"
				},
				"real": {
					"gridtitle": "Réel"
				}
			},
			"grids": {
				"fields": {
					"id": "Type de document",
					"name": "Nom",
					"desc": "Description",
					"sinal": "Signal"
				},
				"enums": {
					"sinal": {
						"mais": "+",
						"menos": "-"
					}
				}
			},
			"avisos": {
				"tipodocnecessario": "Vous devez sélectionner un type de document à ajouter.",
				"tipodocexistente": "Le type de document '{{id}}' est déjà dans la liste."
			},
			"messages": {
				"configsavedsucess": "Configuration des groupes de types de documents enregistrés avec succès."
			},
			"modal": {
				"title": "Paramètres du groupe de types de documents"
			}
		}
	},
	"analisemargemlucro": {
		"title": "Analyse de la marge bénéficiaire",
		"bands": {
			"gastos": "Dépenses",
			"rendimentos": "Revenu",
			"resultado": "Résultat",
			"margem": "Marge %"
		},
		"fields": {
			"nrefprocesso": "Code de processus",
			"nomeprocesso": "Processus",
			"nccusto": "Code du centre de coûts",
			"nomeccusto": "centre de coûts",
			"nconta": "Numéro de compte",
			"nomeconta": "Nom du compte",
			"classificador": "Classificateur",
			"estimado": "Estimé",
			"real": "Réel",
			"gastoestimado": "Dépense estimée",
			"gastoreal": "Dépenses réelles",
			"rendimentoestimado": "Rendement estimé",
			"rendimentoreal": "Revenu réel",
			"resultadoestimado": "Résultat estimé",
			"resultadoreal": "Résultat réel",
			"margemestimado": "Marge estimée",
			"margemreal": "Marge réelle"
		},
		"messages": {
			"semregistos": "Il n’existe aucun enregistrement pour l’analyse de la marge bénéficiaire.",
			"dataAteMenorDataDe": "La date « À » doit être supérieure ou égale à la date « Depuis le document »."
		},
		"detail": {
			"title": "Détail de l'enregistrement de l'analyse de la marge bénéficiaire",
			"fields": {
				"tipologia": "Typologie",
				"ndocfa": "Code du type de document.",
				"nomedocfa": "Type de document",
				"nnumer": "Série",
				"ndocumento": "Numéro du document",
				"ndocasstring": "Document",
				"datadoc": "Date du document.",
				"nconta": "Numéro de compte",
				"nomeconta": "Nom du compte",
				"nartigo": "Code article",
				"nomeartigo": "Article",
				"qtd1": "Qté.",
				"unidade": "Unité",
				"prvendaqtd1": "Prix ​​unitaire",
				"liquido": "Liquide",
				"valoriva": "T.V.A.",
				"nFamilia": "Code famille",
				"nomeFamilia": "Famille",
				"nTpArt": "Code du type d'article",
				"nomeTpArt": "Type d'article",
				"nGrFamilia": "code gr.",
				"nomeGrFamilia": "Famille Gr.",
				"nSubFa": "Code sous-famille",
				"nomeSubFa": "Sous-famille"
			},
			"actions": {
				"documento": "Document"
			}
		},
		"estado": {
			"aberto": "Ouvrir",
			"terminado": "Fini",
			"ambos": "Les deux"
		},
		"caption": {
			"estado": "État",
			"nrefprocesso": "Processus",
			"nccusto": "centre de coûts",
			"datade": "Date du document."
		},
		"modal": {
			"print": {
				"title": "Impression de l'analyse de la marge bénéficiaire"
			}
		}
	},
	"docscontabilidade": {
		"title_new": "Nouveau document",
		"title_edit": "Document {{id}}",
		"title_plural": "Documents comptables",
		"title_singular": "Document comptable",
		"pesquisa": "A chercher",
		"goSearch": "Recherche de documents",
		"goSearchMobile": "Recherche",
		"clearForm": "Effacer les champs",
		"saved": "La pièce comptable {{id}} a été enregistrée avec succès.",
		"error": "La pièce comptable ne peut pas être sauvegardée.",
		"tooltipNDocumento": "Cet identifiant n'est qu'indicatif, il est susceptible de changer après enregistrement du document",
		"tooltipNextDocumento": "Document suivant pour cette période/agenda<br>(Ctrl + Flèche droite)",
		"tooltipPreviousDocumento": "Document précédent de cette période/journal<br>(Ctrl + Flèche gauche)",
		"tooltipFirstDocumento": "Premier document de cette période/journal<br>(Ctrl + Maj + Flèche gauche)",
		"tooltipLastDocumento": "Dernier document de cette période/agenda<br>(Ctrl + Maj + Flèche droite)",
		"fields": {
			"extPocCabID": "Identifiant du document",
			"periodo": "Période",
			"nomePeriodo": "Période",
			"nDiario": "Tous les jours",
			"nomeDiario": "Tous les jours",
			"nDocInterno": "Doc n°",
			"totalDebitoGeral": "Dette totale",
			"totalCreditoGeral": "Crédit complet",
			"dataLancamento": "Date de publication comptable",
			"dataVencimento": "Date d'échéance",
			"dataDoc": "Date du document",
			"codDescritivo": "Code descriptif",
			"nomeDescritivo": "Descriptif",
			"descricao": "Description",
			"codMoeda": "Pièce de monnaie",
			"nomeMoeda": "Pièce de monnaie",
			"nContribuinte": "Numéro de contribuable",
			"documentoExterno": "Document externe.",
			"stampUpdate": "Dernier.",
			"nDocumento": "Numéro du document",
			"nDocumentoFilter": "Numéro du document (ex: 2024011.1.345)",
			"preDefinido": "Prédéfini",
			"anulado": "Annulé",
			"nconta": "Numéro de compte",
			"valor": "Valeur",
			"temDocDigital": "Document numérique"
		},
		"doc": {
			"cab": {
				"extPocCabID": "Identifiant du document",
				"periodo": "Période",
				"nomePeriodo": "Période",
				"nDiario": "Tous les jours",
				"nomeDiario": "Tous les jours",
				"nDocInterno": "Doc n°",
				"totalDebitoGeral": "Dette totale",
				"totalCreditoGeral": "Crédit complet",
				"dataLancamento": "Date de sortie",
				"dataVencimento": "Date d'échéance",
				"dataDoc": "Date du document",
				"codDescritivo": "Code descriptif",
				"nomeDescritivo": "Descriptif",
				"descricao": "Description",
				"codMoeda": "Pièce de monnaie",
				"nomeMoeda": "Pièce de monnaie",
				"nContribuinte": "Contribuable",
				"documentoExterno": "Document externe.",
				"stampUpdate": "Dernier.",
				"nDocumento": "Numéro du document",
				"totalCredidoBaseTributavel": "Crédit de base imposable total",
				"totalDebitoBaseTributavel": "Dette totale sur base imposable",
				"totalCredidoIva": "Crédit de TVA intégral",
				"totalDebitoIva": "Dette TVA totale",
				"cambioRef": "Référence d'échange",
				"moeda": "Pièce de monnaie",
				"anulado": "Annulé"
			},
			"linhas": {
				"nLanc": "Identificateur de ligne",
				"nLancImput": "Identifiant d'imputation",
				"extPocCabID": "Identifiant du document",
				"nConta": "Numéro de compte",
				"periodo": "Période",
				"nDiario": "Tous les jours",
				"nDocInterno": "Doc n°",
				"nSeq": "Séquence",
				"dc": "Débit/Crédit",
				"valor": "Valeur",
				"valorME": "Valeur ME",
				"codMovAberto": "Ouvrir le code de mouvement",
				"valorPago": "Montant payé",
				"valorPagoME": "Montant qui m'a été payé",
				"codMoeda": "Code devise",
				"cambio": "Échange",
				"nDescr": "Code descriptif",
				"dataLancamento": "Date de publication comptable",
				"dataDoc": "Date du document",
				"dataVencimento": "Date d'échéance",
				"nDocExterno": "Document externe.",
				"dataDocExt": "Date du document.",
				"descricao": "Description",
				"nContrib": "Numéro de contribuable",
				"codIva": "Code TVA",
				"valorTaxa": "Valeur TVA",
				"valorExistente": "{{type}} actuel",
				"valorOriginal": "{{type}} original",
				"valorTaxaME": "Valeur des frais ME",
				"cDecPer": "Périodique déc.",
				"cDecAnual": "Annuel déc.",
				"codControloIva": "Code de contrôle TVA",
				"nGrupo": "Groupe",
				"classificControlo": "Classe.",
				"valorDesconto": "Valeur de remise",
				"valorRetencao": "Valeur de rétention",
				"nUtilizador": "Numéro d'utilisateur",
				"nLancOrigemIva": "nLancOrigemIva",
				"nLancDestinoIva": "nLancDestinoIva",
				"valorOrigemIva": "valeurOrigineIva",
				"valorDestinoIva": "valeurDestinationTVA",
				"nLancOrigemRetencao": "nLancOrigemRetencao",
				"nLancDestinoRetencao": "nLancDestinoRetençao",
				"valorOrigemRetencao": "valeurOrigineRétention",
				"valorDestinoRetencao": "valeurDestinationRétention",
				"codRetencao": "Code de rétention",
				"taxaRetencaoUsada": "Taux de rétention utilisé",
				"nGrupoRetencao": "Poignée de rétention",
				"classificControloRetencao": "Classification.",
				"tipoRetServNIF": "Type de conservation NIF",
				"extPocLigaIDOrigemRetNaoDispo": "extPocLigaIDOrigemRetNaoDispo",
				"nLancOrigemImputacaoCCusto": "nLancOrigemImputacaoCCusto",
				"nLancDestinoImputacaoCCusto": "nLancDestinoImputacaoCCusto",
				"nContaDebito": "Compte de débit",
				"nContaCredito": "Compte de crédit",
				"temMovAberto": "Il y a des mouvements ouverts",
				"poc": {
					"nConta": "Numéro de compte",
					"nContaPlaceholder": "Code de compte",
					"nome": "Nom",
					"nomePlaceholder": "Nom du compte",
					"tipo": "Type de compte",
					"cc": "Avoir un compte courant",
					"nif": "Numéro de contribuable",
					"temCCusto": "Possède des analyses",
					"temIVA": "A un contrôle de TVA",
					"registaRetencao": "Conservation du registre",
					"temRetencao": "A une rétention",
					"bloqueada": "Bloqué",
					"dpq06NCampo": "Arrêté périodique TVA",
					"dpq06NCampoNome": "Arrêté périodique TVA",
					"daq08NCampo": "TVA annuelle déc. - Tableaux 03 et 05",
					"daq08NCampoNome": "TVA annuelle déc. - Tableaux 03 et 05",
					"daq09NCampo": "TVA annuelle déc. - Tableaux 04 et 05",
					"daq09NCampoNome": "TVA annuelle déc. - Tableaux 04 et 05",
					"codControloIVADebito": "Code de contrôle TVA débiteur",
					"codControloIVADebitoNome": "Code de contrôle TVA débiteur",
					"codControloIVACredito": "Code de contrôle du crédit TVA",
					"codControloIVACreditoNome": "Code de contrôle du crédit TVA"
				},
				"dcDebito": "Dette",
				"dcCredito": "Crédit"
			},
			"totais": {
				"baseTributavel": "Base imposable",
				"totalIVA": "TVA totale",
				"moedaCorrente": "Devise",
				"moedaEstrangeira": "Devises étrangères",
				"totalGeral": "total",
				"porPagar": "Non rémunéré"
			},
			"tooltipDataLancamento": "3.4.3.8.",
			"tooltipDataDoc": "3.4.3.3."
		},
		"erros": {
			"notselected": "Aucun document sélectionné !",
			"optionsNotAvailable": "Impossible de modifier les options lorsqu'un préréglage est actif",
			"changeMoedaNotAvailable": "Pour changer la devise vous devez d'abord supprimer toutes les lignes",
			"initDoc": "Le document n'a pas été initialisé.",
			"notSaldado": "Le document n'est pas équilibré!",
			"periodoFinalInferiorInicial": "La période finale est plus courte que la période initiale !",
			"nifAlreadyRegistered": "Le numéro de contribuable « {{nContribuinte}} » est déjà enregistré."
		},
		"configs": {
			"valorComIvaIncluido": "Valeur avec TVA incluse",
			"obtemDadosDocDigital": "Obtient les données du document numérique",
			"showFieldPredefinido": "Afficher le champ prédéfini (en-tête)",
			"showFieldDataVencimento": "Afficher le champ de date d'échéance (en-tête)",
			"showFieldMoeda": "Afficher le champ de devise (en-tête)",
			"showFieldLinhaNif": "Afficher le champ du numéro de contribuable (lignes)",
			"showFieldLinhaValorTaxa": "Afficher le champ de valeur TVA (lignes)",
			"showFieldLinhaDataDoc": "Afficher le champ de date du document (lignes)",
			"showFieldLinhaDescricao": "Afficher le champ de description (lignes)",
			"showFieldLinhaCDecPerAnual": "Afficher les champs (lignes) de déclaration périodique/annuelle",
			"showFieldLinhaNomeConta": "Afficher le champ du nom du compte (lignes)",
			"seletorObtemDadosDocDigital": "Afficher le bouton pour obtenir les données du document numérique dans la section des lignes",
			"seletorValorComIvaIncluido": "Afficher la valeur du bouton avec TVA incluse dans la section des lignes",
			"skipDescricao": "Ignorer le champ de description dans les lignes",
			"retainDescription": "Conserver la description entre les documents",
			"showFormMoeda": "Afficher le formulaire de devise étrangère",
			"skipPromptSave": "Ne plus demander de confirmation lors de la sauvegarde du document"
		},
		"changeValorTitle": "Changer {{type}}",
		"changeValorTaxaTitle": "Modifier le montant de la TVA",
		"changeCCustoTitle": "Attribution de valeurs d'analyse",
		"changeTitleInvalidLines": "Vous devez enregistrer au moins une valeur",
		"valorValidatorMessage": "Le {{type}} a une limite de différence maximale de {{value}}{{currency}} par rapport au {{type}} d'origine.",
		"contaTemLigacaoContasCorrentes": "Le compte {{nConta}} est lié aux comptes courants.",
		"desejaEfetuarPagamentos": "Vous souhaitez effectuer des paiements de documents sur des comptes courants ?",
		"contaAImputarJaExiste": "Le compte analytique à imputer {{nConta}} existe déjà dans la liste d'imputation !",
		"valorTemDeSerSuperior0": "La valeur doit être supérieure à 0.",
		"disponibilidadeRendimento": "Disponibilité des revenus",
		"contaCorrenteTemRetencaoDeduzida": "Le montant du compte courant est déjà déduit de la retenue",
		"montanteTributavel": "Montant imposable",
		"montanteRetido": "Montant retenu",
		"nDocumento": "Numéro du document",
		"changeregularizacaocampo40Title": "Régularisations au profit du contribuable - Couvertes par l'article 78",
		"nDocumentoRegularicacaoJaExiste": "Le numéro de document {{nDocumento}} existe déjà",
		"valorSomadoJaExistente": "La valeur {{valor}} ajoutée à la valeur existante {{ValueExisting}} dépasse la valeur de la base fiscale {{ValueBase}}.",
		"documentoEliminadoComSucesso": "Document supprimé avec succès.",
		"diferimentosAnuladosComSucesso": "Reports/accumulations annulés avec succès.",
		"viewDocImputado": "Afficher le document imputé",
		"viewDocTitle": "Document {{nDocument}}",
		"keyboardShortcuts": {
			"save": "Enregistrez le document",
			"saveAndAttach": "Enregistrez et joignez le document",
			"toggleIva": "Toggle \"Valeur TTC\"",
			"obterSaldoConta": "Obtenir le solde du compte (uniquement dans la colonne \"Montant\" de la ligne sélectionnée)",
			"firstDocumento": "Premier document de cette période/agenda",
			"lastDocumento": "Dernier document de cette période/agenda",
			"previousDocumento": "Document précédent de cette période/agenda",
			"nextDocumento": "Document suivant pour cette période/agenda"
		},
		"ccusto": {
			"badTotalTitle": "Confirmation de l'attribution des valeurs analytiques",
			"badTotalMessage": "La valeur affectée au centre de coûts est différente de la valeur totale à affecter."
		},
		"movab": {
			"title": "Paiements de documents en comptes courants",
			"nDocumento": "Numéro du document",
			"dc": "ANNONCE",
			"nDocExterno": "Document externe.",
			"dataDoc": "Date du document.",
			"valor": "Valeur",
			"porPagar": "Non rémunéré",
			"valorPagar": "Montant à payer",
			"valordesconto": "Rabais",
			"imputarTudo": "Imputer tout (toutes les lignes)",
			"imputarTudoLinha": "Imputer tout (ligne)",
			"limparValores": "Effacer toutes les valeurs imputées",
			"limparValorLinha": "Effacer la valeur",
			"dcDebito": "D",
			"dcCredito": "W",
			"totalImputar": "Total à imputer",
			"totalJaImputado": "Total déjà imputé",
			"totalImputado": "Total imputé",
			"descricao": "Description",
			"descritivo": "Descriptif",
			"totalConta": "Totaux du compte",
			"options": {
				"showDocExt": "Afficher le champ du document externe",
				"showDataDoc": "Afficher le champ de date du document",
				"showDescricao": "Afficher le champ de description",
				"showDescritivo": "Afficher le champ descriptif"
			},
			"movmesmoid": "Ces entrées appartiennent au même document numérique.",
			"movmesmoidval": "Ces entrées appartiennent au même document numérique avec la valeur {{value}}.",
			"valorPagarValidationInferior": "La valeur ne peut pas être inférieure à 0.",
			"valorPagarValidationSuperior": "La valeur ne peut pas être supérieure au montant à payer.",
			"valorDescontoValidationSuperior": "La valeur ne peut pas être supérieure au montant payable."
		},
		"regularizacaoCampo40": {
			"title": "Annuler les régularisations en faveur du contribuable",
			"message": "La non-indication des documents de régularisation entraînera des incohérences dans la déclaration de TVA.",
			"validateMessage": "Pour continuer, vous devez activer l'option \"J'ai remarqué et j'ai l'intention de continuer\"",
			"label": "Je l'ai découvert et j'ai l'intention de continuer"
		},
		"retencao": {
			"title": "Rétention à {{value}}",
			"imediata": "Immédiat",
			"noPagamento": "Au paiement",
			"disabledRetencaoDeduzida": "Pour modifier cette valeur, vous devez disposer d'un compte courant préalablement ouvert.",
			"modelo30": {
				"title": "Modèle 30",
				"nGuiaPagamento": "Onglet Paiement"
			},
			"messages": {
				"needCCBefore": "Pour rendre les revenus disponibles au moment du paiement, vous devez d'abord comptabiliser le compte courant."
			}
		},
		"clearPreDefinido": "Supprimer le préréglage",
		"clearSuggestion": "Annuler la suggestion",
		"clearSuggestionTitle": "Annuler la suggestion de lancement",
		"moedaestrangeira": {
			"title": "Libération en devise étrangère",
			"conta": "Compte",
			"moeda": "Devises étrangères",
			"cambioME": "Échange MOI",
			"valorME": "Valeur ME",
			"valorNacional": "Valeur nationale"
		},
		"toolbar": {
			"saveAndAttach": "Enregistrer et joindre",
			"saveWithoutAttach": "Enregistrer sans joindre",
			"contabilidadeDigital": "Comptabilité",
			"criarPreDefinido": "Créer un préréglage",
			"criarPreDefinidoTooltip": "Créer une pièce comptable prédéfinie basée sur cela",
			"infoDiferimentos": "Informations sur le report",
			"infoGlobalDiferimentos": "Informations globales sur le report",
			"infoGlobalAcrescimos": "Informations supplémentaires globales",
			"infoDiferimentosHint": "Informations sur l'accumulation/le report",
			"anularDiferimento": "Annuler l'accumulation/le report"
		},
		"nDocSeq": "Doc n°",
		"text": {
			"maintenancePreDefinidos": "Maintien d'une comptabilité prédéfinie",
			"maintenanceExtratos": "Consulter les relevés de compte",
			"maintenanceMovab": "Afficher les mouvements ouverts",
			"maintenanceClifos": "Maintenance client/fournisseur",
			"recolha": "Collection",
			"conta": "Compte : {{nAccount}} - {{name}}",
			"saldoConta": "Solde : {{saldoConta}}",
			"obterSaldoConta": "Obtenir le solde du compte",
			"modificarRegularizacoesSujeitoPassivo": "Régularisations en faveur du contribuable",
			"calculodiferimentos": "Accumulations et reports",
			"registarNif": "Enregistrer le numéro de contribuable",
			"marcadoComoConsistente": "Document intégré avec erreur et marqué comme cohérent par {{name}} le {{date}}",
			"extratosconta": "Relevés de compte",
			"mudarperiodocontab": "Changer la période comptable de l'entreprise",
			"confirmMudaPerio": "Êtes-vous sûr de vouloir changer la période comptable de l'entreprise en \"{{période}}\" ?",
			"periodoempmudadosuccesso": "La période comptable de l'entreprise a été modifiée avec succès !",
			"docativosconfirm": "Vous avez enregistré un bien en comptabilité.<br>Voulez-vous enregistrer l'acquisition du bien dans le module de gestion de patrimoine ?",
			"editValorIva": "Modifier le montant de la TVA",
			"incValorIva": "Augmenter la valeur de la TVA",
			"decValorIva": "Diminuer la valeur de la TVA",
			"calculouDiferimentos1": "Le calcul des ajouts/reports a été effectué et pour cette raison le document a été bloqué.",
			"calculouDiferimentos2": "cliquez ici pour annuler le calcul",
			"calculouDiferimentos3": "Vous devez cliquer sur « Enregistrer » pour appliquer le report au document.",
			"calculouDiferimentosLocked": "Document avec augmentation/report associé.",
			"confirmAnulaDiferimento": "Etes-vous sûr de vouloir annuler l'augmentation/le report ?",
			"docImputacao": "Imputé au document : {{ndocImput}}"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;'",
			"exitMessage": "En cliquant sur OK, vous quitterez sans enregistrer la pièce comptable et toutes les modifications que vous avez apportées à cette pièce seront supprimées.",
			"doNotAskAgain": "Ne demande plus",
			"perguntaCC": "Ne plus afficher pour ce compte lorsque le signal est à {{sign}}."
		},
		"saveprompt": {
			"saveDocAndAttach": "Enregistrer le document et joindre des documents numériques",
			"saveDocIdAndAttach": "Enregistrez le document {{id}} et joignez des documents numériques"
		}
	},
	"docscontabilidadeimprimir": {
		"label": {
			"email": "E-mail",
			"enviar": "Pour envoyer"
		},
		"email": {
			"emailEnviado": "E-mail envoyé à {{email}}",
			"emailErro": "Erreur lors de l'envoi de l'e-mail à {{email}}."
		}
	},
	"docsnaosaldados": {
		"title": "Documents déséquilibrés",
		"fields": {
			"doPeriodo": "période de",
			"deConta": "compte"
		},
		"alertNaoHaDocs": "Aucun document non réglé n'a été trouvé."
	},
	"documentosFiscais": {
		"tabIva": "T.V.A.",
		"tabIRC": "IRC",
		"tabRecibos": "Recettes",
		"tabIES": "HEI",
		"tabIRS": "IRS",
		"tabIvaDp": "Déclarations périodiques de TVA",
		"tabIvaDr": "Déclarations récapitulatives de TVA",
		"tabIRCPagAnt": "Paiements anticipés",
		"tabIRCMod22": "Modèle 22",
		"tabRecibosRVClient": "Reçus verts en tant que client",
		"tabRecibosRR": "Recettes de revenus",
		"tabIESConsult": "Consulter",
		"tabIRSMod10": "Modèle 10",
		"tabRetencoes": "Retenues",
		"tabRetencoesIRCIRS": "Retenues IRC/IRS et I. Sceau",
		"tabIUC": "CIU",
		"tabVeiculos": "Véhicules",
		"tabCertidoes": "Certificats",
		"tabImoveis": "Immobilier",
		"tabImoveisPP": "Patrimoine bâti",
		"tabImoveisNC": "Notes de facturation",
		"tabPenhoras": "Liens",
		"tabSSSituacaoContrib": "Statut contributif",
		"tabViaCTTInbox": "Boîte aux lettres",
		"tabIUCDocumentos": "Documents",
		"tabIUCEmitir": "Problème",
		"tabConsultarCertidoes": "Consulter les certificats",
		"tabEmitirCertidoes": "Délivrer un certificat",
		"buttons": {
			"get": "Pour obtenir",
			"search": "A chercher",
			"efetuarPedido": "Passer la commande",
			"mb": "ATM"
		},
		"fields": {
			"periodo": "Période",
			"identificacao": "Identification",
			"dataRececao": "Date de réception",
			"valor1": "Livraison des taxes",
			"valor2": "Récupération d'impôt",
			"comprovativo": "Preuve",
			"certidao": "Certificat",
			"docPagamento": "Document de paiement",
			"tipo": "Taper",
			"situacao": "Situation",
			"numero": "Nombre",
			"dataSubmissao": "Date de soumission",
			"submissaoValor": "Valeur de la soumission",
			"dataPagamento": "Date de paiement",
			"pagamentoValor": "Montant du paiement",
			"obterDetalhes": "Détails du document",
			"numDocumento": "Doc n°",
			"dataEmissao": "Date d'émission",
			"nif": "FNI",
			"nome": "Nom",
			"domicilioFiscal": "Domicile fiscal",
			"atividadePrestador": "Activité réalisée",
			"titulo": "Titre",
			"dataRef": "Date de référence",
			"descricao": "Description",
			"valorBase": "Valeur de base",
			"ivaDesc": "Description de la TVA",
			"valorIVA": "Valeur TVA",
			"valorImpostoSelo": "Taxe de timbre",
			"valorIRS": "Valeur IRS",
			"importanciaRecebida": "Importance reçue",
			"reciboEco": "Reçu économique",
			"reciboNormal": "Reçu normal",
			"periodoFull": "Période",
			"dataRecebimento": "Date de réception",
			"tipoImportancia": "Type d'importance",
			"valor": "Valeur",
			"retencao": "Rétention",
			"document": "Document",
			"recRefContrato": "Référence du contrat",
			"numeroRecibo": "N° reçu",
			"importancia": "Importance",
			"anexos": "Pièces jointes",
			"declaracao": "Déclaration",
			"dataRecepcao": "Date de réception",
			"via2comprovativo": "2ème exemplaire",
			"aPagar": "Importance de payer",
			"tipoDocumento": "Type de document.",
			"dataSituacao": "Situation des dates",
			"guiaPagamento": "Onglet Paiement",
			"matricula": "Inscription",
			"catIUC": "IUC Cat.",
			"dataLimitePagamento": "Délai de paiement",
			"valorImposto": "Valeur fiscale",
			"valorJuros": "Valeur des intérêts",
			"situacaoNota": "Remarque sur la situation",
			"refPagamento": "Page de réf.",
			"emissao": "Émission",
			"dataMatricula": "Date d'inscription",
			"marca": "Marque",
			"modelo": "Modèle",
			"propLoc": "Propriétaire/locataire",
			"tipoNota": "Type de note",
			"valorEmitido": "Montant émis",
			"valorPago": "Montant payé",
			"qtdVeiculosLiquidados": "Quantité de véhicules",
			"categoria": "Catégorie",
			"mesMatricula": "Inscription mensuelle",
			"adicionalIUC": "CIU supplémentaire",
			"dataPedido": "Date de commande",
			"tipoCertidao": "Type de certificat",
			"estado": "État",
			"nPedidoPenhora": "Numéro de demande de pièce jointe",
			"descricaoBem": "Description du bien",
			"codigoBem": "code du bien",
			"qtdReconhecida": "Quantité reconnue",
			"unidade": "Unité",
			"valorUnit": "Valeur unitaire.",
			"niss": "NISS",
			"dataResposta": "Date de réponse",
			"resposta": "Réponse",
			"entidade": "Entité",
			"conta": "Compte",
			"dataEntrega": "La date de livraison",
			"lido": "Lire",
			"tratado": "Traité",
			"freguesia": "Paroisse",
			"artigo": "Article",
			"parte": "Partie",
			"valorInicial": "Valeur initiale",
			"idNotaCobranca": "Numéro de note de facturation",
			"numPredios": "Nombre de bâtiments",
			"cadernetaPredial": "Livret de construction",
			"guia": "Guide",
			"pagamento": "Paiement",
			"dataRegisto": "Date d'inscription",
			"pedidoPor": "Demande par"
		},
		"strings": {
			"headerAT": "Autorité Fiscale (AT)",
			"headerSS": "Sécurité sociale",
			"headerViaCTT": "Via CTT",
			"comprovativo": "{{title}} - Preuve : {{id}}",
			"via2comprovativo": "{{title}} - 2ème exemplaire : {{id}}",
			"docPagamento": "{{title}} - Document de paiement : {{id}}",
			"primeiraDeclaracao": "Première déclaration",
			"declaracaoSubstituicao": "Déclaration de remplacement",
			"detalhesDoc": "{{title}} - Détails du document : {{id}}",
			"recibo": "{{title}} - Reçu : {{id}}",
			"naoDisponivel": "Pas disponible",
			"modalTitleGuiaPagamento": "{{title}} - Onglet Paiement : {{id}}",
			"iucEmitirLigeiros": "Voitures et motos",
			"iucEmitirMercadorias": "Marchandises mixtes et marchandises légères d'un poids brut supérieur à 2500 kg",
			"emitido": "Émis",
			"modalMessageEmitirIUC": "Êtes-vous sûr de vouloir émettre le document de paiement pour l'inscription : {{matricula}} ?",
			"emitidoComSucesso": "Document de paiement émis avec succès !",
			"emitir": "Problème",
			"liquidacaoIRS": "Règlement IRS",
			"renunciaIsencaoIvaImovel": "Renonciation à l'exonération de TVA - Immobilier",
			"residenciaFiscal": "Résidence fiscale",
			"dividaNaoDivida": "Dette et non-dette",
			"sujPassivoRecupIVAEstrangeiro": "Sale.",
			"residenteNaoHabitual": "Résident non habituel",
			"domicilioFiscal": "Domicile fiscal",
			"predialNegativa": "Propriété négative",
			"predial": "Bâtiment",
			"activa": "Actif",
			"pendente": "En attente",
			"pendenteConfirmacao": "En attente de confirmation",
			"expirada": "Expiré",
			"anulada": "Annulé",
			"modalMessagePedirCertidao": "Êtes-vous sûr de vouloir demander le certificat : {{name}} ?",
			"comprovativoSimpleTtile": "Preuve",
			"ssSitContribInfoText": "Déclarations de statut contributif émises après le 24/10/2020",
			"viaCTTInfoText": "L'application affiche uniquement les 20 derniers enregistrements.",
			"alterarCredenciais": "Modifier les informations d'identification",
			"portalAT": "Portail financier (AT)",
			"portalSSD": "Sécurité Sociale Directe (SSD)",
			"portalViaCTT": "Via CTT",
			"cadernetaPredial": "{{title}} - Livret de construction - Article : {{article}} - Année : {{year}}",
			"emptyDocs": "Vous n'avez sélectionné aucun document.",
			"associarDocumentoContabDigital": "Ajouter un document à la comptabilité numérique",
			"associarDocumentosContabDigital": "Ajouter des documents à la comptabilité numérique",
			"successAssociarComprovativoContabDigital": "Document ajouté avec succès",
			"verDocumento": "Afficher le document"
		},
		"progress": {
			"message": "Associer des documents à la comptabilité numérique"
		},
		"modules": {
			"iuc": {
				"emitir": {
					"catA": "Catégorie A - Véhicules légers de tourisme et véhicules mixtes d'un poids brut n'excédant pas 2 500 kg (achetés avant le 30 juin 2007)",
					"catB": "Catégorie B - Véhicules légers de tourisme et véhicules mixtes d'un poids brut n'excédant pas 2 500 kg (acquis à partir du 1er juillet 2007)",
					"catE": "Catégorie E - Motocyclettes et équivalents",
					"catC": "Catégorie C - Marchandises mixtes et véhicules légers d'un poids brut supérieur à 2 500 kg pour compte propre",
					"catD": "Catégorie D - Marchandises mixtes et véhicules légers d'un poids brut supérieur à 2 500 kg pour le compte externalisé"
				}
			}
		}
	},
	"efatura": {
		"showComunicacaoInexistencia": "Pas de facturation (TEST)",
		"summary": "Résumé du dossier",
		"summaryErros": "Avertissements / Erreurs de fichiers",
		"invoiceTotal": "Nombre de factures",
		"totalCredit": "Crédit complet",
		"totalDebit": "Dette totale",
		"summaryByDoc": "Résumé par type de document",
		"summaryByType": "Récapitulatif par code TVA",
		"btnComunicaDocs": "Communiquer des documents",
		"btnSaftGlobal": "SAF-T Global",
		"btnSaftAutofaturacao": "SAF-T Autofacturation",
		"btnLoginWebserviceAT": "AT Webservice - Communication facturation",
		"btnGenerateDoc": "Générer une facture électronique",
		"btnDownload": "Télécharger",
		"btnComunicar": "Communiquer",
		"btnComunicaInexistenciaFaturacao": "Signaler un manque de facturation",
		"btnMonthsJan": "Janvier",
		"btnPesquisar": "A chercher",
		"naoGeradoString": "(non généré)",
		"geradoString": "(généré)",
		"inexistenciaFaturacaoModalTitle": "Signaler un manque de facturation",
		"btnRemoverInexistencia": "Supprimer aucune facturation",
		"table": {
			"tipoSAFT": "Taper",
			"docNumber": "Doc n°",
			"nif": "FNI",
			"nomeClifo": "Nom",
			"valorIVA": "Taxe totale",
			"valorTributavel": "Valeur imposable",
			"valorParaSAFT": "Valeur SAFT",
			"valorTotal": "Montant total",
			"estado": "État",
			"comunicado": "Annonce",
			"erros": "Erreurs/Avertissements",
			"name": "Nom",
			"vatValue": "Valeur TVA",
			"rate": "Taux",
			"tipoComunicacao": "Type de communication"
		},
		"tipoComunicacao": {
			"saft": "Fichier SAFT",
			"webService": "Service Web"
		},
		"resumoFicheiro": {
			"comunicados": "Annonces",
			"porComunicar": "Pour communiquer"
		},
		"notices": "Avertissements",
		"errors": "Erreurs",
		"docState": {
			"normal": "N-Normal",
			"nullified": "A - Annulé",
			"billed": "F - Facturé"
		},
		"messageConfigLoginAt": "Les documents de vente seront configurés pour être communiqués via un service Web à eFatura.",
		"nDocsNComunicados": "Nombre de documents non communiqués:",
		"messagePermissions": "Attention : Pour modifier les champs, vous devez disposer des autorisations d'administrateur !",
		"comunicacaoRealizadaSucesso": "Communication réalisée avec succès.",
		"comunicaDocsModal": {
			"title": "Liste des documents"
		},
		"geraDocModal": {
			"title": "Générer un document de facture électronique",
			"soDocsPorComunicar": "Seuls les documents à communiquer",
			"messageTodosComunicados": "En poursuivant, le fichier généré contiendra tous ceux déjà communiqués.",
			"messageSoDocsPorComunicarFalse": "Génère un fichier avec tous les documents de la période",
			"messageSoDocsPorComunicarTrue": "Génère un fichier avec uniquement les documents à communiquer"
		},
		"mesDeclaracao": "Mois de déclaration",
		"confirmComunicacaoCheckLabel": "Je confirme l'intention de communiquer l'absence de facturation",
		"confirmAnulacaoComunicacaoCheckLabel": "Je confirme l'intention de supprimer aucune facturation",
		"comunicacaoSuccessMsg": "La communication de non-facturation a été effectuée avec succès.",
		"anulacaoComunicacaoSuccessMsg": "L'annulation de la communication de non-facturation a été réalisée avec succès."
	},
	"efaturacirculacaologin": {
		"title": "Connexion à AT-Doc.",
		"saveTitle": "Sauvegarder",
		"fields": {
			"username": "Nom d'utilisateur (NIF/ID utilisateur)",
			"password": "Mot de passe"
		},
		"messages": {
			"success": "Connectez-vous avec succès.",
			"errorUsernameEmpty": "Nom d'utilisateur vide.",
			"errorPasswordEmpty": "Mot de passe vide.",
			"noPermissions": "Attention : Pour modifier les valeurs, vous devez disposer des autorisations d'administrateur !"
		}
	},
	"efaturalogin": {
		"title": "Connexion à la facture électronique",
		"saveTitle": "Sauvegarder",
		"fields": {
			"tipoComunicacao": "Type de communication AT",
			"username": "Nom d'utilisateur (NIF/ID utilisateur)",
			"password": "Mot de passe"
		},
		"messages": {
			"success": "Connectez-vous avec succès.",
			"errorUsernameEmpty": "Nom d'utilisateur vide.",
			"errorPasswordEmpty": "Mot de passe vide.",
			"noPermissions": "Attention : Pour modifier les valeurs, vous devez disposer des autorisations d'administrateur !"
		}
	},
	"emailclifos": {
		"sendMail": {
			"title": "Envoyer un e-mail : {{nAccount}} - {{nameAccount}}",
			"messageSuccess": "L'e-mail a été envoyé avec succès à {{emailList}}",
			"sendAllCheckbox": "Je souhaite envoyer automatiquement les e-mails restants",
			"sendAllMessage": "Envoi d'e-mails",
			"sendAllSuccess": "E-mails envoyés avec succès",
			"btn": {
				"ignore": "Ignorer",
				"sendMail": "Envoyer un e-mail",
				"send": "Pour envoyer"
			},
			"tooltips": {
				"ignore": "Lorsqu'il est ignoré, il passe à l'e-mail suivant sans l'envoyer",
				"cancel": "Lors de la désinscription, cet e-mail et les suivants seront désabonnés",
				"cancelCheck": "Lorsque vous annulez, vous ne pourrez plus continuer et les e-mails précédents seront annulés"
			},
			"messages": {
				"success": "E-mail envoyé avec succès"
			},
			"email": "E-mail",
			"assunto": "Sujet",
			"anexos": "Pièces jointes",
			"texto": "Texte"
		}
	},
	"empresabloqueada": {
		"title": "L'entreprise n'est pas disponible",
		"message": "<strong>La société n'est pas disponible</strong>, contactez votre administrateur système pour plus d'informations.",
		"changeCompany": "Changer d'entreprise",
		"reasons": {
			"companyEmptyRoles": "Entreprise non disponible, car elle n'a accès à aucun portail."
		}
	},
	"empresas": {
		"title_detail": "Entreprise {{id}}",
		"title_new": "Nouvelle entreprise",
		"title_plural": "Entreprises",
		"pesquisa": "A chercher",
		"saved": "Entreprise {{id}}, enregistrée avec succès",
		"error": "L'entreprise {{id}} ne peut pas être enregistrée.",
		"fields": {
			"primeiroMesContab": "Premier mois comptable",
			"nempresa": "numéro d'entreprise",
			"nempresaPlaceholder": "Numéro d'entreprise",
			"nome": "Nom",
			"nomePlaceholder": "Nom de l'entreprise",
			"nomeAlternativo": "Nom commercial",
			"morada": "Ménage",
			"codpostal": "Code Postal",
			"localidade": "Emplacement",
			"nif": "Numéro de contribuable",
			"pais": "Pays",
			"moeda": "Pièce de monnaie",
			"tipoEmpresa": "Type d'entreprise",
			"alvara": "Permis",
			"textoRelatorio": "Texte du rapport",
			"textoRelatorioPlaceholder": "(Insérez ici l'IBAN de l'entreprise par exemple)",
			"capitalSocial": "Capital social",
			"telefone": "Téléphone",
			"email": "E-mail",
			"web": "adresse Internet",
			"repFinancas": "Service financier",
			"tipoContab": "Type de comptabilité",
			"regimeIRC": "Régime IRC/IRS",
			"regimeIva": "Régime TVA",
			"periodoIva": "Période de TVA",
			"anoCursoIRC": "Année de cours IRC",
			"periodoContabilistico": "Période comptable",
			"tipoAnalitica": "Analytique",
			"capPublico": "Capital public",
			"capPrivado": "Capital privé national",
			"capEstrangeiro": "Capital étranger",
			"capPublicoPerc": "% Capital public",
			"capPrivadoPerc": "% Capital privé",
			"capEstrangeiroPerc": "% Capital étranger",
			"nomeComarca": "Nom du comté",
			"nomeConservatoria": "Nom du conservatoire",
			"registoConservatoria": "Inscription au bureau d'état civil",
			"nifCC": "Numéro de contribuable d'expert-comptable",
			"nOCC": "Numéro d'immatriculation du comptable à l'OCC",
			"nifAdmin": "Numéro de contribuable du représentant légal",
			"nContribSegSocial": "Numéro de contribuable en sec.",
			"cae": "CAE",
			"atividadeprincipal": "Activité principale",
			"seMesCivilDifPerioComecaAnoSeg": "Si le mois calendaire est différent de la période, l'année commence l'année suivante.",
			"concelhoEmp": "Comté",
			"telemovel": "Téléphone portable",
			"mostraTarifasNasListagens": "Afficher le type de tarif de ligne téléphonique dans les annonces (pour le contact avec le consommateur)",
			"codNatJuridica": "Code natif",
			"codAssocPatronal": "Code de l'association patronale",
			"codddccff": "Code de district"
		},
		"steps": {
			"dadosGerais": "Données générales",
			"contactos": "Contacts",
			"dadosContab": "Données comptables et fiscales",
			"logotipo": "Logo",
			"dadosRH": "Données pour les RH"
		},
		"messages": {
			"success": {
				"save": "Enregistré avec succès",
				"saveLogotipo": "Logo chargé avec succès.",
				"deleteLogoTipo": "Logo supprimé avec succès."
			},
			"error": {
				"save": "Erreur d'enregistrement"
			},
			"logoAlt": "Logo de l'entreprise",
			"changeLogo": "Changer de logo",
			"uploadLogo": "Télécharger le logo",
			"deleteLogo": "Supprimer le logo",
			"changedperiocontabfirstmonth": "Modifications enregistrées avec succès.",
			"configDivulgacaoTiposTarifa": "Configurer la divulgation du type de tarif"
		},
		"changePerioContabTooltip": "Définir une année civile différente de l'année fiscale",
		"modal": {
			"confirmTitle": "Analytics : lancez toujours Analytics",
			"confirmMessage": "Souhaitez-vous configurer le plan comptable des comptes d'analyse ?",
			"configanocivildiftitle": "Configuration de l'année civile différente de l'année fiscale",
			"tarifas": {
				"modalTitle": "Divulgation des types de tarifs",
				"title1": "Publication de lignes téléphoniques pour le contact avec les consommateurs",
				"title2": "Décret-loi n° 59/2021 (en vigueur depuis le 01-11-2021)",
				"redeFixaNacional": "(Réseau fixe national)",
				"redeMovelNacional": "(Réseau mobile national)"
			}
		},
		"maintenance": {
			"analiticaconfig": "Configuration de comptes d'analyse"
		},
		"storewelcome": {
			"title": "Bienvenue sur CentralGest Cloud",
			"message1": "Avant de commencer à utiliser l'application, vous <strong>devez remplir les informations d'identification de l'entreprise</strong>.",
			"message2": "Veuillez remplir les champs suivants",
			"required": {
				"nif": "Numéro de contribuable de l'entreprise"
			},
			"errors": {
				"nif": "Veuillez saisir le numéro fiscal de l'entreprise"
			}
		}
	},
	"entidadeexterna": {
		"title_detail": "Entité externe",
		"title_new": "Nouvelle entité externe",
		"title_plural": "Entités externes",
		"pesquisa": "A chercher",
		"deleted": "Entité externe supprimée avec succès.",
		"saved": "Entité externe enregistrée avec succès.",
		"error": "L'entité externe ne peut pas être enregistrée.",
		"fields": {
			"entidadeExternaID": "",
			"codigo": "Code",
			"nome": "Nom",
			"morada": "Adresse 1",
			"morada2": "Adresse 2",
			"nContribuinte": "Numéro de contribuable",
			"contacto": "Contact",
			"email": "E-mail",
			"tipoEmpresa": "Type d'entreprise",
			"tipoContabilidade": "Type de comptabilité",
			"nEmpresaGabinete": "Entreprise d'ébénisterie",
			"periodoIVA": "Période de TVA",
			"regimeIRC": "Régime IRC/IRS"
		}
	},
	"entidadehabitual": {
		"desconhecido": "Inconnu",
		"clientes": "Clients",
		"fornecedores": "Fournisseurs"
	},
	"erps": {
		"title_detail": "ERP {{id}}",
		"title_new": "Nouvel ERP",
		"title_plural": "ERP",
		"pesquisa": "A chercher",
		"saved": "ERP {{id}}, enregistré avec succès",
		"error": "ERP {{id}}, ne peut pas être enregistré.",
		"fields": {
			"apiUrl": "URL de l'API",
			"apiUrlPlaceholder": "URL de l'API",
			"name": "Nom de l'ERP",
			"nomePlaceholder": "Nom de l'ERP",
			"centralGestId": "Identifiant CentralGest"
		}
	},
	"erputilizadores": {
		"title_detail": "ID de l'utilisateur}}",
		"title_new": "Nouvel utilisateur",
		"pesquisa": "A chercher",
		"saved": "Utilisateur {{id}}, enregistré avec succès",
		"error": "L'utilisateur {{id}} ne peut pas être enregistré.",
		"removecompany": "Supprimer l'entreprise",
		"fields": {
			"erp": "ERP",
			"nutilizador": "Numéro d'utilisateur",
			"nutilizadorPlaceholder": "Numéro d'utilisateur",
			"nomeUtilizador": "Nom d'utilisateur",
			"nomeUtilizadorPlaceholder": "Nom d'utilisateur",
			"nomeCompleto": "Nom et prénom",
			"nomeCompletoPlaceholder": "Nom et prénom"
		},
		"companies": {
			"title": "Entreprises",
			"available": "Disponible",
			"selected": "Choisi"
		},
		"addMultiEmpresa": "Ajouter plusieurs entreprises",
		"options": {
			"showApi": "Afficher les appels d'API"
		},
		"editRow": {
			"title": "Modifier l'ERP",
			"erp": "ERP",
			"nEmpresa": "Numéro d'entreprise",
			"nUtilizador": "Numéro d'utilisateur",
			"ativo": "Actif",
			"codEmpregado": "Code d'employé",
			"disponiveis": "Disponible",
			"temAcesso": "Avoir accès",
			"naoTemAcesso": "Pas d'accès"
		}
	},
	"estabelecimento": {
		"title_detail": "Établissement {{id}}",
		"title_new": "Nouvel établissement",
		"title_plural": "Établissements",
		"pesquisa": "A chercher",
		"saved": "Établissement {{id}}, enregistré avec succès.",
		"error": "L'établissement {{id}} ne peut pas être enregistré.",
		"deleted": "Établissement {{id}}, supprimé avec succès",
		"fields": {
			"nEstabElec": "Code.",
			"nEstabElecPlaceholder": "Code d'établissement",
			"nome": "Nom",
			"nomePlaceholder": "Nom de l'établissement",
			"designaBreve": "Désigner un mémoire",
			"designaBrevePlaceholder": "Désigner un mémoire",
			"morada": "Ménage",
			"moradaPlaceholder": "Ménage",
			"localidade": "Emplacement",
			"localidadePlaceholder": "Emplacement",
			"nPostal": "Code Postal",
			"nPostalPlaceholder": "Code Postal",
			"distrito": "District",
			"concelho": "Comté",
			"freguesia": "Paroisse",
			"freguesiaPlaceholder": "Paroisse",
			"caeaCTPrinc": "caeaCTPrince",
			"caeaCTPrincPlaceholder": "Numéro CAE",
			"codDdCcFf": "Code de district, conc., frég.",
			"codDdCcFfPlaceholder": "Code de district, conc., frég.",
			"nEstabQPessoal": "Numéro d'établissement",
			"nEstabQPessoalPlaceholder": "Numéro d'établissement",
			"nEstabSegSocial": "Numéro d'établissement",
			"nEstabSegSocialPlaceholder": "Numéro d'établissement",
			"activPrincip": "Activité principale",
			"activPrincipPlaceholder": "Activité principale",
			"nContRib": "Numéro de contribuable",
			"nContRibPlaceholder": "Numéro de contribuable",
			"nCaixaPrev": "Numéro de caisse de pension",
			"codCCt": "Code CCT",
			"telefone": "Téléphone",
			"telefonePlaceholder": "Téléphone",
			"calenCABId": "Calendrier",
			"sede": "Soif"
		}
	},
	"estadoconta": {
		"ativa": "Actif",
		"desactivada": "Désactivé",
		"bloqueada": "Bloqué"
	},
	"estadoemp": {
		"ativo": "Actifs",
		"inativo": "Inactif",
		"ambos": "Tous"
	},
	"estadoregisto": {
		"ativo": "Actif",
		"inativo": "Inactif"
	},
	"estados": {
		"title_detail": "État {{id}}",
		"title_plural": "États",
		"pesquisa": "A chercher",
		"fields": {
			"codEstado": "Code d'état",
			"nome": "Nom",
			"nomeAbr": "Abréviation",
			"tipoEstado": "Type d'état"
		}
	},
	"estadosValidacao": {
		"data": {
			"naoPossivel": "Impossible de créer des versions",
			"criadoNaoValidado": "Release créée, mais pas encore validée",
			"criadoValidado": "Release créée, mais déjà validée"
		}
	},
	"exportacaoCompras": {
		"fields": {
			"documentosPorExportar": "Envoyer uniquement les documents non exportés"
		},
		"dataGrid": {
			"doc": "Doc.",
			"nDocExterno": "Doc n°",
			"nFornecedor": "Numéro du fournisseur",
			"nomeFornecedor": "Nom du fournisseur",
			"exportado": "Exporté",
			"exportId": "ID d'exportation"
		},
		"config": {
			"title": "Configuration - Achat export",
			"familiasDataGrid": {
				"title": "Familles",
				"nFamCG": "Numéro de famille CG",
				"nomeFamCG": "Nom de famille CG",
				"idFamGeo": "Famille Géofolia",
				"nomeFamGeo": "Nom de famille Géofolia",
				"operacao": "Opération",
				"legend": {
					"naoConfiged": "Non configuré",
					"campoEdit": "Champ modifiable"
				}
			},
			"docsDataGrid": {
				"titleDocsCompras": "Documents d'achat",
				"titleDocsNotaCredito": "Documents de note de crédit",
				"id": "identifiant du document",
				"name": "Nom",
				"desc": "Description",
				"contectMenu": {
					"desmarcaDocsExported": "Désélectionner les documents exportés (sélectionnés)",
					"abrirDoc": "Ouvrir le document"
				}
			}
		},
		"mensagens": {
			"configSuccess": "Configuration enregistrée avec succès",
			"okModalTitle": "Désélectionner l'exportation des documents sélectionnés",
			"okModalMensagem": "Êtes-vous sûr de vouloir désélectionner l'exportation des documents sélectionnés ?",
			"documentosDupsNotAllowed": "Le document '{{docName}}' est déjà configuré",
			"linhaObrigatoria": "Obligatoire pour sélectionner un document à ajouter avec succès",
			"familiasDupsNotAllowed": "Une famille CentralGest est déjà configurée avec l'identifiant '{{id}}'"
		}
	},
	"extractosartigos": {
		"title_plural": "Extraits d'articles",
		"pesquisa": "A chercher",
		"fields": {
			"nArtigo": "Code article",
			"nomeArtigo": "Nom de l'article",
			"dataDoc": "Date du document.",
			"nDocAsString": "Doc n°",
			"nDocfa": "Type de document.",
			"descricaoDocfa": "Description du type de document.",
			"nArmazem": "Code entrepôt",
			"nomeArmazem": "Nom de l'entrepôt",
			"nLoteEspecifico": "Lot spécifique",
			"preco": "Prix",
			"valorEntrada": "Valeur d'entrée",
			"valorSaida": "Valeur de sortie",
			"saldoValor": "Valeur du solde",
			"qtdEntrada": "Montant saisi",
			"qtdSaida": "Quantité de sortie",
			"saldoQtd": "Quantité d’équilibre.",
			"nConta": "Numéro de compte",
			"nomeClifo": "Nom du client/fournisseur",
			"unidadeMov": "Unité de mouvement."
		},
		"btn": {
			"options": {
				"showNArtigo": "Afficher le code.",
				"showNomeArtigo": "Afficher le nom de l'article",
				"showDataDoc": "Affiche la date du document.",
				"showNDocAsString": "Affiche le numéro de document.",
				"showNDocfa": "Affiche le type de document.",
				"showDescricaoDocfa": "Affiche la description du type de document.",
				"showNArmazem": "Afficher le code.",
				"showNomeArmazem": "Afficher le nom de l'entrepôt",
				"showNLoteEspecifico": "Afficher un lot spécifique",
				"showPreco": "Afficher le prix",
				"showValorEntrada": "Afficher la valeur d'entrée",
				"showValorSaida": "Afficher la valeur de sortie",
				"showSaldoValor": "Affiche la valeur du solde",
				"showQtdEntrada": "Affiche la quantité.",
				"showQtdSaida": "Affiche la quantité.",
				"showSaldoQtd": "Affiche la quantité restante.",
				"showNConta": "Afficher le numéro de compte",
				"showNomeClifo": "Affiche le nom du client/fournisseur",
				"showUnidadeMov": "Affiche l'unité de mouvement."
			}
		},
		"caption": {
			"deCodArtigo": "Code article",
			"deNArmazem": "Code entrepôt",
			"deData": "Date de",
			"calcMovAntesDepoisData": "Calcule les mouvements avant et après la date"
		},
		"info": {
			"erroDataDeMaiorQueAte": "La date de début est supérieure à la date de fin",
			"valormaximonarmazem": "La valeur maximale du code."
		},
		"contextmenu": {
			"openDocCcomercial": "Ouvrir le document",
			"openArtigo": "Ouvrir la fiche article"
		}
	},
	"extratosClifos": {
		"radical": "Radical des comptes",
		"table": {
			"fields": {
				"nConta": "Compte",
				"nomeConta": "Nom du compte",
				"nDocExterno": "Document externe.",
				"dataVenc": "Date d'expiration",
				"descricao": "Description",
				"valorDebito": "Montant du débit",
				"valorCredito": "Valeur du crédit",
				"porPagar": "Non rémunéré",
				"dataDoc": "Date du document.",
				"moeda": "Pièce de monnaie",
				"nCCusto": "Coût NC",
				"valorDebitoSegMoeda": "Montant du débit sec.",
				"valorCreditoSegMoeda": "Valeur du crédit sec.",
				"cambioSegMoeda": "Échange lundi.",
				"descritivo": "Descriptif",
				"nDocumento": "Numéro du document",
				"periodo": "Période",
				"nDiario": "Tous les jours"
			}
		},
		"btn": {
			"processar": "Processus"
		}
	},
	"extratosDT": {
		"fields": {
			"periodo": "Période",
			"diario": "Tous les jours",
			"nDocInterno": "Doc.",
			"nDocExterno": "Document externe.",
			"dataVencimento": "Date d'échéance",
			"descricao": "Description",
			"valorDebito": "Dette",
			"valorCredito": "Crédit",
			"porPagar": "Non rémunéré",
			"dataDoc": "Date du document.",
			"moeda": "Pièce de monnaie",
			"debitoEmDivisa": "Dette en devises",
			"creditoEmDivisa": "Crédit en devises",
			"porPagarEmDivisa": "Pour payer en devise",
			"cCusto": "C. Coût",
			"cambioSegMoeda": "Échange le lundi.",
			"debitoSegMoeda": "Débit en sec.",
			"creditoSegMoeda": "Crédit en sec.",
			"nLanc": "Lancement n°.",
			"dataDocExterno": "Date du document.",
			"cambio": "Échange",
			"debitoME": "Débit en ME",
			"creditoME": "Crédit en ME",
			"abreviaturaMoeda": "Pièce de monnaie"
		},
		"config": {
			"showMoeda": "Afficher la colonne des devises",
			"showDebitoEmDivisa": "Afficher la colonne de débit en devise",
			"showCreditoEmDivisa": "Afficher la colonne de crédit en devise",
			"showPorPagarEmDivisa": "Afficher la colonne des devises impayées",
			"showCCusto": "Afficher la colonne de c.",
			"showCambioSegMoeda": "Afficher la colonne du taux de change en secondes.",
			"showDebitoSegMoeda": "Afficher la colonne de débit en sec.",
			"showCreditoSegMoeda": "Afficher la colonne de crédit en sec.",
			"showPorPagar": "Afficher la colonne des impayés",
			"showDataVencimento": "Afficher la colonne de date d'échéance"
		},
		"filterFields": {
			"nConta": "Compte",
			"deData": "Date de"
		},
		"btn": {
			"processar": "Processus"
		},
		"msg": {
			"contaInvalida": "Champ de compte invalide.",
			"naoMostraDetalhe": "Il n'est pas possible de présenter les détails de ce document, car il s'agit d'un document comptable."
		},
		"caption": {
			"recibos": "Recettes"
		},
		"modal": {
			"title": "Relevé de compte {{type}}"
		},
		"export": {
			"account": "Compte"
		},
		"print": {
			"title": "Imprimer le relevé de compte",
			"obter": "Pour obtenir"
		}
	},
	"familias": {
		"title_detail": "Famille {{id}}",
		"title_new": "Nouvelle famille",
		"title_plural": "Familles",
		"pesquisa": "A chercher",
		"deleted": "Famille supprimée avec succès.",
		"saved": "Famille {{id}}, enregistrée avec succès.",
		"error": "Famille {{id}}, ne peut pas être enregistrée.",
		"fields": {
			"nFamilia": "Code.",
			"nFamiliaPlaceholder": "code de famille",
			"nome": "Nom",
			"nomePlaceholder": "Nom de famille",
			"codtipoartigo": "Code du type d'article",
			"grandesfamilias": "code gr.",
			"subfamilia": "Code sous-famille",
			"unimovimento": "ONU.",
			"contaVenda": "Numéro de compte de vente",
			"contaCompra": "Numéro de compte d'achat",
			"metodoCalcPrecVend": "Méthode de calcul.",
			"nomeIvaVenda": "TVA sur la vente",
			"nomeIvaCompra": "TVA à l'achat",
			"movimentaStock": "Déplacer le stock",
			"criterio": "Critère de valorisation",
			"naoPExistNeg": "Ne permet pas de stock négatif"
		},
		"metodos": {
			"margemprecovenda": "Marge sur prix de vente",
			"margemprecocompra": "Marge sur prix d'achat",
			"customedioponderado": "Coût moyen pondéré",
			"lifo": "Lifo",
			"fifo": "Fifo",
			"custopadrao": "Coût standard",
			"customedio": "Coût moyen",
			"loteespecifico": "Lot spécifique"
		},
		"btn": {
			"buttonOptions": "Possibilités",
			"verContas": "Affiche les comptes de vente et d'achat",
			"esconderContas": "Masquer les comptes"
		}
	},
	"faturacaoContratos": {
		"btn": {
			"search": "A chercher",
			"processar": "Processus",
			"gestaoAvencas": "Gestion des paiements facturés",
			"faturar": "Facture"
		},
		"fields": {
			"sel": "Sel.",
			"classeContrato": "Classe",
			"tipoContrato": "Taper",
			"nContrato": "Numéro de reçu",
			"versao": "Version",
			"descricao": "Description",
			"nClifo": "Numéro de compte",
			"nomeClifo": "Nom",
			"totalLiquido": "Total net",
			"nFaturasEmAberto": "Nombre de factures ouvertes",
			"dataUltimoPagamento": "Date du dernier paiement",
			"dataUltimaFatura": "Date de la dernière facture",
			"obsUltimaFatura": "Notes sur la dernière facture",
			"temDebitoDirectoStr": "Client prélèvement automatique",
			"temErro": "Avec erreur",
			"contratoAsString": "Engagement"
		},
		"caption": {
			"classeContrato": "Classe",
			"tipoClasseContrato": "Taper",
			"nZonaDe": "Code de zone",
			"nContaDe": "Numéro de compte",
			"dataDoc": "Date du document.",
			"dataVenc": "Date d'expiration",
			"dataInicTransp": "Date de début.",
			"horaInicTransp": "Heure de début.",
			"txtContrato": "Observations",
			"txtCtrNoCabecalho": "Placer en pied de page",
			"txtCtrNoCabecalhoTooltip": "Remplissez le champ commentaires dans l'en-tête du document",
			"txtCtrNaLinha": "Placer sur le corps",
			"txtCtrNaLinhaTooltip": "Après la dernière ligne du document",
			"origemTxtContrato": "Origine du texte",
			"localizacao": "Emplacement"
		},
		"config": {
			"verDataInicTransp": "Afficher la date de début.",
			"verHoraInicTransp": "Afficher l'heure de début.",
			"verZonaDeAte": "Afficher le filtre par zone",
			"verErrosContratos": "Afficher les erreurs d'accord",
			"verContasDeAte": "Afficher le filtre par compte",
			"verDataVenc": "Afficher la date d'expiration."
		},
		"table": {
			"semdados": "Aucune donnée"
		},
		"messages": {
			"success": "Covenants facturés avec succès.",
			"sucessContratosFaturados": "Contrat(s) {{nregistos}} facturé(s) avec succès.",
			"semClasseSelecionada": "Aucune classe d'abonnement sélectionnée.",
			"semTiposSelecionado": "Vous devez sélectionner au moins un type de contrat",
			"semDataDocDeSelecionado": "Vous devez renseigner la date du document initial",
			"semDataDocAteSelecionado": "Vous devez renseigner la date du document final",
			"semNContaDeSelecionado": "Doit compléter le compte initial",
			"semNContaAteSelecionado": "Doit compléter la facture finale",
			"infoFaturacaoAuto": "Pour utiliser la facturation automatique, configurez ici le type de paiement"
		},
		"errors": {
			"classeContratoNaoPodeSerNula": "La classe de contrat ne peut pas être nulle.",
			"naoExisteTipoContratoSelecionado": "Aucun type d'abonnement n'est sélectionné.",
			"naoExisteContratoSelecionadoParaFaturar": "Il n'y a aucun contrat sélectionné pour facturer.",
			"contratosAFaturarComErros": "Il existe {{nregistrations}} accord(s) de facturation par erreur."
		},
		"legend": {
			"contratoComErro": "Accord avec erreur"
		},
		"info": {
			"dadosProcessamento": "Informatique"
		},
		"data": {
			"tipoDeAvenca": "Type de contrat",
			"manual": "Manuel"
		},
		"text": {
			"dataParaProc": "Date de traitement"
		}
	},
	"gestaoContratos": {
		"title": "Gestion des paiements facturés",
		"filters": {
			"nClasseContrato": "Classe",
			"nTiposContrato": "Taper",
			"dataDocDe": "Date du document.",
			"nContaDe": "Numéro de compte",
			"estadoDoc": "État",
			"estadosDoc": {
				"encerrados": "Fermé",
				"naoEncerrados": "Non fermé"
			}
		}
	},
	"faturacaoContratosLog": {
		"title": "Traitement des alliances",
		"tabs": {
			"faturados": {
				"title": "Facturé",
				"fields": {
					"contratoAsString": "Engagement",
					"nClasseCtr": "Classe",
					"nTipoContrato": "Taper",
					"nContrato": "Numéro de reçu",
					"descricao": "Description",
					"nConta": "Client",
					"contaNome": "Nom du client",
					"dataDoc": "Date du document.",
					"nDocAsString": "Document",
					"email": "E-mail",
					"emailEnviado": "Envoyé",
					"docJaImprimido": "Document imprimé"
				},
				"btn": {
					"previsualizar": "Aperçu",
					"encerrarImprimir": "Fermer et imprimer/envoyer",
					"encerrarTudo": "Fermer les documents",
					"enviarFatEletronica": "Envoyer des factures",
					"editarDocumento": "Modifier le document",
					"visualizarDocumento": "Afficher le document"
				}
			},
			"erros": {
				"title": "Non facturé",
				"fields": {
					"contratoAsString": "Engagement",
					"nClasseCtr": "Classe",
					"nTipoContrato": "Taper",
					"nContrato": "Numéro de reçu",
					"descricao": "Description",
					"nConta": "Client",
					"contaNome": "Nom du client"
				},
				"warningMsg": {
					"naoFaturados": "Il y a des paiements qui n'ont pas été facturés",
					"consulteNaoFaturados": "Il y a des paiements qui n'ont pas été facturés, voir l'onglet non facturés"
				}
			}
		},
		"success": {
			"documentosEncerrados": "Les contrats facturés ont été clôturés avec succès.",
			"encerradosNumDocumentos": "{{value}} document(s) fermé(s) avec succès.",
			"emailsEnviados": "{{value}} e-mail(s) envoyé(s) avec succès."
		},
		"errors": {
			"documentoJaEncerrado": "Le document {{nDoc}} est maintenant fermé.",
			"emailsNaoEnviados": "Aucun email n'a été envoyé.",
			"naoConfigurados": "Aucun e-mail n'est configuré ou les documents ne sont pas fermés.",
			"erroAEncerrarDoc": "Erreur lors de la fermeture du document",
			"erroAEncerrarDocs": "Erreur lors de la fermeture des documents",
			"erroAEnviarEmail": "Erreur lors de l'envoi de l'e-mail",
			"naoExistemItensSelecionados": "Il n'y a aucun élément sélectionné",
			"documentosEnvioEmailNaoEncerrados": "Les documents de convention sélectionnés pour être envoyés par email ne sont pas fermés"
		},
		"info": {
			"emailsEnviados": "Les documents sélectionnés ont déjà été envoyés par email",
			"documentosEncerrados": "Les documents sélectionnés sont fermés.",
			"docsNaoEncerradosSelecionadosParaEnvioEmail": "Il existe des documents non fermés pour les accords sélectionnés et aucun email ne sera envoyé à ces clients"
		},
		"confirm": {
			"emailsEnviados": "Les documents ont déjà été envoyés par email, souhaitez-vous les renvoyer ?"
		},
		"okCancel": {
			"encerrardocs": {
				"title": "Fermer les documents",
				"message": "Êtes-vous sûr de vouloir fermer les documents sélectionnés ?"
			},
			"enviardocs": {
				"title": "Envoyer les factures du contrat par email",
				"message": "Êtes-vous sûr de vouloir envoyer les documents pour les accords sélectionnés ?",
				"message2": "Certains documents d'alliance sélectionnés ont déjà été envoyés."
			}
		}
	},
	"faturacaoeletronicaconfiguracoes": {
		"fields": {
			"nDocFa": "Tapez Doc.",
			"nome": "Nom",
			"estado": "État",
			"reportDefault": "Imprimé par défaut",
			"nConta": "Numéro de compte",
			"emails": "E-mails",
			"email": "E-mail",
			"emailgeral": "Courriel général"
		},
		"legend": {
			"gravado": "Enregistré",
			"porgravar": "Pour l'enregistrement"
		},
		"tabs": {
			"tiposdocumento": "Types de documents",
			"clientes": "Clients",
			"certificado": "Certificat"
		},
		"text": {
			"certificados": "Certificats",
			"certificadosCount": "Certificats ({{total}})",
			"pendentes": "Pendentifs",
			"pendentesCount": "En attente ({{total}})",
			"semFaturacaoEletronica": "Pas de facture électronique",
			"comFaturacaoEletronica": "Avec facture électronique",
			"obterAtributos": "Obtenir des attributs",
			"authenticate": "Authentifier",
			"register": "Registre",
			"tipoCertificadoNotSelected": "La plateforme de signature électronique de documents n'est pas définie, définissez-la dans l'une des cases à cocher disponibles ci-dessous.",
			"selectedTipoCertificado": "Facturation électronique active, la signature électronique des documents définie est: \"{{plateforme}}\".",
			"setTipoCerficadoAtivo": "Définir comme plateforme d'abonnement",
			"tipoCerficadoAtivo": "Défini comme une plateforme d'abonnement",
			"faturacaoEletronicaNaoAtiva": "Facturation électronique sans plateforme d'abonnement configurée",
			"infoDigitalSignMessage": "Pour utiliser le fournisseur de signes numériques, vous devez obtenir les données de l'autorisateur afin de pouvoir activer la signature.",
			"infoSAFEMessage": "Pour utiliser le fournisseur SAFE, vous devez avoir accès au portail Autentição.GOV et disposer d'attributs professionnels.",
			"faq": "Accédez à notre FAQ pour toute question.",
			"allTiposDocs": "Tous les types de documents SAF-T (FT, FR, ND, NC) sont sélectionnés pour la signature numérique de documents.",
			"allClientes": "Tous les clients sont sélectionnés pour signer des documents numériques.",
			"savedInfoIsAnonimous": "Remarque : ces informations seront stockées de manière anonyme",
			"checkAcessoSAFEMsg": "Le service de signature électronique de factures (SAFE) permet à l'administrateur, au gérant ou au directeur d'une entreprise de signer des factures électroniques dans un logiciel d'intégration intégré à SAFE.",
			"checkAcessoSAFE": "Avez-vous accès au portail Authentication.GOV et disposez-vous d'attributs professionnels ?",
			"apagar": "Pour éteindre",
			"dataValidade": "Date d'expiration"
		},
		"messages": {
			"clientAddedSucess": "Client ajouté !",
			"clientEditedSucess": "Client édité !",
			"emailsEmpty": "Vous devez attribuer au moins un e-mail.",
			"warningAddDigitalSign": "Il existe déjà des comptes d'abonnement définis.<br/>Êtes-vous sûr de vouloir créer un nouveau compte ?",
			"successAddSafeCertificate": "Certificat SAFE ajouté avec succès.",
			"successSAFEObterAtributos": "Attributs du certificat SAFE obtenus avec succès.",
			"successAddDigitalSignAuthorizer": "Certificat de signe numérique ajouté avec succès.",
			"successSetTipoCerficadoAtivo": "Plateforme de signature électronique définie avec succès.",
			"authenticateDigitalSignAuthorizer": "Certificat de signe numérique authentifié avec succès.",
			"safependentinfo": "Vous devez avoir attribué les attributs professionnels permettant la signature de factures électroniques.",
			"promptDeleteDigitalSignAuthData": "Êtes-vous sûr de vouloir supprimer vos données d'accès ?",
			"successDeleteDigitalSignAuthorizer": "Le certificat Digital Sign a été supprimé avec succès.",
			"successConfigSaved": "Configuration enregistrée avec succès."
		},
		"errors": {
			"emptyNewSafeCertificateUrl": "Impossible d'obtenir l'adresse pour ajouter un certificat SAFE.",
			"emptyNewDigitalSignUrl": "Impossible d'obtenir l'adresse pour ajouter un certificat Digital Sign.",
			"safeObterAtributos": "Une erreur inconnue s'est produite lors de la tentative d'obtention des attributs du certificat SAFE.",
			"invalidNomeConta": "Nom de compte invalide, ne peut contenir que des caractères et des chiffres et ne peut pas contenir d'espaces.",
			"noAuthFound": "Aucune authentification valide trouvée"
		},
		"safe": {
			"nomeDaConta": "Nom",
			"nomeUtilizador": "Propriétaire",
			"accountExpiration": "Validité",
			"autenticacaoGov": "Authentification ouverte.Gov",
			"state": "État",
			"errors": {
				"semItems": "N’a aucun titulaire d’attribut professionnel enregistré"
			}
		},
		"digitalsign": {
			"nomeAutorizador": "Nom de l'autorisateur",
			"idAutorizador": "ID de l'autorisateur",
			"nomeUtilizador": "Propriétaire",
			"secret": "Secrète",
			"accountExpiration": "Validité",
			"usoGlobal": "Utilisation mondiale",
			"dropdown": {
				"cliente": "Espace client",
				"certificado": "Certificat ouvert",
				"gestao": "Gestion des certificats"
			},
			"errors": {
				"nomeAutorizadorNulo": "Le nom de l'autorisateur ne peut pas être nul",
				"idAutorizadorNulo": "L'ID de l'autorisateur ne peut pas être nul",
				"secretNula": "Le secret ne peut pas être nul"
			}
		},
		"addsafecertificadomodal": {
			"title": "Ajouter un certificat SAFE",
			"titlePreProduction": "Ajouter un certificat SAFE (pré-production)",
			"fields": {
				"nomeConta": "Nom du compte",
				"email": "E-mail",
				"validade": "Valable jusqu'au"
			},
			"tipoCidadao": {
				"nacional": "Citoyen national",
				"estrangeiro": "Citoyen étranger"
			}
		},
		"digitalsignauthorizermodal": {
			"add": "Ajouter un certificat de signe numérique",
			"addPreProduction": "Ajouter un certificat de signe numérique (pré-production)",
			"update": "Modifier le certificat de signe numérique",
			"updatePreProduction": "Modifier le certificat de signe numérique (pré-production)"
		},
		"configemailsclientemodal": {
			"title": "Configurer l'e-mail du compte"
		},
		"postAddSafeCertificadoModal": {
			"message": "Veuillez patienter pendant que nous terminons le processus d'ajout d'un certificat SAFE...",
			"error": "Une erreur s'est produite lors de la fin du processus d'ajout d'un certificat SAFE."
		},
		"certificadoAvailable": {
			"safe": "SAFE (Service de signature électronique de factures)",
			"safeTestes": "Tests SAFE (Electronic Invoice Signing Service)",
			"digitalSign": "Digital Sign - Sceau électronique qualifié",
			"digitalSignTestes": "Panneau numérique - Tests de sceau électronique qualifiés",
			"safeShort": "SÛR",
			"safeFullname": "Service de signature électronique de facture",
			"digitalSignShort": "Signe numérique",
			"digitalSignFullname": "Sceau électronique qualifié"
		},
		"configOptions": {
			"ambientePreProducao": "Environnement de pré-production"
		},
		"faq": {
			"dropdowntitle": "FAQ - Aide",
			"faq1": "<h3>Qu'est-ce qu'une facture électronique et à quoi sert-elle ?</h3><br><p>Le traitement des factures papier est un processus coûteux pour les entreprises, avec des coûts pour les citoyens et les entreprises consommant des ressources pour l'économie.",
			"faq2": "<h3>Que dois-je faire si l'application est compromise (du point de vue de la sécurité) ?</h3><br><p>Une application est considérée comme compromise chaque fois que des jetons SAFE ont été exposés.</p> <p>Si l'application est compromis, vous devez informer AMA dans les moins de 4 heures (après avoir pris connaissance de l'incident de sécurité) et annuler tous les comptes dans le même délai.</p><p>Pour ce faire, sélectionnez simplement le compte qui a été compromis, et faites un clic droit pour sélectionner",
			"faq3": "<h3>Comment dois-je procéder pour créer un nouveau compte d'abonnement ?</h3><br><p>Pour créer un nouveau compte d'abonnement, procédez comme expliqué dans le \"Guide de création d'un nouveau compte\", sur le bouton en face côté inférieur</p>",
			"faq4": "<h3>Qu'est-ce que l'article 12 du décret-loi 28/2019 du 15 février ?</h3><br><p>L'article 12 fait référence à l'émission de factures par voie électronique.",
			"faq5": "<h3>Qu'est-ce que le décret-loi 12/2021 du 9 février ?</h3><br><p>Ce décret-loi assure la mise en œuvre dans l'ordre juridique interne du règlement (UE) 910/2014, relatif à l'identification électronique. et services de confiance pour les transactions électroniques sur le marché intérieur.</p>",
			"faq6": "<h3>Comment les tokens SAFE seront-ils utilisés pour réaliser des abonnements ?</h3><br><p>Pour répondre à la législation précitée, CentralGest doit stocker le token (clé privée) du compte d'abonnement créé pour pouvoir demander la signature d'un document de facturation.",
			"faq7": "<h3>Les jetons sont-ils stockés en toute sécurité ?</h3><br><p>Oui, les jetons sont cryptés à l'aide d'un algorithme sécurisé avant d'être stockés, conformément aux normes SAFE, ils ne sont donc pas déchiffrables lors d'une requête vers la base de données.< /p>",
			"faq8": "<h3>Quand CentralGest signe-t-il une facture ?</h3><br><p>Après un paramétrage correct, CentralGest signe une facture à chaque fois qu'elle est envoyée par email, ou à chaque fois qu'elle est exportée dans le module d'impression de documents.</p> <p>Chaque fois qu'un document doit être signé, l'utilisateur est informé à l'avance.</p>"
		},
		"guide": {
			"dropdowntitle": "Guide pour créer un nouveau compte",
			"title": "Flux de création de compte",
			"atributosproftitle": "Attributs professionnels",
			"atributosprof": "Avant de démarrer le processus de création de compte, vous devez avoir attribué les attributs professionnels qui vous permettent de signer des factures électroniques.<br>Pour cela, vous devez accéder au site Authentification.gov et dans l'espace privé, accéder à \"mes attributs professionnels\".",
			"novacontatitle": "Nouveau compte",
			"novaconta": "Vous devez cliquer sur « Nouveau » pour lancer le processus de création d'un nouveau compte.",
			"autenticacaotitle": "Authentification",
			"autenticacao": "Après l'étape précédente, vous serez dirigé vers une page d'authentification sur Authentication.gov.<br>Dans cette étape, vous devez sélectionner la méthode d'authentification.",
			"autorizacaotitle": "Autorisation",
			"autorizacao": "Vous devez autoriser la collecte de certaines données personnelles afin de poursuivre la démarche.",
			"autenticacaomovel": "Dans ce cas, en vous authentifiant avec la Digital Mobile Key, vous devez saisir les données nécessaires à l'authentification.",
			"autenticacaomovelcod": "Vous devez saisir le code de sécurité qui a été envoyé au téléphone portable.",
			"obteratributostitle": "Obtenir les attributs",
			"obteratributos": "Après une authentification correcte, le processus sera en attente, il faudra donc obtenir les attributs du certificat.",
			"certificadotitle": "Certificat",
			"certificado": "Une fois le processus terminé, nous pouvons vérifier que nous disposons du certificat actif."
		},
		"btn": {
			"ativar": "Activer",
			"desativar": "Désactiver"
		},
		"radioOptions": {
			"caption": "Signature des documents commerciaux pour",
			"docfas": {
				"todos": "Tous types de documents SAF-T (FT,FR,ND,NC)",
				"selecionados": "Uniquement les types de documents sélectionnés"
			},
			"clientes": {
				"todos": "Tous les clients",
				"selecionados": "Uniquement les clients sélectionnés"
			}
		}
	},
	"faturacaopublica": {
		"tab": {
			"porComunicarManual": "Documents.",
			"porComunicarWebServ": "Documents.",
			"comunicadoEmValidacao": "Documents.",
			"comunicadoComErro": "Documents.",
			"saphety": "Saphétie",
			"feap": "FEAP-eSAP",
			"comunicados": "Annonces"
		},
		"btn": {
			"config": "Paramètres",
			"comunicarWebServ": "Communiquer via un service Web",
			"comunicarManual": "Communiquer manuellement",
			"enviarEmail": "Envoyer un e-mail",
			"exportarAnexos": "Exporter les pièces jointes"
		},
		"menu": {
			"historico": "Histoire",
			"comunicamanual": "Marquer comme signalé manuellement",
			"comunicacaomanual": "Communication manuelle",
			"consutCliente": "Consulter le client",
			"consultDoc": "Consulter le document"
		},
		"table": {
			"sel": "Sel.",
			"ndocAsStringDraftSufixo": "Document",
			"datadoc": "Date du document.",
			"nClifo": "Code de compte",
			"nomeClifo": "Nom du compte",
			"facPublicaModeloStr": "Modèle de visage.",
			"estadoDescricao": "Observations",
			"semdados": "Aucune donnée"
		},
		"historico": {
			"title": "Histoire",
			"data": "Date/Heure",
			"documento": "Document",
			"estado": "État",
			"tipo": "Taper",
			"codigo": "Code",
			"descricao": "Description",
			"nConta": "Numéro de compte",
			"nomeConta": "Nom du compte",
			"nomeUtilizador": "Nom d'utilisateur"
		},
		"observacoes": {
			"title": "Observations",
			"observacoes": "Observations"
		},
		"doccomercial": {
			"title": "Document commercial."
		},
		"config": {
			"title": "Mise en place de la facturation électronique publique",
			"utilizador": "Utilisateur",
			"senha": "Mot de passe",
			"endereco": "URL",
			"estados": "États",
			"estadosConta": "Par défaut",
			"table": {
				"estado": "État",
				"conta": "Compte"
			}
		},
		"messages": {
			"refreshsuccess": "Mis à jour avec succès !",
			"observacoesvazia": "Vous devez saisir une note",
			"contavazia": "Vous devez créer un compte",
			"estadodesc": "État invalide"
		},
		"modal": {
			"comunicacaomanual": {
				"title": "Communication manuelle - Pièces jointes générées",
				"anexos": "Pièces jointes",
				"btn": {
					"sendmail": "Envoyer par email",
					"export": "Exporter"
				}
			}
		},
		"estado": {
			"porComunicar": "Pour communiquer",
			"comunicadoEmValidacao": "Annonce en validation",
			"comunicadoValidado": "Déclaration validée",
			"comunicadoComErro": "Déclaration d'erreur",
			"erroTratado": "Erreur traitée",
			"received": "Reçu",
			"rejected": "Rejeté",
			"paid": "Payé",
			"comunicadoEstruturaEmValidacao": "Structure d'annonce en cours de validation"
		},
		"errors": {
			"faturacaoeletronicanaoconfigurada": "Pour communiquer des fichiers via CIUS-PT, la facturation électronique doit être configurée",
			"errogerarficheiros": "Erreur lors de la génération des fichiers, confirmer le paramétrage de la facturation publique et de la facturation électronique"
		}
	},
	"fct": {
		"tabEmpresas": "Entreprises",
		"tabDocumentos": "Documents",
		"tableDefinition": {
			"nempresa": "numéro d'entreprise",
			"nome": "Nom de l'entreprise",
			"emitido": "Doc. publié",
			"valor": "Valeur totale",
			"valorMesAnterior": "Valeur du mois précédent",
			"mensagem": "Message",
			"dataEmissao": "Date d'émission",
			"documento": "Document",
			"dataPagamento": "Date de paiement",
			"tipoPagamento": "Type de paiement",
			"emitirTipoDocumento": "Problème",
			"pdf": "PDF",
			"valorFCTNoCG": "Valeur totale en CG",
			"email": "E-mail",
			"valorEmAtraso": "Montant en souffrance",
			"valorAPagar": "Montant à payer",
			"identificacao": "Identification",
			"contrato": "Contracter",
			"rendimentos": "Revenu",
			"totalRetidoFundos": "Total des fonds conservés",
			"fctAno": "Année FCT",
			"fctMes": "Mois FCT",
			"codemp": "code emp.",
			"nomeEmp": "Nom",
			"niss": "NISS",
			"iban": "IBAN",
			"modalidadeContrato": "Contracter",
			"dataEntrada": "Date d'entrée",
			"dataInicioContrato": "Date de début",
			"dataFimContrato": "Date de fin",
			"valorRetribuicaoMensal": "Paiement mensuel",
			"valorDiuturnidade": "Valeur diurne",
			"qtdFaltasInjustificadas": "Nombre d'absences injustifiées",
			"valorFaltasInjustificadas": "Valeur des absences",
			"totalFCT": "Total FCT",
			"totalFGCT": "FGCT total",
			"valorPago": "Montant payé",
			"dataCessacaoContrato": "Date de résiliation du contrat",
			"motivo": "Raison",
			"entregasPagas": "Livraisons payantes",
			"numeroUPs": "Numéro d'UP",
			"valorEstimadoReembolso": "Montant estimé du remboursement",
			"numTrabalhadores": "Nombre de travailleurs"
		},
		"toolbar": {
			"obterValores": "Obtenir la valeur FCT",
			"emitirDocumento": "Émettre un document de paiement",
			"downloadDoc": "Télécharger le document",
			"viewTodosTrabComFct": "Tous les travailleurs atteints de FK",
			"comSelecionados": "Fonctionnement avec sélectionné...",
			"pedirReembolso": "Demander un remboursement"
		},
		"messages": {
			"selectRecords": "Vous devez sélectionner au moins une entreprise.",
			"erroDownload": "Erreur lors du téléchargement du document.",
			"emitidoComSucesso": "Le document de paiement a été émis avec succès.",
			"configurarCredentials": "Configurer les informations d'identification FCT",
			"valorObtidoComSucesso": "Valeur obtenue avec succès depuis le portail FCT",
			"porPagar": "Non rémunéré",
			"emailEnviado": "E-mail envoyé avec succès !",
			"emailNaoEnviado": "L'e-mail n'a pas été envoyé.",
			"modalPromptMessage": "Êtes-vous sûr de vouloir émettre des documents de paiement au {{numEmpSelected}} sélectionné ?",
			"verificaEmail": "Vous devez remplir l'email",
			"enviarEmailTooltip": "Envoyer le document de paiement par email",
			"verifiqueEmails": "Vérifiez que les emails sont complets et corrects.",
			"httpErrorTenteNovamente": "Une erreur inattendue s'est produite.",
			"selecionaEmpresa": "Vous devez sélectionner l'entreprise.",
			"selecionarCriterio": "Pour effectuer la recherche vous devez sélectionner au moins un critère.",
			"selectAtLeastOneRecord": "Vous devez sélectionner au moins un enregistrement.",
			"operacaoConcluidaVerifiqueMsgs": "Opération terminée avec succès.",
			"naoFoiPossivelPedirReembolsoParaAlgunsTrabalhadores": "Il n'a pas été possible de faire une demande de remboursement pour certains travailleurs.",
			"dataFimIgualPosteriorDataInicio": "La date de fin de la recherche doit être égale ou postérieure à la date de début de la recherche",
			"dataCessInicialEmpty": "Vous devez renseigner la date de début de résiliation du contrat",
			"dataCessFinalEmpty": "Vous devez renseigner la date de fin de résiliation du contrat",
			"pedidoReembolsoEfetuadoComSucesso": "La demande de remboursement a été effectuée avec succès.",
			"visualizarPedidosEfetuados": "Afficher les commandes passées."
		},
		"strings": {
			"de": "De",
			"tipoSearchDoc": "Type de document.",
			"empresa": "Entreprise",
			"pesquisar": "A chercher",
			"searchTipoDocTodos": "Tous",
			"searchTipoDocADEF": "Document d'adhésion FCT",
			"searchTipoDocADEG": "Document d'adhésion au FGCT",
			"searchTipoDocDOCP": "Document de paiement",
			"detalhes": "Détails",
			"valorTotal": "Valeur totale",
			"valorMesAnterior": "Valeur du mois précédent",
			"loginFCT": "Connexion FCT",
			"niss": "NISS",
			"password": "Mot de passe",
			"sendEmail": "Envoyer un e-mail avec le document de paiement",
			"downloadDoc": "Téléchargez le document de paiement",
			"enviarEmail": "Envoyer un e-mail",
			"accessoFCT": "Accès FCT",
			"viewTodosTrabComFctModalTitle": "Tous les travailleurs bénéficiant d'une caisse d'indemnisation",
			"CDCI": "Licencié pour licenciement avec droit à indemnisation",
			"CDSI": "Licencié pour licenciement sans droit à indemnisation",
			"CRV": "Licencié par rupture volontaire",
			"efetuarPedido": "Passer la commande",
			"efetuarPedidos": "Passer des commandes",
			"pedidosReembolsos": "Demandes de remboursement",
			"pesquisarTrabalhadores": "Travailleurs de recherche",
			"dataCessInicial": "Date de résiliation du contrat",
			"a": "le",
			"estadoContrato": "Statut du contrat",
			"selecionarTodos": "Tout sélectionner",
			"deselecionarTodos": "Effacer la sélection",
			"pedidosReembolsosEfetuados": "{{nEmpresa}} – Demandes de remboursement effectuées"
		},
		"tooltips": {
			"obterValores": "Obtenez de la valeur grâce au portail des fonds de compensation",
			"emitirDocumento": "Émettre le titre de paiement",
			"enviarEmail": "Envoyer l'email avec le document de paiement",
			"downloadDoc": "Téléchargez le document de paiement",
			"viewTodosTrabComFct": "Afficher les données de tous les travailleurs disposant d'un fonds d'indemnisation",
			"comSelecionados": "Sélectionnez l'opération que vous souhaitez effectuer avec les enregistrements sélectionnés",
			"pedirReembolso": "Faire une demande de remboursement",
			"selecionarTodos": "Sélectionnez tous les travailleurs",
			"deselecionarTodos": "Effacer la sélection",
			"expandAllHaveEmployees": "Développez tous ceux qui ont des travailleurs",
			"collapseAll": "Tout réduire",
			"efetuarPedidosDestaEmpresa": "Faire une demande de remboursement pour les salariés sélectionnés de cette entreprise"
		}
	},
	"fileviewer": {
		"table": {
			"nome": "Nom",
			"tamanho": "Taille",
			"datamod": "Date de modification",
			"horamod": "Temps de modification.",
			"datacriacao": "Date de création",
			"horacriacao": "Temps de création",
			"utilizador": "Utilisateur",
			"docexterno": "Document externe.",
			"classificador": "Classificateur",
			"pasta": "Dossier"
		},
		"header": {
			"descBtn": "Voyez comment"
		},
		"tooltip": {
			"visualizar": "Voir",
			"download": "Télécharger",
			"onlyPDF": "Visionneuse pour le format PDF uniquement.",
			"delete": "Retirer",
			"upload": "Pour charger"
		}
	},
	"fimano": {
		"steps": {
			"welcome": "Accueillir",
			"data": "Données",
			"validations": "Validations",
			"execute": "Pour exécuter"
		},
		"text": {
			"welcome": {
				"warning0": "L'opération de fin d'année doit être réalisée par un administrateur!",
				"warning1": "Assurez-vous d'avoir fait des copies de sauvegarde de votre entreprise actuelle.",
				"warning2": "Cliquez sur « Suivant » pour poursuivre l'opération de fin d'année.",
				"errorNext": "Il n'est pas possible de procéder à la fin de l'année en raison de la ou des erreurs suivantes"
			},
			"data": {
				"company": "Entreprise",
				"accountingPeriod": "Période comptable",
				"ircYear": "Année IRC",
				"yearToClose": "Fin d'année",
				"nDiario": "Journal (Soldes d'ouverture)",
				"nDescritivo": "Description (Soldes d'ouverture)",
				"scheduleFor": "Calendrier pour",
				"saldaContasClasse0": "Payer les comptes de classe 0",
				"saldaContasClasse9": "Payer les comptes de classe 9",
				"fazTratamentoME": "Gère les soldes en devises étrangères"
			},
			"validations": {
				"beforeExecute0": "Avant d'exécuter la clôture de l'année, il est nécessaire d'effectuer quelques validations.",
				"beforeExecute1": "Remarque : Ce processus peut prendre un certain temps.",
				"btnValidate": "Valider",
				"executing": "Exécution des validations...",
				"noErrors": "Il n'y a aucune erreur."
			},
			"execute": {
				"text0": "Tout est prêt pour entamer le processus de clôture de l’année.",
				"text1": "Tenez compte du fait qu'après la planification, l'entreprise actuelle sera <strong>bloquée</strong> et <strong>inaccessible</strong> jusqu'à ce que le processus soit terminé.",
				"doExecute": "Cliquez sur \"Terminer\" pour clôturer l'année."
			}
		}
	},
	"fimperiodoenc": {
		"title_detail": "Période {{id}}",
		"title_new": "Fin de période",
		"title_plural": "Périodes",
		"pesquisa": "A chercher",
		"saved": "Période {{id}}, enregistrée avec succès.",
		"error": "La période {{id}} ne peut pas être enregistrée.",
		"fields": {
			"periodo": "Période",
			"periodos": "Périodes",
			"nomePeriodo": "Nom",
			"observacoes": "Observations",
			"flagRigorAbsoluto": "Fermé"
		},
		"placeholders": {
			"periodo": "Code période",
			"observacoes": "Observations"
		},
		"messages": {
			"successfullychanged": "La période a été modifiée avec succès",
			"docsnaosaldados": "Il y a des documents non réglés!"
		},
		"btn": {
			"applyperio": "Changer de période d'entreprise",
			"wontchangeperio": "Ne pas changer de période d'entreprise"
		},
		"titleListaEncerrados": "Liste des agendas fermés",
		"titleDiariosDisponiveis": "Disponible",
		"titleDiariosSelecionados": "Choisi",
		"changePeriodo": "Changer la période comptable de l'entreprise",
		"fazerVerificacaoDocsNSaldTitle": "Vérifier les documents non réglés",
		"fazerVerificacaoDocsNSald": "Appuyez sur OK si vous souhaitez vérifier les documents non réglés ou sur Annuler si vous souhaitez continuer sans vérifier.",
		"temDocsNSald": "Il y a des documents impayés dans la période."
	},
	"fluxoMonetario": {
		"pesquisa": "L'argent circule",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"undefined": "Non défini",
			"doesNotHave": "Il n'a pas",
			"paymentInTheAct": "Paiement sur place",
			"credit": "Crédit"
		}
	},
	"formaIncrementoNDoc": {
		"pesquisa": "Formulaires d'incrémentation de numéro de document",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"numberingRestartsEachPeriod": "La numérotation recommence à chaque période",
			"sequentialNumbering": "Numérotation séquentielle (quelle que soit la période)"
		}
	},
	"fregVig": {
		"data": {
			"AGUEDA": "AGUEDA",
			"ALBERGARIA_A_VELHA": "ALBERGARIA-A-VELHA",
			"ANADIA": "ANADIA",
			"AROUCA": "AROUCA",
			"AVEIRO_1": "AVEIRO-1.",
			"AVEIRO_2": "AVEIRO-2.",
			"CASTELO_DE_PAIVA": "CHÂTEAU DE PAÏVA",
			"ESPINHO": "ÉPINE",
			"ESTARREJA": "ESTARREJA",
			"FEIRA_2": "FOIRE-2",
			"FEIRA_1": "FOIRE-1",
			"FEIRA_4": "FOIRE-4",
			"FEIRA_3": "JUSTE-3",
			"ILHAVO": "ILHAVO",
			"MEALHADA": "MEALHADA",
			"MURTOSA": "MURTOSA",
			"OLIVEIRA_AZEMEIS": "OLIVEIRA AZÉMEIS",
			"OLIVEIRA_DO_BAIRRO": "OLIVEIRA DO BAIRRO",
			"OVAR": "OVAR",
			"S_JOAO_DA_MADEIRA": "S.JOAO DA MADEIRA",
			"SEVER_DO_VOUGA": "SEVER DO VOUGA",
			"VAGOS": "POSTES VACANTS",
			"VALE_DE_CAMBRA": "VALLÉE DE CAMBRA",
			"ALJUSTREL": "ALJUSTREL",
			"ALMODOVAR": "ALMODOVAR",
			"ALVITO": "ALVITO",
			"BARRANCOS": "BARRANCOS",
			"BEJA": "BEJA",
			"CASTRO_VERDE": "CASTRO VERT",
			"CUBA": "CUBA",
			"FERREIRA_DO_ALENTEJO": "FERREIRA DO ALENTEJO",
			"MERTOLA": "MERTOLA",
			"MOURA": "MOURA",
			"ODEMIRA": "ODEMIRA",
			"OURIQUE": "OURIQUE",
			"SERPA": "SERPA",
			"VIDIGUEIRA": "VIDIGUEIRA",
			"AMARES": "AMARES",
			"BARCELOS": "BARCELOS",
			"BRAGA_1": "BRAGA-1.",
			"BRAGA_2": "BRAGA-2.",
			"CABECEIRAS_DE_BASTO": "TÊTES DE LIT BASTO",
			"CELORICO_DE_BASTO": "CELORICO DE BASTO",
			"ESPOSENDE": "ESPOSENDE",
			"FAFE": "FAFÉ",
			"GUIMARAES_2": "GUIMARAES-2.",
			"GUIMARAES_1": "GUIMARAES-1.",
			"POVOA_DE_LANHOSO": "LES GENS DE LANHOSO",
			"TERRAS_DE_BOURO": "TERRES DE BOURO",
			"VIEIRA_DO_MINHO": "VIEIRA DO MINHO",
			"VILA_N_FAMALICAO_1": "VILA N.FAMALICAO-1.",
			"VILA_N_FAMALICA0_2": "VILA N. FAMALICAO 2.",
			"VILA_VERDE": "VILLE VERTE",
			"VIZELA": "VIZÉLA",
			"ALFANDEGA_DA_FE": "DOUANES FE",
			"BRAGANCA": "BRAGANCE",
			"CARRAZEDA_DE_ANSIAES": "CARRAZEDA DE ANSIAES",
			"FR_DE_ESPADA_A_CINTA": "FR DE L'ÉPÉE À LA CEINTURE",
			"MACEDO_DE_CAVALEIROS": "MACÉDO DE CAVALEIROS",
			"MIRANDA_DO_DOURO": "MIRANDA DU DOURO",
			"MIRANDELA": "MIRANDELA",
			"MOGADOURO": "MOGADOURO",
			"TORRE_DE_MONCORVO": "TOUR MONCORVO",
			"VILA_FLOR": "VILA FLEUR",
			"VIMIOSO": "VIMIEUX",
			"VINHAIS": "VIGNOBLES",
			"BELMONTE": "BELMONTE",
			"CASTELO_BRANCO_2": "CHÂTEAU BLANC-2.",
			"CASTELO_BRANCO_1": "CHÂTEAU BLANC-1.",
			"COVILHA": "COVILHA",
			"FUNDAO": "FONDAÏO",
			"IDANHA_A_NOVA": "IDANHA-A-NOVA",
			"OLEIROS": "POTIERS",
			"PENAMACOR": "PÉNAMACOR",
			"PROENCA_A_NOVA": "PROENCA-A-NOVA",
			"SERTA": "SERTA",
			"VILA_DE_REI": "VILA DE REI",
			"VILA_VELHA_DE_RODAO": "VILA VELHA DE RODAO",
			"ARGANIL": "ARGANILE",
			"CANTANHEDE": "CANTANHEDE",
			"COIMBRA_1": "COIMBRA-1.",
			"COIMBRA_2": "COIMBRA-2.",
			"CONDEIXA_A_NOVA": "CONDEIXA-A-NOVA",
			"FIGUEIRA_DA_FOZ_1": "FIGUEIRA DA FOZ-1.",
			"FIGUEIRA_DA_FOZ_2": "FIGUEIRA DA FOZ2.",
			"GOIS": "GOIS",
			"LOUSA": "TABLEAU NOIR",
			"MIRA": "BUT",
			"MIRANDA_DO_CORVO": "MIRANDA DO CORVO",
			"MONTEMOR_O_VELHO": "MONTEMOR-O-VELHO",
			"OLIVEIRA_DO_HOSPITAL": "HÔPITAL OLIVEIRA DO",
			"PAMPILHOSA_DA_SERRA": "PAMPILHOSA DA SERRA",
			"PENACOVA": "UN PIED DANS LA TOMBE",
			"PENELA": "PÉNÉLA",
			"SOURE": "AIGRE",
			"TABUA": "CONSEIL",
			"VILA_NOVA_DE_POIARES": "VILA NOVA DE POIARES",
			"ALANDROAL": "ALANDROAL",
			"ARRAIOLOS": "ARRAIOLOS",
			"BORBA": "BORBA",
			"ESTREMOZ": "ESTREMOZ",
			"EVORA": "ÉVORA",
			"MONTEMOR_O_NOVO": "MONTEMOR-O-NOVO",
			"MORA": "VIES",
			"MOURAO": "MOURAO",
			"PORTEL": "PORTEL",
			"REDONDO": "ROND",
			"REGUENGOS_DE_MONSARAZ": "REGUENGOS DE MONSARAZ",
			"VENDAS_NOVAS": "NOUVELLES VENTES",
			"VIANA_DO_ALENTEJO": "VIANA DO ALENTEJO",
			"VILA_VICOSA": "VILA VICOSA",
			"ALBUFEIRA": "ALBUFEIRA",
			"ALCOUTIM": "ALCOUTIM",
			"ALJEZUR": "ALJEZUR",
			"CASTRO_MARIM": "CASTRO MARIM",
			"FARO": "FARO",
			"LAGOA_ALGARVE": "LAGOA (ALGARVE)",
			"LAGOS": "LAGOS",
			"LOULE_1": "LOULE-1",
			"LOULE_2": "LOULE-2",
			"MONCHIQUE": "MONCHIQUE",
			"OLHAO": "OLHÃO",
			"PORTIMAO": "PORTIMAO",
			"S_BRAS_DE_ALPORTEL": "S.BRAS DE ALPORTEL",
			"SILVES": "SILVES",
			"TAVIRA": "TAVIRA",
			"VILA_DO_BISPO": "VILA DO BISPO",
			"VILA_REAL_S_ANTONIO": "VILA REAL S.ANTONIO",
			"AGUIAR_DA_BEIRA": "AGUIAR DA BEIRA",
			"ALMEIDA": "ALMEIDA",
			"CELORICO_DA_BEIRA": "CELORICO DA BEIRA",
			"FIG_CASTELO_RODRIGO": "FIGUE.",
			"FORNOS_DE_ALGODRES": "FOURNAISES À COTON",
			"GOUVEIA": "GOUVÉIA",
			"GUARDA": "GARDE",
			"MANTEIGAS": "BEURRES",
			"MEDA": "MOI DE",
			"PINHEL": "PINHEL",
			"SABUGAL": "SABUGAL",
			"SEIA": "SEIA",
			"TRANCOSO": "TRANCOSO",
			"VILA_NOVA_DE_FOZ_COA": "VILA NOVA DE FOZ COA",
			"ALCOBACA": "ALCOBAÇA",
			"ALVAIAZERE": "ALVAÏAZÈRE",
			"ANSIAO": "ANXIÉTÉ",
			"BATALHA": "BATAILLE",
			"BOMBARRAL": "BOMBARDIER",
			"CALDAS_DA_RAINHA": "SIROP REINE",
			"CASTANHEIRA_DE_PERA": "POIRIER CHÂTAIGNIER",
			"FIGUEIRO_DOS_VINHOS": "FIGUEIRO DOS VINOS",
			"LEIRIA_2": "LEIRIA-2.",
			"LEIRIA_1": "LEIRIA-1.",
			"MARINHA_GRANDE": "GRANDE MARINE",
			"NAZARE": "NAZARE",
			"OBIDOS": "OBIDOS",
			"PEDROGAO_GRANDE": "PEDROGAO GRANDE",
			"PENICHE": "PÉNICHE",
			"POMBAL": "POMBAL",
			"PORTO_DE_MOS": "PORT DE MOS",
			"ALENQUER": "ALENQUER",
			"ARRUDA_DOS_VINHOS": "ARRUDA DOS VINOS",
			"AZAMBUJA": "AZAMBUJA",
			"CADAVAL": "CADAVALE",
			"CASCAIS_1": "CASCAIS-1.",
			"CASCAIS_2": "CASCAIS-2.",
			"LISBOA_7": "LISBONNE-7",
			"LISBOA_9": "LISBONNE-9",
			"LISBOA_5": "LISBONNE-5",
			"LISBOA_10": "LISBONNE-10",
			"LISBOA_11": "LISBONNE-11",
			"LISBOA_6": "LISBONNE-6",
			"LISBOA_8": "LISBONNE-8",
			"LISBOA_1": "LISBONNE-1",
			"LISBOA_4": "LISBONNE-4",
			"LISBOA_2": "LISBONNE-2",
			"LISBOA_3": "LISBONNE-3",
			"LOURES_1": "LOURES-1",
			"LOURES_3": "LOURES-3",
			"LOURES_4": "LOURES-4.",
			"LOURINHA": "LOURINHA",
			"MAFRA": "MAFRA",
			"OEIRAS_2": "OEIRAS-2.",
			"OEIRAS_3_ALGES": "OEIRAS-3.ALGES",
			"OEIRAS_1": "OEIRAS-1.",
			"SINTRA_2_ALGUEIRAO": "SINTRA-2.",
			"SINTRA_1": "SINTRA-1",
			"SINTRA_4_QUELUZ": "SINTRA-4.",
			"SINTRA_3_CACEM": "SINTRA-3.",
			"SOBRAL_MONTE_AGRACO": "SOBRAL MONTE AGRACO",
			"TORRES_VEDRAS": "TORRES VÉDRAS",
			"VILA_FRANCA_XIRA_2": "VILA FRANCA XIRA-2.",
			"VILA_FRANCA_XIRA_1": "VILA FRANÇA XIRA-1",
			"AMADORA_3": "AMATEUR-3.",
			"AMADORA_2": "AMATEUR-2.",
			"AMADORA_1": "AMATEUR-1.",
			"ODIVELAS": "ODIVELAS",
			"ALTER_DO_CHAO": "CHANGER LE SOL",
			"ARRONCHES": "ARRONCHES",
			"AVIS": "AVIS",
			"CAMPO_MAIOR": "PLUS GRAND CHAMP",
			"CASTELO_DE_VIDE": "VIDÉO CHÂTEAU",
			"CRATO": "CRATO",
			"ELVAS": "ELVAS",
			"FRONTEIRA": "FRONTIÈRE",
			"GAVIAO": "FAUCON",
			"MARVAO": "MARVAO",
			"MONFORTE": "MONFORTÉ",
			"NISA": "CSRN",
			"PONTE_DE_SOR": "PONTE DE SOR",
			"PORTALEGRE": "PORTALEGRE",
			"SOUSEL": "SOUSEL",
			"AMARANTE": "AMARRANTE",
			"BAIAO": "BAIAO",
			"FELGUEIRAS": "FELGUEIRAS",
			"GONDOMAR_2": "GONDOMAR-2",
			"GONDOMAR_1": "GONDOMAR-1",
			"LOUSADA": "LOUSADA",
			"MAIA": "MAÏA",
			"MARCO_DE_CANAVESES": "MARCO DE CANAVESES",
			"MATOSINHOS_2": "MATOSINHOS-2.",
			"MATOSINHOS_1": "MATOSINHOS-1",
			"PACOS_DE_FERREIRA": "PACOS DE FERREIRA",
			"PAREDES": "DES MURS",
			"PENAFIEL": "PÉNAFIEL",
			"PORTO_1": "PORTO-1",
			"PORTO_3": "PORTO-3",
			"PORTO_4": "PORTO-4",
			"PORTO_2": "PORTO-2",
			"PORTO_5": "PORTO-5",
			"POVOA_DE_VARZIM": "POVOA DE VARZIM",
			"SANTO_TIRSO": "SAINT TIRSO",
			"VALONGO_2_ERMESINDE": "VALONGO-2.",
			"VALONGO_1": "VALONGO-1.",
			"VILA_DO_CONDE": "VILA DO CONDE",
			"VILA_NOVA_DE_GAIA_3": "VILA NOVA DE GAIA-3",
			"VILA_NOVA_DE_GAIA_1": "VILA NOVA DE GAIA-1.",
			"VILA_NOVA_DE_GAIA_2": "VILA NOVA DE GAIA-2.",
			"TROFA": "TROPHÉE",
			"ABRANTES": "COMPLET",
			"ALCANENA": "ALCANÈNE",
			"ALMEIRIM": "ALMEIRIM",
			"ALPIARCA": "ALPIARCA",
			"BENAVENTE": "BÉNAVENTE",
			"CARTAXO": "CARTAXO",
			"CHAMUSCA": "ROUSSIR",
			"CONSTANCIA": "CONSTANCE",
			"CORUCHE": "CORUCHE",
			"ENTRONCAMENTO": "ENTRÉE",
			"FERREIRA_DO_ZEZERE": "FERREIRA DO ZEZERE",
			"GOLEGA": "GOLÉGA",
			"MACAO": "MACAO",
			"RIO_MAIOR": "RIO MAIEUR",
			"SALVATERRA_DE_MAGOS": "SAVETERRA DE MAGIE",
			"SANTAREM": "SANTAREM",
			"SARDOAL": "SARDOAL",
			"TOMAR": "PRENDRE",
			"TORRES_NOVAS": "NOUVELLES TOURS",
			"VILA_N_DA_BARQUINHA": "VILA N.DA BARQUINHA",
			"OUREM": "OURÉ",
			"ALCACER_DO_SAL": "ALCACER DO SAL",
			"ALCOCHETE": "ALCOCHÈTE",
			"ALMADA_1": "ALMADA-1.",
			"ALMADA_3_C_CAPARICA": "ALMADA-3.",
			"ALMADA_2_C_PIEDADE": "ALMADA-2.",
			"BARREIRO": "BARREIRO",
			"GRANDOLA": "GRANDOLE",
			"MOITA": "MOÏTA",
			"MONTIJO": "MONTIJO",
			"PALMELA": "PALMÉLA",
			"SANTIAGO_DO_CACEM": "SANTIAGO DU CACEM",
			"SEIXAL_2": "SEIXAL-2.",
			"SEIXAL_1": "SEIXAL1.",
			"SESIMBRA": "SESIMBRA",
			"SETUBAL_1": "SETUBAL-1.",
			"SETUBAL_2": "SETUBAL 2.",
			"SINES": "SINES",
			"ARCOS_DE_VALDEVEZ": "ARCOS DE VALDÉVEZ",
			"CAMINHA": "MARCHER",
			"MELGACO": "MÉLGACO",
			"MONCAO": "MOUSSON",
			"PAREDES_DE_COURA": "MURS EN CUIR",
			"PONTE_DA_BARCA": "PONT BARÇA",
			"PONTE_DE_LIMA": "PONTE DE LIMA",
			"VALENCA": "VALENCE",
			"VIANA_DO_CASTELO": "VIANA DO CASTELÓ",
			"VILA_NOVA_CERVEIRA": "VILA NOVA CERVEIRA",
			"ALIJO": "ALIJO",
			"BOTICAS": "BOTIQUES",
			"CHAVES": "CLÉS",
			"MESAO_FRIO": "MESAO FROID",
			"MONDIM_DE_BASTO": "MONDIM DE BASTO",
			"MONTALEGRE": "MONTALEGRE",
			"MURCA": "MURCA",
			"PESO_DA_REGUA": "POIDS DE LA RÈGLE",
			"RIBEIRA_DE_PENA": "RIBEIRA DE PÉNA",
			"SABROSA": "SAVOUREUX",
			"SANTA_MARTA_PENAGUIAO": "SANTA MARTA PENAGUIAO",
			"VALPACOS": "VALPACOS",
			"VILA_POUCA_DE_AGUIAR": "VILA POUCA DE AGUIAR",
			"VILA_REAL": "VRAI VILLAGE",
			"ARMAMAR": "BRAS",
			"CARREGAL_DO_SAL": "SEL CARREGAL",
			"CASTRO_DAIRE": "CASTRO DAIRE",
			"CINFAES": "CINFAES",
			"LAMEGO": "LAMÉGO",
			"MANGUALDE": "MANGUALDE",
			"MOIMENTA_DA_BEIRA": "MOIMENTA DA BEIRA",
			"MORTAGUA": "MORTAGUA",
			"NELAS": "EN EUX",
			"OLIVEIRA_DE_FRADES": "OLIVEIRA DE FRADES",
			"PENEDONO": "PÉNÉDONO",
			"RESENDE": "RENVOYER",
			"SANTA_COMBA_DAO": "SANTA COMBA DAO",
			"S_JOAO_DA_PESQUEIRA": "S.JOAO DA PESQUEIRA",
			"S_PEDRO_DO_SUL": "S. PEDRO DO SUL",
			"SATAO": "SATAO",
			"SERNANCELHE": "SERNANCELHE",
			"TABUACO": "TABUACO",
			"TAROUCA": "TAROUCA",
			"TONDELA": "TONDELA",
			"VILA_NOVA_DE_PAIVA": "VILA NOVA DE PAIVA",
			"VISEU": "VISEU",
			"VOUZELA": "VOUZELA",
			"ANGRA_DO_HEROISMO": "ANGRA DO HÉROÏSME",
			"CALHETA_S_JORGE": "CALHETA (S.JORGE)",
			"S_CRUZ_DA_GRACIOSA": "S.CRUZ DA GRACIOSA",
			"VELAS": "BOUGIES",
			"PRAIA_DA_VITORIA": "PLAGE VICTORIA",
			"CORVO": "CORBEAU",
			"HORTA": "POTAGER",
			"LAJES_DAS_FLORES": "DALLES DE FLEURS",
			"LAGES_DO_PICO": "LAGES DO PICO",
			"MADALENA": "MADALÈNE",
			"S_CRUZ_DAS_FLORES": "S.CRUZ DAS FLORES",
			"S_ROQUE_DO_PICO": "S.ROQUE DO PICO",
			"LAGOA_S_MIGUEL": "LAGOA (S.MIGUEL)",
			"NORDESTE": "NORD EST",
			"PONTA_DELGADA": "POINTE FINE",
			"POVOACAO": "VILLAGE",
			"RIBEIRA_GRANDE": "RIBEIRA GRANDE",
			"VILA_FRANCA_DO_CAMPO": "VILA FRANCA DO CAMPO",
			"VILA_DO_PORTO": "VILA DE PORTO",
			"CALHETA_MADEIRA": "CALHETA - BOIS",
			"CAMARA_DE_LOBOS": "CHAMBRE DES LOUPS",
			"FUNCHAL_2": "FUNCHAL-2.",
			"FUNCHAL_1": "FUNCHAL-1.",
			"MACHICO": "MACHICO",
			"PONTA_DO_SOL": "PONTA DO SOL",
			"PORTO_MONIZ": "PORTO MONIZ",
			"RIBEIRA_BRAVA": "RIBEIRA BRAVA",
			"SANTA_CRUZ_MADEIRA": "SANTA CRUZ (MADÈRE)",
			"SANTANA": "SANTANA",
			"S_VICENTE_MADEIRA": "S.VICENTE (MADÈRE)"
		}
	},
	"freguesias": {
		"title_detail": "Paroisse {{id}}",
		"title_new": "Nouvelle paroisse",
		"title_plural": "Paroisses",
		"pesquisa": "A chercher",
		"saved": "Paroisse {{id}}, enregistrée avec succès",
		"error": "Paroisse {{id}}, ne peut pas être enregistrée.",
		"deleted": "Paroisse {{id}}, supprimée avec succès",
		"fields": {
			"nFreguesia": "Code paroissial",
			"designacaoBreve": "Brève désignation",
			"designacaoCompleta": "Désignation complète"
		}
	},
	"fremun": {
		"title_detail": "Forme de rémunération {{id}}",
		"title_new": "Nouvelle forme de rémunération",
		"title_plural": "Formes de rémunération",
		"pesquisa": "A chercher",
		"saved": "Formulaire de rémunération {{id}}, enregistré avec succès",
		"error": "Le formulaire de rémunération {{id}} ne peut pas être enregistré.",
		"deleted": "Formulaire de rémunération {{id}}, supprimé avec succès",
		"fields": {
			"nFRemun": "Code du formulaire de rémunération",
			"designacaoBreve": "Brève désignation",
			"designacaoCompleta": "Désignation complète"
		}
	},
	"funcoes": {
		"title_detail": "Fonction {{id}}",
		"title_new": "Nouvelle fonction",
		"title_plural": "Fonctions",
		"pesquisa": "A chercher",
		"saved": "Fonction {{id}}, enregistrée avec succès",
		"error": "La fonction {{id}} ne peut pas être enregistrée.",
		"deleted": "Fonction {{id}}, supprimée avec succès",
		"fields": {
			"nFuncao": "Code de fonction",
			"designaBreve": "Brève désignation",
			"designaComp": "Désignation complète"
		}
	},
	"gdoc": {
		"fields": {
			"cab": {
				"folderId": "Identification du dossier",
				"name": "Nom"
			}
		},
		"messages": {
			"maxFicheirosAtingido": "Le nombre maximum de fichiers autorisés a été atteint !"
		}
	},
	"generateAbdes": {
		"title": "Générer automatiquement de nouveaux {{abonoDesconto}}",
		"gerar": "Pour générer",
		"perfilNotSelected": "Vous devez sélectionner un {{profilAbDesc}} !",
		"abono": "Allocation",
		"desconto": "Rabais",
		"abonoDesconto": "{{abonoDesconto}}",
		"tipoConfig": "Type de configuration",
		"selecionarAbonoDescontoPretendido": "Sélectionnez {{abonoDesconto}} souhaité",
		"criarNovoAbonoDesconto": "Créez un nouveau {{abonoDesconto}} à partir de :",
		"codigoAbonoDescontoNotSelected": "Vous devez sélectionner le {{abonoDesconto}} !",
		"formPerfilInvalid": "La validation du champ a échoué.",
		"fields": {
			"categoria": "Catégorie",
			"grupo": "Groupe",
			"nome": "Nom",
			"descricao": "Description",
			"tipoArredondamento": "Arrondi",
			"tipoRecolha": "Type de collecte",
			"contribuiCalculoCustoPrecoH": "Contribue au calcul du prix/coût horaire",
			"codRem": "Voir code de rémunération.",
			"codUnidade": "Unité défectueuse",
			"tipoRendimento": "Type de revenu",
			"contaMovDeb": "Numéro de compte de débit",
			"contaMovCred": "Numéro de compte de crédit",
			"contaEncDEB": "Numéro de compte de débit",
			"contaEncCRE": "Numéro de compte de crédit",
			"ausencia": "Absence",
			"percAumentHExtra": "% Augmentation des heures supplémentaires",
			"nHorasDiaProvDesc": "Nombre d'heures par jour pour perte de subvention pour les repas",
			"perfilAbDesc": "Profil de {{abonoDesconto}}",
			"semPerfil": "Aucun profil",
			"abonoDescontoPreConfigurado": "{{abonoDesconto}} préconfiguré",
			"abonoDescontoJaExistente": "{{abonoDesconto}} déjà existant",
			"tipo": "Taper",
			"perfil": "Profil"
		},
		"crabdTable": {
			"fields": {
				"categoria": "Catégorie",
				"empregado": "Ent.",
				"patronato": "Ent.",
				"desconto": "Rabais",
				"nomeCategoria": "Catégorie",
				"temIncidenciaEmpregado": "Il a",
				"incidenciaEmpregado": "Incidence",
				"temIncidenciaPatronato": "Il a",
				"incidenciaPatronato": "Incidence",
				"nDesconto": "Code.",
				"nomeDesconto": "Description"
			}
		},
		"stepCategoria": "Catégorie",
		"stepPerfil": "Profil",
		"editarcruzamentos": {
			"title": "Crossovers Modifier",
			"subtitle": "Remise {{category}}",
			"desconto": "Rabais",
			"temcruzent": "A un croisement avec l'entité",
			"empregado": "Employé",
			"incidencia": "(%) Incidence",
			"patronato": "Patronage",
			"temseleentidade": "Vous devez sélectionner au moins une entité pour effectuer la traversée!",
			"teminserirdesc": "Il faut introduire une remise !"
		},
		"abdesemp": "Allocations et réductions aux employés",
		"encpatronempresa": "Frais employeur/entreprise"
	},
	"objscandoctextinterpreter": {
		"invoiceType": {
			"none": "Inconnu",
			"fatura": "Facture",
			"faturaSimplificada": "Facture simplifiée",
			"faturaRecibo": "Reçu de facture",
			"notaDebito": "note de crédit",
			"notaCredito": "Note de débit",
			"vendaDinheiro": "Vendre au comptant",
			"talaoVenda": "Bon de vente",
			"talaoDevolucao": "Bon de retour",
			"alienacaoAtivos": "Cession d'actifs",
			"devolucaoAtivos": "Restitution des actifs",
			"premio": "Prix ​​ou reçu du prix",
			"estorno": "Reçu de rétrofacturation ou de remboursement",
			"imputacaoCoSeguradoras": "Imputation aux coassureurs",
			"imputacaoCoSeguradoraLider": "Imputation au coassureur chef de file",
			"resseguroAceite": "Réassurance acceptée",
			"recibo": "Reçu",
			"notaLancamento": "Note de version",
			"extratoBancario": "Relevé bancaire",
			"pagamentoAoEstado": "Paiement à l'État",
			"levantamento": "Enquête",
			"pagamento": "Paiement",
			"deposito": "Dépôt",
			"cheque": "Vérifier",
			"transferencia": "Transfert"
		}
	},
	"gestaoComunicacoes": {
		"title": "Comptable en gestion des communications",
		"titleCliente": "Gestion de la communication client",
		"messageSidebarSelector": {
			"empresas": "Entreprises",
			"clienteMessage": "Messages",
			"comunicacoes": "Communications",
			"newMessages": "Nouveaux messages",
			"noComunicacoes": "Aucune communication disponible",
			"ontem": "Hier"
		},
		"modal": {
			"config": {
				"selectEmpresa": {
					"title": "Configuration de l'entreprise",
					"leftCaption": "Entreprises disponibles",
					"rightCaption": "Entreprises exclues"
				},
				"selectUtilizador": {
					"title": "Configuration utilisateur",
					"leftCaption": "Utilisateurs possibles",
					"rightCaption": "Utilisateurs sélectionnés"
				},
				"mensagens": {
					"success": "Configuration enregistrée avec succès"
				}
			},
			"empresaConfig": {
				"title": "Configuration {{nCompany}} - {{companyname}}",
				"empresaConfigEstados": {
					"title": "Configuration de l'état",
					"estados": "États",
					"herdado": "Hérité de la configuration générale",
					"detail": {
						"title": "État - {{NameState}}",
						"titleAdd": "Ajouter un statut",
						"todasEmpresas": "Disponible pour toutes les entreprises",
						"cor": "Couleur"
					},
					"mensagens": {
						"successEstados": "Statut enregistré avec succès",
						"successEstado": "Statut enregistré avec succès",
						"errorDeleteEstado": "Vous ne pouvez pas supprimer ce statut, il reste associé à un sujet",
						"notaOrdenarEstados": "Pour trier les états, il suffit de glisser-déposer",
						"notaInherited": "Utilisateurs hérités de la configuration générale"
					}
				}
			},
			"topico": {
				"newMessage": "Nouvelle communication",
				"editMessage": "Modifier la communication",
				"deleteMessage": "Supprimer la communication",
				"assunto": "Sujet",
				"user": "Utilisateur",
				"categoria": "Catégorie",
				"estado": "État",
				"data": "Date",
				"mensagem": "Message",
				"mensagensTitle": "Messages",
				"mensagemPlaceholder": "Écrivez votre message ici...",
				"mensagensNaoLidas": "Messages non lus",
				"mensagens": {
					"success": "Communication envoyée avec succès",
					"updateSuccess": "Communication modifiée avec succès",
					"deleteSuccess": "Communication supprimée avec succès"
				}
			}
		},
		"common": {
			"filtrarPorPalavraChave": "Filtrer par mot-clé",
			"semMensagens": "Aucun message",
			"carregarMais": "Charger plus",
			"typeYourMessage": "Écrivez votre message...",
			"selectEmpresa": "Sélectionnez une entreprise",
			"selectComunicacao": "Sélectionnez une communication",
			"nothingSelected": "Rien n'est sélectionné",
			"ficheiroSelecionadoSucesso": "Fichier sélectionné avec succès"
		}
	},
	"gestaoFicheirosSepa": {
		"grid": {
			"fields": {
				"nContaForn": "Numéro de compte",
				"nomeBeneficiario": "Nom",
				"ibanContaDest": "IBAN",
				"swiftDest": "RAPIDE",
				"valor": "Valeur",
				"dataLanc": "Date de création",
				"nomeFicheiro": "Nom de fichier",
				"estadoNotificacao": "Notification du fournisseur"
			},
			"actions": {
				"imprimirNotificar": "Imprimer/Notifier",
				"download": "Télécharger le fichier SEPA",
				"retrocederPorEnviar": "Changer le statut en \"Non envoyé\" du fichier SEPA"
			},
			"toolbar": {
				"meioPagamento": "Banque (Mode de paiement)",
				"dataDe": "Date de paiement"
			}
		},
		"tabs": {
			"title": {
				"enviados": "Envoyé",
				"porEnviar": "Pour l'envoi"
			}
		},
		"btn": {
			"gerarFicheiroSEPA": "Générer un fichier SEPA"
		},
		"error": {
			"pesquisar": "Tous les paramètres de recherche doivent être renseignés"
		}
	},
	"gestaodgemps": {
		"text": {
			"abonos": "Allocations",
			"faltas": "Fautes",
			"ferias": "Vacances",
			"servico": "Service",
			"view": "Voir",
			"yearly": "Annuel",
			"monthly": "Mensuel",
			"finsSemanaFeriados": "Week-ends/jours fériés",
			"pendentes": "Pendentifs",
			"marcadas": "Marqué",
			"aprovadas": "Approuvé",
			"integradas": "Intégré",
			"rejeitadas": "Rejeté",
			"gozadas": "Éjaculations",
			"restantes": "Restant",
			"conflito": "Conflit",
			"attachments": "Pièces jointes",
			"fromDate": "de",
			"toDate": "au",
			"addNew": "Ajouter un nouvel événement",
			"motivoRejeicao": "Motif du refus",
			"day": "Jour {{date}}",
			"allDay": "{{date}}, toute la journée",
			"fromTo": "Du {{start}} au {{end}}",
			"fromToHours": "{{date}}, du {{start}} au {{end}}",
			"recordedWageProcessing": "Inscrit au traitement des salaires",
			"details": "Détails",
			"integraAll": "Intégrer les événements sélectionnés",
			"integraAllGroup": "Intégrer des événements pour les employés",
			"approveAll": "Approuver les événements sélectionnés",
			"approveAllGroup": "Approuver les événements des employés",
			"rejectAll": "Rejeter les événements sélectionnés",
			"rejectAllGroup": "Rejeter les événements des employés",
			"setMotivoAll": "Définir la raison des événements sélectionnés",
			"setMotivoAllGroup": "Définir la raison des événements des employés",
			"rewind": "Revenir à l'état",
			"rewindAll": "Rétablir l'état des événements sélectionnés",
			"rewindAllGroup": "Rétablir le statut de l'événement pour les employés",
			"markOff": "Décochez",
			"markOffAll": "Désélectionner les événements sélectionnés",
			"markOffAllGroup": "Désélectionner les événements des employés",
			"addAttachment": "Ajouter une pièce jointe",
			"downloadAttachment": "Télécharger la pièce jointe",
			"replaceAttachment": "Remplacer la pièce jointe",
			"deleteAttachment": "Supprimer la pièce jointe",
			"history": "Historique des événements",
			"noEvents": "Il n'y a aucun événement à la date sélectionnée",
			"integrar": "Intégrer",
			"prettyFeriasMarcadas": "{{marked}} Marqué / {{total}} Total",
			"confirm": {
				"integraAll": "Etes-vous sûr de vouloir intégrer tous les événements sélectionnés ?",
				"integraAllGroup": "Êtes-vous sûr de vouloir intégrer tous les événements du collaborateur \"{{codeEmp}} - {{nomeEmp}}\" ?",
				"approveAll": "Êtes-vous sûr de vouloir approuver tous les événements sélectionnés ?",
				"approveAllGroup": "Êtes-vous sûr de vouloir approuver tous les événements pour le collaborateur \"{{codeEmp}} - {{nomeEmp}}\" ?",
				"rejectAll": "Êtes-vous sûr de vouloir rejeter tous les événements sélectionnés ?",
				"rejectAllGroup": "Êtes-vous sûr de vouloir rejeter tous les événements du collaborateur « {{codeEmp}} - {{nomeEmp}} » ?",
				"rewindAll": "Êtes-vous sûr de vouloir rétablir le statut de tous les événements sélectionnés ?",
				"rewindAllGroup": "Êtes-vous sûr de vouloir rétablir le statut de tous les événements du collaborateur « {{codeEmp}} - {{nomeEmp}} » ?",
				"markOffAll": "Êtes-vous sûr de vouloir désélectionner tous les événements sélectionnés ?",
				"markOffAllGroup": "Êtes-vous sûr de vouloir désélectionner tous les événements pour le collaborateur « {{codeEmp}} - {{nomeEmp}} » ?",
				"deleteAttachment": "Êtes-vous sûr de vouloir supprimer la pièce jointe sélectionnée ?"
			},
			"success": {
				"changed": "Modification réussie",
				"integrado": "Intégration réussie",
				"rejeitado": "Rejeté avec succès",
				"aprovado": "Approuvé avec succès",
				"retrocede": "Annulé avec succès",
				"addAttachment": "Pièce jointe ajoutée avec succès",
				"replaceAttachment": "Pièce jointe remplacée avec succès",
				"deleteAttachment": "Pièce jointe supprimée avec succès"
			},
			"warning": {
				"rewindAllPosicao": "Attention!"
			},
			"error": {
				"aprovado": "Une erreur s'est produite lors de la tentative d'approbation"
			},
			"motivos": {
				"default": "raison",
				"retrocede": "Reculer la position"
			},
			"status": {
				"male": {
					"marcado": "MARQUÉ (en attente d'approbation)",
					"aprovado": "APPROUVÉ",
					"aprovadoParcialmente": "APPROUVÉ (partiellement)",
					"rejeitado": "REJETÉ",
					"integrado": "INTÉGRÉ",
					"gozado": "APPRÉCIÉ"
				},
				"female": {
					"marcado": "MARQUÉ (en attente d'approbation)",
					"aprovado": "APPROUVÉ",
					"aprovadoParcialmente": "APPROUVÉ (partiellement)",
					"rejeitado": "REJETÉ",
					"integrado": "INTÉGRÉ",
					"gozado": "SPERME"
				}
			}
		},
		"errors": {
			"invalidTipoAbono": "Vous devez définir un type d'allocation.",
			"invalidTipoFalta": "Vous devez définir un type d'absence.",
			"invalidTipoFeria": "Vous devez définir un type de vacances.",
			"invalidQuantidade": "Vous devez définir une quantité.",
			"invalidTipoProcessamento": "Vous devez définir un type de traitement.",
			"invalidDesconto": "Vous devez définir une remise.",
			"naoPodeMarcarFeriasPorqueTemConflitos": "Vous ne pouvez pas planifier de vacances à cette date ou à cet intervalle en raison de conflits."
		},
		"fields": {
			"codEmp": "Code.",
			"nomeCategoria": "Catégorie",
			"motivo": "Raison",
			"nomeEmpregado": "Collaborateur",
			"tipo": "Taper",
			"tipoFalta": "Type de faute",
			"tipoProcessamento": "Type de traitement",
			"designacaoAbDes": "Rabais",
			"dataTarefa": "Jour",
			"datasTarefas": "Jours",
			"alteraFaltas": "Changer les fautes",
			"novoTipo": "Nouveau type",
			"anexaFicheiro": "Joindre un fichier",
			"historico": "Histoire",
			"quantidade": "Montant",
			"novaQuantidade": "Nouvelle quantité",
			"allDay": "Toute la journée",
			"horaInicio": "Heure de début",
			"horaFim": "Heure de fin.",
			"valor": "Valeur",
			"dadosFixos": "Données fixes",
			"anexo": "Annexe",
			"daData": "Date de",
			"codEmpDe": "code employé",
			"activeOnly": "Actif uniquement",
			"valorUnitario": "Valeur unitaire",
			"dataProcessamento": "Date de traitement",
			"marcacoes": "Rendez-vous"
		},
		"header": {
			"previous": "Précédent",
			"today": "Aujourd'hui",
			"next": "Suivant",
			"previousYear": "Année précédente",
			"nextYear": "L'année prochaine",
			"previousMonth": "Mois précédent",
			"nextMonth": "Mois prochain"
		},
		"abonos": {
			"pageTitle": "Gestion des allocations",
			"sidebarTitle": "Allocations",
			"motivos": {
				"marcar": "Allocations d'horaire",
				"aprovar": "Approbation des allocations",
				"rejeitar": "Rejet des allocations",
				"integrar": "Intégration des allocations"
			},
			"marcarmodal": {
				"title": "Demande de réservation de quota"
			},
			"cancelarmodal": {
				"title": "Désélectionner l'allocation",
				"message": "Etes-vous sûr de vouloir décocher les allocations ?"
			}
		},
		"faltas": {
			"pageTitle": {
				"colaborador": "Marquage des fautes",
				"gestor": "Gestion des absences"
			},
			"sidebarTitle": {
				"colaborador": "Marquer les fautes",
				"gestor": "Fautes"
			},
			"motivos": {
				"marcar": "Marquage des fautes",
				"aprovar": "Approbation des absences",
				"rejeitar": "Rejet des fautes",
				"integrar": "Intégration des défauts"
			},
			"marcarmodal": {
				"title": "Demande de marquage des absences"
			},
			"cancelarmodal": {
				"title": "Effacer les fautes",
				"message": "Êtes-vous sûr de vouloir annuler les fautes ?"
			}
		},
		"ferias": {
			"pageTitle": {
				"colaborador": "Réservation de vacances",
				"gestor": "Gestion des vacances"
			},
			"sidebarTitle": {
				"colaborador": "Réservez des vacances",
				"gestor": "Vacances"
			},
			"motivos": {
				"marcar": "Réservation de vacances",
				"aprovar": "Approbation des vacances",
				"rejeitar": "Rejet des vacances",
				"integrar": "Intégration vacances"
			},
			"titles": {
				"marcadas": "Vacances programmées",
				"rejeitadas": "Vacances refusées",
				"integradas": "Vacances intégrées"
			},
			"marcarmodal": {
				"title": "Demande de réservation de vacances",
				"message": "Êtes-vous sûr de vouloir réserver des vacances ?",
				"marcarPara": "Marquer pour les collaborateurs"
			},
			"cancelarmodal": {
				"title": "Annuler la réservation de vacances",
				"message": "Êtes-vous sûr de vouloir annuler des vacances ?"
			},
			"existemConflitosPertendeContinuar": "Il y a des conflits à cette date ou plage."
		},
		"details": {
			"titleAbonos": "Allocations {{date}}",
			"titleRangeAbonos": "Allocations {{start}} jusqu'au {{end}}",
			"titleFaltas": "Fautes {{date}}",
			"titleRangeFaltas": "Fautes de {{start}} à {{end}}",
			"titleFerias": "Vacances {{date}}",
			"titleRangeFerias": "Vacances {{start}} jusqu'au {{end}}"
		},
		"summary": {
			"title": "Résumé",
			"integradas": "{{value}} intégré"
		},
		"historymodal": {
			"title": "Histoire"
		},
		"motivomodal": {
			"title": "Définir la raison",
			"titleGlobal": "Définir la raison des événements sélectionnés",
			"titleGroup": "Définissez le motif des événements des employés : \"{{EmpName}}\""
		},
		"printmodal": {
			"title": "Impression événementielle"
		}
	},
	"gestorrh": {
		"servicos": {
			"fields": {
				"colaborador": "Collaborateur",
				"nome": "Nom",
				"papel": "Papier"
			},
			"errors": {
				"unicoServico": "Il existe déjà un collaborateur associé au rôle sélectionné dans ce service"
			}
		}
	},
	"global": {
		"centralgestLoginName": "CentralGest - Production de logiciels, SA",
		"title": "CentralGest Cloud",
		"menu": {
			"developer": "Promoteur",
			"empresa": "Entreprise",
			"home": "Maison",
			"demo": "Démo",
			"artigos": "Articles",
			"recibos": "Recettes",
			"pagamentos": "Paiements",
			"clifos": "Tiers",
			"clientes": "Dossiers clients",
			"fornecedores": "Fiches fournisseurs",
			"outrosdevedorescredores": "Registres des autres débiteurs et créanciers",
			"options": "Possibilités",
			"configs": "Paramètres",
			"configsSite": "Configuration de la messagerie",
			"configsErp": "Configuration ERP",
			"erpCheck": "Validation des paramètres",
			"docscomerciais": "Documents",
			"docscontabilidade": "Collection de versions",
			"vendas": "Ventes",
			"compras": "Achats",
			"guias": "Guides",
			"docfas": "Types de documents",
			"familias": "Familles",
			"grandesfamilias": "Grandes familles",
			"processos": "Processus",
			"ccustos": "Centres de coûts",
			"armazens": "Entrepôts",
			"codpostais": "Codes postaux",
			"subfamilias": "Sous-familles",
			"tiposartigo": "Types d'articles",
			"unidadesmovimento": "Unités de mouvement",
			"unece": "Unités de mesure du commerce international",
			"ivas": "Tableaux de TVA",
			"condicoescomerciais": "Conditions commerciales",
			"condicoespagamento": "Conditions de paiement",
			"meiosPagamento": "Modes de paiement",
			"naturezasContab": "Caractères comptables",
			"moradasFaturacao": "Adresses de facturation",
			"listagensMovimentosPendentesClientes": "Mouvements de clients en attente",
			"listagensMovimentosPendentesFornecedores": "Mouvements fournisseurs en attente",
			"listagensMovimentosPendentesOutrosDevedoresCredores": "Mouvements en attente des autres débiteurs et créanciers",
			"taxasretencao": "Taux de rétention",
			"users": "Utilisateurs",
			"tokens": "Jetons",
			"roles": "Autorisations",
			"templates": "Modèles",
			"auditlogin": "Audit de connexion",
			"pocs": "Plan comptable",
			"descritivos": "Descriptions comptables",
			"diarios": "Journaux comptables",
			"anosFaturacao": "Nombre d'années de facturation",
			"portals": "Portails",
			"periodos": "Périodes comptables",
			"qivaps": "Champs de déclaration périodique de TVA",
			"qivaas": "Champs de déclaration de TVA annuelle",
			"controloIVA": "Contrôle de la TVA",
			"vendedores": "Vendeurs",
			"artigoclasses": "Classes d'articles",
			"mascarasanalitica": "Masques d'analyse",
			"amortizacao": "Codes d'amortissement",
			"datavalor": "Date de valeur",
			"estabelecimentos": "Établissements",
			"estabelecimento": "Établissements",
			"localidades": "Emplacements",
			"local": "Emplacements",
			"qpcae": "Activité économique",
			"qpdcf": "District/Municipalité/Paroisse",
			"calendarios": "Calendriers",
			"nacionalidades": "Nationalités",
			"tirs": "Tableau IRS",
			"irsdt": "Date du tableau IRS",
			"classescaixa": "Cours en espèces",
			"classescontratos": "Classes de contrats",
			"classescontratosservicos": "Classes d'alliance",
			"ativos": "Jetons d'actifs",
			"ativosAquisicao": "Acquisitions",
			"categoriaartigo": "Catégorie d'article",
			"reparticoesccustosfaturacao": "Répartition par centre de coûts",
			"reparticoesccustossalarios": "Répartition par centre de coûts",
			"account": {
				"main": "profil",
				"login": "Authentifier",
				"logout": "Se déconnecter du compte",
				"changeEmpresa": "Changer d'entreprise",
				"changeUser": "Changer d'utilisateur",
				"pesquisarEmpresa": "Rechercher une entreprise :"
			},
			"language": "Langue",
			"prhlocais": "Emplacements",
			"prhmenus": "Menus",
			"dgemps": "Collaborateurs",
			"dgempsfull": "Fiche collaborateur",
			"prhrelementas": "Menus relationnels",
			"prhrefeicoes": "Repas",
			"prhtiporefeicoes": "Types de repas",
			"prhservicos": "Services",
			"prhpapeis": "Papiers",
			"prhservicoempregados": "Rapport de service/emploi.",
			"prhfluxos": "Flux",
			"prhcomunicacoesinternas": "Com. interne.",
			"prhcomunicacoesinternasList": "Communication interne",
			"paises": "Pays",
			"prheventosconfig": "Configuration d'événement",
			"prhdocsplata": "Documents.",
			"categorias": "Catégories",
			"efatura": "SAF-T eFatura",
			"configefaturadocporlancar": "Configuration des documents pour le lancement d'eFatura",
			"mercados": "Marchés",
			"criterioDisponiveis": "Critères disponibles",
			"calcPrVenda": "Calcul du prix de vente",
			"tipoResumoMensal": "Type de résumé mensuel",
			"origemCondPagamento": "Paiement des conditions d'origine",
			"origemDataVencimento": "Date d'échéance d'origine",
			"tipoOperacao": "Type d'opération",
			"areaRegional": "Zone régionale",
			"inversaoSujPassivo": "Inversion de matière imposable",
			"tipoLiquidacao": "Type de règlement",
			"tipoDeducao": "Type de déduction",
			"tipoTransmissaoActiva": "Type de transmission active",
			"tipoTransmissaoPassiva": "Type de transmission passive",
			"tipoBem": "Type de bien",
			"tipoTaxa": "Type de frais",
			"tipoValidacaoNif": "Type de validation NIF",
			"tipoRegularizacaoCampo40": "Type de terrain 40 régularisations",
			"tipoRegularizacaoCampo41": "Type de terrain 41 régularisations",
			"tipoDescritivo": "Type de descriptif",
			"formaIncrementoNDoc": "Formulaire d'augmentation du numéro de document",
			"fluxoMonetario": "Flux d'argent",
			"tipoTaxaIva": "Type de taux de TVA",
			"areaRegionalTaxa": "Tarif régional",
			"tipoDeducaoTaxa": "Type de déduction fiscale",
			"tipoConta": "Type de compte",
			"tiposRefeicoes": "Types de repas",
			"tiposMovimento": "Types de mouvements",
			"zonas": "Zones",
			"codigoscontabilisticos": "Codes comptables",
			"reembolsosiva": "Remboursement de TVA",
			"fimperiodoenc": "Clôture des périodes",
			"depto": "Département",
			"subde": "Sous-département",
			"predefinidoscontabilidade": "Comptabilité prédéfinie",
			"moeda": "Pièces",
			"moedaNormasIso": "Normes ISO sur les pièces de monnaie",
			"paisesISO3166": "Pays ISO 3166",
			"prhgruporefeicoes": "Repas de groupe",
			"reconstrucaoacumulados": "Reconstitution des régularisations",
			"verificacaoacumulados": "Vérification des régularisations",
			"erpusers": "Utilisateurs ERP",
			"terceiros": "Tiers",
			"concelhos": "Comtés",
			"distritos": "Quartiers",
			"freguesias": "Paroisses",
			"continentes": "Continents",
			"repfinancas": "Bureaux des impôts",
			"assistenteConfigPortais": "Assistant de configuration",
			"mapasamortizacao": "Cartes d'amortissement",
			"tiposcontrato": "Types de contrat",
			"divisoesTrabalho": "Divisions du travail",
			"qppaises": "Pays du personnel",
			"qpdur": "Durées du cadre personnel",
			"seccoes": "Sections",
			"nifs": "Numéros d'identification fiscale",
			"taxasiva": "Taux de TVA",
			"fimano": "Fin d'année",
			"calculodiferimentos": "Calcul des reports",
			"extratos": "Relevés de compte",
			"extratosgrid": "Relevé de compte",
			"impdecpe": "Extrait du champ de déclaration périodique",
			"ativosconfiguracoes": "Paramètres",
			"intfac": "Intégration de documents",
			"listagemdiarios": "Journaux comptables",
			"ativosdepreciacao": "Dépréciation des actifs",
			"ativosimpressao": "Imprimer à partir d'actifs",
			"ativosmapasoficiais": "Imprimer des cartes officielles",
			"ativosgeramapasauto": "Dossier fiscal",
			"analiticaconfig": "Configuration du compte d'analyse du plan comptable",
			"ativosaquisicaocontab": "Acquisition à partir d'un document comptable",
			"ativosabate": "Enregistrez les victimes en série",
			"ativosvenda": "Enregistrer les ventes en série",
			"entradasdiversas": "Entrées diverses",
			"saidasdiversas": "Diverses sorties",
			"extractosartigos": "Extrait d'article",
			"grabonos": "Groupes d'allocations",
			"grdescontos": "Groupes de réduction",
			"ativosimport": "Importation d'actifs",
			"rhtipoprocess": "Types de traitement des ressources humaines",
			"rhconfiguracoes": "Paramètres des ressources humaines",
			"rhdadosconfigficha": "Configuration des données de la carte",
			"tiposregistocadastro": "Types d'enregistrements d'enregistrement",
			"situacaoempregado": "Statut d'employé",
			"tipohorario": "Type d'horaire",
			"rhassistentetrabalhador": "Configurer l'intégration salariale",
			"rhtransferenciasbancarias": "Virements bancaires",
			"bancos": "Banques",
			"idbancos": "Identification bancaire",
			"sitpr": "Situation des métiers",
			"funcoes": "Fonctions",
			"nivql": "Niveaux de qualification",
			"qpsit": "Situations de recherche",
			"segur": "Compagnies d'assurance",
			"crss": "Centres du régime de sécurité sociale",
			"prof": "Profession",
			"profissao": "Profession",
			"conttrabcab": "Contrat de travail",
			"qpcnp": "Recherche de métier",
			"cltrb": "Catégories d'emplois",
			"conttrabbaselegal": "Base juridique du contrat de travail",
			"conttrabmotivo": "Motifs du contrat de travail",
			"rhunidades": "Unités des ressources humaines",
			"abdes": "Allocations/réductions",
			"qphab": "Qualifications",
			"qptco": "Type de contrat de personnel",
			"abonos": "Allocations",
			"descontos": "Réductions",
			"cgasi": "Codes de situation C.G.A.",
			"remun": "Collecte de données",
			"remfx": "Collecte de données fixes",
			"rhpenhoras": "Liens",
			"rhprocsegsocialconsultenvios": "Lundi social - consultation sur l'expédition",
			"crdescontos": "Crossovers à prix réduit",
			"crabonos": "Croisements des allocations",
			"procseguradorafolhaferias": "Traitement des paies de vacances pour une compagnie d'assurance",
			"pcaRecibosVencimentos": "Fiches de paie",
			"rhgestaoficheirosdmrat": "Gestion des fichiers DMR AT",
			"estados": "États",
			"rendis": "Types de revenus",
			"situacaoprofissional": "Statut professionnel",
			"qpmte": "Raisons de l’entrée des salariés",
			"cct": "Contrats collectifs de travail",
			"qpirt": "Recherche IRCT",
			"qpisp": "Code FAI",
			"trfLancContas": "Transfert de compte",
			"usersbasic": "Utilisateurs",
			"ativoscoeficientecm": "Mettre à jour le coefficient de correction monétaire",
			"propostasaclientes": "Budgets",
			"encomendas": "Ordres",
			"encomendasclientes": "Commandes clients",
			"encomendasfornecedores": "Commandes fournisseurs",
			"ativosconsistencia": "Cohérence des valeurs entre actif et comptabilité",
			"docsComerciaisEstatisticasVendas": "Business Vol - Ventes par article",
			"docsComerciaisEstatisticasDocVendas": "Business Vol - Ventes par document",
			"docsComerciaisEstatisticasCompras": "Business Vol - Achats par article",
			"docsComerciaisEstatisticasDocCompras": "Business Vol - Achats par document",
			"clientconnect": "Connexion client",
			"assistenteConfigPortaisPortalClientConnectUtilizador": "Portail Client Connect - Nouvelle configuration utilisateur",
			"assistenteConfigPortaisPortalClientConnectUtilizadorExistente": "Portail Client Connect - Configuration utilisateur existante",
			"documentosdigitais": "Documents numériques",
			"documentosdigitaisConciliacao": "Rapprochement des documents numériques",
			"documentosDigitaisClientConnect": "Documents numériques",
			"rhcessacontratotrabalho": "Génère la résiliation du contrat",
			"rhmanutencao": "Maintien des ressources humaines",
			"rhcalculorendimentoliquido": "Calcul par résultat net",
			"rhrecolhaseriesubsidiosferias": "Paiement en série de l'indemnité de vacances",
			"rhrecolhaseriesubsidiosnatal": "Paiement en série de l'allocation de Noël",
			"rhrecolhaseriesubsidiosalimentacao": "Prétraitement en série des subventions alimentaires",
			"rhqpirct": "Tableaux officiels du personnel de l'IRCT",
			"rhprocessamentosalarios": "Configuration du traitement de la paie",
			"rhfolfer": "Feuille de salaire",
			"rhestatisticas": "Statistiques des ressources humaines",
			"rhestatisticasmulti": "Statistiques des Ressources Humaines (Multi-Entreprises)",
			"modelo10": "Modèle 10",
			"retencoes": "Visualisation des réservations",
			"modelo22": "Modèle 22",
			"modelo25": "Modèle 25",
			"modelo30": "Modèle 30",
			"modelo39": "Modèle 39",
			"rhimpvenct": "Listes de statistiques d’expiration",
			"impctrirs": "Listes de contrôle IRS",
			"rhagencias": "Tenue des banques, agences et comptes",
			"impfferpoc": "Classement des feuilles de salaire",
			"rhtranssal": "Transferts de salaire",
			"trfba": "Paiements par virement bancaire",
			"trfbarh": "Paiements par virement bancaire",
			"trfbacomercial": "Paiements par virement bancaire",
			"contabAdvancedOperationsPeriodo": "Changement de période de document",
			"contabAdvancedOperationsDiario": "Modification du journal des documents",
			"contabAdvancedOperationsAnular": "Annuler des documents",
			"faturacaopublica": "Gestion des documents de facturation électronique publique",
			"idide": "Groupes de comptes",
			"impencar": "Liste des accusations",
			"impAvisosCobranca": "Impression des avis de facturation",
			"declaracaoiva": "Déclaration périodique de TVA",
			"declaracaoivarecapitulativa": "Déclaration récapitulative de TVA",
			"vendascliente": "Récapitulatif par client",
			"vendasartigo": "Résumé par article",
			"resumoiva": "Récapitulatif de la TVA",
			"definicoesemail": "Définition des e-mails",
			"dashboard": "Tableau de bord",
			"marcarRefeicoes": "Réserver des repas",
			"visualizarRefeicoes": "Voir les repas",
			"extratosFornecedores": "Déclarations des fournisseurs",
			"extratosClientes": "Déclarations des clients",
			"gto": "Gestion des tâches et obligations",
			"entidadeexterna": "Entités externes",
			"solic": "Textes d’avis de demande de prélèvement",
			"impdadosemp": "Liste des données des employés",
			"dmrf": "Relevé mensuel de retenue",
			"simulacaovencimento": "Simulation de réception",
			"contratostipos": "Types de contrats",
			"contratostiposervicos": "Types d'engagements",
			"concilmovab": "Justification des mouvements ouverts",
			"faturacaocontratos": "Facturation à réception",
			"conciliacaobancaria": "Rapprochement bancaire",
			"bancosextrato": "Automatisation bancaire",
			"bancosextratosimple": "Automatisation bancaire",
			"sind": "Dossier syndical",
			"utlviatpatronal": "Utilisation des véhicules de l'employeur",
			"demfinsnc": "SNC états financiers",
			"contratos": "Contrats",
			"contratosservicos": "Alliances",
			"transferenciasarmazens": "Transfert d'articles entre entrepôts",
			"rhrelatoriounico": "Rapport unique",
			"contratosprint": "Impression de contrats",
			"impde119": "Déclaration Artº 119 CIRS",
			"tesrubrica": "États de validation des prévisions du Trésor",
			"prevites": "Prévisions du Trésor",
			"previtesauto": "Lancement automatique des prévisions de trésorerie",
			"prevtesobras": "Importateur de plannings financiers de travaux",
			"qpmts": "Raisons du départ d'un employé",
			"officereporting": "Évolution de l'entreprise",
			"ativosaquisicaoinvestemcurso": "Acquisition basée sur des investissements en cours",
			"faturacaoeletronicaconfiguracoes": "Paramétrage de la facturation électronique",
			"segsocialfolhaferias": "Sécurité sociale/paie de vacances",
			"integracaoSalarios": "Intégration salariale",
			"proceSalarios": "Traitement des salaires",
			"proceDMRAT": "Traitement DMR - AT",
			"proceSegSocial": "Traitement de la sécurité sociale",
			"integracaoSalariosMulti": "Intégration salariale (multi-entreprises)",
			"gestaoFicheirosSepa": "Gestion des fichiers SEPA",
			"rhgozoferias": "j'apprécie les vacances",
			"mbconfigs": "Configuration des références ATM",
			"rhatualizaferiasemp": "Mettre à jour les dossiers de vacances des employés",
			"rhmapassindicatos": "Carte pour les syndicats",
			"extratosDT": "Historique des relevés de compte",
			"extratosDTClientes": "Relevé de compte client",
			"extratosDTFornecedores": "Relevé de compte fournisseur",
			"infoglobaldiferimentosacrescimos": "Reports/ajouts d’informations globales",
			"infoglobaldiferimentos": "Informations sur les reports globaux",
			"infoglobalacrescimos": "Ajouts d'informations globales",
			"rhregistarempregadosss": "Consulter/Inscrire les salariés à la sécurité sociale",
			"rhmanutencaocampos": "Mettre à jour les champs de salaire",
			"analisadorTes": "Planification de la trésorerie",
			"importxls": "Importations",
			"pocscopy": "Copier les comptes du plan pour les entreprises",
			"controloivaentreempresas": "Copier le contrôle de la TVA pour les entreprises",
			"avisoscobrancagrid": "Avis de facturation",
			"saftcontab": "Importateur comptable SAF-T",
			"tesestados": "États de validation des prévisions du Trésor",
			"guiasfornecedores": "Guides des fournisseurs",
			"lotes": "Beaucoup",
			"morals": "Adresses alternatives",
			"arclis": "Article client/fournisseur",
			"clicls": "Classe",
			"mapaPagamentos": "Carte de paiement",
			"exportacaocompras": "exportation géofolia",
			"planoscontasalternativos": "Plans de compte alternatifs",
			"balancetes": "Bilans comptables",
			"balancetesalternativos": "Bilans des régimes alternatifs",
			"balancetesanalitica": "Bilans comptables analytiques",
			"comprasautofaturacao": "Autofacturation",
			"acrescimosencargoscomferias": "Frais de vacances supplémentaires",
			"adiantamentosclientes": "Avances clients",
			"adiantamentosfornecedores": "Avances fournisseurs",
			"rhCopiaAbonosDescontos": "Copier les allocations/réductions",
			"trdocs": "Transformation de documents",
			"cargveic": "Paramètres du véhicule",
			"ativosaquisicaoinvestemcursopordoc": "Acquisition basée sur des investissements en cours par document",
			"manuttributautonomas": "Gestion autonome de la fiscalité",
			"tributautonomas": "Fiscalité autonome",
			"rendHabitPerm": "Utilisation du logement de l'employeur",
			"mapaExploracao": "Carte d'exploration",
			"colaboradoresAlteracoesEntidades": "Données à modifier",
			"colaboradoresAprovacoesRefeicoes": "Repas à approuver",
			"colaboradorboard": "Résumé",
			"colaboradoresassociaservicos": "Associer au service",
			"colaboradoreshome": "Maison",
			"colaboradoresservicos": "Comme. rôles aux services",
			"colaboradorescomunicacoesinternas": "Communications",
			"colaboradoresdgemps": "Utilisateurs",
			"dgempsficha": "Données personnelles",
			"documentosPartilhados": "Documents partagés",
			"dossierAlteracoesCapitalProprio": "Variations des capitaux propres",
			"dossierAnexoDemonstracoesFinanceiras": "Annexe",
			"dossierBalanco": "Équilibre",
			"dossierDemonstracaoResultados": "Démons.",
			"dossierFluxoCaixa": "Flux de trésorerie",
			"dossierResultadosPorNatureza": "Résultats par fonctions",
			"empresas": "Entreprise",
			"fct": "Fonds de compensation",
			"colaboradoresfluxos": "Flux",
			"ies": "Exporter le fichier IES-DA",
			"modules": "Modules",
			"conciliacaoEfaturas": "Rapprochement des factures électroniques",
			"pcaBalancetes": "Bilans",
			"pcaExtratos": "Extraits",
			"pcaIdadeSaldosClientes": "Soldes clients",
			"pcaIdadeSaldosFornecedores": "Soldes fournisseurs",
			"documentosFiscais": "Documents fiscaux",
			"movimentosemaberto": "Mouvements comptables ouverts",
			"svat": "SVAT états financiers",
			"pcaContas": "Identification",
			"pcaDocumentosComerciais": "Facturation",
			"pcaExtratosConta": "Extrait",
			"pcaMovimentosAbertoCliente": "Mouvements ouverts",
			"pcaMapaFaltas": "Carte des fautes",
			"pcaMapaFerias": "Carte des vacances",
			"pcaFundosCompensacao": "Fonds de compensation",
			"pcaMapaSeguradora": "carte d'assurance",
			"pcaSegurancaSocial": "Sécurité sociale",
			"pcaMapaSindicatos": "Carte des syndicats",
			"colaboradoresrecibosvencimento": "Recettes",
			"colaboradoresAssociarMenus": "Associer des menus",
			"colaboradoresListagemMenus": "Listes de menus",
			"colaboradoresListagemRefeicoes": "Listes de repas",
			"rptconfigs": "Configuration du rapport",
			"inventarios": "Inventaires",
			"listagensVendasAnoMes": "Ventes par mois",
			"saft": "Exporter le fichier SAF-T",
			"artars": "Articles d'entrepôt",
			"crneg": "Exceptions croisées",
			"docfasnums": "Série de types de documents",
			"docfasnumscomunicacaologin": "Communication en série de connexion",
			"docspartilhados": "Documents de la plateforme RH",
			"erputilizadores": "Utilisateurs de CentralGest",
			"erps": "Installations",
			"fremun": "Formes de rémunération",
			"gdoc": "Gestion des pièces jointes",
			"indicefluxos": "Indices de débit",
			"ivamotivostaxa": "Raisons de l'exonération de TVA",
			"perfilCategoriaAbono": "Allocations des catégories de profils",
			"perfilCategoriaDesconto": "Remises sur les catégories de profils",
			"sscol": "Paiements de sécurité sociale",
			"taxonomias": "Taxonomies et validations SVAT",
			"importadorEfatura": "Documents à publier eFatura",
			"importadorSaftDirect": "Importateur SAF-T PT facturation pour comptabilité",
			"atFaturasRecibosVerdes": "AT - Factures et reçus verts",
			"apuraiva": "Calcul de la TVA et fin de période",
			"apuramentoresultados": "Calcul des résultats nets",
			"pcaContabilidadeMovimentosAbertoCliente": "Transactions clients ouvertes",
			"pcaContabilidadeMovimentosAbertoFornecedor": "Mouvements de fournisseurs ouverts",
			"qpapa": "Association des employeurs",
			"qpnj": "Nature juridique",
			"sectores": "Secteurs",
			"docsComerciaisConfigNomeExportFile": "Configuration du nom des fichiers à exporter",
			"txtar": "Textes d'articles gratuits",
			"txtcl": "Textes sans entité",
			"mapadiferimentosacrescimos": "Carte des ajouts et des reports",
			"prhtipocom": "Types de communication",
			"consultasafornecedores": "Budgets",
			"grupodocfaconfiguracoes": "Paramètres du groupe de types de documents",
			"analisemargemlucro": "Analyse de la marge bénéficiaire",
			"comcategorias": "Catégories de communications",
			"gestaoComunicacoes": "Gestion des communications",
			"gestaoComunicacoesCliente": "Gestion de la communication client"
		},
		"form": {
			"username": "Utilisateur",
			"username.placeholder": "Votre nom pour l'authentification",
			"usernameemail.placeholder": "Nom d'utilisateur ou email",
			"newpassword": "nouveau mot de passe",
			"newpassword.placeholder": "votre nouveau mot de passe",
			"confirmpassword": "Confirmation du mot de passe",
			"confirmpassword.placeholder": "Confirmation du mot de passe",
			"email": "E-mail",
			"email.placeholder": "votre email",
			"notApplicable": "N / A"
		},
		"text": {
			"notDefined": "N / A",
			"note": "Avis",
			"filters": "Filtres",
			"errorNum": "Erreur n° {{erreur}}.",
			"contactAdmin": "Veuillez contacter l'administrateur.",
			"empty": " ",
			"success": "Succès",
			"info": "Information",
			"warning": "Avis",
			"error": "Erreur",
			"confirmation": "Confirmation",
			"help": "Aide",
			"faq": "FAQ",
			"other": "Autres",
			"year": "Année",
			"month": "Mois",
			"date": "Date",
			"fromDate": "Date de",
			"toDate": "Date jusqu'à",
			"to": "Jusqu'à",
			"total": "Total",
			"saldo": "Équilibre",
			"listing": "Inscription",
			"searchfilter": "Filtres de recherche",
			"keeppanel": "Garder le panneau ouvert",
			"all": "Tous",
			"general": "Général",
			"actions": "Actes",
			"attention": "Attention",
			"preview": "Aperçu",
			"movimentosPendentes": "Mouvements en attente",
			"active": "Actif",
			"initProcess": "Démarrage du traitement...",
			"searchNoData": "Aucune donnée trouvée",
			"and": "et",
			"or": "ou",
			"myAccount": "Mon compte",
			"deleteRecord": "Supprimer l'inscription",
			"name": "Nom",
			"today": "Aujourd'hui"
		},
		"language": {
			"pt": "portugais",
			"en": "Anglais"
		},
		"btn": {
			"actions": "Actes",
			"add": "Pour ajouter",
			"addLine": "Ajouter une ligne",
			"advancedSearch": "Recherche avancée",
			"apply": "Appliquer",
			"approve": "Approuver",
			"attach": "Attacher",
			"back": "Pour revenir",
			"calculate": "Calculer",
			"cancel": "Annuler",
			"change": "Pour modifier",
			"changeEmpresa": "Changer d'entreprise",
			"clone": "Cloner",
			"close": "Pour fermer",
			"config": "Paramètres",
			"configWS": "Paramètres WS",
			"confirm": "Confirmer",
			"create": "Pour créer",
			"createFile": "Créer un fichier",
			"delete": "Pour éteindre",
			"downloadPdf": "Télécharger le PDF",
			"edit": "Modifier",
			"email": "E-mail",
			"excel": "Exceller",
			"export": "Exporter",
			"exportPdf": "Exporter un PDF",
			"exportXls": "Exporter Excel",
			"finalize": "Finition",
			"goBack": "Rembobiner",
			"goForward": "Suivant",
			"help": "Aide",
			"import": "Importer",
			"importExport": "Importer/Exporter",
			"load": "Pour charger",
			"mark": "Pour marquer",
			"new": "Nouveau",
			"next": "Suivant",
			"no": "Non",
			"nullify": "Annuler",
			"ok": "D'ACCORD",
			"options": "Possibilités",
			"pdf": "PDF",
			"preview": "Aperçu",
			"previous": "Précédent",
			"print": "Imprimer",
			"process": "Processus",
			"processExcel": "Processus dans Excel",
			"processGrelha": "Passer sur un grill",
			"processPDF": "Traiter le PDF",
			"refresh": "Pour mettre à jour",
			"register": "Registre",
			"reject": "Rejeter",
			"remove": "Retirer",
			"replenish": "Réinitialiser",
			"replenishOriginalValues": "Réinitialiser les valeurs d'origine",
			"retry": "Essayer à nouveau",
			"revert": "Inverse",
			"save": "Sauvegarder",
			"search": "A chercher",
			"send": "Pour envoyer",
			"sendFiles": "Envoyer des fichiers",
			"sendMail": "Envoyer un e-mail",
			"shortcuts": "Raccourcis",
			"simulate": "Simuler",
			"store": "Magasin",
			"submeterAt": "Soumettre à",
			"update": "Pour mettre à jour",
			"validarAt": "Valider sur AT",
			"validarFicheiro": "Valider le fichier",
			"visualize": "Voir",
			"yes": "Oui"
		},
		"saveDoc": "Enregistrer le document",
		"saveDocId": "Enregistrer le document {{id}}",
		"saveDocSimulation": "Enregistrer le document (simulation)",
		"saveDocMessage": "Êtes-vous sûr de vouloir enregistrer le document ?",
		"saveDocMessageSimulation": "Etes-vous sûr de vouloir simuler l'enregistrement du document ?",
		"advancedFilter": "Filtre avancé",
		"debitCredit": {
			"debit": "Dette",
			"credit": "Crédit"
		},
		"states": {
			"admin": {
				"title": "Administration"
			}
		},
		"allRightsReserved": "Tous droits réservés",
		"export": {
			"toExcel": "Exporter vers Excel",
			"toPDF": "Exporter au format PDF"
		},
		"keyboard": {
			"arrowLeft": "Flèche gauche",
			"arrowRight": "Flèche droite"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;'",
			"exitMessage": "En cliquant sur OK, vous quitterez sans enregistrer et toutes les modifications que vous avez apportées seront supprimées."
		},
		"months": {
			"january": "Janvier",
			"february": "Février",
			"march": "Mars",
			"april": "Avril",
			"may": "Peut",
			"june": "Juin",
			"july": "Juillet",
			"august": "Août",
			"september": "Septembre",
			"october": "Octobre",
			"november": "Novembre",
			"december": "Décembre"
		},
		"dayOfWeek": {
			"monday": "Lundi",
			"tuesday": "Mardi",
			"wednesday": "Mercredi",
			"thursday": "Jeudi",
			"friday": "Vendredi",
			"saturday": "Samedi",
			"sunday": "Dimanche"
		},
		"monthsShort": {
			"january": "Jan",
			"february": "Fév",
			"march": "Mer",
			"april": "Avr",
			"may": "Peut",
			"june": "Juin",
			"july": "Juillet",
			"august": "Août",
			"september": "Ensemble",
			"october": "Octobre",
			"november": "Nov",
			"december": "Dix"
		}
	},
	"disconnected": {
		"title": "Déconnecté du serveur",
		"message": "Veuillez vérifier votre connexion et réessayer."
	},
	"entity": {
		"action": {
			"addblob": "Ajouter une goutte",
			"addimage": "Ajouter une image",
			"back": "Pour revenir",
			"cancel": "Annuler",
			"delete": "Pour éteindre",
			"edit": "Modifier",
			"save": "Sauvegarder",
			"view": "Voir",
			"new": "Nouveau",
			"filter": "Filtre",
			"refresh": "Pour mettre à jour",
			"retrieveBackup": "Récupérer le dernier document",
			"duplicate": "Double",
			"actions": "Actes",
			"config": "Paramètres",
			"tooltip": {
				"duplicate": "Créer un nouveau sur cette base"
			}
		},
		"state": {
			"saved": "Enregistré avec succès",
			"error": "Impossible de sauvegarder, veuillez vérifier les données saisies"
		},
		"list": {
			"withoutdata": "aucune donnée",
			"loading": "en lisant...",
			"secondaryClick": {
				"menu": {
					"openAsModal": "Voir",
					"openAsNewTabOrWindow": "Ouvrir dans une nouvelle fenêtre ou un nouvel onglet"
				}
			}
		},
		"placeholder": {
			"search": "Recherche"
		},
		"detail": {
			"field": "Champ",
			"value": "Valeur"
		},
		"delete": {
			"title": "Supprimer l'enregistrement {{id}} ?",
			"message": "Êtes-vous sûr de vouloir supprimer ?"
		},
		"validation": {
			"required": "Ce champ est obligatoire",
			"minlength": "Ce champ doit contenir au moins {{min}} caractères.",
			"maxlength": "Ce champ ne peut pas contenir plus de {{max}} caractères.",
			"min": "Ce champ doit être supérieur à {{min}}.",
			"max": "Ce champ doit être inférieur à {{max}}.",
			"minbytes": "Ce champ doit contenir plus de {{min}} octets.",
			"maxbytes": "Ce champ ne peut pas dépasser {{max}} octets.",
			"pattern": "Ce champ doit suivre le modèle {{pattern}}.",
			"datetimelocal": "Ce champ doit être une date et une heure.",
			"email": "L'e-mail n'est pas valide",
			"patern": "La valeur n'est pas correcte {{info}}",
			"number": "Ce n'est pas un numéro valide",
			"fieldMatch": "Ne correspond pas",
			"vat": "Le numéro fiscal n'est pas valide"
		},
		"autocomplete": {
			"errorAllowInvalid": "La valeur saisie n'est pas valide",
			"newItem": "Nouveau {{attrName}}",
			"createNewItem": "{{attrName}} n'existe pas."
		},
		"maintenance": {
			"headerSearch": "Rechercher {{entityName}}",
			"headerMaintenance": "Maintenance de {{entityName}}"
		}
	},
	"error": {
		"server": {
			"unavailable": "Le serveur n'est pas accessible.",
			"badRequest": "Le serveur a rejeté votre demande.",
			"unauthorized": "Il n'est pas authentifié.",
			"forbidden": "Vous n'êtes pas autorisé à faire cette demande.",
			"notFound": "Page ou commande introuvable.",
			"internalServerError": "Erreur interne du serveur.",
			"serviceUnavailable": "Le système est en cours de maintenance."
		},
		"invalidForm": "Impossible de sauvegarder, veuillez vérifier les données saisies"
	},
	"toolbar": {
		"year": "Année : {{value}}",
		"month": "Mois : {{value}}",
		"period": "Période : {{value}}"
	},
	"grdescontos": {
		"title_detail": "Groupe de réduction {{id}}",
		"title_new": "Nouveau groupe de réduction",
		"title_plural": "Groupes de réduction",
		"pesquisa": "A chercher",
		"saved": "Groupe de réductions {{id}}, enregistré avec succès",
		"error": "Le groupe de remise {{id}} ne peut pas être enregistré.",
		"deleted": "Groupe de remise {{id}}, supprimé avec succès",
		"fields": {
			"codGrDesconto": "Code",
			"descricao": "Description",
			"categoria": "Catégorie",
			"assiduidadeSegSocial": "Présence par seconde.",
			"absentismo": "Absentéisme",
			"suspensaoContrato": "Suspension du contrat",
			"remunerado": "Payé",
			"recibo": "Reçu",
			"entidadeSuporta": "Entité de soutien",
			"ausencias": "Absences",
			"justificacaoAusencia": "Justificatif d'absence",
			"balancoSocial": "Equilibre social",
			"horasNaoTrabalhadas": "Heures non travaillées",
			"contaDiasFeriasNorm": "Comptabiliser les jours de vacances normaux",
			"contaDiasFeriasAcima": "Tenir compte des jours de vacances au-dessus de la normale",
			"contaDiasSubNatal": "Tenir compte des jours subnatals",
			"contaDiasSubAlimen": "Compter les jours de sous-flux"
		}
	},
	"grabonos": {
		"title_detail": "Groupe d'allocations {{id}}",
		"title_new": "Nouveau groupe d'allocations",
		"title_plural": "Groupes d'allocations",
		"pesquisa": "A chercher",
		"saved": "Groupe d'allocations {{id}}, enregistré avec succès",
		"error": "Le groupe d'allocations {{id}} ne peut pas être enregistré.",
		"deleted": "Groupe d'allocations {{id}}, supprimé avec succès",
		"fields": {
			"codGrAbono": "Code",
			"descricao": "Description",
			"categoria": "Catégorie",
			"assiduidadeSegSocial": "Présence par seconde.",
			"presencas": "Présence",
			"remunerado": "Payé",
			"recibo": "Reçu",
			"entidadeSuporta": "Entité de soutien",
			"tipoTrabalho": "Type de travail",
			"balancoSocial": "Equilibre social",
			"contaDiasFeriasNorm": "Comptabiliser les jours de vacances normaux",
			"contaDiasFeriasAcima": "Tenir compte des jours de vacances au-dessus de la normale",
			"contaDiasSubNatal": "Tenir compte des jours subnatals",
			"contaDiasSubAlimen": "Compter les jours de sous-flux",
			"formacao": "Entraînement"
		}
	},
	"grandesfamilias": {
		"title_detail": "Grande famille {{id}}",
		"title_new": "Nouvelle grande famille",
		"title_plural": "Grandes familles",
		"pesquisa": "A chercher",
		"deleted": "Grande Família {{id}}, supprimée avec succès.",
		"saved": "Big Family {{id}}, enregistré avec succès.",
		"error": "Big Family {{id}}, ne peut pas être enregistré.",
		"fields": {
			"nDepart": "Code.",
			"nDepartPlaceholder": "Grand code familial",
			"nome": "Nom",
			"nomePlaceholder": "Grand nom de famille"
		}
	},
	"gto": {
		"title": "Gestion des tâches et des obligations",
		"tabs": {
			"title": {
				"porEmpresa": "Par entreprise",
				"multiEmpresa": "Multi-entreprises",
				"ativarTarefas": "Activer les tâches",
				"configuracoes": "Paramètres",
				"configuracoesMulti": "Configurations multi-entreprises"
			},
			"filters": {
				"empresa": "Entreprise",
				"tipotarefa": "Type de tâche",
				"responsavel": "Responsable",
				"mostrarfilter": "Montrer",
				"periodoiva": "Période de TVA",
				"regimeirc": "Régime IRC/IRS",
				"nomeTarefa": "Nom de la tâche"
			},
			"table": {
				"fields": {
					"nometarefa": "Nom de la tâche",
					"nempresa": "numéro d'entreprise",
					"sel": "Sel."
				},
				"semdados": "Aucune donnée"
			},
			"btn": {
				"checkstatedmratss": "Vérifier l'état du DMR AT et SS",
				"checkstatedmrss": "Vérifier l'état du DMR SS",
				"checkstatedmrat": "Vérifier l'état du DMR AT",
				"dmrat": "DMR À",
				"dmrss": "DMR SS",
				"alertas": "Alertes",
				"filtroavanc": "Filtre avancé"
			},
			"actions": {
				"executartarefa": "Exécuter la tâche",
				"concluirtarefa": "Tâche terminée",
				"conctarefdentroprazo": "Tâche terminée dans les délais",
				"editartarefa": "Modifier la tâche",
				"desativartarefa": "Désactiver la tâche",
				"estatisticas": "Statistiques"
			}
		},
		"toolbar": {
			"createNewTask": "Créer une tâche",
			"config": "Configurer",
			"copyTasks": "Copier les tâches"
		},
		"nomeTarefa": "Nom de la tâche",
		"tipoTarefa": "Type de tâche",
		"obrigacoes": "Obligations",
		"pagamentos": "Paiements",
		"outra": "Autre",
		"dataInicioPeriodo": "Date de début de période",
		"mes1anterior": "Mois - 1",
		"mes2anterior": "Mois - 2",
		"ano1anterior": "Année - 1",
		"entidadeExterna": "Entité externe",
		"createNewTaskTitle": "Créer une nouvelle tâche",
		"editTaskTitle": "Modifier la tâche",
		"applyAllEmpresas": "Met à jour le calendrier dans toutes les entreprises qui ont cette tâche",
		"tarefa": "Tâche",
		"tarefas": "Tâches",
		"config": "Paramètres",
		"empresa": "Entreprise",
		"ativarGTONaEmpresa": "Activer la gestion des tâches et des obligations",
		"alertasNaApp": "Alertes dans l'application",
		"inicio": "Commencer",
		"fields": {
			"ativar": "Activer",
			"nomeTarefa": "Nom de la tâche",
			"responsavel": "Responsable",
			"nEmpresa": "numéro d'entreprise",
			"nomeEmpresa": "Nom",
			"regimeIRCIRS": "Régime IRC/IRS",
			"periodoIVA": "Période de TVA",
			"sel": "Sel."
		},
		"invalidSelectedDay": "Jour invalide",
		"saveConfig": "Enregistrer la configuration",
		"legends": {
			"concluidoDentroPrazo": "Terminé à temps",
			"guardarValidacao": "En cours de validation",
			"emAtrasoJustificado": "Dans un délai justifié",
			"concluidoForaPrazo": "Terminé après la date limite",
			"emAtraso": "En retard",
			"prazoADecorrer": "Date limite pour courir",
			"naoAplicavel": "Sans objet",
			"pendente": "En attente"
		},
		"datasources": {
			"tipotarefa": {
				"obrigacoes": "Obligations",
				"pagamentos": "Paiements",
				"outra": "Autre",
				"todos": "Tous"
			},
			"mostrarfilter": {
				"datalimite": "Date limite",
				"datalimitePlusNotes": "Date limite et notes",
				"values": "Valeur",
				"valuesPlusNotes": "Valeur et notes",
				"dias": "Jours",
				"diasPlusNotes": "Jours et notes",
				"dataexecucao": "Date d'exécution",
				"dataexecucaoPlusNotes": "Date d'exécution et notes"
			}
		},
		"copyTasksModalTitle": "Copier les tâches",
		"copiar": "Copie",
		"copyFor": "Copier vers",
		"iraSubstituirTarefasNoDestino": "La procédure créera ou remplacera les tâches sur la cible.",
		"iraCriarAtualizarTarefasNoDestino": "La procédure créera ou mettra à jour les tâches sur la cible.",
		"temQueSelecionarUmaTarefa": "Vous devez sélectionner au moins une tâche !",
		"temQueSelecionarUmaEmpresa": "Vous devez sélectionner au moins une entreprise!",
		"tarefasCopiadasComSucesso": "Les tâches sélectionnées ont été copiées avec succès.",
		"paraAno": "Pour l'année",
		"paraEmpresas": "Pour entreprise(s)",
		"naoPodeCopiarParaMesmoAno": "Vous ne pouvez pas copier des tâches sur la même année.",
		"executarTarefa": "Exécuter la tâche",
		"estado": "État",
		"estados": {
			"semAlteracao": "Aucun changement",
			"concluir": "Finition",
			"adicionarJustificacao": "Ajouter une justification du retard",
			"naoAplicavel": "Sans objet",
			"new": "Non terminé",
			"guardarValidacao": "En cours de validation"
		},
		"messages": {
			"taskfromotheruser": "Cette tâche appartient à l'autre personne responsable.",
			"validacaosuccess": "Vérification terminée avec succès !",
			"selempreforcheck": "Sélectionnez les entreprises pour vérifier l’état de l’expédition !",
			"temACertezaQuePertendeConcluirTarefasSelecionadas": "Êtes-vous sûr de vouloir terminer les tâches sélectionnées ?",
			"temACertezaQuePertendeReporTarefas": "Êtes-vous sûr de vouloir réinitialiser les tâches par défaut de la société « {{nCompany}} » à l'année « {{year}} » ?",
			"jaExistemTarefasCriadas": "Il existe déjà des tâches créées pour l'entreprise."
		},
		"alertsModalTitle": "Alertes",
		"valor": "Valeur",
		"justificacao": "Justification",
		"dataExecucao": "Date d'exécution",
		"estaTarefaPertenceAoOutroResponsavel": "Cette tâche appartient à l'autre personne responsable.",
		"operacaoConcluidaComSucesso": "Opération terminée avec succès.",
		"temACertezaQuePertendeApagarEstaTarefa": "Êtes-vous sûr de vouloir supprimer cette tâche ?",
		"tarefaEliminadaComSucesso": "Tâche supprimée avec succès !",
		"licensemodal": {
			"total": "Nombre total d'entreprises : {{total}}",
			"totalConsumed": "Nombre total d'entreprises déjà ajoutées : {{totalConsumed}}",
			"totalToConsume": "Nombre total d'entreprises que vous pouvez encore ajouter : {{totalToConsume}}",
			"license1": "Si vous cliquez sur « Oui », le système ajoutera une nouvelle entreprise à votre licence de gestion de tâches et d'obligations, et il ne sera pas possible d'inverser la situation par la suite.",
			"license2": "À l'heure actuelle, vous pouvez toujours ajouter {{totalToConsume}} entreprises à votre licence de gestion des tâches et des obligations.",
			"license3": "Données de licence de gestion des tâches et des obligations",
			"license4": "Pour le moment, vous ne pouvez pas ajouter de sociétés à votre licence de gestion des tâches et obligations."
		},
		"aSuaLicencaNaoTemGTO": "Votre licence n'a pas de gestion des tâches et obligations.",
		"selectAll": "Tout sélectionner",
		"unSelectAll": "Effacer la sélection",
		"loadingLicenseData": "Chargement des données de licence...",
		"naoTemEmpresasSuficientes": "Vous ne pouvez pas poursuivre l'opération car votre licence ne dispose pas de suffisamment d'entreprises à consommer.<br/><br/>Nombre total d'entreprises que vous pouvez encore ajouter : {{totalToConsume}><br/>Nombre total d'entreprises que vous ajouterez : {{totalToAdd}}",
		"temACertezaQuePretendeAddEmpresas": "Êtes-vous sûr de vouloir ajouter {{nempresas}} entreprise(s) à votre licence de gestion des tâches et obligations ?",
		"hints": {
			"concluidoDentroPrazo": "La tâche a été exécutée le {{dataExec}} (à temps) par {{user}}",
			"concluidoForaPrazo": "La tâche a été exécutée sur {{dataExec}} (hors délai) par {{user}}",
			"emAtrasoJustificado": "La tâche en retard.",
			"valor": "<b>Valeur</b> : {{valeur}}",
			"nota": "<b>Remarque</b> : {{note}}",
			"selecaoMultiplaText": "Appuyez sur Ctrl et cliquez avec le bouton gauche sur un élément pour une sélection non consécutive, ou appuyez sur le bouton gauche de la souris et faites glisser sur les éléments.",
			"inicializaRepoeTarefasPorDefeito": "Initialise/réinitialise les tâches par défaut."
		},
		"selecaoMultipla": "Sélection multiple"
	},
	"helperLinks": {
		"global": {
			"all": "Voir toutes les vidéos",
			"faqs": "Voir les questions fréquemment posées"
		}
	},
	"helptopics": {
		"titles": {
			"tutorialVideos": "Tutoriel - Vidéos",
			"formationsVideos": "Formations - Vidéos",
			"forumCG": "Forum CentralGest",
			"facebookUserGroup": "Groupe d'utilisateurs CentralGest sur Facebook"
		}
	},
	"tiposCategoriasNaoTrabalhadas": {
		"data": {
			"tDhntNA": "À",
			"tDhntPorFormacaoProf": "par Formation Professionnelle",
			"tDhntPorReducaoLegalActividade": "en raison de la Réduction Légale d’Activité",
			"tDhntPorDesempregoInterno": "par Chômage Interne",
			"tDhntPorDescansoSuplementar": "par repos supplémentaire"
		}
	},
	"idadesaldos": {
		"label": {
			"grupoConta": "Groupe de comptes",
			"showGrupoConta": "Afficher le groupe de comptes"
		}
	},
	"idbancos": {
		"title_detail": "Numéro d'identification bancaire {{id}}",
		"title_new": "Nouvelle identification bancaire",
		"title_plural": "Identification bancaire",
		"pesquisa": "chercher",
		"saved": "L'identification bancaire {{id}} a été enregistrée avec succès.",
		"error": "L'ID bancaire {{id}} ne peut pas être enregistré.",
		"deleted": "L'ID bancaire {{id}} a été supprimé avec succès",
		"fields": {
			"idinterBancario": "Code interbancaire",
			"nomeInterBancario": "Nom interbancaire",
			"descricao": "Description",
			"formatoTRFAG": "Format TrfPag",
			"formatoTRFREC": "Format TrfRec",
			"inativo": "Inactif"
		}
	},
	"idide": {
		"title_detail": "Groupe de comptes {{id}}",
		"title_new": "Nouveau groupe de comptes",
		"title_plural": "Groupes de comptes",
		"pesquisa": "A chercher",
		"saved": "Groupe de comptes {{id}}, enregistré avec succès",
		"error": "Le groupe de comptes {{id}} ne peut pas être enregistré.",
		"deleted": "Groupe de comptes {{id}}, supprimé avec succès",
		"fields": {
			"idIdeChav": "Code",
			"descricao": "Description",
			"tipoCred": "Type de crédit",
			"credito1": "Crédit 1",
			"credito2": "Crédit 2",
			"credito3": "Crédit 3",
			"credito4": "Crédit 4",
			"credito5": "Crédit 5"
		}
	},
	"ies": {
		"tabProcess": "Exporter",
		"tabDownload": "Fichier IES-DA",
		"generation": "Génération de fichiers",
		"fileNotProcessed": "Nous sommes désolés, mais pour une raison quelconque, le fichier n'a pas été généré.",
		"jobTimeoutModalTitle": "Tâche",
		"jobTimeoutModalMessage": "La tâche a expiré.",
		"contabilidade": "Comptabilité",
		"faturacao": "Facturation",
		"processar": "Processus",
		"processingMessage": "Traitement en cours, veuillez patienter un instant.",
		"erros": "Erreurs",
		"fileReady": "Le fichier IES-DA est prêt.",
		"promptReprocessTitle": "Il existe déjà un fichier IES-DA généré précédemment",
		"promptReprocessMessage": "Si vous continuez, le système générera à nouveau le fichier.",
		"warningMessage": "Attention: Après avoir démarré le processus, vous disposez de 60 minutes pour télécharger le fichier généré.",
		"promptCancelProcessMessage": "Êtes-vous sûr de vouloir annuler le processus ?",
		"dataCriacao": "Date de création",
		"tamanho": "Taille",
		"utilizador": "Utilisateur",
		"documentos": "Documents",
		"buttons": {
			"reiniciar": "Redémarrage",
			"download": "Télécharger",
			"yes": "Oui",
			"no": "Non",
			"fechar": "Pour fermer"
		},
		"erroDownload": "Une erreur s'est produite lors du téléchargement du fichier!",
		"exportInProcessModalMessageByOtherUser": "Vous ne pouvez pas démarrer le processus d'exportation dans cette entreprise car il a déjà été lancé par l'autre utilisateur",
		"enviarDeclaracao": "Envoyer la déclaration",
		"fields": {
			"anexo": "Annexe",
			"campo": "Champ",
			"descricao": "Description",
			"mensagem": "Message"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
		"leavePromptMessage": "En cliquant sur OUI, vous quitterez et supprimerez toutes les données de traitement."
	},
	"impctrirs": {
		"fields": {
			"deCodEmp": "code employé"
		},
		"btn": {
			"previsualizar": "Aperçu"
		}
	},
	"impde119": {
		"filters": {
			"dedata": "Date de",
			"decodemp": "Employé de",
			"dencontribuinte": "Numéro de contribuable de",
			"filtrarPor": "Filtrer par",
			"empregado": "Collaborateurs",
			"outros": "Fournisseurs de services",
			"ambos": "Les deux"
		}
	},
	"impdecpe": {
		"doPeriodo": "période de",
		"doNCampo": "champ n°",
		"labelCriterios": "Critères de requête"
	},
	"impdoc": {
		"title": "Impression de documents commerciaux",
		"modalTitle": "Document {{nDoc}} - {{clifo}}",
		"label": {
			"emailcliente": "E-mail",
			"enviar": "Pour envoyer",
			"documento": "Document",
			"nvias": "Nombre de voies",
			"segundaVia": "Deuxième exemplaire",
			"rptName": "Rapport"
		},
		"email": {
			"emailEnviado": "E-mail envoyé à {{email}}",
			"emailErro": "Erreur lors de l'envoi de l'e-mail à {{email}}."
		},
		"placeholder": {
			"email": "\"mail@exemple.com\" ou \"mail@exemple.com; mail2@exemple.com\""
		},
		"faturaDigital": {
			"seraEmitidaPor": "La facture électronique sera émise par {{certificate}}",
			"emitida": "Document avec facture électronique émise",
			"clienteSemFatDigital": "Client sans facture électronique",
			"tipoDocSaftNaoConfigurada": "Type de document SAF-T (FT,FR,ND,NC) non configuré pour émettre une facture électronique",
			"semConfiguracao": "Aucune facture électronique configurée",
			"hintSemConfiguracao": "Produit soumis à licence, retrouvez les informations sur www.centralgestcloud.com"
		},
		"btn": {
			"faturacaoPublica": "Facturation publique"
		},
		"tooltip": {
			"avisoEnviar": "Vous ne pouvez envoyer que des documents terminés"
		}
	},
	"impencar": {
		"fields": {
			"dataDe": "Date de",
			"codEmpDe": "code employé",
			"codEncargoDe": "Code de facturation"
		},
		"btn": {
			"previsualizar": "Aperçu"
		},
		"infos": {
			"tooltipExcepcoesProcessamento": "Traitement des exceptions"
		}
	},
	"impfferpoc": {
		"btn": {
			"previsualizar": "Aperçu"
		},
		"filtropesq": "Recherche avancée",
		"fields": {
			"dataProcessamento": "Date de traitement"
		}
	},
	"importadorEfatura": {
		"configPreDefinidosTitle": "Configuration de l'entité",
		"showLastDocs": "Afficher les derniers documents",
		"hideLastDocs": "Masquer les derniers documents",
		"importarEmSerieModalTitle": "Lancement de documents en série...",
		"importarEmSerieModalMessage": "Lancement de documents en série.",
		"definirPredefinidoModalTitle": "Définir des préréglages",
		"cantSelectRowDueInvalidPredefinido": "Vous ne pouvez pas sélectionner l'enregistrement car il n'est pas prédéfini.",
		"fields": {
			"nifEmitente": "FNI",
			"nomeEmitente": "Nom",
			"numerodocumento": "Doc n°",
			"dataEmissaoDocumento": "Date d'émission",
			"actividadeDesc": "Activité",
			"tipoDocumentoDesc": "Taper",
			"fornecedor": "Fournisseur",
			"registadoPor": "Enregistré par",
			"actividadeProfDesc": "Champ d'activité",
			"valorTotalBaseTributavel": "Base imposable",
			"valorTotalIva": "Valeur TVA",
			"valorTotal": "Valeur totale",
			"preDefinido": "Prédéfini",
			"baseTributavel": "Base imposable",
			"taxa": "Base tarifaire",
			"impostoSelo": "Taxe de timbre",
			"total": "Valeur totale"
		},
		"configs": {
			"showColumnActividade": "Afficher la colonne <Activité>",
			"showColumnAmbitoActividade": "Afficher la colonne <Portée de l'activité>",
			"showColumnRegistadoPor": "Afficher la colonne <Enregistré par>"
		},
		"itemShowDetalhesToolbarBtn": "Afficher les détails",
		"itemHideDetalhesToolbarBtn": "Masquer les détails",
		"docSearchTabCaption": "Recherche de documents",
		"criarDoc": "Créer un document",
		"actions": {
			"configSupplier": "Configurer le fournisseur",
			"newAccount": "Générer un nouveau compte courant",
			"extratosConta": "Relevés de compte"
		},
		"modal": {
			"createNewContaCorrente": {
				"title": "Créer un nouveau compte courant",
				"confirmModal": {
					"confirmTitle": "Voir le profil du fournisseur",
					"confirmMessage": "Compte courant fournisseur <strong>créé avec succès</strong>.<br/>Voulez-vous consulter la fiche fournisseur créée ?"
				},
				"body": {
					"radical": "Radical",
					"ncontacriar": "Numéro de compte à créer",
					"nome": "Nom",
					"contrib": "Cotisation n°"
				}
			}
		},
		"tooltip": {
			"criarNovaContaCorrente": "Créer un nouveau compte courant",
			"extratoCorrente": "Relevés de compte"
		},
		"warningImportarEmSerieContabilidadeDigital": "Vous avez choisi de déposer des documents en série dans une entreprise disposant d'archives numériques.<br/>Le système joindra automatiquement les documents numériques proposés, veuillez vérifier qu'ils sont correctement classés.<br/>Etes-vous sûr de vouloir continuer ?",
		"docDigital": {
			"0": "Aucun document possible",
			"1": "Avec un éventuel document",
			"2": "Avec plusieurs documents possibles",
			"naoTem": "Aucun document possible",
			"tem": "Avec un éventuel document",
			"temSemelhante": "Avec plusieurs documents possibles"
		},
		"atMessagePrefix": "Le portail financier (AT) a renvoyé le message suivant"
	},
	"importadorSaftDirect": {
		"strings": {
			"modalTitleEdit": "Modifier",
			"modalSave": "Appliquer",
			"modalCancel": "Annuler",
			"correspContasContaSaftLabel": "Compte SAFT",
			"correspContasContaCGLabel": "Compte CentralGest",
			"taxasIvaSaftLabel": "Frais SAFT",
			"taxasIvaCGLabel": "Frais CentralGest",
			"artigosConfigModalTitle": "Configuration des articles",
			"artigosNewConfigModalTitle": "Configuration de nouveaux articles",
			"correspCCAssociarModalTitle": "Associer un compte courant",
			"modalAssociar": "Associé",
			"modalAttribuir": "Attribuer",
			"configArtigosSaftLabel": "Articles SAFT",
			"configArtigosCGLabel": "Données pour créer un compte base fiscale CentralGest",
			"jobTimeoutModalTitle": "Tâche",
			"jobTimeoutModalMessage": "La tâche a expiré.",
			"verContasFormadasPeloArtigo": "Voir les comptes d'assiette fiscale à constituer par article",
			"verGenDoc": "Afficher le document",
			"applyProductConfig": "Application de la configuration...",
			"savingProductsConfig": "Application de la configuration de l'article...",
			"noDocsPaymentsToImports": "Les pièces contenues dans le fichier SAFT ont déjà été importées en comptabilité précédemment",
			"timeoutModalMessage": "Nous sommes désolés, mais le délai d'importation du fichier SAF-T PT a expiré.",
			"porTipoDeDocumento": "Centre de coûts par type de document",
			"porTipoDocumentoReparticao": "Répartition par type de document",
			"porCliente": "Centre de coûts par client",
			"porClienteReparticao": "Répartition par client",
			"porArtigo": "Centre de coûts par article",
			"porArtigoReparticao": "Répartition par article"
		},
		"steps": {
			"common": {
				"semDados": "Aucune donnée à afficher !",
				"semValoresImportar": "Vous devez sélectionner au moins une des valeurs à importer"
			},
			"welcome": {
				"wizardCaption": "Accueillir",
				"contentText1": "Bienvenue dans l'assistant de facturation importateur SAF-T PT pour la comptabilité directe.",
				"contentText2": "Cet assistant vous guidera tout au long du processus d'importation.",
				"contentText3": "Cliquez sur \"Suivant\" pour continuer l'importation.",
				"contentText4": "Après avoir démarré le processus, vous disposerez de 2 heures pour terminer l’importation.",
				"uploadLimit": "Votre forfait actuel vous permet de traiter des fichiers jusqu'à 20 Mo.",
				"uploadLimitInfo": "Le traitement de fichiers de plus de 20 Mo n'est autorisé qu'après 18 heures chaque jour.",
				"uploadLimitPremium": "Votre forfait actuel vous permet de traiter des fichiers jusqu'à 20 Mo.",
				"uploadLimitPremiumExtended": "Votre forfait actuel vous permet de traiter des fichiers jusqu'à 50 Mo."
			},
			"escolhaTipoImportacao": {
				"wizardCaption": "Type d'importation",
				"text1": "Veuillez choisir le type d'importation que vous souhaitez effectuer",
				"text2": "Si vous choisissez d'importer CentralGest Cloud, veuillez demander à votre client de créer un utilisateur sur la plateforme cloud CentralGest.",
				"tiposImportacao": {
					"title": "Type d'importation",
					"ficheiroSaft": "Fichier de facturation SAF-T",
					"centralGestCloud": "CentralGest Cloud",
					"centralGestPOS": "Zone de point de vente logicielle",
					"centralGestPOSWinTouch": "PLV Wintouch"
				}
			},
			"upload": {
				"wizardCaption": "Fichier SAFT",
				"title": "Veuillez choisir le fichier que vous souhaitez importer",
				"processarFicheiroLabel": "Traitement du fichier SAF-T...",
				"cancel": "Annuler"
			},
			"processaFileCloud": {
				"wizardCaption": "CentralGest Cloud",
				"text1": "Veuillez remplir les informations suivantes",
				"periodo": "Période",
				"user": "Utilisateur",
				"password": "Mot de passe",
				"valoresAImportar": "Valeurs à importer",
				"importarVendas": "Ventes à l'importation",
				"importarRecibos": "Reçus d'importation",
				"importarCompras": "Importer des achats",
				"importarPagamentos": "Paiements à l'importation",
				"processarNaCentralGestCloud": "Traitement..."
			},
			"processaFilePOS": {
				"wizardCaption": "Zone de point de vente logicielle",
				"text1": "Veuillez remplir les informations suivantes",
				"periodo": "Période",
				"user": "Utilisateur",
				"password": "Mot de passe",
				"valoresAImportar": "Valeurs à importer",
				"importarVendas": "Ventes à l'importation",
				"importarRecibos": "Reçus d'importation",
				"importarCompras": "Importer des achats",
				"importarPagamentos": "Paiements à l'importation",
				"processarNaCentralGestPOS": "Traitement...",
				"contactPOS": "Veuillez contacter notre service commercial pour connaître cette fonctionnalité.",
				"emailSubject": "Demande d'informations sur l'import de données POS Zone Soft",
				"emailComercial": "comercial@centralgest.com"
			},
			"processaFilePOSWinTouch": {
				"wizardCaption": "PLV Wintouch",
				"text1": "Veuillez remplir les informations suivantes",
				"periodo": "Période",
				"user": "Utilisateur",
				"password": "Mot de passe",
				"valoresAImportar": "Valeurs à importer",
				"importarVendas": "Ventes à l'importation",
				"importarRecibos": "Reçus d'importation",
				"importarCompras": "Importer des achats",
				"importarPagamentos": "Paiements à l'importation",
				"processarNaCentralGestPOS": "Traitement...",
				"contactPOS": "Veuillez contacter notre service commercial pour connaître cette fonctionnalité.",
				"emailSubject": "Demande d'informations sur l'importation de données Wintouch POS",
				"emailComercial": "comercial@centralgest.com"
			},
			"viewDocsToImport": {
				"wizardCaption": "Documents",
				"title": "Documents et reçus à importer",
				"helpText": "Veuillez vérifier les documents et reçus qui seront importés",
				"valoresNoFicheiroSAFT": "Valeurs dans le fichier SAF-T",
				"nTotalFaturas": "Nombre de factures",
				"totalBaseTributavel": "Assiette fiscale totale",
				"totalIva": "TVA totale",
				"legendError": "Documents d'erreur",
				"legendWarning": "Documents avec différence de totaux corrigés automatiquement",
				"validatorErrorMessage": "Il existe des documents comportant des erreurs.",
				"showDocsWithErrorOrWarningOnly": "Afficher uniquement les documents contenant des avertissements ou des erreurs",
				"tabDocsTitle": "Documents",
				"tabPaymentsTitle": "Recettes",
				"existemDocsComErroDesejaContinuar": "Il existe des documents comportant des erreurs."
			},
			"viewRecibosToImport": {
				"legendError": "Reçus d'erreur",
				"legendWarning": "Reçus avec différence de totaux corrigés automatiquement",
				"showRecibosWithErrorOrWarningOnly": "Afficher uniquement les reçus contenant des avertissements ou des erreurs",
				"nTotalRecibos": "Nombre de reçus",
				"totalCredito": "Total à crédit",
				"totalDebito": "Débit total"
			},
			"cfgTiposDocs": {
				"wizardCaption": "Types de documents",
				"title": "Configuration des types de documents",
				"analiticaTitle": "Analytique",
				"usaAnalitica": "Utiliser les analyses",
				"criaDiariosEDescritivos": "Crée et attribue automatiquement des journaux et des transcriptions à de nouveaux types de documents",
				"mascaraLabel": "Masque analytique",
				"configMascaraAnalitica": "Configuration des analyses"
			},
			"cfgMercados": {
				"wizardCaption": "Marchés",
				"title": "Mise en place de marchés",
				"helpText": "Merci d'indiquer le montant qui servira à constituer les comptes courants et les comptes imposables selon le marché.",
				"valorMercadoNacionalLabel": "Caractère de marché national pour la création de comptes",
				"valorOutrosMercadosLabel": "Caractériser d’autres marchés pour la création de comptes",
				"valorMercadoComunitarioLabel": "Caractère de marché communautaire pour la création de comptes"
			},
			"contasCorrentes": {
				"wizardCaption": "Comptes courants",
				"title": "Constitution de comptes courants",
				"helpText": "Veuillez indiquer comment vous souhaitez que les comptes courants soient créés.",
				"tipoFormacaoContaTitle": "Création de compte",
				"criterioFormacaoTitle": "Critères de création de compte",
				"radicalLabel": "Radical",
				"nDigitosLabel": "Nombre de chiffres",
				"nDigitosHelperText1": "(nombre de chiffres dans la séquence)",
				"nDigitosHelperText2": "(comprend le chiffre du marché + la séquence)",
				"nContaDiversos": "Compte",
				"usaDigitosIdentExterna": "Utiliser le nombre de chiffres dans l'identification externe"
			},
			"correspContasCorrentes": {
				"wizardCaption": "Appariement des comptes courants",
				"title": "Appariement des comptes courants",
				"helpText": "Le système propose automatiquement un numéro de compte en fonction des paramètres de création de compte courant précédemment saisis.",
				"filterLabel": "Filtre",
				"selectAll": "Tout sélectionner",
				"clearAll": "Effacer la sélection",
				"associarContaCorrente": "Associer un compte courant",
				"removerAssociarContaCorrente": "Supprimer l'association c.",
				"mostrarContasComErro": "Afficher les comptes avec des erreurs",
				"mostrarNIFInvalid": "Afficher les NIF invalides",
				"pbSaveText": "Sauvegarde de la correspondance des comptes courants...",
				"pbLoadCorrespTaxas": "Chargement de la correspondance du taux de TVA...",
				"atribuirCCusto": "Centre de coûts associé",
				"removerCCusto": "Supprimer l'association de centres de coûts",
				"atribuirRepCC": "Répartition des centres de coûts associés",
				"removerRepCC": "Supprimer l'association de répartition des centres de coûts",
				"selecionados": "Avec sélectionné..."
			},
			"correspTaxasIva": {
				"wizardCaption": "Taux de TVA",
				"title": "Taux de TVA correspondants",
				"helpText": "Merci d'indiquer le taux de TVA correspondant dans CentralGest au taux de TVA du fichier SAFT."
			},
			"cfgArtigos": {
				"wizardCaption": "Articles",
				"title": "Configuration des articles",
				"helpText": "Veuillez indiquer le compte CentralGest correspondant par article.",
				"selectAll": "Tout sélectionner",
				"selectNews": "Sélectionnez nouveau",
				"clearAll": "Effacer la sélection",
				"configArtigosSelected": "Configurer les articles sélectionnés",
				"configTodosArtigosTipoProduto": "Configurer tous les éléments du type de produit",
				"configTodosArtigosTipoServicos": "Configurer tous les articles de type de services",
				"configTodosArtigosTipoOutros": "Configurer tous les articles de type autres",
				"configTodosArtigosTipoImpostosTaxasEncargosParafiscais": "Configurer tous les postes de type taxes, frais et taxes parafiscales",
				"configTodosArtigosTipoImpostosEspeciaisConsumo": "Configurer tous les éléments de type accise",
				"toolbarRefresh": "Cool",
				"toolbarMostrarApenasComErro": "Afficher uniquement les articles contenant des erreurs",
				"toolbarPlanoContas": "Plan comptable",
				"filterLabel": "Filtre"
			},
			"dataDocs": {
				"wizardCaption": "Dates des documents",
				"title": "Dates des documents",
				"dataLancContabTitle": "Date des écritures comptables"
			},
			"genDocsContab": {
				"wizardCaption": "Documents créés",
				"title": "Documents comptables créés",
				"legendError": "Documents d'erreur",
				"legendWarning": "Documents dont la valeur d'arrondi est supérieure à 1 €",
				"valoresNoFicheiroSAFT": "Valeurs du fichier SAFT",
				"valoresCG": "Valeurs CentralGest",
				"selectAll": "Tout sélectionner",
				"clearAll": "Effacer la sélection",
				"viewDoc": "Afficher le document",
				"deleteDocSelected": "Supprimer les documents sélectionnés",
				"toolbarMostrarApenasComErro": "Afficher uniquement les documents comportant des erreurs",
				"gravarDocsMessage": "Enregistrement de documents"
			}
		},
		"messages": {
			"temACertezaQuePertendeContinuar": "Êtes-vous sûr de vouloir continuer à fonctionner ?",
			"obgEscolherTipoImportacao": "Vous devez sélectionner un type d'importation pour continuer",
			"noFiles": "Sélectionnez le fichier à traiter...",
			"errorProcessFile": "Une erreur inattendue s'est produite lors du traitement du fichier SAFT.",
			"importInProcessModalTitle": "SAF-T PT Importateur",
			"importInProcessModalMessageByOtherUser": "Vous ne pouvez pas démarrer le processus d'importation dans cette entreprise car il a déjà été lancé par l'autre utilisateur",
			"importInProcessModalMessageCancelJob": "Le processus d'importation a déjà commencé.",
			"importInProcessModalLoadProcess": "Processus de chargement",
			"importInProcessModalCancelProcess": "Annuler le processus",
			"jobCancelled": "Le processus d'importation a été annulé avec succès.",
			"nContaDestinoDiffNContrib": "Il n'est pas possible d'attribuer le compte \"{{conta}}\" à l'identification externe \"{{customerID}}\" car le contributeur des comptes est différent.",
			"contasCorrentesNoSelection": "Vous devez sélectionner au moins une ligne",
			"datasDocumentosDataLancInvalid": "La date \"{{date}}\" doit appartenir à l'année \"{{year}}\" et au mois \"{{month}}\"",
			"configTiposDocsTemSelLancaCaixaOuLancaCC": "Vous devez sélectionner au moins une option: Lancer cash ou lancer c.",
			"configTiposDocsTemIndicarContaCaixa": "Vous devez indiquer un compte espèces",
			"SoPodeAgruparDocsTipoFaturaSimple": "Vous ne pouvez regrouper que les documents du type Facture|Facture simplifiée|Reçu de facture|Vente au comptant|Reçu de vente.",
			"configFormacaoContasNContaDiversosInvalid": "Le Client/Fournisseur n'existe pas!",
			"configFormacaoContasRadicalInvalid": "Vous devez indiquer un radical pour constituer des comptes courants.",
			"configFormacaoContasNDigitosInvalid": "Vous devez indiquer le nombre de chiffres pour constituer des comptes courants.",
			"correspTaxasIvaCodIvaInvalid": "Vous devez définir un Code TVA pour le taux de TVA SAFT \"{{rate}}\"",
			"correspTaxasIvaDigitosIvaEmpty": "Vous devez définir les chiffres de TVA pour le taux de TVA SAFT \"{{rate}}\"",
			"finalizedMessageModal": "Opération terminée avec succès.",
			"finalizedCloseModal": "Pour fermer",
			"importHaveErrorsOrWarningsModalMessage": "Il existe des documents contenant des avertissements ou des erreurs dans le fichier SAFT.",
			"correspCCHaveInvalidNifsModalMessage": "Il existe des comptes courants avec des numéros de contribuable invalides.",
			"temIndicarMascaraAnalitica": "Vous devez spécifier un masque d'analyse.",
			"temIndicarCentroCusto": "Vous devez indiquer un centre de coûts",
			"temIndicarCodRepCC": "Vous devez indiquer un code de répartition par centre de coûts"
		},
		"fields": {
			"jaImportado": "Importé",
			"invoiceNo": "Numéro de facture",
			"invoiceTypeStr": "Taper",
			"totalBase": "Valeur de base",
			"totalIVA": "Valeur TVA",
			"invoice": "Série",
			"nDiario": "Tous les jours",
			"descritivo": "Descriptif",
			"lancaCaixa": "Boîte de lancement",
			"contaCaixa": "Compte espèces",
			"lancaContaCorrente": "Lance c.",
			"nCCusto": "C. Coût",
			"agrupamentoDocumentos": "Documents de groupe",
			"tipoRetencao": "Type de rétention",
			"loja": "Magasin",
			"isNew": "Nouveau",
			"customerID": "ID externe",
			"customerTaxID": "Numéro de contribuable",
			"name": "Nom",
			"country": "Pays",
			"mercado": "Marché",
			"jaExiste": "Il y a",
			"contaDestino": "Numéro de compte suggéré",
			"codRetencao": "Code de rétention",
			"nomeContaCG": "Nom du compte CentralGest",
			"taxCountryRegion": "Région",
			"taxCode": "Taper",
			"taxPercentage": "Taux",
			"codIva": "Code TVA",
			"taxaIva": "Taux",
			"motivoTaxa": "Motif de l'exonération",
			"digitosIva": "Caractères TVA pour la constitution du compte",
			"productCode": "Article",
			"productDescription": "Nom",
			"productType": "Taper",
			"nConta": "Factures de compte",
			"nContaNC": "Compte avoir",
			"acresceDigMercado": "Ajoute du caractère au marché",
			"acresceDigIVA": "Ajouter des caractères TVA",
			"retencao": "Rétention",
			"dataUltimoDoc": "Dernier doc.",
			"dataLancamento": "Date de sortie",
			"select": "Sélection",
			"nDocumento": "Numéro du document",
			"invoices": "Numéro de facture",
			"valorBase": "Valeur de base",
			"valorIVA": "Valeur TVA",
			"valor": "Valeur",
			"valorBaseCG": "Valeur de base du CG",
			"valorIVACG": "Valeur TVA CG",
			"valorCG": "Valeur CG",
			"valorArredBase": "Arrêté.",
			"valorArredIVA": "Arrêté.",
			"paymentRefNo": "Numéro de reçu",
			"nomeCliente": "Client",
			"valorLinhasDebito": "Montant du débit",
			"valorLinhasCredito": "Valeur du crédit",
			"importa": "C'est important",
			"nContaClassificada": "Numéro de compte classifié",
			"contaFormadaDigitosMercado": "Caractère du marché",
			"contaFormadaDigitosIVA": "Caractères TVA",
			"nContaFormada": "N° de compte formé",
			"contaFormadaNome": "Nom",
			"selectNode": "Sel.",
			"settlementAmount": "Rabais",
			"contaDescRecibo": "Reçus d'escomptes sur compte",
			"anulado": "Annulé",
			"nCodRepCC": "Panne"
		},
		"configMascaraAnalitica": {
			"modalTitle": "Configuration des analyses",
			"comoPretendeAtribuirAnalitica": "Comment souhaitez-vous attribuer des analyses"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
		"leavePromptMessage": "En cliquant sur OUI, vous quitterez et supprimerez toutes les données de traitement."
	},
	"importxls": {
		"headerinfo": "Cette application vous permet d'importer différentes informations dans le logiciel CentralGest.",
		"fields": {
			"descricao": "Importer",
			"fichasClientes": "Importation des fiches clients",
			"fichasFornecedores": "Importation des fiches fournisseurs",
			"artigos": "Importation d'articles",
			"contasCorrentesClientes": "Importer les comptes courants clients",
			"contasCorrentesFornecedores": "Importer les comptes courants fournisseurs"
		}
	},
	"indicefluxos": {
		"title_detail": "Flux d'index {{id}}",
		"title_new": "Nouveaux flux d’index",
		"title_plural": "Indices de flux",
		"pesquisa": "A chercher",
		"saved": "Index de flux {{id}}, enregistré avec succès",
		"error": "Index des flux {{id}}, ne peut pas être enregistré.",
		"fields": {
			"indice": "Indice",
			"indicePlaceholder": "Indice de débit",
			"tipo": "Taper",
			"tipoPlaceholder": "Type d'indice de flux",
			"nome": "Nom",
			"nomePlaceholder": "Nom de l'index du flux"
		}
	},
	"integracaoSalarios": {
		"tabIntegrarPorEmpregadoCaption": "Intégrer par employé",
		"tabIntegrarPorProcessamentoCaption": "Intégrer par traitement",
		"tabRemoverIntegracao": "Supprimer l'intégration",
		"selectAll": "Tout sélectionner",
		"unSelectAll": "Effacer la sélection",
		"config": "Configurer",
		"labelTodosProcs": "Tous types de traitement",
		"dataDe": "Date de traitement",
		"codEmpDe": "De l'employé",
		"configModalTitle": "Configurer l'intégration salariale",
		"configModalTipoIntegTabCaption": "Type d'intégration",
		"configModalConfigAcertosSegSocTabCaption": "Configuration.",
		"configModalTipoIntegRadioGroupLabel": "Résultat de l'intégration en comptabilité",
		"tipoIntegUmDocPorEmpregado": "Un document par salarié",
		"tipoIntegUmDocPorProcessamento": "Un document par traitement",
		"diarioLabel": "Tous les jours",
		"descritivoLabel": "Descriptif",
		"codEstadoLabel": "Code d'état",
		"contasContrapartidaAcertoSSTitle": "Comptes de compensation de règlement de la sécurité sociale",
		"contaGastoAcertoLabel": "Compte de dépenses de régularisation",
		"contaRendimentoAcertoLabel": "Toucher le compte de revenus",
		"processMessage": "Traitement en cours... veuillez patienter un moment.",
		"jobTimeoutModalTitle": "Tâche",
		"jobTimeoutModalMessage": "Nous sommes désolés, mais la tâche a expiré.",
		"integrarPorEmpregadoMainTitle": "Intégrer les salaires par employé",
		"integrarPorProcessamentoMainTitle": "Intégrer les salaires de transformation",
		"descricaoLabel": "Description",
		"simularInteg": "Simuler l'intégration",
		"execInteg": "Exécuter l'intégration",
		"removeInteg": "Supprimer l'intégration",
		"nDocExtLabel": "Doc n°",
		"canceladoPeloUtiliz": "Opération annulée par l'utilisateur.",
		"processDoneModalTitle": "Intégration salariale",
		"processDoneModalMsg": "L'intégration salariale est terminée.",
		"simulatorModalTitle": "Simulation d'intégration salariale : {{entreprise}}",
		"integModalTitle": "Intégration salariale : {{entreprise}}",
		"totalDebitoLabel": "Dette totale",
		"totalCreditoLabel": "Crédit complet",
		"onlyErrorsLabel": "Seulement avec des erreurs",
		"integRemovidaComSucesso": "L'intégration salariale a été supprimée avec succès.",
		"integrar": "Intégrer",
		"temSelecionarRegistos": "Vous devez sélectionner des enregistrements.",
		"visualizarErros": "Voulez-vous voir les erreurs?",
		"errosIntegSalariosModalTitle": "Erreurs d’intégration salariale",
		"verErrosModal": "Afficher les erreurs",
		"verSimuladorModal": "Voir le simulateur",
		"fields": {
			"selected": "Sel",
			"nProcessamento": "Processus n°",
			"nomeProcessamento": "Description",
			"tipoProcessamento": "Taper",
			"tipoProcessamentoDescricao": "Type de description.",
			"totalVencAbonos": "Total des allocations",
			"totalVencDescontos": "Remises complètes",
			"totalEncargos": "Frais totaux",
			"codEmp": "code emp.",
			"nomeEmpregado": "Nom",
			"nConta": "Numéro de compte",
			"periodo": "Période",
			"nDiario": "Numéro quotidien",
			"nDescritivo": "Numéro descriptif.",
			"nDocInterno": "Doc n°",
			"nDocExterno": "Doc n°",
			"descricao": "Description",
			"dataDoc": "Date du document.",
			"dataValor": "Date de valeur",
			"dataVencimento": "Date d'expiration",
			"valor": "Valeur",
			"nContaCredito": "Numéro de compte de crédit",
			"nContaDebito": "Numéro de compte débiteur",
			"nomeConta": "Nom du compte",
			"integId": "Intégration",
			"cambio": "Échange",
			"codMoeda": "Code devise",
			"nCCusto": "N° C. Coût",
			"nContrib": "FNI",
			"nRefProcesso": "Réf.",
			"contaOrigem": "Compte d'origine",
			"mascaraOrigem": "Masque d'origine",
			"valorME": "Valeur ME",
			"tipoVencimentosEncargos": "Taper",
			"nCodAbdesc": "Allocation / Remise"
		}
	},
	"integracaoSalariosMulti": {
		"promptViewResultsTitle": "Intégration salariale (Multi-entreprises)",
		"promptViewResultsMessage": "L’intégration salariale est terminée.",
		"selMesIntegrarTitle": "Sélectionnez le mois",
		"viewResultBtn": "Voir",
		"initNewProc": "Commencer",
		"jobTimeoutModalTitle": "Tâche",
		"jobTimeoutModalMessage": "La tâche a expiré.",
		"comecar": "Pour commencer",
		"temSeleccionarUmaEmpresa": "Vous devez sélectionner au moins une entreprise.",
		"tabIntroCaption": "Introduction",
		"tabIntroTitle": "Bienvenue dans l'assistant d'intégration salariale multi-entreprises.",
		"tabIntroSubTitle": "Cet assistant vous guidera dans le processus d'intégration automatique des salaires de toutes les entreprises sélectionnées et avec les paramètres correctement configurés.",
		"tabIntroSubTitle2": "A la fin, on vous montre les documents qui ont été saisis en Comptabilité.",
		"tabIntroSubTitle3": "Cliquez sur \"Suivant\" pour continuer.",
		"tabEmpresasCaption": "Entreprises",
		"tabProcCaption": "Traitement",
		"tabResultsCaption": "Rapport",
		"configLabel": "Paramètres",
		"fields": {
			"selected": "Sel.,",
			"nEmpresa": "Entreprise",
			"nomeEmpresa": "Nom de l'entreprise",
			"codEmpresa": "numéro d'entreprise",
			"nDocInterno": "Doc n°",
			"descricaoErro": "Rapport"
		}
	},
	"intfac": {
		"title": {
			"vendas": "Ventes",
			"compras": "Achats",
			"selectAll": "Tout sélectionner",
			"docnaointcontab": "Documents non intégrés en comptabilité"
		},
		"btn": {
			"encerrar": "Fermer",
			"pesquisar": "A chercher",
			"editDoc": "Modifier une pièce comptable",
			"simular": "Simuler"
		},
		"tooltip": {
			"doccomerro": "Document avec erreur."
		},
		"table": {
			"selected": "Sel.",
			"nDoc": "Document",
			"dataDoc": "Date du document",
			"dataVenci": "Date d'expiration",
			"estado": "État",
			"nConta": "Compte",
			"nDocExt": "Doc n°",
			"nDocumentoFacturacao": "Numéro du document de facturation",
			"periodo": "Période",
			"nDiario": "Tous les jours",
			"nDocInterno": "Numéro du document",
			"nContaDebito": "Dette",
			"nContaCredito": "Crédit",
			"valor": "Valeur",
			"nomeConta": "Nom",
			"codMoeda": "Code devise",
			"valorME": "Valeur ME",
			"valorTaxa": "Valeur des frais",
			"valorTaxaME": "Valeur des frais ME",
			"codIva": "Code TVA",
			"valorRetencao": "Valeur de rétention",
			"nContrib": "Numéro de contribuable",
			"dataDocExterno": "Date du document.",
			"codIntegra": "Code d'intégration",
			"descricao": "Code descriptif."
		},
		"tooltips": {
			"cantBtnPesquisar": "Sélectionnez au moins un type de document à rechercher",
			"cantBtnEncerrar": "Sélectionnez au moins un document à fermer"
		},
		"messages": {
			"successfullySaved": "Terminé avec succès.",
			"docscomerro": "Les documents sélectionnés contiennent des erreurs.",
			"semgruposelecionado": "Sélectionnez les types de documents.",
			"dataNotFound": "Les types sélectionnés ne contiennent pas de documents à fermer.",
			"simErro": "Erreur lors du traitement de la simulation."
		},
		"errors": {
			"integradoMasComErro": "Intégré mais avec erreur."
		},
		"modal": {
			"title": "Résultats des simulations",
			"legend": {
				"legendErro": "Avec erreur"
			}
		}
	},
	"invec": {
		"pesquisa": "A chercher",
		"messages": {
			"reprocessaSuccess": "Inventaire retraité avec succès!",
			"errorName": "Le nom de l'inventaire doit être renseigné.",
			"saveSuccess": "Inventaire enregistré avec succès !",
			"deleteSuccess": "Inventaire supprimé avec succès !",
			"revalorizaSuccess": "Inventaire réévalué avec succès !",
			"deletePrompt": "Êtes-vous sûr de vouloir supprimer l'inventaire ?",
			"sistemaNaoDevolveuFicheiro": "Le système n'a pas renvoyé le fichier.",
			"dataRequired": "La date est obligatoire"
		},
		"fields": {
			"nome": "Nom",
			"descricao": "Description",
			"deNArmazem": "Entrepôt",
			"deTipoArtigo": "Type d'article",
			"deGrandeFamilia": "Grande famille de",
			"deFamilia": "famille de",
			"deSubFamilia": "Sous.",
			"deClasse": "Classe de",
			"deCategoria": "Catégorie de",
			"deValorimetria": "Valorisation de",
			"deNArtigo": "Article par",
			"stockNulo": "Stock nul",
			"desativados": "Désactivé",
			"porLote": "Par lot",
			"dataRef": "Date de référence"
		},
		"valorizar": {
			"modal": {
				"title": "Stocks - Valorisation",
				"valorizacao": "Évaluation"
			}
		},
		"btn": {
			"carregarDados": "Charger des données",
			"save": "Sauvegarder",
			"mostrar": "Montrer",
			"exportAT": "Exporter/Communiquer l’inventaire AT",
			"exportATHint": "Exporter le fichier inv.",
			"retroceder": "Rembobiner",
			"pesquisarArtigos": "Rechercher des articles",
			"criar": "Créer un inventaire",
			"ficheiroCSV": "Fichier CSV",
			"ficheiroXML": "Fichier XML"
		},
		"newModalTitle": "Enregistrer l'inventaire",
		"pesquisarArtigosTooltip": "Rechercher des articles",
		"criarTooltip": "Créer un inventaire avec des éléments de table",
		"options": {
			"showTipoArtigo": "Afficher le type d'article",
			"showGrandeFamilia": "Montrer une grande famille",
			"showSubFamilia": "Afficher le sous.",
			"showClasse": "Afficher la classe",
			"showCategoria": "Afficher la catégorie",
			"showValorimetria": "Afficher l'évaluation"
		}
	},
	"inven": {
		"fields": {
			"nArtigo": "Code article",
			"nomeArtigo": "Nom de l'article",
			"nomeArmazem": "Nom de l'entrepôt",
			"nArmazem": "Stockage",
			"lote": "Lot",
			"qtd": "Montant",
			"precoValor": "Prix ​​unitaire",
			"valor": "Valeur",
			"nLoteEspecifico": "Lot spécifique"
		},
		"filename": "Stock_inventaire",
		"tooltips": {
			"fichaArtigo": "Fiche article",
			"extratoArtigo": "Extrait d'article"
		}
	},
	"inventarios": {
		"title": "Inventaires",
		"print": {
			"modalTitle": "Imprimer l'inventaire"
		}
	},
	"inversaoSujPassivo": {
		"pesquisa": "Types d'inversion de sujet passive",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"notApplicable": "N / A",
			"withInversion": "Avec inversion",
			"noInversion": "Pas d'inversion"
		}
	},
	"invoiceType": {
		"fields": {
			"None": "Inconnu",
			"Fatura": "Facture",
			"FaturaSimplificada": "Facture simplifiée",
			"FaturaRecibo": "Reçu de facture",
			"NotaDebito": "Note de débit",
			"NotaCredito": "note de crédit",
			"VendaDinheiro": "Vendre au comptant",
			"TalaoVenda": "Bon de vente",
			"TalaoDevolucao": "Bon de retour",
			"AlienacaoAtivos": "Cession d'actifs",
			"DevolucaoAtivos": "Restitution des actifs",
			"Premio": "Prix ​​ou reçu du prix",
			"Estorno": "Reçu de rétrofacturation ou de remboursement",
			"ImputacaoCoSeguradoras": "Imputation aux coassureurs",
			"ImputacaoCoSeguradoraLider": "Imputation au coassureur chef de file",
			"ResseguroAceite": "Réassurance acceptée",
			"Recibo": "Reçu",
			"NotaLancamento": "Note de version",
			"ExtratoBancario": "Relevé bancaire",
			"PagamentoAoEstado": "Paiement à l'État",
			"Levantamento": "Enquête",
			"Pagamento": "Paiement",
			"Deposito": "Dépôt",
			"Cheque": "Vérifier",
			"Transferencia": "Transfert"
		}
	},
	"irsdt": {
		"title_detail": "Date d’entrée en vigueur du tableau IRS",
		"title_new": "Nouvelle date d’entrée en vigueur du tableau IRS",
		"title_plural": "Dates d’entrée en vigueur du tableau IRS",
		"pesquisa": "A chercher",
		"saved": "Date d'entrée en vigueur de la table IRS, enregistrée avec succès.",
		"error": "La date d'entrée en vigueur du tableau IRS ne peut pas être enregistrée.",
		"deleted": "Date d'entrée en vigueur de la table IRS, supprimée avec succès.",
		"fields": {
			"tabIRSDT": "Date d'imposition de l'IRS"
		}
	},
	"ivamotivostaxa": {
		"title_detail": "Raison de l'exonération de TVA {{id}}",
		"title_new": "Nouvelle raison d’exonération de TVA",
		"title_plural": "Raisons de l'exonération de TVA",
		"pesquisa": "A chercher",
		"saved": "Raison de l'exonération de TVA {{id}}, enregistrée avec succès.",
		"error": "Le motif d'exonération de TVA {{id}} ne peut pas être enregistré.",
		"deleted": "Raison d'exonération de TVA {{id}}, supprimée avec succès",
		"fields": {
			"ivaMotivosTaxaId": "Code",
			"ivaMotivosTaxaIdPlaceholder": "Code d'exonération de TVA",
			"ivaMotivosTaxaCod": "Code motif",
			"ivaMotivosTaxaCodPlaceholder": "Code motif d'exonération de TVA",
			"descricao": "Description",
			"descricaoPlaceholder": "Description",
			"normaAplicavel": "Norme applicable",
			"normaAplicavelPlaceholder": "Norme applicable"
		},
		"novaTaxaIVA": "Nouveau motif d'exonération de TVA"
	},
	"ivas": {
		"title_detail": "Code TVA {{id}}",
		"title_new": "Nouveau code TVA",
		"title_plural": "Codes TVA",
		"pesquisa": "A chercher",
		"saved": "Code TVA {{id}}, enregistré avec succès.",
		"error": "Le code TVA {{id}} ne peut pas être enregistré.",
		"deleted": "Code TVA {{id}}, supprimé avec succès",
		"fields": {
			"codIva": "Code",
			"codIvaPlaceholder": "Code TVA",
			"nome": "Nom",
			"nomePlaceholder": "Nom Code TVA",
			"taxaActual": "Taux",
			"taxaActualPlaceholder": "Taux",
			"dataTaxaActual": "Date réf.",
			"percDedGasoleo": "Perc.",
			"percDedGasoleoPlaceholder": "Pourcentage de franchise diesel",
			"percDedProRata": "Perc.",
			"percDedProRataPlaceholder": "Pourcentage déductible au prorata",
			"codAlternInfac": "Code alternatif",
			"codAlternInfacPlaceholder": "Code alternatif",
			"tipoTaxa": "Type de frais",
			"areaRegional": "Zone régionale",
			"tipoDeducao": "Type de déduction",
			"ivaMotivosTaxaId": "Motif de l'exonération"
		},
		"novaTaxaIVA": "Nouveau taux de TVA",
		"tooltip": {
			"codAlternInfacTooltip": "Complète l’intégration de l’assiette fiscale."
		}
	},
	"justificacaoAusencia": {
		"data": {
			"tjaNA": "N / A",
			"tjaSim": "Oui",
			"tjaNao": "Non"
		}
	},
	"listagemdiarios": {
		"btn": {
			"previsualizar": "Aperçu"
		},
		"header": {
			"fromAccount": "Compte",
			"doPeriodo": "période de",
			"doDiario": "agenda",
			"docInterDo": "Document interne.",
			"datadocde": "Date du document."
		}
	},
	"listagemMenus": {
		"table": {
			"almoco": "Déjeuner",
			"jantar": "Pour déjeuner",
			"sopa": "Soupe",
			"ligeira": "Lumière",
			"sobremesa": "Dessert"
		},
		"form": {
			"local": "Emplacement",
			"de": "De",
			"btns": {
				"pesquisar": "A chercher",
				"imprimir": "Imprimer"
			}
		},
		"title": {
			"ementaSemanal": "Menu hebdomadaire",
			"local": "Emplacement"
		}
	},
	"loadmodule": {
		"title": "Chargement..."
	},
	"localObtencaoRendimentos": {
		"data": {
			"continente": "Continent",
			"regiaoAcores": "Région autonome des Açores",
			"regiaoMadeira": "Région autonome de Madère",
			"estrangeiro": "Étranger"
		}
	},
	"locale": {
		"descriptive": {
			"en": "Anglais",
			"es": "Espagnol",
			"fr": "Français",
			"pt-PT": "Portugais"
		}
	},
	"local": {
		"title_detail": "Emplacement {{id}}",
		"title_new": "Nouvel emplacement",
		"title_plural": "Emplacements",
		"pesquisa": "A chercher",
		"saved": "Emplacement {{id}}, enregistré avec succès.",
		"error": "L'emplacement {{id}} ne peut pas être enregistré.",
		"deleted": "Emplacement {{id}}, supprimé avec succès",
		"fields": {
			"nLocalidade": "Code.",
			"nLocalidadePlaceholder": "Code de localité",
			"designaBreve": "Brève désignation",
			"designaBrevePlaceholder": "Brève désignation",
			"designaCompl": "Désignation complète",
			"designaComplPlaceholder": "Désignation complète"
		}
	},
	"lockedcontract": {
		"title": "Votre contrat est bloqué",
		"titleTemporary": "Votre contrat est temporairement bloqué",
		"changeCompany": "Changer d'entreprise",
		"reasons": {
			"0": "N / A",
			"1": "Votre contrat est en cours de maintenance, ce processus peut prendre un certain temps, veuillez patienter."
		}
	},
	"loginAT": {
		"title": "ÀConfiguration",
		"success": "Informations de connexion enregistrées avec succès",
		"panels": {
			"efatura": "Connexion à la facture électronique AT",
			"efaturaCirculacao": "AT Webservice Login pour la communication des documents de transport",
			"nifAt": "Connexion à la société AT",
			"docfasNumsComunicacaoLogin": "Connexion aux communications en série"
		},
		"fields": {
			"campo": "Champ",
			"valor": "Valeur",
			"usernif": "(NIF/Utilisateur)",
			"password": "Mot de passe",
			"nif": "Numéro de TVA"
		}
	},
	"loginWsSS": {
		"title": "Authentification sécurité sociale - SSD",
		"success": "Informations de connexion enregistrées avec succès",
		"dadosEmpresa": "Détails de l'entreprise",
		"dadosMandatario": "Détails du représentant",
		"fields": {
			"niss": "NISS",
			"password": "Mot de passe"
		},
		"messages": {
			"credentialsSaved": "Les informations d'identification ont été enregistrées avec succès.",
			"errorNISSEmpresaEmpty": "Le NISS de l'entreprise est vide.",
			"errorPasswordEmpresaEmpty": "Le mot de passe de l'entreprise est vide.",
			"errorPasswordMandatarioEmpty": "Le mot de passe de l'agent est vide."
		},
		"nissAutenticacaoDiferenteFichaEmpresa": "Le NISS de l'authentification \"{{nissAuth}}\" est différent de l'enregistrement de l'entreprise \"{{nissEmpresa}}\"."
	},
	"lotes": {
		"title_detail": "Lot {{id}}",
		"title_new": "Nouveau lot",
		"title_plural": "Beaucoup",
		"pesquisa": "A chercher",
		"saved": "Lot {{id}}, enregistré avec succès",
		"error": "Le lot {{id}} ne peut pas être enregistré.",
		"deleted": "Lot {{id}}, supprimé avec succès",
		"fields": {
			"nLote": "Code de lot",
			"nArtigo": "Code article",
			"nLoteEspecifico": "Lot spécifique",
			"loteFornecedor": "Lot fournisseur",
			"nConta": "Fournisseur",
			"dataFabrico": "Date de fabrication",
			"dataValidade": "Date d'expiration",
			"qtdTotal": "Quantité totale",
			"terminado": "Fini",
			"nomeArtigo": "Nom de l'article",
			"nomeConta": "Nom du fournisseur"
		},
		"errors": {
			"artigoDaLinhaNaoTemLote": "Il n'y a aucun lot sélectionné pour l'article {{nartigo}}.",
			"artigoNaoTemLote": "Le code article {{nArtigo}} n'a pas de lot."
		},
		"btn": {
			"new": "Nouveau"
		},
		"list": {
			"config": {
				"showSoTerminados": "Voir je viens de terminer"
			},
			"actions": {
				"editar": "Modifier",
				"terminar": "Finition",
				"teminado": "Fini",
				"verSoLotesTerminados": "Afficher uniquement les lots terminés",
				"verSoLotesAtivos": "Afficher uniquement les lots actifs"
			}
		},
		"okCancel": {
			"terminar": {
				"title": "Terminer le lot",
				"message": "Etes-vous sûr de vouloir terminer le lot {{lot}} de l'article {{nartigo}} ?"
			}
		}
	},
	"maintenance": {
		"pageTitle": "Entretien programmé",
		"title": "Nous effectuons une maintenance programmée",
		"message": "Désolé pour le désagrément occasionné.<br/>Nous vous répondrons dans les plus brefs délais."
	},
	"mapaexploracao": {
		"title": "Carte d'exploration",
		"table": {
			"header": {
				"meses": "Mois"
			}
		},
		"fields": {
			"periodoDe": "période de",
			"conta": "Compte",
			"descricao": "Description",
			"saldo01": "Janvier",
			"saldo02": "Février",
			"saldo03": "Mars",
			"saldo04": "Avril",
			"saldo05": "Peut",
			"saldo06": "Juin",
			"saldo07": "Juillet",
			"saldo08": "Août",
			"saldo09": "Septembre",
			"saldo10": "Octobre",
			"saldo11": "Novembre",
			"saldo12": "Décembre"
		},
		"summary": {
			"rendimentos": "Revenu",
			"compras": "Achats et dépenses",
			"totalrendimentos": "Revenu total",
			"totalcompras": "Total des achats et dépenses",
			"totalexploracao": "Résultat de l'exploration"
		},
		"btn": {
			"dropdown": {
				"balancete": "Bilan"
			}
		},
		"modal": {
			"config": {
				"title": "Configuration de la carte d'exploration",
				"label": {
					"tipoarredondamento": "Type d'arrondi"
				},
				"tipoarredondamento": {
					"semArredondamento": "Pas d'arrondi",
					"arredondamentoAoEuro": "Arrondir à l'euro"
				}
			}
		}
	},
	"mapapagam": {
		"texts": {
			"comSelecionados": "Choisi...",
			"carregarPagamentos": "Pour charger",
			"empresas": "Entreprises",
			"pagamentos": "Paiements",
			"pagamentosManuais": "Paiements manuels",
			"adiconarPagamentoManual": "Créer un paiement manuel",
			"criarPagamento": "Créer un paiement",
			"editarPagamento": "Modifier le paiement",
			"periocidade": "Périodicité",
			"dadosPagamento": "Détails du paiement",
			"verDocumento": "Afficher le(s) document(s)",
			"enviarPagamento": "Pour envoyer",
			"reEnviarPagamento": "Renvoyer le(s) paiement(s)",
			"marcarComoPago": "Marquer comme payé",
			"visualizacaoDocumento": "Aperçu du document",
			"documento": "Document",
			"selecionarTodosPagamentos": "Sélectionnez tous les paiements",
			"selecionarEstePagEmTodasEmpresa": "Sélectionnez ce paiement dans toutes les entreprises",
			"enviarPagamentos": "Envoyer des paiements",
			"continuar": "Continuer",
			"configurarCredentials": "Configurer les informations d'identification",
			"resumoEnvioErros": "Résumé de la soumission – Erreurs",
			"existemEmpresasComErros": "Certaines entreprises ont des erreurs d'expédition.",
			"listaDePagamentosAEnviar": "Liste des paiements à envoyer",
			"viewDocumentoUnico": "Aperçu d'un document unique avec les paiements sélectionnés",
			"verDetalhesPagamento": "Afficher les détails",
			"apagarPagamento": "Supprimer le paiement",
			"detalhesPagamento": "Détails du paiement",
			"semDetalhesPagamento": "Aucun détail de paiement.",
			"naoPossivelVisualizarDocNaoEmitido": "Impossible de visualiser le document.",
			"podeEmitirDocNosDocsFiscais": "Vous pouvez émettre le document dans les documents fiscaux ou sur le portail financier (AT)",
			"selecionarEmpresasSemErro": "Sélectionnez les entreprises sans erreurs",
			"portalAT": "Portail financier (AT)",
			"portalFCT": "Fonds de compensation",
			"portalSSD": "Sécurité sociale directe",
			"enviarSelecionados": "Envoyer la sélection",
			"enviarApenas": "Sélectionner et envoyer uniquement {{name}}",
			"mostrarApenasSelecionadas": "Afficher uniquement la sélection",
			"mostrarComFalhaLogin": "Afficher l'échec de la connexion",
			"pagEstado": "Pag.",
			"configuracao": "Paramètres",
			"pagamentosDisponiveis": "Paiements disponibles",
			"pagamentosSelecionados": "Paiements sélectionnés",
			"disponivelBrevemente": "Cette fonctionnalité sera bientôt disponible.",
			"regimeIva": {
				"normal": "Normale",
				"isencao": "Exemption",
				"pequenosRetalhistas": "Petits détaillants",
				"regimeNormalBens2Mao": "Régime normal + biens d'occasion",
				"regimeNormalRevendaCombustíveis": "Régime normal + revente de carburant",
				"regimeNormalIvaCaixa": "Régime normal + TVA en espèces"
			},
			"periodoIva": {
				"mensal": "Mensuel",
				"trimestral": "Trimestriel"
			},
			"limparFiltros": "Effacer les filtres",
			"aplicar": "Appliquer",
			"alertMessage": "Nous vous déconseillons d'utiliser la carte de paiement entre 12h et 14h, car ces horaires servent de maintenance pour certaines entités publiques",
			"criarPagamentoManual": "Créer un paiement manuel",
			"editarPagamentoManual": "Modifier le paiement manuel",
			"pagamentoManual": "Paiement manuel",
			"todasEmpresas": "Toutes les entreprises",
			"todosMeses": "Chaque mois",
			"durante": "Pendant",
			"anos": "années)",
			"selecioneOQuePertendeApagar": "Sélectionnez ce que vous souhaitez supprimer",
			"apagarApenasMesCorrente": "Supprimer uniquement du mois en cours",
			"apagarTodosMesesNoAnoCurrente": "Supprimer tous les mois de l'année en cours",
			"apagarPorCompletoEmTodasEmpresas": "Supprimer complètement et dans toutes les entreprises",
			"confirmar": "Confirmer",
			"documentosFiscais": "Documents fiscaux",
			"anexo": "Annexe",
			"removerAnexo": "Supprimer la pièce jointe",
			"documentoUnicoPdfName": "{{année}}{{mois}}_DU_{{nCompany}}_{{maintenant}}.pdf",
			"pagamentoPdfName": "{{année}}{{mois}}_{{nCompany}}_{{nom}}_{{maintenant}}.pdf",
			"aPreparar": "Préparation... veuillez patienter un instant.",
			"recarregar": "Recharger"
		},
		"fields": {
			"nEmpresa": "numéro d'entreprise",
			"nomeEmpresa": "Nom",
			"nomePagamento": "Nom du paiement",
			"descricaoPagamento": "Description du paiement",
			"refPagDescricao": "Référence du paiement (Description)",
			"dataLimitePag": "Délai de paiement",
			"montante": "Montant",
			"email": "E-mail",
			"pago": "Payé",
			"status": "État",
			"nif": "FNI",
			"regimeIva": "Régime TVA",
			"periodoIva": "Période de TVA",
			"responsavel": "Responsable",
			"iva": "T.V.A.",
			"ircMod22": "IRCMOD 22",
			"imi": "IMI",
			"iuc": "CIU",
			"dmr": "DMR À",
			"retencaoNaFonte": "Retraité.",
			"pagAntIRC": "Pag.",
			"dmrSS": "DMR SS"
		},
		"messages": {
			"temSeleccionarEmpresa": "Vous devez sélectionner au moins une entreprise.",
			"temQuePreencherNomePagamento": "Vous devez renseigner le nom du paiement",
			"temQueSelecionarPeloMenosUmMes": "Vous devez sélectionner au moins un mois",
			"marcarComoPagoPrompt": "Etes-vous sûr de vouloir marquer ce paiement comme payé ?",
			"empresaNaoTemPagamentosSelecionados": "La société \"{{company}}\" n'a sélectionné aucun paiement",
			"empresaNaoTemEmailPreenchido": "La société \"{{company}}\" n'a pas d'adresse e-mail renseignée",
			"empresaNaoTemPagamentosCarregados": "La société \"{{company}}\" n'a aucun paiement chargé.",
			"processMessage": "Traitement en cours... veuillez patienter un moment.",
			"empresaNaoTemEmailInvalido": "La société \"{{company}}\" a une adresse e-mail invalide",
			"existemPagamentoEnviados": "Certains paiements sélectionnés ont déjà été envoyés.",
			"documentoUnicoEnviadoSucesso": "Le document unique avec les paiements a été envoyé avec succès",
			"carregarEmpresas": "Chargement des entreprises...",
			"pagManGuardadoSucesso": "Le paiement manuel a été enregistré avec succès"
		},
		"tooltips": {
			"emailLido": "L'e-mail avec ce paiement a été lu le {{lidoDataHora}}",
			"emailEnviado": "L'e-mail contenant ce paiement a été envoyé le {{sentDateTime}}",
			"emailNaoEnviado": "L'e-mail avec ce paiement n'a pas été envoyé.",
			"pago": "Le paiement a déjà été payé",
			"obterDadosPortaisATeSSD": "Obtenir des données depuis les portails AT et SSD",
			"enviarPagamentosSelParaEmpresasSel": "Envoyer les paiements sélectionnés aux entreprises sélectionnées",
			"emailEnviadoELido": "L'e-mail avec ce paiement a été envoyé le {{enviadoDataHora}} et lu le {{lidoDataHora}}",
			"enviarPagamentosSelecionadosGrelha": "Envoyer les paiements sélectionnés dans la grille",
			"naoEPossivelObterEstadoPagamento": "Impossible d'obtenir le statut de paiement."
		},
		"btn": {
			"resend": "Renvoyer",
			"resendSkipErrors": "Soumettre à nouveau (ignorer les erreurs)"
		}
	},
	"mapasamortizacao": {
		"title_detail": "Carte d'amortissement {{id}}",
		"title_new": "Nouvelle carte d’amortissement",
		"title_plural": "Cartes d'amortissement",
		"pesquisa": "A chercher",
		"saved": "Carte d'amortissement {{id}}, enregistrée avec succès.",
		"error": "La carte d'amortissement {{id}} ne peut pas être enregistrée.",
		"deleted": "Carte d'amortissement {{id}}, supprimée avec succès",
		"fields": {
			"nImmap": "Code.",
			"nImmapPlaceholder": "Code de carte d'amortissement",
			"nome": "Nom",
			"nomePlaceholder": "Nom du plan d'amortissement"
		}
	},
	"mascarasanalitica": {
		"title_detail": "Masque d'analyse {{id}}",
		"title_new": "Nouveau masque d'analyse",
		"title_plural": "Masques d'analyse",
		"pesquisa": "A chercher",
		"saved": "Masque analytique {{id}}, enregistré avec succès.",
		"error": "Le masque d'analyse {{id}} ne peut pas être enregistré.",
		"errorAdd": "Ce type de personnage existe déjà dans une position précédente du masque",
		"deleted": "Masque d'analyse {{id}}, supprimé avec succès.",
		"fields": {
			"manalID": "Code.",
			"descricao": "Description",
			"mascara": "Masque",
			"tipo": "Type de masque",
			"nCaracteresGeral": "Nombre de caractères",
			"isDefault": "Masque par défaut",
			"subTotaisDaGeral": "Crée des sous-totaux basés sur la structure globale de l'accumulateur",
			"indicesSubTotais": "Indices des sous-totaux",
			"contaContrapartida": "Compte de compensation"
		},
		"placeholders": {
			"descricao": "Description du masque d'analyse",
			"mascara": "Masque",
			"tipo": "Type de masque",
			"nCaracteresGeral": "Nombre de caractères dans le compte général",
			"isDefault": "Masque par défaut",
			"subTotaisDaGeral": "Crée des sous-totaux basés sur la structure globale de l'accumulateur",
			"indicesSubTotais": "Indices des sous-totaux"
		},
		"tipos": {
			"invoicing": "Facturation",
			"salary": "Salaires",
			"fixed": "Immobilisations"
		},
		"tabs": {
			"definition": "Définition du masque",
			"definitionTitle": "Masque",
			"definitionNames": "Définir les noms de comptes à générer",
			"definitionNamesTitle": "Disponible",
			"nameTitle": "Nom du compte créé",
			"nameItemsTitle": "Champs disponibles"
		},
		"table": {
			"description": "Description",
			"char": "Personnage",
			"subTotal": "Créer un sous-total",
			"type": "Taper",
			"field": "Champ par définition",
			"text": "Texte"
		},
		"enum": {
			"definition": {
				"0": "Constante 0",
				"1": "Constante 1",
				"2": "Constante 2",
				"3": "Constante 3",
				"4": "Constante 4",
				"5": "Constante 5",
				"6": "Constante 6",
				"7": "Constante 7",
				"8": "Constante 8",
				"9": "Constante 9"
			},
			"nomes": {
				"poc": "Nom du compte général source"
			},
			"definitionFaturacao": {
				"ccusto": "centre de coûts",
				"zone": "Zone",
				"department": "Département",
				"subDepartment": "Sous-département",
				"family": "Famille",
				"bigFamily": "Grande famille",
				"subFamily": "Sous-famille",
				"accountingCode": "Code comptable",
				"itemType": "Type d'article",
				"class": "Classe",
				"category": "Catégorie",
				"seller": "Vendeur",
				"process": "Processus"
			},
			"definitionSalarios": {
				"ccusto": "centre de coûts",
				"accountingCode": "Code comptable",
				"workerClass": "Code de la classe ouvrière",
				"roleName": "Code de fonction",
				"employeeCCusto": "Centre de coûts du personnel",
				"categoryFamily": "Code famille de catégorie",
				"zone": "Zone",
				"department": "Département"
			},
			"definitionImobilizado": {
				"ccusto": "centre de coûts",
				"department": "Département",
				"bigFamily": "Grande famille",
				"itemType": "Type d'article",
				"accountingCode": "Code comptable"
			},
			"nomesFaturacao": {
				"ccusto": "Nom du centre de coûts",
				"zone": "Nom de la zone",
				"department": "Nom du département",
				"subDepartment": "Nom du sous-département",
				"family": "Nom de famille",
				"bigFamily": "Grand nom de famille",
				"subFamily": "Nom de sous-famille",
				"accountingCode": "Nom de code.",
				"itemType": "Nom du type d'article",
				"class": "Nom de la classe",
				"category": "Nom de la catégorie",
				"seller": "Nom du vendeur",
				"process": "Nom du processus"
			},
			"nomesSalarios": {
				"ccusto": "Nom du centre de coûts",
				"accountingCode": "Nom de code.",
				"workerClass": "Nom de la classe ouvrière",
				"roleName": "Nom de la fonction",
				"employeeCCusto": "Nom du centre de coûts du salarié",
				"categoryFamily": "Nom de famille de la catégorie",
				"zone": "Nom de la zone",
				"department": "Nom du département"
			},
			"nomesImobilizado": {
				"ccusto": "Nom du centre de coûts",
				"department": "Nom du département",
				"family": "Nom de famille",
				"bigFamily": "Grand nom de famille",
				"itemType": "Nom du type d'article",
				"accountingCode": "Nom de code."
			}
		},
		"changeTypePrompt": {
			"title": "Êtes-vous sûr de vouloir modifier le type de masque ?",
			"message": "Changer le type de masque effacera les modifications qui y ont été apportées."
		},
		"textPrompt": {
			"title": "Modifier le texte du champ"
		}
	},
	"mbconfigs": {
		"titles": {
			"pagRec": "Paiements/reçus",
			"addtipodoc": "Ajouter un type de document"
		},
		"fields": {
			"entidade": "Entité",
			"accountID": "ID API",
			"apiKey": "API clé",
			"contaTransf": "Compte de transfert",
			"contaTransitoriaTransf": "Compte de transition",
			"transferenciaLancaSaldo": "Lancer le transfert avec le montant du solde",
			"contaComissao": "Compte de commissions",
			"diario": "Tous les jours",
			"descritivo": "Descriptif",
			"codEstadoDocPago": "Statut du document.",
			"enviarrecibo": "Envoyer le reçu client par e-mail",
			"enviarreciboTooltip": "Valider les emails dans les fichiers clients",
			"enviarrecibotitle": "Notification d'importation de paiement",
			"enviarreciboinfo": "Le système importera automatiquement",
			"dataInicioRef": "Créer des références à partir de",
			"geraRefMovimentoAberto": "J'ai l'intention de générer une deuxième référence avec le solde impayé du client",
			"internalDesc": "Types de documents possibles à configurer",
			"inativo": "Inactif",
			"estado": "État",
			"advancedMode": "Mode avancé",
			"tipoDoc": "Type de document",
			"nDiasValidadeReferenciaMB": "Nombre de jours de validité réf."
		},
		"messages": {
			"configsavedsucess": "Configuration des références ATM enregistrée avec succès !",
			"fornecedorRefMult": "Réf Fournisseur",
			"advancedModeInfo": "Vous ne devez utiliser ce mode que si vous souhaitez configurer les comptes dans le plan comptable.",
			"ndocfaempty": "Vous devez sélectionner un type de document.",
			"ndocfasuccess": "Le type de document a été ajouté avec succès.",
			"infogeral": "Pour utiliser la plateforme EasyPay intégrée à Centralgest Cloud, vous devez obtenir un nouveau CIN EasyPay. Cela s'applique aussi bien aux nouveaux clients qu'à ceux qui ont déjà un compte créé avec EasyPay.<br>Cela ne signifie pas que vous devrez créer. un nouveau compte, juste un nouveau CIN.",
			"loginempty": "Vous devez remplir les champs \"API ID\" et \"API key\".",
			"functioning": "Dès qu'EasyPay en informera le système, nous émettrons le reçu correspondant.",
			"dataEasyPay": "Les données ci-dessus sont fournies par EasyPay.",
			"selectDocFas": "Sélectionnez les types de documents dans lesquels vous souhaitez configurer les références : MB",
			"nDiasValidadeReferenciaMB": "Lorsque la valeur est \"0\", la référence n'expire pas."
		},
		"btn": {
			"acessobackend": "Accès au back-office",
			"addTiposDoc": "Ajouter plus de types de documents",
			"criareasypay": "Créer un compte EasyPay",
			"faq": "FAQ - Aide",
			"testConnection": "Tester la connexion"
		},
		"tooltips": {
			"contaTransferencia": "Compte final pour transférer le montant",
			"contaTransitoria": "Compte temporaire, utilisé après réception effective",
			"contaComissao": "Compte où les frais de traitement seront déposés"
		}
	},
	"meiosPagamento": {
		"title_detail": "Mode de paiement {{id}}",
		"title_new": "Nouveau mode de paiement",
		"title_plural": "Méthodes de paiement",
		"pesquisa": "A chercher",
		"saved": "Mode de paiement {{id}}, enregistré avec succès",
		"error": "Le mode de paiement {{id}} ne peut pas être enregistré.",
		"deleted": "Mode de paiement {{id}}, supprimé avec succès",
		"fields": {
			"nCaixa": "Nombre",
			"nCaixaPlaceholder": "S'il n'est pas renseigné, il sera généré automatiquement.",
			"nome": "Nom",
			"tipoMovimento": "Taper",
			"nConta": "Numéro de compte",
			"moeda": "Code devise",
			"tipo": "Type de mode de paiement",
			"nib": "IBAN",
			"swift": "RAPIDE",
			"porDefeito": "Par défaut"
		},
		"data": {
			"indiferente": "Indifférent",
			"recebimento": "Reçu",
			"pagamento": "Paiement",
			"dinheiro": "Argent",
			"multibanco": "ATM",
			"cartaoCredito": "carte de crédit",
			"cheque": "Vérifier",
			"valorAdicional": "Valeur supplémentaire",
			"pontos": "Points",
			"contraReemsolso": "Paiement à la livraison",
			"trfBancaria": "Virement bancaire",
			"payPal": "Paypal"
		},
		"btn": {
			"verContas": "Afficher les comptes",
			"buttonOptions": "Possibilités",
			"esconderContas": "Masquer les comptes"
		},
		"info": {
			"entregue": "Livré",
			"troco": "Changement"
		},
		"mensagens": {
			"meioPagSemMoedaEmpresaParaTroco": "Impossible de trouver un mode de paiement en espèces dans la devise de l'entreprise pour rendre la monnaie",
			"montanteMeioPagNaoIgualTotalDoc": "Le montant dans les modes de paiement {{amount}} n'est pas égal au total du document {{total}}",
			"adicionarMeiosPagCancelado": "Ajouter des modes de paiement annulés",
			"meioPagamentoAlteradoPorOmissao": "Ce mode de paiement est défini par défaut sur {{type}}, lors du changement de type de mouvement il ne sera plus éligible et un autre sera automatiquement sélectionné.",
			"meioPagamentoAlteradoPorOmissaoTitle": "Confirmer le changement de valeur par défaut pour le type de paiement",
			"erroTipoMeioPagamentoOmissao": "Le moyen de paiement choisi n'a pas le même type de paiement que la configuration",
			"contaInvalida": "Le compte {{nConta}} n'est pas un compte de transaction."
		},
		"table": {
			"actions": {
				"porDefeitoPara": "Par défaut pour",
				"alterarPorDefeito": "Modifier les modes de paiement par défaut",
				"recibos": "Recettes",
				"pagamentos": "Paiements",
				"errorDesactivate": "Vous ne pouvez pas laisser un type de paiement sans mode de paiement par défaut"
			}
		}
	},
	"mercados": {
		"pesquisa": "Marchés",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"notApplicable": "N / A",
			"national": "National",
			"cee": "UE",
			"thirdPartyCountries": "Pays tiers"
		}
	},
	"modelo10": {
		"groups": {
			"atencao": {
				"p1": "Image 00",
				"p2": "Lors du remplissage du modèle de déclaration 10, certains aspects déclaratifs doivent être pris en compte, en soulignant les suivants :",
				"p3": {
					"p3-1": "Pour les déclarations de l'année 2019 et des années suivantes, si elles ont été",
					"p3-2": "payé ou mis à disposition",
					"p3-3": "revenu relatif",
					"p3-4": "aux années précédentes",
					"p3-5": ", le tableau 05 champ 02 doit être complété conformément à l'aide prévue dans ce tableau/champ, en soulignant la nécessité d'indiquer l'année à laquelle se réfèrent les revenus au lieu du nombre d'années."
				},
				"p4": "Les personnes ou entités tenues de soumettre la déclaration mensuelle de rémunération (DMR) ne peuvent pas déclarer les revenus d'un emploi salarié (catégorie A) dans la déclaration modèle 10.",
				"p5": "Les personnes redevables de revenus d'une activité salariée qui ne sont pas tenues de déposer le DMR peuvent choisir de soumettre la déclaration Modèle 10, à condition que ces revenus n'aient pas été soumis à un quelconque prélèvement à la source.",
				"p6": {
					"p6-1": "La déclaration Modèle 10 est destinée à déclarer les revenus soumis à l'impôt, exonérés et non soumis à l'impôt perçus par les contribuables de l'IRS.",
					"p6-2": "résidents",
					"p6-3": "sur le territoire national, ainsi que les retenues à la source respectives."
				},
				"p7": "Les revenus fonciers doivent toujours être déclarés, avec les codes F, même s'ils seront imposés comme revenus de catégorie B car imputables à des activités commerciales ou professionnelles, conformément aux alinéas a) du paragraphe 2 de l'article 3 du Code IRS.",
				"p8": {
					"p8-1": "L'espace géographique à indiquer dans le tableau 5, champ 05 (",
					"p8-2": "Continent, région autonome des Açores, région autonome de Madère ou étranger",
					"p8-3": ") doit correspondre au lieu où les revenus ont été perçus."
				}
			},
			"financAreaDomi": {
				"title": "Service de financement de la zone des ménages",
				"codservfinan": "Code des services financiers"
			},
			"nif": {
				"title": "Numéro d'identification fiscale",
				"nomepassivo": "Nom du sujet imposable",
				"nif": "FNI"
			},
			"importanciaretidas": {
				"title": "Importances conservées",
				"quadro4": {
					"campo1": "A - TRAVAIL DÉPENDANT",
					"campo2": "B - REVENUS D'AFFAIRES ET PROFESSIONNELS",
					"campo3": "E - AUTRES REVENUS DU CAPITAL",
					"campo4": "EE - SOLDES CRÉANCIERS A/C (article 12-A, n. 3, alinéa a) du décret-loi 41/91, du 22 janvier)",
					"campo5": "F - BÂTIMENTS",
					"campo6": "G - AUGMENTATIONS DES CAPITAUX PROPRES",
					"campo7": "H - RETRAITES",
					"campo8": "RETENUES IRC (Article 94 du CIRC)",
					"campo9": "SOMME (01 À 08)",
					"campo10": "RETENUE SUR LES FRAIS DE LIBÉRATION",
					"campo11": "COMPENSATIONS IRS/IRC",
					"campo12": "TOTAL (09 + 10 - 11)",
					"campo13": "RÉTENTION DE SURCHARGE (applicable de 2013 à 2017)"
				}
			},
			"reltitrendimentos": {
				"title": "Liste des détenteurs de revenus",
				"quadro5": {
					"table": {
						"sujpassivo": "Sujet passif",
						"rendAnoAnt": "Revenus des années précédentes",
						"rendAno": "Revenu pour l'année",
						"tipoRend": "Type de revenu",
						"locobtrend": "Lieu d'obtention des revenus",
						"retIrsIrc": "Retenues IRS/IRC",
						"contribObr": "Cotisations obligatoires",
						"quotSindicais": "Cotisations syndicales",
						"retSobreTaxa": "Rétention du supplément (années 2013 à 2017)",
						"valores": "Valeurs",
						"nrAnos": "Nombre d'années",
						"semdados": "Aucune donnée",
						"addlinha": "Ajouter une ligne"
					},
					"tableTot": {
						"rendAnoAnt": "Revenu des années précédentes",
						"rendAno": "Revenu pour l'année",
						"retIrsIrc": "Retenues IRS/IRC",
						"contribObr": "Cotisations obligatoires",
						"quotSindicais": "Cotisations syndicales",
						"retSobreTaxa": "Retenue de supplément (années 2013 à 2017)",
						"somacontr": "Somme de contrôle"
					}
				}
			},
			"tipodec": {
				"title": "Type de déclaration",
				"campo1": "1ère Déclaration de l'Année",
				"campo2": "Déclaration de remplacement",
				"campo3": "Déclaration présentée conformément au paragraphe d), no 1, art.",
				"campo4": "Date de l'événement ayant déterminé l'obligation de déclarer ou de modifier les revenus déjà déclarés"
			},
			"identSujPass": {
				"title": "Identification du sujet imposable ou de la représentation légale et du CC",
				"campo1": "NIF de l'Expert-Comptable",
				"campo2": "Numéro de l'assujetti ou du représentant légal"
			}
		},
		"modal": {
			"config": {
				"title": "Configuration du modèle de comptabilité de rendement des comptes radicaux 10",
				"radicais": "Compte radical"
			},
			"retencaomod10": {
				"title": "Maintien du revenu",
				"nif": "Sujet imposable (NIF)",
				"rendAnoAnt": "Revenus des années précédentes",
				"rendimentosAnterioresValores": "Valeurs",
				"rendimentosAnterioresNAnos": "Nombre d'années",
				"rendimentosDoAno": "Revenu pour l'année",
				"tipoRendimentos": "Type de revenu",
				"localObtencao": "Lieu d'obtention des revenus",
				"retencoesIRSIRC": "Retenues IRS/IRC",
				"contribuicoes": "Cotisations obligatoires",
				"quotizacoes": "Cotisations syndicales",
				"retencaoSobretaxa": "Rétention du supplément (années 2013 à 2017)"
			},
			"configws": {
				"title": "Paramètres du service Web",
				"username": "Nom d'utilisateur du contributeur",
				"password": "Mot de passe du contribuable",
				"servicoInvCC": "Service invoqué par un comptable agréé (CC)",
				"ccPowers": "CC avec pleins pouvoirs déclaratifs pour Contrib.",
				"usernameCC": "Nom d'utilisateur CC",
				"passwordCC": "Mot de passe CC",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> Le service est déjà configuré.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> Le service n'est pas configuré.",
				"configsaved": "Configuration enregistrée avec succès."
			}
		},
		"btn": {
			"config": "Paramètres",
			"limpo": "Faire le ménage",
			"valorescarre": "Charger les valeurs enregistrées",
			"valoresauto": "Charger les valeurs automatiques",
			"criarficheiro": "Créer un fichier",
			"retencoes": "Afficher les réservations",
			"btnSubmeterAt": "Soumettre à",
			"btnValidarAt": "Valider sur AT",
			"btnConfigWS": "Paramètres WS"
		},
		"message": {
			"registosdel": "Enregistrements supprimés avec succès.",
			"dadoscarregados": "Données chargées avec succès !",
			"dadoscarregadosClean": "Champs effacés avec succès !",
			"dadosguardados": "Données enregistrées avec succès !",
			"temDeGuardar": "Vous devez sauvegarder les données pour pouvoir créer le fichier",
			"q6c4NotNull": "Vous devez remplir le champ 4 du tableau 6",
			"servicoAT": {
				"validarAtSuccess": "Déclaration validée avec succès",
				"validarAtFailed": "Erreur lors de la validation de la déclaration dans AT",
				"submeterAtSuccess": "Déclaration soumise avec succès",
				"submeterAtFailed": "Erreur lors de la soumission de la déclaration sur AT"
			}
		}
	},
	"modelo22": {
		"tabs": {
			"anexos": "Pièces jointes",
			"rosto": "Affronter",
			"anexoA": "Annexe A",
			"anexoB": "Annexe B",
			"anexoC": "Annexe C",
			"anexoD": "Annexe D",
			"anexoE": "Annexe E",
			"anexoF": "Annexe F",
			"anexoG": "Annexe G",
			"anexoAIMI": "Annexe AIMI",
			"adicionarAnexo": "Ajouter des pièces jointes"
		},
		"btn": {
			"gravar": "Pour enregistrer",
			"validar": "Valider",
			"criarficheiro": "Déposer",
			"entregar": "Livrer",
			"anular": "Tout annuler",
			"calcular": "Calculer",
			"repor": "Tout réinitialiser",
			"contasdefall": "Comptes définis pour les champs",
			"contasdefcopy": "Copier les comptes définis pour les champs vers une autre société",
			"limpaValores": "Effacer les valeurs",
			"balancetes": "Bilans",
			"extratos": "Relevé de compte",
			"calcDerrama": "Calcul du déversement",
			"tributAutonomas": "Impôts autonomes",
			"prejFiscais": "Pertes fiscales",
			"retencoes": "Retenues",
			"documentosFiscais": "Documents fiscaux"
		},
		"comum": {
			"de": "De",
			"a": "le",
			"ate": "jusqu'à",
			"periodo": "Période",
			"semdados": "Aucune donnée",
			"addline": "Ajouter une ligne",
			"sim": "Oui",
			"nao": "Non",
			"tooltipContasDef": "Définition des comptes du domaine"
		},
		"rosto": {
			"quadro01": {
				"title": "Période d'imposition"
			},
			"quadro02": {
				"title": "Zone du siège social, direction effective ou établissement stable",
				"campo01": "Code du service financier du siège social ou d’un établissement stable",
				"campo02": "Code du service financier de gestion efficace"
			},
			"quadro03": {
				"title": "Identification et caractérisation du contribuable",
				"sujPass": "Désignation",
				"campo02": "Numéro d'identification fiscale (NIF)",
				"campo03": {
					"title": "Type de contribuable",
					"radio1": "Résident qui exerce principalement une activité commerciale, industrielle ou agricole",
					"radio2": "Résident qui n'exerce pas, à titre principal, d'activité commerciale, industrielle ou agricole",
					"radio3": "Non-résident avec un établissement stable",
					"radio4": "Non-résident sans établissement stable"
				},
				"campo3A": {
					"title": "Qualification de l'entreprise selon les termes de l'annexe du décret-loi n° 372/2007 du 6 novembre ou en tant que coopérative",
					"desc": "Si vous avez coché les cases 1 ou 3 du tableau 03-3, indiquez comment vous êtes admissible aux conditions énoncées dans l'annexe du décret-loi n° 372/2007 du 6 novembre.",
					"radio1": "Entreprise moyenne",
					"radio2": "Non-PME",
					"radio3": "Micro-entreprise",
					"radio4": "Petite entreprise",
					"check5": "Coopérative",
					"check6": "Indiquez si elle est considérée comme une société à petite et moyenne capitalisation [(Small Mid Cap (SMC)]"
				},
				"campo3B": {
					"title": "Organismes de placement collectif",
					"field1": "Indiquer s'il s'agit d'un Organisme de Placement Collectif imposé au titre de l'article 22 de la FBE"
				},
				"campo3C": {
					"title": "Imputation du revenu (article 5, paragraphe 9)",
					"desc": "Est-il considéré comme un établissement stable aux fins de l'imputation prévue au paragraphe 9 de l'article 5 ?",
					"field1": "Oui"
				},
				"campo4": {
					"title": "Régimes d'imposition sur le revenu",
					"field3": "Exonération définitive",
					"field4": "Dispense temporaire",
					"field5": "Réduction de taux",
					"field6": "Simplifié",
					"field7": "Transparence fiscale",
					"field8": "Groupes d'entreprises",
					"field9": "NIF de la société contrôlante / Responsable (article 69.º-A, paragraphes 3 et 4)",
					"field10": "Comptez-vous exercer l’option pour les tarifs de l’article 87, no 1 ?",
					"field11": "L’une des situations visées à l’ex-article 87, paragraphe 7, s’est-elle produite ?",
					"field12": "Article 36-A du FBE",
					"field13": "Régime spécial des activités de transport maritime (Décret-loi n° 92/2019 du 13 novembre)"
				},
				"campo4A": {
					"title": "Transfert de résidence/cessation d'activité d'établissement stable/attribution de patrimoine (articles 83, 84 et 54-A, n°11)",
					"desc": "Si au cours de la période fiscale il y a eu un transfert de résidence, une attribution d'actifs et un établissement stable situé en dehors du territoire portugais, une cessation d'activité ou un transfert d'actifs d'un établissement stable situé sur le territoire portugais, indiquez le lieu de destination.",
					"field1": "Pays de l'UE/EEE"
				}
			},
			"quadro04": {
				"title": "Caractéristiques de la déclaration",
				"campo1": {
					"title": "Type de déclaration",
					"radio1": "1ère Déclaration de la période",
					"radio2": "Déclaration de substitution (article 122, paragraphes 1 et 2)",
					"radio3": "Déclaration de substitution (art. 64, n° 4)",
					"radio4": "Déclaration de substitution (article 120, paragraphes 8 et 9)",
					"radio5": "Déclaration de substitution (art. 64, n° 4) après le délai légal",
					"radio6": "Déclaration de substitution (art. 122, n° 3)",
					"data": "Date de notification de la décision/de la sentence"
				},
				"campo2": {
					"title": "Déclarations spéciales",
					"check1": "Déclaration du groupe",
					"check2": "Relevé de la période de règlement",
					"check3": "Déclaration de délai de cessation",
					"check4": "Avant le changement",
					"check5": "Après le changement",
					"check7": "Déclaration de la période de début d'activité",
					"check9": "Avant la dissolution",
					"check10": "Après dissolution",
					"data6": "Date de cessation",
					"data8": "Date de transmission/acquisition (entités non résidentes sans établissement stable)",
					"data11": "Date de dissolution",
					"subtitle1": "Déclaration avec période fiscale particulière"
				}
			},
			"quadro05": {
				"title": "Identification du représentant légal et de l'expert-comptable",
				"campo1": "NIF du représentant légal",
				"campo2": "NIF d'expert-comptable",
				"desc": "S'il y avait un juste empêchement (article 12.º-A du DL n° 452/99, du 5 novembre), indiquez :",
				"campo4": "Fait qui a déterminé le juste obstacle",
				"campo5": "Date du début de la survenance du fait",
				"campo6": "Date de cessation de l'événement"
			},
			"quadro07": {
				"title": "(Période 2010 et après) Calcul du bénéfice imposable",
				"acrescer": "A AJOUTER",
				"deduzir": "EN DÉDUIRE",
				"campo701": "RÉSULTAT NET DE LA PÉRIODE",
				"campo702": "Variations positives des capitaux propres non reflétées dans le résultat net de la période (art. 21) et part de la subvention relative aux actifs non courants, non amortissables/non amortissables [art.",
				"campo703": "Variations positives du patrimoine (régime transitoire prévu à l'article 5, paragraphes 1, 5 et 6 du DL n° 159/2009, du 13/7)",
				"campo704": "Variations négatives des capitaux propres non reflétées dans le résultat net de la période (art. 24)",
				"campo705": "Variations négatives des capitaux propres (régime transitoire prévu à l'article 5, paragraphes 1, 5 et 6 du DL n° 159/2009, du 13/7)",
				"campo706": "Modification du régime fiscal des contrats de construction (corrections positives)",
				"campo707": "Modification du régime fiscal des contrats de construction (corrections négatives)",
				"campo708": "SOMME (champs 701 + 702 + 703 - 704 - 705 + 706 - 707)",
				"campo709": "Revenu imposable/bénéfice imposable imputé par les sociétés transparentes, ACE ou AEIE (art. 6)",
				"campo710": "Corrections relatives aux périodes fiscales antérieures (art. 18, n° 2)",
				"campo711": "Ventes et prestations de services à paiement différé: différence entre le montant nominal de la contrepartie et la juste valeur (art. 18, n° 5)",
				"campo782": "Dépenses relatives aux stocks et fournitures et prestations externes à paiement différé : charges d'intérêts (art. 18, n° 5)",
				"campo712": "Annulation des effets de la méthode de mise en équivalence et de la méthode de l'intégration proportionnelle dans le cas des coentreprises soumises à l'IRC (article 18, alinéa 8)",
				"campo713": "Ajustements non déductibles résultant de l'application de la juste valeur (art. 18, n° 9)",
				"campo714": "Paiements fondés sur des actions (art. 18, n° 11)",
				"campo715": "Dépenses au titre des indemnités de fin de contrat, indemnités de retraite et autres avantages postérieurs à l'emploi ou à long terme (art. 18, n° 12)",
				"campo717": "Frais occasionnés par le transfert coûteux d'actions de capital (ex-article 23, paragraphes 3, 4 et 1ère partie du paragraphe 5)",
				"campo721": "Provisions non déductibles ou au-delà des limites légales (articles 19, n° 4 et 39) et pertes de valeur fiscalement non déductibles sur actifs financiers",
				"campo724": "IRC, y compris la fiscalité autonome et autres impôts qui affectent directement ou indirectement les bénéfices [art. 23.º-A, no 1, al.",
				"campo725": "Impôts différés [art. 23.º-A, no 1, al.",
				"campo716": "Dépenses non documentées [art. 23.º-A, no 1, al.",
				"campo731": "Accusations mal documentées [art. 23.º-A, no 1, al.",
				"campo726": "Charges constatées dans les documents délivrés par les contribuables avec NIF inexistant ou invalide ou par les contribuables résiliés d'office [art 23.º-A, no 1, al.",
				"campo783": "Dépenses illicites [art. 23.º-A, no 1, al.",
				"campo728": "Amendes, amendes et autres charges, y compris les intérêts compensatoires et moratoires, pour avoir commis des infractions [art. 23.º-A, no 1, al.",
				"campo727": "Impôts, taxes et autres impôts perçus sur les tiers que le contribuable n'est pas légalement tenu de supporter [art 23.º-A, no 1, al.",
				"campo729": "Indemnisation pour les événements assurables [art. 23.º-A, no 1, al.",
				"campo730": "Indemnités de séjour et frais d'indemnisation pour les déplacements dans le propre véhicule du travailleur [art. 23.º-A, no 1, al.",
				"campo732": "Frais de location de véhicules sans chauffeur [art. 23.º-A, no 1, al.",
				"campo733": "Taxes sur les carburants [art. 23.º-A, no 1, al.",
				"campo784": "Redevances relatives aux bateaux de plaisance et aux avions de passagers [art. 23.º-A, no 1, al.",
				"campo734": "Intérêts et autres formes de rémunération sur les fournitures et les prêts consentis par les associés à l'entreprise [art 23.º-A, no 1, al.",
				"campo735": "Dépenses non déductibles relatives à la participation aux bénéfices des membres des personnes morales [art. 23.º-A, no 1, al.",
				"campo780": "Contribution au secteur bancaire [art. 23.º-A, no 1, al.",
				"campo785": "Contribution extraordinaire au secteur de l'énergie [art. 23.º-A, no 1, al.",
				"campo802": "Contribution extraordinaire à l'industrie pharmaceutique [art. 23.º-A, no 1, al.",
				"campo746": "Montants payés ou dus à des entités non-résidentes soumises à un régime fiscal privilégié [art. 23.º-A, no 1, al.",
				"campo737": "50% des autres pertes relatives aux parts de capital ou autres éléments des capitaux propres (ex-art. 45, n° 3, partie finale)",
				"campo786": "Autres pertes liées aux instruments de capitaux propres et dépenses occasionnées par le transfert onéreux d'instruments de capitaux propres d'entités non-résidentes soumises à un régime fiscal privilégié (art. 23.º-A, paragraphes 2 et 3)",
				"campo718": "Pertes de valeur des stocks au-delà des limites légales (article 28) et des crédits non déductibles fiscalement ou au-delà des limites légales (articles 28-A à 28-C)",
				"campo719": "Pertes dues aux dépréciations d'actifs non courants (art. 31-B) et aux amortissements (art. 34, n° 1), non acceptées en charges",
				"campo720": "40% de l'augmentation de l'amortissement des immobilisations corporelles suite à la réévaluation fiscale (art. 15, n. 2 du DR 25/2009, du 14/9)",
				"campo722": "Créances irrécouvrables non acceptées en charges (art. 41)",
				"campo723": "Réalisations d'utilité sociale non déductibles (art. 43)",
				"campo736": "Pertes comptables",
				"campo738": "Valeur ajoutée fiscale résultant des changements dans le modèle d'évaluation [art. 46, no 5, al.",
				"campo739": "Différence positive entre gains et pertes fiscales sans intention de réinvestissement (art. 46)",
				"campo740": "50% de la différence positive entre les gains et les pertes fiscales avec intention expresse de réinvestissement (art. 48, n° 1)",
				"campo741": "Créances dues au non-réinvestissement ou au non-maintien des actifs détenus par l'acquéreur (article 48, paragraphe 6)",
				"campo742": "Gains fiscaux - régime transitoire [art. 7, no 7, al.",
				"campo743": "Corrections relatives aux instruments financiers dérivés (art. 49)",
				"campo787": "Pertes des établissements stables situés en dehors du territoire portugais (art. 54 -A)",
				"campo744": "Corrections relatives aux prix de transfert (art. 63, n°8)",
				"campo745": "Différence positive entre la valeur fiscale définitive du bien et la valeur contenue dans le contrat [art. 64, no 3, al.",
				"campo747": "Attribution des revenus des entités non-résidentes soumises à un régime fiscal privilégié (art. 66)",
				"campo748": "Limitation de la déductibilité des frais de financement nets (art. 67)",
				"campo749": "Corrections en cas de crédit d'impôt pour double imposition légale internationale (art. 68, n° 1)",
				"campo788": "Corrections en cas de crédit d'impôt pour double imposition économique internationale (art. 68, n° 3)",
				"campo750": "Corrections résultant de l'option du régime particulier applicable aux fusions, scissions, apports d'actif et échanges d'actions (articles 74, 76 et 77)",
				"campo789": "Transfert de résidence, attribution d'actifs à un établissement stable situé en dehors du territoire portugais, cessation d'activité ou transfert d'actifs d'un établissement stable situé sur le territoire portugais : solde positif se référant à des actifs transférés vers un autre État membre de l'UE ou de l'EEE ou attribués à un établissement stable établissement qui y est situé (articles 83, 84 et 54-A, paragraphe 11)",
				"campo790": "Transfert de résidence, attribution d'actifs à un établissement stable situé en dehors du territoire portugais, cessation d'activité ou transfert d'actifs d'un établissement stable situé sur le territoire portugais : solde positif se référant aux actifs transférés vers des pays hors UE ou EEE ou rattachés à un établissement stable qui s'y trouve (articles 83, 84 et 54-A, n° 11)",
				"campo751": "Dons imprévus ou au-delà des limites légales (articles 62, 62-A et 62-B de la FBE)",
				"campo803": "Asymétries hybrides et asymétries de résidence fiscale (articles 68.º-B, n° 1 et 68.º-D, n° 1)",
				"campo779": "Charges financières non déductibles (ex-art. 32, n° 2 de l'EBF)",
				"campo797": "Supplémentaire à la taxe foncière communale (article 135.º-J du Code IMI)",
				"campo799": "Dépenses et pertes liées aux activités de transport maritime auxquelles s'applique le régime spécial de détermination de l'assiette fiscale (article 6 de l'annexe du décret-loi n° 92/2018 du 13 novembre)",
				"campo804": "Cotisations temporaires de solidarité dans les secteurs de l'énergie et de la distribution alimentaire (art. 16 de la loi n° 24-B/2022, du 30 décembre)",
				"campo752": "Autres ajouts",
				"campo753": "SOMME (champs 708 à 752)",
				"campo754": "Dépenses ou charges de projection économique pluriannuelles enregistrées en charges lors du POC et non encore fiscalement acceptées [art. 22, al.",
				"campo755": "Perte fiscale imputée par l'ACE ou l'AEIE (art. 6)",
				"campo756": "Corrections relatives aux périodes fiscales antérieures (art. 18, n° 2)",
				"campo757": "Ventes et prestations de services à paiement différé : revenus d'intérêts (art. 18, n° 5)",
				"campo791": "Charges relatives aux stocks et fournitures et prestations externes à paiement différé : différence entre le montant nominal de la contrepartie et la juste valeur (art. 18, n° 5)",
				"campo758": "Annulation des effets de la méthode de mise en équivalence et de la méthode de l'intégration proportionnelle dans le cas des coentreprises soumises à l'IRC (article 18, alinéa 8)",
				"campo759": "Ajustements non imposables résultant de l'application de la juste valeur (art. 18, n° 9)",
				"campo760": "Paiements fondés sur des actions (art. 18(11))",
				"campo761": "Versement ou mise à disposition des bénéficiaires d'indemnités de fin de contrat de travail, d'indemnités de retraite et d'autres avantages postérieurs à l'emploi ou à long terme (art. 18, n° 12)",
				"campo762": "Reprise des pertes de valeur imposées (articles 28, paragraphe 3 et 28-A, paragraphe 3)",
				"campo763": "Amortissements et dépréciations imposés lors de périodes fiscales antérieures (art. 20 du DR 25/2009, du 14/9)",
				"campo781": "Pertes de valeur imposées lors d'exercices fiscaux antérieurs (articles 28, 28 -A, n° 1 et 31 -B, n° 7)",
				"campo764": "Reprise de provisions taxées (articles 19, n° 4 et 39, n° 4)",
				"campo765": "Remboursement des taxes non déductibles et estimation du surplus d'impôt",
				"campo766": "Impôts différés [art. 23.º-A, no 1, al.",
				"campo792": "Dépense fiscale relative aux actifs incorporels, aux immeubles de placement et aux actifs biologiques non consommables (art. 45-A)",
				"campo767": "Gains comptables",
				"campo768": "50% de la perte fiscale résultant des changements dans le modèle d'évaluation [art. 46, no 5, al.",
				"campo769": "Différence négative entre les plus-values ​​fiscales et les pertes fiscales (art. 46)",
				"campo770": "Corrections relatives aux instruments financiers dérivés (art. 49)",
				"campo793": "Déduction des revenus des droits d'auteur et des droits de propriété industrielle dans la limite du paragraphe 8 de l'article 50-A",
				"campo771": "Suppression de la double imposition économique des bénéfices et réserves distribués (art. 51 et 51-D)",
				"campo794": "Bénéfices des établissements stables situés en dehors du territoire portugais (art. 54 -A)",
				"campo772": "Correction par l'acquéreur du bien immobilier lors de l'adoption de la valeur fiscale définitive pour déterminer le résultat imposable du transfert respectif [art.",
				"campo795": "Déclaration des charges de financement nettes des périodes fiscales précédentes (art. 67)",
				"campo773": "Corrections résultant de l'option du régime spécial applicable aux fusions, scissions, apports d'actif et échanges de parties sociales (articles 74, 76 et 77)",
				"campo796": "Transfert de résidence, attribution d'actifs à un établissement stable situé hors du territoire portugais, cessation d'activité ou transfert d'actifs d'un établissement stable situé sur le territoire portugais : solde négatif se référant aux actifs transférés hors du territoire portugais ou affectés à un établissement stable qui y est situé ( articles 83, 84 et 54-A, n° 11)",
				"campo774": "Avantages fiscaux",
				"campo800": "Recettes et revenus relatifs aux activités de transport maritime auxquels est applicable le régime spécial de détermination de l'assiette fiscale (article 6 de l'annexe du décret-loi n° 92/2018 du 13 novembre)",
				"campo801": "Augmentation de la dépréciation ou de l'amortissement résultant des réévaluations effectuées conformément au décret-loi n° 66/2016 du 3 novembre (art. 8 du décret-loi).",
				"campo798": "Pertes dues à la dépréciation des crédits et des avantages postérieurs à l'emploi ou à long terme (art. 4 de l'annexe à la loi n° 61/2014, du 26 août)",
				"campo775": "Autres déductions",
				"campo776": "SOMME (champs 754 à 798 + 775 + 801)",
				"campo777": "PERTE POUR EFFETS FISCAUX (Si 776 > 753)",
				"campo778": "BÉNÉFICE IMPOSABLE (Si 753 >= 776) (A reporter au tableau 09)"
			},
			"quadro07Desativado": {
				"title": "(Période 2009 et antérieure) Calcul du bénéfice imposable"
			},
			"quadro08": {
				"title": "Régimes de frais",
				"sect81": {
					"title": "Régimes de réduction des taux",
					"taxas": "Taux d'imposition",
					"check242": "Établissements d'enseignement privés (ex-art. 56 de l'EBF)",
					"check245": "Prestations liées à l'intériorité (art. 41.º-B et ex-art. 43.º de l'EBF)",
					"check269": "Avantages fiscaux applicables aux territoires intérieurs (R.A. Madère) (article 19.º-A du décret législatif régional n° 28.º-A/2021/M, du 30 décembre)",
					"check270": "Avantages fiscaux applicables aux territoires intérieurs (R. A. Açores) (article 38 du décret législatif régional n° 1/2023/A du 5 janvier)",
					"check248": "Ancien statut fiscal des coopératives (article 7, paragraphe 3 de la loi nº 85/98 du 16/12)",
					"check260": "Entités agréées dans la zone franche de Madère (ex-art. 35 de l'EBF)",
					"check265": "Entités agréées dans la zone franche de Madère (articles 36 et 36-A de l'EBF)",
					"check3": "Prestations liées à l'intériorité (art. 41.º-B et ex-art. 43.º de l'EBF)"
				},
				"sect82": {
					"title": "Régime général",
					"taxas": "Taux d'imposition",
					"check246": "Région autonome des Açores (Décret législatif régional n° 2/1999/A, du 20/1)",
					"check249": "Région autonome de Madère (Décret législatif régional n° 2/2001/M, du 20/2)",
					"check262": "Revenus de la propriété des entités non-résidentes sans établissement stable (art. 87, n° 4)",
					"check263": "Gains immobiliers/augmentations de patrimoine obtenus par des entités non-résidentes sans établissement stable (art. 87, n° 4)",
					"check266": "Plus-values ​​sur titres obtenues par des entités non-résidentes sans établissement stable (art. 87, n° 4)",
					"check267": "Revenus provenant de la vente de parts de participation dans FII et de participations dans SII, gagnés par des entités non-résidentes sans établissement stable (art. 22.º-A, n° 1, alinéa c) de l'EBF)",
					"check268": "Revenus du capital non soumis au précompte mobilier définitif",
					"check264": "Autres revenus obtenus par des entités non résidentes sans établissement stable"
				}
			},
			"quadro09": {
				"title": "Détermination du revenu imposable",
				"transq7": "(Q.07 transports)",
				"reggeral": "Régime général",
				"redtaxa": "Avec réduction de taux",
				"isencao": "Avec exonération",
				"regsimp": "Régime simplifié (en vigueur jusqu'en 2010)",
				"prejFiscal": "1. PERTE FISCALE",
				"lucrotrib": "2. BÉNÉFICE IMPOSABLE",
				"regespecial": "Régime spécial pour les groupes de sociétés",
				"field380": "Somme algébrique des résultats fiscaux",
				"field381": "Bénéfices distribués (ex-art. 70, n° 2)",
				"field395": "Charges nettes de financement (option prévue à l'article 67, n°5)",
				"field500": "Ajustement REAID (art. 5, n° 1 al. b) de l'annexe à la loi n° 61/2014, 26 août)",
				"field376": "Résultats internes éliminés sous le précédent RTLC, à inclure dans le bénéfice imposable de l'exercice",
				"field382": "Résultat fiscal du Groupe",
				"table396": {
					"title": "Pertes individuelles déduites, vérifiées dans les périodes précédant le début de l'application du régime :",
					"fields": {
						"prej": "Pertes",
						"periodo": "Période",
						"nif": "FNI"
					}
				},
				"table398": {
					"title": "Parts de pertes fiscales déduites en cas d'acquisition de groupes de sociétés (article 71, alinéas 4 et 5)",
					"fields": {
						"prej": "Pertes",
						"periodo": "Période",
						"nif": "FNI"
					}
				},
				"prejfiscded": "Pertes fiscales déductibles",
				"prejfiscaut": "Pertes fiscales autorisées/transmises (art. 75, paragraphes 1 et 3)",
				"prejfiscaut2": "Pertes fiscales autorisées/transmises [art. 15, no 1, al.",
				"alttitularidade": "Changement de détention de plus de 50% du capital social ou de la majorité des droits de vote - Pertes fiscales non déductibles (art. 52, n° 8)",
				"deducoes": "3. DÉDUCTIONS",
				"subtitledesc": "Répartition des déficits fiscaux déduits, par période de calcul et montant",
				"subtitleregesp": "Régime spécial applicable aux acquéreurs d'entités considérées comme des entreprises en difficulté - article 15 de la loi n° 27-A/2020, du 24 juillet.",
				"table309": {
					"fields": {
						"periodo": "309.1 - Période",
						"montante": "309.2 - Montant"
					}
				},
				"table320": {
					"fields": {
						"periodo": "320.1 - Période",
						"montante": "320.2 - Montant"
					}
				},
				"table331": {
					"fields": {
						"periodo": "331.1 - Période",
						"montante": "331.2 - Montant"
					}
				},
				"benfisc": "Avantages fiscaux",
				"metcoletavel": "4. MATÉRIEL À COLLECTIONNER (2-3)",
				"field336": "ZFM - Montant imposable qui dépasse les plafonds maximaux (articles 36, n° 3 et 36-A, n° 4 de l'EBF)",
				"field399": "COLLECTIFS SPORTIFS - Déduction des sommes investies à hauteur de 50% du montant imposable (art. 54, n° 2 de l'EBF)",
				"existprej": "S’il existe des pertes fiscales autorisées/transmises, indiquez :",
				"field397": "Montant total utilisé dans la période (397-A + 397-B)",
				"table397a": {
					"title": "Valeur utilisée dans la période [art. 15, no 1 al.",
					"fields": {
						"valor": "397.A - Valeur utilisée dans la période",
						"nif": "FNI",
						"periodo": "397.C - Période"
					}
				},
				"table397b": {
					"title": "Valeur utilisée dans la période (article 75, paragraphes 1 et 3)",
					"fields": {
						"valor": "397.B - Valeur utilisée dans la période",
						"nif": "FNI",
						"periodo": "397.D - Période"
					}
				},
				"table309B": {
					"fields": {
						"montante": "309.4 - Montant",
						"nif": "309.5 - NIF de l'entreprise considérée comme une entreprise en difficulté",
						"periodo": "309.3 - Période"
					}
				},
				"field300": "Revenu imposable pour le régime spécial (champ 11 du tableau 04 de l'annexe G)",
				"field346": "MATÉRIAUX DE COLLECTE NON EXEMPTÉS [(311 - 399) + 322 + 336] ou 409 ou champ 42 de l'annexe E, sauf champ 300"
			},
			"quadro10": {
				"title": "Calcul de la taxe",
				"field347A": "Impôt au taux normal (art. 87, n° 2, 1er 50 000,00 € d'assiette pour les PME ou PME) (c. 311 de Q.09 de M22 ou C. 42 de l'Annexe E) x 17 %",
				"field347B": "Taxe au taux normal (art. 87, n°1) (c. 311 du q.09 du m22 ou c. 42 de l'annexe E) x 21%",
				"impoutrataxas": "Taxe à d'autres taux",
				"field350": "Impôt imputable à la Région Autonome des Açores",
				"field370": "Impôt imputable à la Région Autonome de Madère",
				"field351": "COLLECTION (347-A + 347-B + 349 + 350 + 370)",
				"field373": "Déversement étatique (art. 87 - A)",
				"field378": "COLLECTE TOTALE (351 +373)",
				"deducoes": "Déductions",
				"field353": "Double imposition légale internationale (DTJI - art. 91)",
				"field375": "Double imposition économique internationale (art. 91-A)",
				"field355": "Avantages fiscaux",
				"field470": "Supplémentaire à la taxe foncière communale (article 135.º-J du CIMI)",
				"field356": "Acompte spécial (art. 93)",
				"field357": "TOTAL DES DÉDUCTIONS (353 + 375 + 355 + 356 + 470) <= 378",
				"field358": "TOTAL CIT PAYÉ (378 - 357) >= 0",
				"field371": "Résultat de la liquidation (art. 92)",
				"field359": "Retenues à la source",
				"field360": "Acomptes (art. 105) et Acompte autonome (Loi n° 7-A/2016, du 30 mars, art. 136, n° 2)",
				"field374": "Acomptes complémentaires (art. 105 - A)",
				"field361": "IRC PAYABLE (358 + 371 - 359 - 360 - 374) > 0",
				"field362": "IRC À RÉCUPÉRER (358 + 371 - 359 - 360 - 374) < 0",
				"field363": "IRC des périodes précédentes",
				"field372": "Remplacement des avantages fiscaux",
				"field364": "Déversement municipal",
				"field379": "Double imposition légale internationale (art. 91) - Pays avec CDT et lorsque DTJI > 378",
				"field365": "Fiscalité autonome",
				"field366": "Intérêts compensatoires",
				"field369": "Intérêts de retard",
				"field367": "TOTAL À PAYER [361 ou (-362) + 363 + 372 + 364 - 379 + 365 + 366 + 369] > 0",
				"field368": "TOTAL À RÉCUPÉRER [(-362) + 363 + 372 + 364 - 379 + 365 + 366 + 369] < 0",
				"campo10A": {
					"title": "Intérêts compensatoires",
					"desc": "Répartition de la valeur indiquée au champ 366 du tableau 10 :",
					"field366A": "Intérêts compensatoires déclarés pour retard dans le dépôt de la déclaration",
					"field366B": "Intérêts compensatoires déclarés pour d'autres motifs"
				},
				"campo10b": {
					"title": "Transfert de résidence/cessation d'activité d'établissement stable/attribution de patrimoine (articles 83, 84 et 54-A, n°11)",
					"data4": "Date de l'événement",
					"desc": "Mode de paiement de la taxe correspondante (art. 83, n° 2)",
					"radio1": "immédiat [al.",
					"radio2": "différé [al.",
					"radio3": "fractionné [al.",
					"valpagdiffracio": "Montant du paiement différé ou fractionné",
					"field377A": "IRC + surtaxe d'Etat",
					"field377B": "Déversement municipal"
				},
				"field377": "Total des paiements différés ou fractionnés (377-A + 377-B)",
				"field430": "TOTAL À PAYER (367 -377) > 0",
				"field431": "TOTAL À RÉCUPÉRER [367 ou (- 368) - 377] < 0",
				"modal": {
					"newtaxa": {
						"title": "Modifier le taux de coulée",
						"field": "Le taux coule",
						"tooltip": "Le taux coule"
					}
				},
				"consideraPrejuizoFiscal": "Perte fiscale",
				"tributAutonomas": "Fiscalité autonome"
			},
			"quadro11": {
				"title": "Autres informations",
				"field410": "Revenu total pour la période",
				"field411": "Chiffre d'affaires de la période (à détailler dans le tableau 11-B, le cas échéant)",
				"field416": "Différence positive entre la valeur prise en compte aux fins du règlement IMT et la valeur contenue dans le contrat, dans les cas où la procédure prévue à l'article 139 a été utilisée.",
				"data418": "Date à laquelle le transfert des actions sociales a eu lieu (article 51, paragraphe 9 et article 88, paragraphe 11)",
				"check423": "Dans le cas d'une micro-entité, indiquez si, comme alternative aux normes comptables pour les micro-entités (NC-ME), vous choisissez d'appliquer les normes comptables et financières pour les petites entités (NCRF-PE) ou les normes comptables et financières. normes de reporting (NCRF) [art. 9.º-D du DL no. 158/2009, du 13 juillet]",
				"check429": "Une fusion avec effet rétroactif est-elle intervenue au cours de la période fiscale (n° 11 de l'article 8) dont vous êtes la société bénéficiaire ?",
				"check455": "Une fusion a-t-elle eu lieu au cours de l’année 2020 sous le régime particulier prévu aux articles 73 et suivants du Code IRC ?",
				"sim": "Oui?"
			},
			"quadro11A": {
				"title": "Actifs d'impôts différés (AID) - Loi n° 61/2014 du 26 août",
				"AID": "Ventilation des AID incluses dans les états financiers visés au Mod.",
				"field460": "AIDE aux dépréciations des crédits couverts par REAID",
				"field461": "Aide aux avantages postérieurs à l'emploi ou à long terme des salariés couverts par le REAID",
				"field462": "Autres aides",
				"field463": "Équité",
				"field464": "Crédit d'impôt",
				"data465": "Date d'entrée en liquidation",
				"infoadd": "Informations Complémentaires"
			},
			"quadro11B": {
				"title": "Répartition du chiffre d'affaires annuel de la période par districts (continentale, Açores et Madère)",
				"desc": "L'entreprise possède-t-elle des succursales, des délégations, des agences, des bureaux, des installations ou toute forme de représentation permanente sans personnalité juridique propre dans plus d'une circonscription ?",
				"radio6": "Oui",
				"radio7": "Non",
				"desc2": "Si vous avez répondu oui, indiquez quelles circonscriptions :",
				"radio8": "Continent",
				"radio9": "Bois",
				"radio10": "Açores",
				"field1": "Chiffre d'affaires mondial non exonéré",
				"field2": "Chiffre d'affaires, non exonéré, attribuable aux installations situées dans la Région Autonome de Madère (RAM)",
				"field3": "Chiffre d'affaires non exonéré attribuable aux installations situées dans la Région Autonome des Açores (RAA)",
				"field4": "Ratio 1 (RAM) = (champ 2 : champ 1)",
				"field5": "Ratio 2 (RAA) = (champ 3 : champ 1)",
				"field22": "Rapport 3 (CONTINENT) = 1 - (rapport 1 + rapport 2)"
			},
			"quadro12": {
				"title": "Retenues à la source",
				"table": {
					"fields": {
						"nif": "Numéro d'identification fiscale (NIF)",
						"retfonte": "Retenue à la source"
					}
				}
			},
			"quadro13": {
				"title": "Fiscalité autonome",
				"desc": "Description",
				"basetrib": "Base imposable",
				"field414": "Frais de représentation (art. 88, no. 7)",
				"field415": "Frais engagés ou supportés avec les indemnités de subsistance et les indemnités de déplacement dans le propre véhicule du salarié (art. 88, n° 9)",
				"field417": "Bénéfices distribués par les entités soumises à l'IRC aux contribuables bénéficiant de l'exonération totale ou partielle (article 88, alinéa 11)",
				"field420": "Redevances sur les véhicules (ancienne rédaction de l'article 88, n°3) (régime en vigueur jusqu'au 31/12/2013)",
				"field421": "Redevances sur les véhicules (ex-art. 88, n°4) (régime en vigueur jusqu'au 31/12/2013)",
				"field422": "Indemnité de cessation des fonctions de gérant, d'administrateur ou de gérant [art. 88, no 13, al.",
				"field424": "Dépenses ou charges relatives aux primes et autres rémunérations variables versées aux dirigeants, administrateurs ou gérants [art 88, no 13, al.",
				"field425": "Charges non déductibles au sens de l'art.",
				"field426": "Redevances pour véhicules légers de tourisme et de marchandises - Si CA < 27 500,00 € [art. 88, n° 3, al.",
				"field427": "Redevances pour véhicules légers de tourisme et de fret - Si CA >= 27 500,00 € et < 35 000,00 € [art.",
				"field428": "Redevances pour véhicules légers de tourisme et de marchandises - Si CA >= 35 000,00 € [art. 88, n° 3, al.",
				"field432": "Redevances pour les voitures particulières hybrides rechargeables, dont la batterie peut être chargée via une connexion au réseau électrique et qui ont une autonomie minimale, en mode électrique, de 50 km et des émissions officielles inférieures à 50 gCO(indice 2)/km, et véhicules légers de tourisme propulsés au gaz naturel automobile (GNV) - Si CA < 27 500,00 € [art.",
				"field433": "Redevances pour les voitures particulières hybrides rechargeables, dont la batterie peut être chargée via une connexion au réseau électrique et qui ont une autonomie minimale, en mode électrique, de 50 km et des émissions officielles inférieures à 50 gCO(indice 2)/km, et véhicules légers de tourisme fonctionnant au gaz naturel automobile (GNV) - Si CA >= 27 500,00 € et < 35 000,00 €",
				"field434": "Redevances pour les voitures particulières hybrides rechargeables, dont la batterie peut être chargée via une connexion au réseau électrique et qui ont une autonomie minimale, en mode électrique, de 50 km et des émissions officielles inférieures à 50 gCO(indice 2)/km, et véhicules légers de tourisme propulsés au gaz naturel automobile (GNV) - Si CA >= 35 000,00 € [art.",
				"field435": "Redevances pour les véhicules légers de tourisme fonctionnant au GNV - Si CA < 27 500,00 € [art. 88, n° 3, al.",
				"field436": "Redevances pour les véhicules légers de tourisme alimentés au GNV - Si CA >= 27 500,00 € et < 35 000,00 € [art.",
				"field437": "Redevances pour les véhicules légers de tourisme fonctionnant au GNV - Si CA >= 35 000,00 € [art.",
				"field438": "Dépenses non documentées [art.",
				"field439": "Montants payés ou dus à des entités non-résidentes soumises à un régime fiscal privilégié [art.",
				"field456": "Charges liées aux véhicules alimentés exclusivement à l'électricité dont le coût d'acquisition dépasse 62 500,00 € [art.",
				"campoIniciouAtividade": "Avez-vous démarré l'activité dans la période fiscale précédant celle à laquelle se rapporte la déclaration ?",
				"field13e3": "Si vous avez démarré votre activité dans l'une des périodes fiscales 2018, 2019, 2020 ou 2021, indiquez la date à laquelle vous avez démarré votre activité"
			},
			"quadro13A": {
				"title": "Fiscalité autonome - Zone de libre-échange de Madère (art. 36.º-A, n° 14 de l'EBF)",
				"desc": "Description",
				"basetrib": "Base imposable",
				"field440": "Frais de représentation (art. 88, no. 7)",
				"field441": "Frais engagés ou supportés avec les indemnités de subsistance et les indemnités de déplacement dans le propre véhicule du salarié (art. 88, n° 9)",
				"field442": "Bénéfices distribués par les entités soumises à l'IRC aux contribuables bénéficiant de l'exonération totale ou partielle (article 88, alinéa 11)",
				"field443": "Indemnité de cessation des fonctions de gérant, d'administrateur ou de gérant [art. 88, no 13, al.",
				"field444": "Dépenses ou charges relatives aux primes et autres rémunérations variables versées aux dirigeants, administrateurs ou gérants [art 88, no 13, al.",
				"field445": "Charges non déductibles au sens de l'art.",
				"field446": "Redevances pour véhicules légers de tourisme et de marchandises - Si CA < 27 500,00 € [art. 88, n° 3, al.",
				"field447": "Redevances pour véhicules légers de tourisme et de fret - Si CA >= 27 500,00 € et < 35 000,00 € [art.",
				"field448": "Redevances pour véhicules légers de tourisme et de marchandises - Si CA >= 35 000,00 € [art. 88, n° 3, al.",
				"field449": "Redevances pour les voitures particulières hybrides rechargeables, dont la batterie peut être chargée via une connexion au réseau électrique et qui ont une autonomie minimale, en mode électrique, de 50 km et des émissions officielles inférieures à 50 gCO(indice 2)/km, et véhicules légers de tourisme propulsés au gaz naturel automobile (GNV) - Si CA < 27 500,00 € [art.",
				"field450": "Redevances pour les voitures particulières hybrides rechargeables, dont la batterie peut être chargée via une connexion au réseau électrique et qui ont une autonomie minimale, en mode électrique, de 50 km et des émissions officielles inférieures à 50 gCO(indice 2)/km, et véhicules légers de tourisme fonctionnant au gaz naturel automobile (GNV) - Si CA >= 27 500,00 € et < 35 000,00 €",
				"field451": "Redevances pour les voitures particulières hybrides rechargeables, dont la batterie peut être chargée via une connexion au réseau électrique et qui ont une autonomie minimale, en mode électrique, de 50 km et des émissions officielles inférieures à 50 gCO(indice 2)/km, et véhicules légers de tourisme propulsés au gaz naturel automobile (GNV) - Si CA >= 35 000,00 € [art.",
				"field452": "Redevances pour les véhicules légers de tourisme fonctionnant au GNV - Si CA < 27 500,00 € [art. 88, n° 3, al.",
				"field453": "Redevances pour les véhicules légers de tourisme alimentés au GNV - Si CA >= 27 500,00 € et < 35 000,00 € [art.",
				"field454": "Redevances pour les véhicules légers de tourisme fonctionnant au GNV - Si CA >= 35 000,00 € [art.",
				"field457": "Charges liées aux véhicules alimentés exclusivement à l'électricité dont le coût d'acquisition dépasse 62 500,00 € [art."
			},
			"quadro14": {
				"title": "Crédit d'impôt pour double imposition légale internationale (CIDTJI)",
				"table": {
					"fields": {
						"codpais": "Code du pays",
						"tiprend": "Type de revenu",
						"periodo": "Période CIDTJI",
						"salcaducado": "Solde expiré",
						"saldnaored": "Solde non déduit",
						"apurapreio": "Calcul dans la période",
						"dedperio": "Déduction effectuée dans la période",
						"saldotrans": "Solde reporté",
						"imppagestr": "Impôt payé à l'étranger [art. 91, no 1, al.",
						"facimprendest": "Fraction de l'impôt relative aux revenus obtenus à l'étranger [art. 91, no 1, al.",
						"credpreio": "Crédit d'impôt périodique",
						"paisComCDT": "Pays avec CDT"
					}
				},
				"totCIDTJIcomCDT": "CIDTJI TOTAL avec CDT",
				"totCIDTJIsemCDT": "CIDTJI TOTAL sans CDT",
				"totCIDTJIJI": "TOTAL CIDTJI"
			}
		},
		"anexoA": {
			"quadro3": {
				"title": "Informations pertinentes",
				"dadosgerais": "Données générales",
				"dadosesp": "Données spécifiques - Centres de Production d'Electricité/Mine",
				"field1": "Bénéfice imposable total (champ 302 + 313 de Q. 09)",
				"field2": "Bénéfice imposable dans la zone franche de Madère (champ 313 de Q. 09) (art. 36-A de l'EBF)",
				"field10": "Somme algébrique du revenu imposable au régime spécial et du bénéfice imposable au régime général (champ 300 + champ 302)",
				"field3": "Masse salariale totale",
				"check4": "Est-ce la 1ère année d'application du régime ?",
				"field5": "Superficie totale d’installation ou d’exploration (ha2)",
				"field6": "Puissance totale installée (MW)",
				"field7": "Électricité totale produite (GWh)",
				"field8": "Valeur totale de la production à l'embouchure de la mine (en euros)",
				"field9": "Masse salariale totale + prestation de services"
			},
			"quadro4": {
				"title": "Calcul de la surtaxe communale",
				"massasaltot": "Masse salariale totale",
				"somacontr": "Somme de contrôle",
				"massasalprestmun": "Facture salariale + prestation de services municipaux",
				"areintexplomun": "Zone d'installation.",
				"poteinstmun": "Puissance installée dans la commune",
				"toteletrprodmun": "Totalement électrique.",
				"prodbocaminamun": "Production à l'embouchure de la mine sur la commune",
				"sect04-A": {
					"title": "Critères généraux",
					"table": {
						"fields": {
							"field1": "Code du district/commune (1)",
							"field2": "Taux de déversement (2)",
							"field3": "Masse salariale communale (3)",
							"field4": "Rapport de distribution (4) = (3) / (Q.03, C3)",
							"field5": "DÉVERSEMENT CALCULÉ (5) = (Q.03, C1 x (2) x (4)) ou (Q.03, C10) x (2) x (4)"
						}
					}
				},
				"sect04-B": {
					"title": "Critères généraux - Zone de libre-échange de Madère (Art. 36.º -A, n° 12 de l'EBF)",
					"table": {
						"fields": {
							"field1": "Code du district/commune (1)",
							"field2": "Taux de déversement (2)",
							"field3": "Masse salariale communale au ZFM (3)",
							"field4": "Rapport de distribution (4) = (3) / (Q.03, C3)",
							"field5": "Déversement calculé (5) = [(Q.03, C2 x (2) x (4))] x 0,2"
						}
					}
				},
				"sect04-C": {
					"title": "Critère spécifique - centres de production électrique",
					"table": {
						"fields": {
							"field10": "Indiquez s'il s'agit de la 1ère année d'application du critère (10)",
							"field1": "Code du district/commune (1)",
							"field2": "Taux de déversement (2)",
							"field3": "Facture salariale + prestation de services communaux (MSPSMunic) (3)",
							"field4": "Zone d'installation.",
							"field5": "Puissance installée dans la commune (PIMUnic) (5)",
							"field6": "Électricité totale.",
							"field7": "Ratio de municipalités (7)",
							"field8": "Déversement calculé (8) = (Q03 C1) x (2) x (7)"
						}
					}
				},
				"sect04-D": {
					"title": "Critères spécifiques - Minas",
					"table": {
						"fields": {
							"field9": "Indiquez s'il s'agit de la 1ère année d'application du critère (9)",
							"field1": "Code du district/commune (1)",
							"field2": "Taux de déversement (2)",
							"field3": "Facture salariale + prestation de services communaux (MSPSMunic) (3)",
							"field4": "Zone d'installation.",
							"field5": "Production à l'embouchure de la mine dans la commune (PBMunic) (5)",
							"field6": "Ratio de municipalités (6)",
							"field7": "Déversement calculé (7) = (Q03 C1) x (2) x (6)"
						}
					}
				}
			}
		},
		"anexoC": {
			"quadro04": {
				"title": "Régime général et régime simplifié avec application de tarifs régionaux",
				"varglob": "Valeurs mondiales",
				"field6": "MATÉRIAUX À COLLECTER (champs 311 + 336, sauf champ 300, du tableau 09 de la déclaration ou champ 42 de l'annexe E)",
				"field6A": "MONTANT COLLECTABLE RÉGIME SPÉCIAL (Décret-loi n° 92/2018, du 13/11) - (champ 300 du tableau 09 de la déclaration)",
				"field7A": "COLLECTE : Si PME ou PME - jusqu'à 50 000,00 € (montant jusqu'à 50 000,00 € du champ 6 x 17%)",
				"field7B": "COLLECTE : Si PME ou PME - supérieur à 50 000,00 € [(champ 6 - 50 000,00 €) x 21%] ou si Grande entreprise (champ 6 x 21%)",
				"field7C": "COLLECTE du RÉGIME SPÉCIAL (Décret-loi n° 92/2018, du 13/11) - (champ 6-A x 21%)",
				"ram": "BÉLIER",
				"field8A": "COLLECTION RAM : Si PME ou PME - à hauteur de 50 000,00 € (champ 4 du tableau 11-B de la déclaration x montant à hauteur de 50 000,00 € x 11,9%) - à transporter au champ 370 de la déclaration",
				"field8B": "RAM COLLECTION : Si PME ou PME - supérieur à 50 000,00 € [champ 4 du tableau 11-B de la déclaration x (champ 6 - 50 000,00 €) x 14,7%] ou si Grande entreprise [champ 4 du tableau 11-B de la déclaration x champ 6 x 14,7%] - à transférer dans le champ 370 de la déclaration",
				"field8C": "COLLECTE du RÉGIME SPÉCIAL (Décret-loi n° 92/2018, du 13/11) en RAM - (champ 4 du tableau 11-B de la déclaration x champ 6-A x 14,7%) - à transporter sur le terrain 370 de la déclaration",
				"raa": "RAA",
				"field9A": "COLLECTE RAA : Si PME ou PME - à hauteur de 50 000,00 € [champ 5 du tableau 11-B de la déclaration x (montant à hauteur de 50 000,00 € du champ 6) x 11,9%] - à transporter au champ 350 de la déclaration",
				"field9B": "RAA COLLECTE : Si PME ou PME - supérieur à 50 000,00 € [champ 5 du tableau 11-B de la déclaration x (champ 6 - 50 000,00 €) x 14,7%] ou si Grande Entreprise (champ 5 du tableau 11-B de la déclaration x champ 6 x 14,7%) - à transférer dans le champ 350 de la déclaration",
				"field9C": "COLLECTE du RÉGIME SPÉCIAL (Décret-loi n° 92/2018, du 13/11) dans le RAA - (champ 5 du tableau 11-B de la déclaration x champ 6-A x 14,7%) - à transporter au champ 350 de la déclaration",
				"continente": "Continent",
				"field10A": "COLLECTE MÉTROPOLE : Si PME ou PME - à hauteur de 50 000,00 € (champ 22 du tableau 11-B de la déclaration x champ 7-A) - à transporter vers le champ 347-A de la déclaration",
				"field10B": "COLLECTE MÉTROPOLE : Si PME ou SMC - supérieur à 50 000,00 € (champ 22 du tableau 11-B de la déclaration x champ 7-B) ou si Grande entreprise (champ 22 du tableau 11-B de la déclaration x champ 7-B) - à transporter vers le champ 347-B de la déclaration",
				"field10C": "COLLECTE du RÉGIME SPÉCIAL (Décret-loi n° 92/2018, du 13/11) sur le CONTINENT (champ 22 du tableau 11-B de la déclaration x champ 7-C) - à transporter au champ 347-B du la déclaration"
			},
			"quadro05": {
				"title": "Ancien régime simplifié (ex-article 58 du CIRC), régimes de réduction de taux, entités n'exerçant pas d'activités commerciales, industrielles ou agricoles comme activité principale",
				"field11": "Matériel taxable (champs 311 à 399 ou champ 322 ou champ 409 du tableau 09 de la déclaration)",
				"coleta": "Collecte : (champ 11 x frais)",
				"field13": "RAM COLLECTION : (champ 4 du tableau 11-B de la déclaration x champ 12) - à transporter vers le champ 370 de la déclaration",
				"field14": "RAA COLLECTION : (champ 5 du tableau 11-B de la déclaration x champ 12 x 0,7) - à transporter vers le champ 350 de la déclaration",
				"field15": "COLLECTION du CONTINENT : (champ 22 du tableau 11-B de la déclaration x champ 12) - à transporter vers le champ 349 de la déclaration"
			},
			"quadro06": {
				"title": "Régime général sans application de redevances régionales",
				"varglob": "Valeurs mondiales",
				"field16": "Matériel imposable (champ 311 du tableau 09 de la déclaration)",
				"field17A": "COLLECTE : Si PME ou PME - jusqu'à 50 000,00 € (montant jusqu'à 50 000,00 € du champ 16 x 17 %)",
				"field17B": "COLLECTE : Si PME ou PME - supérieur à 50 000,00 € [(champ 16 - 50 000,00 €) x 21%] ou si Grande entreprise (champ 16 x 21%)",
				"ram": "BÉLIER",
				"field18A": "RAM COLLECTION : Si PME ou SMC - à hauteur de 50 000,00 € (champ 4 du tableau 11-B de la déclaration x champ 17-A) - à virer au champ 370 de la déclaration",
				"field18B": "RAM COLLECTION : Si PME ou SMC - supérieur à 50 000,00 € (champ 4 du tableau 11-B de la déclaration x champ 17-B) ou si Grande entreprise (champ 4 du tableau 11-B de la déclaration x champ 17-B) - à transférer dans le champ 370 de la déclaration",
				"raa": "RAA",
				"field19A": "COLLECTION RAA : Si PME ou PME - à hauteur de 50 000,00 € (champ 5 du tableau 11-B de la déclaration x champ 17-A) à virer au champ 350 de la déclaration",
				"field19B": "COLLECTE RAA : Si PME ou SMC - supérieur à 50 000,00 € (champ 5 du tableau 11-B de la déclaration x champ 17-B) ou si Grande entreprise (champ 5 du tableau 11-B de la déclaration x champ 17-B) - à transférer dans le champ 350 de la déclaration",
				"continente": "Continent",
				"field20A": "COLLECTE MÉTROPOLE : Si PME ou PME - à hauteur de 50 000,00 € (champ 22 du tableau 11-B de la déclaration x champ 17-A) - à transporter vers le champ 347-A de la déclaration",
				"field20B": "COLLECTE MÉTROPOLE : Si PME ou SMC - supérieur à 50 000,00 € (champ 22 du tableau 11-B de la déclaration x champ 17-B) ou si Grande entreprise (champ 22 du tableau 11-B de la déclaration x champ 17-B) - à transporter vers le champ 347-B de la déclaration"
			},
			"quadro06A": {
				"title": "Régime des groupes d'entreprises avec application de tarifs régionaux",
				"varglob": "Valeurs mondiales",
				"field22": "Matériel imposable (Champ 311 du tableau 09 de la déclaration)",
				"field22A": "COLLECTE : Si PME ou PME - jusqu'à 50 000,00 € (montant jusqu'à 50 000,00 € du champ 22 x 17%)",
				"field22B": "COLLECTE : Si PME ou PME - supérieur à 50 000,00 € [(champ 22 - 50 000,00 €) x 21%] ou si Grande entreprise (champ 22 x 21%)",
				"ram": "BÉLIER",
				"field23A": "RAM COLLECTION : Si PME ou SMC - à hauteur de 50 000,00 € (champ 4 du tableau 11-B de la déclaration x montant à hauteur de 50 000,00 du champ 22 x 11,9%) - à transporter au champ 370 de la déclaration",
				"field23B": "RAM COLLECTION : Si PME ou PME - supérieur à 50 000,00 € (champ 4 du tableau 11-B de la déclaration x (champ 22 - 50 000,00) x 14,7%) ou si Grande entreprise (champ 4 du tableau 11-B de la déclaration x champ 22 x 14,7%) - à transférer dans le champ 370 de la déclaration",
				"raa": "RAA",
				"field24A": "COLLECTION RAA : Si PME ou PME - à hauteur de 50 000,00 € (champ 5 du tableau 11-B de la déclaration x montant à hauteur de 50 000,00 du champ 22 x 11,9%) à transporter au champ 350 de la déclaration",
				"field24B": "RAA COLLECTE : Si PME ou PME - supérieur à 50 000,00 € (champ 5 du tableau 11-B de la déclaration x (champ 22 - 50 000,00) x 14,7%) ou si Grande entreprise (champ 5 du tableau 11-B de la déclaration x champ 22 x 14,7%) - à transférer dans le champ 350 de la déclaration"
			},
			"quadro07": {
				"title": "Avantage de réduction d'impôt applicable aux territoires intérieurs relevant du champ d'application de la R. A. Madeira",
				"varglob": "Valeurs mondiales",
				"ram": "BÉLIER",
				"raa": "RAA",
				"continente": "Continent",
				"field1": "MATÉRIAUX DE COLLECTE (Champ 42 de l'Annexe E ou Champ 322 du tableau 09)",
				"field2A": "COLLECTE : PME ou PME - jusqu'à 50 000,00 € (montant jusqu'à 50 000,00 € du champ 1 x 17%)",
				"field2B": "COLLECTE : PME ou PME - plus de 50 000,00 € [(champ 1 - 50 000,00 €) x 21%]",
				"field3A": "COLLECTION RAM : PME ou PME - à hauteur de 50 000,00 € (champ 4 du tableau 11-B de la déclaration x montant à hauteur de 50 000,00 € x 8,75%) - à virer au champ 370 de la déclaration",
				"field3B": "RAM COLLECTION : PME ou PME - supérieur à 50 000,00 € [champ 4 du tableau 11-B de la déclaration x (champ 1 - 50 000,00 €) x 14,7%] - à virer au champ 370 de la déclaration",
				"field4A": "COLLECTION RAA : PME - à hauteur de 50 000,00 € [champ 5 du tableau 11-B de la déclaration x (montant à hauteur de 50 000,00 € du champ 1) x 8,75%] - à virer au champ 350 de la déclaration",
				"field4B": "COLLECTE RAA : PME - supérieur à 50 000,00 € [champ 5 du tableau 11-B de la déclaration x (champ 1 - 50 000,00 €) x 14,7%] - à virer au champ 350 de la déclaration",
				"field5A": "COLLECTE MÉTROPOLE : PME ou PME - à hauteur de 50 000,00 € (champ 22 du tableau 11-B de la déclaration x champ 2-A) - à transporter vers le champ 349 de la déclaration",
				"field5B": "COLLECTE MÉTROPOLE : PME ou SMC - supérieur à 50 000,00 € (champ 22 du tableau 11-B de la déclaration x champ 2-B) - à transporter vers le champ 347-B de la déclaration"
			}
		},
		"anexoD": {
			"quadro03": {
				"title": "Revenu exonéré",
				"desc31": "Exonération définitive",
				"rendliqu": "Revenu net",
				"field301": "Personnes morales d'utilité publique et de solidarité sociale (article 10 du CIRC)",
				"field302": "Activités culturelles, récréatives et sportives (art. 11 du CIRC et art. 54, n° 1 de l'EBF)",
				"field303": "Coopératives (art. 66-A de la FBE)",
				"field313": "Entrepreneurs ou soumissionnaires, en ce qui concerne les bénéfices tirés des travaux et des travaux sur les infrastructures communes de l'OTAN (art. 14, n° 2 du CIRC)",
				"field314": "Fonds de pension et assimilés (art. 16, n° 1 de la FBE) et autres fonds définitivement exonérés",
				"field316": "Entité centrale de stockage : résultats nets de la période enregistrés dans la gestion des réserves stratégiques de pétrole (art. 25.º-A du décret-loi n° 165/2013, du 16 décembre)",
				"field304": "Autres exonérations définitives",
				"desc31A": "Champ 314 - Fonds de pension et assimilés (art. 16, n° 1 de la FBE) et autres fonds définitivement exonérés",
				"desc31B": "Zone 304 - Autres exemptions définitives",
				"desc32": "Dispense temporaire",
				"field305": "Zone franche de Madère et de l'île Santa Maria (art. 33, n° 1 de l'EBF)",
				"field306": "Entités gérant les appellations d'origine et les indications géographiques (art. 52 de l'EBF)",
				"field307": "Entités gérant des systèmes intégrés de gestion de flux de déchets spécifiques (art. 53 de l'EBF)",
				"field308": "Associations publiques, confédérations, syndicats et associations d'employeurs et associations de parents (art. 55 de la FBE)",
				"field309": "Sociétés ou associations scientifiques internationales (ex-art. 57 de l'EBF)",
				"field310": "Friches et collectivités locales (art. 59 de l'EBF)",
				"field311": "Mesures de soutien au transport routier de personnes et de marchandises [plus-values ​​exonérées (art. 70 de la FBE)]",
				"field315": "Fonds d'épargne en actions (art. 26 de la FBE) et autres fonds temporairement exonérés",
				"field317": "Revenus perçus par les entités de gestion forestière (EGF) et les unités de gestion forestière (UGF) (art. 59.º-G de l'EBF)",
				"field312": "Autres exemptions temporaires",
				"desc32A": "Champ 315 - Fonds d'épargne en actions (art. 26 de la FBE) et autres fonds temporairement exonérés",
				"desc32B": "Zone 312 - Autres exemptions temporaires",
				"table": {
					"fields": {
						"codben": "Code avantage",
						"montante": "Montant"
					}
				}
			},
			"quadro04": {
				"title": "Déductions sur les revenus (à déduire dans le champ 774 du tableau 07 de la déclaration)",
				"normalegal": "Dispositions légales",
				"dedefetuada": "Déduction effectuée",
				"field401": "Augmentation de la création d'emplois (art. 19 du FBE)",
				"field402": "Fonds d'investissement [art. 22, n° 14, al.",
				"field403": "Élimination de la double imposition économique des bénéfices distribués par les sociétés résidentes des PALOP et du Timor-Leste (ex-art. 42 de l'EBF)",
				"field404": "Majorations appliquées aux avantages fiscaux pour les espaces intérieurs [ex-art. 43, n° 1, al.",
				"field405": "Compagnies maritimes marchandes nationales (art. 51 de l'EBF)",
				"field406": "Majorations appliquées aux dons prévus aux articles 62, 62-A et 62-B de la FBE",
				"field426": "Majorations appliquées aux dons prévus aux articles 62 de la FBE - Mécénat social, sportif et environnemental",
				"field427": "Majorations appliquées aux dons prévus à l'article 62-A de l'EBF - Mécénat scientifique",
				"field428": "Majorations appliquées aux dons prévus à l'article 62-B de l'EBF - Mécénat culturel",
				"field432": "Majorations appliquées aux dons relatifs au mécénat culturel extraordinaire pour 2021 et 2022 (art. 397.º de la loi n°75-B/2020, du 31 décembre et art. 27 juin)",
				"field407": "Augmentation des cotisations des entreprises (article 44 du CIRC)",
				"field408": "Majoration appliquée aux dépenses engagées lors de l'acquisition, sur le territoire portugais, de carburants pour le ravitaillement des véhicules (art. 70, n° 4 de l'EBF)",
				"field409": "Rémunération conventionnelle du capital social (art. 136 de la loi n° 55-A/2010, du 31/12 et art. 41.-A de l'EBF)",
				"field412": "Augmentation des dépenses liées aux garderies, aux lactations et aux jardins d'enfants (art. 43, n° 9 du CIRC)",
				"field413": "Augmentation des dépenses engagées par les coopératives en application de la réserve pour l'éducation et la formation (art. 66-A, n° 7 de la FBE)",
				"field414": "Bénéfices mis à disposition et revenus d'intérêts perçus par les associés ou actionnaires des sociétés agréées à la ZFM (article 36-A, alinéas 10 et 11, de l'EBF)",
				"field415": "Augmentation des dépenses engagées pour l'acquisition d'électricité, de GNC pour le ravitaillement des véhicules (art. 59.º-A de l'EBF)",
				"field416": "Augmentation des dépenses avec les systèmes d'autopartage et de vélopartage (art. 59.º-B de l'EBF)",
				"field417": "Augmentation des dépenses avec les flottes de vélos (art. 59.º-C de l'EBF)",
				"field418": "Augmentation des dépenses supportées par les propriétaires et producteurs forestiers adhérant à la zone d'intervention forestière avec les contributions financières affectées au fonds commun et les charges de défense forestière (art. 59.º-D, n° 12 de la FBE)",
				"field419": "Dépenses majorées avec la certification d'une ferme biologique (art. 59-E de l'EBF)",
				"field420": "Augmentation des dépenses et des pertes dans le cadre des partenariats obligataires à impact social (art. 19-A de l'EBF)",
				"field421": "Augmentation des dépenses et des pertes liées aux travaux de conservation et d'entretien des bâtiments ou parties de bâtiments affectés à des magasins avec un historique reconnu par la commune (art. 59.º-I de l'EBF)",
				"field422": "Augmentation de l'augmentation des amortissements, prévue à l'article 8, n° 3 du décret-loi n° 66/2016 du 3 novembre.",
				"field423": "Augmentation des amortissements fiscalement admis des éléments d'immobilisations corporelles correspondant aux navires électrosolaires ou exclusivement électriques (art. 59.º-J de l'EBF)",
				"field424": "Les revenus et gains qui ne sont pas des plus-values ​​fiscales visés aux paragraphes 1 et 2 de l'article 268 du Code de l'insolvabilité et de la reprise des entreprises (CIRE), approuvé par le décret-loi n° 53/2004, du 18 mars.",
				"field425": "Revenus fonciers résultant de contrats de location ou de sous-location de logements inclus dans le programme de location abordable (art. 20, n° 1 du décret-loi n° 68/2019 du 22 mai)",
				"field429": "Revenus fonciers obtenus dans le cadre des programmes communaux proposant des logements locatifs à prix abordables (art. 71, n° 27 de l'EBF)",
				"field430": "Augmentations des dépenses occasionnées par l'acquisition de laissez-passer sociaux au profit du personnel (art. 43, n° 15 du CIRC)",
				"field431": "Les dépenses liées à l'acquisition de biens et de services directement nécessaires à la mise en œuvre des développements garantissant le dépôt préalable du dossier SAFT-PT relatif à la comptabilité dans le cadre de l'IES et du code unique du document (ATCUD), dans les conditions prévues à paragraphe 1 de l’article 316 de la loi n° 12/2022 du 27 juin.",
				"field433": "Augmentation des dépenses éligibles, engagées au cours des périodes 2021 et 2022, dans le cadre de la participation conjointe à des projets de promotion externe (art. 400, n° 1 de la loi n° 75.º-B/2020, du 31 décembre)",
				"field434": "Augmentations des dépenses liées à la consommation d'électricité et de gaz naturel, dans la mesure où elles dépassent celles de la période précédente, déduites d'une éventuelle aide aux termes du décret-loi n° 30-B/2022 du 18 avril (art. 231). 24-D/2022, du 30 décembre)",
				"field435": "Augmentations des dépenses liées au régime extraordinaire de soutien aux charges supportées dans la production agricole (art. 232 de la loi n° 24-D/2022, du 30 décembre)",
				"field436": "Majorations de charges correspondant à l'augmentation déterminée par un instrument de régulation collective dynamique du travail relatif aux travailleurs titulaires d'un contrat de travail à durée indéterminée (art. 19.º-B de l'EBF)",
				"field437": "Régime fiscal favorisant la capitalisation des entreprises [(ICE) art. 43.º-D de l'EBF]",
				"field438": "Augmentation des charges correspondant à la création nette d'emplois (art. 41.º-B, n° 6 de la FBE)",
				"field439": "Différentiel du coefficient extraordinaire d'aide à la location (art. 3, n° 3 de la loi n° 19/2022, du 21 octobre)",
				"field410": "Autres déductions sur le revenu",
				"field411": "TOTAL DES DÉDUCTIONS (401 + ... + 409 + 412 + ... + 435 + ... + 439 + 410)",
				"desc4A": "Zone 410 - Autres déductions sur le revenu",
				"desc4B": "Informations complémentaires (art. 268 de la CIRE)",
				"radio1-2": "L'un des événements prévus à l'article 268 de la CIRE est-il survenu au cours de la période fiscale ?",
				"casoafirma": "Si oui, veuillez indiquer :",
				"field3": "Impôt sur les plus-values ​​exonérées selon les termes de l'article 268, n° 1.",
				"field4": "Variations positives des capitaux propres exonérées non reflétées dans le bénéfice net de la période (art. 268, paragraphes 1 et 2)",
				"field5": "Dépenses ou pertes déductibles déterminées par le créancier à la suite de la réduction des crédits (art. 268, n° 3)",
				"desc41": "Transmission des avantages fiscaux de la société fusionnée ou scindée ou de la société apporteuse (article 75-A du CIRC)",
				"table": {
					"fields": {
						"codben": "Code avantage",
						"montante": "Montant",
						"nifsoc": "Société NIF."
					}
				}
			},
			"quadro05": {
				"title": "Soc. gestionnaires de la participation sociale (SGPS), soc.",
				"field501": "Plus-values ​​non imposées (ex-art. 32.º, n. 2 et ex-art. 32.º-A, n. 1 de l'EBF)",
				"field502": "Pertes fiscales non déductibles (ex-art. 32.º, n. 2 et ex-art. 32.º-A, n. 1 de l'EBF)"
			},
			"quadro06": {
				"title": "Entités agréées dans la zone franche de Madère",
				"ano": "603.1 - Année d'investissement",
				"montante": "603.2 - Montant investi",
				"field601": "Date de licence",
				"desc603": "Discrimination",
				"desc604": "Code NACE Rév. 1 (art. 36, n° 6 EBF)",
				"desc605": "Code NACE Rév. 2 (art. 36-A, n° 7 de l'EBF)",
				"field602": "Nombre d'emplois créés au cours des six premiers mois d'activité et maintenus sur la période",
				"nrpostocriadmantidos": "Nombre d'emplois créés/maintenus :",
				"field606": "Au début de la période fiscale",
				"field607": "A la fin de la période fiscale",
				"field603": "Investissement réalisé dans l'acquisition d'immobilisations corporelles et d'immobilisations incorporelles, au cours des deux premières années d'activité",
				"desc06-1": "Détermination du plafond applicable aux avantages fiscaux relatifs à la période (à compléter en cas d'application du régime de l'article 36.º-A de l'EBF)",
				"benefdif": "Prestation correspondant à la différence :",
				"field608": "Taux IRC (article 36-A, n°1 de la FBE)",
				"field609": "Déversement régional (article 36-A, n°12 de l'EBF)",
				"field610": "Surtaxe communale (article 36-A, n°12 de l'EBF)",
				"field611": "Taux de taxation autonomes (article 36-A, alinéa 14 de l'EBF)",
				"field612": "Déduction de 50% de la collecte des IRC (article 36-A, n°6 de l'EBF)",
				"field613": "Autres bénéfices attendus (article 36-A, paragraphe 12 de la FBE)",
				"field614": "AVANTAGES FISCAUX TOTALS (608 + 609 + 610 + 611 + 612 + 613)",
				"field615": "Valeur ajoutée brute dans la période et dans la zone franche de Madère x 20,1% [art. 36.-ºA, no 3, a) de la FBE]",
				"field616": "Coûts annuels de main-d'œuvre engagés dans la zone franche de Madère x 30,1% [art. 36.º-A, no 3, b) de l'EBF]",
				"field617": "Chiffre d'affaires pour la période dans la zone franche de Madère x 15,1% [art. 36.º-A, no. 3, c) de l'EBF]",
				"field618": "Franchise à régulariser (art. 36-A, n° 3 de l'EBF) (à transférer dans le champ 372 du tableau 10 de la déclaration)"
			},
			"quadro07": {
				"title": "Déductions fiscales (à déduire dans le champ 355 du tableau 10 de la déclaration)",
				"somacontrolo": "Somme de contrôle",
				"desc71": "Avantages fiscaux contractuels pour l'investissement (ex-article 41, alinéa 1 de l'EBF, articles 15 à 21 du CFI (abrogés) et articles 2 à 21 du CFI approuvés par le décret-loi n° 162/2014 du 31/10 et articles 2 à 21 du TPICE",
				"table71": {
					"fields": {
						"field700": "NIF de l'entreprise.",
						"field1": "Diplôme",
						"field2": "Période à laquelle se rapporte la prestation",
						"field3": "Solde expiré",
						"field701": "Solde non déduit dans la période précédente",
						"field702": "Répartition des périodes",
						"field703": "Déduction périodique",
						"field704": "Solde reporté à la période suivante",
						"tot1": "Total - Solde expiré",
						"tot2": "Total - Solde non déduit dans la période précédente",
						"tot3": "Total - Répartition par période",
						"tot4": "Total - Déduction périodique",
						"tot5": "Total - Solde reporté à la période suivante"
					}
				},
				"desc71A": "RETGS - INFORMATIONS COMPLÉMENTAIRES (à compléter par les sociétés qui composent le groupe) - utilisation de l'avantage au sein du groupe",
				"table71A": {
					"fields": {
						"field1": "Diplôme",
						"field2": "Période à laquelle se rapporte la prestation",
						"field3": "Solde réel expiré dans la déclaration de groupe",
						"field4": "Solde non déduit dans la période précédant la collecte collective",
						"field5": "Affectation de la période dans la déclaration de groupe",
						"field6": "Déduction utilisée dans la déclaration de groupe",
						"field7": "Solde reporté à la période suivante dans la déclaration groupe",
						"tot1": "Total - Solde réel expiré dans la déclaration de groupe",
						"tot2": "Total - Solde non déduit dans la période précédant la collecte collective",
						"tot3": "Total - Répartition des périodes dans la déclaration de groupe",
						"tot4": "Total - Déduction utilisée dans la déclaration de groupe",
						"tot5": "Total - Solde reporté à la période suivante dans la déclaration groupe"
					}
				},
				"desc72": "Projets d'investissement pour l'internationalisation (ex-art. 41, n° 4 de l'EBF et art. 22 du CFI abrogé par la loi n° 83-C/2013, du 31/12)",
				"field705": "Solde non déduit dans la période précédente",
				"field706": "Répartition des périodes",
				"field707": "Déduction périodique",
				"field708": "Solde reporté à la période suivante",
				"desc73": "SIFIDE - Système d'incitations fiscales à la recherche et au développement des entreprises (Loi n° 40/2005, du 3/8) et SIFIDE II (art. 133 de la loi 55-A/2010, du 31/12, art. 33.º au 40.º du CFI (abrogé) et articles 35.º à 42.º du CFI approuvés par le",
				"table73": {
					"fields": {
						"field743": "NIF de l'entreprise.",
						"field1": "Diplôme",
						"field2": "Période à laquelle se rapporte la prestation",
						"field3": "Solde expiré",
						"field709": "Solde non déduit dans la période précédente",
						"field710": "Répartition des périodes",
						"field711": "Déduction périodique",
						"field712": "Solde reporté à la période suivante",
						"tot1": "Total - Solde expiré",
						"tot2": "Total - Solde non déduit dans la période précédente",
						"tot3": "Total - Répartition par période",
						"tot4": "Total - Déduction périodique",
						"tot5": "Total - Solde reporté à la période suivante"
					}
				},
				"desc73A": "RETGS - INFORMATIONS COMPLÉMENTAIRES (à compléter par les sociétés qui composent le groupe) - utilisation de l'avantage au sein du groupe",
				"table73A": {
					"fields": {
						"field1": "Diplôme",
						"field2": "Période à laquelle se rapporte la prestation",
						"field3": "Solde réel expiré dans la déclaration de groupe",
						"field4": "Solde non déduit dans la période précédant la collecte collective",
						"field5": "Affectation de la période dans la déclaration de groupe",
						"field6": "Déduction utilisée dans la déclaration de groupe",
						"field7": "Solde reporté à la période suivante dans la déclaration groupe",
						"tot1": "Total - Solde réel expiré dans la déclaration de groupe",
						"tot2": "Total - Solde non déduit dans la période précédant la collecte collective",
						"tot3": "Total - Répartition par période",
						"tot4": "Total - Déduction périodique",
						"tot5": "Total - Solde reporté à la période suivante"
					}
				},
				"desc74": "Régime fiscal de soutien à l'investissement (loi n° 10/2009 du 10/3 (prolongée successivement), articles 26 à 32 du CFI (abrogés) et articles 22 à 26 du CFI approuvés par le décret-loi n° 162/2014 , du 31/10 et des articles 22 à",
				"table74": {
					"fields": {
						"field744": "NIF de l'entreprise.",
						"field1": "Diplôme",
						"field2": "Période à laquelle se rapporte la prestation",
						"field3": "Solde expiré",
						"field713": "Solde non déduit dans la période précédente",
						"field714": "Répartition des périodes",
						"field715": "Déduction périodique",
						"field716": "Solde reporté à la période suivante",
						"tot1": "Total - Solde expiré",
						"tot2": "Total - Solde non déduit dans la période précédente",
						"tot3": "Total - Répartition par période",
						"tot4": "Total - Déduction périodique",
						"tot5": "Total - Solde reporté à la période suivante"
					}
				},
				"desc74A": "RETGS - INFORMATIONS COMPLÉMENTAIRES (à compléter par les sociétés qui composent le groupe) - utilisation de l'avantage au sein du groupe",
				"table74A": {
					"fields": {
						"field1": "Diplôme",
						"field2": "Période à laquelle se rapporte la prestation",
						"field3": "Solde réel expiré dans la déclaration de groupe",
						"field4": "Solde non déduit dans la période précédant la collecte collective",
						"field5": "Affectation de la période dans la déclaration de groupe",
						"field6": "Déduction utilisée dans la déclaration de groupe",
						"field7": "Solde reporté à la période suivante dans la déclaration groupe",
						"tot1": "Total - Solde réel expiré dans la déclaration de groupe",
						"tot2": "Total - Solde non déduit dans la période précédant la collecte collective",
						"tot3": "Total - Répartition des périodes dans la déclaration de groupe",
						"tot4": "Total - Déduction utilisée dans la déclaration de groupe",
						"tot5": "Total - Solde reporté à la période suivante dans la déclaration groupe"
					}
				},
				"desc76": "Crédit d'impôt extraordinaire pour investissement - CFEI II (article 16 de la loi n° 27-A/2020 du 24 juillet)",
				"table76": {
					"field1": "NIF de l'entreprise.",
					"field2": "Période à laquelle se rapporte la prestation",
					"field722": "Solde non déduit dans la période précédente",
					"field723": "Répartition des périodes",
					"field724": "Déduction périodique",
					"field725": "Solde reporté à la période suivante",
					"tot1": "Total - Solde non déduit dans la période précédente",
					"tot2": "Total - Répartition par période",
					"tot3": "Total - Déduction périodique",
					"tot4": "Total - Solde reporté à la période suivante"
				},
				"desc76A": "RETGS - INFORMATIONS COMPLÉMENTAIRES (à compléter par toutes les sociétés qui composent le groupe) - utilisation de l'avantage au sein du groupe",
				"table76A": {
					"field1": "Période à laquelle se rapporte la prestation",
					"field2": "Solde non déduit dans la période précédant la collecte collective",
					"field3": "Affectation de la période dans la déclaration de groupe",
					"field4": "Déduction utilisée dans la déclaration de groupe",
					"field5": "Solde reporté à la période suivante dans la déclaration groupe",
					"tot1": "Total - Solde non déduit dans la période précédant la collecte collective",
					"tot2": "Total - Répartition des périodes dans la déclaration de groupe",
					"tot3": "Total - Déduction utilisée dans la déclaration de groupe",
					"tot4": "Total - Solde reporté à la période suivante dans la déclaration groupe"
				},
				"desc79": "FIPC - Incitation fiscale à la production cinématographique et audiovisuelle (Article 59.º-F de l'EBF et Ordonnance n° 89.º-A/2017, du 19 avril)",
				"table79": {
					"fields": {
						"field790": "Numéro d'identification du travail",
						"field791": "Date de début des travaux",
						"field792": "Date de fin des travaux",
						"field793": "Solde non déduit dans la période précédente",
						"field794": "Valeur incitative dans la période",
						"field795": "Déduction périodique",
						"field796": "Solde reporté à la période suivante",
						"field797": "Montant à rembourser",
						"field798": "Valeur à restituer (à transporter en C.372 de Q.10 de M.22)",
						"tot1": "Total - Solde non déduit dans la période précédente",
						"tot2": "Total - Valeur incitative dans la période",
						"tot3": "Total - Déduction périodique",
						"tot4": "Total - Solde reporté à la période suivante",
						"tot5": "Total - Montant à rembourser",
						"tot6": "Total - Valeur à remplacer (à transporter en C.372 de Q.10 de M.22)"
					}
				},
				"desc79A": "FIPC - Incitation fiscale à la production cinématographique et audiovisuelle - Redevances supportées par les véhicules légers de tourisme, les véhicules utilitaires légers, les motocyclettes et les motocyclettes, exclues de la fiscalité autonome selon les termes de l'art. 59.º-H de l'EBF.",
				"desc710": "Incitation Fiscale à la Récupération - IFR (article 307 de la loi n° 12/2022 du 27 juin)",
				"field710A": "Montant des dépenses d'investissement éligibles dans la période",
				"field710B": "Montant de la moyenne arithmétique simple des dépenses d'investissement éligibles au cours des périodes fiscales précédentes (article 3, paragraphe 3, de l'annexe III)",
				"table710": {
					"fields": {
						"field71001": "NIF de l'entreprise.",
						"field71002": "Solde non déduit dans la période précédente",
						"field71003": "Montant de l'avantage fiscal",
						"field71004": "Déduction périodique",
						"field71005": "Solde reporté à la période suivante",
						"tot1": "Total - Solde non déduit dans la période précédente",
						"tot2": "Total - Montant de l'avantage fiscal",
						"tot3": "Total - Déduction périodique",
						"tot4": "Total - Solde reporté à la période suivante"
					}
				},
				"desc710A": "RETGS - INFORMATIONS COMPLÉMENTAIRES (à compléter par toutes les sociétés qui composent le groupe) - utilisation de l'avantage au sein du groupe",
				"table710A": {
					"fields": {
						"field71001A": "Solde non déduit dans la période précédant la collecte collective",
						"field71002A": "Montant de l'avantage fiscal",
						"field71003A": "Déduction périodique",
						"field71004A": "Solde reporté à la période suivante",
						"tot1": "Total - Solde non déduit dans la période précédente",
						"tot2": "Total - Montant de l'avantage fiscal",
						"tot3": "Total - Déduction périodique",
						"tot4": "Total - Solde reporté à la période suivante"
					}
				},
				"tipoviat": "Type de véhicules",
				"montencarg": "Montant des frais",
				"field1": "Redevances pour véhicules légers de tourisme et de marchandises - Si CA < 27 500,00 € [art. 88, n° 3, al.",
				"field2": "Redevances pour véhicules légers de tourisme et de fret - Si CA >= 27 500,00 € et < 35 000,00 € [art.",
				"field3": "Redevances pour véhicules légers de tourisme et de marchandises - Si CA >= 35 000,00 € [art. 88, n° 3, al.",
				"field4": "Redevances pour les voitures particulières hybrides rechargeables, dont la batterie peut être chargée via une connexion au réseau électrique et qui ont une autonomie minimale, en mode électrique, de 50 km et des émissions officielles inférieures à 50 gCO(indice 2)/km, et véhicules légers de tourisme propulsés au gaz naturel automobile (GNV) - Si CA < 27 500,00 € [art.",
				"field5": "Redevances pour les voitures particulières hybrides rechargeables, dont la batterie peut être chargée via une connexion au réseau électrique et qui ont une autonomie minimale, en mode électrique, de 50 km et des émissions officielles inférieures à 50 gCO(indice 2)/km, et véhicules légers de tourisme fonctionnant au gaz naturel automobile (GNV) - Si CA >= 27 500,00 € et < 35 000,00 €",
				"field6": "Redevances pour les voitures particulières hybrides rechargeables, dont la batterie peut être chargée via une connexion au réseau électrique et qui ont une autonomie minimale, en mode électrique, de 50 km et des émissions officielles inférieures à 50 gCO(indice 2)/km, et véhicules légers de tourisme propulsés au gaz naturel automobile (GNV) - Si CA >= 35 000,00 € [art.",
				"field7": "Redevances pour les véhicules légers de tourisme fonctionnant au GNV - Si CA < 27 500,00 € [art. 88, n° 3, al.",
				"field8": "Redevances pour les véhicules légers de tourisme alimentés au GNV - Si CA >= 27 500,00 € et < 35 000,00 € [art.",
				"field9": "Redevances pour les véhicules légers de tourisme fonctionnant au GNV - Si CA >= 35 000,00 € [art.",
				"desc75": "Autres déductions de recouvrement",
				"normlegal": "Dispositions légales",
				"dedefet": "Déduction effectuée",
				"field717": "Incitations fiscales pour les bénéfices réinvestis dans la Région Autonome de Madère (Décret législatif régional n° 2/2009/M, du 22/1)",
				"field726": "Incitations fiscales pour les bénéfices réinvestis dans la Région Autonome des Açores (art. 6 du décret législatif régional n° 2/99/A du 20/1)",
				"field718": "Entités agréées dans la zone franche de Madère (art. 35, n° 6 et 36, n° 5 et 36-A, n° 6 de l'EBF)",
				"field719": "Sociétés de capital-risque et investisseurs en capital-risque (art. 32-A, n° 4 de la FBE)",
				"field727": "Déduction pour les bénéfices conservés et réinvestis par les PME (articles 27 à 34 du CFI) approuvées par le décret-loi n° 162/2014, du 31/10 et articles 27 à 34 du CFI en RAM approuvés par le déc.",
				"field728": "Déduction de 50% sur la perception par les entités autorisées à opérer dans la zone franche industrielle de Madère (art. 36-A, n° 6 de l'EBF)",
				"field720": "",
				"field721": "TOTAL DES DÉDUCTIONS [(703 + 707 + 711 + 715 + 724 + 795 + 04(Q.0710) + 717 + 726 + 718 + 719 + 727 + 728 + 720)]",
				"desc77": "Transmission des avantages fiscaux de la société fusionnée ou dissoute ou de la société apporteuse (Article 75.º-A du CIRC)",
				"table77": {
					"fields": {
						"field729": "NIF société fusionnée, scindée ou apportant un apport",
						"field1": "Diplôme",
						"field2": "Période à laquelle se rapporte la prestation",
						"field730": "Solde du bénéfice transmis",
						"field731": "Répartition des périodes",
						"tot1": "Total - Solde des prestations transmises",
						"tot2": "Total - Répartition par période"
					}
				},
				"desc78": "Incitations soumises aux taux maximaux des aides régionales (CFI approuvé par le décret-loi n° 162/2014 du 31 octobre) (Pour les périodes fiscales 2015 et 2016)",
				"table78": {
					"fields": {
						"field746": "Code avantage",
						"field735": "Région éligible (article 43 du CFI)",
						"field736": "Code CAE de l'activité à laquelle l'investissement est destiné (article 2 du Port. n° 282/2014, du 31/12)",
						"field737": "Nombre de demandes pertinentes (articles 11, 22 et 30 du TPICE)",
						"incenfisc": "Incitations : impôt",
						"field738": "IRC",
						"field739": "IMI, IMT et SELO",
						"field740": "Non fiscal"
					}
				},
				"radio1-2": "Indiquez si elle remplit les conditions requises en tant que micro-entité dans les conditions prévues à l'annexe du décret-loi n° 372/2007 du 6 novembre.",
				"desc78A": "Incitations soumises aux taux maximaux des aides régionales (CFI approuvé par le décret-loi n° 162/2014 du 31 octobre) (Pour les périodes fiscales 2017 et suivantes)",
				"desc78-A1": "Informations sur les projets d'investissement régionaux",
				"table78A1": {
					"fields": {
						"field782": "Numéro de ligne",
						"projinvinc": "Projet d'investissement/Incitation",
						"field750": "Taper",
						"field751": "Numéro de projet/code d'incitation",
						"field752": "Date de début de l'investissement",
						"field753": "Date de fin d'investissement",
						"field754": "Typologie d'investissement",
						"field755": "Identification officielle de l'incitation financière",
						"appreleprev": "Applications attendues pertinentes",
						"field756": "Région éligible",
						"field757": "Code CAE",
						"field758": "Montant total",
						"field759": "Montant total mis à jour"
					}
				},
				"desc78-A2": "Incitations financières appréciées et taxe utilisée - Valeurs pour la période fiscale",
				"table78A2": {
					"fields": {
						"field760": "Numéro de projet/code d'incitation",
						"apprelreali": "Applications pertinentes réalisées",
						"field761": "Montant",
						"field762": "Montant mis à jour",
						"financeiro": "Financier",
						"field763": "Montant apprécié",
						"field764": "Montant utilisé mis à jour",
						"irc": "IRC",
						"field765": "Montant utilisé",
						"field766": "Montant mis à jour",
						"imi": "IMI",
						"field767": "Montant utilisé",
						"field768": "Montant mis à jour",
						"imt": "MIT",
						"field769": "Montant utilisé",
						"selo": "TIMBRE",
						"field770": "Montant utilisé",
						"field771": "Montant total mis à jour des avantages bénéficiés/utilisés"
					}
				},
				"desc78-A3": "Incitations financières bénéficiant et taxes utilisées - Valeurs actualisées accumulées",
				"table78A3": {
					"fields": {
						"field772": "Numéro de projet/code d'incitation",
						"apprelreali": "Applications pertinentes réalisées",
						"field773": "Montant accumulé mis à jour",
						"financeiro": "Financier",
						"field774": "Montant utilisé mis à jour",
						"irc": "IRC",
						"field775": "Montant mis à jour",
						"imi": "IMI",
						"field776": "Montant mis à jour",
						"imt": "MIT",
						"field777": "Montant utilisé",
						"selo": "TIMBRE",
						"field778": "Montant utilisé",
						"field779": "Montant total mis à jour des avantages bénéficiés/utilisés",
						"field780": "Intensité de l'aide cumulée (en %)",
						"field781": "Montant à inscrire dans le champ 372 de Q. 10 de M. 22"
					}
				}
			},
			"quadro08": {
				"title": "Donations (articles 62, 62-A et 62-B de l'EBF)",
				"table": {
					"fields": {
						"tipdona": "Type de don",
						"nif": "NIF de l'entité donataire",
						"donativo": "Valeur du don"
					}
				}
			},
			"quadro09": {
				"title": "Incitations fiscales soumises à la règle de minimis",
				"desc1": "Total des incitations des années précédentes (nature fiscale et non fiscale)",
				"field901": "N-2",
				"field902": "N-1",
				"desc2": "Incitations de l'année",
				"field903": "Incitation non fiscale",
				"desc3": "Incitations fiscales",
				"field904A": "Rémunération conventionnelle du capital social (Loi n° 55-A/2010, du 31/12 et art. 41.º-A de l'EBF) x taux IRC",
				"field904B": "Réduction du taux IRC applicable aux PME et aux entreprises de petite et moyenne capitalisation (Small Mid Cap), pour les premiers 50 000,00 € de revenu imposable (art. 87, n° 2 du CIRC)",
				"field904C": "Réduction de taux - Avantages fiscaux applicables aux territoires intérieurs (ex-art. 43 et art. 41-B de l'EBF)",
				"field904D": "Dépenses avec projet d'investissement productif (art. 18, n° 1, al. b) et n° 5 du CFI, abrogé par le décret-loi n° 162/2014, du 31/10) x taux IRC.",
				"field904E": "Taxe communale (art. 18, n° 25 de la loi n° 73/2013 du 3 septembre)",
				"field904F": "Augmentation de 20% de la déduction maximale pour bénéfices conservés et réinvestis (DLRR) par les PME (art. 41.º-B, n ° 4 de l'EBF)",
				"field904G": "Augmentation des dépenses d'investissement liées à la promotion de l'internationalisation (art. 400. 7 et 8 de la loi 75.º-B/2020, du 31 décembre) x taux IRC",
				"field904H": "Augmentation des dépenses d'investissement liées à la promotion de l'internationalisation dans les secteurs de la pêche et de l'aquaculture (art. 400.º n. 9 de la loi n. 75.º-B/2020, du 31 décembre) x taux d'imposition IRC",
				"field904I": "Augmentation des dépenses d'investissement liées à la promotion de l'internationalisation dans le secteur de la production agricole primaire (art. 400.º n. 10 de la loi n. 75.º-B/2020, du 31 décembre) x taux IRC",
				"field904J": "Réduction de taux - Avantages fiscaux applicables aux territoires intérieurs (article 19.º-A du décret législatif régional n° 28.º-A/2021/M, du 30 décembre (R. A. Madère) et article 38.º du décret législatif régional n° 1/2023/A, du 5 janvier (R.A.Açores)",
				"field904K": "Augmentations des dépenses liées au régime extraordinaire de soutien aux charges supportées dans la production agricole x taux IRC",
				"field904": "Total des incitations pour l'année à caractère fiscal (904-A + ... + 904-K)",
				"field905": "Incitatifs totaux pour la période de trois ans (901 + 902 + 903 + 904)",
				"field906": "IRC à régulariser (à indiquer dans le champ 372 du tableau 10 de la déclaration)",
				"desc4": "Identification des sociétés associées (concept de société unique aux fins du plafond de minimis)",
				"table": {
					"field": "FNI"
				}
			},
			"quadro10": {
				"title": "Incitations fiscales intérieures liées aux investissements soumis aux taux maximaux d'aide régionale (ex-art. 43 de la FBE) - à indiquer dans le champ 372 de Q. 10 de la déclaration",
				"invelegiveis": "Investissements éligibles",
				"field1001": "Tangible",
				"field1002": "Intangible",
				"field1003": "TOTAL",
				"desc1": "Aide à l'investissement",
				"field1004": "Réduction des charges sociales x (1 - taux IRC)",
				"majoracaodep": "Augmentation de la dépréciation",
				"field1005": "Majorisation",
				"field1006": "Taux IRC",
				"field1007": "Valeur de l'aide",
				"majoracaoencsegsoc": "Augmentation des charges sociales",
				"field1008": "Majorisation",
				"field1009": "Taux IRC",
				"field1010": "Valeur de l'aide",
				"field1011": "Augmentation du crédit d'impôt à l'investissement",
				"field1013": "AIDE TOTALE (1004 + 1007 + 1010 + 1011 + 1012)",
				"field1014": "Frais d'aide",
				"field1015": "Tarif maximum légal applicable",
				"field1016": "EXCÉDENT À RÉGULARISER (à virer au champ 372 du tableau 10 de la déclaration)"
			},
			"quadro11": {
				"title": "Déductions de la Base Imposable (à déduire dans le champ 399 du tableau 09 de la déclaration)",
				"desc111": "Collectifs sportifs (art. 54, n° 2 de l'EBF)",
				"field1111": "Solde non déduit dans la période précédente",
				"field1112": "Répartition des périodes",
				"field1113": "Déduction périodique",
				"field1114": "Solde reporté sur la ou les périodes suivantes"
			},
			"quadro11A": {
				"title": "Informations complémentaires concernant le régime applicable aux entités agréées dans la ZFM et les aides d'État à finalité régionale",
				"desc": "Si vous avez un partenaire ou une ou plusieurs sociétés associées telles que définies aux paragraphes 2 et 3 de l'article 3 de l'annexe du décret-loi n° 372/2007 du 6 novembre, indiquez le(s) FIV respectif.",
				"table": {
					"field": "FNI"
				}
			},
			"quadro12": {
				"title": "Régime spécial de transférabilité des pertes fiscales applicable aux acquéreurs jusqu'au 31 décembre 2020 de participations dans des entités considérées comme des entreprises en difficulté (article 15 de la loi n° 27-A/2020 du 24 juillet)",
				"desc121": "Informations à communiquer par la société acquéreuse",
				"table121": {
					"fields": {
						"field1": "NIF de l'entreprise en difficulté",
						"field2": "Date d'acquisition de la participation",
						"field3": "Pourcentage moyen de participation directe de la société absorbante dans le capital de vote de la société en difficulté",
						"field4": "Période de calcul des déficits fiscaux courants transmis",
						"field5": "Pertes fiscales courantes (solde)",
						"field6": "Montant des déficits fiscaux courants transmis (Colonne 03 x colonne 05)"
					}
				},
				"desc122": "Informations à communiquer par l'entreprise considérée comme une entreprise en difficulté",
				"campo122": "J'autorise le transfert des pertes fiscales à la société absorbante (n° 3 de l'article 3 de l'annexe IV de la loi n° 27-A/2020, du 24 juillet)",
				"table122": {
					"fields": {
						"field1": "NIF de la société qui acquiert la participation",
						"field2": "Pourcentage moyen de participation directe de la société absorbante dans le capital de vote de la société en difficulté",
						"field3": "Délai de calcul des pertes fiscales courantes",
						"field4": "Pertes fiscales courantes (solde)",
						"field5": "Montant des déficits fiscaux courants transmis (rubrique b du n° 1 de l'article 3 de l'annexe IV) (Colonne 02 x colonne 04)"
					}
				}
			}
		},
		"anexoE": {
			"quadro03": {
				"title": "Détermination du revenu imposable",
				"rend": "Revenu",
				"matcole": "Matériel de collection",
				"field1-16": "Ventes de biens et de produits",
				"field2-17": "Prestations de services entrant dans le cadre des activités de restauration et d'hôtellerie et activités assimilées, à l'exception de celles réalisées dans le cadre de l'activité d'exploitation d'établissements d'hébergement de proximité sous forme de maison ou d'appartement",
				"field3-18": "Prestations de services dans le cadre d'activités professionnelles spécifiquement prévues dans la liste annexée au CIRS",
				"field4-19": "Autres services fournis",
				"field5-20": "Subventions à l'exploration",
				"table": {
					"desc": "Subventions non destinées à l'exploration",
					"rend": "Revenu",
					"matcole": "Matériel de collection = [(6) x 0,30 x t]",
					"totrend": "Total - Revenu",
					"totmatcol": "Total - Matériel taxable",
					"soma": "Somme de contrôle"
				},
				"field7-22": "Cession temporaire ou utilisation de la propriété intellectuelle ou industrielle",
				"field8-23": "Fourniture d'informations concernant l'expérience acquise dans le secteur industriel, commercial ou scientifique",
				"field9-24": "Autres revenus du capital",
				"field10-25": "Résultat positif des revenus fonciers",
				"field11-26": "Solde positif des gains et pertes fiscales",
				"field12-27": "Les capitaux propres restants augmentent",
				"field13-28": "Valeur d'acquisition des augmentations de capitaux propres obtenues à titre gratuit",
				"field32-33": "Revenus provenant de l'exploitation d'établissements locaux d'hébergement sous forme de maison ou d'appartement (point h du paragraphe 1 de l'article 86-B)",
				"field35-36": "Les revenus provenant de l'exploitation d'établissements locaux d'hébergement sous forme de maison ou d'appartement, situés dans une zone de confinement (point g du paragraphe 1 de l'article 86-B)",
				"field14-29": "Ajustement positif aux termes de l'article 64, no 3, al.",
				"field15-30": "Ajustement positif aux termes de l'article 64, no 3, al.",
				"field37-38": "Les revenus du minage cryptoactif et les autres revenus prévus à l'alinéa e) du paragraphe 1 de l'article 86-B.",
				"field39-46": "Les revenus liés aux crypto-actifs, à l'exclusion de ceux issus du minage, qui ne sont pas considérés comme des revenus du capital, ni ne résultent du solde positif des plus et moins-values ​​et autres augmentations de capital (point i) du paragraphe 1 de l'article 86. º-B) .",
				"field40": "REVENU TOTAL",
				"field41": "SOUS-TOTAL MATÉRIEL DE COLLECTE (somme des champs 16 à 30 + 33)",
				"field34": "Augmentation des contributions financières des propriétaires forestiers et des producteurs participant à une zone d'intervention forestière allouées au fonds constitué par l'entité de gestion respective (art. 59-D, n° 14 de la FBE)",
				"field31": "Augmentation pour non-réinvestissement (art. 86-B, n° 11 du CIRC)",
				"field42": "TOTAL DES MATÉRIAUX À COLLECTIONNER (Champs 41 + 31 - 34)",
				"field3142desc": "(à transporter vers le champ 346 du tableau 09 du mod.22)",
				"tdesc": "t - Correspondant au taux minimum de dépréciation/amortissement des actifs aidés."
			},
			"quadro04": {
				"title": "Autres informations",
				"campo43": "Date à laquelle vous avez commencé votre activité"
			}
		},
		"anexoF": {
			"quadro03": {
				"title": "Calcul du bénéfice imposable (article 22, alinéas 2 et 3 de l'EBF)",
				"field1": "RÉSULTAT NET DE LA PÉRIODE",
				"acrescer": "A AJOUTER",
				"field2": "Les moins-values ​​(réalisées ou potentielles) prévues à l'article 10 du Code IRS ne sont pas déductibles",
				"field3": "Charges résultant de l'application de la juste valeur aux instruments financiers et aux immeubles non déductibles",
				"field4": "Pertes résultant de variations de taux de change non déductibles",
				"field5": "Autres dépenses et pertes liées à l'obtention de revenus exclus de l'impôt",
				"field6": "Frais ou pertes non déductibles au titre de l'article 23-A du CIRC",
				"field19": "Charges sur frais de gestion et autres",
				"field7": "",
				"field8": "SOMME (champs 2 à 7 + 19)",
				"deduzir": "EN DÉDUIRE",
				"field9": "Revenus du capital prévus à l'article 5 du Code IRS exclus de l'impôt",
				"field10": "Revenus fonciers prévus à l'article 8 du Code IRS exclus de l'impôt",
				"field11": "Plus-values ​​(réalisées ou potentielles) prévues à l'article 10 du Code IRS exclues de l'impôt",
				"field12": "Produits provenant de l'application de la juste valeur aux instruments financiers et aux immeubles exclus de l'impôt",
				"field13": "Gains résultant des variations des taux de change exclus de l'impôt",
				"field14": "Revenus de frais de gestion et autres",
				"field15": "",
				"field16": "SOMME (champs 9 à 15)",
				"field17": "PERTE POUR EFFETS FISCAUX [si (1+8-16) < 0]",
				"field18": "BÉNÉFICE IMPOSABLE [si (1+8-16) >= 0]"
			},
			"quadro04": {
				"title": "Détermination du revenu imposable",
				"field1": "PERTE FISCALE (transport du champ 17 de Q. 03)",
				"field2": "BÉNÉFICE IMPOSABLE (report du champ 18 de Q. 03)",
				"field3": "Pertes fiscales déductibles (art. 22, n° 4 de l'EBF)",
				"field4": "PERTE FISCALE Déduite",
				"field5": "MATÉRIEL DE COLLECTION (2-4)"
			},
			"quadro05": {
				"title": "Liquidation de collecte",
				"field1": "Impôt au taux normal (art. 22, n° 5 de l'EBF) (champ 5 de Q.04) x 21%",
				"desc": "Régime transitoire (art. 7 du DL n° 7/2015, du 13 janvier) :",
				"field2": "Impôt relatif au solde positif entre plus-values ​​et moins-values ​​immobilières (transport du champ 10 du tableau 06-A)",
				"field3": "Impôt relatif aux plus-values ​​sur autres biens (transport du champ 6 du tableau 06-B)",
				"field4": "COLLECTION (ajoute les champs 1 à 3) (à transporter au C347-B, C350 ou C370 de Q. 10 du Mod. 22)"
			},
			"quadro06": {
				"title": "Plus-values ​​réalisées couvertes par le régime transitoire prévu à l'article 7, n° 6, du DL n° 7/2015, du 13/01).",
				"descA": "Plus et moins-values ​​résultant de la vente d'immeubles acquis selon la rédaction précédente de l'article 22 de l'EBF",
				"descB": "Plus-values ​​résultant de la vente d'autres actifs",
				"tableA": {
					"fields": {
						"identmatimoveis": "Identification matricielle des propriétés",
						"codfreg": "Code paroissial",
						"tipo": "Taper",
						"artigo": "Article",
						"faccaoseccao": "Fraction/Section",
						"dataaqui": "Date d'acquisition (année/mois/jour)",
						"dataalienacao": "Date de cession (année/mois/jour)",
						"montantemaismenosvalia": "Montant de la plus-value et de la perte réalisées",
						"maismenosvaiaabrangida": "Gains et pertes de valeur couverts par le régime transitoire"
					}
				},
				"tableB": {
					"fields": {
						"designacao": "Désignation",
						"dataaqui": "Date d'acquisition (année/mois/jour)",
						"dataalienacao": "Date de cession (année/mois/jour)",
						"montantemaisvalia": "Montant de la plus-value déterminé par référence à la date du 30/06/2015",
						"impcorrespondente": "Taxe correspondante"
					}
				},
				"field9": "ÉQUILIBRE",
				"field10": "Impôt correspondant au solde positif entre les plus-values ​​réalisées et les moins-values ​​réalisées (à reporter en C2 de Q.05) [Solde C9 x 50%] x 25%",
				"field6": "SOMME (à porter au champ 3 de Q.05)"
			}
		},
		"anexoG": {
			"quadro03": {
				"title": "Pertes fiscales déterminées avant le régime spécial déductibles sous le régime général (art. 7, n ° 2 de l'annexe au DL 92/2018, du 13 novembre)",
				"field1": "Chiffre d'affaires des activités prévues (art. 3, n° 1 de l'Annexe au DL) et réalisées par les navires éligibles (art. 4 de l'Annexe au DL)",
				"field2": "Chiffre d'affaires des activités non prévu ou réalisé par des navires/navires non éligibles",
				"field3": "Ratio à appliquer dans la période aux pertes fiscales déductibles calculées avant le régime particulier : (Chiffre d'affaires des activités non prévues ou réalisées par les navires/navires non éligibles / Chiffre d'affaires total)",
				"table": {
					"fields": {
						"perio": "Période à laquelle se rapporte la perte",
						"montprejregespecial": "Montant des pertes fiscales déductibles avant entrée au régime particulier",
						"montprejperio": "Montant des pertes fiscales déduites sur la période",
						"saldo": "Solde reporté sur la ou les périodes suivantes"
					}
				},
				"field10": "Montant à ajouter dans le champ 309 du tableau 09 de la déclaration"
			},
			"quadro04": {
				"title": "Détermination du revenu imposable - Régime spécial",
				"desc": "Navires / Navires",
				"table": {
					"fields": {
						"field1": "Numéro d'identification (OMI)",
						"field2": "Pays d'immatriculation (article 9 de la DL)",
						"field3": "Régime d'exploitation du navire (art. 4, n° 8 de l'annexe au DL)",
						"field4": "Pays de gestion stratégique et commerciale (art. 4, n° 2, alinéa b) de l'Annexe à la DL)",
						"field5": "Pourcentage de membres d'équipage éligibles (art. 3, n° 3 de la DL)",
						"field6": "Tonnage net (article 5 de l'Annexe au DL)",
						"field7": "Pourcentage des revenus des activités auxiliaires dans le revenu total (art. 3, n° 3 de l'Annexe au DL)",
						"field8": "Nombre de jours (art. 5 de l'Annexe au DL)",
						"field9": "Réduction Montant imposable (art. 5, n° 6 de l'Annexe au DL)",
						"field10": "Matériel imposable (article 5 de l'Annexe au DL)"
					}
				},
				"field11": "Montant imposable déterminé (à transporter au champ 300 du tableau 09 de la déclaration)"
			},
			"quadro05": {
				"title": "Informations Complémentaires",
				"desc": "En cas d'affrètement à des tiers, indiquer :",
				"field1": "Le tonnage net des navires/navires pris en affrètement",
				"field2": "Le tonnage net de l'ensemble de la flotte (art. 4, n° 8 de l'Annexe au DL)",
				"field3": "Les revenus des navires/navires pris en affrètement",
				"field4": "Les revenus des navires/navires restants possédés ou assimilés (art. 4, n° 8 de l'Annexe au DL)"
			},
			"quadro06": {
				"title": "Autres informations",
				"field1": "Date à laquelle l’activité IRC a commencé (article 5, paragraphe 4 de l’Annexe au DL)",
				"field2-3": "Il y a eu cessation de la TVA il y a moins de cinq ans (art. 5, n° 5 de l'Annexe au DL)",
				"field2": "Oui",
				"field3": "Non"
			}
		},
		"modal": {
			"prejuizos": "Pertes",
			"nif": "FNI",
			"periodo": "Période",
			"montante": "Montant",
			"valPerio": "Valeur utilisée dans la période",
			"retFonte": "Retenue à la source",
			"rosto": {
				"titles": {
					"309": "Régime général",
					"320": "Réduction de taux",
					"331": "Exemption",
					"396": "Pertes individuelles déduites, vérifiées dans les périodes précédant le début de l'application du régime",
					"398": "Parts de pertes fiscales déduites en cas d'acquisition de groupes de sociétés (article 71, alinéas 4 et 5)",
					"397A": "Valeur utilisée dans la période [art. 15, no 1 al.",
					"397B": "Valeur utilisée dans la période (article 75, paragraphes 1 et 3)",
					"309B": "Régime particulier applicable aux acquéreurs d'entités considérées comme des entreprises en difficulté",
					"quadro12List": "Retenues à la source",
					"quadro14": "Crédit d'impôt pour double imposition légale internationale (CIDTJI)"
				},
				"contasdef": {
					"title": "Définir des comptes pour les champs",
					"campo": "Champ {{champ}}",
					"nContaDe": "Numéro de compte",
					"perio": "Période jusqu'à",
					"deznormal": "Décembre normal",
					"primeiroence": "1ère clôture",
					"segence": "2ème clôture",
					"sinal": "Signal",
					"positivo": "Positif",
					"negativo": "Négatif",
					"addline": "Ajouter une ligne",
					"table": {
						"nContaDe": "Numéro de compte",
						"nContaAte": "Nombre de comptes jusqu'à",
						"perioAte": "Période jusqu'à",
						"sinal": "Positif",
						"valor": "Valeur"
					}
				},
				"contasdefall": {
					"title": "Comptes définis pour les champs",
					"table": {
						"campo": "Champ"
					},
					"btn": {
						"copiarparaempresas": "Copie à d'autres entreprises",
						"reporcontas": "Réinitialiser les paramètres"
					}
				},
				"contasdefcopy": {
					"title": "Copier les comptes définis pour les champs vers une autre société",
					"checkbox": {
						"deletedestino": "Supprime les paramètres existants dans l'entreprise cible"
					}
				}
			},
			"anexoa": {
				"titles": {
					"A": "Critères généraux",
					"B": "Critères généraux - Zone de libre-échange de Madère (Art. 36.º -A, n° 12 de l'EBF)",
					"C": "Critère spécifique - centres de production électrique",
					"D": "Critères spécifiques - Minas"
				},
				"distmuni": "Code du district/commune",
				"taxaderra": "Taux de déversement",
				"massasalA": "Masse salariale municipale",
				"massasalB": "Masse salariale communale chez ZFM",
				"massasalC": "Facture salariale + prestation de services communaux (MSPSMunic)",
				"massasalD": "Facture salariale + prestation de services communaux (MSPSMunic)",
				"racioA": "Rapport de distribution (4) = (3) / (Q.03, C3)",
				"racioB": "Rapport de distribution (4) = (3) / (Q.03, C3)",
				"racioC": "Ratio de municipalité",
				"racioD": "Ratio de municipalité",
				"areainstexpl": "Zone d'installation.",
				"totalProduzido": "Électricité totale.",
				"producao": "Production à l'embouchure de la mine dans la commune (PBMunic)",
				"derramaA": "DÉVERSEMENT CALCULÉ (5) = (Q.03, C1 x (2) x (4)) ou (Q.03, C10) x (2) x (4)",
				"derramaB": "Déversement calculé (5) = [(Q.03, C2 x (2) x (4))] x 0,2",
				"derramaC": "Déversement calculé (8) = (Q03 C1) x (2) x (7)",
				"derramaD": "Déversement calculé (7) = (Q03 C1) x (2) x (6)",
				"primeiroAno": "Indiquez s'il s'agit de la 1ère année d'application du critère",
				"potencia": "Puissance installée dans la commune"
			},
			"anexod": {
				"titles": {
					"71": "Avantages fiscaux contractuels pour l'investissement (ex-article 41, alinéa 1 de l'EBF, articles 15 à 21 du CFI (abrogés) et articles 2 à 21 du CFI approuvés par le décret-loi n° 162/2014 du 31/10 et articles 2 à 21 du TPICE",
					"73": "SIFIDE - Système d'incitations fiscales à la recherche et au développement des entreprises (Loi n° 40/2005, du 3/8) et SIFIDE II (art. 133 de la loi 55-A/2010, du 31/12, art. 33.º au 40.º du CFI (abrogé) et articles 35.º à 42.º du CFI approuvés par le",
					"74": "Régime fiscal de soutien à l'investissement (loi n° 10/2009 du 10/3 (prolongée successivement), articles 26 à 32 du CFI (abrogés) et articles 22 à 26 du CFI approuvés par le décret-loi n° 162/2014 , du 31/10 et des articles 22 à",
					"77": "Transmission des avantages fiscaux de la société fusionnée ou dissoute ou de la société apporteuse (Article 75.º-A du CIRC)",
					"78": "Incitations soumises aux taux maximaux des aides régionales (CFI approuvé par le décret-loi n° 162/2014 du 31 octobre) (Pour les périodes fiscales 2015 et 2016)",
					"79": "FIPC - Incitation fiscale à la production cinématographique et audiovisuelle (Article 59.º-F de l'EBF et Ordonnance n° 89.º-A/2017, du 19 avril)",
					"121": "Informations à communiquer par la société acquéreuse",
					"122": "Informations à communiquer par l'entreprise considérée comme une entreprise en difficulté",
					"604": "Code NACE Rév. 1 (art. 36, n° 6 EBF)",
					"605": "Code NACE Rév. 2 (art. 36-A, n° 7 de l'EBF)",
					"710": "Incitation Fiscale à la Récupération - IFR (article 307 de la loi n° 12/2022 du 27 juin)",
					"31A": "Fonds de pension et assimilés (art. 16, n° 1 de la FBE) et autres fonds définitivement exonérés",
					"31B": "Autres exonérations définitives",
					"32A": "Fonds d'épargne en actions (art. 26 de la FBE) et autres fonds temporairement exonérés",
					"32B": "Autres exemptions temporaires",
					"04A": "Autres déductions sur le revenu",
					"041": "Transmission des avantages fiscaux de la société fusionnée ou scindée ou de la société apporteuse (article 75-A du CIRC)",
					"quadro8": "Donations (articles 62, 62-A et 62-B de l'EBF)",
					"quadro9": "Identification des sociétés associées (concept de société unique aux fins du plafond de minimis)",
					"quadro11A": "Partenaire ou entreprise(s) associée(s) telle(s) que définie(s) aux paragraphes 2 et 3 de l'article 3 de l'annexe du décret-loi n° 372/2007 du 6 novembre, indiquer le(s) FIV respectif.",
					"71A": "RETGS - INFORMATIONS COMPLÉMENTAIRES (à compléter par les sociétés qui composent le groupe) - utilisation de l'avantage au sein du groupe",
					"73A": "RETGS - INFORMATIONS COMPLÉMENTAIRES (à compléter par les sociétés qui composent le groupe) - utilisation de l'avantage au sein du groupe",
					"74A": "RETGS - INFORMATIONS COMPLÉMENTAIRES (à compléter par les sociétés qui composent le groupe) - utilisation de l'avantage au sein du groupe",
					"710A": "RETGS - INFORMATIONS COMPLÉMENTAIRES (à compléter par toutes les sociétés qui composent le groupe) - utilisation de l'avantage au sein du groupe",
					"78A1": "Informations sur les projets d'investissement régionaux",
					"78A2": "Incitations financières appréciées et taxe utilisée - Valeurs pour la période fiscale",
					"78A3": "Incitations financières bénéficiant et taxes utilisées - Valeurs actualisées accumulées"
				},
				"quadro7": {
					"nif71": "NIF de l'entreprise.",
					"nif73": "NIF de l'entreprise.",
					"nif74": "NIF de l'entreprise.",
					"nif76": "NIF de l'entreprise.",
					"nif77": "NIF société fusionnée, scindée ou apportant un apport",
					"nIdentificacao": "Numéro d'identification du travail",
					"diploma": "Diplôme",
					"periodo": "Période à laquelle se rapporte la prestation",
					"saldoCaducado71": "Solde expiré",
					"saldoCaducado73": "Solde expiré",
					"saldoCaducado74": "Solde expiré",
					"saldoCaducado71A": "Solde réel expiré dans la déclaration de groupe",
					"saldoCaducado73A": "Solde réel expiré dans la déclaration de groupe",
					"saldoCaducado74A": "Solde réel expiré dans la déclaration de groupe",
					"saldoNaoDeduzido71": "Solde non déduit dans la période précédente",
					"saldoNaoDeduzido73": "Solde non déduit dans la période précédente",
					"saldoNaoDeduzido74": "Solde non déduit dans la période précédente",
					"saldoNaoDeduzido76": "Solde non déduit dans la période précédente",
					"saldoNaoDeduzido71A": "Solde non déduit dans la période précédant la collecte collective",
					"saldoNaoDeduzido73A": "Solde non déduit dans la période précédant la collecte collective",
					"saldoNaoDeduzido74A": "Solde non déduit dans la période précédant la collecte collective",
					"saldoNaoDeduzido76A": "Solde non déduit dans la période précédant la collecte collective",
					"saldoNaoDeduzido79": "Solde non déduit dans la période précédente",
					"dotacaoDoPeriodo71": "Répartition des périodes",
					"dotacaoDoPeriodo73": "Répartition des périodes",
					"dotacaoDoPeriodo74": "Répartition des périodes",
					"dotacaoDoPeriodo76": "Répartition des périodes",
					"dotacaoDoPeriodo71A": "Affectation de la période dans la déclaration de groupe",
					"dotacaoDoPeriodo73A": "Affectation de la période dans la déclaration de groupe",
					"dotacaoDoPeriodo74A": "Affectation de la période dans la déclaration de groupe",
					"dotacaoDoPeriodo76A": "Affectation de la période dans la déclaration de groupe",
					"deducaoNoPeriodo": "Déduction périodique",
					"deducaoUtilizada": "Déduction utilisée dans la déclaration de groupe",
					"saldoQueTransita71": "Solde reporté à la période suivante",
					"saldoQueTransita73": "Solde reporté à la période suivante",
					"saldoQueTransita74": "Solde reporté à la période suivante",
					"saldoQueTransita76": "Solde reporté à la période suivante",
					"saldoQueTransita71A": "Solde reporté à la période suivante dans la déclaration groupe",
					"saldoQueTransita73A": "Solde reporté à la période suivante dans la déclaration groupe",
					"saldoQueTransita74A": "Solde reporté à la période suivante dans la déclaration groupe",
					"saldoQueTransita76A": "Solde reporté à la période suivante dans la déclaration groupe",
					"saldoQueTransita79": "Solde reporté à la période suivante",
					"dataInicio": "Date de début des travaux",
					"dataConclusao": "Date de fin des travaux",
					"valorIncentivo": "Valeur incitative dans la période",
					"deducaoDoPeriodo": "Déduction périodique",
					"valorAReembolsar": "Montant à rembourser",
					"valorARepor": "Valeur à restituer (à transporter en C.372 de Q.10 de M.22)",
					"saldo": "Solde du bénéfice transmis",
					"dotacao": "Répartition des périodes",
					"montanteBeneficio": "Montant de l'avantage fiscal",
					"dsdiploma": {
						"1": "Statut des avantages fiscaux (ex-art. 41) et décret-loi n° 409/99, du 15 octobre.",
						"2": "Code des impôts sur les investissements, approuvé par le décret-loi n° 249/2009 du 23 septembre, modifié par la loi n° 20/2012 du 14 mai, par le décret-loi n° 82/2013 du 17 juin et par la loi. N° 83-C/2013, du 31 décembre et abrogé par décret-loi",
						"3": "Code des impôts sur les investissements, approuvé par le décret-loi n° 162/2014 du 31 octobre.",
						"4": "Décret législatif régional n° 18/99/M, du 28 juin",
						"5": "Décret législatif régional n. 2/99/A, du 20 janvier (art. 9.º)",
						"6": "Code des impôts sur les investissements en RAM approuvé par le décret législatif régional n ° 24/2016/M du 28 juin",
						"720": "720",
						"724": "Crédit d'impôt extraordinaire pour investissement (Loi n° 49/2013, du 16 juillet)",
						"731": "Loi n° 40/2005 du 3 août",
						"732": "Code des impôts sur les investissements, approuvé par le décret-loi n° 249/2009 du 23 septembre et abrogé par le décret-loi n° 162/2014 du 31 octobre",
						"733": "Code des impôts sur les investissements, approuvé par le décret-loi n° 162/2014 du 31 octobre.",
						"734": "Code des impôts sur les investissements dans la R. A. de Madère approuvé par le décret législatif régional n° 24/2016/M, du 28 juin.",
						"741": "RFAI - Régime fiscal de soutien à l'investissement [Loi n° 10/2009, du 10 mars (successivement prorogée) et art.",
						"742": "RFAI - Régime fiscal de soutien à l'investissement (art. 22 à 26 du CFI approuvé par le décret-loi n° 162/2014 du 31 octobre)",
						"743": "RFAI - Régime fiscal de soutien aux investissements dans la région autonome de Madère - Décret législatif régional n° 24/2016/M, du 28 juin)."
					},
					"tabelas78": {
						"regiao78": "Région éligible (article 43 du CFI)",
						"regiao78A1": "Région éligible",
						"cae78": "Code CAE de l'activité à laquelle l'investissement est destiné (article 2 du Port. n° 282/2014, du 31/12)",
						"cae78A1": "Code CAE",
						"montanteDasAplicacoes": "Nombre de demandes pertinentes (articles 11, 22 et 30 du TPICE)",
						"irc": "IRC",
						"imiIMTSelo": "IMI, IMT et SELO",
						"naoFiscais": "Non fiscal",
						"numeroLinha": "Numéro de ligne",
						"numProjecto": "Numéro de projet/code d'incitation",
						"dataInicio": "Date de début de l'investissement",
						"dataFim": "Date de fin d'investissement",
						"tipologia": "Typologie d'investissement",
						"identificacao": "Identification officielle de l'incitation financière",
						"montante": "Montant total",
						"montanteActualizado": "Montant total mis à jour",
						"aplicacoesMontante78A2": "Demandes pertinentes réalisées - Montant",
						"aplicacoesMontante78A3": "Demandes pertinentes effectuées - Montant accumulé mis à jour",
						"aplicacoesActualizado": "Montant mis à jour",
						"financeiroUsufruido": "Financier - Montant apprécié",
						"financeiroActualizado": "Financier - Montant utilisé mis à jour",
						"ircUtilizado": "IRC - Montant utilisé",
						"ircActualizado": "IRC - Montant mis à jour",
						"imiUtilizado": "IMI - Montant utilisé",
						"imiActualizado": "IMI - Montant mis à jour",
						"imtUtilizado": "IMT - Montant utilisé",
						"seloUtilizado": "SCEAU - Quantité utilisée",
						"financeiroMontante": "Financier - Montant utilisé mis à jour",
						"ircMontante": "IRC - Montant mis à jour",
						"imiMontante": "IMI - Montant mis à jour",
						"imtMontante": "IMT - Montant utilisé",
						"seloMontante": "SCEAU - Quantité utilisée",
						"intensidade": "Intensité de l'aide cumulée (en %)",
						"montanteAInscrever": "Montant à inscrire dans le champ 372 de Q. 10 de M. 22",
						"montanteTotal": "Montant total mis à jour des avantages bénéficiés/utilisés",
						"tipo": "Taper",
						"datasources": {
							"beneficio": {
								"71": "Avantages fiscaux contractuels pour l'investissement (ex-art. 41, n° 1 de l'EBF, articles 15 à 21 du CFI (abrogés), articles 2 à 21 du CFI approuvés par le décret-loi n° 162/2014 du 31 /10 et et articles 2 à 21 du TPICE",
								"727": "DLRR - Déduction pour bénéfices retenus et réinvestis par les PME (articles 27 à 34 du CFI approuvés par le décret-loi n° 162/2014, du 31/10 et articles 27 à 34. du CFI en RAM approuvés par le décret régional n° 162/2014 du 31/10 et articles 27 à 34 du CFI en RAM approuvés par le décret régional n° 24/2016/M, du 28/06)",
								"741": "RFAI - Régime fiscal de soutien à l'investissement [Loi n° 10/2009, du 10 mars (successivement prorogée) et art.",
								"742": "RFAI - Régime fiscal de soutien à l'investissement (art. 22 à 26 du CFI approuvé par le décret-loi n° 162/2014 du 31 octobre)",
								"743": "RFAI - Régime fiscal de soutien aux investissements dans la région autonome de Madère - Décret législatif régional n° 24/2016/M, du 28 juin)."
							},
							"regiao78": {
								"1": "Nord",
								"2": "Centre",
								"3": "Alentejo",
								"4": "Région autonome des Açores",
								"5": "Région autonome de Madère",
								"6": "Algarve",
								"7": "Mafra",
								"8": "Loures",
								"9": "Péninsule de Setúbal",
								"10": "Vila Franca de Xira",
								"11": "S. João das Lampas et Terrugem (Sintra)"
							},
							"regiao78A1": {
								"PT111": "Nord - Haut Minho",
								"PT112": "Nord - Cavado",
								"PT119": "Nord - Avenue",
								"PT11A": "Nord - Zone Métropolitaine de Porto",
								"PT11B": "Nord - Alto Tâmega",
								"PT11C": "Nord - Tâmega et Sousa",
								"PT11D": "Nord - Douro",
								"PT11E": "Nord - Terres de Trás-os-Montes",
								"PT16B": "Centre - Ouest",
								"PT16D": "Centre - Région d'Aveiro",
								"PT16E": "Centre - Région de Coimbra",
								"PT16F": "Centre - Région de Leiria",
								"PT16G": "Centre - Viseu Dão Lafões",
								"PT16H": "Centre - Beira Baixa",
								"PT16I": "Centre - Moyen Tage",
								"PT16J": "Centre - Beiras et Serra da Estrela",
								"PT1109": "Grand Lisbonne - Mafra",
								"PT1107": "Grand Lisbonne - Loures",
								"PT1114": "Grand Lisbonne - Vila Franca de Xira",
								"PT111127": "Grand Lisbonne - S. João das Lampas et Terrugem (Sintra)",
								"PT172": "Péninsule de Setúbal",
								"PT181": "Alentejo - Côte de l'Alentejo",
								"PT184": "Alentejo - Bas Alentejo",
								"PT185": "Alentejo - Lezíria do Tejo",
								"PT186": "Alentejo - Haut Alentejo",
								"PT187": "Alentejo - Centre de l'Alentejo",
								"PT150": "Algarve",
								"PT200": "Région autonome des Açores",
								"PT300": "Région autonome de Madère"
							},
							"tipo": {
								"D": "Distinctif",
								"U": "Célibataire"
							},
							"tipologia": {
								"001": "Création d'un nouvel établissement",
								"002": "Augmenter la capacité d'un établissement existant",
								"003": "Diversification de la production d'un établissement à l'égard de produits non fabriqués auparavant dans cet établissement",
								"004": "Changement fondamental dans le processus de production global d'un établissement existant"
							},
							"identificacao": {
								"10": "NORD 2020",
								"11": "RDP 2020",
								"12": "PO SEUR",
								"13": "POAT 2020",
								"14": "PRODERAM 2020",
								"15": "PRORURAL+",
								"99": "AUTRES",
								"01": "AÇORES 2020",
								"02": "ALENTEJO 2020",
								"03": "CENTRE 2020",
								"04": "LE COMMERCE INVESTIT",
								"05": "COMPÉTITION 2020",
								"06": "CRESC ALGARVE 2020",
								"07": "LISBONNE 2020",
								"08": "BOIS 14-20",
								"09": "MARS 2020"
							}
						}
					}
				},
				"codBeneficio": "Code avantage",
				"montante": "Montant",
				"nif041": "Société NIF.",
				"nifquadro8": "NIF de l'entité donataire",
				"nifquadro9": "907 - FNI",
				"nifquadro11A": "3 - FNI",
				"nif121": "Chronique NIF de l'entreprise en difficulté",
				"nif122": "Colonne NIF de la société qui acquiert la participation",
				"nace604": "Code NACE Rév. 1 (art. 36, n° 6 EBF)",
				"nace605": "Code NACE Rév. 2 (art. 36-A, n° 7 de l'EBF)",
				"valorDonativo": "Valeur du don",
				"tipoDonativo": "Type de don",
				"ano603": "Année d'investissement",
				"percentagem": "Pourcentage moyen",
				"periodo": "Période de dédouanement",
				"prejuizosMontante": "Montant des pertes",
				"prejuizosSaldo": "Pertes fiscales courantes"
			},
			"anexoe": {
				"title": "Subventions non destinées à l'exploration",
				"rendimento": "Performance",
				"materiacol": "Matériel de collection = [(6) x 0,30 x t]"
			},
			"anexof": {
				"titles": {
					"A": "Plus et moins-values ​​résultant de la vente d'immeubles acquis selon la rédaction précédente de l'article 22 de l'EBF",
					"B": "Plus-values ​​résultant de la vente d'autres actifs"
				},
				"identificacao": "Identification matricielle des propriétés",
				"codFreguesia": "Code paroissial",
				"tipo": "Taper",
				"artigo": "Article",
				"fraccao": "Fraction/Section",
				"dataAquisicao": "Date d'acquisition",
				"dataAlienacao": "Date d'élimination",
				"montanteA": "Montant de la plus-value et de la perte réalisées",
				"montanteB": "Montant de la plus-value déterminé par référence à la date du 30/06/2015",
				"montanteRegimeTransitorio": "Gains et pertes de valeur couverts par le régime transitoire",
				"designacao": "Désignation",
				"imposto": "Taxe correspondante"
			},
			"anexog": {
				"titles": {
					"quadro3": "",
					"quadro4": ""
				},
				"periodo": "Période à laquelle se rapporte la perte",
				"montanteDedutiveis": "Montant des pertes fiscales déductibles avant entrée au régime particulier",
				"montanteDeduzidos": "Montant des pertes fiscales déduites sur la période",
				"saldo": "Solde reporté sur la ou les périodes suivantes",
				"nIdentificacao": "Numéro d'identification (OMI)",
				"paisDeRegisto": "Pays d'immatriculation (article 9 de la DL)",
				"regime": "Régime d'exploitation du navire (art. 4, n° 8 de l'annexe au DL)",
				"paisDeGestao": "Pays de gestion stratégique et commerciale (art. 4, n° 2, alinéa b) de l'Annexe à la DL)",
				"percTripulantes": "Pourcentage de membres d'équipage éligibles (art. 3, n° 3 de la DL)",
				"arqueacao": "Tonnage net (article 5 de l'Annexe au DL)",
				"percRendimentos": "Pourcentage des revenus des activités auxiliaires dans le revenu total (art. 3, n° 3 de l'Annexe au DL)",
				"numeroDias": "Nombre de jours (art. 5 de l'Annexe au DL)",
				"reducaoMateria": "Réduction Montant imposable (art. 5, n° 6 de l'Annexe au DL)",
				"materiaColectavel": "Matériel imposable (article 5 de l'Annexe au DL)"
			},
			"prejuizosfiscais": {
				"title": "Pertes fiscales",
				"anoprej": "Année de perte",
				"valprej": "Valeur de la perte fiscale",
				"anoded": "Année de déduction",
				"valded": "Montant de la déduction",
				"adicionarprej": "Ajouter une perte",
				"adicionarded": "Ajouter une déduction",
				"table": {
					"ano": "Année de perte",
					"valorPrejuizoFiscal": "Valeur de la perte",
					"valorDeduzido": "Montant déduit",
					"valorPorDeduzir": "Montant à déduire",
					"anoDeducao": "Année de déduction",
					"valorDeducao": "Montant de la déduction"
				}
			},
			"derrama": {
				"title": "Données supplémentaires pour le calcul du déversement",
				"concelho": "Comté : {{cod}} - {{name}}",
				"ano": "Année : {{année}}",
				"postosTrabalhoCriados": "Nombre d'emplois créés",
				"volumeNegocios": "Volume d'affaires de l'année précédente",
				"investimentoNoAno": "Investissement dans l'année",
				"temSedeNoConcelho": "La société a son siège dans la commune",
				"anoFixacaoDaSede": "Année de création du siège social",
				"microEmpresa": "Micro-entreprise",
				"pequenaEmpresa": "Petite entreprise",
				"mediaEmpresa": "Entreprise moyenne",
				"acrescimoTrabalhadores": "Augmentation du nombre de travailleurs (%)",
				"postosTrabalhoCriados2019": "Nombre d'emplois créés en 2019",
				"postosTrabalhoCriados2020": "Nombre d'emplois créés en 2020"
			},
			"configws": {
				"title": "Paramètres du service Web",
				"username": "Nom d'utilisateur du contributeur",
				"password": "Mot de passe du contribuable",
				"servicoInvCC": "Service invoqué par un comptable agréé (CC)",
				"ccPowers": "CC avec pleins pouvoirs déclaratifs pour Contrib.",
				"usernameCC": "Nom d'utilisateur CC",
				"passwordCC": "Mot de passe CC",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> Le service est déjà configuré.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> Le service n'est pas configuré.",
				"configsaved": "Configuration enregistrée avec succès."
			}
		},
		"datasources": {
			"tiporend": {
				"lucrosestav": "Bénéfices de l'établissement.",
				"outrosrend": "Autres revenus"
			},
			"beneficio": {
				"100": "Régime d'intériorité - article 43 de l'EBF - Régime transitoire",
				"119": "Autres déductions sur le revenu",
				"120": "Concessionnaire de la Zone Franche de Madère - Exonération jusqu'en 2017 (art. 33, n° 12 de l'EBF)",
				"121": "Bénéfices provenant des travaux de la base de Lajes et des installations de soutien - article XI de l'annexe I de l'accord technique, approuvé par la résolution de l'Assemblée de la République 38/95 du 11 octobre - Accord de coopération et de défense entre la République portugaise et les États-Unis",
				"122": "Décret-loi n° 43 335/1960 du 11/19",
				"129": "Autres exemptions temporaires",
				"140": "Fonds de pension et fonds assimilés (art. 16, n° 1 de la FBE)",
				"141": "Fonds d'épargne retraite, d'épargne éducation et d'épargne retraite/études (art. 21, n° 1 de la FBE)",
				"142": "Fonds de capital-risque (art. 23 de la FBE)",
				"143": "Fonds de placement immobilier en ressources forestières (art. 24, n° 1 de la FBE)",
				"149": "Autres fonds définitivement exonérés",
				"150": "Fonds d'épargne en actions (art. 26, n° 1 de la FBE)",
				"151": "Fonds d'investissement immobilier - réhabilitation urbaine (art. 71, n° 1 de la FBE)",
				"159": "Autres fonds temporairement exonérés",
				"401": "Augmentation de la création d'emplois (art. 19 du FBE)",
				"409": "Renumérotation conventionnelle du capital social - PME (art. 136 de la loi n° 55-A/2010, du 31/12 et art. 41-A de l'EBF)",
				"410": "Autres déductions sur le revenu",
				"040": "Entités de navigation maritime et aérienne (article 13 du CIRC)",
				"049": "Autres exonérations définitives"
			},
			"tipodonativo": {
				"10": "Patronage social (art. 62)",
				"11": "Patronage social - soutien spécial (art. 62)",
				"12": "Patronage familial (art. 62)",
				"13": "Patronage culturel (art. 62.º-B)",
				"14": "Mécénat culturel – contrats pluriannuels (art. 62-B)",
				"15": "Patronage des organismes associatifs (art. 62)",
				"16": "Patronage pour la société de l'information (art. 65).",
				"17": "Mécénat - société de l'information - contrats pluriannuels (art. 65).",
				"18": "Etat – mécénat scientifique (art. 62-A)",
				"19": "Etat – mécénat scientifique – contrats pluriannuels (art. 62-A)",
				"20": "Mécénat scientifique - entités privées (art. 62-A)",
				"21": "Mécénat scientifique - entités privées - contrats pluriannuels (art. 62-A)",
				"22": "Régimes spéciaux (législation distincte)",
				"23": "Patronage culturel - autres (art. 62.º-B)",
				"24": "Dons en nature (n° 11 de l'article 62, n° 5 de l'article 62-A de l'EBF et n° 7 de l'article 62-B)",
				"25": "Dons alloués à la Structure de Mission pour les Célébrations du Ve Centenaire de la Circum-Navigation, créée par la Résolution du Conseil des Ministres n° 24/2017, du 26 janvier – mécénat culturel (art. 294 de la Loi n. 71/2018, du 31 décembre)",
				"01": "État – patronage social (art. 62)",
				"02": "État – mécénat culturel (art. 62.º-B)",
				"03": "État - mécénat environnemental (art. 62)",
				"04": "Etat - mécénat éducatif (art. 62)",
				"06": "Etat – mécénat culturel – contrats pluriannuels (art. 62-B)",
				"07": "Etat - mécénat environnemental - contrats pluriannuels (art. 62)",
				"08": "Etat – mécénat sportif – contrats pluriannuels (art. 62)",
				"09": "Etat – mécénat éducatif – contrats pluriannuels (art. 62)"
			},
			"tipoidentmatricial": {
				"u": "Urbain",
				"r": "Rustique",
				"o": "Omis"
			},
			"paises": {
				"4": "AFGHANISTAN",
				"8": "ALBANIE",
				"10": "ANTARCTIQUE",
				"12": "ALGÉRIE",
				"16": "SAMOA AMÉRICAINES",
				"20": "ANDORRE",
				"24": "ANGOLA",
				"28": "ANTIGUA ET BARBUDA",
				"31": "AZERBAÏDJAN",
				"32": "ARGENTINE",
				"36": "AUSTRALIE",
				"40": "AUTRICHE",
				"44": "BAHAMAS",
				"48": "BAREM",
				"50": "BANGLADESH",
				"51": "ARMÉNIE",
				"52": "BARBADE",
				"56": "BELGIQUE",
				"60": "BERMUDES",
				"64": "BUTAO",
				"68": "BOLIVIE",
				"70": "BOSNIE-HERZÉGOVINE",
				"72": "BOTSWANA",
				"74": "ILES BOUVET",
				"76": "BRÉSIL",
				"84": "BÉLISE",
				"86": "TERRITOIRE BRITANNIQUE DE L'OCÉAN INDIEN",
				"90": "ILES SOLOMAO",
				"92": "ÎLES VIERGES (BRITANNIQUES)",
				"96": "BRUNÉI DARUSSALAM",
				"100": "BULGARIE",
				"104": "MYANMAR",
				"108": "BURUNDI",
				"112": "BIÉLORUSSIE",
				"116": "CAMBODGE",
				"120": "CAMEROUN",
				"124": "CANADA",
				"132": "CAP-VERT",
				"136": "ILES CAYMAO",
				"140": "CENTRAFRICAINE (RÉPUBLIQUE)",
				"144": "SRI LANKA",
				"148": "TCHAD",
				"152": "CHILI",
				"156": "CHINE",
				"158": "TAÏWAN",
				"162": "ÎLES DE NOËL",
				"166": "ÎLES COCOS OU KEELING",
				"170": "COLOMBIE",
				"174": "COMORES",
				"175": "MAYOTTE",
				"178": "CONGO",
				"180": "CONGO (RÉPUBLIQUE DÉMOCRATIQUE DU)",
				"184": "ILES COOK",
				"188": "COSTA RICA",
				"191": "CROATIE",
				"192": "CUBA",
				"196": "CHYPRE",
				"203": "RÉPUBLIQUE TCHÈQUE",
				"204": "BÉNIN",
				"208": "DANEMARK",
				"212": "DOMINIQUE",
				"214": "RÉPUBLIQUE DOMINICAINE",
				"218": "ÉQUATEUR",
				"222": "LE SALVADOR",
				"226": "GUINÉE ÉQUATORIALE",
				"231": "ETHIOPIE",
				"232": "ÉRYTHRÉE",
				"233": "ESTONIE",
				"234": "ILES FÉROÉ",
				"238": "ILES FALKLAND (MALVINAS)",
				"239": "GÉORGIE DU SUD ET ÎLES SANDWICH",
				"242": "ILES FIDJI",
				"246": "FINLANDE",
				"248": "ILES ALAND",
				"250": "FRANCE",
				"254": "GUYANE FRANÇAISE",
				"258": "POLYNÉSIE FRANÇAISE",
				"260": "TERRITOIRES DU SUD DE LA FRANÇAIS",
				"262": "Djibouti",
				"266": "GABON",
				"268": "GÉORGIE",
				"270": "GAMBIE",
				"275": "TERRITOIRE PALESTINIEN OCCUPÉ",
				"276": "ALLEMAGNE",
				"278": "RÉPUBLIQUE DÉMOCRATIQUE ALLEMANDE",
				"288": "GHANA",
				"292": "GIBRALTAR",
				"296": "KIRIBATI",
				"300": "GRÈCE",
				"304": "GROENLAND",
				"308": "GRENADE",
				"312": "GUADELOUPE",
				"316": "GUAM",
				"320": "GUATEMALA",
				"324": "GUINÉE",
				"328": "GUYANE",
				"332": "HAÏTI",
				"334": "ÎLES HEARD ET ÎLES MCDONALD",
				"336": "SANTA SE (CITÉ DU VATICAN)",
				"340": "HONDURAS",
				"344": "HONG-KONG",
				"348": "HONGRIE",
				"352": "ISLANDE",
				"356": "INDE",
				"360": "INDONÉSIE",
				"364": "IRAN (RÉPUBLIQUE ISLAMIQUE)",
				"368": "IRAK",
				"372": "IRLANDE",
				"376": "ISRAËL",
				"380": "ITALIE",
				"384": "CÔTE D'IVOIRE",
				"388": "JAMAÏQUE",
				"392": "JAPON",
				"398": "KAZAKHSTAN",
				"400": "JORDANIE",
				"404": "KENYA",
				"408": "CORÉE (RÉPUBLIQUE POPULAIRE DÉMOCRATIQUE DE)",
				"410": "CORÉE (RÉPUBLIQUE DE)",
				"414": "KOWEIT",
				"417": "KIRGHIZ",
				"418": "LAOS (RÉPUBLIQUE POPULAIRE DÉMOCRATIQUE DE)",
				"422": "LIBAN",
				"426": "LESOTHO",
				"428": "LETTONIE",
				"430": "LIBÉRIA",
				"434": "LIBYE (JAMAHIRIYA ARABE)",
				"438": "LIECHTENSTEIN",
				"440": "LITUANIE",
				"442": "LUXEMBOURG",
				"446": "MACAO",
				"450": "MADAGASCAR",
				"454": "MALAWI",
				"458": "MALAISIE",
				"462": "MALDIVES",
				"466": "MALI",
				"470": "MALTE",
				"474": "MARTINIQUE",
				"478": "MAURITANIE",
				"480": "MAURICIA",
				"484": "MEXIQUE",
				"492": "MONACO",
				"496": "MONGOLIE",
				"498": "MOLDAVIE (RÉPUBLIQUE DE)",
				"499": "MONTÉNÉGRO",
				"500": "MONSERRATE",
				"504": "MAROC",
				"508": "MOZAMBIQUE",
				"512": "OMAN",
				"516": "NAMIBIE",
				"520": "NAURU",
				"524": "NÉPAL",
				"528": "PAYS-BAS",
				"530": "Antilles néerlandaises",
				"531": "GUÉRISON",
				"533": "ARUBA",
				"534": "SAINT-MARTIN",
				"535": "BONAIRE, SAINT EUSTATE ET SABA",
				"540": "NOUVELLE-CALÉDONIE",
				"548": "VANUATU",
				"554": "NOUVELLE-ZÉLANDE",
				"558": "NICARAGUA",
				"562": "NIGER",
				"566": "NIGERIA",
				"570": "NIUE",
				"574": "ÎLES NORFOLK",
				"578": "NORVÈGE",
				"580": "ILES MARIENNES DU NORD",
				"581": "PETITES ÎLES LOINTES DES ÉTATS-UNIS",
				"583": "MICRONÉSIE (ÉTATS FÉDÉRÉS DE)",
				"584": "ÎLES MARSHALL",
				"585": "PALAO",
				"586": "PAKISTAN",
				"591": "PANAMA",
				"598": "PAPUASIE - NOUVELLE-GUINÉE",
				"600": "PARAGUAY",
				"604": "PÉROU",
				"608": "PHILIPPINES",
				"612": "PITCAIRN",
				"616": "POLOGNE",
				"620": "PORTUGAL",
				"624": "GUINE-BISSAU",
				"626": "TIMOR ORIENTAL",
				"630": "PORTO RICO",
				"634": "QATAR",
				"638": "RÉUNION",
				"642": "ROUMANIE",
				"643": "RUSSIE (FÉDÉRATION DE)",
				"646": "RWANDA",
				"652": "S.O. BARTHOLOMEU",
				"654": "SAINTE-HÉLÈNE",
				"659": "SAINT-CHITTS-ET-NEVIS",
				"660": "ANGUILA",
				"662": "SANTA LUCIE",
				"666": "SAINT-PIERRE-ET-MIQUELON",
				"670": "SAINT VINCENT ET LES GRENADINES",
				"674": "SAINT-MARIN",
				"678": "SAO TOMÉ ET PRINCIPE",
				"682": "ARABIE SAOUDITE",
				"686": "SÉNÉGAL",
				"688": "SERBIE",
				"690": "SEYCHELLES",
				"694": "SIERRA LEONE",
				"702": "SINGAPOUR",
				"703": "SLOVAQUE (RÉPUBLIQUE)",
				"704": "VIETNAM",
				"705": "SLOVÉNIE",
				"706": "SOMALIE",
				"710": "AFRIQUE DU SUD",
				"716": "ZIMBABWE",
				"724": "ESPAGNE",
				"728": "SOUDAN DU SUD",
				"729": "SOUDAN",
				"732": "SARAH OUEST",
				"736": "SOUDAN",
				"740": "SURINAM",
				"744": "SVALBARD ET L'ÎLE DE JAN MAYEN",
				"748": "SWAZILAND",
				"752": "SUÈDE",
				"756": "SUISSE",
				"760": "SYRIE (RÉPUBLIQUE ARABE DE)",
				"762": "TAJIQUISTAO",
				"764": "THAÏLANDE",
				"768": "ALLER",
				"772": "TOKÉLAOU",
				"776": "TONGA",
				"780": "TRINITÉ ET TOBAGO",
				"784": "ÉMIRATS ARABES UNIS",
				"788": "TUNISIE",
				"792": "Turquie",
				"795": "TURKMÉNISTE",
				"796": "TURQUES ET CAÏQUES (ÎLES)",
				"798": "TUVALU",
				"800": "OUGANDA",
				"804": "UKRAINE",
				"807": "MACÉDOINE (EX-RÉPUBLIQUE YOUGOSLAVE DE)",
				"810": "UNION SOVIÉTIQUE",
				"818": "EGYPTE",
				"826": "ROYAUME-UNI",
				"830": "ILES CANAUX",
				"831": "GUERNESEY",
				"832": "JERSEY",
				"833": "ÎLE DE MAN",
				"834": "TANZANIE, RÉPUBLIQUE-UNIE DE",
				"840": "ÉTATS-UNIS",
				"850": "ÎLES VIERGES (ÉTATS-UNIS)",
				"854": "BURKINA-FASO",
				"858": "URUGUAY",
				"860": "USBÉQUISTAO",
				"862": "VENEZUELA",
				"876": "WALLIS ET FUTUNA (ÎLES)",
				"882": "SAMOA",
				"887": "YÉMEN",
				"891": "JUGOSL.VIA",
				"892": "SERVIA ET MONTÉNÉGRO",
				"894": "ZAMBIE",
				"900": "OLIVEN.A",
				"901": "RÉPUBLIQUE DU KOSOVO",
				"956": "ALDERNE"
			},
			"regimeexploracao": {
				"1": "Acquisition directe",
				"2": "Location longue durée",
				"3": "Location",
				"4": "Charte aux tiers"
			},
			"factoimpedimento": {
				"naoaplica": "Ne s'applique pas",
				"falecimentoconjuge": "Décès d'un conjoint non séparé de personnes et de biens, d'une personne avec laquelle il vit dans des conditions similaires à celles des époux, ou d'un parent ou assimilé au 1er degré de la ligne droite (point a) du paragraphe 1 de l'article 12. -A du décret-loi n° 452/99, du 5 novembre)",
				"falecimentooutro": "Décès d'un autre parent ou assimilé en ligne directe ou au 2ème degré de la ligne collatérale (point b) du n° 1 de l'article 12.º-A du décret-loi n° 452/99, du 5 novembre).",
				"doencagrave": "Maladie grave et soudaine ou hospitalisation, qui rend absolument impossible pour l'expert-comptable de remplir ses obligations, ainsi que dans les situations d'accouchement (paragraphe c) du paragraphe 1 de l'article 12.º-A du décret-loi n. 99, du 5 novembre)",
				"sitparentalidade": "Situations parentales (paragraphe d) du n° 1 de l'article 12.º-A du décret-loi n° 452/99)"
			}
		},
		"messages": {
			"nifAlredyExists": "Le numéro de contribuable existe déjà.",
			"nifEmpty": "Vous devez indiquer votre numéro de contribuable.",
			"periodoAlredyExists": "L'année indiquée comporte déjà une perte attribuée.",
			"paisTipoRendAlredyExists": "Il existe déjà un pays avec un type de revenu.",
			"codbenefvazioAlredyExists": "Le code existe déjà.",
			"codbenefvazio": "Vous n'avez pas sélectionné de code.",
			"naceAlredyExists": "Le code existe déjà.",
			"naceVazio": "Vous n'avez pas sélectionné de code.",
			"tipoDonativovazio": "Vous n'avez pas sélectionné de type de don",
			"niftipoDonativoAlredyExists": "Il existe déjà un numéro de contribuable avec le type de don sélectionné",
			"diplomavazio": "Je n'ai pas sélectionné de diplôme",
			"nIdentificacaovazio": "Vous n'avez pas renseigné le numéro d'identification de l'œuvre.",
			"datavazia": "La date ne peut pas être vide.",
			"regiaovazio": "Vous devez sélectionner une région.",
			"caevazio": "Vous devez sélectionner un code CAE.",
			"regeiaocaeAlredyExists": "Une région avec le code CAE existe déjà.",
			"tipovazio": "Vous devez sélectionner un type.",
			"numProjectovazio": "Vous devez indiquer un numéro de projet/code d'incitation.",
			"rendimentovalido": "Vous devez saisir un revenu.",
			"tvalido": "Le nombre d'années doit être supérieur à zéro.",
			"freguesiavazio": "Vous devez indiquer un code paroissial.",
			"designacaovazio": "La désignation ne peut pas être vide.",
			"montantevazio": "Le montant de la plus-value doit être supérieur à zéro.",
			"prejuizoAlredyExists": "L'année indiquée comporte déjà une perte fiscale imputée.",
			"dadosguardados": "Données enregistrées avec succès !",
			"registosdel": "Model22 supprimé avec succès.",
			"temDeGuardar": "Vous devez sauvegarder les données pour pouvoir créer le fichier",
			"avisoAnexos": "Êtes-vous sûr de vouloir supprimer la pièce jointe <strong>\"{{attachment}}\"</strong> ?",
			"mod22calculado": "Modèle calculé",
			"containicialAlredyExists": "Le compte initial est déjà créé !",
			"derramaErrorMessage": "Déversement municipal.",
			"anoAlredyExists": "L'année indiquée dans le découpage existe déjà",
			"anoVazio": "Vous devez indiquer l'année de l'investissement",
			"prejEmpty": "Les dégâts ne peuvent pas être nuls ou 0.",
			"cleanValuesInfo": "Voulez-vous annuler les valeurs saisies manuellement ?",
			"cleanValuesTooltip": "Annule les valeurs saisies manuellement afin que vous puissiez recommencer le processus",
			"anoDedInv": "L’année de la déduction ne doit pas nécessairement être supérieure à l’année de la perte fiscale.",
			"servicoAT": {
				"validarAtSuccess": "Déclaration validée avec succès",
				"validarAtFailed": "Erreur lors de la validation de la déclaration dans AT",
				"submeterAtSuccess": "Déclaration soumise avec succès",
				"submeterAtFailed": "Erreur lors de la soumission de la déclaration sur AT"
			},
			"modalSubmeterAT": {
				"title": "Confirmez à nouveau l'envoi de la déclaration en ignorant les avertissements",
				"message": "La déclaration ne présente que des avertissements, comptez-vous renvoyer la déclaration en ignorant les avertissements ?"
			},
			"contasdef": {
				"reposicaocomsucesso": "Les définitions de compte pour les champs étaient des réponses",
				"contascopiadascomsucesso": "Les définitions de compte pour les champs ont été copiées avec succès dans les sociétés sélectionnées"
			}
		},
		"errors": {
			"campo346": "Matériel imposable négatif !"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;'",
			"exitMessage": "En cliquant sur OK, vous quitterez sans enregistrer et toutes les modifications que vous avez apportées seront supprimées."
		}
	},
	"modelo25": {
		"title": "Modèle 25",
		"config": {
			"modalTitle": "Configuration — Comptes pour les dons reçus",
			"titleTableDonativosNumerarios": "Dons en espèces — Comptes de revenus/de capitaux propres",
			"titleTableDonativosEspecie": "Dons en nature — Comptes de revenu/capitaux propres",
			"contaDe": "compte",
			"contaAte": "Comptez jusqu'à",
			"alerts": {
				"success": "Configuration enregistrée avec succès !",
				"error": "Un problème est survenu !",
				"hasEditData": "Remarque : Il reste encore des données à sauvegarder !"
			}
		},
		"groups": {
			"q0": {
				"title": "Introduction",
				"p1": "Image 00",
				"p2": "Dons reçus",
				"p3": "Dans le cadre des obligations accessoires des entités bénéficiant de dons, ce modèle sert à respecter les dispositions légales contenues au paragraphe c) du paragraphe 1 de l'art.",
				"p4": "Le respect de cette obligation fiscale doit être complété en remplissant et en envoyant ce formulaire par transmission électronique, avant la fin du mois de février de chaque année, faisant référence aux dons reçus l'année précédente."
			},
			"q1": {
				"title": "Numéro d'identification fiscale du déclarant",
				"campoNifDeclarante": "Numéro d'identification fiscale du déclarant",
				"campoNomeDeclarante": "Nom du contribuable"
			},
			"q2": {
				"title": "Des années de dons"
			},
			"q3": {
				"title": "Code du service financier du siège social ou du domicile",
				"codDeServico": "Code du service financier"
			},
			"q4": {
				"title": "Type de déclaration",
				"radioTipoDeclaracao": {
					"primeira": "D'abord",
					"sub": "Remplacement"
				}
			},
			"q5": {
				"title": "Liste des entités donatrices et des dons",
				"dataGridRegistoList": {
					"colunas": {
						"entidade": "Entité donatrice",
						"codigoDonativo": "Code de don",
						"valorNum": "Valeur du don en espèces",
						"valorEsp": "Valeur du don en nature",
						"donativoStr": "Type de don",
						"linhas": {
							"periodo": "Période",
							"nDiario": "Tous les jours",
							"nDocInterno": "Doc n°",
							"nConta": "Numéro de compte",
							"nomePOC": "Nom du compte",
							"valor": "Valeur"
						}
					}
				}
			}
		},
		"errors": {
			"errorHeader": "Le modèle actuel 25 présente les erreurs suivantes.",
			"errorCodigoDonMensagem": "Erreur dans le champ du code de don pour l'entité {{entidade}}",
			"errorCodigoDonRequired": "Le champ est obligatoire",
			"errorCodigoDonName": "Code de don",
			"errorNoDataList": "* Il est obligatoire de contenir des données pour créer le fichier.",
			"errorValoresMensagem": "Erreur dans l'un des champs de valeur de l'entité {{entidade}}",
			"errorValoresRequired": "Au moins un des champs est obligatoire",
			"errorDadosInseridos": "Il y a au moins une erreur dans les données insérées dans la table {{datagrid}}",
			"errorContasOverlapping": "Il y a un chevauchement entre les « comptes de » et les « comptes de destination » dans les grilles.",
			"errorOnlyDigitsAllowed": "Seuls les chiffres sont autorisés comme valeurs dans les champs « compte de » et « compte jusqu'à »."
		},
		"helpers": {
			"naoExistemContasConfiguradas": "Aucun compte n'est configuré pour le téléchargement de données.",
			"clickConfig": "<a>Cliquez ici pour configurer.</a>"
		},
		"servicoAT": {
			"validarAtSuccess": "Déclaration validée avec succès",
			"validarAtFailed": "Erreur lors de la validation de la déclaration dans AT",
			"submeterAtSuccess": "Déclaration soumise avec succès",
			"submeterAtFailed": "Erreur lors de la soumission de la déclaration sur AT"
		},
		"global": {
			"btns": {
				"btnRefresh": "Pour mettre à jour",
				"btnCriarFicheiro": "Créer un fichier",
				"btnSubmeterAt": "Soumettre à",
				"btnValidarAt": "Valider sur AT",
				"btnConfigWS": "Paramètres WS"
			}
		},
		"modal": {
			"configws": {
				"title": "Paramètres du service Web",
				"username": "Nom d'utilisateur du contributeur",
				"password": "Mot de passe du contribuable",
				"servicoInvCC": "Service invoqué par un comptable agréé (CC)",
				"ccPowers": "CC avec pleins pouvoirs déclaratifs pour Contrib.",
				"usernameCC": "Nom d'utilisateur CC",
				"passwordCC": "Mot de passe CC",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> Le service est déjà configuré.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> Le service n'est pas configuré.",
				"configsaved": "Configuration enregistrée avec succès."
			}
		}
	},
	"modelo30": {
		"title": "Modèle 30",
		"groups": {
			"q1": {
				"title": "Numéro d'identification fiscale",
				"c01": "Numéro d'identification fiscale de l'entité déclarante"
			},
			"q2": {
				"title": "Numéro d'identification fiscale de l'expert-comptable / Juste obstacle",
				"c02": "Numéro d'identification fiscale de l'expert-comptable",
				"casoJustoImpedimento": "S'il y avait un juste empêchement (article 124 du DL nº 452/99, du 5 novembre), indiquez :",
				"c02a": "Fait qui a déterminé le juste obstacle",
				"c02b": "Date de survenance du fait"
			},
			"q3": {
				"title": "Année/Mois"
			},
			"q4": {
				"title": "Code du service des finances du siège social ou du domicile fiscal",
				"c04": "Code du service des finances du siège social ou du domicile fiscal"
			},
			"q5": {
				"title": "Type de déclaration",
				"c05": "Données de déclaration / type de déclaration",
				"primeira": "D'abord",
				"sub": "Remplacement"
			},
			"q6": {
				"title": "Sommaire des sommes retenues",
				"c06": "Dividendes ou bénéfices provenant de participations",
				"c07": "Intérêts ou revenus provenant de l’investissement du capital",
				"c08": "Redevance",
				"c09": "Travail dépendant",
				"c10": "Travail indépendant",
				"c11": "Commissions",
				"c12": "Bâtiments",
				"c13": "Retraites",
				"c14": "Prestations de services",
				"c40": "Rémunérations publiques",
				"c41": "Pensions publiques"
			},
			"q7": {
				"title": "Liste des bulletins de versement",
				"dataGrid": {
					"nGuia": "Guide de paiement n°",
					"valorTotal": "Valeur totale du guide"
				}
			},
			"q8": {
				"title": "Liste des bénéficiaires de revenus",
				"dataGrid": {
					"nif": "NIF portugais",
					"nifPais": "Nif résidence de campagne",
					"codPais": "Code du pays de résidence",
					"partCapD": "Partie.",
					"partCapS": "Partie.",
					"rendTipo": "Rendement",
					"rendValor": "Rendement",
					"tribCod": "Tribu.",
					"tribTaxa": "Tribu.",
					"guia": "Numéro du guide de paiement",
					"montante": "montant du diablotin",
					"nifEntidade": "Nif ent.",
					"legends": {
						"existeLinhasParaMesmoContribuinte": "Il y a plusieurs lignes pour le même contribuable"
					}
				}
			}
		},
		"verificarLancamentos": {
			"title": "Vérifiez les versions possibles",
			"contribuintesNaoPTComRetencao": "Contribuables non-PT avec retenue",
			"contribuintesNaoPTSemRetencao": "Contribuables non-PT sans retenue",
			"mensagens": {
				"success": "Versions appliquées avec succès dans le tableau 8",
				"semDados": "Aucun enregistrement de lancements possibles n'a été trouvé"
			}
		},
		"errors": {
			"errorHeader": "Le modèle 30 actuel présente les erreurs suivantes.",
			"q08MontandeSemGuias": "Il y a des lignes à valeur retenue où le numéro guide n'a pas été renseigné!",
			"q08LinhasDuplicadas": "Il y a des lignes avec le même contributeur, lorsque le fichier sera généré elles seront regroupées.",
			"q08": "Tableau 8",
			"q08WithoutData": "Il n'est pas correctement rempli.",
			"validarFailed": "Erreur lors de la validation de la déclaration",
			"linhaSemNif": "La ligne sélectionnée ne contient pas de NIF à modifier",
			"pais": "Pays",
			"paisMessage": "Le code du pays doit contenir 3 chiffres.",
			"sf": "Service financier",
			"sfMessage": "Vous devez indiquer le code du service financier"
		},
		"servicoAT": {
			"validarAtSuccess": "Déclaration validée avec succès",
			"validarAtFailed": "Erreur lors de la validation de la déclaration dans AT",
			"submeterAtSuccess": "Déclaration soumise avec succès",
			"submeterAtFailed": "Erreur lors de la soumission de la déclaration sur AT"
		},
		"btns": {
			"verificarLancamentos": "Vérifiez les versions possibles",
			"btnRepor": "Charger les données par défaut",
			"btnRepFinancas": "Département des Finances"
		}
	},
	"modelo39": {
		"title": "Modèle 39",
		"initModal": {
			"message": "Souhaitez-vous générer le fichier Modèle 39 avec les données de traitement des salaires pour l'année '{{year}}' du type revenu du capital ?"
		},
		"groups": {
			"q0": {
				"title": "Commencer",
				"p2": "Indications générales",
				"p3": "Le modèle de déclaration n° 39 est obligatoire pour les entités débitrices et les entités qui paient ou mettent à la disposition de leurs titulaires respectifs des personnes physiques résidant sur le territoire portugais et qui ne bénéficient pas d'exonération, d'exonération de retenue à la source ou de réduction d'impôt, dont les revenus se réfèrent à l'article 71 du du Code des impôts sur les revenus des personnes physiques ou tout revenu soumis à retenue définitive à la source.",
				"p4": "L'identification des contribuables dont le revenu total, tous codes confondus, est égal ou inférieur à 25,00 € n'est pas requise."
			},
			"q1": {
				"title": "Numéro d'identification fiscale du déclarant",
				"campoNifDeclarante": "Numéro d'identification fiscale"
			},
			"q2": {
				"title": "Numéro d'identification fiscale CC / Juste obstacle",
				"q02C02": "Numéro d'identification fiscale",
				"q02Caso": "S'il y avait un juste empêchement (article 12-A du DL n° 452/99, du 5 novembre), indiquer:",
				"q02C06": "Fait qui a déterminé le juste obstacle",
				"q02C07": "Date de survenance du fait",
				"q02C08": "Date de cessation de l'événement"
			},
			"q4": {
				"title": "Code du service financier du siège social ou du domicile",
				"q04C04": "Code du service financier"
			},
			"q5": {
				"title": "Type de déclaration",
				"radioTipoDeclaracao": {
					"primeira": "D'abord",
					"sub": "Remplacement"
				}
			},
			"q6": {
				"title": "Liste des titulaires de revenus",
				"dataGridRegistoList": {
					"colunas": {
						"nif": "6.1 - NIF du Détenteur de Revenus",
						"codRend": "6.2 - Code des revenus",
						"rendimento": "6.3 - Montant des revenus",
						"retido": "6.4 - Montant de l'IRS retenu",
						"nifEmitente": "6.5 - NIF de l'Entité Émettrice"
					},
					"extras": {
						"ajuda": "Consulter l'aide du code des revenus",
						"ajudaTitle": "Aide sur le code de revenu",
						"cod": "Code"
					}
				}
			}
		},
		"codigosJustoImpedimento": {
			"01": "Décès d'un conjoint non séparé de personnes et de biens, d'une personne avec laquelle il vit dans des conditions similaires à celles de ses conjoints, ou d'un parent ou assimilé au 1er degré de la ligne droite",
			"02": "Décès d'un autre parent ou apparenté en ligne directe ou au 2ème degré de la ligne collatérale",
			"03": "Maladie grave et soudaine ou hospitalisation du comptable, qui le rend absolument impossible de remplir ses obligations, ou situations d'accouchement ou d'assistance urgente et essentielle à un conjoint ou à une personne vivant en union civile ou en économie commune et à un parent ou assimilé en le 1er degré de la ligne droite, en cas de maladie ou d'accident de ceux-ci",
			"04": "Situations parentales"
		},
		"codigosRendimentos": {
			"10": {
				"1001": "Revenus versés par les fonds d'épargne retraite ne bénéficiant d'aucune exclusion - alinéa 5 de l'article 21 du Statut des Avantages Fiscaux (1ère partie)."
			},
			"11": {
				"1101": "Revenus provenant de parts de participation dans des fonds de capital-risque, des fonds de placement immobilier ou des participations dans des sociétés de placement immobilier - ressources forestières - et dans des fonds de placement immobilier de réhabilitation urbaine - articles 23, 24 et 71 du Statut des avantages fiscaux."
			},
			"12": {
				"1201": "Régime Transitionnel (avant le 1er janvier 1991 et après cette date jusqu'au 31 décembre 1994) - Différence positive entre les sommes versées au titre du rachat, de l'avance ou de l'échéance des assurances et des opérations du secteur « Vie » et les régimes complémentaires bénéficiant de l'exonération fiscale de tous les revenus pour les contrats conclus avant le 1.1.91 et pour les contrats conclus entre le 1er janvier 1991 et le 31 décembre"
			},
			"13": {
				"1301": "Régime Transitionnel (1er janvier 1991 au 31 décembre 1994) - Différence positive entre les sommes versées au titre du rachat, de l'avance ou de l'échéance des assurances et des opérations du secteur « Vie » et les régimes complémentaires qui bénéficient de l'exclusion d'imposition de 1/2 - paragraphe a) du paragraphe 3 de l'article 5 du Code IRS (formulation du décret-loi n."
			},
			"14": {
				"1401": "Régime Transitionnel (1er janvier 1995 au 31 décembre 2000) - Différence positive entre les sommes versées au titre du rachat, de l'avance ou de l'échéance des assurances et opérations du secteur « Vie » et les régimes complémentaires qui bénéficient de l'exclusion du 2/5 - paragraphe a) du n° 3 de l'article 5 du Code IRS (tel que modifié par la loi n° 39-B/94, du"
			},
			"15": {
				"1501": "Régime Transitionnel (1er janvier 1995 au 31 décembre 2000) - Différence positive entre les sommes versées au titre du rachat, de l'avance ou de l'échéance des assurances et des opérations du secteur « Vie » et les régimes complémentaires qui bénéficient de l'exclusion du 4/5 - alinéa b) du n° 3 de l'article 5 du Code IRS (tel que modifié par la loi n° 39-B/1994, du"
			},
			"16": {
				"1601": "Régime Transitionnel (Plans conclus jusqu'au 31.12.2005) - Les sommes versées par les fonds d'épargne retraite, PPE et PPR/E qui bénéficient de l'exclusion des 4/5 - article 21, n° 3, alinéa b) n° 1 de l'Impôt. Statut des prestations, conformément aux dispositions de l'article 55, n° 3 de la loi n."
			},
			"17": {
				"1701": "Revenus mentionnés aux codes 01, 03, 19 à 31 et 33 lorsqu'ils sont versés ou mis à disposition sur des comptes ouverts au nom d'un ou plusieurs titulaires mais pour le compte de tiers non identifiés (sauf lorsque le bénéficiaire effectif est identifié) - paragraphe a) du paragraphe 12 de l’article 71 du Code IRS."
			},
			"18": {
				"1801": "Revenus payés ou mis à la disposition des titulaires respectifs, résidents sur le territoire portugais, dus par des entités non résidentes sans établissement stable sur le territoire portugais et qui sont domiciliées dans un pays, territoire ou région soumis à un régime fiscal nettement plus favorable, par l'intermédiaire d'entités qui sont mandatés par les débiteurs ou les titulaires ou agissent pour le compte de l'un ou l'autre - alinéa c) du n° 12 de l'article 71 du Code IRS (ancien n° 13 du même article)."
			},
			"19": {
				"1901": "Intérêts et autres formes de rémunération découlant de contrats de prêt, d'ouverture de crédit, de pensions et autres qui prévoient, contre rémunération, la disponibilité temporaire d'argent ou d'autres choses fongibles - alinéa a) du paragraphe 2 de l'article 5 du Code IRS - applicable à 2015 et les années suivantes."
			},
			"20": {
				"2001": "Revenus provenant de contrats de mission temporaire, lorsqu'ils ne sont pas gagnés par le titulaire initial, de droits de propriété intellectuelle et industrielle, ou de fourniture d'informations basées sur l'expérience acquise dans le secteur industriel, commercial ou scientifique, ainsi que celles dérivées de l'assistance technique - paragraphe m ) du n°2, article 5 du Code IRS – applicable à 2015 et années suivantes."
			},
			"21": {
				"2101": "Les revenus provenant de l'usage ou de la concession d'usage de matériels agricoles industriels, commerciaux ou scientifiques, lorsqu'ils ne constituent pas des revenus fonciers, ainsi que les revenus provenant de la mise à disposition, sporadique ou continue, de matériels et de réseaux informatiques, y compris la transmission de données. ou mise à disposition de capacité informatique installée sous l'une de ses formes possibles - paragraphe n) du paragraphe 2, article 5 du Code IRS - applicable à 2015 et aux années suivantes."
			},
			"22": {
				"2201": "Soldes d'intérêts calculés dans un contrat ou inscrits sur un compte courant - alinéas f) et o), paragraphe 2 de l'article 5 du Code IRS - applicables à 2015 et aux années suivantes."
			},
			"23": {
				"2301": "Intérêts, dus au retard ou au retard de paiement, à l'exception de ceux dus à l'État et aux autres entités publiques - paragraphe g) du paragraphe 2 de l'article 5 du Code IRS - applicables à 2015 et aux années suivantes."
			},
			"24": {
				"2401": "Gains résultant des opérations de swap de taux d'intérêt - paragraphe q) du paragraphe 2 de l'article 5 du Code IRS - applicable à 2015 et aux années suivantes."
			},
			"25": {
				"2501": "Rémunération des certificats garantissant au titulaire le droit de recevoir une valeur minimale supérieure à la valeur de souscription - paragraphe r) du paragraphe 2 de l'article 5 du Code IRS - applicable à 2015 et aux années suivantes."
			},
			"26": {
				"2601": "Indemnisation visant à compenser les pertes de revenus de catégorie E - paragraphe s) du paragraphe 2 de l'article 5 du Code IRS - applicable à 2015 et aux années suivantes."
			},
			"27": {
				"2701": "Les sommes versées ou mises à la disposition du contribuable par les structures fiduciaires, lorsque ces sommes ne sont pas liées à leur liquidation, révocation ou extinction, et n'ont pas déjà été imposées - paragraphe t) du paragraphe 2 de l'article 5 du Code de l'IRS - applicable à 2015 et années suivantes."
			},
			"28": {
				"2801": "Les revenus distribués provenant de parts de participation dans des fonds communs de placement en valeurs mobilières ou de participations dans des sociétés de placement en valeurs mobilières auxquels est applicable le régime prévu à l'alinéa i) du paragraphe a) du n° 1 de l'article 22-A du Code des avantages fiscaux - applicable à compter du 1. Juillet 2015."
			},
			"29": {
				"2901": "Les revenus distribués provenant de parts de participation dans des sicaf immobilières ou de participations dans des sicaf immobilières auxquels s'applique le régime prévu au i) du paragraphe a) du paragraphe 1 et au paragraphe 13 de l'article º-A du Statut des avantages fiscaux. -- applicable à partir du 1er juillet 2015."
			},
			"30": {
				"3001": "Rachat et liquidation de parts de participation dans des fonds communs de placement en valeurs mobilières ou de parts de sociétés de placement en valeurs mobilières, auxquelles s'applique le régime prévu au paragraphe b) du paragraphe 1 de l'article 22-A du Code des avantages fiscaux - applicable à compter du 1er juillet 2015."
			},
			"31": {
				"3101": "Rachat et liquidation de parts de participation dans des sicaf immobilières ou de parts de sicaf immobilière, auxquelles s'applique le régime prévu au b) du paragraphe 1 de l'article 22-A du Code des avantages fiscaux - applicable à compter du 1er juillet 2015 ."
			},
			"32": {
				"3201": "Revenus des friches - paragraphe 4 de l'article 59 du Statut des avantages fiscaux."
			},
			"33": {
				"3301": "Autres revenus provenant du simple investissement de capital non inclus dans les paragraphes précédents - paragraphe p) du paragraphe 2 de l'article 5 du Code IRS - applicables à 2015 et aux années suivantes."
			},
			"34": {
				"3401": "Bénéfices bruts mis à la disposition des contribuables qui détiennent une participation dans des sociétés remplissant la condition prévue à l'article 35 du Code des sociétés commerciales, au profit desquelles ils ont effectué des apports en capital en numéraire (article 43-B du Statut des avantages fiscaux)."
			},
			"35": {
				"3501": "Revenus distribués dans le cadre de la gestion des ressources forestières par les entités de gestion forestière (EGF) et les unités de gestion forestière (UGF) - paragraphes 2 et 14 de l'article 59.º-G du Statut des avantages fiscaux."
			},
			"desc": "Voir l'aide pour plus de détails",
			"01": {
				"0101": "Bénéfices et réserves mis à la disposition des membres ou titulaires et avances au titre des bénéfices dus par les entités résidentes (y compris les dividendes) - paragraphe h) du paragraphe 2 de l'article 5 du Code IRS, à l'exception des bénéfices identifiés par le code 34.",
				"0102": "Revenus résultant du partage qualifiés d'investissement de capital (applicable aux années 2013 et précédentes).",
				"0103": "La valeur attribuée aux associés lors de l'amortissement des actions sans réduction de capital - paragraphe i) du paragraphe 2 de l'article 5 du Code IRS.",
				"0104": "Les revenus gagnés par le membre dans l'association de participation et dans l'association de quotas, ainsi que, dans cette dernière, les revenus visés aux alinéas h) et i) du n° 1 de l'article 5 du Code IRS gagnés par le membre. après déduction de la prestation due au participant."
			},
			"02": {
				"0201": "Revenus de titres payés ou mis à disposition de leurs titulaires respectifs, résidents sur le territoire portugais, dus par des entités non domiciliées sur le territoire portugais auxquelles le paiement peut être attribué, par l'intermédiaire d'entités mandatées par les débiteurs ou les titulaires ou agissant pour le compte d'un ou un autre - paragraphe b) du paragraphe 1 de l'article 71 du Code IRS."
			},
			"03": {
				"0301": "Intérêts et autres formes de rémunération sur les dépôts courants ou à terme, ainsi que les certificats de dépôt et les comptes de titres avec garantie de prix ou autres opérations similaires ou similaires - paragraphe b) du paragraphe 2 de l'article 5 du Code IRS.",
				"0302": "Intérêts, primes d'amortissement ou de remboursement et autres rémunérations sur titres de créance, obligations, titres de participation, bons de consignation, obligations de trésorerie ou autres titres similaires et autres instruments d'investissement financier - paragraphe c) du paragraphe 2 de l'article 5 du Code de l'IRS.",
				"0303": "Intérêts et autres formes de rémunération sur les fournitures, allocations ou avances en capital faites par les associés à la société - paragraphe d) du paragraphe 2 de l'article 5 du Code IRS.",
				"0304": "Intérêts et autres formes de rémunération dus au fait que les associés ne retirent pas les bénéfices ou les rémunérations mis à leur disposition - paragraphe e) du paragraphe 2 de l'article 5 du Code IRS.",
				"0305": "Produits des opérations de pension et de cessions de crédits - applicables aux années 2014 et précédentes.",
				"0306": "Gains résultant d'opérations de swap ou d'opérations de change à terme - applicables aux années 2014 et précédentes."
			},
			"04": {
				"0401": "Différence positive entre les sommes versées à titre de rachat, d'avance ou d'échéance d'assurance et d'opérations du secteur « Vie » et d'autres régimes complémentaires ne bénéficiant pas d'exclusion - paragraphe 3 de l'article 5 du Code IRS.",
				"0402": "Revenus d'épargne à long terme ou de plans d'épargne en actions ne bénéficiant pas d'exclusion - paragraphe 3 de l'article 5 du Code IRS (par référence aux articles 20-A et 26 du Tax Benefits Statute) et article 25 du Tax Benefits Statute ."
			},
			"05": {
				"0501": "Différence positive entre les sommes versées au titre du rachat, de l'avance ou de l'échéance des assurances et opérations du secteur « Vie » et des autres régimes complémentaires bénéficiant de l'exclusion de l'imposition 1/5 - alinéa a) du paragraphe 3 de l'article 5. º du Code IRS.",
				"0502": "Revenus d'épargne à long terme ou de plans d'épargne en actions bénéficiant d'une exonération d'impôt de 1/5 - alinéa a) du paragraphe 3 de l'article 5 du Code IRS (par référence aux articles 20-A et 26 du Statut des Avantages Fiscaux) et l'article 25 de la loi sur les avantages fiscaux."
			},
			"06": {
				"0601": "Différence positive entre les sommes versées au titre du rachat, de l'avance ou de l'échéance des assurances et des opérations du secteur « Vie » et des régimes complémentaires bénéficiant de l'exclusion de la fiscalité 3/5 - alinéa b) du paragraphe 3 de l'article 5. du Code IRS .",
				"0602": "Revenus d'épargne à long terme ou de plans d'épargne en actions bénéficiant de l'exclusion fiscale des 3/5 - alinéa b) du paragraphe 3 de l'article 5 du Code IRS (par référence aux articles 20-A et 26 du Statut des Avantages Fiscaux) et l'article 25 de la loi sur les avantages fiscaux."
			},
			"07": {
				"0701": "Les revenus versés par les fonds d'épargne retraite, y compris ceux versés à titre échelonné, sur une période supérieure à dix ans, qui bénéficient d'une exclusion de 3/5 - alinéa b) du paragraphe 3 de l'article 21 du Statut des avantages fiscaux."
			},
			"08": {
				"0801": "Revenus versés par les fonds d'épargne-retraite qui bénéficient de l'exclusion de l'impôt sur le revenu de 1/5 - n° 5 de l'article 21 du Statut des avantages fiscaux et alinéa a) du n° 3 de l'article 5 du Code IRS."
			},
			"09": {
				"0901": "Revenus versés par les fonds d'épargne-retraite qui bénéficient de l'exclusion de l'impôt sur le revenu aux 3/5 - n° 5 de l'article 21 du Tax Benefits Statut et alinéa b) du n° 3 de l'article 5 du Code IRS."
			}
		},
		"errors": {
			"errorNoDataList": "* Il est obligatoire de contenir des données pour créer le fichier.",
			"errorHeader": "Le modèle 39 actuel présente les erreurs suivantes."
		},
		"servicoAT": {
			"validarAtSuccess": "Déclaration validée avec succès",
			"validarAtFailed": "Erreur lors de la validation de la déclaration dans AT",
			"submeterAtSuccess": "Déclaration soumise avec succès",
			"submeterAtFailed": "Erreur lors de la soumission de la déclaration sur AT"
		}
	},
	"moeda": {
		"title_new": "Nouvelle devise",
		"title_detail": "Devise {{id}}",
		"title_edit": "Devise {{id}}",
		"title_plural": "Pièces",
		"pesquisa": "A chercher",
		"saved": "Devise {{id}}, enregistrée avec succès.",
		"error": "La devise {{id}} ne peut pas être enregistrée.",
		"errorOnNew": "La nouvelle devise ne peut pas être enregistrée.",
		"deleted": "Devise {{id}}, supprimée avec succès.",
		"fields": {
			"codMoeda": "Code devise",
			"nomeMoeda": "Nom de la devise",
			"abreviaturaMoeda": "Abréviation de la devise",
			"extenso1Unidade": "Unité complète (singulier)",
			"extensoNUnidades": "Unité complète (pluriel)",
			"extensoCentimos": "Cents au complet",
			"nDecimais": "Nombre de décimales",
			"dataReferencia": "Date de référence",
			"exchange": "Échange"
		},
		"tabs": {
			"activeExchange": "Change de devises actif",
			"otherExchange": "Autres bureaux de change"
		},
		"titles": {
			"cambioMoedaEmpresaEmMoedaEstrangeira": "Échanger la devise de l'entreprise contre une devise étrangère",
			"cambioMoedaEstrangeiraEmMoedaEmpresa": "Change de devises contre la devise de l'entreprise"
		},
		"errors": {
			"moedaNaoExiste": "La monnaie n'existe pas.",
			"naoPodeAlterarMoedaSemRemoverLinhas": "Vous ne pouvez pas modifier la devise sans supprimer au préalable toutes les lignes du document commercial."
		},
		"chooseTemplate": "Choisir un modèle",
		"invalidTemplate": "Le pays choisi ne contient pas de modèle monétaire valide.",
		"editCambio": "Modifier le taux de change {{date}}",
		"choosecounty": "Copier le modèle",
		"updateCambio": "Mettre à jour le taux de change"
	},
	"moedaNormasIso": {
		"title": "Normes monétaires ISO",
		"pesquisa": "Rechercher des normes monétaires ISO",
		"fields": {
			"codigo": "Code",
			"paisNomeMoeda": "Pays (devise)",
			"nomeMoeda": "Nom de la devise",
			"extensoUnidade": "Unité complète (singulier)",
			"extensoNUnidades": "Unité complète (pluriel)",
			"extensoSubUnidade": "Unité complète (sous)"
		}
	},
	"moradasFaturacao": {
		"title_detail": "Adresse de facturation {{id}}",
		"title_new": "Nouvelle adresse de facturation",
		"title_plural": "Adresses de facturation",
		"pesquisa": "chercher",
		"saved": "Adresse de facturation {{id}}, enregistrée avec succès",
		"error": "L'adresse de facturation {{id}} ne peut pas être enregistrée.",
		"fields": {
			"nConta": "Non.",
			"nome": "Nom",
			"rua": "Route",
			"codPostal": "Code Postal",
			"localidade": "Emplacement",
			"nipc": "CNIPC",
			"id": "Indice",
			"observacoes": "Note",
			"morada": "Ménage",
			"codPais": "Code du pays",
			"moralId": "identité morale.",
			"nIdAltern": "ID alternatif"
		}
	},
	"morals": {
		"title_detail": "Adresse alternative",
		"title_new": "Nouvelle adresse alternative",
		"title_plural": "Adresses alternatives",
		"pesquisa": "A chercher",
		"saved": "Adresse alternative, enregistrée avec succès.",
		"error": "Adresse alternative, ne peut pas être enregistrée.",
		"deleted": "Adresse alternative, supprimée avec succès.",
		"deleteModalText": "Supprimer l'enregistrement \"{{name}}\" ?",
		"fields": {
			"nConta": "Numéro de compte",
			"nome": "Nom",
			"rua": "Route",
			"cPost": "Code Postal",
			"localidade": "Emplacement",
			"observacoes": "Observations",
			"morada": "Ménage",
			"codPais": "Code du pays",
			"desativado": "Désactivé",
			"contactoNome": "Nom du contact",
			"contactoTelefone": "Téléphone de contact",
			"codigoPontoPickup": "Code du point de retrait"
		}
	},
	"motivoContrato": {
		"data": {
			"semmotivo": "Aucune raison",
			"temporariamenteimpedidoprestarserviço": "Temporairement empêché de fournir le service",
			"impendaaccaojuizoapreciacaolicitudedespedimento": "Pour quelle action doit être intentée en justice pour apprécier la légalité du licenciement",
			"situacaolicencasemretribuicao": "Situation de congé sans solde",
			"substituicaotrabalhadorportempoindeterminado": "Remplacement d'un travailleur à temps plein qui commence à travailler à temps partiel pour une durée indéterminée",
			"cctividadessazonais": "Activités saisonnières",
			"acrescimoexcepcionalactividadeempresa": "Hausse exceptionnelle de l'activité de l'entreprise",
			"execucaotarefaserviçodefinidonaoduradouro": "Exécution d'une tâche occasionnelle ou d'une prestation spécifique, précisément définie et de courte durée",
			"execucaoprojectoactividadedefinidatemporaria": "Exécution d'un ouvrage, d'un projet ou d'une autre activité définie et temporaire",
			"lancamentonovaactividadeduraçãoincerta": "Lancement d'une nouvelle activité à durée incertaine, ainsi que la mise en exploitation d'une entreprise ou d'un établissement",
			"contratacaotrabalhadoresprimeiroemprego": "Embaucher des travailleurs à la recherche de leur premier emploi ou des chômeurs de longue durée",
			"actividadecontinuidadeousemtermo": "Continuité ou activité sans fin",
			"outros": "Autres"
		}
	},
	"movimentosaberto": {
		"btn": {
			"sendMail": "Envoyer un e-mail",
			"processarexcel": "Traiter et exporter Excel"
		},
		"modal": {
			"sendMail": {
				"title": "Envoyer un e-mail : {{nAccount}} - {{nameAccount}}",
				"fields": {
					"email": "E-mail",
					"subject": "Sujet",
					"body": "Texte"
				}
			}
		},
		"label": {
			"grupoConta": "Groupe de comptes",
			"showGrupoConta": "Afficher le groupe de comptes",
			"movimentosEmAberto": "Mouvements ouverts {{type}}"
		}
	},
	"movimentospendentes": {
		"clientes": {
			"nConta": "Compte",
			"nome": "Nom",
			"tipoDocumento": "Type de document",
			"numeroDocumento": "Numéro du document",
			"dataDoc": "Date du document.",
			"dataVencimento": "Date d'expiration",
			"valor": "Valeur du document",
			"valorRecebido": "Montant déjà reçu",
			"valorPorReceber": "Valeur à recevoir",
			"nif": "FNI",
			"nDocExterno": "Doc n°"
		},
		"fornecedores": {
			"nConta": "Compte",
			"nome": "Nom",
			"tipoDocumento": "Type de document",
			"numeroDocumento": "Numéro du document",
			"dataDoc": "Date du document.",
			"dataVencimento": "Date d'expiration",
			"valor": "Valeur du document",
			"valorRecebido": "Montant déjà payé",
			"valorPorReceber": "Montant à payer",
			"nif": "FNI",
			"nDocExterno": "Doc n°"
		},
		"outrosDevedoresCredores": {
			"nConta": "Compte",
			"nome": "Nom",
			"tipoDocumento": "Type de document",
			"numeroDocumento": "Numéro du document",
			"dataDoc": "Date du document.",
			"dataVencimento": "Date d'expiration",
			"valor": "Valeur du document",
			"valorRecebido": "Montant déjà payé",
			"valorPorReceber": "Montant à payer",
			"nif": "FNI",
			"nDocExterno": "Doc n°"
		}
	},
	"nacionalidades": {
		"title_detail": "Nationalité {{id}}",
		"title_new": "Nouvelle nationalité",
		"title_plural": "Nationalités",
		"pesquisa": "A chercher",
		"saved": "Nationalité {{id}}, enregistrée avec succès.",
		"error": "Nationalité {{id}}, ne peut pas être enregistrée.",
		"deleted": "Nationalité {{id}}, supprimée avec succès.",
		"fields": {
			"nNacionalidade": "Code.",
			"codNacionQPessoal": "Q. Code de nationalité personnelle",
			"nomeNacionQPessoal": "Nom Nationalité Q. Personnel",
			"designacaoBreve": "Brève désignation",
			"designacaoCompleta": "Désignation complète"
		}
	},
	"naturezasContab": {
		"title_detail": "Nature comptable {{id}}",
		"title_new": "Nouvelle nature comptable",
		"title_plural": "Caractères comptables",
		"pesquisa": "A chercher",
		"saved": "Nature comptable {{id}}, enregistrée avec succès.",
		"error": "Nature comptable {{id}}, ne peut pas être sauvegardée.",
		"deleted": "Nature comptable {{id}}, supprimée avec succès.",
		"fields": {
			"idGrContaCorrente": "Code de nature comptable.",
			"idGrContaCorrentePlaceholder": "code nature comptable",
			"idSubGrContaCorrente": "Code de sous-nature comptable.",
			"idSubGrContaCorrentePlaceholder": "code de sous-nature comptable",
			"descricao": "Description",
			"descricaoPlaceholder": "description de la nature comptable",
			"radical": "Radical",
			"radicalPlaceholder": "radical de nature comptable ex.: 21111",
			"nDigitosSequencia": "Nombre de chiffres dans la séquence",
			"nDigitosSequenciaPlaceholder": "Séquence de nombres de chiffres, par exemple : si radical = 21111 et comporte une séquence de 4 chiffres, le nouveau compte est 211110001.",
			"tipo": "Taper"
		}
	},
	"nifs": {
		"title_detail": "NIF {{id}}",
		"title_new": "Nouveau FNI",
		"title_plural": "Numéros d'identification fiscale",
		"pesquisa": "A chercher",
		"saved": "NIF {{id}}, enregistré avec succès",
		"error": "NIF {{id}}, ne peut pas être enregistré.",
		"deleted": "NIF {{id}}, supprimé avec succès",
		"fields": {
			"codPais": "Pays",
			"nContribuinte": "Numéro de contribuable",
			"codRet": "Taux de rétention",
			"tipoRetServNIF": "Entité généralement",
			"temCativo": "A une TVA captive",
			"taxaCativo": "Taux de TVA captif"
		},
		"modelo30": {
			"title": "Modèle 30",
			"fields": {
				"nifPt": "NIF portugais",
				"nifEmitente": "NIF ent.",
				"partCapD": "Partie.",
				"partCapS": "Partie."
			}
		},
		"registar": "S'inscrire au FNI",
		"registado": "NIF {{nif}} a été enregistré avec succès",
		"naoregistado": "Le NIF n'est pas enregistré",
		"desejaregistar": "Voulez-vous enregistrer le NIF?"
	},
	"nivql": {
		"title_detail": "Niveau de qualification {{id}}",
		"title_new": "Nouveau niveau de qualification",
		"title_plural": "Niveaux de qualification",
		"pesquisa": "A chercher",
		"saved": "Niveau de qualification {{id}}, enregistré avec succès",
		"error": "Le niveau de qualification {{id}} ne peut pas être enregistré.",
		"deleted": "Niveau de qualification {{id}}, supprimé avec succès",
		"fields": {
			"nNivQualific": "Code du niveau de qualification",
			"designaBreve": "Brève désignation",
			"designaComp": "Désignation complète",
			"ruNivQualific": "Niveau de qualification"
		},
		"niveisqualificacao": {
			"0": "Pas de définition",
			"1": "Cadres supérieurs",
			"2": "Montures moyennes",
			"3": "Superviseurs, contremaîtres, maîtres et chefs d'équipe",
			"4": "Des professionnels hautement qualifiés",
			"5": "Des professionnels qualifiés",
			"6": "Professionnels semi-qualifiés (spécialisés)",
			"7": "Professionnels non qualifiés (indifférenciés)",
			"8": "Stagiaires, praticiens et apprentis"
		}
	},
	"noauthority": {
		"title": "Sans autorisation!",
		"message": "Contactez votre administrateur système pour plus d'informations."
	},
	"notificationcenter": {
		"title": "Notifications",
		"openAll": "Voir tout",
		"new": "Nouvelles",
		"old": "Précédent",
		"image": "Image",
		"newNotification": "Nouveau ({{count}} notification)",
		"newNotifications": "Nouveau ({{count}} notifications)",
		"noNotifications": "Il n'y a aucune notification",
		"categories": {
			"0": "Tous",
			"1": "Nouvelles",
			"2": "Entretien programmé",
			"all": "Tous",
			"news": "Nouvelles",
			"maintenance": "Entretien programmé"
		},
		"actions": {
			"readAll": "Marquer tout comme lu"
		}
	},
	"obrigacoesAcessorias": {
		"title": "Obligations supplémentaires",
		"modal": {
			"configws": {
				"title": "Paramètres du service Web",
				"username": "Nom d'utilisateur du contributeur",
				"password": "Mot de passe du contribuable",
				"servicoInvCC": "Service invoqué par un expert-comptable (CC)",
				"ccPowers": "CC avec pleins pouvoirs déclaratifs pour Contrib.",
				"usernameCC": "Nom d'utilisateur CC",
				"passwordCC": "Mot de passe CC",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> Le service est déjà configuré.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> Le service n'est pas configuré.",
				"configsaved": "Configuration enregistrée avec succès."
			}
		}
	},
	"officereporting": {
		"ano": "Année à analyser : {{value}}",
		"mes": "Mois à analyser : {{nameMonth}}",
		"fields": {
			"pagina": "Cartes"
		},
		"messages": {
			"erroDownload": "Une erreur s'est produite lors du téléchargement du fichier!"
		}
	},
	"origemCondPagamento": {
		"pesquisa": "Origines des conditions de paiement",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"tabela": "Tableau",
			"cliente": "Client"
		}
	},
	"origemContasRetencao": {
		"data": {
			"ambos": "Les deux",
			"porTerceiros": "Par des tiers (Clients)",
			"aTerceiros": "À des tiers (Fournisseurs)"
		}
	},
	"origemDataVencimento": {
		"pesquisa": "Origines des dates d'échéance",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"tabela": "Tableau",
			"condicaoPagamento": "Cond."
		}
	},
	"pagamentoimprimir": {
		"label": {
			"emailfornecedor": "E-mail du fournisseur",
			"enviar": "Envoyer"
		},
		"email": {
			"required": "Pour envoyer, veuillez remplir le champ \"<em>E-mail du fournisseur</em>\"",
			"emailEnviado": "E-mail envoyé à {{email}}",
			"emailErro": "Erreur lors de l'envoi de l'e-mail à {{email}}."
		},
		"placeholder": {
			"email": "\"mail@exemple.com\" ou \"mail@exemple.com; mail2@exemple.com\""
		},
		"btn": {
			"downloadSEPA": "Télécharger le fichier SEPA"
		}
	},
	"pagamentos": {
		"title_new": "Nouveau paiement",
		"title_detail": "Nouveau paiement {{id}}",
		"title_plural": "Paiements",
		"pesquisa": "A chercher",
		"saved": "Paiement enregistré avec succès.",
		"error": "Le paiement ne peut pas être enregistré.",
		"fields": {
			"extPocCabID": "Identifiant.",
			"numero": "Numéro de paiement.",
			"nome": "Fournisseur",
			"nconta": "Compte",
			"stampUpdate": "Dernier.",
			"origem": "Origine du document",
			"abreviaturaMoeda": "Pièce de monnaie",
			"nDocExterno": "Doc n°"
		},
		"pagamento": {
			"nCaixaAux": "Mode de paiement",
			"nomeCaixaAux": "Le nom signifie paiement",
			"tipoCaixaAux": "Type de demi-paiement",
			"valorNCaixa": "Valeur",
			"totalCaixa": "Livré",
			"troco": "Changement",
			"fornecedor": "Fournisseur",
			"data": "Date de paiement",
			"cab": {
				"extPocCabID": "identifiant d'en-tête",
				"numero": "Nombre",
				"nConta": "Compte",
				"descricao": "Description",
				"nome": "Nom",
				"nif": "FNI",
				"valor": "Valeur",
				"valorPlaceHolder": "Montant à payer",
				"stampUpdate": "Changer de date",
				"totalDescontos": "Remises complètes",
				"totalPagar": "Total à payer",
				"temRetencao": "A une rétention",
				"totalRetencao": "Rétention complète",
				"abreviaturaMoeda": "Pièce de monnaie"
			},
			"linhas": {
				"summary": "Résumé",
				"nlanc": "identifiant de ligne",
				"tipoDocumento": "Type de document",
				"numeroDocumento": "Document",
				"nDocExterno": "Doc n°",
				"dataVencimento": "Date d'expiration",
				"valor": "Valeur",
				"valorPorPagar": "Montant à payer",
				"percDesconto": "Rabais %",
				"valorDesconto": "Valeur de remise",
				"valorAPagar": "Montant à payer",
				"valorIVA": "Valeur TVA",
				"montanteAReter": "Rétention",
				"montanteRetido": "Valeur de rétention",
				"abreviaturaMoeda": "Pièce de monnaie",
				"cambio": "Échange",
				"cambioOrigem": "Taux de change d'origine",
				"cambioPagam": "Paiement d'échange",
				"dataDoc": "Date du document.",
				"dataDocExterno": "Date du document.",
				"descricao": "Description",
				"valorDoc": "Valeur du document"
			},
			"disableRevoke": "Le paiement ne peut pas être annulé car il n'a pas été effectué dans CentralGest Cloud",
			"pdf": "PDF",
			"revoke": "Annuler",
			"summary": "Résumé",
			"totalPago": "Total payé",
			"downloadSepa": "Télécharger SEPA",
			"generateAndDownloadSepa": "Générer SEPA"
		},
		"info": {
			"anular": "Paiement annulé avec succès !",
			"anularpagamento": "Annuler le paiement",
			"temCertezaAnularpagamento": "Êtes-vous sûr de vouloir supprimer le paiement ?",
			"pagamentoPorMeioPagamSepa": "Paiement effectué via paiement SEPA.",
			"avisoAnularPagamSepa": "Veuillez noter que cela annulera un paiement effectué via le paiement SEPA et que continuer supprimera le fichier de virement SEPA qui peut contenir d'autres paiements.",
			"semMovimentos": "Pas de mouvements ouverts !",
			"vencido": "En retard",
			"naoVencido": "Non expiré",
			"aPagar": "Pour éteindre"
		},
		"erros": {
			"notselected": "Aucun paiement sélectionné !",
			"anular": "Erreur lors de l'annulation du paiement !",
			"valorDescontoMaiorValor": "Valeur de la remise supérieure au montant reçu",
			"percentagemDescontoMaior100": "Le pourcentage de remise ne peut pas dépasser 100 %",
			"moedasdiferentesnosrecibos": "Il n'est pas possible d'avoir des devises différentes dans les paiements à comptabiliser",
			"errodesconhecido": "Erreur inconnue",
			"invalidHeaderDate": "La date de paiement ne peut pas être supérieure à 1 an à compter de la date du jour",
			"valorUltrapassado": "Le montant à distribuer a été dépassé",
			"faltaDistribuirValor": "Vous devez distribuer la totalité du montant inscrit"
		},
		"text": {
			"autoDistribute": "Distribuer automatiquement la valeur",
			"amountToPay": "Montant à payer"
		},
		"list": {
			"gestaoFicheirosSepa": "Gestion des fichiers SEPA",
			"notifyMessage": "Souhaitez-vous informer les fournisseurs des paiements sélectionnés ?",
			"notifyTitle": "Envoyer des notifications"
		},
		"saveModal": {
			"title": "Enregistrer le paiement",
			"paymentMean": "Mode de paiement",
			"paymentValue": "Valeur du mode de paiement",
			"payInFull": "Payer intégralement",
			"clearValue": "Effacer la valeur",
			"document": "Document",
			"save": "Sauvegarder",
			"saveCheck": "Enregistrer et afficher le PDF",
			"saveGenerateSEPA": "Enregistrer et créer SEPA",
			"saveGestaoSEPA": "Économisez dans la gestion SEPA",
			"erros": {
				"naoDefiniuMeioPagamento": "Vous n'avez pas défini le mode de paiement.",
				"naoSatisfazValorDoc": "La valeur livrée ne satisfait pas la valeur du document.",
				"trfSepaSoPodeTerUmMeio": "Le paiement par « Virement Bancaire » via fichier SEPA ne peut avoir qu'un seul mode de paiement défini.",
				"trfSepaMeioPagamentoNaoValido": "Le mode de paiement défini n'est pas du type « Virement Bancaire ».",
				"trfSepaMeioPagamentoSemIban": "Le mode de paiement n'a pas d'IBAN défini.",
				"trfSepaMeioPagamentoSemSwift": "Le mode de paiement n'a pas de définition SWIFT."
			},
			"success": {
				"saveGestaoSepa": "Enregistré dans la gestion des fichiers SEPA, vous pourrez le générer ultérieurement."
			}
		},
		"btn": {
			"pagaTudo": "Payez tout",
			"limpaTudo": "Effacer tous les montants payés",
			"distribuirValores": "Distribuer de la valeur",
			"limpaValor": "Montant clair payé"
		},
		"modal": {
			"pagamentoimprimir": {
				"label": {
					"emailfornecedor": "E-mail du fournisseur",
					"enviar": "Pour envoyer"
				},
				"email": {
					"required": "Pour envoyer, veuillez remplir le champ \"<em>E-mail du fournisseur</em>\"",
					"emailEnviado": "E-mail envoyé à {{email}}",
					"emailErro": "Erreur lors de l'envoi de l'e-mail à {{email}}."
				},
				"placeholder": {
					"email": "\"mail@exemple.com\" ou \"mail@exemple.com; mail2@exemple.com\""
				},
				"btn": {
					"downloadSEPA": "Télécharger le fichier SEPA"
				}
			}
		},
		"modalDistribuir": {
			"tile": "Distribue de la valeur",
			"automatico": "Automatique",
			"manual": "Manuel",
			"tooltip": {
				"automatico": "Triés du plus ancien au plus récent.",
				"manual": "Permet de distribuer jusqu'à ce que la valeur indiquée soit atteinte."
			}
		}
	},
	"paises": {
		"title_detail": "Pays {{id}}",
		"title_new": "Nouveau pays",
		"title_plural": "Pays",
		"pesquisa": "A chercher",
		"saved": "Pays {{id}}, enregistré avec succès",
		"error": "Pays {{id}}, ne peut pas être enregistré.",
		"deleted": "Pays {{id}}, supprimé avec succès",
		"fields": {
			"codPais": "Code du pays",
			"nomePais": "Nom",
			"abrevFiscal": "Abréviation fiscale",
			"abrevPais": "Abréviation du pays",
			"nomePaisPropriaLingua": "Nom du pays dans sa propre langue",
			"codMoeda": "Code devise",
			"codLingua": "Code de langue",
			"mercado": "Marché",
			"codISO": "Code international"
		},
		"mercado": {
			"data": {
				"intraComuni": "Intracommunautaire",
				"extraComuni": "Communauté supplémentaire"
			}
		}
	},
	"paisesISO3166": {
		"title": "Pays ISO 3166",
		"pesquisa": "Rechercher des pays ISO 3166",
		"fields": {
			"codigo": "Code.",
			"designacaoPT": "Désignation (PT)",
			"codigoAlpha2": "ISO3166 Alpha-2",
			"codigoAlpha3": "ISO3166 Alpha-3",
			"designacao": "Langue du prénom"
		}
	},
	"partners": {
		"millennium": {
			"title": "Millénaire",
			"erros": {
				"invalidTargetOrigin": "Une erreur s'est produite lors du chargement des paramètres.",
				"invalidUsernameOrPassword": "Veuillez remplir les champs d'authentification.",
				"invalidRecaptcha": "Veuillez cocher la case pour confirmer qu'il ne s'agit pas d'une machine.",
				"partnerAssociateInvalidToken": "Une erreur s'est produite lors de la tentative d'association de votre entreprise.",
				"partnerAssociateUserNotFound": "L'authentification a échoué.",
				"noCompaniesWereFoundWithTheProvi": "Aucune entreprise n’a été trouvée avec le NIF fourni.",
				"partnerIsActivePleaseSetUnactive": "Il existe déjà une association pour cette entreprise."
			}
		}
	},
	"pca": {
		"contabilidade": {
			"anos": "Années",
			"ficheiros": "Fichiers",
			"periodo": "Période",
			"encerrado": "fermé",
			"semFicheiros": "Aucun fichier"
		},
		"empresagabinete": {
			"extratosconta": {
				"fields": {
					"nDiario": "Code.",
					"nomeDiario": "Tous les jours",
					"nConta": "Code client",
					"nome": "Nom du client",
					"periodo": "Période",
					"nDocumento": "Doc n°",
					"nDescritivo": "Code descriptif",
					"nomeDescritivo": "Nom descriptif",
					"nDocExterno": "Doc n°",
					"dataLancamento": "Date de sortie",
					"valorDebito": "Dette",
					"valorCredito": "Crédit",
					"valor": "Valeur",
					"dataDoc": "Date du document."
				}
			},
			"movimentosaberto": {
				"fields": {
					"tipoDocumento": "Type de document.",
					"numeroDocumento": "Doc n°",
					"dataVencimento": "Date d'expiration",
					"valorPorReceber": "Montant à recevoir",
					"valorRecebido": "Montant reçu",
					"valorDesconto": "Valeur de remise",
					"valorIVA": "Valeur TVA",
					"valor": "Valeur",
					"nConta": "Code client",
					"nome": "Nom du client",
					"nif": "Cotisation n°"
				}
			}
		}
	},
	"perfilCategoriaAbono": {
		"title": "Allocations des catégories de profils",
		"fields": {
			"tipoArredondamento": "Arrondi",
			"tipoRecolha": "Type de collecte",
			"contribuiCalculoCustoPrecoH": "Contribue au calcul du prix/coût horaire",
			"codRem": "code rem.",
			"codUnidade": "Unité défectueuse",
			"tipoRendimento": "Type de revenu IRS",
			"contaMovDeb": "Allocation - Compte débiteur",
			"contaMovCred": "Allocation - Compte de crédit",
			"contaEncDEB": "Charges employeur/entreprise - Compte débiteur",
			"contaEncCRE": "Charges employeur/entreprise - Compte de crédit"
		},
		"crabdTable": {
			"fields": {
				"categoria": "Catégorie",
				"empregado": "Ent.",
				"patronato": "Ent.",
				"desconto": "Rabais",
				"nomeCategoria": "Catégorie",
				"temIncidenciaEmpregado": "Il a",
				"incidenciaEmpregado": "Incidence",
				"temIncidenciaPatronato": "Il a",
				"incidenciaPatronato": "Incidence",
				"nDesconto": "Code.",
				"nomeDesconto": "Description"
			}
		}
	},
	"perfilCategoriaDesconto": {
		"title": "Remises sur les catégories de profils",
		"fields": {
			"categoria": "Catégorie",
			"tipoArredondamento": "Arrondi",
			"tipoRecolha": "Type de collecte",
			"contribuiCalculoCustoPrecoH": "Contribue au calcul du prix/coût horaire",
			"codRem": "code rem",
			"codUnidade": "Unité défectueuse",
			"tipoRendimento": "Type de revenu",
			"contaMovDeb": "Remise - Compte débiteur",
			"contaMovCred": "Remise - Compte de crédit",
			"contaEncDEB": "Charges employeur/entreprise - Compte débiteur",
			"contaEncCRE": "Charges employeur/entreprise - Compte de crédit"
		},
		"crabdTable": {
			"fields": {
				"categoria": "Catégorie",
				"empregado": "Ent.",
				"patronato": "Ent.",
				"desconto": "Rabais",
				"nomeCategoria": "Catégorie",
				"temIncidenciaEmpregado": "Il a",
				"incidenciaEmpregado": "Incidence",
				"temIncidenciaPatronato": "Il a",
				"incidenciaPatronato": "Incidence",
				"nDesconto": "Code.",
				"nomeDesconto": "Description"
			}
		}
	},
	"periocidadeContratoTipo": {
		"data": {
			"sempre": "Toujours",
			"diario": "Tous les jours",
			"semanal": "Hebdomadaire",
			"quinzenal": "Bimensuel",
			"mensal": "Mensuel",
			"bimensal": "Bimensuel",
			"trimestral": "Trimestriel",
			"semestral": "Semestriel",
			"anual": "Annuel"
		}
	},
	"periodos": {
		"title_detail": "Période comptable {{id}}",
		"title_plural": "Périodes comptables",
		"pesquisa": "A chercher",
		"fields": {
			"periodo": "Période",
			"periodoPlaceholder": "Période comptable",
			"nome": "Nom",
			"nomePlaceholder": "Nom de la période comptable"
		}
	},
	"periodosIVA": {
		"data": {
			"mensal": "Mensuel",
			"trimestral": "Trimestriel"
		}
	},
	"planoscontasalternativos": {
		"title_detail": "Plan de compte alternatif \"{{id}}\"",
		"title_new": "Nouveau plan de compte alternatif",
		"title_plural": "Plans de compte alternatifs",
		"pesquisa": "A chercher",
		"saved": "Plan comptable alternatif, enregistré avec succès.",
		"error": "Un plan de compte alternatif ne peut pas être enregistré.",
		"deleted": "Plan de compte alternatif, supprimé avec succès",
		"fields": {
			"nome": "Nom",
			"tipoValidacao": "Type de validation",
			"contas": "Comptes",
			"nconta": "Numéro de compte",
			"ncontaEdit": "Numéro de compte alternatif",
			"nomeEdit": "Description du compte alternatif",
			"tipoConta": {
				"title": "Type de compte",
				"movimento": "Mouvement",
				"subtotal": "Total",
				"razao": "Raison",
				"classe": "Classe"
			},
			"contasradicais": "Comptes/radicaux",
			"nradical": "N° de compte/Radical",
			"incluiexclui": "Inclut/Exclut",
			"inclui": "Comprend",
			"exclui": "Exclut",
			"contasalt": "Comptes alternatifs",
			"radicaisValidacao": "Validation radicale",
			"escondeRubricasSemMovimento": "Masquer les titres immobiles",
			"inverteSinal": "Inverser le signe comptable",
			"inverteSinalInfo": "Lors de l'activation de cette option, les valeurs seront affichées avec un signe négatif et les valeurs de crédit avec un signe positif."
		},
		"actions": {
			"importPAlt": "Importer un plan alternatif",
			"exportPlano": "Exporter ce plan",
			"getModImportPAlt": "Obtenir un modèle d'importation de plan alternatif"
		},
		"datasource": {
			"data": {
				"validaGeral": "Validation générale",
				"naoValida": "Non valide",
				"validaAnalitica": "Valide les analyses",
				"validaRadicais": "Valide les radicaux"
			}
		},
		"tipoconta": {
			"0": "Mouvement",
			"1": "Total",
			"2": "Raison",
			"3": "Classe"
		},
		"messages": {
			"exportedsuccess": "Plan alternatif exporté avec succès!",
			"modeloImportContasAlt": "Modèle d'importation de plan alternatif réussi obtenu !",
			"errorFiles": "Vous devez télécharger au moins un fichier.",
			"importPlanoAlt": "Plan alternatif importé avec succès !",
			"notSelectPlanoContAlt": "Vous n'avez sélectionné aucun plan de compte alternatif."
		},
		"modals": {
			"conta": {
				"title": "Compte de plan comptable alternatif",
				"ncontaemprty": "Le numéro de compte ne peut pas être vide.",
				"radicalInvalid": "Le numéro de compte/la valeur radicale n’est pas valide.",
				"radicalempty": "Le numéro de compte/radical ne peut pas être vide."
			},
			"import": {
				"title": "Importer un plan comptable alternatif"
			},
			"cubo": {
				"btn": "Cube",
				"title": "Cube de plan de compte alternatif",
				"layoutCubo": {
					"caption": "Style d'aperçu du cube",
					"saldoGeral": "Bilan général",
					"saldoCredDeb": "Solde créditeur/débiteur"
				},
				"fields": {
					"acumuladoCredito": "Crédit accumulé",
					"acumuladoDebito": "Dette accumulée",
					"nconta": "Numéro de compte",
					"ncontaNome": "Numéro de compte - nom",
					"nmesNome": "Numéro de mois - nom",
					"nome": "Nom",
					"nomeNConta": "Nom - Numéro de compte",
					"periodo": "Période",
					"periodoCredito": "Période de crédit",
					"periodoDebito": "Période de débit",
					"saldoCredito": "Solde créditeur",
					"saldoDebito": "Solde débiteur",
					"saldoGeral": "Bilan général"
				}
			},
			"extrato": {
				"titleAcumu": "Provisions du plan comptable alternatif",
				"titleExtrato": "Relevé du plan comptable alternatif",
				"fields": {
					"credito": "Crédit",
					"debito": "Dette",
					"documento": "Document",
					"nDiario": "Numéro quotidien",
					"nDocExterno": "Doc n°",
					"nDocInterno": "Doc n°",
					"nconta": "Numéro de compte",
					"nome": "Nom",
					"periodo": "Période"
				}
			}
		}
	},
	"pocs": {
		"title_detail": "Plan comptable compte {{id}}",
		"title_new": "Nouveau plan comptable compte",
		"title_plural": "Plan comptable",
		"pesquisa": "A chercher",
		"saved": "Plan comptable compte {{id}}, enregistré avec succès",
		"error": "Le compte du plan comptable {{id}} ne peut pas être enregistré.",
		"deleted": "Compte du plan comptable {{id}}, supprimé avec succès",
		"actions": {
			"maintenance": {
				"new": "Créer un nouveau compte",
				"edit": "Modifier le compte"
			}
		},
		"fields": {
			"nConta": "Numéro de compte",
			"nContaPlaceholder": "Code de compte",
			"nome": "Nom",
			"nomePlaceholder": "Nom du compte",
			"tipo": "Type de compte",
			"cc": "Avoir un compte courant",
			"nif": "Numéro de contribuable",
			"temCCusto": "Possède des analyses",
			"temIVA": "A un contrôle de TVA",
			"registaRetencao": "Conservation du registre",
			"temRetencao": "A une rétention",
			"bloqueada": "Bloqué",
			"dpq06NCampo": "Arrêté périodique TVA",
			"dpq06NCampoNome": "Arrêté périodique TVA",
			"daq08NCampo": "TVA annuelle déc. - Tableaux 03 et 05",
			"daq08NCampoNome": "TVA annuelle déc. - Tableaux 03 et 05",
			"daq09NCampo": "TVA annuelle déc. - Tableaux 04 et 05",
			"daq09NCampoNome": "TVA annuelle déc. - Tableaux 04 et 05",
			"codControloIVADebito": "Code de contrôle TVA débiteur",
			"codControloIVADebitoNome": "Code de contrôle TVA débiteur",
			"codControloIVACredito": "Code de contrôle du crédit TVA",
			"codControloIVACreditoNome": "Code de contrôle du crédit TVA",
			"codTaxonomia": "code de taxonomie"
		},
		"text": {
			"maintenancePocs": "Gestion des numéros de contribuable",
			"avisoAlteracaoCC1": "Le compte comporte déjà des écritures comptables.",
			"avisoAlteracaoCC2": "Lors de la réalisation de cette opération, toutes les inscriptions resteront ouvertes.",
			"avisoAlteracaoCC3": "Êtes-vous sûr de vouloir continuer ?",
			"pretendeContinuar": "Je l'ai découvert et j'ai l'intention de continuer"
		},
		"list": {
			"dpq06": "Périodique déc.",
			"daqAnual": "Annuel déc."
		},
		"tipocontacontab": {
			"0": "Mouvement",
			"1": "Total",
			"2": "Raison",
			"3": "Classe"
		},
		"validations": {
			"deConta": "Le compte renseigné ne peut pas être supérieur au compte dans le champ \"Jusqu'au compte\"",
			"ateConta": "Le compte saisi ne peut pas être inférieur au compte dans le champ \"Compte\""
		},
		"alerts": {
			"contaCorrente": "Le compte que vous créez est un compte courant, ce type de compte doit être créé dans le module de tenue des dossiers clients/fournisseurs ou autres débiteurs et créanciers."
		},
		"exportXls": "Exporter",
		"btns": {
			"copiaContasPlanoEmpresa": "Copier les comptes du plan pour les entreprises"
		}
	},
	"pocscopy": {
		"exportcontas": "Comptes_plan_comptes",
		"exportempresas": "entreprises_disponibles",
		"exporterror": "Comptes_erreurs",
		"fields": {
			"nconta": "Numéro de compte",
			"nome": "Nom",
			"tipo": "Taper",
			"temccusto": "Il y a C. Coût",
			"temiva": "A la TVA",
			"ncontade": "Numéro de compte",
			"nEmpresaNome": "Entreprise",
			"nContaNome": "Compte"
		},
		"steps": {
			"intro": {
				"step": "Introduction",
				"title": "Bienvenue dans l'Assistant de copie de factures de plan de consommation intersociétés.",
				"description": "Cet assistant vous guidera tout au long du processus de copie des comptes.",
				"description2": "Vous pouvez annuler l'opération à tout moment."
			},
			"contas": {
				"step": "Comptes",
				"title": "Choisir les comptes à copier.",
				"descriptions": "Veuillez sélectionner les comptes que vous souhaitez copier."
			},
			"empresas": {
				"step": "Entreprises",
				"title": "Choix des entreprises.",
				"descriptions": "Veuillez choisir les sociétés vers lesquelles vous souhaitez copier le(s) compte(s)."
			},
			"relatorio": {
				"step": "Rapport",
				"title": "Configuration terminée.",
				"descriptions": "Compte(s) copié(s) avec succès.",
				"titleerror": "Erreurs lors de la copie des comptes",
				"descriptionserror": "Des erreurs se sont produites lors de la copie des comptes, aucune modification n'a été apportée."
			},
			"errorcontassel": "Vous n'avez sélectionné aucun compte à copier.",
			"errorempresassel": "Vous n'avez sélectionné aucune entreprise dans laquelle vous souhaitez copier le(s) compte(s).",
			"success": ""
		},
		"messages": {
			"pesqsuccess": "Recherche effectuée avec succès."
		}
	},
	"portals": {
		"title_detail": "Portail {{id}}",
		"title_new": "Nouveau portail",
		"title_plural": "Portails",
		"pesquisa": "A chercher",
		"saved": "Portail {{id}}, enregistré avec succès",
		"deleted": "Portail {{id}}, supprimé avec succès",
		"error": "Le portail {{id}} ne peut pas être enregistré.",
		"fields": {
			"id": "identifiant",
			"name": "Nom",
			"description": "Description",
			"icon": "icône",
			"url": "URL",
			"data": "Données de configuration du portail",
			"sticky": "Fixé",
			"sortOrder": "Commande",
			"roles": "Autorisations",
			"includes": "Comprend"
		},
		"actions": {
			"sort": "Trier les portails"
		},
		"text": {
			"noAccess": "Vous n'avez pas accès à ce portail.",
			"editNotAllowed": "Impossible de modifier les portails système",
			"deleteNotAllowed": "Impossible de supprimer les portails du système",
			"forbiddenMessage": "Certains utilisateurs ont accès à ce portail. Si vous le supprimez, les accès existants seront également supprimés.",
			"showModuleName": "Afficher le nom du module",
			"tooltipIncludes": "Les utilisateurs ayant accès à ce portail auront également accès aux portails inclus",
			"sidebarTitle": "Titre dans la barre latérale"
		},
		"btn": {
			"copy": "Copier le nom du module",
			"remove": "Retirer",
			"edit": "Modifier",
			"toggleVisibility": "Visible"
		},
		"items": {
			"erp": "Commercial",
			"financeiro": "Financier",
			"contabilidade": "Comptabilité",
			"ativos": "Actifs",
			"rh": "Ressources humaines",
			"pca": "Ma comptabilité",
			"clientconnect": "Connexion client",
			"gestorrh": "Responsable RH",
			"gestorservico": "Responsable des services",
			"colaborador": "Collaborateur",
			"gestorementas": "Gestionnaire de menus",
			"configuracoes": "Paramètres",
			"contabilidadepremium": "Comptabilité Premium",
			"erpadvanced": "Commercial avancé"
		},
		"module": {
			"newMenu": "Nouveau menu",
			"menu": "Menu",
			"modulosdisponiveis": "Modules disponibles"
		},
		"menu": {
			"erp": {
				"clientes": "Clients",
				"fornecedores": "Fournisseurs",
				"consultasvendas": "Demandes de vente",
				"consultascompras": "Demandes d'achat",
				"contascorrentes": "Comptes courants",
				"stocks": "actions",
				"tesouraria": "Trésorerie",
				"integracoes": "Intégrations",
				"configuracoes": "Paramètres",
				"manutencoes": "Entretien"
			},
			"pca": {
				"contabilidade": "Comptabilité",
				"abonosdescontos": "Allocations et réductions",
				"mapassalarios": "Cartes des salaires",
				"prestacaocontas": "Responsabilité",
				"minhaconta": "Mon compte"
			},
			"gestorementas": {
				"colaborador": "Collaborateur",
				"menus": "Menus",
				"refeicoes": "Repas"
			},
			"gestorrh": {
				"colaborador": "Collaborateur",
				"servicos": "Services",
				"tabelas": "Tableaux",
				"marcacaoeventos": "Réservation d'événement"
			},
			"gestorservico": {
				"colaborador": "Collaborateur",
				"marcacaoeventos": "Réservation d'événement"
			},
			"contabilidade": {
				"lancamentos": "Sorties",
				"consultas": "Requêtes",
				"manutencaoContas": "Tenue de compte",
				"declaracoesFiscais": "Déclarations fiscales",
				"fechoContas": "Clôture des comptes",
				"ferramentas": "Outils",
				"manutencoes": "Entretien"
			},
			"configuracoes": {
				"atalhos": "Raccourcis de l'assistant de configuration",
				"configuracaoerp": "Configuration ERP"
			},
			"ativos": {
				"manutencoesTitle": "Entretien",
				"manutencoes": "Entretien",
				"consultarTitle": "Consulter",
				"consultar": "Consulter",
				"atalhoscontabilidadeTitle": "Raccourcis vers la comptabilité",
				"atalhoscontabilidade": "Raccourcis vers la comptabilité",
				"tabelasTitle": "Tableaux",
				"tabelas": "Tableaux"
			},
			"rh": {
				"transferencias": "Transferts",
				"recolhadados": "Collecte de données",
				"marcacaocalendario": "Marquage du calendrier",
				"processamento": "Traitement",
				"obrigacoeslegaisfiscais": "Obligations légales et fiscales",
				"integracaocontabilidade": "Intégration comptable",
				"consultas": "Requêtes",
				"abonosdescontos": "Allocations/réductions",
				"ferramentas": "Outils",
				"configuracoes": "Paramètres",
				"manutencoes": "Entretien"
			},
			"financeiro": {
				"clientes": "Clients",
				"fornecedores": "Fournisseurs",
				"manutencoes": "Entretien"
			}
		},
		"sidebar": {
			"modules": {
				"adiantamentos": "Avances",
				"analiticaconfig": "Configuration de comptes d'analyse",
				"apuraiva": "Apurement de la TVA",
				"apuramentoresultados": "Calcul des résultats",
				"atFaturasRecibosVerdes": "Des tickets de ventes verts",
				"ativosaquisicaocontab": "Enregistrer les actifs sur la base de la comptabilité",
				"ativosaquisicaoinvestemcurso": "Des investissements en cours",
				"ativoscoeficientecm": "Coefficients de correction monétaire",
				"ativosconsistencia": "Cohérence avec la comptabilité",
				"balancetes": "Bilans",
				"balancetesalternativos": "Bilans plats alternatifs",
				"balancetesanalitica": "Bilans analytiques",
				"controloivaentreempresas": "Copier le contrôle de la TVA pour les entreprises",
				"declaracaoiva": "Arrêté périodique TVA",
				"declaracaoivarecapitulativa": "Arrêté récapitulatif TVA",
				"demfinsnc": "Dém. financier SNC.",
				"descritivos": "Descriptifs",
				"diarios": "Agendas",
				"dmrf": "Déc. retenues à la source",
				"docsComerciaisEstatisticas": "Statistiques par article",
				"docsComerciaisEstatisticasDoc": "Statistiques par document",
				"docscontabilidade": "Collection",
				"documentosdigitaisConciliacao": "Documents de conciliation.",
				"extratosDT": "Extrait historique",
				"faturacaoeletronicaconfiguracoes": "Facturation électronique",
				"faturacaopublica": "Facturation publique",
				"gto": "Gestion des tâches",
				"impdecpe": "Extraits déc.",
				"importadorEfatura": "Achats par facture électronique",
				"importadorSaftDirect": "Ventes aux importateurs",
				"infoglobalacrescimos": "Infos.",
				"infoglobaldiferimentos": "Infos.",
				"infoglobaldiferimentosacrescimos": "Infos.",
				"listagemdiarios": "Agendas",
				"listagensVendasAnoMes": "Sommaire par mois",
				"manuttributautonomas": "Entretien",
				"mapasamortizacao": "Cartes officielles",
				"mbconfigs": "Références MB",
				"meiosPagamento": "Modes de paiement",
				"movimentosemaberto": "Mouvements ouverts",
				"pcaContabilidadeMovimentosAbertoCliente": "Mouvements ouverts",
				"pcaContabilidadeMovimentosAbertoFornecedor": "Mouvements ouverts",
				"pcaMovimentosAbertoCliente": "Se déplacer.",
				"periodos": "Périodes",
				"pocscopy": "Copier les comptes du plan pour les entreprises",
				"predefinidoscontabilidade": "Prédéfini",
				"prheventosconfig": "Configuration.",
				"proceDMRAT": "DMR À",
				"proceSalarios": "Salaires",
				"proceSegSocial": "DMR SS (DRI)",
				"procseguradorafolhaferias": "Feuille de vacances pour compagnie d'assurance",
				"qivaas": "Campos déc.",
				"qivaps": "Campos déc.",
				"remfx": "Données fixes",
				"remun": "Données manuelles",
				"reparticoesccustosfaturacao": "C. Ventilation des coûts",
				"reparticoesccustossalarios": "C. Ventilation des coûts",
				"rhassistentetrabalhador": "Intégration des salaires",
				"rhdadosconfigficha": "Données de la carte",
				"rhmanutencao": "Entretien",
				"rhprocessamentosalarios": "Traitement des salaires",
				"rhrecolhaseriesubsidiosalimentacao": "Allocation alimentaire",
				"rhrecolhaseriesubsidiosferias": "Allocation de vacances",
				"rhrecolhaseriesubsidiosnatal": "Allocation de Noël",
				"rhsaltotrf": "Salaires",
				"saft": "SAF-T complet",
				"solic": "Textes d'avis de facturation",
				"svat": "Dém. financier SVAT.",
				"tesrubrica": "Éléments de trésorerie",
				"trfba": "Paiements SEPA",
				"trfbacomercial": "Paiements SEPA",
				"trfbarh": "Paiements SEPA",
				"mapadiferimentosacrescimos": "Cartographier les régularisations et les reports",
				"grupodocfaconfiguracoes": "Groupes de types de documents",
				"analisemargemlucro": "Analyse de la marge bénéficiaire"
			},
			"contabilidade": {
				"clientes": "Clients",
				"fornecedores": "Fournisseurs",
				"outrosdevedorescredores": "Autres débiteurs et créanciers"
			},
			"rh": {
				"integracaoSalarios": "Entreprise",
				"integracaoSalariosMulti": "Multi-entreprises"
			}
		},
		"modals": {
			"editnode": {
				"title": "Modifier les données d'entrée du menu",
				"fields": {
					"menuTitle": "Titre du menu",
					"pageTitle": "Titre du module",
					"sidebarTitle": "Titre du module dans la barre latérale",
					"sidebarTitleTooltip": "Si ce champ n'est pas renseigné, la valeur sera la même que celle du champ \"Titre du module\".",
					"icon": "icône"
				}
			},
			"sort": {
				"title": "Définir l'ordre des portails",
				"success": "Portails commandés avec succès"
			}
		}
	},
	"predefinidoscontabilidade": {
		"title_plural": "Préréglages comptables",
		"pesquisa": "A chercher",
		"success": {
			"save": "Préréglage comptable enregistré avec succès",
			"edit": "Comptabilité prédéfinie \"{{id}}\" enregistrée avec succès",
			"delete": "Comptabilité prédéfinie \"{{id}}\" supprimée avec succès",
			"simulate": "Pièce comptable enregistrée avec succès (simulation)"
		},
		"steps": {
			"operation": "Opération à réaliser",
			"basic": "Configuration des données",
			"header": "Configuration des données d'en-tête",
			"lines": "Configuration des données de ligne",
			"preview": "Aperçu prédéfini",
			"companies": "Choix des entreprises",
			"finalize": "Finition"
		},
		"fields": {
			"preDefinidosID": "Code",
			"description": "Description",
			"data": "Données",
			"revision": "Révision",
			"cgBanking": "A utiliser dans CentralGest Banking",
			"document": "Document",
			"copyExisting": "Préréglage à copier"
		},
		"titles": {
			"chooseOperation": "Veuillez choisir l'opération que vous souhaitez effectuer",
			"notAvailable": "Cette option n'est pas encore disponible",
			"suggestCodigo": "Proposer un code",
			"suggestCodigoToolTip": "Proposer un code unique pour toutes les entreprises",
			"legend": "Légende",
			"verified": "Champ vérifié",
			"notVerified": "Champ non vérifié",
			"availableCompanies": "Entreprises disponibles",
			"companiesToSave": "Entreprises où les prédéfinis seront enregistrés",
			"companiesToRemove": "Entreprises où les prédéfinis seront supprimés",
			"selectColumns": "Veuillez choisir les entreprises dans lesquelles vous souhaitez créer le profil prédéfini",
			"selectColumnsEdit": "Veuillez choisir les entreprises pour lesquelles vous souhaitez modifier la valeur par défaut",
			"selectColumnsDelete": "Veuillez choisir les entreprises dans lesquelles vous souhaitez supprimer les valeurs prédéfinies"
		},
		"operations": {
			"new": "Créer un nouveau préréglage",
			"newBasedOnDoc": "Créer un nouveau préréglage basé sur une pièce comptable",
			"newBasedOnExisting": "Créer un nouveau préréglage basé sur un préréglage existant",
			"newAdvanced": "Créer un nouveau préréglage avancé",
			"edit": "Changer le préréglage",
			"delete": "Supprimer le préréglage"
		},
		"errors": {
			"invalidDocContab": "La pièce comptable \"{{doc}}\" n'existe pas",
			"invalidPreDefinido": "Le \"{{predéfini}}\" prédéfini n'existe pas"
		},
		"btn": {
			"simulate": "Simuler l'enregistrement",
			"clear": "Effacer le document"
		}
	},
	"predefinidocontabcab": {
		"periodo": "Période",
		"diario": "Tous les jours",
		"nDocInterno": "Doc n°",
		"dataLancamento": "Date de publication comptable",
		"dataVencimento": "Date d'échéance",
		"dataDoc": "Date du document",
		"nContribuinte": "Numéro de contribuable",
		"nDocExterno": "Doc n°",
		"descricao": "Description",
		"descritivo": "Descriptif",
		"dataTransacaoBanco": "Date de la transaction bancaire",
		"titles": {
			"fieldProperties": "Propriétés du champ",
			"visualProperties": "Propriétés visuelles"
		},
		"fields": {
			"readonly": "Lecture seule",
			"tabStop": "Le curseur s'arrête dans le champ",
			"visible": "Visible",
			"tipoValorPeriodo": "Origine de la valeur",
			"tipoValorDiario": "Origine de la valeur",
			"valorFixo": "Valeur fixe",
			"valorFixoDiario": "Agenda fixe",
			"valorFixoNDocExterno": "Doc n°",
			"valorFixoDescricao": "Description fixe",
			"valorFixoDescritivo": "Description fixe",
			"valorFixoConta": "Compte fixe",
			"origem": "Origine",
			"incrementaAutomaticamente": "Incrémentation automatique",
			"origemValor": "Origine de la valeur"
		},
		"errorInvalid": "Vous devez vérifier tous les champs d'en-tête pour continuer",
		"enums": {
			"tipoValorPeriodo": {
				"0": "Tableau de l'entreprise",
				"1": "Date du système",
				"2": "Date du document.",
				"3": "Date de la transaction bancaire"
			},
			"tipoValorDiario": {
				"0": "Non défini",
				"1": "Fixé"
			},
			"origemDataDoc": {
				"0": "De la période du doc.",
				"1": "Date du document."
			},
			"origemDataVencimento": {
				"0": "De la période du doc.",
				"1": "Cond."
			},
			"origemDataLancamento": {
				"0": "Dernier jour de la période",
				"1": "Date actuelle",
				"2": "Date du document."
			},
			"valorDescricao": {
				"0": "Non défini",
				"1": "Fixé",
				"2": "Formule"
			},
			"valorDescritivo": {
				"0": "Non défini",
				"1": "Idem que le dernier doc.",
				"2": "Fixé"
			}
		}
	},
	"predefinidocontablinhas": {
		"addLine": "Ajouter une ligne",
		"duplicateLine": "Dupliquer la ligne sélectionnée",
		"moveLineUp": "Déplacer la file",
		"moveLineDown": "Déplacer la ligne vers le bas",
		"removeLine": "Supprimer la ligne sélectionnée",
		"reorder": "Réorganiser les colonnes",
		"errorInvalid": "N'a défini aucune ligne pour le paramètre prédéfini",
		"titles": {
			"fieldProperties": "Propriétés du champ \"{{field}}\" ({{cell}})",
			"visualProperties": "Propriétés visuelles",
			"emptyLines": "La table de lignes est vide.",
			"baseTributavelProperties": "Propriétés de base imposables",
			"contaCorrenteProperties": "Propriétés du compte courant",
			"ask": "Question",
			"salda": "Salda",
			"arredEFatura": "Arrêté.",
			"hintFormula": "Pour modifier, cliquez sur la calculatrice à droite"
		},
		"fields": {
			"conta": "Compte",
			"valor": "Valeur",
			"valorIva": "Valeur TVA",
			"nContribuinte": "Numéro de contribuable",
			"descricao": "Description",
			"nDocExterno": "Doc n°",
			"descritivo": "Descriptif",
			"dataLancamento": "Date de publication comptable",
			"dataVencimento": "Date d'échéance",
			"dataDoc": "Date du document",
			"nomeConta": "Nom du compte",
			"dC": "Débit/Crédit",
			"radical": "Radical",
			"tipoConta": "Type de compte",
			"temImputacao": "Sélectionnez les montants à régler",
			"vaiImputarALinha": "Imputera à la ligne",
			"codRepCC": "Code de répartition c.",
			"tipoValor": "Type de valeur",
			"valorFormula": "Valeur par formule",
			"colocaValorEFaturaDocDigital": "Entrez la valeur eFatura ou doc.",
			"incrementaAutomaticamente": "Incrémentation automatique",
			"tipoValorBaseTributavel": "Valeur avec/sans TVA",
			"importEFaturaSuportaTaxaIsenta": "Prend en charge le diablotin exonéré d'impôt.",
			"importEFaturaValorImpSelo": "Placer le timbre fiscal diablotin.",
			"importEFaturaContaUnicaSemDeducao": "Compte unique sans déduction fiscale",
			"importFRVSuportaTaxaIsenta": "Prend en charge l'enregistrement exonéré de frais.",
			"importFRVValorImpSelo": "Placer le timbre fiscal rec."
		},
		"enums": {
			"tipoConta": {
				"0": "Non défini",
				"1": "Compte courant",
				"2": "Base imposable",
				"3": "Compte bancaire CG Banking"
			},
			"tipoValor": {
				"0": "Question",
				"1": "Fixé",
				"2": "Formule",
				"3": "Document de solde",
				"4": "Arrondi des factures électroniques"
			},
			"tipoValorBaseTributavel": {
				"0": "Question",
				"1": "Base",
				"2": "Assiette + TVA"
			},
			"tipoValorDescritivo": {
				"0": "Non défini",
				"1": "Idem que le dernier doc.",
				"2": "Fixé"
			}
		}
	},
	"predefinidocontabformula": {
		"title": "Formule pour le champ {{field}} de la ligne {{line}}",
		"operators": "Opérateurs",
		"variables": "Variables",
		"formula": "Formule",
		"validator": "Je n'ai pas encore défini de formule pour le champ",
		"extraVariables": {
			"valorPorPagar": "ValeurParPayLine"
		},
		"headerVariables": {
			"periodo": "En-tête.Période",
			"nomePeriodo": "Header.NamePeriod"
		}
	},
	"prevites": {
		"manual": {
			"new": {
				"title": "Prévisions du Trésor",
				"previtessaved": "Les prévisions de trésorerie ont été enregistrées avec succès.",
				"rubrica": "Rubrique",
				"descricao": "Description",
				"valor": "Valeur",
				"cardtitle": "Occurrences successives",
				"periodicas": "Périodiques",
				"ocrrfimperio": "Occurrence à la fin de chaque période",
				"ocorrencias": "Nombre d'occurrences",
				"periodicidade": "Périodicité",
				"temdefnocurr": "Vous avez défini la Périodicité mais n'avez pas encore défini le Nombre d'Occurrences!",
				"temdefocurr": "Vous avez défini le Nombre d'Occurrences mais n'avez pas encore défini la Périodicité !",
				"rubricanull": "La rubrique ne peut pas être nulle !",
				"valornull": "La valeur ne peut pas être nulle !",
				"datainf": "La date ne peut pas être inférieure à la date du jour !",
				"nConta": "Numéro de compte",
				"nomeConta": "Nom du compte"
			},
			"tooltip": {
				"btnDelete": "Supprimer l'inscription",
				"btnDeleteMultiple": "Supprimer les enregistrements sélectionnés"
			},
			"messages": {
				"certezaeliminar": "Êtes-vous sûr de vouloir supprimer le ou les enregistrements ?"
			},
			"btnDeleteMulti": "Supprimer les enregistrements sélectionnés"
		},
		"automatica": {
			"modalTitle": "Rubrique Trésorerie",
			"valselecionado": "Valeur sélectionnée",
			"clientefornecedor": "Clients / Fournisseurs",
			"clientes": "Clients",
			"fornecedores": "Fournisseurs",
			"datatitle": "Sélectionnez la date du Trésor",
			"datade": "Date d'expiration",
			"contade": "compte",
			"selecaoAutomatica": "Sélectionnez Automatique",
			"selecaoAutomaticaTooltip": "Une fois sélectionné, sélectionne automatiquement toutes les lignes du document",
			"fields": {
				"seleccionado": "Choisi",
				"nConta": "Numéro de compte",
				"nome": "Nom",
				"nDocString": "Doc n°",
				"nDocExterno": "N° de document externe",
				"descricao": "Description",
				"debito": "Dette",
				"credito": "Crédit",
				"porPagar": "Non rémunéré",
				"dataDocExt": "Date.",
				"dataVencimento": "Date d'échéance",
				"dataTes": "Date de trésorerie"
			},
			"messages": {
				"procesuccess": "Données traitées avec succès !",
				"temselecionar": "Vous devez sélectionner les entrées que vous souhaitez traiter."
			},
			"actions": {
				"verdoc": "Afficher le document",
				"aplicardata": "Appliquer la date de trésorerie",
				"selecionaDocConta": "Sélectionnez les documents de compte",
				"removeSeleconta": "Effacer la sélection des documents de compte",
				"docfaturacao": "Afficher le document de facturation"
			}
		},
		"datasource": {
			"tesourariaperiodicidade": {
				"none": "Aucun",
				"diaria": "Tous les jours",
				"semanal": "Hebdomadaire",
				"quinzenal": "Bimensuel",
				"mensal": "Mensuel",
				"bimensal": "Bimensuel",
				"trimestral": "Trimestriel",
				"quadrimestral": "Trimestriel",
				"semestral": "Semestriel",
				"anual": "Annuel"
			}
		},
		"legend": {
			"correcaoAutomatica": "Correction automatique - Date actuelle"
		}
	},
	"prevtesobras": {
		"codTipoPlaneamentoDe": "Code du type de planification",
		"codEstadoObraDe": "Code de statut de travail",
		"cabCodigo": "Code de travail",
		"cabDescricao": "Nom de l'œuvre",
		"codPlaneamenTipo": "Code d'urbanisme",
		"descricao": "Desc.",
		"codEstado": "Code d'état",
		"codEstadoDesc": "Desc.",
		"modaltitle": "Poste de trésorerie",
		"rubrica": "Code de rubrique",
		"rubricanull": "Le code de l'article ne peut pas être nul",
		"importsuccess": "Données traitées avec succès!"
	},
	"prhcomunicacoesinternas": {
		"title_detail": "Communication interne {{id}}",
		"title_new": "Nouvelle communication interne",
		"title_plural": "Communications internes",
		"pesquisa": "A chercher",
		"saved": "Communication interne {{id}}, enregistrée avec succès",
		"error": "La communication interne {{id}} ne peut pas être enregistrée.",
		"fields": {
			"comIntCabID": "Code.",
			"comIntCabIDPlaceholder": "Code de communication interne",
			"titulo": "Titre",
			"tituloPlaceholder": "Titre de la communication interne",
			"estadoLido": "Lire",
			"de": "De",
			"texto": "Texte",
			"ficheiro": "Déposer"
		},
		"masterDetail": {
			"emp": "Code employé",
			"nome": "Nom",
			"lido": "Lire",
			"dateHora": "Date/heure de lecture"
		}
	},
	"prhdocsplata": {
		"title_detail": "Document de la plateforme RH {{id}}",
		"title_new": "Nouveau document de la plateforme RH",
		"title_plural": "Documents de la plateforme RH",
		"pesquisa": "A chercher",
		"saved": "Document de la plateforme RH {{id}}, enregistré avec succès",
		"error": "Le document de la plateforme RH {{id}} ne peut pas être enregistré.",
		"fields": {
			"cab": {
				"codprhdocsplata": "Code.",
				"codprhdocsplataPlaceholder": "Code document de la plateforme RH",
				"titulo": "Titre",
				"tituloPlaceholder": "Titre du document de la plateforme RH",
				"dataPlaceholder": "Date du document de la plateforme RH",
				"dataValidade": "Date d'expiration",
				"dataValidadePlaceholder": "Date d'expiration"
			}
		},
		"steps": {
			"documento": "Données du document",
			"papeis": "Papiers",
			"servicos": "Services"
		}
	},
	"prhentalters": {
		"erroDoCampo": "Une erreur s'est produite lors de l'envoi de la demande de modification des données du champ : \"{{campo}}\"",
		"naoExistemAlteracoesParaGuardar": "Il n’y a aucune modification à enregistrer.",
		"foiEnviadoPedidoDeAlteracaoDados": "La demande de modification des données a été envoyée.",
		"foiEnviadoPedidoDeAlteracaoDadosMasAlgunsComErro": "La demande de modification des données a été envoyée, mais certains champs comportent des erreurs.",
		"ibanInvalido": "L'IBAN \"{{iban}}\" n'est pas valide",
		"tooltips": {
			"ibanInvalido": "Cet IBAN n'est pas valide."
		}
	},
	"prheventosconfig": {
		"title_detail": "{{id}} configuration de l'événement",
		"title_new": "Nouvelle configuration d'événement",
		"title_plural": "Paramètres d'événement RH",
		"title_small": "Configuration.",
		"pesquisa": "A chercher",
		"saved": "Configuration de l'événement {{id}}, enregistrée avec succès",
		"error": "La configuration de l'événement {{id}} ne peut pas être enregistrée.",
		"fields": {
			"codPRHEventosConfig": "Configuration d'événement",
			"codPRHEventosConfigPlaceholder": "Configuration d'événement",
			"tipoEvento": "Type d'événement",
			"tipoEventoPlaceholder": "Type d'événement",
			"tipo": "Taper",
			"tipoPlaceholder": "Taper",
			"nCodAbdes": "Code de réduction/de réduction",
			"nCodAbdesPlaceholder": "Code de réduction/de réduction",
			"descricao": "Description",
			"descricaoPlaceholder": "Description",
			"tipoProcessamento": "Type de traitement",
			"tipoProcessamentoPlaceholder": "Type de traitement",
			"tipofalta": "Type de défaut",
			"desconto": "Rabais",
			"abono": "Allocation"
		},
		"tipos": {
			"abonos": "Allocations",
			"faltas": "Fautes",
			"ferias": "Vacances"
		},
		"messages": {
			"error": "La configuration de l'événement ne peut pas être enregistrée.",
			"addsuccess": "Ajouté avec succès",
			"editsuccess": "Modification réussie",
			"deleteSuccess": "Supprimé avec succès",
			"deleteTitle": "Attention!",
			"deleteConfirm": "Voulez-vous vraiment supprimer cet enregistrement ?",
			"fieldsUnfiled": "Il y a des champs qui ne sont pas remplis!"
		}
	},
	"prhfluxos": {
		"title_detail": "Flux {{id}}",
		"title_new": "Nouveau flux",
		"title_plural": "Flux RH",
		"pesquisa": "A chercher",
		"saved": "Flux {{id}}, enregistré avec succès",
		"error": "Le flux {{id}} ne peut pas être enregistré.",
		"fields": {
			"idFluxo": "Couler",
			"idFluxoPlaceholder": "Code de flux",
			"posicao": "Position",
			"posicaoPlaceholder": "Position du débit",
			"indice": "Indice",
			"indicePlaceholder": "Indice de débit",
			"codPapel": "Code papier",
			"codPapelPlaceholder": "Code de rôle actif",
			"integraCG": "Integra CentralGest",
			"integraCGPlaceholder": "Integra CentralGest",
			"papel": "Papier",
			"papelCGPlaceholder": "Papier",
			"tipo": "Taper",
			"tipoPlaceholder": "Taper"
		}
	},
	"prhgruporefeicoes": {
		"title_detail": "Groupe de repas {{id}}",
		"title_new": "Nouveau groupe de repas",
		"title_plural": "Repas de groupes",
		"pesquisa": "A chercher",
		"saved": "Groupe de repas {{id}}, enregistré avec succès",
		"error": "Le groupe de repas {{id}} ne peut pas être enregistré.",
		"fields": {
			"grupo": "Groupe",
			"grupoPlaceholder": "Groupe",
			"nome": "Nom",
			"nomePlaceholder": "Nom du groupe de repas",
			"permiteAgendamento": "Permet la planification",
			"permiteAgendamentoPlaceholder": "Permet la planification",
			"horaInicio": "Heure de début",
			"horaInicioPlaceholder": "Heure de début",
			"horaFim": "Heure de fin",
			"horaFimPlaceholder": "Heure de fin"
		}
	},
	"prhlocais": {
		"title_detail": "Emplacement {{id}}",
		"title_new": "Nouvel emplacement",
		"title_plural": "Emplacements RH",
		"pesquisa": "A chercher",
		"saved": "Emplacement {{id}}, enregistré avec succès",
		"error": "L'emplacement {{id}} ne peut pas être enregistré.",
		"fields": {
			"codLocal": "Emplacement",
			"codLocalPlaceholder": "Code de localisation",
			"nome": "Nom",
			"nomePlaceholder": "Nom du lieu"
		}
	},
	"prhmenus": {
		"title_detail": "Menu {{id}}",
		"title_new": "Nouveau menu",
		"title_plural": "Menus RH",
		"pesquisa": "A chercher",
		"saved": "Menu {{id}}, enregistré avec succès",
		"error": "Menu {{id}}, ne peut pas être enregistré.",
		"fields": {
			"codMenu": "Menu",
			"codMenuPlaceholder": "Codes des menus",
			"titulo": "Titre",
			"tituloPlaceholder": "Titre du menu",
			"descricao": "Description",
			"descricaoPlaceholder": "Description des menus"
		}
	},
	"prhpapeis": {
		"title_detail": "Papier {{id}}",
		"title_new": "Nouveau rôle",
		"title_plural": "Documents RH",
		"pesquisa": "A chercher",
		"saved": "Rôle {{id}}, enregistré avec succès",
		"error": "Le papier {{id}} ne peut pas être enregistré.",
		"fields": {
			"codPapel": "Papier",
			"codPapelPlaceholder": "Code papier",
			"nome": "Nom",
			"nomePlaceholder": "Nom du papier",
			"unicoServico": "Unique par service",
			"unicoServicoPlaceholder": "Unique par service"
		}
	},
	"prhrefeicoes": {
		"title_detail": "Repas {{id}}",
		"title_new": "Collation",
		"title_plural": "Repas HR",
		"pesquisa": "A chercher",
		"saved": "Repas {{id}}, enregistré avec succès",
		"error": "Le repas {{id}} ne peut pas être enregistré.",
		"fields": {
			"CodRefeicao": "Collation",
			"CodRefeicaoPlaceholder": "code repas",
			"CODEMP": "Collaborateur",
			"CODEMPPlaceholder": "Code employé",
			"codPRHRelEmentas": "Relament",
			"codPRHRelEmentasPlaceholder": "Code RelEmenta",
			"consumiu": "Consommé",
			"local": "Emplacement",
			"tipoRefeicao": "Type de repas",
			"cor": "Couleur",
			"menu": "Menu",
			"ementa": "Menu",
			"refConsumida": "Repas consommé",
			"locais": "Emplacements"
		},
		"message": {
			"porProcessar": "Aucune donnée à traiter.",
			"procComSucesso": "Traité avec succès"
		},
		"grupoRefeicao": {
			"almoco": "Déjeuner",
			"jantar": "Pour déjeuner",
			"nao": "Non",
			"sim": "Oui"
		},
		"btn": {
			"procRefNCons": "Proc."
		}
	},
	"prhrelementas": {
		"title_detail": "RelEmenta {{id}}",
		"title_new": "Relament",
		"title_plural": "Relations Menu RH",
		"pesquisa": "A chercher",
		"saved": "RelEmenta {{id}}, enregistré avec succès",
		"error": "RelEmenta {{id}}, ne peut pas être enregistré.",
		"menusAssociados": "Le menu ne peut pas être supprimé car des repas sont déjà programmés.",
		"fields": {
			"codPRHRelEmentas": "Rapport de menu",
			"codPRHRelEmentasPlaceholder": "code de relation entre les menus",
			"codMenu": "Menu",
			"codMenuPlaceholder": "Codes des menus",
			"codLocal": "Emplacement",
			"codLocalPlaceholder": "Code de localisation",
			"tipoRefeicao": "Type de repas",
			"tipoRefeicaoPlaceholder": "Type de repas"
		}
	},
	"prhservicoempregados": {
		"title_detail": "Rapport de service/employé {{id}}",
		"title_new": "Nouveau rapport de service/employé",
		"title_plural": "Service RH/Rapport sur les employés",
		"pesquisa": "A chercher",
		"saved": "Rapport de service/employé enregistré avec succès.",
		"error": "Les rapports de service/d'employé ne peuvent pas être enregistrés.",
		"fields": {
			"codServicoEmpregado": "Code rel.",
			"codServicoEmpregadoPlaceholder": "Code relais.",
			"codPapel": "Papier",
			"codPapelPlaceholder": "Code papier",
			"codServico": "Service",
			"codServicoPlaceholder": "Code de service",
			"codEmp": "Employé",
			"codEmpPlaceholder": "Code employé"
		},
		"errors": {
			"servicoAtualNaoSelecionado": "Sélectionnez un service actuel.",
			"servicoDestinoNaoSelecionado": "Sélectionnez un service cible.",
			"servicosAtualDestinoIguais": "Le service actuel ne peut pas être le même que le service cible.",
			"naoExistemEmpregadosSelecionados": "Il n'y a aucun employé sélectionné."
		}
	},
	"prhservicos": {
		"title_detail": "Service {{id}}",
		"title_new": "Nouveau service",
		"title_plural": "Services RH",
		"pesquisa": "A chercher",
		"saved": "Service {{id}}, enregistré avec succès",
		"error": "Le service {{id}} ne peut pas être enregistré.",
		"deleted": "Service {{id}}, supprimé avec succès.",
		"fields": {
			"codServico": "Service",
			"codServicoPlaceholder": "Code de service",
			"nome": "Nom",
			"nomePlaceholder": "Nom du service"
		}
	},
	"prhtipocom": {
		"title_detail": "Type de communication {{id}}",
		"title_new": "Nouveau type de communication",
		"title_plural": "Types de communication",
		"pesquisa": "A chercher",
		"saved": "Tapez Communication {{id}}, enregistré avec succès.",
		"error": "Le type de communication {{id}} ne peut pas être enregistré.",
		"deleted": "Tapez Communication {{id}}, supprimée avec succès",
		"fields": {
			"codPRHTipoCom": "Code du type de communication",
			"codPRHTipoComPlaceholder": "Code du type de communication",
			"nome": "Nom",
			"sistema": "Système"
		}
	},
	"prhtiporefeicoes": {
		"title_detail": "Type de repas {{id}}",
		"title_new": "Nouveau type de repas",
		"title_plural": "Types de repas RH",
		"pesquisa": "Recherche",
		"saved": "Type de repas {{id}}, enregistré avec succès",
		"error": "Type de repas {{id}}, non enregistré.",
		"fields": {
			"tipoRefeicao": "Type de repas",
			"tipoRefeicaoPlaceholder": "Type de repas",
			"nome": "Nom",
			"nomePlaceholder": "type de repas nom",
			"horaRefeicao": "L'heure du repas",
			"horaRefeicaoPlaceholder": "L'heure du repas",
			"numMinutosMarcacao": "Nombre de minutes à composer",
			"numMinutosMarcacaoPlaceholder": "Nombre de minutes à composer",
			"corPrimaria": "Couleur primaire",
			"corPrimariaPlaceholder": "Couleur primaire",
			"corSecundaria": "Couleur secondaire",
			"corSecundariaPlaceholder": "Couleur secondaire",
			"grupoRefeicao": "Repas de groupe",
			"grupoRefeicaoPlaceholder": "Repas de groupe"
		}
	},
	"proceDMRAT": {
		"comecar": "Pour commencer",
		"comoResolver": "Vérifiez le statut de l'entreprise en question et/ou du salarié.",
		"decSubstituicao": "Déclaration de remplacement",
		"enviadoComErros": "Il y avait des erreurs dans les fichiers envoyés.",
		"enviadoComSuccess": "Fichier(s) envoyé(s) avec succès.",
		"erros": "Erreurs",
		"genDRIFile": "Générer un fichier",
		"geracaoImpressao": "Génération et impression",
		"initNewProc": "Commencer nouveau",
		"jobTimeoutModalMessage": "La tâche a expiré.",
		"jobTimeoutModalTitle": "Tâche",
		"obterPdf": "Obtenir le pdf",
		"printMapPerEmp": "Imprimer la carte",
		"printMapaModalTitle": "Impression des cartes des relevés mensuels de rémunération - AT",
		"procAlertMsg1": "Par défaut, il générera du DMR-AT pour tous les traitements du mois.",
		"procAlertMsg2": "Si vous souhaitez vérifier les processus existants, sélectionnez le champ ci-dessous",
		"procExistentesReprocMessage": "Il existe des entreprises qui traitent le relevé de rémunération mensuel - AT.",
		"promptAlertModalTitle": "Attention!",
		"promptSendModalMessage": "Vous enverrez le(s) fichier(s) de décompte de rémunération mensuel via WebService.",
		"promptViewFilesMessage": "Les dossiers ont été communiqués.",
		"promptViewResultsMessage": "Le traitement du décompte mensuel de rémunération est terminé.",
		"promptViewResultsTitle": "Déclaration de rémunération mensuelle",
		"resumoDadosFuncionario": "Résumé des données des employés",
		"semAlteracaoParaGerar": "Le système n'a pas généré de fichier, car celui-ci avait déjà été généré précédemment.",
		"sendFile": "Envoyer via WebService",
		"tabConfigCaption": "Paramètres",
		"tabEmpresasCaption": "Entreprises",
		"tabIntroCaption": "Introduction",
		"tabIntroSubTitle": "L'assistant vous aidera à générer le fichier de relevé de rémunération mensuel - AT",
		"tabIntroTitle": "Bienvenue dans l'assistant de traitement des décomptes de rémunération mensuels - AT",
		"tabProcCaption": "Traitement",
		"tabResultsCaption": "Rapport",
		"temSeleccionarDatas": "Vous devez sélectionner au moins une date.",
		"temSeleccionarUmMesParaProcessar": "Vous devez sélectionner un mois pour traiter",
		"temSeleccionarUmaEmpresa": "Vous devez sélectionner au moins une entreprise pour continuer.",
		"viewInfoDadosAutoTab": "Données automatiques",
		"viewInfoDadosGlobaisContribObrigTab": "Cotisations obligatoires",
		"viewInfoDadosGlobaisControloTab": "Contrôle",
		"viewInfoDadosGlobaisQuotizSindicaisTab": "Cotisations syndicales",
		"viewInfoDadosGlobaisRendimAnosAntTab": "Revenus des années précédentes",
		"viewInfoDadosGlobaisRetencaoIRSTab": "Retenue IRS",
		"viewInfoDadosGlobaisRetencaoSobretaxaTab": "Retenue de majoration",
		"viewInfoDadosGlobaisTab": "Données mondiales",
		"viewInfoDadosGlobaisValorRendimentosTab": "Valeur du revenu",
		"viewInfoDadosManuaisTab": "Données manuelles",
		"viewInfoModalTitle": "Année : {{year}} Mois : {{month}} - Affiche les informations sur le salaire",
		"viewResultBtn": "Voir",
		"viewSends": "Preuve/Doc.",
		"vizProcExistentes": "Afficher les processus existants",
		"fields": {
			"abonosOutros": "Autres allocations",
			"abonosRendimentosNaoSujeitos": "Revenu non sujet",
			"abonosRendimentosSujeitos": "Sujet au revenu",
			"anoAntRend": "Revenu de l'année précédente",
			"codEmp": "code emp.",
			"contribuicosObrigatoriasNIF1": "Contribution",
			"contribuicosObrigatoriasNIF2": "Contribution",
			"contribuicosObrigatoriasNIF3": "Contribution",
			"contribuicosObrigatoriasValores": "Contribution",
			"dataProcessamento": "Date de traitement",
			"descObrigSSocial": "Contribution",
			"descontosContribuicoesObrigatorias": "Cotisations obligatoires",
			"descontosIRS": "Retenue IRS",
			"descontosOutros": "Autres réductions",
			"descontosSindicatos": "Les syndicats",
			"descontosSobretaxa": "Retenue de majoration",
			"descrInsManual": "Type d'insertion",
			"descricaoAbono": "Desc.",
			"dmrAno": "Année de procédure",
			"dmrMes": "Mois de procédure.",
			"empresa": "Entreprise",
			"enviaparaDMR": "Envoyer à DMR",
			"irsRetido": "Retenue IRS",
			"liquidoCalculado": "Liquide calculé",
			"liquidoVencimentos": "Maturités nettes",
			"localObtencaoRend": "Localisation des revenus",
			"mesAno": "Mois/Année",
			"nCodAbdes": "Allocation/remise",
			"nProcessamento": "Processus n°",
			"nif": "Numéro de contribuable",
			"nome": "Nom",
			"nomeEmpresa": "Nom de l'entreprise",
			"origemRendiDescr": "Origine",
			"outrosAbonos": "Autres allocations",
			"outrosDescontos": "Autres réductions",
			"queFazer": "Comment résoudre",
			"quotizSindical": "Cotisations syndicales",
			"quotizacaoSindical": "Cotisations syndicales",
			"rendAnoAnteriorAno": "Rendement",
			"rendAnoAnteriorValores": "Rendement",
			"rendNaoSujeitos": "Rendement",
			"rendSujeitos": "Rendement",
			"rendimentodoano": "Rendement",
			"rendimentosAnoAnterior": "Valeur du revenu (années précédentes)",
			"retencaoIRS": "Retenue IRS",
			"retencaoSobretaxa": "Retenue de majoration",
			"selected": "Sel",
			"sobretaxaExtraord": "Retenues de supplément",
			"statusImage": "État",
			"tipRendiment": "Conseil.",
			"tipoProcessamento": "Type de processus.",
			"tipoProcessamentoDescricao": "Description",
			"tipoRendimento": "Type de rendement.",
			"totalDMRATRemun": "Total - Rémunération sec.",
			"totalValorContribuicoesObrigatorias": "Total - Cotisations obligatoires",
			"totalValorQuotizacaoSindicais": "Total - Valeur des cotisations syndicales",
			"totalValorRendimentos": "Total - Valeur du revenu",
			"totalValorRetencaoIRS": "Total – Retenue IRS",
			"totalValorRetencaoSobretaxa": "Total - Montant de retenue du supplément",
			"totalVencRemun": "Total - Rémunération Proc.",
			"valorInciden": "Valeur du revenu",
			"valorNaoSujeito": "Rendement",
			"wsEstadoFicheiroStr": "Statut d'expédition AT"
		},
		"btn": {
			"ordenar": "Trier par : {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "Code de l'entreprise",
				"sortEmpresasByName": "Nom de l'entreprise"
			}
		}
	},
	"proceSalarios": {
		"tabIntroCaption": "Introduction",
		"tabIntroTitle": "Bienvenue dans l'assistant de traitement de la paie",
		"tabIntroSubTitle": "L'assistant vous aidera à traiter les salaires",
		"tabEmpresasCaption": "Entreprises",
		"tabConfigCaption": "Paramètres",
		"tabProcCaption": "Traitement",
		"tabResultsCaption": "Rapport",
		"jobTimeoutModalTitle": "Tâche",
		"jobTimeoutModalMessage": "La tâche a expiré.",
		"processar": "Processus",
		"processingMessage": "Traitement en cours, veuillez patienter un instant.",
		"modalRemoveSelEmpConfirmMsg": "Etes-vous sûr de vouloir supprimer le(s) employé(s) sélectionné(s) de ce traitement ?",
		"comecar": "Pour commencer",
		"tipoProcessamentoGerarTitle": "Type de traitement à générer",
		"tipoProceMensalCaption": "Traitement mensuel",
		"tipoProceFeriasCaption": "Traitement des vacances",
		"tipoProceNatalCaption": "Traitement natal",
		"dataParaProcessamentoCaption": "Date de traitement",
		"dia": "Jour",
		"podeReprocessarSalariosCaption": "Retraitement du salaire (Avec traitement dans le mois)",
		"podeReprocessarSalariosHelpText1": "Vous ne pouvez retraiter que les salaires",
		"podeReprocessarSalariosHelpText2": "- S'il n'y a qu'un seul Traitement dans le mois sélectionné.",
		"podeReprocessarSalariosHelpText3": "- Le type de traitement existant ne peut pas être modifié.",
		"procExistentesTitle": "Proc.",
		"processModalMessage": "Traitement en cours... veuillez patienter un instant.",
		"estimativa": "j'ai estimé",
		"avisosErros": "Avertissements/Erreurs",
		"fields": {
			"selected": "Sel",
			"mesAno": "Mois/Année",
			"nEmpresa": "Entreprise",
			"nomeEmpresa": "Nom de l'entreprise",
			"dataProce": "Date de traitement",
			"dADataProc": "Date de début",
			"ateDataProc": "Date de fin",
			"nDiasUteis": "Nombre de jours ouvrables",
			"descricao": "Description",
			"nDiasTotalMes": "Nombre total de jours par mois",
			"calendar": "Calendrier",
			"tipoProcessamento": "Type de traitement",
			"codEmp": "Code.",
			"nome": "Nom",
			"nBIdentidade": "Numéro BI / Carte de citoyen",
			"nContribuinte": "FNI",
			"dataNascimento": "Date de naissance",
			"strEstadoProcEmpregado": "État",
			"nProcessamento": "Processus n°",
			"nRecibo": "Numéro de reçu.",
			"dataProcessamento": "Date de traitement",
			"identificacaoEmpregado": "Employé",
			"dataInicoProc": "Date de début",
			"dataFimProce": "Date de fin",
			"nDiasParaProcessamen": "Nombre de jours ouvrables pour le traitement.",
			"anoParaSegSocial": "Année (SS)",
			"mesParaSegSocial": "Mois (SS)",
			"proceImportado": "Proc.",
			"tipoProcessamentoDescricao": "Type de processus."
		},
		"temSeleccionarUmMesParaProcessar": "Vous devez sélectionner un mois pour traiter",
		"temSeleccionarPeloMenosTipoProcessamento": "Vous devez sélectionner au moins un type de traitement",
		"promptViewResultsTitle": "Traitement de la paie",
		"promptViewResultsMessage": "Le traitement de la paie est terminé.",
		"viewResultBtn": "Voir",
		"initNewProc": "Commencer nouveau",
		"mostrarSoComErros": "Afficher uniquement avec les erreurs",
		"segSocialRelativaTitle": "La sécurité sociale relative aux",
		"novoProcessamentoTitle": "Nouveau traitement",
		"empregadosTitle": "Employés",
		"selectAll": "Tout sélectionner",
		"unSelectAll": "Effacer la sélection",
		"temSeleccionarUmEmpregado": "Vous devez sélectionner au moins un employé à traiter.",
		"singleProcDescDef": "Proc.",
		"temSeleccionarUmaEmpresa": "Vous devez sélectionner au moins une entreprise pour continuer.",
		"addEmpregado": "Ajouter des employés",
		"removeSelEmpregado": "Supprimer les employés sélectionnés",
		"addSelected": "Ajouter la sélection",
		"pesqEmpregados": "Recherche d'employés",
		"reprocEmpregadoTooltip": "Retraiter l'employé",
		"reprocEncargosTooltip": "Frais de retraitement",
		"confirmReProcMsg": "Êtes-vous sûr de vouloir retraiter l'employé {{codEmp}} de ce traitement ?",
		"confirmReProcEncargoMsg": "Êtes-vous sûr de vouloir retraiter les frais de l'employé {{codEmp}} - {{name}} pour ce traitement ?",
		"confirmReProcSelMsg": "Etes-vous sûr de vouloir retraiter les salariés sélectionnés à partir de ce traitement ?",
		"empregadoReprocessado": "L'employé {{codEmp}} a été retraité.",
		"encargosReprocessados": "Les frais {{codEmp}} des employés ont été retraités.",
		"empregadoSelReprocessado": "Les salariés sélectionnés ont été retraités.",
		"deleteProcess": "Éliminer le traitement",
		"empregadoTemTransfBancariaPrompt": "Attention, l'employé {{codEmp}} - {{nome}} a déjà créé un virement bancaire pour ce traitement.",
		"naoPodeReprocExisteTransfBancaria": "Vous ne pouvez pas retraiter le traitement des employés {{codEmp}} - {{name}} il y a un virement bancaire.",
		"empregadoTemFichSegSocialPrompt": "Attention, le salarié {{codEmp}} - {{nome}} a déjà créé un dossier de sécurité sociale.",
		"naoPodeReprocExisteFichSegSocial": "Vous ne pouvez pas retraiter le traitement du salarié {{codEmp}} - {{nome}}, le dossier de sécurité sociale est déjà créé.",
		"existemEmpregadosQueNaoPodemSerReProc": "Il y a des salariés qui ne peuvent pas être retraités : <br/><br/> {{content}} <br/><br/>Voulez-vous les ignorer et continuer avec ceux qui peuvent être retraités ?",
		"naoPodeReprocIntegContab": "Vous ne pouvez pas retraiter le traitement.",
		"messages": {
			"empEliminadoSucesso": "Employé supprimé avec succès !",
			"empAdicionadoSucesso": "Employé ajouté avec succès !",
			"naoContemEmpSelecionado": "Vous devez sélectionner au moins un employé!"
		},
		"btn": {
			"ordenar": "Trier par : {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "Code de l'entreprise",
				"sortEmpresasByName": "Nom de l'entreprise"
			}
		},
		"consultarRecibo": "Consulter les reçus",
		"consultarReciboEmpregado": "Consulter les reçus des employés",
		"existemCamposComErros": "Il y a des champs avec des erreurs.",
		"promptDeleteProcTitle": "Éliminer le traitement",
		"promptDeleteProcMessage": "Êtes-vous sûr de vouloir éliminer le traitement ?",
		"reprocSelected": "Répétition."
	},
	"proceSegSocial": {
		"tabIntroCaption": "Introduction",
		"tabIntroTitle": "Bienvenue dans l'assistant de traitement de la sécurité sociale",
		"tabIntroSubTitle": "L'assistant vous aidera à générer le dossier de sécurité sociale",
		"tabEmpresasCaption": "Entreprises",
		"tabConfigCaption": "Paramètres",
		"tabProcCaption": "Traitement",
		"tabResultsCaption": "Rapport",
		"temSeleccionarUmaEmpresa": "Vous devez sélectionner au moins une entreprise pour continuer.",
		"temSeleccionarUmMesParaProcessar": "Vous devez sélectionner un mois pour traiter",
		"vizProcExistentes": "Afficher les processus existants",
		"procAlertMsg1": "Par défaut, il générera sec.",
		"procAlertMsg2": "Si vous souhaitez vérifier les processus existants, sélectionnez le champ ci-dessous",
		"temSeleccionarDatas": "Vous devez sélectionner au moins une date.",
		"procExistentesReprocMessage": "Il existe des entreprises dont les traitements sont générés pour la sécurité sociale.",
		"reprocEmpSelected": "Oui",
		"loadProcSavedAndGenNewsForNotProccessed": "Non",
		"geracaoImpressao": "Génération et impression",
		"promptViewResultsTitle": "Traitement de la sécurité sociale",
		"promptViewResultsMessage": "Le traitement de la sécurité sociale est terminé.",
		"viewResultBtn": "Voir",
		"initNewProc": "Commencer nouveau",
		"erros": "Erreurs",
		"printMapPerEmp": "Imprimer la carte par employé",
		"printSummary": "Imprimer le résumé",
		"genDRIFile": "Générer un fichier pour DRI",
		"sendFile": "Envoyer le fichier WebService",
		"viewSends": "Consulter l'expédition WebService",
		"printMapPerEmpModalTitle": "Impression de cartes de sécurité sociale",
		"printSummaryModalTitle": "Impression des cartes récapitulatives des dossiers de sécurité sociale",
		"semAlteracaoParaGerar": "Le système n'a pas généré de fichier, car celui-ci avait déjà été généré précédemment.",
		"viewInfoSSModalTitle": "Année : {{year}} Mois : {{month}} - Affiche les informations sur la sécurité sociale",
		"codPTitle": "CodeP",
		"codITitle": "Code I",
		"nDiasCodPLabel": "Code P - Nombre de jours",
		"nDias2Label": "Code 2 - Nombre de jours",
		"nDiasILabel": "Code I - Nombre de jours",
		"valLabel": "Valeur",
		"valRLabel": "Code R - Valeur",
		"valFLabel": "Code F - Valeur",
		"valNLabel": "Code N - Valeur",
		"valALabel": "Code A - Valeur",
		"valBLabel": "Code B - Valeur",
		"valCLabel": "Code C - Valeur",
		"valDLabel": "Code D - Valeur",
		"valHLabel": "Code H - Valeur",
		"valMLabel": "Code M - Valeur",
		"valOLabel": "Code O - Valeur",
		"valSLabel": "Code S - Valeur",
		"valTLabel": "Code T - Valeur",
		"valXLabel": "Code X - Valeur",
		"val6Label": "Code 6 - Valeur",
		"val2Label": "Code 2 - Valeur",
		"valILabel": "Code I - Valeur",
		"valPLabel": "Code P - Valeur",
		"infoSSSaved": "Données enregistrées avec succès.",
		"promptInfoSSSave": "Vous modifierez les données du dossier à transmettre à la sécurité sociale.",
		"nibADebitar": "IBAN pour débiter",
		"obterPdf": "Obtenir le PDF",
		"promptAlertModalTitle": "Attention!",
		"promptSendModalMessage": "Vous enverrez les dossiers à la sécurité sociale via WebService.",
		"enviadoComSuccess": "Le(s) fichier(s) ont été envoyés avec succès.",
		"enviadoComSuccesso": "Le(s) fichier(s) ont été envoyés avec succès.",
		"enviadoComErros": "Il y avait des erreurs dans les fichiers envoyés.",
		"jobTimeoutModalTitle": "Tâche",
		"jobTimeoutModalMessage": "La tâche a expiré.",
		"comecar": "Pour commencer",
		"fields": {
			"selected": "Sel",
			"mesAno": "Mois/Année",
			"tipoProcessamento": "Type de processus.",
			"tipoProcessamentoDescricao": "Description",
			"dataProcessamento": "Date de traitement",
			"empresa": "Entreprise",
			"nomeEmpresa": "Nom de l'entreprise",
			"ssAno": "Année lundi.",
			"ssMes": "Mois Lundi",
			"fichGeradoPodeReprocessarFicheiro": "Peut retraiter",
			"estadoFicheiroStr": "Statut du fichier",
			"ssInfoEstadoProcessamentoStr": "Statut SS",
			"totalRemuneracaoSegSocial": "Total - Rémunération",
			"totalContribuicaoSegSocial": "Total - Cotisation sec.",
			"totalRemuneracaoVencimento": "Total - Rémunération",
			"queFazer": "Comment résoudre",
			"codEmp": "code emp.",
			"nome": "Nom",
			"numSS": "Numéro SS.",
			"totalSegSocialRemun": "Total - Rémunération sec.",
			"totalVencRemun": "Total - Rémunération Proc.",
			"taxaAAplicar": "Taxes (%)"
		},
		"configWsSSTooltip": "Configuration du WebService de la Sécurité Sociale",
		"btn": {
			"ordenar": "Trier par : {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "Code de l'entreprise",
				"sortEmpresasByName": "Nom de l'entreprise"
			}
		}
	},
	"processos": {
		"title_detail": "Processus {{id}}",
		"title_new": "Nouveau processus",
		"title_plural": "Processus",
		"pesquisa": "A chercher",
		"saved": "Processus {{id}}, enregistré avec succès.",
		"error": "Le processus {{id}} ne peut pas être enregistré.",
		"deleted": "Processus {{id}}, supprimé avec succès",
		"fields": {
			"nRefProcesso": "Processus",
			"nRefProcessoPlaceholder": "Numéro de processus",
			"nome": "Nom",
			"nomePlaceholder": "Nom du processus",
			"nCCusto": "N° C. Coût",
			"nomeCCusto": "Nom C. Coût",
			"nomeCCustoPlaceholder": "centre de coûts",
			"nClifo": "Client",
			"nClifoPlaceholder": "Compte client",
			"nomeClifo": "Nom du client",
			"nomeClifoPlaceholder": "Nom du client",
			"terminado": "Fini"
		}
	},
	"procseguradorafolhaferias": {
		"steps": {
			"introducao": "Introduction",
			"empresas": "Entreprises",
			"configuracao": "Paramètres",
			"relatorio": "Rapport",
			"title": {
				"titleintro": "Bienvenue dans l'Assistant de traitement de la paie de vacances d'assurance",
				"subtitleintro": "L'Assistant vous aidera à générer le fichier Feuille de vacances pour la compagnie d'assurance",
				"titleempresas": "Entreprises avec traitement",
				"subtitleempresas": "Choisissez les entreprises pour générer le fichier",
				"titleconfig": "Dates de traitement",
				"subtitleconfig": "Sélection des dates de traitement des échéances",
				"titlerelatorio": "Génération et impression de feuilles de vacances pour les compagnies d'assurance",
				"errossegSocial": "Erreurs des compagnies d'assurance",
				"erros": "Erreurs"
			}
		},
		"fields": {
			"selected": "Sel.",
			"mesAno": "Mois/Année",
			"tipoProcessamento": "Type de processus.",
			"tipoProcessamentoDescricao": "Description",
			"dataProcessamento": "Date de traitement",
			"empresa": "Entreprise",
			"nomeEmpresa": "Nom de l'entreprise",
			"temFichGerado": "Il y a un fichier généré",
			"nempresa": "Entreprise",
			"queFazer": "Comment résoudre",
			"totalRemuneracaoSegSocial": "Total - Rémunération sec.",
			"email": {
				"caption": "E-mail",
				"placeholder": "Email pour envoyer le fichier"
			}
		},
		"messages": {
			"temSeleccionarDatas": "Vous devez sélectionner au moins une date.",
			"invalidStepEmp": "Vous devez sélectionner au moins une entreprise à traiter.",
			"invalidStepConf": "Il n'y a pas de traitement pour l'année/le mois sélectionné.",
			"empresascomerros": "Il y a des entreprises qui font des erreurs!",
			"email": {
				"enviado": "E-mail envoyé avec succès !",
				"naoenviado": "L'e-mail n'a pas été envoyé."
			}
		},
		"prompt": {
			"confMessage": "Il existe des entreprises dont le traitement est généré avec la feuille de vacances de la compagnie d'assurance.",
			"geraFich": "Il générera le fichier de feuille de vacances de la compagnie d'assurance."
		},
		"btn": {
			"printmap": "Imprimer la carte par employé",
			"genfichsegu": "Générer un fichier pour la compagnie d'assurance",
			"previsualizar": "Aperçu",
			"ordenar": "Trier par : {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "Code de l'entreprise",
				"sortEmpresasByName": "Nom de l'entreprise"
			},
			"enviaEmail": "Envoyer le fichier par email"
		},
		"modal": {
			"impressaomapas": "Impression de feuilles de vacances pour les compagnies d'assurance"
		}
	},
	"profissao": {
		"title_detail": "Métier {{id}}",
		"title_new": "Profession",
		"title_plural": "Métiers",
		"pesquisa": "A chercher",
		"deleted": "Profession supprimée avec succès.",
		"saved": "Profession {{id}}, enregistrée avec succès.",
		"error": "Profession {{id}}, ne peut pas être enregistrée.",
		"fields": {
			"nProfissao": "Code des métiers",
			"designaBreve": "Désignation",
			"designaCompl": "Désignation complète",
			"codClassNaCProf": "Code national des professions"
		}
	},
	"protecaoPassword": {
		"modal": {
			"save": "Le type de mot de passe choisi a été enregistré avec succès",
			"tipoPassword": "Protégez-vous par mot de passe les reçus à envoyer par e-mail ?",
			"title": "Sélectionnez le type de protection"
		}
	},
	"qivaas": {
		"title_detail": "Champ de déclaration de TVA annuelle {{id}}",
		"title_new": "Nouveau Champ de la déclaration annuelle de TVA",
		"title_plural": "Champs de déclaration de TVA annuelle",
		"pesquisa": "A chercher",
		"saved": "Champ de déclaration de TVA annuelle {{id}}, enregistré avec succès.",
		"error": "Le champ de déclaration de TVA annuelle {{id}} ne peut pas être sauvegardé.",
		"deleted": "Champ de déclaration de TVA annuelle {{id}}, supprimé avec succès.",
		"fields": {
			"nCampo": "Code de champ",
			"nCampoPlaceholder": "Code champ déclaration TVA annuelle",
			"nome": "Nom",
			"nomePlaceholder": "Nom du champ de la déclaration annuelle de TVA",
			"nQuadro": "numéro de cadre.",
			"nQuadroPlaceholder": "Numéro de table du champ de déclaration TVA annuelle"
		}
	},
	"qivaps": {
		"title_detail": "Champ de déclaration de TVA périodique {{id}}",
		"title_new": "Nouveau champ de déclaration périodique de TVA",
		"title_plural": "Champs de déclaration de TVA périodique",
		"pesquisa": "A chercher",
		"saved": "Champ de déclaration de TVA périodique {{id}}, enregistré avec succès.",
		"error": "Le champ de déclaration de TVA périodique {{id}} ne peut pas être sauvegardé.",
		"deleted": "Champ de déclaration de TVA périodique {{id}}, supprimé avec succès.",
		"fields": {
			"nCampo": "Code de champ",
			"nCampoPlaceholder": "Code champ de la déclaration périodique de TVA",
			"nomeCampo": "Nom",
			"nomeCampoPlaceholder": "Nom du champ de la déclaration périodique de TVA",
			"nQuadro": "numéro de cadre.",
			"nQuadroPlaceholder": "N° de table du champ de la déclaration périodique de TVA",
			"nCampoOficial": "Code de terrain officiel",
			"nCampoOficialPlaceholder": "Code du champ de la déclaration périodique officielle de TVA"
		}
	},
	"qpapa": {
		"title_detail": "Association des employeurs {{id}}",
		"title_new": "Nouvelle association d'employeurs",
		"title_plural": "Associations d'employeurs",
		"pesquisa": "A chercher",
		"saved": "Association d'employeurs {{id}}, enregistrée avec succès.",
		"error": "L'association d'employeurs {{id}} ne peut pas être enregistrée.",
		"deleted": "Association d'employeurs {{id}}, supprimée avec succès",
		"fields": {
			"codAssocPatronal": "Code.",
			"codAssocPatronalPlaceholder": "Code de l'association patronale",
			"nomeAssocPatronal": "Nom",
			"nomeAssocPatronalPlaceholder": "Nom",
			"morada": "Ménage",
			"moradaPlaceholder": "Ménage",
			"codPostal": "Code postal",
			"codPostalPlaceholder": "Code postal",
			"localidade": "Emplacement",
			"localidadePlaceholder": "Emplacement"
		}
	},
	"qpcae": {
		"title_detail": "Code d'activité économique {{id}}",
		"title_new": "Nouvelle activité économique",
		"title_plural": "Codes d'activité économique",
		"pesquisa": "A chercher",
		"saved": "Activité économique {{id}}, enregistrée avec succès.",
		"error": "L'activité économique {{id}} ne peut pas être sauvegardée.",
		"deleted": "Activité économique {{id}}, supprimée avec succès",
		"fields": {
			"codcae": "Code.",
			"codcaePlaceholder": "Code CAE",
			"nome": "Nom",
			"nomePlaceholder": "Nom",
			"codSubSec": "Sous-section",
			"codSubSecPlaceholder": "Sous-section"
		}
	},
	"qpcnp": {
		"title_detail": "Métier {{id}}",
		"title_new": "Nouveau métier",
		"title_plural": "Métiers",
		"pesquisa": "A chercher",
		"saved": "Profession {{id}}, enregistrée avec succès.",
		"error": "Profession {{id}}, ne peut pas être enregistrée.",
		"deleted": "Profession {{id}}, supprimée avec succès.",
		"fields": {
			"codNacProfissao": "Code",
			"nome": "Nom",
			"tipo": "Taper"
		}
	},
	"qpdcf": {
		"title_detail": "District/Comté/Code paroissial {{id}}",
		"title_new": "Nouveau code de district/comté/paroisse",
		"title_plural": "Codes de district/comté/paroisse",
		"pesquisa": "A chercher",
		"saved": "Code de district/municipalité/paroisse {{id}}, enregistré avec succès.",
		"error": "Code de district/municipalité/paroisse {{id}}, ne peut pas être enregistré.",
		"deleted": "Code de district/comté/paroisse {{id}}, supprimé avec succès",
		"fields": {
			"codDistConcFreg": "Code.",
			"codDistConcFregPlaceholder": "Code du district/comté/paroisse",
			"nome": "Nom",
			"nomePlaceholder": "Nom",
			"codDistrito": "Code de district",
			"codDistritoPlaceholder": "Code de district",
			"codConcelho": "code du comté",
			"codConcelhoPlaceholder": "code du comté",
			"codFreguesia": "Code paroissial",
			"codFreguesiaPlaceholder": "Code paroissial",
			"codServicoFinancas": "Code du service financier",
			"codServicoFinancasPlaceholder": "Code du service financier",
			"nomeServicoFinancas": "Nom du service financier",
			"nomeServicoFinancasPlaceholder": "Nom du service financier"
		}
	},
	"qpdur": {
		"title_detail": "Durée du tableau personnel {{id}}",
		"title_new": "Durée du nouveau cadre personnel",
		"title_plural": "Durée des tableaux personnels",
		"pesquisa": "A chercher",
		"saved": "Durée du profil personnel {{id}}, enregistrée avec succès.",
		"error": "La durée du profil personnel {{id}} ne peut pas être enregistrée.",
		"deleted": "Durée du profil personnel {{id}}, supprimé avec succès.",
		"fields": {
			"codDuracao": "Code de durée",
			"nome": "Nom"
		}
	},
	"qphab": {
		"title_plural": "Qualifications",
		"pesquisa": "A chercher",
		"fields": {
			"codHabilitacao": "Code de qualification",
			"nome": "Nom"
		}
	},
	"qpirt": {
		"title_detail": "Recherche IRCT {{id}}",
		"title_new": "Nouvelle recherche de l'IRCT",
		"title_plural": "IRCT",
		"pesquisa": "A chercher",
		"saved": "Recherche IRCT {{id}}, enregistrée avec succès.",
		"error": "Recherche IRCT {{id}}, ne peut pas être enregistrée.",
		"deleted": "Recherche IRCT {{id}}, supprimée avec succès",
		"fields": {
			"codIRCT": "Code IRCT",
			"nome": "Nom de l'IRCT"
		}
	},
	"qpisp": {
		"title_detail": "Code FAI {{id}}",
		"title_plural": "Codes FAI",
		"pesquisa": "A chercher",
		"fields": {
			"codISP": "Code FAI",
			"nome": "Nom"
		}
	},
	"qpmte": {
		"title_detail": "Raison d'entrée de l'employé {{id}}",
		"title_new": "Nouveau motif d'entrée des employés",
		"title_plural": "Raisons de l’entrée des employés",
		"pesquisa": "A chercher",
		"saved": "Raison d'entrée de l'employé {{id}}, enregistrée avec succès.",
		"error": "Le motif d'entrée de l'employé {{id}} ne peut pas être enregistré.",
		"deleted": "Raison d'entrée de l'employé {{id}}, supprimée avec succès",
		"fields": {
			"codmte": "Code MTE",
			"nome": "Nom"
		}
	},
	"qpmts": {
		"title_detail": "Raison du départ de l'employé {{id}}",
		"title_new": "Nouvelle raison de départ des employés",
		"title_plural": "Raisons du départ d’un employé",
		"pesquisa": "A chercher",
		"saved": "Raison du départ de l'employé {{id}}, enregistrée avec succès.",
		"error": "La raison du départ de l'employé {{id}} ne peut pas être enregistrée.",
		"deleted": "Raison du départ de l'employé {{id}}, supprimée avec succès",
		"fields": {
			"codmts": "Code MTS",
			"nome": "Nom"
		}
	},
	"qpnj": {
		"title_detail": "Nature juridique {{id}}",
		"title_new": "Nouvelle nature juridique",
		"title_plural": "Natures juridiques",
		"pesquisa": "A chercher",
		"saved": "Nature juridique {{id}}, enregistrée avec succès.",
		"error": "Nature juridique {{id}}, ne peut pas être enregistrée.",
		"deleted": "Nature juridique {{id}}, supprimé avec succès",
		"fields": {
			"codNatJuridica": "Code.",
			"codNatJuridicaPlaceholder": "Code de nature juridique",
			"nome": "Nom",
			"nomePlaceholder": "Nom"
		}
	},
	"qppaises": {
		"title_detail": "Pays {{id}}",
		"title_new": "Nouveau pays",
		"title_plural": "Pays du personnel",
		"pesquisa": "A chercher",
		"saved": "Pays {{id}}, enregistré avec succès.",
		"error": "Pays {{id}}, ne peut pas être enregistré.",
		"deleted": "Pays {{id}}, supprimé avec succès.",
		"fields": {
			"codPais": "Code.",
			"name": "Nom",
			"abrev": "Abréviation."
		}
	},
	"qpsit": {
		"title_detail": "Situation {{id}}",
		"title_new": "Nouvelle situation",
		"title_plural": "Situations",
		"pesquisa": "chercher",
		"saved": "Situation {{id}}, enregistrée avec succès.",
		"error": "La situation {{id}} ne peut pas être enregistrée.",
		"deleted": "Statut {{id}}, supprimé avec succès",
		"fields": {
			"codsituacao": "Code d'état",
			"nome": "Nom"
		}
	},
	"qptco": {
		"title_detail": "Type de contrat de personnel {{id}}",
		"title_new": "Nouveau type de contrat de personnel",
		"title_plural": "Type de contrat de personnel",
		"pesquisa": "A chercher",
		"saved": "Type de contrat de profil personnel {{id}}, enregistré avec succès",
		"error": "Le type de contrat de profil personnel {{id}} ne peut pas être enregistré.",
		"deleted": "Type de contrat de profil personnel {{id}}, supprimé avec succès",
		"fields": {
			"codTContrato": "Type de contrat code du personnel",
			"nome": "Nom"
		}
	},
	"rastreabilidade": {
		"title": "Traçabilité",
		"export": {
			"svg": "Exporter SVG",
			"jpg": "Exporter JPG",
			"png": "Exporter PNG"
		}
	},
	"recaptcha": {
		"branding": {
			"1": "Ce site est protégé par reCAPTCHA et s'applique à",
			"2": "de Google.",
			"privacy": "politique de confidentialité",
			"and": "et le",
			"terms": "Conditions d'utilisation"
		},
		"v2modal": {
			"title": "Veuillez vérifier que vous n'êtes pas un robot"
		}
	},
	"reciboimprimir": {
		"label": {
			"emailcliente": "E-mail du client",
			"enviar": "Pour envoyer"
		},
		"email": {
			"required": "Pour envoyer, merci de remplir le champ \"<em>Email client",
			"emailEnviado": "E-mail envoyé à {{email}}",
			"emailErro": "Erreur lors de l'envoi de l'e-mail à {{email}}."
		}
	},
	"recibos": {
		"title_new": "Nouveau reçu",
		"title_detail": "Nouveau reçu {{id}}",
		"title_plural": "Recettes",
		"pesquisa": "A chercher",
		"saved": "Reçu enregistré avec succès.",
		"error": "Le reçu ne peut pas être enregistré.",
		"fields": {
			"extPocCabID": "Identifiant.",
			"numero": "Numéro de reçu.",
			"nome": "Client",
			"nconta": "Compte",
			"stampUpdate": "Dernier.",
			"origem": "Origine du document",
			"descricao": "Description",
			"abreviaturaMoeda": "Pièce de monnaie",
			"deData": "Date de",
			"nDocExterno": "Doc n°"
		},
		"text": {
			"autoDistribute": "Distribuer automatiquement la valeur",
			"amountToReceive": "Montant à recevoir"
		},
		"recibo": {
			"title": "Reçu",
			"nCaixaAux": "Mode de paiement",
			"nomeCaixaAux": "Le nom signifie paiement",
			"tipoCaixaAux": "Type de demi-paiement",
			"valorNCaixa": "Valeur",
			"totalCaixa": "Livré",
			"troco": "Changement",
			"cliente": "Client",
			"data": "Date",
			"percDescSobreValorIVA": "Remise sur la valeur de la TVA",
			"cab": {
				"extPocCabID": "identifiant d'en-tête",
				"numero": "Nombre",
				"nConta": "Compte",
				"nome": "Nom",
				"nif": "FNI",
				"valor": "Valeur",
				"valorPlaceHolder": "Montant à recevoir",
				"stampUpdate": "Changer de date",
				"totalDescontos": "Remises complètes",
				"totalReceber": "Total à recevoir",
				"totalRecebido": "Total reçu",
				"temRetencao": "A une rétention",
				"totalRetencao": "Rétention complète",
				"descricao": "Description",
				"resumo": "Résumé",
				"valorEntregueCredito": "Total livré à crédit"
			},
			"linhas": {
				"nlanc": "identifiant de ligne",
				"tipoDocumento": "Type de document",
				"numeroDocumento": "Document",
				"dataVencimento": "Date d'expiration",
				"valor": "Valeur",
				"valorPorReceber": "Valeur à recevoir",
				"percDesconto": "% Rabais",
				"valorDesconto": "Valeur de remise",
				"valorRecebido": "Montant reçu",
				"valorIVA": "Valeur TVA",
				"montanteAReter": "Rétention",
				"montanteRetido": "Valeur de rétention",
				"cambio": "Échange",
				"cambioOrigem": "Taux de change d'origine",
				"cambioPagam": "Paiement d'échange",
				"dataDoc": "Date du document.",
				"dataDocExterno": "Date du document.",
				"nDocExterno": "Doc n°",
				"descricao": "Description",
				"valorDoc": "Valeur du document"
			}
		},
		"saveModal": {
			"title": "Enregistrer le reçu",
			"paymentMeans": "Modes de paiement",
			"paymentMean": "Mode de paiement",
			"paymentValue": "Valeur livrée",
			"payInFull": "Payer intégralement",
			"clearValue": "Effacer la valeur",
			"document": "Document",
			"save": "Sauvegarder",
			"saveCheck": "Enregistrer et afficher le PDF"
		},
		"imprimirModal": {
			"title": "Reçu - {{nom du compte}}",
			"fields": {
				"numeroDeVias": "Nombre de voies",
				"emailCliente": "E-mail",
				"relatorio": "Rapport"
			},
			"actions": {
				"send": "Pour envoyer"
			},
			"placeholder": {
				"email": "\"mail@exemple.com\" ou \"mail@exemple.com; mail2@exemple.com\""
			}
		},
		"info": {
			"anular": "Reçu annulé avec succès !",
			"anularRecibo": "Annuler le reçu",
			"temCertezaAnularRecibo": "Êtes-vous sûr de vouloir supprimer le reçu ?",
			"naoefetuadoCG": "Le reçu ne peut pas être annulé car il n'a pas été effectué dans CentralGest Cloud",
			"semMovimentos": "Pas de mouvements ouverts !",
			"vencido": "En retard",
			"naoVencido": "Non expiré",
			"aReceber": "Recevable"
		},
		"erros": {
			"notselected": "Aucun reçu sélectionné !",
			"anular": "Erreur lors de l'annulation du reçu !",
			"valorDescontoMaiorValor": "Valeur de la remise supérieure au montant reçu",
			"percentagemDescontoMaior100": "Le pourcentage de remise ne peut pas dépasser 100 %",
			"invalidValorAPagar": "La valeur délivrée ({{valorEntregue}}) doit être égale ou supérieure à la valeur du document ({{valorDocumento}}).",
			"moedasdiferentesnosrecibos": "Il n'est pas possible d'avoir des devises différentes dans les réceptions à comptabiliser",
			"errodesconhecido": "Erreur inconnue",
			"cambiocomvalorerrado": "Mauvais taux de change",
			"invalidHeaderDate": "La date de réception ne peut pas être supérieure à 1 an à compter de la date du jour",
			"valorUltrapassado": "Le montant à distribuer a été dépassé",
			"faltaDistribuirValor": "Vous devez distribuer la totalité du montant inscrit"
		},
		"btn": {
			"receberTudo": "Recevez tout",
			"limpaTudo": "Effacer toutes les valeurs reçues",
			"distribuirValores": "Distribuer de la valeur",
			"limpaValor": "Effacer le montant reçu"
		},
		"modalDistribuir": {
			"tile": "Distribue de la valeur",
			"automatico": "Automatique",
			"manual": "Manuel",
			"tooltip": {
				"automatico": "Triés du plus ancien au plus récent.",
				"manual": "Permet de distribuer jusqu'à ce que la valeur indiquée soit atteinte."
			}
		}
	},
	"recibosvencimentos": {
		"todos": "Tous les collaborateurs",
		"colaborador": "Collaborateur",
		"gravado": "Enregistré",
		"reciboimpresso": "Reçu imprimé",
		"processadosegsocial": "Traité en sec.",
		"integradocontabilidade": "Intégré à la comptabilité",
		"mostraGrafico": "Affiche un graphique à la réception",
		"email": {
			"emailEnviado": "E-mail envoyé avec succès !",
			"emailNaoEnviado": "Une erreur s'est produite lors de l'envoi de l'e-mail.",
			"insiraemail": "Vous devez saisir un email."
		},
		"modal": {
			"enviaremail": "E-mail",
			"byEmailAlternativo": "E-mail alternatif",
			"emailAlternativo": "E-mail"
		}
	},
	"reconstrucaoacumulados": {
		"title": "Reconstruction accumulée",
		"pesquisa": "A chercher",
		"steps": {
			"info": "Début de la reconstruction des actifs accumulés",
			"variables": "Données pour reconstituer le cumul",
			"finish": "Finition"
		},
		"fields": {
			"tipoPoc": "Type de compte",
			"nConta": "Numéro de compte",
			"contaEspecifica": "Uniquement accumulé à partir du compte",
			"todasAsContas": "Tous les comptes"
		},
		"description": {
			"firstParagraph": "Cette opération reconstruira tous les comptes accumulés dans le plan comptable.",
			"secondParagraph": "Cette opération est exclusive.",
			"finishMessage": "Cette opération peut prendre un certain temps, pour continuer, appuyez sur Terminer.",
			"atencao": "Attention!"
		},
		"alerts": {
			"reconstrucaoSucesso": "La reconstruction de l'accumulation s'est terminée avec succès"
		}
	},
	"reembolsosiva": {
		"title_detail": "Remboursement pour la période de déclaration \"{{id}}\"",
		"title_detail_relacao": "Remboursement pour la période de relation \"{{id}}\"",
		"title_new": "Nouveau remboursement",
		"title_plural": "Remboursements de TVA",
		"pesquisa": "A chercher",
		"saved": "Remboursement de TVA avec période de déclaration \"{{id}}\" traité avec succès.",
		"savedAlt": "Remboursement de TVA avec période de déclaration \"{{id}}\" retraité avec succès.",
		"error": "Le remboursement de la TVA avec la période de déclaration « {{id}} » ne peut pas être traité.",
		"errorEmptyPeriodosRelacao": "Aucune période de relation n'est disponible pour la période de relevé sélectionnée",
		"errorClosed": "Attention: la période de déclaration sélectionnée appartient à un exercice comptable déjà terminé",
		"pristineEmptyPeriodosRelacao": "Veuillez sélectionner une période de rapport pour choisir une période de rapport",
		"deleteReembolsoTitle": "Effacer le remboursement de la TVA ?",
		"deleteReembolsoMessage": "Etes-vous sûr de vouloir supprimer le remboursement de TVA avec la période de déclaration « {{declaracao}} » ?",
		"deleteReembolsoComplexMessage": "Êtes-vous sûr de vouloir supprimer le remboursement de TVA avec la période de déclaration « {{declaracao}} » et la période de relation « {{relacao}} » ?",
		"deleteDocumentTitle": "Supprimer le document ?",
		"deleteDocumentMessage": "Êtes-vous sûr de vouloir supprimer le document avec la période de déclaration « {{declaracao}} » et la période de relation « {{relacao}} » ?",
		"process": "Processus",
		"reProcess": "Retraiter le remboursement",
		"disabledHasErrors": "Il n'est pas possible de modifier la période de relation s'il y a des erreurs dans les documents",
		"document": "Numéro de document",
		"documentNoErrors": "Le document ne comporte aucune erreur",
		"documentHasErrors": "Le document contient des erreurs",
		"fields": {
			"periodoDeclaracao": "Période de déclaration",
			"periodoRelacao": "Période de relation",
			"periodosRelacao": "Périodes de relation",
			"nCampoDeducao": "Numéro du champ de déduction",
			"mercado": "Marché",
			"periodo": "Période",
			"nDiario": "Tous les jours",
			"nDocInterno": "Numéro du document",
			"codPais": "Code du pays",
			"abrevFiscal": "Préfixe",
			"nContribuinte": "Numéro de cotisation",
			"dC": "Débit/Crédit",
			"valorTransacaoAbsoluto": "Valeur d'acquisition sans TVA",
			"valorIvaDeduzidoAbsoluto": "Valeur de la TVA déduite",
			"nDocExterno": "Document externe.",
			"dataDocExterno": "Date du document.",
			"nDeclarImportExport": "Liquide non.",
			"nConta": "Numéro de compte",
			"nomeConta": "Nom",
			"anoDocExterno": "Année doc.",
			"mesDocExterno": "Mois du Doc.",
			"all": "Toutes les périodes de relation",
			"error": "Erreur de document"
		},
		"tabs": {
			"clients": "Clients",
			"suppliers": "Fournisseurs",
			"regularizations": "Régularisations",
			"documents": "Documents",
			"relations": "Rapports",
			"clientDoc": "Clients_Documents_{{nreembolso}}",
			"clientRel": "Customers_Relacoes_{{nreembolso}}",
			"suppliersDoc": "Clients_Documents_{{nreembolso}}",
			"suppliersRel": "Customers_Relacoes_{{nreembolso}}"
		},
		"messages": {
			"successDeleteDocument": "Remboursement de TVA supprimé avec succès"
		},
		"clientes": {
			"0": {
				"periodo": "Période"
			},
			"1": {
				"title": "Opérations réalisées avec des clients nationaux",
				"datagrid": {
					"nif": "Numéro d'identification fiscale",
					"valor": "Valeur"
				}
			},
			"3": {
				"title": "Exportation de marchandises",
				"datagrid": {
					"nIdnDeclaracaoExportacao": "Numéro d'identification de la déclaration d'exportation",
					"valor": "Valeur"
				}
			},
			"4": {
				"title": "Opérations réalisées à l'étranger",
				"valor": "Valeur"
			},
			"5": {
				"title": "Autres opérations exonérées ou sans TVA qui donnent droit à déduction",
				"valor": "Valeur"
			},
			"6": {
				"valor": "Valeur"
			},
			"title": "Liste des clients dont les opérations ont donné lieu à déduction fiscale sur la période"
		},
		"fornecedores": {
			"0": {
				"periodo": "Période"
			},
			"1": {
				"campo20": "Champ 20"
			},
			"2": {
				"campo21": "Champ 21"
			},
			"3": {
				"campo22": "Champ 22"
			},
			"4": {
				"campo23": "Champ 23"
			},
			"5": {
				"campo24": "Champ 24"
			},
			"7": {
				"totalValorAquisicoes": "Valeur totale des acquisitions",
				"totalIvaDeduzido": "TVA totale déduite"
			},
			"title": "Liste des fournisseurs dont les opérations ont donné lieu à déduction fiscale sur la période",
			"datagrid": {
				"identificacao": "Identification",
				"periodoEmissao": "Période d'émission",
				"prefixo": "Préfixe",
				"nif": "FNI",
				"nLiquidacao": "Numéro de règlement",
				"valorAquisicoes": "Valeur des acquisitions",
				"ivaDeduzido": "TVA déduite"
			}
		},
		"mercadoControloIva": {
			"notApplicable": "N / A",
			"national": "National",
			"cee": "UE",
			"thirdPartyCountries": "Pays tiers"
		},
		"action": {
			"editDoc": "Modifier le document",
			"deleteLine": "Supprimer la ligne"
		},
		"showDocsWithErrorOnly": "Afficher uniquement les documents comportant des erreurs"
	},
	"refeicoes": {
		"text": {
			"local": "Emplacement",
			"menu": "Menu",
			"marcar": "Pour marquer",
			"desmarcar": "Décochez",
			"refeicoes": "Repas"
		},
		"clonarmodal": {
			"title": "Cloner des menus",
			"fields": {
				"localDestino": "Lieu de destination",
				"dataInicio": "Date de début",
				"dataFim": "Date de fin"
			}
		}
	},
	"regIRC": {
		"data": {
			"geral": "Général",
			"naoSujeicao": "Pas de soumission",
			"isencaoDefinitiva": "Exonération définitive",
			"isencaoTemporaria": "Dispense temporaire",
			"reducaoTaxa": "Réduction de taux",
			"transicaoBenificiosFiscais": "Avantages fiscaux transitoires",
			"transparenciaFiscal": "Transparence fiscale",
			"lucroConsolidado": "Bénéfice consolidé",
			"simplificado": "Simplifié",
			"normal": "Normale",
			"isencao": "Exemption",
			"pequenosRetalhistas": "Petits détaillants",
			"regimeNormalBensSegMao": "Régime normal + biens d'occasion",
			"regimeNormalRevendaCombustiveis": "Régime normal + revente de carburant",
			"regimeNormalIvaCaixa": "Régime normal + TVA en espèces"
		}
	},
	"rendhabitperm": {
		"fields": {
			"codEmp": "Employé",
			"codAbdes": "Allocation",
			"anoProcessamento": "Année de traitement",
			"valorRend": "Valeur du revenu",
			"rhTipoProce": "Traitement"
		},
		"filename": "Revenus d'un logement permanent",
		"tipoRendHabitPerm": {
			"todas": "Tous",
			"ativas": "Actif",
			"inativas": "Inactif"
		},
		"toolbar": {
			"tipoRendHabitPerm": "Voir le rendement."
		},
		"modal": {
			"title": "Maintien des revenus grâce à l’utilisation d’un logement permanent",
			"fields": {
				"tipoProcessamento": "Type de traitement",
				"codAbdes": "Allocation",
				"codEmp": "Code employé",
				"anoProcessamento": "Année de traitement",
				"mesInicioProce": "Mois de début",
				"suspende": "Suspendu",
				"valor": "Valeur",
				"obs": "Observations",
				"obsSupensao": "Observations sur les suspensions",
				"valorRenda": "Valeur du revenu",
				"valorRendaMaxMuni": "Valeur de revenu maximale (par typologie de municipalité)"
			},
			"message": {
				"msgRendaMaxMuni": "Revenu maximum autorisé pour l'année 2024 conformément aux dispositions du décret-loi n° 90-c/2022, du 30 décembre (arrêté par commune)."
			}
		}
	},
	"rendis": {
		"title_detail": "Type de revenu {{id}}",
		"title_new": "Nouveau type de revenu",
		"title_plural": "Types de revenus",
		"pesquisa": "A chercher",
		"saved": "Enregistré avec succès.",
		"error": "Type de revenu {{id}}, ne peut pas être enregistré.",
		"deleted": "Type de revenu {{id}}, supprimé avec succès.",
		"deleteModalText": "Supprimer l'enregistrement \"{{tipRendiment}}\" ?",
		"fields": {
			"tipRendiment": "Type de revenu",
			"nome": "Nom du rendement",
			"tipRendimentId": "Code du type de revenu",
			"tipoDeclaracao": "Type de déclaration"
		}
	},
	"tiposDeclaracao": {
		"data": {
			"modelo10": "Modèle 10",
			"modelo22": "Modèle 22",
			"modelo30": "Modèle 30",
			"modelo39": "Modèle 39"
		}
	},
	"repfinancas": {
		"title_detail": "Département des finances {{id}}",
		"title_new": "Nouveau département financier{{id}}",
		"title_plural": "Bureaux des impôts",
		"pesquisa": "A chercher",
		"saved": "Bureau des impôts {{id}}, enregistré avec succès",
		"error": "Bureau des impôts {{id}}, ne peut pas être enregistré.",
		"deleted": "Bureau des impôts {{id}}, supprimé avec succès",
		"fields": {
			"codFiscal": "Code de division",
			"nome": "Nom du département",
			"tipo": "Type de financement",
			"morada": "Ménage",
			"codPostal": "Code postal",
			"codDistrito": "Code de district",
			"nomeDistrito": "Nom du district",
			"codPais": "Code du pays",
			"nomePais": "Nom du pays",
			"telefone": "Téléphone",
			"fax": "Fax",
			"codFinancasAT": "Au code financier"
		},
		"tipo": {
			"bairroFiscal": "District fiscal",
			"reparticao": "Panne"
		}
	},
	"reparticoesccustos": {
		"title_detail": "Répartition par centre de coûts {{id}}",
		"title_new": "Nouvelle répartition par centre de coûts",
		"title_plural": "Répartitions par centre de coûts",
		"pesquisa": "A chercher",
		"add": "Pour ajouter",
		"saved": "Répartition par centre de coûts {{id}}, enregistrée avec succès",
		"error": "La répartition par centre de coûts {{id}} ne peut pas être enregistrée.",
		"deleted": "Répartition par centre de coûts {{id}}, supprimée avec succès",
		"fields": {
			"nCodRepCC": "Tableau",
			"descricao": "Description",
			"designacao": "Désignation",
			"nCodRepCCPlaceholder": "Tableau",
			"descricaoPlaceholder": "Désignation",
			"valorMascara": "Centre de coûts",
			"perc": "Pourcentage",
			"dataReferencia": "Date de référence",
			"mascara": "Masque C. Coût"
		},
		"mascara": {
			"cCusto": "Centre de coûts",
			"zona": "Zone",
			"departamento": "Département",
			"SubDepartamento": "Sous-département",
			"familia": "Famille",
			"grandeFamilia": "Famille Gr.",
			"subFamilia": "Sous-famille",
			"codContab": "Code comptable",
			"tipoArtigo": "Type d'article",
			"classe": "Classe",
			"categoria": "Catégorie",
			"vendedor": "Vendeur",
			"nRefProcesso": "Processus"
		},
		"title": {
			"apagarDatadeReferencia": "Supprimer la date de référence",
			"apagarItemPerDate": "Supprimer la date",
			"apagarItem": "Supprimer la répartition par centre de coûts"
		},
		"message": {
			"desejaApagarDataReferencia": "Voulez-vous supprimer la date de référence ?",
			"reparticaoccustoEliminadoComSucesso": "Répartition par centre de coûts supprimée avec succès",
			"desejaApagarItemPerDate": "Si vous supprimez la dernière ligne de la date sélectionnée, elle sera supprimée.",
			"desejaApagarItem": "Si vous supprimez la dernière ligne de la répartition des centres de coûts, elle sera supprimée."
		},
		"text": {
			"addDate": "Ajouter une date",
			"selectDate": "Sélectionnez une date"
		}
	},
	"reparticoesccustosfaturacao": {
		"title_detail": "Répartition par centre de coûts {{id}}",
		"title_new": "Nouvelle répartition par centre de coûts",
		"title_plural": "Répartition par centre de coûts",
		"pesquisa": "A chercher",
		"saved": "Répartition par centre de coûts {{id}}, enregistrée avec succès",
		"error": "La répartition par centre de coûts {{id}} ne peut pas être enregistrée.",
		"deleted": "Répartition par centre de coûts {{id}}, supprimée avec succès"
	},
	"reparticoesccustossalarios": {
		"title_detail": "Répartition par centre de coûts {{id}}",
		"title_new": "Nouvelle répartition par centre de coûts",
		"title_plural": "Répartitions par centre de coûts",
		"pesquisa": "A chercher",
		"saved": "Répartition par centre de coûts {{id}}, enregistrée avec succès",
		"error": "La répartition par centre de coûts {{id}} ne peut pas être enregistrée.",
		"deleted": "Répartition par centre de coûts {{id}}, supprimée avec succès"
	},
	"reports": {
		"pesquisa": "A chercher",
		"fields": {
			"name": "Nom",
			"namePlaceholder": "Nom du rapport",
			"title": "Titre",
			"titlePlaceholder": "Titre du rapport"
		},
		"messages": {
			"noReports": "Aucun rapport trouvé"
		}
	},
	"resumoiva": {
		"fields": {
			"codigo": "Code",
			"nome": "Nom",
			"infoString": "FNI",
			"valor": "Valeur",
			"percentagem": "%",
			"tipodocumento": "Type de document",
			"iva": "T.V.A.",
			"basetributavel": "Base imposable",
			"totais": "Totaux"
		},
		"totalvendas": "Ventes totales",
		"btn": {
			"mes": "Mois",
			"primeiroTrimestre": "1er trimestre",
			"segundoTrimestre": "2e trimestre",
			"terceiroTrimestre": "3ème trimestre",
			"quartoTrimestre": "4ème trimestre"
		},
		"toolbar": {
			"month": "Mois : {{nameMonth}}"
		}
	},
	"retencoes": {
		"header": {
			"tipoVisualizacao": "Type de vue",
			"nifDe": "Numéro de contribuable",
			"tipRetServNif": "Entité généralement",
			"estadoRet": "Statut de conservation",
			"origemContas": "Origine du compte",
			"radio": {
				"retEfeutadas": "Retenues effectuées",
				"docsSujeitosRet": "Documents.",
				"sujeitoRetencao": "A réaliser",
				"retido": "Effectué",
				"ambos": "Les deux"
			}
		},
		"table": {
			"fields": {
				"isContaPocOrigemFornecedor": "Origine",
				"nContribuinte": "Numéro de contribuable",
				"conta": "Compte",
				"codRetencao": "Rétention",
				"taxaRetUsada": "Taux",
				"tipoRendimento": "Type de revenu",
				"periodo": "Période",
				"nDiario": "Tous les jours",
				"nDocInterno": "Numéro du document",
				"descricaoDC": "Débit/Crédit",
				"nDocExterno": "Doc n°",
				"valorOrigem": "Valeur de base d'incidence",
				"valorDestino": "Valeur à retenir",
				"valorDestDispo": "Valeur conservée",
				"dataDoc": "Date du document"
			}
		},
		"doc": "Afficher le document",
		"config": {
			"showColumnIsContaPocOrigemFornecedor": "Afficher l'origine",
			"showColumnNContribuinte": "Afficher le numéro de contribuable",
			"showColumnConta": "Afficher le compte",
			"showColumnCodRetencao": "Afficher la rétention",
			"showColumnTaxaRetUsada": "Afficher le tarif",
			"showColumnTipoRendimento": "Afficher le type de revenu",
			"showColumnPeriodo": "Afficher la période",
			"showColumnNDiario": "Afficher le numéro quotidien",
			"showColumnNDocInterno": "Afficher le document n°",
			"showColumnDescricaoDC": "Afficher le débit/crédit",
			"showColumnDataDoc": "Afficher la date",
			"showColumnNDocExterno": "Afficher le document n°",
			"showColumnValorOrigem": "Afficher la valeur d'incidence de base",
			"showColumnValorDestino": "Afficher la valeur à retenir",
			"showColumnValorDestDispo": "Afficher le montant retenu"
		}
	},
	"rhEstatisticas": {
		"porEmpresa": "Par entreprise",
		"multiEmpresa": "Multi-entreprises",
		"fields": {
			"codEmp": "Code.",
			"nome": "Nom",
			"totalAbonos": "Total des allocations",
			"totalDescontos": "Remises complètes",
			"totalIliquido": "Total brut",
			"irs": "IRS",
			"segSocial": "Lundi social.",
			"cga": "CGA",
			"sindicato": "Union",
			"totalImpostos": "Impôts totaux",
			"totalRecibo": "Total net",
			"totalLiquido": "Total net",
			"totalEspecie": "Espèces totales",
			"totalAPagar": "Total à payer",
			"encargos": "Frais",
			"custoEmpresa": "Coût de l'entreprise",
			"totalCustosEmpresa": "Coût de l'entreprise",
			"departamento": "Département",
			"ccusto": "centre de coûts",
			"abonos": "Allocations",
			"descontos": "Réductions",
			"colaboradores": "Collaborateurs",
			"impostos": "Impôts",
			"qtd": "Quantité",
			"valorUnit": "Unité V.",
			"valorTotal": "Valeur totale",
			"dtNascimento": "Date de naissance",
			"regimeSS": "Régime sec.",
			"totalRemun": "Rémunération totale",
			"codP": "Code.",
			"codR": "Code.",
			"codF": "Code.",
			"codN": "Code.",
			"codA": "Code.",
			"codB": "Code.",
			"codC": "Code.",
			"codD": "Code.",
			"codH": "Code.",
			"codM": "Code.",
			"codO": "Code.",
			"codS": "Code.",
			"codT": "Code.",
			"codX": "Code.",
			"cod2": "Code.",
			"cod6": "Code.",
			"codI": "Code.",
			"encargosTrabalhador": "Frais des travailleurs",
			"encargosEmpresa": "Frais de l'entreprise",
			"totalEncargos": "Frais totaux",
			"sindicatoPerc": "% de syndicats",
			"valorSujeito": "Valeur du sujet",
			"valorDesconto": "Valeur de remise",
			"niss": "NISS",
			"seguradora": "Compagnie d'assurance",
			"nEmpresa": "numéro d'entreprise",
			"totalSalarios": "Salaires totaux",
			"totalCustos": "Coûts totaux",
			"nif": "FNI",
			"totalRendimentos": "Revenu total",
			"rendAnoAnterior": "Rendement",
			"rendNaoSujeito": "Rendement",
			"rendSujeito": "Rendement",
			"retencaoIrs": "Retenue IRS",
			"areaRendimento": "Revenu de la région",
			"contibuicoesSS": "Cotisations SS",
			"tipoRendimento": "Type de revenu",
			"a25": "A25",
			"a81": "A81",
			"a18": "A18",
			"a40": "A40",
			"a19": "A19",
			"a12": "A12",
			"a68": "A68",
			"a13": "A13",
			"a69": "A69",
			"a11": "A11",
			"al6": "Al6",
			"al7": "Al7",
			"al4": "Al4",
			"a62": "A62",
			"a15": "A15",
			"a63": "A63",
			"a61": "A61",
			"a66": "A66",
			"a67": "A67",
			"a2": "A2",
			"a64": "A64",
			"a3": "A3",
			"a65": "A65",
			"a32": "A32",
			"a33": "A33",
			"a30": "A30",
			"a31": "A31",
			"a4": "A4",
			"a5": "A5",
			"a22": "A22",
			"a23": "A23",
			"a": "LE",
			"a20": "A20",
			"a21": "A21",
			"a26": "A26",
			"a80": "A80",
			"a27": "A27",
			"a24": "A24",
			"totalBruto": "Total brut"
		},
		"filters": {
			"mesProce": "Mois de traitement",
			"codEmpregado": "Code employé",
			"depto": "Département",
			"ccusto": "centre de coûts",
			"anoProce": "Année de traitement"
		},
		"modals": {
			"exclusao": {
				"title": "Suppression d'enregistrements"
			}
		},
		"messages": {
			"intervaloInvalidoEmpregado": "La plage de filtre du code d'employé n'est pas valide.",
			"intervaloInvalidoDepto": "La plage de filtre de département n'est pas valide.",
			"intervaloInvalidoMesProc": "La plage de filtre du mois de traitement n’est pas valide."
		},
		"string": {
			"estatisticas": "Statistiques",
			"analiseRemuneracoes": "Analyse de la rémunération",
			"analiseMensalDetalhada": "Analyse mensuelle détaillée",
			"extratoRemuneracoes": "Déclaration de rémunération",
			"analiseAnual": "Analyse annuelle",
			"analiseSegSocial": "Analyse sec.",
			"analiseAT": "Analyse AT",
			"analiseSindicato": "Analyse syndicale",
			"analiseCGA": "Analyse CGA",
			"analiseSeguro": "Analyse d'assurance",
			"listagensRU": "Annonces au Royaume-Uni",
			"vistaResumo": "Vue récapitulative",
			"vistaDetalhadaPorAbonoDesconto": "Vue détaillée par franchise et remise",
			"impostosColaborador": "Impôts des employés",
			"recibo": "Reçu",
			"custosEmpresa": "Coûts de l'entreprise",
			"excluidos": "Exclu",
			"tipo": "Taper",
			"funcionario": "Collaborateur",
			"totais": "Totaux",
			"anoMenosUm": "Année N - 1",
			"totalN": "Nombre total",
			"pesquisar": "A chercher",
			"config": "Paramètres"
		}
	},
	"rhMapasSindicatos": {
		"btn": {
			"previsualizar": "Aperçu"
		},
		"filters": {
			"dedata": "Date de",
			"deSindicato": "syndicat de"
		}
	},
	"rhtipoprocess": {
		"title_detail": "Type de traitement {{id}}",
		"title_new": "Nouveau type de traitement",
		"title_plural": "Types de traitement",
		"pesquisa": "A chercher",
		"saved": "Tapez Processing {{id}}, enregistré avec succès.",
		"error": "Le type de traitement {{id}} ne peut pas être enregistré.",
		"deleted": "Tapez Processing {{id}}, supprimé avec succès.",
		"fields": {
			"tipoProcessamento": "Type de traitement",
			"descricao": "Description",
			"dadosVencimento": "Données d'expiration",
			"dadosManuais": "Données manuelles",
			"dadosFixos": "Données fixes",
			"subsidioRefeicao": "Indemnité de repas",
			"diuturnidades": "Indemnités d'ancienneté",
			"subsidioNatal": "Allocation de Noël",
			"subsidioFerias": "Allocation de vacances",
			"valorCaducidadeCTR": "Valeur d'expiration du contrat à terme",
			"tipoPeriodicidade": "Périodicité",
			"desativado": "Type de traitement désactivé",
			"func": "Fonction"
		}
	},
	"rhagencias": {
		"onlyativos": "Afficher uniquement les éléments",
		"onlyiban": "Afficher uniquement avec IBAN",
		"fields": {
			"codAgencia": "Code agence",
			"nome": "Nom",
			"iban": "IBAN",
			"descricao": "Description"
		},
		"btn": {
			"addAgencia": "Ajouter une agence",
			"addBanco": "Ajouter une banque",
			"addConta": "Ajouter un compte",
			"editAgencia": "Modifier l'agence",
			"editBanco": "Modifier la banque",
			"editConta": "Modifier le compte",
			"deleteAgencia": "Supprimer l'agence",
			"deleteBanco": "Supprimer la banque",
			"deleteConta": "Supprimer le compte"
		},
		"messages": {
			"delAgencia": "Êtes-vous sûr de vouloir supprimer l'agence ?",
			"delBanco": "Êtes-vous sûr de vouloir supprimer la banque ?",
			"delConta": "Êtes-vous sûr de vouloir supprimer le compte ?",
			"delsuccAgencia": "Agence supprimée avec succès !",
			"delsuccConta": "Compte supprimé avec succès !",
			"newSuccAgencia": "Agence ajoutée avec succès !",
			"newSuccConta": "Compte ajouté avec succès !",
			"updateSuccAgencia": "Agence mise à jour avec succès !",
			"updateSuccConta": "Compte mis à jour avec succès !"
		},
		"modal": {
			"agencia": {
				"title": "Agences",
				"fields": {
					"banco": "Banque",
					"codAgencia": "Code agence",
					"nome": "Nom",
					"descBreve": "Brève description",
					"morada": "Ménage",
					"codPostal": "Code postal",
					"localidade": "Emplacement",
					"telefone": "Téléphone",
					"contacto": "Contact",
					"contaagencia": "ID d'interb.",
					"fax": "Fax",
					"estado": "État",
					"tipoInstal": "Type d'installation",
					"sede": "Soif",
					"agencia": "Agence"
				}
			},
			"conta": {
				"title": "Comptes",
				"fields": {
					"banco": "Banque",
					"agencia": "Agence",
					"nib": "PLUME",
					"nContaPoc": "Numéro de compte",
					"gestorBanco": "Directeur",
					"descricao": "Description",
					"nProjecto": "Numéro de projet",
					"iban": "IBAN",
					"swift": "RAPIDE",
					"debitoDirectoID": "Identifiant de prélèvement automatique",
					"nContaEmprestimos": "Non c.",
					"codPais": "Code du pays",
					"status": "Statut du compte",
					"validaIBAN": "Valide l'IBAN"
				}
			}
		}
	},
	"rhintegracaosalarios": {
		"titles": {
			"dadosadicionaisint": "Données supplémentaires pour l'intégration salariale",
			"dadosadicionaisuni": "Données supplémentaires pour le rapport unique",
			"dadosadicionaisuniint": "Données supplémentaires pour le rapport unique et l'intégration des salaires",
			"confgintegracaosalarios": "Configuration de l'intégration salariale",
			"mascaraanalitica": "Masque analytique"
		},
		"fields": {
			"integraSalarios": "Intégration salariale",
			"pocRemuLiq": "Compte débiteur net",
			"classeTrab": "Classe de travail",
			"funcao": "Fonction",
			"operacaoSeccao": "Opération/Section",
			"codZona": "Code de zone",
			"departamento": "Département",
			"subDepartamento": "Sous.",
			"centroCusto": "centre de coûts",
			"reparticaoCentroCusto": "Répartition des centres de coûts",
			"integraRU": "S'intègre dans le rapport unique",
			"profissao": "Profession",
			"situacaoProfissao": "Situation professionnelle",
			"codCCT": "Code CCT",
			"nivelQualif": "Niveau de qualification",
			"categoria": "Catégorie",
			"integraSalariosPorDefeito": "Intégration normale",
			"integraSalariosPorOrgaoSocial": "Intégration par les organismes sociaux/le personnel",
			"validamascara": "Il a une configuration de masque analytique",
			"mascara": "Masque C. Coût"
		},
		"tab": {
			"integracaosalarios": "Intégration des salaires",
			"mascaraanaliticaabono": "Tolérances pour les masques analytiques",
			"mascaraanaliticadesconto": "Remises sur les masques analytiques"
		},
		"message": {
			"savedsuccessfully": "Données enregistrées avec succès !",
			"resetsuccessfully": "Réinitialisation des données réussie !",
			"retiraropcaointegsal": "En supprimant cette option vous n’aurez plus d’intégration salariale."
		},
		"btn": {
			"repor": "Réinitialiser"
		},
		"configApoiosCOVID19": "Configuration du soutien pour bénéficier de la sécurité sociale - COVID-19",
		"configApoiosCOVID19Fields": {
			"card": {
				"apoioexcepcionalfamilia": "Accompagnement familial exceptionnel pour les salariés (COVID-19)",
				"apoioLayoffSimplificadoPSuspensaoTrabalho": "Aide simplifiée en cas de licenciement pour cause de suspension du travail (COVID-19)",
				"apoioLayoffSimplificadoPReducaoTrabalho": "Aide au licenciement simplifiée en raison d’une réduction du travail (COVID-19)"
			},
			"nContaCovid19ApoioFamilia": "Numéro de compte",
			"nContaCovid19ApoioFamiliaPerc": "Perc.",
			"nContaCovid19LayoffSimplSuspencao": "Numéro de compte",
			"nContaCovid19LayoffSimplSuspencaoPerc": "Perc.",
			"nContaCovid19LayoffSimplParcial": "Numéro de compte",
			"nContaCovid19LayoffSimplParcialPerc": "Perc.",
			"mensagemPercDeApoioAtribuidoSS": "% de l'aide accordée par la sécurité sociale"
		}
	},
	"rhatualizaferiasemp": {
		"atualizadatacadastro": "Mettre à jour la date d'inscription",
		"fields": {
			"empregado": "Employé",
			"feriasano": "Nombre de jours de vacances dans l'année {{year}}",
			"temregano": "A une inscription dans l'année {{year}}",
			"codEmp": "Employé",
			"nomeEmpregado": "Nom",
			"anoAnteriorNDiasFeriasAnoAnterior": "Année précédente",
			"anoAnteriorGozadasNDiasFeriasAnoAnterior": "Éjaculations l'année dernière",
			"anoAnteriorGozadasNDiasFeriasAnoAtual": "Éjaculations dans l'année",
			"nDiasFeriasAnoAnterior": "Année précédente",
			"dataMotivoCadastro": "Date d'inscription",
			"livre": "Informations"
		},
		"messages": {
			"temselecionaremp": "N'a sélectionné aucun employé.",
			"savedsuccess": "L'enregistrement s'est terminé avec succès.",
			"datainferior": "La date ne peut être inférieure à la date déjà enregistrée.",
			"valNegativo": "La valeur ne peut pas être négative."
		}
	},
	"rhcalculorendimentoliquido": {
		"success": "Calcul par revenus épargnés avec succès!",
		"fields": {
			"codEmp": "Code employé",
			"valorLiquido": "Valeur nette",
			"nCodABDESC": "Allocation",
			"adicionaRecolha": "Ajouter la valeur calculée des avantages dans la collecte de données"
		},
		"steps": {
			"processos": "Processus",
			"empregados": "Employés",
			"calcular": "Calculer"
		},
		"titles": {
			"empregados": "Employés de traitement",
			"processamentos": "Liste de traitement"
		},
		"empregado": {
			"codEmpregado": "Code.",
			"nomeEmpregado": "Nom",
			"strEstadoProcEmpregado": "État",
			"nProcessamento": "Processus n°",
			"nRecibo": "Numéro de reçu.",
			"dataProcessamento": "Date de traitement.",
			"identificacaoEmpregado": "Employé"
		},
		"processo": {
			"nProcessamento": "Processus n°",
			"dataProcessamento": "Date de traitement.",
			"tipoProcessamento": "Type de processus.",
			"proceDescricao": "Description",
			"dataInicoProc": "Date de début",
			"dataFimProce": "Date de fin"
		},
		"messages": {
			"invalidStepProc": "Il faut sélectionner un processus !",
			"invalidStepEmp": "Vous devez sélectionner un employé !"
		},
		"btn": {
			"finalizar": "Calculer"
		}
	},
	"rhcessacontratotrabalho": {
		"fields": {
			"datacessacao": "Date de résiliation",
			"entidade": "Entité qui a résilié le contrat",
			"tipoContrato": "Type de contrat",
			"datainicio": "Date de début",
			"horasSemana": "Heures par semaine",
			"dataPrimeiraRenovacaoExtra": "Date du 1er renouvellement extraordinaire",
			"retribuicaoBase": "Salaire de base",
			"diuturnidades": "Indemnités d'ancienneté",
			"complementos": "Modules complémentaires",
			"feriasGozadas": "Des vacances déjà prises",
			"subsidioFerias": "Allocation de vacances déjà perçue",
			"subSidioNatalJaRecebido": "Allocation de Noël déjà reçue",
			"codEmp": "Code employé",
			"duracao": "Durée:",
			"situacao": "Nouveau code.",
			"motivoCessacao": "Nouveau motif d'inscription",
			"duracaodata": "{{years}} année(s) {{months}} mois(s) {{days}} jour(s)",
			"tipoProcessamento": "Type de traitement :",
			"radio": {
				"empregador": "Employeur",
				"trabalhador": "Travailleur",
				"justacausa": "Avec une juste cause ?"
			},
			"calculado": {
				"compensacao": "Compensation:",
				"valorFeriasVencidas": "Vacances non prises :",
				"valorSubsidioFerias": "Allocation de vacances à percevoir :",
				"feriasPropValor": "Vacances:",
				"subsidioFeriasPropValor": "Allocation de vacances :",
				"subsidioNatalPropValor": "Allocation de Noël :",
				"compensacaoTotal": "Montant global:"
			}
		},
		"card": {
			"confirmedados": "Confirmez les données suivantes",
			"valorescalc": "Valeurs de calcul",
			"valoremfalta": "Vacances - Montant manquant",
			"proporcionais": "Proportionnel l'année de la cessation",
			"retribuicao": "Châtiment",
			"ferias": "Vacances"
		},
		"btn": {
			"simularcessacao": "Calculer la valeur",
			"dadosemp": "Maintenance des données des employés",
			"validar": "Valider/Modifier les valeurs",
			"manutencaocessacontrato": "Configurer la résiliation du contrat"
		},
		"infos": {
			"tooltipRetribuicaoBase": "La rémunération de base correspond à l'activité du travailleur pendant la période normale de travail.",
			"tooltipComplementos": "Versements périodiques réguliers.",
			"tooltipTipoContrato": "Un contrat à durée indéterminée est communément appelé effectif.",
			"tooltipJustCausa": "Un motif valable ne peut être apprécié que par un tribunal.",
			"tooltipFeriasPropValor": "Pour le calcul, le simulateur prend en compte : <br> - la valeur des congés et les proportions respectives : rémunération de base, indemnités d'ancienneté et avantages salariaux indiqués par l'utilisateur comme étant une rémunération pour la manière particulière dont le travail est fourni.",
			"tooltipExcecpoes": "Exceptions",
			"tooltipReCalcular": "Calculer les compléments",
			"temcalcularvalor": "Vous devez effectuer le calcul pour valider les valeurs.",
			"temcorrigiremp": "Vous devez corriger les erreurs de l'employé.",
			"insiraemp": "Vous devez saisir un employé.",
			"situacaoandmotivodefault": "Les paramètres par défaut pour le statut de résiliation et le motif de l'inscription ne sont pas renseignés.",
			"calculadocomplementossuccess": "Compléments calculés avec succès!",
			"calcsuccess": "Valeurs calculées avec succès !",
			"gravadocomsucessonotipoproc": "Les données d'expiration ont été écrites avec succès dans Data Collection dans le type de traitement « {{processingtype}} ».",
			"abonodescsuccess": "Allocation/réduction ajoutée avec succès !"
		},
		"modal": {
			"defsitandmot": "Souhaitez-vous définir les codes « Situation » et « Raison » par défaut ?",
			"encerrar": {
				"cessacaocontrat": "Résiliation du contrat de travail",
				"table": {
					"nCodABDESC": "Code d'allocation/déduction",
					"descricaoAbono": "Description",
					"valor": "Valeur",
					"observacoes": "Note.",
					"semdados": "Aucune donnée"
				}
			},
			"complementosexcepcoes": {
				"title": "Exceptions",
				"indiqueexcluidos": "Indiquez les éléments que vous souhaitez exclure de la sélection",
				"possiveis": "Possible",
				"excluidos": "Exclu",
				"fields": {
					"codABDESC": "Code",
					"designaBreve": "Nom"
				}
			},
			"addabonodesconto": {
				"title": "Ajouter un nouveau bonus/remise à la résiliation du contrat",
				"fields": {
					"abonoDesc": "Taper",
					"codABDESC": "Code",
					"valor": "Valeur",
					"observacoes": "Note."
				}
			}
		}
	},
	"rhcopiaabonosdescontos": {
		"fmt": {
			"relatorioLoadABDESCopia": "{{CodAbdes}}-{{NameCodAbdes}}",
			"abonoDescontoACopiar": "Allocation/Remise à copier : {{abdesCopia}}",
			"empresaDeOrigem": "Entreprise d'origine : {{codCompanyOrigem}}-{{nomeEmpresaOrigem}}",
			"empresaDeDestino": "Entreprise cible : {{destinationcode}}-{{destinationcompanyname}}"
		},
		"titles": {
			"copiaDeAbonosDescontosMultiEmpresa": "Copie des indemnités / Remises multi-entreprises",
			"selecaoDeEmpresas": "Sélection d'entreprise",
			"confirmacaoDasOperacoes": "Confirmation des opérations",
			"estadoDaCopia": "Statut de la copie"
		},
		"text": {
			"1-1": "Cet assistant vous guidera tout au long du processus de copie ou de mise à jour automatique de l'allocation/réduction pour toutes les entreprises sélectionnées.",
			"1-2": "Il sera également possible de copier les intersections, mais cela ne permettra pas de les mettre à jour dans l'entreprise de destination.",
			"1-3": "Enfin, un rapport s'affichera avec des informations détaillées sur les succès ou les erreurs, afin qu'ils puissent être corrigés.",
			"2-1": "Trouvez-vous",
			"3-1": "Confirmez si vous souhaitez remplacer les entreprises qui ont déjà configuré des allocations/remises"
		},
		"fields": {
			"tipo": "Taper",
			"codigo": "Allocation/remise",
			"abono": "Allocation",
			"desconto": "Rabais",
			"copiaCruzamentos": "Copier les intersections",
			"copiaIntSalarios": "Copier l'intégration salariale"
		},
		"modal": {
			"title": "Informations sur la copie des allocations/réductions",
			"radio": {
				"gerarNovo": "Générer un nouveau code de groupe basé sur l'origine",
				"utilizarExistente": "Utiliser un groupe existant à destination avec toutes les caractéristiques de la source"
			}
		},
		"dataGrid": {
			"caption": {
				"empresa": "Code de l'entreprise",
				"nomeEmpresa": "Nom de l'entreprise",
				"copiouABDES": "Flic.",
				"copiouGrupo": "Flic.",
				"copiouCruzamento": "Flic.",
				"copiouIntegracaoSalarios": "Flic.",
				"permiteCopia": "Infos.",
				"codAbdesDestino": "Code de destination",
				"nomeAbdesDestino": "Nom de la destination",
				"temCruzamento": "Il y a un croisement",
				"mensagem": {
					"resultado": "Résultat",
					"descricao": "Description"
				}
			}
		},
		"errors": {
			"semCodAbDesc": "Vous devez sélectionner une allocation/remise",
			"semEmpresas": "Vous devez sélectionner au moins une entreprise",
			"selecionarLinhaComErro": "La ligne ne peut pas être sélectionnée car elle contient une erreur",
			"selecionarLinhaComAviso": "Choisissez une action avant de sélectionner la ligne"
		}
	},
	"rhdadosconfigficha": {
		"titles": {
			"registo": "Inscription",
			"situacaoemprego": "Situation de l'emploi",
			"dadosrenumeracao": "Données de rémunération",
			"outrosdados": "Autres données",
			"subsidioferias": "Allocation de vacances",
			"subsidionatal": "Allocation de Noël",
			"residenciafiscal": "Résidence fiscale"
		},
		"fields": {
			"motivoRegisto": "Raison de l'inscription",
			"situacaoEmprego": "Situation",
			"nCodABVencimento": "Allocation de salaire",
			"nCodABSubAlimentacao": "Allocation alimentaire",
			"nCodABSubFerias": "Allocation de vacances",
			"nCodABSubNatal": "Allocation de Noël",
			"nCodABDiuturnidades": "Indemnité d'ancienneté",
			"nCodDescIRS": "Remise IRS",
			"localTrabalho": "Localisation ou établissement",
			"tipoHorarioTrabalho": "Type de temps",
			"nMaxDiasUteisFerias": "Nombre maximum de jours ouvrables",
			"nDiasTemDireitoFerias": "Nombre de jours ouvrables auxquels vous avez droit",
			"nMaxDiasUteisNatal": "Nombre maximum de jours",
			"nDiasTemDireitoNatal": "Nombre de jours pendant lesquels vous êtes éligible",
			"nCodABFerias": "Prime de vacances",
			"localObtencaoRendimentos": "Lieu gagnant un revenu"
		},
		"text": {
			"suggestion1": "Il semble que vous n'ayez pas encore configuré les données par défaut dans le dossier de l'employé.",
			"suggestion2": "appliquer la configuration recommandée"
		}
	},
	"rhfolfer": {
		"fields": {
			"dataProcesDE": "Date de traitement",
			"deAbono": "Allocation de",
			"deCCusto": "centre de coûts",
			"deDepto": "Département de",
			"deDesconto": "Remise à partir de",
			"deSeccao": "Section",
			"deSubDepto": "Sous-département",
			"deZona": "Zone",
			"doCodNum": "code employé",
			"mostraAbonosVirtuais": "Afficher les allocations virtuelles"
		},
		"infos": {
			"tooltipExcecpoesAbono": "Allocations d'exception",
			"tooltipExcecpoesDesconto": "Remises d'exception",
			"tooltipExcepcoesProcessamento": "Traitement des exceptions"
		},
		"btn": {
			"previsualizar": "Aperçu"
		},
		"messages": {
			"exclprocessamentoemp": "Vous ne pouvez sélectionner que l'entreprise actuelle !"
		}
	},
	"rhgestaoficheirosdmrat": {
		"comprovativo": "Preuve",
		"docpagamento": "Document de paiement",
		"emaildest": "E-mail du destinataire",
		"semdados": "Aucune donnée",
		"header": {
			"dataAquisicaoDe": "Mois/Année"
		},
		"btn": {
			"pesquisar": "A chercher",
			"verificarenvios": "Vérifier les soumissions",
			"gerarficheiro": "Générer des fichiers",
			"reenviarwebservice": "Renvoyer via WebService"
		},
		"table": {
			"dados": "Données",
			"infoficheiro": "Informations sur le fichier",
			"sel": "Sel.",
			"empresa": "Entreprise",
			"nomeempresa": "Nom de l'entreprise",
			"contribemp": "Contribution",
			"info": "Informations",
			"erros": "Erreurs",
			"infoerros": "Erreurs d'informations",
			"estado": "État",
			"docpagamento": "Document de paiement",
			"comprovativo": "Preuve",
			"email": "E-mail"
		},
		"tab": {
			"dadosAutomaticos": "Données automatiques",
			"dadosManuais": "Données manuelles",
			"dadosGlobais": "Données mondiales",
			"controlo": "Contrôle",
			"valorRendimentos": "Valeur du revenu",
			"retencaoIRS": "Retenue IRS",
			"contribObrigatorias": "Cotisations obligatoires",
			"quotizacaoSindicais": "Cotisations syndicales",
			"retencaoSobretaxa": "Retenue de majoration",
			"rendiAnosAnt": "Revenus des années précédentes"
		},
		"modal": {
			"rhgestaoficheirosdmrattitle": "Informations sur les données de rémunération",
			"table": {
				"ok": "D'ACCORD",
				"nif": "Numéro de contribuable",
				"codemp": "Numéro d'employé",
				"nome": "Nom",
				"abonosRendimentosSujeitos": "Sujet au revenu",
				"abonosRendimentosNaoSujeitos": "Revenu non sujet",
				"abonosOutros": "Autres allocations",
				"descontosContribuicoesObrigatorias": "Cotisations obligatoires",
				"descontosIRS": "Retenue IRS",
				"descontosSindicatos": "Les syndicats",
				"descontosSobretaxa": "Retenue de majoration",
				"descontosOutros": "Autres réductions",
				"rendimentosAnoAnterior": "Revenu de l'année précédente",
				"liquidoCalculado": "Net calculé",
				"liquidoVencimentos": "Salaires nets",
				"erroDescricao": "Description de l'erreur/avertissement",
				"origemRendiDescr": "Origine",
				"tipRendiment": "Type de rendement.",
				"valorInciden": "Valeur du revenu",
				"descrInsManual": "Type d'insertion",
				"quotizSindical": "Cotisations syndicales",
				"irsRetido": "Retenue IRS",
				"descobrigSSocial": "Cotisations obligatoires",
				"sobretaxaExtraord": "Retenue de majoration",
				"rendimentosAnoAnteriorTable": "Valeur du revenu (années précédentes)",
				"rendianosanteriores": "Revenus des années précédentes",
				"valores": "Valeurs",
				"rendimentosano": "Revenu pour l'année",
				"tipoRendi": "Type de revenu",
				"localorigemrendi": "Localisation des revenus",
				"nipc1": "Entité NIPC 1",
				"nipc2": "Entité NIPC 2",
				"nipc3": "Entité NIPC 3",
				"enviaparaDMR": "Envoyer à DMR",
				"valorNaoSujeito": "Revenu non sujet",
				"outrosDescontos": "Autres réductions",
				"outrosAbonos": "Autres allocations",
				"ncodAbdes": "Allocation/remise",
				"descricaoAbono": "Description de l'avantage/réduction"
			}
		},
		"email": {
			"enviado": "E-mail envoyé avec succès !",
			"naoenviado": "L'e-mail n'a pas été envoyé."
		},
		"messages": {
			"envioscomsucesso": "Soumissions vérifiées avec succès !"
		},
		"tooltip": {
			"naotemlinhasselecionadas": "Aucune ligne n'est sélectionnée."
		},
		"prompt": {
			"geraFich": "Il générera le fichier du relevé de rémunération mensuel - AT.",
			"errogerarficheiro": "Le fichier n'a pas été généré avec succès !",
			"ficheirosenviadosconfirmcolum": "Fichiers envoyés."
		}
	},
	"gozoferias": {
		"usaMarcacaoGozoFerias": "Utiliser le marquage du plaisir des vacances",
		"motivoRegistoFerias": "Raison du changement",
		"nCodAbdesDescontoGozoFeriasAnoAtual": "Remise de l'année en cours",
		"nCodAbdesDescontoGozoFeriasAnoAnterior": "Remise de l'année précédente"
	},
	"rhimpvenct": {
		"fields": {
			"doCodNum": "code employé",
			"deZona": "Zone",
			"deDepto": "Département de",
			"deSubDepto": "Sous-département",
			"deCCusto": "centre de coûts",
			"deAbono": "Allocation de",
			"deDesconto": "Remise à partir de",
			"deSeccao": "Section",
			"mostraAbonosVirtuais": "Afficher les allocations virtuelles",
			"dataProcesDE": "Date de traitement"
		},
		"infos": {
			"tooltipExcecpoesAbono": "Allocations d'exception",
			"tooltipExcecpoesDesconto": "Remises d'exception",
			"tooltipExcepcoesProcessamento": "Traitement des exceptions"
		},
		"btn": {
			"previsualizar": "Aperçu"
		},
		"messages": {
			"exclprocessamentoemp": "Vous ne pouvez sélectionner que l'entreprise actuelle !"
		}
	},
	"rhmanutencao": {
		"tabs": {
			"vencimentos": "Salaires"
		},
		"message": {
			"savedsuccessfully": "Données enregistrées avec succès !"
		},
		"fields": {
			"situacaoCessacao": "Statut de cessation",
			"motivoCessacao": "Raison de l'inscription",
			"nCodAbonoIndemnizacaoCaducidade": "Indemnité de confiscation non imposable",
			"nCodAbonoIndemnizacaoDespedimento": "Indemnité de licenciement",
			"nCodAbonoIndemnizacaoGlobal": "Rémunération complète",
			"nCodAbonoSubFeriasProp": "Indemnité de vacances proportionnelle",
			"nCodAbonoFeriasProp": "Congés proportionnels"
		},
		"titles": {
			"abcessaconttrab": "Définitions des indemnités/déductions pour rupture de contrat de travail",
			"abpropanoatual": "Définitions des abattements/déductions proportionnelles pour l'année en cours"
		},
		"steps": {
			"cessacaocontrato": "Résiliation du contrat"
		},
		"segsocialfolhaferias": {
			"title": "Feuille de vacances (Assureur)",
			"cardseguradora": {
				"usaTipodeRemunEspecificaFichSeguradora": "Utiliser un type de rémunération spécifique pour le dossier d'assurance (\"A\", \"B\", \"C\", \"F\", \"M\", \"N\", \"O\", \"P\", \"R\", \"S\", \" T \", \"X\", \"SR\")",
				"fichSeguradoraEnvioFichPelaExclAbDescCampo": "Envoyer dossier d'assurance - Envoyer les franchises/remises en validant le champ \"Carte des assurances\""
			}
		},
		"rmmg": {
			"title": "Rémunération Mensuelle Minimum Garanti (RMMG)",
			"info": "La mise à jour RMMG est effectuée automatiquement par CentralGest",
			"saved": "Configuration de la Rémunération Mensuelle Minimum Garantie (RMMG) mise à jour avec succès",
			"tooltip": {
				"needSave": "Vous devez enregistrer les paramètres pour effectuer le traitement",
				"needCheck": "Vous devez sélectionner le champ responsable pour effectuer le traitement",
				"needSaveAndCheck": "Vous devez sélectionner le champ gestionnaire et le sauvegarder pour pouvoir effectuer le traitement"
			},
			"fields": {
				"dataAtualizacao": "Date de mise à jour",
				"gerente": "Directeur",
				"funcionario": "Employé"
			}
		}
	},
	"rhmanutencaocampos": {
		"pesquisar": "A chercher",
		"camposModificar": "Champs à modifier",
		"processamentos": "Liste de traitement",
		"valorCampoNaoPodeSerNulo": "La Valeur du champ {{campo}} ne peut pas être nulle ou vide !",
		"nenhumCampoSelecionadoParaAtualizar": "Aucun champ sélectionné à mettre à jour",
		"nenhumProceSelecionadoParaAtualizar": "Aucun traitement sélectionné à mettre à jour",
		"naoFoiEncontradoValorNoModel": "La valeur n'a pas été trouvée dans le modèle : {{field}}",
		"atualizarLista": "Mettre à jour la liste",
		"promptMessage": "Êtes-vous sûr de vouloir modifier les champs dans les processus sélectionnés ?",
		"dataProcFinalNaoPodeInferiorInicial": "La date finale de traitement ne peut être inférieure à la date de début.",
		"infoHistoricoEmpregado": "Informations sur l'historique des employés",
		"dadosProcessamento": "Traitement des données",
		"processamento": "Traitement",
		"fields": {
			"dateProc": "Date de traitement",
			"codEmp": "Code Emp.",
			"nome": "Nom",
			"nProcessamento": "Processus n°",
			"dataProcessamento": "Date de traitement",
			"funcao": "Fonction",
			"nZona": "Code de zone",
			"seccao": "Section",
			"ccusto": "centre de coûts",
			"nPOCRemuLiq": "Compte POC d'alcool.",
			"departamento": "Département",
			"classeTrab": "Catégorie d'emploi",
			"nSubDe": "Sous-Département",
			"descricaoErro": "Rapport"
		},
		"wizard": {
			"stepOne": {
				"wizardCaption": "Accueillir",
				"text1": "Cet assistant vous permettra de corriger les champs mal enregistrés lors du traitement de la paie.",
				"text2": "Vous effectuerez ces corrections uniquement dans des processus qui ne sont pas encore intégrés en comptabilité.",
				"text3": "Cliquez sur \"Suivant\" pour continuer."
			},
			"stepTwo": {
				"wizardCaption": "Mettre à jour les champs",
				"text1": "Recherche des traitements (non intégrés) effectués dans la plage de dates.",
				"text2": "La modification des enregistrements peut être effectuée champ par champ, ou en sélectionner plusieurs et les modifier tous en même temps."
			},
			"stepThree": {
				"wizardCaption": "Rapport"
			}
		}
	},
	"rhpenhoras": {
		"title_detail": "Pion",
		"title_new": "Nouvelle pièce jointe",
		"title_plural": "Liens",
		"pesquisa": "A chercher",
		"saved": "Engagement, enregistré avec succès.",
		"error": "La pièce jointe ne peut pas être conservée.",
		"deleted": "Pièce jointe supprimée avec succès.",
		"apagarregisto": "Supprimer l'inscription",
		"card": {
			"catabo": "Sélectionnez les allocations pour calculer la valeur nette du salaire pour attribuer la saisie-arrêt.",
			"calcLiqTodasCateg": "Toutes les catégories de prestations"
		},
		"toolbar": {
			"tipoEscolha": "Afficher les pièces jointes : {{nameTipoEscolha}}"
		},
		"fields": {
			"ncodABDESC": "Rabais",
			"codEMP": "Employé",
			"tipoValor": "Type de valeur",
			"valorTotalPenhora": "Valeur totale de la pièce jointe",
			"dataInicio": "Date de début",
			"tipoProcessamento": "Traitement",
			"todosProcessamentos": "Tous les traitements",
			"penhoraPorInsolvenci": "Saisie pour insolvabilité",
			"suspende": "Suspendu",
			"dataSuspensao": "Date de suspension",
			"obsSupencao": "Description des suspensions",
			"totalValorPenhoraPaga": "Montant de la saisie payée",
			"tipoValorPenhora": "Type de valeur de pièce jointe",
			"valor": "Valeur du type de pièce jointe",
			"temValorLimiteMinimoImpenhoravel": "Possède une valeur limite minimale insaisissable",
			"valorLimImpenhoravel": "Valeur limite insaisissable",
			"valorExternoPenhora": "Valeur nette externe : (s'ajoute à la valeur nette calculée)",
			"obsPenhora": "Observations",
			"referenciaPagamento": "Description",
			"identificacaoPenhora": "Identification de la pièce jointe",
			"iban": "IBAN",
			"mbEntidade": "Entité",
			"mbReferencia": "Référence",
			"formaPagamento": "Mode de paiement"
		},
		"enumStr": {
			"formaPagamento": {
				"none": "Non spécifié",
				"mbref": "Entité/Référence",
				"iban": "IBAN",
				"mbrefestado": "Réf. paiement à l'État"
			}
		},
		"maintenance": {
			"descontos": "Configuration de la remise"
		}
	},
	"rhprocessamentosalariosconfig": {
		"tab": {
			"procesalarios": "Traitement de la paie",
			"duodecimos": "Douzièmes",
			"recibovenc": "Reçu de salaire"
		},
		"procesalarios": {
			"usaFiltroFuncaoNosTiposProcessamento": "Utiliser la fonction de filtre dans le traitement des salaires",
			"calcNDiasParaAdmissaoEmpEmDiasBase30": "Calcule la valeur de traitement du salaire pour l'admission des employés sur une base de 30 jours"
		},
		"duodecimos": {
			"acertoDuodecimosFaltouMesInteiro": "Supprimer les douzièmes si le salarié était absent pendant tout le mois",
			"efetuaAcertoDuodecimos": "Effectuer un ajustement des douzièmes lors du dernier versement de décembre",
			"percentagemEmSubFerias": "Pourcentage de sous.",
			"percentagemEmSubNatal": "Pourcentage de sous."
		},
		"recibovenc": {
			"reciboVencimentosMostraGraficoCustos": "Affiche la répartition des coûts totaux de l'employé pour l'entreprise sur la fiche de paie",
			"reciboVencimentosMostraGraficoCustosAcumuladosAnuais": "Afficher les cumuls annuels dans les détails du coût total"
		},
		"card": {
			"percduodecimos": "Douzièmes du pourcentage des allocations de vacances et de Noël"
		},
		"errors": {
			"invalidpercentage": "Pourcentage invalide, doit être 0, 50, 100 !",
			"verificarFerias": "Vérifiez que le pourcentage de douzièmes inscrit dans le sous.",
			"verificarNatal": "Vérifiez que le pourcentage de douzièmes inscrit dans le sous."
		}
	},
	"rhprocsegsocialconsultenvios": {
		"semdados": "Aucune donnée",
		"comprovativo": "Preuve",
		"header": {
			"dataAquisicaoDe": "Mois/Année"
		},
		"btn": {
			"pesquisar": "A chercher",
			"gerarficheiro": "Générer des fichiers",
			"reenviarwebservice": "Renvoyer via WebService",
			"reprocessarSegSocial": "Retraiter sec.",
			"configWsSS": "Configuration du WebService de la Sécurité Sociale",
			"impresumo": "Imprimer le résumé",
			"gerarEstorno": "Générer une rétrofacturation",
			"apagarEstorno": "Supprimer la rétrofacturation"
		},
		"table": {
			"dados": "Données",
			"dadosEmpresa": "Données de l'entreprise",
			"infoficheiro": "Informations sur le fichier",
			"infowebservice": "Service Web d'informations",
			"sel": "Sel.",
			"empresa": "Entreprise",
			"nomeempresa": "Nom de l'entreprise",
			"totalRemuneracoes": "Total - Rémunération sec.",
			"totalContribuicoes": "Total - Cotisation sec.",
			"totalVencRemun": "Total - Rémunération Proc.",
			"procficheiro": "Proc.",
			"info": "Informations",
			"erros": "Erreurs",
			"reprocessado": "Retraité",
			"gerado": "Généré",
			"infoerros": "Erreurs d'informations",
			"estado": "État",
			"alertaserros": "Alerte/Erreurs",
			"dataentrega": "La date de livraison",
			"datalimitesub": "Remplacer la date limite",
			"nome": "Nom",
			"comprovativo": "Preuve",
			"modal": {
				"title": "Affiche les informations du fichier de feuille de vacances",
				"identificacao": "Identification",
				"codp": "CodeP",
				"codr": "CodeR",
				"codsr": "Code SR",
				"codf": "CodeF",
				"codn": "Code N",
				"coda": "CodeA",
				"codb": "CodeB",
				"codc": "CodeC",
				"codd": "CodeD",
				"codh": "CodeH",
				"codm": "CodeM",
				"codo": "Code",
				"cods": "CodeS",
				"codt": "Code T",
				"codx": "CodeX",
				"cod2": "Codage 2",
				"cod6": "Code 6",
				"codi": "Code I",
				"nss": "Numéro SS.",
				"nfuncionario": "Numéro d'employé",
				"nome": "Nom",
				"ndias": "Nombre de jours",
				"valor": "Valeur"
			},
			"config": "Configuration."
		},
		"messages": {
			"infosuccess": "Données enregistrées avec succès !",
			"infoerror": "Une erreur s'est produite lors de l'enregistrement des données, veuillez confirmer les données saisies.",
			"empresasSelecionasHouveEmpresnaoGeradas": "Parmi les entreprises sélectionnées, certaines n’ont pas été créées.",
			"reprocessamentoSuccess": "Retraitement social réalisé avec succès.",
			"empresasSelecionasHouveEmpres": "Parmi les entreprises sélectionnées, certaines comportaient des erreurs.",
			"enviowebserviceSuccess": "Expédition effectuée vers toutes les entreprises.",
			"enviadosSucess": "Les fichiers ont été envoyés avec succès",
			"semRegistosSelecionadas": "Vous devez sélectionner au moins un enregistrement.",
			"verificadosuccess": "Soumissions vérifiées avec succès",
			"soPodeSelecionarUmRegisto": "Vous ne pouvez sélectionner qu'un seul enregistrement."
		},
		"prompt": {
			"geraFich": "Il générera le dossier de déclaration de rémunération à la sécurité sociale.",
			"errogerarficheiro": "Le fichier n'a pas été généré avec succès !",
			"ficheirosenviadosconfirmcolum": "Fichiers envoyés.",
			"semAlteracaoParaGerar": "Le système n'a pas généré de fichier, car celui-ci avait déjà été généré précédemment."
		},
		"tooltip": {
			"naotemlinhasselecionadas": "Aucune ligne n'est sélectionnée.",
			"configWsSS": "Configuration du WebService de la Sécurité Sociale",
			"gerarEstorno": "Générer une rétrofacturation",
			"apagarEstorno": "Supprimer la rétrofacturation"
		},
		"verificarEnvios": "Vérifier les soumissions",
		"modal": {
			"title": "Impression des cartes récapitulatives du dossier de sécurité sociale"
		}
	},
	"rhqpirct": {
		"card": {
			"irct": "IRCT",
			"irctcateg": "IRCT\\Catégorie",
			"codirct": "Code IRCT",
			"nomeirct": "Nom de l'IRCT",
			"codcategoria": "Code de catégorie",
			"nomecategoria": "Nom de la catégorie"
		},
		"btn": {
			"addirct": "Ajouter un IRCT",
			"addcateg": "Ajouter une catégorie",
			"infooficialirct": "Obtenir des informations officielles sur les codes à attribuer à l'IRCT et les catégories",
			"importlistacateg": "Import de la liste des catégories professionnelles (fichier CSV)"
		},
		"messages": {
			"filesuccess": "Fichier importé avec succès !",
			"importedsucess": "Importé avec succès !",
			"irctexists": "L'IRCT que vous comptez importer existe déjà !"
		},
		"modal": {
			"title": "Importation de catégories professionnelles",
			"table": {
				"codIRCT": "Code",
				"nome": "Nom"
			}
		}
	},
	"rhrecolhadados": {
		"apagarregisto": "Supprimer l'inscription",
		"radio": {
			"dadosmanual": "Collecte manuelle de données",
			"dadosfixos": "Collecter des données fixes",
			"byabondesc": "Collecte de données par allocation/remise",
			"byemp": "Collecte de données par employé"
		},
		"error": {
			"invalidStep": "Il y a des champs qui ne sont pas renseignés ou contiennent une erreur!",
			"invalidStepTodosProcessamentos": "Vous devez sélectionner un type de traitement valide !"
		},
		"fields": {
			"todosProcessamentos": "Tous les traitements",
			"nomeEmp": "Employé",
			"tipoProcessamento": "Type de traitement",
			"abonoDesc": "Allocation/remise",
			"codABDESC": "Code de réduction/de réduction",
			"codEMP": "Code employé",
			"dataDados": "Date de l'événement",
			"dataDadosFixos": "Date de traitement",
			"dataProcess": "Date de traitement",
			"dataParaProcess": "Date de traitement",
			"quantidade": "Montant",
			"valorUNIT": "Valeur unitaire",
			"valor": "Valeur",
			"vALINCIDESC": "Incidence",
			"descricao": "Note.",
			"cambioMEParaMC": "Échange",
			"valorME": "Valeur ME",
			"codMOEDA": "Pièce de monnaie",
			"tipoALUNIT": "Unité",
			"ccusto": "C. Coût",
			"nsubde": "Sous-Département.",
			"departamento": "Département",
			"nzona": "Zone",
			"contador": "Numéro d'enregistrement en C. Coût",
			"htrabDia": "Travail par jour",
			"htrabSemana": "Travail par semaine",
			"htrabMes": "Travail par mois",
			"nomeAbonoDesconto": "Code de réduction/de réduction",
			"nomeTipoProcessamento": "Type de traitement"
		},
		"tabs": {
			"infoemp": "Informations sur les employés",
			"outrosdados": "Autres données",
			"title": {
				"horasempregado": "Heures des employés",
				"ndiasempregado": "Nombre de jours travaillés",
				"valoresuniemp": "Valeurs unitaires des salariés"
			}
		},
		"table": {
			"sel": "Sel",
			"tipo": "Taper",
			"valor": "Valeur",
			"valorme": "Valeur ME",
			"horaextra": "% Au fil du temps",
			"pagaseguro": "% Paie l'assurance"
		},
		"modal": {
			"continuar": "Etes-vous sûr de vouloir modifier l'enregistrement enregistré ?",
			"avisodesc": "L'allocation/réduction '{{abodesc}}' que vous introduisez entraînera la perte de l'indemnité de repas.",
			"labelnhoras": "Nombre d'absences marquées pour la réduction {{abodesc}}",
			"labelndias": "Nombre de jours pour l'indemnité de repas",
			"nhoras": "Nombre d'heures",
			"ndias": "Nombre de jours",
			"title": "Indemnité de repas"
		},
		"toolbar": {
			"month": "Mois : {{nameMonth}}",
			"stateemp": "Employés : {{nameState}}"
		},
		"steps": {
			"tiporecolha": "Type de collecte",
			"dadosrecolha": "Données de collecte"
		}
	},
	"remfx": {
		"title_detail": "Collecter des données fixes",
		"title_new": "Nouvelle collecte de données fixes",
		"title_plural": "Collectes de données fixes",
		"pesquisa": "A chercher",
		"saved": "Collecte de données fixe, enregistrée avec succès.",
		"error": "Collecte de données fixe, ne peut pas être enregistrée.",
		"deleted": "Collecte de données fixe, supprimée avec succès."
	},
	"remun": {
		"title_detail": "Collecter des données",
		"title_new": "Nouvelle collecte de données",
		"title_plural": "Collectes de données",
		"pesquisa": "A chercher",
		"saved": "Collecte de données, enregistrée avec succès.",
		"error": "La collecte de données ne peut pas être enregistrée.",
		"deleted": "Collecte de données, supprimée avec succès."
	},
	"rhrecolhaseriesubsidios": {
		"empTable": {
			"codEmp": "Code employé",
			"nomeEmp": "Nom",
			"nBI": "Numéro BI.",
			"contribuinte": "Numéro de contribuable",
			"dataNascimento": "Date de naissance"
		},
		"steps": {
			"pesquisa": "Recherche",
			"processar": "Processus"
		},
		"titles": {
			"pesquisa": "Recherche",
			"processar": "Sélectionnez le jour de paiement"
		},
		"fields": {
			"codEmpDe": "code emp.",
			"codEmpAte": "code emp.",
			"situacaoEmprego": "Situation de l'emploi",
			"tipoProcessamento": "Type de traitement",
			"dataReferencia": "Date de référence",
			"dataParaPagarSubsidio": "Date de calcul du montant payable à titre de subvention",
			"nDiasSubsidioAlimentacao": "Nombre de jours de défaut",
			"radio": {
				"inativo": "Inactif"
			}
		},
		"messages": {
			"selEmp": "Vous devez sélectionner au moins un employé!",
			"tipoProcEmpty": "Vous devez saisir un type de traitement !",
			"semdadossave": "Aucune donnée à enregistrer.",
			"dadossaved": "Données enregistrées avec succès."
		},
		"modal": {
			"subssavedtitlenatal": "Paiement en série de l'allocation de Noël",
			"subssavedtitleferias": "Paiement en série de l'indemnité de vacances",
			"subssavedtitlealimentacao": "Prétraitement en série de l'allocation alimentaire",
			"semdados": "Aucune donnée",
			"btn": {
				"options": {
					"showCodEmpregado": "Afficher le code.",
					"showNomeEmpregado": "Afficher le nom",
					"showDataAdmissao": "Afficher la date d'admission",
					"showNDiasAPagar": "Indique le nombre de jours pour payer",
					"showValorAPagar": "Affiche le montant à payer",
					"showNMAxDiasUteisSubsidio": "Affiche le nombre maximum.",
					"showNDiasPagosAnoAnterior": "Indique le nombre de jours payés l'année précédente",
					"showValorPagoAnoAnterior": "Indique le montant payé l'année dernière",
					"showNDiasPorPagarAnoAnterior": "Indique le nombre de jours non payés au cours de l'année précédente",
					"showNDiasTrabalhados": "Affiche le nombre de jours travaillés",
					"showNDiasDescontarPorFaltasEmpregado": "Affiche le nombre de jours déduits pour les absences",
					"showNDiasPagos": "Affiche le nombre de jours déjà payés",
					"showInfo": "Afficher les informations"
				}
			},
			"fields": {
				"codEmpregado": "code emp.",
				"nomeEmpregado": "Nom",
				"dataAdmissao": "Date d'admission",
				"nMAxDiasUteisSubsidio": "Nombre maximum.",
				"nDiasPagosAnoAnterior": "Nombre de jours payés l'année précédente",
				"valorPagoAnoAnterior": "Montant payé l'année précédente",
				"nDiasPorPagarAnoAnterior": "Nombre de jours impayés l'année précédente",
				"nDiasTrabalhados": "Nombre de jours travaillés",
				"nDiasDescontarPorFaltasEmpregado": "Nombre de jours déduits pour absences",
				"nDiasPagos": "Nombre de jours déjà payés",
				"nDiasAPagar": "Nombre de jours pour payer",
				"valorAPagar": "Montant à payer",
				"info": "Informations",
				"valorEmMoedaEstrangeira": "Valeur en devise étrangère",
				"valorCambio": "Valeur d'échange",
				"moedaCambio": "Change de devises"
			}
		}
	},
	"rhregistarempregadosss": {
		"consultarMainTabTitle": "Consulter les employés",
		"registarMainTabTitle": "Inscrire les employés",
		"verificarEmpregadosTabTitle": "Vérifier les employés",
		"verificarRegistarContratosTabTitle": "Vérifier/Enregistrer les anciens contrats",
		"registarEmpregadosRegistarTabTitle": "Inscrire un employé",
		"registarEmpregadosCessarTabTitle": "Licencier un employé",
		"registarEmpregadosPerioRendTabTitle": "Période de revenu",
		"promptRegistarEmpregado": "Etes-vous sûr de vouloir inscrire l'employé ?",
		"promptCessarEmpregado": "Etes-vous sûr de vouloir licencier l'employé ?",
		"promptRegPeriodoRendimento": "Êtes-vous sûr de vouloir enregistrer la période de revenus ?",
		"editarContratoModalTitle": "Modifier le contrat",
		"configWS": {
			"modalTitle": "Configuration pour l'enregistrement des travailleurs dans sec.",
			"instruction1Title": "Accès à la sécurité sociale directe",
			"instruction1Text": "Pour adhérer à la plateforme de services d'interopérabilité, vous devez accéder directement à la sécurité sociale (SSD), via le NISS et le mot de passe dont vous disposez à cet effet.",
			"instruction2Title": "Rejoignez la plateforme de services d'interopérabilité",
			"instruction2Text": "Après avoir accédé au SSD, vous trouverez les conditions d'adhésion à la plateforme de services d'interopérabilité dans l'option de menu profil.",
			"saveButton": "Pour enregistrer",
			"seguinte": "Suivant",
			"voltar": "Pour revenir",
			"utilizadorNiss": "Utilisateur (NISS)",
			"senha": "Mot de passe",
			"warnignMsg": "Le nom d'utilisateur et le mot de passe sont ceux de l'entreprise et non ceux accessibles en tant qu'agent."
		},
		"strings": {
			"empregado": "Employé",
			"consultarEmpregados": "Consulter les employés",
			"verificarContratos": "Vérifier les contrats",
			"dados": "Données",
			"taxas": "Frais",
			"vinculo": "Lier",
			"localTrabalho": "Lieu de travail",
			"codemp": "code emp.",
			"niss": "Non, sec.",
			"nome": "Nom",
			"datanascimento": "Date de naissance",
			"vinculocomunicadoem": "Lien communiqué en",
			"iniciodoVinculo": "Début du lien",
			"fimdoVinculo": "Fin du lien",
			"inicioaplicacaoTaxa": "Début des frais de dossier",
			"fimaplicacaotaxa": "Fin de la demande de frais",
			"valortaxa": "Valeur des frais",
			"contratos": "Contrats",
			"isContratoEnviadoParaSS": "A un nouveau contrat chez SS",
			"modalidade": "Modalité",
			"temErros": "Il y a des erreurs",
			"prestacaoTrabalho": "Mise à disposition de travail",
			"inicioContrato": "Démarrer le contrat",
			"fimContrato": "Fin de contrat",
			"inicioInformacao": "Informations sur la maison",
			"fimInformacao": "Fin des informations",
			"profissao": "Profession",
			"remuneracaoBase": "Salaire de base",
			"diuturnidades": "Indemnités d'ancienneté",
			"percentagemTrabalho": "Perc.",
			"horasTrabalho": "heures de travail",
			"diasTrabalho": "Jours de travail",
			"motivoTrabalho": "Raison du travail",
			"nissTrabalhador": "Travailleur du NISS",
			"nomeTrabalhador": "Nom du travailleur",
			"descErros": "Erreurs",
			"atualizarEstadoEmpregados": "Mettre à jour le statut de l'employé",
			"registarContratos": "Enregistrer les contrats",
			"codempregado": "Code employé",
			"dadosEmpregado": "Données des employés",
			"dataAdmissao": "Date d'admission",
			"numEstabelecimentoSS": "N° établissement sec.",
			"tipoContratoTrabalho": "Type de contrat de travail",
			"assinadoEm": "Connecté",
			"validaAte": "Valable jusqu'au",
			"vencimentoBase": "Salaire de base",
			"naoVerificado": "Non vérifié",
			"contratoSegSocial": "Contrat sec.",
			"motivo": "Raison",
			"tempoParcial": "Temps partiel",
			"codEmpSubstituir": "code emp.",
			"dataFimVinculo": "Date de fin de l'obligation",
			"comunicaDesemprego": "Communication à des fins de chômage",
			"fundamentacao": "Raisonnement",
			"registarPerioRendSS": "Enregistrer la période de revenus sec.",
			"dataNovoRegistoPeriodo": "Nouvelle date de règles",
			"vencimentoRegistoPeriodo": "Nouveau salaire de base",
			"diuturnidadeRegistoPeriodo": "Nouvelle valeur diurne",
			"registar": "Registre",
			"confiWsSaved": "La configuration a été enregistrée avec succès",
			"empregadosAtualizadosComSucesso": "Employés mis à jour avec succès",
			"editarContrato": "Modifier le contrat",
			"authenticationError": "L'authentification a échoué."
		},
		"enums": {
			"modalidade": {
				"A": "A - CONGÉDATION, TEMPS PLEIN",
				"B": "B - CONGÉDATION, TEMPS PARTIEL",
				"C": "C - TRAVAIL INTERMITTENT, TEMPS PLEIN",
				"D": "D - TRAVAIL INTERMITTENT, À TEMPS PARTIEL",
				"E": "E - BON DURÉE, TEMPS PLEIN",
				"EA": "EA - AVEC DURÉE, DROIT, TEMPS PLEIN, COMMISSION DE SERVICE",
				"EB": "EB - À TERME, DROIT, TEMPS PLEIN, TÉLÉTRAVAIL",
				"F": "F - CDD, TEMPS PARTIEL",
				"FA": "FA - AVEC DURÉE, DROIT, TEMPS PARTIEL, COMMISSION DE SERVICE",
				"FB": "FB - AVEC CDD, DROIT, TEMPS PARTIEL, TÉLÉTRAVAIL",
				"G": "G - DURÉE INCERTAINE, TEMPS PLEIN",
				"H": "H - DURÉE INCERTAINE, TEMPS PARTIEL",
				"I": "I - TRAVAUX DE TRÈS COURTE DURÉE",
				"J": "J - TÉLÉTRAVAIL, TEMPS PLEIN",
				"K": "K - TÉLÉTRAVAIL, TEMPS PARTIEL",
				"L": "L - COMMISSION DE SERVICE, TEMPS PLEIN",
				"M": "M - COMMISSION DE SERVICE, TEMPS PARTIEL",
				"N": "N - LE BON TERME, JOB.",
				"O": "O - LE BON TERME, TRAVAIL.",
				"P": "P - DURÉE INCERTAINE, EMPLOI.",
				"Q": "Q - DURÉE INCERTAINE, TRAVAIL.",
				"R": "A - POUR UNE DURÉE INDÉTERMINÉE, TRAVAILLEZ.",
				"S": "S - POUR UNE DURÉE INDÉTERMINÉE, TRAVAILLEZ.",
				"AA": "AA - Sans mandat, temps plein, commission de service",
				"AB": "AB - Aucune durée déterminée, temps plein, télétravail",
				"BA": "BA - Aucune durée déterminée, temps partiel, commission de service",
				"BB": "BB - Aucune durée déterminée, temps partiel, télétravail",
				"GA": "GA - Durée incertaine, temps plein, commission de service",
				"GB": "GB - Durée incertaine, temps plein, télétravail",
				"HA": "HA - Durée incertaine, temps partiel, commission de service",
				"HB": "HB - Durée incertaine, télétravail à temps partiel"
			},
			"motivoContrato": {
				"AEAT": "AEAT – Augmentation exceptionnelle de l’activité",
				"ATSA": "ATSA - Activité saisonnière",
				"CTSD": "CTSD - Embauche d'un travailleur en situation de chômage de très longue durée",
				"EOPA": "EOPA - Exécution d'un travail, d'un projet ou d'une activité défini et temporaire",
				"EXTO": "EXTO - Exécution de tâches occasionnelles",
				"IFEE": "IFEE - Début d'exploitation d'une entreprise/établissement de moins de 250 salariés, dans les 2 prochaines années",
				"LNAT": "LNAT - Lancement d'une nouvelle activité à durée incertaine dans une entreprise/établissement de moins de 250 salariés, dans les 2 années suivantes",
				"STAJ": "STAJ - Travailleur de remplacement avec poursuite pour licenciement",
				"STAT": "STAT - Remplacement des travailleurs absents ou temporairement empêchés",
				"STLR": "STLR - Remplacement des travailleurs en congé sans solde",
				"STTC": "STTC - Remplacement de travailleurs à temps plein par du travail à temps partiel pour une durée déterminée"
			},
			"prestacaoTrabalhoContrato": {
				"P": "P - En personne",
				"T": "T - Télétravail"
			},
			"motivoCessar": {
				"CCAI": "CCAI - Licenciement par l'administrateur judiciaire",
				"CCCT": "CCCT – Expiration de CDD",
				"CCEE": "CCEE - Cessation de personne morale / fermeture de l'entreprise / décès de l'employeur",
				"CCFM": "CCFM - Expiration du contrat militaire",
				"CCMT": "CCMT - Expiration du contrat en raison du décès du travailleur",
				"CCRI": "CCRI - Expiration de contrat pour cause de retraite pour invalidité",
				"CCRV": "CCRV - Expiration de contrat pour cause de retraite vieillesse",
				"CDT": "CDT - Affectation permanente de travailleur (Affectation de poste contractuel)",
				"IECC": "IECC – Commission de cessation de service ou situation similaire à l’initiative de l’employeur",
				"IEDC": "IEDC - Licenciement collectif à l'initiative de l'employeur",
				"IEEX": "IEEX - Licenciement pour cessation d'emploi à l'initiative de l'employeur",
				"IEIN": "IEIN - Licenciement pour inaptitude à l'initiative de l'employeur",
				"IEJC": "IEJC - Justification à l'initiative de l'employeur",
				"IEPE": "IEPE – Rupture de contrat pendant la période d'essai à l'initiative de l'employeur",
				"IIAT": "IIAT - Abandon du travail",
				"IIDD": "IIDD - Rupture du contrat de travail/licenciement à l'initiative du travailleur",
				"IIDE": "IIDE - Résiliation du contrat pendant la période d'essai à l'initiative du travailleur",
				"IIJC": "IIJC - Cause juste à l'initiative du travailleur",
				"IISA": "IISA - Justification du salaire impayé à l'initiative du travailleur",
				"RADC": "RADC – Accord de révocation aux termes du paragraphe 4 de l’article 10",
				"RANE": "RANE - Accord de révocation - sans réduction du niveau d'emploi, pour renforcer les qualifications techniques de l'entreprise",
				"RAOT": "RAOT – Accord de révocation non prévu dans les numéros précédents",
				"RARC": "RARC - Accord de révocation - entreprise en processus de redressement",
				"RARD": "RARD - Convention de rétractation - entreprise en restructuration par arrêté",
				"RARE": "RARE - Accord de rétractation - entreprise en situation économique difficile",
				"RARR": "RARR – Accord de révocation – entreprise en restructuration appartenant à un secteur précis",
				"TE": "TE - Transfert d'entreprise"
			}
		},
		"messages": {
			"selectRecords": "Veuillez sélectionner au moins un enregistrement",
			"existemEmpregadosComErro": "Il y a des salariés qui font des erreurs.",
			"empregadoRegistadoComSucesso": "Employé inscrit avec succès",
			"empregadoCessadoComSucesso": "Employé licencié avec succès",
			"erroInesperado": "Une erreur inattendue s'est produite.",
			"periodoRendimentoRegistadoComSucesso": "La période de rendement a été enregistrée avec succès"
		}
	},
	"rhrelatoriounico": {
		"title": "Rapport unique",
		"titleExport": "Exportation du fichier de rapport unique {{year}}",
		"tabProcess": "Exporter",
		"tabDownload": "Fichier de rapport unique",
		"generation": "Génération de fichiers",
		"fileNotProcessed": "Nous sommes désolés, mais pour une raison quelconque, le fichier n'a pas été généré.",
		"jobTimeoutModalTitle": "Tâche",
		"jobTimeoutModalMessage": "La tâche a expiré.",
		"processar": "Processus",
		"processingMessage": "Traitement en cours, veuillez patienter un instant.",
		"erros": "Les erreurs suivantes se sont produites lors du traitement (le fichier peut toujours être disponible en téléchargement dans l'onglet Fichier de rapport unique)",
		"fileReady": "Le fichier de rapport unique est prêt.",
		"promptReprocessTitle": "Un fichier de rapport unique généré précédemment existe déjà",
		"promptReprocessMessage": "Si vous continuez, le système générera à nouveau le fichier.",
		"warningMessage": "Attention: Après avoir démarré le processus, vous disposez de 60 minutes pour télécharger le fichier généré.",
		"promptCancelProcessMessage": "Êtes-vous sûr de vouloir annuler le processus ?",
		"dataCriacao": "Date de création",
		"tamanho": "Taille",
		"utilizador": "Utilisateur",
		"buttons": {
			"reiniciar": "Redémarrage",
			"download": "Télécharger",
			"yes": "Oui",
			"no": "Non",
			"fechar": "Pour fermer"
		},
		"erroDownload": "Une erreur s'est produite lors du téléchargement du fichier!",
		"modal": {
			"config": {
				"title": "Rapport unique - Configuration",
				"nestabelec": "Code",
				"nome": "Nom",
				"nestabQPessoal": "Numéro d'établissement",
				"savesuccess": "Configuration enregistrée avec succès !",
				"idEntidadeEmpty": "Il est nécessaire d'indiquer l'identifiant de l'entité.",
				"idEntidade": "ID d'entité"
			}
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
		"leavePromptMessage": "En cliquant sur OUI, vous quitterez et supprimerez toutes les données de traitement."
	},
	"rhsaltotrf": {
		"fields": {
			"codEmpDe": "Employé de",
			"dataProcesDE": "Date de traitement",
			"bancoDe": "Banque de",
			"fRemuDe": "Forme de rémunération"
		},
		"tabs": {
			"0": "Salaires",
			"1": "Carte/billet de repas",
			"vencimentos": "Salaires",
			"cartaoTicketRefeicao": "Carte/billet de repas"
		},
		"table": {
			"sel": "Sel.",
			"estado": "État",
			"emp": "Employé",
			"nome": "Nom",
			"valor": "Valeur",
			"datalanc": "Date de sortie",
			"iban": "IBAN",
			"swift": "RAPIDE",
			"semdados": "Aucune donnée"
		},
		"messages": {
			"pesqsuccess": "Recherche terminée avec succès",
			"pesqsemdados": "Il n'y a aucune expiration pour le filtre sélectionné",
			"tooltipExcepcoesProcessamento": "Traitement des exceptions",
			"linesnotselected": "Il n'y a aucune ligne sélectionnée à traiter",
			"corrigecartao": "Réparer la carte",
			"removetrans": "Supprimer le transfert",
			"temcertezaremtransf": "Êtes-vous sûr de vouloir supprimer la ligne de transfert ?",
			"ncontaRequired": "Vous devez sélectionner un numéro de compte D/O",
			"listatransfsuccess": "Liste de transfert enregistrée avec succès"
		},
		"modal": {
			"title": "Générer des données de transfert",
			"fields": {
				"dataTransf": "Date de transfert",
				"nContaPoc": "Numéro de compte D/O"
			}
		},
		"config": {
			"title": "Configuration des banques, succursales et comptes"
		},
		"imprimir": {
			"title": "Paiement par banque",
			"titleWithTab": "Paiement par banque - {{activeTab}}"
		}
	},
	"rhtransferenciasbancarias": {
		"tab": {
			"listabancos": "Banques avec tickets repas",
			"exclusaoabonos": "Exclusion des franchises/réductions",
			"transfBancarias": {
				"title": "Virements bancaires",
				"formatoPS2": {
					"title": "Format de fichier PS2",
					"ficheiroFormatoPS2": "Transferts de fichiers au format PS2"
				},
				"cgd": {
					"title": "Banco Caixa Geral de Depósitos",
					"separacaoNibs": "Exportation CGD TXT - Sépare les NIB"
				},
				"dgt": {
					"title": "Direction Générale du Trésor",
					"dgtFormatoSEPA": "Fichier de transfert DGT au format SEPA"
				}
			},
			"codigo": "Code"
		},
		"options": {
			"ticketFormatSEPA": "Transférer le fichier"
		},
		"fields": {
			"codBanco": "Code banque",
			"codOpTrfBa": "code opérationnel",
			"nClienteBanco": "Numéro de client à la banque",
			"nCartaoTrfBaIBAN": "Numéro de carte de transfert",
			"cartaoDifBanco": "Carte réf.",
			"configTrfBaTicket": "Transfert.",
			"tipoFichTrf": "Type de fichier de transfert.",
			"tipo": "Taper",
			"abonodesconto": "Allocation/remise",
			"designacao": "Désignation"
		},
		"modal": {
			"configransfticket": "Configuration du virement bancaire du billet",
			"codBanco": "Banque",
			"codOpTrfBa": "Code opération de virement bancaire (BCO = '48')",
			"nClienteBanco": "Numéro de client à la banque",
			"nCartaoTrfBaIBAN": "Le numéro de carte de virement bancaire est IBAN",
			"cartaoDifBanco": "La banque de cartes repas est différente de la banque de paiement",
			"configTrfBaTicket": "Vous avez mis en place un ticket de virement bancaire",
			"tipoFichTrf": "Type de fichier de transfert ('0 - PS2')",
			"bancoValidator": "Entrez une banque valide"
		},
		"message": {
			"addsuccess": "Banque ajoutée avec succès !",
			"editsuccess": "Banque mise à jour avec succès !",
			"abdescExclusionRepeated": "Exclusion déjà saisie !",
			"abdescempty": "Vous devez sélectionner une allocation/réduction !",
			"savedSuccess": "Paramètres enregistrés avec succès !"
		}
	},
	"rhunidades": {
		"title_detail": "Unité des ressources humaines {{id}}",
		"title_new": "Nouvelle unité des ressources humaines",
		"title_plural": "Unités des ressources humaines",
		"pesquisa": "A chercher",
		"saved": "Unité des ressources humaines {{id}}, enregistrée avec succès.",
		"error": "L'unité des ressources humaines {{id}} ne peut pas être enregistrée.",
		"deleted": "Unité des ressources humaines {{id}}, supprimée avec succès.",
		"fields": {
			"codUnidade": "Code",
			"nome": "Nom",
			"nomeAbreviado": "Nom court",
			"tipoUnidade": "Type d'unité"
		}
	},
	"roles": {
		"title_detail": "Rôle {{id}}",
		"title_plural": "Rôles",
		"pesquisa": "A chercher",
		"fields": {
			"role": "Nom",
			"description": "Description"
		}
	},
	"rptconfigs": {
		"fields": {
			"modulo": "Module",
			"nome": "Nom de la formule",
			"descricao": "Description",
			"valor": "Valeur"
		},
		"modulos": "Modules",
		"variaveisRelatorio": "Variables du rapport",
		"saveSuccess": "Paramètres enregistrés avec succès."
	},
	"saft": {
		"tabProcess": "Exporter",
		"tabDownload": "Fichier SAF-T",
		"generation": "Génération de fichiers",
		"fileNotProcessed": "Nous sommes désolés, mais pour une raison quelconque, le fichier n'a pas été généré.",
		"jobTimeoutModalTitle": "Tâche",
		"jobTimeoutModalMessage": "La tâche a expiré.",
		"contabilidade": "Comptabilité",
		"faturacao": "Facturation",
		"processar": "Processus",
		"processingMessage": "Traitement en cours, veuillez patienter un instant.",
		"erros": "Erreurs",
		"fileReady": "Le fichier SAF-T est prêt.",
		"promptReprocessTitle": "Il existe déjà un fichier SAF-T généré précédemment",
		"promptReprocessMessage": "Si vous continuez, le système générera à nouveau le fichier.",
		"warningMessage": "Attention: Après avoir démarré le processus, vous disposez de 60 minutes pour télécharger le fichier généré.",
		"promptCancelProcessMessage": "Êtes-vous sûr de vouloir annuler le processus ?",
		"dataCriacao": "Date de création",
		"tamanho": "Taille",
		"utilizador": "Utilisateur",
		"documentos": "Documents",
		"buttons": {
			"reiniciar": "Redémarrage",
			"download": "Télécharger",
			"yes": "Oui",
			"no": "Non",
			"fechar": "Pour fermer"
		},
		"erroDownload": "Une erreur s'est produite lors du téléchargement du fichier!",
		"recibos": "Recettes",
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
		"leavePromptMessage": "En cliquant sur OUI, vous quitterez et supprimerez toutes les données de traitement.",
		"contabilidadeFaturacaoRecibos": "Comptabilité, facturation et reçus",
		"autofaturacao": "Autofacturation",
		"nConta": "Fournisseur",
		"title": {
			"normal": "Exporter le fichier SAF-T",
			"autofaturacao": "Exporter le fichier d'autofacturation SAF-T"
		}
	},
	"saftcontab": {
		"steps": {
			"welcome": {
				"step": "Accueillir",
				"title": "Bienvenue dans l'assistant d'importation SAF-T PT pour la comptabilité.",
				"desc2": "Cliquez sur \"Suivant\" pour continuer l'importation.",
				"desc": "Cet importateur est destiné à importer en comptabilité les mouvements comptables via un fichier SAF-T PT."
			},
			"importtype": {
				"step": "Type d'importation",
				"importtypes": "Types d'importations disponibles",
				"fields": {
					"importextratocomsi": "Relevé d'importation (Avec importation des soldes d'ouverture)",
					"importextratosemsi": "Relevé d'importation (Sans Import des soldes d'ouverture)",
					"importsaldosiniciais": "Importer uniquement les soldes d’ouverture",
					"importterceiros": "Importez uniquement des comptes tiers"
				}
			},
			"upload": {
				"step": "Fichier SAFT",
				"title": "Veuillez choisir le fichier que vous souhaitez importer",
				"import": "Importer Importer le fichier de configuration",
				"export": "Exporter Importer le fichier de configuration"
			},
			"fields": {
				"nDiario": "Numéro quotidien",
				"nome": "Nom",
				"diarioOriem": "Journal source",
				"diarioDestino": "Journal cible",
				"contaOrigem": "Compte source",
				"contaDestino": "Compte cible",
				"nConta": "Numéro de compte",
				"criaConta": "Pour créer",
				"codControlIvaDebito": "Contrôle de la TVA au débit",
				"codControlIvaCredito": "Contrôle des crédits de TVA",
				"nif": "Contribuable",
				"documento": "Document",
				"valorBase": "Valeur de base",
				"valorIvaCalculado": "Valeur TVA probable",
				"taxaCalculada": "Taux probable",
				"nContasBase": "Nombre de comptes de base",
				"nContasIVA": "Nombre de comptes TVA",
				"valorBaseDoc": "Valeur de base doc.",
				"valorIVADoc": "Valeur TVA doc.",
				"taxaSobreTotalIVA": "Tarif hors TVA totale",
				"contaCredito": "Compte de crédit",
				"contaDebito": "Compte de débit",
				"descricao": "Nom",
				"valor": "Valeur",
				"contaExistente": "Comptes existants",
				"convertConta": "Comptes avec conversion",
				"contasSemConversao": "Comptes sans conversion",
				"nContaDestino": "Numéro de compte de destination",
				"mercado": "Marché"
			},
			"associacaoDiarios": {
				"step": "Association de revues",
				"title": "Association des journaux comptables",
				"desc": "Veuillez indiquer si vous souhaitez associer des revues à des revues existantes."
			},
			"planoContas": {
				"step": "Plan comptable",
				"title": "Plan comptable",
				"filters": {
					"contasComErro": "Afficher uniquement les comptes comportant des erreurs",
					"contasComConversao": "Afficher uniquement les comptes avec conversion",
					"contasSemConversao": "Afficher uniquement les comptes non convertis"
				},
				"tooltip": {
					"importMatriz": "Importer la matrice de conversion"
				},
				"btn": {
					"importMatriz": "Matrice d'importation"
				}
			},
			"convertContas": {
				"step": "Conversion de compte",
				"title": "Conversion de compte",
				"desc": "Veuillez indiquer la matrice de conversion souhaitée pour les comptes du plan comptable."
			},
			"contasExist": {
				"step": "Comptes existants",
				"title": "Comptes qui existent déjà dans le plan comptable",
				"desc": "Veuillez vérifier les comptes qui seront importés et qui existent déjà dans le plan comptable.",
				"desc1": "Si vous le souhaitez, vous pouvez remplacer le compte cible."
			},
			"contasSemConvert": {
				"step": "Comptes sans conversion",
				"title": "Comptes sans conversion définie",
				"desc": "Veuillez indiquer les comptes non convertis que vous avez l'intention de créer."
			},
			"contasCorrentes": {
				"step": "Comptes courants",
				"title": "Comptes courants à créer",
				"apenasContasMovimentadas": "Importe uniquement les comptes actifs",
				"efetuaConversaoContasCorrentes": "Effectue la conversion vers le compte cible en fonction du marché"
			},
			"configImport": {
				"step": "Paramètres",
				"title": "Paramètres d'importation",
				"fields": {
					"diario": "Numéro quotidien",
					"periodo": "Période",
					"ndescritivo": "Description des versions",
					"descritivosaldosiniciais": "Numéro descriptif.",
					"saldosiniciais": "Soldes d'ouverture",
					"diarioapura": "Journal des résultats dans le fichier SAF-T",
					"transactiondate": "Effectuer l'import dans lequel la période est formée à partir de <TransactionDate> (attention, à utiliser uniquement lorsque le fichier SAF-T a changé de date)",
					"diaroAbertura": "Ouverture",
					"descritivoSaldosIniciais": "Solde d'ouverture",
					"justificaContasCorrenteMovAb": "Justifier les mouvements ouverts en comptes courants"
				},
				"messages": {
					"nDescritivoError": "Les entrées descriptives ne peuvent pas être vides.",
					"periodoSaldosIniciaisError": "La période ne peut pas être vide.",
					"nDiarioSaldosIniciaisError": "Le numéro quotidien ne peut pas être vide.",
					"nDescritivoSaldosIniciaisError": "Le numéro descriptif ne peut pas être vide.",
					"justificaContasCorrenteMovAbInfo": "Les mouvements du compte courant seront automatiquement justifiés jusqu'à la dernière période où le fichier SAFT présente des écritures.",
					"importarSaftContab": "Traitement de l'import de la comptabilité SAF-T..."
				}
			},
			"finalized": {
				"step": "Résultat",
				"successfully": "Opération terminée avec succès!"
			}
		},
		"modals": {
			"processing": {
				"title": "Importateur comptable SAF-T",
				"processModalLoad": "Processus de chargement",
				"processModalCancel": "Annuler le processus"
			},
			"planos": {
				"title": "Cadre du plan comptable du compte \"{{nConta}}\"",
				"hint": "Cadre du plan comptable"
			},
			"convertcontas": {
				"title": "Conversion de compte",
				"contaorigem": "Compte d'origine",
				"contadestino": "Compte de destination"
			},
			"balanceteNaoSaldado": "La balance de vérification du fichier SAFT n'est pas équilibrée.",
			"import": {
				"title": "Importer",
				"configimportedsuccess": "Configuration importée avec succès."
			},
			"importMatriz": {
				"title": "Importation de la matrice de conversion",
				"content": {
					"1": "Bienvenue dans l'assistant d'importation de matrice de conversion.",
					"2": "Veuillez sélectionner le modèle que vous souhaitez télécharger.",
					"3": "Après avoir téléchargé le fichier, remplissez-le avec les données que vous souhaitez importer."
				},
				"btn": {
					"modClean": "Télécharger le modèle vierge",
					"modFilled": "Télécharger le modèle complété"
				}
			}
		},
		"messages": {
			"timeoutModalMessage": "Nous sommes désolés, mais le délai d'importation du fichier comptable SAF-T est expiré.",
			"importProcessModalMessageCancelJob": "Le processus d'importation a déjà commencé.",
			"jobCancelled": "Le processus d'importation a été annulé avec succès.",
			"alertTimerEndProcess": "Attention: Après avoir démarré le processus, vous disposez de 60 minutes pour terminer l'importation.",
			"jobTimeoutModalTitle": "Importation comptable SAF-T",
			"jobTimeoutModalMessage": "La tâche a expiré.",
			"processarFicheiroLabel": "Traitement du dossier comptable SAF-T...",
			"noFiles": "Sélectionnez le fichier à traiter...",
			"noTypeImport": "Vous n'avez pas sélectionné le type d'importation.",
			"accountsWithError": "Certains comptes contiennent des erreurs.",
			"accountsWithoutSel": "Certains comptes ne sont pas marqués pour être créés.",
			"errorocur": "Une erreur interne s'est produite.",
			"contaOrigemVazia": "Vous n'avez pas sélectionné de compte source.",
			"contaDestinoVazia": "Vous n'avez pas sélectionné de compte cible.",
			"erroDownload": "Une erreur s'est produite lors du téléchargement du fichier !",
			"importsuccess": "Importation terminée avec succès !",
			"operationsuccess": "Opération terminée avec succès !",
			"aprepararImportSaft": "Préparation du processus d'import comptable SAF-T..."
		},
		"btns": {
			"processLoad": "",
			"processReset": ""
		},
		"strings": {
			"jobTimeoutModalTitle": "Tâche",
			"planoSaft": "Plan SAFT",
			"planoCG": "Plan CentralGest"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmation",
		"leavePromptMessage": "En cliquant sur OUI, vous quitterez et supprimerez toutes les données de traitement.",
		"importTypePromptMessage": "La modification du type d'importation supprimera toutes les données de traitement."
	},
	"scss": {
		"or": "ou",
		"and": "et",
		"locked-portal": "Vous n'avez pas accès à ce portail",
		"locked-portal-cgstore": "Vous n'avez pas accès à ce portail, merci de vous rendre sur le portail shopping en cliquant sur le bouton « Boutique ».",
		"locked-portal-expired": "Ce portail a été bloqué car le plan associé a expiré."
	},
	"seccoes": {
		"title_detail": "Section {{id}}",
		"title_new": "Nouvelle rubrique",
		"title_plural": "Sections",
		"pesquisa": "A chercher",
		"saved": "Section {{id}}, enregistrée avec succès",
		"error": "La section {{id}} ne peut pas être enregistrée.",
		"deleted": "Section {{id}}, supprimée avec succès",
		"fields": {
			"nSeccao": "Code de section",
			"designacaoBreve": "Brève désignation",
			"designacaoCompleta": "Désignation complète"
		}
	},
	"sectores": {
		"title_detail": "Secteur {{id}}",
		"title_new": "Nouveau Secteur",
		"title_plural": "Secteurs",
		"pesquisa": "A chercher",
		"saved": "Secteur {{id}}, enregistré avec succès",
		"error": "Le secteur {{id}} ne peut pas être enregistré.",
		"deleted": "Secteur {{id}}, supprimé avec succès",
		"fields": {
			"sector": "Code de l'industrie",
			"nome": "Nom",
			"idExterno": "ID externe"
		}
	},
	"segur": {
		"title_detail": "Compagnie d'assurance {{id}}",
		"title_new": "Nouvelle compagnie d'assurance",
		"title_plural": "Compagnies d'assurance",
		"pesquisa": "chercher",
		"saved": "Compagnie d'assurance {{id}}, enregistrée avec succès.",
		"error": "La compagnie d'assurance {{id}} ne peut pas être enregistrée.",
		"deleted": "Compagnie d'assurance {{id}}, supprimée avec succès",
		"fields": {
			"nsegur": "code des assurances",
			"designaBreve": "Brève description",
			"nome": "Descriptif complet",
			"morada": "Ménage",
			"npostal": "Code postal",
			"localidade": "Emplacement",
			"telefone": "Téléphone",
			"napolice": "Politique",
			"codSeguradoraISP": "Code des assurances (FAI)"
		},
		"errors": {
			"invalidApolice": "Le format de fichier de feuille de vacances exige que le numéro de police comporte 20 caractères."
		}
	},
	"simpleLogin": {
		"at": {
			"userNameFieldLabel": "Numéro de contribuable"
		},
		"ssd": {
			"userNameFieldLabel": "NISS"
		},
		"viactt": {
			"userNameFieldLabel": "Utilisateur"
		},
		"saveTitle": "Sauvegarder",
		"passwordFieldLabel": "Mot de passe",
		"messages": {
			"successLogin": "Connectez-vous avec succès.",
			"credentialsEmpty": "Le nom d'utilisateur ou le mot de passe est vide."
		}
	},
	"simulacaoVencimento": {
		"fields": {
			"dgemp": "Collaborateur",
			"nDiasUteis": "Nombre de jours ouvrables",
			"valorLiquido": "Valeur nette à obtenir",
			"dataSimulacao": "Date de simulation"
		},
		"text": {
			"simularValor": "Simuler la valeur nette",
			"reciboSimulado": "Reçu simulé",
			"vencimento": "Valeur brute à l'échéance simulée",
			"totalIliquido": "Total brut",
			"totalDesconto": "Remise complète",
			"totalLiquido": "Total net",
			"totalEspecie": "Espèces totales",
			"totalAPagar": "Total à payer"
		},
		"table": {
			"tipoAbono": "Type d'allocation",
			"codAbDesc": "code d'allocation",
			"descricao": "Description",
			"qtd": "Qté.",
			"valorUnit": "Valeur unitaire.",
			"valor": "Valeur",
			"incidencia": "Incidence",
			"percentagem": "Pourcentage"
		}
	},
	"sinalTaxaRetencao": {
		"data": {
			"negativo": "-",
			"positivo": "+"
		}
	},
	"sind": {
		"title_detail": "Fichier Union {{id}}",
		"title_new": "Nouveau dossier syndical",
		"title_plural": "Fichiers syndicaux",
		"pesquisa": "A chercher",
		"saved": "Fichier Union {{id}}, enregistré avec succès.",
		"error": "Le fichier Union {{id}} ne peut pas être enregistré.",
		"deleted": "Fichier Union {{id}}, supprimé avec succès",
		"fields": {
			"nSindicato": "numéro d'union",
			"nome": "Nom",
			"designaBreve": "Brève désignation",
			"morada": "Ménage",
			"npostal": "Code postal",
			"localidade": "Emplacement",
			"telefone": "Téléphone",
			"fax": "Fax",
			"nContrib": "Numéro de contribuable",
			"nBanco": "code banque",
			"nAgencia": "Code agence",
			"contaGenBanc": "Numéro de compte bancaire",
			"bairroFiscal": "Département des finances",
			"valorMinQuot": "Valeur minimale de l'action",
			"nomeLocalidade": "Emplacement",
			"nomeBairroFiscal": "Département des finances"
		}
	},
	"situacaoprofissional": {
		"title_detail": "Statut professionnel {{id}}",
		"title_new": "Nouvelle situation professionnelle",
		"title_plural": "Situation des métiers",
		"pesquisa": "A chercher",
		"saved": "Statut professionnel {{id}}, enregistré avec succès",
		"error": "Le statut de profession {{id}} ne peut pas être enregistré.",
		"deleted": "Statut professionnel {{id}}, supprimé avec succès",
		"fields": {
			"nSitProfiss": "Code de statut professionnel",
			"designaBreve": "Brève désignation",
			"designaComp": "Désignation complète",
			"codQuadroPessoal": "Tableau du personnel"
		}
	},
	"situacaoempregado": {
		"title_detail": "Statut d'employé {{id}}",
		"title_new": "Statut de nouvel employé",
		"title_plural": "Situations des employés",
		"pesquisa": "A chercher",
		"saved": "Statut d'employé {{id}}, enregistré avec succès",
		"error": "Le statut d'employé {{id}} ne peut pas être enregistré.",
		"deleted": "Statut d'employé {{id}}, supprimé avec succès",
		"fields": {
			"nSitEmpreg": "Code situation",
			"designaBreve": "Brève désignation",
			"designaCompl": "Désignation complète",
			"processaVenc": "Date d'échéance du processus"
		}
	},
	"solic": {
		"title_detail": "Texte de l'avis de demande de facturation {{id}}",
		"title_new": "Texte de l'avis de nouvelle demande de facturation",
		"title_plural": "Textes d’avis de demande de prélèvement",
		"pesquisa": "A chercher",
		"saved": "Texte de l'avis de demande de facturation {{id}}, enregistré avec succès.",
		"error": "Le texte de l'avis de demande de facturation {{id}} ne peut pas être enregistré.",
		"deleted": "Texte de l'avis de demande de facturation {{id}}, supprimé avec succès.",
		"fields": {
			"tipoTexto": "Taper",
			"nTexto": "Nombre",
			"tituloTexto": "Titre",
			"nDiasAtraso": "Nombre de jours de retard",
			"note": "Description"
		},
		"datasource": {
			"0": "En-tête",
			"1": "Plinthe",
			"cabecalho": "En-tête",
			"rodape": "Plinthe"
		}
	},
	"sscol": {
		"title_detail": "Indemnisation de la sécurité sociale {{id}}",
		"title_new": "Nouvelle rémunération de la Sécurité Sociale",
		"title_plural": "Rémunérations de la Sécurité Sociale",
		"pesquisa": "A chercher",
		"saved": "Rémunération de la sécurité sociale {{id}}, enregistrée avec succès.",
		"error": "La rémunération de la sécurité sociale {{id}} ne peut pas être enregistrée.",
		"deleted": "Rémunération de la sécurité sociale {{id}}, supprimée avec succès.",
		"fields": {
			"codSSCol": "Code",
			"designacao": "Désignation",
			"dias": "Jours",
			"valor": "Valeur"
		}
	},
	"subde": {
		"title_detail": "Sous-département {{id}}",
		"title_new": "Nouveau sous-département",
		"title_plural": "Sous-départements",
		"pesquisa": "A chercher",
		"saved": "Sous-département {{id}}, enregistré avec succès.",
		"error": "Le sous-département {{id}} ne peut pas être enregistré.",
		"deleted": "Sous-département {{id}}, supprimé avec succès",
		"fields": {
			"nSubDe": "Code.",
			"nSubDePlaceholder": "Code du sous-département",
			"nome": "Nom",
			"nomePlaceholder": "Nom du sous-département"
		}
	},
	"subfamilias": {
		"title_detail": "Sous-famille {{id}}",
		"title_new": "Nouvelle sous-famille",
		"title_plural": "Sous-familles",
		"pesquisa": "A chercher",
		"saved": "Sous-famille {{id}}, enregistrée avec succès.",
		"error": "Sous-famille {{id}}, ne peut pas être enregistrée.",
		"deleted": "Sous-famille {{id}}, supprimée avec succès",
		"fields": {
			"nSubFa": "Code.",
			"nSubFaPlaceholder": "Code de sous-famille",
			"nome": "Nom",
			"nomePlaceholder": "Nom de sous-famille"
		}
	},
	"suspensaoContrato": {
		"data": {
			"tscNA": "N / A",
			"tscSim": "Oui",
			"tscNao": "Non"
		}
	},
	"svat": {
		"messages": {
			"noData": "Veuillez choisir l'année et appuyer sur le bouton « Traiter » pour afficher les données."
		},
		"modal": {
			"title": "États financiers SVAT - Année : {{currentyear}}",
			"titleBalancete": "Bilan"
		},
		"fields": {
			"descricao": "Rubrique",
			"notas": "Remarques",
			"anoN": "Année N",
			"anoN1": "Année N-1",
			"nContaDe": "Numéro de compte",
			"nContaAte": "Le nombre compte jusqu'à",
			"taxonomyCode": "Taxonomie",
			"taxonomyDescription": "Description",
			"taxonomyClasse": "Classe",
			"taxonomySaldoEsperado": "Solde attendu",
			"mesPeriodo": "Période de mois",
			"tipoSaldo": "Type de solde",
			"nomeCliente": "Nom",
			"nConta": "Numéro de compte"
		},
		"strings": {
			"mainTabBalanco": "Équilibre",
			"mainTabDemoResultadosPorNaturezas": "Démonstration des résultats par nature",
			"rubricaSubTabIntervaloContas": "Plages de comptes",
			"rubricaSubTabBalancete": "Bilan",
			"verBalanceteTitulo": "Afficher la balance de vérification"
		}
	},
	"taxasiva": {
		"title_detail": "Notez {{id}}",
		"title_new": "Nouveau tarif",
		"title_plural": "Taux de TVA",
		"pesquisa": "A chercher",
		"saved": "Notez {{id}}, enregistré avec succès",
		"error": "Tarif{{id}}, ne peut pas être enregistré.",
		"deleted": "Notez {{id}}, supprimé avec succès",
		"fields": {
			"codIva": "Code TVA",
			"dataAltLegal": "Date de changement légal",
			"taxa": "Taux",
			"observacao": "Observation"
		},
		"criar": "Maintien du taux de TVA",
		"criada": "Taux de TVA créé avec succès.",
		"codIvaNull": "Le code TVA ne peut pas être nul"
	},
	"taxasretencao": {
		"title_new": "Nouveau taux de rétention",
		"title_detail": "{{id}} taux de rétention",
		"title_plural": "Taux de rétention",
		"saved": "Taux de rétention {{id}}, enregistré avec succès",
		"error": "Taux de rétention {{id}}, ne peut pas être enregistré.",
		"deleted": "Taux de rétention {{id}}, supprimé avec succès",
		"pesquisa": "A chercher",
		"fields": {
			"codRet": "Code de rétention",
			"nomeRet": "Nom",
			"taxaRet": "Taux (%)",
			"tipoCodRet": "Type de code",
			"sinal": "Signal de mouvement",
			"tipRendiment": "Type de revenu",
			"nContaRetencao": "Compte de rétention",
			"nomeTipRendiment": "Type de revenu",
			"nomeContaRetencao": "Compte de rétention"
		},
		"tipoRendimento": {
			"title": "Type de revenu",
			"fields": {
				"declaracao": "Déclaration",
				"tipoRendimento": "Type de revenu",
				"regTrib": "Régime fiscal"
			}
		},
		"declaracaoRetFonte": {
			"title": "Déclaration de retenue à la source",
			"fields": {
				"zona": "Zone",
				"rubrica": "Rubrique"
			}
		}
	},
	"taxonomias": {
		"exportXls": "Grille d'exportation",
		"autoCorrect": "Appliquer des correctifs automatiques",
		"autoCorrectSuccess": "Correctifs appliqués avec succès",
		"autoCorrectDatas": "Appliquer des corrections automatiques aux dates",
		"autoCorrectDatasMessageOkCancel": "Lors de l'application de la correction automatique des dates du document, les dates invalides seront remplacées par le dernier jour de la période à laquelle le document fait référence.<br/>Êtes-vous sûr de vouloir continuer ?",
		"autoCorrectDatasTitleOkCancel": "Attention!",
		"autoCorrectDoc0": "Appliquer des corrections automatiques aux documents avec une seule entrée et avec une valeur de 0",
		"autoCorrectClasse9": "Appliquer des corrections automatiques aux documents de classe 9 non réglés",
		"autoCorrectClasse0": "Appliquer des corrections automatiques aux documents de classe 0 non réglés",
		"autoCorrectDocsSemLinhas": "Appliquer des corrections automatiques aux documents sans lignes",
		"autoCorrectMessageClasse0": "Lors de l'application de la correction automatique aux documents de classe 0 impayés, le système comptabilisera automatiquement le compte {{nconta}} afin de payer la classe 0.<br/>Êtes-vous sûr de vouloir continuer ?",
		"autoCorrectMessageClasse9": "Lors de l'application d'une correction automatique aux documents de classe 9 non réglés, le système comptabilisera automatiquement le compte {{nconta}} afin de régler l'analyse.<br/>Êtes-vous sûr de vouloir continuer ?",
		"autoCorrectMessageClasseDocValor": "Lors de l'application de la correction automatique aux documents comportant une seule ligne de valeur 0, le système saisira le même compte avec le signe opposé dans tous les documents de cette situation.<br/>Êtes-vous sûr de vouloir continuer ?",
		"relacaoContas": {
			"nContaDe": "Numéro de compte",
			"nContaAte": "Le nombre compte jusqu'à",
			"codTaxonomia": "code de taxonomie",
			"descricao": "Description"
		},
		"informacaoLegal": {
			"codTaxonomia": "code de taxonomie",
			"codSNC": "Code SNC",
			"descricao": "Description",
			"observacoes": "Observations",
			"classe": "Classe",
			"saldoEsperado": "Solde attendu",
			"demFinanCampDeb": "Champ de débit des états financiers",
			"demFinanCampCred": "Champ de crédit des états financiers"
		},
		"planoOficial": {
			"nConta": "Numéro de compte",
			"nome": "Nom"
		},
		"validacoesPlano": {
			"fase": "Phase",
			"conta": "Numéro de compte",
			"mensagem": "Message",
			"mensagemCorrecaoAutomatica": "Message de correction automatique",
			"contaterceirosuccess": "Compte courant tiers créé avec succès.<br/>Voulez-vous modifier le compte tiers ?"
		},
		"validacoesSvat": {
			"showOnlyErrors": "Afficher uniquement les comptes comportant des erreurs",
			"tableFirstHeader": {
				"contasComSaldo": "Comptes avec solde",
				"valSVAT": "Validations SVAT pour le type comptable - \"Organisé avec SNC\""
			},
			"tableSecondHeader": {
				"contas": "Comptes",
				"saldos": "Soldes - Aucun calcul de résultats"
			},
			"tableThirdHeader": {
				"nConta": "Numéro de compte",
				"nome": "Nom",
				"tipo": "Taper",
				"conta": "Compte",
				"codTaxonomia": "code de taxonomie",
				"descricao": "Description",
				"classe": "Classe",
				"saldoEsperado": "Solde attendu",
				"dezembroNormal": "Décembre (normal)",
				"dezembroFirst": "Décembre (1ère Clôture)",
				"dezembroSecond": "Décembre (2e clôture)"
			}
		},
		"validacoesLancamentos": {
			"tipo": "Taper",
			"fase": "Phase",
			"conta": "Compte",
			"periodo": "Période",
			"diario": "Tous les jours",
			"numeroDocInterno": "Document interne n°"
		},
		"relacaocontasTab": "Relation des comptes avec les taxonomies",
		"informacaoLegalTab": "Mentions légales",
		"planoOficialTab": "Plan officiel publié",
		"validacoesSvatTab": "Validations SVAT",
		"validacoesPlanoTab": "Validations des plans",
		"validacoesLancamentosTab": "Validations des versions",
		"confirmValidacoesLancamentos": "Souhaitez-vous valider les saisies ?<br/>Cette opération peut prendre quelques minutes.",
		"actions": {
			"criarContaTerceiro": "Créer un compte tiers",
			"trfConta": "Transfert de compte",
			"manutencaoPoc": "Tenue du plan comptable",
			"extratos": "Relevé de compte"
		},
		"modalContaSalda": {
			"titleAnalitica": "Veuillez choisir le compte avec lequel vous souhaitez payer les analyses dans les documents",
			"titleClasse0": "Veuillez choisir le compte avec lequel vous souhaitez payer la classe 0 dans les documents",
			"titleNormal": "Veuillez choisir le compte que vous souhaitez pour la correction des documents",
			"conta": "Compte"
		},
		"grids": {
			"title": "Taxonomies {{typeTaxonomy}} - {{typeAccounting}}"
		}
	},
	"templates": {
		"title_detail": "Modèle {{id}}",
		"title_new": "Nouveau modèle",
		"title_plural": "Modèles",
		"pesquisa": "A chercher",
		"saved": "Modèle {{id}}, enregistré avec succès",
		"error": "Le modèle {{id}} ne peut pas être enregistré.",
		"fields": {
			"name": "Nom",
			"subject": "Sujet",
			"body": "Corps",
			"variables": "Variables"
		},
		"text": {
			"sendEmail": "Envoyer un e-mail"
		}
	},
	"terceiros": {
		"clientes": "Clients",
		"fornecedores": "Fournisseurs",
		"outros": "Autres débiteurs et créanciers"
	},
	"terms": {
		"title": "Conditions générales et politique de confidentialité CentralGest Cloud",
		"version": "Version <strong>{{version}}</strong>",
		"introduction": "Avant d'utiliser CentralGest Cloud, vous devez accepter nos conditions générales d'utilisation du service et notre politique de confidentialité.",
		"titleTerms": "Termes et conditions",
		"titlePrivacyPolicy": "politique de confidentialité",
		"changedTerms": "La politique de confidentialité ou les conditions générales d'utilisation du service CentralGest Cloud ont <strong>été mises à jour</strong> depuis la dernière fois que vous les avez acceptées, veuillez les consulter et les accepter.",
		"acceptLabel": "J'ai lu et accepté les termes et conditions d'utilisation du service et la politique de confidentialité",
		"acceptTooltip": "Pour continuer, vous devez accepter les termes et conditions d'utilisation du service et la politique de confidentialité",
		"errorRequired": "Vous devez accepter les termes, conditions et la politique de confidentialité de CentralGest Cloud pour accéder à l'application."
	},
	"tesestados": {
		"title_detail": "Statut de validation des prévisions de trésorerie {{id}}",
		"title_new": "Nouvel état de validation des prévisions de trésorerie",
		"title_plural": "États de validation des prévisions de trésorerie",
		"pesquisa": "A chercher",
		"saved": "Statut de validation des prévisions de trésorerie {{id}}, enregistré avec succès.",
		"error": "Statut de validation des prévisions de trésorerie {{id}}, ne peut pas être sauvegardé.",
		"deleted": "Statut de validation des prévisions de trésorerie {{id}}, supprimé avec succès",
		"fields": {
			"codEstado": "Code d'état",
			"codEstadoPlaceholder": "Code d'état de validation des prévisions de trésorerie",
			"descricao": "Description",
			"descricaoPlaceholder": "Description de l'état",
			"estadoValidacao": "Statut de validation",
			"estadoValidacaoPlaceholder": "Choisir le statut de validation"
		}
	},
	"tesrubrica": {
		"title_detail": "Statut de validation des prévisions de trésorerie {{id}}",
		"title_new": "Nouveau statut de validation des prévisions de trésorerie",
		"title_plural": "États de validation des prévisions du Trésor",
		"pesquisa": "A chercher",
		"deleted": "Le statut de validation des prévisions de trésorerie a été supprimé avec succès.",
		"saved": "Statut de validation des prévisions de trésorerie {{id}}, enregistré avec succès.",
		"error": "L'état de validation des prévisions de trésorerie {{id}} ne peut pas être enregistré.",
		"fields": {
			"tesRubricaID": "Code de rubrique",
			"nome": "Nom",
			"receitaDespesa": "Revenus/Dépenses"
		},
		"tipoReceitaDespesa": {
			"receita": "Revenu",
			"despesa": "Frais"
		}
	},
	"themes": {
		"light": "Bien sûr",
		"dark": "Sombre",
		"auto": "Automatique"
	},
	"tipoAbonoAgrupado": {
		"data": {
			"nd": "N / A",
			"vencimentos": "Maturité",
			"subRefeicao": "Subventions pour les repas",
			"remunAdicionais": "Rémunération supplémentaire",
			"subsidios": "Subventions",
			"rendEspecie": "Rendement des espèces",
			"cessacaoContrato": "Résiliation du contrat",
			"gratificacoes": "Pourboires"
		}
	},
	"tipoAbonoDesc": {
		"data": {
			"abono": "Allocation",
			"desconto": "Rabais"
		}
	},
	"tipoAnalitica": {
		"data": {
			"lancaConformeDefinidoIntegracao": "Lancements tels que définis dans l'intégration",
			"naoLancaAnalitica": "Ne lance pas l'analyse",
			"lancaSempreAnalitica": "Lancez toujours Analytics"
		}
	},
	"tipoAssiduidadeSegSocial": {
		"data": {
			"taaNA": "N / A",
			"taaSim": "Oui",
			"taaNao": "Non"
		}
	},
	"tiposAusencias": {
		"data": {
			"na": "N / A",
			"porFerias": "Pour les vacances",
			"porDoencaImprevista": "En raison d'une maladie imprévue",
			"porDoencaProf": "En raison d'une maladie professionnelle",
			"porDoencaProlongada": "En raison d'une maladie prolongée",
			"porTribunal": "Par le tribunal",
			"porServicoMilitar": "Pour le service militaire",
			"porAssistenciaFamiliar": "Pour l'aide à la famille",
			"porPaternidadeMaternidade": "Par paternité/maternité",
			"porLicencaAurorizada": "Par licence autorisée",
			"porPenaDisciplinar": "Pour sanction disciplinaire",
			"porLicencaSabatica": "En congé sabbatique",
			"porCasamento": "Par mariage",
			"porNascimentoFamiliar": "Par naissance d'un membre de la famille",
			"porFalecimentoFamiliar": "En raison du décès d'un membre de la famille",
			"porTrabalhadorEstudante": "Par travailleur étudiant",
			"porGreveParalizacao": "Par grève/arrêt",
			"porConsultaMedico": "Par consultation médicale, tests diagnostiques",
			"outras": "Autres",
			"porFaltaInjustificada": "En raison d'une absence injustifiée",
			"actividadeSindical": "Par activité syndicale",
			"acidenteTrabalho": "Suite à un accident du travail"
		}
	},
	"tiposBalancoSocial": {
		"data": {
			"tabsNA": "N / A",
			"tabsSim": "Oui",
			"tabsNao": "Non"
		}
	},
	"tipoBem": {
		"pesquisa": "Types de bien",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"notApplicable": "N / A",
			"stocks": "Actions",
			"otherGoodsServices": "Autres biens et services",
			"immobilized": "Immobilisations"
		}
	},
	"tiposCategoria": {
		"data": {
			"notApplicable": "Aucun groupe",
			"vencimento": "Maturité",
			"diuturnidade": "Ancienneté",
			"premios": "Récompenses",
			"comissoes": "Commissions",
			"subRefeicao": "Sous.",
			"ajudaCusto": "Allocation de frais",
			"horaExtra": "Au fil du temps",
			"ferias": "Vacances",
			"subFerias": "Sous.",
			"feriasPagas": "Congés payés",
			"subNatal": "Sous.",
			"trabNocturno": "Travail de nuit",
			"insencaoHorario": "Dispense d’horaire",
			"subTurno": "Sous.",
			"faltasPagas": "Absences payées",
			"indemnizacaoCaducidade": "Indemnité de confiscation",
			"indemnizacaoDespedimento": "Indemnité de licenciement",
			"indemnizacaoGlobal": "Rémunération complète",
			"deslocacao": "Voyage",
			"remProfLivre": "Rémunération professionnelle gratuite",
			"iva": "T.V.A.",
			"virtual": "Virtuel",
			"suplementoRemuneratorio": "Supplément de rémunération (Allocation pour échecs)",
			"ticketValesRefeicao": "Billet/titres restaurant",
			"pagamentosEmEspecie": "Paiement en nature",
			"valesEducacao": "Chèques-éducation",
			"utlViaturaPatronal": "Utilisation du véhicule de l'employeur",
			"gratificacoesBombeiros": "Primes des pompiers",
			"cartaoOferta": "Carte cadeau",
			"apoioRegimeLayoff": "Soutien au régime de licenciement",
			"despesasTeletrabalho": "Frais de télétravail",
			"utilizHabitaPropriaPermanente": "Utilisation du logement fourni par l'employeur",
			"gratificacaoBalancoArt236": "Art. du bonus de solde."
		}
	},
	"tipoClasseContrato": {
		"data": {
			"contratoServico": "Contrat de service",
			"contratoPrecoCliente": "Contrat tarif client",
			"contratoPrecoFornecedor": "Accord sur la liste de prix du fournisseur",
			"contratoSubContratacao": "Contrat de sous-traitance",
			"contratoFornecimentoCliente": "Contrat de fourniture client",
			"contratoFornecimentoFornecedor": "Contrat de fourniture fournisseur",
			"contratoAlocacaoRecurso": "Contrat d'allocation de ressources"
		}
	},
	"tipoConta": {
		"pesquisa": "Types de comptes",
		"fields": {
			"value": "Valeur",
			"label": "Nom"
		},
		"data": {
			"movement": "Mouvement",
			"subtotal": "Total",
			"reason": "Raison",
			"class": "Classe",
			"cc": "Compte courant"
		}
	},
	"tipoContaDiasSubAlimentacao": {
		"data": {
			"notApplicable": "N / A",
			"yes": "Oui",
			"no": "Non"
		}
	},
	"tipoContabilidade": {
		"data": {
			"none": "Il n'a pas",
			"organizadaPOCDL35": "Organisé avec le POC DL 35",
			"naoOrganizadaPOCDL35": "Non organisé avec POC DL 35",
			"organizadaSNC": "Organisé avec la SNC",
			"naoOrganizadaSNC": "Non organisé avec la SNC",
			"organizadaPOCIPSS": "Organisé avec POC IPSS",
			"organizadaPOCAL": "Organisé avec POC AL",
			"organizadaPOCP": "Organisé avec POC P",
			"organizadaPOCMZ": "Organisé avec POC MZ",
			"naoOrganizadaPOCMZ": "Non organisé avec POC MZ",
			"organizadaPGCAN": "Organisé avec PGC AN",
			"naoOrganizadaPGCAN": "Non organisé avec PGC AN",
			"planoGeralContasNIRFMZ": "Plan général comptes NIRF MZ",
			"organizadaSNCIPSS": "Organisé avec la SNC IPSS",
			"organizadaSNCPE": "Organisé avec SNC PE",
			"organizadaSNCME": "Organisé avec SNC ME",
			"organizadaEntSectorNLucrativo": "Organisé - Entités du secteur à but non lucratif",
			"naoOrganizadaArt50CodIVA": "Non organisé - Article 50 du code TVA",
			"organizadaSNCAP": "Organisé avec SNC AP"
		}
	},
	"tipoContrato": {
		"data": {
			"semtipocontrato": "Aucun type de contrat",
			"contratosemtermo": "Contrat sans durée (permanent)",
			"contratoatermo": "Contrat à durée déterminée",
			"contratotrabtempoindeterm": "Contrat de travail.",
			"contratotrabalhotermocedtemp": "Contrat de travail à durée déterminée pour mission temporaire",
			"contratoprestoserviço": "Contrat de prestation de services"
		}
	},
	"tipoContratotrab": {
		"data": {
			"tempoIndeterminado": "Contrat à durée indéterminée",
			"termoCerto": "Contrat à durée déterminée",
			"termoIncerto": "Contrat à durée incertaine"
		}
	},
	"tipoDeducao": {
		"pesquisa": "Types de déduction",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"notApplicable": "N / A",
			"withDeduction": "Avec déduction",
			"noDeduction": "Aucune déduction",
			"withCountryRefundOrigin": "Avec remboursement du pays d'origine"
		}
	},
	"tipoDeducaoTaxa": {
		"pesquisa": "Types de déduction fiscale",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"normal": "Normale",
			"intraCommunity": "Intracommunautaire",
			"passiveSubjectInversion": "Inversion de matière imposable",
			"notApplicable": "N / A"
		}
	},
	"tipoDescontoAgrupado": {
		"data": {
			"nd": "N / A",
			"faltas": "Fautes",
			"penhoras": "Liens",
			"impostos": "Impôts"
		}
	},
	"tipoDescritivo": {
		"pesquisa": "Types de description",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"receipt": "Reçu",
			"acceptedLetter": "Lettre acceptée",
			"acceptedDiscounted": "Remise acceptée",
			"acceptedPaymentDiscounted": "Paiement accepté avec remise",
			"acceptedReturnDiscounted": "Retour accepté à prix réduit",
			"acceptedReformDiscounted": "Retraite acceptée à prix réduit",
			"acceptedPaymentNotDiscounted": "Paiement accepté sans escompte",
			"acceptedReturnNotDiscounted": "Retour accepté non encaissé",
			"acceptedReformNotDiscounted": "Retraite acceptée non actualisée",
			"preDatedCheck": "Chèque postdaté",
			"preDatedCheckDiscount": "Remise sur chèque postdaté",
			"preDatedCheckPaymentDiscounted": "Paiement par chèque postdaté encaissé",
			"preDatedCheckReturnDiscounted": "Retour du chèque postdaté encaissé",
			"preDatedCheckRetirementDiscounted": "Chèque postdaté de la réforme encaissé",
			"preDatedCheckPaymentNotDiscounted": "Paiement par chèque postdaté non encaissé",
			"preDatedCheckReturnNotDiscounted": "Retour de chèque postdaté non encaissé",
			"preDatedCheckRetirementNotDiscounted": "Réforme des chèques postdatés non encaissés",
			"saqueSemAceite": "Retrait sans acceptation",
			"DescontoSaqueSemAceite": "Retrait de la remise sans acceptation",
			"pagamentoSaqueSemAceiteDiscounted": "Paiement retiré sans acceptation escompté",
			"devoluçãoSaqueSemAceiteDiscounted": "Retour de rétractation sans acceptation remise",
			"reformaSaqueSemAceiteDiscounted": "Retrait de retraite sans acceptation réduite",
			"pagamentoSaqueSemAceiteNotDiscounted": "Paiement retiré sans acceptation non escompté",
			"devoluçãoSaqueSemAceiteNotDiscounted": "Retour de rétractation sans acceptation non escompté",
			"reformaSaqueSemAceiteNotDiscounted": "Retrait de la réforme sans acceptation non actualisé",
			"doesNotControlVAT": "Ne contrôle pas la TVA",
			"documentManagement": "Gestion des documents",
			"firstAdoptionNewAccountingReference": "Première adoption du nouveau cadre comptable",
			"accountingPolicyChanges": "Modifications de méthodes comptables",
			"differencesTranslationFinancialStatements": "Différences de conversion des états financiers",
			"realizationExcessesRevaluationTangibleIntangibleAssets": "Réalisation des plus-values ​​de réévaluation des immobilisations corporelles et incorporelles",
			"excessesRevaluationTangibleIntangibleAssets": "Plus-values ​​de réévaluation des immobilisations corporelles et incorporelles",
			"deferredTaxAdjustments": "Ajustements d'impôts différés",
			"otherChangesShareholdersEquity": "Autres modifications des capitaux propres",
			"netIncome": "revenu net",
			"capitalRealizations": "Réalisations en capital",
			"performanceEmissionPremiums": "Émettre des succès premium",
			"distributions": "Distribution",
			"lossCoverageEntries": "Entrées pour couvrir les pertes",
			"otherOperations": "Autres opérations"
		}
	},
	"tipoEntSuporteABDES": {
		"data": {
			"None": "N / A",
			"Empregado": "Employé",
			"Patronato": "Patronage",
			"EmpregadoPatronato": "Salarié et employeur"
		}
	},
	"tipoEntidadeSuporta": {
		"data": {
			"taesNA": "À",
			"taesTrabalhador": "Ouvrier",
			"taesEntidadePatronal": "Employeur",
			"taesSegSocial": "Lundi social",
			"taesSeguro": "Sûr",
			"taesSindicato": "Syndicat",
			"taesOutros": "Autres"
		}
	},
	"tipoExcecaoCRABD": {
		"data": {
			"SemExcecoes": "Aucune exception",
			"ExcluirEmpregados": "Exclusion",
			"IncluirEmpregados": "Inclusion"
		}
	},
	"tipoInventario": {
		"data": {
			"naotemInvPerm": "Pas d'inventaire perpétuel mensuel",
			"temInvPermMensalCG": "Il y a un inventaire permanent mensuel réalisé dans CentralGest",
			"temInvPermMensalManual": "Un inventaire permanent mensuel est-il effectué manuellement"
		}
	},
	"tipoLiquidacao": {
		"pesquisa": "Types de règlement",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"notApplicable": "N / A",
			"withSettlement": "Avec liquidation",
			"noSettlement": "Aucun règlement"
		}
	},
	"tipoOperacao": {
		"pesquisa": "Types d'opération",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"notApplicable": "N / A",
			"active": "Actif",
			"passive": "Passif",
			"regularizationFavorState": "Régularisation au profit de l'Etat",
			"regularizationFavorCompany": "Régularisation au profit de l'entreprise"
		}
	},
	"tipoOrdDocumentos": {
		"data": {
			"dataNContribuinte": "Dates |",
			"nContribuinteData": "Numéro de contribuable |",
			"dataRececao": "Date de réception",
			"nomeData": "Nom |",
			"dataNome": "Dates |"
		}
	},
	"tipoPasswordRecibo": {
		"data": {
			"nao": "Non",
			"igualNIF": "Oui, avec un mot de passe égal au NIF",
			"igualNumeroCC": "Oui, avec un mot de passe égal à votre numéro de carte de citoyen",
			"igualNumeroSS": "Oui, avec un mot de passe égal à votre numéro de sécurité sociale",
			"igualCodigoFuncionario": "Oui, avec un mot de passe égal au code employé"
		}
	},
	"tipoRecibo": {
		"data": {
			"taSim": "Oui",
			"taNao": "Non"
		}
	},
	"tipoValorUnitarioRecolher": {
		"data": {
			"Indiferente": "Par défaut",
			"ValorVariavel": "Valeur variable",
			"ValorFixo": "Valeur fixe",
			"REmuneracaoHora": "Salaire horaire",
			"REmuneracaoHoraBase": "Salaire horaire de base",
			"VencimentoBase": "Salaire de base",
			"REmuneracaoMensalTotal": "Rémunération mensuelle totale",
			"RemuneracaoPorDiaUtil": "Rémunération par jour ouvrable",
			"REmuneracaoPorDiaBase30": "Rémunération par jour / 30",
			"REmuneracaoMensalSubsidioBase22": "Salaire mensuel de base / 22",
			"REmuneracaoMensalSubsidioBase30": "Salaire mensuel de base / 30",
			"REmuneracaoHora2": "Heure de paie 2"
		}
	},
	"tipoRegularizacaoCampo40": {
		"pesquisa": "Types de régularisations Champ 40",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"notApplicable": "N / A",
			"art78N2": "Art.º 78 nº 2 - Réduction ou annulation de la valeur d'une transaction",
			"art78N3": "Art.º 78 nº 3 - Rectification de l'impôt payé en trop",
			"art78N6": "Art.º 78 nº 6 - Correction d'erreurs supplémentaires de matière ou de calcul",
			"art78N7Pre2013ItemA": "Art.º 78 nº 7 crédits irrécouvrables avant 2013 paragraphe a) en cours d'exécution",
			"art78N7Pre2013ItemB": "Art.º 78 nº 7 créances irrécouvrables avant 2013 paragraphe b) dans une procédure d'insolvabilité",
			"art78N7Pre2013ItemC": "Art.º 78 nº 7 crédits irrécouvrables avant 2013 paragraphe c) en processus spécial de revitalisation",
			"art78N7Pre2013ItemD": "Art.º 78 nº 7 crédits irrécouvrables avant 2013 alinéa d) dans le Système de Reprise Extrajudiciaire des Entreprises (SIREVE)",
			"art78N7Post2013ItemA": "Art.º 78 nº 7 crédits irrécouvrables après 2013 paragraphe a) en cours d'exécution",
			"art78N7Post2013ItemB": "Art.º 78 nº 7 créances irrécouvrables après 2013 paragraphe b) dans une procédure d'insolvabilité",
			"art78N7Post2013ItemC": "Art.º 78 nº 7 crédits irrécouvrables après 2013 paragraphe c) en processus spécial de revitalisation",
			"art78N7Post2013ItemD": "Art.º 78 nº 7 crédits irrécouvrables après 2013 paragraphe d) en processus spécial de revitalisation",
			"art78N8ItemD": "Art.º 78 nº 8 paragraphe d) - Les crédits, SP, sont inférieurs à 6000 € reconnus dans le cadre d'une procédure judiciaire - ROC",
			"art78N8ItemB": "Art.º 78 nº 8 paragraphe b) - Les crédits, autres que SP, sont supérieurs à 750 et inférieurs à 8000 € reconnus en procédure judiciaire, sans avoirs - ROC",
			"art78N8ItemC": "Art.º 78 nº 8 paragraphe c) - Les crédits, autres que SP, sont supérieurs à 750 et inférieurs à 8000 € reconnus en justice, avec actifs - ROC",
			"art78N8ItemE": "Art.º 78 nº 8 paragraphe e) - Les crédits, autres que SP, sont supérieurs à 750 et inférieurs à 8000 € reconnus en justice, sur la liste des faillis - ROC",
			"art78AN4ItemA": "Art. 78 - N° 4 (a) - Crédits en procédure d'exécution, sans avoirs",
			"art78AN4ItemB": "Art.º 78 - Alinéa 4 b) - Crédits dans une procédure d'insolvabilité après avoir été prononcés",
			"art78AN4ItemC": "Art. 78 - N° 4 (c) - Crédits spéciaux de revitalisation, après approbation",
			"art78AN4ItemD": "Art.º 78 - N° 4 alinéa d) - Dans le Système de Reprise Extrajudiciaire des Entreprises (SIREVE), après signature de la convention",
			"art78AN2ItemA": "Art.º 78 - Alinéa 2 (a)",
			"art78BN4": "Art.º 78 - B nº 4 - Dans les créances douteuses, avec approbation tacite de déduction",
			"adjustmentsWhoseCreditDoesNotExceed750VATIncluded": "Régularisations dont le crédit n'excède pas 750 € TVA incluse",
			"otherRegularizationsNotCoveredArt78RegularizationCoveredArticles23to26": "Autres régularisations non visées à l'article 78 - Régularisations visées aux articles 23 à 26",
			"otherRegularizationsNotCoveredByArt78": "Autres régularisations non couvertes par l'article 78 - Autres régularisations (par exemple fusion)"
		}
	},
	"tipoRegularizacaoCampo41": {
		"pesquisa": "Types de régularisations du champ 41",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"notApplicable": "N / A",
			"art78N3": "Art.º 78 nº 3 - Rectification de l'impôt sous-payé",
			"art78N4": "Art.º 78 nº 4 - Rectification de l'impôt retenu en trop",
			"art78N6": "Art.º 78 nº 6 - Correction des erreurs de matière ou de calcul sauf si",
			"art78N7ItemA": "Art.",
			"art78N7ItemB": "Article 78, paragraphe 7, paragraphe b) - Article 78, paragraphe 11, par communication de régularisation, dans le cadre d'une procédure d'insolvabilité",
			"art78N7ItemC": "Art.º 78 nº 7 paragraphe c) - Art.º 78, nº 11, par communication de régularisation, dans un processus spécial de revitalisation",
			"art78N7ItemD": "Art.",
			"art78N8ItemD": "Art.º 78 nº 8 paragraphe d) - Art.º 78, nº 11, par communication de régularisation, SP, est inférieur à 6000 € reconnu en procédure judiciaire",
			"art78N12": "Art.º 78 nº 12 - En récupération de crédits sur lesquels la TVA a déjà été déduite",
			"art78CN1": "Art.º 78 - C nº1 - Par communication de régularisation, SP, en créances douteuses, avec approbation tacite de déduction",
			"art78CN3": "Art.º 78 - C nº3 - En recouvrement de créances douteuses dans lesquelles la TVA a déjà été déduite",
			"otherRegularizationsNotCoveredByArt78RegularizationCoveredArticles23to26": "Autres régularisations non visées à l'article 78 - Régularisations visées aux articles 23 à 26",
			"otherRegularizationsNotCoveredByArticle78": "Autres régularisations non couvertes par l'article 78 - Autres régularisations (ex: fusion)",
			"article6OfDecreeLawN198/90of19June": "Article 6 du décret-loi n° 198/90 du 19 juin",
			"decreeLawNo19/2017OfFebruary14": "Décret-loi n° 19/2017 du 14 février"
		}
	},
	"tipoResumoMensal": {
		"pesquisa": "Types de résumés mensuels",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"naoUsa": "N'utilise pas",
			"aPartirDoUltimoDia": "Depuis le dernier jour",
			"aPartirDoUltimoDiaUtil": "Depuis le dernier jour ouvrable",
			"aPartirDoPrimeiroDiaDoMesSeguinte": "Dès le premier jour du mois suivant",
			"aPartirDoPrimeiroDiaUtilDoMesSeguinte": "Dès le premier jour ouvrable du mois suivant"
		}
	},
	"tipoTaxa": {
		"pesquisa": "Types de frais",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"notApplicable": "N / A",
			"exempt": "Gratuit",
			"reduced": "Réduit",
			"intermediate": "Intermédiaire",
			"normal": "Normale"
		}
	},
	"tipoTaxaIva": {
		"pesquisa": "Types de taux de TVA",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"exempt": "Exempter",
			"reduced": "Réduit",
			"normal": "Normale",
			"intermediate": "Intermédiaire",
			"doesNotDeduct": "Ne déduit pas",
			"diesel50Percent": "Diesel 50%",
			"exemptEU": "Exonération de l'UE",
			"exemptNotEU": "Exonéré hors UE"
		}
	},
	"tipoTaxaRetencao": {
		"data": {
			"rendimentos": "Revenu",
			"subNatal": "Sous.",
			"subFerias": "Sous."
		}
	},
	"tipoTransmissaoActiva": {
		"pesquisa": "Types de transmission active",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"notApplicable": "N / A",
			"transmissionSubject": "Transmission du sujet",
			"advanceReceiptsYear": "Recettes prévues pour l'année",
			"opLocalizedForeignProviderReverseCharge": "Op. situé ici par ravitaillement.",
			"transmitFreeWithoutDeductible": "Transmission exonérée sans droit à déduction",
			"civilConstructionServicesReverseCharge": "Services de construction civile avec autoliquidation (TVA à la charge de l'acquéreur)",
			"transmissionScrapReverseCharge": "Transmission de ferraille avec autoliquidation (TVA à la charge de l'acquéreur)",
			"transmissionExemptOrNotSubjectDeduction": "Cession exonérée ou non avec droit à déduction - art 21, al.",
			"transmissionExemptTourismVehicles": "Transmission exonérée de véhicules touristiques art. 9, n° 32 (qui ne déduisait pas la TVA)",
			"transmissionExemptVAT": "Transfert exonéré de TVA soumis à l'IMT art.",
			"priorYearReceiptsWithDefinitiveBillingYear": "Encaissements anticipés de l’année précédente avec facturation finale dans l’année",
			"establishmentTransmission": "Transfert d'établissement art. 3, n° 4.",
			"otherActiveOperationsOutsideCIVAField": "Autres opérations actives hors du champ CIVA",
			"intraCommunityTransmission": "Transmission intracommunautaire - Art.º 14 RITI",
			"transmissionExport": "Transmission à l'exportation",
			"opMNLocalizedProviderEUResidentsReverseCharge": "Op. situé MN par ravitaillement.",
			"transmissionGoldReverseCharge": "Transmission d'or (DL 362/99) avec autoliquidation (TVA due par l'acquéreur)",
			"transmissionRealEstateWaiverExemptionReverseCharge": "Transfert de propriété sans exonération d'autoliquidation (TVA à la charge de l'acquéreur)",
			"opFromAllocationOwnUseFreeTransmissionsAllocationSectorExemptActivity": "Possibilité d'attribution pour usage propre, transmissions gratuites et attribution à un secteur d'activité exonéré",
			"opBankingActivity": "Activité bancaire op.",
			"insuranceBusinessOpportunity": "Activité d'assurance op.",
			"opportunityRentalRealEstate": "Option activité de location immobilière",
			"triangularIntraCommunityTransmission": "Transmission intracommunautaire triangulaire – Art. 8 et 15 RITI",
			"intraCommunityService": "Prestation de services intracommunautaires - Art. 6 CIVA",
			"transmissionNewMeansTransportEUIndividuals": "Transfert de nouveaux moyens de transport aux particuliers de l'UE",
			"greenhouseGasEmissionReverseCharge": "Émission de gaz à effet de serre avec recharge inversée",
			"operacoesEfetuadasNoEstrangeiro": "Opérations réalisées à l'étranger",
			"outrasOperacoesIsentasOuSemLiquidacaoDeIVAQueConferemDireitoADeducao": "Autres opérations exonérées ou sans TVA qui donnent droit à déduction"
		}
	},
	"tipoTransmissaoPassiva": {
		"pesquisa": "Types de transmission passive",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"notApplicable": "N / A",
			"acquisitionTotalTaxDeductionOthers": "Acquisition avec déduction totale de TVA - Autres",
			"acquisitionPartialDeductionVATOthers": "Acquisition avec déduction partielle de TVA - Autres",
			"acquisitionPartialDeductionVAT": "Acquisition avec déduction partielle de TVA - (article 21 du CIVA - Gazoil)",
			"acquisitionPartiallyTotallyDeductibleDeductionTourismVehicles": "Acquisition avec franchise partielle ou totale - Véhicules de tourisme",
			"acquisitionGasolineFullyNonDeductibleTax": "Achat d'essence avec TVA totalement non déductible",
			"acquisitionTourismTravelTotallyNonDeductibleTaxAcquisition": "Acquisition de via.",
			"acquisitionTourismTravelFullyNonDeductibleTaxRentOtherExpenses": "Acquisition de via.",
			"acquisitionGoodsServicesTotallyNonDeductibleTaxOther": "Acquisition de biens et services avec TVA totalement non déductible - Autres",
			"acquisitionTaxExemptGoodsServices": "Acquisition de biens et services exonérés de TVA",
			"acquisitionGoodsServicesCoveredSchemeRetailingPetitioners": "Acquisition de biens et services couverts par l'exonération et le petit régime.",
			"acquisitionExemptionVATSubmissionIMT": "Acquisition exonérée de TVA soumise à l'IMT art.",
			"acquisitionEstablishment": "Acquisition d'établissement art. 3, n° 4.",
			"otherCIVAOutFieldLiabilities": "Autres opérations passives hors champ CIVA",
			"acquisitionCivilConstructionServices": "Acquisition de services de construction civile (TVA à la charge de l'acquéreur)",
			"acquisitionScrap": "Acquisition de ferraille (TVA à la charge de l'acquéreur)",
			"intraCommunityAcquisitionExemptOrigin": "Acquisition intracommunautaire - Exonérée à l'origine",
			"acquisitionCEESubjectOrigin": "Acquisition dans les CEE - Sujet à l'origine",
			"importAcquisition": "Importer des acquisitions",
			"mNAcquisitionForeignSupplierReverseCharge": "Acquisition MN par fourniture.",
			"mNAcquisitionEUResidentsReverseCharge": "Acquisition MN par fourniture.",
			"goldAcquisitionReverseCharge": "Acquisition d'or (DL 362/99) avec autoliquidation (IvaDevidoPeloAcquirente)",
			"acquisitionPropertiesWaiverExemptionReverseCharge": "Acquisition de biens immobiliers sans exonération d'autoliquidation (TVA à la charge de l'acquéreur)",
			"emissionGreenhouseGases": "Émission de gaz à effet de serre",
			"aquisicaoDeCorticaEOutrosProdutosDeOrigemSilvicola": "Acquisition de liège et autres produits d'origine forestière"
		}
	},
	"tipoUnidadesValue": {
		"data": {
			"valor": "Valeur",
			"hora": "Temps",
			"minuto": "Minute",
			"segundo": "Deuxième",
			"dia": "Jour",
			"mes": "Mois",
			"ano": "Année",
			"horautil": "Horaires de travail",
			"horabase30": "Temps de base 30",
			"diautil": "Jour ouvrable",
			"diabase30": "Jour de base 30"
		}
	},
	"tipoNif": {
		"pesquisa": "Types de validation NIF",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"validoseminformacaodeActividade": "Valable sans informations d'activité",
			"nifInvalidoInexistente": "NIF invalide et inexistant",
			"validoSemActividade": "Valable sans activité",
			"validoComActividade": "Valable avec activité",
			"validoComActividadeCessada": "Valable avec cessation d'activité"
		}
	},
	"tipoValorPenhora": {
		"emfraccao": "En fraction",
		"empercentagem": "En pourcentage",
		"valorabsoluto": "Valeur absolue",
		"porinsolvencia": "En raison de l'insolvabilité"
	},
	"tipoValorizacao": {
		"data": {
			"custoMedioPonderado": "Coût moyen pondéré",
			"precoUltimaCompra": "Dernier prix d'achat",
			"precoBaseCusto": "Prix ​​de base",
			"custoMedio": "Coût moyen",
			"custoPadrao": "Coût standard"
		}
	},
	"tipofacpublicamodelo": {
		"data": {
			"nd": "Ne s'applique pas",
			"cius": "CIUS-PT",
			"ciusip": "Infrastructures au Portugal",
			"ciussaphety": "Saphety CIUS-PT",
			"ciusfeap": "FE-AP eSAP"
		}
	},
	"tipohorario": {
		"title_detail": "Type d'heure {{id}}",
		"title_new": "Nouveau type d'horaire",
		"title_plural": "Types d'horaires",
		"pesquisa": "A chercher",
		"saved": "Type de programme {{id}}, enregistré avec succès",
		"error": "Type de programme {{id}}, ne peut pas être enregistré.",
		"deleted": "Type d'heure {{id}}, supprimé avec succès",
		"fields": {
			"nTipoHorario": "Type d'horaire",
			"designaBreve": "Brève désignation",
			"designaCompl": "Désignation complète"
		}
	},
	"tiposArredondamentoABDES": {
		"data": {
			"NaoArredonda": "N'arrondit pas",
			"UnidMaisProxima": "À l'unité, la plus proche",
			"DezenaMaisProxima": "À la dizaine près",
			"Baixo1": "Basse 1",
			"Cima1": "Jusqu'à 1",
			"Baixo10": "Faible 10",
			"Cima10": "Jusqu'à 10",
			"ParaDecimaMaisProxima": "Au dixième, le plus proche",
			"ParaCentesimaMaisProxima": "Au centième près",
			"ParaMilesimaMaisProxima": "Au millième le plus proche",
			"ParaDezenaMaisInferior": "À dix, plus bas",
			"ParaDezenaMaisSuperior": "Pour dix, plus supérieur",
			"ParaDecimaMaisInferior": "Au dixième, le plus bas",
			"ParaDecimaMaisSuperior": "Au dixième, le plus supérieur",
			"ParaCentesimaMaisInferior": "Au centième, le plus bas",
			"ParaCentesimaMaisSuperior": "Au centième, le plus supérieur",
			"ParaMilesimaMaisInferior": "Au millième, le plus bas",
			"ParaMilesimaMaisSuperior": "Au millième, le plus supérieur",
			"ParaCentenaMaisProxima": "À cent, plus près"
		}
	},
	"tiposCategoriaDesc": {
		"data": {
			"segGrupo": "Aucun groupe",
			"irs": "IRS",
			"segSocial": "Sécurité sociale",
			"cga": "CGA",
			"impostoSelo": "Taxe de timbre",
			"sindicato": "Union",
			"seguro": "Assurance",
			"falta": "Manque",
			"ferias": "Vacances",
			"punicao": "Punition",
			"retImpTerceiros": "Retenues imposées par des tiers",
			"subsidioRefeicao": "Subvention pour les repas",
			"outra": "Autre",
			"sobretaxaExtra": "Supplément extraordinaire",
			"adse": "ADSE",
			"penhora": "Pion",
			"fct": "FCT - Caisse d'indemnisation du travail",
			"fgct": "FGCT - Fonds de Garantie des Indemnités du Travail",
			"penhoraPensaoAlimentos": "Saisie-arrêt de pension alimentaire",
			"indemnizacaoFaltaAvisoPrevio": "L’indemnisation manque de préavis"
		}
	},
	"tipoComunicacaoAt": {
		"data": {
			"manual": "par fichier",
			"emTempoReal": "par Web-Service"
		}
	},
	"tipoCredito": {
		"data": {
			"credito1": "Crédit 1",
			"credito2": "Crédit 2",
			"credito3": "Crédit 3",
			"credito4": "Crédit 4",
			"credito5": "Crédit 5"
		}
	},
	"tiposDescontoProporcionalABDES": {
		"data": {
			"naoProcessa": "Ne traite pas",
			"porQuantidade": "Par quantité",
			"porNHorasDia": "Par nombre d'heures par jour"
		}
	},
	"tiposEmpresa": {
		"data": {
			"nomeIndividual": "Entreprise individuelle",
			"sociedadeQuotas": "Société à responsabilité limitée",
			"sociedadeAnonima": "Société anonyme",
			"sociedadeCooperativa": "Société coopérative",
			"associacao": "Association",
			"servicosMunicipalizados": "Services municipalisés",
			"sociedadeUnipessoal": "Entreprise individuelle",
			"agrupComplementarEmrpesas": "Groupe de sociétés complémentaire",
			"camaraMunicipal": "Conseil municipal",
			"empresaMunicipal": "Corporation municipale",
			"institutoPublico": "institut public",
			"federacaoDesportiva": "fédération sportive",
			"sociedadeAnonimaDesportiva": "Société par actions sportive",
			"escolaSuperior": "École supérieure",
			"estabelecimentoEstavelSocEstrangeira": "Établissement social stable."
		}
	},
	"tipoFormacao": {
		"data": {
			"tfNA": "N / A",
			"tfSim": "Oui",
			"tfnao": "Non"
		}
	},
	"tiposIVA": {
		"data": {
			"0": "Exempter",
			"1": "Réduit",
			"2": "Normale",
			"3": "Intermédiaire",
			"4": "Ne déduit pas",
			"5": "Diesel 50%",
			"6": "Exonération CEE (Commission économique européenne)",
			"7": "Exonéré hors CEE (Commission économique européenne)",
			"isenta": "Exempter",
			"reduzida": "Réduit",
			"normal": "Normale",
			"intermedia": "Intermédiaire",
			"naoDeduz": "Ne déduit pas",
			"gasoleo50": "Diesel 50%",
			"isentoCEE": "Exonération CEE (Commission économique européenne)",
			"isentoForaCEE": "Exonéré hors CEE (Commission économique européenne)"
		}
	},
	"tiposMovimento": {
		"pesquisa": "Types de mouvements",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"notDefined": "N / A",
			"default": "Par défaut",
			"squareMeters": "Mètres carrés",
			"cubicMeters": "Mètres cubes",
			"linearMeters": "Mètres linéaires",
			"grams": "Grammes",
			"kilos": "kilos",
			"tons": "Tonnes",
			"liters": "Litres",
			"hectoliters": "Hectolitres",
			"perimeter": "Périmètre",
			"packing": "Conditionnement",
			"hours": "Heures"
		}
	},
	"tipoValidacaoNif": {
		"pesquisa": "Rechercher des types de situations NIF",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"notApplicable": "N / A",
			"requiresValidNIF": "Nécessite un NIF valide",
			"requiresValidNIFAllowsInexistent": "Nécessite un NIF valide mais autorise des informations inexistantes",
			"doesNotRequireNIF": "Ne nécessite pas de NIF"
		}
	},
	"tipoPeriocidade": {
		"data": {
			"Sempre": "Toujours",
			"Mensal": "Mensuel",
			"Anual": "Annuel",
			"Quinzenal": "Bimensuel",
			"Semanal": "Hebdomadaire"
		}
	},
	"tiposPresenca": {
		"data": {
			"tapNA": "N / A",
			"tapNao": "Non",
			"tapSim": "Oui"
		}
	},
	"tiposRefeicoes": {
		"pesquisa": "Types de comptes",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"notDefined": "N / A",
			"launch": "Déjeuner",
			"lightLaunch": "Déjeuner léger",
			"dinner": "Pour déjeuner",
			"lightDinner": "Dîner léger"
		}
	},
	"tipoRemunerado": {
		"data": {
			"notApplicable": "N / A",
			"yes": "Oui",
			"no": "Non"
		}
	},
	"tiposRetServNIF": {
		"data": {
			"adquirenteDeServicos": "Acheteur de services",
			"prestadoraDeServicos": "Prestataire de services"
		}
	},
	"tipoTrabalho": {
		"data": {
			"tatNA": "N / A",
			"tatTrabalhoNormal": "travail normal",
			"tatTrabEXTRANormal": "Heures supplémentaires normales",
			"tatTrabEXTRACompPeriodoNormaTrabalho": "Heures supplémentaires rémunérées pour les heures normales de travail",
			"tatTrabEXTRACompPeriodoFerias": "Heures supplémentaires compensées par des vacances",
			"tatTrabDiasDescansoComplementar": "Travailler les jours de repos complémentaires','Travailler les jours de repos hebdomadaire",
			"tatTrabDiasDescansoSemanal": "Travailler les jours de repos hebdomadaire",
			"tatTrabDiasFeriado": "Travailler les jours fériés"
		}
	},
	"tiposanexo": {
		"continente": "Continent",
		"madeira": "Bois",
		"acores": "Açores",
		"decRecapitulativa": "État récapitulatif",
		"campo40": "Champ 40",
		"campo41": "Champ 41"
	},
	"tiposartigo": {
		"title_detail": "Type d'article {{id}}",
		"title_new": "Nouveau type d'article",
		"title_plural": "Types d'articles",
		"pesquisa": "A chercher",
		"saved": "Type d'article {{id}}, enregistré avec succès.",
		"error": "Le type d'article {{id}} ne peut pas être enregistré.",
		"deleted": "Type d'article {{id}} supprimé avec succès",
		"fields": {
			"codTpArt": "Code.",
			"CodPlaceholder": "Code du type d'article",
			"nome": "Nom",
			"nomePlaceholder": "Nom du type d'article",
			"productCategory": "Type de produit (inventaire)",
			"productCategoryPlaceholder": "Type de produit en inventaire"
		}
	},
	"tiposcontrato": {
		"title_detail": "Type de contrat {{id}}",
		"title_new": "Nouveau type de contrat",
		"title_plural": "Types de contrat",
		"pesquisa": "A chercher",
		"saved": "Type de contrat {{id}}, enregistré avec succès.",
		"error": "Le type de contrat {{id}} ne peut pas être enregistré.",
		"deleted": "Type de contrat {{id}}, supprimé avec succès.",
		"fields": {
			"nTipoContrato": "Code.",
			"designacaoBreve": "Brève désignation",
			"designacaoCompleta": "Désignation complète",
			"codTipCtQPessoal": "Type de contrat Q. Personnel",
			"naoEnviaparaDMR": "N'envoie pas à DMR"
		}
	},
	"tiposregistocadastro": {
		"title_detail": "Type d'inscription {{id}}",
		"title_new": "Nouveau type d'inscription",
		"title_plural": "Types d'inscription",
		"pesquisa": "A chercher",
		"deleted": "Type d'enregistrement supprimé avec succès.",
		"saved": "Type d'inscription {{id}}, enregistré avec succès.",
		"error": "Le type d'enregistrement {{id}} ne peut pas être enregistré.",
		"fields": {
			"codQPMOT": "Code de raison Q. Personnel",
			"designaBreve": "Brève description",
			"designaCompl": "Descriptif complet",
			"infobalancosoc": "Informations pour le bilan social:",
			"nMotivoMov": "Types d'enregistrements"
		},
		"nodes": {
			"altRegimeContr": "Modification du régime contractuel",
			"antDaCessacao": "Anticipation de cessation",
			"aumVencimento": "Augmentation de salaire",
			"cessacao": "Cessation",
			"comJustaCausa": "Avec une juste cause",
			"contComTermo": "Contrat à durée déterminée",
			"contComTermoCerto": "Contrat à durée déterminée",
			"contComTermoIncerto": "Contrat à durée incertaine",
			"contSemTermo": "Contrat à durée indéterminée (en vigueur)",
			"despColetivo": "Licenciement collectif",
			"despedimento": "Congédiement",
			"entradas": "Entrées",
			"falecimento": "La mort",
			"impedProlong": "Hors-jeu prolongé",
			"iniEmpresa": "Initiative de l'entreprise",
			"inicTrabalhador": "Initiative des travailleurs",
			"movPessoas": "Mouvement des personnes",
			"mutuoAcordo": "D'un commun accord",
			"passouASemTermo": "Cela a duré pour toujours",
			"porAntigui": "Par ancienneté",
			"porMerito": "Au mérite",
			"porOutroMotiv": "Pour une autre raison",
			"preReforma": "Pré-réforme",
			"promo": "Promotions",
			"reconvReclass": "Reconversions/Reclassement",
			"reformaAntec": "Préretraite",
			"reformaPorInval": "Retraite d'invalidité",
			"reformaPorVelh": "Retraite de vieillesse",
			"saidas": "Sorties",
			"semJustaCausa": "Sans juste cause",
			"termIncerto": "Terme incertain",
			"termoCerto": "Terme correct"
		}
	},
	"tipotempo": {
		"pesquisa": "Types de temps",
		"fields": {
			"value": "Valeur",
			"name": "Nom"
		},
		"data": {
			"completo": "À temps plein",
			"parcial": "Temps partiel"
		}
	},
	"tirs": {
		"title_detail": "Taxe IRS {{id}}",
		"title_new": "Nouveau taux IRS",
		"title_plural": "Frais IRS",
		"pesquisa": "A chercher",
		"saved": "Taux IRS {{id}}, enregistré avec succès.",
		"error": "Le taux IRS {{id}} ne peut pas être enregistré.",
		"deleted": "Taxe IRS {{id}}, supprimée avec succès.",
		"fields": {
			"nTabIrs": "Code de table IRS",
			"designaBreve": "Brève désignation",
			"designaCompl": "Désignation complète",
			"dtulTabela": "Date d'entrée",
			"datasTaxasIrs": "Dates d'imposition de l'IRS",
			"anoVigor": "Année d'entrée en vigueur"
		},
		"table": {
			"title": "Niveaux - IRS",
			"ateRemunMes": "Jusqu'au salaire.",
			"maiorigual5": ">=5",
			"vabater5": "Valeur.",
			"vabater4": "Valeur.",
			"vabater3": "Valeur.",
			"vabater2": "Valeur.",
			"vabater1": "Valeur.",
			"vabater0": "Valeur.",
			"taxaMarginalMaxima": "Taux marginal maximum",
			"parcelaAbaterValor": "Valeur de la remise à tempérament",
			"parcelaAbaterFormula": "Formule d'abattage de colis",
			"parcelaAbaterDepende": "L'abattage des portions dépend",
			"txEfetivaMensalEscal": "Barème de taux mensuel effectif",
			"parcelaAbaterFormulaInfo": "Infos sur la formule d'abattage des parcelles"
		},
		"btn": {
			"novoEscalao": "Nouveau niveau",
			"apagarEscalao": "Supprimer le niveau"
		},
		"messages": {
			"naoPodeRemoverTabelasSistema": "Vous ne pouvez pas supprimer les tables IRS du système.",
			"naoPodeAtualizarTabelasSistema": "Vous ne pouvez pas modifier les tables IRS du système."
		}
	},
	"tokens": {
		"title_detail": "Jeton {{id}}",
		"title_plural": "Jetons",
		"pesquisa": "A chercher",
		"fields": {
			"token": "jeton",
			"validUntil": "Valable jusqu'au"
		}
	},
	"trDocsCabsWizard": {
		"captions": {
			"transformacaoDocumentos": "Transformation de documents",
			"documento": "Document",
			"dataDoc": "Date du document",
			"nConta": "Numéro de compte",
			"conta": "Compte",
			"nif": "FNI",
			"liquido": "Liquide",
			"iva": "T.V.A.",
			"totalDocumento": "Document total",
			"nCCusto": "N° C. Coût",
			"nRefProcesso": "Processus",
			"nFactFornec": "Doc externe n°",
			"onlyPossibleToTransform": "Montre uniquement possible à transformer",
			"regraTransformacao": "Règle de transformation",
			"agruparPorCliente": "Regrouper par {{name}}",
			"naoAgrupar": "Ne pas regrouper",
			"dataDocExterno": "Date du document.",
			"texto": "Texte",
			"processar": "Processus",
			"avancar": "Suivant",
			"encerrarDocumentos": "Finaliser les documents",
			"docsGerados": "Documents générés",
			"erros": "Erreurs",
			"transformacaoDocumentosErros": "Transformation de documents - Erreurs",
			"existemErrosATranformDocs": "Il y a {{count}} erreur(s) dans la transformation du document.",
			"verErros": "Afficher les erreurs",
			"tipoDocumento": "Type de document",
			"ateDataDoc": "Document à jour",
			"cliente": "Client",
			"fornecedor": "Fournisseur",
			"homePage": "Page d'accueil"
		},
		"steps": {
			"pesquisa": "Recherche"
		},
		"tooltips": {
			"documentoTransformado": "Document transformé",
			"editarDocumento": "Modifier le document",
			"finalizarDocumento": "Finaliser le document",
			"existemErrosATranformDocs": "Cliquez ici pour voir les erreurs dans la transformation de documents.",
			"criarLoteParaArtigo": "Créer un nouveau lot pour l'article"
		}
	},
	"transferenciasarmazens": {
		"title_detail": "Nouveau transfert d'articles entre entrepôts",
		"title_list": "Transferts d'articles entre entrepôts",
		"fields": {
			"origemNArtigo": "Code article",
			"origemNArmazem": "Entrepôt d'origine",
			"origemQtd": "Montant",
			"destinoNArmazem": "Entrepôt de destination",
			"destinoQtd": "Quantité de destination"
		},
		"modal": {
			"newlinetitle": "Transferts d'articles",
			"titleorigem": "Document source (Sortie)",
			"titledestino": "Document cible (Entrée)",
			"fields": {
				"data": "Date du document",
				"tipodoc": "Type de document.",
				"nnumer": "Numéro numéro.",
				"ndoc": "Doc n°",
				"clientfor": "Client / Pour.",
				"armazemorigem": "Entrepôt d'origine",
				"armazemdestino": "Entrepôt de destination",
				"armazem": "Stockage",
				"codartigo": "Code article",
				"qtd": "Montant",
				"stock": "Action"
			}
		},
		"messages": {
			"transfarmsuccess": "Transfert(s) d'articles entre entrepôts traités avec succès.",
			"origemartigonulo": "L'article source ne peut être nul.",
			"destinoartigonulo": "L'article cible ne peut pas être nul.",
			"origemarmazemnulo": "L’entrepôt source ne peut pas être nul.",
			"destinoarmazemnulo": "L'entrepôt de destination ne peut pas être nul.",
			"qtdnula": "La quantité ne peut pas être nulle.",
			"qtdsup": "La valeur de la quantité saisie ne peut être supérieure à la valeur du stock disponible.",
			"qtdtotalsup": "La valeur de la quantité saisie plus la quantité de lignes déjà ajoutées à partir de l'article {{article}} est supérieure à la valeur du stock disponible.",
			"configGuardadasSucesso": "Les paramètres de transfert d'entrepôt ont été enregistrés avec succès."
		},
		"tooltip": {
			"addNewLine": "Nouvelle ligne de transfert"
		},
		"detail": {
			"table": {
				"fields": {
					"artigo": "Article",
					"qtd": "Montant",
					"armazemorigem": "Entrepôt d'origine",
					"armazemdestino": "Entrepôt de destination"
				}
			},
			"cab": {
				"documentos": "Documents"
			}
		},
		"list": {
			"fields": {
				"faccbIdOrigem": "IDENTIFIANT",
				"nDocfaOrigem": "Type de document.",
				"nDocOrigem": "Doc n°",
				"nNumerOrigem": "Numéro de série d'origine.",
				"nDocfaDestino": "Type de document.",
				"nDocDestino": "Doc n°",
				"nNumerDestino": "Numéro de série de destination.",
				"numeroOrigem": "Document source",
				"numeroDestino": "Document cible",
				"dataDoc": "Date du document."
			}
		}
	},
	"trdoc": {
		"title": "Transformation de documents",
		"saveTitle": "Processus",
		"fields": {
			"nDocfaOrigem": "Type de document.",
			"nDocfaDestino": "Type de document.",
			"nNumerDestino": "série de destin",
			"dataDoc": "Date.",
			"dataDocOrigem": "Date du document.",
			"dataVenc": "Date d'échéance",
			"dataVencCondVenda": "Date de péremption selon les conditions commerciales",
			"nFactFornec": "Doc n°",
			"nFactFornecOrigem": "Doc n°",
			"dataDocExterno": "Date du document.",
			"dataDocExternoOrigem": "Date du document.",
			"nRefProcesso": "Processus",
			"nRefProcessoOrigem": "Le processus est celui d'origine",
			"nArmazem": "Stockage",
			"nArmazemOrigem": "L'entrepôt est l'origine",
			"encerraDocumentoOrigem": "Fermer le document source",
			"encerraDocumentoDestino": "Fermer le document cible"
		},
		"actions": {
			"cancel": "Annuler"
		},
		"modal": {
			"linhas": {
				"title": "Transformation des lignes de documents",
				"steps": {
					"linhasDoc": "Lignes",
					"lotes": "Beaucoup",
					"ident": "Identification",
					"resumo": "Résumé",
					"carga": "Charger",
					"descarga": "Décharge"
				},
				"info": {
					"transformado": "Le document a déjà été transformé",
					"linhasTransformadas": "Le document a déjà des lignes transformées"
				},
				"label": {
					"continuarTransformacao": "J'ai l'intention de poursuivre la transformation",
					"naoContinuarTransformacao": "je n'ai pas l'intention de continuer",
					"aposTransformarEncerrarDoc": "Après la transformation, fermez le(s) document(s)",
					"origem": "Origine",
					"destino": "Destination"
				},
				"btn": {
					"editDoc": "Modifier le document",
					"preencherAuto": "Remplissage automatique des lots"
				},
				"messages": {
					"proceSuccess": "Traitement terminé avec succès.",
					"atribuirLotes": "Vous devez affecter des lots aux lignes contenant des articles avec des lots afin de simuler.",
					"naoTemLinhasSelecionadas": "Il n'y a aucune ligne à traiter, sélectionnez les lignes à transformer dans le document.",
					"qtdLotesDifQtdLinha": "La quantité ({{qty}}) de l'article {{nArtigo}} répartie entre les lots n'est pas la même que celle de la ligne {{nSeq}} ({{qty1}}).",
					"naoTransformou": "Aucun document n'a été généré.",
					"proceFalhou": "Erreur lors du traitement de la transformation."
				}
			}
		},
		"legend": {
			"linhaTransformada": "Ligne déjà transformée"
		}
	},
	"trdocconfig": {
		"title": "Transformation de documents",
		"saveTitle": "Processus",
		"fields": {
			"nDocfaOrigem": "Type de document.",
			"nDocfaDestino": "Type de document.",
			"nNumerDestino": "série de destin",
			"dataDoc": "Date.",
			"dataDocOrigem": "Date du document.",
			"dataVenc": "Date d'échéance",
			"dataVencCondVenda": "Date de péremption selon les conditions commerciales",
			"nFactFornec": "Doc n°",
			"nFactFornecOrigem": "Doc n°",
			"dataDocExterno": "Date du document.",
			"dataDocExternoOrigem": "Date du document.",
			"nRefProcesso": "Processus",
			"nRefProcessoOrigem": "Le processus est celui d'origine",
			"nArmazem": "Stockage",
			"nArmazemOrigem": "L'entrepôt est l'origine",
			"encerraDocumentoOrigem": "Fermer le document source",
			"encerraDocumentoDestino": "Fermer le document cible",
			"referenciaTextoBase": "Texte de référence"
		},
		"actions": {
			"cancel": "Annuler",
			"variables": "Variables",
			"variaveis": {
				"nomeDoc": "@DocName - Nom du document",
				"nDoc": "@NDoc - Numéro de document",
				"dtDoc": "@DtDoc - Date du document",
				"docExt": "@DocExt - Document externe",
				"processo": "@Processo - Processus",
				"contrato": "@Contrat - Contrat",
				"obs1": "@OBS1 - Observations"
			}
		},
		"messages": {
			"success": "Document transformé avec succès.",
			"tipoDocNulo": "Le type de document ne peut pas être nul."
		},
		"tooltips": {
			"nDocExterno": "Référence externe imprimée sur le document",
			"dataDocExterno": "Date externe imprimée sur le document",
			"aposTransformarEncerrarDocsFinalizar": "Ces actions sont irréversibles."
		}
	},
	"trfLancContas": {
		"title": "Transfert de compte",
		"success": "Transfert de compte terminé avec succès",
		"fields": {
			"nContaOrigem": "Numéro de compte d'origine",
			"nContaDestino": "Numéro de compte de destination",
			"nomeContaDestino": "Nom du compte cible",
			"manterContaOrigem": "Maintenir le compte d'origine",
			"dependencias": "Dépendances"
		},
		"fieldsTableDependencias": {
			"nConta": "Numéro de compte d'origine",
			"nome": "Nom d'origine",
			"nContaGeralReal": "Numéro de compte général d'origine",
			"valorMascara": "Valeur du masque",
			"radicalMascara": "Masque"
		},
		"stepIntroCaption": "Introduction",
		"stepContasCaption": "Transfert",
		"stepIntroMessage": "Ce programme vous permet de convertir un compte source en un autre compte de destination.",
		"stepFinalizeCaption": "Conclusion",
		"stepFinalizeMessage": "Conversion terminée.",
		"messages": {
			"contadestjaexiste": "Attention: le compte de destination existe déjà dans le plan comptable courant."
		}
	},
	"trfba": {
		"pagreg": "Paiements enregistrés",
		"documentos": "Documents",
		"tree": {
			"docporenviar": "Documents à envoyer",
			"docenviados": "Documents envoyés",
			"operacao": {
				"op0": "Non défini",
				"op1": "Paiements aux fournisseurs",
				"op2": "Ordonné",
				"op3": "Transfert",
				"op4": "revenus de la maison",
				"op5": "Carte / ticket repas"
			}
		},
		"table": {
			"semdados": "Aucune donnée",
			"sel": "Sel.",
			"nContaCaixa": "Numéro de compte D/O",
			"nContaForn": "Numéro de compte d'approvisionnement",
			"valor": "Valeur",
			"nomeBeneficiario": "Nom du bénéficiaire",
			"nDocInternoLanc": "Doc. interne.",
			"dataLanc": "Date de lancement",
			"nDocPago": "Document payant",
			"codBancoEMI": "Code emi de la banque.",
			"codagenciaEMI": "Code de l'agence EMI.",
			"ibanContaEMI": "IBAN émis.",
			"swiftEmi": "SWIFT émi.",
			"codBancoDest": "Code de la banque destination.",
			"codAgenciaDest": "Code agence destination.",
			"ibanContaDest": "IBAN dest.",
			"swiftDest": "Destination SWIFT.",
			"nomeFicheiro": "Nom de fichier",
			"trfBaID": "Trf.",
			"chaveToPS2": "Clé de la PS2",
			"operacao": "Opération",
			"codEmpregado": "Employé",
			"nomeFuncionario": "Nom de l'employé",
			"formatoTransferencia": "Format de transfert",
			"efectuada": "Effectué"
		},
		"radio": {
			"ps2xml": "PS2/XML",
			"cgd": "CGD",
			"rgFormato": "Format du fichier à générer",
			"igcp": "PICG"
		},
		"btn": {
			"geraficheiro": "Générer un fichier",
			"alterarestado": "Changer le statut en non envoyé",
			"editarregisto": "Modifier l'enregistrement",
			"eliminartransf": "Supprimer la sélection",
			"configSEPA": "Paramètres SEPA"
		},
		"modal": {
			"config": {
				"steps": {
					"outraidprivadas": "Autre identifiant.",
					"template": "Modèles SEPA"
				},
				"fields": {
					"iban": "IBAN",
					"outraIDPrivada": "Une autre pièce d'identité.",
					"regTempSal": "Salaires"
				}
			},
			"geraficheiro": {
				"titleData": "Date de transfert",
				"titlePrioridade": "Date et priorité du transfert",
				"titleDGT": "Données pour le fichier de téléchargement DGT",
				"fields": {
					"nomeFicheiroUploadDGT": "Nom de fichier",
					"numeroSequencial": "Numéro séquentiel",
					"dataLanc": "Effectuer le virement à la date",
					"prioridade": "Priorité",
					"normal": "Normale",
					"alta": "Haut"
				}
			},
			"erros": {
				"dataTrfbaNaoPodeSerInferiorDataHoje": "La date du transfert ne peut être inférieure à la date d'aujourd'hui.",
				"erroGerarFicheiro": "Erreur lors de la génération du fichier de virement bancaire."
			}
		},
		"messages": {
			"temselecionar": "Aucun virement bancaire n'est sélectionné.",
			"confaltestado": "Voulez-vous modifier le statut des enregistrements sélectionnés ?",
			"deltransf": "Voulez-vous supprimer les enregistrements sélectionnés ?",
			"deltransfsuccess": "Enregistrements supprimés avec succès",
			"fichformPS2": "Il générera un fichier au format PS2.",
			"fichformSEPAXML": "Il générera un fichier au format SEPA XML.",
			"fichformCGD": "Il générera un fichier au format CGD (.txt).",
			"fichHomeBanking": "Le fichier UPLOAD pour Home Banking a été généré avec succès.",
			"ibanjaconfig": "L'IBAN est déjà configuré.",
			"linhaaddsuccess": "Ajouté avec succès",
			"linharemsuccess": "IBAN supprimé avec succès",
			"templatealterado": "Le modèle a été modifié avec succès",
			"removeline": "Supprimer la ligne",
			"ibannulo": "L'IBAN ne peut pas être nul"
		}
	},
	"tributAutonomas": {
		"header": {
			"modeloTributAutonoma": "Fiscalité appliquée",
			"prejuizoFiscal": "Perte fiscale",
			"contasDe": "Numéro de compte",
			"perioDezembroCaption": "Décembre - Normal"
		},
		"fields": {
			"nConta": "Numéro de compte",
			"contaDescricao": "Compte",
			"taxa": "Taux",
			"taxaAgravada": "% d'aggravation",
			"descricao": "Classificateur",
			"nome": "Nom",
			"saldoGeral": "Solde accumulé",
			"valorTributAutonoma": "Fiscalité autonome",
			"fromPeriod": "période de"
		},
		"modeloTributAutonoma": {
			"modelo0": "IRC - Continent",
			"modelo1": "IRC - Zone de libre-échange de Madère",
			"modelo3": "IRC - Açores",
			"modelo2": "IRS"
		},
		"messages": {
			"desejaAtribuirOClassificadorAsCo": "Voulez-vous affecter le classificateur aux comptes marqués ?",
			"configuracoesDaTributacaoAutonom": "Paramètres de fiscalité autonome copiés dans les entreprises sélectionnées.",
			"modeloChangedsuccess": "Le modèle de taxation a été remplacé par \"{{modelTribut}}\" avec succès.",
			"changedModeloTriubt": "Souhaitez-vous commencer à utiliser les taux d'imposition autonomes « {{modelTributAfter}} » au lieu de « {{modelTribut}} » ?",
			"modeloTributAutonomaInfo": "Pour changer de régime fiscal, vous devez accéder au maintien de la fiscalité autonome"
		},
		"modals": {
			"empresas": {
				"title": "Copie pour d'autres entreprises"
			},
			"print": {
				"title": "Impression des taxes autonomes"
			}
		},
		"btn": {
			"copiaParaOutraEmpresas": "Copie pour d'autres entreprises",
			"manutTributAutonoma": "Maintien de la fiscalité autonome"
		},
		"actions": {
			"poc": "Plan comptable"
		}
	},
	"compilerOptions": {
		"module": "commonjs",
		"target": "es5",
		"sourceMap": "vrai"
	},
	"exclude": {
		"0": "nœud_modules"
	},
	"txtar": {
		"title_detail": "Texte libre {{id}}",
		"title_new": "Nouveau texte libre",
		"title_plural": "Textes libres",
		"pesquisa": "A chercher",
		"saved": "Texte libre {{id}}, enregistré avec succès.",
		"error": "Texte libre {{id}}, ne peut pas être enregistré.",
		"deleted": "Texte libre {{id}}, supprimé avec succès",
		"fields": {
			"nArtigo": "Article",
			"nArtigoPlaceholder": "Code article",
			"nTexto": "Texte n°",
			"nTextoPlaceholder": "Texte n°",
			"titulo": "Titre",
			"tituloPlaceholder": "Titre",
			"note": "Texte",
			"notePlaceholder": "Texte"
		}
	},
	"txtcl": {
		"title_detail": "Texte libre {{id}}",
		"title_new": "Nouveau texte libre",
		"title_plural": "Textes libres",
		"pesquisa": "A chercher",
		"saved": "Texte libre {{id}}, enregistré avec succès.",
		"error": "Texte libre {{id}}, ne peut pas être enregistré.",
		"deleted": "Texte libre {{id}}, supprimé avec succès",
		"fields": {
			"nClifo": "Numéro de compte",
			"nClifoPlaceholder": "Numéro de compte",
			"nTexto": "Texte n°",
			"nTextoPlaceholder": "Texte n°",
			"titulo": "Titre",
			"tituloPlaceholder": "Titre",
			"note": "Texte",
			"notePlaceholder": "Texte"
		}
	},
	"unidadesMedidaComercioInternacional": {
		"title_detail": "Unité de mesure du commerce international {{id}}",
		"title_plural": "Unités de mesure pour le commerce international",
		"pesquisa": "A chercher",
		"fields": {
			"codigo": "Code",
			"designacao": "Désignation",
			"designacaoEN": "Désignation FR",
			"simbolo": "Symbole",
			"tipoUnidadeCG": "Type d'unité"
		}
	},
	"unidadesmovimento": {
		"title_detail": "Unité de mouvement {{id}}",
		"title_new": "Nouvelle unité de mouvement",
		"title_plural": "Unités de mouvement",
		"pesquisa": "A chercher",
		"saved": "Unité de mouvement {{id}}, enregistrée avec succès.",
		"error": "L'unité de mouvement {{id}} ne peut pas être sauvegardée.",
		"deleted": "Unité de mouvement {{id}}, éliminée avec succès.",
		"fields": {
			"codUni": "Code",
			"codUniPlaceholder": "Code d'unité de mouvement",
			"nome": "Nom",
			"nomePlaceholder": "Nom de l'unité de mouvement",
			"nomeAbrev": "Nom abrégé",
			"nomeAbrevPlaceholder": "Nom abrégé de l'unité de mouvement",
			"tipo": "Taper",
			"tipoPlaceholder": "Type d'unité de mouvement",
			"qtdRefUnidad": "Qté par unité",
			"qtdRefUnidadPlaceholder": "Quantité par unité",
			"codUnece": "Le code unit",
			"codUnecePlaceholder": "Code de l'unité de mesure du commerce international"
		}
	},
	"users": {
		"title_detail": "Utilisateur {{id}} (avancé)",
		"title_new": "Nouvel utilisateur (avancé)",
		"title_plural": "Utilisateurs (configuration avancée)",
		"pesquisa": "A chercher",
		"saved": "L'utilisateur \"{{id}}\" a été enregistré avec succès",
		"error": "L'utilisateur \"{{id}}\" ne peut pas être enregistré.",
		"deleted": "Utilisateur supprimé avec succès",
		"passwordchanged": "Mot de passe modifié avec succès !",
		"instrucoes.email": "Les instructions pour changer le mot de passe ont été envoyées à l'e-mail de l'utilisateur.",
		"instrucoes.emailerro": "Impossible d'envoyer un e-mail pour créer un mot de passe utilisateur",
		"acessosPortaisApi": "Il n'y a aucun accès sélectionné",
		"actions": {
			"changepassword": "Changer le mot de passe",
			"cancel": "Annuler",
			"change": "Pour modifier",
			"adicionarEmpresas": "Ajouter des entreprises",
			"adicionarEmpresa": "Ajouter une entreprise",
			"empresas": "Changer de société",
			"sendEmail": "Envoyer un e-mail de récupération de mot de passe"
		},
		"fields": {
			"userId": "IDENTIFIANT",
			"username": "Authentification",
			"firstName": "Nom",
			"lastName": "Nom de famille",
			"password": "Mot de passe",
			"email": "E-mail",
			"active": "Actif",
			"nEmpresa": "numéro d'entreprise",
			"nUtilizador": "Numéro d'utilisateur",
			"admin": "Administrateur",
			"apiv1": "Utilisateur",
			"colaborador": "Collaborateur",
			"erp": "Utilisateur ERP",
			"gestorRH": "Responsable RH",
			"gestorServico": "Responsable des services",
			"gestorEmentas": "Gestionnaire de menus",
			"regex": "Filtre API",
			"administrator": "Administrateur",
			"formUsername": "Authentification (champ permettant à l'utilisateur de se connecter à la plateforme)",
			"formEmail": "Email (champ permettant à l'utilisateur de se connecter à la plateforme)"
		},
		"password": {
			"title": "Changer le mot de passe",
			"newpassword": "Mot de passe",
			"confirmpassword": "Confirmer",
			"errorEqualsPassword": "Les mots de passe ne correspondent pas"
		},
		"delete": {
			"title": "Voulez-vous supprimer l'utilisateur {{id}} ?"
		},
		"steps": {
			"utilizador": "Utilisateur",
			"portaisApi": "Accès aux portails/API",
			"empresas": "Accès aux entreprises"
		},
		"emailSuccess": "E-mail envoyé avec succès"
	},
	"usersbasic": {
		"title_detail": "ID de l'utilisateur}}",
		"title_new": "Nouvel utilisateur",
		"title_plural": "Utilisateurs",
		"pesquisa": "A chercher",
		"saved": "L'utilisateur \"{{id}}\" a été enregistré avec succès",
		"error": "L'utilisateur \"{{id}}\" ne peut pas être enregistré.",
		"deleted": "Utilisateur supprimé avec succès",
		"fields": {
			"instalacao": "Installation",
			"utilizador": "Utilisateur CentralGest"
		},
		"text": {
			"deleteSameUserDisabled": "Vous ne pouvez pas supprimer votre propre utilisateur",
			"createUtilizador": "Créer un utilisateur CentralGest et associer automatiquement",
			"acessos": "Accès",
			"selectInstallation": "Veuillez sélectionner une installation ci-dessus pour configurer l'accès aux entreprises",
			"emptyCompanies": "Aucune entreprise n'est disponible pour cette installation.",
			"allRoles": "Tous les portails",
			"filterCompanies": "Rechercher des entreprises",
			"nEmpresa": "numéro d'entreprise",
			"nomeEmpresa": "Nom de l'entreprise",
			"configuracaoEmpresa": "Configuration de l'entreprise",
			"configuracaoGeral": "Configuration générale",
			"acessosEmpresa": "Accès entreprise",
			"allPolicies": "Toutes les entreprises",
			"config": "Paramètres",
			"loadingEmpresas": "Chargement des entreprises d'installation...",
			"blockedAcessosEmpresa": "Veuillez, dans le tableau ci-dessus, donner l'accès à au moins une entreprise pour configurer l'accès des entreprises dans cette installation.",
			"loadPolicies": "Pour afficher et/ou modifier l'accès de l'entreprise, veuillez cliquer ici.",
			"loadingPolicies": "Chargement de l'accès à l'entreprise...",
			"loadPoliciesError": "Les erreurs suivantes se sont produites lors du chargement des accès de l'entreprise",
			"portalInclude": "Les entreprises ayant accès à ce portail auront également accès au portail",
			"portalIncludes": "Les entreprises ayant accès à ce portail auront également accès aux portails suivants"
		},
		"errors": {
			"requiredUtilizador": "Il est obligatoire de sélectionner un Utilisateur CentralGest pour l'association"
		},
		"accesses": {
			"generic": {
				"view": "Voir",
				"add": "Pour créer",
				"edit": "Modifier",
				"delete": "Annuler"
			},
			"addons": {
				"title": "Modules complémentaires"
			},
			"ativos": {
				"title": "Actifs"
			},
			"contabilidade": {
				"title": "Comptabilité"
			},
			"comercial": {
				"title": "Gestion Commerciale"
			}
		},
		"noRoles": {
			"title": "Confirmer l'enregistrement de l'utilisateur sans accès",
			"message": "<p>Vous n'avez sélectionné aucun accès pour l'utilisateur, <b>l'utilisateur ne pourra pas accéder à la plateforme</b>.</p><p>Êtes-vous sûr de vouloir continuer ?</p>"
		}
	},
	"utlviatpatronal": {
		"fields": {
			"codEmp": "Employé",
			"codABDESC": "Allocation",
			"anoProcessamento": "Données",
			"vAquisicaoViatura": "Valeur d'acquisition",
			"vMercadoViatura": "Valeur marchande",
			"rhTipoProce": "Traitement"
		},
		"tipoUtlViatPatronal": {
			"todas": "Tous",
			"ativas": "Actif",
			"inativas": "Inactif"
		},
		"toolbar": {
			"tipoUtlViatPatronal": "Afficher l'utilisation."
		},
		"modal": {
			"title": "Maintien de l'usage du véhicule d'un employeur",
			"fields": {
				"tipoProcessamento": "Type de traitement",
				"codABDESC": "Allocation",
				"codEmp": "Code employé",
				"anoProcessamento": "Année de traitement",
				"mesInicioProce": "Mois de début",
				"suspende": "Suspendu",
				"valor": "Valeur",
				"vAquisicaoViatura": "Valeur d'achat du véhicule ou valeur de référence dans le contrat de location (TVA incluse)",
				"vMercadoViatura": "Valeur marchande de la voiture",
				"valorIncidenciaSS": "Lundi social",
				"valorIncidenciaIRS": "IRS",
				"valincidencia": "Valeurs d'incidence",
				"simvalmensal": "Simulation des valeurs mensuelles du montant des revenus en nature pour l'utilisation d'un véhicule de société",
				"obs": "Observations",
				"obsSupencao": "Observations sur les suspensions",
				"simulacaodesc": "Simulation des valeurs mensuelles du montant des revenus en nature pour l'utilisation d'un véhicule de société"
			}
		}
	},
	"datagrid": {
		"column": {
			"required": "Cette colonne est obligatoire",
			"dupsNotAllowed": "Aucun doublon autorisé",
			"digitsOnly": "Seuls les chiffres sont autorisés"
		}
	},
	"vendasanomes": {
		"chart": {
			"title": "Ventes par mois",
			"diference": "Différence"
		},
		"table": {
			"valorVariacao": "Valeur de variation",
			"percentVariacao": "% changement"
		},
		"campos": {
			"checkboxAnoAnterior": "Voir l'intégralité de l'année précédente"
		}
	},
	"vendasartigo": {
		"fields": {
			"codigo": "Code",
			"nome": "Nom",
			"infoString": "FNI",
			"valor": "Valeur",
			"percentagem": "%"
		},
		"totalvendas": "Ventes totales",
		"btn": {
			"mes": "Mois"
		},
		"toolbar": {
			"month": "Mois : {{nameMonth}}"
		}
	},
	"vendascliente": {
		"fields": {
			"codigo": "Code",
			"nome": "Nom",
			"infoString": "FNI",
			"valor": "Valeur totale",
			"valorIva": "Valeur TVA",
			"valorLiquido": "Valeur nette",
			"percentagem": "%"
		},
		"totalvendas": "Ventes totales",
		"btn": {
			"mes": "Mois"
		},
		"toolbar": {
			"month": "Mois : {{nameMonth}}"
		}
	},
	"vendedores": {
		"title_detail": "Vendeur {{id}}",
		"title_new": "Nouveau vendeur",
		"title_plural": "Vendeurs",
		"pesquisa": "A chercher",
		"saved": "Vendeur {{id}}, enregistré avec succès.",
		"error": "Le vendeur {{id}} ne peut pas être enregistré.",
		"fields": {
			"nVendedor": "Code.",
			"nVendedorPlaceholder": "Code vendeur",
			"nome": "Nom",
			"nomePlaceholder": "Nom du vendeur"
		}
	},
	"verificaEFaturas": {
		"tabDocsEFaturaCaption": "Documents dans e-Fatura non liés à la comptabilité",
		"tabJaLancadosCaption": "Documents déjà saisis en comptabilité ou ignorés",
		"viewDocModalTitle": "Vue du document {{ndoc}}",
		"viewDocModalTitleSimple": "Aperçu du document",
		"extratosModalTitle": "Relevés de compte",
		"pesquisaDocPossivelModalTitle": "Recherche de documents comptables",
		"expandAllBtn": "Développer tout",
		"associarTodosPossiveis": "Associer des documents à 1 document possible",
		"associarTodosPossiveisPromptTitle": "Attention",
		"associarTodosPossiveisPromptMsg": "Cette opération associera automatiquement les documents e-Fatura à un document éventuel.<br>Vous devez toujours vérifier document par document si le document éventuel associé correspond au document e-Fatura.<br><br>Etes-vous sûr de vouloir continuer?",
		"associarTodosPossiveisNoSourceTitle": "Association automatique...",
		"associarTodosPossiveisNoSourceMsg": "Vous n'avez aucun document ou vous avez des documents avec plus d'un document possible.",
		"associarDocSemAlterarDocExterno": "Associer un document sans modifier un document externe",
		"associarDocComAlteracaoDocExterno": "Associer un document à une modification de document externe",
		"verDocumento": "Afficher le document",
		"deixarIgnorarDocumento": "Arrêtez d'ignorer ce document",
		"deixarIgnorarPorFornecedor": "Arrêtez d'ignorer les documents de ce fournisseur",
		"removeAssociacao": "Supprimer l'association de ce document",
		"filtropesq": "Recherche",
		"messages": {
			"docAssociadoSuccessMsg": "Le document {{ndoc}} a été lié.",
			"docsAssociadoSuccessMsg": "Tous les documents avec 1 document possible ont été associés avec succès.",
			"docIgnoradoSuccessMsg": "Le document {{ndoc}} a été ajouté à la liste des ignorés.",
			"fornecedorIgnoradoSuccessMsg": "Fournisseur {{nif}} - {{name}} a été ajouté à la liste des ignorés.",
			"docRemAssociacaoSuccessMsg": "L'association du document {{ndoc}} a été supprimée",
			"docRemIgnoringSuccessMsg": "Le document {{ndoc}} a été supprimé de la liste des ignorés.",
			"docRemIgnoringFornecedorSuccessMsg": "Le fournisseur {{nif}} a été supprimé de la liste des ignorés.",
			"docDigital1": "Avec doc.",
			"docDigital2": "Avec doc."
		},
		"fields": {
			"numPossiveisDocs": "Nombre de documents possibles",
			"numPossiveisDocsAbbr": "Possible",
			"numSemelhantesDocs": "Nombre de documents similaires",
			"numSemelhantesDocsAbbr": "Similaire",
			"documentoLancado": "Numéro de document",
			"nifEmitente": "FNI",
			"nomeEmitente": "Nom",
			"numerodocumento": "N° de document externe",
			"dataEmissaoDocumento": "Date d'émission",
			"tipoDocumentoDesc": "Taper",
			"estadoBeneficioDesc": "Situation",
			"actividadeProfDesc": "Portée de l'activité",
			"valorTotalBaseTributavel": "Base imposable",
			"valorTotalIva": "Valeur TVA",
			"valorTotal": "Montant",
			"baseTributavel": "Base imposable",
			"taxa": "Taux de base",
			"impostoSelo": "Taxe de timbre",
			"total": "Montant",
			"nDocInterno": "Numéro de document",
			"nDocExterno": "N° de document externe",
			"valor": "Valeur",
			"dataDocExterno": "Date du document externe",
			"docDigitalAbbr": "Document numérique"
		},
		"btn": {
			"accao": "Actions",
			"dropdown": {
				"extratosConta": "Relevés de compte",
				"ignorarSempreTodosDocumentos": "Ignorez toujours tous les documents de ce fournisseur",
				"ignorarDocumento": "Ignorer ce document"
			}
		},
		"tabJaLancadosCaptionContainer": {
			"table": {
				"badges": {
					"ligacaoDireta": "Lien direct",
					"naoIgnorar": "N'ignorez pas",
					"remover": "Retirer"
				}
			},
			"legends": {
				"documentoIgnorado": "Document ignoré",
				"associadoDocExtDiferente": "Associé au doc ​​no.",
				"documentoFornecedorIgnorado": "Document fournisseur ignoré"
			}
		},
		"tabDocsEFaturaCaptionContainer": {
			"mostradocpossiveis": "Afficher seulement aucun document possible",
			"tooltip": {
				"associarAutTodosPossiveis": "Associez automatiquement tous les documents à 1 document possible."
			}
		},
		"formFields": {
			"dataDe": "Date de:",
			"dataAte": "Date jusqu'au :",
			"tipo": "Taper",
			"fornecedor": "Fournisseur",
			"placeholder": {
				"contribuinte": "Numéro de TVA"
			}
		},
		"modal": {
			"config": {
				"title": "Configuration du rapprochement des factures électroniques",
				"fields": {
					"nCaracteresAValidar": "Nombre de caractères à valider"
				}
			}
		}
	},
	"verificacaoacumulados": {
		"title": "Chèque d'accumulation",
		"periodo": "Cumulé par période avec mouvement.",
		"movimentos": "Accumulé avec le mouvement.",
		"extratos": "Accumulé d'extraits",
		"inconsistencias": "Toutes les incohérences",
		"consistencias": "Cohérence du compte courant",
		"fields": {
			"deNconta": "compte",
			"periodo": "Période jusqu'à"
		},
		"btn": {
			"verificar": "Pour vérifier",
			"corrigir": "Correctif sélectionné"
		},
		"tooltip": {
			"corrigir": "Pour le corriger, vous devez sélectionner au moins un élément de la liste"
		},
		"alerts": {
			"reconstrucaoSucesso": "La reconstruction du compte a réussi",
			"reconstrucaoErro": "Une erreur s'est produite lors de la création du compte",
			"correcaoContasCorrentesSucesso": "Les comptes sélectionnés ont été corrigés avec succès",
			"correcaoContasCorrentesErro": "Une erreur s'est produite lors de la correction des comptes sélectionnés"
		},
		"tables": {
			"nConta": "Numéro de compte",
			"nome": "Nom",
			"saldoAcumulado": "Solde accumulé",
			"saldoMovAberto": "Solde mobile",
			"tipo": "Taper",
			"saldoAcumuladoAte": "Solde accumulé jusqu'à la période",
			"saldoExtratoAte": "Bilan jusqu'à la période",
			"saldoExtrato": "Solde du relevé",
			"fase": "Phase",
			"conta": "Compte",
			"periodo": "Période",
			"diario": "Tous les jours",
			"docInterno": "Doc n°",
			"sel": "Sel.",
			"origem": "Origine"
		},
		"actions": {
			"reconstruir": "Reconstruire"
		}
	},
	"zonas": {
		"title_detail": "Zone {{id}}",
		"title_new": "Nouvelle zone",
		"title_plural": "Zones",
		"pesquisa": "A chercher",
		"saved": "Zone {{id}}, enregistrée avec succès.",
		"error": "Zone {{id}}, ne peut pas être enregistrée.",
		"deleted": "Zone {{id}}, supprimée avec succès",
		"fields": {
			"nZona": "Code.",
			"nZonaPlaceholder": "Code de zone",
			"nome": "Nom",
			"nomePlaceholder": "Nom de la zone"
		}
	}
}