<ng-template #templateOptions>
  <div ngbDropdown autoClose="outside" #dropdown="ngbDropdown" (openChange)="dropdownOpenChanged(dropdown, $event)">
    <pl-tooltip [config]="btnOptions?.tooltip">
      <button
        type="button"
        *plTooltipContent
        class="btn btn-sm btn-link btn-helper-links"
        ngbDropdownToggle
        [disabled]="disabled || btnOptions?.disabled"
        [attr.title]="'global.menu.options' | translate">
        <i class="fa fa-fw fa-cogs" aria-hidden="true"></i>
        <span [translate]="'global.btn.options'" *ngIf="!isMobile"></span>
      </button>
    </pl-tooltip>

    <div class="config-options" ngbDropdownMenu>
      <div class="config-options-rows">
        <div class="config-options-row" *ngFor="let key of optionsKeys" ngbDropdownItem [disabled]="disabled" (click)="changedConfig(key[0], !options.get(key[0]).value)">
          <div class="config-options-cell">{{ key[1] }}</div>
          <div class="config-options-cell">
            <pl-edit type="boolean" [model]="options.get(key[0]).value" [properties]="{disabled: disabled}"></pl-edit>
          </div>
        </div>
      </div>

      <ng-container *ngIf="hideRevertToDefault !== true">
        <div class="dropdown-divider"></div>

        <button type="button" class="btn" ngbDropdownItem [disabled]="disabled || btnOptions?.disabled" [translate]="'components.configOptions.revertToDefault'" (click)="revertToDefault()"></button>
      </ng-container>
    </div>
  </div>
</ng-template>
