import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {
  downloadStream,
  ICGTableOnSelect,
  IPlNavWizardCallback,
  IPlNavWizardDefinition,
  IPlNavWizardEventValidator,
  IPlNavWizardOptions,
  IPlNavWizardStep,
  IPlTableCallback,
  IPlTableDefinition,
  IPlTableOptions,
  IPlTabsCallback,
  isDefined,
  isDefinedNotNull,
  isFunction,
  PlAlertService
} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {
  ERHProceSegSocialPrintMode,
  ERHProceSegSocialStatus,
  ERHProceSegSocialSteps,
  IRHProceSegSocialDataItem,
  IRHProceSegSocialErroItem,
  IRHProceSegSocialItem,
  IRHProceSegSocialModel,
  IRHProceSegSocialProcessExistentesItem,
  IRHProceSegSocialProcessExistentesParams,
  IRHProceSegSocialReProcItem,
  IRHProceSegSocialSegSocialList,
  IRHProceSegSocialSendWebService,
  IRHProceSegSocialStatus
} from '../proceSegSocial.module.interface';
import {IJsonErpUser, IJsonUserRole} from '../../../../services/account/jsonUserApi.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment from 'moment';
import {ProceSegSocialPrintModalComponent} from '../modals/print/proceSegSocial.print.modal.component';
import {ProceSegSocialService} from '../proceSegSocial.module.service';
import {ProceSegSocialViewInfoComponent} from '../modals/viewinfo/proceSegSocial.viewInfo.modal.component';
import {ROLE} from '../../../../services/role.const';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {MODULE_NAME_RH_PROC_SEG_SOCIAL_CONSULT_ENVIOS} from '../../../portalrh/rhprocsegsocialconsultenvios/rhProcCegCocialConsultEnvios.module.interface';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ConfigLoginWsSSModalComponent} from '../../../segsocial/modals/loginWsSS.modal.component';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {orderBy} from 'lodash-es';

const INTERVAL_TIMEOUT = 2000;

const STEP_INTRO_INDEX = 0;
const STEP_EMPRESAS_SEL_INDEX = 1;
const STEP_PROC_CONFIG_INDEX = 2;
const STEP_PROC_INDEX = 3;
const STEP_PROC_RESULTS_INDEX = 4;
const TOTAL_MONTHS = 12;

const TAB_ID_PROC_RESULT = 'proc-results-tab';
const TAB_ID_PROC_RESULT_ERRORS = 'empregados-tab';
const TOOLBAR_INSTANCE_ID_PROC_RESULTS = 'toolbar-proc-results';

@Component({
  selector: 'proceSegSocial',
  templateUrl: './proceSegSocial.module.component.html'
})
export class ProceSegSocialComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly definitionNavWizard: IPlNavWizardDefinition;
  public readonly dataGridDefinitionProcExistentes: IDevExpressDataGrid;
  public readonly dataGridDefinitionProcResults: IDevExpressDataGrid;
  public readonly dataGridDefinitionProcResultsErrors: IDevExpressDataGrid;
  public readonly procResultsTabsCallback: IPlTabsCallback;
  public readonly proceSegSocialSteps: typeof ERHProceSegSocialSteps;
  public readonly msEmpresasTemplate: string;
  public readonly tabIdRrocResults: string;
  public readonly tabIdRrocResultsErrors: string;

  public model: IRHProceSegSocialModel;
  public propertiesNavWizard: IPlNavWizardOptions;
  public plWizardCallback: IPlNavWizardCallback;
  public empresasSource: Array<IJsonErpUser>;
  public empresasSel: Array<IJsonErpUser>;
  public multiDatasTableDef: IPlTableDefinition;
  public multiDatasTableCallback: IPlTableCallback;
  public multiDatasTableOptions: IPlTableOptions;
  public currentStatus: IRHProceSegSocialStatus;
  public selectedRowKeys: Array<number>;
  public toolbarInstIdProcResults: string;
  public orderBySelected: string;
  public pbProcLabel: string;
  public selectedYear: number;
  public vizProcExistentes: boolean;
  public isBlocked: boolean;

  private readonly _multiDatasSourceCache: {[year: number]: Array<IRHProceSegSocialDataItem>};
  private readonly _maintenanceProcSegSocialConsultaEnvios: IModuleMaintenanceInstance;
  private readonly _currentMonth: number;

  private _dataGridProcExistentesInstance: dxDataGrid;
  private _dataGridProcResultsInstance: dxDataGrid;
  private _dataGridProcErrorsInstance: dxDataGrid;
  private _intervalId: number;
  private _multiDatasTableSource: Array<IRHProceSegSocialDataItem>;
  private _procExistentesTableSource: Array<IRHProceSegSocialProcessExistentesItem>;
  private _procResultsTableSource: Array<IRHProceSegSocialItem>;
  private _procResultsErrorsTableSource: Array<IRHProceSegSocialErroItem>;
  private _isFirstTime: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _proceSegSocialService: ProceSegSocialService,
    private readonly _plAlertService: PlAlertService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this._maintenanceProcSegSocialConsultaEnvios = this._moduleMaintenanceService.build(MODULE_NAME_RH_PROC_SEG_SOCIAL_CONSULT_ENVIOS);
    this.msEmpresasTemplate = '{{nEmpresa}} - {{nomeEmpresa}}';
    this._currentMonth = moment().subtract(1, 'months').month() + 1;
    this.tabIdRrocResults = TAB_ID_PROC_RESULT;
    this.tabIdRrocResultsErrors = TAB_ID_PROC_RESULT_ERRORS;
    this.procResultsTabsCallback = {};
    this.definitionNavWizard = {
      items: []
    };
    this.proceSegSocialSteps = ERHProceSegSocialSteps;
    this.propertiesNavWizard = {
      disableNavigation: false,
      disablePreviousStep: false,
      disableNextStep: false
    };
    this.plWizardCallback = {};
    this._multiDatasSourceCache = {};
    this._multiDatasTableSource = [];
    this.multiDatasTableCallback = {};
    this.selectedRowKeys = [];
    this.empresasSel = [];
    this._plToolbarService.registerInstance(this.toolbarInstIdProcResults, {
      items: [
        {
          id: 'toolbar-imprimir-map-por-empregado',
          type: 'button',
          class: 'btn-outline-primary',
          iconLeft: '<i class="fa fa-print"></i>&nbsp;',
          caption: 'proceSegSocial.printMapPerEmp',
          click: () => {
            this._printMapPerEmp();
          }
        },
        {
          id: 'empregados-imprimir-resumo',
          type: 'button',
          class: 'btn-outline-primary',
          iconLeft: '<i class="fa fa-print"></i>&nbsp;',
          caption: 'proceSegSocial.printSummary',
          click: () => {
            this._printSummary();
          }
        },
        {
          id: 'empregados-gerar-ficheiro-dri',
          type: 'button',
          class: 'btn-outline-primary',
          iconLeft: '<i class="fa fa-bolt"></i>&nbsp;',
          caption: 'proceSegSocial.genDRIFile',
          click: () => this._genDRIFile()
        },
        {
          id: 'empregados-enviar-ficheiro',
          type: 'button',
          class: 'btn-outline-primary',
          iconLeft: '<i class="fa fa-upload"></i>&nbsp;',
          caption: 'proceSegSocial.sendFile',
          click: () => this._sendFile()
        },
        {
          id: 'empregados-consultar-envio',
          type: 'button',
          class: 'btn-outline-primary',
          iconLeft: '<i class="fa fa-bars"></i>&nbsp;',
          caption: 'proceSegSocial.viewSends',
          click: () => this._viewSends()
        }
      ]
    });
    this.multiDatasTableDef = {
      fields: [
        {name: 'selected', caption: 'proceSegSocial.fields.selected', type: 'boolean'},
        {name: 'descricao', caption: 'proceSegSocial.fields.mesAno'}
      ]
    };
    this.multiDatasTableOptions = {perPage: 12, suppressEmptyLines: true, hidePagination: true};
    this._procExistentesTableSource = [];
    this.dataGridDefinitionProcExistentes = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'tipoProcessamento', dataType: 'string', caption: 'proceSegSocial.fields.tipoProcessamento'},
        {dataField: 'tipoProcessamentoDescricao', dataType: 'string', caption: 'proceSegSocial.fields.tipoProcessamentoDescricao'},
        {dataField: 'dataProcessamento', dataType: 'date', caption: 'proceSegSocial.fields.dataProcessamento'},
        {dataField: 'empresa', dataType: 'string', caption: 'proceSegSocial.fields.empresa'},
        {dataField: 'nomeEmpresa', dataType: 'string', caption: 'proceSegSocial.fields.nomeEmpresa'},
        {dataField: 'ssAno', dataType: 'string', caption: 'proceSegSocial.fields.ssAno'},
        {dataField: 'ssMes', dataType: 'string', caption: 'proceSegSocial.fields.ssMes'},
        {dataField: 'fichGeradoPodeReprocessarFicheiro', dataType: 'boolean', caption: 'proceSegSocial.fields.fichGeradoPodeReprocessarFicheiro'},
        {dataField: 'estadoFicheiroStr', dataType: 'string', caption: 'proceSegSocial.fields.estadoFicheiroStr'},
        {dataField: 'ssInfoEstadoProcessamentoStr', dataType: 'string', caption: 'proceSegSocial.fields.ssInfoEstadoProcessamentoStr'}
      ],
      dataSource: new CustomStore({
        key: '_index',
        load: () => this._getProcExistentesTableSource()
      }),
      export: {filename: 'proceSegSocial.tabConfigCaption'},
      selection: {mode: 'multiple', selectAllMode: 'allPages', showCheckBoxesMode: 'always'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateProcExistentes',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionProcResults = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'ssNEMPRESA', dataType: 'string', caption: 'proceSegSocial.fields.empresa'},
        {dataField: 'ssNOMEEMPRESA', dataType: 'string', caption: 'proceSegSocial.fields.nomeEmpresa'},
        {dataField: 'ano', dataType: 'number', caption: 'proceSegSocial.fields.ssAno'},
        {dataField: 'mes', dataType: 'number', caption: 'proceSegSocial.fields.ssMes'},
        {dataField: 'totalRemuneracaoSegSocial', dataType: 'double', caption: 'proceSegSocial.fields.totalRemuneracaoSegSocial'},
        {dataField: 'totalContribuicaoSegSocial', dataType: 'double', caption: 'proceSegSocial.fields.totalContribuicaoSegSocial'},
        {dataField: 'totalRemuneracaoVencimento', dataType: 'double', caption: 'proceSegSocial.fields.totalRemuneracaoVencimento'},
        {dataField: 'statusImage', dataType: 'string', caption: 'global.text.error', alignment: 'center', cellTemplate: 'cellTemplateStatusImage'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnProcResults'}
      ],
      dataSource: new CustomStore({
        load: () => this._getProcResultsTableSource()
      }),
      export: {filename: 'proceSegSocial.tabResultsCaption'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateProcResults',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionProcResultsErrors = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nEmpresa', dataType: 'string', caption: 'proceSegSocial.fields.empresa'},
        {dataField: 'erro', dataType: 'string', caption: 'global.text.error'},
        {dataField: 'queFazer', dataType: 'string', caption: 'proceSegSocial.fields.queFazer'}
      ],
      dataSource: new CustomStore({
        load: () => this._getProcResultsErrorsTableSource()
      }),
      export: {filename: 'proceSegSocial.erros'},
      remoteOperations: false
    };
    this._init();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._loadEmpresas();
    this._isFirstTime = true;
    this._proceSegSocialService.getJobStatus().then((response: IRHProceSegSocialStatus) => {
      this.currentStatus = response;
      this._init();
      if (response.state === ERHProceSegSocialStatus.Timeout) {
        this._showTimeoutModal();
        this._resetWizard();
      } else if (response.state === ERHProceSegSocialStatus.Error) {
        this._handleStateError(response);
        this._resetWizard();
      } else if (response.state === ERHProceSegSocialStatus.Ended) {
        this._cgModalService
          .showOkCancel('proceSegSocial.promptViewResultsTitle', 'proceSegSocial.promptViewResultsMessage', {
            size: 'md',
            btnOkText: 'proceSegSocial.viewResultBtn',
            btnCancelText: 'proceSegSocial.initNewProc',
            btnOkIcon: 'fa-eye',
            btnCancelIcon: 'fa-home',
            backdrop: 'static',
            showCloseBtn: false,
            keyboard: false
          })
          .then(() => this._showResults())
          .catch(() => {
            this._resetWizard();
            this._init();
          });
      } else if (response.state === ERHProceSegSocialStatus.Started) {
        this.isBlocked = response.userStartedId !== this.session.userId;
        if (!this.isBlocked) {
          this._setWizActiveStep(ERHProceSegSocialSteps.PROC, true);
          this._startProcessChecker(true);
        }
      }
    });
    this.orderBySelected = this._translateService.instant('proceSegSocial.btn.dropdown.sortEmpresasByNEmpresa');
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    clearInterval(this._intervalId);
    this._plToolbarService.unRegisterInstance(this.toolbarInstIdProcResults);
    this._proceSegSocialService.stopJob();
  }

  public setInstanceName(value: string): void {
    super.setInstanceName(value);
    this.toolbarInstIdProcResults = `${this.instanceName}-${TOOLBAR_INSTANCE_ID_PROC_RESULTS}`;
  }

  public onMultiYearChange(year: number): void {
    this.selectedYear = year;
    this._invalidateSteps(ERHProceSegSocialSteps.PROC_CONFIG, 'forward');
    this.definitionNavWizard.items[STEP_PROC_CONFIG_INDEX].setIncomplete();
    if (isFunction(this.multiDatasTableCallback.refresh)) {
      this.multiDatasTableCallback.refresh();
    }
  }

  public onDecYearClick(): void {
    this.selectedYear -= 1;
    if (isFunction(this.multiDatasTableCallback.refresh)) {
      this.multiDatasTableCallback.refresh();
    }
  }

  public onIncYearClick(): void {
    this.selectedYear += 1;
    if (isFunction(this.multiDatasTableCallback.refresh)) {
      this.multiDatasTableCallback.refresh();
    }
  }

  public empresasChanged(): void {
    this._invalidateSteps(ERHProceSegSocialSteps.EMPRESAS_SEL, 'forward');
    this.definitionNavWizard.items[STEP_EMPRESAS_SEL_INDEX].setIncomplete();
  }

  public onVizProcExistentesChange(value: boolean): void {
    this.vizProcExistentes = value;
    if (this.vizProcExistentes) {
      this._loadProceExistentes();
    } else {
      this._procExistentesTableSource = [];
      this._refreshProceExistentesTable();
    }
  }

  public viewInfoSSClick(item: IRHProceSegSocialItem): void {
    const modalRef = this._cgModalService.showVanilla(ProceSegSocialViewInfoComponent, {size: 'xl'});
    const componentInstance: ProceSegSocialViewInfoComponent = modalRef.componentInstance;
    componentInstance.nEmpresa = item.ssNEMPRESA;
    componentInstance.ano = item.ano;
    componentInstance.mes = item.mes;
    componentInstance.idOrdemFichGerado = item.idOrdemFichGerado;
  }

  public configWsSSLogin(item: IRHProceSegSocialItem): Promise<void> {
    const modalRef = this._cgModalService.showVanilla(ConfigLoginWsSSModalComponent, {size: 'md'});
    const componentInstance: ConfigLoginWsSSModalComponent = modalRef.componentInstance;
    componentInstance.nEmpresa = item.ssNEMPRESA;
    return modalRef.result;
  }

  public onSelectMultiDatasTable({item}: ICGTableOnSelect<IRHProceSegSocialDataItem>): void {
    for (const sourceItem of this._multiDatasTableSource) {
      sourceItem.selected = false;
    }
    item.selected = !item.selected;
    this.multiDatasTableCallback.rePaintBody();
    if (!item.selected) {
      if (this.vizProcExistentes) {
        this._procExistentesTableSource = this._procExistentesTableSource.filter((procExistente) => procExistente.ssMes !== item.mes);
        this._refreshProceExistentesTable();
      }
    } else if (this.vizProcExistentes) {
      this.multiDatasTableCallback.refresh();
      this._loadProceExistentes();
      return;
    }
    this._invalidateSteps(ERHProceSegSocialSteps.PROC_CONFIG, 'forward');
    this.definitionNavWizard.items[STEP_PROC_CONFIG_INDEX].setIncomplete();
    this.multiDatasTableCallback.refresh();
  }

  public onSelectProcExistentes(): void {
    this._invalidateSteps(ERHProceSegSocialSteps.PROC_CONFIG, 'forward');
    this.definitionNavWizard.items[STEP_PROC_CONFIG_INDEX].setIncomplete();
  }

  public onInitializedProcExistentes({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridProcExistentesInstance = component;
  }

  public onInitializedProcResults({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridProcResultsInstance = component;
  }

  public onInitializedProcResultsErrors({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridProcErrorsInstance = component;
  }

  public sortEmpresasByNEmpresa(): void {
    this.empresasSource = orderBy(this.empresasSource, ['nEmpresa', 'asc']);
    this.empresasSel = orderBy(this.empresasSel, ['nEmpresa', 'asc']);
    this.orderBySelected = this._translateService.instant('proceSegSocial.btn.dropdown.sortEmpresasByNEmpresa');
  }

  public sortEmpresasByName(): void {
    this.empresasSource = orderBy(this.empresasSource, ['nomeEmpresa', 'asc']);
    this.empresasSel = orderBy(this.empresasSel, ['nomeEmpresa', 'asc']);
    this.orderBySelected = this._translateService.instant('proceSegSocial.btn.dropdown.sortEmpresasByName');
  }

  public readonly fnValidateStepEmpresasSel: (event: IPlNavWizardEventValidator) => boolean = () => this._validateStepEmpresasSel();

  public readonly fnValidateStepProcConfig: (event: IPlNavWizardEventValidator) => Promise<void> = () => this._validateStepProcConfig();

  public readonly fnFinalize: () => Promise<void> = () => this._finalize();

  public readonly fnMultiDatasTableSource = (): Array<IRHProceSegSocialDataItem> => this._getMultiDatasTableSource();

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._appService.sendBeacon(this._proceSegSocialService.stopJobUrl());
  }

  private _init(): void {
    this.model = {
      datas: [],
      empresas: [],
      reProcList: [],
      canReProcExistentes: false
    };
    this.selectedYear = moment().subtract(1, 'months').year();
    this.pbProcLabel = 'A processar...';
    this._procResultsTableSource = [];
    this._procResultsErrorsTableSource = [];
  }

  private _showTimeoutModal(): Promise<void> {
    return this._cgModalService.showOkCancel('proceSegSocial.jobTimeoutModalTitle', 'proceSegSocial.jobTimeoutModalMessage', {
      size: 'md',
      showCancelBtn: false,
      btnOkText: 'proceSegSocial.comecar',
      backdrop: 'static',
      keyboard: false
    });
  }

  private _handleStateError(state: IRHProceSegSocialStatus): void {
    this._cgModalService.showOkCancel('proceSegSocial.erros', state.description, {
      size: 'md',
      showCancelBtn: false,
      backdrop: 'static',
      keyboard: false
    });
  }

  private _loadEmpresas(): boolean {
    const empresas: Array<IJsonErpUser> = this.session.erps.filter((erp: IJsonErpUser) => {
      const role: IJsonUserRole = erp.rolesAcess.find((erpRole: IJsonUserRole) => erpRole.role === ROLE.RH);
      return isDefined(role);
    });
    let idx = -1;
    const foundItem = empresas.find((item: IJsonErpUser, index: number) => {
      if (item.nEmpresa === this.session.erp.nEmpresa) {
        idx = index;
        return true;
      }
      return false;
    });
    if (idx > -1 && isDefinedNotNull(foundItem)) {
      empresas.splice(idx, 1);
      this.empresasSel = [foundItem];
    }
    this.empresasSource = empresas;
    this.sortEmpresasByNEmpresa();
    return true;
  }

  private _finalize(): Promise<void> {
    this._wizNavControl(true, false);
    this._init();
    this._invalidateSteps(ERHProceSegSocialSteps.INTRO, 'forward');
    this._setWizActiveStep(ERHProceSegSocialSteps.INTRO);
    return Promise.resolve();
  }

  private _validateStepEmpresasSel(): boolean {
    if (!this.empresasSel.length) {
      this._plAlertService.error('proceSegSocial.temSeleccionarUmaEmpresa');
      return false;
    }
    return true;
  }

  private _validateStepProcConfig(): Promise<void> {
    this.model.canReProcExistentes = false;
    for (const item of this._procExistentesTableSource) {
      item.selected = this.selectedRowKeys.findIndex((index: number) => item._index === index) !== -1;
    }
    this.model.reProcList = this._procExistentesTableSource
      .filter((item: IRHProceSegSocialProcessExistentesItem) => item.selected)
      .map<IRHProceSegSocialReProcItem>((item: IRHProceSegSocialProcessExistentesItem) => {
        return {
          empresa: item.empresa,
          ssAno: item.ssAno,
          ssMes: item.ssMes,
          tipoProcessamento: item.tipoProcessamento
        };
      });
    const itemsCanReproc = this._procExistentesTableSource.filter((item: IRHProceSegSocialProcessExistentesItem) => item.selected && item.fichGeradoPodeReprocessarFicheiro === true);
    if (itemsCanReproc.length > 0) {
      return this._cgModalService
        .showOkCancel('global.text.confirmation', 'proceSegSocial.procExistentesReprocMessage', {
          size: 'md',
          btnOkText: 'proceSegSocial.reprocEmpSelected',
          btnCancelText: 'proceSegSocial.loadProcSavedAndGenNewsForNotProccessed',
          backdrop: 'static',
          showCloseBtn: false,
          keyboard: false
        })
        .then(() => {
          this.model.canReProcExistentes = true;
          return this._processar();
        })
        .catch((reason: unknown) => {
          this._logger.error(reason);
          return this._processar();
        });
    }
    return this._processar();
  }

  private _getMultiDatasTableSource(): Array<IRHProceSegSocialDataItem> {
    this._multiDatasTableSource = this._multiDatasSourceCache[this.selectedYear];
    if (this._multiDatasTableSource) {
      if (this._isFirstTime) {
        for (const item of this._multiDatasTableSource) {
          item.selected = false;
        }
        this._selectMultiDatasCurrentMonth();
        this._isFirstTime = false;
      }
      return this._multiDatasTableSource;
    }
    this._multiDatasTableSource = this._buildDataSource(this.selectedYear);
    if (this._isFirstTime) {
      this._selectMultiDatasCurrentMonth();
      this._isFirstTime = false;
    }
    this._multiDatasSourceCache[this.selectedYear] = this._multiDatasTableSource;
    return this._multiDatasTableSource;
  }

  private _startProcessChecker(fromInit: boolean = false): void {
    this._wizNavControl(false, false);
    this._intervalId = window.setInterval(() => {
      this._proceSegSocialService.getJobStatus().then((response: IRHProceSegSocialStatus) => {
        this.currentStatus = response;
        if (response.state === ERHProceSegSocialStatus.Timeout) {
          clearInterval(this._intervalId);
          this._showTimeoutModal();
          this._resetWizard();
          this._init();
        } else if (response.state === ERHProceSegSocialStatus.Error) {
          clearInterval(this._intervalId);
          this._handleStateError(response);
          if (fromInit) {
            this._init();
            this._resetWizard();
          } else {
            this._wizNavControl(true, false);
            this.definitionNavWizard.items[STEP_PROC_CONFIG_INDEX].setIncomplete();
            this._setWizActiveStep(ERHProceSegSocialSteps.PROC_CONFIG);
          }
        } else if (response.state === ERHProceSegSocialStatus.Ended) {
          clearInterval(this._intervalId);
          this._showResults();
        }
      });
    }, INTERVAL_TIMEOUT);
  }

  private _resetWizard(): void {
    this._wizNavControl(true, true);
    this._invalidateSteps(ERHProceSegSocialSteps.INTRO, 'forward');
    this._setWizActiveStep(ERHProceSegSocialSteps.INTRO);
    this.definitionNavWizard.items[STEP_INTRO_INDEX].setIncomplete();
  }

  private _processar(): Promise<void> {
    this.model.empresas = this.empresasSel.map((item: IJsonErpUser) => item.nEmpresa);
    this.model.datas = this._multiDatasTableSource.filter((item: IRHProceSegSocialDataItem) => item.selected);
    if (!this.model.datas.length) {
      this._plAlertService.error('proceSegSocial.temSeleccionarDatas');
      return Promise.resolve();
    }
    this._wizNavControl(false, false);
    return this._proceSegSocialService.processar(this.model).then(() => {
      this._startProcessChecker();
    });
  }

  private _getWizStepIndex(wizStepId: ERHProceSegSocialSteps): number {
    switch (wizStepId) {
      case ERHProceSegSocialSteps.INTRO:
        return STEP_INTRO_INDEX;
      case ERHProceSegSocialSteps.EMPRESAS_SEL:
        return STEP_EMPRESAS_SEL_INDEX;
      case ERHProceSegSocialSteps.PROC_CONFIG:
        return STEP_PROC_CONFIG_INDEX;
      case ERHProceSegSocialSteps.PROC:
        return STEP_PROC_INDEX;
      case ERHProceSegSocialSteps.PROC_RESULTS:
        return STEP_PROC_RESULTS_INDEX;
      default:
        return -1;
    }
  }

  private _setWizActiveStep(wizStepId: ERHProceSegSocialSteps, markPrevAsVisited: boolean = false): void {
    const stepIndex = this._getWizStepIndex(wizStepId);
    if (markPrevAsVisited) {
      for (let i = 0; i < stepIndex; i++) {
        this.definitionNavWizard.items[i].visited = true;
        this.definitionNavWizard.items[i].setComplete();
      }
    }
    this.definitionNavWizard.items[stepIndex].visited = true;
    this.plWizardCallback.setStep(this.definitionNavWizard.items[stepIndex], true);
  }

  private _wizNavControl(nav: boolean, finilize: boolean = true, hidePrev: boolean = false): void {
    this.propertiesNavWizard = {disableNavigation: !nav, disableFinalize: !finilize, hidePrevious: hidePrev};
  }

  private _invalidateSteps(fromStep: ERHProceSegSocialSteps, direction: 'forward' | 'backward'): void {
    const mapArray: Array<ERHProceSegSocialSteps> = [
      ERHProceSegSocialSteps.INTRO,
      ERHProceSegSocialSteps.EMPRESAS_SEL,
      ERHProceSegSocialSteps.PROC_CONFIG,
      ERHProceSegSocialSteps.PROC,
      ERHProceSegSocialSteps.PROC_RESULTS
    ];
    const fromIndex = mapArray.findIndex((item: ERHProceSegSocialSteps) => item === fromStep);
    this.definitionNavWizard.items.forEach((step: IPlNavWizardStep) => {
      const itemIndex = mapArray.findIndex((item: ERHProceSegSocialSteps) => item === step.stepId);
      const doInvalidate = direction === 'forward' ? itemIndex > fromIndex : itemIndex < fromIndex;
      if (doInvalidate) {
        step.visited = false;
        step.setIncomplete();
      }
    });
  }

  private _selectMultiDatasCurrentMonth(): void {
    const itemIndex: number = this._multiDatasTableSource.findIndex(
      (dataItem: IRHProceSegSocialDataItem) => dataItem.mes === this._currentMonth && dataItem.ano === moment().subtract(1, 'months').year()
    );
    if (itemIndex !== -1) {
      this._multiDatasTableSource[itemIndex].selected = true;
      this.multiDatasTableCallback.rePaintBodyItem(itemIndex);
    }
  }

  private _buildDataSource(year: number): Array<IRHProceSegSocialDataItem> {
    const source: Array<IRHProceSegSocialDataItem> = [];
    for (let i = 1; i <= TOTAL_MONTHS; i++) {
      source.push({
        selected: false,
        mes: i,
        ano: year,
        descricao: moment()
          .year(year)
          .month(i - 1)
          .format('MMMM [de] YYYY')
      });
    }
    return source;
  }

  private _getProcExistentesTableSource(): Array<IRHProceSegSocialProcessExistentesItem> {
    this.selectedRowKeys = [];
    let index = 0;
    for (const item of this._procExistentesTableSource) {
      item._index = index;
      if (item.selected) {
        this.selectedRowKeys.push(index);
      }
      index++;
    }
    return this._procExistentesTableSource;
  }

  private _getProcResultsTableSource(): Array<IRHProceSegSocialItem> {
    return this._procResultsTableSource;
  }

  private _getProcResultsErrorsTableSource(): Array<IRHProceSegSocialErroItem> {
    return this._procResultsErrorsTableSource;
  }

  private _loadProceExistentes(): Promise<void> {
    const params: IRHProceSegSocialProcessExistentesParams = {
      empresas: this.empresasSel.map((empresa: IJsonErpUser) => empresa.nEmpresa),
      datas: this._multiDatasTableSource.filter((item: IRHProceSegSocialDataItem) => item.selected)
    };

    if (!params.datas.length) {
      this._plAlertService.error(this._translateService.instant('proceSegSocial.temSeleccionarDatas'));
      return undefined;
    }

    return this._proceSegSocialService.getProcessExistentes(params).then((response: Array<IRHProceSegSocialProcessExistentesItem>) => {
      this._procExistentesTableSource = response;
      this._refreshProceExistentesTable();
    });
  }

  private _refreshProceExistentesTable(): void {
    if (this._dataGridProcExistentesInstance) {
      this._dataGridProcExistentesInstance.refresh();
    }
  }

  private _loadResults(): Promise<void> {
    return this._proceSegSocialService.getProcResults().then((response: IRHProceSegSocialSegSocialList) => {
      this._procResultsTableSource = response.list;
      this._procResultsTableSource.forEach((item: IRHProceSegSocialItem) => {
        item.statusImage = `fa fa-circle ${item.erros !== 0 ? 'text-danger' : 'text-success'}`;
      });
      this._procResultsErrorsTableSource = response.erros;
      this._refreshProcResultsTable();
      this._refreshProcResultsErrorsTable();
      if (this._procResultsErrorsTableSource.length > 0) {
        this.procResultsTabsCallback.select(this.tabIdRrocResultsErrors);
      }
    });
  }

  private _showResults(): Promise<void> {
    this._wizNavControl(false, true, true);
    this._setWizActiveStep(ERHProceSegSocialSteps.PROC_RESULTS, true);
    return this._loadResults();
  }

  private _refreshProcResultsTable(): void {
    if (this._dataGridProcResultsInstance) {
      this._dataGridProcResultsInstance.refresh();
    }
  }

  private _refreshProcResultsErrorsTable(): void {
    if (this._dataGridProcErrorsInstance) {
      this._dataGridProcErrorsInstance.refresh();
    }
  }

  private _printMapPerEmp(): void {
    const modalRef = this._cgModalService.showVanilla(ProceSegSocialPrintModalComponent, {size: 'xl'});
    const componentInstance: ProceSegSocialPrintModalComponent = modalRef.componentInstance;
    componentInstance.modalTitle = this._translateService.instant('proceSegSocial.printMapPerEmpModalTitle');
    componentInstance.mode = ERHProceSegSocialPrintMode.MAPA_POR_EMPREGADO;
  }

  private _printSummary(): void {
    const modalRef = this._cgModalService.showVanilla(ProceSegSocialPrintModalComponent, {size: 'xl'});
    const componentInstance: ProceSegSocialPrintModalComponent = modalRef.componentInstance;
    componentInstance.modalTitle = this._translateService.instant('proceSegSocial.printSummaryModalTitle');
    componentInstance.mode = ERHProceSegSocialPrintMode.SUMMARY;
  }

  private async _sendFile(): Promise<void> {
    await this._cgModalService.showOkCancel('global.text.confirmation', 'proceSegSocial.promptSendModalMessage', {
      size: 'md',
      btnOkText: 'global.btn.yes',
      btnCancelText: 'global.btn.no',
      backdrop: 'static',
      showCloseBtn: false,
      keyboard: false
    });

    const sendFileResponse: IRHProceSegSocialSendWebService = await this._proceSegSocialService.sendFileWebService();

    const modalTitle = sendFileResponse.isEstadoComErros ? 'proceSegSocial.promptAlertModalTitle' : 'global.text.confirmation';
    const modalMessage = sendFileResponse.isEstadoComErros ? 'proceSegSocial.enviadoComErros' : 'proceSegSocial.enviadoComSuccess';

    await this._cgModalService.showOkCancel(modalTitle, modalMessage, {
      size: 'md',
      btnOkText: 'global.btn.yes',
      btnCancelText: 'global.btn.no',
      backdrop: 'static',
      showCloseBtn: false,
      keyboard: false
    });

    this._maintenanceProcSegSocialConsultaEnvios.maintenance({
      params: {
        ano: sendFileResponse.ano,
        mes: sendFileResponse.mes
      }
    });
  }

  private _genDRIFile(): Promise<void> {
    return this._proceSegSocialService.genDRIFile().then((response: HttpResponse<Blob>) => {
      const doc: Blob = response.body;
      if (!doc?.size) {
        this._plAlertService.success(this._translateService.instant('proceSegSocial.semAlteracaoParaGerar'));
      } else {
        downloadStream(response);
      }
    });
  }

  private _viewSends(): Promise<void> {
    return this._maintenanceProcSegSocialConsultaEnvios.maintenance().then(() => undefined);
  }
}
