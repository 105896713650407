import {Component, Injector, OnInit} from '@angular/core';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IJsonABDES} from '../../jsonABDES.entity.interface';
import {IPlToolbarItem} from 'pl-comps-angular';
import {IRHCopiaAbonosDescontosParams, MODULE_NAME_RH_COPIA_ABONOS_DESCONTOS} from '../../../../modules/portalrh/rhcopiaabonosdescontos/rhCopiaAbonosDescontos.module.interface';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {ENTITY_NAME_DESCONTOS, ETipoAbonoDesconto} from '../../abdes.entity.interface';

@Component({
  selector: 'abdes-list',
  templateUrl: './abdes.entity.list.component.html'
})
export class AbDesListComponent extends ModuloEntityListComponent<IJsonABDES> implements OnInit {
  public btnCopyAbdes: IPlToolbarItem;

  private readonly _maintenanceInstanceRHCopiaAbonosDescontos: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this._maintenanceInstanceRHCopiaAbonosDescontos = this._moduleMaintenanceService.build(MODULE_NAME_RH_COPIA_ABONOS_DESCONTOS);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.toolbar.addButton({
      id: 'btnCopyAbdes',
      order: 125,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-files-o"></i>',
      class: 'btn-primary',
      caption: 'gto.copiar',
      click: () => this._copyAbdes()
    });
  }

  private _copyAbdes(): Promise<void> {
    const params: IRHCopiaAbonosDescontosParams = {
      abdes: this.entityName === ENTITY_NAME_DESCONTOS ? ETipoAbonoDesconto.Desconto : ETipoAbonoDesconto.Abono,
      codAbdes: '',
      nomeCodAbdes: ''
    };

    return this._maintenanceInstanceRHCopiaAbonosDescontos.maintenance({params: params, closeOnSaved: true});
  }
}
