<div>
  <entity-detail [entityName]="entityName" [(model)]="model" [type]="type" [maintenanceMode]="maintenanceMode" [callback]="callback" (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form>
        <pl-group>
          <label [translate]="'crabd.fields.tipoAbDescOrigem'"></label>
          <edit>
            <pl-edit type="tipoAbonoDesc" [(model)]="model.tipoAbDescDestino"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'crabd.fields.codDestino'"></label>
          <edit>
            <div *ngIf="model.tipoAbDescDestino === 0">
              <entity-autocomplete
                entity="abonos"
                [model]="model"
                (modelChange)="codDestinoChange($event)"
                [selectedKey]="model.codDestino"
                (evtSelectedDescriptionChanged)="model.nomeABDESDestino = $event"
                [fieldsMap]="{codAbDesc: 'codDestino', designacaoBreve: 'nomeABDESDestino'}"
                [properties]="{validators: {required: {value: true}}}"
                [output]="abdesOutput">
              </entity-autocomplete>
            </div>
            <div *ngIf="model.tipoAbDescDestino === 1">
              <entity-autocomplete
                entity="descontos"
                [model]="model"
                (modelChange)="codDestinoChange($event)"
                [selectedKey]="model.codDestino"
                (evtSelectedDescriptionChanged)="model.nomeABDESDestino = $event"
                [fieldsMap]="{codAbDesc: 'codDestino', designacaoBreve: 'nomeABDESDestino'}"
                [properties]="{validators: {required: {value: true}}}"
                [output]="abdesOutput">
              </entity-autocomplete>
            </div>
          </edit>
        </pl-group>

        <div class="align-items-center mb-2">
          <div class="col-auto pt-4">
            <pl-edit-checkbox [(model)]="model.entSuporteEmpregado" [properties]="{label: 'crabd.fields.entSuporteEmpregado'}" [attrName]="'dataDocOrigem'"></pl-edit-checkbox>
          </div>

          <div class="col">
            <label [translate]="'crabd.fields.incidenciaEmpregado'"></label>
            <edit>
              <pl-edit type="number" [(model)]="model.incidenciaEmpregado"></pl-edit>
            </edit>
          </div>

          <div class="col-auto pt-4">
            <pl-edit-checkbox [(model)]="model.entSuportePatronato" [properties]="{label: 'crabd.fields.entSuportePatronato'}" [attrName]="'dataDocOrigem'"></pl-edit-checkbox>
          </div>

          <div class="col">
            <label [translate]="'crabd.fields.incidenciaPatronato'"></label>
            <edit>
              <pl-edit type="number" [(model)]="model.incidenciaPatronato"></pl-edit>
            </edit>
          </div>
        </div>

        <pl-group *ngIf="model.entSuporteEmpregado">
          <label [translate]="'crabd.fields.tipoExcecao'"></label>
          <edit>
            <pl-edit [type]="'tipoExcecaoCRABD'" [(model)]="model.tipoExcecao"></pl-edit>
          </edit>
        </pl-group>

        <div *ngIf="model.tipoExcecao !== 0 && model.entSuporteEmpregado">
          <label [translate]="'crabd.fields.excecoes'"></label>
          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinition"
            [dataSource]="dataGridDefinition.dataSource"
            (onInitialized)="onInitialized($event)"
            (onContentReady)="onContentReady()"
            [selectedRowKeys]="selectedRowKeys"
            (selectedRowKeysChange)="selectedRowKeysChanged($event)"
            (onToolbarPreparing)="onDataGridToolbarPreparing($event)">
            <div *dxTemplate="let headerInfo of 'headerTemplateCRNEG'">
              <span class="float-end">
                <button type="button" class="btn btn-success btn-xs action-add-line" (click)="adicionarExcecao()">
                  <i class="fa fa-fw fa-plus"></i>
                </button>
                <button type="button" class="btn btn-danger btn-xs action-delete" (click)="deleteSelectedCRNEG()" [disabled]="!selectedRowKeys">
                  <i class="fa fa-fw fa-trash"></i>
                </button>
              </span>
            </div>
          </dx-data-grid>
        </div>
      </pl-form>
    </div>
  </entity-detail>
</div>
