import {Component, Injector} from '@angular/core';
import {CGModalComponent} from '../cgmodal.component';
import {DATA_SOURCE_PAISES_ISO_3166} from '../../../../datasources/paisesiso3166/paisesISO3166.datasource';
import {IPaisesISO3166} from '../../../../datasources/paisesiso3166/paisesISO3166.datasource.interface';
import {IDevExpressDataGrid} from '../../../devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'choose-country-modal',
  templateUrl: './choosecountry.modal.component.html'
})
export class CGModalChooseCountryComponent extends CGModalComponent<IPaisesISO3166> {
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly countries: Array<IPaisesISO3166>;
  public selected: IPaisesISO3166;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.countries = DATA_SOURCE_PAISES_ISO_3166.data.filter((country: IPaisesISO3166) => country.abrevMoeda);
    this.dataGridDefinition = {
      columns: [
        {dataField: 'codigo', caption: 'paisesISO3166.fields.codigo'},
        {dataField: 'designacaoPT', caption: 'paisesISO3166.fields.designacaoPT'},
        {dataField: 'codigoAlpha2', caption: 'paisesISO3166.fields.codigoAlpha2'},
        {dataField: 'codigoAlpha3', caption: 'paisesISO3166.fields.codigoAlpha3'},
        {dataField: 'designacao', caption: 'paisesISO3166.fields.designacao'}
      ],
      export: {enabled: false},
      selection: {mode: 'single', allowSelectAll: false, showCheckBoxesMode: 'always'},
      rowAlternationEnabled: true,
      height: '60vh',
      paging: {enabled: false},
      pager: {visible: false},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'}
    };
  }

  public select(item: IPaisesISO3166): void {
    this.selected = item;
  }
}
