import {isArray} from 'pl-comps-angular';
import {EDebitoCredito} from '../datasources/debitocredito/debitoCredito.datasource.interface';
import {round} from '../../common/utils/utils';
import {TDate} from '../../common/dates';

export enum EEntidadeBancariaProvider {
  Tink,
  NBanks,
  UnifiedPost
}

export enum EEntidadeBancariaEstado {
  NaoLancado,
  Lancado,
  Ignorado,
  NaoLancadoComDocPossivel
}

export enum EEntidadeBancariaDocDigital {
  NaoTem,
  Tem,
  Varios
}

export type TEntidadeBancariaEstados = Array<EEntidadeBancariaEstado> | ReadonlyArray<EEntidadeBancariaEstado>;

export interface IJsonEntidadeBancariaTransaction {
  id: string;
  accountId: string;
  amount: number;
  date: TDate;
  dateShort: TDate;
  description: string;
  status: string;
  accountBalance: number;
  imported: boolean;
  predefinidoID: number;
  predefinidoNome: string;
  bankPreDefCfgId: string;
  estado: EEntidadeBancariaEstado;
  extPocCabID: string;
  selected: boolean;
  order: number;
  docDigital: EEntidadeBancariaDocDigital;
  nContaBanco: string;
  nomeContaBanco: string;
  nContaCorrente: string;
  nomeContaCorrente: string;
  dc: EDebitoCredito;
  isRecebimento: boolean;
  observacoes: string;
  nDiarioPag: number;
  nDiarioRec: number;
}

export interface IJsonEntidadeBancariaConsent {
  credentialsId: string;
  accountIds: Array<string>;
  sessionExpiryDate: TDate;
  status: string;
  statusUpdated: TDate;
  errorReason: string;
  errorMessage: string;
  errorType: string;
  providerName: string;
}

export interface IJsonBankPreDefCfg {
  bankPreDefCfgId: string;
  bankAccountId: string;
  descriptionKey: string;
  preDefinidosID: number;
  preDefinidosNome: string;
  nConta: string;
  nomeConta: string;
}

export interface IJsonEntidadeBancariaRecPag {
  nLanc: string;
  extPocCabID: string;
  nDocumento: string;
  descricao: string;
  nDocExterno: string;
  dataDoc: TDate;
  dataVencimento: TDate;
  dc: EDebitoCredito;
  valor: number;
  valorPago: number;
  valorPorPagar: number;
  valorAReceber: number;
  valorDesconto: number;
}

export interface IJsonEntidadeBancariaSaveRecPagData {
  transaction: IJsonEntidadeBancariaTransaction;
  recPagList: Array<IJsonEntidadeBancariaRecPag>;
  isRecebimento: boolean;
  dateRecPag: TDate;
}

export interface IJsonEntidadeBancariaSearchResult {
  transactions: Array<IJsonEntidadeBancariaTransaction>;
  warnings: Array<string>;
}

export interface IJsonEntidadeBancariaAddNewAccount {
  financialInstitutionId?: string;
  iban?: string;
  nomeTitular?: string;
  redirectUrl?: string;
}

export interface IEntidadeBancariaAddNewAccountState {
  provider: EEntidadeBancariaProvider;
  state: string;
}

export function entidadeBancariaRecPagObterTotalValorAReter(recPagList: Array<IJsonEntidadeBancariaRecPag>, isRecebimento: boolean, precision: number = 2): number {
  let totalValorAReter = 0;
  if (isArray(recPagList)) {
    for (const recPag of recPagList) {
      const valorAReter: number = recPag.valorAReceber - recPag.valorDesconto;
      if (recPag.dc === EDebitoCredito.Debito) {
        if (isRecebimento) {
          totalValorAReter += valorAReter;
        } else {
          totalValorAReter -= valorAReter;
        }
      }
      if (recPag.dc === EDebitoCredito.Credito) {
        if (isRecebimento) {
          totalValorAReter -= valorAReter;
        } else {
          totalValorAReter += valorAReter;
        }
      }
    }
  }
  return round(totalValorAReter, precision);
}
