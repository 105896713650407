import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_CALC_PR_VENDA} from './calcPrVenda.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_CALC_PR_VENDA: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_CALC_PR_VENDA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 1, name: 'calcPrVenda.data.marginOnSellingPrice'},
    {value: 2, name: 'calcPrVenda.data.marginOnPurchasePrice'}
  ]
});
