import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_FAC_PUBLICA_MODELO} from './tipoFacPublicaModelo.datasource.interface';
import {EFacPublicaModelo} from '../../modules/faturacaopublica/jsonFaturacaoPublica.module.interface';

export const DATA_SOURCE_TIPO_FAC_PUBLICA_MODELO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_FAC_PUBLICA_MODELO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EFacPublicaModelo.ND, name: 'tipofacpublicamodelo.data.nd'},
    {value: EFacPublicaModelo.CIUS, name: 'tipofacpublicamodelo.data.cius'},
    {value: EFacPublicaModelo.CIUS_IP, name: 'tipofacpublicamodelo.data.ciusip'},
    {value: EFacPublicaModelo.CIUS_Saphety, name: 'tipofacpublicamodelo.data.ciussaphety'},
    {value: EFacPublicaModelo.CIUS_FEAP, name: 'tipofacpublicamodelo.data.ciusfeap'}
  ]
});
