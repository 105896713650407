<div class="modelo10-config-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo25.config.modalTitle'"></h5>
  </div>

  <div class="modal-body">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinitionNumerarioList"
      [dataSource]="modeloConfig.contaAssociadaDonativoNumerarioList"
      (onInitialized)="onInitializedNumerosList($event)"
      (onKeyDown)="onKeyDown($event)">
      <div *dxTemplate="let cellInfo of 'editCellContaDe'">
        <entity-autocomplete entity="pocs" attrName="contaDe" [model]="cellInfo.data.contaDe" (selectedKeyChange)="cellInfo.data.contaDe = $event" [helperMode]="true" output="key">
        </entity-autocomplete>
      </div>

      <div *dxTemplate="let cellInfo of 'editCellContaAte'">
        <entity-autocomplete entity="pocs" attrName="contaAte" [model]="cellInfo.data.contaAte" (selectedKeyChange)="cellInfo.data.contaAte = $event" [helperMode]="true" output="key">
        </entity-autocomplete>
      </div>
    </dx-data-grid>

    <hr />

    <dx-data-grid
      class="mt-5"
      [cgDxDataGrid]="dataGridDefinitionEspecieList"
      [dataSource]="modeloConfig.contaAssociadaDonativoEspecieList"
      (onInitialized)="onInitializedEspeciesList($event)"
      (onKeyDown)="onKeyDown($event)">
      <div *dxTemplate="let cellInfo of 'editCellContaDe'">
        <entity-autocomplete entity="pocs" attrName="contaDe" [model]="cellInfo.data.contaDe" (selectedKeyChange)="cellInfo.data.contaDe = $event" [helperMode]="true" output="key">
        </entity-autocomplete>
      </div>

      <div *dxTemplate="let cellInfo of 'editCellContaAte'">
        <entity-autocomplete entity="pocs" attrName="contaAte" [model]="cellInfo.data.contaAte" (selectedKeyChange)="cellInfo.data.contaAte = $event" [helperMode]="true" output="key">
        </entity-autocomplete>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-dismiss" [onClick]="close" [disabled]="closeDisabled"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
