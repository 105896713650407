<div class="docscontasinventario-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="title"></h5>
  </div>

  <div class="modal-body">
    <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="docsContasInventarioModal" [dataSource]="dataGridDefinition.dataSource" (onCellClick)="onCellClick($event)">
      <div *dxTemplate="let item of 'masterDetailTemplate'">
        <documento-contabilidade [(doc)]="item.data._docContabilidade"></documento-contabilidade>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
