<div class="ativosdepreciacao entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="search" plPromise>
        <pl-group>
          <pl-group>
            <label [translate]="'ativosdepreciacao.header.anoDeCalculo'"></label>
            <edit>
              <pl-autocomplete
                [source]="metodoSource"
                [model]="header.anoDeCalculo"
                [rowTemplate]="anoDeCalculoTemplate"
                (evtSelected)="anoDeCalculoChanged($event)"
                [allowInvalid]="false"
                output="ano">
              </pl-autocomplete>
            </edit>
          </pl-group>
          <pl-group class="align-items-center">
            <label></label>
            <edit>
              <pl-edit
                type="boolean"
                attrName="pesquisaAnosAnteriores"
                [model]="header.pesquisaAnosAnteriores"
                (modelChange)="pesquisaAnosAnterioresChanged($event)"
                [properties]="{label: 'ativosdepreciacao.header.mostraAtivosDepAnosAnteriores'}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'ativosdepreciacao.header.codAtivoDe'"></label>
            <edit>
              <pl-edit type="cginteger" [(model)]="header.codAtivoDe" plAutoFocus></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit type="cginteger" [(model)]="header.codAtivoAte"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'ativosdepreciacao.header.dataAquisicaoDe'"></label>
            <edit>
              <pl-edit type="date" [(model)]="header.dataAquisicaoDe" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit type="date" [(model)]="header.dataAquisicaoAte" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group class="align-self-end">
          <edit>
            <pl-button type="submit" klass="btn btn-sm btn-primary"><i class="fa fa-fw fa-search"></i><span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <div class="mt-3">
    <pl-alert *ngIf="model.comDataUtilEmAnosPost.length > 0" type="warning" [closeable]="false">
      <div [innerHTML]="'ativosdepreciacao.header.alertativosdataposterior' | translate"></div>
      <div class="hyperlink" [translate]="'ativosdepreciacao.btn.consultar'" (click)="openAtivosDataPosterior()"></div>
    </pl-alert>

    <pl-tabs [activeId]="activeTab" (activeIdChange)="changedTab($event)">
      <pl-tab [id]="tabsIds.PorDepreciar">
        <div *plTabTitle><i class="fa fa-fw fa-list-alt" aria-hidden="true"></i>&nbsp;<span [translate]="'ativosdepreciacao.tab.ativosPorDepreciarNoAno'"></span></div>

        <div *plTabContent>
          <ng-container *ngTemplateOutlet="templateCGDxDataGridAtivosDep; context: {instanceName: 'ativosPorDepreciarNoAno'}"></ng-container>
        </div>
      </pl-tab>

      <pl-tab [id]="tabsIds.Depreciados">
        <div *plTabTitle><i class="fa fa-fw fa-check-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'ativosdepreciacao.tab.ativosDepreciadosNoAno'"></span></div>

        <div *plTabContent>
          <ng-container *ngTemplateOutlet="templateCGDxDataGridAtivosDep; context: {instanceName: 'ativosDepreciadosNoAno'}"></ng-container>
        </div>
      </pl-tab>

      <pl-tab [id]="tabsIds.DepreciadosAnoAnterior" [disabled]="!header.pesquisaAnosAnteriores">
        <div *plTabTitle [ngbTooltip]="header.pesquisaAnosAnteriores ? '' : ('ativosdepreciacao.tab.ativosDepEmAnoAnteriorTooltip' | translate)">
          <i class="fa fa-fw fa-calendar-check-o" aria-hidden="true"></i>&nbsp;<span class="pe-auto" [translate]="'ativosdepreciacao.tab.ativosDepreciadosEmAnoAnterior'"></span>
        </div>
        <div *plTabContent>
          <ng-container *ngTemplateOutlet="templateCGDxDataGridAtivosDep; context: {instanceName: 'ativosDepreciadosEmAnoAnterior'}"></ng-container>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>
</div>

<ng-template #templateCGDxDataGridAtivosDep let-instanceName="instanceName">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    [cgDxDataGridInstanceName]="instanceName"
    [dataSource]="dataGridDefinition.dataSource"
    [(selectedRowKeys)]="selectedKeys"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()"
    (onCellPrepared)="onCellPrepared($event)"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onSelectionChanged)="onSelectionChanged($event)">
    <div *dxTemplate="let cell of 'editCellTemplateContabPercReducao'">
      <pl-edit type="number" attrName="contabPercReducao" [model]="cell.data.contabPercReducao" (modelChange)="contabPercReducaoChanged(cell.data, $event)" [properties]="propertiesInput"></pl-edit>
    </div>
  </dx-data-grid>
</ng-template>
