import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ContasClienteComponent} from './components/contasCliente.module.component';

export const MODULE_PCA_MYACCOUNT_CONTAS_CLIENTE: IModuleDefinition = {
  name: 'pcaContas',
  state: {
    url: '/contas',
    component: ContasClienteComponent,
    data: {
      roles: [ROLE.PCA],
      pageTitle: 'global.menu.pcaContas'
    }
  }
};
