import {Component, Injector, OnInit} from '@angular/core';
import {IPlTooltipConfig} from 'pl-comps-angular';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonSegur} from '../../jsonSegur.entity.interface';

@Component({
  selector: 'segur-edit',
  templateUrl: './segur.entity.edit.component.html'
})
export class SegurEditComponent extends ModuloEntityDetailComponent<IJsonSegur> implements OnInit {
  public readonly tooltipNApolice: IPlTooltipConfig;
  public readonly modoAvancado: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.tooltipNApolice = {text: 'segur.errors.invalidApolice', placement: 'left-bottom', container: 'body'};
    this.modoAvancado = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.model) {
      this.model = {
        nsegur: '',
        designaBreve: '',
        nome: '',
        morada: '',
        npostal: '',
        localidade: '',
        telefone: '',
        napolice: '',
        codSeguradoraISP: '',
        nomeCodSeguradoraISP: ''
      };
    }
  }
}
