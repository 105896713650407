<div class="entity-detail-form">
  <pl-form>
    <pl-group>
      <pl-group class="not-print">
        <label> <span [translate]="'prhrefeicoes.title_new'"></span>:</label>
        <edit>
          <select class="form-select" [ngModel]="grupoRefeicao" (ngModelChange)="fillPresencaList()">
            <option ngValue="-1" ng-selected="true" [translate]="'global.text.all'"></option>
            <option ngValue="0" [translate]="'prhrefeicoes.grupoRefeicao.almoco'"></option>
            <option ngValue="1" [translate]="'prhrefeicoes.grupoRefeicao.jantar'"></option>
          </select>
        </edit>
      </pl-group>

      <pl-group class="not-print">
        <label><span [translate]="'prhrefeicoes.fields.refConsumida'"></span>:</label>
        <edit>
          <select class="form-select" [ngModel]="usufruiu" (ngModelChange)="fillPresencaList()">
            <option ngValue="-1" ng-selected="true" [translate]="'global.text.all'"></option>
            <option ngValue="false" [translate]="'prhrefeicoes.grupoRefeicao.nao'"></option>
            <option ngValue="true" [translate]="'prhrefeicoes.grupoRefeicao.sim'"></option>
          </select>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <label [translate]="'prhrefeicoes.fields.locais'"></label>
      <edit>
        <pl-multiselect [model]="locaisSelected" [source]="locais" key="codLocal" [template]="locaisTemplate"></pl-multiselect>
      </edit>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'global.text.fromDate'"></label>
        <edit>
          <pl-edit-datepicker attrName="dateDe" [model]="dayDe" (modelChange)="changedDataDe($event)"></pl-edit-datepicker>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'global.text.toDate'"></label>
        <edit>
          <pl-edit-datepicker attrName="dateAte" [model]="dayAte" (modelChange)="changedDataAte($event)"></pl-edit-datepicker>
        </edit>
      </pl-group>
    </pl-group>

    <div class="not-print">
      <button type="button" class="btn btn-outline btn-primary btn-sm" (click)="fillPresencaList()"><i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span></button>
      <button type="button" class="btn btn-primary btn-sm" (click)="imprimir()"><i class="fa fa-fw fa-print"></i>&nbsp;<span [translate]="'global.btn.print'"></span></button>
      <button type="button" class="btn btn-light btn-sm" (click)="proceRefNaoConsumidas()" *ngIf="canProcess && sourceToProcessRefNaoCons">
        <i class="fa fa-fw fa-cog"></i><span [translate]="'prhrefeicoes.btn.procRefNCons'"></span>
      </button>
    </div>
  </pl-form>
</div>

<br />

<!-- TODO: http://youtrack.centralgest.com/issue/EC-4538/Converter-usos-da-pl-pivot-table-para-DevExpress-PivotGrid -->
<!--<pl-pivot-table [source]="source" [definition]="definition" [template]="template"></pl-pivot-table>-->
