<cg-card-panel>
  <div *cgCardPanelContent>
    <pl-form>
      <pl-group>
        <pl-group>
          <label [translate]="'global.text.listing'"></label>
          <edit>
            <pl-edit type="reports_recibossal" attrName="listagem" [model]="reportModel" (modelChange)="changedReport($event)"></pl-edit>
          </edit>
        </pl-group>
        <pl-group *ngIf="!isEmbed() && !todosColaboradores">
          <label><span [translate]="'recibosvencimentos.colaborador'"></span>:</label>
          <edit>
            <entity-autocomplete entity="dgempsfull" [model]="dgemp" (modelChange)="changedDGEMP($event)"></entity-autocomplete>
          </edit>
        </pl-group>
      </pl-group>
      <pl-group *ngIf="!isEmbed()">
        <label><span [translate]="'recibosvencimentos.todos'"></span>:</label>
        <edit>
          <pl-edit type="boolean" [model]="todosColaboradores" (modelChange)="changedTodos($event)"></pl-edit>
        </edit>
      </pl-group>
      <pl-group *ngIf="showMostraGrafico">
        <label [translate]="'recibosvencimentos.mostraGrafico'"></label>
        <edit>
          <pl-edit type="boolean" [model]="mostraGrafico" (modelChange)="changedMostraGrafico($event)"></pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>
</cg-card-panel>
<hr />

<cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
