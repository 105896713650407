import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IJsonClifo} from '../../../../../entities/clifos/jsonClifo.entity.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {PortalClientAccountService} from '../../../../../services/portalclientaccount/portalclientaccount.service';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IApiQueryResponse} from '../../../../../services/api/api.service.interface';

@Component({
  selector: 'contas-cliente',
  templateUrl: './contasCliente.module.component.html'
})
export class ContasClienteComponent extends ModuloComponent implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _portalClientAccountsService: PortalClientAccountService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columns: [
        {dataField: 'nContribuint', caption: 'clifos.fields.nContribuint'},
        {dataField: 'nome', caption: 'clifos.fields.nome'},
        {dataField: 'rua', caption: 'clifos.fields.rua'},
        {dataField: 'codPostal', caption: 'clifos.fields.codPostal'},
        {dataField: 'localidade', caption: 'clifos.fields.localidade'},
        {dataField: 'telefone', caption: 'clifos.fields.telefone'},
        {dataField: 'email', caption: 'clifos.fields.email'}
      ],
      dataSource: [],
      export: {filename: 'contas_clientes'},
      remoteOperations: false,
      pager: {
        displayMode: 'full'
      }
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._getList();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  private _getList(): Promise<void> {
    return this._portalClientAccountsService.getContasCliente().then((response: HttpResponse<IApiQueryResponse<IJsonClifo>>) => {
      this.dataGridDefinition.dataSource = response.body.list;
    });
  }
}
