import {HttpResponse} from '@angular/common/http';
import {IJsonConfigRHTransfBancarias} from '../jsonRHConfiguracoes.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_CONFIGURACOES_TRANSFERENCIAS_BANCARIAS} from '../rhConfiguracoes.module.interface';
import {RHConfiguracoesService} from '../rhConfiguracoes.module.service';
import {RHTransferenciasBancariasModuleComponent} from './components/rhTransferenciasBancarias.module.component';
import {ROLE} from '../../../services/role.const';

export const MODULE_RH_CONFIGURACOES_TRANSFERENCIAS_BANCARIAS: IModuleDefinition = {
  name: MODULE_NAME_RH_CONFIGURACOES_TRANSFERENCIAS_BANCARIAS,
  state: {
    url: `/${MODULE_NAME_RH_CONFIGURACOES_TRANSFERENCIAS_BANCARIAS}`,
    component: RHTransferenciasBancariasModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhtransferenciasbancarias'
    },
    resolve: [
      {
        provide: 'model',
        deps: [RHConfiguracoesService],
        useFactory: (rhConfiguracoesService: RHConfiguracoesService): Promise<IJsonConfigRHTransfBancarias> => {
          return rhConfiguracoesService.getConfigTransfBancarias().then((response: HttpResponse<IJsonConfigRHTransfBancarias>) => response.body);
        }
      }
    ]
  }
};
