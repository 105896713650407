<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'conciliacaoEFaturas.modal.config.title'"></h4>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'conciliacaoEFaturas.modal.config.fields.nCaracteresAValidar'"></label>
      <edit>
        <pl-edit type="cginteger" attrName="nCaracteresAValidar" [(model)]="config.nCaracteresAValidar" plAutoFocus></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'conciliacaoEFaturas.modal.config.fields.filtraPorDataDocExterno'"></label>
      <edit>
        <pl-edit type="boolean" attrName="filtraPorDataDocExterno" [(model)]="config.filtraPorDataDocExterno" plAutoFocus></pl-edit>
      </edit>
    </pl-group>

    <fieldset>
      <legend [translate]="'conciliacaoEFaturas.modal.config.diariosList'"></legend>
      <div>
        <pl-group>
          <label [translate]="'conciliacaoEFaturas.modal.config.fields.diarioAExcluir'"></label>
          <edit>
            <entity-autocomplete
              entity="diarios"
              attrName="diariosAExcluir"
              [model]="diarioAExcluir"
              (selectedKeyChange)="diarioAExcluir.nDiario = $event"
              (evtSelectedDescriptionChanged)="diarioAExcluir.nomeDiario = $event">
            </entity-autocomplete>
          </edit>
          <actions>
            <pl-tooltip [config]="{text: 'conciliacaoEFaturas.modal.config.fields.addDiarioAExcluir', placement: 'bottom-left', container: 'body'}">
              <pl-button *plTooltipContent klass="btn btn-sm btn-primary" id="addDiarioAExcluir" (evtClicked)="addDiarioAExcluir()" [disabled]="!diarioAExcluir.nDiario">
                <i class="fa fa-fw fa-plus-circle"></i><span [translate]="'global.btn.add'"></span>
              </pl-button>
            </pl-tooltip>
          </actions>
        </pl-group>

        <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="listaDiariosAExcluir" [dataSource]="config.diariosAExcluir"></dx-data-grid>
      </div>
    </fieldset>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-primary btn-sm action-submit" [onClick]="close"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-light btn-sm action-close" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</pl-form>
