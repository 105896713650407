<div [ngClass]="entityClassName">
  <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName" (configChange)="onConfigChange()"></config-options>

  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [toolbarInstanceId]="instanceName"
    [dataGridState]="dataGridState"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [properties]="listOptions"
    [beforeRequest]="fnBeforeRequest"
    [afterRequest]="fnAfterRequest"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridOnInitialized)="onDataGridOnInitialized($event)"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
  </entity-list>
</div>
