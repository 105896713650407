import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'modal-acrescimos-encargos-ferias-multi-empresa-detail',
  templateUrl: './acrescimosEncargosFerias.multiempresadetail.modal.component.html'
})
export class AcrescimosEncargosFeriasMultiempresadetailModalComponent extends CGModalComponent<boolean> {
  @Input() public anoEncargo: number;
  @Input() public nEmpresa: string;

  private _needRefresh: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._needRefresh = false;
    this.anoEncargo = this.configurations.empresa.anoEmCursoIRC;
    this.nEmpresa = this.configurations.empresa.nEmpresa;
  }

  public close(): void {
    super.close(this._needRefresh);
  }

  public dataSourceChanged(value: boolean): void {
    this._needRefresh = value;
  }
}
