import {merge} from 'lodash-es';
import InputMask from 'inputmask';
import {AfterViewInit, Component, Inject, Injector, Input, LOCALE_ID, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {getLocaleNumberSymbol, NumberSymbol} from '@angular/common';
import type {IPlEditComponentOptionsInputMask} from './edit.mask.component.interface';
import {isEmpty, isFunction, isObject, isString, isUndefinedOrNull} from '../../../common/utilities/utilities';
import {PlEditInputTypeComponent} from '../../generic/input/edit.input.type.component';

@Component({
  selector: 'pl-edit-mask',
  templateUrl: './edit.mask.component.html',
  standalone: false,
  exportAs: 'cgcEditMask'
})
export class PlEditMaskComponent extends PlEditInputTypeComponent<string, IPlEditComponentOptionsInputMask> implements OnInit, OnChanges, AfterViewInit {
  @Input() public mask: string | object;

  private _inputMask: any;
  private _isEditing: boolean;

  constructor(
    protected readonly _injector: Injector,
    @Inject(LOCALE_ID) private readonly _locale: string
  ) {
    super('text', _injector);
    this._defaultOptions = Object.freeze<IPlEditComponentOptionsInputMask>(
      merge({}, this._defaultOptions, {
        selectOnFocus: false,
        mask: undefined
      })
    );
    this._isEditing = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._changedMask();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {mask}: SimpleChanges = changes;
    if (mask && !mask.isFirstChange()) {
      this._changedMask(mask);
      if (this._inputMask && this._inputField) {
        this._inputMask.mask(this._inputField);
      }
    }
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    if (!this._inputField) {
      return;
    }
    let mask: any = {};
    if (isString(this.mask)) {
      mask.alias = this.mask;
    } else if (isObject(this.mask)) {
      mask = {...this.mask};
    }
    if (!isEmpty(mask.alias)) {
      if (isEmpty(mask.radixPoint)) {
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        mask.radixPoint = getLocaleNumberSymbol(this._locale, NumberSymbol.Decimal);
      }
      if (isEmpty(mask.groupSeparator)) {
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        mask.groupSeparator = getLocaleNumberSymbol(this._locale, NumberSymbol.Group);
      }
      this._inputMask = new InputMask(mask);
      this._inputMask.mask(this._inputField);
    }
  }

  public onInputFocus(event: FocusEvent): void {
    this._isEditing = true;
    super.onInputFocus(event);
  }

  public onInputBlur(event: FocusEvent): void {
    super.onInputBlur(event);
    this._isEditing = false;
    if (this.options.events && isFunction(this.options.events.blur)) {
      this.options.events.blur(this.value, event);
    }
  }

  public set viewValue(value: any) {
    if (!this._isEditing) {
      this.formControl.setValue(value);
    }
  }

  private _changedMask(value: string | object = this.mask): void {
    let val: string | object = value;
    if (isUndefinedOrNull(val)) {
      val = this.options.mask;
    }
    if (isEmpty(val) && !isObject(val)) {
      val = undefined;
    }
    this.mask = val;
  }
}
