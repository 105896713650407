import {HttpResponse} from '@angular/common/http';
import {Component, Injector, OnInit} from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {isObject, merge} from 'lodash-es';
import {copy, isDefined, isDefinedNotNull, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSaved
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {EGrupoDoc} from '../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {IDocfasNumsComunicacaoLogin} from '../../../../services/docfasNumsComunicacaoLogin/docfasNumsComunicacaoLogin.service.interface';
import {DocFasNumsComunicacaoLoginModalComponent} from '../../../../modules/docfasnumscomunicacaologin/docFasNumsComunicacaoLogin.modal.component';
import {ISimpleLoginCredentials} from '../../../../modules/simplelogin/simpleLogin.module.interface';
import {SimpleLoginService} from '../../../../modules/simplelogin/simpleLogin.module.service';
import {IApiRequestConfigWithBody} from '../../../../services/api/api.service.interface';
import {GrupoDocService} from '../../../../services/grupodoc/grupo.doc.service';
import {DocfasNumsService} from '../../../docfasnums/docfasNums.entity.service';
import {IJsonDocfaNum, IJsonDocfaNumATConsulta} from '../../../docfasnums/jsonDocfaNum.entity.interface';
import {ComunicacaoSeriesATModalComponent} from '../../../docfasnums/modals/comunicacao/comunicacaoseriesat/comunicacaoSeriesAt.modal.component';
import {ConsultaSerieAtModalComponent} from '../../../docfasnums/modals/consulta/consultaSerieAt.modal.component';
import {LogComunicacaoSerieAtModalComponent} from '../../../docfasnums/modals/logs/logComunicacaoSerieAt.modal.component';
import {ESubTipoMovimentoSAFT} from '../../../docscomerciais/docsComerciais.entity.interface';
import {IDocfaReport, IDocFasEntityService, ISelectKeyPair, SELECT_NVIASDEFEITO} from '../../docFas.entity.interface';
import {EReportKind, ETipoAutoFaturacao, IDocfaReportSelect, IJsonDocfa, IJsonDocfaNewFromBase, IJsonDocfaReport} from '../../jsonDocFa.entity.interface';
import {DocfasNewModalComponent} from '../../modals/newdocfas/docFas.new.modal.component';
import {DocfasNovaSerieModalComponent} from '../../modals/novaserie/docFas.novaSerie.modal.component';
import {ENTITY_NAME_CLIENTES} from '../../../clifos/clifos.entity.interface';
import {DocfasNumsComunicacaoLoginService} from '../../../../services/docfasNumsComunicacaoLogin/docfasNumsComunicacaoLogin.service';
import {IJsonQpcae} from '../../../qpcae/jsonQpcae.entity.interface';
import {IJsonDiario} from '../../../diarios/jsonDiario.entity.interface';
import {IJsonDescritivo} from '../../../descritivos/jsonDescritivo.entity.interface';
import {AuthService} from '../../../../services/auth/auth.service';
import {IJsonUserRole, TUserSession} from '../../../../services/account/jsonUserApi.interface';
import {ROLE} from '../../../../services/role.const';

@Component({
  selector: 'docfass-edit',
  templateUrl: './docFas.entity.edit.component.html'
})
export class DocfasEditComponent extends ModuloEntityDetailComponent<IJsonDocfa, IDocFasEntityService> implements OnInit {
  public readonly definitionsDataGrid: IDevExpressDataGrid;
  public readonly fornecedoresOutput: string = '{{nConta}}';
  public readonly fornecedoresFilter: string = 'autoFaturacao=true';
  public readonly reportKind: typeof EReportKind;
  public readonly nViasDefeitoSource: Array<ISelectKeyPair> = SELECT_NVIASDEFEITO;

  public definitionGridReports: IDevExpressDataGrid;
  public clifoEntName: string;
  public reports: Array<IDocfaReport>;
  public reportsSource: Array<IDocfaReportSelect>;
  public reportDefeito: string;
  public haveDataGridChanges: boolean;
  public extraMessage: string;
  public isVisibleExtraMessage: boolean;
  public haveGridReportsChanges: boolean;
  public movStockInfo: string;
  public temRoleContabilidade: boolean;

  private readonly _nContaColumn: IDevExpressDataGridColumn;
  private _dataGridInstance: dxDataGrid;
  private _dataGridInstanceRpt: dxDataGrid;
  private _docFasNumsComunicacaoLogin: IDocfasNumsComunicacaoLogin;
  private _atLogin: ISimpleLoginCredentials;
  private _origDocfanumList: Array<IJsonDocfaNum>;
  private _origReportList: Array<IDocfaReport>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _grupoDoc: GrupoDocService,
    private readonly _plAlertService: PlAlertService,
    private readonly _docfasNumsService: DocfasNumsService,
    private readonly _simpleLoginService: SimpleLoginService,
    private readonly _serviceDocFasNumsComunicacaoLogin: DocfasNumsComunicacaoLoginService,
    private readonly _authService: AuthService
  ) {
    super(_injector);
    this.reports = [];
    this.haveDataGridChanges = false;
    this.haveGridReportsChanges = false;
    this.reportKind = EReportKind;
    this.temRoleContabilidade = false;
    this._nContaColumn = {
      dataField: 'nConta',
      caption: 'docfasNums.fields.nConta',
      cellTemplate: 'nContaTmpl',
      width: 200,
      allowFiltering: false,
      allowHeaderFiltering: false,
      allowReordering: false,
      allowSorting: false,
      allowEditing: false,
      showInColumnChooser: false,
      visible: false
    };
    this.definitionsDataGrid = {
      columns: [
        {
          dataField: 'nNumer',
          dataType: 'string',
          caption: 'docfasNums.fields.nNumer',
          alignment: 'center',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: false
        },
        {
          dataField: 'num',
          dataType: 'string',
          caption: 'docfasNums.fields.num',
          alignment: 'center',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: false
        },
        {
          dataField: 'descNumer',
          dataType: 'string',
          caption: 'docfasNums.fields.descNumer',
          alignment: 'center',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: false
        },
        {
          dataField: 'data',
          dataType: 'date',
          caption: 'global.text.date',
          alignment: 'center',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: false
        },
        this._nContaColumn,
        {
          dataField: 'encerrado',
          dataType: 'boolean',
          caption: 'docfasNums.fields.encerrado',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: false
        },
        {
          dataField: 'atEstadoSerieDescricao',
          dataType: 'string',
          caption: 'docfasNums.fields.atEstadoSerieDescricao',
          alignment: 'center',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: false
        },
        {
          dataField: 'atcud',
          dataType: 'string',
          caption: 'docfasNums.fields.atcud',
          alignment: 'center',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: false
        },
        {
          dataField: 'visivelERPCloud',
          dataType: 'boolean',
          caption: 'docfasNums.fields.visivelERPCloud',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: false
        },
        {
          dataField: 'codLocalEmissao',
          dataType: 'string',
          caption: 'docfasNums.fields.codLocalEmissao',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: false
        },
        {
          dataField: 'vf_actions',
          type: 'buttons',
          caption: ' ',
          cellTemplate: 'actions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false,
          showInColumnChooser: false,
          visible: false
        }
      ],
      dataSource: new CustomStore({
        key: 'idDocfaNum',
        load: () => this._getDocFasNumsTableSource(),
        update: (idDocfaNum: number, values: Partial<IJsonDocfaNum>) => {
          const record: IJsonDocfaNum = this.model.docfanumList.find((item: IJsonDocfaNum) => item.idDocfaNum === idDocfaNum);
          if (record) {
            merge(record, values);
          }
          return Promise.resolve();
        }
      }),
      scrolling: {
        rowRenderingMode: 'virtual'
      },
      filterRow: {visible: false}
    };

    this._atLogin = {
      username: '',
      password: ''
    };

    this.isVisibleExtraMessage = false;
    this.extraMessage = '';

    this._authService.identity().then((session: TUserSession) => {
      this.temRoleContabilidade = isDefined(session.roles.find((userRole: IJsonUserRole) => [ROLE.CONTABILIDADE].includes(userRole.role)));
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._simpleLoginService.getAtCredentials().then((result) => {
      this._atLogin = result.body;
    });
    this._serviceDocFasNumsComunicacaoLogin.get().then((response) => {
      this._docFasNumsComunicacaoLogin = response.body;
    });

    if (this.type === this.stateTypes.NEW) {
      this.id = this.model.nDocFa;
      this.model.nDocFa = undefined;
    } else {
      this._checkVisibleExtraMessage();
    }

    this.definitionGridReports = {
      columns: [
        {
          dataField: 'visible',
          dataType: 'boolean',
          caption: 'docfas.reports.fields.visible',
          alignment: 'center',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: this.type === EEntityStateDetailType.EDIT
        },
        {
          dataField: 'defaultReport',
          dataType: 'boolean',
          caption: 'docfas.reports.fields.defaultReport',
          alignment: 'center',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: this.type === EEntityStateDetailType.EDIT
        },
        {
          dataField: 'nome',
          dataType: 'string',
          caption: 'docfas.reports.fields.nome',
          alignment: 'left',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: false
        },
        {
          dataField: 'descricao',
          dataType: 'string',
          caption: 'docfas.reports.fields.descricao',
          alignment: 'left',
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowReordering: false,
          allowSorting: false,
          allowEditing: false
        },
        {
          dataField: 'tipoDescricao',
          caption: 'docfas.reports.fields.tipoDescricao',
          dataType: 'string',
          cellTemplate: 'tipoTemplate',
          allowEditing: false
        }
      ],
      scrolling: {
        rowRenderingMode: 'virtual'
      },
      filterRow: {visible: false}
    };

    const grupoClientes = [EGrupoDoc.VendasEfectivas, EGrupoDoc.GuiasTransporteRemessa, EGrupoDoc.SaidasDiversas, EGrupoDoc.PropostasAClientes, EGrupoDoc.EncomendasClientes];
    const grupo = this._grupoDoc.getGrupoDoc(this.model.grupoDocfa);
    this.clifoEntName = grupoClientes.includes(grupo.id) ? ENTITY_NAME_CLIENTES : 'fornecedores';
    this._origDocfanumList = copy(this.model.docfanumList);

    this.reports = this.model.reportList?.list.map((item: IJsonDocfaReport) => {
      item.defaultReport = item.nome === this.model.reportDefeito;
      let myDesc = '';
      switch (item.tipo) {
        case EReportKind.System:
          myDesc = this._translateService.instant('docfas.reports.descricao.system');
          break;
        case EReportKind.User:
          myDesc = this._translateService.instant('docfas.reports.descricao.user');
          break;
        case EReportKind.UserEmpresa:
          myDesc = this._translateService.instant('docfas.reports.descricao.userEmpresa');
          break;
      }
      return {...item, tipoDescricao: myDesc};
    });
    this._origReportList = copy(this.reports);
    this.definitionGridReports.dataSource = this.reports;
    this.movStockInfo = this._getmovStockInfo();
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    return super.onUpdate(stateType).then(() => {
      if (this._dataGridInstance) {
        this._dataGridInstance.columnOption('vf_actions', 'visible', this.type === this.stateTypes.EDIT && this.model?.codigoSAFTTipoDoc !== '00');
        this._dataGridInstance.updateDimensions();
      }
      if (this._dataGridInstanceRpt) {
        ['visible', 'defaultReport'].forEach((fieldName) => {
          this._dataGridInstanceRpt.columnOption(fieldName, 'allowEditing', this.type === EEntityStateDetailType.EDIT);
        });
      }
      return this._dataGridInstance?.refresh();
    });
  }

  public save(config?: IApiRequestConfigWithBody<IJsonDocfa>): Promise<IJsonDocfa> {
    this.model.reportList = {
      list: !this.haveDataGridChanges ? copy(this.reports) : [],
      nviasDefault: this.model.reportList.nviasDefault,
      reportDefault: ''
    };
    this.haveGridReportsChanges = false;
    return super.save(config).then((response) => {
      if (response) {
        this.haveDataGridChanges = false;
        return response;
      }
      return undefined;
    });
  }

  public edit(): void {
    super.edit();
    this._dataGridInstance.refresh();
  }

  public cancel(): Promise<void> {
    this.haveDataGridChanges = false;
    this.haveGridReportsChanges = false;
    this.model.docfanumList = merge(this.model.docfanumList, this._origDocfanumList);

    this.reports = copy(this._origReportList);
    this.definitionGridReports.dataSource = this.reports;

    return super.cancel().then(() => {
      return this._dataGridInstance?.refresh();
    });
  }

  public async duplicate(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DocfasNewModalComponent, {size: 'md'});
    modalInstance.componentInstance.docFasBase = this.model;
    const newDocfa: IJsonDocfaNewFromBase = await modalInstance.result;
    const response: HttpResponse<IJsonDocfa> = await this.service.duplicateDocfa(newDocfa);
    const docFa: IJsonDocfa = response.body;
    this._plAlertService.success(this._translateService.instant('docfas.saved', {id: this.entity.getSuccessMessageId(docFa)}));
    if (this.maintenanceMode) {
      this.closeMaintenance(docFa);
      return;
    }
    await this._stateService.go(this.states.detail, {id: docFa.nDocFa});
  }

  public addNovaSerie(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DocfasNovaSerieModalComponent, {size: 'md'});
    const componentInstance: DocfasNovaSerieModalComponent = modalInstance.componentInstance;
    componentInstance.docfa = this.model;
    componentInstance.editAction = false;
    return modalInstance.result
      .then((result) => {
        this.model.docfanumList.push(result);
        this._dataGridInstance.refresh();
        this._plAlertService.success('docfas.warnings.addnovaserie');
      })
      .catch((error) => {
        if (isDefinedNotNull(error)) {
          this._plAlertService.error(`${<string>this._translateService.instant('docfas.errors.erroNovaSerie')}: ${<string>error.message}`);
        }
      });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
    if (this.model.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresComAcordo) {
      this._dataGridInstance.columnOption('nConta', {
        visible: true,
        showInColumnChooser: true
      });
    }
  }

  public onInitializedRpt({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstanceRpt = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
    ['num', 'descNumer', 'encerrado', 'visivelERPCloud'].forEach((fieldName) => {
      this._dataGridInstance.columnOption(fieldName, 'allowEditing', this.type === EEntityStateDetailType.EDIT);
    });
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonDocfa, number>): void {
    switch (event.column.dataField) {
      case 'vf_actions':
        if (this.type !== this.stateTypes.EDIT) {
          event.component.columnOption(event.column.dataField, 'visible', event.data?.codigoSAFTTipoDoc !== '00');
        }
        break;
    }
  }

  public onDataGridSavedRpt(event: IDevExpressDataGridEventOnSaved<IJsonDocfaReport>): void {
    if (event.changes[0]) {
      this.haveGridReportsChanges = true;
      const itemUpdated = event.changes[0].data;
      if (itemUpdated.defaultReport && !itemUpdated.visible) {
        itemUpdated.defaultReport = false;
        return;
      }
      if (itemUpdated.defaultReport && itemUpdated.nome !== this.model.reportDefeito) {
        this.model.reportDefeito = itemUpdated.nome;
        for (const report of this.reports) {
          if (report.defaultReport && report.nome !== itemUpdated.nome) {
            report.defaultReport = false;
            break;
          }
        }
      }
    }
  }

  public onDataGridSaved(event: IDevExpressDataGridEventOnSaved<IJsonDocfa>): void {
    if (event.changes[0]) {
      this.haveDataGridChanges = true;
    }
  }

  public comunicarSerie(docFaNum: IJsonDocfaNum): void {
    if (this._docFasNumsComunicacaoLogin.username || this._atLogin.username) {
      this._callComunicarSerieAT(docFaNum);
    } else {
      const instanceComunicacaoLoginModal = this._cgModalService.showVanilla(DocFasNumsComunicacaoLoginModalComponent, {size: 'sm'});
      instanceComunicacaoLoginModal.result.then((result: IDocfasNumsComunicacaoLogin) => {
        this._docFasNumsComunicacaoLogin = result;
        this._callComunicarSerieAT(docFaNum);
      });
    }
  }

  public logSerie(docFaNum: IJsonDocfaNum): void {
    if (this._docFasNumsComunicacaoLogin.username || this._atLogin.username) {
      const instanceLogComunicacao = this._cgModalService.showVanilla(LogComunicacaoSerieAtModalComponent, {size: 'lg'});
      instanceLogComunicacao.componentInstance.nDocfa = docFaNum.nDocfa;
      instanceLogComunicacao.componentInstance.nNumer = docFaNum.nNumer;
    } else {
      const instanceComunicacaoLoginModal = this._cgModalService.showVanilla(DocFasNumsComunicacaoLoginModalComponent, {size: 'sm'});
      instanceComunicacaoLoginModal.result.then((result: IDocfasNumsComunicacaoLogin) => {
        this._docFasNumsComunicacaoLogin = result;
        const instanceLogComunicacao = this._cgModalService.showVanilla(LogComunicacaoSerieAtModalComponent, {size: 'lg'});
        instanceLogComunicacao.componentInstance.nDocfa = docFaNum.nDocfa;
        instanceLogComunicacao.componentInstance.nNumer = docFaNum.nNumer;
      });
    }
  }

  public consultarSerie(docFaNum: IJsonDocfaNum): void {
    if (this._docFasNumsComunicacaoLogin.username || this._atLogin.username) {
      this._docfasNumsService.consultaSerieNaAT(docFaNum.idDocfaNum).then((result) => {
        const instanceConsultaSerie = this._cgModalService.showVanilla(ConsultaSerieAtModalComponent, {size: 'lg'});
        instanceConsultaSerie.componentInstance.model = result.body;
      });
    } else {
      const instanceComunicacaoLoginModal = this._cgModalService.showVanilla(DocFasNumsComunicacaoLoginModalComponent, {size: 'sm'});
      instanceComunicacaoLoginModal.result.then((result: IDocfasNumsComunicacaoLogin) => {
        this._docFasNumsComunicacaoLogin = result;
        this._docfasNumsService.consultaSerieNaAT(docFaNum.idDocfaNum).then((data) => {
          const instanceConsultaSerie = this._cgModalService.showVanilla(ConsultaSerieAtModalComponent, {size: 'lg'});
          instanceConsultaSerie.componentInstance.model = data.body;
        });
      });
    }
  }

  public onReportDefeitoChange(nomeRptDefeito: string): void {
    this.model.reportDefeito = nomeRptDefeito;
    this.reportDefeito = this.reportsSource.find((value) => value.nome === nomeRptDefeito).nomeDescricao;
  }

  public rowNContaChanged(nConta: string, row: IJsonDocfaNum): void {
    if (isUndefinedOrNull(nConta)) {
      if (isDefinedNotNull(row.nConta)) {
        row.nConta = undefined;
        this.haveDataGridChanges = true;
      }
    } else if (row.nConta !== nConta) {
      row.nConta = nConta;
      this.haveDataGridChanges = true;
    }
  }

  public caeChanged(cae: IJsonQpcae | string): void {
    if (isObject(cae)) {
      this.model = {...this.model, codCae: cae.codcae, nomeCae: cae.nome};
    } else {
      this.model = cae?.length > 0 ? {...this.model} : {...this.model, codCae: '', nomeCae: ''};
    }
  }

  public diarioChanged(diario: IJsonDiario | string): void {
    if (isObject(diario)) {
      this.model = {...this.model, ndiarioContab: diario.nDiario, nomeDiario: diario.nome};
    } else {
      this.model = diario?.length > 0 ? {...this.model} : {...this.model, ndiarioContab: 0, nomeDiario: ''};
    }
  }

  public descritiChanged(descriti: IJsonDescritivo | string): void {
    if (isObject(descriti)) {
      this.model = {...this.model, ndescritiContab: descriti.nDescrit, nomeDescriti: descriti.nome};
    } else {
      this.model = descriti?.length > 0 ? {...this.model} : {...this.model, ndescritiContab: 0, nomeDescriti: ''};
    }
  }

  private _callComunicarSerieAT(docFaNum: IJsonDocfaNum): void {
    this._docfasNumsService.getSerieComunicadaAT(docFaNum.idDocfaNum).then((result) => {
      const modalInstance = this._cgModalService.showVanilla(ComunicacaoSeriesATModalComponent, {size: 'md'});
      const componentInstance: ComunicacaoSeriesATModalComponent = modalInstance.componentInstance;
      result.body.nConta = result.body.nConta === docFaNum.nConta ? result.body.nConta : docFaNum.nConta;
      componentInstance.idDocFaNum = docFaNum.idDocfaNum;
      componentInstance.model = result.body;
      componentInstance.autoFaturacao = this.model.autoFaturacao;
      modalInstance.result.then((data: IJsonDocfaNumATConsulta) => {
        if (data.sucesso) {
          this.service.series(docFaNum.nDocfa).then((response) => {
            this.model.docfanumList = [];
            for (const item of response.body) {
              this.model.docfanumList.push(item);
            }
            this._dataGridInstance.refresh();
          });
        }
      });
    });
  }

  private _checkVisibleExtraMessage(): void {
    this.isVisibleExtraMessage =
      [ESubTipoMovimentoSAFT.DocumentoGlobal, ESubTipoMovimentoSAFT.EntregaEfectivaSobreDocumentoGlobal].includes(this.model.saftSubTipoMovimento) ||
      this.model.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresComAcordo ||
      this.model.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresSemAcordo;
    if (this.isVisibleExtraMessage) {
      if (this.model.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresComAcordo || this.model.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresSemAcordo) {
        this.extraMessage = this.model.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresComAcordo ? 'docfas.autofaturacaoComAcordo' : 'docfas.autofaturacaoSemAcordo';
      } else {
        this.extraMessage = this.model.saftSubTipoMovimento === ESubTipoMovimentoSAFT.DocumentoGlobal ? 'docfas.documentoGlobal' : 'docfas.entregaEfectivaSobreDocumentoGlobal';
      }
    }
  }

  private _getDocFasNumsTableSource(): Array<IJsonDocfaNum> {
    return this.model.docfanumList;
  }

  private _getmovStockInfo(): string {
    if (!this.model.integraStocks) {
      return this._translateService.instant('docfas.messages.semMovimentoStock');
    }
    return this.model.tipoMovimentoStock === 0 ? this._translateService.instant('docfas.messages.saidaStock') : this._translateService.instant('docfas.messages.entradaStock');
  }
}
