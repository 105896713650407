<pl-form [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'arquivodigital.removepagesmodal.title'"></h4>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'arquivodigital.removepagesmodal.label'"></label>
      <edit>
        <pl-edit type="text" [(model)]="page" [properties]="{validators: {required: {value: true}}}"></pl-edit>
      </edit>
    </pl-group>

    <div class="d-flex flex-column">
      <span [translate]="'arquivodigital.removepagesmodal.help1'" class="mb-2"></span>
      <span [translate]="'arquivodigital.removepagesmodal.help2'"></span>
      <span [translate]="'arquivodigital.removepagesmodal.help3'"></span>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn-danger action-submit"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.remove'"></span></pl-button>

    <pl-button klass="btn-light action-cancel" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</pl-form>
