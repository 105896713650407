import {Component, Injector, OnInit} from '@angular/core';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonArCli} from '../../jsonArCli.entity.interface';
import {IArCliParams} from '../../arClis.entity.interface';

@Component({
  selector: 'morals-edit',
  templateUrl: './arclis.entity.edit.component.html'
})
export class ArclisEditComponent extends ModuloEntityDetailComponent<IJsonArCli> implements OnInit {
  public clifosOutput: string;
  public nContaReadonlyOnNew: boolean;

  private readonly _arCliParams: IArCliParams;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.clifosOutput = '{{nConta}} - {{nome}}';
    this._arCliParams = <IArCliParams>this._transition.params();
    this.nContaReadonlyOnNew = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.type === this.stateTypes.NEW) {
      if (this._arCliParams?.nConta !== '') {
        this.model.nConta = this._arCliParams.nConta;
        this.model.nomeConta = this._arCliParams.nomeConta;
        this.nContaReadonlyOnNew = true;
      }
    }
  }
}
