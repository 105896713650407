export const MODULE_NAME_TRDOCS = 'trdocs';

export enum EStepsNavTrDocs {
  Linhas = 'linhas',
  Identificacao = 'identificacao',
  Lotes = 'lotes',
  Resumo = 'resumo',
  Carga = 'carga',
  Descarga = 'descarga'
}
