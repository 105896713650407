import {toInteger} from 'pl-comps-angular';
import {IJsonCRABD, IJsonCRABDPerfil} from '../crabd/jsonCrAbD.entity.interface';
import {IJsonABDES, IJsonPerfilSistemaAbdes} from './jsonABDES.entity.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ETipoCategoria} from '../../datasources/tipocategoria/tipoCategoria.datasource.interface';
import {ETipoCategoriaDesc} from '../../datasources/tipocategoriadesc/tipoCategoriaDesc.datasource.interface';

export enum ETipoAbonoDesconto {
  Abono,
  Desconto
}

export enum ESiglaTipoAbonoDesconto {
  'A',
  'D'
}

export enum ETipoCategoriaABDES {
  SEGGRUPO,
  IRS,
  SegSocial,
  CGA,
  ImpostoSelo,
  Sindicato,
  Seguro,
  Falta,
  Ferias,
  Punicao,
  RetImpTerceiros,
  SubsidioRefeicao,
  Outra,
  SobretaxaExtra,
  ADSE,
  Penhora,
  FCT,
  FGCT,
  PenhoraPensaoAlimentos,
  IndemnizacaoFaltaAvisoPrevio
}

export enum EDec119EnviaImprime {
  EnviaImprime,
  NaoEnviaNaoImprime,
  EnviaNaoImprime,
  NaoEnviaImprime
}

export interface IParsedAbonoDesconto {
  kind: ETipoAbonoDesconto;
  value: string;
}

export interface ICRABDPerfil extends IJsonCRABDPerfil {
  _index?: number;
}

export interface IABDESStateParams {
  defaultABDES: IJsonABDES;
  defaultCRABD: Array<IJsonCRABD>;
}

export interface INaoImpDecIRS114 {
  imprimeDec119: boolean;
  enviaAT: boolean;
}

export interface IABDES extends IJsonABDES {
  categoriaAbono: ETipoCategoria;
  categoriaDesconto: ETipoCategoriaDesc;
}

export function fromCodAbonoDesconto(value: string): IParsedAbonoDesconto {
  const kind: ETipoAbonoDesconto = <ETipoAbonoDesconto>toInteger(value.charAt(0));
  const codAbonoDesconto: string = value.substring(1);
  return {kind: kind, value: codAbonoDesconto};
}

export function toCodAbonoDesconto(value: IParsedAbonoDesconto): string {
  return String(value.kind) + value.value;
}

export interface IABDESEntityService extends IEntityService<IJsonABDES> {
  getPerfilSistema(perfilSistema: number): TServiceResponse<IJsonPerfilSistemaAbdes>;

  listaPerfilSistemaByCategoria(abono: boolean, categoria: number): TServiceResponse<Array<IJsonPerfilSistemaAbdes>>;

  listaPerfilSistemaByTipo(abono: boolean, tipo: number): TServiceResponse<Array<IJsonPerfilSistemaAbdes>>;
}

export interface IABDESEntity extends IEntityDefinition<IJsonABDES, IABDESEntityService> {}

export const ENTITY_NAME_ABDES = 'abdes';

export const ENTITY_NAME_ABONOS = 'abonos';

export const ENTITY_NAME_DESCONTOS = 'descontos';
