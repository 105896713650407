import {TDate} from '../../../../../common/dates';

export interface IListagemDiariosModel {
  ncontade: string;
  ncontaate: string;
  periodode: string;
  periododeNome?: string;
  periodoate: string;
  periodoateNome?: string;
  ndiariode: string;
  ndiarioate: string;
  ndocinternode: string;
  ndocinternoate: string;
  datadocde: TDate;
  datadocate: TDate;
  nutilizadorde: number;
  nutilizadorate: number;
  reportName: string;
  tipoExportacao: number;
  loadAllAnos: number;
  loadOnlyAno: number;
  dePeriodoAno: string;
}

export const MODULE_NAME_PCA_LISTAGEM_DE_DIARIOS = 'listagemdiarios';
