import {Observable, Subject, Subscription} from 'rxjs';
import {Inject, Injectable, NgZone, OnDestroy, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import type {IPlRecaptchaParameters} from '../recaptcha.interface';
import type {IPlRecaptchaV3OnExecuteData, IPlRecaptchaV3OnExecuteErrorData, TActionBacklogEntry} from './recaptcha.v3.interface';
import {Logger} from '../../logger/logger';
import {PlRecaptchaService} from '../recaptcha.service';

@Injectable({
  providedIn: 'root'
})
export class PlRecaptchaV3Service implements OnDestroy {
  private readonly _isBrowser: boolean;
  private readonly _actionBacklog: Array<TActionBacklogEntry>;
  private _recaptcha: ReCaptchaV2.ReCaptcha;
  private _recaptchaParameters: IPlRecaptchaParameters;
  private _subjectOnExecute: Subject<IPlRecaptchaV3OnExecuteData>;
  private _subjectOnExecuteError: Subject<IPlRecaptchaV3OnExecuteErrorData>;
  private _observableOnExecute: Observable<IPlRecaptchaV3OnExecuteData>;
  private _observableOnExecuteError: Observable<IPlRecaptchaV3OnExecuteErrorData>;
  private _subscriptionParameters: Subscription;
  private _promiseInit: Promise<void>;

  constructor(private readonly _zone: NgZone, @Inject(PLATFORM_ID) platformId: any, private readonly _logger: Logger, private readonly _plRecaptchaService: PlRecaptchaService) {
    this._isBrowser = isPlatformBrowser(platformId);
    this._actionBacklog = [];
  }

  public ngOnDestroy(): void {
    if (this._subscriptionParameters) {
      this._subscriptionParameters.unsubscribe();
    }
  }

  public execute(action: string): Observable<string> {
    const subject: Subject<string> = new Subject<string>();
    if (this._isBrowser) {
      if (!this._recaptcha) {
        this._actionBacklog.push([action, subject]);
        this._init();
      } else {
        this._executeActionWithSubject(action, subject);
      }
    } else {
      subject.complete();
    }
    return subject.asObservable();
  }

  public get onExecute(): Observable<IPlRecaptchaV3OnExecuteData> {
    if (!this._subjectOnExecute) {
      this._subjectOnExecute = new Subject<IPlRecaptchaV3OnExecuteData>();
      this._observableOnExecute = this._subjectOnExecute.asObservable();
    }
    return this._observableOnExecute;
  }

  public get onExecuteError(): Observable<IPlRecaptchaV3OnExecuteErrorData> {
    if (!this._subjectOnExecuteError) {
      this._subjectOnExecuteError = new Subject<IPlRecaptchaV3OnExecuteErrorData>();
      this._observableOnExecuteError = this._subjectOnExecuteError.asObservable();
    }
    return this._observableOnExecuteError;
  }

  private _init(): Promise<void> {
    if (!this._promiseInit) {
      this._promiseInit = this._plRecaptchaService
        .getRecaptcha()
        .then((recaptcha: ReCaptchaV2.ReCaptcha) => {
          this._recaptcha = recaptcha;
          if (this._actionBacklog.length > 0) {
            for (const [action, subject] of this._actionBacklog) {
              this._executeActionWithSubject(action, subject);
            }
            this._actionBacklog.length = 0;
          }
        })
        .catch((reason: unknown) => {
          this._logger.error(reason);
        })
        .finally(() => {
          this._promiseInit = undefined;
        });
    }
    return this._promiseInit;
  }

  private _executeActionWithSubject(action: string, subject: Subject<string>): void {
    const onError = (error: unknown): void => {
      this._zone.run(() => {
        subject.error(error);
        if (this._subjectOnExecuteError) {
          this._subjectOnExecuteError.next({action: action, error: error});
        }
      });
    };

    if (!this._subscriptionParameters) {
      this._subscriptionParameters = this._plRecaptchaService.parameters.subscribe((parameters: IPlRecaptchaParameters) => {
        this._recaptchaParameters = parameters;
      });
    }

    this._zone.runOutsideAngular(() => {
      try {
        this._recaptcha.execute(this._recaptchaParameters?.sitekeyV3, {action: action}).then((token: string) => {
          this._zone.run(() => {
            subject.next(token);
            subject.complete();
            if (this._subjectOnExecute) {
              this._subjectOnExecute.next({action, token});
            }
          });
        }, onError);
      } catch (e: unknown) {
        onError(e);
      }
    });
  }
}
