import {firstValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import {ProviderLike} from '@uirouter/core';
import {BancosExtratoService} from './service/bancosExtrato.module.service';
import {ConfigService} from '../../services/config/config.service';
import {IJsonBancosExtratoConfigs} from './jsonBancosExtrato.module.interface';

export const RESOLVER_EMPRESA_TEM_CG_BANKING: ProviderLike = {
  provide: 'empresaTemCGBanking',
  deps: [ConfigService],
  useFactory: (configService: ConfigService): Promise<boolean> => {
    return firstValueFrom(configService.configurationsAsObservable().pipe(take(1))).then((configurations) => configurations.empresa.temCGBanking);
  }
};

export const RESOLVER_EMPRESA_CGON_CG_BANKING_EXPIRED: ProviderLike = {
  provide: 'cgOnCGBankingExpired',
  deps: [ConfigService],
  useFactory: (configService: ConfigService): Promise<boolean> => {
    return firstValueFrom(configService.configurationsAsObservable().pipe(take(1))).then((configurations) => configurations.empresa.cgon.cgBankingExpired);
  }
};

export const RESOLVER_CG_BANKING_ACESSO: ProviderLike = {
  provide: 'acessoBankingAutomation',
  deps: [ConfigService],
  useFactory: (configService: ConfigService): Promise<boolean> => {
    return firstValueFrom(configService.configurationsAsObservable().pipe(take(1))).then((configurations) => configurations.acessos.erpcloud.addons.bankingAutomation.acesso);
  }
};

export const RESOLVER_CONFIGS_BANCOS_EXTRATO: ProviderLike = {
  provide: 'configsBancosExtrato',
  deps: [BancosExtratoService],
  useFactory: (bancosExtratoService: BancosExtratoService): Promise<IJsonBancosExtratoConfigs> => {
    return firstValueFrom(bancosExtratoService.configs().pipe(take(1)));
  }
};
