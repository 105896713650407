import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ConfigService} from '../../../services/config/config.service';
import {EReport} from '../../../entities/reports/reports.interface';
import {IJsonReport} from '../../../entities/reports/jsonReport.interface';
import {
  EDebDiretosSEPAFicheiroEmitidosType,
  IDebDiretosSEPAImpressaoFilters,
  IJsonDebDiretosSEPAFicheirosEmitidosAllRecord,
  IJsonDebDiretosSEPAFicheirosEmitidosRecord,
  IJsonDebDiretosSEPAFicheirosEmitidosRecordUI
} from '../debDiretosSEPA.module.interface';
import moment from 'moment';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {DebDiretosSEPAModuleService} from '../debDiretosSEPA.module.service';
import {ReportsRegistryService} from '../../../components/reports/reports.registry.service';
import {IPlEditComponentOptionsInputAutocomplete, isDefinedNotNull, KEYCODES, PlEditAutocompleteComponent} from 'pl-comps-angular';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGridEventOnFocusedRowChanged, IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import dxDataGrid from 'devextreme/ui/data_grid';
import {IJsonBankAccount} from '../../../interfaces/jsonBankAccount.interface';

const SUBSTRACT_DAYS = 15;
const AC_SEARCH_TIMER = 400;

@Component({
  selector: 'ddsepa-impressao-modal',
  templateUrl: './debDiretosSEPA.impressao.modal.component.html'
})
export class DebDiretosSEPAImpressaoModalComponent extends CGModalComponent<void> implements OnInit {
  @ViewChild('ficheirosEmitidosAc') public readonly ficheirosEmitidosAc: PlEditAutocompleteComponent;

  public readonly propertiesAutocompleteFicheirosEmitidos: IPlEditComponentOptionsInputAutocomplete;
  public readonly rowTemplateFicheirosEmitidos: string = '{{loteFicheiro}}';
  public readonly outputFicheirosEmitidos: string = 'loteFicheiro';
  public readonly dataGridFicheirosEmitidosInstanceName: string = 'dataGridFicheirosEmitidosInstName';

  public modelFicheirosEmitidos: IJsonDebDiretosSEPAFicheirosEmitidosRecordUI;
  public dataGridFicheirosEmitidos: IDevExpressDataGrid<IJsonDebDiretosSEPAFicheirosEmitidosRecord, string>;
  public filters: IDebDiretosSEPAImpressaoFilters;
  public pdfUrl: string;
  public reportModel: IJsonReport;

  private _autocompleteSearchTimer: number;
  private _dataGridFicheirosEmitidosInstance: dxDataGrid<IJsonDebDiretosSEPAFicheirosEmitidosRecord, string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    protected readonly _debDiretosSEPAModuleService: DebDiretosSEPAModuleService,
    private readonly _config: ConfigService
  ) {
    super(_injector);
    this.filters = {
      dataLiquidacaoDe: moment().subtract(SUBSTRACT_DAYS, 'days'),
      dataLiquidacaoAte: moment()
    };

    this.propertiesAutocompleteFicheirosEmitidos = {
      showFilter: false,
      allowEmpty: false,
      disallowClear: true,
      allowInvalid: false,
      cssClassDropdownWrapper: 'ddsepa-ficheiros-emitidos-dropdown-wrapper',
      events: {
        input: () => {
          clearTimeout(this._autocompleteSearchTimer);
          this._autocompleteSearchTimer = window.setTimeout(() => {
            this._dataGridFicheirosEmitidosInstance?.refresh();
          }, AC_SEARCH_TIMER);
        }
      }
    };

    this._resetModel();
    this.dataGridFicheirosEmitidos = {
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      filterPanel: {visible: false},
      focusedRowEnabled: true,
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false},
      headerFilter: {visible: false},
      height: 400,
      paging: {
        pageSize: 10
      },
      scrolling: {rowRenderingMode: 'virtual'},
      filterRow: {visible: false},
      toolbar: {visible: false},
      columns: [
        {dataField: 'loteFicheiro', dataType: 'string', caption: 'debDiretosSEPA.fields.nLoteFicheiro'},
        {dataField: 'nDocumentos', dataType: 'number', caption: 'debDiretosSEPA.strings.nDocumentos'},
        {dataField: 'valor', dataType: 'number', caption: 'debDiretosSEPA.fields.valor'},
        {dataField: 'dataEmissao', dataType: 'date', caption: 'debDiretosSEPA.strings.dataEmissao'},
        {dataField: 'enviadoEmail', dataType: 'boolean', caption: 'debDiretosSEPA.fields.notificado'},
        {dataField: 'liquidado', dataType: 'boolean', caption: 'debDiretosSEPA.strings.liquidado'},
        {dataField: 'dataLiquidacao', dataType: 'date', caption: 'debDiretosSEPA.fields.dataLiquidacao'}
      ],
      dataSource: new DataSource({
        store: new CustomStore({
          key: 'loteFicheiro',
          loadMode: 'processed',
          load: () => {
            return this._debDiretosSEPAModuleService
              .getAllFicheirosEmitidos(this.ficheirosEmitidosAc.search, this._dataGridFicheirosEmitidosInstance.pageIndex() + 1, this._dataGridFicheirosEmitidosInstance.pageSize())
              .then((response) => {
                return response.body.list.map((item: IJsonDebDiretosSEPAFicheirosEmitidosAllRecord) => {
                  return {...item, uid: `${item.loteFicheiro}-${item.liquidado}`};
                });
              });
          }
        })
      })
    };
  }

  public ngOnInit(): void {
    this._loadDefaultReport();
  }

  public ficheirosEmitidosChanged(item: IJsonDebDiretosSEPAFicheirosEmitidosRecord): void {
    this._resetModel();
    if (isDefinedNotNull(item)) {
      this.modelFicheirosEmitidos.loteFicheiro = item.loteFicheiro;
      this.modelFicheirosEmitidos.dataEmissao = item.dataEmissao;
      this.modelFicheirosEmitidos.enviadoEmail = item.enviadoEmail;
      this.modelFicheirosEmitidos.valor = item.valor;
      this.modelFicheirosEmitidos.temDocsPorNotificar = !item.enviadoEmail;
    }
  }

  public listar(): void {
    this.pdfUrl = undefined;
    this._debDiretosSEPAModuleService
      .getListagemUrl(this.filters.dataLiquidacaoDe, this.filters.dataLiquidacaoAte, this.reportModel.name, this.modelFicheirosEmitidos.loteFicheiro)
      .subscribe((url: string) => {
        this.pdfUrl = url;
      });
  }

  public onDataGridFicheirosEmitidosInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonDebDiretosSEPAFicheirosEmitidosRecord, string>): void {
    this._dataGridFicheirosEmitidosInstance = component;
  }

  public onDataGridFicheirosEmitidosFocusedRowChanged(event: IDevExpressDataGridEventOnFocusedRowChanged<IJsonBankAccount, string>): void {
    if (event.row?.rowType === 'data') {
      this.ficheirosEmitidosAc.selectItem(event.row.data);
    }
  }

  public readonly fnKeydownDataLiquidacaoAte = (value: string, event: KeyboardEvent): void => {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      const button: HTMLElement = this._element.querySelector('button.btn-action-pesquisar');
      if (button) {
        setTimeout(() => {
          button.focus();
        });
      }
    }
  };

  private _loadDefaultReport(): Promise<void> {
    this.reportModel = {
      name: this._config.configurations.gestaoComercial.debitosDiretosSEPA.reportImpressao,
      title: this._config.configurations.gestaoComercial.debitosDiretosSEPA.reportImpressao
    };
    return this._reportsRegistryService
      .get(EReport.DDSEPA)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          let foundDefault = false;
          for (const report of reports) {
            if (report.name === this.reportModel.name) {
              this.reportModel = {name: report.name, title: report.title};
              foundDefault = true;
              break;
            }
          }
          if (!foundDefault) {
            this.reportModel = {
              name: reports[0].name,
              title: reports[0].title
            };
          }
        }
      });
  }

  private _resetModel(): void {
    this.modelFicheirosEmitidos = {
      ficheiroEmitidosType: EDebDiretosSEPAFicheiroEmitidosType.PorLiquidar,
      loteFicheiro: '',
      dataEmissao: undefined,
      enviadoEmail: false,
      valor: undefined,
      nDocumentos: undefined,
      dataLiquidacao: undefined,
      temDocsPorNotificar: false
    };
  }
}
