import {lastValueFrom, Subscription} from 'rxjs';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CGCModalService} from '../../../modal/service/modal.service';
import type {IPlImageResizerProperties} from '../../../common/imageresizer/image.resizer.interface';
import type {IPlLocale, IPlLocaleMediaDevicesCameraCaptureImage} from '../../../common/locale/locales.interface';
import type {IPlMediaDevicesCameraCaptureProperties} from './mediadevices.camera.captureimage.interface';
import {PlImageResizer} from '../../../common/imageresizer/image.resizer';
import {PlLocaleService} from '../../../common/locale/locale.service';
import {PlMediaDevicesCameraCaptureImageChangeModalComponent} from './changemodal/mediadevices.camera.captureimage.change.modal.component';

const DEFAULT_ICON = 'pl-icon-user';

@Component({
  selector: 'pl-media-devices-camera-capture-image',
  templateUrl: './mediadevices.camera.captureimage.component.html',
  standalone: false
})
export class PlMediaDevicesCameraCaptureImageComponent implements OnChanges, OnDestroy {
  @Input() public model: File;
  @Input() public disabled: boolean;
  @Input() public hideActions: boolean;
  @Input() public hideActionChange: boolean;
  @Input() public hideActionRemove: boolean;
  @Input() public icon: string;
  @Input() public properties: IPlMediaDevicesCameraCaptureProperties;
  @Output() public readonly modelChange: EventEmitter<File>;

  public locale: IPlLocaleMediaDevicesCameraCaptureImage;
  public value: File;
  public hasImage: boolean;
  public imageSafeValue: SafeUrl;

  private readonly _subscriptionLocale: Subscription;
  private _imageValue: string;

  constructor(
    private readonly _domSanitizer: DomSanitizer,
    private readonly _cgcModalService: CGCModalService,
    private readonly _plLocaleService: PlLocaleService
  ) {
    this.modelChange = new EventEmitter<File>();
    this.disabled = false;
    this.hasImage = false;
    this.icon = DEFAULT_ICON;
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.locale = locale.plMediaDevicesCameraCaptureImage;
    });
  }

  public ngOnChanges({model}: SimpleChanges): void {
    if (model && model.currentValue !== this.value) {
      this._setImageContent(model.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
  }

  public async change(): Promise<void> {
    if (!this.disabled) {
      const modalInstance: NgbModalRef = this._cgcModalService.showVanilla(PlMediaDevicesCameraCaptureImageChangeModalComponent, {size: 'lg'});
      modalInstance.componentInstance.properties = this.properties;
      const result: File = await modalInstance.result;
      let imageContent: File;
      if (result) {
        imageContent = await this._generateImage(result);
      }
      this._setImageContent(imageContent);
    }
    return Promise.resolve();
  }

  public remove(): void {
    if (this.disabled) {
      return;
    }
    if (this.hasImage) {
      this._cleanupImageContent();
      this._setModel(undefined);
      this.hasImage = false;
    }
  }

  private _setImageContent(value: File): void {
    this._cleanupImageContent();
    this.value = value;
    if (this.value) {
      this._setImageValue(URL.createObjectURL(this.value));
    }
    this.hasImage = Boolean(this._imageValue);
    this._setModel(this.value);
  }

  private _cleanupImageContent(): void {
    if (this._imageValue) {
      URL.revokeObjectURL(this._imageValue);
      this._setImageValue(undefined);
    }
  }

  private _setImageValue(value: string): void {
    this._imageValue = value;
    this.imageSafeValue = this._imageValue ? this._domSanitizer.bypassSecurityTrustUrl(this._imageValue) : undefined;
  }

  private _setModel(value: File): void {
    this.model = value;
    this.modelChange.emit(this.model);
  }

  private _generateImage(file: File): Promise<File> {
    const resizerProperties: Partial<IPlImageResizerProperties> = {
      resizeWidth: this.properties.resizeWidth,
      resizeHeight: this.properties.resizeHeight,
      resizeMimeType: this.properties.resizeMimeType,
      resizeQuality: this.properties.resizeQuality,
      resizeMethod: this.properties.resizeMethod
    };
    return lastValueFrom(PlImageResizer.resizeImageToFile(file, resizerProperties));
  }
}
