<div class="maintenance-modal maintenance-detail-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title">
      {{ title }}<span *ngIf="toolbarTitle">&nbsp;({{ toolbarTitle }})</span>
    </h5>
    <pl-toolbar [instanceId]="toolbarInstanceId"></pl-toolbar>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <ng-template #templateRef></ng-template>
  </div>
</div>
