import {IImportadorEfaturaErro} from '../importadorEfatura.module.interface';
import {TDate} from '../../../../../common/dates';

export interface IEFaturaDocumento {
  actividadeEmitente: string;
  actividadeEmitenteDesc: string;
  actividadeProf: string;
  actividadeProfDesc: string;
  comunicacaoComerciante: boolean;
  comunicacaoConsumidor: boolean;
  dataEmissaoDocumento: TDate;
  estadoBeneficio: string;
  estadoBeneficioDesc: string;
  estadoBeneficioDescEmitente: string;
  estadoBeneficioEmitente: string;
  existeTaxaNormal: string;
  hashDocumento: string;
  idDocumento: string;
  isDocumentoEstrangeiro: boolean;
  nifAdquirente: string;
  nifEmitente: string;
  nomeAdquirente: string;
  nomeEmitente: string;
  numerodocumento: string;
  origemRegisto: string;
  origemRegistoDesc: string;
  tipoDocumento: string;
  tipoDocumentoDesc: string;
  tipoDocumentoAbbr: string;
  valorTotal: number;
  valorTotalBaseTributavel: number;
  valorTotalBeneficioProv: number;
  valorTotalDespesasGerais: number;
  valorTotalIva: number;
  valorTotalSetorBeneficio: number;
  docDigitalDocID: string;
  docDigitalForderID: string;
  docDigital: number;
}

export interface IEFaturaDocumentoLinha {
  valorBaseTributavel: number;
  taxaIva: number;
  valorTotal: number;
  valorIva: number;
}

export interface IEFaturaDocumentoComLinhas extends IEFaturaDocumento {
  linhas: Array<IEFaturaDocumentoLinha>;
}

export interface IEFaturaTipoFatura {
  id: number;
  tipo: string;
}

export interface IEFaturaDocDetails {
  list: Array<IEFaturaDocumentoLinha>;
  errors: Array<IImportadorEfaturaErro>;
}

export interface IEFaturaDocDetailsCab {
  valorTotal: number;
  valorTotalBaseTributavel: number;
  valorTotalIva: number;
}

export interface IEFaturaDocDetailsWithCab {
  cab: IEFaturaDocDetailsCab;
  list: Array<IEFaturaDocumentoLinha>;
  errors: Array<IImportadorEfaturaErro>;
}

export const EFATURA_TIPO_FATURA_TODOS: IEFaturaTipoFatura = Object.freeze({id: -1, tipo: 'TODOS'});
