<div class="segur">
  <entity-detail entityName="segur" [(model)]="model" [type]="type" [maintenanceMode]="maintenanceMode" [callback]="callback" (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL, validators: {required: {value: true}}}">
        <pl-group>
          <pl-group>
            <label [translate]="'segur.fields.nsegur'"></label>
            <edit>
              <pl-edit
                type="text"
                attrName="nsegur"
                [(model)]="model.nsegur"
                [properties]="{validators: {required: {value: true}, maxlength: {value: 4}}, placeholder: 'segur.fields.nsegur'}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'segur.fields.designaBreve'"></label>
            <edit>
              <pl-edit
                type="text"
                attrName="descricao"
                [(model)]="model.designaBreve"
                [properties]="{validators: {required: {value: true}, maxlength: {value: 150}}, placeholder: 'segur.fields.designaBreve'}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="modoAvancado">
          <label [translate]="'segur.fields.nome'"></label>
          <edit>
            <pl-edit type="text" attrName="nome" [(model)]="model.nome" [properties]="{placeholder: 'segur.fields.nome'}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group *ngIf="modoAvancado">
          <pl-group>
            <label [translate]="'segur.fields.morada'"></label>
            <edit>
              <pl-edit type="text" attrName="morada" [(model)]="model.morada" [properties]="{placeholder: 'segur.fields.morada'}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'segur.fields.localidade'"></label>
            <edit>
              <pl-edit type="text" attrName="localidade" [(model)]="model.localidade" [properties]="{placeholder: 'segur.fields.localidade'}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="modoAvancado">
          <pl-group>
            <label [translate]="'segur.fields.npostal'"></label>
            <edit>
              <entity-autocomplete
                entity="codpostais"
                attrName="codpostais"
                [model]="model"
                [(selectedKey)]="model.npostal"
                [properties]="{validators: {required: {value: true}}}"
                [fieldsMap]="{codPostal: 'npostal'}"
                [outputKey]="'codPostal'">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'segur.fields.telefone'"></label>
            <edit>
              <pl-edit type="text" attrName="telefone" [(model)]="model.telefone" [properties]="{placeholder: 'segur.fields.telefone'}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'segur.fields.codSeguradoraISP'"></label>
            <edit>
              <entity-autocomplete
                entity="qpisp"
                [model]="model"
                (selectedKeyChange)="model.codSeguradoraISP = $event"
                (evtSelectedDescriptionChanged)="model.nomeCodSeguradoraISP = $event"
                [properties]="{validators: {required: {value: false}}}"
                [fieldsMap]="{codISP: 'codSeguradoraISP', nome: 'nomeCodSeguradoraISP'}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'segur.fields.napolice'"></label>
            <edit>
              <pl-edit type="text" attrName="napolice" [(model)]="model.napolice" [properties]="{placeholder: 'segur.fields.napolice', validators: {required: {value: true}, maxlength: {value: 20}}}">
                <div *inputGroup="{klass: 'hollow-form'}">
                  <pl-tooltip *ngIf="model.napolice.length < 20" [config]="tooltipNApolice">
                    <i *plTooltipContent class="fa fa-exclamation-circle text-error" aria-hidden="true"></i>
                  </pl-tooltip>
                </div>
              </pl-edit>
            </edit>
          </pl-group>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
