import {Component, Injector} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {TDate} from '../../../../../../../common/dates';
import moment from 'moment';

@Component({
  selector: 'modal-previ-tes-auto-data',
  templateUrl: './previTesAuto.dataTes.modal.component.html'
})
export class PreviTesAutoDataTesModalComponent extends CGModalComponent<TDate> {
  public dataTesouraria: TDate;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.dataTesouraria = moment();
  }

  public close(): void {
    super.close(this.dataTesouraria);
  }
}
