import {Component, Injector} from '@angular/core';
import {IPlUploadCallback, isNumber, PlI18nService, PlTranslateService} from 'pl-comps-angular';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../../../../entities/dgemps/dgemps.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../../config/constants';
import {GestaoDGEMPSMarcarAbstractModalComponent} from '../gestaodgemps.marcar.abstract.modal.component';
import {gestaoDGEMPSMarcarHoraFim, gestaoDGEMPSMarcarHoraInicio} from '../../../gestaodgemps.utilities';
import {IGestaoDGEMPSCalendario, IGestaoDGEMPSMarcarModalData} from '../../../gestaodgemps.interface';
import {IJsonDGEMP} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHEventosConfig} from '../../../../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import {IJsonPRHMarcarFaltas} from '../../../../../interfaces/jsonPRHFaltas.interface';
import moment, {Moment} from 'moment';
import {TDate} from '../../../../../../common/dates';

@Component({
  selector: 'gestao-dgemps-marcar-faltas-modal',
  templateUrl: './gestaodgemps.marcar.faltas.modal.component.html'
})
export class GestaoDGEMPSMarcarFaltasModalComponent extends GestaoDGEMPSMarcarAbstractModalComponent {
  public readonly uploadCallback: IPlUploadCallback;
  public hasTarefa: boolean;
  public tipoFalta: IJsonPRHEventosConfig;
  public motivo: string;
  public allDay: boolean;
  public horaInicio: TDate;
  public horaFim: TDate;
  public anexaFicheiro: boolean;
  public file: File;

  private readonly _serviceDGEMPS: IDGEMPSEntityService;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _plI18nService: PlI18nService,
    protected readonly _plTranslateService: PlTranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector, _plI18nService, _plTranslateService);
    this.uploadCallback = {};
    this.hasTarefa = false;
    this.allDay = true;
    this.horaInicio = gestaoDGEMPSMarcarHoraInicio();
    this.horaFim = gestaoDGEMPSMarcarHoraFim();
    this.anexaFicheiro = false;
    this._serviceDGEMPS = this._entityServiceBuilder.build<IJsonDGEMP, IDGEMPSEntityService>(ENTITY_NAME_DGEMPS);
  }

  public close(): Promise<void> {
    if (!this.tipoFalta) {
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    this.disableClose();

    const {viewDate, startDate, endDate}: IGestaoDGEMPSMarcarModalData = this.marcarData;
    const calendarios: Array<IGestaoDGEMPSCalendario> = this.marcarData.calendarios.slice();
    const file: File = this.file;

    const fnMarcarFaltas = (): Promise<void> => {
      const calendario: IGestaoDGEMPSCalendario = calendarios.shift();
      if (!calendario) {
        return Promise.resolve();
      }
      return this._marcarFalta(viewDate, startDate, endDate, calendario, file).then(fnMarcarFaltas);
    };

    return fnMarcarFaltas()
      .then(() => {
        this.enableClose();
        super.close();
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }

  public changedTipoFalta(value: number): void {
    this.tipoFalta = isNumber(value) ? this.configEvents.find((configEvent: IJsonPRHEventosConfig) => configEvent.tipo === value) : undefined;
  }

  private async _marcarFalta(viewDate: Moment, startDate: Moment, endDate: Moment, calendario: IGestaoDGEMPSCalendario, file: File): Promise<void> {
    await this._marcarFaltaTarefa(viewDate, startDate, endDate, calendario, file);
    if (!this.hasTarefa) {
      this.hasTarefa = true;
    }
    for (let i = this.marcarData.calendarios.length - 1; i >= 0; i--) {
      if (this.marcarData.calendarios[i].codEmp === calendario.codEmp) {
        this.marcarData.calendarios.splice(i, 1);
      }
    }
  }

  private _marcarFaltaTarefa(viewDate: Moment, startDate: Moment, endDate: Moment, calendario: IGestaoDGEMPSCalendario, file: File): Promise<unknown> {
    const faltas: IJsonPRHMarcarFaltas = {
      cab: undefined,
      linhas: [],
      motivo: this.motivo,
      codEmpLanca: this.codEmpLanca,
      codServico: this.codServico,
      proximoPapel: -1
    };

    for (const dataAtual = startDate.clone(); dataAtual.diff(endDate, 'days') <= 0; dataAtual.add(1, 'day')) {
      const horaInicio: Moment = !this.allDay ? moment(this.horaInicio) : dataAtual.clone().startOf('day');
      const horaFim: Moment = !this.allDay ? moment(this.horaFim) : dataAtual.clone().endOf('day');

      const dataInicio: Moment = viewDate.clone().set({
        hours: horaInicio.hours(),
        minutes: horaInicio.minutes(),
        seconds: horaInicio.seconds(),
        milliseconds: horaInicio.milliseconds()
      });
      const dataFim: Moment = viewDate.clone().set({
        hours: horaFim.hours(),
        minutes: horaFim.minutes(),
        seconds: horaFim.seconds(),
        milliseconds: horaFim.milliseconds()
      });

      faltas.linhas.push({
        idTarefaCab: undefined,
        posicao: undefined,
        idFaltas: undefined,
        data: dataAtual.clone(),
        horaInicio: dataInicio,
        horaFim: dataFim,
        tipoProcessamento: this.tipoFalta.tipoProcessamento,
        ncodAbdes: this.tipoFalta.ncodAbdes,
        tipoFalta: this.tipoFalta.tipo,
        ocupacaoId: undefined
      });
    }

    return this._serviceDGEMPS.marcarFaltas(calendario.codEmp, faltas, file).then(() => {
      this._successfulCalendarios.add(calendario);
    });
  }
}
