import {Component, Injector, Input, OnInit} from '@angular/core';
import {isDefinedNotNull, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {TranslateService} from '@ngx-translate/core';
import {EMapaPagamEntity, IJsonMapaPagamATLoginModel} from '../../mapaPagamentos.module.interface';
import {MapaPagamentosService} from '../../mapaPagamentos.module.service';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EMPTY_PASSWORD} from '../../../../../../config/constants';
import {TServiceResponse} from '../../../../../services/api/api.service.interface';

@Component({
  selector: 'mapa-pagamentos-login-modal',
  templateUrl: './mapaPagamentos.login.modal.component.html'
})
export class MapaPagamentosLoginModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public entity: EMapaPagamEntity;
  @Input() public nEmpresa: string;
  public model: IJsonMapaPagamATLoginModel;
  public userNameLabel: string;
  public logoImageSource: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public promise: TServiceResponse<any>;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _mapaPagamentosService: MapaPagamentosService,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.model = {username: '', password: ''};
  }

  public ngOnInit(): void {
    if (isUndefinedOrNull(this.entity)) {
      this.entity = EMapaPagamEntity.AT;
    }
    switch (this.entity) {
      case EMapaPagamEntity.AT:
        this.logoImageSource = '../../../assets/images/logo-at.webp';
        this.userNameLabel = 'simpleLogin.at.userNameFieldLabel';
        break;
      case EMapaPagamEntity.SSD:
        this.logoImageSource = '../../../assets/images/logo-ssd.svg';
        this.userNameLabel = 'simpleLogin.ssd.userNameFieldLabel';
        break;
    }

    this.promise = this._mapaPagamentosService.getEntityCredentials(this.entity, this.nEmpresa);

    this.promise.then((response) => {
      if (isDefinedNotNull(response)) {
        this.model = {...(<IJsonMapaPagamATLoginModel>response.body)};
      }
    });
  }

  public close(): Promise<void> {
    if (this.isSaveBtnDisabled()) {
      return Promise.reject(new Error(this._translateService.instant('simpleLogin.messages.credentialsEmpty')));
    }
    this.promise = this._mapaPagamentosService.saveEntityCredentials(this.entity, this.nEmpresa, this.model);
    return this.promise.then((response) => {
      if (response.body.sucesso) {
        super.close();
      } else {
        this._plAlertService.error(response.body.erro);
      }
      return undefined;
    });
  }

  public isSaveBtnDisabled(): boolean {
    return this.model.password === EMPTY_PASSWORD || this.model.password === '' || isUndefinedOrNull(this.model.password) || this.model.username === '' || isUndefinedOrNull(this.model.username);
  }
}
