import {Component, Injector, OnInit} from '@angular/core';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IJsonPortal} from '../../jsonPortal.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {PortalsSortModalComponent} from '../../modals/sort/portals.sort.modal.component';

@Component({
  selector: 'portal-entity-list',
  templateUrl: './portal.entity.list.component.html'
})
export class PortalEntityListComponent extends ModuloEntityListComponent<IJsonPortal> implements OnInit {
  constructor(
    protected readonly _injector: Injector,
    private readonly _modalService: CGModalService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setModuleActions([
      {
        caption: 'portals.actions.sort',
        action: () => this._sortPortals()
      }
    ]);
  }

  private async _sortPortals(): Promise<void> {
    await this._modalService.show(PortalsSortModalComponent, {size: 'md'});
    await this.refreshList();
  }
}
