import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonGrandeFamilia} from './jsonGrandeFamilia.entity.interface';
import {ENTITY_NAME_GRANDES_FAMILIAS} from './grandesFamilias.entity.interface';

export const ENTITY_GRANDES_FAMILIAS: IEntityDefinition<IJsonGrandeFamilia> = {
  name: ENTITY_NAME_GRANDES_FAMILIAS,
  roles: [ROLE.ERP, ROLE.ATIVOS],
  searchPlaceholder: 'grandesfamilias.pesquisa',
  metadata: {
    keyName: 'nDepart',
    fields: [
      {
        name: 'nDepart',
        type: 'cginteger',
        width: '120px',
        caption: 'grandesfamilias.fields.nDepart',
        placeholder: 'grandesfamilias.fields.nDepartPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'nome',
        caption: 'grandesfamilias.fields.nome',
        placeholder: 'grandesfamilias.fields.nomePlaceholder',
        validators: {required: true, maxlength: 50}
      }
    ],
    order: 'nome',
    searchFields: 'nDepart,nome'
  },
  autocomplete: {
    rowTemplate: '{{nDepart}} - {{nome}}',
    output: 'nome',
    searchFields: 'nDepart,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.grandesfamilias'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.grandesfamilias'
      }
    }
  }
};
