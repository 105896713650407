<div class="modal-body">
  <span [translate]="'documentosFiscais.progress.message'" class="mb-5"></span>
  <div class="progress" style="height: 6px">
    <div
      class="progress-bar bg-info progress-bar-striped progress-bar-animated"
      style="width: 100%; height: 10px"
      role="progressbar"
      [attr.aria-valuenow]="100"
      [attr.aria-valuemin]="0"
      [attr.aria-valuemax]="100"></div>
  </div>
</div>
