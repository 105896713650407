import {sortBy} from 'lodash-es';
import {Component, EventEmitter, Injector, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {isArray, isNumber, ngClassAdd} from 'pl-comps-angular';
import {DELPHI_DEFAULT_DATE_YEAR} from '../../../../../config/constants';
import {EGestaoDGEMPSType, EGestaoDGEMPSView, IGestaoDGEMPSCalendario, IGestaoDGEMPSEvent} from '../../gestaodgemps.interface';
import {evaluateDGEMPName, IJsonDGEMPMarcacoes} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {gestaoDGEMPSDefaultViewDate, gestaoDGEMPSEvaluateDayCSSClass, gestaoDGEMPSPrettyFeriasMarcadas, gestaoDGEMPSTypeToPRHFluxo} from '../../gestaodgemps.utilities';
import {GestaoDGEMPSViewComponent} from '../gestaodgemps.view.component';
import {
  IGestaoDGEMPSMonthViewCalendario,
  IGestaoDGEMPSMonthViewDataset,
  IGestaoDGEMPSMonthViewDay,
  IGestaoDGEMPSMonthViewDayClick,
  IGestaoDGEMPSMonthViewEvent,
  IGestaoDGEMPSMonthViewRange,
  IGestaoDGEMPSMonthViewRangeSelect,
  IGestaoDGEMPSMonthViewScheduler,
  IGestaoDGEMPSMonthViewSelectionChanged
} from './gestaodgemps.month.view.component.interface';
import {IGestaoDGEMPSViewDayEvent, IGestaoDGEMPSViewFetchMarcacoes, IGestaoDGEMPSViewRefreshMarcacoes} from '../gestaodgemps.view.component.interface';
import {IJsonPRHServicoDatasMarcacoesPendentes, IJsonPRHServicoMarcacoes} from '../../../../entities/prhservicos/jsonPRHServico.entity.interface';
import moment, {Moment, MomentInput} from 'moment';

const CALENDARIO_BASE: IGestaoDGEMPSMonthViewCalendario = {
  codEmp: 0,
  nomeEmp: '',
  nomeCompleto: '',
  listaCalendario: [],
  listaMarcadas: [],
  listaIntegradas: [],
  selected: true,
  labelCompleta: ''
};

@Component({
  selector: 'gestao-dgemps-month-view',
  templateUrl: './gestaodgemps.month.view.component.html'
})
export class GestaoDGEMPSMonthViewComponent extends GestaoDGEMPSViewComponent implements OnInit, OnChanges {
  @Output() public readonly evtChangedPendingPrevious: EventEmitter<Moment>;
  @Output() public readonly evtChangedPendingNext: EventEmitter<Moment>;
  @Output() public readonly evtChangedDisabledPendingPrevious: EventEmitter<boolean>;
  @Output() public readonly evtChangedDisabledPendingNext: EventEmitter<boolean>;

  public readonly monthDaysWithAttachment: WeakSet<IGestaoDGEMPSMonthViewDay>;
  public datePrevious: Moment;
  public dateNext: Moment;
  public datasets: Array<IGestaoDGEMPSMonthViewDataset>;
  public monthSelectedRange: IGestaoDGEMPSMonthViewRange;

  private readonly _datasetByCodEmp: Map<number, IGestaoDGEMPSMonthViewDataset>;
  private _previousViewDate: Moment;
  private _selectedCalendario: IGestaoDGEMPSMonthViewCalendario;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.evtChangedPendingPrevious = new EventEmitter<Moment>();
    this.evtChangedPendingNext = new EventEmitter<Moment>();
    this.evtChangedDisabledPendingPrevious = new EventEmitter<boolean>();
    this.evtChangedDisabledPendingNext = new EventEmitter<boolean>();
    this.viewDate = gestaoDGEMPSDefaultViewDate();
    this.monthDaysWithAttachment = new WeakSet<IGestaoDGEMPSMonthViewDay>();
    this.datePrevious = this.viewDate.clone();
    this.dateNext = this.viewDate.clone();
    this.datasets = [];
    this._datasetByCodEmp = new Map<number, IGestaoDGEMPSMonthViewDataset>();
    this._postMarcarTarefaFn = this._postMarcarTarefa.bind(this);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.evtChangedDisabledPendingPrevious.emit(true);
    this.evtChangedDisabledPendingNext.emit(true);
    if (isNumber(this.type) && moment.isMoment(this.viewDate) && this.servico) {
      this._previousViewDate = this.viewDate.clone();
      this._evaluateCalendarios();
    }
  }

  public override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {type, viewDate, servico}: SimpleChanges = changes;
    const changedType: boolean = type && !type.isFirstChange();
    const changedServico: boolean = servico && !servico.isFirstChange();
    let changedViewDate = false;
    if (viewDate && !viewDate.isFirstChange()) {
      const newViewDate = moment(viewDate.currentValue);
      if (!this._previousViewDate || !newViewDate.isSame(this._previousViewDate, 'year')) {
        changedViewDate = true;
      }
      if (!changedViewDate && (!this._previousViewDate || !newViewDate.isSame(this._previousViewDate, 'month'))) {
        this.hideEventsDetail();
        this._refreshDatasPendentes();
      }
      this._previousViewDate = newViewDate.clone();
    }
    if (changedViewDate) {
      this._changedViewDate(viewDate.currentValue);
      this._evaluateCalendarios();
    } else if (changedType || changedServico) {
      this._evaluateCalendarios();
    }
  }

  public override setViewDate(value: MomentInput): void {
    if (!moment(value).isSame(this.viewDate, 'month')) {
      super.setViewDate(value);
    }
  }

  public beforeViewRender(event: IGestaoDGEMPSMonthViewScheduler): void {
    const isFaltas: boolean = this.type === EGestaoDGEMPSType.Faltas;
    for (const colaborador of event.datasets) {
      for (const day of colaborador.items) {
        if (!day.meta?.events.length) {
          continue;
        }
        const cssClass = gestaoDGEMPSEvaluateDayCSSClass(day.meta.events, (dayEvent: IGestaoDGEMPSViewDayEvent) => {
          if (isFaltas && dayEvent.meta.docID) {
            this.monthDaysWithAttachment.add(day);
          }
        });
        if (cssClass) {
          if (!day.cssClass) {
            day.cssClass = [];
          }
          day.cssClass = ngClassAdd(day.cssClass, cssClass);
        }
      }
    }
  }

  public onMonthDayClick(day: IGestaoDGEMPSMonthViewDayClick): void {
    const calendario: IGestaoDGEMPSMonthViewCalendario = day.dataset.meta;
    if (!calendario || (day.holiday && this.type === EGestaoDGEMPSType.Ferias)) {
      this._clearSelectedCalendario();
      return;
    }
    this._setSelectedCalendario(day.dataset.meta);
    if (!day.events.length) {
      const momentDate: Moment = moment(day.date);
      this._marcarTarefa({
        startDate: momentDate,
        endDate: momentDate,
        events: []
      });
    } else if (this.colaboradorMarcarFerias) {
      const event: IGestaoDGEMPSEvent = day.events[0].meta;
      if (event.allDay && event.status.desmarcavel) {
        day.preventDetail();
        this._desmarcarTarefa(event).then(() => {
          this._refreshFeriasColaboradores([event.codEmp]);
        });
      }
    }
  }

  public onMonthRangeSelect({dataset, start, end, events}: IGestaoDGEMPSMonthViewRangeSelect): void {
    if (this.colaboradorMarcarFerias && start.isBefore(this._today, 'date')) {
      return;
    }
    this._setSelectedCalendario(dataset.meta);
    this.onRangeSelect({
      startDate: start,
      endDate: end,
      events: this._getEvents(events)
    });
  }

  public onMonthSelectionChanged({start, end, events}: IGestaoDGEMPSMonthViewSelectionChanged): void {
    this.onSelectionChanged({
      startDate: start,
      endDate: end,
      events: this._getEvents(events)
    });
  }

  public onMonthDetailRefresh(): void {
    if (!this.monthSelectedRange) {
      return;
    }
    if (this.type === EGestaoDGEMPSType.Ferias && this.manager && this._selectedCalendario) {
      this._refreshFeriasColaboradores([this._selectedCalendario.codEmp]);
    }
    this.onDetailRefresh({startDate: this.monthSelectedRange.start, endDate: this.monthSelectedRange.end}).then(() => {
      const updatedEvents: Map<number, IGestaoDGEMPSEvent> = new Map<number, IGestaoDGEMPSEvent>();
      for (const dataset of this.datasets) {
        for (const event of dataset.events) {
          updatedEvents.set(event.meta.idTarefaCab, event.meta);
        }
      }
      this.detailEvents = this.detailEvents.map((event: IGestaoDGEMPSEvent) => {
        return updatedEvents.get(event.idTarefaCab) || event;
      });
    });
  }

  public override get view(): EGestaoDGEMPSView {
    return EGestaoDGEMPSView.Month;
  }

  protected override _getActiveCalendarios(): Array<IGestaoDGEMPSMonthViewCalendario> {
    return [this._selectedCalendario ? this._selectedCalendario : CALENDARIO_BASE];
  }

  protected override _fetchMarcacoes({startDate, endDate, codServico}: IGestaoDGEMPSViewFetchMarcacoes): Promise<IJsonPRHServicoMarcacoes> {
    return this._gestaoDGEMPSService.getListaMarcacoesMes(this.type, codServico, startDate, endDate, this.viewDate);
  }

  protected override _refreshMarcacoes({startDate, endDate, marcacoes}: IGestaoDGEMPSViewRefreshMarcacoes<IJsonPRHServicoMarcacoes>): void {
    // Remove previous events
    for (const dataset of this.datasets) {
      dataset.events = dataset.events.filter((event: IGestaoDGEMPSMonthViewEvent) => !event.meta.date.isBetween(startDate, endDate, 'date', '[]'));
    }
    this._setDatasPendentes(marcacoes);
    if (marcacoes.listaEmp.length > 0) {
      for (const colaborador of marcacoes.listaEmp) {
        let calendario: IGestaoDGEMPSMonthViewCalendario;
        let dataset: IGestaoDGEMPSMonthViewDataset = this._datasetByCodEmp.get(colaborador.dgEmp.codEmp);
        if (!dataset) {
          calendario = {
            codEmp: colaborador.dgEmp.codEmp,
            nomeEmp: '',
            nomeCompleto: '',
            listaMarcadas: [],
            listaIntegradas: [],
            listaCalendario: [],
            selected: false,
            labelCompleta: undefined
          };
          dataset = {
            label: '',
            highlight: false,
            events: [],
            meta: calendario
          };
          this._evaluateDataset(dataset, colaborador);
          this._datasetByCodEmp.set(calendario.codEmp, dataset);
        } else {
          calendario = dataset.meta;
        }

        switch (this.type) {
          case EGestaoDGEMPSType.Abonos:
            calendario.listaMarcadas = colaborador.listaAbonosMarcadas;
            calendario.listaIntegradas = colaborador.listaAbonosJaIntegradas;
            dataset.highlight = colaborador.numAbonosPendentes > 0;
            break;
          case EGestaoDGEMPSType.Faltas:
            calendario.listaMarcadas = colaborador.listaFaltasMarcadas;
            calendario.listaIntegradas = colaborador.listaFaltasJaIntegradas;
            dataset.highlight = colaborador.numFaltasPendentes > 0;
            break;
          case EGestaoDGEMPSType.Ferias:
            calendario.listaMarcadas = colaborador.listaFeriasMarcadas;
            calendario.listaIntegradas = colaborador.listaFeriasJaIntegradas;
            break;
        }

        this._addCalendarioEvents(calendario);

        const datasetIndex: number = this.datasets.findIndex((currentDataset: IGestaoDGEMPSMonthViewDataset) => currentDataset.meta.codEmp === dataset.meta.codEmp);
        if (datasetIndex === -1) {
          this.datasets.push(dataset);
        } else {
          this.datasets[datasetIndex] = dataset;
        }
      }
      this.datasets = sortBy(this.datasets, (dataset: IGestaoDGEMPSMonthViewDataset) => dataset.meta.nomeCompleto);
    }

    if (isArray(this.detailEvents) && this.detailEvents.length) {
      const eventsIds: Array<number> = [];
      const eventsCodsIntegracao: Array<string | number> = [];
      for (const dataset of this.datasets) {
        for (const event of dataset.events) {
          if (event.meta.codEvento) {
            eventsIds.push(event.meta.codEvento);
          } else if (event.meta.codIntegracao) {
            eventsCodsIntegracao.push(event.meta.codIntegracao);
          }
        }
      }
      this.detailEvents = isArray(this.detailEvents)
        ? this.detailEvents.filter((event: IGestaoDGEMPSEvent) => eventsIds.includes(event.codEvento) || eventsCodsIntegracao.includes(event.codIntegracao))
        : undefined;
    }
  }

  protected override _addCalendarioEvent(calendario: IGestaoDGEMPSMonthViewCalendario, event: IGestaoDGEMPSEvent): void {
    const dataset: IGestaoDGEMPSMonthViewDataset = this._datasetByCodEmp.get(calendario.codEmp);
    dataset.events.push({
      date: event.date.clone(),
      meta: event
    });
  }

  private _evaluateCalendarios(): void {
    this.datasets = [];
    this._empregadosByDate.clear();
    this._datasetByCodEmp.clear();
    this._clearSelectedCalendario();
    this.hideEventsDetail();
    this._refreshCalendarios();
  }

  private _evaluateDataset(dataset: IGestaoDGEMPSMonthViewDataset, marcacoes: IJsonDGEMPMarcacoes): void {
    const colaborador = marcacoes.dgEmp;
    const calendario: IGestaoDGEMPSMonthViewCalendario = dataset.meta;
    calendario.nomeEmp = evaluateDGEMPName(colaborador);
    calendario.nomeCompleto = colaborador.nome;
    calendario.labelCompleta = calendario.nomeCompleto;
    dataset.label = calendario.nomeEmp;
    if (this.type === EGestaoDGEMPSType.Ferias) {
      const feriasMarcadas: string = gestaoDGEMPSPrettyFeriasMarcadas(this._translateService, colaborador);
      calendario.labelCompleta = `${calendario.labelCompleta} (${feriasMarcadas})`;
      dataset.label = `${dataset.label} (${feriasMarcadas})`;
      dataset.highlight = marcacoes.numFeriasPendentes > 0;
    }
  }

  private _getEvents(monthEvents: Array<IGestaoDGEMPSMonthViewEvent>): Array<IGestaoDGEMPSEvent> {
    return monthEvents.map((monthEvent: IGestaoDGEMPSMonthViewEvent) => monthEvent.meta);
  }

  private _setSelectedCalendario(calendario: IGestaoDGEMPSMonthViewCalendario): void {
    this._clearSelectedCalendario();
    calendario.selected = true;
    this._selectedCalendario = calendario;
  }

  private _clearSelectedCalendario(): void {
    if (this._selectedCalendario) {
      this._selectedCalendario.selected = false;
      this._selectedCalendario = undefined;
    }
  }

  private _refreshDatasPendentes(): void {
    this._addLoadingPromise(
      this._gestaoDGEMPSService
        .getDatasMarcacoesPendentes(gestaoDGEMPSTypeToPRHFluxo(this.type), this.servico.codServico, this.viewDate)
        .then((datasPendentes: IJsonPRHServicoDatasMarcacoesPendentes) => {
          this._setDatasPendentes(datasPendentes);
        })
    );
  }

  private _setDatasPendentes(datasPendentes: IJsonPRHServicoDatasMarcacoesPendentes): void {
    this.datePrevious = moment(datasPendentes.anteriorPendente);
    this.dateNext = moment(datasPendentes.proximoPendente);
    this.evtChangedPendingPrevious.next(this.datePrevious);
    this.evtChangedPendingNext.next(this.dateNext);
    this.evtChangedDisabledPendingPrevious.emit(this.datePrevious.year() === DELPHI_DEFAULT_DATE_YEAR || this.viewDate.isSame(this.datePrevious, 'month'));
    this.evtChangedDisabledPendingNext.emit(this.dateNext.year() === DELPHI_DEFAULT_DATE_YEAR || this.viewDate.isSame(this.dateNext, 'month'));
  }

  private _refreshFeriasColaboradores(codEmps: Array<number>): void {
    Promise.all(
      codEmps.map<Promise<[IGestaoDGEMPSMonthViewDataset, IJsonDGEMPMarcacoes]>>((codEmp: number) => {
        const dataset: IGestaoDGEMPSMonthViewDataset = this._datasetByCodEmp.get(codEmp);
        if (!dataset) {
          return undefined;
        }
        return this._gestaoDGEMPSService
          .getListaMarcacoesAno(codEmp, this._startOfYear.clone(), this._endOfYear.clone())
          .then((marcacoes: IJsonDGEMPMarcacoes) => {
            return [dataset, marcacoes];
          })
          .catch(() => undefined);
      })
    ).then((resolvedValues: Array<[IGestaoDGEMPSMonthViewDataset, IJsonDGEMPMarcacoes]>) => {
      for (const resolvedValue of resolvedValues) {
        if (!resolvedValue) {
          continue;
        }
        const [dataset, marcacoes] = resolvedValue;
        this._evaluateDataset(dataset, marcacoes);
      }
      this.datasets = this.datasets.slice();
    });
  }

  private _postMarcarTarefa(successfulCalendarios: ReadonlySet<IGestaoDGEMPSCalendario>): void {
    const successfulCodEmps: Array<number> = Array.from(successfulCalendarios.values()).map((calendario: IGestaoDGEMPSCalendario) => calendario.codEmp);
    this._refreshFeriasColaboradores(successfulCodEmps);
  }
}
