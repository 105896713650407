<div class="gestao-comunicacoes entity-detail-form" [ngClass]="{loading: promiseLoading}" [plPromise]="promiseLoading">
  <!--  empresa selector widget-->
  <div class="gestao-comunicacoes-widget">
    <div class="gestao-comunicacoes-widget-header p-2">
      <span class="gestao-comunicacoes-widget-header-title" [translate]="'gestaoComunicacoes.messageSidebarSelector.empresas'"></span>
      <pl-edit
        type="text"
        class="gestao-comunicacoes-widget-header-input"
        [model]="filterEmpresas"
        (modelChange)="filterEmpresasChanged($event)"
        [properties]="{placeholder: 'gestaoComunicacoes.common.filtrarPorPalavraChave', modelOptions: {debounce: 500}, disallowClear: true}"></pl-edit>
    </div>
    <div *ngFor="let empresa of topicoEmpresas; let lastMessage = last">
      <cg-message-empresa
        [contabilistaView]="true"
        [empresa]="empresa"
        [topico]="undefined"
        [lastMessage]="lastMessage"
        [itemSelected]="empresaSelected === empresa.nEmpresa"
        (evtChangedEmpresa)="handleEmpresaChange($event)"></cg-message-empresa>
    </div>
    <div *ngIf="topicoEmpresas.length < 1">
      <div class="gestao-comunicacoes-widget-no-results text-center">
        <span [translate]="'global.text.searchNoData'"></span>
      </div>
    </div>
  </div>

  <ng-container [ngSwitch]="!!empresaSelected">
    <ng-container *ngSwitchCase="true">
      <div class="gestao-comunicacoes-widget full-width">
        <div class="gestao-comunicacoes-widget-header">
          <div class="gestao-comunicacoes-widget-header-left gap-3 align-items-center">
            <span class="gestao-comunicacoes-widget-header-icon" [ngClass]="{active: vistaKanban}" (click)="changeVistaKanban(true)"><i class="fa fa-th-list" aria-hidden="true"></i> </span>
            <span class="gestao-comunicacoes-widget-header-icon" [ngClass]="{active: !vistaKanban}" (click)="changeVistaKanban(false)"><i class="fa fa-bars" aria-hidden="true"></i></span>
            <span class="gestao-comunicacoes-widget-header-title">{{ empresaSelectedDados?.nempresa }} - {{ empresaSelectedDados?.nome }}</span>
          </div>
          <div class="d-flex align-items-center gap-3">
            <div (click)="onClickEmpresaEstadoConfig()" class="gestao-comunicacoes-widget-header-icon">
              <i class="fa fa-cog d-flex align-items-center" aria-hidden="true"></i>
            </div>
            <pl-edit
              type="text"
              class="gestao-comunicacoes-widget-header-input"
              [model]="filterTopicos"
              (modelChange)="filterTopicosChanged($event)"
              [properties]="{placeholder: 'gestaoComunicacoes.common.filtrarPorPalavraChave', modelOptions: {debounce: 500}, disallowClear: true}"></pl-edit>
          </div>
        </div>

        <!--    right side widgets-->
        <ng-container [ngSwitch]="vistaKanban">
          <ng-container *ngSwitchCase="true">
            <ng-container *ngTemplateOutlet="templateKanbanView"></ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="false">
            <ng-container *ngTemplateOutlet="templateDatagridView"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="cg-logo-default-view">
        <img alt="cg-logo" class="cg-logo" [src]="'../../../assets/images/logo-compact-cloud.webp'" />
        <h5 class="mb-1 cg-logo-title" [translate]="'gestaoComunicacoes.common.selectEmpresa'"></h5>
        <h6 [translate]="'gestaoComunicacoes.common.nothingSelected'"></h6>
      </div>
    </ng-container>
  </ng-container>
</div>

<!--  kanban widget-->
<ng-template #templateKanbanView>
  <div class="kanban" *ngIf="vistaKanban">
    <!--    [scrollByThumb]="true" [scrollByContent]="true"-->
    <dx-scroll-view class="scrollable-board" [direction]="'horizontal'" [showScrollbar]="'always'">
      <dx-sortable class="sortable-lists" itemOrientation="horizontal" handle=".list-header" (onReorder)="onListReorder($event)" (onDragStart)="onDragStart($event)">
        <div class="sortable-list mx-3 my-2" *ngFor="let estado of empresaEstadosTopicos.estados">
          <div class="list-header p-2 d-flex justify-content-between align-items-center" [ngStyle]="{'background-color': estado.cor}">
            <span class="list-header-title">{{ estado.nome }}</span>
            <div class="list-header-add-container" (click)="onClickAddTopico(estado, false)" *ngIf="estado.tipo !== eTipoEstados.SistemaFinal">
              <i class="fa fa-plus" aria-hidden="true"></i>
            </div>
          </div>
          <dx-scroll-view class="scrollable-list" direction="vertical" showScrollbar="always">
            <dx-sortable
              class="sortable-cards"
              group="cardsGroup"
              [data]="estado.topicos"
              (onDragStart)="onTaskDragStart($event)"
              (onReorder)="onReorderTaskDrop($event, estado)"
              (onAdd)="onTaskDrop($event, estado)">
              <div class="sortable-card" *ngFor="let topico of estado.topicos">
                <div (click)="onClickTopico(topico, estado)">
                  <cg-sortable-card
                    [topicoId]="topico.comTopicoId"
                    [categoria]="topico.categoriaNome"
                    [categoriaCor]="topico.categoriaCor"
                    [name]="topico.assunto"
                    [subTitle]="topico.ultimaMensagem ? topico.ultimaMensagem : ('gestaoComunicacoes.common.semMensagens' | translate)"
                    [author]="topico._utilizadorShow"
                    [date]="topico.stampUltimaMensagem"
                    [numberUnreadMessages]="topico.hasUnreadMessages"
                    (evtOpenId)="openEditTopico($event, true)"
                    (evtDeleteId)="deleteTopico($event, true)"></cg-sortable-card>
                </div>
              </div>
              <div class="d-flex justify-content-center" *ngIf="estado._carregarMais">
                <a class="btn btn-primary btn-carregar-mais" (click)="onClickCarregarMais(estado)"> <span [translate]="'gestaoComunicacoes.common.carregarMais'"></span></a>
              </div>
            </dx-sortable>
          </dx-scroll-view>
        </div>
      </dx-sortable>
    </dx-scroll-view>
  </div>
</ng-template>

<!--datagrid widget-->
<ng-template #templateDatagridView>
  <dx-data-grid
    [cgDxDataGrid]="dataGridListView"
    cgDxDataGridInstanceName="gestaoComunicacoesDatagridView"
    [dataSource]="dataGridListViewSource"
    (onInitialized)="onInitializedDatagrid($event)"
    (onCellClick)="onCellClickDatagrid($event)"
    (onCellPrepared)="onCellPreparedDatagrid($event)">
    <div *dxTemplate="let item of 'cellTemplateHasUnreadMessages'">
      <div class="d-flex">
        <div class="sortable-card-header-message-icon">
          <i class="fa fa-commenting-o" aria-hidden="true"></i>
          <div *ngIf="item.data.hasUnreadMessages > 0" class="unread-dot datagrid-view"></div>
        </div>
      </div>
    </div>

    <div *dxTemplate="let item of 'cellTemplateCategoria'">
      <div class="sortable-card-header-category" [ngStyle]="{'background-color': item.data._categoryBackgroundColor, color: item.data.categoriaCor}">{{ item.data.categoriaNome }}</div>
    </div>

    <div *dxTemplate="let item of 'cellTemplateActions'">
      <div ngbDropdown container="body" class="col-main-table-actions">
        <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-ellipsis-v"></i></button>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" [translate]="'gestaoComunicacoes.modal.topico.editMessage'" (click)="openEditTopico(item.data.comTopicoId, false)"></button>
          <button type="button" class="dropdown-item" [translate]="'gestaoComunicacoes.modal.topico.deleteMessage'" (click)="deleteTopico(item.data.comTopicoId, false)"></button>
        </div>
      </div>
    </div>
  </dx-data-grid>
</ng-template>
