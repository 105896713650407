<dx-data-grid
  [cgDxDataGrid]="definition"
  cgDxDataGridInstanceName="dadosAAlterar"
  [dataSource]="definition.dataSource"
  (onInitialized)="onDataGridInitializedDadosAAlterar($event)"
  (onCellClick)="onDataGridCellClickDadosAAlterar($event)">
  <div *dxTemplate="let item of 'detail'; let itemIndex = index">
    <dx-data-grid [cgDxDataGrid]="definitionMasterDetail" [dataSource]="item.data.lista">
      <div *dxTemplate="let itemAction of 'actions'" class="text-center">
        <div class="btn-group">
          <button type="button" class="btn btn-xs btn-success" [click]="fnAprovar(item.data, itemAction.data)" plPromise>
            <i class="fa fa-fw fa-check"></i>
          </button>
          <button type="button" class="btn btn-xs btn-danger" [click]="fnRejeitar(item.data, itemAction.data)" plPromise>
            <i class="fa fa-fw fa-ban"></i>
          </button>
        </div>
      </div>
    </dx-data-grid>
  </div>
</dx-data-grid>
