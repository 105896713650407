<div class="saft-contab-progressbar-modal">
  <div class="modal-header">
    <h5 class="modal-title" [translate]="'saftcontab.messages.jobTimeoutModalTitle'"></h5>
  </div>

  <div class="modal-body">
    <div class="proc-container">
      <div class="progress">
        <div class="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" [attr.aria-valuemin]="0" [attr.aria-valuemax]="100" style="width: 100%"></div>
      </div>
      <div class="procLabel" [translate]="pbProcLabel"></div>
    </div>
  </div>
</div>
