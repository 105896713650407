import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_CALCULO_RENDIMENTO_LIQUIDO} from './rhCalculoRendimentoLiquido.module.interface';
import {RhCalculoRendimentoLiquidoModuleComponent} from './components/rhCalculoRendimentoLiquido.module.component';

export const MODULE_RH_CALCULO_RENDIMENTO_LIQUIDO: IModuleDefinition = {
  name: MODULE_NAME_RH_CALCULO_RENDIMENTO_LIQUIDO,
  state: {
    url: `/${MODULE_NAME_RH_CALCULO_RENDIMENTO_LIQUIDO}`,
    component: RhCalculoRendimentoLiquidoModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhcalculorendimentoliquido'
    }
  }
};
