import {deepFreeze} from 'pl-comps-angular';
import {EDHEmpRegimeTrabalho} from '../../../modules/portalrh/dgempsfull/jsonDGEMPFull.interface';
import {IDataSource} from '../../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_REGIME_TRABALHO} from './regimeTrabalho.datasource.interface';

export const DATA_SOURCE_REGIME_TRABALHO: IDataSource<EDHEmpRegimeTrabalho> = deepFreeze({
  name: DATA_SOURCE_NAME_REGIME_TRABALHO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EDHEmpRegimeTrabalho.Privado, name: 'dgemps.workRegime.0', label: 'dgemps.workRegime.0'},
    {value: EDHEmpRegimeTrabalho.FuncaoPublica, name: 'dgemps.workRegime.1', label: 'dgemps.workRegime.1'}
  ]
});
