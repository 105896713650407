import {Component, Injector, OnInit} from '@angular/core';
import {isArray} from 'pl-comps-angular';
import {IBlockedPluginModuleStateParams} from '../blockedPlugin.module.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {ROLE} from '../../../services/role.const';

@Component({
  selector: 'blocked-plugin-module',
  templateUrl: './blockedPlugin.module.component.html'
})
export class BlockedPluginModuleComponent extends ModuloComponent implements OnInit {
  public pluginRoles: Array<ROLE>;
  public requiredRoles: Array<ROLE>;

  private readonly _params: IBlockedPluginModuleStateParams;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._params = <IBlockedPluginModuleStateParams>this._transition.params();
    this.requiredRoles = isArray(this._params.requiredRoles) ? this._params.requiredRoles : [];
    this.pluginRoles = isArray(this._params.pluginRoles) ? this._params.pluginRoles : [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setCaption(this._params.pageTitle);
  }
}
