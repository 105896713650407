export enum ETipoValorUnitarioRecolher {
  Indiferente,
  ValorVariavel,
  ValorFixo,
  REmuneracaoHora,
  REmuneracaoHoraBase,
  VencimentoBase,
  REmuneracaoMensalTotal,
  RemuneracaoPorDiaUtil,
  REmuneracaoPorDiaBase30,
  REmuneracaoMensalSubsidioBase22,
  REmuneracaoMensalSubsidioBase30,
  REmuneracaoHora2
}

export const DATA_SOURCE_NAME_TIPO_VALOR_UNITARIO_RECOLHER = 'tipoValorUnitarioRecolher';
