import {EDebitoCredito} from '../../datasources/debitocredito/debitoCredito.datasource.interface';
import {TDate} from '../../../common/dates';

export enum EConcilMovAbStatus {
  Inactive = 'cmasInactive',
  Scheduled = 'cmasScheduled',
  Started = 'cmasStarted',
  Ended = 'cmasEnded',
  Timeout = 'cmasTimeout',
  Error = 'cmasError'
}

export interface IJsonConcilMovAbConta {
  nConta: string;
  nome: string;
  concilMovAb: Array<IJsonConcilMovAb>;
}

export interface IJsonConcilMovAb {
  extPocCabID: string;
  periodo: string;
  nDiario: number;
  nDoc: string;
  nDocExterno: string;
  dataDocExt: TDate;
  isDocumentoOrigemRegimeDeIvaDeCaixa: boolean;
  valor: number;
  porPagar: number;
  dc: EDebitoCredito;
  aImputar: number;
  dataVenc: TDate;
  moeda: string;
  valorActualAImputar: number;
  montanteAReter: number;
  valorPago: number;
  nLanc: string;
}

export interface IJsonConcilMovAbJobStatus {
  requestDate: TDate;
  state: EConcilMovAbStatus;
  userStartedId: number;
  plannedStartDate: TDate;
  description: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  DeNConta: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AteNConta: string;
}
