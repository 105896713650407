import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {LoadOptions} from 'devextreme/data';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {EDateMonth, IPlDynamicVisualsSecondaryClickAction, IPlFilterPanelEvtFiltered, IPlToolbarItem, IPlToolbarMenuItem, isEmpty} from 'pl-comps-angular';
import {DATA_SOURCE_ESTADO_EMP} from '../../../../datasources/estadoemp/estadoEmp.datasource';
import {DATA_SOURCE_MESES} from '../../../../datasources/meses/meses.datasource';
import {devExpressDataGridFiltersToQueryFilter, devExpressDataGridSortToOrder} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {EEstadoEmp} from '../../../../datasources/estadoemp/estadoEmp.datasource.interface';
import {ENTITY_NAME_REMUN} from '../../rhRecolhaDados.entity.interface';
import {ETipoAbonoDesconto} from '../../../abdes/abdes.entity.interface';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {IDevExpressDataGridLoadResult} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonREMUNFX} from '../../jsonRHRecolhaDados.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import moment, {Moment} from 'moment';
import {
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowClick
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {IJsonNumber} from '../../../../../common/interfaces/json';

const CURRENT_DATE: Moment = moment();
const CURRENT_YEAR: number = CURRENT_DATE.year();
const CURRENT_MONTH: number = CURRENT_DATE.month();

@Component({
  selector: 'rh-recolhadados-list',
  templateUrl: './rhRecolhaDados.entity.list.component.html'
})
export class RHRecolhaDadosListComponent extends ModuloEntityListComponent<IJsonREMUNFX> implements OnInit {
  @Input() public anos: Array<IJsonNumber>;

  public isRemun: boolean;
  public dataSource: CustomStore;
  public dataGridInstance: dxDataGrid;
  public filter: string;

  private readonly _mnuEstadoEmp: IPlToolbarItem;
  private readonly _estadosEmp: Array<IPlToolbarMenuItem>;
  private _estadoEmp: number;
  private _anoEmCurso: number;
  private _mesEmCurso: number;
  private _meses: Array<IPlToolbarMenuItem>;
  private _mnuAnos: IPlToolbarItem;
  private _mnuMes: IPlToolbarItem;
  private _selectedEstadoEmp: IPlToolbarMenuItem;
  private _selectedAno: IPlToolbarMenuItem;
  private _selectedMes: IPlToolbarMenuItem;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._meses = [];
    this._estadosEmp = [];
    this._estadoEmp = EEstadoEmp.Activo;
    this._estadosEmp = DATA_SOURCE_ESTADO_EMP.data.map<IPlToolbarMenuItem>(({value, name}: IDataSourceItem<EEstadoEmp>) => {
      const itemEstadoEmp: IPlToolbarMenuItem = {
        id: String(value),
        caption: name,
        active: value === this._estadoEmp,
        click: (menuItem: IPlToolbarMenuItem) => {
          this._changedStateEmp(menuItem);
        }
      };
      if (itemEstadoEmp.active) {
        this._selectedEstadoEmp = itemEstadoEmp;
      }
      return itemEstadoEmp;
    });
    this._mnuEstadoEmp = {order: 101, id: 'rhrecolhadadosListMnuEstadoEmp', caption: this._getCaptionEstadoEmp(), type: 'dropdown', menu: this._estadosEmp};
    this._anoEmCurso = CURRENT_DATE.year();
    this._mesEmCurso = CURRENT_DATE.month();
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.isRemun = this.entity.name === ENTITY_NAME_REMUN;
    this.dataSource = new CustomStore({
      key: this.entity.metadata.keyName,
      load: this._onLoadDataGrid.bind(this)
    });
    this.toolbar.addButton(this._mnuEstadoEmp);

    if (this.isRemun) {
      let addedCurrentYear = false;
      const anos: Array<IPlToolbarMenuItem> = this.anos.map<IPlToolbarMenuItem>(({value}: IJsonNumber) => {
        if (!addedCurrentYear && value === CURRENT_YEAR) {
          addedCurrentYear = true;
        }
        const itemAno: IPlToolbarMenuItem = {
          caption: String(value),
          active: value === this._anoEmCurso,
          click: (menuItem: IPlToolbarMenuItem) => {
            this._changedAno(menuItem);
          }
        };
        if (itemAno.active) {
          this._selectedAno = itemAno;
        }
        return itemAno;
      });
      if (!addedCurrentYear) {
        const itemAno: IPlToolbarMenuItem = {
          caption: String(CURRENT_YEAR),
          active: CURRENT_YEAR === this._anoEmCurso,
          click: (menuItem: IPlToolbarMenuItem) => {
            this._changedAno(menuItem);
          }
        };
        if (itemAno.active) {
          this._selectedAno = itemAno;
        }
        anos.unshift(itemAno);
      }
      this._meses = DATA_SOURCE_MESES.data.map<IPlToolbarMenuItem>(({value, name}: IDataSourceItem<EDateMonth>) => {
        const month: number = value - 1;
        const itemMes: IPlToolbarMenuItem = {
          id: String(month),
          caption: this._translateService.instant(name),
          active: month === this._mesEmCurso,
          click: (menuItem: IPlToolbarMenuItem) => {
            this._changedMes(menuItem);
          }
        };
        if (itemMes.active) {
          this._selectedMes = itemMes;
        }
        return itemMes;
      });
      this._mnuAnos = {order: 102, id: 'rhrecolhadadosListMnuAnos', caption: this._getCaptionAno(), type: 'dropdown', menu: anos};
      this._mnuMes = {order: 103, id: 'rhrecolhadadosListMnuMes', caption: this._getCaptionMes(), type: 'dropdown', menu: this._meses};
      this.toolbar.addButton(this._mnuAnos).addButton(this._mnuMes);
    }
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this.dataGridInstance = component;
  }

  public onDataGridRowClick({handled, rowType, component, rowIndex, isExpanded}: IDevExpressDataGridEventOnRowClick<IJsonREMUNFX>): void {
    if (!handled && rowType === 'group') {
      const key: unknown = component.getKeyByRowIndex(rowIndex);
      if (isExpanded) {
        component.collapseRow(key);
      } else {
        component.expandRow(key);
      }
    }
  }

  public onDataGridContextMenuPreparing({target, row, event}: IDevExpressDataGridEventOnContextMenuPreparing<IJsonREMUNFX>): void {
    if (target === 'content' && row?.rowType === 'data') {
      event.preventDefault();
      const id: string = this.isRemun ? row.data.remUNID : row.data.remFXID;
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateContextMenuActions(id);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.target, actions);
    }
  }

  public redirectToDetail(item: IJsonREMUNFX): void {
    const id: string = this.isRemun ? item.remUNID : item.remFXID;
    this._stateService.go(this.states.detail, {id: id});
  }

  public calculateGroupValueEmpregado(item: IJsonREMUNFX): string {
    return `${item.codEMP} - ${item.nomeEmpr}`;
  }

  public calculateCellValueCodABDESC(item: IJsonREMUNFX): string {
    return `${item.codABDESC} - ${item.nomeAbonoDesconto}`;
  }

  public calculateCellValueTipoProcessamento(item: IJsonREMUNFX): string {
    return `${item.tipoProcessamento} - ${item.nomeTipoProcessamento}`;
  }

  public readonly fnOnFiltered: (event: IPlFilterPanelEvtFiltered<string>) => IPlFilterPanelEvtFiltered<string> = (event: IPlFilterPanelEvtFiltered<string>) => this._onFiltered(event);

  public readonly fnCalculateCellValueAbonoDesc = (item: IJsonREMUNFX): string => this._calculateCellValueAbonoDesc(item);

  private _onLoadDataGrid(loadOptions: LoadOptions): Promise<IDevExpressDataGridLoadResult<IJsonREMUNFX>> {
    const filter: Array<string> = [];
    filter.push(`estado=${this._estadoEmp}`);
    if (this.isRemun) {
      filter.push(`ano=${this._anoEmCurso}`);
      if (this._mesEmCurso > -1) {
        filter.push(`mes=${this._mesEmCurso + 1}`);
      }
    }
    if (loadOptions.filter) {
      filter.push(devExpressDataGridFiltersToQueryFilter(this.dataGridInstance, loadOptions.filter));
    }
    filter.push(this.filter);
    const normalizedFilter: string = filter.filter((value: string) => !isEmpty(value)).join('&');
    const order: string = devExpressDataGridSortToOrder(loadOptions.sort);
    return this.service.query({pesquisa: this.searchValue, ordena: order, filtro: normalizedFilter}).then((response: THttpQueryResponse<IJsonREMUNFX>) => {
      return {
        data: response.body.list,
        totalCount: response.body.total
      };
    });
  }

  private _changedStateEmp(menuItem: IPlToolbarMenuItem): void {
    if (menuItem.id !== String(this._estadoEmp)) {
      if (this._selectedEstadoEmp) {
        this._selectedEstadoEmp.active = false;
      }
      this._selectedEstadoEmp = menuItem;
      this._selectedEstadoEmp.active = true;
      this._estadoEmp = Number(this._selectedEstadoEmp.id);
      this._mnuEstadoEmp.caption = this._getCaptionEstadoEmp();
      this.refreshList();
    }
  }

  private _changedAno(menuItem: IPlToolbarMenuItem): void {
    if (menuItem.caption !== String(this._anoEmCurso)) {
      if (this._selectedAno) {
        this._selectedAno.active = false;
      }
      this._selectedAno = menuItem;
      this._selectedAno.active = true;
      this._anoEmCurso = Number(this._selectedAno.caption);
      this._mnuAnos.caption = this._getCaptionAno();
      this._mnuMes.menu = this._anoEmCurso === CURRENT_YEAR ? this._meses.slice(0, CURRENT_MONTH + 1) : this._meses;
      if (this._mesEmCurso > this._mnuMes.menu.length - 1) {
        this._changedMes(this._mnuMes.menu[this._mnuMes.menu.length - 1], false);
      }
      this.refreshList();
    }
  }

  private _changedMes(menuItem: IPlToolbarMenuItem, refresh: boolean = true): void {
    if (menuItem.id !== String(this._mesEmCurso)) {
      if (this._selectedMes) {
        this._selectedMes.active = false;
      }
      this._selectedMes = menuItem;
      this._selectedMes.active = true;
      this._mesEmCurso = Number(this._selectedMes.id);
      this._mnuMes.caption = this._getCaptionMes();
      if (refresh) {
        this.refreshList();
      }
    }
  }

  private _getCaptionEstadoEmp(): string {
    const nomeEstadoEmp = this._translateService.instant(this._estadosEmp[this._estadoEmp].caption);
    return this._translateService.instant('rhrecolhadados.toolbar.stateemp', {nameState: nomeEstadoEmp});
  }

  private _getCaptionAno(): string {
    return this._translateService.instant('toolbar.year', {value: this._anoEmCurso});
  }

  private _getCaptionMes(): string {
    const nomeMesEmCurso = this._meses[this._mesEmCurso].caption;
    return this._translateService.instant('rhrecolhadados.toolbar.month', {nameMonth: nomeMesEmCurso});
  }

  private _onFiltered(event: IPlFilterPanelEvtFiltered<string>): IPlFilterPanelEvtFiltered<string> {
    return {
      ...event,
      serializedFilters: event.serializedFilters.replace('nomeEmpr', 'codEMP').replace('nomeAbonoDesconto', 'codABDESC').replace('nomeTipoProcessamento', 'tipoProcessamento')
    };
  }

  private _calculateCellValueAbonoDesc(item: IJsonREMUNFX): string {
    return this._translateService.instant(item.abonoDesc === ETipoAbonoDesconto.Desconto ? 'tipoAbonoDesc.data.desconto' : 'tipoAbonoDesc.data.abono');
  }
}
