import {HttpResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import type dxDataGrid from 'devextreme/ui/data_grid';
import moment from 'moment';
import {PlAlertService} from 'pl-comps-angular';
import {TDate} from '../../../common/dates';
import {MIN_DATE_CGD} from '../../../common/utils/utils';
import {ETipoCalculoDiferimento, ETipoDataRefDiferimento, IJsonCalculoDiferimentos, IJsonCalculoDiferimentosConfig} from '../../modules/diferimentos/jsonCalculoDiferimentos.interface';
import {calculoDiferimentosSourceTipos, calculoDiferimentosSourceTiposDataRef, IDiferimentosParams, IHeaderCalculoDiferimentos} from '../../modules/diferimentos/diferimentos.module.interface';
import {DiferimentosService} from '../../modules/diferimentos/diferimentos.module.service';
import {ConfigService} from '../../services/config/config.service';
import {IDataSourceItem} from '../datasource/datasources.interface';
import {IDevExpressDataGrid} from '../devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {CGCardPanelComponent} from '../cg/cardpanel/cardpanel.component';

const INDEX_DATA_LANCAMENTO = 1;

@Component({
  selector: 'calc-diferimentos',
  templateUrl: './calcdiferimentos.component.html'
})
export class CalcDiferimentosComponent implements OnInit, OnChanges {
  @Input() public diferimentosParams: IDiferimentosParams;
  @Input() public diferimentosConfig: IJsonCalculoDiferimentosConfig;
  @Output() public readonly evtHeaderChanged: EventEmitter<IHeaderCalculoDiferimentos>;
  @Output() public readonly evtOutOffFocusDataFim: EventEmitter<void>;

  public readonly sourceMetodos: Array<IDataSourceItem<ETipoCalculoDiferimento>>;
  public readonly sourceTiposDataRef: Array<IDataSourceItem<ETipoDataRefDiferimento>>;
  public header: IHeaderCalculoDiferimentos;
  public messageFieldValor: string;
  public dataGridDefinition: IDevExpressDataGrid<IJsonCalculoDiferimentos>;
  public form: FormGroupDirective;
  public promise: Promise<void>;

  private _dataGridInstance: dxDataGrid;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    private readonly _calculoDiferimentosService: DiferimentosService,
    private readonly _plAlertService: PlAlertService,
    private readonly _configService: ConfigService,
    private readonly _translateService: TranslateService
  ) {
    this.evtHeaderChanged = new EventEmitter<IHeaderCalculoDiferimentos>();
    this.evtOutOffFocusDataFim = new EventEmitter<void>();
    this.diferimentosParams = {
      fromComercial: false,
      fromContabilidade: false,
      dataIniDiferimento: undefined,
      dataFimDiferimento: undefined,
      valor: undefined,
      docBalanceado: false
    };
    this.sourceMetodos = calculoDiferimentosSourceTipos(this._translateService);
    this.sourceTiposDataRef = calculoDiferimentosSourceTiposDataRef(this._translateService);

    this.dataGridDefinition = {
      columns: [
        {dataField: 'dataDoc', dataType: 'date', caption: 'calculodiferimentos.linhas.dataDoc'},
        {dataField: 'ano', dataType: 'number', caption: 'calculodiferimentos.linhas.ano'},
        {dataField: 'numeroDias', dataType: 'number', caption: 'calculodiferimentos.linhas.numeroDias'},
        {dataField: 'valor', dataType: 'double', caption: 'calculodiferimentos.linhas.valor'}
      ],
      allowColumnReordering: false,
      allowColumnResizing: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      sorting: {mode: 'none'},
      remoteOperations: false,
      paging: {pageSize: 15}
    };
    this.calculaDiferimentos = this.calculaDiferimentos.bind(this);
  }

  public ngOnInit(): void {
    this.messageFieldValor =
      !this.diferimentosParams?.fromComercial && this.diferimentosParams?.fromContabilidade
        ? this.diferimentosParams?.docBalanceado && !this.diferimentosParams?.valor
          ? 'calculodiferimentos.messages.semcontaparadif'
          : !this.diferimentosParams?.docBalanceado
            ? 'calculodiferimentos.messages.naobalanceado'
            : ''
        : '';
    this._applyHeader();
  }

  public ngOnChanges({diferimentosConfig}: SimpleChanges): void {
    if (diferimentosConfig && !diferimentosConfig.isFirstChange()) {
      this.header.tipodiferimento = this.sourceMetodos[this.diferimentosConfig ? this.diferimentosConfig.rendimentosTipoCalculo : 0].value;
      this.header.nomeTipodiferimento = this.sourceMetodos[this.diferimentosConfig ? this.diferimentosConfig.rendimentosTipoCalculo : 0].label;
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public calculaDiferimentos(): Promise<void> {
    if (this.promise) {
      return this.promise;
    }
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    this.promise = this._calculoDiferimentosService
      .calculaDiferimentos(this.header)
      .then((response: HttpResponse<Array<IJsonCalculoDiferimentos>>) => {
        this.dataGridDefinition.dataSource = response.body;
        if (!this._cardPanel.collapsed) {
          this._cardPanel.collapse();
        }
        this._plAlertService.success('calculodiferimentos.success');
      })
      .finally(() => {
        if (this._dataGridInstance) {
          this._dataGridInstance.endCustomLoading();
        }
        this.promise = undefined;
      });
    return this.promise;
  }

  public setTipoDiferimento(value: IDataSourceItem<ETipoCalculoDiferimento>): void {
    this.header.tipodiferimento = value?.value;
    this.header.nomeTipodiferimento = value?.label;
    this.emitHeader();
  }

  public setTipoDataRefDiferimento(value: IDataSourceItem<ETipoDataRefDiferimento>): void {
    this.header.tipoDataRefDiferimento = value?.value;
    this.header.nomeTipoDataRefDiferimento = value?.label;
    this.emitHeader();
  }

  public dataFimChanged(value: TDate): void {
    this.header.datafim = value;
    this.emitHeader();
  }

  public emitHeader(): void {
    this.evtHeaderChanged.emit(this.header);
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _applyHeader(): void {
    const dataIni = !this.diferimentosParams.dataIniDiferimento || this.diferimentosParams.dataIniDiferimento === MIN_DATE_CGD ? moment() : this.diferimentosParams.dataIniDiferimento;
    const datafim =
      !this.diferimentosParams.dataFimDiferimento || this.diferimentosParams.dataFimDiferimento === MIN_DATE_CGD
        ? this.diferimentosParams.fromComercial
          ? moment()
          : moment().add(1, 'year')
        : this.diferimentosParams.dataFimDiferimento;
    this.header = {
      tipodiferimento: this.sourceMetodos[this.diferimentosConfig ? this.diferimentosConfig.rendimentosTipoCalculo : 0].value,
      nomeTipodiferimento: this.sourceMetodos[this.diferimentosConfig ? this.diferimentosConfig.rendimentosTipoCalculo : 0].label,
      tipoDataRefDiferimento: this.sourceTiposDataRef[INDEX_DATA_LANCAMENTO].value,
      nomeTipoDataRefDiferimento: this.sourceTiposDataRef[INDEX_DATA_LANCAMENTO].label,
      dataini: dataIni,
      datafim: datafim,
      valor: !this.diferimentosParams.valor ? undefined : this.diferimentosParams.valor,
      ncasasarredondamento: this._configService.configurations.contabilidade.decimais.valor
    };
    this.emitHeader();
  }
}
