import {FaturacaoContratosLogComponent} from '../modals/faturacaocontratoslog/faturacaoContratosLog.modal.component';
import {Component, Injector, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {EDelphiNumberTypes, IPlToolbarItem, isEmpty, isObject, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {ModuloComponent} from '../../../components/module/module.component';
import moment from 'moment';
import {EConfigOptionsInstanceName, IFaturacaoContratosConfigOptions, TConfigOptions} from '../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../config/constants';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {
  EFaturacaoContratosTableLegendColors,
  ETipoOrigemTxtContrato,
  IFaturacaoAvencasHeader,
  IFaturacaoAvencasProcessParams,
  IFaturacaoContratosParams,
  IJsonFaturacaoContrato,
  ITipoOrigemTxtContrato,
  TABLE_LEGEND_FATURACAO_CONTRATOS
} from '../faturacaoContratos.module.interface';
import {IJsonContratoTipo, IJsonContratoTipoSelect} from '../../../entities/contratostipos/jsonContratoTipo.entity.interface';
import {FaturacaoContratosService} from '../faturacaoContratos.module.service';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {HttpResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowPrepared,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {TTableLegend} from '../../../components/tablelegend/tablelegend.component.interface';
import {GestaoAvencasModalComponent} from '../modals/gestaoavencasfaturadas/gestaoAvencas.modal.component';
import {IJsonClifo} from '../../../entities/clifos/jsonClifo.entity.interface';
import {AvencasSelecionarTipoModalComponent} from '../../../entities/avencas/modal/selecionartipo/avencas.selecionar.tipo.modal.component';
import {EntityMaintenanceService} from '../../../components/entity/maintenance/entity/entity.maintenance.service';
import {IJsonClasseContrato} from '../../../entities/classescontratos/jsonClasseContrato.entity.interface';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';
import {CGCardPanelComponent} from '../../../components/cg/cardpanel/cardpanel.component';
import {ENTITY_NAME_CLASSES_CONTRATOS_AVENCAS} from '../../../entities/classescontratos/classesContratos.entity.interface';
import {ENTITY_NAME_CONTRATOS_TIPOS} from '../../../entities/contratostipos/contratosTipos.entity.interface';
import {ENTITY_NAME_CONTRATOS_TIPO_AVENCAS} from '../../../entities/contratostipoavencas/contratosTipoAvencas.entity.interface';

const NZONA_DE = 0;
const NZONA_ATE = 32767;

@Component({
  selector: 'module-faturacao-contratos',
  templateUrl: './faturacaoContratos.module.component.html'
})
export class FaturacaoContratosComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly tiposOrigemTxtContrato: typeof ETipoOrigemTxtContrato;
  public readonly variablesTxtContratoSource: Array<string>;
  public readonly classeFilter: string;
  public readonly classeOutPut: string;

  public dataGridDefinition: IDevExpressDataGrid;
  public selectedRowKeys: Array<number>;
  public selectedLines: Array<IJsonFaturacaoContrato>;
  public contratoTipoSource: Array<IJsonContratoTipoSelect>;
  public faturacaoContratosSource: Array<IJsonFaturacaoContrato>;
  public contratoTipolist: Array<string>;
  public optionShowDataInicTransp: boolean;
  public optionShowHoraInicTransp: boolean;
  public optionShowZonaDeAte: boolean;
  public optionShowErrosContratos: boolean;
  public optionShowContasDeAte: boolean;
  public optionShowDataVenc: boolean;
  public header: IFaturacaoAvencasHeader;
  public promise: Promise<void>;
  public tableLegend: TTableLegend;
  public tipoOrigemTxtContrato: Array<ITipoOrigemTxtContrato>;

  protected readonly _cgModalService: CGModalService;

  private readonly _serviceClassesContrato: IEntityService<IJsonClasseContrato>;
  private readonly _contratoTipoService: IEntityService<IJsonContratoTipo>;
  private readonly _btnRetrocederToolbar: IPlToolbarItem;
  private readonly _btnProcessarToolbar: IPlToolbarItem;
  private readonly _btnGestaoAvencasToolbar: IPlToolbarItem;
  private readonly _contaDe: string;
  private readonly _contaAte: string;
  private readonly _moduleNameRetroceder: string;

  private _subscription: Subscription;
  private _faturacaoContratosConfigOptions: TConfigOptions<boolean, IFaturacaoContratosConfigOptions>;
  private _dataGridInstance: dxDataGrid;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _faturacaoContratosService: FaturacaoContratosService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _renderer: Renderer2,
    private readonly _entityMaintenanceService: EntityMaintenanceService
  ) {
    super(_injector);
    const params: IFaturacaoContratosParams = <IFaturacaoContratosParams>this._transition.params();
    this._moduleNameRetroceder = params.moduleNameRetroceder;
    this.classeFilter = 'ClasseTipo=0';
    this.classeOutPut = '{{nClasseCtr}} - {{nomeClasseCtr}}';
    this._serviceClassesContrato = this._entityServiceBuilder.build<IJsonClasseContrato, IEntityService<IJsonClasseContrato>>(ENTITY_NAME_CLASSES_CONTRATOS_AVENCAS);
    this.configOptionsInstanceName = EConfigOptionsInstanceName.FATURACAO_CONTRATOS;
    this.configOptionsGroupName = EGroupName.ERP;
    this.variablesTxtContratoSource = ['@Descriçao', '@DtIni', '@DtFim', '@NContrato', '@PeriodoServico', '@AnoPeriodoServico'];
    this.contratoTipoSource = [];
    this.faturacaoContratosSource = [];
    this.contratoTipolist = [];
    this._contratoTipoService = this._entityServiceBuilder.build(ENTITY_NAME_CONTRATOS_TIPOS);

    this._configuraGrid();

    this.selectedRowKeys = [];
    this._contaDe = this._configService.configurations.clientesFornecedores.radicalClientes;
    this._contaAte = `${this._configService.configurations.clientesFornecedores.radicalClientes}9999999`;

    this._btnRetrocederToolbar = {
      id: 'retroceder-toolbar',
      type: 'button',
      class: 'btn-secondary',
      iconLeft: '<i class="fa fa-fw fa-undo"></i>',
      caption: 'global.btn.goBack',
      order: 0,
      click: () => this._retroceder()
    };

    this._btnProcessarToolbar = {
      id: 'faturacao-contratos-processar-toolbar',
      type: 'button',
      class: 'btn-success',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      caption: 'faturacaoContratos.btn.processar',
      order: 1,
      click: () => this._processar()
    };

    this._btnGestaoAvencasToolbar = {
      id: 'faturacao-contratos-gestao-avencas-toolbar',
      type: 'button',
      class: 'btn-info',
      iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
      caption: 'faturacaoContratos.btn.gestaoAvencas',
      order: 2,
      click: () => this._gestaoAvencas()
    };

    this._cgModalService = this._injector.get<CGModalService>(CGModalService);

    this.tipoOrigemTxtContrato = [
      {value: ETipoOrigemTxtContrato.TipoDeAvenca, label: 'faturacaoContratos.data.tipoDeAvenca'},
      {value: ETipoOrigemTxtContrato.Manual, label: 'faturacaoContratos.data.manual'}
    ];

    this.tiposOrigemTxtContrato = ETipoOrigemTxtContrato;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.header = {
      nClasseCtr: '',
      nomeClasseCtr: '',
      nZonaDe: NZONA_DE,
      nZonaAte: NZONA_ATE,
      nContaDe: this._configService.configurations.clientesFornecedores.radicalClientes,
      nContaAte: this._contaAte,
      dataDoc: moment(),
      dataVenc: moment(),
      dataVencActiva: false,
      dataDocExterno: moment(),
      dataInicTransp: moment(),
      horaInicTransp: moment(),
      origemTxtContrato: ETipoOrigemTxtContrato.Manual,
      txtCtrNoCabecalho: false,
      txtCtrNaLinha: true,
      txtContrato: ''
    };
    this._serviceClassesContrato.query({filtro: this.classeFilter}).then((response: THttpQueryResponse<IJsonClasseContrato>) => {
      if (response.body.list.length === 1) {
        this.header = {...this.header, nClasseCtr: response.body.list[0].nClasseCtr, nomeClasseCtr: response.body.list[0].nomeClasseCtr};
        this._getTiposContrato(this.header.nClasseCtr);
      }
    });
    this.contratoTipolist = [];

    if (!isEmpty(this._moduleNameRetroceder)) {
      this.toolbar.addButton(this._btnRetrocederToolbar);
    }

    this.toolbar.addButton(this._btnProcessarToolbar);
    this.toolbar.addButton(this._btnGestaoAvencasToolbar);

    this._subscription = this._faturacaoContratosService.getOptions().subscribe((value: TConfigOptions<boolean, IFaturacaoContratosConfigOptions>) => {
      this._faturacaoContratosConfigOptions = value;
      this.optionShowDataInicTransp = this._faturacaoContratosConfigOptions.get('showDataInicTransp').value;
      this.optionShowHoraInicTransp = this._faturacaoContratosConfigOptions.get('showHoraInicTransp').value;
      this.optionShowZonaDeAte = this._faturacaoContratosConfigOptions.get('showZonaDeAte').value;
      this.optionShowErrosContratos = this._faturacaoContratosConfigOptions.get('showErrosContratos').value;
      this.optionShowContasDeAte = this._faturacaoContratosConfigOptions.get('showContasDeAte').value;
      this.optionShowDataVenc = this._faturacaoContratosConfigOptions.get('showDataVenc').value;
    });

    this.tableLegend = TABLE_LEGEND_FATURACAO_CONTRATOS;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    this._faturacaoContratosService.cancel();
  }

  public pesquisar(): Promise<void> {
    this.promise = undefined;

    this.contratoTipolist = [];
    for (const item of this.contratoTipoSource) {
      if (item.selected) {
        this.contratoTipolist.push(item.nTipoContrato);
      }
    }

    if (!this.header.nClasseCtr || this.header.nClasseCtr === '' || this.header.nClasseCtr === '0') {
      this._plAlertService.error('faturacaoContratos.errors.classeContratoNaoPodeSerNula');
      return this.promise;
    }

    if (this.contratoTipolist.length === 0) {
      this._plAlertService.error('faturacaoContratos.errors.naoExisteTipoContratoSelecionado');
      return this.promise;
    }

    this.promise = this._faturacaoContratosService
      .get(
        this.header.nClasseCtr,
        this.contratoTipolist.toString(),
        this.header.dataDoc,
        this.header.dataDoc,
        this.header.nZonaDe,
        this.header.nZonaAte,
        this.header.nContaDe,
        this.header.nContaAte,
        true
      )
      .then((response) => {
        this.selectedRowKeys = [];
        this.faturacaoContratosSource = response.list;
        for (const item of this.faturacaoContratosSource) {
          if (item.temErro) {
            continue;
          }
          this.selectedRowKeys.push(item.refCtrCab);
        }
      });
    this._cardPanel.collapse();
    return this.promise;
  }

  public onSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IJsonFaturacaoContrato>): void {
    this.selectedLines = [];
    for (const selectedItem of event.selectedRowsData) {
      const index: number = this.faturacaoContratosSource.findIndex((item: IJsonFaturacaoContrato) => item.refCtrCab === selectedItem.refCtrCab);
      if (index > -1) {
        this.selectedLines.push(this.faturacaoContratosSource[index]);
      }
    }
  }

  public classeContratoChanged(value: string): void {
    if (isUndefinedOrNull(value)) {
      this.header = {...this.header, nClasseCtr: '', nomeClasseCtr: ''};
      return;
    }
    this._getTiposContrato(value);

    this.header.nClasseCtr = value;
  }

  public nZonaDeChanged(value: number): void {
    if (isUndefinedOrNull(value) || value.toString() === '0') {
      this.header.nZonaDe = 0;
      this.header.nZonaAte = EDelphiNumberTypes.MaxSmallInt;
      return;
    }
    this.header.nZonaDe = value;
    this.header.nZonaAte = value;
  }

  public nZonaAteChanged(value: number): void {
    if (isUndefinedOrNull(value)) {
      this.header.nZonaAte = EDelphiNumberTypes.MaxSmallInt;
      return;
    }
    this.header.nZonaAte = value;
  }

  public nContaDeChanged(value: string | IJsonClifo): void {
    if (isUndefinedOrNull(value) || value === '0') {
      this.header.nContaDe = this._contaDe;
      this.header.nContaAte = this._contaAte;
      return;
    }
    if (isObject(value)) {
      const clifo = <IJsonClifo>value;
      this.header.nContaDe = clifo.nConta;
      this.header.nContaAte = clifo.nConta;
    } else {
      this.header.nContaDe = <string>value;
      this.header.nContaAte = this._contaAte;
    }
  }

  public nContaAteChanged(value: string): void {
    if (!value) {
      this.header.nContaAte = this._contaAte;
      return;
    }
    this.header.nContaAte = value;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onDataGridRowPrepared({data, rowElement, rowType}: IDevExpressDataGridEventOnRowPrepared<IJsonFaturacaoContrato>): void {
    if (rowType === 'data') {
      let cssClass: string;
      if (data.erroStr) {
        cssClass = EFaturacaoContratosTableLegendColors.ContratoComErro;
      }

      if (cssClass) {
        this._renderer.addClass(rowElement, cssClass);
      } else if (rowElement.style.backgroundColor) {
        this._renderer.removeClass(rowElement, cssClass);
      }
    } else if (rowType === 'detail') {
      this._renderer.addClass(rowElement, EFaturacaoContratosTableLegendColors.ContratoComErro);
    }
  }

  public onContentReady(): void {
    for (const contrato of this.faturacaoContratosSource) {
      if (this.optionShowErrosContratos && contrato.temErro) {
        this._dataGridInstance.expandRow(contrato.refCtrCab);
      } else if (this._dataGridInstance.isRowExpanded(contrato.refCtrCab)) {
        this._dataGridInstance.collapseRow(contrato.refCtrCab);
      }
    }
  }

  public callModuloSelTiposAvencas(): Promise<void> {
    const contratoTipolist = [];
    for (const item of this.contratoTipoSource) {
      if (item.selected) {
        contratoTipolist.push(item.nTipoContrato);
      }
    }

    let tipoAvencaSelecionada = '';
    if (contratoTipolist.length === 1) {
      tipoAvencaSelecionada = contratoTipolist[0];
    }

    if (this.header.nClasseCtr && tipoAvencaSelecionada) {
      return this._callModuloTipoAvencas(`${this.header.nClasseCtr}_${tipoAvencaSelecionada}`);
    }

    const instance = this._cgModalService.showVanilla(AvencasSelecionarTipoModalComponent, {size: 'md'});
    const componentInstance: AvencasSelecionarTipoModalComponent = instance.componentInstance;
    componentInstance.nClasseCtr = this.header.nClasseCtr ? this.header.nClasseCtr : undefined;
    return instance.result.then((response: string) => {
      if (response) {
        return this._callModuloTipoAvencas(response);
      }
      return undefined;
    });
  }

  public applyVariable(variable: string): void {
    if (this.header.txtContrato) {
      this.header.txtContrato += variable;
    } else {
      this.header.txtContrato = variable;
    }
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._appService.sendBeacon(this._faturacaoContratosService.cancelUrl());
  }

  private async _processar(): Promise<void> {
    const refCtrCabList = [];

    for (const item of this._dataGridInstance.getSelectedRowsData()) {
      refCtrCabList.push(item.refCtrCab);
    }

    if (refCtrCabList.length === 0) {
      this._plAlertService.error('faturacaoContratos.errors.naoExisteContratoSelecionadoParaFaturar');
      return;
    }

    const processParams: IFaturacaoAvencasProcessParams = {
      refCtrCabList: refCtrCabList.toString(),
      dataDoc: this.header.dataDoc,
      dataVenc: this.header.dataVenc,
      utilizaDtVenc: this.header.dataVencActiva,
      dataDocExterno: this.header.dataDocExterno,
      dataInicTransp: this.header.dataInicTransp,
      horaInicTransp: this.header.horaInicTransp,
      txtCtrNoCabecalho: this.header.txtCtrNoCabecalho,
      txtCtrNaLinha: this.header.txtCtrNaLinha,
      txtContrato: this.header.txtContrato,
      skipControlaEstados: true,
      origemPeloTipoContrato: this.header.origemTxtContrato === ETipoOrigemTxtContrato.TipoDeAvenca
    };

    await this._faturacaoContratosService.post(processParams).then((response: HttpResponse<void>) => {
      if (response) {
        this._faturacaoContratosService.getServicosFaturados().then((responseAux) => {
          if (responseAux) {
            const temDocsGerados = responseAux.body?.docsGerados?.length > 0;
            if (temDocsGerados) {
              this.pesquisar();
            }

            if (temDocsGerados || responseAux.body?.erros.length > 0) {
              const instanceFaturacaoContratosLog = this._cgModalService.showVanilla(FaturacaoContratosLogComponent, {size: 'xxl'});
              instanceFaturacaoContratosLog.componentInstance.contratosFaturados = responseAux.body;
            }
          }
        });
      }
    });
  }

  private _gestaoAvencas(): Promise<void> {
    return new Promise<void>((resolve) => {
      this._cgModalService.showVanilla(GestaoAvencasModalComponent, {size: 'xxl'});
      resolve();
    });
  }

  private _getClasse(value: string): string {
    return `nClasseCtr=${value}&classeTipo=0`;
  }

  private _configuraGrid(): void {
    this.dataGridDefinition = {
      keyExpr: 'refCtrCab',
      columns: [
        {dataField: 'classeContrato', dataType: 'string', caption: 'faturacaoContratos.fields.classeContrato', visible: false},
        {dataField: 'tipoContrato', dataType: 'string', caption: 'faturacaoContratos.fields.tipoContrato', visible: false},
        {dataField: 'nContrato', dataType: 'number', caption: 'faturacaoContratos.fields.nContrato', visible: false},
        {dataField: 'versao', dataType: 'number', caption: 'faturacaoContratos.fields.versao', visible: false},
        {dataField: 'contratoAsString', dataType: 'string', caption: 'faturacaoContratos.fields.contratoAsString'},
        {dataField: 'descricao', dataType: 'string', caption: 'faturacaoContratos.fields.descricao'},
        {dataField: 'nClifo', dataType: 'string', caption: 'faturacaoContratos.fields.nClifo'},
        {dataField: 'nomeClifo', dataType: 'string', caption: 'faturacaoContratos.fields.nomeClifo'},
        {dataField: 'totalLiquido', dataType: 'double', caption: 'faturacaoContratos.fields.totalLiquido'},
        {dataField: 'nFaturasEmAberto', dataType: 'number', caption: 'faturacaoContratos.fields.nFaturasEmAberto'},
        {dataField: 'dataUltimoPagamento', dataType: 'date', caption: 'faturacaoContratos.fields.dataUltimoPagamento'},
        {dataField: 'dataUltimaFatura', dataType: 'date', caption: 'faturacaoContratos.fields.dataUltimaFatura'},
        {dataField: 'obsUltimaFatura', dataType: 'string', caption: 'faturacaoContratos.fields.obsUltimaFatura', visible: false},
        {dataField: 'temDebitoDirectoStr', dataType: 'string', caption: 'faturacaoContratos.fields.temDebitoDirectoStr', visible: false},
        {dataField: 'temErro', dataType: 'boolean', caption: 'faturacaoContratos.fields.temErro', visible: false}
      ],
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      columnHidingEnabled: false,
      masterDetail: {enabled: false, template: 'dataGridTemplateDetail'}
    };
  }

  private _callModuloTipoAvencas(id: string): Promise<void> {
    const maintenanceTiposAvencas = this._entityMaintenanceService.build(ENTITY_NAME_CONTRATOS_TIPO_AVENCAS);
    return maintenanceTiposAvencas.maintenanceEdit(id).then(() => undefined);
  }

  private _getTiposContrato(value: string): Promise<void> {
    return this._contratoTipoService.query({pesquisa: this._getClasse(value)}).then((response: THttpQueryResponse<IJsonContratoTipo>) => {
      this.contratoTipoSource = response.body.list;
      for (const item of this.contratoTipoSource) {
        item.selected = true;
      }
    });
  }

  private _retroceder(): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: this._moduleNameRetroceder});
  }
}
