import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonQPMTS} from './jsonQPMTS.entity.interface';
import {ENTITY_NAME_QPMTS} from './qpmts.entity.interface';

export const ENTITY_QPMTS: IEntityDefinition<IJsonQPMTS> = {
  name: ENTITY_NAME_QPMTS,
  roles: [ROLE.RH],
  pageTitle: 'global.menu.qpmts',
  searchPlaceholder: 'qpmts.pesquisa',
  metadata: {
    keyName: 'codmts',
    fields: [
      {name: 'codmts', caption: 'qpmts.fields.codmts', placeholder: 'qpmts.fields.codmts', validators: {required: true, maxlength: 3}},
      {name: 'nome', caption: 'qpmts.fields.nome', placeholder: 'qpmts.fields.nome', validators: {required: true, maxlength: 100}}
    ],
    order: 'codmts',
    searchFields: 'codmts,nome',
    listFields: 'codmts,nome',
    newFields: 'codmts,nome'
  },
  autocomplete: {
    rowTemplate: '{{codmts}} - {{nome}}',
    output: 'nome',
    searchFields: 'codmts,nome'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    search: true,
    delete: false,
    filter: true
  }
};
