import type {IElementOffset} from './utilities.interface';
import {isFunction} from './utilities';

export function prev<T extends Element>(element: T, selector?: string): T {
  if (!element) {
    return undefined;
  }
  if (!selector) {
    return <T>element.previousElementSibling;
  }
  let previousElement: T = <T>element.previousElementSibling;
  for (;;) {
    if (!previousElement) {
      return undefined;
    }
    if (previousElement.matches(selector)) {
      return previousElement;
    }
    previousElement = <T>previousElement.previousElementSibling;
  }
}

export function next<T extends Element>(element: T, selector?: string): T {
  if (!element) {
    return undefined;
  }
  if (!selector) {
    return <T>element.nextElementSibling;
  }
  let previousElement: T = <T>element.nextElementSibling;
  for (;;) {
    if (!previousElement) {
      return undefined;
    }
    if (previousElement.matches(selector)) {
      return previousElement;
    }
    previousElement = <T>previousElement.nextElementSibling;
  }
}

const animateWithHandleEvents: ReadonlyArray<string> = Object.freeze('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend'.split(' '));

export function animateWith(element: Element, animationClass: string, callback?: (animationClass: string) => void): void {
  const handler = (): void => {
    element.classList.remove(animationClass, 'animated');
    if (isFunction(callback)) {
      callback(animationClass);
    }
  };

  element.classList.remove(animationClass, 'animated');

  for (const eventName of animateWithHandleEvents) {
    element.addEventListener(eventName, handler, {once: true});
  }

  element.classList.add(animationClass, 'animated');
}

export function getElementOffset(element: Element): IElementOffset {
  const elementRect: DOMRect = element.getBoundingClientRect();
  const elementWindow: Window = element.ownerDocument.defaultView;
  const elementDocument: Element = element.ownerDocument.documentElement;
  return {
    top: elementRect.top + elementWindow.scrollY - elementDocument.clientTop,
    left: elementRect.left + elementWindow.scrollX - elementDocument.clientLeft
  };
}
