import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {copy, IPlFormDefinition, IPlFormTemplate} from 'pl-comps-angular';
import {EntityRegistryService} from '../../../components/entity/entity.registry.service';
import {IEntity} from '../../../components/entity/entity.definition.interface';
import {IJsonDataValor} from '../../datavalor/jsonDataValor.entity.interface';
import moment from 'moment';
import {ENTITY_NAME_DATA_VALOR} from '../../datavalor/dataValor.entity.interface';

@Component({
  selector: 'amortizacao-edit-modal',
  templateUrl: './imtab.edit.dataValor.modal.component.html'
})
export class ImtabEditDataValorModalComponent extends CGModalComponent<IJsonDataValor> implements OnInit {
  @Input() public model: IJsonDataValor;
  @Input() public new: boolean;

  public title: string;
  public captionSave: string;
  public formDefinition: IPlFormDefinition;
  public formTemplate: IPlFormTemplate;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityRegistryService: EntityRegistryService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    const entity: IEntity<IJsonDataValor> = this._entityRegistryService.getEntity<IJsonDataValor>(ENTITY_NAME_DATA_VALOR);
    this.formDefinition = entity.detail.definition;
    this.formTemplate = entity.detail.template;
    this.title = this.new ? '' : '';
    this.captionSave = this.new ? 'global.btn.save' : 'global.btn.update';
    if (!this.model) {
      this.model = {
        dataValorID: undefined,
        tipo: 1,
        keyValue: '',
        valor1: undefined,
        valor2: undefined,
        valor3: undefined,
        dataInicio: moment()
      };
    } else {
      this.model = copy<IJsonDataValor>(this.model);
    }
  }

  public close(): void {
    super.close(this.model);
  }
}
