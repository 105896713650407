import {IPlTooltipCallback} from 'pl-comps-angular';
import {TDate} from '../../../common/dates';

export enum EViewType {
  List = 'list',
  Icon = 'icon'
}

export interface IIconBtn {
  btnSearch: string;
  btnDownload: string;
}

export interface IFileItemDefinition {
  docId?: string | number;
  folderId?: string | number;
  text: string;
  textExt: string;
  mimeType?: string;
  size?: string;
  dateModified?: TDate;
  hourModified?: TDate;
  user?: string;
  dateCreate?: TDate;
  hourCreate?: TDate;
  docExt?: string;
  classificador?: string;
  path?: string;
}

export interface IFileItem extends IFileItemDefinition {
  urlExtension: string;
  tooltipCallback: IPlTooltipCallback;
}
