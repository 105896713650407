import {merge} from 'lodash-es';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {copy, isDefinedNotNull, isNumber} from 'pl-comps-angular';
import {CGModalService} from '../../../../../../components/cg/modal/cgmodal.service';
import {IAnexoEQuadro3Totais} from '../../../modelo22.module.interface';
import {IJsonModelo22, IJsonModelo22AnexoE} from '../../../jsonModelo22.module.interface';
import {Modelo22AnexoEModalComponent} from './modals/modelo22.anexoE.modal.component';
import {maxDateCG, maxDateCGD, minDateCG, minDateCGD, round} from '../../../../../../../common/utils/utils';
import {IDevExpressDataGrid, TDevExpressDataGridToolbar} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {Properties as ButtonOptions} from 'devextreme/ui/button';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {HttpResponse} from '@angular/common/http';
import {Modelo22Service} from '../../../modelo22.module.service';

const NUMBER_4 = 4;
const NUMBER_10 = 10;
const NUMBER_15 = 15;
const NUMBER_35 = 35;
const NUMBER_50 = 50;
const NUMBER_75 = 75;
const NUMBER_95 = 95;

@Component({
  selector: 'modelo22-anexos-anexoe',
  templateUrl: './modelo22.anexos.anexoE.component.html'
})
export class Modelo22AnexosAnexoEComponent implements OnInit, OnChanges {
  @Input() public modelo22: IJsonModelo22;
  @Output() public readonly evtChangedModelo22: EventEmitter<IJsonModelo22>;

  public readonly dataGridDefinition621: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro03SomaControlo: IDevExpressDataGrid;

  public promise: Promise<void>;
  public anexoEQuadro3TotArray: Array<IAnexoEQuadro3Totais>;

  private readonly _anexoAQuadro4ListaTot: IAnexoEQuadro3Totais;

  constructor(
    private readonly _cgModalService: CGModalService,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _translateService: TranslateService
  ) {
    this.evtChangedModelo22 = new EventEmitter<IJsonModelo22>();
    this.dataGridDefinition621 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'rendimentos', dataType: 'double', caption: 'modelo22.anexoE.quadro03.table.rend'},
        {dataField: 'rendimentosT', dataType: 'double', caption: 't'},
        {dataField: 'materiaColectavel', dataType: 'double', caption: 'modelo22.anexoE.quadro03.table.matcole'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn621'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoE.quadro03.table.desc'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoEQuadro3())
    };
    this.dataGridDefinitionQuadro03SomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: 'rendimento', dataType: 'double', caption: 'modelo22.anexoE.quadro03.table.totrend'},
        {dataField: 'materiaColetavel', dataType: 'double', caption: 'modelo22.anexoE.quadro03.table.totmatcol'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };

    const totalsTitle: string = this._translateService.instant('modelo22.anexoE.quadro03.table.soma');
    this._anexoAQuadro4ListaTot = {
      title: totalsTitle,
      rendimento: 0,
      materiaColetavel: 0
    };

    this.anexoEQuadro3TotArray = [this._anexoAQuadro4ListaTot];
  }

  public ngOnInit(): void {
    this._calcTotals();
    this._setListIndexes();
  }

  public ngOnChanges({modelo22}: SimpleChanges): void {
    if (modelo22 && !modelo22.isFirstChange()) {
      this.modelo22 = modelo22.currentValue;
      this._setListIndexes();
    }
  }

  public addLineAnexoEQuadro3(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22AnexoEModalComponent);
    const componentInstance: Modelo22AnexoEModalComponent = modalInstance.componentInstance;
    componentInstance.anexoE = copy(this.anexoE);
    return modalInstance.result.then((result: IJsonModelo22AnexoE) => {
      this._applyAnexoE(result);
      this.emitModelo22(true);
    });
  }

  public deleteLineAnexoEQuadro3(line: number): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      this.anexoE.quadro03.campo0621List.splice(line, 1);
      this._applyAnexoE(this.anexoE);
      this.emitModelo22(true);
    });
  }

  public calcAnexoE(field: string): void {
    switch (field) {
      case '16':
        this.anexoE.quadro03.campo16 = round(this.anexoE.quadro03.campo01 * (NUMBER_4 / 100), 2);
        break;
      case '17':
        this.anexoE.quadro03.campo17 = round(this.anexoE.quadro03.campo02 * (NUMBER_4 / 100), 2);
        break;
      case '18':
        this.anexoE.quadro03.campo18 = round(this.anexoE.quadro03.campo03 * (NUMBER_75 / 100), 2);
        break;
      case '19':
        this.anexoE.quadro03.campo19 = round(this.anexoE.quadro03.campo04 * (NUMBER_10 / 100), 2);
        break;
      case '20':
        this.anexoE.quadro03.campo20 = round(this.anexoE.quadro03.campo05 * (NUMBER_10 / 100), 2);
        break;
      case '22':
        this.anexoE.quadro03.campo22 = round(this.anexoE.quadro03.campo07 * (NUMBER_95 / 100), 2);
        break;
      case '23':
        this.anexoE.quadro03.campo23 = round(this.anexoE.quadro03.campo08 * (NUMBER_95 / 100), 2);
        break;
      case '24':
        this.anexoE.quadro03.campo24 = round(this.anexoE.quadro03.campo09 * (NUMBER_95 / 100), 2);
        break;
      case '25':
        this.anexoE.quadro03.campo25 = round(this.anexoE.quadro03.campo10 * (NUMBER_95 / 100), 2);
        break;
      case '26':
        this.anexoE.quadro03.campo26 = round(this.anexoE.quadro03.campo11 * (NUMBER_95 / 100), 2);
        break;
      case '27':
        this.anexoE.quadro03.campo27 = round(this.anexoE.quadro03.campo12 * (NUMBER_95 / 100), 2);
        break;
      case '28':
        this.anexoE.quadro03.campo28 = round(this.anexoE.quadro03.campo13 * (100 / 100), 2);
        break;
      case '33':
        this.anexoE.quadro03.campo33 = round(this.anexoE.quadro03.campo32 * (NUMBER_35 / 100), 2);
        break;
      case '36':
        this.anexoE.quadro03.campo36 = round(this.anexoE.quadro03.campo35 * (NUMBER_50 / 100), 2);
        break;
      case '29':
        this.anexoE.quadro03.campo29 = round(this.anexoE.quadro03.campo14 * (NUMBER_4 / 100), 2);
        break;
      case '30':
        this.anexoE.quadro03.campo30 = round(this.anexoE.quadro03.campo15 * (NUMBER_95 / 100), 2);
        break;
      case '38':
        this.anexoE.quadro03.campo38 = round(this.anexoE.quadro03.campo37 * (NUMBER_95 / 100), 2);
        break;
      case '46':
        this.anexoE.quadro03.campo46 = round(this.anexoE.quadro03.campo39 * (NUMBER_15 / 100), 2);
        break;
    }
    this.emitModelo22(true);
  }

  public async emitModelo22(evaluateModelo22: boolean = false): Promise<void> {
    if (evaluateModelo22) {
      await this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
        this.modelo22 = response.body;
      });
    }
    this.evtChangedModelo22.emit(this.modelo22);
  }

  public get anexoE(): IJsonModelo22AnexoE {
    return this.modelo22.anexoE;
  }

  public set anexoE(value: IJsonModelo22AnexoE) {
    this.modelo22.anexoE = value;
  }

  private _applyAnexoE(anexoE: IJsonModelo22AnexoE): void {
    this.anexoE = merge(this.anexoE, anexoE);
    this._clearCGDatesDefault();
    this._calcTotals();
    this._setListIndexes();
    this.emitModelo22();
  }

  private _setListIndexes(): void {
    let index = 1;
    for (const listElement of this.anexoE.quadro03.campo0621List) {
      listElement._index = index;
      index++;
    }
    this.dataGridDefinition621.dataSource = this.anexoE.quadro03.campo0621List;
  }

  private _calcTotals(): void {
    this._anexoAQuadro4ListaTot.rendimento = 0;
    this._anexoAQuadro4ListaTot.materiaColetavel = 0;
    for (const item of this.anexoE.quadro03.campo0621List) {
      const valueRendimentos = isNumber(item.rendimentos) ? item.rendimentos : 0;
      const valueMateria = isNumber(item.materiaColectavel) ? item.materiaColectavel : 0;
      this._anexoAQuadro4ListaTot.rendimento += valueRendimentos;
      this._anexoAQuadro4ListaTot.materiaColetavel += valueMateria;
    }
    this.anexoEQuadro3TotArray = this.anexoEQuadro3TotArray.slice();
  }

  private _generateDataGridToolbar(callback: () => void): TDevExpressDataGridToolbar {
    return {
      items: [
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'add',
            hint: this._translateService.instant('modelo22.comum.addline'),
            onClick: () => {
              callback();
            }
          } satisfies ButtonOptions
        },
        'exportButton',
        'columnChooserButton'
      ]
    };
  }

  private _clearCGDatesDefault(): void {
    if (
      (isDefinedNotNull(this.anexoE.quadro04) && moment(this.anexoE.quadro04).isSameOrBefore(minDateCG(), 'date')) ||
      moment(this.anexoE.quadro04).isSameOrBefore(minDateCGD(), 'date') ||
      moment(this.anexoE.quadro04).isSameOrAfter(maxDateCG(), 'date') ||
      moment(this.anexoE.quadro04).isSameOrAfter(maxDateCGD(), 'date')
    ) {
      this.anexoE.quadro04 = undefined;
    }
  }
}
