import {firstValueFrom} from 'rxjs';
import {isArray} from 'pl-comps-angular';
import {buildSessionUrlWithParams, jsonWithFileToFormData} from '../../../common/utils/utils';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntity} from './dgemps.entity.interface';
import {IApiRequestConfig, IApiRequestConfigWithBody} from '../../services/api/api.service.interface';
import {IJsonCalendario} from '../calendarios/jsonCalendarios.entity.interface';
import {IJsonDGEMPDetail, IJsonDGEMPMarcacoes, IJsonDGEMPPorAprovar, IJsonDGEMPRefeicoesPorAprovar, IJsonProcessamentoEmpregadoSalarios} from './jsonDGEMP.entity.interface';
import {IJsonDHEMPCalcularCustoHoraEmpregado} from '../../modules/portalrh/dgempsfull/jsonDGEMPFull.interface';
import {IJsonPRHComIntLin, IJsonPRHComunicacaoInterna, IJsonPRHNumber} from '../prhcomunicacoesinternas/jsonPRHComunicacaoInterna.entity.interface';
import {IJsonPRHDocsPlata} from '../prhdocsplata/jsonPRHDocsPlata.entity.interface';
import {IJsonPRHLocal} from '../prhlocais/jsonPRHLocal.entity.interface';
import {IJsonPRHMarcarAbonos} from '../../interfaces/jsonPRHAbonos.interface';
import {IJsonPRHMarcarFaltas} from '../../interfaces/jsonPRHFaltas.interface';
import {IJsonPRHMarcarFerias} from '../../interfaces/jsonPRHFerias.interface';
import {Moment} from 'moment';
import {ROLE} from '../../services/role.const';
import {ICalendariosGetParams} from '../calendarios/calendarios.entity.interface';

export const ENTITY_DGEMPS: IDGEMPSEntity = {
  name: ENTITY_NAME_DGEMPS,
  url: 'colaboradores',
  entityUrl: ENTITY_NAME_DGEMPS,
  roles: [ROLE.ERP, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.COLABORADOR, ROLE.PCA],
  pageTitle: 'global.menu.dgemps',
  searchPlaceholder: 'dgemps.pesquisa',
  metadata: {
    keyName: 'codEmp',
    fields: [
      {
        name: 'codEmp',
        type: 'cgsmallint',
        width: '120px',
        caption: 'dgemps.fields.codEmp',
        placeholder: 'dgemps.fields.codEmpPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'nome',
        caption: 'dgemps.fields.nome',
        placeholder: 'dgemps.fields.nomePlaceholder',
        validators: {required: true, maxlength: 60}
      },
      {
        name: 'apelido',
        caption: 'dgemps.fields.apelido',
        placeholder: 'dgemps.fields.apelidoPlaceholder',
        validators: {required: true, maxlength: 20}
      },
      {
        name: 'rua',
        caption: 'dgemps.fields.rua',
        placeholder: 'dgemps.fields.ruaPlaceholder',
        validators: {maxlength: 50}
      },
      {
        name: 'morada',
        caption: 'dgemps.fields.morada',
        placeholder: 'dgemps.fields.moradaPlaceholder',
        validators: {maxlength: 50}
      },
      {
        name: 'codPostal',
        caption: 'dgemps.fields.codPostal',
        placeholder: 'dgemps.fields.codPostalPlaceholder',
        validators: {required: true, maxlength: 20}
      },
      {
        name: 'nomeLocalida',
        caption: 'dgemps.fields.nomeLocalida',
        placeholder: 'dgemps.fields.nomelocalidaPlaceholder',
        validators: {maxlength: 50}
      },
      {
        name: 'email',
        caption: 'dgemps.fields.email',
        placeholder: 'dgemps.fields.emailPlaceholder',
        validators: {maxlength: 100}
      },
      {
        name: 'nTelefone',
        caption: 'dgemps.fields.nTelefone',
        placeholder: 'dgemps.fields.nTelefonePlaceholder',
        validators: {maxlength: 15}
      },
      {
        name: 'numContrib',
        caption: 'dgempsfull.fields.numContrib',
        placeholder: 'dgempsfull.fields.numContribPlaceholder',
        validators: {maxlength: 25}
      },
      {
        name: 'telemovelPart1',
        caption: 'dgemps.fields.telemovelPart1',
        placeholder: 'dgemps.fields.telemovelpart1Placeholder',
        validators: {maxlength: 15}
      },
      {name: 'codServicoPRH', visible: false},
      {
        name: 'nomeServicoPRH',
        caption: 'dgemps.fields.codservicoprh',
        placeholder: 'dgemps.fields.codservicoprhPlaceholder',
        entity: {name: 'prhservicos', keyTarget: 'codServicoPRH', outputTarget: 'nomeServicoPRH'},
        validators: {required: true}
      },
      {
        name: 'ativo',
        type: 'boolean',
        caption: 'global.text.active'
      }
    ],
    searchFields: 'nome,numContrib'
  },
  autocomplete: {
    rowTemplate: '{{codEmp}} - {{nome}}',
    output: 'nome',
    searchFields: 'codEmp,nome,numContrib'
  },
  actions: {
    new: false,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  detail: {
    template: {
      items: [
        {type: 'field', field: 'codEmp', order: 1},
        {type: 'group', order: 2, fields: [{field: 'nome'}, {field: 'apelido'}]},
        {type: 'field', field: 'rua', order: 3},
        {type: 'field', field: 'morada', order: 4},
        {type: 'group', order: 5, fields: [{field: 'codPostal'}, {field: 'nomeLocalida'}]},
        {type: 'field', order: 6, field: 'email'},
        {type: 'group', order: 7, fields: [{field: 'nTelefone'}, {field: 'telemovelPart1'}]},
        {type: 'field', field: 'codServicoPRH', order: 8}
      ]
    }
  },
  service: function () {
    this.definirLocalPorDefeito = (codEmp: number, codLocal: number) => {
      return this.put<void, void>({url: `${codEmp}/definelocaldefeito`, body: undefined, params: {codlocal: codLocal}});
    };

    this.getLocalDefeito = (codEmp: number) => {
      return this.get<IJsonPRHLocal>({id: `${codEmp}/localdefeito`});
    };

    this.getListaDocPlata = (codEmp: number, dataValidade: Moment) => {
      return this.get<Array<IJsonPRHDocsPlata>>({id: `${codEmp}/listadocsplata`, params: {datavalidade: dataValidade}});
    };

    this.getListaRefeicoesPorAprovar = (codEmp: number, codpapel: number) => {
      return this.get<Array<IJsonDGEMPRefeicoesPorAprovar>>({id: `${codEmp}/listarefeicoesporaprovar`, params: {codpapel: codpapel}});
    };

    this.getDetail = (codEmp: number) => {
      return this.get<IJsonDGEMPDetail>({id: `${codEmp}/detail`});
    };

    this.getListaComunicacoes = (codEmp: number, dataDe: Moment, dataAte: Moment, tipoComunicacao: number, soPorLer: boolean) => {
      return this.get<Array<IJsonPRHComunicacaoInterna>>({
        id: `${codEmp}/listacomunicacoesinternas`,
        params: {
          datade: dataDe,
          dataate: dataAte,
          tipocomint: tipoComunicacao,
          soporler: soPorLer
        }
      });
    };

    this.getListaPendentesPorTratar = (codEmp: number) => {
      return this.get<IJsonDGEMPPorAprovar>({id: `${codEmp}/assuntospendentesaprovacao`});
    };

    this.getCountComunicacoesPorLer = (codEmp: number, dataDe: Moment, dataAte: Moment, tipo: number) => {
      return this.get<IJsonPRHNumber>({id: `${codEmp}/countcomunicacoesinternas`, params: {datade: dataDe, dataate: dataAte, tipo: tipo}});
    };

    this.marcarAbonos = (codEmp: number, abonos: IJsonPRHMarcarAbonos) => {
      return this.post<IJsonPRHMarcarAbonos>({url: `${codEmp}/marcarabonos`, body: abonos});
    };

    this.marcarFaltas = (codEmp: number, faltas: IJsonPRHMarcarFaltas, file: Blob) => {
      const body: FormData = jsonWithFileToFormData(faltas, file);
      return this.upload<IJsonPRHMarcarFaltas>({url: `${codEmp}/marcarfaltas`, body: body});
    };

    this.marcarFerias = (codEmp: number, ferias: IJsonPRHMarcarFerias) => {
      return this.post<IJsonPRHMarcarFerias>({url: `${codEmp}/marcarferias`, body: ferias});
    };

    this.getCalendario = (codEmp: number, params: ICalendariosGetParams) => {
      return this.get<IJsonCalendario>({
        id: `${codEmp}/calendario`,
        params: {
          datade: params.dataDe,
          dataate: params.dataAte,
          holidaysonly: params.holidaysOnly,
          tiposdia: isArray(params.tiposDia) ? params.tiposDia.join() : undefined,
          excludetiposdia: isArray(params.excludeTiposDia) ? params.excludeTiposDia.join() : undefined
        }
      });
    };

    this.getListaMarcacoes = (codEmp: number, dataDe: Moment, dataAte: Moment) => {
      return this.get<IJsonDGEMPMarcacoes>({id: `${codEmp}/listamarcacoes`, params: {datade: dataDe, dataate: dataAte}});
    };

    this.getListaProcessamentos = (codEmp: number, dataDe: Moment, dataAte: Moment, fichcriadosegsocial: boolean) => {
      return this.get<Array<IJsonProcessamentoEmpregadoSalarios>>({
        id: `${codEmp}/listaprocessamentos`,
        params: {
          datade: dataDe,
          dataate: dataAte,
          fichcriadosegsocial: fichcriadosegsocial
        }
      });
    };

    this.exportReciboVencimentoToPdf = (codEmp: number, nProcessamento: number = 0, nVias: number = 1, reportName: string = '') => {
      return firstValueFrom(this.exportReciboVencimentoToPdfUrl(codEmp, nProcessamento, nVias, reportName)).then((url: string) => {
        return this.apiService.get<Blob>({url: url, responseType: 'blob'});
      });
    };

    this.exportReciboVencimentoToPdfUrl = (codEmp: number, nProcessamento: number = 0, nVias: number = 1, reportName: string = '') => {
      return buildSessionUrlWithParams(`${this.entityUrl()}/${codEmp}/recibovencimentopdf`, {
        nprocessamento: nProcessamento,
        nvias: nVias,
        rptname: reportName
      });
    };

    this.getFoto = (codEmp: number, config?: IApiRequestConfig) => {
      return this.get<Blob>({id: `${codEmp}/foto`, responseType: 'blob', ...config});
    };

    this.setFoto = (codEmp: number, file: File, config?: IApiRequestConfig) => {
      const formData: FormData = new FormData();
      formData.append(file.name, file);
      return this.upload<void>({url: `${codEmp}/foto`, body: formData, ...config});
    };

    this.deleteFoto = (codEmp: number, config?: IApiRequestConfig) => {
      return this.delete({url: `${codEmp}/foto`, ...config});
    };

    this.calcularCustoHoraEmpregado = (
      codEmp: number,
      calcularCustoHoraEmpregado: IJsonDHEMPCalcularCustoHoraEmpregado,
      summary: boolean = false,
      config?: IApiRequestConfigWithBody<IJsonDHEMPCalcularCustoHoraEmpregado>
    ) => {
      return this.post({
        url: `${codEmp}/calculacustohoraempregado`,
        body: calcularCustoHoraEmpregado,
        params: {
          summary: summary
        },
        ...config
      });
    };

    this.getModeloImportacao = () => this.get<Blob>({id: 'import/modelo/xls', responseType: 'blob'});

    this.getExecuteImportacaoUrl = () => `${this.entityUrl()}/import/execute/xls`;

    this.executeImportacao = () => this.apiService.post<Blob, void>({url: this.getExecuteImportacaoUrl(), responseType: 'blob'});

    this.getLastPortaisReg = () => {
      return this.get<string>({id: 'lastportals'});
    };

    this.saveLastPortaisReg = (portais: Array<string>) => {
      return this.put<void, Array<string>>({url: 'lastportals', params: {portais: portais.join(',')}});
    };

    this.marcarComunicacaoComoLida = (codEmp: number, comintlinid: number) => {
      return this.put<IJsonPRHComIntLin, void>({url: `${codEmp}/marcarcomunicacaocomolida`, params: {comintlinid: comintlinid}});
    };
  }
};
