<div class="cg-dashboards">
  <div class="row">
    <div class="col-md-6 cg-dashboards-spacing">
      <div class="row">
        <div class="col-12">
          <h4 class="page-title"><i class="fa fa-fw fa-shopping-basket"></i> <span [translate]="'dashboards.erp.pageTitle.vendas'"></span></h4>
          <div class="card p-3">
            <dx-chart [cgDxChart]="chartVendas" [dataSource]="vendas" (onInitialized)="onInitializedChartVendas($event)">
              <dxo-legend verticalAlignment="top" horizontalAlignment="center" itemTextPosition="right"></dxo-legend>
              <div *dxTemplate="let item of 'customTooltip'" class="customTooltip">
                <div>
                  <b>{{ item.argument }}</b>
                </div>
                <div>
                  <span>{{ item.seriesName }}: </span>
                  <span>{{ item.originalValue | cgcNumber }}</span>
                </div>
              </div>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card p-3">
            <dx-pie-chart [cgDxPieChart]="chartRendimentos" [dataSource]="rendimentos" (onInitialized)="onInitializedChartRendimentos($event)"></dx-pie-chart>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <pl-dashboard class="cg-dashboards-table" [dashboard]="rendimentosTable" [showTotalTable]="true"></pl-dashboard>
        </div>
      </div>
    </div>

    <div class="col-md-6 cg-dashboards-spacing">
      <div class="row">
        <div class="col-12">
          <h4 class="page-title"><i class="fa fa-fw fa-bank"></i> <span [translate]="'dashboards.erp.pageTitle.tesourariaLiquida'"></span></h4>
          <div class="card p-3">
            <dx-chart
              [cgDxChart]="chartTesourariaLiquida"
              [dataSource]="tesourariaLiquida"
              (onInitialized)="onInitializedChartTesourariaLiquida($event)"
              (onPointHoverChanged)="onPointHoverChangedChartTesourariaLiquida($event)"
              (onLegendClick)="onLegendClickChartTesourariaLiquida($event)">
              <dxo-legend verticalAlignment="top" horizontalAlignment="center" itemTextPosition="right"></dxo-legend>
              <div *dxTemplate="let item of 'customTooltip'">
                <div>
                  <b>{{ item.argument }}</b>
                </div>
                <div *ngFor="let itemPoint of item.points">
                  <span>{{ itemPoint.seriesName }}: </span>
                  <span>{{ itemPoint.originalValue | cgcNumber }}</span>
                </div>
              </div>
            </dx-chart>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card p-3">
            <dx-pie-chart [cgDxPieChart]="chartGastosCompras" [dataSource]="gastosCompras" (onInitialized)="onInitializedChartGastosCompras($event)"></dx-pie-chart>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <pl-dashboard class="cg-dashboards-table" [dashboard]="gastosComprasTable" [showTotalTable]="true"></pl-dashboard>
        </div>
      </div>
    </div>
  </div>
</div>
