import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MapaFundosCompensacaoComponent} from './components/mapaFundosCompensacao.module.component';

export const MODULE_PCA_RH_MAPA_FUNDOS_COMPENSACAO: IModuleDefinition = {
  name: 'pcaFundosCompensacao',
  state: {
    url: '/rh/mapa/fundoscompensacao',
    component: MapaFundosCompensacaoComponent,
    data: {
      roles: [ROLE.PCA],
      pageTitle: 'global.menu.pcaFundosCompensacao'
    }
  }
};
