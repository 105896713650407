import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_TAXA_RETENCAO, ETipoTaxaRetencao} from './tipoTaxaRetencao.datasource.interface';

export const DATA_SOURCE_TIPO_TAXA_RETENCAO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_TAXA_RETENCAO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoTaxaRetencao.Rendimentos, name: 'tipoTaxaRetencao.data.rendimentos'},
    {value: ETipoTaxaRetencao.SubNatal, name: 'tipoTaxaRetencao.data.subNatal'},
    {value: ETipoTaxaRetencao.SubFerias, name: 'tipoTaxaRetencao.data.subFerias'}
  ]
});
