import {Subscription} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';
import {AuthService} from '../../../../services/auth/auth.service';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';

@Component({
  selector: 'cg-user-profile-image',
  templateUrl: './cg.user.profileimage.component.html'
})
export class CGUserProfileImageComponent implements OnDestroy {
  public name: string;

  private readonly _subscriptionSession: Subscription;
  private _session: TUserSession;

  constructor(private readonly _authService: AuthService) {
    this._subscriptionSession = this._authService.identityAsObservable().subscribe((session: TUserSession) => {
      this._session = session;
      this._evaluateSession();
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionSession.unsubscribe();
  }

  private _evaluateSession(): void {
    if (!this._session) {
      return;
    }
    this.name = this._evaluateUserName(this._session);
  }

  private _evaluateUserName(session: TUserSession): string {
    const firstName = session.firstName.trim();
    const lastName = session.lastName.trim();
    if (!lastName) {
      if (firstName.length > 1) {
        return (firstName.charAt(0) + firstName.charAt(1)).toLocaleUpperCase();
      }
      return firstName;
    }
    return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
  }
}
