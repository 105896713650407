<div class="ativosdepreciacoes-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'ativos.steps.ativoDepreciacoes'"></h5>
  </div>

  <div class="modal-body">
    <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="ativosDepreciacoesModal" [dataSource]="dataGridDefinition.dataSource"></dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
