<pl-autocomplete
  [attrName]="attrName"
  [model]="value"
  (modelChange)="render($event)"
  [allowInvalid]="options.allowInvalid"
  [allowEmpty]="options.allowEmpty"
  [filterFields]="options.filterFields"
  [inputClass]="inputClass"
  [output]="options.output"
  [placeholder]="options.placeholder"
  [rowTemplate]="options.rowTemplate"
  [source]="options.source"
  [validateFn]="options.validateFn"
  [properties]="options">
</pl-autocomplete>
