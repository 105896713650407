<div class="modelo10-retencaomod10-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo10.modal.retencaomod10.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <pl-group plAutoFocus>
        <label [translate]="'modelo10.modal.retencaomod10.nif'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="nif" [(model)]="itemQuadro5.nif" [properties]="{disabled: !isNewLine, validators: {required: {value: true}}}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'modelo10.modal.retencaomod10.rendimentosDoAno'"></label>
          <edit>
            <pl-edit type="number" attrName="rendimentosDoAno" [(model)]="itemQuadro5.rendimentosDoAno" [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo10.modal.retencaomod10.tipoRendimentos'"></label>
          <edit>
            <entity-autocomplete
              entity="rendis"
              [model]="itemQuadro5"
              (selectedKeyChange)="itemQuadro5.tipoRendimentos = $event"
              [fieldsMap]="{tipRendiment: 'tipoRendimentos'}"
              [properties]="{disabled: !(isNewLine || itemQuadro5._isManuallyAdded), validators: {required: {value: true}}}"
              [filter]="filterTiposRendimento"
              outputKey="tipRendiment"
              output="key">
            </entity-autocomplete>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'modelo10.modal.retencaomod10.localObtencao'"></label>
          <edit>
            <pl-edit type="localObtencaoRendimentos" attrName="localObtencao" [(model)]="itemQuadro5.localObtencao"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo10.modal.retencaomod10.retencoesIRSIRC'"></label>
          <edit>
            <pl-edit type="number" attrName="retencoesIRSIRC" [(model)]="itemQuadro5.retencoesIRSIRC"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'modelo10.modal.retencaomod10.contribuicoes'"></label>
          <edit>
            <pl-edit type="number" attrName="contribuicoes" [(model)]="itemQuadro5.contribuicoes"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo10.modal.retencaomod10.quotizacoes'"></label>
          <edit>
            <pl-edit type="number" attrName="quotizacoes" [(model)]="itemQuadro5.quotizacoes"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <label [translate]="'modelo10.modal.retencaomod10.retencaoSobretaxa'"></label>
        <edit>
          <pl-edit type="number" attrName="retencaoSobretaxa" [(model)]="itemQuadro5.retencaoSobretaxa"></pl-edit>
        </edit>
      </pl-group>

      <div class="card">
        <div class="card-header" [translate]="'modelo10.modal.retencaomod10.rendAnoAnt'"></div>
        <div class="card-body">
          <pl-group>
            <pl-group>
              <label [translate]="'modelo10.modal.retencaomod10.rendimentosAnterioresValores'"></label>
              <edit>
                <pl-edit type="number" attrName="rendimentosAnterioresValores" [(model)]="itemQuadro5.rendimentosAnterioresValores"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'modelo10.modal.retencaomod10.rendimentosAnterioresNAnos'"></label>
              <edit>
                <pl-edit type="integer" attrName="rendimentosAnterioresNAnos" [(model)]="itemQuadro5.rendimentosAnterioresNAnos"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'global.text.year'"></label>
              <edit>
                <pl-edit type="integer" attrName="rendimentosAnterioresAno" [(model)]="itemQuadro5.rendimentosAnterioresAno" [properties]="{events: {keydown: keydownAnoAnt}}"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>
        </div>
      </div>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-dismiss" id="saveNewLine" (evtClicked)="close()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
