import {TDate} from '../../../common/dates';

export enum ECalendarioTipoDia {
  Feriado,
  Ponte,
  DiaUtil,
  FimSemana,
  FeriasColect,
  DescansoSemanal
}

export enum ECalendarioTipoDiaStr {
  Feriado = 'Feriado',
  Ponte = 'Ponte',
  DiaUtil = 'Útil',
  FimSemana = 'Fim Sem.',
  FeriasColect = 'Fér.Col.',
  DescansoSemanal = 'Descanso'
}

export interface IJsonCalendarioCab {
  calenCabID: number;
  nome: string;
  sabadosDiaUtil: boolean;
  domingosDiaUtil: boolean;
  naoTemFeriados: boolean;
}

export interface IJsonCalendario extends IJsonCalendarioCab {
  datas: Array<IJsonCalendarioData>;
}

export interface IJsonCalendarioData {
  calenCabID: number;
  data: TDate;
  tipoDia: ECalendarioTipoDia;
  tipoDiaStr: ECalendarioTipoDiaStr;
}

export interface IJsonCalendarioNDiasUteis {
  nDiasUteis: number;
}

export const CALENDARIO_CAB_ID_SISTEMA = 1;
