import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonAtivosImport} from './jsonAtivosImport.entity.interface';
import {ENTITY_NAME_ATIVOS_IMPORT} from './ativosImport.entity.interface';
import {AtivosImportEditComponent} from './component/edit/ativosImport.entity.edit.component';
import {AtivosImportListComponent} from './component/list/ativosImport.entity.list.component';

export const ENTITY_ATIVOS_IMPORT: IEntityDefinition<IJsonAtivosImport> = {
  name: ENTITY_NAME_ATIVOS_IMPORT,
  roles: [ROLE.ATIVOS],
  pageTitle: 'global.menu.ativosimport',
  icon: 'fa-download',
  searchPlaceholder: 'ativosimport.pesquisa',
  metadata: {
    keyName: 'ativosImportID',
    descriptionName: 'designacao',
    fields: [
      {name: 'importado', type: 'boolean', caption: 'ativosimport.fields.importado'},
      {name: 'codAtivoGerado', type: 'number', caption: 'ativosimport.fields.codAtivo'},
      {name: 'designacao', caption: 'ativosimport.fields.designacao'},
      {name: 'refExterna', caption: 'ativosimport.fields.refExterna'},
      {name: 'usado', type: 'boolean', caption: 'ativosimport.fields.usado'},
      {name: 'estado', type: 'number', caption: 'ativosimport.fields.estado'},
      {name: 'dataAbate', type: 'date', caption: 'ativosimport.fields.dataAbate'},
      {name: 'valorVenda', type: 'number', caption: 'ativosimport.fields.valorVenda'},
      {name: 'dataAquisicao', type: 'date', caption: 'ativosimport.fields.dataAquisicao'},
      {name: 'valorAquisicao', type: 'number', caption: 'ativosimport.fields.valorAquisicao'},
      {name: 'valorAquisicaoFiscal', type: 'number', caption: 'ativosimport.fields.valorAquisicaoFiscal'},
      {name: 'nMapaAmortizacao', caption: 'ativosimport.fields.mapa'},
      {name: 'dataInicioUtiliza', type: 'date', caption: 'ativosimport.fields.dtInicioUtilizacao'},
      {name: 'baseCalculo', type: 'number', caption: 'ativosimport.fields.baseParaCalculo'},
      {name: 'metodoCalculo', type: 'number', caption: 'ativosimport.fields.metodoParaCalculo'},
      {name: 'dataUltProcessamento', type: 'date', caption: 'ativosimport.fields.dtUltProcessamento'},
      {name: 'depreciacaoAcumulada', type: 'number', caption: 'ativosimport.fields.depAcumulada'},
      {name: 'depreciacaoExercicio', type: 'number', caption: 'ativosimport.fields.depExercicio'},
      {name: 'percReducao', type: 'number', caption: 'ativosimport.fields.percReducao'},
      {name: 'depreciacaoAcumuladaCalculada', type: 'number', caption: 'ativosimport.fields.depCalculada'},
      {name: 'depreciacaoDiferenca', type: 'number', caption: 'ativosimport.fields.diferenca'},
      {name: 'valoresDepManuais', type: 'boolean', caption: 'ativosimport.fields.valoresManuais'},
      {name: 'fiscalDepAcumulada', type: 'number', caption: 'ativosimport.fields.depCalculada'},
      {name: 'fiscalDepExercicio', type: 'number', caption: 'ativosimport.fields.depExercicio'},
      {name: 'fiscalPercReducao', type: 'number', caption: 'ativosimport.fields.percReducao'},
      {name: 'depreciacaoFiscalAcumuladaCalculada', type: 'number', caption: 'ativosimport.fields.depCalculada'},
      {name: 'depreciacaoFiscalDiferenca', type: 'number', caption: 'ativosimport.fields.diferenca'},
      {name: 'codAmortizacao', caption: 'ativosimport.fields.codDR'},
      {name: 'taxaAmortizacao', type: 'number', caption: 'ativosimport.fields.taxaContab'},
      {name: 'fiscalTaxaAmortiza', type: 'number', caption: 'ativosimport.fields.taxaFiscal'},
      {name: 'nContaDoAtivo', caption: 'ativosimport.fields.ncontaAtivo'},
      {name: 'nomeContaDoAtivo', caption: 'ativosimport.fields.nomeDaContaDoAtivo'},
      {name: 'nContaFornecedor', caption: 'ativosimport.fields.nContaFornecedor'},
      {name: 'nomeContaFornecedor', caption: 'ativosimport.fields.nomeDaContaDoFornecedor'},
      {name: 'periodo', caption: 'ativosimport.fields.periodo'},
      {name: 'ndiario', type: 'number', caption: 'ativosimport.fields.diario'},
      {name: 'ndocInterno', caption: 'ativosimport.fields.nDocInterno'}
    ],
    order: 'ativosImportID',
    searchFields: 'ativosImportID,designacao'
  },
  autocomplete: {
    rowTemplate: '{{designacao}}',
    output: 'designacao',
    searchFields: 'ativosImportID,designacao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: false,
    search: true,
    filter: true,
    duplicate: false
  },
  list: {
    state: {
      component: AtivosImportListComponent
    }
  },
  detail: {
    state: {
      component: AtivosImportEditComponent
    }
  }
};
