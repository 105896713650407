import {Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import type {IEditInputEvents, TEditInputEventCallback} from './edit.input.eventshandler.directive.interface';
import {isFunction, isObject, isString} from '../../../common/utilities/utilities';

const IGNORED_SEPARATOR = ',';

@Directive({
  selector: '[editEventsHandler]',
  standalone: false
})
export class PlEditInputEventsHandlerDirective implements OnChanges, OnDestroy {
  @Input() public editEventsHandler: IEditInputEvents<any>;
  @Input() public editEventsHandlerValue: any;
  @Input() public editEventsHandlerIgnore: string;

  private readonly _element: HTMLInputElement;
  private readonly _registeredEvents: Map<string & keyof IEditInputEvents<any>, EventListener>;
  private _ignoredEvents: Array<string>;

  constructor(private readonly _elementRef: ElementRef<HTMLInputElement>) {
    this._element = this._elementRef.nativeElement;
    this._registeredEvents = new Map<string & keyof IEditInputEvents<any>, any>();
    this._ignoredEvents = [];
  }

  public ngOnChanges({editEventsHandlerIgnore, editEventsHandler}: SimpleChanges): void {
    if (editEventsHandlerIgnore) {
      const toIgnore: string = editEventsHandlerIgnore.currentValue;
      if (isString(toIgnore)) {
        this._ignoredEvents = toIgnore.split(IGNORED_SEPARATOR);
      }
    }
    if (editEventsHandler) {
      this._registerEvents(editEventsHandler.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._unregisterEvents();
  }

  private _registerEvents(events: IEditInputEvents<any>): void {
    if (!isObject(events)) {
      return;
    }
    this._unregisterEvents();
    for (const eventName of Object.keys(events)) {
      if (this._ignoredEvents.includes(eventName)) {
        continue;
      }
      const eventListener: EventListener = events[eventName];
      if (isFunction(eventListener)) {
        this._registerEvent(eventName, eventListener);
      }
    }
  }

  private _registerEvent(name: string & keyof IEditInputEvents<any>, originalListener: TEditInputEventCallback<any>): void {
    if (this._registeredEvents.has(name)) {
      this._unregisterEvent(name);
    }
    const proxyListener: EventListener = this._generateProxyListener(originalListener);
    this._element.addEventListener(name, proxyListener);
    this._registeredEvents.set(name, proxyListener);
  }

  private _unregisterEvents(): void {
    for (const eventName of this._registeredEvents.keys()) {
      this._removeEventListener(eventName);
    }
    this._registeredEvents.clear();
  }

  private _unregisterEvent(name: string & keyof IEditInputEvents<any>): void {
    if (this._removeEventListener(name)) {
      this._registeredEvents.delete(name);
    }
  }

  private _removeEventListener(name: string & keyof IEditInputEvents<any>): boolean {
    const registeredEvent: EventListener = this._registeredEvents.get(name);
    if (isFunction(registeredEvent)) {
      this._element.removeEventListener(name, registeredEvent);
      return true;
    }
    return false;
  }

  private _generateProxyListener(originalHandler: TEditInputEventCallback<any>): EventListener {
    return (event: Event) => originalHandler.call(this._element, this.editEventsHandlerValue, event);
  }
}
