import {Component, Injector, OnInit} from '@angular/core';
import {ENTITY_NAME_CLIENTES, ENTITY_NAME_FORNECEDORES, ENTITY_NAME_OUTROS_DEVEDORES_CREDORES} from '../../../entities/clifos/clifos.entity.interface';
import {ITerceirosEntity, ITerceirosEntityStateParams} from '../terceiros.module.interface';
import {ModuloComponent} from '../../../components/module/module.component';

@Component({
  selector: 'terceiros',
  templateUrl: './terceiros.module.component.html'
})
export class TerceirosComponent extends ModuloComponent implements OnInit {
  public readonly entities: Array<ITerceirosEntity>;
  public activeId: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.entities = [
      {entityName: ENTITY_NAME_CLIENTES, caption: 'terceiros.clientes'},
      {entityName: ENTITY_NAME_FORNECEDORES, caption: 'terceiros.fornecedores'},
      {entityName: ENTITY_NAME_OUTROS_DEVEDORES_CREDORES, caption: 'terceiros.outros'}
    ];
    const params: ITerceirosEntityStateParams = <ITerceirosEntityStateParams>this._transition.params();
    if (params.clifo) {
      const terceirosEntity: ITerceirosEntity = this._getEntity(params.clifo);
      if (terceirosEntity) {
        this.activeId = terceirosEntity.entityName;
      }
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();
    // First load fix
    setTimeout(() => {
      if (this.toolbar) {
        this.toolbar.removeButton(`${this.instanceName}-refresh`);
        this.toolbar.removeButton(`${this.instanceName}-filterCollapse`);
        this.toolbar.removeButton(`${this.instanceName}-search`);
      }
    });
  }

  public changedTab(tabId: string): void {
    const selected: ITerceirosEntity = this._getEntity(tabId);
    if (!selected) {
      return;
    }
    const title: string = this._translateService.instant('global.menu.terceiros');
    const subtitle: string = this._translateService.instant(`global.menu.${selected.entityName}`);
    this.setCaption(`${title} - ${subtitle}`);
  }

  private _getEntity(id: string): ITerceirosEntity {
    return this.entities.find((terceirosEntity: ITerceirosEntity) => terceirosEntity.entityName === id);
  }
}
