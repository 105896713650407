<div class="fimperiodoenc">
  <entity-detail
    entityName="fimperiodoenc"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [model]="model" [definition]="definition" [template]="template" [properties]="{readonly: type === 'detail'}"></pl-form>

      <pl-multiselect
        [model]="model.diarios"
        [source]="model.diariosPossiveis"
        key="nDiario"
        [template]="diariosTemplate"
        [callback]="plMultiselect"
        [properties]="{
          disabled: type === 'detail',
          leftCaption: 'fimperiodoenc.titleDiariosDisponiveis',
          rightCaption: 'fimperiodoenc.titleDiariosSelecionados'
        }"
        (evtChanged)="changedDiarios($event.model)">
      </pl-multiselect>
    </div>
  </entity-detail>
</div>
