<div class="portal-edit">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: !callback.isEditing()}">
        <pl-group [properties]="{validators: {required: {value: true}}}">
          <label><span [translate]="'portals.fields.url'"></span>:</label>
          <edit>
            <pl-edit type="string" attrName="url" [(model)]="model.url"></pl-edit>
          </edit>
        </pl-group>

        <pl-group [properties]="{validators: {required: {value: true}}}">
          <label><span [translate]="'portals.fields.name'"></span>:</label>
          <edit>
            <pl-edit type="string" attrName="name" [(model)]="model.name"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label><span [translate]="'portals.fields.description'"></span>:</label>
          <edit>
            <pl-edit type="string" attrName="description" [(model)]="model.description"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label><span [translate]="'portals.fields.icon'"></span>:</label>
          <edit>
            <pl-edit type="string" attrName="description" [(model)]="model.icon">
              <div *inputGroup="{klass: 'hollow-form'}"><span [translate]="'global.text.preview'"></span>:&nbsp;<i [cgcIcon]="model.icon" aria-hidden="true"></i></div>
            </pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label><span [translate]="'portals.fields.sortOrder'"></span>:</label>
          <edit>
            <pl-edit type="integer" attrName="sortOrder" [(model)]="model.sortOrder" [properties]="{allowNegative: false, thousandsSeparator: ''}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label><span [translate]="'portals.fields.sticky'"></span>:</label>
          <edit>
            <pl-edit type="boolean" attrName="sticky" [(model)]="model.sticky"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label><span [translate]="'portals.fields.roles'"></span>:</label>
          <edit>
            <pl-select [source]="rolesList" [model]="model.roles" (modelChange)="changedRoles($event)" [multiple]="true" [properties]="{disabled: !callback.isEditing()}"></pl-select>
          </edit>
        </pl-group>

        <pl-group>
          <label>
            <pl-tooltip [config]="{text: 'portals.text.tooltipIncludes'}">
              <div *plTooltipContent><span [translate]="'portals.fields.includes'"></span>&nbsp;<i class="fa fa-info-circle text-info" aria-hidden="true"></i>&nbsp;:</div>
            </pl-tooltip>
          </label>
          <edit>
            <pl-select
              [source]="portals"
              [(model)]="model.includes"
              [multiple]="true"
              rowTemplate="description"
              valueField="url"
              output="name"
              [properties]="{disabled: !callback.isEditing()}"></pl-select>
          </edit>
        </pl-group>
      </pl-form>

      <hr />

      <portal-ui-tree [menu]="model.data" [editing]="callback.isEditing()" [toolbarInstance]="instanceName" (evtChangedMenu)="changedData($event)"></portal-ui-tree>
    </div>
  </entity-detail>
</div>
