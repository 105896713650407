import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_ORD_VISUALIZADOR} from './tiposOrdenacaoVisualizador.datasource.interface';
import {ETipoOrdenacaoVisualizador} from '../../services/contabilidadedigital/jsonContabDigital.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPO_ORD_VISUALIZADOR: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_ORD_VISUALIZADOR,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoOrdenacaoVisualizador.NifData, name: 'arquivodigital.configs.tipoOrdenacaoVisualizador.nifData'},
    {value: ETipoOrdenacaoVisualizador.DataNif, name: 'arquivodigital.configs.tipoOrdenacaoVisualizador.dataNif'},
    {value: ETipoOrdenacaoVisualizador.DataRececao, name: 'arquivodigital.configs.tipoOrdenacaoVisualizador.dataRececao'},
    {value: ETipoOrdenacaoVisualizador.NomeData, name: 'arquivodigital.configs.tipoOrdenacaoVisualizador.nomeData'},
    {value: ETipoOrdenacaoVisualizador.DataNome, name: 'arquivodigital.configs.tipoOrdenacaoVisualizador.dataNome'},
    {value: ETipoOrdenacaoVisualizador.NomeFicheiro, name: 'arquivodigital.configs.tipoOrdenacaoVisualizador.nomeFicheiro'}
  ]
});
