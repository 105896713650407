<div class="rhMapasSindicatos entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form>
        <pl-group>
          <pl-group>
            <label [translate]="'global.text.listing'"></label>
            <edit>
              <pl-edit type="reports_mapas_sindicato" attrName="listagem" [(model)]="report"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'rhMapasSindicatos.filters.dedata'"></label>
            <edit>
              <pl-edit type="date" [(model)]="filters.dataDe"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit type="date" [(model)]="filters.dataAte"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'rhMapasSindicatos.filters.deSindicato'"></label>
            <edit>
              <pl-edit type="cgsmallint" [(model)]="filters.deSindicato"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit type="cgsmallint" [(model)]="filters.ateSindicato" [properties]="{events: {keydown: fnKeydownProcessar}}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>
  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
