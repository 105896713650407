export interface IJsonPrhMenu {
  codMenu: number;
  titulo: string;
  descricao: string;
  activo: boolean;
}

export interface IPrhMenu extends IJsonPrhMenu {
  sopa: string;
  prato: string;
  sobremesa: string;
}

export const ENTITY_NAME_PRH_MENUS = 'prhmenus';
