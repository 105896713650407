import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {GestaoFicheirosSepaModuleComponent} from './components/gestaoFicheirosSepa.module.component';
import {MODULE_NAME_GESTAO_FICHEIROS_SEPA} from './gestaoFicheirosSepa.module.interface';

export const MODULE_GESTAO_FICHEIROS_SEPA: IModuleDefinition = {
  name: MODULE_NAME_GESTAO_FICHEIROS_SEPA,
  state: {
    url: `/${MODULE_NAME_GESTAO_FICHEIROS_SEPA}`,
    component: GestaoFicheirosSepaModuleComponent,
    data: {
      roles: [ROLE.ERP, ROLE.CONTABILIDADE],
      pluginsRoles: [ROLE.ERPADVANCED],
      pageTitle: 'global.menu.gestaoFicheirosSepa'
    }
  }
};
