<div class="configs-erp-rh-module">
  <pl-navpill attrName="configs-erp-rh-module-nav">
    <pl-navpill-panel [id]="'geral'" [caption]="'global.text.general'">
      <div *plNavPillPanelContent>
        <dx-data-grid [cgDxDataGrid]="dataGridConfigRH" cgDxDataGridInstanceName="configRH" [dataSource]="configList">
          <div *dxTemplate="let cellTemplateInfo of 'cellTemplateValues'">
            <div class="configs-erp-rh-module-inline-edit">
              <pl-inline-edit
                *ngIf="cellTemplateInfo.data.name === 'pca.reportrecibosal'"
                [type]="cellTemplateInfo.data.cguid || cellTemplateInfo.data.kind"
                [(model)]="cellTemplateInfo.data.value"
                [onValidate]="fnOnValidateReport"
                [properties]="{readonly: cellTemplateInfo.data.readOnly, inlineMode: true, entity: {output: 'key'}}">
              </pl-inline-edit>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-navpill-panel>
  </pl-navpill>
</div>
