<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'global.text.error'"></h5>
</div>

<div class="modal-body">
  <div [translate]="errorMessage"></div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-danger" (click)="viewErrorsModal()"><i class="fa fa-fw fa-exclamation-triangle"></i>&nbsp;<span [translate]="'integracaoSalarios.verErrosModal'"></span></button>

  <button type="button" class="btn btn-primary" (click)="viewSimulatorModal()"><i class="fa fa-fw fa-magic"></i>&nbsp;<span [translate]="'integracaoSalarios.verSimuladorModal'"></span></button>

  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-close"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
