import type {Moment, MomentInput} from 'moment';

export type TPlDatepickerEvaluatedDates = MomentInput | Array<MomentInput> | IPlDatepickerEvaluatedDaysCollection | Set<MomentInput> | Map<MomentInput, boolean>;

export type TPlDatepickerEvaluateDateFn = (date: MomentInput, normalizedDate: string) => boolean;

export enum EPlDatepickerKind {
  Year = 'year',
  Month = 'month',
  Date = 'date'
}

export enum EPlDatepickerState {
  Decade = 'decade',
  Year = 'year',
  Month = 'month',
  Day = 'day'
}

export interface IPlDatepickerEvaluatedDaysCollection {
  [input: string]: boolean;
}

export interface IPlDatepickerDecade {
  startYear: number;
  endYear: number;
  decadeStr: string;
  current: boolean;
  selected: boolean;
  disabled: boolean;
}

export interface IPlDatepickerYear {
  year: number;
  yearStr: string;
  current: boolean;
  selected: boolean;
  disabled: boolean;
}

export interface IPlDatepickerMonth {
  month: number;
  monthStr: string;
  monthStrShort: string;
  year: number;
  current: boolean;
  selected: boolean;
  disabled: boolean;
}

export interface IPlDatepickerDay {
  date: Moment;
  day: number;
  month: number;
  year: number;
  current: boolean;
  selected: boolean;
  disabled: boolean;
  marked: boolean;
}
