import {IJsonDocTransfArmazem, IJsonDocTransfArmazemCab, IJsonTransfArmazem} from './jsonTransferenciasArmazens.entity.interface';

export interface ITransfArmazem extends IJsonTransfArmazem {
  _index?: number;
  nomeOrigemArtigo?: string;
  nomeOrigemArmazem?: string;
  nomeDestinoArtigo?: string;
  nomeDestinoArmazem?: string;
}

export interface IDocTransfArmazemCabWithData extends IJsonDocTransfArmazemCab {
  _doc?: IJsonDocTransfArmazem;
}

export const MODULE_NAME_TRANSFERENCIAS_ARMAZENS = 'transferenciasarmazens';
export const ENTITY_NAME_TRANSFERENCIAS_ARMAZENS = 'transferenciasarmazens';
