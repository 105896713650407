import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_MODELO22_FACTO_IMPEDIMENTO} from '../modelo22.datasource.interface';
import {IDataSource} from '../../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_MODELO22_FACTO_IMPEDIMENTO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_MODELO22_FACTO_IMPEDIMENTO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{value}} - {{name}}',
    output: '{{value}} - {{name}}'
  },
  data: [
    {value: 1, name: 'modelo22.datasources.factoimpedimento.falecimentoconjuge'},
    {value: 2, name: 'modelo22.datasources.factoimpedimento.falecimentooutro'},
    {value: 3, name: 'modelo22.datasources.factoimpedimento.doencagrave'},
    {value: 4, name: 'modelo22.datasources.factoimpedimento.sitparentalidade'}
  ]
});
