import moment from 'moment';
import {produce} from 'immer';
import type {Subscription} from 'rxjs';
import {AfterContentChecked, Component, ContentChildren, EventEmitter, Input, OnDestroy, Output, QueryList} from '@angular/core';
import {interpolate, isEmpty, isFunction, isNumber, isObject, isString, toInteger} from '../../common/utilities/utilities';
import type {IPlCookiesConsent, IPlCookiesConsentConfig} from './cookiesconsent.interface';
import type {IPlEditBaseComponentOptions} from '../../edit/component/edit.component.interface';
import type {IPlLocale, IPlLocaleCookiesConsent} from '../../common/locale/locales.interface';
import {PlCookiesConsentAboutDirective} from './cookiesconsent.about.directive';
import {PlCookiesConsentHeaderDirective} from './cookiesconsent.header.directive';
import {PlCookiesConsentService} from './cookiesconsent.service';
import {PlLocaleService} from '../../common/locale/locale.service';
import {PlTranslateService} from '../../translate/translate.service';
import {TValueOrPromise} from '../../common/utilities/utilities.interface';

const animationDuration = '250ms';

@Component({
  selector: 'pl-cookies-consent',
  templateUrl: './cookiesconsent.component.html',
  standalone: false
})
export class PlCookiesConsentComponent implements OnDestroy, AfterContentChecked {
  @Input() public detailsOpen: boolean;
  @Input() public consentFn: (value: IPlCookiesConsent) => TValueOrPromise<void>;
  @Output() public readonly detailsOpenChange: EventEmitter<boolean>;
  @Output() public readonly evtConsented: EventEmitter<IPlCookiesConsent>;

  public readonly animationDuration: string;
  public readonly propertiesNecessary: IPlEditBaseComponentOptions<boolean>;
  public locale: IPlLocaleCookiesConsent;
  public captionDeclaration: string;
  public captionAbout: string;
  public captionNecessary: string;
  public captionPreferences: string;
  public captionStatistics: string;
  public captionMarketing: string;
  public captionUnclassified: string;
  public cookies: IPlCookiesConsent;
  public captionFooter: string;
  public hideHeader: boolean;
  public hideNecessary: boolean;
  public hidePreferences: boolean;
  public hideStatistics: boolean;
  public hideMarketing: boolean;
  public hideUnclassified: boolean;
  public hideDetails: boolean;
  public hideFooter: boolean;
  public templateHeader: PlCookiesConsentHeaderDirective;
  public templateAbout: PlCookiesConsentAboutDirective;

  @ContentChildren(PlCookiesConsentHeaderDirective, {descendants: false}) private readonly _templateHeader: QueryList<PlCookiesConsentHeaderDirective>;
  @ContentChildren(PlCookiesConsentAboutDirective, {descendants: false}) private readonly _templateAbout: QueryList<PlCookiesConsentAboutDirective>;
  private readonly _subscriptionLocale: Subscription;
  private readonly _subscriptionConsentConfig: Subscription;
  private _config: IPlCookiesConsentConfig;

  constructor(
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plCookiesConsentService: PlCookiesConsentService
  ) {
    this.detailsOpenChange = new EventEmitter<boolean>();
    this.evtConsented = new EventEmitter<IPlCookiesConsent>();
    this.animationDuration = animationDuration;
    this.propertiesNecessary = {disabled: true};
    this.detailsOpen = false;
    this.consent = this.consent.bind(this);
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.locale = locale.plCookiesConsent;
      this.captionDeclaration = this._plTranslateService.translate(this.locale.declaration);
      this.captionAbout = this._plTranslateService.translate(this.locale.about);
      this.captionNecessary = this._plTranslateService.translate(this.locale.necessaryTitle);
      this.captionPreferences = this._plTranslateService.translate(this.locale.preferencesTitle);
      this.captionStatistics = this._plTranslateService.translate(this.locale.statisticsTitle);
      this.captionMarketing = this._plTranslateService.translate(this.locale.marketingTitle);
      this.captionUnclassified = this._plTranslateService.translate(this.locale.unclassifiedTitle);
      this._evaluateCaptionFooter();
    });
    this._subscriptionConsentConfig = this._plCookiesConsentService.config.subscribe((value: IPlCookiesConsentConfig) => {
      this._config = value;
      this.cookies = this._config.consent;
      this.hideHeader = this._config.hideHeader;
      this.hideNecessary = this._config.hideNecessary;
      this.hidePreferences = this._config.hidePreferences;
      this.hideStatistics = this._config.hideStatistics;
      this.hideMarketing = this._config.hideMarketing;
      this.hideUnclassified = this._config.hideUnclassified;
      this.hideDetails = this._config.hideDetails;
      this.hideFooter = this._config.hideFooter;
      this._evaluateCaptionFooter();
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
    this._subscriptionConsentConfig.unsubscribe();
  }

  public ngAfterContentChecked(): void {
    this.templateHeader = this._templateHeader.first;
    this.templateAbout = this._templateAbout.first;
  }

  public consent(): Promise<void> {
    this.cookies = produce(this.cookies, (cookies) => {
      cookies.consented = true;
    });
    let promise: TValueOrPromise<void>;
    if (isFunction(this.consentFn)) {
      promise = this.consentFn(this.cookies);
    }
    return Promise.resolve(promise).finally(() => {
      this.evtConsented.emit(this.cookies);
    });
  }

  public toggleDetails(): void {
    if (this.hideDetails !== true) {
      this.detailsOpen = !this.detailsOpen;
      this.detailsOpenChange.emit(this.detailsOpen);
    }
  }

  public prettyExpireOn(value: number | 'SESSION'): string {
    let prettyValue: string;
    let duration: number;
    if (isString(value)) {
      if (value === 'SESSION') {
        return this.locale.session;
      }
      duration = toInteger(value);
    } else if (isNumber(value)) {
      duration = value;
    } else {
      return this.locale.invalidExpireTime;
    }
    prettyValue = moment.duration(duration, 'milliseconds').humanize(false);
    if (prettyValue) {
      prettyValue = prettyValue.charAt(0).toUpperCase() + prettyValue.substring(1);
    }
    return prettyValue;
  }

  public setAcceptedPreferences(value: boolean): this {
    this.cookies = produce(this.cookies, (cookies) => {
      cookies.preferences.accepted = value;
    });
    return this;
  }

  public setAcceptedStatistics(value: boolean): this {
    this.cookies = produce(this.cookies, (cookies) => {
      cookies.statistics.accepted = value;
    });
    return this;
  }

  public setAcceptedMarketing(value: boolean): this {
    this.cookies = produce(this.cookies, (cookies) => {
      cookies.marketing.accepted = value;
    });
    return this;
  }

  private _evaluateCaptionFooter(): void {
    this.captionFooter = '';
    if (isObject(this.cookies) && !isEmpty(this.cookies.lastUpdatedOn)) {
      const formattedValue = moment(this.cookies.lastUpdatedOn).format('DD/MM/YYYY');
      this.captionFooter = interpolate(this._plTranslateService.translate(this.locale.footer))({value: formattedValue});
    }
  }
}
