<div class="entity-detail-form">
  <pl-form>
    <pl-group>
      <pl-group>
        <label>Conta:</label>
        <edit>
          <entity-autocomplete entity="pocs" placeholder="Pesquisar conta" [filter]="'tipo=0'" (modelChange)="onCCSelected($event)"></entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>
  </pl-form>
</div>

<br />

<cg-pdf-viewer [pdfSrc]="pdfUrl" [toolbarInstanceId]="instanceName" [hideToolbar]="true"></cg-pdf-viewer>
