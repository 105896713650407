import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isBoolean, isObject} from 'pl-comps-angular';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {IGestaoDGEMPSEventsDetailGroup} from '../../eventsdetail/gestaodgemps.eventsdetail.component.interface';

@Component({
  selector: 'gestao-dgemps-motivo-modal',
  templateUrl: './gestaodgemps.motivo.modal.component.html'
})
export class GestaoDGEMPSMotivoModalComponent extends CGModalComponent<string> implements OnInit {
  @Input() public motivo: string;
  @Input() public global?: boolean;
  @Input() public group?: IGestaoDGEMPSEventsDetailGroup;

  public modalTitle: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.global = false;
  }

  public ngOnInit(): void {
    if (!isBoolean(this.global)) {
      this.global = false;
    }
    if (this.global) {
      this.modalTitle = this._translateService.instant('gestaodgemps.motivomodal.titleGlobal');
    } else if (isObject(this.group)) {
      this.modalTitle = this._translateService.instant('gestaodgemps.motivomodal.titleGroup', {codEmp: this.group.codEmp, nomeEmp: this.group.nomeEmp});
    } else {
      this.modalTitle = this._translateService.instant('gestaodgemps.motivomodal.title');
    }
  }

  public close(): void | Promise<void> {
    return super.close(this.motivo);
  }
}
