<div class="cg-app-status-maintenance">
  <div class="toast cg-toast fade show toast-maintenance" role="alert" aria-live="assertive" aria-atomic="true" [class.show]="showToast" [class.mb-3]="showToast">
    <div class="toast-header gap-2">
      <i cgcIcon="fa-wrench"></i>
      <strong [translate]="!ended ? 'application.maintenance.title' : 'application.maintenance.endedTitle'"></strong>
      <div class="ms-auto" [ngSwitch]="ended">
        <i *ngSwitchCase="false" cgcIcon="fa-circle-o-notch fa-spin" style="font-size: 1.1rem"></i>
        <button *ngSwitchCase="true" type="button" class="btn-close" (click)="hide()"></button>
      </div>
    </div>

    <div class="toast-body">
      <div [translate]="!ended ? 'application.maintenance.description' : 'application.maintenance.endedDescription'"></div>
      <div *ngIf="!ended" class="mt-1 text-right">
        <small [translate]="'application.maintenance.lastChecked'" [translateParams]="{time: lastChecked | cgcTime: 'HH:mm'}"></small>
      </div>
    </div>

    <div class="cg-toast-backdrop" [class.bg-info]="!ended" [class.bg-success]="ended"></div>
  </div>
</div>
