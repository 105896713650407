import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {ProceSegSocialComponent} from './components/proceSegSocial.module.component';
import {MODULE_NAME_PROCE_SEG_SOCIAL} from './proceSegSocial.module.interface';

export const MODULE_PROCE_SEG_SOCIAL: IModuleDefinition = {
  name: MODULE_NAME_PROCE_SEG_SOCIAL,
  state: {
    url: `/${MODULE_NAME_PROCE_SEG_SOCIAL}`,
    component: ProceSegSocialComponent,
    data: {
      roles: [ROLE.RH],
      sidebarTitle: 'portals.sidebar.modules.proceSegSocial'
    }
  }
};
