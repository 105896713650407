import {Ng2StateDeclaration} from '@uirouter/angular';
import {MaintenanceStateComponent} from './maintenance.state.component';
import {STATE_NAME_MAINTENANCE} from './maintenance.state.interface';
import {STATE_NAME_SITE} from '../../../../common/site';

export const STATE_MAINTENANCE: Ng2StateDeclaration = {
  parent: STATE_NAME_SITE,
  name: STATE_NAME_MAINTENANCE,
  url: `/${STATE_NAME_MAINTENANCE}`,
  component: MaintenanceStateComponent,
  data: {
    pageTitle: 'maintenance.pageTitle',
    disableRecover: true
  }
};
