import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {IDecIvaPerioExtratoCampo} from '../../contabilidade.declaracaoIva.module.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../../docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../../../services/entity/entity.service.builder';
import {IJsonDocContabilidade} from '../../../../docscontabilidade/jsonDocContabilidade.interface';
import ArrayStore from 'devextreme/data/array_store';
import {IDevExpressDataGrid} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnCellClick} from '../../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';

@Component({
  selector: 'modal-declaracaoivaperiodica-extratocampo',
  templateUrl: './declaracaoIvaPeriodica.extratoCampo.modal.component.html'
})
export class DeclaracaoIvaPeriodicaExtratoCampoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public extratoCampoList: Array<IDecIvaPerioExtratoCampo>;
  @Input() public campo: string;
  @Input() public showColumnsBase: boolean;

  public dataGridDefinition: IDevExpressDataGrid<IDecIvaPerioExtratoCampo>;
  public empresaTemContabilidadeDigital: boolean;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
  }

  public ngOnInit(): void {
    this.empresaTemContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
    this.dataGridDefinition = {
      columns: [
        {dataField: 'periodo', dataType: 'string', caption: 'declaracaoiva.anexos.anexoR.modal.fields.periodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'declaracaoiva.anexos.anexoR.modal.fields.nDiario'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'declaracaoiva.anexos.anexoR.modal.fields.nDocInterno'},
        {dataField: 'nConta', dataType: 'string', caption: 'declaracaoiva.anexos.anexoR.modal.fields.nConta'},
        {dataField: 'nContrib', dataType: 'string', caption: 'declaracaoiva.anexos.anexoR.modal.fields.nContrib'},
        {dataField: 'valorDebito', dataType: 'double', caption: 'declaracaoiva.anexos.anexoR.modal.fields.valorDebito'},
        {dataField: 'valorCredito', dataType: 'double', caption: 'declaracaoiva.anexos.anexoR.modal.fields.valorCredito'},
        {dataField: 'codIva', dataType: 'number', caption: 'declaracaoiva.anexos.anexoR.modal.fields.codIva'},
        {dataField: 'areaRegionalStr', dataType: 'string', caption: 'declaracaoiva.anexos.anexoR.modal.fields.areaRegionalStr'},
        {dataField: 'nCampoOficial', dataType: 'string', caption: 'declaracaoiva.anexos.anexoR.modal.fields.nCampoOficial'},
        {dataField: 'contaOrigem', dataType: 'string', caption: 'declaracaoiva.anexos.anexoR.modal.fields.contaOrigem', visible: this.showColumnsBase, showInColumnChooser: this.showColumnsBase},
        {
          dataField: 'nomeContaOrigem',
          dataType: 'string',
          caption: 'declaracaoiva.anexos.anexoR.modal.fields.nomeContaOrigem',
          visible: this.showColumnsBase,
          showInColumnChooser: this.showColumnsBase
        },
        {dataField: 'valorBase', dataType: 'double', caption: 'declaracaoiva.anexos.anexoR.modal.fields.valorBase', visible: this.showColumnsBase, showInColumnChooser: this.showColumnsBase}
      ],
      dataSource: [],
      height: '60vh',
      paging: {enabled: false},
      pager: {visible: false},
      scrolling: {rowRenderingMode: 'virtual'},
      masterDetail: {enabled: true, template: 'masterDetailTemplate'},
      remoteOperations: false,
      summary: {
        totalItems: [
          {column: 'valorDebito', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double', skipEmptyValues: true},
          {column: 'valorCredito', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double', skipEmptyValues: true},
          {column: 'valorBase', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double', skipEmptyValues: true}
        ]
      }
    };
    this.dataGridDefinition.dataSource = new ArrayStore({
      key: 'extPocCabID',
      data: this.extratoCampoList
    });
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IDecIvaPerioExtratoCampo>): void {
    devExpressDataGridExpandDetailHandler(event, async () => {
      const response: HttpResponse<IJsonDocContabilidade> = await this._serviceDocsContabilidade.get({id: event.data.extPocCabID});
      event.data._docContabilidade = response.body;
      event.data._docContabDigital =
        this.empresaTemContabilidadeDigital && response.body.temDocDigital
          ? {
              extPocCabID: response.body.extPocCabID,
              periodo: response.body.periodo,
              nDiario: response.body.nDiario,
              nDocInterno: response.body.nDocInterno,
              dataDoc: response.body.dataDoc
            }
          : undefined;
    }).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }
}
