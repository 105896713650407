import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {BancosExtratoUiService} from '../../service/bancosExtrato.module.ui.service';
import {EEntidadeBancariaProvider} from '../../../../interfaces/jsonEntidadeBancaria.interface';

@Component({
  selector: 'bancos-extrato-module-unifiedpost-account-modal',
  templateUrl: './bancosExtrato.unifiedpost.account.modal.component.html'
})
export class BancosExtratoUnifiedPostAccountModalComponent extends CGModalComponent<void> {
  @Input() public state: string;

  public iban: string;
  public nomeTitular: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _bancosExtratoService: BancosExtratoUiService
  ) {
    super(_injector);
    this.iban = '';
    this.nomeTitular = '';
  }

  public async close(): Promise<void> {
    this.disableClose();
    try {
      await this._bancosExtratoService.newBankAccount({provider: EEntidadeBancariaProvider.UnifiedPost, state: this.state}, {iban: this.iban, nomeTitular: this.nomeTitular});
      this.enableClose();
      super.close();
    } catch (reason) {
      this.enableClose();
      this._logger.error(reason);
    }
  }
}
