<div class="modal-body">
  <div class="entity-detail-form">
    <pl-form>
      <pl-group>
        <label></label>
        <edit>
          <pl-edit type="radiogroup" [(model)]="model.type" [properties]="{groupItems: tipoGroupItems}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label>Imposto a entregar (campo 93):</label>
        <edit>
          <pl-edit type="number" [(model)]="model.ivaEntregar"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label>Imposto a recuperar (campo 94):</label>
        <edit>
          <pl-edit type="number" [model]="model.ivaRecuperar" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group *ngIf="model.type === 1">
        <label>Imposto pago relativo ao período: </label>
        <edit>
          <pl-edit type="number" [(model)]="model.ivaPago"></pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  <button type="button" class="btn btn-primary action-dismiss" (click)="close(model)"><i class="fa fa-fw fa-download"></i>&nbsp;<span [translate]="'documentosFiscais.buttons.get'"></span></button>
</div>
