import CustomStore from 'devextreme/data/custom_store';
import ArrayStore from 'devextreme/data/array_store';
import type {LoadOptions} from 'devextreme/data';
import type {Injector} from '@angular/core';
import {isArray, isFunction, isObject} from 'pl-comps-angular';
import type {IAsyncArrayStoreOptions} from './devexpress.datalayer.async.array.store.interface';
import type {IDevExpressDataGridLoadResult} from '../../../datagrid/devexpress.datagrid.interface';
import type {IDevExpressDataGridStoreChange} from '../../../datagrid/store/devexpress.datagrid.store.interface';

export class AsyncArrayStore<TItem extends object = object, TKey = unknown> extends CustomStore<TItem, TKey> {
  protected readonly _injector: Injector;

  private readonly _key: string | Array<string>;
  private _arrayStore: ArrayStore<TItem, TKey>;
  private _loadedItems: Array<TItem>;

  constructor(options: IAsyncArrayStoreOptions<TItem, TKey>) {
    if (!isObject(options)) {
      throw new TypeError('Options object must be provided to instantiate an `AsyncArrayStore`.');
    }
    if (!options.injector) {
      throw new TypeError('Injector object must be provided through the options constructor argument to instantiate an `AsyncArrayStore`.');
    }
    if (!isFunction(options.load)) {
      throw new TypeError('Load function must be provided through the options constructor argument to instantiate an `AsyncArrayStore`.');
    }
    super({
      ...options,
      key: options.key,
      loadMode: 'processed',
      load: (loadOptions: LoadOptions<TItem>): Promise<IDevExpressDataGridLoadResult<TItem>> => {
        return Promise.resolve(options.load(loadOptions)).then((result: Array<TItem> | IDevExpressDataGridLoadResult<TItem>) => {
          if (isArray(result)) {
            result = {
              data: result,
              totalCount: result.length
            };
          }
          this._loadedItems = result.data.slice();
          this._store.clear();
          this._store.push(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            result.data.map<any>((item: TItem) => {
              return {type: 'insert', data: item} satisfies IDevExpressDataGridStoreChange<TItem, TKey>;
            })
          );
          return result;
        });
      },
      byKey: (key: TKey) => this._byKey(key),
      insert: (item: TItem) => this._insert(item),
      update: (key: TKey, item: TItem) => this._update(key, item),
      remove: (key: TKey) => this._remove(key),
      totalCount: undefined
    });
    this._injector = options.injector;
    this._key = options.key;
    this._loadedItems = [];
  }

  public items(): Array<TItem> {
    return this._loadedItems.slice();
  }

  private _byKey(key: TKey): Promise<TItem> {
    return this._store.byKey(key);
  }

  private _insert(item: TItem): Promise<TItem> {
    return this._store.insert(item);
  }

  private _update(key: TKey, item: Partial<TItem>): Promise<TItem> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._store.update(key, <any>item);
  }

  private _remove(key: TKey): Promise<void> {
    return this._store.remove(key);
  }

  private get _store(): ArrayStore<TItem, TKey> {
    if (!this._arrayStore) {
      this._arrayStore = new ArrayStore<TItem, TKey>({
        key: this._key,
        data: []
      });
    }
    return this._arrayStore;
  }
}
