import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {DIGITS_INFO_TWO_DECIMALS} from '../../../../../common/data';
import {IDevExpressDataGridEventOnCellClick} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonRecibo, IJsonReciboCab} from '../../jsonRecibo.entity.interface';
import {IReciboCabWithData, IRecibosCabEntityService, TServiceHttpQueryResponseRecibos} from '../../recibos.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {TEntityListAfterRequestFn} from '../../../../components/entity/list/entity.list.component.interface';
import {IPlToolbarMenuItem} from 'pl-comps-angular';
import {DocFasNumsComunicacaoLoginModalComponent} from '../../../../modules/docfasnumscomunicacaologin/docFasNumsComunicacaoLogin.modal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'recibos-list',
  templateUrl: './recibos.entity.list.component.html'
})
export class RecibosListComponent extends ModuloEntityListComponent<IJsonReciboCab, IRecibosCabEntityService> implements OnInit {
  public readonly digitsInfoFloat: string;
  public sum: number;

  private readonly _btnLoginWebServiceAT: IPlToolbarMenuItem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.digitsInfoFloat = DIGITS_INFO_TWO_DECIMALS;
    this.sum = 0;

    this._btnLoginWebServiceAT = {
      order: 1,
      caption: 'docfas.loginAT',
      iconLeft: '<i class="fa fa-fw fa-key" aria-hidden="true"></i>',
      click: () => this._configLoginAt()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnConfig.visible = true;
    this.btnConfig.type = 'dropdown';
    this.btnConfig.menu = [this._btnLoginWebServiceAT];
  }

  public readonly fnAfterRequest: TEntityListAfterRequestFn<IJsonReciboCab> = (response: TServiceHttpQueryResponseRecibos<IJsonReciboCab>) => {
    this._afterRequest(response);
  };

  public onDetail({data}: IDevExpressDataGridEventOnCellClick<IReciboCabWithData>): Promise<void> {
    return this.service.get<IJsonRecibo>({id: data.extPocCabID, params: {nconta: data.nConta}}).then((result: HttpResponse<IJsonRecibo>) => {
      data._recibo = result.body;
    });
  }

  public onDataGridColumnsPreparing(fields: Array<IDevExpressDataGridColumn>): void {
    for (const field of fields) {
      if (field.dataField === 'nDocAsStr') {
        field.filterOperations = ['=', 'contains'];
        break;
      }
    }
  }

  private _afterRequest(response: TServiceHttpQueryResponseRecibos<IJsonReciboCab>): void {
    this.sum = response.body.sum;
  }

  private _configLoginAt(): Promise<void> {
    const instance = this._cgModalService.showVanilla(DocFasNumsComunicacaoLoginModalComponent, {size: 'sm'});
    return instance.result;
  }
}
