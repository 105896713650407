import {JSONSchema} from '@ngx-pwa/local-storage';

export const SCHEMA_BOOLEAN: JSONSchema = Object.freeze<JSONSchema>({
  type: 'boolean'
});

export const SCHEMA_NUMBER: JSONSchema = Object.freeze<JSONSchema>({
  type: 'number'
});

export const SCHEMA_INTEGER: JSONSchema = Object.freeze<JSONSchema>({
  type: 'integer'
});

export const SCHEMA_STRING: JSONSchema = Object.freeze<JSONSchema>({
  type: 'string'
});

export const SCHEMA_STRING_ARRAY: JSONSchema = Object.freeze<JSONSchema>({
  type: 'array',
  items: SCHEMA_STRING
});

export const SCHEMA_NUMBER_ARRAY: JSONSchema = Object.freeze<JSONSchema>({
  type: 'array',
  items: SCHEMA_NUMBER
});
