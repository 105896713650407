<pl-form class="trdocs-linhas-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="closeModal()" [disabled]="closeDisabled"></button>
    <h4 class="modal-title" [translate]="'trdoc.modal.linhas.title'"></h4>
  </div>

  <div class="modal-body">
    <pl-nav-wizard
      #navWizard="navWizard"
      [definition]="navWizardDefinition"
      [callback]="navWizardCallback"
      [properties]="propertiesNavWizard"
      [hideFooter]="true"
      (evtStepChange)="stepChanged($event)"
      [beforeStepChange]="fnBeforeChangedStep"
      [onFinalize]="finalize">
      <pl-nav-wizard-step [stepId]="stepsNavTrDocs.Linhas" caption="trdoc.modal.linhas.steps.linhasDoc" icon="fa fa-list fa-fw" [hidePrevious]="true">
        <div *plNavWizardStepContent>
          <div class="alert alert-sm alert-warning" *ngIf="doc.cab.transformado">
            <i class="fa fa-fw fa-info-circle" aria-hidden="true"></i><span [translate]="'trdoc.modal.linhas.info.transformado'"></span>
          </div>

          <div class="alert alert-sm alert-info" *ngIf="!doc.cab.transformado && temLinhasTransformadas">
            <i class="fa fa-fw fa-info-circle" aria-hidden="true"></i><span [translate]="'trdoc.modal.linhas.info.linhasTransformadas'"></span>
          </div>

          <pl-group *ngIf="doc.cab.transformado">
            <edit>
              <pl-edit
                type="boolean"
                attrName="trdocPretendeContinuar"
                [model]="trdocPretendeContinuar"
                (modelChange)="onChangeTrdocPretendeContinuar($event)"
                [properties]="{label: 'trdoc.modal.linhas.label.continuarTransformacao'}">
              </pl-edit>
            </edit>
          </pl-group>

          <div class="trdocs-linhas-grid-container" [ngClass]="{'disabled-selection': !trdocPretendeContinuar}">
            <dx-data-grid
              [cgDxDataGrid]="dataGridLinhasDefinition"
              cgDxDataGridInstanceName="TrDocLinhasTable"
              [dataSource]="linhasATransformar"
              (onInitialized)="onInitialized($event)"
              (onRowPrepared)="onDataGridRowPrepared($event)"
              (onContentReady)="onContentReady()">
              <div *dxTemplate="let item of 'cellTemplateLinhaTransformada'">
                <div class="d-flex justify-content-center">
                  {{ item.data.nArtigo }} &nbsp;
                  <div *ngIf="item.data.transformada">
                    <pl-tooltip [config]="{text: 'trdoc.legend.linhaTransformada'}">
                      <i *plTooltipContent class="fa fa-info-circle icon-warning" aria-hidden="true"></i>
                    </pl-tooltip>
                  </div>
                </div>
              </div>
            </dx-data-grid>
          </div>
          <br />

          <div class="bancos-extrato-module-legend" *ngIf="temLinhasTransformadas">
            <cg-table-legend [legend]="tableLegend"></cg-table-legend>
          </div>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step [stepId]="stepsNavTrDocs.Identificacao" caption="trdoc.modal.linhas.steps.ident" icon="fa-file-text-o">
        <div *plNavWizardStepContent>
          <pl-group>
            <pl-group>
              <label [translate]="'trdocconfig.fields.nDocfaDestino'"></label>
              <edit>
                <entity-autocomplete
                  entity="docfas"
                  attrName="nDocfaDestino"
                  [model]="docFa"
                  (modelChange)="onDocFaChanged($event)"
                  [(selectedKey)]="proceTrDoc.config.nDocfaDestino"
                  [output]="docfasOutput"
                  outputKey="nDocFa"
                  outputDescription="nome"
                  plAutoFocus>
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'trdocconfig.fields.nNumerDestino'"></label>
              <edit>
                <entity-autocomplete
                  attrName="nNumerDestino"
                  entity="docfasnums"
                  [filter]="docfaNumFilter"
                  [model]="proceTrDoc.config.nNumerDestino"
                  [output]="'nNumer'"
                  [outputKey]="'nNumer'"
                  [(selectedKey)]="proceTrDoc.config.nNumerDestino">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="clifoDescription"></label>
              <edit>
                <pl-edit attrName="clifo" type="text" [(model)]="clifo" [properties]="{readonly: true}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'trdocconfig.fields.dataDoc'"></label>
              <edit>
                <pl-edit-datepicker attrName="dataDoc" [(model)]="proceTrDoc.config.dataDoc"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label>
                <span [translate]="'trdocconfig.fields.nFactFornec'"></span>
                <pl-tooltip [config]="{text: 'trdocconfig.tooltips.nDocExterno', placement: ['right', 'bottom'], container: 'body'}">
                  <i *plTooltipContent class="fa fa-info-circle text-info ms-1" aria-hidden="true"></i>
                </pl-tooltip>
              </label>
              <edit>
                <pl-edit attrName="nFactFornec" type="text" [(model)]="proceTrDoc.config.nFactFornec" [properties]="{placeholder: 'Ex: FT 1 1/100'}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label>
                <span [translate]="'trdocconfig.fields.dataDocExterno'"></span>
                <pl-tooltip [config]="{text: 'trdocconfig.tooltips.dataDocExterno', placement: ['right', 'bottom'], container: 'body'}">
                  <i *plTooltipContent class="fa fa-info-circle text-info ms-1" aria-hidden="true"></i>
                </pl-tooltip>
              </label>
              <edit>
                <pl-edit-datepicker attrName="dataDocExterno" [(model)]="proceTrDoc.config.dataDocExterno"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <label [translate]="'trdocconfig.fields.referenciaTextoBase'"></label>
            <edit>
              <pl-edit-textarea attrName="referenciaTextoBase" [(model)]="proceTrDoc.config.referenciaTextoBase"></pl-edit-textarea>
            </edit>
            <actions>
              <div ngbDropdown>
                <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                  <i><span [translate]="'trdocconfig.actions.variables'"></span></i>
                </button>

                <div ngbDropdownMenu>
                  <button type="button" class="dropdown-item" [translate]="'trdocconfig.actions.variaveis.nomeDoc'" (click)="applyVariable('@NomeDoc')"></button>
                  <button type="button" class="dropdown-item" [translate]="'trdocconfig.actions.variaveis.nDoc'" (click)="applyVariable('@NDoc')"></button>
                  <button type="button" class="dropdown-item" [translate]="'trdocconfig.actions.variaveis.dtDoc'" (click)="applyVariable('@DtDoc')"></button>
                  <button type="button" class="dropdown-item" [translate]="'trdocconfig.actions.variaveis.docExt'" (click)="applyVariable('@DocExt')"></button>
                  <button type="button" class="dropdown-item" [translate]="'trdocconfig.actions.variaveis.processo'" (click)="applyVariable('@Processo')"></button>
                  <button type="button" class="dropdown-item" [translate]="'trdocconfig.actions.variaveis.contrato'" (click)="applyVariable('@Contrato')"></button>
                  <button type="button" class="dropdown-item" [translate]="'trdocconfig.actions.variaveis.obs1'" (click)="applyVariable('@OBS1')"></button>
                </div>
              </div>
            </actions>
          </pl-group>
          <pl-group>
            <pl-group>
              <label>
                <span [translate]="'trdoc.modal.linhas.label.aposTransformarEncerrarDoc'"></span>
                <pl-tooltip [config]="{text: 'trdocconfig.tooltips.aposTransformarEncerrarDocsFinalizar', placement: ['right', 'bottom'], container: 'body'}">
                  <i *plTooltipContent class="fa fa-info-circle text-info ms-1" aria-hidden="true"></i>
                </pl-tooltip>
              </label>
            </pl-group>
            <pl-group>
              <pl-group>
                <edit>
                  <pl-edit type="checkbox" [(model)]="proceTrDoc.config.encerraDocumentoOrigem" [properties]="{disabled: doc.cab.terminado, label: 'trdoc.modal.linhas.label.origem'}"></pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <edit>
                  <pl-edit type="checkbox" [(model)]="proceTrDoc.config.encerraDocumentoDestino" [properties]="{label: 'trdoc.modal.linhas.label.destino'}"></pl-edit>
                </edit>
              </pl-group>
              <pl-group></pl-group>
            </pl-group>
            <pl-group></pl-group>
          </pl-group>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step [stepId]="stepsNavTrDocs.Carga" caption="trdoc.modal.linhas.steps.carga" icon="fas fa-cubes" *ngIf="showIdentificaCarga">
        <div *plNavWizardStepContent>
          <pl-group>
            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.dataInicTran'"></label>
              <edit>
                <pl-edit-datepicker attrName="iniTransp" [(model)]="proceTrDoc.documentos[0].carga.dataInicTran" plAutoFocus></pl-edit-datepicker>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.horaInicTran'"></label>
              <edit>
                <pl-edit-timepicker attrName="horaInicTran" [(model)]="proceTrDoc.documentos[0].carga.horaInicTran"></pl-edit-timepicker>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.morada'"></label>
              <edit>
                <pl-edit type="text" [(model)]="proceTrDoc.documentos[0].carga.rua"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.codPostal'"></label>
              <edit>
                <entity-autocomplete
                  entity="codpostais"
                  [model]="proceTrDoc.documentos[0].carga"
                  (modelChange)="codPostaisChanged($event, 'carga')"
                  [fieldsMap]="{codPostal: 'cPost'}"
                  [outputKey]="'codPostal'">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.localidade'"></label>
              <edit>
                <pl-edit type="text" [(model)]="proceTrDoc.documentos[0].carga.localidade"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.codPais'"></label>
              <edit>
                <entity-autocomplete
                  entity="paises"
                  [model]="proceTrDoc.documentos[0].carga"
                  (selectedKeyChange)="proceTrDoc.documentos[0].carga.codPais = $event"
                  (evtSelectedDescriptionChanged)="proceTrDoc.documentos[0].carga.nomePais = $event"
                  [output]="paisesOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step [stepId]="stepsNavTrDocs.Descarga" caption="trdoc.modal.linhas.steps.descarga" icon="fas fa-truck" *ngIf="showIdentificaCarga">
        <div *plNavWizardStepContent>
          <pl-group class="cab-descarga-options-edit">
            <label [translate]="'docscomerciais.doc.moradaDescarga.moralId'"></label>
            <edit>
              <entity-autocomplete
                attrName="contaMoradaDescarga"
                entity="morals"
                [model]="proceTrDoc.documentos[0].descarga"
                (selectedKeyChange)="proceTrDoc.documentos[0].descarga.nConta = $event"
                (evtSelectedDescriptionChanged)="proceTrDoc.documentos[0].descarga.nome = $event"
                [filter]="nContaMoralFilter"
                [fieldsMap]="{nome: 'moralId'}"
                [outputKey]="'nConta'"
                output="nConta"
                outputDescription="nome"
                plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.morada'"></label>
              <edit>
                <pl-edit type="text" [(model)]="proceTrDoc.documentos[0].descarga.rua"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.codPostal'"></label>
              <edit>
                <entity-autocomplete
                  entity="codpostais"
                  [model]="proceTrDoc.documentos[0].descarga"
                  (modelChange)="codPostaisChanged($event, 'descarga')"
                  [fieldsMap]="{codPostal: 'cPost'}"
                  [outputKey]="'codPostal'"></entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.localidade'"></label>
              <edit>
                <pl-edit type="text" [(model)]="proceTrDoc.documentos[0].descarga.localidade"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.codPais'"></label>
              <edit>
                <entity-autocomplete
                  entity="paises"
                  [model]="proceTrDoc.documentos[0].descarga"
                  (selectedKeyChange)="proceTrDoc.documentos[0].descarga.codPais = $event"
                  (evtSelectedDescriptionChanged)="proceTrDoc.documentos[0].descarga.nomePais = $event"
                  [output]="paisesOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group *ngIf="showObservacoes">
            <label [translate]="'encomendas.modal.processamento.fields.observacoes'"></label>
            <edit>
              <pl-edit-textarea attrName="observacoes" [(model)]="proceTrDoc.documentos[0].descarga.observacoes" [properties]="{validators: {maxlength: {value: 50}}}"></pl-edit-textarea>
            </edit>
          </pl-group>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step [stepId]="stepsNavTrDocs.Lotes" caption="trdoc.modal.linhas.steps.lotes" icon="fas fa-list-ul" *ngIf="showLotes">
        <div *plNavWizardStepContent>
          <div>
            <dx-data-grid
              [cgDxDataGrid]="dataGridLinhasLotesDefinition"
              [dataSource]="dataGridLinhasLotesDefinition.dataSource"
              cgDxDataGridInstanceName="gridProcessamentoLinhasLotes"
              (onInitialized)="onGridLinhasLotesInitialized($event)"
              (onContentReady)="onGridLinhasLotesContentReady()"
              (onSelectionChanged)="onGridLinhasLotesSelectionChanged($event)">
              <div *dxTemplate="let item of 'templateBtnToolbar'">
                <pl-button klass="btn-sm btn-warning action-preenche-auto-lotes" (evtClicked)="preencheAutomaticamenteLotes()" [disabled]="!showLotes">
                  <i class="fa fa-list-ul"></i>&nbsp;<span [translate]="'trdoc.modal.linhas.btn.preencherAuto'"></span>
                </pl-button>
              </div>
            </dx-data-grid>
            <hr />
            <dx-data-grid
              #gridLotes
              [cgDxDataGrid]="dataGridLotesDefinition"
              [dataSource]="dataGridLotesDefinition.dataSource"
              cgDxDataGridInstanceName="gridProcessamentoLotes"
              (onInitialized)="onGridLotesInitialized($event)"
              (onContentReady)="onGridLotesContentReady()"
              (onFocusedCellChanged)="onGridLotesFocusedCellChanged($event)">
            </dx-data-grid>
          </div>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step [stepId]="stepsNavTrDocs.Resumo" caption="trdoc.modal.linhas.steps.resumo" icon="fas fa-eye" *ngIf="showResumo">
        <div *plNavWizardStepContent>
          <documento-faturacao [doc]="docComercial" [actionPdf]="fnGetPdf"></documento-faturacao>
        </div>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </div>

  <div class="modal-footer" plNavigation>
    <pl-nav-wizard-actions [navWizardInstance]="navWizard"></pl-nav-wizard-actions>

    <pl-button klass="btn-sm btn-warning action-edit-doc" *ngIf="showEditDocBtn" (evtClicked)="editDoc()">
      <i class="fa fa-pencil"></i>&nbsp;<span [translate]="'trdoc.modal.linhas.btn.editDoc'"></span>
    </pl-button>

    <button type="button" class="btn btn-sm btn-light action-dismiss" (click)="closeModal()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</pl-form>
