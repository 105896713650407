import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ModuloComponent} from '../../../../components/module/module.component';
import {PortalClientAccountService} from '../../../../services/portalclientaccount/portalclientaccount.service';
import moment from 'moment';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {EReport} from '../../../../entities/reports/reports.interface';
import {IReportInstance} from '../../../../components/reports/input/reports.input.component.interface';
import {IRhMapasSindicatosFilters} from '../rhMapasSindicatos.module.interface';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {IPlToolbarItem, KEYCODES} from 'pl-comps-angular';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';

const DE_SINDICATO_DEFAULT = 81;
const ATE_SINDICATO_DEFAULT = 89;
const BTN_PROCESS_PDF = 'processarPDf';

@Component({
  selector: 'rh-mapas-sindicatos',
  templateUrl: './rhMapasSindicatos.module.component.html'
})
export class RhMapasSindicatosModuleComponent extends ModuloComponent implements OnInit {
  public pdfUrl: string;
  public filters: IRhMapasSindicatosFilters;
  public report: IJsonReport;

  private _reportInstance: IReportInstance;
  private readonly _btnProcessPdf: IPlToolbarItem;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _portalClientAccountsFactory: PortalClientAccountService
  ) {
    super(_injector);

    this.filters = {
      dataDe: moment().startOf('month'),
      dataAte: moment().endOf('month'),
      deSindicato: DE_SINDICATO_DEFAULT,
      ateSindicato: ATE_SINDICATO_DEFAULT
    };

    this._btnProcessPdf = {
      id: BTN_PROCESS_PDF,
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-file-pdf-o fa-fw"></i>',
      class: 'btn-success',
      caption: 'global.btn.processPDF',
      click: () => {
        this._previsualizar();
      }
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.toolbar.addButton(this._btnProcessPdf);

    this._loadReport();
  }

  public readonly fnKeydownProcessar = (value: string, event: KeyboardEvent): void => {
    this._keydownProcessar(event);
  };

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _keydownProcessar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      this.toolbar.focusItem(BTN_PROCESS_PDF);
    }
  }

  private _previsualizar(): void {
    this._portalClientAccountsFactory
      .getRHMapaSindicatosUrl(this.filters.deSindicato, this.filters.ateSindicato, this.filters.dataDe, this.filters.dataAte, this.report.name)
      .subscribe((url: string) => {
        this.pdfUrl = url;
        this._cardPanel.collapse();
      });
  }

  private _loadReport(): Promise<void> {
    this._reportInstance = this._reportsRegistryService.get(EReport.LSindic);
    return this._reportInstance.query().then((reports: Array<IJsonReport>) => {
      if (reports.length) {
        this.report = reports[0];
      }
    });
  }
}
