import TreeList, {Column, DataStructure} from 'devextreme/ui/tree_list';
import {Cell, CellValue, Column as ExcelColumn, Row, Worksheet} from 'exceljs';
import {EAnalisadorTesPeriodicidadeAnalise, IAnalisadorTesRecord, IAnalisadorTesRecordWithItems} from '../analisadorTes.module.interface';
import {isDefinedNotNull} from 'pl-comps-angular';
import moment from 'moment';

const MIN_COLUMN_WIDTH = 10;
const PIXELS_PER_INDENT = 10;
const PIXELS_PER_EXCEL_WIDTH_UNIT = 8;
const CELL_PADDING = 2;
const PROP_NAME_DATE_FORMAT = 'DDMMYYYY';
const DIARIA_EXCEL_DATEFORMAT = 'DD MMM YYYY';
const MENSAL_EXCEL_DATEFORMAT = 'MMM YYYY';

interface ITreeListExcelPropsEmpresa {
  nEmpresa: string;
  nomeEmpresa: string;
}

interface ITreeListExcelProps {
  component: TreeList;
  worksheet: Worksheet;
  periodoAnalise: EAnalisadorTesPeriodicidadeAnalise;
  saldoInicialRow: IAnalisadorTesRecord;
  empresa: ITreeListExcelPropsEmpresa;
}

class TreeListHelpers {
  private readonly _component: TreeList;

  private readonly _worksheet: Worksheet;

  private readonly _columns: Array<Column>;

  private readonly _dateColumns: Array<Column>;

  private readonly _lookupColumns: Array<Column>;

  private readonly _rootValue: unknown;

  private readonly _parentIdExpr: string;

  private readonly _keyExpr: string;

  private readonly _dataStructure: DataStructure;

  private readonly _periodoAnalise: EAnalisadorTesPeriodicidadeAnalise;

  private readonly _saldoInicialRow: IAnalisadorTesRecord;

  private readonly _empresa: ITreeListExcelPropsEmpresa;

  constructor(component: TreeList, worksheet: Worksheet, periodoAnalise: EAnalisadorTesPeriodicidadeAnalise, saldoInicialRow: IAnalisadorTesRecord, empresa: ITreeListExcelPropsEmpresa) {
    this._empresa = empresa;
    this._component = component;
    this._worksheet = worksheet;
    this._periodoAnalise = periodoAnalise;
    this._saldoInicialRow = saldoInicialRow;
    this._columns = this._getComponentColumns();
    this._dateColumns = this._columns.filter((column) => column.dataType === 'date' || column.dataType === 'datetime');
    this._lookupColumns = this._columns.filter((column) => column.lookup !== undefined);

    this._rootValue = this._component.option('rootValue');
    this._parentIdExpr = <string>this._component.option('parentIdExpr');
    this._keyExpr = <string>(this._component.option('keyExpr') ?? this._component.getDataSource().key());
    this._dataStructure = this._component.option('dataStructure');

    // bug: check ExcelJS's GitHub issues #1352 & #2218
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const properties: any = this._worksheet.properties;
    properties.outlineProperties = {
      summaryBelow: false,
      summaryRight: false
    };
  }

  public getData(): Promise<Array<IAnalisadorTesRecordWithItems>> {
    return this._component
      .getDataSource()
      .store()
      .load()
      .then((result: Array<IAnalisadorTesRecord>) => this._processData(result));
  }

  public export(): Promise<void> {
    this._component.beginCustomLoading('Exporting to Excel...');

    return this.getData().then((rows: Array<IAnalisadorTesRecordWithItems>) => {
      this._generateColumns();
      this._addSaldoInicial(rows);
      this._exportRows(rows);
      this._addEmpresa();
      this._autoFitColumnsWidth();
      this._component.endCustomLoading();
    });
  }

  private _processData(data: Array<IAnalisadorTesRecord>): Array<IAnalisadorTesRecordWithItems> {
    let rows = data;
    if (this._dataStructure === 'plain') {
      rows = this._convertToHierarchical(rows);
    }
    return this._depthDecorator(rows);
  }

  private _depthDecorator(data: Array<IAnalisadorTesRecord> | Array<IAnalisadorTesRecordWithItems>, depth = 0): Array<IAnalisadorTesRecordWithItems> {
    const result: Array<IAnalisadorTesRecordWithItems> = [];

    data.forEach((node: IAnalisadorTesRecord | IAnalisadorTesRecordWithItems) => {
      result.push({
        ...node,
        depth,
        items: this._depthDecorator('items' in node ? node.items : [], depth + 1)
      });
    });

    return result;
  }

  private _convertToHierarchical(data: Array<IAnalisadorTesRecord> | Array<IAnalisadorTesRecordWithItems>, id = this._rootValue): Array<IAnalisadorTesRecordWithItems> {
    const result: Array<IAnalisadorTesRecordWithItems> = [];
    const roots: Array<IAnalisadorTesRecord | IAnalisadorTesRecordWithItems> = [];

    data.forEach((node) => {
      if (node[this._parentIdExpr] === id) {
        roots.push(node);
      }
    });

    roots.forEach((node) => {
      result.push({
        ...node,
        items: this._convertToHierarchical(data, node[this._keyExpr]),
        depth: 0
      });
    });

    return result;
  }

  private _exportRows(rows: Array<IAnalisadorTesRecordWithItems>): void {
    rows.forEach((row: IAnalisadorTesRecordWithItems) => {
      this._exportRow(row);

      if (this._hasChildren(row)) {
        this._exportRows(<Array<IAnalisadorTesRecordWithItems>>row.items);
      }
    });
  }

  private _exportRow(row: IAnalisadorTesRecordWithItems): void {
    this._formatDates(row);
    this._assignLookupText(row);

    const insertedRow: Row = this._worksheet.addRow(row);
    insertedRow.outlineLevel = row.depth;
    this._worksheet.getCell(`A${insertedRow.number}`).alignment = {
      indent: row.depth * 2
    };
  }

  private _formatDates(row: IAnalisadorTesRecordWithItems): void {
    this._dateColumns.forEach((column) => {
      if (column.dataField !== undefined) {
        row[column.dataField] = new Date(row[column.dataField]);
      }
    });
  }

  private _assignLookupText(row: IAnalisadorTesRecordWithItems): void {
    this._lookupColumns.forEach((column) => {
      if (column.dataField && column.lookup?.calculateCellValue) {
        row[column.dataField] = column.lookup.calculateCellValue(row[column.dataField]);
      }
    });
  }

  private _generateColumns(): void {
    this._worksheet.columns = this._columns.map(({caption, dataField}: Column) => ({
      header: caption,
      key: dataField
    }));
  }

  private _hasChildren(row: IAnalisadorTesRecordWithItems): boolean {
    return row.items && row.items.length > 0;
  }

  private _autoFitColumnsWidth(): void {
    this._worksheet.columns.forEach((column: Partial<ExcelColumn>) => {
      let maxLength: number = MIN_COLUMN_WIDTH;

      // first column
      if (column.number === 1 && column.eachCell !== undefined) {
        column.eachCell((cell: Cell) => {
          const indent: number = cell.alignment?.indent ? cell.alignment.indent * (PIXELS_PER_INDENT / PIXELS_PER_EXCEL_WIDTH_UNIT) : 0;

          const valueLength = this._getValueLength(cell.value);

          if (indent + valueLength > maxLength) {
            maxLength = indent + valueLength;
          }
        });
      }

      // other columns
      if (column.number !== 1) {
        column.values?.forEach((value: CellValue) => {
          if (value === null || value === undefined) {
            return;
          }
          const valueLength = this._getValueLength(value);

          if (valueLength > maxLength) {
            maxLength = valueLength;
          }
        });
      }

      column.width = maxLength + CELL_PADDING;
    });
  }

  private _getValueLength(value: CellValue): number {
    let length = 0;

    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
      length = value.toString().length;
    }

    if (value instanceof Date) {
      length = value.toLocaleDateString().length;
    }

    return length;
  }

  private _getComponentColumns(): Array<Column> {
    const buildedCols: Array<Column> = [];

    this._component.getVisibleColumns().forEach((col) => {
      if (isDefinedNotNull(col.dataField)) {
        let caption: string;
        if (['nomeRubrica', 'descicaoRubrica'].includes(col.dataField)) {
          caption = col.dataField === 'nomeRubrica' ? 'Rúbrica' : 'Descrição';
        } else if (this._periodoAnalise === EAnalisadorTesPeriodicidadeAnalise.DIARIA) {
          caption = moment(col.dataField, PROP_NAME_DATE_FORMAT).format(DIARIA_EXCEL_DATEFORMAT);
        } else {
          const dateRangeArray = col.dataField.split('_');
          const startDate = moment(dateRangeArray[0], PROP_NAME_DATE_FORMAT);
          const endDate = moment(dateRangeArray[1], PROP_NAME_DATE_FORMAT);
          if (this._periodoAnalise === EAnalisadorTesPeriodicidadeAnalise.SEMANAL) {
            caption = `${startDate.format(DIARIA_EXCEL_DATEFORMAT)} - ${endDate.format(DIARIA_EXCEL_DATEFORMAT)}`;
          } else {
            caption = startDate.format(MENSAL_EXCEL_DATEFORMAT);
          }
        }
        buildedCols.push({...col, caption: caption});
      }
    });

    return buildedCols;
  }

  private _addSaldoInicial(rows: Array<IAnalisadorTesRecordWithItems>): void {
    if (isDefinedNotNull(this._saldoInicialRow)) {
      rows.unshift({...this._saldoInicialRow, depth: 0, items: [], nomeRubrica: '', descicaoRubrica: 'Saldo Inicial'});
    }
  }

  private _addEmpresa(): void {
    this._worksheet.insertRow(1, [`Empresa: ${this._empresa.nEmpresa} - ${this._empresa.nomeEmpresa}`]);
    this._worksheet.mergeCells('A1', 'B1');
    this._worksheet.getCell('A1').style = {
      font: {
        color: {argb: '3498db'}
      }
    };
  }
}

export class AnalisadorTesExcelExporter {
  public static exportTreeList({component, worksheet, periodoAnalise, saldoInicialRow, empresa}: ITreeListExcelProps): Promise<void> {
    const helpers = new TreeListHelpers(component, worksheet, periodoAnalise, saldoInicialRow, empresa);
    return helpers.export();
  }
}
