import {Component, Injector, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {isDefinedNotNull} from 'pl-comps-angular';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {ETipoRegime, IJsonClasseCaixa} from '../../jsonClasseCaixa.entity.interface';

@Component({
  selector: 'classes-caixa-edit',
  templateUrl: './classesCaixa.entity.edit.component.html'
})
export class ClassesCaixaEditComponent extends ModuloEntityDetailComponent<IJsonClasseCaixa> implements OnInit {
  public formInstance: UntypedFormGroup;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!isDefinedNotNull(this.model)) {
      this.model = {
        nclcx: '',
        designacaoBreve: '',
        designacaoCompleta: '',
        naoProcSubNatal: false,
        naoProcSubFerias: false,
        tipoRegime: ETipoRegime.Empregados,
        ncrss: undefined,
        nomeCRSS: '',
        percentPATR: 0,
        percentEMPR: 0,
        codIntegracao: '',
        naoVaiSegSocial: false,
        temLimiteMaximo: false,
        limMaxNSalariosMin: 0,
        temLimiteMinimo: false,
        limMinNSalariosMin: 0,
        descricao: ''
      };
    }
  }
}
