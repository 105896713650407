import {IJsonListaExcl, IJsonRHFolFerHeader} from './jsonRHFolfer.module.interface';
import {TDate} from '../../../../common/dates';

export const MODULE_NAME_RH_FOLFER = 'rhfolfer';

export type TRHFolFerComplementosExcepcoesFrom = 'abono' | 'desconto' | 'processamento';

export interface IRHSalToTrfHeader {
  codEmpDe: number;
  codEmpAte: number;
  dataProcesDe: TDate;
  dataProcesAte: TDate;
  bancoDe: string;
  bancoAte: string;
  fRemuDe: string;
  fRemuAte: string;
}

export interface IRHFolFerHeader extends IJsonRHFolFerHeader {
  listaExclAbonoExcluidos: Array<IJsonListaExcl>;
  listaExclDescontoExcluidos: Array<IJsonListaExcl>;
}
