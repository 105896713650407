import type {EventEmitter} from '@angular/core';
import type {AbstractControl} from '@angular/forms';
import type {IPlEditBaseComponent, IPlEditComponent} from '../../component/edit.component.interface';
import type {IPlInlineEditOptions} from './inline.edit.component.interface';

export abstract class PlInlineEditToken<T> implements IPlEditComponent<T> {
  public model: T;
  public attrName: string;
  public inputClass: string;
  public properties: IPlInlineEditOptions | any;
  public modelChange: EventEmitter<T>;
  public options: IPlInlineEditOptions;

  public abstract updateComponent(properties: object): void;

  public abstract render(value: T): void;

  public abstract updateValue?(value: T): void;

  public abstract valueChanged(value: any, field: string): void;

  public abstract setHasError(hasError: boolean): void;

  public abstract addComponent(component: IPlEditBaseComponent<any>): void;

  public abstract removeComponent(): void;

  public abstract addControl(name: string, control: AbstractControl): void;

  public abstract removeControl(name: string): void;
}
