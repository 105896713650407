import {lastValueFrom} from 'rxjs';
import {Injector} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Logger} from 'pl-comps-angular';
import {API_FEATURE_FLAGS, API_RUNTIME_PROPERTIES} from './constants';
import {apiActivateRuntimeProperties} from '../common/api';
import {APP_RUNTIME_PROPERTIES} from '../common/app';
import {FEATURE_FLAGS_CLIENT_KEY} from '../../environments/constants';
import {IAppRuntimeProperties} from '../common/interfaces/interfaces';

export async function configRuntime(injector: Injector): Promise<void> {
  let properties: IAppRuntimeProperties;

  if (window?.location) {
    const httpClient: HttpClient = injector.get(HttpClient);
    const url = window.location.origin + API_RUNTIME_PROPERTIES;
    try {
      properties = await lastValueFrom(httpClient.request<IAppRuntimeProperties>('GET', url, {responseType: 'json'}));
    } catch (reason: unknown) {
      if (!(reason instanceof HttpErrorResponse)) {
        const logger = injector.get(Logger, undefined, {optional: true});
        if (logger) {
          logger.error(reason);
        }
      }
    }
  }

  APP_RUNTIME_PROPERTIES.next(
    Object.freeze({
      featureFlagsAddress: API_FEATURE_FLAGS,
      featureFlagsClientKey: FEATURE_FLAGS_CLIENT_KEY,
      ...properties
    })
  );

  apiActivateRuntimeProperties(APP_RUNTIME_PROPERTIES.value);
}
