import {TDate} from '../../../common/dates';
import {ETrfOperacaoBancaria} from './trfBa.module.interface';

export enum ETrfBaRGFormato {
  PS2XML,
  CGD
}

export enum ETemplateSEPASalarios {
  Geral,
  IGCP
}

export interface IMetodoSourcePrioridade {
  valueMetodo: boolean;
  nameMetodo: string;
}

export interface IJsonTrfBaRegistoFich {
  registoFicheiroDeTransferenciasNoFormatoPS2: boolean;
  registoFicheiroDeTransferenciaDGTNoFormatoSEPA: boolean;
  registoFicheiroTicketRefeicaoNoFormatoSEPA: boolean;
  registoFicheiroExportacaoCGDTXTSeparacaoNibs: boolean;
}

export interface IJsonTrfBaItemTree {
  estado: boolean;
  formatotransferencia: string;
  operacao: ETrfOperacaoBancaria;
  data: string;
  iban: string;
}

export interface IJsonTrfBa {
  selected: boolean;
  trfBaID: number;
  efectuada: boolean;
  formatoTrasnferencia: string;
  origemDados: number;
  operacao: ETrfOperacaoBancaria;
  codBancoEMI: string;
  codagenciaEMI: string;
  ibanContaEMI: string;
  codBancoDest: string;
  codAgenciaDest: string;
  ibanContaDest: string;
  nPeriodo: string;
  nDiario: number;
  nDocInternoLanc: string;
  nDocPago: string;
  nContaForn: string;
  nContaCaixa: string;
  codEmpregado: number;
  dataLanc: TDate;
  valor: number;
  codPais: number;
  numSequencial: number;
  nomeFicheiro: string;
  dataExportacao: TDate;
  swiftDest: string;
  swiftEmi: string;
  chaveToPS2: string;
  nomeFuncionario: string;
  nomeBeneficiario: string;
  showRGFormato: boolean;
  nLote?: string;
  estadoSEPA?: number;
  estadoNotificacao?: number;
  extPocCabId?: string;
}

export interface IJsonTrfBaConfigIBAN {
  iban: string;
  outraIDPrivada: string;
}

export interface IJsonTrfBaConfig {
  regTempSal: ETemplateSEPASalarios;
  trfBaConfigIBANList: Array<IJsonTrfBaConfigIBAN>;
}
