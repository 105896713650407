import {JSONSchema} from '@ngx-pwa/local-storage';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonCopyPOCsEmpErroInfo, IJsonCopyPOCsEmpresas, IJsonPOC, IJsonPOCCopy} from './jsonPOC.entity.interface';

export enum EPocsClasse {
  ORCAMENTAL = '0',
  ANALITICA = '9'
}

export interface IPOCSEntityService extends IEntityService<IJsonPOC> {
  getPocsCopyList(nContaDe: string, nContaAte: string): TServiceResponse<Array<IJsonPOCCopy>>;

  postPocsCopyList(pocsCopyEmpresa: IJsonCopyPOCsEmpresas): TServiceResponse<Array<IJsonCopyPOCsEmpErroInfo>>;
}

export interface IPOCSEntity extends IEntityDefinition<IJsonPOC, IPOCSEntityService> {}

export const ENTITY_NAME_POCS = 'pocs';

export const MODULE_NAME_POCS_COPY = 'pocscopy';

export const schemaPocs: JSONSchema = {
  type: 'object',
  properties: {
    nConta: {
      type: 'string'
    },
    nome: {
      type: 'string'
    },
    tipo: {
      type: 'string'
    },
    cc: {
      type: 'boolean'
    },
    nif: {
      type: 'string'
    },
    temCCusto: {
      type: 'boolean'
    },
    temIVA: {
      type: 'boolean'
    },
    registaRetencao: {
      type: 'boolean'
    },
    temRetencao: {
      type: 'boolean'
    },
    bloqueada: {
      type: 'boolean'
    },
    dpq06NCampo: {
      type: 'string'
    },
    dpq06NCampoNome: {
      type: 'string'
    },
    daq08NCampo: {
      type: 'string'
    },
    daq08NCampoNome: {
      type: 'string'
    },
    daq09NCampo: {
      type: 'string'
    },
    daq09NCampoNome: {
      type: 'string'
    },
    codControloIVADebito: {
      type: 'string'
    },
    codControloIVADebitoNome: {
      type: 'string'
    },
    codControloIVACredito: {
      type: 'string'
    },
    codControloIVACreditoNome: {
      type: 'string'
    },
    nifNome: {
      type: 'string'
    },
    codRet: {
      type: 'string'
    },
    nomeRet: {
      type: 'string'
    }
  }
};
