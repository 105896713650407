import {isElement} from '../common/utilities/utilities';

export const DEFAULT_MODAL_MAXIMUM_BODY_HEIGHT = '80vh';

export function calculateMaximumBodyHeight(modalElement: HTMLElement): string {
  if (!isElement(modalElement)) {
    return DEFAULT_MODAL_MAXIMUM_BODY_HEIGHT;
  }
  const elementWindow: HTMLElement = modalElement.closest<HTMLElement>('.modal');
  if (!elementWindow) {
    return DEFAULT_MODAL_MAXIMUM_BODY_HEIGHT;
  }
  const elementDialog: HTMLElement = elementWindow.querySelector<HTMLElement>('.modal-dialog');
  if (!elementDialog) {
    return DEFAULT_MODAL_MAXIMUM_BODY_HEIGHT;
  }
  const elementContent: HTMLElement = elementDialog.querySelector<HTMLElement>('.modal-content');
  const elementHeader: HTMLElement = elementDialog.querySelector<HTMLElement>('.modal-header');
  const elementFooter: HTMLElement = elementDialog.querySelector<HTMLElement>('.modal-footer');
  if (!elementContent || !elementHeader || !elementFooter) {
    return DEFAULT_MODAL_MAXIMUM_BODY_HEIGHT;
  }
  const stylesDialog: CSSStyleDeclaration = getComputedStyle(elementDialog);
  const stylesContent: CSSStyleDeclaration = getComputedStyle(elementContent);
  const windowHeight: number = elementWindow.getBoundingClientRect().height;
  const dialogMargins: number = (parseFloat(stylesDialog.marginTop) || 0) + (parseFloat(stylesDialog.marginBottom) || 0);
  const contentBorders: number = (parseFloat(stylesContent.borderTop) || 0) + (parseFloat(stylesContent.borderBottom) || 0);
  const headerHeight: number = elementHeader.getBoundingClientRect().height;
  const footerHeight: number = elementFooter.getBoundingClientRect().height;
  return `${windowHeight - dialogMargins - contentBorders - headerHeight - footerHeight}px`;
}

export function applyMaximumBodyHeight(modalBodyElementOrParent: HTMLElement): void {
  if (!isElement(modalBodyElementOrParent)) {
    return;
  }
  if (!modalBodyElementOrParent.classList.contains('.modal-body')) {
    modalBodyElementOrParent = modalBodyElementOrParent.querySelector<HTMLElement>('.modal-body');
  }
  if (!modalBodyElementOrParent) {
    return;
  }
  const maxHeight: string = calculateMaximumBodyHeight(modalBodyElementOrParent);
  if (maxHeight !== modalBodyElementOrParent.style.maxHeight) {
    modalBodyElementOrParent.style.maxHeight = maxHeight;
  }
}
