import {merge} from 'lodash-es';
import {AuthService} from '../../../../services/auth/auth.service';
import {EGestaoDGEMPSType} from '../../../../components/gestaodgemps/gestaodgemps.interface';
import {gestaoDGEMPSTypeToPRHFluxo} from '../../../../components/gestaodgemps/gestaodgemps.utilities';
import {IJsonDGEMP} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_PCA_RH_GESTAO_DGEMPS_ABONOS, MODULE_NAME_PCA_RH_GESTAO_DGEMPS_FALTAS, MODULE_NAME_PCA_RH_GESTAO_DGEMPS_FERIAS} from './pca.rh.gestaoDGEMPS.module.interface';
import {PcaRhGestaoDGEMPSModuleComponent} from './components/pca.rh.gestaoDGEMPS.module.component';
import {PCARHGestaoDGEMPSService} from './pca.rh.gestaoDGEMPS.module.service';
import {RESOLVER_GESTAO_DGEMPS_HOLIDAYS_DATES} from '../../../../components/gestaodgemps/gestaodgemps.router.interface';
import {ROLE} from '../../../../services/role.const';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';

export const MODULE_PCA_RH_GESTAO_DGEMPS_ABONOS: IModuleDefinition = generateModule(EGestaoDGEMPSType.Abonos, {
  name: MODULE_NAME_PCA_RH_GESTAO_DGEMPS_ABONOS,
  state: {
    url: '/abonos',
    data: {
      pageTitle: 'gestaodgemps.text.abonos',
      roles: [ROLE.PCA]
    }
  }
});

export const MODULE_PCA_RH_GESTAO_DGEMPS_FALTAS: IModuleDefinition = generateModule(EGestaoDGEMPSType.Faltas, {
  name: MODULE_NAME_PCA_RH_GESTAO_DGEMPS_FALTAS,
  state: {
    url: '/faltas',
    data: {
      pageTitle: 'gestaodgemps.text.faltas',
      roles: [ROLE.PCA, ROLE.RH]
    }
  }
});

export const MODULE_PCA_RH_GESTAO_DGEMPS_FERIAS: IModuleDefinition = generateModule(EGestaoDGEMPSType.Ferias, {
  name: MODULE_NAME_PCA_RH_GESTAO_DGEMPS_FERIAS,
  state: {
    url: '/ferias',
    data: {
      pageTitle: 'gestaodgemps.text.ferias',
      roles: [ROLE.PCA, ROLE.RH]
    }
  }
});

function generateModule(type: EGestaoDGEMPSType, base: Partial<IModuleDefinition>): IModuleDefinition {
  return <IModuleDefinition>merge<object, Partial<IModuleDefinition>, Partial<IModuleDefinition>>({}, base, {
    state: {
      component: PcaRhGestaoDGEMPSModuleComponent,
      resolve: [
        {provide: 'moduleType', useFactory: () => type},
        RESOLVER_GESTAO_DGEMPS_HOLIDAYS_DATES,
        {
          provide: 'configStructure',
          deps: [PCARHGestaoDGEMPSService],
          useFactory: (gestaoDGEMPSService: PCARHGestaoDGEMPSService) => (type !== EGestaoDGEMPSType.Ferias ? gestaoDGEMPSService.getConfigStructure(type) : undefined)
        },
        {
          provide: 'configEvents',
          deps: [PCARHGestaoDGEMPSService],
          useFactory: (gestaoDGEMPSService: PCARHGestaoDGEMPSService) => (type !== EGestaoDGEMPSType.Ferias ? gestaoDGEMPSService.getConfigEvents(type) : undefined)
        },
        {
          provide: 'fluxos',
          deps: [PCARHGestaoDGEMPSService],
          useFactory: (gestaoDGEMPSService: PCARHGestaoDGEMPSService) => gestaoDGEMPSService.getFluxos(gestaoDGEMPSTypeToPRHFluxo(type))
        },
        {
          provide: 'empregado',
          deps: [AuthService, PCARHGestaoDGEMPSService],
          useFactory: (authService: AuthService, gestaoDGEMPSService: PCARHGestaoDGEMPSService): Promise<IJsonDGEMP> => {
            return authService.identity().then((session: TUserSession) => gestaoDGEMPSService.getEmpregado(session.erp.codEmp));
          }
        }
      ]
    }
  });
}
