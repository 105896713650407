import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {IPlToolbarItem, PlAlertService} from 'pl-comps-angular';
import {Subscription} from 'rxjs';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {IJsonEmailClifo} from '../../../../../components/emailsCliFo/jsonEmailCliFo.interface';
import {EmailClifoSendMailModalComponent} from '../../../../../components/emailsCliFo/modal/emailClifo.modal.component';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {AuthService} from '../../../../../services/auth/auth.service';
import {PortalClientAccountService} from '../../../../../services/portalclientaccount/portalclientaccount.service';
import {ROLE} from '../../../../../services/role.const';
import {IJsonPOC} from '../../../../../entities/pocs/jsonPOC.entity.interface';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {PcaContabilidadeService} from '../../pca.contabilidade.module.service';
import {
  EPcaContabilidadeServiceOptionsDefaultPeriod,
  EPcaTipoExportacao,
  IPcaContabilidadePeriodo,
  IPcaContabilidadeServiceOptions,
  IPcaContabilidadeServiceRefreshedEvent
} from '../../pca.contabilidade.module.service.interface';
import {GenericImporterService} from '../../../../../components/genericimporter/generic.importer.service';
import {EGenericImporterType} from '../../../../../components/genericimporter/generic.importer.component.interface';
import {EConfigOptionsInstanceName, IMovimentosAbertosConfigOptions, TConfigOptions} from '../../../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../../../config/constants';
import {IJsonBalanceteAno} from '../../../../portalcontabilidade/estatistica/balancetes/jsonContabilidade.balancetes.module.interface';

@Component({
  selector: 'pca-movimentos-aberto',
  templateUrl: './movimentosAberto.module.component.html'
})
export class MovimentosAbertoComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public tipo: string;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;

  public filtro: string;
  public pdfUrl: string;
  public reportModel: IJsonReport;
  public optionShowGrupoConta: boolean;
  public grupoConta: string;

  private readonly _btnProcessXml: IPlToolbarItem;
  private readonly _btnSendMail: IPlToolbarItem;
  private readonly _subscriptionConfig: Subscription;
  private readonly _subscriptionPcaServiceOnRefresh: Subscription;
  private _btnImport: IPlToolbarItem;
  private _conta: IJsonPOC;
  private _ano: IJsonBalanceteAno;
  private _periodo: IPcaContabilidadePeriodo;

  constructor(
    protected readonly _injector: Injector,
    private readonly _pcaContabilidadeService: PcaContabilidadeService,
    private readonly _portalclientaccountsFactory: PortalClientAccountService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService,
    private readonly _authService: AuthService,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _genericImporterService: GenericImporterService
  ) {
    super(_injector);
    this.refresh = this.refresh.bind(this);
    this.configOptionsInstanceName = EConfigOptionsInstanceName.MOVIMENTOS_ABERTOS;
    this.configOptionsGroupName = EGroupName.ERP;

    this.reportModel = {
      name: '',
      title: ''
    };

    this._btnProcessXml = {
      id: 'btn-excel',
      order: 4,
      type: 'download',
      iconLeft: '<i class="fa fa-fw fa-file-excel-o"></i>',
      class: 'btn-success',
      caption: 'global.btn.processExcel',
      disabled: false,
      download: {
        url: undefined
      }
    };

    this._btnSendMail = {
      id: 'sendmail',
      order: 129,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-envelope-o"></i>',
      class: 'btn-success',
      caption: 'emailclifos.sendMail.btn.sendMail',
      disabled: true,
      click: () => this._sendMail()
    };

    this._subscriptionConfig = this._pcaContabilidadeService.getOptions().subscribe((configOptions: TConfigOptions<boolean, IMovimentosAbertosConfigOptions>) => {
      this.optionShowGrupoConta = configOptions.get('showGrupoConta').value;
    });

    this._subscriptionPcaServiceOnRefresh = this._pcaContabilidadeService.onRefresh().subscribe((event: IPcaContabilidadeServiceRefreshedEvent) => {
      this._onNotify(event);
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnProcessXml);
    this.toolbar.addButton(this._btnSendMail);
    this._btnImport = this._genericImporterService.toolbarButton(this.tipo === 'cliente' ? EGenericImporterType.CCClientes : EGenericImporterType.CCFornecedores);
    this._btnImport.visible = false;
    this.toolbar.addButton(this._btnImport);
    const fnClick = this._btnImport.click.bind(this);
    this._btnImport.click = (item: IPlToolbarItem, event: MouseEvent | KeyboardEvent) =>
      Promise.resolve(fnClick(item, event)).then(() => {
        this.refresh();
      });
    this.pdfUrl = undefined;
    this.filtro = 'tipo=0';
    this._conta = undefined;
    this._ano = undefined;
    this._periodo = undefined;

    if (this.tipo === 'cliente') {
      this.filtro += '&nconta>=21&nconta<=21699999';
    }
    if (this.tipo === 'fornecedor') {
      this.filtro += '&nconta>=22&nconta<=228999999';
    }

    (async () => {
      await this._loadDefaultReport();

      if (this.destroyed) {
        return;
      }

      const hasContabilidade: boolean = await this._authService.hasAuthority(ROLE.CONTABILIDADE);
      const options: IPcaContabilidadeServiceOptions = {
        abortSignal: this.abortSignal,
        defaultPeriod: EPcaContabilidadeServiceOptionsDefaultPeriod.Current,
        showCaptionMes: !hasContabilidade
      };

      if (this.destroyed) {
        return;
      }

      await this._pcaContabilidadeService.init(this.toolbar, options);

      this._ano = this._pcaContabilidadeService.ano;
      this._periodo = this._pcaContabilidadeService.periodo;

      if (this.tipo === 'cliente') {
        this._portalclientaccountsFactory
          .getMovimentosAbertoClientesUrl(this._ano.ano, this._periodo.periodo, EPcaTipoExportacao.Pdf, this.reportModel.name, this.grupoConta)
          .subscribe((url: string) => {
            this.pdfUrl = undefined;
            setTimeout(() => {
              this.pdfUrl = url;
            });
          });
        this._portalclientaccountsFactory
          .getMovimentosAbertoClientesUrl(this._ano.ano, this._periodo.periodo, EPcaTipoExportacao.Xls, this.reportModel.name, this.grupoConta)
          .subscribe((url: string) => {
            this._btnProcessXml.download.url = url;
            this._btnProcessXml.disabled = false;
          });
      } else if (this.tipo === 'fornecedor') {
        this._portalclientaccountsFactory
          .getMovimentosAbertoFornecedoresUrl(this._ano.ano, this._periodo.periodo, EPcaTipoExportacao.Pdf, this.reportModel.name, this.grupoConta)
          .subscribe((url: string) => {
            this.pdfUrl = undefined;
            setTimeout(() => {
              this.pdfUrl = url;
            });
          });
        this._portalclientaccountsFactory
          .getMovimentosAbertoFornecedoresUrl(this._ano.ano, this._periodo.periodo, EPcaTipoExportacao.Xls, this.reportModel.name, this.grupoConta)
          .subscribe((url: string) => {
            this._btnProcessXml.download.url = url;
            this._btnProcessXml.disabled = false;
          });
      }
    })();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionConfig.unsubscribe();
    this._subscriptionPcaServiceOnRefresh.unsubscribe();
  }

  public onCCSelected(value: IJsonPOC): void {
    this._conta = value;
    this.refresh();
  }

  public refresh(): void {
    this.pdfUrl = undefined;
    setTimeout(() => {
      if (!this._conta) {
        this._btnSendMail.disabled = true;
        if (this.tipo === 'cliente') {
          this._portalclientaccountsFactory
            .getMovimentosAbertoClientesUrl(this._ano.ano, this._periodo.periodo, EPcaTipoExportacao.Pdf, this.reportModel.name, this.grupoConta)
            .subscribe((url: string) => {
              this.pdfUrl = url;
            });
          this._portalclientaccountsFactory
            .getMovimentosAbertoClientesUrl(this._ano.ano, this._periodo.periodo, EPcaTipoExportacao.Xls, this.reportModel.name, this.grupoConta)
            .subscribe((url: string) => {
              this._btnProcessXml.download.url = url;
            });
        } else if (this.tipo === 'fornecedor') {
          this._portalclientaccountsFactory
            .getMovimentosAbertoFornecedoresUrl(this._ano.ano, this._periodo.periodo, EPcaTipoExportacao.Pdf, this.reportModel.name, this.grupoConta)
            .subscribe((url: string) => {
              this.pdfUrl = url;
            });
          this._portalclientaccountsFactory
            .getMovimentosAbertoFornecedoresUrl(this._ano.ano, this._periodo.periodo, EPcaTipoExportacao.Xls, this.reportModel.name, this.grupoConta)
            .subscribe((url: string) => {
              this._btnProcessXml.download.url = url;
              this._btnProcessXml.disabled = false;
            });
        }
      } else {
        const nConta = this._conta.nConta || '';

        this._portalclientaccountsFactory.getMovimentosAbertoUrl(this._ano.ano, this._periodo.periodo, nConta, EPcaTipoExportacao.Pdf, this.reportModel.name).subscribe((url: string) => {
          this.pdfUrl = url;
          if (url) {
            this._btnSendMail.disabled = false;
          }
        });
        this._portalclientaccountsFactory.getMovimentosAbertoUrl(this._ano.ano, this._periodo.periodo, nConta, EPcaTipoExportacao.Xls, this.reportModel.name).subscribe((url: string) => {
          this._btnProcessXml.download.url = url;
        });
      }
    });
  }

  private _sendMail(): Promise<void> {
    return this._portalclientaccountsFactory.getEmailContent(this._conta.nConta).then((response) => {
      const modalInstance = this._cgModalService.showVanilla(EmailClifoSendMailModalComponent);
      const componentInstance: EmailClifoSendMailModalComponent = modalInstance.componentInstance;
      componentInstance.emailContent = response.body;
      componentInstance.numEmails = 1;
      return modalInstance.result.then((emailContent: IJsonEmailClifo) => {
        return this._portalclientaccountsFactory.sendMailMovimentosAberto(this._ano.ano, this._periodo.periodo, emailContent, this.reportModel.name).then(() => {
          this._plAlertService.success('emailclifos.sendMail.messages.success');
        });
      });
    });
  }

  private _onNotify(event: IPcaContabilidadeServiceRefreshedEvent): void {
    this._ano = event.ano;
    this._periodo = event.periodo;
    if (this._ano && this._periodo) {
      this.refresh();
    }
  }

  private _loadDefaultReport(): Promise<void> {
    const reportModel = this._configService.configurations.contabilidade.movimentosemaberto.reportImpressao;
    return this._reportsRegistryService
      .get(EReport.MovimentosEmAberto)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          let foundDefault = false;
          for (const report of reports) {
            if (report.name === reportModel) {
              this.reportModel = report;
              foundDefault = true;
              break;
            }
          }
          if (!foundDefault) {
            this.reportModel = reports[0];
          }
        }
      });
  }
}
