import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_DESCONTO_AGRUPADO, ETipoDescontoAgrupado} from './tipodescontoagrupado.datasource.interface';

export const DATA_SOURCE_TIPO_DESCONTO_AGRUPADO: IDataSource<ETipoDescontoAgrupado> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_DESCONTO_AGRUPADO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoDescontoAgrupado.NA, name: 'tipoDescontoAgrupado.data.nd'},
    {value: ETipoDescontoAgrupado.Faltas, name: 'tipoDescontoAgrupado.data.faltas'},
    {value: ETipoDescontoAgrupado.Penhoras, name: 'tipoDescontoAgrupado.data.penhoras'},
    {value: ETipoDescontoAgrupado.Impostos, name: 'tipoDescontoAgrupado.data.impostos'}
  ]
});
