import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_TIPOS_ANALITICA, ETipoAnalitica} from './tipoAnalitica.datasource.interface';

export const DATA_SOURCE_TIPOS_ANALITICA: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_ANALITICA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoAnalitica.NaoDefinido, name: 'tipoAnalitica.data.lancaConformeDefinidoIntegracao'},
    {value: ETipoAnalitica.NaoUsa, name: 'tipoAnalitica.data.naoLancaAnalitica'},
    {value: ETipoAnalitica.Usa, name: 'tipoAnalitica.data.lancaSempreAnalitica'}
  ]
});
