export interface IBalancetesAnaliticaFilters {
  nContaCGeralDe: string;
  nContaCGeralAte: string;
  periodoDe: string;
  periodoDeNome: string;
  periodoAte: string;
  periodoAteNome: string;
  cCustoDe: string;
  cCustoAte: string;
  zonaDe: number;
  zonaAte: number;
  departamentoDe: number;
  departamentoAte: number;
  subDepartDe: string;
  subDepartAte: string;
  familiaDe: number;
  familiaAte: number;
  grFamiliaDe: number;
  grFamiliaAte: number;
  subFamiliaDe: number;
  subFamiliaAte: number;
  codContabDe: string;
  codContabAte: string;
  tipoArtigoDe: number;
  tipoArtigoAte: number;
  classeDe: number;
  classeAte: number;
  categoriaDe: number;
  categoriaAte: number;
  vendedorDe: number;
  vendedorAte: number;
  processoDe: string;
  processoAte: string;
  ano: string;
  contasRazao: boolean;
  contasClasse: boolean;
  contasSubTotal: boolean;
  contasMovimento: boolean;
}

export const MODULE_NAME_PCA_ESTATISTICA_BALANCETES_ANALITICA = 'balancetesanalitica';
