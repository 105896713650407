import {Injectable} from '@angular/core';
import {
  filterPanelFiltersFromUrl,
  IPlFilterPanelAdapterDeserializeData,
  IPlFilterPanelAdapterSerializeData,
  IPlFilterPanelField,
  IPlFilterPanelFilters,
  isEmpty,
  isObject,
  isSerializable,
  isString,
  PlCompsService,
  PlFilterPanelAdapter
} from 'pl-comps-angular';

const QUERY_PARAM_VALUES_SEPARATOR = '&';
const QUERY_PARAM_VALUE_SEPARATOR = '=';
const QUERY_PARAM_VALUE_LIKE = '%';

@Injectable()
export class CGEntityFilterPanelUrlAdapter extends PlFilterPanelAdapter<string> {
  constructor(private readonly _plCompsService: PlCompsService) {
    super();
  }

  public override serialize({fieldsMap, filters}: IPlFilterPanelAdapterSerializeData): string {
    let query = '';
    for (const fieldName of Object.keys(filters)) {
      let value: unknown = filters[fieldName];
      if (isEmpty(value)) {
        continue;
      }
      if (isObject(value)) {
        if (isSerializable(value)) {
          value = value.toJSON();
        } else {
          value = (<{value: unknown}>value).value;
        }
      }
      if (query) {
        query += QUERY_PARAM_VALUES_SEPARATOR;
      }
      const field: IPlFilterPanelField = fieldsMap.get(fieldName);
      const isText: boolean = isEmpty(field.type) || field.type === 'text' || field.type === 'string';
      query += field.name + QUERY_PARAM_VALUE_SEPARATOR;
      if (isText) {
        query += QUERY_PARAM_VALUE_LIKE;
      }
      query += <string>value;
      if (isText) {
        query += QUERY_PARAM_VALUE_LIKE;
      }
    }
    return query;
  }

  public override deserialize({fieldsMap, serializedFilters}: IPlFilterPanelAdapterDeserializeData<string>): IPlFilterPanelFilters {
    const appliedFilters: IPlFilterPanelFilters = filterPanelFiltersFromUrl(serializedFilters, fieldsMap);
    for (const fieldName of Object.keys(appliedFilters)) {
      const field = fieldsMap.get(fieldName);
      if (!field || (!isEmpty(field.type) && field.type !== 'text' && field.type !== 'string')) {
        continue;
      }
      const filterValue: unknown = appliedFilters[fieldName];
      if (!isEmpty(filterValue) && isString(filterValue) && filterValue.startsWith(QUERY_PARAM_VALUE_LIKE) && filterValue.endsWith(QUERY_PARAM_VALUE_LIKE)) {
        appliedFilters[fieldName] = filterValue.slice(1, filterValue.length - QUERY_PARAM_VALUE_LIKE.length);
      }
    }
    return appliedFilters;
  }

  public override prettyPrintFilterValue(field: IPlFilterPanelField, value: unknown): string {
    let prettyValue: string = this._plCompsService.prettyPrintValue(value, field.type, undefined, field.properties);
    if (isEmpty(field.type) || field.type === 'text' || field.type === 'string') {
      if (prettyValue.startsWith(QUERY_PARAM_VALUE_LIKE)) {
        prettyValue = prettyValue.slice(QUERY_PARAM_VALUE_LIKE.length);
      }
      if (prettyValue.endsWith(QUERY_PARAM_VALUE_LIKE)) {
        prettyValue = prettyValue.slice(0, prettyValue.length - QUERY_PARAM_VALUE_LIKE.length);
      }
    }
    return prettyValue;
  }
}
