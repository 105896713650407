import {Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IExportacaoComprasFilters} from '../exportacaoCompras.module.interface';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';
import {MAX_DATE_CG, MIN_DATE_CG} from '../../../../../../common/utils/utils';
import {downloadStream, IPlDynamicVisualsSecondaryClickAction, IPlToolbarMenuItem, isObject, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ExportacaoComprasConfigModalComponent} from '../modal/config/exportacaoCompras.config.modal.component';
import {IJsonExportacaoCompras, IJsonExportacaoComprasConfig, IJsonGeofoliaFamilia} from '../jsonExportacaoCompras.module.interface';
import {HttpResponse} from '@angular/common/http';
import {ExportacaoComprasService} from '../exportacaoCompras.module.service';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonFamilia} from '../../../../../entities/familias/jsonFamilia.entity.interface';
import {ENTITY_NAME_FAMILIAS} from '../../../../../entities/familias/familias.entity.interface';
import {IJsonDocfa} from '../../../../../entities/docfas/jsonDocFa.entity.interface';
import {ENTITY_NAME_DOC_FAS} from '../../../../../entities/docfas/docFas.entity.interface';
import {EGrupoDoc} from '../../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {IDevExpressDataGridEventOnContextMenuPreparing, IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {DevExpressDataGridUIService} from '../../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {IEntityMaintenanceInstance} from '../../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {ENTITY_DOCS_COMERCIAIS_VENDAS} from '../../../../../entities/docscomerciais/docsComerciais.entity';

@Component({
  selector: 'module-exportacaocompras',
  templateUrl: './exportacaoCompras.module.component.html'
})
export class ExportacaoComprasModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly filters: IExportacaoComprasFilters;
  public compras: Array<IJsonExportacaoCompras>;
  public selectedRowKeys: Array<IJsonExportacaoCompras>;
  public dataGridDefinition: IDevExpressDataGrid<IJsonExportacaoCompras, IJsonExportacaoCompras>;

  private readonly _btnConfig: IPlToolbarMenuItem;
  private readonly _btnExportar: IPlToolbarMenuItem;
  private readonly _serviceFamilias: IEntityService<IJsonFamilia>;
  private readonly _serviceDocumentosCompras: IEntityService<IJsonDocfa>;
  private readonly _maintenanceInstanceDocsComerciais: IEntityMaintenanceInstance;
  private _cardPanel: CGCardPanelComponent;
  private _config: IJsonExportacaoComprasConfig;
  private _geofoliaFamilias: Array<IJsonGeofoliaFamilia>;
  private _familias: Array<IJsonFamilia>;
  private _documentos: Array<IJsonDocfa>;
  private _dataGridInstance: dxDataGrid<IJsonExportacaoCompras, IJsonExportacaoCompras>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _exportacaoComprasService: ExportacaoComprasService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.fnPesquisar = this.fnPesquisar.bind(this);
    this.filters = {
      datade: MIN_DATE_CG,
      dataate: MAX_DATE_CG,
      documentosporexportar: true
    };
    this._btnExportar = {
      id: 'export',
      type: 'button',
      order: 2,
      iconLeft: '<i class="fa fa-fw fa-download"></i>',
      class: 'btn-success',
      caption: 'global.btn.export',
      disabled: true,
      click: () => this._exportarFicheiro()
    };
    this._btnConfig = {
      id: 'config',
      order: this._btnExportar.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      class: 'btn-light',
      caption: 'global.btn.config',
      click: () => this._openConfigModal()
    };

    this.selectedRowKeys = [];
    this.dataGridDefinition = {
      columns: [
        {dataField: 'nDocAsString', dataType: 'string', caption: 'exportacaoCompras.dataGrid.doc'},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'exportacaoCompras.dataGrid.nDocExterno'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'nConta', dataType: 'string', caption: 'exportacaoCompras.dataGrid.nFornecedor'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'exportacaoCompras.dataGrid.nomeFornecedor'},
        {dataField: 'exportado', dataType: 'boolean', caption: 'exportacaoCompras.dataGrid.exportado'},
        {dataField: 'exportId', dataType: 'string', caption: 'exportacaoCompras.dataGrid.exportId', visible: false}
      ],
      export: {enabled: false},
      remoteOperations: false,
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'}
    };

    this._serviceFamilias = this._entityServiceBuilder.build(ENTITY_NAME_FAMILIAS);
    this._serviceDocumentosCompras = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
    this._maintenanceInstanceDocsComerciais = this._entityMaintenanceService.build(ENTITY_DOCS_COMERCIAIS_VENDAS.name);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnExportar).addButton(this._btnConfig);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonExportacaoCompras, IJsonExportacaoCompras>): void {
    this._dataGridInstance = component;
  }

  public onSelectionChanged(): void {
    this._evaluateBtns();
  }

  public onContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IJsonExportacaoCompras, IJsonExportacaoCompras>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();
      const itemData: IJsonExportacaoCompras = this.compras.find((item) => item.nDocAsString === event.row.data.nDocAsString);
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(itemData);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions).then(() => undefined);
    }
  }

  public async fnPesquisar(): Promise<void> {
    await this._refresh();
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private async _refresh(): Promise<void> {
    try {
      const response: HttpResponse<Array<IJsonExportacaoCompras>> = await this._exportacaoComprasService.getExportacaoCompras(this.filters);

      this.compras = response.body;

      if (response.body?.length) {
        this._cardPanel.collapse();
      } else {
        this._cardPanel.focusFirstElement();
        this._plAlertService.info('global.text.searchNoData');
      }
    } catch (e) {
      this._cardPanel.focusFirstElement();
    }
  }

  private _evaluateBtns(): void {
    this._btnExportar.disabled = this.selectedRowKeys.length < 1;
  }

  private _openConfigModal(): Promise<void> {
    return Promise.all([
      this._config ? Promise.resolve(this._config) : this._exportacaoComprasService.getConfig().then((response: HttpResponse<IJsonExportacaoComprasConfig>) => response.body),
      this._familias ? Promise.resolve(this._familias) : this._serviceFamilias.query().then((response) => response.body.list),
      this._documentos ? Promise.resolve(this._documentos) : this._serviceDocumentosCompras.query({pesquisa: `grupoDocfa=${EGrupoDoc.ComprasEfectivas}`}).then((response) => response.body.list),
      this._geofoliaFamilias
        ? Promise.resolve(this._geofoliaFamilias)
        : this._exportacaoComprasService.getGeofoliaFamilias().then((response: HttpResponse<Array<IJsonGeofoliaFamilia>>) => response.body)
    ]).then(([config, familias, documentos, geofoliaFamilias]: [IJsonExportacaoComprasConfig, Array<IJsonFamilia>, Array<IJsonDocfa>, Array<IJsonGeofoliaFamilia>]) => {
      this._config = config;
      this._familias = familias;
      this._documentos = documentos;
      this._geofoliaFamilias = geofoliaFamilias;
      const modalInstance = this._cgModalService.showVanilla(ExportacaoComprasConfigModalComponent);
      const componentInstance: ExportacaoComprasConfigModalComponent = modalInstance.componentInstance;
      componentInstance.config = this._config;
      componentInstance.documentos = this._documentos;
      componentInstance.familias = this._familias;
      componentInstance.geofoliaFamilias = this._geofoliaFamilias;
      return modalInstance.result.then(() => {
        if (this._cardPanel.collapsed) {
          this._cardPanel.toggleCollapse();
        }
      });
    });
  }

  private async _exportarFicheiro(): Promise<void> {
    const selectedDocs: Array<string> = this._parseSelectedDocsToStringArray();
    const responseFicheiro: HttpResponse<Blob> = await this._exportacaoComprasService.exportFicheiro(this.filters, selectedDocs);
    downloadStream(responseFicheiro);
    await this._refresh();
  }

  private async _clearSelectedDocsExportados(): Promise<void> {
    await this._cgModalService.showOkCancel('exportacaoCompras.mensagens.okModalTitle', 'exportacaoCompras.mensagens.okModalMensagem');
    const selectedDocs: Array<string> = this._parseSelectedDocsToStringArray();
    await this._exportacaoComprasService.clearDocsExportados(this.filters, selectedDocs);
    await this._refresh();
  }

  private _openDoc(item: IJsonExportacaoCompras): Promise<void> {
    return this._maintenanceInstanceDocsComerciais.maintenanceEdit(item.fACCBId).then(() => {
      return this._dataGridInstance.refresh();
    });
  }

  private _generateSecondaryClickActions(item: IJsonExportacaoCompras): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'exportacaoCompras.config.docsDataGrid.contectMenu.desmarcaDocsExported',
        disabled: this.selectedRowKeys.length < 1,
        icon: 'fa fa-fw fa-close',
        click: () => {
          this._clearSelectedDocsExportados().then(() => undefined);
        }
      },
      {
        caption: 'exportacaoCompras.config.docsDataGrid.contectMenu.abrirDoc',
        icon: 'fa fa-fw pl-icon-ver-doc-contabilidade',
        click: () => {
          this._openDoc(item).then(() => undefined);
        }
      }
    ];
  }

  private _parseSelectedDocsToStringArray(): Array<string> {
    return this.selectedRowKeys.map((item) => {
      return item.nDocAsString;
    });
  }
}
