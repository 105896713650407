import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlVirtualScrollingComponent} from './virtual.scrolling.component';
import {PlVirtualScrollingContentDirective} from './virtual.scrolling.content.directive';
import {PlVirtualScrollingForOfDirective} from './virtual.scrolling.forof.directive';

export * from './virtual.scrolling.interface';
export * from './virtual.scrolling.component';
export * from './virtual.scrolling.content.directive';
export * from './virtual.scrolling.forof.directive';

const DECLARATIONS = [
  PlVirtualScrollingComponent,
  PlVirtualScrollingContentDirective,
  PlVirtualScrollingForOfDirective
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlScrollingModule {
}
