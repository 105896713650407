import {Component, Input} from '@angular/core';
import {CGModalComponent} from '../cgmodal.component';

@Component({
  selector: 'delete-prompt-modal',
  templateUrl: './deleteprompt.modal.component.html'
})
export class CGDeletePromptModalComponent extends CGModalComponent<void> {
  @Input() public title: string;
  @Input() public message: string;
}
