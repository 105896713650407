import {merge} from 'lodash-es';
import {BehaviorSubject, Observable} from 'rxjs';
import {Inject, Injectable, OnDestroy, Optional} from '@angular/core';
import type {IPlTinyMCEConfig, IPlTinyMCEPartialConfig} from '../tinymce.interface';
import {TINY_MCE_INITIAL_CONFIG} from '../tinymce.di';

@Injectable({
  providedIn: 'root'
})
export class PlTinyMCEConfigService implements OnDestroy {
  private readonly _subjectConfig: BehaviorSubject<IPlTinyMCEConfig>;
  private _observableConfig: Observable<IPlTinyMCEConfig>;

  constructor(@Inject(TINY_MCE_INITIAL_CONFIG) @Optional() initialConfig: IPlTinyMCEPartialConfig) {
    this._subjectConfig = new BehaviorSubject<IPlTinyMCEConfig>(
      merge(
        {
          cloudChannel: undefined,
          apiKey: undefined,
          init: {
            promotion: false
          },
          id: undefined,
          initialValue: undefined,
          outputFormat: undefined,
          inline: undefined,
          tagName: undefined,
          plugins: undefined,
          toolbar: undefined,
          modelEvents: undefined,
          allowedEvents: undefined,
          ignoreEvents: undefined,
          scriptSrc: undefined
        },
        initialConfig
      )
    );
  }

  public ngOnDestroy(): void {
    this._subjectConfig.complete();
  }

  public setConfig(value: IPlTinyMCEPartialConfig): this {
    this._subjectConfig.next({...this._subjectConfig.value, ...value});
    return this;
  }

  public config(): Observable<IPlTinyMCEConfig> {
    if (!this._observableConfig) {
      this._observableConfig = this._subjectConfig.asObservable();
    }
    return this._observableConfig;
  }
}
