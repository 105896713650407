import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {AuthManagerService} from '../../../services/auth/auth.manager.service';
import {ConfigErpService} from '../../../services/configErp.service';
import {ENTITY_NAME_EMPRESAS} from '../../../entities/empresas/empresas.entity.interface';
import {ENTITY_NAME_ERPS} from '../../../entities/erps/erps.entity.interface';
import {ENTITY_NAME_PORTALS, sortPortals} from '../../../entities/portal/portal.entity.interface';
import {ENTITY_NAME_ROLES} from '../../../entities/roles/roles.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IJsonConfigERP, IJsonConfigERPUser} from '../../../entities/configserp/jsonConfigERP.entity.interface';
import {IJsonEmpresaErp} from '../../../interfaces/jsonEmpresa.interface';
import {IJsonErp, IJsonRole} from '../../../interfaces/jsonUserManager.interface';
import {IJsonPortal} from '../../../entities/portal/jsonPortal.entity.interface';
import {IUserInstallationEmpresa} from './utilizadores.installation.interface';
import {IUserInstallationsData} from './utilizadores.installations.interface';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';

const PROMISE_INDEX_INSTALLATIONS = 0;
const PROMISE_INDEX_ROLES = 1;
const PROMISE_INDEX_PORTALS = 2;
const PROMISE_INDEX_CONFIGS_ERP = 3;

@Injectable({
  providedIn: 'root'
})
export class UtilizadoresInstallationsService {
  private readonly _serviceErps: IEntityService<IJsonErp>;
  private readonly _serviceEmpresas: IEntityService<IJsonEmpresaErp>;
  private readonly _serviceRoles: IEntityService<IJsonRole>;
  private readonly _servicePortals: IEntityService<IJsonPortal>;

  constructor(
    private readonly _authManagerService: AuthManagerService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _configErpService: ConfigErpService
  ) {
    this._serviceErps = this._entityServiceBuilder.build<IJsonErp>(ENTITY_NAME_ERPS);
    this._serviceEmpresas = this._entityServiceBuilder.build<IJsonEmpresaErp>(ENTITY_NAME_EMPRESAS);
    this._serviceRoles = this._entityServiceBuilder.build<IJsonRole>(ENTITY_NAME_ROLES);
    this._servicePortals = this._entityServiceBuilder.build<IJsonPortal>(ENTITY_NAME_PORTALS);
  }

  public getInstallationData(params: {loadInstallations?: boolean}): Promise<IUserInstallationsData> {
    const promises: [Promise<Array<IJsonErp>>, Promise<Array<IJsonRole>>, Promise<Array<IJsonPortal>>, Promise<Array<IJsonConfigERP>>] = [
      !params || params.loadInstallations !== false ? this.getInstallations() : Promise.resolve([]),
      this._getRoles(),
      this._getPortals(),
      this._getConfigsErp()
    ];
    return Promise.all(promises).then<IUserInstallationsData>((resolvedPromises: [Array<IJsonErp>, Array<IJsonRole>, Array<IJsonPortal>, Array<IJsonConfigERP>]) => {
      return {
        installations: resolvedPromises[PROMISE_INDEX_INSTALLATIONS],
        roles: resolvedPromises[PROMISE_INDEX_ROLES],
        portals: resolvedPromises[PROMISE_INDEX_PORTALS],
        configsErp: resolvedPromises[PROMISE_INDEX_CONFIGS_ERP]
      };
    });
  }

  public async getInstallationEmpresas(installation: {centralGestId: number; name: string}): Promise<Array<IUserInstallationEmpresa>> {
    const empresas: Array<IJsonEmpresaErp> = (await this._serviceEmpresas.query({params: {centralGestId: installation.centralGestId}})).body.list;
    return empresas.map<IUserInstallationEmpresa>((empresa: IJsonEmpresaErp) => {
      return {
        centralGestId: installation.centralGestId,
        centralGestName: installation.name,
        nempresa: empresa.nempresa,
        nome: empresa.nome
      };
    });
  }

  public getInstallations(): Promise<Array<IJsonErp>> {
    return this._serviceErps.query().then((response: THttpQueryResponse<IJsonErp>) => response.body.list);
  }

  public getUserAccesses(userId: number, nEmpresa: string): Promise<Array<IJsonConfigERPUser>> {
    return this._authManagerService.getUserAccesses(userId, nEmpresa).then((response: HttpResponse<Array<IJsonConfigERPUser>>) => response.body);
  }

  private _getRoles(): Promise<Array<IJsonRole>> {
    return this._serviceRoles.query().then((response: THttpQueryResponse<IJsonRole>) => response.body.list);
  }

  private _getPortals(): Promise<Array<IJsonPortal>> {
    return this._servicePortals.query().then((response: THttpQueryResponse<IJsonPortal>) => sortPortals(response.body.list));
  }

  private _getConfigsErp(): Promise<Array<IJsonConfigERP>> {
    return this._configErpService.queryAccesses().then((response: HttpResponse<Array<IJsonConfigERP>>) => response.body);
  }
}
