<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'efatura.geraDocModal.title'"></h4>
</div>

<div class="modal-body efatura">
  <div *ngIf="!docsTodosComunicados" class="entity-detail-form">
    <pl-edit type="boolean" [(model)]="soDocsPorComunicar" [properties]="{label: 'efatura.geraDocModal.soDocsPorComunicar'}"></pl-edit>
    <div class="efatura-info" *ngIf="!soDocsPorComunicar">
      <i class="fa fa-info-circle" aria-hidden="true"></i>
      <span class="btn" [translate]="'efatura.geraDocModal.messageSoDocsPorComunicarFalse'"></span>
    </div>
    <div class="efatura-info" *ngIf="soDocsPorComunicar">
      <i class="fa fa-info-circle" aria-hidden="true"></i>
      <span class="btn" [translate]="'efatura.geraDocModal.messageSoDocsPorComunicarTrue'"></span>
    </div>
  </div>
  <div *ngIf="docsTodosComunicados" class="entity-detail-form">
    <span [translate]="'efatura.geraDocModal.messageTodosComunicados'"></span>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-primary btn-sm action-submit" (click)="close(soDocsPorComunicar)"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></button>
  <button type="button" class="btn btn-light btn-sm action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
