import {isNumber} from 'pl-comps-angular';

export const DEV_EXPRESS_DEFAULT_DECIMALS_LIMIT = 2;
export const DEV_EXPRESS_FORMAT_INTEGER = '#,##0';

export function devExpressGenerateFormatDouble(decimalDigits: number = DEV_EXPRESS_DEFAULT_DECIMALS_LIMIT): string {
  if (!isNumber(decimalDigits) || decimalDigits < 0) {
    decimalDigits = DEV_EXPRESS_DEFAULT_DECIMALS_LIMIT;
  }
  let format = '#,##0.';
  for (let i = 0; i < decimalDigits; i++) {
    format += '0';
  }
  return format;
}
