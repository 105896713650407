{
	"abdes": {
		"title_detail": "Abono / Desconto {{id}}",
		"title_new": "Novo Abono / Desconto",
		"title_plural": "Abonos / Descontos",
		"pesquisa": "Pesquisar",
		"saved": "Abono / Desconto {{id}}, guardado com sucesso.",
		"error": "Abono / Desconto {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Abono / Desconto {{id}}, eliminado com sucesso.",
		"fields": {
			"abonoDesc": "Abono / Desconto",
			"codAbDesc": "Código",
			"designacaoBreve": "Nome",
			"designacaoCompleta": "Descrição",
			"valorFixo": "Valor unitário",
			"valorLimIncidencia": "Limite incidência",
			"tipoArredondamento": "Arredondamento",
			"procNatal": "Taxa retenção",
			"taxaRetencao": "Taxa retenção",
			"procFerias": "",
			"mapaSeguro": "Mapa seguro",
			"mapaSindic": "Mapa sindicato",
			"colQuadroP": "",
			"colMapaCX": "",
			"nCodABDESC": "",
			"entiSuporte": "Entidade",
			"contaMovDeb": "Abonos e descontos empregados - Conta débito",
			"contaMovCred": "Abonos e descontos empregados - Conta crédito",
			"calcCustHora": "Contribui cálculo custo preço / hora",
			"coefHExtra": "% de aumento da hora extra",
			"horaDiaValor": "",
			"tipoHoraExtr": "",
			"diaHoraExtra": "",
			"grupoAbono": "Grupo",
			"grupoDesconto": "Grupo",
			"naoImpDecIRS114": "Não imprime declaração",
			"tipoValorUnitRecolha": "Tipo recolha",
			"percLimValorHExtra": "% Valor limite H. extras relat. venc. base",
			"codCGASit": "Cód. de situação",
			"contaEncDEB": "Encargos patronato / empresa - Conta débito",
			"contaEncCRE": "Encargos patronato / empresa - Conta crédito",
			"percInciSegSocial": "(%) Incidência",
			"tipoRendimento": "Tipo rend.",
			"codSSCol": "Cód. rem.",
			"codRubrOrcAbon": "",
			"codRubrOrcEnc": "",
			"abonoDescDesativado": "Inativo",
			"tipoDescontoProporcionalABDES": "Tipo desconto proporcional",
			"nHorasDescontoProporcional": "Nº Horas",
			"sPReducaoRemunatoria": "Tem redução remuneratória",
			"nHorasDiaProvDesc": "Nº Horas dia para perda de subsídio de refeição",
			"perctDescSeguro": "Percentagem paga pelo seguro",
			"usaSPReducaoRemuneratoria": "Trabalhadores serviço público - Tem redução remuneratória",
			"tem": "Tem",
			"irs": "IRS",
			"segsocial": "Segurança Social",
			"perfilSistema": "Perfil de sistema"
		},
		"virtualFields": {
			"imprimeDec119": "Imprime na dec. 119",
			"enviaAT": "Envia para AT"
		},
		"tabIntegracaoCruzamento": "Integração / Cruzamentos",
		"tabOutrosDados": "Outros dados",
		"naotem": "Não tem",
		"abono": "Abono",
		"abonos": "Abonos",
		"desconto": "Desconto",
		"descontos": "Descontos",
		"messages": {
			"abdescSystemLocked": "Perfil de sistema. Alguns campos não podem ser alterados. O sistema atualiza de forma automática esses campos, caso surjam alterações ao perfil ''{{perfilSistemaAbdesNome}}'' (exemplo: alterações de legislação).",
			"abdescSystemUnLocked": "Perfil editável. O sistema não atualiza de forma automática os campos caso surjam alterações (exemplo: alterações de legislação). Recomenda-se que sejam sempre utilizados {{abonosDescontos}} com perfil associado.",
			"perfilSistema": "Perfil de sistema. Gere os campos editáveis."
		},
		"unlockPrompt": {
			"title": "Perfil de Sistema",
			"message": "<p>Pretende desbloquear todos os campos geridos pelo sistema, de acordo com o perfil ''{{perfilSistemaAbdesNome}}'', para alterar valores?</p> <p>Ao desbloquear as configurações, o sistema deixará de atualizar de forma automática esses campos, caso surjam alterações ao perfil ''{{perfilSistemaAbdesNome}}'' (exemplo: alterações de legislação).</p> <p>Recomenda-se que sejam sempre utilizados {{abonosDescontos}} com perfil associado.</p>"
		}
	},
	"descontos": {
		"title_detail": "Desconto {{id}}",
		"title_new": "Novo Desconto",
		"title_plural": "Descontos",
		"pesquisa": "Pesquisar",
		"saved": "Desconto {{id}}, guardado com sucesso.",
		"error": "Desconto {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Desconto {{id}}, eliminado com sucesso.",
		"actions": "Gerar novo desconto"
	},
	"abonos": {
		"title_detail": "Abono {{id}}",
		"title_new": "Novo Abono",
		"title_plural": "Abonos",
		"pesquisa": "Pesquisar",
		"saved": "Abono {{id}}, guardado com sucesso.",
		"error": "Abono {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Abono {{id}}, eliminado com sucesso.",
		"actions": "Gerar novo abono"
	},
	"about": {
		"title": "Sobre o CentralGest Cloud",
		"licenca": {
			"licId": "Nº de licença",
			"nome": "Nome",
			"descricao": "Licenciado a",
			"nUtilizadores": "Nº de utilizadores",
			"portal": "Portal {{name}}"
		},
		"erp": {
			"versao": "Versão do ERP",
			"dataVersao": "Data da versão",
			"emManutencao": "Em manutenção"
		},
		"user": {
			"roles": "Acessos do utilizador"
		}
	},
	"account": {
		"messages": {
			"error": {
				"invalidRecaptcha": "Por favor marque a caixa de verificação para confirmar que não é uma máquina.",
				"invalidSecurePassword": "A password não é válida, deve conter pelo menos 8 caracteres, um número, minúsculas e maiúsculas.",
				"notAuthorized": "Não tem acesso à plataforma. Por favor contacte o administrador do sistema."
			}
		}
	},
	"login": {
		"title": "Autenticar",
		"header": "Iniciar a sessão para começar a utilizar o CentralGest Cloud.",
		"form": {
			"password": "Palavra-passe",
			"password.placeholder": "Palavra-passe",
			"button": "Autenticar",
			"resetpassword": "Esqueci-me da palavra-passe"
		},
		"messages": {
			"error": {
				"authentication": "Falha na autenticação! Por favor verifique as suas credenciais e tente novamente.",
				"invalidCredentials": "Introduza o seu nome de utilizador e a sua palavra-passe."
			}
		}
	},
	"resetpassword": {
		"success": "Por favor verifique a sua caixa de e-mail",
		"title": "Recuperar a sua palavra-passe",
		"message": "Por favor introduza o seu e-mail ou o seu nome de utilizador.<br/>Em breve vai receber uma mensagem no seu e-mail com a informação para alterar a sua palavra-passe.",
		"email.placeholder": "E-mail ou nome de utilizador",
		"action": {
			"back": "Voltar",
			"resetpassword": "Recuperar a palavra-passe"
		}
	},
	"changepassword": {
		"title": "Alterar a sua palavra-passe",
		"message": "Por favor escreva a sua nova palavra-passe.",
		"success": "A sua palavra-chave foi alterada.",
		"action": {
			"back": "Voltar",
			"changepassword": "Mudar a palavra-passe"
		},
		"erro": {
			"minlength": "A palavra-passe tem de ter 5 caracteres ou mais!",
			"repetirinvalido": "A palavra-passe não coincide"
		},
		"placeholder": {
			"password": "Palavra-passe",
			"confirmPassword": "Repita a palavra-passe"
		}
	},
	"changefirm": {
		"fields": {
			"nEmpresa": "Nº empresa",
			"nomeEmpresa": "Nome empresa"
		}
	},
	"acessos": {
		"erpcloud": {
			"addons": {
				"bankingAutomation": {
					"acesso": "Acesso ao banking automation"
				}
			},
			"ativos": {
				"fichas": {
					"visualizarFichas": "Visualizar fichas de ativos",
					"criarFichas": "Criar fichas de ativos",
					"editarFichas": "Editar fichas de ativos",
					"apagarFichas": "Apagar fichas de ativos",
					"operacoesAbatesVendas": "Operações sobre abates e vendas",
					"calcularAnularDepreciacoes": "Calcular e anular depreciações"
				}
			},
			"contabilidade": {
				"documentos": {
					"criarDocumentos": "Criar documentos",
					"editarDocumentos": "Editar documentos",
					"anularDocumentos": "Anular documentos",
					"visualizarDocumentos": "Visualizar documentos"
				}
			},
			"gestaocomercial": {
				"editarContaArtigoFamilia": "Pode editar conta venda e compra de artigos e familias",
				"editarMeioPagamento": "Pode editar meios de pagamento",
				"documentos": {
					"comprasefetivas": {
						"title": "Compras efetivas"
					},
					"encomendasclientes": {
						"title": "Encomendas clientes"
					},
					"encomendasfornecedores": {
						"title": "Encomendas fornecedores"
					},
					"guiastransporte": {
						"title": "Guias transporte"
					},
					"guiastransportefornecedores": {
						"title": "Guias transporte fornecedores"
					},
					"propostasclientes": {
						"title": "Propostas clientes"
					},
					"vendasefetivas": {
						"title": "Vendas efetivas"
					},
					"entradasdiversas": {
						"title": "Entradas Diversas"
					},
					"saidasdiversas": {
						"title": "Saídas Diversas"
					},
					"propostasfornecedores": {
						"title": "Propostas fornecedores"
					}
				}
			}
		},
		"manager": {
			"generic": {
				"view": "Visualizar",
				"add": "Criar",
				"edit": "Editar",
				"delete": "Anular"
			},
			"addons": {
				"title": "Addons"
			},
			"ativos": {
				"title": "Ativos"
			},
			"contabilidade": {
				"title": "Contabilidade"
			},
			"gestaoComercial": {
				"title": "Comercial"
			}
		}
	},
	"acrescimosencargosferias": {
		"porEmpresa": "Por empresa",
		"multiEmpresa": "Multi empresa",
		"fields": {
			"empregado": "Empregado",
			"totaisEncargos": "Totais encargos",
			"codEmpregado": "Empregado",
			"nomeEmpregado": "Nome",
			"dataAdmissao": "Data admissão",
			"nMesesTrabalhados": "Nº Meses trab.",
			"ccusto": "C. Custo",
			"nCodRepCC": "Repartição",
			"estadoID": "Estado",
			"vBaseEncargosFerias": "Base encargos férias",
			"valorAcertoManual": "Acerto manual",
			"valorSubFerias": "Sub. férias",
			"valorFerias": "Férias",
			"valorEncargosSS": "Seg. social",
			"valorEncargoAcTrab": "Ac. trabalho",
			"descrBaseEncFerias": "Info",
			"dados": "Dados",
			"sel": "Sel.",
			"nEmpresa": "Empresa",
			"nomeEmpresa": "Nome empresa",
			"tipoID": "Tipo",
			"descricaoErro": "Info",
			"dataIntegracao": "Data integração"
		},
		"cab": {
			"totalEncAnuais": "Total encargos anuais por empregado",
			"tipo": "Tipo",
			"acTrabalho": "% Ac. de trabalho",
			"estado": "Estado",
			"dataInt": "Data integração",
			"tiposProce": {
				"tipoproce0": "",
				"tipoproce1": "Anual",
				"tipoproce2": "Mensal"
			}
		},
		"modals": {
			"config": {
				"title": "Configuração acréscimos de encargos com férias",
				"contasDebito": "Contas débito",
				"contasCredito": "Contas crédito",
				"percActTrabalho": "% Seg. acidentes trabalho",
				"diario": "Diário",
				"descritivo": "Descritivo",
				"pocSubFerias": "Sub. férias",
				"pocFerias": "Férias",
				"pocEncargosSegSocial": "Encargos seg. social",
				"pocEncargosActTrabalho": "Encargos acid. trabalho",
				"pocOutrosEncargos": "Outros encargos",
				"tipo": "Tipo",
				"percSeguradora": "% Seg. acidentes trabalho",
				"dadosProce": "Dados para processamento",
				"intContab": "Integração contabilidade"
			},
			"resumoAnual": {
				"title": "Encargos anuais",
				"totaisAnual": "Totais anual",
				"encargos": "Encargos",
				"acertoManual": "Acerto manual",
				"subferias": "Sub. férias",
				"ferias": "Férias",
				"encSegSocial": "Encargos seg. social",
				"encAcTrab": "Encargos ac. trabalho",
				"segSocial": "Seg. social",
				"acTrab": "Ac. trabalho",
				"outrosEnc": "Outros encargos",
				"custosEmp": "Custos empresa",
				"totaisAno": "Totais ano"
			},
			"empresas": {
				"title": "Acréscimos de encargos com férias multi empresa",
				"nEmpresa": "Empresa",
				"nomeEmpresa": "Nome empresa",
				"tipo": "Tipo",
				"percSeguradora": "% Ac. de trabalho"
			},
			"multiempresadetail": {
				"title": "Acréscimos de encargos com férias"
			},
			"print": {
				"title": "Impressão de acréscimos de encargos com férias",
				"deCodEmp": "Cód. empregado de",
				"deCCusto": "Centro custo de",
				"deReparticao": "Repartição de"
			}
		},
		"tipoAcrescimoEncFeriasSource": {
			"anual": "Anual",
			"mensal": "Mensal"
		},
		"estadoRegEmp": {
			"novo": "Novo",
			"ok": "OK",
			"alterado": "Dados alterados",
			"apagado": "Saiu da empresa"
		},
		"estadoAcrescimoEncFerias": {
			"processado": "Processado",
			"integrado": "Integrado",
			"comInconsistencias": "Com inconsistências",
			"proceInteg": "Processado e integrado",
			"proceNotInteg": "Processado e não integrado"
		},
		"integContabMultiErrors": {
			"title": "Relatório integração contabilidade",
			"naoInteg": "Não integrado",
			"integ": "Integrado"
		},
		"btns": {
			"geraEncargos": "Gerar encargos",
			"removeEncargos": "Remove encargos",
			"resumoAnual": "Resumo anual",
			"editCab": "Editar cabeçalho",
			"empregado": "Empregado",
			"assistente": "Assistente",
			"lancarDocumento": "Lançar documento",
			"apagarDocumento": "Apagar documento",
			"lancarDocumentoMulti": "Lançar documento",
			"apagarDocumentoMulti": "Apagar documento",
			"verificarInconsistenciasMulti": "Verificar inconsistências",
			"apagaEncargosMulti": "Remover",
			"details": "Detalhes",
			"integraContab": "Integração na contabilidade",
			"consultarLanc": "Consultar lançamentos"
		},
		"messages": {
			"naotemencargos": "Não existem encargos",
			"naoSelEmp": "Tem de selecionar pelo menos uma empresa.",
			"docsIntegSuccess": "Documentos integrados com sucesso",
			"docsDeletedSuccess": "Documentos integrados apagados com sucesso"
		}
	},
	"adiantamentos": {
		"cliente": "Cliente",
		"fornecedor": "Fornecedor",
		"tabs": {
			"porRegularizar": "Por regularizar",
			"regularizado": "Regularizado",
			"fields": {
				"documentoStr": "Documento",
				"origemDocStr": "Origem",
				"nClifo": "Conta",
				"nomeClifo": "Nome conta",
				"liquido": "Líquido",
				"valorIva": "IVA",
				"porRegularizar": "Por regularizar",
				"estadoStr": "Estado",
				"valorRegularizar": "A regularizar",
				"dateDocTo": "Data doc. de",
				"dataRef": "Data referência",
				"intervalo": "Intervalo",
				"datarefradio": "Data de referência",
				"modofunc": "Modo de funcionamento",
				"dataUltRegular": "Data últ. regularização",
				"valorRegularizadoAData": "Valor regularizado à data ref.",
				"estadoNaDataRefStr": "Estado na data ref."
			},
			"pesquisaDatas": "Pesquisa por datas",
			"pesquisaIntervalo": "Pesquisar adiantamentos entre datas",
			"pesquisaNaData": "Pesquisar situação dos adiantamentos à data"
		},
		"modals": {
			"familia": "Família",
			"nArtigoAdiantamento": "Artigo de adiantamento",
			"nArtigoRegAdiantamento": "Artigo de regularização de adiantamento",
			"autoConfig": {
				"title": "Gestão de adiantamentos - Configuração automática",
				"steps": {
					"intro": {
						"title": "Introdução",
						"desc": "Assistente para configuração automática da gestão de adiantamentos"
					},
					"familiaBase": {
						"title": "Família base",
						"desc": "Família base para a criação de adiantamentos",
						"assistente1": "Este assistente vai criar",
						"assistente2": "Família de artigos",
						"assistente3": "Artigo de regularização de adiantamento",
						"assistente4": "Artigo de adiantamento",
						"familiaEmpty": "Tem de selecionar uma família."
					},
					"familiaArtigos": {
						"title": "Família e artigos",
						"desc": "Família e artigos que serão criados"
					}
				}
			},
			"config": {
				"title": "Configurações de adiantamentos",
				"desc": "É necessário efetuar a configuração dos artigos a serem utilizados pelo módulo de gestão de adiantamentos"
			},
			"registar": {
				"title": "Registar adiantamento",
				"emitAdiant": "Emissão de adiantamento",
				"emitAdiantDoc": "Emissão de adiantamento relativo ao documento {{doc}}",
				"taxaIva": "Taxa IVA",
				"valorSIva": "Valor sem IVA",
				"valorCIva": "Valor com IVA",
				"codiva": "Cód. IVA"
			},
			"print": {
				"title": "Impressão de adiantamentos"
			}
		},
		"messages": {
			"notConfigured": "O sistema ainda não está configurado para utilizar a gestão de adiantamentos! Clique aqui para configurar automaticamente.",
			"criaConfigTooltip": "Serão criadas as configurações necessárias.",
			"noDocSel": "Não selecionou nenhum documento para regularizar.",
			"configDocFields": "Para criar um novo documento tem de configurar os campos.",
			"valueInvalid": "O valor tem de ser superior a zero.",
			"clifoNaoSel": "Tem de inserir um {{clifo}} para regularizar os adiantamentos.",
			"clifoNaoSelReg": "Tem de inserir um {{clifo}} para registar o adiantamento.",
			"sistemNotConfigured": "Tem de configurar o sistema para utilizar a gestão de adiantamentos.",
			"docHasAdiant": "O documento já contém uma linha de adiantamento. Não é possível fazer regularização neste documento.",
			"docHasRegAdiant": "O documento já contém uma linha de regularização de adiantamento. Não é possível fazer o adiantamento neste documento."
		},
		"btns": {
			"autoConfig": "Configurar automaticamente",
			"regularizar": "Regularizar",
			"rastreabilidade": "Rastreabilidade"
		}
	},
	"amalia": {
		"title": "Amália",
		"text": {
			"promptPlaceholder": "Pergunta-me qualquer coisa...",
			"openFaq": "Ver no site",
			"unsupported": "Ainda não suportado: {{message}}"
		},
		"actions": {
			"premade": {
				"balanceteMesAnterior": "Balancete do mês anterior",
				"possoUsarTelemovel": "Posso usar no telemóvel?",
				"currentTime": "Que horas são?",
				"configurarProRata": "Como configurar pro rata?",
				"comoAtribuirPaisCliente": "Como atribuir pais a um cliente?"
			}
		}
	},
	"amortizacao": {
		"add": "Adicionar",
		"title_detail": "Código de depreciação {{id}}",
		"title_new": "Novo código de depreciação",
		"title_plural": "Códigos de depreciação",
		"pesquisa": "Pesquisar",
		"saved": "Código de depreciação {{id}}, guardado com sucesso.",
		"error": "Código de depreciação {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Código de depreciação {{id}}, apagado com sucesso",
		"fields": {
			"nImtab": "Cód.",
			"nImtabPlaceholder": "Código da amortização",
			"nome": "Nome",
			"nomePlaceholder": "Nome da amortização",
			"tipo": "Tipo",
			"tabela": "Tabela",
			"divisao": "Divisão",
			"grupo": "Grupo",
			"alinea": "Alínea",
			"artigo": "Artigo",
			"keyValue": "Key Value",
			"valor1": "Limite fiscal",
			"valor2": "Limite contabilístico",
			"valor3": "Taxa",
			"dataInicio": "Data início"
		}
	},
	"analisadorTes": {
		"btns": {
			"toolbarShortcuts": "Atalhos",
			"toolbarConfig": "Configuração",
			"toolbarPocMenu": "Clientes e fornecedores",
			"syncToolbar": "Sincronizar",
			"collapse": "Colapsar",
			"expand": "Expandir",
			"collapseAll": "Colapsar todos",
			"expandAll": "Expandir todos",
			"pesquisar": "Pesquisar",
			"editarPrevisaoRecebimento": "Editar previsão de recebimento",
			"alterarDataTesouraria": "Alterar data tesouraria",
			"removerDocumentoTesouraria": "Remover documento da tesouraria",
			"toolbarPreviTes": "Lançamento manual",
			"toolbarPreviTesAuto": "Lançamento automático",
			"extratoConta": "Extrato de conta",
			"toolbarPrevTesObras": "Importador de cronogramas financeiros das obras",
			"criarTesRubricas": "Criar rúbrica",
			"editarRubrica": "Editar rúbrica",
			"pagamento": "Pagamento",
			"recebimento": "Recebimento",
			"verDocumentoContabilidade": "Ver documento na contabilidade",
			"verDocumentoGestComercial": "Ver documento na gestão comercial",
			"removerTodosDocumentosDaConta": "Remover todos documentos da conta"
		},
		"messages": {
			"syncDados": "A sincronizar dados...",
			"deleteTesLancoMessage": "Tem a certeza que pretende eliminar o registo da tesouraria?",
			"datasAlteradasComSucesso": "As novas datas de tesouraria foram guardados com sucesso.",
			"temACertezaQueQuerEliminarTodosNConta": "Tem a certeza que pretende remover todos os documentos da tesouraria da conta \"{{nConta}}\"?",
			"aDataNaoPodeSerUmDiaDeFimDeSemana": "A data não pode ser um dia de fim de semana. Por favor, corrija a data para o próximo dia útil.",
			"aDataNaoPodeSerInferiorADataAtual": "A data não pode ser inferior à data atual!"
		},
		"strings": {
			"saldoInicial": "Saldo inicial",
			"saldoFinal": "Saldo final",
			"configModalTitle": "Configuração",
			"tabLotesTitle": "Pagamentos em lote",
			"manual": "Manual",
			"contabilidade": "Contabilidade",
			"cgBanking": "CG Banking",
			"origemSaldoInicial": "Origem do saldo inicial",
			"intervaloContas": "Intervalo de contas",
			"contaDe": "Conta de",
			"intervaloContasBancarias": "Intervalo de contas bancárias",
			"importaAutomaticamente": "Importa automaticamente",
			"rubricaParaRecEmLote": "Rúbrica para recebimentos em lote",
			"rubricaParaPagEmLote": "Rúbrica para pagamentos em lote",
			"periodicidadeAnalise": "Periodicidade da análise",
			"periodicidadeAnaliseMobile": "Periodicidade",
			"updateSaldoInicial": "Atualizar saldo inicial",
			"valor": "Valor",
			"dataUltimaAtualizacaoNota": "Data última atualização da nota",
			"diaria": "Diária",
			"semanal": "Semanal",
			"mensal": "Mensal",
			"rubrica": "Rúbrica",
			"descricao": "Descrição",
			"documento": "Documento",
			"nConta": "Nº conta",
			"nomeConta": "Nome conta",
			"novaData": "Nova data",
			"valoresAnt": "Valores com data de tesouraria anterior",
			"novaDataTesParaTodos": "Nova data tesouraria para todos"
		}
	},
	"analiticaconfig": {
		"radicaisList": "Lista de radicais",
		"radicaisExcluidoList": "Lista de radicais excluídos",
		"novoRadical": "Novo radical",
		"novoRadicalPlaceholder": "Insira as contas da contabilidade geral com analítica por ex:31",
		"novoRadicalExcluido": "Novo radical excluído",
		"novoRadicalExcluidoPlaceholder": "Insira as contas da contabilidade geral sem analítica por ex:3112",
		"messages": {
			"radicalJaExistente": "O radical inserido já existe na lista.",
			"radicalExcluidoJaExistente": "O radical excluído inserido já existe na lista.",
			"apagarItem": "Apagar linha",
			"desejaApagarItem": "Deseja apagar a linha?",
			"successfullySaved": "Alterações guardadas com sucesso.",
			"campoRadicalVazio": "Campo do radical não pode ser vazio.",
			"campoRadicalExcluidoVazio": "Campo do radical excluído não pode ser vazio."
		}
	},
	"anosFaturacao": {
		"pesquisa": "Pesquisar"
	},
	"aplicabilidadeIRCT": {
		"data": {
			"filiacao": "Filiação",
			"portariaExt": "Portaria de extensão",
			"escolha": "Escolha",
			"actoGest": "Acto de gestão",
			"sem": "Sem aplicabilidade",
			"auto": "Automática"
		}
	},
	"application": {
		"alt": {
			"logo": "Logótipo da aplicação",
			"companyLogo": "Logótipo da empresa"
		},
		"versionUpdate": {
			"title": "Nova versão disponível",
			"description": "Está disponível uma nova versão da aplicação, por favor,",
			"reload": "Clique aqui para atualizar a aplicação"
		},
		"maintenance": {
			"title": "Sistema em manutenção",
			"description": "O sistema encontra-se em manutenção. Por favor, aguarde antes de retomar o seu trabalho.",
			"endedTitle": "Manutenção do sistema terminada",
			"endedDescription": "Pode fechar este aviso e retomar o seu trabalho.",
			"lastChecked": "Última verificação: {{time}}"
		},
		"disconnected": {
			"title": "Comunicação com o servidor perdida",
			"description": "A comunicação com o servidor está com problemas. Por favor, aguarde enquanto tentamos restabelecer a ligação.",
			"restoredTitle": "Comunicação com o servidor restaurada",
			"restoredDescription": "A comunicação com o servidor foi restaurada. Pode fechar este aviso e retomar o seu trabalho.",
			"offline": "Encontra-se desligado do servidor. Por favor, verifique a sua ligação à internet."
		}
	},
	"apurainventperma": {
		"title": "Apuramento mensal do inventário permanente",
		"module": {
			"steps": {
				"inventario": "Inventário final do período",
				"documents": "Documentos de apuramento a gerar",
				"preview": "Pré-visualização de documentos"
			},
			"datagrids": {
				"infoApuramento": {
					"periodo": "Período",
					"apuramentoEfetuado": "Apuramento efetuado",
					"existeDocumento": "Existe documento",
					"temErros": "Tem erros"
				},
				"contas": {
					"codConta": "Código conta",
					"designacao": "Designação",
					"masterDetail": {
						"nConta": "Nº conta",
						"nome": "Nome",
						"valor": "Valor"
					}
				},
				"previsualDocumentos": {
					"extPocCabID": "PocCabID",
					"periodo": "Período",
					"nContaDebito": "Débito",
					"nContaCredito": "Crédito",
					"valor": "Valor",
					"descricao": "Descrição"
				}
			}
		},
		"modal": {
			"configContas": {
				"title": "Configuração de contas de apuramento",
				"mercadorias": {
					"title": "Mercadorias",
					"contaDeCompras": "Contas de compras de transferência para mercadorias",
					"contaDeMercadorias": "Contas de mercadorias de transferência de compras",
					"contaDeConsumo": "Conta de consumo de mercadorias",
					"contaDeTransferencia": "Conta de transferência de regularização de mercadorias"
				},
				"materiaPrima": {
					"title": "Matérias-primas",
					"contaComprasTransferenciaParaMateriasPrimas": "Conta de compras de transferência para matérias-primas",
					"contaMateriasPrimasParaTransferenciaCompras": "Conta de matérias-primas para transferência de compras",
					"contaConsumoMateriasPrimas": "Conta de consumo de matérias-primas",
					"contaMateriasPrimasParaRegularizacao": "Conta de matérias-primas para regularização"
				},
				"materiasSubsidiarias": {
					"title": "Matérias subsidiárias",
					"contaComprasTransferenciaParaMateriasSubsidiarias": "Conta de compras de transferência para matérias subsidiárias",
					"contaMateriasSubsidiariasParaTransferenciaCompras": "Conta de matérias subsidiárias para transferência de compras",
					"contaConsumoMateriasSubsidiarias": "Conta de consumo de matérias subsidiárias",
					"contaMateriasSubsidiariasParaRegularizacao": "Conta de matérias subsidiárias para regularização"
				},
				"embalagens": {
					"title": "Materiais embalagens",
					"contaComprasTransferenciaParaMateriaisEmbalagens": "Contas de compras transferências para materiais embalagens",
					"contaMateriaisEmbalagensParaTransferenciaCompras": "Conta de materiais embalagens para transferências de compras",
					"contaConsumoEmbalagensParaConsumo": "Conta de consumo de embalagens para consumo",
					"contaMateriaisEmbalagensParaRegularizacao": "Conta de materiais embalagens para regularização"
				},
				"materiaisDiversos": {
					"title": "Materiais diversos",
					"contaComprasTransferenciaParaMateriaisDiversos": "Conta de compras transferências para materiais diversos",
					"contaMateriaisDiversosParaTransferenciaCompras": "Conta de materiais diversos para transferências de compras",
					"contaConsumoMateriaisDiversosParaConsumo": "Conta de consumo de materiais diversos",
					"contaMateriaisDiversosParaRegularizacao": "Conta de materiais diversos para regularização"
				},
				"materiasTransito": {
					"title": "Matérias em trânsito",
					"contaComprasTransferenciaParaMateriasTransito": "Conta de compras de transferências para materiais diversas",
					"contaMateriasTransitoParaTransferenciaCompras": "Conta de matérias em trânsito para transferências de compras",
					"contaConsumoMateriasTransitoParaConsumo": "Conta de consumo de matérias em trânsito",
					"contaMateriasTransitoParaRegularizacao": "Conta de matérias em trânsito para regularização"
				},
				"produtosAcabados": {
					"title": "Produtos acabados",
					"contaVariacaoProducaoParaProdutosAcabados": "Conta de variação de produção para produtos acabados",
					"contaRegularizacaoExistenciaParaProdutosAcabados": "Conta regularização existência para produtos acabados"
				},
				"subProdutos": {
					"title": "Subprodutos",
					"contaVariacaoProducaoParaSubprodutos": "Conta de variação de produção para subprodutos",
					"contaRegularizacaoExistenciaParaSubprodutos": "Conta regularização existência para subprodutos"
				},
				"produtosTrabalhosEmCurso": {
					"title": "Produtos e trabalhos em curso",
					"contaVariacaoProducaoParaProdutosTrabalhosEmCurso": "Conta variação produção produtos e trabalhos em curso",
					"contaRegularizacaoExistenciaParaProdutosTrabalhosEmCurso": "Conta regularização existência produtos e trabalhos em curso"
				},
				"activosBiologicosCompras": {
					"title": "Ativos biológicos de compras",
					"contaComprasTransferenciaParaActivosBiologicosConsumo": "Conta compras de transferências para ativos biológicos de consumo",
					"contaActivosBiologicosConsumoParaTransferenciaCompras": "Conta ativos biológicos de consumo para transferências de compras",
					"contaConsumoActivosBiologicosConsumoParaConsumo": "Conta consumo de ativos biológicos de consumo",
					"contaActivosBiologicosConsumoParaRegularizacao": "Conta ativos biológicos de consumo para regularização"
				},
				"activosBiologicosProducao": {
					"title": "Ativos biológicos de produção",
					"contaExistenciaActivosBiologicosProducao": "Conta de existência para ativos biológicos de produção",
					"contaVariacaoProducaoParaActivosBiologicosProducao": "Conta variação de produção para ativos biológicos produção",
					"contaRegularizacaoExistenciaParaActivosBiologicosProducao": "Conta regularização existência ativos biológicos de produção"
				}
			}
		},
		"btns": {
			"configContas": "Configuração de contas"
		},
		"actions": {
			"verDocumentoApuramento": "Ver documento de apuramento deste período",
			"apagarDocumentoDeApuramento": "Apagar documento de apuramento"
		},
		"menagens": {
			"valoresOriginaisRepostos": "Valores originais repostos com sucesso",
			"savedSuccess": "Configuração de contas guardada com sucesso",
			"apuramentoJaExiste": "Apuramento já foi efetuado",
			"periodoAlreadyHasApuramento": "Já efetuou o apuramento mensal do inventário permanente para o período '{{periodo}}'. Se continuar este documento será eliminado. Tem a certeza que pretende continuar?",
			"documentosGravadosSucesso": "Documentos gravados com sucesso",
			"apagarDocumentoApuramentoTitle": "Apagar documento de apuramento",
			"apagarDocumentoApuramentoMessage": "Pretende mesmo apagar o documento de apuramento do período {{periodo}}?",
			"naoDeveFazerOApuramentoDeInventario": "O apuramento mensal do inventário permanente no período 12 deve ser feito a partir da aplicação de apuramento de resultados. No entanto, é possível continuar esta operação."
		}
	},
	"apuraiva": {
		"success": "Apuramento de IVA efetuado com sucesso",
		"promptAlreadyExistsTitle": "Apuramento de IVA já foi efetuado",
		"promptAlreadyExistsMessage": "O apuramento de IVA para o período \"{{periodo}}\" já foi efetuado. Se continuar o documento que contém o apuramento anterior será eliminado. Deseja continuar?",
		"errorShortValue": "Tem {{value}} a mais do que o valor apurado",
		"errorExtraValue": "Faltam {{value}} para perfazer o total do apuramento",
		"saveTitle": "Apuramento de IVA",
		"saveMessage": "Tem a certeza que deseja finalizar o apuramento de IVA?",
		"docsporintegrar": "Existem documentos por integrar na contabilidade referentes ao período de IVA em apuramento.",
		"saveSeeDoc": "Guardar e ver documento gerado",
		"errorInvalidPeriodoMensal": "O período {{periodo}} é inválido na lista de períodos mensais",
		"errorInvalidPeriodoTrimestral": "O período {{periodo}} é inválido na lista de períodos trimestrais",
		"apurado": "apurado",
		"steps": {
			"filter": "Filtro de apuramento de IVA",
			"apuramento": "Apuramento de IVA no período",
			"fimperiodo": "Fim de período contabilístico"
		},
		"fields": {
			"descriptive": "Descritivo",
			"period": "Período",
			"periodCurrent": "Período atual",
			"periodNext": "Novo período",
			"diarios": "Diários a encerrar",
			"ivaDedutivel": "Montante IVA dedutível",
			"ivaLiquidado": "Montante IVA liquidado",
			"ivaApurado": "Montante apurado",
			"aFavorDaEmpresa": "Beneficiário",
			"aFavorDaEmpresa0": "A favor da empresa",
			"aFavorDaEmpresa1": "A favor do estado",
			"ivaRegFavEmpresa": "Mensais / trimestrais a favor da empresa",
			"ivaRegFavEstado": "Mensais / trimestrais a favor do estado",
			"ivaRegCalculoProRata": "Anuais por cálculo do pro rata definitivo",
			"ivaVariacoesProRata": "Anuais por variações dos pro rata definitivos",
			"ivaRegComunicadasPeloSIVA": "A favor da empresa comunicadas pelo SIVA",
			"ivaRegOutrasAnuais": " Outras regularizações anuais",
			"saldoAnterApuramento": "Apuramento anterior",
			"saldoAnterIVAAPagar": "IVA a pagar",
			"saldoAnterIVAARecuperar": "IVA a recuperar",
			"saldoAnterIVAAReembolsar": "IVA a reembolsar",
			"ivaAPagar": "IVA a pagar",
			"ivaARecuperar": "IVA a recuperar",
			"ivaAReembolsar": "IVA a reembolsar"
		},
		"titles": {
			"regularizations": "Regularizações",
			"details": "Informação detalhada do apuramento",
			"information": "Informação dos saldos anteriores",
			"destination": "Destino dos valores apurados",
			"tabDestination": "Destino do apuramento e saldos anteriores",
			"tabDetails": "Detalhes do apuramento de IVA",
			"change": "Mudança de período contabilístico"
		}
	},
	"apuramentoresultados": {
		"title_complex": "Apuramento de resultados líquidos - {{year}}",
		"title_complex_apurado": "Apuramento de resultados líquidos - {{year}} (apurado)",
		"pesquisa": "Pesquisar",
		"success": "Apuramento de resultados efetuado com sucesso",
		"deleted": "Apuramento de resultados eliminado com sucesso",
		"beforeDeleteModalMessage": "Tem a certeza que pretende eliminar o apuramento de resultados?",
		"promptAlreadyExistsTitle": "Apuramento de resultados já foi efetuado",
		"promptAlreadyExistsMessage": "O apuramento de resultados para o ano \"{{year}}\" já foi efetuado. Se continuar com o apuramento de resultados estes documentos serão eliminados. Deseja continuar?",
		"anoapuramento": "Ano de apuramento: {{ano}}",
		"tipoinvpermanente": "Tipo inventário permanente",
		"steps": {
			"variables": "Variáveis de apuramento",
			"documents": "Documentos de apuramento a gerar",
			"preview": "Pré-visualização de documentos",
			"finished": "Terminado"
		},
		"fields": {
			"codConta": "Cód. conta",
			"nConta": "Nº conta",
			"nomeConta": "Designação",
			"nome": "Nome",
			"valor": "Valor",
			"descritivo": "Descritivo",
			"dataDoc": "Data doc",
			"description": "Descrição"
		},
		"titles": {
			"generatedDocs": "Documentos gerados"
		},
		"errorInvalidData": "Por favor preencha os campos para processar",
		"errorMissingProcess": "Por favor processe os dados antes de guardar",
		"toolbar": {
			"analise": "Análise",
			"infoSaldosInv": "Informação sobre saldos de contas de inventário",
			"docsSaldosErrados": "Documentos inconsistentes com o inv. permanente do Centralgest",
			"docsContasInventario": "Documentos com lançamentos manuais em contas de inventários",
			"delete": "Eliminar apuramento"
		},
		"modal": {
			"saldoscontinv": {
				"title": "Informação sobre saldos de contas de inventário",
				"periodo": "Período",
				"compras": "Compras",
				"produtos": "Produtos",
				"fields": {
					"nomeConta": "",
					"saldoInicial": "Saldo inicial",
					"compras": "Compras ilíquidas",
					"devolucao": "Devolução",
					"descontos": "Descontos",
					"totCompras": "Total compras",
					"comprasTrf": "Compras trf.",
					"difCompras": "Dif. compras",
					"saldoFinal": "Saldo final",
					"regularizacoesRegistadas": "Reg. registadas",
					"regularizacoesTrfConsumo": "Reg. trf. consumo",
					"difRegularizacoes": "Dif. regularizações",
					"consumo": "Consumo",
					"consCalculado": "Cons. calculado",
					"difConsumo": "Dif. consumo",
					"regularizacoesTrfVariacao": "Reg. trf. variação",
					"difRegularizacoesProducao": "Dif. regularizações",
					"variacaoProducao": "Variação produção",
					"varProducaoCalc": "Variação produção calculada",
					"difProducao": "Dif. variação produção"
				}
			},
			"docscontasinventario": {
				"naoExistemDocumentosComMovimenta": "Não existem documentos com movimentação de contas de inventário.",
				"naoExistemDocumentosComSaldosDeC": "Não existem documentos com saldos de contas de inventário errados.",
				"title": "Documentos com lançamentos manuais em contas de inventários",
				"titleIcons": "Documentos inconsistentes com o inv. permanente do Centralgest",
				"fields": {
					"inconsistenciasDocApu": "Inconsistência",
					"periodo": "Período",
					"nDiario": "Diário",
					"nDocinterno": "Nº documento"
				}
			}
		}
	},
	"arclis": {
		"title_detail": "Artigo de cliente / fornecedor",
		"title_new": "Novo Artigo de cliente / fornecedor",
		"title_plural": "Artigos de cliente / fornecedor",
		"pesquisa": "Pesquisar",
		"saved": "Artigo de cliente / fornecedor, guardado com sucesso.",
		"error": "Artigo de cliente / fornecedor, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Artigo de cliente / fornecedor, apagado com sucesso.",
		"deleteModalText": "Apagar registo \"{{nClifoArtigo}}\"?",
		"fields": {
			"nClifoArtigo": "Cód. artigo do cli. / forn.",
			"nArtigo": "Cód. artigo",
			"nConta": "Cliente / fornecedor",
			"descricaoFornecedor": "Descrição do artigo do cli. / forn."
		},
		"btn": {
			"newArtigo": "Novo"
		},
		"list": {
			"actions": {
				"editar": "Editar"
			}
		}
	},
	"areaRegionalTaxa": {
		"pesquisa": "Áreas regionais taxa",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"continent": "Continente",
			"madeira": "Madeira",
			"acores": "Açores"
		}
	},
	"areasRegionais": {
		"data": {
			"0": "Continente",
			"1": "Madeira",
			"2": "Açores",
			"continente": "Continente",
			"madeira": "Madeira",
			"acores": "Açores"
		}
	},
	"areasRegionaisControloIVA": {
		"data": {
			"0": "N/A",
			"1": "Continente",
			"2": "Madeira",
			"3": "Açores",
			"notApplicable": "N/A",
			"continente": "Continente",
			"madeira": "Madeira",
			"acores": "Açores"
		}
	},
	"armazens": {
		"title_detail": "Armazém {{id}}",
		"title_new": "Novo Armazém",
		"title_plural": "Armazéns",
		"pesquisa": "Pesquisar",
		"saved": "Armazém {{id}}, guardado com sucesso.",
		"error": "Armazém {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Armazém {{id}}, eliminado com sucesso.",
		"fields": {
			"nArmazem": "Cód.",
			"nArmazemPlaceholder": "Código do armazém",
			"nome": "Nome",
			"nomePlaceholder": "Nome do armazém",
			"morada1": "Morada",
			"morada1Placeholder": "Morada do armazém",
			"morada2": "Morada",
			"codPostal": "Cód. postal",
			"codPostalPlaceholder": "Código postal do armazém",
			"nomeCPostal": "Localidade",
			"nomeCPostalPlaceholder": "Localidade do armazém",
			"codPais": "Cód. país",
			"codPaisPlaceholder": "País do armazém",
			"nomePais": "País",
			"nomePaisPlaceholder": "País do armazém",
			"nTelefone": "Telefone",
			"nTelefonePlaceholder": "Telefone do armazém",
			"nFax": "Fax",
			"nFaxPlaceholder": "Fax do armazém"
		}
	},
	"arquivodigital": {
		"errors": {
			"licenseNotActivated": "A empresa atual não tem a licença de arquivo digital ativada. Por favor ative a licença da contabilidade digital.",
			"licenseNotActivatedCGOn": "A empresa atual não tem a licença de arquivo digital ativada.<br/>Por favor, aceda à <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\">loja</a> do CentralGest Cloud e ative ou renove a licença da contabilidade digital.",
			"nifFinalInferiorInicial": "O nif final é inferior ao inicial",
			"docExtFinalInferiorInicial": "O doc. externo final é inferior ao inicial",
			"dataDocExtFinalInferiorInicial": "A data do doc. externo final é inferior à inicial",
			"dataRecFinalInferiorInicial": "A data de receção final é inferior à inicial"
		},
		"gestaodocsdigitalizados": {
			"actions": {
				"obterDadosEFatura": "Obter dados do documento selecionado do eFatura (precisa de NIF e data documento preenchida)",
				"atribuirTipoDocumento": "Atribuir tipo documento",
				"unirDocs": "Unir documentos selecionados",
				"separarDoc": "Separar páginas do documento",
				"copiarDoc": "Copiar documento",
				"removePages": "Remover página(s) do documento",
				"apagar": "Eliminar documento(s) selecionado(s)",
				"removeassociation": "Remover associação ao(s) documento(s) de contabilidade",
				"verDoc": "Ver documento de contabilidade",
				"novaContaCC": "Criar nova conta corrente",
				"exportarArquivo": "Exportar arquivo digital",
				"adicionardoc": "Adicionar documento(s)",
				"mudardocpasta": "Mudar documento(s) selecionado(s) de pasta",
				"reaplicarocr": "Voltar a aplicar OCR no documento",
				"predefinidos": "Configuração de pré-definidos para a entidade",
				"docsContabSemContabDig": "Documentos da contabilidade sem documento digital",
				"robo": "Lançar documentos usando Robô de IA CentralGest",
				"extratoConta": "Ver extrato de conta",
				"associardocsauto": "Associar todos os documentos automaticamente",
				"associardocsincluidoanuladosauto": "Associar a todos os documentos automaticamente incluindo os documentos anulados",
				"possiveisDocsRepetidos": "Ver possíveis documentos repetidos"
			},
			"pesqavancada": {
				"title": "Pesquisa avançada",
				"nifDe": "NIF de",
				"docExternoDe": "Doc. externo de",
				"dataDocExternoDe": "Data doc. externo de",
				"dataRececaoDe": "Data receção de",
				"mostrarDocClassificados": "Mostrar também documentos já classificados",
				"mostraApenasLancRobot": "Mostrar apenas documentos já classificados pelo robô"
			},
			"table": {
				"fields": {
					"folder": "Pasta",
					"nif": "NIF",
					"entidade": "Entidade",
					"temNIFEmpresa": "Tem NIF empresa",
					"invoiceTypeStr": "Tipo documento",
					"nDocumento": "Doc. externo",
					"totalBase": "Total base",
					"totalIVA": "Total IVA",
					"pasta": "Pasta",
					"ficheiro": "Ficheiro",
					"fiscalmenteRelevante": "Fiscalmente relevante",
					"dataRececao": "Data receção",
					"classficadoPor": "Classificado por",
					"ndocContabilidade": "Nº doc. contabilidade",
					"ndocumentoSAFT": "Nº documento SAFT",
					"temNIFEmpresamin": "Tem NIF empresa",
					"invoiceTypeStrmin": "Tipo doc.",
					"fiscalmenteRelevantemin": "Fiscalmente relevante",
					"classficadoPormin": "Class. por",
					"ndocContabilidademin": "Nº doc. contab.",
					"ndocumentoSAFTmin": "Nº doc. SAFT",
					"preDefenidosID": "Pré-definido",
					"actions": {
						"adicionardoc": "Adicionar documento(s)",
						"mudardocpasta": "Mudar documento(s) selecionado(s) de pasta",
						"reaplicarocr": "Voltar a aplicar OCR no documento",
						"obterDadosEFatura": "Obter dados do(s) documento(s) do eFatura",
						"atribuirTipoDocumento": "Atribuir tipo documento",
						"unirDocs": "Unir documentos selecionados",
						"separarDoc": "Separar documento",
						"copiarDoc": "Copiar documento",
						"removePages": "Remover página(s)",
						"apagar": "Eliminar documento(s) selecionado(s)",
						"verDoc": "Ver documento de contabilidade",
						"novaContaCC": "Criar nova conta corrente"
					},
					"docSemelhante": {
						"nDocumento": "Nº documento",
						"nDocExterno": "Nº doc. externo",
						"nContrib": "Nº contribuinte",
						"descricao": "Descrição",
						"dataDocExt": "Data documento",
						"docspossiveis": "Documentos possiveis de associar"
					}
				},
				"havechanges": "Existem alterações ainda não guardadas."
			},
			"legend": {
				"error": "Valor não obtido ou errado",
				"warning": "Valor obtido mas deve ser revisto",
				"waitingocr": "Documento sem OCR efetuado",
				"comdocparaassociar": "Com documento na contabilidade para associar",
				"docassociado": "Já associado a documento de contabilidade"
			},
			"messages": {
				"confirmDelete": "Tem certeza que pretende eliminar o documento selecionado?",
				"confirmDeletePlural": "Tem certeza que pretende eliminar os documentos selecionados?",
				"exitTitle": "Pretende sair sem gravar as alterações?",
				"exitMessage": "Ao clicar em OK irá sair sem gravar e todas as alterações serão apagadas. Tem a certeza que pretende sair?",
				"updateWithChangesTitle": "Pretende refrescar os dados sem gravar as alterações?",
				"updateWithChangesMessage": "Ao clicar em OK irá refrescar os dados sem gravar e todas as alterações serão apagadas. Tem a certeza que pretende refrescar os dados?",
				"naotemalteracoes": "Não existem alterações para guardar.",
				"docvalidadoefatura": "Documento encontrado e validado pelo eFatura.",
				"docQR": "Documento carregado por QR Code",
				"contabDigitalLancadoRoboSuccess": "Documentos selecionados lançados com sucesso",
				"lancrobotdoc": "Documento {{filename}}. Este documento foi lançado automaticamente pelo nosso Robô de IA CentralGest. Nº Documento: {{ndocumentointerno}}.",
				"deleteAttachment": {
					"titleDelete": "Apagar documento digital",
					"titleRemoveAssociation": "Remover associação ao documento de contabilidade",
					"messageRemoveAssociation": "Deseja remover a associação deste documento digital ao documento de contabilidade?<br/>Ao remover a associação o documento fica novamente disponível na gestão de documentos digitais como não classificado."
				},
				"modal": {
					"treedocsnaoclassificados": {
						"escolhapastaadd": "Escolha a pasta para onde pretende adicionar os documentos",
						"errorMoveDocSameFolder": "Não pode mover o documento para a mesma pasta onde o mesmo se encontra."
					},
					"adicionardocumentos": {
						"escolhadocumentos": "Escolha o(s) documento(s)",
						"success": "Documentos adicionados com sucesso! Os serviços CentralGest vão agora processar o OCR do(s) documento(s). Pode refrescar os dados quando pretender ou fazer outras tarefas enquanto o processamento do OCR é efetuado."
					},
					"export": {
						"title": "Exportação do arquivo digital",
						"periodoDe": "Período de",
						"diarioDe": "Diário de",
						"nifDe": "NIF de",
						"exportaNaoClassificados": "Exporta apenas documentos não classificados",
						"exportaApenasRelevantes": "Exporta apenas documentos fiscalmente relevantes",
						"exportar": "Exportar",
						"folder": "Pasta",
						"searchAllFolders": "Pesquisar em todas as pastas",
						"searchExportFolder": "Pesquisar pasta a exportar"
					},
					"exportfolder": {
						"documentosClassificados": "Documentos classificados",
						"documentosNaoClassificados": "Documentos não classificados"
					}
				},
				"docsassociados": "Documento/s associado/s com sucesso.",
				"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmação",
				"leavePromptMessage": "Ao clicar em SIM irá sair e descartar o ficheiro gerado. Tem a certeza que pretende sair?"
			},
			"alerts": {
				"deletesuccess": "Documento(s) eliminado(s) com sucesso!",
				"associacaoremoved": "Associação dos documento(s) removida com sucesso!",
				"selsamefolder": "Deve selecionar documentos da mesma pasta."
			},
			"btn": {
				"alterapasta": "Alterar pasta",
				"refresh": "Refrescar dados",
				"associar": "Associar"
			},
			"export": {
				"processLabel": "Exportação: A preparar o arquivo...",
				"processDownloadTooltip": "Efetuar download do arquivo",
				"downloadText": "Descarregar",
				"zipReadyText": "O arquivo está pronto.",
				"downloadError": "Ocorreu um erro ao efetuar o download do ficheiro!",
				"jobTimeoutModalTitle": "Tarefa",
				"jobTimeoutModalMessage": "A tarefa excedeu o tempo limite. Tem que iniciar a importação novamente.",
				"fileNotProcessed": "Lamentamos, mas por alguma razão o ficheiro não foi gerado."
			},
			"errors": {
				"addFilesDisallowed": "De momento, a sua licença do módulo de arquivo digital não permite adicionar mais documentos digitais.<br/>Por favor, aceda à <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\">loja</a> do CentralGest Cloud e ative ou renove a licença de arquivo digital."
			},
			"docscontabsemdocdigital": {
				"title": "Documentos da contabilidade sem documento digital",
				"header": {
					"periodo": "Período",
					"diario": "Diário"
				},
				"table": {
					"periodo": "Período",
					"nDiario": "Diário",
					"nDocInterno": "Nº Doc. interno",
					"debito": "Débito",
					"credito": "Crédito",
					"nDocExterno": "Nº Doc. externo",
					"dataDoc": "Data doc.",
					"dataDocExterno": "Data doc. externo",
					"descritivo": "Descritivo",
					"descricao": "Descrição",
					"contrib": "Nº contribuinte",
					"contaCorrente": "Nº conta",
					"nomeContaCorrente": "Nome"
				}
			}
		},
		"genericviewer": {
			"text": {
				"currentFolder": "Pasta atual",
				"root": "Raiz"
			}
		},
		"treeviewer": {
			"actions": {
				"addDoc": "Adicionar documentos a esta pasta",
				"captureDoc": "Capturar documento e adicionar à pasta atual",
				"deleteDocs": "Apagar todos os documentos desta pasta",
				"deleteDoc": "Apagar documento"
			}
		},
		"flatviewer": {
			"titles": {
				"folders": "Pastas",
				"files": "Ficheiros",
				"empty": "(Vazio)"
			}
		},
		"docviewer": {
			"actions": {
				"zoomIn": "Zoom in",
				"zoomOut": "Zoom out",
				"rotateLeft": "Rodar esquerda",
				"rotateRight": "Rodar direita"
			}
		},
		"docviewerrecolha": {
			"title": {
				"location": "Localização",
				"attachment": "Anexo",
				"addDocs": "Adicionar documentos",
				"addDocsCurrentFolder": "Adicionar documentos à pasta atual",
				"addDocsWithPath": "Adicionar documentos à pasta atual ({{folderPath}})",
				"attachDoc": "Anexar documento digital",
				"chooseFolder": "Escolher pasta",
				"scannedDocumentManagement": "Gestão de documentos digitalizados",
				"deleteAttachment": "Remover anexo",
				"download": "Transferir documento",
				"noAttachment": "Sem documento digital",
				"attachmentNotFound": "Documento digital não encontrado",
				"attachmentFound": "Documento digital encontrado",
				"attachmentPossiblyFound": "Encontrámos um documento digital possível, por favor, verifique se o documento digital sugerido corresponde ao documento que está a lançar",
				"attachmentMultipleFound": "Encontrámos vários documentos digitais possíveis, por favor, verifique se o documento digital sugerido corresponde ao documento que está a lançar",
				"linhasIVA": {
					"valorBase": "Incidência",
					"valorIVA": "IVA",
					"tipoTaxa": "Tipo de taxa",
					"areaRegional": "Área regional",
					"taxa": "Taxa"
				},
				"totais": {
					"baseTributavel": "Base tributável",
					"totalIVA": "Total IVA",
					"valorRetencao": "Valor retenção"
				}
			},
			"navigation": {
				"selectFirstAttachment": "Ir para primeiro anexo",
				"selectPreviousAttachment": "Anterior",
				"selectNextAttachment": "Seguinte",
				"selectLastAttachment": "Ir para último anexo"
			},
			"actions": {
				"attachExistingDoc": "Anexar um documento existente na contabilidade digital ao documento atual",
				"attachNewDoc": "Adicionar novo documento na contabilidade digital e anexar ao documento atual",
				"attachVerbete": "Adicionar verbete ao documento digital",
				"deleteAttachment": {
					"titleDelete": "Apagar documento digital",
					"titleRemoveAssociation": "Remover associação ao documento de contabilidade",
					"messageDelete": "Tem a certeza que pretende apagar o documento?<br/><span class=\"text-danger\">Atenção:</span> se o documento foi enviado pelo seu cliente de serviços de contabilidade ao apagá-lo terá de pedir para este ser reenviado.",
					"messageRemoveAssociation": "Deseja remover a associação deste documento digital ao documento de contabilidade?<br/>Ao remover a associação o documento fica novamente disponível na gestão de documentos digitais como não classificado."
				}
			},
			"text": {
				"warningHasDigitalDoc": "O documento \"{{periodo}}.{{nDiario}}.{{nDocInterno}}\" já tem pelo menos um documento digital associado.<br/>Tem a certeza que deseja também anexar mais um documento digital?",
				"successAttachDoc": "Documento digital anexado com sucesso",
				"successAttachVerbete": "Verbete adicionado ao documento digital com sucesso",
				"warningDateMismatch": "Está a anexar um documento digital que foi definido com a data de \"{{dataDoc}}\" e o seu documento de contabilidade tem a data de \"{{dataDocDigital}}\".<br/>Tem a certeza que pretende continuar?",
				"valuesSource": {
					"0": "Leitura OCR",
					"1": "Leitura de código de barras",
					"2": "e-Fatura"
				},
				"documento": "Documento: {{nDocumento}}",
				"emptyLocation": "Clique aqui para escolher a pastas de documentos da contabilidade digital.",
				"emptyAttachments1": "Sem documentos na pasta \"{{location}}\".",
				"emptyAttachments2": "Clique aqui para mudar de pasta."
			},
			"choosefoldermodal": {
				"title": "Escolha de pastas de documentos",
				"titleFileRequired": "Escolha de documento digital"
			},
			"attachexistingdocmodal": {
				"title": "Anexar novo documento"
			},
			"helptopicsmodal": {
				"faq": {
					"basicConcepts": "Conceitos básicos do arquivo digital",
					"addDocuments": "Adicionar documentos digitais ao arquivo digital",
					"showHideColumns": "Mostrar e ocultar colunas na grelha de documentos digitais",
					"setFileFolders": "Definir as pastas do arquivo digital",
					"moveAttachmentToAnotherFolder": "Mudar o documento digital para outra pasta",
					"createCheckingAccountFromAttachment": "Criar uma conta corrente a partir do documento digital",
					"speedUpManualAccountingRecordingWithAttachmentData": "Acelerar o registo contabilístico manual com os dados do documento digital",
					"addDocumentsThroughPreDefined": "Lançar na contabilidade os documentos digitais através de pré-definidos"
				}
			}
		},
		"clientconnect": {
			"actions": {
				"addDocs": "Adicionar documentos à pasta atual",
				"captureDoc": "Capturar documento e adicionar à pasta atual",
				"deleteDoc": {
					"btn": "Apagar documento",
					"title": "Apagar documento \"{{name}}\"?",
					"message": "Tem a certeza que deseja apagar o documento selecionado?"
				}
			},
			"tooltips": {
				"addDocs": "Por favor selecione uma pasta válida para adicionar documentos",
				"captureDoc": "Por favor selecione uma pasta válida para capturar um documento",
				"deleteDoc": "Por favor selecione um documento para apagar o mesmo"
			},
			"text": {
				"successDeleteDoc": "Documento apagado com sucesso"
			},
			"adddocsmodal": {
				"title": "Adicionar documentos",
				"sendDocs": "Enviar ficheiros",
				"success": "Documentos adicionados com sucesso"
			},
			"deletedocsmodal": {
				"title": "Apagar documentos",
				"message": "Tem a certeza que deseja apagar os documentos da pasta \"{{path}}\"?"
			}
		},
		"activatelicensemodal": {
			"text": {
				"total": "Número total de empresas: {{total}}",
				"totalConsumed": "Número total de empresas já adicionadas: {{totalConsumed}}",
				"totalToConsume": "Número total de empresas que ainda pode adicionar: {{totalToConsume}}"
			},
			"types": {
				"0": {
					"title": "Ativar arquivo digital na empresa \"{{nEmpresa}} - {{nomeEmpresa}}\"",
					"license1": "Caso clique em \"Sim\", o sistema irá adicionar uma nova empresa à sua licença de arquivo digital, não sendo possível reverter a situação posteriormente.",
					"license2": "Neste momento, ainda pode adicionar à sua licença da arquivo digital {{totalToConsume}} empresas. Deseja adicionar uma nova empresa à sua licença de arquivo digital?",
					"dadosLicenca": "Dados da licença de arquivo digital",
					"limitReached": "Não pode adicionar mais empresas à sua licença de arquivo digital, porque já tem \"{{consumidas}}\" empresas de \"{{total}}\" registadas. Por favor contate a CentralGest para adquirir mais empresas.",
					"errorCGOnMode": "Não pode ativar a arquivo digital, porque esta é uma empresa CGOn."
				},
				"1": {
					"title": "Ativar client connect na empresa \"{{nEmpresa}} - {{nomeEmpresa}}\"",
					"license1": "Caso clique em \"Sim\", o sistema irá adicionar uma nova empresa à sua licença de client connect, não sendo possível reverter a situação posteriormente.",
					"license2": "Neste momento, ainda pode adicionar à sua licença da client connect {{totalToConsume}} empresas. Deseja adicionar uma nova empresa à sua licença de contabilidade digital?",
					"dadosLicenca": "Dados da licença de client connect",
					"limitReached": "Não pode adicionar mais empresas à sua licença de client connect, porque já tem \"{{consumidas}}\" empresas de \"{{total}}\" registadas. Por favor contate a CentralGest para adquirir mais empresas.",
					"errorCGOnMode": "Não pode ativar o client connect, porque esta é uma empresa CGOn."
				}
			}
		},
		"atribuirtipodocumentomodal": {
			"title": "Indique o tipo de documento que pretende atribuir",
			"label": "Tipo de documento",
			"required": "Tem de indicar o tipo de documento que pretende.",
			"success": "Tipo de documento atribuído com sucesso."
		},
		"checkfolderscreatedmodal": {
			"title": "A inicializar arquivo digital",
			"message": "Por favor aguarde enquanto a arquivo digital está a ser inicializada. Este processo poderá demorar alguns minutos."
		},
		"copiardocmodal": {
			"title": "Copiar documento",
			"body": "Tem a certeza que deseja criar um cópia do documento \"{{name}}\"?",
			"success": "Cópia do documento criada com sucesso.<br/>Nome do ficheiro: \"{{name}}\"."
		},
		"novacontaccmodal": {
			"title": "Criar nova conta corrente",
			"fields": {
				"radical": "Radical",
				"nConta": "Nº conta a criar",
				"nome": "Nome"
			},
			"successModal": {
				"title": "Visualizar a ficha do {{type}}",
				"message": "Conta corrente de {{type}} criada com sucesso.<br/>Deseja editar a ficha da mesma?",
				"types": {
					"fornecedor": "fornecedor",
					"cliente": "cliente",
					"outroDevedorCredor": "outro devedor/credor"
				}
			}
		},
		"removepagesmodal": {
			"title": "Indique a(s) página(s) que pretende remover",
			"label": "Página(s) a remover",
			"help1": "Se pretende remover mais que uma página separe por vírgula ou indique um intervalo.",
			"help2": "Por exemplo: 1,4,5 - remove as páginas 1, 4 e 5.",
			"help3": "Por exemplo: 6-10 - remove as páginas 6, 7, 8, 9 e 10.",
			"success": "Página(s) removida(s) com sucesso."
		},
		"separardocmodal": {
			"title": "Separar documento",
			"body1": "Deseja manter o documento original?",
			"success": "Documento separado com sucesso!"
		},
		"unirdocsmodal": {
			"title": "Unir documentos",
			"body1": "Ao efetuar esta operação vai unir o(s) documento(s)",
			"body2": "Ao documento",
			"body3": "Tem a certeza que pretende continuar?",
			"success": "Documentos unidos com sucesso."
		},
		"predefinidosconfig": {
			"title": "Configurações de pré-definidos por NIF/Tipo de documento",
			"fatura": "Fatura",
			"faturaSimplificada": "Fatura simplificada",
			"faturaRecibo": "Fatura/Recibo",
			"notaDebito": "Nota débito",
			"notaCredito": "Nota crédito",
			"vendaDinheiro": "Venda a dinheiro",
			"talaoVenda": "Talão de venda",
			"talaoDevolucao": "Talão de devolução",
			"alienacaoAtivos": "Alienação de ativos",
			"devolucaoAtivos": "Devolução de ativos",
			"premio": "Prémio ou recibo prémio",
			"estorno": "Estorno ou recibo estorno",
			"imputacaoSeguradoras": "Imputação a co-seguradoras",
			"imputacaoSegLider": "Imputação a co-seg. líder",
			"recibo": "Recibo",
			"pagamentoEstado": "Pagamento ao estado",
			"levantamento": "Levantamento",
			"pagamento": "Pagamento",
			"deposito": "Depósito",
			"cheque": "Cheque",
			"transferencia": "Transferência",
			"efaturainfo": "Este valor foi obtido das configurações do eFatura para este contribuinte.",
			"success": "Configuração gravada com sucesso.",
			"predefnotacredito": "O pré-definido para notas de crédito não deve ser igual ao dos outros tipos de documento. <br> Tem a certeza que prentede continuar?",
			"warningEFatura": "*Se pretende lançar os documentos por eFatura não necessita de configurar pré-definidos",
			"lancfaturahint": "Clique para obter ajuda sobre esta funcionalidade",
			"lancfaturahinttitle": "Lançamentos automáticos por Robô",
			"lancfaturahintinfo": "Se ativar lançamentos por Robô nesta entidade, o sistema vai lançar os documentos na contabilidade automaticamente após serem adicionados. <br><br> O sistema vai podr lançar os documentos com código de barras ou que existam no eFatura e para os quais encontre um modelo de lançamento anteriormente feito na contabilidade para o NIF do documento e com as mesmas taxas de IVA. <br><br> Conforme vai lançando documento da contabilidade digital o sistema vai aprendendo a fazer os lançamentos por si. <br><br> Pode também ativar esta funcionalidade na configuração da contabilidade digital para as entidades.",
			"nif": "Contribuinte",
			"nomeFornecedor": "Nome fornecedor"
		},
		"configs": {
			"tabs": {
				"defgerais": {
					"title": "Definições gerais",
					"carddefgerais": "Definições gerais",
					"cardverbete": "Verbete de documento",
					"cardconfigpastas": "Configuração de pastas"
				},
				"efatura": {
					"title": "eFatura",
					"comprasefatura": "Compras eFatura",
					"defoticocaracteres": "Definições de reconhecimento ótico de caracteres (OCR)"
				},
				"lancautorobo": {
					"title": "Lançamentos automáticos por Robô",
					"lancaDocsAuto": "Efetua lançamentos por Robô para todos os documentos automaticamente após serem adicionados",
					"origemPeriodoLancAuto": "Origem do período em lançamentos por Robô ou por sugestão",
					"tabelaempresa": "Tabela de empresa",
					"datadoc": "Data do documento",
					"message1": "Se ativar lançamentos por Robô para todos os documentos, o sistema vai lançar os documentos na contabilidade automaticamente após serem adicionados.",
					"message2": "O sistema vai poder lançar os documentos com código de barras ou que existam no eFatura e para os quais encontre um modelo de lançamento anteriormente feito na contabilidade para o NIF do documento e com as mesmas taxas de IVA.",
					"message3": "Conforme vai lançando documentos da contabilidade digital o sistema vai aprendendo a fazer os lançamentos por si.",
					"message4": "Pode também ativar esta funcionalidade entidade a entitade na grelha de documentos digitais clicando no botão direito do rato na linha da entidade pretendida e depois em configuração da entidade."
				},
				"opcoesocr": {
					"titleSimple": "Opções de OCR",
					"title": "Opções de OCR (Reconhecimento Ótico de Caracteres)",
					"cardtitle": "Contribuintes para fazer separação de documentos por QR Code (por exemplo faturas EDP ou Via Verde)",
					"removePaginasBrancoAuto": "Remove automaticamente páginas em branco (esta funcionalidade só deve ser utilizada se utilizar um scanner recomendado pela CentralGest)",
					"table": {
						"nif": "Nº contribuinte",
						"descricao": "Nome",
						"addedpviaverde": "Adicionar EDP/Via Verde",
						"addedp": "Adicionar EDP",
						"addviaverde": "Adicionar Via Verde"
					}
				}
			},
			"title": "Configuração da contabilidade digital",
			"success": "Configuração gravada com sucesso",
			"fields": {
				"anexaVerbete": "Acrescenta verbete aos documentos digitalizados",
				"dataLancamIgualDataDoc": "Na recolha data lançamento igual à data de documento ao obter dados do doc. digital",
				"abreSempreVisualizador": "Ao abrir recolha mostra contabilidade digital",
				"ordenacaoVisualizador": "Ordenação dos documentos",
				"usaEFaturaOCR": "Usa dados do eFatura (obtém melhores resultados no preenchimento de totais)",
				"recolhaAnexaAuto": "Na recolha anexa automaticamente doc. digital na tecla \"+\"",
				"pasta": "Pasta",
				"nomePasta": "Nome da pasta",
				"nDiario": "Diário",
				"nDescritivo": "Descritivo",
				"visivel": "Visível",
				"searchEFaturaSoNdocParcial": "Ao pesquisar documentos no modo eFatura só mostra documentos possíveis com nº documento semelhante",
				"searchEFaturaSoMesmoDiario": "Ao pesquisar documentos no modo eFatura só mostra documentos possíveis com diário igual entre pré-definido e pasta do documento",
				"entidadeHabitual": "Entidade habitual",
				"nCodRepcc": "Repartição c. custo",
				"usaDiarioConfigSugestLancam": "Na sugestão de lançamento utiliza diários configurados nas pastas",
				"eFaturaOCR": "Usa eFatura OCR",
				"fazOCR": "Efetua OCR",
				"sugereBanking": "Sugere no banking",
				"nDocfaFT": "Fatura crédito",
				"nDocfaFR": "Fatura / Recibo",
				"nDocfaNC": "Nota de crédito",
				"nDocfaNCPagaNoAto": "Nº crédito de compra paga no ato",
				"nCCusto": "Centro de custo",
				"nRefProcesso": "Processo"
			},
			"actions": {
				"obterChaveSincro": "Obter chave de sincronização client connect desta empresa",
				"ativarClientConnect": "Ativar client connect nesta empresa"
			},
			"messages": {
				"clientConnectAlreadyActivated": "Esta empresa já tem o client connect ativo",
				"activatedClientConnect": "Client connect ativado na sua empresa com sucesso",
				"opcocraddednif": "Nº contribuinte adicionado na lista com sucesso",
				"opcocraddednifedp": "Nº contribuintes EDP adicionados na lista com sucesso",
				"opcocraddednifviaverde": "Nº contribuinte Via Verde adicionados na lista com sucesso",
				"opcocranifalreadyexists": "Nº contribuinte já existe na lista",
				"repccinfo": "Código de repartição de centro de custo (pode ser utilizado para lançar automaticamente centro de custo por sugestão de lançamento do Robô)"
			},
			"configPastas": {
				"title": "Configuração de pasta"
			},
			"licencas": {
				"message": "'Caso clique em \"Sim\", o sistema irá consumir uma nova empresa à sua licença de contabilidade digital client connect, não sendo possível reverter a situação posteriormente.<br/>Neste momento, ainda pode adicionar à sua licença da contabilidade digital client connect {{nEmpresasAAdicionar}} empresas. Deseja consumir uma nova empresa à sua licença da contabilidade digital client connect?<br/>Dados da Licença de Contabilidade Digital Client Connect:<br/>Número total de empresas: {{nEmpresasTotal}}<br/>Número total de empresas já consumidas: {{nEmpresasConsumidas}}<br/>Número total de empresas que ainda pode consumir: {{nEmpresasAConsumir}}'"
			},
			"synchronizationKey": {
				"title": "Chave de sincronização para Client Connect",
				"key": "Chave de sincronização"
			},
			"tipoOrdenacaoVisualizador": {
				"0": "Nº Contribuinte | Data",
				"1": "Data | Nº Contribuinte",
				"2": "Data receção",
				"3": "Nome | Data",
				"4": "Data | Nome",
				"5": "Numeração do ficheiro",
				"nifData": "Nº Contribuinte | Data",
				"dataNif": "Data | Nº Contribuinte",
				"dataRececao": "Data receção",
				"nomeData": "Nome | Data",
				"dataNome": "Data | Nome",
				"nomeFicheiro": "Numeração do ficheiro"
			}
		}
	},
	"artars": {
		"title_detail": "Artigo de armazém",
		"title_new": "Novo Artigo de armazém",
		"title_plural": "Artigos de armazém",
		"pesquisa": "Pesquisar",
		"saved": "Artigo de armazém, guardado com sucesso.",
		"error": "Artigo de armazém, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Artigo de armazém, apagado com sucesso.",
		"fields": {
			"nArtigo": "Nº artigo",
			"nArmazem": "Nº armazém",
			"stockMinimo": "Stock mínimo",
			"stockMaxIMO": "Stock máximo",
			"stockReposic": "Stock reposição",
			"qtdMinEncome": "Qtd. min. encomenda",
			"qtdMaxEncome": "Qtd. max. encomenda",
			"qtdRepEncome": "Qtd. rep. encomenda",
			"despPorEncom": "Desp. por encomenda",
			"txCustoPosse": "Taxa custo posse",
			"nClifoHabitual": "Nº clifo habitual",
			"nDiasValidade": "Nº dias validade",
			"nSectorLocal": "Nº sector local",
			"qtdLimArmazenamento": "Qtd. lim. armazeamento",
			"localExcedentes": "Local excedentes",
			"stockAlerta": "Stock alerta"
		}
	},
	"artigoclasses": {
		"title_detail": "Classe de artigo {{id}}",
		"title_new": "Nova classe de artigo",
		"title_plural": "Classes de artigos",
		"pesquisa": "Pesquisar",
		"saved": "Classe de artigo {{id}}, guardada com sucesso.",
		"error": "Classe de artigo {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"fields": {
			"classe": "Cód.",
			"classePlaceholder": "Código da classe de artigo",
			"descricao": "Descrição",
			"descricaoPlaceholder": "Descrição da classe de artigo"
		}
	},
	"artigos": {
		"title_detail": "Artigo {{id}}",
		"title_new": "Novo Artigo",
		"title_plural": "Artigos",
		"pesquisa": "Pesquisar",
		"saved": "Artigo {{id}}, guardado com sucesso",
		"error": "Artigo {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Artigo {{id}}, apagado com sucesso",
		"texto_livre": "Texto Livre",
		"fields": {
			"nArtigo": "Cód. artigo",
			"nArtigoPlaceholder": "Código do artigo, se não for preenchido será gerado automaticamente.",
			"nome": "Nome do artigo",
			"nomePlaceholder": "Nome do artigo",
			"nFamilia": "Nº família",
			"nFamiliaPlaceholder": "Código da família",
			"nomeFamilia": "Família",
			"nomeFamiliaPlaceholder": "Nome da família",
			"nTpArt": "Tipo",
			"nTpArtPlaceholder": "Tipo de artigo",
			"nomeTpArt": "Tipo de artigo",
			"nomeTpArtPlaceholder": "Nome do tipo de artigo",
			"nGrFamilia": "Gr. família",
			"nGrFamiliaPlaceholder": "Grande família",
			"nomeGrFamilia": "Gr. família",
			"nomeGrFamiliaPlaceholder": "Nome da grande família",
			"nSubFa": "Sub-família",
			"nSubFaPlaceholder": "sub-família",
			"nomeSubFa": "Sub-família",
			"nomeSubFaPlaceholder": "Nome da sub-família",
			"nomeUnidadeMov": "Unidade",
			"nomeUnidadeMovPlaceholder": "Unidade de movimento",
			"qtdPorUnid1": "Quant. p/ unidade",
			"qtdPorUnid1Placeholder": "Quantidade por unidade",
			"movimStock": "Movimenta stock",
			"movimStockPlaceholder": "Movimenta stock",
			"codvaloriz": "Valorimetria",
			"codvalorizPlaceholder": "Valorimetria",
			"nomeIvaVenda": "IVA venda",
			"nomeIvaVendaPlaceholder": "Nome IVA",
			"taxaIvaVenda": "Taxa IVA",
			"taxaIvaVendaPlaceholder": "Taxa IVA",
			"nomeIvaCompra": "IVA compra",
			"nomeIvaCompraPlaceholder": "Nome IVA compra",
			"taxaIvaCompra": "Taxa IVA compra",
			"taxaIvaCompraPlaceholder": "Taxa IVA de compra",
			"precoSemIva": "Pr. s/ IVA",
			"precoSemIvaPlaceholder": "Preço sem IVA",
			"precoComIva": "Pr. c/ IVA",
			"precoComIvaPlaceholder": "Preço com IVA",
			"qtdTotal": "Qtd. Stock",
			"qtdTotalPlaceholder": "Quantidade total de stock",
			"temRetencaoNaFonte": "Tem retenção na fonte",
			"ncontapoc": "Nº conta venda",
			"nContaPocCompra": "Nº conta compra",
			"grupoartigo": "Propriedade",
			"artDesactivado": "Artigo desativado",
			"artBloqueado": "Artigo bloqueado",
			"categoria": "Categoria",
			"precoBaseCusto": "Preço base custo",
			"precoBaseCustoPlaceholder": "Preço base custo",
			"nArmazem": "Cód. armazém",
			"nomeArmazem": "Nome armazém",
			"qtd": "Stock atual",
			"qtdStockPotencial": "Stock potencial",
			"contaExt": "Conta externa",
			"artigoExt": "Cód. artigo externo",
			"descContaExt": "Descrição conta externa",
			"precoCompUlt": "Valor última compra",
			"precoCompUltPlaceholder": "Valor última compra",
			"prVendaUlti": "Valor última venda",
			"prVendaUltiPlaceholder": "Valor última venda",
			"dataPrCompra": "Data última compra",
			"dataPrVendUl": "Data última venda",
			"naoPermiteExistNeg": "Não permite stock negativo",
			"temLote": "Tem lote",
			"temDataValidade": "Data de validade",
			"diasValidade": "Nº dias validade",
			"diasValidadePlaceholder": "Número de dias validade",
			"segNArtigo": "Cód. barras",
			"segNome": "Descrição alternativa",
			"qtdStockEncCliente": "Encom. clientes",
			"qtdStockEncForn": "Encom. fornecedores",
			"qtdTotalStockPotencial": "Stock potencial",
			"qtdTotalStockEncCliente": "Encom. clientes",
			"qtdTotalStockEncForn": "Encom. fornecedores",
			"taxaIvaTip": "Taxa de {{taxa}}%",
			"qtdStockMinimo": "Stock mínimo",
			"qtdStockMaximo": "Stock máximo",
			"qtdAdd": "Qtd."
		},
		"btn": {
			"buttonOptions": "Opções",
			"verContas": "Mostra contas venda e compra",
			"verPrecoBaseCusto": "Mostra preço base custo",
			"verCategoria": "Mostra categoria",
			"esconderContas": "Esconder contas",
			"mostraTodas": "Todas",
			"extratoArtigo": "Extrato de artigo",
			"verPropriedade": "Mostra propriedade",
			"verApenasDesativado": "Ver apenas desativados",
			"verApenasBloqueado": "Ver apenas bloqueados"
		},
		"message": {
			"diferefamilia": "O nº de conta deste artigo difere do nº de conta que está na família.",
			"mostraTodas": "Ver todas as contas possíveis de integração",
			"contaPocFormadaVenda": "A conta de integração a ser formada será possivelmente {{nConta}}. (Depende do tipo de documento)",
			"contaPocFormadaCompra": "A conta de integração a ser formada será possivelmente {{nConta}}. (Depende do tipo de documento, por exemplo, numa nota de crédito o 3º caracter pode ser um 8)",
			"contaPocFormadaMovimento": "A conta de integração a ser formada será {{nConta}}.",
			"infofield": "Este campo é meramente informativo",
			"extratoArtigoTooltip": "Vizualizar o extrato de artigo"
		},
		"actions": {
			"familias": "Manutenção de familias"
		},
		"datasource": {
			"grupoartigo": {
				"Geral": "Geral",
				"Mercadoria": "Mercadoria",
				"Produto": "Produto",
				"MateriaPrima": "Matéria prima",
				"Servico": "Serviço",
				"ServicoExterno": "Serviço externo",
				"Componente": "Componente",
				"Equipamento": "Equipamento",
				"Ferramenta": "Ferramenta",
				"MaoDeObra": "Mão de Obra",
				"ArtigoReferencia": "Artigo referência",
				"MaterialSubsidiario": "Material subsidiário",
				"Embalagem": "Embalagem",
				"Veiculo": "Veículo",
				"Motorista": "Motorista",
				"TipoTarefa": "Tipo tarefa",
				"TarefaEspecifica": "Tarefa específica",
				"TarefaGeral": "Tarefa geral",
				"Transportadora": "Transportadora",
				"MaquinaMotriz": "Máquina motriz",
				"Configuracao": "Configuração",
				"ArtigoTara": "Artigo tara",
				"Infraestrutura": "Infraestrutura",
				"Utensilio": "Utensílio",
				"ParteInfraestrutura": "Parte infraestrutura",
				"Adiantamentos": "Adiantamentos",
				"AlienacaoDeImobilizado": "Alienação de imobilizado"
			}
		},
		"navigator": {
			"tabs": {
				"ficha": "Ficha",
				"extrato": "Extrato",
				"anexos": "Anexos",
				"lotes": "Lotes",
				"estatistica": "Estatística"
			},
			"groups": {
				"indentificacao": "Identificação",
				"classificadores": "Classificadores",
				"precos": "Preços",
				"stocks": "Stocks",
				"classificacao": "Impostos e outros",
				"clifo": "Cliente / Fornecedor",
				"contab": "Contabilidade"
			}
		},
		"text": {
			"detalheArmazem": "Detalhe por armazém",
			"tipoprod": "Tipo de produto (Inventário): {{cod}} - {{desc}}",
			"codBarras": "EAN13 - Identificador único lido através de um scanner próprio para leitura.",
			"artigoBloqueado": "Utilizado normalmente para bloquear de forma temporária.",
			"artigoDesativado": "Utilizado normalmente para bloquear de forma permanente. (Descontinuado)",
			"familia": "Classificador que permite inicializar vários campos da ficha de artigo.",
			"stockPotencial": "Obtido através da seguinte forma: Stock - Enc. clientes + Enc. fornecedores",
			"lotesBloqueadosMovStock": "Lotes de artigo bloqueados devido a não movimentar stock",
			"lotesBloqueadosLicencaPremium": "Lote bloqueado devido ao artigo não movimentar stock",
			"fileNamePicture": "Imagem_{{nArtigo}}",
			"deletePicture": "Apagar imagem"
		}
	},
	"assistenteconfigportais": {
		"pages": {
			"empresa": "Configuração empresa",
			"utilizador": "Configuração utilizador",
			"empregado": "Configuração empregado",
			"email": "Configurações e-mail por empresa"
		},
		"fields": {
			"portal": "Selecione o portal",
			"operacao": "Selecione a operação",
			"novoutilizador": "Novo utilizador",
			"nomeutilizador": "Nome de utilizador",
			"nome": "Nome",
			"apelido": "Apelido",
			"email": "E-mail",
			"enviaEmail": "Envia e-mail com password",
			"codempregado": "Cód. empregado",
			"nomeempregado": "Nome empregado",
			"addempregado": "Associar empregado",
			"contaemail": "Conta",
			"password": "Password",
			"smtp": "SMTP",
			"portasmtp": "Porta SMTP",
			"ssl": "SSL",
			"empresa": "Empresa",
			"addempresa": "Adicionar empresa",
			"removeempresa": "Remover empresa",
			"utilizador": "Utilizador"
		},
		"opcoes": {
			"selecionarportal": "Selecione um portal",
			"operacoesportal": "Selecione uma operação"
		},
		"outros": {
			"empresa": "Empresa",
			"utilizador": "Utilizador",
			"gabinete": "Gabinete"
		},
		"info": {
			"naocriautilizador": "Não vai criar novo utilizador",
			"savesucess": "Utilizador criado com sucesso",
			"saveerror": "Erro a criar utilizador",
			"empresagabinetenaoselec": "Não existe nenhuma empresa gabinete selecionada!",
			"empresaclientenaoselec": "Não existe nenhuma empresa cliente selecionada!",
			"empresanaoselec": "Não existe nenhuma empresa para selecionar ou não tem nenhuma selecionada!",
			"associateempsucess": "Empregado associado com sucesso",
			"associateemperror": "Erro a associar empregado ao utilizador",
			"utilizadornaosel": "Não existe nenhum utilizador selecionado",
			"empresanaosel": "Não existe nenhuma empresa selecionada",
			"empregadonaosel": "Não existe nenhum empregado selecionado",
			"naotemempresasparaconfigurar": "O utilizador já tem o(s) empregado(s) associado(s) a(s) empresa(s)",
			"empresaClienteConfigurada": "A empresa cliente foi configurada com sucesso",
			"empresaClienteConfigError": "Erro a configurar empresa cliente",
			"contaemailnula": "A conta de e-mail tem de estar preenchida",
			"passwordnula": "A password da conta de e-mail tem de estar preenchida",
			"smtpnulo": "O SMTP tem de estar preenchido",
			"portasmtpnula": "A porta SMTP tem de estar preenchida",
			"emailconfigsavesucess": "E-mail configurado com sucesso",
			"emailconfigsaveerror": "Erro a configurar o e-mail",
			"utilizadorexistentesuccess": "Utilizador atualizado com sucesso"
		},
		"operations": {
			"myAccounting": {
				"addUser": "Adicionar utilizador ao portal",
				"addEmpresa": "Disponibilizar empresa no portal",
				"associarEmpregado": "Associar empregado a utilizador no portal"
			},
			"portalclientconnect": {
				"createUser": "Criar novo utilizador no portal",
				"addUser": "Adicionar utilizador existente ao portal"
			}
		},
		"steps": {
			"stepGabinete": "Gabinete",
			"stepEmpresa": "Empresa",
			"stepUtilizador": "Utilizador",
			"stepEmpregado": "Empregado",
			"stepEmail": "E-mail"
		},
		"tiposEmail": {
			"gmail": "Gmail",
			"hotmail": "Hotmail",
			"outro": "Outro"
		}
	},
	"atFRV": {
		"tabDocsEFaturaCaption": "Recibos verdes emitidos",
		"tabJaLancadosCaption": "Recibos já lançados na contabilidade ou ignorados",
		"viewDocModalTitle": "Visualização do documento {{ndoc}}",
		"viewDocModalTitleSimple": "Visualização do documento",
		"extratosModalTitle": "Extratos de conta",
		"expandAllBtn": "Expandir todos",
		"cantSelectRowDueInvalidPredefinido": "Não pode selecionar o registo porque não tem pré-definido.",
		"cantSelectRowsDueInvalidPredefinido": "Os registos sem pré-definido não foram selecionados!",
		"selectAll": "Selecionar todos",
		"lancdocserie": "Lançar documentos em série",
		"createDoc": "Criar documento",
		"messages": {
			"docIgnoradoSuccessMsg": "O recibo {{ndoc}} foi adicionado à lista dos ignorados.",
			"nifIgnoradoSuccessMsg": "{{nif}} - {{nome}} foi adicionado à lista dos ignorados.",
			"docRemIgnoringSuccessMsg": "O recibo {{ndoc}} foi removido da lista dos ignorados.",
			"docRemIgnoringFornecedorSuccessMsg": "O contribuinte {{nif}} foi removido da lista dos ignorados."
		},
		"fields": {
			"documentoLancado": "Nº documento",
			"nifAdquirente": "NIF",
			"nomeAdquirente": "Nome",
			"numDocumento": "Nº recibo",
			"dataEmissao": "Data emissão",
			"tipoDocumento": "Tipo",
			"situacao": "Situação",
			"atividadePrestador": "Atividade prestador",
			"observacoes": "Observações",
			"importanciaRecebida": "Importância recebida",
			"valorBase": "Valor base",
			"valorIVA": "Valor IVA",
			"valorIRS": "Valor IRS",
			"valorImpostoSelo": "Valor Imposto selo",
			"taxaIVA": "Taxa IVA",
			"preDefinido": "Pré-definido",
			"numeroUnico": "Nº recibo"
		},
		"btn": {
			"dropdown": {
				"extratosConta": "Extratos de conta",
				"ignorarSempreTodosDocumentos": "Ignorar sempre todos os recibos deste contribuinte",
				"ignorarDocumento": "Ignorar este recibo",
				"nifConfig": "Configurar NIF",
				"gerarNovaCC": "Gerar nova conta corrente",
				"pdf": "Ver PDF recibo"
			}
		},
		"tabJaLancadosCaptionContainer": {
			"table": {
				"badges": {
					"naoIgnorar": "Não ignorar",
					"remover": "Remover"
				}
			},
			"legends": {
				"documentoIgnorado": " Recibo ignorado",
				"documentoFornecedorIgnorado": " Documento do contribuinte ignorado"
			}
		},
		"formFields": {
			"dataDe": "Data de",
			"tipo": "Tipo",
			"situacao": "Situação",
			"placeholder": {
				"contribuinte": "Nº contribuinte"
			}
		},
		"configModalTitle": "Configuração de pré-definido",
		"reciboVerdeLabel": "",
		"novacontaccmodal": {
			"title": "Criar nova conta corrente",
			"fields": {
				"radical": "Radical",
				"nConta": "Nº conta a criar",
				"nome": "Nome"
			},
			"successModal": {
				"title": "Visualizar a ficha do fornecedor",
				"message": "Conta corrente de fornecedor criada com sucesso.<br/>Deseja editar a ficha da mesma?"
			}
		},
		"modal": {
			"nifConfig": {
				"confPreDefinido": "Configuração de pré-definidos por contribuinte",
				"contaCorrente": "Conta corrente a utilizar"
			}
		}
	},
	"ativos": {
		"title_detail": "Ativo {{id}}",
		"title_new": "Novo Ativo",
		"title_plural": "Ativos",
		"pesquisa": "Pesquisar",
		"saved": "Ativo {{id}}, guardado com sucesso",
		"error": "Ativo {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Ativo {{id}}, apagado com sucesso",
		"fields": {
			"codAtivo": "Código",
			"refExterna": "Ref. externa",
			"designacao": "Designação",
			"estado": "Estado",
			"dataEstado": "Data estado",
			"viatLigArtigo11DR": "Viatura ligeira - Artigo 11 DR",
			"codAtivoPai": "Código ativo pai",
			"documentoDeAquisicao": "Documento de aquisição",
			"origem": "Origem",
			"ignoraImpostosDiferidos": "Ignora contabilização de impostos diferidos.",
			"header": {
				"estado": "Estado: ",
				"valorAquisicao": "Valor aquisição",
				"depAcumulada": "Dep. acumulada",
				"valorLiquido": "Valor líquido",
				"diario": "Diário",
				"periodo": "Período",
				"ndocinterno": "Nº doc. interno",
				"contabTaxaAmortiza": "Taxa amortização contabilística",
				"fiscalTaxaAmortiza": "Taxa amortização físcal",
				"contabBaseCalculo": "Base para cálculo contabilística",
				"fiscalBaseCalculo": "Base para cálculo fiscal"
			},
			"totals": {
				"estado": "Estado",
				"qtdEstado": "",
				"totalValAquisicao": "",
				"totalDepAcumulada": "",
				"totalValLiquido": ""
			}
		},
		"modal": {
			"btn": {
				"marcarGR": "Marcar",
				"desmarcarGR": "Desmarcar"
			},
			"abatedoativo": "Abate do ativo",
			"registodevendaativo": "Registo de venda de ativos",
			"radicalAbate": "Nº conta do abate",
			"dataAbate": "Data do abate",
			"grMarcar": "Marcar ativo como sendo uma grande reparação",
			"grDesmarcar": "Desmarcar ativo de ser uma grande reparação"
		},
		"header": {
			"estado": "Estado: ",
			"valorAquisicao": "Valor aquisição: ",
			"depAcumulada": "Dep. acumulada: ",
			"valorLiquido": "Valor líquido: "
		},
		"estado": {
			"0": "Normal",
			"1": "Abatido ({{date}})",
			"2": "Vendido ({{date}})",
			"3": "Dividido ({{date}})"
		},
		"titles": {
			"dadosDoAtivo": "Dados do ativo",
			"dadosDoFornecedor": "Dados do fornecedor",
			"dadosContabilisticos": "Dados contabilísticos",
			"dadosFiscais": "Dados fiscais",
			"contas": "Contas",
			"outrosDados": "Outros dados",
			"impostosDiferidos": "Impostos diferidos",
			"classificadores": "Classificadores",
			"grandeReparacao": "Ativo pai"
		},
		"toolbar": {
			"acontecimentos": "Operações",
			"dropdownDeprPlan": "Depreciações e planning",
			"dropdownRegContab": "Contabilidade",
			"tooltipAtivoAbateVendido": "Ativo já abatido / vendido",
			"dropdownConsulta": "Consulta",
			"tooltipAtivoEstadoNormal": "O ativo tem de ser do estado normal.",
			"tooltipOpJustoValorABP": "O ativo tem de ser biológico de produção e do estado normal."
		},
		"actions": {
			"abate": "Registar abate",
			"venda": "Registar venda",
			"planning": "Planning",
			"depreciacoes": "Depreciações",
			"depreciacoesDtl": "Depreciações detalhadas",
			"regcontab": "Registos contabilísticos",
			"mudarcontaativo": "Mudar conta do ativo",
			"mudarcontadepexerc": "Mudar conta dep. exercício",
			"mudarcontadepacumu": "Mudar conta dep. acumulado",
			"mudastaxasdep": "Mudar taxas da depreciação",
			"notacredito": "Desconto (Nota de crédito)",
			"divisao": "Divisão do ativo",
			"ajustedepantigas": "Ajuste depreciações acumuladas em anos anteriores",
			"imprimeativo": "Imprimir ficha do ativo",
			"reavaliacoesNaoLegais": "Reavaliações não legais",
			"aumentoifrs16": "Aumento de valor em IFRS16",
			"opjustovalorabp": "Op. de justo valor em ABP",
			"grandereparacao": "Grande reparação (Marcar / Desmarcar)"
		},
		"steps": {
			"aquisicaoAtivo": "Aquisição de ativo",
			"dadosContabilisticosDadosFiscais": "Dados contabilísticos e dados fiscais",
			"contasPlanoOutrosDados": "Contas do plano e outros dados",
			"classificadoresDoAtivo": "Classificadores do ativo",
			"planningAmortizacoes": "Planning de amortizações",
			"ativoDepreciacoesDetalhadas": "Depreciações detalhadas",
			"ativoDepreciacoes": "Depreciações",
			"pesquisaContabilidade": "Registos contabilísticos"
		},
		"enum": {
			"origem": {
				"0": "Aquisição",
				"1": "Conversão",
				"2": "Contabilidade",
				"3": "Importação excel",
				"4": "Importação manual",
				"5": "Divisão de ativo",
				"6": "Reavaliações não legais",
				"7": "Aumento valor IFRS 16",
				"8": "Operação justo valor",
				"9": "Investimentos em curso"
			}
		},
		"table": {
			"dadosContabilisticos": "Dados contabilísticos",
			"dadosFiscais": "Dados fiscais",
			"depreciacaocontabilistica": "Depreciação contabilística",
			"depreciacaofiscalaceite": "Depreciação fiscal aceite",
			"taxascontab": "Taxas contabilísticas",
			"taxasfiscal": "Taxas fiscais",
			"limiteminimofiscal": "Limite mínimo fiscal",
			"limitemaximofiscal": "Limite máximo fiscal",
			"perdasimparidadecontabilisticas": "Perdas por imparidade contabilísticas",
			"perdasimparidadefiscais": "Perdas por imparidade fiscais",
			"valoresperdidosfiscais": "Valores perdidos fiscais",
			"taxasperdidasfiscais": "Taxas perdidas fiscais",
			"depreciacoesnaoaceites": "Depreciações não aceites",
			"imparidadesnaoaceites": "Imparidades não aceites",
			"depreciacoesarecuperar": "Depreciações a recuperar",
			"depreciacoesrecuperadas": "Depreciações recuperadas",
			"imparidadesrecuperadasnoperiodo": "Imparidades recuperadas no período",
			"acrescemodelo22": "Acresce ao modelo 22",
			"deduzmodelo22": "Deduz ao modelo 22",
			"valortributavelativosimpostosdiferidos": "Valor tributável dos ativos por impostos diferidos",
			"valortributaveldopassivoimpostosdiferidos": "Valor tributável do passivo por impostos diferidos",
			"valornaoaceitefiscalmenteporreavaliacoes": "Valor não aceite fiscalmente por reavaliações",
			"impostosdiferidos": "Impostos diferidos",
			"rendimentos": "Rendimentos de subsídios",
			"impostosdifSubsidios": "Impostos diferidos de subsídios",
			"tipoOperacao": "Operação",
			"ndiario": "Diário",
			"ndocInterno": "Nº doc. interno",
			"dataDoc": "Data lançamento",
			"dataDocExterno": "Data doc. externo",
			"periodo": "Período",
			"depreciacoes": "Depreciações",
			"body": {
				"mesde": "Mês de",
				"mesate": "Mês até",
				"anoEconomico": "Ano económico",
				"valorAquisicao": "Valor aquisição",
				"valorResidual": "Valor residual",
				"taxa": "Taxa",
				"redTaxa": "% red. taxa",
				"depreciacao": "Depreciação",
				"depreciacoes": {
					"anodoubledot": "Ano: ",
					"contabtaxamedia": "Taxa média contabilistica",
					"fiscaltaxamedia": "Taxa média fiscal",
					"periodo": "Período",
					"periodoano": "Ano período",
					"periodomes": "Mês período",
					"depmes": "No mês",
					"depmesaquisicao": "No mês sobre aquisição",
					"depmesreavalicao": "No mês sobre reavaliação",
					"depacumano": "No ano",
					"depacumant": "No ano anterior",
					"taxa": "Taxa",
					"percreducao": "% Redução",
					"reforco": "Reforço",
					"taxairc": "Taxa IRC"
				}
			}
		},
		"configuracoes": {
			"tab": {
				"title": {
					"aquisicao": "Aquisição",
					"impostosDiferidos": "Impostos diferidos",
					"depreciacoes": "Depreciações",
					"ajustes": "Ajustes",
					"errosTitle": "Erros de depreciações de anos anteriores",
					"vendaTitle1": "Fatura da venda",
					"vendaTitle2": "Valor bruto e depreciação acumulada",
					"abate": "Abate",
					"contabilidadeanaliticatitle": "Radicais sujeitos a contabilidade analítica",
					"reavaliacoesnaolegais": "Reavaliações não legais",
					"impdifreavaliacoesnaolegais": "Impostos diferidos das reavaliações não legais",
					"operacoesjustovalor": "Operações de justo valor",
					"impdifoperacaojustovalor": "Impostos diferidos da operação de justo valor"
				},
				"diversos": "Diversos",
				"contasDepreciacao": "Contas depreciação",
				"aquisicao": "Aquisição",
				"depreciacoes": "Depreciações",
				"ajustes": "Ajustes",
				"erros": "Erros",
				"venda": "Venda",
				"abate": "Abate",
				"regraAbateVenda": "Regras de abate / venda",
				"contabilidadeAnalitica": "Contabilidade analítica",
				"classificadores": "Classificadores",
				"reavaliacoesnaolegais": "Reavaliações não legais",
				"ativosbioprod": "Ativos biológicos de produção (ABP)",
				"contasInvestCurso": "Contas de investimento em curso"
			},
			"btn": {
				"adicionar": "Adicionar",
				"guardar": "Guardar",
				"cancelar": "Cancelar"
			},
			"messages": {
				"realizadacomsucesso": "Operação realizada com sucesso.",
				"grcodativoempty": "O cód. de grande reparação não pode ser vazio.",
				"radicalRequired": "O radical não pode ser vazio.",
				"radicalInvalid": "O radical apenas permite caracteres numéricos.",
				"radicalJaExistente": "O radical inserido já está configurado.",
				"contaImobilizadoRequired": "O radical da conta de imobilizado não pode ser vazio.",
				"contaImobilizadoInvalid": "O radical da conta de imobilizado apenas permite caracteres numéricos.",
				"contaImobilizadoJaExiste": "A conta de imobilizado inserida já está existe.",
				"pocInvalid": "A conta de depreciação ou amortização não existe.",
				"successfullySaved": "Alterações guardadas com sucesso.",
				"ativoguardadosuccess": "Ativo guardado com sucesso.",
				"contaTransicaoInvalid": "A conta de transição não é válida."
			},
			"radiooptions": {
				"ativoperiodo": "Lança um documento por cada ativo, em cada período",
				"ativoperiodoult": "Lança um documento por cada ativo, num único perído (último)",
				"ativosperiodo": "Lança um documento com todos os ativos, em cada período",
				"ativosperiodoult": "Lança um documento com todos os ativos, num único perído (último)"
			},
			"trataAtivosNoCentralGest": "Trata ativos no CentralGest",
			"radicaisContasImobilizado": "Radicais de ativos",
			"radicalIVA": "Radical IVA",
			"taxaIRCEstimada": "Taxa IRC estimada",
			"codDiario": "Cód. diário",
			"codDescritivo": "Cód. descritivo",
			"radicaisFornecedorAquisicao": "Radicais de fornecedor",
			"impostosDiferidosContaPassivo": "Cód. conta passivo",
			"impostosDiferidosContaAjustamentos": "Cód. conta ajustamentos",
			"impostosDiferidosContaImpostoDiferido": "Cód. conta imp. diferidos",
			"mostraContabilidadeConversao": "Na manutenção de ativos, mostra as relações de Conversão da estrutura antiga",
			"depreciacaoDataEncerramento": "Data de encerramento",
			"contaContrapartida": "Cód. conta contrapartida",
			"contaMaisValia": "Conta mais-valia",
			"contaMenosValia": "Conta menos-valia",
			"vendaRadicalMaisValiaFatura": "Radical mais-valia",
			"vendaRadicalMenosValiaFatura": "Radical menos-valia",
			"abateRadical": "Radical abate",
			"ccusto": "Cód. C. Custo",
			"zona": "Zona",
			"departamento": "Departamento",
			"subdepartamento": "Sub-departamento",
			"tipoartigo": "Tipo artigo",
			"grandefamilia": "Grande familia",
			"familia": "Familia",
			"subfamilia": "Sub-familia",
			"contabilidade": "Contabilidade",
			"classe": "Classe",
			"categoria": "Categoria",
			"vendedor": "Vendedor",
			"processo": "Processo",
			"dataLimiteParaCalculoContabilisticoIgualFiscalTrue": "Data limite para cálculo contabilístico, igual à fiscal (último dia do mês anterior)",
			"dataLimiteParaCalculoContabilisticoIgualFiscalFalse": "Data limite para cálculo contabilístico, é o dia anterior ao abate / venda",
			"contabilidadeAnaliticaUsa": "Usa contabilidade analítica na integração de ativos",
			"contabilidadeAnaliticaMascara": "Máscara da analítica",
			"analiticaContaContraPartida": "Conta contrapartida",
			"radicaisSujeitosList": "Lista de radicais",
			"novoRadicalSujeito": "Novo radical",
			"contaImobilizado": "Conta imobilizado",
			"contaDepreciacao": "Conta depreciação",
			"contaDepreciacaoAcumulada": "Conta depreciação acumulada",
			"infodep": "Já existem depreciações efetuadas para o ano em curso.",
			"depreciacaoSoRegistaNaContabilidadeEmPeriodoAposAquisicao": "Só regista na Contabilidade em período igual ou superior à aquisição",
			"depreciacoesDoAnoPesquisaPeloEstadoDepreciacoes": "Pesquisa as depreciações anuais pelo estado da depreciação",
			"nContaContrapartida": "Nº conta contrapartida",
			"nContaPassivo": "Nº conta passivo",
			"nContaAjustamentos": "Nº conta ajustamentos",
			"radicalabp": "Radical ABP",
			"nContaParaReducao": "Nº conta para redução",
			"nContaParaAumento": "Nº conta para aumento",
			"nContaAtivo": "Nº conta ativo",
			"cursoRadicalInvestimentos": "Radical de investimentos em curso",
			"cursoRadicalAtivo": "Radical da conta do ativo",
			"cursoContaTransicao": "Conta de transição"
		},
		"verificacaoDeConsistenciaVenda": {
			"error": "Há documentos de venda de ativos na contabilidade, sem relação com os ativos.<br>Proceda ao registo das vendas respectivas.",
			"btn": {
				"redirectToVendaSerie": "Clique aqui para ser redirecionado para as vendas de ativos."
			}
		},
		"listTotals": {
			"totalativos": "Nº total de bens",
			"totalvalaquisicao": "Total do valor de aquisição",
			"totaldepacumulada": "Total da dep. acumulada",
			"totalvalliquido": "Total do valor liquído",
			"resumo": "Resumo",
			"estado": "Estado"
		},
		"aumentoifrs16": {
			"title": "Aumento de valor em IFRS16",
			"initdescription": "[Aumento de valor] - ",
			"info": "Esta ação permite realizar o aumento em IFRS 16.\nSerá criado um novo ativo com o valor do aumento, que ficará relacionado com o ativo original.",
			"data": "Data do aumento",
			"valor": "Valor de aumento",
			"designacao": "Designação do novo ativo",
			"messages": {
				"descempty": "A designação não pode ser nula!",
				"invalidvalue": "O valor do aumento não é válido!",
				"invaliddate": "A data do aumento de valor tem que ser superior ao início do ano em curso!",
				"registedsuccess": "Aumento de valor registado."
			}
		},
		"ativosopjustovalorabp": {
			"title": "Operação de justo valor em ativos biológicos de produção (ABP)",
			"dataOperacao": "Data da operação",
			"valorOperacao": "Valor da operação",
			"temcertezaOPJustoValor": "Pretende realizar a operação de justo valor?",
			"successOPJustoValor": "Operação de justo valor realizada com sucesso"
		},
		"messages": {
			"existemmaisativoscontab": "O ativo teve origem na contabilidade.<br/>Ao eliminá-lo, eliminará todos os outros que resultaram do mesmo documento.<br/>Tem a certeza que pretende continuar?",
			"existemmaisativosdivididos": "Tem a certeza que deseja eliminar o ativo?<br/>Este é um ativo que resultou de uma divisão.<br/>Ao eliminar este ativo vai eliminar todos os ativos que resultaram da divisão.<br/>O ativo que foi dividido voltará ao estado normal."
		}
	},
	"ativosreavaliacoesnaolegais": {
		"titleList": "Reavaliações não legais",
		"title": "Reavaliação não legal",
		"ativoparcial": "Ativo {{codAtivo}} parcialmente depreciado.",
		"ativototaldep": "Ativo {{codAtivo}} totalmente depreciado.",
		"vidaUtilDefinidaEDiferente": "A vida útil definida é diferente da do ativo atual. Tem a certeza que pretende realizar a reavaliação não legal do ativo?",
		"temACertezaQuePretendeRealizarAR": "Tem a certeza que pretende realizar a reavaliação não legal do ativo?",
		"success": "Reavaliação não legal efetuada com sucesso.",
		"jahadepreciacoes": "Já há depreciações efetuadas no ano para o ativo! Anule as depreciações do ativo para poder realizar a reavaliação.",
		"fields": {
			"dataReavNaoLegal": "Data da reavaliação",
			"vidaUtilMeses": "Vida útil (meses)",
			"vidaUtilAnos": "Vida útil (anos)",
			"aumentoValor": "Aumento de valor"
		}
	},
	"ativosAquisicao": {
		"title_detail": "Aquisição {{id}}",
		"title_new": "Novo Aquisição",
		"title_plural": "Aquisições",
		"pesquisa": "Pesquisar",
		"saved": "Aquisição {{id}}, guardada com sucesso",
		"error": "Aquisição {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Aquisição {{id}}, apagada com sucesso",
		"warn": {
			"tooltipTaxa": "Taxa diferente da do decreto regulamentar!",
			"baseFiscDifContab": "Base para cálculo fiscal diferente da contabilística",
			"confirmBaseFiscDifContab": "Base para cálculo fiscal diferente da contabilística!<br>Pretende continuar?"
		},
		"fields": {
			"nMapaAmortizacao": "Nº mapa amortiz",
			"codAmortizacao": "Cód. amortização",
			"dataAquisicao": "Data aquisição",
			"dataUtilizacao": "Data utilização",
			"quantidade": "Nº de unidades(Qtd)",
			"grandeReparacao": "É grande reparação (de outro ativo)",
			"usado": "Usado",
			"nDocExterno": "Nº de doc. externo",
			"dataDocExterno": "Data doc. externo",
			"nContaFornecedor": "Nº conta fornecedor",
			"nomeFornecedor": "Nome fornecedor",
			"contabValorAquisicao": "Valor da aquisição",
			"contabTaxaAmortiza": "Taxa de amortização",
			"contabNumAnosUteis": "Nº de anos úteis",
			"contabPercReducao": "Perc. redução da taxa de amortização",
			"contabValorResidual": "Valor residual",
			"contabDataDispUso": "Data de disponibilidade para uso",
			"contabBaseCalculo": "Base para cálculo",
			"contabMetodoCalculo": "Método para cálculo",
			"fiscalValorAquisicao": "Valor da aquisição",
			"fiscalTaxaAmortiza": "Taxa de amortização",
			"fiscalNumAnosUteis": "Nº de anos úteis",
			"fiscalPercReducao": "Perc. redução da taxa de amortização",
			"fiscalValorResidual": "Valor residual",
			"fiscalDataInicioUtil": "Data de início utilização",
			"fiscalBaseCalculo": "Base para cálculo",
			"fiscalMetodoCalculo": "Método para cálculo",
			"nContaDoAtivo": "Nº conta do ativo",
			"nContaAmortExerc": "Nº conta amort. exercício",
			"nContaAmortAcumu": "Nº conta amort. acumulado",
			"nContaIVADedutivel": "Nº conta IVA dedutível",
			"codIVAAquisicao": "Cód. IVA de aquisição",
			"iVADeduzido": "IVA deduzido",
			"codRubOrcam": "Cód. rubrica orçamental",
			"nCodRepCC": "Cód. repartição",
			"nCCusto": "Nº centro de custo",
			"nZona": "Zona",
			"nDepto": "Departamento",
			"nSubde": "Sub-departamento",
			"tipoArtigo": "Tipo de artigo",
			"nGrFamilia": "Grande familia",
			"nFamilia": "Familia",
			"nSubfa": "Sub-familia",
			"codContab": "Cód. contabilidade",
			"classe": "Classe",
			"categoria": "Categoria",
			"nVendedor": "Vendedor",
			"nRefProcesso": "Processo",
			"nSeccao": "Secção"
		},
		"radioOptions": {
			"baseAnual": "Base anual",
			"inicioUtilizacao": "A partir do início da utilização",
			"quotasConstantes": "Quotas constantes",
			"quotasDecrescentes": "Quotas decrescentes"
		},
		"tooltip": {
			"contabDataDispUso": "Colocar dia 1 do mês de início de utilização"
		}
	},
	"ativosabate": {
		"dadosAbate": "Dados do abate",
		"ativosporabater": "Ativos por abater",
		"dataAbate": "Data do abate",
		"radicalAbate": "Nº conta do abate",
		"selecionarativos": "Selecione os ativos a abater",
		"message": {
			"success": "Abate registado!"
		},
		"table": {
			"codAtivo": "Cód. ativo",
			"designacao": "Designação",
			"refExterna": "Ref. externa",
			"dataAquisicao": "Dt. aquisição",
			"valorAtivoBrutoContab": "Ativo bruto",
			"valorDepreciacoesAcumuladasContab": "Dep. acumuladas",
			"ativosporabater": "Ativos por abater",
			"ativosaabater": "Ativos a abater"
		},
		"btn": {
			"realizarabates": "Realizar abates"
		}
	},
	"ativosajustedepantigas": {
		"title": "Ajuste de depreciações acumuladas em anos anteriores",
		"temcerteza": "Tem a certeza que quer registar as depreciações em falta de anos anteriores e lançar o valor em ajustes na Contabilidade?",
		"infoajustes": "Esta funcionalidade vai registar as depreciações em falta de anos anteriores e lança o valor em ajustes na Contabilidade",
		"message": {
			"success": "Ajuste de depreciações registado com sucesso!",
			"haveDepEmFalta": "Não existem depreciações em falta de anos anteriores."
		},
		"fields": {
			"periodo": "Período de lançamento para ajustes",
			"valoremfalta": "Valor em falta"
		}
	},
	"ativosaquisicaocontab": {
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;' aria-hidden='true'></i> Pretende sair sem gravar o documento?",
			"exitMessage": "Ao clicar em OK irá sair sem gravar o documento e todas as alterações que fez neste documento serão apagadas. Tem a certeza que pretende sair do documento?"
		},
		"header": {
			"fornecedor": "Fornecedor",
			"totalIva": "Total de IVA do documento",
			"valorAssociado": "Valor de aquisição já associado a ativos existentes",
			"total": "Total"
		},
		"btn": {
			"pesquisa": "Pesquisar documento de contabilidade",
			"adiciona": "Adicionar outro documento de contabilidade",
			"guardarAtivos": "Guardar"
		},
		"message": {
			"ativoguardado": "Ativo guardado com sucesso!",
			"confirmacao": "Registo aquisição de ativo",
			"desejaregistaraquisicao": "Deseja registar a aquisição do ativo?"
		},
		"table": {
			"nLanc": "nLanc",
			"nConta": "Nº conta",
			"nome": "Nome",
			"valor": "Valor de aquisição",
			"valorCalculado": "Já associado",
			"valorCalculado2": "Em novos ativos",
			"periodo": "Período",
			"nDiario": "Nº diário",
			"nDocInterno": "Nº doc. interno",
			"dCDescription": "Deb./Cré",
			"nContaDebito": "Débito",
			"nContaCredito": "Crédito",
			"descricao": "Descrição",
			"designacao": "Designação",
			"codAtivo": "Cód.",
			"nContaDoAtivo": "Nº conta do ativo",
			"contabValorAquisicao": "Valor"
		},
		"tooltip": {
			"novoativo": "Novo ativo",
			"removedoc": "Remover documento",
			"valorEsgotado": "O valor de aquisição para esta conta já foi esgotada!",
			"removeAtivo": "Remover ativo"
		},
		"error": {
			"contabValorAquisicaoSup": "O valor de aquisição não pode ser superior ao valor restante!",
			"fiscalValorAquisicaoSup": "O valor de aquisição fiscal não pode ser superior ao contabilístico!",
			"invalidStep": "Tem campos que não estão preenchidos ou que contém erro!",
			"somainvalida": "A soma dos valores das aquisições {{valorAssociado}} não coincide com o total do doc. contabilístico {{documentos}}!",
			"temdeselecionarum": "Tem de selecionar uma aquisição!",
			"naoMesmosRadicais": "As linhas selecionadas tem que ter o mesmo radical!"
		},
		"half": {
			"contasAquisicao": "Contas de aquisição",
			"ativos": "Ativos"
		},
		"modal": {
			"ativoaquisicaocontabmodaltitle": "Pesquisa de aquisições na contabilidade",
			"saldosIniciais": "Pesquisa saldos iniciais"
		}
	},
	"ativosaquisicaoinvestemcurso": {
		"tabs": {
			"aquisicaoPorConta": "Aquisição por conta",
			"aquisicaoPorDocumento": "Aquisição por documento"
		},
		"infocontainvest": "Informações da conta de investimentos em curso",
		"contasAquisicaoCurso": "Contas de aquisição em curso",
		"fields": {
			"nconta": "Conta",
			"saldo": "Saldo {{ano}}",
			"saldo2": "Saldo {{anoseguinte}}",
			"transferido": "Transferido",
			"codAtivo": "Cód.",
			"designacao": "Descrição",
			"nContaDoAtivo": "Nº conta do ativo",
			"contabValorAquisicao": "Valor"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;' aria-hidden='true'></i> Pretende sair sem gravar o documento?",
			"exitMessage": "Ao clicar em OK irá sair sem gravar o documento e todas as alterações que fez neste documento serão apagadas. Tem a certeza que pretende sair do documento?",
			"desejaregistaraquisicao": "Deseja registar a aquisição do ativo?",
			"ativoguardado": "Ativo/s guardado/s com sucesso!"
		},
		"messages": {
			"invalidDateNewAtivo": "A data de aquisição tem de ser do ano: {{ano}} ou {{anoseguinte}}",
			"invalidvalue": "O valor não pode ser superior a: {{value}}",
			"nContaEmpty": "Tem que pesquisar e selecionar uma conta de aquisição!",
			"saldoTranferidoCompleto": "Saldo da conta já associado a ativos. Não é possível adicionar mais ativos.",
			"desejaApagarDados": "Ao mudar de separador vai mudar o método de pesquisa e limpar os dados inseridos"
		},
		"tooltip": {
			"novoativo": "Novo ativo",
			"removedoc": "Remover documento",
			"valorEsgotado": "O valor de aquisição para esta conta já foi esgotada!",
			"removeAtivo": "Remover ativo"
		},
		"modal": {
			"title": "Pesquisa de contas de investimento em curso",
			"fields": {
				"nConta": "Nº conta",
				"nome": "Nome",
				"virtualDouble01": "Saldo {{ano}}",
				"virtualDouble02": "Saldo {{anoseguinte}}"
			}
		},
		"btns": {
			"search": "Pesquisa contas de investimento em curso"
		}
	},
	"ativosaquisicaoinvestemcursopordoc": {
		"infocontainvest": "Informações da conta de investimentos em curso por documento",
		"listadocs": "Lista documentos",
		"documentos": "Documentos",
		"ativos": "Ativos",
		"ativosFieldset": "Lista de ativos a criar",
		"fields": {
			"radical": "Radical",
			"valor": "Valor",
			"transferido": "Valor transferido",
			"codAtivo": "Cód.",
			"designacao": "Descrição",
			"nContaDoAtivo": "Nº conta do ativo",
			"contabValorAquisicao": "Valor"
		},
		"prompt": {
			"desejaregistaraquisicao": "Deseja registar a aquisição do ativo?",
			"ativoguardado": "Ativo(s) guardado(s) com sucesso!"
		},
		"messages": {
			"invalidvalue": "O valor não pode ser superior a: {{value}}",
			"docsEmpty": "Tem de adicionar pelo menos um documento!",
			"nextStepAtivosList": "Para adicionar ativos clicar no botão '+' da grelha 'Lista de ativos a criar'"
		},
		"tooltip": {
			"novoativo": "Novo ativo",
			"removeAtivo": "Remover ativo"
		},
		"btn": {
			"search": "Pesquisa documentos de investimentos em curso",
			"searchMobile": "Pesquisa documentos"
		}
	},
	"ativoscoeficientecm": {
		"header": {
			"anoPesquisa": "Ano a atualizar"
		},
		"btn": {
			"gravaTooltip": "Selecione um ativo para guardar"
		},
		"table": {
			"ativo": "Dados dos ativos",
			"coeficientes": "Coeficientes",
			"maismenosvalias": "Mais ou menos valias",
			"sel": "Sel.",
			"codativo": "Cód. ativo",
			"designacao": "Designação",
			"dataaquisicao": "Data aquisição",
			"dataavenda": "Data venda",
			"valor": "Valor",
			"coefatual": "Coef. atual",
			"coefcorrigido": "Coef. corrigido",
			"contab": "Contabilística",
			"fiscal": "Fiscal",
			"fiscalcorrig": "Fiscal corrigida",
			"emptyline": "Sem dados"
		},
		"message": {
			"temcerteza": "Deseja atualizar o coeficiente nos ativos selecionados?",
			"success": "Os ativos foram atualizados com sucesso!"
		}
	},
	"ativosconsistencia": {
		"tab": {
			"ativosconsistenciaContab": "Contabilidade sem ligação aos ativos",
			"ativosconsistenciaResumo": "Resumo por conta",
			"ativosconsistenciaAnaliseConta": "Análise por conta",
			"ativosconsistenciaPeriodoDiferente": "Ativos com período diferente",
			"ativosconsistenciaAnosErrados": "Ativos com ano errado",
			"ativosconsistenciaVendaDiferente": "Vendas em anos diferentes",
			"ativosconsistenciaDetalhe": "Detalhe por ativo",
			"ativosconsistenciaIgnorados": "Documentos ignorados da relação com ativos",
			"ativosajustesgerados": "Ajustes gerados",
			"resumoerros": "Resumo dos erros"
		},
		"table": {
			"codErro": "Cód.",
			"nConta": "Nº conta",
			"nomeConta": "Nome conta",
			"periodo": "Período",
			"nDiario": "Diário",
			"nDocInterno": "Nº doc. interno",
			"valorContabilidade": "Valor contabilidade",
			"dCAsStr": "Débito/Crédito",
			"valorAtivos": "Valor nos ativos",
			"diferenca": "Diferença",
			"contabValorAquisicao": "Valor contabilidade",
			"debito": "Débito",
			"credito": "Crédito",
			"nContaDebito": "Débito",
			"nContaCredito": "Crédito",
			"descricao": "Descrição",
			"valor": "Valor",
			"tipo0": "Tipo: ativo bruto",
			"tipo1": "Tipo: depreciação acumulada",
			"tipo2": "Tipo: depreciação exercício",
			"tipo3": "Tipo: outros"
		},
		"analiseconta": {
			"ano": "Ano económico",
			"registoMes": "Até período"
		},
		"btn": {
			"lancadifajustes": "Lança diferenças como ajustes",
			"pesquisa": "Pesquisar",
			"ignorardocumentossel": "Ignorar os documentos selecionados",
			"retiradocignoradossel": "Retirar os documentos selecionados",
			"tipo": {
				"ativobruto": "Tipo: ativo bruto",
				"depreciacaoAcumulada": "Tipo: depreciação acumulada",
				"depreciacaoExercicio": "Tipo: depreciação exercício",
				"outros": "Tipo: outros"
			},
			"accao": "Acções",
			"dropdown": {
				"listaativos": "Ativos da conta",
				"ignorardocumento": "Ignorar documento",
				"apagarIgnorados": "Retira documento dos ignorados",
				"planocontas": "Plano de contas",
				"extratocontas": "Extrato de conta",
				"aquisicaobydocumento": "Nova aquisição a partir de documento"
			}
		},
		"modal": {
			"listaativostitle": "Ativos que pertencem à conta",
			"periodotitle": "Período para o lançamento dos ajustes",
			"periodo": "Período",
			"table": {
				"codAtivo": "Código",
				"designacao": "Designação",
				"dataAquisicao": "Data aquisição",
				"virtualValor": "Valor"
			}
		},
		"message": {
			"temcertezaignora": "Tem a certeza que pretende ignorar os documentos selecionados da relação com os ativos?",
			"temcertezaretira": "Tem a certeza que pretende retirar os documentos selecionados dos ignorados na relação com os ativos?",
			"temcertezaajustes": "Tem a certeza que pretende lançar as diferenças como ajustes?",
			"successLanca": "Diferenças lançadas com sucesso!",
			"semerros": "Sem erros",
			"comerros": "Tem erros"
		}
	},
	"ativosdepreciacao": {
		"header": {
			"anoDeCalculo": "Ano económico a que respeita o cálculo",
			"codAtivoDe": "Cód. ativo de",
			"dataAquisicaoDe": "Data aquisição de",
			"mostraAtivosDepAnosAnteriores": "Mostra ativos totalmente depreciados em anos anteriores",
			"alertativosdataposterior": "Há ativos com data de início de depreciação com data superior à de aquisição. Verifique se está tudo correto."
		},
		"btn": {
			"pesquisar": "Pesquisar",
			"calcular": "Registar depreciações",
			"anulaCalculo": "Anular depreciações selecionadas",
			"consultar": "Consultar"
		},
		"tab": {
			"ativosDepEmAnoAnteriorTooltip": "Não foram filtrados ativos totalmente depreciados em anos anteriores",
			"ativosDepreciadosEmAnoAnterior": "Ativos depreciados em anos anteriores",
			"ativosDepreciadosNoAno": "Ativos depreciados no ano",
			"ativosPorDepreciarNoAno": "Ativos por depreciar no ano",
			"registoMes": "Registar até: {{nameMonth}}"
		},
		"table": {
			"title": {
				"ultimaDepreciacao": "Última depreciação",
				"depreciacaoContabilisticos": "Depreciação contabilística",
				"depreciacaoFiscal": "Depreciação fiscal"
			},
			"subtitle": {
				"sel": "Sel.",
				"atCodAtivo": "Cód. ativo",
				"atDesignacao": "Designação",
				"atDataAquisicao": "Data aquisição",
				"atEstadoSTR": "Estado",
				"atDataAbate": "Data abate",
				"atRefExterna": "Ref. externa",
				"ultPeriodo": "Período",
				"valorAquisicaoComResidual": "Valor aquisição",
				"taxaAmortiza": "Taxa amortização",
				"percReducao": "% Redução",
				"valorAnualJaRegistado": "Já registado",
				"valorAnual": "Valor a depreciar"
			}
		},
		"message": {
			"calculado": "Ativos por depreciar calculados com sucesso.",
			"calculoAnulado": "Ativos por depreciar anulados com sucesso.",
			"percentAbove": "Há percentagens de redução de 100%. Tem a certeza que pretende continuar?",
			"jaforamdepreciadostodosano": "Já foram depreciados todos os ativos para o ano.",
			"hasujeitosadepreciacaoano": "Há ativos sujeitos a depreciação para o ano.",
			"naohanenhumsujeitodepreciacaoano": "Não há nenhum ativo sujeito a depreciação para o ano."
		},
		"title": {
			"titlePercentAbove": "Cálculo ativos por depreciar"
		},
		"tooltip": {
			"selecionarumadep": "Tem de selecionar pelo menos uma depreciação!"
		},
		"modal": {
			"dataspostetitle": "Ativos com data de início de depreciação bastante superior à de aquisição",
			"table": {
				"codAtivo": "Código",
				"designacao": "Designação",
				"contabBaseCalculoStr": "Método cálculo",
				"dataAquisicao": "Data aquisição",
				"contabDataDispUso": "Data disponibilidade"
			}
		}
	},
	"ativosdivisao": {
		"message": {
			"temcerteza": "Tem a certeza que pretende efetuar a divisão do ativo?",
			"success": "Ativo dividido com sucesso!",
			"info": "Esta ação permite dividir um ativo em 2. <br> O ativo existente será abatido, e serão criados 2 novos, descendentes do atual, que herdam todas as características (datas, aquisição, depreciações)."
		},
		"title": {
			"divisao": "Divisão do ativo"
		},
		"header": {
			"unidades": "Nº de unidades/qtd",
			"valoraquisicao": "Valor aquisição",
			"depacumu": "Dep. acumulada",
			"valorliquido": "Valor líquido"
		},
		"fields": {
			"designacao": "Designação",
			"datadivisao": "Data da divisão"
		}
	},
	"ativosestado": {
		"data": {
			"normal": "Normal",
			"abatido": "Abatido",
			"vendido": "Vendido",
			"dividido": "Dividido"
		}
	},
	"ativosgeramapasauto": {
		"tableDefinition": {
			"codAtivo": "Cód. ativo",
			"designacao": "Designação",
			"estadoStr": "Estado",
			"dataAquisicao": "Data aquisição",
			"dataAbateVenda": "Data abate",
			"nMapaAmortizacao": "Mapa",
			"naturezaDosAtivosStr": "Natureza"
		},
		"btn": {
			"geraativos": "Gerar mapas"
		},
		"tab": {
			"bens": "Bens",
			"mapasarquivados": "Mapas arquivados"
		},
		"nav": {
			"mapa": "Mapa"
		},
		"modal": {
			"jaexistemmapas": "Já existem mapas gerados para o ano.",
			"jaexistemmapastext": "Deseja continuar e substituir os mapas existentes?."
		},
		"message": {
			"mapasgerados": "Mapas gerados",
			"benspesquisados": "Bens pesquisados com sucesso.",
			"errorFolderMessage": "Não existem mapas arquivados ou ainda não foram gerados."
		}
	},
	"ativoshistorico": {
		"table": {
			"tipoAcontecimentoSTR": "Tipo",
			"dataReferencia": "Data referência",
			"nomeUtilizador": "Utilizador",
			"stampInsert": "Data/Hora do registo",
			"observacoes": "Observações"
		},
		"title": {
			"historico": "Histórico"
		}
	},
	"ativosimport": {
		"title_detail": "Ativo Importado \"{{id}}\"",
		"title_new": "Nova Importação",
		"title_plural": "Importações",
		"saved": "Importação {{id}}, guardada com sucesso.",
		"error": "Importação {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Importação {{id}}, apagada com sucesso",
		"deletedsuccess": "Ativo apagado com sucesso",
		"calculoaceite": "Cálculos aceites com sucesso",
		"calculobaseanual": "Cálculos base anual efetuados com sucesso",
		"calculoinicioutilizacao": "Cálculos início de utilização efetuados com sucesso",
		"valoreslimposcomsucesso": "Valores atribuídos manualmente limpos com sucesso",
		"atpercred": "Valores atribuídos manualmente limpos com sucesso",
		"valsemarredondanual": "Valores sem arredondamento anual distribuídos com sucesso",
		"valoranosanterior": "Valor atribuído pelos anos anterioes distribuído com sucesso",
		"pesquisa": "Pesquisa",
		"onlyByImportar": "Mostra só ativos por importar",
		"table": {
			"semdados": "Sem dados",
			"header": {
				"aquisicao": "Aquisição",
				"depacumucontab": "Depreciações acumuladas contabilísticas",
				"depacumufiscais": "Depreciações acumuladas fiscais",
				"decregulamentar": "Doc. regulamentar",
				"contas": "Contas",
				"associacaocontab": "Associação contabilidade",
				"sel": "Sel."
			},
			"estado0": "Normal",
			"estado1": "Abatido",
			"estado2": "Vendido",
			"estado3": "Divido",
			"baseCalculo0": "Base anual",
			"baseCalculo1": "A partir do início da utilização",
			"metodoCalculo0": "Quotas constantes",
			"metodoCalculo1": "Quotas decrescentes"
		},
		"fields": {
			"importado": "Importado",
			"codAtivo": "Cód. ativo",
			"designacao": "Designação",
			"refExterna": "Ref. externa",
			"usado": "Usado",
			"estado": "Estado",
			"dataAbate": "Data abate",
			"valorVenda": "Valor venda",
			"dataAquisicao": "Data aquisição",
			"valorAquisicao": "Valor aquisição contab.",
			"valorAquisicaoFiscal": "Valor aquisição fiscal",
			"mapa": "Mapa",
			"dtInicioUtilizacao": "Dt. início utilização",
			"baseParaCalculo": "Base para cálculo",
			"metodoParaCalculo": "Método para cálculo",
			"dtUltProcessamento": "Dt. últ. processamento",
			"valoresManuais": "Valores manuais",
			"depAcumulada": "Dep. acumulada",
			"depExercicio": "Dep. exercício",
			"percReducao": "% Redução",
			"depCalculada": "Dep. calculada",
			"diferenca": "Diferença",
			"codDR": "Cód. DR",
			"viatLigArtigo11DR": "Viatura ligeira - Art. 11.º DR",
			"taxaContab": "Taxa contab.",
			"taxaFiscal": "Taxa fiscal",
			"ncontaAtivo": "Conta ativo",
			"nomeDaContaDoAtivo": "Nome da conta do ativo",
			"ncontaFornecedor": "Conta fornecedor",
			"nomeDaContaDoFornecedor": "Nome da conta do fornecedor",
			"periodo": "Período",
			"diario": "Diário",
			"nDocInterno": "Nº doc. interno",
			"quantidade": "Quantidade"
		},
		"form": {
			"designacao": "Designação",
			"refExterna": "Ref. externa",
			"dataAquisicao": "Data aquisição",
			"dataInicioUtiliza": "Data de início de utilização",
			"valorAquisicao": "Valor aquisição",
			"valorAquisicaoFiscal": "Valor aquisição fiscal",
			"codAmortizacao": "Cód. amortização",
			"valorResidual": "Valor residual",
			"nMapaAmortizacao": "Mapa",
			"viatLigArtigo11DR": "Viatura ligeira - Art. 11.º DR",
			"usado": "Usado",
			"taxaAmortizacao": "Taxa contabilística",
			"percReducao": "% Redução contab.",
			"fiscalTaxaAmortiza": "Taxa fiscal",
			"fiscalPercReducao": "% Redução fiscal",
			"nContaDoAtivo": "Nº conta do ativo",
			"nContaFornecedor": "Nº conta do fornecedor",
			"baseCalculo": "Base para cálculo",
			"metodoCalculo": "Método para cálculo",
			"estado": "Estado",
			"dataUltProcessamento": "Último processamento",
			"depreciacaoAcumulada": "Depreciação acumulada contabilística",
			"depreciacaoExercicio": "Dep. exercício",
			"fiscalDepAcumulada": "Depreciação acumulada fiscal",
			"fiscalDepExercicio": "Dep. exerc. fiscal",
			"dataAbate": "Data abate/venda",
			"valorVenda": "Valor venda",
			"quantidade": "Quantidade"
		},
		"radioOptions": {
			"normal": "Normal",
			"abatido": "Abatido",
			"vendido": "Vendido",
			"quotasConstantes": "Quotas constantes",
			"quotasDecrescentes": "Quotas decrescentes",
			"inicioUtilizacao": "A partir do início da utilização",
			"baseAnual": "Base anual"
		},
		"steps": {
			"aquisicaoAtivo": "Aquisição",
			"contas": "Contas",
			"depreciacoes": "Depreciações acumuladas"
		},
		"tabs": {
			"porimportar": "Por importar",
			"importados": "Importados"
		},
		"btn": {
			"excel": "Excel",
			"apagasel": "Apagar selecionados",
			"delativossel": "Apagar imp. de ativos sel.",
			"delativosselimportados": "Apagar ativos importados sel.",
			"dropdown": {
				"editar": "Editar",
				"aceitarcalculos": "Aceitar cálculos",
				"alterarmetodocalculo": "Alterar método de cálculo",
				"atribuivaloranualmanual": "Atribuir valores anuais manualmente",
				"distribuivalorsemarredond": "Distribuir valores sem arredondamento anual",
				"distribuivaloranosanterior": "Distribuir valor atribuido pelos anos anteriores",
				"atribuipercredu": "Atribuir percentagem de redução",
				"limpavaloratribuid": "Limpa valores atribuidos manualmente",
				"abrirdoccontab": "Abrir documento de contabilidade",
				"apagaativo": "Apagar importação do ativo",
				"associarativos": "Associar ativos",
				"alterarmetodocalculoanual": "Alterar método de cálculo - base anual",
				"alterarmetodocalculoinicio": "Alterar método de cálculo - início de utilização",
				"apagaativoimport": "Apagar ativo importado"
			},
			"actions": {
				"criafolhaimportar": "Criar folha para importação",
				"importarficheiro": "Importar ficheiro"
			}
		},
		"modal": {
			"contasinconsistencias": "Contas com diferenças entre a contabilidade e os ativos",
			"tipo": "Tipo",
			"ajustescontastitle": "Documentos de ajustes gerados",
			"criaficheiroexcel": "Criação de ficheiro excel",
			"importaficheiro": "Importação de ficheiro excel",
			"ficheirocriadocomsucesso": "Ficheiro excel criado com sucesso",
			"atpercreducao": "Atribuir percentagem de redução",
			"dadoscontab": "Dados contabilísticos",
			"dadosfiscais": "Dados fiscais",
			"atvaloranualman": "Atribui valores manualmente",
			"valoresemcadano": "Valores em cada ano",
			"difcontab": "Diferença contabilística",
			"diffiscal": "Diferença fiscal",
			"folhaimport": "Criação de ficheiro Excel",
			"colunasfich": "Colunas para o ficheiro",
			"reavaliacoes": "Reavaliações",
			"filesuccess": "Ficheiro criado com sucesso!",
			"fields": {
				"taxaamort": "Taxa amortização",
				"amortacumu": "Amortização acumulada",
				"percred": "% Redução",
				"valoraquisicao": "Valor aquisição",
				"inseridomanual": "(Inserido manualmente)",
				"aplicarred": "Aplicar % redução",
				"dadosfiscaisiguais": "Dados fiscais iguais aos contabilisticos",
				"todosanos": "Todos os anos",
				"perc": "Percentagem de redução",
				"nConta": "Nº Conta",
				"nomeConta": "Nome conta",
				"valorContabilidade": "Valor contabilidade",
				"valorAtivos": "Valor nos ativos",
				"diferenca": "Diferença",
				"periodo": "Período",
				"ndiario": "Diário",
				"ndocInterno": "Nº doc. interno"
			},
			"btn": {
				"aplicarpercred": "Aplicar % redução",
				"excel": "Excel",
				"importarativos": "Importar ativos",
				"inconsistenciabtn": "Lançar diferenças como ajustes",
				"fechar": "Fechar",
				"actions": {
					"criafolhaimportar": "Criar folha para importação",
					"importarficheiro": "Importar ficheiro"
				}
			},
			"table": {
				"depcontab": "Depreciação contab.",
				"ajuste": "Ajuste",
				"depfiscal": "Depreciação fiscal"
			},
			"msg": {
				"ativosimportados": "Ativos importados!",
				"avisoinconsistenciasfirst": "Há contas com diferenças entre a contabilidade e os ativos.",
				"avisoinconsistenciassecond": "Caso pretenda, pode lançar as diferenças como ajustes.",
				"ajustesnascontas": "Ativos importados e ajustes nas contas registados.",
				"desejaimportar": "Deseja importar os ativos?",
				"haativosdataaquisicao": "Há ativos com data de aquisição no ano em curso. Estes ativos não serão importados por esta ferramenta!<br>Devem sê-lo, pelos métodos de aquisição normais no ano (a partir da contabilidade ou aquisição).<br>",
				"haativoserro": "Há ativos com erro. Estes ativos não serão importados!<br>",
				"aprepararimportacao": "A preparar o processo de importação..."
			},
			"camposexport": {
				"basecalculo": "Base calculo",
				"designacao": "Designação",
				"mapa": "Mapa",
				"CodAmortizacao": "Cód. amortização",
				"ViatLigArt11DR": "Viatura ligeira - Art. 11.º DR",
				"TaxaAmortizacao": "Taxa dep. contabilística",
				"FiscalTaxaAmortizacao": "Taxa dep. fiscal",
				"DataAquisicao": "Data aquisição",
				"DataInicioUtiliza": "Data início utilização",
				"NContaFornecedor": "Nº conta fornecedor",
				"ValorAquisicao": "Valor aquisição",
				"ValorAquisicaoFiscal": "Valor aquisição fiscal",
				"NContaDoAtivo": "Nº conta ativo",
				"PercReducao": "Perc. redução contab.",
				"FiscalPercReducao": "Perc. redução fiscal",
				"BaseCalculo": "Base cálculo",
				"MetodoCalculo": "Método cálculo",
				"DepreciacaoAcumulada": "Depreciação acumulada",
				"DepreciacaoExercicio": "Depreciação do exercício",
				"FiscalDepreciacaoAcumulada": "Dep. acumulada fiscal",
				"FiscalDepreciacaoExercicio": "Dep. do exercício fiscal",
				"DataUltProcessamento": "Data últ. processamento",
				"Usado": "Usado",
				"Qtd": "Quantidade",
				"RefExterna": "Ref. externa",
				"Estado": "Estado",
				"DataAbate": "Data abate/venda",
				"ValorVenda": "Valor da venda",
				"ValorResidual": "Valor residual",
				"NCCusto": "C. Custo",
				"NZona": "Zona",
				"NDepto": "Departamento",
				"NSubde": "Sub-departamento",
				"TipoArtigo": "Tipo artigo",
				"NGrFamilia": "Grande família",
				"NFamilia": "Família",
				"NSubfa": "Sub-família",
				"CodContab": "Cód. contabilidade",
				"Classe": "Classe",
				"Categoria": "Categoria",
				"NVendedor": "Vendedor",
				"NRefProcesso": "Processo",
				"NSeccao": "Secção",
				"DL126": "DL 126/77",
				"DL24": "DL 24/82",
				"DL219": "DL 219/82",
				"DL399G": "DL 399G/84",
				"DL118B": "DL 118B/86",
				"DL111": "DL 111/88",
				"DL49": "DL 49/91",
				"DL264": "DL 264/92",
				"DL31": "DL 31/98"
			},
			"importProgressTitle": "Importação"
		},
		"messages": {
			"temQueTerValorAtribuido": "O ano {{ultAno}} tem que ter valor atribuído.",
			"temqueselecionarativo": "Tem que selecionar pelo menos um ativo."
		},
		"jobImportTimeoutModalTitle": "Importação",
		"jobImportTimeoutModalMessage": "A tarefa excedeu o tempo limite. Tem que iniciar a importação novamente."
	},
	"ativosimpressao": {
		"header": {
			"codAtivoDe": "Cód. ativo de",
			"nContaDe": "Nº conta de",
			"nCCustoDe": "Nº centro de custo de",
			"codAmortDe": "Cód. amortização de",
			"codMapaDe": "Cód. mapa de",
			"dataAquisicaoDe": "Data de aquisição de",
			"dataAbateDe": "Data de abate de",
			"situacao": "Situação",
			"datareferencia": "Data de referência (Para situação do ativo e análise de depreciações)",
			"pesqavancada": "Filtro avançado",
			"estadoUso": "Estado do uso"
		},
		"radioOptions": {
			"todos": "Normal e abatido/vendido no ano",
			"dividido": "Dividido",
			"normal": "Normal",
			"abatido": "Abatido",
			"vendido": "Vendido",
			"indiferente": "Qualquer estado",
			"usado": "Usado",
			"novo": "Novo"
		},
		"table": {
			"aquisicao": "Aquisição",
			"classificadores": "Classificadores",
			"contas": "Contas",
			"dadoscontabilisticos": "Dados contabilísticos",
			"dadosfiscais": "Dados fiscais",
			"abatevenda": "Abate/Venda",
			"limiteminimofiscal": "Limite mínimo fiscal",
			"perdasimparidadecontabilistica": "Perdas por imparidade contabilísticas",
			"perdasimparidadefiscais": "Perdas por imparidade fiscais",
			"valoresperdidosfiscais": "Valores perdidos fiscais",
			"taxasperdidasfiscais": "Taxas perdidas fiscais",
			"depreciacoesnaoaceites": "Depreciacões não aceites",
			"imparidadesnaoaceites": "Imparidades não aceites",
			"depreciacoesarecuperar": "Depreciacões a recuperar",
			"depreciacoesrecuperadas": "Depreciacões recuperadas",
			"imparidadesrecuperadasnoperiodo": "Imparidades recuperadas no período",
			"acrescemodelo22": "Acresce ao modelo 22",
			"deduzmodelo22": "Deduz ao modelo 22",
			"valortributavelativosporimpos": "Valor tributável do ativo por impostos diferidos",
			"valortributaveldopassivoporimpos": "Valor tributável do passivo por impostos diferidos",
			"valornaoaceitefiscalmenteporreavaliacoes": "Valor não aceite fiscalmente por reavaliacões",
			"impostosdiferidos": "Impostos diferidos",
			"rendimentosdossubsidios": "Rendimentos dos subsídios",
			"impostosdifSubsidios": "Impostos diferidos sobre subsídios",
			"semdados": "Sem dados",
			"sub": {
				"codativo": "Cód. ativo",
				"designacao": "Designação",
				"estado": "Estado",
				"refexterna": "Ref. externa",
				"usado": "Usado",
				"valoraquisicaocontabilisticocomr": "Valor aquisição contabilístico, com residual",
				"dataaquisicao": "Data aquisição",
				"mapa": "Mapa",
				"coddecregulamentar": "Cód. dec. regulamentar",
				"nomeDecRegulamentar": "Nome dec. regulamentar",
				"taxaDoDecRegulamentar": "Taxa do dec. regulamentar",
				"viatLigArtigo11": "Viat. lig. artigo 11",
				"codAtivoPai": "Cód. ativo pai",
				"numContaAtivo": "Nº conta ativo",
				"numContaDepAcum": "Nº conta dep. acum.",
				"numContaDepExerc": "Nº conta dep. exerc.",
				"valAquisicao": "Val. aquisição",
				"valResidual": "Val. residual",
				"taxaDepreciacaoAdoptada": "Taxa depreciação adoptada",
				"reducao": "Redução",
				"depNoAno": "Dep. no ano",
				"depAcumNoAnoAnterior": "Dep. acum. no ano anterior",
				"depAcumTotal": "Dep. acum. total",
				"valor": "Valor",
				"coefCorrecaoMonetaria": "Coef. correção monetária",
				"contaVenda": "Conta venda",
				"maisMenosValiaContabilistica": "Mais/Menos valia contabilística",
				"maisMenosValiaFiscal": "Mais/Menos valia fiscal",
				"noAno": "No ano",
				"acumNoAnoAnterior": "Acum. no ano anterior",
				"acumTotal": "Acum. total",
				"taxaIRC": "Taxa IRC",
				"noAnoAnterior": "No ano anterior",
				"situacaoa": "Situação a ",
				"situacaoAtDataSTR": "Situação a {{dataReferencia}}",
				"doccontabilidade": "Doc. contabilidade (aquisição)",
				"codProcesso": "Cód. processo",
				"nomeProcesso": "Nome processo",
				"codCCusto": "Cód. C. Custo",
				"nomeCCusto": "Nome C. Custo",
				"metodoCalculo": "Método para cálculo",
				"baseCalculo": "Base para cálculo"
			}
		},
		"btn": {
			"pesquisar": "Ver em grelha",
			"processar": "Processar",
			"ok": "Fechar",
			"dropdown": {
				"depreciacoes": "Consultar depreciações",
				"fichaativo": "Ficha do ativo",
				"extaquisicao": "Extrato da conta de aquisição",
				"extexercicio": "Extrato da conta de dep. do exercício",
				"extacumulada": "Extrato da conta de dep. acumulada",
				"doccontab": "Doc. contabilidade"
			},
			"processPDFTooltip": "Selecione um ativo para processar o pdf"
		},
		"modal": {
			"ativoimpressaomodaltitle": "Impressão de ativos",
			"ativoimpressaodepmodaltitle": "Detalhe das depreciações do ativo",
			"depresumo": "Resumo",
			"depDetalhe": "Detalhe",
			"ativoimpressaodocmodaltitle": "Doc. contabilidade"
		}
	},
	"ativosmapasoficiais": {
		"header": {
			"codAtivoDe": "Cód. ativo de",
			"nContaDe": "Nº conta de",
			"nCCustoDe": "Nº centro de custo de",
			"codAmortDe": "Cód. amortização de",
			"nMapa": "Mapa",
			"dataAquisicaoDe": "Data de aquisição de",
			"dataAbateDe": "Data de abate de",
			"anoEconomico": "Ano económico",
			"situacao": "Situação",
			"registoMes": "Mês",
			"natureza": "Natureza"
		},
		"radioOptions": {
			"todos": "Normal e abatido/vendido no ano",
			"normal": "Normal",
			"abatido": "Abatido",
			"vendido": "Vendido",
			"qualquer": "Qualquer método",
			"quotasConstantes": "Quotas constantes",
			"quotasDecrescentes": "Quotas decrescentes",
			"indiferente": "Qualquer estado",
			"usado": "Usado",
			"novo": "Novo"
		},
		"btn": {
			"previsualizar": "Pré-visualizar",
			"processar": "Processar",
			"ok": "Fechar"
		},
		"print": {
			"ativoimpressaomodaltitle": "Impressão de ativos",
			"metodos": "Métodos",
			"estadouso": "Estado"
		},
		"natureza": {
			"qualquer": "Qualquer natureza",
			"tangiveis": "Ativos fixos tangíveis",
			"intangiveis": "Ativos intangíveis",
			"bioNaoConsumiveis": "Ativos biológicos não consumíveis",
			"propInvetimento": "Propriedades de investimento"
		},
		"card": {
			"selecao": "Seleção"
		},
		"modal": {
			"title": "Ativos - Mapa oficial"
		}
	},
	"ativosmudarcontaativo": {
		"temcerteza": "Tem a certeza que pretende alterar a conta do ativo?",
		"title": {
			"mudarcontaativo": "Mudar conta do ativo"
		},
		"fields": {
			"ncontanovo": "Nº conta novo",
			"ncontaatual": "Nº conta atual"
		},
		"message": {
			"success": "Conta do ativo alterada com sucesso!"
		}
	},
	"ativosmudarcontadep": {
		"temcerteza": "Tem a certeza que pretende alterar a conta?",
		"title": {
			"mudarcontadepexerc": "Mudar conta de depreciação do exercício",
			"mudarcontadepacumu": "Mudar conta de depreciação do exercício"
		},
		"fields": {
			"ncontanovo": "Nº conta novo",
			"ncontaatual": "Nº conta atual"
		},
		"message": {
			"successExercicio": "Conta de depreciação do exercício alterada com sucesso!",
			"successAcumulado": "Conta de depreciação do acumulado alterado com sucesso!",
			"jahadep": "Já há depreciações registadas para o ano em curso, não é possível alterar a conta!",
			"contaVazio": "O nº conta não pode ser vazio."
		}
	},
	"ativosmudartaxasdep": {
		"temcerteza": "Tem a certeza que pretende alterar as taxas de depreciação?",
		"title": {
			"mudartaxasdep": "Mudar taxas de depreciação",
			"depcontab": "Depreciações contabilísticas",
			"depfiscal": "Depreciações fiscais"
		},
		"fields": {
			"taxanovo": "Nova taxa",
			"taxaatual": "Taxa atual"
		},
		"message": {
			"success": "Taxas de depreciação alteradas com sucesso!"
		}
	},
	"ativosnotacredito": {
		"title": "Desconto de ativos",
		"header": {
			"fornecedor": "Fornecedor",
			"valoraquisicao": "Valor de aquisição",
			"valornotacredito": "Valor de nota de crédito",
			"novovaloraquisicao": "Novo valor de aquisição"
		},
		"btn": {
			"pesquisanota": "Pesquisa nota de crédito"
		},
		"table": {
			"nContaDebito": "Débito",
			"nContaCredito": "Crédito",
			"valor": "Valor",
			"descricao": "Descrição"
		},
		"modaltable": {
			"nConta": "Nº conta",
			"nome": "Nome",
			"valor": "Valor de aquisição",
			"valorCalculado": "Já associado",
			"valorCalculado2": "Em novos ativos",
			"periodo": "Período",
			"nDiario": "Nº diário",
			"nDocInterno": "Nº doc. interno",
			"dCDescription": "Deb./Cré",
			"nContaDebito": "Débito",
			"nContaCredito": "Crédito",
			"descricao": "Descrição",
			"designacao": "Designação",
			"codAtivo": "Cód.",
			"nContaDoAtivo": "Nº conta do ativo",
			"contabValorAquisicao": "Valor"
		},
		"message": {
			"temdeselecionarum": "Tem de selecionar um desconto!",
			"temcerteza": "Tem a certeza que pretende associar a Nota de Crédito, e reduzir o valor líquido do ativo?",
			"success": "Desconto associado com sucesso!"
		},
		"submodal": {
			"title": "Pesquisa nota de crédito"
		}
	},
	"ativosvenda": {
		"ativosporvender": "Ativos por vender",
		"datavenda": "Data da venda",
		"selecionarativos": "Selecione os ativos a vender",
		"distribuicaovalor": "Distribuição do valor da venda pelos ativos",
		"radiooptions": {
			"liquido": "Proporcional pelo valor líquido",
			"bruto": "Proporcional pelo valor bruto",
			"manual": "Atribuir manualmente"
		},
		"message": {
			"success": "Venda registada!",
			"selecionarfatura": "Tem de selecionar uma venda",
			"docnaosaldado": "O documento contabilístico selecionado não está saldado!"
		},
		"steps": {
			"tabeladocumentos": "Pesquisa de novas vendas na contabilidade",
			"detalhesparavenda": "Valores e contas da venda do ativo"
		},
		"card": {
			"valorsemiva": "Valor sem IVA",
			"valordoiva": "Valor do IVA",
			"total": "Total",
			"docliente": "Do cliente",
			"maismenosvalia": "Mais / Menos valia",
			"iva": "IVA",
			"contas": "Contas",
			"valores": "Valores",
			"coef": "Coef. corr. monet."
		},
		"table": {
			"codAtivo": "Cód. ativo",
			"designacao": "Designação",
			"refExterna": "Ref. externa",
			"dataAquisicao": "Dt. aquisição",
			"valorAtivoBrutoContab": "Ativo bruto",
			"valorDepreciacoesAcumuladasContab": "Dep. acumuladas",
			"ativosporvender": "Ativos por vender",
			"ativosavender": "Ativos a vender",
			"valorVenda": "Valor venda"
		},
		"tooltip": {
			"valorNaoValido": "A soma dos valores da venda dos ativos não é igual ao valor do documento!"
		},
		"btn": {
			"realizarvenda": "Realizar venda",
			"pesquisafaturavenda": "Pesquisar fatura da venda"
		},
		"error": {
			"invalidStep": "Tem de selecionar um documento para poder registar a venda!"
		}
	},
	"auditlogin": {
		"fields": {
			"username": "Nome de utilizador",
			"centralGestName": "Instalação",
			"cgId": "Cód. instalação",
			"ip": "IP",
			"action": "Ação",
			"stampInsert": "Data",
			"nEmpresa": "Nº empresa",
			"nomeEmpresa": "Nome empresa"
		}
	},
	"contratosservicos": {
		"title_detail": "Avença {{id}}",
		"title_new": "Nova Avença",
		"title_plural": "Avenças",
		"pesquisa": "Pesquisar",
		"saved": "Avença {{id}}, guardada com sucesso",
		"error": "Avença {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Avença {{id}}, apagada com sucesso",
		"fields": {
			"cab": {
				"refCtrCab": "Cód. avença",
				"nClasseCtr": "Classe",
				"classeCtrNome": "Nome classe",
				"nClasseCtrPlaceholder": "Classe da avença",
				"nTipoContrato": "Tipo",
				"tipoContratoNome": "Nome tipo",
				"nTipoContratoPlaceholder": "Tipo de avença",
				"nContrato": "Nº avença",
				"nContratoPlaceholder": "Número de avença",
				"verContrato": "Versão",
				"verContratoPlaceHolder": "Versão de avença",
				"nContratoAsString": "Avença",
				"nContratoSemVersaoAsString": "Avença",
				"descricao": "Descrição",
				"descricaoPlaceHolder": "Descrição da avença",
				"nClifo": "Cliente",
				"nClifoPlaceHolder": "Cliente",
				"clifoNome": "Nome cliente",
				"idIdeChav": "Grupo de conta",
				"idIdeChavPlaceHolder": "Grupo de conta",
				"dataCtr": "Data avença",
				"dataCtrPlaceHolder": "Data avença",
				"dataInicio": "Data início",
				"dataInicioPlaceHolder": "Data início",
				"dataFim": "Data fim",
				"dataFimPlaceHolder": "Data fim",
				"estado": "Estado",
				"estadoPlaceHolder": "Cód. estado",
				"ccusto": "Centro de custo",
				"ccustoPlaceHolder": "Código do centro de custo",
				"nRefProcesso": "Processo",
				"nRefProcessoPlaceHolder": "Código do processo",
				"codMoeda": "Moeda",
				"codMoedaPlaceHolder": "Cód. moeda",
				"refExterna": "Referência externa",
				"terminado": "Terminado",
				"totalLiquido": "Valor",
				"valorGlobal": "Valor global",
				"codCondComerciais": "Condição comercial",
				"codCondComerciaisPlaceHolder": "Código da condição comercial",
				"estadoContrato": "Estado",
				"estadoContratoPlaceHolder": "Estado do contrato"
			},
			"linhas": {
				"nArtigo": "Cód. artigo",
				"nomeArtigo": "Descrição",
				"qtd1": "Qtd",
				"prVenda": "Pr. unit.",
				"d1": "D1",
				"d2": "D2",
				"d3": "D3",
				"desconto": "Desconto",
				"d4": "D4",
				"d5": "D5",
				"d6": "D6",
				"codIva": "Cód. IVA",
				"taxaIva": "Taxa IVA",
				"nArmazem": "Armazém",
				"cunimo": "Unid. mov.",
				"qtdPUnid": "Qtd. / Unid.",
				"nVendedor": "Vendedor",
				"ccusto": "Centro de custo",
				"nZona": "Zona",
				"nDepto": "Departamento",
				"nSubde": "Sub-departamento",
				"qtd1Fact": "Qtd. faturada",
				"nRefProcesso": "Processo",
				"estado": "Estado",
				"obs": "Observações",
				"valorLiquido": "Líquido"
			}
		},
		"tabs": {
			"linhas": "Linhas"
		},
		"config": {
			"verGrupoConta": "Mostrar grupo conta",
			"verMoeda": "Mostrar moeda",
			"verRefExterna": "Mostrar referência externa",
			"verCondComerciais": "Mostrar condições comerciais",
			"verVendedor": "Mostrar vendedor",
			"verNCCusto": "Mostrar centro de custo",
			"verD1": "Mostrar D1",
			"verArmazem": "Mostrar armazém",
			"verUnidadeMovimento": "Mostrar unidade de movimento"
		},
		"errors": {
			"notSelected": "Não existe nenhuma avença selecionada",
			"terminado": "A avença {{avenca}} já se encontra terminada",
			"notTerminado": "A avença {{avenca}} não se encontra terminada"
		},
		"btn": {
			"configurar": "Configurar"
		},
		"info": {
			"terminado": "Terminada",
			"reabrir": "Reabrir avença",
			"terminar": "Terminar avença",
			"apagar": "Apagar avença"
		},
		"message": {
			"temCertezaReabrir": "Tem a certeza que deseja reabrir a avença {{avenca}}?",
			"temCertezaTerminar": "Tem a certeza que deseja terminar a avença {{avenca}}?",
			"temCertezaApagar": "Tem a certeza que deseja apagar a avença {{avenca}}?"
		},
		"modulos": {
			"classes": "Classes de avenças",
			"tipos": "Tipos de avenças",
			"faturacao": "Faturação"
		},
		"modal": {
			"faturacaoAuto": {
				"title": "Faturação automática",
				"cards": {
					"configurar": "Tipo de avença a configurar"
				}
			}
		}
	},
	"avisoscobranca": {
		"fields": {
			"clifoNaoPodeFacturar": "Não pode faturar",
			"dataDocDe": "Data doc. de",
			"dataPrimeiroVencimento": "Data 1º vencimento",
			"dataUltimoAvisoProc": "Data/Hora últ. aviso",
			"dataUltimoComentario": "Data últ. comentário",
			"dataUltimoRecebimento": "Data últ. recebimento",
			"dataVencimento": "Data vencimento",
			"dataVencimentoDe": "Data vencimento de",
			"dividaVencida": "Dívida vencida",
			"emailDestino": "Email",
			"grupoConta": "Grupo conta",
			"grupoContaStr": "Descrição gr. conta",
			"nCondPagamDe": "Nº condição pagamento de",
			"nConta": "Nº conta",
			"nContaDe": "Nº conta de",
			"nContaNome": "Nome",
			"nDiario": "Diário",
			"nDiasUltimoRecebimento": "Nº dias últ. recebimento",
			"nDocExterno": "Nº doc. externo",
			"nDocInterno": "Nº documento",
			"nDocsPorRegularizar": "Nº docs por regularizar",
			"nVendedorDe": "Nº vendedor de",
			"observacoes": "Observações",
			"periodo": "Período",
			"ultimoComentario": "Último comentário",
			"valor": "Valor",
			"valorCredito": "Crédito",
			"valorDebito": "Débito",
			"valorPago": "Valor pago",
			"valorPorPagar": "Valor por pagar"
		},
		"btn": {
			"previsualizar": "Pré-visualizar",
			"sendMail": "Enviar e-mail",
			"pesqinterrompido": "Pesquisa com processamento interrompido"
		},
		"modal": {
			"avisoscobranca": "Avisos cobrança",
			"avisoscobrancaemail": "Enviar por e-mail os avisos de cobrança selecionados",
			"avisoscobrancaAlterarEmailProntual": "Alterar e-mail pontualmente",
			"erros": {
				"title": "Lista de erros",
				"noEmails": "Não foi encontrada nenhuma conta com movimentos pendentes que tenha e-mail configurado"
			},
			"emissora": {
				"configuradaEasyPay": "Está configurada a emissora de referências MB: EasyPay",
				"defaultReport": "Relatório por defeito",
				"desativado": "Desativado",
				"easypay": "EasyPayOnline",
				"emissaorefmb": "Emissão de referência multibanco",
				"emissora": "Emissora",
				"emitirAvisosCobranca": "Deseja emitir referências MB nos avisos de cobrança?",
				"naoExisteConfig": "Não existe nenhuma emissora de referências MB ativa",
				"title": "Configuração de avisos de cobrança"
			}
		},
		"messages": {
			"erroRadicais": "Apenas pode pesquisar clientes neste módulo (Contas começadas por 22).",
			"naoselconta": "Não selecionou nenhuma conta",
			"estadochangedsuccess": "Estado alterado com sucesso!",
			"procseminterrompido": "Há registos com o processamento interrompido.",
			"contaSemMail1": "As seguintes contas selecionadas não têm e-mail atribuído: ",
			"contaSemMail2": "Pretende continuar?",
			"allContasSemMail": "Não existem e-mails de contas configurados",
			"savedsuccessfully": "Dados guardados com sucesso!",
			"emailSentSuccessfully": "E-mail enviado com sucesso."
		},
		"actions": {
			"cliente": "Cliente",
			"extratoconta": "Extrato de conta",
			"movaberto": "Movimentos em aberto",
			"previsualiza": "Pré-visualizar listagem",
			"naopodefaturar": "Mudar estado do cliente para \"Não pode faturar\"",
			"podefaturar": "Mudar estado do cliente para \"Pode faturar\"",
			"doccontab": "Mostra doc. contabilidade",
			"doccomercial": "Mostra doc. faturação",
			"alterarEmailPontual": "Alterar e-mail pontualmente"
		},
		"config": {
			"showVendedor": "Mostrar vendedor",
			"showCondPagamento": "Mostrar condições de pagamento"
		},
		"datagrid": {
			"legend": {
				"campoAlterado": "Campo pontualmente alterado"
			}
		}
	},
	"balancetes": {
		"title": "Balancetes",
		"fields": {
			"doPeriodo": "Período de",
			"deConta": "Conta de",
			"deCCusto": "C. Custo de",
			"planoAlt": "Plano alternativo"
		},
		"naoTemTipoConta": "Deve selecionar pelo menos um tipo de conta para efetuar o processamento!",
		"btn": {
			"processar": "Processar em PDF",
			"processarexcel": "Processar e exportar excel",
			"processargrelha": "Processar",
			"exportardefir": "Exportar Defir",
			"exportargabmov": "Exportar Gabmov",
			"dropdown": {
				"planocontas": "Plano de contas",
				"extratosgrid": "Extractos de conta",
				"historicoextratos": "Histórico de extratos de conta",
				"clifos": "Clientes e fornecedores",
				"movaberto": "Movimentos em aberto",
				"extratoPlanoAlt": "Extratos do plano alternativo",
				"acumuPlanoAlt": "Acumulados do plano alternativo"
			}
		},
		"messages": {
			"withoutData": "Sem dados para apresentar!"
		},
		"modal": {
			"title": "Balancete de contabilidade",
			"tipoperfil": "Perfil",
			"pesq": "Pesquisa",
			"pesquisar": "Pesquisar",
			"table": {
				"empresa": "{{nempresa}} - {{descempresa}}",
				"descano": "{{ano}} - {{periodoDesc}} ({{doperiodo}} até {{ateperiodo}})",
				"conta": "Conta",
				"movperiodo": "Movimentos do período",
				"movacumu": "Movimentos acumulados",
				"total": "Totais",
				"semdados": "Sem dados",
				"header": {
					"nconta": "Nº conta",
					"nome": "Nome",
					"debito": "Débito",
					"credito": "Crédito",
					"saldodebito": "Saldo débito",
					"saldocredito": "Saldo crédito",
					"saldogeral": "Saldo geral"
				}
			},
			"tipocontacontab": {
				"0": "Movimento",
				"1": "Sub-total",
				"2": "Razão",
				"3": "Classe"
			}
		},
		"tipoperfil": {
			"data": {
				"todas": "Todas colunas",
				"saldo": "Colunas de saldo"
			}
		},
		"tab": {
			"reportpdf": "PDF",
			"reportgrelha": "Grelha",
			"grelhaprocessar": "Tem de processar a grelha!"
		}
	},
	"balancetesanalitica": {
		"periodoDe": "Período de",
		"nContaCGeralDe": "Conta de",
		"cCustoDe": "C. Custo de",
		"zonaDe": "Zona de",
		"departamentoDe": "Departamento de",
		"subDepartDe": "Sub. departamento de",
		"familiaDe": "Família de",
		"grFamiliaDe": "Grande família de",
		"subFamiliaDe": "Sub. família de",
		"codContabDe": "Cód. contabilístico de",
		"tipoArtigoDe": "Tipo artigo de",
		"classeDe": "Classe de",
		"categoriaDe": "Categoria de",
		"vendedorDe": "Vendedor de",
		"processoDe": "Processo de",
		"erros": {
			"listagemNaoSelec": "Precisa de selecionar um item da listagem",
			"contaFinalInferiorInicial": "A conta final é inferior à inicial",
			"custoFinalInferiorInicial": "O custo final é inferior ao inicial",
			"zonaFinalInferiorInicial": "A zona final é inferior à inicial",
			"depFinalInferiorInicial": "O departamento final é inferior ao inicial",
			"subDepFinalInferiorInicial": "O sub. departamento final é inferior ao inicial",
			"famFinalInferiorInicial": "A família final é inferior à inicial",
			"grFamFinalInferiorInicial": "A grande família final é inferior à inicial",
			"subFamFinalInferiorInicial": "A sub família final é inferior à inicial",
			"codContabFinalInferiorInicial": "O cód. contabilístico final é inferior ao inicial",
			"tipoArtFinalInferiorInicial": "O tipo artigo final é inferior ao inicial",
			"classeFinalInferiorInicial": "A classe final é inferior à inicial",
			"catFinalInferiorInicial": "A categoria final é inferior à inicial",
			"vendFinalInferiorInicial": "O vendedor final é inferior ao inicial",
			"procFinalInferiorInicial": "O processo final é inferior ao inicial",
			"periodoAteNDefinido": "Precisa de definir o período"
		}
	},
	"bancos": {
		"title_detail": "Banco {{id}}",
		"title_new": "Novo Banco",
		"title_plural": "Identificação de Bancos",
		"pesquisa": "Pesquisar",
		"saved": "Identificação de Banco {{id}}, guardado com sucesso.",
		"error": "Identificação de Banco {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Identificação de Banco {{id}}, apagado com sucesso",
		"fields": {
			"codBanco": "Código",
			"nome": "Nome",
			"designaBreve": "Designação breve",
			"idInterBancaria": "Identificação interbancária",
			"nomeInterBancaria": "Nome da identificação interbancária",
			"codSwift": "Código SWIFT"
		}
	},
	"bancosextrato": {
		"config": {
			"autoSuggestDoc": "Sugerir lançamento automaticamente"
		},
		"fields": {
			"bankAccount": "Conta bancária",
			"description": "Descrição",
			"amount": "Valor",
			"predefinido": "Pré-Definido",
			"observacoes": "Observações",
			"saldo": "Saldo atual",
			"dataSyncDados": "Data de sincronização",
			"nConta": "Nº Conta",
			"nomeBanco": "Nome do banco",
			"iban": "IBAN",
			"visivel": "Visível",
			"diarioRecebimentos": "Diário recebimentos",
			"diarioPagamentos": "Diário pagamentos"
		},
		"actions": {
			"refreshAccount": "Efetuar pedido para refrescar saldo e extrato da conta bancária (feito automaticamente de 6 em 6 horas)",
			"editBankAccount": "Editar conta bancária",
			"addBankAccount": "Adicionar contas bancárias de um novo banco",
			"importByReconciliation": "Importar extrato de conciliação bancária",
			"search": "Pesquisar",
			"viewDocument": "Ver documento",
			"ignoreDocuments": "Ignorar linhas de extrato selecionadas",
			"stopIgnoreDocuments": "Deixar de ignorar linha de extrato selecionada",
			"addPredefDesc": "Atribuir pré-definido ou conta por descrição do extrato",
			"editPredefDesc": "Editar atribuição de pré-definido ou conta por descrição do extrato",
			"removePredefDesc": "Remover atribuição de pré-definido ou conta por descrição do extrato",
			"mergeMode": "Unir linhas do extrato em modo completo",
			"fullExtract": "Ver extrato completo (mostra também já lançados e ignorados)",
			"doReceipt": "Efetuar recebimento",
			"doPayment": "Efetuar pagamento",
			"configurations": "Configuração geral",
			"suggestDoc": "Sugerir lançamento",
			"lancarDocs": "Lançar documentos",
			"lancarDocsEmSerie": "Lançar documentos em série",
			"selectAll": "Selecionar todos",
			"selectAllMesmoPredefinido": "Selecionar linhas com o mesmo pré-definido",
			"selectAllComPredefinido": "Selecionar todas as linhas que têm pré-definido ou configuração de conta",
			"selectAllComDocDigital": "Seleciona todos os docs com o doc digital correspondente",
			"selectAllMesmaConfgPorDesc": "Seleciona todas as linhas com a mesma configuração por descrição",
			"deselectAll": "Limpar seleção",
			"seeSimilarPostedDocs": "Ver documentos semelhantes já lançados"
		},
		"actionssimple": {
			"addPredefDesc": "Atribuir conta por descrição do extrato",
			"editPredefDesc": "Editar atribuição de conta por descrição do extrato",
			"removePredefDesc": "Remover atribuição de conta por descrição do extrato"
		},
		"text": {
			"noLicense": "A empresa atual não tem a licença de banking automation ativada. ",
			"accessDenied": "Não tem permissões para aceder ao banking automation.",
			"accessDeniedStore": "Não tem permissões para aceder ao banking automation, por favor, contacte o seu gestor para gerir o acesso.",
			"activate": "Clique aqui para ativar.",
			"selectedTransactions": "Transações selecionadas",
			"confirmEditBankAccount": "Esta conta bancária ainda não tem a conta de banco do plano de contas atribuído.<br/>Deseja atribuir agora?",
			"confirmLancarDocsEmSerie": "Tem a certeza que pretende lançar os documento selecionados?",
			"confirmLancarDocsEmSerieContabilidadeDigital": "Optou por lançar documentos em série numa empresa com arquivo digital.<br/>O sistema vai anexar automaticamente os documentos digitais sugeridos, por favor, verifique se estes se encontram bem classificados.<br/>Tem a certeza que pretende lançar os documento selecionados?",
			"successRefreshBankAccount": "O pedido para refrescar saldo e extrato da conta bancária foi efetuado com sucesso. Por favor aguarde uns segundos se os dados não forem refrescados imediatamente.",
			"successUpdateBankAccount": "Conta bancária atualizada com sucesso.",
			"successAddBankAccount": "Conta bancária adicionada com sucesso.",
			"successLancarDocsEmSerie": "Documentos lançados com sucesso.",
			"successIgnoreDocuments": "Linhas de extrato ignoradas com sucesso.",
			"successStopIgnoreDocuments": "Linha de extrato deixada de ser ignorada com sucesso.",
			"confirmIgnoreDocumentsTitle": "Ignorar linhas de extrato selecionadas",
			"confirmIgnoreDocumentsMessage": "Tem a certeza que pretende ignorar as linhas de extrato selecionadas?",
			"confirmDeletePredefDescTitle": "Remover configuração por descrição do extrato",
			"confirmDeletePredefDescMessage": "Tem a certeza que pretende remover a configuração por descrição do extrato?",
			"deletePredefDesc": "Configuração por descrição do extrato removida com sucesso.",
			"promptPrintReceipt": "Deseja imprimir o recibo?",
			"promptPrintPayment": "Deseja imprimir o pagamento?",
			"bankAccountConsentExpiredTitle": "Credencial para aceder ao banco está expirada",
			"bankAccountConsentExpiredMessage": "A credencial para aceder ao banco está expirada. Deseja introduzir novamente os dados de acesso ao banco?",
			"infoEditBankAccountDiarioInheritance": "*Se não configurar os diários vão ser utilizados os diários da configuração geral"
		},
		"legend": {
			"lancado": "Já lançado",
			"ignorado": "Ignorado",
			"comDocumentoSemelhante": "Existe um documento já lançado nessa data com o mesmo valor",
			"comConfig": "Com configuração de pré-definido ou conta"
		},
		"legendsimple": {
			"comConfig": "Com configuração de conta"
		},
		"estado": {
			"0": "Extrato por lançar",
			"1": "Extrato lançado",
			"2": "Extrato ignorado",
			"3": "Extrato por lançar com doc. possível",
			"naoLancado": "Extrato por lançar",
			"lancado": "Extrato lançado",
			"ignorado": "Extrato ignorado",
			"naoLancadoComDocPossivel": "Extrato por lançar com doc. possível"
		},
		"docDigital": {
			"0": "Sem documentos possíveis",
			"1": "Com um documento possível",
			"2": "Com vários documentos possíveis",
			"naoTem": "Sem documentos possíveis",
			"tem": "Com um documento possível",
			"varios": "Com vários documentos possíveis"
		},
		"dataRegistoRecebimentosPagamentos": {
			"label": "Data de lançamento para recebimentos e pagamentos",
			"dataAtual": "Data atual",
			"dataMovimentoBancario": "Data movimento bancário"
		},
		"errors": {
			"licenseNotActivatedCGOn": "A empresa atual não tem a licença de banking automation ativada.<br/>Por favor, aceda à <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\">loja</a> do CentralGest Cloud e ative ou renove a licença do banking automation.",
			"licenseCGOnExpired": "A sua licença de banking automation expirou. Por favor, aceda à <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\">loja</a> do CentralGest Cloud e renove a licença de banking automation.",
			"activateCGBankingLimitReached": "Não pode adicionar mais empresas à sua licença de banking automation, porque já tem \"{{consumidas}}\" empresas de \"{{total}}\" registadas. Por favor contate a CentralGest para adquirir mais empresas.",
			"activateCGBankingCGOnMode": "Não pode ativar o banking automation, porque esta é uma empresa CGOn.",
			"emptyNewBankAccountUrl": "Não foi possível obter o endereço para adicionar uma conta bancária nova.",
			"emptyRenewBankAccountUrl": "Não foi possível obter o endereço para renovar a credencial da conta bancária.",
			"newBankAccount": "Não foi possível adicionar a conta bancária: \"{{error}}\".",
			"emptyBankAccount": "Tem de indicar uma conta bancária.",
			"emptyDocs": "Não selecionou nenhum documento para lançar."
		},
		"errorsTinkLink": {
			"BAD_REQUEST": "O URL do Tink link foi configurado incorretamente",
			"AUTHENTICATION_ERROR": "Não se autenticou com sucesso na instituição financeira",
			"INTERNAL_ERROR": "Erro interno no serviço Tink",
			"TEMPORARY_ERROR": "Erro temporário no serviço Tink."
		},
		"errorsUnifiedPost": {
			"BAD_REQUEST": "O URL do UnifiedPost foi configurado incorretamente",
			"AUTHENTICATION_ERROR": "Não se autenticou com sucesso na instituição financeira",
			"INTERNAL_ERROR": "Erro interno no serviço UnifiedPost",
			"TEMPORARY_ERROR": "Erro temporário no serviço UnifiedPost."
		},
		"configmodal": {
			"title": "Configuração geral",
			"success": "Configuração gravada com sucesso",
			"fields": {
				"descricao": "Descrição por omissão",
				"movAbAuto": "Abre movimentos pendentes automaticamente ao passar para próximo registo",
				"showDescritivo": "Campo de descritivo visível"
			}
		},
		"activatelicensemodal": {
			"title": "Ativar banking automation na empresa \"{{nEmpresa}} - {{nomeEmpresa}}\"",
			"message1": "Caso clique em \"Sim\", o sistema irá adicionar uma nova empresa à sua licença de banking automation, não sendo possível reverter a situação posteriormente.",
			"message2": "Neste momento, ainda pode adicionar à sua licença de banking automation {{totalToConsume}} empresas. Deseja adicionar uma nova empresa à sua licença de banking automation?",
			"dadosLicenca": "Dados da licença de banking automation",
			"total": "Número total de empresas: {{total}}",
			"totalConsumed": "Número total de empresas já adicionadas: {{totalConsumed}}",
			"totalToConsume": "Número total de empresas que ainda pode adicionar: {{totalToConsume}}"
		},
		"authorizationsmodal": {
			"title": "Consentimentos",
			"fields": {
				"externalId": "Identificador",
				"accountsId": "Accounts ID",
				"credentialsId": "Credentials ID",
				"providerName": "Provider name",
				"status": "Status",
				"errorMessage": "Message",
				"errorType": "Type",
				"errorReason": "Reason",
				"statusUpdated": "Data",
				"sessionExpiryDate": "Data expiração"
			},
			"text": {
				"deleteConsent": "Eliminar consentimento",
				"deleteConsentMessage": "Tem a certeza que deseja eliminar este consentimento?<br/>Terá de efetuar um novo consentimento após efetuar esta operação.",
				"successDeleteConsent": "Consentimento eliminado com sucesso"
			}
		},
		"docssemelhantesmodal": {
			"title": "Documentos semelhantes já lançados na contabilidade",
			"fields": {
				"periodo": "Período",
				"diario": "Diário",
				"nDocInterno": "Nº documento",
				"nDocExterno": "Nº doc. externo",
				"dataLancamento": "Data lançamento",
				"dataDocExterno": "Data documento",
				"dc": "D/C",
				"valor": "Valor",
				"descricao": "Descrição",
				"contaCorrente": "Conta corrente"
			},
			"text": {
				"mostrarOutrasDatas": "Mostrar documentos com o mesmo valor mas lançados com outras datas"
			},
			"actions": {
				"connect": "Associar"
			}
		},
		"editbankaccountmodal": {
			"title": "Edição de conta de banco \"{{nomeBanco}}\"",
			"fields": {
				"descricao": "Descrição",
				"nomeBanco": "Nome banco",
				"iban": "IBAN",
				"visivel": "Visível",
				"conta": "Conta banco"
			},
			"text": {
				"mostrarOutrasDatas": "Mostrar documentos com o mesmo valor mas lançados com outras datas"
			},
			"actions": {
				"connect": "Associar"
			}
		},
		"unifiedPostModal": {
			"title": "Adicionar conta",
			"fields": {
				"iban": "IBAN",
				"nometitular": "Nome titular"
			}
		},
		"importbyreconciliationmodal": {
			"title": "Escolha de conciliação bancária a importar"
		},
		"movabmodal": {
			"titleReceipt": "Recebimentos por efetuar ao cliente",
			"titlePayment": "Pagamentos por efetuar ao fornecedor",
			"save": "Registar",
			"fields": {
				"nContaClient": "Conta cliente",
				"nContaSupplier": "Conta fornecedor",
				"valorExtrato": "Valor movimento bancário",
				"valorSelecionado": "Valor selecionado",
				"nDocumento": "Documento",
				"descricao": "Descrição",
				"nDocExterno": "Nº doc. externo",
				"dataDoc": "Data doc.",
				"dataVencimento": "Data venc.",
				"dc": "Débito/Crédito",
				"valor": "Valor",
				"valorPago": "Valor pago",
				"valorPorPagar": "Valor por pagar",
				"valorAReceber": "Valor a Receber",
				"valorAPagar": "Valor a pagar",
				"valorDesconto": "Desconto",
				"data": "Data"
			},
			"text": {
				"receberTudoReceipt": "Receber tudo (atalho \"+\" ou \"F9\")",
				"receberTudoPayment": "Pagar tudo (atalho \"+\" ou \"F9\")",
				"limparValor": "Limpar valor",
				"successReceipt": "Recebimento registado com sucesso",
				"successPayment": "Pagamento registado com sucesso"
			},
			"errors": {
				"recPagAmmountNoMatch": "O valor selecionado \"{{valorSelecionado}}\" é diferente do valor da transação do banco \"{{amount}}\"."
			}
		},
		"predefdescmodal": {
			"title": "Atribuir pré-definido ou conta por descrição do extrato",
			"titlesimple": "Atribuir conta por descrição do extrato",
			"fields": {
				"descriptionKey": "Descrição contém",
				"conta": "Conta contrapartida",
				"predefinido": "Pré-definido"
			}
		},
		"simplemodefilter": {
			"filter": "Filtro",
			"onlyentradas": "Ver apenas entradas",
			"onlysaidas": "Ver apenas saídas",
			"onlynaoLancado": "Ver apenas documentos por lançar",
			"onlylancado": "Ver apenas documentos já lançados"
		}
	},
	"blockedPlugin": {
		"text": {
			"portalNoLicense": "A empresa atual não tem a licença {{portal}} premium ativa. Por favor contacte a CentralGest para obter mais informações.",
			"portalNoLicenseStoreMode": "A empresa atual não tem a licença {{portal}} premium ativa.<br/>Por favor, aceda à <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\">loja</a> do CentralGest Cloud e ative a licença premium.",
			"noAccessModule": "Não tem acesso a este módulo devido a:",
			"noAccess": "Esta funcionalidade não está disponível devido a:",
			"noLicenseSingle": "A sua licença CentralGest tem de ter o componente {{portals}} ativo. Por favor contacte o seu administrador para obter mais informações.",
			"noLicenseMulti": "A sua licença CentralGest tem de ter um dos seguintes componentes ativo: {{portals}}. Por favor contacte o seu administrador para obter mais informações.",
			"noLicenseStoreMode": "A empresa atual não tem a licença {{portals}} ativa ou encontra-se expirada.<br/>Por favor, aceda à <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\">loja</a> do CentralGest Cloud e ative a licença necessária.",
			"requiredRolesSingle": "Este módulo necessita de acesso ao portal {{portals}}.",
			"requiredRolesMulti": "Este módulo necessita de acesso aos portais {{portals}}."
		}
	},
	"cae": {
		"fields": {
			"codCAE": "Código",
			"nome": "Nome",
			"codSubSec": "Código da subsecção"
		}
	},
	"calcPrVenda": {
		"pesquisa": "Tipos cálculo",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"marginOnSellingPrice": "Margem sobre preço de venda",
			"marginOnPurchasePrice": "Margem sobre preço de compra"
		}
	},
	"calculodiferimentos": {
		"intervalo": "Intervalo",
		"filtrosCalculo": "Filtros de calculo",
		"fields": {
			"valor": "Valor",
			"dataini": "Data inicial",
			"datafim": "Data final",
			"tipodiferimento": "Método",
			"tipoDataRefDiferimento": "Data de cálculo"
		},
		"metodos": {
			"anual": "Anual",
			"mensal": "Mensal"
		},
		"tipoDataRef": {
			"dataDoDocumento": "Data do documento",
			"dataDoLancamento": "Data do lançamento"
		},
		"success": "Cálculo efetuado com sucesso",
		"linhas": {
			"dataDoc": "Data doc.",
			"ano": "Ano civil",
			"numeroDias": "Nº dias",
			"valor": "Valor"
		},
		"btn": {
			"lancacontabilidade": "Aplicar ao documento"
		},
		"messages": {
			"naobalanceado": "O documento não está balanceado. Não é possível lançar diferimentos.",
			"semcontaparadif": "Não foi possível encontrar uma conta com valor a acrescer/diferir. Verifique a configuração de radicais por favor."
		},
		"modals": {
			"config": {
				"title": "Configuração de acréscimos e diferimentos",
				"cardrendimentos": "Rendimentos",
				"cardgastos": "Gastos",
				"fields": {
					"novoRadical": "Novo radical",
					"radicalrendimentos": "Radical",
					"contaAcrescimos": "Conta acréscimos",
					"contaDiferimentos": "Conta diferimentos",
					"radicalgastos": "Radical",
					"contadif": "Conta de diferimentos",
					"contaacresc": "Conta de acréscimos",
					"calculo": "Cálculo",
					"nDiario": "Número de diário",
					"descritivoAnulacaoInicial": "Descritivo p/ anulação do lançamento inicial",
					"descritivo": "Descritivo p/ o(s) valor(es) a diferir",
					"descritivoAcrescimos": "Descritivo p/ o(s) valor(es) a acrescer"
				},
				"messages": {
					"radicalJaExistente": "O radical inserido já existe na lista.",
					"apagarItem": "Apagar linha",
					"desejaApagarItem": "Deseja apagar a linha?",
					"successfullySaved": "Alterações guardadas com sucesso.",
					"campoRadicalVazio": "Campo do radical não pode ser vazio.",
					"nContaDiferimentosEmpty": "A conta de diferimentos não pode ser vazia.",
					"nContaAcrescimosEmpty": "A conta de acréscimos não pode ser vazia.",
					"nDiarioEmpty": "O diário não pode ser vazio.",
					"descritivoEmpty": "Descritivo não pode ser vazio."
				}
			},
			"info": {
				"title": "Informação de acréscimos e diferimentos",
				"infodetalhada": "Informação detalhada",
				"infomes": "Informação por mês",
				"exportDetalhes": "Informação diferimentos detalhada",
				"exportTotais": "Informação diferimentos por mês",
				"fields": {
					"nDocumento": "Nº documento",
					"nConta": "Nº conta",
					"nomeConta": "Nome",
					"valorDebito": "Débito",
					"valorCredito": "Crédito",
					"descricao": "Descrição",
					"valor": "Valor",
					"valorAcrescimoNoMes": "Acréscimo",
					"valorDiferimentoNoMes": "Diferimento",
					"saldoAcrescimoNoMes": "Saldo acréscimo",
					"saldoDiferimentoNoMes": "Saldo diferimento"
				}
			}
		}
	},
	"diferimentosglobal": {
		"diferimentos": "Diferimentos",
		"acrescimos": "Acréscimos",
		"acrescimosDiferimentos": "Acréscimos e Diferimentos",
		"docsselmes": "Documentos no mês selecionado",
		"docscontribuem": "Documentos que contribuem para o saldo no mês selecionado",
		"fields": {
			"nDocumento": "Nº documento",
			"valor": "Valor",
			"descricao": "Descrição"
		},
		"infoAction": "Informação de acréscimos e diferimentos"
	},
	"mapadiferimentosacrescimos": {
		"diferimentos": "Diferimentos",
		"acrescimos": "Acréscimos",
		"diferimento": "Diferimento",
		"acrescimo": "Acréscimo",
		"acrescimosDiferimentos": "Acréscimos e Diferimentos",
		"anon": "Ano {{ano}}",
		"fields": {
			"nDocumento": "Documento",
			"descricaoDoc": "Descrição",
			"nomeConta": "Nome conta",
			"nConta": "Conta",
			"dataInicio": "Data início",
			"dataFim": "Data fim",
			"dias": "Dias",
			"valor": "Valor",
			"valorAno1N": "Ano anterior",
			"valorAnoN": "Total ano",
			"valorAnoN1": "Ano seguinte",
			"nDocExterno": "Doc. Externo",
			"dataDocExterno": "Data doc. externo",
			"nomeDescriti": "Descritivo"
		},
		"tableTitle": "Mapa de {{tipoOperacao}} de {{tipodiferimento}}",
		"tipoOperacao": "Tipo de especialização",
		"tipodiferimento": "Natureza",
		"btn": {
			"infoglobaldiferimentosacrescimos": "Informação global diferimentos/acréscimos"
		}
	},
	"tipoDiferimento": {
		"naoDefinido": "Gastos e Rendimentos",
		"rendimento": "Rendimento",
		"gasto": "Gasto"
	},
	"calendarios": {
		"title_detail": "Calendário {{id}}",
		"title_plural": "Calendários",
		"pesquisa": "Pesquisar",
		"fields": {
			"calenCabID": "Cód.",
			"nome": "Nome",
			"sabadosDiaUtil": "Sábados como dia útil",
			"domingosDiaUtil": "Domingos como dia útil",
			"naoTemFeriados": "Não tem feriados"
		}
	},
	"cargveic": {
		"title_detail": "Veículo",
		"title_new": "Novo Veículo",
		"title_plural": "Veículos",
		"pesquisa": "Pesquisar",
		"saved": "Veículo guardado com sucesso.",
		"error": "Veículo não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Veículo apagado com sucesso.",
		"deleteModalText": "Apagar veículo com matrícula \"{{matricula}}\" ?",
		"reboqueJaExiste": "O reboque com matrícula \"{{matricula}}\" já existe.",
		"reboques": "Reboques",
		"adicionarReboque": "Adicionar Reboque",
		"fields": {
			"matricula": "Matrícula",
			"reboque": "Reboque",
			"desativado": "Desativado",
			"nConta": "Nº cliente",
			"pesoTara": "Tara do veículo",
			"nomeTranspor": "Nome transportador",
			"nomeViatura": "Nome veículo",
			"modelo": "Modelo",
			"motor": "Motor",
			"marca": "Marca",
			"chassi": "Chassi",
			"kilometros": "Kilómetros",
			"matriculaReboque": "Matrícula reboque"
		},
		"btn": {
			"newArtigo": "Novo"
		},
		"list": {
			"actions": {
				"editar": "Editar"
			}
		},
		"messages": {
			"temAlteracoes": "Existem alterações ainda não guardadas.",
			"apagarReboque": "Apagar Reboque"
		}
	},
	"categoriaartigo": {
		"title_detail": "Categoria Artigo {{id}}",
		"title_new": "Novo Categoria Artigo",
		"title_plural": "Categorias Artigo",
		"pesquisa": "Pesquisar",
		"saved": "Categoria Artigo {{id}}, guardado com sucesso",
		"error": "Categoria Artigo {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Categoria Artigo {{id}}, apagado com sucesso",
		"fields": {
			"categoria": "Categoria",
			"descricao": "Descrição",
			"categoriaPlaceholder": "Categoria",
			"descricaoPlaceholder": "Descrição"
		}
	},
	"categoriaProdutos": {
		"pesquisa": "Categoria Produtos",
		"fields": {
			"value": "Código",
			"name": "Descrição"
		},
		"data": {
			"mercadorias": "M - Mercadorias",
			"materiasPrimas": "P - Matérias-primas, subsidiárias e de consumo",
			"produtosAcabados": "A - Produtos acabados e intermédios",
			"subprodutos": "S - Subprodutos, desperdícios e refugos",
			"produtosTrabalhosEmCurso": "T - Produtos e trabalhos em curso",
			"naoAplicaveis": "N/A - Não aplicável",
			"ativosBiologicos": "B - Ativos biológicos"
		}
	},
	"categorias": {
		"title_detail": "Categoria {{id}}",
		"title_new": "Nova Categoria",
		"title_plural": "Categorias",
		"pesquisa": "pesquisa",
		"deleted": "Categoria {{id}}, apagada com sucesso",
		"saved": "Categoria {{id}}, gravada com sucesso",
		"error": "Categoria {{id}}, não gravada. Por favor verifique os campos.",
		"fields": {
			"nCateg": "Nº categoria",
			"designaBreve": "Designação",
			"designaCompl": "Designação completa",
			"codCategoriaMT": "Cód. categoria IRCT / DGEEP",
			"nomeCategoria": "Nome categoria IRCT / DGEEP",
			"codIrct": "Cód. IRCT",
			"nomeIrct": "Nome IRCT"
		}
	},
	"cct": {
		"title_detail": "Contrato Coletivo de Trabalho {{id}}",
		"title_new": "Novo Contrato Coletivo de Trabalho",
		"title_plural": "Contratos Coletivos de Trabalho",
		"pesquisa": "Pesquisar",
		"saved": "Contrato Coletivo de Trabalho {{id}}, guardado com sucesso.",
		"error": "Contrato Coletivo de Trabalho {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Contrato Coletivo de Trabalho {{id}}, apagado com sucesso",
		"fields": {
			"ncct": "Código de CCT",
			"designaBreve": "Descrição breve",
			"nome": "Desig. completa",
			"numBolTrab": "Nº boletim de trabalho",
			"dtBolTrab": "Data Boletim trabalho",
			"mediaTabelas": "Média das tabelas",
			"dtIniValidad": "Data inicio validade",
			"codIRCT": "Cód. IRCT para quadro pessoal",
			"aplicabilidadeIRCT": "Aplicabilidade IRCT"
		},
		"actions": {
			"taboficialirct": "Tabelas oficiais IRCT dos quadros pessoal"
		}
	},
	"ccustos": {
		"title_detail": "Centro de Custo {{id}}",
		"title_new": "Novo Centro de Custo",
		"title_plural": "Centro de Custo",
		"pesquisa": "Pesquisar",
		"saved": "Centro de Custo {{id}}, guardado com sucesso",
		"deleted": "Centro de Custo {{id}}, apagado com sucesso",
		"error": "Centro de Custo {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"nCCusto": "C. Custo",
			"nCCustoPlaceholder": "Código do centro de custo",
			"nome": "Nome",
			"nomePlaceholder": "Nome do centro de custo",
			"encerrado": "Encerrado"
		}
	},
	"cgasi": {
		"title_detail": "Código de Situação da Caixa Geral de Aposentações {{id}}",
		"title_new": "Novo Código de Situação da Caixa Geral de Aposentações",
		"title_plural": "Códigos de Situações da Caixa Geral de Aposentações",
		"pesquisa": "Pesquisar",
		"saved": "Código de Situação da Caixa Geral de Aposentações {{id}}, guardado com sucesso.",
		"error": "Código de Situação da Caixa Geral de Aposentações {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Código de Situação da Caixa Geral de Aposentações {{id}}, eliminado com sucesso.",
		"fields": {
			"codCGASit": "Cód. situação CGA",
			"nomeCGASit": "Descrição",
			"tipoCodCGASit": "Tipo de código",
			"obs": "Observações"
		}
	},
	"changeArtigosFieldsInSerie": {
		"modalTitle": "Alterações de campos em série",
		"strings": {
			"pesquisa": "Pesquisa",
			"atualizacao": "Atualização",
			"relatorio": "Relatório",
			"artigosSelecionados": "Artigos selecionados",
			"artigos": "Artigos",
			"camposAAtualizar": "Campos a modificar",
			"anterior": "Valor anterior",
			"novo": "Novo valor",
			"camposAlterados": "Campos alterados",
			"calculoPreco": "Calculo preço",
			"precoComIva": "Preço c/ IVA",
			"precoSemIva": "Preço s/ IVA"
		},
		"temSelecionarPeloMenosUmArtigo": "Tem que selecionar pelo menos um ativo.",
		"valorCampoNaoPodeSerNulo": "O Valor do campo {{campo}} a modificar não pode ser nulo ou vazio!",
		"naoSelecinouNenhumCampoParaAlterar": "Não selecionou nenhum campo para alterar"
	},
	"classescontratos": {
		"title_detail": "Classe de Contrato {{id}}",
		"title_new": "Nova Classe de Contrato",
		"title_plural": "Classes de Contratos",
		"pesquisa": "Pesquisar",
		"saved": "Classe de Contrato {{id}}, guardada com sucesso",
		"error": "Classe de Contrato {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Classe de Contrato {{id}}, apagada com sucesso",
		"fields": {
			"nClasseCtr": "Código",
			"nClasseCtrPlaceholder": "Código da classe de contrato",
			"nomeClasseCtr": "Nome",
			"nomeClasseCtrPlaceholder": "Nome da classe de contrato",
			"classeTipo": "Classe tipo",
			"classeTipoPlaceholder": "Classe tipo de contrato"
		}
	},
	"classescontratosservicos": {
		"title_detail": "Classe de Avença {{id}}",
		"title_new": "Nova Classe de Avença",
		"title_plural": "Classes de Avenças",
		"pesquisa": "Pesquisar",
		"saved": "Classe de Avença {{id}}, guardada com sucesso",
		"error": "Classe de Avença {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Classe de Avença {{id}}, apagada com sucesso",
		"fields": {
			"nClasseCtr": "Código",
			"nClasseCtrPlaceholder": "Código da classe de avença, se não for preenchido será gerado automaticamente.",
			"nomeClasseCtr": "Nome",
			"nomeClasseCtrPlaceholder": "Nome da classe de avença"
		}
	},
	"classescaixa": {
		"title_detail": "Classe de Caixa {{id}}",
		"title_new": "Nova Classe de Caixa",
		"title_plural": "Classes de Caixa",
		"pesquisa": "Pesquisar",
		"saved": "Classe de Caixa {{id}}, guardada com sucesso",
		"error": "Classe de Caixa {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Classe de Caixa {{id}}, apagada com sucesso",
		"fields": {
			"nClasseCaixa": "Código classe de caixa",
			"designacaoBreve": "Designação breve",
			"percentPatr": "Percentagem patronal",
			"percentEmpr": "Percentagem empregado",
			"naoProcSubNatal": "Não processa subs. natal",
			"naoProcSubFerias": "Não processa subs. férias",
			"ncrss": "Nº centro RSS",
			"tipoRegime": "Tipo regime",
			"descricao": "Descrição",
			"taxas": "Taxas",
			"config": "Configurações",
			"naoVaiSegSocial": "Não envia dados para a seg. social",
			"percentPATR": "Patronal",
			"percentEMPR": "Empregado",
			"percenttitle": "% de contribuições",
			"codIntegracaotitle": "Integração na contabilidade",
			"limites": "Limites de contribuições mensais em nº de salários mínimos",
			"codIntegracao": "Cód. integração",
			"temLimiteMaximo": "Tem limite máximo",
			"limMaxNSalariosMin": "Nº sal. max.",
			"temLimiteMinimo": "Tem limite mínimo",
			"limMinNSalariosMin": "Nº sal. min."
		}
	},
	"tipoRegime": {
		"empregados": "Empregados",
		"gerenteMOE": "Gerentes/MOE",
		"primeiroEmprego": "1º Emprego",
		"pensionistas": "Pensionistas",
		"deficientes": "Deficientes",
		"trabEntSFimLuc": "Trab. ent. s/ fim luc.",
		"trabOutros": "Outros trab.",
		"praticantesDesportivos": "Praticantes desportivos profissionais",
		"servicoDomesticoConvencional": "Serviço doméstico - Remuneração convencional",
		"servicoDomesticoReal": "Serviço doméstico - Remuneração real"
	},
	"clicls": {
		"title_detail": "Classe de cliente / fornecedor {{id}}",
		"title_new": "Nova Classe de cliente / fornecedor",
		"title_plural": "Classes de cliente / fornecedor",
		"pesquisa": "Pesquisar",
		"saved": "Classe de cliente / fornecedor {{id}}, guardada com sucesso.",
		"error": "Classe de cliente / fornecedor {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Classe de cliente / fornecedor {{id}}, apagada com sucesso.",
		"fields": {
			"codigo": "Código",
			"descricao": "Descrição"
		}
	},
	"clifoExtraValueTipos": {
		"fields": {
			"tipo": "Tipo",
			"descricao": "Descrição",
			"natureza": "Natureza",
			"codigo": "Código"
		}
	},
	"clifoExtraValues": {
		"save": "Guardado com sucesso!",
		"errorEmailVazio": "O e-mail não pode ser nulo.",
		"errorEmailJaExiste": "O e-mail já existe.",
		"errorEmailInvalid": "O e-mail têm que ser válido.",
		"modal": {
			"title": "Adicionar e-mail",
			"editTitle": "Editar e-mails ({{title}})",
			"table": {
				"fields": {
					"email": "E-mail"
				}
			}
		},
		"modalConfirmDelete": {
			"title": "Atenção!",
			"message": "Tem a certeza que pretende apagar este registo?"
		},
		"fields": {
			"valor": "E-mail",
			"codigo": "Tipo"
		},
		"table": {
			"descricao": "Tipo e-mail",
			"valor": "Valor"
		},
		"btn": {
			"adicionar": "Adicionar",
			"remover": "Remover",
			"addEmail": "Adicionar e-mail",
			"new": "Novo"
		},
		"tooltips": {
			"addNewTypeEmail": "Adicionar tipo e-mail",
			"editTypeEmail": "Editar tipo e-mail",
			"removeTypeEmail": "Remover tipo e-mail"
		}
	},
	"clifos": {
		"pesquisa": "Pesquisar",
		"tab": {
			"ficha": "Ficha",
			"dadosconta": "Dados da conta",
			"extratos": "Extratos",
			"artigos": "Artigos",
			"anexos": "Anexos",
			"encomendas": "Encomendas"
		},
		"title_detail": "Cliente / Fornecedor {{id}}",
		"title_new": "Novo cliente / fornecedor",
		"title_plural": "Clientes / Fornecedores",
		"saved": "Cliente / Fornecedor {{id}}, guardado com sucesso",
		"error": "Cliente / Fornecedor {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Terceiro {{id}}, apagado com sucesso",
		"navigation": {
			"tooltipFirst": "Primeiro",
			"tooltipPrevious": "Anterior",
			"tooltipNext": "Seguinte",
			"tooltipLast": "Último"
		},
		"groups": {
			"indentificacao": "Identificação",
			"estadoAtVies": "Estado no VIES",
			"dadosprincipais": "Dados principais",
			"dadosfinan": "Dados financeiros",
			"condECaract": "Características",
			"classificadores": "Classificadores",
			"moradasAlt": "Moradas alternativas",
			"tiposEmail": "Tipos de email"
		},
		"fields": {
			"nConta": "Nº conta",
			"nContaPlaceholder": "Número de conta",
			"nIdAltern": "Id. altern.",
			"nIdAlternPlaceholder": "Nº de identificação alternativa",
			"nome": "Nome",
			"nomePlaceholder": "Nome",
			"nContribuint": "Nº contribuinte",
			"nContribuintPlaceholder": "Número de contribuinte",
			"rua": "Rua",
			"ruaPlaceholder": "Rua",
			"codPostal": "Cód. postal",
			"codPostalPlaceholder": "Código postal",
			"localidade": "Localidade",
			"localidadePlaceholder": "Localidade",
			"telefone": "Telefone",
			"telefonePlaceholder": "Telefone",
			"fax": "Fax",
			"faxPlaceholder": "Fax",
			"nomePais": "País",
			"nomePaisPlaceholder": "País",
			"email": "E-mail",
			"emailPlaceholder": "E-mail",
			"codRet": "Cód. retenção",
			"codRetPlaceholder": "Código da taxa retenção",
			"situacaoActual": "Situação atual",
			"desactivado": "Desativado",
			"naoPodeFacturar": "Não pode faturar",
			"codCondComerciais": "Cond. comercial",
			"codCondComerciaisPlaceholder": "Código da condição comercial",
			"codPagamento": "Cond. pagamento",
			"codPagamentoPlaceholder": "Código da condição de pagamento",
			"observacoes": "Observações",
			"observacoes2": "Observações (Cont.)",
			"idSubGrContaCorrente": "Natureza conta",
			"idSubGrContaCorrentePlaceholder": "Registo selecionado gera nº conta segundo a natureza",
			"iban": "IBAN",
			"ibanPlaceholder": "IBAN",
			"swift": "SWIFT",
			"swiftPlaceholder": "SWIFT",
			"nomeMoeda": "Cód. moeda faturação",
			"nomeMoedaPlaceholder": "Código moeda faturação",
			"facPublicaModelo": "Modelo de EDI / Faturação eletrónica pública",
			"idideChav": "Grupo de conta",
			"idideChavPlacholder": "Grupo de conta",
			"nTxtCabeca": "Cabeçalho de aviso de cobrança",
			"nTxtCabecaPlacholder": "Cabeçalho de aviso de cobrança",
			"nTxtPE": "Rodapé de aviso de cobrança",
			"nTxtPEPlacholder": "Rodapé de aviso de cobrança",
			"codZona": "Zona",
			"codVendedor": "Vendedor",
			"ruaCont": "Rua (Continuação)",
			"telemovel": "Telemóvel",
			"telemovelPlaceholder": "Telemóvel",
			"wwwSite": "Página WEB",
			"wwwSitePlaceholder": "Página WEB",
			"utilizaIVAFichaClifo": "Aplica taxa de IVA da ficha",
			"codIva": "Taxa de IVA",
			"obrigaNumRequisicao": "Obriga \"Requisição\"",
			"classeClifo": "Classe",
			"situacaoComercial": "Situação comercial",
			"nifSituacaoAtDescricao": "AT",
			"nifSituacaoViesDescricao": "VIES",
			"autoFaturacao": "Autofaturação",
			"textoFaturacao": "Notas de faturação",
			"sector": "Setor"
		},
		"errors": {
			"contaNaoExiste": "A conta não existe",
			"nifInvalido": "NIF inválido!"
		},
		"avisodadosrepetidosmodal": {
			"title": "Cliente / Fornecedor com dados repetidos",
			"bodyMessage": "Já existe cliente(s)/fornecedor(es) com o mesmo nº de contribuinte ou nome. Deseja continuar e adicionar?"
		},
		"anexos": "Anexos",
		"selectNavTipoEmail": "Ir para tipos de email",
		"text": {
			"iban": "Obrigatório para pagamentos SEPA a fornecedores",
			"modeloFatPublic": "O comportamento na faturação altera consoante o modelo utilizado: Manual - CIUS-PT / Webservice - Saphety e FEAP",
			"aplicaTaxaIva": "Ignora a taxa de IVA do artigo.",
			"codRetencao": "Informação adicional na faturação",
			"codMoeda": "Informação adicional na faturação. Atenção ao câmbio.",
			"textoFaturacao": "Texto vívisel na emissão de documentos. Máx. 250 chars"
		},
		"btn": {
			"validaNif": "Validar NIF no VIES",
			"btnNewMoral": "Novo"
		},
		"nifInvalidoModal": {
			"title": "NIF inválido",
			"bodyMessage": "O NIF {{nContribuint}} não é Valido.<br/>Pretende mesmo assim registar o NIF como Inválido/Inexistente?"
		}
	},
	"clientes": {
		"pesquisa": "Pesquisar",
		"tab": "Ficha",
		"title_detail": "Cliente {{id}}",
		"title_new": "Novo Cliente",
		"title_plural": "Clientes",
		"title_singular": "Cliente",
		"saved": "Cliente {{id}}, guardado com sucesso",
		"error": "Cliente {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Cliente {{id}}, apagado com sucesso",
		"showZona": "Mostrar campo zona",
		"showDesativados": "Mostrar apenas desativados",
		"showVendedor": "Mostrar campo vendedor",
		"showSetor": "Mostra o campo setor"
	},
	"fornecedores": {
		"pesquisa": "Pesquisar",
		"tab": "Ficha",
		"title_detail": "Fornecedor {{id}}",
		"title_new": "Novo Fornecedor",
		"title_plural": "Fornecedores",
		"title_singular": "Fornecedor",
		"saved": "Fornecedor {{id}}, guardado com sucesso",
		"error": "Fornecedor {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Fornecedor {{id}}, apagado com sucesso",
		"showDesativados": "Mostrar apenas desativados"
	},
	"outrosdevedorescredores": {
		"pesquisa": "Pesquisar",
		"title_detail": "Outro Devedor / Credor {{id}}",
		"title_new": "Novo Devedor / Credor",
		"title_plural": "Devedores / credores",
		"title_singular": "Devedor / credor",
		"saved": "Devedor / Credor {{id}}, guardado com sucesso",
		"error": "Devedor / Credor {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Devedor / Credor {{id}}, apagado com sucesso"
	},
	"modal": {
		"txtFaturacao": {
			"title": "Notas de faturação"
		}
	},
	"cltrb": {
		"title_detail": "Classe de Trabalho {{id}}",
		"title_new": "Nova Classe de Trabalho",
		"title_plural": "Classes de Trabalho",
		"pesquisa": "Pesquisar",
		"saved": "Classe de Trabalho {{id}}, guardada com sucesso.",
		"error": "Classe de Trabalho {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Classe de Trabalho {{id}}, apagada com sucesso.",
		"fields": {
			"nclasTrab": "Código de classe",
			"designaBreve": "Designação breve",
			"designaCompl": "Designação completa"
		}
	},
	"codigoscontabilisticos": {
		"title_detail": "Código contabilístico {{id}}",
		"title_new": "Novo código contabilístico",
		"title_plural": "Códigos contabilísticos",
		"pesquisa": "Pesquisar",
		"saved": "Código contabilístico {{id}}, guardado com sucesso.",
		"error": "Código contabilístico {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Código contabilístico {{id}}, apagado com sucesso.",
		"fields": {
			"codcontab": "Código",
			"nome": "Nome "
		},
		"placeholders": {
			"codcontab": "Código contabilístico",
			"nome": "Nome do código contabilístico"
		}
	},
	"codpostais": {
		"title_detail": "Cód. Postal {{id}}",
		"title_new": "Novo Cód. Postal",
		"title_plural": "Códigos postais",
		"pesquisa": "Pesquisar",
		"saved": "Cód. Postal {{id}}, guardado com sucesso",
		"error": "Cód. Postal {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Cód. Postal {{id}}, eliminado com sucesso",
		"fields": {
			"codPostal": "Cód. postal",
			"codPostalPlaceholder": "Código postal",
			"localidade": "Localidade",
			"localidadePlaceholder": "Localidade do código postal",
			"codPais": "Cód. país",
			"nomePais": "País"
		}
	},
	"colaboradores": {
		"pesquisa": "Pesquisar",
		"title": "Colaboradores",
		"sentSuccess": "Enviado com sucesso",
		"uploadSuccess": "Ficheiro carregado com sucesso",
		"uploadError": "Ocorreu um erro ao tentar carregar o ficheiro",
		"comunicacoesinternas": {
			"titlenew": "Nova comunicação interna",
			"title": "Comunicação interna",
			"fields": {
				"tipoComunicacao": "Tipo de comunicação",
				"titulo": "Titulo",
				"texto": "Texto"
			},
			"you": "Você",
			"confirmDeleteMessage": "Tem a certeza que pretende eliminar a comunicação?",
			"anexo": "Anexo"
		},
		"docsplata": {
			"title": "Docs. plataforma",
			"text": {
				"servicos": "Serviços",
				"servico": "Serviço",
				"papeis": "Papeis",
				"papel": "Papel"
			},
			"modal": {
				"title": "Novo documento da plataforma RH",
				"titulo": "Título",
				"dataValidade": "Data validade",
				"errorFiles": "Deve carregar pelo menos um ficheiro.",
				"errorServicosSelected": "Deve escolher pelo menos um serviço ao qual o documento ficará associado.",
				"errorPapeisSelected": "Deve escolher pelo menos um papel ao qual o documento ficará associado."
			}
		},
		"board": {
			"fields": {
				"servico": "Serviço",
				"dadosColaborador": "Dados colaborador",
				"colaborador": "Colaborador",
				"saldoFerias": "Saldo férias",
				"feriasGozadas": "Férias gozadas",
				"faltas": "Faltas",
				"horasSemanais": "Horas semanais",
				"localTrabalho": "Local de trabalho",
				"tipoHorario": "Tipo de horário",
				"tipoContrato": "Tipo de contrato",
				"validadeContrato": "Validade contrato",
				"centroCusto": "Centro de custo",
				"reparticao": "Repartição",
				"ferias": "Férias",
				"abonos": "Abonos"
			},
			"msg": {
				"nenhumColabSelected": "Nenhum colaborador selecionado"
			}
		}
	},
	"comcategorias": {
		"title_detail": "Categoria de comunicação",
		"title_new": "Nova Categoria de comunicação",
		"title_plural": "Categorias de comunicações",
		"pesquisa": "Pesquisar",
		"saved": "Categoria de Comunicação, guardada com sucesso.",
		"error": "Categoria de Comunicação, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Categoria de Comunicação, apagada com sucesso.",
		"categoriaSistema": "Categorias de sistema não podem ser editadas",
		"fields": {
			"nome": "Categoria",
			"nEmpresa": "Nº Empresa",
			"tipo": "Tipo",
			"cor": "Cor",
			"todasEmpresas": "Disponível para todas as empresas"
		},
		"tipoComCategorias": {
			"data": {
				"none": "Não definido",
				"sistema": "Sistema",
				"utilizador": "Utilizador"
			}
		}
	},
	"companystatus": {
		"header": "A empresa \"<em>{{nEmpresa}} - {{nomeEmpresa}}\"</em> está inacessível devido ao agendamento ou execução da seguinte tarefa",
		"status": "Estado",
		"tasks": {
			"ECGOnFimAno": {
				"title": "Fim de ano",
				"state": {
					"fasInactive": "Inativo",
					"fasScheduled": "Agendado",
					"fasStarted": "A executar...",
					"fasEnded": "Terminado"
				}
			}
		},
		"text": {
			"back": "Voltar",
			"description": "Tarefa solicitada em <em>\"{{requested}}\"</em> pelo utilizador <em>\"{{user}}\"</em> para a empresa <em>\"{{company}}\"</em>.",
			"scheduled": "Agendada para iniciar em <em>\"{{scheduled}}\"</em>."
		},
		"cancel": {
			"title": "Cancelar agendamento",
			"message": "Tem a certeza que deseja cancelar o agendamento da tarefa \"{{taskName}}\"?"
		}
	},
	"components": {
		"usernav": {
			"name": "Olá, {{name}}!",
			"store": "Gerir os seus planos e empresas",
			"showRecentCompanies": "Mostrar empresas recentes",
			"hideRecentCompanies": "Ocultar empresas recentes",
			"theme": "Tema"
		},
		"cgmodal": {
			"choosecountry": {
				"title": "Selecionar país",
				"btn": "Copiar template",
				"codigo": "Cód.",
				"designacaoPT": "Designação (PT)",
				"codigoAlpha2": "ISO3166 Alfa-2",
				"codigoAlpha3": "ISO3166 Alfa-3",
				"designacao": "Nome própria língua"
			}
		},
		"filter": {
			"advancedFilter": "Filtro avançado"
		},
		"multiselect": {
			"available": "Disponíveis",
			"selected": "Selecionados"
		},
		"devextreme": {
			"datagrid": {
				"preferences": "Configurações",
				"statestoring": {
					"clear": "Limpar configurações"
				}
			}
		},
		"contabilidade": {
			"listagens": {
				"account": "Conta",
				"fromAccount": "Conta de",
				"fromContrib": "Do contribuinte",
				"fromPeriod": "Período de",
				"fromdiary": "Diário de",
				"toPeriod": "Período até"
			},
			"balancetes": {
				"accountTypes": "Tipos de conta",
				"yearsToCompare": "Anos a comparar",
				"hideAccountsZeroBalance": "Esconde contas com saldo zero",
				"calculaSaldosIniciaisAnoNMaisUm": "Calcular saldos iniciais de {{nextYear}} com ano {{year}} em aberto"
			},
			"extratosgrid": {
				"contaInvalida": "Campo conta inválido.",
				"linhas": {
					"nConta": "Conta",
					"nomeConta": "Nome conta",
					"nDocExterno": "Doc. externo",
					"dataVenc": "Data venc.",
					"descricao": "Descrição",
					"valorDebito": "Valor débito",
					"valorCredito": "Valor crédito",
					"porPagar": "Por pagar",
					"dataDoc": "Data doc.",
					"moeda": "Moeda",
					"nCCusto": "NCCusto",
					"valorDebitoSegMoeda": "Valor débito seg. moeda",
					"valorCreditoSegMoeda": "Valor crédito seg. moeda",
					"cambioSegMoeda": "Cambio seg. moeda",
					"descritivo": "Descritivo",
					"perioDiarioDoc": "Nº documento",
					"temDocDigital": "Doc. digital",
					"stampInsert": "Data inserção",
					"periodo": "Período",
					"diario": "Diário",
					"ndocInterno": "Doc. interno"
				},
				"options": {
					"showDiarios": "Mostra diários"
				},
				"btn": {
					"processPdftooltip": "Necessita de dados para processar o pdf",
					"sendMail": "Enviar e-mail"
				}
			}
		},
		"keybindingshelper": {
			"header": "Teclas de atalho"
		},
		"configOptions": {
			"title": "Opções",
			"revertToDefault": "Reverter para as opções padrão"
		},
		"genericImporter": {
			"title": "Importação de {{type}}",
			"steps": {
				"downloadModel": {
					"title": "Descarregar modelo",
					"content": {
						"1": "Bem-vindo ao assistente de importação de {{type}}. Este assistente vai guiá-lo no processo de importação.",
						"2": "Por favor carregue no botão abaixo para descarregar o modelo de importação de {{type}}.",
						"3": "Após descarregar o ficheiro, preencha o mesmo com os dados que deseja importar.",
						"4": "Atenção: É necessário ter um software de edição de folhas de cálculo. É recomendado que utilize o Microsoft Excel."
					}
				},
				"uploadModel": {
					"title": "Carregar modelo preenchido",
					"content": {
						"1": "Preencha o ficheiro com os dados que deseja importar.",
						"2": "Após preencher o ficheiro, faça o carregamento do ficheiro para continuar a importação."
					}
				},
				"analyzeErrors": {
					"title": "Analisar erros",
					"content": {
						"2": "Para analisar o ficheiro com erros descarregue o mesmo carregando no botão abaixo.",
						"1.1": "Ocorreu pelo menos um erro",
						"1.2": "durante a importação de {{type}}."
					}
				},
				"success": {
					"title": "Finalizar",
					"content": {
						"1": "Importação de {{type}} terminada com sucesso.",
						"2": "Clique em \"Finalizar\" para terminar o processo de importação de {{type}}."
					}
				}
			},
			"types": {
				"0": "artigos",
				"1": "clientes",
				"2": "fornecedores",
				"3": "outros devedores/credores",
				"4": "colaboradores",
				"5": "contas corrente de clientes",
				"6": "contas corrente de fornecedores",
				"7": "matriz de conversão"
			},
			"actions": {
				"downloadModel": "Descarregar modelo de importação",
				"downloadErrors": "Descarregar ficheiro com os erros da importação",
				"retry": "Tentar novamente",
				"import": "Importar"
			},
			"errors": {
				"requiredUploadModel": "Para continuar a importação tem que carregar o ficheiro com os dados que deseja importar."
			}
		},
		"scheduler": {
			"text": {
				"colaborador": "Colaborador"
			}
		},
		"refeicoesscheduler": {
			"header": {
				"previous": "Anterior",
				"today": "Hoje",
				"next": "Próximo"
			}
		},
		"panelmultipleselectionexecute": {
			"labels": {
				"selectedDocs": "Documentos selecionados"
			},
			"actions": {
				"lancarDocs": "Lançar documentos",
				"lancarDoc": "Lançar documento"
			}
		},
		"userSatisfaction": {
			"suggestions": "Feedback e Sugestões",
			"feedback": "Dê-nos a sua opinião"
		}
	},
	"concelhos": {
		"title_detail": "Concelho {{id}}",
		"title_new": "Novo Concelho",
		"title_plural": "Concelhos",
		"pesquisa": "Pesquisar",
		"saved": "Concelho {{id}}, guardado com sucesso",
		"error": "Concelho {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Concelho {{id}}, apagado com sucesso",
		"fields": {
			"codConcelho": "Código do concelho",
			"nomeConcelho": "Nome do concelho",
			"codDistrito": "Código do distrito",
			"nomeDistrito": "Distrito",
			"taxaDerrama": "Taxa derrama",
			"codConcelhoAT": "Concelho AT"
		},
		"cardderrama": "Cálculo da taxa de derrama",
		"modal": {
			"title": "Cálculo da taxa de derrama",
			"editardados": "Editar dados",
			"fields": {
				"tipoDadosSTR": "Tipo",
				"valor": "Valor",
				"condicaoCumprida": "Ok",
				"tipoTaxaSTR": "Tipo taxa",
				"taxa": "Taxa",
				"ambito": "Âmbito"
			}
		}
	},
	"conciliacaoBancaria": {
		"mostrarLinhasConciliadasLabel": "Mostrar linhas conciliadas",
		"addDocNaoConciliadosTitle": "Adicionar documento(s) não conciliado(s) de periodos anteriores",
		"addExtradoBancario": "Adicionar",
		"addLinhaExtradoBancario": "Adicionar lançamento na contabilidade e no banco",
		"addLancPrimeiraConciliacaoExtradoBancario": "Adicionar lançamento de primeira conciliação do banco",
		"addExtradoBancarioBtn": "Adicionar",
		"novaConciliacao": "Nova conciliação",
		"printConciliacao": "Imprimir conciliação",
		"print": "Imprimir",
		"editarConciliacao": "Editar conciliação",
		"refreshConciliacao": "Atualizar conciliação",
		"eliminaConciliacao": "Eliminar",
		"terminarConcilidacao": "Terminar",
		"terminadaConcilidacao": "Concilidação terminada.",
		"terminarConcilidacaoModalMsg": "Tem a certeza que pretende terminar a concilidação?",
		"eliminaConciliacaoModalMsg": "Tem a certeza que pretende eliminar a concilidação \"{{nome}}\"?",
		"eliminaTodasConciliacoesModalMsg": "Tem a certeza que pretende eliminar todas as concilidação da conta \"{{conta}}\"?",
		"selectConciliacaoMessage": "Selecione o banco que pretende conciliar ou crie uma nova conciliação bancária.",
		"seraLancadaNaConta": "A nova linha será lançada na conta",
		"seraCriadaNovaConta": "Vai ser criada nova conta",
		"searchByValue": "Pesquisa por valor...",
		"addDocsNaoConciliadosPeriodoAnt": "Adicionar documento(s) não conciliados de periodos anteriores",
		"addDocsNaoConciliadosPeriodoAntBtn": "Adicionar documento(s)",
		"naoExistemDocs": "Não existem documento(s) não conciliado(s) de periodos anteriores",
		"totalSelecionado": "Total selecionado",
		"hideTreeText": "Esconder árvore",
		"showTreeText": "Mostrar árvore",
		"removeTodasLinhasExtratoBancoTitle": "Apagar",
		"removeTodasLinhasExtratoBancoMsg": "Tem a certeza que pretende eliminar todas as linhas?",
		"extratoContabilidadeTitle": "Extrato da contabilidade",
		"extratoBancoTitle": "Extrato do banco",
		"viewDocTooltip": "Ver lançamento",
		"viewDocModalTitle": "Visualizar documento",
		"associarExtratoTooltip": "Associar extrato",
		"associarLinhasDosExtratosEmAmbosLadosTooltip": "Associa as linhas dos extratos seleciondas em ambos os lados",
		"removeLinhaExtratoBancoTooltip": "Remover linha",
		"eliminarTodasConciliacoesBanco": "Eliminar todas as conciliações do banco",
		"lancamentoContab": "Lançamento na contabilidade",
		"printModalTitle": "Imprimir",
		"deNConta": "Nº conta de",
		"dePeriodo": "Período de",
		"applyFilters": "Aplicar filtros",
		"saldoExtratoBancarioPerspetivaBancoTitle": "Saldo do extrato bancário na perspetiva do banco",
		"periodoNaoEValidoDeveIndicarOPeriodpSeg": "O periodo não é valido, deve indicar o perído seguinte ao da ultima conciliação",
		"legendaLabel": "Legenda",
		"adicionarLinhaPrimeiraConcilExtratoCG": "Adicionar linha de primeira conciliação ao extrato da contabilidade",
		"adicionarDocsPrimeiraConcilExtratoCG": "Adicionar documentos de primeira conciliação",
		"importaDocsPendentesConcilAntiga": "Importa documentos pendentes na contabilidade da conciliação antiga",
		"extratoContaPendentes": "Extrato de conta de pendentes",
		"importaDocsPendentesBancoConcilAntiga": "Importa documentos pendentes no banco da conciliação antiga",
		"importarExtratoBancario": "Importar extrato bancário",
		"adicionarLancamentoBanco": "Adicionar lançamento no banco",
		"conciliarAutomaticamente": "Conciliar automaticamente",
		"addLinhaPrimeiraConcilExtratoCGModalTitle": "Adicionar linha de primeira conciliação à conta da Contabilidade",
		"editLinhaPrimeiraConcilExtratoCGModalTitle": "Editar linha de primeira conciliação à conta da contabilidade",
		"lancNaContaContabilidadeNaPerspetivaEmpresaTitle": "Lançamento na conta da contabilidade na perspetiva da empresa",
		"tipoMovimentoNaPerspectivaEmpresaTitle": "Tipo de movimento na perspectiva da empresa",
		"oValorMovimentoNaoPodeSer0ouInferior": "O valor do movimento não pode ser 0 ou inferior.",
		"editLanc": "Editar lançamento",
		"removeLinha": "Remover linha",
		"desassociarLinha": "Desassociar linha",
		"editLinhaExtradoBancarioModalTitle": "Editar linha do extrato bancário",
		"extPocItemNotFound": "O lançamento da contabilidade não foi encontrado",
		"adicionarDocsExtratoBancarioAContabilidadeModalTitle": "Adicionar documentos do extrato bancário à contabilidade",
		"maisInfoSobreSaldos": "Clique para ver mais detalhes sobre saldos",
		"viewSaldoDetalhesModalTitle": "Detalhes dos saldos da conciliação",
		"saldoContabilidade": "Saldos da contabilidade",
		"saldoBanco": "Saldos do banco",
		"associarTodoExtratoCGAoExtradoDoBanco": "As linhas que não estão conciliadas no extrado da empresa serão criadas no extrato do banco e automaticamente conciliadas",
		"removerTodasLinhasExtrato": "Remover todas as linhas do extrato bancário",
		"temQueSelecionarLinhas": "Tem que selecionar linhas para efetuar a operação.",
		"sAntesDeProsseguirDeveVerificarSe": "Antes de prosseguir deve verificar se a ultima conciliação (Modulo Antigo) está correta. Deseja continuar?",
		"s01Contrapartida2": "{{radical}}{{nconta}} - Pendentes sem classf. - {{nomeConta}}",
		"adicionarLinhaExtratoBancario": "Adicionar linha ao extrato bancário",
		"adicionarDocsDoExtratoBancarioAContabilidade": "Adicionar documentos do extrato bancário à contabilidade",
		"saldosNaPerspetivaDoBanco": "Saldos na perspetiva do banco",
		"import": "Importar extrato",
		"noFiles": "Selecione o ficheiro para processar...",
		"importTab": "Importador",
		"importResultsTab": "Resultados",
		"osSaldosDoExtratoBancarioImporta": "Os saldos do extrato bancário importado não coincidem com os saldos inidicados na conciliação.",
		"adicionarAContabilidade": "Adicionar à contabilidade",
		"ordenarPorDataDocExt": "Ordenar por data doc. ext.",
		"ordenarPorValor": "Ordenar por valor",
		"ordenarPorData": "Ordenar por data",
		"ordenarPorDescricao": "Ordenar por descrição",
		"ordenarPorNDoc": "Ordenar por nº doc",
		"sMsgOQueFazercomVariaslinhas": "Clique em Sim para conciliar as linhas selecionadas e mostrar apenas uma linha de somatório no lado do banco. Clique em Não para conciliar cada uma das linhas separadamente.",
		"conciliadoLabel": "Conciliado",
		"configImportModalTitle": "Configuração de Template de importação de extrato",
		"configDebitoCreditoNaPerspectivaBancoTitle": "Configuração de débito e crédito na perspectiva do banco",
		"comoEObtidoValorMovimentoNoExtrato": "Como é obtido o valor do movimento no extrato",
		"configImportTooltip": "Configurar importação",
		"estaNaPropriaColunaLabel": "Está na própria coluna",
		"estaEmColunasSeparadasLabel": "Está em colunas separadas",
		"estEmUmaColunaENoutraColunaLabel": "Está em uma coluna e noutra coluna a ident. débito/crédito",
		"removeTemplatePromptMsg": "Tem a certeza que pretende eliminar o modelo?",
		"templateSavedMsg": "O modelo foi guardado com sucesso",
		"templateRemovedMsg": "O modelo foi removido com sucesso",
		"outrosanos": "Outros anos",
		"contaPendentesBanco": "Conta de pendentes do banco",
		"configContaPendentesBanco": "Configurar conta de pendentes do banco",
		"radicalContaGuardadoComSucesso": "O radical da conta foi guardado com sucesso",
		"fields": {
			"nconta": "Conta",
			"periodo": "Período",
			"dataInicio": "Data início",
			"dataFim": "Data fim",
			"saldoInicialBanco": "Saldo inicial no banco",
			"saldoFinalBanco": "Saldo final no banco",
			"saldoInicial": "Saldo inicial",
			"saldoFinal": "Saldo final",
			"saldoFinalCalc": "Saldo final calculado",
			"dc": "DC",
			"valor": "Valor",
			"descricao": "Descrição",
			"conciliado": "Conciliado",
			"tipo": "Tipo",
			"radicalConta": "Radical conta",
			"contaBanco": "Conta banco",
			"diario": "Diário",
			"descritivo": "Descritivo",
			"tipoMovimento": "Tipo de movimento na perspectiva do banco",
			"ndoc": "Nº doc",
			"nDocExterno": "Nº doc ext.",
			"dataLacam": "Data lanc.",
			"dataDocExt": "Data doc. ext.",
			"nSeq": "Seq.",
			"stampUpdate": "Hora concil.",
			"diferencaConciliacao": "Diferença da conciliação",
			"valorSemDC": "Valor sem sinal",
			"paramImport": "Parâmetros de importação",
			"message": "Mensagem",
			"template": "Modelo",
			"numPrimeiraLinhaDados": "Nº da 1º linha de dados",
			"colunaDataMovimento": "Coluna data movimento",
			"colunaDataValor": "Coluna data valor",
			"colunaDescrição": "Coluna descrição",
			"colunaSaldo": "Coluna saldo",
			"colunaValorDoMovimento": "Coluna valor do movimento",
			"seValorNegativoInverteSinalELancaA": "Se valor negativo inverte sinal e lança a",
			"seValorPositivoNaoInverteSinalELancaA": "Se valor positivo não inverte sinal e lança a",
			"colunaValorADebito": "Coluna valor a débito",
			"colunaValorACredito": "Coluna valor a crédito",
			"inverteSinalDoValorADebito": "Inverte sinal do valor a débito",
			"colunaDoTipoDeMovimento": "Coluna do tipo de movimento",
			"caraterParaDebito": "Carater para débito",
			"caraterParaCredito": "Carater para crédito"
		},
		"config": {
			"showColumnDescricao": "Mostrar coluna \"Descrição\" na grelha do extrato da contabilidade",
			"showColumnNDocExt": "Mostrar coluna \"Nº doc. externo\" na grelha do extrato da contabilidade",
			"showColumnTipo": "Mostrar coluna \"Tipo\" na grelha do extrato do banco",
			"showColumnSaldo": "Mostrar coluna \"Saldo\" na grelha do extrato do banco",
			"showColumnStampUpdate": "Mostrar coluna \"Hora conciliação\" na grelha do extrato do banco",
			"showColumnDataLanc": "Mostrar coluna \"Data lançamento\" na grelha do extrato da contabilidade",
			"showColumnData": "Mostrar coluna \"Data\" na grelha do extrato da contabilidade",
			"showColumnValorSemDC": "Mostrar coluna \"Valor sem sinal\" na grelha do extrato da contabilidade",
			"showColumnBancoValorSemDC": "Mostrar coluna \"Valor sem sinal\" na grelha do extrato do banco",
			"showColumnDC": "Mostrar coluna \"DC\" na grelha do extrato da contabilidade",
			"showColumnBancoDC": "Mostrar coluna \"DC\" na grelha do extrato do banco"
		},
		"btns": {
			"toolbarConciliacaoBtn": "Ações de conciliação",
			"selecionados": "Selecionados...",
			"toolbarShortcuts": "Atalhos",
			"toolbarBancoPoc": "Ver plano de conta do banco",
			"toolbarPesqDocs": "Pesquisa de documentos"
		},
		"messages": {
			"deleteSelectedLine": "Deseja eliminar a linha selecionada?"
		},
		"tabMainTitle": "Conciliação",
		"tabAttachmentsTitle": "Anexos",
		"fromTo": "{{from}} até {{to}}"
	},
	"conciliacaoEFaturas": {
		"tabDocsEFaturaCaption": "Documentos no e-Fatura sem relação com a contabilidade",
		"tabJaLancadosCaption": "Documentos já lançados na contabilidade ou ignorados",
		"viewDocModalTitle": "Visualização do documento {{ndoc}}",
		"viewDocModalTitleSimple": "Visualização do documento",
		"extratosModalTitle": "Extratos de conta",
		"pesquisaDocPossivelModalTitle": "Pesquisa de documentos de contabilidade",
		"expandAllBtn": "Expandir todos",
		"associarTodosPossiveis": "Associar documentos com 1 documento possível",
		"associarTodosPossiveisPromptMsg": "Esta operação irá associar automaticamente os documentos e-Fatura com um documento possível.<br>Deve sempre verificar documento a documento se o documento possível associado corresponde ao documento do e-Fatura.<br><br>Tem a certeza que pretende continuar?",
		"associarTodosPossiveisNoSourceTitle": "Associação automática...",
		"associarTodosPossiveisNoSourceMsg": "Não tem documentos ou tem documentos com mais que 1 documento possível.",
		"associarDocSemAlterarDocExterno": "Associar documento sem alterar documento externo",
		"associarDocComAlteracaoDocExterno": "Associar documento com alteração documento externo",
		"verDocumento": "Ver documento",
		"deixarIgnorarDocumento": "Deixar de ignorar este documento",
		"deixarIgnorarPorFornecedor": "Deixar de ignorar documentos deste fornecedor",
		"removeAssociacao": "Remover associação deste documento",
		"withoutPossibleDocs": "Sem documentos possíveis",
		"filtropesq": "Pesquisa",
		"messages": {
			"docAssociadoSuccessMsg": "O documento {{ndoc}} foi associado.",
			"docsAssociadoSuccessMsg": "Todos os documentos com 1 documento possível, foram associados com sucesso.",
			"docIgnoradoSuccessMsg": "O documento {{ndoc}} foi adicionado à lista dos ignorados.",
			"fornecedorIgnoradoSuccessMsg": "O fornecedor {{nif}} - {{nome}} foi adicionado à lista dos ignorados.",
			"docRemAssociacaoSuccessMsg": "Foi removida a associação do documento {{ndoc}}",
			"docRemIgnoringSuccessMsg": "O documento {{ndoc}} foi removido da lista dos ignorados.",
			"docRemIgnoringFornecedorSuccessMsg": "O fornecedor {{nif}} foi removido da lista dos ignorados.",
			"docDigital1": "Com doc. digital",
			"docDigital2": "Com doc. digital semelhante"
		},
		"fields": {
			"numPossiveisDocs": "Nº docs possíveis",
			"numPossiveisDocsAbbr": "Possíveis",
			"numSemelhantesDocs": "Nº docs semelhantes",
			"numSemelhantesDocsAbbr": "Semelhantes",
			"documentoLancado": "Nº documento",
			"nifEmitente": "NIF",
			"nomeEmitente": "Nome",
			"numerodocumento": "Nº doc. externo",
			"dataEmissaoDocumento": "Data emissão",
			"tipoDocumentoDesc": "Tipo",
			"estadoBeneficioDesc": "Situação",
			"actividadeProfDesc": "Âmbito atividade",
			"valorTotalBaseTributavel": "Base tributável",
			"valorTotalIva": "Valor IVA",
			"valorTotal": "Valor total",
			"baseTributavel": "Base tributável",
			"taxa": "Base taxa",
			"impostoSelo": "Imposto selo",
			"total": "Valor total",
			"nDocInterno": "Nº documento",
			"nDocExterno": "Nº doc. externo",
			"valor": "Valor",
			"dataDocExterno": "Data doc. externo",
			"docDigitalAbbr": "Doc. digital"
		},
		"btn": {
			"dropdown": {
				"extratosConta": "Extratos de conta",
				"ignorarSempreTodosDocumentos": "Ignorar sempre todos os documentos deste fornecedor",
				"ignorarDocumento": "Ignorar este documento",
				"configSupplier": "Configurar fornecedor"
			}
		},
		"tabJaLancadosCaptionContainer": {
			"table": {
				"badges": {
					"ligacaoDireta": "Ligação direta",
					"remover": "Remover",
					"naoIgnorar": "Não ignorar"
				}
			},
			"legends": {
				"documentoIgnorado": "Documento ignorado",
				"associadoDocExtDiferente": "Associado com nº doc. externo diferente",
				"documentoFornecedorIgnorado": "Documento de fornecedor ignorado"
			}
		},
		"tabDocsEFaturaCaptionContainer": {
			"mostradocpossiveis": "Mostrar apenas sem documentos possíveis",
			"tooltip": {
				"associarAutTodosPossiveis": "Associar automaticamente todos os documentos com 1 documento possível."
			}
		},
		"formFields": {
			"tipo": "Tipo",
			"fornecedor": "Fornecedor",
			"placeholder": {
				"contribuinte": "Nº contribuinte"
			}
		},
		"modal": {
			"config": {
				"title": "Configuração de conciliação e-fatura",
				"diariosList": "Lista de diários a excluir",
				"fields": {
					"nCaracteresAValidar": "Nº de caracteres Doc. Externo a validar:",
					"filtraPorDataDocExterno": "Data documento externo contribui para documentos possíveis",
					"diarioAExcluir": "Diário a excluir",
					"addDiarioAExcluir": "Adicionar diário a excluir",
					"diario": "Diário",
					"nomeDiario": "Nome"
				},
				"messages": {
					"diarioAlreadyInList": "O diário selecionado já existe na lista."
				}
			}
		},
		"error": {
			"dataFinalInferiorInicial": "A data final é inferior à inicial"
		}
	},
	"concilmovab": {
		"title": "Justificação de movimentos em aberto",
		"conta": "Conta",
		"deconta": "Conta de",
		"table": {
			"periodo": "Período",
			"nDiario": "Nº diário",
			"nDoc": "Documento",
			"nDocExterno": "Doc. ext.",
			"descricao": "Descrição",
			"dataDocExt": "Data doc.",
			"isDocumentoOrigemRegimeDeIvaDeCaixa": "Regime IVA caixa",
			"valor": "Valor",
			"porPagar": "Por pagar",
			"dc": "D/C",
			"aImputar": "A justificar",
			"dataVenc": "Vencimento",
			"moeda": "Moeda",
			"valorActualAImputar": "Conta valor atual",
			"montanteAReter": "Montante a reter"
		},
		"totals": {
			"valajustificar": "Valores a justificar",
			"totconta": "Totais da conta"
		},
		"messages": {
			"success": "Abate registado!",
			"saldomovinvalido": "O saldo dos movimentos tem de ser 0 para efetuar esta operação!",
			"jobTimeoutModalTitle": "Justificar valores automaticamente em série",
			"jobTimeoutModalMessage": "A tarefa excedeu o tempo limite. Tem que iniciar a justificação valores automaticamente em série novamente.",
			"aprepararjustvalautoserie": "A preparar o processo de justificação valores automaticamente em série...",
			"aefetuarjustvalautoserie": "A processar as justificações de valores automaticamente em série...",
			"successjustvalautoserie": "Justificações de valores automaticamente em série processadas com sucesso!",
			"noValjustauto": "Não existem valores a justificar.",
			"valjustautosuccess": "Valores justificados automaticamente!",
			"savjustautosuccess": "Movimentos justificados automaticamente guardados com sucesso!",
			"desejaefetuarjustmovi": "Deseja efetuar a justificação de movimentos em aberto para os valores introduzidos?",
			"valimputnaopodsermaior": "O valor a imputar não pode ser superior ao valor a pagar!"
		},
		"modal": {
			"justemseriecontas": "Justificar valores automaticamente em série",
			"limiteperiodomaximo": "Limita o período máximo."
		},
		"btn": {
			"justDropdownTitle": "Justificar valores",
			"justvalauto": "Justificar valores automaticamente",
			"justvalautoserie": "Justificar valores automaticamente em série",
			"justificarvalor": "Justificar valor",
			"justserie": "Justificar em série"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmação",
		"leavePromptMessage": "Ao clicar em SIM irá sair e descartar todos os dados do processamento. Tem a certeza que pretende sair?",
		"searchlimiteperiodomaximo": "Limita o período máximo da pesquisa."
	},
	"condicoescomerciais": {
		"title_detail": "Condição Comercial {{id}}",
		"title_new": "Nova Condição Comercial",
		"title_plural": "Condições Comerciais",
		"pesquisa": "pesquisa",
		"saved": "Condição Comercial {{id}}, guardada com sucesso.",
		"error": "Condição Comercial {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Condição Comercial {{id}}, eliminada com sucesso.",
		"fields": {
			"codCondComerciais": "Código",
			"codCondComerciaisPlaceholder": "código da condição comercial",
			"descricao": "Descrição",
			"descricaoPlaceholder": "descrição",
			"nomeCondPagamento": "Condição de pagamento",
			"nomeCondPagamentoPlaceholder": "condição de pagamento",
			"numDiasDataVenc": "Nº dias",
			"numDiasDataVencPlaceholder": "Número de dias",
			"tipoResumoMensal": "Tipo resumo mensal",
			"tipoResumoMensalPlaceholder": "tipo resumo mensal",
			"origemCondPagamento": "Condição de pagamento, tem origem no cliente",
			"origemDataVencimento": "Nº dias e tipo de resumo mensal, tem origem na condição de pagamento"
		}
	},
	"condicoespagamento": {
		"title_detail": "Condição de Pagamento {{id}}",
		"title_new": "Nova Condição de Pagamento",
		"title_plural": "Condições de Pagamento",
		"pesquisa": "pesquisa",
		"saved": "Condição de Pagamento {{id}}, guardada com sucesso.",
		"error": "Condição de Pagamento {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Condição de Pagamento {{id}}, eliminada com sucesso.",
		"fields": {
			"nCondPag": "Cód.",
			"nCondPagPlaceholder": "Código da condição de pagamento",
			"descricao": "Descrição",
			"descricaoPlaceholder": "Descrição",
			"nDiasPP": "Nº dias PP",
			"nDiasPPPlaceholder": "Número de dias PP",
			"percDesconto": "Perc. desconto",
			"percDescontoPlaceholder": "Percentagem de desconto",
			"numDiasDataVenc": "Nº dias (Vencimento)",
			"numDiasDataVencholder": "Número de dias vencimento",
			"tipoResumoMensal": "Tipo de resumo mensal",
			"tipoResumoMensalPlaceholder": "Tipo de resumo mensal"
		}
	},
	"configEfaturaDocPorLanc": {
		"success": "As configurações foram guardadas com sucesso",
		"fields": {
			"fatura": "Fatura",
			"faturaRecibo": "Fatura/Recibo",
			"notaDebito": "Nota de débito",
			"notaCredito": "Nota de crédito",
			"reciboVerde": "Recibo verde eletrónico",
			"faturaSimplificada": "Fatura simplificada",
			"docConferencia": "Doc. de conferência",
			"faturaEstrangeiro": "Fatura emitida estrangeiro"
		},
		"text": {
			"mesmoPredefinido": {
				"title": "Possível erro na configuração",
				"message": "O pré-definido para <strong>notas de crédito</strong> não deve ser igual ao dos outros tipos de documento. Tem a certeza que pretende continuar?"
			},
			"maintenancePreDefinidos": "Manutenção de pré-definidos de contabilidade"
		},
		"global": {
			"title": "Configuração de documentos por lançar E-Fatura",
			"fields": {
				"autoFixATDocWithDiffOneCent": "Corrige automaticamente documentos com diferença de 1 cêntimo",
				"criterioOrdenacao": "Ordenação da grelha",
				"mostraDocsFornIgnorados": "Mostra documentos de fornecedores ignorados na conciliação",
				"posicionaAutoNextDoc": "Posiciona automaticamente no próximo documento após lançar documento"
			},
			"criterioOrdenacao": {
				"dataNome": "Data Fatura | Nome Fornecedor",
				"nomeData": "Nome Fornecedor | Data Fatura"
			}
		},
		"supplier": {
			"title": "Configurações específicas para o fornecedor",
			"fields": {
				"contaCorrente": "Conta corrente a utilizar"
			}
		}
	},
	"configsERPCheck": {
		"state": {
			"load": "A carregar validações",
			"genDefaultData": "A validar e gerar dados por defeito",
			"validate": "Validações",
			"validating": "A validar",
			"repair": "A reparar",
			"finished": "Validação terminada"
		},
		"text": {
			"correction": "Correção"
		},
		"actions": {
			"validate": "Validar",
			"validateAll": "Validar tudo",
			"repair": "Reparar",
			"repairAll": "Reparar tudo"
		},
		"validateAllModal": {
			"title": "Gerar dados por defeito da empresa",
			"message": "Desejo gerar dados da empresa por defeito (tipos de documentos, famílias, IVA's, tipos de artigos, etc)"
		}
	},
	"configsErp": {
		"title_detail": "Valor {{id}}",
		"title_plural": "Configurações ERP",
		"pesquisa": "Pesquisar",
		"fields": {
			"name": "Nome",
			"description": "Descrição",
			"value": "Valor",
			"readOnly": "Só de leitura"
		},
		"items": {
			"updated": "Valor atualizado",
			"clientesFornecedoresTitle": "Clientes/Fornecedores",
			"clientesFornecedores": {
				"contaIdentificacaoAlternativaTitle": "Conta de identificação alternativa de clientes",
				"contaIdentificacaoAlternativaFornecedoresTitle": "Conta de identificação alternativa de fornecedores"
			},
			"contabilidadeTitle": "Contabilidade",
			"contabilidade": {
				"recibosTitle": "Recebimentos",
				"recibos": {
					"aplicaPercDescontoSobreValorIVATitle": "Aplica % de desconto sobre o valor com IVA",
					"reportImpressaoTitle": "Report de impressão",
					"diarioLancamentosTitle": "Nº diário",
					"descritivoLancamentosTitle": "Nº descritivo",
					"contaPocDescontProntoPagamentoTitle": "Nº conta POC de desconto P.P.",
					"meioPagamentoOmissaoTitle": "Meio de pagamento por omissão"
				},
				"pagamentosTitle": "Pagamentos",
				"pagamentos": {
					"reportImpressaoTitle": "Report de impressão",
					"diarioLancamentosTitle": "Nº diário",
					"descritivoLancamentosTitle": "Nº descritivo",
					"contaPocDescontProntoPagamentoTitle": "Nº conta POC de desconto P.P.",
					"meioPagamentoOmissaoTitle": "Meio de pagamento por omissão"
				},
				"decimaisTitle": "Decimais",
				"decimais": {
					"valorTitle": "Nº decimais para valor",
					"descontoTitle": "Nº decimais para desconto",
					"percDescontoTitle": "Nº decimais para percentagem de desconto"
				},
				"balancetesTitle": "Balancetes",
				"balancetes": {
					"reportImpressaoTitle": "Report de impressão"
				},
				"extratosTitle": "Extratos",
				"extratos": {
					"reportImpressaoTitle": "Report de impressão"
				},
				"movimentosemabertoTitle": "Movimentos em aberto",
				"movimentosemaberto": {
					"reportImpressaoTitle": "Report de impressão"
				},
				"apuraivaTitle": "Apuramento de IVA",
				"documentosTitle": "Documentos",
				"reparticaoccTitle": "Repartições p/ centro custo",
				"reparticaocc": {
					"usadataTitle": "Usa repartições por data"
				},
				"listagemdiariosTitle": "Listagem de diários"
			},
			"documentosComerciaisTitle": "Documentos comerciais",
			"documentosComerciais": {
				"tiposTitle": "Tipos de documentos",
				"tipos": {
					"outros": {
						"documentoPorDefeitoTitle": "Documento por defeito",
						"visivelTitle": "Visível",
						"groupProperty": "outros"
					},
					"comprasEfectivasTitle": "Compras efetivas",
					"comprasEfectivas": {
						"documentoPorDefeitoTitle": "Documento por defeito",
						"groupProperty": "comprasEfectivas"
					},
					"consultasaFornecedoresTitle": "Consultas a fornecedores",
					"consultasaFornecedores": {
						"documentoPorDefeitoTitle": "Documento por defeito",
						"groupProperty": "consultasaFornecedores",
						"otherTitle": "Orçamentos a fornecedores"
					},
					"encomendasClientesTitle": "Encomendas clientes",
					"encomendasClientes": {
						"documentoPorDefeitoTitle": "Documento por defeito",
						"groupProperty": "encomendasClientes"
					},
					"encomendasFornecedoresTitle": "Encomendas fornecedores",
					"encomendasFornecedores": {
						"documentoPorDefeitoTitle": "Documento por defeito",
						"groupProperty": "encomendasFornecedores"
					},
					"folhasdeObraTitle": "Folhas de obra",
					"folhasdeObra": {
						"documentoPorDefeitoTitle": "Documento por defeito"
					},
					"guiasTransporteRemessaTitle": "Guias transporte remessa",
					"guiasTransporteRemessa": {
						"documentoPorDefeitoTitle": "Documento por defeito",
						"groupProperty": "guiasTransporteRemessa"
					},
					"propostasaClientesTitle": "Propostas a clientes",
					"propostasaClientes": {
						"documentoPorDefeitoTitle": "Documento por defeito",
						"groupProperty": "propostasaClientes",
						"otherTitle": "Orçamentos a clientes"
					},
					"reservasClientesTitle": "Reservas clientes",
					"reservasClientes": {
						"documentoPorDefeitoTitle": "Documento por defeito"
					},
					"trfArmazensEntradasTitle": "Trf. armazéns entradas",
					"trfArmazensEntradas": {
						"documentoPorDefeitoTitle": "Documento por defeito"
					},
					"trfArmazensSaidasTitle": "Trf. armazéns saídas",
					"trfArmazensSaidas": {
						"documentoPorDefeitoTitle": "Documento por defeito"
					},
					"vendasEfectivasTitle": "Vendas efetivas",
					"vendasEfectivas": {
						"documentoPorDefeitoTitle": "Documento por defeito",
						"groupProperty": "vendasEfectivas"
					},
					"consignacoesClientesTitle": "Consignações clientes",
					"consignacoesClientes": {
						"documentoPorDefeitoTitle": "Documento por defeito"
					},
					"consignacoesFornecedoresTitle": "Consignações fornecedores",
					"consignacoesFornecedores": {
						"documentoPorDefeitoTitle": "Documento por defeito"
					},
					"recibosClientesTitle": "Recibos clientes",
					"recibosClientes": {
						"documentoPorDefeitoTitle": "Documento por defeito"
					},
					"pagamentosFornecedoresTitle": "Pagamentos fornecedores",
					"pagamentosFornecedores": {
						"documentoPorDefeitoTitle": "Documento por defeito"
					},
					"noneTitle": "Sem Info",
					"none": {
						"documentoPorDefeitoTitle": "Documento por defeito"
					},
					"planoProducaoTitle": "Plano produção",
					"planoProducao": {
						"documentoPorDefeitoTitle": "Documento por defeito"
					},
					"preEncomendasClientesTitle": "Pré-encomendas clientes",
					"preEncomendasClientes": {
						"documentoPorDefeitoTitle": "Documento por defeito"
					},
					"custosAdicionaisTitle": "Custos adicionais",
					"custosAdicionais": {
						"documentoPorDefeitoTitle": "Documento por defeito"
					},
					"notaCreditoTitle": "Nota de crédito",
					"notaCredito": {
						"documentoPorDefeitoTitle": "Documento por defeito"
					},
					"guiasTransporteFornecedoresTitle": "Guias transporte fornecedores",
					"guiasTransporteFornecedores": {
						"documentoPorDefeitoTitle": "Documento por defeito",
						"groupProperty": "guiasTransporteFornecedores"
					},
					"entradasDiversasTitle": "Entradas diversas",
					"entradasDiversas": {
						"documentoPorDefeitoTitle": "Documento por defeito",
						"groupProperty": "entradasDiversas"
					},
					"saidasDiversasTitle": "Saídas diversas",
					"saidasDiversas": {
						"documentoPorDefeitoTitle": "Documento por defeito",
						"groupProperty": "saidasDiversas"
					}
				}
			},
			"validacaoDasConfiguracoesTitle": "Validação das configurações",
			"validacaoDasConfiguracoes": {
				"state": {
					"Load": "A carregar validações",
					"GenDefaultData": "A validar e gerar dados por defeito",
					"Validate": "Validações",
					"Validating": "A validar",
					"Repair": "A reparar",
					"Finished": "Validação terminada"
				},
				"errors": {
					"GenDefaultDataError": "Erro a gerar os dados por defeito"
				}
			},
			"empresaTitle": "Empresa",
			"empresa": {
				"nomeTitle": "Nome da empresa",
				"islockedTitle": "Empresa bloqueada",
				"motivoislockedTitle": "Motivo empresa bloqueada",
				"verificacaoEfetuadaTitle": "Configuração verificada",
				"verificacaoNaoEfetuadaError": "As configurações desta empresa ainda não foram verificadas. Por favor comunique esta situação ao administrador de sistema.",
				"periodoIvaTrimestralTitle": "Período de IVA trimestral"
			},
			"licencaTitle": "Licença",
			"licenca": {
				"licIdTitle": "Nº da licença CentralGest",
				"nomeTitle": "Nome da licença",
				"descricaoTitle": "Descrição da licença",
				"nempresasTitle": "Nº máximo de empresas",
				"nutilizadoresTitle": "Nº máximo de utilizadores",
				"portalcolaboradorTitle": "Portal colaborador"
			},
			"erpTitle": "CentralGest",
			"erp": {
				"versaoTitle": "Versão do CentralGest Cloud",
				"dataversaoTitle": "Data da versão do CentralGest Cloud",
				"emManutencaoTitle": "ERP em manutenção"
			},
			"rhTitle": "RH",
			"rh": {
				"refeicoesTitle": "Refeições",
				"refeicoes": {
					"numMaxTitle": "Número máximo"
				}
			},
			"pcaTitle": "My Accounting",
			"portalColaborador": {
				"title": "Portal colaborador",
				"notificacao": "Notificação",
				"desativado": "Desativado",
				"diaria": "Diária",
				"diaDaSemana": "Dia da semana",
				"hora": "Hora",
				"configGuardadaComSucesso": "A configuração das notificações do portal do colaborador foi guardada com sucesso.",
				"conflitos": {
					"title": "Conflitos",
					"colaboradores": "Colaboradores",
					"permite": "Permite",
					"naoPermite": "Não permite",
					"permiteMarcarFeriasComConflitos": "Permite marcar férias com conflitos",
					"fields": {
						"nEmpresa": "Nº empresa",
						"nomeEmpresa": "Nome empresa",
						"colaborador1": "Colaborador 1",
						"colaborador2": "Colaborador 2"
					},
					"aplicarGeralEmTodas": "Aplicar geral em todas",
					"tooltips": {
						"aplicarGeralEmTodas": "Aplicar a configuração do geral em todas as empesas",
						"apagarTodosConflitosDoEmpregado": "Apagar todos os conflitos do empregado"
					},
					"alteracoesNaoGuardadas": "Existem alterações ainda não guardadas. Por favor, guarde ou descarte antes de continuar.",
					"descartarAlteracoes": "Descartar alterações",
					"criarConflito": "Criar conflito",
					"temQueSelecionarColaborador1": "Tem que selecionar o colaborador 1",
					"temQueSelecionarPeloMenosUmColaborador": "Tem que selecionar pelo menos um colaborador",
					"temACertezaApagarTodosConflitosDoEmpregado": "Tem a certeza que pretende apagar todos os conflitos do empregado \"{{empregado}}\"?",
					"temACertezaApagarEsteConflito": "Tem a certeza que pretende apagar este conflito?",
					"colaborador1": "Colaborador 1"
				},
				"prhtipocom": {
					"temACertezaApagarEsteTipoCom": "Tem a certeza que pretende apagar este tipo de comunicação?",
					"criarTipoCom": "Criar tipo de comunicação"
				}
			}
		},
		"check": {
			"disabledValidate": "Está a decorrer o processo de reparação, por favor aguarde.",
			"disabledRepair": "Está a decorrer o processo de validação, por favor aguarde."
		},
		"groups": {
			"comercial": {
				"requiredDefaultDoc": "É obrigatório ter um documento por defeito selecionado"
			},
			"pca": {
				"optionsPeriodo": {
					"todos": "Todos os períodos",
					"atePeriodoAtivoEmpresa": "Até ao período activo da empresa",
					"ateUltimoPeriodoEncerrado": "Até ao último período encerrado"
				}
			}
		}
	},
	"configsSite": {
		"title_detail": "Valor {{id}}",
		"title_plural": "Configurações e-mail",
		"pesquisa": "Pesquisar",
		"fields": {
			"configId": "Nome",
			"value": "Valor"
		},
		"saved": "Configuração gravada.",
		"error": "As configurações de e-mail não podem ser guardadas. Por favor verifique os campos.",
		"testMail": "Testar mail",
		"configs": {
			"database.version": "Versão da base de dados",
			"mail.smtp.adminemail": "E-mail administrador",
			"mail.smtp.siteemail": "E-mail site",
			"mail.smtp.smtppassword": "SMTP password",
			"mail.smtp.smtpport": "SMTP porta",
			"mail.smtp.smtpserver": "SMTP servidor",
			"mail.smtp.smtpuser": "SMTP utilizador",
			"mail.smtp.smtpusessl": "SMTP usa ssl",
			"termsRGPDVersion": "Versão dos termos RGPD"
		}
	},
	"contabAdvancedOperations": {
		"fields": {
			"dePeriodo": "Período",
			"deNDiario": "Diário de",
			"deNDoc": "Nº documento de",
			"periodoDestino": "Período de destino",
			"diarioDestino": "Diário de destino",
			"listaDocsNaoPermitidos": "Documentos que não podem ser alterados",
			"listaDocsPermitidos": "Documentos que podem ser alterados"
		},
		"list": {
			"fields": {
				"nPeriodo": "Período",
				"nDiario": "Diário",
				"nDoc": "Nº documento",
				"tipoErroNaoPermitido": "Motivo",
				"seleccionado": "Selecção",
				"processado": "Processado"
			}
		},
		"messages": {
			"warning": "Ao efetuar esta operação todos os documentos selecionados serão anulados e criadas cópias destes no período {{periodo}}.\nTem a certeza que pretende continuar?",
			"warningAnular": "Ao efetuar esta operação todos os documentos selecionados serão anulados.\n Tem a certeza que pretende continuar?",
			"temQueSeleccionar": "Tem de selecionar pelo menos um documento",
			"success": "Operação efectuada com sucesso"
		},
		"fieldsAnulado": {
			"listaDocsNaoPermitidos": "Documentos que não podem ser anulados",
			"listaDocsPermitidos": "Documentos que podem ser alterados"
		},
		"error": {
			"periodoNaoPreenchido": "O período precisa de estar preenchido.",
			"nDiarioFinalInferiorInicial": "O diário final é inferior ao inicial",
			"periodoDestNaoPreenchido": "O período de destino precisa de estar preenchido.",
			"nDocFinalInferiorInicial": "O nº de documento final é inferior ao inicial",
			"periodoFinalInferiorInicial": "O período final é inferior ao inicial"
		}
	},
	"continentes": {
		"pesquisa": "Continentes",
		"fields": {
			"codContinente": "Código do continente",
			"nomeContinente": "Nome do continente"
		},
		"data": {
			"portugal": "Continente",
			"acores": "Região Açores",
			"madeira": "Região Madeira",
			"estrangeiro": "Estrangeiro",
			"outros": "Outros"
		}
	},
	"contratos": {
		"title_detail": "Contrato {{id}}",
		"title_new": "Novo Contrato",
		"title_plural": "Contratos",
		"pesquisa": "Pesquisar",
		"saved": "Contrato {{id}}, guardado com sucesso",
		"error": "Contrato {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Contrato {{id}}, apagado com sucesso",
		"fields": {
			"cab": {
				"refCtrCab": "Cód. contrato",
				"nClasseCtr": "Classe",
				"nClasseCtrPlaceholder": "Código da classe de contrato",
				"nTipoContrato": "Tipo",
				"nTipoContratoPlaceholder": "Tipo de contrato",
				"nContrato": "Nº contrato",
				"nContratoPlaceholder": "Número de contrato",
				"verContrato": "Versão",
				"verContratoPlaceHolder": "Versão de contrato",
				"nContratoAsString": "Contrato",
				"nContratoSemVersaoAsString": "Contrato",
				"descricao": "Descrição",
				"descricaoPlaceHolder": "Descrição do contrato",
				"nClifo": "Cliente / Fornecedor",
				"nClifoPlaceHolder": "Cliente / Fornecedor",
				"clifoNome": "Nome cliente",
				"idIdeChav": "Grupo de conta",
				"idIdeChavPlaceHolder": "Grupo de conta",
				"dataCtr": "Data contrato",
				"dataCtrPlaceHolder": "Data contrato",
				"dataInicio": "Data início",
				"dataInicioPlaceHolder": "Data início",
				"dataFim": "Data fim",
				"dataFimPlaceHolder": "Data fim",
				"estado": "Estado",
				"estadoPlaceHolder": "Cód. estado",
				"ccusto": "Centro de custo",
				"ccustoPlaceHolder": "Código do centro de custo",
				"nRefProcesso": "Processo",
				"nRefProcessoPlaceHolder": "Código do processo",
				"codMoeda": "Moeda",
				"codMoedaPlaceHolder": "Cód. moeda",
				"refExterna": "Referência externa",
				"terminado": "Terminado",
				"totalLiquido": "Valor",
				"valorGlobal": "Valor global",
				"codCondComerciais": "Condição comercial",
				"codCondComerciaisPlaceHolder": "Código da condição comercial"
			},
			"linhas": {
				"nArtigo": "Cód. artigo",
				"nomeArtigo": "Descrição",
				"qtd1": "Qtd",
				"prVenda": "Pr. unit.",
				"d1": "D1",
				"d2": "D2",
				"d3": "D3",
				"desconto": "Desconto",
				"d4": "D4",
				"d5": "D5",
				"d6": "D6",
				"codIva": "Cód. IVA",
				"nArmazem": "Armazém",
				"cunimo": "Unid. mov.",
				"qtdPUnid": "Qtd./Unid.",
				"nVendedor": "Vendedor",
				"ccusto": "Centro de custo",
				"nZona": "Zona",
				"nDepto": "Departamento",
				"nSubde": "Sub-departamento",
				"qtd1Fact": "Qtd. faturada",
				"nRefProcesso": "Processo",
				"estado": "Estado",
				"obs": "Observações",
				"valorLiquido": "Líquido",
				"taxaIva": "Taxa IVA"
			}
		},
		"detail": {
			"cab": {
				"dataCtr": "Data contrato",
				"nClifo": "Cliente / Fornecedor",
				"dataFim": "Data de fim",
				"dataInicio": "Data de início",
				"nContribuinte": "Nº contribuinte",
				"codPostal": "Cód. postal"
			}
		},
		"tabs": {
			"linhas": "Linhas"
		},
		"config": {
			"verGrupoConta": "Mostrar grupo conta",
			"verMoeda": "Mostrar moeda",
			"verRefExterna": "Mostrar referência externa",
			"verCondComerciais": "Mostrar condições comerciais",
			"verVendedor": "Mostrar vendedor",
			"verNCCusto": "Mostrar centro de custo",
			"verD1": "Mostrar D1",
			"verArmazem": "Mostrar armazém",
			"verUnidadeMovimento": "Mostrar unidade de movimento"
		},
		"totais": {
			"resumo": "Resumo"
		},
		"print": {
			"config": {
				"showClasses": "Mostrar filtro de classes"
			},
			"filters": {
				"classes": "Classes",
				"declifo": "Cliente de",
				"dencontrato": "Nº contrato de",
				"dtdedatactr": "Data de contrato de",
				"dtdefim": "Data de fim de",
				"dtdeini": "Data de início de",
				"tipos": "Tipos de contrato"
			}
		},
		"errors": {
			"artigoBloqueadoDesativado": "O artigo {{nArtigo}} encontra-se bloqueado ou desactivado!",
			"notSelected": "Não existe nenhum contrato selecionado",
			"terminado": "O contrato {{contrato}} já se encontra terminado",
			"notTerminado": "O contrato {{contrato}} não se encontra terminado",
			"reabrir": "Cancelada a reabertura",
			"terminar": "Cancelada a ação de terminar",
			"apagar": "Cancelada a ação de apagar"
		},
		"btn": {
			"editar": "Editar",
			"reabrir": "Reabrir",
			"terminar": "Terminar",
			"apagar": "Apagar"
		},
		"info": {
			"terminado": "Terminado",
			"reabrir": "Reabrir contrato",
			"terminar": "Terminar contrato",
			"apagar": "Apagar contrato"
		},
		"list": {
			"config": {
				"showNaoAtivosOnly": "Ver apenas terminados e expirados"
			}
		}
	},
	"contratostiposervicos": {
		"title_detail": "Tipo de Avença {{id}}",
		"title_new": "Novo Tipo de Avença",
		"title_plural": "Tipos de Avenças",
		"pesquisa": "Pesquisar",
		"saved": "Tipo de Avença {{id}}, guardada com sucesso",
		"error": "Tipo de Avença {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Tipo de Avença {{id}}, apagada com sucesso",
		"fields": {
			"id": "Código tipo de contrato da classe",
			"nClasseCtr": "Código",
			"nClasseCtrPlaceholder": "Código da classe de avença",
			"classeTipo": "Classe tipo",
			"classeTipoPlaceholder": "Classe tipo de avença",
			"nTipoContrato": "Número do tipo de avença",
			"nTipoContratoPlaceholder": "Tipo de avença, se não for preenchido será gerado automaticamente.",
			"nome": "Nome",
			"nomePlaceholder": "Nome tipo de avença",
			"periocidade": "Periodicidade",
			"periocidadePlaceholder": "Periodicidade da avença",
			"nDocfa": "Tipo de documento",
			"nDocfaPlaceholder": "Tipo de documento",
			"nNumer": "Numeração",
			"nNumerPlaceholder": "Numeração do tipo de documento",
			"nomeClasse": "Classe",
			"nomeClassePlaceholder": "Classe avença",
			"processaDiferimento": "Processa diferimento",
			"faturacaomes": "Faturação no mês",
			"docComercialAssinar": "Assinar",
			"docComercialEncerrar": "Assinar/Encerrar",
			"txtContrato": "Observações",
			"variables": "Variáveis",
			"proceAuto": "Processamento automático",
			"procAutoTipo": "Agendamento",
			"procAutoDiaFixo": "Dia fixo",
			"procAutoEnviaMailTitle": "O processamento será executado durante o dia especificado",
			"procAutoEnviaMail": "Enviar e-mail automaticamente ao cliente",
			"proAutoEmailResponsavel": "E-Mail responsável"
		},
		"showFaturacaoMes": "Mostra faturação no mês",
		"actions": {
			"nnumer": "Gerar automaticamente uma nova numeração",
			"decrementafaturacaomes": "Decrementa o mês de faturação",
			"incrementafaturacaomes": "Incrementa o mês de faturação"
		},
		"infoFaturacaoMes": {
			"info": "A faturação e a prestação do serviço respeitam ao mesmo mês.",
			"infoPositiveValue": "Exemplo: a faturação em Agosto respeita a um serviço já prestado no mês de {{month}}.",
			"infoNegativeValue": "Exemplo: a faturação em Agosto respeita a um serviço a ser prestado no próximo mês de {{month}}."
		},
		"messages": {
			"emailNotification": "Envio de notificação com o relatório do processamento",
			"txtContrato": "Observações vísiveis na fatura"
		},
		"datasource": {
			"none": "Inativo",
			"primeiroDiaUtil": "1º dia útil",
			"ultimoDiaUtil": "Último dia útil",
			"primeiroDiaDoPeriodo": "1º dia do período",
			"ultimoDiaPeriodo": "Último dia do período",
			"diaFixo": "Dia fixo"
		}
	},
	"contratostipos": {
		"title_detail": "Tipo de Contrato {{id}}",
		"title_new": "Novo Tipo de Contrato",
		"title_plural": "Tipos de Contratos",
		"pesquisa": "Pesquisar",
		"saved": "Tipo de Contrato {{id}}, guardado com sucesso",
		"error": "Tipo de Contrato {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Tipo de Contrato {{id}}, apagado com sucesso",
		"fields": {
			"id": "Código tipo de contrato da classe",
			"nClasseCtr": "Código",
			"nClasseCtrPlaceholder": "Código da classe de contrato",
			"classeTipo": "Classe tipo",
			"classeTipoPlaceholder": "Classe tipo de contrato",
			"nTipoContrato": "Tipo de contrato",
			"nTipoContratoPlaceholder": "Tipo de contrato",
			"nome": "Nome",
			"nomePlaceholder": "Nome tipo de contrato",
			"periocidade": "Periocidade",
			"periocidadePlaceholder": "Periocidade do tipo de contrato",
			"nDocfa": "Tipo de documento",
			"nDocfaPlaceholder": "Tipo de documento",
			"nNumer": "Numeração",
			"nNumerPlaceholder": "Numeração do tipo de documento",
			"nomeClasse": "Classe",
			"nomeClassePlaceholder": "Classe tipo de contrato"
		}
	},
	"controloIVA": {
		"title_detail": "Controlo de IVA {{id}}",
		"title_new": "Novo cód. de controlo de IVA",
		"title_plural": "Controlo de IVA",
		"pesquisa": "Pesquisar",
		"saved": "Cód. de controlo de IVA {{id}}, guardado com sucesso.",
		"error": "Cód. de controlo de IVA {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Cód. de controlo de IVA {{id}}, apagado com sucesso.",
		"fields": {
			"codControloIVA": "Cód. controlo IVA",
			"descAbreviada": "Descrição",
			"descCompleta": "Descrição completa",
			"tipoBem": "Tipo de bem",
			"tipoTaxa": "Tipo de taxa",
			"tipoOperacao": "Tipo de operação",
			"tipoLiquidacao": "Tipo de liquidação",
			"tipoTransmissaoAct": "Tipo transmissão ativa",
			"tipoTransmissaoPas": "Tipo transm. passiva",
			"mercado": "Mercado",
			"areaRegional": "Área regional",
			"inversaoSujPassivo": "Inversão suj. passivo",
			"tipoDeducao": "Tipo dedução",
			"decPERBASETRIBUT": "Cód. dec. periódica",
			"decANUBASETRIBUT": "Cód. dec. anual",
			"codIVA": "Cód. IVA",
			"nContaIVADEDLIQREG": "Conta IVA líq. / dedutível / reg.",
			"nContaVTNAODEDUT": "Conta VT não dedutível",
			"nContaIVANAODEDUT": "Conta IVA não dedutível",
			"nContaVTNDEDPROR": "Conta VT não ded. prorrata",
			"nContaIVANDEDPROR": "Conta IVA não ded. prorrata",
			"nContaVTLIQUIDRCH": "Conta 99 val. trib. aq. UE / autoliq.",
			"nContaIVALIQUIDRCH": "Conta 24 IVA líq. aq. UE / autoliq.",
			"decPERIVADEDLIQREG": "",
			"decPERVTNAODEDUT": "",
			"decPERIVANAODEDUT": "",
			"decPERVTNDEDPROR": "",
			"decPERIVANDEDPROR": "",
			"decPERVTLIQUIDRCH": "Cód. dec. per. b. trib. aq. UE / autoliq.",
			"decPERIVALIQUIDRCH": "",
			"decANUIVADEDLIQREG": "",
			"decANUVTNAODEDUT": "",
			"decANUIVANAODEDUT": "",
			"decANUVTNDEDPROR": "",
			"decANUIVANDEDPROR": "",
			"decANUVTLIQUIDRCH": "",
			"decANUIVALIQUIDRCH": "",
			"tipoValidacaoNIF": "Tipo de validação do NIF",
			"usaVTNAODEDUTOrigem": "Usa conta origem",
			"usaIVANAODEDUTOrigem": "Usa conta origem",
			"usaVTNDEDPROROrigem": "Usa conta origem",
			"usaIVANDEDPROROrigem": "Usa conta origem",
			"isRegimeIvaCaixa": "Regime de IVA de caixa",
			"regularizacoesC40": "Tipo de regularização do campo 40",
			"regularizacoesC41": "Tipo de regularização do campo 41",
			"regularizacoesC40RCH": "Tipo de regularização do campo 40",
			"regularizacoesC41RCH": "Tipo de regularização do campo 41",
			"codIVANome": "Cód. IVA",
			"decPERBASETRIBUTNome": "Cód. dec. periódica",
			"decANUBASETRIBUTNome": "Cód. dec. anual",
			"nCONTAIVADEDLIQREGNome": "Conta IVA líq. / dedutível / reg.",
			"nCONTAVTNAODEDUTNome": "Conta VT não dedutível",
			"nCONTAIVANAODEDUTNome": "Conta IVA não dedutível",
			"nCONTAVTNDEDPRORNome": "Conta VT não ded prorrata",
			"nCONTAIVANDEDPRORNome": "Conta IVA não ded. prorrata",
			"nCONTAVTLIQUIDRCHNome": "Conta 99 val. trib. aq. UE / autoliq.",
			"nCONTAIVALIQUIDRCHNome": "Conta 24 IVA líq. aq. UE / autoliq.",
			"tipoRegularizacaoCampo40": "Tipo de regularização do campo 40",
			"tipoRegularizacaoCampo41": "Tipo de regularização do campo 41",
			"nContaIvaCativo": "Conta de IVA cativo",
			"tipoRegularizacao": "Tipo de regularização",
			"declaracaoPerio": "O campo da declaração periódica: {{info}}"
		},
		"steps": {
			"operacaoIVA": "Tipo de operação IVA",
			"camposIVA": "Campos do IVA e contas relacionadas com a base tributável"
		},
		"helper": {
			"taxaAtual": "Taxa atual",
			"percDedGasoleo": "Perc. ded. gasóleo",
			"percDedProRata": "Perc. ded. pro-rata",
			"decPeriodicaIva": "Dec. periódica IVA",
			"decAnualIva": "Dec. anual IVA"
		},
		"btn": {
			"controloIVAEntreEmpresas": "Copia controlo IVA p/ empresas"
		}
	},
	"controlosIvaEntreEmpresas": {
		"navwizard": {
			"controlosIva": "Controlos IVA",
			"empresas": "Empresas",
			"erros": "Erros/Avisos",
			"final": "Final"
		},
		"grid": {
			"fields": {
				"nEmpresa": "Cód. empresa",
				"nomeEmpresa": "Nome empresa",
				"codControloIVA": "Cód. controlo IVA",
				"nomeControloIVA": "Nome controlo IVA",
				"erros": "Erros/Avisos",
				"copiar": "Ignorar avisos e copiar"
			}
		},
		"confirmacaoOkCancel": {
			"message": "Tem a certeza que pretende copiar os códigos de controlo de IVA selecionados para as empresas?"
		},
		"success": "Cópia efetuada com sucesso"
	},
	"conttrabbaselegal": {
		"title_detail": "Base legal de Contrato de Trabalho {{id}}",
		"title_new": "Novo base legal de contrato de trabalho",
		"title_plural": "Bases legais de Contratos de Trabalho",
		"pesquisa": "Pesquisar",
		"saved": "Base legal de Contrato de Trabalho {{id}}, guardado com sucesso.",
		"error": "Base legal de Contrato de Trabalho {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Base legal de Contrato de Trabalho {{id}}, apagado com sucesso.",
		"fields": {
			"contTrabBaseLegal": "Código base legal",
			"tipoContrato": "Tipo de contrato",
			"descricao": "Descrição",
			"duracaoMinima": "Nº dias de duração mínima",
			"duracaoMaxima": "Nº dias de duração máxima (Ind. renov)",
			"duracaoMaxPrimEmpreg": "Nº dias de duração máxima primeiro emp.",
			"dilacaoNovoContrato": "Nº dias de dilatação para novo contrato",
			"numRenovNormal": "Nº renov. normais",
			"numRenovExcepcionais": "Nº renov. excecionais",
			"passagemSemTermo": "Nº dias após data para den., fica efetivo",
			"contTBLNovaSeq": "Novo código legal após renovações",
			"periodoExpNormalInd": "Normal",
			"periodoExpTecnicoInd": "Técnico termo indeterminado",
			"periodoExpSuperioInd": "Superior termo indeterminado",
			"periodoExpTermoCerto": "Termo certo",
			"preAvisoDenunNormal": "Normal",
			"preAvisoDenunTecnico": "Técnico termo indeterminado",
			"preAvisoDenunSuperio": "Técnico termo indeterminado",
			"preAvisoDenunTermoC": "Termo certo",
			"feriasPorMesCompl": "Férias por mês completo",
			"feriasMaximo": "Férias máximo",
			"aviDesvincTrab": "Aviso desvinculação trab.",
			"aviCaducidadeTrab": "Aviso caducidade trab",
			"aviCaducidadeEmpresa": "Aviso caducidade empresa",
			"compCaducidadeEmpre": "Compensação por caducidade"
		}
	},
	"conttrabcab": {
		"title_detail": "Contrato de Trabalho {{id}}",
		"title_new": "Novo contrato de trabalho",
		"title_plural": "Contratos de Trabalho",
		"pesquisa": "Pesquisar",
		"saved": "Contrato de Trabalho {{id}}, guardado com sucesso.",
		"error": "Contrato de Trabalho {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Contrato de Trabalho {{id}}, apagado com sucesso.",
		"fields": {
			"nContrato": "Nº contrato",
			"codEmp": "Cód. empregado",
			"descricao": "Descrição",
			"nCodBaseLegalContr": "Cód. base legal",
			"codEstado": "Estado",
			"vigor": "Em vigor",
			"dataCelebracaoContr": "Data celebração",
			"dataInicioTrab": "Data início de trabalho",
			"dataCessacao": "Data cessação",
			"actContratada": "Atividade contratada",
			"retribuicao": "Retribuição",
			"localTrab": "Local de trabalho",
			"perioNormalTrab": "Perído normal de trabalho",
			"termo": "Indicação do termos estipulado",
			"motivoTermo": "Motivo justificativo do termo",
			"codContratoMotivo": "Cód. motivo",
			"observacoes": "Observações"
		}
	},
	"conttrabmotivo": {
		"title_detail": "Motivo de Contrato de Trabalho {{id}}",
		"title_new": "Novo motivo de contrato de trabalho",
		"title_plural": "Motivos de Contrato de Trabalho",
		"pesquisa": "Pesquisar",
		"saved": "Motivo de Contrato de Trabalho {{id}}, guardado com sucesso.",
		"error": "Motivo de Contrato de Trabalho {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Motivo de Contrato de Trabalho {{id}}, apagado com sucesso.",
		"fields": {
			"codContTrabMotivo": "Código motivo contrato trabalho",
			"descrBreve": "Descrição breve",
			"descrCompleta": "Descrição completa",
			"propriedadeMotivo": "Propriedade"
		}
	},
	"cookies": {
		"consent": {
			"header": "<p>O CentralGest Cloud utiliza cookies para personalizar o conteúdo da aplicação.</p><p>Antes de começar <strong>precisamos do seu consentimento</strong> para armazenar cookies no seu dispositivo.</p>",
			"about": {
				"1": "Cookies são pequenos ficheiros de texto que são usados pela aplicação para tornar a experiência do utilizador mais eficiente.",
				"2": "A lei estabelece que podemos armazenar cookies no seu dispositivo se os mesmos forem estritamente necessários para o normal funcionamento da aplicação. Para todos os outros tipos de cookies, precisamos da sua autorização.",
				"3": "O CentralGest Cloud utiliza diferentes tipos de cookies, alguns dos quais são colocados por serviços externos que aparecem nas nossas páginas."
			}
		},
		"purpose": {
			"x-auth-token": "Identifica o utilizador e permite autenticar o mesmo no servidor",
			"reCaptcha": "O reCAPTCHA protege a aplicação contra spam e outros tipos de abusos automatizados"
		}
	},
	"crabd": {
		"title_detail": "Cruzamento do Abono / Desconto {{id}}",
		"title_new": "Novo Cruzamento de Abono / Desconto",
		"title_plural": "Cruzamentos de Abonos / Descontos",
		"pesquisa": "Pesquisar",
		"deleted": "Cruzamento apagado com sucesso.",
		"saved": "Cruzamento guardado com sucesso.",
		"error": "Cruzamento não pode ser guardado. Por favor verifique os campos.",
		"updated": "Cruzamento atualizado com sucesso.",
		"fields": {
			"tipoAbDescOrigem": "Tipo",
			"codOrigem": "Abono / Desconto",
			"nCodOrigem": "Código",
			"tipoAbDescDestino": "Abono / Desconto",
			"codDestino": "Abono / Desconto",
			"nCodDestino": "Código",
			"entSuporteEmpregado": "Empregado",
			"entSuportePatronato": "Patronato",
			"incidenciaEmpregado": "(%) Incidência",
			"incidenciaPatronato": "(%) Incidência",
			"tipoExcecao": "Tipo exceção",
			"categoriaAbDescDestino": "Categoria",
			"excecoes": "Exceções"
		},
		"tableFields": {
			"crabdList": "Lista de cruzamentos",
			"categoria": "Categoria",
			"cruzamento": "Cruzamento",
			"empregado": "Empregado",
			"patronato": "Patronato",
			"abonoDesconto": "A/D",
			"desconto": "Desconto",
			"cod": "Cód",
			"nome": "Nome",
			"cat": "Cat",
			"excecao": "Tem exceção"
		},
		"removeCruzamento": "Tem certeza que pretende remover o cruzamento?",
		"cruzdelsuccess": "Cruzamento removido com sucesso."
	},
	"crabonos": {
		"title_detail": "Cruzamento do Abono {{id}}",
		"title_new": "Novo Cruzamento de Abono",
		"title_plural": "Cruzamentos de Abonos",
		"pesquisa": "Pesquisar",
		"deleted": "Cruzamento apagado com sucesso.",
		"saved": "Cruzamento guardado com sucesso.",
		"error": "Cruzamento não pode ser guardado. Por favor verifique os campos."
	},
	"crdescontos": {
		"title_detail": "Cruzamento do Desconto {{id}}",
		"title_new": "Novo Cruzamento de Desconto",
		"title_plural": "Cruzamentos de Descontos",
		"pesquisa": "Pesquisar",
		"deleted": "Cruzamento apagado com sucesso.",
		"saved": "Cruzamento guardado com sucesso.",
		"error": "Cruzamento não pode ser guardado. Por favor verifique os campos."
	},
	"criterioDisponiveis": {
		"pesquisa": "Critérios disponíveis",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"weightedAverageCost": "Custo médio ponderado",
			"lifo": "Lifo (Last In First Out)",
			"fifo": "Fifo (First In First Out)",
			"standardCost": "Custo padrão",
			"averageCost": "Custo médio",
			"specificLot": "Lote específico"
		}
	},
	"crneg": {
		"title_detail": "Exceção de Cruzamento do Empregado {{id}}",
		"title_new": "Nova Exceção de Cruzamento",
		"title_plural": "Exceções de Cruzamento",
		"pesquisa": "Pesquisar",
		"deleted": "Exceção de Cruzamento apagada com sucesso.",
		"saved": "Exceção de Cruzamento guardada com sucesso.",
		"error": "Exceção de Cruzamento não pode ser guardada. Por favor verifique os campos.",
		"fields": {
			"nCodEmp": "Código empregado",
			"nomeEmp": "Nome empregado",
			"nCodOrigem": "Código origem",
			"nCodDestino": "Código destino",
			"entSuporte": "Entidade suporte"
		},
		"modalCRNEGTitle": "Indique os empregados a incluir"
	},
	"crss": {
		"title_detail": "Regime de Segurança Social {{id}}",
		"title_new": "Novo regime de segurança social",
		"title_plural": "Segurança Social",
		"listRegimes": "Regimes de Segurança Social",
		"pesquisa": "Pesquisar",
		"deleted": "Regime de Segurança Social apagado com sucesso.",
		"saved": "Regime de Segurança Social {{id}}, guardada com sucesso.",
		"error": "Regime de Segurança Social {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"nifentidades": "NIF das entidades",
		"fields": {
			"ncrss": "Código do CRSS",
			"nome": "Nome",
			"designaBreve": "Designação breve",
			"morada": "Morada",
			"localidade": "Localidade",
			"npostal": "Código postal",
			"telefone": "Telefone",
			"fax": "Fax",
			"nbenefCaixa": "Nº ident. seg. social",
			"crssSedeSocial": "CRSS da SEDE",
			"nifSegSocial": "NIF seg. social",
			"nifCGA": "NIF CGA",
			"nifADSE": "NIF ADSE"
		},
		"table": {
			"nclcx": "Classe de caixa",
			"nomeregime": "Nome do regime",
			"percentPatr": "% de contrib. (Patronal)",
			"percentEmpr": "% de contrib. (Empregado)",
			"codIntegracao": "Cód. Integração",
			"naoVaiSegSocial": "Envia dados p/SS"
		}
	},
	"dashboards": {
		"erp": {
			"pageTitle": {
				"vendas": "Volume de negócios",
				"tesourariaLiquida": "Tesouraria líquida",
				"rendimentos": "Rendimentos",
				"gastosCompras": "Gastos compras"
			},
			"vendas": {
				"ano": "Vendas de {{year}}",
				"dia": "Vendas do dia",
				"mes": "Vendas do mês",
				"vendasAno": "Vendas do ano",
				"anoTop": "Top 5 artigos vendidos em {{year}}"
			},
			"clientes": {
				"title": "Clientes",
				"ano": "Novos clientes de {{year}}",
				"dia": "Novos clientes do dia",
				"mes": "Novos clientes do mês",
				"clientesAno": "Novos clientes do ano",
				"anoTop": "Top 5 maiores clientes de {{year}}",
				"legendaClienteAno": "Clientes de {{year}}"
			},
			"table": {
				"nome": "Nome",
				"valor": "Valor",
				"percentagem": "Percentagem",
				"legends": {
					"vendasAno": "Vendas por mês",
					"tesourariaLiquidaDisponibilidade": "Disponibilidades",
					"tesourariaLiquidaFinanciamento": "Financiamento"
				}
			},
			"compras": {
				"title": "Compras",
				"ano": "Compras de {{year}}",
				"dia": "Compras do dia",
				"mes": "Compras do mês",
				"anoTop": "Top 5 artigos comprados em {{year}}"
			},
			"fornecedores": {
				"title": "Fornecedores",
				"ano": "Novos fornecedores de {{year}}",
				"dia": "Novos fornecedores do dia",
				"mes": "Novos fornecedores do mês",
				"anoTop": "Top 5 maiores fornecedores de {{year}}"
			},
			"comprasVendas": {
				"title": "Vendas e compras",
				"titleParam": "Vendas e compras de {{year}}",
				"legendas": {
					"vendas": "Vendas",
					"compras": "Compras",
					"diference": "Diferença"
				}
			}
		},
		"financeiro": {
			"tableContasAOrdem": {
				"title": "Ligação aos bancos em tempo real",
				"descricao": "Descrição",
				"nomeBanco": "Nome do banco",
				"iban": "IBAN",
				"saldoAtual": "Saldo atual"
			},
			"tableValoresEmAberto": {
				"title": "Total de valores em aberto",
				"descricao": "Descrição",
				"valorEmAberto": "Valor em aberto",
				"prazoMedio": "Prazo médio (dias)"
			},
			"receberPagarTables": {
				"titleReceber": "Valores por receber de clientes",
				"titlePagar": "Valores por pagar a fornecedores",
				"titleTableReceber": "Top 5 de clientes com valores a receber",
				"titleTablePagar": "Top 5 de fornecedores com valores a pagar",
				"naoVencido": "Não vencido",
				"vencido": "Vencido"
			}
		},
		"rh": {
			"tiles": {
				"funcionariosAtivos": "Funcionários ativos",
				"entradas": "Entradas",
				"saidas": "Saídas",
				"subtitleMeses": "Últimos {{meses}} meses",
				"tempoInteiro": "Tempo inteiro",
				"tempoParcial": "Tempo parcial"
			},
			"graficos": {
				"funcionariosPorGenero": "Funcionários por género",
				"funcionariosPorDepartamento": "Funcionários por departamento",
				"funcionariosPorIdade": "Funcionários por idade",
				"funcionariosPorTipoContrato": "Funcionários por tipo de contrato",
				"custosFuncionarios": {
					"title": "Gastos mensais",
					"vencimentosFaltas": "Vencimentos - Faltas",
					"subRefeicao": "Sub. refeição",
					"impostos": "Impostos"
				}
			}
		},
		"ativos": {
			"bensAtivos": "Bens ativos",
			"nFichasAtivos": "Fichas",
			"nAquisicoesAno": "Aquisições",
			"nVendasAbatesAno": "Vendas e abates",
			"valorAquisicao": "Valor de aquisição",
			"depreciacoesAcumuladas": "Depreciações acumul.",
			"valorAtual": "Valor atual",
			"pie": {
				"ativosTangiveis": "Ativos fixos tangíveis",
				"ativosInTangiveis": "Ativos intangíveis / Prop. investimento",
				"nFichas": "Nº de fichas: {{fichas}}"
			},
			"table": {
				"topAtivos": "Top 10 - Valor de aquisição",
				"codAmortizacao": "Cód. dec. reg.",
				"nomeAmortizacao": "Descrição",
				"nFichas": "Nº de fichas",
				"valorAq": "Valor aquisição",
				"valorDep": "Valor depreciação",
				"valorLiq": "Valor liquido",
				"percentDep": "% Depreciado"
			},
			"bar": {
				"title": "Depreciações mensais",
				"depMonth": "Depreciações por mês",
				"depAcumulado": "Depreciações acumuladas"
			},
			"radical": {
				"rdl431": "Terrenos e recursos naturais",
				"rdl432": "Edifícios e outras construções",
				"rdl433": "Equipamento básico",
				"rdl434": "Equipamento de transporte",
				"rdl435": "Equipamento administrativo",
				"rdl436": "Equipamentos biológicos",
				"rdl437": "Outros ativos fixos tangíveis",
				"rdl441": "Goodwill",
				"rdl442": "Projetos de desenvolvimento",
				"rdl443": "Programas de computador",
				"rdl444": "Propriedade industrial",
				"rdl445": "Outros ativos intangíveis",
				"rdl446": "Outros ativos intangíveis",
				"rdl447": "Outros ativos intangíveis",
				"rdl421": "Terrenos e recursos naturais",
				"rdl422": "Edifícios e outras construções",
				"rdl423": "Outras propriedades de investimento",
				"rdl424": "Outras propriedades de investimento",
				"rdl425": "Outras propriedades de investimento",
				"rdl426": "Outras propriedades de investimento",
				"rdl427": "Outras propriedades de investimento"
			},
			"messages": {
				"contab": "Há aquisições realizadas na contabilidade sem relação com os ativos!",
				"contabLink": "Criar ficha a partir do registo da contabilidade",
				"investEmCurso": "Existem investimentos em curso sem ficha criada.",
				"investEmCursoLink": "Criar ficha a partir do investimentos em curso"
			}
		}
	},
	"datavalor": {
		"title_detail": "Data Valor {{id}}",
		"title_new": "Novo Data Valor",
		"title_plural": "Datas Valor",
		"pesquisa": "Pesquisar",
		"saved": "Data Valor {{id}}, guardada com sucesso.",
		"error": "Data Valor {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Data Valor {{id}}, apagada com sucesso",
		"fields": {
			"tipo": "Tipo",
			"keyValue": "Key value",
			"valor1": "Limite fiscal",
			"valor2": "Limite contabilístico",
			"valor3": "Taxa",
			"dataInicio": "Data início"
		}
	},
	"debDiretosSEPA": {
		"buttons": {
			"toolbarEmissao": "Emissão",
			"toolbarImportar": "Importar",
			"clientesAderentes": "Clientes aderentes",
			"notificar": "Notificar",
			"imprimir": "Imprimir",
			"consultaFicheirosSEPA": "Consulta de ficheiros SEPA"
		},
		"fields": {
			"tipo": "Tipo",
			"valor": "Valor",
			"digitos": "Nº dígitos",
			"estado": "Estado",
			"meioPagamento": "Meio pagamento",
			"iban": "IBAN",
			"idDebitoDireto": "ID débito direto",
			"exampleIdDebito": "Ex: PTPTXXZZZXXXXXXXXXX",
			"nConta": "Nº conta",
			"contaBanco": "Conta banco",
			"nDiario": "Nº diário",
			"diario": "Diário",
			"nDescritivo": "Nº descritivo",
			"descritivo": "Descritivo",
			"nome": "Nome",
			"emailDD": "Email débitos diretos",
			"nAutorizacao": "Nº autorização",
			"inicio": "Início",
			"fim": "Fim",
			"contrato": "Contrato",
			"firstEnviado": "FRST Enviado",
			"codMeioPagamento": "Cod. meio pagamento",
			"dataInicio": "Data início",
			"contaIntermedia": "Conta intermédia",
			"calendario": "Calendário",
			"docFaturacao": "Doc. faturação",
			"dataDoc": "Data doc.",
			"docContabilidade": "Doc. contabilidade",
			"docContabilidadeLiquidacao": "Doc. contabilidade liquidação",
			"valorDD": "Valor déb. direto",
			"erroLiquidacao": "Erro liquidação",
			"dataHora": "Data / Hora",
			"utilizador": "Utilizador",
			"operacao": "Operação",
			"observacao": "Observação",
			"nDocFa": "Tipo doc.",
			"nNumer": "Nº série",
			"nDocumento": "Nº documento",
			"documento": "Documento",
			"valorEmAberto": "Valor em aberto",
			"enviadoEmail": "Notificação email",
			"xmlGerado": "XML gerado",
			"titularIBAN": "Titular IBAN",
			"tipoFicheiro": "Tipo ficheiro",
			"nLoteFicheiro": "Lote do ficheiro",
			"nLotePagamento": "Lote do pagamento",
			"firstAsSTR": "Seq. type",
			"nRegistos": "Nº registos",
			"fileName": "Ficheiro",
			"campo": "Campo",
			"mensagem": "Mensagem",
			"notificado": "Notificado",
			"liquidacao": "Liquidação",
			"devolucao": "Devolução",
			"comErro": "Com erro",
			"valorTotal": "Valor total",
			"nErroCG": "Nº erros CG",
			"importValorImputado": "Valor imputado",
			"valorLiquidacao": "Valor liquidação",
			"recibo": "Recibo",
			"autorizacao": "Autorização",
			"dataLiquidacao": "Data liquidação",
			"valorDoc": "Valor doc."
		},
		"strings": {
			"tabConfigGerais": "Config. gerais",
			"tabContasCredoras": "Contas credoras",
			"tabTiposDocumento": "Tipos documento",
			"tabClientes": "Clientes",
			"naoTemAcessoEstaConfiguracao": "Não tem acesso a esta configuração. Contacte o administrador de sistema.",
			"dataVencimento": "Data de vencimento",
			"nDiasPrimeiraComunicacao": "Nº de dias 1ª comunicação",
			"seguintes": "Seguintes",
			"dataLiquidacao": "Data de liquidação",
			"chaveNumeroAutorizacao": "Chave do número de autorização",
			"exemploChave": "Exemplo de chave",
			"modalTitleConfiguracao": "Débitos diretos SEPA - Configuração",
			"semDebitosDiretos": "Sem débitos diretos",
			"comDebitosDiretos": "Com débitos diretos",
			"modalTitleContaCredora": "Débitos diretos SEPA - Conta credora",
			"modalTitleConfigClientes": "Débitos diretos SEPA - Clientes aderentes",
			"integracaoEmContabilidade": "Integração em contabilidade",
			"oFormatoIDDebitoDiretoErrado": "O formato do \"ID Débito Direto\" não está de acordo com o exemplo. Tem a certeza que pretende continuar?",
			"haCamposNaoPreenchidos": "Há campos não preenchidos que podem originar erros no futuro. Tem a certeza que pretende continuar?",
			"titularConta": "Titular da conta",
			"nomeFichaCliente": "Nome da ficha do cliente",
			"outroNome": "Outro nome",
			"ibanCredor": "IBAN (Credor)",
			"contratos": "Contratos",
			"contrato": "Contrato",
			"autorizacao": "Autorização",
			"consultar": "Consultar",
			"temSelecionarConta": "Tem que selecionar a conta",
			"jaFoiEnviadaPrimeiraComunicacaoFRST": "Já foi enviada a primeira comunicação (FRST)",
			"emailDebitosDiretosObrigatorio": "O email para os débitos diretos é obrigatório.",
			"ibanClienteObrigatorio": "O IBAN do cliente é obrigatório.",
			"nContaObrigatorio": "O nº conta é obrigatório.",
			"nAutorizacaoDDObrigatorioGerarAutomaticamente": "O nº de autorização de débitos diretos é obrigatório. Pretende gerar automaticamente?",
			"dataInicioNaoPodeSerSuperiorDataFim": "A Data de Início não pode ser superior à Data Fim",
			"naoAnexouAutorizacaoPertendeContinuar": "Não anexou a autorização de débitos diretos. Tem a certeza que pretende continuar?",
			"manual": "Manual",
			"auto": "Auto",
			"descricao": "Descrição",
			"documento": "Documento",
			"documentoAutorizacao": "Documento autorização",
			"dataParaLiquidacaoSeraADataVencDocumento": "A data para liquidação será a data de vencimento do documento.",
			"dataParaLiquidacaoSeraCalcPelosDiasConfig": "A data para liquidação será calculada pelos dias configurados.",
			"dataParaLiquidacaoSeraCalcPelosDiasConfig2": "Atenção: Os dias indicados são aconselhados pelas instituições bancárias.",
			"contaIntermediaParaLiquidacao": "Conta intermédia para liquidação",
			"configSaved": "As configurações dos débitos diretos SEPA foram guardadas com sucesso.",
			"configClientesSaved": "As configurações dos clientes foram guardadas com sucesso.",
			"configClienteDeleted": "O cliente foi removido com sucesso.",
			"codMeioPagamentoExisteNaLista1": "O código meio pagamento selecionado já existe na configuração.",
			"codMeioPagamentoExisteNaLista2": "Se gravar, irá atualizar o registo existente.",
			"ficheirosEmitidos": "Ficheiros emitidos",
			"porLiquidar": "Por liquidar",
			"liquidado": "Liquidado",
			"emitido": "Emitido",
			"estado": "Estado",
			"valor": "Valor",
			"disponivelParaNovaEmissao": "Disponível para nova emissão",
			"documentoFaturacao": "Documento (Faturação)",
			"reciboLiquidacao": "Recibo (Liquidação)",
			"cliente": "Cliente",
			"historico": "Histórico",
			"logModalTitle": "Log do documento {{ndoc}}",
			"anexos": "Anexos",
			"comentarios": "Comentários",
			"ddsepaEmissaoPorContaCorrente": "Débitos diretos SEPA - Emissão por conta corrente",
			"gerarFicheiro": "Gerar ficheiro",
			"gerar": "Gerar",
			"resumo": "Resumo",
			"notificacao": "Notificação",
			"resumoPorContaCorrente": "Resumo por conta corrente",
			"registos": "Registos",
			"dataVencimentoAte": "Data vencimento até",
			"dataLiquidacaoFirst": "Data liquidação (1ª vez)",
			"ficheirosGerados": "Ficheiros gerados",
			"errosEAvisos": "Erros e avisos",
			"notificarDevedores": "Notificar devedores",
			"dadosAvancados": "Dados avançados",
			"apagado": "Apagado",
			"alterado": "Alterado",
			"novo": "Novo",
			"contaCredora": "Conta credora",
			"impressaoEmissoesModalTitle": "Impressão de emissões de débitos diretos - SEPA",
			"importadorModalTitle": "Débitos diretos SEPA - Importação",
			"processarFicheiro": "A processar o ficheiro ...",
			"importarFicheiro": "A importar o ficheiro ...",
			"dados": "Dados",
			"resumoLiquidacao": "Resumo da liquidação",
			"processadosComSucesso": "Processados com sucesso",
			"dadosDoFicheiro": "Dados do ficheiro",
			"errosImportacao": "Erros Importação",
			"importadosComSucesso": "Importados com sucesso",
			"importadosComErro": "Importados com erro",
			"ficheiroProcessadoComSucesso": "Ficheiro processado com sucesso",
			"resumoProcessamento": "Resumo processamento",
			"liquidados": "Liquidados",
			"devolvidos": "Devolvidos",
			"naoLiquidados": "Não liquidados",
			"imputado": "Imputado",
			"liquidadoSemImputacao": "Liquidado sem imputação",
			"devolvido": "Devolvido",
			"naoLiquidado": "Não liquidado",
			"valores": "Valores",
			"nDeRegistos": "Nº de registos",
			"erroLiquidacao": "Erro liquidação",
			"nDocumentos": "# Documentos",
			"dataEmissao": "Data emissão",
			"porNotificar": "Por notificar",
			"mostrar": "Mostrar",
			"xNaoTemAutorizacaoAnexada": "{{count}} cliente(s) não tem autorização anexada.",
			"obterAutorizacao": "Obter autorização",
			"mostrarTodosOsRegistos": "{{count}} cliente(s) configurados.",
			"dataLiquidacaoFRST": "Data de Liquidação (1º Vez)",
			"dataLiquidacaoRCUR": "Data de Liquidação (Seguintes)"
		},
		"messages": {
			"temCertezaMarcarRegistosSelDisponiveisParaEmissao": "Tem a certeza que deseja marcar os registos selecionados como disponíveis para nova emissão?",
			"temCertezaMarcarRegistoDisponiveisParaEmissao": "Tem a certeza que deseja marcar o registo como disponíveis para nova emissão?",
			"semRegistosSelecionados": "Tem de selecionar pelo menos um registo",
			"contaCredoraObrigatorio": "A conta credora é obrigatória",
			"nAutorizacaoObrigatorio": "O nº autorização é obrigatório",
			"desejaImprimir": "Deseja imprimir?",
			"devedoresNotificadosSucesso": "{{count}} devedores foram notificados com sucesso.",
			"noFiles": "Selecione o ficheiro para importar",
			"existemNdocumentosDisponiveisParaEmissao": "Existem {{count}} documentos disponíveis para emissão.",
			"naoTemAutorizacaoAnexada": "Não tem autorização anexada.",
			"alteracaoMeioPagamentoMsg": "Todos os clientes com débito direto associado ao meio de pagamento {{codMeioPagamento}} ({{nomeMeioPagamento}}) passam a estar associados ao novo meio de pagamento indicado e respetivo IBAN.",
			"alteracaoIbanMsg": "O meio de pagamento {{codMeioPagamento}} ({{nomeMeioPagamento}}) com IBAN \"{{iban}}\" irá ser atualizado para o novo IBAN indicado. Todos os clientes com débito direto associado IBAN atual passarão a estar associados ao IBAN indicado.",
			"clienteComDDAlterados": "{{countClientes}} cliente(s) com débito direto para a conta credora com IBAN: \"{{iban}}\" passará a ter como conta credora o IBAN: \"{{newIban}}\". Pretende continuar com a alteração?",
			"emailEnviadoComSucesso": "O e-mail foi enviado com sucesso.",
			"emailEnviadoComErro": "Ocorreu um erro ao enviar o e-mail. Por favor, tente novamente.",
			"temACertezaQuePertendeEnviarEmail": "Tem a certeza que pretende enviar o e-mail com formulário de adesão débito direto?",
			"temDocumentosPorLiquidarAteDataIni": "Tem {{count}} documentos por liquidar até a data de início.",
			"filtarRegistosQueNaoTemAutorizacao": "Mostrar clientes que não tem autorização anexada.",
			"temACertezaQuePertendeAnularFichEmitido": "Tem a certeza que pretende anular o ficheiro emitido: {{loteFicheiro}} ?",
			"haErrosNosDocumentos": "Há erros nos documentos para importação. Não é possível continuar.",
			"naoHaNenhumDadoParaImportar": "Não há nenhum dado para importar.",
			"soPodeAnexarPdf": "O tipo de ficheiro não é permitido. Permitido: .pdf",
			"mostrarTodosOsClientes": "Mostrar todos os clientes com e sem autorização anexada.",
			"naoPodeGerarFichComValorTotalZero": "Não pode gerar o ficheiro com valor total a 0.",
			"codMeioPagamentoObrigatorio": "O cód. meio pagamento é obrigatorio"
		},
		"tooltips": {
			"geradoAutomatico": "Gerado automático",
			"atencaoSoFicaraoDisponiveisDataDocSuperiorADataIni": "Atenção: Só ficarão disponíveis os débitos cujo a data de documento seja superior à data de inicio.",
			"ibanDiffDoMeioPagamento": "O IBAN \"{{iban}}\" é differente do IBAN \"{{ibanMeioPagamento}}\" do meio de pagamento {{codMeioPagamento}} ({{nomeMeioPagamento}}). Clique aqui para atualizar."
		},
		"tipoChaveAutorizacao": {
			"texto": "Texto",
			"conta": "Conta",
			"classeContrato": "Classe contrato",
			"tipoContrato": "Tipo contrato",
			"nContrato": "Nº contrato",
			"ibanCredor": "IBAN credor",
			"ibanCliente": "IBAN cliente",
			"dia": "Dia",
			"hora": "Hora",
			"minutos": "Minutos",
			"sequencial": "Sequencial"
		}
	},
	"declaracaoiva": {
		"configured": "<i class='fa fa-check' aria-hidden='true'></i> O serviço já se encontra configurado.",
		"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> O serviço não está configurado.",
		"btns": {
			"getXML": "Obter ficheiro",
			"getRecapitulativaXML": "Obter ficheiro recapitulativa",
			"btnValidarNaAT": "Validar na AT",
			"btnConfiguracao": "Configuração",
			"repor": "Repor declaração",
			"previsualizar": "Pré-visualizar",
			"balancete": "Balancete",
			"documentosFiscais": "Documentos fiscais",
			"entregarDeclaracao": "Entregar",
			"configATLogin": "Configuração AT"
		},
		"tooltips": {
			"btnGuardarNotValid": "Para guardar o quadro 4 tem que estar devidamente preenchido",
			"btnGuardar": "A declaração tem de ser anulada para poder gravar novamente",
			"btnGetXML": "A declaração tem de ser guardada para obter o xml da declaração",
			"btnGetRecapitulativaXML": "A declaração tem de ser guardada para obter o xml da declaração recapitulativa",
			"validarNaAT": "A declaração tem de ser guardada para poder efetuar operações na AT",
			"guardarPrimeiroParaRealizarOperacao": "A declaração tem de ser guardada para poder realizar esta operação"
		},
		"messages": {
			"successValidarNaAt": "A declaração não apresenta erros e/ou alertas.",
			"successSubmeterNaAt": "A declaração periódica de IVA foi submetida com sucesso, via webservice da AT. Devido a possíveis anomalias no portal da AT, deve verificar se foi efetivamente submetida e obter o respectivo comprovativo.",
			"help": "<strong>Se for necessário efetuar alterações na declaração do IVA deve:</strong></br>1 - Proceder à exportação do ficheiro xml clicando no botão \"Obter ficheiro xml\";</br>2 - Aceder ao site da Autoridade Tributária: <a class=\"text-white\" href=\"https://iva.portaldasfinancas.gov.pt/dpiva/portal/entregar-declaracao\"><strong>link de acesso</strong></a>;</br>3 - Importar o ficheiro xml previamente descarregado no espaço indicado.</br>Agora poderá efetuar todas as alterações necessárias e submeter o ficheiro.",
			"submitAtPromptMessage": "Tem a certeza que deseja submeter a declaração",
			"anuladasuccess": "Declaração anulada!",
			"temDeGuardar": "Tem de guardar os dados para poder criar o ficheiro",
			"savedsucessfully": "Declaração guardada",
			"errorTextDataGridRowNotOk": "Obrigatório todos os dados da linha estarem preenchidos",
			"entreguesuccess": "A Declaração recapitulativa de IVA foi entregue com sucesso",
			"validateOperacoesSedeValoresIguais": "Valores dos campos 63 e 64 do {{nomeAnexo}} não coincidem com os valores dos campos {{campo1}} e {{campo2}} do anexo sede. Declaração de IVA não foi guardada."
		},
		"showmessage": {
			"title": "Não voltar a mostrar",
			"message": "\"Ok\" se não deseja voltar a ver esta mensagem!"
		},
		"text": {
			"attachment": "Anexo",
			"saved": "gravada"
		},
		"tabs": {
			"anexos": "Anexos",
			"rosto": "Rosto",
			"anexoRContinente": "Anexo R continente",
			"anexoRAcores": "Anexo R açores",
			"anexoRMadeira": "Anexo R madeira",
			"anexoCampo40": "Anexo campo 40",
			"anexoCampo41": "Anexo campo 41",
			"adicionarAnexo": "Adicionar Anexo",
			"sede": "(Sede)"
		},
		"anexos": {
			"comum": {
				"sim": "Sim",
				"nao": "Não"
			},
			"rosto": {
				"title": "Início",
				"quadro0": {
					"desc1": "Identificação do sujeito passivo",
					"nif": "Número de identificação fiscal",
					"locSede": "Localização da sede",
					"desc2": "Identificação da declaração",
					"periodo": "Período em curso",
					"prazodecl": "Prazo da declaração",
					"dentroprazo": "Dentro do prazo",
					"foraprazo": "Fora do prazo",
					"desc3": "Operações em espaço diferente do da sede",
					"continente": "Continente",
					"acores": "Açores",
					"madeira": "Madeira",
					"desc4A": "Declaração recapitulativa",
					"check4A": "Assinale, se, no período de referência, apresentou alguma declaração recapitulativa",
					"desc5": "Inexistência de operações",
					"check5": "Se no período a que respeita a declaração, não realizou operações passivas nem ativas que devam constar do quadro 06, assinale neste quadro e passe já ao quadro 20",
					"desc20": "Contabilista certificado",
					"nifcert": "NIF do contabilista certificado"
				}
			},
			"anexoR": {
				"extratodecperio": "Extrato de campo da declaração periódica.",
				"valorReembolsoSolicitado": "Solicitou reembolso de IVA, mas ainda não processou as relações de reembolso.",
				"quadro6": {
					"title": "Apuramento",
					"desc": "Efetuou operações desta natureza em que, na qualidade de adquirente, liquidou o imposto? (valores incluídos nos campos 1, 5, 3 ou 9)",
					"point1": "A que se referem as alíneas a), b) e c) do art.º 42.º do CIVA",
					"point2": "A que se referem as alíneas f) e g) do n.º 3 do art.º 3.º e alíneas a) e b) do n.º 2 do art.º 4.º do CIVA",
					"desc1": "Transmissões de bens e prestações de serviços efetuados em que liquidou imposto",
					"basetribut": "Base tributável",
					"impfavorestado": "Imposto a favor do estado",
					"field1e2": "À taxa reduzida",
					"field5e6": "À taxa intermédia",
					"field3e4": "À taxa normal",
					"isentaounaotrib": "Isentas ou não tributadas",
					"field7": "Transmissões intracomunitárias de bens e prestações de serviços mencionadas nas declarações recapitulativas",
					"field8": "Operações que conferem direito à dedução",
					"field9": "Operações que não conferem direito a dedução",
					"desc2": "Aquisições intracomunitárias de bens e operações assimiladas",
					"field12e13": "Cujo imposto foi liquidado pelo declarante",
					"field14": "Abrangidas pelos artigos 15.º do CIVA ou do RITI",
					"field15": "Abrangidas pelos n.ºs 3, 4 e 5 do art.º 22.º do RITI",
					"field10e11": "Totais",
					"desc3": "Serviços efetuados por sujeitos passivos de outros Estados membros cujo imposto foi liquidado pelo declarante",
					"desc4": "Importações de bens cujo imposto foi liquidado pelo declarante",
					"field62": "Total da base tributável",
					"desc5": "Imposto dedutível",
					"impostfavSujPassivo": "Imposto a favor do suj. passivo",
					"field20": "Ativos não correntes (imobilizado)",
					"inventarios": "Inventários (existências)",
					"field21": "À taxa reduzida",
					"field23": "À taxa intermédia",
					"field22": "À taxa normal",
					"field24": "Outros bens e serviços",
					"desc6": "Regularizações mensais/trimestrais e anuais",
					"desc6NotSedeAnexoTotalPassivo": "Total imposto a favor do sujeito passivo",
					"desc6NotSedeAnexoTotalImposto": "Total imposto a favor do Estado",
					"desc7": "Excesso a reportar do período anterior",
					"field61": "Campo 96 da declaração anterior - n.º 4 do art.º 22.º",
					"desc8": "Anexo R (1) Operações em espaço diferente do da sede",
					"impdedutivel": "Imposto dedutível",
					"impliquidado": "Imposto liquidado",
					"desc9": "Anexo R (2) Operações em espaço diferente do da sede",
					"desc10": "Regularizações a favor do Sujeito passivo comunicadas pela DS Cobranças (Mod. - BH008)",
					"desc11": "Totais",
					"field90": "Total da base tributável",
					"field91": "Total imposto a favor do sujeito passivo",
					"field92": "Total imposto a favor do Estado",
					"field93": "Imposto a entregar ao Estado",
					"field94": "Crédito de imposto a recuperar",
					"field95": "Solicito o reembolso",
					"field96": "Excesso a reportar"
				},
				"quadro6A": {
					"title": "Desenvolvimento",
					"descA": "Operações localizadas em Portugal em que, na qualidade de adquirente, liquidou o IVA devido",
					"efetuadentresident": "Efetuadas por entidades residentes em",
					"basetrib": "Base tributável",
					"field97": "Países comunitários",
					"field98": "Territórios terceiros",
					"descB": "Operações em que liquidou o IVA devido por aplicação da regra de inversão do sujeito passivo",
					"field99": "Ouro",
					"field100": "Aquisições de imóveis com renúncia à isenção",
					"field101": "Sucatas",
					"field102": "Serviços de construção civil",
					"field105": "Emissão de gases com efeito de estufa",
					"field107": "Aquisição de cortiça e outros produtos de origem silvícola",
					"descC": "Operações referidas nas alíneas f) e g) do n.º 3 do artigo 3º e alíneas a) e b) do n.º 2 do artigo 4º do CIVA",
					"field103": "Se efetuou operações desta natureza, indique o seu valor",
					"descD": "Operações referidas nas alíneas a), b) e c) do artigo 42º do CIVA",
					"field104": "Se efetuou operações desta natureza, indique o seu valor",
					"soma6A": "Soma do quadro 06-A",
					"desc10": "Operações referidas nas alíneas f) e g) do n.º 3 do Art.º 3º e nas alíneas a) e b) do n.º 2 do Art.º 4º",
					"field264": "Se sim, indique o montante do imposto liquidado, sem prejuízo de o mesmo constar dos campos 2, 6 ou 4 do Q.06"
				},
				"modal": {
					"title": "Extrato de campo da declaração periódica - Campo {{campo}}",
					"fields": {
						"periodo": "Período",
						"nDiario": "Nº diário",
						"nDocInterno": "Nº documento",
						"nConta": "Nº conta",
						"nContrib": "Nº contribuinte",
						"valorDebito": "Valor débito",
						"valorCredito": "Valor crédito",
						"codIva": "Cód. IVA",
						"areaRegionalStr": "Area regional IVA",
						"nCampoOficial": "Campo oficial",
						"contaOrigem": "Conta base tributável",
						"nomeContaOrigem": "Nome base tributável",
						"valorBase": "Valor base"
					}
				}
			},
			"anexoCampo40": {
				"title": "Regularizações",
				"desc1": "Regularizações a favor do sujeito passivo",
				"desc2": "Regularizações cujo crédito não seja superior a €750, IVA incluído",
				"baseincidenciareg": "Base de incidência da regularização",
				"ivareg": "IVA regularizado",
				"field3e4": "Valor do crédito não seja superior a €750",
				"desc3": "Outras regularizações",
				"field3Ae4A": "Regularizações abrangidas pelos art.ºs 23º a 26º",
				"field3Be4B": "Outras regularizações (Ex: fusão)",
				"desc4": "Valor total do campo 40",
				"desc5": "Certificação por Revisor Oficial de Contas (ROC)",
				"certirevisoroficialcontas": "Certificação por revisor oficial de contas",
				"nifroc": "Número de identificação fiscal do ROC",
				"modal": {
					"roc": {
						"title": "Número de identificação fiscal do ROC",
						"naopodeservazio": "O número de identificação fiscal do ROC não pode ser vazio.",
						"jaexiste": "Já existe o número de identificação fiscal do ROC inserido."
					}
				}
			},
			"anexoCampo41": {
				"title": "Regularizações",
				"desc1": "Regularizações a favor do estado",
				"desc2": "Outras regularizações",
				"baseincidenciareg": "Base de incidência da regularização",
				"ivareg": "IVA regularizado",
				"field3Ae4A": "Regularizações abrangidas pelos art.ºs 23º a 26º",
				"field3Be4B": "Outras regularizações",
				"field3Ce4C": "Artigo 6.º do Decreto-Lei n.º 198/90, de 19 de junho",
				"field3De4D": "Decreto-Lei n.º 19/2017, de 14 de fevereiro",
				"desc3": "Valor Total do Campo 41"
			},
			"table40e41": {
				"fields": {
					"tipoRegularizacaoCampo": "Tipo de regularização",
					"nif": "Nº contribuinte",
					"nPedido": "Nº do pedido",
					"baseIncidencia": "Base de incidência da regularização",
					"ivaRegularizado": "IVA regularizado",
					"dataEmissao": "Data emissão"
				}
			}
		},
		"recapitulativa": {
			"title": "Declaração de IVA recapitulativa",
			"table": {
				"paisDestinoStr": "País destino (2)",
				"nContribuinte": "NIF do adquirente (3)",
				"valorOperacoes": "Valor em EURO (4)",
				"tipoOperacaoStr": "Tipo de operação (5)"
			},
			"quadros": {
				"quadro1": {
					"title": "Quadro 01",
					"desc1": "Identificação do sujeito passivo",
					"nif": "Número de identificação fiscal"
				},
				"quadro2": {
					"title": "Quadro 02",
					"desc": "Tipo de declaração",
					"primeira": "Primeira",
					"substituicao": "Substituição",
					"desc1": "Houve alteração de periodicidade de envio de trimestral para mensal?",
					"field21": "Alteração de periodicidade de envio de trimestral para mensal",
					"desc21": "O valor mensal substitui ou não totalmente o anterior valor trimestral declarado?",
					"field22": "Inexistência de operações intracomunitárias no respetivo período",
					"field23": "Outras alterações em operações intracomunitárias",
					"field24": "Transferências de bens à consignação",
					"field241": "Sem alterações no respetivo período",
					"field2411": "Sem transferências de bens à consignação declaradas",
					"field2412": "Com transferências de bens à consignação declaradas",
					"field242": "Com alterações no respetivo período",
					"field243": "Declara pela 1ª vez no respetivo período"
				},
				"quadro3": {
					"title": "Quadro 03",
					"desc": "Período a que respeita",
					"mensal": "Mensal",
					"trimestral": "Trimestral",
					"mesincltrimestre": "Mês(es) incluído(s) no trimestre"
				},
				"quadro45": {
					"title": "Quadro 04/05",
					"desc4": "Descrição das operações intracomunitárias",
					"modalTitle": "Descrição das operações intracomunitárias",
					"modalmessages": {
						"tipoopvazio": "O tipo de operação não pode ser vazio.",
						"paisdestvazio": "O país de destino não pode ser vazio."
					},
					"table": {
						"prefpaisdest": "Prefixo do país de destino (2)",
						"nif": "NIF do adquirente (3)",
						"valor": "Valor (4)",
						"tipoop": "Tipo de operação (5)"
					},
					"desc5": "Soma dos valores declarados no quadro 4 (agrupados por tipo de operação)",
					"field10": "Soma (apenas dos valores que respeitem às operações tipificadas na coluna 5 com 1)",
					"field11": "Total das vendas de meios de transporte novos a particulares e equiparados de outros estados membros",
					"field17": "Soma (apenas dos valores que respeitem às operações indicadas na coluna 5 com 4)",
					"field18": "Soma (apenas dos valores que respeitem às operações indicadas na coluna 5 com 5)",
					"field19": "Total (10+11+18)"
				},
				"quadro6": {
					"title": "Quadro 06",
					"desc6": "Descrição das transferências de bens à consignação",
					"table": {
						"prefpaisdest": "Prefixo do país de destino (2)",
						"nif": "NIF do destinatário (3)",
						"nifOriginal": "NIF do destinatário original (4)",
						"codigo": "Código (5)"
					}
				}
			}
		}
	},
	"demfinsnc": {
		"fields": {
			"processafluxo": "Processa fluxos de caixa",
			"vercomdados": "Ver apenas rúbricas com valores",
			"processaIntercalares": "Processa demonstrações intercalares",
			"variaveisapuramento": "Variáveis de apuramento intercalar",
			"periointercalartable": "Inventário final do período intercalar do ano \"{{ano}}\""
		},
		"tabs": {
			"balancoTitle": "Balanço",
			"demresultNatTitle": "DR por naturezas",
			"demresultFuncTitle": "DR por funções",
			"demfluxocaixaTitle": "Demonstração dos fluxos de caixa",
			"demAltCPTitle": "DACP {{dataDemAltCP}}",
			"demAltCP2Title": "DACP {{dataDemAltCP2}}",
			"intContaTitle": "Intervalos contas",
			"balanceteTitle": "Balancete",
			"fluxosCaixaTitle": "Fluxos de caixa",
			"table": {
				"nContaDe": "Nº conta de",
				"nContaAte": "Nº conta até",
				"mesPeriodo": "Mês período",
				"nConta": "Nº conta",
				"nomeConta": "Nome",
				"designacao": "Designação",
				"codFluxo": "Cód.",
				"parent": {
					"parent0": "Ativo",
					"parent1": "Capital Próprio",
					"parent2": "Passivo"
				}
			},
			"capitalRealizado": "Capital",
			"accoesQuotasProprias": "Acções",
			"premiosDeEmissao": "Prémios",
			"reservasLegais": "Reservas",
			"outrasReservas": "Outras",
			"resultadosTransitados": "Resultados",
			"ajustamentosEmActivosFinanceirosOutrasVariacoes": "Ajustamentos",
			"excedentesDeRevalorizacao": "Excedentes",
			"resultadoLiquidoDoPeriodo": "Resultado",
			"outrosInstrumentosCapProprio": "Outros"
		},
		"radio": {
			"sncncrf": "SNC - NCRF",
			"microentidade": "Microentidades"
		},
		"btn": {
			"notas": "Notas",
			"valdefeito": "Valores por defeito",
			"previsualizargrid": "Processar em grelha",
			"demfinsncsaved": "Versões Dem. Fin. guardadas",
			"repvaloriginal": "Repor todos os valores originais",
			"periodo": "Período intercalar: ",
			"anexoRelatorioGestao": "Anexo / Relatório Gestão"
		},
		"modal": {
			"title": "Notas de Demonstrações Financeiras",
			"titlelistsaved": "Versões de Demonstrações Financeiras guardadas",
			"titleobs": "Indique observações",
			"titlepreviewpdf": "Demonstrações financeiras SNC",
			"obs": "Observações",
			"badgecaptionsaved": "Demonstração financeira selecionada",
			"tabs": {
				"table": {
					"rubrica": "Rúbricas",
					"notas": "Notas"
				},
				"balanco": {
					"title": "Balanço",
					"tab": {
						"titles": {
							"passivo": "Passivo"
						},
						"ativonaocorrente": "Ativo não corrente",
						"ativocorrente": "Ativo corrente",
						"capitalproprio": "Capital próprio",
						"passivonaocorrente": "Passivo não corrente",
						"passivocorrente": "Passivo corrente"
					}
				},
				"demresult": {
					"title": "Demonstração dos Resultados",
					"pornaturezas": "Por naturezas",
					"porfuncoes": "Por funções"
				},
				"capproprioperio": {
					"title": "Capital próprio do Período",
					"demaltcptproprio": "Dem. das alteraçoes no capital próprio do périodo"
				},
				"fluxocaixa": {
					"title": "Fluxos de Caixa",
					"atvop": "Atividades operacioanais",
					"atvinvest": "Atividades de investimento",
					"atvfin": "Atividades de financiamento"
				}
			},
			"listsaved": {
				"hora": "Hora",
				"obs": "Observações"
			},
			"message": {
				"valdef": "<b>Atençao:</b> Se continuar com esta operação vai repor os valores por defeito. <br> Tem a certeza que pretende continuar?",
				"delidsaved": "Apagar demonstração financeira guardada anteriormente?",
				"badgecaptioncellmanuallyedited": "Célula editada manualmente pelo utilizador",
				"delversaodemfinant": "Versão de demonstração financeira apagada com sucesso!",
				"savedemfin": "Demonstração financeira SNC guardada com sucesso!"
			}
		},
		"messages": {
			"periodoempty": "Período não pode ser vazio.",
			"notastemdeserumnumero": "Notas tem de ser um número"
		},
		"language": {
			"language0": "Português",
			"language1": "Inglês",
			"idioma": "Idioma: {{idioma}}"
		}
	},
	"depto": {
		"title_detail": "Departamento {{id}}",
		"title_new": "Novo Departamento",
		"title_plural": "Departamentos",
		"pesquisa": "Pesquisar",
		"saved": "Departamento {{id}}, guardado com sucesso.",
		"error": "Departamento {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"nDepto": "Cód.",
			"nDeptoPlaceholder": "Código do departamento",
			"nome": "Nome",
			"nomePlaceholder": "Nome do departamento"
		}
	},
	"descritivos": {
		"title_detail": "Descritivo de contabilidade {{id}}",
		"title_new": "Novo descritivo de contabilidade",
		"title_plural": "Descritivos de Contabilidade",
		"pesquisa": "Pesquisar",
		"saved": "Descritivo de contabilidade {{id}}, guardado com sucesso.",
		"error": "Descritivo de contabilidade {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Descritivo de contabilidade {{id}}, apagado com sucesso.",
		"fields": {
			"nDescrit": "Cód.",
			"nDescritPlaceholder": "Código do descritivo de contabilidade",
			"nome": "Nome",
			"nomePlaceholder": "Nome do descritivo de contabilidade",
			"descricao": "Descrição",
			"descricaoPlaceholder": "Descrição do descritivo de contabilidade",
			"tipoDescritivo": "Tipo",
			"tipoDescritivoPlaceholder": "Tipo do descritivo de contabilidade"
		}
	},
	"dgemps": {
		"title_detail": "Colaborador {{id}}",
		"title_new": "Novo Colaborador",
		"title_plural": "Colaboradores",
		"pesquisa": "Pesquisar",
		"saved": "Colaborador {{id}} guardado com sucesso",
		"error": "Colaborador {{id}} não pode ser guardado. Por favor, verifique os campos.",
		"fields": {
			"apelido": "Apelido",
			"apelidoPlaceholder": "Apelido do colaborador",
			"codEmp": "Colab.",
			"codEmpPlaceholder": "Código do colaborador",
			"codPostal": "Cód. postal",
			"codPostalPlaceholder": "Cód. postal do colaborador",
			"codservicoprh": "Serviço",
			"codservicoprhPlaceholder": "Código do serviço",
			"email": "E-mail",
			"emailPlaceholder": "E-mail do colaborador",
			"formRemun": "Forma de remuneração",
			"formRemunPlaceholder": "Forma de remuneração",
			"local": "Local",
			"masterDetail": {
				"antigo": "Valor antigo",
				"campo": "Campo",
				"novo": "Valor novo"
			},
			"morada": "Morada",
			"moradaPlaceholder": "Morada do colaborador",
			"nTelefone": "Nº telefone",
			"nTelefonePlaceholder": "Nº telefone do colaborador",
			"nome": "Nome",
			"nomeCategoria": "Categoria",
			"nomeCategoriaPlaceHolder": "Categoria",
			"nomeCompleto": "Nome completo",
			"nomeLocalida": "Localidade",
			"nomePlaceholder": "Nome do colaborador",
			"nomelocalidaPlaceholder": "Localidade do colaborador",
			"operacao": "Operação",
			"rua": "Rua",
			"ruaPlaceholder": "Rua do colaborador",
			"telemovelPart1": "Telemóvel",
			"telemovelpart1Placeholder": "Telemóvel do colaborador",
			"tipoRefeição": "Tipo de refeição"
		},
		"recibos": {
			"title": "Recibo de vencimento",
			"fields": {
				"nrecibo": "Nº recibo",
				"nprocessamento": "Nº processamento",
				"dataprocessamento": "Data de processamento",
				"datarecibo": "Data do recibo",
				"lido": "Lido"
			}
		},
		"sexes": {
			"0": "Feminino",
			"1": "Masculino",
			"2": "N/A"
		},
		"taxResidence": {
			"0": "Residente em território português",
			"1": "Não residente que aufere rendimentos numa única entidade empregadora",
			"2": "Não residente que aufere rendimentos em mais de uma entidade empregadora"
		},
		"workRegime": {
			"0": "Privado",
			"1": "Função pública"
		},
		"salaryCalculationMethod": {
			"0": "N/A",
			"1": "Por vencimento base",
			"2": "Por horas de trabalho"
		},
		"twelfthsCalculationType": {
			"0": "Subs. Natal e Subs. férias",
			"1": "Subsídio de Natal",
			"2": "Subsídio de férias",
			"3": "Não efetua processamento"
		},
		"twelfthsValuesType": {
			"0": "0",
			"50": "50",
			"100": "100"
		},
		"home": {
			"altDeFichasPorAprov": "Tem alterações de fichas de colaborador por aprovar",
			"pedidosFeriasPorAprov": "Tem pedidos de férias por aprovar no(s) serviço(s)",
			"faltasPorAprov": "Tem faltas por aprovar no(s) serviço(s)",
			"abonosPorAprov": "Tem abonos por aprovar no(s) serviço(s)",
			"intFerias": "Intervalo de férias",
			"intFaltas": "Intervalo de faltas",
			"recibos": "Recibos",
			"comunicacoes": "Comunicações",
			"ferias": "Férias",
			"feriasMarc": "Férias marcadas",
			"feriasAprov": "Férias aprovadas",
			"feriasReje": "Férias rejeitadas",
			"faltas": "Faltas",
			"faltasMarc": "Faltas marcadas",
			"faltasAprov": "Faltas aprovadas",
			"faltasReje": "Faltas rejeitadas",
			"aprovadas": "Aprovadas",
			"restantes": "Restantes",
			"marcadas": "Marcadas",
			"rejeitadas": "Rejeitadas"
		}
	},
	"dgempsfull": {
		"title_detail": "Colaborador {{id}}",
		"title_new": "Novo Colaborador",
		"title_plural": "Colaboradores",
		"pesquisa": "Pesquisar",
		"saved": "Colaborador {{id}} guardado com sucesso",
		"error": "Colaborador {{id}} não pode ser guardado. Por favor, verifique os campos.",
		"deleted": "Colaborador {{id}} apagado com sucesso",
		"btn": {
			"configWSS": "Configuração WSS"
		},
		"tabelaIRSColaboradoresPorValidar": "Por favor, valide a tabela de IRS dos colaboradores:",
		"fields": {
			"morada": "Rua",
			"moradaPlaceholder": "Rua do colaborador",
			"nTelefone": "Telefone",
			"nTelefonePlaceholder": "Telefone do colaborador",
			"dtMotMov": "Última atualização cadastro",
			"dtMotMovPlaceholder": "Data de cadastro do colaborador",
			"situacao": "Situação",
			"situacaoPlaceholder": "Situação do colaborador",
			"sexo": "Sexo",
			"dtNascimento": "Data de nascimento",
			"dtNascimentoPlaceholder": "Data de nascimento do colaborador",
			"nacionalidad": "Nacionalidade",
			"nacionalidadPlaceholder": "Nacionalidade do colaborador",
			"concelho": "Concelho",
			"concelhoPlaceholder": "Concelho do colaborador",
			"freguesia": "Freguesia",
			"freguesiaPlaceholder": "Freguesia do colaborador",
			"nbIdentidade": "Nº cartão de cidadão",
			"nbIdentidadePlaceholder": "Nº cartão de cidadão do colaborador",
			"dtBiValidade": "Validade cartão de cidadão",
			"dtBiValidadePlaceholder": "Data validade do cartão de cidadão/bilhete de identidade",
			"numSegSoc": "NISS",
			"numSegSocPlaceholder": "Nº identificação da segurança social do colaborador",
			"numContrib": "NIF",
			"numContribPlaceholder": "Nº identificação fiscal do colaborador",
			"nCartaoTicketRef": "Nº cartão refeição",
			"nCartaoTicketRefPlaceholder": "Nº de cartão de refeição do colaborador",
			"banco": "Banco do colaborador",
			"iban": "IBAN do colaborador",
			"swift": "SWIFT do colaborador",
			"codNHABQPessoal": "Nível habilitação",
			"codNHABQPessoalPlaceholder": "Nível de habilitação do colaborador",
			"dtAdmEmpresa": "Data de admissão",
			"dtAdmEmpresaPlaceholder": "Data de admissão do colaborador",
			"vencimBase": "Vencimento base",
			"vencimBasePlaceholder": "Vencimento base do colaborador",
			"nDiuturnidad": "Número de diuturnidades",
			"valorUnDiutu": "Valor de cada diuturnidade",
			"horasDia": "Horas de trabalho p/ dia",
			"horasDiaPlaceholder": "Horas de trabalho por dia",
			"horasSemana": "Horas de trabalho p/ semana",
			"horasSemanaPlaceholder": "Horas de trabalho por semana",
			"horasMes": "Horas de trabalho p/ mês",
			"horasMesPlaceholder": "Horas de trabalho por mês",
			"remunHora": "Remuneração hora",
			"temSubAlim": "Tem subsídio",
			"valorSubAlim": "Valor diário",
			"temPreProcessamentoSubAlimen": "Tem pré-processamento",
			"valorSFerias": "Valor do subsídio de férias",
			"valorSFeriasPlaceholder": "Valor do subsídio de férias do colaborador",
			"valorSNatal": "Valor do subsídio de natal",
			"valorSNatalPlaceholder": "Valor do subsídio de natal do colaborador",
			"metodPaga": "Método cálculo do salário",
			"divisaoTrab": "Divisão (duração) trabalho",
			"divisaoTrabPlaceholder": "Divisão (duração) trabalho do colaborador",
			"tipoContrato": "Tipo de contrato",
			"tipoContratoPlaceholder": "Tipo de contrato do colaborador",
			"dtIniContrat": "Data de assinatura",
			"dtIniContratPlaceholder": "Data de assinatura do colaborador",
			"dtFimContrat": "Data de renovação do contrato",
			"dtFimContratPlaceholder": "Data de renovação do contrato do colaborador",
			"dataEfetividade": "Data efetividade",
			"dataEfetividadePlaceholder": "Data de efetividade do contrato do colaborador",
			"temSegurAcTr": "Tem seguro de acidente de trabalho",
			"nSeguradAcTr": "Seguradora de acidente de trabalho",
			"nSeguradAcTrPlaceholder": "Seguradora de acidente de trabalho do colaborador",
			"caixaPrev": "Centro registo segurança social",
			"caixaPrevPlaceholder": "Centro registo segurança social do colaborador",
			"classeCaixa": "Regime segurança social",
			"classeCaixaPlaceholder": "Regime segurança social do colaborador",
			"nTabIrs": "Tabela de IRS",
			"nTabIrsPlaceholder": "Tabela IRS do colaborador",
			"numDepend": "Nº de dependentes sem deficiência",
			"numDependPlaceholder": "Nº total de dependentes sem deficiência do colaborador",
			"numDependDef": "Nº de dependentes com deficiência",
			"numDependDefPlaceholder": "Nº total de dependentes deficientes com deficiência do colaborador",
			"numDependDefLabel": "Número de dependentes deficientes com incapacidade permanente igual ou superior a 60%",
			"regimeTrabalho": "Regime de trabalho",
			"tipoCalcDuodecimos": "Cálculo do valor",
			"calcDuodecVariavel": "Cálculo de duodécimos sobre valores variáveis",
			"eExResidente": "Tributação sobre ex-residentes",
			"eExResidenteLabel": "Sujeitos passivos que se tornam fiscalmente residentes nos termos dos n.ºs 1 e 2 do artigo 16.º do CIRS",
			"nPocRemuLiq": "Conta contabilística onde se lança o valor a receber",
			"classeTrab": "Classe trabalho",
			"classeTrabPlaceholder": "Classe trabalho do colaborador",
			"funcao": "Função",
			"funcaoPlaceholder": "Função do colaborador",
			"seccao": "Operação/secção",
			"seccaoPlaceholder": "Operação/secção do colaborador",
			"nZona": "Zona",
			"nZonaPlaceholder": "Zona do colaborador",
			"departamento": "Departamento",
			"departamentoPlaceholder": "Departamento do colaborador",
			"nSubDe": "Subdepartamento",
			"nSubDePlaceholder": "Subdepartamento do colaborador",
			"ccusto": "Centro de custo",
			"ccustoPlaceholder": "Centro de custo do colaborador",
			"nCodRepCC": "Repartição ponto pessoal",
			"nCodRepCCPlaceholder": "Repartição ponto pessoal do colaborador",
			"categoria": "Categoria",
			"categoriaPlaceholder": "Categoria do colaborador",
			"profissao": "Profissão",
			"profissaoPlaceholder": "Profissão do colaborador",
			"sitProfissao": "Situação profissional",
			"sitProfissaoPlaceholder": "Situação profissional do colaborador",
			"codcct": "Contrato colectivo trabalho",
			"codcctPlaceholder": "Contrato colectivo trabalho do colaborador",
			"nivQualific": "Nível de qualificação",
			"nivQualificPlaceholder": "Nível de qualificação do colaborador",
			"motivoRegisto": "Motivo última atualização",
			"situacaoEmprego": "Situação do colaborador",
			"residenciaFiscal": "Residência fiscal",
			"nCodABVencimento": "Vencimento",
			"nCodABSubAlimentacao": "Subsídio de alimentação",
			"nCodABDiuturnidades": "Diuturnidades",
			"nCodABSubFerias": "Subsídio de férias",
			"nCodABSubNatal": "Subsídio de natal",
			"nCodABFerias": "Férias",
			"nCodDescIRS": "IRS",
			"codRepFinancas": "Repartição de finanças",
			"codRepFinancasPlaceholder": "Repartição de finanças",
			"conjugeIncapacidad60": "Cônjuge com incapacidade igual ou superior a 60%",
			"temDescIRSTxFixa": "Aplica desconto taxa fixa IRS",
			"percDescTaxaFixaIRS": "Taxa Fixa IRS (Taxa Marginal)",
			"deficienteForcasArmadas": "Deficiente forças armadas",
			"naoEfetuaFCT": "Fundos de compensação",
			"naoEfetuaFCTLabel": "Não efetua cálculo fundos de compensação",
			"sindicato": "Sindicato",
			"ncartsindicato": "Nº cartão do sindicato",
			"temRendJovensTrab": "Tem isenção parcial dos rendimentos",
			"temRendResNaoHabit": "Tem isenção residentes não habitual",
			"dtInicio": "Data início",
			"crlCalcLiquido": "Faz cálculo pelo rendimento líquido",
			"crlValorLiquido": "Valor líquido",
			"crlValorLiquidoEmMe": "Valor líquido em moeda estrangeira",
			"crlAbonoLiquido": "Abono",
			"nDiasFeriasAnoAnteri": "Dias férias ano anterior",
			"duodecimosConfigEmpresa": "Duodécimos configurados por empresa",
			"usaDuodecimosPorEmp": "Usa duodécimos por empregado",
			"duodecimosSubNatal": "Sub. natal",
			"duodecimosSubFerias": "Sub. férias",
			"temRedIrsCredHabitac": "Aplica redução sujeitos com crédito à habitação para habitação própria e permanente (medida em vigor só durante o ano de 2023)",
			"temRedCtrArrendameHP": "Aplica redução sujeitos para titulares de contrato de arrendamento/crédito para habitação permanente",
			"localTributacaoRendi": "Residência",
			"situacaoCivilTitular": "Situação",
			"naturezaRendimento": "Tipo Rendimento"
		},
		"groups": {
			"estadoSS": {
				"title": "Estado segurança social",
				"naoVerificado": "Não verificado",
				"verificar": "Verificar",
				"registar": "Registar",
				"consultar": "Consultar"
			},
			"addressContacts": {
				"title": "Morada e contactos"
			},
			"identification": {
				"title": "Identificação"
			},
			"bankData": {
				"title": "Dados bancários"
			},
			"taxData": {
				"title": "Dados fiscais",
				"sections": {
					"irs": "IRS",
					"socialSecurity": "Segurança social",
					"financas": "Finanças",
					"rendJovensTrab": "Isenção parcial dos rendimentos do trabalho auferido por sujeitos passivos com idades entre os 18 e os 26 anos",
					"titCredHab": "Redução das retenções na fonte para titulares de contratos",
					"temRendResNaoHabit": "Residente não habitual - Isenção de rendimentos regime fiscal durante 10 anos"
				}
			},
			"contractualData": {
				"title": "Dados contratuais",
				"sections": {
					"timeAndPlaceOfWork": "Horário e local de trabalho",
					"remuneration": "Remuneração",
					"calcPorRendLiq": "Cálculo por rendimento líquido"
				}
			},
			"professionalData": {
				"title": "Dados profissionais"
			},
			"subsidies": {
				"title": "Subsídios e seguro",
				"sections": {
					"food": "Subsídio de alimentação",
					"vacations": "Subsídio de férias",
					"christmas": "Subsídio de natal",
					"workAccidentInsurance": "Seguro de acidentes de trabalho"
				}
			},
			"twelfths": {
				"title": "Duodécimos",
				"sections": {
					"rulesProcessingTwelfths": "Regras para o processamento de duodécimos"
				}
			},
			"integration": {
				"title": "Integração",
				"sections": {
					"integrationGeneralAccounting": "Integração na contabilidade geral",
					"integrationAnalyticalAccounting": "Integração na contabilidade analítica"
				}
			},
			"otherData": {
				"title": "Outros dados",
				"sections": {
					"allowanceCodesEmployeeWageProcessing": "Códigos de abonos a usar no processamento salarial do colaborador",
					"discountCodeEmployeeWage": "Código de desconto a usar no processamento salarial do colaborador",
					"comments": "Comentários"
				}
			}
		},
		"actions": {
			"calculateRemunHora": "Calcular a remuneração por hora do empregado",
			"applySuggestedRemunHora": "Aplicar a remuneração por hora sugerida",
			"simula": "Simula",
			"selecaoTabelaIRS": "Sel. Tabela IRS"
		},
		"text": {
			"datasHistoricoDadosCaption": "Histórico de cadastro",
			"datasHistoricoDadosCurrent": "em vigor",
			"fileNamePicture": "Foto_{{codEmp}}",
			"configurarOutrosDados1": "Parece que ainda não configurou os dados por defeito da ficha do empregado. Deseja",
			"configurarOutrosDados2": "fazê-lo agora",
			"oldRecordWarning": "<strong>Atenção:</strong> está a visualizar um cadastro do histórico de dados. Estes dados podem não corresponder aos dados atuais do colaborador.<br/>Não é possível <strong>editar</strong> nem <strong>apagar</strong> cadastros do histórico de dados. Se pretende editar ou apagar o colaborador, por favor, selecione o cadastro <strong>em vigor</strong> (o mais recente).",
			"total": "Total: {{value}} (remuneração por hora sugerida)",
			"configWSS": "Configuração do acesso ao sistema de webservice da segurança social",
			"percDescTaxaFixaIRSShowWarning": "Um \"{{description}}\" tem taxa legal mínima de {{value}}.",
			"naoResidente": "Não residente",
			"residenteNaoHabitual": "Residente não habitual"
		},
		"titles": {
			"abono": "{{designacao}}: {{valorCalculado}} = ({{valor}} &times; {{meses}}) / ({{horasSemana}} &times; {{semanas}})",
			"abonoCruzamento": "{{designacao}}: {{valorCalculado}} = (({{valor}} &times; ({{percentagem}} / 100) &times; {{meses}}) / ({{horasSemana}} &times; {{semanas}})",
			"valorCalculado": "Valor calculado",
			"valorDesignacao": "Valor {{designacao}}",
			"nDiuturnidades": "Número de diuturnidades",
			"percentagem": "Percentagem",
			"meses": "Meses",
			"horasSemana": "Número de horas por semana",
			"semanas": "Número de semanas por ano"
		},
		"errors": {
			"invalidNumSegSoc": "Número de identificação da segurança social inválido",
			"invalidDtFimContrat": "Data de fim de contrato é anterior à data de inicio de contrato",
			"invalidDTNascimento": "A data de nascimento não é válida! Idade permitida compreendida entre 15 e 100 anos."
		},
		"deletePrompt": {
			"title": "Apagar colaborador {{id}}?",
			"message": "Como pretende apagar o colaborador?",
			"lastOnly": "Apenas o cadastro em vigor (o mais recente)",
			"all": "Todo o seu histórico",
			"invalid": "Por favor, selecione como pretende apagar o colaborador"
		},
		"saveCadastro": {
			"title": "Guardar cadastro",
			"message": "Os dados de cadastro foram alterados. Como pretende guardar os mesmos?",
			"newCadastro": "Criar novo registo no cadastro",
			"updateLatestCadastro": "Atualizar último registo no cadastro",
			"dataRegisto": "Seleccione a data do registo"
		},
		"prompt": {
			"exitMessage": "Tem a certeza que pretende sair sem gravar os dados alterados?",
			"inconsistenciasTabelaIRSNotifCloseMessage": "Pretende ocultar permanentemente o alerta de validação da tabela de IRS dos colaboradores?"
		},
		"comments": {
			"modalTitle": "Manutenção de comentários",
			"title": "Titulo",
			"text": "Texto",
			"user": "Utilizador"
		},
		"modalSimularTaxaFixaIrs": {
			"modalTitle": "Simulador taxa marginal IRS",
			"taxaEfetiva": "Taxa efetiva mensal",
			"infoModal": "Ao calcular será apresentada a taxa marginal, tendo em conta os diferentes tipos de remuneração definidos para o funcionário (vencimento, diuturnidades, subsídio de refeição, dados fixos).",
			"taxaMarginal": "Taxa marginal",
			"semDados": "Sem dados para apresentar",
			"infoCalculos": "Informação cálculos",
			"taxaNaoValida": "Taxa efetiva mensal tem que ser entre 0% e 100%",
			"dataSimulacao": "Data da simulação"
		},
		"modalSelecaoTabelaIRS": {
			"modalTitle": "Seleção da Tabela IRS",
			"fields": {
				"dataEmVigor": "Data em vigor",
				"residencia": "Residência",
				"situacao": "Situação",
				"tipoRendimento": "Tipo Rendimento",
				"declaranteDeficiente": "Declarante Deficiente",
				"dependentes": "Dependentes",
				"dependentesDeficiente": "Dependentes Deficiente"
			},
			"groupSelection": {
				"title": "Seleção da Tabela IRS"
			},
			"groupSelected": {
				"title": "Com os dados selecionados, a tabela a aplicar é:"
			},
			"tabelaDesignacaoIRS": "Tabela: {{designacaoIRS}}.",
			"tabelaNaoDeterminada": "Tabela IRS não determinada.",
			"parcelaAbaterDepende": "Parcela por dependente: {{parcelaAbaterDepende}}."
		},
		"enumStr": {
			"rhLocalTributacaoRendimento": {
				"rhltrNone": "Não aplicável",
				"rhltrContinente": "Continente",
				"rhltrRegiaoAcores": "Região Açores",
				"rhltrRegiaoMadeira": "Região Madeira"
			},
			"rhNaturezaRendimento": {
				"rhnrNone": "Não aplicável",
				"rhnrDependente": "Dependente",
				"rhnrPensoes": "Pensões"
			},
			"rhTipoDeficiencia": {
				"rhtdNone": "Não aplicável",
				"rhtdDeficienciaGeral": "Deficiência Geral",
				"rhtdDeficienciaForcasArmadas": "Deficiência Forças Armadas"
			},
			"rhSituacaoCivilTitular": {
				"rhsctNone": "Não aplicável",
				"rhsctNaoCasado": "Não Casado",
				"rhsctCasado1Titular": "Casado 1 Titular",
				"rhsctCasado2Titulares": "Casado 2 Titulares"
			},
			"rhDependentes": {
				"rhdSemDependentes": "Sem Dependentes",
				"rhdComDependentes": "Com Dependentes"
			}
		},
		"gridIRSDTEmVigor": {
			"fields": {
				"valorILIQ": "Até à renum. mês",
				"taxaMarginalMaxima": "Taxa marginal máxima",
				"parcelaAbaterDepende": "Parcela dependente (€)",
				"txEfetivaMensalEscal": "Tem limite escalão",
				"parcelaAbaterFormulaInfo": "Parcela a abater (€)"
			}
		}
	},
	"impdadosemp": {
		"filtropesq": "Pesq. avançada",
		"deleteFoto": "Apagar foto",
		"fields": {
			"deCod": "Cód. do empregado de",
			"deNome": "Nome de",
			"deIdade": "Idade de",
			"deSituacao": "Situação de",
			"deCategoria": "Categoria de",
			"deDepartamento": "Departamento de",
			"deDtNasc": "Data nascimento de",
			"deDtValCt": "Data val. BI de",
			"deDtValBI": "Data val. contrato de"
		}
	},
	"diarios": {
		"title_detail": "Diário de contabilidade {{id}}",
		"title_new": "Novo diário de contabilidade",
		"title_plural": "Diários de contabilidade",
		"pesquisa": "Pesquisar",
		"saved": "Diário de contabilidade {{id}}, guardado com sucesso.",
		"error": "Diário de contabilidade {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Diário de contabilidade {{id}}, apagado com sucesso.",
		"fields": {
			"nDiario": "Cód.",
			"nDiarioPlaceholder": "Código do diário de contabilidade",
			"nome": "Nome",
			"nomePlaceholder": "Nome do diário de contabilidade",
			"formaIncrementoNDoc": "Forma de incremento nº doc. interno",
			"formaIncrementoNDocPlaceholder": "Forma de incremento",
			"aberturaContab": "Diário de abertura da contabilidade (não lança IVA)",
			"aberturaContabPlaceHolder": "Diário de abertura da contabilidade"
		}
	},
	"distritos": {
		"title_detail": "Distrito {{id}}",
		"title_new": "Novo Distrito",
		"title_plural": "Distritos",
		"pesquisa": "Pesquisar",
		"saved": "Distrito {{id}}, guardado com sucesso",
		"error": "Distrito {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Distrito {{id}}, apagado com sucesso",
		"fields": {
			"codDistrito": "Código do distrito",
			"nomeDistrito": "Nome do distrito",
			"localObtencaoRendimentos": "Local obtenção rendimentos",
			"codContinente": "Continente",
			"codPais": "Código do país",
			"nomePais": "País"
		}
	},
	"divisoesTrabalho": {
		"title_detail": "Divisão de Trabalho {{id}}",
		"title_new": "Nova Divisão de Trabalho",
		"title_plural": "Divisões de Trabalho",
		"pesquisa": "Pesquisar",
		"saved": "Divisão de Trabalho {{id}}, guardada com sucesso.",
		"error": "Divisão de Trabalho {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Divisão de Trabalho {{id}}, eliminada com sucesso.",
		"fields": {
			"nDivisao": "Cód.",
			"designacaoBreve": "Designação breve",
			"designacaoCompleta": "Designação completa",
			"codDuracaoQPessoal": "Cod. duração quadro pessoal",
			"tipo": "Tipo de tempo"
		}
	},
	"divisTipo": {
		"0": "Desconhecido",
		"1": "Tempo Completo",
		"2": "Tempo Parcial"
	},
	"dmrf": {
		"frestran": "Retenções a não residentes",
		"edit": "Editar",
		"delete": "Apagar",
		"newModalTitle": "Criar registo",
		"editModalTitle": "Editar registo",
		"summaryTitle": "Preenchimento da Guia de Retenção na Fonte IRC / IRS",
		"fields": {
			"nContrib": "Nº contribuinte",
			"nDocExterno": "Nº doc. externo",
			"zona": "Zona",
			"rubrica": "Rubrica",
			"retencao": "Retenção"
		},
		"messages": {
			"contribrequired": "Tem de colocar um nº contribuinte!",
			"rubricaRequired": "Tem de escolher uma rubrica!",
			"savedSuccess": "Registo guardado com sucesso",
			"promptDeleteRecord": "Tem a certeza que pretende apagar o registo?",
			"promptDeleteDeclaracao": "Tem a certeza que pretende eliminar a declaração?",
			"erroExport": "Ocorreu um erro a exportar o ficheiro da declaração.",
			"promptSendMessage": "Esta operação irá gravar os dados e entregar declaração de retenções IRC / IRS no portal das finanças (AT).<br/>Tem a certeza que pretende continuar?",
			"sendSuccess": "O ficheiro da declaração foi enviado com sucesso.",
			"sending": "A enviar... por favor aguarde um momento.",
			"exporting": "A exportar... por favor aguarde um momento.",
			"saving": "A guardar... por favor aguarde um momento.",
			"deleting": "A eliminar... por favor aguarde um momento.",
			"deletingRecord": "A eliminar registo... por favor aguarde um momento.",
			"valueRetNegative": "Valor de retenção negativo. Esta linha não vai ser comunicada.",
			"valueRetNull": "Valor de retenção nulo. Esta linha não vai ser comunicada."
		},
		"toolbar": {
			"deletePeriodo": "Anular declaração",
			"refresh": "Obter dados",
			"save": "Gravar declaração",
			"export": "Gerar ficheiro",
			"send": "Enviar declaração"
		},
		"rubrica": {
			"102": "102: IRS - Empresariais e Profissionais",
			"103": "103: IRS - Pensões",
			"104": "104: IRS - Prediais",
			"105": "105: IRS - Capitais - Valores mobiliários - Entidades emitentes",
			"106": "106: IRS - Capitais - Valores mobiliários - Entidades registadoras, depositárias e outras",
			"107": "107: IRS - Capitais - Juros de depósitos à ordem ou a prazo",
			"108": "108: IRS - Capitais - Outros rendimentos",
			"109": "109: IRS - Prémios de jogos, lotarias, rifas, apostas mútuas, sorteios, concursos",
			"110": "110: IRS - Indemnizações e outros incrementos patrimoniais",
			"112": "112: IRS - Sobretaxa extraordinária",
			"114": "114: IRS - Sobretaxa extraordinária - Cat. H",
			"115": "115: IRS - Outros rendimentos de capitais sujeitos às taxas previstas no art.º 71 do CIRS",
			"116": "116: IRS - Rend. de capitais sujeitos à taxa do artº 101º do CIRS - Rend. obtidos até 31 de dez. de 2014",
			"117": "117: IRS - Rend. de resgate de unidades de participação fundos de inv. [Artº 22ºA, nº1, al. b) e c) EBF]",
			"118": "118: IRS - Capitais-Valores mobiliários representativos de divida (D.L. 193/2005 de 7 de novembro)",
			"199": "199: IRS - Juros Compensatórios",
			"201": "201: IRC - Prediais",
			"202": "202: IRC - Comissões por intermediação em quaisquer contratos e prestações de serviços",
			"203": "203: IRC - Capitais - Valores mobiliários - Entidades emitentes",
			"204": "204: IRC - Capitais - Valores mobiliários - Entidades registadoras ou depositárias",
			"205": "205: IRC - Capitais - Juros de depósitos à ordem ou a prazo",
			"206": "206: IRC - Capitais - Outros rendimentos",
			"207": "207: IRC - Prémios de jogos, lotarias, rifas, apostas mútuas, sorteios, concursos",
			"208": "208: IRC - Remunerações de membros de órgãos estatutários de Pessoas Colectivas",
			"209": "209: IRC - Fundos de Inv. - Rend. obtidos até 31 de dez. de 2014 (anterior redação do art.º 22.º do EBF)",
			"210": "210: IRC - Resgate Uni. Participação - Fundos Inv. Imob. (Não Residentes) [Artº 22º-A, nº 1, al. c), EBF",
			"211": "211: IRC - Org. de Inv. Coletivo-Reg. Transitório - n.º 3 do Art. 7.º do DL n.º 7/2015, de 13 de janeiro",
			"212": "212: IRC - Org. Inv. Coletivo duração determinada - Reg. Tran. - nº 8 do art. 7º do DL nº 7/2015, de 13/1",
			"213": "213: IRC - Org. Inv. Coletivo duração determinada - Reg. Tran. - nº 8 do art. 7º do DL nº 7/2015, de 13/1",
			"299": "299: IRC - Juros Compensatórios",
			"000": ""
		},
		"zona": {
			"desconhecido": "Desconhecido",
			"continente": "Continente",
			"madeira": "Madeira",
			"acores": "Açores"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmação",
		"leavePromptMessage": "Ao clicar em SIM irá sair e descartar todos os dados do processamento. Tem a certeza que pretende sair?"
	},
	"docfas": {
		"title_detail": "Tipo de documento {{id}}",
		"title_new": "Novo tipo de documento",
		"title_plural": "Tipo de documento",
		"pesquisa": "Pesquisar",
		"saved": "Tipo de documento {{id}}, guardado com sucesso",
		"error": "Tipo de documento {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"documentoGlobal": "Documento Global",
		"entregaEfectivaSobreDocumentoGlobal": "Entrega Efectiva sobre Documento Global",
		"loginAT": "Webservice AT - Comunicação de Séries",
		"accoes": "Acções",
		"autofaturacaoComAcordo": "Autofaturação para fornecedores com Acordo",
		"autofaturacaoSemAcordo": "Autofaturação para fornecedores sem Acordo",
		"fields": {
			"nDocFa": "Número",
			"nome": "Nome",
			"descricao": "Descrição",
			"codigoSAFTTipoDoc": "SAFT - tipo documento",
			"identificaCarga": "Identifica carga",
			"faturaComIvaIncluido": "Fatura com IVA incluído",
			"integraStocks": "Integra stocks",
			"integraNaContabilidade": "Integra",
			"integraEmContasCorrentes": "Integra em contas correntes",
			"reportDefeito": "Relatório por defeito",
			"tipoMovimentoStock": "Movimento stock",
			"grupoDocfa": "Grupo",
			"fluxoMonetario": "Fluxo monetário",
			"nDocfaDestino": "Tipo doc. destino",
			"faturacaoPublica": "Faturação pública",
			"obrigatorioMeiosPagam": "Utiliza meios de pagamento",
			"descricaoSAFTTipoDoc": "Descrição SAFT - Tipo doc.",
			"codigoSAFTTipoDocDescStr": "SAFT - Tipo documento",
			"entregaEfetivaSobreDocGlobal": "Entrega efetiva sobre documento global",
			"sujeitoAHASHStr": "Assinatura",
			"cae": "CAE",
			"nViasDefeito": "Nº de vias por defeito",
			"tipoMovimento": "Tipo de movimento",
			"movimentaStock": "Movimenta Stock",
			"diario": "Diário",
			"descriti": "Descritivo"
		},
		"list": {
			"actions": {
				"new": "Novo com base neste"
			}
		},
		"captions": {
			"definicoes": "Definições comerciais e de integração",
			"numeracoes": "Séries",
			"dadosAT": "Dados AT",
			"reports": "Relatórios",
			"dadosGerais": "Dados Gerais",
			"integraContabilidade": "Integração na Contabilidade"
		},
		"warnings": {
			"canceladaNovaSerie": "Criação da nova série cancelada",
			"novaserie": "Nova série",
			"addnovaserie": "Adicionar nova série",
			"novaSerieAdd": "Nova série adicionada com sucesso",
			"temAlteracoes": "Existem alterações ainda não guardadas."
		},
		"errors": {
			"tipoDocNotExist": "O tipo de documento não existe",
			"erroNovaSerie": "Erro na criação de nova série",
			"autofaturacaoNome": "O tipo de documento é autofaturação, que obriga a que se contenha o termo \"autofaturação ou auto-faturação\" no nome",
			"soPodeSelecionarTpDocQuePertencemAoGrupo": "Só pode selecionar tipos de documento que pertencem ao grupo",
			"autoFaturacaoInvalido": "O tipo de documentos pertence ao Grupo, mas tem o campo \"autoFaturacao\" inválido."
		},
		"tooltip": {
			"cae": "Campo não obrigatório. No entanto facilita o processo de classificação dos documentos aos seus clientes e evita que a AT não aceite os documentos em determinadas áreas de negócio."
		},
		"messages": {
			"semMovimentoStock": "Sem movimento",
			"entradaStock": "Entrada de stock",
			"saidaStock": "Saída de stock"
		},
		"modal": {
			"new": {
				"cardBase": "Base para novo tipo de documento",
				"cardNew": "Novo tipo de documento",
				"title": "Assistente de criação de novo tipo de documento",
				"nDocfaBase": "Documento base",
				"nDocFaPlaceHolder": "Se não preenchido gera automaticamente",
				"saftTipoDoc": "SAFT - Tipo documento",
				"fatura": "Fatura",
				"faturaRecibo": "Fatura-Recibo"
			}
		},
		"modals": {
			"comunicacaoSeriesAT": "Comunicação de séries à AT"
		},
		"reports": {
			"fields": {
				"visible": "Disponível",
				"defaultReport": "Por defeito",
				"nome": "Nome",
				"descricao": "Descrição",
				"tipoDescricao": "Tipo"
			},
			"descricao": {
				"system": "Sistema",
				"user": "Utilizador",
				"userEmpresa": "Utilizador / Empresa"
			}
		}
	},
	"docfasNums": {
		"title_detail": "Série Tipo de documento Id. {{id}}",
		"title_new": "Nova série de tipo de documento",
		"title_plural": "Séries de Tipos de documentos",
		"pesquisa": "Pesquisar",
		"saved": "Série de Tipo de documento {{id}}, guardado com sucesso",
		"error": "Série de Tipo de documento {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"idDocfaNum": "Id. série",
			"nDocfa": "Tipo de documento",
			"nNumer": "Nº série",
			"nNumerPlaceholder": "Nº série, se não for preenchido será gerado automaticamente.",
			"num": "Nº doc.",
			"descNumer": "Descrição",
			"numDraft": "Nº doc. draft",
			"nConta": "Nº conta",
			"encerrado": "Encerrado",
			"atcud": "AT - Código validação",
			"visivelERPCloud": "Visível cloud",
			"codLocalEmissao": "Local emissão",
			"atEstadoSerieDescricao": "Estado na AT",
			"paraTodosDoGrupo": "Criar para todos os tipos de documento do grupo {{nomeGrupo}}"
		},
		"auxiliares": {
			"novaserie": "Nova série",
			"addnovaserie": "Adicionar nova série",
			"novaSerieAdd": "Nova série adicionada com sucesso"
		},
		"errors": {
			"SerietipoDocNotExist": "A série de tipo de documento não existe",
			"serieNaoPodeSerNula": "A série de tipo de documento não pode ser nula",
			"seriesTipoDocumentoNaoDaParaComunicarAT": "O tipo de documento não está sujeito a assinatura logo não dá para comunicar à AT",
			"seriesTipoDocumentoNaoDaParaVerLogAT": "O tipo de documento não está sujeito a assinatura logo não tem log de comunicação à AT",
			"seriesTipoDocumentoNaoDaParaConsultarComunicacaoAT": "O tipo de documento não está sujeito a assinatura logo não dá para consultar a comunicação à AT",
			"atAFEnquadramentoRequired": "O enquadramento é obrigatório",
			"nContaRequired": "O nº conta é obrigatório"
		},
		"btn": {
			"comunicarSeries": "Comunicar séries de faturação à AT",
			"logSeries": "LOG de comunicação da série à AT",
			"consultarSeries": "Consultar série na AT"
		},
		"modal": {
			"comunicacao": {
				"title": "Comunicação de séries de faturação à AT",
				"selecionar": "Selecionar",
				"enquadramentoDesperdicios": "Alinea i) do nº1 do artº 2 do CIVA - Setor de desperdícios, resíduos, sucatas recicláveis",
				"enquadramentoCortica": "Alinea m) do nº 1 do artº 2 do CIVA - Adquirentes de cortiça, madeira, pinhas e pinhões com casca",
				"atSeriesLinkTooltip": "Registo das séries no portal das finanças",
				"fields": {
					"serie": "Série AT",
					"tipoSerie": "Tipo de série",
					"classeDoc": "Classe do documento",
					"tipoDoc": "Tipo de documento",
					"numInicialSeq": "Início da sequência",
					"dataInicioPrevUtiliz": "Início previsto de utilização",
					"seqUltimoDocEmitido": "Último doc. emitido",
					"codValidacaoSerie": "Cód. validação (ATCUD)",
					"estado": "Estado da série",
					"meioProcessamento": "Meio de processamento",
					"numCertificado": "Número de certificado",
					"nifFornecedor": "NIF do fornecedor",
					"enquadramento": "Enquadramento"
				},
				"btn": {
					"finalizar": "Finalizar série",
					"registarWebService": "Comunicar por webservice",
					"registarManual": "Comunicar manualmente"
				},
				"finalizar": {
					"title": "Finalizar comunicação da série",
					"justificacao": "Justificação",
					"erroJustificacaoVazia": "A justificação não pode ser nula."
				},
				"autoFaturacaoComAcordoMessage1": "Série de autofaturação com acordo",
				"autoFaturacaoComAcordoMessage2": "Para ativar uma série de autofaturação com acordo em nome do seu fornecedor nacional ou estrangeiro, deve previamente fazê-lo no portal das finanças na área adequada, introduzindo os dados que apresentamos.",
				"autoFaturacaoComAcordoMessage3": "Após o registo no portal, deve inserir neste formulário o ATCUD e o NIF do fornecedor, e efetuar a gravação.",
				"autoFaturacaoSemAcordoMessage1": "Série de autofaturação sem acordo",
				"autoFaturacaoSemAcordoMessage2": "Para ativar uma série de autofaturação sem acordo em nome do seu fornecedor de acordo com enquadramento a que se destina, deve previamente fazê-lo no portal das finanças na área adequada, introduzindo os dados que apresentamos.",
				"autoFaturacaoSemAcordoMessage3": "Após o registo no portal, deve inserir neste formulário o ATCUD e o enquadramento, e efetuar a gravação."
			},
			"consulta": {
				"fields": {
					"serie": "Série",
					"tipoSerie": "Tipo de série",
					"classeDoc": "Classe doc.",
					"tipoDoc": "Tipo doc.",
					"numInicialSeq": "Num. inicial",
					"dataInicioPrevUtiliz": "Data inicial",
					"seqUltimoDocEmitido": "Última num. emitida",
					"meioProcessamento": "Meio de processamento",
					"numCertSWFatur": "Nº certificado SW",
					"codValidacaoSerie": "Cód. validação (ATCUD)",
					"dataRegisto": "Data de registo",
					"estado": "Estado",
					"dataEstado": "Data estado",
					"nifComunicou": "NIF comunicação"
				}
			},
			"logs": {
				"title": "",
				"fields": {
					"atDataInicioPrev": "Data/Hora",
					"operacaoSTR": "Operação",
					"respostaCodigo": "Código da resposta",
					"respostaMensagem": "Mensagem da resposta",
					"codValidacaoSerie": "Cód. validação série",
					"atTipoSerie": "Tipo série",
					"atTipoSerieDescricao": "Tipo série (descrição)",
					"atClasseDoc": "Classe doc.",
					"atClasseDocDescricao": "Classe doc. (descrição)",
					"atTipoDocSTR": "Tipo doc.",
					"atEstadoSerie": "Estado série",
					"atEstadoSerieDescricao": "Estado série (descrição)"
				}
			},
			"seriesPorComunicarAT": {
				"title": "Comunicação das séries documentais à AT",
				"fields": {
					"nDocfa": "Tipo doc.",
					"nNumer": "Nº série",
					"nome": "Nome tipo doc.",
					"tipoDocSaft": "Tipo SAFT",
					"numAtual": "Numeração atual",
					"numInicial": "Numeração inicial",
					"dataInicio": "Data início"
				},
				"btn": {
					"comunicarseriesAT": "Comunica por Web Service"
				}
			}
		}
	},
	"docfasnumscomuncicacaologin": {
		"title": "Login Comunicação de Séries",
		"saveTitle": "Guardar",
		"fields": {
			"username": "Username (NIF/UserID)",
			"password": "Password"
		},
		"messages": {
			"success": "Login efetuado com sucesso.",
			"errorUsernameEmpty": "Username vazio.",
			"errorPasswordEmpty": "Password vazia.",
			"noPermissions": "Aviso: Para alterar valores deve ter permissões de administrador!"
		}
	},
	"configNomeExportFile": {
		"caption": {
			"filesType": "Tipo configuração",
			"nDocFa": "Tipo documento",
			"groupConfigurador": "Configurador",
			"fieldType": "Tipo campo",
			"valor": "Valor",
			"groupConfigs": "Configurações de nomes dos ficheiros",
			"mascaraLabel": "Máscara"
		},
		"grids": {
			"ordem": "Ordem",
			"tipo": "Tipo",
			"valor": "Valor",
			"doc": "Documento"
		},
		"apagarModal": {
			"title": "Apagar configuração",
			"message": "Tem a certeza que deseja elimninar a configuração \"{{configuracao}}\" ?"
		},
		"messages": {
			"erroMaskLength": "Não tem nenhuma máscara configurada.",
			"erroNDocFa": "Tem de de selecionar um tipo de documento.",
			"erroFieldType": "Tem de selecionar um tipo de campo.",
			"erroValorNulo": "O valor não pode ser nulo.",
			"erroValorInvalido": "O valor tem carateres não válidos.",
			"deleteSucesso": "Configuração elimninada com sucesso."
		}
	},
	"docsComerciaisEstatisticas": {
		"title_plural": "Extractos de Vendas",
		"pesquisa": "Pesquisar",
		"verMaisFiltros": "Ver mais filtros",
		"verMaisFiltrosHide": "Esconder filtros",
		"tabs": {
			"cubo": "Cubo"
		},
		"fields": {
			"nDocStr": "Doc.",
			"nArtigo": "Artigo",
			"artigo": "Artigo",
			"nArtigoPivot": "Nº artigo",
			"nomeArtigo": "Nome artigo",
			"nConta": "Nº conta",
			"contaNome": "Nome",
			"nif": "Nif",
			"dataDoc": "Data doc.",
			"liquido": "Liquido",
			"valorIva": "Valor IVA",
			"sinal": "Sinal",
			"liquidoMovimento": "Liquido mov.",
			"valorIvaMovimento": "IVA mov.",
			"totalMovimento": "Total mov.",
			"qtd1": "Qtd.",
			"prVendaQtd1": "Preço unit.",
			"d1": "Desc. 1",
			"totalDocumento": "Total doc.",
			"totalLiquido": "Total líq.",
			"totalIliquido": "Total ilíq.",
			"totalIva": "Total IVA",
			"totalDesconto": "Total desc.",
			"taxaRet": "Taxa retenção",
			"retencaoIncidencia": "Incidência ret.",
			"retencaoValor": "Valor ret.",
			"nFactFornec": "Doc. externo",
			"dataDocExterno": "Data doc. externo",
			"nVendedor": "Cód. vendedor",
			"nomeVendedor": "Vendedor",
			"nDocfa": "Cód. tipo doc.",
			"docfaNome": "Tipo de documento",
			"nnumer": "Série do doc.",
			"nDepart": "Cód. gr. família",
			"nomeDepart": "Grande família",
			"nFamil": "Cód. família",
			"nomeFamilia": "Família",
			"nSubfa": "Cód. sub-família",
			"nomeSubFamilia": "Sub-família",
			"tipoArtigo": "Cód. tipo de artigo",
			"nomeTipoArtigo": "Tipo de artigo",
			"nCCusto": "Cód. C. Custo",
			"centroCusto": "Centro de custo",
			"nRefProcesso": "Cód. processo",
			"nomeProcesso": "Processo",
			"totalDocumentoMovimento": "Total doc. mov.",
			"totalLiquidoMovimento": "Total líq. mov.",
			"totalIliquidoMovimento": "Total ilíq. mov.",
			"totalIvaMovimento": "Total IVA mov.",
			"totalDescontoMovimento": "Total desc. mov.",
			"valorEmAberto": "Valor em aberto",
			"estado": "Estado",
			"sectorId": "Cód. do setor",
			"nomeSector": "Setor"
		},
		"caption": {
			"ncontade": "Nº de conta",
			"nartigode": "Cód. artigo de",
			"tipoartigode": "Tipo de artigo de",
			"ngrfamiliade": "Grande família de",
			"nfamiliade": "Família de",
			"nsubfamiliade": "Sub-família de",
			"classede": "Classe de",
			"categoriade": "Categoria artigo de",
			"datade": "Data doc. de",
			"nvendedorde": "Vendedor de",
			"codzonade": "Zona de",
			"ccustode": "C. Custo de",
			"nrefprocessode": "Nº processo de",
			"artigoCaption": "Artigo",
			"clienteCaption": "Cliente",
			"docCaption": "Documento",
			"socommovimstock": "Só artigos que movimentem stock",
			"docFa": "Tipos de documentos",
			"obter": "Obter",
			"printModalTitle": "Estatísticas de gestão comercial",
			"ididechavde": "Grupo de conta de",
			"cliclde": "Classe artigo de"
		},
		"info": {
			"erroDataDeMaiorQueAte": "A data De é maior que a data Até",
			"docPago": "Pago",
			"docPagoParcial": "Pago parcialmente",
			"docPorPagar": "Por pagar"
		},
		"configs": {
			"showFilterTipoArtigo": "Mostrar filtro por tipo de artigo",
			"showFilterGrFamilia": "Mostrar filtro por grande família",
			"showFilterFamilia": "Mostrar filtro por família",
			"showFilterSubFamilia": "Mostrar filtro por sub-família",
			"showFilterClasseArtigo": "Mostrar filtro por classe de artigo",
			"showFilterCategoriaArtigo": "Mostrar filtro por categoria de artigo",
			"showFilterSoComMovimStock": "Mostrar filtro só artigos que movimentem stock",
			"showFilterConta": "Mostrar filtro por conta",
			"showFilterVendedor": "Mostrar filtro por vendedor",
			"showFilterZona": "Mostrar filtro por zona",
			"showFilterCentroCusto": "Mostrar filtro por centro de custo",
			"showFilterProcesso": "Mostrar filtro por processo",
			"showFilterDocfaList": "Mostrar filtro por tipo de documento",
			"showFilterNArtigo": "Mostrar filtro para o artigo",
			"showFilterClasse": "Mostrar filtro por classe",
			"showFilterGrupoConta": "Mostrar filtro por grupo conta"
		},
		"errors": {
			"naoExisteTipoDocSelecionado": "Não existe nenhum tipo de documento selecionado.",
			"naoExisteTipoDocSelecionadoCheckOpcoes": "Não existem tipos documento selecionados. Verifique se tem documentos para selecionar colocando o filtro visível nas \"Opções\""
		}
	},
	"docsPartilhados": {
		"title_detail": "Documento da plataforma RH {{id}}",
		"title_new": "Nova Documento da plataforma RH",
		"title_plural": "Documentos Plataforma RH",
		"pesquisa": "Pesquisar",
		"saved": "Documento da plataforma RH {{id}}, guardado com sucesso",
		"error": "Documento da plataforma RH {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"cab": {
				"docId": "Doc. Id.",
				"nome": "Nome",
				"horaCriacaoReg": "Hora",
				"visualizado": "Visualizado",
				"tamanho": "Tamanho",
				"extensao": "Extensão"
			}
		},
		"docs": {
			"title": "Documento"
		},
		"uploadmodal": {
			"titlenew": "Enviar ficheiros para o contabilista",
			"dataRef": "Data de referência do ficheiro"
		},
		"info": {
			"apagardocumentos": "Apagar documentos",
			"temCertezaApagarDocumentos": "Tem a certeza que deseja apagar os documentos selecionados?",
			"emailParaGabSucess": "E-mail enviado para gabinete com sucesso",
			"fileUploadSuccess": "Ficheiro carregado com sucesso"
		},
		"toolbar": {
			"year": "Ano: {{year}}",
			"month": "Mês: {{month}}"
		},
		"btn": {
			"enviarFicheiros": "Enviar ficheiros"
		}
	},
	"guias": {
		"title_detail": "Guia {{id}}",
		"title_new": "Nova Guia",
		"title_plural": "Guias",
		"saved": "Guia {{id}} guardada com sucesso.",
		"error": "A Guia não pode ser guardada. Por favor verifique os campos.",
		"toolbar": {
			"btnConfigAt": "&nbsp;Webservice AT - Documentos de transporte"
		}
	},
	"vendas": {
		"title_detail": "Venda {{id}}",
		"title_new": "Nova Venda",
		"title_plural": "Vendas",
		"saved": "Venda {{id}} guardada com sucesso.",
		"error": "A Venda não pode ser guardada. Por favor verifique os campos."
	},
	"compras": {
		"title_detail": "Compra {{id}}",
		"title_new": "Nova Compra",
		"title_plural": "Compras",
		"saved": "Compra {{id}} guardada com sucesso.",
		"error": "A Compra não pode ser guardada. Por favor verifique os campos."
	},
	"entradasdiversas": {
		"title_detail": "Entrada Diversa {{id}}",
		"title_new": "Nova Entrada Diversa",
		"title_plural": "Entradas Diversas",
		"saved": "Entrada Diversa {{id}} guardada com sucesso.",
		"error": "A Entrada Diversa não pode ser guardada. Por favor verifique os campos."
	},
	"saidasdiversas": {
		"title_detail": "Saída Diversa {{id}}",
		"title_new": "Nova Saída Diversa",
		"title_plural": "Saídas Diversas",
		"saved": "Saída Diversa {{id}} guardada com sucesso.",
		"error": "A Saída Diversa não pode ser guardada. Por favor verifique os campos."
	},
	"propostasaclientes": {
		"title_detail": "Proposta/orçamento {{id}}",
		"title_new": "Nova proposta/orçamento",
		"title_plural": "Propostas a Clientes",
		"saved": "Proposta a Cliente {{id}} guardada com sucesso.",
		"error": "A Proposta a Cliente não pode ser guardada. Por favor verifique os campos."
	},
	"encomendasclientes": {
		"title_detail": "Encomenda cliente {{id}}",
		"title_new": "Nova encomenda cliente",
		"title_plural": "Encomendas Clientes",
		"saved": "Encomenda Cliente {{id}} guardada com sucesso.",
		"error": "A Encomenda a Cliente não pode ser guardada. Por favor verifique os campos."
	},
	"encomendasfornecedores": {
		"title_detail": "Encomenda fornecedor {{id}}",
		"title_new": "Nova encomenda fornecedor",
		"title_plural": "Encomendas Fornecedores",
		"saved": "Encomenda Fornecedor {{id}} guardada com sucesso.",
		"error": "A Encomenda a Fornecedor não pode ser guardada. Por favor verifique os campos."
	},
	"guiasfornecedores": {
		"title_detail": "Guia {{id}}",
		"title_new": "Nova Guia",
		"title_plural": "Guias",
		"saved": "Guia {{id}} guardada com sucesso.",
		"error": "A Guia não pode ser guardada. Por favor verifique os campos.",
		"toolbar": {
			"btnConfigAt": "Login AT para comunicação dos documentos de transporte"
		}
	},
	"comprasautofaturacao": {
		"title_detail": "Doc. Autofaturação {{id}}",
		"title_new": "Novo doc. Autofaturação",
		"title_plural": "Autofaturação",
		"saved": "Doc. de Autofaturação {{id}} guardado com sucesso.",
		"error": "O doc. de Autofaturação não pode ser guardado. Por favor verifique os campos."
	},
	"consultasafornecedores": {
		"title_detail": "Orçamento {{id}}",
		"title_new": "Novo orçamento",
		"title_plural": "Orçamentos",
		"saved": "Orçamento {{id}} guardado com sucesso.",
		"error": "O Orçamento não pode ser guardado. Por favor verifique os campos."
	},
	"docscomerciais": {
		"addLine": "Adicionar linha",
		"title_detail": "Documento {{id}}",
		"title_new": "Novo Documento",
		"title_plural": "Documentos Comerciais",
		"pesquisa": "Pesquisar",
		"saved": "Documento Comercial {{id}} guardado com sucesso.",
		"error": "O Documento Comercial não pode ser guardado. Por favor, verifique os campos.",
		"fields": {
			"nDocFa": "Tipo doc.",
			"nNumer": "Série",
			"nDocumento": "Nº documento",
			"nDocumentoDraft": "Nº documento draft",
			"nDoc": "Nº doc",
			"nDocFilter": "Nº doc (Ex.: 1.23.10)",
			"nome": {
				"clifos": "Nome",
				"clientes": "Cliente",
				"fornecedores": "Fornecedor"
			},
			"nConta": "Conta",
			"nif": "Contribuinte",
			"estado": "Estado",
			"porPagar": "Por pagar",
			"porReceber": "Por receber",
			"stampUpdate": "Ult. alteração",
			"modoEnvio": "Modo envio",
			"estadoEnvioStr": "Estado envio",
			"nomeMoeda": "Moeda",
			"transformado": "Transformado",
			"nFactFornec": "Doc. externo",
			"dataDocExterno": "Data doc. externo",
			"observacoes": "Observações",
			"anulado": "Anulado",
			"dataVenc": "Data venc.",
			"textoLivre": "Texto livre"
		},
		"toolbar": {
			"importDoc": "Importar documento",
			"meiosPagamento": "Meios de pagamento",
			"calcular": "Calcular",
			"arredondamentoManual": "Arredondamento manual",
			"alterarCamposSerie": "Alterar campos em série",
			"rastreabilidade": "Rastreabilidade",
			"preView": "Pré-visualizar/Rascunho",
			"salvageLastDoc": "Recuperar último documento",
			"guardar": "Guardar",
			"guardarRascunho": "Guardar rascunho",
			"finalizarDocumento": "Finalizar documento",
			"transformacaoDocumentos": "Transformação de documentos",
			"arquivoDigital": "Arquivo digital",
			"configNameFileExport": "Nome ficheiros a exportar",
			"faturacaoPublica": "EDI/Faturação Eletrónica Pública"
		},
		"doc": {
			"cab": {
				"nDocFa": "Tipo doc.",
				"nNumer": "Série",
				"dataDoc": "Data doc.",
				"nDocumento": "Nº documento",
				"nDocumentoDraft": "Nº doc. draft",
				"nome": "Nome",
				"totalLiquido": "Incidência",
				"totalIva": "IVA",
				"totalDesconto": "Descontos",
				"totalIliquido": "SubTotal",
				"taxaRet": "Taxa",
				"retencaoIncidencia": "Incidência",
				"retencaoValor": "Valor",
				"observacoes": "Observações",
				"nFactFornec": "Doc. externo",
				"dataDocExterno": "Data doc. externo",
				"dataInicTran": "Data início de transporte",
				"horaInicTran": "Hora início de transporte",
				"estadoEnvioStr": "Estado envio",
				"atDocCodeID": "Código AT",
				"nCCusto": "Nº centro custo",
				"dataVenc": "Data vencimento",
				"codMoeda": "Cód. moeda",
				"nomeMoeda": "Moeda",
				"cambio": "Câmbio",
				"nRefProcesso": "Processo",
				"comunicadoAoEFatura": "Estado envio",
				"tipoComunicacao": "Modo envio",
				"ctRefExterna": "Contrato",
				"acordoQuadro": "Acordo quadro",
				"compromisso": "Compromisso",
				"nRequisicao": "Nº requisição",
				"nDocGlobalOrigem": "Doc. global",
				"nCondPagam": "Condições de pagamento"
			},
			"linhas": {
				"nArtigo": "Artigo",
				"nome": "Nome",
				"qtd": "Qtd.",
				"qtdStock": "Qtd. stock",
				"preco": "Preço",
				"codIva": "Cód. IVA",
				"taxaIva": "IVA %",
				"desconto": "Desconto %",
				"d1": "Desc. 1 %",
				"d2": "Desc. 2 %",
				"d3": "Desc. 3 %",
				"d4": "Desc. 4 %",
				"d5": "Desc. 5 %",
				"d6": "Desc. 6 %",
				"valorComIva": "Valor c/ IVA",
				"nCCusto": "C. Custo",
				"valorIva": "Valor IVA",
				"nRefProcesso": "Processo",
				"nArmazem": "Armazém",
				"nVendedor": "Vendedor",
				"codUnidMov": "Unid. movimento",
				"valorSemIVA": "Valor s/ IVA",
				"unidMovAbrev": "Unid.",
				"liquido": "Líquido",
				"descEmValor": "Desc. em valor",
				"dataEntregaPrev": "Data entrega prevista",
				"nSeq": "Nº seq."
			},
			"tabela": {
				"tooltipCellNomeArtigo": "Adicionar descrição"
			},
			"iva": {
				"taxa": "Taxa",
				"incidencia": "Incidência",
				"valorIva": "Valor do IVA"
			},
			"clifo": {
				"nome": "Nome",
				"nContribuint": "NIF",
				"rua": "Rua",
				"codPostal": "Cód. postal",
				"localidade": "Localidade",
				"telefone": "Telefone",
				"email": "E-mail"
			},
			"moradaCarga": {
				"rua": "Rua",
				"codPostal": "Cód. postal",
				"localidade": "Localidade",
				"observacoes": "Obs.",
				"morada": "Rua(Continuação)",
				"codPais": "Cód. país"
			},
			"moradaDescarga": {
				"rua": "Rua",
				"codPostal": "Cód. postal",
				"localidade": "Localidade",
				"observacoes": "Obs.",
				"morada": "Rua (Continuação)",
				"codPais": "Cód. país",
				"moralId": "Morada alternativa"
			},
			"transportador": {
				"nGuiaTransporte": "Nº guia transp.",
				"dataGuiaTransporte": "Data guia",
				"matricula": "Matrícula",
				"matriculaReboque": "Matrícula reboque",
				"nomeTransportador": "Nome transportador",
				"nomeViatura": "Nome viatura",
				"nomeMotorista": "Nome motorista",
				"moradaMotorista": "Morada motorista",
				"codPostalMotorista": "Cód. postal motorista",
				"codPaisMotorista": "Cód. país motorista"
			},
			"novo": {
				"cliente": "Cliente",
				"observacoes": "Observações",
				"docExterno": "Doc. externo"
			}
		},
		"info": {
			"comunicacaoFaturaAT": "Fatura comunicada à AT com sucesso.",
			"comunicacaoDocTransporteAT": "Doc. transporte comunicado à AT com sucesso.",
			"inserirCodComunicacaoAT": "Código telefone inserido com sucesso.",
			"anularDocumento": "Documento anulado com sucesso.",
			"notaCreditoSucesso": "Devolução efetuada com sucesso.",
			"comunicadoAoEFatura": "Documento comunicado à AT, anulação não permitida utilizar Devolução",
			"comunicadoAT": "Comunicado AT",
			"transformarDocumento": "Documento transformado com sucesso.",
			"duplicarDocumento": "Documento duplicado com sucesso.",
			"copiarLinhas": "Linhas do documento copiadas com sucesso.",
			"meiosPagamentoVazios": "Valor do documento não atribuído aos meios de pagamento",
			"meiosPagamentoOk": "Meios de pagamento atribuídos",
			"encerrarDocumento": "Documento finalizado com sucesso",
			"desmarcadoDocTransformado": "Documento marcado como não transformado",
			"transformarLinhasDocumento": "Linhas do documento transformadas com sucesso."
		},
		"erros": {
			"comunicacaoAT": "Erro na comunicação à AT.",
			"notselected": "Nenhum documento selecionado.",
			"emptycodAT": "Código AT vazio.",
			"anularDocumento": "Erro na anulação do documento.",
			"emptyMotivo": "Motivo da anulação vazio.",
			"inserirCodComunicacaoAT": "Erro a inserir o código AT.",
			"notaCreditoErro": "Erro ao efetuar devolução.",
			"emptyMotivoNotaCredito": "Motivo da devolução vazio.",
			"anulacaoFisica": "Anulação física",
			"transformarDocumento": "Erro a transformar o documento.",
			"docJaTransformado": "O documento já foi transformado.",
			"docAnulado": "O documento encontra-se anulado.",
			"naoCriouPastaDoc": "Erro a criar a pasta do documento na pasta gestão comercial",
			"anexarGDoc": "Erro a anexar ficheiro(s)",
			"duplicarDocumentoErro": "Erro a duplicar documento",
			"copiarLinhas": "Erro a copiar linhas",
			"duplicarDocumentoNDocFaNulo": "O tipo de documento não pode ser nulo",
			"duplicarDocumentoNContaNulo": "O nº de conta não pode ser nulo",
			"docSemLinhas": "O documento não tem linhas",
			"docSemValorLinhasMP": "O documento não tem valor para distribuir nos meios de pagamento",
			"meiosPagam": "Erro a mostrar os meios de pagamento",
			"editarDocumento": "Erro a editar documento",
			"encerrarDocumento": "Erro a finalizar documento",
			"desmarcadoDocTransformado": "Erro a marcar documento como não transformado",
			"moralInvalidSelected": "Não selecionou uma morada alternativa existente.",
			"tipoDocNaoIntegraStock": "Tipo de documento não integra em stocks.",
			"noSelArtigos": "Tem de selecionar pelo menos um artigo.",
			"notaCreditoSemCliente": "É necessário um {{clifo}} para efetuar uma nota de crédito.",
			"reAbreDocumento": "Erro a re-abrir documento",
			"anularDocumentoComNotaCredito": "Não pode anular um documento, pois existe um documento retificativo."
		},
		"auxiliares": {
			"motivoNotaCredito": "Motivo da devolução",
			"motivo": "Motivo",
			"motivoTitle": "Motivo da anulação",
			"anular": "Anular",
			"anexarDocumento": "Anexar documento",
			"duplicarDocumento": "Duplicar documento",
			"copiarLinhasDocumento": "Copiar linhas",
			"meiosPagamento": "Meios de pagamento",
			"documento": "Documento",
			"copiaDocumentoLinhas": "Cópia de documento/linhas",
			"importarLinhasTemplate": "Importar linhas – Template",
			"calcular": "Calcular",
			"arredondamentoManual": "Arredondamento manual",
			"alterarCamposSerie": "Alterar campos em série",
			"rastreabilidade": "Rastreabilidade",
			"gestaoEncomendas": "Encomendas pendentes",
			"selecionarDocumento": "Selecionar documento",
			"saldoEmAberto": "Saldo em aberto",
			"anularDocumento": "Anular documento",
			"tooltips": {
				"addTextoLivre": "Adicionar texto livre",
				"copiaDocumentoLinhasTooltip": "Permite duplicar um documento ou copiar apenas as linhas",
				"importarLinhasTemplateTooltip": "Permite importar um template preparado para folha de cálculo",
				"pesquisaDocsGlobais": "Pesquisa de documentos globais",
				"limparCampo": "Limpar campo",
				"obterUltimoDocumentoGlobalCriado": "Obter o último documento global criado",
				"atualizarCambio": "Atualizar câmbio para data do documento",
				"comprasDataDoc": "Data do registo no sistema",
				"comprasNDocExterno": "Referência externa impressa no documento",
				"comprasDataDocExterno": "Data externa impressa no Documento"
			},
			"recibosPagamRelComDocSeraoAnulados": "Os recibos/pagamentos relacionados com o documento a anular também serão anulados. Pretende continuar ?",
			"recibosPagamRelComDocFinalizadoSeraoAnulados": "Os recibos/pagamentos relacionados com o documento finalizado a editar serão anulados. Pretende continuar ?",
			"nDocAsString": "Nº documento",
			"docFinalizadoApenasEditarCamposNaoComunicEFatura": "O documento encontra-se no estado Finalizado, é apenas permitido editar campos não comunicados no eFatura."
		},
		"codATPerPhone": "Comunicação documentos AT",
		"markAsCompleted": "Marcar como \"Concluído\"",
		"configs": {
			"showObservacoes": "Mostrar observações",
			"showDocExterno": "Mostrar doc. externo",
			"showDataDoc": "Mostrar data doc.",
			"showDataDocExterno": "Mostrar data doc. externo",
			"showDataVenc": "Mostrar data vencimento",
			"showCCusto": "Mostrar centro de custo",
			"showMoeda": "Mostrar moeda",
			"showNRefProcesso": "Mostrar processo",
			"showDescontos": "Mostrar colunas de descontos",
			"showNDoc": "Mostrar número do documento",
			"showNArmazem": "Mostrar armazém",
			"showCodUnidMov": "Mostrar unidade movimento",
			"showValorCIva": "Mostrar valor c/ IVA",
			"showValorSIva": "Mostrar valor s/ IVA",
			"showNVendedor": "Mostrar vendedor",
			"showNNumer": "Mostrar série",
			"showNRequisicao": "Mostrar número requisição",
			"showLiquido": "Mostrar líquido",
			"showNDocExterno": "Mostrar nº doc. externo",
			"showD2": "Mostrar desconto 2",
			"showD3": "Mostrar desconto 3",
			"showDescEmValor": "Mostrar desc. em valor",
			"showDiferimentos": "Mostrar diferimentos",
			"showPerguntaSeFinalizaDoc": "Mostrar confirmação de finalização",
			"obtemDadosDocDigital": "Obtém dados do documento digital"
		},
		"actions": {
			"emitirRecibo": "Emitir recibo",
			"pdf": "PDF",
			"duplicarDoc": "Duplicar doc.",
			"comunicarDocAt": "Comunicar AT",
			"editarDocumento": "Editar",
			"encerrarDocumento": "Finalizar",
			"artigos": "Abrir artigo",
			"rastreabilidade": "Rastreabilidade",
			"lotes": "Ver lotes",
			"adiantRegDocAtual": "Adiantamentos - Registar no doc. atual",
			"adiantRegDocNew": "Adiantamentos - Registar em novo doc.",
			"adiantRegDoc": "Registar adiantamento",
			"adiantConsult": "Regularizar adiantamentos",
			"movimentosEmAberto": "Movimentos em aberto",
			"extratoConta": "Extrato de conta",
			"txtFaturacao": "Notas de faturação",
			"emitirPagamento": "Emitir pagamento"
		},
		"btn": {
			"importDoc": "Importar documento",
			"meiosPagam": "Meios de pagamento",
			"addMeiosPagam": "Adicionar meios de pagamento",
			"closeMeiosPagam": "Fechar meios de pagamento",
			"calculate": "Calcular",
			"arredManual": "Arredondamento manual",
			"altCamposSerie": "Alterar campos em série",
			"rastreabilidade": "Rastreabilidade",
			"save": "Guardar",
			"saveDraft": "Rascunho",
			"savePdf": "PDF",
			"anexarDoc": "Anexar",
			"cancelar": "Cancelar",
			"gestaoDocsEditaveis": "Gestão de documentos editáveis",
			"encerrarDocumentos": "Finalizar documentos",
			"anularDocumentos": "Anular documentos",
			"diferimentos": "Diferimentos",
			"finalizar": "Finalizar",
			"anular": "Anular",
			"gestaoRascunhos": "Gestão de rascunhos"
		},
		"tabs": {
			"carga": "Carga",
			"descarga": "Descarga",
			"transportadora": "Transportadora",
			"faturacaoPublica": "Faturação pública",
			"linhas": "Linhas",
			"documentos": "Documentos",
			"docsEditaveis": "Documentos editáveis",
			"gestaoenc": "Pendentes",
			"porProcessar": "Por processar",
			"processado": "Processado",
			"transporte": "Transporte",
			"anexos": "Anexos",
			"finalizados": "Finalizados",
			"rascunho": "Em Rascunho"
		},
		"modals": {
			"saveDoc": "Guardar documento",
			"gestaoDocsEditaveis": "Gestão de documentos editáveis",
			"saveMessageOkCancel": "Esta ação é irreversível. Pretende mesmo finalizar o documento?",
			"newItem": "Novo artigo",
			"createNewItem": "O artigo não existe. Pretende criar um novo artigo?",
			"info": {
				"naoMostraSaveMsgOkCancel": "Não voltar a mostrar esta questão"
			},
			"anexosPromptSaveDraft": "Para anexar o ficheiro é necessário gravar o documento. Pretende gravar documento como rascunho?",
			"precoUnitario": {
				"modalTitle": "Calcular preço",
				"strings": {
					"artigo": "Artigo",
					"taxa": "Taxa",
					"qtd": "Quantidade",
					"precoUnitarioSemIva": "Preço s/ IVA",
					"precoUnitarioComIva": "Preço c/ IVA"
				}
			}
		},
		"questions": {
			"confirmSave": "Tem a certeza que deseja guardar o documento?"
		},
		"legend": {
			"anulado": "Anulado"
		},
		"importadorLinhas": {
			"modalTitle": "Importador de linhas",
			"extraFields": {
				"nomeartigo": "Descrição artigo",
				"cunimo": "Unidade de movimento",
				"narmazem": "Armazém",
				"nvendedor": "Vendedor",
				"nccusto": "Centro de custo",
				"nrefprocesso": "Processo"
			},
			"steps": {
				"welcome": {
					"title": "Bem-vindo",
					"text1": "Bem-vindo ao assistente de importação de linhas. Este assistente vai guiá-lo no processo de importação.",
					"text2": "Pode selecionar campos extras para exportação"
				},
				"downloadModel": {
					"title": "Descarregar modelo",
					"text1": "Por favor carregue no botão abaixo para descarregar o modelo de importação de linhas.",
					"text2": "Após descarregar o ficheiro, preencha o mesmo com os dados que deseja importar.",
					"text3": "Atenção: É necessário ter um software de edição de folhas de cálculo. É recomendado que utilize o Microsoft Excel.",
					"downloadButton": "Descarregar modelo de importação"
				},
				"uploadModel": {
					"title": "Carregar modelo preenchido",
					"text1": "Preencha o ficheiro com os dados que deseja importar.",
					"text2": "Após preencher o ficheiro, faça o carregamento do ficheiro para continuar a importação."
				},
				"resumo": {
					"title": "Resumo",
					"text1": "Importação de linhas terminada.",
					"text2": "Foram importadas {{total}} linhas. Clique em \"Finalizar\" para inserir as linhas importadas com sucesso ao documento.",
					"text3": "Ocorreu pelo menos um erro durante a importação de linhas",
					"listaErros": "Lista de erros"
				}
			},
			"promptMsg": "Tem dados da importação em memória. Tem a certeza que pretende continuar e descartar os dados?"
		},
		"artigosAdvancedSearch": {
			"title": "Pesquisa avançada de artigos"
		},
		"clifo": {
			"action": {
				"newContaCorrente": "Vai ser criada uma nova conta corrente",
				"newContaOcasional": "Vai ser criada uma nova conta ocasional",
				"contaCorrente": "Conta corrente",
				"contaOcasional": "Conta ocasional"
			}
		}
	},
	"encomendas": {
		"title": "Encomendas",
		"btn": {
			"initProce": "Processar"
		},
		"header": {
			"clientes": "Clientes",
			"artigos": "Artigos",
			"dataDoc": "Data documento de",
			"dataPrevEnt": "Data prevista entrega de",
			"clifoPlaceHolder": "Selecione um cliente / fornecedor para processar",
			"fornecedorPlaceHolder": "Selecione um fornecedor para processar",
			"clientePlaceHolder": "Selecione um cliente para processar"
		},
		"table": {
			"nDoc": "Nº enc.",
			"dataDoc": "Data doc.",
			"nArtigo": "Cód. artigo",
			"nomeArtigo": "Artigo",
			"nClifo": "Nº conta.",
			"nomeClifo": "Conta",
			"nArmaz": "Armazém",
			"nomeArmaz": "Nome armazém",
			"prVendaQtd1": "Preço",
			"qtdStockNoArmazemOrigem": "Stock",
			"qtd1": "Encomendado",
			"qtd1Fact": "Processado",
			"ligacaoQtd": "A processar",
			"dataEntregaPrev": "Data entrega prevista",
			"nRequisicao": "Requisição",
			"nRefProcesso": "Processo",
			"nCCusto": "Centro de custo"
		},
		"badges": {
			"partSatif": "Parcialmente processada",
			"totalSatif": "Totalmente processada",
			"linhaTextLivre": "Linha com texto livre",
			"satisfMais": "Processada a mais"
		},
		"actions": {
			"satTotal": "Quantidade total (F9)",
			"satTotalArtigo": "Quantidade total em todas as linhas (Artigo)",
			"satTotalDocumento": "Quantidade total em todas as linhas (Documento)",
			"satLinhaSemFat": "Processar linha sem faturar (F7)",
			"limparQtdSat": "Limpar quantidade a processar",
			"limparQtdSatTodas": "Limpar quantidade a processar de todas as linhas",
			"selecionarClifo": "Selecionar {{clifo}}",
			"verDocumento": "Ver documento",
			"satisfClifo": "Processar {{clifo}}",
			"showArtigos": "Mostra artigos",
			"showArmazens": "Mostra armazéns",
			"showDocFas": "Mostra tipos de documento",
			"verDadosClifo": "Ver processado do {{clifo}}",
			"showTextoLivre": "Mostra texto livre"
		},
		"modal": {
			"btns": {
				"finalize": "Finalizar",
				"finalizePreviewDoc": "Finalizar e ver documento",
				"finalizeEditarDoc": "Finalizar e editar documento",
				"editDoc": "Editar documento",
				"preencherAuto": "Preencher lotes auto",
				"lotesOk": "Ok"
			},
			"processamento": {
				"title": "Processamento de encomenda",
				"steps": {
					"ident": "Identificação",
					"carga": "Carga",
					"descarga": "Descarga",
					"lote": "Lotes",
					"preview": "Resumo"
				},
				"fields": {
					"nDocFa": "Tipo documento",
					"nNumer": "Série",
					"cliente": "Cliente",
					"fornecedor": "Fornecedor",
					"clifos": "Clientes/Fornecedores",
					"dataDoc": "Data documento",
					"dataInicTran": "Início transporte",
					"morada": "Morada",
					"codPostal": "Código postal",
					"localidade": "Localidade",
					"codPais": "País",
					"moradaAlt": "Morada alternativa",
					"observacoes": "Observações",
					"horaInicTran": "Hora início de transporte"
				},
				"messages": {
					"proceSuccess": "Processamento concluído com sucesso.",
					"atribuirLotes": "Tem de atribuir os lotes nas linhas com artigos com lotes para poder simular.",
					"notAutoSelLotesEntradaStock": "Não é possível selecionar automaticamente os lotes numa entrada de stock.",
					"naoTemLinhas": "Não existem linhas para processar.",
					"naoTemLinhasComLotes": "Não existem linhas com lotes para preencher automaticamente."
				}
			},
			"documento": {
				"title": "Documento"
			},
			"impressao": {
				"title": "Impressão",
				"fields": {
					"dataDocDe": "Data doc. de",
					"dataEntregaPrevDe": "Data prev. entrega de",
					"estadoEncomendas": "Estado encomendas",
					"armazens": "Armazéns",
					"docfaclist": "Tipos de documento"
				},
				"estado": {
					"soPendentes": "Por processar",
					"soSatisfeitas": "Processado"
				}
			},
			"simulacao": {
				"title": "Simulação",
				"fields": {
					"nConta": "Nº conta",
					"nome": "Nome",
					"totalIva": "IVA",
					"totalLiquido": "Líquido"
				}
			},
			"satisfazerclifo": {
				"title": "Encomendas pendentes"
			},
			"lotes": {
				"title": "Seleção de lotes",
				"messages": {
					"existemLinhasSemLotesAtribuidos": "Existe(m) lote(s) sem quantidade a processar preenchida para a(s) encomenda(s)",
					"qtdLotesNaoEIgualLinha": "A quantidade ({{qtdLotes}}) do artigo {{nArtigo}} distribuída pelos lotes não é igual à da linha: {{ligacaoQtd}}."
				}
			}
		},
		"messages": {
			"semLinhasSati": "Não existe quantidade a processar preenchida para a(s) encomenda(s).",
			"atribuicaoDeLotesCancelada": "Atribuição de lotes no processamento de encomendas cancelada.",
			"processamentoLotesEncomendaFalhou": "Processamento de lotes na(s) encomenda(s) falhou.",
			"infoProcessamentoClifo": "É necessário pesquisar para depois selecionar um {{clifo}} em baixo ou na grelha para processar as encomendas",
			"linhasComValorNulo": "Existem linhas com valor nulo na quantidade a processar"
		},
		"errors": {
			"clientenaoselecionado": "Insira o {{clifo}} para processar as encomendas pendentes",
			"valorAProcessarNulo": "O valor a processar não pode ser nulo"
		},
		"keyboardShortcuts": {
			"processarTotalmente": "Colocar quantidade total para processar da linha da encomenda",
			"processarLinhaSemFaturar": "Processar linha da encomenda sem faturar"
		}
	},
	"documentoFaturacao": {
		"btn": {
			"communicateFaturaAT": "Comunicar AT",
			"communicateDocTransporteAT": "Comunicar doc. transp. AT",
			"insertATCode": "Inserir cód. AT",
			"notaCredito": "Devolução",
			"anular": "Anular",
			"transformarDoc": "Transformar doc.",
			"transformarLinhasDoc": "Transformar linhas",
			"pdf": "Pdf",
			"opcoes": "Opções",
			"anexos": "Anexos",
			"duplicarDoc": "Duplicar doc.",
			"copiarLinhas": "Copiar linhas doc.",
			"meiosPagam": "Meios de pagamento",
			"editarDocumento": "Editar",
			"encerrarDocumento": "Finalizar",
			"desmarcadoDocTransformado": "Desmarcar transformado"
		},
		"modals": {
			"encerrar": {
				"title": "Finalizar documento",
				"caracteres": "Caracteres: {{numChar}}/50"
			},
			"questions": {
				"confirmEncerrar": "Tem a certeza que deseja finalizar o documento com o nº {{id}}?"
			}
		},
		"summary": "Resumo",
		"retention": "Retenção"
	},
	"grupoDoc": {
		"outros": "Outros",
		"comprasEfetivas": "Compras efetivas",
		"consultasAFornecedores": "Consultas a fornecedores",
		"encomendasClientes": "Encomendas clientes",
		"encomendasFornecedores": "Encomendas fornecedores",
		"folhasDeObra": "Folhas de obra",
		"guiasTransporteRemessa": "Guias transporte remessa",
		"propostasAClientes": "Propostas a clientes",
		"reservasClientes": "Reservas clientes",
		"trfArmazensEntradas": "Trf. armazéns entradas",
		"trfArmazensSaidas": "Trf. armazéns saidas",
		"vendasEfectivas": "Vendas efetivas",
		"consignacoesClientes": "Consignações clientes",
		"consignacoesFornecedores": "Consignações fornecedores",
		"recibosClientes": "Recibos clientes",
		"pagamentosFornecedores": "Pagamentos fornecedores",
		"none": "Sem info",
		"planoProducao": "Plano produção",
		"preEncomendasClientes": "Pré-encomendas clientes",
		"custosAdicionais": "Custos adicionais",
		"notaCredito": "Nota crédito",
		"guiasTransporteFornecedores": "Guias transporte fornecedores",
		"entradasDiversas": "Entradas diversas",
		"saidasDiversas": "Saídas diversas"
	},
	"arredondamentoManual": {
		"title": "Documento comercial - Arredondamento manual",
		"gridHeader": {
			"documento": "Documento",
			"arredondamento": "Arredondamento"
		},
		"gridFields": {
			"codIva": "Cód. IVA",
			"taxaIva": "Taxa",
			"prVenda1": "Incidência",
			"valorIva": "IVA",
			"arredLiquidoManualEditavel": "Incid. arred. manual",
			"arredIVAManualEditavel": "IVA arred. manual",
			"incidenciaTotalEditavel": "Incid. total",
			"ivaTotalEditavel": "IVA total"
		},
		"messages": {
			"warningMaxValue": "O arredondamento pretendido em absoluto ultrapassa o máximo permitido ({{value}})",
			"totalIvaLiquido": "Arredondamento manual: Líquido {{totalLiquido}} e IVA {{totalIva}}",
			"totalLiquido": "Arredondamento manual: Líquido {{totalLiquido}}",
			"totalIva": "Arredondamento manual: IVA {{totalIva}}"
		},
		"resumo": {
			"totalIva": "Total IVA",
			"totalIncidencia": "Total incidência"
		}
	},
	"alterarCamposSerie": {
		"title": "Selecionar os campos para alterar"
	},
	"gestaoDocsComerciais": {
		"title": "Gestão de documentos em rascunho",
		"filters": {
			"nDocFa": "Tipo doc.",
			"deDataDoc": "Data doc. de"
		},
		"fields": {
			"mensagemErro": "Mensagem"
		},
		"messages": {
			"encerrarFullSuccess": "Os documentos foram todos finalizados com sucesso.",
			"encerrarSuccessWithErrors": "Foram finalizados {{nDocsSuccess}} dos {{nDocs}} selecionados.",
			"encerrarError": "Ocorreu um erro ao finalizar os documentos, nenhum documento foi encerrado.",
			"anularFullSuccess": "Os documentos foram todos anulados com sucesso.",
			"anularSuccessWithErrors": "Foram anulados {{nDocsSuccess}} dos {{nDocs}} selecionados.",
			"anularError": "Ocorreu um erro ao anular os documentos, nenhum documento foi encerrado.",
			"anularExistemDocumentosEncerrados": "Existem documentos encerrados, pretende seguir com a anulação dos mesmos?",
			"existemDocumentosEncerrados": "Existem documentos selecionados que já se encontram encerrados.",
			"naoExistemDocsSelecionados": "Não existem documentos selecionados.",
			"artigoNaoExiste": "Artigo não existe.",
			"anularAviso": "Esta ação é irreversível. Pretende mesmo anular o documento?"
		},
		"legend": {
			"documentoComErro": "Documento com erro"
		}
	},
	"pesquisaDocumentos": {
		"title": "Pesquisa de documentos"
	},
	"selecaoLotes": {
		"title": "Seleção de lotes",
		"tooltips": {
			"lote": "Lotes"
		}
	},
	"devolucao": {
		"caption": {
			"artigoQuantidade": "Devolução de artigo",
			"artigoValor": "Desconto de artigo",
			"documentoValor": "Desconto de documento",
			"documentoExterno": "Externo",
			"stepTipo": "Tipo",
			"documentoDestino": "Documento de destino",
			"emQuantidade": "Em quantidade",
			"emValor": "Em valor",
			"tipoDocOrigem": "Tipo de documento de origem",
			"docExternoOrigem": "Documento externo (origem)",
			"dataDocExternoOrigem": "Data documento externo (origem)",
			"tipoDocumento": "Tipo de documento",
			"devolucao": "Devolução",
			"deArtigo": "Artigo de",
			"docExternoPlaceholder": "Exemplo: FT 001/1",
			"artigoDesconto": "Artigo de desconto",
			"valorDocExterno": "Valor total",
			"adicionar": "Adicionar",
			"dataDoc": "Data doc.",
			"desdeDataDoc": "Data doc. desde",
			"quantidade": "Quantidade",
			"codIva": "Cód. IVA"
		},
		"messages": {
			"sucesso": "Documento de devolução gerado com sucesso.",
			"escolherTipo": "Escolha o tipo de devolução que pretende efetuar",
			"erroTipoDoc": "Tem de selecionar um tipo de documento de origem.",
			"erroDocExterno": "Documento externo (origem) é obrigatório.",
			"erroMotivo": "Tem que indicar o motivo da devolução.",
			"erroDocExternoValor": "O valor a devolver tem ser maior que zero.",
			"erroLinhas": "Não tem linhas selecionadas.",
			"erroArtigoDevolucao": "Tem que indicar um artigo de devolução.",
			"erroValor": "O valor a devolver não pode ser 0.",
			"erroValorSuperior": "O valor inserido para regularizar ultrapassa o valor a regularizar (A regularizar: {{porDevolver}} | Inserido: {{aDevolver}}).",
			"devolucaoEditar": "Este documento já contém linhas de devolução.<br>Escolha a opção que pretende realizar."
		},
		"table": {
			"facliId": "FacliId",
			"nDoc": "Documento",
			"porDevolver": "Por devolver",
			"aDevolver": "A devolver"
		},
		"tooltip": {
			"devolveTudo": "Devolver tudo",
			"limpaTudo": "Limpar todos os valores devolvidos",
			"limpaDevolvido": "Limpar valor devolvido",
			"adicionarMotivo": "Adicionar à lista de motivos",
			"artigoQuantidade": "Usado para efetuar devoluções de quantidade de produtos ou mercadorias",
			"documentoValor": "Usado para efetuar um desconto sobre um ou vários documentos",
			"artigoValor": "Usado para efetuar um desconto sobre um artigo em especifico",
			"documentoExterno": "Usado para regularizar um documento que não está presente no sistema"
		},
		"placeholder": {
			"motivo": "Digitar ou pesquisar",
			"artigoParaDesconto": "Artigo para desconto"
		}
	},
	"gruposdocfas": {
		"configuracoes": {
			"title": "Configurações ",
			"modofuncionamento": {
				"label": "Modo de funcionamento",
				"options": {
					"processo": "Processo",
					"centrocusto": "Centro de custo"
				}
			},
			"gastos": {
				"title": "Gastos",
				"estimado": {
					"gridtitle": "Estimado"
				},
				"real": {
					"gridtitle": "Real"
				}
			},
			"rendimentos": {
				"title": "Rendimentos",
				"estimado": {
					"gridtitle": "Estimado"
				},
				"real": {
					"gridtitle": "Real"
				}
			},
			"grids": {
				"fields": {
					"id": "Tipo de documento",
					"name": "Nome",
					"desc": "Descrição",
					"sinal": "Sinal"
				},
				"enums": {
					"sinal": {
						"mais": "+",
						"menos": "-"
					}
				}
			},
			"avisos": {
				"tipodocnecessario": "É necessário selecionar um tipo de documento para adicionar.",
				"tipodocexistente": "O tipo de documento '{{id}}' já se encontra na lista."
			},
			"messages": {
				"configsavedsucess": "Configuração dos grupos de tipos de documentos guardados com sucesso."
			},
			"modal": {
				"title": "Configurações dos grupos de tipos de documentos"
			}
		}
	},
	"analisemargemlucro": {
		"title": "Análise de margem de lucro",
		"bands": {
			"gastos": "Gastos",
			"rendimentos": "Rendimentos",
			"resultado": "Resultado",
			"margem": "Margem %"
		},
		"fields": {
			"nrefprocesso": "Cód. processo",
			"nomeprocesso": "Processo",
			"nccusto": "Cód. centro custo",
			"nomeccusto": "Centro de custo",
			"nconta": "Nº conta",
			"nomeconta": "Nome conta",
			"classificador": "Classificador",
			"estimado": "Estimado",
			"real": "Real",
			"gastoestimado": "Gasto estimado",
			"gastoreal": "Gasto real",
			"rendimentoestimado": "Rendimento estimado",
			"rendimentoreal": "Rendimento real",
			"resultadoestimado": "Resultado estimado",
			"resultadoreal": "Resultado real",
			"margemestimado": "Margem estimada",
			"margemreal": "Margem real"
		},
		"messages": {
			"semregistos": "Não existem registos para a análise de margem de lucro.",
			"dataAteMenorDataDe": "A data \"Até\" tem de ser superior ou igual à \"De data doc.\"."
		},
		"detail": {
			"title": "Detalhe de registo da análise de margem de lucro",
			"fields": {
				"tipologia": "Tipologia",
				"ndocfa": "Cód. tipo de doc.",
				"nomedocfa": "Tipo de documento",
				"nnumer": "Série",
				"ndocumento": "Nº documento",
				"ndocasstring": "Documento",
				"datadoc": "Data doc.",
				"nconta": "Nº conta",
				"nomeconta": "Nome conta",
				"nartigo": "Cód. artigo",
				"nomeartigo": "Artigo",
				"qtd1": "Qtd.",
				"unidade": "Unidade",
				"prvendaqtd1": "Preço unitário",
				"liquido": "Líquido",
				"valoriva": "IVA",
				"nFamilia": "Cód. família",
				"nomeFamilia": "Família",
				"nTpArt": "Cód. tipo artigo",
				"nomeTpArt": "Tipo de artigo",
				"nGrFamilia": "Cód. gr. família",
				"nomeGrFamilia": "Gr. família",
				"nSubFa": "Cód. sub-família",
				"nomeSubFa": "Sub-família"
			},
			"actions": {
				"documento": "Documento"
			}
		},
		"estado": {
			"aberto": "Em aberto",
			"terminado": "Terminado",
			"ambos": "Ambos"
		},
		"caption": {
			"estado": "Estado",
			"nrefprocesso": "Processo",
			"nccusto": "Centro de custo",
			"datade": "Data doc. de"
		},
		"modal": {
			"print": {
				"title": "Impressão da análise da margem de lucro"
			}
		}
	},
	"docscontabilidade": {
		"title_new": "Novo Documento",
		"title_edit": "Documento {{id}}",
		"title_plural": "Documentos Contabilidade",
		"title_singular": "Documento de contabilidade",
		"pesquisa": "Pesquisar",
		"goSearch": "Pesquisa de documentos",
		"goSearchMobile": "Pesquisa",
		"clearForm": "Limpar Campos",
		"saved": "Documento de contabilidade {{id}} guardado com sucesso.",
		"error": "O documento de contabilidade não pode ser guardado. Por favor, verifique os campos.",
		"tooltipNDocumento": "Este identificador é apenas sugestivo, o mesmo pode mudar após a gravação do documento",
		"tooltipNextDocumento": "Documento seguinte deste período/diário<br>(Ctrl + Seta direita)",
		"tooltipPreviousDocumento": "Documento anterior deste período/diário<br>(Ctrl + Seta esquerda)",
		"tooltipFirstDocumento": "Primeiro documento deste período/diário<br>(Ctrl + Shift + Seta esquerda)",
		"tooltipLastDocumento": "Último documento deste período/diário<br>(Ctrl + Shift + Seta direita)",
		"fields": {
			"extPocCabID": "Identificador de documento",
			"periodo": "Período",
			"nomePeriodo": "Período",
			"nDiario": "Diário",
			"nomeDiario": "Diário",
			"nDocInterno": "Nº doc. interno",
			"totalDebitoGeral": "Total débito",
			"totalCreditoGeral": "Total crédito",
			"dataLancamento": "Data lançamento contab.",
			"dataVencimento": "Data vencimento",
			"dataDoc": "Data documento",
			"codDescritivo": "Cód. descritivo",
			"nomeDescritivo": "Descritivo",
			"descricao": "Descrição",
			"codMoeda": "Moeda",
			"nomeMoeda": "Moeda",
			"nContribuinte": "Nº contribuinte",
			"documentoExterno": "Doc. externo",
			"stampUpdate": "Ult. alteração",
			"nDocumento": "Nº documento",
			"nDocumentoFilter": "Nº documento (ex: 2024011.1.345)",
			"preDefinido": "Pré-definido",
			"anulado": "Anulado",
			"nconta": "Nº conta",
			"valor": "Valor",
			"temDocDigital": "Doc. digital",
			"nUtilizador": "Utiz. Criou Reg."
		},
		"doc": {
			"cab": {
				"extPocCabID": "Identificador de documento",
				"periodo": "Período",
				"nomePeriodo": "Período",
				"nDiario": "Diário",
				"nomeDiario": "Diário",
				"nDocInterno": "Nº doc. interno",
				"totalDebitoGeral": "Total débito",
				"totalCreditoGeral": "Total crédito",
				"dataLancamento": "Data lançamento",
				"dataVencimento": "Data vencimento",
				"dataDoc": "Data documento",
				"codDescritivo": "Cód. descritivo",
				"nomeDescritivo": "Descritivo",
				"descricao": "Descrição",
				"codMoeda": "Moeda",
				"nomeMoeda": "Moeda",
				"nContribuinte": "Contribuinte",
				"documentoExterno": "Doc. externo",
				"stampUpdate": "Ult. alteração",
				"nDocumento": "Nº documento",
				"totalCredidoBaseTributavel": "Total crédito base tributável",
				"totalDebitoBaseTributavel": "Total débito base tributável",
				"totalCredidoIva": "Total crédito IVA",
				"totalDebitoIva": "Total débito IVA",
				"cambioRef": "Referência de câmbio",
				"moeda": "Moeda",
				"anulado": "Anulado"
			},
			"linhas": {
				"nLanc": "Identificador de linha",
				"nLancImput": "Identificador de imputação",
				"extPocCabID": "Identificador de documento",
				"nConta": "Nº conta",
				"periodo": "Período",
				"nDiario": "Diário",
				"nDocInterno": "Nº doc. interno",
				"nSeq": "Sequência",
				"dc": "Débito/Crédito",
				"valor": "Valor",
				"valorME": "Valor ME",
				"codMovAberto": "Cód. movimento em aberto",
				"valorPago": "Valor pago",
				"valorPagoME": "Valor pago ME",
				"codMoeda": "Cód. moeda",
				"cambio": "Câmbio",
				"nDescr": "Cód. descritivo",
				"dataLancamento": "Data lançamento contab.",
				"dataDoc": "Data documento",
				"dataVencimento": "Data vencimento",
				"nDocExterno": "Doc. externo",
				"dataDocExt": "Data doc. externo",
				"descricao": "Descrição",
				"nContrib": "Nº contribuinte",
				"codIva": "Cód. IVA",
				"valorTaxa": "Valor IVA",
				"valorExistente": "{{type}} atual",
				"valorOriginal": "{{type}} original",
				"valorTaxaME": "Valor taxa ME",
				"cDecPer": "Dec. periódica",
				"cDecAnual": "Dec. anual",
				"codControloIva": "Cód. controlo IVA",
				"nGrupo": "Grupo",
				"classificControlo": "Class. controlo",
				"valorDesconto": "Valor desconto",
				"valorRetencao": "Valor retenção",
				"nUtilizador": "Nº utilizador",
				"nLancOrigemIva": "nLancOrigemIva",
				"nLancDestinoIva": "nLancDestinoIva",
				"valorOrigemIva": "valorOrigemIva",
				"valorDestinoIva": "valorDestinoIva",
				"nLancOrigemRetencao": "nLancOrigemRetencao",
				"nLancDestinoRetencao": "nLancDestinoRetencao",
				"valorOrigemRetencao": "valorOrigemRetencao",
				"valorDestinoRetencao": "valorDestinoRetencao",
				"codRetencao": "Cód. retenção",
				"taxaRetencaoUsada": "Taxa retenção usada",
				"nGrupoRetencao": "Gripo retenção",
				"classificControloRetencao": "Classific. controlo retenção",
				"tipoRetServNIF": "tipo retenção NIF",
				"extPocLigaIDOrigemRetNaoDispo": "extPocLigaIDOrigemRetNaoDispo",
				"nLancOrigemImputacaoCCusto": "nLancOrigemImputacaoCCusto",
				"nLancDestinoImputacaoCCusto": "nLancDestinoImputacaoCCusto",
				"nContaDebito": "Conta débito",
				"nContaCredito": "Conta crédito",
				"temMovAberto": "Tem movimentos em aberto",
				"poc": {
					"nConta": "Nº conta",
					"nContaPlaceholder": "Código da conta",
					"nome": "Nome",
					"nomePlaceholder": "Nome da conta",
					"tipo": "Tipo conta",
					"cc": "Tem conta corrente",
					"nif": "Nº contribuinte",
					"temCCusto": "Tem analítica",
					"temIVA": "Tem controlo IVA",
					"registaRetencao": "Regista retenção",
					"temRetencao": "Tem retenção",
					"bloqueada": "Bloqueada",
					"dpq06NCampo": "Dec. periódica IVA",
					"dpq06NCampoNome": "Dec. periódica IVA",
					"daq08NCampo": "Dec. anual IVA - Quadro 03 e 05",
					"daq08NCampoNome": "Dec. anual IVA - Quadro 03 e 05",
					"daq09NCampo": "Dec. anual IVA - Quadro 04 e 05",
					"daq09NCampoNome": "Dec. anual IVA - Quadro 04 e 05",
					"codControloIVADebito": "Cód. controlo IVA débito",
					"codControloIVADebitoNome": "Cód. controlo IVA débito",
					"codControloIVACredito": "Cód. controlo IVA crédito",
					"codControloIVACreditoNome": "Cód. controlo IVA crédito"
				},
				"dcDebito": "Débito",
				"dcCredito": "Crédito"
			},
			"totais": {
				"baseTributavel": "Base tributável",
				"totalIVA": "Total IVA",
				"moedaCorrente": "Moeda corrente",
				"moedaEstrangeira": "Moeda estrangeira",
				"totalGeral": "Total geral",
				"porPagar": "Por pagar"
			},
			"tooltipDataLancamento": "3.4.3.8. Data do movimento contabilístico (GLPostingDate).<br/><br/>Este campo deve ser preenchido com a data relevante para efeitos contabilísticos.",
			"tooltipDataDoc": "3.4.3.3. Data do documento (GLPostingDate).<br/><br/>Deve ser indicada a data impressa no documento que serve de suporte ao registo."
		},
		"erros": {
			"notselected": "Nenhum documento selecionado!",
			"optionsNotAvailable": "Não é possível alterar as opções quando um pré-definido está ativo",
			"changeMoedaNotAvailable": "Para alterar a moeda tem primeiro de eliminar todas as linhas",
			"initDoc": "O documento não foi inicializado. Por favor selecione um <strong>período</strong> e um <strong>diário</strong> para inicializar o documento.",
			"notSaldado": "O documento não está balanceado!",
			"periodoFinalInferiorInicial": "O período final é inferior ao inicial!",
			"nifAlreadyRegistered": "O número de contribuinte \"{{nContribuinte}}\" já se encontra registado."
		},
		"configs": {
			"valorComIvaIncluido": "Valor com IVA incluído",
			"obtemDadosDocDigital": "Obtém dados do documento digital",
			"showFieldPredefinido": "Mostrar campo pré-definido (cabeçalho)",
			"showFieldDataVencimento": "Mostrar campo data vencimento (cabeçalho)",
			"showFieldMoeda": "Mostrar campo moeda (cabeçalho)",
			"showFieldLinhaNif": "Mostrar campo nº contribuinte (linhas)",
			"showFieldLinhaValorTaxa": "Mostrar campo valor IVA (linhas)",
			"showFieldLinhaDataDoc": "Mostrar campo data documento (linhas)",
			"showFieldLinhaDescricao": "Mostrar campo descrição (linhas)",
			"showFieldLinhaCDecPerAnual": "Mostrar campos declaração periódica/anual (linhas)",
			"showFieldLinhaNomeConta": "Mostrar campo nome da conta (linhas)",
			"seletorObtemDadosDocDigital": "Mostrar botão para obter dados do documento digital na secção das linhas",
			"seletorValorComIvaIncluido": "Mostrar botão valor com IVA incluído na secção das linhas",
			"skipDescricao": "Saltar campo da descrição nas linhas",
			"retainDescription": "Manter descrição entre documentos",
			"showFormMoeda": "Mostrar o formulário da moeda estrangeira",
			"skipPromptSave": "Não voltar a perguntar confirmação ao guardar o documento"
		},
		"changeValorTitle": "Alterar {{type}}",
		"changeValorTaxaTitle": "Alterar valor de IVA",
		"changeCCustoTitle": "Atribuição de valores de analítica",
		"changeTitleInvalidLines": "Tem de registar pelo menos um valor",
		"valorValidatorMessage": "O {{type}} tem um limite de diferença máxima de {{value}}{{currency}} relativamente ao {{type}} original",
		"contaTemLigacaoContasCorrentes": "A conta {{nConta}} tem ligação com contas correntes.",
		"desejaEfetuarPagamentos": "Deseja efetuar pagamentos de documentos em contas correntes?",
		"contaAImputarJaExiste": "A conta de analítica a imputar {{nConta}}, já existe na lista de imputações!",
		"valorTemDeSerSuperior0": "O valor ter de ser superior a 0.",
		"disponibilidadeRendimento": "Disponibilidade do rendimento",
		"contaCorrenteTemRetencaoDeduzida": "O valor da conta corrente já tem a retenção deduzida",
		"montanteTributavel": "Montante tributável",
		"montanteRetido": "Montante retido",
		"nDocumento": "Nº documento",
		"changeregularizacaocampo40Title": "Regularizações a favor do sujeito passivo - Abrangidas pelo Art.º 78",
		"nDocumentoRegularicacaoJaExiste": "O nº de documento {{nDocumento}} já existe",
		"valorSomadoJaExistente": "O valor {{valor}} somado ao valor {{valorExistente}} já existente ultrapassa o valor da base tributável {{valorBase}}. {{valor}} + {{valorExistente}} > {{valorBase}}",
		"documentoEliminadoComSucesso": "Documento eliminado com sucesso.",
		"diferimentosAnuladosComSucesso": "Diferimentos/acréscimos anulados com sucesso.",
		"viewDocImputado": "Visualizar documento imputado",
		"viewDocTitle": "Documento {{nDocumento}}",
		"keyboardShortcuts": {
			"save": "Guardar o documento",
			"saveAndAttach": "Guardar e anexar o documento",
			"toggleIva": "Alternar \"Valor com IVA incluído\"",
			"obterSaldoConta": "Obter saldo da conta (apenas na coluna \"Valor\" da linha selecionada)",
			"firstDocumento": "Primeiro documento deste período/diário",
			"lastDocumento": "Último documento deste período/diário",
			"previousDocumento": "Documento anterior deste período/diário",
			"nextDocumento": "Documento seguinte deste período/diário"
		},
		"ccusto": {
			"badTotalTitle": "Confirmação da atribuição de valores da analítica",
			"badTotalMessage": "O valor imputado a centro de custo é diferente do valor total a imputar. Tem a certeza que pretende continuar?"
		},
		"movab": {
			"title": "Pagamentos de documentos em contas correntes",
			"nDocumento": "Nº documento",
			"dc": "D/C",
			"nDocExterno": "Doc. externo",
			"dataDoc": "Data doc.",
			"valor": "Valor",
			"porPagar": "Por pagar",
			"valorPagar": "Valor a pagar",
			"valordesconto": "Desconto",
			"imputarTudo": "Imputar tudo (todas as linhas)",
			"imputarTudoLinha": "Imputar tudo (linha)",
			"limparValores": "Limpar todos os valores imputados",
			"limparValorLinha": "Limpar valor",
			"dcDebito": "D",
			"dcCredito": "C",
			"totalImputar": "Total a imputar",
			"totalJaImputado": "Total já imputado",
			"totalImputado": "Total imputado",
			"descricao": "Descrição",
			"descritivo": "Descritivo",
			"totalConta": "Totais da conta",
			"options": {
				"showDocExt": "Mostrar campo documento externo",
				"showDataDoc": "Mostrar campo data documento",
				"showDescricao": "Mostrar campo descrição",
				"showDescritivo": "Mostrar campo descritivo"
			},
			"movmesmoid": "Estes lançamentos pertencem ao mesmo documento digital.",
			"movmesmoidval": "Estes lançamentos pertencem ao mesmo documento digital com o valor {{value}}.",
			"valorPagarValidationInferior": "O valor não pode ser inferior a 0.",
			"valorPagarValidationSuperior": "O valor não pode ser superior ao valor por pagar.",
			"valorDescontoValidationSuperior": "O valor não pode ser superior ao valor a pagar."
		},
		"regularizacaoCampo40": {
			"title": "Cancelar regularizações a favor do sujeito passivo",
			"message": "Se não indicar os documentos de regularização irá criar inconsistências na declaração de IVA.",
			"validateMessage": "Para continuar tem de ativar a opção \"Tomei conhecimento e pretendo continuar\"",
			"label": "Tomei conhecimento e pretendo continuar"
		},
		"retencao": {
			"title": "Retenção a {{value}}",
			"imediata": "Imediata",
			"noPagamento": "No pagamento",
			"disabledRetencaoDeduzida": "Para alterar este valor é necessário haver uma conta corrente previamente lançada",
			"modelo30": {
				"title": "Modelo 30",
				"nGuiaPagamento": "Guia pagamento"
			},
			"messages": {
				"needCCBefore": "Para efectuar uma disponibilidade de rendimento no ato de pagamento tem de lançar previamente a conta corrente."
			}
		},
		"clearPreDefinido": "Remover pré-definido",
		"clearSuggestion": "Cancelar sugestão",
		"clearSuggestionTitle": "Cancelar sugestão de lançamento",
		"moedaestrangeira": {
			"title": "Lançamento em moeda estrangeira",
			"conta": "Conta",
			"moeda": "Moeda estrangeira",
			"cambioME": "Câmbio ME",
			"valorME": "Valor ME",
			"valorNacional": "Valor nacional"
		},
		"toolbar": {
			"saveAndAttach": "Guardar e anexar",
			"saveWithoutAttach": "Guardar sem anexar",
			"contabilidadeDigital": "Contab. digital",
			"criarPreDefinido": "Criar pré-definido",
			"criarPreDefinidoTooltip": "Criar um pré-definido com base neste documento de contabilidade",
			"infoDiferimentos": "Informação diferimento",
			"infoGlobalDiferimentos": "Informação global de diferimentos",
			"infoGlobalAcrescimos": "Informação global de acréscimos",
			"infoDiferimentosHint": "Informação de acréscimo/diferimento",
			"anularDiferimento": "Anular acréscimo/diferimento"
		},
		"nDocSeq": "Nº doc. seq",
		"text": {
			"maintenancePreDefinidos": "Manutenção de pré-definidos de contabilidade",
			"maintenanceExtratos": "Visualizar extratos de conta",
			"maintenanceMovab": "Visualizar movimentos em aberto",
			"maintenanceClifos": "Manutenção de clientes / fornecedores",
			"recolha": "Recolha",
			"conta": "Conta: {{nConta}} - {{nome}}",
			"saldoConta": "Saldo: {{saldoConta}}",
			"obterSaldoConta": "Obter saldo da conta",
			"modificarRegularizacoesSujeitoPassivo": "Regularizações a favor do sujeito passivo",
			"calculodiferimentos": "Acréscimos e diferimentos",
			"registarNif": "Registar número de contribuinte",
			"marcadoComoConsistente": "Documento integrado com erro e marcado como consistente por {{nome}} em {{data}}",
			"extratosconta": "Extratos de conta",
			"mudarperiodocontab": "Mudar período contabilístico da empresa",
			"confirmMudaPerio": "Tem a certeza que pretende mudar o período contabilístico da empresa para \"{{periodo}}\"",
			"periodoempmudadosuccesso": "Período contabilístico da empresa alterado com sucesso!",
			"docativosconfirm": "Registou um ativo na contabilidade.<br>Deseja registar a aquisição do ativo no módulo de gestão de ativos?",
			"editValorIva": "Editar valor do IVA",
			"incValorIva": "Incrementar valor do IVA",
			"decValorIva": "Decrementar valor do IVA",
			"calculouDiferimentos1": "Foi efetuado o cálculo de acréscimos/diferimentos e por este motivo o documento foi bloqueado. Caso queira efetuar alterações, por favor, ",
			"calculouDiferimentos2": "carregue aqui para anular o cálculo",
			"calculouDiferimentos3": "Deve carregar em \"Guardar\" para aplicar o diferimento no documento.",
			"calculouDiferimentosLocked": "Documento com acréscimo/diferimento associado. Para alterar este documento tem de anular o acréscimo/diferimento associado.",
			"confirmAnulaDiferimento": "Tem a certeza que pretende anular o acréscimo/diferimento?",
			"docImputacao": "Imputado ao documento: {{ndocImput}}"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;' aria-hidden='true'></i> Pretende sair sem gravar o documento?",
			"exitMessage": "Ao clicar em OK irá sair sem gravar o documento contabilístico e todas as alterações que fez neste documento serão apagadas. Tem a certeza que pretende sair do documento?",
			"doNotAskAgain": "Não voltar a perguntar",
			"perguntaCC": "Não voltar a mostrar para esta conta quando o sinal está a {{sinal}}."
		},
		"saveprompt": {
			"saveDocAndAttach": "Guardar documento e anexar documentos digitais",
			"saveDocIdAndAttach": "Guardar documento {{id}} e anexar documentos digitais"
		}
	},
	"docscontabilidadeimprimir": {
		"label": {
			"email": "E-mail",
			"enviar": "Enviar"
		},
		"email": {
			"emailEnviado": "E-mail enviado para {{email}}",
			"emailErro": "Erro a enviar e-mail para {{email}}. Contacte o seu administrador de sistema."
		}
	},
	"docsnaosaldados": {
		"title": "Documentos não saldados",
		"fields": {
			"doPeriodo": "Período de",
			"deConta": "Conta de"
		},
		"alertNaoHaDocs": "Não foram encontrados documentos não saldados."
	},
	"documentosFiscais": {
		"tabIva": "IVA",
		"tabIRC": "IRC",
		"tabRecibos": "Recibos",
		"tabIES": "IES",
		"tabIRS": "IRS",
		"tabIvaDp": "Declarações periódica de IVA",
		"tabIvaDr": "Declarações recapitulativas de IVA",
		"tabIRCPagAnt": "Pagamentos antecipados",
		"tabIRCMod22": "Modelo 22",
		"tabRecibosRVClient": "Recibos verdes como cliente",
		"tabRecibosRR": "Recibos rendas",
		"tabIESConsult": "Consultar",
		"tabIRSMod10": "Modelo 10",
		"tabRetencoes": "Retenções",
		"tabRetencoesIRCIRS": "Retenções IRC/IRS e I. Selo",
		"tabIUC": "IUC",
		"tabVeiculos": "Veículos",
		"tabCertidoes": "Certidões",
		"tabImoveis": "Imóveis",
		"tabImoveisPP": "Património predial",
		"tabImoveisNC": "Notas cobrança",
		"tabPenhoras": "Penhoras",
		"tabSSSituacaoContrib": "Situação contributiva",
		"tabViaCTTInbox": "Caixa de correio",
		"tabIUCDocumentos": "Documentos",
		"tabIUCEmitir": "Emitir",
		"tabConsultarCertidoes": "Consultar certidões",
		"tabEmitirCertidoes": "Emitir certidão",
		"buttons": {
			"get": "Obter",
			"search": "Pesquisar",
			"efetuarPedido": "Efetuar pedido",
			"mb": "Multibanco"
		},
		"fields": {
			"periodo": "Período",
			"identificacao": "Identificação",
			"dataRececao": "Data rececão",
			"valor1": "Imposto entregar",
			"valor2": "Imposto recuperar",
			"comprovativo": "Comprovativo",
			"certidao": "Certidão",
			"docPagamento": "Doc. pagamento",
			"tipo": "Tipo",
			"situacao": "Situação",
			"numero": "Número",
			"dataSubmissao": "Data submissão",
			"submissaoValor": "Valor submissão",
			"dataPagamento": "Data pagamento",
			"pagamentoValor": "Valor pagamento",
			"obterDetalhes": "Detalhes documento",
			"numDocumento": "Nº doc",
			"dataEmissao": "Data emissão",
			"nif": "NIF",
			"nome": "Nome",
			"domicilioFiscal": "Domicílio fiscal",
			"atividadePrestador": "Actividade exercida",
			"titulo": "Título",
			"dataRef": "Data refª",
			"descricao": "Descrição",
			"valorBase": "Valor base",
			"ivaDesc": "Descrição IVA",
			"valorIVA": "Valor IVA",
			"valorImpostoSelo": "Imposto selo",
			"valorIRS": "Valor IRS",
			"importanciaRecebida": "Importância recebida",
			"reciboEco": "Recibo económico",
			"reciboNormal": "Recibo normal",
			"periodoFull": "Período",
			"dataRecebimento": "Data recebimento",
			"tipoImportancia": "Tipo importância",
			"valor": "Valor",
			"retencao": "Retenção",
			"document": "Documento",
			"recRefContrato": "Referência contrato",
			"numeroRecibo": "Nª recibo",
			"importancia": "Importância",
			"anexos": "Anexos",
			"declaracao": "Declaração",
			"dataRecepcao": "Data recepção",
			"via2comprovativo": "2ª via",
			"aPagar": "Importância a pagar",
			"tipoDocumento": "Tipo doc.",
			"dataSituacao": "Data situação",
			"guiaPagamento": "Guia pagamento",
			"matricula": "Matricula",
			"catIUC": "Cat. IUC",
			"dataLimitePagamento": "Data limit pag.",
			"valorImposto": "Valor imposto",
			"valorJuros": "Valor juros",
			"situacaoNota": "Situação nota",
			"refPagamento": "Ref. pag.",
			"emissao": "Emissão",
			"dataMatricula": "Data matricula",
			"marca": "Marca",
			"modelo": "Modelo",
			"propLoc": "Proprietário / Locatário",
			"tipoNota": "Tipo nota",
			"valorEmitido": "Valor emitido",
			"valorPago": "Valor Pago",
			"qtdVeiculosLiquidados": "Qtd. veículos",
			"categoria": "Categoria",
			"mesMatricula": "Mês matricula",
			"adicionalIUC": "Adicional IUC",
			"dataPedido": "Data pedido",
			"tipoCertidao": "Tipo certidão",
			"estado": "Estado",
			"nPedidoPenhora": "Nº pedido penhora",
			"descricaoBem": "Descrição do bem",
			"codigoBem": "Código do bem",
			"qtdReconhecida": "Qtd. reconhecida",
			"unidade": "Unidade",
			"valorUnit": "Valor unit.",
			"niss": "NISS",
			"dataResposta": "Data resposta",
			"resposta": "Resposta",
			"entidade": "Entidade",
			"conta": "Conta",
			"dataEntrega": "Data entrega",
			"lido": "Lido",
			"tratado": "Tratado",
			"freguesia": "Freguesia",
			"artigo": "Artigo",
			"parte": "Parte",
			"valorInicial": "Valor inicial",
			"idNotaCobranca": "Nº nota cobrança",
			"numPredios": "Nº prédios",
			"cadernetaPredial": "Caderneta predial",
			"guia": "Guia",
			"pagamento": "Pagamento",
			"dataRegisto": "Data registo",
			"pedidoPor": "Pedido por"
		},
		"strings": {
			"headerAT": "Autoridade Tributária (AT)",
			"headerSS": "Segurança Social",
			"headerViaCTT": "Via CTT",
			"comprovativo": "{{title}} - Comprovativo: {{id}}",
			"via2comprovativo": "{{title}} - 2ª via: {{id}}",
			"docPagamento": "{{title}} - Documento de pagamento: {{id}}",
			"primeiraDeclaracao": "Primeira declaração",
			"declaracaoSubstituicao": "Declaração substituição",
			"detalhesDoc": "{{title}} - Detalhes documento: {{id}}",
			"recibo": "{{title}} - Recibo: {{id}}",
			"naoDisponivel": "Não disponível",
			"modalTitleGuiaPagamento": "{{title}} - Guia pagamento: {{id}}",
			"iucEmitirLigeiros": "Ligeiros e motociclos",
			"iucEmitirMercadorias": "Mercadorias e ligeiros mistos com peso bruto superior a 2500 kg",
			"emitido": "Emitido",
			"modalMessageEmitirIUC": "Tem a certeza que pretende emitir o documento de pagamento para matricula: {{matricula}}?",
			"emitidoComSucesso": "O documento de pagamento emitido com sucesso!",
			"emitir": "Emitir",
			"liquidacaoIRS": "Liquidação de IRS",
			"renunciaIsencaoIvaImovel": "Renúncia de isenção de IVA - Imóvel",
			"residenciaFiscal": "Residência fiscal",
			"dividaNaoDivida": "Dívida e não dívida",
			"sujPassivoRecupIVAEstrangeiro": "Suj. passivo/recup. IVA estrangeiro",
			"residenteNaoHabitual": "Residente não habitual",
			"domicilioFiscal": "Domicílio fiscal",
			"predialNegativa": "Predial negativa",
			"predial": "Predial",
			"activa": "Activa",
			"pendente": "Pendente",
			"pendenteConfirmacao": "Pendente confirmação",
			"expirada": "Expirada",
			"anulada": "Anulada",
			"modalMessagePedirCertidao": "Tem a certeza que pretende efetuar o pedido de certidão: {{nome}}?",
			"comprovativoSimpleTtile": "Comprovativo",
			"ssSitContribInfoText": "Declarações de situação contributiva emitidas após 24/10/2020",
			"viaCTTInfoText": "A aplicação só mostra os últimos 20 registos.",
			"alterarCredenciais": "Alterar credenciais",
			"portalAT": "Portal das Finanças (AT)",
			"portalSSD": "Segurança Social Direta (SSD)",
			"portalViaCTT": "Via CTT",
			"cadernetaPredial": "{{title}} - Caderneta Predial - Artigo: {{artigo}} - Ano: {{ano}}",
			"emptyDocs": "Não selecionou nenhum documento.",
			"associarDocumentoContabDigital": "Adicionar documento à contabilidade digital",
			"associarDocumentosContabDigital": "Adicionar documentos à contabilidade digital",
			"successAssociarComprovativoContabDigital": "Documento adicionado com sucesso",
			"verDocumento": "Ver documento"
		},
		"progress": {
			"message": "Associar documentos à contabilidade digital"
		},
		"modules": {
			"iuc": {
				"emitir": {
					"catA": "Categoria A - Veículos Ligeiros de Passageiros e Mistos com peso bruto não superior a 2500 kg (adquiridos até 30 de Junho de 2007)",
					"catB": "Categoria B - Veículos Ligeiros de Passageiros e Mistos com peso bruto não superior a 2500 kg (adquiridos a partir de 1 de Julho de 2007)",
					"catE": "Categoria E - Motociclos e equivalentes",
					"catC": "Categoria C - Veículos de Mercadorias e Ligeiros Mistos com peso bruto superior a 2500 kg por Conta Própria",
					"catD": "Categoria D - Veículos de Mercadorias e Ligeiros Mistos com peso bruto superior a 2500 kg por Conta Outrém"
				}
			}
		}
	},
	"efatura": {
		"showComunicacaoInexistencia": "Inexistência de Faturação (TEST)",
		"summary": "Resumo do ficheiro",
		"summaryErros": "Avisos / Erros do ficheiro",
		"invoiceTotal": "Nº de faturas",
		"totalCredit": "Total crédito",
		"totalDebit": "Total débito",
		"summaryByDoc": "Resumo por tipo de documento",
		"summaryByType": "Resumo por código de IVA",
		"btnComunicaDocs": "Comunicar documentos",
		"btnSaftGlobal": "SAF-T Global",
		"btnSaftAutofaturacao": "SAF-T Autofaturação",
		"btnLoginWebserviceAT": "Webservice AT - Comunicação de faturação",
		"btnGenerateDoc": "Gerar eFatura",
		"btnDownload": "Download",
		"btnComunicar": "Comunicar",
		"btnComunicaInexistenciaFaturacao": "Comunicar inexistência de faturação",
		"btnMonthsJan": "Janeiro",
		"btnPesquisar": "Pesquisar",
		"naoGeradoString": "(não gerado)",
		"geradoString": "(gerado)",
		"inexistenciaFaturacaoModalTitle": "Comunicar inexistência de faturação",
		"btnRemoverInexistencia": "Remover inexistência de faturação",
		"table": {
			"tipoSAFT": "Tipo",
			"docNumber": "Nº doc.",
			"nif": "NIF",
			"nomeClifo": "Nome",
			"valorIVA": "Total imposto",
			"valorTributavel": "Valor tributável",
			"valorParaSAFT": "Valor SAFT",
			"valorTotal": "Montante total",
			"estado": "Estado",
			"comunicado": "Comunicado",
			"erros": "Erros/Avisos",
			"name": "Nome",
			"vatValue": "Valor IVA",
			"rate": "Taxa",
			"tipoComunicacao": "Tipo de comunicação"
		},
		"tipoComunicacao": {
			"saft": "Ficheiro SAFT",
			"webService": "WebService"
		},
		"resumoFicheiro": {
			"comunicados": "Comunicados",
			"porComunicar": "Por comunicar"
		},
		"notices": "Avisos",
		"errors": "Erros",
		"docState": {
			"normal": "N - Normal",
			"nullified": "A - Anulado",
			"billed": "F - Faturado"
		},
		"messageConfigLoginAt": "Os documentos de vendas ficarão configurados para comunicação por webservice ao eFatura. Não necessitará mais de enviar o ficheiro SAF-T para o eFatura.",
		"nDocsNComunicados": "Nº de documentos não comunicados: ",
		"messagePermissions": "Aviso: Para alterar os campos deve ter permissões de administrador!",
		"comunicacaoRealizadaSucesso": "Comunicação realizada com sucesso.",
		"comunicaDocsModal": {
			"title": "Lista de documentos"
		},
		"geraDocModal": {
			"title": "Gerar documento e-Fatura",
			"soDocsPorComunicar": "Só documentos por comunicar",
			"messageTodosComunicados": "Ao continuar o ficheiro gerado irá conter todos os já comunicados.",
			"messageSoDocsPorComunicarFalse": "Gera um ficheiro com todos os documentos do período",
			"messageSoDocsPorComunicarTrue": "Gera um ficheiro apenas com os documentos por comunicar"
		},
		"mesDeclaracao": "Mês da declaração",
		"confirmComunicacaoCheckLabel": "Confirmo a intenção de comunicar inexistência de faturação",
		"confirmAnulacaoComunicacaoCheckLabel": "Confirmo a intenção de remover inexistência de faturação",
		"comunicacaoSuccessMsg": "A comunicação de inexistência de faturação foi efetuada com sucesso.",
		"anulacaoComunicacaoSuccessMsg": "A anulação da comunicação de inexistência de faturação foi efetuada com sucesso."
	},
	"efaturacirculacaologin": {
		"title": "Login AT-Doc. Transp.",
		"saveTitle": "Guardar",
		"fields": {
			"username": "Username (NIF/UserID)",
			"password": "Password"
		},
		"messages": {
			"success": "Login efetuado com sucesso.",
			"errorUsernameEmpty": "Username vazio.",
			"errorPasswordEmpty": "Password vazia.",
			"noPermissions": "Aviso: Para alterar valores deve ter permissões de administrador!"
		}
	},
	"efaturalogin": {
		"title": "Login E-Fatura",
		"saveTitle": "Guardar",
		"fields": {
			"tipoComunicacao": "Tipo comunicação AT",
			"username": "Username (NIF/UserID)",
			"password": "Password"
		},
		"messages": {
			"success": "Login efetuado com sucesso.",
			"errorUsernameEmpty": "Username vazio.",
			"errorPasswordEmpty": "Password vazia.",
			"noPermissions": "Aviso: Para alterar valores deve ter permissões de administrador!"
		}
	},
	"emailclifos": {
		"sendMail": {
			"title": "Enviar e-mail: {{nConta}} - {{nomeConta}}",
			"messageSuccess": "O e-mail foi enviado com sucesso para {{emailList}}",
			"sendAllCheckbox": "Desejo enviar automaticamente os restantes e-mails",
			"sendAllMessage": "Envio de e-mails",
			"sendAllSuccess": "E-mails enviados com sucesso",
			"btn": {
				"ignore": "Ignorar",
				"sendMail": "Enviar e-mail",
				"send": "Enviar"
			},
			"tooltips": {
				"ignore": "Ao ignorar, avança para o e-mail seguinte sem proceder ao envio",
				"cancel": "Ao cancelar, este e os seguintes e-mails serão cancelados",
				"cancelCheck": "Ao cancelar, não poderá prosseguir e os e-mails anteriores serão cancelados"
			},
			"messages": {
				"success": "E-mail enviado com sucesso "
			},
			"email": "Email",
			"assunto": "Assunto",
			"anexos": "Anexos",
			"texto": "Texto"
		}
	},
	"empresabloqueada": {
		"title": "Empresa não está disponível",
		"message": "<strong>Empresa não está disponível</strong>, contacte o administrador do sistema para mais informações.",
		"changeCompany": "Mudar empresa",
		"reasons": {
			"companyEmptyRoles": "Empresa não disponível, porque a mesma não tem acesso a nenhum portal."
		}
	},
	"empresas": {
		"title_detail": "Empresa {{id}}",
		"title_new": "Nova empresa",
		"title_plural": "Empresas",
		"pesquisa": "Pesquisar",
		"saved": "Empresa {{id}}, guardado com sucesso",
		"error": "Empresa {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"fields": {
			"primeiroMesContab": "Primeiro mês contabilístico",
			"nempresa": "Nº empresa",
			"nempresaPlaceholder": "Número da empresa",
			"nome": "Nome",
			"nomePlaceholder": "Nome da empresa",
			"nomeAlternativo": "Nome comercial",
			"morada": "Morada",
			"codpostal": "Cód. postal",
			"localidade": "Localidade",
			"nif": "Nº contribuinte",
			"pais": "País",
			"moeda": "Moeda",
			"tipoEmpresa": "Tipo empresa",
			"alvara": "Alvará",
			"textoRelatorio": "Texto relatório",
			"textoRelatorioPlaceholder": "(coloque aqui por exemplo o IBAN da empresa)",
			"capitalSocial": "Capital social",
			"telefone": "Telefone",
			"email": "E-mail",
			"web": "Endereço web",
			"repFinancas": "Serviço de finanças",
			"tipoContab": "Tipo contabilidade",
			"regimeIRC": "Regime IRC / IRS",
			"regimeIva": "Regime IVA",
			"periodoIva": "Período IVA",
			"anoCursoIRC": "Ano curso IRC",
			"periodoContabilistico": "Período contabilístico",
			"tipoAnalitica": "Analítica",
			"capPublico": "Capital público",
			"capPrivado": "Capital privado nacional",
			"capEstrangeiro": "Capital estrangeiro",
			"capPublicoPerc": "% Capital público",
			"capPrivadoPerc": "% Capital privado",
			"capEstrangeiroPerc": "% Capital estrangeiro",
			"nomeComarca": "Nome comarca",
			"nomeConservatoria": "Nome da conservatória",
			"registoConservatoria": "Registo na conservatória",
			"nifCC": "Nº contribuinte contabilista certificado",
			"nOCC": "Nº inscrição do contabilista na OCC",
			"nifAdmin": "Nº contribuinte do representante legal",
			"nContribSegSocial": "Nº contribuinte na seg. social",
			"cae": "CAE",
			"atividadeprincipal": "Atividade principal",
			"seMesCivilDifPerioComecaAnoSeg": "Se o mês civil for diferente do período, o ano inicia no ano seguinte.",
			"concelhoEmp": "Concelho",
			"telemovel": "Telemóvel",
			"mostraTarifasNasListagens": "Mostrar tipo de tarifa de linhas telefónicas nas listagens (para contacto do consumidor)",
			"codNatJuridica": "Cód. nat. Jurídica",
			"codAssocPatronal": "Cód. associação patronal",
			"codddccff": "Cód. distrito"
		},
		"steps": {
			"dadosGerais": "Dados gerais",
			"contactos": "Contatos",
			"dadosContab": "Dados contabilísticos e fiscais",
			"logotipo": "Logótipo",
			"dadosRH": "Dados para RH"
		},
		"messages": {
			"success": {
				"save": "Gravado com sucesso",
				"saveLogotipo": "Logótipo carregado com sucesso.",
				"deleteLogoTipo": "Logótipo eliminado com sucesso."
			},
			"error": {
				"save": "Erro a gravar"
			},
			"logoAlt": "Logótipo empresa",
			"changeLogo": "Alterar logótipo",
			"uploadLogo": "Carregar logótipo",
			"deleteLogo": "Apagar logótipo",
			"changedperiocontabfirstmonth": "Alterações gravadas com sucesso.",
			"configDivulgacaoTiposTarifa": "Configurar a divulgação de tipos de tarifa"
		},
		"changePerioContabTooltip": "Configuração do ano civil diferente do fiscal",
		"modal": {
			"confirmTitle": "Analítica: Lança sempre analítica",
			"confirmMessage": "Pretende configurar as contas de analítica do plano de contas?",
			"configanocivildiftitle": "Configuração ano civil diferente do fiscal",
			"tarifas": {
				"modalTitle": "Divulgação de tipos de tarifa",
				"title1": "Divulgação de linhas telefónicas para contacto do consumidor",
				"title2": "Decreto-Lei n.º 59/2021 (em vigor desde 01-11-2021)",
				"redeFixaNacional": "(Rede fixa nacional)",
				"redeMovelNacional": "(Rede móvel nacional)"
			}
		},
		"maintenance": {
			"analiticaconfig": "Configuração de contas de analítica"
		},
		"storewelcome": {
			"title": "Bem-Vindo ao CentralGest Cloud",
			"message1": "Antes de começar a utilizar a aplicação é <strong>necessário preencher os dados de identificação da empresa</strong>.",
			"message2": "Por favor preencha os seguintes campos",
			"required": {
				"nif": "Nº contribuinte empresa"
			},
			"errors": {
				"nif": "Por favor introduza o número de contribuinte da empresa"
			}
		}
	},
	"entidadeexterna": {
		"title_detail": "Entidade Externa",
		"title_new": "Nova entidade externa",
		"title_plural": "Entidades Externas",
		"pesquisa": "Pesquisar",
		"deleted": "Entidade Externa apagada com sucesso.",
		"saved": "Entidade Externa guardada com sucesso.",
		"error": "Entidade Externa não pode ser guardada. Por favor verifique os campos.",
		"fields": {
			"entidadeExternaID": "",
			"codigo": "Código",
			"nome": "Nome",
			"morada": "Morada 1",
			"morada2": "Morada 2",
			"nContribuinte": "Nº contribuinte",
			"contacto": "Contacto",
			"email": "E-mail",
			"tipoEmpresa": "Tipo empresa",
			"tipoContabilidade": "Tipo contabilidade",
			"nEmpresaGabinete": "Empresa gabinete",
			"periodoIVA": "Período IVA",
			"regimeIRC": "Regime IRC/IRS"
		}
	},
	"entidadehabitual": {
		"desconhecido": "Desconhecido",
		"clientes": "Clientes",
		"fornecedores": "Fornecedores"
	},
	"erps": {
		"title_detail": "ERP {{id}}",
		"title_new": "Novo erp",
		"title_plural": "ERPs",
		"pesquisa": "Pesquisar",
		"saved": "ERP {{id}}, guardado com sucesso",
		"error": "ERP {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"apiUrl": "URL da API",
			"apiUrlPlaceholder": "URL da API",
			"name": "Nome do erp",
			"nomePlaceholder": "Nome do erp",
			"centralGestId": "CentralGest Id"
		}
	},
	"erputilizadores": {
		"title_detail": "Utilizador {{id}}",
		"title_new": "Novo utilizador",
		"pesquisa": "Pesquisar",
		"saved": "Utilizador {{id}}, guardado com sucesso",
		"error": "Utilizador {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"removecompany": "Remover empresa",
		"fields": {
			"erp": "ERP",
			"nutilizador": "Nº utilizador",
			"nutilizadorPlaceholder": "Nº utilizador",
			"nomeUtilizador": "Nome utilizador",
			"nomeUtilizadorPlaceholder": "Nome utilizador",
			"nomeCompleto": "Nome completo",
			"nomeCompletoPlaceholder": "Nome completo"
		},
		"companies": {
			"title": "Empresas",
			"available": "Disponíveis",
			"selected": "Selecionadas"
		},
		"addMultiEmpresa": "Adicionar várias empresas",
		"options": {
			"showApi": "Mostrar acessos de API"
		},
		"editRow": {
			"title": "Editar ERP",
			"erp": "ERP",
			"nEmpresa": "Nº Empresa",
			"nUtilizador": "Nº Utilizador",
			"ativo": "Ativo",
			"codEmpregado": "Cód. Empregado",
			"disponiveis": "Disponíveis",
			"temAcesso": "Tem acesso",
			"naoTemAcesso": "Não tem acesso"
		}
	},
	"estabelecimento": {
		"title_detail": "Estabelecimento {{id}}",
		"title_new": "Novo Estabelecimento",
		"title_plural": "Estabelecimentos",
		"pesquisa": "Pesquisar",
		"saved": "Estabelecimento {{id}}, guardado com sucesso.",
		"error": "Estabelecimento {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Estabelecimento {{id}}, apagado com sucesso",
		"fields": {
			"nEstabElec": "Cód.",
			"nEstabElecPlaceholder": "Código do estabelecimento",
			"nome": "Nome",
			"nomePlaceholder": "Nome do estabelecimento",
			"designaBreve": "Designa breve",
			"designaBrevePlaceholder": "Designa breve",
			"morada": "Morada",
			"moradaPlaceholder": "Morada",
			"localidade": "Localidade",
			"localidadePlaceholder": "Localidade",
			"nPostal": "Cód. postal",
			"nPostalPlaceholder": "Cód. postal",
			"distrito": "Distrito",
			"concelho": "Concelho",
			"freguesia": "Freguesia",
			"freguesiaPlaceholder": "Freguesia",
			"caeaCTPrinc": "caeaCTPrinc",
			"caeaCTPrincPlaceholder": "Nº do CAE",
			"codDdCcFf": "Cód. distrito, conc., freg. do MT",
			"codDdCcFfPlaceholder": "Cód. distrito, conc., freg. do MT",
			"nEstabQPessoal": "Nº estab. oficial para q. pessoal",
			"nEstabQPessoalPlaceholder": "Nº estab. oficial para q. pessoal",
			"nEstabSegSocial": "Nº estab. oficial para seg. social",
			"nEstabSegSocialPlaceholder": "Nº estab. oficial para seg. social",
			"activPrincip": "Atividade principal",
			"activPrincipPlaceholder": "Atividade principal",
			"nContRib": "Nº contribuinte",
			"nContRibPlaceholder": "Nº contribuinte",
			"nCaixaPrev": "Nº caixa previdência",
			"codCCt": "Código CCT",
			"telefone": "Telefone",
			"telefonePlaceholder": "Telefone",
			"calenCABId": "Calendário",
			"sede": "Sede"
		}
	},
	"estadoconta": {
		"ativa": "Activa",
		"desactivada": "Desactivada",
		"bloqueada": "Bloqueada"
	},
	"estadoemp": {
		"ativo": "Ativos",
		"inativo": "Inativos",
		"ambos": "Todos"
	},
	"estadoregisto": {
		"ativo": "Ativo",
		"inativo": "Inativo"
	},
	"estados": {
		"title_detail": "Estado {{id}}",
		"title_plural": "Estados",
		"pesquisa": "Pesquisar",
		"fields": {
			"codEstado": "Código de estado",
			"nome": "Nome",
			"nomeAbr": "Abreviatura",
			"tipoEstado": "Tipo de estado"
		}
	},
	"estadosValidacao": {
		"data": {
			"naoPossivel": "Não é possível criar lançamentos",
			"criadoNaoValidado": "Criado o lançamento, mas ainda não foi validado",
			"criadoValidado": "Criado o lançamento, mas já foi validado"
		}
	},
	"exportacaoCompras": {
		"fields": {
			"documentosPorExportar": "Enviar apenas documentos por exportar"
		},
		"dataGrid": {
			"doc": "Doc.",
			"nDocExterno": "Nº doc. externo",
			"nFornecedor": "Nº Fornecedor",
			"nomeFornecedor": "Nome fornecedor",
			"exportado": "Exportado",
			"exportId": "Export ID"
		},
		"config": {
			"title": "Configuração - Exportação de compras",
			"familiasDataGrid": {
				"title": "Familias",
				"nFamCG": "Nº familia CG",
				"nomeFamCG": "Nome familia CG",
				"idFamGeo": "Familia geofolia",
				"nomeFamGeo": "Nome familia geofolia",
				"operacao": "Operação",
				"legend": {
					"naoConfiged": "Não configurado",
					"campoEdit": "Campo editável"
				}
			},
			"docsDataGrid": {
				"titleDocsCompras": "Documentos de compras",
				"titleDocsNotaCredito": "Documentos de nota crédito",
				"id": "ID documento",
				"name": "Nome",
				"desc": "Descrição",
				"contectMenu": {
					"desmarcaDocsExported": "Desmarcar documentos exportados (selecionados)",
					"abrirDoc": "Abrir documento"
				}
			}
		},
		"mensagens": {
			"configSuccess": "Configuração guardada com sucesso",
			"okModalTitle": "Desmarcar exportação dos documentos selecionados",
			"okModalMensagem": "Tem a certeza que pretende desmarcar a exportação dos documentos selecionados?",
			"documentosDupsNotAllowed": "O documento '{{docNome}}' já se encontra configurado",
			"linhaObrigatoria": "Obrigatório selecionar um documento para adicionar com sucesso",
			"familiasDupsNotAllowed": "Já se encontra uma família CentralGest configurada com o id '{{id}}'"
		}
	},
	"extractosartigos": {
		"title_plural": "Extractos Artigo",
		"pesquisa": "Pesquisar",
		"fields": {
			"nArtigo": "Cód. artigo",
			"nomeArtigo": "Nome artigo",
			"dataDoc": "Data doc.",
			"nDocAsString": "Nº doc.",
			"nDocfa": "Tipo doc.",
			"descricaoDocfa": "Descrição tipo doc.",
			"nArmazem": "Cód. armazém",
			"nomeArmazem": "Nome armazém",
			"nLoteEspecifico": "Lote específico",
			"preco": "Preço",
			"valorEntrada": "Valor entrada",
			"valorSaida": "Valor saída",
			"saldoValor": "Saldo valor",
			"qtdEntrada": "Qtd. entrada",
			"qtdSaida": "Qtd. saída",
			"saldoQtd": "Saldo qtd.",
			"nConta": "Nº conta",
			"nomeClifo": "Nome cliente / fornecedor",
			"unidadeMov": "Unidade de mov."
		},
		"btn": {
			"options": {
				"showNArtigo": "Mostra cód. artigo",
				"showNomeArtigo": "Mostra nome artigo",
				"showDataDoc": "Mostra data doc.",
				"showNDocAsString": "Mostra nº doc.",
				"showNDocfa": "Mostra tipo doc.",
				"showDescricaoDocfa": "Mostra descrição tipo doc.",
				"showNArmazem": "Mostra cód. armazém",
				"showNomeArmazem": "Mostra nome armazém",
				"showNLoteEspecifico": "Mostra lote específico",
				"showPreco": "Mostra preço",
				"showValorEntrada": "Mostra valor entrada",
				"showValorSaida": "Mostra valor saída",
				"showSaldoValor": "Mostra saldo valor",
				"showQtdEntrada": "Mostra qtd. entrada",
				"showQtdSaida": "Mostra qtd. saída",
				"showSaldoQtd": "Mostra saldo qtd.",
				"showNConta": "Mostra nº conta",
				"showNomeClifo": "Mostra nome cliente / fornecedor",
				"showUnidadeMov": "Mostra unidade de mov."
			}
		},
		"caption": {
			"deCodArtigo": "Cód. artigo de",
			"deNArmazem": "Cód. armazém de",
			"deData": "Data de",
			"calcMovAntesDepoisData": "Calcula movimentos antes e depois da data"
		},
		"info": {
			"erroDataDeMaiorQueAte": "A data De é maior que a data Até",
			"valormaximonarmazem": "O valor máximo para o cód. armazém é \"32767\"."
		},
		"contextmenu": {
			"openDocCcomercial": "Abrir documento",
			"openArtigo": "Abrir ficha de artigo"
		}
	},
	"extratosClifos": {
		"radical": "Radical de contas",
		"table": {
			"fields": {
				"nConta": "Conta",
				"nomeConta": "Nome conta",
				"nDocExterno": "Doc. externo",
				"dataVenc": "Data venc.",
				"descricao": "Descrição",
				"valorDebito": "Valor débito",
				"valorCredito": "Valor crédito",
				"porPagar": "Por pagar",
				"dataDoc": "Data doc.",
				"moeda": "Moeda",
				"nCCusto": "NCCusto",
				"valorDebitoSegMoeda": "Valor débito seg. moeda",
				"valorCreditoSegMoeda": "Valor crédito seg. moeda",
				"cambioSegMoeda": "Cambio seg. moeda",
				"descritivo": "Descritivo",
				"nDocumento": "Nº documento",
				"periodo": "Período",
				"nDiario": "Diário"
			}
		},
		"btn": {
			"processar": "Processar"
		}
	},
	"extratosDT": {
		"fields": {
			"periodo": "Período",
			"diario": "Diário",
			"nDocInterno": "Doc.",
			"nDocExterno": "Doc. externo",
			"dataVencimento": "Data vencimento",
			"descricao": "Descrição",
			"valorDebito": "Débito",
			"valorCredito": "Crédito",
			"porPagar": "Por pagar",
			"dataDoc": "Data doc.",
			"moeda": "Moeda",
			"debitoEmDivisa": "Débito em divisa",
			"creditoEmDivisa": "Crédito em divisa",
			"porPagarEmDivisa": "Por pagar em divisa",
			"cCusto": "C. Custo",
			"cambioSegMoeda": "Câmbio em seg. moeda",
			"debitoSegMoeda": "Débito em seg. moeda",
			"creditoSegMoeda": "Crédito em seg. moeda",
			"nLanc": "Nº lanc.",
			"dataDocExterno": "Data doc. externo",
			"cambio": "Câmbio",
			"debitoME": "Débito em ME",
			"creditoME": "Crédito em ME",
			"abreviaturaMoeda": "Moeda"
		},
		"config": {
			"showMoeda": "Mostrar coluna de moeda",
			"showDebitoEmDivisa": "Mostrar coluna de débito em divisa",
			"showCreditoEmDivisa": "Mostrar coluna de crédito em divisa",
			"showPorPagarEmDivisa": "Mostrar coluna de por pagar em divisa",
			"showCCusto": "Mostrar coluna de c. custo",
			"showCambioSegMoeda": "Mostrar coluna de câmbio em seg. moeda",
			"showDebitoSegMoeda": "Mostrar coluna de débito em seg. moeda",
			"showCreditoSegMoeda": "Mostrar coluna de crédito em seg. moeda",
			"showPorPagar": "Mostrar coluna por pagar",
			"showDataVencimento": "Mostrar coluna data de vencimento"
		},
		"filterFields": {
			"nConta": "Conta",
			"deData": "Data de"
		},
		"btn": {
			"processar": "Processar"
		},
		"msg": {
			"contaInvalida": "Campo conta inválido.",
			"naoMostraDetalhe": "Não é possível apresentar o detalhe deste documento, pois é um documento contabilístico."
		},
		"caption": {
			"recibos": "Recibos"
		},
		"modal": {
			"title": "Extrato de conta {{tipo}}"
		},
		"export": {
			"account": "Conta"
		},
		"print": {
			"title": "Impressão Extrato de conta",
			"obter": "Obter"
		}
	},
	"familias": {
		"title_detail": "Família {{id}}",
		"title_new": "Nova Família",
		"title_plural": "Famílias",
		"pesquisa": "Pesquisar",
		"deleted": "Família apagada com sucesso.",
		"saved": "Família {{id}}, guardada com sucesso.",
		"error": "Família {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"fields": {
			"nFamilia": "Cód.",
			"nFamiliaPlaceholder": "Código da família",
			"nome": "Nome",
			"nomePlaceholder": "Nome da família",
			"codtipoartigo": "Cód. tipo artigo",
			"grandesfamilias": "Cód. gr. família",
			"subfamilia": "Cód. sub-família",
			"unimovimento": "Un. movimento",
			"contaVenda": "Nº conta venda",
			"contaCompra": "Nº conta compra",
			"metodoCalcPrecVend": "Método calc. preço venda",
			"nomeIvaVenda": "IVA na venda",
			"nomeIvaCompra": "IVA na compra",
			"movimentaStock": "Movimenta stock",
			"criterio": "Critério valorimétrico",
			"naoPExistNeg": "Não permite stock negativo"
		},
		"metodos": {
			"margemprecovenda": "Margem sobre preço de venda",
			"margemprecocompra": "Margem sobre preço de compra",
			"customedioponderado": "Custo médio ponderado",
			"lifo": "Lifo",
			"fifo": "Fifo",
			"custopadrao": "Custo padrão",
			"customedio": "Custo médio",
			"loteespecifico": "Lote específico"
		},
		"btn": {
			"buttonOptions": "Opções",
			"verContas": "Mostra contas venda e compra",
			"esconderContas": "Esconder contas"
		}
	},
	"faturacaoContratos": {
		"btn": {
			"search": "Pesquisar",
			"processar": "Processar",
			"gestaoAvencas": "Gestão avenças faturadas",
			"faturar": "Faturar"
		},
		"fields": {
			"sel": "Sel.",
			"classeContrato": "Classe",
			"tipoContrato": "Tipo",
			"nContrato": "Nº avença",
			"versao": "Versão",
			"descricao": "Descrição",
			"nClifo": "Nº conta",
			"nomeClifo": "Nome",
			"totalLiquido": "Total líquido",
			"nFaturasEmAberto": "Nº faturas em aberto",
			"dataUltimoPagamento": "Data último pagamento",
			"dataUltimaFatura": "Data da última fatura",
			"obsUltimaFatura": "Observações da última fatura",
			"temDebitoDirectoStr": "Cliente c/ débito direto",
			"temErro": "Com erro",
			"contratoAsString": "Avença"
		},
		"caption": {
			"classeContrato": "Classe",
			"tipoClasseContrato": "Tipo",
			"nZonaDe": "Cód. zona de",
			"nContaDe": "Nº conta de",
			"dataDoc": "Data doc.",
			"dataVenc": "Data venc.",
			"dataInicTransp": "Data inic. transp.",
			"horaInicTransp": "Hora inic. transp.",
			"txtContrato": "Observações",
			"txtCtrNoCabecalho": "Coloca no rodapé",
			"txtCtrNoCabecalhoTooltip": "Preenche o campo observações do cabeçalho do documento",
			"txtCtrNaLinha": "Coloca no corpo",
			"txtCtrNaLinhaTooltip": "Depois da última linha do documento",
			"origemTxtContrato": "Origem do texto",
			"localizacao": "Localização"
		},
		"config": {
			"verDataInicTransp": "Mostrar data inic. transp.",
			"verHoraInicTransp": "Mostrar hora inic. transp.",
			"verZonaDeAte": "Mostrar filtro por zona",
			"verErrosContratos": "Mostrar erros das avenças",
			"verContasDeAte": "Mostrar filtro por conta",
			"verDataVenc": "Mostrar data venc."
		},
		"table": {
			"semdados": "Sem dados"
		},
		"messages": {
			"success": "Avenças faturadas com sucesso.",
			"sucessContratosFaturados": "Faturado(s) {{nregistos}} avença(s) com sucesso.",
			"semClasseSelecionada": "Sem classe de avença selecionada.",
			"semTiposSelecionado": "Deve selecionar pelo menos um tipo de avença",
			"semDataDocDeSelecionado": "Deve preencher a data de documento inicial",
			"semDataDocAteSelecionado": "Deve preencher a data de documento final",
			"semNContaDeSelecionado": "Deve preencher a conta inicial",
			"semNContaAteSelecionado": "Deve preencher a conta final",
			"infoFaturacaoAuto": "Para utilizar a faturação automática, configure o tipo de avença aqui"
		},
		"errors": {
			"classeContratoNaoPodeSerNula": "A classe de avença não pode ser nula.",
			"naoExisteTipoContratoSelecionado": "Não existe nenhum tipo de avença selecionado.",
			"naoExisteContratoSelecionadoParaFaturar": "Não existe nenhuma avença selecionado para faturar.",
			"contratosAFaturarComErros": "Existe(m) {{nregistos}} avença(s) para faturar com erro."
		},
		"legend": {
			"contratoComErro": "Avença com erro"
		},
		"info": {
			"dadosProcessamento": "Dados processamento"
		},
		"data": {
			"tipoDeAvenca": "Tipo de avença",
			"manual": "Manual"
		},
		"text": {
			"dataParaProc": "Data para processamento"
		}
	},
	"gestaoContratos": {
		"title": "Gestão de avenças faturadas",
		"filters": {
			"nClasseContrato": "Classe",
			"nTiposContrato": "Tipo",
			"dataDocDe": "Data doc. de",
			"nContaDe": "Nº conta de",
			"estadoDoc": "Estado",
			"estadosDoc": {
				"encerrados": "Encerrados",
				"naoEncerrados": "Não encerrados"
			}
		}
	},
	"faturacaoContratosLog": {
		"title": "Processamento de Avenças",
		"tabs": {
			"faturados": {
				"title": "Faturados",
				"fields": {
					"contratoAsString": "Avença",
					"nClasseCtr": "Classe",
					"nTipoContrato": "Tipo",
					"nContrato": "Nº avença",
					"descricao": "Descrição",
					"nConta": "Cliente",
					"contaNome": "Nome cliente",
					"dataDoc": "Data doc.",
					"nDocAsString": "Documento",
					"email": "E-mail",
					"emailEnviado": "Enviado",
					"docJaImprimido": "Doc. impresso"
				},
				"btn": {
					"previsualizar": "Pré-visualizar",
					"encerrarImprimir": "Encerrar e imprimir/enviar",
					"encerrarTudo": "Encerrar documentos",
					"enviarFatEletronica": "Enviar faturas",
					"editarDocumento": "Editar documento",
					"visualizarDocumento": "Visualizar documento"
				}
			},
			"erros": {
				"title": "Não faturados",
				"fields": {
					"contratoAsString": "Avença",
					"nClasseCtr": "Classe",
					"nTipoContrato": "Tipo",
					"nContrato": "Nº avença",
					"descricao": "Descrição",
					"nConta": "Cliente",
					"contaNome": "Nome cliente"
				},
				"warningMsg": {
					"naoFaturados": "Existem avenças que não foram faturadas",
					"consulteNaoFaturados": "Existem avenças que não foram faturadas, consulte o separador não faturados"
				}
			}
		},
		"success": {
			"documentosEncerrados": "As avenças faturadas foram encerradas com sucesso.",
			"encerradosNumDocumentos": "Encerrado(s) {{value}} documento(s) com sucesso.",
			"emailsEnviados": "Enviado(s) {{value}} e-mail(s) com sucesso."
		},
		"errors": {
			"documentoJaEncerrado": "O documento {{nDoc}} já se encontra encerrado.",
			"emailsNaoEnviados": "Não foi enviado nenhum e-mail.",
			"naoConfigurados": "Não existem e-mails configurados ou documentos não estão encerrados.",
			"erroAEncerrarDoc": "Erro a encerrar documento",
			"erroAEncerrarDocs": "Erro a encerrar os documentos",
			"erroAEnviarEmail": "Erro a enviar e-mail",
			"naoExistemItensSelecionados": "Não existem itens selecionados",
			"documentosEnvioEmailNaoEncerrados": "Os documentos das avenças selecionadas para enviar por e-mail não estão encerrados"
		},
		"info": {
			"emailsEnviados": "Os documentos selecionados já foram enviados por e-mail",
			"documentosEncerrados": "Os documentos selecionados encontram-se encerrados.",
			"docsNaoEncerradosSelecionadosParaEnvioEmail": "Existem documentos não encerrados das avenças selecionadas e não irá ser enviado e-mail para esses clientes"
		},
		"confirm": {
			"emailsEnviados": "Já foram enviados os documentos por e-mail, deseja enviar novamente?"
		},
		"okCancel": {
			"encerrardocs": {
				"title": "Encerrar documentos",
				"message": "Tem a certeza que deseja encerrar os documentos selecionados?"
			},
			"enviardocs": {
				"title": "Enviar faturas das avenças por e-mail",
				"message": "Tem a certeza que deseja enviar os documentos das avenças selecionadas?",
				"message2": "Existem documentos de avenças selecionadas que já foram enviadas. Tem a certeza que deseja enviar?"
			}
		}
	},
	"faturacaoeletronicaconfiguracoes": {
		"fields": {
			"nDocFa": "Tipo Doc.",
			"nome": "Nome",
			"estado": "Estado",
			"reportDefault": "Impresso por defeito",
			"nConta": "Nº conta",
			"emails": "E-mails",
			"email": "E-mail",
			"emailgeral": "E-mail geral"
		},
		"legend": {
			"gravado": "Gravado",
			"porgravar": "Por gravar"
		},
		"tabs": {
			"tiposdocumento": "Tipos documento",
			"clientes": "Clientes",
			"certificado": "Certificado"
		},
		"text": {
			"certificados": "Certificados",
			"certificadosCount": "Certificados ({{total}})",
			"pendentes": "Pendentes",
			"pendentesCount": "Pendentes ({{total}})",
			"semFaturacaoEletronica": "Sem fatura eletrónica",
			"comFaturacaoEletronica": "Com fatura eletrónica",
			"obterAtributos": "Obter atributos",
			"authenticate": "Autenticar",
			"register": "Registar",
			"tipoCertificadoNotSelected": "Não está definida a plataforma de assinatura eletrónica de documentos, defina a mesma numa das caixas de seleção abaixo disponiveis.",
			"selectedTipoCertificado": "Faturação eletrónica ativa, a assinatura eletrónica de documentos definida é: \"{{plataforma}}\".",
			"setTipoCerficadoAtivo": "Definir como plataforma de assinatura",
			"tipoCerficadoAtivo": "Definido como plataforma de assinatura",
			"faturacaoEletronicaNaoAtiva": "Faturação eletrónica sem plataforma de assinatura configurada",
			"infoDigitalSignMessage": "Para que possa usar o fornecedor Digital Sign, é necessário obter os dados do Autorizador para que possa permitir a assinatura.",
			"infoSAFEMessage": "Para que possa usar o fornecedor SAFE, precisa de ter acesso ao portal Autenticação.GOV e obrigatóriamente ser portador de atributos profissionais.",
			"faq": "Aceda à nossa FAQ para qualquer dúvida.",
			"allTiposDocs": "Todos os tipos de documentos SAF-T (FT,FR,ND,NC) estão selecionados para assinatura de documentos digitais.",
			"allClientes": "Todos os clientes estão selecionados para assinatura de documentos digitais.",
			"savedInfoIsAnonimous": "Nota: Esta informação irá ser guardada de forma anónima",
			"checkAcessoSAFEMsg": "O Serviço de Assinatura de Faturas eletrónicas (SAFE) permite que o administrador, gerente ou diretor de uma empresa possa assinar faturas eletrónicas em software de integração integrados com SAFE.",
			"checkAcessoSAFE": "Possui acesso ao portal Autenticação.GOV e é portador de atributos profissionais?",
			"apagar": "Apagar",
			"dataValidade": "Data de Validade"
		},
		"messages": {
			"clientAddedSucess": "Cliente adicionado!",
			"clientEditedSucess": "Cliente editado!",
			"emailsEmpty": "Deve atribuir pelo menos um email.",
			"warningAddDigitalSign": "Já existem contas de assinatura definidas.<br/>Tem a certeza que pretende criar uma nova conta?",
			"successAddSafeCertificate": "Certificado SAFE adicionado com sucesso.",
			"successSAFEObterAtributos": "Atributos do certificado SAFE obtidos com sucesso.",
			"successAddDigitalSignAuthorizer": "Certificado Digital Sign adicionado com sucesso.",
			"successSetTipoCerficadoAtivo": "Plataforma de assinatura eletrónica definida com sucesso.",
			"authenticateDigitalSignAuthorizer": "Certificado Digital Sign autenticado com sucesso.",
			"safependentinfo": "Deve ter atribuído os atributos profissionais que permitam a assinatura de faturas eletrónicas.",
			"promptDeleteDigitalSignAuthData": "Tem a certeza que pretende eliminar os dados de acesso?",
			"successDeleteDigitalSignAuthorizer": "Certificado Digital Sign removido com sucesso.",
			"successConfigSaved": "A configuração guardada com sucesso."
		},
		"errors": {
			"emptyNewSafeCertificateUrl": "Não foi possível obter o endereço para adicionar um certificado SAFE.",
			"emptyNewDigitalSignUrl": "Não foi possível obter o endereço para adicionar um certificado Digital Sign.",
			"safeObterAtributos": "Ocorreu um erro desconhecido ao tentar obter os atributos do certicado SAFE.",
			"invalidNomeConta": "Nome de conta inválido, apenas pode conter caracteres e números e não pode conter espaços.",
			"noAuthFound": "Não foi encontrada uma autenticação valida"
		},
		"safe": {
			"nomeDaConta": "Nome",
			"nomeUtilizador": "Proprietário",
			"accountExpiration": "Validade",
			"autenticacaoGov": "Abrir Autenticação.Gov",
			"state": "Estado",
			"errors": {
				"semItems": "Não tem nenhum portador de atributos profissionais registado"
			}
		},
		"digitalsign": {
			"nomeAutorizador": "Nome do autorizador",
			"idAutorizador": "ID do autorizador",
			"nomeUtilizador": "Proprietário",
			"secret": "Secret",
			"accountExpiration": "Validade",
			"usoGlobal": "Uso global",
			"dropdown": {
				"cliente": "Área de cliente",
				"certificado": "Abrir certificado",
				"gestao": "Gestão de certificados"
			},
			"errors": {
				"nomeAutorizadorNulo": "O nome do autorizador não pode ser nulo",
				"idAutorizadorNulo": "O ID do autorizador não pode ser nulo",
				"secretNula": "Secret não pode ser nulo"
			}
		},
		"addsafecertificadomodal": {
			"title": "Adicionar certificado SAFE",
			"titlePreProduction": "Adicionar certificado SAFE (pré-produção)",
			"fields": {
				"nomeConta": "Nome da conta",
				"email": "E-mail",
				"validade": "Válida até"
			},
			"tipoCidadao": {
				"nacional": "Cidadão nacional",
				"estrangeiro": "Cidadão estrangeiro"
			}
		},
		"digitalsignauthorizermodal": {
			"add": "Adicionar certificado Digital Sign",
			"addPreProduction": "Adicionar certificado Digital Sign (pré-produção)",
			"update": "Editar certificado Digital Sign",
			"updatePreProduction": "Editar certificado Digital Sign (pré-produção)"
		},
		"configemailsclientemodal": {
			"title": "Configurar email da conta"
		},
		"postAddSafeCertificadoModal": {
			"message": "Por favor aguarde enquanto finalizamos o processo de adicionar um certificado SAFE...",
			"error": "Ocorreu um erro ao finalizar o processo de adicionar um certificado SAFE."
		},
		"certificadoAvailable": {
			"safe": "SAFE (Serviço de Assinatura de Faturas Eletrónicas)",
			"safeTestes": "SAFE (Serviço de Assinatura de Faturas Eletrónicas) testes",
			"digitalSign": "Digital Sign - Selo eletrónico qualificado",
			"digitalSignTestes": "Digital Sign - Selo eletrónico qualificado testes",
			"safeShort": "SAFE",
			"safeFullname": "Serviço de assinatura de faturas eletrónicas",
			"digitalSignShort": "Digital Sign",
			"digitalSignFullname": "Selo eletrónico qualificado"
		},
		"configOptions": {
			"ambientePreProducao": "Ambiente pré-produção"
		},
		"faq": {
			"dropdowntitle": "FAQ - Ajuda",
			"faq1": "<h3>O que é a fatura eletrónica e para que é que serve?</h3><br><p>O processamento de faturas em papel é um processo dispendioso para as empresas, com custos para cidadãos e empresas consumindo recursos à economia. De modo a melhorar e tornar mais seguro este processo, as entidades e empresas procuram proceder à desmaterialização das faturas. A fatura eletrónica, uma fatura emitida e recebida em formato eletrónico, vem então dar resposta a esta necessidade de desmaterialização.</p><p>O Serviço de Assinatura de Faturas Eletrónicas (SAFE), enquadrado no Sistema de Certificação de Atributos Profissionais (SCAP), surge com o objetivo de suportar este processo de desmaterialização, estando conforme com o artigo 12º do DL 28/2019 de 15 de fevereiro.</p><p>Este serviço permite ao cidadão, enquanto profissional de uma empresa, assinar digitalmente faturas eletrónicas, através de mecanismo automatizado pelo software de faturação.</p>",
			"faq2": "<h3>O que é que devo fazer se a aplicação for comprometida (relativamente a segurança)?</h3><br><p>Uma aplicação considera-se comprometida sempre que os tokens do SAFE tiverem sido expostos.</p><p>Em caso de comprometimento da aplicação, deve informar a AMA num prazo inferior a 4 horas (após conhecimento do incidente de segurança), e cancelar todas as contas nesse mesmo prazo.</p><p>Para tal, basta selecionar a conta que foi comprometida, e com o botão direito do rato selecionar \"cancelar a conta de assinatura\", e seguir as instruções.</p>",
			"faq3": "<h3>Como devo proceder para criar uma nova conta de assinatura?</h3><br><p>Para a criação de uma nova conta de assinatura deve proceder conforme explicado no \"Guia para criação de nova conta\", no botão ao lado</p>",
			"faq4": "<h3>O que é o Artigo 12º do Decreto-Lei 28/2019 de 15 de fevereiro?</h3><br><p>O Artigo 12º refere-se à emissão de fatura por via eletrónica. Nomeadamente:</p><p><b>1 -</b> As faturas e demais documentos fiscalmente relevantes podem, mediante aceitação pelo destinatário, ser emitidos por via eletrónica.</p><p><b>2 -</b> Para efeitos do artigo 6.º, considera-se garantida a autenticidade da origem e a integridade do conteúdo dos documentos emitidos por via eletrónica se adotado, nomeadamente, um dos seguintes procedimentos:</p><p><b>a)</b> Aposição de uma assinatura eletrónica qualificada nos termos legais;</p><p><b>b)</b> Aposição de um selo eletrónico qualificado, nos termos do Regulamento (UE) n.º 910/2014, do Parlamento Europeu e do Conselho, de 23 de julho de 2014;</p><p><b>c)</b> Utilização de um sistema de intercâmbio eletrónico de dados, desde que os respetivos emitentes e destinatários outorguem um acordo que siga as condições jurídicas do «Acordo tipo EDI europeu», aprovado pela Recomendação n.º 1994/820/CE, da Comissão, de 19 de outubro.</p><p><b>3 -</b> No caso de lotes que compreendam vários documentos transmitidos ou disponibilizados ao mesmo destinatário, as menções comuns aos vários documentos podem ser feitas apenas uma vez, na medida em que, para cada documento, esteja acessível a totalidade da informação.</p>",
			"faq5": "<h3>O que é o Decreto-Lei 12/2021 de 9 de fevereiro?</h3><br><p>Este Decreto-Lei assegura a execução na ordem jurídica interna do Regulamento (UE) 910/2014, relativo à identificação eletrónica e aos serviços de confiança para as transações eletrónicas no mercado interno.</p>",
			"faq6": "<h3>De que forma vão ser utilizados os tokens do SAFE para efetuar assinaturas?</h3><br><p>Para dar resposta à legislação mencionada, o CentralGest necessita guardar o token (chave privada) da conta de assinatura criada para poder solicitar a assinatura de um documento de faturação. Este token tem uma validade reduzida, e, sempre que o mesmo expire, a aplicação solicita automaticamente a sua renovação utilizando um \"refresh token\" que também deve ficar guardado na base de dados.</p><p>O token é enviado em cada comunicação com o SAFE para validar a conta de assinatura associada.</p>",
			"faq7": "<h3>Os tokens são guardados de forma segura?</h3><br><p>Sim, os tokens são encriptados através de um algoritmo seguro antes de serem guardados, seguindo as normas do SAFE, pelo que não são decifráveis numa consulta à base de dados.</p>",
			"faq8": "<h3>Quando é que o CentralGest procede à assinatura de uma fatura?</h3><br><p>Após correta parametrização, o CentralGest assina uma fatura sempre que a mesma é enviada por email, ou sempre que a mesma é exportada no módulo de impressão de documentos.</p><p>Sempre que se vai proceder à assinatura de um documento, o utilizador é previamente informado.</p>"
		},
		"guide": {
			"dropdowntitle": "Guia para criação de nova conta",
			"title": "Fluxo de criação de conta",
			"atributosproftitle": "Atributos profissionais",
			"atributosprof": "Antes de iniciar o processo de criação de conta, deve ter atribuído os atributos profissionais que permitam a assinatura de faturas eletrónicas<br>Para tal, deve aceder ao site da autenticação.gov e na área privada, aceder aos \"meus atributos profissinais\".",
			"novacontatitle": "Nova conta",
			"novaconta": "Deve clicar em \"Novo\" para iniciar o processo de criação de uma nova conta.",
			"autenticacaotitle": "Autenticação",
			"autenticacao": "Após o passo anterior, será direcionado para uma página de autenticação em autenticação.gov.<br>Neste passo deve selecionar o meio de autenticação.",
			"autorizacaotitle": "Autorização",
			"autorizacao": "Deve autorizar a recolha de alguns dados pessoais para poder continuar o processo.",
			"autenticacaomovel": "Neste caso, autenticando com a Chave Móvel Digital, deve introduzir os dados necessários para a autenticação.",
			"autenticacaomovelcod": "Deve introduzir o código de segurança que foi enviado para o telemóvel.",
			"obteratributostitle": "Obter Atributos",
			"obteratributos": "Após a correta autenticação, o processo ficará pendente, pelo que será necessário obter os atributos para o certificado.",
			"certificadotitle": "Certificado",
			"certificado": "Concluído o processo, podemos verificar que temos o certificado ativo."
		},
		"btn": {
			"ativar": "Ativar",
			"desativar": "Desativar"
		},
		"radioOptions": {
			"caption": " Assinatura de documentos comerciais para",
			"docfas": {
				"todos": "Todos os tipos de documentos SAF-T (FT,FR,ND,NC)",
				"selecionados": "Apenas os tipos de documentos selecionados"
			},
			"clientes": {
				"todos": "Todos os clientes",
				"selecionados": "Apenas os clientes selecionados"
			}
		}
	},
	"faturacaopublica": {
		"tab": {
			"porComunicarManual": "Docs. por comunicar (Manual)",
			"porComunicarWebServ": "Docs. por comunicar (WebService)",
			"comunicadoEmValidacao": "Docs. em validação",
			"comunicadoComErro": "Docs. com erros",
			"saphety": "Saphety",
			"feap": "FEAP-eSPAP",
			"comunicados": "Comunicados"
		},
		"btn": {
			"comunicarWebServ": "Comunicar por web service",
			"comunicarManual": "Comunicar manualmente",
			"enviarEmail": "Enviar e-mail",
			"exportarAnexos": "Exportar anexos"
		},
		"menu": {
			"historico": "Histórico",
			"comunicamanual": "Marcar como comunicado manualmente",
			"comunicacaomanual": "Comunicação manual",
			"consutCliente": "Consultar cliente",
			"consultDoc": "Consultar documento"
		},
		"table": {
			"sel": "Sel.",
			"ndocAsStringDraftSufixo": "Documento",
			"datadoc": "Data do doc.",
			"nClifo": "Cód. conta",
			"nomeClifo": "Nome conta",
			"facPublicaModeloStr": "Modelo de fac.",
			"estadoDescricao": "Observações",
			"semdados": "Sem dados"
		},
		"historico": {
			"title": "Histórico",
			"data": "Data/Hora",
			"documento": "Documento",
			"estado": "Estado",
			"tipo": "Tipo",
			"codigo": "Código",
			"descricao": "Descrição",
			"nConta": "Nº conta",
			"nomeConta": "Nome conta",
			"nomeUtilizador": "Nome utilizador"
		},
		"observacoes": {
			"title": "Observações",
			"observacoes": "Observações"
		},
		"doccomercial": {
			"title": "Doc. comercial"
		},
		"config": {
			"title": "Configuração da faturação eletrónica pública",
			"utilizador": "Utilizador",
			"senha": "Senha",
			"endereco": "URL",
			"estados": "Estados",
			"estadosConta": "Por omissão",
			"table": {
				"estado": "Estado",
				"conta": "Conta"
			}
		},
		"messages": {
			"refreshsuccess": "Atualizado com sucesso!",
			"observacoesvazia": "Tem de inserir uma observação",
			"contavazia": "Tem de colocar uma conta",
			"estadodesc": "Estado inválido"
		},
		"modal": {
			"comunicacaomanual": {
				"title": "Comunicação manual - Anexos gerados",
				"anexos": "Anexos",
				"btn": {
					"sendmail": "Enviar por e-mail",
					"export": "Exportar"
				}
			}
		},
		"estado": {
			"porComunicar": "Por comunicar",
			"comunicadoEmValidacao": "Comunicado em validação",
			"comunicadoValidado": "Comunicado validado",
			"comunicadoComErro": "Comunicado com erro",
			"erroTratado": "Erro tratado",
			"received": "Recebido",
			"rejected": "Rejeitado",
			"paid": "Pago",
			"comunicadoEstruturaEmValidacao": "Comunicado estrutura em validação"
		},
		"errors": {
			"faturacaoeletronicanaoconfigurada": "Para comunicar ficheiros via CIUS-PT, é necessário que a faturação eletrónica esteja configurada",
			"errogerarficheiros": "Erro a gerar ficheiros, confirme a configuração da faturação pública e da faturação eletrónica"
		}
	},
	"fct": {
		"tabEmpresas": "Empresas",
		"tabDocumentos": "Documentos",
		"tableDefinition": {
			"nempresa": "Nº empresa",
			"nome": "Nome empresa",
			"emitido": "Doc. emitido",
			"valor": "Valor total",
			"valorMesAnterior": "Valor mês anterior",
			"mensagem": "Mensagem",
			"dataEmissao": "Data emissão",
			"documento": "Documento",
			"dataPagamento": "Data pagamento",
			"tipoPagamento": "Tipo pagamento",
			"emitirTipoDocumento": "Emitir",
			"pdf": "PDF",
			"valorFCTNoCG": "Valor total no CG",
			"email": "E-mail",
			"valorEmAtraso": "Valor em atraso",
			"valorAPagar": "Valor a pagar",
			"identificacao": "Identificação",
			"contrato": "Contrato",
			"rendimentos": "Rendimentos",
			"totalRetidoFundos": "Total retido fundos",
			"fctAno": "FCT ano",
			"fctMes": "FCT mês",
			"codemp": "Cód. emp.",
			"nomeEmp": "Nome",
			"niss": "NISS",
			"iban": "IBAN",
			"modalidadeContrato": "Contrato",
			"dataEntrada": "Data entrada",
			"dataInicioContrato": "Data inicio",
			"dataFimContrato": "Data fim",
			"valorRetribuicaoMensal": "Retribuição mensal",
			"valorDiuturnidade": "Valor diuturnidade",
			"qtdFaltasInjustificadas": "Qtd. faltas injustificadas",
			"valorFaltasInjustificadas": "Valor faltas",
			"totalFCT": "Total FCT",
			"totalFGCT": "Total FGCT",
			"valorPago": "Valor pago",
			"dataCessacaoContrato": "Data cessação contrato",
			"motivo": "Motivo",
			"entregasPagas": "Entregas pagas",
			"numeroUPs": "Número UP's",
			"valorEstimadoReembolso": "Valor estimado reembolso",
			"numTrabalhadores": "Num. trabalhadores"
		},
		"toolbar": {
			"obterValores": "Obter valor do FCT",
			"emitirDocumento": "Emitir documento de pagamento",
			"downloadDoc": "Descarregar documento",
			"viewTodosTrabComFct": "Todos trabalhadores com FC",
			"comSelecionados": "Operação com selecionados...",
			"pedirReembolso": "Pedir reembolso"
		},
		"messages": {
			"selectRecords": "Tem que selecionar pelo menos uma empresa.",
			"erroDownload": "Erro a efetuar o download do documento.",
			"emitidoComSucesso": "O Documento de pagamento foi emitido com sucesso.",
			"configurarCredentials": "Configurar credenciais do FCT",
			"valorObtidoComSucesso": "Valor obtido do portal FCT com sucesso",
			"porPagar": "Por pagar",
			"emailEnviado": "E-mail enviado com sucesso!",
			"emailNaoEnviado": "E-mail não foi enviado.",
			"modalPromptMessage": "Tem a certeza que pretende emitir documento de pagamentos para {{numEmpSelected}} selecionadas?",
			"verificaEmail": "Deve preencher o email",
			"enviarEmailTooltip": "Enviar o documento de pagamento por e-mail",
			"verifiqueEmails": "Verifique se os e-mails estão preenchidos e corretos.",
			"httpErrorTenteNovamente": "Ocorreu um erro inesperado. Por favor, verifique se o portal de fundos de compensação está disponível e tente novamente.",
			"selecionaEmpresa": "Tem que selecionar a empresa.",
			"selecionarCriterio": "Para efetuar a pesquisa deve selecionar pelo menos um critério.",
			"selectAtLeastOneRecord": "Tem que selecionar pelo menos um registo.",
			"operacaoConcluidaVerifiqueMsgs": "Operação concluída com sucesso. Verifique as mensagens nos registos.",
			"naoFoiPossivelPedirReembolsoParaAlgunsTrabalhadores": "Não foi possível efetuar o pedido de reembolso para alguns trabalhadores. Por favor, verifique as mensagens nos registos.",
			"dataFimIgualPosteriorDataInicio": "A data fim da pesquisa deve ser igual ou posterior à data de início da pesquisa",
			"dataCessInicialEmpty": "Tem que preencher a data de início da cessação de contrato",
			"dataCessFinalEmpty": "Tem que preencher a data de fim da cessação de contrato",
			"pedidoReembolsoEfetuadoComSucesso": "O pedido de reembolso efetuado com sucesso.",
			"visualizarPedidosEfetuados": "Visualizar pedidos efetuados."
		},
		"strings": {
			"de": "De",
			"tipoSearchDoc": "Tipo doc.",
			"empresa": "Empresa",
			"pesquisar": "Pesquisar",
			"searchTipoDocTodos": "Todos",
			"searchTipoDocADEF": "Documento adesão FCT",
			"searchTipoDocADEG": "Documento adesão ao FGCT",
			"searchTipoDocDOCP": "Documento de pagamento",
			"detalhes": "Detalhes",
			"valorTotal": "Valor total",
			"valorMesAnterior": "Valor mês anterior",
			"loginFCT": "Login FCT",
			"niss": "NISS",
			"password": "Senha",
			"sendEmail": "Enviar e-mail com documento de pagamento",
			"downloadDoc": "Efetuar download do documento de pagamento",
			"enviarEmail": "Enviar e-mail",
			"accessoFCT": "Acesso FCT",
			"viewTodosTrabComFctModalTitle": "Todos trabalhadores com fundo de compensação",
			"CDCI": "Cessado por despedimento com direito a compensação",
			"CDSI": "Cessado por despedimento sem direito a compensação",
			"CRV": "Cessado por rescisão voluntária",
			"efetuarPedido": "Efetuar pedido",
			"efetuarPedidos": "Efetuar pedidos",
			"pedidosReembolsos": "Pedidos de reembolsos",
			"pesquisarTrabalhadores": "Pesquisar trabalhadores",
			"dataCessInicial": "Data cessação contrato",
			"a": "a",
			"estadoContrato": "Estado contrato",
			"selecionarTodos": "Selecionar todos",
			"deselecionarTodos": "Limpar seleção",
			"pedidosReembolsosEfetuados": "{{nEmpresa}} - Pedidos de reembolsos efetuados"
		},
		"tooltips": {
			"obterValores": "Obter valor do portal fundos de comprensação",
			"emitirDocumento": "Emitir o documento de pagamento",
			"enviarEmail": "Enviar o e-mail com o documento de pagamento",
			"downloadDoc": "Descarregar o documento de pagamento",
			"viewTodosTrabComFct": "Visualizar dados de todos trabalhadores com fundo de compensação",
			"comSelecionados": "Selecione a operação que pretende efetuar com registos selecionados",
			"pedirReembolso": "Efetuar o pedido de reembolso",
			"selecionarTodos": "Selecionar todos os trabalhadores",
			"deselecionarTodos": "Limpar seleção",
			"expandAllHaveEmployees": "Expandir todos que tem trabalhadores",
			"collapseAll": "Colapsar todos",
			"efetuarPedidosDestaEmpresa": "Efetuar o pedido de reembolso dos trabalhadores selecionados desta empresa"
		}
	},
	"fileviewer": {
		"table": {
			"nome": "Nome",
			"tamanho": "Tamanho",
			"datamod": "Data mod.",
			"horamod": "Hora mod.",
			"datacriacao": "Data criação",
			"horacriacao": "Hora criação",
			"utilizador": "Utilizador",
			"docexterno": "Doc. externo",
			"classificador": "Classificador",
			"pasta": "Pasta"
		},
		"header": {
			"descBtn": "Ver como"
		},
		"tooltip": {
			"visualizar": "Visualizar",
			"download": "Download",
			"onlyPDF": "Visualizador apenas para formato PDF.",
			"delete": "Remover",
			"upload": "Carregar"
		}
	},
	"fimano": {
		"steps": {
			"welcome": "Bem-Vindo",
			"data": "Dados",
			"validations": "Validações",
			"execute": "Executar"
		},
		"text": {
			"welcome": {
				"warning0": "A operação de encerramento do ano deverá ser efetuada por um administrador!",
				"warning1": "Certifique-se que efetuou cópias de segurança da empresa actual.",
				"warning2": "Clique em \"Seguinte\" para continuar a operação de encerramento do ano.",
				"errorNext": "Não é possível avançar com o encerramento do ano devido ao(s) seguinte(s) erro(s)"
			},
			"data": {
				"company": "Empresa",
				"accountingPeriod": "Período contabilístico",
				"ircYear": "Ano IRC",
				"yearToClose": "Ano a encerrar",
				"nDiario": "Diário (Saldos iniciais)",
				"nDescritivo": "Descritivo (Saldos iniciais)",
				"scheduleFor": "Agendar para",
				"saldaContasClasse0": "Salda contas da Classe 0",
				"saldaContasClasse9": "Salda contas da Classe 9",
				"fazTratamentoME": "Faz tratamento de saldos em moeda estrangeira"
			},
			"validations": {
				"beforeExecute0": "Antes de executar o fim de ano é necessário fazer algumas validações.",
				"beforeExecute1": "Nota: Este processo poderá demorar algum tempo.",
				"btnValidate": "Validar",
				"executing": "A executar validações...",
				"noErrors": "Não existem erros. Clique em \"Avançar\" para continuar a operação de encerramento do ano."
			},
			"execute": {
				"text0": "Está tudo pronto para poder iniciar o processo de encerramento do ano.",
				"text1": "Tome em consideração que após agendar o mesmo, a empresa atual irá ficar <strong>bloqueada</strong> e <strong>inacessível</strong> enquanto o processo não terminar.",
				"doExecute": "Clique em \"Finalizar\" para encerrar o ano."
			}
		}
	},
	"fimperiodoenc": {
		"title_detail": "Período {{id}}",
		"title_new": "Encerramento do Período",
		"title_plural": "Períodos",
		"pesquisa": "Pesquisar",
		"saved": "Período {{id}}, guardado com sucesso.",
		"error": "Período {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"periodo": "Período",
			"periodos": "Períodos",
			"nomePeriodo": "Nome",
			"observacoes": "Observações",
			"flagRigorAbsoluto": "Encerrado"
		},
		"placeholders": {
			"periodo": "Código do período",
			"observacoes": "Observações"
		},
		"messages": {
			"successfullychanged": "Período alterado com sucesso",
			"docsnaosaldados": "Existem documentos não saldados!"
		},
		"btn": {
			"applyperio": "Mudar período da empresa",
			"wontchangeperio": "Não mudar período da empresa"
		},
		"titleListaEncerrados": "Lista dos diários encerrados",
		"titleDiariosDisponiveis": "Disponíveis",
		"titleDiariosSelecionados": "Selecionados",
		"changePeriodo": "Mudar período contabilístico da empresa",
		"fazerVerificacaoDocsNSaldTitle": "Fazer verificação de documentos não saldados",
		"fazerVerificacaoDocsNSald": "Prima Ok se pretende efetuar a verificação da existência de documentos não saldados, ou Cancelar, se pretende avançar sem verificar.",
		"temDocsNSald": "Existem documentos não saldados no período. Pretende continuar?"
	},
	"fluxoMonetario": {
		"pesquisa": "Fluxos monetários",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"undefined": "Não definido",
			"doesNotHave": "Não tem",
			"paymentInTheAct": "Pagamento no ato",
			"credit": "Crédito"
		}
	},
	"formaIncrementoNDoc": {
		"pesquisa": "Formas incremento número documento",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"numberingRestartsEachPeriod": "Numeração reinicia em cada período",
			"sequentialNumbering": "Numeração sequencial (independentemente do período)"
		}
	},
	"freguesias": {
		"title_detail": "Freguesia {{id}}",
		"title_new": "Nova Freguesia",
		"title_plural": "Freguesias",
		"pesquisa": "Pesquisar",
		"saved": "Freguesia {{id}}, guardada com sucesso",
		"error": "Freguesia {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Freguesia {{id}}, apagada com sucesso",
		"fields": {
			"nFreguesia": "Código da freguesia",
			"designacaoBreve": "Designação breve",
			"designacaoCompleta": "Designação completa"
		}
	},
	"fremun": {
		"title_detail": "Forma de Remuneração {{id}}",
		"title_new": "Nova Forma de Remuneração",
		"title_plural": "Formas de Remuneração",
		"pesquisa": "Pesquisar",
		"saved": "Forma de Remuneração {{id}}, guardada com sucesso",
		"error": "Forma de Remuneração {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Forma de Remuneração {{id}}, apagada com sucesso",
		"fields": {
			"nFRemun": "Código da forma de remuneração",
			"designacaoBreve": "Designação breve",
			"designacaoCompleta": "Designação completa"
		}
	},
	"funcoes": {
		"title_detail": "Função {{id}}",
		"title_new": "Nova função",
		"title_plural": "Funções",
		"pesquisa": "Pesquisar",
		"saved": "Função {{id}}, guardada com sucesso",
		"error": "Função {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Função {{id}}, apagada com sucesso",
		"fields": {
			"nFuncao": "Código da função",
			"designaBreve": "Designação breve",
			"designaComp": "Designação completa"
		}
	},
	"gdoc": {
		"fields": {
			"cab": {
				"folderId": "Identificação da pasta",
				"name": "Nome"
			}
		},
		"messages": {
			"maxFicheirosAtingido": "O numero max de ficheiros permitidos foi atingido! Elimine um ficheiro antes de carregar um novo."
		}
	},
	"generateAbdes": {
		"title": "Gerar novo {{abonoDesconto}} automaticamente",
		"gerar": "Gerar",
		"perfilNotSelected": "Tem de selecionar um {{perfilAbDesc}}!",
		"abono": "Abono",
		"desconto": "Desconto",
		"abonoDesconto": "{{abonoDesconto}}",
		"tipoConfig": "Tipo de configuração",
		"selecionarAbonoDescontoPretendido": "Selecionar {{abonoDesconto}} pretendido",
		"criarNovoAbonoDesconto": "Criar novo {{abonoDesconto}} a partir de:",
		"codigoAbonoDescontoNotSelected": "Tem de selecionar o {{abonoDesconto}}!",
		"formPerfilInvalid": "Falha na validação dos campos. Por favor verifique!",
		"fields": {
			"categoria": "Categoria",
			"grupo": "Grupo",
			"nome": "Nome",
			"descricao": "Descrição",
			"tipoArredondamento": "Arredondamento",
			"tipoRecolha": "Tipo recolha",
			"contribuiCalculoCustoPrecoH": "Contribui cálculo custo preço/hora",
			"codRem": "Cód. remuneração da seg. social",
			"codUnidade": "Unidade defeito",
			"tipoRendimento": "Tipo rendimento",
			"contaMovDeb": "Nº conta débito",
			"contaMovCred": "Nº conta crédito",
			"contaEncDEB": "Nº conta débito",
			"contaEncCRE": "Nº conta crédito",
			"ausencia": "Ausência",
			"percAumentHExtra": "% Aumento horas extra",
			"nHorasDiaProvDesc": "Nº horas dia para perda de subsidio de refeição",
			"perfilAbDesc": "Perfil de {{abonoDesconto}}",
			"semPerfil": "Sem perfil",
			"abonoDescontoPreConfigurado": "{{abonoDesconto}} pré-configurado",
			"abonoDescontoJaExistente": "{{abonoDesconto}} já existente",
			"tipo": "Tipo",
			"perfil": "Perfil"
		},
		"crabdTable": {
			"fields": {
				"categoria": "Categoria",
				"empregado": "Ent. suporte empregado",
				"patronato": "Ent. suporte patronato",
				"desconto": "Desconto",
				"nomeCategoria": "Categoria",
				"temIncidenciaEmpregado": "Tem",
				"incidenciaEmpregado": "Incidência",
				"temIncidenciaPatronato": "Tem",
				"incidenciaPatronato": "Incidência",
				"nDesconto": "Cód.",
				"nomeDesconto": "Descrição"
			}
		},
		"stepCategoria": "Categoria",
		"stepPerfil": "Perfil",
		"editarcruzamentos": {
			"title": "Edição de cruzamentos",
			"subtitle": "Desconto {{categoria}}",
			"desconto": "Desconto",
			"temcruzent": "Tem cruzamento com entidade",
			"empregado": "Empregado",
			"incidencia": "(%) Incidência",
			"patronato": "Patronato",
			"temseleentidade": "Tem de selecionar pelo menos uma entidade para efetuar o cruzamento!",
			"teminserirdesc": "Tem de introduzir um desconto!"
		},
		"abdesemp": "Abonos e descontos empregado",
		"encpatronempresa": "Encargos patronato/empresa"
	},
	"objscandoctextinterpreter": {
		"invoiceType": {
			"none": "Desconhecido",
			"fatura": "Fatura",
			"faturaSimplificada": "Fatura simplificada",
			"faturaRecibo": "Fatura recibo",
			"notaDebito": "Nota de crédito",
			"notaCredito": "Nota de débito",
			"vendaDinheiro": "Venda a dinheiro",
			"talaoVenda": "Talão de venda",
			"talaoDevolucao": "Talão de devolução",
			"alienacaoAtivos": "Alienação de ativos",
			"devolucaoAtivos": "Devolução de ativos",
			"premio": "Prémio ou recibo de prémio",
			"estorno": "Estorno ou recibo de estorno",
			"imputacaoCoSeguradoras": "Imputação a co-seguradoras",
			"imputacaoCoSeguradoraLider": "Imputação a co-seguradora líder",
			"resseguroAceite": "Resseguro aceite",
			"recibo": "Recibo",
			"notaLancamento": "Nota de lançamento",
			"extratoBancario": "Extrato bancário",
			"pagamentoAoEstado": "Pagamento ao estado",
			"levantamento": "Levantamento",
			"pagamento": "Pagamento",
			"deposito": "Depósito",
			"cheque": "Cheque",
			"transferencia": "Transferência"
		}
	},
	"gestaoComunicacoes": {
		"title": "Gestão comunicações contabilista",
		"titleCliente": "Gestão comunicações cliente",
		"messageSidebarSelector": {
			"empresas": "Empresas",
			"clienteMessage": "Mensagens",
			"comunicacoes": "Comunicações",
			"newMessages": "Novas mensagens",
			"noComunicacoes": "Nenhuma comunicação disponivel",
			"ontem": "Ontem"
		},
		"modal": {
			"config": {
				"selectEmpresa": {
					"title": "Configuração de empresas",
					"leftCaption": "Empresas disponíveis",
					"rightCaption": "Empresas excluídas"
				},
				"selectUtilizador": {
					"title": "Configuração de utilizadores",
					"leftCaption": "Utilizadores possíveis",
					"rightCaption": "Utilizadores selecionados"
				},
				"mensagens": {
					"success": "Configuração guardada com sucesso"
				}
			},
			"empresaConfig": {
				"title": "Configuração {{nEmpresa}} - {{nomeEmpresa}}",
				"empresaConfigEstados": {
					"title": "Configuração de estados",
					"estados": "Estados",
					"herdado": "Herdado da configuração geral",
					"detail": {
						"title": "Estado - {{nomeEstado}}",
						"titleAdd": "Adicionar estado",
						"todasEmpresas": "Disponível para todas as empresas",
						"cor": "Cor"
					},
					"mensagens": {
						"successEstados": "Estados guardados com sucesso",
						"successEstado": "Estado guardado com sucesso",
						"errorDeleteEstado": "Não pode eliminar este estado, continua associado a um tópico",
						"notaOrdenarEstados": "Para ordenar os estados basta arrastar e soltar",
						"notaInherited": "Utilizadores herdados da configuração geral"
					}
				}
			},
			"topico": {
				"newMessage": "Nova comunicação",
				"editMessage": "Editar comunicação",
				"deleteMessage": "Apagar comunicação",
				"assunto": "Assunto",
				"user": "Utilizador",
				"categoria": "Categoria",
				"estado": "Estado",
				"data": "Data",
				"mensagem": "Mensagem",
				"mensagensTitle": "Mensagens",
				"mensagemPlaceholder": "Escreva aqui a sua mensagem...",
				"mensagensNaoLidas": "Mensagens não lidas",
				"mensagens": {
					"success": "Comunicação enviada com sucesso",
					"updateSuccess": "Comunicação editada com sucesso",
					"deleteSuccess": "Comunicação apagada com sucesso"
				}
			}
		},
		"common": {
			"filtrarPorPalavraChave": "Filtrar por palavra chave",
			"semMensagens": "Sem mensagens",
			"carregarMais": "Carregar mais",
			"typeYourMessage": "Escreva a sua mensagem...",
			"selectEmpresa": "Selecione uma empresa",
			"selectComunicacao": "Selecione uma comunicação",
			"nothingSelected": "Nada está selecionado",
			"ficheiroSelecionadoSucesso": "Ficheiro selecionado com sucesso"
		}
	},
	"gestaoFicheirosSepa": {
		"grid": {
			"fields": {
				"nContaForn": "Nº conta",
				"nomeBeneficiario": "Nome",
				"ibanContaDest": "IBAN",
				"swiftDest": "SWIFT",
				"valor": "Valor",
				"dataLanc": "Data de criação",
				"nomeFicheiro": "Nome ficheiro",
				"estadoNotificacao": "Notificação ao fornecedor"
			},
			"actions": {
				"imprimirNotificar": "Imprimir/Notificar",
				"download": "Transferir ficheiro SEPA",
				"retrocederPorEnviar": "Alterar estado para \"Por enviar\" do ficheiro SEPA"
			},
			"toolbar": {
				"meioPagamento": "Banco (Meio de pagamento)",
				"dataDe": "Data pagamento de"
			}
		},
		"tabs": {
			"title": {
				"enviados": "Enviados",
				"porEnviar": "Por enviar"
			}
		},
		"btn": {
			"gerarFicheiroSEPA": "Gerar ficheiro SEPA"
		},
		"error": {
			"pesquisar": "Todos os parâmetros da pesquisa devem estar preenchidos"
		}
	},
	"gestaodgemps": {
		"text": {
			"abonos": "Abonos",
			"faltas": "Faltas",
			"ferias": "Férias",
			"servico": "Serviço",
			"view": "Vista",
			"yearly": "Anual",
			"monthly": "Mensal",
			"finsSemanaFeriados": "Fins-de-semana/Feriados",
			"pendentes": "Pendentes",
			"marcadas": "Marcadas",
			"aprovadas": "Aprovadas",
			"integradas": "Integradas",
			"rejeitadas": "Rejeitadas",
			"gozadas": "Gozadas",
			"restantes": "Restantes",
			"conflito": "Conflito",
			"attachments": "Anexos",
			"fromDate": "das",
			"toDate": "às",
			"addNew": "Adicionar novo evento",
			"motivoRejeicao": "Motivo rejeição",
			"day": "Dia {{date}}",
			"allDay": "{{date}}, todo o dia",
			"fromTo": "De {{start}} até {{end}}",
			"fromToHours": "{{date}}, das {{start}} às {{end}}",
			"recordedWageProcessing": "Registado no processamento salarial",
			"details": "Detalhes",
			"integraAll": "Integrar eventos selecionados",
			"integraAllGroup": "Integrar eventos do colaborador",
			"approveAll": "Aprovar eventos selecionados",
			"approveAllGroup": "Aprovar eventos do colaborador",
			"rejectAll": "Rejeitar eventos selecionados",
			"rejectAllGroup": "Rejeitar eventos do colaborador",
			"setMotivoAll": "Definir motivo dos eventos selecionados",
			"setMotivoAllGroup": "Definir motivo dos eventos do colaborador",
			"rewind": "Reverter estado",
			"rewindAll": "Reverter estado dos eventos selecionados",
			"rewindAllGroup": "Reverter estado dos eventos do colaborador",
			"markOff": "Desmarcar",
			"markOffAll": "Desmarcar eventos selecionados",
			"markOffAllGroup": "Desmarcar eventos do colaborador",
			"addAttachment": "Adicionar anexo",
			"downloadAttachment": "Descarregar anexo",
			"replaceAttachment": "Substituir anexo",
			"deleteAttachment": "Remover anexo",
			"history": "Histórico do evento",
			"noEvents": "Não existem eventos na data selecionada",
			"integrar": "Integrar",
			"prettyFeriasMarcadas": "{{marcadas}} Marcados / {{total}} Total",
			"confirm": {
				"integraAll": "Tem a certeza que deseja integrar todos os eventos selecionados?",
				"integraAllGroup": "Tem a certeza que deseja integrar todos os eventos do colaborador \"{{codEmp}} - {{nomeEmp}}\"?",
				"approveAll": "Tem a certeza que deseja aprovar todos os eventos selecionados?",
				"approveAllGroup": "Tem a certeza que deseja aprovar todos os eventos do colaborador \"{{codEmp}} - {{nomeEmp}}\"?",
				"rejectAll": "Tem a certeza que deseja rejeitar todos os eventos selecionados?",
				"rejectAllGroup": "Tem a certeza que deseja rejeitar todos os eventos do colaborador \"{{codEmp}} - {{nomeEmp}}\"?",
				"rewindAll": "Tem a certeza que deseja reverter o estado de todos os eventos selecionados?",
				"rewindAllGroup": "Tem a certeza que deseja reverter o estado de todos os eventos do colaborador \"{{codEmp}} - {{nomeEmp}}\"?",
				"markOffAll": "Tem a certeza que deseja desmarcar todos os eventos selecionados?",
				"markOffAllGroup": "Tem a certeza que deseja desmarcar todos os eventos do colaborador \"{{codEmp}} - {{nomeEmp}}\"?",
				"deleteAttachment": "Tem a certeza que deseja remover o anexo selecionado?"
			},
			"success": {
				"changed": "Alterado com sucesso",
				"integrado": "Integrado com sucesso",
				"rejeitado": "Rejeitado com sucesso",
				"aprovado": "Aprovado com sucesso",
				"retrocede": "Retrocedido com sucesso",
				"addAttachment": "Anexo adicionado com sucesso",
				"replaceAttachment": "Anexo substituído com sucesso",
				"deleteAttachment": "Anexo removido com sucesso"
			},
			"warning": {
				"rewindAllPosicao": "Atenção! Existem eventos em estados diferentes, tem a certeza que pretende retroceder a posição dos eventos selecionados?"
			},
			"error": {
				"aprovado": "Ocorreu um erro ao tentar aprovar"
			},
			"motivos": {
				"default": "motivo",
				"retrocede": "Retrocede posição"
			},
			"status": {
				"male": {
					"marcado": "MARCADO (pendente aprovação)",
					"aprovado": "APROVADO",
					"aprovadoParcialmente": "APROVADO (parcialmente)",
					"rejeitado": "REJEITADO",
					"integrado": "INTEGRADO",
					"gozado": "GOZADO"
				},
				"female": {
					"marcado": "MARCADA (pendente aprovação)",
					"aprovado": "APROVADA",
					"aprovadoParcialmente": "APROVADA (parcialmente)",
					"rejeitado": "REJEITADA",
					"integrado": "INTEGRADA",
					"gozado": "GOZADA"
				}
			}
		},
		"errors": {
			"invalidTipoAbono": "Deve definir um tipo de abono.",
			"invalidTipoFalta": "Deve definir um tipo de falta.",
			"invalidTipoFeria": "Deve definir um tipo de férias.",
			"invalidQuantidade": "Deve definir uma quantidade.",
			"invalidTipoProcessamento": "Deve definir um tipo de processamento.",
			"invalidDesconto": "Deve definir um desconto.",
			"naoPodeMarcarFeriasPorqueTemConflitos": "Não pode marcar férias nessa data ou intervalo porque existem conflitos."
		},
		"fields": {
			"codEmp": "Cod.",
			"nomeCategoria": "Categoria",
			"motivo": "Motivo",
			"nomeEmpregado": "Colaborador",
			"tipo": "Tipo",
			"tipoFalta": "Tipo falta",
			"tipoProcessamento": "Tipo processamento",
			"designacaoAbDes": "Desconto",
			"dataTarefa": "Dia",
			"datasTarefas": "Dias",
			"alteraFaltas": "Altera faltas",
			"novoTipo": "Novo tipo",
			"anexaFicheiro": "Anexa ficheiro",
			"historico": "Histórico",
			"quantidade": "Quantidade",
			"novaQuantidade": "Nova quantidade",
			"allDay": "Todo dia",
			"horaInicio": "Hora início",
			"horaFim": "Hora fim.",
			"valor": "Valor",
			"dadosFixos": "Dados fixos",
			"anexo": "Anexo",
			"daData": "Data de",
			"codEmpDe": "Cód. empregado de",
			"activeOnly": "Apenas ativos",
			"valorUnitario": "Valor unitário",
			"dataProcessamento": "Data para processamento",
			"marcacoes": "Marcações"
		},
		"header": {
			"previous": "Anterior",
			"today": "Hoje",
			"next": "Próximo",
			"previousYear": "Ano anterior",
			"nextYear": "Ano seguinte",
			"previousMonth": "Mês anterior",
			"nextMonth": "Mês seguinte"
		},
		"abonos": {
			"pageTitle": "Gestão de abonos",
			"sidebarTitle": "Abonos",
			"motivos": {
				"marcar": "Marcação de abonos",
				"aprovar": "Aprovação de abonos",
				"rejeitar": "Rejeição de abonos",
				"integrar": "Integração de abonos"
			},
			"marcarmodal": {
				"title": "Pedido de marcação de abono"
			},
			"cancelarmodal": {
				"title": "Desmarcar abono",
				"message": "Tem a certeza que pretende desmarcar abonos?"
			}
		},
		"faltas": {
			"pageTitle": {
				"colaborador": "Marcação de faltas",
				"gestor": "Gestão de faltas"
			},
			"sidebarTitle": {
				"colaborador": "Marcar faltas",
				"gestor": "Faltas"
			},
			"motivos": {
				"marcar": "Marcação de faltas",
				"aprovar": "Aprovação de faltas",
				"rejeitar": "Rejeição de faltas",
				"integrar": "Integração de faltas"
			},
			"marcarmodal": {
				"title": "Pedido de marcação de faltas"
			},
			"cancelarmodal": {
				"title": "Desmarcar faltas",
				"message": "Tem a certeza que pretende desmarcar faltas?"
			}
		},
		"ferias": {
			"pageTitle": {
				"colaborador": "Marcação de férias",
				"gestor": "Gestão de férias"
			},
			"sidebarTitle": {
				"colaborador": "Marcar férias",
				"gestor": "Férias"
			},
			"motivos": {
				"marcar": "Marcação de férias",
				"aprovar": "Aprovação de férias",
				"rejeitar": "Rejeição de férias",
				"integrar": "Integração de férias"
			},
			"titles": {
				"marcadas": "Férias marcadas",
				"rejeitadas": "Férias rejeitadas",
				"integradas": "Férias integradas"
			},
			"marcarmodal": {
				"title": "Pedido de marcação de férias",
				"message": "Tem a certeza que deseja marcar as férias?",
				"marcarPara": "Marcar para os colaboradores"
			},
			"cancelarmodal": {
				"title": "Desmarcar férias",
				"message": "Tem a certeza que pretende desmarcar férias?"
			},
			"existemConflitosPertendeContinuar": "Existem conflitos nessa data ou intervalo. Pretende continuar?"
		},
		"details": {
			"titleAbonos": "Abonos {{date}}",
			"titleRangeAbonos": "Abonos {{start}} até {{end}}",
			"titleFaltas": "Faltas {{date}}",
			"titleRangeFaltas": "Faltas {{start}} até {{end}}",
			"titleFerias": "Férias {{date}}",
			"titleRangeFerias": "Férias {{start}} até {{end}}"
		},
		"summary": {
			"title": "Resumo",
			"integradas": "{{value}} integrada(s)"
		},
		"historymodal": {
			"title": "Histórico"
		},
		"motivomodal": {
			"title": "Definir motivo",
			"titleGlobal": "Definir o motivo dos eventos selecionados",
			"titleGroup": "Definir o motivo dos eventos do colaborador: \"{{nomeEmp}}\""
		},
		"printmodal": {
			"title": "Impressão de eventos"
		}
	},
	"gestorrh": {
		"servicos": {
			"fields": {
				"colaborador": "Colaborador",
				"nome": "Nome",
				"papel": "Papel"
			},
			"errors": {
				"unicoServico": "Já existe um colaborador associado ao papel selecionado neste serviço"
			}
		}
	},
	"global": {
		"centralgestLoginName": "CentralGest - Produção de Software, SA",
		"title": "CentralGest Cloud",
		"menu": {
			"developer": "Developer",
			"empresa": "Empresa",
			"home": "Home",
			"demo": "Demo",
			"artigos": "Artigos",
			"recibos": "Recebimentos",
			"pagamentos": "Pagamentos",
			"clifos": "Terceiros",
			"clientes": "Fichas de clientes",
			"fornecedores": "Fichas de fornecedores",
			"outrosdevedorescredores": "Fichas de outros devedores e credores",
			"options": "Opções",
			"configs": "Configurações",
			"configsSite": "Configuração e-mail",
			"configsErp": "Configuração ERP",
			"erpCheck": "Validação das configurações",
			"docscomerciais": "Documentos",
			"docscontabilidade": "Recolha de lançamentos",
			"vendas": "Vendas",
			"compras": "Compras",
			"guias": "Guias",
			"docfas": "Tipos de documento",
			"familias": "Famílias",
			"grandesfamilias": "Grandes famílias",
			"processos": "Processos",
			"ccustos": "Centros de custo",
			"armazens": "Armazéns",
			"codpostais": "Códigos postais",
			"subfamilias": "Sub-famílias",
			"tiposartigo": "Tipos de artigo",
			"unidadesmovimento": "Unidades de movimento",
			"unece": "Unidades de medida de comércio internacional",
			"ivas": "Tabelas de IVA",
			"condicoescomerciais": "Condições comerciais",
			"condicoespagamento": "Condições de pagamento",
			"meiosPagamento": "Meios de pagamento",
			"naturezasContab": "Naturezas contabilísticas",
			"moradasFaturacao": "Moradas de faturação",
			"listagensMovimentosPendentesClientes": "Movimentos pendentes de clientes",
			"listagensMovimentosPendentesFornecedores": "Movimentos pendentes de fornecedores",
			"listagensMovimentosPendentesOutrosDevedoresCredores": "Movimentos pendentes de outros devedores e credores",
			"taxasretencao": "Taxas de retenção",
			"users": "Utilizadores",
			"tokens": "Tokens",
			"roles": "Permissões",
			"templates": "Templates",
			"auditlogin": "Auditoria de login",
			"pocs": "Plano de contas",
			"descritivos": "Descritivos de contabilidade",
			"diarios": "Diários de contabilidade",
			"anosFaturacao": "Nº de anos de faturação",
			"portals": "Portais",
			"periodos": "Períodos de contabilidade",
			"qivaps": "Campos declaração periódica de IVA",
			"qivaas": "Campos declaração anual de IVA",
			"controloIVA": "Controlo de IVA",
			"vendedores": "Vendedores",
			"artigoclasses": "Classes de artigo",
			"mascarasanalitica": "Máscaras da analítica",
			"amortizacao": "Códigos de depreciação",
			"datavalor": "Data valor",
			"estabelecimentos": "Estabelecimentos",
			"estabelecimento": "Estabelecimentos",
			"localidades": "Localidades",
			"local": "Localidades",
			"qpcae": "Atividade Económica",
			"qpdcf": "Distrito/Concelho/Freguesia",
			"calendarios": "Calendários",
			"nacionalidades": "Nacionalidades",
			"tirs": "Tabela IRS",
			"irsdt": "Data da tabela IRS",
			"classescaixa": "Classes de caixa",
			"classescontratos": "Classes de contratos",
			"classescontratosservicos": "Classes de avenças",
			"ativos": "Fichas de ativos",
			"ativosAquisicao": "Aquisições",
			"categoriaartigo": "Categoria artigo",
			"reparticoesccustosfaturacao": "Repartições por centro de custos",
			"reparticoesccustossalarios": "Repartições por centro de custos",
			"account": {
				"main": "perfil",
				"login": "Autenticar",
				"logout": "Sair da conta",
				"changeEmpresa": "Mudar de empresa",
				"changeUser": "Mudar de utilizador",
				"pesquisarEmpresa": "Pesquisar empresa:"
			},
			"language": "Idioma",
			"prhlocais": "Locais",
			"prhmenus": "Menus",
			"dgemps": "Colaboradores",
			"dgempsfull": "Ficha de colaboradores",
			"prhrelementas": "Relacionamento ementas",
			"prhrefeicoes": "Refeições",
			"prhtiporefeicoes": "Tipos de refeições",
			"prhservicos": "Serviços",
			"prhpapeis": "Papeis",
			"prhservicoempregados": "Rel. serv./emp.",
			"prhfluxos": "Fluxos",
			"prhcomunicacoesinternas": "Com. interna",
			"prhcomunicacoesinternasList": "Comunicação interna",
			"paises": "Países",
			"prheventosconfig": "Configuração de eventos",
			"prhdocsplata": "Docs. RH",
			"categorias": "Categorias",
			"efatura": "SAF-T eFatura",
			"configefaturadocporlancar": "Configuração de documentos por lançar eFatura",
			"mercados": "Mercados",
			"criterioDisponiveis": "Critérios Disponíveis",
			"calcPrVenda": "Cálculo preço venda",
			"tipoResumoMensal": "Tipo resumo mensal",
			"origemCondPagamento": "Origem condição pagamento",
			"origemDataVencimento": "Origem data vencimento",
			"tipoOperacao": "Tipo de operação",
			"areaRegional": "Área regional",
			"inversaoSujPassivo": "Inversão sujeito passivo",
			"tipoLiquidacao": "Tipo de liquidação",
			"tipoDeducao": "Tipo de dedução",
			"tipoTransmissaoActiva": "Tipo de transmissão ativa",
			"tipoTransmissaoPassiva": "Tipo de transmissão passiva",
			"tipoBem": "Tipo de bem",
			"tipoTaxa": "Tipo de taxa",
			"tipoValidacaoNif": "Tipo de validação NIF",
			"tipoRegularizacaoCampo40": "Tipo de regularizações do campo 40",
			"tipoRegularizacaoCampo41": "Tipo de regularizações do campo 41",
			"tipoDescritivo": "Tipo de descritivo",
			"formaIncrementoNDoc": "Forma incremento número documento",
			"fluxoMonetario": "Fluxo monetário",
			"tipoTaxaIva": "Tipo taxa iva",
			"areaRegionalTaxa": "Área regional taxa",
			"tipoDeducaoTaxa": "Tipo dedução taxa",
			"tipoConta": "Tipo conta",
			"tiposRefeicoes": "Tipos refeições",
			"tiposMovimento": "Tipos movimento",
			"zonas": "Zonas",
			"codigoscontabilisticos": "Códigos contabilísticos",
			"reembolsosiva": "Reembolso de IVA",
			"fimperiodoenc": "Encerramento de períodos",
			"depto": "Departmento",
			"subde": "Sub-departmento",
			"predefinidoscontabilidade": "Pré-definidos de contabilidade",
			"moeda": "Moedas",
			"moedaNormasIso": "Normas moedas ISO",
			"paisesISO3166": "Paises ISO 3166",
			"prhgruporefeicoes": "Grupo de refeições",
			"reconstrucaoacumulados": "Reconstrução de acumulados",
			"verificacaoacumulados": "Verificação de acumulados",
			"erpusers": "Utilizadores do ERP",
			"terceiros": "Terceiros",
			"concelhos": "Concelhos",
			"distritos": "Distritos",
			"freguesias": "Freguesias",
			"continentes": "Continentes",
			"repfinancas": "Repartições de finanças",
			"assistenteConfigPortais": "Assistente configuração",
			"mapasamortizacao": "Mapas de amortização",
			"tiposcontrato": "Tipos de contrato",
			"divisoesTrabalho": "Divisões de trabalho",
			"qppaises": "Quadro pessoal países",
			"qpdur": "Quadro pessoal durações",
			"seccoes": "Secções",
			"nifs": "Números de identificação fiscal",
			"taxasiva": "Taxas de IVA",
			"fimano": "Fim de ano",
			"calculodiferimentos": "Cálculo diferimentos",
			"extratos": "Extratos de contas",
			"extratosgrid": "Extrato por conta",
			"impdecpe": "Extrato de campo de declaração periódica",
			"ativosconfiguracoes": "Configurações",
			"intfac": "Integração de documentos",
			"listagemdiarios": "Diários de contabilidade",
			"ativosdepreciacao": "Depreciação de ativos",
			"ativosimpressao": "Imprimir de ativos",
			"ativosmapasoficiais": "Imprimir mapas oficiais",
			"ativosgeramapasauto": "Dossier fiscal",
			"analiticaconfig": "Configuração de contas de analítica do plano de contas",
			"ativosaquisicaocontab": "Aquisição a partir de documento contabilístico",
			"ativosabate": "Registar abates em série",
			"ativosvenda": "Registar vendas em série",
			"entradasdiversas": "Entradas diversas",
			"saidasdiversas": "Saídas diversas",
			"extractosartigos": "Extrato de artigos",
			"grabonos": "Grupos de abonos",
			"grdescontos": "Grupos de descontos",
			"ativosimport": "Importação de ativos",
			"rhtipoprocess": "Tipos de processamento de recursos humanos",
			"rhconfiguracoes": "Configurações recursos humanos",
			"rhdadosconfigficha": "Configuração de dados da ficha",
			"tiposregistocadastro": "Tipos de registo de cadastro",
			"situacaoempregado": "Situação de empregado",
			"tipohorario": "Tipo de Horário",
			"rhassistentetrabalhador": "Configuração da integração de salários",
			"rhtransferenciasbancarias": "Transferências bancárias",
			"bancos": "Bancos",
			"idbancos": "Identificação de bancos",
			"sitpr": "Situação de profissões",
			"funcoes": "Funções",
			"nivql": "Níveis de qualificações",
			"qpsit": "Pesquisa situações",
			"segur": "Seguradoras",
			"crss": "Centros de regimes de segurança social",
			"prof": "Profissão",
			"profissao": "Profissão",
			"conttrabcab": "Contrato de trabalho",
			"qpcnp": "Pesquisa de profissões",
			"cltrb": "Classes de trabalho",
			"conttrabbaselegal": "Base legal de contrato de trabalho",
			"conttrabmotivo": "Motivos de contrato de trabalho",
			"rhunidades": "Unidades de recursos humanos",
			"abdes": "Abonos/Descontos",
			"qphab": "Habilitações",
			"qptco": "Quadro pessoal tipo de contrato",
			"abonos": "Abonos",
			"descontos": "Descontos",
			"cgasi": "Códigos de situações da C.G.A.",
			"remun": "Recolha de dados",
			"remfx": "Recolha de dados fixos",
			"rhpenhoras": "Penhoras",
			"rhprocsegsocialconsultenvios": "Seg. social - consulta de envios",
			"crdescontos": "Cruzamentos de descontos",
			"crabonos": "Cruzamentos de abonos",
			"procseguradorafolhaferias": "Processamento da folha de férias para seguradora",
			"pcaRecibosVencimentos": "Recibos de vencimento",
			"rhgestaoficheirosdmrat": "Gestão de ficheiros DMR AT",
			"estados": "Estados",
			"rendis": "Tipos de rendimento",
			"situacaoprofissional": "Situação profissional",
			"qpmte": "Motivos de entrada de empregados",
			"cct": "Contratos coletivos de trabalho",
			"qpirt": "Pesquisa IRCT",
			"qpisp": "Código ISP",
			"trfLancContas": "Transferência de contas",
			"usersbasic": "Utilizadores",
			"ativoscoeficientecm": "Atualização coeficiente de correção monetária",
			"propostasaclientes": "Orçamentos",
			"encomendas": "Encomendas",
			"encomendasclientes": "Encomendas clientes",
			"encomendasfornecedores": "Encomendas fornecedores",
			"ativosconsistencia": "Consistência de valores entre ativos e contabilidade",
			"docsComerciaisEstatisticasVendas": "Vol. Negócios - Vendas por artigo",
			"docsComerciaisEstatisticasDocVendas": "Vol. Negócios - Vendas por documento",
			"docsComerciaisEstatisticasCompras": "Vol. Negócios - Compras por artigo",
			"docsComerciaisEstatisticasDocCompras": "Vol. Negócios - Compras por documento",
			"clientconnect": "Client Connect",
			"assistenteConfigPortaisPortalClientConnectUtilizador": "Portal Client Connect - Configuração novo utilizador",
			"assistenteConfigPortaisPortalClientConnectUtilizadorExistente": "Portal Client Connect - Configuração utilizador existente",
			"documentosdigitais": "Documentos digitais",
			"documentosdigitaisConciliacao": "Conciliação de documentos digitais",
			"documentosDigitaisClientConnect": "Documentos digitais",
			"rhcessacontratotrabalho": "Gera cessação de contrato",
			"rhmanutencao": "Manutenção dos recursos humanos",
			"rhcalculorendimentoliquido": "Cálculo por rendimento líquido",
			"rhrecolhaseriesubsidiosferias": "Pagamento em série de subsídio de férias",
			"rhrecolhaseriesubsidiosnatal": "Pagamento em série de subsídio de natal",
			"rhrecolhaseriesubsidiosalimentacao": "Pré-processamento em série de subsídio de alimentação",
			"rhqpirct": "Tabelas oficiais IRCT dos quadros pessoal",
			"rhprocessamentosalarios": "Configuração do processamento de salários",
			"rhfolfer": "Folha de ordenados",
			"rhestatisticas": "Estatísticas de Recursos Humanos",
			"rhestatisticasmulti": "Estatísticas de Recursos Humanos (Multi-Empresa)",
			"modelo10": "Modelo 10",
			"retencoes": "Visualização de retenções",
			"modelo22": "Modelo 22",
			"modelo25": "Modelo 25",
			"modelo30": "Modelo 30",
			"modelo39": "Modelo 39",
			"rhimpvenct": "Listagens de estatísticas de vencimentos",
			"impctrirs": "Listagens de controlo de IRS",
			"rhagencias": "Manutenção de bancos, agências e contas",
			"impfferpoc": "Classificação da folha de ordenados",
			"rhtranssal": "Transferências salários",
			"trfba": "Pagamentos por transferência bancária",
			"trfbarh": "Pagamentos por transferência bancária",
			"trfbacomercial": "Pagamentos por transferência bancária",
			"contabAdvancedOperationsPeriodo": "Alteração de período de documentos",
			"contabAdvancedOperationsDiario": "Alteração de diário de documentos",
			"contabAdvancedOperationsAnular": "Anular documentos",
			"faturacaopublica": "Gestão de documentos de faturação eletrónica pública",
			"idide": "Grupos de conta",
			"impencar": "Listagem de encargos",
			"impAvisosCobranca": "Impressão de avisos de cobrança",
			"declaracaoiva": "Declaração periódica IVA",
			"declaracaoivarecapitulativa": "Declaração recapitulativa de IVA",
			"vendascliente": "Resumo por cliente",
			"vendasartigo": "Resumo por artigo",
			"resumoiva": "Resumo de IVA",
			"definicoesemail": "Definição de E-Mails",
			"dashboard": "Dashboard",
			"marcarRefeicoes": "Marcar refeições",
			"visualizarRefeicoes": "Visualizar refeições",
			"extratosFornecedores": "Extratos fornecedores",
			"extratosClientes": "Extratos clientes",
			"gto": "Gestão de tarefas e obrigações",
			"entidadeexterna": "Entidades externas",
			"solic": "Textos de aviso de solitação de cobranças",
			"impdadosemp": "Listagem de dados de colaboradores",
			"dmrf": "Declaração mensal de retenção na fonte",
			"simulacaovencimento": "Simulação de recibos",
			"contratostipos": "Tipos de contratos",
			"contratostiposervicos": "Tipos de avenças",
			"concilmovab": "Justificação de movimentos em aberto",
			"faturacaocontratos": "Faturação de avenças",
			"conciliacaobancaria": "Conciliação bancária",
			"bancosextrato": "Banking automation",
			"bancosextratosimple": "Banking automation",
			"sind": "Ficheiro de sindicato",
			"utlviatpatronal": "Utilização viaturas de entidade patronal",
			"demfinsnc": "Demonstrações financeiras SNC",
			"contratos": "Contratos",
			"contratosservicos": "Avenças",
			"transferenciasarmazens": "Transferência de artigos entre armazéns",
			"rhrelatoriounico": "Relatório único",
			"contratosprint": "Impressão de contratos",
			"impde119": "Declaração Artº 119 CIRS",
			"tesrubrica": "Estados de validação de previsões de tesouraria",
			"prevites": "Previsões de tesouraria",
			"previtesauto": "Lançamento automático de previsões de tesouraria",
			"prevtesobras": "Importador de cronogramas financeiros das obras",
			"qpmts": "Motivos de saída de empregados",
			"officereporting": "Evolução negócio",
			"ativosaquisicaoinvestemcurso": "Aquisição a partir dos investimentos em curso",
			"faturacaoeletronicaconfiguracoes": "Configuração da faturação eletrónica",
			"segsocialfolhaferias": "Segurança social / folha de férias",
			"integracaoSalarios": "Integração de salários",
			"proceSalarios": "Processamento salários",
			"proceDMRAT": "Processamento da DMR - AT",
			"proceSegSocial": "Processamento segurança social",
			"integracaoSalariosMulti": "Integração de salários (multi-empresas)",
			"gestaoFicheirosSepa": "Gestão de ficheiros SEPA",
			"rhgozoferias": "Gozo férias",
			"mbconfigs": "Configuração das referências multibanco",
			"rhatualizaferiasemp": "Atualizar férias ficha empregado",
			"rhmapassindicatos": "Mapa para sindicatos",
			"extratosDT": "Histórico de extratos de conta",
			"extratosDTClientes": "Extrato de conta cliente",
			"extratosDTFornecedores": "Extrato de conta fornecedor",
			"infoglobaldiferimentosacrescimos": "Informação global diferimentos/acréscimos",
			"infoglobaldiferimentos": "Informação global diferimentos",
			"infoglobalacrescimos": "Informação global acréscimos",
			"rhregistarempregadosss": "Consultar/Registar empregados na segurança social",
			"rhmanutencaocampos": "Atualização campos salários",
			"analisadorTes": "Planeamento de tesouraria",
			"importxls": "Importações",
			"pocscopy": "Copiar contas plano para empresas",
			"controloivaentreempresas": "Copiar controlo iva para empresas",
			"avisoscobrancagrid": "Avisos de cobrança",
			"saftcontab": "Importador SAF-T contabilidade",
			"tesestados": "Estados de validação de previsões de tesouraria",
			"guiasfornecedores": "Guias fornecedores",
			"lotes": "Lotes",
			"morals": "Moradas alternativas",
			"arclis": "Artigo de cliente/fornecedor",
			"clicls": "Classe",
			"mapaPagamentos": "Mapa de pagamentos",
			"exportacaocompras": "Exportação geofolia",
			"planoscontasalternativos": "Planos contas alternativos",
			"balancetes": "Balancetes de contabilidade",
			"balancetesalternativos": "Balancetes de planos alternativos",
			"balancetesanalitica": "Balancetes de contabilidade analítica",
			"comprasautofaturacao": "Autofaturação",
			"acrescimosencargoscomferias": "Acréscimos de encargos com férias",
			"adiantamentosclientes": "Adiantamentos clientes",
			"adiantamentosfornecedores": "Adiantamentos fornecedores",
			"rhCopiaAbonosDescontos": "Copia abonos/descontos",
			"trdocs": "Transformação de documentos",
			"cargveic": "Definições de veículo",
			"ativosaquisicaoinvestemcursopordoc": "Aquisição a partir dos investimentos em curso por documento",
			"manuttributautonomas": "Manutenção tributações autónomas",
			"tributautonomas": "Tributações autónomas",
			"rendHabitPerm": "Utilização de habitação entidade patronal",
			"mapaExploracao": "Mapa exploração",
			"colaboradoresAlteracoesEntidades": "Dados a alterar",
			"colaboradoresAprovacoesRefeicoes": "Refeições por aprovar",
			"colaboradorboard": "Resumo",
			"colaboradoresassociaservicos": "Associar a serviço",
			"colaboradoreshome": "Home",
			"colaboradoresservicos": "As. papéis a serviços",
			"colaboradorescomunicacoesinternas": "Comunicações",
			"colaboradoresdgemps": "Utilizadores",
			"dgempsficha": "Dados pessoais",
			"documentosPartilhados": "Documentos partilhados",
			"dossierAlteracoesCapitalProprio": "Alterações do capital próprio",
			"dossierAnexoDemonstracoesFinanceiras": "Anexo",
			"dossierBalanco": "Balanço",
			"dossierDemonstracaoResultados": "Demons. resultados",
			"dossierFluxoCaixa": "Fluxos de caixa",
			"dossierResultadosPorNatureza": "Resultados por funções",
			"empresas": "Empresa",
			"fct": "Fundo de compensação",
			"colaboradoresfluxos": "Fluxos",
			"ies": "Exportar ficheiro IES-DA",
			"modules": "Módulos",
			"conciliacaoEfaturas": "Conciliação eFatura",
			"pcaBalancetes": "Balancetes",
			"pcaExtratos": "Extratos",
			"pcaIdadeSaldosClientes": "Saldos clientes",
			"pcaIdadeSaldosFornecedores": "Saldos fornecedores",
			"documentosFiscais": "Documentos fiscais",
			"movimentosemaberto": "Movimentos em aberto de contabilidade",
			"svat": "Demonstrações financeiras SVAT",
			"pcaContas": "Identificação",
			"pcaDocumentosComerciais": "Faturação",
			"pcaExtratosConta": "Extrato",
			"pcaMovimentosAbertoCliente": "Movimentos em aberto",
			"pcaMapaFaltas": "Mapa faltas",
			"pcaMapaFerias": "Mapa férias",
			"pcaFundosCompensacao": "Fundo compensação",
			"pcaMapaSeguradora": "Mapa seguradora",
			"pcaSegurancaSocial": "Segurança social",
			"pcaMapaSindicatos": "Mapa sindicatos",
			"colaboradoresrecibosvencimento": "Recibos",
			"colaboradoresAssociarMenus": "Associar menus",
			"colaboradoresListagemMenus": "Listagens menus",
			"colaboradoresListagemRefeicoes": "Listagens refeições",
			"rptconfigs": "Configuração de relatórios",
			"inventarios": "Inventários",
			"listagensVendasAnoMes": "Vendas por mês",
			"saft": "Exportar ficheiro SAF-T",
			"artars": "Artigos de armazém",
			"crneg": "Exceções de cruzamento",
			"docfasnums": "Séries de tipos de documentos",
			"docfasnumscomunicacaologin": "Login comunicação de séries",
			"docspartilhados": "Documentos plataforma RH",
			"erputilizadores": "Utilizadores CentralGest",
			"erps": "Instalações",
			"fremun": "Formas de remuneração",
			"gdoc": "Gestão de anexos",
			"indicefluxos": "Índices fluxos",
			"ivamotivostaxa": "Motivos de isenção de IVA",
			"perfilCategoriaAbono": "Perfis categorias abonos",
			"perfilCategoriaDesconto": "Perfis categorias descontos",
			"sscol": "Remunerações da segurança social",
			"taxonomias": "Taxonomias e validações SVAT",
			"importadorEfatura": "Documentos por lançar eFatura",
			"importadorSaftDirect": "Importador SAF-T PT faturação para contabilidade",
			"atFaturasRecibosVerdes": "AT - Faturas e recibos verdes",
			"apuraiva": "Apuramento de IVA e fim de período",
			"apuramentoresultados": "Apuramento de resultados líquidos",
			"pcaContabilidadeMovimentosAbertoCliente": "Movimentos em aberto de clientes",
			"pcaContabilidadeMovimentosAbertoFornecedor": "Movimentos em aberto de fornecedores",
			"qpapa": "Associação Patronal",
			"qpnj": "Natureza Jurídica",
			"sectores": "Setores",
			"docsComerciaisConfigNomeExportFile": "Configuração de nome dos ficheiros a exportar",
			"txtar": "Textos Livres de Artigos",
			"txtcl": "Textos Livres de Entidades",
			"mapadiferimentosacrescimos": "Mapa Acréscimos e Diferimentos",
			"prhtipocom": "Tipos de Comunicação",
			"consultasafornecedores": "Orçamentos",
			"grupodocfaconfiguracoes": "Configurações de Grupo de Tipos de Documento",
			"analisemargemlucro": "Análise Margem de Lucro",
			"comcategorias": "Categorias de Comunicação",
			"gestaoComunicacoes": "Gestão de comunicações",
			"gestaoComunicacoesCliente": "Gestão de comunicações cliente"
		},
		"form": {
			"username": "Utilizador",
			"username.placeholder": "O seu nome para autenticação",
			"usernameemail.placeholder": "Nome de utilizador ou e-mail",
			"newpassword": "nova palavra-passe",
			"newpassword.placeholder": "a sua nova palavra-passe",
			"confirmpassword": "Confirmação da palavra-passe",
			"confirmpassword.placeholder": "Confirmação da palavra-passe",
			"email": "E-mail",
			"email.placeholder": "o seu e-mail",
			"notApplicable": "N/A"
		},
		"text": {
			"notDefined": "N/D",
			"note": "Nota",
			"filters": "Filtros",
			"errorNum": "Erro #{{error}}.",
			"contactAdmin": "Por favor contacte o administrador.",
			"empty": " ",
			"success": "Sucesso",
			"info": "Informação",
			"warning": "Aviso",
			"error": "Erro",
			"confirmation": "Confirmação",
			"help": "Ajuda",
			"faq": "Perguntas frequentes",
			"other": "Outros",
			"year": "Ano",
			"month": "Mês",
			"date": "Data",
			"fromDate": "Data de",
			"toDate": "Data até",
			"to": "Até",
			"total": "Total",
			"saldo": "Saldo",
			"listing": "Listagem",
			"searchfilter": "Filtros de pesquisa",
			"keeppanel": "Manter painel aberto",
			"all": "Todos",
			"general": "Geral",
			"actions": "Ações",
			"attention": "Atenção",
			"preview": "Pré-visualização",
			"movimentosPendentes": "Movimentos pendentes",
			"active": "Ativo",
			"initProcess": "A iniciar o processamento...",
			"searchNoData": "Não foram encontrados dados",
			"and": "e",
			"or": "ou",
			"myAccount": "A minha conta",
			"pleaseSearchFirst": "Por favor realize uma pesquisa primeiro",
			"deleteRecord": "Apagar registo",
			"name": "Nome",
			"today": "Hoje"
		},
		"language": {
			"pt": "Português",
			"en": "Inglês"
		},
		"btn": {
			"actions": "Ações",
			"add": "Adicionar",
			"addLine": "Adicionar linha",
			"advancedSearch": "Pesquisa avançada",
			"apply": "Aplicar",
			"approve": "Aprovar",
			"attach": "Anexar",
			"back": "Voltar",
			"calculate": "Calcular",
			"cancel": "Cancelar",
			"change": "Alterar",
			"changeEmpresa": "Mudar empresa",
			"clone": "Clonar",
			"close": "Fechar",
			"config": "Configuração",
			"configWS": "Configurações WS",
			"confirm": "Confirmar",
			"create": "Criar",
			"createFile": "Criar Ficheiro",
			"delete": "Apagar",
			"downloadPdf": "Download PDF",
			"edit": "Editar",
			"email": "E-mail",
			"excel": "Excel",
			"export": "Exportar",
			"exportPdf": "Exportar PDF",
			"exportXls": "Exportar excel",
			"finalize": "Finalizar",
			"goBack": "Retroceder",
			"goForward": "Avançar",
			"help": "Ajuda",
			"import": "Importar",
			"importExport": "Importar/Exportar",
			"load": "Carregar",
			"mark": "Marcar",
			"new": "Novo",
			"next": "Seguinte",
			"no": "Não",
			"nullify": "Anular",
			"ok": "OK",
			"options": "Opções",
			"pdf": "PDF",
			"preview": "Pré-visualizar",
			"previous": "Anterior",
			"print": "Imprimir",
			"process": "Processar",
			"processExcel": "Processar em excel",
			"processGrelha": "Processar em grelha",
			"processPDF": "Processar PDF",
			"refresh": "Atualizar",
			"register": "Registar",
			"reject": "Rejeitar",
			"remove": "Remover",
			"replenish": "Repor",
			"replenishOriginalValues": "Repor valores de origem",
			"retry": "Tentar novamente",
			"revert": "Reverter",
			"save": "Guardar",
			"search": "Pesquisar",
			"send": "Enviar",
			"sendFiles": "Enviar ficheiros",
			"sendMail": "Enviar E-mail",
			"shortcuts": "Atalhos",
			"simulate": "Simular",
			"store": "Loja",
			"submeterAt": "Submeter AT",
			"update": "Atualizar",
			"validarAt": "Validar na AT",
			"validarFicheiro": "Validar Ficheiro",
			"visualize": "Visualizar",
			"yes": "Sim"
		},
		"saveDoc": "Guardar documento",
		"saveDocId": "Guardar documento {{id}}",
		"saveDocSimulation": "Guardar documento (simulação)",
		"saveDocMessage": "Tem a certeza que deseja guardar o documento?",
		"saveDocMessageSimulation": "Tem a certeza que deseja simular a gravação do documento?",
		"advancedFilter": "Filtro avançado",
		"debitCredit": {
			"debit": "Débito",
			"credit": "Crédito"
		},
		"states": {
			"admin": {
				"title": "Administração"
			}
		},
		"allRightsReserved": "Todos os direitos reservados",
		"export": {
			"toExcel": "Exportar para Excel",
			"toPDF": "Exportar para PDF"
		},
		"keyboard": {
			"arrowLeft": "Seta esquerda",
			"arrowRight": "Seta direita"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;' aria-hidden='true'></i> Pretende sair sem gravar?",
			"exitMessage": "Ao clicar em OK irá sair sem gravar e todas as alterações que fez serão apagadas. Tem a certeza que pretende sair?"
		},
		"months": {
			"january": "Janeiro",
			"february": "Fevereiro",
			"march": "Março",
			"april": "Abril",
			"may": "Maio",
			"june": "Junho",
			"july": "Julho",
			"august": "Agosto",
			"september": "Setembro",
			"october": "Outubro",
			"november": "Novembro",
			"december": "Dezembro"
		},
		"dayOfWeek": {
			"monday": "Segunda-feira",
			"tuesday": "Terça-feira",
			"wednesday": "Quarta-feira",
			"thursday": "Quinta-feira",
			"friday": "Sexta-feira",
			"saturday": "Sábado",
			"sunday": "Domingo"
		},
		"monthsShort": {
			"january": "Jan",
			"february": "Fev",
			"march": "Mar",
			"april": "Abr",
			"may": "Mai",
			"june": "Jun",
			"july": "Jul",
			"august": "Ago",
			"september": "Set",
			"october": "Out",
			"november": "Nov",
			"december": "Dez"
		}
	},
	"disconnected": {
		"title": "Desligado do Servidor",
		"message": "Por favor verifique a sua ligação e tente novamente."
	},
	"entity": {
		"action": {
			"addblob": "Add blob",
			"addimage": "Adiciona imagem",
			"back": "Voltar",
			"cancel": "Cancelar",
			"delete": "Apagar",
			"edit": "Editar",
			"save": "Guardar",
			"view": "Ver",
			"new": "Novo",
			"filter": "Filtro",
			"refresh": "Atualizar",
			"retrieveBackup": "Recuperar último documento",
			"duplicate": "Duplicar",
			"actions": "Ações",
			"config": "Configuração",
			"tooltip": {
				"duplicate": "Criar novo com base neste"
			}
		},
		"state": {
			"saved": "Guardado com sucesso",
			"error": "Não é possível gravar, por favor verifique os dados introduzidos"
		},
		"list": {
			"withoutdata": "sem dados",
			"loading": "a ler...",
			"secondaryClick": {
				"menu": {
					"openAsModal": "Visualizar",
					"openAsNewTabOrWindow": "Abrir numa nova janela ou separador"
				}
			}
		},
		"placeholder": {
			"search": "Pesquisa"
		},
		"detail": {
			"field": "Campo",
			"value": "Valor"
		},
		"delete": {
			"title": "Apagar registo {{id}}?",
			"message": "Tem a certeza que deseja apagar?"
		},
		"validation": {
			"required": "Este campo é obrigatório",
			"minlength": "Este campo tem de ter pelo menos {{min}} caracteres.",
			"maxlength": "Este campo não pode ter mais que {{max}} caracteres.",
			"min": "Este campo tem de ser maior do que {{min}}.",
			"max": "Este campo tem se ser menor do que {{max}}.",
			"minbytes": "This field should be more than {{min}} bytes.",
			"maxbytes": "This field cannot be more than {{max}} bytes.",
			"pattern": "This field should follow pattern {{pattern}}.",
			"datetimelocal": "This field should be a date and time.",
			"email": "O e-mail não é valido",
			"patern": "O valor não está correto {{info}}",
			"number": "Não é um número válido",
			"fieldMatch": "Não coincide",
			"vat": "Número de contribuinte não é válido"
		},
		"autocomplete": {
			"errorAllowInvalid": "O valor introduzido não é válido",
			"newItem": "Novo {{attrName}}",
			"createNewItem": "O {{attrName}} não existe. Pretende criar um novo {{attrName}}?"
		},
		"maintenance": {
			"headerSearch": "Pesquisa de {{entityName}}",
			"headerMaintenance": "Manutenção de {{entityName}}"
		}
	},
	"error": {
		"server": {
			"unavailable": "O servidor não está acessível. Por favor, tente novamente mais tarde.",
			"badRequest": "O servidor rejeitou o seu pedido.",
			"unauthorized": "Não está autenticado.",
			"forbidden": "Não tem autorização para efetuar este pedido.",
			"notFound": "Página ou pedido não encontrado.",
			"internalServerError": "Erro interno no servidor. Por favor, tente novamente mais tarde.",
			"serviceUnavailable": "O sistema encontra-se em manutenção. Por favor, tente novamente mais tarde."
		},
		"invalidForm": "Não é possível gravar, por favor verifique os dados introduzidos"
	},
	"toolbar": {
		"year": "Ano: {{value}}",
		"month": "Mês: {{value}}",
		"period": "Período: {{value}}"
	},
	"grdescontos": {
		"title_detail": "Grupo de Desconto {{id}}",
		"title_new": "Novo Grupo de Desconto",
		"title_plural": "Grupos de Desconto",
		"pesquisa": "Pesquisar",
		"saved": "Grupo de Desconto {{id}}, guardado com sucesso",
		"error": "Grupo de Desconto {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Grupo de Desconto {{id}}, apagado com sucesso",
		"fields": {
			"codGrDesconto": "Código",
			"descricao": "Descrição",
			"categoria": "Categoria",
			"assiduidadeSegSocial": "Assiduidade p/ seg. social",
			"absentismo": "Absentismo",
			"suspensaoContrato": "Suspensão de contrato",
			"remunerado": "Remunerado",
			"recibo": "Recibo",
			"entidadeSuporta": "Entidade que suporta",
			"ausencias": "Ausências",
			"justificacaoAusencia": "Justificação da ausência",
			"balancoSocial": "Balanço social",
			"horasNaoTrabalhadas": "Horas não trabalhadas",
			"contaDiasFeriasNorm": "Conta p/ dias férias normais",
			"contaDiasFeriasAcima": "Conta p/ dias férias acima normais",
			"contaDiasSubNatal": "Conta p/ dias sub natal",
			"contaDiasSubAlimen": "Conta p/ dias sub alimentação"
		}
	},
	"grabonos": {
		"title_detail": "Grupo de Abono {{id}}",
		"title_new": "Novo Grupo de Abono",
		"title_plural": "Grupos de Abono",
		"pesquisa": "Pesquisar",
		"saved": "Grupo de Abono {{id}}, guardado com sucesso",
		"error": "Grupo de Abono {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Grupo de Abono {{id}}, apagado com sucesso",
		"fields": {
			"codGrAbono": "Código",
			"descricao": "Descrição",
			"categoria": "Categoria",
			"assiduidadeSegSocial": "Assiduidade p/ seg. social",
			"presencas": "Presenças",
			"remunerado": "Remunerado",
			"recibo": "Recibo",
			"entidadeSuporta": "Entidade que suporta",
			"tipoTrabalho": "Tipo de trabalho",
			"balancoSocial": "Balanço social",
			"contaDiasFeriasNorm": "Conta p/ dias férias normais",
			"contaDiasFeriasAcima": "Conta p/ dias férias acima normais",
			"contaDiasSubNatal": "Conta p/ dias sub natal",
			"contaDiasSubAlimen": "Conta p/ dias sub alimentação",
			"formacao": "Formação"
		}
	},
	"grandesfamilias": {
		"title_detail": "Grande Família {{id}}",
		"title_new": "Nova Grande Família",
		"title_plural": "Grandes Famílias",
		"pesquisa": "Pesquisar",
		"deleted": "Grande Família {{id}}, apagada com sucesso.",
		"saved": "Grande Família {{id}}, guardada com sucesso.",
		"error": "Grande Família {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"fields": {
			"nDepart": "Cód.",
			"nDepartPlaceholder": "Código da grande família",
			"nome": "Nome",
			"nomePlaceholder": "Nome da grande família"
		}
	},
	"gto": {
		"title": "Gestão de Tarefas e Obrigações",
		"tabs": {
			"title": {
				"porEmpresa": "Por empresa",
				"multiEmpresa": "Multi-empresa",
				"ativarTarefas": "Ativar tarefas",
				"configuracoes": "Configurações",
				"configuracoesMulti": " Configurações multi-empresa"
			},
			"filters": {
				"empresa": "Empresa",
				"tipotarefa": "Tipo tarefa",
				"responsavel": "Responsável",
				"mostrarfilter": "Mostrar",
				"periodoiva": "Período IVA",
				"regimeirc": "Regime IRC / IRS",
				"nomeTarefa": "Nome tarefa"
			},
			"table": {
				"fields": {
					"nometarefa": "Nome tarefa",
					"nempresa": "Nº empresa",
					"sel": "Sel."
				},
				"semdados": "Sem dados"
			},
			"btn": {
				"checkstatedmratss": "Verificar estado DMR AT e SS",
				"checkstatedmrss": "Verificar estado DMR SS",
				"checkstatedmrat": "Verificar estado DMR AT",
				"dmrat": "DMR AT",
				"dmrss": "DMR SS",
				"alertas": "Alertas",
				"filtroavanc": "Filtro avançado"
			},
			"actions": {
				"executartarefa": "Executar tarefa",
				"concluirtarefa": "Concluir tarefa",
				"conctarefdentroprazo": "Concluir tarefa dentro do prazo",
				"editartarefa": "Editar tarefa",
				"desativartarefa": "Desativar tarefa",
				"estatisticas": "Estatísticas"
			}
		},
		"toolbar": {
			"createNewTask": "Criar tarefa",
			"config": "Configurar",
			"copyTasks": "Copiar tarefas"
		},
		"nomeTarefa": "Nome tarefa",
		"tipoTarefa": "Tipo tarefa",
		"obrigacoes": "Obrigações",
		"pagamentos": "Pagamentos",
		"outra": "Outra",
		"dataInicioPeriodo": "Data de início do período",
		"mes1anterior": "Mês - 1",
		"mes2anterior": "Mês - 2",
		"ano1anterior": "Ano - 1",
		"entidadeExterna": "Entidade externa",
		"createNewTaskTitle": "Criar nova tarefa",
		"editTaskTitle": "Editar tarefa",
		"applyAllEmpresas": "Atualiza o calendário em todas as empresas que têm esta tarefa",
		"tarefa": "Tarefa",
		"tarefas": "Tarefas",
		"config": "Configuração",
		"empresa": "Empresa",
		"ativarGTONaEmpresa": "Ativar gestão de tarefas e obrigações",
		"alertasNaApp": "Alertas na aplicação",
		"inicio": "Início",
		"fields": {
			"ativar": "Ativar",
			"nomeTarefa": "Nome tarefa",
			"responsavel": "Responsável",
			"nEmpresa": "Nº empresa",
			"nomeEmpresa": "Nome",
			"regimeIRCIRS": "Regime IRC / IRS",
			"periodoIVA": "Período IVA",
			"sel": "Sel."
		},
		"invalidSelectedDay": "Dia inválido",
		"saveConfig": "Guardar configuração",
		"legends": {
			"concluidoDentroPrazo": "Concluído dentro do prazo",
			"guardarValidacao": "Em validação",
			"emAtrasoJustificado": "Em atraso justificado",
			"concluidoForaPrazo": "Concluído fora do prazo",
			"emAtraso": "Em atraso",
			"prazoADecorrer": "Prazo a decorrer",
			"naoAplicavel": "Não aplicável",
			"pendente": "Pendente"
		},
		"datasources": {
			"tipotarefa": {
				"obrigacoes": "Obrigações",
				"pagamentos": "Pagamentos",
				"outra": "Outra",
				"todos": "Todos"
			},
			"mostrarfilter": {
				"datalimite": "Data limite",
				"datalimitePlusNotes": "Data limite e notas",
				"values": "Valor",
				"valuesPlusNotes": "Valor e notas",
				"dias": "Dias",
				"diasPlusNotes": "Dias e notas",
				"dataexecucao": "Data execução",
				"dataexecucaoPlusNotes": "Data execução e Notas"
			}
		},
		"copyTasksModalTitle": "Copiar tarefas",
		"copiar": "Copiar",
		"copyFor": "Copiar para",
		"iraSubstituirTarefasNoDestino": "O procedimento irá criar ou substituir as tarefas no destino. No caso de substituição, irá eliminar toda a classificação das tarefas. Tem a certeza que pretende continuar?",
		"iraCriarAtualizarTarefasNoDestino": "O procedimento irá criar ou atualizar as tarefas no destino. Tem a certeza que pretende continuar?",
		"temQueSelecionarUmaTarefa": "Tem que selecionar pelo menos uma tarefa!",
		"temQueSelecionarUmaEmpresa": "Tem que selecionar pelo menos uma empresa!",
		"tarefasCopiadasComSucesso": "As tarefas selecionadas foram copiadas com sucesso.",
		"paraAno": "Para ano",
		"paraEmpresas": "Para empresa(s)",
		"naoPodeCopiarParaMesmoAno": "Não pode copiar tarefas para o mesmo ano.",
		"executarTarefa": "Executar Tarefa",
		"estado": "Estado",
		"estados": {
			"semAlteracao": "Sem alteração",
			"concluir": "Concluir",
			"adicionarJustificacao": "Adicionar justificação do atraso",
			"naoAplicavel": "Não aplicável",
			"new": "Não concluído",
			"guardarValidacao": "Em validação"
		},
		"messages": {
			"taskfromotheruser": "Esta tarefa pertence ao outro responsável. Tem a certeza que pretende continuar?",
			"validacaosuccess": "Verificação efetuada com sucesso!",
			"selempreforcheck": "Selecione empresas para verificar o estado de envios!",
			"temACertezaQuePertendeConcluirTarefasSelecionadas": "Tem a certeza que pretende concluir tarefas selecionadas?",
			"temACertezaQuePertendeReporTarefas": "Tem a certeza que pretende repor as tarefas por defeito da empresa \"{{nEmpresa}}\" para ano \"{{ano}}\"?",
			"jaExistemTarefasCriadas": "Já existem tarefas criadas para a empresa. Pretende eliminar as atuais e criar novas tarefas?"
		},
		"alertsModalTitle": "Alertas",
		"valor": "Valor",
		"justificacao": "Justificação",
		"dataExecucao": "Data execução",
		"estaTarefaPertenceAoOutroResponsavel": "Esta tarefa pertence ao outro responsável. Tem a certeza que pretende continuar?",
		"operacaoConcluidaComSucesso": "Operação concluída com sucesso.",
		"temACertezaQuePertendeApagarEstaTarefa": "Tem a certeza que quer eliminar esta tarefa?",
		"tarefaEliminadaComSucesso": "Tarefa eliminada com sucesso!",
		"licensemodal": {
			"total": "Número total de empresas: {{total}}",
			"totalConsumed": "Número total de empresas já adicionadas: {{totalConsumed}}",
			"totalToConsume": "Número total de empresas que ainda pode adicionar: {{totalToConsume}}",
			"license1": "Caso clique em \"Sim\", o sistema irá adicionar uma nova empresa à sua licença de gestão de tarefas e obrigações, não sendo possível reverter a situação posteriormente.",
			"license2": "Neste momento, ainda pode adicionar à sua licença da gestão de tarefas e obrigações {{totalToConsume}} empresas. Deseja adicionar uma nova empresa à sua licença de gestão de tarefas e obrigações?",
			"license3": "Dados da licença de gestão de tarefas e obrigações",
			"license4": "Neste momento, não pode adicionar empresas à sua licença da gestão de tarefas e obrigações. Por favor contacte a CentralGest para obter mais informações."
		},
		"aSuaLicencaNaoTemGTO": "A sua licença não tem gestão de tarefas e obrigações. Por favor contacte a CentralGest para adquirir o produto.",
		"selectAll": "Selecionar todos",
		"unSelectAll": "Limpar seleção",
		"loadingLicenseData": "A carregar os dados da licença...",
		"naoTemEmpresasSuficientes": "Não pode proceder com operação porque a sua licença não tem empresas suficientes para consumir.<br/><br/>Número total de empresas que ainda pode adicionar: {{totalToConsume}}<br/>Número total de empresas que vai adicionar: {{totalToAdd}}",
		"temACertezaQuePretendeAddEmpresas": "Tem a certeza que pretende adicionar {{nempresas}} empresa(s) a sua licença da gestão de tarefas e obrigações?",
		"hints": {
			"concluidoDentroPrazo": "A tarefa foi executada em {{dataExec}} (dentro do prazo) por {{user}}",
			"concluidoForaPrazo": "A tarefa foi executada em {{dataExec}} (fora do prazo) por {{user}}",
			"emAtrasoJustificado": "A tarefa em atraso. <b>Justificação</b>: {{justificacao}}",
			"valor": "<b>Valor</b>: {{valor}}",
			"nota": "<b>Nota</b>: {{nota}}",
			"selecaoMultiplaText": "Pressione Ctrl e clique com o botão esquerdo do rato em um item para seleção não consecutiva, ou pressione o botão esquerdo do rato e arraste sobre items.",
			"inicializaRepoeTarefasPorDefeito": "Inicializa / Repõe as tarefas por defeito."
		},
		"selecaoMultipla": "Seleção múltipla"
	},
	"helperLinks": {
		"global": {
			"all": "Ver todos os vídeos",
			"faqs": "Ver perguntas frequentes"
		}
	},
	"helptopics": {
		"titles": {
			"tutorialVideos": "Tutorial - Vídeos",
			"formationsVideos": "Formações - Vídeos",
			"forumCG": "Fórum CentralGest",
			"facebookUserGroup": "Grupo de utilizadores CentralGest no Facebook"
		}
	},
	"idadesaldos": {
		"label": {
			"grupoConta": "Grupo de conta",
			"showGrupoConta": "Mostrar grupo de conta"
		}
	},
	"idbancos": {
		"title_detail": "Identificação de banco {{id}}",
		"title_new": "Nova identificação de banco",
		"title_plural": "Identificação de bancos",
		"pesquisa": "pesquisar",
		"saved": "Identificação de banco {{id}} guardado com sucesso.",
		"error": "Identificação de banco {{id}} não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Identificação de banco {{id}} apagado com sucesso",
		"fields": {
			"idinterBancario": "Cód. interbancário",
			"nomeInterBancario": "Nome interbancário",
			"descricao": "Descrição",
			"formatoTRFAG": "Formato TrfPag",
			"formatoTRFREC": "Formato TrfRec",
			"inativo": "Inativo"
		}
	},
	"idide": {
		"title_detail": "Grupo de Conta {{id}}",
		"title_new": "Novo Grupo de Conta",
		"title_plural": "Grupos de Contas",
		"pesquisa": "Pesquisar",
		"saved": "Grupo de Conta {{id}}, guardado com sucesso",
		"error": "Grupo de Conta {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Grupo de Conta {{id}}, apagado com sucesso",
		"fields": {
			"idIdeChav": "Código",
			"descricao": "Descrição",
			"tipoCred": "Tipo crédito",
			"credito1": "Crédito 1",
			"credito2": "Crédito 2",
			"credito3": "Crédito 3",
			"credito4": "Crédito 4",
			"credito5": "Crédito 5"
		}
	},
	"ies": {
		"tabProcess": "Exportação",
		"tabDownload": "Ficheiro IES-DA",
		"generation": "Geração do ficheiro",
		"fileNotProcessed": "Lamentamos, mas por alguma razão o ficheiro não foi gerado.",
		"jobTimeoutModalTitle": "Tarefa",
		"jobTimeoutModalMessage": "A tarefa excedeu o tempo limite. Tem que iniciar o processo novamente.",
		"contabilidade": "Contabilidade",
		"faturacao": "Faturação",
		"processar": "Processar",
		"processingMessage": "A processar, por favor aguarde um momento.",
		"erros": "Erros",
		"fileReady": "O ficheiro IES-DA está pronto. Clique no botão para descarregar.",
		"promptReprocessTitle": "Já existe ficheiro IES-DA gerado anteriormente",
		"promptReprocessMessage": "Se continuar, o sistema irá gerar o ficheiro novamente. Tem a certeza que pretende continuar?",
		"warningMessage": "Atenção: Após iniciar o processo, tem 60 minutos para descarregar o ficheiro gerado.",
		"promptCancelProcessMessage": "Tem a certeza que pretende cancelar o processo?",
		"dataCriacao": "Data de criação",
		"tamanho": "Tamanho",
		"utilizador": "Utilizador",
		"documentos": "Documentos",
		"buttons": {
			"reiniciar": "Reiniciar",
			"download": "Descarregar",
			"yes": "Sim",
			"no": "Não",
			"fechar": "Fechar"
		},
		"erroDownload": "Ocorreu um erro ao efetuar o download do ficheiro!",
		"exportInProcessModalMessageByOtherUser": "Não pode iniciar o processo de exportação nesta empresa porque já se encontra iniciado pelo outro utilizador",
		"enviarDeclaracao": "Enviar declaração",
		"fields": {
			"anexo": "Anexo",
			"campo": "Campo",
			"descricao": "Descrição",
			"mensagem": "Mensagem"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmação",
		"leavePromptMessage": "Ao clicar em SIM irá sair e descartar todos os dados do processamento. Tem a certeza que pretende sair?"
	},
	"impctrirs": {
		"fields": {
			"deCodEmp": "Cód. empregado de"
		}
	},
	"impde119": {
		"filters": {
			"dedata": "Data de",
			"decodemp": "Empregado de",
			"dencontribuinte": "Nº contribuinte de",
			"filtrarPor": "Filtrar por",
			"empregado": "Colaboradores",
			"outros": "Prestadores serviços",
			"ambos": "Ambos"
		}
	},
	"impdecpe": {
		"doPeriodo": "Período de",
		"doNCampo": "Nº campo de",
		"labelCriterios": "Critérios de consulta"
	},
	"impdoc": {
		"title": "Impressão de documentos comerciais",
		"modalTitle": "Documento {{nDoc}} - {{clifo}}",
		"label": {
			"emailcliente": "E-mail",
			"enviar": "Enviar",
			"documento": "Documento",
			"nvias": "Número de vias",
			"segundaVia": "Segunda via",
			"rptName": "Relatório"
		},
		"email": {
			"emailEnviado": "E-mail enviado para {{email}}",
			"emailErro": "Erro a enviar e-mail para {{email}}. \n\n {{erro}}"
		},
		"placeholder": {
			"email": "\"mail@exemplo.com\" ou \"mail@exemplo.com; mail2@exemplo.com\""
		},
		"faturaDigital": {
			"seraEmitidaPor": "Fatura eletrónica será emitida por {{certificado}}",
			"emitida": "Documento com fatura eletrónica emitida",
			"clienteSemFatDigital": "Cliente sem fatura eletrónica",
			"tipoDocSaftNaoConfigurada": "Tipo de documento SAF-T (FT,FR,ND,NC) não configurado para emitir fatura eletrónica",
			"semConfiguracao": "Sem fatura eletrónica configurada",
			"hintSemConfiguracao": "Produto sujeito a licenciamento, informe-se em www.centralgestcloud.com"
		},
		"btn": {
			"faturacaoPublica": "Faturação pública"
		},
		"tooltip": {
			"avisoEnviar": "Só pode enviar documentos finalizados"
		}
	},
	"impencar": {
		"fields": {
			"dataDe": "Data de",
			"codEmpDe": "Cód. empregado de",
			"codEncargoDe": "Cód. encargo de"
		},
		"btn": {
			"previsualizar": "Pré-visualizar"
		},
		"infos": {
			"tooltipExcepcoesProcessamento": "Exceções processamento"
		}
	},
	"impfferpoc": {
		"btn": {
			"previsualizar": "Pré-visualizar"
		},
		"filtropesq": "Pesquisa avançada",
		"fields": {
			"dataProcessamento": "Data de processamento"
		}
	},
	"importadorEfatura": {
		"configPreDefinidosTitle": "Configuração de entidades",
		"showLastDocs": "Mostrar últimos documentos",
		"hideLastDocs": "Esconder últimos documentos",
		"importarEmSerieModalTitle": "A lançar documentos em serie...",
		"importarEmSerieModalMessage": "A lançar documentos em serie. Por favor, aguarde...",
		"definirPredefinidoModalTitle": "Definir predefinidos",
		"cantSelectRowDueInvalidPredefinido": "Não pode selecionar o registo porque não tem pré - definido.",
		"fields": {
			"nifEmitente": "NIF",
			"nomeEmitente": "Nome",
			"numerodocumento": "Nº doc. externo",
			"dataEmissaoDocumento": "Data emissão",
			"actividadeDesc": "Atividade",
			"tipoDocumentoDesc": "Tipo",
			"fornecedor": "Fornecedor",
			"registadoPor": "Registado por",
			"actividadeProfDesc": "Âmbito atividade",
			"valorTotalBaseTributavel": "Base tributável",
			"valorTotalIva": "Valor IVA",
			"valorTotal": "Valor total",
			"preDefinido": "Pré-definido",
			"baseTributavel": "Base tributável",
			"taxa": "Base taxa",
			"impostoSelo": "Imposto selo",
			"total": "Valor total"
		},
		"configs": {
			"showColumnActividade": "Mostrar coluna <Atividade>",
			"showColumnAmbitoActividade": "Mostrar coluna <Âmbito atividade>",
			"showColumnRegistadoPor": "Mostrar coluna <Registado por>"
		},
		"itemShowDetalhesToolbarBtn": "Mostrar detalhes",
		"itemHideDetalhesToolbarBtn": "Esconder detalhes",
		"docSearchTabCaption": "Pesquisa de documentos",
		"criarDoc": "Criar documento",
		"actions": {
			"configSupplier": "Configurar fornecedor",
			"newAccount": "Gerar nova conta corrente",
			"extratosConta": "Extratos de conta"
		},
		"modal": {
			"createNewContaCorrente": {
				"title": "Criar nova conta corrente",
				"confirmModal": {
					"confirmTitle": "Visualizar a ficha do fornecedor",
					"confirmMessage": "Conta corrente de fornecedor <strong>criada com sucesso</strong>.<br/>Deseja visualizar a ficha do fornecedor criado?"
				},
				"body": {
					"radical": "Radical",
					"ncontacriar": "Nº conta a criar",
					"nome": "Nome",
					"contrib": "Nº contrib."
				}
			}
		},
		"tooltip": {
			"criarNovaContaCorrente": "Criar nova conta corrente",
			"extratoCorrente": "Extratos de conta"
		},
		"warningImportarEmSerieContabilidadeDigital": "Optou por lançar documentos em série numa empresa com arquivo digital.<br/>O sistema vai anexar automaticamente os documentos digitais sugeridos, por favor, verifique se estes se encontram bem classificados.<br/>Tem a certeza que pretende continuar?",
		"docDigital": {
			"0": "Sem documentos possíveis",
			"1": "Com um documento possível",
			"2": "Com vários documentos possíveis",
			"naoTem": "Sem documentos possíveis",
			"tem": "Com um documento possível",
			"temSemelhante": "Com vários documentos possíveis"
		},
		"atMessagePrefix": "O portal das finanças (AT) devolveu a seguinte mensagem",
		"error": {
			"dataFinalInferiorInicial": "A data final é inferior à inicial"
		}
	},
	"importadorSaftDirect": {
		"strings": {
			"modalTitleEdit": "Editar",
			"modalSave": "Aplicar",
			"modalCancel": "Cancelar",
			"correspContasContaSaftLabel": "Conta SAFT",
			"correspContasContaCGLabel": "Conta CentralGest",
			"taxasIvaSaftLabel": "Taxas SAFT",
			"taxasIvaCGLabel": "Taxas CentralGest",
			"artigosConfigModalTitle": "Configuração de artigo(s)",
			"artigosNewConfigModalTitle": "Configuração de novo(s) artigo(s)",
			"correspCCAssociarModalTitle": "Associar conta corrente",
			"modalAssociar": "Associar",
			"modalAttribuir": "Atribuir",
			"configArtigosSaftLabel": "Artigos SAFT",
			"configArtigosCGLabel": "Dados para criação de conta de base tributável CentralGest",
			"jobTimeoutModalTitle": "Tarefa",
			"jobTimeoutModalMessage": "A tarefa excedeu o tempo limite. Tem que iniciar a importação novamente.",
			"verContasFormadasPeloArtigo": "Ver contas de base tributável a serem formadas pelo artigo",
			"verGenDoc": "Ver Documento",
			"applyProductConfig": "A aplicar configuração ...",
			"savingProductsConfig": "A aplicar configuração dos artigos...",
			"noDocsPaymentsToImports": "Os documentos que constam do ficheiro SAFT já foram importados anteriormente para a contabilidade",
			"timeoutModalMessage": "Lamentamos, mas terminou o tempo limite para importação do ficheiro de SAF-T PT. Por favor, tente novamente.",
			"porTipoDeDocumento": "Centro de Custo por tipo de documento",
			"porTipoDocumentoReparticao": "Repartição por tipo de documento",
			"porCliente": "Centro de custo por cliente",
			"porClienteReparticao": "Repartição por cliente",
			"porArtigo": "Centro de custo por artigo",
			"porArtigoReparticao": "Repartição por artigo"
		},
		"steps": {
			"common": {
				"semDados": "Sem dados para mostrar!",
				"semValoresImportar": "Tem que selecionar pelo menos um dos valores a importar"
			},
			"welcome": {
				"wizardCaption": "Bem-Vindo",
				"contentText1": "Bem-vindo ao assistente de importador SAF-T PT faturação para contabilidade direto.",
				"contentText2": "Este assistente vai guiá-lo no processo de importação.",
				"contentText3": "Clique em \"Seguinte\" para continuar a importação.",
				"contentText4": "Após iniciar o processo, terá 2 horas para concluir a importação.",
				"uploadLimit": "O seu plano atual permite processar ficheiros até 20 MB. Para aumentar o limite para 50 MB* e aproveitar outras funcionalidades avançadas, considere atualizar para o plano Contabilidade PREMIUM.",
				"uploadLimitInfo": "O processamento de ficheiros superiores a 20 MB apenas é permitido após as 18h00 de cada dia.",
				"uploadLimitPremium": "O seu plano atual permite processar ficheiros até 20 MB. Após as 18h00 poderá processar ficheiros até 50 MB.",
				"uploadLimitPremiumExtended": "O seu plano atual permite processar ficheiros até 50 MB."
			},
			"escolhaTipoImportacao": {
				"wizardCaption": "Tipo importação",
				"text1": "Por favor, escolha o tipo de importação que pretende efetuar",
				"text2": "Se optar pela importação CentralGest Cloud por favor peça ao seu cliente para lhe criar um utilizador na plataforma cloud CentralGest.",
				"tiposImportacao": {
					"title": "Tipo de importação",
					"ficheiroSaft": "Ficheiro SAF-T Faturação",
					"centralGestCloud": "CentralGest Cloud",
					"centralGestPOS": "POS Zone Soft",
					"centralGestPOSWinTouch": "POS Wintouch"
				}
			},
			"upload": {
				"wizardCaption": "Ficheiro SAFT",
				"title": "Por favor escolha o ficheiro que pretende importar",
				"processarFicheiroLabel": "A processar o ficheiro SAF-T...",
				"cancel": "Cancelar"
			},
			"processaFileCloud": {
				"wizardCaption": "CentralGest Cloud",
				"text1": "Por favor, preencha os seguintes dados",
				"periodo": "Período",
				"user": "Utilizador",
				"password": "Password",
				"valoresAImportar": "Valores a importar",
				"importarVendas": "Importar vendas",
				"importarRecibos": "Importar recibos",
				"importarCompras": "Importar compras",
				"importarPagamentos": "Importar pagamentos",
				"processarNaCentralGestCloud": "A processar..."
			},
			"processaFilePOS": {
				"wizardCaption": "POS Zone Soft",
				"text1": "Por favor, preencha os seguintes dados",
				"periodo": "Período",
				"user": "Utilizador",
				"password": "Password",
				"valoresAImportar": "Valores a importar",
				"importarVendas": "Importar vendas",
				"importarRecibos": "Importar recibos",
				"importarCompras": "Importar compras",
				"importarPagamentos": "Importar pagamentos",
				"processarNaCentralGestPOS": "A processar...",
				"contactPOS": "Por favor, contacte o nosso departamento comercial para conhecer esta funcionalidade. ",
				"emailSubject": "Pedido de informação sobre importação de dados POS Zone Soft",
				"emailComercial": "comercial@centralgest.com"
			},
			"processaFilePOSWinTouch": {
				"wizardCaption": "POS Wintouch",
				"text1": "Por favor, preencha os seguintes dados",
				"periodo": "Período",
				"user": "Utilizador",
				"password": "Password",
				"valoresAImportar": "Valores a importar",
				"importarVendas": "Importar vendas",
				"importarRecibos": "Importar recibos",
				"importarCompras": "Importar compras",
				"importarPagamentos": "Importar Pagamentos",
				"processarNaCentralGestPOS": "A processar...",
				"contactPOS": "Por favor, contacte o nosso departamento comercial para conhecer esta funcionalidade. ",
				"emailSubject": "Pedido de informação sobre importação de dados POS Wintouch",
				"emailComercial": "comercial@centralgest.com"
			},
			"viewDocsToImport": {
				"wizardCaption": "Documentos",
				"title": "Documentos e recibos a importar",
				"helpText": "Por favor verifique os documentos e recibos que vão ser importados",
				"valoresNoFicheiroSAFT": "Valores no Ficheiro SAF-T",
				"nTotalFaturas": "Nº de faturas",
				"totalBaseTributavel": "Total base tributável",
				"totalIva": "Total IVA",
				"legendError": "Documentos com erro",
				"legendWarning": "Documentos com diferença nos totais corrigidos automaticamente",
				"validatorErrorMessage": "Existem documentos com erro. Não é possível importar este ficheiro de SAFT",
				"showDocsWithErrorOrWarningOnly": "Mostrar apenas documentos com avisos ou erros",
				"tabDocsTitle": "Documentos",
				"tabPaymentsTitle": "Recibos",
				"existemDocsComErroDesejaContinuar": "Existem documentos com erro. Os documentos com erro não serão importados. Deseja continuar?"
			},
			"viewRecibosToImport": {
				"legendError": "Recibos com erro",
				"legendWarning": "Recibos com diferença nos totais corrigidos automaticamente",
				"showRecibosWithErrorOrWarningOnly": "Mostrar apenas recibos com avisos ou erros",
				"nTotalRecibos": "Nº de recibos",
				"totalCredito": "Total a crédito",
				"totalDebito": "Total a débito"
			},
			"cfgTiposDocs": {
				"wizardCaption": "Tipos de documento",
				"title": "Configuração de tipos de documento",
				"analiticaTitle": "Analítica",
				"usaAnalitica": "Usa analítica",
				"criaDiariosEDescritivos": "Cria e atribui automaticamente diários e descritivos a novos tipos de documento",
				"mascaraLabel": "Máscara analítica",
				"configMascaraAnalitica": "Configuração de analítica"
			},
			"cfgMercados": {
				"wizardCaption": "Mercados",
				"title": "Configuração de mercados",
				"helpText": "Por favor indique o valor que será usado para formar contas correntes e contas de base tributável consoante o mercado.",
				"valorMercadoNacionalLabel": "Caractere mercado nacional para formação de conta",
				"valorOutrosMercadosLabel": "Caractere outros mercados para formação de conta",
				"valorMercadoComunitarioLabel": "Caractere mercado comunitário para formação de conta"
			},
			"contasCorrentes": {
				"wizardCaption": "Contas correntes",
				"title": "Formação de contas correntes",
				"helpText": "Por favor indique como pretende que as contas correntes sejam criadas.",
				"tipoFormacaoContaTitle": "Criação de contas",
				"criterioFormacaoTitle": "Critério para formação da conta",
				"radicalLabel": "Radical",
				"nDigitosLabel": "Nº dígitos",
				"nDigitosHelperText1": "(número de dígitos da sequência)",
				"nDigitosHelperText2": "(incluí dígito mercado + sequência)",
				"nContaDiversos": "Conta",
				"usaDigitosIdentExterna": "Usar número de dígitos em identificação externa"
			},
			"correspContasCorrentes": {
				"wizardCaption": "Correspondência de contas correntes",
				"title": "Correspondência de contas correntes",
				"helpText": "O sistema sugere automaticamente um número de conta com base nas configurações de formação de contas correntes introduzidas anteriormente.\nPode alterar o mercado, o número de conta sugerida pelo sistema e também a retenção a aplicar por conta.",
				"filterLabel": "Filtrar",
				"selectAll": "Selecionar todos",
				"clearAll": "Limpar seleção",
				"associarContaCorrente": "Associar conta corrente",
				"removerAssociarContaCorrente": "Remove associação c. corrente",
				"mostrarContasComErro": "Mostrar contas com erro",
				"mostrarNIFInvalid": "Mostrar NIFs inválidos",
				"pbSaveText": "A guardar correspondência de contas correntes...",
				"pbLoadCorrespTaxas": "A carregar correspondência de taxas de IVA...",
				"atribuirCCusto": "Associar centro de custo",
				"removerCCusto": "Remover associação de centro de custo",
				"atribuirRepCC": "Associar repartição de centro de custo",
				"removerRepCC": "Remover associação repartição de centro de custo",
				"selecionados": "Com selecionados..."
			},
			"correspTaxasIva": {
				"wizardCaption": "Taxas de IVA",
				"title": "Correspondência de taxas de IVA",
				"helpText": "Por favor indique para taxa de IVA do ficheiro SAFT qual a taxa de IVA correspondente no CentralGest."
			},
			"cfgArtigos": {
				"wizardCaption": "Artigos",
				"title": "Configuração de artigos",
				"helpText": "Por favor indique por artigo a conta correspondente no CentralGest.",
				"selectAll": "Selecionar todos",
				"selectNews": "Selecionar novos",
				"clearAll": "Limpar seleção",
				"configArtigosSelected": "Configurar artigos selecionados",
				"configTodosArtigosTipoProduto": "Configurar todos os artigos do tipo produto",
				"configTodosArtigosTipoServicos": "Configurar todos os artigos do tipo serviços",
				"configTodosArtigosTipoOutros": "Configurar todos os artigos do tipo outros",
				"configTodosArtigosTipoImpostosTaxasEncargosParafiscais": "Configurar todos os artigos do tipo impostos, taxas e encargos parafiscais",
				"configTodosArtigosTipoImpostosEspeciaisConsumo": "Configurar todos os artigos do tipo impostos especiais de consumo",
				"toolbarRefresh": "Refrescar",
				"toolbarMostrarApenasComErro": "Monstrar apenas artigos com erro",
				"toolbarPlanoContas": "Plano de contas",
				"filterLabel": "Filtrar"
			},
			"dataDocs": {
				"wizardCaption": "Datas de documento",
				"title": "Datas de documento",
				"dataLancContabTitle": "Data dos lançamentos na contabilidade"
			},
			"genDocsContab": {
				"wizardCaption": "Documentos criados",
				"title": "Documentos contabilísticos criados",
				"legendError": "Documentos com erro",
				"legendWarning": "Documentos com valor de arredondamento aplicado superior a 1€",
				"valoresNoFicheiroSAFT": "Valores ficheiro SAFT",
				"valoresCG": "Valores CentralGest",
				"selectAll": "Selecionar todos",
				"clearAll": "Limpar selecção",
				"viewDoc": "Ver documento",
				"deleteDocSelected": "Eliminar documentos selecionados",
				"toolbarMostrarApenasComErro": "Monstrar apenas documentos com erro",
				"gravarDocsMessage": "A gravar documentos"
			}
		},
		"messages": {
			"temACertezaQuePertendeContinuar": "Tem a certeza que pretende continuar com operação?",
			"obgEscolherTipoImportacao": "Obrigatório selecionar um tipo de importação para continuar",
			"noFiles": "Selecione o ficheiro para processar...",
			"errorProcessFile": "Ocorreu um erro inesperado no processamento do ficheiro SAFT. Por favor, tente novamente...",
			"importInProcessModalTitle": "Importador SAF-T PT",
			"importInProcessModalMessageByOtherUser": "Não pode iniciar o processo de importação nesta empresa porque já se encontra iniciado pelo outro utilizador",
			"importInProcessModalMessageCancelJob": "O processo de importação já se encontra iniciado. Pode cancelar ou carregar o processo.",
			"importInProcessModalLoadProcess": "Carregar Processo",
			"importInProcessModalCancelProcess": "Cancelar Processo",
			"jobCancelled": "O processo de importação foi cancelado com sucesso. Pode iniciar o novo processo.",
			"nContaDestinoDiffNContrib": "Não é possível atribuir a conta \"{{conta}}\" à identificação externa \"{{customerID}}\" porque o contribuinte das contas é diferente. Só pode atribuir contas sugeridas com o mesmo número de contribuinte.",
			"contasCorrentesNoSelection": "Tem que selecionar pelo menos uma linha",
			"datasDocumentosDataLancInvalid": "A data \"{{data}}\" tem de pertencer ao ano \"{{ano}}\" e mês \"{{mes}}\"",
			"configTiposDocsTemSelLancaCaixaOuLancaCC": "Tem de selecionar pelo menos uma opção: Lança caixa ou lança c. corrente.",
			"configTiposDocsTemIndicarContaCaixa": "Tem de indicar uma conta de caixa",
			"SoPodeAgruparDocsTipoFaturaSimple": "Só pode agrupar documentos do tipo Fatura|Fatura simplificada|Fatura recibo|Venda a dinheiro|Talão de venda.",
			"configFormacaoContasNContaDiversosInvalid": "O Cliente / Fornecedor não existe!",
			"configFormacaoContasRadicalInvalid": "Tem de indicar um radical para formação de contas correntes.",
			"configFormacaoContasNDigitosInvalid": "Tem de indicar o número de dígitos para formação de contas correntes.",
			"correspTaxasIvaCodIvaInvalid": "Tem de definir um Cód. de IVA para a taxa de IVA SAFT \"{{taxa}}\"",
			"correspTaxasIvaDigitosIvaEmpty": "Tem de definir os digitos de IVA para a taxa de IVA SAFT \"{{taxa}}\"",
			"finalizedMessageModal": "Operação concluída com sucesso.",
			"finalizedCloseModal": "Fechar",
			"importHaveErrorsOrWarningsModalMessage": "Existem documentos com avisos ou erros no ficheiro SAFT.\n Por favor verifique os documentos e/ou recibos com avisos ou erros.",
			"correspCCHaveInvalidNifsModalMessage": "Existem contas correntes com números de contribuinte inválidos.\n Deseja continuar?",
			"temIndicarMascaraAnalitica": "Tem de indicar uma máscara de analítica.",
			"temIndicarCentroCusto": "Tem de indicar um centro de custo",
			"temIndicarCodRepCC": "Tem de indicar um código de repartição por centro de custo"
		},
		"fields": {
			"jaImportado": "Importado",
			"invoiceNo": "Nº fatura",
			"invoiceTypeStr": "Tipo",
			"totalBase": "Valor base",
			"totalIVA": "Valor IVA",
			"invoice": "Série",
			"nDiario": "Diário",
			"descritivo": "Descritivo",
			"lancaCaixa": "Lança caixa",
			"contaCaixa": "Conta caixa",
			"lancaContaCorrente": "Lança c. corrente",
			"nCCusto": "C. Custo",
			"agrupamentoDocumentos": "Agrupar documentos",
			"tipoRetencao": "Tipo de retenção",
			"loja": "Loja",
			"isNew": "Novo",
			"customerID": "Id. externa",
			"customerTaxID": "Nº contribuinte",
			"name": "Nome",
			"country": "País",
			"mercado": "Mercado",
			"jaExiste": "Existe",
			"contaDestino": "Nº conta sugerida",
			"codRetencao": "Cód. retenção",
			"nomeContaCG": "Nome conta CentralGest",
			"taxCountryRegion": "Região",
			"taxCode": "Tipo",
			"taxPercentage": "Taxa",
			"codIva": "Cód. IVA",
			"taxaIva": "Taxa",
			"motivoTaxa": "Motivo isenção",
			"digitosIva": "Caracteres IVA para formação de conta",
			"productCode": "Artigo",
			"productDescription": "Nome",
			"productType": "Tipo",
			"nConta": "Conta faturas",
			"nContaNC": "Conta notas crédito",
			"acresceDigMercado": "Acresce caracter mercado",
			"acresceDigIVA": "Acresce caracteres IVA",
			"retencao": "Retenção",
			"dataUltimoDoc": "Último doc. do mês",
			"dataLancamento": "Data do lançamento",
			"select": "Seleção",
			"nDocumento": "Nº documento",
			"invoices": "Nº fatura",
			"valorBase": "Valor base",
			"valorIVA": "Valor IVA",
			"valor": "Valor",
			"valorBaseCG": "CG valor base",
			"valorIVACG": "CG valor IVA",
			"valorCG": "CG valor",
			"valorArredBase": "Arred. base",
			"valorArredIVA": "Arred. IVA",
			"paymentRefNo": "Nº de recibo",
			"nomeCliente": "Cliente",
			"valorLinhasDebito": "Valor débito",
			"valorLinhasCredito": "Valor crédito",
			"importa": "Importa",
			"nContaClassificada": "Nº conta classificada",
			"contaFormadaDigitosMercado": "Caracter mercado",
			"contaFormadaDigitosIVA": "Caracteres IVA",
			"nContaFormada": "Nº Conta formada",
			"contaFormadaNome": "Nome",
			"selectNode": "Sel.",
			"settlementAmount": "Desconto",
			"contaDescRecibo": "Conta descontos recibos",
			"anulado": "Anulado",
			"nCodRepCC": "Repartição"
		},
		"configMascaraAnalitica": {
			"modalTitle": "Configuração de analítica",
			"comoPretendeAtribuirAnalitica": "Como pretende atribuir analítica"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmação",
		"leavePromptMessage": "Ao clicar em SIM irá sair e descartar todos os dados do processamento. Tem a certeza que pretende sair?"
	},
	"importxls": {
		"headerinfo": "Esta aplicação permite-lhe importar diferentes informações para o Software CentralGest. Para isso, disponibilizamos vários modelos de formato XLS ou XLSX para cada tipo de importação. Após preencher os seus dados nesse ficheiro, poderá fazer o upload do mesmo, e desta forma, os seus dados serão importados para o sistema.",
		"fields": {
			"descricao": "Importação",
			"fichasClientes": "Importação de fichas de clientes",
			"fichasFornecedores": "Importação de fichas de fornecedores",
			"artigos": "Importação de artigos",
			"contasCorrentesClientes": "Importação de contas correntes de clientes",
			"contasCorrentesFornecedores": "Importação de contas correntes de fornecedores"
		}
	},
	"indicefluxos": {
		"title_detail": "Índice fluxos {{id}}",
		"title_new": "Novo Índice fluxos",
		"title_plural": "Índices Fluxos",
		"pesquisa": "Pesquisar",
		"saved": "Índice fluxos {{id}}, guardado com sucesso",
		"error": "Índice fluxos {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"indice": "Índice",
			"indicePlaceholder": "Índice do fluxo",
			"tipo": "Tipo",
			"tipoPlaceholder": "Tipo do índice fluxo",
			"nome": "Nome",
			"nomePlaceholder": "Nome do índice fluxo"
		}
	},
	"integracaoSalarios": {
		"tabIntegrarPorEmpregadoCaption": "Integrar por empregado",
		"tabIntegrarPorProcessamentoCaption": "Integrar por processamento",
		"tabRemoverIntegracao": "Remover integração",
		"selectAll": "Selecionar todos",
		"unSelectAll": "Limpar seleção",
		"config": "Configurar",
		"labelTodosProcs": "Todos os tipos de processamento",
		"dataDe": "Data processamento de",
		"codEmpDe": "Do empregado",
		"configModalTitle": "Configuração da integração de salários",
		"configModalTipoIntegTabCaption": "Tipo integração",
		"configModalConfigAcertosSegSocTabCaption": "Config. acertos seg. social",
		"configModalTipoIntegRadioGroupLabel": "Resultado da integração na contabilidade",
		"tipoIntegUmDocPorEmpregado": "Um documento por empregado",
		"tipoIntegUmDocPorProcessamento": "Um documento por processamento",
		"diarioLabel": "Diário",
		"descritivoLabel": "Descritivo",
		"codEstadoLabel": "Cód. estado",
		"contasContrapartidaAcertoSSTitle": "Contas de contrapartida de acerto da segurança social",
		"contaGastoAcertoLabel": "Conta gasto de acerto",
		"contaRendimentoAcertoLabel": "Conta rendimento de acerto",
		"processMessage": "A processar... por favor aguarde um momento.",
		"jobTimeoutModalTitle": "Tarefa",
		"jobTimeoutModalMessage": "Pedimos desculpa mas a tarefa excedeu o tempo limite. Tem que efetuar a pesquisa novamente.",
		"integrarPorEmpregadoMainTitle": "Integrar salários por empregado",
		"integrarPorProcessamentoMainTitle": "Integrar salários por processamento",
		"descricaoLabel": "Descrição",
		"simularInteg": "Simular integração",
		"execInteg": "Executar integração",
		"removeInteg": "Remover integração",
		"nDocExtLabel": "Nº doc. externo",
		"canceladoPeloUtiliz": "Operação cancelada pelo utilizador.",
		"processDoneModalTitle": "Integração de salários",
		"processDoneModalMsg": "A integração de salários foi concluída.",
		"simulatorModalTitle": "Simulação integração de salários: {{empresa}}",
		"integModalTitle": "Integração de salários: {{empresa}}",
		"totalDebitoLabel": "Total débito",
		"totalCreditoLabel": "Total crédito",
		"onlyErrorsLabel": "Só com erros",
		"integRemovidaComSucesso": "A integração de salários foi removida com sucesso.",
		"integrar": "Integrar",
		"temSelecionarRegistos": "Tem que selecionar registos.",
		"visualizarErros": "Deseja visualizar os erros?",
		"errosIntegSalariosModalTitle": "Erros da integração de salários",
		"verErrosModal": "Ver erros",
		"verSimuladorModal": "Ver simulador",
		"fields": {
			"selected": "Sel",
			"nProcessamento": "Nº proc.",
			"nomeProcessamento": "Descrição",
			"tipoProcessamento": "Tipo",
			"tipoProcessamentoDescricao": "Tipo desc.",
			"totalVencAbonos": "Total abonos",
			"totalVencDescontos": "Total descontos",
			"totalEncargos": "Total encargos",
			"codEmp": "Cód emp.",
			"nomeEmpregado": "Nome",
			"nConta": "Nº conta",
			"periodo": "Período",
			"nDiario": "Nº diário",
			"nDescritivo": "Nº descritivo",
			"nDocInterno": "Nº doc. interno",
			"nDocExterno": "Nº doc. externo",
			"descricao": "Descrição",
			"dataDoc": "Data doc.",
			"dataValor": "Data valor",
			"dataVencimento": "Data venc.",
			"valor": "Valor",
			"nContaCredito": "Nº conta créd.",
			"nContaDebito": "Nº conta deb.",
			"nomeConta": "Nome conta",
			"integId": "Integ. ID",
			"cambio": "Cambio",
			"codMoeda": "Cód. moeda",
			"nCCusto": "Nº C. Custo",
			"nContrib": "NIF",
			"nRefProcesso": "Nº ref. proc.",
			"contaOrigem": "Conta origem",
			"mascaraOrigem": "Máscara origem",
			"valorME": "Valor ME",
			"tipoVencimentosEncargos": "Tipo",
			"nCodAbdesc": "Abono / Desconto"
		}
	},
	"integracaoSalariosMulti": {
		"promptViewResultsTitle": "Integração de salários (Multi-empresa)",
		"promptViewResultsMessage": "A integração de salários terminou. Deseja visualizar resultados?",
		"selMesIntegrarTitle": "Selecione o mês",
		"viewResultBtn": "Visualizar",
		"initNewProc": "Inicio",
		"jobTimeoutModalTitle": "Tarefa",
		"jobTimeoutModalMessage": "A tarefa excedeu o tempo limite. Tem que iniciar a integração novamente.",
		"comecar": "Começar",
		"temSeleccionarUmaEmpresa": "Tem que selecionar pelo menos uma empresa.",
		"tabIntroCaption": "Introdução",
		"tabIntroTitle": "Bem-vindo ao assistente para efetuar Integração de salários multi-empresa.",
		"tabIntroSubTitle": "Este assistente vai guiá-lo no processo de integrar os salários de forma automática a todas as empresas que estejam selecionadas e com as configurações definidas de forma correta.",
		"tabIntroSubTitle2": "No fim é lhe mostrado os documentos que foram lançados na Contabilidade.",
		"tabIntroSubTitle3": "Clique em \"Seguinte\" para continuar.",
		"tabEmpresasCaption": "Empresas",
		"tabProcCaption": "Processamento",
		"tabResultsCaption": "Relatório",
		"configLabel": "Configuração",
		"fields": {
			"selected": "Sel.,",
			"nEmpresa": "Empresa",
			"nomeEmpresa": "Nome empresa",
			"codEmpresa": "Nº empresa",
			"nDocInterno": "Nº doc. interno",
			"descricaoErro": "Relatório"
		}
	},
	"intfac": {
		"title": {
			"vendas": "Vendas",
			"compras": "Compras",
			"selectAll": "Selecionar todos",
			"docnaointcontab": "Documentos não integrados na contabilidade"
		},
		"btn": {
			"encerrar": " Encerrar",
			"pesquisar": " Pesquisar ",
			"editDoc": "Editar documento de contabilidade",
			"simular": "Simular"
		},
		"tooltip": {
			"doccomerro": "Documento com erro."
		},
		"table": {
			"selected": "Sel.",
			"nDoc": "Documento",
			"dataDoc": "Data do documento",
			"dataVenci": "Data do vencimento",
			"estado": "Estado",
			"nConta": "Conta",
			"nDocExt": "Nº doc. externo",
			"nDocumentoFacturacao": "Nº documento faturação",
			"periodo": "Período",
			"nDiario": "Diário",
			"nDocInterno": "Nº documento",
			"nContaDebito": "Débito",
			"nContaCredito": "Crédito",
			"valor": "Valor",
			"nomeConta": "Nome",
			"codMoeda": "Cód. moeda",
			"valorME": "Valor ME",
			"valorTaxa": "Valor taxa",
			"valorTaxaME": "Valor taxa ME",
			"codIva": "Cód. IVA",
			"valorRetencao": "Valor retenção",
			"nContrib": "Nº contribuinte",
			"dataDocExterno": "Data doc. externo",
			"codIntegra": "Cód. integração",
			"descricao": "Descrição cod. integração"
		},
		"tooltips": {
			"cantBtnPesquisar": "Selecione pelo menos um tipo de documento para pesquisar",
			"cantBtnEncerrar": "Selecione pelo menos um documento para encerrar"
		},
		"messages": {
			"successfullySaved": "Encerrado com sucesso.",
			"docscomerro": "Documentos selecionados contêm erro.",
			"semgruposelecionado": "Selecione os tipos de documento.",
			"dataNotFound": "Os tipos selecionados não contêm documentos para encerrar.",
			"simErro": "Erro a processar a simulação."
		},
		"errors": {
			"integradoMasComErro": "Integrado mas com erro."
		},
		"modal": {
			"title": "Resultados da simulação",
			"legend": {
				"legendErro": "Com erro"
			}
		}
	},
	"invec": {
		"pesquisa": "Pesquisar",
		"messages": {
			"reprocessaSuccess": "Inventário reprocessado com sucesso!",
			"errorName": "O nome do inventário tem que ser preenchido.",
			"saveSuccess": "Inventário guardado com sucesso!",
			"deleteSuccess": "Inventário eliminado com sucesso!",
			"revalorizaSuccess": "Inventário revalorizado com sucesso!",
			"deletePrompt": "Tem a certeza que pretende eliminar o inventário?",
			"sistemaNaoDevolveuFicheiro": "O sistema não devolveu o ficheiro.",
			"dataRequired": "A data é obrigatória"
		},
		"fields": {
			"nome": "Nome",
			"descricao": "Descrição",
			"deNArmazem": "Armazém de",
			"deTipoArtigo": "Tipo artigo de",
			"deGrandeFamilia": "Grande família de",
			"deFamilia": "Família de",
			"deSubFamilia": "Sub. família de",
			"deClasse": "Classe de",
			"deCategoria": "Categoria de",
			"deValorimetria": "Valorimetria de",
			"deNArtigo": "Artigo de",
			"stockNulo": "Stock nulo",
			"desativados": "Desativados",
			"porLote": "Por lote",
			"dataRef": "Data referência"
		},
		"valorizar": {
			"modal": {
				"title": "Inventários - Valorização",
				"valorizacao": "Valorização"
			}
		},
		"btn": {
			"carregarDados": "Carregar dados",
			"save": "Guardar",
			"mostrar": "Mostrar",
			"exportAT": "Exportar/Comunicar inventário AT",
			"exportATHint": "Exportar ficheiro inv. stocks AT",
			"retroceder": "Retroceder",
			"pesquisarArtigos": "Pesquisar artigos",
			"criar": "Criar inventário",
			"ficheiroCSV": "Ficheiro CSV",
			"ficheiroXML": "Ficheiro XML"
		},
		"newModalTitle": "Gravar inventário",
		"pesquisarArtigosTooltip": "Pesquisar artigos",
		"criarTooltip": "Criar inventário com artigos da tabela",
		"options": {
			"showTipoArtigo": "Mostrar tipo artigo",
			"showGrandeFamilia": "Mostrar grande família",
			"showSubFamilia": "Mostrar sub. família",
			"showClasse": "Mostrar classe",
			"showCategoria": "Mostrar categoria",
			"showValorimetria": "Mostrar valorimetria"
		}
	},
	"inven": {
		"fields": {
			"nArtigo": "Cód. artigo",
			"nomeArtigo": "Nome artigo",
			"nomeArmazem": "Nome armazém",
			"nArmazem": "Armazém",
			"lote": "Lote",
			"qtd": "Quantidade",
			"precoValor": "Preço unitário",
			"valor": "Valor",
			"nLoteEspecifico": "Lote específico"
		},
		"filename": "Inventario_de_Stocks",
		"tooltips": {
			"fichaArtigo": "Ficha de artigo",
			"extratoArtigo": "Extrato de artigo"
		}
	},
	"inventarios": {
		"title": "Inventários",
		"print": {
			"modalTitle": "Imprimir inventário"
		}
	},
	"inversaoSujPassivo": {
		"pesquisa": "Tipos inversão sujeito passivo",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"notApplicable": "N/A",
			"withInversion": "Com inversão",
			"noInversion": "Sem inversão"
		}
	},
	"invoiceType": {
		"fields": {
			"None": "Desconhecido",
			"Fatura": "Fatura",
			"FaturaSimplificada": "Fatura simplificada",
			"FaturaRecibo": "Fatura recibo",
			"NotaDebito": "Nota de débito",
			"NotaCredito": "Nota de crédito",
			"VendaDinheiro": "Venda a dinheiro",
			"TalaoVenda": "Talão de venda",
			"TalaoDevolucao": "Talão de devolução",
			"AlienacaoAtivos": "Alienação de ativos",
			"DevolucaoAtivos": "Devolução de ativos",
			"Premio": "Prémio ou recibo de prémio",
			"Estorno": "Estorno ou recibo de estorno",
			"ImputacaoCoSeguradoras": "Imputação a co-seguradoras",
			"ImputacaoCoSeguradoraLider": "Imputação a co-seguradora líder",
			"ResseguroAceite": "Resseguro aceite",
			"Recibo": "Recibo",
			"NotaLancamento": "Nota de lançamento",
			"ExtratoBancario": "Extrato bancário",
			"PagamentoAoEstado": "Pagamento ao estado",
			"Levantamento": "Levantamento",
			"Pagamento": "Pagamento",
			"Deposito": "Depósito",
			"Cheque": "Cheque",
			"Transferencia": "Transferência"
		}
	},
	"irsdt": {
		"title_detail": "Data de Entrada em Vigor da Tabela IRS",
		"title_new": "Nova Data de Entrada em Vigor da Tabela IRS",
		"title_plural": "Datas de Entrada em Vigor da Tabela IRS",
		"pesquisa": "Pesquisar",
		"saved": "Data de Entrada em Vigor da Tabela IRS, guardada com sucesso.",
		"error": "Data de Entrada em Vigor da Tabela IRS, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Data de Entrada em Vigor da Tabela IRS, eliminada com sucesso.",
		"fields": {
			"tabIRSDT": "Data taxa IRS"
		}
	},
	"ivamotivostaxa": {
		"title_detail": "Motivo de Isenção de IVA {{id}}",
		"title_new": "Novo Motivo de Isenção de IVA",
		"title_plural": "Motivos de Isenção de IVA",
		"pesquisa": "Pesquisar",
		"saved": "Motivo de Isenção de IVA {{id}}, guardado com sucesso.",
		"error": "Motivo de Isenção de IVA {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Motivo de Isenção de IVA {{id}}, apagado com sucesso",
		"fields": {
			"ivaMotivosTaxaId": "Código",
			"ivaMotivosTaxaIdPlaceholder": "Código de isenção de IVA",
			"ivaMotivosTaxaCod": "Código motivo",
			"ivaMotivosTaxaCodPlaceholder": "Código motivo de isenção de IVA",
			"descricao": "Descrição",
			"descricaoPlaceholder": "Descrição",
			"normaAplicavel": "Norma aplicável",
			"normaAplicavelPlaceholder": "Norma aplicável"
		},
		"novaTaxaIVA": "Novo motivo de isenção de IVA"
	},
	"ivas": {
		"title_detail": "Código de IVA {{id}}",
		"title_new": "Novo Código de IVA",
		"title_plural": "Códigos de IVA",
		"pesquisa": "Pesquisar",
		"saved": "Código de IVA {{id}}, guardado com sucesso.",
		"error": "Código de IVA {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Código de IVA {{id}}, apagado com sucesso",
		"fields": {
			"codIva": "Código",
			"codIvaPlaceholder": "Código IVA",
			"nome": "Nome",
			"nomePlaceholder": "Nome código IVA",
			"taxaActual": "Taxa",
			"taxaActualPlaceholder": "Taxa",
			"dataTaxaActual": "Data ref.",
			"percDedGasoleo": "Perc. ded. gasóleo",
			"percDedGasoleoPlaceholder": "Percentagem dedutível gasóleo",
			"percDedProRata": "Perc. ded. pro-rata",
			"percDedProRataPlaceholder": "Percentagem dedutível pro-rata",
			"codAlternInfac": "Cód. altern. integração",
			"codAlternInfacPlaceholder": "Cód. altern. integração",
			"tipoTaxa": "Tipo de taxa",
			"areaRegional": "Área regional",
			"tipoDeducao": "Tipo de dedução",
			"ivaMotivosTaxaId": "Motivo de isenção"
		},
		"novaTaxaIVA": "Nova taxa IVA",
		"tooltip": {
			"codAlternInfacTooltip": "Completa a integração da base tributável.\nEx.: Nac. isenta -> 110 / Nac. c/ IVA à taxa normal -> 113 / Comunitária isenta -> 210."
		}
	},
	"justificacaoAusencia": {
		"data": {
			"tjaNA": "N/A",
			"tjaSim": "Sim",
			"tjaNao": "Não"
		}
	},
	"listagemdiarios": {
		"btn": {
			"previsualizar": "Pré-visualizar"
		},
		"header": {
			"fromAccount": "Conta",
			"doPeriodo": "Período de",
			"doDiario": "Diário de",
			"docInterDo": "Doc. interno de",
			"datadocde": "Data doc. de"
		},
		"error": {
			"listagemNaoSelec": "Precisa de selecionar um item da listagem",
			"contaFinalInferiorInicial": "A conta final é inferior à inicial",
			"periodoFinalInferiorInicial": "O período final é inferior ao inicial",
			"diarioFinalInferiorInicial": "O diário final é inferior ao inicial",
			"docFinalInferiorInicial": "O doc. interno final é inferior ao inicial",
			"dataFinalInferiorInicial": "A data doc. final é inferior à inicial"
		}
	},
	"listagemMenus": {
		"table": {
			"almoco": "Almoço",
			"jantar": "Jantar",
			"sopa": "Sopa",
			"ligeira": "Ligeira",
			"sobremesa": "Sobremesa"
		},
		"form": {
			"local": "Local",
			"de": "De",
			"btns": {
				"pesquisar": "Pesquisar",
				"imprimir": "Imprimir"
			}
		},
		"title": {
			"ementaSemanal": "Ementa semanal",
			"local": "Local"
		}
	},
	"loadmodule": {
		"title": "A carregar..."
	},
	"localObtencaoRendimentos": {
		"data": {
			"continente": "Continente",
			"regiaoAcores": "Região Autónoma dos Açores",
			"regiaoMadeira": "Região Autónoma da Madeira",
			"estrangeiro": "Estrangeiro"
		}
	},
	"locale": {
		"descriptive": {
			"en": "English",
			"es": "Español",
			"fr": "Français",
			"pt-PT": "Português"
		}
	},
	"local": {
		"title_detail": "Localidade {{id}}",
		"title_new": "Nova Localidade",
		"title_plural": "Localidades",
		"pesquisa": "Pesquisar",
		"saved": "Localidade {{id}}, guardada com sucesso.",
		"error": "Localidade {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Localidade {{id}}, apagada com sucesso",
		"fields": {
			"nLocalidade": "Cód.",
			"nLocalidadePlaceholder": "Código da localidade",
			"designaBreve": "Designação breve",
			"designaBrevePlaceholder": "Designação breve",
			"designaCompl": "Designação completa",
			"designaComplPlaceholder": "Designação completa"
		}
	},
	"lockedcontract": {
		"title": "O seu contrato está bloqueado",
		"titleTemporary": "O seu contrato está temporariamente bloqueado",
		"changeCompany": "Mudar empresa",
		"reasons": {
			"0": "N/D",
			"1": "O seu contrato está em manutenção, este processo poderá demorar algum tempo, por favor aguarde."
		}
	},
	"loginWsSS": {
		"title": "Autenticação na segurança social - SSD",
		"success": "Dados de login guardados com sucesso",
		"dadosEmpresa": "Dados da empresa",
		"dadosMandatario": "Dados do mandatário",
		"fields": {
			"niss": "NISS",
			"password": "Senha"
		},
		"messages": {
			"credentialsSaved": "Os credenciais foram guardados com sucesso.",
			"errorNISSEmpresaEmpty": "O NISS da empresa vazio.",
			"errorPasswordEmpresaEmpty": "A password da empresa vazia.",
			"errorPasswordMandatarioEmpty": "A password do mandatário vazia."
		},
		"nissAutenticacaoDiferenteFichaEmpresa": "O NISS da autenticação \"{{nissAuth}}\" é diferente da ficha da empresa \"{{nissEmpresa}}\". O sistema usou NISS da ficha da empresa, por favor atualize a senha."
	},
	"lotes": {
		"title_detail": "Lote {{id}}",
		"title_new": "Novo Lote",
		"title_plural": "Lotes",
		"pesquisa": "Pesquisar",
		"saved": "Lote {{id}}, guardado com sucesso",
		"error": "Lote {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Lote {{id}}, apagado com sucesso",
		"fields": {
			"nLote": "Cód. lote",
			"nArtigo": "Cód. artigo",
			"nLoteEspecifico": "Lote específico",
			"loteFornecedor": "Lote fornecedor",
			"nConta": "Fornecedor",
			"dataFabrico": "Data fabrico",
			"dataValidade": "Data validade",
			"qtdTotal": "Qtd. total",
			"terminado": "Terminado",
			"nomeArtigo": "Nome artigo",
			"nomeConta": "Nome fornecedor"
		},
		"errors": {
			"artigoDaLinhaNaoTemLote": "Não existe nenhum lote selecionado para o artigo {{nartigo}}.",
			"artigoNaoTemLote": "O código de artigo {{nArtigo}} não tem lote."
		},
		"btn": {
			"new": "Novo"
		},
		"list": {
			"config": {
				"showSoTerminados": "Ver apenas terminados"
			},
			"actions": {
				"editar": "Editar",
				"terminar": "Terminar",
				"teminado": "Terminado",
				"verSoLotesTerminados": "Ver apenas lotes terminados",
				"verSoLotesAtivos": "Ver apenas lotes ativos"
			}
		},
		"okCancel": {
			"terminar": {
				"title": "Terminar lote",
				"message": "Tem a certeza que deseja terminar o lote {{lote}} do artigo {{nartigo}}?"
			}
		}
	},
	"maintenance": {
		"pageTitle": "Manutenção programada",
		"title": "Estamos a proceder a uma manutenção programada",
		"message": "Desculpe pelo inconveniente.<br/>Voltaremos o mais rápido possível."
	},
	"mapaexploracao": {
		"title": "Mapa exploração",
		"table": {
			"header": {
				"meses": "Meses"
			}
		},
		"fields": {
			"periodoDe": "Período de",
			"conta": "Conta",
			"descricao": "Descrição",
			"saldo01": "Janeiro",
			"saldo02": "Fevereiro",
			"saldo03": "Março",
			"saldo04": "Abril",
			"saldo05": "Maio",
			"saldo06": "Junho",
			"saldo07": "Julho",
			"saldo08": "Agosto",
			"saldo09": "Setembro",
			"saldo10": "Outubro",
			"saldo11": "Novembro",
			"saldo12": "Dezembro"
		},
		"summary": {
			"rendimentos": "Rendimentos",
			"compras": "Compras e gastos",
			"totalrendimentos": "Total de rendimentos",
			"totalcompras": "Total de compras e gastos",
			"totalexploracao": "Resultado de exploração"
		},
		"btn": {
			"dropdown": {
				"balancete": "Balancete"
			}
		},
		"modal": {
			"config": {
				"title": "Configuração do mapa de exploração",
				"label": {
					"tipoarredondamento": "Tipo de arredondamento"
				},
				"tipoarredondamento": {
					"semArredondamento": "Sem arredondamento",
					"arredondamentoAoEuro": "Arredonda ao euro"
				}
			}
		}
	},
	"mapapagam": {
		"texts": {
			"comSelecionados": "Selecionados...",
			"carregarPagamentos": "Carregar",
			"empresas": "Empresas",
			"pagamentos": "Pagamentos",
			"pagamentosManuais": "Pagamentos manuais",
			"adiconarPagamentoManual": "Criar pagamento manual",
			"criarPagamento": "Criar pagamento",
			"editarPagamento": "Editar pagamento",
			"periocidade": "Periocidade",
			"dadosPagamento": "Dados de pagamento",
			"verDocumento": "Ver documento(s)",
			"enviarPagamento": "Enviar",
			"reEnviarPagamento": "Re-enviar pagamento(s)",
			"marcarComoPago": "Marcar como pago",
			"visualizacaoDocumento": "Visualização do documento",
			"documento": "Documento",
			"selecionarTodosPagamentos": "Selecionar todos pagamentos",
			"selecionarEstePagEmTodasEmpresa": "Selecionar este pagamento em todas empresas",
			"enviarPagamentos": "Enviar pagamentos",
			"continuar": "Continuar",
			"configurarCredentials": "Configurar credenciais",
			"resumoEnvioErros": "Resumo de envio - Erros",
			"existemEmpresasComErros": "Existem empresas com erros de envio.",
			"listaDePagamentosAEnviar": "Lista de pagamentos a enviar",
			"viewDocumentoUnico": "Pre-visualizar o documento único com pagamentos selecionados",
			"verDetalhesPagamento": "Ver detalhes",
			"apagarPagamento": "Apagar pagamento",
			"detalhesPagamento": "Detalhes do pagamento",
			"semDetalhesPagamento": "Sem detalhes do pagamento.",
			"naoPossivelVisualizarDocNaoEmitido": "Não é possível visualizar o documento. Estado: {{estado}}",
			"podeEmitirDocNosDocsFiscais": "Pode emitir o documento nos documentos fiscais ou no portal das finanças (AT)",
			"selecionarEmpresasSemErro": "Selecionar empresas sem erros",
			"portalAT": "Portal das finanças (AT)",
			"portalFCT": "Fundo de compensação",
			"portalSSD": "Segurança social direta",
			"enviarSelecionados": "Enviar selecionados",
			"enviarApenas": "Selecionar e Enviar apenas {{nome}}",
			"mostrarApenasSelecionadas": "Mostrar apenas selecionadas",
			"mostrarComFalhaLogin": "Mostrar com falha de login",
			"pagEstado": "Pag. ao estado",
			"configuracao": "Configuração",
			"pagamentosDisponiveis": "Pagamentos disponíveis",
			"pagamentosSelecionados": "Pagamentos selecionados",
			"disponivelBrevemente": "Esta funcionalidade irá ser disponibilizada brevemente.",
			"regimeIva": {
				"normal": "Normal",
				"isencao": "Isenção",
				"pequenosRetalhistas": "Pequenos retalhistas",
				"regimeNormalBens2Mao": "Regime normal + bens 2ª mão",
				"regimeNormalRevendaCombustíveis": "Regime normal + revenda combustíveis",
				"regimeNormalIvaCaixa": "Regime normal + IVA de caixa"
			},
			"periodoIva": {
				"mensal": "Mensal",
				"trimestral": "Trimestral"
			},
			"limparFiltros": "Limpar filtros",
			"aplicar": "Aplicar",
			"alertMessage": "Não recomendamos a utilização do mapa de pagamentos entre o 12H e 14H, pois estes horários servem de manutenção para algumas entidades públicas",
			"criarPagamentoManual": "Criar pagamento manual",
			"editarPagamentoManual": "Editar pagamento manual",
			"pagamentoManual": "Pagamento manual",
			"todasEmpresas": "Todas empresas",
			"todosMeses": "Todos meses",
			"durante": "Durante",
			"anos": "ano(s)",
			"selecioneOQuePertendeApagar": "Selecione o que pretende apagar",
			"apagarApenasMesCorrente": "Apagar apenas do mês corrente",
			"apagarTodosMesesNoAnoCurrente": "Apagar todos os meses do ano corrente",
			"apagarPorCompletoEmTodasEmpresas": "Apagar por completo e em todas as empresas",
			"confirmar": "Confirmar",
			"documentosFiscais": "Documentos fiscais",
			"anexo": "Anexo",
			"removerAnexo": "Remover anexo",
			"documentoUnicoPdfName": "{{ano}}{{mes}}_DU_{{nEmpresa}}_{{now}}.pdf",
			"pagamentoPdfName": "{{ano}}{{mes}}_{{nEmpresa}}_{{nome}}_{{now}}.pdf",
			"aPreparar": "A preparar...por favor aguarde um momento.",
			"recarregar": "Recarregar"
		},
		"fields": {
			"nEmpresa": "Nº empresa",
			"nomeEmpresa": "Nome",
			"nomePagamento": "Nome pagamento",
			"descricaoPagamento": "Descrição pagamento",
			"refPagDescricao": "Ref. pagamento (Descrição)",
			"dataLimitePag": "Data limite de pagamento",
			"montante": "Montante",
			"email": "Email",
			"pago": "Pago",
			"status": "Estado",
			"nif": "NIF",
			"regimeIva": "Regime IVA",
			"periodoIva": "Período IVA",
			"responsavel": "Responsável",
			"iva": "IVA",
			"ircMod22": "IRC MOD 22",
			"imi": "IMI",
			"iuc": "IUC",
			"dmr": "DMR AT",
			"retencaoNaFonte": "Ret. na fonte",
			"pagAntIRC": "Pag. por conta",
			"dmrSS": "DMR SS"
		},
		"messages": {
			"temSeleccionarEmpresa": "Tem que selecionar pelo menos uma empresa.",
			"temQuePreencherNomePagamento": "Tem que preencher o nome do pagamento",
			"temQueSelecionarPeloMenosUmMes": "Tem que selecionar pelo menos um mes",
			"marcarComoPagoPrompt": "Tem a certeza que pretende marcar este pagamento como pago?",
			"empresaNaoTemPagamentosSelecionados": "A empresa \"{{empresa}}\" não tem pagamentos selecionados",
			"empresaNaoTemEmailPreenchido": "A empresa \"{{empresa}}\" não tem email preenchido",
			"empresaNaoTemPagamentosCarregados": "A empresa \"{{empresa}}\" não tem pagamentos carregados. Seleciona a empresa e carrega no botão \"Carregar Pagamentos\"",
			"processMessage": "A processar... por favor aguarde um momento.",
			"empresaNaoTemEmailInvalido": "A empresa \"{{empresa}}\" tem o email inválido",
			"existemPagamentoEnviados": "Existem pagamentos selecionados que já foram enviados. Tem a certeza que pretende continuar com envio?",
			"documentoUnicoEnviadoSucesso": "O documento único com pagamentos foi enviado com sucesso",
			"carregarEmpresas": "A carregar empresas...",
			"pagManGuardadoSucesso": "O pagamento manual foi guardado com sucesso"
		},
		"tooltips": {
			"emailLido": "O e-mail com este pagamento foi lido em {{lidoDataHora}}",
			"emailEnviado": "O e-mail com este pagamento foi enviado em {{enviadoDataHora}}",
			"emailNaoEnviado": "O e-mail com este pagamento não foi enviado.",
			"pago": "O pagamento já foi pago",
			"obterDadosPortaisATeSSD": "Obter dados nos portais da AT e SSD",
			"enviarPagamentosSelParaEmpresasSel": "Enviar os pagamentos selecionados para as empresas selecionadas",
			"emailEnviadoELido": "O e-mail com este pagamento foi enviado em {{enviadoDataHora}} e lido em {{lidoDataHora}}",
			"enviarPagamentosSelecionadosGrelha": "Enviar pagamentos selecionados na grelha",
			"naoEPossivelObterEstadoPagamento": "Não é possível obter o estado do pagamento."
		},
		"btn": {
			"resend": "Re-enviar",
			"resendSkipErrors": "Re-enviar (Ignorar Erros)"
		}
	},
	"mapasamortizacao": {
		"title_detail": "Mapa de Amortização {{id}}",
		"title_new": "Novo Mapa de Amortização",
		"title_plural": "Mapas de Amortização",
		"pesquisa": "Pesquisar",
		"saved": "Mapa de Amortização {{id}}, guardado com sucesso.",
		"error": "Mapa de Amortização {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Mapa de Amortização {{id}}, apagado com sucesso",
		"fields": {
			"nImmap": "Cód.",
			"nImmapPlaceholder": "Código do mapa de amortização",
			"nome": "Nome",
			"nomePlaceholder": "Nome do mapa de amortização"
		}
	},
	"mascarasanalitica": {
		"title_detail": "Máscara da analítica {{id}}",
		"title_new": "Nova Máscara da analítica",
		"title_plural": "Máscaras da analítica",
		"pesquisa": "Pesquisar",
		"saved": "Máscara da analítica {{id}}, guardada com sucesso.",
		"error": "Máscara da analítica {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"errorAdd": "Já existe este tipo de carácter numa posição anterior da máscara",
		"deleted": "Máscara da analítica {{id}}, apagada com sucesso.",
		"fields": {
			"manalID": "Cód.",
			"descricao": "Descrição",
			"mascara": "Máscara",
			"tipo": "Tipo máscara",
			"nCaracteresGeral": "Nº caracteres",
			"isDefault": "Máscara por defeito",
			"subTotaisDaGeral": "Cria sub totais baseado na estrutura de acumuladores da geral",
			"indicesSubTotais": "Índices de sub totais",
			"contaContrapartida": "Conta contrapartida"
		},
		"placeholders": {
			"descricao": "Descrição da máscara de analítica",
			"mascara": "Máscara",
			"tipo": "Tipo de máscara",
			"nCaracteresGeral": "Nº de caracteres da conta de geral",
			"isDefault": "Máscara por defeito",
			"subTotaisDaGeral": "Cria sub totais baseado na estrutura de acumuladores da geral",
			"indicesSubTotais": "Índices de sub totais"
		},
		"tipos": {
			"invoicing": "Faturação",
			"salary": "Salários",
			"fixed": "Imobilizado"
		},
		"tabs": {
			"definition": "Definição da máscara",
			"definitionTitle": "Máscara",
			"definitionNames": "Definição nomes das contas a gerar",
			"definitionNamesTitle": "Disponíveis",
			"nameTitle": "Nome da conta criada",
			"nameItemsTitle": "Campos disponíveis"
		},
		"table": {
			"description": "Descrição",
			"char": "Carácter",
			"subTotal": "Cria sub-total",
			"type": "Tipo",
			"field": "Campo por definição",
			"text": "Texto"
		},
		"enum": {
			"definition": {
				"0": "Constante 0",
				"1": "Constante 1",
				"2": "Constante 2",
				"3": "Constante 3",
				"4": "Constante 4",
				"5": "Constante 5",
				"6": "Constante 6",
				"7": "Constante 7",
				"8": "Constante 8",
				"9": "Constante 9"
			},
			"nomes": {
				"poc": "Nome da conta geral de origem"
			},
			"definitionFaturacao": {
				"ccusto": "Centro de custo",
				"zone": "Zona",
				"department": "Departamento",
				"subDepartment": "Sub departamento",
				"family": "Família",
				"bigFamily": "Grande família",
				"subFamily": "Sub família",
				"accountingCode": "Cód. contabilístico",
				"itemType": "Tipo artigo",
				"class": "Classe",
				"category": "Categoria",
				"seller": "Vendedor",
				"process": "Processo"
			},
			"definitionSalarios": {
				"ccusto": "Centro de custo",
				"accountingCode": "Cód. contabilístico",
				"workerClass": "Cód. classe trabalhador",
				"roleName": "Cód. Função",
				"employeeCCusto": "Centro de custo funcionário",
				"categoryFamily": "Cód. família da categoria",
				"zone": "Zona",
				"department": "Departamento"
			},
			"definitionImobilizado": {
				"ccusto": "Centro de custo",
				"department": "Departamento",
				"bigFamily": "Grande família",
				"itemType": "Tipo artigo",
				"accountingCode": "Cód. contabilístico"
			},
			"nomesFaturacao": {
				"ccusto": "Nome do centro de custo",
				"zone": "Nome da zona",
				"department": "Nome do departamento",
				"subDepartment": "Nome do sub departamento",
				"family": "Nome da família",
				"bigFamily": "Nome da grande família",
				"subFamily": "Nome da sub família",
				"accountingCode": "Nome do cód. contabilístico",
				"itemType": "Nome do tipo artigo",
				"class": "Nome da classe",
				"category": "Nome da categoria",
				"seller": "Nome do vendedor",
				"process": "Nome do processo"
			},
			"nomesSalarios": {
				"ccusto": "Nome do centro de custo",
				"accountingCode": "Nome do cód. contabilístico",
				"workerClass": "Nome da classe trabalhador",
				"roleName": "Nome da função",
				"employeeCCusto": "Nome do centro de custo funcionário",
				"categoryFamily": "Nome família da categoria",
				"zone": "Nome da zona",
				"department": "Nome do departamento"
			},
			"nomesImobilizado": {
				"ccusto": "Nome do centro de custo",
				"department": "Nome do departamento",
				"family": "Nome da família",
				"bigFamily": "Nome da grande família",
				"itemType": "Nome do tipo artigo",
				"accountingCode": "Nome do cód. contabilístico"
			}
		},
		"changeTypePrompt": {
			"title": "Tem a certeza que deseja mudar o tipo de máscara?",
			"message": "Alterar o tipo de máscara vai limpar as alterações efetuadas à mesma. Tem a certeza que pretende continuar?"
		},
		"textPrompt": {
			"title": "Alterar o texto do campo"
		}
	},
	"mbconfigs": {
		"titles": {
			"pagRec": "Pagamentos / Recibos",
			"addtipodoc": "Adicionar tipo de documento"
		},
		"fields": {
			"entidade": "Entidade",
			"accountID": "API ID",
			"apiKey": "API chave",
			"contaTransf": "Conta transferência",
			"contaTransitoriaTransf": "Conta transitória",
			"transferenciaLancaSaldo": "Lançar transferência com valor saldo",
			"contaComissao": "Conta comissão",
			"diario": "Diário",
			"descritivo": "Descritivo",
			"codEstadoDocPago": "Estado doc. pago",
			"enviarrecibo": "Enviar recibo do cliente por email",
			"enviarreciboTooltip": "Valide os emails nas fichas de cliente",
			"enviarrecibotitle": "Notificação de importação de pagamentos",
			"enviarreciboinfo": "O sistema irá importar de forma automática",
			"dataInicioRef": "Criar referências a partir de",
			"geraRefMovimentoAberto": "Pretendo gerar uma segunda referência com o saldo em dívida do cliente",
			"internalDesc": "Tipos documento possíveis de configurar",
			"inativo": "Inativo",
			"estado": "Estado",
			"advancedMode": "Modo avançado",
			"tipoDoc": "Tipo documento",
			"nDiasValidadeReferenciaMB": "Nº dias validade ref."
		},
		"messages": {
			"configsavedsucess": "Configuração das referências multibanco guardadas com sucesso!",
			"fornecedorRefMult": "Fornecedor de ref. multibanco: EasyPay",
			"advancedModeInfo": "Só deve utilizar este modo caso pretenda configurar as contas do plano de contas.",
			"ndocfaempty": "Tem de selecionar um tipo de documento.",
			"ndocfasuccess": "Foi adicionado o tipo de documento com sucesso.",
			"infogeral": "Para que possa usar a plataforma da EasyPay integrada com o Centralgest Cloud, precisa obrigatoriamente de obter um novo CIN da EasyPay, aplica-se tanto a novos clientes, como aos que já tenham uma conta criada na EasyPay.<br>Isto não significa que terá que criar uma conta nova, apenas um novo CIN.",
			"loginempty": "Tem de preencher os campos \"API ID\" e \"API chave\".",
			"functioning": "Assim que a EasyPay notificar o sistema, iremos lançar o respetivo recibo.",
			"dataEasyPay": "Os dados acima são fornecidos pela EasyPay. Consulte as FAQ's disponíveis para obter essa informação e configurar a notificação do pagamento.",
			"selectDocFas": "Selecione os tipos de documento em que pretende configurar as Referências: MB",
			"nDiasValidadeReferenciaMB": "Quando o valor é \"0\" a referência não expira."
		},
		"btn": {
			"acessobackend": "Acesso backoffice",
			"addTiposDoc": "Adicionar mais tipos de documento",
			"criareasypay": "Criar uma conta EasyPay",
			"faq": "FAQ - Ajuda",
			"testConnection": "Testar ligação"
		},
		"tooltips": {
			"contaTransferencia": "Conta definitiva para transferência do montante",
			"contaTransitoria": "Conta temporária, usada após recebimento efetivo",
			"contaComissao": "Conta onde serão depositados as comissões do processamento"
		}
	},
	"meiosPagamento": {
		"title_detail": "Meio de pagamento {{id}}",
		"title_new": "Novo meio de pagamento",
		"title_plural": "Meios de Pagamento",
		"pesquisa": "Pesquisar",
		"saved": "Meio de pagamento {{id}}, guardado com sucesso",
		"error": "Meio de pagamento {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Meio de pagamento {{id}}, apagado com sucesso",
		"fields": {
			"nCaixa": "Número",
			"nCaixaPlaceholder": "Se não for preenchido será gerado automaticamente.",
			"nome": "Nome",
			"tipoMovimento": "Tipo",
			"nConta": "Nº conta",
			"moeda": "Cód. moeda",
			"tipo": "Tipo de meio de pagamento",
			"nib": "IBAN",
			"swift": "SWIFT",
			"porDefeito": "Por defeito"
		},
		"data": {
			"indiferente": "Indiferente",
			"recebimento": "Recebimento",
			"pagamento": "Pagamento",
			"dinheiro": "Dinheiro",
			"multibanco": "Multibanco",
			"cartaoCredito": "Cartão de crédito",
			"cheque": "Cheque",
			"valorAdicional": "Valor adicional",
			"pontos": "Pontos",
			"contraReemsolso": "Contra reembolso",
			"trfBancaria": "Transferência bancária",
			"payPal": "PayPal"
		},
		"btn": {
			"verContas": "Ver contas",
			"buttonOptions": "Opções",
			"esconderContas": "Esconder contas"
		},
		"info": {
			"entregue": "Entregue",
			"troco": "Troco"
		},
		"mensagens": {
			"meioPagSemMoedaEmpresaParaTroco": "Não foi possível encontrar um meio de pagamento de dinheiro na moeda da empresa para colocar o troco",
			"montanteMeioPagNaoIgualTotalDoc": "O montante em meios de pagamento {{montante}} não é igual ao total de documento {{total}}",
			"adicionarMeiosPagCancelado": "Adicionar meios de pagamento cancelado",
			"meioPagamentoAlteradoPorOmissao": "Este meio de pagamento encontra-se definido por defeito para {{tipo}}, ao alterar o tipo de movimento este deixa de ser elegível e outro será selecionado automaticamente. Pretende prosseguir com a alteração?",
			"meioPagamentoAlteradoPorOmissaoTitle": "Confirmar alteração do valor por defeito para o tipo de pagamento",
			"erroTipoMeioPagamentoOmissao": "O meio de pagamento escolhido não tem o mesmo tipo de pagamento da configuração",
			"contaInvalida": "A conta {{nConta}} não é de movimento."
		},
		"table": {
			"actions": {
				"porDefeitoPara": "Por defeito para",
				"alterarPorDefeito": "Alterar meios de pagamento por defeito ",
				"recibos": "Recibos",
				"pagamentos": "Pagamentos",
				"errorDesactivate": "Não pode deixar um tipo de pagamento sem meio de pagamento por defeito"
			}
		}
	},
	"mercados": {
		"pesquisa": "Mercados",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"notApplicable": "N/A",
			"national": "Nacional",
			"cee": "UE",
			"thirdPartyCountries": "Países terceiros"
		}
	},
	"modelo10": {
		"groups": {
			"atencao": {
				"p1": "Quadro 00",
				"p2": "No preenchimento da declaração modelo 10, deve ter-se em atenção alguns aspetos declarativos, destacando-se os seguintes:",
				"p3": {
					"p3-1": "Para declarações do ano de 2019 e seguintes, se tiverem sido ",
					"p3-2": "pagos ou colocados à disposição ",
					"p3-3": "rendimentos respeitantes ",
					"p3-4": "a anos anteriores",
					"p3-5": ", deve-se proceder ao preenchimento do quadro 05 campo 02 de acordo com a ajuda disponibilizada neste quadro/campo, destacando-se a necessidade de indicar o ano a que respeita o rendimento em substituição do número de anos."
				},
				"p4": "As pessoas ou entidades obrigadas à entrega da Declaração Mensal de Remunerações (DMR) não podem declarar os rendimentos do trabalho dependente (categoria A) na declaração Modelo 10.",
				"p5": "As pessoas singulares devedoras de rendimentos do trabalho dependente que não estão obrigadas à entrega da DMR, podem optar pela entrega da declaração Modelo 10, desde que esses rendimentos não tenham sido objeto de qualquer retenção na fonte. Estão nesta situação as pessoas singulares que não se encontrem inscritas para o exercício de uma atividade empresarial ou profissional ou, encontrando-se, os rendimentos da categoria A, aos quais corresponda a taxa 0% nas tabelas de retenção na fonte, não se relacionem exclusivamente com essa atividade. Caso aquelas pessoas singulares tenham entregue DMR, não podem declarar os rendimentos da categoria A na declaração Modelo 10.",
				"p6": {
					"p6-1": "A declaração Modelo 10 destina-se a declarar os rendimentos sujeitos a imposto, isentos e não sujeitos auferidos por sujeitos passivos de IRS ",
					"p6-2": "residentes",
					"p6-3": " no território nacional, bem como as respetivas retenções na fonte. Para além destes destina-se também a declarar rendimentos sujeitos a retenção na fonte de IRC, excluindo os que se encontram dela dispensados."
				},
				"p7": "Os rendimentos prediais devem ser sempre declarados, com os códigos F, ainda que venham a ser tributados como rendimentos da categoria B por serem imputáveis a atividades empresariais ou profissionais, nos termos das alíneas a) do n.º 2 do artigo 3.º do Código do IRS.",
				"p8": {
					"p8-1": "O espaço geográfico a indicar no quadro 5 campo 05 (",
					"p8-2": "Continente, Região Autónoma dos Açores, Região Autónoma da Madeira ou Estrangeiro",
					"p8-3": ") deve, corresponder ao local onde foram obtidos os rendimentos. A definição do espaço geográfico, no que respeita às Regiões Autónomas encontra-se estabelecida no nº 3 do artigo 17º do CIRS. Para melhor esclarecimento, consulte a \"Ajuda no preenchimento\" inserida no campo 05 do quadro 5."
				}
			},
			"financAreaDomi": {
				"title": "Serviço de Finanças da Área do Domicílio",
				"codservfinan": "Código do Serviço de Finanças"
			},
			"nif": {
				"title": "Número de Identificação Fiscal",
				"nomepassivo": "Nome do Sujeito Passivo",
				"nif": "NIF"
			},
			"importanciaretidas": {
				"title": "Importâncias Retidas",
				"quadro4": {
					"campo1": "A - TRABALHO DEPENDENTE",
					"campo2": "B - RENDIMENTOS EMPRESARIAIS E PROFISSIONAIS",
					"campo3": "E - OUTROS RENDIMENTOS DE CAPITAIS",
					"campo4": "EE - SALDOS CREDORES C/C (Artº 12º-A, nº3, alínea a) do Decreto-Lei 41/91, de 22 de Janeiro)",
					"campo5": "F - PREDIAIS",
					"campo6": "G - INCREMENTOS PATRIMONIAIS",
					"campo7": "H - PENSÕES",
					"campo8": "RETENÇÕES DE IRC (Artº 94º do CIRC)",
					"campo9": "SOMA (01 A 08)",
					"campo10": "RETENÇÕES A TAXAS LIBERATÓRIAS",
					"campo11": "COMPENSAÇÕES DE IRS/IRC",
					"campo12": "TOTAL (09 + 10 - 11)",
					"campo13": "RETENÇÃO DA SOBRETAXA (aplicável anos de 2013 a 2017)"
				}
			},
			"reltitrendimentos": {
				"title": "Relação dos Titulares dos Rendimentos",
				"quadro5": {
					"table": {
						"sujpassivo": "Sujeito passivo",
						"rendAnoAnt": "Rendimentos de anos anteriores",
						"rendAno": "Rendimentos do ano",
						"tipoRend": "Tipo de rendimentos",
						"locobtrend": "Local de obtenção do rendimento",
						"retIrsIrc": "Retenções de IRS/IRC",
						"contribObr": "Contribuições obrigatórias",
						"quotSindicais": "Quotizações sindicais",
						"retSobreTaxa": "Retenção da sobretaxa (anos de 2013 a 2017)",
						"valores": "Valores",
						"nrAnos": "Número de anos",
						"semdados": "Sem dados",
						"addlinha": "Adicionar linha"
					},
					"tableTot": {
						"rendAnoAnt": "Rendimentos de Anos Anteriores",
						"rendAno": "Rendimentos do Ano",
						"retIrsIrc": "Retenções de IRS/IRC",
						"contribObr": "Contribuições Obrigatórias",
						"quotSindicais": "Quotizações Sindicais",
						"retSobreTaxa": "Retenção da Sobretaxa (anos de 2013 a 2017)",
						"somacontr": "Soma de Controlo"
					}
				}
			},
			"tipodec": {
				"title": "Tipo de Declaração",
				"campo1": "1ª Declaração do Ano",
				"campo2": "Declaração de Substituição",
				"campo3": "Declaração apresentada nos termos da alínea d), nº1, art. 119º do CIRS",
				"campo4": "Data do facto que determinou a obrigação de declarar ou alterar rendimentos já declarados"
			},
			"identSujPass": {
				"title": "Identificação do Sujeito Passivo ou Representação Legal e do CC",
				"campo1": "NIF do Contabilista Certificado",
				"campo2": "Número do Sujeito Passivo ou Representante Legal"
			}
		},
		"modal": {
			"config": {
				"title": "Configuração de radicais contas rendimento contabilidade modelo 10",
				"radicais": "Radicais de contas"
			},
			"retencaomod10": {
				"title": "Manutenção de rendimentos",
				"nif": "Sujeito passivo (NIF)",
				"rendAnoAnt": "Rendimentos de anos anteriores",
				"rendimentosAnterioresValores": "Valores",
				"rendimentosAnterioresNAnos": "Número de anos",
				"rendimentosDoAno": "Rendimentos do ano",
				"tipoRendimentos": "Tipo de rendimentos",
				"localObtencao": "Local de obtenção do rendimento",
				"retencoesIRSIRC": "Retenções de IRS/IRC",
				"contribuicoes": "Contribuições obrigatórias",
				"quotizacoes": "Quotizações sindicais",
				"retencaoSobretaxa": "Retenção da sobretaxa (anos de 2013 a 2017)"
			},
			"configws": {
				"title": "Configurações do WebService",
				"username": "Username do contribuinte",
				"password": "Password do contribuinte",
				"servicoInvCC": "Serviço invocado por Contabilista Certificado (CC)",
				"ccPowers": "CC com plenos poderes declarativos para o Contrib.",
				"usernameCC": "Username do CC",
				"passwordCC": "Password do CC",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> O serviço já se encontra configurado.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> O serviço não está configurado.",
				"configsaved": "Configuração guardada com sucesso."
			}
		},
		"btn": {
			"config": "Configurações",
			"limpo": "Limpo",
			"valorescarre": "Carregar valores guardados",
			"valoresauto": "Carregar valores automáticos",
			"criarficheiro": "Criar Ficheiro",
			"retencoes": "Visualizar Retenções",
			"btnSubmeterAt": "Submeter AT",
			"btnValidarAt": "Validar na AT",
			"btnConfigWS": "Configurações WS"
		},
		"message": {
			"registosdel": "Registos apagados com sucesso.",
			"dadoscarregados": "Dados carregados com sucesso!",
			"dadoscarregadosClean": "Campos limpos com sucesso!",
			"dadosguardados": "Dados guardados com sucesso!",
			"temDeGuardar": "Tem de guardar os dados para poder criar o ficheiro",
			"q6c4NotNull": "Tem que preencher o campo 4 do quadro 6",
			"servicoAT": {
				"validarAtSuccess": "Declaração validada com sucesso",
				"validarAtFailed": "Erro ao validar a declaração na AT",
				"submeterAtSuccess": "Declaração submetida com sucesso",
				"submeterAtFailed": "Erro ao submeter a declaração na AT"
			}
		}
	},
	"modelo22": {
		"tabs": {
			"anexos": "Anexos",
			"rosto": "Rosto",
			"anexoA": "Anexo A",
			"anexoB": "Anexo B",
			"anexoC": "Anexo C",
			"anexoD": "Anexo D",
			"anexoE": "Anexo E",
			"anexoF": "Anexo F",
			"anexoG": "Anexo G",
			"anexoAIMI": "Anexo AIMI",
			"adicionarAnexo": "Adicionar Anexos"
		},
		"btn": {
			"gravar": "Gravar",
			"validar": "Validar",
			"criarficheiro": "Ficheiro",
			"entregar": "Entregar",
			"anular": "Anular tudo",
			"calcular": "Calcular",
			"repor": "Repor tudo",
			"contasdefall": "Contas definidas para os campos",
			"contasdefcopy": "Copiar contas definidas para os campos para outra empresa",
			"limpaValores": "Limpa valores",
			"balancetes": "Balancetes",
			"extratos": "Extrato de conta",
			"calcDerrama": "Cálculo da derrama",
			"tributAutonomas": "Tributações Autónomas",
			"prejFiscais": "Prejuízos Fiscais",
			"retencoes": "Retenções",
			"documentosFiscais": "Documentos fiscais"
		},
		"comum": {
			"de": "De",
			"a": "a",
			"ate": "até",
			"periodo": "Período",
			"semdados": "Sem dados",
			"addline": "Adicionar linha",
			"sim": "Sim",
			"nao": "Não",
			"tooltipContasDef": "Definição das contas para o campo"
		},
		"rosto": {
			"quadro01": {
				"title": "Período de tributação"
			},
			"quadro02": {
				"title": "Área da sede, direção efetiva ou estabelecimento estável",
				"campo01": "Código do Serviço de Finanças da Sede ou do Estabelecimento Estável",
				"campo02": "Código do Serviço de Finanças da Direção Efetiva"
			},
			"quadro03": {
				"title": "Identificação e caracterização do sujeito passivo",
				"sujPass": "Designação",
				"campo02": "Nº de Identificação Fiscal (NIF)",
				"campo03": {
					"title": "Tipo de sujeito passivo",
					"radio1": "Residente que exerce, a título principal, atividade comercial, industrial ou agrícola",
					"radio2": "Residente que não exerce, a título principal, atividade comercial, industrial ou agrícola",
					"radio3": "Não residente com estabelecimento estável",
					"radio4": "Não residente sem estabelecimento estável"
				},
				"campo3A": {
					"title": "Qualificação da empresa nos termos do Anexo ao Decreto-Lei N.º 372/2007, de 6 de Novembro ou como cooperativa",
					"desc": "Se assinalou os campos 1 ou 3 do Quadro 03 - 3, indique como se qualifica nos termos previstos no Anexo ao Decreto-Lei n.º 372/2007, de 06 de novembro",
					"radio1": "Média empresa",
					"radio2": "Não PME",
					"radio3": "Micro empresa",
					"radio4": "Pequena empresa",
					"check5": "Cooperativa",
					"check6": "Indique se se qualifica como empresa de pequena-média capitalização [(Small Mid Cap (SMC)]"
				},
				"campo3B": {
					"title": "Organismos de investimento coletivo",
					"field1": "Indique se se trata de um Organismo de Investimento Coletivo tributado nos termos do artigo 22.º do EBF"
				},
				"campo3C": {
					"title": "Imputação de rendimentos (Art.º 5.º, n.º 9)",
					"desc": "É considerado um estabelecimento estável para efeitos da imputação prevista no n.º 9 do artigo 5.º?",
					"field1": "Sim"
				},
				"campo4": {
					"title": "Regimes de tributação dos rendimentos",
					"field3": "Isenção definitiva",
					"field4": "Isenção temporária",
					"field5": "Redução de taxa",
					"field6": "Simplificado",
					"field7": "Transparência fiscal",
					"field8": "Grupos de sociedades",
					"field9": "NIF da sociedade dominante / Responsável (art.º 69.º-A, n.ºs 3 e 4)",
					"field10": "Pretende exercer a opção pelas taxas do art.º 87.º, n.º 1? (art.º 91.º, n.º 2 da Lei n.º 3 - B/2010, de 28 de abril)",
					"field11": "Ocorreu alguma das situações referidas no ex-art.º 87.º, n.º 7?",
					"field12": "Artigo 36.º-A do EBF",
					"field13": "Regime especial das atividades de transporte marítimo (Dec.-lei n.º 92/2019, de 13 de novembro)"
				},
				"campo4A": {
					"title": "Transferência de residência/cessação da atividade de estabelecimento estável/afetação de elementos patrimoniais (art.ºs 83.º, 84.º e 54.º-A, n.º 11)",
					"desc": "Se no período de tributação ocorreu transferência de residência, afetação de elementos patrimoniais e estabelecimento estável situado fora do território português, cessação da atividade ou transferência de elementos patrimoniais de estabelecimento estável situado em território português, indique o local de destino",
					"field1": "Países da UE/EEE"
				}
			},
			"quadro04": {
				"title": "Características da declaração",
				"campo1": {
					"title": "Tipo de declaração",
					"radio1": "1.ª Declaração do período",
					"radio2": "Declaração de substituição (art.º 122.º, n.ºs 1 e 2)",
					"radio3": "Declaração de substituição (art.º 64.º, n.º 4)",
					"radio4": "Declaração de substituição (art.º 120.º, n.ºs 8 e 9)",
					"radio5": "Declaração de substituição (art.º 64.º, nº 4) fora do prazo legal",
					"radio6": "Declaração de substituição (art.º 122.º, n.º 3)",
					"data": "Data de notificação da decisão/sentença"
				},
				"campo2": {
					"title": "Declarações especiais",
					"check1": "Declaração do grupo",
					"check2": "Declaração do período de liquidação",
					"check3": "Declaração do período de cessação",
					"check4": "Antes da alteração",
					"check5": "Após a alteração",
					"check7": "Declaração do período do início de atividade",
					"check9": "Antes da dissolução",
					"check10": "Após a dissolução",
					"data6": "Data da cessação",
					"data8": "Data da transmissão/aquisição (entidades não residentes sem estabelecimento estável)",
					"data11": "Data da dissolução",
					"subtitle1": "Declaração com período especial de tributação"
				}
			},
			"quadro05": {
				"title": "Identificação do representante legal e do contabilista certificado",
				"campo1": "NIF do representante legal",
				"campo2": "NIF do contabilista certificado",
				"desc": "Caso tenha ocorrido justo impedimento (art.º 12.º-A do DL n.º 452/99, de 5 de novembro), indique:",
				"campo4": "Facto que determinou o justo impedimento",
				"campo5": "Data do início da ocorrência do facto",
				"campo6": "Data da cessação do facto"
			},
			"quadro07": {
				"title": "(Período 2010 e posteriores) Apuramento do lucro tributável",
				"acrescer": "A ACRESCER",
				"deduzir": "A DEDUZIR",
				"campo701": "RESULTADO LÍQUIDO DO PERÍODO",
				"campo702": "Variações patrimoniais positivas não refletidas no resultado líquido do período (art.º 21.º) e quota-parte do subsídio respeitante a ativos não correntes, não depreciáveis/não amortizáveis [art.º 22.º n.º 1, al. b) a al. d)]",
				"campo703": "Variações patrimoniais positivas (regime transitório previsto no art.º 5.º, n.ºs 1, 5 e 6 do DL n.º 159/2009, de 13/7)",
				"campo704": "Variações patrimoniais negativas não refletidas no resultado líquido do período (art.º 24.º)",
				"campo705": "Variações patrimoniais negativas (regime transitório previsto no art.º 5.º, n.ºs 1, 5 e 6 do DL n.º 159/2009, de 13/7)",
				"campo706": "Alteração do regime fiscal dos contratos de construção (correções positivas)",
				"campo707": "Alteração do regime fiscal dos contratos de construção (correções negativas)",
				"campo708": "SOMA (campos 701 + 702 + 703 - 704 - 705 + 706 - 707)",
				"campo709": "Matéria coletável/lucro tributável imputado por sociedades transparentes, ACE ou AEIE (art.º 6.º)",
				"campo710": "Correções relativas a períodos de tributação anteriores (art.º 18.º, n.º 2)",
				"campo711": "Vendas e prestações de serviços com pagamento diferido: diferença entre a quantia nominal da contraprestação e o justo valor (art.º 18.º, n.º 5)",
				"campo782": "Gastos referentes a inventários e a fornecimentos e serviços externos com pagamento diferido: gastos de juros (art.º 18.º, n.º 5)",
				"campo712": "Anulação dos efeitos do método da equivalência patrimonial e do método de consolidação proporcional no caso de empreendimentos conjuntos que sejam sujeitos passivos de IRC (art.º 18.º, n.º 8)",
				"campo713": "Ajustamentos não dedutíveis decorrentes da aplicação do justo valor (art.º 18.º, n.º 9)",
				"campo714": "Pagamentos com base em ações (art.º 18.º, n.º11)",
				"campo715": "Gastos de benefícios de cessação de emprego, benefícios de reforma e outros benefícios pós emprego ou a longo prazo dos empregados (art.º 18.º, n.º12)",
				"campo717": "Gastos suportados com a transmissão onerosa de partes de capital (ex-art.º 23.º, n.ºs 3, 4 e 1.ª parte do n.º5)",
				"campo721": "Provisões não dedutíveis ou para além dos limites legais (art.ºs 19.º, n.º 4 e 39.º) e perdas por imparidade fiscalmente não dedutíveis de ativos financeiros",
				"campo724": "IRC, incluindo as tributações autónomas, e outros impostos que direta ou indiretamente incidam sobre os lucros [art.º 23.º -A, n.º 1, al. a)]",
				"campo725": "Impostos diferidos [art.º 23.º -A, n.º 1, al. a)]",
				"campo716": "Despesas não documentadas [art.º 23.º -A, n.º1, al. b)]",
				"campo731": "Encargos não devidamente documentados [art.º 23.º -A, n.º 1, al. c)]",
				"campo726": "Encargos evidenciados em documentos emitidos por sujeitos passivos com NIF inexistente ou inválido ou por sujeitos passivos cessados oficiosamente [art.º 23.º -A, n.º 1, al. c)]",
				"campo783": "Despesas ilícitas [art.º 23.º -A, n.º1, al. d)]",
				"campo728": "Multas, coimas e demais encargos, incluindo juros compensatórios e moratórios, pela prática de infrações [art.º 23.º -A, n.º 1, al. e)]",
				"campo727": "Impostos, taxas e outros tributos que incidam sobre terceiros que o sujeito passivo não esteja legalmente obrigado a suportar [art.º 23.º -A, n.º1, al. f)]",
				"campo729": "Indemnizações por eventos seguráveis [art.º 23.º -A, n.º 1, al. g)]",
				"campo730": "Ajudas de custo e encargos com compensação pela deslocação em viatura própria do trabalhador [art.º 23.º -A, n.º 1, al. h)]",
				"campo732": "Encargos com o aluguer de viaturas sem condutor [art.º 23.º -A, n.º 1, al. i)]",
				"campo733": "Encargos com combustíveis [art.º 23.º -A, n.º 1, al. j)]",
				"campo784": "Encargos relativos a barcos de recreio e aeronaves de passageiros [art.º 23.º -A, n.º 1, al. k)]",
				"campo734": "Juros e outras formas de remuneração de suprimentos e empréstimos feitos pelos sócios à sociedade [art.º 23.º -A, n.º 1, al. m)]",
				"campo735": "Gastos não dedutíveis relativos à participação nos lucros por membros dos orgãos sociais [art.º 23.º -A, n.º 1, al. o)]",
				"campo780": "Contribuição sobre o setor bancário [art.º 23.º -A, n.º 1, al. p)]",
				"campo785": "Contribuição extraordinária sobre o setor energético [art.º 23.º -A, n.º 1, al. q)]",
				"campo802": "Contribuição extraordinária sobre a indústria farmacêutica [art.º 23.º-A, n.º 1, al. s)]",
				"campo746": "Importâncias pagas ou devidas a entidades não residentes sujeitas a um regime fiscal privilegiado [art.º 23.º -A, n.º 1, al. r) e n.º 7]",
				"campo737": "50% de outras perdas relativas a partes de capital ou outras componentes do capital próprio (ex-art.º 45.º, n.º 3, parte final)",
				"campo786": "Outras perdas relativas a instrumentos de capital próprio e gastos suportados com a transmissão onerosa de instrumentos de capital próprio de entidades não residentes sujeitas a um regime fiscal privilegiado (art.º 23.º -A, n.ºs 2 e 3)",
				"campo718": "Perdas por imparidade em inventários para além dos limites legais (art.º 28.º) e em créditos não fiscalmente dedutíveis ou para além dos limites legais (art.ºs 28.º -A a 28.º -C)",
				"campo719": "Perdas por imparidade de ativos não correntes (art.º 31 -B) e depreciações e amortizações (art.º 34.º, n.º1), não aceites como gastos",
				"campo720": "40% do aumento das depreciações dos ativos fixos tangíveis em resultado de reavaliação fiscal (art.º 15.º, n.º 2 do DR 25/2009, de 14/9)",
				"campo722": "Créditos incobráveis não aceites como gastos (art.º 41.º)",
				"campo723": "Realizações de utilidade social não dedutíveis (art.º 43.º)",
				"campo736": "Menos-valias contabilísticas",
				"campo738": "Mais-valia fiscal resultante de mudanças no modelo de valorização [art.º 46.º, n.º 5, al. b)]",
				"campo739": "Diferença positiva entre as mais-valias e as menos-valias fiscais sem intenção de reinvestimento (art.º 46.º)",
				"campo740": "50% da diferença positiva entre as mais-valias e as menos-valias fiscais com intenção expressa de reinvestimento (art.º 48.º, n.º 1)",
				"campo741": "Acréscimos por não reinvestimento ou pela não manutenção dos ativos na titularidade do adquirente (art.º 48.º, n.º 6)",
				"campo742": "Mais-valias fiscais-regime transitório [art.º 7.º, n.º 7, al. b) da Lei n.º 30-G/2000, de 29/12 e art.º 32.º, n.º 8 da Lei n.º 109-B/2001, de 27/12]",
				"campo743": "Correções relativas a instrumentos financeiros derivados (art.º 49.º)",
				"campo787": "Prejuízos de estabelecimentos estáveis situados fora do território português (art.º 54.º -A)",
				"campo744": "Correções relativas a preços de transferência (art.º 63.º, n.º 8)",
				"campo745": "Diferença positiva entre o valor patrimonial tributário definitivo do imóvel e o valor constante do contrato [art.º 64.º, n.º 3, al. a)]",
				"campo747": "Imputação de rendimentos de entidades não residentes sujeitas a um regime fiscal privilegiado (art.º 66.º)",
				"campo748": "Limitação à dedutibilidade de gastos de financiamento líquidos (art.º 67.º)",
				"campo749": "Correções nos casos de crédito de imposto por dupla tributação jurídica internacional (art.º 68.º, n.º 1)",
				"campo788": "Correções nos casos de crédito de imposto por dupla tributação económica internacional (art.º 68.º, n.º 3)",
				"campo750": "Correções resultantes da opção pelo regime especial aplicável às fusões, cisões, entradas de ativos e permutas de partes sociais (art.ºs 74.º, 76.º e 77.º)",
				"campo789": "Transferência de residência, afetação de elementos patrimoniais a estabelecimento estável situado fora do território português, cessação de atividade ou transferência de elementos patrimoniais de estabelecimento estável situado em território português: saldo positivo referente aos elementos patrimoniais transferidos para outro Estado membro da UE ou do EEE ou afetos a estabelecimento estável aí situado (art.ºs 83.º, 84.º e 54.º -A, n.º 11)",
				"campo790": "Transferência de residência, afetação de elementos patrimoniais a estabelecimento estável situado fora do território português, cessação de atividade ou transferência de elementos patrimoniais de estabelecimento estável situado em território português: saldo positivo referente aos elementos patrimoniais transferidos para países fora da UE ou do EEE ou afetos a estabelecimento estável aí situado (art.ºs 83.º, 84.º e 54.º -A, n.º 11)",
				"campo751": "Donativos não previstos ou além dos limites legais (art.ºs 62.º, 62.º-A e 62.º-B do EBF)",
				"campo803": "Assimetrias híbridas e assimetrias de residência fiscal (art.ºs 68.º-B, n.º 1 e 68.º-D, n.º1)",
				"campo779": "Encargos financeiros não dedutíveis (ex-art.º 32.º, n.º2 do EBF)",
				"campo797": "Adicional ao Imposto Municipal sobre imóveis (art.º 135.º-J do Código do IMI)",
				"campo799": "Gastos e perdas relativos às atividades de transporte marítimo às quais é aplicável o regime especial de determinação da matéria coletável (art.º 6.º do Anexo ao Decreto-Lei n.º 92/2018, de 13 de novembro)",
				"campo804": "Contribuições de solidariedade temporária sobre os setores da energia e da distribuição alimentar (art.º 16.º da Lei n.º 24-B/2022, de 30 de dezembro )",
				"campo752": "Outros acréscimos",
				"campo753": "SOMA (campos 708 a 752)",
				"campo754": "Despesas ou encargos de projeção económica plurianual contabilizados como gasto na vigência do POC e ainda não aceites fiscalmente [art.º 22.º, al. f) do DR 25/2009, de 14/9]",
				"campo755": "Prejuízo fiscal imputado por ACE ou AEIE (art.º 6.º)",
				"campo756": "Correções relativas a períodos de tributação anteriores (art.º 18, n.º 2)",
				"campo757": "Vendas e prestações de serviços com pagamento diferido: rédito de juros (art.º 18.º, n.º 5)",
				"campo791": "Gastos referentes a inventários e a fornecimentos e serviços externos com pagamento diferido: diferença entre a quantia nominal da contraprestação e o justo valor (art.º 18.º, n.º 5)",
				"campo758": "Anulação dos efeitos do método da equivalência patrimonial e do método de consolidação proporcional no caso de empreendimentos conjuntos que sejam sujeitos passivos de IRC (art.º 18.º, n.º 8)",
				"campo759": "Ajustamentos não tributáveis decorrentes da aplicação do justo valor (art.º 18.º, n.º 9)",
				"campo760": "Pagamentos com base em ações (art.º 18.º, n.º 11)",
				"campo761": "Pagamento ou colocação à disposição dos beneficiários de benefícios de cessação de emprego, benefícios de reforma e outros benefícios pós emprego ou a longo prazo dos empregados (art.º 18.º, n.º 12)",
				"campo762": "Reversão de perdas por imparidade tributadas (art.ºs 28.º, n.º 3 e 28.º -A, n.º 3)",
				"campo763": "Depreciações e amortizações tributadas em períodos de tributação anteriores (art.º 20.º do DR 25/2009, de 14/9)",
				"campo781": "Perdas por imparidade tributadas em períodos de tributação anteriores (art.ºs 28.º, 28.º -A, n.º 1 e 31.º -B, n.º7)",
				"campo764": "Reversão de provisões tributadas (art.ºs 19.º, n.º 4 e 39.º, n.º 4)",
				"campo765": "Restituição de Impostos não dedutíveis e excesso da estimativa para impostos",
				"campo766": "Impostos diferidos [art.º 23.º -A, n.º 1, al. a)]",
				"campo792": "Gasto fiscal relativo a ativos intangíveis, propriedades de investimento e ativos biológicos não consumíveis (art.º 45.º -A)",
				"campo767": "Mais-valias contabilísticas",
				"campo768": "50% da menos-valia fiscal resultante de mudanças no modelo de valorização [art.º 46.º, n.º 5, al. b) e ex-art.º 45.º, n.º 3, parte final] e 50% da diferença negativa entre as mais e as menos-valias fiscais de partes de capital ou outras componentes do capital próprio (ex-art. 45.º, n.º 3, 1.ª parte)",
				"campo769": "Diferença negativa entre as mais-valias e as menos-valias fiscais (art.º 46.º)",
				"campo770": "Correções relativas a instrumentos financeiros derivados (art.º 49.º)",
				"campo793": "Dedução dos rendimentos de direitos de autor e de direitos de propriedade industrial com o limite do n.º 8 do artigo 50.º-A",
				"campo771": "Eliminação da dupla tributação económica de lucros e reservas distribuídos (art.º 51.º e 51.º -D)",
				"campo794": "Lucros de estabelecimentos estáveis situados fora do território português (art.º 54.º -A)",
				"campo772": "Correção pelo adquirente do imóvel quando adota o valor patrimonial tributário definitivo para a determinação do resultado tributável na respetiva transmissão [art.º 64.º, n.º 3, al. b)]",
				"campo795": "Reporte dos gastos de financiamento líquidos de períodos de tributação anteriores (art.º 67.º)",
				"campo773": "Correções resultantes da opção pelo regime especial aplicável às fusões, cisões, entradas de ativos e permutas das partes sociais (art.ºs 74.º, 76.º e 77.º)",
				"campo796": "Transferência de residência, afetação de elementos patrimoniais a estabelecimento estável situado fora do território português, cessação da atividade ou transferência de elementos patrimoniais de estabelecimento estável situado em território português: saldo negativo referente aos elementos patrimoniais transferidos para fora do território português ou afetos a estabelecimento estável aí situado (art.ºs 83.º, 84.º e 54.º -A, n.º 11)",
				"campo774": "Benefícios Fiscais",
				"campo800": "Réditos e rendimentos relativos às atividades de transporte marítimo às quais é aplicável o regime especial de determinação da matéria coletável (art.º 6.º do Anexo ao Decreto-Lei n.º 92/2018, de 13 de novembro)",
				"campo801": "Aumento das depreciações ou amortizações resultantes das reavaliações efetuadas nos termos do Decreto-Lei n.º 66/2016, de 3 de novembro (art.º 8.º do Decreto-Lei)",
				"campo798": "Perdas por imparidade em créditos e benefícios pós-emprego ou a longo prazo de empregados (art.º 4.º do anexo à Lei n.º 61/2014, de 26 de agosto)",
				"campo775": "Outras deduções",
				"campo776": "SOMA (campos 754 a 798 + 775 + 801)",
				"campo777": "PREJUÍZO PARA EFEITOS FISCAIS (Se 776 > 753)",
				"campo778": "LUCRO TRIBUTÁVEL (Se 753 >= 776) (A transportar para o quadro 09)"
			},
			"quadro07Desativado": {
				"title": "(Período 2009 e anteriores) Apuramento do lucro tributável"
			},
			"quadro08": {
				"title": "Regimes de taxa",
				"sect81": {
					"title": "Regimes de redução de taxa",
					"taxas": "Taxas de tributação",
					"check242": "Estabelecimentos de ensino particular (ex-art.º 56.º do EBF)",
					"check245": "Benefícios relativos à interioridade (art.º 41.º-B e ex-art.º 43.º do EBF)",
					"check269": "Benefícios fiscais aplicáveis aos territórios do interior (R.A. Madeira) (artigo 19.º-A do Decreto Legislativo Regional n.º 28.º-A/2021/M, de 30 de dezembro)",
					"check270": "Benefícios fiscais aplicáveis aos territórios do interior (R. A. Açores) (artigo 38.º do Decreto Legislativo Regional n.º 1/2023/A, de 5 de janeiro)",
					"check248": "Antigo Estatuto Fiscal Cooperativo (art.º 7.º, n.º 3 da Lei nº 85/98, de 16/12)",
					"check260": "Entidades licenciadas na Zona Franca da Madeira (ex-art.º 35.º do EBF)",
					"check265": "Entidades licenciadas na Zona Franca da Madeira (art.ºs 36.º e 36.º-A do EBF)",
					"check3": "Benefícios relativos à interioridade (art.º 41.º-B e ex-art.º 43.º do EBF)"
				},
				"sect82": {
					"title": "Regime geral",
					"taxas": "Taxas de tributação",
					"check246": "Região Autónoma dos Açores (Dec. Leg. Regional n.º 2/1999/A, de 20/1)",
					"check249": "Região Autónoma da Madeira (Dec. Leg. Regional n.º 2/2001/M, de 20/2)",
					"check262": "Rendimentos prediais de entidades não residentes sem estabelecimento estável (art.º 87.º, n.º 4)",
					"check263": "Mais-valias imobiliárias/incrementos patrimoniais obtidos por entidades não residentes sem estabelecimento estável (art.º 87.º, n.º 4)",
					"check266": "Mais-valias mobiliárias obtidas por entidades não residentes sem estabelecimento estável (art.º 87.º, n.º 4)",
					"check267": "Rendimentos decorrentes da alienação de unidades de participação em FII e de participações sociais em SII, auferidos por entidades não residentes sem estabelecimento estável (art.º 22.º-A, n.º1, al. c) do EBF)",
					"check268": "Rendimentos de capitais não sujeitos a retenção na fonte a título definitivo",
					"check264": "Outros rendimentos obtidos por entidades não residentes sem estabelecimento estável"
				}
			},
			"quadro09": {
				"title": "Apuramento da matéria coletável",
				"transq7": "(transporte do Q.07)",
				"reggeral": "Regime geral",
				"redtaxa": "Com redução de taxa",
				"isencao": "Com isenção",
				"regsimp": "Regime simplificado (em vigor até 2010)",
				"prejFiscal": "1. PREJUÍZO FISCAL",
				"lucrotrib": "2. LUCRO TRIBUTÁVEL",
				"regespecial": "Regime especial dos grupos de sociedades",
				"field380": "Soma algébrica dos resultados fiscais",
				"field381": "Lucros distribuídos (ex-art.º 70.º, n.º 2)",
				"field395": "Gastos de financiamento líquidos (opção prevista no art.º 67.º, n.º 5)",
				"field500": "Ajustamento REAID (art.º 5.º, n.º 1 al. b) do Anexo à Lei n.º 61/2014, 26 de agosto)",
				"field376": "Resultados internos eliminados ao abrigo do anterior RTLC, a incluir no lucro tributável do período",
				"field382": "Resultado fiscal do grupo",
				"table396": {
					"title": "Prejuízos individuais deduzidos, verificados em períodos anteriores ao início da aplicação do regime:",
					"fields": {
						"prej": "Prejuízos",
						"periodo": "Período",
						"nif": "NIF"
					}
				},
				"table398": {
					"title": "Quotas-partes dos prejuízos fiscais deduzidas em caso de aquisição de grupos de sociedades (art.º 71.º, n.ºs 4 e 5)",
					"fields": {
						"prej": "Prejuízos",
						"periodo": "Período",
						"nif": "NIF"
					}
				},
				"prejfiscded": "Prejuízos fiscais dedutíveis",
				"prejfiscaut": "Prejuízos fiscais autorizados / transmitidos (art.º 75.º, n.ºs 1 e 3)",
				"prejfiscaut2": "Prejuízos fiscais autorizados / transmitidos [art.º 15.º, n.º 1, al. c) e art.º 75, n.º 5]",
				"alttitularidade": "Alteração da titularidade de mais de 50% do capital social ou da maioria dos direitos de voto - Prejuízos fiscais não dedutíveis (art.º 52.º, n.º 8)",
				"deducoes": "3. DEDUÇÕES",
				"subtitledesc": "Discriminação dos prejuízos fiscais deduzidos, por período de apuramento e montante",
				"subtitleregesp": "Regime especial aplicável aos adquirentes de entidades consideradas empresas em dificuldade - art.º 15.º da Lei n.º 27-A/2020, de 24 de julho",
				"table309": {
					"fields": {
						"periodo": "309.1 - Período",
						"montante": "309.2 - Montante"
					}
				},
				"table320": {
					"fields": {
						"periodo": "320.1 - Período",
						"montante": "320.2 - Montante"
					}
				},
				"table331": {
					"fields": {
						"periodo": "331.1 - Período",
						"montante": "331.2 - Montante"
					}
				},
				"benfisc": "Benefícios fiscais",
				"metcoletavel": "4. MATÉRIA COLETÁVEL (2-3)",
				"field336": "ZFM - Matéria coletável que excede os plafonds máximos (art.ºs 36.º, n.º 3 e 36.º-A, n.º 4 do EBF)",
				"field399": "COLETIVIDADES DESPORTIVAS - Dedução das importâncias investidas até 50% da matéria coletável (art.º 54, n.º 2 do EBF)",
				"existprej": "Existindo prejuízos fiscais autorizados / transmitidos, indique:",
				"field397": "Total do valor utilizado no período (397-A + 397-B)",
				"table397a": {
					"title": "Valor utilizado no período [art.º 15.º, n.º 1 al. c) e art.º 75.º, n.º 5]",
					"fields": {
						"valor": "397.A - Valor utilizado no período",
						"nif": "NIF",
						"periodo": "397.C - Período"
					}
				},
				"table397b": {
					"title": "Valor utilizado no período (art.º 75.º, n.ºs 1 e 3)",
					"fields": {
						"valor": "397.B - Valor utilizado no período",
						"nif": "NIF",
						"periodo": "397.D - Período"
					}
				},
				"table309B": {
					"fields": {
						"montante": "309.4 - Montante",
						"nif": "309.5 - NIF da sociedade considerada empresa em dificuldade",
						"periodo": "309.3 - Período"
					}
				},
				"field300": "Matéria Coletável do regime especial (campo 11 do quadro 04 do anexo G)",
				"field346": "MATÉRIA COLETÁVEL NÃO ISENTA [(311 - 399) + 322 + 336] ou 409 ou campo 42 do anexo E, exceto o campo 300"
			},
			"quadro10": {
				"title": "Cálculo do imposto",
				"field347A": "Imposto à taxa normal (art.º 87.º, n.º 2, 1ºs € 50.000,00 de matéria coletável das PME ou SMC) (c. 311 do Q.09 da M22 ou C. 42 do Anexo E) x 17%",
				"field347B": "Imposto à taxa normal (art.º 87.º, n.º 1) (c. 311 do q.09 da m22 ou c. 42 do anexo E) x 21%",
				"impoutrataxas": "Imposto a outras taxas",
				"field350": "Imposto imputável à Região Autónoma dos Açores",
				"field370": "Imposto imputável à Região Autónoma da Madeira",
				"field351": "COLETA (347-A + 347-B + 349 + 350 + 370)",
				"field373": "Derrama estadual (art.º 87.º - A)",
				"field378": "COLETA TOTAL (351 +373)",
				"deducoes": "Deduções",
				"field353": "Dupla tributação jurídica internacional (DTJI - art.º 91.º)",
				"field375": "Dupla tributação económica internacional (art.º 91.º -A)",
				"field355": "Benefícios fiscais",
				"field470": "Adicional ao Imposto Municipal sobre imóveis (art.º 135.º-J do CIMI)",
				"field356": "Pagamento especial por conta (art.º 93.º)",
				"field357": "TOTAL DAS DEDUÇÕES (353 + 375 + 355 + 356 + 470) <= 378",
				"field358": "TOTAL DO IRC LIQUIDADO (378 - 357) >= 0",
				"field371": "Resultado da liquidação (art.º 92.º)",
				"field359": "Retenções na fonte",
				"field360": "Pagamentos por conta (art.º 105.º) e Pagamento por conta autónomo (Lei n.º 7-A/2016, de 30 de março, art.º 136.º, n.º2)",
				"field374": "Pagamentos adicionais por conta (art.º 105.º - A)",
				"field361": "IRC A PAGAR (358 + 371 - 359 - 360 - 374) > 0",
				"field362": "IRC A RECUPERAR (358 + 371 - 359 - 360 - 374) < 0",
				"field363": "IRC de períodos anteriores",
				"field372": "Reposição de benefícios fiscais",
				"field364": "Derrama municipal",
				"field379": "Dupla tributação jurídica internacional (art.º 91.º) - Países com CDT e quando DTJI > 378",
				"field365": "Tributações autónomas",
				"field366": "Juros compensatórios",
				"field369": "Juros de mora",
				"field367": "TOTAL A PAGAR [361 ou (-362) + 363 + 372 + 364 - 379 + 365 + 366 + 369] > 0",
				"field368": "TOTAL A RECUPERAR [(-362) + 363 + 372 + 364 - 379 + 365 + 366 + 369] < 0",
				"campo10A": {
					"title": "Juros compensatórios",
					"desc": "Discriminação do valor indicado no campo 366 do quadro 10:",
					"field366A": "Juros compensatórios declarados por atraso na entrega da declaração",
					"field366B": "Juros compensatórios declarados por outros motivos"
				},
				"campo10b": {
					"title": "Transferência de residência/cessação da atividade de estabelecimento estável/afetação de elementos patrimoniais (art.ºs 83.º, 84.º e 54.º -A, n.º 11)",
					"data4": "Data da ocorrência",
					"desc": "Modalidade de pagamento do imposto correspondente (art.º 83.º, n.º 2)",
					"radio1": "imediato [al. a)]",
					"radio2": "diferido [al. b)]",
					"radio3": "fracionado [al. c)]",
					"valpagdiffracio": "Valor do pagamento diferido ou fracionado",
					"field377A": "IRC + Derrama estadual",
					"field377B": "Derrama municipal"
				},
				"field377": "Total dos pagamentos diferidos ou fracionados (377-A + 377-B)",
				"field430": "TOTAL A PAGAR (367 -377) > 0",
				"field431": "TOTAL A RECUPERAR [367 ou (- 368) - 377] < 0",
				"modal": {
					"newtaxa": {
						"title": "Alterar taxa derrama",
						"field": "Taxa derrama",
						"tooltip": "Taxa derrama"
					}
				},
				"consideraPrejuizoFiscal": "Prejuízo Fiscal",
				"tributAutonomas": "Tributações autónomas"
			},
			"quadro11": {
				"title": "Outras informações",
				"field410": "Total de rendimentos do período",
				"field411": "Volume de negócios do período (a repartir no quadro 11-B, se for caso disso)",
				"field416": "Diferença positiva entre o valor considerado para efeitos de liquidação do IMT e o valor constante do contrato, nos casos em que houve recurso ao procedimento previsto no art.º 139.º",
				"data418": "Data em que ocorreu a transmissão das partes sociais (art.º 51.º, n.º 9 e art.º 88.º, n.º 11)",
				"check423": "Tratando-se de microentidade, indique se, em alternativa às normas contabilísticas para microentidades (NC-ME), opta pela aplicação das normas contabilísticas e de relato financeiro para as pequenas entidades (NCRF-PE) ou das normas contabilísticas e de relato financeiro (NCRF) [art.º 9.º-D do DL n.º 158/2009, de 13 de julho]",
				"check429": "Ocorreu no período de tributação uma operação de fusão com eficácia retroativa (n.º 11 do art.º 8.º) da qual é sociedade beneficiária?",
				"check455": "Ocorreu durante o ano de 2020 operação de fusão ao abrigo do regime especial previsto nos artigos 73.º e seguintes do Código do IRC? (n.º 3 do artigo 14.º da Lei n.º 27-A/2020, de 24 de julho)",
				"sim": "Sim?"
			},
			"quadro11A": {
				"title": "Ativos por impostos diferidos (AID) - Lei n.º 61/2014, de 26 de agosto",
				"AID": "Discriminação dos AID inscritos nas demonstrações financeiras a que respeita a Mod. 22",
				"field460": "AID de perdas por imparidade em créditos abrangidos pelo REAID",
				"field461": "AID de benefícios pós-emprego ou a longo prazo de empregados abrangidos pelo REAID",
				"field462": "Outros AID",
				"field463": "Capital próprio",
				"field464": "Crédito Tributário",
				"data465": "Data da entrada em liquidação",
				"infoadd": "Informação adicional"
			},
			"quadro11B": {
				"title": "Repartição do volume anual de negócios do período pelas circunscrições (Continente, Açores e Madeira)",
				"desc": "A empresa possui sucursais, delegações, agências, escritórios, instalações ou quaisquer formas de representação permanente sem personalidade jurídica própria em mais do que uma circunscrição?",
				"radio6": "Sim",
				"radio7": "Não",
				"desc2": "Se respondeu sim, indique quais as circunscrições:",
				"radio8": "Continente",
				"radio9": "Madeira",
				"radio10": "Açores",
				"field1": "Volume global de negócios não isento",
				"field2": "Volume de negócios, não isento, imputável às instalações situadas na Região Autónoma da Madeira (RAM)",
				"field3": "Volume de negócios, não isento, imputável às instalações situadas na Região Autónoma dos Açores (RAA)",
				"field4": "Rácio 1 (RAM) = (campo 2 : campo 1)",
				"field5": "Rácio 2 (RAA) = (campo 3 : campo 1)",
				"field22": "Rácio 3 (CONTINENTE) = 1 - (rácio 1 + rácio 2)"
			},
			"quadro12": {
				"title": "Retenções na fonte",
				"table": {
					"fields": {
						"nif": "Nº de identificação fiscal (NIF)",
						"retfonte": "Retenção na fonte"
					}
				}
			},
			"quadro13": {
				"title": "Tributações autónomas",
				"desc": "Descrição",
				"basetrib": "Base tributável",
				"field414": "Despesas de representação (art.º 88.º, n.º7)",
				"field415": "Encargos efetuados ou suportados com ajudas de custo e de compensação pela deslocação em viatura própria do trabalhador (art.º 88.º, n.º 9)",
				"field417": "Lucros distribuídos por entidades sujeitas a IRC a sujeitos passivos que beneficiem de isenção total ou parcial (art.º 88.º, n.º 11)",
				"field420": "Encargos com viaturas (antiga redação do art.º 88, n.º 3) (regime em vigor até 31/12/2013)",
				"field421": "Encargos com viaturas (ex-art.º 88.º, n.º4) (regime em vigor até 31/12/2013)",
				"field422": "Indemnizações por cessação de funções de gestor, administrador ou gerente [art.º 88.º, n.º 13, al. a)]",
				"field424": "Gastos ou encargos relativos a bónus e outras remunerações variáveis pagas a gestores, administradores ou gerentes [art.º 88.º, n.º 13, al. b)]",
				"field425": "Encargos não dedutíveis nos termos da al. h) do n.º 1 do artigo 23.º-A suportados pelos sujeitos passivos que apresentem prejuízo fiscal (art.º 88.º, n.º 9) (regime em vigor até 31/12/2016)",
				"field426": "Encargos com viaturas ligeiras de passageiros e de mercadorias - Se CA < € 27.500,00 [art.º 88.º, n.º 3, al. a)]",
				"field427": "Encargos com viaturas ligeiras de passageiros e de mercadorias - Se CA >= € 27.500,00 e < € 35.000,00 [art.º 88.º, n.º 3, al. b)]",
				"field428": "Encargos com viaturas ligeiras de passageiros e de mercadorias - Se CA >= € 35.000,00 [art.º 88.º, n.º 3, al. c)]",
				"field432": "Encargos com viaturas ligeiras de passageiros híbridas plug-in, cuja bateria possa ser carregada através de ligação à rede elétrica e que tenham uma autonomia mínima, no modo elétrico, de 50 km e emissões oficiais inferiores a 50 gCO(índice 2)/km, e de viaturas ligeiras de passageiros movidas a gás natural veicular (GNV) - Se CA < € 27.500,00 [art.º 88.º , n.º 3, al. a) e n.º 18]",
				"field433": "Encargos com viaturas ligeiras de passageiros híbridas plug-in, cuja bateria possa ser carregada através de ligação à rede elétrica e que tenham uma autonomia mínima, no modo elétrico, de 50 km e emissões oficiais inferiores a 50 gCO(índice 2)/km, e de viaturas ligeiras de passageiros movidas a gás natural veicular (GNV) - Se CA >= € 27.500,00 e < € 35.000,00 [art.º 88.º , n.º 3, al. b) e n.º 18]",
				"field434": "Encargos com viaturas ligeiras de passageiros híbridas plug-in, cuja bateria possa ser carregada através de ligação à rede elétrica e que tenham uma autonomia mínima, no modo elétrico, de 50 km e emissões oficiais inferiores a 50 gCO(índice 2)/km, e de viaturas ligeiras de passageiros movidas a gás natural veicular (GNV) - Se CA >= € 35.000,00 [art.º 88.º , n.º 3, al. c) e n.º 18]",
				"field435": "Encargos com viaturas ligeiras de passageiros movidas a GNV - Se CA < € 27.500,00 [art.º 88.º, n.º 3, al. a) e n.º 19]",
				"field436": "Encargos com viaturas ligeiras de passageiros movidas a GNV - Se CA >= € 27.500,00 e < € 35.000,00 [art.º 88.º, n.º 3, al. b) e n.º 19]",
				"field437": "Encargos com viaturas ligeiras de passageiros movidas a GNV - Se CA >= € 35.000,00 [art.º 88.º, n.º 3, al. c) e n.º 19]",
				"field438": "Despesas não documentadas [art.º 88, n.ºs 1 e 2] (residentes que não exercem a título principal atividade comercial, industrial ou agrícola, regime simplificado ou OIC abrangidos pelo art.º 22.º, n.º 8 do EBF)",
				"field439": "Importâncias pagas ou devidas a entidades não residentes sujeitas a um regime fiscal privilegiado [art.º 88.º, n.ºs 1 e 8] (residentes que não exercem a título principal, atividade comercial, industrial ou agrícola, regime simplificado ou OIC abrangidos pelo art.º 22.º, n.º 8 do EBF)",
				"field456": "Encargos relacionados com veículos movidos exclusivamente a energia elétrica com custo de aquisição superior a € 62.500,00 [art.º 88.º , n.º20]",
				"campoIniciouAtividade": "Iniciou a atividade no período de tributação anterior àquele a que respeita a declaração? (art.º 88.º, n.º 15)",
				"field13e3": "Se iniciou a atividade num dos períodos de tributação de 2018, 2019, 2020 ou 2021, indique a data de início de atividade"
			},
			"quadro13A": {
				"title": "Tributações autónomas - Zona Franca da Madeira (art.º 36.º-A, n.º 14 do EBF)",
				"desc": "Descrição",
				"basetrib": "Base tributável",
				"field440": "Despesas de representação (art.º 88.º, n.º7)",
				"field441": "Encargos efetuados ou suportados com ajudas de custo e de compensação pela deslocação em viatura própria do trabalhador (art.º 88.º, n.º 9)",
				"field442": "Lucros distribuídos por entidades sujeitas a IRC a sujeitos passivos que beneficiem de isenção total ou parcial (art.º 88.º, n.º 11)",
				"field443": "Indemnizações por cessação de funções de gestor, administrador ou gerente [art.º 88.º, n.º 13, al. a)]",
				"field444": "Gastos ou encargos relativos a bónus e outras remunerações variáveis pagas a gestores, administradores ou gerentes [art.º 88.º, n.º 13, al. b)]",
				"field445": "Encargos não dedutíveis nos termos da al. h) do n.º 1 do artigo 23.º -A suportados pelos sujeitos passivos que apresentem prejuízo fiscal (art.º 88.º, n.º 9) (regime em vigor até 31/12/2016)",
				"field446": "Encargos com viaturas ligeiras de passageiros e de mercadorias - Se CA < € 27.500,00 [art.º 88.º, n.º 3, al. a)]",
				"field447": "Encargos com viaturas ligeiras de passageiros e de mercadorias - Se CA >= € 27.500,00 e < € 35.000,00 [art.º 88.º, n.º 3, al. b)]",
				"field448": "Encargos com viaturas ligeiras de passageiros e de mercadorias - Se CA >= € 35.000,00 [art.º 88.º, n.º 3, al. c)]",
				"field449": "Encargos com viaturas ligeiras de passageiros híbridas plug-in, cuja bateria possa ser carregada através de ligação à rede elétrica e que tenham uma autonomia mínima, no modo elétrico, de 50 km e emissões oficiais inferiores a 50 gCO(índice 2)/km, e de viaturas ligeiras de passageiros movidas a gás natural veicular (GNV) - Se CA < € 27.500,00 [art.º 88.º , n.º 3, al. a) e n.º 18]",
				"field450": "Encargos com viaturas ligeiras de passageiros híbridas plug-in, cuja bateria possa ser carregada através de ligação à rede elétrica e que tenham uma autonomia mínima, no modo elétrico, de 50 km e emissões oficiais inferiores a 50 gCO(índice 2)/km, e de viaturas ligeiras de passageiros movidas a gás natural veicular (GNV) - Se CA >= € 27.500,00 e < € 35.000,00 [art.º 88.º , n.º 3, al. b) e n.º 18]",
				"field451": "Encargos com viaturas ligeiras de passageiros híbridas plug-in, cuja bateria possa ser carregada através de ligação à rede elétrica e que tenham uma autonomia mínima, no modo elétrico, de 50 km e emissões oficiais inferiores a 50 gCO(índice 2)/km, e de viaturas ligeiras de passageiros movidas a gás natural veicular (GNV) - Se CA >= € 35.000,00 [art.º 88.º , n.º 3, al. c) e n.º 18]",
				"field452": "Encargos com viaturas ligeiras de passageiros movidas a GNV - Se CA < € 27.500,00 [art.º 88.º, n.º 3, al. a) e n.º 19]",
				"field453": "Encargos com viaturas ligeiras de passageiros movidas a GNV - Se CA >= € 27.500,00 e < € 35.000,00 [art.º 88.º, n.º 3, al. b) e n.º 19]",
				"field454": "Encargos com viaturas ligeiras de passageiros movidas a GNV - Se CA >= € 35.000,00 [art.º 88.º, n.º 3, al. c) e n.º 19]",
				"field457": "Encargos relacionados com veículos movidos exclusivamente a energia elétrica com custo de aquisição superior a € 62.500,00 [art.º 88.º , n.º20]"
			},
			"quadro14": {
				"title": "Crédito de imposto por dupla tributação jurídica internacional (CIDTJI)",
				"table": {
					"fields": {
						"codpais": "Código do País",
						"tiprend": "Tipo de rendimentos",
						"periodo": "Período do CIDTJI",
						"salcaducado": "Saldo caducado",
						"saldnaored": "Saldo não deduzido",
						"apurapreio": "Apuramento no período",
						"dedperio": "Dedução efetuada no período",
						"saldotrans": "Saldo que transita",
						"imppagestr": "Imposto pago no estrangeiro [art.º 91.º, n.º1, al. a)]",
						"facimprendest": "Fração do imposto relativa a rendimentos obtidos no estrangeiro [art.º 91.º, n.º 1, al. b)]",
						"credpreio": "Crédito de imposto do período",
						"paisComCDT": "País com CDT"
					}
				},
				"totCIDTJIcomCDT": "TOTAL do CIDTJI com CDT",
				"totCIDTJIsemCDT": "TOTAL do CIDTJI sem CDT",
				"totCIDTJIJI": "TOTAL do CIDTJI"
			}
		},
		"anexoA": {
			"quadro3": {
				"title": "Informação relevante",
				"dadosgerais": "Dados gerais",
				"dadosesp": "Dados específicos - Centros Eletroprodutores/Minas",
				"field1": "Lucro tributável total (campo 302 + 313 do Q. 09)",
				"field2": "Lucro tributável na Zona Franca da Madeira (campo 313 do Q. 09) (art.º 36.º-A do EBF)",
				"field10": "Soma algébrica da matéria coletável do regime especial e do lucro tributável do regime geral (campo 300 + campo 302)",
				"field3": "Massa salarial total",
				"check4": "É o 1.º ano de aplicação do regime?",
				"field5": "Total da área de instalação ou exploração (ha2)",
				"field6": "Total da potência instalada (MW)",
				"field7": "Total da eletricidade produzida (GWh)",
				"field8": "Valor total da produção à boca da mina (em euros)",
				"field9": "Total da massa salarial + prestações de serviços"
			},
			"quadro4": {
				"title": "Cálculo da derrama municipal",
				"massasaltot": "Massa Salarial Total",
				"somacontr": "Soma de Controlo",
				"massasalprestmun": "Massa salarial + prestações de serviços do município",
				"areintexplomun": "Área de instal. ou explor. no município",
				"poteinstmun": "Potência instalada no município",
				"toteletrprodmun": "Total de eletric. produzida no município",
				"prodbocaminamun": "Produção à boca da mina no município",
				"sect04-A": {
					"title": "Critério Geral",
					"table": {
						"fields": {
							"field1": "Código do distrito / município (1)",
							"field2": "Taxa de derrama (2)",
							"field3": "Massa salarial do município (3)",
							"field4": "Rácio de repartição (4) = (3) / (Q.03, C3)",
							"field5": "DERRAMA CALCULADA (5) = (Q.03, C1 x (2) x (4)) ou (Q.03, C10) x (2) x (4)"
						}
					}
				},
				"sect04-B": {
					"title": "Critério geral - Zona Franca da Madeira (Art.º 36.º -A, n.º 12 do EBF)",
					"table": {
						"fields": {
							"field1": "Código do distrito / município (1)",
							"field2": "Taxa de derrama (2)",
							"field3": "Massa salarial do município na ZFM (3)",
							"field4": "Rácio de repartição (4) = (3) / (Q.03, C3)",
							"field5": "Derrama calculada (5) = [(Q.03, C2 x (2) x (4))] x 0,2"
						}
					}
				},
				"sect04-C": {
					"title": "Critério específico - centros eletroprodutores",
					"table": {
						"fields": {
							"field10": "Indique se é o 1.º ano de aplicação do critério (10)",
							"field1": "Código do distrito / município (1)",
							"field2": "Taxa de derrama (2)",
							"field3": "Massa salarial + prestações de serviços do município (MSPSMunic) (3)",
							"field4": "Área de instal. ou explor. no município (AIMunic) (4)",
							"field5": "Potência instalada no município (PIMunic) (5)",
							"field6": "Total da eletric. produzida no município (EPMunic) (6)",
							"field7": "Rácio município (7)",
							"field8": "Derrama calculada (8) = (Q03 C1) x (2) x (7)"
						}
					}
				},
				"sect04-D": {
					"title": "Critério específico - Minas",
					"table": {
						"fields": {
							"field9": "Indique se é o 1.º ano de aplicação do critério (9)",
							"field1": "Código do distrito / município (1)",
							"field2": "Taxa de derrama (2)",
							"field3": "Massa salarial + prestações de serviços do município (MSPSMunic) (3)",
							"field4": "Área de instal. ou explor. no município (AIMunic) (4)",
							"field5": "Produção à boca da mina no município (PBMunic) (5)",
							"field6": "Rácio município (6)",
							"field7": "Derrama calculada (7) = (Q03 C1) x (2) x (6)"
						}
					}
				}
			}
		},
		"anexoC": {
			"quadro04": {
				"title": "Regime geral e regime simplificado com aplicação das taxas regionais",
				"varglob": "Valores Globais",
				"field6": "MATÉRIA COLETÁVEL (campos 311 + 336, exceto campo 300, do quadro 09 da declaração ou campo 42 do Anexo E)",
				"field6A": "MATÉRIA COLETÁVEL REGIME ESPECIAL (Decreto-lei n.º 92/2018, de 13/11) - (campo 300 do quadro 09 da declaração)",
				"field7A": "COLETA: Se PME ou SMC - até € 50.000,00 (montante até € 50.000,00 do campo 6 x 17%)",
				"field7B": "COLETA: Se PME ou SMC - superior a € 50.000,00 [(campo 6 - € 50.000,00) x 21%] ou se Grande empresa (campo 6 x 21%)",
				"field7C": "COLETA do REGIME ESPECIAL (Decreto-lei n.º 92/2018, de 13/11) - (campo 6-A x 21%)",
				"ram": "RAM",
				"field8A": "COLETA da RAM: Se PME ou SMC - até € 50.000,00 (campo 4 do quadro 11-B da declaração x montante até € 50.000,00 x 11,9%) - a transportar para o campo 370 da declaração",
				"field8B": "COLETA da RAM: Se PME ou SMC - superior a € 50.000,00 [campo 4 do quadro 11-B da declaração x (campo 6 - € 50.000,00) x 14,7%] ou se Grande empresa [campo 4 do quadro 11-B da declaração x campo 6 x 14,7%] - a transportar para o campo 370 da declaração",
				"field8C": "COLETA do REGIME ESPECIAL (Decreto-lei n.º 92/2018, de 13/11) na RAM - (campo 4 do quadro 11-B da declaração x campo 6-A x 14,7%) - a transportar para o campo 370 da declaração",
				"raa": "RAA",
				"field9A": "COLETA da RAA: Se PME ou SMC - até € 50.000,00 [campo 5 do quadro 11-B da declaração x (montante até € 50.000,00 do campo 6) x 11,9%] - a transportar para o campo 350 da declaração",
				"field9B": "COLETA da RAA: Se PME ou SMC - superior a € 50.000,00 [campo 5 do quadro 11-B da declaração x (campo 6 - € 50.000,00) x 14,7%] ou se Grande Empresa (campo 5 do quadro 11-B da declaração x campo 6 x 14,7%) - a transportar para o campo 350 da declaração",
				"field9C": "COLETA do REGIME ESPECIAL (Decreto-lei n.º 92/2018, de 13/11) na RAA - (campo 5 do quadro 11-B da declaração x campo 6-A x 14,7%) - a transportar para o campo 350 da declaração",
				"continente": "Continente",
				"field10A": "COLETA do CONTINENTE: Se PME ou SMC - até € 50.000,00 (campo 22 do quadro 11-B da declaração x campo 7-A) - a transportar para o campo 347-A da declaração",
				"field10B": "COLETA do CONTINENTE: Se PME ou SMC - superior a € 50.000,00 (campo 22 do quadro 11-B da declaração x campo 7-B) ou se Grande empresa (campo 22 do quadro 11-B da declaração x campo 7-B) - a transportar para o campo 347-B da declaração",
				"field10C": "COLETA do REGIME ESPECIAL (Decreto-lei n.º 92/2018, de 13/11) no CONTINENTE (campo 22 do quadro 11-B da declaração x campo 7-C ) - a transportar para o campo 347-B da declaração"
			},
			"quadro05": {
				"title": "Antigo regime simplificado (ex-art.º 58.º do CIRC), regimes de redução de taxa, entidades que não exercem a título principal uma atividade comercial, industrial ou agrícola",
				"field11": "Matéria coletável (campos 311-399 ou campo 322 ou campo 409 do quadro 09 da declaração)",
				"coleta": "Coleta: (campo 11 x taxa)",
				"field13": "COLETA DA RAM: (campo 4 do quadro 11-B da declaração x campo 12) - a transportar para o campo 370 da declaração",
				"field14": "COLETA DA RAA: (campo 5 do quadro 11-B da declaração x campo 12 x 0,7) - a transportar para o campo 350 da declaração",
				"field15": "COLETA do CONTINENTE: (campo 22 do quadro 11-B da declaração x campo 12) - a transportar para o campo 349 da declaração"
			},
			"quadro06": {
				"title": "Regime geral sem aplicação das taxas regionais",
				"varglob": "Valores Globais",
				"field16": "Matéria coletável (campo 311 do quadro 09 da declaração)",
				"field17A": "COLETA: Se PME ou SMC - até € 50.000,00 (montante até € 50.000,00 do campo 16 x 17%)",
				"field17B": "COLETA: Se PME ou SMC - superior a € 50.000,00 [(campo 16 - € 50.000,00) x 21%] ou se Grande empresa (campo 16 x 21%)",
				"ram": "RAM",
				"field18A": "COLETA da RAM: Se PME ou SMC - até € 50.000,00 (campo 4 do quadro 11-B da declaração x campo 17-A) - a transportar para o campo 370 da declaração",
				"field18B": "COLETA da RAM: Se PME ou SMC - superior a € 50.000,00 (campo 4 do quadro 11-B da declaração x campo 17-B) ou se Grande empresa (campo 4 do quadro 11-B da declaração x campo 17-B) - a transportar para o campo 370 da declaração",
				"raa": "RAA",
				"field19A": "COLETA da RAA: Se PME ou SMC - até € 50.000,00 (campo 5 do quadro 11-B da declaração x campo 17-A) a transportar para o campo 350 da declaração",
				"field19B": "COLETA da RAA: Se PME ou SMC - superior a € 50.000,00 (campo 5 do quadro 11-B da declaração x campo 17-B) ou se Grande empresa (campo 5 do quadro 11-B da declaração x campo 17-B) - a transportar para o campo 350 da declaração",
				"continente": "Continente",
				"field20A": "COLETA do CONTINENTE: Se PME ou SMC - até € 50.000,00 (campo 22 do quadro 11-B da declaração x campo 17-A) - a transportar para o campo 347-A da declaração",
				"field20B": "COLETA do CONTINENTE: Se PME ou SMC - superior a € 50.000,00 (campo 22 do quadro 11-B da declaração x campo 17-B) ou se Grande empresa (campo 22 do quadro 11-B da declaração x campo 17-B) - a transportar para o campo 347-B da declaração"
			},
			"quadro06A": {
				"title": "Regime de grupos de sociedades com aplicação das taxas regionais",
				"varglob": "Valores Globais",
				"field22": "Matéria coletável (Campo 311 do Quadro 09 da declaração)",
				"field22A": "COLETA: Se PME ou SMC - até € 50.000,00 (montante até € 50.000,00 do campo 22 x 17%)",
				"field22B": "COLETA: Se PME ou SMC - superior a € 50.000,00 [(campo 22 - € 50.000,00) x 21%] ou se Grande empresa (campo 22 x 21%)",
				"ram": "RAM",
				"field23A": "COLETA da RAM: Se PME ou SMC - até € 50.000,00 (campo 4 do quadro 11-B da declaração x montante até 50.000,00 do campo 22 x 11,9%) - a transportar para o campo 370 da declaração",
				"field23B": "COLETA da RAM: Se PME ou SMC - superior a € 50.000,00 (campo 4 do quadro 11-B da declaração x (campo 22 - 50.000,00) x 14,7%) ou se Grande empresa (campo 4 do quadro 11-B da declaração x campo 22 x 14,7%) - a transportar para o campo 370 da declaração",
				"raa": "RAA",
				"field24A": "COLETA da RAA: Se PME ou SMC - até € 50.000,00 (campo 5 do quadro 11-B da declaração x montante até 50.000,00 do campo 22 x 11,9%) a transportar para o campo 350 da declaração",
				"field24B": "COLETA da RAA: Se PME ou SMC - superior a € 50.000,00 (campo 5 do quadro 11-B da declaração x (campo 22 - 50.000,00) x 14,7%) ou se Grande empresa (campo 5 do quadro 11-B da declaração x campo 22 x 14,7%) - a transportar para o campo 350 da declaração"
			},
			"quadro07": {
				"title": "Benefício de Redução de Taxa Aplicável aos Territórios do Interior no Âmbito da R. A. Madeira",
				"varglob": "Valores Globais",
				"ram": "RAM",
				"raa": "RAA",
				"continente": "Continente",
				"field1": "MATÉRIA COLETÁVEL (Campo 42 do Anexo E ou Campo 322 do quadro 09)",
				"field2A": "COLETA: PME ou SMC - até € 50.000,00 (montante até € 50.000,00 do campo 1 x 17%)",
				"field2B": "COLETA: PME ou SMC - superior a € 50.000,00 [(campo 1 - € 50.000,00) x 21%]",
				"field3A": "COLETA da RAM: PME ou SMC - até € 50.000,00 (campo 4 do quadro 11-B da declaração x montante até € 50.000,00 x 8,75%) - a transportar para o campo 370 da declaração",
				"field3B": "COLETA da RAM: PME ou SMC - superior a € 50.000,00 [campo 4 do quadro 11-B da declaração x (campo 1 - € 50.000,00) x 14,7%] - a transportar para o campo 370 da declaração",
				"field4A": "COLETA da RAA: PME - até € 50.000,00 [campo 5 do quadro 11-B da declaração x (montante até € 50.000,00 do campo 1) x 8,75%] - a transportar para o campo 350 da declaração",
				"field4B": "COLETA da RAA: PME - superior a € 50.000,00 [campo 5 do quadro 11-B da declaração x (campo 1 - € 50.000,00) x 14,7%] - a transportar para o campo 350 da declaração",
				"field5A": "COLETA do CONTINENTE: PME ou SMC - até € 50.000,00 (campo 22 do quadro 11-B da declaração x campo 2-A) - a transportar para o campo 349 da declaração",
				"field5B": "COLETA do CONTINENTE: PME ou SMC - superior a € 50.000,00 (campo 22 do quadro 11-B da declaração x campo 2-B) - a transportar para o campo 347-B da declaração"
			}
		},
		"anexoD": {
			"quadro03": {
				"title": "Rendimentos Isentos",
				"desc31": "Isenção definitiva",
				"rendliqu": "Rendimentos Líquidos",
				"field301": "Pessoas coletivas de utilidade pública e de solidariedade social (art.º 10.º do CIRC)",
				"field302": "Atividades culturais, recreativas e desportivas (art.º 11.º do CIRC e art.º 54.º, n.º 1 do EBF)",
				"field303": "Cooperativas (art.º 66.º-A do EBF)",
				"field313": "Empreiteiros ou arrematantes, relativamente aos lucros derivados de obras e trabalhos das infraestruturas comuns NATO (art.º 14, n.º 2 do CIRC)",
				"field314": "Fundos de pensões e equiparáveis (art.º 16.º, n.º 1 do EBF) e outros fundos isentos definitivamente",
				"field316": "Entidade central de armazenagem: resultados líquidos do período contabilizados na gestão de reservas estratégicas de petróleo (art.º 25.º-A do Decreto-Lei n.º 165/2013, de 16 de dezembro)",
				"field304": "Outras isenções definitivas",
				"desc31A": "Campo 314 - Fundos de pensões e equiparáveis (art.º 16.º, n.º 1 do EBF) e outros fundos isentos definitivamente",
				"desc31B": "Campo 304 - Outras isenções definitivas",
				"desc32": "Isenção temporária",
				"field305": "Zona Franca da Madeira e da Ilha de Santa Maria (art.º 33.º, n.º 1 do EBF)",
				"field306": "Entidades gestoras de denominações de origem e indicações geográficas (art.º 52.º do EBF)",
				"field307": "Entidades gestoras de sistemas integrados de gestão de fluxos específicos de resíduos (art.º 53.º do EBF)",
				"field308": "Associações públicas, confederações, associações sindicais e patronais e associações de pais (art.º 55.º do EBF)",
				"field309": "Sociedades ou associações científicas internacionais (ex-art.º 57.º do EBF)",
				"field310": "Baldios e comunidades locais (art.º 59.º do EBF)",
				"field311": "Medidas de apoio ao transporte rodoviário de passageiros e mercadorias [mais-valias isentas (art.º 70.º do EBF)]",
				"field315": "Fundos de poupança em ações (art.º 26.º do EBF) e outros fundos isentos temporariamente",
				"field317": "Rendimentos obtidos por entidades de gestão florestal (EGF) e unidades de gestão florestal (UGF) (art.º 59.º-G do EBF)",
				"field312": "Outras isenções temporárias",
				"desc32A": "Campo 315 - Fundos de poupança em ações (art.º 26.º do EBF) e outros fundos isentos temporariamente",
				"desc32B": "Campo 312 - Outras isenções temporárias",
				"table": {
					"fields": {
						"codben": "Código do benefício",
						"montante": "Montante"
					}
				}
			},
			"quadro04": {
				"title": "Deduções ao rendimento (a deduzir no campo 774 no quadro 07 da declaração)",
				"normalegal": "Normativo legal",
				"dedefetuada": "Dedução efetuada",
				"field401": "Majoração à criação de emprego (art.º 19.º do EBF)",
				"field402": "Fundos de investimento [art.º 22.º, n.º 14, al. b) do EBF]",
				"field403": "Eliminação da dupla tributação económica dos lucros distribuídos por sociedades residentes nos PALOP e Timor-Leste (ex-art.º 42.º do EBF)",
				"field404": "Majorações aplicadas aos benefícios fiscais à interioridade [ex-art.º 43.º, n.º1, al. c) e d) do EBF]",
				"field405": "Empresas armadoras da marinha mercante nacional (art.º 51.º do EBF)",
				"field406": "Majorações aplicadas aos donativos previstos nos artigos 62.º, 62.º-A e 62.º-B do EBF",
				"field426": "Majorações aplicadas aos donativos previstos nos artigos 62.ºdo EBF - Mecenato social, desportivo e ambiental",
				"field427": "Majorações aplicadas aos donativos previstos no artigo 62.º-A do EBF - Mecenato científico",
				"field428": "Majorações aplicadas aos donativos previstos no artigo 62.º-B do EBF - Mecenato cultural",
				"field432": "Majorações aplicadas aos donativos relativos ao mecenato cultural extraordinário para 2021 e 2022 (art.º 397.º da Lei n.º75-B/2020, de 31 de dezembro e art.º 315.º da Lei n.º12/2022, de 27 de junho)",
				"field407": "Majoração de quotizações empresariais (art.º 44.º do CIRC)",
				"field408": "Majoração aplicada aos gastos suportados com a aquisição, em território português, de combustíveis para abastecimento de veículos (art.º 70.º, n.º 4 do EBF)",
				"field409": "Remuneração convencional do capital social (art.º 136.º da Lei n.º 55-A/2010, de 31/12 e art.º 41.º-A do EBF)",
				"field412": "Majoração dos gastos relativos a creches, lactários e jardins de infância (art.º 43.º, n.º 9 do CIRC)",
				"field413": "Majoração das despesas realizadas por cooperativas em aplicação da reserva para a educação e formação (art.º 66.º-A, n.º 7 do EBF)",
				"field414": "Lucros colocados à disposição e rendimentos de juros obtidos por sócios ou acionistas de sociedades licenciadas na ZFM (art.º 36.º-A, n.ºs 10 e 11, do EBF)",
				"field415": "Majoração dos gastos suportados com a aquisição de eletricidade, GNV para abastecimento de veículos (art.º 59.º-A do EBF)",
				"field416": "Majoração das despesas com sistemas de car-sharing e bike-sharing (art.º 59.º-B do EBF)",
				"field417": "Majoração das despesas com frotas de velocípedes (art.º 59.º-C do EBF)",
				"field418": "Majoração do gasto suportado por proprietários e produtores florestais aderentes a zona de intervenção florestal com contribuições financeiras destinadas ao fundo comum e encargos com defesa da floresta (art.º 59.º-D, n.º 12 do EBF)",
				"field419": "Majoração das despesas com certificação biológica de exploração (art.º 59.º-E do EBF)",
				"field420": "Majorações dos gastos e perdas no âmbito de parcerias de títulos de impacto social (art.º 19.º-A do EBF)",
				"field421": "Majorações dos gastos e perdas relativos a obras de conservação e manutenção dos prédios ou parte de prédios afetos a lojas com história reconhecidas pelo município (art.º 59.º-I do EBF)",
				"field422": "Majoração do aumento das depreciações e amortizações, prevista no art.º 8.º, n.º 3 do Decreto-Lei n.º 66/2016, de 3 de novembro",
				"field423": "Majoração das depreciações fiscalmente aceites de elementos do ativo fixo tangível correspondentes a embarcações eletrossolares ou exclusivamente elétricas (art.º 59.º-J do EBF)",
				"field424": "Rendimentos e ganhos que não sejam mais valias fiscais a que se referem os n.ºs 1 e 2 do artigo 268.º do Código da Insolvência e da Recuperação de Empresas (CIRE), aprovado pelo Decreto-Lei n.º 53/2004, de 18 de março",
				"field425": "Rendimentos prediais resultantes de contratos de arrendamento ou subarrendamento habitacional enquadrados no Programa de Arrendamento Acessível (art.º 20.º, n.º 1 do Decreto-Lei n.º 68/2019, de 22 de maio)",
				"field429": "Rendimentos prediais obtidos no âmbito dos programas municipais de oferta para arrendamento habitacional a custos acessíveis (art.º 71.º, n.º 27 do EBF)",
				"field430": "Majorações dos gastos suportados com a aquisição de passes sociais em benefício do pessoal (art.º 43.º, n.º 15 do CIRC)",
				"field431": "Despesas com aquisição de bens e serviços diretamente necessários para a implementação dos desenvolvimentos que permitam assegurar a prévia submissão do ficheiro SAFT-PT relativo à contabilidade no âmbito da IES e do código único do documento (ATCUD), nos termos previstos no n.º 1 do artigo 316.º da Lei n.º 12/2022, de 27 de junho.",
				"field433": "Majoração das despesas elegíveis, incorridas nos períodos de 2021 e 2022, no âmbito de participação conjunta em projetos de promoção externa (art.º 400.º, n.º 1 da Lei n.º 75.º-B/2020, de 31 de dezembro)",
				"field434": "Majorações dos gastos referentes a consumos de eletricidade e gás natural, na parte em que excedam os do período anterior, deduzidos de eventuais apoios nos termos do Decreto-Lei n.º 30-B/2022, de 18 de abril (art.º 231.º da Lei n.º24-D/2022, de 30 de dezembro)",
				"field435": "Majorações dos gastos referentes ao regime extraordinário de apoio a encargos suportados na produção agrícola (art.º 232.º da Lei n.º24-D/2022, de 30 de dezembro)",
				"field436": "Majorações dos encargos correspondentes ao aumento determinado por instrumento de regulamentação coletiva de trabalho dinâmica relativos a trabalhadores com contrato de trabalho por tempo indeterminado (art.º 19.º-B do EBF)",
				"field437": "Regime fiscal de incentivo à capitalização das empresas [(ICE) art.º 43.º-D do EBF]",
				"field438": "Majoração dos encargos correspondentes à criação líquida de postos de trabalho (art.º 41.º-B, n.º 6 do EBF)",
				"field439": "Diferencial do coeficiente do apoio extraordinário ao arrendamento (art.º 3.º, n.º 3 da Lei n.º 19/2022, de 21 de outubro)",
				"field410": "Outras deduções ao rendimento",
				"field411": "TOTAL DAS DEDUÇÕES (401 + ... + 409 + 412 + ... + 435 + ... + 439 + 410)",
				"desc4A": "Campo 410 - Outras deduções ao rendimento",
				"desc4B": "Informação adicional ( art.º 268.º do CIRE)",
				"radio1-2": "Ocorreu no período de tributação um dos factos previstos no art.º 268.º do CIRE?",
				"casoafirma": "Em caso afirmativo, indique:",
				"field3": "Mais-valias fiscais isentas nos termos do art.º 268.º, n.º 1",
				"field4": "Variações patrimoniais positivas isentas não refletidas no resultado líquido do período (art.º 268.º, n.ºs 1 e 2)",
				"field5": "Gastos ou perdas dedutíveis apurados pelo credor em resultado da redução de créditos (art.º 268.º, n.º 3)",
				"desc41": "Transmissão de benefícios fiscais da sociedade fundida ou cindida ou da sociedade contribuidora (art.º 75.º-A do CIRC)",
				"table": {
					"fields": {
						"codben": "Código do benefício",
						"montante": "Montante",
						"nifsoc": "NIF soc. fundida, cindida ou contribuídora"
					}
				}
			},
			"quadro05": {
				"title": "Soc. gestoras de participações sociais (SGPS), soc. de capital de risco (SCR) e investidores de capital de risco (ICR)",
				"field501": "Mais-valias não tributadas (ex-art.º 32.º, n.º 2 e ex-art.º 32.º-A, n.º 1 do EBF)",
				"field502": "Menos-valias fiscais não dedutíveis (ex-art.º 32.º, n.º 2 e ex-art.º 32.º-A, n.º 1 do EBF)"
			},
			"quadro06": {
				"title": "Entidades Licenciadas na Zona Franca da Madeira",
				"ano": "603.1 - Ano do investimento",
				"montante": "603.2 - Montante investido",
				"field601": "Data do licenciamento",
				"desc603": "Descriminação",
				"desc604": "Código NACE Rev. 1 (art.º 36.º, n.º 6 do EBF)",
				"desc605": "Código NACE Rev. 2 (art.º 36.º-A, n.º 7 do EBF)",
				"field602": "Número de postos de trabalho criados nos primeiros seis meses de atividade e mantidos no período",
				"nrpostocriadmantidos": "Número de postos de trabalho criados/mantidos:",
				"field606": "No início do período de tributação",
				"field607": "No final do período de tributação",
				"field603": "Investimento efetuado na aquisição de ativos fixos tangíveis e ativos intangíveis, nos dois primeiros anos de atividade",
				"desc06-1": "Apuramento do limite máximo aplicável aos benefícios fiscais relativos ao período (a preencher no caso de aplicação do regime do art.º 36.º-A do EBF)",
				"benefdif": "Benefício correspondente à diferença:",
				"field608": "Taxa de IRC (artigo 36.º-A, n.º1 do EBF)",
				"field609": "Derrama regional (artigo 36.º-A, n.º 12 do EBF)",
				"field610": "Derrama municipal (artigo 36.º-A, n.º 12 do EBF)",
				"field611": "Taxas de tributações autónomas (artigo 36.º-A, n.º 14 do EBF)",
				"field612": "Dedução de 50% da coleta do IRC (artigo 36.º-A, n.º 6 do EBF)",
				"field613": "Outros benefícios previstos (artigo 36.º-A, n.º 12 do EBF)",
				"field614": "TOTAL DOS BENEFÍCIOS FISCAIS (608 + 609 + 610 + 611 + 612 + 613)",
				"field615": "Valor acrescentado bruto no período e na Zona Franca da Madeira x 20,1% [art.º 36.-ºA, n.º 3, a) do EBF]",
				"field616": "Custos anuais de mão-de-obra incorridos na Zona Franca da Madeira x 30,1% [art.º 36.º-A, n.º 3, b) do EBF]",
				"field617": "Volume de negócios do período na Zona Franca da Madeira x 15,1% [art.º 36.º-A, n.º 3, c) do EBF]",
				"field618": "Excesso a regularizar (art.º 36.º-A, n.º 3 do EBF) (a transportar para o campo 372 do quadro 10 da declaração)"
			},
			"quadro07": {
				"title": "Deduções à coleta (a deduzir no campo 355 do quadro 10 da declaração)",
				"somacontrolo": "Soma de Controlo",
				"desc71": "Benefícios fiscais contratuais ao investimento (ex-art.º 41.º, n.º 1 do EBF, art.ºs 15.º a 21.º do CFI (revogado) e art.ºs 2.º a 21.º do CFI aprovado pelo Dec.-Lei n.º 162/2014, de 31/10 e art.ºs 2.º a 21.º do CFI na RAM aprovado pelo Dec. Leg. Regional n.º 24/2016/M, de 28/06",
				"table71": {
					"fields": {
						"field700": "NIF da soc. Individual (RETGS)",
						"field1": "Diploma",
						"field2": "Período a que respeita o benefício",
						"field3": "Saldo caducado",
						"field701": "Saldo não deduzido no período anterior",
						"field702": "Dotação do período",
						"field703": "Dedução do período",
						"field704": "Saldo que transita para período seguinte",
						"tot1": "Total - Saldo caducado",
						"tot2": "Total - Saldo não deduzido no período anterior",
						"tot3": "Total - Dotação do período",
						"tot4": "Total - Dedução do período",
						"tot5": "Total - Saldo que transita para período seguinte"
					}
				},
				"desc71A": "RETGS - INFORMAÇÃO ADICIONAL (a preencher pelas sociedades que integram o grupo) - utilização do benefício no âmbito do grupo",
				"table71A": {
					"fields": {
						"field1": "Diploma",
						"field2": "Período a que respeita o benefício",
						"field3": "Saldo caducado real na declaração do grupo",
						"field4": "Saldo não deduzido no período anterior à coleta do grupo",
						"field5": "Dotação do período na declaração do grupo",
						"field6": "Dedução utilizada na declaração de grupo",
						"field7": "Saldo que transita para período seguinte na declaração de grupo",
						"tot1": "Total - Saldo caducado real na declaração do grupo",
						"tot2": "Total - Saldo não deduzido no período anterior à coleta do grupo",
						"tot3": "Total - Dotação do período na declaração do grupo",
						"tot4": "Total - Dedução utilizada na declaração de grupo",
						"tot5": "Total - Saldo que transita para período seguinte na declaração de grupo"
					}
				},
				"desc72": "Projetos de investimento à internacionalização (ex-art.º 41.º, n.º 4 do EBF e art.º 22.º do CFI revogado pela Lei n.º 83-C/2013, de 31/12)",
				"field705": "Saldo não deduzido no período anterior",
				"field706": "Dotação do período",
				"field707": "Dedução do período",
				"field708": "Saldo que transita para período seguinte",
				"desc73": "SIFIDE - Sistema de incentivos fiscais em investigação e desenvolvimento empresarial (Lei n.º 40/2005, de 3/8) e SIFIDE II (art.º 133.º da Lei 55-A/2010, de 31/12, art.ºs 33.º a 40.º do CFI (revogado) e art.ºs 35.º a 42.º do CFI aprovado pelo Dec.-Lei n.º 162/2014, de 31/10 e art.ºs 35.º a 42.º do CFI na RAM aprovado pelo Dec. Leg. Regional n.º 24/2016/M, de 28/06",
				"table73": {
					"fields": {
						"field743": "NIF da soc. Individual (RETGS)",
						"field1": "Diploma",
						"field2": "Período a que respeita o benefício",
						"field3": "Saldo caducado",
						"field709": "Saldo não deduzido no período anterior",
						"field710": "Dotação do período",
						"field711": "Dedução do período",
						"field712": "Saldo que transita para período seguinte",
						"tot1": "Total - Saldo caducado",
						"tot2": "Total - Saldo não deduzido no período anterior",
						"tot3": "Total - Dotação do período",
						"tot4": "Total - Dedução do período",
						"tot5": "Total - Saldo que transita para período seguinte"
					}
				},
				"desc73A": "RETGS - INFORMAÇÃO ADICIONAL (a preencher pelas sociedades que integram o grupo) - utilização do benefício no âmbito do grupo",
				"table73A": {
					"fields": {
						"field1": "Diploma",
						"field2": "Período a que respeita o benefício",
						"field3": "Saldo caducado real na declaração do grupo",
						"field4": "Saldo não deduzido no período anterior à coleta do grupo",
						"field5": "Dotação do período na declaração do grupo",
						"field6": "Dedução utilizada na declaração de grupo",
						"field7": "Saldo que transita para período seguinte na declaração de grupo",
						"tot1": "Total - Saldo caducado real na declaração do grupo",
						"tot2": "Total - Saldo não deduzido no período anterior à coleta do grupo",
						"tot3": "Total - Dotação do período",
						"tot4": "Total - Dedução do período",
						"tot5": "Total - Saldo que transita para período seguinte"
					}
				},
				"desc74": "Regime fiscal de apoio ao investimento (Lei n.º 10/2009, de 10/3 (sucessivamente prorrogada), art.ºs 26º a 32º do CFI (revogado) e art.ºs 22.º a 26.º do CFI aprovado pelo Dec.-Lei n.º 162/2014, de 31/10 e art.ºs 22.º a 26.º do CFI na RAM aprovado pelo Dec. Leg. Regional n.º 24/2016/M, de 28/06",
				"table74": {
					"fields": {
						"field744": "NIF da soc. Individual (RETGS)",
						"field1": "Diploma",
						"field2": "Período a que respeita o benefício",
						"field3": "Saldo caducado",
						"field713": "Saldo não deduzido no período anterior",
						"field714": "Dotação do período",
						"field715": "Dedução do período",
						"field716": "Saldo que transita para período seguinte",
						"tot1": "Total - Saldo caducado",
						"tot2": "Total - Saldo não deduzido no período anterior",
						"tot3": "Total - Dotação do período",
						"tot4": "Total - Dedução do período",
						"tot5": "Total - Saldo que transita para período seguinte"
					}
				},
				"desc74A": "RETGS - INFORMAÇÃO ADICIONAL (a preencher pelas sociedades que integram o grupo) - utilização do benefício no âmbito do grupo",
				"table74A": {
					"fields": {
						"field1": "Diploma",
						"field2": "Período a que respeita o benefício",
						"field3": "Saldo caducado real na declaração do grupo",
						"field4": "Saldo não deduzido no período anterior à coleta do grupo",
						"field5": "Dotação do período na declaração do grupo",
						"field6": "Dedução utilizada na declaração de grupo",
						"field7": "Saldo que transita para período seguinte na declaração de grupo",
						"tot1": "Total - Saldo caducado real na declaração do grupo",
						"tot2": "Total - Saldo não deduzido no período anterior à coleta do grupo",
						"tot3": "Total - Dotação do período na declaração do grupo",
						"tot4": "Total - Dedução utilizada na declaração de grupo",
						"tot5": "Total - Saldo que transita para período seguinte na declaração de grupo"
					}
				},
				"desc76": "Crédito fiscal extraordinário ao investimento - CFEI II (artigo 16.º da Lei n.º 27-A/2020, de 24 de julho)",
				"table76": {
					"field1": "NIF da soc. Individual (RETGS)",
					"field2": "Período a que respeita o benefício",
					"field722": "Saldo não deduzido no período anterior",
					"field723": "Dotação do período",
					"field724": "Dedução do período",
					"field725": "Saldo que transita para período seguinte",
					"tot1": "Total - Saldo não deduzido no período anterior",
					"tot2": "Total - Dotação do período",
					"tot3": "Total - Dedução do período",
					"tot4": "Total - Saldo que transita para período seguinte"
				},
				"desc76A": "RETGS - INFORMAÇÃO ADICIONAL (a preencher por todas as sociedades que integram o grupo) - utilização do benefício no âmbito do grupo",
				"table76A": {
					"field1": "Período a que respeita o benefício",
					"field2": "Saldo não deduzido no período anterior à coleta do grupo",
					"field3": "Dotação do período na declaração do grupo",
					"field4": "Dedução utilizada na declaração de grupo",
					"field5": "Saldo que transita para período seguinte na declaração de grupo",
					"tot1": "Total - Saldo não deduzido no período anterior à coleta do grupo",
					"tot2": "Total - Dotação do período na declaração do grupo",
					"tot3": "Total - Dedução utilizada na declaração de grupo",
					"tot4": "Total - Saldo que transita para período seguinte na declaração de grupo"
				},
				"desc79": "IFPC - Incentivo fiscal à produção cinematográfica e audiovisual (Artigo 59.º-F do EBF e Portaria n.º 89.º-A/2017, de 19 de abril)",
				"table79": {
					"fields": {
						"field790": "Nº de identificação da obra",
						"field791": "Data do início da obra",
						"field792": "Data de conclusão da obra",
						"field793": "Saldo não deduzido no período anterior",
						"field794": "Valor do incentivo no período",
						"field795": "Dedução do período",
						"field796": "Saldo que transita para período seguinte",
						"field797": "Valor a reembolsar",
						"field798": "Valor a repôr (a transportar para o C.372 do Q.10 da M.22)",
						"tot1": "Total - Saldo não deduzido no período anterior",
						"tot2": "Total - Valor do incentivo no período",
						"tot3": "Total - Dedução do período",
						"tot4": "Total - Saldo que transita para período seguinte",
						"tot5": "Total - Valor a reembolsar",
						"tot6": "Total - Valor a repôr (a transportar para o C.372 do Q.10 da M.22)"
					}
				},
				"desc79A": "IFPC - Incentivo fiscal à produção cinematográfica e audiovisual - Encargos suportados com viaturas ligeiras de passageiros, viaturas ligeiras de mercadorias, motos e motociclos, excluídos de tributação autónoma nos termos do Art.º 59.º-H do EBF",
				"desc710": "Incentivo Fiscal à Recuperação - IFR (artigo 307.º da Lei n.º12/2022, de 27 de junho)",
				"field710A": "Montante das despesas de investimentos elegíveis no período",
				"field710B": "Montante da média aritmética simples das despesas de investimento elegíveis nos períodos de tributação anteriores (n.º 3 do artigo 3.º do anexo III)",
				"table710": {
					"fields": {
						"field71001": "NIF da soc. Individual (RETGS)",
						"field71002": "Saldo não deduzido no período anterior",
						"field71003": "Montante do benefício fiscal",
						"field71004": "Dedução do período",
						"field71005": "Saldo que transita para período seguinte",
						"tot1": "Total - Saldo não deduzido no período anterior",
						"tot2": "Total - Montante do benefício fiscal",
						"tot3": "Total - Dedução do período",
						"tot4": "Total - Saldo que transita para período seguinte"
					}
				},
				"desc710A": "RETGS - INFORMAÇÃO ADICIONAL (a preencher por todas as sociedades que integram o grupo) - utilização do benefício no âmbito do grupo",
				"table710A": {
					"fields": {
						"field71001A": "Saldo não deduzido no período anterior à coleta do grupo",
						"field71002A": "Montante do benefício fiscal",
						"field71003A": "Dedução do período",
						"field71004A": "Saldo que transita para período seguinte",
						"tot1": "Total - Saldo não deduzido no período anterior",
						"tot2": "Total - Montante do benefício fiscal",
						"tot3": "Total - Dedução do período",
						"tot4": "Total - Saldo que transita para período seguinte"
					}
				},
				"tipoviat": "Tipo de viaturas",
				"montencarg": "Montante de encargos",
				"field1": "Encargos com viaturas ligeiras de passageiros e de mercadorias - Se CA < € 27.500,00 [art.º 88.º , n.º 3, al. a)]",
				"field2": "Encargos com viaturas ligeiras de passageiros e de mercadorias - Se CA >= € 27.500,00 e < € 35.000,00 [art.º 88.º , n.º 3, al. b)]",
				"field3": "Encargos com viaturas ligeiras de passageiros e de mercadorias - Se CA >= € 35.000,00 [art.º 88.º , n.º 3, al. c)]",
				"field4": "Encargos com viaturas ligeiras de passageiros híbridas plug-in, cuja bateria possa ser carregada através de ligação à rede elétrica e que tenham uma autonomia mínima, no modo elétrico, de 50 km e emissões oficiais inferiores a 50 gCO(índice 2)/km, e de viaturas ligeiras de passageiros movidas a gás natural veicular (GNV) - Se CA < € 27.500,00 [art.º 88.º , n.º 3, al. a) e n.º 18]",
				"field5": "Encargos com viaturas ligeiras de passageiros híbridas plug-in, cuja bateria possa ser carregada através de ligação à rede elétrica e que tenham uma autonomia mínima, no modo elétrico, de 50 km e emissões oficiais inferiores a 50 gCO(índice 2)/km, e de viaturas ligeiras de passageiros movidas a gás natural veicular (GNV) - Se CA >= € 27.500,00 e < € 35.000,00 [art.º 88.º , n.º 3, al. b) e n.º 18]",
				"field6": "Encargos com viaturas ligeiras de passageiros híbridas plug-in, cuja bateria possa ser carregada através de ligação à rede elétrica e que tenham uma autonomia mínima, no modo elétrico, de 50 km e emissões oficiais inferiores a 50 gCO(índice 2)/km, e de viaturas ligeiras de passageiros movidas a gás natural veicular (GNV) - Se CA >= € 35.000,00 [art.º 88.º , n.º 3, al. c) e n.º 18]",
				"field7": "Encargos com viaturas ligeiras de passageiros movidas a GNV - Se CA < € 27.500,00 [art.º 88.º , n.º 3, al. a) e n.º 19]",
				"field8": "Encargos com viaturas ligeiras de passageiros movidas a GNV - Se CA >= € 27.500,00 e < € 35.000,00 [art.º 88.º , n.º 3, al. b) e n.º 19]",
				"field9": "Encargos com viaturas ligeiras de passageiros movidas a GNV - Se CA >= € 35.000,00 [art.º 88.º , n.º 3, al. c) e n.º 19]",
				"desc75": "Outras deduções à coleta",
				"normlegal": "Normativo legal",
				"dedefet": "Dedução efetuada",
				"field717": "Incentivos fiscais aos lucros reinvestidos na Região Autónoma da Madeira (Dec. Leg. Regional n.º 2/2009/M, de 22/1)",
				"field726": "Incentivos fiscais aos lucros reinvestidos na Região Autónoma dos Açores (art.º 6.º do Dec. Leg. Regional n.º 2/99/A. de 20/1)",
				"field718": "Entidades licenciadas na Zona Franca da Madeira (art.º 35.º, n.º 6 e 36º, n.º5 e 36.º-A, n.º 6 do EBF)",
				"field719": "Sociedades de capital de risco e investidores de capital de risco (art.º 32.º-A, n.º 4 do EBF)",
				"field727": "Dedução por lucros retidos e reinvestidos pelas PME (art.ºs 27.º a 34.º do CFI) aprovado pelo Dec.-Lei n.º 162/2014, de 31/10 e art.ºs 27.º a 34.º do CFI na RAM aprovado pelo Dec. Leg. Regional n.º 24/2016/M, de 28/06)",
				"field728": "Dedução de 50% à coleta pelas entidades licenciadas para operar na Zona Franca Industrial da Madeira (art.º 36.º-A, n.º 6 do EBF)",
				"field720": "",
				"field721": "TOTAL DAS DEDUÇÕES [(703 + 707 + 711 + 715 + 724 + 795 + 04(Q.0710) + 717 + 726 + 718 + 719 + 727 + 728 + 720)]",
				"desc77": "Transmissão de benefícios fiscais da sociedade fundida ou cindica ou da sociedade contribuídora (Art.º 75.º-A do CIRC)",
				"table77": {
					"fields": {
						"field729": "NIF sociedade fundida, cindida ou contribuidora",
						"field1": "Diploma",
						"field2": "Período a que respeita o benefício",
						"field730": "Saldo do benefício transmitido",
						"field731": "Dotação do período",
						"tot1": "Total - Saldo do benefício transmitido",
						"tot2": "Total - Dotação do período"
					}
				},
				"desc78": "Incentivos sujeitos às taxas máximas de auxílios regionais (CFI aprovado pelo Decreto-Lei n.º 162/2014, de 31 de outubro) (Para períodos de tributação de 2015 e 2016)",
				"table78": {
					"fields": {
						"field746": "Código do benefício",
						"field735": "Região elegível (art.º 43.º do CFI)",
						"field736": "Código CAE da atividade a que se destina o investimento (art.º 2.º da Port. n.º 282/2014, de 31/12)",
						"field737": "Montante das aplicações relevantes (art.ºs 11.º, 22.º e 30.º do CFI)",
						"incenfisc": "Incentivos: Fiscais",
						"field738": "IRC",
						"field739": "IMI, IMT e SELO",
						"field740": "Não Fiscais"
					}
				},
				"radio1-2": "Indique se se qualifica como microentidade nos termos previstos no Anexo ao Decreto-Lei n.º 372/2007, de 6 de novembro",
				"desc78A": "Incentivos sujeitos às taxas máximas de auxílios regionais (CFI aprovado pelo Decreto-Lei n.º 162/2014, de 31 de outubro) (Para períodos de tributação de 2017 e seguintes)",
				"desc78-A1": "Informação relativa a projetos de investimento de âmbito regional",
				"table78A1": {
					"fields": {
						"field782": "Nº Linha",
						"projinvinc": "Projeto de investimento/Incentivo",
						"field750": "Tipo",
						"field751": "Nº projeto/Código do incentivo",
						"field752": "Data de início do investimento",
						"field753": "Data de fim do investimento",
						"field754": "Tipologia de investimento",
						"field755": "Identificação oficial do incentivo financeiro",
						"appreleprev": "Aplicações relevantes previstas",
						"field756": "Região elegível",
						"field757": "Código CAE",
						"field758": "Montante total",
						"field759": "Montante total atualizado"
					}
				},
				"desc78-A2": "Incentivos financeiros usufruídos e fiscais utilizados - Valores do período de tributação",
				"table78A2": {
					"fields": {
						"field760": "Nº projeto/Código do incentivo",
						"apprelreali": "Aplicações relevantes realizadas",
						"field761": "Montante",
						"field762": "Montante atualizado",
						"financeiro": "Financeiro",
						"field763": "Montante usufruído",
						"field764": "Montante usufruído atualizado",
						"irc": "IRC",
						"field765": "Montante utilizado",
						"field766": "Montante atualizado",
						"imi": "IMI",
						"field767": "Montante utilizado",
						"field768": "Montante atualizado",
						"imt": "IMT",
						"field769": "Montante utilizado",
						"selo": "SELO",
						"field770": "Montante utilizado",
						"field771": "Montante total atualizado dos benefícios usufruídos/utilizados"
					}
				},
				"desc78-A3": "Incentivos financeiros usufruídos e fiscais utilizados - Valores atualizados acumulados",
				"table78A3": {
					"fields": {
						"field772": "Nº projeto/Código do incentivo",
						"apprelreali": "Aplicações relevantes realizadas",
						"field773": "Montante acumulado atualizado",
						"financeiro": "Financeiro",
						"field774": "Montante usufruído atualizado",
						"irc": "IRC",
						"field775": "Montante atualizado",
						"imi": "IMI",
						"field776": "Montante atualizado",
						"imt": "IMT",
						"field777": "Montante utilizado",
						"selo": "SELO",
						"field778": "Montante utilizado",
						"field779": "Montante total atualizado dos benefícios usufruídos/utilizados",
						"field780": "Intensidade de auxílio acumulada (em %)",
						"field781": "Montante a inscrever no campo 372 do Q. 10 da M. 22"
					}
				}
			},
			"quadro08": {
				"title": "Donativos (art.ºs 62.º, 62.º-A e 62.º-B do EBF)",
				"table": {
					"fields": {
						"tipdona": "Tipo donativo",
						"nif": "NIF da entidade donatária",
						"donativo": "Valor donativo"
					}
				}
			},
			"quadro09": {
				"title": "Incentivos Fiscais sujeitos à regra de minimis",
				"desc1": "Total dos Incentivos de anos anteriores (de natureza fiscal e não fiscal)",
				"field901": "N-2",
				"field902": "N-1",
				"desc2": "Incentivos do ano",
				"field903": "Incentivo de natureza não fiscal",
				"desc3": "Incentivos de natureza fiscal",
				"field904A": "Remuneração convencional do capital social (Lei n.º 55-A/2010, de 31/12 e art.º 41.º-A do EBF) x taxa do IRC",
				"field904B": "Redução da taxa do IRC aplicável às PME e empresa de pequena-média capitalização (Small Mid Cap), aos primeiros € 50.000,00 de matéria coletável (art.º 87.º, n.º 2 do CIRC)",
				"field904C": "Redução de taxa - Benefícios fiscais aplicáveis aos territórios do Interior (ex-art.º 43.º e art.º 41.º-B do EBF)",
				"field904D": "Despesas com projeto de investimento produtivo (art.º 18.º, n.º1, al. b) e n.º5 do CFI, revogado pelo Dec-Lei n.º 162/2014, de 31/10) x taxa do IRC",
				"field904E": "Derrama municipal (art.º 18.º, n.º 25 da Lei n.º73/2013, de 3 de setembro)",
				"field904F": "Majoração de 20% à dedução máxima por lucros retidos e reinvestidos (DLRR) pelas PME ( art.º 41.º-B, n.º 4 do EBF)",
				"field904G": "Majoração das despesas de investimento relacionadas com a promoção da internacionalização (art.º 400.º n.ºs 7 e 8 da Lei n.º 75.º-B/2020, de 31 de dezembro) x taxa do IRC",
				"field904H": "Majoração das despesas de investimento relacionadas com a promoção da internacionalização nos setores das pescas e da aquicultura (art.º 400.º n.º 9 da Lei n.º 75.º-B/2020, de 31 de dezembro) x taxa do IRC",
				"field904I": "Majoração das despesas de investimento relacionadas com a promoção da internacionalização no setor da produção agrícola primária (art.º 400.º n.º 10 da Lei n.º 75.º-B/2020, de 31 de dezembro) x taxa do IRC",
				"field904J": "Redução de taxa - Benefícios fiscais aplicáveis aos territórios do interior (artigo 19.º-A do Decreto Legislativo Regional n.º 28.º-A/2021/M, de 30 de dezembro (R. A. Madeira) e artigo 38.º do Decreto Legislativo Regional n.º 1/2023/A, de 5 de janeiro (R.A.Açores)",
				"field904K": "Majorações dos gastos referentes ao regime extraordinário de apoio a encargos suportados na produção agrícola x taxa do IRC",
				"field904": "Total dos incentivos do ano de natureza fiscal (904-A + ... + 904-K)",
				"field905": "Total dos incentivos do triénio (901 + 902 + 903 + 904)",
				"field906": "IRC a regularizar (a indicar no campo 372 do quadro 10 da declaração)",
				"desc4": "Identificação das empresas associadas (conceito de empresa única para efeitos do limite de minimis)",
				"table": {
					"field": "NIF"
				}
			},
			"quadro10": {
				"title": "Incentivos Fiscais à interioridade ligados ao investimento sujeitos às taxas máximas de auxílios regionais (ex-art.º 43.º do EBF) - a indicar no campo 372 do Q. 10 da declaração",
				"invelegiveis": "Investimentos elegíveis",
				"field1001": "Tangível",
				"field1002": "Intangível",
				"field1003": "TOTAL",
				"desc1": "Auxílios ao investimento",
				"field1004": "Redução dos encargos com a segurança social x (1 - taxa do IRC)",
				"majoracaodep": "Majoração das depreciações",
				"field1005": "Majoração",
				"field1006": "Taxa do IRC",
				"field1007": "Valor do auxílio",
				"majoracaoencsegsoc": "Majoração dos encargos com a segurança social",
				"field1008": "Majoração",
				"field1009": "Taxa do IRC",
				"field1010": "Valor do auxílio",
				"field1011": "Majoração do crédito fiscal ao investimento",
				"field1013": "TOTAL DOS AUXÍLIOS (1004 + 1007 + 1010 + 1011 + 1012)",
				"field1014": "Taxa de auxílio",
				"field1015": "Taxa máxima legal aplicável",
				"field1016": "EXCESSO A REGULARIZAR (a transportar para o campo 372 do quadro 10 da declaração)"
			},
			"quadro11": {
				"title": "Deduções à Matéria Coletável (a deduzir no campo 399 do quadro 09 da declaração)",
				"desc111": "Coletividades desportivas (art.º 54, n.º 2 do EBF)",
				"field1111": "Saldo não deduzido no período anterior",
				"field1112": "Dotação do período",
				"field1113": "Dedução do período",
				"field1114": "Saldo que transita para período(s) seguinte(s)"
			},
			"quadro11A": {
				"title": "Informação adicional relativa ao regime aplicável às entidades licenciadas na ZFM e aos auxílios de estado com finalidade regional",
				"desc": "Caso tenha empresa(s) parceira(s) ou associada(s) tal como definida(s) nos n.ºs 2 e 3 do artigo 3.º do Anexo ao Decreto-Lei n.º 372/2007, de 6 de novembro, indique o(s) respetivo(s) NIF",
				"table": {
					"field": "NIF"
				}
			},
			"quadro12": {
				"title": "Regime Especial de Transmissibilidade de Prejuízos Fiscais Aplicável aos Adquirentes até 31 de Dezembro de 2020, de Participações Sociais de Entidades Consideradas Empresas em Dificuldade (Artigo 15.º da Lei n.º 27-A/2020, de 24 de julho)",
				"desc121": "Informação a comunicar pela sociedade adquirente",
				"table121": {
					"fields": {
						"field1": "NIF da empresa em dificuldade",
						"field2": "Data de aquisição da participação",
						"field3": "Percentagem média de detenção direta da empresa adquirente no capital com direito de voto da empresa em dificuldade",
						"field4": "Período de apuramento dos prejuízos fiscais vigentes transmitidos",
						"field5": "Prejuízos fiscais vigentes (saldo)",
						"field6": "Montante dos prejuízos fiscais vigentes transmitidos (Coluna 03 x coluna 05)"
					}
				},
				"desc122": "Informação a comunicar pela sociedade considerada empresa em dificuldade",
				"campo122": "Autorizo a transmissão dos prejuízos fiscais para a sociedade adquirente (n.º 3 do artigo 3.º do anexo IV da Lei n.º 27-A/2020, de 24 de julho)",
				"table122": {
					"fields": {
						"field1": "NIF da sociedade adquirente da participação social",
						"field2": "Percentagem média da participação direta da empresa adquirente no capital com direito de voto da empresa em dificuldade",
						"field3": "Período de apuramento dos prejuízos fiscais vigentes",
						"field4": "Prejuízos fiscais vigentes (saldo)",
						"field5": "Montante dos prejuízos fiscais vigentes transmitidos (alínea b do n.º 1 do art.º 3.º do anexo IV) (Coluna 02 x coluna 04)"
					}
				}
			}
		},
		"anexoE": {
			"quadro03": {
				"title": "Apuramento da matéria coletável",
				"rend": "Rendimentos",
				"matcole": "Matéria Coletável",
				"field1-16": "Vendas de mercadorias e produtos",
				"field2-17": "Prestações de serviços no âmbito de atividades de restauração e bebidas e de atividades hoteleiras e similares, com exceção daquelas que se desenvolvam no âmbito da atividade de exploração de estabelecimentos de alojamento local na modalidade de moradia ou apartamento",
				"field3-18": "Prestações de serviços no âmbito de atividades profissionais especificamente previstas na lista anexa ao CIRS",
				"field4-19": "Restantes prestações de serviços",
				"field5-20": "Subsídios à exploração",
				"table": {
					"desc": "Subsídios não destinados à exploração",
					"rend": "Rendimentos",
					"matcole": "Matéria coletável = [(6) x 0,30 x t]",
					"totrend": "Total - Rendimentos",
					"totmatcol": "Total - Matéria Coletável",
					"soma": "Soma de Controlo"
				},
				"field7-22": "Cessão ou utilização temporária da propriedade intelectual ou industrial",
				"field8-23": "Prestação de informações respeitantes a uma experiência adquirida no setor industrial, comercial ou científico",
				"field9-24": "Outros rendimentos de capitais",
				"field10-25": "Resultado positivo de rendimentos prediais",
				"field11-26": "Saldo positivo das mais-valias e menos-valias fiscais",
				"field12-27": "Restantes incrementos patrimoniais",
				"field13-28": "Valor de aquisição dos incrementos patrimoniais obtidos a título gratuito",
				"field32-33": "Rendimentos da exploração de estabelecimentos de alojamento local na modalidade de moradia ou apartamento (alínea h do n.º 1 do art.º 86.º-B)",
				"field35-36": "Rendimentos da exploração de estabelecimentos de alojamento local na modalidade de moradia ou apartamento, localizados em área de contenção (alínea g do n.º 1 do art.º 86.º-B)",
				"field14-29": "Ajustamento positivo nos termos do art.º 64.º, n.º 3, al. a) do CIRC (inventários)",
				"field15-30": "Ajustamento positivo nos termos do art.º 64.º, n.º 3, al. a) do CIRC (ativos fixos tangíveis)",
				"field37-38": "Rendimentos provenientes da mineração de criptoativos e outros rendimentos previstos na alínea e) do n.º 1 do art.º 86.º-B.",
				"field39-46": "Rendimentos relativos a criptoativos, excluindo os decorrentes da mineração, que não sejam considerados rendimentos de capitais, nem resultem do saldo positivo das mais e menos-valias e dos restantes incrementos patrimoniais (alínea i) do n.º 1 do art.º 86.º-B).",
				"field40": "TOTAL DOS RENDIMENTOS",
				"field41": "SUBTOTAL DA MATÉRIA COLETÁVEL (somatório dos campos 16 a 30 + 33)",
				"field34": "Majoração das contribuições financeiras dos proprietários e produtores florestais aderentes a uma zona de intervenção florestal destinadas ao fundo constituído pela respetiva entidade gestora (art.º 59.º-D, n.º 14 do EBF)",
				"field31": "Acréscimo por não reinvestimento (art.º 86.º-B, n.º 11 do CIRC)",
				"field42": "TOTAL DA MATÉRIA COLETÁVEL (Campos 41 + 31 - 34)",
				"field3142desc": "(a transportar para o campo 346 do quadro 09 da mod.22)",
				"tdesc": "t - Correspondente à taxa mínima de depreciação/amortização dos ativos subsidiados. Nos restantes casos, corresponde às percentagens de 5%, 10% ou 1/n.º de anos x 100%"
			},
			"quadro04": {
				"title": "Outras informações",
				"campo43": "Data em que iniciou atividade"
			}
		},
		"anexoF": {
			"quadro03": {
				"title": "Apuramento do lucro tributável (art.º 22.º, n.ºs 2 e 3 do EBF)",
				"field1": "RESULTADO LÍQUIDO DO PERÍODO",
				"acrescer": "A ACRESCER",
				"field2": "Menos-valias (realizadas ou potenciais) previstas no artigo 10.º do Código do IRS não dedutíveis",
				"field3": "Gastos decorrentes da aplicação do justo valor a instrumentos financeiros e imóveis não dedutíveis",
				"field4": "Perdas decorrentes de variações cambiais não dedutíveis",
				"field5": "Outros gastos e perdas associados à obtenção de rendimentos excluídos de tributação",
				"field6": "Gastos ou perdas não dedutíveis nos termos do artigo 23.º-A do CIRC",
				"field19": "Gastos com comissões de gestão e outras",
				"field7": "",
				"field8": "SOMA (campos 2 a 7 + 19)",
				"deduzir": "A DEDUZIR",
				"field9": "Rendimentos de capitais previstos no artigo 5.º do Código do IRS excluídos de tributação",
				"field10": "Rendimentos prediais previstos no artigo 8.º do Código do IRS excluídos de tributação",
				"field11": "Mais-valias (realizadas ou potenciais) previstas no artigo 10.º do Código do IRS excluídas de tributação",
				"field12": "Rendimentos decorrentes da aplicação do justo valor a instrumentos financeiros e imóveis excluídos de tributação",
				"field13": "Ganhos decorrentes de variações cambiais excluídos de tributação",
				"field14": "Rendimentos com comissões de gestão e outras",
				"field15": "",
				"field16": "SOMA (campos 9 a 15)",
				"field17": "PREJUÍZO PARA EFEITOS FISCAIS [se (1+8-16) < 0]",
				"field18": "LUCRO TRIBUTÁVEL [se (1+8-16) >= 0]"
			},
			"quadro04": {
				"title": "Apuramento da matéria coletável",
				"field1": "PREJUÍZO FISCAL (transporte do campo 17 do Q. 03)",
				"field2": "LUCRO TRIBUTÁVEL (transporte do campo 18 do Q. 03)",
				"field3": "Prejuízos fiscais dedutíveis (art.º 22.º, n.º 4 do EBF)",
				"field4": "PREJUÍZOS FISCAIS DEDUZIDOS",
				"field5": "MATÉRIA COLETÁVEL (2-4)"
			},
			"quadro05": {
				"title": "Apuramento da coleta",
				"field1": "Imposto à taxa normal (art.º 22.º, n.º 5 do EBF) (campo 5 do Q.04) x 21%",
				"desc": "Regime transitório (art.º 7.º do DL n.º 7/2015, de 13 de janeiro):",
				"field2": "Imposto relativo ao saldo positivo entre as mais-valias e menos-valias de imóveis (transporte do campo 10 do quadro 06-A)",
				"field3": "Imposto relativo a mais-valias de outros elementos patrimoniais (transporte do campo 6 do quadro 06-B)",
				"field4": "COLETA (soma campos 1 a 3) (a transportar para o C347-B, C350 ou C370 do Q. 10 da Mod. 22)"
			},
			"quadro06": {
				"title": "Mais-valias realizadas abrangidas pelo regime transitório previsto no art.º 7.º, n.º 6, do DL n.º 7/2015, de 13/01)",
				"descA": "Mais-valias e menos-valias resultantes da alienação de imóveis adquiridos na vigência da anterior redação do art.º 22º do EBF",
				"descB": "Mais-valias resultantes da alienação de outros elementos patrimoniais",
				"tableA": {
					"fields": {
						"identmatimoveis": "Identificação Matricial dos Imóveis",
						"codfreg": "Código da freguesia",
						"tipo": "Tipo",
						"artigo": "Artigo",
						"faccaoseccao": "Fração/Secção",
						"dataaqui": "Data de aquisição (ano/mês/dia)",
						"dataalienacao": "Data de alienação (ano/mês/dia)",
						"montantemaismenosvalia": "Montante da mais-valia e menos-valia realizada",
						"maismenosvaiaabrangida": "Mais-valia e menos-valia abrangida pelo regime transitório"
					}
				},
				"tableB": {
					"fields": {
						"designacao": "Designação",
						"dataaqui": "Data de aquisição (ano/mês/dia)",
						"dataalienacao": "Data de alienação (ano/mês/dia)",
						"montantemaisvalia": "Montante da mais valia apurada por referência à data de 2015-06-30",
						"impcorrespondente": "Imposto correspondente"
					}
				},
				"field9": "SALDO",
				"field10": "Imposto correspondente ao saldo positivo entre as mais-valias e as menos-valias realizadas (a transportar para o C2 do Q.05) [Saldo do C9 x 50%] x 25%",
				"field6": "SOMA (a transportar para o campo 3 do Q.05)"
			}
		},
		"anexoG": {
			"quadro03": {
				"title": "Prejuízos fiscais apurados antes do regime especial dedutíveis ao regime geral (art.º 7.º, n.º 2 do Anexo ao DL 92/2018, de 13 de novembro)",
				"field1": "Volume de negócios das atividades previstas (art.º 3.º, n.º 1 do Anexo ao DL) e exercidas por navios/embarcações elegíveis (art.º 4.º do Anexo ao DL)",
				"field2": "Volume de negócios das atividades não previstas ou exercidas por navios/embarcações não elegíveis",
				"field3": "Rácio a aplicar no período aos prejuízos fiscais dedutíveis apurados antes do regime especial: (Volume de negócios das atividades não previstas ou exercidas por navios/embarcações não elegíveis / Volume de negócios total )",
				"table": {
					"fields": {
						"perio": "Período a que respeita o prejuízo",
						"montprejregespecial": "Montante dos prejuízos fiscais dedutíveis antes da entrada no regime especial",
						"montprejperio": "Montante dos prejuízos fiscais deduzidos no período",
						"saldo": "Saldo que transita para período(s) seguinte(s)"
					}
				},
				"field10": "Montante a adicionar no campo 309 do quadro 09 da declaração"
			},
			"quadro04": {
				"title": "Apuramento da matéria coletável - Regime especial",
				"desc": "Navios / Embarcações",
				"table": {
					"fields": {
						"field1": "Nº de identificação (IMO)",
						"field2": "País de Registo (art.º 9.º do DL)",
						"field3": "Regime de exploração do navio / embarcação (art.º 4.º, n.º 8 do Anexo ao DL)",
						"field4": "País de gestão estratégica e comercial (art.º 4.º, n.º 2, alínea b) do Anexo ao DL)",
						"field5": "Percentagem de tripulantes elegíveis (art.º 3.º, n.º 3 do DL)",
						"field6": "Arqueação líquida (art.º 5.º do Anexo ao DL)",
						"field7": "Percentagem dos rendimentos das atividades auxiliares no total do rendimento (art.º 3.º, n.º 3 do Anexo ao DL)",
						"field8": "Número de dias (art.º 5.º do Anexo ao DL)",
						"field9": "Redução Matéria coletável (art.º 5.º, n.º 6 do Anexo ao DL)",
						"field10": "Matéria coletável (art.º 5.º do Anexo ao DL)"
					}
				},
				"field11": "Matéria Coletável apurada (a transportar para o campo 300 do quadro 09 da declaração)"
			},
			"quadro05": {
				"title": "Informações adicionais",
				"desc": "No caso de afretamento a terceiros, indique:",
				"field1": "A tonelagem líquida dos navios/embarcações tomados em regime de afretamento",
				"field2": "A tonelagem líquida da totalidade da frota (art.º 4.º, n.º 8 do Anexo ao DL)",
				"field3": "O rendimento dos navios/embarcações tomados em regime de afretamento",
				"field4": "O rendimento dos restantes navios/embarcações da sua propriedade ou equiparados (art.º 4.º, n.º 8 do Anexo ao DL)"
			},
			"quadro06": {
				"title": "Outras informações",
				"field1": "Data em que iniciou a atividade em IRC (art.º 5.º, n.º 4 do Anexo ao DL)",
				"field2-3": "Houve cessação de IVA há menos de cinco anos (art.º 5.º, n.º 5 do Anexo ao DL)",
				"field2": "Sim",
				"field3": "Não"
			}
		},
		"modal": {
			"prejuizos": "Prejuízos",
			"nif": "NIF",
			"periodo": "Período",
			"montante": "Montante",
			"valPerio": "Valor utilizado no período",
			"retFonte": "Retenção na fonte",
			"rosto": {
				"titles": {
					"309": "Regime geral",
					"320": "Redução de taxa",
					"331": "Isenção",
					"396": "Prejuízos individuais deduzidos, verificados em períodos anteriores ao início da aplicação do regime",
					"398": "Quotas-partes dos prejuízos fiscais deduzidas em caso de aquisição de grupos de sociedades (art.º 71.º, n.ºs 4 e 5)",
					"397A": "Valor utilizado no período [art.º 15.º, n.º 1 al. c) e art.º 75.º, n.º 5]",
					"397B": "Valor utilizado no período (art.º 75.º, n.ºs 1 e 3)",
					"309B": "Regime especial aplicável aos adquirentes de entidades consideradas empresas em dificuldade",
					"quadro12List": "Retenções na fonte",
					"quadro14": "Crédito de imposto por dupla tributação jurídica internacional (CIDTJI)"
				},
				"contasdef": {
					"title": "Definição das contas para os campos",
					"campo": "Campo {{campo}}",
					"nContaDe": "Nº Conta de",
					"perio": "Período até",
					"deznormal": "Dezembro normal",
					"primeiroence": "1º encerramento",
					"segence": "2º encerramento",
					"sinal": "Sinal",
					"positivo": "Positivo",
					"negativo": "Negativo",
					"addline": "Adicionar Linha",
					"table": {
						"nContaDe": "Nº Conta de",
						"nContaAte": "Nº Conta até",
						"perioAte": "Período até",
						"sinal": "Positivo",
						"valor": "Valor"
					}
				},
				"contasdefall": {
					"title": "Contas definidas para os campos",
					"table": {
						"campo": "Campo"
					},
					"btn": {
						"copiarparaempresas": "Copiar para outras empresas",
						"reporcontas": "Repor configurações"
					}
				},
				"contasdefcopy": {
					"title": "Copiar contas definidas para os campos para outra empresa",
					"checkbox": {
						"deletedestino": "Apaga as configurações existentes na empresa destino"
					}
				}
			},
			"anexoa": {
				"titles": {
					"A": "Critério Geral",
					"B": "Critério geral - Zona Franca da Madeira (Art.º 36.º -A, n.º 12 do EBF)",
					"C": "Critério específico - centros eletroprodutores",
					"D": "Critério específico - Minas"
				},
				"distmuni": "Código do distrito / município",
				"taxaderra": "Taxa de derrama",
				"massasalA": "Massa salarial do município",
				"massasalB": "Massa salarial do município na ZFM",
				"massasalC": "Massa salarial + prestações de serviços do município (MSPSMunic)",
				"massasalD": "Massa salarial + prestações de serviços do município (MSPSMunic)",
				"racioA": "Rácio de repartição (4) = (3) / (Q.03, C3)",
				"racioB": "Rácio de repartição (4) = (3) / (Q.03, C3)",
				"racioC": "Rácio município",
				"racioD": "Rácio município",
				"areainstexpl": "Área de instal. ou explor. no município (AIMunic)",
				"totalProduzido": "Total da eletric. produzida no município (EPMunic)",
				"producao": "Produção à boca da mina no município (PBMunic)",
				"derramaA": "DERRAMA CALCULADA (5) = (Q.03, C1 x (2) x (4)) ou (Q.03, C10) x (2) x (4)",
				"derramaB": "Derrama calculada (5) = [(Q.03, C2 x (2) x (4))] x 0,2",
				"derramaC": "Derrama calculada (8) = (Q03 C1) x (2) x (7)",
				"derramaD": "Derrama calculada (7) = (Q03 C1) x (2) x (6)",
				"primeiroAno": "Indique se é o 1.º ano de aplicação do critério",
				"potencia": "Potência instalada no município"
			},
			"anexod": {
				"titles": {
					"71": "Benefícios fiscais contratuais ao investimento (ex-art.º 41.º, n.º 1 do EBF, art.ºs 15.º a 21.º do CFI (revogado) e art.ºs 2.º a 21.º do CFI aprovado pelo Dec.-Lei n.º 162/2014, de 31/10 e art.ºs 2.º a 21.º do CFI na RAM aprovado pelo Dec. Leg. Regional n.º 24/2016/M, de 28/06",
					"73": "SIFIDE - Sistema de incentivos fiscais em investigação e desenvolvimento empresarial (Lei n.º 40/2005, de 3/8) e SIFIDE II (art.º 133.º da Lei 55-A/2010, de 31/12, art.ºs 33.º a 40.º do CFI (revogado) e art.ºs 35.º a 42.º do CFI aprovado pelo Dec.-Lei n.º 162/2014, de 31/10 e art.ºs 35.º a 42.º do CFI na RAM aprovado pelo Dec. Leg. Regional n.º 24/2016/M, de 28/06",
					"74": "Regime fiscal de apoio ao investimento (Lei n.º 10/2009, de 10/3 (sucessivamente prorrogada), art.ºs 26º a 32º do CFI (revogado) e art.ºs 22.º a 26.º do CFI aprovado pelo Dec.-Lei n.º 162/2014, de 31/10 e art.ºs 22.º a 26.º do CFI na RAM aprovado pelo Dec. Leg. Regional n.º 24/2016/M, de 28/06",
					"77": "Transmissão de benefícios fiscais da sociedade fundida ou cindica ou da sociedade contribuídora (Art.º 75.º-A do CIRC)",
					"78": "Incentivos sujeitos às taxas máximas de auxílios regionais (CFI aprovado pelo Decreto-Lei n.º 162/2014, de 31 de outubro) (Para períodos de tributação de 2015 e 2016)",
					"79": "IFPC - Incentivo fiscal à produção cinematográfica e audiovisual (Artigo 59.º-F do EBF e Portaria n.º 89.º-A/2017, de 19 de abril)",
					"121": "Informação a comunicar pela sociedade adquirente",
					"122": "Informação a comunicar pela sociedade considerada empresa em dificuldade",
					"604": "Código NACE Rev. 1 (art.º 36.º, n.º 6 do EBF)",
					"605": "Código NACE Rev. 2 (art.º 36.º-A, n.º 7 do EBF)",
					"710": "Incentivo Fiscal à Recuperação - IFR (artigo 307.º da Lei n.º12/2022, de 27 de junho)",
					"31A": "Fundos de pensões e equiparáveis (art.º 16.º, n.º 1 do EBF) e outros fundos isentos definitivamente",
					"31B": "Outras isenções definitivas",
					"32A": "Fundos de poupança em ações (art.º 26.º do EBF) e outros fundos isentos temporariamente",
					"32B": "Outras isenções temporárias",
					"04A": "Outras deduções ao rendimento",
					"041": "Transmissão de benefícios fiscais da sociedade fundida ou cindida ou da sociedade contribuidora (art.º 75.º-A do CIRC)",
					"quadro8": "Donativos (art.ºs 62.º, 62.º-A e 62.º-B do EBF)",
					"quadro9": "Identificação das empresas associadas (conceito de empresa única para efeitos do limite de minimis)",
					"quadro11A": "Empresa(s) parceira(s) ou associada(s) tal como definida(s) nos n.ºs 2 e 3 do artigo 3.º do Anexo ao Decreto-Lei n.º 372/2007, de 6 de novembro, indique o(s) respetivo(s) NIF",
					"71A": "RETGS - INFORMAÇÃO ADICIONAL (a preencher pelas sociedades que integram o grupo) - utilização do benefício no âmbito do grupo",
					"73A": "RETGS - INFORMAÇÃO ADICIONAL (a preencher pelas sociedades que integram o grupo) - utilização do benefício no âmbito do grupo",
					"74A": "RETGS - INFORMAÇÃO ADICIONAL (a preencher pelas sociedades que integram o grupo) - utilização do benefício no âmbito do grupo",
					"710A": "RETGS - INFORMAÇÃO ADICIONAL (a preencher por todas as sociedades que integram o grupo) - utilização do benefício no âmbito do grupo",
					"78A1": "Informação relativa a projetos de investimento de âmbito regional",
					"78A2": "Incentivos financeiros usufruídos e fiscais utilizados - Valores do período de tributação",
					"78A3": "Incentivos financeiros usufruídos e fiscais utilizados - Valores atualizados acumulados"
				},
				"quadro7": {
					"nif71": "NIF da soc. Individual (RETGS)",
					"nif73": "NIF da soc. Individual (RETGS)",
					"nif74": "NIF da soc. Individual (RETGS)",
					"nif76": "NIF da soc. Individual (RETGS)",
					"nif77": "NIF sociedade fundida, cindida ou contribuidora",
					"nIdentificacao": "Nº de identificação da obra",
					"diploma": "Diploma",
					"periodo": "Período a que respeita o benefício",
					"saldoCaducado71": "Saldo caducado",
					"saldoCaducado73": "Saldo caducado",
					"saldoCaducado74": "Saldo caducado",
					"saldoCaducado71A": "Saldo caducado real na declaração do grupo",
					"saldoCaducado73A": "Saldo caducado real na declaração do grupo",
					"saldoCaducado74A": "Saldo caducado real na declaração do grupo",
					"saldoNaoDeduzido71": "Saldo não deduzido no período anterior",
					"saldoNaoDeduzido73": "Saldo não deduzido no período anterior",
					"saldoNaoDeduzido74": "Saldo não deduzido no período anterior",
					"saldoNaoDeduzido76": "Saldo não deduzido no período anterior",
					"saldoNaoDeduzido71A": "Saldo não deduzido no período anterior à coleta do grupo",
					"saldoNaoDeduzido73A": "Saldo não deduzido no período anterior à coleta do grupo",
					"saldoNaoDeduzido74A": "Saldo não deduzido no período anterior à coleta do grupo",
					"saldoNaoDeduzido76A": "Saldo não deduzido no período anterior à coleta do grupo",
					"saldoNaoDeduzido79": "Saldo não deduzido no período anterior",
					"dotacaoDoPeriodo71": "Dotação do período",
					"dotacaoDoPeriodo73": "Dotação do período",
					"dotacaoDoPeriodo74": "Dotação do período",
					"dotacaoDoPeriodo76": "Dotação do período",
					"dotacaoDoPeriodo71A": "Dotação do período na declaração do grupo",
					"dotacaoDoPeriodo73A": "Dotação do período na declaração do grupo",
					"dotacaoDoPeriodo74A": "Dotação do período na declaração do grupo",
					"dotacaoDoPeriodo76A": "Dotação do período na declaração do grupo",
					"deducaoNoPeriodo": "Dedução do período",
					"deducaoUtilizada": "Dedução utilizada na declaração de grupo",
					"saldoQueTransita71": "Saldo que transita para período seguinte",
					"saldoQueTransita73": "Saldo que transita para período seguinte",
					"saldoQueTransita74": "Saldo que transita para período seguinte",
					"saldoQueTransita76": "Saldo que transita para período seguinte",
					"saldoQueTransita71A": "Saldo que transita para período seguinte na declaração de grupo",
					"saldoQueTransita73A": "Saldo que transita para período seguinte na declaração de grupo",
					"saldoQueTransita74A": "Saldo que transita para período seguinte na declaração de grupo",
					"saldoQueTransita76A": "Saldo que transita para período seguinte na declaração de grupo",
					"saldoQueTransita79": "Saldo que transita para período seguinte",
					"dataInicio": "Data do início da obra",
					"dataConclusao": "Data de conclusão da obra",
					"valorIncentivo": "Valor do incentivo no período",
					"deducaoDoPeriodo": "Dedução do período",
					"valorAReembolsar": "Valor a reembolsar",
					"valorARepor": "Valor a repôr (a transportar para o C.372 do Q.10 da M.22)",
					"saldo": "Saldo do benefício transmitido",
					"dotacao": "Dotação do período",
					"montanteBeneficio": "Montante benefício fiscal",
					"dsdiploma": {
						"1": "Estatuto dos Benefícios Fiscais (ex-art.º 41.º) e Decreto-Lei n.º 409/99, de 15 de outubro",
						"2": "Código Fiscal do Investimento, aprovado pelo Decreto-Lei n.º 249/2009, de 23 de setembro, alterado pela Lei n.º 20/2012, de 14 de maio, pelo Decreto-Lei n.º 82/2013, de 17 de junho, e pela Lei n.º 83-C/2013, de 31 de dezembro e revogado pelo Decreto-Lei n.º 162/2014, de 31 de outubro",
						"3": "Código Fiscal do Investimento, aprovado pelo Decreto-Lei n.º 162/2014, de 31 de outubro",
						"4": "Decreto Legislativo Regional n.º 18/99/M, de 28 de junho",
						"5": "Decreto Legislativo Regional n.º 2/99/A, de 20 de janeiro (art.º 9.º)",
						"6": "Código Fiscal do Investimento na RAM aprovado pelo Decreto Legislativo Regional n.º 24/2016/M, de 28 de junho",
						"720": "720",
						"724": "Crédito fiscal extraordinário ao investimento (Lei n.º 49/2013, de 16 de Julho)",
						"731": "Lei n.º 40/2005, de 3 de agosto",
						"732": "Código Fiscal do Investimento, aprovado pelo Decreto-Lei n.º 249/2009, de 23 de setembro e revogado pelo Decreto-Lei n.º 162/2014, de 31 de outubro",
						"733": "Código Fiscal do Investimento, aprovado pelo Decreto-Lei n.º 162/2014, de 31 de outubro",
						"734": "Código Fiscal do Investimento na R. A. da Madeira aprovado pelo Decreto Legislativo Regional n.º 24/2016/M, de 28 de junho",
						"741": "RFAI - Regime fiscal de apoio ao investimento [Lei n.º 10/2009, de 10 de março (sucessivamente prorrogada) e arts. 26.º a 32.º do Código Fiscal do Investimento (CFI), aprovado pelo Decreto-Lei n.º 249/2009, de 23 de setembro, e revogado pelo Decreto-Lei n.º 162/2014, de 31 de outubro]",
						"742": "RFAI - Regime fiscal de apoio ao investimento (arts. 22.º a 26.º do CFI aprovado pelo Decreto-Lei n.º 162/2014, de 31 de outubro)",
						"743": "RFAI - Regime fiscal de apoio ao investimento na Região Autónoma da Madeira -  Decreto Legislativo Regional n.º 24/2016/M, de 28 de junho)"
					},
					"tabelas78": {
						"regiao78": "Região elegível (art.º 43.º do CFI)",
						"regiao78A1": "Região elegível",
						"cae78": "Código CAE da atividade a que se destina o investimento (art.º 2.º da Port. n.º 282/2014, de 31/12)",
						"cae78A1": "Código CAE",
						"montanteDasAplicacoes": "Montante das aplicações relevantes (art.ºs 11.º, 22.º e 30.º do CFI)",
						"irc": "IRC",
						"imiIMTSelo": "IMI, IMT e SELO",
						"naoFiscais": "Não Fiscais",
						"numeroLinha": "Nº Linha",
						"numProjecto": "Nº projeto/Código do incentivo",
						"dataInicio": "Data de início do investimento",
						"dataFim": "Data de fim do investimento",
						"tipologia": "Tipologia de investimento",
						"identificacao": "Identificação oficial do incentivo financeiro",
						"montante": "Montante total",
						"montanteActualizado": "Montante total atualizado",
						"aplicacoesMontante78A2": "Aplicações relevantes realizadas - Montante",
						"aplicacoesMontante78A3": "Aplicações relevantes realizadas - Montante acumulado atualizado",
						"aplicacoesActualizado": "Montante atualizado",
						"financeiroUsufruido": "Financeiro - Montante usufruído",
						"financeiroActualizado": "Financeiro - Montante usufruído atualizado",
						"ircUtilizado": "IRC - Montante utilizado",
						"ircActualizado": "IRC - Montante atualizado",
						"imiUtilizado": "IMI - Montante utilizado",
						"imiActualizado": "IMI - Montante atualizado",
						"imtUtilizado": "IMT - Montante utilizado",
						"seloUtilizado": "SELO - Montante utilizado",
						"financeiroMontante": "Financeiro - Montante usufruído atualizado",
						"ircMontante": "IRC - Montante atualizado",
						"imiMontante": "IMI - Montante atualizado",
						"imtMontante": "IMT - Montante utilizado",
						"seloMontante": "SELO - Montante utilizado",
						"intensidade": "Intensidade de auxílio acumulada (em %)",
						"montanteAInscrever": "Montante a inscrever no campo 372 do Q. 10 da M. 22",
						"montanteTotal": "Montante total atualizado dos benefícios usufruídos/utilizados",
						"tipo": "Tipo",
						"datasources": {
							"beneficio": {
								"71": "Benefícios fiscais contratuais ao investimento (ex-art.º 41.º, n.º 1 do EBF, art.ºs 15.º a 21.º do CFI (revogado), art.ºs 2.º a 21.º do CFI aprovado pelo Dec.-Lei n.º 162/2014, de 31/10 e e art.ºs 2.º a 21.º do CFI na RAM aprovado pelo Dec. Leg. Regional n.º 24/2016/M, de 28/06)",
								"727": "DLRR - Dedução por lucros retidos e reinvestidos pelas PME (art.ºs 27.º a 34.º do CFI aprovado pelo Dec.-Lei n.º 162/2014, de 31/10 e art.ºs 27.º a 34.º do CFI na RAM aprovado pelo Dec. Leg. Regional n.º 24/2016/M, de 28/06)",
								"741": "RFAI - Regime fiscal de apoio ao investimento [Lei n.º 10/2009, de 10 de março (sucessivamente prorrogada) e arts. 26.º a 32.º do Código Fiscal do Investimento (CFI), aprovado pelo Decreto-Lei n.º 249/2009, de 23 de setembro, e revogado pelo Decreto-Lei n.º 162/2014, de 31 de outubro]",
								"742": "RFAI - Regime fiscal de apoio ao investimento (arts. 22.º a 26.º do CFI aprovado pelo Decreto-Lei n.º 162/2014, de 31 de outubro)",
								"743": "RFAI - Regime fiscal de apoio ao investimento na Região Autónoma da Madeira -  Decreto Legislativo Regional n.º 24/2016/M, de 28 de junho)"
							},
							"regiao78": {
								"1": "Norte",
								"2": "Centro",
								"3": "Alentejo",
								"4": "Região Autónoma dos Açores",
								"5": "Região Autónoma da Madeira",
								"6": "Algarve",
								"7": "Mafra",
								"8": "Loures",
								"9": "Península de Setúbal",
								"10": "Vila Franca de Xira",
								"11": "S. João das Lampas e Terrugem (Sintra)"
							},
							"regiao78A1": {
								"PT111": "Norte - Alto Minho",
								"PT112": "Norte - Cávado",
								"PT119": "Norte - Ave",
								"PT11A": "Norte - Área Metropolitana do Porto",
								"PT11B": "Norte - Alto Tâmega",
								"PT11C": "Norte - Tâmega e Sousa",
								"PT11D": "Norte - Douro",
								"PT11E": "Norte - Terras de Trás-os-Montes",
								"PT16B": "Centro - Oeste",
								"PT16D": "Centro - Região de Aveiro",
								"PT16E": "Centro - Região de Coimbra",
								"PT16F": "Centro - Região de Leiria",
								"PT16G": "Centro - Viseu Dão Lafões",
								"PT16H": "Centro - Beira Baixa",
								"PT16I": "Centro - Médio Tejo",
								"PT16J": "Centro - Beiras e Serra da Estrela",
								"PT1109": "Grande Lisboa - Mafra",
								"PT1107": "Grande Lisboa - Loures",
								"PT1114": "Grande Lisboa - Vila Franca de Xira",
								"PT111127": "Grande Lisboa - S. João das Lampas e Terrugem (Sintra)",
								"PT172": "Peninsula de Setúbal",
								"PT181": "Alentejo - Alentejo Litoral",
								"PT184": "Alentejo - Baixo Alentejo",
								"PT185": "Alentejo - Lezíria do Tejo",
								"PT186": "Alentejo - Alto Alentejo",
								"PT187": "Alentejo - Alentejo Central",
								"PT150": "Algarve",
								"PT200": "Região Autónoma dos Açores",
								"PT300": "Região Autónoma da Madeira"
							},
							"tipo": {
								"D": "Distinto",
								"U": "Único"
							},
							"tipologia": {
								"001": "Criação de um novo estabelecimento",
								"002": "Aumento da capacidade de um estabelecimento já existente",
								"003": "Diversificação da produção de um estabelecimento no que se refere a produtos não fabricados anteriormente nesse estabelecimento",
								"004": "Alteração fundamental do processo de produção global de um estabelecimento existente"
							},
							"identificacao": {
								"10": "NORTE 2020",
								"11": "PDR 2020",
								"12": "PO SEUR",
								"13": "POAT 2020",
								"14": "PRODERAM 2020",
								"15": "PRORURAL+",
								"99": "OUTROS",
								"01": "AÇORES 2020",
								"02": "ALENTEJO 2020",
								"03": "CENTRO 2020",
								"04": "COMÉRCIO INVESTE",
								"05": "COMPETE 2020",
								"06": "CRESC ALGARVE 2020",
								"07": "LISBOA 2020",
								"08": "MADEIRA 14-20",
								"09": "MAR 2020"
							}
						}
					}
				},
				"codBeneficio": "Código do benefício",
				"montante": "Montante",
				"nif041": "NIF soc. fundida, cindida ou contribuídora",
				"nifquadro8": "NIF da entidade donatária",
				"nifquadro9": "907 - NIF",
				"nifquadro11A": "3 - NIF",
				"nif121": "Coluna NIF da empresa em dificuldade",
				"nif122": "Coluna NIF da sociedade adquirente da participação social",
				"nace604": "Código NACE Rev. 1 (art.º 36.º, n.º 6 do EBF)",
				"nace605": "Código NACE Rev. 2 (art.º 36.º-A, n.º 7 do EBF)",
				"valorDonativo": "Valor donativo",
				"tipoDonativo": "Tipo donativo",
				"ano603": "Ano do investimento",
				"percentagem": "Percentagem média",
				"periodo": "Período de apuramento",
				"prejuizosMontante": "Montante dos prejuízos",
				"prejuizosSaldo": "Prejuízos fiscais vigentes"
			},
			"anexoe": {
				"title": "Subsídios não destinados à exploração",
				"rendimento": "Rendimento",
				"materiacol": "Matéria coletável = [(6) x 0,30 x t]"
			},
			"anexof": {
				"titles": {
					"A": "Mais-valias e menos-valias resultantes da alienação de imóveis adquiridos na vigência da anterior redação do art.º 22º do EBF",
					"B": "Mais-valias resultantes da alienação de outros elementos patrimoniais"
				},
				"identificacao": "Identificação Matricial dos Imóveis",
				"codFreguesia": "Código da freguesia",
				"tipo": "Tipo",
				"artigo": "Artigo",
				"fraccao": "Fração/Secção",
				"dataAquisicao": "Data de aquisição",
				"dataAlienacao": "Data de alienação",
				"montanteA": "Montante da mais-valia e menos-valia realizada",
				"montanteB": "Montante da mais-valia apurada por referência à data de 2015-06-30",
				"montanteRegimeTransitorio": "Mais-valia e menos-valia abrangida pelo regime transitório",
				"designacao": "Designação",
				"imposto": "Imposto correspondente"
			},
			"anexog": {
				"titles": {
					"quadro3": "",
					"quadro4": ""
				},
				"periodo": "Período a que respeita o prejuízo",
				"montanteDedutiveis": "Montante dos prejuízos fiscais dedutíveis antes da entrada no regime especial",
				"montanteDeduzidos": "Montante dos prejuízos fiscais deduzidos no período",
				"saldo": "Saldo que transita para período(s) seguinte(s)",
				"nIdentificacao": "Nº de identificação (IMO)",
				"paisDeRegisto": "País de Registo (art.º 9.º do DL)",
				"regime": "Regime de exploração do navio / embarcação (art.º 4.º, n.º 8 do Anexo ao DL)",
				"paisDeGestao": "País de gestão estratégica e comercial (art.º 4.º, n.º 2, alínea b) do Anexo ao DL)",
				"percTripulantes": "Percentagem de tripulantes elegíveis (art.º 3.º, n.º 3 do DL)",
				"arqueacao": "Arqueação líquida (art.º 5.º do Anexo ao DL)",
				"percRendimentos": "Percentagem dos rendimentos das atividades auxiliares no total do rendimento (art.º 3.º, n.º 3 do Anexo ao DL)",
				"numeroDias": "Número de dias (art.º 5.º do Anexo ao DL)",
				"reducaoMateria": "Redução Matéria coletável (art.º 5.º, n.º 6 do Anexo ao DL)",
				"materiaColectavel": "Matéria coletável (art.º 5.º do Anexo ao DL)"
			},
			"prejuizosfiscais": {
				"title": "Prejuízos Fiscais",
				"anoprej": "Ano do prejuízo",
				"valprej": "Valor do prejuízo fiscal",
				"anoded": "Ano da dedução",
				"valded": "Valor da dedução",
				"adicionarprej": "Adicionar prejuízo",
				"adicionarded": "Adicionar dedução",
				"table": {
					"ano": "Ano do prejuízo",
					"valorPrejuizoFiscal": "Valor do prejuízo",
					"valorDeduzido": "Valor deduzido",
					"valorPorDeduzir": "Valor por deduzir",
					"anoDeducao": "Ano da dedução",
					"valorDeducao": "Valor da dedução"
				}
			},
			"derrama": {
				"title": "Dados adicionais para cálculo da derrama",
				"concelho": "Concelho: {{cod}} - {{name}}",
				"ano": "Ano: {{ano}}",
				"postosTrabalhoCriados": "Nº postos trabalho criados",
				"volumeNegocios": "Vol. de negócios no ano anterior",
				"investimentoNoAno": "Investimento no ano",
				"temSedeNoConcelho": "Empresa tem sede no concelho",
				"anoFixacaoDaSede": "Ano de fixação da sede",
				"microEmpresa": "Micro empresa",
				"pequenaEmpresa": "Pequena empresa",
				"mediaEmpresa": "Média empresa",
				"acrescimoTrabalhadores": "Acréscimo nº trabalhadores (%)",
				"postosTrabalhoCriados2019": "Nº postos trabalho criados 2019",
				"postosTrabalhoCriados2020": "Nº postos trabalho criados 2020"
			},
			"configws": {
				"title": "Configurações do WebService",
				"username": "Username do contribuinte",
				"password": "Password do contribuinte",
				"servicoInvCC": "Serviço invocado por Contabilista Certificado (CC)",
				"ccPowers": "CC com plenos poderes declarativos para o Contrib.",
				"usernameCC": "Username do CC",
				"passwordCC": "Password do CC",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> O serviço já se encontra configurado.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> O serviço não está configurado.",
				"configsaved": "Configuração guardada com sucesso."
			}
		},
		"datasources": {
			"tiporend": {
				"lucrosestav": "Lucros de estab. estáveis",
				"outrosrend": "Outros rendimentos"
			},
			"beneficio": {
				"100": "Regime de interioridade - art.º 43.º do EBF - Regime transitório",
				"119": "Outras deduções ao rendimento",
				"120": "Concessionária da Zona Franca da Madeira - Isenção até 2017 (art.º 33.º, n.º 12 do EBF)",
				"121": "Lucros derivados das obras e trabalhos na Base das Lajes e instalações de apoio - artigo XI do Anexo I do Acordo Técnico, aprovado pela Resolução da Assembleia da República 38/95, de 11 de outubro - Acordo de Cooperação e Defesa entre a República Portuguesa e os EUA",
				"122": "Decreto-Lei n.º 43 335/1960 de 19/11",
				"129": "Outras isenções temporárias",
				"140": "Fundos de pensões e equiparáveis (art.º 16.º, n.º 1 do EBF)",
				"141": "Fundos de poupança-reforma, poupança-educação e poupança-reforma/educação (art.º 21.º, n.º 1 do EBF)",
				"142": "Fundos de capital de risco (art.º 23.º do EBF)",
				"143": "Fundos de investimento imobiliário em recursos florestais (art.º 24.º, n.º 1 do EBF)",
				"149": "Outros fundos isentos definitivamente",
				"150": "Fundos de poupança em ações (art.º 26.º, n.º 1 do EBF)",
				"151": "Fundos de investimento imobiliário - reabilitação urbana (art.º 71.º, n.º 1 do EBF)",
				"159": "Outros fundos isentos temporariamente",
				"401": "Majoração à criação de emprego (art.º 19.º do EBF)",
				"409": "Renumeração convencional do capital social - PME (art.º 136.º da Lei n.º 55-A/2010, de 31/12 e art.º 41.º-A do EBF)",
				"410": "Outras deduções ao rendimento",
				"040": "Entidades de navegação marítima e aérea (art.º 13.º do CIRC)",
				"049": "Outras isenções definitivas"
			},
			"tipodonativo": {
				"10": "Mecenato social (art.º 62.º)",
				"11": "Mecenato social - apoio especial (art.º 62.º)",
				"12": "Mecenato familiar (art.º 62.º)",
				"13": "Mecenato cultural (art.º 62.º-B)",
				"14": "Mecenato cultural - contratos plurianuais (art.º 62.º-B)",
				"15": "Mecenato a organismos associativos (art.º 62.º)",
				"16": "Mecenato para a sociedade de informação (art.º 65.º). Apenas para o período de tributação de 2011",
				"17": "Mecenato - sociedade de informação - contratos plurianuais (art.º 65.º). Apenas para o período de tributação de 2011",
				"18": "Estado - mecenato científico (art.º 62.º-A)",
				"19": "Estado - mecenato científico - contratos plurianuais (art.º 62.º-A)",
				"20": "Mecenato científico - entidades privadas (art.º 62.º-A)",
				"21": "Mecenato científico - entidades privadas - contratos plurianuais (art.º 62.º-A)",
				"22": "Regimes especiais (legislação avulsa)",
				"23": "Mecenato cultural - outros (art.º 62.º-B)",
				"24": "Donativos em espécie (n.º 11 do art.º 62.º, n.º 5 do art.º 62.º-A do EBF e n.º 7 do art.º 62.º-B)",
				"25": "Donativos atribuídos à Estrutura de Missão para as Comemorações do V centenário da Circum-Navegação, criada pela Resolução do Conselho de Ministros n.º 24/2017, de 26 de janeiro – mecenato cultural (art.º 294.º da Lei n.º 71/2018, de 31 de dezembro)",
				"01": "Estado - mecenato social (art.º 62.º)",
				"02": "Estado - mecenato cultural (art.º 62.º-B)",
				"03": "Estado - mecenato ambiental (art.º 62.º)",
				"04": "Estado - mecenato educacional (art.º 62.º)",
				"06": "Estado - mecenato cultural - contratos plurianuais (art.º 62.º-B)",
				"07": "Estado - mecenato ambiental - contratos plurianuais (art.º 62.º)",
				"08": "Estado - mecenato desportivo - contratos plurianuais (art.º 62.º)",
				"09": "Estado - mecenato educacional - contratos plurianuais (art.º 62.º)"
			},
			"tipoidentmatricial": {
				"u": "Urbano",
				"r": "Rústico",
				"o": "Omisso"
			},
			"paises": {
				"4": "AFEGANISTAO",
				"8": "ALBANIA",
				"10": "ANTARCTICA",
				"12": "ARGELIA",
				"16": "SAMOA AMERICANA",
				"20": "ANDORRA",
				"24": "ANGOLA",
				"28": "ANTIGUA E BARBUDA",
				"31": "AZERBAIJAO",
				"32": "ARGENTINA",
				"36": "AUSTRALIA",
				"40": "AUSTRIA",
				"44": "BAHAMAS",
				"48": "BAREM",
				"50": "BANGLADESH",
				"51": "ARMENIA",
				"52": "BARBADOS",
				"56": "BELGICA",
				"60": "BERMUDA",
				"64": "BUTAO",
				"68": "BOLIVIA",
				"70": "BOSNIA E HERZEGOVINA",
				"72": "BOTSWANA",
				"74": "ILHAS BOUVET",
				"76": "BRASIL",
				"84": "BELIZE",
				"86": "TERRITORIO BRITANICO DO OCEANO INDICO",
				"90": "ILHAS SALOMAO",
				"92": "ILHAS VIRGENS (BRITANICAS)",
				"96": "BRUNEI DARUSSALAM",
				"100": "BULGARIA",
				"104": "MYANMAR",
				"108": "BURUNDI",
				"112": "BIELORRUSSIA",
				"116": "CAMBOJA",
				"120": "CAMAROES",
				"124": "CANADA",
				"132": "CABO VERDE",
				"136": "ILHAS CAIMAO",
				"140": "CENTRO-AFRICANA (REPUBLICA)",
				"144": "SRI LANKA",
				"148": "CHADE",
				"152": "CHILE",
				"156": "CHINA",
				"158": "TAIWAN",
				"162": "ILHAS NATAL",
				"166": "ILHAS COCOS O KEELING",
				"170": "COLOMBIA",
				"174": "COMORES",
				"175": "MAYOTTE",
				"178": "CONGO",
				"180": "CONGO (REPUBLICA DEMOCRATICA DO)",
				"184": "ILHAS COOK",
				"188": "COSTA RICA",
				"191": "CROACIA",
				"192": "CUBA",
				"196": "CHIPRE",
				"203": "REPUBLICA CHECA",
				"204": "BENIN",
				"208": "DINAMARCA",
				"212": "DOMINICA",
				"214": "REPUBLICA DOMINICANA",
				"218": "EQUADOR",
				"222": "EL SALVADOR",
				"226": "GUINE EQUATORIAL",
				"231": "ETIOPIA",
				"232": "ERITREIA",
				"233": "ESTONIA",
				"234": "ILHAS FAROE",
				"238": "ILHAS FALKLAND (MALVINAS)",
				"239": "GEORGIA DO SUL E ILHAS SANDWICH",
				"242": "ILHAS FIJI",
				"246": "FINLANDIA",
				"248": "ALAND ISLANDS",
				"250": "FRANCA",
				"254": "GUIANA FRANCESA",
				"258": "POLINESIA FRANCESA",
				"260": "TERRITORIOS FRANCESES DO SUL",
				"262": "JIBUTI",
				"266": "GABAO",
				"268": "GEORGIA",
				"270": "GAMBIA",
				"275": "TERRITORIO PALESTINIANO OCUPADO",
				"276": "ALEMANHA",
				"278": "REPUBLICA DEMOCRATICA ALEM",
				"288": "GANA",
				"292": "GIBRALTAR",
				"296": "KIRIBATI",
				"300": "GRECIA",
				"304": "GRONELANDIA",
				"308": "GRANADA",
				"312": "GUADALUPE",
				"316": "GUAM",
				"320": "GUATEMALA",
				"324": "GUINE",
				"328": "GUIANA",
				"332": "HAITI",
				"334": "ILHAS HEARD E ILHAS MCDONALD",
				"336": "SANTA SE (CIDADE ESTADO DO VATICANO)",
				"340": "HONDURAS",
				"344": "HONG KONG",
				"348": "HUNGRIA",
				"352": "ISLANDIA",
				"356": "INDIA",
				"360": "INDONESIA",
				"364": "IRAO (REPUBLICA ISLAMICA)",
				"368": "IRAQUE",
				"372": "IRLANDA",
				"376": "ISRAEL",
				"380": "ITALIA",
				"384": "COSTA DO MARFIM",
				"388": "JAMAICA",
				"392": "JAPAO",
				"398": "CAZAQUISTAO",
				"400": "JORDANIA",
				"404": "KENYA",
				"408": "COREIA (REPUBLICA POPULAR DEMOCRATICA DA)",
				"410": "COREIA (REPUBLICA DA)",
				"414": "KUWAIT",
				"417": "QUIRGUIZISTAO",
				"418": "LAOS (REPUBLICA POPULAR DEMOCRATICA DO)",
				"422": "LIBANO",
				"426": "LESOTO",
				"428": "LETONIA",
				"430": "LIBERIA",
				"434": "LIBIA (JAMAHIRIYA ARABE DA)",
				"438": "LIECHTENSTEIN",
				"440": "LITUANIA",
				"442": "LUXEMBURGO",
				"446": "MACAU",
				"450": "MADAGASCAR",
				"454": "MALAWI",
				"458": "MALASIA",
				"462": "MALDIVAS",
				"466": "MALI",
				"470": "MALTA",
				"474": "MARTINICA",
				"478": "MAURITANIA",
				"480": "MAURICIAS",
				"484": "MEXICO",
				"492": "MONACO",
				"496": "MONGOLIA",
				"498": "MOLDOVA (REPUBLICA DE)",
				"499": "MONTENEGRO",
				"500": "MONSERRATE",
				"504": "MARROCOS",
				"508": "MOCAMBIQUE",
				"512": "OMA",
				"516": "NAMIBIA",
				"520": "NAURU",
				"524": "NEPAL",
				"528": "PAISES BAIXOS",
				"530": "ANTILHAS HOLANDESAS",
				"531": "CURA.AO",
				"533": "ARUBA",
				"534": "SINT MAARTEN",
				"535": "BONAIRE, SAINT EUSTATIUS E SABA",
				"540": "NOVA CALEDONIA",
				"548": "VANUATU",
				"554": "NOVA ZELANDIA",
				"558": "NICARAGUA",
				"562": "NIGER",
				"566": "NIGERIA",
				"570": "NIUE",
				"574": "ILHAS NORFOLK",
				"578": "NORUEGA",
				"580": "ILHAS MARIANAS DO NORTE",
				"581": "ILHAS MENORES DISTANTES DOS ESTADOS UNIDO",
				"583": "MICRONESIA (ESTADOS FEDERADOS DA)",
				"584": "ILHAS MARSHALL",
				"585": "PALAU",
				"586": "PAQUISTAO",
				"591": "PANAMA",
				"598": "PAPUASIA -NOVA GUINE",
				"600": "PARAGUAI",
				"604": "PERU",
				"608": "FILIPINAS",
				"612": "PITCAIRN",
				"616": "POLONIA",
				"620": "PORTUGAL",
				"624": "GUINE-BISSAU",
				"626": "TIMOR LESTE",
				"630": "PORTO RICO",
				"634": "CATAR",
				"638": "REUNIAO",
				"642": "ROMENIA",
				"643": "RUSSIA (FEDERACAO DA)",
				"646": "RUANDA",
				"652": "S.O BARTOLOMEU",
				"654": "SANTA HELENA",
				"659": "SAO CRISTOVAO E NEVIS",
				"660": "ANGUILA",
				"662": "SANTA LUCIA",
				"666": "SAO PEDRO E MIQUELON",
				"670": "SAO VICENTE E GRANADINAS",
				"674": "SAO MARINO",
				"678": "SAO TOME E PRINCIPE",
				"682": "ARABIA SAUDITA",
				"686": "SENEGAL",
				"688": "SERVIA",
				"690": "SEYCHELLES",
				"694": "SERRA LEOA",
				"702": "SINGAPURA",
				"703": "ESLOVACA (REPUBLICA)",
				"704": "VIETNAME",
				"705": "ESLOVENIA",
				"706": "SOMALIA",
				"710": "AFRICA DO SUL",
				"716": "ZIMBABWE",
				"724": "ESPANHA",
				"728": "SOUTH SUDAN",
				"729": "SUDAN",
				"732": "SARA OCIDENTAL",
				"736": "SUDAO",
				"740": "SURINAME",
				"744": "SVALBARD E A ILHA DE JAN MAYEN",
				"748": "SUAZILANDIA",
				"752": "SUECIA",
				"756": "SUICA",
				"760": "SIRIA (REPUBLICA ARABE DA)",
				"762": "TAJIQUISTAO",
				"764": "TAILANDIA",
				"768": "TOGO",
				"772": "TOKELAU",
				"776": "TONGA",
				"780": "TRINDADE E TOBAGO",
				"784": "EMIRATOS ARABES UNIDOS",
				"788": "TUNISIA",
				"792": "TURQUIA",
				"795": "TURQUEMENISTAO",
				"796": "TURCOS E CAICOS (ILHAS)",
				"798": "TUVALU",
				"800": "UGANDA",
				"804": "UCRANIA",
				"807": "MACEDONIA (ANTIGA REPUBLICA JUGOSLAVA DA)",
				"810": "UNI.O SOVIETICA",
				"818": "EGIPTO",
				"826": "REINO UNIDO",
				"830": "ILHAS DO CANAL",
				"831": "GUERNSEY",
				"832": "JERSEY",
				"833": "ILHA DE MAN",
				"834": "TANZANIA, REPUBLICA UNIDA DA",
				"840": "ESTADOS UNIDOS",
				"850": "ILHAS VIRGENS (ESTADOS UNIDOS)",
				"854": "BURKINA FASO",
				"858": "URUGUAI",
				"860": "USBEQUISTAO",
				"862": "VENEZUELA",
				"876": "WALLIS E FUTUNA (ILHAS)",
				"882": "SAMOA",
				"887": "IEMEN",
				"891": "JUGOSL.VIA",
				"892": "SERVIA E MONTENEGRO",
				"894": "ZAMBIA",
				"900": "OLIVEN.A",
				"901": "REPUBLICA DO KOSOVO",
				"956": "ALDERNE"
			},
			"regimeexploracao": {
				"1": "Aquisição direta",
				"2": "Aluguer de longa duração",
				"3": "Leasing",
				"4": "Afretamento a terceiros"
			},
			"factoimpedimento": {
				"naoaplica": "Não se aplica",
				"falecimentoconjuge": "Falecimento de cônjuge não separado de pessoas e bens, de pessoa com quem vivam em condições análogas às dos cônjuges, ou de parente ou afim no 1.º grau da linha reta (alínea a) do n.º1 do art.º 12.º-A do Decreto-Lei n.º 452/99, de 5 de novembro)",
				"falecimentooutro": "Falecimento de outro parente ou afim na linha reta ou no 2.º grau da linha colateral (alínea b) do n.º1 do art.º 12.º-A do Decreto-Lei n.º 452/99, de 5 de novembro)",
				"doencagrave": "Doença grave e súbita ou internamento hospitalar, que impossibilite em absoluto o contabilista certificado de cumprir as suas obrigações, bem como nas situações de parto (alínea c) do n.º1 do art.º 12.º-A do Decreto-Lei n.º 452/99, de 5 de novembro)",
				"sitparentalidade": "Situações de parentalidade (alínea d) do n.º1 do art.º 12.º-A do Decreto-Lei n.º 452/99)"
			}
		},
		"messages": {
			"nifAlredyExists": "Já existe o nº de contribuinte.",
			"nifEmpty": "Tem que indicar o nº de contribuinte.",
			"periodoAlredyExists": "O ano indicado já tem um prejuízo atribuído.",
			"paisTipoRendAlredyExists": "Já existe um pais com tipo de rendimento.",
			"codbenefvazioAlredyExists": "Já existe o cód. do benefício.",
			"codbenefvazio": "Não selecionou um cód. do benefício.",
			"naceAlredyExists": "Já existe o cód. NACE.",
			"naceVazio": "Não selecionou um cód. NACE.",
			"tipoDonativovazio": "Não selecionou um tipo de donativo",
			"niftipoDonativoAlredyExists": "Já existe um nº de contribuinte com o tipo de donativo selecionado",
			"diplomavazio": "Não selecionou um diploma",
			"nIdentificacaovazio": "Não inseriu o N.º de identificação da obra.",
			"datavazia": "A data não pode estar vazia.",
			"regiaovazio": "Tem de selecionar uma região.",
			"caevazio": "Tem de selecionar um código CAE.",
			"regeiaocaeAlredyExists": "Já existe uma região com o código CAE.",
			"tipovazio": "Tem de selecionar um Tipo.",
			"numProjectovazio": "Tem de indicar um N.º projeto/Código do incentivo.",
			"rendimentovalido": "Tem de inserir um rendimento.",
			"tvalido": "O número de anos tem de ser superior a zero.",
			"freguesiavazio": "Tem de indicar um código da freguesia.",
			"designacaovazio": "A designação não pode ser vazio.",
			"montantevazio": "O montante da mais valia tem de ser superior a zero.",
			"prejuizoAlredyExists": "O ano indicado já tem prejuízo fiscal atribuído.",
			"dadosguardados": "Dados guardados com sucesso!",
			"registosdel": "Modelo22 apagado com sucesso.",
			"temDeGuardar": "Tem de guardar os dados para poder criar o ficheiro",
			"avisoAnexos": "Tem a certeza que quer apagar o anexo <strong>\"{{anexo}}\"</strong>?",
			"mod22calculado": "Modelo calculado",
			"containicialAlredyExists": "A conta inicial já está configurada!",
			"derramaErrorMessage": "Derrama Municipal. Há anos com dados adicionais em falta para este concelho. Clique aqui para introduzir os dados e efetuar o cálculo da derrama.",
			"anoAlredyExists": "Já existe o ano indicado na discriminação",
			"anoVazio": "Tem de indicar o ano do investimento",
			"prejEmpty": "Prejuízo não pode ser nulo ou 0.",
			"cleanValuesInfo": "Pretende anular os valores inseridos manualmente?",
			"cleanValuesTooltip": "Anula os valores inseridos manualmente para que possa recomeçar o processo",
			"anoDedInv": "O ano da dedução não tem que ser superior ao ano do prejuízo fiscal.",
			"servicoAT": {
				"validarAtSuccess": "Declaração validada com sucesso",
				"validarAtFailed": "Erro ao validar a declaração na AT",
				"submeterAtSuccess": "Declaração submetida com sucesso",
				"submeterAtFailed": "Erro ao submeter a declaração na AT"
			},
			"modalSubmeterAT": {
				"title": "Confirmar envio novamente da declaração ignorando os avisos",
				"message": "A declaração apenas apresenta avisos, pretende enviar novamente a declaração ignorando os avisos?"
			},
			"contasdef": {
				"reposicaocomsucesso": "As definições de contas para os campos foram respostas",
				"contascopiadascomsucesso": "As definições de contas para os campos foram copiadas com sucesso para as empresas selecionadas"
			}
		},
		"errors": {
			"campo346": "Matéria coletável negativa!"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-exclamation-triangle text-warning' style='color: orange;' aria-hidden='true'></i> Pretende sair sem gravar?",
			"exitMessage": "Ao clicar em OK irá sair sem gravar e todas as alterações que fez serão apagadas. Tem a certeza que pretende sair?"
		}
	},
	"modelo25": {
		"title": "Modelo 25",
		"config": {
			"modalTitle": "Configuração — Contas dos Donativos Recebidos",
			"titleTableDonativosNumerarios": "Donativos em Numerário — Contas de Rendimentos / Capital Próprio",
			"titleTableDonativosEspecie": "Donativos em Espécie — Contas de Rendimentos / Capital Próprio",
			"contaDe": "Conta de",
			"contaAte": "Conta até",
			"alerts": {
				"success": "Configuração guardada com sucesso!",
				"error": "Ocorreu um problema!",
				"hasEditData": "Nota: Ainda tem dados por guardar!"
			}
		},
		"groups": {
			"q0": {
				"title": "Introdução",
				"p1": "Quadro 00",
				"p2": "Donativos Recebidos",
				"p3": "No âmbito das obrigações acessórias das entidades beneficiárias dos donativos, serve este modelo para cumprir com as disposições legais contidas na alínea c) do n.o 1 do art. 66º do Estatuto dos Benefícios Fiscais. ",
				"p4": "O cumprimento desta obrigação fiscal deve efetivar-se através do preenchimento e envio do presente modelo por transmissão eletrónica, até ao fim do mês de fevereiro de cada ano, referente aos donativos recebidos no ano anterior. "
			},
			"q1": {
				"title": "Número de identificação fiscal do declarante",
				"campoNifDeclarante": "Número de identificação fiscal do declarante",
				"campoNomeDeclarante": "Nome do sujeito passivo"
			},
			"q2": {
				"title": "Anos dos donativos"
			},
			"q3": {
				"title": "Código do serviço de finanças da sede ou domicílio",
				"codDeServico": "Código do serviço de finanças"
			},
			"q4": {
				"title": "Tipo de declaração",
				"radioTipoDeclaracao": {
					"primeira": "Primeira",
					"sub": "Substituição"
				}
			},
			"q5": {
				"title": "Relação das entidades doadoras e dos donativos",
				"dataGridRegistoList": {
					"colunas": {
						"entidade": "Entidade doadora",
						"codigoDonativo": "Código do donativo",
						"valorNum": "Valor do donativo em numerário",
						"valorEsp": "Valor do donativo em espécie",
						"donativoStr": "Tipo donativo",
						"linhas": {
							"periodo": "Período",
							"nDiario": "Diário",
							"nDocInterno": "Nº doc. interno",
							"nConta": "Nº conta",
							"nomePOC": "Nome conta",
							"valor": "Valor"
						}
					}
				}
			}
		},
		"errors": {
			"errorHeader": "O modelo 25 atual tem os seguintes erros. Por favor, verifique.",
			"errorCodigoDonMensagem": "Erro no campo código donativo da entidade {{entidade}}",
			"errorCodigoDonRequired": "Campo é obrigatório",
			"errorCodigoDonName": "Código do donativo",
			"errorNoDataList": "* É obrigatório conter dados para a criação do ficheiro.",
			"errorValoresMensagem": "Erro num dos campos de valor da entidade {{entidade}}",
			"errorValoresRequired": "Pelo menos um dos campos é obrigatório",
			"errorDadosInseridos": "Existe pelo menos um erro nos dados inseridos na tabela {{datagrid}}",
			"errorContasOverlapping": "Existe uma sobreposição entre as 'contas de' e as 'contas até' nas grelhas",
			"errorOnlyDigitsAllowed": "Apenas são permitidos dígitos como valores nos campos 'conta de' e 'conta até'"
		},
		"helpers": {
			"naoExistemContasConfiguradas": "Não existem contas configuradas para o carregamento de dados. ",
			"clickConfig": "<a>Clique aqui para configurar.</a>"
		},
		"servicoAT": {
			"validarAtSuccess": "Declaração validada com sucesso",
			"validarAtFailed": "Erro ao validar a declaração na AT",
			"submeterAtSuccess": "Declaração submetida com sucesso",
			"submeterAtFailed": "Erro ao submeter a declaração na AT"
		},
		"global": {
			"btns": {
				"btnRefresh": "Atualizar",
				"btnCriarFicheiro": "Criar Ficheiro",
				"btnSubmeterAt": "Submeter AT",
				"btnValidarAt": "Validar na AT",
				"btnConfigWS": "Configurações WS"
			}
		},
		"modal": {
			"configws": {
				"title": "Configurações do WebService",
				"username": "Username do contribuinte",
				"password": "Password do contribuinte",
				"servicoInvCC": "Serviço invocado por Contabilista Certificado (CC)",
				"ccPowers": "CC com plenos poderes declarativos para o Contrib.",
				"usernameCC": "Username do CC",
				"passwordCC": "Password do CC",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> O serviço já se encontra configurado.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> O serviço não está configurado.",
				"configsaved": "Configuração guardada com sucesso."
			}
		}
	},
	"modelo30": {
		"title": "Modelo 30",
		"groups": {
			"q1": {
				"title": "Número de identificação fiscal",
				"c01": "Número de identificação fiscal da entidade declarante"
			},
			"q2": {
				"title": "Número de identificação fiscal do contabilista certificado / Justo impedimento",
				"c02": "Número de identificação fiscal do contabilista certificado",
				"casoJustoImpedimento": "Caso tenha ocorrido justo impedimento (artº 124 do DL nº 452/99, de 5 de novembro), indique:",
				"c02a": "Facto que determinou o justo impedimento",
				"c02b": "Data da ocorrência do facto"
			},
			"q3": {
				"title": "Ano / Mês"
			},
			"q4": {
				"title": "Código do serviço de finanças da sede ou domicílio fiscal",
				"c04": "Código do serviço de finanças da sede ou domicilio fiscal"
			},
			"q5": {
				"title": "Tipo de declaração",
				"c05": "Dados da declaração / tipo declaração",
				"primeira": "Primeira",
				"sub": "Substituição"
			},
			"q6": {
				"title": "Resumo das importâncias retidas",
				"c06": "Dividendos ou Lucros Derivados de Participações Sociais",
				"c07": "Juros ou Rendimentos Derivados da Aplicação de Capitais",
				"c08": "Royalties",
				"c09": "Trabalho Dependente",
				"c10": "Trabalho Independente",
				"c11": "Comissões",
				"c12": "Prediais",
				"c13": "Pensões",
				"c14": "Prestações de Serviços",
				"c40": "Remunerações Públicas",
				"c41": "Pensões Públicas"
			},
			"q7": {
				"title": "Relação das guias de pagamento",
				"dataGrid": {
					"nGuia": "Nº guia de pagamento",
					"valorTotal": "Valor total da guia"
				}
			},
			"q8": {
				"title": "Relação dos beneficiários dos rendimentos",
				"dataGrid": {
					"nif": "Nif português",
					"nifPais": "Nif país residência",
					"codPais": "Cód. país residência",
					"partCapD": "Part. no capital (D)",
					"partCapS": "Part. no capital (S)",
					"rendTipo": "Rend. (Tipo)",
					"rendValor": "Rend. (Valor)",
					"tribCod": "Trib. (Cód.)",
					"tribTaxa": "Trib. (Taxa)",
					"guia": "Nº guia pagamento",
					"montante": "Montante imp. retido",
					"nifEntidade": "Nif ent. emitente",
					"legends": {
						"existeLinhasParaMesmoContribuinte": "Existem múltiplas linhas para o mesmo contribuinte"
					}
				}
			}
		},
		"verificarLancamentos": {
			"title": "Verificar possível lançamentos",
			"contribuintesNaoPTComRetencao": "Contribuintes não PT com retenção",
			"contribuintesNaoPTSemRetencao": "Contribuintes não PT sem retenção",
			"mensagens": {
				"success": "Lançamentos aplicados com sucesso no quadro 8",
				"semDados": "Não foram encontrados registos de possíveis lançamentos"
			}
		},
		"errors": {
			"errorHeader": "O modelo 30 atual tem os seguintes erros. Por favor, verifique.",
			"q08MontandeSemGuias": "Existem linhas com valor retido em que não foi preenchido o número da guia!",
			"q08LinhasDuplicadas": "Existem linhas com o mesmo contribuinte, no momento de geração do ficheiro estas vão ser agrupadas.",
			"q08": "Quadro 8",
			"q08WithoutData": "Não se encontra devidamente preenchido.",
			"validarFailed": "Erro ao validar a declaração",
			"linhaSemNif": "Linha selecionada não contem um NIF para editar",
			"pais": "País",
			"paisMessage": "Código de país tem de conter 3 dígitos.",
			"sf": "Serviço finanças",
			"sfMessage": "Tem de indicar o código de serviço de finanças"
		},
		"servicoAT": {
			"validarAtSuccess": "Declaração validada com sucesso",
			"validarAtFailed": "Erro ao validar a declaração na AT",
			"submeterAtSuccess": "Declaração submetida com sucesso",
			"submeterAtFailed": "Erro ao submeter a declaração na AT"
		},
		"btns": {
			"verificarLancamentos": "Verificar lançamentos possíveis",
			"btnRepor": "Carrega dados por defeito",
			"btnRepFinancas": "Repartição de Finanças"
		}
	},
	"modelo39": {
		"title": "Modelo 39",
		"initModal": {
			"message": "Pretende gerar o ficheiro do Modelo 39 com os dados do processamento de salários do ano '{{ano}}' do tipo rendimentos capitais?"
		},
		"groups": {
			"q0": {
				"title": "Início",
				"p2": "Indicações Gerais",
				"p3": "A declaração modelo n.º 39 é de entrega obrigatória pelas entidades devedoras e pelas entidades que paguem ou coloquem à disposição dos respetivos titulares pessoas singulares residentes em território português e que não beneficiem de isenção, dispensa de retenção ou redução de taxa, rendimentos a que se refere o artigo 71.º do Código do Imposto sobre o Rendimento das Pessoas Singulares ou quaisquer rendimentos sujeitos a retenção na fonte a título definitivo. ",
				"p4": "É dispensada a identificação dos sujeitos passivos cujo somatório dos rendimentos, para todos os códigos, seja igual ou inferior a €25,00. "
			},
			"q1": {
				"title": "Número de identificação fiscal do declarante",
				"campoNifDeclarante": "Número de identificação fiscal"
			},
			"q2": {
				"title": "Número de identificação fiscal do CC / Justo impedimento",
				"q02C02": "Número de identificação fiscal",
				"q02Caso": "Caso tenha ocorrido justo impedimento (art.º 12º-A do DL n.º 452/99, de 5 de novembro), indique:",
				"q02C06": "Facto que determinou o justo impedimento",
				"q02C07": "Data da ocorrência do facto",
				"q02C08": "Data da cessação do facto"
			},
			"q4": {
				"title": "Código do serviço de finanças da sede ou domicílio",
				"q04C04": "Código do serviço de finanças"
			},
			"q5": {
				"title": "Tipo de declaração",
				"radioTipoDeclaracao": {
					"primeira": "Primeira",
					"sub": "Substituição"
				}
			},
			"q6": {
				"title": "Relação dos titulares dos rendimentos",
				"dataGridRegistoList": {
					"colunas": {
						"nif": "6.1 - NIF do Titular dos Rendimentos",
						"codRend": "6.2 - Código dos Rendimentos",
						"rendimento": "6.3 - Montante dos Rendimentos",
						"retido": "6.4 - Montante do IRS Retido",
						"nifEmitente": "6.5 - NIF de Entidade Emitente"
					},
					"extras": {
						"ajuda": "Consultar ajuda dos códigos rendimentos",
						"ajudaTitle": "Ajuda códigos de rendimentos",
						"cod": "Código"
					}
				}
			}
		},
		"codigosJustoImpedimento": {
			"01": "Falecimento de cônjuge não separado de pessoas e bens, de pessoa com quem vivam em condições análogas às dos cônjuges, ou de parente ou afim no 1.º grau da linha reta",
			"02": "Falecimento de outro parente ou afim na linha reta ou no 2.º grau da linha colateral",
			"03": "Doença grave e súbita ou internamento hospitalar do contabilista, que o impossibilite em absoluto de cumprir as suas obrigações, ou situações de parto ou de assistência inadiável e imprescindível a cônjuge ou pessoa que viva em união de facto ou economia comum e a parente ou afim no 1º grau da linha reta, em caso de doença ou acidente destes",
			"04": "Situações de parentalidade"
		},
		"codigosRendimentos": {
			"10": {
				"1001": "Rendimentos pagos pelos fundos de poupança-reforma que não beneficiam de qualquer exclusão - n.º 5 do artigo 21.º do Estatuto dos Benefícios Fiscais (1ª parte). "
			},
			"11": {
				"1101": "Rendimentos de unidades de participação em fundos de capital de risco, fundos de investimento imobiliário ou de participações sociais em sociedades de investimento imobiliário - recursos florestais - e em fundos de investimento imobiliário de reabilitação urbana- artigos 23º, 24º e 71º do Estatuto dos Benefócios Fiscais. "
			},
			"12": {
				"1201": "Regime Transitório (antes de 1 de janeiro de 1991 e depois desta data até 31 de dezembro de 1994) - Diferença positiva entre os montantes pagos a título de resgate, adiantamento ou vencimento de seguros e operações do ramo \"Vida\" e regimes complementares que beneficiam da exclusão da tributação da totalidade do rendimento para contratos celebrados antes de 1.1.91 e para contratos celebrados entre 1 de janeiro de 1991 e 31 de dezembro de 1994 - alínea b) do n.º 3 do artigo 5.º do Código do IRS - redação do Decreto-Lei n.º 267/91, de 6 de agosto. "
			},
			"13": {
				"1301": "Regime Transitório (1 de janeiro de 1991 a 31 de dezembro de 1994) - Diferença positiva entre os montantes pagos a título de resgate, adiantamento ou vencimento de seguros e operações do ramo \"Vida\" e regimes complementares que beneficiam da exclusão da tributação de 1/2 - alínea a) do n.º 3 do artigo 5.º do Código do IRS (redação do Decreto-Lei n.º 267/91, de 6 de agosto). "
			},
			"14": {
				"1401": "Regime Transitório (1 de janeiro de 1995 a 31 de dezembro de 2000) - Diferença positiva entre os montantes pagos a título de resgate, adiantamento ou vencimento de seguros e operações do ramo \"Vida\" e regimes complementares que beneficiam da exclusão da tributação de 2/5 - alínea a) do n.º 3 do artigo 5.º do Código do IRS (redação da Lei n.º 39-B/94, de 27 de dezembro). "
			},
			"15": {
				"1501": "Regime Transitório (1 de janeiro de 1995 a 31 de dezembro de 2000) - Diferença positiva entre os montantes pagos a título de resgate, adiantamento ou vencimento de seguros e operações do ramo \"Vida\" e regimes complementares que beneficiam da exclusão da tributação de 4/5 - alínea b) do n.º 3 do artigo 5.º do Código do IRS (redação da Lei n.º 39-B/1994, de 27 de dezembro). "
			},
			"16": {
				"1601": "Regime Transitório (Planos celebrados até 31.12.2005) - As importâncias pagas pelos fundos de poupança-reforma, PPE e PPR/E que beneficiam da exclusão de 4/5 - artigo 21.º, n.º 3, alínea b) n.º 1 do Estatuto dos Benefícios Fiscais, conforme o disposto no artigo 55.º, n.º 3 da Lei n.º 60-A/2005, de 30 de dezembro. "
			},
			"17": {
				"1701": "Rendimentos referidos nos códigos 01, a 03, 19 a 31 e 33 quando sejam pagos ou colocados à disposição em contas abertas em nome de um ou mais titulares mas por conta de terceiros não identificados (exceto quando seja identificado o beneficiário efetivo) - alínea a) do n.º 12 do artigo 71.º do Código do IRS. "
			},
			"18": {
				"1801": "Rendimentos pagos ou colocados à disposição dos respetivos titulares, residentes em território português, devidos por entidades não residentes sem estabelecimento estável em território português e que sejam domiciliadas em país, território ou região sujeitas a um regime fiscal claramente mais favorável, por intermédio de entidades que estejam mandatadas por devedores ou titulares ou ajam por conta de uns ou outros - alínea c) do n.º 12 do artigo 71.º do Código do IRS (anterior n.º 13 do mesmo artigo). "
			},
			"19": {
				"1901": "Juros e outras formas de remuneração decorrentes de contratos de mútuo, abertura de crédito, reporte e outros que propiciem, a título oneroso, a disponibilidade temporária de dinheiro ou outras coisas fungíveis - alínea a) do n.º 2 do artigo 5.º do Código do IRS - aplicável a 2015 e anos seguintes. "
			},
			"20": {
				"2001": "Rendimentos de contratos de cessão temporária, quando não auferidos pelo titular originário, de direitos de propriedade intelectual, industrial, ou de prestação de informações por experiência adquirida no sector industrial, comercial ou científico, bem como os derivados de assistência técnica- alínea m) do n.º 2, artigo 5 º do Código do IRS - aplicável a 2015 e anos seguintes. "
			},
			"21": {
				"2101": "Rendimentos derivados do uso ou de concessão do uso de equipamento agrícola industrial, comercial ou científico, quando não constituam rendimentos prediais, bem como os provenientes da cedência, esporádica ou continuada, de equipamentos e redes informáticas, incluindo a transmissão de dados ou disponibilização de capacidade informática instalada em qualquer das suas formas possíveis - alínea n) do n.º 2, artigo 5.º do Código do IRS - aplicável a 2015 e anos seguintes. "
			},
			"22": {
				"2201": "Saldos dos juros apurados em contrato ou lançados em conta corrente - alíneas f) e o), n.º 2 do artigo 5.º do Código do IRS - aplicável a 2015 e anos seguintes. "
			},
			"23": {
				"2301": "Juros, pela dilação ou mora no pagamento, com exceção dos devidos ao Estado e outros entes públicos - alínea g) do n.º 2 do artigo 5.º do Código do IRS - aplicável a 2015 e anos seguintes. "
			},
			"24": {
				"2401": "Ganhos decorrentes de operações swaps de taxa de juro - alínea q) do n.º 2 do artigo 5.º do Código do IRS - aplicável a 2015 e anos seguintes. "
			},
			"25": {
				"2501": "Remuneração de certificados que garantam ao titular o direito de receber um valor mínimo superior ao valor de subscrição - alínea r) do n.º 2 do artigo 5.º do Código do IRS - aplicável a 2015 e anos seguintes. "
			},
			"26": {
				"2601": "Indemnizações que visem compensar perdas de rendimentos da categoria E - alínea s) do n.º 2 do artigo 5.º do Código do IRS - aplicável a 2015 e anos seguintes. "
			},
			"27": {
				"2701": "Montantes pagos ou colocados à disposição do sujeito passivo por estruturas fiduciárias, quando tais montantes não estejam associados à sua liquidação, revogação ou extinção, e não tenham sido já tributados - alínea t) do n.º 2 do artigo 5.º do Código do IRS - aplicável a 2015 e anos seguintes. "
			},
			"28": {
				"2801": "Rendimentos distribuídos das unidades de participação em fundos de investimento mobiliário ou de participações sociais em sociedades de investimento mobiliário a que seja aplicável o regime previsto na subalínea i) da alínea a) do nº 1 do artigo 22-A do Estatuto dos Benefícios Fiscais-- aplicável a partir de 1 de julho 2015. "
			},
			"29": {
				"2901": "Rendimentos distribuídos das unidades de participação em fundos de investimento imobiliário ou de participações sociais em sociedades de investimento imobiliário a que seja aplicável o regime previsto na subalínea i) da alínea a) do n.º 1 e no n.º 13 do artigo 22.º-A do Estatuto dos Benefícios Fiscais -- aplicável a partir de 1 de julho 2015. "
			},
			"30": {
				"3001": "Resgate e liquidação de unidades de participação em fundos de investimento mobiliário ou de participações sociais em sociedades de investimento mobiliário, a que seja aplicável o regime previsto na alínea b) do n.º 1 do artigo 22.º-A do Estatuto dos Benefícios Fiscais - aplicável a partir de 1 de julho de 2015. "
			},
			"31": {
				"3101": "Resgate e liquidação de unidades de participação em fundos de investimento imobiliário ou de participações sociais em sociedades de investimento imobiliário, a que seja aplicável o regime previsto na alínea b) do n.º 1 do artigo 22.º-A do Estatuto dos Benefícios Fiscais - aplicável a partir de 1 de julho de 2015. "
			},
			"32": {
				"3201": "Rendimentos de baldios - n.º 4 do artigo 59.º do Estatuto dos Benefícios Fiscais. "
			},
			"33": {
				"3301": "Outros rendimentos derivados da simples aplicação de capitais não incluídos nas alíneas anteriores- alínea p) do n.º 2 do artigo 5.º do Código do IRS - aplicável a 2015 e anos seguintes. "
			},
			"34": {
				"3401": "Lucros brutos colocados à disposição de sujeitos passivos que detenham uma participação social em sociedades que se encontrem na condição prevista no artigo 35.º do Código das Sociedades Comerciais, a favor das quais realizaram entradas de capital em dinheiro (artigo 43.º-B do Estatuto dos Benefícios Fiscais). "
			},
			"35": {
				"3501": "Rendimentos distribuídos no âmbito da gestão de recursos florestais por entidades de gestão florestal (EGF) e unidades de gestão florestal (UGF) - n.ºs 2 e 14 do artigo 59.º-G do Estatuto dos Benefícios Fiscais. Rendimentos distribuídos no âmbito da gestão de recursos florestais por entidades de gestão florestal (EGF) e unidades de gestão florestal (UGF) - n.ºs 2 e 15 do artigo 59.º-G do Estatuto dos Benefícios Fiscais. "
			},
			"desc": "Consultar ajuda para detalhes",
			"01": {
				"0101": "Lucros e reservas colocados à disposição dos associados ou titulares e adiantamentos por conta de lucros devidos por entidades residentes (inclui dividendos) - alínea h) do n.º2 do artigo 5º do Código do IRS, com exceção dos lucros identificados com o código 34. ",
				"0102": "Rendimentos resultantes de partilha qualificados como de aplicação de capitais (aplicável a 2013 e anos anteriores). ",
				"0103": "O valor atribuído aos associados na amortização de partes sociais sem redução de capital - alínea i) do n.º2 do artigo 5º do Código do IRS. ",
				"0104": "Os rendimentos auferidos pelo associado na associação em participação e na associação à quota, bem como, nesta ultima, os rendimentos referidos nas alíneas h) e i) do n.º 1 do artigo 5.º do Código do IRS auferidos pelo associante depois de descontada a prestação por si devida ao associado. "
			},
			"02": {
				"0201": "Rendimentos de valores mobiliários pagos ou colocados à disposição dos respetivos titulares, residentes em território português, devidos por entidades que não tenham domicílio em território português a que possa imputar-se o pagamento, por intermédio de entidades que estejam mandatadas por devedores ou titulares ou ajam por conta de uns ou outros- alínea b) do nº 1 do artigo 71º do Código do IRS. "
			},
			"03": {
				"0301": "Juros e outras formas de remuneração de depósitos à ordem ou a prazo, bem como de certificados de depósitos e de contas de títulos com garantia de preço ou de outras operações similares ou afins - alínea b) do nº 2 do artigo 5º do Código do IRS. ",
				"0302": "Juros, prémios de amortização ou reembolso e outras remunerações de títulos de dívida, obrigações, títulos de participação, certificados de consignação, obrigações de caixa ou outros títulos análogos e demais instrumentos de aplicação financeira - alínea c) do nº 2 do artigo 5º do Código do IRS. ",
				"0303": "Juros e outras formas de remuneração de suprimentos, abonos ou adiantamentos de capital feitos pelos sócios à sociedade - alínea d) do nº 2 do artigo 5º do Código do IRS. ",
				"0304": "Juros e outras formas de remuneração devidos pelo facto de os sócios não levantarem os lucros ou remunerações colocados à sua disposição - alínea e) do nº 2 do artigo 5º do Código do IRS. ",
				"0305": "Rendimentos de operações de reporte e cessões de crédito - aplicável a 2014 e anos anteriores. ",
				"0306": "Ganhos decorrentes das operações de swaps ou operações cambiais a prazo - aplicável a 2014 e anos anteriores. "
			},
			"04": {
				"0401": "Diferença positiva entre os montantes pagos a título de resgate, adiantamento ou vencimento de seguros e operações do ramo \"Vida\" e outros regimes complementares que não beneficiam de exclusão - n.º 3 do artigo 5.º do Código do IRS. ",
				"0402": "Rendimentos de poupança a longo prazo ou de planos poupança de ações que não beneficiem de exclusão - n.º 3 do artigo 5.º do Código do IRS (por remissão dos artigos 20.º-A e 26.º do Estatuto dos Benefícios Fiscais) e artigo 25.º do Estatuto dos Benefícios Fiscais. "
			},
			"05": {
				"0501": "Diferença positiva entre os montantes pagos a título de resgate, adiantamento ou vencimento de seguros e operações do ramo \"Vida\" e outros regimes complementares que beneficiam da exclusão da tributação de 1/5 - alínea a) do n.º 3 do artigo 5.º do Código do IRS. ",
				"0502": "Rendimentos de poupança a longo prazo ou de planos poupança de ações que beneficiem de exclusão da tributação de 1/5 - alínea a) do n.º 3 do artigo 5.º do Código do IRS (por remissão dos artigos 20.º-A e 26.º do Estatuto dos Benefícios Fiscais) e artigo 25.º do Estatuto dos Benefícios Fiscais. "
			},
			"06": {
				"0601": "Diferença positiva entre os montantes pagos a título de resgate, adiantamento ou vencimento de seguros e operações do ramo \"Vida\" e regimes complementares que beneficiam da exclusão da tributação de 3/5 - alínea b) do n.º 3 do artigo 5.º do Código do IRS. ",
				"0602": "Rendimentos de poupança a longo prazo ou de planos poupança de ações que beneficiem de exclusão da tributação de 3/5 - alínea b) do n.º 3 do artigo 5.º do Código do IRS (por remissão dos artigos 20.º-A e 26.º do Estatuto dos Benefícios Fiscais) e artigo 25.º do Estatuto dos Benefícios Fiscais. "
			},
			"07": {
				"0701": "Rendimentos pagos pelos fundos de poupança-reforma, incluindo os que sejam efetuados com natureza prestacional, durante um período no superior a dez anos, que beneficiam de exclusão de 3/5 - alínea b) do n.º 3 do artigo 21.º do Estatuto dos Benefícios Fiscais. "
			},
			"08": {
				"0801": "Rendimentos pagos pelos fundos de poupança-reforma que beneficiam da exclusão da tributação do rendimento de 1/5 - n.º 5 do artigo 21.º do Estatuto dos Benefícios Fiscais e alínea a) do n.º 3 do artigo 5.º do Código do IRS. "
			},
			"09": {
				"0901": "Rendimentos pagos pelos fundos de poupança-reforma que beneficiam da exclusão da tributação do rendimento de 3/5 - n.º 5 do artigo 21.º do Estatuto dos Benefícios Fiscais e alínea b) do n.º 3 do artigo 5.º do Código do IRS. "
			}
		},
		"errors": {
			"errorNoDataList": "* É obrigatório conter dados para a criação do ficheiro.",
			"errorHeader": "O modelo 39 atual tem os seguintes erros. Por favor, verifique."
		},
		"servicoAT": {
			"validarAtSuccess": "Declaração validada com sucesso",
			"validarAtFailed": "Erro ao validar a declaração na AT",
			"submeterAtSuccess": "Declaração submetida com sucesso",
			"submeterAtFailed": "Erro ao submeter a declaração na AT"
		}
	},
	"moeda": {
		"title_new": "Nova Moeda",
		"title_detail": "Moeda {{id}}",
		"title_edit": "Moeda {{id}}",
		"title_plural": "Moedas",
		"pesquisa": "Pesquisar",
		"saved": "Moeda {{id}}, guardada com sucesso.",
		"error": "Moeda {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"errorOnNew": "Nova Moeda não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Moeda {{id}}, apagada com sucesso.",
		"fields": {
			"codMoeda": "Código da moeda",
			"nomeMoeda": "Nome da moeda",
			"abreviaturaMoeda": "Abreviatura da moeda",
			"extenso1Unidade": "Unidade por extenso (singular)",
			"extensoNUnidades": "Unidade por extenso (plural)",
			"extensoCentimos": "Cêntimos por extenso",
			"nDecimais": "Nº de casas decimais",
			"dataReferencia": "Data referência",
			"exchange": "Câmbio"
		},
		"tabs": {
			"activeExchange": "Câmbio moeda ativo",
			"otherExchange": "Outros câmbios moeda"
		},
		"titles": {
			"cambioMoedaEmpresaEmMoedaEstrangeira": "Câmbio moeda empresa para moeda estrangeira",
			"cambioMoedaEstrangeiraEmMoedaEmpresa": "Câmbio moeda estrangeira para moeda empresa"
		},
		"errors": {
			"moedaNaoExiste": "A moeda não existe.",
			"naoPodeAlterarMoedaSemRemoverLinhas": "Não pode alterar a moeda sem que antes remova todas as linhas do documento comercial."
		},
		"chooseTemplate": "Escolher template",
		"invalidTemplate": "O país escolhido não contém um modelo de moeda válido. Por favor tente novamente",
		"editCambio": "Editar câmbio moeda {{date}}",
		"choosecounty": "Copiar template",
		"updateCambio": "Atualizar câmbio"
	},
	"moedaNormasIso": {
		"title": "Normas Moedas ISO",
		"pesquisa": "Pesquisar normas moedas ISO",
		"fields": {
			"codigo": "Código",
			"paisNomeMoeda": "País (moeda)",
			"nomeMoeda": "Nome da moeda",
			"extensoUnidade": "Unidade por extenso (singular)",
			"extensoNUnidades": "Unidade por extenso (plural)",
			"extensoSubUnidade": "Unidade por extenso (sub)"
		}
	},
	"moradasFaturacao": {
		"title_detail": "Morada de faturação {{id}}",
		"title_new": "Nova morada de faturação",
		"title_plural": "Moradas de faturação",
		"pesquisa": "pesquisar",
		"saved": "Morada de faturação {{id}}, guardada com sucesso",
		"error": "Morada de faturação {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"fields": {
			"nConta": "Nº",
			"nome": "Nome",
			"rua": "Rua",
			"codPostal": "Cód. postal",
			"localidade": "Localidade",
			"nipc": "NIPC",
			"id": "Índice",
			"observacoes": "Obs",
			"morada": "Morada",
			"codPais": "Cód. país",
			"moralId": "Id. moral",
			"nIdAltern": "Id. alternativa"
		}
	},
	"morals": {
		"title_detail": "Morada alternativa",
		"title_new": "Nova morada alternativa",
		"title_plural": "Moradas alternativas",
		"pesquisa": "Pesquisar",
		"saved": "Morada alternativa, guardada com sucesso.",
		"error": "Morada alternativa, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Morada alternativa, apagada com sucesso.",
		"deleteModalText": "Apagar registo \"{{nome}}\"?",
		"fields": {
			"nConta": "Nº conta",
			"nome": "Nome",
			"rua": "Rua",
			"cPost": "Cód. postal",
			"localidade": "Localidade",
			"observacoes": "Observações",
			"morada": "Morada",
			"codPais": "Cód. país",
			"desativado": "Desativado",
			"contactoNome": "Contacto nome",
			"contactoTelefone": "Contacto telefone",
			"codigoPontoPickup": "Código ponto pickup"
		}
	},
	"motivoContrato": {
		"data": {
			"semmotivo": "Sem motivo",
			"temporariamenteimpedidoprestarserviço": "Temporariamente impedido de prestar serviço",
			"impendaaccaojuizoapreciacaolicitudedespedimento": "Em relação ao qual impenda ação em juízo de apreciação da licitude do despedimento",
			"situacaolicencasemretribuicao": "Situação de licença sem retribuição",
			"substituicaotrabalhadorportempoindeterminado": "Substituição de trabalhador a tempo completo que passe a trabalhar a tempo parcial por tempo indeterminado",
			"cctividadessazonais": "Atividades sazonais",
			"acrescimoexcepcionalactividadeempresa": "Acréscimo excecional da atividade da empresa",
			"execucaotarefaserviçodefinidonaoduradouro": "Execução de tarefa ocasional ou serviço determinado precisamente definido e não duradouro",
			"execucaoprojectoactividadedefinidatemporaria": "Execução de uma obra, projeto ou outra atividade definida e temporária",
			"lancamentonovaactividadeduraçãoincerta": "Lançamento de uma nova atividade de duração incerta, bem como início de laboração de uma empresa ou estabelecimento",
			"contratacaotrabalhadoresprimeiroemprego": "Contratação de trabalhadores à procura de primeiro emprego ou de desempregados de longa duração",
			"actividadecontinuidadeousemtermo": "Atividade de continuidade ou sem termo",
			"outros": "Outros"
		}
	},
	"movimentosaberto": {
		"btn": {
			"sendMail": "Enviar e-mail",
			"processarexcel": "Processar e exportar excel"
		},
		"modal": {
			"sendMail": {
				"title": "Enviar e-mail: {{nConta}} - {{nomeConta}}",
				"fields": {
					"email": "E-mail",
					"subject": "Assunto",
					"body": "Texto"
				}
			}
		},
		"label": {
			"grupoConta": "Grupo de conta",
			"showGrupoConta": "Mostrar grupo de conta",
			"movimentosEmAberto": "Movimentos em aberto {{tipo}}"
		}
	},
	"movimentospendentes": {
		"clientes": {
			"nConta": "Conta",
			"nome": "Nome",
			"tipoDocumento": "Tipo de documento",
			"numeroDocumento": "Número de documento",
			"dataDoc": "Data doc.",
			"dataVencimento": "Data de vencimento",
			"valor": "Valor do documento",
			"valorRecebido": "Valor já recebido",
			"valorPorReceber": "Valor por receber",
			"nif": "NIF",
			"nDocExterno": "Nº doc. externo"
		},
		"fornecedores": {
			"nConta": "Conta",
			"nome": "Nome",
			"tipoDocumento": "Tipo de documento",
			"numeroDocumento": "Número de documento",
			"dataDoc": "Data doc.",
			"dataVencimento": "Data de vencimento",
			"valor": "Valor do documento",
			"valorRecebido": "Valor já pago",
			"valorPorReceber": "Valor por pagar",
			"nif": "NIF",
			"nDocExterno": "Nº doc. externo"
		},
		"outrosDevedoresCredores": {
			"nConta": "Conta",
			"nome": "Nome",
			"tipoDocumento": "Tipo de documento",
			"numeroDocumento": "Número de documento",
			"dataDoc": "Data doc.",
			"dataVencimento": "Data de vencimento",
			"valor": "Valor do documento",
			"valorRecebido": "Valor já pago",
			"valorPorReceber": "Valor por pagar",
			"nif": "NIF",
			"nDocExterno": "Nº doc. externo"
		}
	},
	"nacionalidades": {
		"title_detail": "Nacionalidade {{id}}",
		"title_new": "Nova Nacionalidade",
		"title_plural": "Nacionalidades",
		"pesquisa": "Pesquisar",
		"saved": "Nacionalidade {{id}}, guardada com sucesso.",
		"error": "Nacionalidade {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Nacionalidade {{id}}, eliminada com sucesso.",
		"fields": {
			"nNacionalidade": "Cód.",
			"codNacionQPessoal": "Cód. Nacionalidade Q. Pessoal",
			"nomeNacionQPessoal": "Nome Nacionalidade Q. Pessoal",
			"designacaoBreve": "Designação breve",
			"designacaoCompleta": "Designação completa"
		}
	},
	"naturezasContab": {
		"title_detail": "Natureza contabilística {{id}}",
		"title_new": "Nova natureza contabilística",
		"title_plural": "Naturezas contabilísticas",
		"pesquisa": "Pesquisar",
		"saved": "Natureza contabilística {{id}}, guardada com sucesso.",
		"error": "Natureza contabilística {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Natureza contabilística {{id}}, eliminada com sucesso.",
		"fields": {
			"idGrContaCorrente": "Cód. natureza contab.",
			"idGrContaCorrentePlaceholder": "código da natureza contabilística",
			"idSubGrContaCorrente": "Cód. sub-natureza contab.",
			"idSubGrContaCorrentePlaceholder": "código da sub-natureza contabilística",
			"descricao": "Descrição",
			"descricaoPlaceholder": "descrição da natureza contabilística",
			"radical": "Radical",
			"radicalPlaceholder": "radical da natureza contabilística ex.: 21111",
			"nDigitosSequencia": "Nº de dígitos da sequência",
			"nDigitosSequenciaPlaceholder": "Nº dígitos sequência ex.: se radical=21111 e tem 4 dígitos sequência então a nova conta é 211110001",
			"tipo": "Tipo"
		}
	},
	"nifs": {
		"title_detail": "NIF {{id}}",
		"title_new": "Novo NIF",
		"title_plural": "Números de Identificação Fiscais",
		"pesquisa": "Pesquisar",
		"saved": "NIF {{id}}, guardado com sucesso",
		"error": "NIF {{id}}, não pode ser guardado. Por favor, verifique os campos.",
		"deleted": "NIF {{id}}, apagado com sucesso",
		"fields": {
			"codPais": "País",
			"nContribuinte": "Nº contribuinte",
			"codRet": "Taxa de retenção",
			"tipoRetServNIF": "Entidade habitualmente",
			"temCativo": "Tem IVA cativo",
			"taxaCativo": "Taxa IVA cativo"
		},
		"modelo30": {
			"title": "Modelo 30",
			"fields": {
				"nifPt": "NIF português",
				"nifEmitente": "NIF ent. emitente",
				"partCapD": "Part. capital D",
				"partCapS": "Part. capital S"
			}
		},
		"registar": "Registar NIF",
		"registado": "O NIF {{nif}} foi registado com sucesso",
		"naoregistado": "O NIF não está registado",
		"desejaregistar": "Deseja registar o NIF?"
	},
	"nivql": {
		"title_detail": "Nível de qualificação {{id}}",
		"title_new": "Novo nível de qualificação",
		"title_plural": "Níveis de qualificações",
		"pesquisa": "Pesquisar",
		"saved": "Nível de qualificação {{id}}, guardado com sucesso",
		"error": "Nível de qualificação {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Nível de qualificação {{id}}, apagado com sucesso",
		"fields": {
			"nNivQualific": "Código do nível de qualificação",
			"designaBreve": "Designação breve",
			"designaComp": "Designação completa",
			"ruNivQualific": "Nível de qualificação"
		},
		"niveisqualificacao": {
			"0": "Sem definição",
			"1": "Quadros superiores",
			"2": "Quadros médios",
			"3": "Encarregados, contramestres, mestres e chefes de equipa",
			"4": "Profissionais altamente qualificados",
			"5": "Profissionais qualificados",
			"6": "Profissionais semi-qualificados (especializados)",
			"7": "Profissionais não qualificados (indiferenciados)",
			"8": "Estagiários, praticantes e aprendizes"
		}
	},
	"noauthority": {
		"title": "Sem autorização!",
		"message": "Contacte o administrador do sistema para mais informações."
	},
	"notificationcenter": {
		"title": "Notificações",
		"openAll": "Ver todas",
		"new": "Novas",
		"old": "Anteriores",
		"image": "Imagem",
		"newNotification": "Novas ({{count}} notificação)",
		"newNotifications": "Novas ({{count}} notificações)",
		"noNotifications": "Não existem notificações",
		"categories": {
			"0": "Todas",
			"1": "Notícias",
			"2": "Manutenções programadas",
			"all": "Todas",
			"news": "Notícias",
			"maintenance": "Manutenções programadas"
		},
		"actions": {
			"readAll": "Marcar todas como lidas"
		}
	},
	"obrigacoesAcessorias": {
		"title": "Obrigações acessórias",
		"modal": {
			"configws": {
				"title": "Configurações do WebService",
				"username": "Username do contribuinte",
				"password": "Password do contribuinte",
				"servicoInvCC": "Serviço invocado por contabilista certificado (CC)",
				"ccPowers": "CC com plenos poderes declarativos para o Contrib.",
				"usernameCC": "Username do CC",
				"passwordCC": "Password do CC",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> O serviço já se encontra configurado.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> O serviço não está configurado.",
				"configsaved": "Configuração guardada com sucesso."
			}
		}
	},
	"officereporting": {
		"ano": "Ano a analisar: {{value}}",
		"mes": "Mês a analisar: {{nameMonth}}",
		"fields": {
			"pagina": "Mapas"
		},
		"messages": {
			"erroDownload": "Ocorreu um erro ao efetuar o download do ficheiro!"
		}
	},
	"origemCondPagamento": {
		"pesquisa": "Origens condição pagamento",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"tabela": "Tabela",
			"cliente": "Cliente "
		}
	},
	"origemContasRetencao": {
		"data": {
			"ambos": "Ambos",
			"porTerceiros": "Por terceiros (Clientes)",
			"aTerceiros": "A terceiros (Fornecedores)"
		}
	},
	"origemDataVencimento": {
		"pesquisa": "Origens data vencimento",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"tabela": "Tabela",
			"condicaoPagamento": "Cond. pagamento"
		}
	},
	"pagamentos": {
		"title_new": "Novo pagamento",
		"title_detail": "Novo pagamento {{id}}",
		"title_plural": "Pagamentos",
		"pesquisa": "Pesquisar",
		"saved": "Pagamento guardado com sucesso.",
		"error": "O pagamento não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"extPocCabID": "Id.",
			"numero": "Nº pagamento",
			"nome": "Fornecedor",
			"nconta": "Conta",
			"stampUpdate": "Ult. alteração",
			"origem": "Origem do documento",
			"abreviaturaMoeda": "Moeda",
			"nDocExterno": "Nº doc. externo"
		},
		"pagamento": {
			"nCaixaAux": "Meio de pagamento",
			"nomeCaixaAux": "Nome meio pagamento",
			"tipoCaixaAux": "Tipo meio pagamento",
			"valorNCaixa": "Valor",
			"totalCaixa": "Entregue",
			"troco": "Troco",
			"fornecedor": "Fornecedor",
			"data": "Data do pagamento",
			"cab": {
				"extPocCabID": "ID cabeçalho",
				"numero": "Número",
				"nConta": "Conta",
				"descricao": "Descrição",
				"nome": "Nome",
				"nif": "NIF",
				"valor": "Valor",
				"valorPlaceHolder": "Valor a pagar",
				"stampUpdate": "Data alteração",
				"totalDescontos": "Total descontos",
				"totalPagar": "Total a pagar",
				"temRetencao": "Tem retenção",
				"totalRetencao": "Total retenção",
				"abreviaturaMoeda": "Moeda"
			},
			"linhas": {
				"summary": "Resumo",
				"nlanc": "ID linha",
				"tipoDocumento": "Tipo de documento",
				"numeroDocumento": "Documento",
				"nDocExterno": "Nº doc. externo",
				"dataVencimento": "Data venc.",
				"valor": "Valor",
				"valorPorPagar": "Valor por pagar",
				"percDesconto": "Desconto %",
				"valorDesconto": "Valor desconto",
				"valorAPagar": "Valor a pagar",
				"valorIVA": "Valor de IVA",
				"montanteAReter": "Retenção",
				"montanteRetido": "Valor da retenção",
				"abreviaturaMoeda": "Moeda",
				"cambio": "Câmbio",
				"cambioOrigem": "Câmbio origem",
				"cambioPagam": "Câmbio pagamento",
				"dataDoc": "Data doc.",
				"dataDocExterno": "Data doc. externo",
				"descricao": "Descrição",
				"valorDoc": "Valor do documento"
			},
			"disableRevoke": "O pagamento não pode ser anulado porque não foi efetuado no CentralGest Cloud",
			"pdf": "PDF",
			"revoke": "Anular",
			"summary": "Resumo",
			"totalPago": "Total pago",
			"downloadSepa": "Download SEPA",
			"generateAndDownloadSepa": "Gerar SEPA"
		},
		"info": {
			"anular": "Pagamento anulado com sucesso!",
			"anularpagamento": "Anular pagamento",
			"temCertezaAnularpagamento": "Tem a certeza que pretende eliminar o pagamento?",
			"pagamentoPorMeioPagamSepa": "Pagamento efetuado por meio de pagamento SEPA.",
			"avisoAnularPagamSepa": "Atenção que vai anular um pagamento efetuado por meio de pagamento SEPA e ao continuar vai eliminar o ficheiro de transferência SEPA que pode conter outros pagamentos. \n Tem a certeza que pretende eliminar o pagamento?",
			"semMovimentos": "Sem movimentos em aberto!",
			"vencido": "Vencido",
			"naoVencido": "Não vencido",
			"aPagar": "A pagar"
		},
		"erros": {
			"notselected": "Nenhum pagamento selecionado!",
			"anular": "Erro na anulação do pagamento!",
			"valorDescontoMaiorValor": "Valor do desconto superior ao valor recebido",
			"percentagemDescontoMaior100": "A percentagem de desconto não pode ser superior a 100%",
			"moedasdiferentesnosrecibos": "Não é possível existir moedas diferentes nos pagamentos a lançar",
			"errodesconhecido": "Erro desconhecido",
			"invalidHeaderDate": "A data do pagamento não pode ser superior a 1 ano relativamente à data atual",
			"valorUltrapassado": "O valor a distribuir foi ultrapassado",
			"faltaDistribuirValor": "Tem de distribuir a totalidade do valor inserido"
		},
		"text": {
			"autoDistribute": "Distribuir valor automaticamente",
			"amountToPay": "Valor a pagar"
		},
		"list": {
			"gestaoFicheirosSepa": "Gestão de ficheiros SEPA",
			"notifyMessage": "Deseja notificar os fornecedores relativos aos pagamentos selecionados?",
			"notifyTitle": "Enviar notificações"
		},
		"saveModal": {
			"title": "Guardar pagamento",
			"paymentMean": "Meio de pagamento",
			"paymentValue": "Valor do meio de pagamento",
			"payInFull": "Pagar totalidade",
			"clearValue": "Limpar valor",
			"document": "Documento",
			"save": "Guardar",
			"saveCheck": "Guardar e ver PDF",
			"saveGenerateSEPA": "Guardar e criar SEPA",
			"saveGestaoSEPA": "Guardar na Gestão SEPA",
			"erros": {
				"naoDefiniuMeioPagamento": "Não definiu o meio de pagamento.",
				"naoSatisfazValorDoc": "O valor entregue não satisfaz o valor do documento.",
				"trfSepaSoPodeTerUmMeio": "O pagamento \"Transferência Bancária\" por ficheiro SEPA só pode ter um meio de pagamento definido.",
				"trfSepaMeioPagamentoNaoValido": "O meio de pagamento definido não é do tipo \"Transferência Bancária\".",
				"trfSepaMeioPagamentoSemIban": "O meio de pagamento não tem definido o IBAN.",
				"trfSepaMeioPagamentoSemSwift": "O meio de pagamento não tem definido o SWIFT."
			},
			"success": {
				"saveGestaoSepa": "Guardado na Gestão de Ficheiros SEPA, poderá gerar mais tarde."
			}
		},
		"btn": {
			"pagaTudo": "Pagar tudo",
			"limpaTudo": "Limpar todos os valores pagos",
			"distribuirValores": "Distribuir valor",
			"limpaValor": "Limpar valor pago"
		},
		"modal": {
			"pagamentoimprimir": {
				"label": {
					"emailfornecedor": "E-mail do fornecedor",
					"enviar": "Enviar"
				},
				"email": {
					"required": "Para enviar, por favor, preencha o campo \"<em>E-mail do fornecedor</em>\"",
					"emailEnviado": "E-mail enviado para {{email}}",
					"emailErro": "Erro a enviar e-mail para {{email}}. Contacte o seu administrador de sistema."
				},
				"placeholder": {
					"email": "\"mail@exemplo.com\" ou \"mail@exemplo.com; mail2@exemplo.com\""
				},
				"btn": {
					"downloadSEPA": "Transferir ficheiro SEPA"
				}
			}
		},
		"modalDistribuir": {
			"tile": "Distribui valor",
			"automatico": "Automático",
			"manual": "Manual",
			"tooltip": {
				"automatico": "Ordenado do mais antigo para o mais recente.",
				"manual": "Permite distribuir até atingir o valor indicado."
			}
		}
	},
	"paises": {
		"title_detail": "País {{id}}",
		"title_new": "Novo País",
		"title_plural": "Países",
		"pesquisa": "Pesquisar",
		"saved": "País {{id}}, guardado com sucesso",
		"error": "País {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "País {{id}}, apagado com sucesso",
		"fields": {
			"codPais": "Cód. país",
			"nomePais": "Nome",
			"abrevFiscal": "Abreviatura fiscal",
			"abrevPais": "Abreviatura do país",
			"nomePaisPropriaLingua": "Nome do país na própria língua",
			"codMoeda": "Código da moeda",
			"codLingua": "Código da língua",
			"mercado": "Mercado",
			"codISO": "Código internacional"
		},
		"mercado": {
			"data": {
				"intraComuni": "Intra comunitário",
				"extraComuni": "Extra comunitário"
			}
		}
	},
	"paisesISO3166": {
		"title": "Países ISO 3166",
		"pesquisa": "Pesquisar países ISO 3166",
		"fields": {
			"codigo": "Cód.",
			"designacaoPT": "Designação (PT)",
			"codigoAlpha2": "ISO3166 Alfa-2",
			"codigoAlpha3": "ISO3166 Alfa-3",
			"designacao": "Nome própria língua"
		}
	},
	"partners": {
		"millennium": {
			"title": "Millennium",
			"erros": {
				"invalidTargetOrigin": "Ocorreu um erro a carregar as configurações.",
				"invalidUsernameOrPassword": "Por favor preencha os campos de autenticação.",
				"invalidRecaptcha": "Por favor marque a caixa de verificação para confirmar que não é uma máquina.",
				"partnerAssociateInvalidToken": "Ocorreu um erro ao tentar associar a sua empresa. O NIF fornecido não é válido.",
				"partnerAssociateUserNotFound": "A autenticação falhou. Por favor verifique as suas credenciais e tente novamente.",
				"noCompaniesWereFoundWithTheProvi": "Não foi encontrada nenhuma empresa com o NIF fornecido.",
				"partnerIsActivePleaseSetUnactive": "Já existe uma associação para esta empresa. Se pretende associar novamente, por favor desassocie a mesma e tente novamente."
			}
		}
	},
	"pca": {
		"contabilidade": {
			"anos": "Anos",
			"ficheiros": "Ficheiros",
			"periodo": "Período",
			"encerrado": "encerrado",
			"semFicheiros": "Sem ficheiros"
		},
		"empresagabinete": {
			"extratosconta": {
				"fields": {
					"nDiario": "Cód.",
					"nomeDiario": "Diário",
					"nConta": "Cód. cliente",
					"nome": "Nome cliente",
					"periodo": "Período",
					"nDocumento": "Nº doc.",
					"nDescritivo": "Cód. descritivo",
					"nomeDescritivo": "Nome descritivo",
					"nDocExterno": "Nº doc. externo",
					"dataLancamento": "Data lançamento",
					"valorDebito": "Débito",
					"valorCredito": "Crédito",
					"valor": "Valor",
					"dataDoc": "Data doc."
				}
			},
			"movimentosaberto": {
				"fields": {
					"tipoDocumento": "Tipo doc.",
					"numeroDocumento": "Nº doc.",
					"dataVencimento": "Data venc.",
					"valorPorReceber": "Valor a receber",
					"valorRecebido": "Valor recebido",
					"valorDesconto": "Valor desconto",
					"valorIVA": "Valor IVA",
					"valor": "Valor",
					"nConta": "Cód. cliente",
					"nome": "Nome cliente",
					"nif": "Nº contrib."
				}
			}
		}
	},
	"perfilCategoriaAbono": {
		"title": "Perfis categorias abonos",
		"fields": {
			"tipoArredondamento": "Arredondamento",
			"tipoRecolha": "Tipo recolha",
			"contribuiCalculoCustoPrecoH": "Contribui cálculo custo preço/hora",
			"codRem": "Cód. rem. seg. social",
			"codUnidade": "Unidade defeito",
			"tipoRendimento": "Tipo rendimento IRS",
			"contaMovDeb": "Abono - Conta débito",
			"contaMovCred": "Abono - Conta crédito",
			"contaEncDEB": "Encargos patronato/empresa - Conta débito",
			"contaEncCRE": "Encargos patronato/empresa - Conta crédito"
		},
		"crabdTable": {
			"fields": {
				"categoria": "Categoria",
				"empregado": "Ent. suporte empregado",
				"patronato": "Ent. suporte patronato",
				"desconto": "Desconto",
				"nomeCategoria": "Categoria",
				"temIncidenciaEmpregado": "Tem",
				"incidenciaEmpregado": "Incidência",
				"temIncidenciaPatronato": "Tem",
				"incidenciaPatronato": "Incidência",
				"nDesconto": "Cód.",
				"nomeDesconto": "Descrição"
			}
		}
	},
	"perfilCategoriaDesconto": {
		"title": "Perfis categorias descontos",
		"fields": {
			"categoria": "Categoria",
			"tipoArredondamento": "Arredondamento",
			"tipoRecolha": "Tipo recolha",
			"contribuiCalculoCustoPrecoH": "Contribui cálculo custo preço/hora",
			"codRem": "Cód. rem",
			"codUnidade": "Unidade defeito",
			"tipoRendimento": "Tipo rendimento",
			"contaMovDeb": "Desconto - Conta débito",
			"contaMovCred": "Desconto - Conta crédito",
			"contaEncDEB": "Encargos patronato/empresa - Conta débito",
			"contaEncCRE": "Encargos patronato/empresa - Conta crédito"
		},
		"crabdTable": {
			"fields": {
				"categoria": "Categoria",
				"empregado": "Ent. suporte empregado",
				"patronato": "Ent. suporte patronato",
				"desconto": "Desconto",
				"nomeCategoria": "Categoria",
				"temIncidenciaEmpregado": "Tem",
				"incidenciaEmpregado": "Incidência",
				"temIncidenciaPatronato": "Tem",
				"incidenciaPatronato": "Incidência",
				"nDesconto": "Cód.",
				"nomeDesconto": "Descrição"
			}
		}
	},
	"periocidadeContratoTipo": {
		"data": {
			"sempre": "Sempre",
			"diario": "Diário",
			"semanal": "Semanal",
			"quinzenal": "Quinzenal",
			"mensal": "Mensal",
			"bimensal": "Bimensal",
			"trimestral": "Trimestral",
			"semestral": "Semestral",
			"anual": "Anual"
		}
	},
	"periodos": {
		"title_detail": "Período de contabilidade {{id}}",
		"title_plural": "Períodos de contabilidade",
		"pesquisa": "Pesquisar",
		"fields": {
			"periodo": "Período",
			"periodoPlaceholder": "Período de contabilidade",
			"nome": "Nome",
			"nomePlaceholder": "Nome do período de contabilidade"
		}
	},
	"periodosIVA": {
		"data": {
			"mensal": "Mensal",
			"trimestral": "Trimestral"
		}
	},
	"planoscontasalternativos": {
		"title_detail": "Plano contas alternativo \"{{id}}\"",
		"title_new": "Nova plano contas alternativo",
		"title_plural": "Planos contas alternativos",
		"pesquisa": "Pesquisar",
		"saved": "Plano contas alternativo, guardado com sucesso.",
		"error": "Plano contas alternativo, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Plano contas alternativo, apagado com sucesso",
		"fields": {
			"nome": "Nome",
			"tipoValidacao": "Tipo validação",
			"contas": "Contas",
			"nconta": "Nº conta",
			"ncontaEdit": "Nº conta alternativa",
			"nomeEdit": "Descrição conta alternativa",
			"tipoConta": {
				"title": "Tipo conta",
				"movimento": "Movimento",
				"subtotal": "Sub-total",
				"razao": "Razão",
				"classe": "Classe"
			},
			"contasradicais": "Contas/Radicais",
			"nradical": "Nº/Radical de conta",
			"incluiexclui": "Inclui/Exclui",
			"inclui": "Inclui",
			"exclui": "Exclui",
			"contasalt": "Contas alternativas",
			"radicaisValidacao": "Radicais validação",
			"escondeRubricasSemMovimento": "Esconde rúbricas sem movimento",
			"inverteSinal": "Inverter sinal contabilístico",
			"inverteSinalInfo": "Ao ativar esta opção os valores serão apresentados com sinal negativo e os valores credores com sinal positivo."
		},
		"actions": {
			"importPAlt": "Importar plano alternativo",
			"exportPlano": "Exportar este plano",
			"getModImportPAlt": "Obter modelo de importação do plano alternativo"
		},
		"datasource": {
			"data": {
				"validaGeral": "Valida geral",
				"naoValida": "Não valida",
				"validaAnalitica": "Valida analítica",
				"validaRadicais": "Valida radicais"
			}
		},
		"tipoconta": {
			"0": "Movimento",
			"1": "Sub-Total",
			"2": "Razão",
			"3": "Classe"
		},
		"messages": {
			"exportedsuccess": "Plano alternativo exportado com sucesso!",
			"modeloImportContasAlt": "Obtido modelo de importação do plano alternativo com sucesso!",
			"errorFiles": "Deve carregar pelo menos um ficheiro.",
			"importPlanoAlt": "Plano alternativo importado com sucesso!",
			"notSelectPlanoContAlt": "Não selecionou nenhum plano contas alternativo."
		},
		"modals": {
			"conta": {
				"title": "Conta plano contas alternativo",
				"ncontaemprty": "O nº conta não pode ser vazio.",
				"radicalInvalid": "Valor do nº/radical de conta é inválido.",
				"radicalempty": "O nº/radical de conta não pode ser vazio."
			},
			"import": {
				"title": "Importar plano contas alternativo"
			},
			"cubo": {
				"btn": "Cubo",
				"title": "Cubo plano contas alternativo",
				"layoutCubo": {
					"caption": "Estilo de pré-visualização do cubo",
					"saldoGeral": "Saldo geral",
					"saldoCredDeb": "Saldo crédito/débito"
				},
				"fields": {
					"acumuladoCredito": "Acumulado crédito",
					"acumuladoDebito": "Acumulado débito",
					"nconta": "Nº conta",
					"ncontaNome": "Nº de conta - nome",
					"nmesNome": "Nº mês - nome",
					"nome": "Nome",
					"nomeNConta": "Nome - Nº de conta",
					"periodo": "Período",
					"periodoCredito": "Período crédito",
					"periodoDebito": "Período débito",
					"saldoCredito": "Saldo crédito",
					"saldoDebito": "Saldo débito",
					"saldoGeral": "Saldo geral"
				}
			},
			"extrato": {
				"titleAcumu": "Acumulados do plano de contas alternativo",
				"titleExtrato": "Extrato do plano de contas alternativo",
				"fields": {
					"credito": "Crédito",
					"debito": "Débito",
					"documento": "Documento",
					"nDiario": "Nº diário",
					"nDocExterno": "Nº doc. externo",
					"nDocInterno": "Nº doc. interno",
					"nconta": "Nº conta",
					"nome": "Nome",
					"periodo": "Período"
				}
			}
		}
	},
	"pocs": {
		"title_detail": "Conta do plano de contas {{id}}",
		"title_new": "Nova conta do plano de contas",
		"title_plural": "Plano de Contas",
		"pesquisa": "Pesquisar",
		"saved": "Conta do plano de contas {{id}}, guardada com sucesso",
		"error": "Conta do plano de contas {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Conta do plano de contas {{id}}, apagada com sucesso",
		"actions": {
			"maintenance": {
				"new": "Criar nova conta",
				"edit": "Editar conta"
			}
		},
		"fields": {
			"nConta": "Nº conta",
			"nContaPlaceholder": "Código da conta",
			"nome": "Nome",
			"nomePlaceholder": "Nome da conta",
			"tipo": "Tipo conta",
			"cc": "Tem conta corrente",
			"nif": "Nº contribuinte",
			"temCCusto": "Tem analítica",
			"temIVA": "Tem controlo IVA",
			"registaRetencao": "Regista retenção",
			"temRetencao": "Tem retenção",
			"bloqueada": "Bloqueada",
			"dpq06NCampo": "Dec. periódica IVA",
			"dpq06NCampoNome": "Dec. periódica IVA",
			"daq08NCampo": "Dec. anual IVA - Quadro 03 e 05",
			"daq08NCampoNome": "Dec. anual IVA - Quadro 03 e 05",
			"daq09NCampo": "Dec. anual IVA - Quadro 04 e 05",
			"daq09NCampoNome": "Dec. anual IVA - Quadro 04 e 05",
			"codControloIVADebito": "Cód. controlo IVA débito",
			"codControloIVADebitoNome": "Cód. controlo IVA débito",
			"codControloIVACredito": "Cód. controlo IVA crédito",
			"codControloIVACreditoNome": "Cód. controlo IVA crédito",
			"codTaxonomia": "Cód. taxonomia"
		},
		"text": {
			"maintenancePocs": "Manutenção de números de contribuinte",
			"avisoAlteracaoCC1": "A conta já tem lançamentos efetuados na contabilidade.",
			"avisoAlteracaoCC2": "Ao efetuar esta operação todos os lançamentos ficarão em aberto.",
			"avisoAlteracaoCC3": "Tem a certeza que pretende continuar?",
			"pretendeContinuar": "Tomei conhecimento e pretendo continuar"
		},
		"list": {
			"dpq06": "Dec. periódica",
			"daqAnual": "Dec. anual"
		},
		"tipocontacontab": {
			"0": "Movimento",
			"1": "Sub-total",
			"2": "Razão",
			"3": "Classe"
		},
		"validations": {
			"deConta": "A conta introduzida não pode ser maior que a conta do campo \"Até conta\"",
			"ateConta": "A conta introduzida não pode ser menor que a conta do campo \"De conta\""
		},
		"alerts": {
			"contaCorrente": "A conta que está a criar é uma conta corrente, este tipo de conta deve ser criada no módulo de manutenção das fichas de clientes/fornecedores ou outros devedores e credores."
		},
		"exportXls": "Exportar",
		"btns": {
			"copiaContasPlanoEmpresa": "Copia contas plano p/ empresas"
		}
	},
	"pocscopy": {
		"exportcontas": "Contas_plano_contas",
		"exportempresas": "Empresas_disponiveis",
		"exporterror": "Erros_contas",
		"fields": {
			"nconta": "Nº conta",
			"nome": "Nome",
			"tipo": "Tipo",
			"temccusto": "Tem C. Custo",
			"temiva": "Tem IVA",
			"ncontade": "Nº conta de",
			"nEmpresaNome": "Empresa",
			"nContaNome": "Conta"
		},
		"steps": {
			"intro": {
				"step": "Introdução",
				"title": "Bem-vindo ao assistente de cópia de contas do plano de contras entre empresas.",
				"description": "Este assistente vai guiá-lo no processo de cópia das contas.",
				"description2": "A qualquer momento pode cancelar a operação."
			},
			"contas": {
				"step": "Contas",
				"title": "Escolha de contas a copiar.",
				"descriptions": "Por favor selecione as contas que pretende copiar."
			},
			"empresas": {
				"step": "Empresas",
				"title": "Escolha de empresas.",
				"descriptions": "Por favor escolha as empresas para onde pretende copiar a(s) conta(s)."
			},
			"relatorio": {
				"step": "Relatório",
				"title": "Configuração concluída.",
				"descriptions": "Conta(s) copiada(s) com sucesso.",
				"titleerror": "Erros na cópia de contas",
				"descriptionserror": "Ocorreram erros a copiar as contas, não foram feitas quaisquer alterações. Por favor verifique os erros ocorridos."
			},
			"errorcontassel": "Não selecionou nenhuma conta para copiar.",
			"errorempresassel": "Não selecionou nenhuma empresa para onde pretende copiar a(s) conta(s).",
			"success": ""
		},
		"messages": {
			"pesqsuccess": "Pesquisa efetuada com sucesso."
		}
	},
	"portals": {
		"title_detail": "Portal {{id}}",
		"title_new": "Novo Portal",
		"title_plural": "Portais",
		"pesquisa": "Pesquisar",
		"saved": "Portal {{id}}, guardado com sucesso",
		"deleted": "Portal {{id}}, apagado com sucesso",
		"error": "Portal {{id}}, não pode ser guardado. Por favor verifique os dados introduzidos.",
		"fields": {
			"id": "id",
			"name": "Nome",
			"description": "Descrição",
			"icon": "Icon",
			"url": "Url",
			"data": "Dados de configuração do portal",
			"sticky": "Fixo",
			"sortOrder": "Ordenação",
			"roles": "Permissões",
			"includes": "Inclui"
		},
		"actions": {
			"sort": "Ordenar portais"
		},
		"text": {
			"noAccess": "Não tem acesso a este portal.",
			"editNotAllowed": "Não pode editar portais do sistema",
			"deleteNotAllowed": "Não pode apagar portais do sistema",
			"forbiddenMessage": "Existem utilizadores com acesso a este portal, se apagar o mesmo, serão também eliminados os acessos existentes. Deseja continuar?",
			"showModuleName": "Mostrar nome do módulo",
			"tooltipIncludes": "Os utilizadores com acesso a este portal, terão também acesso aos portais incluídos",
			"sidebarTitle": "Título na barra lateral"
		},
		"btn": {
			"copy": "Copiar nome do modulo",
			"remove": "Remover",
			"edit": "Editar",
			"toggleVisibility": "Visível"
		},
		"items": {
			"erp": "Comercial",
			"financeiro": "Financeiro",
			"contabilidade": "Contabilidade",
			"ativos": "Ativos",
			"rh": "Recursos Humanos",
			"pca": "My Accounting",
			"clientconnect": "Client connect",
			"gestorrh": "Gestor RH",
			"gestorservico": "Gestor serviço",
			"colaborador": "Colaborador",
			"gestorementas": "Gestor ementas",
			"configuracoes": "Configurações",
			"contabilidadepremium": "Contabilidade Premium",
			"erpadvanced": "Comercial Avançado"
		},
		"module": {
			"newMenu": "Novo menu",
			"menu": "Menu",
			"modulosdisponiveis": "Módulos disponíveis"
		},
		"menu": {
			"erp": {
				"clientes": "Clientes",
				"fornecedores": "Fornecedores",
				"consultasvendas": "Consultas de vendas",
				"consultascompras": "Consultas de compras",
				"contascorrentes": "Contas Correntes",
				"stocks": "Stocks",
				"tesouraria": "Tesouraria",
				"integracoes": "Integrações",
				"configuracoes": "Configurações",
				"manutencoes": "Manutenções"
			},
			"pca": {
				"contabilidade": "Contabilidade",
				"abonosdescontos": "Abonos e descontos",
				"mapassalarios": "Mapas Salários",
				"prestacaocontas": "Prestação de contas",
				"minhaconta": "A minha conta"
			},
			"gestorementas": {
				"colaborador": "Colaborador",
				"menus": "Menus",
				"refeicoes": "Refeições"
			},
			"gestorrh": {
				"colaborador": "Colaborador",
				"servicos": "Serviços",
				"tabelas": "Tabelas",
				"marcacaoeventos": "Marcação de eventos"
			},
			"gestorservico": {
				"colaborador": "Colaborador",
				"marcacaoeventos": "Marcação de eventos"
			},
			"contabilidade": {
				"lancamentos": "Lançamentos",
				"consultas": "Consultas",
				"manutencaoContas": "Manutenção de contas",
				"declaracoesFiscais": "Declarações fiscais",
				"fechoContas": "Fecho de contas",
				"ferramentas": "Ferramentas",
				"manutencoes": "Manutenções"
			},
			"configuracoes": {
				"atalhos": "Atalhos assistentes de configuração",
				"configuracaoerp": "Configuração ERP"
			},
			"ativos": {
				"manutencoesTitle": "Manutenções",
				"manutencoes": "Manutenções",
				"consultarTitle": "Consultar",
				"consultar": "Consultar",
				"atalhoscontabilidadeTitle": "Atalhos à contabilidade",
				"atalhoscontabilidade": "Atalhos à contabilidade",
				"tabelasTitle": "Tabelas",
				"tabelas": "Tabelas"
			},
			"rh": {
				"transferencias": "Transferências",
				"recolhadados": "Recolha de dados",
				"marcacaocalendario": "Marcação em calendário",
				"processamento": "Processamento",
				"obrigacoeslegaisfiscais": "Obrigações legais e fiscais",
				"integracaocontabilidade": "Integração contabilidade",
				"consultas": "Consultas",
				"abonosdescontos": "Abonos / Descontos",
				"ferramentas": "Ferramentas",
				"configuracoes": "Configurações",
				"manutencoes": "Manutenções"
			},
			"financeiro": {
				"clientes": "Clientes",
				"fornecedores": "Fornecedores",
				"manutencoes": "Manutenções"
			}
		},
		"sidebar": {
			"modules": {
				"adiantamentos": "Adiantamentos",
				"analiticaconfig": "Configuração de contas de analítica",
				"apuraiva": "Apuramento de IVA",
				"apuramentoresultados": "Apuramento de resultados",
				"atFaturasRecibosVerdes": "Recibos verdes vendas",
				"ativosaquisicaocontab": "Registar ativos com base na contabilidade",
				"ativosaquisicaoinvestemcurso": "Investimentos em curso",
				"ativoscoeficientecm": "Coeficientes correção monetária",
				"ativosconsistencia": "Consistência com a contabilidade",
				"balancetes": "Balancetes",
				"balancetesalternativos": "Balancetes planos alternativos",
				"balancetesanalitica": "Balancetes analítica",
				"controloivaentreempresas": "Copiar controlo IVA p/ empresas",
				"declaracaoiva": "Dec. periódica IVA",
				"declaracaoivarecapitulativa": "Dec. recapitulativa IVA",
				"demfinsnc": "Dem. financeiras SNC",
				"descritivos": "Descritivos",
				"diarios": "Diários",
				"dmrf": "Dec. retenções na fonte",
				"docsComerciaisEstatisticas": "Estatísticas por artigo",
				"docsComerciaisEstatisticasDoc": "Estatísticas por documento",
				"docscontabilidade": "Recolha",
				"documentosdigitaisConciliacao": "Conciliação docs. digitais",
				"extratosDT": "Extrato histórico",
				"faturacaoeletronicaconfiguracoes": "Faturação eletrónica",
				"faturacaopublica": "Faturação pública",
				"gto": "Gestão de tarefas",
				"impdecpe": "Extratos dec. periódica",
				"importadorEfatura": "Compras eFatura",
				"importadorSaftDirect": "Importador vendas",
				"infoglobalacrescimos": "Info. acréscimos",
				"infoglobaldiferimentos": "Info. diferimentos",
				"infoglobaldiferimentosacrescimos": "Info. diferimentos/acréscimos",
				"listagemdiarios": "Diários",
				"listagensVendasAnoMes": "Resumo por mês",
				"manuttributautonomas": "Manut. tributações autónomas",
				"mapasamortizacao": "Mapas oficiais",
				"mbconfigs": "Referências MB",
				"meiosPagamento": "Meios pagamento",
				"movimentosemaberto": "Movimentos em aberto",
				"pcaContabilidadeMovimentosAbertoCliente": "Movimentos em aberto",
				"pcaContabilidadeMovimentosAbertoFornecedor": "Movimentos em aberto",
				"pcaMovimentosAbertoCliente": "Mov. em aberto",
				"periodos": "Períodos",
				"pocscopy": "Copiar contas plano p/ empresas",
				"predefinidoscontabilidade": "Pré-definidos",
				"prheventosconfig": "Config. eventos",
				"proceDMRAT": "DMR AT",
				"proceSalarios": "Salários",
				"proceSegSocial": "DMR SS (DRI)",
				"procseguradorafolhaferias": "Folha férias para seguradora",
				"qivaas": "Campos dec. anual IVA",
				"qivaps": "Campos dec. periódica IVA",
				"remfx": "Dados fixos",
				"remun": "Dados manuais",
				"reparticoesccustosfaturacao": "Repartições C. Custo",
				"reparticoesccustossalarios": "Repartições C. Custo",
				"rhassistentetrabalhador": "Integração salários",
				"rhdadosconfigficha": "Dados da ficha",
				"rhmanutencao": "Manutenção",
				"rhprocessamentosalarios": "Processamento salários",
				"rhrecolhaseriesubsidiosalimentacao": "Subsídio de alimentação",
				"rhrecolhaseriesubsidiosferias": "Subsídio de férias",
				"rhrecolhaseriesubsidiosnatal": "Subsídio de natal",
				"rhsaltotrf": "Salários",
				"saft": "SAF-T Completo",
				"solic": "Textos avisos cobrança",
				"svat": "Dem. financeiras SVAT",
				"tesrubrica": "Rubricas tesouraria",
				"trfba": "Pagamentos SEPA",
				"trfbacomercial": "Pagamentos SEPA",
				"trfbarh": "Pagamentos SEPA",
				"mapadiferimentosacrescimos": "Mapa acréscimos e diferimentos",
				"grupodocfaconfiguracoes": "Grupos de tipos de documentos",
				"analisemargemlucro": "Análise margem de lucro"
			},
			"contabilidade": {
				"clientes": "Clientes",
				"fornecedores": "Fornecedores",
				"outrosdevedorescredores": "Outros devedores e credores"
			},
			"rh": {
				"integracaoSalarios": "Empresa",
				"integracaoSalariosMulti": "Multi-empresas"
			}
		},
		"modals": {
			"editnode": {
				"title": "Alterar dados da entrada de menu",
				"fields": {
					"menuTitle": "Título do menu",
					"pageTitle": "Título do módulo",
					"sidebarTitle": "Título do módulo na barra lateral",
					"sidebarTitleTooltip": "Caso este campo não esteja preenchido, o valor será o mesmo do campo \"Título do módulo\"",
					"icon": "Icon"
				}
			},
			"sort": {
				"title": "Definir ordenação dos portais",
				"success": "Portais ordenados com sucesso"
			}
		}
	},
	"predefinidoscontabilidade": {
		"title_plural": "Pré-definidos de contabilidade",
		"pesquisa": "Pesquisar",
		"success": {
			"save": "Pré-definido de contabilidade guardado com sucesso",
			"edit": "Pré-definido de contabilidade \"{{id}}\" guardado com sucesso",
			"delete": "Pré-definido de contabilidade \"{{id}}\" apagado com sucesso",
			"simulate": "Documento de contabilidade gravado com sucesso (simulação)"
		},
		"steps": {
			"operation": "Operação a efetuar",
			"basic": "Configuração dos dados",
			"header": "Configuração dos dados de cabeçalho",
			"lines": "Configuração dos dados de linhas",
			"preview": "Pré-visualização do pré-definido",
			"companies": "Escolha de empresas",
			"finalize": "Finalizar"
		},
		"fields": {
			"preDefinidosID": "Código",
			"description": "Descrição",
			"data": "Dados",
			"revision": "Revisão",
			"cgBanking": "Para usar em CentralGest Banking",
			"document": "Documento",
			"copyExisting": "Pré-definido a copiar"
		},
		"titles": {
			"chooseOperation": "Por favor escolha a operação que pretende efetuar",
			"notAvailable": "Esta opção ainda não está disponível",
			"suggestCodigo": "Sugerir código",
			"suggestCodigoToolTip": "Sugerir código único para todas as empresas",
			"legend": "Legenda",
			"verified": "Campo verificado",
			"notVerified": "Campo não verificado",
			"availableCompanies": "Empresas disponíveis",
			"companiesToSave": "Empresas onde será gravado o pré-definido",
			"companiesToRemove": "Empresas onde será apagado o pré-definido",
			"selectColumns": "Por favor escolha as empresas onde pretende criar o pré-definido",
			"selectColumnsEdit": "Por favor escolha as empresas onde pretende alterar o pré-definido",
			"selectColumnsDelete": "Por favor escolha as empresas onde pretende apagar o pré-definido"
		},
		"operations": {
			"new": "Criar novo pré-definido",
			"newBasedOnDoc": "Criar novo pré-definido com base num documento de contabilidade",
			"newBasedOnExisting": "Criar novo pré-definido com base num já existente",
			"newAdvanced": "Criar novo pré-definido avançado",
			"edit": "Alterar pré-definido",
			"delete": "Apagar pré-definido"
		},
		"errors": {
			"invalidDocContab": "O documento de contabilidade \"{{doc}}\" não existe",
			"invalidPreDefinido": "O pré-definido \"{{predefinido}}\" não existe"
		},
		"btn": {
			"simulate": "Simular gravação",
			"clear": "Limpar documento"
		}
	},
	"predefinidocontabcab": {
		"periodo": "Período",
		"diario": "Diário",
		"nDocInterno": "Nº doc. interno",
		"dataLancamento": "Data lançamento contab.",
		"dataVencimento": "Data vencimento",
		"dataDoc": "Data documento",
		"nContribuinte": "Nº contribuinte",
		"nDocExterno": "Nº doc. externo",
		"descricao": "Descrição",
		"descritivo": "Descritivo",
		"dataTransacaoBanco": "Data transação banco",
		"titles": {
			"fieldProperties": "Propriedades do campo",
			"visualProperties": "Propriedades visuais"
		},
		"fields": {
			"readonly": "Só de leitura",
			"tabStop": "Cursor para no campo",
			"visible": "Visível",
			"tipoValorPeriodo": "Origem do valor",
			"tipoValorDiario": "Origem do valor",
			"valorFixo": "Valor fixo",
			"valorFixoDiario": "Diário fixo",
			"valorFixoNDocExterno": "Nº doc. externo fixo",
			"valorFixoDescricao": "Descrição fixa",
			"valorFixoDescritivo": "Descritivo fixo",
			"valorFixoConta": "Conta fixa",
			"origem": "Origem",
			"incrementaAutomaticamente": "Incremento automático",
			"origemValor": "Origem do valor"
		},
		"errorInvalid": "Tem de verificar todos os campos de cabeçalho para continuar",
		"enums": {
			"tipoValorPeriodo": {
				"0": "Tabela de empresa",
				"1": "Data do sistema",
				"2": "Data do doc. e-fatura",
				"3": "Data transação banco"
			},
			"tipoValorDiario": {
				"0": "Não definido",
				"1": "Fixo"
			},
			"origemDataDoc": {
				"0": "Do período do doc.",
				"1": "Data do doc. e-fatura"
			},
			"origemDataVencimento": {
				"0": "Do período do doc.",
				"1": "Cond. pagam. entidade e-fatura"
			},
			"origemDataLancamento": {
				"0": "Último dia do período",
				"1": "Data atual",
				"2": "Data do doc. e-fatura"
			},
			"valorDescricao": {
				"0": "Não definido",
				"1": "Fixo",
				"2": "Fórmula"
			},
			"valorDescritivo": {
				"0": "Não definido",
				"1": "Igual último doc. do período/diário",
				"2": "Fixo"
			}
		}
	},
	"predefinidocontablinhas": {
		"addLine": "Adicionar linha",
		"duplicateLine": "Duplicar linha selecionada",
		"moveLineUp": "Mover linha para cima",
		"moveLineDown": "Mover linha para baixo",
		"removeLine": "Remover linha selecionada",
		"reorder": "Reordenar colunas",
		"errorInvalid": "Não definiu nenhuma linha para o pré-definido",
		"titles": {
			"fieldProperties": "Propriedades do campo \"{{field}}\" ({{cell}})",
			"visualProperties": "Propriedades visuais",
			"emptyLines": "A tabela de linhas está vazia. Para adicionar uma linha for favor carregue no botão acima com a descrição \"Adicionar linha\"",
			"baseTributavelProperties": "Propriedades de base tributável",
			"contaCorrenteProperties": "Propriedades de conta corrente",
			"ask": "Pergunta",
			"salda": "Salda",
			"arredEFatura": "Arred. eFatura",
			"hintFormula": "Para editar, clique na calculadora ao lado direito"
		},
		"fields": {
			"conta": "Conta",
			"valor": "Valor",
			"valorIva": "Valor IVA",
			"nContribuinte": "Nº contribuinte",
			"descricao": "Descrição",
			"nDocExterno": "Nº doc. externo",
			"descritivo": "Descritivo",
			"dataLancamento": "Data lançamento contab.",
			"dataVencimento": "Data vencimento",
			"dataDoc": "Data documento",
			"nomeConta": "Nome da conta",
			"dC": "Débito/Crédito",
			"radical": "Radical",
			"tipoConta": "Tipo de conta",
			"temImputacao": "Seleciona valores a liquidar",
			"vaiImputarALinha": "Vai imputar à linha",
			"codRepCC": "Cód. repartição c. custo automático",
			"tipoValor": "Tipo de valor",
			"valorFormula": "Valor por fórmula",
			"colocaValorEFaturaDocDigital": "Coloca valor eFatura ou doc. digital",
			"incrementaAutomaticamente": "Incremento automático",
			"tipoValorBaseTributavel": "Valor com/sem IVA",
			"importEFaturaSuportaTaxaIsenta": "Suporta taxa isenta imp. eFatura",
			"importEFaturaValorImpSelo": "Coloca imposto selo imp. eFatura",
			"importEFaturaContaUnicaSemDeducao": "Conta única sem dedução de imposto",
			"importFRVSuportaTaxaIsenta": "Suporta taxa isenta rec. verdes emitidos",
			"importFRVValorImpSelo": "Coloca imposto selo rec. verdes emitidos"
		},
		"enums": {
			"tipoConta": {
				"0": "Não definido",
				"1": "Conta corrente",
				"2": "Base tributável",
				"3": "CG Banking conta banco"
			},
			"tipoValor": {
				"0": "Pergunta",
				"1": "Fixo",
				"2": "Fórmula",
				"3": "Salda documento",
				"4": "Arredondamento eFatura"
			},
			"tipoValorBaseTributavel": {
				"0": "Pergunta",
				"1": "Base",
				"2": "Base + IVA"
			},
			"tipoValorDescritivo": {
				"0": "Não definido",
				"1": "Igual último doc. do período/diário",
				"2": "Fixo"
			}
		}
	},
	"predefinidocontabformula": {
		"title": "Fórmula do campo {{field}} da linha {{line}}",
		"operators": "Operadores",
		"variables": "Variáveis",
		"formula": "Fórmula",
		"validator": "Ainda não definiu uma fórmula para o campo",
		"extraVariables": {
			"valorPorPagar": "ValorPorPagarLinha"
		},
		"headerVariables": {
			"periodo": "Cabecalho.Periodo",
			"nomePeriodo": "Cabecalho.NomePeriodo"
		}
	},
	"prevites": {
		"manual": {
			"new": {
				"title": "Previsão de Tesouraria",
				"previtessaved": "Previsão de tesouraria guardada com sucesso.",
				"rubrica": "Rúbrica",
				"descricao": "Descrição",
				"valor": "Valor",
				"cardtitle": "Ocorrências sucessivas",
				"periodicas": "Periódicas",
				"ocrrfimperio": "Ocorrência no final de cada período",
				"ocorrencias": "Nº Ocorrências",
				"periodicidade": "Periodicidade",
				"temdefnocurr": "Definiu a Periodicidade mas ainda não definiu o Nº de Ocorrências!",
				"temdefocurr": "Definiu o Nº de Ocorrências mas ainda não definiu a Periodicidade!",
				"rubricanull": "A rubrica não pode ser nula!",
				"valornull": "O valor não pode ser nulo!",
				"datainf": "A data não pode ser inferior à data atual!",
				"nConta": "Nº Conta",
				"nomeConta": "Nome Conta"
			},
			"tooltip": {
				"btnDelete": "Apagar registo",
				"btnDeleteMultiple": "Apagar registos selecionados"
			},
			"messages": {
				"certezaeliminar": "Tem a certeza que pretende eleminiar o(s) registo(s)?"
			},
			"btnDeleteMulti": "Apagar registos selecionados"
		},
		"automatica": {
			"modalTitle": "Rubrica de Tesouraria",
			"valselecionado": "Valor Selecionado",
			"clientefornecedor": "Clientes / Fornecedores",
			"clientes": "Clientes",
			"fornecedores": "Fornecedores",
			"datatitle": "Selecione a data de Tesouraria",
			"datade": "Data Venc. de",
			"contade": "Conta de",
			"selecaoAutomatica": "Seleciona Automática",
			"selecaoAutomaticaTooltip": "Ao selecionar, seleciona automaticamente todas as linhas do documento",
			"fields": {
				"seleccionado": "Selecionado",
				"nConta": "Nº Conta",
				"nome": "Nome",
				"nDocString": "Nº Doc.",
				"nDocExterno": "Nº Doc. Externo",
				"descricao": "Descrição",
				"debito": "Débito",
				"credito": "Crédito",
				"porPagar": "Por pagar",
				"dataDocExt": "Data. Doc.",
				"dataVencimento": "Data vencimento",
				"dataTes": "Data tesouraria"
			},
			"messages": {
				"procesuccess": "Dados processados com sucesso!",
				"temselecionar": "Tem de selecionar os lançamentos que pretende processar."
			},
			"actions": {
				"verdoc": "Ver documento",
				"aplicardata": "Aplicar a data de Tesouraria",
				"selecionaDocConta": "Seleciona documentos da conta",
				"removeSeleconta": "Limpa seleção documentos da conta",
				"docfaturacao": "Ver documento faturação"
			}
		},
		"datasource": {
			"tesourariaperiodicidade": {
				"none": "Nenhuma",
				"diaria": "Diária",
				"semanal": "Semanal",
				"quinzenal": "Quinzenal",
				"mensal": "Mensal",
				"bimensal": "Bimensal",
				"trimestral": "Trimestral",
				"quadrimestral": "Quadrimestral",
				"semestral": "Semestral",
				"anual": "Anual"
			}
		},
		"legend": {
			"correcaoAutomatica": "Correção Automática - Data Atual"
		}
	},
	"prevtesobras": {
		"codTipoPlaneamentoDe": "Cód. tipo planeamento de",
		"codEstadoObraDe": "Cód. estado obra de",
		"cabCodigo": "Cód. obra",
		"cabDescricao": "Nome obra",
		"codPlaneamenTipo": "Cód. planeamento",
		"descricao": "Desc. planeamento",
		"codEstado": "Cód. estado",
		"codEstadoDesc": "Desc. estado",
		"modaltitle": "Rúbrica de tesouraria",
		"rubrica": "Cód. rúbrica",
		"rubricanull": "Cód. rúbrica não pode ser nulo",
		"importsuccess": "Dados processados com sucesso!"
	},
	"prhcomunicacoesinternas": {
		"title_detail": "Comunicação interna {{id}}",
		"title_new": "Nova Comunicação interna",
		"title_plural": "Comunicações Internas",
		"pesquisa": "Pesquisar",
		"saved": "Comunicação interna {{id}}, guardada com sucesso",
		"error": "Comunicação interna {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"fields": {
			"comIntCabID": "Cód.",
			"comIntCabIDPlaceholder": "Código da comunicação interna",
			"titulo": "Título",
			"tituloPlaceholder": "Título da comunicação interna",
			"estadoLido": "Lido",
			"de": "De",
			"texto": "Texto",
			"ficheiro": "Ficheiro"
		},
		"masterDetail": {
			"emp": "Cód. empregado",
			"nome": "Nome",
			"lido": "Lido",
			"dateHora": "Data/Hora leitura"
		}
	},
	"prhdocsplata": {
		"title_detail": "Documento da plataforma RH {{id}}",
		"title_new": "Nova Documento da plataforma RH",
		"title_plural": "Documentos da Plataforma RH",
		"pesquisa": "Pesquisar",
		"saved": "Documento da plataforma RH {{id}}, guardado com sucesso",
		"error": "Documento da plataforma RH {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"cab": {
				"codprhdocsplata": "Cód.",
				"codprhdocsplataPlaceholder": "Código do documento da plataforma RH",
				"titulo": "Título",
				"tituloPlaceholder": "Título do documento da plataforma RH",
				"dataPlaceholder": "Data do documento da plataforma RH",
				"dataValidade": "Data de validade",
				"dataValidadePlaceholder": "Data de validade"
			}
		},
		"steps": {
			"documento": "Dados do documento",
			"papeis": "Papeis",
			"servicos": "Serviços"
		}
	},
	"prhentalters": {
		"erroDoCampo": "Ocorreu um erro a enviar o pedido de alteração de dados do campo: \"{{campo}}\"",
		"naoExistemAlteracoesParaGuardar": "Não existem alterações para guardar.",
		"foiEnviadoPedidoDeAlteracaoDados": "Foi enviado o pedido de alteração de dados.",
		"foiEnviadoPedidoDeAlteracaoDadosMasAlgunsComErro": "Foi enviado o pedido de alteração de dados, mas alguns campos tem erros.",
		"ibanInvalido": "O IBAN \"{{iban}}\" não é valido",
		"tooltips": {
			"ibanInvalido": "Este IBAN é invalido."
		}
	},
	"prheventosconfig": {
		"title_detail": "Configuração de eventos {{id}}",
		"title_new": "Nova configuração de eventos",
		"title_plural": "Configurações de Eventos RH",
		"title_small": "Config. Eventos",
		"pesquisa": "Pesquisar",
		"saved": "Configuração de eventos {{id}}, guardado com sucesso",
		"error": "Configuração de eventos {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"codPRHEventosConfig": "Configuração de eventos",
			"codPRHEventosConfigPlaceholder": "Configuração de eventos",
			"tipoEvento": "Tipo de evento",
			"tipoEventoPlaceholder": "Tipo de evento",
			"tipo": "Tipo",
			"tipoPlaceholder": "Tipo",
			"nCodAbdes": "Cód. abono/desconto",
			"nCodAbdesPlaceholder": "Cód. abono/desconto",
			"descricao": "Descrição",
			"descricaoPlaceholder": "Descrição",
			"tipoProcessamento": "Tipo de processamento",
			"tipoProcessamentoPlaceholder": "Tipo de processamento",
			"tipofalta": "Tipo de falta",
			"desconto": "Desconto",
			"abono": "Abono"
		},
		"tipos": {
			"abonos": "Abonos",
			"faltas": "Faltas",
			"ferias": "Férias"
		},
		"messages": {
			"error": "Configuração de eventos não pode ser guardado. Por favor verifique os campos.",
			"addsuccess": "Adicionado com sucesso",
			"editsuccess": "Alterado com sucesso",
			"deleteSuccess": "Apagado com sucesso",
			"deleteTitle": "Atenção!",
			"deleteConfirm": "Pretende mesmo apagar este registo?",
			"fieldsUnfiled": "Existem campos que não estão preenchidos!"
		}
	},
	"prhfluxos": {
		"title_detail": "Fluxo {{id}}",
		"title_new": "Novo Fluxo",
		"title_plural": "Fluxos RH",
		"pesquisa": "Pesquisar",
		"saved": "Fluxo {{id}}, guardado com sucesso",
		"error": "Fluxo {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"idFluxo": "Fluxo",
			"idFluxoPlaceholder": "Código do fluxo",
			"posicao": "Posição",
			"posicaoPlaceholder": "Posição do fluxo",
			"indice": "Índice",
			"indicePlaceholder": "Índice do fluxo",
			"codPapel": "Código papel",
			"codPapelPlaceholder": "Código papel ativo",
			"integraCG": "Integra CentralGest",
			"integraCGPlaceholder": "Integra CentralGest",
			"papel": "Papel",
			"papelCGPlaceholder": "Papel",
			"tipo": "Tipo",
			"tipoPlaceholder": "Tipo"
		}
	},
	"prhgruporefeicoes": {
		"title_detail": "Grupo refeições {{id}}",
		"title_new": "Novo grupo refeição",
		"title_plural": "Grupos refeições",
		"pesquisa": "Pesquisar",
		"saved": "Grupo refeição {{id}}, guardado com sucesso",
		"error": "Grupo refeição {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"grupo": "Grupo",
			"grupoPlaceholder": "Grupo",
			"nome": "Nome",
			"nomePlaceholder": "Nome do grupo de refeição",
			"permiteAgendamento": "Permite agendamento",
			"permiteAgendamentoPlaceholder": "Permite agendamento",
			"horaInicio": "Hora inicio",
			"horaInicioPlaceholder": "Hora inicio",
			"horaFim": "Hora Fim",
			"horaFimPlaceholder": "Hora fim"
		}
	},
	"prhlocais": {
		"title_detail": "Local {{id}}",
		"title_new": "Novo Local",
		"title_plural": "Locais RH",
		"pesquisa": "Pesquisar",
		"saved": "Local {{id}}, guardado com sucesso",
		"error": "Local {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"codLocal": "Local",
			"codLocalPlaceholder": "Código do local",
			"nome": "Nome",
			"nomePlaceholder": "Nome do local"
		}
	},
	"prhmenus": {
		"title_detail": "Menu {{id}}",
		"title_new": "Novo Menu",
		"title_plural": "Menus RH",
		"pesquisa": "Pesquisar",
		"saved": "Menu {{id}}, guardado com sucesso",
		"error": "Menu {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"codMenu": "Menu",
			"codMenuPlaceholder": "Código do menu",
			"titulo": "Titulo",
			"tituloPlaceholder": "Titulo do menu",
			"descricao": "Descrição",
			"descricaoPlaceholder": "Descrição do menu"
		}
	},
	"prhpapeis": {
		"title_detail": "Papel {{id}}",
		"title_new": "Novo Papel",
		"title_plural": "Papeis RH",
		"pesquisa": "Pesquisar",
		"saved": "Papel {{id}}, guardado com sucesso",
		"error": "Papel {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"codPapel": "Papel",
			"codPapelPlaceholder": "Código do Papel",
			"nome": "Nome",
			"nomePlaceholder": "Nome do papel",
			"unicoServico": "Único por serviço",
			"unicoServicoPlaceholder": "Único por serviço"
		}
	},
	"prhrefeicoes": {
		"title_detail": "Refeição {{id}}",
		"title_new": "Refeição",
		"title_plural": "Refeições RH",
		"pesquisa": "Pesquisar",
		"saved": "Refeição {{id}}, guardado com sucesso",
		"error": "Refeição {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"CodRefeicao": "Refeição",
			"CodRefeicaoPlaceholder": "Código de refeição",
			"CODEMP": "Colaborador",
			"CODEMPPlaceholder": "Código do colaborador",
			"codPRHRelEmentas": "RelEmenta",
			"codPRHRelEmentasPlaceholder": "Código do RelEmenta",
			"consumiu": "Consumiu",
			"local": "Local",
			"tipoRefeicao": "Tipo de refeição",
			"cor": "Cor",
			"menu": "Menu",
			"ementa": "Ementa",
			"refConsumida": "Refeição consumida",
			"locais": "Locais"
		},
		"message": {
			"porProcessar": "Sem dados por processar.",
			"procComSucesso": "Processado com sucesso"
		},
		"grupoRefeicao": {
			"almoco": "Almoço",
			"jantar": "Jantar",
			"nao": "Não",
			"sim": "Sim"
		},
		"btn": {
			"procRefNCons": "Proc. ref. não consumida"
		}
	},
	"prhrelementas": {
		"title_detail": "RelEmenta {{id}}",
		"title_new": "RelEmenta",
		"title_plural": "Relações de Ementa RH",
		"pesquisa": "Pesquisar",
		"saved": "RelEmenta {{id}}, guardado com sucesso",
		"error": "RelEmenta {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"menusAssociados": "O menu não pode ser apagado pois já existem refeições marcadas.",
		"fields": {
			"codPRHRelEmentas": "Rel. ementa",
			"codPRHRelEmentasPlaceholder": "código da relação ementas",
			"codMenu": "Menu",
			"codMenuPlaceholder": "Código do menu",
			"codLocal": "Local",
			"codLocalPlaceholder": "Código do local",
			"tipoRefeicao": "Tipo de refeição",
			"tipoRefeicaoPlaceholder": "Tipo de refeição"
		}
	},
	"prhservicoempregados": {
		"title_detail": "Rel. Serviço/Empregado {{id}}",
		"title_new": "Novo Rel. Serviço/Empregado",
		"title_plural": "Rel. Serviço/Empregado RH",
		"pesquisa": "Pesquisar",
		"saved": "Rel. Serviço/Empregado guardada(s) com sucesso.",
		"error": "Rel. Serviço/Empregado não pode(m) ser guardada(s). Por favor verifique os campos.",
		"fields": {
			"codServicoEmpregado": "Cód. rel. serviço/empregado",
			"codServicoEmpregadoPlaceholder": "Código do rel. serviço/empregado",
			"codPapel": "Papel",
			"codPapelPlaceholder": "Código do papel",
			"codServico": "Serviço",
			"codServicoPlaceholder": "Código do serviço",
			"codEmp": "Empregado",
			"codEmpPlaceholder": "Código do empregado"
		},
		"errors": {
			"servicoAtualNaoSelecionado": "Selecione um serviço atual.",
			"servicoDestinoNaoSelecionado": "Selecione um serviço de destino.",
			"servicosAtualDestinoIguais": "O serviço atual não pode ser igual ao serviço destino.",
			"naoExistemEmpregadosSelecionados": "Não existem empregados selecionados."
		}
	},
	"prhservicos": {
		"title_detail": "Serviço {{id}}",
		"title_new": "Novo Serviço",
		"title_plural": "Serviços RH",
		"pesquisa": "Pesquisar",
		"saved": "Serviço {{id}}, guardado com sucesso",
		"error": "Serviço {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Serviço {{id}}, eliminado com sucesso.",
		"fields": {
			"codServico": "Serviço",
			"codServicoPlaceholder": "Código do serviço",
			"nome": "Nome",
			"nomePlaceholder": "Nome do serviço"
		}
	},
	"prhtipocom": {
		"title_detail": "Tipo de comunicação {{id}}",
		"title_new": "Novo tipo de comunicação",
		"title_plural": "Tipos de comunicação",
		"pesquisa": "Pesquisar",
		"saved": "Tipo Comunicação {{id}}, guardado com sucesso.",
		"error": "Tipo Comunicação {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Tipo Comunicação {{id}}, apagado com sucesso",
		"fields": {
			"codPRHTipoCom": "Cód. tipo comunicação",
			"codPRHTipoComPlaceholder": "Código de tipo comunicação",
			"nome": "Nome",
			"sistema": "Sistema"
		}
	},
	"prhtiporefeicoes": {
		"title_detail": "Tipo refeição {{id}}",
		"title_new": "Novo Tipo refeição",
		"title_plural": "Tipos Refeição RH",
		"pesquisa": "Pesquisa",
		"saved": "Tipo refeição {{id}}, guardado com sucesso",
		"error": "Tipo refeição {{id}}, não foi guardado. Verifique os campos.",
		"fields": {
			"tipoRefeicao": "Tipo refeição",
			"tipoRefeicaoPlaceholder": "Tipo de refeição",
			"nome": "Nome",
			"nomePlaceholder": "Tipo de refeição nome",
			"horaRefeicao": "Hora refeição",
			"horaRefeicaoPlaceholder": "Hora refeição",
			"numMinutosMarcacao": "Núm. minutos para marcar",
			"numMinutosMarcacaoPlaceholder": "Núm. minutos para marcar",
			"corPrimaria": "Cor primária",
			"corPrimariaPlaceholder": "Cor primária",
			"corSecundaria": "Cor secundária",
			"corSecundariaPlaceholder": "Cor secundária",
			"grupoRefeicao": "Grupo de refeição",
			"grupoRefeicaoPlaceholder": "Grupo de refeição"
		}
	},
	"proceDMRAT": {
		"comecar": "Começar",
		"comoResolver": "Verifique o estado da empresa em questão e/ou empregado.",
		"decSubstituicao": "Declaração de substituição",
		"enviadoComErros": "Houve erros nos ficheiros enviados. Quer verificar os ficheiros enviados?",
		"enviadoComSuccess": "Ficheiro(s) enviado(s) com sucesso. Quer verificar o(s) ficheiro(s) enviado(s)?",
		"erros": "Erros",
		"genDRIFile": "Gerar ficheiro",
		"geracaoImpressao": "Geração e impressão",
		"initNewProc": "Iniciar o novo",
		"jobTimeoutModalMessage": "A tarefa excedeu o tempo limite. Tem que iniciar o processo novamente.",
		"jobTimeoutModalTitle": "Tarefa",
		"obterPdf": "Obter pdf",
		"printMapPerEmp": "Imprimir mapa",
		"printMapaModalTitle": "Impressão de mapas declaração mensal de remunerações - AT",
		"procAlertMsg1": "Por defeito vai gerar DMR-AT para todos os processamentos do Mês.",
		"procAlertMsg2": "Caso queira verificar os processamentos existentes selecione o campo em baixo",
		"procExistentesReprocMessage": "Existem empresas com o processamento gerado da declaração mensal de remunerações - AT. Quer voltar a processar essas empresas?<br/><br/>Sim - Reprocessa todas as empresas selecionadas.<br/><br/>Não - Carrega processamentos guardados e gera novos para empresas não processadas.",
		"promptAlertModalTitle": "Atenção!",
		"promptSendModalMessage": "Vai enviar por WebService o(s) ficheiro(s) da declaração mensal de remunerações. Quer continuar?",
		"promptViewFilesMessage": "Os ficheiros foram comunicados. Deseja abrir os comprovativos de envio?",
		"promptViewResultsMessage": "O processamento da declaração mensal de remunerações terminou. Deseja visualizar resultados?",
		"promptViewResultsTitle": "Declaração mensal de remunerações",
		"resumoDadosFuncionario": "Resumo dados funcionário",
		"semAlteracaoParaGerar": "O sistema não gerou ficheiro, porque já foi gerado anteriormente.",
		"sendFile": "Enviar por WebService",
		"tabConfigCaption": "Configuração",
		"tabEmpresasCaption": "Empresas",
		"tabIntroCaption": "Introdução",
		"tabIntroSubTitle": "O assistente vai ajuda-lo a gerar o ficheiro da declaração mensal de remunerações - AT",
		"tabIntroTitle": "Bem-vindo ao assistente de processamento da declaração mensal de remunerações - AT",
		"tabProcCaption": "Processamento",
		"tabResultsCaption": "Relatório",
		"temSeleccionarDatas": "Tem que selecionar pelo menos uma data.",
		"temSeleccionarUmMesParaProcessar": "Tem de selecionar um mês para processar",
		"temSeleccionarUmaEmpresa": "Tem de selecionar pelo menos uma empresa para continuar.",
		"viewInfoDadosAutoTab": "Dados automáticos",
		"viewInfoDadosGlobaisContribObrigTab": "Contribuições obrigatórias",
		"viewInfoDadosGlobaisControloTab": "Controlo",
		"viewInfoDadosGlobaisQuotizSindicaisTab": "Quotizações sindicais",
		"viewInfoDadosGlobaisRendimAnosAntTab": "Rendimentos anos anteriores",
		"viewInfoDadosGlobaisRetencaoIRSTab": "Retenção IRS",
		"viewInfoDadosGlobaisRetencaoSobretaxaTab": "Retenção sobretaxa",
		"viewInfoDadosGlobaisTab": "Dados globais",
		"viewInfoDadosGlobaisValorRendimentosTab": "Valor dos rendimentos",
		"viewInfoDadosManuaisTab": "Dados manuais",
		"viewInfoModalTitle": "Ano: {{ano}} Mês: {{mes}} - Mostra informação dados remuneração",
		"viewResultBtn": "Visualizar",
		"viewSends": "Comprovativos/Doc. pagamento",
		"vizProcExistentes": "Visualizar processamentos existentes",
		"fields": {
			"abonosOutros": "Outros abonos",
			"abonosRendimentosNaoSujeitos": "Rendimentos não sujeitos",
			"abonosRendimentosSujeitos": "Rendimentos sujeitos",
			"anoAntRend": "Ano anterior rend.",
			"codEmp": "Cód. emp.",
			"contribuicosObrigatoriasNIF1": "Contrib. obrig. - NIPC entidade 1",
			"contribuicosObrigatoriasNIF2": "Contrib. obrig. - NIPC entidade 2",
			"contribuicosObrigatoriasNIF3": "Contrib. obrig. - NIPC entidade 3",
			"contribuicosObrigatoriasValores": "Contrib. obrig. - Valores",
			"dataProcessamento": "Data proc.",
			"descObrigSSocial": "Contrib. obrig.",
			"descontosContribuicoesObrigatorias": "Contribuições obrigatórias",
			"descontosIRS": "Retenção IRS",
			"descontosOutros": "Outros descontos",
			"descontosSindicatos": "Sindicatos",
			"descontosSobretaxa": "Retenção sobretaxa",
			"descrInsManual": "Tipo de inserção",
			"descricaoAbono": "Desc. abono/desconto",
			"dmrAno": "Ano proc.",
			"dmrMes": "Mês proc.",
			"empresa": "Empresa",
			"enviaparaDMR": "Envia para DMR",
			"irsRetido": "Retenção IRS",
			"liquidoCalculado": "Liquido calculado",
			"liquidoVencimentos": "Liquido vencimentos",
			"localObtencaoRend": "Local origem rend.",
			"mesAno": "Mês/Ano",
			"nCodAbdes": "Abono/Desconto",
			"nProcessamento": "Nº proc.",
			"nif": "Nº contribuinte",
			"nome": "Nome",
			"nomeEmpresa": "Nome empresa",
			"origemRendiDescr": "Origem",
			"outrosAbonos": "Outros abonos",
			"outrosDescontos": "Outros descontos",
			"queFazer": "Como resolver",
			"quotizSindical": "Quotizações sindicais",
			"quotizacaoSindical": "Quotização sindical",
			"rendAnoAnteriorAno": "Rend. anos anteriores - Ano",
			"rendAnoAnteriorValores": "Rend. anos anteriores - Valores",
			"rendNaoSujeitos": "Rend. não sujeitos",
			"rendSujeitos": "Rend. sujeitos",
			"rendimentodoano": "Rend. do ano",
			"rendimentosAnoAnterior": "Valor rendimentos (Anos anteriores)",
			"retencaoIRS": "Retenção IRS",
			"retencaoSobretaxa": "Retenção sobretaxa",
			"selected": "Sel",
			"sobretaxaExtraord": "Retenções sobretaxa",
			"statusImage": "Estado",
			"tipRendiment": "Tip. rend.",
			"tipoProcessamento": "Tipo proc.",
			"tipoProcessamentoDescricao": "Descrição",
			"tipoRendimento": "Tipo rend.",
			"totalDMRATRemun": "Total - Remuneração seg. social",
			"totalValorContribuicoesObrigatorias": "Total - Contribuições obrigatórias",
			"totalValorQuotizacaoSindicais": "Total - Valor quotizações sindicais",
			"totalValorRendimentos": "Total - Valor rendimentos",
			"totalValorRetencaoIRS": "Total - Retenção IRS",
			"totalValorRetencaoSobretaxa": "Total - Valor retenção sobretaxa",
			"totalVencRemun": "Total - Remuneração proc. salários",
			"valorInciden": "Valor rendimentos",
			"valorNaoSujeito": "Rend. sujeitos",
			"wsEstadoFicheiroStr": "Situação envio AT"
		},
		"btn": {
			"ordenar": "Ordenar por: {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "Cód. empresa",
				"sortEmpresasByName": "Nome empresa"
			}
		}
	},
	"proceSalarios": {
		"tabIntroCaption": "Introdução",
		"tabIntroTitle": "Bem-vindo ao assistente de processamento de salários",
		"tabIntroSubTitle": "O assistente vai ajuda-lo a processar salários",
		"tabEmpresasCaption": "Empresas",
		"tabConfigCaption": "Configuração",
		"tabProcCaption": "Processamento",
		"tabResultsCaption": "Relatório",
		"jobTimeoutModalTitle": "Tarefa",
		"jobTimeoutModalMessage": "A tarefa excedeu o tempo limite. Tem que iniciar o processo novamente.",
		"processar": "Processar",
		"processingMessage": "A processar, por favor aguarde um momento.",
		"modalRemoveSelEmpConfirmMsg": "Tem a certeza que quer remover o(s) empregado(s) selecionado(s) deste processamento?",
		"comecar": "Começar",
		"tipoProcessamentoGerarTitle": "Tipo processamento a gerar",
		"tipoProceMensalCaption": "Processamento mensal",
		"tipoProceFeriasCaption": "Processamento férias",
		"tipoProceNatalCaption": "Processamento natal",
		"dataParaProcessamentoCaption": "Data para o processamento",
		"dia": "Dia",
		"podeReprocessarSalariosCaption": "Reprocessamento de salário (Com processamentos no mês)",
		"podeReprocessarSalariosHelpText1": "Só pode fazer reprocessamento de salários",
		"podeReprocessarSalariosHelpText2": "- Se existe apenas um Processamento no mês selecionado.",
		"podeReprocessarSalariosHelpText3": "- O tipo de processamento existente não pode ser alterado.",
		"procExistentesTitle": "Proc. existentes",
		"processModalMessage": "A efetuar o processamento...por favor aguarde um momento.",
		"estimativa": "Estimativa",
		"avisosErros": "Avisos/Erros",
		"fields": {
			"selected": "Sel",
			"mesAno": "Mês/Ano",
			"nEmpresa": "Empresa",
			"nomeEmpresa": "Nome empresa",
			"dataProce": "Data processamento",
			"dADataProc": "Data início",
			"ateDataProc": "Data fim",
			"nDiasUteis": "Nº dias úteis",
			"descricao": "Descrição",
			"nDiasTotalMes": "Nº dias total mês",
			"calendar": "Calendário",
			"tipoProcessamento": "Tipo processamento",
			"codEmp": "Cód.",
			"nome": "Nome",
			"nBIdentidade": "Nº BI / Cartão cidadão",
			"nContribuinte": "NIF",
			"dataNascimento": "Data nascimento",
			"strEstadoProcEmpregado": "Estado",
			"nProcessamento": "Nº proc.",
			"nRecibo": "Nº recibo",
			"dataProcessamento": "Data proc.",
			"identificacaoEmpregado": "Empregado",
			"dataInicoProc": "Data inicio",
			"dataFimProce": "Data fim",
			"nDiasParaProcessamen": "Nº dias úteis para proc.",
			"anoParaSegSocial": "Ano (SS)",
			"mesParaSegSocial": "Mês (SS)",
			"proceImportado": "Proc. importado",
			"tipoProcessamentoDescricao": "Tipo proc. desc."
		},
		"temSeleccionarUmMesParaProcessar": "Tem de selecionar um mês para processar",
		"temSeleccionarPeloMenosTipoProcessamento": "Tem de selecionar pelo menos um Tipo de processamento",
		"promptViewResultsTitle": "Processamento de salários",
		"promptViewResultsMessage": "O processamento de salários terminou. Deseja visualizar resultados?",
		"viewResultBtn": "Visualizar",
		"initNewProc": "Iniciar o novo",
		"mostrarSoComErros": "Mostrar só com erros",
		"segSocialRelativaTitle": "Segurança social relativa a",
		"novoProcessamentoTitle": "Novo processamento",
		"empregadosTitle": "Empregados",
		"selectAll": "Selecionar todos",
		"unSelectAll": "Limpar seleção",
		"temSeleccionarUmEmpregado": "Tem que selecionar pelo menos um empregado para processar.",
		"singleProcDescDef": "Proc. salários para a data: {{date}}",
		"temSeleccionarUmaEmpresa": "Tem de selecionar pelo menos uma empresa para continuar.",
		"addEmpregado": "Adicionar empregado(s)",
		"removeSelEmpregado": "Remover empregado(s) selecionados",
		"addSelected": "Adicionar selecionados",
		"pesqEmpregados": "Pesquisa de empregados",
		"reprocEmpregadoTooltip": "Reprocessa empregado",
		"reprocEncargosTooltip": "Reprocessa encargos",
		"confirmReProcMsg": "Tem a certeza que quer Reprocessar o empregado {{codEmp}} deste processamento?",
		"confirmReProcEncargoMsg": "Tem a certeza que quer reprocessar os encargos do empregado {{codEmp}} - {{nome}} deste processamento?",
		"confirmReProcSelMsg": "Tem a certeza que pretende reprocessar os empregados selecionados deste processamento?",
		"empregadoReprocessado": "O empregado {{codEmp}} foi reprocessado.",
		"encargosReprocessados": "Os encargos do empregado {{codEmp}} foram reprocessados.",
		"empregadoSelReprocessado": "Os empregados selecionados foram reprocessados.",
		"deleteProcess": "Eliminar processamento",
		"empregadoTemTransfBancariaPrompt": "Atenção, o empregado {{codEmp}} - {{nome}} já tem criado uma transferência bancária para este processamento. Quer voltar a Reprocessar o empregado?",
		"naoPodeReprocExisteTransfBancaria": "Não pode reprocessar o processamento do empregado {{codEmp}} - {{nome}} existe uma transferência bancária.",
		"empregadoTemFichSegSocialPrompt": "Atenção, o empregado {{codEmp}} - {{nome}} já tem criado um ficheiro para a segurança social. Quer voltar a reprocessar o empregado?",
		"naoPodeReprocExisteFichSegSocial": "Não pode reprocessar o processamento do empregado {{codEmp}} - {{nome}}, já foi criado o ficheiro para a segurança social.",
		"existemEmpregadosQueNaoPodemSerReProc": "Existem empregados que não podem ser reprocessados: <br/><br/> {{content}} <br/><br/>Pretende ignorá-los e continuar com os possíveis a reprocessar?",
		"naoPodeReprocIntegContab": "Não pode reprocessar o processamento. O processamento do empregado {{codEmp}} - {{nome}} está integrado na contabilidade.",
		"messages": {
			"empEliminadoSucesso": "Empregado removido com sucesso!",
			"empAdicionadoSucesso": "Empregado adicionado com sucesso!",
			"naoContemEmpSelecionado": "Tem de selecionar pelo menos um empregado!"
		},
		"btn": {
			"ordenar": "Ordenar por: {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "Cód. empresa",
				"sortEmpresasByName": "Nome empresa"
			}
		},
		"consultarRecibo": "Consultar recibos",
		"consultarReciboEmpregado": "Consultar recibos do empregado",
		"existemCamposComErros": "Existem campos com erros. Verifique os erros antes de continuar.",
		"promptDeleteProcTitle": "Eliminar processamento",
		"promptDeleteProcMessage": "Tem a certeza que pretende eliminar o processamento?",
		"reprocSelected": "Reproc. empregados"
	},
	"proceSegSocial": {
		"tabIntroCaption": "Introdução",
		"tabIntroTitle": "Bem-vindo ao assistente de processamento para segurança social",
		"tabIntroSubTitle": "O assistente vai ajuda-lo a gerar o ficheiro para a segurança social",
		"tabEmpresasCaption": "Empresas",
		"tabConfigCaption": "Configuração",
		"tabProcCaption": "Processamento",
		"tabResultsCaption": "Relatório",
		"temSeleccionarUmaEmpresa": "Tem de selecionar pelo menos uma empresa para continuar.",
		"temSeleccionarUmMesParaProcessar": "Tem de selecionar um mês para processar",
		"vizProcExistentes": "Visualizar processamentos existentes",
		"procAlertMsg1": "Por defeito vai gerar seg. social para todos os processamentos do mês",
		"procAlertMsg2": "Caso queira verificar os processamentos existentes selecione o campo em baixo",
		"temSeleccionarDatas": "Tem que selecionar pelo menos uma data.",
		"procExistentesReprocMessage": "Existem empresas com o processamento gerado para a segurança social. Quer voltar a processar essas empresas?<br/><br/>Sim - Reprocessa todas as empresas selecionadas.<br/><br/>Não - Carrega processamentos guardados e gera novos para empresas não processadas.",
		"reprocEmpSelected": "Sim",
		"loadProcSavedAndGenNewsForNotProccessed": "Não",
		"geracaoImpressao": "Geração e impressão",
		"promptViewResultsTitle": "Processamento para segurança social",
		"promptViewResultsMessage": "O processamento para segurança social terminou. Deseja visualizar resultados?",
		"viewResultBtn": "Visualizar",
		"initNewProc": "Iniciar o novo",
		"erros": "Erros",
		"printMapPerEmp": "Imprimir mapa por empregado",
		"printSummary": "Imprimir resumo",
		"genDRIFile": "Gerar ficheiro para DRI",
		"sendFile": "Enviar ficheiro WebService",
		"viewSends": "Consultar envio WebService",
		"printMapPerEmpModalTitle": "Impressão de mapas segurança social",
		"printSummaryModalTitle": "Impressão de mapas resumo do ficheiro segurança social",
		"semAlteracaoParaGerar": "O sistema não gerou ficheiro, porque já foi gerado anteriormente.",
		"viewInfoSSModalTitle": "Ano: {{ano}} Mês: {{mes}} - Mostra informação dados segurança social",
		"codPTitle": "Cód. P",
		"codITitle": "Cód. I",
		"nDiasCodPLabel": "Cód. P - Nº dias",
		"nDias2Label": "Cód. 2 - Nº dias",
		"nDiasILabel": "Cód. I - Nº dias",
		"valLabel": "Valor",
		"valRLabel": "Cód. R - Valor",
		"valFLabel": "Cód. F - Valor",
		"valNLabel": "Cód. N - Valor",
		"valALabel": "Cód. A - Valor",
		"valBLabel": "Cód. B - Valor",
		"valCLabel": "Cód. C - Valor",
		"valDLabel": "Cód. D - Valor",
		"valHLabel": "Cód. H - Valor",
		"valMLabel": "Cód. M - Valor",
		"valOLabel": "Cód. O - Valor",
		"valSLabel": "Cód. S - Valor",
		"valTLabel": "Cód. T - Valor",
		"valXLabel": "Cód. X - Valor",
		"val6Label": "Cód. 6 - Valor",
		"val2Label": "Cód. 2 - Valor",
		"valILabel": "Cód. I - Valor",
		"valPLabel": "Cód. P - Valor",
		"infoSSSaved": "Dados gravados com sucesso.",
		"promptInfoSSSave": "Vai alterar os dados do ficheiro a ser enviado para a segurança social. <br/> Tem a certeza que quer alterar os dados?",
		"nibADebitar": "IBAN a Debitar",
		"obterPdf": "Obter Pdf",
		"promptAlertModalTitle": "Atenção!",
		"promptSendModalMessage": "Vai enviar por WebService os ficheiros para a segurança social. Quer continuar?",
		"enviadoComSuccess": "O(s) ficheiro(s) enviado(s) com sucesso. Quer verificar o(s) ficheiro(s) enviado(s)?",
		"enviadoComSuccesso": "O(s) ficheiro(s) foram enviado(s) com sucesso.",
		"enviadoComErros": "Houve erros nos ficheiros enviados. Quer verificar os ficheiros enviados?",
		"jobTimeoutModalTitle": "Tarefa",
		"jobTimeoutModalMessage": "A tarefa excedeu o tempo limite. Tem que iniciar o processo novamente.",
		"comecar": "Começar",
		"fields": {
			"selected": "Sel",
			"mesAno": "Mês/Ano",
			"tipoProcessamento": "Tipo proc.",
			"tipoProcessamentoDescricao": "Descrição",
			"dataProcessamento": "Data proc.",
			"empresa": "Empresa",
			"nomeEmpresa": "Nome empresa",
			"ssAno": "Ano seg. social",
			"ssMes": "Mês seg. social",
			"fichGeradoPodeReprocessarFicheiro": "Pode reprocessar",
			"estadoFicheiroStr": "Estado ficheiro",
			"ssInfoEstadoProcessamentoStr": "SS estado",
			"totalRemuneracaoSegSocial": "Total - Remun. seg. social",
			"totalContribuicaoSegSocial": "Total - Contribuição seg. social",
			"totalRemuneracaoVencimento": "Total - Remun. proc. salários",
			"queFazer": "Como resolver",
			"codEmp": "Cód. emp.",
			"nome": "Nome",
			"numSS": "Nº SS",
			"totalSegSocialRemun": "Total - Remuneração seg. social",
			"totalVencRemun": "Total - Remuneração proc. salários",
			"taxaAAplicar": "Tx (%)"
		},
		"configWsSSTooltip": "Configuração do WebService da segurança social",
		"btn": {
			"ordenar": "Ordenar por: {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "Cód. empresa",
				"sortEmpresasByName": "Nome empresa"
			}
		}
	},
	"processos": {
		"title_detail": "Processo {{id}}",
		"title_new": "Novo Processo",
		"title_plural": "Processos",
		"pesquisa": "Pesquisar",
		"saved": "Processo {{id}}, guardado com sucesso.",
		"error": "Processo {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Processo {{id}}, apagado com sucesso",
		"fields": {
			"nRefProcesso": "Processo",
			"nRefProcessoPlaceholder": "Número do processo",
			"nome": "Nome",
			"nomePlaceholder": "Nome do processo",
			"nCCusto": "Nº C. Custo",
			"nomeCCusto": "Nome C. Custo",
			"nomeCCustoPlaceholder": "Centro de custo",
			"nClifo": "Cliente",
			"nClifoPlaceholder": "Conta cliente",
			"nomeClifo": "Nome cliente",
			"nomeClifoPlaceholder": "Nome cliente",
			"terminado": "Terminado"
		}
	},
	"procseguradorafolhaferias": {
		"steps": {
			"introducao": "Introdução",
			"empresas": "Empresas",
			"configuracao": "Configuração",
			"relatorio": "Relatório",
			"title": {
				"titleintro": "Bem-vindo ao assistente de processamento da folha de férias para seguradora",
				"subtitleintro": "O Assistente vai ajuda-lo a gerar o ficheiro de Folha de férias para seguradora",
				"titleempresas": "Empresas com processamentos",
				"subtitleempresas": "Escolha as empresas para gerar ficheiro",
				"titleconfig": "Datas processamento",
				"subtitleconfig": "Selecção de datas dos processamentos de vencimentos",
				"titlerelatorio": "Geração e impressão da folha de férias para seguradora",
				"errossegSocial": "Erros da seguradora",
				"erros": "Erros"
			}
		},
		"fields": {
			"selected": "Sel.",
			"mesAno": "Mês/Ano",
			"tipoProcessamento": "Tipo proc.",
			"tipoProcessamentoDescricao": "Descrição",
			"dataProcessamento": "Data processamento",
			"empresa": "Empresa",
			"nomeEmpresa": "Nome empresa",
			"temFichGerado": "Tem ficheiro gerado",
			"nempresa": "Empresa",
			"queFazer": "Como resolver",
			"totalRemuneracaoSegSocial": "Total - Remuneração seg. social",
			"email": {
				"caption": "Email",
				"placeholder": "Email para envio de ficheiro"
			}
		},
		"messages": {
			"temSeleccionarDatas": "Tem que selecionar pelo menos uma data.",
			"invalidStepEmp": "Tem que selecionar pelo menos uma empresa para processar.",
			"invalidStepConf": "Não existe nenhum processamento para o ano/mês selecionado.",
			"empresascomerros": "Existem empresas com erro!",
			"email": {
				"enviado": "E-mail enviado com sucesso!",
				"naoenviado": "E-mail não foi enviado."
			}
		},
		"prompt": {
			"confMessage": "Existem empresas com o processamento gerado com a folha de Férias da seguradora. <br> Quer voltar a processar essas empresas? <br> Sim - Reprocessa todas as empresas selecionadas <br> Não - Carrega processamentos guardados e gera novos para empresas não processadas.",
			"geraFich": "Vai gerar o ficheiro para a folha de férias da seguradora. Quer continuar?"
		},
		"btn": {
			"printmap": "Imprimir mapa por empregado",
			"genfichsegu": "Gerar ficheiro para seguradora",
			"previsualizar": "Pre-visualizar",
			"ordenar": "Ordenar por: {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "Cód. empresa",
				"sortEmpresasByName": "Nome empresa"
			},
			"enviaEmail": "Enviar ficheiro por email"
		},
		"modal": {
			"impressaomapas": "Impressão de mapas folha de férias para seguradora"
		}
	},
	"profissao": {
		"title_detail": "Profissão {{id}}",
		"title_new": "Profissão",
		"title_plural": "Profissões",
		"pesquisa": "Pesquisar",
		"deleted": "Profissão apagada com sucesso.",
		"saved": "Profissão {{id}}, guardada com sucesso.",
		"error": "Profissão {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"fields": {
			"nProfissao": "Código de profissão",
			"designaBreve": "Designação",
			"designaCompl": "Designação completa",
			"codClassNaCProf": "Cód. nacional profissão"
		}
	},
	"protecaoPassword": {
		"modal": {
			"save": "O tipo de password escolhido foi guardado com sucesso",
			"tipoPassword": "Protege por password os recibos a enviar por e-mail?",
			"title": "Selecionar tipo de proteção"
		}
	},
	"qivaas": {
		"title_detail": "Campo da declaração anual de IVA {{id}}",
		"title_new": "Novo Campo da declaração anual de IVA",
		"title_plural": "Campos Declaração Anual IVA",
		"pesquisa": "Pesquisar",
		"saved": "Campo da declaração anual de IVA {{id}}, guardado com sucesso.",
		"error": "Campo da declaração anual de IVA {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Campo da declaração anual de IVA {{id}}, apagado com sucesso.",
		"fields": {
			"nCampo": "Cód. de campo",
			"nCampoPlaceholder": "Código do campo da declaração anual de IVA",
			"nome": "Nome",
			"nomePlaceholder": "Nome do campo da declaração anual de IVA",
			"nQuadro": "Nº de quadro",
			"nQuadroPlaceholder": "Nº de quadro do campo da declaração anual de IVA"
		}
	},
	"qivaps": {
		"title_detail": "Campo da declaração periódica de IVA {{id}}",
		"title_new": "Novo Campo da declaração periódica de IVA",
		"title_plural": "Campos Declaração Periódica IVA",
		"pesquisa": "Pesquisar",
		"saved": "Campo da declaração periódica de IVA {{id}}, guardado com sucesso.",
		"error": "Campo da declaração periódica de IVA {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Campo da declaração periódica de IVA {{id}}, apagado com sucesso.",
		"fields": {
			"nCampo": "Cód. de campo",
			"nCampoPlaceholder": "Código do campo da declaração periódica de IVA",
			"nomeCampo": "Nome",
			"nomeCampoPlaceholder": "Nome do campo da declaração periódica de IVA",
			"nQuadro": "Nº de quadro",
			"nQuadroPlaceholder": "Nº de Quadro do campo da declaração periódica de IVA",
			"nCampoOficial": "Cód. oficial campo",
			"nCampoOficialPlaceholder": "Cód. oficial do campo da declaração periódica de IVA"
		}
	},
	"qpapa": {
		"title_detail": "Associação Patronal {{id}}",
		"title_new": "Nova Associação Patronal",
		"title_plural": "Associações Patronais",
		"pesquisa": "Pesquisar",
		"saved": "Associação Patronal {{id}}, guardada com sucesso.",
		"error": "Associação Patronal {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Associação Patronal {{id}}, apagada com sucesso",
		"fields": {
			"codAssocPatronal": "Cód.",
			"codAssocPatronalPlaceholder": "Código associação patronal",
			"nomeAssocPatronal": "Nome",
			"nomeAssocPatronalPlaceholder": "Nome",
			"morada": "Morada",
			"moradaPlaceholder": "Morada",
			"codPostal": "Código postal",
			"codPostalPlaceholder": "Código postal",
			"localidade": "Localidade",
			"localidadePlaceholder": "Localidade"
		}
	},
	"qpcae": {
		"title_detail": "Código Atividade Económica {{id}}",
		"title_new": "Nova Atividade Económica",
		"title_plural": "Códigos Atividade Económica",
		"pesquisa": "Pesquisar",
		"saved": "Atividade económica  {{id}}, guardada com sucesso.",
		"error": "Atividade económica {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Atividade económica {{id}}, apagada com sucesso",
		"fields": {
			"codcae": "Cód.",
			"codcaePlaceholder": "Código CAE",
			"nome": "Nome",
			"nomePlaceholder": "Nome",
			"codSubSec": "Sub-secção",
			"codSubSecPlaceholder": "Sub-secção"
		}
	},
	"qpcnp": {
		"title_detail": "Profissão {{id}}",
		"title_new": "Novo profissão",
		"title_plural": "Profissões",
		"pesquisa": "Pesquisar",
		"saved": "Profissão {{id}}, guardado com sucesso.",
		"error": "Profissão {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Profissão {{id}}, apagado com sucesso.",
		"fields": {
			"codNacProfissao": "Código",
			"nome": "Nome",
			"tipo": "Tipo"
		}
	},
	"qpdcf": {
		"title_detail": "Código Distrito / Concelho / Freguesia {{id}}",
		"title_new": "Novo Código Distrito / Concelho / Freguesia",
		"title_plural": "Códigos Distrito / Concelho/Freguesia",
		"pesquisa": "Pesquisar",
		"saved": "Código Distrito/Concelho/Freguesia {{id}}, guardado com sucesso.",
		"error": "Código Distrito/Concelho/Freguesia {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Código Distrito/Concelho/Freguesia {{id}}, apagado com sucesso",
		"fields": {
			"codDistConcFreg": "Cód.",
			"codDistConcFregPlaceholder": "Código Distrito/Concelho/Freguesia",
			"nome": "Nome",
			"nomePlaceholder": "Nome",
			"codDistrito": "Código distrito",
			"codDistritoPlaceholder": "Código distrito",
			"codConcelho": "Código concelho",
			"codConcelhoPlaceholder": "Código concelho",
			"codFreguesia": "Código freguesia",
			"codFreguesiaPlaceholder": "Código freguesia",
			"codServicoFinancas": "Código serviço finanças",
			"codServicoFinancasPlaceholder": "Código serviço finanças",
			"nomeServicoFinancas": "Nome serviço finanças",
			"nomeServicoFinancasPlaceholder": "Nome serviço finanças"
		}
	},
	"qpdur": {
		"title_detail": "Quadro Pessoal Duração {{id}}",
		"title_new": "Novo Quadro Pessoal Duração",
		"title_plural": "Quadros Pessoais Duração",
		"pesquisa": "Pesquisar",
		"saved": "Quadro Pessoal Duração {{id}}, guardado com sucesso.",
		"error": "Quadro Pessoal Duração {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Quadro Pessoal Duração {{id}}, eliminado com sucesso.",
		"fields": {
			"codDuracao": "Cód. duração",
			"nome": "Nome"
		}
	},
	"qphab": {
		"title_plural": "Habilitações",
		"pesquisa": "Pesquisar",
		"fields": {
			"codHabilitacao": "Código habilitação",
			"nome": "Nome"
		}
	},
	"qpirt": {
		"title_detail": "Pesquisa de IRCT {{id}}",
		"title_new": "Nova Pesquisa de IRCT",
		"title_plural": "IRCT",
		"pesquisa": "Pesquisar",
		"saved": "Pesquisa de IRCT {{id}}, guardada com sucesso.",
		"error": "Pesquisa de IRCT {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Pesquisa de IRCT {{id}}, apagada com sucesso",
		"fields": {
			"codIRCT": "Cód. IRCT",
			"nome": "Nome IRCT"
		}
	},
	"qpisp": {
		"title_detail": "Código ISP {{id}}",
		"title_plural": "Códigos ISP",
		"pesquisa": "Pesquisar",
		"fields": {
			"codISP": "Código ISP",
			"nome": "Nome"
		}
	},
	"qpmte": {
		"title_detail": "Motivo de Entrada de Empregado {{id}}",
		"title_new": "Novo Motivo de Entrada de Empregado",
		"title_plural": "Motivos de Entrada de Empregados",
		"pesquisa": "Pesquisar",
		"saved": "Motivo de Entrada de Empregado  {{id}}, guardado com sucesso.",
		"error": "Motivo de Entrada de Empregado {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Motivo de Entrada de Empregado {{id}}, apagado com sucesso",
		"fields": {
			"codmte": "Código MTE",
			"nome": "Nome"
		}
	},
	"qpmts": {
		"title_detail": "Motivo de Saída de Empregado {{id}}",
		"title_new": "Novo Motivo de Saída de Empregado",
		"title_plural": "Motivos de Saída de Empregados",
		"pesquisa": "Pesquisar",
		"saved": "Motivo de Saída de Empregado  {{id}}, guardado com sucesso.",
		"error": "Motivo de Saída de Empregado {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Motivo de Saída de Empregado {{id}}, apagado com sucesso",
		"fields": {
			"codmts": "Código MTS",
			"nome": "Nome"
		}
	},
	"qpnj": {
		"title_detail": "Natureza Jurídica {{id}}",
		"title_new": "Nova Natureza Jurídica",
		"title_plural": "Naturezas Jurídicas",
		"pesquisa": "Pesquisar",
		"saved": "Natureza Jurídica {{id}}, guardada com sucesso.",
		"error": "Natureza Jurídica {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Natureza Jurídica {{id}}, apagada com sucesso",
		"fields": {
			"codNatJuridica": "Cód.",
			"codNatJuridicaPlaceholder": "Código natureza jurídica",
			"nome": "Nome",
			"nomePlaceholder": "Nome"
		}
	},
	"qppaises": {
		"title_detail": "País {{id}}",
		"title_new": "Novo País",
		"title_plural": "Quadro Pessoal Países",
		"pesquisa": "Pesquisar",
		"saved": "País {{id}}, guardado com sucesso.",
		"error": "País {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "País {{id}}, eliminado com sucesso.",
		"fields": {
			"codPais": "Cód.",
			"name": "Nome",
			"abrev": "Abrev."
		}
	},
	"qpsit": {
		"title_detail": "Situação {{id}}",
		"title_new": "Nova Situação",
		"title_plural": "Situações",
		"pesquisa": "pesquisar",
		"saved": "Situação  {{id}}, guardada com sucesso.",
		"error": "Situação {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Situação {{id}}, apagada com sucesso",
		"fields": {
			"codsituacao": "Cód. situação",
			"nome": "Nome"
		}
	},
	"qptco": {
		"title_detail": "Quadro Pessoal Tipo de Contrato {{id}}",
		"title_new": "Nova Quadro Pessoal Tipo de Contrato",
		"title_plural": "Quadros Pessoal Tipo de Contrato",
		"pesquisa": "Pesquisar",
		"saved": "Quadro Pessoal Tipo de Contrato {{id}}, guardado com sucesso",
		"error": "Quadro Pessoal Tipo de Contrato {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Quadro Pessoal Tipo de Contrato {{id}}, apagada com sucesso",
		"fields": {
			"codTContrato": "Cód. quadro pessoal tipo de contrato",
			"nome": "Nome"
		}
	},
	"rastreabilidade": {
		"title": "Rastreabilidade",
		"export": {
			"svg": "Exportar SVG",
			"jpg": "Exportar JPG",
			"png": "Exportar PNG"
		}
	},
	"recaptcha": {
		"branding": {
			"1": "Este site é protegido pelo reCAPTCHA e são aplicáveis a",
			"2": "da Google.",
			"privacy": "Política de privacidade",
			"and": "e os",
			"terms": "Termos de serviço"
		},
		"v2modal": {
			"title": "Por favor verifique que não é um robô"
		}
	},
	"reciboimprimir": {
		"label": {
			"emailcliente": "E-mail do cliente",
			"enviar": "Enviar"
		},
		"email": {
			"required": "Para enviar, por favor, preencha o campo \"<em>E-mail do cliente\n</em>\"",
			"emailEnviado": "E-mail enviado para {{email}}",
			"emailErro": "Erro a enviar e-mail para {{email}}. Contacte o seu administrador de sistema."
		}
	},
	"recibos": {
		"title_new": "Novo recibo",
		"title_detail": "Novo recibo {{id}}",
		"title_plural": "Recibos",
		"pesquisa": "Pesquisar",
		"saved": "Recibo guardado com sucesso.",
		"error": "O recibo não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"extPocCabID": "Id.",
			"numero": "Nº recibo",
			"nome": "Cliente",
			"nconta": "Conta",
			"stampUpdate": "Ult. alteração",
			"origem": "Origem do documento",
			"descricao": "Descrição",
			"abreviaturaMoeda": "Moeda",
			"deData": "Data de",
			"nDocExterno": "Nº doc. externo"
		},
		"text": {
			"autoDistribute": "Distribuir valor automaticamente",
			"amountToReceive": "Valor a receber"
		},
		"recibo": {
			"title": "Recibo",
			"nCaixaAux": "Meio de pagamento",
			"nomeCaixaAux": "Nome meio pagamento",
			"tipoCaixaAux": "Tipo meio pagamento",
			"valorNCaixa": "Valor",
			"totalCaixa": "Entregue",
			"troco": "Troco",
			"cliente": "Cliente",
			"data": "Data",
			"percDescSobreValorIVA": "Desconto sobre valor do IVA",
			"cab": {
				"extPocCabID": "ID cabeçalho",
				"numero": "Número",
				"nConta": "Conta",
				"nome": "Nome",
				"nif": "NIF",
				"valor": "Valor",
				"valorPlaceHolder": "Valor a receber",
				"stampUpdate": "Data alteração",
				"totalDescontos": "Total descontos",
				"totalReceber": "Total a receber",
				"totalRecebido": "Total recebido",
				"temRetencao": "Tem retenção",
				"totalRetencao": "Total retenção",
				"descricao": "Descrição",
				"resumo": "Resumo",
				"valorEntregueCredito": "Total entregue a crédito"
			},
			"linhas": {
				"nlanc": "ID linha",
				"tipoDocumento": "Tipo de documento",
				"numeroDocumento": "Documento",
				"dataVencimento": "Data venc.",
				"valor": "Valor",
				"valorPorReceber": "Valor por receber",
				"percDesconto": "% Desconto",
				"valorDesconto": "Valor desconto",
				"valorRecebido": "Valor recebido",
				"valorIVA": "Valor de IVA",
				"montanteAReter": "Retenção",
				"montanteRetido": "Valor da retenção",
				"cambio": "Câmbio",
				"cambioOrigem": "Câmbio origem",
				"cambioPagam": "Câmbio pagamento",
				"dataDoc": "Data doc.",
				"dataDocExterno": "Data doc. externo",
				"nDocExterno": "Nº doc. externo",
				"descricao": "Descrição",
				"valorDoc": "Valor do documento"
			}
		},
		"saveModal": {
			"title": "Guardar recibo",
			"paymentMeans": "Meios de pagamento",
			"paymentMean": "Meio de pagamento",
			"paymentValue": "Valor entregue",
			"payInFull": "Pagar totalidade",
			"clearValue": "Limpar valor",
			"document": "Documento",
			"save": "Guardar",
			"saveCheck": "Guardar e ver PDF"
		},
		"imprimirModal": {
			"title": "Recibo - {{nomeConta}}",
			"fields": {
				"numeroDeVias": "Número de vias",
				"emailCliente": "E-mail",
				"relatorio": "Relatório"
			},
			"actions": {
				"send": "Enviar"
			},
			"placeholder": {
				"email": "\"mail@exemplo.com\" ou \"mail@exemplo.com; mail2@exemplo.com\""
			}
		},
		"info": {
			"anular": "Recibo anulado com sucesso!",
			"anularRecibo": "Anular recibo",
			"temCertezaAnularRecibo": "Tem a certeza que pretende eliminar o recibo?",
			"naoefetuadoCG": "O recibo não pode ser anulado porque não foi efetuado no CentralGest Cloud",
			"semMovimentos": "Sem movimentos em aberto!",
			"vencido": "Vencido",
			"naoVencido": "Não vencido",
			"aReceber": "A receber"
		},
		"erros": {
			"notselected": "Nenhum recibo selecionado!",
			"anular": "Erro na anulação do recibo!",
			"valorDescontoMaiorValor": "Valor do desconto superior ao valor recebido",
			"percentagemDescontoMaior100": "A percentagem de desconto não pode ser superior a 100%",
			"invalidValorAPagar": "O valor entregue ({{valorEntregue}}) tem de ser igual ou superior ao valor do documento ({{valorDocumento}}).",
			"moedasdiferentesnosrecibos": "Não é possível existir moedas diferentes nos recibos a lançar",
			"errodesconhecido": "Erro desconhecido",
			"cambiocomvalorerrado": "Câmbio com valor errado",
			"invalidHeaderDate": "A data do recibo não pode ser superior a 1 ano relativamente à data atual",
			"valorUltrapassado": "O valor a distribuir foi ultrapassado",
			"faltaDistribuirValor": "Tem de distribuir a totalidade do valor inserido"
		},
		"btn": {
			"receberTudo": "Receber tudo",
			"limpaTudo": "Limpar todos os valores recebidos",
			"distribuirValores": "Distribuir valor",
			"limpaValor": "Limpar valor recebido"
		},
		"modalDistribuir": {
			"tile": "Distribui valor",
			"automatico": "Automático",
			"manual": "Manual",
			"tooltip": {
				"automatico": "Ordenado do mais antigo para o mais recente.",
				"manual": "Permite distribuir até atingir o valor indicado."
			}
		}
	},
	"recibosvencimentos": {
		"todos": "Todos os colaboradores",
		"colaborador": "Colaborador",
		"gravado": "Gravado",
		"reciboimpresso": "Recibo impresso",
		"processadosegsocial": "Processado na seg. social",
		"integradocontabilidade": "Integrado na contabilidade",
		"mostraGrafico": "Mostra gráfico no recibo",
		"email": {
			"emailEnviado": "E-mail enviado com sucesso!",
			"emailNaoEnviado": "Ocurreu um erro ao enviar o e-mail. Contacte o seu administrador de sistema.",
			"insiraemail": "Tem de inserir um e-mail."
		},
		"modal": {
			"enviaremail": "E-mail",
			"byEmailAlternativo": "E-mail alternativo",
			"emailAlternativo": "E-mail"
		}
	},
	"reconstrucaoacumulados": {
		"title": "Reconstrução de Acumulados",
		"pesquisa": "Pesquisar",
		"steps": {
			"info": "Início da reconstrução de acumulados",
			"variables": "Dados para reconstruir os acumulados",
			"finish": "Finalizar"
		},
		"fields": {
			"tipoPoc": "Tipo de conta",
			"nConta": "Nº de conta",
			"contaEspecifica": "Só acumulados da conta",
			"todasAsContas": "Todas as contas"
		},
		"description": {
			"firstParagraph": "Esta operação irá reconstruir todos os acumulados das contas do plano de contas.",
			"secondParagraph": "Esta operação é exclusiva. Certifique-se que não existem utilizadores autenticados nesta empresa, caso contrário, não será possível continuar a operação",
			"finishMessage": "Esta operação poderá demorar algum tempo, para continuar carregue em finalizar.",
			"atencao": "Atenção! "
		},
		"alerts": {
			"reconstrucaoSucesso": "A reconstrução de acumulados foi concluída com sucesso"
		}
	},
	"reembolsosiva": {
		"title_detail": "Reembolso do período de declaração \"{{id}}\"",
		"title_detail_relacao": "Reembolso do período de relação \"{{id}}\"",
		"title_new": "Novo Reembolso",
		"title_plural": "Reembolsos IVA",
		"pesquisa": "Pesquisar",
		"saved": "Reembolso de IVA com o período de declaração \"{{id}}\" processado com sucesso.",
		"savedAlt": "Reembolso de IVA com o período de declaração \"{{id}}\" reprocessado com sucesso.",
		"error": "Reembolso de IVA com o período de declaração \"{{id}}\" não pode ser processado. Por favor verifique os documentos.",
		"errorEmptyPeriodosRelacao": "Não existem períodos de relação disponíveis para o período de declaração selecionado",
		"errorClosed": "Atenção: o período de declaração selecionado pertence a um ano contabilístico já encerrado",
		"pristineEmptyPeriodosRelacao": "Por favor selecione um período de declaração para puder escolher um período de relação",
		"deleteReembolsoTitle": "Apagar o reembolso de IVA?",
		"deleteReembolsoMessage": "Tem a certeza que deseja apagar o reembolso de IVA com o período de declaração \"{{declaracao}}\"?",
		"deleteReembolsoComplexMessage": "Tem a certeza que deseja apagar o reembolso de IVA com o período de declaração \"{{declaracao}}\" e período de relação \"{{relacao}}\"?",
		"deleteDocumentTitle": "Apagar o documento?",
		"deleteDocumentMessage": "Tem a certeza que deseja apagar o documento com o período de declaração \"{{declaracao}}\" e período de relação \"{{relacao}}\"?",
		"process": "Processar",
		"reProcess": "Reprocessar reembolso",
		"disabledHasErrors": "Não é possível alterar o período de relação se existirem erros nos documentos",
		"document": "Documento Nº",
		"documentNoErrors": "O documento não tem erros",
		"documentHasErrors": "O documento tem erros",
		"fields": {
			"periodoDeclaracao": "Período declaração",
			"periodoRelacao": "Período relação",
			"periodosRelacao": "Períodos relação",
			"nCampoDeducao": "Nº campo dedução",
			"mercado": "Mercado",
			"periodo": "Período",
			"nDiario": "Diário",
			"nDocInterno": "Nº documento",
			"codPais": "Cód. país",
			"abrevFiscal": "Prefixo",
			"nContribuinte": "Nº contrib",
			"dC": "Débito/Crédito",
			"valorTransacaoAbsoluto": "Val. aquisição s/ IVA",
			"valorIvaDeduzidoAbsoluto": "Val. IVA deduzido",
			"nDocExterno": "Doc. externo",
			"dataDocExterno": "Data doc. externo",
			"nDeclarImportExport": "Nº liq. importação",
			"nConta": "Nº conta",
			"nomeConta": "Nome",
			"anoDocExterno": "Ano doc. externo",
			"mesDocExterno": "Mês doc. externo",
			"all": "Todos os períodos de relação",
			"error": "Erro do documento"
		},
		"tabs": {
			"clients": "Clientes",
			"suppliers": "Fornecedores",
			"regularizations": "Regularizações",
			"documents": "Documentos",
			"relations": "Relações",
			"clientDoc": "Clientes_Documentos_{{nreembolso}}",
			"clientRel": "Clientes_Relacoes_{{nreembolso}}",
			"suppliersDoc": "Clientes_Documentos_{{nreembolso}}",
			"suppliersRel": "Clientes_Relacoes_{{nreembolso}}"
		},
		"messages": {
			"successDeleteDocument": "Reembolso de IVA apagado com sucesso"
		},
		"clientes": {
			"0": {
				"periodo": "Período"
			},
			"1": {
				"title": "Operações efetuadas com clientes nacionais",
				"datagrid": {
					"nif": "Número de identificação fiscal",
					"valor": "Valor"
				}
			},
			"3": {
				"title": "Exportação de bens",
				"datagrid": {
					"nIdnDeclaracaoExportacao": "Nº de identificação da declaração de exportação",
					"valor": "Valor"
				}
			},
			"4": {
				"title": "Operações efetuadas no estrangeiro",
				"valor": "Valor"
			},
			"5": {
				"title": "Outras operações isentas ou sem liquidação de IVA que conferem direito a dedução",
				"valor": "Valor"
			},
			"6": {
				"valor": "Valor"
			},
			"title": "Relação de clientes cujas operações originaram imposto deduzido do período"
		},
		"fornecedores": {
			"0": {
				"periodo": "Período"
			},
			"1": {
				"campo20": "Campo 20"
			},
			"2": {
				"campo21": "Campo 21"
			},
			"3": {
				"campo22": "Campo 22"
			},
			"4": {
				"campo23": "Campo 23"
			},
			"5": {
				"campo24": "Campo 24"
			},
			"7": {
				"totalValorAquisicoes": "Total valor das aquisições",
				"totalIvaDeduzido": "Total IVA deduzido"
			},
			"title": "Relação de fornecedores cujas operações originaram imposto deduzido do período",
			"datagrid": {
				"identificacao": "Identificação",
				"periodoEmissao": "Período de emissão",
				"prefixo": "Prefixo",
				"nif": "NIF",
				"nLiquidacao": "Nº liquidação",
				"valorAquisicoes": "Valor das aquisições",
				"ivaDeduzido": "IVA deduzido"
			}
		},
		"mercadoControloIva": {
			"notApplicable": "N/A",
			"national": "Nacional",
			"cee": "UE",
			"thirdPartyCountries": "Países terceiros"
		},
		"action": {
			"editDoc": "Editar documento",
			"deleteLine": "Apagar linha"
		},
		"showDocsWithErrorOnly": "Mostrar apenas documentos com erros"
	},
	"refeicoes": {
		"text": {
			"local": "Local",
			"menu": "Menu",
			"marcar": "Marcar",
			"desmarcar": "Desmarcar",
			"refeicoes": "Refeições"
		},
		"clonarmodal": {
			"title": "Clonar menus",
			"fields": {
				"localDestino": "Local destino",
				"dataInicio": "Data início",
				"dataFim": "Data fim"
			}
		}
	},
	"regIRC": {
		"data": {
			"geral": "Geral",
			"naoSujeicao": "Não sujeição",
			"isencaoDefinitiva": "Isenção definitiva",
			"isencaoTemporaria": "Isenção temporária",
			"reducaoTaxa": "Redução de taxa",
			"transicaoBenificiosFiscais": "Transicção benefícios fiscais",
			"transparenciaFiscal": "Transparência fiscal",
			"lucroConsolidado": "Lucro consolidado",
			"simplificado": "Simplificado",
			"normal": "Normal",
			"isencao": "Isenção",
			"pequenosRetalhistas": "Pequenos retalhistas",
			"regimeNormalBensSegMao": "Regime normal + bens 2ª mão",
			"regimeNormalRevendaCombustiveis": "Regime normal + revenda combustíveis",
			"regimeNormalIvaCaixa": "Regime normal + IVA de caixa"
		}
	},
	"rendhabitperm": {
		"fields": {
			"codEmp": "Empregado",
			"codAbdes": "Abono",
			"anoProcessamento": "Ano processamento",
			"valorRend": "Valor da renda",
			"rhTipoProce": "Processamento"
		},
		"filename": "Rendimentos de habitação permanente",
		"tipoRendHabitPerm": {
			"todas": "Todas",
			"ativas": "Ativas",
			"inativas": "Inativas"
		},
		"toolbar": {
			"tipoRendHabitPerm": "Visualiza rend. habit.: {{nomeTipo}}"
		},
		"modal": {
			"title": "Manutenção de rendimentos pelo uso de habitação permanente",
			"fields": {
				"tipoProcessamento": "Tipo processamento",
				"codAbdes": "Abono",
				"codEmp": "Cód. empregado",
				"anoProcessamento": "Ano processamento",
				"mesInicioProce": "Mês início",
				"suspende": "Suspenso",
				"valor": "Valor",
				"obs": "Observações",
				"obsSupensao": "Observações suspensão",
				"valorRenda": "Valor da renda",
				"valorRendaMaxMuni": "Valor renda máximo (Por Município Tipologia)"
			},
			"message": {
				"msgRendaMaxMuni": "Renda máxima admitida para o ano de 2024 de acordo com o disposto no decreto-lei n.º 90-c/2022, de 30 de dezembro (ordenada por município)"
			}
		}
	},
	"rendis": {
		"title_detail": "Tipo de rendimento {{id}}",
		"title_new": "Novo tipo de rendimento",
		"title_plural": "Tipos de rendimentos",
		"pesquisa": "Pesquisar",
		"saved": "Guardado com sucesso.",
		"error": "Tipo de rendimento {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Tipo de rendimento {{id}}, apagado com sucesso.",
		"deleteModalText": "Apagar registo \"{{tipRendiment}}\" ?",
		"fields": {
			"tipRendiment": "Tipo de rendimento",
			"nome": "Nome do rendimento",
			"tipRendimentId": "Código tipo de rendimento",
			"tipoDeclaracao": "Tipo de declaração"
		}
	},
	"tiposDeclaracao": {
		"data": {
			"modelo10": "Modelo 10",
			"modelo22": "Modelo 22",
			"modelo30": "Modelo 30",
			"modelo39": "Modelo 39"
		}
	},
	"repfinancas": {
		"title_detail": "Repartição de Finanças {{id}}",
		"title_new": "Nova Repartição de Finanças{{id}}",
		"title_plural": "Repartições de Finanças",
		"pesquisa": "Pesquisar",
		"saved": "Repartição de Finanças {{id}}, guardada com sucesso",
		"error": "Repartição de Finanças {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Repartição de Finanças {{id}}, apagada com sucesso",
		"fields": {
			"codFiscal": "Código da repartição",
			"nome": "Nome da repartição",
			"tipo": "Tipo de finanças",
			"morada": "Morada",
			"codPostal": "Código postal",
			"codDistrito": "Código do distrito",
			"nomeDistrito": "Nome do distrito",
			"codPais": "Código do país",
			"nomePais": "Nome do país",
			"telefone": "Telefone",
			"fax": "Fax",
			"codFinancasAT": "Cód. finanças AT"
		},
		"tipo": {
			"bairroFiscal": "Bairro fiscal",
			"reparticao": "Repartição"
		}
	},
	"reparticoesccustos": {
		"title_detail": "Repartição por Centro de Custo {{id}}",
		"title_new": "Nova Repartição por Centro de Custo",
		"title_plural": "Repartições por Centro de Custos",
		"pesquisa": "Pesquisar",
		"add": "Adicionar",
		"saved": "Repartição por Centro de Custo {{id}}, guardada com sucesso",
		"error": "Repartição por Centro de Custo {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Repartição por Centro de Custo {{id}}, apagada com sucesso",
		"fields": {
			"nCodRepCC": "Tabela",
			"descricao": "Descrição",
			"designacao": "Designação",
			"nCodRepCCPlaceholder": "Tabela",
			"descricaoPlaceholder": "Designação",
			"valorMascara": "Centro Custo",
			"perc": "Percentagem",
			"dataReferencia": "Data referência",
			"mascara": "Máscara C. Custo"
		},
		"mascara": {
			"cCusto": "Centro Custo",
			"zona": "Zona",
			"departamento": "Departamento",
			"SubDepartamento": "Sub-departamento",
			"familia": "Família",
			"grandeFamilia": "Gr. família",
			"subFamilia": "Sub-família",
			"codContab": "Cód. contab",
			"tipoArtigo": "Tipo artigo",
			"classe": "Classe",
			"categoria": "Categoria",
			"vendedor": "Vendedor",
			"nRefProcesso": "Processo"
		},
		"title": {
			"apagarDatadeReferencia": "Apagar data de referência",
			"apagarItemPerDate": "Remover data",
			"apagarItem": "Remover repartição por centro de custo"
		},
		"message": {
			"desejaApagarDataReferencia": "Deseja apagar a data de referência?",
			"reparticaoccustoEliminadoComSucesso": "Repartição por centro de custo eliminada com sucesso",
			"desejaApagarItemPerDate": "Se remover a última linha da data selecionada, a mesma será eliminada. Pretende continuar?",
			"desejaApagarItem": "Se remover a última linha da Repartição por Centro de Custo, a mesma será eliminada. Pretende continuar?"
		},
		"text": {
			"addDate": "Adicionar data",
			"selectDate": "Selecionar data"
		}
	},
	"reparticoesccustosfaturacao": {
		"title_detail": "Repartição por centro de custo {{id}}",
		"title_new": "Nova repartição por centro de custo",
		"title_plural": "Repartições por centro de custos",
		"pesquisa": "Pesquisar",
		"saved": "Repartição por Centro de Custo {{id}}, guardada com sucesso",
		"error": "Repartição por Centro de Custo {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Repartição por Centro de Custo {{id}}, apagada com sucesso"
	},
	"reparticoesccustossalarios": {
		"title_detail": "Repartição por Centro de Custo {{id}}",
		"title_new": "Nova Repartição por Centro de Custo",
		"title_plural": "Repartições por Centro de Custos",
		"pesquisa": "Pesquisar",
		"saved": "Repartição por Centro de Custo {{id}}, guardada com sucesso",
		"error": "Repartição por Centro de Custo {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Repartição por Centro de Custo {{id}}, apagada com sucesso"
	},
	"reports": {
		"pesquisa": "Pesquisar",
		"fields": {
			"name": "Nome",
			"namePlaceholder": "Nome do relatório",
			"title": "Título",
			"titlePlaceholder": "Título do relatório"
		},
		"messages": {
			"noReports": "Nenhum relatório encontrado"
		}
	},
	"resumoiva": {
		"fields": {
			"codigo": "Código",
			"nome": "Nome",
			"infoString": "NIF",
			"valor": "Valor",
			"percentagem": "%",
			"tipodocumento": "Tipo documento",
			"iva": "IVA ",
			"basetributavel": "Base tributável",
			"totais": "Totais"
		},
		"totalvendas": "Total de vendas",
		"btn": {
			"mes": "Mês",
			"primeiroTrimestre": "1º Trimestre",
			"segundoTrimestre": "2º Trimestre",
			"terceiroTrimestre": "3º Trimestre",
			"quartoTrimestre": "4º Trimestre"
		},
		"toolbar": {
			"month": "Mês: {{nameMonth}}"
		}
	},
	"retencoes": {
		"header": {
			"tipoVisualizacao": "Tipo de visualização",
			"nifDe": "Nº contribuinte",
			"tipRetServNif": "Entidade habitualmente",
			"estadoRet": "Estado retenção",
			"origemContas": "Origem contas",
			"radio": {
				"retEfeutadas": "Retenções efetuadas",
				"docsSujeitosRet": "Docs. sujeitos a retenção",
				"sujeitoRetencao": "Por efetuar",
				"retido": "Efetuada",
				"ambos": "Ambos"
			}
		},
		"table": {
			"fields": {
				"isContaPocOrigemFornecedor": "Origem",
				"nContribuinte": "Nº contribuinte",
				"conta": "Conta",
				"codRetencao": "Retenção",
				"taxaRetUsada": "Taxa",
				"tipoRendimento": "Tipo rendimento",
				"periodo": "Período",
				"nDiario": "Diário",
				"nDocInterno": "Nº documento",
				"descricaoDC": "Débito/Crédito",
				"nDocExterno": "Nº doc. externo",
				"valorOrigem": "Valor base incidência",
				"valorDestino": "Valor a reter",
				"valorDestDispo": "Valor retido",
				"dataDoc": "Data documento"
			}
		},
		"doc": "Ver documento",
		"config": {
			"showColumnIsContaPocOrigemFornecedor": "Mostrar origem",
			"showColumnNContribuinte": "Mostrar nº contribuinte",
			"showColumnConta": "Mostrar conta",
			"showColumnCodRetencao": "Mostrar retenção",
			"showColumnTaxaRetUsada": "Mostrar taxa",
			"showColumnTipoRendimento": "Mostrar tipo rendimento",
			"showColumnPeriodo": "Mostrar período",
			"showColumnNDiario": "Mostrar nº diário",
			"showColumnNDocInterno": "Mostrar nº doc. interno",
			"showColumnDescricaoDC": "Mostrar débito/crédito",
			"showColumnDataDoc": "Mostrar data",
			"showColumnNDocExterno": "Mostrar nº doc. externo",
			"showColumnValorOrigem": "Mostrar valor base incidência",
			"showColumnValorDestino": "Mostrar valor a reter",
			"showColumnValorDestDispo": "Mostrar valor retido"
		},
		"error": {
			"dataFinalInferiorInicial": "A data final é inferior à inicial"
		}
	},
	"rhEstatisticas": {
		"porEmpresa": "Por empresa",
		"multiEmpresa": "Multi-empresa",
		"fields": {
			"codEmp": "Cód.",
			"nome": "Nome",
			"totalAbonos": "Total abonos",
			"totalDescontos": "Total descontos",
			"totalIliquido": "Total bruto",
			"irs": "IRS",
			"segSocial": "Seg. social",
			"cga": "CGA",
			"sindicato": "Sindicato",
			"totalImpostos": "Total impostos",
			"totalRecibo": "Total liquido",
			"totalLiquido": "Total liquido",
			"totalEspecie": "Total espécie",
			"totalAPagar": "Total a pagar",
			"encargos": "Encargos",
			"custoEmpresa": "Custo empresa",
			"totalCustosEmpresa": "Custo empresa",
			"departamento": "Departamento",
			"ccusto": "Centro custo",
			"abonos": "Abonos",
			"descontos": "Descontos",
			"colaboradores": "Colaboradores",
			"impostos": "Impostos",
			"qtd": "Qtd",
			"valorUnit": "V. unit",
			"valorTotal": "Valor total",
			"dtNascimento": "Data nascimento",
			"regimeSS": "Regime seg. social",
			"totalRemun": "Total remun.",
			"codP": "Cod. P",
			"codR": "Cod. R",
			"codF": "Cod. F",
			"codN": "Cod. N",
			"codA": "Cod. A",
			"codB": "Cod. B",
			"codC": "Cod. C",
			"codD": "Cod. D",
			"codH": "Cod. H",
			"codM": "Cod. M",
			"codO": "Cod. O",
			"codS": "Cod. S",
			"codT": "Cod. T",
			"codX": "Cod. X",
			"cod2": "Cod. 2",
			"cod6": "Cod. 6",
			"codI": "Cod. I",
			"encargosTrabalhador": "Encargos trabalhador",
			"encargosEmpresa": "Encargos empresa",
			"totalEncargos": "Total encargos",
			"sindicatoPerc": "% Sindicato",
			"valorSujeito": "Valor sujeito",
			"valorDesconto": "Valor desconto",
			"niss": "NISS",
			"seguradora": "Seguradora",
			"nEmpresa": "Nº empresa",
			"totalSalarios": "Total salários",
			"totalCustos": "Total custos",
			"nif": "NIF",
			"totalRendimentos": "Total rendimentos",
			"rendAnoAnterior": "Rend. ano anterior",
			"rendNaoSujeito": "Rend. não sujeito",
			"rendSujeito": "Rend. sujeito",
			"retencaoIrs": "Retenção IRS",
			"areaRendimento": "Area rendimento",
			"contibuicoesSS": "Contribuições SS",
			"tipoRendimento": "Tipo rendimento",
			"a25": "A25",
			"a81": "A81",
			"a18": "A18",
			"a40": "A40",
			"a19": "A19",
			"a12": "A12",
			"a68": "A68",
			"a13": "A13",
			"a69": "A69",
			"a11": "A11",
			"al6": "Al6",
			"al7": "Al7",
			"al4": "Al4",
			"a62": "A62",
			"a15": "A15",
			"a63": "A63",
			"a61": "A61",
			"a66": "A66",
			"a67": "A67",
			"a2": "A2",
			"a64": "A64",
			"a3": "A3",
			"a65": "A65",
			"a32": "A32",
			"a33": "A33",
			"a30": "A30",
			"a31": "A31",
			"a4": "A4",
			"a5": "A5",
			"a22": "A22",
			"a23": "A23",
			"a": "A",
			"a20": "A20",
			"a21": "A21",
			"a26": "A26",
			"a80": "A80",
			"a27": "A27",
			"a24": "A24",
			"totalBruto": "Total bruto"
		},
		"filters": {
			"mesProce": "Mês de processamento",
			"codEmpregado": "Código empregado",
			"depto": "Departamento",
			"ccusto": "Centro custo",
			"anoProce": "Ano de processamento"
		},
		"modals": {
			"exclusao": {
				"title": "Exclusão de registos"
			}
		},
		"messages": {
			"intervaloInvalidoEmpregado": "O intervalo do filtro código de empregado é inválido.",
			"intervaloInvalidoDepto": "O intervalo do filtro departamento é inválido.",
			"intervaloInvalidoMesProc": "O intervalo do filtro mês de processamento é inválido."
		},
		"string": {
			"estatisticas": "Estatísticas",
			"analiseRemuneracoes": "Análise de remunerações",
			"analiseMensalDetalhada": "Análise mensal detalhada",
			"extratoRemuneracoes": "Extrato de remunerações",
			"analiseAnual": "Análise anual",
			"analiseSegSocial": "Análise seg. social",
			"analiseAT": "Análise AT",
			"analiseSindicato": "Análise sindicato",
			"analiseCGA": "Análise CGA",
			"analiseSeguro": "Análise seguro",
			"listagensRU": "Listagens RU",
			"vistaResumo": "Vista resumo",
			"vistaDetalhadaPorAbonoDesconto": "Vista detalhada por abono e desconto",
			"impostosColaborador": "Impostos colaborador",
			"recibo": "Recibo",
			"custosEmpresa": "Custos empresa",
			"excluidos": "Excluídos",
			"tipo": "Tipo",
			"funcionario": "Colaborador",
			"totais": "Totais",
			"anoMenosUm": "Ano N - 1",
			"totalN": "Total N",
			"pesquisar": "Pesquisar",
			"config": "Configuração"
		}
	},
	"rhMapasSindicatos": {
		"btn": {
			"previsualizar": "Pré-visualizar"
		},
		"filters": {
			"dedata": "Data de",
			"deSindicato": "Sindicato de"
		}
	},
	"rhtipoprocess": {
		"title_detail": "Tipo de Processamento {{id}}",
		"title_new": "Novo Tipo Processamento",
		"title_plural": "Tipos de Processamentos",
		"pesquisa": "Pesquisar",
		"saved": "Tipo Processamento {{id}}, guardado com sucesso.",
		"error": "Tipo Processamento {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Tipo Processamento {{id}}, eliminado com sucesso.",
		"fields": {
			"tipoProcessamento": "Tipo processamento",
			"descricao": "Descrição",
			"dadosVencimento": "Dados vencimento",
			"dadosManuais": "Dados manuais",
			"dadosFixos": "Dados fixos",
			"subsidioRefeicao": "Subsídio refeição",
			"diuturnidades": "Diuturnidades",
			"subsidioNatal": "Subsídio natal",
			"subsidioFerias": "Subsídio férias",
			"valorCaducidadeCTR": "Valor caducidade contrato termo",
			"tipoPeriodicidade": "Periocidade",
			"desativado": "Tipo de processamento desativado",
			"func": "Função"
		}
	},
	"rhagencias": {
		"onlyativos": "Mostrar só ativos",
		"onlyiban": "Mostrar só com IBAN",
		"fields": {
			"codAgencia": "Cód. agência",
			"nome": "Nome",
			"iban": "IBAN",
			"descricao": "Descrição"
		},
		"btn": {
			"addAgencia": "Adicionar agência",
			"addBanco": "Adicionar banco",
			"addConta": "Adicionar conta",
			"editAgencia": "Editar agência",
			"editBanco": "Editar banco",
			"editConta": "Editar conta",
			"deleteAgencia": "Eliminar agência",
			"deleteBanco": "Eliminar banco",
			"deleteConta": "Eliminar conta"
		},
		"messages": {
			"delAgencia": "Tem a certeza que pretende eliminar a agência?",
			"delBanco": "Tem a certeza que pretende eliminar o banco?",
			"delConta": "Tem a certeza que pretende eliminar a conta?",
			"delsuccAgencia": "Agência eliminada com sucesso!",
			"delsuccConta": "Conta eliminada com sucesso!",
			"newSuccAgencia": "Agência adicionada com sucesso!",
			"newSuccConta": "Conta adicionada com sucesso!",
			"updateSuccAgencia": "Agência atualizada com sucesso!",
			"updateSuccConta": "Conta atualizada com sucesso!"
		},
		"modal": {
			"agencia": {
				"title": "Agências",
				"fields": {
					"banco": "Banco",
					"codAgencia": "Código agência",
					"nome": "Nome",
					"descBreve": "Descrição breve",
					"morada": "Morada",
					"codPostal": "Código postal",
					"localidade": "Localidade",
					"telefone": "Telefone",
					"contacto": "Contacto",
					"contaagencia": "Id interb. agência",
					"fax": "Fax",
					"estado": "Estado",
					"tipoInstal": "Tipo de instalação",
					"sede": "Sede",
					"agencia": "Agência"
				}
			},
			"conta": {
				"title": "Contas",
				"fields": {
					"banco": "Banco",
					"agencia": "Agência",
					"nib": "NIB",
					"nContaPoc": "Nº conta",
					"gestorBanco": "Gestor",
					"descricao": "Descrição",
					"nProjecto": "Núm. do projeto",
					"iban": "IBAN",
					"swift": "SWIFT",
					"debitoDirectoID": "Id. débito direto",
					"nContaEmprestimos": "Nº c. empréstimos",
					"codPais": "Cód. país",
					"status": "Estado da conta",
					"validaIBAN": "Valida IBAN"
				}
			}
		}
	},
	"rhintegracaosalarios": {
		"titles": {
			"dadosadicionaisint": "Dados adicionais para a Integração de Salários",
			"dadosadicionaisuni": "Dados adicionais para o Relatório Único",
			"dadosadicionaisuniint": "Dados adicionais para o Relatório Único e Integração de Salários",
			"confgintegracaosalarios": "Configuração de Integração de Salários",
			"mascaraanalitica": "Máscara Analítica"
		},
		"fields": {
			"integraSalarios": "Integração  de salários",
			"pocRemuLiq": "Conta do líquido a receber",
			"classeTrab": "Classe trabalho",
			"funcao": "Função",
			"operacaoSeccao": "Operação/Secção",
			"codZona": "Cód. zona",
			"departamento": "Departamento",
			"subDepartamento": "Sub. departamento",
			"centroCusto": "Centro de custo",
			"reparticaoCentroCusto": "Repartição centro de custo",
			"integraRU": "Integra no relatório único",
			"profissao": "Profissão",
			"situacaoProfissao": "Situação profissão",
			"codCCT": "Código de CCT",
			"nivelQualif": "Nível de qualificação",
			"categoria": "Categoria",
			"integraSalariosPorDefeito": "Integração normal",
			"integraSalariosPorOrgaoSocial": "Integração por orgãos sociais/pessoal",
			"validamascara": "Tem configuração por máscara analítica",
			"mascara": "Máscara C. Custo"
		},
		"tab": {
			"integracaosalarios": "Integração salários",
			"mascaraanaliticaabono": "Máscara analítica abonos",
			"mascaraanaliticadesconto": "Máscara analítica descontos"
		},
		"message": {
			"savedsuccessfully": "Dados guardados com sucesso!",
			"resetsuccessfully": "Dados repostos com sucesso!",
			"retiraropcaointegsal": "Ao retirar esta opção deixará de ter a integração de salários."
		},
		"btn": {
			"repor": "Repor"
		},
		"configApoiosCOVID19": "Configuração apoios a receber segurança social - COVID-19",
		"configApoiosCOVID19Fields": {
			"card": {
				"apoioexcepcionalfamilia": "Apoio excecional à família para trabalhadores por conta de outrem (COVID-19)",
				"apoioLayoffSimplificadoPSuspensaoTrabalho": "Apoio lay off simplificado por suspensão de trabalho (COVID-19)",
				"apoioLayoffSimplificadoPReducaoTrabalho": "Apoio lay off simplificado por redução de trabalho (COVID-19)"
			},
			"nContaCovid19ApoioFamilia": "Nº conta",
			"nContaCovid19ApoioFamiliaPerc": "Perc.",
			"nContaCovid19LayoffSimplSuspencao": "Nº conta",
			"nContaCovid19LayoffSimplSuspencaoPerc": "Perc.",
			"nContaCovid19LayoffSimplParcial": "Nº conta",
			"nContaCovid19LayoffSimplParcialPerc": "Perc.",
			"mensagemPercDeApoioAtribuidoSS": "% do apoio atribuído pela segurança social"
		}
	},
	"rhatualizaferiasemp": {
		"atualizadatacadastro": "Atualiza data de cadastro",
		"fields": {
			"empregado": "Empregado",
			"feriasano": "Nº dias férias ano {{ano}}",
			"temregano": "Tem registo cadastro no ano {{ano}}",
			"codEmp": "Empregado",
			"nomeEmpregado": "Nome",
			"anoAnteriorNDiasFeriasAnoAnterior": "Ano anterior",
			"anoAnteriorGozadasNDiasFeriasAnoAnterior": "Gozadas no ano anterior",
			"anoAnteriorGozadasNDiasFeriasAnoAtual": "Gozadas no ano",
			"nDiasFeriasAnoAnterior": "Ano anterior",
			"dataMotivoCadastro": "Data cadastro",
			"livre": "Info"
		},
		"messages": {
			"temselecionaremp": "Não selecionou nenhum funcionário.",
			"savedsuccess": "Gravação efetuada com sucesso.",
			"datainferior": "A data não pode ser inferior à data já registada.",
			"valNegativo": "O valor não pode ser negativo."
		}
	},
	"rhcalculorendimentoliquido": {
		"success": "Cálculo por rendimento guardado com sucesso!",
		"fields": {
			"codEmp": "Cód. empregado",
			"valorLiquido": "Valor líquido",
			"nCodABDESC": "Abono",
			"adicionaRecolha": "Adicionar valor calculado do abono na recolha de dados"
		},
		"steps": {
			"processos": "Processos",
			"empregados": "Empregados",
			"calcular": "Calcular"
		},
		"titles": {
			"empregados": "Empregados do processamento",
			"processamentos": "Lista de processamentos"
		},
		"empregado": {
			"codEmpregado": "Cód.",
			"nomeEmpregado": "Nome",
			"strEstadoProcEmpregado": "Estado",
			"nProcessamento": "Nº proce.",
			"nRecibo": "Nº recibo",
			"dataProcessamento": "Data proce.",
			"identificacaoEmpregado": "Empregado"
		},
		"processo": {
			"nProcessamento": "Nº proce.",
			"dataProcessamento": "Data proce.",
			"tipoProcessamento": "Tipo proce.",
			"proceDescricao": "Descrição",
			"dataInicoProc": "Data início",
			"dataFimProce": "Data fim"
		},
		"messages": {
			"invalidStepProc": "Tem de selecionar um processo!",
			"invalidStepEmp": "Tem de selecionar um Empregado!"
		},
		"btn": {
			"finalizar": "Calcular"
		}
	},
	"rhcessacontratotrabalho": {
		"fields": {
			"datacessacao": "Data de cessação",
			"entidade": "Entidade que fez cessar o contrato",
			"tipoContrato": "Tipo de contrato",
			"datainicio": "Data de início",
			"horasSemana": "Horas por semana",
			"dataPrimeiraRenovacaoExtra": "Data 1ª renovação extraordinária",
			"retribuicaoBase": "Retribuição base",
			"diuturnidades": "Diuturnidades",
			"complementos": "Complementos",
			"feriasGozadas": "Férias já gozadas",
			"subsidioFerias": "Subsídio de férias já recebido",
			"subSidioNatalJaRecebido": "Subsídio de Natal já recebido",
			"codEmp": "Cód. empregado",
			"duracao": "Duração: ",
			"situacao": "Novo cód. situação",
			"motivoCessacao": "Novo motivo registo",
			"duracaodata": "{{years}} ano(s) {{months}} mes(es) {{days}} dia(s)",
			"tipoProcessamento": "Tipo processamento: ",
			"radio": {
				"empregador": "Empregador",
				"trabalhador": "Trabalhador",
				"justacausa": "Com justa causa?"
			},
			"calculado": {
				"compensacao": "Compensação: ",
				"valorFeriasVencidas": "Férias não gozadas: ",
				"valorSubsidioFerias": "Subsídio de férias a receber: ",
				"feriasPropValor": "Férias: ",
				"subsidioFeriasPropValor": "Subsídio de Férias: ",
				"subsidioNatalPropValor": "Subsídio de Natal: ",
				"compensacaoTotal": "Montante global: "
			}
		},
		"card": {
			"confirmedados": "Confirme os seguintes dados",
			"valorescalc": "Valores do cálculo",
			"valoremfalta": "Férias - Valor em falta",
			"proporcionais": "Proporcionais no ano de cessação",
			"retribuicao": "Retribuição",
			"ferias": "Férias"
		},
		"btn": {
			"simularcessacao": "Calcular valor",
			"dadosemp": "Manutenção de dados do empregado",
			"validar": "Validar/Editar valores",
			"manutencaocessacontrato": "Configuração da cessação contrato"
		},
		"infos": {
			"tooltipRetribuicaoBase": "Retribuição base é a correspondente à atividade do trabalhador no período normal de trabalho.",
			"tooltipComplementos": "Prestações regulares periódicas. Incluídos por exemplo: isenção de horário trabalho, gratificações, subsídios noturno ou de turnos, quando regulares. Excluídos por exemplo ajudas de custo, subsídios de refeição, de transporte ou de representação e comissões.",
			"tooltipTipoContrato": "Contrato por tempo indeterminado é o vulgarmente chamado efetivo. <br> Contrato a termo certo é o que tem uma data específica prevista para acabar. <br> Contrato a termo incerto é aquele que é por um determinado período mas não se sabe a data em que acaba. <br> Contrato temporário pode ser certo ou incerto.",
			"tooltipJustCausa": "A justa causa só pode ser apreciada por tribunal. Ver os artigos 351.º e 394.º do código do trabalho sobre justa causa.",
			"tooltipFeriasPropValor": "O simulador considera para efeitos de cálculo: <br> - do valor das férias e dos respetivos proporcionais: retribuição base, diuturnidades e prestações retributivas indicadas pelo utilizador como sendo contrapartida do modo específico da prestação do trabalho. <br> - do subsídio de férias e dos respetivos proporcionais: retribuição base e as prestações retributivas indicadas pelo utilizador como sendo contrapartida do modo específico da prestação do trabalho. <br> - dos proporcionais do subsídio de natal: retribuição base e diuturnidades.'",
			"tooltipExcecpoes": "Exceções",
			"tooltipReCalcular": "Calcular complementos",
			"temcalcularvalor": "Tem de efetuar o cálculo para validar os valores.",
			"temcorrigiremp": "Tem de corrigir os erros no empregado.",
			"insiraemp": "Tem de inserir um empregado.",
			"situacaoandmotivodefault": "As configurações por defeito da situação da cessação e o motivo do registo não estão preenchidas. Pretende configurar?",
			"calculadocomplementossuccess": "Complementos calculados com sucesso!",
			"calcsuccess": "Valores calculados com sucesso!",
			"gravadocomsucessonotipoproc": "Os dados de caducidade foram gravados com sucesso na Recolha de dados no tipo de processamento '{{tipoprocessamento}}'.",
			"abonodescsuccess": "Abono/Desconto adicionado com sucesso!"
		},
		"modal": {
			"defsitandmot": "Pretende definir os códigos da 'Situação' e do 'Motivo' por defeito?",
			"encerrar": {
				"cessacaocontrat": "Cessação de contrato de trabalho",
				"table": {
					"nCodABDESC": "Cód. abono/desc",
					"descricaoAbono": "Descrição",
					"valor": "Valor",
					"observacoes": "Obs.",
					"semdados": "Sem dados"
				}
			},
			"complementosexcepcoes": {
				"title": "Excepções",
				"indiqueexcluidos": "Indique os itens que deseja excluir da seleção",
				"possiveis": "Possíveis",
				"excluidos": "Excluídos",
				"fields": {
					"codABDESC": "Código",
					"designaBreve": "Nome"
				}
			},
			"addabonodesconto": {
				"title": "Adicionar novo abono/desconto à cessação de contrato",
				"fields": {
					"abonoDesc": "Tipo",
					"codABDESC": "Código",
					"valor": "Valor",
					"observacoes": "Obs."
				}
			}
		}
	},
	"rhcopiaabonosdescontos": {
		"fmt": {
			"relatorioLoadABDESCopia": "{{codAbdes}}-{{nomeCodAbdes}}",
			"abonoDescontoACopiar": "Abono/Desconto a copiar: {{abdesCopia}}",
			"empresaDeOrigem": "Empresa de origem: {{codEmpresaOrigem}}-{{nomeEmpresaOrigem}}",
			"empresaDeDestino": "Empresa de destino: {{codEmpresaDestino}}-{{nomeEmpresaDestino}}"
		},
		"titles": {
			"copiaDeAbonosDescontosMultiEmpresa": "Cópia de abonos / Descontos multi-empresa",
			"selecaoDeEmpresas": "Seleção de empresas",
			"confirmacaoDasOperacoes": "Confirmação das operações",
			"estadoDaCopia": "Estado da cópia"
		},
		"text": {
			"1-1": "Este assistente vai guiá-lo no processo de copiar ou atualizar o abono/desconto de forma automática a todas as empresas que estejam selecionadas.",
			"1-2": "Também será possível copiar os cruzamentos, mas não permite atualizá-los na empresa destino.",
			"1-3": "Por fim será demonstrado um relatório com a informação de sucesso ou os erros de forma detalhada, para poder proceder à correção dos mesmos.",
			"2-1": "Encontra-se ",
			"3-1": "Confirme se pretende substituir as empresas que já têm os abonos/descontos configurados"
		},
		"fields": {
			"tipo": "Tipo",
			"codigo": "Abono/Desconto",
			"abono": "Abono",
			"desconto": "Desconto",
			"copiaCruzamentos": "Copia cruzamentos",
			"copiaIntSalarios": "Copia integração de salários"
		},
		"modal": {
			"title": "Informação da cópia de abonos/descontos",
			"radio": {
				"gerarNovo": "Gerar novo código de grupo com base na origem",
				"utilizarExistente": "Utilizar um grupo existente no destino com todas as características da origem"
			}
		},
		"dataGrid": {
			"caption": {
				"empresa": "Cód. da empresa",
				"nomeEmpresa": "Nome da empresa",
				"copiouABDES": "Cop. ABDES",
				"copiouGrupo": "Cop. grupo",
				"copiouCruzamento": "Cop. cruzamento",
				"copiouIntegracaoSalarios": "Cop. integração de salários",
				"permiteCopia": "Info.",
				"codAbdesDestino": "Cód. destino",
				"nomeAbdesDestino": "Nome destino",
				"temCruzamento": "Tem cruzamento",
				"mensagem": {
					"resultado": "Resultado",
					"descricao": "Descrição"
				}
			}
		},
		"errors": {
			"semCodAbDesc": "Tem de selecionar um Abono/Desconto",
			"semEmpresas": "Tem de selecionar pelo menos uma empresa",
			"selecionarLinhaComErro": "A linha não pode ser selecionada porque contém um erro",
			"selecionarLinhaComAviso": "Escolha uma ação antes de selecionar a linha"
		}
	},
	"rhdadosconfigficha": {
		"titles": {
			"registo": "Registo",
			"situacaoemprego": "Situação emprego",
			"dadosrenumeracao": "Dados remuneração",
			"outrosdados": "Outros dados",
			"subsidioferias": "Subsídio de férias",
			"subsidionatal": "Subsídio de natal",
			"residenciafiscal": "Residência fiscal"
		},
		"fields": {
			"motivoRegisto": "Motivo do registo",
			"situacaoEmprego": "Situação",
			"nCodABVencimento": "Abono vencimento",
			"nCodABSubAlimentacao": "Abono de subsídio alimentação",
			"nCodABSubFerias": "Abono de subsídio férias",
			"nCodABSubNatal": "Abono de subsídio natal",
			"nCodABDiuturnidades": "Abono de diuturnidades",
			"nCodDescIRS": "Desconto IRS",
			"localTrabalho": "Local ou estabelecimento",
			"tipoHorarioTrabalho": "Tipo horário",
			"nMaxDiasUteisFerias": "Nº máximo dias úteis",
			"nDiasTemDireitoFerias": "Nº dias úteis que tem direito",
			"nMaxDiasUteisNatal": "Nº máximo dias",
			"nDiasTemDireitoNatal": "Nº dias que tem direito",
			"nCodABFerias": "Abono de férias",
			"localObtencaoRendimentos": "Local obtenção rendimentos"
		},
		"text": {
			"suggestion1": "Parece que ainda não configurou os dados por defeito da ficha do empregado. Deseja",
			"suggestion2": "aplicar a configuração recomendada"
		}
	},
	"rhfolfer": {
		"fields": {
			"dataProcesDE": "Data de processamento de",
			"deAbono": "Abono de",
			"deCCusto": "Centro de custo de",
			"deDepto": "Departamento de",
			"deDesconto": "Desconto de",
			"deSeccao": "Secção de",
			"deSubDepto": "Sub-departamento",
			"deZona": "Zona de",
			"doCodNum": "Cód. empregado de",
			"mostraAbonosVirtuais": "Mostra abonos virtuais"
		},
		"infos": {
			"tooltipExcecpoesAbono": "Exceções abonos",
			"tooltipExcecpoesDesconto": "Exceções descontos",
			"tooltipExcepcoesProcessamento": "Exceções processamento"
		},
		"btn": {
			"previsualizar": "Pré-visualizar"
		},
		"messages": {
			"exclprocessamentoemp": "Só pode ter a empresa atual selecionada!"
		}
	},
	"rhgestaoficheirosdmrat": {
		"comprovativo": "Comprovativo",
		"docpagamento": "Documento de pagamento",
		"emaildest": "E-mail destinatário",
		"semdados": "Sem dados",
		"header": {
			"dataAquisicaoDe": "Mês/Ano"
		},
		"btn": {
			"pesquisar": "Pesquisar",
			"verificarenvios": "Verificar envios",
			"gerarficheiro": "Gerar ficheiros",
			"reenviarwebservice": "Reenviar por WebService"
		},
		"table": {
			"dados": "Dados",
			"infoficheiro": "Info ficheiro",
			"sel": "Sel.",
			"empresa": "Empresa",
			"nomeempresa": "Nome empresa",
			"contribemp": "Contrib. emp.",
			"info": "Info",
			"erros": "Erros",
			"infoerros": "Info erros",
			"estado": "Estado",
			"docpagamento": "Doc. pagamento",
			"comprovativo": "Comprovativo",
			"email": "E-mail"
		},
		"tab": {
			"dadosAutomaticos": "Dados automáticos",
			"dadosManuais": "Dados manuais",
			"dadosGlobais": "Dados globais",
			"controlo": "Controlo",
			"valorRendimentos": "Valor dos rendimentos",
			"retencaoIRS": "Retenção IRS",
			"contribObrigatorias": "Contribuições obrigatórias",
			"quotizacaoSindicais": "Quotizações sindicais",
			"retencaoSobretaxa": "Retenção sobretaxa",
			"rendiAnosAnt": "Rendimentos anos anteriores"
		},
		"modal": {
			"rhgestaoficheirosdmrattitle": "Informação dados remuneração",
			"table": {
				"ok": "Ok",
				"nif": "Nº contribuinte",
				"codemp": "Nº funcionário",
				"nome": "Nome",
				"abonosRendimentosSujeitos": "Rendimentos sujeitos",
				"abonosRendimentosNaoSujeitos": "Rendimentos não sujeitos",
				"abonosOutros": "Outros abonos",
				"descontosContribuicoesObrigatorias": "Contribuições obrigatórias",
				"descontosIRS": "Retenção IRS",
				"descontosSindicatos": "Sindicatos",
				"descontosSobretaxa": "Retenção sobretaxa",
				"descontosOutros": "Outros descontos",
				"rendimentosAnoAnterior": "Ano anterior rendimentos",
				"liquidoCalculado": "Líquido calculado",
				"liquidoVencimentos": "Líquido vencimentos",
				"erroDescricao": "Erro/Aviso descrição",
				"origemRendiDescr": "Origem",
				"tipRendiment": "Tipo rend.",
				"valorInciden": "Valor rendimentos",
				"descrInsManual": "Tipo de inserção",
				"quotizSindical": "Quotizações sindicais",
				"irsRetido": "Retenção IRS",
				"descobrigSSocial": "Contribuições obrigatórias",
				"sobretaxaExtraord": "Retenção sobretaxa",
				"rendimentosAnoAnteriorTable": "Valor rendimentos (Anos anteriores)",
				"rendianosanteriores": "Rendimentos anos anteriores",
				"valores": "Valores",
				"rendimentosano": "Rendimentos do ano",
				"tipoRendi": "Tipo rendimento",
				"localorigemrendi": "Local origem rendimento",
				"nipc1": "NIPC entidade 1",
				"nipc2": "NIPC entidade 2",
				"nipc3": "NIPC entidade 3",
				"enviaparaDMR": "Envia para DMR",
				"valorNaoSujeito": "Rendimentos não sujeitos",
				"outrosDescontos": "Outros descontos",
				"outrosAbonos": "Outros abonos",
				"ncodAbdes": "Abono/Desconto",
				"descricaoAbono": "Descrição abono/desconto"
			}
		},
		"email": {
			"enviado": "E-mail enviado com sucesso!",
			"naoenviado": "E-mail não foi enviado."
		},
		"messages": {
			"envioscomsucesso": "Envios verificados com sucesso!"
		},
		"tooltip": {
			"naotemlinhasselecionadas": "Não tem linhas selecionadas."
		},
		"prompt": {
			"geraFich": "Vai gerar o ficheiro para a declaração mensal de remunerações - AT. Quer continuar?",
			"errogerarficheiro": "Ficheiro não foi gerado com sucesso!",
			"ficheirosenviadosconfirmcolum": "Ficheiros enviados. Por favor verifique a coluna do estado do ficheiro."
		}
	},
	"gozoferias": {
		"usaMarcacaoGozoFerias": "Usa marcação gozo férias",
		"motivoRegistoFerias": "Motivo alteração",
		"nCodAbdesDescontoGozoFeriasAnoAtual": "Desconto ano atual",
		"nCodAbdesDescontoGozoFeriasAnoAnterior": "Desconto ano anterior"
	},
	"rhimpvenct": {
		"fields": {
			"doCodNum": "Cód. empregado de",
			"deZona": "Zona de",
			"deDepto": "Departamento de",
			"deSubDepto": "Sub-departamento",
			"deCCusto": "Centro de custo de",
			"deAbono": "Abono de",
			"deDesconto": "Desconto de",
			"deSeccao": "Secção de",
			"mostraAbonosVirtuais": "Mostra abonos virtuais",
			"dataProcesDE": "Data de processamento de"
		},
		"infos": {
			"tooltipExcecpoesAbono": "Exceções abonos",
			"tooltipExcecpoesDesconto": "Exceções descontos",
			"tooltipExcepcoesProcessamento": "Exceções processamento"
		},
		"btn": {
			"previsualizar": "Pré-visualizar"
		},
		"messages": {
			"exclprocessamentoemp": "Só pode ter a empresa atual selecionada!"
		}
	},
	"rhmanutencao": {
		"tabs": {
			"vencimentos": "Vencimentos"
		},
		"message": {
			"savedsuccessfully": "Dados guardados com sucesso!"
		},
		"fields": {
			"situacaoCessacao": "Situação da cessação",
			"motivoCessacao": "Motivo do registo",
			"nCodAbonoIndemnizacaoCaducidade": "Indemnização caducidade não sujeita a impostos",
			"nCodAbonoIndemnizacaoDespedimento": "Indemnização despedimento",
			"nCodAbonoIndemnizacaoGlobal": "Indemnização global",
			"nCodAbonoSubFeriasProp": "Subsídio de férias proporcional",
			"nCodAbonoFeriasProp": "Férias proporcionais"
		},
		"titles": {
			"abcessaconttrab": "Definições de abonos/descontos para cessação de contrato de trabalho",
			"abpropanoatual": "Definições de abonos/descontos proporcionais no ano atual"
		},
		"steps": {
			"cessacaocontrato": "Cessação de contrato"
		},
		"segsocialfolhaferias": {
			"title": "Folha de férias (Seguradora)",
			"cardseguradora": {
				"usaTipodeRemunEspecificaFichSeguradora": "Usa tipo de remuneração especifica para ficheiro seguradora (\"A\", \"B\", \"C\", \"F\", \"M\", \"N\", \"O\", \"P\", \"R\", \"S\", \"T\", \"X\", \"SR\")",
				"fichSeguradoraEnvioFichPelaExclAbDescCampo": "Envio ficheiro seguradora - Envia abonos/descontos pela confirmação do campo \"Mapa Seguro\""
			}
		},
		"rmmg": {
			"title": "Retribuição Mínima Mensal Garantida (RMMG)",
			"info": "A atualização do RMMG é efetuada de forma automática pelo CentralGest",
			"saved": "Configuração da Retribuição Mínima Mensal Garantida (RMMG) atualizada com sucesso",
			"tooltip": {
				"needSave": "Deve gravar as configurações para efetuar o processamento",
				"needCheck": "Deve selecionar o campo gerente para efetuar o processamento",
				"needSaveAndCheck": "Deve selecionar o campo gerente e gravar para poder efetuar o processamento"
			},
			"fields": {
				"dataAtualizacao": "Data atualização",
				"gerente": "Gerente",
				"funcionario": "Funcionário"
			}
		}
	},
	"rhmanutencaocampos": {
		"pesquisar": "Pesquisar",
		"camposModificar": "Campos a modificar",
		"processamentos": "Lista de processamentos",
		"valorCampoNaoPodeSerNulo": "O Valor do campo {{campo}} não pode ser nulo ou vazio!",
		"nenhumCampoSelecionadoParaAtualizar": "Nenhum campo selecionado para atualizar",
		"nenhumProceSelecionadoParaAtualizar": "Nenhum processamento selecionado para atualizar",
		"naoFoiEncontradoValorNoModel": "Não foi encontrado o valor no modelo: {{campo}}",
		"atualizarLista": "Atualizar lista",
		"promptMessage": "Tem a certeza que pretende efetuar a alteração dos campos nos processamentos selecionados?",
		"dataProcFinalNaoPodeInferiorInicial": "A data de processamento final não pode ser inferior à data inicial.",
		"infoHistoricoEmpregado": "Informação histórico empregado",
		"dadosProcessamento": "Dados do processamento",
		"processamento": "Processamento",
		"fields": {
			"dateProc": "Data processamento de",
			"codEmp": "Cód. Emp.",
			"nome": "Nome",
			"nProcessamento": "Nº proc.",
			"dataProcessamento": "Data proc.",
			"funcao": "Função",
			"nZona": "Cód. zona",
			"seccao": "Secção",
			"ccusto": "Centro custo",
			"nPOCRemuLiq": "Conta POC do liq. rec.",
			"departamento": "Departamento",
			"classeTrab": "Classe de trabalho",
			"nSubDe": "SubDepartamento",
			"descricaoErro": "Relatório"
		},
		"wizard": {
			"stepOne": {
				"wizardCaption": "Bem-vindo",
				"text1": "Este assistente vai permitir corrigir campos que foram incorretamente registados no processamento de salários.",
				"text2": "Só vai efetuar estas correções em processamentos que ainda não foram integrados na contabilidade.",
				"text3": "Clique em \"Seguinte\" para continuar."
			},
			"stepTwo": {
				"wizardCaption": "Atualização campos",
				"text1": "Efetue a pesquisa dos processamentos (não integrados) efetuados no intervalo de datas.",
				"text2": "A modificação dos registos pode ser feita campo a campo, ou selecionar vários e mudar todos de uma única vez."
			},
			"stepThree": {
				"wizardCaption": "Relatório"
			}
		}
	},
	"rhpenhoras": {
		"title_detail": "Penhora",
		"title_new": "Nova Penhora",
		"title_plural": "Penhoras",
		"pesquisa": "Pesquisar",
		"saved": "Penhora, guardada com sucesso.",
		"error": "Penhora, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Penhora, eliminada com sucesso.",
		"apagarregisto": "Apagar registo",
		"card": {
			"catabo": "Selecione os abonos para o cálculo do valor líquido do vencimento para afetação da penhora.",
			"calcLiqTodasCateg": "Todas as categorias de abonos"
		},
		"toolbar": {
			"tipoEscolha": "Visualizar penhoras: {{nameTipoEscolha}}"
		},
		"fields": {
			"ncodABDESC": "Desconto",
			"codEMP": "Empregado",
			"tipoValor": "Tipo valor",
			"valorTotalPenhora": "Valor total penhora",
			"dataInicio": "Data início",
			"tipoProcessamento": "Processamento",
			"todosProcessamentos": "Todos os processamentos",
			"penhoraPorInsolvenci": "Penhora por insolvência",
			"suspende": "Suspensa",
			"dataSuspensao": "Data suspensão",
			"obsSupencao": "Descrição da suspensão",
			"totalValorPenhoraPaga": "Valor penhora paga",
			"tipoValorPenhora": "Tipo valor penhora",
			"valor": "Valor do tipo de penhora",
			"temValorLimiteMinimoImpenhoravel": "Tem valor limite mínimo impenhorável",
			"valorLimImpenhoravel": "Valor limite impenhorável",
			"valorExternoPenhora": "Valor líquido externo: (Acresce ao valor líquido calculado)",
			"obsPenhora": "Observações",
			"referenciaPagamento": "Descrição",
			"identificacaoPenhora": "Identificação da penhora",
			"iban": "IBAN",
			"mbEntidade": "Entidade",
			"mbReferencia": "Referência",
			"formaPagamento": "Forma de pagamento"
		},
		"enumStr": {
			"formaPagamento": {
				"none": "Não especificada",
				"mbref": "Entidade/Referência",
				"iban": "IBAN",
				"mbrefestado": "Ref. pagamento ao estado"
			}
		},
		"maintenance": {
			"descontos": "Configuração de descontos"
		}
	},
	"rhprocessamentosalariosconfig": {
		"tab": {
			"procesalarios": "Processamento de salários",
			"duodecimos": "Duodécimos",
			"recibovenc": "Recibo vencimento"
		},
		"procesalarios": {
			"usaFiltroFuncaoNosTiposProcessamento": "Usa filtro função no processamento de salários",
			"calcNDiasParaAdmissaoEmpEmDiasBase30": "Calcula valor processamento salários para admissão do funcionário em base 30 dias"
		},
		"duodecimos": {
			"acertoDuodecimosFaltouMesInteiro": "Retira duodécimos se funcionário faltou o mês inteiro",
			"efetuaAcertoDuodecimos": "Efetua acerto duodécimos no ultimo pagamento no mês de Dezembro",
			"percentagemEmSubFerias": "Percentagem de sub. férias a aplicar em duodécimos [0, 50, 100]",
			"percentagemEmSubNatal": "Percentagem de sub. natal a aplicar em duodécimos [0, 50, 100]"
		},
		"recibovenc": {
			"reciboVencimentosMostraGraficoCustos": "Mostra distribuição de custos totais do colaborador para a empresa no recibo de vencimento",
			"reciboVencimentosMostraGraficoCustosAcumuladosAnuais": "Mostra acumulados anuais nos detalhes dos custos totais"
		},
		"card": {
			"percduodecimos": "Percentagem duodécimos sobre subsídio de férias e natal"
		},
		"errors": {
			"invalidpercentage": "Percentagem inválida, tem de ser 0, 50, 100!",
			"verificarFerias": "Verifique se a percentagem de duodécimos introduzida no sub. férias está correta!",
			"verificarNatal": "Verifique se a percentagem de duodécimos introduzida no sub. natal está correta!"
		}
	},
	"rhprocsegsocialconsultenvios": {
		"semdados": "Sem dados",
		"comprovativo": "Comprovativo",
		"header": {
			"dataAquisicaoDe": "Mês/Ano"
		},
		"btn": {
			"pesquisar": "Pesquisar",
			"gerarficheiro": "Gerar ficheiros",
			"reenviarwebservice": "Reenviar por WebService",
			"reprocessarSegSocial": "Reprocessar seg. social",
			"configWsSS": "Configuração do WebService da Segurança social",
			"impresumo": "Imprimir resumo",
			"gerarEstorno": "Gerar estorno",
			"apagarEstorno": "Apagar estorno"
		},
		"table": {
			"dados": "Dados",
			"dadosEmpresa": "Dados empresa",
			"infoficheiro": "Info ficheiro",
			"infowebservice": "Info Web Service",
			"sel": "Sel.",
			"empresa": "Empresa",
			"nomeempresa": "Nome empresa",
			"totalRemuneracoes": "Total - Remuneração seg. social",
			"totalContribuicoes": "Total - Contribuição seg. social",
			"totalVencRemun": "Total - Remuneração proc. salários",
			"procficheiro": "Proc. do ficheiro",
			"info": "Info",
			"erros": "Erros",
			"reprocessado": "Reprocessado",
			"gerado": "Gerado",
			"infoerros": "Info erros",
			"estado": "Estado",
			"alertaserros": "Alerta/Erros",
			"dataentrega": "Data entrega",
			"datalimitesub": "Data limite substituir",
			"nome": "Nome",
			"comprovativo": "Comprovativo",
			"modal": {
				"title": "Mostra informação do ficheiro folha de férias",
				"identificacao": "Identificação",
				"codp": "Cód. P",
				"codr": "Cód. R",
				"codsr": "Cód. SR",
				"codf": "Cód. F",
				"codn": "Cód. N",
				"coda": "Cód. A",
				"codb": "Cód. B",
				"codc": "Cód. C",
				"codd": "Cód. D",
				"codh": "Cód. H",
				"codm": "Cód. M",
				"codo": "Cód. O",
				"cods": "Cód. S",
				"codt": "Cód. T",
				"codx": "Cód. X",
				"cod2": "Cód. 2",
				"cod6": "Cód. 6",
				"codi": "Cód. I",
				"nss": "Nº SS",
				"nfuncionario": "Nº funcionário",
				"nome": "Nome",
				"ndias": "Nº dias",
				"valor": "Valor"
			},
			"config": "Config."
		},
		"messages": {
			"infosuccess": "Dados gravados com sucesso!",
			"infoerror": "Ocorreu um erro ao gravar os dados, por favor confirme os dados inseridos.",
			"empresasSelecionasHouveEmpresnaoGeradas": "Das empresas selecionas houve empresas que não foram geradas. Verifique os erros na grelha.",
			"reprocessamentoSuccess": "Reprocessamento da segurança social efetuada Com Sucesso.",
			"empresasSelecionasHouveEmpres": "Das empresas selecionas houve empresas com erros. Verifique os erros na grelha.",
			"enviowebserviceSuccess": "Envio efetuado para todas as empresas. Atenção é necessário consultar os ficheiros.",
			"enviadosSucess": "Os ficheiros foram enviados com sucesso",
			"semRegistosSelecionadas": "Tem que selecionar pelo menos um registo.",
			"verificadosuccess": "Envios verificados com sucesso",
			"soPodeSelecionarUmRegisto": "Só pode selecionar um registo."
		},
		"prompt": {
			"geraFich": "Vai gerar o ficheiro para a declaração de remunerações à segurança social. Quer continuar?",
			"errogerarficheiro": "Ficheiro não foi gerado com sucesso!",
			"ficheirosenviadosconfirmcolum": "Ficheiros enviados. Por favor verifique a coluna do estado do ficheiro.",
			"semAlteracaoParaGerar": "O sistema não gerou ficheiro, porque já foi gerado anteriormente."
		},
		"tooltip": {
			"naotemlinhasselecionadas": "Não tem linhas selecionadas.",
			"configWsSS": "Configuração do WebService da segurança social",
			"gerarEstorno": "Gerar estorno",
			"apagarEstorno": "Apagar estorno"
		},
		"verificarEnvios": "Verificar envios",
		"modal": {
			"title": "Impressão de mapas resumo do ficheiro da segurança social"
		}
	},
	"rhqpirct": {
		"card": {
			"irct": "IRCT",
			"irctcateg": "IRCT \\ Categoria",
			"codirct": "Cód. IRCT",
			"nomeirct": "Nome IRCT",
			"codcategoria": "Cód. categoria",
			"nomecategoria": "Nome categoria"
		},
		"btn": {
			"addirct": "Adicionar IRCT",
			"addcateg": "Adicionar categoria",
			"infooficialirct": "Obter informação oficial sobre os códigos a atribuir ao IRCT e categorias",
			"importlistacateg": "Importação da listagem de categorias profissionais (Ficheiro CSV)"
		},
		"messages": {
			"filesuccess": "Ficheiro importado com sucesso!",
			"importedsucess": "Importados com sucesso!",
			"irctexists": "O IRCT que pretende importar já existe! <br> Pretende continuar com a importação?"
		},
		"modal": {
			"title": "Importação de categorias profissionais",
			"table": {
				"codIRCT": "Código",
				"nome": "Nome"
			}
		}
	},
	"rhrecolhadados": {
		"apagarregisto": "Apagar registo",
		"radio": {
			"dadosmanual": "Recolha dados manual",
			"dadosfixos": "Recolha dados fixos",
			"byabondesc": "Recolha de dados por abono/desconto",
			"byemp": "Recolha de dados por empregado"
		},
		"error": {
			"invalidStep": "Tem campos que não estão preenchidos ou que contém erro!",
			"invalidStepTodosProcessamentos": "Tem que selecionar um tipo de processamento valido!"
		},
		"fields": {
			"todosProcessamentos": "Todos os processamentos",
			"nomeEmp": "Empregado",
			"tipoProcessamento": "Tipo processamento",
			"abonoDesc": "Abono/Desconto",
			"codABDESC": "Cód. abono/desconto",
			"codEMP": "Cód. empregado",
			"dataDados": "Data evento",
			"dataDadosFixos": "Data processamento",
			"dataProcess": "Data processamento",
			"dataParaProcess": "Data para processamento",
			"quantidade": "Quantidade",
			"valorUNIT": "Valor unitário",
			"valor": "Valor",
			"vALINCIDESC": "Incidência",
			"descricao": "Obs.",
			"cambioMEParaMC": "Câmbio",
			"valorME": "Valor ME",
			"codMOEDA": "Moeda",
			"tipoALUNIT": "Unidade",
			"ccusto": "C. Custo",
			"nsubde": "Sub-Depart.",
			"departamento": "Departamento",
			"nzona": "Zona",
			"contador": "Nº registo no C. Custo",
			"htrabDia": "Trabalho por dia",
			"htrabSemana": "Trabalho por semana",
			"htrabMes": "Trabalho por mês",
			"nomeAbonoDesconto": "Cód. abono/desconto",
			"nomeTipoProcessamento": "Tipo processamento"
		},
		"tabs": {
			"infoemp": "Informação empregado",
			"outrosdados": "Outros dados",
			"title": {
				"horasempregado": "Horas empregado",
				"ndiasempregado": "Nº dias empregado",
				"valoresuniemp": "Valores unitários empregado"
			}
		},
		"table": {
			"sel": "Sel",
			"tipo": "Tipo",
			"valor": "Valor",
			"valorme": "Valor ME",
			"horaextra": "% Hora extra",
			"pagaseguro": "% Paga seguro"
		},
		"modal": {
			"continuar": "Tem a certeza que quer alterar o registo gravado?",
			"avisodesc": "O abono/desconto '{{abonodesc}}' que está a introduzir tem perda de subsídio de refeição. <br> Quer introduzir os dias a retirar para subsídio de refeição?",
			"labelnhoras": "Nº faltas marcadas para o desconto {{abonodesc}}",
			"labelndias": "Nº dias para subsídio de refeição",
			"nhoras": "Nº horas",
			"ndias": "Nº dias",
			"title": "Subsídio de refeição"
		},
		"toolbar": {
			"month": "Mês: {{nameMonth}}",
			"stateemp": "Funcionários: {{nameState}}"
		},
		"steps": {
			"tiporecolha": "Tipo de recolha",
			"dadosrecolha": "Dados de recolha"
		}
	},
	"remfx": {
		"title_detail": "Recolha Dados Fixos",
		"title_new": "Nova Recolha Dados Fixos",
		"title_plural": "Recolhas Dados Fixos",
		"pesquisa": "Pesquisar",
		"saved": "Recolha Dados Fixos, guardada com sucesso.",
		"error": "Recolha Dados Fixos, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Recolha Dados Fixos, eliminada com sucesso."
	},
	"remun": {
		"title_detail": "Recolha Dados",
		"title_new": "Nova Recolha Dados",
		"title_plural": "Recolhas Dados",
		"pesquisa": "Pesquisar",
		"saved": "Recolha Dados, guardada com sucesso.",
		"error": "Recolha Dados, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Recolha Dados, eliminada com sucesso."
	},
	"rhrecolhaseriesubsidios": {
		"empTable": {
			"codEmp": "Cód. empregado",
			"nomeEmp": "Nome",
			"nBI": "Nº BI",
			"contribuinte": "Nº contribuinte",
			"dataNascimento": "Data de nascimento"
		},
		"steps": {
			"pesquisa": "Pesquisa",
			"processar": "Processo"
		},
		"titles": {
			"pesquisa": "Pesquisa",
			"processar": "Selecione o dia de pagamento"
		},
		"fields": {
			"codEmpDe": "Cód. emp. de",
			"codEmpAte": "Cód. emp. até",
			"situacaoEmprego": "Situação de emprego",
			"tipoProcessamento": "Tipo processamento",
			"dataReferencia": "Data referência",
			"dataParaPagarSubsidio": "Data para cálculo do valor a pagar de subsídio",
			"nDiasSubsidioAlimentacao": "Nº dias defeito",
			"radio": {
				"inativo": "Inativo"
			}
		},
		"messages": {
			"selEmp": "Tem de selecionar pelo menos um empregado!",
			"tipoProcEmpty": "Tem de inserir um tipo de processamento!",
			"semdadossave": "Sem dados para gravar.",
			"dadossaved": "Dados gravados com sucesso."
		},
		"modal": {
			"subssavedtitlenatal": "Pagamento em série de subsídio natal",
			"subssavedtitleferias": "Pagamento em série de subsídio férias",
			"subssavedtitlealimentacao": "Pré processamento em série de subsídio de alimentação",
			"semdados": "Sem dados",
			"btn": {
				"options": {
					"showCodEmpregado": "Mostra cód. emp.",
					"showNomeEmpregado": "Mostra nome",
					"showDataAdmissao": "Mostra data admissão",
					"showNDiasAPagar": "Mostra nº dias a pagar",
					"showValorAPagar": "Mostra valor a pagar",
					"showNMAxDiasUteisSubsidio": "Mostra nº máx. dias subsídio",
					"showNDiasPagosAnoAnterior": "Mostra nº dias pagos ano anterior",
					"showValorPagoAnoAnterior": "Mostra valor pago ano anterior",
					"showNDiasPorPagarAnoAnterior": "Mostra nº dias por pagar ano anterior",
					"showNDiasTrabalhados": "Mostra nº dias trabalhados",
					"showNDiasDescontarPorFaltasEmpregado": "Mostra nº dias descontar por faltas",
					"showNDiasPagos": "Mostra nº dias já pagos",
					"showInfo": "Mostra info"
				}
			},
			"fields": {
				"codEmpregado": "Cód. emp.",
				"nomeEmpregado": "Nome",
				"dataAdmissao": "Data admissão",
				"nMAxDiasUteisSubsidio": "Nº máx. dias subsídio",
				"nDiasPagosAnoAnterior": "Nº dias pagos ano anterior",
				"valorPagoAnoAnterior": "Valor pago ano anterior",
				"nDiasPorPagarAnoAnterior": "Nº dias por pagar ano anterior",
				"nDiasTrabalhados": "Nº dias trabalhados",
				"nDiasDescontarPorFaltasEmpregado": "Nº dias descontar por faltas",
				"nDiasPagos": "Nº dias já pagos",
				"nDiasAPagar": "Nº dias a pagar",
				"valorAPagar": "Valor a pagar",
				"info": "Info",
				"valorEmMoedaEstrangeira": "Valor moeda estrangeira",
				"valorCambio": "Valor câmbio",
				"moedaCambio": "Moeda câmbio"
			}
		}
	},
	"rhregistarempregadosss": {
		"consultarMainTabTitle": "Consultar empregados",
		"registarMainTabTitle": "Registar empregados",
		"verificarEmpregadosTabTitle": "Verificar empregados",
		"verificarRegistarContratosTabTitle": "Verificar/Registar contratos antigos",
		"registarEmpregadosRegistarTabTitle": "Registar empregado",
		"registarEmpregadosCessarTabTitle": "Cessar empregado",
		"registarEmpregadosPerioRendTabTitle": "Período de rendimentos",
		"promptRegistarEmpregado": "Tem a certeza que pretende registar o empregado?",
		"promptCessarEmpregado": "Tem a certeza que pretende cessar o empregado?",
		"promptRegPeriodoRendimento": "Tem a certeza que pretende registar o período rendimento?",
		"editarContratoModalTitle": "Editar contrato",
		"configWS": {
			"modalTitle": "Configuração para registo de trabalhadores na seg. social",
			"instruction1Title": "Acesso à segurança social direta",
			"instruction1Text": "Para aderir à plataforma de serviços de interoperabilidade é necessário que aceda à segurança social direta (SSD), através do NISS e da palavra-chave que tem para esse efeito.",
			"instruction2Title": "Aderir à plataforma de Serviços de interoperabilidade",
			"instruction2Text": "Após acesso à SSD, encontra as condições de adesão à plataforma de serviços de interoperabilidade na opção do menu perfil. As condições de adesão à plataforma de serviços de interoperabilidade têm obrigatoriamente que ser aceites para acesso à utilização dos serviços web.",
			"saveButton": "Gravar",
			"seguinte": "Seguinte",
			"voltar": "Voltar",
			"utilizadorNiss": "Utilizador (NISS)",
			"senha": "Senha",
			"warnignMsg": "O utilizador e a senha são os da empresa, e não os acessos como mandatário"
		},
		"strings": {
			"empregado": "Empregado",
			"consultarEmpregados": "Consultar empregados",
			"verificarContratos": "Verificar contratos",
			"dados": "Dados",
			"taxas": "Taxas",
			"vinculo": "Vínculo",
			"localTrabalho": "Local trabalho",
			"codemp": "Cód. emp.",
			"niss": "Nº seg. social",
			"nome": "Nome",
			"datanascimento": "Data nascimento",
			"vinculocomunicadoem": "Vínculo comunicado em",
			"iniciodoVinculo": "Inicio do vínculo",
			"fimdoVinculo": "Fim do vínculo",
			"inicioaplicacaoTaxa": "Inicio aplicação taxa",
			"fimaplicacaotaxa": "Fim aplicação taxa",
			"valortaxa": "Valor taxa",
			"contratos": "Contratos",
			"isContratoEnviadoParaSS": "Tem novo contrato na SS",
			"modalidade": "Modalidade",
			"temErros": "Tem Erros",
			"prestacaoTrabalho": "Prestação trabalho",
			"inicioContrato": "Inicio contrato",
			"fimContrato": "Fim contrato",
			"inicioInformacao": "Inicio informação",
			"fimInformacao": "Fim informação",
			"profissao": "Profissão",
			"remuneracaoBase": "Remuneração base",
			"diuturnidades": "Diuturnidades",
			"percentagemTrabalho": "Perc. trabalho",
			"horasTrabalho": "Horas trabalho",
			"diasTrabalho": "Dias trabalho",
			"motivoTrabalho": "Motivo trabalho",
			"nissTrabalhador": "NISS trabalhador",
			"nomeTrabalhador": "Nome trabalhador",
			"descErros": "Erros",
			"atualizarEstadoEmpregados": "Atualizar estado empregados",
			"registarContratos": "Registar contratos",
			"codempregado": "Cód. empregado",
			"dadosEmpregado": "Dados empregado",
			"dataAdmissao": "Data admissão",
			"numEstabelecimentoSS": "Nº estabelecimento seg. social",
			"tipoContratoTrabalho": "Tipo de contrato de trabalho",
			"assinadoEm": "Assinado em",
			"validaAte": "Válido até",
			"vencimentoBase": "Vencimento base",
			"naoVerificado": "Não verificado",
			"contratoSegSocial": "Contrato seg. social",
			"motivo": "Motivo",
			"tempoParcial": "Tempo parcial",
			"codEmpSubstituir": "Cód. emp. substituir",
			"dataFimVinculo": "Data fim vínculo",
			"comunicaDesemprego": "Comunicação para efeito de desemprego",
			"fundamentacao": "Fundamentação",
			"registarPerioRendSS": "Registar período rendimento seg. social",
			"dataNovoRegistoPeriodo": "Data novo período",
			"vencimentoRegistoPeriodo": "Novo vencimento base",
			"diuturnidadeRegistoPeriodo": "Novo valor diuturnidade",
			"registar": "Registar",
			"confiWsSaved": "A configuração foi guardada com sucesso",
			"empregadosAtualizadosComSucesso": "Empregados atualizados com sucesso",
			"editarContrato": "Editar contrato",
			"authenticationError": "Falha na autenticação. Por favor verifique as suas credenciais e tente novamente."
		},
		"enums": {
			"modalidade": {
				"A": "A - SEM TERMO, TEMPO COMPLETO",
				"B": "B - SEM TERMO, TEMPO PARCIAL",
				"C": "C - TRABALHO INTERMITENTE, TEMPO COMPLETO",
				"D": "D - TRABALHO INTERMITENTE, TEMPO PARCIAL",
				"E": "E - A TERMO CERTO, TEMPO COMPLETO",
				"EA": "EA - COM TERMO, CERTO, TEMPO COMPLETO, COMISSÃO DE SERVIÇO",
				"EB": "EB - COM TERMO, CERTO, TEMPO COMPLETO, TELETRABALHO",
				"F": "F - A TERMO CERTO, TEMPO PARCIAL",
				"FA": "FA - COM TERMO, CERTO, TEMPO PARCIAL, COMISSÃO DE SERVIÇO",
				"FB": "FB - COM TERMO, CERTO, TEMPO PARCIAL, TELETRABALHO",
				"G": "G - A TERMO INCERTO, TEMPO COMPLETO",
				"H": "H - A TERMO INCERTO, TEMPO PARCIAL",
				"I": "I - TRABALHO MUITO CURTA DURAÇÃO",
				"J": "J - TELETRABALHO, TEMPO COMPLETO",
				"K": "K - TELETRABALHO, TEMPO PARCIAL",
				"L": "L - COMISSÃO SERVIÇO, TEMPO COMPLETO",
				"M": "M - COMISSÃO SERVIÇO, TEMPO PARCIAL",
				"N": "N - A TERMO CERTO, TRAB. TEMPORÁRIO, TEMPO PARCIAL",
				"O": "O - A TERMO CERTO, TRAB. TEMPORÁRIO, TEMPO COMPLETO",
				"P": "P - A TERMO INCERTO, TRAB. TEMPORÁRIO, TEMPO PARCIAL",
				"Q": "Q - A TERMO INCERTO, TRAB. TEMPORÁRIO, TEMPO COMPLETO",
				"R": "R - POR TEMPO INDETERMINADO, TRAB. TEMPORÁRIO, TEMPO PARCIAL",
				"S": "S - POR TEMPO INDETERMINADO, TRAB. TEMPORÁRIO, TEMPO COMPLETO",
				"AA": "AA - Sem termo, tempo completo, comissão de serviço",
				"AB": "AB - Sem termo, tempo completo, teletrabalho",
				"BA": "BA - Sem termo, tempo parcial, comissão de serviço",
				"BB": "BB - Sem termo, tempo parcial, teletrabalho",
				"GA": "GA - A termo incerto, tempo completo, comissão de serviço",
				"GB": "GB - A termo incerto, tempo completo, teletrabalho",
				"HA": "HA - A termo incerto, tempo parcial, comissão de serviço",
				"HB": "HB - A termo incerto, tempo parcial teletrabalho"
			},
			"motivoContrato": {
				"AEAT": "AEAT - Acréscimo excecional de atividade",
				"ATSA": "ATSA - Atividade sazonal",
				"CTSD": "CTSD - Contratação trabalhador situação desemprego muito longa duração",
				"EOPA": "EOPA - Execução de obra, projeto ou atividade definida e temporária",
				"EXTO": "EXTO - Execução tarefa ocasional",
				"IFEE": "IFEE - Início de funcionamento de empresa/estabelecimento com menos de 250 trabalhadores, nos 2 anos posteriores",
				"LNAT": "LNAT - Lançamento nova atividade duração incerta em empresa/estabelecimento com menos de 250 trabalhadores, nos 2 anos posteriores",
				"STAJ": "STAJ - Substituição trabalhador com ação judicial despedimento",
				"STAT": "STAT - Substituição trabalhador ausente ou temporariamente impedido",
				"STLR": "STLR - Substituição trabalhador com licença sem retribuição",
				"STTC": "STTC - Substituição trabalhador tempo completo por prestar trabalho tempo parcial por período determinado"
			},
			"prestacaoTrabalhoContrato": {
				"P": "P - Presencial",
				"T": "T - Teletrabalho"
			},
			"motivoCessar": {
				"CCAI": "CCAI - Despedimento pelo administrador de insolvência",
				"CCCT": "CCCT - Caducidade do contrato a termo",
				"CCEE": "CCEE - Extinção de pessoa coletiva / encerramento da empresa / morte do empregador",
				"CCFM": "CCFM - Caducidade do contrato de militar",
				"CCMT": "CCMT - Caducidade do contrato por morte do trabalhador",
				"CCRI": "CCRI - Caducidade do contrato por reforma por invalidez",
				"CCRV": "CCRV - Caducidade do contrato por reforma por velhice",
				"CDT": "CDT  - Cedência definitiva de trabalhador (Cessão da posição contratual)",
				"IECC": "IECC - Cessação de comissão de serviço ou situação equiparada por iniciativa do empregador",
				"IEDC": "IEDC - Despedimento coletivo por iniciativa do empregador",
				"IEEX": "IEEX - Despedimento por extinção do posto de trabalho por iniciativa do empregador",
				"IEIN": "IEIN - Despedimento por inadaptação por iniciativa do empregador",
				"IEJC": "IEJC - Justa causa por iniciativa do empregador",
				"IEPE": "IEPE - Denúncia contrato no período experimental por iniciativa do empregador",
				"IIAT": "IIAT - Abandono do trabalho",
				"IIDD": "IIDD - Denúncia do contrato de trabalho/demissão por iniciativa do trabalhador",
				"IIDE": "IIDE - Denúncia contrato no período experimental por iniciativa do trabalhador",
				"IIJC": "IIJC - Justa causa por iniciativa do trabalhador",
				"IISA": "IISA - Justa causa por salários em atraso por iniciativa do trabalhador",
				"RADC": "RADC - Acordo de revogação nos termos do n.º 4 do art.º 10º",
				"RANE": "RANE - Acordo de revogação - sem redução do nível de emprego, para reforço da qualificação técnica da empresa",
				"RAOT": "RAOT - Acordo de revogação não previsto nos números anteriores",
				"RARC": "RARC - Acordo de revogação - empresa em processo de recuperação",
				"RARD": "RARD - Acordo de revogação - empresa em reestruturação por despacho",
				"RARE": "RARE - Acordo de revogação - empresa em situação económica difícil",
				"RARR": "RARR - Acordo de revogação - empresa em reestruturação pertencente a setor específico",
				"TE": "TE   - Transmissão de empresa"
			}
		},
		"messages": {
			"selectRecords": "Por favor selecione pelo menos um registo",
			"existemEmpregadosComErro": "Existem empregados com erro. Por favor verifique.",
			"empregadoRegistadoComSucesso": "Empregado registado com sucesso",
			"empregadoCessadoComSucesso": "Empregado cessado com sucesso",
			"erroInesperado": "Ocorreu um erro inesperado. Por favor, tente novamente.",
			"periodoRendimentoRegistadoComSucesso": "O período rendimento foi registado com sucesso"
		}
	},
	"rhrelatoriounico": {
		"title": "Relatório único",
		"titleExport": "Exportação do ficheiro do relatório único de {{year}}",
		"tabProcess": "Exportação",
		"tabDownload": "Ficheiro relatório único",
		"generation": "Geração do ficheiro",
		"fileNotProcessed": "Lamentamos, mas por alguma razão o ficheiro não foi gerado.",
		"jobTimeoutModalTitle": "Tarefa",
		"jobTimeoutModalMessage": "A tarefa excedeu o tempo limite. Tem que iniciar o processo novamente.",
		"processar": "Processar",
		"processingMessage": "A processar, por favor aguarde um momento.",
		"erros": "Ocorreram os seguintes erros a processar (o ficheiro pode mesmo assim estar disponível para efetuar download no separador ficheiro relatório único)",
		"fileReady": "O ficheiro do relatório único está pronto. Clique no botão para descarregar.",
		"promptReprocessTitle": "Já existe um ficheiro do relatório único gerado anteriormente",
		"promptReprocessMessage": "Se continuar, o sistema irá gerar o ficheiro novamente. Tem a certeza que pretende continuar?",
		"warningMessage": "Atenção: Após iniciar o processo, tem 60 minutos para descarregar o ficheiro gerado.",
		"promptCancelProcessMessage": "Tem a certeza que pretende cancelar o processo?",
		"dataCriacao": "Data de criação",
		"tamanho": "Tamanho",
		"utilizador": "Utilizador",
		"buttons": {
			"reiniciar": "Reiniciar",
			"download": "Descarregar",
			"yes": "Sim",
			"no": "Não",
			"fechar": "Fechar"
		},
		"erroDownload": "Ocorreu um erro ao efetuar o download do ficheiro!",
		"modal": {
			"config": {
				"title": "Relatório único - Configuração",
				"nestabelec": "Código",
				"nome": "Nome",
				"nestabQPessoal": "Id. estabelecimento",
				"savesuccess": "Configuração guardada com sucesso!",
				"idEntidadeEmpty": "É necessário indicar o ID da entidade.",
				"idEntidade": "Id. entidade"
			}
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmação",
		"leavePromptMessage": "Ao clicar em SIM irá sair e descartar todos os dados do processamento. Tem a certeza que pretende sair?"
	},
	"rhsaltotrf": {
		"fields": {
			"codEmpDe": "Empregado de",
			"dataProcesDE": "Data processamento de",
			"bancoDe": "Banco de",
			"fRemuDe": "Forma de remun. de"
		},
		"tabs": {
			"0": "Vencimentos",
			"1": "Cartão/Ticket refeição",
			"vencimentos": "Vencimentos",
			"cartaoTicketRefeicao": "Cartão/Ticket refeição"
		},
		"table": {
			"sel": "Sel.",
			"estado": "Estado",
			"emp": "Empregado",
			"nome": "Nome",
			"valor": "Valor",
			"datalanc": "Data lançamento",
			"iban": "IBAN",
			"swift": "SWIFT",
			"semdados": "Sem dados"
		},
		"messages": {
			"pesqsuccess": "Pesquisa efetuada com sucesso",
			"pesqsemdados": "Não existem vencimentos para o filtro selecionado",
			"tooltipExcepcoesProcessamento": "Exceções processamento",
			"linesnotselected": "Não existem linhas selecionadas para processar",
			"corrigecartao": "Corrigir cartão",
			"removetrans": "Remove transferência",
			"temcertezaremtransf": "Tem a certeza que quer remover a linha de transferência?",
			"ncontaRequired": "Tem de selecionar um nº conta D/O",
			"listatransfsuccess": "Lista de transferência gravada com sucesso"
		},
		"modal": {
			"title": "Gerar dados de transferências",
			"fields": {
				"dataTransf": "Data transferência",
				"nContaPoc": "Nº conta D/O"
			}
		},
		"config": {
			"title": "Configuração de bancos, agências e contas"
		},
		"imprimir": {
			"title": "Pagamento por banco",
			"titleWithTab": "Pagamento por banco - {{activeTab}}"
		}
	},
	"rhtransferenciasbancarias": {
		"tab": {
			"listabancos": "Bancos com tickets refeição",
			"exclusaoabonos": "Exclusão abonos/descontos",
			"transfBancarias": {
				"title": "Transferências bancárias",
				"formatoPS2": {
					"title": "Formato ficheiro PS2",
					"ficheiroFormatoPS2": "Ficheiro transferências formato PS2"
				},
				"cgd": {
					"title": "Banco Caixa Geral de Depósitos",
					"separacaoNibs": "CGD exportação TXT - Efetua separação de NIBs"
				},
				"dgt": {
					"title": "Direção Geral do Tesouro",
					"dgtFormatoSEPA": "Ficheiro de transferência da DGT no formato SEPA"
				}
			},
			"codigo": "Código"
		},
		"options": {
			"ticketFormatSEPA": "Ficheiro transf. do ticket refeição no formato SEPA"
		},
		"fields": {
			"codBanco": "Cód. banco",
			"codOpTrfBa": "Cód. op. tansf. banc.",
			"nClienteBanco": "Nº cliente no banco",
			"nCartaoTrfBaIBAN": "Nº cartão transf. banc. é o IBAN",
			"cartaoDifBanco": "Cartão ref. dif. pagamento",
			"configTrfBaTicket": "Transf. banc. ticket",
			"tipoFichTrf": "Tipo ficheiro transf.",
			"tipo": "Tipo",
			"abonodesconto": "Abono/Desconto",
			"designacao": "Designação"
		},
		"modal": {
			"configransfticket": "Configuração de transferência bancária ticket",
			"codBanco": "Banco",
			"codOpTrfBa": "Código operação transferência bancária(BCO = '48')",
			"nClienteBanco": "Nº cliente no banco",
			"nCartaoTrfBaIBAN": "Nº cartão transferência bancária é o IBAN",
			"cartaoDifBanco": "O banco do cartão refeição é diferente do banco de pagamento",
			"configTrfBaTicket": "Tem configurada transferência bancária ticket",
			"tipoFichTrf": "Tipo ficheiro transferência ('0 - PS2')",
			"bancoValidator": "Introduza um banco válido"
		},
		"message": {
			"addsuccess": "Banco adicionado com sucesso!",
			"editsuccess": "Banco atualizado com sucesso!",
			"abdescExclusionRepeated": "Exclusão já inserida!",
			"abdescempty": "Tem de selecionar um abono/desconto!",
			"savedSuccess": "Configurações guardadas com sucesso!"
		}
	},
	"rhunidades": {
		"title_detail": "Unidade de Recursos Humanos {{id}}",
		"title_new": "Nova Unidade de Recursos Humanos",
		"title_plural": "Unidades de Recursos Humanos",
		"pesquisa": "Pesquisar",
		"saved": "Unidade de Recursos Humanos {{id}}, guardada com sucesso.",
		"error": "Unidade de Recursos Humanos {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Unidade de Recursos Humanos {{id}}, apagada com sucesso.",
		"fields": {
			"codUnidade": "Código",
			"nome": "Nome",
			"nomeAbreviado": "Nome abreviado",
			"tipoUnidade": "Tipo unidade"
		}
	},
	"roles": {
		"title_detail": "Role {{id}}",
		"title_plural": "Roles",
		"pesquisa": "Pesquisar",
		"fields": {
			"role": "Nome",
			"description": "Descrição"
		}
	},
	"rptconfigs": {
		"fields": {
			"modulo": "Módulo",
			"nome": "Nome da fórmula",
			"descricao": "Descrição",
			"valor": "Valor"
		},
		"modulos": "Módulos",
		"variaveisRelatorio": "Variáveis de relatório",
		"saveSuccess": "Configurações gravadas com sucesso."
	},
	"saft": {
		"tabProcess": "Exportação",
		"tabDownload": "Ficheiro SAF-T",
		"generation": "Geração do ficheiro",
		"fileNotProcessed": "Lamentamos, mas por alguma razão o ficheiro não foi gerado.",
		"jobTimeoutModalTitle": "Tarefa",
		"jobTimeoutModalMessage": "A tarefa excedeu o tempo limite. Tem que iniciar o processo novamente.",
		"contabilidade": "Contabilidade",
		"faturacao": "Faturação",
		"processar": "Processar",
		"processingMessage": "A processar, por favor aguarde um momento.",
		"erros": "Erros",
		"fileReady": "O ficheiro SAF-T está pronto. Clique no botão para descarregar.",
		"promptReprocessTitle": "Já existe ficheiro SAF-T gerado anteriormente",
		"promptReprocessMessage": "Se continuar, o sistema irá gerar o ficheiro novamente. Tem a certeza que pretende continuar?",
		"warningMessage": "Atenção: Após iniciar o processo, tem 60 minutos para descarregar o ficheiro gerado.",
		"promptCancelProcessMessage": "Tem a certeza que pretende cancelar o processo?",
		"dataCriacao": "Data de criação",
		"tamanho": "Tamanho",
		"utilizador": "Utilizador",
		"documentos": "Documentos",
		"buttons": {
			"reiniciar": "Reiniciar",
			"download": "Descarregar",
			"yes": "Sim",
			"no": "Não",
			"fechar": "Fechar"
		},
		"erroDownload": "Ocorreu um erro ao efetuar o download do ficheiro!",
		"recibos": "Recibos",
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmação",
		"leavePromptMessage": "Ao clicar em SIM irá sair e descartar todos os dados do processamento. Tem a certeza que pretende sair?",
		"contabilidadeFaturacaoRecibos": "Contabilidade, Faturação e Recibos",
		"autofaturacao": "Autofaturação",
		"nConta": "Fornecedor",
		"title": {
			"normal": "Exportar ficheiro SAF-T",
			"autofaturacao": "Exportar ficheiro SAF-T Autofaturação"
		}
	},
	"saftcontab": {
		"steps": {
			"welcome": {
				"step": "Bem-vindo",
				"title": "Bem-vindo ao assistente de importação SAF-T PT para contabilidade.",
				"desc2": "Clique em \"Seguinte\" para continuar a importação.",
				"desc": "Este importador destina-se a importar os movimentos contabilísticos através de um ficheiro SAF-T PT para a contabilidade."
			},
			"importtype": {
				"step": "Tipo importação",
				"importtypes": "Tipos de importações disponíveis",
				"fields": {
					"importextratocomsi": "Importar extrato (Com importação de saldos iniciais)",
					"importextratosemsi": "Importar extrato (Sem Importação de saldos iniciais)",
					"importsaldosiniciais": "Importar apenas saldos iniciais",
					"importterceiros": "Importar apenas contas de terceiros"
				}
			},
			"upload": {
				"step": "Ficheiro SAFT",
				"title": "Por favor escolha o ficheiro que pretende importar",
				"import": "Importar ficheiro de configuração da Importação",
				"export": "Exportar ficheiro de configuração da Importação"
			},
			"fields": {
				"nDiario": "Nº diário",
				"nome": "Nome",
				"diarioOriem": "Diário de origem",
				"diarioDestino": "Diário de destino",
				"contaOrigem": "Conta de origem",
				"contaDestino": "Conta de destino",
				"nConta": "Nº conta",
				"criaConta": "Criar",
				"codControlIvaDebito": "Controlo IVA débito",
				"codControlIvaCredito": "Controlo IVA crédito",
				"nif": "Contribuinte",
				"documento": "Documento",
				"valorBase": "Valor base",
				"valorIvaCalculado": "Valor IVA provável",
				"taxaCalculada": "Taxa provável",
				"nContasBase": "Nº contas base",
				"nContasIVA": "Nº contas IVA",
				"valorBaseDoc": "Valor base doc.",
				"valorIVADoc": "Valor IVA doc.",
				"taxaSobreTotalIVA": "Taxa s/ total IVA",
				"contaCredito": "Conta crédito",
				"contaDebito": "Conta débito",
				"descricao": "Nome",
				"valor": "Valor",
				"contaExistente": "Contas existentes",
				"convertConta": "Contas com conversão",
				"contasSemConversao": "Contas sem conversão",
				"nContaDestino": "Nº conta destino",
				"mercado": "Mercado"
			},
			"associacaoDiarios": {
				"step": "Associação de diários",
				"title": "Associação de diários de contabilidade",
				"desc": "Por favor indique se pretende associar diários a diários já existentes. Se não indicar nenhuma associação os diários serão criados."
			},
			"planoContas": {
				"step": "Plano de contas",
				"title": "Plano de contas",
				"filters": {
					"contasComErro": "Ver apenas contas com erro",
					"contasComConversao": "Ver apenas contas com conversão",
					"contasSemConversao": "Ver apenas contas sem conversão"
				},
				"tooltip": {
					"importMatriz": "Importar matriz de conversão"
				},
				"btn": {
					"importMatriz": "Importar matriz"
				}
			},
			"convertContas": {
				"step": "Conversão de contas",
				"title": "Conversão de contas",
				"desc": "Por favor indique a matriz de conversão que pretende para as contas do plano de contas."
			},
			"contasExist": {
				"step": "Contas existentes",
				"title": "Contas que já existem no plano de contas",
				"desc": "Por favor verifique as contas que vão ser importadas e que já existem no plano de contas.",
				"desc1": "Se pretender pode substituir a conta de destino."
			},
			"contasSemConvert": {
				"step": "Contas sem conversão",
				"title": "Contas sem conversão definida",
				"desc": "Por favor indique as contas sem conversão que pretende criar."
			},
			"contasCorrentes": {
				"step": "Contas correntes",
				"title": "Contas correntes a serem criadas",
				"apenasContasMovimentadas": "Importa apenas contas movimentadas",
				"efetuaConversaoContasCorrentes": "Efetua conversão para conta destino com base em mercado"
			},
			"configImport": {
				"step": "Configurações",
				"title": "Configurações de importação",
				"fields": {
					"diario": "Nº diário",
					"periodo": "Período",
					"ndescritivo": "Descritivo lançamentos",
					"descritivosaldosiniciais": "Nº descritivo",
					"saldosiniciais": "Saldos iniciais",
					"diarioapura": "Diário de apuramentos de resultados do ficheiro SAF-T",
					"transactiondate": "Efetuar a importação em que o período é formado a partir da <TransactionDate> (atenção, usar apenas quando o ficheiro SAF-T apresenta datas trocadas)",
					"diaroAbertura": "Abertura",
					"descritivoSaldosIniciais": "Saldo inicial",
					"justificaContasCorrenteMovAb": "Justificar movimentos em aberto de contas correntes"
				},
				"messages": {
					"nDescritivoError": "Descritivo lançamentos não pode ser vazio.",
					"periodoSaldosIniciaisError": "Período não pode ser vazio.",
					"nDiarioSaldosIniciaisError": "Nº diário não pode ser vazio.",
					"nDescritivoSaldosIniciaisError": "Nº descritivo não pode ser vazio.",
					"justificaContasCorrenteMovAbInfo": "Os movimentos das contas correntes serão automaticamente justificados até ao último período onde o ficheiro SAFT apresenta lançamentos.",
					"importarSaftContab": "A processar a importação do SAF-T contabilidade..."
				}
			},
			"finalized": {
				"step": "Resultado",
				"successfully": "Operação concluida com sucesso!"
			}
		},
		"modals": {
			"processing": {
				"title": "Importador SAF-T Contabilidade",
				"processModalLoad": "Carregar Processo",
				"processModalCancel": "Cancelar Processo"
			},
			"planos": {
				"title": "Enquadramento do plano de contas da conta \"{{nConta}}\"",
				"hint": "Enquadramento do plano de contas da conta"
			},
			"convertcontas": {
				"title": "Conversão de conta",
				"contaorigem": "Conta origem",
				"contadestino": "Conta destino"
			},
			"balanceteNaoSaldado": "O balancete do ficheiro SAFT não está saldado. Tem a certeza que pretende continuar? <br> Saldo a débito: {{debito}} <br> Saldo a crédito: {{credito}}",
			"import": {
				"title": "Importar",
				"configimportedsuccess": "Configuração importada com sucesso."
			},
			"importMatriz": {
				"title": "Importação de matriz de conversão",
				"content": {
					"1": "Bem vindo ao assistente de importação de matriz de conversão. Este assistente vai guiá-lo no processo de importação.",
					"2": "Por favor selecione o modelo que pretende descarregar.",
					"3": "Após descarregar o ficheiro, preencha o mesmo com dados que pretende importar."
				},
				"btn": {
					"modClean": "Descarregar modelo em branco",
					"modFilled": "Descarregar modelo Preenchido"
				}
			}
		},
		"messages": {
			"timeoutModalMessage": "Lamentamos, mas terminou o tempo limite para importação do ficheiro de SAF-T contabilidade. Por favor, tente novamente.",
			"importProcessModalMessageCancelJob": "O processo de importação já se encontra iniciado. Pode cancelar ou carregar o processo.",
			"jobCancelled": "O processo de importação foi cancelado com sucesso. Pode iniciar o novo processo.",
			"alertTimerEndProcess": "Atenção: Após iniciar o processo, tem 60 minutos para terminar a importação.",
			"jobTimeoutModalTitle": "Importação SAF-T contabilidade",
			"jobTimeoutModalMessage": "A tarefa excedeu o tempo limite. Tem que iniciar a importação novamente.",
			"processarFicheiroLabel": "A processar o ficheiro SAF-T contabilidade...",
			"noFiles": "Selecione o ficheiro para processar...",
			"noTypeImport": "Não selecionou o tipo de importação.",
			"accountsWithError": "Existem contas que contém erros. Não é possível avançar.",
			"accountsWithoutSel": "Existem contas que não estão marcadas para serem criadas. Não é possível avançar.",
			"errorocur": "Ocorreu um erro interno.",
			"contaOrigemVazia": "Não selecionou uma conta de origem.",
			"contaDestinoVazia": "Não selecionou uma conta de destino.",
			"erroDownload": "Ocorreu um erro ao efetuar o download do ficheiro!",
			"importsuccess": "Importação concluída com sucesso!",
			"operationsuccess": "Operação concluída com sucesso!",
			"aprepararImportSaft": "A preparar o processo de importação do SAF-T contabilidade..."
		},
		"btns": {
			"processLoad": "",
			"processReset": ""
		},
		"strings": {
			"jobTimeoutModalTitle": "Tarefa",
			"planoSaft": "Plano SAFT",
			"planoCG": "Plano CentralGest"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmação",
		"leavePromptMessage": "Ao clicar em SIM irá sair e descartar todos os dados do processamento. Tem a certeza que pretende sair?",
		"importTypePromptMessage": "Ao alterar o tipo de importação vai descartar todos os dados do processamento. Pretende alterar?"
	},
	"scss": {
		"or": " ou ",
		"and": " e ",
		"locked-portal": "Não tem acesso a este portal",
		"locked-portal-cgstore": "Não tem acesso a este portal, por favor dirija-se ao portal de compras clicando no botão \"Loja\".",
		"locked-portal-expired": "Este portal foi bloqueado, porque o plano associado expirou. Por favor dirija-se ao portal de compras clicando no botão \"Loja\"."
	},
	"seccoes": {
		"title_detail": "Secção {{id}}",
		"title_new": "Nova Secção",
		"title_plural": "Secções",
		"pesquisa": "Pesquisar",
		"saved": "Secção {{id}}, guardada com sucesso",
		"error": "Secção {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Secção {{id}}, apagada com sucesso",
		"fields": {
			"nSeccao": "Código da secção",
			"designacaoBreve": "Designação breve",
			"designacaoCompleta": "Designação completa"
		}
	},
	"sectores": {
		"title_detail": "Setor {{id}}",
		"title_new": "Novo Setor",
		"title_plural": "Setores",
		"pesquisa": "Pesquisar",
		"saved": "Setor {{id}}, guardado com sucesso",
		"error": "Setor {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Setor {{id}}, apagado com sucesso",
		"fields": {
			"sector": "Código do setor",
			"nome": "Nome",
			"idExterno": "Id. externa"
		}
	},
	"segur": {
		"title_detail": "Seguradora {{id}}",
		"title_new": "Nova Seguradora",
		"title_plural": "Seguradoras",
		"pesquisa": "pesquisar",
		"saved": "Seguradora  {{id}}, guardada com sucesso.",
		"error": "Seguradora {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Seguradora {{id}}, apagada com sucesso",
		"fields": {
			"nsegur": "Código seguradora",
			"designaBreve": "Descrição breve",
			"nome": "Descrição completa",
			"morada": "Morada",
			"npostal": "Código postal",
			"localidade": "Localidade",
			"telefone": "Telefone",
			"napolice": "Apólice",
			"codSeguradoraISP": "Cód. seguradora (ISP)"
		},
		"errors": {
			"invalidApolice": "O formato do ficheiro da folha de férias obriga a que o número da apólice tenha 20 carateres. Deve preencher com zeros à esquerda até totalizar 20 carateres."
		}
	},
	"simpleLogin": {
		"at": {
			"userNameFieldLabel": "Nº Contribuinte"
		},
		"ssd": {
			"userNameFieldLabel": "NISS"
		},
		"viactt": {
			"userNameFieldLabel": "Utilizador"
		},
		"saveTitle": "Guardar",
		"passwordFieldLabel": "Password",
		"messages": {
			"successLogin": "Login efetuado com sucesso.",
			"credentialsEmpty": "O utilizador ou senha está vazia."
		}
	},
	"simulacaoVencimento": {
		"fields": {
			"dgemp": "Colaborador",
			"nDiasUteis": "Nº dias uteis",
			"valorLiquido": "Valor liquido a obter",
			"dataSimulacao": "Data Simulação"
		},
		"text": {
			"simularValor": "Simular valor líquido",
			"reciboSimulado": "Recibo simulado",
			"vencimento": "Valor bruto vencimento simulado",
			"totalIliquido": "Total ilíquido",
			"totalDesconto": "Total desconto",
			"totalLiquido": "Total líquido",
			"totalEspecie": "Total espécie",
			"totalAPagar": "Total a pagar",
			"simValLiqTooltip": "Preencha todos os campos para simular o valor líquido"
		},
		"table": {
			"tipoAbono": "Tipo abono",
			"codAbDesc": "Cód. abono",
			"descricao": "Descrição",
			"qtd": "Qtd.",
			"valorUnit": "Valor unit.",
			"valor": "Valor",
			"incidencia": "Incidência",
			"percentagem": "Percentagem"
		}
	},
	"sinalTaxaRetencao": {
		"data": {
			"negativo": "-",
			"positivo": "+"
		}
	},
	"sind": {
		"title_detail": "Ficheiro de Sindicato {{id}}",
		"title_new": "Novo ficheiro de Sindicato",
		"title_plural": "Ficheiros de Sindicato",
		"pesquisa": "Pesquisar",
		"saved": "Ficheiro de Sindicato  {{id}}, guardado com sucesso.",
		"error": "Ficheiro de Sindicato {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Ficheiro de Sindicato {{id}}, apagado com sucesso",
		"fields": {
			"nSindicato": "Nº sindicato",
			"nome": "Nome",
			"designaBreve": "Designação breve",
			"morada": "Morada",
			"npostal": "Código postal",
			"localidade": "Localidade",
			"telefone": "Telefone",
			"fax": "Fax",
			"nContrib": "Nº contribuinte",
			"nBanco": "Código banco",
			"nAgencia": "Código agência",
			"contaGenBanc": "Nº conta bancária",
			"bairroFiscal": "Repartição finanças",
			"valorMinQuot": "Valor mínimo de quota",
			"nomeLocalidade": "Localidade",
			"nomeBairroFiscal": "Repartição finanças"
		}
	},
	"situacaoprofissional": {
		"title_detail": "Situação de profissão {{id}}",
		"title_new": "Nova situação de profissão",
		"title_plural": "Situação de profissões",
		"pesquisa": "Pesquisar",
		"saved": "Situação de profissão {{id}}, guardada com sucesso",
		"error": "Situação de profissão {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Situação de profissão {{id}}, apagada com sucesso",
		"fields": {
			"nSitProfiss": "Código da situação de profissão",
			"designaBreve": "Designação breve",
			"designaComp": "Designação completa",
			"codQuadroPessoal": "Quadro de pessoal"
		}
	},
	"situacaoempregado": {
		"title_detail": "Situação de empregado {{id}}",
		"title_new": "Nova situação de empregado",
		"title_plural": "Situações de empregado",
		"pesquisa": "Pesquisar",
		"saved": "Situação de empregado {{id}}, guardado com sucesso",
		"error": "Situação de empregado {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Situação de empregado {{id}}, apagada com sucesso",
		"fields": {
			"nSitEmpreg": "Código da situação",
			"designaBreve": "Designação breve",
			"designaCompl": "Designação completa",
			"processaVenc": "Processa vencimento"
		}
	},
	"solic": {
		"title_detail": "Texto de aviso de solitação de cobrança {{id}}",
		"title_new": "Novo Texto de aviso de solitação de cobrança",
		"title_plural": "Textos de aviso de solitação de cobranças",
		"pesquisa": "Pesquisar",
		"saved": "Texto de aviso de solitação de cobrança {{id}}, guardado com sucesso.",
		"error": "Texto de aviso de solitação de cobrança {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Texto de aviso de solitação de cobrança {{id}}, apagado com sucesso.",
		"fields": {
			"tipoTexto": "Tipo",
			"nTexto": "Número",
			"tituloTexto": "Título",
			"nDiasAtraso": "Nº dias atraso",
			"note": "Descrição"
		},
		"datasource": {
			"0": "Cabeçalho",
			"1": "Rodapé",
			"cabecalho": "Cabeçalho",
			"rodape": "Rodapé"
		}
	},
	"sscol": {
		"title_detail": "Remuneração da Segurança Social {{id}}",
		"title_new": "Nova Remuneração da Segurança Social",
		"title_plural": "Remunerações da Segurança Social",
		"pesquisa": "Pesquisar",
		"saved": "Remuneração da Segurança Social {{id}}, guardada com sucesso.",
		"error": "Remuneração da Segurança Social {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Remuneração da Segurança Social {{id}}, eliminada com sucesso.",
		"fields": {
			"codSSCol": "Código",
			"designacao": "Designação",
			"dias": "Dias",
			"valor": "Valor"
		}
	},
	"subde": {
		"title_detail": "Sub-Departamento {{id}}",
		"title_new": "Novo Sub-Departamento",
		"title_plural": "Sub-Departamentos",
		"pesquisa": "Pesquisar",
		"saved": "Sub-Departamento {{id}}, guardado com sucesso.",
		"error": "Sub-Departamento {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Sub-Departamento {{id}}, apagado com sucesso",
		"fields": {
			"nSubDe": "Cód.",
			"nSubDePlaceholder": "Código do sub-departamento",
			"nome": "Nome",
			"nomePlaceholder": "Nome do sub-departamento"
		}
	},
	"subfamilias": {
		"title_detail": "Sub-família {{id}}",
		"title_new": "Nova Sub-família",
		"title_plural": "Sub-famílias",
		"pesquisa": "Pesquisar",
		"saved": "Sub-família {{id}}, guardada com sucesso.",
		"error": "Sub-família {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Sub-família {{id}}, apagada com sucesso",
		"fields": {
			"nSubFa": "Cód.",
			"nSubFaPlaceholder": "Código da sub-família",
			"nome": "Nome",
			"nomePlaceholder": "Nome da sub-família"
		}
	},
	"suspensaoContrato": {
		"data": {
			"tscNA": "N/A",
			"tscSim": "Sim",
			"tscNao": "Não"
		}
	},
	"svat": {
		"messages": {
			"noData": "Por favor, escolha o ano e pressione o botão \"Processar\" para visualizar os dados."
		},
		"modal": {
			"title": "Demonstrações financeiras SVAT - Ano : {{anoEmCurso}}",
			"titleBalancete": "Balancete"
		},
		"fields": {
			"descricao": "Rúbrica",
			"notas": "Notas",
			"anoN": "Ano N",
			"anoN1": "Ano N-1",
			"nContaDe": "Nº conta de",
			"nContaAte": "Nº conta até",
			"taxonomyCode": "Taxonomia",
			"taxonomyDescription": "Descrição",
			"taxonomyClasse": "Classe",
			"taxonomySaldoEsperado": "Saldo esperado",
			"mesPeriodo": "Mês período",
			"tipoSaldo": "Tipo saldo",
			"nomeCliente": "Nome",
			"nConta": "Nº conta"
		},
		"strings": {
			"mainTabBalanco": "Balanço",
			"mainTabDemoResultadosPorNaturezas": "Demonstração dos resultados por naturezas",
			"rubricaSubTabIntervaloContas": "Intervalos contas",
			"rubricaSubTabBalancete": "Balancete",
			"verBalanceteTitulo": "Visualizar balancete"
		}
	},
	"taxasiva": {
		"title_detail": "Taxa {{id}}",
		"title_new": "Nova Taxa",
		"title_plural": "Taxas de IVA",
		"pesquisa": "Pesquisar",
		"saved": "Taxa {{id}}, guardada com sucesso",
		"error": "Taxa{{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Taxa {{id}}, apagada com sucesso",
		"fields": {
			"codIva": "Código IVA",
			"dataAltLegal": "Data de alteração legal",
			"taxa": "Taxa",
			"observacao": "Observação"
		},
		"criar": "Manutenção da taxa de IVA ",
		"criada": "Taxa de IVA criada com sucesso.",
		"codIvaNull": "O código IVA não pode ser nulo"
	},
	"taxasretencao": {
		"title_new": "Nova Taxa de retenção",
		"title_detail": "Taxa de retenção {{id}}",
		"title_plural": "Taxas de retenção",
		"saved": "Taxa de retenção {{id}}, guardada com sucesso",
		"error": "Taxa de retenção {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Taxa de retenção {{id}}, apagada com sucesso",
		"pesquisa": "Pesquisar",
		"fields": {
			"codRet": "Cód. retenção",
			"nomeRet": "Nome",
			"taxaRet": "Taxa (%)",
			"tipoCodRet": "Tipo de código",
			"sinal": "Sinal movimento",
			"tipRendiment": "Tipo rendimento",
			"nContaRetencao": "Conta retenção",
			"nomeTipRendiment": "Tipo rendimento",
			"nomeContaRetencao": "Conta retenção"
		},
		"tipoRendimento": {
			"title": "Tipo de rendimento",
			"fields": {
				"declaracao": "Declaração",
				"tipoRendimento": "Tipo rendimento",
				"regTrib": "Regime de tributação"
			}
		},
		"declaracaoRetFonte": {
			"title": "Declaração de retenção na fonte",
			"fields": {
				"zona": "Zona",
				"rubrica": "Rúbrica"
			}
		}
	},
	"taxonomias": {
		"exportXls": "Exportar grelha",
		"autoCorrect": "Aplicar correções automáticas",
		"autoCorrectSuccess": "Correções aplicadas com sucesso",
		"autoCorrectDatas": "Aplicar correções automáticas a datas",
		"autoCorrectDatasMessageOkCancel": "Ao aplicar correção automática a datas de documentos, as datas inválidas vão ser alteradas para o último dia do período a que se refere o documento.<br/>Tem a certeza que pretende continuar?",
		"autoCorrectDatasTitleOkCancel": "Atenção!",
		"autoCorrectDoc0": "Aplicar correções automáticas a documentos só com um lançamento e com valor 0",
		"autoCorrectClasse9": "Aplicar correções automáticas a documentos com classe 9 não saldada",
		"autoCorrectClasse0": "Aplicar correções automáticas a documentos com classe 0 não saldada",
		"autoCorrectDocsSemLinhas": "Aplicar correções automáticas a documentos sem linhas",
		"autoCorrectMessageClasse0": "Ao aplicar correção automática a documentos com classe 0 não saldada, o sistema vai lançar automaticamente a conta {{nconta}} por forma a saldar a classe 0.<br/>Tem a certeza que pretende continuar?",
		"autoCorrectMessageClasse9": "Ao aplicar correção automática a documentos com classe 9 não saldada, o sistema vai lançar automaticamente a conta {{nconta}} por forma a saldar a analítica.<br/>Tem a certeza que pretende continuar?",
		"autoCorrectMessageClasseDocValor": "Ao aplicar correção automática a documentos com apenas uma linha de valor 0, o sistema vai lançar a mesma conta com sinal contrário em todos os documentos nesta situação.<br/>Tem a certeza que pretende continuar?",
		"relacaoContas": {
			"nContaDe": "Nº conta de",
			"nContaAte": "Nº conta até",
			"codTaxonomia": "Cód. taxonomia",
			"descricao": "Descrição"
		},
		"informacaoLegal": {
			"codTaxonomia": "Cód. taxonomia",
			"codSNC": "Cód. SNC",
			"descricao": "Descrição",
			"observacoes": "Observações",
			"classe": "Classe",
			"saldoEsperado": "Saldo esperado",
			"demFinanCampDeb": "Demonstrações financeiras campo débito",
			"demFinanCampCred": "Demonstrações financeiras campo crédito"
		},
		"planoOficial": {
			"nConta": "Nº conta",
			"nome": "Nome"
		},
		"validacoesPlano": {
			"fase": "Fase",
			"conta": "Nº conta",
			"mensagem": "Mensagem",
			"mensagemCorrecaoAutomatica": "Mensagem correção automática",
			"contaterceirosuccess": "Conta corrente de terceiro criada com sucesso.<br/>Deseja editar a ficha de terceiro?"
		},
		"validacoesSvat": {
			"showOnlyErrors": "Visualiza apenas contas com erro",
			"tableFirstHeader": {
				"contasComSaldo": "Contas com Saldo",
				"valSVAT": "Validações SVAT para o tipo de contabilidade - \"Organizada c/SNC\""
			},
			"tableSecondHeader": {
				"contas": "Contas",
				"saldos": "Saldos - Sem apuramentos de resultados"
			},
			"tableThirdHeader": {
				"nConta": "Nº conta",
				"nome": "Nome",
				"tipo": "Tipo",
				"conta": "Conta",
				"codTaxonomia": "Código taxonomia",
				"descricao": "Descrição",
				"classe": "Classe",
				"saldoEsperado": "Saldo esperado",
				"dezembroNormal": "Dezembro (Normal)",
				"dezembroFirst": "Dezembro (1º Encerramento)",
				"dezembroSecond": "Dezembro (2º Encerramento)"
			}
		},
		"validacoesLancamentos": {
			"tipo": "Tipo",
			"fase": "Fase",
			"conta": "Conta",
			"periodo": "Período",
			"diario": "Diário",
			"numeroDocInterno": "Nº doc interno"
		},
		"relacaocontasTab": "Relação de contas com taxonomias",
		"informacaoLegalTab": "Informação legal",
		"planoOficialTab": "Plano oficial publicado",
		"validacoesSvatTab": "Validações SVAT",
		"validacoesPlanoTab": "Validações do plano",
		"validacoesLancamentosTab": "Validações de lançamentos",
		"confirmValidacoesLancamentos": "Deseja efetuar a validação dos lançamentos?<br/>Esta operação pode demorar alguns minutos.",
		"actions": {
			"criarContaTerceiro": "Criar conta de terceiro",
			"trfConta": "Transferência de contas",
			"manutencaoPoc": "Manutenção do plano de contas",
			"extratos": "Extrato de conta"
		},
		"modalContaSalda": {
			"titleAnalitica": "Por favor escolha a conta com que pretende saldar a analítica nos documentos",
			"titleClasse0": "Por favor, escolha a conta com que pretende saldar a classe 0 nos documentos",
			"titleNormal": "Por favor, escolha a conta que pretende para a correção dos documentos",
			"conta": "Conta"
		},
		"grids": {
			"title": "Taxonomias {{tipoTaxonomia}} - {{tipoContabilidade}}"
		}
	},
	"templates": {
		"title_detail": "Template {{id}}",
		"title_new": "Novo template",
		"title_plural": "Templates",
		"pesquisa": "Pesquisar",
		"saved": "Template {{id}}, guardado com sucesso",
		"error": "Template {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"name": "Nome",
			"subject": "Assunto",
			"body": "Corpo",
			"variables": "Variáveis"
		},
		"text": {
			"sendEmail": "Enviar e-mail"
		}
	},
	"terceiros": {
		"clientes": "Clientes",
		"fornecedores": "Fornecedores",
		"outros": "Outros devedores e credores"
	},
	"terms": {
		"title": "Termos, condições e política de privacidade CentralGest Cloud",
		"version": "Versão <strong>{{version}}</strong>",
		"introduction": "Antes de utilizar o CentralGest Cloud é necessário aceitar os nossos termos e condições de utilização do serviço e a nossa política de privacidade.",
		"titleTerms": "Termos e condições",
		"titlePrivacyPolicy": "Política de privacidade",
		"changedTerms": "A política de privacidade ou os termos e condições de utilização do serviço do CentralGest Cloud <strong>foram atualizados</strong> desde a última vez que os aceitou, por favor reveja e aceite os mesmos.",
		"acceptLabel": "Li e aceito os termos e condições de utilização do serviço e a política de privacidade",
		"acceptTooltip": "Para continuar tem de aceitar os termos e condições de utilização do serviço e a política de privacidade",
		"errorRequired": "Tem de aceitar os termos, condições e política de privacidade do CentralGest Cloud para aceder à aplicação."
	},
	"tesestados": {
		"title_detail": "Estado de validação de previsão de tesouraria {{id}}",
		"title_new": "Novo estado de validação de previsão de tesouraria",
		"title_plural": "Estados de validação de previsão de tesouraria",
		"pesquisa": "Pesquisar",
		"saved": "Estado de validação de previsão de tesouraria {{id}}, guardado com sucesso.",
		"error": "Estado de validação de previsão de tesouraria {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Estado de validação de previsão de tesouraria {{id}}, apagado com sucesso",
		"fields": {
			"codEstado": "Cód. estado",
			"codEstadoPlaceholder": "Código do estado de validação de previsão de tesouraria",
			"descricao": "Descrição",
			"descricaoPlaceholder": "Descrição do estado",
			"estadoValidacao": "Estado de validação",
			"estadoValidacaoPlaceholder": "Escolha o estado de validação"
		}
	},
	"tesrubrica": {
		"title_detail": "Estado de validação de Previsões de Tesouraria {{id}}",
		"title_new": "Novo estado de validação de Previsões de Tesouraria",
		"title_plural": "Estados de validação de previsões de tesouraria",
		"pesquisa": "Pesquisar",
		"deleted": "Estado de validação de Previsões de Tesouraria apagado com sucesso.",
		"saved": "Estado de validação de Previsões de Tesouraria {{id}}, guardada com sucesso.",
		"error": "Estado de validação de Previsões de Tesouraria {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"tesRubricaID": "Cód. rúbrica",
			"nome": "Nome",
			"receitaDespesa": "Receita/Despesa"
		},
		"tipoReceitaDespesa": {
			"receita": "Receita",
			"despesa": "Despesa"
		}
	},
	"themes": {
		"light": "Claro",
		"dark": "Escuro",
		"auto": "Automático"
	},
	"tipoAbonoAgrupado": {
		"data": {
			"nd": "N/D",
			"vencimentos": "Vencimento",
			"subRefeicao": "Subsidios Refeição",
			"remunAdicionais": "Remunerações Adicionais",
			"subsidios": "Subsídios",
			"rendEspecie": "Rendimento Espécie",
			"cessacaoContrato": "Cessação Contrato",
			"gratificacoes": "Gratificações"
		}
	},
	"tipoAbonoDesc": {
		"data": {
			"abono": "Abono",
			"desconto": "Desconto"
		}
	},
	"tipoAnalitica": {
		"data": {
			"lancaConformeDefinidoIntegracao": "Lança conforme definido na integração",
			"naoLancaAnalitica": "Não lança analítica",
			"lancaSempreAnalitica": "Lança sempre analítica"
		}
	},
	"tipoAssiduidadeSegSocial": {
		"data": {
			"taaNA": "N/A",
			"taaSim": "Sim",
			"taaNao": "Não"
		}
	},
	"tiposAusencias": {
		"data": {
			"na": "N/A",
			"porFerias": "Por férias",
			"porDoencaImprevista": "Por doença imprevista",
			"porDoencaProf": "Por doença profissional",
			"porDoencaProlongada": "Por doença prolongada",
			"porTribunal": "Por tribunal",
			"porServicoMilitar": "Por serviço militar",
			"porAssistenciaFamiliar": "Por assistência familiar",
			"porPaternidadeMaternidade": "Por paternidade / maternidade",
			"porLicencaAurorizada": "Por licença autorizada",
			"porPenaDisciplinar": "Por pena disciplinar",
			"porLicencaSabatica": "Por licença sabática",
			"porCasamento": "Por casamento",
			"porNascimentoFamiliar": "Por nascimento de familiar",
			"porFalecimentoFamiliar": "Por falecimento de familiar",
			"porTrabalhadorEstudante": "Por trabalhador estudante",
			"porGreveParalizacao": "Por greve / paralisação",
			"porConsultaMedico": "Por consulta médica, exames de diagnóstico",
			"outras": "Outras",
			"porFaltaInjustificada": "Por falta injustificada",
			"actividadeSindical": "Por atividade sindical",
			"acidenteTrabalho": "Por acidente de trabalho"
		}
	},
	"tiposBalancoSocial": {
		"data": {
			"tabsNA": "N/A",
			"tabsSim": "Sim",
			"tabsNao": "Não"
		}
	},
	"tipoBem": {
		"pesquisa": "Tipos de bem",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"notApplicable": "N/A",
			"stocks": "Existências",
			"otherGoodsServices": "Outros bens e serviços",
			"immobilized": "Imobilizado"
		}
	},
	"tiposCategoria": {
		"data": {
			"notApplicable": "Sem grupo",
			"vencimento": "Vencimento",
			"diuturnidade": "Diuturnidade",
			"premios": "Prémios",
			"comissoes": "Comissões",
			"subRefeicao": "Sub. refeição",
			"ajudaCusto": "Ajuda de custo",
			"horaExtra": "Hora extra",
			"ferias": "Férias",
			"subFerias": "Sub. férias",
			"feriasPagas": "Férias pagas",
			"subNatal": "Sub. natal",
			"trabNocturno": "Trabalho noturno",
			"insencaoHorario": "Isenção de horário",
			"subTurno": "Sub. turno",
			"faltasPagas": "Faltas pagas",
			"indemnizacaoCaducidade": "Indemnização de caducidade",
			"indemnizacaoDespedimento": "Indemnização de despedimento",
			"indemnizacaoGlobal": "Indemnização global",
			"deslocacao": "Deslocação",
			"remProfLivre": "Remuneração de profissional livre",
			"iva": "IVA",
			"virtual": "Virtual",
			"suplementoRemuneratorio": "Suplemento remuneratório (Abono para falhas)",
			"ticketValesRefeicao": "Ticket / Vales refeição",
			"pagamentosEmEspecie": "Pagamento em espécie",
			"valesEducacao": "Vales de educação",
			"utlViaturaPatronal": "Utilização viatura patronal",
			"gratificacoesBombeiros": "Gratificações bombeiros",
			"cartaoOferta": "Cartão oferta",
			"apoioRegimeLayoff": "Apoio regime layoff",
			"despesasTeletrabalho": "Despesas teletrabalho",
			"utilizHabitaPropriaPermanente": "Utilização habitação fornecida pela entidade patronal",
			"gratificacaoBalancoArt236": "Gratificação de balanço art. 236 OE - 2024"
		}
	},
	"tipoClasseContrato": {
		"data": {
			"contratoServico": "Contrato de serviços",
			"contratoPrecoCliente": "Contrato de tabela de preços a clientes",
			"contratoPrecoFornecedor": "Contrato de tabela de preços de fornecedores",
			"contratoSubContratacao": "Contrato de subcontratação",
			"contratoFornecimentoCliente": "Contrato de fornecimentos a clientes",
			"contratoFornecimentoFornecedor": "Contrato de fornecimentos de fornecedores",
			"contratoAlocacaoRecurso": "Contrato de alocação de recurso"
		}
	},
	"tipoConta": {
		"pesquisa": "Tipos conta",
		"fields": {
			"value": "Valor",
			"label": "Nome"
		},
		"data": {
			"movement": "Movimento",
			"subtotal": "Sub-total",
			"reason": "Razão",
			"class": "Classe",
			"cc": "Conta corrente"
		}
	},
	"tipoContaDiasFeriasNorm": {
		"data": {
			"notApplicable": "N/A",
			"yes": "Sim",
			"no": "Não"
		}
	},
	"tipoContaDiasSubAlimentacao": {
		"data": {
			"notApplicable": "N/A",
			"yes": "Sim",
			"no": "Não"
		}
	},
	"tipoContabilidade": {
		"data": {
			"none": "Não tem",
			"organizadaPOCDL35": "Organizada c/ POC DL 35",
			"naoOrganizadaPOCDL35": "Não organizada c/ POC DL 35",
			"organizadaSNC": "Organizada c/ SNC",
			"naoOrganizadaSNC": "Não organizada c/ SNC",
			"organizadaPOCIPSS": "Organizada c/ POC IPSS",
			"organizadaPOCAL": "Organizada c/ POC AL",
			"organizadaPOCP": "Organizada c/ POC P",
			"organizadaPOCMZ": "Organizada c/ POC MZ",
			"naoOrganizadaPOCMZ": "Não organizada c/ POC MZ",
			"organizadaPGCAN": "Organizada c/ PGC AN",
			"naoOrganizadaPGCAN": "Não organizada c/ PGC AN",
			"planoGeralContasNIRFMZ": "Plano geral contas NIRF MZ",
			"organizadaSNCIPSS": "Organizada c/ SNC IPSS",
			"organizadaSNCPE": "Organizada c/ SNC PE",
			"organizadaSNCME": "Organizada c/ SNC ME",
			"organizadaEntSectorNLucrativo": "Organizada - Entidades do sector não lucrativo",
			"naoOrganizadaArt50CodIVA": "Não organizada - Art.º 50.º do código do IVA",
			"organizadaSNCAP": "Organizada c/ SNC AP"
		}
	},
	"tipoContrato": {
		"data": {
			"semtipocontrato": "Sem tipo de contrato",
			"contratosemtermo": "Contrato sem termo (permanente)",
			"contratoatermo": "Contrato a termo",
			"contratotrabtempoindeterm": "Contrato de trab. por tempo indeterm.",
			"contratotrabalhotermocedtemp": "Contrato de trabalho a termo para cedência temporária",
			"contratoprestoserviço": "Contrato de prestação de serviço"
		}
	},
	"tipoContratotrab": {
		"data": {
			"tempoIndeterminado": "Contrato por tempo indeterminado",
			"termoCerto": "Contrato a termo certo",
			"termoIncerto": "Contrato a termo incerto"
		}
	},
	"tipoDeducao": {
		"pesquisa": "Tipos de dedução",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"notApplicable": "N/A",
			"withDeduction": "Com dedução",
			"noDeduction": "Sem dedução",
			"withCountryRefundOrigin": "Com reembolso do pais de origem"
		}
	},
	"tipoDeducaoTaxa": {
		"pesquisa": "Tipos dedução taxa",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"normal": "Normal",
			"intraCommunity": "Intra comunitária",
			"passiveSubjectInversion": "Inversão de sujeito passivo",
			"notApplicable": "N/A"
		}
	},
	"tipoDescontoAgrupado": {
		"data": {
			"nd": "N/D",
			"faltas": "Faltas",
			"penhoras": "Penhoras",
			"impostos": "Impostos"
		}
	},
	"tipoDescritivo": {
		"pesquisa": "Tipos de descritivo",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"receipt": "Recibo",
			"acceptedLetter": "Letra aceite",
			"acceptedDiscounted": "Desconto aceite",
			"acceptedPaymentDiscounted": "Pagamento aceite descontado",
			"acceptedReturnDiscounted": "Devolução aceite descontado",
			"acceptedReformDiscounted": "Reforma aceite descontado",
			"acceptedPaymentNotDiscounted": "Pagamento aceite não descontado",
			"acceptedReturnNotDiscounted": "Devolução aceite não descontado",
			"acceptedReformNotDiscounted": "Reforma aceite não descontado",
			"preDatedCheck": "Cheque pré-datado",
			"preDatedCheckDiscount": "Desconto cheque pré-datado",
			"preDatedCheckPaymentDiscounted": "Pagamento cheque pré-datado descontado",
			"preDatedCheckReturnDiscounted": "Devolução cheque pré-datado descontado",
			"preDatedCheckRetirementDiscounted": "Reforma cheque pré-datado descontado",
			"preDatedCheckPaymentNotDiscounted": "Pagamento cheque pré-datado não descontado",
			"preDatedCheckReturnNotDiscounted": "Devolução cheque pré-Datado não descontado",
			"preDatedCheckRetirementNotDiscounted": "Reforma cheque pré-datado não descontado",
			"saqueSemAceite": "Saque sem aceite",
			"DescontoSaqueSemAceite": "Desconto saque sem aceite",
			"pagamentoSaqueSemAceiteDiscounted": "Pagamento saque sem aceite descontado",
			"devoluçãoSaqueSemAceiteDiscounted": "Devolução saque sem aceite descontado",
			"reformaSaqueSemAceiteDiscounted": "Reforma saque sem aceite descontado",
			"pagamentoSaqueSemAceiteNotDiscounted": "Pagamento saque sem aceite não descontado",
			"devoluçãoSaqueSemAceiteNotDiscounted": "Devolução saque sem aceite não descontado",
			"reformaSaqueSemAceiteNotDiscounted": "Reforma saque sem aceite não descontado",
			"doesNotControlVAT": "Não controla IVA",
			"documentManagement": "Gestão documental",
			"firstAdoptionNewAccountingReference": "Primeira adoção do novo referencial contabilístico",
			"accountingPolicyChanges": "Alterações políticas contabilísticas",
			"differencesTranslationFinancialStatements": "Diferenças de conversão de demonstrações financeiras",
			"realizationExcessesRevaluationTangibleIntangibleAssets": "Realização de excedentes de revalorização de ativos tangíveis e intangíveis",
			"excessesRevaluationTangibleIntangibleAssets": "Excedentes de revalorização de ativos tangíveis e intangíveis",
			"deferredTaxAdjustments": "Ajustamentos por impostos diferidos",
			"otherChangesShareholdersEquity": "Outras alterações do capital próprio",
			"netIncome": "Resultado líquido",
			"capitalRealizations": "Realizações de capital",
			"performanceEmissionPremiums": "Realizações de prémios de emissão",
			"distributions": "Distribuições",
			"lossCoverageEntries": "Entradas para cobertura de perdas",
			"otherOperations": "Outras operações"
		}
	},
	"tipoEntSuporteABDES": {
		"data": {
			"None": "N/A",
			"Empregado": "Empregado",
			"Patronato": "Patronato",
			"EmpregadoPatronato": "Empregado e patronato"
		}
	},
	"tipoExcecaoCRABD": {
		"data": {
			"SemExcecoes": "Sem exceções",
			"ExcluirEmpregados": "Exclusão",
			"IncluirEmpregados": "Inclusão"
		}
	},
	"tipoInventario": {
		"data": {
			"naotemInvPerm": "Não tem inventário permanente mensal",
			"temInvPermMensalCG": "Tem inventário permanente mensal feito no CentralGest",
			"temInvPermMensalManual": "Tem inventário permanente mensal feito manualmente"
		}
	},
	"tipoLiquidacao": {
		"pesquisa": "Tipos de liquidação",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"notApplicable": "N/A",
			"withSettlement": "Com liquidação",
			"noSettlement": "Sem liquidação"
		}
	},
	"tipoOperacao": {
		"pesquisa": "Tipos de operação",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"notApplicable": "N/A",
			"active": "Ativa",
			"passive": "Passiva",
			"regularizationFavorState": "Regularização a favor estado",
			"regularizationFavorCompany": "Regularização a favor empresa"
		}
	},
	"tipoOrdDocumentos": {
		"data": {
			"dataNContribuinte": "Data | Nº contribuinte",
			"nContribuinteData": "Nº contribuinte | Data",
			"dataRececao": "Data receção",
			"nomeData": "Nome | Data",
			"dataNome": "Data | Nome"
		}
	},
	"tipoPasswordRecibo": {
		"data": {
			"nao": "Não",
			"igualNIF": "Sim, com senha igual ao NIF",
			"igualNumeroCC": "Sim, com senha igual ao nº cartão cidadão",
			"igualNumeroSS": "Sim, com senha igual ao nº segurança social",
			"igualCodigoFuncionario": "Sim, com senha igual ao código funcionário"
		}
	},
	"tipoRecibo": {
		"data": {
			"taSim": "Sim",
			"taNao": "Não"
		}
	},
	"tipoValorUnitarioRecolher": {
		"data": {
			"Indiferente": "Por defeito",
			"ValorVariavel": "Valor variável",
			"ValorFixo": "Valor fixo",
			"REmuneracaoHora": "Remuneração hora",
			"REmuneracaoHoraBase": "Remuneração hora base",
			"VencimentoBase": "Vencimento base",
			"REmuneracaoMensalTotal": "Remuneração mensal total",
			"RemuneracaoPorDiaUtil": "Remuneração por dia útil",
			"REmuneracaoPorDiaBase30": "Remuneração por dia / 30",
			"REmuneracaoMensalSubsidioBase22": "Vencimento mensal base / 22",
			"REmuneracaoMensalSubsidioBase30": "Vencimento mensal base / 30",
			"REmuneracaoHora2": "Remuneração hora 2"
		}
	},
	"tipoRegularizacaoCampo40": {
		"pesquisa": "Tipos de regularizações do Campo 40",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"notApplicable": "N/A",
			"art78N2": "Art.º 78 nº 2 - Redução ou anulação do valor de uma transação",
			"art78N3": "Art.º 78 nº 3 - Retificação de imposto liquidado a mais",
			"art78N6": "Art.º 78 nº 6 - Correção de erros materiais ou de cálculo a mais",
			"art78N7Pre2013ItemA": "Art.º 78 nº 7 créditos incobráveis antes 2013 alínea a) em processo de execução",
			"art78N7Pre2013ItemB": "Art.º 78 nº 7 créditos incobráveis antes 2013 alínea b) em processo de insolvência",
			"art78N7Pre2013ItemC": "Art.º 78 nº 7 créditos incobráveis antes 2013 alínea c) em processo especial de revitalização",
			"art78N7Pre2013ItemD": "Art.º 78 nº 7 créditos incobráveis antes 2013 alínea d) no Sistema de recuperação de Empresas por via extrajudicial (SIREVE)",
			"art78N7Post2013ItemA": "Art.º 78 nº 7 créditos incobráveis depois 2013 alínea a) em processo de execução",
			"art78N7Post2013ItemB": "Art.º 78 nº 7 créditos incobráveis depois 2013 alínea b) em processo de insolvência",
			"art78N7Post2013ItemC": "Art.º 78 nº 7 créditos incobráveis depois 2013 alínea c) em processo especial de revitalização",
			"art78N7Post2013ItemD": "Art.º 78 nº 7 créditos incobráveis depois 2013 alínea d) em processo especial de revitalização",
			"art78N8ItemD": "Art.º 78 nº 8 alínea d) - Os créditos, SP, sejam inferiores a € 6000 reconhecidos em processo judicial - ROC",
			"art78N8ItemB": "Art.º 78 nº 8 alínea b) - Os créditos, não SP, sejam superiores a 750 e inferiores a € 8000 reconhecidos em processo judicial, sem bens - ROC",
			"art78N8ItemC": "Art.º 78 nº 8 alínea c) - Os créditos, não SP, sejam superiores a 750 e inferiores a € 8000 reconhecidos em processo judicial, com bens - ROC",
			"art78N8ItemE": "Art.º 78 nº 8 alínea e) - Os créditos, não SP, sejam superiores a 750 e inferiores a € 8000 reconhecidos em processo judicial, na lista de falidos - ROC",
			"art78AN4ItemA": "Art.º 78 - A nº 4 alínea a) - Os créditos em processo de execução, sem bens",
			"art78AN4ItemB": "Art.º 78 - A nº 4 alínea b) - Os créditos em processo de insolvência após ser decretada",
			"art78AN4ItemC": "Art.º 78 - A nº 4 alínea c) - Os créditos processo especial de revitalização, após homologação",
			"art78AN4ItemD": "Art.º 78 - A nº 4 alínea d) - No Sistema de Recuperação de Empresas por Via Extrajudicial (SIREVE), após celebração do acordo",
			"art78AN2ItemA": "Art.º 78 - A nº 2 alínea a)",
			"art78BN4": "Art.º 78 - B nº 4 - Em créditos de cobrança duvidosa, com aprovação tácita de dedução",
			"adjustmentsWhoseCreditDoesNotExceed750VATIncluded": "Regularizações cujo crédito não seja superior a € 750, IVA incluído",
			"otherRegularizationsNotCoveredArt78RegularizationCoveredArticles23to26": "Outras regularizações não abrangidas pelo artº78 - Regularizações abrangidas pelos artºs 23 a 26º",
			"otherRegularizationsNotCoveredByArt78": "Outras regularizações não abrangidas pelo artº78 - Outras Regularizações (ex: fusão)"
		}
	},
	"tipoRegularizacaoCampo41": {
		"pesquisa": "Tipos de regularizações do Campo 41",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"notApplicable": "N/A",
			"art78N3": "Art.º 78 nº 3 - Retificação de imposto liquidado a menos",
			"art78N4": "Art.º 78 nº 4 - Retificação de imposto deduzido a mais",
			"art78N6": "Art.º 78 nº 6 - Correção de erros materiais ou de cálculo a menos",
			"art78N7ItemA": "Art.º 78 nº 7 alínea a) - Art.º 78, nº 11, por comunicação de regularização, em processo de execução",
			"art78N7ItemB": "Art.º 78 nº 7 alínea b) - Art.º 78, nº 11, por comunicação de regularização, em processo de insolvência",
			"art78N7ItemC": "Art.º 78 nº 7 alínea c) - Art.º 78, nº 11, por comunicação de regularização, em processo de especial de revitalização",
			"art78N7ItemD": "Art.º 78 nº 7 alínea d) - Art.º 78, nº 11, por comunicação de regularização, no Sistema de Recuperação de Empresas por Via Extrajudicial (SIREVE)",
			"art78N8ItemD": "Art.º 78 nº 8 alínea d) - Art.º 78, nº 11, por comunicação de regularização, SP, sejam inferiores a € 6000 reconhecidos em processo judicial",
			"art78N12": "Art.º 78 nº 12 - Em recuperação dos créditos em que já foi deduzido o IVA",
			"art78CN1": "Art.º 78 - C nº1 - Por comunicação de regularização, SP, em créditos de cobrança duvidosa, com aprovação tácita de dedução",
			"art78CN3": "Art.º 78 - C nº3 - Em recuperação dos créditos de cobrança duvidosa em que já foi deduzido o IVA",
			"otherRegularizationsNotCoveredByArt78RegularizationCoveredArticles23to26": "Outras regularizações não abrangidas pelo artº78 - Regularizações abrangidas pelos artigos 23 a 26º",
			"otherRegularizationsNotCoveredByArticle78": "Outras regularizações não abrangidas pelo artº78 - Outras regularizações (ex: fusão)",
			"article6OfDecreeLawN198/90of19June": "Artigo 6º do Decreto-Lei n.º 198/90 de 19 de Junho",
			"decreeLawNo19/2017OfFebruary14": "Decreto-Lei n.º 19/2017 de 14 de Fevereiro"
		}
	},
	"tipoResumoMensal": {
		"pesquisa": "Tipos resumo mensal",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"naoUsa": "Não utiliza",
			"aPartirDoUltimoDia": "A partir do último dia",
			"aPartirDoUltimoDiaUtil": "A partir do último dia útil",
			"aPartirDoPrimeiroDiaDoMesSeguinte": "A partir do primeiro dia do mês seguinte",
			"aPartirDoPrimeiroDiaUtilDoMesSeguinte": "A partir do primeiro dia útil do mês seguinte"
		}
	},
	"tipoTaxa": {
		"pesquisa": "Tipos de taxa",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"notApplicable": "N/A",
			"exempt": "Isento",
			"reduced": "Reduzido",
			"intermediate": "Intermédia",
			"normal": "Normal"
		}
	},
	"tipoTaxaIva": {
		"pesquisa": "Tipos taxa IVA",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"exempt": "Isenta",
			"reduced": "Reduzida",
			"normal": "Normal",
			"intermediate": "Intermédia",
			"doesNotDeduct": "Não deduz",
			"diesel50Percent": "Gasóleo 50%",
			"exemptEU": "Isento UE",
			"exemptNotEU": "Isento fora UE"
		}
	},
	"tipoTaxaRetencao": {
		"data": {
			"rendimentos": "Rendimentos",
			"subNatal": "Sub. Natal",
			"subFerias": "Sub. Férias"
		}
	},
	"tipoTransmissaoActiva": {
		"pesquisa": "Tipos de transmissão ativa",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"notApplicable": "N/A",
			"transmissionSubject": "Transmissão sujeita",
			"advanceReceiptsYear": "Recebimentos antecipados do ano",
			"opLocalizedForeignProviderReverseCharge": "Op. localizadas cá por fornec. estrangeiro com reverse charge (IVA devido pelo adquirente)",
			"transmitFreeWithoutDeductible": "Transmissão isenta sem direito à dedução",
			"civilConstructionServicesReverseCharge": "Serviços de construção civil com reverse charge (IVA devido pelo adquirente)",
			"transmissionScrapReverseCharge": "Transmissão de sucatas com reverse charge (IVA devido pelo adquirente)",
			"transmissionExemptOrNotSubjectDeduction": "Transmissão isenta ou não sujeitas com direito à dedução - art.º 21, al. b) do n.º 1",
			"transmissionExemptTourismVehicles": "Transmissão isenta de viaturas de turismo art.º 9, n.º 32 (que não foi deduzido IVA)",
			"transmissionExemptVAT": "Transmissão isenta de IVA por sujeição a IMT art.º 9, n.º 30",
			"priorYearReceiptsWithDefinitiveBillingYear": "Recebimentos antecipados do ano anterior com faturação definitiva no ano",
			"establishmentTransmission": "Transmissão de estabelecimento art.º 3, n.º 4",
			"otherActiveOperationsOutsideCIVAField": "Outras operações ativas fora do campo do CIVA",
			"intraCommunityTransmission": "Transmissão intra-comunitária - Art.º 14 RITI",
			"transmissionExport": "Transmissão exportação",
			"opMNLocalizedProviderEUResidentsReverseCharge": "Op. localizadas MN por fornec. residentes UE com reverse charge (IVA devido pelo adquirente)",
			"transmissionGoldReverseCharge": "Transmissão ouro (DL 362/99) com reverse charge (IVA devido pelo adquirente)",
			"transmissionRealEstateWaiverExemptionReverseCharge": "Transmissão de imóveis com renuncia à isenção com reverse charge (IVA devido pelo adquirente)",
			"opFromAllocationOwnUseFreeTransmissionsAllocationSectorExemptActivity": "Op. de afetação a uso próprio, transmissões gratuitas e afetação a um sector atividade isento",
			"opBankingActivity": "Op. de atividade bancaria",
			"insuranceBusinessOpportunity": "Op. de atividade seguradora",
			"opportunityRentalRealEstate": "Op. de atividade de locação de bens imóveis",
			"triangularIntraCommunityTransmission": "Transmissão intra-comunitária triangular - Art.º 8º e 15º RITI",
			"intraCommunityService": "Prestação serviços intra-comunitária - Art.º 6 CIVA",
			"transmissionNewMeansTransportEUIndividuals": "Transmissão de meios de transporte novos a particulares da UE",
			"greenhouseGasEmissionReverseCharge": "Emissão de gases com efeitos de estufa com reverse charge",
			"operacoesEfetuadasNoEstrangeiro": "Operações efetuadas no estrangeiro",
			"outrasOperacoesIsentasOuSemLiquidacaoDeIVAQueConferemDireitoADeducao": "Outras operações isentas ou sem liquidação de IVA que conferem direito à dedução"
		}
	},
	"tipoTransmissaoPassiva": {
		"pesquisa": "Tipos de transmissão passiva",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"notApplicable": "N/A",
			"acquisitionTotalTaxDeductionOthers": "Aquisição com dedução total de IVA - Outros",
			"acquisitionPartialDeductionVATOthers": "Aquisição com dedução parcial de IVA - Outros",
			"acquisitionPartialDeductionVAT": "Aquisição com dedução parcial de IVA - (art.º 21.º do CIVA - Gasóleo)",
			"acquisitionPartiallyTotallyDeductibleDeductionTourismVehicles": "Aquisição com dedução parcial ou totalmente dedutível - Viaturas de turismo",
			"acquisitionGasolineFullyNonDeductibleTax": "Aquisição de gasolina com IVA totalmente não dedutível",
			"acquisitionTourismTravelTotallyNonDeductibleTaxAcquisition": "Aquisição de viat. de turismo com IVA totalmente não dedutível - Aquisição",
			"acquisitionTourismTravelFullyNonDeductibleTaxRentOtherExpenses": "Aquisição de viat. de turismo com IVA totalmente não dedutível - Locação e outras despesas",
			"acquisitionGoodsServicesTotallyNonDeductibleTaxOther": "Aquisição de bens e serviços com IVA totalmente não dedutível - Outros",
			"acquisitionTaxExemptGoodsServices": "Aquisição de bens e serviços isentos de IVA",
			"acquisitionGoodsServicesCoveredSchemeRetailingPetitioners": "Aquisição de bens e serviços abrangidos pelo regime de isenção e peq. retalhistas",
			"acquisitionExemptionVATSubmissionIMT": "Aquisição isenta de IVA  por sujeição a IMT art.º 9, n.º 30",
			"acquisitionEstablishment": "Aquisição de estabelecimento art.º 3, n.º 4",
			"otherCIVAOutFieldLiabilities": "Outras operações passivas fora do campo do CIVA",
			"acquisitionCivilConstructionServices": "Aquisição de serviços de construção civil (IVA devido pelo adquirente)",
			"acquisitionScrap": "Aquisição de sucatas (IVA devido pelo adquirente)",
			"intraCommunityAcquisitionExemptOrigin": "Aquisição intra-comunitária - Isenta na origem",
			"acquisitionCEESubjectOrigin": "Aquisição na CEE - Sujeita na origem",
			"importAcquisition": "Aquisição importação",
			"mNAcquisitionForeignSupplierReverseCharge": "Aquisição MN por fornec. estrangeiro com reverse charge (IVA devido pelo adquirente)",
			"mNAcquisitionEUResidentsReverseCharge": "Aquisição MN por fornec. residentes UE com reverse charge (IVA devido pelo adquirente)",
			"goldAcquisitionReverseCharge": "Aquisição ouro (DL 362/99) com reverse charge (IvaDevidoPeloAdquirente)",
			"acquisitionPropertiesWaiverExemptionReverseCharge": "Aquisição de imóveis com renuncia à isenção com reverse charge (IVA devido pelo adquirente)",
			"emissionGreenhouseGases": "Emissão de gases com efeitos de estufa",
			"aquisicaoDeCorticaEOutrosProdutosDeOrigemSilvicola": "Aquisição de cortiça e outros produtos de origem silvícola"
		}
	},
	"tipoUnidadesValue": {
		"data": {
			"valor": "Valor",
			"hora": "Hora",
			"minuto": "Minuto",
			"segundo": "Segundo",
			"dia": "Dia",
			"mes": "Mês",
			"ano": "Ano",
			"horautil": "Hora útil",
			"horabase30": "Hora base 30",
			"diautil": "Dia útil",
			"diabase30": "Dia base 30"
		}
	},
	"tipoNif": {
		"pesquisa": "Tipos de validação NIF",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"validoseminformacaodeActividade": "Válido sem informação de atividade",
			"nifInvalidoInexistente": "NIF inválido inexistente",
			"validoSemActividade": "Válido sem atividade",
			"validoComActividade": "Válido com atividade",
			"validoComActividadeCessada": "Válido com atividade cessada"
		}
	},
	"tipoValorPenhora": {
		"emfraccao": "Em fração",
		"empercentagem": "Em percentagem",
		"valorabsoluto": "Valor absoluto",
		"porinsolvencia": "Por insolvência"
	},
	"tipoValorizacao": {
		"data": {
			"custoMedioPonderado": "Custo médio ponderado",
			"precoUltimaCompra": "Preço última compra",
			"precoBaseCusto": "Preço base de custo",
			"custoMedio": "Custo médio",
			"custoPadrao": "Custo padrão"
		}
	},
	"tipofacpublicamodelo": {
		"data": {
			"nd": "Não se aplica",
			"cius": "CIUS-PT",
			"ciusip": "Infrastruturas de Portugal",
			"ciussaphety": "Saphety CIUS-PT",
			"ciusfeap": "FE-AP eSPAP"
		}
	},
	"tipohorario": {
		"title_detail": "Tipo de horário {{id}}",
		"title_new": "Novo tipo de horário",
		"title_plural": "Tipos de horários",
		"pesquisa": "Pesquisar",
		"saved": "Tipo de horário {{id}}, guardado com sucesso",
		"error": "Tipo de horário {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Tipo de horário {{id}}, apagado com sucesso",
		"fields": {
			"nTipoHorario": "Tipo de horário",
			"designaBreve": "Designação breve",
			"designaCompl": "Designação completa"
		}
	},
	"tiposArredondamentoABDES": {
		"data": {
			"NaoArredonda": "Não arredonda",
			"UnidMaisProxima": "Para unidade, mais próxima",
			"DezenaMaisProxima": "Para dezena, mais próxima",
			"Baixo1": "Baixo 1",
			"Cima1": "Cima 1",
			"Baixo10": "Baixo 10",
			"Cima10": "Cima 10",
			"ParaDecimaMaisProxima": "Para décima, mais próxima",
			"ParaCentesimaMaisProxima": "Para centésima, mais próxima",
			"ParaMilesimaMaisProxima": "Para milésima, mais próxima",
			"ParaDezenaMaisInferior": "Para dezena, mais inferior",
			"ParaDezenaMaisSuperior": "Para dezena, mais superior",
			"ParaDecimaMaisInferior": "Para décima, mais inferior",
			"ParaDecimaMaisSuperior": "Para décima, mais superior",
			"ParaCentesimaMaisInferior": "Para centésima, mais inferior",
			"ParaCentesimaMaisSuperior": "Para centésima, mais superior",
			"ParaMilesimaMaisInferior": "Para milésima, mais inferior",
			"ParaMilesimaMaisSuperior": "Para milésima, mais superior",
			"ParaCentenaMaisProxima": "Para centena, mais próxima"
		}
	},
	"tiposCategoriaDesc": {
		"data": {
			"segGrupo": "Sem Grupo",
			"irs": "IRS",
			"segSocial": "Segurança Social",
			"cga": "CGA",
			"impostoSelo": "Imposto de Selo",
			"sindicato": "Sindicato",
			"seguro": "Seguro",
			"falta": "Falta",
			"ferias": "Férias",
			"punicao": "Punição",
			"retImpTerceiros": "Retenções impostas por terceiros",
			"subsidioRefeicao": "Subsidio Refeição",
			"outra": "Outra",
			"sobretaxaExtra": "Sobretaxa Extraordinária",
			"adse": "ADSE",
			"penhora": "Penhora",
			"fct": "FCT - Fundo Compensação de Trabalho",
			"fgct": "FGCT - Fundo de Garantia de Compensação do Trabalho",
			"penhoraPensaoAlimentos": "Penhora pensão alimentos",
			"indemnizacaoFaltaAvisoPrevio": "Indemnização falta aviso prévio"
		}
	},
	"tipoComunicacaoAt": {
		"data": {
			"manual": "por Ficheiro",
			"emTempoReal": "por Web-Service"
		}
	},
	"tipoCredito": {
		"data": {
			"credito1": "Crédito 1",
			"credito2": "Crédito 2",
			"credito3": "Crédito 3",
			"credito4": "Crédito 4",
			"credito5": "Crédito 5"
		}
	},
	"tiposDescontoProporcionalABDES": {
		"data": {
			"naoProcessa": "Não processa",
			"porQuantidade": "Por quantidade",
			"porNHorasDia": "Por nº horas dia"
		}
	},
	"tiposEmpresa": {
		"data": {
			"nomeIndividual": "Empresa em nome individual",
			"sociedadeQuotas": "Sociedade por quotas",
			"sociedadeAnonima": "Sociedade anónima",
			"sociedadeCooperativa": "Sociedade cooperativa",
			"associacao": "Associação",
			"servicosMunicipalizados": "Serviços municipalizados",
			"sociedadeUnipessoal": "Sociedade unipessoal",
			"agrupComplementarEmrpesas": "Agrupamento complementar de empresas",
			"camaraMunicipal": "Camara municipal",
			"empresaMunicipal": "Empresa municipal",
			"institutoPublico": "Instituto publico",
			"federacaoDesportiva": "Federação desportiva",
			"sociedadeAnonimaDesportiva": "Sociedade anónima desportiva",
			"escolaSuperior": "Escola superior",
			"estabelecimentoEstavelSocEstrangeira": "Estabelecimento estável soc. estrangeira"
		}
	},
	"tipoFormacao": {
		"data": {
			"tfNA": "N/A",
			"tfSim": "Sim",
			"tfnao": "Não"
		}
	},
	"tiposIVA": {
		"data": {
			"0": "Isenta",
			"1": "Reduzida",
			"2": "Normal",
			"3": "Intermédia",
			"4": "Não deduz",
			"5": "Gasóleo 50%",
			"6": "Isento CEE (Comissão Económica Europa)",
			"7": "Isento fora CEE (Comissão Económica Europa)",
			"isenta": "Isenta",
			"reduzida": "Reduzida",
			"normal": "Normal",
			"intermedia": "Intermédia",
			"naoDeduz": "Não deduz",
			"gasoleo50": "Gasóleo 50%",
			"isentoCEE": "Isento CEE (Comissão Económica Europa)",
			"isentoForaCEE": "Isento fora CEE (Comissão Económica Europa)"
		}
	},
	"tiposMovimento": {
		"pesquisa": "Tipos movimento",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"notDefined": "N/D",
			"default": "Por Defeito",
			"squareMeters": "Metros quadrados",
			"cubicMeters": "Metros cúbicos",
			"linearMeters": "Metros lineares",
			"grams": "Gramas",
			"kilos": "Kilos",
			"tons": "Toneladas",
			"liters": "Litros",
			"hectoliters": "Hectolitros",
			"perimeter": "Perímetro",
			"packing": "Embalagem",
			"hours": "Horas"
		}
	},
	"tipoValidacaoNif": {
		"pesquisa": "Pesquisa de Tipos de Situação NIF",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"notApplicable": "N/A",
			"requiresValidNIF": "Obriga NIF válido",
			"requiresValidNIFAllowsInexistent": "Obriga NIF válido mas permite inexistentes",
			"doesNotRequireNIF": "Não obriga a NIF"
		}
	},
	"tipoPeriocidade": {
		"data": {
			"Sempre": "Sempre",
			"Mensal": "Mensal",
			"Anual": "Anual",
			"Quinzenal": "Quinzenal",
			"Semanal": "Semanal"
		}
	},
	"tiposPresenca": {
		"data": {
			"tapNA": "N/A",
			"tapNao": "Não",
			"tapSim": "Sim"
		}
	},
	"tiposRefeicoes": {
		"pesquisa": "Tipos conta",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"notDefined": "N/D",
			"launch": "Almoço",
			"lightLaunch": "Almoço ligeiro",
			"dinner": "Jantar",
			"lightDinner": "Jantar ligeiro"
		}
	},
	"tipoRemunerado": {
		"data": {
			"notApplicable": "N/A",
			"yes": "Sim",
			"no": "Não"
		}
	},
	"tiposRetServNIF": {
		"data": {
			"adquirenteDeServicos": "Adquirente de serviços",
			"prestadoraDeServicos": "Prestadora de serviços"
		}
	},
	"tipoTrabalho": {
		"data": {
			"tatNA": "N/A",
			"tatTrabalhoNormal": "Trabalho normal",
			"tatTrabEXTRANormal": "Trabalho extraordinário normal",
			"tatTrabEXTRACompPeriodoNormaTrabalho": "Trabalho extraordinário compensado por período normais de trabalho",
			"tatTrabEXTRACompPeriodoFerias": "Trabalho extraordinário compensado por período de férias",
			"tatTrabDiasDescansoComplementar": "Trabalho em dias descanso complementar','Trabalho em dias descanso semanal",
			"tatTrabDiasDescansoSemanal": "Trabalho em dias descanso semanal",
			"tatTrabDiasFeriado": "Trabalho em dias de feriado"
		}
	},
	"tiposanexo": {
		"continente": "Continente",
		"madeira": "Madeira",
		"acores": "Açores",
		"decRecapitulativa": "Declaração recapitulativa",
		"campo40": "Campo 40",
		"campo41": "Campo 41"
	},
	"tiposartigo": {
		"title_detail": "Tipo de Artigo {{id}}",
		"title_new": "Novo Tipo de Artigo",
		"title_plural": "Tipos de Artigos",
		"pesquisa": "Pesquisar",
		"saved": "Tipo de Artigo {{id}}, guardado com sucesso.",
		"error": "Tipo de Artigo {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Tipo de Artigo {{id}} apagado com sucesso",
		"fields": {
			"codTpArt": "Cód.",
			"CodPlaceholder": "Código do tipo de artigo",
			"nome": "Nome",
			"nomePlaceholder": "Nome do tipo de artigo",
			"productCategory": "Tipo de produto (Inventário)",
			"productCategoryPlaceholder": "Tipo de produto no inventário"
		}
	},
	"tiposcontrato": {
		"title_detail": "Tipo de Contrato {{id}}",
		"title_new": "Novo Tipo de Contrato",
		"title_plural": "Tipos de Contrato",
		"pesquisa": "Pesquisar",
		"saved": "Tipo de Contrato {{id}}, guardado com sucesso.",
		"error": "Tipo de Contrato {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Tipo de Contrato {{id}}, eliminado com sucesso.",
		"fields": {
			"nTipoContrato": "Cód.",
			"designacaoBreve": "Designação breve",
			"designacaoCompleta": "Designação completa",
			"codTipCtQPessoal": "Tipo Contrato Q. Pessoal",
			"naoEnviaparaDMR": "Não envia para DMR"
		}
	},
	"tiposregistocadastro": {
		"title_detail": "Tipo de Cadastro {{id}}",
		"title_new": "Novo tipo de cadastro",
		"title_plural": "Tipos de Cadastro",
		"pesquisa": "Pesquisar",
		"deleted": "Tipo de Cadastro apagado com sucesso.",
		"saved": "Tipo de cadastro {{id}}, guardada com sucesso.",
		"error": "Tipo de Cadastro {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"codQPMOT": "Cód. Motivo Q. Pessoal",
			"designaBreve": "Descrição breve",
			"designaCompl": "Descrição completa",
			"infobalancosoc": "Informação para o balanço social: ",
			"nMotivoMov": "Tipos de registo"
		},
		"nodes": {
			"altRegimeContr": "Alteração do regime de contrato",
			"antDaCessacao": "Antecipação da cessação",
			"aumVencimento": "Aumento de vencimento",
			"cessacao": "Cessação",
			"comJustaCausa": "Com justa causa",
			"contComTermo": "Contrato com termo",
			"contComTermoCerto": "Contrato com termo certo",
			"contComTermoIncerto": "Contrato com termo incerto",
			"contSemTermo": "Contrato sem termo (efetivo)",
			"despColetivo": "Despedimento coletivo",
			"despedimento": "Despedimento",
			"entradas": "Entradas",
			"falecimento": "Falecimento",
			"impedProlong": "Impedimento prolongado",
			"iniEmpresa": "Iniciativa empresa",
			"inicTrabalhador": "Iniciativa do trabalhador",
			"movPessoas": "Movimento de pessoas",
			"mutuoAcordo": "Mútuo acordo",
			"passouASemTermo": "Passou a sem termo",
			"porAntigui": "Por antiguidade",
			"porMerito": "Por mérito",
			"porOutroMotiv": "Por outro motivo",
			"preReforma": "Pré-reforma",
			"promo": "Promoções",
			"reconvReclass": "Reconversões/Reclassificação",
			"reformaAntec": "Reforma antecipada",
			"reformaPorInval": "Reforma por invalidez",
			"reformaPorVelh": "Reforma por velhice",
			"saidas": "Saídas",
			"semJustaCausa": "Sem justa causa",
			"termIncerto": "Termo incerto",
			"termoCerto": "Termo certo"
		}
	},
	"tipotempo": {
		"pesquisa": "Tipos de tempo",
		"fields": {
			"value": "Valor",
			"name": "Nome"
		},
		"data": {
			"completo": "Tempo completo",
			"parcial": "Tempo parcial"
		}
	},
	"tirs": {
		"title_detail": "Taxa de IRS {{id}}",
		"title_new": "Nova Taxa de IRS",
		"title_plural": "Taxas de IRS",
		"pesquisa": "Pesquisar",
		"saved": "Taxa de IRS {{id}}, guardada com sucesso.",
		"error": "Taxa de IRS {{id}}, não pode ser guardada. Por favor, verifique os campos.",
		"deleted": "Taxa de IRS {{id}}, eliminada com sucesso.",
		"fields": {
			"nTabIrs": "Cód. tabela IRS",
			"designaBreve": "Designação breve",
			"designaCompl": "Designação completa",
			"dtulTabela": "Data entr. em vigor",
			"datasTaxasIrs": "Datas taxas IRS",
			"anoVigor": "Ano vigor"
		},
		"table": {
			"title": "Escalões - IRS",
			"ateRemunMes": "Até à remun. mês",
			"maiorigual5": ">=5",
			"vabater5": "Valor. abater 5",
			"vabater4": "Valor. abater 4",
			"vabater3": "Valor. abater 3",
			"vabater2": "Valor. abater 2",
			"vabater1": "Valor. abater 1",
			"vabater0": "Valor. abater 0",
			"taxaMarginalMaxima": "Taxa marginal máxima",
			"parcelaAbaterValor": "Parcela abater valor",
			"parcelaAbaterFormula": "Parcela abater formula",
			"parcelaAbaterDepende": "Parcela abater depende",
			"txEfetivaMensalEscal": "Taxa efetiva mensal escala",
			"parcelaAbaterFormulaInfo": "Parcela abater formula info"
		},
		"btn": {
			"novoEscalao": "Novo escalão",
			"apagarEscalao": "Apagar escalão"
		},
		"messages": {
			"naoPodeRemoverTabelasSistema": "Não pode remover tabelas IRS de sistema.",
			"naoPodeAtualizarTabelasSistema": "Não pode alterar tabelas IRS de sistema."
		}
	},
	"tokens": {
		"title_detail": "Token {{id}}",
		"title_plural": "Tokens",
		"pesquisa": "Pesquisar",
		"fields": {
			"token": "token",
			"validUntil": "Válido até"
		}
	},
	"trDocsCabsWizard": {
		"captions": {
			"transformacaoDocumentos": "Transformação de documentos",
			"documento": "Documento",
			"dataDoc": "Data documento",
			"nConta": "Nº conta",
			"conta": "Conta",
			"nif": "NIF",
			"liquido": "Liquido",
			"iva": "IVA",
			"totalDocumento": "Total documento",
			"nCCusto": "Nº C. Custo",
			"nRefProcesso": "Processo",
			"nFactFornec": "Nº doc externo",
			"onlyPossibleToTransform": "Mostra só possíveis de transformar",
			"regraTransformacao": "Regra de transformação",
			"agruparPorCliente": "Agrupar por {{name}}",
			"naoAgrupar": "Não agrupar",
			"dataDocExterno": "Data doc. externo",
			"texto": "Texto",
			"processar": "Processar",
			"avancar": "Avançar",
			"encerrarDocumentos": "Finalizar documentos",
			"docsGerados": "Documentos gerados",
			"erros": "Erros",
			"transformacaoDocumentosErros": "Transformação de documentos - Erros",
			"existemErrosATranformDocs": "Existe(m) {{count}} erro(s) na transformação de documentos.",
			"verErros": "Ver erros",
			"tipoDocumento": "Tipo documento",
			"ateDataDoc": "Até data documento",
			"cliente": "Cliente",
			"fornecedor": "Fornecedor",
			"homePage": "Página inicial"
		},
		"steps": {
			"pesquisa": "Pesquisa"
		},
		"tooltips": {
			"documentoTransformado": "Documento transformado",
			"editarDocumento": "Editar documento",
			"finalizarDocumento": "Finalizar documento",
			"existemErrosATranformDocs": "Clique aqui para ver os erros na transformação de documentos.",
			"criarLoteParaArtigo": "Criar novo lote para artigo"
		}
	},
	"transferenciasarmazens": {
		"title_detail": "Nova transferência de artigos entre armazéns",
		"title_list": "Transferências de artigos entre armazéns",
		"fields": {
			"origemNArtigo": "Cód. artigo",
			"origemNArmazem": "Armazém origem",
			"origemQtd": "Quantidade",
			"destinoNArmazem": "Armazém destino",
			"destinoQtd": "Qtd. destino"
		},
		"modal": {
			"newlinetitle": "Transferências de artigos",
			"titleorigem": "Documento de origem (Saída)",
			"titledestino": "Documento de destino (Entrada)",
			"fields": {
				"data": "Data documento",
				"tipodoc": "Tipo doc.",
				"nnumer": "Nº numer.",
				"ndoc": "Nº doc.",
				"clientfor": "Cliente / For.",
				"armazemorigem": "Armazém origem",
				"armazemdestino": "Armazém destino",
				"armazem": "Armazém",
				"codartigo": "Cód. artigo",
				"qtd": "Quantidade",
				"stock": "Stock"
			}
		},
		"messages": {
			"transfarmsuccess": "Transferência/s de artigos entre armazéns processadas com sucesso.",
			"origemartigonulo": "O Artigo de origem não pode ser nulo.",
			"destinoartigonulo": "O Artigo de destino não pode ser nulo.",
			"origemarmazemnulo": "O Armazém de origem não pode ser nulo.",
			"destinoarmazemnulo": "O Armazém de destino não pode ser nulo.",
			"qtdnula": "A quantidade não pode ser nula.",
			"qtdsup": "O valor da quantidade introduzida não pode ser superior ao valor de stock disponível.",
			"qtdtotalsup": "O valor da quantidade introduzida mais a quantidade das linhas já adicionadas do artigo {{artigo}} é superior ao valor de stock disponível.",
			"configGuardadasSucesso": "As configurações das transferências de armazém foram guardadas com sucesso."
		},
		"tooltip": {
			"addNewLine": "Nova linha de transferência"
		},
		"detail": {
			"table": {
				"fields": {
					"artigo": "Artigo",
					"qtd": "Quantidade",
					"armazemorigem": "Armazém origem",
					"armazemdestino": "Armazém destino"
				}
			},
			"cab": {
				"documentos": "Documentos"
			}
		},
		"list": {
			"fields": {
				"faccbIdOrigem": "Id",
				"nDocfaOrigem": "Tipo doc. origem",
				"nDocOrigem": "Nº doc. origem",
				"nNumerOrigem": "Nº série origem",
				"nDocfaDestino": "Tipo doc. destino",
				"nDocDestino": "Nº doc. destino",
				"nNumerDestino": "Nº série destino",
				"numeroOrigem": "Documento origem",
				"numeroDestino": "Documento destino",
				"dataDoc": "Data doc."
			}
		}
	},
	"trdoc": {
		"title": "Transformação de documentos",
		"saveTitle": "Processar",
		"fields": {
			"nDocfaOrigem": "Tipo doc. origem",
			"nDocfaDestino": "Tipo doc. destino",
			"nNumerDestino": "Série destino",
			"dataDoc": "Data. doc.",
			"dataDocOrigem": "Data doc. é a de origem",
			"dataVenc": "Data vencimento",
			"dataVencCondVenda": "Data de vencimento pela condição comercial",
			"nFactFornec": "Nº doc. externo",
			"nFactFornecOrigem": "Nº doc. externo é o da origem",
			"dataDocExterno": "Data doc. externo",
			"dataDocExternoOrigem": "Data doc. externo é a da origem",
			"nRefProcesso": "Processo",
			"nRefProcessoOrigem": "Processo é o da origem",
			"nArmazem": "Armazém",
			"nArmazemOrigem": "Armazém é o da origem",
			"encerraDocumentoOrigem": "Encerra documento origem",
			"encerraDocumentoDestino": "Encerra documento destino"
		},
		"actions": {
			"cancel": "Cancelar"
		},
		"modal": {
			"linhas": {
				"title": "Transformação de linhas de documento",
				"steps": {
					"linhasDoc": "Linhas",
					"lotes": "Lotes",
					"ident": "Identificação",
					"resumo": "Resumo",
					"carga": "Carga",
					"descarga": "Descarga"
				},
				"info": {
					"transformado": "O documento já foi transformado",
					"linhasTransformadas": "O documento já tem linhas transformadas"
				},
				"label": {
					"continuarTransformacao": "Pretendo continuar com a transformação",
					"naoContinuarTransformacao": "Não pretendo continuar",
					"aposTransformarEncerrarDoc": "Após transformar, encerrar documento(s)",
					"origem": "Origem",
					"destino": "Destino"
				},
				"btn": {
					"editDoc": "Editar documento",
					"preencherAuto": "Preencher lotes auto"
				},
				"messages": {
					"proceSuccess": "Processamento concluído com sucesso.",
					"atribuirLotes": "Tem de atribuir os lotes nas linhas com artigos com lotes para poder simular.",
					"naoTemLinhasSelecionadas": "Não existem linhas para processar, selecione as linhas a transformar do documento.",
					"qtdLotesDifQtdLinha": "A quantidade ({{qtd}}) do artigo {{nArtigo}} distribuída pelos lotes não é igual à da linha {{nSeq}} ({{qtd1}}).",
					"naoTransformou": "Não foram gerados documentos.",
					"proceFalhou": "Erro a processar a transformação."
				}
			}
		},
		"legend": {
			"linhaTransformada": "Linha já transformada"
		}
	},
	"trdocconfig": {
		"title": "Transformação de documentos",
		"saveTitle": "Processar",
		"fields": {
			"nDocfaOrigem": "Tipo doc. origem",
			"nDocfaDestino": "Tipo doc. destino",
			"nNumerDestino": "Série destino",
			"dataDoc": "Data. doc.",
			"dataDocOrigem": "Data doc. é a de origem",
			"dataVenc": "Data vencimento",
			"dataVencCondVenda": "Data de vencimento pela condição comercial",
			"nFactFornec": "Nº doc. externo",
			"nFactFornecOrigem": "Nº doc. externo é o da origem",
			"dataDocExterno": "Data doc. externo",
			"dataDocExternoOrigem": "Data doc. externo é a da origem",
			"nRefProcesso": "Processo",
			"nRefProcessoOrigem": "Processo é o da origem",
			"nArmazem": "Armazém",
			"nArmazemOrigem": "Armazém é o da origem",
			"encerraDocumentoOrigem": "Encerra documento origem",
			"encerraDocumentoDestino": "Encerra documento destino",
			"referenciaTextoBase": "Texto da referência"
		},
		"actions": {
			"cancel": "Cancelar",
			"variables": "Variáveis",
			"variaveis": {
				"nomeDoc": "@NomeDoc  - Nome do documento",
				"nDoc": "@NDoc     - Nº do documento",
				"dtDoc": "@DtDoc    - Data do documento",
				"docExt": "@DocExt   - Documento externo",
				"processo": "@Processo - Processo",
				"contrato": "@Contrato - Contrato",
				"obs1": "@OBS1     - Observações"
			}
		},
		"messages": {
			"success": "Documento transformado com sucesso.",
			"tipoDocNulo": "O tipo documento não pode ser nulo."
		},
		"tooltips": {
			"nDocExterno": "Referência externa impressa no documento",
			"dataDocExterno": "Data externa impressa no documento",
			"aposTransformarEncerrarDocsFinalizar": "Estas ações são irreversíveis. Verifique se pretende mesmo finalizar o(s) documento(s)."
		}
	},
	"trfLancContas": {
		"title": "Transferência de contas",
		"success": "Transferência de contas efetuada com sucesso",
		"fields": {
			"nContaOrigem": "Nº conta origem",
			"nContaDestino": "Nº conta destino",
			"nomeContaDestino": "Nome conta destino",
			"manterContaOrigem": "Manter a conta de origem",
			"dependencias": "Dependências"
		},
		"fieldsTableDependencias": {
			"nConta": "Nº conta origem",
			"nome": "Nome origem",
			"nContaGeralReal": "Nº conta geral origem",
			"valorMascara": "Valor da máscara",
			"radicalMascara": "Máscara"
		},
		"stepIntroCaption": "Introdução",
		"stepContasCaption": "Transferência",
		"stepIntroMessage": "Este programa permite converter uma conta origem para outra conta destino. Esta operação irá afetar toda a faturação, contabilidade e restantes módulos que utilizem o plano de contas.<br/>Por favor, tenha em conta os balancetes já emitidos, tem de os imprimir novamente!<br/>Nesta ferramenta consegue transferir automaticamente todos os movimentos de uma conta (conta de origem) para uma outra conta nova (conta de destino). Esta funcionalidade modifica a conta anterior para a nova em todos os módulos que a utilizavam, como a contabilidade, comercial, ativos e recursos humanos. Os balancetes anteriormente impressos deverão ser substituídos.",
		"stepFinalizeCaption": "Conclusão",
		"stepFinalizeMessage": "Conversão concluída.",
		"messages": {
			"contadestjaexiste": "Atenção: a conta destino já existe no plano de contas actual. A conta origem vai ser substituída."
		}
	},
	"trfba": {
		"pagreg": "Pagamentos registados",
		"documentos": "Documentos",
		"tree": {
			"docporenviar": "Documentos por enviar",
			"docenviados": "Documentos enviados",
			"operacao": {
				"op0": "Não definida",
				"op1": "Pagamentos a fornecedores",
				"op2": "Ordenados",
				"op3": "Transferência",
				"op4": "Renda de casa",
				"op5": "Cartão / Ticket refeição"
			}
		},
		"table": {
			"semdados": "Sem dados",
			"sel": "Sel.",
			"nContaCaixa": "Nº conta D/O",
			"nContaForn": "Nº conta forn.",
			"valor": "Valor",
			"nomeBeneficiario": "Nome beneficiário",
			"nDocInternoLanc": "Doc. interno",
			"dataLanc": "Data lanc.",
			"nDocPago": "Doc. pago",
			"codBancoEMI": "Cód. banco emi.",
			"codagenciaEMI": "Cód. agência emi.",
			"ibanContaEMI": "IBAN emi.",
			"swiftEmi": "SWIFT emi.",
			"codBancoDest": "Cód. banco dest.",
			"codAgenciaDest": "Cód. agência dest.",
			"ibanContaDest": "IBAN dest.",
			"swiftDest": "SWIFT dest.",
			"nomeFicheiro": "Nome ficheiro",
			"trfBaID": "Trf. ID",
			"chaveToPS2": "Chave to PS2",
			"operacao": "Operação",
			"codEmpregado": "Funcionário",
			"nomeFuncionario": "Nome funcionário",
			"formatoTransferencia": "Formato transferência",
			"efectuada": "Efetuada"
		},
		"radio": {
			"ps2xml": "PS2 / XML",
			"cgd": "CGD",
			"rgFormato": "Formato do ficheiro a gerar",
			"igcp": "IGCP"
		},
		"btn": {
			"geraficheiro": "Gerar ficheiro",
			"alterarestado": "Alterar Estado para por enviar",
			"editarregisto": "Editar registo",
			"eliminartransf": "Eliminar selecionados",
			"configSEPA": "Configurações SEPA"
		},
		"modal": {
			"config": {
				"steps": {
					"outraidprivadas": "Outras id. privadas",
					"template": "Templates SEPA"
				},
				"fields": {
					"iban": "IBAN",
					"outraIDPrivada": "Outra id. privada",
					"regTempSal": "Salários"
				}
			},
			"geraficheiro": {
				"titleData": "Data transferência",
				"titlePrioridade": "Data transferência e prioridade",
				"titleDGT": "Dados para o ficheiro de upload da DGT",
				"fields": {
					"nomeFicheiroUploadDGT": "Nome de ficheiro",
					"numeroSequencial": "Número sequencial",
					"dataLanc": "Efetuar transferência na data",
					"prioridade": "Prioridade",
					"normal": "Normal",
					"alta": "Alta"
				}
			},
			"erros": {
				"dataTrfbaNaoPodeSerInferiorDataHoje": "A data da transferência não pode ser inferior à data de hoje.",
				"erroGerarFicheiro": "Erro a gerar ficheiro da transferência bancária."
			}
		},
		"messages": {
			"temselecionar": "Não existe nenhuma transferência bancária selecionada.",
			"confaltestado": "Deseja alterar o estado  dos registos selecionados?",
			"deltransf": "Deseja eliminar os registos selecionados?",
			"deltransfsuccess": "Registos eliminados com sucesso",
			"fichformPS2": "Vai gerar um ficheiro no formato PS2. Deseja continuar?",
			"fichformSEPAXML": "Vai gerar um ficheiro no formato SEPA XML. Deseja continuar?",
			"fichformCGD": "Vai gerar um ficheiro no formato CGD(.txt). Deseja continuar??",
			"fichHomeBanking": "Ficheiro de UPLOAD para o Home Banking foi gerado com sucesso.",
			"ibanjaconfig": "O IBAN já se encontra configurado. Pretende substituir?",
			"linhaaddsuccess": "Adicionado com sucesso",
			"linharemsuccess": "IBAN removido com sucesso",
			"templatealterado": "Template alterado com sucesso",
			"removeline": "Remover linha",
			"ibannulo": "O IBAN não pode ser nulo"
		}
	},
	"tributAutonomas": {
		"header": {
			"modeloTributAutonoma": "Tributação aplicada",
			"prejuizoFiscal": "Prejuízo fiscal",
			"contasDe": "Nº conta de",
			"perioDezembroCaption": "Dezembro - Normal"
		},
		"fields": {
			"nConta": "Nº conta",
			"contaDescricao": "Conta",
			"taxa": "Taxa",
			"taxaAgravada": "% de agravamento",
			"descricao": "Classificador",
			"nome": "Nome",
			"saldoGeral": "Saldo acumulado",
			"valorTributAutonoma": "Tributação autónoma",
			"fromPeriod": "Período de"
		},
		"modeloTributAutonoma": {
			"modelo0": "IRC - Continente",
			"modelo1": "IRC - Zona Franca da Madeira",
			"modelo3": "IRC - Açores",
			"modelo2": "IRS"
		},
		"messages": {
			"desejaAtribuirOClassificadorAsCo": "Deseja atribuir o classificador às contas marcadas?",
			"configuracoesDaTributacaoAutonom": "Configurações da tributação autónoma copiadas para as empresas selecionadas.",
			"modeloChangedsuccess": "O modelo de tributação foi alterado para \"{{modelTribut}}\" com sucesso.",
			"changedModeloTriubt": "Pretende passar a utilizar as taxas de tributação autónoma \"{{modelTributAfter}}\" em vez de \"{{modelTribut}}\"? <br> Nota: Ao alterar o modelo as configurações de contas existentes serão removidas e aplicadas as novas configurações por defeito.",
			"modeloTributAutonomaInfo": "Para alterar o Regime de tributação deve aceder à manutenção de tributações autónomas"
		},
		"modals": {
			"empresas": {
				"title": "Copia para outras empresas"
			},
			"print": {
				"title": "Impressão de tributações autónomas"
			}
		},
		"btn": {
			"copiaParaOutraEmpresas": "Copia para outras empresas",
			"manutTributAutonoma": "Manutenção de tributações autónomas"
		},
		"actions": {
			"poc": "Plano de contas"
		}
	},
	"compilerOptions": {
		"module": "commonjs",
		"target": "es5",
		"sourceMap": true
	},
	"exclude": [
		"node_modules"
	],
	"txtar": {
		"title_detail": "Texto Livre {{id}}",
		"title_new": "Novo Texto Livre",
		"title_plural": "Textos Livres",
		"pesquisa": "Pesquisar",
		"saved": "Texto Livre {{id}}, guardado com sucesso.",
		"error": "Texto Livre {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Texto Livre {{id}}, apagado com sucesso",
		"fields": {
			"nArtigo": "Artigo",
			"nArtigoPlaceholder": "Código de artigo",
			"nTexto": "Nº texto",
			"nTextoPlaceholder": "Nº texto",
			"titulo": "Título",
			"tituloPlaceholder": "Título",
			"note": "Texto",
			"notePlaceholder": "Texto"
		}
	},
	"txtcl": {
		"title_detail": "Texto Livre {{id}}",
		"title_new": "Novo Texto Livre",
		"title_plural": "Textos Livres",
		"pesquisa": "Pesquisar",
		"saved": "Texto Livre {{id}}, guardado com sucesso.",
		"error": "Texto Livre {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Texto Livre {{id}}, apagado com sucesso",
		"fields": {
			"nClifo": "Nº conta",
			"nClifoPlaceholder": "Nº conta",
			"nTexto": "Nº texto",
			"nTextoPlaceholder": "Nº texto",
			"titulo": "Título",
			"tituloPlaceholder": "Título",
			"note": "Texto",
			"notePlaceholder": "Texto"
		}
	},
	"unidadesMedidaComercioInternacional": {
		"title_detail": "Unidade de medida de comércio internacional {{id}}",
		"title_plural": "Unidades de medidas de comércios Internacionais",
		"pesquisa": "Pesquisar",
		"fields": {
			"codigo": "Código",
			"designacao": "Designação",
			"designacaoEN": "Designação EN",
			"simbolo": "Símbolo",
			"tipoUnidadeCG": "Tipo unidade"
		}
	},
	"unidadesmovimento": {
		"title_detail": "Unidade de Movimento {{id}}",
		"title_new": "Nova Unidade de Movimento",
		"title_plural": "Unidades de Movimento",
		"pesquisa": "Pesquisar",
		"saved": "Unidade de Movimento {{id}}, guardado com sucesso.",
		"error": "Unidade de Movimento {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Unidade de Movimento {{id}}, eliminada com sucesso.",
		"fields": {
			"codUni": "Código",
			"codUniPlaceholder": "Código da unidade de movimento",
			"nome": "Nome",
			"nomePlaceholder": "Nome da unidade de movimento",
			"nomeAbrev": "Nome abrev.",
			"nomeAbrevPlaceholder": "Nome abreviado da unidade de movimento",
			"tipo": "Tipo",
			"tipoPlaceholder": "Tipo da unidade de movimento",
			"qtdRefUnidad": "Qtd. por unidade",
			"qtdRefUnidadPlaceholder": "Quantidade por unidade",
			"codUnece": "Código unece",
			"codUnecePlaceholder": "Código da unidade de medida de comércio internacional"
		}
	},
	"users": {
		"title_detail": "Utilizador {{id}} (avançado)",
		"title_new": "Novo Utilizador (avançado)",
		"title_plural": "Utilizadores (configuração avançada)",
		"pesquisa": "Pesquisar",
		"saved": "Utilizador \"{{id}}\" guardado com sucesso",
		"error": "Utilizador \"{{id}}\" não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Utilizador apagado com sucesso",
		"passwordchanged": "Palavra-passe alterada com sucesso!",
		"instrucoes.email": "As instruções para alterar a palavra-passe foram enviadas para o e-mail do utilizador.",
		"instrucoes.emailerro": "Não foi possível enviar e-mail para criação da palavra-passe do utilizador",
		"acessosPortaisApi": "Não tem acessos selecionados",
		"actions": {
			"changepassword": "Alterar palavra-passe",
			"cancel": "Cancelar",
			"change": "Alterar",
			"adicionarEmpresas": "Adicionar empresas",
			"adicionarEmpresa": "Adicionar empresa",
			"empresas": "Mudar empresas",
			"sendEmail": "Enviar e-mail de recuperação de password"
		},
		"fields": {
			"userId": "Id",
			"username": "Autenticação",
			"firstName": "Nome",
			"lastName": "Apelido",
			"password": "Palavra-passe",
			"email": "E-mail",
			"active": "Ativo",
			"nEmpresa": "Nº empresa",
			"nUtilizador": "Nº utilizador",
			"admin": "Administrador",
			"apiv1": "Utilizador",
			"colaborador": "Colaborador",
			"erp": "Utilizador ERP",
			"gestorRH": "Gestor RH",
			"gestorServico": "Gestor serviço",
			"gestorEmentas": "Gestor ementas",
			"regex": "Filtro de API",
			"administrator": "Administrador",
			"formUsername": "Autenticação (campo utilizado para o utilizador efetuar login na plataforma)",
			"formEmail": "E-mail (campo utilizado para o utilizador efetuar login na plataforma)"
		},
		"password": {
			"title": "Muda palavra-passe",
			"newpassword": "Palavra-passe",
			"confirmpassword": "Confirmar",
			"errorEqualsPassword": "As palavras-passe não correspondem"
		},
		"delete": {
			"title": "Deseja apagar o utilizador {{id}}?"
		},
		"steps": {
			"utilizador": "Utilizador",
			"portaisApi": "Acessos a portais / api",
			"empresas": "Acessos a empresas"
		},
		"emailSuccess": "E-mail enviado com sucesso"
	},
	"usersbasic": {
		"title_detail": "Utilizador {{id}}",
		"title_new": "Novo utilizador",
		"title_plural": "Utilizadores",
		"pesquisa": "Pesquisar",
		"saved": "Utilizador \"{{id}}\" guardado com sucesso",
		"error": "Utilizador \"{{id}}\" não pode ser guardado. Por favor verifique os campos.",
		"deleted": "Utilizador apagado com sucesso",
		"fields": {
			"instalacao": "Instalação",
			"utilizador": "Utilizador CentralGest"
		},
		"text": {
			"deleteSameUserDisabled": "Não pode apagar o próprio utilizador",
			"createUtilizador": "Criar utilizador CentralGest e associar automaticamente",
			"acessos": "Acessos",
			"selectInstallation": "Por favor, selecione acima uma instalação para configurar os acessos a empresas",
			"emptyCompanies": "Não existem empresas disponíveis para esta instalação.",
			"allRoles": "Todos os portais",
			"filterCompanies": "Pesquisar empresas",
			"nEmpresa": "Nº empresa",
			"nomeEmpresa": "Nome da empresa",
			"configuracaoEmpresa": "Configuração das empresas",
			"configuracaoGeral": "Configuração geral",
			"acessosEmpresa": "Acessos das empresas",
			"allPolicies": "Todas as empresas",
			"config": "Configuração",
			"loadingEmpresas": "A carregar as empresas da instalação...",
			"blockedAcessosEmpresa": "Por favor, no quadro acima dê acesso a pelo menos uma empresa para configurar os acessos das empresas desta instalação.",
			"loadPolicies": "Para visualizar e/ou editar os acessos das empresas, por favor, clique aqui.",
			"loadingPolicies": "A carregar os acessos das empresas...",
			"loadPoliciesError": "Ocorreram os seguintes erros a carregar os acessos das empresas",
			"portalInclude": "As empresas com acesso a este portal, terão também acesso ao portal",
			"portalIncludes": "As empresas com acesso a este portal, terão também acesso aos seguintes portais"
		},
		"errors": {
			"requiredUtilizador": "É obrigatório selecionar um Utilizador CentralGest para associação"
		},
		"accesses": {
			"generic": {
				"view": "Visualizar",
				"add": "Criar",
				"edit": "Editar",
				"delete": "Anular"
			},
			"addons": {
				"title": "Addons"
			},
			"ativos": {
				"title": "Ativos"
			},
			"contabilidade": {
				"title": "Contabilidade"
			},
			"comercial": {
				"title": "Gestão Comercial"
			}
		},
		"noRoles": {
			"title": "Confirmar guardar utilizador sem acessos",
			"message": "<p>Não selecionou nenhum acesso para o utilizador, <b>o mesmo não vai conseguir entrar na plataforma</b>.</p><p>Tem a certeza que deseja continuar?</p>"
		}
	},
	"utlviatpatronal": {
		"fields": {
			"codEmp": "Empregado",
			"codABDESC": "Abono",
			"anoProcessamento": "Dados",
			"vAquisicaoViatura": "Valor aquisição",
			"vMercadoViatura": "Valor mercado",
			"rhTipoProce": "Processamento"
		},
		"tipoUtlViatPatronal": {
			"todas": "Todas",
			"ativas": "Ativas",
			"inativas": "Inativas"
		},
		"toolbar": {
			"tipoUtlViatPatronal": "Visualiza utl. viatura: {{nameTipoUtl}}"
		},
		"modal": {
			"title": "Manutenção utilização viatura de entidade patronal",
			"fields": {
				"tipoProcessamento": "Tipo processamento",
				"codABDESC": "Abono",
				"codEmp": "Cód. empregado",
				"anoProcessamento": "Ano processamento",
				"mesInicioProce": "Mês início",
				"suspende": "Suspenso",
				"valor": "Valor",
				"vAquisicaoViatura": "Valor de aquisição da viatura ou valor de referência em contrato de locação (IVA incluído)",
				"vMercadoViatura": "Valor de mercado viatura",
				"valorIncidenciaSS": "Seg. Social",
				"valorIncidenciaIRS": "IRS",
				"valincidencia": "Valores incidência",
				"simvalmensal": "Simulação dos valores mensais do montante de rendimento em espécie por utilização de viatura da empresa",
				"obs": "Observações",
				"obsSupencao": "Observações suspensão",
				"simulacaodesc": "Simulação dos valores mensais do montante de rendimento em espécie por utilização de viatura de empresa"
			}
		}
	},
	"datagrid": {
		"column": {
			"required": "Esta coluna é obrigatória",
			"dupsNotAllowed": "Não são permitidos duplicados",
			"digitsOnly": "São apenas permitidos números"
		}
	},
	"vendasanomes": {
		"chart": {
			"title": "Vendas por mês",
			"diference": "Diferença"
		},
		"table": {
			"valorVariacao": "Valor variação",
			"percentVariacao": "% variação"
		},
		"campos": {
			"checkboxAnoAnterior": "Ver ano anterior completo"
		}
	},
	"vendasartigo": {
		"fields": {
			"codigo": "Código",
			"nome": "Nome",
			"infoString": "NIF",
			"valor": "Valor",
			"percentagem": "%"
		},
		"totalvendas": "Total de vendas",
		"btn": {
			"mes": "Mês"
		},
		"toolbar": {
			"month": "Mês: {{nameMonth}}"
		}
	},
	"vendascliente": {
		"fields": {
			"codigo": "Código",
			"nome": "Nome",
			"infoString": "NIF",
			"valor": "Valor total",
			"valorIva": "Valor IVA",
			"valorLiquido": "Valor líquido",
			"percentagem": "%"
		},
		"totalvendas": "Total de vendas",
		"btn": {
			"mes": "Mês"
		},
		"toolbar": {
			"month": "Mês: {{nameMonth}}"
		}
	},
	"vendedores": {
		"title_detail": "Vendedor {{id}}",
		"title_new": "Novo Vendedor",
		"title_plural": "Vendedores",
		"pesquisa": "Pesquisar",
		"saved": "Vendedor {{id}}, guardado com sucesso.",
		"error": "Vendedor {{id}}, não pode ser guardado. Por favor verifique os campos.",
		"fields": {
			"nVendedor": "Cód.",
			"nVendedorPlaceholder": "Código do vendedor",
			"nome": "Nome",
			"nomePlaceholder": "Nome do vendedor"
		}
	},
	"verificacaoacumulados": {
		"title": "Verificação de Acumulados",
		"periodo": "Acumulados por período com mov. em aberto",
		"movimentos": "Acumulados com mov. em aberto até um período",
		"extratos": "Acumulados com extratos",
		"inconsistencias": "Todas as inconsistências",
		"consistencias": "Consistência contas correntes",
		"fields": {
			"deNconta": "Conta de",
			"periodo": "Período até"
		},
		"btn": {
			"verificar": "Verificar",
			"corrigir": "Corrigir selecionados"
		},
		"tooltip": {
			"corrigir": "Para corrigir é necessário selecionar pelo menos um item da lista"
		},
		"alerts": {
			"reconstrucaoSucesso": "A reconstrução da conta foi efetuada com sucesso",
			"reconstrucaoErro": "Ocorreu um erro na construção da conta",
			"correcaoContasCorrentesSucesso": "As contas selecionadas foram corrigidas com sucesso",
			"correcaoContasCorrentesErro": "Ocorreu um erro na correção das contas selecionadas"
		},
		"tables": {
			"nConta": "Número da conta",
			"nome": "Nome",
			"saldoAcumulado": "Saldo acumulado",
			"saldoMovAberto": "Saldo mov. aberto",
			"tipo": "Tipo",
			"saldoAcumuladoAte": "Saldo acumulado até período",
			"saldoExtratoAte": "Saldo extrato até período",
			"saldoExtrato": "Saldo extrato",
			"fase": "Fase",
			"conta": "Conta",
			"periodo": "Período",
			"diario": "Diário",
			"docInterno": "Nº doc. interno",
			"sel": "Sel.",
			"origem": "Origem"
		},
		"actions": {
			"reconstruir": "Reconstruir"
		}
	},
	"zonas": {
		"title_detail": "Zona {{id}}",
		"title_new": "Nova Zona",
		"title_plural": "Zonas",
		"pesquisa": "Pesquisar",
		"saved": "Zona {{id}}, guardada com sucesso.",
		"error": "Zona {{id}}, não pode ser guardada. Por favor verifique os campos.",
		"deleted": "Zona {{id}}, apagada com sucesso",
		"fields": {
			"nZona": "Cód.",
			"nZonaPlaceholder": "Código da zona",
			"nome": "Nome",
			"nomePlaceholder": "Nome da zona"
		}
	}
}