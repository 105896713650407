import {ENTITY_NAME_EMPRESAS_ERP, IEmpresaExtended} from './empresas.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_EMPRESAS_ERP: IEntityDefinition<IEmpresaExtended> = {
  name: ENTITY_NAME_EMPRESAS_ERP,
  url: 'empresas',
  roles: [ROLE.ERP, ROLE.CONTABILIDADE],
  pageTitle: 'global.menu.empresa',
  metadata: {
    keyName: 'nempresa',
    fields: [
      {
        name: 'nempresa',
        width: '120px',
        caption: 'empresa.fields.nempresa',
        placeholder: 'empresa.fields.nempresaPlaceholder',
        validators: {required: true, maxlength: 20}
      },
      {name: 'nome', caption: 'empresa.fields.nome', placeholder: 'empresa.fields.nomePlaceholder', validators: {required: true, maxlength: 50}}
    ],
    order: 'nempresa',
    searchFields: 'nempresa,nome'
  },
  autocomplete: {
    rowTemplate: '{{nempresa}} - {{nome}}',
    output: 'nome',
    searchFields: 'nempresa,nome'
  },
  actions: {
    new: false,
    detail: false,
    edit: false,
    delete: false,
    search: false
  }
};
