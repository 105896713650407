<div class="rend-habit-perm">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="rendHbitPerm"
    [dataSource]="dataGridDefinition.dataSource"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()">
    <div *dxTemplate="let item of 'cellTemplateBtnsRendHbitPerm'">
      <div class="btn-group">
        <pl-tooltip [config]="{text: 'global.btn.edit', tooltipClass: 'tooltip-warning', placement: 'left', container: 'body'}">
          <button type="button" *plTooltipContent class="btn btn-warning btn-xs" [click]="fnEditRendHabitPerm(item.data)" plPromise>
            <i class="fa fa-fw fa-pencil"></i>
          </button>
        </pl-tooltip>
        <pl-tooltip [config]="{text: 'global.btn.delete', tooltipClass: 'tooltip-danger', placement: 'left', container: 'body'}">
          <button type="button" *plTooltipContent class="btn btn-danger btn-xs" [click]="fnDeleteRendHabitPerm(item.data)" plPromise>
            <i class="fa fa-fw fa-trash"></i>
          </button>
        </pl-tooltip>
      </div>
    </div>
  </dx-data-grid>
</div>
