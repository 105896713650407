import type {AbstractControl, FormGroupDirective} from '@angular/forms';
import type {IPlEditGroup} from '../group/edit.group.interface';
import type {IPlFormOptions} from './form.interface';

export abstract class PlEditFormToken {
  public properties: IPlFormOptions | any;

  public abstract addGroup(group: IPlEditGroup): void;

  public abstract removeGroup(group: IPlEditGroup): void;

  public abstract valueChanged(value: unknown, field: string): void;

  public abstract addControl(name: string, control: AbstractControl): void;

  public abstract removeControl(name: string): void;

  public abstract get ngFormInstance(): FormGroupDirective;

  public abstract set ngFormInstance(form: FormGroupDirective);
}
