import {NgModule} from '@angular/core';
import {PlFormErrorNavigationDirective} from './formerror/form.error.navigation.directive';
import {PlFormNavigationDirective} from './form/form.navigation.directive';
import {PlNavigationDirective} from './navigation.directive';

export * from './form/form.navigation.interface';
export * from './form/form.navigation.directive';
export * from './formerror/form.error.navigation.directive';
export * from './navigation.directive';

export const DECLARATIONS = [
  PlFormErrorNavigationDirective,
  PlFormNavigationDirective,
  PlNavigationDirective
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlNavigationModule {
}
