import {HttpResponse} from '@angular/common/http';
import {EMBEmissorasSTR, MODULE_NAME_MB_CONFIGS} from './mbConfigs.module.interface';
import {IJsonConfigEmissora} from './jsonMBConfigs.module.interface';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {MBConfigsModuleComponent} from './components/mbConfigs.module.component';
import {MBConfigsService} from './mbConfigs.module.service';
import {ROLE} from '../../services/role.const';

export const MODULE_MB_CONFIGS: IModuleDefinition = {
  name: MODULE_NAME_MB_CONFIGS,
  state: {
    url: `/${MODULE_NAME_MB_CONFIGS}`,
    component: MBConfigsModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pluginsRoles: [ROLE.ERPADVANCED],
      sidebarTitle: 'portals.sidebar.modules.mbconfigs'
    },
    resolve: [
      {
        provide: 'mbConfigs',
        deps: [MBConfigsService],
        useFactory: (mbConfigsService: MBConfigsService): Promise<IJsonConfigEmissora> => {
          return mbConfigsService.getConfig(EMBEmissorasSTR.EasyPayOnline).then((response: HttpResponse<IJsonConfigEmissora>) => response.body);
        }
      }
    ]
  }
};
