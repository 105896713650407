import {produce} from 'immer';
import {merge} from 'lodash-es';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {IPlCookiesConsent, IPlCookiesConsentConfig} from './cookiesconsent.interface';

const DEFAULT_CONSENT: IPlCookiesConsentConfig = produce(
  {
    consent: {
      consented: false,
      lastUpdatedOn: '',
      necessary: {
        items: []
      },
      preferences: {
        accepted: false,
        items: []
      },
      statistics: {
        accepted: false,
        items: []
      },
      marketing: {
        accepted: false,
        items: []
      },
      unclassified: {
        accepted: false,
        items: []
      }
    },
    hideHeader: false,
    hideNecessary: false,
    hidePreferences: false,
    hideStatistics: false,
    hideMarketing: false,
    hideUnclassified: false,
    hideDetails: false,
    hideFooter: false
  },
  () => undefined
);

@Injectable({
  providedIn: 'root'
})
export class PlCookiesConsentService implements OnDestroy {
  private readonly _subjectConfig: BehaviorSubject<IPlCookiesConsentConfig>;
  private _observableConfig: Observable<IPlCookiesConsentConfig>;
  private _observableConsent: Observable<IPlCookiesConsent>;

  constructor() {
    this._subjectConfig = new BehaviorSubject<IPlCookiesConsentConfig>(undefined);
    this.setConfig(undefined);
  }

  public ngOnDestroy(): void {
    this._subjectConfig.complete();
  }

  public setConfig(value: Partial<IPlCookiesConsentConfig>): void {
    this._subjectConfig.next(
      produce(DEFAULT_CONSENT, (config) => {
        merge(config, this._subjectConfig.value, value);
      })
    );
  }

  public setConsent(value: Partial<IPlCookiesConsent>): void {
    this.setConfig({consent: value});
  }

  public get config(): Observable<IPlCookiesConsentConfig> {
    if (!this._observableConfig) {
      this._observableConfig = this._subjectConfig.asObservable();
    }
    return this._observableConfig;
  }

  public get consent(): Observable<IPlCookiesConsent> {
    if (!this._observableConsent) {
      this._observableConsent = this.config.pipe(map((config: IPlCookiesConsentConfig) => config.consent));
    }
    return this._observableConsent;
  }
}
