import {ETipModelo30} from './modelo30.module.interface';
import {TDate} from '../../../../common/dates';

export enum ERadioTipoDeclaracao {
  Primeira = 1,
  Substituicao
}

export interface IJsonModelo30Params {
  tipmodelo: ETipModelo30;
  periodo: string;
  tipodeclaracao: ERadioTipoDeclaracao;
}

export interface IJsonJustosImpedimentos {
  cod: string;
  descricao: string;
}

export interface IJsonRegimesTributacao {
  cod: string;
  descricao: string;
}

export interface IJsonModelo30Q8List {
  _duplicated?: boolean;
  nifpt: string;
  nif: string;
  codPais: string;
  nifemit: string;
  partcapd: number;
  partcaps: number;
  renditipo: string;
  codtributacao: string;
  taxatributacao: number;
  rendimentovalor: number;
  montanteimpostoretido: number;
  nguiam30: string;
  extPocLigaID: string;
}

export interface IJsonModelo30Q7List {
  guiaPagamento: string;
  valorTotal: number;
}

export interface IJsonModelo30 {
  q1c01: number;
  q2c02: number;
  q2c02a: string;
  q2c02b: TDate;
  q3c03: number;
  q3c03a: number;
  q4c04: string;
  q5c05: number;
  q6c06: number;
  q6c07: number;
  q6c08: number;
  q6c09: number;
  q6c10: number;
  q6c11: number;
  q6c12: number;
  q6c13: number;
  q6c14: number;
  q6c40: number;
  q6c41: number;
  q6c16: number;
  q6c17: number;
  codRepFinancas: number;
  q7List: Array<IJsonModelo30Q7List>;
  q8List: Array<IJsonModelo30Q8List>;
}

export interface IJsonContribuintesNaoPtComRetencao {
  nif: string;
  codPais: string;
  taxatributacao: number;
  rendimentovalor: number;
  montanteimpostoretido: number;
  nguiam30: string;
  extPocLigaID: string;
}

export interface IJsonContribuintesNaoPtSemRetencao {
  nif: string;
  codPais: string;
  rendimentovalor: number;
  extPocLigaID: string;
}
