<div class="rhagencias-conta-modal entity-detail-form">
  <pl-form plPromise>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'rhagencias.modal.conta.title'"></h5>
    </div>

    <div class="modal-body">
      <pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.conta.fields.banco'"></label>
          <edit>
            <div class="form-control-align">{{ model.codBanco }} - {{ model.nomeBanco }}</div>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.conta.fields.agencia'"></label>
          <edit>
            <div class="form-control-align">{{ model.codAgencia }} - {{ model.nomeAgencia }}</div>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.conta.fields.iban'"></label>
          <edit>
            <pl-edit type="text" attrName="iban" [(model)]="model.iban" [properties]="{disabled: isUpdate, validators: {required: {value: !isUpdate}}}" plAutoFocus></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.conta.fields.descricao'"></label>
          <edit>
            <pl-edit type="text" attrName="descricao" [(model)]="model.descricao" [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <label [translate]="'rhagencias.modal.conta.fields.validaIBAN'"></label>
        <edit>
          <pl-edit type="checkbox" attrName="validaIBAN" [(model)]="model.validaIBAN"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.conta.fields.swift'"></label>
          <edit>
            <pl-edit type="text" attrName="swift" [(model)]="model.swift"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.conta.fields.status'"></label>
          <edit>
            <pl-edit type="estadoconta" attrName="status" [(model)]="model.status"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.conta.fields.debitoDirectoID'"></label>
          <edit>
            <pl-edit type="text" attrName="debitoDirectoID" [(model)]="model.debitoDirectoID"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.conta.fields.gestorBanco'"></label>
          <edit>
            <pl-edit type="text" attrName="gestorBanco" [(model)]="model.gestorBanco"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.conta.fields.nContaPoc'"></label>
          <edit>
            <entity-autocomplete
              entity="pocs"
              attrName="nContaPoc"
              [model]="model"
              (selectedKeyChange)="model.nContaPoc = $event"
              (evtSelectedDescriptionChanged)="model.nomeNconta = $event"
              [fieldsMap]="{nConta: 'nContaPoc', nome: 'nomeNconta'}">
            </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.conta.fields.nProjecto'"></label>
          <edit>
            <pl-edit type="text" attrName="nProjecto" [(model)]="model.nProjecto"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.conta.fields.codPais'"></label>
          <edit>
            <entity-autocomplete
              entity="paises"
              attrName="codPais"
              [model]="model"
              (selectedKeyChange)="model.codPais = $event"
              (evtSelectedDescriptionChanged)="model.nomePais = $event"
              [properties]="{validators: {required: {value: true}}}"
              [fieldsMap]="{nomePais: 'nomePais'}">
            </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'rhagencias.modal.conta.fields.nContaEmprestimos'"></label>
          <edit>
            <entity-autocomplete
              entity="pocs"
              attrName="nContaEmprestimos"
              [model]="model"
              (selectedKeyChange)="model.nContaEmprestimos = $event"
              (evtSelectedDescriptionChanged)="model.nomeEmprestimos = $event"
              [fieldsMap]="{nConta: 'nContaEmprestimos', nome: 'nomeEmprestimos'}">
            </entity-autocomplete>
          </edit>
        </pl-group>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button" plPromise>
      <pl-button klass="btn-success action-save" (evtClicked)="close()" data-focus><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

      <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
    </div>
  </pl-form>
</div>
