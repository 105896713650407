import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_TIPOS_DECLARACAO} from './tiposDeclaracao.datasource.interface';
import {ERendiTipoDeclaracao} from '../../entities/rendis/jsonRendi.entity.interface';

export const DATA_SOURCE_TIPOS_DECLARACAO: IDataSource<ERendiTipoDeclaracao> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_DECLARACAO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ERendiTipoDeclaracao.Modelo10, name: 'tiposDeclaracao.data.modelo10'},
    {value: ERendiTipoDeclaracao.Modelo22, name: 'tiposDeclaracao.data.modelo22'},
    {value: ERendiTipoDeclaracao.Modelo30, name: 'tiposDeclaracao.data.modelo30'},
    {value: ERendiTipoDeclaracao.Modelo39, name: 'tiposDeclaracao.data.modelo39'}
  ]
});
