import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {IIesStatus} from './ies.module.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class IesService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${_apiService.path.restapi}/ies`;
  }

  public downloadFile(): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({url: `${this._path}/download`, responseType: 'blob'});
  }

  public getStatus(): TServiceResponse<IIesStatus> {
    return this._apiService.get<IIesStatus>({url: `${this._path}/status`});
  }

  public processar(ano: number, anexos: Array<number>): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/processar`,
      params: {ano: ano},
      body: anexos
    });
  }

  public cancelProcess(): TServiceResponse<void> {
    return this._apiService.post({url: this.cancelProcessUrl()});
  }

  public cancelProcessUrl(): string {
    return `${this._path}/cancel`;
  }
}
