import {IApiRequestConfig, TServiceResponse} from '../../services/api/api.service.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityDetailParams} from '../../../common/utils/entity.state.utils';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonPeriodosReembolsoIva, IJsonPeriodosRelacaoReembolsoIva, IJsonReembolsoIva} from './jsonReembolsoIva.entity.interface';

export enum EReembolsosIvaTipoCampo {
  Normal,
  OperacoesEfetuadasNoEstrangeiro,
  OutrasOperacoesIsentas
}

export interface IReembolsosIvaEntityService extends IEntityService<IJsonReembolsoIva> {
  getPeriodosDeclaracao(config?: IApiRequestConfig): TServiceResponse<IJsonPeriodosReembolsoIva>;

  getPeriodosRelacao(periodoDeclaracao: string, config?: IApiRequestConfig): TServiceResponse<IJsonPeriodosRelacaoReembolsoIva>;

  deleteDocument(id: number, config?: IApiRequestConfig): TServiceResponse<void>;
}

export interface IReembolsosIvaEntity extends IEntityDefinition<IJsonReembolsoIva, IReembolsosIvaEntityService> {}

export interface IReembolsosIvaDetailParams extends IEntityDetailParams {
  id: string;
  model?: IJsonReembolsoIva;
  periodoRelacao?: string;
  documentIndex?: number;
  typeTab?: string;
  typePill?: string;
}

export interface IReembolsosIvaPeriodoRelacao {
  value: string;
  name: string;
}

export const ENTITY_NAME_REEMBOLSOS_IVA = 'reembolsosiva';
