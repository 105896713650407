import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_OPERACAO} from './tipoOperacao.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPO_OPERACAO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_OPERACAO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'tipoOperacao.data.notApplicable'},
    {value: 1, name: 'tipoOperacao.data.active'},
    {value: 2, name: 'tipoOperacao.data.passive'},
    {value: 3, name: 'tipoOperacao.data.regularizationFavorState'},
    {value: 4, name: 'tipoOperacao.data.regularizationFavorCompany'}
  ]
});
