export enum EModeloTributAutonoma {
  IRC,
  IRCMadeira,
  IRS,
  IRCAcores
}

export enum ETributAutonomaIRS {
  NaoSeAplica,
  DepesasNaoDocumentadas,
  EncargosDespesasRepresentacaoEViaturasMaior,
  EncargosViaturasGPLMaior,
  EncargosViaturasHibridasMaior,
  EncargosAutomoveisMaiorIgual,
  EncargosViaturasGPLMaiorIgual,
  EncargosViaturasHibridasMaiorIgual,
  ImportanciasPagasDevidasTitulo,
  EncargosDedutiveisRelativosDespesas
}

export enum ETributAutonomaIRC {
  //Continente
  NaoSeAplica,
  DespesasNaoDocumentadas,
  DespesasRepresentacao,
  EncargosDedutiveisAjudasCusto,
  ImportanciasPagasDevidasEntidades,
  LucrosDistribuidosEntidadesSujeitas,
  EncargosViaturasAntigaRedacao,
  EncargosViaturas,
  IndemnizacoesCessacaoFuncoes,
  GastosEncargosRelativos,
  EncargosNaoDedutiveis,
  EncargosViaturasMenor,
  EncargosViaturasMaiorIgualMenor,
  EncargosViaturasMaiorIgual,
  EncargosViaturasHibridasMenor,
  EncargosViaturasHibridasMaiorIgualMenor,
  EncargosViaturasHibridasMaiorIgual,
  EncargosViaturasGPLMenor,
  EncargosViaturasGPLMaiorIgualMenor,
  EncargosViaturasGPLMaiorIgual,
  //Madeira
  NaoSeAplicaMadeira,
  DespesasNaoDocumentadasMadeira,
  DespesasRepresentacaoMadeira,
  EncargosDedutiveisAjudasCustoMadeira,
  ImportanciasPagasDevidasEntidadesMadeira,
  LucrosDistribuidosEntidadesSujeitasMadeira,
  IndemnizacoesCessacaoFuncoesMadeira,
  GastosEncargosRelativosMadeira,
  EncargosNaoDedutiveisMadeira,
  EncargosViaturasMenorMadeira,
  EncargosViaturasMaiorIgualMenorMadeira,
  EncargosViaturasMaiorIgualMadeira,
  EncargosViaturasHibridasMenorMadeira,
  EncargosViaturasHibridasMaiorIgualMenorMadeira,
  EncargosViaturasHibridasMaiorIgualMadeira,
  EncargosViaturasGPLMenorMadeira,
  EncargosViaturasGPLMaiorIgualMenorMadeira,
  EncargosViaturasGPLMaiorIgualMadeira,
  //Açores
  NaoSeAplicaAcores,
  DespesasNaoDocumentadasAcores,
  DespesasRepresentacaoAcores,
  EncargosDedutiveisAjudasCustoAcores,
  ImportanciasPagasDevidasEntidadesAcores,
  LucrosDistribuidosEntidadesSujeitasAcores,
  IndemnizacoesCessacaoFuncoesAcores,
  GastosEncargosRelativosAcores,
  EncargosViaturasMenorAcores,
  EncargosViaturasMaiorIgualMenorAcores,
  EncargosViaturasMaiorIgualAcores,
  EncargosViaturasHibridasMenorAcores,
  EncargosViaturasHibridasMaiorIgualMenorAcores,
  EncargosViaturasHibridasMaiorIgualAcores,
  EncargosViaturasGPLMenorAcores,
  EncargosViaturasGPLMaiorIgualMenorAcores,
  EncargosViaturasGPLMaiorIgualAcores,
  // Continente
  EncargosViaturasHibridasNaoCumpreRequisitos,
  EncargosViaturasGNVNaoCumpreRequisitos,
  //Madeira
  EncargosViaturasHibridasNaoCumpreRequisitosMadeira,
  EncargosViaturasGNVNaoCumpreRequisitosMadeira,
  //Açores
  EncargosViaturasHibridasNaoCumpreRequisitosAcores,
  EncargosViaturasGNVNaoCumpreRequisitosAcores
}

export interface IJsonTributAutonomaHeader {
  modeloTributAutonoma: EModeloTributAutonoma;
  ano: number;
  contasDe: string;
  contasAte: string;
  prejuizoFiscal: boolean;
  anosSource: Array<number>;
}

export interface IJsonTributAutonoma {
  nConta: string;
  nome: string;
  contaDesc: string;
  taxa: number;
  taxaAgravada: number;
  descricao: string;
  tributAutonoTaxasID: string;
  codTributAutonoma: number;
}

export interface IJsonTributAutonoTaxas {
  tributAutonoTaxasID: string;
  codTributAutonoma: number;
  ano: number;
  modelo: number;
  taxa: number;
  taxaAgravada: number;
  descricao: string;
}

export interface IJsonTributAutonomaSearch {
  tributAutonoTaxas: Array<IJsonTributAutonoTaxas>;
  tributAutonoma: Array<IJsonTributAutonoma>;
}

export interface IJsonTributAutonomaSave {
  tributAutonoHeader: IJsonTributAutonomaHeader;
  tributAutonomaChanged: Array<IJsonTributAutonoma>;
}

export interface IJsonTributAutonoAnaliseHeader {
  contasDe: string;
  contasAte: string;
  periodoDe: string;
  nomePeriodoDe: string;
  periodoAte: string;
  nomePeriodoAte: string;
  prejuizoFiscal: boolean;
  modeloTributAutonoma: EModeloTributAutonoma;
}

export interface IJsonTributAutonomaAnalise {
  nConta: string;
  nome: string;
  tributAutonoTaxasID: string;
  descricao: string;
  codTributAutonoma: number;
  taxaTotal: number;
  taxaAgravada: number;
  saldoGeral: number;
  valorTributAutonoma: number;
}
