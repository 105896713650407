import {ECalendarioTipoDia, ECalendarioTipoDiaStr} from '../calendarios/jsonCalendarios.entity.interface';
import {IJsonPRHAbonos, IJsonPRHMarcarAbonos} from '../../interfaces/jsonPRHAbonos.interface';
import {IJsonPRHFaltas, IJsonPRHMarcarFaltas} from '../../interfaces/jsonPRHFaltas.interface';
import {IJsonPRHFerias, IJsonPRHMarcarFerias} from '../../interfaces/jsonPRHFerias.interface';
import {TDate} from '../../../common/dates';

export interface IJsonDGEMP {
  codEmp: number;
  nome: string;
  apelido: string;
  morada: string;
  codPostal: string;
  nomeLocalida: string;
  email: string;
  nTelefone: string;
  telemovelPart1: string;
  codServicoPRH: number;
  nomeServicoPRH: string;
  categoriaActual: string;
  nomeCategoria: string;
  rua: string;
  ativo: boolean;
  numContrib: string;
}

export interface IJsonDGEMPDetail {
  codEmp: number;
  numDiasFerias: number;
  saldoFerias: number;
  numHorasSemanais: number;
  codLocalTrabalho: number;
  localTrabalho: string;
  codTipoHorario: string;
  tipoHorario: string;
  codTipoContrato: string;
  tipocontrato: string;
  validadeContrato: TDate;
  nCCusto: string;
  centroCusto: string;
  codReparticao: number;
  reparticao: string;
}

export interface IJsonDGEMPFerias extends IJsonDGEMP {
  horasDia: number; // horas por dia em segundos
  nDiasSFerias: number;
  ndiasFeriasMarcados: number;
  nDiasSFeriasTotal: number;
  nDiasSFeriasIntegradas: number;
  nDiasFeriasMarcadasAprovadas: number;
}

export interface IJsonDGEMPCalendario {
  data: TDate;
  tipoDia: ECalendarioTipoDia;
  tipoDiaStr: ECalendarioTipoDiaStr;
}

export interface IJsonDGEMPMarcacoes {
  dgEmp: IJsonDGEMPFerias;
  listaFeriasMarcadas: Array<IJsonPRHMarcarFerias>;
  listaFaltasMarcadas: Array<IJsonPRHMarcarFaltas>;
  listaAbonosMarcadas: Array<IJsonPRHMarcarAbonos>;
  listaFeriasJaIntegradas: Array<IJsonPRHFerias>;
  listaFaltasJaIntegradas: Array<IJsonPRHFaltas>;
  listaAbonosJaIntegradas: Array<IJsonPRHAbonos>;
  listaCalendario: Array<IJsonDGEMPCalendario>;
  numFeriasPendentes: number;
  numFaltasPendentes: number;
  numAbonosPendentes: number;
  mesesPendentes: Array<number>;
}

export interface IEmpregado extends IJsonDGEMP {
  haveUser?: boolean;
}

export interface IJsonProcessamentoEmpregadoSalarios {
  nProcessamento: number;
  dataProcessamento: TDate;
  nRecibo: number;
  lido: boolean;
}

export interface IJsonProcessamentoSalarios {
  nProcessamento: number;
  tipo: number;
  dataProcessamento: TDate;
  descricao: string;
  tipoProceDesc: string;
}

export interface IJsonProcessamentoSegurancaSocial {
  ano: number;
  mes: number;
}

export interface IJsonDGEmpPorAprovarServico {
  codServico: number;
  nome: string;
  colaboradores: Array<number>;
}

export interface IJsonDGEMPPorAprovar {
  listaServicosFerias: Array<IJsonDGEmpPorAprovarServico>;
  listaServicosFaltas: Array<IJsonDGEmpPorAprovarServico>;
  listaServicosAbonos: Array<IJsonDGEmpPorAprovarServico>;
}

export interface IJsonDGEMPRefeicoesPorAprovar {
  codPRHRefAprova: number;
  codPRHRelEmentasOld: number;
  codPRHRelEmentas: number;
  estado: number;
  codEmp: number;
  nomeEmp: string;
  dataAprova: TDate;
  nomeLocal: string;
  dataRefeicao: TDate;
  nomeTipoRefeicao: string;
  operacao: string;
}

export interface IJsonEstadosProcessamento {
  gravado: boolean;
  reciboImpresso: boolean;
  processadoSegSocial: boolean;
  integradoContabilidade: boolean;
}

export function evaluateDGEMPName(dgemp: IJsonDGEMP): string {
  let firstName: string = dgemp.nome;
  const index: number = dgemp.nome.indexOf(' ');
  if (index > 0) {
    firstName = firstName.substring(0, index);
  }
  return `${firstName} ${dgemp.apelido}`.trim();
}
