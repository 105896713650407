<div [ngClass]="entityClassName">
  <config-options
    *ngIf="grupo.name === 'clientes' || grupo.name === 'fornecedores'"
    [instanceName]="configOptionsInstanceName"
    [groupName]="configOptionsGroupName"
    (configChange)="onConfigChange()"
    [toolbarInstanceName]="instanceName">
  </config-options>

  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [toolbarInstanceId]="instanceName"
    [dataGridState]="dataGridState"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [properties]="listOptions"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridOnInitialized)="onDataGridInitialized($event)"
    (evtDataGridOnContentReady)="onDataGridOnContentReady()"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
  </entity-list>
</div>
