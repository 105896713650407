import {Injectable} from '@angular/core';
import {CONTABILIDADE_PREDEFINIDOS_DEFAULT_ORDEM_COLUNAS, CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES} from '../preDefinidosContab.module.interface';
import {EDebitoCredito} from '../../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {
  EPreDefinidoContabOrigemDataDoc,
  EPreDefinidoContabOrigemDataLancamento,
  EPreDefinidoContabOrigemDataVenc,
  EPreDefinidoContabTipoConta,
  EPreDefinidoContabTipoValorBaseTributavelLinha,
  EPreDefinidoContabTipoValorCabDescricao,
  EPreDefinidoContabTipoValorCabDiario,
  EPreDefinidoContabTipoValorCabPeriodo,
  EPreDefinidoContabTipoValorDescricao,
  EPreDefinidoContabTipoValorDescritivo,
  EPreDefinidoContabTipoValorLinha,
  EPreDefinidoContabTipoValorMoeda,
  EPreDefinidoContabTipoValorPerguntaBTIntroduzido,
  IJsonPreDefinidoContab,
  IJsonPreDefinidoContabLinha
} from '../jsonPreDefinidosContab.module.interface';

@Injectable({
  providedIn: 'root'
})
export class ContabilidadePredefinidoService {
  public emptyPredefinido(): IJsonPreDefinidoContab {
    return {
      preDefinidosID: undefined,
      descricao: '',
      revision: undefined,
      cgBanking: false,
      isGeneric: false,
      cabecalho: {
        periodo: {
          visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES},
          tipoValorPeriodo: EPreDefinidoContabTipoValorCabPeriodo.TabelaEmpresa
        },
        diario: {
          visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES},
          tipoValorDiario: EPreDefinidoContabTipoValorCabDiario.NaoDefinido,
          valorFixo: undefined
        },
        nDocInterno: {
          visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, readonly: true, tabStop: false}
        },
        dataLancamento: {
          visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
          origem: EPreDefinidoContabOrigemDataLancamento.UltimoDiaPeriodo
        },
        dataDoc: {
          visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES},
          origem: EPreDefinidoContabOrigemDataDoc.NaoDefinido
        },
        dataVencimento: {
          visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
          origem: EPreDefinidoContabOrigemDataVenc.NaoDefinido
        },
        nContribuinte: {
          visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false}
        },
        nDocExterno: {
          visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES},
          incrementaAutomaticamente: false,
          valorFixo: undefined
        },
        descricao: {
          visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
          tipoValorDescricao: EPreDefinidoContabTipoValorCabDescricao.NaoDefinido,
          valorFixo: undefined
        },
        descritivo: {
          visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
          tipoValorDescritivo: EPreDefinidoContabTipoValorDescritivo.ObtidoPorUltimoDocumento,
          valorFixo: undefined
        },
        moeda: {
          visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
          tipoValorMoeda: EPreDefinidoContabTipoValorMoeda.NaoDefinido,
          valorFixo: undefined
        }
      },
      linhas: [],
      empresasSelecionadas: [],
      ordemColunas: CONTABILIDADE_PREDEFINIDOS_DEFAULT_ORDEM_COLUNAS.slice()
    };
  }

  public emptyPredefinidoLine(): IJsonPreDefinidoContabLinha {
    return {
      conta: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES},
        tipoConta: EPreDefinidoContabTipoConta.NaoDefinido,
        valorFixo: undefined,
        dC: EDebitoCredito.Debito,
        radical: '',
        baseTributavelProperties: {codRepCC: 0},
        contaCorrenteProperties: {temImputacao: false, vaiImputarALinha: 0}
      },
      valor: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES},
        tipoValor: EPreDefinidoContabTipoValorLinha.Pergunta,
        valorFixo: undefined,
        valorFormula: undefined,
        colocaValorEFaturaDocDigital: true,
        baseTributavelProperties: {
          tipoValorPerguntaIntroduzido: EPreDefinidoContabTipoValorPerguntaBTIntroduzido.None,
          tipoValorBaseTributavel: EPreDefinidoContabTipoValorBaseTributavelLinha.BaseMaisIva,
          importEFaturaContaUnicaSemDeducao: false,
          importEFaturaSuportaTaxaIsenta: false,
          importEFaturaValorImpSelo: false,
          importFRVSuportaTaxaIsenta: false,
          importFRVValorImpSelo: false
        }
      },
      valorIva: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, readonly: true, tabStop: false}
      },
      descricao: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
        tipoValorDescricao: EPreDefinidoContabTipoValorDescricao.NaoDefinido,
        valorFixo: undefined,
        valorFormula: undefined
      },
      nContribuinte: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false}
      },
      nDocExterno: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
        incrementaAutomaticamente: false,
        valorFixo: undefined
      },
      descritivo: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
        tipoValorDescritivo: EPreDefinidoContabTipoValorDescritivo.ObtidoPorUltimoDocumento,
        valorFixo: undefined
      },
      dataDoc: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
        origem: EPreDefinidoContabOrigemDataDoc.NaoDefinido
      },
      dataVencimento: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
        origem: EPreDefinidoContabOrigemDataVenc.NaoDefinido
      },
      nomeConta: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false, readonly: true, tabStop: false}
      },
      fake: false
    };
  }
}
