import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {produce} from 'immer';
import {Injectable, OnDestroy} from '@angular/core';
import {StateService} from '@uirouter/core';
import {copy, getPathValue, PlAlertService, PlFormatService, skipIf} from 'pl-comps-angular';
import {AppService} from '../app/app.service';
import {CG_DEFAULT_CONFIGURATIONS, ICGConfigurations, IWritableCGConfigurations} from './config.service.interface';
import {DEFAULT_CURRENCY_CODE} from '../../../config/constants';
import {EAppLaunchMode} from '../../../common/site';
import {ENTITY_NAME_CONFIGS_ERP} from '../../entities/configserp/configsErp.entity.interface';
import {EntityServiceBuilder} from '../entity/entity.service.builder';
import {FeatureFlagService} from '../featureflag/featureflag.service';
import {IAppStatus} from '../app/app.service.interface';
import {IEntityService} from '../entity/entity.service.interface';
import {IJsonConfigERP} from '../../entities/configserp/jsonConfigERP.entity.interface';
import {STATE_NAME_EMPRESA_BLOQUEADA} from '../../states/account/empresabloqueada/empresabloqueada.state.interface';
import {THttpQueryResponse} from '../api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigService implements OnDestroy {
  private readonly _subjectConfigurations: BehaviorSubject<ICGConfigurations>;
  private readonly _subjectRaw: BehaviorSubject<Array<IJsonConfigERP>>;
  private readonly _subscriptionStatus: Subscription;
  private readonly _subscriptionConfigurations: Subscription;

  private _observableConfigurations: Observable<ICGConfigurations>;
  private _serviceConfigsErp: IEntityService<IJsonConfigERP>;
  private _observableRaw: Observable<Array<IJsonConfigERP>>;
  private _promise: Promise<ICGConfigurations>;
  private _raw: Array<IJsonConfigERP>;
  private _hybridMode: boolean;
  private _fetched: boolean;

  constructor(
    private readonly _stateService: StateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plFormatService: PlFormatService,
    private readonly _appService: AppService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _featureFlagService: FeatureFlagService
  ) {
    this._subjectConfigurations = new BehaviorSubject<ICGConfigurations>(CG_DEFAULT_CONFIGURATIONS);
    this._subjectRaw = new BehaviorSubject<Array<IJsonConfigERP>>([]);
    this._fetched = false;
    this._subscriptionStatus = this._appService.status().subscribe((status: IAppStatus) => {
      this._hybridMode = status.launchMode === EAppLaunchMode.Hybrid || status.launchMode === EAppLaunchMode.HybridPartial;
    });
    this._subscriptionConfigurations = this.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      this._featureFlagService.updateContext({licId: configurations.licenca.licId});
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionStatus.unsubscribe();
    this._subscriptionConfigurations.unsubscribe();
  }

  public getConfigurations(force: boolean = false, silent: boolean = true): Promise<ICGConfigurations> {
    if (!force && this._fetched) {
      return Promise.resolve(this._subjectConfigurations.value);
    }
    return this.refresh(silent);
  }

  public refresh(silent: boolean = false): Promise<ICGConfigurations> {
    if (!this._promise) {
      this._fetched = false;
      if (!this._serviceConfigsErp) {
        this._serviceConfigsErp = this._entityServiceBuilder.build<IJsonConfigERP>(ENTITY_NAME_CONFIGS_ERP);
      }
      this._promise = this._serviceConfigsErp
        .query()
        .then((response: THttpQueryResponse<IJsonConfigERP>) => {
          this._fetched = true;
          this._raw = <Array<IJsonConfigERP>>(<unknown>response.body);
          this._subjectRaw.next(this._raw);
          const configurations: ICGConfigurations = this._parseRawConfigurations(this._raw);
          this._plFormatService.setFormat({currencyCode: configurations.empresa.abreviaturaMoeda || DEFAULT_CURRENCY_CODE});
          this._subjectConfigurations.next(configurations);
          if (!silent) {
            this.checkEmpresa();
          }
          return this._subjectConfigurations.value;
        })
        .finally(() => {
          this._promise = undefined;
        });
    }
    return this._promise;
  }

  public configurationsAsObservable(): Observable<ICGConfigurations> {
    if (!this._observableConfigurations) {
      this._observableConfigurations = this._subjectConfigurations.asObservable().pipe(skipIf(() => !this._fetched));
    }
    return this._observableConfigurations;
  }

  public rawAsObservable(): Observable<Array<IJsonConfigERP>> {
    if (!this._observableRaw) {
      this._observableRaw = this._subjectRaw.asObservable().pipe(skipIf(() => !this._fetched));
    }
    return this._observableRaw;
  }

  public setConfigurations(setState: (configurations: IWritableCGConfigurations) => void): ICGConfigurations {
    const updatedConfigurations: ICGConfigurations = produce(this._subjectConfigurations.value, setState);
    this._subjectConfigurations.next(updatedConfigurations);
    return updatedConfigurations;
  }

  public checkEmpresa(): boolean {
    if (this._subjectConfigurations.value.empresa.islocked && this._subjectConfigurations.value.empresa.motivoislocked !== this._subjectConfigurations.value.empresa.ignoreLocked) {
      this.setConfigurations((configurations: IWritableCGConfigurations) => {
        configurations.empresa.ignoreLocked = undefined;
      });
      this._stateService.go(STATE_NAME_EMPRESA_BLOQUEADA);
      return false;
    }
    if (!this._subjectConfigurations.value.empresa.islocked) {
      this.setConfigurations((configurations: IWritableCGConfigurations) => {
        configurations.empresa.ignoreLocked = undefined;
      });
    }
    return true;
  }

  public async checkConfiguracaoVerificada(): Promise<void> {
    if (this._hybridMode) {
      return;
    }
    let configurations: ICGConfigurations;
    if (!this._fetched) {
      configurations = await this.getConfigurations();
    } else {
      configurations = await this.refresh();
    }
    if (!this._hybridMode && !configurations.licenca.storeModePublic && !this._subjectConfigurations.value.empresa.verificacaoEfetuada) {
      this._plAlertService.error('configsErp.items.empresa.verificacaoNaoEfetuadaError');
    }
  }

  public lockEmpresa(motivo: string): void {
    this.setConfigurations((configurations: IWritableCGConfigurations) => {
      configurations.empresa.islocked = true;
      configurations.empresa.motivoislocked = motivo;
      configurations.empresa.ignoreLocked = undefined;
    });
  }

  public get configurations(): ICGConfigurations {
    return this._subjectConfigurations.value;
  }

  public get raw(): Array<IJsonConfigERP> {
    return copy<Array<IJsonConfigERP>>(this._raw);
  }

  private _parseRawConfigurations(rawConfigurations: Array<IJsonConfigERP>): ICGConfigurations {
    return produce(CG_DEFAULT_CONFIGURATIONS, (configurations: IWritableCGConfigurations) => {
      for (const rawConfiguration of rawConfigurations) {
        const classPath = rawConfiguration.name;
        const configuration = getPathValue(configurations, classPath);
        const configurationName = classPath.substring(classPath.lastIndexOf('.') + 1);
        configuration[configurationName] = rawConfiguration.value;
      }
    });
  }
}
