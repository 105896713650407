import {firstValueFrom} from 'rxjs';
import {Component, Injector, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {IPlTableCallback, IPlTableDefinition, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DocsPlataformaModalComponent} from '../modals/docsPlataforma.modal.component';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonPRHDocsPlata} from '../../../../entities/prhdocsplata/jsonPRHDocsPlata.entity.interface';
import {IJsonPRHPapel} from '../../../../entities/prhpapeis/jsonPRHPapel.entity.interface';
import {IJsonPRHServico} from '../../../../entities/prhservicos/jsonPRHServico.entity.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment from 'moment';
import {ENTITY_NAME_PRH_DOCS_PLATA, IPRHDocsPlata, IPRHDocsPlataEntityService} from '../../../../entities/prhdocsplata/pRHDocsPlata.entity.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {ENTITY_NAME_PRH_SERVICOS} from '../../../../entities/prhservicos/pRHServicos.entity.interface';
import {ENTITY_NAME_PRH_PAPEIS} from '../../../../entities/prhpapeis/pRHPapeis.entity.interface';

@Component({
  selector: 'colaboradores-docsplataforma',
  templateUrl: './docsPlataforma.module.component.html'
})
export class DocsPlataformaComponent extends ModuloComponent implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid<IPRHDocsPlata>;
  public readonly definitionDocsPlata: IPlTableDefinition;
  public readonly callback: IPlTableCallback;
  public readonly templateServicosSelected: string;
  public readonly templatePapeisSelected: string;
  public servicos: Array<IJsonPRHServico>;
  public servicosSelected: Array<IJsonPRHServico>;
  public papeis: Array<IJsonPRHPapel>;
  public papeisSelected: Array<IJsonPRHPapel>;

  private readonly _prhdocsplata: IPRHDocsPlataEntityService;
  private readonly _prhservicos: IEntityService<IJsonPRHServico>;
  private readonly _prhpapeis: IEntityService<IJsonPRHPapel>;
  private _docsPlata: Array<IPRHDocsPlata>;
  private _dataGridInstance: dxDataGrid<IPRHDocsPlata>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.templateServicosSelected = '{{codServico}} - {{nome}}';
    this.templatePapeisSelected = '{{codPapel}} - {{nome}}';
    this.dataGridDefinition = {
      columns: [
        {dataField: 'codPrhDocsPlata', dataType: 'string', caption: 'prhdocsplata.fields.cab.codprhdocsplata'},
        {dataField: 'titulo', dataType: 'string', caption: 'prhdocsplata.fields.cab.titulo'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn'}
      ],
      dataSource: new CustomStore({
        load: () => this._getListDocsPlata()
      }),
      masterDetail: {enabled: true, template: 'masterDetailTemplate'},
      remoteOperations: false
    };
    this.definitionDocsPlata = {
      fields: [
        {name: 'codPrhDocsPlata', width: '10px', caption: 'prhdocsplata.fields.cab.codprhdocsplata'},
        {name: 'titulo', width: '120px', caption: 'prhdocsplata.fields.cab.titulo'}
      ]
    };
    this.callback = {};
    this.servicos = [];
    this.servicosSelected = [];
    this.papeis = [];
    this.papeisSelected = [];
    this._prhdocsplata = this._entityServiceBuilder.build(ENTITY_NAME_PRH_DOCS_PLATA);
    this._prhservicos = this._entityServiceBuilder.build(ENTITY_NAME_PRH_SERVICOS);
    this._prhservicos.query().then((response) => {
      this.servicosSelected = response.body.list;
    });
    this._prhpapeis = this._entityServiceBuilder.build(ENTITY_NAME_PRH_PAPEIS);
    this._prhpapeis.query().then((response) => {
      this.papeisSelected = response.body.list;
    });
    this._docsPlata = [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'btnNewDocsPlata',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-plus-circle"></i>',
      class: 'btn-primary',
      caption: 'global.btn.new',
      click: () => this._docsPlataInternaModal()
    });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IPRHDocsPlata>): void {
    this._dataGridInstance = component;
  }

  public readonly fnGetDocsPlataList = (): Promise<void> => this._getDocsPlataList();

  public readonly fnGetListDocsPlata = (): Array<IPRHDocsPlata> => this._getListDocsPlata();

  public readonly fnDelete = (item: IJsonPRHDocsPlata) => (): Promise<void> => this._delete(item);

  private _docsPlataInternaModal(): Promise<void> {
    return this._cgModalService.show(DocsPlataformaModalComponent).then(() => {
      return this._getDocsPlataList();
    });
  }

  private _getDocsPlataList(): Promise<void> {
    let filterServicos = 'codServico=-1';
    let filterPapeis = 'codPapel=-1';

    for (const servicoSelected of this.servicosSelected) {
      filterServicos += `||codServico=${servicoSelected.codServico}`;
    }

    for (const papelSelected of this.papeisSelected) {
      filterPapeis += `||codPapel=${papelSelected.codPapel}`;
    }

    const dataFilter: string = moment().format('YYYY-MM-DD');
    return this._prhdocsplata
      .query({pesquisa: `(${filterServicos})&(${filterPapeis})&dataValidade>=${dataFilter}`})
      .then((response) =>
        Promise.all(
          response.body.list.map(async (doc: IJsonPRHDocsPlata): Promise<IPRHDocsPlata> => {
            const url: string = await firstValueFrom(this._prhdocsplata.downloadDocUrl(doc.codPrhDocsPlata));
            return {...doc, url: url};
          })
        ).then((docsPlata: Array<IPRHDocsPlata>) => {
          this._docsPlata = docsPlata;
          return this._dataGridInstance.refresh();
        })
      )
      .catch((reason: HttpErrorResponse) => {
        this._logger.error(reason);
        this._docsPlata = [];
      });
  }

  private _getListDocsPlata(): Array<IPRHDocsPlata> {
    for (const doc of this._docsPlata) {
      let servico = 'Todos';
      let papel = 'Todos';
      if (doc.codServico > -1) {
        for (const servicoSelected of this.servicosSelected) {
          if (servicoSelected.codServico === doc.codServico) {
            servico = servicoSelected.nome;
            break;
          }
        }
      }
      if (doc.codPapel > -1) {
        for (const papelSelected of this.papeisSelected) {
          if (papelSelected.codPapel === doc.codPapel) {
            papel = papelSelected.nome;
            break;
          }
        }
      }
      doc.servico = servico;
      doc.papel = papel;
    }
    return this._docsPlata;
  }

  private _delete(item: IJsonPRHDocsPlata): Promise<void> {
    return this._prhdocsplata
      .delete({id: item.codPrhDocsPlata})
      .then(() => {
        return this._getDocsPlataList();
      })
      .catch((error: HttpErrorResponse) => {
        this._logger.error(error);
        this._plAlertService.error('prheventosconfig.error');
      });
  }
}
