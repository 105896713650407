import {isFunction, isObject} from 'pl-comps-angular';
import {IJsonContabDigitalFile, IJsonContabDigitalFolder, IJsonContabDigitalFolderWithChildren} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';

export interface IContabilidadeDigitalGenericViewerFolderPath {
  name: string;
  folderID: string;
}

export interface IContabilidadeDigitalGenericViewerFolder extends IJsonContabDigitalFolder {
  files: Array<IContabilidadeDigitalGenericViewerFile>;
  folders: Array<IContabilidadeDigitalGenericViewerFolder>;
  parentFolder?: IContabilidadeDigitalGenericViewerFolder;
  promise?: Promise<void>;
}

export interface IContabilidadeDigitalGenericViewerFile extends IJsonContabDigitalFile {
  parentFolder?: IContabilidadeDigitalGenericViewerFolder;
}

export interface IContabilidadeDigitalGenericViewerCallback {
  getFolderItem?(folderIDOrItem: string | IContabilidadeDigitalGenericViewerFolder): IContabilidadeDigitalGenericViewerFolder;

  setSelectedFolder?(folderIDOrItem: string | IContabilidadeDigitalGenericViewerFolder): void;

  setSelectedFile?(docIDOrItem: string | IContabilidadeDigitalGenericViewerFile): void;

  refreshFolder?(folderIDOrItem: string | IContabilidadeDigitalGenericViewerFolder): Promise<IContabilidadeDigitalGenericViewerFolder>;
}

export interface IContabilidadeDigitalGenericViewerEvtItemChanged {
  root: IContabilidadeDigitalGenericViewerFolder;
}

export interface IContabilidadeDigitalGenericViewerEvtFolderItemChanged extends IContabilidadeDigitalGenericViewerEvtItemChanged {
  folder: IContabilidadeDigitalGenericViewerFolder;
}

export interface IContabilidadeDigitalGenericViewerEvtFileItemChanged extends IContabilidadeDigitalGenericViewerEvtItemChanged {
  file: IContabilidadeDigitalGenericViewerFile;
}

export function contabDigitalGenerateBaseFolder(): IContabilidadeDigitalGenericViewerFolder {
  return {
    folderID: undefined,
    folderParentID: undefined,
    name: undefined,
    folderPath: undefined,
    hasSubFiles: false,
    hasSubFolders: false,
    files: [],
    folders: [],
    parentFolder: undefined,
    promise: undefined
  };
}

export function contabDigitalBaseFolderToGenericViewerFolder(baseFolder: IJsonContabDigitalFolder): IContabilidadeDigitalGenericViewerFolder {
  return {...contabDigitalGenerateBaseFolder(), ...baseFolder};
}

export function contabDigitalBaseFoldersToGenericViewerFolders(baseFolders: Array<IJsonContabDigitalFolder>): Array<IContabilidadeDigitalGenericViewerFolder> {
  return baseFolders.map<IContabilidadeDigitalGenericViewerFolder>(contabDigitalBaseFolderToGenericViewerFolder);
}

export function contabDigitalFoldersWithChildrenToFolders(
  foldersWithChildren: Array<IJsonContabDigitalFolderWithChildren>,
  action?: (folderWithChildren: IJsonContabDigitalFolderWithChildren, genericViewerFolder: IContabilidadeDigitalGenericViewerFolder) => void | IContabilidadeDigitalGenericViewerFolder
): Array<IContabilidadeDigitalGenericViewerFolder> {
  const hasAction: boolean = isFunction(action);
  return foldersWithChildren.map<IContabilidadeDigitalGenericViewerFolder>((folderWithChildren: IJsonContabDigitalFolderWithChildren) => {
    const folders: Array<IContabilidadeDigitalGenericViewerFolder> = contabDigitalFoldersWithChildrenToFolders(folderWithChildren.childFolders, action);
    let genericViewerFolder: IContabilidadeDigitalGenericViewerFolder = {...folderWithChildren, folders: folders};
    if (hasAction) {
      const result: void | IContabilidadeDigitalGenericViewerFolder = action(folderWithChildren, genericViewerFolder);
      if (isObject(result)) {
        genericViewerFolder = <IContabilidadeDigitalGenericViewerFolder>result;
      }
    }
    delete folderWithChildren.childFolders;
    return genericViewerFolder;
  });
}
