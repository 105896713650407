<div class="terms-modal" [plPromise]="promise">
  <div class="modal-header">
    <button type="button" class="btn-close" *ngIf="viewOnly || cancelable" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'terms.title'"></h5>
    <div class="terms-modal-version"><small [innerHTML]="'terms.version' | translate: {version: version}"></small></div>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <!-- Terms changed -->
    <pl-alert class="mb-2" type="info" [closeable]="false" *ngIf="!viewOnly && !firstTime">
      <div [innerHTML]="'terms.changedTerms' | translate"></div>
    </pl-alert>

    <div class="terms-modal-introduction" *ngIf="!viewOnly" [translate]="'terms.introduction'"></div>

    <!-- Termos e condições -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title" [translate]="'terms.titleTerms'"></h4>
        <section class="section-termos">
          <div class="section-termos-header">
            <p class="mb-4">
              <span>Última atualização: {{ dateUpdated | cgcDate }}</span>
            </p>
            <h6 class="mb-2">Cumprimento do disposto no art. 10.º do Decreto-Lei n.º 7/2004, de 7 de janeiro:</h6>
            <div><span class="h6">Denominação social:</span> CentralGest – Produção de Software, SA</div>
            <div><span class="h6">Número de identificação fiscal:</span> 501932992</div>
            <div><span class="h6">Matrícula:</span> Conservatória do Registo Comercial da Mealhada sob o nº 29/880212</div>
            <div><span class="h6">Morada:</span> Av. Cidade de Coimbra, n.º 92/9, 3050-374 Mealhada</div>
            <div><span class="h6">Telefone:</span> +351 231 209 530</div>
            <div><span class="h6">E-mail:</span> info&#64;centralgest.com</div>
          </div>

          <ol class="mt-4">
            <li>
              <h6>Objeto:</h6>
              <p>
                Este documento define as condições contratuais pelo as quais a Centralgest – Produção de Software, SA (designada adiante por “Centralgest”), disponibiliza uma licença de utilização de
                software (designado adiante por “CentralGest Cloud” ou “Software”) e ainda as condições contratuais relativas à prestação de serviços de suporte e manutenção deste software (designados
                adiante “Suporte e Manutenção” ou “Serviços”).
              </p>
              <p>
                A concessão do software e serviços destinam-se a pessoas singulares ou coletivas que desenvolvam atividade comercial, empresarial ou profissional (designados adiante por “Cliente”).
              </p>
              <p>Ao utilizar o Software o Cliente e/ou Utilizador concorda expressamente com os termos e condições descritos neste documento.</p>
            </li>

            <li>
              <h6>Dicionário de termos:</h6>
              <ol>
                <li>
                  <h6>Cliente</h6>
                  <p>
                    A concessão do Software e Serviços anteriormente referidos destinam-se a pessoas singulares ou coletivas que desenvolvam atividade comercial, empresarial ou profissional e que
                    pretendam a licença para uso profissional.
                  </p>
                  <p>
                    O Cliente é a entidade, pessoa singular ou coletiva que contratou o serviço, ou seja, é o subscritor da licença do Software e apenas a este é concedida a possibilidade de ativação
                    ou desativação utilizadores.
                  </p>
                </li>

                <li>
                  <h6>Utilizador</h6>
                  <p>
                    Os utilizadores são o Cliente, respetivos colaboradores, empresários e os seus respetivos colaboradores, ou outras pessoas, registadas pelo Cliente como os utilizadores na
                    plataforma acessível no endereço https://cloudstore.centralgest.com. Aquando do registo da conta o Cliente é considerado de imediato como um Utilizador.
                  </p>
                  <p>O utilizador é a pessoa que usa o serviço, e que está devidamente autorizada a fazê-lo pelo Cliente.</p>
                </li>

                <li>
                  <h6>Conta do utilizador</h6>
                  <p>Refere-se à área privada (“Minha Conta”) que o utilizador subscritor do serviço tem acesso.</p>
                </li>

                <li>
                  <h6>Serviço</h6>
                  <p>
                    No âmbito deste documento o termo Serviço refere-se ao uso do produto, serviços de apoio, suporte, manutenção, formação, comunicação e informação em geral que são parte integrante
                    da plataforma CentralGest Cloud.
                  </p>
                </li>

                <li>
                  <h6>Contrato</h6>
                  <p>Esta palavra refere-se ao Contrato de Utilização, ou seja, o presente documento.</p>
                </li>

                <li>
                  <h6>Subscrição</h6>
                  <p>É o termo adotado no que respeita à assinatura do serviço efetuada pelo Cliente.</p>
                </li>

                <li>
                  <h6>Backup</h6>
                  <p>Refere-se à segurança de uma cópia dos dados geridos pelo serviço.</p>
                </li>

                <li>
                  <h6>Base de dados do utilizador</h6>
                  <p>
                    Refere-se ao conjunto de dados inseridos ou comunicados pelo Utilizador e Cliente para a plataforma CentralGest Cloud, excetuando o conjunto de informação do módulo de
                    Contabilidade.
                  </p>
                </li>

                <li>
                  <h6>Base de dados do cliente</h6>
                  <p>
                    Refere-se ao conjunto de dados inseridos ou comunicados pelo Cliente ou seus colaboradores no módulo de contabilidade, ativos e recursos humanos do CentralGest Cloud, nomeadamente,
                    os necessários para a configuração do plano de contas, preenchimento das declarações fiscais, realização de lançamentos contabilísticos, processamento salarial, entre outros.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <h6>Termos do serviço:</h6>
              <ol>
                <li>A versão mais atual dos termos da prestação de serviço está disponibilizada no endereço https://cloudstore.centralgest.com.</li>

                <li>
                  A Centralgest reserva-se ao direito de atualizar e alterar periodicamente sem aviso prévio os termos e condições do presente documento, sendo esse facto comunicado ao utilizador por
                  correio eletrónico e produzindo efeitos a partir do primeiro dia do mês seguinte ao da respetiva comunicação. Caso o Cliente não concorde com as alterações, terá o direito a fazer
                  cessar a utilização do Software, sem qualquer penalização. Devendo comunicar o facto por carta registada.
                </li>

                <li>
                  As referidas alterações serão publicadas na aplicação CentralGest Cloud pelo que se recomenda a consulta deste documento. A versão publicada na aplicação é a que está em vigor.
                </li>

                <li>
                  Se após a entrada em vigor das alterações aos termos da prestação de serviço, se verificar o uso do Software e ou Serviços, considera-se aceite pelo Cliente os novos termos deste
                  documento.
                </li>
                <li>O Utilizador assume que aceita expressamente os riscos da utilização do serviço.</li>

                <li>
                  O Utilizador aceita e concorda que a Centralgest não pode ser responsabilizada por quaisquer tipos de dados registados por si ou por interposta pessoa no CentralGest Cloud e ou
                  restante serviço.
                </li>

                <li>
                  Qualquer funcionalidade que vise melhorar ou aperfeiçoar o serviço atual, incluindo novas funcionalidades, fica sujeita às condições estabelecidas nos termos da prestação de serviço.
                </li>

                <li>A violação de qualquer das condições estabelecidas nos termos da prestação de serviço confere à Centralgest o direito de encerrar a conta do Utilizador infrator.</li>
              </ol>
            </li>
            <li>
              <h6>Termos de utilização e uso da conta:</h6>
              <ol>
                <li>
                  O Utilizador é o único e exclusivo responsável pelo bom uso e por manter em segurança a sua conta e senha de acesso pessoal, declinando a Centralgest qualquer responsabilidade pela
                  perda ou dano resultante do incumprimento por parte do utilizador ou falha em cumprir com esta obrigação de segurança.
                </li>

                <li>
                  Ao Utilizador é concedido um acesso exclusivo à conta, sendo este pessoal, privado e intransmissível, não sendo autorizada a partilha deste acesso a terceiros com o objetivo de
                  aceder ao CentralGest Cloud.
                </li>

                <li>O Utilizador não podem utilizar a sua conta para quaisquer fins ilegais, ilícitos, fraudulentos ou não autorizados.</li>

                <li>O Cliente é o único responsável pelo registo de utilizadores na sua conta e pelos dados de identificação inseridos.</li>

                <li>
                  O Cliente e Utilizador entendem e aceitam expressamente que a Centralgest não pode ser responsabilizada, nem a título de negligência, por quaisquer tipos de danos resultantes da
                  utilização do CentralGest Cloud pelo Cliente, pelo Utilizador ou por um terceiro que aceda aos dados do Cliente ou Utilizador, usando os seus dados de acesso.
                </li>

                <li>
                  Ao divulgar informação produzida no CentralGest Cloud e na área privada da sua conta, o Cliente e ou Utilizador declara ser o proprietário desses conteúdos, ficando responsabilizado
                  pelos mesmos e isentando a Centralgest de todas as responsabilidades, seja de que natureza forem, nomeadamente legais, fiscais ou parafiscais.
                </li>

                <li>O Cliente e ou Utilizador são os únicos responsáveis por todos os dados inseridos na sua conta e por todas as atividades que nela ocorram.</li>

                <li>
                  O Cliente e Utilizador aceitam que a Centralgest se reserve no direito de, em qualquer momento, em especial por razões de ordem técnica, suspender temporariamente o acesso ao
                  CentralGest Cloud e Serviços (ou parte deles), com ou sem aviso prévio.
                </li>

                <li>
                  Na eventualidade do serviço ser descontinuado permanentemente, a CentralGest informará o Cliente e Utilizador, com antecedência mínima de 30 (trinta) dias antes do termo do serviço
                  subscrito.
                </li>

                <li>A Centralgest não pode ser responsabilizada por quaisquer danos resultantes da cessação ou suspensão da conta e/ou acesso do Cliente e/ou Utilizador.</li>

                <li>
                  Devido a trabalhos técnicos de manutenção, atualização e/ou quaisquer outras situações técnicas, a Centralgest poderá interromper, de forma limitada e/ou temporariamente a utilização
                  do CentralGest Cloud e Serviço.
                </li>

                <li>É expressamente aceite pelo Cliente e Utilizador que Centralgest fornece automaticamente as alterações e correções ao CentralGest Cloud como parte do Serviço.</li>

                <li>
                  O Utilizador declara que confia na Centralgest como entidade emissora e repositória dos seus documentos de faturação, no caso de usar o CentralGest Cloud para envio das faturas por
                  via eletrónica.
                </li>
              </ol>
            </li>

            <li>
              <h6>Registo da conta:</h6>
              <ol>
                <li>
                  Previamente ao seu registo, o Cliente deverá ler atentamente os Termos e Condições constantes neste clausulado, e realizar aceitação expressa do mesmo, para continuar o processo de
                  registo.
                </li>

                <li>
                  O contrato de licenciamento do Software e Serviços rege-se pelos termos deste documento e celebra-se com a conclusão do registo pelo Cliente, pelo qual este manifesta que aceita
                  expressamente as presentes condições.
                </li>

                <li>
                  O acesso gratuito à licença experimental do CentralGest Cloud exige o registo prévio do Cliente no seguinte endereço de internet “https://cloudstore.centralgest.com” e aceitação dos
                  Termos e Condições do presente documento.
                </li>

                <li>O processo de registo da conta, concede ao Cliente as credenciais de acesso do seu respetivo Utilizador na plataforma CentralGest Cloud.</li>

                <li>O acesso ao CentralGest Cloud é efetuado através da atribuição de um nome de utilizador e senha de acesso, definidos pelo Cliente e ou pelo Utilizador.</li>

                <li>
                  O Cliente e ou Utilizador são os únicos responsáveis pela guarda e boa utilização da sua própria senha de acesso, declinando a Centralgest qualquer responsabilidade pela sua perda,
                  transmissão a terceiros e uso inadequado da senha.
                </li>

                <li>
                  O Cliente e ou Utilizador aceitam que serão os únicos responsáveis perante a Autoridade Tributária e Aduaneira ou quaisquer outros terceiros, por todas as atividades que ocorram
                  através da utilização das contas que tenham no CentralGest Cloud, bem como por manter a confidencialidade de todas as senhas de outros serviços que possam ser utilizados no
                  funcionamento do Software.
                </li>

                <li>Durante o período da licença experimental será disponibilizado gratuitamente o Software, e não inclui o serviço de Suporte e Manutenção.</li>

                <li>
                  Após o período da licença experimental, o Cliente deverá subscrever uma licença na área privada, no sítio “https://www.cloud.centralgest.com”, e deverá completar os dados de
                  identificação necessários aos processos de faturação, pagamento e licenciamento.
                </li>

                <li>O Cliente é o único responsável pela veracidade, completude e licitude dos dados facultados à Centralgest e inseridos no Software.</li>

                <li>O Cliente deverá manter os dados facultados à Centralgest sempre atualizados e proceder o mais breve possível a qualquer alteração.</li>

                <li>
                  Durante o processo de registo é enviado ao Cliente uma comunicação por correio eletrónico para confirmação dos dados inseridos, por forma a garantir a veracidade deste registo.
                </li>

                <li>
                  O contrato de Software e Serviços celebra-se com a conclusão do registo e a confirmação de boa cobrança do pagamento que o Cliente fez para a subscrição da licença do Software.
                </li>

                <li>A Centralgest dispõe de backups diários de todos os dados e disponibiliza redundância em tempo real sobre os dados carregados corretamente para os servidores</li>

                <li>O CentralGest Cloud é um serviço disponível na cloud para um amplo conjunto de Utilizadores, pelo que o Cliente não pode solicitar ajustes pontuais e/ou específicos.</li>

                <li>
                  O CentralGest Cloud é um serviço disponibilizado sob a condição “as is”, isto é, com as características e condições existentes no momento do registo, subscrição e ainda está sujeito
                  às adaptações legais e funcionais.
                </li>

                <li>
                  O Cliente poderá adicionar Utilizadores, de acordo com o previsto na licença que subscrever, na área privada, no sítio “https://www.cloud.centralgest.com”, ficando o Utilizador
                  autorizado a usar o Software, segundo os dados de identificação que o Cliente tiver inserido na plataforma, sendo o Cliente responsável pelo procedimento de atualização imediata e
                  modificação dos dados no sítio aqui mencionado.
                </li>

                <li>
                  No caso em que o registo é subscrito por representante do Cliente sem os necessários poderes de representação, aquele fica pessoalmente obrigado pelos termos e condições deste
                  documento, se o Cliente colocar em causa a sua vinculação por insuficiência de poderes do representante. Entende-se para todos os efeitos legais e contratuais, que as referências ao
                  Cliente respeitam ao subscritor. No caso aqui identificado, é conferido com efeitos imediatos, o direito à Centralgest de optar por rescindir o contrato.
                </li>
              </ol>
            </li>

            <li>
              <h6>Período experimental:</h6>
              <ol>
                <li>
                  Com o registo do Cliente, efetuado de acordo com o ponto anterior, a Centralgest disponibiliza ao Cliente uma licença grátis pelo período experimental de 30 dias. Esta licença é
                  intransmissível e exclusiva do Cliente.
                </li>

                <li>A licença experimental do Software apenas disponibiliza uma empresa e um utilizador experimental, sem possibilidade de criação de utilizadores e empresas adicionais.</li>

                <li>
                  Após o período experimental da licença, é concedida ao Cliente a possibilidade de continuar a utilizar a empresa da licença do período experimental ou proceder à sua eliminação.
                </li>

                <li>O utilizador será notificado com antecedência para o correio eletrónico do seu registo, alertar para o prazo de expiração da licença experimental.</li>
              </ol>
            </li>

            <li>
              <h6>Notifições:</h6>
              <ol>
                <li>
                  O Cliente aceita expressamente que as notificações a realizar pela Centralgest no âmbito deste contrato, sejam feitas por correio eletrónico para o endereço de e-mail indicado pelo
                  Cliente no registo.
                </li>

                <li>As comunicações por correio eletrónico consideram-se realizadas na data de receção da comunicação do aparelho do recetor.</li>
              </ol>
            </li>

            <li>
              <h6>Vigência:</h6>
              <ol>
                <li>O licenciamento do CentralGest Cloud é concedido gratuitamente por um prazo inicial de 30 dias, período experimental, contado a partir da data de registo do Cliente.</li>

                <li>
                  Após o termo do período experimental, o contrato de licenciamento e serviço, vigorará, de acordo com a opção do Cliente, por períodos de seis ou doze meses, automática e
                  sucessivamente renováveis por iguais períodos.
                </li>

                <li>
                  A Centralgest comunicará previamente ao Cliente a data limite relativa ao pagamento exigido para evitar o cancelamento da renovação da licença. O cancelamento da licença ocorre na
                  falta de pagamento pelo Cliente no prazo requerido.
                </li>

                <li>
                  O Cliente poderá obstar a renovação automática do contrato, mediante notificação à Centralgest por carta registada para morada identificada no presente documento. A comunicação da
                  Centralgest com igual finalidade será comunicada ao Cliente de acordo com o clausulado das notificações.
                </li>

                <li>
                  Caso não se verifique a renovação da licença, no termo de vigência do contrato, o acesso ao CentralGest Cloud e disponibilização do Serviço cessarão automaticamente e sem a
                  necessidade da Centralgest efetuar qualquer aviso prévio, sendo que a partir desse momento, o Cliente e ou Utilizador deixará de ter acesso ao CentralGest Cloud, bem como, à
                  informação nele armazenada.
                </li>

                <li>
                  Caso se verifique a falta ou atraso de pagamento do Cliente, relativo à subscrição do serviço, a Centralgest poderá suspender o acesso ao CentralGest Cloud e serviços de Manutenção e
                  Suporte.
                </li>
              </ol>
            </li>

            <li>
              <h6>Preço e pagamento:</h6>
              <ol>
                <li>Os preços aplicáveis e em vigor em cada momento, à subscrição do serviço, estão disponibilizados no endereço de internet “https://cloudstore.centralgest.com”.</li>

                <li>O Cliente deverá proceder ao pagamento do serviço contratado no momento da contratação ou da renovação do mesmo, sendo que o valor cobrado não é reembolsável.</li>

                <li>A Centralgest pode suspende a disponibilização de acesso ao CentralGest Cloud e Serviços, no caso do Cliente, atrasar ou não efetuar o pagamento da licença do serviço.</li>

                <li>
                  Quando durante a vigência do contrato de serviço, o Cliente fizer upgrades aos planos contratados, o serviço solicitará o pagamento ou acerto de valores relativamente ao preço em
                  vigor, devendo o Cliente efetuar o pagamento relativo à diferença de preço entre antigo e o novo preço do plano de subscrição contratado.
                </li>

                <li>
                  A Centralgest tudo fará para disponibilizar as formas de pagamento universais comumente utilizados na aquisição de serviços cloud, como sejam o pagamento por cartão de crédito,
                  Paypal e meios nacionais vulgarizados. Sendo disponibilizada toda a informação necessária relativa ao pagamento solicitado.
                </li>
              </ol>
            </li>
            <li>
              <h6>DIREITOS DE AUTOR E PROPRIEDADE INTELECTUAL E GESTÃO DE DADOS:</h6>
              <ol>
                <li>
                  A Centralgest é a única titular do CentralGest Cloud, e de todos os direitos de propriedade intelectual sobre quaisquer vídeos, manuais e outros materiais disponibilizados ao abrigo
                  dos serviços de Suporte e Manutenção, não existindo qualquer transmissão desses direitos a favor do Cliente.
                </li>

                <li>Todos os direitos do serviço são reservados à Centralgest.</li>

                <li>A concessão da licença apenas respeita ao Software de que a Centralgest seja titular.</li>

                <li>
                  Os dados geridos pelo CentralGest Cloud são propriedade do Cliente, e este autoriza a Centralgest no âmbito exclusivo do serviço contratado, a manusear os dados, nomeadamente para a
                  gestão de cópias de segurança e deteção de erros.
                </li>

                <li>O Cliente e/ou utilizador aceita que o Serviço contém informações confidenciais e protegidas de acordo com a legislação aplicável.</li>

                <li>
                  Exceto se obtida a expressa autorização da Centralgest, o utilizador aceita que não poderá divulgar, copiar, reproduzir, alugar, vender, revender, distribuir, duplicar, reutilizar ou
                  partilhar com terceiros quaisquer conteúdos ou elementos visuais do Software e Serviço. A violação desta alínea constitui uma infração dos direitos da Centralgest e pode constituir
                  uma infração penal prevista e punida, designadamente nos termos da Lei n.º 109/2009 de 15 de setembro.
                </li>

                <li>
                  A Centralgest não pode ser responsabilizada pela má utilização do Serviço por qualquer utilizador autorizado pelo Cliente. Dado que o Cliente é o único responsável pela criação e
                  gestão de utilizadores, este aceita que os utilizadores por si autorizados aceder ao Software e Serviço tenham acesso aos dados de acordo com a configuração que o Cliente lhes
                  atribuir.
                </li>

                <li>
                  Caso o Cliente deixe de usar o Serviço, poderá exportar uma segurança dos dados ou o ficheiro SAF-T, se além disto, o Cliente ainda poderá mediante um pagamento reduzido consultar a
                  informação existente no CentralGest Cloud.
                </li>

                <li>
                  Após a rescisão do contrato de serviço, a Centralgest garantirá o armazenamento dos dados das empresas durante um período de 12 meses, sendo os dados eliminados definitivamente após
                  esse período.
                </li>

                <li>
                  No caso de o Cliente voltar a subscrever o serviço a reposição dos dados da anterior subscrição, só é possível apenas se não tiver decorrido o período de 12 meses após a rescisão o
                  serviço.
                </li>
              </ol>
            </li>

            <li>
              <h6>Dados pessoais:</h6>
              <ol>
                <li>Quaisquer dados pessoais comunicados ou inseridos no CentralGest Cloud pelo Utilizador e Cliente serão mantidos em segredo absoluto pela Centralgest.</li>

                <li>A Centralgest obriga-se à não divulgação de quaisquer dados pessoais existentes no CentralGest Cloud, mesmo após a cessação do contrato de serviço.</li>

                <li>A Centralgest garantirá o sigilo e a confidencialidade dos dados pessoais comunicados ou inseridos na utilização do Serviço.</li>

                <li>
                  Após o conhecimento de factos relacionados com a perda ou o roubo de dados pessoais existentes no serviço, a Centralgest obriga-se a informar o facto ocorrido ao titular desses dados
                  pessoais no prazo máximo de dois dias úteis.
                </li>

                <li>
                  A Centralgest garantirá ao titular de dados pessoais o direto de acesso e retificação, de acordo com a legislação aplicável sempre que tais dados sejam recolhidos no âmbito do
                  serviço.
                </li>

                <li>
                  A qualquer momento o titular dos dados pessoais poderá exercer o direito ao esquecimento dos dados inseridos no CentralGest Cloud, no caso em que estes deixarem de necessários para a
                  finalidade que motivou a sua recolha ou tratamento, salvo algum motivo de força maior que o impeça, nos termos previstos no número 3 do artigo 17.º do Regulamento (EU) 2016/679 do
                  Parlamento Europeu e do Conselho, de 27 de abril de 2016.
                </li>

                <li>
                  A Centralgest, seus colaboradores e parceiros obrigam-se à adoção das melhores práticas organizacionais internas para manter e garantir a proteção de dados pessoais comunicados ou
                  inseridos pelo Cliente ou Utilizador no CentralGest Cloud.
                </li>
              </ol>
            </li>

            <li>
              <h6>Acesso e uso do CentralGest Cloud:</h6>
              <p>
                Os utilizadores acedem e usam o CentralGest Cloud a partir da internet, através do endereço web https://cloud.centralgest.com, utilizando um navegador de internet atualizado, sendo
                apenas recomendado a utilização do navegador “Chrome”.
              </p>
              <p>Não é concedida ao Cliente a possibilidade de obter uma versão executável do Software.</p>
              <p>O acesso ao Software só é concedido após a conclusão do registo do Cliente e respetivo pagamento da licença de subscrita.</p>
              <p>
                Para que outros utilizadores associados ao Cliente possam ter acesso ao Software, é necessário que o este os registe previamente na plataforma de gestão de utilizadores, disponível no
                endereço https://cloud.centralgest.com.
              </p>
            </li>

            <li>
              <h6>Localização da plataforma CentralGest Cloud:</h6>
              <p>Aplicação CentralGest Cloud está instalado do Data Center da Altice na Covilhã sendo replicado em tempo real para outro Data Center da mesma empresa em Lisboa.</p>
              <p>A licença de utilização do Software é concedida ao Cliente com o início do pagamento da sua subscrição, processo que inicia ativação da licença.</p>
            </li>

            <li>
              <h6>Cookies:</h6>
              <p>
                Os nossos sites, aplicações e serviços cloud utilizam a tecnologia de cookies para melhorar a sua experiência de navegação, sendo que apenas utilizamos os que são estritamente
                necessários para o funcionamento correto dos nossos sítios web. A sua utilização está dependente da sua aceitação quando navega nos nossos sítios web.
              </p>
            </li>

            <li>
              <h6>Lei e foro:</h6>
              <ol>
                <li>Os preços aplicáveis e em vigor em cada momento, à subscrição do serviço, estão disponibilizados no endereço de internet “https://cloudstore.centralgest.com”.</li>

                <li>O presente acordo rege-se pela lei vigente em Portugal.</li>

                <li>
                  Para dirimir quaisquer questões emergentes da interpretação ou da execução do presente contrato, as partes designam como competente o foro da Comarca mais próxima da sede da
                  Centralgest, com expressa renúncia a qualquer outro.
                </li>
              </ol>
            </li>

            <li>
              <h6>Limitação de garantia e responsabilidade:</h6>
              <ol>
                <li>O CentralGest Cloud é um serviço disponibilizado sob a condição “tal como está” e “conforme disponível”, sem quaisquer outras garantias, expressas ou implícitas.</li>

                <li>
                  O Cliente e/ou Utilizador são os responsáveis pelos resultados e desempenho do produto, pelo que a Centralgest nunca será responsável perante o Cliente, o Utilizador ou qualquer
                  outra pessoa individual ou coletiva, ou outra entidade, por qualquer dano direito ou indireto, seja ele, acidental, especial, consequencial ou qualquer outro, incluindo, mas não se
                  limitando a, lucros cessantes, perda ou destruição de dados ou outros prejuízos económicos ou comerciais, ainda que a Centralgest tenha sido notificada da eventualidade de ocorrência
                  de tais danos, ou que a seja previsível a ocorrência dos mesmos.
                </li>

                <li>
                  O serviço é disponibilizado um amplo conjunto de utilizadores, pelo que não poderá ser exigido que Centralgest não garanta a satisfação de necessidades específicas do Cliente e/ou
                  Utilizador.
                </li>

                <li>A Centralgest não garante a plenitude do serviço como sendo ininterrupto, atempado, seguro ou livre de erros.</li>

                <li>A responsabilidade total da Centralgest perante o Cliente e ou Utilizador não excederá o montante que este pagou pela subscrição do Serviço no período em vigor.</li>
              </ol>
            </li>
            <li>
              <h6>Condições gerais:</h6>
              <ol>
                <li>O Cliente e/ou Utilizador assume os riscos próprios da utilização do Serviço.</li>

                <li>O suporte técnico é efetuado através de correio eletrónico ao Cliente que subscrever o serviço e após o período experimental.</li>

                <li>
                  O Serviço é disponibilizado através da internet, pelo que a Centralgest não poderá ser responsabilizada pela falha ou desempenho da conectividade e inerente impossibilidade da sua
                  utilização.
                </li>
              </ol>
            </li>

            <li>
              <h6>Disposições finais:</h6>
              <p>
                O Utilizador reconhece ter lido e entendido a presente licença de utilização do Software de garantia limitada, e aceita obrigar-se nos seus precisos termos e condições. O Utilizador
                reconhece igualmente que a referida concessão de licença e garantia limitada, constituem o teor integral e exclusivo da convenção entre as partes e substituem todos os acordos ou
                propostas anteriores, verbais ou escritas, e qualquer outra comunicação entre as partes relacionadas com o objecto da presente licença, ou da garantia limitada.
              </p>
            </li>

            <li>
              <h6>RGPD Regulamento Geral de Proteção de Dados:</h6>
              <ol>
                <li>
                  Os dados pessoais recolhidos na subscrição do serviço são recolhidos no princípio do respeito do Regulamento Geral sobre a Proteção de Dados e estão sujeitos a tratamento pela
                  Centralgest, nomeadamente para cumprimento do serviço de apoio de técnico à plataforma e comunicação de novidades das novas versões e de campanhas.
                </li>

                <li>
                  O endereço de correio eletrónico e outros dados pessoais inseridos no processo de registo, poderão ser usados para o envio de newsletters, campanhas, novidades e atualizações ou
                  avisos sobre o CentralGest Cloud. Caso o utilizador do e-mail deseje deixar de receber este tipo de mensagens eletrónicas, poderá fazê-lo clicando no local apropriado existente no
                  texto de email.
                </li>

                <li>Os dados pessoais inseridos no registo pelo cliente, não serão partilhados, sendo mantidos por tempo ilimitado até indicação contrária por parte deste.</li>

                <li>
                  De acordo com o Regulamento Geral sobre a Proteção de Dados, é garantido ao titular dos dados, o direito de acesso, retificação, atualização e ao esquecimento dos seus dados
                  pessoais.
                </li>

                <li>
                  O titular dos dados tem o direito de oposição ao tratamento dos seus dados pessoais, mediante pedido dirimido à Centralgest para o endereço de correio eletrónico
                  privacidade&#64;centralgest.com.
                </li>
              </ol>
            </li>

            <li>
              <h6>Faturas eletrónicas:</h6>
              <ol>
                <li>O módulo Comercial do CentralGest Cloud poderá permitir ao Utilizador o envio de faturas eletrónicas.</li>

                <li>O Utilizador concede à Centralgest o direito de usar o certificado digital pertença da Centralgest SA para assinar digitalmente por sua ordem os documentos de faturação.</li>

                <li>
                  O Utilizador será sempre e em qualquer circunstância o responsável pelo conteúdo das faturas eletrónicas emitidas de acordo com o presente clausulado, pelo que a Centralgest não
                  poderá, em qualquer circunstância ser responsabilizada, por qualquer dano, risco ou custo relacionado com essas mesmas faturas ou documentos comercias, nem pela sua autenticidade
                  legal ou fiscal.
                </li>

                <li>
                  O Utilizador será sempre e em qualquer circunstância o único responsável pela assinatura da fatura, pelo seu conteúdo, emissão, envio, arquivo e comunicação à Autoridade Tributária e
                  Aduaneira das mesmas faturas e restantes documentos comerciais, incluindo guias ou documentos de transporte, seja por que meio for, nomeadamente por comunicação por webservice ou
                  ficheiro XML.
                </li>
              </ol>
            </li>
          </ol>
        </section>
      </div>
    </div>

    <!-- Política de privacidade -->
    <div class="card">
      <div class="card-body">
        <h4 class="card-title" [translate]="'terms.titlePrivacyPolicy'"></h4>
        <section class="section-politica-privacidade">
          <div class="section-politica-privacidade-header">
            <p>Recomenda-se a leitura atentamente dos Termos de Utilização e Privacidade do website da CentralGest.</p>
            <p>
              Com o acesso e utilização deste Site, o utilizador declara ter lido, compreendido e aceite os Termos de Utilização e de Privacidade abaixo descritos, sem necessidade de qualquer ato ou
              consentimento posterior.
            </p>
          </div>

          <ol class="mt-4">
            <li>
              <h6>Declaração de Privacidade da CentralGest:</h6>
              <p>
                Na CentralGest levamos muito a sério o nosso compromisso pela privacidade na proteção dos dados em conformidade com o Regulamento Geral de Proteção de Dados da União Europeia, no
                âmbito da recolha e tratamento dos seus dados pessoais.
              </p>
              <p>Garantimos a segurança dos dados pessoais que recolhemos e asseguramos que os mesmos apenas se destinam estritamente ao processo de compra e prestação de serviços.</p>
              <p>
                Sempre que recolhemos os dados pessoais para outros fins não o mencionado no parágrafo anterior, prestamos uma informação prévia e clara sobre a finalidade desses dados e obtemos o
                consentimento explícito para a sua utilização.
              </p>
              <p>Os dados recolhidos ficam guardados pelo período estritamente necessário à prestação do serviço ou durante a vigência do contrato.</p>
              <p>
                A CentralGest não disponibiliza, transmite ou transfere os seus dados pessoais a quaisquer entidades terceiras, exceto à empresa contratada a prestar o serviço de armazenamento,
                manutenção, proteção e segurança de dados, e esta não tem autorização de acesso e tratamento de dados pessoais e cumpre formalmente os requisitos do Regulamento Geral de Proteção de
                Dados.
              </p>
              <p>
                Os dados enviados para a nossa solução CentralGest Cloud, por si registados no nosso site ou outros locais na Web e formulários em geral, serão tratados com a mais restrita
                confidencialidade e serão tratados segundo a legislação europeia ou nacional. relativamente à privacidade da informação. Ao enviar esta informação para a nossa aplicação CentralGest
                Cloud, está a autorizar, livre e voluntariamente, a sua inclusão na nossa base de dados e a sua utilização de acordo com a Política de Privacidade.
              </p>
              <p>
                O Cliente e o Utilizador autorizam a CentralGest ou qualquer outro subcontratante com quem a CentralGest venha a estabelecer relação comercial no âmbito do serviço CentralGest Cloud, a
                utilizar os seus dados pessoais de contacto para enviar informação de novos serviços e atualizações relacionados com este serviço.
              </p>
              <p>
                A utilização da plataforma CentralGest Cloud e outras com ela estritamente relacionadas está condicionada à aceitação desta Política de Privacidade, e ao fornecimento dos seus dados
                pessoais e de outros, sem os quais não é possível usufruir do serviço disponibilizado.
              </p>
              <p>Esta política de privacidade aplica-se à CentralGest – Produção de Software, SA</p>
            </li>

            <li>
              <h6>Recolha de dados:</h6>
              <ol>
                <li>
                  <span class="h6">Que dados são recolhidos pela CentralGest?</span>
                  <p>Os dados recolhidos pela CentralGest são relativos a potenciais clientes, clientes, colaboradores dos clientes, parceiros e fornecedores.</p>
                  <p>
                    Os dados recolhidos limitam-se ao nome, apelido, endereço, telefone ou correio eletrónico, mas poderão ser recolhidos outros dados necessários à subscrição, encomenda ou faturação
                    de produtos ou serviços.
                  </p>
                </li>

                <li>
                  <span class="h6">Como são tratados os dados fornecidos através do Site?</span>
                  <p>
                    Estes dados são solicitados antes da submissão dos formulários a preencher pelo utilizador do Site, tais como, o pedido de informação, pedido de demonstração, pedido de contactos,
                    pedido de parceria, inscrição na newsletter e subscrição de serviços.
                  </p>
                  <p>
                    A finalidade da recolha de informações pessoais e empresariais visa a realização dos objetivos para os quais foram recolhidos, sendo para uso exclusivo de processos administrativos
                    e comerciais da CentralGest.
                  </p>
                  <p>A qualquer momento o utilizador do Site poderá cancelar a subscrição da newsletter, através das instruções enviadas com a mesma.</p>
                </li>

                <li>
                  <span class="h6">Quem é responsável pelo tratamento dos dados?</span>
                  <p>O responsável pelo tratamento dos dados é a CentralGest – Produção de Software, S.A.</p>
                </li>
              </ol>
            </li>

            <li>
              <h6>Recolha de dados pessoais:</h6>
              <ol>
                <li>
                  <span class="h6">Que dados pessoais são recolhidos?</span>
                  <p>
                    Os dados pessoais podem ser recolhidos através de formulário digital ou papel e estão limitados ao nome, apelido, email, telefone, morada, número de identificação fiscal e IBAN e
                    outros obrigatórios de acordo com o documento de consentimento.
                  </p>
                </li>

                <li>
                  <span class="h6">Em que casos podem ser utilizados os dados pessoais recolhidos? </span>
                  <p>Apenas podem ser utilizados os dados quando se verifica uma das seguintes situações:</p>
                  <ol>
                    <li>Existe o consentimento do titular dos dados;</li>
                    <li>Exigidos para fazer cumprir um contrato ou qualquer obrigação legal;</li>
                    <li>Exigidos por interesses legítimos da CentralGest, que não podem prevalecer sobre os interesses ou direitos, liberdades e garantias do titular dos dados.</li>
                  </ol>
                </li>

                <li>
                  <span class="h6">Quem é o responsável pela recolha de dados pessoais?</span>
                  <p>O responsável pelo tratamento dos dados é a CentralGest – Produção de Software, S.A., existindo internamente um Responsável pelo Tratamento de dados pessoais.</p>
                </li>

                <li>
                  <span class="h6">Quem terá acesso aos dados recolhidos?</span>
                  <p>
                    A CentralGest não partilha a empresas terceiras quaisquer dados pessoais sem o consentimento do titular, exceto quando tal for exigido ou permitido por lei, ou no caso da empresa
                    subcontratante do datacenter que tratarão dos dados segundo instruções e por conta da CentralGest, no entanto, estes não têm autorização de consultar os referidos dados, devendo a
                    sua ação aplicar-se à manutenção e gestão técnica do sistema informático e à gestão dos sistemas de informação do datacenter.
                  </p>
                  <p>
                    Os dados pessoais confiados à CentralGest serão tratados pelos funcionários devidamente autorizados a proceder ao respetivo tratamento no desempenho das suas funções, nomeadamente
                    de assistência técnica e marketing, sendo que estes funcionários estão funcional e contratualmente obrigados a tratar dos dados pessoais nos termos da lei e da presente Política de
                    Privacidade.
                  </p>
                </li>

                <li>
                  <span class="h6">Qual a finalidade dos dados recolhidos?</span>
                  <p>
                    Os dados pessoais recolhidos têm como finalidade a identificação que permita o processamento de encomendas, faturação, licenciamento e realização de posteriores contactos
                    comerciais e de apoio técnico. Também podem ser utilizados para envio de informações legais, newsletters e campanhas dos nossos produtos.
                  </p>
                </li>

                <li>
                  <span class="h6">Os dados recolhidos serão usados para marketing?</span>
                  <p>
                    Os dados pessoais recolhidos apenas serão utilizados para envio de comunicações de marketing se a informação for previamente solicitada ou consentida. O marketing direto inclui o
                    envio de comunicações por correio eletrónico, telefone e SMS.
                  </p>
                </li>

                <li>
                  <span class="h6">Por quanto tempo serão retidos os dados pessoais?</span>
                  <p>
                    Os dados pessoais recolhidos serão retidos na nossa base de dados pelo tempo estritamente necessário para processamento no âmbito comercial e de pedidos de suporte. A qualquer
                    momento o titular dos dados pessoais pode exercer o direito ao esquecimento sobre estes dados recolhidos.
                  </p>
                  <p>A CentralGest poderá manter os dados durante o prazo que se torne exigível para o cumprimento das suas obrigações legais ou das do cliente e empresas.</p>
                </li>

                <li>
                  <span class="h6">Onde estão guardados os meus dados?</span>
                  <p>
                    A CentralGest guarda os seus dados pessoais numa entidade reconhecida e especializada em datacenter que está localizada em território nacional. Este datacenter mantém elevados
                    padrões internacionais de proteção sobre os seus dados e a sua infraestrutura, tendo além disto, a replicação em tempo real de dados para outra localização.
                  </p>
                </li>

                <li>
                  <span class="h6">Os meus dados estão seguros?</span>
                  <p>A CentralGest implementou medidas técnicas e organizacionais de proteção, no que respeita ao acesso e processamento dos seus dados armazenados no datacenter.</p>
                  <p>A CentralGest encripta através de certificados de segurança todas as comunicações de dados nas suas soluções cloud e sites.</p>
                  <p>
                    Para a CentralGest a segurança e o acesso a dados pessoais é da maior relevância e por isso desenvolvemos todos os esforços para evitar a sua utilização não autorizada ou ilegal,
                    no entanto, esta afirmação não é uma garantia absoluta de segurança, sendo que qualquer violação ou incidente de segurança serão prontamente comunicados às autoridades competentes.
                  </p>
                  <p>
                    A CentralGest encripta todas as palavras-passe de acesso aos nossos sites, aplicações ou soluções cloud, não sendo possível a sua partilha, recomendamos que guarde a sua password
                    em segurança e que a altere periodicamente.
                  </p>
                </li>

                <li>
                  <span class="h6">Como se pode aceder, verificar e retificar os dados pessoais?</span>
                  <p>Para obter as informações e solicitar ações sobre os seus dados pessoais, poderá enviar um email para privacidade&#64;centralgest.com.</p>
                </li>

                <li>
                  <span class="h6">A quem posso reclamar?</span>
                  <p>Para efetuar uma reclamação obter as informações e ações praticadas sobre os seus dados pessoais, poderá enviar um email para privacidade&#64;centralgest.com.</p>
                  <p>
                    Recomendamos a consulta à legislação aplicável à proteção de dados, nomeadamente ao Regulamento (EU) 2016/679 do Parlamento Europeu e do Conselho de 27 de abril de 2016, e
                    legislação nacional aplicável.
                  </p>
                  <p>
                    Caso esteja insatisfeito com a nossa utilização dos seus dados pessoais ou com a nossa resposta após o exercício de algum destes direitos, tem o direito de apresentar reclamação
                    junto da autoridade de controlo nacional (Comissão Nacional de Proteção de Dados – CNPD | Av. D. Carlos I, 134 - 1.º, 1200-651 Lisboa | Tel.: +351 213928400 | Fax.: +351 213976832
                    | E-mail: geral&#64;cnpd.pt
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <h6>Cookies:</h6>
              <p>
                Os nossos sites, aplicações e serviços cloud utilizam a tecnologia de cookies para melhorar a sua experiência de navegação, sendo que apenas utilizamos os que são estritamente
                necessários para o funcionamento correto dos nossos sítios web. A sua utilização está dependente da sua aceitação quando navega nos nossos sítios web.
              </p>
            </li>

            <li>
              <h6>Direitos de Autor e de Propriedade Industrial:</h6>
              <p>
                O conteúdo deste Site, incluindo a marca registada e todos os dados nele mencionado, são da propriedade ou do uso exclusivo da CentralGest – Produção de Software, SA e de empresas por
                si autorizadas, e estão protegidas nos termos gerais de direito e pela legislação nacional e internacional de proteção da Propriedade Intelectual.
              </p>
              <p>A propagação para uso pessoal é permitida, mas estão totalmente proibidas as alterações não autorizadas, cópias, ou venda de qualquer conteúdo deste Site.</p>
              <p>
                São autorizados os usos livres definidos por lei, nomeadamente o direito de citação, sempre que seja identificada expressamente a sua origem, e que modifique os documentos originais.
              </p>
              <p>
                É permitida ao utilizador a publicação de conteúdo a partir deste Site, como seja o caso de Notícias e Press Releases, na condição de o aviso de direitos de autor, seja claramente
                identificado em todas cópias produzidas e desde que, o fim das mesmas, seja meramente informativo e não possua efeitos comerciais ou pessoais. Será objeto de processo judicial o
                infrator relacionado com atos aqui proibidos ou não autorizados.
              </p>
              <p>
                Todos os dados pessoais e restantes informações comunicadas através da internet, são da nossa propriedade e uso exclusivo. Tal informação não terá que ser tratada por nós como
                confidencial, mas o seu uso será restrito e apenas, divulgada a parceiros para feitos de contacto de pedido de informações do utilizador do Site.
              </p>
              <p>As informações disponibilizadas nos sítios web da CentralGest poderão ser alteradas ou atualizadas sem qualquer aviso prévio.</p>
              <p>
                Todo o software disponível para download neste Site, é fornecido pela CentralGest – Produção de Software, SA, e sempre que os termos de uso forem disponibilizados, como parte
                integrante do conteúdo desse software, recomendamos que se proceda à sua leitura.
              </p>
              <p>
                O software CentralGest é um programa certificado nº 326/AT, desde 22-11-2010, e tem atribuído à sua solução de contabilidade o Selo de Validação da Autoridade Tributária (SVAT) desde
                12-06-2019. Estas informações podem ser confirmadas no portal das finanças em http://www.portaldasfinancas.gov.pt.
              </p>
            </li>

            <li>
              <h6>Links:</h6>
              <ol>
                <li>
                  <span class="h6">Qual a finalidade dos links internos?</span>
                  <p>A CentralGest utiliza com frequência links internos, apenas e só, com a finalidade de melhorar as propriedades de usabilidade do Site.</p>
                </li>

                <li>
                  <span class="h6">A CentralGest utiliza links para sites externos?</span>
                  <p>Sim, as páginas deste Site, poderão recorrer à utilização de links para websites externos seguros, mas apenas com uma finalidade informativa.</p>
                  <p>
                    O separador “Outros Sites”, apresenta a links para websites externos que são propriedade da CentralGest – Produção de Software, SA, e utilização igualmente estes Termos de
                    Utilização e Privacidade.
                  </p>
                </li>
              </ol>
            </li>

            <li>
              <h6>Atualização dos Termos de Utilização e Privacidade:</h6>
              <ol>
                <li>
                  <span class="h6">Posso contribuir para melhorar o conteúdo dos Termos de Utilização e Privacidade?</span>
                  <p>Sim, a CentralGest agradece a colaboração de todos os intervenientes deste Site, por forma, alcançar um serviço de excelência, o mais rapidamente possível.</p>
                  <p>As sugestões podem ser submetidas através do separador “Sugestões” disponibilizado neste Site.</p>
                </li>

                <li>
                  <span class="h6">Quando são atualizados os Termos de Utilização e Privacidade?</span>
                  <p>Sempre que se demonstre oportuno e necessário este conteúdo será revisto, por forma, a garantir um serviço de maior qualidade.</p>
                  <p>
                    O conteúdo dos Termos de Utilização e Privacidade foram revistos e atualizados em <strong>{{ dateUpdated | cgcDate: 'MMMM' }} de {{ dateUpdated | cgcDate: 'y' }}</strong
                    >.
                  </p>
                </li>

                <li>
                  <span class="h6">Para onde posso enviar ou esclarecer as minhas dúvidas?</span>
                  <p>
                    Poderá obter informação adicional ou esclarecer qualquer dúvida sobre os Termos de Utilização e Privacidade, bastando para o efeito remeter as suas questões para:
                    privacidade&#64;centralgest.com.
                  </p>
                </li>

                <li>
                  <span class="h6">Alterações à Política de Privacidade</span>
                  <p>
                    A CentralGest pode alterar a presente política de privacidade, sem redução dos seus direitos na presente política de privacidade. Este documento encontra-se atualizado no nosso
                    site pelo que recomendamos a sua leitura.
                  </p>
                </li>

                <li>
                  <span class="h6">CentralGest – Produção de Software, S.A.</span>
                  <p>
                    (sede da empresa)<br />
                    Avenida Cidade de Coimbra, nº 92/94<br />
                    3050 - 374 Mealhada<br />
                    ou, para o endereço eletrónico: privacidade&#64;centralgest.com
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </section>
      </div>
    </div>
  </div>

  <div class="modal-footer" *ngIf="!viewOnly">
    <div class="terms-modal-accept">
      <pl-edit type="checkbox" [model]="accepted" (modelChange)="changedAccepted($event)" [properties]="{label: 'terms.acceptLabel'}" plAutoFocus></pl-edit>
      <pl-tooltip [config]="tooltip" *ngIf="acceptedOnce">
        <button type="button" *plTooltipContent class="btn btn-primary action-accept" [translate]="'global.btn.ok'" [disabled]="!accepted" [click]="close" plPromise></button>
      </pl-tooltip>
      <button type="button" class="btn btn-link" *ngIf="!viewOnly && cancelable" (click)="dismiss()" [plTranslate]="'global.btn.cancel'"></button>
    </div>
  </div>
</div>
