import {IJsonRhCessaContratoTrabalhoEncerrar, IJsonRhCessaContratoTrabalhoForm} from './jsonRHCessaContratoTrabalho.module.interface';
import {IJsonListaExcl} from '../rhfolfer/jsonRHFolfer.module.interface';

export type TExcluidosAbonosDescontos = 'possivel' | 'excluido';

export interface IExcludedAbDesc extends IJsonListaExcl {
  status: TExcluidosAbonosDescontos;
}

export interface IExcluidosAbonosDescontos {
  possiveis: Array<IJsonListaExcl>;
  excluidos: Array<IJsonListaExcl>;
}

export interface IRhCessaContratoTrabalhoGravar {
  rhCessaContratoTrabalhoForm: IJsonRhCessaContratoTrabalhoForm;
  rhCessaContratoTrabalhoEncerrar: Array<IJsonRhCessaContratoTrabalhoEncerrar>;
}

export const MODULE_NAME_RH_CESSA_CONTRATO_TRABALHO = 'rhcessacontratotrabalho';
