<div class="demfinsnc-notas-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'demfinsnc.modal.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-tabs [destroyOnHide]="true" (activeIdChange)="onTabChanged()">
      <pl-tab id="balanco" caption="demfinsnc.modal.tabs.balanco.title">
        <div *plTabContent>
          <div class="card mb-4">
            <div class="card-header fw-bold demfinsnc-notas-card-header" (click)="collapsedAtivo = !collapsedAtivo">
              <button type="button" class="btn btn-link" [translate]="'global.text.active'"></button>
              <i class="fa fa-caret-right" aria-hidden="true" [plAnimationRotate]="!collapsedAtivo"></i>
            </div>

            <pl-animation-collapse [collapsed]="collapsedAtivo">
              <div class="card-body">
                <div>
                  <dx-data-grid [cgDxDataGrid]="dataGridAtivoNaoCorrenteDefinition" cgDxDataGridInstanceName="ativoNaoCorrente" [dataSource]="dataGridAtivoNaoCorrenteDefinition.dataSource">
                    <div *dxTemplate="let cell of 'templateToolbarAtivoNaoCorrente'">
                      <label class="text-info" [translate]="'demfinsnc.modal.tabs.balanco.tab.ativonaocorrente'"></label>
                    </div>
                  </dx-data-grid>
                </div>

                <div class="mt-4">
                  <dx-data-grid [cgDxDataGrid]="dataGridAtivoCorrenteDefinition" cgDxDataGridInstanceName="ativoCorrente" [dataSource]="dataGridAtivoCorrenteDefinition.dataSource">
                    <div *dxTemplate="let cell of 'templateToolbarAtivoCorrente'">
                      <label class="text-info" [translate]="'demfinsnc.modal.tabs.balanco.tab.ativocorrente'"></label>
                    </div>
                  </dx-data-grid>
                </div>
              </div>
            </pl-animation-collapse>
          </div>

          <div>
            <dx-data-grid [cgDxDataGrid]="dataGridCptlProprioDefinition" cgDxDataGridInstanceName="cptlProprio" [dataSource]="dataGridCptlProprioDefinition.dataSource">
              <div *dxTemplate="let cell of 'templateToolbarCptlProprio'">
                <label class="text-info" [translate]="'demfinsnc.modal.tabs.balanco.tab.capitalproprio'"></label>
              </div>
            </dx-data-grid>
          </div>

          <div class="card mt-4">
            <div class="card-header fw-bold demfinsnc-notas-card-header" (click)="collapsedPassivo = !collapsedPassivo">
              <button type="button" class="btn btn-link" [translate]="'demfinsnc.modal.tabs.balanco.tab.titles.passivo'"></button>
              <i class="fa fa-caret-right" aria-hidden="true" [plAnimationRotate]="!collapsedPassivo"></i>
            </div>

            <pl-animation-collapse [collapsed]="collapsedPassivo">
              <div class="card-body">
                <div>
                  <dx-data-grid [cgDxDataGrid]="dataGridPassvNaoCorrenteDefinition" cgDxDataGridInstanceName="passivoNaoCorrente" [dataSource]="dataGridPassvNaoCorrenteDefinition.dataSource">
                    <div *dxTemplate="let cell of 'templateToolbarPassivoNaoCorrente'">
                      <label class="text-info" [translate]="'demfinsnc.modal.tabs.balanco.tab.passivonaocorrente'"></label>
                    </div>
                  </dx-data-grid>
                </div>

                <div class="mt-4">
                  <dx-data-grid [cgDxDataGrid]="dataGridPassvCorrenteDefinition" cgDxDataGridInstanceName="passivoCorrente" [dataSource]="dataGridPassvCorrenteDefinition.dataSource">
                    <div *dxTemplate="let cell of 'templateToolbarPassivoCorrente'">
                      <label class="text-info" [translate]="'demfinsnc.modal.tabs.balanco.tab.passivocorrente'"></label>
                    </div>
                  </dx-data-grid>
                </div>
              </div>
            </pl-animation-collapse>
          </div>
        </div>
      </pl-tab>

      <pl-tab id="demresult" caption="demfinsnc.modal.tabs.demresult.title">
        <div *plTabContent>
          <dx-data-grid [cgDxDataGrid]="dataGridPorNaturezasDefinition" cgDxDataGridInstanceName="porNaturezas" [dataSource]="dataGridPorNaturezasDefinition.dataSource">
            <div *dxTemplate="let cell of 'templateToolbarPorNaturezas'">
              <label class="text-info" [translate]="'demfinsnc.modal.tabs.demresult.pornaturezas'"></label>
            </div>
          </dx-data-grid>

          <div class="mt-4">
            <dx-data-grid [cgDxDataGrid]="dataGridPorFuncoesDefinition" cgDxDataGridInstanceName="porFuncoes" [dataSource]="dataGridPorFuncoesDefinition.dataSource">
              <div *dxTemplate="let cell of 'templateToolbarPorFuncoes'">
                <label class="text-info" [translate]="'demfinsnc.modal.tabs.demresult.porfuncoes'"></label>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </pl-tab>

      <pl-tab id="capproprioperio" caption="demfinsnc.modal.tabs.capproprioperio.title">
        <div *plTabContent>
          <dx-data-grid [cgDxDataGrid]="dataGridCptlProprioPerioDefinition" cgDxDataGridInstanceName="cptlProprioPerio" [dataSource]="dataGridCptlProprioPerioDefinition.dataSource">
            <div *dxTemplate="let cell of 'templateToolbarCptlProprioPerio'">
              <label class="text-info" [translate]="'demfinsnc.modal.tabs.capproprioperio.demaltcptproprio'"></label>
            </div>
          </dx-data-grid>
        </div>
      </pl-tab>

      <pl-tab id="fluxocaixa" caption="demfinsnc.modal.tabs.fluxocaixa.title">
        <div *plTabContent>
          <dx-data-grid [cgDxDataGrid]="dataGridAtvOperacDefinition" cgDxDataGridInstanceName="atvOperac" [dataSource]="dataGridAtvOperacDefinition.dataSource">
            <div *dxTemplate="let cell of 'templateToolbarAtvOperac'">
              <label class="text-info" [translate]="'demfinsnc.modal.tabs.fluxocaixa.atvop'"></label>
            </div>
          </dx-data-grid>

          <div class="mt-4">
            <dx-data-grid [cgDxDataGrid]="dataGridAtvInvestDefinition" cgDxDataGridInstanceName="atvInvest" [dataSource]="dataGridAtvInvestDefinition.dataSource">
              <div *dxTemplate="let cell of 'templateToolbarAtvInvest'">
                <label class="text-info" [translate]="'demfinsnc.modal.tabs.fluxocaixa.atvinvest'"></label>
              </div>
            </dx-data-grid>
          </div>

          <div class="mt-4">
            <dx-data-grid [cgDxDataGrid]="dataGridAtvFinanDefinition" cgDxDataGridInstanceName="atvFinan" [dataSource]="dataGridAtvFinanDefinition.dataSource">
              <div *dxTemplate="let cell of 'templateToolbarAtvFinan'">
                <label class="text-info" [translate]="'demfinsnc.modal.tabs.fluxocaixa.atvfin'"></label>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>

  <div class="modal-footer">
    <pl-button klass="btn-secondary btn-sm action-undo" [onClick]="fnValPorDefeito" attrName="valdefeito">
      <i class="fa fa-fw fa-undo"></i>&nbsp;<span [translate]="'demfinsnc.btn.valdefeito'"></span>
    </pl-button>
    <pl-button klass="btn-primary btn-sm action-close" [disabled]="!canSaveData" [onClick]="close" attrName="save">
      <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
    </pl-button>
    <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
