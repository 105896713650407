<div>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h5 class="modal-title" [translate]="'dgempsfull.modalSelecaoTabelaIRS.modalTitle'"></h5>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <div class="d-flex">
      <fieldset class="me-2 w-50">
        <legend [translate]="'dgempsfull.modalSelecaoTabelaIRS.groupSelection.title'"></legend>
        <div>
          <pl-group>
            <label [translate]="'dgempsfull.modalSelecaoTabelaIRS.fields.dataEmVigor'"></label>
            <edit>
              <pl-select
                attrName="dataEmVigor"
                [model]="currentDataEmVigor"
                [source]="dataEmVigorDataSource"
                (evtChanged)="dataEmVigorChange($event.item)"
                [rowTemplate]="'label'"
                [output]="'label'"
                [clearable]="false">
              </pl-select>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'dgempsfull.modalSelecaoTabelaIRS.fields.residencia'"></label>
            <edit>
              <pl-select
                attrName="residencia"
                [model]="residenciaData"
                [source]="residenciaDataSource"
                [clearable]="false"
                [searchable]="false"
                [labelField]="'label'"
                [rowTemplate]="'label'"
                [output]="'label'"
                (evtChanged)="residenciaChange($event.item)">
              </pl-select>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'dgempsfull.modalSelecaoTabelaIRS.fields.situacao'"></label>
            <edit>
              <pl-select
                attrName="situacao"
                [model]="situacaoData"
                [source]="situacaoDataSource"
                [clearable]="false"
                [searchable]="false"
                [labelField]="'label'"
                [rowTemplate]="'label'"
                [output]="'label'"
                (evtChanged)="situacaoChange($event.item)">
              </pl-select>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'dgempsfull.modalSelecaoTabelaIRS.fields.tipoRendimento'"></label>
            <edit>
              <pl-select
                attrName="tipoRendimento"
                [model]="tipoRendimentoData"
                [source]="tipoRendimentoDataSource"
                [clearable]="false"
                [searchable]="false"
                [labelField]="'label'"
                [rowTemplate]="'label'"
                [output]="'label'"
                (evtChanged)="tipoRendimentoChange($event.item)">
              </pl-select>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'dgempsfull.modalSelecaoTabelaIRS.fields.declaranteDeficiente'"></label>
            <edit>
              <pl-select
                attrName="declaranteDeficiente"
                [model]="declaranteDeficienteData"
                [source]="declaranteDeficienteDataSource"
                [clearable]="false"
                [searchable]="false"
                [labelField]="'label'"
                [rowTemplate]="'label'"
                [output]="'label'"
                (evtChanged)="declaranteDeficienteChange($event.item)">
              </pl-select>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'dgempsfull.modalSelecaoTabelaIRS.fields.dependentes'"></label>
            <edit>
              <pl-select
                attrName="dependentes"
                [model]="dependentesData"
                [source]="dependentesDataSource"
                [clearable]="false"
                [searchable]="false"
                [labelField]="'label'"
                [rowTemplate]="'label'"
                [output]="'label'"
                (evtChanged)="dependentesChange($event.item)">
              </pl-select>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'dgempsfull.modalSelecaoTabelaIRS.fields.dependentesDeficiente'"></label>
            <edit>
              <pl-select
                attrName="dependentesDeficiente"
                [model]="dependentesDeficienteData"
                [source]="dependentesDeficienteDataSource"
                [clearable]="false"
                [searchable]="false"
                [labelField]="'label'"
                [rowTemplate]="'label'"
                [output]="'label'"
                (evtChanged)="dependentesDeficienteChange($event.item)">
              </pl-select>
            </edit>
          </pl-group>
        </div>
      </fieldset>

      <fieldset class="me-2 w-50">
        <legend [translate]="'dgempsfull.modalSelecaoTabelaIRS.groupSelected.title'"></legend>
        <div class="text-center">
          <pl-group>
            <label>{{ outputDesignacaoIRS }}</label>
          </pl-group>
          <pl-group>
            <label *ngIf="outputParcelaAbaterDepende">{{ outputParcelaAbaterDepende }}</label>
          </pl-group>
        </div>
        <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="gridIRSDTEmVigor" [dataSource]="dataGridDefinition.dataSource"> </dx-data-grid>
      </fieldset>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success" [onClick]="close" [disabled]="closeDisabled"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()" [disabled]="closeDisabled"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
