<div class="modelo22-anexod-quadro7-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo22.modal.anexod.titles.' + newLineFor"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <pl-group *ngIf="is71 || is73 || is74 || is76 || is77">
        <label [translate]="'modelo22.modal.anexod.quadro7.nif' + newLineFor"></label>
        <edit>
          <pl-edit type="cginteger" attrName="nif" [(model)]="nif" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is79 || is710">
        <label [translate]="'modelo22.modal.anexod.quadro7.nIdentificacao'"></label>
        <edit>
          <pl-edit type="text" attrName="nIdentificacao" [(model)]="nIdentificacao"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is710 || is710A">
        <label [translate]="'modelo22.modal.anexod.quadro7.montanteBeneficio'"></label>
        <edit>
          <pl-edit type="number" attrName="montanteBeneficio" [(model)]="montanteBeneficio"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="!is79 && !is76 && !is76A && !is710 && !is710A">
        <label [translate]="'modelo22.modal.anexod.quadro7.diploma'"></label>
        <edit>
          <pl-autocomplete [source]="dsDiploma" [model]="diplomaView" (evtSelected)="evtDiplomaChanged($event)" [allowInvalid]="false" [rowTemplate]="dsTemplate" [output]="dsTemplate">
          </pl-autocomplete>
        </edit>
      </pl-group>

      <pl-group *ngIf="!is79 && !is710 && !is710A">
        <label [translate]="'modelo22.modal.anexod.quadro7.periodo'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="periodo" [(model)]="periodo" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is71 || is71A || is73 || is73A || is74 || is74A">
        <label [translate]="'modelo22.modal.anexod.quadro7.saldoCaducado' + newLineFor"></label>
        <edit>
          <pl-edit type="number" attrName="saldoCaducado" [(model)]="saldoCaducado"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is71 || is71A || is73 || is73A || is74 || is74A || is76 || is76A">
        <label [translate]="'modelo22.modal.anexod.quadro7.dotacaoDoPeriodo' + newLineFor"></label>
        <edit>
          <pl-edit type="number" attrName="dotacaoDoPeriodo" [(model)]="dotacaoDoPeriodo"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is71 || is71A || is73 || is73A || is74 || is74A || is76 || is76A || is79 || is710 || is710A">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.saldoNaoDeduzido' + newLineFor"></label>
          <edit>
            <pl-edit type="number" attrName="saldoNaoDeduzido" [(model)]="saldoNaoDeduzido"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.saldoQueTransita' + newLineFor"></label>
          <edit>
            <pl-edit type="number" attrName="saldoQueTransita" [(model)]="saldoQueTransita"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is71 || is73 || is74">
        <label [translate]="'modelo22.modal.anexod.quadro7.deducaoNoPeriodo'"></label>
        <edit>
          <pl-edit type="number" attrName="deducaoNoPeriodo" [(model)]="deducaoNoPeriodo"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is71A || is73A || is74A || is76A">
        <label [translate]="'modelo22.modal.anexod.quadro7.deducaoUtilizada'"></label>
        <edit>
          <pl-edit type="number" attrName="deducaoUtilizada" [(model)]="deducaoUtilizada"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is79">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.dataInicio'"></label>
          <edit>
            <pl-edit type="date" attrName="dataInicio" [(model)]="dataInicio"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.dataConclusao'"></label>
          <edit>
            <pl-edit type="date" attrName="dataConclusao" [(model)]="dataConclusao"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is79">
        <label [translate]="'modelo22.modal.anexod.quadro7.valorIncentivo'"></label>
        <edit>
          <pl-edit type="number" attrName="valorIncentivo" [(model)]="valorIncentivo"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is76 || is79 || is710 || is710A">
        <label [translate]="'modelo22.modal.anexod.quadro7.deducaoDoPeriodo'"></label>
        <edit>
          <pl-edit type="number" attrName="deducaoDoPeriodo" [(model)]="deducaoDoPeriodo"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is79">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.valorAReembolsar'"></label>
          <edit>
            <pl-edit type="number" attrName="valorAReembolsar" [(model)]="valorAReembolsar"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.valorARepor'"></label>
          <edit>
            <pl-edit type="number" attrName="valorARepor" [(model)]="valorARepor"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="is77">
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.saldo'"></label>
          <edit>
            <pl-edit type="number" attrName="saldo" [(model)]="saldo"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexod.quadro7.dotacao'"></label>
          <edit>
            <pl-edit type="number" attrName="dotacao" [(model)]="dotacao"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
