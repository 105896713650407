import {Component, Injector, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {IPlValidator, IPlValidatorValidateParams, isUndefined, PlCompsService, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {DocContabilidadeService} from '../../docContabilidade.service';
import {ICGConfigContabilidade} from '../../../../../../../services/config/config.service.interface';
import {IDocContabilidadePropertyNameOriginalData, IDocContabilidadePropertyNameOriginalValidateData} from '../../docContabilidade.interface';

@Component({
  selector: 'docscontabilidade-edit-valor-modal',
  templateUrl: './docsContabilidade.editValor.modal.component.html'
})
export class DocsContabilidadeEditValorModalComponent<T extends object, S extends string & keyof T> extends CGModalComponent<T> implements OnInit {
  @Input() public property: S;
  @Input() public type: string;
  @Input() public model: T;
  @Input() public maxDifference: number;
  @Input() public moeda: string;
  @Input() public podeUltrapassarTolerancia: boolean;

  public propertyData: IDocContabilidadePropertyNameOriginalData<T[S]>;
  public form: UntypedFormGroup;

  private _message: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plCompsService: PlCompsService,
    private readonly _docContabilidadeService: DocContabilidadeService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    const config: ICGConfigContabilidade = this._configService.configurations.contabilidade;
    if (isUndefined(this.maxDifference)) {
      this.maxDifference = config.diferencaIva;
    }
    if (isUndefined(this.moeda)) {
      this.moeda = config.moeda;
    }
    this.type = this._plTranslateService.translate(this.type).toLowerCase();
    this._message = this._plTranslateService.translate('docscontabilidade.valorValidatorMessage', {
      type: this.type,
      value: this.maxDifference,
      currency: this.moeda
    });
    this.propertyData = this._docContabilidadeService.initPropertyNameOriginal<T, S>(this.model, this.property);
  }

  public close(): void {
    if (this.form.valid) {
      super.close(this.model);
    }
  }

  public dismiss(): void {
    this.model[this.property] = <T[S]>(<unknown>this.propertyData.valorExistente);
    super.dismiss();
  }

  public readonly validateMaxDifference: () => IPlValidator<string, number> = () => this._validateMaxDifference();

  private _validateMaxDifference(): IPlValidator<string, number> {
    return {
      message: this._message,
      validate: ({formControlValue}: IPlValidatorValidateParams<string, number>) => {
        if (this.podeUltrapassarTolerancia) {
          return true;
        }
        const value: number = this._plCompsService.parseNumber(formControlValue);
        const validateData: IDocContabilidadePropertyNameOriginalValidateData = this.propertyData.validate(value, this.maxDifference);
        return validateData.valid;
      }
    };
  }
}
