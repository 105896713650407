import {TDate} from '../../../common/dates';
import {IJsonDocComercial} from '../../entities/docscomerciais/jsonDocComercial.entity.interface';

export const MODULE_NAME_EXTRATOS_FORNECEDORES = 'extratosFornecedores';
export const MODULE_NAME_EXTRATOS_CLIENTES = 'extratosClientes';

export interface IExtratosClifos {
  cambioSegMoeda: number;
  dataDoc: TDate;
  dataVenc: TDate;
  descricao: string;
  moeda: string;
  nCCusto: string;
  nConta: string;
  nDiario: number;
  nDocExterno: string;
  nDocumento: string;
  periodo: string;
  porPagar: number;
  valorCredito: number;
  valorCreditoSegMoeda: number;
  valorDebito: number;
  valorDebitoSegMoeda: number;
  saldo: number;
  extPocCabId: string;
  nLanc: string;
  descritivo: string;
  ano: number;
  nDocInterno: string;
  temDocDigital: boolean;
  perioDiarioDoc?: string;
  faccbId?: string;
}

export interface IExtratosClifosGrid extends IExtratosClifos {
  _thedoc?: IJsonDocComercial;
}
