import {IAtApiCommonBase} from '../../../common/interfaces/at.interfaces';
import {TDate} from '../../../common/dates';
import type dxDataGrid from 'devextreme/ui/data_grid';

export enum EFctTipoDocumento {
  DEFAULT,
  TOTAL,
  PARTIAL
}

export enum EFctSearchTipoDocumento {
  NONE,
  ADEF,
  ADEG,
  DOCP
}

export enum EFctLoginStatus {
  UNKNOWN,
  REQUIRE,
  OK
}

export enum EFctMessageType {
  NONE,
  ERROR,
  WARNING,
  CREDENTIALS_WARNING,
  SUCCESS
}

export enum EFctPedidoReembolsoEstadoContrato {
  TODOS,
  CDCI,
  CDSI,
  CRV
}

export interface IFctObterValorData {
  emitido: boolean;
  nissEmpregador: string;
  nomeEmpregador: string;
  anoMesRef: string;
  valorCent: number;
  valorString: string;
  valorPartialCent: number;
  valorPartialString: string;
  valorEmAtrasoCent: number;
}

export interface IFctObterDetalhesData {
  filename: string;
  stream: string;
}

export interface IFctDocumentoItem {
  dataEmissao: string;
  searchIdDoc: string;
  documento: string;
  dataPagamento: string;
  tipoPagamento: string;
  hash: string;
}

export interface IFctObterValor extends IAtApiCommonBase {
  data: IFctObterValorData;
}

export interface IFctObterDetalhes extends IAtApiCommonBase {
  data: IFctObterDetalhesData;
}

export interface IFctDocumentos extends IAtApiCommonBase {
  data: Array<IFctDocumentoItem>;
}

export interface IFctEmitirDocumento extends IAtApiCommonBase {
  data: string;
}

export interface IFctObterDocumento extends IAtApiCommonBase {
  data: string;
}

export interface IFctPedidoReembolso extends IAtApiCommonBase {
  data: Array<IFctPedidoReembolsoItem>;
}

export interface IFctPedidoReembolsoSubmit extends IAtApiCommonBase {
  data: IFctPedidoReembolsoSubmitData;
}

export interface IFctEmpresasDTItemMessage {
  msg: string;
  msgType: EFctMessageType;
}

export interface IFctEmpresa {
  nEmpresa: string;
  nome: string;
  valorFCTNoCG: number;
  email: string;
  lastMessages: Array<IFctEmpresasDTItemMessage>;
  pedidosReembolso: Array<IFctPedidoReembolsoEfetuadoDataGridItem>;
}

export interface IFctEmpresasDTItem extends IFctEmpresa {
  valor: number;
  emitido: boolean;
  mensagens: Array<IFctEmpresasDTItemMessage>;
  emitirDocumento: EFctTipoDocumento;
  loginStatus: EFctLoginStatus;
  valorEmAtrasoCent: number;
  _canViewDetails: boolean;
  _canSendEmail: boolean;
}

export interface IFctSearchFilters {
  startDate: TDate;
  endDate: TDate;
  tipoDocumento: EFctSearchTipoDocumento;
  nempresa: string;
  nome: string;
}

export interface IFctCredentials {
  niss: string;
  password: string;
}

export interface IFctEmitirDocPromptResult {
  sendEmail: boolean;
  downloadDoc: boolean;
}

export interface IFctEnviarDocPagam {
  success: boolean;
}

export interface IFctValueName {
  value: number;
  name: string;
}

export interface IFctSearchFiltersNEmpresa {
  nEmpresa: string;
  nome: string;
}

export interface IFctDecMensalRemunProcessExistentesFCT {
  empresa: string;
  nomeEmpresa: string;
  fctAno: number;
  fctMes: number;
  codemp: number;
  nome: string;
  niss: string;
  iban: string;
  modalidadeContrato: string;
  dataEntrada: TDate;
  dataInicioContrato: TDate;
  dataFimContrato: TDate;
  valorRetribuicaoMensal: number;
  valorDiuturnidade: number;
  qtdFaltasInjustificadas: number;
  valorFaltasInjustificadas: number;
  valorRetribuicaoMensalAnterior: number;
  valorDiuturnidadeAnterior: number;
  qtdFaltasInjustificadasAnterior: number;
  valorFaltasInjustificadasAnterior: number;
  totalFCT: number;
  totalFGCT: number;
}

export interface IFctPedidoReembolsoItem {
  rkey: string;
  ibanReembolso: string;
  niss: string;
  nome: string;
  dataCessacaoContrato: string;
  motivo: string;
  entregasPagas: string;
  numeroUPs: string;
  valorEstimadoReembolso: string;
  errorMessage: string;
}

export interface IFctPedidoReembolsoInsuccessItem {
  niss: string;
  nome: string;
  motivo: string;
}

export interface IFctPedidoReembolsoSuccessItem {
  niss: string;
  nome: string;
  dataCessContrato: string;
  estadoContrato: string;
  entregasPagas: string;
  numeroUPs: string;
  valorEstimadoReembolso: string;
}

export interface IFctPedidoReembolsoSubmitData {
  insuccessList: Array<IFctPedidoReembolsoInsuccessItem>;
  successList: Array<IFctPedidoReembolsoSuccessItem>;
}

export interface IFctPedidoReembolsoDataGridItem {
  nempresa: string;
  nomeEmpresa: string;
  haveCredentials: boolean;
  errorMessage: string;
  trabalhadores: Array<IFctPedidoReembolsoItem>;
  numTrabalhadores: string;
  pedidosReembolso: Array<IFctPedidoReembolsoEfetuadoDataGridItem>;
  _detailsDataGridInstance: dxDataGrid;
}

export interface IFctPedidoReembolsoFilters {
  nissTrabalhador: string;
  dataCessInicial: TDate;
  dataCessFinal: TDate;
  estadoContrato: EFctPedidoReembolsoEstadoContrato;
}

export interface IFctPedidoReembolsoEfetuadoDataGridItem {
  nomeEmpregado: string;
  valorEstimadoReembolso: string;
  niss: string;
}
