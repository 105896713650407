<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="!isEdit ? 'clifoExtraValues.modal.title' : 'clifoExtraValues.modal.editTitle'" [translateParams]="isEdit ? {title: definicaoEmail.descricao} : undefined"></h4>
</div>

<div class="modal-body">
  <pl-form *ngIf="!isEdit">
    <pl-group>
      <label [translate]="'clifoExtraValues.fields.codigo'"></label>
      <edit>
        <entity-autocomplete
          entity="clifoextravaluetipos"
          [model]="tipo"
          (modelChange)="changedTipoDefinicao($event)"
          output="description"
          [properties]="{allowEmpty: false, allowInvalid: false}"
          plAutoFocus>
        </entity-autocomplete>
      </edit>
    </pl-group>
  </pl-form>

  <dx-data-grid [cgDxDataGrid]="dataGridDefinitionModalEmails" [dataSource]="emails" (onInitialized)="onDataGridInitialized($event)" (onInitNewRow)="onDataGridInitNewRow($event)"></dx-data-grid>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-submit" [onClick]="close" [disabled]="!isEdit && (!tipo || !tipo.tipo)">
    <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
  </pl-button>

  <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
</div>
