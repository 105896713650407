import {PDFDocumentProxy, PDFProgressData, RenderTextMode} from 'ng2-pdf-viewer';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IPlPdfCallback, IPlPdfEventPageRendered, IPlPdfEvtConfigureToolbar, TPlPdfSource, TPlPdfZoomLevel} from 'pl-comps-angular';

const TOOLBAR_ID = 'cg-pdf-viewer-toolbar';
let uniqueToolbarId = 0;

@Component({
  selector: 'cg-pdf-viewer',
  templateUrl: './cg.pdf.viewer.component.html'
})
export class CGPdfViewerComponent implements OnInit, OnChanges {
  @Input() public pdfSrc: TPlPdfSource;
  @Input() public errorMessage: string;
  @Input() public externalLinkTarget: string;
  @Input() public filename: string;
  @Input() public contentType: string;
  @Input() public page: number;
  @Input() public renderText: boolean;
  @Input() public renderTextMode: RenderTextMode;
  @Input() public rotation: number;
  @Input() public showAll: boolean;
  @Input() public showBorders: boolean;
  @Input() public stickToPage: boolean;
  @Input() public toolbarInstanceId: string;
  @Input() public zoomLevel: TPlPdfZoomLevel;
  @Input() public callback: IPlPdfCallback;
  @Input() public hideToolbar: boolean;
  @Output() public readonly originalSizeChange: EventEmitter<boolean>;
  @Output() public readonly pageChange: EventEmitter<number>;
  @Output() public readonly zoomLevelChange: EventEmitter<TPlPdfZoomLevel>;
  @Output() public readonly evtConfigureToolbar: EventEmitter<IPlPdfEvtConfigureToolbar>;
  @Output() public readonly evtAfterLoadComplete: EventEmitter<PDFDocumentProxy>;
  @Output() public readonly evtPageRendered: EventEmitter<IPlPdfEventPageRendered>;
  @Output() public readonly evtTextLayerRendered: EventEmitter<CustomEvent>;
  @Output() public readonly evtError: EventEmitter<unknown>;
  @Output() public readonly evtOnProgress: EventEmitter<PDFProgressData>;
  @Output() public readonly evtStatus: EventEmitter<boolean>;

  constructor() {
    this.originalSizeChange = new EventEmitter<boolean>();
    this.pageChange = new EventEmitter<number>();
    this.zoomLevelChange = new EventEmitter<TPlPdfZoomLevel>();
    this.evtConfigureToolbar = new EventEmitter<IPlPdfEvtConfigureToolbar>();
    this.evtAfterLoadComplete = new EventEmitter<PDFDocumentProxy>();
    this.evtPageRendered = new EventEmitter<IPlPdfEventPageRendered>();
    this.evtTextLayerRendered = new EventEmitter<CustomEvent>();
    this.evtError = new EventEmitter<unknown>();
    this.evtOnProgress = new EventEmitter<PDFProgressData>();
    this.evtStatus = new EventEmitter<boolean>();
  }

  public ngOnInit(): void {
    this._handleChanges();
  }

  public ngOnChanges({toolbarInstanceId}: SimpleChanges): void {
    if (toolbarInstanceId && !toolbarInstanceId.isFirstChange()) {
      this._changedToolbarInstanceId(toolbarInstanceId.currentValue);
    }
  }

  public changedPage(value: number): void {
    this.page = value;
    this.pageChange.emit(this.page);
  }

  public changedZoomLevel(value: TPlPdfZoomLevel): void {
    this.zoomLevel = value;
    this.zoomLevelChange.emit(this.zoomLevel);
  }

  public configureToolbar(event: IPlPdfEvtConfigureToolbar): void {
    this.evtConfigureToolbar.emit(event);
  }

  public afterLoadComplete(pdf: PDFDocumentProxy): void {
    this.evtAfterLoadComplete.emit(pdf);
  }

  public pageRendered(event: IPlPdfEventPageRendered): void {
    this.evtPageRendered.emit(event);
  }

  public textLayerRendered(event: CustomEvent): void {
    this.evtTextLayerRendered.emit(event);
  }

  public errored(event: unknown): void {
    this.evtError.emit(event);
  }

  public progress(event: PDFProgressData): void {
    this.evtOnProgress.emit(event);
  }

  public status(event: boolean): void {
    this.evtStatus.emit(event);
  }

  private _handleChanges(): void {
    this._changedToolbarInstanceId();
  }

  private _changedToolbarInstanceId(value: string = this.toolbarInstanceId): void {
    this.toolbarInstanceId = value || `${TOOLBAR_ID}-${uniqueToolbarId++}`;
  }
}
