<pl-edit-number [attrName]="fieldName" inputClass="input-sm" [model]="model" (modelChange)="modelChanged($event)" [properties]="properties">
  <ng-container *inputGroup="{klass: 'btn-enabled hollow-form'}">
    <div class="item-group-dropdown" tabindex="-1" ngbDropdown container="body" [placement]="['bottom-right', 'bottom-left', 'top-right', 'top-left']">
      <div class="item-group-dropdown-toggle" ngbDropdownToggle>
        <i class="pl-icon-more-vertical" aria-hidden="true"></i>
      </div>
      <div class="dropdown-menu" ngbDropdownMenu>
        <button type="button" class="dropdown-item" (click)="obterSaldoConta()">
          <i class="fa fa-fw fa-money" aria-hidden="true"></i>&nbsp;<span [translate]="'docscontabilidade.text.obterSaldoConta'"></span>
        </button>

        <button type="button" class="dropdown-item" (click)="openRegularizacaoCampo40Modal()" *ngIf="linha.isRegularizacaoCampo40Art78">
          <i class="fa fa-fw fa-pencil" aria-hidden="true"></i>&nbsp;<span [translate]="'docscontabilidade.text.modificarRegularizacoesSujeitoPassivo'"></span>
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="moedaPresent">
    <div *inputGroup="{klass: classLockButton, disabled: locked, tabindex: -1}">
      <i class="fa" aria-hidden="true" [ngClass]="classLockIcon" (click)="doLock()" plDelegateEventDownwardsClick></i>
    </div>
  </ng-container>

  <ng-container *ngIf="allowChangeValor">
    <div *inputGroup="{klass: 'btn-light btn-enabled', disabled: locked, tabindex: -1}">
      <div class="spin-btns-container">
        <i class="fa fa-angle-up" [title]="'docscontabilidade.text.incValorIva' | translate" (click)="addValorTaxa(0.01)" plDelegateEventDownwardsClick aria-hidden="true"></i>

        <i class="fa fa-angle-down" [title]="'docscontabilidade.text.decValorIva' | translate" (click)="addValorTaxa(-0.01)" plDelegateEventDownwardsClick aria-hidden="true"></i>
      </div>
    </div>

    <div *inputGroup="{klass: 'btn-light btn-enabled', disabled: locked, tabindex: -1}">
      <i class="fa fa-pencil" [title]="'docscontabilidade.text.editValorIva' | translate" (click)="changeValorTaxa()" plDelegateEventDownwardsClick aria-hidden="true"></i>
    </div>
  </ng-container>
</pl-edit-number>
