import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IJsonFinanceiroContaOrdem, IJsonFinanceiroValoresEmAberto, IJsonFinanceiroValoresPagarReceber} from './jsonDashboardFinanceiro.module.interface';
import {IJsonDashboardsPercentagem} from '../../interfaces/jsonDashboards.interface';

@Injectable({
  providedIn: 'root'
})
export class DashboardFinanceiroService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/dashboardfinanceiro`;
  }

  public getFinanceiroContasAOrdem(force: boolean): TServiceResponse<Array<IJsonFinanceiroContaOrdem>> {
    return this._apiService.get<Array<IJsonFinanceiroContaOrdem>>({
      url: `${this._path}/contasaordem`,
      params: {force: force}
    });
  }

  public getFinanceiroValoresEmAberto(force: boolean): TServiceResponse<Array<IJsonFinanceiroValoresEmAberto>> {
    return this._apiService.get<Array<IJsonFinanceiroValoresEmAberto>>({
      url: `${this._path}/valoresemaberto`,
      params: {force: force}
    });
  }

  public getFinanceiroValorAReceberDeClientes(force: boolean): TServiceResponse<IJsonDashboardsPercentagem> {
    return this._apiService.get<IJsonDashboardsPercentagem>({
      url: `${this._path}/valorareceberdeclientes`,
      params: {force: force}
    });
  }

  public getFinanceiroValorAPagarAFornecedores(force: boolean): TServiceResponse<IJsonDashboardsPercentagem> {
    return this._apiService.get<IJsonDashboardsPercentagem>({
      url: `${this._path}/valorapagarafornecedores`,
      params: {force: force}
    });
  }

  public getFinanceiroTopValorAReceberClientes(top: number, force: boolean): TServiceResponse<Array<IJsonFinanceiroValoresPagarReceber>> {
    return this._apiService.get<Array<IJsonFinanceiroValoresPagarReceber>>({
      url: `${this._path}/topvalorareceberclientes`,
      params: {top: top, force: force}
    });
  }

  public getFinanceiroTopValorPagarAFornecedores(top: number, force: boolean): TServiceResponse<Array<IJsonFinanceiroValoresPagarReceber>> {
    return this._apiService.get<Array<IJsonFinanceiroValoresPagarReceber>>({
      url: `${this._path}/topvalorapagarfornecedores`,
      params: {top: top, force: force}
    });
  }
}
