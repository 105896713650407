import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ModuloComponent} from '../../../../components/module/module.component';
import {UntypedFormGroup} from '@angular/forms';
import {IJsonRHEstatisticasMultiItem, IRHEstatisticasMultiFilters, IRhEstatisticasPorEmpresaCallback} from '../rhEstatisticas.module.interface';
import moment from 'moment';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {RhEstatisticasModuleService} from '../rhEstatisticas.module.service';
import {IPlToolbarItem} from 'pl-comps-angular';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import dxDataGrid from 'devextreme/ui/data_grid';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';
import {RhEstatisticasMultiConfigModalComponent} from '../modals/config/rhEstatisticasMulti.config.modal';
import {RhEstatisticasPorEmpresaModalComponent} from '../modals/porEmpresa/rhEstatisticas.porEmpresa.modal';

const NUMBER_5 = 5;

@Component({
  selector: 'module-rh-estatisticas',
  templateUrl: './rhEstatisticas.module.component.html'
})
export class RhEstatisticasModuleComponent extends ModuloComponent implements OnInit {
  public readonly tabIdPorEmpresa: string = 'tabEmpresa';
  public readonly tabIdMultiEmpresa: string = 'tabMultiEmpresa';
  public readonly nEmpresa: string;
  public readonly anosList: Array<number>;

  public isFirstLoad: boolean;
  public formSearch: UntypedFormGroup;
  public filtersCollapsed: boolean;
  public activeTab: string;
  public dataGridMultiDefinition: IDevExpressDataGrid<IJsonRHEstatisticasMultiItem, string>;
  public dataGridMulti: dxDataGrid;
  public multiFilters: IRHEstatisticasMultiFilters;
  public porEmpresaCallback: IRhEstatisticasPorEmpresaCallback;

  private readonly _btnConfig: IPlToolbarItem;

  private _cardPanelMulti: CGCardPanelComponent;
  private _dataGridSource: Array<IJsonRHEstatisticasMultiItem>;
  private _isFirstLoad: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _rhEstatisticasModuleService: RhEstatisticasModuleService
  ) {
    super(_injector);
    this.anosList = [];
    this.porEmpresaCallback = {};
    this._isFirstLoad = true;
    this.activeTab = this.tabIdPorEmpresa;

    this.multiFilters = {
      ano: moment().year(),
      mes: moment().month() + 1
    };

    this._btnConfig = {
      id: 'config',
      caption: 'global.btn.config',
      class: 'btn-light',
      iconLeft: '<i class="fa fa-fw fa-cog" aria-hidden="true"></i>',
      type: 'button',
      click: () => this._showConfigModal(),
      visible: false
    };

    this.dataGridMultiDefinition = {
      columns: [
        {
          dataField: 'vf_actions',
          fixed: true,
          fixedPosition: 'left',
          caption: ' ',
          cellTemplate: 'cellTemplateActions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false,
          width: 100,
          alignment: 'center'
        },
        {dataField: 'nEmpresa', caption: 'rhEstatisticas.fields.nEmpresa', dataType: 'string', allowEditing: false, fixed: true, fixedPosition: 'left'},
        {dataField: 'nome', caption: 'rhEstatisticas.fields.nome', dataType: 'string', allowEditing: false},
        {dataField: 'totalAbonos', caption: 'rhEstatisticas.fields.abonos', dataType: 'currency', allowEditing: false, format: {type: 'currency', precision: 2}},
        {dataField: 'totalDescontos', caption: 'rhEstatisticas.fields.descontos', dataType: 'currency', allowEditing: false, format: {type: 'currency', precision: 2}},
        {
          dataField: 'totalBruto',
          caption: 'rhEstatisticas.fields.totalBruto',
          dataType: 'currency',
          allowEditing: false,
          format: {type: 'currency', precision: 2}
        },
        {
          dataField: 'totalImpostosColaborador',
          caption: 'rhEstatisticas.string.impostosColaborador',
          dataType: 'currency',
          allowEditing: false,
          format: {type: 'currency', precision: 2}
        },
        {dataField: 'totalSalarios', caption: 'rhEstatisticas.fields.totalLiquido', dataType: 'currency', allowEditing: false, format: {type: 'currency', precision: 2}},
        {dataField: 'totalEncargos', caption: 'rhEstatisticas.fields.encargosEmpresa', dataType: 'currency', allowEditing: false, format: {type: 'currency', precision: 2}},
        {dataField: 'totalCustos', caption: 'rhEstatisticas.fields.custoEmpresa', dataType: 'currency', allowEditing: false, format: {type: 'currency', precision: 2}}
      ],
      dataSource: new CustomStore({
        key: 'nEmpresa',
        load: () => {
          if (this._isFirstLoad) {
            return [];
          }
          return this._dataGridSource;
        }
      })
    };

    this.nEmpresa = this.session.erp.nEmpresa;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.isFirstLoad = true;
    this.toolbar.addButton(this._btnConfig);
    const year: number = moment().year();
    this.anosList.push(year + 1);
    for (let i = 0; i <= NUMBER_5; i++) {
      this.anosList.push(year - i);
    }
  }

  public activeIdChanged(id: string): void {
    this.activeTab = id;
    this._btnConfig.visible = this.activeTab === this.tabIdMultiEmpresa;
    if (this.activeTab === this.tabIdMultiEmpresa) {
      const filters = this.porEmpresaCallback.getFilters();
      this.multiFilters.ano = filters.anoDe;
      this.multiFilters.mes = filters.mesDe;
    } else {
      this.porEmpresaCallback.setFilterAnoMes(this.multiFilters.ano, this.multiFilters.mes);
    }
  }

  public onDataGridMultiInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this.dataGridMulti = component;
  }

  public readonly fnPesquisar = (): Promise<void> => this._pesquisar();

  public readonly fnViewMultiEmpresa = (nEmpresa: string, nomeEmpresa: string) => (): Promise<void> => this._viewMultiEmpresa(nEmpresa, nomeEmpresa);

  @ViewChild('cardPanelMulti')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanelMulti = value;
  }

  private _setMultiFiltersCollapsed(collapsed: boolean): void {
    if (collapsed && this._cardPanelMulti.fixedCardBody) {
      return;
    }
    this._cardPanelMulti.setCollapsed(collapsed);
  }

  private _viewMultiEmpresa(nEmpresa: string, nomeEmpresa: string): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(RhEstatisticasPorEmpresaModalComponent, {size: 'xxl', backdrop: 'static'});
    const componentInstance: RhEstatisticasPorEmpresaModalComponent = modalInstance.componentInstance;
    componentInstance.nEmpresa = nEmpresa;
    componentInstance.nomeEmpresa = nomeEmpresa;
    componentInstance.ano = this.multiFilters.ano;
    componentInstance.mes = this.multiFilters.mes;
    return modalInstance.result;
  }

  private _getDataGridSource(): Promise<void> {
    this._dataGridSource = [];
    return this._rhEstatisticasModuleService.getDataMultiEmpresas(this.multiFilters.ano, this.multiFilters.mes).then((response) => {
      this._dataGridSource = response.body;
      if (response.body.length > 0) {
        this._setMultiFiltersCollapsed(true);
      }
    });
  }

  private _pesquisar(): Promise<void> {
    this._isFirstLoad = false;
    return this._getDataGridSource().then(() => {
      this.dataGridMulti?.refresh();
    });
  }

  private _showConfigModal(): Promise<void> {
    return this._rhEstatisticasModuleService.getMultiConfig().then((response) => {
      const modalInstance = this._cgModalService.showVanilla(RhEstatisticasMultiConfigModalComponent, {size: 'lg', backdrop: 'static'});
      const componentInstance: RhEstatisticasMultiConfigModalComponent = modalInstance.componentInstance;
      componentInstance.config = response.body;
      return modalInstance.result;
    });
  }
}
