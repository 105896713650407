import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ContabilidadeEstatisticaService} from '../../contabilidadeEstatistica.module.service';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {IContabilidadeExtratosGridModel} from '../contabilidade.extratosGrid.module.interface';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {IReportInstance} from '../../../../../components/reports/input/reports.input.component.interface';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {PlAlertService, PlToolbarService} from 'pl-comps-angular';
import {IJsonEmailClifo} from '../../../../../components/emailsCliFo/jsonEmailCliFo.interface';
import {EmailClifoSendMailModalComponent} from '../../../../../components/emailsCliFo/modal/emailClifo.modal.component';

const DEFAULT_REPORT_NAME = 'Extract05.FR3';
const EXTRATOS_PDF_TOOLBAR_ID = 'extratos-pdf-toolbar-id';

@Component({
  selector: 'contabilidade-extratosgrid-modal',
  templateUrl: './contabilidade.extratosGrid.modal.component.html'
})
export class ContabilidadeExtratosGridModalComponent extends CGModalComponent<void> implements OnInit, OnDestroy {
  @Input() public header: IContabilidadeExtratosGridModel;

  public pdfUrl: string;
  public reportModel: IJsonReport;
  public toolbarInstanceId: string;

  private readonly _reportService: IReportInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _contabilidadeEstatisticaService: ContabilidadeEstatisticaService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plToolbarService: PlToolbarService
  ) {
    super(_injector);
    this._reportService = this._reportsRegistryService.get(EReport.ExtratosPorConta);
    this.toolbarInstanceId = EXTRATOS_PDF_TOOLBAR_ID;

    this._plToolbarService.registerInstance(this.toolbarInstanceId, {
      items: [
        {
          id: 'pdf-toolbar-sendmail',
          type: 'button',
          class: 'btn-primary',
          caption: 'emailclifos.sendMail.btn.sendMail',
          iconLeft: '<i class="fa fa-fw fa-envelope-o"></i>',
          order: 999,
          click: () => this._sendMail()
        }
      ]
    });
  }

  public ngOnInit(): void {
    this._loadDefaultReportModal().finally(() => {
      this._buildPdfUrl();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._plToolbarService.unRegisterInstance(this.toolbarInstanceId);
  }

  public reportModelChange(value: IJsonReport): void {
    if (value.name === this.reportModel.name) {
      return;
    }
    this.reportModel.name = value.name;
    this.reportModel.title = value.title;
    this._buildPdfUrl();
  }

  private _loadDefaultReportModal(): Promise<void> {
    this.reportModel = {
      name: DEFAULT_REPORT_NAME,
      title: DEFAULT_REPORT_NAME
    };
    return this._reportService.query().then((reports: Array<IJsonReport>) => {
      if (!reports.length) {
        return;
      }
      let foundDefault = false;
      for (const report of reports) {
        if (report.name === this.reportModel.name) {
          this.reportModel = {name: report.name, title: report.title};
          foundDefault = true;
          break;
        }
      }
      if (!foundDefault) {
        this.reportModel = reports[0];
      }
    });
  }

  private _sendMail(): Promise<void | HttpResponse<IJsonEmailClifo>> {
    return this._contabilidadeEstatisticaService.getExtratosPorContaEmailContent(this.header.nConta).then((response) => {
      if (!response.body.nConta) {
        response.body.nConta = this.header.nConta;
      }
      const modalInstance = this._cgModalService.showVanilla(EmailClifoSendMailModalComponent);
      const componentInstance: EmailClifoSendMailModalComponent = modalInstance.componentInstance;
      componentInstance.emailContent = response.body;
      componentInstance.numEmails = 1;
      return modalInstance.result.then((emailContent: IJsonEmailClifo) => {
        return this._contabilidadeEstatisticaService
          .sendMailExtratosPorConta(this.header.dePeriodo, this.header.atePeriodo, this.header.deNDiario, this.header.ateNDiario, this.reportModel.name, emailContent)
          .then(() => {
            this._plAlertService.success('emailclifos.sendMail.messages.success');
          });
      });
    });
  }

  private _buildPdfUrl(): void {
    this._contabilidadeEstatisticaService
      .getExtratosPorContaUrl(this.header.nConta, this.header.dePeriodo, this.header.atePeriodo, this.header.deNDiario, this.header.ateNDiario, this.reportModel.name)
      .subscribe((url: string) => {
        this.pdfUrl = url;
      });
  }
}
