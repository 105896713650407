import {ENTITY_NAME_BANCOS} from './bancos.entity.interface';
import {ENTITY_NAME_ID_BANCOS} from '../idbancos/idBancos.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonBanco} from './jsonBanco.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_BANCOS: IEntityDefinition<IJsonBanco> = {
  name: ENTITY_NAME_BANCOS,
  roles: [ROLE.RH],
  searchPlaceholder: 'bancos.pesquisa',
  metadata: {
    keyName: 'codBanco',
    fields: [
      {name: 'codBanco', caption: 'bancos.fields.codBanco', validators: {required: true, maxlength: 4}},
      {name: 'nome', caption: 'bancos.fields.nome', validators: {required: true, maxlength: 60}},
      {name: 'designaBreve', caption: 'bancos.fields.designaBreve', validators: {maxlength: 15}},
      {
        name: 'idInterBancaria',
        visible: false,
        validators: {maxlength: 5}
      },
      {
        name: 'nomeInterBancaria',
        caption: 'bancos.fields.idInterBancaria',
        entity: {
          name: ENTITY_NAME_ID_BANCOS,
          keyTarget: 'idInterBancaria',
          outputTarget: 'nomeInterBancaria',
          fieldsMap: {
            idinterBancario: 'idInterBancaria',
            nomeInterBancario: 'nomeInterBancaria'
          }
        },
        validators: {required: true}
      },
      {name: 'codSwift', caption: 'bancos.fields.codSwift', validators: {maxlength: 20}}
    ],
    order: 'codBanco',
    searchFields: 'codBanco,nome,designaBreve,idInterBancaria,codSwift'
  },
  autocomplete: {
    rowTemplate: '{{codBanco}} - {{nome}}',
    output: 'nome',
    searchFields: 'codBanco,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  }
};
