import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {
  IRHRegistarEmpregadosSSCessarVinculoEmpregadoSSData,
  IRHRegistarEmpregadosSSConfiguracaoWS,
  IRHRegistarEmpregadosSSContratosBase,
  IRHRegistarEmpregadosSSContratosItem,
  IRHRegistarEmpregadosSSEmpregadoInfo,
  IRHRegistarEmpregadosSSFilterModel,
  IRHRegistarEmpregadosSSOperationResponse,
  IRHRegistarEmpregadosSSRegistarContratoResponse,
  IRHRegistarEmpregadosSSRegistarEmpregadoSSData,
  IRHRegistarEmpregadosSSRegistarPeriodoRendimentoData,
  IRHRegistarEmpregadosSSVinculosEmpregadoItem
} from './rhRegistarEmpregadosSS.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class RHRegistarEmpregadosSSService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${_apiService.path.restapi}/rhempexternalcommunications`;
  }

  public consultarEmpregadosSS(filters: IRHRegistarEmpregadosSSFilterModel): TServiceResponse<Array<IRHRegistarEmpregadosSSVinculosEmpregadoItem>> {
    return this._apiService.get({
      url: `${this._path}/consultarEmpregadosSS`,
      params: {
        deCodEmp: filters.deCodEmp,
        ateCodEmp: filters.ateCodEmp,
        dataIni: filters.dataIni,
        dataFim: filters.dataFim
      },
      reportExceptions: false
    });
  }

  public consultarContratosEmpregadosSS(filters: IRHRegistarEmpregadosSSFilterModel): TServiceResponse<Array<IRHRegistarEmpregadosSSContratosItem>> {
    return this._apiService.get({
      url: `${this._path}/consultarContratosEmpregadosSS`,
      params: {
        deCodEmp: filters.deCodEmp,
        ateCodEmp: filters.ateCodEmp,
        dataIni: filters.dataIni,
        dataFim: filters.dataFim
      },
      reportExceptions: false
    });
  }

  public getConfiguracaoWS(): TServiceResponse<IRHRegistarEmpregadosSSConfiguracaoWS> {
    return this._apiService.get({url: `${this._path}/configuracaoWS`});
  }

  public saveConfiguracaoWS(config: IRHRegistarEmpregadosSSConfiguracaoWS): TServiceResponse<void> {
    return this._apiService.post<void, IRHRegistarEmpregadosSSConfiguracaoWS>({url: `${this._path}/configuracaoWS`, body: config});
  }

  public atualizarEstadoEmpregadosSS(filters: IRHRegistarEmpregadosSSFilterModel): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/atualizarEstadoEmpregadosSS`,
      params: {
        deCodEmp: filters.deCodEmp,
        ateCodEmp: filters.ateCodEmp,
        dataIni: filters.dataIni,
        dataFim: filters.dataFim
      },
      reportExceptions: false
    });
  }

  public registarContrato(contratos: Array<IRHRegistarEmpregadosSSContratosBase>): TServiceResponse<IRHRegistarEmpregadosSSRegistarContratoResponse> {
    return this._apiService.post<IRHRegistarEmpregadosSSRegistarContratoResponse, Array<IRHRegistarEmpregadosSSContratosBase>>({
      url: `${this._path}/registarContrato`,
      body: contratos,
      reportExceptions: false
    });
  }

  public registarEmpregadoSS(data: IRHRegistarEmpregadosSSRegistarEmpregadoSSData): TServiceResponse<IRHRegistarEmpregadosSSOperationResponse> {
    return this._apiService.post<IRHRegistarEmpregadosSSOperationResponse, IRHRegistarEmpregadosSSRegistarEmpregadoSSData>({
      url: `${this._path}/registarEmpregadoSS`,
      body: data
    });
  }

  public cessarVinculoEmpregadoSS(data: IRHRegistarEmpregadosSSCessarVinculoEmpregadoSSData): TServiceResponse<IRHRegistarEmpregadosSSOperationResponse> {
    return this._apiService.post<IRHRegistarEmpregadosSSOperationResponse, IRHRegistarEmpregadosSSCessarVinculoEmpregadoSSData>({
      url: `${this._path}/cessarVinculoEmpregadoSS`,
      body: data
    });
  }

  public registarPeriodoRendimentoSS(data: IRHRegistarEmpregadosSSRegistarPeriodoRendimentoData): TServiceResponse<IRHRegistarEmpregadosSSOperationResponse> {
    return this._apiService.post<IRHRegistarEmpregadosSSOperationResponse, IRHRegistarEmpregadosSSRegistarPeriodoRendimentoData>({
      url: `${this._path}/registarPeriodoRendimento`,
      body: data
    });
  }

  public getEmpregadoInfo(codEmp: number, reportExceptions: boolean = true): TServiceResponse<IRHRegistarEmpregadosSSEmpregadoInfo> {
    return this._apiService.get({url: `${this._path}/empregado/${codEmp}`, reportExceptions: reportExceptions});
  }
}
