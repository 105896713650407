<div class="site-standalone-container">
  <h1 [translate]="'maintenance.title'"></h1>

  <div class="alert alert-info" [innerHTML]="'maintenance.message' | translate"></div>

  <div class="site-standalone-container-actions">
    <button type="button" class="btn btn-light" [click]="fnBack" plPromise><i class="fa fa-fw fa-angle-left"></i>&nbsp;<span [translate]="'entity.action.back'"></span></button>

    <button type="button" class="btn btn-primary" *ngIf="!partnerMode && !hybridMode" [click]="fnLogin" plPromise>
      <i class="fa fa-fw fa-sign-in"></i>&nbsp;<span [translate]="'global.menu.account.login'"></span>
    </button>
  </div>
</div>
