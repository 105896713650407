import {Injectable} from '@angular/core';
import {copy} from 'pl-comps-angular';
import {ECompanyStatusInstance, IApiJobStatus} from './companystatus.interface';
import {IStatusService} from '../../../services/generic/status.service.interface';
import {FimAnoService} from '../../../modules/portalcontabilidade/fimano/fimAno.module.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyStatusRegistryService {
  private readonly _instances: Map<ECompanyStatusInstance, IStatusService<string | number, IApiJobStatus<string | number>>>;

  constructor(private readonly _fimAnoService: FimAnoService) {
    this._instances = new Map<ECompanyStatusInstance, IStatusService<string | number, IApiJobStatus<string | number>>>([[ECompanyStatusInstance.FimAno, this._fimAnoService]]);
  }

  public add(name: ECompanyStatusInstance, service: IStatusService<string | number, IApiJobStatus<string | number>>): this {
    this._instances.set(name, service);
    return this;
  }

  public get<T extends string | number, S extends IApiJobStatus<T>>(name: ECompanyStatusInstance): IStatusService<T, S> {
    return <IStatusService<T, S>>copy(this._instances.get(name));
  }

  public getAll(): Array<IStatusService<string | number, IApiJobStatus<string | number>>> {
    const instances: Array<IStatusService<string | number, IApiJobStatus<string | number>>> = [];
    this._instances.forEach((instance: IStatusService<string | number, IApiJobStatus<string | number>>) => {
      instances.push(copy<IStatusService<string | number, IApiJobStatus<string | number>>>(instance));
    });
    return instances;
  }
}
