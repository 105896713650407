import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {Observable} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {IJsonImpCtrIrs} from './jsonImpCtrIrs.module.interface';

@Injectable({
  providedIn: 'root'
})
export class ImpCtrlIrsService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/impctrirs`;
  }

  public getListagemImpCtrlIrsUrl(filters: IJsonImpCtrIrs, listaEmpresas: string, reportName: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report`, {
      deData: filters.deData,
      ateData: filters.ateData,
      deCodEmp: filters.deCodEmp,
      ateCodEmp: filters.ateCodEmp,
      reportName: reportName,
      nCopia: filters.nCopia,
      listaempresas: listaEmpresas
    });
  }
}
