import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {
  EMapaPagamEntity,
  EMapaPagamManualDeleteMethod,
  IJsonMapaCreatePagamManual,
  IJsonMapaEditPagamManual,
  IJsonMapaPagamATLoginModel,
  IJsonMapaPagamEmpresasEnvio,
  IJsonMapaPagamEmpresasEnvioResumo,
  IJsonMapaPagamentoConfig,
  IJsonMapaPagamentoLoadEmpresasFilters,
  IJsonMapaPagamentoUserConfig,
  IJsonMapaPagamLoadResults,
  IJsonMapaPagamPagManuaisResults,
  IJsonMapaPagamPagManuaisSaveData,
  IJsonMapaPagamResponse,
  IJsonMapaPagamState,
  IMapaPagamentoResponsavel
} from './mapaPagamentos.module.interface';
import {IJsonERPUtilizador} from '../../../interfaces/jsonERPUtilizador.interface';
import {HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapaPagamentosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/mapaPagam`;
  }

  public loadEmpresas(filters: IJsonMapaPagamentoLoadEmpresasFilters): TServiceResponse<IJsonMapaPagamLoadResults> {
    return this._apiService.get({
      url: `${this._path}/load-empresas`,
      params: filters
    });
  }

  public getConfig(): TServiceResponse<IJsonMapaPagamentoConfig> {
    return this._apiService.get({
      url: `${this._path}/config`
    });
  }

  public saveConfig(data: IJsonMapaPagamentoUserConfig): TServiceResponse<void> {
    return this._apiService.post<void, IJsonMapaPagamentoUserConfig>({
      url: `${this._path}/config`,
      body: data
    });
  }

  public loadEmpresasComPagamentos(ano: number, mes: number, empresas: Array<string>): TServiceResponse<void> {
    return this._apiService.post<void, Array<string>>({
      params: {
        ano: ano,
        mes: mes
      },
      body: empresas,
      url: `${this._path}/load-empresas-pagamentos`
    });
  }

  public createPagamentoManual(data: IJsonMapaCreatePagamManual): TServiceResponse<IJsonMapaPagamLoadResults> {
    return this._apiService.post<IJsonMapaPagamLoadResults, IJsonMapaCreatePagamManual>({
      body: data,
      url: `${this._path}/create-pagamento`
    });
  }

  public editPagamentoManual(nEmpresa: string, pagamentoGUID: string, ano: number, mes: number, data: IJsonMapaEditPagamManual): TServiceResponse<IJsonMapaPagamLoadResults> {
    return this._apiService.post<IJsonMapaPagamLoadResults, IJsonMapaEditPagamManual>({
      body: data,
      url: `${this._path}/${nEmpresa}/editar-pagamento/${pagamentoGUID}/${ano}/${mes}`
    });
  }

  public getPagamentoManual(nEmpresa: string, pagamentoGUID: string, ano: number, mes: number): TServiceResponse<IJsonMapaEditPagamManual> {
    return this._apiService.get<IJsonMapaEditPagamManual>({
      url: `${this._path}/${nEmpresa}/pagamento-manual/${pagamentoGUID}/${ano}/${mes}`
    });
  }

  public marcarPagamentoManualPago(nEmpresa: string, pagManTaskId: string): TServiceResponse<void> {
    return this._apiService.put({
      url: `${this._path}/${nEmpresa}/pagamento-pago/${pagManTaskId}`
    });
  }

  public stopMS(): TServiceResponse<void> {
    return this._apiService.post({
      url: this.stopMSUrl()
    });
  }

  public stopMSUrl(): string {
    return `${this._path}/stop-ms`;
  }

  public enviarPagamentos(data: IJsonMapaPagamEmpresasEnvio, skipErrors: boolean = false): TServiceResponse<void> {
    return this._apiService.post<void, IJsonMapaPagamEmpresasEnvio>({
      body: data,
      params: {skipErrors: skipErrors},
      url: `${this._path}/enviar-pagamentos`
    });
  }

  public obterResumoEnvio(): TServiceResponse<IJsonMapaPagamEmpresasEnvioResumo> {
    return this._apiService.get<IJsonMapaPagamEmpresasEnvioResumo>({
      url: `${this._path}/resumo-envio`
    });
  }

  public getStatus(): TServiceResponse<IJsonMapaPagamState> {
    return this._apiService.get<IJsonMapaPagamState>({
      url: `${this._path}/status`
    });
  }

  public getViewDocumentATUrl(nEmpresa: string, pagamentoGUID: string): string {
    return `${this._path}/${nEmpresa}/view-at-document/${pagamentoGUID}`;
  }

  public getEmpresasComPagamentos(): TServiceResponse<IJsonMapaPagamLoadResults> {
    return this._apiService.get<IJsonMapaPagamLoadResults>({
      url: `${this._path}/empresas-pagamentos`
    });
  }

  public saveEntityCredentials(entity: EMapaPagamEntity, nEmpresa: string, data: IJsonMapaPagamATLoginModel): TServiceResponse<IJsonMapaPagamResponse> {
    return this._apiService.post<IJsonMapaPagamResponse, IJsonMapaPagamATLoginModel>({
      body: data,
      url: `${this._path}/${nEmpresa}/save-credentials/${entity}`
    });
  }

  public getEntityCredentials(entity: EMapaPagamEntity, nEmpresa: string): TServiceResponse<IJsonMapaPagamATLoginModel> {
    return this._apiService.get<IJsonMapaPagamATLoginModel>({
      url: `${this._path}/${nEmpresa}/get-credentials/${entity}`
    });
  }

  public getDocumentoUnicoPdf(nEmpresa: string, pagamentos: Array<string>): TServiceResponse<Blob> {
    return this._apiService.post<Blob, Array<string>>({
      url: `${this._path}/${nEmpresa}/documento-unico-pdf`,
      responseType: 'blob',
      body: pagamentos
    });
  }

  public deletePagamentoManual(nEmpresa: string, pagamGUID: string, method: EMapaPagamManualDeleteMethod, ano: number, mes: number): TServiceResponse<void> {
    return this._apiService.delete<void>({
      params: {ano: ano, mes: mes},
      url: `${this._path}/${nEmpresa}/apagar-pagamento/${pagamGUID}/${method}`
    });
  }

  public getResponsaveis(): Promise<Array<IMapaPagamentoResponsavel>> {
    return this._apiService.get<Array<IJsonERPUtilizador>>({url: `${this._path}/erpusers`}).then((response: HttpResponse<Array<IJsonERPUtilizador>>) => {
      return response.body.map<IMapaPagamentoResponsavel>((erpUser: IJsonERPUtilizador) => {
        return {
          nResponsavel: erpUser.nutilizador,
          nomeResponsavel: erpUser.nomeCompleto
        };
      });
    });
  }

  public getAttachDocumentUrl(nEmpresa: string, pagamGUID: string, ano: number, mes: number): Observable<string> {
    return of(`${this._path}/${nEmpresa}/${pagamGUID}/${ano}/${mes}/upload`);
  }

  public saveEmails(nEmpresa: string, emails: string): TServiceResponse<void> {
    return this._apiService.post<void, string>({
      url: `${this._path}/${nEmpresa}/save-emails`,
      body: emails
    });
  }

  public getPagMan(ano: number): TServiceResponse<IJsonMapaPagamPagManuaisResults> {
    return this._apiService.get<IJsonMapaPagamPagManuaisResults>({
      url: `${this._path}/pagamentos-manuais/${ano}`
    });
  }

  public savePagMan(ano: number, nome: string, empresas: Array<string>, meses: Array<number>): TServiceResponse<void> {
    return this._apiService.post<void, IJsonMapaPagamPagManuaisSaveData>({
      url: `${this._path}/pagamentos-manuais`,
      body: {ano: ano, nomePagamento: nome, meses: meses, empresas: empresas}
    });
  }
}
