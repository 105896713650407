import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {RenderTextMode} from 'ng2-pdf-viewer';
import {
  IPlPdfCallback,
  IPlPdfEvtConfigureToolbar,
  IPlToolbarInstance,
  IPlToolbarItem,
  IPlToolbarMenuItem,
  isBoolean,
  isDefined,
  isFunction,
  isObject,
  Logger,
  PlToolbarService,
  timeout,
  TPlPdfSource,
  TPlPdfZoomLevel
} from 'pl-comps-angular';
import {ContabilidadeDigitalServiceDocuments} from '../../../../services/contabilidadedigital/contabilidadedigital.service.documents';
import {ContabilidadeDigitalService} from '../../../../services/contabilidadedigital/contabilidadedigital.service';
import {EContabDigitalDocumentRotateTo} from '../../../../services/contabilidadedigital/contabilidadedigital.interface';
import {IArquivoDigitalDocViewerCallback, IArquivoDigitalDocViewerEvtConfigureToolbar} from './arquivodigital.docviewer.component.interface';

const TOOLBAR_GROUP_ID = 'arquivodigital-docviewer';
const TOOLBAR_ITEM_ORDER = 370;
const STEP_ROTATION = 90;
const MAX_STEP_ROTATION = 3;
const MAX_ROTATION_RIGHT = STEP_ROTATION * MAX_STEP_ROTATION;
const MAX_ROTATION_LEFT = MAX_ROTATION_RIGHT * -1;
let TOOLBAR_ID = 0;

@Component({
  selector: 'arquivodigital-docviewer',
  templateUrl: './arquivodigital.docviewer.component.html'
})
export class ArquivoDigitalDocViewerComponent implements OnInit, OnChanges {
  @Input() public documentsService: ContabilidadeDigitalServiceDocuments;
  @Input() public docId: string;
  @Input() public toolbarInstanceId: string;
  @Input() public hideToolbarOnEmptyDoc: boolean;
  @Input() public nDocumento: string;
  @Input() public pdfSrc: TPlPdfSource;
  @Input() public errorMessage: string;
  @Input() public externalLinkTarget: string;
  @Input() public filename: string;
  @Input() public page: number;
  @Input() public renderText: boolean;
  @Input() public renderTextMode: RenderTextMode;
  @Input() public showAll: boolean;
  @Input() public showBorders: boolean;
  @Input() public stickToPage: boolean;
  @Input() public zoomLevel: TPlPdfZoomLevel;
  @Input() public callback: IArquivoDigitalDocViewerCallback;
  @Output() public readonly docIdChange: EventEmitter<string>;
  @Output() public readonly originalSizeChange: EventEmitter<boolean>;
  @Output() public readonly pageChange: EventEmitter<number>;
  @Output() public readonly zoomLevelChange: EventEmitter<TPlPdfZoomLevel>;
  @Output() public readonly evtConfigureToolbar: EventEmitter<IArquivoDigitalDocViewerEvtConfigureToolbar>;

  public readonly callbackPdf: IPlPdfCallback;
  public pdfUrl: string;
  public rotation: number;
  public showNDocumento: boolean;

  private readonly _btnRotateLeft: IPlToolbarMenuItem;
  private readonly _btnRotateRight: IPlToolbarMenuItem;
  private readonly _mnuRotate: IPlToolbarItem;
  private _toolbarInstance: IPlToolbarInstance;
  private _toolbarInstanceOwned: boolean;

  constructor(
    private readonly _logger: Logger,
    private readonly _plToolbarService: PlToolbarService,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService
  ) {
    this.documentsService = this._contabilidadeDigitalService.documents;
    this.docIdChange = new EventEmitter<string>();
    this.originalSizeChange = new EventEmitter<boolean>();
    this.pageChange = new EventEmitter<number>();
    this.zoomLevelChange = new EventEmitter<TPlPdfZoomLevel>();
    this.evtConfigureToolbar = new EventEmitter<IArquivoDigitalDocViewerEvtConfigureToolbar>();
    this.callbackPdf = {};
    this.pdfUrl = '';
    this.rotation = 0;
    this.showNDocumento = false;
    this._btnRotateLeft = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'rotate-left',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-undo"></i>',
      class: 'btn-light',
      disabled: true,
      title: 'arquivodigital.docviewer.actions.rotateLeft',
      click: () => {
        this._documentRotateLeft();
      }
    };
    this._btnRotateRight = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'rotate-right',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-repeat"></i>',
      class: 'btn-light',
      disabled: true,
      title: 'arquivodigital.docviewer.actions.rotateRight',
      click: () => {
        this._documentRotateRight();
      }
    };
    this._mnuRotate = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'group-rotate',
      order: TOOLBAR_ITEM_ORDER,
      type: 'button-group',
      items: [this._btnRotateLeft, this._btnRotateRight]
    };
    this._toolbarInstanceOwned = false;
  }

  public ngOnInit(): void {
    this._handleChanges();
  }

  public ngOnChanges({toolbarInstanceId, docId, hideToolbarOnEmptyDoc, callback}: SimpleChanges): void {
    if (toolbarInstanceId && !toolbarInstanceId.isFirstChange()) {
      this._changedToolbarInstanceId(toolbarInstanceId.currentValue);
    }
    if (docId && !docId.isFirstChange()) {
      this._changedDocId(docId.currentValue);
    }
    if (hideToolbarOnEmptyDoc && !hideToolbarOnEmptyDoc.isFirstChange()) {
      this._changedHideToolbarOnEmptyDoc(hideToolbarOnEmptyDoc.currentValue);
    }
    if (callback && !callback.isFirstChange()) {
      this._changedCallback(callback.currentValue);
    }
  }

  public configureToolbar(event: IPlPdfEvtConfigureToolbar): void {
    this._contabilidadeDigitalService.configureToolbar(event);
    this.evtConfigureToolbar.emit({
      ...event,
      btnRotateLeft: this._btnRotateLeft,
      btnRotateRight: this._btnRotateRight,
      mnuRotate: this._mnuRotate
    });
  }

  public changedPage(value: number): void {
    this.page = value;
    this.pageChange.emit(this.page);
  }

  public changedZoomLevel(value: TPlPdfZoomLevel): void {
    this.zoomLevel = value;
    this.zoomLevelChange.emit(this.zoomLevel);
  }

  public changedPdfStatus(status: boolean): void {
    this._btnRotateLeft.disabled = !status;
    this._btnRotateRight.disabled = !status;
  }

  public afterLoadComplete(): void {
    this.showNDocumento = true;
  }

  private _handleChanges(): void {
    this._changedToolbarInstanceId();
    this._changedDocId();
    this._changedHideToolbarOnEmptyDoc();
    this._changedCallback();
  }

  private _changedToolbarInstanceId(toolbarInstanceId: string = this.toolbarInstanceId): void {
    this._cleanupToolbar();
    this.toolbarInstanceId = toolbarInstanceId || `${TOOLBAR_GROUP_ID}-${TOOLBAR_ID++}`;
    this._toolbarInstanceOwned = !this._plToolbarService.isRegistered(this.toolbarInstanceId);
    this._toolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceId);
    this._toolbarInstance.removeGroupId(TOOLBAR_GROUP_ID).addButton(this._mnuRotate);
  }

  private _changedDocId(docId: string = this.docId): void {
    this.docId = docId;
    this.rotation = 0;
    this.showNDocumento = false;
    if (!this.docId) {
      this.pdfUrl = undefined;
      return;
    }
    this.documentsService.getUrl(this.docId, false, true).subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }

  private _changedHideToolbarOnEmptyDoc(value: boolean = this.hideToolbarOnEmptyDoc): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = true;
    }
    this.hideToolbarOnEmptyDoc = val;
  }

  private _changedCallback(value: IArquivoDigitalDocViewerCallback = this.callback): void {
    if (isObject(value)) {
      value.pdf = this.callbackPdf;
      const clearFn: IArquivoDigitalDocViewerCallback['clear'] = value.clear;
      value.clear = async () => {
        if (isFunction(clearFn)) {
          await clearFn().catch((reason: unknown) => {
            this._logger.error(reason);
          });
        }
        return this._clear();
      };
    }
  }

  private _documentRotateLeft(): void {
    this.rotation -= STEP_ROTATION;
    if (this.rotation < MAX_ROTATION_LEFT) {
      this.rotation = 0;
    }
    this._rotateDocument(EContabDigitalDocumentRotateTo.LEFT);
  }

  private _documentRotateRight(): void {
    this.rotation += STEP_ROTATION;
    if (this.rotation > MAX_ROTATION_RIGHT) {
      this.rotation = 0;
    }
    this._rotateDocument(EContabDigitalDocumentRotateTo.RIGHT);
  }

  private _rotateDocument(rotateTo: EContabDigitalDocumentRotateTo): void {
    this.documentsService.rotate(this.docId, rotateTo).catch((reason: HttpErrorResponse) => {
      this._logger.error(reason);
    });
  }

  private _cleanupToolbar(): void {
    if (this._toolbarInstance) {
      if (this._toolbarInstanceOwned) {
        this._plToolbarService.unRegisterInstance(this._toolbarInstance);
        this._toolbarInstanceOwned = false;
      } else {
        this._toolbarInstance.removeGroupId(TOOLBAR_GROUP_ID);
      }
      this._toolbarInstance = undefined;
    }
  }

  private _clear(): Promise<void> {
    this.docId = undefined;
    this.docIdChange.emit(this.docId);
    this.pdfUrl = isDefined(this.pdfUrl) ? undefined : null;
    this.showNDocumento = false;
    return timeout();
  }
}
