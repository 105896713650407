import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonUnidadeMovimento} from './jsonUnidadeMovimento.entity.interface';
import {DATA_SOURCE_NAME_TIPOS_MOVIMENTO} from '../../datasources/tiposmovimento/tiposMovimento.datasource.interface';
import {ENTITY_NAME_UNECE} from '../unidadesmedidacomerciointernacional/unece.entity.interface';
import {ENTITY_NAME_UNIDADES_MOVIMENTO} from './unidadesMovimento.entity.interface';

export const ENTITY_UNIDADES_MOVIMENTO: IEntityDefinition<IJsonUnidadeMovimento> = {
  name: ENTITY_NAME_UNIDADES_MOVIMENTO,
  roles: [ROLE.ERP],
  searchPlaceholder: 'unidadesmovimento.pesquisa',
  metadata: {
    keyName: 'codUni',
    fields: [
      {
        name: 'codUni',
        type: 'cgsmallint',
        width: '120px',
        caption: 'unidadesmovimento.fields.codUni',
        placeholder: 'unidadesmovimento.fields.codUniPlaceholder',
        validators: {required: true, min: 1, maxlength: 4, max: 9999}
      },
      {
        name: 'nome',
        caption: 'unidadesmovimento.fields.nome',
        placeholder: 'unidadesmovimento.fields.nomePlaceholder',
        validators: {required: true, maxlength: 20}
      },
      {
        name: 'nomeAbrev',
        caption: 'unidadesmovimento.fields.nomeAbrev',
        placeholder: 'unidadesmovimento.fields.nomeAbrevPlaceholder',
        validators: {required: true, maxlength: 5}
      },
      {
        name: 'tipo',
        caption: 'unidadesmovimento.fields.tipo',
        placeholder: 'unidadesmovimento.fields.tipoPlaceholder',
        type: DATA_SOURCE_NAME_TIPOS_MOVIMENTO
      },
      {
        name: 'qtdRefUnidad',
        type: 'number',
        caption: 'unidadesmovimento.fields.qtdRefUnidad',
        placeholder: 'unidadesmovimento.fields.qtdRefUnidadPlaceholder',
        properties: {decimalsLimit: 2},
        validators: {required: true}
      },
      {
        name: 'codUnece',
        caption: 'unidadesmovimento.fields.codUnece',
        validators: {required: true},
        entity: {name: ENTITY_NAME_UNECE, keyTarget: 'codigo', outputTarget: 'codUnece'}
      }
    ],
    order: 'nome',
    searchFields: 'codUni,nome,nomeAbrev'
  },
  autocomplete: {
    rowTemplate: '{{codUni}} - {{nome}} ({{nomeAbrev}})',
    output: 'nome',
    searchFields: 'codUni,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.unidadesmovimento'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.unidadesmovimento'
      }
    }
  }
};
