import {Directive, Injector} from '@angular/core';
import {DocContabilidadeFieldAutocomplete} from './docContabilidade.field.autocomplete';
import {EPreDefinidoContabTipoConta} from '../../../../manutencao/predefinidos/jsonPreDefinidosContab.module.interface';
import {ETipoContaContabilidade} from '../../../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {EDocContabilidadeField, IDocContabilidadeEventConta} from '../docContabilidade.interface';
import {IDocContabilidadeLinha} from '../../../docsContabilidade.interface';
import {IJsonPOC} from '../../../../../../entities/pocs/jsonPOC.entity.interface';

@Directive()
export abstract class DocContabilidadeFieldConta extends DocContabilidadeFieldAutocomplete<IJsonPOC, IDocContabilidadeEventConta> {
  public filter: string;

  protected constructor(
    public readonly fieldName: EDocContabilidadeField,
    protected readonly _injector: Injector
  ) {
    super(fieldName, _injector, 'conta');
  }

  protected abstract _notValidDc(): boolean;

  protected _handleLinhaChanged(linha: IDocContabilidadeLinha): void {
    super._handleLinhaChanged(linha);
    this._buildFilter();
  }

  protected _disabled(linha: IDocContabilidadeLinha): boolean {
    return this._notValidDc() || super._disabled(linha);
  }

  protected _buildFilter(): void {
    this.filter = `tipo=${ETipoContaContabilidade.Movimento}&bloqueada=false`;
    if (this.preDefinidoContabLinha) {
      this.filter = `tipo=${ETipoContaContabilidade.Movimento}`;
      const radical = this.preDefinidoContabLinha.conta.radical;
      if (radical) {
        this.filter += `&nConta=%${radical}%`;
      }

      switch (this.preDefinidoContabLinha.conta.tipoConta) {
        case EPreDefinidoContabTipoConta.ContaCorrente:
          this.filter += '&cc=true';
          break;
        case EPreDefinidoContabTipoConta.BaseTributavel:
          this.filter += '&temIVA=true';
          break;
        default:
          break;
      }
    }
  }
}
