import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {MapaPagamentosService} from '../../mapaPagamentos.module.service';
import {EMapaPagamImpostoEnum, EMapaPagamPdfViewerMode, IJsonMapaPagamEmpresaPagamentoDocumento, IMapaPagamATDocumentsSourceItem, isPagamentoManual} from '../../mapaPagamentos.module.interface';
import {isDefinedNotNull, isUndefinedOrNull, PlI18nService, PlTranslateService} from 'pl-comps-angular';
import {buildSessionUrlWithParams} from '../../../../../../common/utils/utils';
import {HttpResponse} from '@angular/common/http';
import {IModuleMaintenanceInstance} from '../../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../../components/entity/maintenance/module/module.maintenance.service';
import {EDocumentosFiscaisMainMenu, MODULE_NAME_DOCUMENTOS_FISCAIS} from '../../../manutencao/documentosFiscais/documentosFiscais.module.interface';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';

const ESTADO_TEXT = 'Estado';
const ESTADO_PENDENTE_EMISSAO_TEXT = 'Pendente de emissão';

@Component({
  selector: 'module-mapa-pagamentos-documentos-modal',
  templateUrl: './mapaPagamentos.documentos.modal.component.html'
})
export class MapaPagamentosDocumentosModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public viewMode: EMapaPagamPdfViewerMode;
  @Input() public pagamentos: Array<string>;
  @Input() public pagamentoGUID: string;
  @Input() public pagamentoNome: string;
  @Input() public impostoEnum: EMapaPagamImpostoEnum;
  @Input() public documentos: Array<IJsonMapaPagamEmpresaPagamentoDocumento>;
  @Input() public nEmpresa: string;
  @Input() public ano: number;
  @Input() public mes: number;

  public documentosSource: Array<IMapaPagamATDocumentsSourceItem>;
  public readonly docRowTemplate: string;
  public readonly docOutput: string;
  public pdfSrc: string | Blob;
  public pdfFilename: string;
  public docSelected: string;
  public viewModeType: typeof EMapaPagamPdfViewerMode;
  public promise: Promise<unknown>;
  public documentoEmitido: boolean;
  public documentoIsIUC: boolean;
  public isExternalDocument: boolean;
  public documentoEstado: string;
  private _maintenanceDocsFiscais: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plI18nService: PlI18nService,
    protected readonly _mapaPagamentosService: MapaPagamentosService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.viewModeType = EMapaPagamPdfViewerMode;
    this.docRowTemplate = '{{caption}}';
    this.docOutput = '{{caption}}';
    this.documentosSource = [];
    this.promise = undefined;
    this.documentoEmitido = true;
    this.documentoIsIUC = false;
    this.isExternalDocument = false;
  }

  public ngOnInit(): void {
    this.viewMode = isDefinedNotNull(this.viewMode) ? this.viewMode : EMapaPagamPdfViewerMode.DOCUMENTO;
    if (this.viewMode === EMapaPagamPdfViewerMode.DOCUMENTO) {
      this.isExternalDocument = this.impostoEnum !== EMapaPagamImpostoEnum.Manual;
      this.documentoIsIUC = this.impostoEnum === EMapaPagamImpostoEnum.IUC;
      if (isDefinedNotNull(this.documentos)) {
        this.documentos.forEach((item) => {
          if (this.documentoIsIUC) {
            const matriculas = item.detalhes.filter((detalheItem) => detalheItem.text === 'matricula').map((detalheItem) => detalheItem.value);
            const sourceName = `${this.pagamentoNome} : ${matriculas.join(' | ')}`;
            this.documentosSource.push({value: item.idDoc, name: `${sourceName} | ${this._plI18nService.formatCurrency(item.montante)}`});
          } else {
            this.documentosSource.push({
              value: item.idDoc,
              name: isPagamentoManual(this.pagamentoGUID)
                ? `${this._plTranslateService.translate('mapapagam.texts.anexo')} | ${this._plI18nService.formatCurrency(item.montante)}`
                : `${this.pagamentoNome} : ${item.idDoc} | ${this._plI18nService.formatCurrency(item.montante)}`
            });
          }
        });
        this.onDocumentChange(this.documentosSource[0].value);
      }
      this.pdfFilename = this._translateService.instant('mapapagam.texts.pagamentoPdfName', {
        nEmpresa: this.nEmpresa,
        nome: this.pagamentoNome,
        ano: this.ano,
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        mes: this.mes < 10 ? `0${this.mes}` : this.mes,
        now: moment().format('DDMMYYYY_HHmmss')
      });
    } else {
      this.pdfFilename = this._translateService.instant('mapapagam.texts.documentoUnicoPdfName', {
        nEmpresa: this.nEmpresa,
        ano: this.ano,
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        mes: this.mes < 10 ? `0${this.mes}` : this.mes,
        now: moment().format('DDMMYYYY_HHmmss')
      });
      this.promise = this._mapaPagamentosService.getDocumentoUnicoPdf(this.nEmpresa, this.pagamentos).then((response: HttpResponse<Blob>) => {
        this.pdfSrc = response.body;
      });
    }
  }

  public onDocumentChange(idDoc: string): void {
    this.docSelected = idDoc;

    const document = this.documentos.find((item) => {
      return item.idDoc === idDoc;
    });

    if (document.detalhes.length) {
      const documentDetalhe = document.detalhes.find((detalhe) => {
        return detalhe.text === ESTADO_TEXT;
      });
      if (isDefinedNotNull(documentDetalhe)) {
        this.documentoEmitido = documentDetalhe.value !== ESTADO_PENDENTE_EMISSAO_TEXT;
        if (!this.documentoEmitido) {
          this.documentoEstado = documentDetalhe.value;
        }
      }
    }

    if (this.documentoEmitido) {
      buildSessionUrlWithParams(this._mapaPagamentosService.getViewDocumentATUrl(this.nEmpresa, this.pagamentoGUID), {idDoc: idDoc}).subscribe((url: string) => {
        this.pdfSrc = url;
      });
    }
  }

  public showDocumentosFiscais(): void {
    if (isUndefinedOrNull(this._maintenanceDocsFiscais)) {
      this._maintenanceDocsFiscais = this._moduleMaintenanceService.build(MODULE_NAME_DOCUMENTOS_FISCAIS);
    }
    this._maintenanceDocsFiscais.maintenance({params: {menu: EDocumentosFiscaisMainMenu.IUC}});
  }

  public forceReload(): void {
    buildSessionUrlWithParams(this._mapaPagamentosService.getViewDocumentATUrl(this.nEmpresa, this.pagamentoGUID), {
      idDoc: this.docSelected,
      forceGet: true
    }).subscribe((url: string) => {
      this.pdfSrc = url;
    });
  }
}
