import {deepFreeze} from '../../utilities/utilities';
import type {IPlLocale} from '../locales.interface';

/* eslint-disable @typescript-eslint/naming-convention */

const locale: IPlLocale = deepFreeze<IPlLocale>({
  autocomplete: {
    textSearching: 'recherche...',
    textEmpty: 'aucun résultat',
    textNotFound: 'pas trouvé'
  },
  btn: {
    goBack: 'Retourner',
    goForward: 'Avance',
    finalize: 'Finition',
    submit: 'Soumettre',
    search: 'Chercher',
    ok: "D'ACCORD",
    cancel: 'Annuler',
    close: 'Fermer',
    apply: 'Postuler',
    addFilter: 'Ajouter un filtre'
  },
  calendar: {
    views: {
      month: {
        items: 'Articles'
      },
      year: {
        months: 'Mois'
      }
    },
    detailsBar: {
      simultaneousEvents: 'Événements simultanés'
    },
    sidebar: {
      filter: 'Filtre'
    }
  },
  datepicker: {
    nextCentury: 'Le siècle prochain',
    nextDecade: 'La prochaine décennie',
    nextMillennium: 'Le prochain millénaire',
    nextMonth: 'Le mois prochain',
    nextYear: "L'année prochaine",
    previousCentury: 'Siècle précédent',
    previousDecade: 'Décennie précédente',
    previousMillennium: 'Millénaire précédent',
    previousMonth: 'Le mois dernier',
    previousYear: "L'année dernière",
    toggleContext: 'Basculer la vue',
    validators: {
      minimumDate: 'Ce champ ne peut pas avoir de date antérieure à {{value}}',
      maximumDate: 'Ce champ ne peut pas avoir une date supérieure à {{value}}'
    }
  },
  mimeTypes: {
    'application/epub+zip': 'EPUB',
    'application/gzip': 'GZ',
    'application/java-archive': 'JAVA',
    'application/json': 'JSON',
    'application/ld+json': 'JSON (formato LD)',
    'application/msword': 'DOC (Word)',
    'application/octet-stream': 'BIN',
    'application/ogg': 'OGG',
    'application/pdf': 'PDF',
    'application/php': 'PHP',
    'application/rtf': 'RTF',
    'application/vnd.amazon.ebook': 'AZW',
    'application/vnd.apple.installer+xml': 'MPKG',
    'application/vnd.mozilla.xul+xml': 'XUL',
    'application/vnd.ms-excel': 'XLS (Excel)',
    'application/vnd.ms-fontobject': 'EOT',
    'application/vnd.ms-powerpoint': 'PPT (Powerpoint)',
    'application/vnd.oasis.opendocument.presentation': 'ODP',
    'application/vnd.oasis.opendocument.spreadsheet': 'ODS',
    'application/vnd.oasis.opendocument.text': 'ODT',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPT (Powerpoint)',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLS (Excel)',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOC (Word)',
    'application/vnd.visio': 'VSD',
    'application/x-7z-compressed': '7z',
    'application/x-abiword': 'ABW',
    'application/x-bzip': 'BZ',
    'application/x-bzip2': 'BZ2',
    'application/x-csh': 'CSH',
    'application/x-freearc': 'ARC',
    'application/x-rar-compressed': 'RAR',
    'application/x-sh': 'SH',
    'application/x-shockwave-flash': 'SWF',
    'application/x-tar': 'TAR',
    'application/xhtml+xml': 'XHTML',
    'application/zip': 'ZIP',
    'audio/aac': 'AAC',
    'audio/midi': 'MID(I)',
    'audio/mpeg': 'MP3',
    'audio/ogg': 'OGG',
    'audio/wav': 'WAV',
    'audio/webm': 'WEBM',
    'font/otf': 'OTF',
    'font/ttf': 'TTF',
    'font/woff': 'WOFF',
    'font/woff2': 'WOFF2',
    'image/*': 'imagens',
    'image/bmp': 'BMP',
    'image/gif': 'GIF',
    'image/jpeg': 'JPG/JPEG',
    'image/png': 'PNG',
    'image/svg+xml': 'SVG',
    'image/tiff': 'TIFF',
    'image/vnd.microsoft.icon': 'ICON',
    'image/webp': 'WEBP',
    'image/x-icon': 'ICON',
    'text/calendar': 'ICS',
    'text/css': 'CSS',
    'text/csv': 'CSV',
    'text/html': 'HTML',
    'text/javascript': 'JS',
    'text/plain': 'TXT',
    'text/xml': 'XML',
    'video/3gpp': '3GP',
    'video/3gpp2': '3G2',
    'video/mp2t': 'TS',
    'video/mpeg': 'MPEG',
    'video/ogg': 'OGV',
    'video/webm': 'WEBM',
    'video/x-msvideo': 'AVI'
  },
  months: {
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Peut',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre'
  },
  placeholders: {
    search: 'Recherchez dans le menu...',
    tableGroupBy: 'Faites glisser une colonne dans cette zone pour la regrouper par cette colonne'
  },
  plColorPicker: {
    presetLabel: 'Couleurs prédéfinies',
    okButtonText: "D'ACCORD",
    cancelButtonText: 'Annuler',
    addColorButtonText: 'Ajouter de la couleur',
    presetEmptyMessage: 'Aucune couleur ajoutée'
  },
  plCookiesConsent: {
    header: 'Ce site web utilise des cookies',
    footer: 'La déclaration de cookie a été mise à jour pour la dernière fois en {{value}}',
    necessaryTitle: 'Obligatoire',
    necessaryDescription:
      "Les cookies obligatoires sont essentiels pour utiliser ce site Web, permettant des fonctions de base telles que la navigation dans les pages et l'accès aux zones restreintes.",
    preferencesTitle: 'Préférences',
    preferencesDescription: 'Les cookies de préférence permettent à un site Web de mémoriser des informations qui modifient son apparence ou son comportement.',
    statisticsTitle: 'Statistiques',
    statisticsDescription: 'Les cookies statistiques aident à comprendre comment les utilisateurs interagissent avec le site Web, en collectant et en rapportant des informations de manière anonyme.',
    marketingTitle: 'Commercialisation',
    marketingDescription:
      "Les cookies de marketing sont utilisés pour améliorer les publicités présentées à l'utilisateur, par exemple en affichant uniquement les publicités les plus pertinentes et les plus attrayantes pour l'utilisateur.",
    unclassifiedTitle: 'Non classés',
    unclassifiedDescription: "Les cookies non classés sont ceux dont la fonction n'a pas encore été définie ou sont en attente de classification.",
    showDetails: 'Afficher les détails',
    hideDetails: 'Cacher les détails',
    ok: "D'ACCORD",
    declaration: 'Déclaration des cookies',
    about: 'À propos des cookies',
    tableHeaderName: 'Nom',
    tableHeaderProvider: 'Entité',
    tableHeaderPurpose: 'Intention',
    tableHeaderExpiresOn: 'Expiration',
    tableHeaderType: 'Taper',
    session: 'Session',
    persistent: 'Persistant',
    emptyGroup: "Nous n'utilisons pas de cookies de ce type.",
    invalidExpireTime: 'valeur invalide'
  },
  plDashboard: {
    emptyData: "Il n'y a aucune donnée à afficher",
    total: 'Total'
  },
  plMediaDevicesCameraCaptureImage: {
    imageContentAlt: "Aperçu de l'image",
    titleChange: "Changer l'image",
    titleTabUpload: 'Télécharger une image',
    titleTabCapture: 'Capturer une image',
    textChange: 'Altérer',
    textRemove: 'Retirer',
    textDisabledTabCapture: "Votre appareil ne prend pas en charge la capture d'images.",
    textRetry: 'Essayer à nouveau',
    textCapture: 'Capturer',
    textPause: 'Pause',
    textResume: 'Résumer',
    errorAbortError: "Un problème inconnu s'est produit et vous empêche d'utiliser l'appareil photo de votre appareil.",
    errorNotAllowedError: 'La caméra de votre appareil ne peut pas être utilisée pour le moment.',
    errorNotFoundError: "Aucune caméra n'a été trouvée sur votre appareil.",
    errorNotReadableError: "Une erreur matérielle s'est produite et empêche l'accès à la caméra de votre appareil.",
    errorOverconstrainedError: "Aucune caméra répondant aux critères requis n'a été trouvée sur votre appareil.",
    errorSecurityError: "La prise en charge de l'utilisation de l'appareil photo de votre appareil est désactivée.",
    errorTypeError: "Un problème inconnu s'est produit et vous empêche d'utiliser l'appareil photo de votre appareil.",
    errorTimedOutError: "La demande d'autorisations requise pour utiliser la caméra de votre appareil a expiré.",
    errorUnavailableMediaDevices: "Votre appareil ne prend pas en charge la capture d'images."
  },
  plMultiSelect: {
    available: 'Disponible',
    selected: 'Choisi'
  },
  plNavbar: {
    toggleMenu: 'Changer de menu'
  },
  plPdf: {
    loadError: 'Erreur de lecture du document',
    noDocument: 'Aucun document',
    renderNotSupported: 'Votre document a été traité.',
    pages: 'Pages',
    zoomAuto: 'Zoom automatique',
    zoomPageActual: 'Taille réelle',
    zoomPageFit: 'Ajuster à la page',
    zoomPageWidth: 'Ajuster à la largeur',
    zoomIn: 'Agrandir',
    zoomOut: 'Dézoomer',
    presentationMode: 'Passer en mode présentation',
    download: 'transfert',
    print: 'Imprimer',
    loading: 'Chargement du document...'
  },
  plSelect: {
    clearAllText: 'Nettoie tout',
    loadingText: 'Chargement...',
    notFoundText: 'Aucun élément trouvé'
  },
  plSideBar: {
    toggleSideBar: 'Basculer la barre latérale'
  },
  text: {
    today: "Aujourd'hui",
    day: 'Jour',
    days: 'Jours',
    week: 'Semaine',
    weeks: 'Semaines',
    month: 'Mois',
    months: 'Mois',
    year: 'Année',
    years: 'Années',
    weekend: 'Fin de semaine',
    weekends: 'Fins de semaine',
    lines: 'Lignes',
    close: 'Fermer',
    now: 'Maintenant',
    clear: 'Nettoyer',
    open: 'Ouvrir',
    initialize: 'Démarrage',
    noFilters: 'Aucun filtre',
    activeFilters: 'Filtres actifs',
    clearFilters: 'Effacer les filtres actifs',
    true: 'VRAI',
    false: 'FAUX',
    and: "C'est",
    or: 'ou',
    of: 'dans'
  },
  uploader: {
    defaultMessage: 'Déposez les fichiers ici pour les envoyer',
    fileTooBig: 'Le fichier est très volumineux, la taille maximale autorisée est <strong>{{maxFilesize}}Mo</strong>.',
    invalidFileType: 'Vous ne pouvez pas envoyer de fichiers de ce type.',
    cancelUpload: 'Annuler le téléchargement',
    uploadCanceled: 'Téléchargement annulé.',
    removeAllFiles: 'Enlever tout',
    removeFile: 'Retirer',
    maxFileExceeded: 'Vous ne pouvez plus envoyer de fichiers.',
    maxFilesExceeded: 'Vous ne pouvez plus envoyer de fichiers.',
    acceptedFileType: 'Seul le type de fichier {{acceptedFiles}} est pris en charge.',
    acceptedFileTypes: 'Seuls les types de fichiers {{acceptedFiles}} sont pris en charge.',
    maximumFileSize: 'La taille maximale du fichier est {{maximumFileSize}}.',
    maximumPerFileSize: 'La taille maximale par fichier est de {{maximumFileSize}}.',
    dropHere: 'déposer des fichiers',
    clickToUpload: 'cliquez ici pour envoyer',
    files: 'Des dossiers',
    uploadAll: 'Envoyer tout',
    upload: 'Envoyer',
    rejected: 'Fichier rejeté',
    retry: "Essayez à nouveau d'envoyer",
    uploadSuccess: 'Fichier téléchargé avec succès',
    uploadError: "Une erreur s'est produite lors du chargement du fichier",
    units: {b: 'B', kb: 'KB', mb: 'MB', gb: 'GB', tb: 'TB', pb: 'PB', eb: 'EB', zb: 'ZB', yb: 'YB'}
  },
  validators: {
    date: 'Date invalide',
    datetimelocal: 'Date invalide',
    email: 'Email invalide',
    equals: 'La valeur de ce champ doit être égale à la valeur du champ {{value}}',
    max: 'Ce champ ne peut pas avoir une valeur supérieure à {{value}}',
    maxlength: 'Ce champ a une longueur maximale de {{value}} caractères',
    maxSelected: 'Ce champ ne peut pas avoir plus de {{value}} élément(s) sélectionné(s)',
    min: 'Ce champ ne peut pas avoir une valeur inférieure à {{value}}',
    minlength: 'Ce champ a une longueur minimale de {{value}} caractères',
    minSelected: 'Ce champ doit avoir au moins {{value}} élément(s) sélectionné(s)',
    month: 'Mois invalide',
    number: 'Numéro invalide',
    pattern: 'Champ invalide',
    required: 'Ce champ est obligatoire',
    time: 'Champ invalide',
    url: 'Champ invalide',
    week: 'Champ invalide'
  },
  weekdays: {
    sunday: 'Dimanche',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi'
  }
});

/* eslint-enable @typescript-eslint/naming-convention */

export default locale;
