import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../config/constants';
import {IJsonPOC} from '../../../../entities/pocs/jsonPOC.entity.interface';
import {IJsonValidacoesLancamentos} from '../../../../entities/taxonomias/jsonTaxonomias.entity.interface';
import {TaxonomiasService} from '../../taxonomias.module.service';
import {ETaxonomiasModoModalPoc} from '../../taxonomias.module.interface';

const CONTASALDAANALITICA = '981';
const CONTASALDACLASSE0 = '0981';
const CONTACAIXA = '111';
const NOMECONTACAIXA = 'Caixa';
const REFLETIDADACONTAANALITICA = 'Refletida da Contab Analitica';
const REFLETIDA_DA_CLASSE_0 = 'Refletida da classe 0';
const CONTA_ANALITICA = '9';
const CONTA_CLASSE_0 = '0';

@Component({
  selector: 'contapocsalda-modal',
  templateUrl: './contaPocSalda.modal.component.html'
})
export class ContaPocSaldaModalComponent extends CGModalComponent<Array<IJsonValidacoesLancamentos>> implements OnInit {
  @Input() public modoModalPoc: ETaxonomiasModoModalPoc;

  public title: string;
  public contaPoc: Partial<IJsonPOC>;
  public filterPoc: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _taxonomiasService: TaxonomiasService
  ) {
    super(_injector);
    this.modoModalPoc = ETaxonomiasModoModalPoc.ModalContaNormal;
  }

  public ngOnInit(): void {
    this.title =
      this.modoModalPoc === ETaxonomiasModoModalPoc.ModalContaAnalita
        ? 'taxonomias.modalContaSalda.titleAnalitica'
        : this.modoModalPoc === ETaxonomiasModoModalPoc.ModalContaClasse0
          ? 'taxonomias.modalContaSalda.titleAnalitica'
          : 'taxonomias.modalContaSalda.titleNormal';
    this.contaPoc = {
      nConta: this.modoModalPoc === ETaxonomiasModoModalPoc.ModalContaAnalita ? CONTASALDAANALITICA : this.modoModalPoc === ETaxonomiasModoModalPoc.ModalContaClasse0 ? CONTASALDACLASSE0 : CONTACAIXA,
      nome:
        this.modoModalPoc === ETaxonomiasModoModalPoc.ModalContaAnalita
          ? REFLETIDADACONTAANALITICA
          : this.modoModalPoc === ETaxonomiasModoModalPoc.ModalContaClasse0
            ? REFLETIDA_DA_CLASSE_0
            : NOMECONTACAIXA
    };
    const nconta: string = this.modoModalPoc === ETaxonomiasModoModalPoc.ModalContaAnalita ? CONTA_ANALITICA : this.modoModalPoc === ETaxonomiasModoModalPoc.ModalContaClasse0 ? CONTA_CLASSE_0 : '';
    this.filterPoc = `tipo=${ETipoContaContabilidade.Movimento}&nconta=%${nconta}%`;
  }

  public close(): Promise<void> {
    if (!this.contaPoc.nConta) {
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }

    const messageClasse: string = this._plTranslateService.translate(
      this.modoModalPoc === ETaxonomiasModoModalPoc.ModalContaAnalita
        ? 'taxonomias.autoCorrectMessageClasse9'
        : this.modoModalPoc === ETaxonomiasModoModalPoc.ModalContaClasse0
          ? 'taxonomias.autoCorrectMessageClasse0'
          : 'taxonomias.autoCorrectDocsSemLinhas',
      {
        nconta: this.contaPoc.nConta
      }
    );
    return this._cgModalService.showOkCancel('taxonomias.autoCorrectDatasTitleOkCancel', messageClasse).then(() => {
      this.disableClose();
      return (
        this.modoModalPoc === ETaxonomiasModoModalPoc.ModalContaAnalita
          ? this._taxonomiasService.aplicarCorrecoesAutomaticasAnalitica(this.contaPoc.nConta)
          : this.modoModalPoc === ETaxonomiasModoModalPoc.ModalContaClasse0
            ? this._taxonomiasService.aplicarCorrecoesAutomaticasClasse0(this.contaPoc.nConta)
            : this._taxonomiasService.aplicarCorrecoesAutomaticasDocsSemLinhas(this.contaPoc.nConta)
      )
        .then((result: HttpResponse<Array<IJsonValidacoesLancamentos>>) => {
          this.enableClose();
          super.close(result.body);
        })
        .catch((reason: unknown) => {
          this.enableClose();
          this._logger.error(reason);
        });
    });
  }
}
