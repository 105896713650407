import {HttpResponse} from '@angular/common/http';
import {ContabilidadeApuramentoResultadosComponent} from './components/contabilidade.apuramentoResultados.module.component';
import {ContabilidadeApuramentoResultadosService} from './contabilidade.apuramentoResultados.module.service';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ROLE} from '../../../../services/role.const';
import {IJsonApuramento} from './jsonApuramentoResultados.module.interface';

export const MODULE_PCA_MANUTENCAO_APURAMENTO_RESULTADOS: IModuleDefinition = {
  name: 'apuramentoresultados',
  state: {
    url: '/apuramentoresultados',
    component: ContabilidadeApuramentoResultadosComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.apuramentoresultados'
    },
    params: {
      selected: {
        type: 'string',
        value: null,
        dynamic: true
      },
      docsGerados: {
        type: 'json',
        value: null,
        dynamic: true
      }
    },
    resolve: [
      {
        provide: 'apurado',
        deps: [ContabilidadeApuramentoResultadosService],
        useFactory: (apuramentoResultadosService: ContabilidadeApuramentoResultadosService): Promise<boolean> => {
          return apuramentoResultadosService.jaEfectuouApuramentoNoAno().then((response: HttpResponse<boolean>) => response.body);
        }
      },
      {
        provide: 'apuramento',
        deps: [ContabilidadeApuramentoResultadosService],
        useFactory: (apuramentoResultadosService: ContabilidadeApuramentoResultadosService): Promise<IJsonApuramento> => {
          return apuramentoResultadosService.getDefaultDocsApuramentoGerar().then((response: HttpResponse<IJsonApuramento>) => response.body);
        }
      }
    ]
  }
};
