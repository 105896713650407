import {Component, Injector, Input, OnInit} from '@angular/core';
import {IJsonNIFs} from '../../jsonNifs.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonTxRet} from '../../../taxasretencao/jsonTxRet.entity.interface';
import {ERendiTipoDeclaracao} from '../../../rendis/jsonRendi.entity.interface';

@Component({
  selector: 'nifs-edit',
  templateUrl: './nifs.entity.edit.component.html'
})
export class NifsEditComponent extends ModuloEntityDetailComponent<IJsonNIFs> implements OnInit {
  @Input() public taxasRetencaoList: Array<IJsonTxRet>;

  public showModelo30: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.showModelo30 = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.showModelo30 = this.taxasRetencaoList.find((item: IJsonTxRet) => item.codRet === this.model.codRet).tipoDeclaracao === ERendiTipoDeclaracao.Modelo30;
  }

  public changedCodRet(value: IJsonTxRet): void {
    if (value) {
      this.model.codRet = value.codRet;
      this.model.nomeRet = value.nomeRet;
    } else {
      this.model.codRet = undefined;
      this.model.nomeRet = '';
    }
    this.showModelo30 = value.tipoDeclaracao === ERendiTipoDeclaracao.Modelo30;
    if (!this.showModelo30) {
      this._cleanModelo30Values();
    }
  }

  private _cleanModelo30Values(): void {
    this.model.m30NifPT = this.model.m30NifEmit = this.model.m30PartCapD = this.model.m30PartCapS = undefined;
  }
}
