import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {copy, isArray, isDefinedNotNull, isObject, isUndefined, Logger, PlEditRegistryService} from 'pl-comps-angular';
import {ApiService} from '../../services/api/api.service';
import {EReport} from '../../entities/reports/reports.interface';
import {IJsonReport} from '../../entities/reports/jsonReport.interface';
import {IReportInstance, IReportInstanceDefinition, IReportsQueryRequestConfig, reportInputComponentName} from './input/reports.input.component.interface';

@Injectable({
  providedIn: 'root'
})
export class ReportsRegistryService {
  private readonly _instances: Map<string, IReportInstance>;

  constructor(
    private readonly _logger: Logger,
    private readonly _plEditRegistryService: PlEditRegistryService,
    private readonly _apiService: ApiService
  ) {
    this._instances = new Map<string, IReportInstance>();
  }

  public register(report: IReportInstanceDefinition): void {
    if (!report) {
      return;
    }
    const newReport: IReportInstance = <IReportInstance>report;
    newReport._originalUrl = newReport.url;
    this._setReportRequests(newReport);
    newReport.subfolder = (name: string) => this._reportSubFolder(newReport, name);
    this._plEditRegistryService.map(reportInputComponentName, report.name, {_report: report.name});
    this._instances.set(report.name, newReport);
  }

  public get(reportName: EReport): IReportInstance {
    const instance: IReportInstance = this._instances.get(reportName);
    if (isUndefined(instance)) {
      this._logger.error(`Report '${reportName}' não encontrado`);
    }
    return copy<IReportInstance>(instance);
  }

  public getAll(): Array<IReportInstance> {
    const instances: Array<IReportInstance> = [];
    this._instances.forEach((dataSource: IReportInstance) => {
      instances.push(copy<IReportInstance>(dataSource));
    });
    return instances;
  }

  private _setReportRequests(report: IReportInstance): void {
    report.query = (requestConfig?: IReportsQueryRequestConfig) => this._reportQuery(report, requestConfig);
    report.get = (name?: EReport, requestConfig?: IReportsQueryRequestConfig) => this._reportGet(report, name, requestConfig);
  }

  private _reportSubFolder(report: IReportInstance, name: string): IReportInstance {
    report = {...report, url: `${report.url}/${name}`};
    this._setReportRequests(report);
    return report;
  }

  private _reportQuery(report: IReportInstance, requestConfig?: IReportsQueryRequestConfig): Promise<Array<IJsonReport>> {
    const config: IReportsQueryRequestConfig = isObject(requestConfig) ? copy(requestConfig) : {};
    let url: string = this._reportUrl(report);
    const subFolders: Array<string> = isDefinedNotNull(config.subfolder) ? (isArray(config.subfolder) ? config.subfolder : [config.subfolder]) : [];
    if (subFolders.length) {
      for (const subfolder of subFolders) {
        if (subfolder) {
          url += `/${subfolder}`;
        }
      }
    }
    config.url = url;
    delete config.filtro;
    delete config.subfolder;
    return this._apiService.get(config).then((response: HttpResponse<Array<IJsonReport>>) => response.body);
  }

  private _reportGet(report: IReportInstance, name?: EReport, requestConfig?: IReportsQueryRequestConfig): Promise<Array<IJsonReport>> {
    const search = name ? `${report.metadata.keyName}=${name}` : undefined;
    return this._reportQuery(report, {...requestConfig, pesquisa: search});
  }

  private _reportUrl(report: IReportInstance): string {
    return `${this._apiService.path.restapi}/${report.url ? report.url : report.name}`;
  }
}
