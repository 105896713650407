import {Component, Injector} from '@angular/core';
import {ConfigsErpBaseModuleComponent} from '../../configs.erp.base.module.component';
import {EConfigsErpDepth, IConfigsErpEditConfigERP} from '../../components/configedit/configs.erp.edit.component.interface';
import {IJsonConfigERP} from '../../../../../entities/configserp/jsonConfigERP.entity.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

const CONFIG_TYPES = ['empresa', 'erp'];

@Component({
  selector: 'module-configs-erp-empresa',
  templateUrl: './configs.empresa.module.component.html'
})
export class ConfigsErpEmpresaModuleComponent extends ConfigsErpBaseModuleComponent {
  public readonly definition: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.definition = {
      columns: [
        {dataField: 'description', dataType: 'string', caption: 'entity.detail.field'},
        {dataField: 'value', caption: 'entity.detail.value'}
      ]
    };
  }

  public get depthLevel(): EConfigsErpDepth {
    return EConfigsErpDepth.Single;
  }

  protected _parseList(rawConfigurations: Array<IJsonConfigERP>): Array<IConfigsErpEditConfigERP> {
    const items: Array<IConfigsErpEditConfigERP> = [];
    for (const item of rawConfigurations) {
      for (const type of CONFIG_TYPES) {
        if (item.name.startsWith(`${type}.`)) {
          items.push({...item, saveField: this.fnSaveField, index: items.length});
          break;
        }
      }
    }
    return items;
  }
}
