import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_CREDITO, ETipoCredito} from './tiposCredito.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPOS_CREDITO: IDataSource<ETipoCredito> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_CREDITO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{label}}',
    output: 'label'
  },
  data: [
    {value: ETipoCredito.Credito1, label: 'tipoCredito.data.credito1'},
    {value: ETipoCredito.Credito2, label: 'tipoCredito.data.credito2'},
    {value: ETipoCredito.Credito3, label: 'tipoCredito.data.credito3'},
    {value: ETipoCredito.Credito4, label: 'tipoCredito.data.credito4'},
    {value: ETipoCredito.Credito5, label: 'tipoCredito.data.credito5'}
  ]
});
