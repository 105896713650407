import {HttpResponse} from '@angular/common/http';
import {ContabilidadeBalancetesAnaliticaComponent} from './components/contabilidade.balancetes.analitica.module.component';
import {ContabilidadeEstatisticaService} from '../contabilidadeEstatistica.module.service';
import {IJsonString} from '../../../../../common/interfaces/json';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_PCA_ESTATISTICA_BALANCETES_ANALITICA} from './contabilidade.balancetes.analitica.module.interface';
import {ROLE} from '../../../../services/role.const';

export const MODULE_PCA_ESTATISTICA_BALANCETES_ANALITICA: IModuleDefinition = {
  name: MODULE_NAME_PCA_ESTATISTICA_BALANCETES_ANALITICA,
  state: {
    url: '/balancetesanalitica',
    component: ContabilidadeBalancetesAnaliticaComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.balancetesanalitica'
    },
    resolve: [
      {
        provide: 'mascarasAnalitica',
        deps: [ContabilidadeEstatisticaService],
        useFactory: (contabilidadeEstatisticaService: ContabilidadeEstatisticaService): Promise<string> => {
          return contabilidadeEstatisticaService.getMascarasAnalitica().then((response: HttpResponse<IJsonString>) => response.body.value);
        }
      }
    ]
  }
};
