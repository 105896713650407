import {firstValueFrom, isObservable} from 'rxjs';
import type {TranslateService} from '@ngx-translate/core';
import type {SortDescriptor} from 'devextreme/data';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {HttpResponse} from '@angular/common/http';
import {cgcHighlight, isArray, isDefinedNotNull, isFunction, isNumber, isObject, isString, KEYCODES, normalizeAccents} from 'pl-comps-angular';
import type {IApiQueryResponse} from '../../../../services/api/api.service.interface';
import type {
  IDevExpressDataGridColumn,
  IDevExpressDataGridColumnCustomizeTextCellInfo,
  IDevExpressDataGridColumnLookup,
  IDevExpressDataGridLoadResult,
  TDevExpressDataGridColumnCustomizeTextFn
} from '../devexpress.datagrid.interface';
import type {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnCellPrepared} from '../events/devexpress.datagrid.events.interface';
import type {IDevExpressDataGridExpandDetailHandlerOptions} from './devexpress.datagrid.utilities.interface';
import type {IDevExpressDataGridState, IDevExpressDataGridStateColumn} from '../state/devexpress.datagrid.state.interface';
import type {IDevExpressFilters, TDevExpressComparisonOperator, TDevExpressFilterExpression} from '../../datalayer/filtering/devexpress.datalayer.filtering.interface';
import {SELECTOR_DEV_EXPRESS_EDITOR_CELL} from '../../widget/devexpress.selectors.interface';

const OPERATOR_AND = '&';
const OPERATOR_OR = '|';
const OPERATOR_PARENTHESIS_OPEN = '(';
const OPERATOR_PARENTHESIS_CLOSE = ')';
const OPERATOR_TRUE = 'true';
const OPERATOR_FALSE = 'false';
const KLASS_DATA_GRID_SEARCH_TEXT = 'dx-datagrid-search-text';

export function devExpressDataGridExpandDetailHandler(event: IDevExpressDataGridEventOnCellClick, callback?: () => unknown, options?: IDevExpressDataGridExpandDetailHandlerOptions): Promise<void> {
  if (event.rowType !== 'data' || (event.column?.type && event.column?.type !== 'detailExpand')) {
    return Promise.resolve();
  }

  // Whether the event was triggered by the expand arrow
  const handleExpand: boolean = event.column ? event.column.type !== 'detailExpand' : true;

  // Check if already expanded
  if (event.component.isRowExpanded(event.key)) {
    if (!handleExpand) {
      return Promise.resolve();
    }
    return event.component.collapseRow(event.key).then(() => undefined);
  }

  if (!handleExpand) {
    event.event.preventDefault();
    event.event.stopImmediatePropagation();
  }

  // Not expanded, handle it
  if (isFunction(callback)) {
    const callbackResult: unknown = callback();
    const promise: Promise<unknown> = isObservable(callbackResult) ? firstValueFrom(callbackResult) : Promise.resolve(callbackResult);
    event.component.beginCustomLoading(undefined);
    return promise
      .then(() => {
        if (options?.collapseOthers) {
          event.component.collapseAll(-1);
        }
        return event.component.expandRow(event.key);
      })
      .finally(() => {
        event.component.endCustomLoading();
      });
  }

  if (options?.collapseOthers) {
    event.component.collapseAll(-1);
  }

  return event.component.expandRow(event.key);
}

export function devExpressDataGridFiltersToQueryFilter(component: dxDataGrid, filters: IDevExpressFilters | Array<IDevExpressFilters>, negated: boolean = false): string {
  if (!isArray(filters)) {
    return '';
  }
  const result: Array<string> = [];
  for (const filter of filters) {
    if (isArray(filter)) {
      /** When column calculateDisplayValue is defined with a function,
       this argument will be a function and this isn't supported on the server-side
       */
      if (isFunction(filter[0])) {
        continue;
      }
      const filterResult: string = devExpressDataGridFilterToQueryFilter(component, <Array<string>>filter, negated);
      if (filterResult) {
        const isolate: boolean = filterResult.includes(OPERATOR_OR);
        if (isolate) {
          result.push(OPERATOR_PARENTHESIS_OPEN);
        }
        result.push(filterResult);
        if (isolate) {
          result.push(OPERATOR_PARENTHESIS_CLOSE);
        }
      }
    } else if (isString(filter)) {
      if (!result.length) {
        return devExpressDataGridFilterToQueryFilter(component, <Array<string>>filters, negated);
      }
      switch (filter) {
        case 'and':
          result.push(OPERATOR_AND);
          break;
        case 'or':
          result.push(OPERATOR_OR);
          break;
        default:
          // not supported
          break;
      }
    }
  }
  return result.join('');
}

function devExpressDataGridFilterToQueryFilter(component: dxDataGrid, filter: Array<string | Array<string>>, negated: boolean = false): string {
  const result: Array<string> = [];
  let i = 0;
  while (i < filter.length) {
    const filterArgument: unknown = filter[i];
    if (isArray(filterArgument)) {
      const filterArgumentResult: string = devExpressDataGridFiltersToQueryFilter(component, filterArgument, negated);
      if (filterArgumentResult) {
        result.push(filterArgumentResult);
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      switch (String(filterArgument)) {
        case '=':
          result.push(!negated ? '=' : '<>');
          break;
        case '<>':
          result.push(!negated ? '<>' : '=');
          break;
        case '>':
          result.push(!negated ? '>' : '<');
          break;
        case '>=':
          result.push(!negated ? '>=' : '<');
          break;
        case '<':
          result.push(!negated ? '<' : '>');
          break;
        case '<=':
          result.push(!negated ? '<=' : '>');
          break;
        case 'startswith':
        case 'endswith':
        case 'contains':
          if (i < filter.length - 1) {
            result.push('=%');
            if (filterArgument === 'contains' || filterArgument === 'endswith') {
              result.push('%');
            }
            result.push(devExpressDataGridValueToQueryValue(filter[i + 1]));
            if (filterArgument === 'contains' || filterArgument === 'startswith') {
              result.push('%');
            }
          }
          // Advance twice
          i++;
          break;
        case 'true':
          result.push(!negated ? OPERATOR_TRUE : OPERATOR_FALSE);
          break;
        case 'false':
          result.push(!negated ? OPERATOR_FALSE : OPERATOR_TRUE);
          break;
        case 'or':
          result.push(!negated ? OPERATOR_OR : OPERATOR_AND);
          break;
        case 'and':
          result.push(!negated ? OPERATOR_AND : OPERATOR_OR);
          break;
        case '!':
          return devExpressDataGridFilterToQueryFilter(component, <Array<string>>filter[1], true);
        default:
          result.push(devExpressDataGridValueToQueryValue(filterArgument));
          break;
      }
    }
    i++;
  }
  return result.join('');
}

function devExpressDataGridValueToQueryValue(value: unknown): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-base-to-string
  return isObject(value) && isFunction((<any>value).toJSON) ? (<any>value).toJSON() : String(value);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function devExpressDataGridSortToOrder(sort: SortDescriptor<any> | Array<SortDescriptor<any>>): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sortItems: Array<SortDescriptor<any>> = isDefinedNotNull(sort) ? (!isArray(sort) ? [sort] : sort) : [];
  if (!sortItems.length) {
    return undefined;
  }
  const result: Array<string> = [];
  for (const sortItem of sortItems) {
    if (isFunction(sortItem)) {
      continue;
    }
    if (isString(sortItem)) {
      result.push(sortItem);
    } else {
      result.push(sortItem.desc ? `${<string>sortItem.selector} desc` : <string>sortItem.selector);
    }
  }
  return result.join(',');
}

export function devExpressDataGridTranslateLookupDataSource(translator: TranslateService, lookup: IDevExpressDataGridColumnLookup, displayExpr?: string): IDevExpressDataGridColumnLookup {
  displayExpr = displayExpr || <string>lookup.displayExpr;
  if (isArray(lookup.dataSource) && isString(displayExpr) && displayExpr) {
    for (const dataSourceItem of lookup.dataSource) {
      dataSourceItem[displayExpr] = translator.instant(dataSourceItem[displayExpr]);
    }
  }
  return lookup;
}

export function devExpressDataGridColumnSuppressZeros(column: IDevExpressDataGridColumn): void {
  const originalCustomizeTextFn: TDevExpressDataGridColumnCustomizeTextFn = column.customizeText;
  column.customizeText = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string => {
    const valueText: string = cellInfo.target === 'row' && cellInfo.value === 0 ? '' : cellInfo.valueText;
    if (isFunction(originalCustomizeTextFn)) {
      return originalCustomizeTextFn({
        ...cellInfo,
        valueText: valueText
      });
    }
    return valueText;
  };
}

export function devExpressDataGridColumnPercent(column: IDevExpressDataGridColumn): void {
  const percentSign = '%';
  const originalCustomizeTextFn: TDevExpressDataGridColumnCustomizeTextFn = column.customizeText;
  column.alignment = 'right';
  column.customizeText = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string => {
    const valueText: string = cellInfo.valueText;
    if (isFunction(originalCustomizeTextFn)) {
      return originalCustomizeTextFn({
        ...cellInfo,
        valueText: valueText + percentSign
      });
    }
    return valueText + percentSign;
  };
}

export function devExpressDataGridStateSortToOrder(state: IDevExpressDataGridState): string {
  if (!isArray(state?.columns)) {
    return '';
  }
  return state.columns
    .filter((column: IDevExpressDataGridStateColumn) => isNumber(column.sortIndex))
    .sort((a: IDevExpressDataGridStateColumn, b: IDevExpressDataGridStateColumn) => a.sortIndex - b.sortIndex)
    .map<string>((column: IDevExpressDataGridStateColumn) => (column.sortOrder !== 'asc' ? `${column.dataField} ${column.sortOrder}` : column.dataField))
    .join(',');
}

export function devExpressDataGridIsEditingCell(component: dxDataGrid, rowIndex: number, dataFieldOrColumnIndex: string | number): boolean {
  const cellElement: Element = component.getCellElement(rowIndex, <string>dataFieldOrColumnIndex);
  return cellElement?.matches(SELECTOR_DEV_EXPRESS_EDITOR_CELL) ?? false;
}

export function devExpressDataGridCellTriggerChange(cellElement: EventTarget): void {
  const event = new KeyboardEvent('change', {key: KEYCODES.ENTER, altKey: false, ctrlKey: false, metaKey: false, bubbles: true, cancelable: true});
  cellElement.dispatchEvent(event);
}

export function devExpressDataGridProcessResult<T>(
  result: IDevExpressDataGridLoadResult<T> | IApiQueryResponse<T> | Array<T> | HttpResponse<IApiQueryResponse<T> | Array<T>>
): IDevExpressDataGridLoadResult<T> {
  let loadResult: IDevExpressDataGridLoadResult<T> = {data: [], totalCount: 0};
  const resultData: IDevExpressDataGridLoadResult<T> | Array<T> | IApiQueryResponse<T> = result instanceof HttpResponse ? result.body : result;
  if (isArray(resultData)) {
    loadResult.data = resultData;
    loadResult.totalCount = resultData.length;
  } else if (isObject(resultData)) {
    if (Object.prototype.hasOwnProperty.call(resultData, 'list') && Object.prototype.hasOwnProperty.call(resultData, 'total')) {
      loadResult.data = (<IApiQueryResponse<T>>resultData).list;
      loadResult.totalCount = (<IApiQueryResponse<T>>resultData).total;
    } else if (Object.prototype.hasOwnProperty.call(resultData, 'data') && Object.prototype.hasOwnProperty.call(resultData, 'totalCount')) {
      loadResult = <IDevExpressDataGridLoadResult<T>>resultData;
    }
  }
  return loadResult;
}

export function devExpressDataGridNormalizeFilterExpressionAccents(
  this: IDevExpressDataGridColumn,
  filterValue: string,
  selectedFilterOperation: TDevExpressComparisonOperator
): TDevExpressFilterExpression {
  const normalizedColumnValue = (columnData: unknown): string => (columnData ? normalizeAccents(columnData[this.dataField]) || '' : '');
  const normalizedFilterValue = normalizeAccents(filterValue);
  return [normalizedColumnValue, selectedFilterOperation || 'contains', normalizedFilterValue];
}

export function devExpressDataGridCellPreparedHighlight(event: IDevExpressDataGridEventOnCellPrepared): void {
  if (event.rowType === 'data' && !event.column.type && (!event.column.dataType || event.column.dataType === 'string')) {
    const searchPanelText: string = <string>event.component.option('searchPanel.text') || '';
    const filterValue: string = <string>event.component.columnOption(event.column.name, 'filterValue') || '';
    if (!searchPanelText && !filterValue) {
      return;
    }

    let element: HTMLElement = event.cellElement;
    const elementSearchText: HTMLElement = element.querySelector<HTMLElement>(`.${KLASS_DATA_GRID_SEARCH_TEXT}`);
    if (elementSearchText) {
      element = elementSearchText;
    }

    highlight(element, searchPanelText);
    highlight(element, filterValue);
  }
}

function highlight(element: InnerHTML, search: string): void {
  if (search) {
    element.innerHTML = cgcHighlight(element.innerHTML, search, true, false, KLASS_DATA_GRID_SEARCH_TEXT);
  }
}
