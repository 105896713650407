<div class="conciliacao-bancaria-bancos-tree-viewer" [plPromise]="promise">
  <tree-viewer
    [tree]="tree"
    [model]="model"
    [disabled]="disabled"
    [collapseOnSelect]="collapseOnSelect"
    [callback]="callback"
    (modelChange)="changedSelectedNode($event)"
    (evtDoubleClickedItem)="evtDoubleClickedItem.emit($event)">
  </tree-viewer>
</div>
