import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_TRANSMISSAO_ATIVA} from './tipoTransmissaoActiva.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPO_TRANSMISSAO_ATIVA: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_TRANSMISSAO_ATIVA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'tipoTransmissaoActiva.data.notApplicable'},
    {value: 1, name: 'tipoTransmissaoActiva.data.transmissionSubject'},
    {value: 2, name: 'tipoTransmissaoActiva.data.advanceReceiptsYear'},
    {value: 3, name: 'tipoTransmissaoActiva.data.opLocalizedForeignProviderReverseCharge'},
    {value: 4, name: 'tipoTransmissaoActiva.data.transmitFreeWithoutDeductible'},
    {value: 5, name: 'tipoTransmissaoActiva.data.civilConstructionServicesReverseCharge'},
    {value: 6, name: 'tipoTransmissaoActiva.data.transmissionScrapReverseCharge'},
    {value: 7, name: 'tipoTransmissaoActiva.data.transmissionExemptOrNotSubjectDeduction'},
    {value: 8, name: 'tipoTransmissaoActiva.data.transmissionExemptTourismVehicles'},
    {value: 9, name: 'tipoTransmissaoActiva.data.transmissionExemptVAT'},
    {value: 10, name: 'tipoTransmissaoActiva.data.priorYearReceiptsWithDefinitiveBillingYear'},
    {value: 11, name: 'tipoTransmissaoActiva.data.establishmentTransmission'},
    {value: 12, name: 'tipoTransmissaoActiva.data.otherActiveOperationsOutsideCIVAField'},
    {value: 13, name: 'tipoTransmissaoActiva.data.intraCommunityTransmission'},
    {value: 14, name: 'tipoTransmissaoActiva.data.transmissionExport'},
    {value: 15, name: 'tipoTransmissaoActiva.data.opMNLocalizedProviderEUResidentsReverseCharge'},
    {value: 16, name: 'tipoTransmissaoActiva.data.transmissionGoldReverseCharge'},
    {value: 17, name: 'tipoTransmissaoActiva.data.transmissionRealEstateWaiverExemptionReverseCharge'},
    {value: 18, name: 'tipoTransmissaoActiva.data.opFromAllocationOwnUseFreeTransmissionsAllocationSectorExemptActivity'},
    {value: 19, name: 'tipoTransmissaoActiva.data.opBankingActivity'},
    {value: 20, name: 'tipoTransmissaoActiva.data.insuranceBusinessOpportunity'},
    {value: 21, name: 'tipoTransmissaoActiva.data.opportunityRentalRealEstate'},
    {value: 22, name: 'tipoTransmissaoActiva.data.triangularIntraCommunityTransmission'},
    {value: 23, name: 'tipoTransmissaoActiva.data.intraCommunityService'},
    {value: 24, name: 'tipoTransmissaoActiva.data.transmissionNewMeansTransportEUIndividuals'},
    {value: 25, name: 'tipoTransmissaoActiva.data.greenhouseGasEmissionReverseCharge'},
    {value: 26, name: 'tipoTransmissaoActiva.data.operacoesEfetuadasNoEstrangeiro'},
    {value: 27, name: 'tipoTransmissaoActiva.data.outrasOperacoesIsentasOuSemLiquidacaoDeIVAQueConferemDireitoADeducao'}
  ]
});
