<div
  class="contabilidade-digital-doc-viewer-recolha"
  [class.contabilidade-digital-doc-viewer-recolha-default]="state === states.Default"
  [class.contabilidade-digital-doc-viewer-recolha-editing]="state === states.Editing"
  [class.contabilidade-digital-doc-viewer-recolha-searching]="state === states.Searching">
  <div class="card">
    <div class="card-header contabilidade-digital-doc-viewer-recolha-title" *ngIf="showHeader" [ngSwitch]="state">
      <button type="button" class="btn btn-link" *ngSwitchCase="states.Default" [disabled]="loading || readonly" (click)="chooseFolder()">
        <i class="fa fa-fw" [ngClass]="{'fa-circle-o-notch fa-spin': loading, 'fa-folder-open-o': !loading}" aria-hidden="true"></i>
        <span [translate]="'arquivodigital.docviewerrecolha.title.location'"></span>:&nbsp;
        <span class="contabilidade-digital-doc-viewer-recolha-title-location" [attr.title]="locationWithFilename">{{ locationWithFilename }}</span>
      </button>

      <span *ngSwitchCase="states.Editing" [ngSwitch]="!!attachmentDocId">
        <ng-container *ngSwitchCase="true">
          <i class="fa fa-fw" [ngClass]="{'fa-circle-o-notch fa-spin': loading, 'fa-folder-open-o': !loading}" aria-hidden="true"></i>
          <span [translate]="'arquivodigital.docviewerrecolha.title.location'"></span>:&nbsp;
          <span class="contabilidade-digital-doc-viewer-recolha-title-location" [attr.title]="locationWithFilename">{{ locationWithFilename }}</span>
        </ng-container>

        <ng-container *ngSwitchCase="false">
          <i class="fa fa-fw" [ngClass]="{'fa-circle-o-notch fa-spin': loading, 'fa-times': !loading}" aria-hidden="true"></i>
          <span [translate]="'arquivodigital.docviewerrecolha.title.noAttachment'"></span>
        </ng-container>
      </span>

      <ng-container *ngSwitchCase="states.Searching">
        <span *ngIf="validSearch">
          <i
            class="fa fa-fw"
            aria-hidden="true"
            [ngClass]="{
              'fa-circle-o-notch fa-spin': loading,
              'fa-times text-danger': !loading && attachmentsTotal === 0,
              'fa-check text-success': !loading && attachmentsTotal === 1 && oneHundredPercentMatch,
              'fa-exclamation-triangle text-warning': !loading && (attachmentsTotal > 1 || !oneHundredPercentMatch)
            }">
          </i>
          <span [attr.title]="searchingTitle">{{ searchingTitle }}</span>
        </span>
      </ng-container>
    </div>

    <div class="contabilidade-digital-doc-viewer-recolha-body">
      <pl-alert type="info" *ngIf="!loading && state === states.Default && attachmentsTotal === 0" [closeable]="false" theme="mb-1 p-2">
        <ng-container><i class="fa fa-exclamation-circle"></i>&nbsp;</ng-container>
        <ng-container *ngIf="location"><span [translate]="'arquivodigital.docviewerrecolha.text.emptyAttachments1'" [translateParams]="{location: location}"></span>&nbsp;</ng-container>
        <span
          class="hyperlink"
          [translate]="location ? 'arquivodigital.docviewerrecolha.text.emptyAttachments2' : 'arquivodigital.docviewerrecolha.text.emptyLocation'"
          (click)="chooseFolder()"></span>
      </pl-alert>

      <arquivodigital-docviewer
        [documentsService]="documentsService"
        [(docId)]="attachmentDocId"
        [toolbarInstanceId]="toolbarInstanceId"
        [hideToolbarOnEmptyDoc]="hideToolbarOnEmptyDoc"
        [showAll]="true"
        [nDocumento]="nDocumento"
        [callback]="callback"
        (evtConfigureToolbar)="configureToolbar($event)">
      </arquivodigital-docviewer>
    </div>

    <ng-container *ngIf="showFooter && selectedAttachment && state !== states.Editing">
      <hr />

      <contabilidade-digital-doc-viewer-recolha-footer
        [docOCR]="selectedAttachment"
        [showLinhasIVA]="showLinhasIVA"
        [showCollapse]="showFooterCollapse"
        [collapsed]="footerCollapsed"
        (collapsedChange)="changedFooterCollapsed($event)">
      </contabilidade-digital-doc-viewer-recolha-footer>
    </ng-container>
  </div>
</div>
