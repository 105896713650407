<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'emailclifos.sendMail.title'" [translateParams]="{nConta: emailContent.nConta, nomeConta: emailContent.nomeConta}"></h4>
</div>

<div class="modal-body">
  <pl-form>
    <pl-group>
      <label [translate]="'emailclifos.sendMail.email'"></label>
      <edit>
        <pl-edit type="text" [(model)]="emailContent.emails"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'emailclifos.sendMail.assunto'"></label>
      <edit>
        <pl-edit type="text" [(model)]="emailContent.subject"></pl-edit>
      </edit>
    </pl-group>

    <pl-group *ngIf="anexos?.length">
      <label [translate]="'emailclifos.sendMail.anexos'"></label>
      <edit>
        <div *ngFor="let anexo of anexos">
          <i class="fa fa-fw fa-paperclip"></i>&nbsp;<span>{{ anexo }}</span>
        </div>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'emailclifos.sendMail.texto'"></label>
      <edit>
        <dx-html-editor [(ngModel)]="emailContent.body" [height]="400">
          <dxo-toolbar [multiline]="true">
            <dxi-item name="undo"></dxi-item>
            <dxi-item name="redo"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"></dxi-item>
            <dxi-item name="font" [acceptedValues]="['Arial', 'Georgia', 'Tahoma', 'Times New Roman', 'Verdana']"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="bold"></dxi-item>
            <dxi-item name="italic"></dxi-item>
            <dxi-item name="strike"></dxi-item>
            <dxi-item name="underline"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="alignLeft"></dxi-item>
            <dxi-item name="alignCenter"></dxi-item>
            <dxi-item name="alignRight"></dxi-item>
            <dxi-item name="alignJustify"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="orderedList"></dxi-item>
            <dxi-item name="bulletList"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="color"></dxi-item>
            <dxi-item name="background"></dxi-item>
            <dxi-item name="separator"></dxi-item>
            <dxi-item name="link"></dxi-item>
            <dxi-item name="image"></dxi-item>
          </dxo-toolbar>
        </dx-html-editor>
      </edit>
    </pl-group>
  </pl-form>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary" (evtClicked)="close()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'emailclifos.sendMail.btn.send'"></span></pl-button>

  <ng-container *ngIf="numEmails > 1">
    <pl-button klass="btn-warning" (evtClicked)="ignore()" [tooltip]="{text: 'emailclifos.sendMail.tooltips.ignore', placement: 'top'}">
      <i class="fa fa-fw fa-step-forward"></i>&nbsp;<span [translate]="'emailclifos.sendMail.btn.ignore'"></span>
    </pl-button>
  </ng-container>

  <pl-button klass="btn-light" (evtClicked)="dismiss()" [tooltip]="{disabled: numEmails <= 1, text: 'emailclifos.sendMail.tooltips.cancel', placement: 'top'}">
    <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
  </pl-button>
</div>
