export const APP_LOCALES: ReadonlyArray<string> = Object.freeze(['en', 'es', 'fr', 'pt-PT']);

export const LOCALE_DEFAULT_CURRENCY_CODE = 'EUR';

export const LOCALE_CURRENCY_CODES_MAP = Object.freeze(
  new Map<string, string>([
    ['en', 'USD'],
    ['es', 'EUR'],
    ['fr', 'EUR'],
    ['pt-PT', 'EUR']
  ])
);

export const LOCALE_FLAGS_MAP = Object.freeze(
  new Map<string, string>([
    ['en', 'gb'],
    ['es', 'es'],
    ['fr', 'fr'],
    ['pt-PT', 'pt']
  ])
);
