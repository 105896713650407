import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_ESTADO_EMP, EEstadoEmp} from './estadoEmp.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_ESTADO_EMP: IDataSource<EEstadoEmp> = deepFreeze({
  name: DATA_SOURCE_NAME_ESTADO_EMP,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EEstadoEmp.Activo, name: 'estadoemp.ativo'},
    {value: EEstadoEmp.Inactivo, name: 'estadoemp.inativo'},
    {value: EEstadoEmp.Ambos, name: 'estadoemp.ambos'}
  ]
});
