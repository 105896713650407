import {AfterContentChecked, ContentChildren, Directive, Input, QueryList} from '@angular/core';
import {PlTabTitleDirective} from './tab.title.directive';
import {PlTabContentDirective} from './tab.content.directive';

let nextId = 0;

@Directive({
  selector: 'pl-tab',
  standalone: false
})
export class PlTabDirective implements AfterContentChecked {
  @Input() public id: any;
  @Input() public caption: string;
  @Input() public disabled: boolean;
  @Input() public closable: boolean;

  public templateTitle: PlTabTitleDirective;
  public templateContent: PlTabContentDirective;

  @ContentChildren(PlTabTitleDirective, {descendants: false}) private readonly _titleTemplates: QueryList<PlTabTitleDirective>;
  @ContentChildren(PlTabContentDirective, {descendants: false}) private readonly _contentTemplates: QueryList<PlTabContentDirective>;

  constructor() {
    this.id = `pl-tab-${nextId++}`;
  }

  public ngAfterContentChecked(): void {
    this.templateTitle = this._titleTemplates.first;
    this.templateContent = this._contentTemplates.first;
  }
}
