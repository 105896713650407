import type {Subscription} from 'rxjs';
import {AfterViewInit, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild} from '@angular/core';
import {IPlSplitViewResizerEventValues, IPlSplitViewSizeValues} from './splitview.component.interface';
import {isNumber} from '../common/utilities/utilities';
import {PlDocumentService} from '../common/document/document.service';
import {PlSplitViewLeftSideDirective} from './splitview.leftside.directive';
import {PlSplitViewRightSideDirective} from './splitview.rightside.directive';

const DEFAULT_INITIAL_SIZE = 50; // as percentage
const SELECTOR_SPLIT_VIEW = '.pl-split-view';

@Component({
  selector: 'pl-split-view',
  templateUrl: './splitview.component.html',
  standalone: false
})
export class PlSplitViewComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() public initialSizeLeft: number;
  @Input() public initialSizeRight: number;
  @Input() public initialSizeAsPx: boolean;
  @Input() public hideLeftSide: boolean;
  @Input() public hideRightSide: boolean;
  @Output() public readonly evtResizerMoved: EventEmitter<IPlSplitViewResizerEventValues>;
  @Output() public readonly evtSizeValuesChanged: EventEmitter<IPlSplitViewSizeValues>;

  @ContentChild(PlSplitViewLeftSideDirective) public readonly leftSideDirective: PlSplitViewLeftSideDirective;
  @ContentChild(PlSplitViewRightSideDirective) public readonly rightSideDirective: PlSplitViewRightSideDirective;
  public sizeLeftWidth: string;
  public sizeRightWidth: string;

  private readonly _element: HTMLElement;
  private readonly _subscriptionWindowResize: Subscription;
  private readonly _eventValues: IPlSplitViewResizerEventValues;
  private readonly _sizeValues: IPlSplitViewSizeValues;
  private _mouseMoving: boolean;
  private _elementLeft: HTMLElement;
  private _elementRight: HTMLElement;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _plDocumentService: PlDocumentService
  ) {
    this.initialSizeAsPx = false;
    this.hideLeftSide = false;
    this.hideRightSide = false;
    this.evtResizerMoved = new EventEmitter<IPlSplitViewResizerEventValues>();
    this.evtSizeValuesChanged = new EventEmitter<IPlSplitViewSizeValues>();
    this._element = this._elementRef.nativeElement;
    this._subscriptionWindowResize = this._plDocumentService.windowResizeDebounced().subscribe(() => {
      if (!this._mouseMoving) {
        this._sizeValuesChanged();
      }
    });
    this._eventValues = {
      resizerClientX: undefined,
      leftSideWidth: undefined,
      rightSideWidth: undefined
    };
    this._sizeValues = {
      clientWidth: -1,
      sizeLeftAsPercentage: -1,
      sizeRightAsPercentage: -1,
      sizeLeftAsPx: -1,
      sizeRightAsPx: -1
    };
    this._mouseMoving = false;
  }

  public ngOnChanges({hideLeftSide, hideRightSide}: SimpleChanges): void {
    if ((hideLeftSide && !hideLeftSide.isFirstChange()) || (hideRightSide && !hideRightSide.isFirstChange())) {
      this._changedHideSide();
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionWindowResize.unsubscribe();
  }

  public ngAfterViewInit(): void {
    this._setInitialWidths();
    this._changeDetectorRef.detectChanges();
  }

  public mouseDowned(event: MouseEvent): void {
    this._mouseMoving = true;
    this._eventValues.resizerClientX = event.clientX;
    this._eventValues.leftSideWidth = this._elementLeft.offsetWidth;
    this._eventValues.rightSideWidth = this._elementRight.offsetWidth;
  }

  public resizerMoved(event: MouseEvent): void {
    if (!this._mouseMoving) {
      return;
    }
    const currentResizerClientX: number = event.clientX;
    if (!this._elementLeft || !this._elementRight || currentResizerClientX === this._eventValues.resizerClientX) {
      return;
    }
    let sizeLeft: number;
    let sizeRight: number;
    let sizeValues: IPlSplitViewSizeValues;
    if (this.hideLeftSide || this.hideRightSide) {
      if (this.hideLeftSide) {
        sizeLeft = 0;
        sizeRight = 100;
      } else {
        sizeLeft = 100;
        sizeRight = 0;
      }
      sizeValues = this._percentageToPx(sizeLeft, sizeRight);
    } else {
      const deltaX: number = event.clientX - this._eventValues.resizerClientX;
      sizeLeft = this._eventValues.leftSideWidth + deltaX;
      sizeRight = this._eventValues.rightSideWidth - deltaX;
      sizeValues = this._pxToPercentage(sizeLeft, sizeRight);
    }
    this._setWidths(sizeValues);
  }

  public mouseUpped(): void {
    this._mouseMoving = false;
    this._sizeValuesChanged();
  }

  @ViewChild('elementLeftSide')
  public set elementLeftSide(value: ElementRef<HTMLElement>) {
    this._elementLeft = value?.nativeElement;
  }

  @ViewChild('elementRightSide')
  public set elementRightSide(value: ElementRef<HTMLElement>) {
    this._elementRight = value?.nativeElement;
  }

  private _setInitialWidths(): void {
    let sizeLeft: number;
    let sizeRight: number;
    let sizeValues: IPlSplitViewSizeValues;
    if (this.hideLeftSide || this.hideRightSide) {
      if (this.hideLeftSide) {
        sizeLeft = 0;
        sizeRight = 100;
      } else {
        sizeLeft = 100;
        sizeRight = 0;
      }
      sizeValues = this._percentageToPx(sizeLeft, sizeRight);
    } else {
      if (!isNumber(this.initialSizeLeft)) {
        if (isNumber(this.initialSizeRight) && this.initialSizeRight >= 0 && this.initialSizeRight <= 100) {
          this.initialSizeLeft = 100 - this.initialSizeRight;
        } else {
          this.initialSizeLeft = DEFAULT_INITIAL_SIZE;
          this.initialSizeRight = DEFAULT_INITIAL_SIZE;
        }
      }
      if (!isNumber(this.initialSizeRight)) {
        if (isNumber(this.initialSizeLeft) && this.initialSizeLeft >= 0 && this.initialSizeLeft <= 100) {
          this.initialSizeRight = 100 - this.initialSizeLeft;
        } else {
          this.initialSizeLeft = DEFAULT_INITIAL_SIZE;
          this.initialSizeRight = DEFAULT_INITIAL_SIZE;
        }
      }
      if (this.initialSizeRight < 0 || this.initialSizeRight > 100 || this.initialSizeRight < 0 || this.initialSizeRight > 100) {
        this.initialSizeLeft = DEFAULT_INITIAL_SIZE;
        this.initialSizeRight = DEFAULT_INITIAL_SIZE;
      }
      sizeValues = !this.initialSizeAsPx ? this._percentageToPx(this.initialSizeLeft, this.initialSizeRight) : this._pxToPercentage(this.initialSizeLeft, this.initialSizeRight);
    }
    this._setWidths(sizeValues);
  }

  private _changedHideSide(): void {
    if (this.hideLeftSide || this.hideRightSide) {
      if (this._mouseMoving) {
        this._mouseMoving = false;
      }
      let sizeLeft: number;
      let sizeRight: number;
      if (this.hideLeftSide) {
        sizeLeft = 0;
        sizeRight = 100;
      } else {
        sizeLeft = 100;
        sizeRight = 0;
      }
      const sizeValues: IPlSplitViewSizeValues = this._percentageToPx(sizeLeft, sizeRight);
      this._setWidths(sizeValues);
    } else {
      this._setInitialWidths();
    }
  }

  private _setWidths({clientWidth, sizeLeftAsPercentage, sizeRightAsPercentage, sizeLeftAsPx, sizeRightAsPx}: IPlSplitViewSizeValues): void {
    this.sizeLeftWidth = `${sizeLeftAsPercentage}%`;
    this.sizeRightWidth = `${sizeRightAsPercentage}%`;
    this._sizeValues.clientWidth = clientWidth;
    this._sizeValues.sizeLeftAsPercentage = sizeLeftAsPercentage;
    this._sizeValues.sizeRightAsPercentage = sizeRightAsPercentage;
    this._sizeValues.sizeLeftAsPx = sizeLeftAsPx;
    this._sizeValues.sizeRightAsPx = sizeRightAsPx;
  }

  private _sizeValuesChanged(): void {
    this.evtSizeValuesChanged.emit({...this._sizeValues});
  }

  private _percentageToPx(sizeLeftAsPercentage: number, sizeRightAsPercentage: number): IPlSplitViewSizeValues {
    const elementSplitView: HTMLElement = this._element.querySelector<HTMLElement>(SELECTOR_SPLIT_VIEW);
    const clientWidth: number = elementSplitView.clientWidth;
    const sizeLeftAsPx: number = (sizeLeftAsPercentage * clientWidth) / 100;
    const sizeRightAsPx: number = (sizeRightAsPercentage * clientWidth) / 100;
    return {
      clientWidth: clientWidth,
      sizeLeftAsPercentage: sizeLeftAsPercentage,
      sizeRightAsPercentage: sizeRightAsPercentage,
      sizeLeftAsPx: sizeLeftAsPx,
      sizeRightAsPx: sizeRightAsPx
    };
  }

  private _pxToPercentage(sizeLeftAsPx: number, sizeRightAsPx: number): IPlSplitViewSizeValues {
    const elementSplitView: HTMLElement = this._element.querySelector<HTMLElement>(SELECTOR_SPLIT_VIEW);
    const clientWidth: number = elementSplitView.clientWidth;
    const sizeLeftAsPercentage: number = (sizeLeftAsPx * 100) / clientWidth;
    const sizeRightAsPercentage: number = (sizeRightAsPx * 100) / clientWidth;
    return {
      clientWidth: clientWidth,
      sizeLeftAsPercentage: sizeLeftAsPercentage,
      sizeRightAsPercentage: sizeRightAsPercentage,
      sizeLeftAsPx: sizeLeftAsPx,
      sizeRightAsPx: sizeRightAsPx
    };
  }
}
