import {StateDeclaration, StateObject, Transition} from '@uirouter/core';
import {isArray} from 'pl-comps-angular';
import {AssistenteConfigPortaisModuleComponent} from './components/assistenteConfigPortais.module.component';
import {AssistenteConfigPortaisService} from './assistente.configPortais.module.service';
import {ConfigSiteService} from '../../../services/configsite.service';
import {EPortal} from '../../../../common/enums/portals.enums';
import {IAssistenteConfigPortal, IAssistenteConfigPortalOperation, MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS} from './assistente.configPortais.module.service.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';

export const MODULE_ASSISTENTE_CONFIG_PORTAIS: IModuleDefinition = {
  name: MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS,
  state: {
    url: '/assistenteconfigportais',
    component: AssistenteConfigPortaisModuleComponent,
    data: {
      roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
      pageTitle: 'global.menu.assistenteConfigPortais',
      icon: 'fa-wrench'
    },
    resolve: [
      {
        provide: 'portalsList',
        deps: [Transition, ConfigSiteService, AssistenteConfigPortaisService],
        useFactory: (transition: Transition, configSiteService: ConfigSiteService, assistenteConfigPortaisService: AssistenteConfigPortaisService): Promise<Array<IAssistenteConfigPortal>> => {
          return configSiteService.cgStoreUrl().then((cgStoreUrl: string) => {
            let isConfiguracoes = false;
            if (cgStoreUrl) {
              const toState: StateObject = transition.$to();
              const parentState: StateDeclaration = toState.parent.self;
              const statePortal: string = parentState.url.slice(1);
              isConfiguracoes = statePortal === EPortal.CONFIGURACOES;
            }
            return assistenteConfigPortaisService.getPortais().then((portais: Array<IAssistenteConfigPortal>) => {
              if (isConfiguracoes) {
                for (const portal of portais) {
                  if (portal.name === 'geral') {
                    portal.operations = portal.operations.filter((operation: IAssistenteConfigPortalOperation) => operation.moduleName !== 'assistConfigPortaisGeralEmail');
                  }
                }
              }
              return portais.filter((portal: IAssistenteConfigPortal) => isArray(portal.operations) && portal.operations.length > 0);
            });
          });
        }
      }
    ]
  }
};
