import {merge} from 'lodash-es';
import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService, PlI18nService, PlTranslateService} from 'pl-comps-angular';
import {ECalendarioTipoDiaStr} from '../../../../../../../entities/calendarios/jsonCalendarios.entity.interface';
import {ECampoCalculadoME} from '../../../../../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {ENTITY_NAME_REMUN} from '../../../../../../../entities/rhrecolhadados/rhRecolhaDados.entity.interface';
import {ETipoAbonoDesconto} from '../../../../../../../entities/abdes/abdes.entity.interface';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../../../../config/constants';
import {GestaoDGEMPSMarcarAbstractModalComponent} from '../../../../../../../components/gestaodgemps/modals/marcar/gestaodgemps.marcar.abstract.modal.component';
import {IGestaoDGEMPSCalendario, IGestaoDGEMPSMarcarModalData} from '../../../../../../../components/gestaodgemps/gestaodgemps.interface';
import {IJsonDGEMPCalendario} from '../../../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHEventosConfig} from '../../../../../../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import {IJsonPRHMarcarAbonos} from '../../../../../../../interfaces/jsonPRHAbonos.interface';
import {IJsonREMDadosValoresUni, IJsonREMUNFX} from '../../../../../../../entities/rhrecolhadados/jsonRHRecolhaDados.entity.interface';
import {Moment} from 'moment';
import {PortalClientAccountService} from '../../../../../../../services/portalclientaccount/portalclientaccount.service';
import {RHRecolhaDadosService} from '../../../../../../../entities/rhrecolhadados/rhRecolhaDados.entity.service';

@Component({
  selector: 'pca-rh-gestao-dgemps-abonos-marcar-modal',
  templateUrl: './pca.rh.gestaoDGEMPS.abonos.marcar.modal.component.html'
})
export class PcaRhGestaoDGEMPSAbonosMarcarModalComponent extends GestaoDGEMPSMarcarAbstractModalComponent implements OnInit {
  public tipoAbono: IJsonPRHEventosConfig;
  public quantidade: number;
  public valor: number;
  public valorUnitario: number;
  public promise: Promise<void>;

  private readonly _defaultRecolhaDados: IJsonREMUNFX;
  private _rem: IJsonREMUNFX;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _plI18nService: PlI18nService,
    protected readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _portalClientAccountService: PortalClientAccountService,
    private readonly _rhRecolhaDadosService: RHRecolhaDadosService
  ) {
    super(_injector, _plI18nService, _plTranslateService);
    this.quantidade = 0;
    this.valor = 0;
    this.valorUnitario = 0;
    this._rem = this._emptyModelRem();

    this._rhRecolhaDadosService.getDefaultRecolhaDados(ENTITY_NAME_REMUN).then((response: HttpResponse<IJsonREMUNFX>) => {
      merge({}, response.body, this._defaultRecolhaDados);
      this._setDefaultModel();
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const calendarios: Array<IGestaoDGEMPSCalendario> = this.marcarData.calendarios.slice();
    this._rem.codEMP = calendarios.shift().codEmp;
    this._rem.data = this.marcarData.startDate;
    this._rem.dataProcess = this.marcarData.startDate;
  }

  public onTipoAbonoChange(tipoAbono: IJsonPRHEventosConfig): Promise<void> {
    this.tipoAbono = tipoAbono;
    this._rem.ncodABDESC = this.tipoAbono.ncodAbdes;
    this._rem.codABDESC = this.tipoAbono.ncodAbdes.slice(1);
    this.promise = this._getValoresByCodEmp();
    return this.promise;
  }

  public onQuantidadeChange(quantidade: number): void {
    this.quantidade = quantidade;
    this.valorUnitario = this.valor / quantidade;
  }

  public onValorChange(valor: number): void {
    this.valor = valor;
    this.valorUnitario = valor / this.quantidade;
  }

  public close(): Promise<void> {
    if (!this.tipoAbono) {
      this._plAlertService.error('gestaodgemps.errors.invalidTipoAbono');
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }

    this.disableClose();

    const {startDate, endDate}: IGestaoDGEMPSMarcarModalData = this.marcarData;
    const calendarios: Array<IGestaoDGEMPSCalendario> = this.marcarData.calendarios.slice();

    const fnMarcarAbonos = (): Promise<void> => {
      const calendario = calendarios.shift();
      if (!calendario) {
        return Promise.resolve();
      }
      return this._marcarAbono(startDate, endDate, calendario).then(fnMarcarAbonos);
    };

    return fnMarcarAbonos()
      .then(() => {
        this.enableClose();
        super.close();
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }

  private _marcarAbono(startDate: Moment, endDate: Moment, calendario: IGestaoDGEMPSCalendario): Promise<void> {
    const abonos: IJsonPRHMarcarAbonos = {
      cab: undefined,
      linhas: [],
      motivo: undefined,
      codEmpLanca: this.codEmpLanca,
      codServico: this.codServico,
      proximoPapel: -1
    };

    for (const dataAtual = startDate.clone(); dataAtual.diff(endDate, 'days') <= 0; dataAtual.add(1, 'day')) {
      const dataCalendario: IJsonDGEMPCalendario = calendario.listaCalendario.find((day: IJsonDGEMPCalendario) => dataAtual.isSame(day.data, 'day'));
      if (!dataCalendario || dataCalendario.tipoDiaStr === ECalendarioTipoDiaStr.DiaUtil) {
        abonos.linhas.push({
          idTarefaCab: undefined,
          posicao: undefined,
          codPRHAbonos: undefined,
          tipoProcessamento: this.tipoAbono.tipoProcessamento,
          ncodAbdes: this.tipoAbono.ncodAbdes,
          dataAbono: dataAtual.clone(),
          quantidade: this.quantidade,
          remunId: undefined,
          remfxId: undefined,
          dataAteRecFix: undefined,
          dadosFixos: false,
          valor: this.valor
        });
      }
    }

    return this._portalClientAccountService.marcarAbonosEmpregado(calendario.codEmp, abonos).then(() => {
      this._successfulCalendarios.add(calendario);
      for (let i = this.marcarData.calendarios.length - 1; i >= 0; i--) {
        if (this.marcarData.calendarios[i].codEmp === calendario.codEmp) {
          this.marcarData.calendarios.splice(i, 1);
        }
      }
    });
  }

  /*---------------------------------------------------------RECOLHA DE DADOS---------------------------------------------*/

  private _getValoresByCodEmp(): Promise<void> {
    if (this._rem.codEMP && this._rem.codABDESC) {
      return this._rhRecolhaDadosService.getEmpRecolhaDadosManual(ENTITY_NAME_REMUN, this._rem).then((response: HttpResponse<IJsonREMDadosValoresUni>) => {
        this._rem = {...this._rem, ...response.body.rem};
        this.valorUnitario = this._rem.valorUNIT;
      });
    }
    return Promise.resolve();
  }

  private _setDefaultModel(): void {
    this._rem = merge(
      {},
      {
        rem: {
          ...this._emptyModelRem(),
          ...this._defaultRecolhaDados
        }
      },
      this._rem
    );
    if (!this._rem.tipoProcessamento && this._rem.nomeTipoProcessamento) {
      this._rem.tipoProcessamento = 0;
    }
  }

  private _emptyModelRem(): IJsonREMUNFX {
    return {
      tipoProcessamento: undefined,
      data: undefined,
      dataProcess: undefined,
      abonoDesc: ETipoAbonoDesconto.Abono,
      codABDESC: undefined,
      ncodABDESC: undefined,
      codEMP: undefined,
      quantidade: 1,
      valor: undefined,
      codUnidade: undefined,
      vALINCIDESC: undefined,
      tipoVALUNIT: undefined,
      valorUNIT: undefined,
      vALREMSARRED: undefined,
      codMOEDA: undefined,
      iNSMANUAL: undefined,
      cambioMEParaMC: undefined,
      valorME: undefined,
      campoMECalculado: ECampoCalculadoME.Valor,
      contador: undefined,
      descricao: undefined,
      dtReferencia: undefined,
      remUNID: undefined,
      tipoHorario: undefined,
      repartRepSL: '1',
      remFXID: undefined,
      suspende: undefined,
      nomeTipoUnidade: undefined,
      htrabDia: undefined,
      htrabMes: undefined,
      htrabSemana: undefined,
      nomeTipoProcessamento: undefined,
      nomeAbonoDesconto: undefined,
      nomeEmpr: undefined,
      nomeMoeda: undefined,
      empregadoMoedaEstrangeira: undefined,
      adicionaSubRefeicaoAuto: false,
      ndiasSubAlimentacao: undefined,
      temPerdaSubRefeicao: false,
      todosProcessamentos: true,
      unidade: undefined
    };
  }
}
