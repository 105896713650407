import {HttpResponse} from '@angular/common/http';
import {IJsonConfigRHManutencaoCessacaoContrato, IJsonConfigRMMG} from '../jsonRHConfiguracoes.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_MANUTENCAO} from '../rhConfiguracoes.module.interface';
import {RHConfiguracoesService} from '../rhConfiguracoes.module.service';
import {ROLE} from '../../../services/role.const';
import {RhManutencaoModuleComponent} from './components/rhManutencao.module.component';

export const MODULE_RH_MANUTENCAO: IModuleDefinition = {
  name: MODULE_NAME_RH_MANUTENCAO,
  state: {
    url: `/${MODULE_NAME_RH_MANUTENCAO}`,
    component: RhManutencaoModuleComponent,
    data: {
      roles: [ROLE.RH],
      sidebarTitle: 'portals.sidebar.modules.rhmanutencao'
    },
    resolve: [
      {
        provide: 'cessacaoContrato',
        deps: [RHConfiguracoesService],
        useFactory: (rhConfiguracoesService: RHConfiguracoesService): Promise<IJsonConfigRHManutencaoCessacaoContrato> => {
          return rhConfiguracoesService.getManutencaoCessacaoContrato().then((response: HttpResponse<IJsonConfigRHManutencaoCessacaoContrato>) => response.body);
        }
      },
      {
        provide: 'configRMMG',
        deps: [RHConfiguracoesService],
        useFactory: (rhConfiguracoesService: RHConfiguracoesService): Promise<IJsonConfigRMMG> => {
          return rhConfiguracoesService.getConfigRMMG().then((response: HttpResponse<IJsonConfigRMMG>) => response.body);
        }
      }
    ]
  }
};
