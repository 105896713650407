import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonUNECE} from './jsonUnece.entity.interface';
import {ENTITY_NAME_UNECE} from './unece.entity.interface';

export const ENTITY_UNECE: IEntityDefinition<IJsonUNECE> = {
  name: ENTITY_NAME_UNECE,
  roles: [ROLE.ERP],
  metadata: {
    keyName: 'codigo',
    fields: [
      {
        name: 'codigo',
        type: 'string',
        caption: 'unidadesMedidaComercioInternacional.fields.codigo'
      },
      {
        name: 'designacao',
        type: 'string',
        caption: 'unidadesMedidaComercioInternacional.fields.designacao'
      },
      {
        name: 'designacaoEN',
        type: 'string',
        caption: 'unidadesMedidaComercioInternacional.fields.designacaoEN'
      },
      {
        name: 'simbolo',
        type: 'string',
        caption: 'unidadesMedidaComercioInternacional.fields.simbolo'
      },
      {
        name: 'tipoUnidadeCG',
        type: 'number',
        caption: 'unidadesMedidaComercioInternacional.fields.tipoUnidadeCG',
        visible: false
      }
    ],
    order: 'codigo',
    searchFields: 'codigo,designacao'
  },
  autocomplete: {
    rowTemplate: '{{codigo}} - {{designacao}} ({{simbolo}})',
    output: 'codigo',
    searchFields: 'codigo,designacao,simbolo'
  },
  actions: {
    new: false,
    detail: false,
    edit: false,
    delete: false,
    search: true,
    filter: false
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.unece'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.unece'
      }
    }
  }
};
