<div class="pl-navpill-wrapper">
  <div class="pl-navpill">
    <div class="pl-navpill-nav">
      <ul class="nav nav-pills flex-column pl-navpill-nav-pills">
        <li *ngFor="let pill of panels" class="pl-navpill-nav-pill nav-item" [class.active]="pill.id === activeId" [ngClass]="pill.captionClass">
          <div
            class="pl-navpill-nav-pills-link nav-link"
            role="tab"
            [id]="pill.id"
            [class.disabled]="pill.disabled"
            [attr.tabindex]="pill.disabled ? '-1' : undefined"
            [attr.aria-controls]="!destroyOnHide || pill.id === activeId ? pill.id + '-panel' : undefined"
            [attr.aria-expanded]="pill.id === activeId"
            [attr.aria-disabled]="pill.disabled"
            (click)="select(pill.id); $event.preventDefault()">
            <span [plTranslate]="pill.caption"></span>
            <ng-container *ngTemplateOutlet="pill.templateTitle?.templateRef"></ng-container>
          </div>
        </li>

        <li *ngIf="templateRefFooter" class="pl-navpill-nav-pill nav-item">
          <div class="nav-link">
            <ng-container *ngTemplateOutlet="templateRefFooter"></ng-container>
          </div>
        </li>
      </ul>
    </div>

    <div class="pl-navpill-content">
      <ng-container *ngFor="let pill of panels">
        <div
          *ngIf="!destroyOnHide || pill.id === activeId"
          class="pl-panel-content"
          role="tabpanel"
          [id]="pill.id + '-panel'"
          [class.active]="pill.id === activeId"
          [attr.aria-labelledby]="pill.id"
          [attr.aria-expanded]="pill.id === activeId"
          [hidden]="!destroyOnHide && pill.id !== activeId">
          <ng-container *ngTemplateOutlet="pill.templateContent?.templateRef"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
