import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_COMERCIAL_DIGITAL_DOCUMENTOS_DIGITAIS} from '../interfaces/comercialDigital.documentosDigitais.module.interface';
import {Type} from '@angular/core';
import {IJsonBoolean} from '../../../../common/interfaces/json';
import {HttpResponse} from '@angular/common/http';
import {ComercialDigitalDocumentosDigitaisModuleComponent} from './components/comercialDigital.documentosDigitais.module.component';
import {EGestaoDocumentosDigitalizadosMode} from '../../../components/arquivodigital/common/gestaodocumentosdigitalizados/arquivoDigital.gestaoDocumentosDigitalizados.component.interface';
import {RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL} from '../../../services/contabilidadedigital/contabilidadedigital.router.interface';
import {RESOLVER_CG_STORE_URL_BACKOFFICE} from '../../../../config/uirouter/uirouter.resolvers';
import {ComercialDigitalService} from '../services/comercialDigital.service';

export const MODULE_COMERCIAL_DIGITAL_DOCUMENTOS_DIGITAIS: IModuleDefinition = generateModule({
  moduleName: MODULE_NAME_COMERCIAL_DIGITAL_DOCUMENTOS_DIGITAIS,
  url: `/${MODULE_NAME_COMERCIAL_DIGITAL_DOCUMENTOS_DIGITAIS}`,
  component: ComercialDigitalDocumentosDigitaisModuleComponent,
  roles: [ROLE.ERPADVANCED],
  clientConnectMode: false,
  mode: EGestaoDocumentosDigitalizadosMode.Comercial
});

function generateModule({
  moduleName,
  url,
  component,
  roles,
  clientConnectMode,
  mode
}: {
  moduleName: string;
  url: string;
  component: Type<ComercialDigitalDocumentosDigitaisModuleComponent>;
  roles: Array<ROLE>;

  clientConnectMode: boolean;
  mode: EGestaoDocumentosDigitalizadosMode;
}): IModuleDefinition {
  return {
    name: moduleName,
    state: {
      url: url,
      component: component,
      data: {
        requiredRoles: roles,
        pageTitle: 'global.menu.documentosdigitais'
      },
      params: {
        clientConnectMode: {
          type: 'bool',
          value: clientConnectMode,
          dynamic: true
        },
        nif: {
          type: 'string',
          value: ''
        },
        nDocExterno: {
          type: 'string',
          value: ''
        }
      },
      resolve: [
        RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL,
        RESOLVER_CG_STORE_URL_BACKOFFICE,
        {provide: 'clientConnectMode', useFactory: () => clientConnectMode},
        {provide: 'mode', useFactory: () => mode},
        {
          provide: 'temDocumentosDigitais',
          deps: ['empresaTemContabilidadeDigital', ComercialDigitalService],
          useFactory: (empresaTemContabilidadeDigital: boolean, comercialDigitalService: ComercialDigitalService): Promise<boolean> => {
            if (empresaTemContabilidadeDigital || clientConnectMode) {
              return Promise.resolve(true);
            }
            return comercialDigitalService.temDocumentosDigitais().then((response: HttpResponse<IJsonBoolean>) => response.body.value);
          }
        }
      ]
    }
  };
}
