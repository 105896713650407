<div class="rhrecolhadados-list">
  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [dataGridInstance]="dataGridInstance"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [filterCollapsed]="false"
    [toolbarInstanceId]="instanceName"
    [onFiltered]="fnOnFiltered"
    [properties]="listOptions"
    (filterChange)="filter = $event"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
    <div *entityListDataGridTemplate>
      <dx-data-grid
        [dataSource]="dataSource"
        [showColumnLines]="true"
        (onInitialized)="onDataGridInitialized($event)"
        (onRowClick)="onDataGridRowClick($event)"
        (onCellClick)="onDataGridCellClick($event)"
        (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)">
        <dxi-column
          dataField="codEMP"
          dataType="string"
          [caption]="'rhrecolhadados.fields.nomeEmp' | translate"
          [sortIndex]="0"
          sortOrder="asc"
          [groupIndex]="0"
          [calculateGroupValue]="calculateGroupValueEmpregado"
          [autoExpandGroup]="false">
        </dxi-column>
        <dxi-column dataField="abonoDesc" dataType="string" [caption]="'rhrecolhadados.fields.abonoDesc' | translate" [calculateCellValue]="fnCalculateCellValueAbonoDesc"></dxi-column>
        <dxi-column dataField="codABDESC" dataType="number" [caption]="'rhrecolhadados.fields.codABDESC' | translate" [calculateCellValue]="calculateCellValueCodABDESC"></dxi-column>
        <dxi-column dataField="quantidade" dataType="number" [caption]="'rhrecolhadados.fields.quantidade' | translate"></dxi-column>
        <dxi-column dataField="valor" dataType="number" [caption]="'rhrecolhadados.fields.valor' | translate"></dxi-column>
        <dxi-column dataField="tipoProcessamento" dataType="number" [caption]="'rhrecolhadados.fields.tipoProcessamento' | translate" [calculateCellValue]="calculateCellValueTipoProcessamento">
        </dxi-column>
        <dxi-column dataField="data" dataType="date" [caption]="(isRemun ? 'rhrecolhadados.fields.dataDados' : 'rhrecolhadados.fields.dataDadosFixos') | translate"></dxi-column>
        <dxi-column cellTemplate="cellTemplateVisualize" [allowSorting]="false" [width]="40"></dxi-column>

        <div *dxTemplate="let cell of 'cellTemplateVisualize'">
          <pl-tooltip [config]="{text: 'global.btn.visualize', placement: 'left'}">
            <button type="button" *plTooltipContent class="btn btn-primary btn-xs" (click)="redirectToDetail(cell.data)">
              <i class="fa fa-fw fa-info-circle"></i>
            </button>
          </pl-tooltip>
        </div>

        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-paging [enabled]="false"></dxo-paging>
      </dx-data-grid>
    </div>
  </entity-list>
</div>
