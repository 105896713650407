import {TDate} from '../../../common/dates';
import {IJsonDocfa} from '../../entities/docfas/jsonDocFa.entity.interface';
import {IJsonArmazem} from '../../entities/armazens/jsonArmazem.entity.interface';
import {IRadioGroup} from '../../../common/interfaces/interfaces';
import {deepFreeze} from 'pl-comps-angular';
import {JSONSchema} from '@ngx-pwa/local-storage';
import {EDataGridLegendCellColors} from '../../components/devexpress/datagrid/configuration/devexpress.datagrid.configuration';
import {TTableLegend} from '../../components/tablelegend/tablelegend.component.interface';

export enum EStepsNavGestaoEncomendas {
  Identificacao = 'identificacao',
  Carga = 'carga',
  Descarga = 'descarga',
  Lotes = 'lotes',
  Preview = 'preview'
}

export enum EEstadoEncomendas {
  SoPendentes,
  SoSatisfeitas,
  Todas
}

export interface IHeaderGestaoEncomendas {
  dataDocDe: TDate;
  dataDocAte: TDate;
  dataPrevEntregaDe: TDate;
  dataPrevEntregaAte: TDate;
  nConta?: string;
  nomeConta?: string;
  nArtigo?: string;
  nContaDe?: string;
  nContaAte?: string;
  estadoEncomendas?: EEstadoEncomendas;
  docfaList?: Array<IJsonDocfa>;
  armazens?: Array<IJsonArmazem>;
}

export interface IParamsPrintEncomendas {
  ncontade: string;
  ncontaate: string;
  datadocde: TDate;
  datadocate: TDate;
  dataentregaprevde: TDate;
  dataentregaprevate: TDate;
  estadoencomendas: number;
  armazens: string;
  docfalist: string;
  reportname: string;
}

export interface IClifosSourceItem {
  nConta: string;
  nome: string;
}

export const RADIO_GROUP_ESTADO_ENCOMENDAS_TEMPLATE: IRadioGroup<EEstadoEncomendas> = deepFreeze<IRadioGroup<EEstadoEncomendas>>({
  groupItems: [
    {value: EEstadoEncomendas.SoPendentes, label: 'encomendas.modal.impressao.estado.soPendentes'},
    {value: EEstadoEncomendas.SoSatisfeitas, label: 'encomendas.modal.impressao.estado.soSatisfeitas'},
    {value: EEstadoEncomendas.Todas, label: 'global.text.all'}
  ]
});

export enum EEncomendasEstadosTableLegendColors {
  EncomendaParcialProcessada = 'bg-part-satif',
  EncomendaTotalProcessada = 'bg-total-satif',
  EncomendaSatisfMaisProcessada = 'bg-satisf-mais',
  EncomendaTextoLivre = 'bg-linha-text-livre',
  EncomendaTableNotEditableCell = 'table-not-editable-cell',
  EncomendaTableEditableCell = 'datagrid-editable-cell'
}

export interface IEncomendasDefaultData {
  nDocfa: number;
  nNumer: number;
  nomeDocfa: string;
}

export const schemaIJsonEncomendasCab: JSONSchema = {
  type: 'object',
  properties: {
    nDocfa: {
      type: 'number'
    },
    nomeDocfa: {
      type: 'string'
    },
    nNumer: {
      type: 'number'
    }
  }
};

export const TABLE_LEGEND_ENCOMENDAS: TTableLegend = deepFreeze([
  {
    caption: 'encomendas.badges.partSatif',
    badgeCSSClass: EDataGridLegendCellColors.RedLegendColor
  },
  {
    caption: 'encomendas.badges.totalSatif',
    badgeCSSClass: EDataGridLegendCellColors.GreenLegendColor
  },
  {
    caption: 'encomendas.badges.linhaTextLivre',
    badgeCSSClass: EDataGridLegendCellColors.BlueLegendColor
  },
  {
    caption: 'encomendas.badges.satisfMais',
    badgeCSSClass: EDataGridLegendCellColors.IndigoLegendColor
  }
]);

export const MODULE_NAME_ENCOMENDAS = 'encomendas';
