import {Component, Injector, OnInit} from '@angular/core';
import {IPlNavPillEventSelected, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DATA_SOURCE_TIPOS_CATEGORIA_DESCONTOS} from '../../../../datasources/tipocategoriadesc/tipoCategoriaDesc.datasource';
import {ENTITY_NAME_PERFIL_CATEGORIA_DESCONTO} from '../../perfilCategoriaAbDes.module.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ETipoCategoriaDesc} from '../../../../datasources/tipocategoriadesc/tipoCategoriaDesc.datasource.interface';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonPerfilCategoriaDesconto} from '../../jsonPerfilCategoriaAbDes.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonCRABDPerfil} from '../../../../entities/crabd/jsonCrAbD.entity.interface';

@Component({
  selector: 'perfil-categoria-desconto-edit',
  templateUrl: './perfilCategoriaDesconto.edit.component.html'
})
export class PerfilCategoriaDescontoEditComponent extends ModuloComponent implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonCRABDPerfil, IJsonCRABDPerfil>;
  public readonly categorias: ReadonlyArray<IDataSourceItem<ETipoCategoriaDesc>>;
  public model: IJsonPerfilCategoriaDesconto;
  public promise: Promise<unknown>;

  private readonly _entityService: IEntityService<IJsonPerfilCategoriaDesconto>;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _entityServiceBuilder: EntityServiceBuilder,
    protected readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.categorias = DATA_SOURCE_TIPOS_CATEGORIA_DESCONTOS.data;
    this.model = {
      nCategoriaAbDes: 0,
      tipoArredondamento: 0,
      tipoRecolha: 0,
      unidadeDefeito: 0,
      nomeUnidadeDefeito: '',
      tipoRendimento: '',
      codRem: '',
      nomeCodRem: '',
      nContaDebitoEmp: '',
      nContaCreditoEmp: '',
      nContaDebitoPatronato: '',
      nContaCreditoPatronato: '',
      cruzamentos: [],
      nHorasDiaProvDesc: 0,
      grupo: 0,
      nomeGrupo: '',
      nomeTipoRendimento: ''
    };
    this._entityService = this._entityServiceBuilder.build<IJsonPerfilCategoriaDesconto>(ENTITY_NAME_PERFIL_CATEGORIA_DESCONTO);

    this.dataGridDefinition = {
      columns: [
        {
          caption: 'perfilCategoriaDesconto.crabdTable.fields.categoria',
          dataType: 'string',
          columns: [{dataField: 'nomeCategoria', caption: 'perfilCategoriaDesconto.crabdTable.fields.nomeCategoria', dataType: 'string'}]
        },
        {
          caption: 'perfilCategoriaDesconto.crabdTable.fields.empregado',
          dataType: 'string',
          columns: [
            {dataField: 'entSuporteEmpregado', caption: 'perfilCategoriaDesconto.crabdTable.fields.temIncidenciaEmpregado', dataType: 'boolean'},
            {dataField: 'entSuporteEmpregadoIncidencia', caption: 'perfilCategoriaDesconto.crabdTable.fields.incidenciaEmpregado', dataType: 'string'}
          ]
        },
        {
          caption: 'perfilCategoriaDesconto.crabdTable.fields.patronato',
          dataType: 'string',
          columns: [
            {dataField: 'entSuportePatronato', caption: 'perfilCategoriaDesconto.crabdTable.fields.temIncidenciaPatronato', dataType: 'boolean'},
            {dataField: 'entSuportePatronatoIncidencia', caption: 'perfilCategoriaDesconto.crabdTable.fields.incidenciaPatronato', dataType: 'string'}
          ]
        },
        {
          caption: 'perfilCategoriaDesconto.crabdTable.fields.desconto',
          dataType: 'string',
          columns: [
            {dataField: 'nCodDesconto', caption: 'perfilCategoriaDesconto.crabdTable.fields.nDesconto', dataType: 'string'},
            {dataField: 'descricao', caption: 'perfilCategoriaDesconto.crabdTable.fields.nomeDesconto', dataType: 'string'}
          ]
        }
      ],
      columnAutoWidth: true,
      export: {filename: this._state.data.pageTitle},
      hoverStateEnabled: true,
      remoteOperations: false,
      showBorders: true
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnSave.visible = true;
    this.btnSave.click = () => this._save();
    setTimeout(() => {
      this._entityService.get({id: 0}).then((result) => {
        this.model = result.body;
      });
    });
  }

  public categoriaSelected({nextId}: IPlNavPillEventSelected): void {
    this.promise = this._entityService.get({id: nextId}).then((result) => {
      this.model = result.body;
    });
  }

  private _save(): Promise<void> {
    return this._entityService
      .put({id: this.model.nCategoriaAbDes, body: this.model})
      .then(() => {
        this._plAlertService.success('O perfil foi guardado com sucesso');
      })
      .catch(() => {
        this._plAlertService.error('Houve um erro a guardar o perfil');
      });
  }
}
