<div class="rhprocessamentosalariosconfig entity-detail-form" [plPromise]="promise">
  <pl-tabs>
    <pl-tab id="procesalarios" caption="rhprocessamentosalariosconfig.tab.procesalarios">
      <div *plTabContent>
        <pl-group>
          <edit>
            <pl-edit
              type="checkbox"
              attrName="usaFiltroFuncaoNosTiposProcessamento"
              [(model)]="processamentosalariosconfig.processamentoSalariosFields.usaFiltroFuncaoNosTiposProcessamento"
              [properties]="{label: 'rhprocessamentosalariosconfig.procesalarios.usaFiltroFuncaoNosTiposProcessamento'}">
            </pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <edit>
            <pl-edit
              type="checkbox"
              attrName="calcNDiasParaAdmissaoEmpEmDiasBase30"
              [(model)]="processamentosalariosconfig.processamentoSalariosFields.calcNDiasParaAdmissaoEmpEmDiasBase30"
              [properties]="{label: 'rhprocessamentosalariosconfig.procesalarios.calcNDiasParaAdmissaoEmpEmDiasBase30'}">
            </pl-edit>
          </edit>
        </pl-group>
      </div>
    </pl-tab>

    <pl-tab id="duodecimos" caption="rhprocessamentosalariosconfig.tab.duodecimos">
      <div *plTabContent>
        <pl-form>
          <div class="card">
            <h6 class="card-header m-0" [translate]="'rhprocessamentosalariosconfig.card.percduodecimos'"></h6>
            <div class="card-body">
              <pl-group>
                <edit>
                  <pl-edit
                    type="checkbox"
                    class="me-3"
                    attrName="acertoDuodecimosFaltouMesInteiro"
                    [model]="processamentosalariosconfig.processamentoSalariosDuodecimos.acertoDuodecimosFaltouMesInteiro"
                    (modelChange)="processamentosalariosconfig.processamentoSalariosDuodecimos.acertoDuodecimosFaltouMesInteiro = $event"
                    [properties]="{label: 'rhprocessamentosalariosconfig.duodecimos.acertoDuodecimosFaltouMesInteiro'}">
                  </pl-edit>

                  <pl-edit
                    type="checkbox"
                    attrName="efetuaAcertoDuodecimos"
                    [model]="processamentosalariosconfig.processamentoSalariosDuodecimos.efetuaAcertoDuodecimos"
                    (modelChange)="processamentosalariosconfig.processamentoSalariosDuodecimos.efetuaAcertoDuodecimos = $event"
                    [properties]="{label: 'rhprocessamentosalariosconfig.duodecimos.efetuaAcertoDuodecimos'}">
                  </pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'rhprocessamentosalariosconfig.duodecimos.percentagemEmSubFerias'"></label>
                  <edit>
                    <pl-edit
                      type="number"
                      attrName="percentagemEmSubFerias"
                      [(model)]="processamentosalariosconfig.processamentoSalariosDuodecimos.percentagemEmSubFerias"
                      [properties]="{validators: {percentagemEmSubFerias: percentagemEmSubFerias}}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'rhprocessamentosalariosconfig.duodecimos.percentagemEmSubNatal'"></label>
                  <edit>
                    <pl-edit
                      type="number"
                      attrName="percentagemEmSubNatal"
                      [(model)]="processamentosalariosconfig.processamentoSalariosDuodecimos.percentagemEmSubNatal"
                      [properties]="{validators: {percentagemEmSubNatal: percentagemEmSubNatal}}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>
            </div>
          </div>
        </pl-form>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
