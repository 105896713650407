import {deepFreeze} from '../../utilities/utilities';
import type {IPlLocale} from '../locales.interface';

/* eslint-disable @typescript-eslint/naming-convention */

const locale: IPlLocale = deepFreeze<IPlLocale>({
  autocomplete: {
    textSearching: 'a pesquisar...',
    textEmpty: 'sem resultados',
    textNotFound: 'não encontrado'
  },
  btn: {
    goBack: 'Retroceder',
    goForward: 'Avançar',
    finalize: 'Finalizar',
    submit: 'Submeter',
    search: 'Pesquisar',
    ok: 'OK',
    cancel: 'Cancelar',
    close: 'Fechar',
    apply: 'Aplicar',
    addFilter: 'Adicionar filtro'
  },
  calendar: {
    views: {
      month: {
        items: 'Items'
      },
      year: {
        months: 'Meses'
      }
    },
    detailsBar: {
      simultaneousEvents: 'Simultaneous Events'
    },
    sidebar: {
      filter: 'Filtro'
    }
  },
  datepicker: {
    nextCentury: 'Século seguinte',
    nextDecade: 'Década seguinte',
    nextMillennium: 'Milénio seguinte',
    nextMonth: 'Mês seguinte',
    nextYear: 'Ano seguinte',
    previousCentury: 'Século anterior',
    previousDecade: 'Década anterior',
    previousMillennium: 'Milénio anterior',
    previousMonth: 'Mês anterior',
    previousYear: 'Ano anterior',
    toggleContext: 'Alternar vista',
    validators: {
      minimumDate: 'Este campo não pode ter uma data anterior a {{value}}',
      maximumDate: 'Este campo não pode ter uma data superior a {{value}}'
    }
  },
  mimeTypes: {
    'application/epub+zip': 'EPUB',
    'application/gzip': 'GZ',
    'application/java-archive': 'JAVA',
    'application/json': 'JSON',
    'application/ld+json': 'JSON (formato LD)',
    'application/msword': 'DOC (Word)',
    'application/octet-stream': 'BIN',
    'application/ogg': 'OGG',
    'application/pdf': 'PDF',
    'application/php': 'PHP',
    'application/rtf': 'RTF',
    'application/vnd.amazon.ebook': 'AZW',
    'application/vnd.apple.installer+xml': 'MPKG',
    'application/vnd.mozilla.xul+xml': 'XUL',
    'application/vnd.ms-excel': 'XLS (Excel)',
    'application/vnd.ms-fontobject': 'EOT',
    'application/vnd.ms-powerpoint': 'PPT (Powerpoint)',
    'application/vnd.oasis.opendocument.presentation': 'ODP',
    'application/vnd.oasis.opendocument.spreadsheet': 'ODS',
    'application/vnd.oasis.opendocument.text': 'ODT',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPT (Powerpoint)',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLS (Excel)',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOC (Word)',
    'application/vnd.visio': 'VSD',
    'application/x-7z-compressed': '7z',
    'application/x-abiword': 'ABW',
    'application/x-bzip': 'BZ',
    'application/x-bzip2': 'BZ2',
    'application/x-csh': 'CSH',
    'application/x-freearc': 'ARC',
    'application/x-rar-compressed': 'RAR',
    'application/x-sh': 'SH',
    'application/x-shockwave-flash': 'SWF',
    'application/x-tar': 'TAR',
    'application/xhtml+xml': 'XHTML',
    'application/zip': 'ZIP',
    'audio/aac': 'AAC',
    'audio/midi': 'MID(I)',
    'audio/mpeg': 'MP3',
    'audio/ogg': 'OGG',
    'audio/wav': 'WAV',
    'audio/webm': 'WEBM',
    'font/otf': 'OTF',
    'font/ttf': 'TTF',
    'font/woff': 'WOFF',
    'font/woff2': 'WOFF2',
    'image/*': 'imagens',
    'image/bmp': 'BMP',
    'image/gif': 'GIF',
    'image/jpeg': 'JPG/JPEG',
    'image/png': 'PNG',
    'image/svg+xml': 'SVG',
    'image/tiff': 'TIFF',
    'image/vnd.microsoft.icon': 'ICON',
    'image/webp': 'WEBP',
    'image/x-icon': 'ICON',
    'text/calendar': 'ICS',
    'text/css': 'CSS',
    'text/csv': 'CSV',
    'text/html': 'HTML',
    'text/javascript': 'JS',
    'text/plain': 'TXT',
    'text/xml': 'XML',
    'video/3gpp': '3GP',
    'video/3gpp2': '3G2',
    'video/mp2t': 'TS',
    'video/mpeg': 'MPEG',
    'video/ogg': 'OGV',
    'video/webm': 'WEBM',
    'video/x-msvideo': 'AVI'
  },
  months: {
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'Março',
    april: 'Abril',
    may: 'Maio',
    june: 'Junho',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    november: 'Novembro',
    december: 'Dezembro'
  },
  placeholders: {
    search: 'Pesquisar no menu...',
    tableGroupBy: 'Arraste uma coluna para esta zona para agrupar por essa coluna'
  },
  plColorPicker: {
    presetLabel: 'Cores predefinidas',
    okButtonText: 'OK',
    cancelButtonText: 'Cancelar',
    addColorButtonText: 'Adicionar cor',
    presetEmptyMessage: 'Não foi adicionada nenhuma cor'
  },
  plCookiesConsent: {
    header: 'Este website utiliza cookies',
    footer: 'A última atualização da declaração de cookies foi em {{value}}',
    necessaryTitle: 'Obrigatórios',
    necessaryDescription:
      'Os cookies obrigatórios são essenciais para utilizar este site, permitindo funções básicas como navegação na página e acesso a áreas restritas. O site não consegue funcionar corretamente sem estes cookies.',
    preferencesTitle: 'Preferências',
    preferencesDescription: 'Os cookies de preferência permitem que o site se lembre de informações que alteram a aparência ou o comportamento do mesmo.',
    statisticsTitle: 'Estatísticas',
    statisticsDescription: 'Os cookies estatísticos ajudam a perceber como os utilizadores interagem com o site, recolhendo e relatando informações anonimamente.',
    marketingTitle: 'Marketing',
    marketingDescription: 'Cookies de marketing são usados para melhorar os anúncios mostrados ao utilizador, por exemplo, mostrando apenas os anúncios mais relevantes e atraentes para o mesmo.',
    unclassifiedTitle: 'Não classificados',
    unclassifiedDescription: 'Cookies não classificados são aqueles cuja função ainda não foi definida ou estão pendentes de classificação.',
    showDetails: 'Mostrar detalhes',
    hideDetails: 'Esconder detalhes',
    ok: 'OK',
    declaration: 'Declaração de cookies',
    about: 'Sobre os cookies',
    tableHeaderName: 'Nome',
    tableHeaderProvider: 'Entidade',
    tableHeaderPurpose: 'Intenção',
    tableHeaderExpiresOn: 'Expiração',
    tableHeaderType: 'Tipo',
    session: 'Sessão',
    persistent: 'Persistente',
    emptyGroup: 'Não utilizamos cookies deste tipo.',
    invalidExpireTime: 'Valor inválido'
  },
  plDashboard: {
    emptyData: 'Não existem dados para mostrar',
    total: 'Total'
  },
  plMediaDevicesCameraCaptureImage: {
    imageContentAlt: 'Pré-visualizar imagem',
    titleChange: 'Alterar imagem',
    titleTabUpload: 'Carregar imagem',
    titleTabCapture: 'Capturar imagem',
    textChange: 'Alterar',
    textRemove: 'Remover',
    textDisabledTabCapture: 'O seu dispositivo não suporta a captura de imagens.',
    textRetry: 'Tentar novamente',
    textCapture: 'Capturar',
    textPause: 'Pausar',
    textResume: 'Resumir',
    errorAbortError: 'Ocorreu um problema desconhecido que impediu o uso da câmera do seu dispositivo.',
    errorNotAllowedError: 'De momento a câmera do seu dispositivo não pode ser usada. Por favor certifique-se que permitiu o acesso à mesma.',
    errorNotFoundError: 'Nenhuma câmera foi encontrada no seu dispositivo.',
    errorNotReadableError: 'Ocorreu um erro de hardware que impediu o acesso à câmera do seu dispositivo.',
    errorOverconstrainedError: 'Não foi encontrado nenhuma câmera no seu dispositivo que atendesse aos critérios necessários.',
    errorSecurityError: 'O suporte ao uso da câmera do seu dispositivo está desativado.',
    errorTypeError: 'Ocorreu um problema desconhecido que impediu o uso da câmera do seu dispositivo.',
    errorTimedOutError: 'O pedido das permissões necessárias para o uso da câmera do seu dispositivo expirou.',
    errorUnavailableMediaDevices: 'O seu dispositivo não suporta captura de imagens.'
  },
  plMultiSelect: {
    available: 'Disponíveis',
    selected: 'Selecionados'
  },
  plNavbar: {
    toggleMenu: 'Alternar menu'
  },
  plPdf: {
    loadError: 'Erro a ler o documento',
    noDocument: 'Sem documento',
    renderNotSupported: 'O seu documento foi processado. Por favor clique em "Transferir" para obter o documento.',
    pages: 'Páginas',
    zoomAuto: 'Zoom automático',
    zoomPageActual: 'Tamanho real',
    zoomPageFit: 'Ajustar à pagina',
    zoomPageWidth: 'Ajustar à largura',
    zoomIn: 'Zoom in',
    zoomOut: 'Zoom out',
    presentationMode: 'Trocar para o modo de apresentação',
    download: 'Transferir',
    print: 'Imprimir',
    loading: 'A carregar o documento...'
  },
  plSelect: {
    clearAllText: 'Limpar tudo',
    loadingText: 'A carregar...',
    notFoundText: 'Nenhum item encontrado'
  },
  plSideBar: {
    toggleSideBar: 'Alternar barra lateral'
  },
  text: {
    today: 'Hoje',
    day: 'Dia',
    days: 'Dias',
    week: 'Semana',
    weeks: 'Semanas',
    month: 'Mês',
    months: 'Meses',
    year: 'Ano',
    years: 'Anos',
    weekend: 'Fim de semana',
    weekends: 'Fins de semana',
    lines: 'Linhas',
    close: 'Fechar',
    now: 'Agora',
    clear: 'Limpar',
    open: 'Abrir',
    initialize: 'A inicializar',
    noFilters: 'Sem filtros',
    activeFilters: 'Filtros ativos',
    clearFilters: 'Limpar filtros ativos',
    true: 'Verdadeiro',
    false: 'Falso',
    and: 'e',
    or: 'ou',
    of: 'de'
  },
  uploader: {
    defaultMessage: 'Largue ficheiros aqui para enviar',
    fileTooBig: 'O ficheiro é muito grande, o tamanho máximo permitido é: <strong>{{maxFilesize}} MB</strong>.',
    invalidFileType: 'Não pode enviar ficheiros desse tipo.',
    cancelUpload: 'Cancelar carregamento',
    uploadCanceled: 'Carregamento cancelado.',
    removeAllFiles: 'Remover todos',
    removeFile: 'Remover',
    maxFileExceeded: 'Não pode enviar mais ficheiros. Apenas é permitido um ficheiro.',
    maxFilesExceeded: 'Não pode enviar mais ficheiros. São permitidos apenas {{maxFiles}} ficheiros.',
    acceptedFileType: 'Apenas é suportado o tipo de ficheiro {{acceptedFiles}}.',
    acceptedFileTypes: 'Apenas são suportados os tipos de ficheiro {{acceptedFiles}}.',
    maximumFileSize: 'Tamanho máximo do ficheiro é {{maximumFileSize}}.',
    maximumPerFileSize: 'Tamanho máximo por ficheiro é {{maximumFileSize}}.',
    dropHere: 'largue ficheiros',
    clickToUpload: 'clique aqui para enviar',
    files: 'Ficheiros',
    uploadAll: 'Enviar todos',
    upload: 'Enviar',
    rejected: 'Ficheiro rejeitado',
    retry: 'Tentar enviar novamente',
    uploadSuccess: 'Ficheiro carregado com sucesso',
    uploadError: 'Ocorreu um erro ao carregar o ficheiro',
    units: {b: 'B', kb: 'KB', mb: 'MB', gb: 'GB', tb: 'TB', pb: 'PB', eb: 'EB', zb: 'ZB', yb: 'YB'}
  },
  validators: {
    date: 'Data inválida',
    datetimelocal: 'Data inválida',
    email: 'Email inválido',
    equals: 'O valor deste campo deve ser igual ao valor do campo {{value}}',
    max: 'Este campo não pode ter um valor superior a {{value}}',
    maxlength: 'Este campo tem um tamanho máximo de {{value}} caracteres',
    maxSelected: 'Este campo não pode ter mais de {{value}} elemento(s) selecionados',
    min: 'Este campo não pode ter um valor inferior a {{value}}',
    minlength: 'Este campo tem um tamanho mínimo de {{value}} caracteres',
    minSelected: 'Este campo tem de ter pelo menos {{value}} elemento(s) selecionados',
    month: 'Mês inválido',
    number: 'Número inválido',
    pattern: 'Campo inválido',
    required: 'Este campo é obrigatório',
    time: 'Campo inválido',
    url: 'Campo inválido',
    week: 'Campo inválido'
  },
  weekdays: {
    sunday: 'Domingo',
    monday: 'Segunda-feira',
    tuesday: 'Terça-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    saturday: 'Sábado'
  }
});

/* eslint-enable @typescript-eslint/naming-convention */

export default locale;
