import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {MODULE_NAME_DEM_FIN_SNC} from './demFinSNC.module.interface';
import {DemFinSNCModuleComponent} from './components/demFinSNC.module.component';
import {HttpResponse} from '@angular/common/http';
import {DemFinSNCService} from './demFinSNC.module.service';
import {IJsonDemFinSNCInitialData} from './jsonDemFinSNC.module.interface';

export const MODULE_DEM_FIN_SNC: IModuleDefinition = {
  name: MODULE_NAME_DEM_FIN_SNC,
  state: {
    url: `/${MODULE_NAME_DEM_FIN_SNC}`,
    component: DemFinSNCModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.demfinsnc'
    },
    resolve: [
      {
        provide: 'defaultData',
        deps: [DemFinSNCService],
        useFactory: (demFinSNCService: DemFinSNCService): Promise<IJsonDemFinSNCInitialData> => {
          return demFinSNCService.loadDefaultDemFinSNC().then((response: HttpResponse<IJsonDemFinSNCInitialData>) => response.body);
        }
      }
    ]
  }
};
