import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {MODULE_NAME_DASHBOARD_CONTABILIDADE} from './dashboardContabilidade.module.interface';
import {DashboardContabilidadeModuleComponent} from './components/dashboardContabilidade.module.component';

export const MODULE_DASHBOARD_CONTABILIDADE: IModuleDefinition = {
  name: MODULE_NAME_DASHBOARD_CONTABILIDADE,
  state: {
    url: '/dashboard',
    component: DashboardContabilidadeModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.dashboard',
      icon: 'fa-bar-chart'
    }
  }
};
