import {ColaboradoresMarcacaoRefeicoesModuleComponent} from './components/marcacaoRefeicoes.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ROLE} from '../../../../services/role.const';

export const MODULE_MARCACAO_REFEICOES: IModuleDefinition = {
  name: 'colaboradoresMarcacaoRefeicoes',
  state: {
    url: '/refeicoes/marcar',
    component: ColaboradoresMarcacaoRefeicoesModuleComponent,
    data: {
      roles: [ROLE.COLABORADOR, ROLE.GESTOREMENTAS, ROLE.GESTORRH, ROLE.GESTORSERVICO],
      pageTitle: 'global.menu.marcarRefeicoes',
      icon: 'fa-calendar-check-o'
    }
  }
};
