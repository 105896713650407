import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_MODELO_39} from './modelo39.module.interface';
import {Modelo39ModuleComponent} from './components/modelo39.module.component';
import {Modelo39Service} from './modelo39.module.service';
import {HttpResponse} from '@angular/common/http';

export const MODULE_MODELO_39: IModuleDefinition = {
  name: MODULE_NAME_MODELO_39,
  state: {
    url: '/modelo39',
    component: Modelo39ModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.RH],
      pageTitle: 'global.menu.modelo39'
    },
    resolve: [
      {
        provide: 'anosAnteriores',
        deps: [Modelo39Service],
        useFactory: (modelo39Service: Modelo39Service): Promise<Array<number>> => {
          return modelo39Service.getAnosAnteriores().then((response: HttpResponse<Array<number>>) => response.body);
        }
      }
    ]
  }
};
