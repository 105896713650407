import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlNavWizardDefinition, IPlNavWizardEventBeforeChange, isBoolean, isString, PlAlertService} from 'pl-comps-angular';
import {ENTITY_NAME_POCS} from '../../../entities/pocs/pocs.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {ETrfLancContasSteps, IJsonDependencias} from '../jsonTrfLancContas.module.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IJsonPOC} from '../../../entities/pocs/jsonPOC.entity.interface';
import {ITrfLancContas} from '../trfLancContas.module.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {TrfLancContasService} from '../trfLancContas.module.service';

@Component({
  selector: 'trflanccontas.module',
  templateUrl: './trfLancContas.module.component.html'
})
export class TrfLancContasModuleComponent extends ModuloComponent implements OnInit {
  @Input() public manterContaOrigem: boolean;
  @Input() public contaOrigem: IJsonPOC;

  public readonly steps: typeof ETrfLancContasSteps;
  public readonly definitionNavWizard: IPlNavWizardDefinition;
  public readonly definition: IDevExpressDataGrid;
  public model: ITrfLancContas;
  public contaDestinoJaExiste: boolean;

  private readonly _servicePocs: IEntityService<IJsonPOC>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _trfLancContasService: TrfLancContasService
  ) {
    super(_injector);
    this.steps = ETrfLancContasSteps;
    this.definitionNavWizard = {
      items: [],
      force: false
    };
    this.definition = {
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'trfLancContas.fieldsTableDependencias.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'trfLancContas.fieldsTableDependencias.nome'},
        {dataField: 'nContaGeralReal', dataType: 'string', caption: 'trfLancContas.fieldsTableDependencias.nContaGeralReal'},
        {dataField: 'valorMascara', dataType: 'string', caption: 'trfLancContas.fieldsTableDependencias.valorMascara'},
        {dataField: 'radicalMascara', dataType: 'string', caption: 'trfLancContas.fieldsTableDependencias.radicalMascara'}
      ],
      paging: {
        enabled: true
      },
      pager: {
        visible: true
      }
    };
    this.model = {
      nContaOrigem: '',
      nomeContaOrigem: '',
      nContaDestino: '',
      nomeContaDestino: '',
      manterContaOrigem: false,
      dependencias: []
    };
    this.contaDestinoJaExiste = false;
    this._servicePocs = this._entityServiceBuilder.build<IJsonPOC>(ENTITY_NAME_POCS);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (isBoolean(this.manterContaOrigem)) {
      this.model.manterContaOrigem = this.manterContaOrigem;
    }
    if (this.contaOrigem) {
      this.changedContaOrigem(this.contaOrigem);
    }
  }

  public changedContaOrigem(contaOrigem: string | IJsonPOC): void {
    if (isString(contaOrigem)) {
      this.model.nContaOrigem = contaOrigem;
      this.model.nomeContaOrigem = undefined;
    } else {
      this.model.nContaOrigem = contaOrigem?.nConta;
      this.model.nomeContaOrigem = contaOrigem?.nome;
    }
    this._getDependencias();
  }

  public changedContaDestino(nContaDestino: string): void {
    this.model.nContaDestino = nContaDestino;
    this.contaDestinoJaExiste = false;
    this._servicePocs.get({id: this.model.nContaDestino, reportExceptions: false}).then((response: HttpResponse<IJsonPOC>) => {
      this.contaDestinoJaExiste = true;
      this.model.nomeContaDestino = response.body.nome;
    });
  }

  public readonly fnTransfereContas = (event: IPlNavWizardEventBeforeChange): Promise<void> => this._transfereContas(event);

  private _getDependencias(): void {
    if (!this.model.nContaOrigem) {
      this.model.dependencias = [];
      return;
    }
    this._trfLancContasService
      .getDependencias(this.model.nContaOrigem)
      .then((response: HttpResponse<Array<IJsonDependencias>>) => {
        this.model.dependencias = response.body;
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
      });
  }

  private _transfereContas({currentStep, type}: IPlNavWizardEventBeforeChange): Promise<void> {
    if (currentStep.stepId === ETrfLancContasSteps.INTRO) {
      currentStep.disabled = true;
    }
    if (currentStep.stepId === ETrfLancContasSteps.CONTAS && type === 'next') {
      return this._trfLancContasService.transferenciaContas(this.model).then(() => {
        this._plAlertService.success('trfLancContas.success');
      });
    } else if (currentStep.stepId === ETrfLancContasSteps.FINALIZAR && (type === 'previous' || type === 'set')) {
      this.model = {
        nContaDestino: '',
        nomeContaDestino: '',
        nContaOrigem: '',
        nomeContaOrigem: '',
        manterContaOrigem: false,
        dependencias: []
      };
    }
    return Promise.resolve();
  }
}
