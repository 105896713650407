import {ENTITY_NAME_PRH_SERVICOS, IPRHServicosEntity} from './pRHServicos.entity.interface';
import {Moment} from 'moment';
import {ROLE} from '../../services/role.const';
import {EPRHFluxoIndiceTipo} from '../prhfluxos/jsonPRHFluxo.entity.interface';
import {IJsonPRHServicoDatasMarcacoesPendentes, IJsonPRHServicoMarcacoes} from './jsonPRHServico.entity.interface';
import {IJsonDGEMP} from '../dgemps/jsonDGEMP.entity.interface';

export const ENTITY_PRH_SERVICOS: IPRHServicosEntity = {
  name: ENTITY_NAME_PRH_SERVICOS,
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  searchPlaceholder: 'prhservicos.pesquisa',
  metadata: {
    keyName: 'codServico',
    descriptionName: 'nome',
    fields: [
      {
        name: 'codServico',
        width: '120px',
        caption: 'prhservicos.fields.codServico',
        placeholder: 'prhservicos.fields.codServicoPlaceholder',
        validators: {required: true, min: 1}
      },
      {name: 'nome', caption: 'prhservicos.fields.nome', placeholder: 'prhservicos.fields.nomePlaceholder', validators: {required: true, maxlength: 50}}
    ],
    order: 'nome',
    searchFields: 'codServico,nome'
  },
  autocomplete: {
    rowTemplate: '{{codServico}} - {{nome}}',
    output: 'nome',
    searchFields: 'codServico,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.COLABORADOR],
        pageTitle: 'global.menu.prhservicos'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.COLABORADOR],
        pageTitle: 'global.menu.prhservicos'
      }
    },
    template: {
      items: [{type: 'group', fields: [{field: 'codServico'}, {field: 'nome'}]}]
    }
  },
  new: {
    state: {
      data: {
        roles: [ROLE.COLABORADOR],
        pageTitle: 'global.menu.prhservicos'
      }
    },
    template: {
      items: [
        {type: 'field', field: 'codServico', order: 1},
        {type: 'field', field: 'nome', order: 2}
      ]
    }
  },
  service: function () {
    this.getListaEmpregados = (codServico: number) => {
      return this.get<Array<IJsonDGEMP>>({
        id: `${codServico}/listaempregados`
      });
    };

    this.getListaEmpregadosServicos = (listaCodServico: Array<number>) => {
      return this.post<Array<IJsonDGEMP>, Array<number>>({
        url: 'listaempregadosservicos',
        body: listaCodServico
      });
    };

    this.getMarcacoesFerias = (codServico: number, dataDe: Moment, dataAte: Moment, dataContext?: Moment) => {
      return this.get<IJsonPRHServicoMarcacoes>({
        id: `${codServico}/marcacoesferias`,
        params: {
          datade: dataDe,
          dataate: dataAte,
          datacontext: dataContext
        }
      });
    };

    this.getMarcacoesFaltas = (codServico: number, dataDe: Moment, dataAte: Moment, dataContext?: Moment) => {
      return this.get<IJsonPRHServicoMarcacoes>({
        id: `${codServico}/marcacoesfaltas`,
        params: {
          datade: dataDe,
          dataate: dataAte,
          datacontext: dataContext
        }
      });
    };

    this.getMarcacoesAbonos = (codServico: number, dataDe: Moment, dataAte: Moment, dataContext?: Moment) => {
      return this.get<IJsonPRHServicoMarcacoes>({
        id: `${codServico}/marcacoesabonos`,
        params: {
          datade: dataDe,
          dataate: dataAte,
          datacontext: dataContext
        }
      });
    };

    this.getDatasMarcacoesPendentes = (tipo: EPRHFluxoIndiceTipo, codServico: number, dataContext: Moment) => {
      return this.get<IJsonPRHServicoDatasMarcacoesPendentes>({
        id: `${codServico}/dataspendentes`,
        params: {
          tipo: tipo,
          datacontext: dataContext
        }
      });
    };
  }
};
