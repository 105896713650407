import {Component, Injector, Input, OnInit} from '@angular/core';
import {downloadStream, IPlToolbarItem, IPlToolbarMenuItem, isEmpty, isNumber, isObject, PlAlertService} from 'pl-comps-angular';
import moment from 'moment';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {
  ERadioTipoDeclaracao,
  IJsonContribuintesNaoPtComRetencao,
  IJsonContribuintesNaoPtSemRetencao,
  IJsonJustosImpedimentos,
  IJsonModelo30,
  IJsonModelo30Params,
  IJsonModelo30Q7List,
  IJsonModelo30Q8List,
  IJsonRegimesTributacao
} from '../jsonModelo30.module.interface';
import {
  EModelo30Q8DataGridLegendColors,
  ETipModelo30,
  ETiposValidar,
  IJsonGuiasPagamento,
  IJsonModeloErrors,
  IJsonValidarModeloErrors,
  IModelo30VerificarLancamentos,
  RADIO_GROUP_TIPO_DECLARACAO,
  TABLE_LEGEND_MODELO_30_Q8
} from '../modelo30.module.interface';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonPeriodo} from '../../../../entities/periodos/jsonPeriodo.entity.interface';
import {IJsonRENDI} from '../../../../entities/rendis/jsonRendi.entity.interface';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {Modelo30Service} from '../modelo30.module.service';
import {ModeloConfigwsModalComponent} from '../../modeloconfigwsmodal/modals/modelo.configWS.modal.component';
import {ModuloComponent} from '../../../../components/module/module.component';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowUpdated
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {ENTITY_NAME_NIFS, INifsEntityService} from '../../../../entities/nifs/nifs.entity.interface';
import {Modelo30VerificarLancamentosModalComponent} from '../modals/verificarlancamentos/modelo30.verificarLancamentos.modal.component';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IJsonNIFs} from '../../../../entities/nifs/jsonNifs.entity.interface';
import {TTableLegend} from '../../../../components/tablelegend/tablelegend.component.interface';
import {ENTITY_NAME_REP_FINANCAS} from '../../../../entities/repfinancas/repFinancas.entity.interface';
import {IJsonReparticaoFinancas} from '../../../../entities/repfinancas/jsonReparticaoFinancas.entity.interface';

const TOOLBAR_GROUP_RESPONSIVE = 'module-btns-responsive';

@Component({
  selector: 'module-modelo30',
  templateUrl: './modelo30.module.component.html'
})
export class Modelo30ModuleComponent extends ModuloComponent implements OnInit {
  @Input() public modelo30: IJsonModelo30;
  @Input() public periodos: Array<IJsonPeriodo>;
  @Input() public justosImpedimentos: Array<IJsonJustosImpedimentos>;
  @Input() public regimesTributacao: Array<IJsonRegimesTributacao>;
  @Input() public rendimentos: Array<IJsonRENDI>;

  public readonly eTiposValidar: typeof ETiposValidar;
  public readonly templateTiposImpedimento: string;
  public readonly outputTiposImpedimento: string;
  public readonly tiposDeclaracao: IRadioGroup<ERadioTipoDeclaracao>;

  public dataGridQ07: IDevExpressDataGrid<IJsonGuiasPagamento, IJsonGuiasPagamento>;
  public dataGridQ08: IDevExpressDataGrid<IJsonModelo30Q8List, IJsonModelo30Q8List>;
  public q02c02CasoJustoImpedimento: boolean;
  public q08ExisteMontanteSemGuias: boolean;
  public q08ExisteLinhasDuplicadas: boolean;
  public errors: IJsonModeloErrors;
  public tableLegend: TTableLegend;

  private readonly _mnuPeriodos: IPlToolbarItem<IJsonPeriodo>;
  private readonly _btnCriarFicheiro: IPlToolbarItem;
  private readonly _btnValidarFicheiro: IPlToolbarItem;
  private readonly _btnValidarAt: IPlToolbarItem;
  private readonly _btnSumbeterAt: IPlToolbarItem;
  private readonly _btnConfigWS: IPlToolbarItem;
  private readonly _btnRepor: IPlToolbarItem;
  private readonly _btnVerificarLancamentos: IPlToolbarItem;

  private readonly _maintenanceInstanceNifs: IEntityMaintenanceInstance;
  private readonly _maintenanceInstanceRepFinan: IEntityMaintenanceInstance;
  private readonly _serviceNifs: INifsEntityService;

  private _dataGridInstanceQ08: dxDataGrid<IJsonModelo30Q8List, IJsonModelo30Q8List>;
  private _periodo: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo30Service: Modelo30Service,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._cleanErrors();
    this._maintenanceInstanceNifs = this._entityMaintenanceService.build(ENTITY_NAME_NIFS);
    this._maintenanceInstanceRepFinan = this._entityMaintenanceService.build(ENTITY_NAME_REP_FINANCAS);
    this._serviceNifs = this._entityServiceBuilder.build<IJsonNIFs, INifsEntityService>(ENTITY_NAME_NIFS);
    this.eTiposValidar = ETiposValidar;
    this.tiposDeclaracao = RADIO_GROUP_TIPO_DECLARACAO;
    this.outputTiposImpedimento = '{{cod}} - {{descricao}}';
    this.templateTiposImpedimento = '{{cod}} - {{descricao}}';
    this.q02c02CasoJustoImpedimento = false;
    this.q08ExisteMontanteSemGuias = false;
    this.q08ExisteLinhasDuplicadas = false;

    // btns
    this._mnuPeriodos = {
      id: 'periodos',
      order: 1,
      caption: '',
      type: 'dropdown',
      class: 'btn-primary',
      menu: []
    };
    this._btnCriarFicheiro = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'criarFicheiro',
      type: 'dropdown-split',
      order: this._mnuPeriodos.order + 1,
      class: 'btn-primary',
      iconLeft: '<i class="fa fa-fw fa-file-text-o"></i>',
      caption: 'global.btn.createFile',
      disabled: false,
      click: () => this._saveModelo30()
    };
    this._btnValidarFicheiro = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'validarFicheiro',
      type: 'button',
      order: this._btnCriarFicheiro.order + 1,
      caption: 'global.btn.validarFicheiro',
      iconLeft: '<i class="fa fa-fw fa-globe"></i>',
      click: () => this._validarFicheiro(),
      disabled: false
    };
    this._btnValidarAt = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'validarNaAT',
      type: 'dropdown-split',
      order: this._btnValidarFicheiro.order + 1,
      class: 'btn-primary',
      caption: 'global.btn.validarAt',
      iconLeft: '<i class="fa fa-fw fa-globe"></i>',
      click: () => this._validarNaAT(),
      disabled: false
    };
    this._btnSumbeterAt = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'submeterNaAT',
      order: this._btnValidarAt.order + 1,
      type: 'button',
      caption: 'global.btn.submeterAt',
      iconLeft: '<i class="fa fa-fw fa-upload"></i>',
      click: () => this._submeterNaAT(),
      disabled: false
    };
    this._btnConfigWS = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'configWS',
      order: this._btnSumbeterAt.order + 1,
      type: 'button',
      caption: 'global.btn.configWS',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      click: () => this._callConfigWS(),
      disabled: false
    };
    this._btnRepor = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'repor',
      order: this._btnConfigWS.order + 1,
      type: 'button',
      caption: 'global.btn.replenish',
      class: 'btn-warning',
      iconLeft: '<i class="fa fa-fw fa-refresh"></i>',
      tooltip: {
        disabled: false,
        text: 'modelo30.btns.btnRepor',
        placement: 'bottom'
      },
      click: () => this._getModeloDados(),
      disabled: false
    };
    this._btnVerificarLancamentos = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'verificarLancamentos',
      order: this._btnRepor.order + 1,
      type: 'button',
      caption: 'modelo30.btns.verificarLancamentos',
      class: 'btn-info',
      iconLeft: '<i class="fa fa-fw fa-magic"></i>',
      click: () => this._verificarLancamentos(),
      disabled: false
    };

    this.dataGridQ07 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'index', dataType: 'number', caption: '', cellTemplate: 'dataGridTemplateIndex', allowEditing: false, allowFiltering: false},
        {
          dataField: 'guiaPagamento',
          dataType: 'number',
          caption: 'modelo30.groups.q7.dataGrid.nGuia',
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('datagrid.column.required')
            }
          ]
        },
        {
          dataField: 'valorTotal',
          dataType: 'double',
          caption: 'modelo30.groups.q7.dataGrid.valorTotal',
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('datagrid.column.required')
            }
          ]
        }
      ],
      summary: {
        totalItems: [
          {
            column: 'valorTotal',
            summaryType: 'sum',
            valueFormat: 'double',
            displayFormat: `${<string>this._translateService.instant('global.text.total')}: {0}`
          }
        ]
      },
      export: {filename: 'modelo30.groups.q7.title'},
      columnChooser: {enabled: false},
      remoteOperations: false,
      headerFilter: {visible: false}
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.tableLegend = TABLE_LEGEND_MODELO_30_Q8;
    this.modelo30.q2c02b = this.q02c02CasoJustoImpedimento ? moment().format('YYYY-MM-DD') : undefined;
    this._periodo = `${moment().year()}${moment().format('MM')}1`;
    this._buildPeriodosMenu().then(() => {
      this.toolbar.addButton(this._mnuPeriodos);
    });

    // add to the toolbar
    this.dropdownActions.menu = [this._btnCriarFicheiro, this._btnValidarFicheiro, this._btnValidarAt, this._btnSumbeterAt, this._btnConfigWS, this._btnRepor, this._btnVerificarLancamentos];
    this.toolbar.addButton(this._btnCriarFicheiro).addButton(this._btnValidarAt).addButton(this._btnRepor).addButton(this._btnVerificarLancamentos);
    this._buildToolbarResponsive(this.isMobile);

    this.dataGridQ08 = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'extPocLigaID', dataType: 'string', caption: '', allowEditing: false, visible: false, showInColumnChooser: false},
        {dataField: 'index', dataType: 'number', caption: '', cellTemplate: 'dataGridTemplateIndex', allowEditing: false, allowFiltering: false},
        {dataField: 'nguiam30', dataType: 'string', caption: 'modelo30.groups.q8.dataGrid.guia'},
        {
          dataField: 'nifpt',
          dataType: 'string',
          caption: 'modelo30.groups.q8.dataGrid.nif',
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('datagrid.column.required')
            }
          ]
        },
        {
          dataField: 'nif',
          dataType: 'string',
          caption: 'modelo30.groups.q8.dataGrid.nifPais',
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('datagrid.column.required')
            }
          ]
        },
        {
          dataField: 'codPais',
          dataType: 'string',
          caption: 'modelo30.groups.q8.dataGrid.codPais',
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('datagrid.column.required')
            }
          ]
        },
        {dataField: 'partcapd', dataType: 'number', caption: 'modelo30.groups.q8.dataGrid.partCapD', format: {percent: true}},
        {dataField: 'partcaps', dataType: 'number', caption: 'modelo30.groups.q8.dataGrid.partCapS', format: {percent: true}},
        {
          dataField: 'renditipo',
          dataType: 'string',
          caption: 'modelo30.groups.q8.dataGrid.rendTipo',
          width: 300,
          showEditorAlways: true,
          lookup: {
            dataSource: this.rendimentos,
            valueExpr: 'tipRendiment',
            displayExpr: (item: IJsonRENDI) => `${item.tipRendiment} - ${item.nome}`,
            allowClearing: false
          }
        },
        {
          dataField: 'rendimentovalor',
          dataType: 'double',
          caption: 'modelo30.groups.q8.dataGrid.rendValor',
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('datagrid.column.required')
            }
          ]
        },
        {
          dataField: 'codtributacao',
          dataType: 'string',
          caption: 'modelo30.groups.q8.dataGrid.tribCod',
          width: 300,
          showEditorAlways: true,
          lookup: {
            dataSource: this.regimesTributacao,
            valueExpr: 'cod',
            displayExpr: (item: IJsonRegimesTributacao) => `${item.cod} - ${item.descricao}`,
            allowClearing: false
          }
        },
        {
          dataField: 'taxatributacao',
          dataType: 'number',
          caption: 'modelo30.groups.q8.dataGrid.tribTaxa',
          format: {percent: true},
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('datagrid.column.required')
            }
          ]
        },
        {
          dataField: 'montanteimpostoretido',
          dataType: 'double',
          caption: 'modelo30.groups.q8.dataGrid.montante',
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('datagrid.column.required')
            }
          ]
        },
        {
          dataField: 'nifemit',
          dataType: 'string',
          caption: 'modelo30.groups.q8.dataGrid.nifEntidade'
        },
        {type: 'buttons', cellTemplate: 'cellTemplateBtnsEdit', fixedPosition: 'right', fixed: true},
        {type: 'actions', fixedPosition: 'right', fixed: true}
      ],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        mode: 'cell',
        newRowPosition: 'last'
      },
      summary: {
        totalItems: [
          {
            column: 'montanteimpostoretido',
            summaryType: 'sum',
            valueFormat: 'double',
            displayFormat: `${<string>this._translateService.instant('global.text.total')}: {0}`
          }
        ]
      },
      export: {filename: 'modelo30.groups.q8.title'},
      columnChooser: {enabled: false},
      remoteOperations: false,
      headerFilter: {visible: false}
    };
    this._checkDuplicatedQ8Linhas();
  }

  public onInitializedQ08({component}: IDevExpressDataGridEventOnInitialized<IJsonModelo30Q8List, IJsonModelo30Q8List>): void {
    this._dataGridInstanceQ08 = component;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonModelo30Q8List, IJsonModelo30Q8List>): void {
    if (event.rowType === 'data' && event.data._duplicated) {
      event.cellElement.classList.add(EModelo30Q8DataGridLegendColors.ExisteLinhasParaMesmoContribuinte);
    }
  }

  public onSavedQ08(): void {
    this._validateq08Rules();
    this._buildQ07();
    this._buildQ06();
    this.calcQuadro6Field17();
    this._checkDuplicatedQ8Linhas();
    this._evaluateButtons();
  }

  public onRowUpdatedQ08(event: IDevExpressDataGridEventOnRowUpdated<IJsonModelo30Q8List, IJsonModelo30Q8List>): void {
    if (event.data.extPocLigaID && event.data.nguiam30) {
      this._modelo30Service.saveNGuia(event.data.extPocLigaID, event.data.nguiam30).then(() => {
        this._dataGridInstanceQ08.refresh();
      });
    }
  }

  public onContentReadyQ08(): void {
    this._validateq08Rules();
    this._buildQ07();
    this._buildQ06();
    this.calcQuadro6Field17();
    this._checkDuplicatedQ8Linhas();
    this._evaluateButtons();
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    if (this.toolbar) {
      this._buildToolbarResponsive(value);
    }
  }

  public q02c02CasoJustoImpedimentoChanged(value: boolean): void {
    this.q02c02CasoJustoImpedimento = value;
    if (!value) {
      this.modelo30.q2c02a = this.modelo30.q2c02b = undefined;
    }
  }

  public q2c02aChanged(value: IJsonJustosImpedimentos): void {
    if (isObject(value)) {
      if (value.cod === '') {
        this.modelo30.q2c02a = undefined;
        return;
      }
      this.modelo30.q2c02a = value.cod;
    }
  }

  public calcQuadro6Field17(): void {
    const fq6c06 = isNumber(this.modelo30.q6c06) ? this.modelo30.q6c06 : 0;
    const fq6c07 = isNumber(this.modelo30.q6c07) ? this.modelo30.q6c07 : 0;
    const fq6c08 = isNumber(this.modelo30.q6c08) ? this.modelo30.q6c08 : 0;
    const fq6c09 = isNumber(this.modelo30.q6c09) ? this.modelo30.q6c09 : 0;
    const fq6c10 = isNumber(this.modelo30.q6c10) ? this.modelo30.q6c10 : 0;
    const fq6c11 = isNumber(this.modelo30.q6c11) ? this.modelo30.q6c11 : 0;
    const fq6c12 = isNumber(this.modelo30.q6c12) ? this.modelo30.q6c12 : 0;
    const fq6c13 = isNumber(this.modelo30.q6c13) ? this.modelo30.q6c13 : 0;
    const fq6c14 = isNumber(this.modelo30.q6c14) ? this.modelo30.q6c14 : 0;
    const fq6c40 = isNumber(this.modelo30.q6c40) ? this.modelo30.q6c40 : 0;
    const fq6c41 = isNumber(this.modelo30.q6c41) ? this.modelo30.q6c41 : 0;
    const fq6c16 = isNumber(this.modelo30.q6c16) ? this.modelo30.q6c16 : 0;

    this.modelo30.q6c17 = fq6c06 + fq6c07 + fq6c08 + fq6c09 + fq6c10 + fq6c11 + fq6c12 + fq6c13 + fq6c14 + fq6c40 + fq6c41 + fq6c16;
  }

  public openRepFinancas(): void {
    this._maintenanceInstanceRepFinan.maintenanceEdit(this.modelo30.codRepFinancas).then((repFinancas: IJsonReparticaoFinancas) => {
      this.modelo30.q4c04 = repFinancas.codFinancasAT;
    });
  }

  public async editManutencaoNif(item: IJsonModelo30Q8List): Promise<void> {
    if (!item.nif) {
      this._plAlertService.warning('modelo30.errors.linhaSemNif');
      return;
    }
    await this._maintenanceInstanceNifs.maintenanceEdit(item.nif);
    for (const modeloItem of this.modelo30.q8List) {
      if (item.nif === modeloItem.nif) {
        this._serviceNifs.get({id: item.nif}).then((responseNif: HttpResponse<IJsonNIFs>) => {
          const nifLines: Array<IJsonModelo30Q8List> = this.modelo30.q8List.filter((findIndex: IJsonModelo30Q8List) => findIndex.nif === modeloItem.nif);
          nifLines.forEach((nifLinesItem: IJsonModelo30Q8List) => {
            nifLinesItem.partcaps = responseNif.body.m30PartCapS;
            nifLinesItem.partcapd = responseNif.body.m30PartCapD;
            nifLinesItem.nifemit = responseNif.body.m30NifEmit;
            nifLinesItem.nifpt = responseNif.body.m30NifPT;
          });
        });
        break;
      }
    }
  }

  private _buildPeriodosMenu(): Promise<void> {
    this.periodos.forEach((item: IJsonPeriodo) => {
      const itemActive: boolean = item.periodo === this._periodo;
      this._mnuPeriodos.menu.push({
        id: item.periodo,
        caption: this._generatePeriodosString(item),
        data: item,
        active: itemActive,
        click: (menu: IPlToolbarMenuItem<IJsonPeriodo>) => this._mudaPeriodo(menu)
      });
      if (itemActive) {
        this._changeCaptionMenuPeriodos(item.periodo);
      }
    });
    return Promise.resolve();
  }

  private _mudaPeriodo(menu: IPlToolbarMenuItem<IJsonPeriodo>): Promise<void> {
    if (!menu || menu.data.periodo === this._periodo) {
      return Promise.resolve();
    }
    const index: number = this._mnuPeriodos.menu.findIndex((item: IPlToolbarMenuItem<IJsonPeriodo>) => item.id === this._periodo);
    if (index !== -1) {
      this._mnuPeriodos.menu[index].active = false;
    }
    this._mnuPeriodos.caption = menu.caption;
    this._periodo = menu.data.periodo;
    menu.active = true;
    return this._periodoChanged();
  }

  private _periodoChanged(): Promise<void> {
    return this._getModeloDados();
  }

  private _changeCaptionMenuPeriodos(periodo: string): void {
    const periodoObj: IJsonPeriodo = this.periodos.find((item: IJsonPeriodo) => item.periodo === periodo);
    this._mnuPeriodos.caption = <string>this._translateService.instant('toolbar.period', {value: this._generatePeriodosString(periodoObj)});
  }

  private _generatePeriodosString(periodo: IJsonPeriodo): string {
    return `${periodo.periodo} - ${periodo.nome}`;
  }

  private _cleanErrors(): void {
    this.errors = {
      tipoValidacao: ETiposValidar.Default,
      erros: []
    };
  }

  private async _saveModelo30(): Promise<void> {
    this._cleanErrors();
    const response: HttpResponse<Array<IJsonValidarModeloErrors>> = await this._modelo30Service.validarModelo(this.modelo30);
    if (response.body) {
      if (isEmpty(this.modelo30.q4c04)) {
        const indexSF = response.body.findIndex((error: IJsonValidarModeloErrors) => error.nomeCampo === 'SF');
        if (indexSF !== -1) {
          response.body[indexSF].nomeCampo = this._translateService.instant('modelo30.errors.sf');
          response.body[indexSF].mensagem = this._translateService.instant('modelo30.errors.sfMessage');
        }
      }
      this.errors = {
        tipoValidacao: ETiposValidar.Default,
        mensagemTipoHeader: this._translateService.instant('modelo30.errors.validarFailed'),
        erros: response.body
      };
    }
    if (!this.errors.erros.length) {
      const responseFicheiro: HttpResponse<Blob> = await this._modelo30Service.criarFicheiro(this.modelo30);
      downloadStream(responseFicheiro);
    } else {
      const index: number = this.errors.erros.findIndex((item: IJsonValidarModeloErrors) => item.nomeCampo === 'Pais');
      if (index !== -1) {
        this.errors.erros[index].nomeCampo = this._translateService.instant('modelo30.errors.pais');
        this.errors.erros[index].mensagem = this._translateService.instant('modelo30.errors.paisMessage');
      }
    }
    this._evaluateButtons();
  }

  private async _validarFicheiro(): Promise<void> {
    this._cleanErrors();
    const response: HttpResponse<Array<IJsonValidarModeloErrors>> = await this._modelo30Service.validarModelo(this.modelo30);
    if (response.body) {
      if (isEmpty(this.modelo30.q4c04)) {
        const indexSF = response.body.findIndex((error: IJsonValidarModeloErrors) => error.nomeCampo === 'SF');
        if (indexSF !== -1) {
          response.body[indexSF].nomeCampo = this._translateService.instant('modelo30.errors.sf');
          response.body[indexSF].mensagem = this._translateService.instant('modelo30.errors.sfMessage');
        }
      }
      this.errors = {
        tipoValidacao: ETiposValidar.Default,
        mensagemTipoHeader: this._translateService.instant('modelo30.errors.validarFailed'),
        erros: response.body
      };
    }
    if (!this.errors.erros.length) {
      this._plAlertService.success('modelo30.servicoAT.validarAtSuccess');
    }
    this._evaluateButtons();
  }

  private async _validarNaAT(): Promise<void> {
    this._cleanErrors();
    const response = await this._modelo30Service.validarDeclaracaoAT(this.modelo30).catch((reason: HttpErrorResponse) => {
      const exception: ICGExceptionError = this._cgExceptionService.get(reason);
      this.errors = {
        tipoValidacao: ETiposValidar.AT,
        mensagemTipoHeader: this._translateService.instant('modelo30.servicoAT.validarAtFailed'),
        erros: [{mensagemOriginal: exception.message}]
      };
    });
    if (response) {
      this._plAlertService.success('modelo30.servicoAT.validarAtSuccess');
    }
    this._evaluateButtons();
  }

  private async _submeterNaAT(): Promise<void> {
    this._cleanErrors();
    const response = await this._modelo30Service.submeterDeclaracaoAT(this.modelo30).catch((reason: HttpErrorResponse) => {
      const exception: ICGExceptionError = this._cgExceptionService.get(reason);
      this.errors = {
        tipoValidacao: ETiposValidar.AT,
        mensagemTipoHeader: this._translateService.instant('modelo30.servicoAT.submeterAtFailed'),
        erros: [{mensagemOriginal: exception.message}]
      };
    });
    if (response) {
      this._plAlertService.success('modelo30.servicoAT.submeterAtSuccess');
    }
    this._evaluateButtons();
  }

  private _callConfigWS(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ModeloConfigwsModalComponent);
    return modalInstance.result;
  }

  private _buildToolbarResponsive(isMobile: boolean): void {
    this.toolbar.removeGroupId(TOOLBAR_GROUP_RESPONSIVE, false);
    this.dropdownActions.visible = isMobile;
    this._btnValidarAt.type = isMobile ? 'button' : 'dropdown-split';
    this._btnCriarFicheiro.type = isMobile ? 'button' : 'dropdown-split';
    if (!isMobile) {
      this._btnCriarFicheiro.class = this._btnValidarAt.class = 'btn-primary';
      this._btnValidarAt.menu = [this._btnSumbeterAt, this._btnConfigWS];
      this._btnCriarFicheiro.menu = [this._btnValidarFicheiro];
      this.toolbar.addButton(this._btnCriarFicheiro).addButton(this._btnValidarAt).addButton(this._btnRepor).addButton(this._btnVerificarLancamentos);
    } else {
      this._btnCriarFicheiro.class = this._btnValidarAt.class = this._btnRepor.class = this._btnVerificarLancamentos.class = undefined;
      this.toolbar.addButton(this.dropdownActions);
    }
  }

  private _getValuesQ08FromType(type: string): number {
    let total = 0;
    const itens: Array<IJsonModelo30Q8List> = this.modelo30.q8List.filter((item: IJsonModelo30Q8List) => item.renditipo === type);
    itens.forEach((item: IJsonModelo30Q8List) => {
      total += item.montanteimpostoretido;
    });
    return total;
  }

  private _buildQ06(): void {
    this.modelo30.q6c06 = this._getValuesQ08FromType('10');
    this.modelo30.q6c07 = this._getValuesQ08FromType('11');
    this.modelo30.q6c08 = this._getValuesQ08FromType('12');
    this.modelo30.q6c09 = this._getValuesQ08FromType('15') + this._getValuesQ08FromType('16');
    this.modelo30.q6c10 = this._getValuesQ08FromType('14') + this._getValuesQ08FromType('17');
    this.modelo30.q6c11 = this._getValuesQ08FromType('08');
    this.modelo30.q6c12 = this._getValuesQ08FromType('06');
    this.modelo30.q6c13 = this._getValuesQ08FromType('18');
    this.modelo30.q6c14 = this._getValuesQ08FromType('07');
    this.modelo30.q6c40 = this._getValuesQ08FromType('22');
    this.modelo30.q6c41 = this._getValuesQ08FromType('23');
    this.modelo30.q6c16 = this._getValuesQ08FromType('13') + this._getValuesQ08FromType('20') + this._getValuesQ08FromType('21');
  }

  private _buildQ07(): void {
    this.modelo30.q7List = [];
    for (const item of this.modelo30.q8List) {
      if (!item.nguiam30) {
        continue;
      }
      const guiaIndex: number = this.modelo30.q7List.findIndex((guiaItem: IJsonModelo30Q7List) => guiaItem.guiaPagamento === item.nguiam30);
      if (guiaIndex === -1) {
        this.modelo30.q7List.push({guiaPagamento: item.nguiam30, valorTotal: item.montanteimpostoretido});
      } else {
        this.modelo30.q7List[guiaIndex].valorTotal += item.montanteimpostoretido;
      }
    }
  }

  private _validateq08Rules(): void {
    this.q08ExisteMontanteSemGuias = false;
    this.modelo30.q8List.forEach((item: IJsonModelo30Q8List) => {
      if (!item.nguiam30 && item.montanteimpostoretido) {
        this.q08ExisteMontanteSemGuias = true;
      }
    });
  }

  private _evaluateButtons(): void {
    this._btnCriarFicheiro.disabled = this._btnValidarFicheiro.disabled = this._btnValidarAt.disabled = this._btnSumbeterAt.disabled = !this._checkModeloForExport();
  }

  private _checkModeloForExport(): boolean {
    if (this._dataGridInstanceQ08.hasEditData()) {
      return false;
    }
    if (this.modelo30.q8List.length <= 0) {
      this._hasQ08WithoutData();
      return false;
    }
    return true;
  }

  private _hasQ08WithoutData(): void {
    this._cleanErrors();
    this.errors.erros.push({
      nomeCampo: this._translateService.instant('modelo30.errors.q08'),
      mensagem: this._translateService.instant('modelo30.errors.q08WithoutData')
    });
  }

  private async _getModeloDados(): Promise<void> {
    const params: IJsonModelo30Params = {
      periodo: this._periodo,
      tipodeclaracao: this.modelo30.q5c05,
      tipmodelo: ETipModelo30.NewFromDB
    };
    return this._modelo30Service.getModelo30(params).then((response: HttpResponse<IJsonModelo30>) => {
      this._cleanErrors();
      this.modelo30 = response.body;
      this._changeCaptionMenuPeriodos(params.periodo);
      this._checkDuplicatedQ8Linhas();
      this._evaluateButtons();
    });
  }

  private async _verificarLancamentos(): Promise<void> {
    const contribuintesNaoPtComRetencaos: Array<IJsonContribuintesNaoPtComRetencao> = await this._modelo30Service
      .getContribuintesNaoPtComRetencao(this._periodo)
      .then((response: HttpResponse<Array<IJsonModelo30Q8List>>) =>
        response.body.map<IJsonContribuintesNaoPtComRetencao>((value: IJsonModelo30Q8List) => {
          return {
            nif: value.nif,
            codPais: value.codPais,
            taxatributacao: value.taxatributacao,
            rendimentovalor: value.rendimentovalor,
            montanteimpostoretido: value.montanteimpostoretido,
            nguiam30: value.nguiam30,
            extPocLigaID: value.extPocLigaID
          };
        })
      );
    const contribuintesNaoPtSemRetencaos: Array<IJsonContribuintesNaoPtSemRetencao> = await this._modelo30Service
      .getContribuintesNaoPtSemRetencao(this._periodo)
      .then((response: HttpResponse<Array<IJsonModelo30Q8List>>) =>
        response.body.map<IJsonContribuintesNaoPtSemRetencao>((value: IJsonModelo30Q8List) => {
          return {
            nif: value.nif,
            codPais: value.codPais,
            rendimentovalor: value.rendimentovalor,
            extPocLigaID: value.extPocLigaID
          };
        })
      );
    const modalInstance = this._cgModalService.showVanilla(Modelo30VerificarLancamentosModalComponent);
    const componentInstance: Modelo30VerificarLancamentosModalComponent = modalInstance.componentInstance;
    componentInstance.modelo = this.modelo30;
    componentInstance.contribuintesNaoPtComRetencao = contribuintesNaoPtComRetencaos;
    componentInstance.contribuintesNaoPtSemRetencao = contribuintesNaoPtSemRetencaos;
    const newLines: IModelo30VerificarLancamentos = await modalInstance.result;
    this._addVerificarLancamentosLinesToQ08(newLines);
  }

  private _addVerificarLancamentosLinesToQ08(lines: IModelo30VerificarLancamentos): void {
    if (Boolean(lines.contribuintesComRetencao.length)) {
      lines.contribuintesComRetencao.forEach((item: IJsonContribuintesNaoPtComRetencao) => {
        const itemExisteNoQ08: boolean = this.modelo30.q8List.some((findItem: IJsonContribuintesNaoPtComRetencao) => findItem.extPocLigaID === item.extPocLigaID);
        if (!itemExisteNoQ08) {
          this.modelo30.q8List.push({
            nif: item.nif,
            codPais: item.codPais,
            taxatributacao: item.taxatributacao,
            rendimentovalor: item.rendimentovalor,
            montanteimpostoretido: item.montanteimpostoretido,
            nguiam30: item.nguiam30,
            extPocLigaID: item.extPocLigaID,
            renditipo: undefined,
            codtributacao: undefined,
            nifemit: undefined,
            nifpt: undefined,
            partcapd: undefined,
            partcaps: undefined
          });
        }
      });
    }

    if (Boolean(lines.contribuintesSemRetencao.length)) {
      lines.contribuintesSemRetencao.forEach((item: IJsonContribuintesNaoPtSemRetencao) => {
        const itemExisteNoQ08: boolean = this.modelo30.q8List.some((findItem: IJsonContribuintesNaoPtSemRetencao) => findItem.extPocLigaID === item.extPocLigaID && findItem.nif === item.nif);
        if (!itemExisteNoQ08) {
          this.modelo30.q8List.push({
            nif: item.nif,
            codPais: item.codPais,
            rendimentovalor: item.rendimentovalor,
            extPocLigaID: item.extPocLigaID,
            renditipo: undefined,
            codtributacao: undefined,
            nifemit: undefined,
            nifpt: undefined,
            partcapd: undefined,
            partcaps: undefined,
            nguiam30: undefined,
            montanteimpostoretido: undefined,
            taxatributacao: undefined
          });
        }
      });
    }
  }

  private _checkDuplicatedQ8Linhas(): void {
    this._changeDuplicatedLinesFalse();
    this.q08ExisteLinhasDuplicadas = false;
    for (const linha of this.modelo30.q8List) {
      if (this.modelo30.q8List.filter((linhaItem: IJsonModelo30Q8List) => linhaItem.nifpt === linha.nifpt).length > 1) {
        linha._duplicated = true;
        this.q08ExisteLinhasDuplicadas = true;
      }
    }
  }

  private _changeDuplicatedLinesFalse(): void {
    for (const linha of this.modelo30.q8List) {
      linha._duplicated = false;
    }
  }
}
