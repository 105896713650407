import {IJsonContabAdvancedOperations, IJsonContabAdvancedOperationsDocs} from './jsonContabAdvancedOperations.module.interface';
import {IJsonDocContabilidade} from '../docscontabilidade/jsonDocContabilidade.interface';

export const MODULE_NAME_CONTAB_ADVANCED_OPERATIONS_PERIODO = 'contabadvancedoperations/periodo';
export const MODULE_NAME_CONTAB_ADVANCED_OPERATIONS_DIARIO = 'contabadvancedoperations/diario';
export const MODULE_NAME_CONTAB_ADVANCED_OPERATIONS_ANULAR = 'contabadvancedoperations/anular';

export enum EContabTipoOperacao {
  Periodo,
  Diario,
  Anulacao
}

export interface IContabAdvancedOperationsDocs extends IJsonContabAdvancedOperationsDocs {
  _docContabilidade?: IJsonDocContabilidade;
}

export interface IContabAdvancedOperations extends IJsonContabAdvancedOperations {
  listaDocsNaoPermitidos?: Array<IContabAdvancedOperationsDocs>;
  listaDocsPermitidos?: Array<IContabAdvancedOperationsDocs>;
}
