import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IDMRFItemBase, IDMRFLoadResults} from './dmrf.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class DmrfService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/dmrf`;
  }

  public load(periodo: string): TServiceResponse<IDMRFLoadResults> {
    return this._apiService.get<IDMRFLoadResults>({
      url: `${this._path}/load`,
      params: {periodo: periodo}
    });
  }

  public saveRecord(data: IDMRFItemBase): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/save-record`,
      body: data
    });
  }

  public deleteRecord(dmrfId: string): TServiceResponse<void> {
    return this._apiService.delete({
      url: `${this._path}/delete-record`,
      params: {dmrfId: dmrfId}
    });
  }

  public delete(): TServiceResponse<void> {
    return this._apiService.delete({
      url: `${this._path}/delete`
    });
  }

  public save(frestran: boolean): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/save`,
      params: {frestran: frestran}
    });
  }

  public exportXML(): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({url: `${this._path}/export`, responseType: 'blob'});
  }

  public sendAT(frestran: boolean): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/send-file`,
      params: {frestran: frestran}
    });
  }

  public stopMS(): TServiceResponse<void> {
    return this._apiService.post({
      url: this.stopMSUrl()
    });
  }

  public stopMSUrl(): string {
    return `${this._path}/stop-ms`;
  }
}
