import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {RhMapasSindicatosModuleComponent} from './components/rhMapasSindicatos.module.component';
import {MODULE_NAME_RH_MAPAS_SINDICATOS} from './rhMapasSindicatos.module.interface';

export const MODULE_RH_MAPAS_SINDICATOS: IModuleDefinition = {
  name: MODULE_NAME_RH_MAPAS_SINDICATOS,
  state: {
    url: `/${MODULE_NAME_RH_MAPAS_SINDICATOS}`,
    component: RhMapasSindicatosModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhmapassindicatos'
    }
  }
};
