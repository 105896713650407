import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_MOTIVO_CONTRATO} from './motivoContrato.datasource.interface';

export const DATA_SOURCE_MOTIVO_CONTRATO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_MOTIVO_CONTRATO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'motivoContrato.data.semmotivo'},
    {value: 1, name: 'motivoContrato.data.temporariamenteimpedidoprestarserviço'},
    {value: 2, name: 'motivoContrato.data.impendaaccaojuizoapreciacaolicitudedespedimento'},
    {value: 3, name: 'motivoContrato.data.situacaolicencasemretribuicao'},
    {value: 4, name: 'motivoContrato.data.substituicaotrabalhadorportempoindeterminado'},
    {value: 5, name: 'motivoContrato.data.cctividadessazonais'},
    {value: 6, name: 'motivoContrato.data.acrescimoexcepcionalactividadeempresa'},
    {value: 7, name: 'motivoContrato.data.execucaotarefaserviçodefinidonaoduradouro'},
    {value: 8, name: 'motivoContrato.data.execucaoprojectoactividadedefinidatemporaria'},
    {value: 9, name: 'motivoContrato.data.lancamentonovaactividadeduraçãoincerta'},
    {value: 10, name: 'motivoContrato.data.contratacaotrabalhadoresprimeiroemprego'},
    {value: 11, name: 'motivoContrato.data.actividadecontinuidadeousemtermo'},
    {value: 12, name: 'motivoContrato.data.outros'}
  ]
});
