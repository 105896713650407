import {Component, Injector, Input} from '@angular/core';
import {PlAlertService, PlI18nService, PlTranslateService} from 'pl-comps-angular';
import {ECalendarioTipoDiaStr} from '../../../../../entities/calendarios/jsonCalendarios.entity.interface';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../../../../entities/dgemps/dgemps.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {GestaoDGEMPSMarcarAbstractModalComponent} from '../gestaodgemps.marcar.abstract.modal.component';
import {gestaoDGEMPSMarcarHoraFim, gestaoDGEMPSMarcarHoraInicio} from '../../../gestaodgemps.utilities';
import {IGestaoDGEMPSCalendario, IGestaoDGEMPSMarcarModalData} from '../../../gestaodgemps.interface';
import {IJsonDGEMP, IJsonDGEMPCalendario} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHMarcarFerias} from '../../../../../interfaces/jsonPRHFerias.interface';
import moment, {Moment} from 'moment';
import {TDate} from '../../../../../../common/dates';
import {CGModalService} from '../../../../cg/modal/cgmodal.service';
import {ConfigsPortalColaboradorModuleService} from '../../../../../modules/configs/erp/portalcolaborador/configs.portalcolaborador.module.service';

@Component({
  selector: 'gestao-dgemps-marcar-ferias-modal',
  templateUrl: './gestaodgemps.marcar.ferias.modal.component.html'
})
export class GestaoDGEMPSMarcarFeriasModalComponent extends GestaoDGEMPSMarcarAbstractModalComponent {
  @Input() public motivo: string;

  public allDay: boolean;
  public horaInicio: TDate;
  public horaFim: TDate;

  private readonly _serviceDGEMPS: IDGEMPSEntityService;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _plI18nService: PlI18nService,
    protected readonly _plTranslateService: PlTranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _rhConflitosModuleService: ConfigsPortalColaboradorModuleService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector, _plI18nService, _plTranslateService);
    this.allDay = true;
    this.horaInicio = gestaoDGEMPSMarcarHoraInicio();
    this.horaFim = gestaoDGEMPSMarcarHoraFim();
    this._serviceDGEMPS = this._entityServiceBuilder.build<IJsonDGEMP, IDGEMPSEntityService>(ENTITY_NAME_DGEMPS);
  }

  public close(): Promise<void> {
    this.disableClose();

    const {viewDate, startDate, endDate}: IGestaoDGEMPSMarcarModalData = this.marcarData;
    const calendarios: Array<IGestaoDGEMPSCalendario> = this.marcarData.calendarios.slice();

    const fnMarcarFerias = (): Promise<void> => {
      const calendario = calendarios.shift();
      if (!calendario) {
        return Promise.resolve();
      }
      return this._marcarFeria(viewDate, startDate, endDate, calendario).then(fnMarcarFerias);
    };

    return fnMarcarFerias()
      .then(() => {
        this.enableClose();
        super.close();
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }

  private async _marcarFeria(viewDate: Moment, startDate: Moment, endDate: Moment, calendario: IGestaoDGEMPSCalendario): Promise<void> {
    const ferias: IJsonPRHMarcarFerias = {
      cab: undefined,
      linhas: [],
      motivo: this.motivo,
      codEmpLanca: this.codEmpLanca,
      codServico: this.codServico,
      proximoPapel: -1
    };

    for (const dataAtual = startDate.clone(); dataAtual.diff(endDate, 'days') <= 0; dataAtual.add(1, 'day')) {
      const dataCalendario: IJsonDGEMPCalendario = calendario.listaCalendario.find((day: IJsonDGEMPCalendario) => dataAtual.isSame(day.data, 'day'));
      if (!dataCalendario || dataCalendario.tipoDiaStr === ECalendarioTipoDiaStr.DiaUtil) {
        const horaInicio: Moment = !this.allDay ? moment(this.horaInicio) : dataAtual.clone().startOf('day');
        const horaFim: Moment = !this.allDay ? moment(this.horaFim) : dataAtual.clone().endOf('day');

        const dataInicio: Moment = viewDate.clone().set({
          hours: horaInicio.hours(),
          minutes: horaInicio.minutes(),
          seconds: horaInicio.seconds(),
          milliseconds: horaInicio.milliseconds()
        });
        const dataFim: Moment = viewDate.clone().set({
          hours: horaFim.hours(),
          minutes: horaFim.minutes(),
          seconds: horaFim.seconds(),
          milliseconds: horaFim.milliseconds()
        });

        ferias.linhas.push({
          idTarefaCab: undefined,
          posicao: undefined,
          idFerias: undefined,
          data: dataAtual.clone(),
          horaInicio: dataInicio,
          horaFim: dataFim,
          ocupacaoId: undefined
        });
      }
    }

    const checkResult = await this._rhConflitosModuleService.checkConflitoCodEmp(calendario.codEmp, ferias.linhas).then((response) => response.body);
    if (checkResult.temConflito) {
      if (!checkResult.permiteMarcar) {
        this._plAlertService.error('gestaodgemps.errors.naoPodeMarcarFeriasPorqueTemConflitos');
        await Promise.reject(this._plTranslateService.translate('gestaodgemps.errors.naoPodeMarcarFeriasPorqueTemConflitos'));
      }
      await this._cgModalService.showOkCancel('global.text.confirmation', 'gestaodgemps.ferias.existemConflitosPertendeContinuar');
    }

    await this._serviceDGEMPS.marcarFerias(calendario.codEmp, ferias).then(() => {
      this._successfulCalendarios.add(calendario);
      for (let i = this.marcarData.calendarios.length - 1; i >= 0; i--) {
        if (this.marcarData.calendarios[i].codEmp === calendario.codEmp) {
          this.marcarData.calendarios.splice(i, 1);
        }
      }
    });
  }
}
