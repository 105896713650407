<div class="prevites-auto">
  <pl-form>
    <pl-group>
      <label [translate]="'prevites.automatica.clientefornecedor'"></label>
      <edit>
        <pl-edit
          type="radiogroup"
          attrName="cliforn"
          [model]="clientFornecedor"
          (modelChange)="clientFornecedor = $event; clifornChanged()"
          [properties]="radioGroupTemplateClienteFornecedor"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'prevites.automatica.contade'"></label>
        <edit>
          <entity-autocomplete entity="pocs" attrName="contaDe" [(selectedKey)]="contaDe" output="key"></entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'global.text.to'"></label>
        <edit>
          <entity-autocomplete entity="pocs" attrName="contaAte" [(selectedKey)]="contaAte" output="key"></entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'prevites.automatica.datade'"></label>
        <edit>
          <pl-edit type="date" attrName="dataTesDe" [(model)]="dataTesDe"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'global.text.to'"></label>
        <edit>
          <pl-edit type="date" attrName="dataTesAte" [(model)]="dataTesAte"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>
  </pl-form>

  <dx-data-grid
    class="mt-3"
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="previTesAuto"
    [dataSource]="dataGridDefinition.dataSource"
    [(selectedRowKeys)]="selectedKeys"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()"
    (onCellPrepared)="onCellPrepared($event)"
    (onRowUpdated)="onRowUpdated($event)"
    (onContextMenuPreparing)="onContextMenuPreparing($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)">
    <div *dxTemplate="let cell of 'headerTemplateAutoSelection'">
      <div class="d-flex align-items-center">
        <pl-tooltip [config]="{text: 'prevites.automatica.selecaoAutomaticaTooltip'}">
          <pl-edit-switch *plTooltipContent class="prevites-auto-embed-switch" [(model)]="autoSelectAllLinesOfDocument" [label]="'prevites.automatica.selecaoAutomatica'"></pl-edit-switch>
        </pl-tooltip>
      </div>
    </div>
  </dx-data-grid>

  <div class="bancos-extrato-module-legend">
    <cg-table-legend [legend]="tableLegend"></cg-table-legend>
  </div>
</div>
