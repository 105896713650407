import {firstValueFrom} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {Transition} from '@uirouter/core';
import {ENTITY_NAME_POCS} from '../../entities/pocs/pocs.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonPOC} from '../../entities/pocs/jsonPOC.entity.interface';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ITrfLancContasModuleStateParams, MODULE_NAME_TRFLANCCONTAS} from './trfLancContas.module.interface';
import {ROLE} from '../../services/role.const';
import {TrfLancContasModuleComponent} from './components/trfLancContas.module.component';
import {ConfigService} from '../../services/config/config.service';
import {map, take} from 'rxjs/operators';
import {ICGConfigurations} from '../../services/config/config.service.interface';

export const MODULE_TRFLANCCONTAS: IModuleDefinition = {
  name: MODULE_NAME_TRFLANCCONTAS,
  state: {
    url: '/trflanccontas',
    component: TrfLancContasModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.trfLancContas'
    },
    params: {
      nContaOrigem: {
        type: 'string',
        value: ''
      }
    },
    resolve: [
      {
        provide: 'manterContaOrigem',
        deps: [ConfigService],
        useFactory: (configService: ConfigService): Promise<boolean> => {
          return firstValueFrom(
            configService
              .configurationsAsObservable()
              .pipe(take(1))
              .pipe(map<ICGConfigurations, boolean>((configurations: ICGConfigurations) => configurations.contabilidade.transferirSaldo.manterContaOrigem))
          );
        }
      },
      {
        provide: 'contaOrigem',
        deps: [Transition, EntityServiceBuilder],
        useFactory: (transition: Transition, entityServiceBuilder: EntityServiceBuilder): Promise<IJsonPOC> => {
          const params: ITrfLancContasModuleStateParams = transition.params();
          if (!params.nContaOrigem) {
            return Promise.resolve(undefined);
          }
          const servicePocs: IEntityService<IJsonPOC> = entityServiceBuilder.build<IJsonPOC>(ENTITY_NAME_POCS);
          return servicePocs.get({id: params.nContaOrigem}).then((response: HttpResponse<IJsonPOC>) => response.body);
        }
      }
    ]
  }
};
