import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_ESTADO_REGISTO, EEstadoRegisto} from './estadoRegisto.datasource.interface';

export const DATA_SOURCE_ESTADO_REGISTO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_ESTADO_REGISTO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EEstadoRegisto.Activo, name: 'estadoregisto.ativo'},
    {value: EEstadoRegisto.Inactivo, name: 'estadoregisto.inativo'}
  ]
});
