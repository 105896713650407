import {ENTITY_NAME_DOCFAS_NUMS_COMUNICACAO_LOGIN} from './docFasNumsComunicacaoLogin.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_DOCFAS_NUMS_COMUNICACAO_LOGIN: IEntityDefinition = {
  name: ENTITY_NAME_DOCFAS_NUMS_COMUNICACAO_LOGIN,
  roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
  asModule: false,
  metadata: {
    keyName: 'username',
    fields: [
      {
        name: 'username',
        width: '120px',
        caption: 'Username (NIF/UserID)',
        placeholder: 'Username (NIF/UserID)',
        validators: {required: true, min: 1}
      },
      {
        name: 'password',
        caption: 'password',
        placeholder: 'password',
        validators: {required: true}
      }
    ]
  }
};
