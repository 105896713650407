import {uniqBy} from 'lodash-es';
import ArrayStore from 'devextreme/data/array_store';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {FluxosFichaModalComponent} from '../modals/fluxosficha/fluxos.ficha.modal.component';
import {IDevExpressDataGridEventOnRowClick} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonPRHIndiceFluxo} from '../fluxos.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';

@Component({
  selector: 'colaboradores-fluxos',
  templateUrl: './fluxos.module.component.html'
})
export class FluxosComponent extends ModuloComponent implements OnInit {
  @Input() public indicesFluxos: Array<IJsonPRHIndiceFluxo>;

  public readonly definition: IDevExpressDataGrid<IJsonPRHIndiceFluxo, IJsonPRHIndiceFluxo>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.definition = {
      columns: [{dataField: 'nomeTipo', dataType: 'string', caption: 'indicefluxos.fields.tipo'}],
      allowColumnResizing: false,
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {contextMenuEnabled: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      searchPanel: {visible: false},
      sorting: {mode: 'none'}
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.definition.dataSource = new ArrayStore({
      data: uniqBy(this.indicesFluxos, 'tipo')
    });
  }

  public onDataGridRowClickedTiposFluxos(event: IDevExpressDataGridEventOnRowClick<IJsonPRHIndiceFluxo, IJsonPRHIndiceFluxo>): void {
    const modalInstance = this._cgModalService.showVanilla(FluxosFichaModalComponent);
    const componentInstance: FluxosFichaModalComponent = modalInstance.componentInstance;
    componentInstance.indicesFluxos = this.indicesFluxos.filter((indiceFluxo: IJsonPRHIndiceFluxo) => indiceFluxo.tipo === event.data.tipo);
    componentInstance.tipo = event.data.tipo;
    componentInstance.nomeTipo = event.data.nomeTipo;
  }
}
