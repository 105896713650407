<div class="colaborador-board row">
  <div class="colaborador-board-colaboradores">
    <div class="form-group" *ngIf="servicos.length > 1">
      <div class="d-flex align-items-center mb-2" style="gap: 0.5rem">
        <label class="m-0" for="selectServicos" [translate]="'colaboradores.board.fields.servico'"></label>
        <select id="selectServicos" class="form-select" name="servico" [ngModel]="board.servico" (ngModelChange)="selectServico($event)">
          <option [ngValue]="servico" *ngFor="let servico of servicos">{{ servico.nome }}</option>
        </select>
      </div>
    </div>

    <dx-data-grid
      [cgDxDataGrid]="dataGridColaboradores"
      [dataSource]="board.colaboradoresList"
      cgDxDataGridInstanceName="colaboradoresGrid"
      (onCellClick)="onDataGridColaboradoresCellClick($event)"
      (onSelectionChanged)="onDataGridColaboradoresSelectionChanged($event)">
    </dx-data-grid>
  </div>

  <div class="colaborador-board-dados">
    <div ngbAccordion [closeOthers]="true">
      <div [ngbAccordionItem]="'dados'">
        <div ngbAccordionHeader>
          <button type="button" ngbAccordionButton><i class="fa fa-fw fa-list"></i>&nbsp;<span [translate]="'colaboradores.board.fields.dadosColaborador'"></span></button>
        </div>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <span *ngIf="!board.codEmpSelected" [translate]="'colaboradores.board.msg.nenhumColabSelected'"></span>
              <div class="resumo" *ngIf="board.codEmpSelected">
                <dl>
                  <dt><span [translate]="'colaboradores.board.fields.colaborador'"></span>:</dt>
                  <dd>{{ board.colaboradorDetail.nome }}</dd>
                  <dt><span [translate]="'colaboradores.board.fields.saldoFerias'"></span>:</dt>
                  <dd>{{ board.colaboradorDetail.saldoFerias }}</dd>
                  <dt><span [translate]="'colaboradores.board.fields.feriasGozadas'"></span>:</dt>
                  <dd>{{ board.ferias.feriasIntegradas.length }}</dd>
                  <dt><span [translate]="'colaboradores.board.fields.faltas'"></span>:</dt>
                  <dd>{{ board.faltas.faltasIntegradas.length }}</dd>
                  <dt><span [translate]="'colaboradores.board.fields.horasSemanais'"></span>:</dt>
                  <dd>{{ board.colaboradorDetail.numHorasSemanais }}</dd>
                  <dt><span [translate]="'colaboradores.board.fields.servico'"></span>:</dt>
                  <dd>{{ board.colaboradorDetail.servicoPRH }}</dd>
                  <dt><span [translate]="'colaboradores.board.fields.localTrabalho'"></span>:</dt>
                  <dd>{{ board.colaboradorDetail.codLocalTrabalho }} - {{ board.colaboradorDetail.localTrabalho }}</dd>
                  <dt><span [translate]="'colaboradores.board.fields.tipoHorario'"></span>:</dt>
                  <dd>{{ board.colaboradorDetail.codTipoHorario }} - {{ board.colaboradorDetail.tipoHorario }}</dd>
                  <dt><span [translate]="'colaboradores.board.fields.tipoContrato'"></span>:</dt>
                  <dd>{{ board.colaboradorDetail.codTipoContrato }} - {{ board.colaboradorDetail.tipocontrato }}</dd>
                  <dt><span [translate]="'colaboradores.board.fields.validadeContrato'"></span>:</dt>
                  <dd>{{ board.colaboradorDetail.validadeContrato | cgcDate }}</dd>
                  <dt><span [translate]="'colaboradores.board.fields.centroCusto'"></span>:</dt>
                  <dd>{{ board.colaboradorDetail.nCCusto }} - {{ board.colaboradorDetail.centroCusto }}</dd>
                  <ng-container *ngIf="board.colaboradorDetail.codReparticao">
                    <dt><span [translate]="'colaboradores.board.fields.reparticao'"></span>:</dt>
                    <dd>{{ board.colaboradorDetail.codReparticao }} - {{ board.colaboradorDetail.reparticao }}</dd>
                  </ng-container>
                </dl>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div [ngbAccordionItem]="'ferias'" *ngIf="board.codEmpSelected">
        <div ngbAccordionHeader>
          <button ngbAccordionButton type="button" class="d-flex align-items-center">
            <i class="fa fa-fw fa-picture-o"></i>&nbsp;
            <div [translate]="'colaboradores.board.fields.ferias'"></div>
            &nbsp;
            <div class="badge bg-white">{{ prettyFeriasMarcadas }}</div>
          </button>
        </div>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="row">
                <div class="col-6">
                  <div class="chart-container-doughnut">
                    <dx-pie-chart [cgDxPieChart]="chartFerias" [dataSource]="chartDataFerias"></dx-pie-chart>
                  </div>
                </div>

                <div class="col-6">
                  <div class="chart-container-doughnut">
                    <dx-pie-chart [cgDxPieChart]="chartFeriasGlobal" [dataSource]="chartDataFeriasGlobal"></dx-pie-chart>
                  </div>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col-md-4">
                  <dx-data-grid cgDxDataGridInstanceName="tableFeriasMarcadas" [cgDxDataGrid]="dataGridFeriasMarcadas" [dataSource]="board.ferias.feriasMarcadas"></dx-data-grid>
                </div>

                <div class="col-md-4">
                  <dx-data-grid cgDxDataGridInstanceName="tableFeriasRejeitadas" [cgDxDataGrid]="dataGridFeriasRejeitadas" [dataSource]="board.ferias.feriasRejeitadas"></dx-data-grid>
                </div>

                <div class="col-md-4">
                  <dx-data-grid cgDxDataGridInstanceName="tableFeriasIntegradas" [cgDxDataGrid]="dataGridFeriasIntegradas" [dataSource]="board.ferias.feriasIntegradas"></dx-data-grid>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem id="faltas" *ngIf="board.codEmpSelected">
        <div ngbAccordionHeader>
          <button ngbAccordionButton type="button" class="d-flex align-items-center">
            <i class="fa fa-fw fa-calendar-times-o"></i>&nbsp;
            <div [translate]="'colaboradores.board.fields.faltas'"></div>
            &nbsp;
            <div class="badge bg-white">{{ board.faltas.faltasIntegradas.length }}</div>
          </button>
        </div>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="chart-container-doughnut">
                <dx-pie-chart [cgDxPieChart]="chartFaltas" [dataSource]="chartDataFaltas"></dx-pie-chart>
              </div>

              <hr />

              <div class="row">
                <div class="col-md-4">
                  <dx-data-grid cgDxDataGridInstanceName="tableFaltasMarcadas" [cgDxDataGrid]="dataGridFaltasMarcadas" [dataSource]="board.faltas.faltasMarcadas"></dx-data-grid>
                </div>

                <div class="col-md-4">
                  <dx-data-grid cgDxDataGridInstanceName="tableFaltasRejeitadas" [cgDxDataGrid]="dataGridFaltasRejeitadas" [dataSource]="board.faltas.faltasRejeitadas"></dx-data-grid>
                </div>

                <div class="col-md-4">
                  <dx-data-grid cgDxDataGridInstanceName="tableFaltasIntegradas" [cgDxDataGrid]="dataGridFaltasIntegradas" [dataSource]="board.faltas.faltasIntegradas"></dx-data-grid>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem id="abonos" *ngIf="board.codEmpSelected">
        <div ngbAccordionHeader>
          <button ngbAccordionButton type="button" class="d-flex align-items-center">
            <i class="fa fa-fw fa-calendar-times-o"></i>&nbsp;
            <div [translate]="'colaboradores.board.fields.abonos'"></div>
            &nbsp;
            <div class="badge bg-white">{{ board.abonos.abonosIntegradas.length }}</div>
          </button>
        </div>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="chart-container-doughnut">
                <dx-pie-chart [cgDxPieChart]="chartAbonosGlobal" [dataSource]="chartDataAbonosGlobal"></dx-pie-chart>
              </div>

              <hr />

              <div class="row">
                <div class="col-md-4">
                  <dx-data-grid cgDxDataGridInstanceName="tableAbonosMarcados" [cgDxDataGrid]="dataGridAbonosMarcadas" [dataSource]="board.abonos.abonosMarcadas"></dx-data-grid>
                </div>
                <div class="col-md-4">
                  <dx-data-grid class="pl-table-border" cgDxDataGridInstanceName="tableAbonosRejeitados" [cgDxDataGrid]="dataGridAbonosRejeitadas" [dataSource]="board.abonos.abonosRejeitadas">
                  </dx-data-grid>
                </div>
                <div class="col-md-4">
                  <dx-data-grid cgDxDataGridInstanceName="tableAbonosIntegradas" [cgDxDataGrid]="dataGridAbonosIntegradas" [dataSource]="board.abonos.abonosIntegradas"></dx-data-grid>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
