import {Component, Injector, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'docfaNums-finalizarAt-modal',
  templateUrl: './docfaNumsFinalizarAt.modal.component.html'
})
export class DocfaNumsFinalizarAtModalComponent extends CGModalComponent<string> {
  @Input() public readonly serie: number;
  @Input() public readonly seqUltimoDocEmitido: number;
  public justificacao: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
  }

  public close(): void {
    if (!this.justificacao) {
      this._plAlertService.error(this._translateService.instant('docfasNums.modal.comunicacao.finalizar.erroJustificacaoVazia'));
      return;
    }
    super.close(this.justificacao);
  }
}
