import {Observable} from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export class PlDynamicVisualsActiveReference<T = unknown> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  public close(result?: T): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  public dismiss(reason?: unknown): void {}

  public closed(): Observable<void> {
    return undefined;
  }
}
