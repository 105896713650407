import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonQPMTE} from './jsonQPMTE.entity.interface';
import {ENTITY_NAME_QPMTE} from './qpmte.entity.interface';

export const ENTITY_QPMTE: IEntityDefinition<IJsonQPMTE> = {
  name: ENTITY_NAME_QPMTE,
  roles: [ROLE.RH],
  pageTitle: 'global.menu.qpmte',
  searchPlaceholder: 'qpmte.pesquisa',
  metadata: {
    keyName: 'codmte',
    fields: [
      {name: 'codmte', caption: 'qpmte.fields.codmte', placeholder: 'qpmte.fields.codmte', validators: {required: true, maxlength: 3}},
      {name: 'nome', caption: 'qpmte.fields.nome', placeholder: 'qpmte.fields.nome', validators: {required: true, maxlength: 100}}
    ],
    order: 'codmte',
    searchFields: 'codmte,nome',
    listFields: 'codmte,nome',
    newFields: 'codmte,nome'
  },
  autocomplete: {
    rowTemplate: '{{codmte}} - {{nome}}',
    output: 'nome',
    searchFields: 'codmte,nome'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    search: true,
    delete: false,
    filter: true
  }
};
