import {Component, Injector, OnInit} from '@angular/core';
import {IJsonPrhMenu, IPrhMenu} from '../prhMenus.module.interface';
import {ModuloEntityDetailComponent} from '../../../../../components/module/entitydetail/module.entitydetail.component';

@Component({
  selector: 'colaboradores-menusedit',
  templateUrl: './menusEdit.module.component.html'
})
export class MenuseditComponent extends ModuloEntityDetailComponent<IJsonPrhMenu> implements OnInit {
  public modelExtended: IPrhMenu;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.modelExtended = {
      codMenu: this.model.codMenu,
      titulo: this.model.titulo,
      descricao: this.model.descricao,
      activo: this.model.activo,
      sopa: this._split(this.model.descricao, 0),
      prato: this._split(this.model.descricao, 1),

      sobremesa: this._split(this.model.descricao, 2)
    };
  }

  public changeDescricao(): void {
    this.model.descricao = `${this.modelExtended.sopa} | ${this.modelExtended.prato} | ${this.modelExtended.sobremesa}`;
  }

  private _split(value, nb): string {
    if (!value) {
      return '';
    }
    const array = value.split('|');
    return array[nb];
  }
}
