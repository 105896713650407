import {Injectable, Injector} from '@angular/core';
import {isObject, isString, isUndefined} from 'pl-comps-angular';
import {IModuleMaintenanceInstance} from './module.maintenance.interface';
import {IModule} from '../../../module/module.definition.interface';
import {ModuleMaintenanceInstance} from './module.maintenance.instance';
import {ModuleRegistryService} from '../../../module/module.registry.service';

@Injectable({
  providedIn: 'root'
})
export class ModuleMaintenanceService {
  private readonly _instances: Map<string, IModuleMaintenanceInstance<unknown>>;

  constructor(
    private readonly _injector: Injector,
    private readonly _moduleRegistryService: ModuleRegistryService
  ) {
    this._instances = new Map<string, IModuleMaintenanceInstance<unknown>>();
  }

  public build<T = void>(moduleOrName: string | IModule, strictCheck: boolean = true): IModuleMaintenanceInstance<T> {
    let module: IModule<string>;
    let moduleName: string;
    if (isString(moduleOrName)) {
      moduleName = moduleOrName;
      if (this._instances.has(moduleName)) {
        return <IModuleMaintenanceInstance<T>>this._instances.get(moduleName);
      }
      module = this._moduleRegistryService.get(moduleName);
    } else if (isObject(moduleOrName)) {
      module = moduleOrName;
      if (module?.name) {
        moduleName = module.name;
        if (this._instances.has(moduleName)) {
          return <IModuleMaintenanceInstance<T>>this._instances.get(moduleName);
        }
      }
    }
    if (isUndefined(module)) {
      throw new Error('Provided module or module name is invalid.');
    }
    const instance: IModuleMaintenanceInstance<T> = new ModuleMaintenanceInstance<T>(this._injector, module, strictCheck);
    moduleName = module.name;
    this._instances.set(moduleName, instance);
    return instance;
  }
}
