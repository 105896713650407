import {EScreenSize, getScreenSize, THyperlinkTarget} from './constants';

export type TCGCExpression = string | ICGCExpressionEvaluable | Array<string | ICGCExpressionEvaluable>;

export type TCGCWeakExpression = string | ICGCExpressionEvaluable | Array<string>;

export type TOrientation = 'vertical' | 'horizontal';

export type TValueType = string | 'text' | 'date' | 'datetime' | 'time' | 'boolean' | 'booleangroup' | 'number' | 'integer' | 'radio' | 'radiogroup' | 'select';

export interface ICGCExpressionEvaluable {
  [klass: string]: () => boolean;
}

export interface IPlCompsServiceConfig {
  readonly debug: boolean;
  readonly plEditForm: IPlCompsServiceConfigEditForm;
  readonly plToolbar: IPlCompsServiceConfigToolbar;
}

export interface IPlCompsServiceConfigEditForm {
  readonly orientation: TOrientation;
  readonly modelValueEditTypes: Array<string>;
}

export interface IPlCompsServiceConfigToolbar {
  readonly defaultInstanceId: string;
  readonly defaultHyperlinkTarget: THyperlinkTarget;
  readonly defaultDownloadHyperlinkTarget: THyperlinkTarget;
}

export interface IPlCompsResponsiveTheme<T = string> {
  default?: T;
  small?: T;
  medium?: T;
  large?: T;
  extraLarge?: T;
}

export interface ISerializable {
  toJSON(): string;
}

export const COMMA_SEPARATOR = ',';

export function getResponseScreenSize<T>(responsiveTheme: IPlCompsResponsiveTheme<T>, inherit: boolean = false, screenSize?: EScreenSize): T {
  if (typeof screenSize !== 'number') {
    screenSize = getScreenSize();
  }
  let size: T;
  switch (screenSize) {
    case EScreenSize.DEFAULT:
      size = responsiveTheme.default;
      break;
    case EScreenSize.SMALL:
      size = responsiveTheme.small;
      if (!size && inherit) {
        size = responsiveTheme.default;
      }
      break;
    case EScreenSize.MEDIUM:
      size = responsiveTheme.medium;
      if (!size && inherit) {
        size = responsiveTheme.small;
      }
      break;
    case EScreenSize.LARGE:
      size = responsiveTheme.large;
      if (!size && inherit) {
        size = responsiveTheme.medium;
      }
      break;
    default:
      return responsiveTheme.default;
  }
  if (!size) {
    size = responsiveTheme.default;
  }
  return size;
}
