<div class="faturacaopublica-observacoes-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'faturacaopublica.observacoes.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <edit>
        <label [translate]="'faturacaopublica.observacoes.observacoes'"></label>
        <pl-edit type="textarea" [(model)]="observacoes"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button" plPromise>
    <pl-button klass="btn-success action-save" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

    <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
