<div class="fim-ano">
  <pl-nav-wizard [definition]="definitionNavWizard" [beforeStepChange]="fnBeforeStepChange" [onFinalize]="fnFinalize" (evtStepChange)="stepChanged($event)" [properties]="propertiesNavWizard">
    <pl-nav-wizard-step icon="fa-home" caption="fimano.steps.welcome">
      <div *plNavWizardStepContent class="fim-ano-welcome">
        <h6>
          <small [translate]="'fimano.text.welcome.warning2'"></small>
        </h6>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-file-text-o" caption="fimano.steps.data">
      <div *plNavWizardStepContent class="fim-ano-data">
        <div class="entity-detail-form">
          <pl-form (evtFieldValueChanged)="changedFieldValue()">
            <pl-group>
              <label> <span [translate]="'fimano.text.data.company'"></span>: </label>
              <edit>
                <div class="form-control-align">{{ uiData?.companyName }}</div>
              </edit>
            </pl-group>

            <pl-group>
              <pl-group>
                <label> <span [translate]="'fimano.text.data.accountingPeriod'"></span>: </label>
                <edit>
                  <div class="form-control-align">{{ uiData?.acountingPeriod }}</div>
                </edit>
              </pl-group>

              <pl-group>
                <label> <span [translate]="'fimano.text.data.ircYear'"></span>: </label>
                <edit>
                  <div class="form-control-align">{{ uiData?.ircYear }}</div>
                </edit>
              </pl-group>
            </pl-group>

            <hr />

            <pl-group>
              <label> <span [translate]="'fimano.text.data.yearToClose'"></span>: </label>
              <edit>
                <div class="form-control-align">{{ uiData?.yearToClose }}</div>
              </edit>
            </pl-group>

            <pl-group>
              <label> <span [translate]="'fimano.text.data.nDiario'"></span>: </label>
              <edit>
                <entity-autocomplete
                  entity="diarios"
                  [attrName]="editNameDiario"
                  [selectedKey]="model.nDiario"
                  (selectedKeyChange)="changedDiario($event)"
                  [fieldsMap]="{nDiario: 'nDiario'}"
                  output="key"
                  [properties]="{validators: {required: {value: true}}}">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label> <span [translate]="'fimano.text.data.nDescritivo'"></span>: </label>
              <edit>
                <entity-autocomplete
                  entity="descritivos"
                  [selectedKey]="model.nDescritivo"
                  (selectedKeyChange)="changedDescritivo($event)"
                  [fieldsMap]="{nDescrit: 'nDescritivo'}"
                  output="key"
                  [properties]="{validators: {required: {value: true}}, events: {keydown: fnKeydownAvancar}}">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group *ngIf="usaAgendamento">
              <label for="fim-ano-schedule-for"> <span [translate]="'fimano.text.data.scheduleFor'"></span>: </label>
              <edit>
                <select id="fim-ano-schedule-for" class="form-select" [(ngModel)]="model.agendadoPara" required>
                  <option *ngFor="let schedule of allowedSchedule" [ngValue]="schedule">{{ schedule | cgcTime: 'HH:mm' }}</option>
                </select>
              </edit>
            </pl-group>

            <pl-group>
              <label> <span [translate]="'fimano.text.data.saldaContasClasse9'"></span>: </label>
              <edit>
                <pl-edit type="boolean" [(model)]="model.saldaContasClasse9"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label> <span [translate]="'fimano.text.data.saldaContasClasse0'"></span>: </label>
              <edit>
                <pl-edit type="boolean" [(model)]="model.saldaContasClasse0"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label> <span [translate]="'fimano.text.data.fazTratamentoME'"></span>: </label>
              <edit>
                <pl-edit type="boolean" [(model)]="model.fazTratamentoME"></pl-edit>
              </edit>
            </pl-group>
          </pl-form>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-check" caption="fimano.steps.validations" [validator]="fnValidatorStepValidations">
      <div *plNavWizardStepContent class="fim-ano-validations">
        <div class="mb-3">
          <h6 [translate]="'fimano.text.validations.beforeExecute0'"></h6>
          <h6>
            <em>
              <small [translate]="'fimano.text.validations.beforeExecute1'"></small>
            </em>
          </h6>
        </div>

        <dx-data-grid [cgDxDataGrid]="dataGridDefinition" [dataSource]="validationErrors">
          <div *dxTemplate="let item of 'headerCellTemplate'">
            <div *ngIf="!validated && executing">
              <h6 [translate]="'fimano.text.validations.executing'"></h6>
            </div>

            <div *ngIf="validated && !executing && !validationErrors.length">
              <h6 class="text-success" [translate]="'fimano.text.validations.noErrors'"></h6>
            </div>
          </div>
          <div *dxTemplate="let item of 'cellTemplate'">
            <i class="fa fa-fw fa-exclamation-circle text-danger" aria-hidden="true"></i><span class="fim-ano-error-content">{{ item.data.error }}</span>
          </div>
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-cog" caption="fimano.steps.execute">
      <div *plNavWizardStepContent>
        <h6 [translate]="'fimano.text.execute.text0'"></h6>
        <h6 class="mb-3" [innerHTML]="'fimano.text.execute.text1' | translate"></h6>
        <h6>
          <small [translate]="'fimano.text.execute.doExecute'"></small>
        </h6>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
