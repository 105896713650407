import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {
  IJsonModelo22,
  IJsonModelo22CompDerrama,
  IJsonModelo22ConfigWS,
  IJsonModelo22ContasDef,
  IJsonModelo22Prejuizos,
  IJsonModelo22PrejuizosFiscais,
  IJsonModelo22Rosto,
  IJsonModelo22SubmeterResponse
} from './jsonModelo22.module.interface';
import {IJsonNumber} from '../../../../common/interfaces/json';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class Modelo22Service {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/modelo22`;
  }

  public getModelo22(): TServiceResponse<IJsonModelo22> {
    return this._apiService.get<IJsonModelo22>({
      url: this._path
    });
  }

  public getModelo22Rosto(): TServiceResponse<IJsonModelo22Rosto> {
    return this._apiService.get<IJsonModelo22Rosto>({
      url: `${this._path}/loadrosto`
    });
  }

  public getAnosAnteriores(): TServiceResponse<Array<number>> {
    return this._apiService.get<Array<number>>({
      url: `${this._path}/anosanteriores`
    });
  }

  public checkCalculoDerrama(anodeclaracao: number): TServiceResponse<boolean> {
    return this._apiService.get<boolean>({
      url: `${this._path}/validacalculoderrama`,
      params: {anodeclaracao: anodeclaracao}
    });
  }

  public loadCalculoDerrama(anodeclaracao: number): TServiceResponse<IJsonModelo22CompDerrama> {
    return this._apiService.get<IJsonModelo22CompDerrama>({
      url: `${this._path}/carregacalculoderrama`,
      params: {anodeclaracao: anodeclaracao}
    });
  }

  public getValidateNif(nif: number): TServiceResponse<void> {
    return this._apiService.get({
      url: `${this._path}/validanif`,
      params: {nif: nif}
    });
  }

  public getCamposContasDef(campo: string): TServiceResponse<Array<IJsonModelo22ContasDef>> {
    return this._apiService.get<Array<IJsonModelo22ContasDef>>({
      url: `${this._path}/contasdef`,
      params: {campo: campo}
    });
  }

  public getCamposContasDefAll(): TServiceResponse<Array<IJsonModelo22ContasDef>> {
    return this._apiService.get<Array<IJsonModelo22ContasDef>>({
      url: `${this._path}/contasdefall`
    });
  }

  public getReporContasDef(): TServiceResponse<Array<IJsonModelo22ContasDef>> {
    return this._apiService.get<Array<IJsonModelo22ContasDef>>({
      url: `${this._path}/reporcontasdef`
    });
  }

  public calcNewLineCamposContasDef(ncontade: string, ncontaate: string, perioate: string, sinal: boolean): TServiceResponse<IJsonModelo22ContasDef> {
    return this._apiService.get<IJsonModelo22ContasDef>({
      url: `${this._path}/calcnewlinecontasdef`,
      params: {
        ncontade: ncontade,
        ncontaate: ncontaate,
        perioate: perioate,
        sinal: sinal
      }
    });
  }

  public getModelo22ByYear(ano: number): TServiceResponse<IJsonModelo22> {
    return this._apiService.get<IJsonModelo22>({
      url: `${this._path}/modelo22byyear`,
      params: {ano: ano}
    });
  }

  public prejuizosFiscais(): TServiceResponse<Array<IJsonModelo22PrejuizosFiscais>> {
    return this._apiService.get<Array<IJsonModelo22PrejuizosFiscais>>({
      url: `${this._path}/prejuizosfiscais`
    });
  }

  public calculaModelo22(modelo22: IJsonModelo22): TServiceResponse<IJsonModelo22> {
    return this._apiService.post<IJsonModelo22>({
      url: `${this._path}/calculamodelo22`,
      body: modelo22
    });
  }

  public calculaRosto(rosto: IJsonModelo22Rosto): TServiceResponse<IJsonModelo22Rosto> {
    return this._apiService.post<IJsonModelo22Rosto>({
      url: `${this._path}/calcularosto`,
      body: rosto
    });
  }

  public criarFicheiro(periodo: number): TServiceResponse<Blob> {
    return this._apiService.post<Blob>({
      url: `${this._path}/criarficheiro`,
      params: {
        periodo: periodo
      },
      responseType: 'blob'
    });
  }

  public saveModelo22(modelo22: IJsonModelo22): TServiceResponse<IJsonModelo22> {
    return this._apiService.post<IJsonModelo22>({
      url: `${this._path}/savemodelo22`,
      body: modelo22
    });
  }

  public saveCalculoDerrama(modelo22CompDerrama: IJsonModelo22CompDerrama): TServiceResponse<IJsonModelo22> {
    return this._apiService.post<IJsonModelo22, IJsonModelo22CompDerrama>({
      url: `${this._path}/calculoderrama`,
      body: modelo22CompDerrama
    });
  }

  public postPrejuizosFiscais(prejuizos: IJsonModelo22Prejuizos): TServiceResponse<IJsonModelo22> {
    return this._apiService.post<IJsonModelo22, IJsonModelo22Prejuizos>({
      url: `${this._path}/prejuizosfiscais`,
      body: prejuizos
    });
  }

  public saveCamposContasDef(contasdef: Array<IJsonModelo22ContasDef>, campo: string): TServiceResponse<IJsonNumber> {
    return this._apiService.post<IJsonNumber, Array<IJsonModelo22ContasDef>>({
      url: `${this._path}/guardacamposcontasdef`,
      body: contasdef,
      params: {campo: campo}
    });
  }

  public deleteModelo22(ano: number): TServiceResponse<IJsonModelo22> {
    return this._apiService.delete<IJsonModelo22>({
      url: this._path,
      params: {ano: ano}
    });
  }

  public deletePrejuizoFiscal(anoprejuizo: number, modelo22: IJsonModelo22): TServiceResponse<IJsonModelo22> {
    return this._apiService.post<IJsonModelo22>({
      url: `${this._path}/deleteprejuizosfiscais`,
      body: modelo22,
      params: {anoprejuizo: anoprejuizo}
    });
  }

  public getModelo22AtConfig(): TServiceResponse<IJsonModelo22ConfigWS> {
    return this._apiService.get<IJsonModelo22ConfigWS>({
      url: `${this._path}/at/config`
    });
  }

  public saveModelo22AtConfig(config: IJsonModelo22ConfigWS): TServiceResponse<IJsonModelo22ConfigWS> {
    return this._apiService.post<IJsonModelo22ConfigWS>({
      url: `${this._path}/at/config`,
      body: config
    });
  }

  public validarDeclaracaoAT(periodo: number): TServiceResponse<void> {
    return this._apiService.post<void>({
      url: `${this._path}/at/validar`,
      params: {
        periodo: periodo
      }
    });
  }

  public submeterDeclaracaoAT(periodo: number, force: boolean = false): TServiceResponse<IJsonModelo22SubmeterResponse> {
    return this._apiService.post<IJsonModelo22SubmeterResponse>({
      url: `${this._path}/at/submeter`,
      params: {
        periodo: periodo,
        force: force
      }
    });
  }

  public copiaContasEmpresas(apagacontasempresadestino: boolean, empresas: Array<string>): TServiceResponse<void> {
    return this._apiService.post<void, Array<string>>({
      url: `${this._path}/copiacontasempresas`,
      body: empresas,
      params: {
        apagacontasdestino: apagacontasempresadestino
      }
    });
  }

  public evaluateModelo22(modelo22: IJsonModelo22): TServiceResponse<IJsonModelo22> {
    return this._apiService.post<IJsonModelo22>({
      url: `${this._path}/evaluatemodelo22`,
      body: modelo22
    });
  }
}
