import {NgModule} from '@angular/core';
import {PlPageWrapperComponent} from './pagewrapper.component';
import {CommonModule} from '@angular/common';
import {PlPageWrapperContainerComponent} from './components/pagewrapper.container.component';
import {PlPageWrapperNavbarComponent} from './components/pagewrapper.navbar.component';
import {PlPageWrapperSidebarComponent} from './components/pagewrapper.sidebar.component';

export * from './pagewrapper.service';
export * from './pagewrapper.component';
export * from './components/pagewrapper.container.component';
export * from './components/pagewrapper.navbar.component';
export * from './components/pagewrapper.sidebar.component';

const DECLARATIONS = [
  PlPageWrapperComponent,
  PlPageWrapperContainerComponent,
  PlPageWrapperNavbarComponent,
  PlPageWrapperSidebarComponent
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlPageWrapperModule {
}
