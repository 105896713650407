import {animate, AnimationTriggerMetadata, style, transition, trigger} from '@angular/animations';

export function toastAnimation(slideInOutDuration: string, slideUpDuration: string): AnimationTriggerMetadata {
  return trigger('animation', [
    transition(':enter', [style({transform: 'translateX(100%)'}), animate(`${slideInOutDuration} ease`)]),
    transition(':leave', [
      animate(`${slideInOutDuration} ease`, style({transform: 'translateX(100%)'})),
      style({visibility: 'hidden'}),
      animate(`50ms ${slideUpDuration} ease-out`, style({marginTop: '-{{toastSize}}'}))
    ])
  ]);
}
