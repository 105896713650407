import {ENTITY_NAME_ERPS} from './erps.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonErp} from '../../interfaces/jsonUserManager.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_ERPS: IEntityDefinition<IJsonErp> = {
  name: ENTITY_NAME_ERPS,
  url: 'manager/erps',
  roles: [ROLE.ADMIN, ROLE.ERP],
  searchPlaceholder: 'erps.pesquisa',
  pageTitle: 'global.menu.erps',
  metadata: {
    keyName: 'centralGestId',
    fields: [
      {name: 'centralGestId', caption: 'erps.fields.centralGestId'},
      {name: 'apiUrl', caption: 'erps.fields.apiUrl'},
      {name: 'name', caption: 'erps.fields.name'}
    ],
    order: 'centralGestId',
    searchFields: ''
  },
  autocomplete: {
    rowTemplate: '{{name}} - {{apiUrl}}',
    output: 'name',
    searchFields: 'name,apiUrl'
  },
  actions: {
    new: false,
    edit: false,
    detail: false,
    delete: false,
    search: false
  }
};
