import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {copy, elementIndex, IPlEditComponentOptionsInputNumber, isArray, isEmpty, isEmptyObject, isObject, isString, KEYCODES, PlAlertService} from 'pl-comps-angular';
import {Subscription} from 'rxjs';
import {focusElement} from '../../../../../common/utils/element.utils';
import {IEntityEvaluationMethods} from '../../../../components/entity/entity.definition.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {EGroupName} from '../../../../../config/constants';
import moment from 'moment';
import {ConfigOptionsService} from '../../../../services/config/options/config.options.service';
import {EConfigOptionsInstanceName, IContratosConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonClifo} from '../../../clifos/jsonClifo.entity.interface';
import {IJsonArtigo} from '../../../artigos/jsonArtigo.entity.interface';
import {EProcAutoTipo, IJsonClasseContrato} from '../../../classescontratos/jsonClasseContrato.entity.interface';
import {IJsonContratoTipo} from '../../../contratostipos/jsonContratoTipo.entity.interface';
import {IJsonIva} from '../../../ivas/jsonIva.entity.interface';
import {IJsonContrato, IJsonContratoLin} from '../../jsonContrato.entity.interface';
import {ENTITY_NAME_CONTRATOS_TIPOS} from '../../../contratostipos/contratosTipos.entity.interface';

const VIRTUAL_SCROLL_ROWS = 10;
const VIRTUAL_SCROLL_ROWS_AHEAD = 2;
const VIRTUAL_SCROLL_HEADER_HEIGHT = 29.8; // This value must be checked manually (use inspector and check row height's pxs)
const VIRTUAL_SCROLL_ROW_HEIGHT = 39.84; // This value must be checked manually (use inspector and check row height's pxs)
const VIRTUAL_SCROLL_MIN_BUFFER_PX = VIRTUAL_SCROLL_ROW_HEIGHT * VIRTUAL_SCROLL_ROWS_AHEAD;
const VIRTUAL_SCROLL_MAX_BUFFER_PX = VIRTUAL_SCROLL_ROW_HEIGHT * (VIRTUAL_SCROLL_ROWS_AHEAD * VIRTUAL_SCROLL_ROWS_AHEAD);

@Component({
  selector: 'contratos-edit',
  templateUrl: './contratos.entity.edit.component.html'
})
export class ContratosEditComponent extends ModuloEntityDetailComponent<IJsonContrato> implements OnInit, OnDestroy {
  @Input() public classecontrato: IJsonClasseContrato;
  @Input() public contratotipo: IJsonContratoTipo;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly virtualScrollRows: number;
  public readonly virtualScrollHeaderHeight: number;
  public readonly virtualScrollRowHeight: number;
  public readonly virtualScrollMinBufferPx: number;
  public readonly virtualScrollMaxBufferPx: number;
  public readonly virtualScrollHeightPx: number;
  public readonly propertiesEditDecimalsValoresUnitariosDisabledLimit: IPlEditComponentOptionsInputNumber;
  public readonly evaluationMethods: IEntityEvaluationMethods<IJsonContrato>;
  public propertiesEditDecimalsQtdLimit: IPlEditComponentOptionsInputNumber;
  public propertiesEditDecimalsValoresLinhaLimit: IPlEditComponentOptionsInputNumber;
  public propertiesEditDecimalsValoresUnitariosLimit: IPlEditComponentOptionsInputNumber;
  public filterTipoContrato: string;
  public optionShowGrupoConta: boolean;
  public optionShowMoeda: boolean;
  public optionShowCondComerciais: boolean;
  public optionShowRefExterna: boolean;
  public optionShowVendedor: boolean;
  public optionShowNCCusto: boolean;
  public optionShowD1: boolean;
  public optionShowArmazem: boolean;
  public optionShowUnidadeMovimento: boolean;
  public codMoedaEmpresa: number;
  public abreviaturaMoedaEmpresa: string;
  public totalContrato: number;
  public preventAutoFocus: boolean;

  private readonly _serviceTipoContrato: IEntityService<IJsonContratoTipo>;
  private readonly _subscriptionConfigOptions: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _configOptionsService: ConfigOptionsService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);

    this.configOptionsInstanceName = EConfigOptionsInstanceName.CONTRATOS;
    this.configOptionsGroupName = EGroupName.ERP;

    this.virtualScrollRows = VIRTUAL_SCROLL_ROWS;
    this.virtualScrollHeaderHeight = VIRTUAL_SCROLL_HEADER_HEIGHT;
    this.virtualScrollRowHeight = VIRTUAL_SCROLL_ROW_HEIGHT;
    this.virtualScrollMinBufferPx = VIRTUAL_SCROLL_MIN_BUFFER_PX;
    this.virtualScrollMaxBufferPx = VIRTUAL_SCROLL_MAX_BUFFER_PX;
    this.virtualScrollHeightPx = VIRTUAL_SCROLL_ROWS + VIRTUAL_SCROLL_HEADER_HEIGHT + VIRTUAL_SCROLL_ROWS * VIRTUAL_SCROLL_ROW_HEIGHT;

    this.propertiesEditDecimalsValoresUnitariosDisabledLimit = {disabled: true, decimalsLimit: this._configService.configurations.gestaoComercial.decimais.valoresUnitarios};

    this.evaluationMethods = {evaluateId: (contrato: IJsonContrato) => contrato.cab.refCtrCab};
    this.filterTipoContrato = '';
    this.codMoedaEmpresa = this._configService.configurations.empresa.codMoeda;
    this.abreviaturaMoedaEmpresa = this._configService.configurations.empresa.abreviaturaMoeda;
    this.preventAutoFocus = true;

    this._serviceTipoContrato = this._entityServiceBuilder.build<IJsonContratoTipo, IEntityService<IJsonContratoTipo>>(ENTITY_NAME_CONTRATOS_TIPOS);

    this._subscriptionConfigOptions = this._configOptionsService
      .getGroupOptions(this.configOptionsGroupName)
      .get(this.configOptionsInstanceName)
      .options()
      .subscribe((configOptions: TConfigOptions<boolean, IContratosConfigOptions>) => {
        this.optionShowGrupoConta = configOptions.get('showGrupoConta').value;
        this.optionShowMoeda = configOptions.get('showMoeda').value;
        this.optionShowCondComerciais = configOptions.get('showCondComerciais').value;
        this.optionShowRefExterna = configOptions.get('showRefExterna').value;
        this.optionShowVendedor = configOptions.get('showVendedor').value;
        this.optionShowNCCusto = configOptions.get('showNCCusto').value;
        this.optionShowD1 = configOptions.get('showD1').value;
        this.optionShowArmazem = configOptions.get('showArmazem').value;
        this.optionShowUnidadeMovimento = configOptions.get('showUnidadeMovimento').value;
      });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.propertiesEditDecimalsQtdLimit = {decimalsLimit: this._configService.configurations.gestaoComercial.decimais.quantidades, disabled: this.type === this.stateTypes.DETAIL};
    this.propertiesEditDecimalsValoresLinhaLimit = {decimalsLimit: this._configService.configurations.gestaoComercial.decimais.valoresLinha, disabled: this.type === this.stateTypes.DETAIL};
    this.propertiesEditDecimalsValoresUnitariosLimit = {decimalsLimit: this._configService.configurations.gestaoComercial.decimais.valoresUnitarios, disabled: this.type === this.stateTypes.DETAIL};
    if (!isObject(this.model) || isEmptyObject(this.model)) {
      this.model = this._emptyContrato();
      this.totalContrato = 0;
    }
    if (!this.contratotipo) {
      this.contratotipo = {
        id: '',
        nClasseCtr: '',
        classeTipo: 0,
        nTipoContrato: '',
        nome: '',
        periocidade: 0,
        nDocfa: 0,
        nNumer: 0,
        nomeClasse: '',
        nomeDocfa: '',
        processaDiferimento: false,
        faturacaoNoMes: 0,
        txtContrato: '',
        procAutoEnviaMail: false,
        procAutoDiaFixo: 0,
        proAutoEmailResponsavel: '',
        procAutoTipo: EProcAutoTipo.None
      };
    } else {
      this._calculaTotalContrato(this.model);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigOptions.unsubscribe();
  }

  public save(): Promise<IJsonContrato> {
    const model: IJsonContrato = copy(this.model);
    this._deleteEmptyLines(model);
    return super.save({body: model});
  }

  public onClasseContratoChanged(classeContrato: IJsonClasseContrato): void {
    this.classecontrato = classeContrato;
    this.model.cab.nClasseCtr = this.classecontrato?.nClasseCtr;
    this.model.cab.classeCtrNome = this.classecontrato?.nomeClasseCtr;
    this.filterTipoContrato = this.classecontrato ? `nClasseCtr=${this.classecontrato.nClasseCtr}&classetipo=${this.classecontrato.classeTipo}` : '';
  }

  public onTipoContratoChanged(tipoContrato: IJsonContratoTipo): void {
    this.contratotipo = tipoContrato;
    this.model.cab.nTipoContrato = this.contratotipo?.nTipoContrato;
    this.model.cab.tipoContratoNome = this.contratotipo?.nome;
  }

  public onClifoChanged(clifo: IJsonClifo): void {
    this.model.cab.idIdeChav = clifo?.idIdeChav;
    this.model.cab.idideDescricao = clifo?.idIdeDescricao;
    this.model.cab.codMoeda = clifo?.codMoeda;
    this.model.cab.moedaNome = clifo?.nomeMoeda;
    this.model.cab.codCondComerciais = clifo?.codCondComerciais;
    this.model.cab.condComerciaisNome = clifo?.nomeCondComerciais;
    this.model.cab = {...this.model.cab};
  }

  public checkLine(event: KeyboardEvent, next: string): boolean {
    if (event.key !== KEYCODES.DOWN || !next) {
      return false;
    }
    const nextElement: HTMLElement = document.querySelector<HTMLElement>(next);
    const rowIndex: number = elementIndex(nextElement.closest('tr'));
    if (rowIndex !== this.model.linhas.length - 1) {
      return false;
    }
    const linha: IJsonContratoLin = this.model.linhas[rowIndex];
    if (!isEmpty(linha.nArtigo)) {
      return false;
    }
    this._setFocusNArtigo(rowIndex + 1);
    if (this.preventAutoFocus) {
      this.preventAutoFocus = false;
    }
    return true;
  }

  public artigoChanged(artigo: IJsonArtigo, item: IJsonContratoLin): void {
    if (!artigo || artigo.artBloqueado || artigo.artDesactivado) {
      item.nArtigo = undefined;
      item.nomeArtigo = undefined;
      if (artigo && (artigo.artBloqueado || artigo.artDesactivado)) {
        this._plAlertService.error(this._translateService.instant('contratos.errors.artigoBloqueadoDesativado', {nArtigo: artigo.nArtigo}));
      }
    } else {
      item.nArtigo = artigo.nArtigo;
      item.nomeArtigo = artigo.nome;
      item.prVenda = artigo.precoSemIva;
      item.codIva = artigo.codIvaVenda;
      item.cunimo = artigo.unidadeMov;
    }
    this._recalculaLiquido(item);
  }

  public addLine(): void {
    if (this.model.linhas.length > 0 && !this.model.linhas[this.model.linhas.length - 1].nArtigo) {
      return;
    }

    this.model.linhas.push(this._emptyLine());
    this.model.linhas = this.model.linhas.slice();
  }

  public remove(index: number): void {
    this.model.linhas.splice(index, 1);
    if (!this.model.linhas.length) {
      this.addLine();
    } else {
      this.model.linhas = this.model.linhas.slice();
    }
    this._calculaTotalContrato(this.model);
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public onQdt1Changed(value: number, item: IJsonContratoLin): void {
    item.qtd1 = value;
    this._recalculaLiquido(item);
  }

  public onPrVendaChanged(value: number, item: IJsonContratoLin): void {
    item.prVenda = value;
    this._recalculaLiquido(item);
  }

  public onD1Changed(value: number, item: IJsonContratoLin): void {
    item.d1 = value;
    this._recalculaLiquido(item);
  }

  public codIvaChanged(iva: IJsonIva, item: IJsonContratoLin): void {
    item.taxaIva = iva.taxaActual;
    this._recalculaLiquido(item);
  }

  public readonly fnAddLine = (): void => {
    if (this.preventAutoFocus) {
      this.preventAutoFocus = false;
    }
    this.addLine();
  };

  public readonly fnCheckLine = (event: KeyboardEvent, next: string): boolean => this.checkLine(event, next);

  public readonly fnOnTipoContratoValidate = (id: string, item: string | IJsonContratoTipo): Promise<IJsonContratoTipo> => this._onTipoContratoValidate(id, item);

  private _setFocusNArtigo(nline?: number): void {
    const selector: string = nline ? `.table-artigos tr:nth-child(${nline}) entity-autocomplete[entity="artigos"] input` : '.table-artigos input[name="artigo"]';
    focusElement(this._element.querySelector<HTMLInputElement>(selector));
  }

  private _emptyLine(): IJsonContratoLin {
    return {
      refCtrLin: 0,
      nSeq: 0,
      nArtigo: '',
      nomeArtigo: '',
      qtd1: 1,
      prVenda: 0,
      d1: 0,
      d2: 0,
      d3: 0,
      desconto: 0,
      d4: 0,
      d5: 0,
      d6: 0,
      codIva: 0,
      nArmazem: 0,
      cunimo: 0,
      qtdPUnid: 0,
      nVendedor: 0,
      ccusto: '',
      nZona: 0,
      nDepto: 0,
      nSubde: '',
      qtd1Fact: 0,
      nRefProcesso: '',
      estado: '',
      obs: '',
      valorLiquido: 0,
      taxaIva: 0,
      nArmazem2: 0
    };
  }

  private _emptyContrato(): IJsonContrato {
    const now = moment();
    const endDate = now.clone().endOf('year');
    return {
      cab: {
        refCtrCab: 0,
        nClasseCtr: '',
        nTipoContrato: '',
        nContrato: 0,
        verContrato: 0,
        descricao: '',
        nClifo: '',
        idIdeChav: '',
        dataCtr: now,
        dataInicio: now,
        dataFim: endDate,
        estado: '',
        terminado: false,
        totalLiquido: 0,
        valorGlobal: 0,
        ccusto: '',
        nRefProcesso: '',
        codMoeda: this.codMoedaEmpresa,
        refExterna: '',
        codCondComerciais: 0
      },
      linhas: [this._emptyLine()]
    };
  }

  private _onTipoContratoValidate(id: string, item: string | IJsonContratoTipo): Promise<IJsonContratoTipo> {
    if (isEmpty(item)) {
      return Promise.resolve(undefined);
    }
    id = isObject(item) ? (<IJsonContratoTipo>item).id : isString(item) ? `${this.model.cab.nClasseCtr}_${item}` : `${this.model.cab.nClasseCtr}_${id}`;
    return this._serviceTipoContrato.get({id: id, reportExceptions: false}).then((response: HttpResponse<IJsonContratoTipo>) => response.body);
  }

  private _deleteEmptyLines(contrato: IJsonContrato): void {
    for (let i = contrato.linhas.length - 1; i >= 0; i--) {
      const linha: IJsonContratoLin = contrato.linhas[i];
      if (!linha.nArtigo) {
        contrato.linhas.splice(i, 1);
      }
    }
  }

  private _recalculaLiquido(linha: IJsonContratoLin): void {
    linha.valorLiquido = linha.qtd1 * linha.prVenda * (1 - linha.d1 / 100) - linha.desconto;
    this._calculaTotalContrato(this.model);
  }

  private _calculaTotalContrato(contrato: IJsonContrato): void {
    this.totalContrato = 0;
    if (isArray(contrato?.linhas)) {
      for (const item of contrato.linhas) {
        this.totalContrato += item.valorLiquido;
      }
    }
  }
}
