import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {HookMatchCriteria, StateDeclaration, StateObject, Transition, TransitionService} from '@uirouter/core';
import {ModuloComponent} from '../../../../components/module/module.component';
import {IPlToolbarMenuItem, isBoolean, isEmpty, PlAlertService, TPlTableItem} from 'pl-comps-angular';
import {AtivosNewComponent} from '../../../../entities/ativos/components/new/ativos.entity.new.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ENTITY_NAME_ATIVOS, IAtivosNewStateParams} from '../../../../entities/ativos/interfaces/ativos.entity.interface';
import {IEntityMaintenanceInstance, IEntityMaintenanceOnMaintenanceSave} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {IJsonAtivos} from '../../../../entities/ativos/interfaces/jsonAtivos.entity.interface';
import {isTest} from '../../../../../config/constants';
import {STATE_NAME_DISCONNECTED} from '../../../../states/account/disconnected/disconnected.state.interface';
import {STATE_NAME_LOGIN} from '../../../../states/account/login/login.state.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowRemoved
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonAquisicaoBeforeNovoAtivo} from '../../ativosaquisicaocontab/jsonAtivosAquisicaoContab.module.interface';
import {IAquisicaoContaDeInvestEmCursoPorDocParams, IAquisicaoInvestEmCursoPorDocItem} from '../ativosAquisicaoInvestEmCursoPorDoc.module.interface';
import {IJsonAquisicaoInvestEmCursoPorDoc, IJsonAquisicaoInvestEmCursoPorDocResumo} from '../jsonAtivosAquisicaoInvestEmCursoPorDoc.module.interface';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {IJsonAtivosconfiguracoes} from '../../ativosconfiguracoes/jsonAtivosConfiguracoes.module.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocContabilidade, IDocContabilidadeLinha, IDocsContabilidadeEntityService} from '../../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {AtivosAquisicaoInvestEmCursoPorDocService} from '../ativosAquisicaoInvestEmCursoPorDoc.module.service';
import {IJsonDocContabilidade} from '../../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {MODULE_NAME_ATIVOS_CONFIGURACOES} from '../../ativosconfiguracoes/ativosConfiguracoes.module.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {Properties} from 'devextreme/ui/button';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {IAtivoAquisicaoPesquisaContab} from '../../ativosaquisicaocontab/ativosAquisicaoContab.module.interface';
import {AtivosAquisicaoInvestEmCursoPorDocContabModalComponent} from '../modal/contab/ativosAquisicaoInvestEmCursoPorDoc.contab.modal.component';

@Component({
  selector: 'module-ativos-aquisicao-invest-em-curso',
  templateUrl: './ativosAquisicaoInvestEmCursoPorDoc.module.component.html'
})
export class AtivosAquisicaoInvestEmCursoPorDocModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly dataGridDefinitionDocumentos: IDevExpressDataGrid<IAquisicaoInvestEmCursoPorDocItem, string>;
  public readonly dataGridDefinitionAtivosDocumentos: IDevExpressDataGrid<IJsonAtivos, string>;

  public beforeNovoAtivoconstDocumentos: IJsonAquisicaoBeforeNovoAtivo;
  public aquisicaoInvestEmCursoPorDocDocumentos: IJsonAquisicaoInvestEmCursoPorDoc;
  public anoEmCurso: number;

  private readonly _entityMaintenanceInstanceAtivos: IEntityMaintenanceInstance<IJsonAtivos>;
  private readonly _moduleMaintenanceAtivosConfigDocumentos: IModuleMaintenanceInstance<IJsonAtivosconfiguracoes>;
  private readonly _serviceDocsContabilidadeDocumentos: IDocsContabilidadeEntityService;
  private readonly _params: IAquisicaoContaDeInvestEmCursoPorDocParams;

  private _deRegisterOnStartFn: Function;
  private _deRegisterOnStartFnDocumentos: Function;
  private _btnSearch: IPlToolbarMenuItem;
  private _dataGridInstanceDocumentos: dxDataGrid<IAquisicaoInvestEmCursoPorDocItem, string>;
  private _dataGridInstanceAtivosDocumentos: dxDataGrid<IJsonAtivos, string>;
  private _nLancsListDocumentos: Array<string>;
  private _hasContabilidadeDigital: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _ativosAquisicaoInvestEmCursoPorDocumentoService: AtivosAquisicaoInvestEmCursoPorDocService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _transitionService: TransitionService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._serviceDocsContabilidadeDocumentos = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._moduleMaintenanceAtivosConfigDocumentos = this._moduleMaintenanceService.build(MODULE_NAME_ATIVOS_CONFIGURACOES);
    this._entityMaintenanceInstanceAtivos = this._entityMaintenanceService.build(ENTITY_NAME_ATIVOS);
    this.dataGridDefinitionAtivosDocumentos = {
      keyExpr: 'codAtivo',
      columns: [
        {dataField: 'codAtivo', dataType: 'string', caption: 'ativosaquisicaoinvestemcursopordoc.fields.codAtivo'},
        {dataField: 'designacao', dataType: 'string', caption: 'ativosaquisicaoinvestemcursopordoc.fields.designacao', width: 200},
        {dataField: 'aquisicao.nContaDoAtivo', dataType: 'string', caption: 'ativosaquisicaoinvestemcursopordoc.fields.nContaDoAtivo'},
        {dataField: 'aquisicao.contabValorAquisicao', dataType: 'double', caption: 'ativosaquisicaoinvestemcursopordoc.fields.contabValorAquisicao'}
      ],
      columnHidingEnabled: false,
      editing: {allowDeleting: true},
      export: {filename: 'ativosaquisicaoinvestemcursopordoc.ativos'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'add',
              hint: this._translateService.instant('global.btn.addLine'),
              onClick: () => this._novoAtivoDocumentos()
            } satisfies Properties
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionDocumentos = {
      keyExpr: 'nLanc',
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'ativosaquisicaocontab.table.nConta'},
        {dataField: 'periodo', dataType: 'string', caption: 'ativosaquisicaocontab.table.periodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'ativosaquisicaocontab.table.nDiario'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'ativosaquisicaocontab.table.nDocInterno'},
        {dataField: 'dCDescription', dataType: 'string', caption: 'ativosaquisicaocontab.table.dCDescription'},
        {dataField: 'valor', dataType: 'double', caption: 'ativosaquisicaocontab.table.contabValorAquisicao'}
      ],
      columnHidingEnabled: false,
      editing: {allowDeleting: true, confirmDelete: false},
      export: {filename: 'ativosaquisicaoinvestemcursopordoc.listadocs'},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocContabilidade'},
      remoteOperations: false
    };
    this._emptyDadosDocumentos();
    this.beforeNovoAtivoconstDocumentos = {
      extPocCabID: '',
      nContaAtivo: '',
      lastCodAtivo: 0,
      valorAquisicao: 0
    };
    this._nLancsListDocumentos = [];
    this.anoEmCurso = this._configService.configurations.empresa.anoEmCursoIRC;
    this._params = <IAquisicaoContaDeInvestEmCursoPorDocParams>this._transition.params();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._hasContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
    this._btnSearch = {
      id: 'pesquisa',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-search fa-fw"></i>',
      caption: 'global.btn.search',
      class: 'btn-primary'
    };
    this._buildToolbarInitBtns();
    if (this._params?.doSearch) {
      this._openModalDocsAquisicaoInvestEmCursoDocumentos();
    }

    setTimeout(() => {
      this._registerOnStart();
      this._registerOnStartDocumentos();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._deRegisterOnStart();
    this._deRegisterOnStartDocumentos();
  }

  public onInitializedAtivosDocumentos({component}: IDevExpressDataGridEventOnInitialized<IJsonAtivos, string>): void {
    this._dataGridInstanceAtivosDocumentos = component;
  }

  public onRowRemovedAtivosDocumentos(): void {
    this._changeSaveDisable(!this.aquisicaoInvestEmCursoPorDocDocumentos.listaAtivos.length);
    this._updateContaDeInvestEmCursoCabDocumentos(this.aquisicaoInvestEmCursoPorDocDocumentos.listaAtivos);
  }

  public onInitializedDocumentos({component}: IDevExpressDataGridEventOnInitialized<IAquisicaoInvestEmCursoPorDocItem, string>): void {
    this._dataGridInstanceDocumentos = component;
  }

  public onCellClickDocumentos(event: IDevExpressDataGridEventOnCellClick<IAquisicaoInvestEmCursoPorDocItem, string>): void {
    if (event.rowType !== 'data') {
      return;
    }
    devExpressDataGridExpandDetailHandler(event, () =>
      this._serviceDocsContabilidadeDocumentos.get({id: event.data.extPocCabID}).then((response: HttpResponse<IJsonDocContabilidade>) => {
        event.data._docContabilidade = response.body;
        if (this._hasContabilidadeDigital) {
          event.data._contabDigitalDoc = {
            extPocCabID: response.body.extPocCabID,
            periodo: response.body.periodo,
            nDiario: response.body.nDiario,
            nDocInterno: response.body.nDocInterno,
            dataDoc: response.body.dataDoc
          };
        }
      })
    );
  }

  public onRowRemovedDocumentos(event: IDevExpressDataGridEventOnRowRemoved<IAquisicaoInvestEmCursoPorDocItem, string>): void {
    for (const documento of this.aquisicaoInvestEmCursoPorDocDocumentos.listaDocumentos.slice().reverse()) {
      if (event.data.extPocCabID === documento.extPocCabID) {
        const rowIndex: number = this._dataGridInstanceDocumentos.getRowIndexByKey(documento.nLanc);
        if (rowIndex !== -1) {
          this.aquisicaoInvestEmCursoPorDocDocumentos.listaDocumentos.splice(rowIndex, 1);
        }
      }
    }
    this._evaluateCalcResumoDocumentos(this.aquisicaoInvestEmCursoPorDocDocumentos);
    if (!this.aquisicaoInvestEmCursoPorDocDocumentos.listaDocumentos.length) {
      this._evaluateResumoDocumentos(undefined);
    }
  }

  public actionDocumentoDeletedDocumentos(documento: IDocContabilidade): void {
    const removeLinhas: Array<IAquisicaoInvestEmCursoPorDocItem> = [];
    const linhaKeys: Array<string> = documento.linhas.map((linha: IDocContabilidadeLinha) => linha.nLanc);
    linhaKeys.forEach((linhaKey: string) => {
      this.aquisicaoInvestEmCursoPorDocDocumentos.listaDocumentos
        .filter((documentoItem: IAquisicaoInvestEmCursoPorDocItem) => documentoItem.nLanc === linhaKey)
        .forEach((documentoItem: IAquisicaoInvestEmCursoPorDocItem) => {
          if (!removeLinhas.includes(documentoItem)) {
            removeLinhas.push(documentoItem);
          }
        });
    });

    removeLinhas
      .slice()
      .reverse()
      .forEach((item: IAquisicaoInvestEmCursoPorDocItem) => {
        const rowIndex: number = this._dataGridInstanceDocumentos.getRowIndexByKey(item.nLanc);
        if (rowIndex !== -1) {
          this.aquisicaoInvestEmCursoPorDocDocumentos.listaDocumentos.splice(rowIndex, 1);
        }
      });
  }

  private _openModalDocsAquisicaoInvestEmCursoDocumentos(): Promise<void> {
    let promise: Promise<void>;
    this._btnSearch.promise = promise;
    this.btnSave.promise = promise;

    promise = this._ativosAquisicaoInvestEmCursoPorDocumentoService
      .pesquisaAquisicoesInvestEmCurso(false)
      .then((response: HttpResponse<Array<IAtivoAquisicaoPesquisaContab>>) => {
        const docsAdicionados: Array<IAquisicaoInvestEmCursoPorDocItem> = this._dataGridInstanceDocumentos.getDataSource().items();
        const modalInstance = this._cgModalService.showVanilla(AtivosAquisicaoInvestEmCursoPorDocContabModalComponent);
        const componentInstance: AtivosAquisicaoInvestEmCursoPorDocContabModalComponent = modalInstance.componentInstance;
        componentInstance.aquisicaoContabList = response.body;
        componentInstance.docsList = this.aquisicaoInvestEmCursoPorDocDocumentos.listaDocumentos;
        componentInstance.radical = this.aquisicaoInvestEmCursoPorDocDocumentos.resumo.radical;
        componentInstance.nLancsList = this._nLancsListDocumentos;
        componentInstance.docsAddedList = docsAdicionados.filter((doc: TPlTableItem<IAquisicaoInvestEmCursoPorDocItem>) => {
          return !isBoolean(doc.empty) || !doc.empty;
        });

        return modalInstance.result.then((resAddDoc: IJsonAquisicaoInvestEmCursoPorDoc) => {
          this._nLancsListDocumentos = resAddDoc.listaDocumentos.map((documento: IAquisicaoInvestEmCursoPorDocItem) => documento.nLanc);
          this.aquisicaoInvestEmCursoPorDocDocumentos.resumo = resAddDoc.resumo;
          this._applyDataSourceDocumentos(resAddDoc.listaDocumentos);
          this._applyDataSourceAtivosDocumentos([]);
        });
      })
      .finally(() => {
        promise = undefined;
      });
    return promise;
  }

  private async _saveAtivosDocumentos(): Promise<void> {
    await this._cgModalService.showOkCancel('global.text.confirmation', 'ativosaquisicaoinvestemcursopordoc.prompt.desejaregistaraquisicao');
    this._dataGridInstanceAtivosDocumentos.beginCustomLoading(undefined);
    try {
      await this._ativosAquisicaoInvestEmCursoPorDocumentoService.saveAtivosAquisicao(this.aquisicaoInvestEmCursoPorDocDocumentos);
      this._evaluateResumoDocumentos(undefined);
      this._applyDataSourceDocumentos([]);
      this._applyDataSourceAtivosDocumentos([]);
      this._plAlertService.success('ativosaquisicaoinvestemcursopordoc.prompt.ativoguardado');
    } finally {
      this._dataGridInstanceAtivosDocumentos.endCustomLoading();
    }
  }

  private async _novoAtivoDocumentos(): Promise<void> {
    // check if user can add ativos
    if (isEmpty(this.aquisicaoInvestEmCursoPorDocDocumentos.resumo.radical)) {
      this._plAlertService.warning('ativosaquisicaoinvestemcursopordoc.messages.docsEmpty');
      return;
    }

    const response: HttpResponse<IJsonAtivos> = await this._ativosAquisicaoInvestEmCursoPorDocumentoService.beforeNovoAtivo(this.aquisicaoInvestEmCursoPorDocDocumentos);
    const params: IAtivosNewStateParams = {
      defaultData: response.body,
      contabValorAquisicao: response.body.aquisicao.contabValorAquisicao.toString(),
      docExtPocCabId: '',
      fromInvestimentosEmCurso: true,
      valorInvEmCursoDisponivelAnoN: String(this.aquisicaoInvestEmCursoPorDocDocumentos.resumo.valorRadical - this.aquisicaoInvestEmCursoPorDocDocumentos.resumo.jaAssociado),
      valorInvEmCursoDisponivelAnoN1: String(0)
    };
    await this._entityMaintenanceInstanceAtivos.maintenanceNew({params: params, onMaintenanceSave: this._fnAdicionaAtivoDocumentos});
  }

  private _adicionaAtivoDocumentos({config}: IEntityMaintenanceOnMaintenanceSave<IJsonAtivos, AtivosNewComponent>): Promise<IJsonAtivos> {
    this._dataGridInstanceAtivosDocumentos.beginCustomLoading(undefined);
    return Promise.resolve()
      .then(() => {
        this.aquisicaoInvestEmCursoPorDocDocumentos.listaAtivos.push(config.body);
        this._applyDataSourceAtivosDocumentos(this.aquisicaoInvestEmCursoPorDocDocumentos.listaAtivos);
        return undefined;
      })
      .finally(() => {
        this._dataGridInstanceAtivosDocumentos.endCustomLoading();
      });
  }

  private _updateContaDeInvestEmCursoCabDocumentos(listaAtivos: Array<IJsonAtivos>): void {
    this.aquisicaoInvestEmCursoPorDocDocumentos.resumo.jaAssociado = 0;
    for (const ativo of listaAtivos) {
      this.aquisicaoInvestEmCursoPorDocDocumentos.resumo.jaAssociado += ativo.aquisicao.contabValorAquisicao;
    }
  }

  private _applyDataSourceAtivosDocumentos(listaAtivos: Array<IJsonAtivos>): void {
    this.aquisicaoInvestEmCursoPorDocDocumentos.listaAtivos = listaAtivos;
    this._changeSaveDisable(!listaAtivos.length);
    this._updateContaDeInvestEmCursoCabDocumentos(listaAtivos);
  }

  private _applyDataSourceDocumentos(listaDocs: Array<IAquisicaoInvestEmCursoPorDocItem>): void {
    this.aquisicaoInvestEmCursoPorDocDocumentos.listaDocumentos = listaDocs;
  }

  private _evaluateCalcResumoDocumentos(dados: IJsonAquisicaoInvestEmCursoPorDoc): void {
    const resumo: IJsonAquisicaoInvestEmCursoPorDocResumo = {radical: dados.resumo.radical, valorRadical: 0, jaAssociado: dados.resumo.jaAssociado};

    // calc resumo valor
    for (const documento of dados.listaDocumentos) {
      resumo.valorRadical += documento.valor;
    }
    this._evaluateResumoDocumentos(resumo);
  }

  private _evaluateResumoDocumentos(resumo: IJsonAquisicaoInvestEmCursoPorDocResumo): void {
    this.aquisicaoInvestEmCursoPorDocDocumentos.resumo = {
      radical: resumo ? resumo.radical : '',
      valorRadical: resumo ? resumo.valorRadical : 0,
      jaAssociado: resumo ? resumo.jaAssociado : 0
    };
  }

  private async _openModalAtivosConfigDocumentos(): Promise<void> {
    await this._moduleMaintenanceAtivosConfigDocumentos.maintenance();
  }

  private _deRegisterOnStart(): void {
    if (this._deRegisterOnStartFn) {
      this._deRegisterOnStartFn();
      this._deRegisterOnStartFn = undefined;
    }
  }

  private _deRegisterOnStartDocumentos(): void {
    if (this._deRegisterOnStartFnDocumentos) {
      this._deRegisterOnStartFnDocumentos();
      this._deRegisterOnStartFnDocumentos = undefined;
    }
  }

  private _registerOnStart(): void {
    this._deRegisterOnStart();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_LOGIN && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFn = this._transitionService.onStart(criteria, () => this._navigationSafeGuard());
  }

  private _navigationSafeGuard(): Promise<void> {
    if (!isTest() && this._dataGridInstanceAtivosDocumentos && this._dataGridInstanceAtivosDocumentos.totalCount() > 0) {
      return this._cgModalService.showOkCancel('ativosaquisicaoinvestemcurso.prompt.exitTitle', 'ativosaquisicaoinvestemcurso.prompt.exitMessage');
    }
    return Promise.resolve();
  }

  private _registerOnStartDocumentos(): void {
    this._deRegisterOnStartDocumentos();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_LOGIN && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFnDocumentos = this._transitionService.onStart(criteria, () => this._navigationSafeGuardDocumentos());
  }

  private _navigationSafeGuardDocumentos(): Promise<void> {
    if (!isTest() && (this.aquisicaoInvestEmCursoPorDocDocumentos.resumo.radical !== '' || (this._dataGridInstanceAtivosDocumentos && this._dataGridInstanceAtivosDocumentos.totalCount() > 0))) {
      return this._cgModalService.showOkCancel('global.prompt.exitTitle', 'global.prompt.exitMessage');
    }
    return Promise.resolve();
  }

  private _buildToolbarInitBtns(): void {
    // btn search
    this.toolbar.addButton(this._btnSearch);
    this._btnSearch.click = () => this._openModalDocsAquisicaoInvestEmCursoDocumentos();

    // btn save
    this.btnSave.visible = true;
    this.btnSave.order = this._btnSearch.order + 1;
    this._changeSaveDisable(true);
    this.btnSave.click = () => this._saveAtivosDocumentos();

    // btn config
    this.btnConfig.visible = true;
    this.btnConfig.order = this.btnSave.order + 1;
    this.btnConfig.click = () => this._openModalAtivosConfigDocumentos();
  }

  private _changeSaveDisable(isDisabled: boolean): void {
    this.btnSave.disabled = isDisabled;
  }

  private _emptyDadosDocumentos(): void {
    this.aquisicaoInvestEmCursoPorDocDocumentos = {
      resumo: {radical: '', valorRadical: 0, jaAssociado: 0},
      listaDocumentos: [],
      listaAtivos: []
    };
  }

  private readonly _fnAdicionaAtivoDocumentos = (event: IEntityMaintenanceOnMaintenanceSave<IJsonAtivos, AtivosNewComponent>): Promise<IJsonAtivos> => {
    return this._adicionaAtivoDocumentos(event);
  };
}
