<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div class="pl-navbar">
  <nav class="navbar navbar-expand-lg fixed-top">
    <div class="navbar-wrapper">
      <div id="cg-navbar-toggle-sidebar" class="navbar-text cg-navbar-icon" (mouseenter)="mouseEnterSidebar()" (mouseleave)="mouseLeaveSidebar()">
        <button type="button" class="cg-navbar-icon-toggler" (click)="toggleSidebar()" (mouseenter)="mouseEnterSidebarToggler()" (mouseleave)="mouseLeaveSidebarToggler()">
          <div class="pl-hamburger active" [class.active]="activeSideBar">
            <div class="pl-hamburger-content">{{ locale.plSideBar.toggleSideBar }}</div>
          </div>
        </button>

        <div class="cg-navbar-icon-content">
          <ng-content select="pl-navbar-brand"></ng-content>
        </div>
      </div>

      <div class="navbar-brand">
        <ng-container *ngIf="!fallback || !fallback.childNodes.length || !fallback.textContent.trim().length">
          <div class="navbar-sub-brand" [innerHTML]="subBrand | cgcBindHtml"></div>
        </ng-container>
        <div #fallback [hidden]="!fallback || !fallback.childNodes.length || !fallback.textContent.trim().length">
          <ng-content select="pl-navbar-sub-brand"></ng-content>
        </div>
      </div>

      <div *ngIf="showStickyMenus" class="navbar-sticky-menus">
        <ng-container *ngFor="let menu of stickyMenus">
          <ng-container *ngTemplateOutlet="templateNavItem; context: {menu: menu}"></ng-container>
        </ng-container>
      </div>

      <button type="button" class="navbar-toggler" [attr.title]="locale.plNavbar.toggleMenu" (click)="toggleCollapsed()">
        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
      </button>
    </div>

    <div id="pl-navbar-collapse" class="navbar-collapse" [ngbCollapse]="collapsed">
      <div class="navbar-nav" [ngSwitch]="showStickyMenus">
        <ng-container *ngSwitchCase="false">
          <ng-container *ngFor="let menu of menus">
            <ng-container *ngTemplateOutlet="templateNavItem; context: {menu: menu}"></ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="true">
          <ng-container *ngFor="let menu of regularMenus">
            <ng-container *ngTemplateOutlet="templateNavItem; context: {menu: menu}"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </nav>
</div>

<ng-template #templateNavItem let-menu="menu">
  <div *ngIf="menu.visible !== false" class="nav-item" [class.dropdown]="menu.menu && menu.menu.length" [ngSwitch]="!!menu.menu && menu.menu.length > 0">
    <ng-container *ngSwitchCase="false" [ngSwitch]="!!menu.templateRef">
      <a
        *ngSwitchCase="false"
        class="nav-link"
        rel="noopener noreferrer"
        [attr.href]="menu.href"
        [ngClass]="menu.customClass"
        [class.active]="menu.active"
        [attr.data-content]="menu.content"
        [attr.target]="menu.hrefTarget"
        (click)="navAction(menu, $event)">
        <i *ngIf="menu.icon" [cgcIcon]="menu.icon"></i><span *ngIf="menu.title && !menu.titleTemplateRef" class="item-title-menu-right" [plTranslate]="menu.title"></span
        ><span *ngIf="menu.titleTemplateRef" class="item-title-menu-right">
          <ng-container *ngTemplateOutlet="menu.titleTemplateRef; context: {item: menu}"></ng-container>
        </span>
      </a>

      <button *ngSwitchCase="true" type="button" class="nav-link" (click)="navAction(menu, $event)">
        <ng-container *ngTemplateOutlet="menu.templateRef; context: {item: menu}"></ng-container>
      </button>
    </ng-container>

    <div
      *ngSwitchCase="true"
      #dropdown="ngbDropdown"
      class="nav-link dropdown"
      ngbDropdown
      placement="bottom-left"
      autoClose="outside"
      [ngClass]="menu.customClass"
      [class.active]="menu.active"
      [attr.data-content]="menu.content">
      <div class="pl-navbar-dropdown-toggle" ngbDropdownToggle>
        <i *ngIf="menu.icon" [cgcIcon]="menu.icon"></i><span *ngIf="menu.title && !menu.titleTemplateRef" class="item-title-menu-right" [plTranslate]="menu.title"></span
        ><span *ngIf="menu.titleTemplateRef">
          <ng-container *ngTemplateOutlet="menu.titleTemplateRef; context: {item: menu}"></ng-container>
        </span>
      </div>

      <div class="dropdown-menu" ngbDropdownMenu [ngSwitch]="!!menu.templateRef">
        <ng-container *ngSwitchCase="false">
          <ng-container *ngFor="let item of menu.menu">
            <ng-container *ngIf="item.visible !== false">
              <a
                *ngIf="!item.templateRef && item.divider !== true"
                rel="noopener noreferrer"
                class="dropdown-item nav-link"
                ngbDropdownItem
                [attr.href]="item.href"
                [ngClass]="item.customClass"
                [class.active]="item.active"
                [attr.data-content]="item.content"
                [attr.target]="item.hrefTarget"
                (click)="navAction(item, $event, menu, dropdown)">
                <i *ngIf="item.icon" [cgcIcon]="item.icon"></i><span *ngIf="item.title && !item.titleTemplateRef" [plTranslate]="item.title"></span
                ><span *ngIf="item.titleTemplateRef">
                  <ng-container *ngTemplateOutlet="item.titleTemplateRef; context: {item: item, parent: menu, dropdownRef: dropdown}"></ng-container>
                </span>
              </a>

              <div *ngIf="!item.templateRef && item.divider === true" class="dropdown-divider"></div>

              <button *ngIf="item.templateRef" type="button" class="dropdown-item" (click)="navAction(menu, $event)">
                <ng-container *ngTemplateOutlet="item.templateRef; context: {item: item, parent: menu, dropdownRef: dropdown}"></ng-container>
              </button>
            </ng-container>
          </ng-container>
        </ng-container>

        <div *ngSwitchCase="true" class="dropdown-menu-template">
          <ng-container *ngTemplateOutlet="menu.templateRef; context: {item: menu, dropdownRef: dropdown}"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
