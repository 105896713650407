<div class="ativosimport-list">
  <div class="mb-2">
    <pl-edit type="boolean" attrName="onlyByImportar" [model]="onlyToImport" (modelChange)="onlyByImportarChange($event)" [properties]="{label: 'ativosimport.onlyByImportar'}"></pl-edit>
  </div>

  <pl-tabs [activeId]="activeTab" [callback]="tabCallback" (evtSelected)="changedTab($event)">
    <pl-tab caption="ativosimport.tabs.porimportar" [id]="typeNavTabsAtivosImport.PorImportar">
      <div *plTabContent>
        <ng-container *ngTemplateOutlet="templateDataGrid; context: {list: ativosPorImportar, instanceName: 'AtivosImportPorImportar'}"></ng-container>
      </div>
    </pl-tab>

    <pl-tab caption="ativosimport.tabs.importados" [id]="typeNavTabsAtivosImport.Importados" [disabled]="onlyToImport">
      <div *plTabContent>
        <ng-container *ngTemplateOutlet="templateDataGrid; context: {list: ativosImportados, instanceName: 'AtivosImportImportados'}"></ng-container>
      </div>
    </pl-tab>
  </pl-tabs>
</div>

<ng-template #templateDataGrid let-list="list" let-instanceName="instanceName">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    [cgDxDataGridInstanceName]="instanceName"
    [dataSource]="list"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()"
    (onCellPrepared)="onCellPrepared($event)"
    (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)">
    <dxi-column *ngFor="let band of ativosImportGridBand" [caption]="band.caption | translate" [alignment]="band.alignment">
      <dxi-column
        *ngFor="let field of band.fields"
        [dataField]="field.dataField"
        [dataType]="field.dataType"
        [caption]="field.caption | translate"
        [format]="field.format"
        [width]="field.width"
        [cellTemplate]="field.cellTemplate"
        [headerCellTemplate]="field.headerTemplate"
        [allowHeaderFiltering]="field.allowHeaderFiltering"
        [allowSearch]="field.allowSearch"
        [allowFiltering]="field.allowFiltering"
        [allowSorting]="field.allowSorting">
      </dxi-column>
    </dxi-column>

    <div *dxTemplate="let cell of 'headerTemplateSelected'">
      <div *ngIf="activeTab === typeNavTabsAtivosImport.PorImportar">
        <dx-check-box name="selectAllPorDepreciar" [value]="!!selectAllPorImportar" (valueChange)="selectAllPorImportar = $event; selectAllChanged(selectAllPorImportar)"></dx-check-box>
      </div>
      <div *ngIf="activeTab === typeNavTabsAtivosImport.Importados">
        <dx-check-box name="selectAllDepreciados" [value]="!!selectAllImportados" (valueChange)="selectAllImportados = $event; selectAllChanged(selectAllImportados)"></dx-check-box>
      </div>
    </div>

    <div *dxTemplate="let cell of 'cellTemplateSelected'">
      <div class="action-collumn">
        <div class="d-flex align-items-center gap-2">
          <dx-check-box name="selected" [value]="!!cell.value" (valueChange)="cell.value = $event; setSelectAll(cell.data, cell.value)"></dx-check-box>

          <div class="ativosimport-list-actions-dropdown">
            <div class="item-group-dropdown" tabindex="-1" ngbDropdown container="body" [placement]="['right-bottom', 'bottom-right', 'right', 'auto']">
              <button type="button" class="btn btn-sm btn-primary dropdown-toggle" ngbDropdownToggle></button>

              <div class="dropdown-menu" ngbDropdownMenu>
                <button type="button" class="dropdown-item" [translate]="'ativosimport.btn.dropdown.editar'" (click)="redirectToEdit(cell.data)" *ngIf="!cell.data.importado"></button>
                <div class="dropdown-divider" *ngIf="!cell.data.importado"></div>
                <button type="button" class="dropdown-item" [translate]="'ativosimport.btn.dropdown.aceitarcalculos'" (click)="aceitarCalculos(cell.data)" *ngIf="!cell.data.importado"></button>
                <button
                  type="button"
                  class="dropdown-item"
                  [translate]="'ativosimport.btn.dropdown.alterarmetodocalculoanual'"
                  (click)="alterarMetodoCalculoAnual(cell.data)"
                  *ngIf="!cell.data.importado"></button>
                <button
                  type="button"
                  class="dropdown-item"
                  [translate]="'ativosimport.btn.dropdown.alterarmetodocalculoinicio'"
                  (click)="alterarMetodoCalculoInicio(cell.data)"
                  *ngIf="!cell.data.importado"></button>
                <button
                  type="button"
                  class="dropdown-item"
                  [translate]="'ativosimport.btn.dropdown.atribuivaloranualmanual'"
                  (click)="atribuiValorAnualManual(cell.data)"
                  *ngIf="!cell.data.importado"></button>
                <button
                  type="button"
                  class="dropdown-item"
                  [translate]="'ativosimport.btn.dropdown.distribuivalorsemarredond'"
                  (click)="distribuiValorSemArredond(cell.data)"
                  *ngIf="!cell.data.importado"></button>
                <button
                  type="button"
                  class="dropdown-item"
                  [translate]="'ativosimport.btn.dropdown.distribuivaloranosanterior'"
                  (click)="distribuiValorAnosAnterior(cell.data)"
                  *ngIf="!cell.data.importado"></button>
                <button type="button" class="dropdown-item" [translate]="'ativosimport.btn.dropdown.atribuipercredu'" (click)="atribuiPercRedu(cell.data)" *ngIf="!cell.data.importado"></button>
                <button
                  type="button"
                  class="dropdown-item"
                  [translate]="'ativosimport.btn.dropdown.limpavaloratribuid'"
                  [disabled]="!cell.data.valoresDepManuais"
                  (click)="limpaValorAtribuid(cell.data)"
                  *ngIf="!cell.data.importado"></button>
                <div class="dropdown-divider" *ngIf="!cell.data.importado"></div>
                <button type="button" class="dropdown-item" [translate]="'ativosimport.btn.dropdown.apagaativo'" (click)="apagaAtivo(false, cell.data)" *ngIf="!cell.data.importado"></button>
                <button type="button" class="dropdown-item" [translate]="'ativosimport.btn.dropdown.apagaativoimport'" (click)="apagaAtivo(false, cell.data)" *ngIf="cell.data.importado"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *dxTemplate="let cell of 'cellTemplateEstado'">
      <span [ngClass]="{'text-danger': cell.data.erro}" [translate]="'ativosimport.table.estado' + cell.text"></span>
    </div>
    <div *dxTemplate="let cell of 'cellTemplateDataAbate'">
      <span *ngIf="cell.data.estado !== 0" [ngClass]="{'text-danger': cell.data.erro}">{{ cell.text }}</span>
    </div>
    <div *dxTemplate="let cell of 'cellTemplateBaseCalculo'">
      <span [ngClass]="{'text-danger': cell.data.erro}" [translate]="'ativosimport.table.baseCalculo' + cell.text"></span>
    </div>
    <div *dxTemplate="let cell of 'cellTemplateMetodoCalculo'">
      <span [ngClass]="{'text-danger': cell.data.erro}" [translate]="'ativosimport.table.metodoCalculo' + cell.text"></span>
    </div>

    <div *dxTemplate="let cell of 'templateImportAtivos'" class="">
      <div class="" *ngIf="activeTab === typeNavTabsAtivosImport.PorImportar">
        <pl-button klass="btn-primary btn-sm" id="importar" (evtClicked)="importarAtivosSave()" [promise]="promise">
          <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'ativosimport.modal.btn.importarativos'"></span>
        </pl-button>
      </div>
    </div>

    <div *dxTemplate="let cell of 'templateDeleteAtivosSel'">
      <div>
        <pl-button klass="ms-auto me-1 btn-danger btn-sm" id="delselecteds" (evtClicked)="apagaAtivo(true)" [promise]="promise" [disabled]="!hasSelectedAtivos">
          <i class="fa fa-fw fa-trash"></i>&nbsp;<span [translate]="'ativosimport.btn.apagasel'"></span>
        </pl-button>
      </div>
    </div>
  </dx-data-grid>
</ng-template>
