import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {isObject, PlAlertService} from 'pl-comps-angular';
import {CGExceptionService} from '../../../../../components/exceptions/exceptions.service';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EDocTipoEntity, EFolderType, IJsonGDFolder} from '../../../../../entities/gdoc/JsonGDoc.entity.interface';
import {EFolderDoc, ENTITY_NAME_GDOC, IGDocEntityService} from '../../../../../entities/gdoc/gdoc.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {ICGExceptionError} from '../../../../../components/exceptions/exceptions.service.interface';
import {IJsonDocContabilidade} from '../../jsonDocContabilidade.interface';
import {IGDocsOnAddingFile} from '../../../../../components/gdocs/gdocs.component.interface';

@Component({
  selector: 'gdoc-contabilidade-modal',
  templateUrl: './gdoc.contabilidade.modal.component.html'
})
export class GDocContabilidadeModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public doc: IJsonDocContabilidade;

  public readonly tipoDocEntity: EDocTipoEntity;
  public folderId: string;
  public params: string;

  private readonly _service: IGDocEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.tipoDocEntity = EDocTipoEntity.Contabilidade;
    this._service = this._entityServiceBuilder.build<IJsonGDFolder, IGDocEntityService>(ENTITY_NAME_GDOC);
  }

  public ngOnInit(): void {
    if (isObject(this.doc)) {
      this.params = `periodo=${this.doc.periodo}&nDiario=${this.doc.nDiario}&nDocInterno=${this.doc.nDocInterno}`;
    }
    this._getDocFolder(this._getDocumentoFolderName(this.doc), EFolderDoc.DefaultFolderContabilidade);
  }

  public onGDocsViewerAddingFile(event: IGDocsOnAddingFile): void {
    event.promise = (async () => {
      const nomePasta = this._getDocumentoFolderName(this.doc);
      await this._addDocFolder(nomePasta, EFolderDoc.DefaultFolderContabilidade);
      await new Promise((resolve) => {
        setTimeout(resolve, 0);
      });
    })();
  }

  private _addDocFolder(name: string, parentFolderId: string): Promise<void> {
    const documentoFolderName: string = this._getDocumentoFolderName(this.doc);
    const gdocFolder: Partial<IJsonGDFolder> = {
      parentId: parentFolderId,
      name: name,
      folderType: EFolderType.System,
      additionalParams: `CGUID=Contabilidade&CGUIDValue=${documentoFolderName}&Contabilidade=${documentoFolderName}&TipoEntityRef=${EDocTipoEntity.Contabilidade}`
    };
    return this._service
      .post({body: gdocFolder})
      .then((response: HttpResponse<IJsonGDFolder>) => {
        this.folderId = response.body.folderId;
      })
      .catch((reason: HttpErrorResponse) => {
        const exception: ICGExceptionError = this._cgExceptionService.get(reason);
        const message: string = exception.message ? exception.message : 'docscomerciais.erros.naoCriouPastaDoc';
        this._plAlertService.error(message);
      });
  }

  private _getDocumentoFolderName(doc: IJsonDocContabilidade): string {
    return isObject(doc) ? `${doc.periodo}.${doc.nDiario.toString()}.${doc.nDocInterno.toString().trim()}` : '';
  }

  private _getDocFolder(name: string, parentfolderId: string): Promise<unknown> {
    return this._service
      .getFolderId(parentfolderId, name)
      .then((response) => {
        if (response?.body) {
          this.folderId = response.body.folderId;
        }
      })
      .catch((reason) => {
        const exception = this._cgExceptionService.get(reason);
        if (exception) {
          this._plAlertService.error(exception.message);
        }
      });
  }
}
