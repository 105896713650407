import {IContabDigitalGDocViewerRecolhaDoc} from '../../../../services/contabilidadedigital/contabilidadedigital.interface';
import {IDocContabilidade} from '../../docscontabilidade/docsContabilidade.interface';
import {TDate} from '../../../../../common/dates';

export interface IContabilidadeExtratosGrid {
  cambioSegMoeda: number;
  dataDoc: TDate;
  dataVenc: TDate;
  descricao: string;
  moeda: string;
  nCCusto: string;
  nConta: string;
  nDiario: number;
  nDocExterno: string;
  nDocumento: string;
  periodo: string;
  porPagar: number;
  valorCredito: number;
  valorCreditoSegMoeda: number;
  valorDebito: number;
  valorDebitoSegMoeda: number;
  saldo: number;
  extPocCabId: string;
  nLanc: string;
  descritivo: string;
  ano: number;
  nDocInterno: string;
  temDocDigital: boolean;
  dataDocExt: TDate;
  stampInsert: TDate;
  perioDiarioDoc?: string;
}

export interface IContabilidadeExtratosGridTableItem extends IContabilidadeExtratosGrid {
  _thedoc?: IDocContabilidade;
  _contabDigitalDoc?: IContabDigitalGDocViewerRecolhaDoc;
}

export interface IContabilidadeExtratosGridModel {
  nConta: string;
  nomeConta: string;
  dePeriodo: string;
  dePeriodoNome: string;
  atePeriodo: string;
  atePeriodoNome: string;
  loadAllAnos: number;
  loadOnlyAno: number;
  dePeriodoAno: string;
  deNDiario: number;
  ateNDiario: number;
}

export interface IContabilidadeExtratosGridParams {
  nConta?: string;
  process?: string;
  dePeriodo?: string;
  atePeriodo?: string;
}

export const MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID = 'extratosgrid';
