import {Component, Input, OnInit} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {EDocTipoEntity, EFolderType, IJsonGDFolder} from '../../../entities/gdoc/JsonGDoc.entity.interface';
import {IGDocsCallback, IGDocsOnAddingFile} from '../../gdocs/gdocs.component.interface';
import {EFolderDoc, ENTITY_NAME_GDOC, IGDocEntityService} from '../../../entities/gdoc/gdoc.entity.interface';
import {CGExceptionService} from '../../exceptions/exceptions.service';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'clifos-gdoc',
  templateUrl: './clifos.gdoc.component.html'
})
export class ClifosGdocComponent implements OnInit {
  @Input() public nConta: string;

  public readonly tipoDocEntity: EDocTipoEntity;
  public readonly callbackGDocs: IGDocsCallback;

  public folderID: string;
  public params: string;

  private readonly _service: IGDocEntityService;

  constructor(
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this.nConta = '';
    this.tipoDocEntity = EDocTipoEntity.CliFo;
    this.callbackGDocs = {};
    this._service = this._entityServiceBuilder.build<IJsonGDFolder, IGDocEntityService>(ENTITY_NAME_GDOC);
  }

  public ngOnInit(): void {
    this.params = `nConta=${this.nConta}`;
    this._getGDocFolder(this.nConta, EFolderDoc.DefaultFolderCliFo);
  }

  public onGDocsViewerAddingFile(event: IGDocsOnAddingFile): void {
    event.promise = (async () => {
      await this._addDocFolder(this.nConta, EFolderDoc.DefaultFolderCliFo);
      await new Promise((resolve) => {
        setTimeout(resolve, 0);
      });
    })();
  }

  private _addDocFolder(name: string, parentfolderId: string): Promise<unknown> {
    const gdocFolder: Partial<IJsonGDFolder> = {
      parentId: parentfolderId,
      name: name,
      folderType: EFolderType.System,
      additionalParams: `CGUID=CliFo&CGUIDValue=${name}&CliFo=${name}&TipoEntityRef=${EDocTipoEntity.CliFo}`
    };
    return this._service
      .post({body: gdocFolder})
      .then((response: HttpResponse<IJsonGDFolder>) => {
        if (response) {
          this.folderID = response.body.folderId;
        }
      })
      .catch((reason) => {
        const exception = this._cgExceptionService.get(reason);
        if (exception) {
          this._plAlertService.error(exception.message);
        } else {
          this._plAlertService.error('docscomerciais.erros.naoCriouPastaDoc');
        }
      });
  }

  private _getGDocFolder(name: string, parentfolderId: string): Promise<unknown> {
    return this._service
      .getFolderId(parentfolderId, name)
      .then((response) => {
        if (response?.body) {
          this.folderID = response.body.folderId;
        }
      })
      .catch((reason) => {
        const exception = this._cgExceptionService.get(reason);
        if (exception) {
          this._plAlertService.error(exception.message);
        }
      });
  }
}
