import {Directive, EventEmitter, Injector, Output} from '@angular/core';
import {KEYCODES} from 'pl-comps-angular';
import {DocContabilidadeField} from './docContabilidade.field';
import {EDocContabilidadeField, IDocContabilidadeEventValue} from '../docContabilidade.interface';
import {IDocContabilidadeLinha} from '../../../docsContabilidade.interface';
import {IEntityAutocompleteCustomActionDefinition, IEntityAutocompleteOptions} from '../../../../../../components/entity/entity.autocomplete.definition.interface';
import {TPredefinidoField} from '../../../../manutencao/predefinidos/jsonPreDefinidosContab.module.interface';

@Directive()
export abstract class DocContabilidadeFieldAutocomplete<TValue, TEvent extends IDocContabilidadeEventValue<TValue> = IDocContabilidadeEventValue<TValue>> extends DocContabilidadeField<
  TValue,
  TEvent
> {
  @Output() public readonly evtActionExtratos: EventEmitter<string>;
  @Output() public readonly evtActionMovab: EventEmitter<string>;
  @Output() public readonly evtActionClifos: EventEmitter<string>;

  public readonly customActionsPoc: Array<IEntityAutocompleteCustomActionDefinition>;
  public properties: IEntityAutocompleteOptions<unknown>;

  protected constructor(
    public readonly fieldName: EDocContabilidadeField,
    protected readonly _injector: Injector,
    protected readonly _predefinidoField?: TPredefinidoField
  ) {
    super(fieldName, _injector, _predefinidoField);
    this.evtActionExtratos = new EventEmitter<string>();
    this.evtActionMovab = new EventEmitter<string>();
    this.evtActionClifos = new EventEmitter<string>();
    this.customActionsPoc = [
      {
        caption: 'docscontabilidade.text.maintenanceExtratos',
        icon: 'fa-fw fa-eye',
        visible: (selectedKey: string) => Boolean(selectedKey),
        action: (selectedKey: string) => {
          this.evtActionExtratos.emit(selectedKey);
        }
      },
      {
        caption: 'docscontabilidade.text.maintenanceMovab',
        visible: (selectedKey: string) => {
          if (!selectedKey || this.linha.nConta !== selectedKey) {
            return false;
          }
          return Boolean(this.linha.poc.cc);
        },
        action: (selectedKey: string) => {
          this.evtActionMovab.emit(selectedKey);
        }
      },
      {
        caption: 'docscontabilidade.text.maintenanceClifos',
        visible: (selectedKey: string) => {
          if (!selectedKey || this.linha.nConta !== selectedKey) {
            return false;
          }
          return Boolean(this.linha.poc.cc);
        },
        action: (selectedKey: string) => {
          this.evtActionClifos.emit(selectedKey);
        }
      }
    ];
  }

  protected _generateProperties(linha: IDocContabilidadeLinha): IEntityAutocompleteOptions<unknown> {
    const raw: boolean = this._raw(linha);
    const properties = super._generateProperties(linha);
    properties.disabled = properties.disabled || raw;
    return {
      ...properties,
      showToggle: !properties.disabled,
      hideActionsOnDisabled: false
    };
  }

  protected _raw(linha: IDocContabilidadeLinha): boolean {
    return this._disabled(linha) || this._docContabilidadeService.evaluateReadOnly(this.docContabilidade, this._predefinidoField, linha.preDefinidoContabLinhaIndice);
  }

  protected _keydown(event: KeyboardEvent): void {
    switch (event.key) {
      case KEYCODES.ADD:
      case KEYCODES.EQUAL_SIGN:
      case KEYCODES.DASH:
      case KEYCODES.MULTIPLY:
        event.preventDefault();
        break;
    }
    super._keydown(event);
  }
}
