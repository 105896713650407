import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {buildSessionUrlWithParams} from '../../common/utils/utils';
import {IJsonDecIvaConfig, IJsonDecIvaPeriodica, IJsonDecIvaPerioExtratoCampo, IJsonDecIvaRecapitulativa, IJsonPeriodoDecIva} from '../interfaces/jsonDeclaracaoIva.interface';
import {TDate} from '../../common/dates';
import {ETipoAnexo} from '../datasources/tiposanexo/tiposAnexo.datasource.interface';
import {TServiceQueryResponse, TServiceResponse} from './api/api.service.interface';
import {ETypePeriodoIva} from '../modules/portalcontabilidade/manutencao/declaracaoiva/contabilidade.declaracaoIva.module.interface';

@Injectable({
  providedIn: 'root'
})
export class ContabilidadeDeclaracaoIvaService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/declaracaoiva`;
  }

  public getDeclaracaoIVAPdfUrl(periodo: string, tipoAnexo: ETipoAnexo, periodoIva?: ETypePeriodoIva): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report/pdf`, {
      periodo: periodo,
      tipoAnexo: tipoAnexo,
      periodoiva: periodoIva
    });
  }

  public anularDeclaracao(periodo: string): TServiceResponse<void> {
    return this._apiService.delete<void>({url: `${this._path}/declaracao/${periodo}`});
  }

  public gravarDeclaracao(periodo: string): TServiceResponse<void> {
    return this._apiService.put<void>({url: `${this._path}/declaracao/${periodo}`, body: undefined});
  }

  public getPeriodosIVA(periodoIva?: ETypePeriodoIva): TServiceQueryResponse<IJsonPeriodoDecIva> {
    return this._apiService.query<IJsonPeriodoDecIva>({
      url: `${this._path}/declaracao/periodosIVA`,
      params: {
        periodoiva: periodoIva
      }
    });
  }

  public getXml(periodo: string, periodoIva?: ETypePeriodoIva): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: `${this._path}/report/xml`,
      params: {
        periodo: periodo,
        periodoiva: periodoIva
      },
      responseType: 'blob'
    });
  }

  public getRecapitulativaXml(periodo: string, periodoIva?: ETypePeriodoIva): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: `${this._path}/reportDRIVA/xml`,
      params: {
        periodo: periodo,
        periodoiva: periodoIva
      },
      responseType: 'blob'
    });
  }

  public getXmlUrl(periodo: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report/xml`, {periodo: periodo});
  }

  public getRecapitulativaXmlUrl(periodo: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/reportDRIVA/xml`, {periodo: periodo});
  }

  public getConfig(isForDrIva: boolean = false): TServiceResponse<IJsonDecIvaConfig> {
    return this._apiService.get<IJsonDecIvaConfig>({url: `${this._path}/config/webservice`, params: {isForDrIva: isForDrIva}});
  }

  public saveConfig(config: IJsonDecIvaConfig): TServiceResponse<IJsonDecIvaConfig> {
    return this._apiService.post<IJsonDecIvaConfig>({url: `${this._path}/config/webservice`, body: config});
  }

  public validarNaAT(periodo: string): TServiceResponse<void> {
    return this._apiService.post<void>({url: `${this._path}/at/valida`, params: {periodo: periodo}});
  }

  public submeterNaAT(periodo: string): TServiceResponse<void> {
    return this._apiService.post<void>({url: `${this._path}/at/submeter`, params: {periodo: periodo}});
  }

  public loadDeclaracaoIVAPerio(periodo: string = ''): TServiceResponse<IJsonDecIvaPeriodica> {
    return this._apiService.get<IJsonDecIvaPeriodica>({url: `${this._path}/declaracaoperiodica`, params: {periodo: periodo}});
  }

  public loadDeclaracaoIVARecap(periodo: string = '', periodoIva?: ETypePeriodoIva): TServiceResponse<IJsonDecIvaRecapitulativa> {
    return this._apiService.get<IJsonDecIvaRecapitulativa>({
      url: `${this._path}/declaracaorecapitulativa`,
      params: {
        periodo: periodo,
        periodoiva: periodoIva
      }
    });
  }

  public postSaveDeclaracaoIvaPerio(decivaperio: IJsonDecIvaPeriodica, periodo: string): TServiceResponse<IJsonDecIvaPeriodica> {
    return this._apiService.post<IJsonDecIvaPeriodica>({url: `${this._path}/savedecivaperio`, body: decivaperio, params: {periodo: periodo}});
  }

  public postSaveDeclaracaoIvaRecap(decivaperio: IJsonDecIvaRecapitulativa, periodo: string, periodoIva?: ETypePeriodoIva): TServiceResponse<IJsonDecIvaRecapitulativa> {
    return this._apiService.post<IJsonDecIvaRecapitulativa>({
      url: `${this._path}/savedecivarecap`,
      params: {
        periodo: periodo,
        periodoiva: periodoIva
      },
      body: decivaperio
    });
  }

  public getExtratoCampoDecPeriodica(dataDe: TDate, dataAte: TDate, campoOficial: string): TServiceResponse<Array<IJsonDecIvaPerioExtratoCampo>> {
    return this._apiService.get<Array<IJsonDecIvaPerioExtratoCampo>>({
      url: `${this._path}/extratocampodecperiodica`,
      params: {
        datade: dataDe,
        dataate: dataAte,
        campooficial: campoOficial
      }
    });
  }

  public postEntregarDeclaracaoIvaRecap(periodo: string, periodoIva?: ETypePeriodoIva): TServiceResponse<void> {
    return this._apiService.post<void>({
      url: `${this._path}/entregardeclaracaoivarecap`,
      params: {
        periodo: periodo,
        periodoiva: periodoIva
      }
    });
  }
}
