import {Component, Injector} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {IFctDecMensalRemunProcessExistentesFCT} from '../../fct.module.interface';
import {FctService} from '../../fct.module.service';

@Component({
  selector: 'fct-dados-todos-trabalhadores-com-fc-modal',
  templateUrl: './fct.dadosTodosTrabComFC.modal.component.html'
})
export class FctDadosTodosTrabComFCModalComponent extends CGModalComponent<void> {
  public dataGridDefinition: IDevExpressDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _fctService: FctService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columns: [
        {
          caption: 'fct.tableDefinition.identificacao',
          columns: [
            {dataField: 'empresa', caption: 'fct.tableDefinition.nempresa'},
            {dataField: 'nomeEmpresa', caption: 'fct.tableDefinition.nome'},
            {dataField: 'fctAno', caption: 'fct.tableDefinition.fctAno'},
            {dataField: 'fctMes', caption: 'fct.tableDefinition.fctMes'},
            {dataField: 'codemp', caption: 'fct.tableDefinition.codemp'},
            {dataField: 'nome', caption: 'fct.tableDefinition.nomeEmp'},
            {dataField: 'niss', caption: 'fct.tableDefinition.niss'},
            {dataField: 'iban', caption: 'fct.tableDefinition.iban'}
          ]
        },
        {
          caption: 'fct.tableDefinition.contrato',
          columns: [
            {dataField: 'modalidadeContrato', caption: 'fct.tableDefinition.modalidadeContrato'},
            {dataField: 'dataEntrada', caption: 'fct.tableDefinition.dataEntrada', dataType: 'date'},
            {dataField: 'dataInicioContrato', caption: 'fct.tableDefinition.dataInicioContrato', dataType: 'date'},
            {dataField: 'dataFimContrato', caption: 'fct.tableDefinition.dataFimContrato', dataType: 'date'}
          ]
        },
        {
          caption: 'fct.tableDefinition.rendimentos',
          columns: [
            {dataField: 'valorRetribuicaoMensal', caption: 'fct.tableDefinition.valorRetribuicaoMensal', dataType: 'currency', format: {type: 'currency', precision: 2}},
            {dataField: 'valorDiuturnidade', caption: 'fct.tableDefinition.valorDiuturnidade', dataType: 'currency', format: {type: 'currency', precision: 2}},
            {dataField: 'qtdFaltasInjustificadas', caption: 'fct.tableDefinition.qtdFaltasInjustificadas'},
            {dataField: 'valorFaltasInjustificadas', caption: 'fct.tableDefinition.valorFaltasInjustificadas', dataType: 'currency', format: {type: 'currency', precision: 2}}
          ]
        },
        {
          caption: 'fct.tableDefinition.totalRetidoFundos',
          fixed: true,
          fixedPosition: 'right',
          columns: [
            {dataField: 'totalFCT', caption: 'fct.tableDefinition.totalFCT', dataType: 'currency', format: {type: 'currency', precision: 2}},
            {dataField: 'totalFGCT', caption: 'fct.tableDefinition.totalFGCT', dataType: 'currency', format: {type: 'currency', precision: 2}}
          ]
        }
      ],
      summary: {
        totalItems: [
          {
            column: 'totalFCT',
            displayFormat: '{0}',
            skipEmptyValues: true,
            summaryType: 'sum',
            valueFormat: {
              type: 'currency',
              precision: 2
            }
          },
          {
            column: 'totalFGCT',
            displayFormat: '{0}',
            skipEmptyValues: true,
            summaryType: 'sum',
            valueFormat: {
              type: 'currency',
              precision: 2
            }
          }
        ]
      },
      dataSource: new CustomStore({
        load: () => this._loadDataGrid()
      })
    };
  }

  private _loadDataGrid(): Promise<Array<IFctDecMensalRemunProcessExistentesFCT>> {
    return this._fctService.getDadosTodosTrabalhadoresComFCT().then((response) => {
      return response.body;
    });
  }
}
