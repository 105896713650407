import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {downloadStream, IPlNavWizardCallback, IPlNavWizardDefinition, IPlUploadCallback, IPlUploadFile, IPlUploadParams, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EGenericImporterType} from '../../../../components/genericimporter/generic.importer.component.interface';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {focusElement} from '../../../../../common/utils/element.utils';
import {IJsonSAFTContabPlanoContas} from '../../jsonSaftContab.module.interface';
import {SaftContabService} from '../../saftContab.module.service';

const WIZARD_STEP_ID_DOWNLOAD_MODEL = 'downloadmodel';
const WIZARD_STEP_ID_UPLOAD_MODEL = 'uploadmodel';
const WIZARD_STEP_ID_ANALYZE_ERRORS = 'analyzeerrors';
const WIZARD_STEP_ID_SUCCESS = 'success';

@Component({
  selector: 'saft-contab-import',
  templateUrl: './saftContab.import.modal.component.html'
})
export class SaftContabImportModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public planoContas: Array<IJsonSAFTContabPlanoContas>;

  public readonly type: EGenericImporterType;
  public readonly navWizardDefinition: IPlNavWizardDefinition;
  public readonly navWizardCallback: IPlNavWizardCallback;
  public readonly uploadCallback: IPlUploadCallback;
  public readonly wizardStepIdDownloadModel: string;
  public readonly wizardStepIdUploadModel: string;
  public readonly wizardStepIdAnalyzeErrors: string;
  public readonly wizardStepIdSuccess: string;
  public textType: string;
  public uploadUrl: string;
  public uploadParams: IPlUploadParams;
  public uploadedFilledModel: boolean;
  public downloadedErrors: boolean;
  public uploadingFilledModel: boolean;
  public visibleStepAnalyzeErrors: boolean;

  private _errorsFile: HttpResponse<Blob>;
  private _needRefresh: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _saftContabService: SaftContabService
  ) {
    super(_injector);
    this.type = EGenericImporterType.ImpSaftContab;
    this.navWizardDefinition = {
      items: [],
      force: false
    };
    this.navWizardCallback = {};
    this.uploadCallback = {};
    this.wizardStepIdDownloadModel = WIZARD_STEP_ID_DOWNLOAD_MODEL;
    this.wizardStepIdUploadModel = WIZARD_STEP_ID_UPLOAD_MODEL;
    this.wizardStepIdAnalyzeErrors = WIZARD_STEP_ID_ANALYZE_ERRORS;
    this.wizardStepIdSuccess = WIZARD_STEP_ID_SUCCESS;
    this._reset();
    this.downloadModel = this.downloadModel.bind(this);
    this.downloadModelFull = this.downloadModelFull.bind(this);
  }

  public ngOnInit(): void {
    this.textType = this._plTranslateService.translate(`components.genericImporter.types.${this.type}`);
    this.uploadUrl = this._saftContabService.importExecuteMatrizXls();
    this.uploadParams = {tipomodelo: String(this.type)};
  }

  public onUploadedFilledModel(uploadFile: IPlUploadFile): void {
    this.uploadingFilledModel = false;
    const response: HttpResponse<Blob> = <HttpResponse<Blob>>uploadFile.upload.response;
    if (!this._cgExceptionService.responseHasException(response)) {
      this.visibleStepAnalyzeErrors = false;
      this.uploadedFilledModel = true;
      this.navWizardCallback.setStep(this.wizardStepIdSuccess);
    } else {
      this._errorsFile = response;
      this.visibleStepAnalyzeErrors = true;
      setTimeout(() => {
        this.uploadedFilledModel = true;
        this.navWizardCallback.setStep(this.wizardStepIdAnalyzeErrors);
      });
    }
    this._needRefresh = true;
    this.uploadCallback.removeAllFiles();
  }

  public downloadErrors(): void {
    if (this._errorsFile) {
      this.downloadedErrors = true;
      downloadStream(this._errorsFile);
    }
  }

  public retry(): void {
    this.uploadCallback.removeAllFiles();
    this.uploadedFilledModel = false;
    this.downloadedErrors = false;
    this.visibleStepAnalyzeErrors = false;
    this._errorsFile = undefined;
    this.navWizardCallback.setStep(this.wizardStepIdUploadModel);
  }

  public downloadModel(): Promise<void> {
    return this._saftContabService.importGetModeloXLS().then((response: HttpResponse<Blob>) => {
      downloadStream(response);
      this._focusBtnNext();
    });
  }

  public downloadModelFull(): Promise<void> {
    return this._saftContabService.importGetModeloXLS(this.planoContas).then((response: HttpResponse<Blob>) => {
      downloadStream(response);
      this._focusBtnNext();
    });
  }

  public dismiss(reason?: unknown): void | Promise<void> {
    if (this._needRefresh) {
      return super.close();
    }
    return super.dismiss(reason);
  }

  public readonly fnValidatorStepUploadModel = (): boolean => this._validatorStepUploadModel();

  public readonly fnValidatorStepAnalyzeErrors = (): boolean => this._validatorStepAnalyzeErrors();

  public readonly fnFinalize = (): void => {
    super.close();
  };

  private _reset(): void {
    this.textType = undefined;
    this.uploadUrl = undefined;
    this.uploadParams = undefined;
    this.uploadedFilledModel = false;
    this.downloadedErrors = false;
    this.uploadingFilledModel = false;
    this.visibleStepAnalyzeErrors = false;
    this._errorsFile = undefined;
  }

  private _validatorStepUploadModel(): boolean {
    const valid: boolean = this.uploadedFilledModel;
    if (!valid) {
      this._plAlertService.error('components.genericImporter.errors.requiredUploadModel');
    }
    return valid;
  }

  private _validatorStepAnalyzeErrors(): boolean {
    return false;
  }

  private _focusBtnNext(): void {
    const btnNext: HTMLButtonElement = this._element.querySelector<HTMLButtonElement>('pl-nav-wizard .nav-wizard-item-content-wrapper.active .nav-wizard-item-footer .action-next-step');
    focusElement(btnNext);
  }
}
