import {HttpResponse} from '@angular/common/http';
import {ProviderLike} from '@uirouter/core';
import {ConciliacaoEFaturasService} from './conciliacaoEFaturas.service';
import {EFATURA_TIPO_FATURA_TODOS, IEFaturaTipoFatura} from '../../importadorefatura/service/importadorEfatura.service.interface';

export const RESOLVER_CONCILIACAO_EFATURAS_TIPOS_FATURA: ProviderLike = {
  provide: 'tiposFatura',
  deps: [ConciliacaoEFaturasService],
  useFactory: (conciliacaoEFaturasService: ConciliacaoEFaturasService) =>
    conciliacaoEFaturasService.getAtTiposList().then((response: HttpResponse<Array<IEFaturaTipoFatura>>) => [{...EFATURA_TIPO_FATURA_TODOS}, ...response.body])
};
