import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IApiQueryParams, TServiceQueryResponse, TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonDocContabilidadeCab, IJsonDocContabilidadeLinha} from '../../../modules/portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';

@Injectable({
  providedIn: 'root'
})
export class PesquisaDocsLinhaService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/pesquisaDocsLinha`;
  }

  public pesquisar(params: IApiQueryParams): TServiceQueryResponse<IJsonDocContabilidadeCab> {
    return this._apiService.query<IJsonDocContabilidadeCab>({url: this._path, params: params});
  }

  public getLinhaImputada(nLancImput: string): TServiceResponse<IJsonDocContabilidadeLinha> {
    return this._apiService.get<IJsonDocContabilidadeLinha>({url: `${this._path}/${nLancImput}`});
  }
}
