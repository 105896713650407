import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_COPIA_ABONOS_DESCONTOS} from './rhCopiaAbonosDescontos.module.interface';
import {RHCopiaAbonosDescontosModuleComponent} from './components/rhCopiaAbonosDescontos.module.component';
import {ROLE} from '../../../services/role.const';
import {ETipoAbonoDesconto} from '../../../entities/abdes/abdes.entity.interface';

export const MODULE_RH_COPIA_ABONOS_DESCONTOS: IModuleDefinition = {
  name: MODULE_NAME_RH_COPIA_ABONOS_DESCONTOS,
  state: {
    url: '/copiaabonosdescontos',
    component: RHCopiaAbonosDescontosModuleComponent,
    data: {
      pageTitle: 'global.menu.rhCopiaAbonosDescontos',
      roles: [ROLE.RH]
    },
    params: {
      abdes: {
        type: 'int',
        value: ETipoAbonoDesconto.Abono
      },
      codAbdes: {
        type: 'string',
        value: ''
      },
      nomeCodAbdes: {
        type: 'string',
        value: ''
      }
    }
  }
};
