export const SITUACAO_ATUAL_ATIVA = 'ACTIVA';
export const SITUACAO_VIES_OK = 'Válido (para transacções)';
export const SITUACAO_VIES_NOT_OK = 'Inválido (para transacções)';

export interface IJsonExternalNif {
  apresentacao: string;
  areaDeActividade: string;
  cae: string;
  capitalSocial: number;
  categoria: string;
  codigoPostal: string;
  concelho: string;
  distrito: string;
  email: string;
  empregados: number;
  entidade: string;
  fax: string;
  freguesia: string;
  inicioDeActividade: string;
  lastUpdate: string;
  localidade: string;
  marcas: string;
  morada: string;
  nif: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Nif: string;
  paginaWeb: string;
  palavrasChave: string;
  produtosServicos: string;
  situacaoActual: string;
  socio1: string;
  telefone: string;
  telemovel: string;
}
