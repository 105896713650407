<pl-form [(formInstance)]="form" (evtSubmitted)="close()">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'docscontabilidade.changeValorTitle'" [translateParams]="{type: type}"></h4>
  </div>

  <div class="modal-body entity-detail-form">
    <pl-group *ngIf="propertyData.valorExistente !== propertyData.valorOriginal">
      <label [translate]="'docscontabilidade.doc.linhas.valorOriginal'" [translateParams]="{type: type}"></label>
      <edit>
        <div class="form-control-align">{{ propertyData.valorOriginal }}</div>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'docscontabilidade.doc.linhas.valorExistente'" [translateParams]="{type: type}"></label>
      <edit>
        <div class="form-control-align">{{ propertyData.valorExistente }}</div>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="type"></label>
      <edit>
        <pl-edit type="number" [(model)]="model[property]" [properties]="{validators: {maxDifference: validateMaxDifference}}" plAutoFocus></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn-primary action-save"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.apply'"></span></pl-button>

    <pl-button type="button" klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</pl-form>
