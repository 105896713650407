import {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isObject, isUndefined} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {ConfigOptionsService} from '../../../../../../../services/config/options/config.options.service';
import {IConfigOptionsInstance} from '../../../../../../../services/config/options/config.options.instance.interface';
import {IDocContabilidadeConfigOptions, TConfigOptions} from '../../../../../../../services/config/options/config.options.service.interface';
import {IDocContabilidade} from '../../../../docsContabilidade.interface';

@Component({
  selector: 'docscontabilidade-saveprompt-modal',
  templateUrl: './docsContabilidade.savePrompt.modal.component.html'
})
export class DocsContabilidadeSavePromptModalComponent extends CGModalComponent<void> implements OnInit, OnDestroy {
  @Input() public docContabilidade: IDocContabilidade;
  @Input() public simulation: boolean;
  @Input() public saldado: boolean;
  @Input() public attachDigitalDocs: boolean;

  public title: string;
  public message: string;
  public skipPromptSave: boolean;

  private readonly _configOptionsInstance: IConfigOptionsInstance<boolean, IDocContabilidadeConfigOptions>;
  private _originalSkipPromptSave: boolean;
  private _subscription: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _configOptionsService: ConfigOptionsService
  ) {
    super(_injector);
    this.simulation = false;
    this.saldado = false;
    this.attachDigitalDocs = false;
    this._configOptionsInstance = this._configOptionsService.getOptionsContabilidade().get('docContabilidade');
  }

  public ngOnInit(): void {
    this.title = !this.simulation
      ? !this.attachDigitalDocs
        ? this.docContabilidade.nDocumento
          ? this._translateService.instant('global.saveDocId', {id: this.docContabilidade.nDocumento})
          : this._translateService.instant('global.saveDoc')
        : this.docContabilidade.nDocumento
          ? this._translateService.instant('docscontabilidade.saveprompt.saveDocIdAndAttach', {id: this.docContabilidade.nDocumento})
          : this._translateService.instant('docscontabilidade.saveprompt.saveDocAndAttach')
      : 'global.saveDocSimulation';
    this.message = !this.simulation ? 'global.saveDocMessage' : 'global.saveDocMessageSimulation';
    if (!isObject(this.docContabilidade)) {
      this._logger.warn('WARNING: DocContabilidade is not an object! This is probably unwanted behaviour and should be double checked for errors.');
    }
    this._subscription = this._configOptionsInstance.options().subscribe((configOptions: TConfigOptions<boolean, IDocContabilidadeConfigOptions>) => {
      this._originalSkipPromptSave = configOptions.get('skipPromptSave').value;
      if (isUndefined(this.skipPromptSave)) {
        this.skipPromptSave = this._originalSkipPromptSave;
      }
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  public close(): Promise<void> {
    if (!this.saldado) {
      return Promise.reject(new Error(this._translateService.instant('docscontabilidade.erros.notSaldado')));
    }
    return new Promise<void>((resolve) => {
      let promise: Promise<unknown>;
      if (this.skipPromptSave !== this._originalSkipPromptSave) {
        promise = this._configOptionsInstance.setOption('skipPromptSave', this.skipPromptSave);
      }
      Promise.resolve(promise).finally(() => {
        super.close();
        resolve();
      });
    });
  }
}
