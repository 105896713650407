import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonContratoTipo} from '../contratostipos/jsonContratoTipo.entity.interface';
import {DATA_SOURCE_NAME_PERIOCIDADE_CONTRATO_TIPO} from '../../datasources/periocidadecontratotipo/periocidadeContratoTipo.datasource.interface';
import {ContratosTipoAvencasEditComponent} from './components/edit/contratosTipoAvencas.entity.edit.component';
import {ENTITY_NAME_CONTRATOS_TIPO_AVENCAS} from './contratosTipoAvencas.entity.interface';
import {ENTITY_NAME_DOC_FAS} from '../docfas/docFas.entity.interface';

export const ENTITY_CONTRATOS_TIPO_AVENCAS: IEntityDefinition<IJsonContratoTipo> = {
  name: ENTITY_NAME_CONTRATOS_TIPO_AVENCAS,
  roles: [ROLE.ERP],
  searchPlaceholder: 'contratostiposervicos.pesquisa',
  pageTitle: 'global.menu.contratostiposervicos',
  metadata: {
    keyName: 'id',
    descriptionName: 'nome',
    fields: [
      {name: 'id', caption: 'contratostiposervicos.fields.id', visible: false, properties: {disabled: true}},
      {name: 'nClasseCtr', caption: 'contratostiposervicos.fields.nClasseCtr', visible: false, properties: {disabled: true}},
      {
        name: 'nomeClasse',
        caption: 'contratostiposervicos.fields.nomeClasse',
        placeholder: 'contratostiposervicos.fields.nomeClassePlaceholder',
        entity: {name: 'classescontratos', keyTarget: 'nClasseCtr', outputTarget: 'nomeClasse'},
        validators: {required: true}
      },
      {
        name: 'classeTipo',
        visible: false,
        placeholder: 'contratostiposervicos.fields.nomeClasseCtrPlaceholder',
        caption: 'contratostiposervicos.fields.nomeClasseCtr',
        validators: {required: false}
      },
      {
        name: 'nTipoContrato',
        type: 'smallint',
        placeholder: 'contratostiposervicos.fields.nTipoContratoPlaceholder',
        caption: 'contratostiposervicos.fields.nTipoContrato',
        validators: {required: false}
      },
      {
        name: 'nome',
        placeholder: 'contratostiposervicos.fields.nomePlaceholder',
        caption: 'contratostiposervicos.fields.nome',
        validators: {required: true}
      },
      {
        name: 'periocidade',
        placeholder: 'contratostiposervicos.fields.periocidadePlaceholder',
        caption: 'contratostiposervicos.fields.periocidade',
        validators: {required: false},
        type: DATA_SOURCE_NAME_PERIOCIDADE_CONTRATO_TIPO,
        visible: true
      },
      {
        name: 'nDocfa',
        caption: 'contratostiposervicos.fields.nDocfa',
        placeholder: 'contratostiposervicos.fields.nDocfaPlaceholder',
        entity: {name: ENTITY_NAME_DOC_FAS, keyTarget: 'nDocfa', outputTarget: 'nomeDocfa'},
        validators: {required: false},
        visible: true
      },
      {
        name: 'nDocfa',
        caption: 'contratostiposervicos.fields.nDocfa',
        placeholder: 'contratostiposervicos.fields.nDocfaPlaceholder',
        entity: {name: ENTITY_NAME_DOC_FAS, keyTarget: 'nDocfa', outputTarget: 'nomeDocfa'},
        validators: {required: false},
        visible: true
      },
      {
        name: 'nNumer',
        caption: 'contratostiposervicos.fields.nNumer',
        placeholder: 'contratostiposervicos.fields.nNumerPlaceholder',
        entity: {name: 'docfasNums', keyTarget: 'nNumer', outputTarget: 'nNumer'},
        validators: {required: false},
        visible: true
      },
      {name: 'processaDiferimento', type: 'boolean', caption: 'contratostiposervicos.fields.processaDiferimento'}
    ],
    order: 'nClasseCtr',
    searchFields: 'nClasseCtr,nTipoContrato,nome',
    listFields: 'nTipoContrato,nome,nomeClasse',
    newFields: 'nClasseCtr,nomeClasse,nTipoContrato,nome,periocidade,nDocfa,nNumer,processaDiferimento',
    editFields: 'nClasseCtr,nomeClasse,nTipoContrato,nome,periocidade,nDocfa,nNumer,processaDiferimento',
    detailFields: 'nomeClasse,nTipoContrato,nome,periocidade,nDocfa,nNumer,processaDiferimento'
  },
  autocomplete: {
    rowTemplate: '{{nTipoContrato}} - {{nome}}',
    output: 'nome',
    searchFields: 'nClasseCtr,nTipoContrato,nome,classeTipo'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    duplicate: false,
    search: true,
    delete: true,
    filter: true
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.contratostiposervicos'
      },
      component: ContratosTipoAvencasEditComponent
    }
  }
};
