import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ApiService} from '../../../services/api/api.service';
import {EAtivosCommandType, ENTITY_NAME_ATIVOS, IAtivosEntityService, TAtivosCommandResult} from '../interfaces/ativos.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IJsonAtivos, IJsonAtivosError, IJsonAtivosTotals} from '../interfaces/jsonAtivos.entity.interface';
import {IJsonAtivosDepreciacoesDetalhadas} from '../interfaces/jsonAtivosDepreciacoesDetalhadas.interface';
import {IJsonAtivosDepreciacoes} from '../interfaces/jsonAtivosDepreciacoes.interface';
import {IJsonAtivosHistorico} from '../interfaces/jsonAtivosHistorico.interface';
import {IJsonAtivosPesquisaContabilidade} from '../../../modules/ativos/ativospesquisacontabilidade/jsonAtivosPesquisaContabilidade.module.interface';
import {IRestAtivosCommand, IRestAtivosCommandValoresContabilisticosEFiscais} from '../interfaces/restAtivosCommands.interface';
import {TDate} from '../../../../common/dates';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AtivosService {
  private readonly _path: string;
  private readonly _service: IAtivosEntityService;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this._path = `${this._apiService.path.restapi}/${ENTITY_NAME_ATIVOS}`;
    this._service = this._entityServiceBuilder.build<IJsonAtivos, IAtivosEntityService>(ENTITY_NAME_ATIVOS);
  }

  public emptyAtivo(): IJsonAtivos {
    return {
      aquisicao: {
        ativosAquisicaoID: '',
        categoria: 0,
        classe: 0,
        codAmortizacao: '',
        codAtivo: 0,
        codContab: '',
        codIVAAquisicao: 0,
        codRubOrcam: '',
        contabBaseCalculo: undefined,
        contabDataDispUso: undefined,
        contabMetodoCalculo: undefined,
        contabNumAnosUteis: 0,
        contabPercReducao: 0,
        contabTaxaAmortiza: 0,
        contabValorAquisicao: 0,
        contabValorResidual: 0,
        dataAquisicao: undefined,
        dataDocExterno: undefined,
        dataUtilizacao: undefined,
        fiscalBaseCalculo: undefined,
        fiscalDataInicioUtil: undefined,
        fiscalMetodoCalculo: undefined,
        fiscalNumAnosUteis: 0,
        fiscalPercReducao: 0,
        fiscalTaxaAmortiza: 0,
        fiscalTribAutonTipo: undefined,
        fiscalValorAquisicao: 0,
        fiscalValorResidual: 0,
        iVADeduzido: 0,
        nCCusto: '',
        nCodRepCC: 0,
        nContaAmortAcumu: '',
        nContaAmortExerc: '',
        nContaDoAtivo: '',
        nContaFornecedor: '',
        nContaIVADedutivel: '',
        nDepto: 0,
        nDocExterno: '',
        nFamilia: 0,
        nGrFamilia: 0,
        nMapaAmortizacao: '',
        nRefProcesso: '',
        nSeccao: '',
        nSubde: '',
        nSubfa: 0,
        nVendedor: 0,
        nZona: 0,
        nomeAmortizacao: '',
        nomeCCusto: '',
        nomeCategoria: '',
        nomeClasse: '',
        nomeContaDoAtivo: '',
        nomeContaFornecedor: '',
        nomeContab: '',
        nomeDepto: '',
        nomeFamilia: '',
        nomeFornecedor: '',
        nomeGrFamilia: '',
        nomeMapaAmortizacao: '',
        nomeProcesso: '',
        nomeRepcc: '',
        nomeSeccao: '',
        nomeSubDe: '',
        nomeSubFa: '',
        nomeTipoArtigo: '',
        nomeVendedores: '',
        nomeZona: '',
        quantidade: 0,
        tipoArtigo: 0,
        usado: false,
        nomeContaAmortExerc: '',
        nomeContaAmortAcumu: '',
        nomeContaIva: '',
        nomeCodIva: '',
        planningList: undefined,
        podeEditar: undefined,
        podeEditarContas: undefined
      },
      codAtivo: 0,
      codAtivoPai: 0,
      depreciacoesEstado: undefined,
      depreciacoesLastAno: 0,
      depreciacoesLastMes: 0,
      designacao: '',
      documentoDeAquisicao: '',
      estado: undefined,
      ignoraImpostosDif: false,
      origem: undefined,
      refExterna: '',
      viatLigArtigo11DR: false,
      valorLiquidoContab: undefined,
      valorAquisicaoContab: undefined,
      valorDepreciacaoContab: undefined,
      dataEstado: undefined,
      isAtivoBiologicoDeProducao: false
    };
  }

  public commandDataPorBaseParaCalculo(ativo: IJsonAtivos): TAtivosCommandResult {
    const command: IRestAtivosCommand = {
      jsonData: ativo
    };
    return this._newCommand(EAtivosCommandType.DataPorBaseParaCalculo, command);
  }

  public commandValidaTaxasCodAmortizacao(ativo: IJsonAtivos): TAtivosCommandResult {
    const command: IRestAtivosCommand = {
      jsonData: ativo
    };
    return this._newCommand(EAtivosCommandType.ValidaTaxasCodAmortizacao, command);
  }

  public commandValoresContabilisticosEFiscais(ativo: IJsonAtivos, contabValorAquisicao: number = 0, isAtivoContab: boolean = false, docExtPocCabId: string = ''): TAtivosCommandResult {
    const command: IRestAtivosCommandValoresContabilisticosEFiscais = {
      jsonData: ativo,
      contabValorAquisicao: contabValorAquisicao,
      isAtivoContab: isAtivoContab,
      docExtPocCabId: docExtPocCabId
    };
    return this._newCommand(EAtivosCommandType.ValoresContabilisticosEFiscais, command);
  }

  public commandValoresClassificadores(ativo: IJsonAtivos): TAtivosCommandResult {
    const command: IRestAtivosCommand = {
      jsonData: ativo
    };
    return this._newCommand(EAtivosCommandType.Classificadores, command);
  }

  public commandContasAndIVAFromNContaAtivo(ativo: IJsonAtivos): TAtivosCommandResult {
    const command: IRestAtivosCommand = {
      jsonData: ativo
    };
    return this._newCommand(EAtivosCommandType.ContasAndIVAFromNContaAtivo, command);
  }

  public commandPlanning(ativo: IJsonAtivos): TAtivosCommandResult {
    const command: IRestAtivosCommand = {
      jsonData: ativo
    };
    return this._newCommand(EAtivosCommandType.Planning, command);
  }

  public commandDefaultData(ativo: IJsonAtivos): TAtivosCommandResult {
    const command: IRestAtivosCommand = {
      jsonData: ativo
    };
    return this._newCommand(EAtivosCommandType.DefaultData, command);
  }

  public getDepreciacoesDetalhadas(codAtivo: number): TServiceResponse<Array<IJsonAtivosDepreciacoesDetalhadas>> {
    return this._apiService.get<Array<IJsonAtivosDepreciacoesDetalhadas>>({url: `${this._path}/${codAtivo}/depreciacoesdetalhadas`});
  }

  public checkHaveMoreAtivosFromContab(codAtivo: number): TServiceResponse<boolean> {
    return this._apiService.get<boolean>({url: `${this._path}/${codAtivo}/checkhavemoreativosfromcontab`});
  }

  public getVerificacaoDeConsistenciaVenda(): TServiceResponse<Array<IJsonAtivosError>> {
    return this._apiService.get<Array<IJsonAtivosError>>({url: `${this._path}/verificacaoconsistenciavenda`});
  }

  public getDateDefaultOpJustoValorABP(): TServiceResponse<TDate> {
    return this._apiService.get<TDate>({url: `${this._path}/datedefaultopjustovalorabp`});
  }

  public getVerificacaoDeConsistenciaAquisicao(): TServiceResponse<Array<IJsonAtivosError>> {
    return this._apiService.get<Array<IJsonAtivosError>>({url: `${this._path}/verificacaoconsistenciaaquisicao`});
  }

  public getAtivosTotals(): TServiceResponse<Array<IJsonAtivosTotals>> {
    return this._apiService.get<Array<IJsonAtivosTotals>>({url: `${this._path}/totaisativos`});
  }

  public getAtivo(codAtivo: number): TServiceResponse<IJsonAtivos> {
    return this._apiService.get<IJsonAtivos>({url: `${this._path}/${codAtivo}`});
  }

  public getAtivosPesquisaContabilidade(codAtivo: number): TServiceResponse<Array<IJsonAtivosPesquisaContabilidade>> {
    return this._apiService.get<Array<IJsonAtivosPesquisaContabilidade>>({
      url: `${this._apiService.path.restapi}/ativospesquisacontabilidade`,
      params: {codativo: codAtivo}
    });
  }

  public getDepreciacoes(codAtivo: number): TServiceResponse<Array<IJsonAtivosDepreciacoes>> {
    return this._apiService.get<Array<IJsonAtivosDepreciacoes>>({url: `${this._path}/${codAtivo}/depreciacoes`});
  }

  public getHistorico(codAtivo: number): TServiceResponse<Array<IJsonAtivosHistorico>> {
    return this._apiService.get<Array<IJsonAtivosHistorico>>({url: `${this._path}/${codAtivo}/historico`});
  }

  public postAumentoIFRS16(codAtivo: number, data: TDate, valor: number, designacao: string): TServiceResponse<number> {
    return this._apiService.post<number>({
      url: `${this._path}/${codAtivo}/aumentoifrs16`,
      params: {
        data: data,
        valor: valor,
        designacao: designacao
      }
    });
  }

  public ativosGrandesReparacoes(codAtivo: number, codAtivoPai: number, associa: boolean): TServiceResponse<IJsonAtivos> {
    return this._apiService.post<IJsonAtivos>({
      url: `${this._path}/${codAtivo}/grandesreparacoes`,
      params: {
        codativopai: codAtivoPai,
        associa: associa
      }
    });
  }

  public postOpJustoValorABP(codAtivo: number, data: TDate, valor: number): TServiceResponse<IJsonAtivos> {
    return this._apiService.post<IJsonAtivos>({
      url: `${this._path}/${codAtivo}/opjustovalorabp`,
      params: {
        data: data,
        valor: valor
      }
    });
  }

  private _newCommand(commandName: EAtivosCommandType, command: IRestAtivosCommand): TAtivosCommandResult {
    return this._service.sendAtivosCommand(command.jsonData?.codAtivo, commandName, command).then((response: HttpResponse<IRestAtivosCommand>) => response.body.jsonData);
  }
}
