import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {
  IPlRecaptchaParameters,
  PlRecaptchaService,
  PlRecaptchaV3Service,
  RECAPTCHA_BASE_URL,
  RECAPTCHA_ENTERPRISE,
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_NONCE,
  RECAPTCHA_PARAMETERS,
  RECAPTCHA_SITE_KEY,
  RECAPTCHA_SITE_KEY_V3
} from 'pl-comps-angular';
import {CGModalService} from '../../components/cg/modal/cgmodal.service';
import {ConfigSiteService} from '../configsite.service';
import {IJsonRecaptchaConfig} from '../../interfaces/jsonConfigValue.interface';
import {RecaptchaActions} from './recaptcha.actions';
import {RecaptchaV2ModalComponent} from './v2modal/recaptcha.v2.modal.component';

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService extends PlRecaptchaService {
  private readonly _actions: RecaptchaActions;

  constructor(
    @Inject(PLATFORM_ID) platformId: unknown,
    @Inject(RECAPTCHA_SITE_KEY) @Optional() siteKey: string,
    @Inject(RECAPTCHA_SITE_KEY_V3) @Optional() siteKeyV3: string,
    @Inject(RECAPTCHA_ENTERPRISE) @Optional() enterprise: boolean,
    @Inject(RECAPTCHA_BASE_URL) @Optional() baseUrl: string,
    @Inject(RECAPTCHA_NONCE) @Optional() nonce: string,
    @Inject(RECAPTCHA_LANGUAGE) @Optional() language: string,
    @Inject(RECAPTCHA_PARAMETERS) @Optional() parameters: IPlRecaptchaParameters,
    private readonly _plRecaptchaV3Service: PlRecaptchaV3Service,
    private readonly _configSiteService: ConfigSiteService,
    private readonly _cgModalService: CGModalService
  ) {
    super(platformId, siteKey, siteKeyV3, enterprise, baseUrl, nonce, language, parameters);
    this._actions = new RecaptchaActions(this._plRecaptchaV3Service);
  }

  public validateRecaptchaV2(): Promise<string> {
    return this._configSiteService.recaptchaConfig().then((recaptcha: IJsonRecaptchaConfig) => {
      if (!recaptcha.active) {
        return Promise.resolve('');
      }
      return this._cgModalService.show<string>(RecaptchaV2ModalComponent, {
        size: 'md',
        backdrop: 'static',
        keyboard: false
      });
    });
  }

  public get actions(): RecaptchaActions {
    return this._actions;
  }
}
