import moment from 'moment';
import {BehaviorSubject, combineLatest, debounceTime, from, mergeMap, Observable, of, Subject, Subscription} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {uuidv7} from 'uuidv7';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {JSONSchema} from '@ngx-pwa/local-storage';
import {ChatResourceService, Message} from '@centralgest/amalia-api-angular';
import {isNumber, PlDocumentService} from 'pl-comps-angular';
import {AuthService} from '../../auth/auth.service';
import {CGLocalStorageGroupService} from '../../storage/localstoragegroup.service';
import {DI_AMALIA_INTENT_HANDLERS} from '../intent/amalia.intent.handler.di';
import {EAmaliaMessageBodyFaqFormat, EAmaliaMessageChannel, EAmaliaMessageFrom, IAmaliaMessageBodyIntent, IAmaliaMessageTextWrite} from '../api/amalia.api.service.interface';
import {EGroupName, LOCAL_STORAGE_AMALIA_ROOM_ID, LOCAL_STORAGE_AMALIA_WINDOW_WIDTH} from '../../../../config/constants';
import {IAmaliaAgentContext} from './amalia.window.service.interface';
import {IAmaliaIntentHandler} from '../intent/amalia.intent.handler.interface';
import {SCHEMA_NUMBER, SCHEMA_STRING} from '../../../../common/schemas';
import {TUserSession} from '../../account/jsonUserApi.interface';

const DEFAULT_WINDOW_WIDTH = 700;
const GROUP: EGroupName = EGroupName.GLOBAL;
const KEY_ROOM_ID: string = LOCAL_STORAGE_AMALIA_ROOM_ID;
const SCHEMA_ROOM_ID: JSONSchema = SCHEMA_STRING;
const KEY_WIDTH: string = LOCAL_STORAGE_AMALIA_WINDOW_WIDTH;
const SCHEMA_WIDTH: JSONSchema = SCHEMA_NUMBER;
const DEBOUNCE_SET_WINDOW_WIDTH = 500;

@Injectable({
  providedIn: 'root'
})
export class AmaliaWindowService implements OnDestroy {
  private readonly _subjectMessages: BehaviorSubject<ReadonlyArray<Message>>;
  private readonly _subjectWindowOpen: BehaviorSubject<boolean>;
  private readonly _subjectWindowWidth: BehaviorSubject<number>;
  private readonly _subjectSetWindowWidth: Subject<number>;
  private readonly _subscriptionSession: Subscription;
  private readonly _subscriptionSetWindowWidth: Subscription;
  private readonly _mapIntentHandlers: ReadonlyMap<string, IAmaliaIntentHandler>;
  private _observableAgentContext: Observable<IAmaliaAgentContext>;
  private _observableMessages: Observable<ReadonlyArray<Message>>;
  private _observableWindowOpen: Observable<boolean>;
  private _observableWindowWidth: Observable<number>;

  constructor(
    @Inject(DI_AMALIA_INTENT_HANDLERS) private readonly _intentHandlers: Array<IAmaliaIntentHandler>,
    private readonly _authService: AuthService,
    private readonly _plDocumentService: PlDocumentService,
    private readonly _storageService: CGLocalStorageGroupService,
    private readonly _amaliaChatResourceService: ChatResourceService
  ) {
    this._subjectMessages = new BehaviorSubject<ReadonlyArray<Message>>([]);
    this._subjectWindowOpen = new BehaviorSubject<boolean>(false);
    this._subjectWindowWidth = new BehaviorSubject<number>(undefined);
    this._subjectSetWindowWidth = new Subject<number>();
    this._subscriptionSetWindowWidth = this._subjectSetWindowWidth
      .pipe(
        tap((windowWidth: number) => {
          this._subjectWindowWidth.next(windowWidth);
        })
      )
      .pipe(debounceTime(DEBOUNCE_SET_WINDOW_WIDTH))
      .subscribe((windowWidth: number) => {
        if (windowWidth !== DEFAULT_WINDOW_WIDTH) {
          this._storageService.setItem(KEY_WIDTH, windowWidth, SCHEMA_WIDTH, GROUP).subscribe();
        } else {
          this._storageService.removeItem(KEY_WIDTH, GROUP).subscribe();
        }
      });
    this._mapIntentHandlers = Object.freeze(new Map(this._intentHandlers.map((handler: IAmaliaIntentHandler) => [handler.intentName(), handler])));
  }

  public ngOnDestroy(): void {
    this._subscriptionSession.unsubscribe();
    this._subscriptionSetWindowWidth.unsubscribe();
    this._subjectMessages.complete();
    this._subjectWindowOpen.complete();
    this._subjectWindowWidth.complete();
    this._subjectSetWindowWidth.complete();
  }

  public agentContext(): Observable<IAmaliaAgentContext> {
    if (!this._observableAgentContext) {
      this._observableAgentContext = this._authService.identityAsObservable().pipe(
        mergeMap((session: TUserSession) => {
          if (!session) {
            if (this._subjectWindowOpen.value) {
              this.closeWindow();
            }
            return undefined;
          }
          return this._loadRoomId().pipe(
            map((roomId: string) => {
              const context: IAmaliaAgentContext = Object.freeze({
                userId: String(session.userId),
                roomId: roomId,
                agentName: `${session.firstName} ${session.lastName}`.trim()
              });
              return context;
            })
          );
        })
      );
    }
    return this._observableAgentContext;
  }

  public messages(): Observable<ReadonlyArray<Message>> {
    if (!this._observableMessages) {
      this._observableMessages = this._subjectMessages.asObservable().pipe(
        mergeMap((messages: ReadonlyArray<Message>) => {
          if (messages.length) {
            return of(messages);
          }
          return from(this._loadMessages());
        })
      );
    }
    return this._observableMessages;
  }

  public addMessage(messageText: string): Observable<void> {
    if (!messageText) {
      throw new Error('Message is required');
    }
    return this.agentContext().pipe(
      mergeMap((context: IAmaliaAgentContext) => {
        if (!context) {
          throw new Error('AgentContext is required');
        }
        if (!context.userId) {
          throw new Error('Session is required');
        }
        if (!context.roomId) {
          throw new Error('RoomId is required');
        }

        const messageWrite: IAmaliaMessageTextWrite = {
          messageId: uuidv7(),
          userId: context.userId,
          roomId: context.roomId,
          body: {
            type: EAmaliaMessageBodyFaqFormat.Text,
            message: messageText
          }
        };

        this._appendMessage({
          messageId: messageWrite.messageId,
          parentId: undefined,
          timestamp: moment().toJSON(),
          roomId: messageWrite.roomId,
          userId: messageWrite.userId,
          channel: EAmaliaMessageChannel.Chat,
          from: EAmaliaMessageFrom.Client,
          body: [messageWrite.body]
        });

        return from(this._amaliaChatResourceService.apiAmaliaChatPost({messageTextWrite: messageWrite}, 'response')).pipe(
          map((response: HttpResponse<Array<Message>>) => {
            this._appendMessages(response.body);
          })
        );
      })
    );
  }

  public windowOpen(): Observable<boolean> {
    if (!this._observableWindowOpen) {
      this._observableWindowOpen = this._subjectWindowOpen.asObservable();
    }
    return this._observableWindowOpen;
  }

  public openWindow(): void {
    this._subjectWindowOpen.next(true);
  }

  public closeWindow(): void {
    this._subjectWindowOpen.next(false);
  }

  public windowWidth(): Observable<number> {
    if (!this._observableWindowWidth) {
      this._observableWindowWidth = combineLatest([this._plDocumentService.isMobile(), this._subjectWindowWidth.asObservable()]).pipe(
        mergeMap(([isMobile, windowWidth]: [boolean, number]) => {
          if (isMobile) {
            return of(0);
          }
          if (isNumber(windowWidth)) {
            return of(windowWidth);
          }
          return this._storageService.getItem<number>(KEY_WIDTH, SCHEMA_WIDTH, GROUP).pipe(
            map((storedWindowWidth: number) => {
              if (!isNumber(storedWindowWidth)) {
                storedWindowWidth = DEFAULT_WINDOW_WIDTH;
              }
              return storedWindowWidth;
            })
          );
        })
      );
    }
    return this._observableWindowWidth;
  }

  public setWindowWidth(windowWidth: number): void {
    this._subjectSetWindowWidth.next(Math.max(DEFAULT_WINDOW_WIDTH, windowWidth));
  }

  public async handleIntent(intent: IAmaliaMessageBodyIntent): Promise<void> {
    const handler = this._mapIntentHandlers.get(intent.intent);
    if (!handler) {
      return;
    }
    await handler.intentHandler(intent);
    this.closeWindow();
  }

  private _loadMessages(): Observable<Array<Message>> {
    return this.agentContext().pipe(
      mergeMap((context: IAmaliaAgentContext) => {
        const roomId: string = context.roomId;
        if (!roomId) {
          throw new Error('RoomId is required');
        }
        return from(this._amaliaChatResourceService.apiAmaliaChatRoomIdGet({roomId: roomId}, 'response')).pipe(
          map((response: HttpResponse<Array<Message>>) => {
            const messages: Array<Message> = response.body;
            if (messages.length) {
              this._appendMessages(response.body);
            }
            return messages;
          })
        );
      })
    );
  }

  private _loadRoomId(): Observable<string> {
    return this._storageService.getItem<string>(KEY_ROOM_ID, SCHEMA_ROOM_ID, GROUP).pipe(
      map((roomId: string) => {
        if (!roomId) {
          roomId = uuidv7();
          this._storageService.setItem(KEY_ROOM_ID, roomId, SCHEMA_ROOM_ID, GROUP).subscribe();
        }
        if (!roomId) {
          throw new Error('Failed to get or generate roomId');
        }
        return roomId;
      })
    );
  }

  private _appendMessage(message: Message): void {
    this._subjectMessages.next(Object.freeze([...this._subjectMessages.value, message]));
  }

  private _appendMessages(messages: Array<Message>): void {
    this._subjectMessages.next(Object.freeze([...this._subjectMessages.value, ...messages]));
  }
}
