import {Transition} from '@uirouter/core';
import {HttpResponse} from '@angular/common/http';
import {isObject} from 'pl-comps-angular';
import {CruzamentosABDESEditComponent} from './components/edit/crabd.entity.edit.component';
import {CruzamentosABDESListComponent} from './components/list/crabd.entity.list.component';
import {ENTITY_NAME_CRABD, ENTITY_NAME_CRUZAMENTOS_ABONOS, ENTITY_NAME_CRUZAMENTOS_DESCONTOS, ICRABDEditStateParams} from './crabd.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonCRABD} from './jsonCrAbD.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_CRABD: IEntityDefinition<IJsonCRABD> = generateEntity(ENTITY_NAME_CRABD);

export const ENTITY_CRUZAMENTOS_ABONOS: IEntityDefinition<IJsonCRABD> = generateEntity(ENTITY_NAME_CRUZAMENTOS_ABONOS, ENTITY_NAME_CRUZAMENTOS_ABONOS);

export const ENTITY_CRUZAMENTOS_DESCONTOS: IEntityDefinition<IJsonCRABD> = generateEntity(ENTITY_NAME_CRUZAMENTOS_DESCONTOS, ENTITY_NAME_CRUZAMENTOS_DESCONTOS);

function generateEntity(entityName: string, entityUrl?: string): IEntityDefinition<IJsonCRABD> {
  let url = ENTITY_NAME_CRABD;
  if (entityUrl) {
    url += `/${entityUrl}`;
  }
  return {
    name: entityName,
    asModule: entityName !== ENTITY_NAME_CRABD,
    entityUrl: url,
    roles: [ROLE.RH],
    searchPlaceholder: 'abdes.pesquisa',
    metadata: {
      keyName: 'nCodOrigem',
      fields: [
        {
          name: 'abono',
          type: 'cgsmallint',
          width: '120px',
          caption: 'abdes.fields.abdes',
          placeholder: 'abdes.fields.abonoDescPlaceholder',
          validators: {required: true}
        },
        {
          name: 'codAbono',
          type: 'text',
          caption: 'abdes.fields.codAbDesc',
          placeholder: 'abdes.fields.codAbDescPlaceholder',
          validators: {required: true, maxlength: 50}
        },
        {
          name: 'nCodAbono',
          type: 'text',
          caption: 'abdes.fields.designacaoBreve',
          placeholder: 'abdes.fields.designacaoBrevePlaceholder',
          validators: {maxlength: 50}
        },
        {name: 'desconto', type: 'cgsmallint', caption: 'abdes.fields.designacaoCompleta'},
        {name: 'codDesconto', type: 'string', caption: 'abdes.fields.valorFixo'},
        {name: 'nCodDesconto', type: 'string', caption: 'abdes.fields.valorLimIncidencia'},
        {name: 'percentagem', type: 'number', caption: 'abdes.fields.tipoArredondamento'},
        {name: 'entSuporte', type: 'number', caption: 'abdes.fields.procNatal'},
        {name: 'prioridadeProce', type: 'number', caption: 'abdes.fields.procFerias'},
        {name: 'tipoExcecao', type: 'number', caption: 'abdes.fields.mapaSeguro'}
      ]
    },
    actions: {
      new: true,
      detail: false,
      edit: true,
      search: true,
      delete: true,
      filter: true
    },
    list: {
      state: {
        component: CruzamentosABDESListComponent
      }
    },
    new: {
      state: {
        component: CruzamentosABDESEditComponent,
        params: {
          crabd: {
            type: 'any'
          }
        },
        resolve: [
          {
            provide: 'model',
            deps: [Transition],
            useFactory: (transition: Transition) => {
              const params: ICRABDEditStateParams = <ICRABDEditStateParams>transition.params();
              if (isObject(params.crabd)) {
                return params.crabd;
              }
              return undefined;
            }
          }
        ]
      }
    },
    detail: {
      state: {
        urlSuffix: '?abdes',
        component: CruzamentosABDESEditComponent,
        params: {
          abdes: {
            type: 'string'
          }
        },
        resolve: [
          {
            provide: 'model',
            deps: [EntityServiceBuilder, Transition],
            useFactory: (entityServiceBuilder: EntityServiceBuilder, transition: Transition) => {
              const {id, abdes}: ICRABDEditStateParams = <ICRABDEditStateParams>transition.params();
              if (id) {
                const service: IEntityService<IJsonCRABD> = entityServiceBuilder.build<IJsonCRABD>(ENTITY_NAME_CRABD);
                return service.get({id: id, params: {nCodDestino: abdes}}).then((response: HttpResponse<IJsonCRABD>) => response.body);
              }
              return Promise.resolve();
            }
          }
        ]
      }
    }
  };
}
