import {ProviderLike} from '@uirouter/core';
import {ENTITY_NAME_CALENDARIOS, ICalendariosEntityService} from '../../entities/calendarios/calendarios.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {IJsonCalendarioCab} from '../../entities/calendarios/jsonCalendarios.entity.interface';
import moment, {MomentInput} from 'moment';

export const RESOLVER_GESTAO_DGEMPS_HOLIDAYS_DATES: ProviderLike = {
  provide: 'holidaysDates',
  deps: [EntityServiceBuilder],
  useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<MomentInput>> => {
    const calendariosService: ICalendariosEntityService = entityServiceBuilder.build<IJsonCalendarioCab, ICalendariosEntityService>(ENTITY_NAME_CALENDARIOS);
    return calendariosService.getHolidaysDates(moment());
  }
};
