import {deepFreeze, isEmpty} from 'pl-comps-angular';
import {EEntidadeBancariaDocDigital, EEntidadeBancariaEstado, IJsonEntidadeBancariaConsent, IJsonEntidadeBancariaTransaction} from '../../interfaces/jsonEntidadeBancaria.interface';
import {IConciliacaoBancariaBancoCab} from '../portalcontabilidade/conciliacaobancaria/conciliacaoBancaria.module.interface';
import {IDevExpressDataGridColumnLookup} from '../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridStoreChange} from '../../components/devexpress/datagrid/store/devexpress.datagrid.store.interface';
import {IDocContabilidadeCallback} from '../portalcontabilidade/docscontabilidade/components/doccontabilidade/docContabilidade.interface';
import {IJsonBancosExtratoDocSemelhante, IJsonBancosExtratoGetTransactionsByConciliacaoBancariaResult} from './jsonBancosExtrato.module.interface';
import {IJsonDocContabilidade} from '../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IJsonPreDefinidoContab} from '../portalcontabilidade/manutencao/predefinidos/jsonPreDefinidosContab.module.interface';
import {TDate} from '../../../common/dates';
import {TTableLegend} from '../../components/tablelegend/tablelegend.component.interface';

export enum EBancosExtratoTableLegendColors {
  Lancado = 'badge-ja-lancado',
  Ignorado = 'badge-ignorado',
  ComDocumentoSemelhante = 'badge-com-documento-semelhante',
  ComConfig = 'badge-com-config'
}

export enum EBancosExtratoSimpleModeFilter {
  None,
  OnlyEntradas,
  OnlySaidas,
  OnlyNaoLancado,
  OnlyLancado
}

export interface IBancosExtratoSimpleModeFilter {
  id: EBancosExtratoSimpleModeFilter;
  caption: string;
}

export interface IBancosExtratoTransaction extends IJsonEntidadeBancariaTransaction {
  predefinido: string;
  toolbarInstanceId?: string;
  docOCRCabID?: string;
  jsonDocContabilidade?: IJsonDocContabilidade;
  jsonPreDefinido?: IJsonPreDefinidoContab;
  callbackDocContabilidade?: IDocContabilidadeCallback;
  loadPreDefinidoFirstTime?: boolean;
}

export interface IBancosExtratoDataGridStoreChange extends IDevExpressDataGridStoreChange<IBancosExtratoTransaction, string> {}

export interface IBancosExtratoGetTransactionsByConciliacaoBancariaResult extends IJsonBancosExtratoGetTransactionsByConciliacaoBancariaResult {
  banco: IConciliacaoBancariaBancoCab;
}

export interface IBancosExtratoTransactionEvaluatedActions {
  addPredefDesc: boolean;
  editPredefDesc: boolean;
  removePredefDesc: boolean;
  doReceipt: boolean;
  doPayment: boolean;
  seeSimilarPostedDocs: boolean;
}

export interface IBancosExtratoDocSemelhante extends IJsonBancosExtratoDocSemelhante {
  diario: string;
}

export interface IBancosExtratoPanelSliderTransaction {
  id: string;
  amount: number;
  dateShort: TDate;
  description: string;
  accountBalance: number;
}

export interface IBancosExtratoConsent extends IJsonEntidadeBancariaConsent {
  accountsId: string;
}

export const MODULE_NAME_BANCOS_EXTRATO = 'bancosextrato';

export const MODULE_NAME_BANCOS_EXTRATO_SIMPLE = 'bancosextratosimple';

export const TABLE_LEGEND_BANCOS_EXTRATO: TTableLegend = deepFreeze([
  {caption: 'bancosextrato.legend.lancado', badgeCSSClass: EBancosExtratoTableLegendColors.Lancado},
  {caption: 'bancosextrato.legend.ignorado', badgeCSSClass: EBancosExtratoTableLegendColors.Ignorado},
  {caption: 'bancosextrato.legend.comDocumentoSemelhante', badgeCSSClass: EBancosExtratoTableLegendColors.ComDocumentoSemelhante},
  {caption: 'bancosextrato.legend.comConfig', badgeCSSClass: EBancosExtratoTableLegendColors.ComConfig}
]);

export const TABLE_LEGEND_BANCOS_EXTRATO_SIMPLE: TTableLegend = deepFreeze([
  {caption: 'bancosextrato.legend.lancado', badgeCSSClass: EBancosExtratoTableLegendColors.Lancado},
  {caption: 'bancosextrato.legend.comDocumentoSemelhante', badgeCSSClass: EBancosExtratoTableLegendColors.ComDocumentoSemelhante},
  {caption: 'bancosextrato.legendsimple.comConfig', badgeCSSClass: EBancosExtratoTableLegendColors.ComConfig}
]);

export const DEV_EXPRESS_DATA_GRID_LOOKUP_ENTIDADE_BANCARIA_ESTADO: IDevExpressDataGridColumnLookup = {
  dataSource: [
    {value: EEntidadeBancariaEstado.NaoLancado, label: 'bancosextrato.estado.naoLancado'},
    {value: EEntidadeBancariaEstado.Lancado, label: 'bancosextrato.estado.lancado'},
    {value: EEntidadeBancariaEstado.Ignorado, label: 'bancosextrato.estado.ignorado'},
    {value: EEntidadeBancariaEstado.NaoLancadoComDocPossivel, label: 'bancosextrato.estado.naoLancadoComDocPossivel'}
  ],
  valueExpr: 'value',
  displayExpr: 'label'
};

export const DEV_EXPRESS_DATA_GRID_LOOKUP_ENTIDADE_BANCARIA_DOC_DIGITAL: IDevExpressDataGridColumnLookup = {
  dataSource: [
    {value: EEntidadeBancariaDocDigital.NaoTem, label: 'bancosextrato.docDigital.naoTem'},
    {value: EEntidadeBancariaDocDigital.Tem, label: 'bancosextrato.docDigital.tem'},
    {value: EEntidadeBancariaDocDigital.Varios, label: 'bancosextrato.docDigital.varios'}
  ],
  valueExpr: 'value',
  displayExpr: 'label'
};

export function evaluateBancosExtratoTransactionActions(transaction: IJsonEntidadeBancariaTransaction): IBancosExtratoTransactionEvaluatedActions {
  const hasBankPreDefCfgId = !isEmpty(transaction.bankPreDefCfgId);
  const estadoNaoLancado: boolean = transaction.estado === EEntidadeBancariaEstado.NaoLancado || transaction.estado === EEntidadeBancariaEstado.NaoLancadoComDocPossivel;
  return {
    addPredefDesc: !hasBankPreDefCfgId,
    editPredefDesc: hasBankPreDefCfgId,
    removePredefDesc: hasBankPreDefCfgId,
    doReceipt: transaction.isRecebimento && estadoNaoLancado,
    doPayment: !transaction.isRecebimento && estadoNaoLancado,
    seeSimilarPostedDocs: estadoNaoLancado
  };
}
