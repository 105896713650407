import {Observable} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {PlToolbarService} from 'pl-comps-angular';
import {ApiService} from '../../../../../services/api/api.service';
import {buildSessionUrlWithParams} from '../../../../../../common/utils/utils';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {IConciliacaoBancariaPrint} from '../../conciliacaoBancaria.module.interface';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';

const PDF_TOOLBAR_INSTANCE_NAME = 'pdf-toolbar-conciliacao-bancaria';

@Component({
  selector: 'conciliacao-bancaria-pdf-modal',
  templateUrl: './conciliacaoBancaria.pdf.modal.component.html'
})
export class ConciliacaoBancariaPdfModalComponent extends CGModalComponent<void> implements OnInit, OnDestroy {
  @Input() public nConta: string;
  @Input() public nomeNConta: string;
  @Input() public periodo: string;

  public readonly pocsFilter: string;
  public readonly toolbarInstanceId: string;
  public model: IConciliacaoBancariaPrint;
  public reportModel: IJsonReport;
  public pdfUrl: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _apiService: ApiService,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _plToolbarService: PlToolbarService
  ) {
    super(_injector);
    this.pocsFilter = 'nConta=%12%&tipo=0';
    this.toolbarInstanceId = PDF_TOOLBAR_INSTANCE_NAME;
    this.model = {
      deNConta: '',
      nomeDeNConta: '',
      ateNConta: '',
      nomeAteNConta: '',
      dePeriodo: '',
      atePeriodo: ''
    };
    this._plToolbarService.registerInstance(this.toolbarInstanceId, {
      items: [
        {
          id: 'pdf-toolbar-apply',
          type: 'button',
          class: 'btn-warning',
          caption: 'conciliacaoBancaria.applyFilters',
          order: 999,
          click: () => {
            this._getPdf();
          }
        }
      ]
    });
  }

  public ngOnInit(): void {
    this.model = {
      deNConta: this.nConta,
      nomeDeNConta: this.nomeNConta,
      ateNConta: this.nConta,
      nomeAteNConta: this.nomeNConta,
      dePeriodo: this.periodo,
      atePeriodo: this.periodo
    };
    this._loadDefaultReport().then(() => {
      this._getPdf();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._plToolbarService.unRegisterInstance(this.toolbarInstanceId);
  }

  public onReportChange(report: IJsonReport): void {
    this.reportModel = report;
    this._getPdf();
  }

  private _getPdf(): void {
    this._getPdfUrl().subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }

  private _getPdfUrl(): Observable<string> {
    return buildSessionUrlWithParams(`${this._apiService.path.restapi}/conciliacaoBancaria/report/pdf`, {...this.model, reportName: this.reportModel.name});
  }

  private _loadDefaultReport(): Promise<void> {
    this.reportModel = {
      name: this.configurations.contabilidade.conciliacaoBancaria.reportImpressao,
      title: this.configurations.contabilidade.conciliacaoBancaria.reportImpressao
    };
    return this._reportsRegistryService
      .get(EReport.ConciliacaoBancaria)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          let foundDefault = false;
          for (const report of reports) {
            if (report.name.toUpperCase() === this.reportModel.name.toUpperCase()) {
              this.reportModel = {name: report.name, title: report.title};
              foundDefault = true;
              break;
            }
          }

          if (!foundDefault) {
            this.reportModel = {
              name: reports[0].name,
              title: reports[0].title
            };
          }
        }
      });
  }
}
