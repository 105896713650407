import {IJsonPeriodo} from '../../../entities/periodos/jsonPeriodo.entity.interface';
import {IJsonBalanceteAno} from '../../portalcontabilidade/estatistica/balancetes/jsonContabilidade.balancetes.module.interface';

export enum EPCAPeriodo {
  TODOS = 0,
  ATE_EMPRESA = 1,
  ENCERRADO = 2
}

export enum EPcaContabilidadeServiceOptionsDefaultYear {
  First,
  Last,
  AnoAtual,
  AnoCursoIRC
}

export enum EPcaContabilidadeServiceOptionsDefaultPeriod {
  First,
  Last,
  AccountingPeriod,
  Current
}

export enum EPcaTipoExportacao {
  Pdf,
  Xls
}

export interface IPcaContabilidadeServiceRefreshedEvent {
  ano: IJsonBalanceteAno;
  periodo: IPcaContabilidadePeriodo;
  anos: Array<IJsonBalanceteAno>;
  periodos: Array<IPcaContabilidadePeriodo>;
}

export interface IPcaContabilidadeServiceOptions {
  abortSignal?: AbortSignal;
  showDropdownAnos?: boolean;
  showDropdownPeriodos?: boolean;
  showCaptionMes?: boolean;
  showCaptionPeriodoMes?: boolean;
  showCaptionEncerrado?: boolean;
  defaultYear?: EPcaContabilidadeServiceOptionsDefaultYear;
  defaultPeriod?: EPcaContabilidadeServiceOptionsDefaultPeriod;
}

export interface IPcaContabilidadePeriodo extends IJsonPeriodo {
  ano: number;
  periodoSimples: string;
}

export const MODULE_NAME_PCA_CONTABILIDADE_BALANCETES = 'pcaBalancetes';
