import {Directive, ElementRef, HostListener} from '@angular/core';
import {FOCUSABLE_QUERY_SELECTOR} from '../../common/utilities/utilities';

const ERROR_SELECTOR = FOCUSABLE_QUERY_SELECTOR.split(',')
  .map((selector: string) => `.cgc-form-group > .has-error ${selector}`)
  .join(',');

@Directive({
  selector: '[plFormErrorNavigation]',
  standalone: false
})
export class PlFormErrorNavigationDirective {
  private readonly _element: HTMLElement;

  constructor(private readonly _elementRef: ElementRef) {
    this._element = this._elementRef.nativeElement;
  }

  @HostListener('submit')
  public submit(): void {
    window.setTimeout(() => {
      const element: HTMLElement = this._element.querySelector(ERROR_SELECTOR);
      if (element) {
        element.scrollIntoView();
        element.focus();
      }
    });
  }
}
