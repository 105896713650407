import moment, {Moment, MomentInput} from 'moment';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef} from '@angular/core';
import {DATE_MAXIMUM_YEAR, DATE_MINIMUM_YEAR, EWeekDay} from '../../common/constants';
import {
  IPlCalendarMonthViewDataset,
  IPlCalendarMonthViewDatasetHeaderDetailTemplateContext,
  IPlCalendarMonthViewDatasetTemplateContext,
  IPlCalendarMonthViewDay,
  IPlCalendarMonthViewDayDetailTemplateContext,
  IPlCalendarMonthViewDayMeta,
  IPlCalendarMonthViewDayTemplateContext,
  IPlCalendarMonthViewEvent,
  IPlCalendarMonthViewEventDay,
  IPlCalendarMonthViewEvtDayClick,
  IPlCalendarMonthViewEvtRangeSelect,
  IPlCalendarMonthViewEvtSelectionChanged,
  IPlCalendarMonthViewHeader,
  IPlCalendarMonthViewHeaderTemplateContext,
  IPlCalendarMonthViewInternalDataset,
  IPlCalendarMonthViewRange,
  IPlCalendarMonthViewScheduler
} from './calendar.month.view.component.interface';
import {
  IPlSchedulerEvtCellClick,
  IPlSchedulerEvtRangeSelect,
  IPlSchedulerEvtSelectionChanged,
  IPlSchedulerRange,
  SCHEDULER_CSS_CLASS_HOLIDAY,
  SCHEDULER_CSS_CLASS_TODAY,
  SCHEDULER_DEFAULT_RANGE,
  SCHEDULER_GRANULARITY
} from '../../scheduler/scheduler.component.interface';
import {isArray, isBoolean, isNumber, isObject} from '../../common/utilities/utilities';
import {ngClassAdd} from '../../common/utilities/angular.utilities';
import {normalizeDate} from '../../common/dates/moment.utils';

const TODAY: Moment = normalizeDate();
const EVENTS_BY_DATE_KEY = 'DDMM';
const GRANULARITY = SCHEDULER_GRANULARITY;

@Component({
  selector: 'pl-calendar-month-view',
  templateUrl: './calendar.month.view.component.html',
  standalone: false
})
export class PlCalendarMonthViewComponent implements OnInit, OnChanges {
  @Input() public datasets: Array<IPlCalendarMonthViewDataset<any, any>>;
  @Input() public datasetsLabel: string;
  @Input() public viewDate: MomentInput;
  @Input() public holidays: boolean | Array<EWeekDay>;
  @Input() public holidaysDates: Array<MomentInput>;
  @Input() public allowSelectHolidays: boolean;
  @Input() public selectableDates: boolean | Array<MomentInput>;
  @Input() public activeDates: boolean | Array<MomentInput>;
  @Input() public rangeSelect: boolean;
  @Input() public datasetDetail: boolean;
  @Input() public datasetDetailOnSelect: boolean;
  @Input() public datasetDetailActive: boolean;
  @Input() public showTitle: boolean;
  @Input() public showLabel: boolean;
  @Input() public selectedRange: IPlCalendarMonthViewRange<any, any>;
  @Input() public templateTitle: TemplateRef<void>;
  @Input() public templateLabel: TemplateRef<void>;
  @Input() public templateHeader: TemplateRef<IPlCalendarMonthViewHeaderTemplateContext>;
  @Input() public templateDataset: TemplateRef<IPlCalendarMonthViewDatasetTemplateContext<any, any>>;
  @Input() public templateDay: TemplateRef<IPlCalendarMonthViewDayTemplateContext<any, any>>;
  @Input() public templateDatasetHeaderDetail: TemplateRef<IPlCalendarMonthViewDatasetHeaderDetailTemplateContext<any, any>>;
  @Input() public templateDayDetail: TemplateRef<IPlCalendarMonthViewDayDetailTemplateContext<any, any>>;
  @Output() public readonly datasetDetailActiveChange: EventEmitter<boolean>;
  @Output() public readonly selectedRangeChange: EventEmitter<IPlCalendarMonthViewRange<any, any>>;
  @Output() public readonly evtBeforeViewRender: EventEmitter<IPlCalendarMonthViewScheduler<any, any>>;
  @Output() public readonly evtDayClick: EventEmitter<IPlCalendarMonthViewEvtDayClick<any, any>>;
  @Output() public readonly evtRangeSelect: EventEmitter<IPlCalendarMonthViewEvtRangeSelect<any, any>>;
  @Output() public readonly evtSelectionChanged: EventEmitter<IPlCalendarMonthViewEvtSelectionChanged<any, any>>;

  public schedulerData: IPlCalendarMonthViewScheduler<unknown, unknown>;
  public schedulerRange: IPlSchedulerRange;

  private readonly _datasetByDay: WeakMap<IPlCalendarMonthViewDay<unknown>, IPlCalendarMonthViewDataset<unknown, unknown>>;
  private readonly _holidaysDates: Set<string>;
  private readonly _selectableDates: Set<string>;
  private readonly _activeDates: Set<string>;
  private _viewDate: Moment;
  private _holidays: Array<EWeekDay>;
  private _allDatesSelectable: boolean;
  private _allDatesActive: boolean;

  constructor() {
    this.schedulerData = {
      header: [],
      datasets: [],
      title: undefined,
      label: undefined
    };
    this.datasetDetailActiveChange = new EventEmitter<boolean>();
    this.selectedRangeChange = new EventEmitter<IPlCalendarMonthViewRange<any, any>>();
    this.evtBeforeViewRender = new EventEmitter<IPlCalendarMonthViewScheduler<any, any>>();
    this.evtDayClick = new EventEmitter<IPlCalendarMonthViewEvtDayClick<any, any>>();
    this.evtRangeSelect = new EventEmitter<IPlCalendarMonthViewEvtRangeSelect<any, any>>();
    this.evtSelectionChanged = new EventEmitter<IPlCalendarMonthViewEvtSelectionChanged<any>>();
    this._datasetByDay = new WeakMap<IPlCalendarMonthViewDay<unknown>, IPlCalendarMonthViewDataset<unknown, unknown>>();
    this._holidaysDates = new Set<string>();
    this._selectableDates = new Set<string>();
    this._activeDates = new Set<string>();
    this._allDatesSelectable = false;
    this._allDatesActive = false;
  }

  public ngOnInit(): void {
    this._handleChanges();
    this._refreshSchedulerData();
  }

  public ngOnChanges({datasets, viewDate, holidays, holidaysDates, allowSelectHolidays, selectableDates, activeDates, selectedRange}: SimpleChanges): void {
    const changedDatasets: boolean = datasets && !datasets.isFirstChange();
    const changedViewDate: boolean = viewDate && !viewDate.isFirstChange() && !moment(viewDate.currentValue).isSame(this._viewDate, 'month');
    const changedHolidays: boolean = holidays && !holidays.isFirstChange();
    const changedHolidaysDates: boolean = holidaysDates && !holidaysDates.isFirstChange();
    const changedAllowSelectHolidays: boolean = allowSelectHolidays && !allowSelectHolidays.isFirstChange();
    const changedSelectableDates: boolean = selectableDates && !selectableDates.isFirstChange();
    const changedActiveDates: boolean = activeDates && !activeDates.isFirstChange();
    if (changedDatasets || changedViewDate || changedHolidays || changedHolidaysDates || changedSelectableDates || changedActiveDates) {
      if (changedDatasets) {
        this._changedDatasets(datasets.currentValue);
      }
      let updateSets = false;
      if (changedViewDate) {
        const previousViewDate = this._viewDate.clone();
        this._changedViewDate(viewDate.currentValue);
        updateSets = !this._viewDate.isSame(previousViewDate, 'month');
      }
      if (changedHolidays) {
        this._changedHolidays(holidays.currentValue);
      }
      if (updateSets) {
        this._changedHolidaysDates();
        this._changedAllowSelectHolidays();
        this._changedSelectableDates();
        this._changedActiveDates();
      } else {
        if (changedHolidaysDates) {
          this._changedHolidaysDates(holidaysDates.currentValue);
        }
        if (changedAllowSelectHolidays) {
          this._changedAllowSelectHolidays(allowSelectHolidays.currentValue);
        }
        if (changedSelectableDates) {
          this._changedSelectableDates(selectableDates.currentValue);
        }
        if (changedActiveDates) {
          this._changedActiveDates(activeDates.currentValue);
        }
      }
      this._refreshSchedulerData();
    }
    if (selectedRange && !selectedRange.isFirstChange()) {
      this._changedSelectedRange(selectedRange.currentValue);
    }
  }

  public onCellClick(event: IPlSchedulerEvtCellClick<unknown, IPlCalendarMonthViewDayMeta<unknown>>): void {
    const datasetItem: IPlCalendarMonthViewDay<unknown> = event.datasetItem;
    const dateKey: string = moment(datasetItem.date).format(EVENTS_BY_DATE_KEY);
    if (!this.allowSelectHolidays && (this._holidays.includes(moment(datasetItem.date).day()) || this._holidaysDates.has(dateKey))) {
      event.preventDefault();
      return;
    }
    const day: IPlCalendarMonthViewEventDay<unknown, unknown> = this._datasetItemToDay(datasetItem);
    this.evtDayClick.emit({
      ...day,
      preventDefault: event.preventDefault,
      preventDetail: event.preventDetail
    });
  }

  public onRangeSelect(event: IPlSchedulerEvtRangeSelect<IPlCalendarMonthViewDayMeta<unknown>>): void {
    const {start, end}: IPlCalendarMonthViewRange<unknown, unknown> = this._schedulerRangeToMonthRange(event);
    if (!this.allowSelectHolidays && (this._holidays.length || this._holidaysDates.size)) {
      const setRange: IPlSchedulerRange = {
        datasetStart: event.datasetStart,
        datasetEnd: event.datasetEnd,
        datasetItemStart: event.datasetItemStart,
        datasetItemEnd: event.datasetItemEnd
      };

      // Exclude holidays from range start
      let startKey: string = start.format(EVENTS_BY_DATE_KEY);
      while (this._holidays.includes(start.day()) || this._holidaysDates.has(startKey)) {
        start.add(1, 'day');
        if (start.isSameOrAfter(end, GRANULARITY)) {
          return;
        }
        startKey = start.format(EVENTS_BY_DATE_KEY);
        setRange.datasetItemStart++;
      }

      // Exclude holidays from range end
      let endKey: string = end.format(EVENTS_BY_DATE_KEY);
      while (this._holidays.includes(end.day()) || this._holidaysDates.has(endKey)) {
        end.subtract(1, 'day');
        if (end.isSameOrBefore(start, GRANULARITY)) {
          return;
        }
        endKey = end.format(EVENTS_BY_DATE_KEY);
        setRange.datasetItemEnd--;
      }

      event.setRange = setRange;

      event.datasetItems = event.datasetItems.filter((day: IPlCalendarMonthViewDay<unknown>) => {
        return moment(day.date).isBetween(start, end, GRANULARITY, '[]') && !day.meta.holiday;
      });
    }
    const days: Array<IPlCalendarMonthViewEventDay<unknown, unknown>> = event.datasetItems.map<IPlCalendarMonthViewEventDay<unknown, unknown>>((day: IPlCalendarMonthViewDay<unknown>) => {
      return this._datasetItemToDay(day);
    });
    const events: Array<IPlCalendarMonthViewEvent<unknown>> = days.reduce((accumulator: Array<IPlCalendarMonthViewEvent<unknown>>, day: IPlCalendarMonthViewEventDay<unknown, unknown>) => {
      return accumulator.concat(day.events);
    }, []);
    this.evtRangeSelect.emit({
      dataset: this.schedulerData.datasets[event.datasetStart],
      start: start,
      end: end,
      days: days,
      events: events,
      preventDefault: event.preventDefault,
      preventDetail: event.preventDetail
    });
  }

  public onDatasetDetailActiveChange(value: boolean): void {
    this.datasetDetailActive = value;
    this.datasetDetailActiveChange.emit(this.datasetDetailActive);
  }

  public onSelectedRangeChange(event: IPlSchedulerRange): void {
    this.schedulerRange = event;
    this.selectedRange = this._schedulerRangeToMonthRange(event);
    this.selectedRangeChange.emit(this.selectedRange);
  }

  public onSelectionChanged(event: IPlSchedulerEvtSelectionChanged<unknown, IPlCalendarMonthViewDayMeta<unknown>>): void {
    const range: IPlCalendarMonthViewRange<unknown, unknown> = this._schedulerRangeToMonthRange(event);
    const events: Array<IPlCalendarMonthViewEvent<unknown>> = [];
    for (const datasetItem of event.datasetsItems) {
      if (datasetItem.meta.events) {
        events.push(...datasetItem.meta.events);
      }
    }
    this.evtSelectionChanged.emit({
      ...range,
      datasets: event.datasets,
      dataset: event.dataset,
      days: event.datasetsItems,
      day: event.datasetItem,
      events: events
    });
  }

  private _handleChanges(): void {
    this._changedDatasets();
    this._changedViewDate();
    this._changedHolidays();
    this._changedHolidaysDates();
    this._changedAllowSelectHolidays();
    this._changedSelectableDates();
    this._changedActiveDates();
    this._changedSelectedRange();
  }

  private _changedDatasets(value: Array<IPlCalendarMonthViewDataset<unknown, unknown>> = this.datasets): void {
    this.datasets = isArray(value) ? value : [];
  }

  private _changedViewDate(value: MomentInput = this.viewDate): void {
    let val: Moment = moment(value);
    if (!val.isValid()) {
      val = TODAY.clone();
    }
    if (val.year() < DATE_MINIMUM_YEAR) {
      val.year(DATE_MINIMUM_YEAR);
    } else if (val.year() > DATE_MAXIMUM_YEAR) {
      val.year(DATE_MAXIMUM_YEAR);
    }
    this._viewDate = val;
  }

  private _changedHolidays(value: boolean | Array<EWeekDay> = this.holidays): void {
    this._holidays = isArray(value)
      ? value.filter((holiday: EWeekDay) => isNumber(holiday) && holiday >= EWeekDay.Sunday && holiday <= EWeekDay.Saturday)
      : value !== false
        ? [EWeekDay.Sunday, EWeekDay.Saturday]
        : [];
  }

  private _changedHolidaysDates(value: Array<MomentInput> = this.holidaysDates): void {
    this._holidaysDates.clear();
    this._datesToSet(value, this._holidaysDates);
  }

  private _changedAllowSelectHolidays(value: boolean = this.allowSelectHolidays): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.allowSelectHolidays = val;
  }

  private _changedSelectableDates(value: boolean | Array<MomentInput> = this.selectableDates): void {
    this._selectableDates.clear();
    if (!isBoolean(value)) {
      this._allDatesSelectable = false;
      this._datesToSet(value, this._selectableDates);
    } else {
      this._allDatesSelectable = value;
    }
  }

  private _changedActiveDates(value: boolean | Array<MomentInput> = this.activeDates): void {
    this._activeDates.clear();
    if (!isBoolean(value)) {
      this._allDatesActive = false;
      this._datesToSet(value, this._activeDates);
    } else {
      this._allDatesActive = value;
    }
  }

  private _changedSelectedRange(value: IPlCalendarMonthViewRange<unknown, unknown> = this.selectedRange): void {
    this.selectedRange = isObject(value) ? value : undefined;
    this.schedulerRange = this._monthRangeToSchedulerRange(this.selectedRange);
  }

  private _datesToSet(dates: Array<MomentInput>, set: Set<string>): void {
    if (isArray(dates)) {
      for (const input of dates) {
        const date: Moment = moment(input);
        if (date.isValid() && date.isSame(this._viewDate, 'month')) {
          const dateKey: string = date.format(EVENTS_BY_DATE_KEY);
          set.add(dateKey);
        }
      }
    }
  }

  private _refreshSchedulerData(): void {
    this.schedulerData = {
      header: this._evaluateHeader(),
      datasets: this._evaluateDatasets(),
      title: this._evaluateTitle()
    };
    this.evtBeforeViewRender.emit(this.schedulerData);
  }

  private _evaluateHeader(): Array<IPlCalendarMonthViewHeader> {
    const header: Array<IPlCalendarMonthViewHeader> = [];
    for (let day = 1; day <= this._viewDate.daysInMonth(); day++) {
      const context: Moment = this._viewDate.clone().date(day);
      header.push({
        label: String(day),
        cssClass: context.isSame(TODAY, 'date') ? SCHEDULER_CSS_CLASS_TODAY : '',
        meta: {
          day: day
        }
      });
    }
    return header;
  }

  private _evaluateDatasets(): Array<IPlCalendarMonthViewInternalDataset<unknown, unknown>> {
    return this.datasets.map<IPlCalendarMonthViewInternalDataset<unknown, unknown>>((dataset: IPlCalendarMonthViewDataset<unknown, unknown>) => {
      const eventsByDate: Map<string, Array<IPlCalendarMonthViewEvent<unknown>>> = new Map<string, Array<IPlCalendarMonthViewEvent<unknown>>>();
      for (const event of dataset.events) {
        const date: Moment = moment(event.date);
        if (!date.isSame(this._viewDate, 'month')) {
          continue;
        }
        const eventKey: string = date.format(EVENTS_BY_DATE_KEY);
        let datasetEvents: Array<IPlCalendarMonthViewEvent<unknown>> = eventsByDate.get(eventKey);
        if (!datasetEvents) {
          datasetEvents = [];
          eventsByDate.set(eventKey, datasetEvents);
        }
        datasetEvents.push(event);
      }

      const internalDatasetItems: Array<IPlCalendarMonthViewDay<unknown>> = [];
      const internalDataset: IPlCalendarMonthViewInternalDataset<unknown, unknown> = {
        items: internalDatasetItems,
        label: dataset.label,
        highlight: dataset.highlight,
        meta: dataset.meta
      };
      for (let day = 1; day <= this._viewDate.daysInMonth(); day++) {
        const context: Moment = this._viewDate.clone().date(day);
        const dateKey: string = context.format(EVENTS_BY_DATE_KEY);
        const events: Array<IPlCalendarMonthViewEvent<unknown>> = eventsByDate.get(dateKey) || [];
        const hasEvents: boolean = events.length > 0;
        const active: boolean = hasEvents || this._allDatesActive || this._activeDates.has(dateKey);
        const holiday =
          this._holidays.includes(context.day()) || this._holidaysDates.has(dateKey) || (hasEvents && events.findIndex((event: IPlCalendarMonthViewEvent<unknown>) => event.holiday) > -1);
        const internalDatasetItem: IPlCalendarMonthViewDay<unknown> = {
          date: context,
          label: '',
          cssClass: hasEvents ? events[0].cssClass : '',
          selectable: (active || this.rangeSelect || this._allDatesSelectable || this._selectableDates.has(dateKey)) && (!holiday || this.allowSelectHolidays),
          active: active,
          meta: {
            events: events,
            holiday: holiday
          }
        };
        if (holiday) {
          internalDatasetItem.cssClass = ngClassAdd(internalDatasetItem.cssClass, SCHEDULER_CSS_CLASS_HOLIDAY);
        }
        this._datasetByDay.set(internalDatasetItem, dataset);
        internalDatasetItems.push(internalDatasetItem);
      }
      return internalDataset;
    });
  }

  private _evaluateTitle(): string {
    return this._viewDate.format('MMMM YYYY');
  }

  private _datasetItemToDay(datasetItem: IPlCalendarMonthViewDay<unknown>): IPlCalendarMonthViewEventDay<unknown, unknown> {
    const date: Moment = moment(datasetItem.date);
    return {
      date: date,
      day: date.date(),
      holiday: datasetItem.meta.holiday,
      dataset: this._datasetByDay.get(datasetItem),
      events: datasetItem.meta.events
    };
  }

  private _schedulerRangeToMonthRange(range: IPlSchedulerRange): IPlCalendarMonthViewRange<unknown, unknown> {
    let start: Moment;
    let end: Moment;
    if (range.datasetStart > -1 && range.datasetStart < this.schedulerData.datasets.length) {
      const dataset: IPlCalendarMonthViewInternalDataset<unknown, unknown> = this.schedulerData.datasets[range.datasetStart];
      if (range.datasetItemStart > -1 && range.datasetItemStart < dataset.items.length) {
        const day: IPlCalendarMonthViewDay<unknown> = dataset.items[range.datasetItemStart];
        start = moment(day.date);
      }
    }
    if (range.datasetEnd > -1 && range.datasetEnd < this.schedulerData.datasets.length) {
      const dataset: IPlCalendarMonthViewInternalDataset<unknown, unknown> = this.schedulerData.datasets[range.datasetEnd];
      if (range.datasetItemEnd > -1 && range.datasetItemEnd < dataset.items.length) {
        const day: IPlCalendarMonthViewDay<unknown> = dataset.items[range.datasetItemEnd];
        end = moment(day.date);
      }
    }
    return {
      dataset: this.schedulerData.datasets[range.datasetStart],
      start: start,
      end: end
    };
  }

  private _monthRangeToSchedulerRange(range: IPlCalendarMonthViewRange<unknown, unknown>): IPlSchedulerRange {
    if (!isObject(range) || !range.dataset || !moment.isMoment(range.start) || !moment.isMoment(range.end)) {
      return {...SCHEDULER_DEFAULT_RANGE};
    }
    const datasetIndex: number = range.dataset ? this.schedulerData.datasets.indexOf(range.dataset) : -1;
    if (datasetIndex === -1) {
      return {...SCHEDULER_DEFAULT_RANGE};
    }
    return {
      datasetStart: datasetIndex,
      datasetEnd: datasetIndex,
      datasetItemStart: range.start.date() - 1,
      datasetItemEnd: range.end.date() - 1
    };
  }
}
