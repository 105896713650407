import {Subscription} from 'rxjs';
import {Component, Host, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {IPlToolbarInstance, IPlToolbarItem, PlToolbarService} from 'pl-comps-angular';
import {EntityListComponent} from './entity.list.component';
import {IEntity} from '../entity.definition.interface';
import {TOOLBAR_ENTITY_LIST_BTN_FILTER, TOOLBAR_ENTITY_LIST_BTN_REFRESH, TOOLBAR_GROUP_ID_ENTITY_LIST} from './entity.list.component.interface';

let TOOLBAR_ID = 0;

@Component({
  selector: 'entity-list-toolbar',
  template: '<div class="entity-list-toolbar"></div>'
})
export class EntityListToolbarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public toolbarInstanceId: string;

  private readonly _btnFilter: IPlToolbarItem;
  private readonly _subscriptionRefreshed: Subscription;
  private _btnRefresh: IPlToolbarItem;
  private _entity: IEntity;
  private _toolbarInstance: IPlToolbarInstance;
  private _toolbarInstanceOwned: boolean;
  private _subscriptionSearch: Subscription;

  constructor(
    @Host() private readonly _entityList: EntityListComponent,
    private readonly _plToolbarService: PlToolbarService
  ) {
    this._btnRefresh = {
      groupId: TOOLBAR_GROUP_ID_ENTITY_LIST,
      id: TOOLBAR_ENTITY_LIST_BTN_REFRESH,
      order: 50,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-refresh"></i>',
      class: 'btn-success',
      caption: 'entity.action.refresh',
      hideCaptionOnMobile: true,
      click: () => this._entityList.refresh()
    };
    this._btnFilter = {
      groupId: TOOLBAR_GROUP_ID_ENTITY_LIST,
      id: TOOLBAR_ENTITY_LIST_BTN_FILTER,
      order: 51,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-filter"></i>',
      class: 'btn-primary',
      caption: 'entity.action.filter',
      hideCaptionOnMobile: true,
      click: () => {
        this._entityList.filterCollapse();
      }
    };
    this._toolbarInstanceOwned = false;
    this._subscriptionRefreshed = this._entityList.evtOnRefresh.subscribe((value: Promise<void>) => {
      if (this._btnRefresh.promise !== value) {
        this._btnRefresh.promise = value;
      }
    });
  }

  public ngOnInit(): void {
    this._entity = this._entityList.entity;
    this._changedToolbarInstanceId();
  }

  public ngOnChanges({toolbarInstanceId}: SimpleChanges): void {
    if (toolbarInstanceId && !toolbarInstanceId.isFirstChange()) {
      this._changedToolbarInstanceId(toolbarInstanceId.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionRefreshed.unsubscribe();
    this._cleanupToolbar();
  }

  private _changedToolbarInstanceId(toolbarInstanceId: string = this.toolbarInstanceId): void {
    this._cleanupToolbar();
    this.toolbarInstanceId = toolbarInstanceId || `${TOOLBAR_GROUP_ID_ENTITY_LIST}-${TOOLBAR_ID++}`;
    this._toolbarInstanceOwned = !this._plToolbarService.isRegistered(this.toolbarInstanceId);
    this._toolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceId);
    this._toolbarInstance.removeGroupId(TOOLBAR_GROUP_ID_ENTITY_LIST).addButton(this._btnRefresh);
    const btnRefresh = this._toolbarInstance.find(TOOLBAR_ENTITY_LIST_BTN_FILTER);
    if (btnRefresh) {
      this._btnRefresh = btnRefresh;
      this._btnRefresh.visible = true;
      this._btnRefresh.hideCaptionOnMobile = true;
    } else {
      this._toolbarInstance.addButton(this._btnRefresh);
    }
    if (this._entity.actions?.filter) {
      this._toolbarInstance.addButton(this._btnFilter);
    }
    if (this._entity.actions?.search) {
      this._subscriptionSearch = this._toolbarInstance.onSearchChanged().subscribe((searchValue: string) => {
        this._entityList.setSearchValue(searchValue);
      });
    }
  }

  private _cleanupToolbar(): void {
    if (this._toolbarInstance) {
      if (this._toolbarInstanceOwned) {
        this._plToolbarService.unRegisterInstance(this._toolbarInstance);
        this._toolbarInstanceOwned = false;
      } else {
        this._toolbarInstance.removeGroupId(TOOLBAR_GROUP_ID_ENTITY_LIST);
      }
      this._toolbarInstance = undefined;
    }
    if (this._subscriptionSearch) {
      this._subscriptionSearch.unsubscribe();
      this._subscriptionSearch = undefined;
    }
  }
}
