import {Component, Injector, OnInit} from '@angular/core';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonRHTipoProcessamento} from '../../jsonRhTiposProcessamento.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {IJsonProcessamentoSalariosConfig} from '../../../../modules/rhconfiguracoes/jsonRHConfiguracoes.module.interface';
import {RHConfiguracoesService} from '../../../../modules/rhconfiguracoes/rhConfiguracoes.module.service';

@Component({
  selector: 'rhTiposProcessamento-edit',
  templateUrl: './rhTiposProcessamento.entity.edit.component.html'
})
export class RhTiposProcessamentoEditComponent extends ModuloEntityDetailComponent<IJsonRHTipoProcessamento> implements OnInit {
  public showFunc: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhConfiguracoesService: RHConfiguracoesService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.model) {
      this.model = {
        tipoProcessamento: undefined,
        descricao: '',
        dadosVencimento: false,
        dadosManuais: false,
        dadosFixos: false,
        subsidioRefeicao: false,
        diuturnidades: false,
        subsidioNatal: false,
        subsidioFerias: false,
        valorCaducidadeCTR: false,
        tipoPeriodicidade: undefined,
        desativado: false,
        funcao: '',
        nomeFuncao: ''
      };
    }
    this._rhConfiguracoesService.getProcessamentoSalarios().then((response: HttpResponse<IJsonProcessamentoSalariosConfig>) => {
      this.showFunc = response.body.processamentoSalariosFields.usaFiltroFuncaoNosTiposProcessamento;
    });
  }
}
