import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_TIPO_INVENTARIO, ETipoInventarioApuramento} from './tipoInventario.datasource.interface';

export const DATA_SOURCE_TIPO_INVENTARIO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_INVENTARIO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoInventarioApuramento.NaotemInventarioPermanenteMensal, name: 'tipoInventario.data.naotemInvPerm'},
    {value: ETipoInventarioApuramento.TemInventarioPermanenteMensalCG, name: 'tipoInventario.data.temInvPermMensalCG'},
    {value: ETipoInventarioApuramento.TemInventarioPermanenteMensalManual, name: 'tipoInventario.data.temInvPermMensalManual'}
  ]
});
