import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlPromisesModule} from '../promises/promises.module';
import {PlProgressModule} from '../progress/progress.module';
import {PlUploadComponent} from './upload.component';

export * from './upload.component.interface';
export * from './upload.component';
export * from './upload.config.service';
export * from './upload.di';
export * from './upload.errorhandler';
export * from './transform/upload.transform.service';
export * from './transform/upload.transform';
export * from './transform/transformers/upload.resize.transform';
export * from './transform/transformers/upload.zip.transform';

const DECLARATIONS = [PlUploadComponent];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
  imports: [CommonModule, PlPromisesModule, PlProgressModule]
})
export class PlUploadModule {}
