export interface IEmpresa {
  nempresa: string;
  nome: string;
}

export interface IEmpresaExtended extends IEmpresa {
  nomeAlternativo: string;
  morada: string;
  codpostal: string;
  localidade: string;
  nif: string;
  actividade: string;
  cae: string;
  conservatoria: string;
  registo: string;
  codMoeda: number;
  codPais: number;
  nomePais: string;
  nomeMoeda: string;
  telefone: string;
  email: string;
  web: string;
  actividadePrincipal: string;
  codTipoEmpresa: number;
  tipoEmpresa: string;
  alvara: string;
  capitalSocial: number;
  codRepFinancas: number;
  repFinancas: string;
  codTipoContab: number;
  tipoContab: string;
  codRegimeIRC: number;
  regimeIRC: string;
  codRegimeIva: number;
  regimeIva: string;
  codPeriodoIva: number;
  periodoIva: string;
  anoCursoIRC: number;
  periodoContabilistico: string;
  textoRelatorio: string;
  naoProcSalarios: boolean;
  telemovel: string;
}

export interface IJsonPerioMudaPrimeiroMesContab {
  primeiroMesContab: number;
  seMesCivilDifPerioComecaAnoSeg: boolean;
}

export const ENTITY_NAME_EMPRESAS = 'empresas';
export const ENTITY_NAME_EMPRESAS_ERP = 'empresasErp';
