import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {TApuraIvaSaveModalResult} from '../../contabilidade.apuraIva.module.interface';

@Component({
  selector: 'apuraiva-save-modal',
  templateUrl: './contabilidade.apuraIva.save.modal.component.html'
})
export class ContabilidadeApuraIvaSaveModalComponent extends CGModalComponent<TApuraIvaSaveModalResult> {
  @Input() public message: string;
  @Input() public cssMessage: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.message = '';
    this.cssMessage = '';
  }
}
