<div class="pl-ui-tree-node-content pl-ui-tree-node-handle" [ngClass]="node._cssClass ? node._cssClass : ''" (dragover)="dragOver($event, parentArray, nodeIndex)">
  <button *ngIf="node.items && node.items.length > 0" type="button" class="btn btn-success btn-xs" (click)="node.collapsed = !node.collapsed">
    <span class="fa fa-fw" [ngClass]="{'fa-chevron-right': node.collapsed, 'fa-chevron-down': !node.collapsed}"></span>
  </button>

  <ng-container
    *ngTemplateOutlet="
      templateContent || templateContentDefault;
      context: {
        $implicit: node,
        node: node,
        nodeIndex: nodeIndex,
        parentArray: parentArray,
        parentNode: parentNode,
        meta: node.meta,
        self: self
      }
    "></ng-container>
</div>

<pl-animation-collapse [collapsed]="node.collapsed">
  <div *ngIf="node.items && node.items.length > 0" class="pl-ui-tree-nodes pl-ui-tree-node-sub-nodes">
    <pl-ui-tree-drag-node
      *ngFor="let subNode of node.items; let subNodeIndex = index"
      class="d-block pl-ui-tree-node"
      [node]="subNode"
      [nodeIndex]="subNodeIndex"
      [parentArray]="node.items"
      [parentNode]="node"
      [templateContent]="templateContent"
      [disabled]="disabled"
      [draggable]="!disabled"
      (dragstart)="dragStart($event, node.items, subNodeIndex)"
      (evtDragStart)="dragStart($event.event, $event.sourceArray, $event.index)"
      (evtDragOver)="dragOver($event.event, $event.targetArray, $event.index)"
      (evtRemoveNode)="
        evtRemoveNode.emit({
          parentArray: $event.parentArray,
          nodeIndex: $event.nodeIndex
        })
      ">
    </pl-ui-tree-drag-node>
  </div>
</pl-animation-collapse>

<ng-template #templateContentDefault let-node="node">{{ node.title }}</ng-template>
