import {EDebitoCredito} from '../debitocredito/debitoCredito.datasource.interface';

export enum EInvoiceType {
  None,
  Fatura,
  FaturaSimplificada,
  FaturaRecibo,
  NotaDebito,
  NotaCredito,
  VendaDinheiro,
  TalaoVenda,
  TalaoDevolucao,
  AlienacaoAtivos,
  DevolucaoAtivos,
  Premio,
  Estorno,
  ImputacaoCoSeguradoras,
  ImputacaoCoSeguradoraLider,
  ResseguroAceite,
  Recibo,
  NotaLancamento,
  ExtratoBancario,
  PagamentoAoEstado,
  Levantamento,
  Pagamento,
  Deposito,
  Cheque,
  Transferencia
}

export type TInvoiceTypeStruct = {
  [key in EInvoiceType]: IInvoiceTypeRecord;
};

export interface IInvoiceTypeRecord {
  char: string;
  dc: EDebitoCredito;
  description: string;
}

export const DATA_SOURCE_NAME_INVOICE_TYPE = 'invoiceType';

export const INVOICE_TYPE_STRUCT: TInvoiceTypeStruct = {
  [EInvoiceType.None]: {char: '', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.None'},
  [EInvoiceType.Fatura]: {char: 'FT', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.Fatura'},
  [EInvoiceType.FaturaSimplificada]: {char: 'FS', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.FaturaSimplificada'},
  [EInvoiceType.FaturaRecibo]: {char: 'FR', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.FaturaRecibo'},
  [EInvoiceType.NotaDebito]: {char: 'ND', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.NotaDebito'},
  [EInvoiceType.NotaCredito]: {char: 'NC', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.NotaCredito'},
  [EInvoiceType.VendaDinheiro]: {char: 'VD', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.VendaDinheiro'},
  [EInvoiceType.TalaoVenda]: {char: 'TV', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.TalaoVenda'},
  [EInvoiceType.TalaoDevolucao]: {char: 'TD', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.TalaoDevolucao'},
  [EInvoiceType.AlienacaoAtivos]: {char: 'AA', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.AlienacaoAtivos'},
  [EInvoiceType.DevolucaoAtivos]: {char: 'DA', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.DevolucaoAtivos'},
  [EInvoiceType.Premio]: {char: 'RP', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.Premio'},
  [EInvoiceType.Estorno]: {char: 'RE', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.Estorno'},
  [EInvoiceType.ImputacaoCoSeguradoras]: {char: 'CS', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.ImputacaoCoSeguradoras'},
  [EInvoiceType.ImputacaoCoSeguradoraLider]: {char: 'LD', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.ImputacaoCoSeguradoraLider'},
  [EInvoiceType.ResseguroAceite]: {char: 'RA', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.ResseguroAceite'},
  [EInvoiceType.Recibo]: {char: 'Recibos', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.Recibo'},
  [EInvoiceType.NotaLancamento]: {char: 'NL', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.NotaLancamento'},
  [EInvoiceType.ExtratoBancario]: {char: 'EXTRATOBANCARIO', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.ExtratoBancario'},
  [EInvoiceType.PagamentoAoEstado]: {char: 'PAGENTOESTADO', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.PagamentoAoEstado'},
  [EInvoiceType.Levantamento]: {char: 'LEVANTAMENTO', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.Levantamento'},
  [EInvoiceType.Pagamento]: {char: 'PAGAMENTO', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.Pagamento'},
  [EInvoiceType.Deposito]: {char: 'DEPOSITO', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.Deposito'},
  [EInvoiceType.Cheque]: {char: 'CHEQUE', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.Cheque'},
  [EInvoiceType.Transferencia]: {char: 'TRANSFERENCIA', dc: EDebitoCredito.Credito, description: 'invoiceType.fields.Transferencia'}
};
