import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonClasseContrato} from './jsonClasseContrato.entity.interface';
import {DATA_SOURCE_NAME_TIPO_CLASSE_CONTRATO} from '../../datasources/tipoclassecontrato/tipoClasseContrato.datasource.interface';
import {ENTITY_NAME_CLASSES_CONTRATOS} from './classesContratos.entity.interface';

export const ENTITY_CLASSES_CONTRATOS: IEntityDefinition<IJsonClasseContrato> = {
  name: ENTITY_NAME_CLASSES_CONTRATOS,
  roles: [ROLE.ERP],
  searchPlaceholder: 'classescontratos.pesquisa',
  pageTitle: 'global.menu.classescontratos',
  metadata: {
    keyName: 'nClasseCtr',
    fields: [
      {
        name: 'nClasseCtr',
        type: 'cgsmallint',
        placeholder: 'classescontratos.fields.nClasseCtrPlaceholder',
        width: '200px',
        caption: 'classescontratos.fields.nClasseCtr',
        validators: {required: true, maxlength: 4, min: 1}
      },
      {
        name: 'nomeClasseCtr',
        placeholder: 'classescontratos.fields.nomeClasseCtrPlaceholder',
        caption: 'classescontratos.fields.nomeClasseCtr',
        validators: {required: true}
      },
      {
        name: 'classeTipo',
        placeholder: 'classescontratos.fields.classeTipoPlaceholder',
        caption: 'classescontratos.fields.classeTipo',
        validators: {required: true},
        type: DATA_SOURCE_NAME_TIPO_CLASSE_CONTRATO
      }
    ],
    order: 'nClasseCtr',
    searchFields: 'nClasseCtr,nomeClasseCtr',
    listFields: 'nClasseCtr,nomeClasseCtr',
    newFields: 'nClasseCtr,nomeClasseCtr,classeTipo',
    editFields: 'nClasseCtr,nomeClasseCtr,classeTipo',
    detailFields: 'nClasseCtr,nomeClasseCtr,classeTipo'
  },
  autocomplete: {
    rowTemplate: '{{nClasseCtr}} - {{nomeClasseCtr}}',
    output: 'nomeClasseCtr',
    searchFields: 'nClasseCtr,nomeClasseCtr'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    duplicate: false,
    search: true,
    delete: true,
    filter: true
  }
};
