export enum ETipoEmpresa {
  None,
  EmpNomeIndivid,
  SociedQuotas,
  SociedAnonima,
  SociedCooperativa,
  Associacao,
  ServicosMunicipalizados,
  SociedadeUnipessoal,
  AgrupamentoComplementarEmpresas,
  CamaraMunicipal,
  EmpresaMunicipal,
  InstitutoPublico,
  FederacaoDesportiva,
  SociedadeAnonimaDesportiva,
  EscolaSuperior,
  EstabelecimentoEstavelSocEstrangeira
}

export interface IJsonEmpresaErpBasic {
  nempresa: string;
  nome: string;
}

export interface IJsonEmpresaErp extends IJsonEmpresaErpBasic {
  morada: string;
  codpostal: string;
  localidade: string;
  nif: string;
  actividade: string;
  cae: string;
  conservatoria: string;
  registo: string;
  codMoeda: number;
  empresaGabinete: boolean;
  nEmpresaGabinete: string;
  nomeAlternativo?: string;
  codPais?: number;
  nomePais?: string;
  nomeMoeda?: string;
  telefone?: string;
  email?: string;
  web?: string;
  actividadePrincipal?: string;
  codTipoEmpresa?: number;
  tipoEmpresa?: string;
  alvara?: string;
  capitalSocial?: number;
  codRepFinancas?: number;
  repFinancas?: string;
  codTipoContab?: number;
  tipoContab?: string;
  codRegimeIRC?: number;
  regimeIRC?: string;
  codRegimeIva?: number;
  regimeIva?: string;
  codPeriodoIva?: number;
  periodoIva?: string;
  anoCursoIRC?: number;
  periodoContabilistico?: string;
  textoRelatorio?: string;
  demonstracao?: boolean;
  tipoAnalitica: number;
  capPublico?: number;
  capPrivado?: number;
  capEstrangeiro?: number;
  capPublicoPerc?: number;
  capPrivadoPerc?: number;
  capEstrangeiroPerc?: number;
  nomeComarca?: string;
  nifCC?: string;
  nOCC?: string;
  nifAdmin?: string;
  nContribSegSocial?: string;
  nomeCAE?: string;
  concelhoEmp?: number;
  nomeConcelhoEmp?: string;
  telemovel?: string;
  mostraTarifasNasListagens?: boolean;
  codNatJuridica?: string;
  nomeNatJuridica?: string;
  codAssocPatronal?: string;
  nomeAssocPatronal?: string;
  codddccff?: string;
  nomeCodddccff?: string;
}

export interface IJsonEmpresaAno {
  ano: number;
}
