import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ConciliacaoBancariaService} from '../../conciliacaoBancaria.module.service';
import {PlAlertService} from 'pl-comps-angular';
import {IJsonPOC} from '../../../../../entities/pocs/jsonPOC.entity.interface';
import {ENTITY_NAME_POCS} from '../../../../../entities/pocs/pocs.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IConciliacaoBancaria} from '../../conciliacaoBancaria.module.interface';

const TIMEOUT_DELAY = 400;

@Component({
  selector: 'conciliacao-bancaria-conta-pendentes-banco-modal',
  templateUrl: './conciliacaoBancaria.contaPendentesBanco.modal.component.html'
})
export class ConciliacaoBancariaContaPendentesBancoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public conciliacao: IConciliacaoBancaria;
  @Input() public nConta: string;
  @Input() public periodo: string;

  public radicalConta: string;
  public conta: string;

  private readonly _servicePocs: IEntityService<IJsonPOC>;
  private _timeoutId: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _conciliacaoBancariaService: ConciliacaoBancariaService,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._servicePocs = this._entityServiceBuilder.build<IJsonPOC>(ENTITY_NAME_POCS);
    this.radicalConta = '';
    this.conta = '';
  }

  public ngOnInit(): void {
    this.radicalConta = this.conciliacao.radicalContaContrapartida;
    this._checkRadicalConta();
  }

  public radicalContaChanged(value: string): void {
    this.radicalConta = value;
    this._checkRadicalConta();
  }

  public saveRadicalConta(): Promise<void> {
    return this._conciliacaoBancariaService.setContaPendentesBanco(this.radicalConta, this.nConta, this.periodo).then(() => {
      this._plAlertService.success('conciliacaoBancaria.radicalContaGuardadoComSucesso');
      this.close();
    });
  }

  private _checkRadicalConta(): void {
    clearTimeout(this._timeoutId);
    this._timeoutId = window.setTimeout(() => {
      this._servicePocs
        .get({id: `${this.radicalConta}${this.conciliacao.extratoBanco.nConta}`, reportExceptions: false})
        .then((response) => {
          this.conta = `${response.body.nConta} - ${response.body.nome}`;
        })
        .catch(() => {
          this.conta = `${this.radicalConta}${this.conciliacao.extratoBanco.nConta} - Pendentes sem Classf. - ${this.conciliacao.extratoBanco.nomeConta}`;
        });
    }, TIMEOUT_DELAY);
  }
}
