import {IJsonConfigERPCheck} from '../../../entities/configserp/jsonConfigERP.entity.interface';

export enum EErpCheckState {
  Load = 'configsERPCheck.state.load',
  Validate = 'configsERPCheck.state.validate',
  GenerateDefaultData = 'configsERPCheck.state.genDefaultData',
  Validating = 'configsERPCheck.state.validating',
  Repair = 'configsERPCheck.state.repair',
  Finished = 'configsERPCheck.state.finished'
}

export enum EErpCheckItemState {
  PendingValidate,
  Working,
  Error,
  Success
}

export interface IConfigERPCheck extends IJsonConfigERPCheck {
  _state?: EErpCheckItemState;
  _showDetail?: boolean;
}

export const MODULE_NAME_CONFIGS_ERP_CHECK = 'configsERPCheck';
