import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {IAtivosMapasOficiais} from './ativosMapasOficiais.module.interface';
import {IJsonAnosCalculo} from '../ativosdepreciacao/jsonAtivosDepreciacao.module.interface';
import {HttpResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AtivosMapasOficiaisService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/ativosmapasoficiais`;
  }

  public getAnos(): Promise<Array<IJsonAnosCalculo>> {
    return this._apiService.get<Array<IJsonAnosCalculo>>({url: `${this._path}/anos`}).then((response: HttpResponse<Array<IJsonAnosCalculo>>) => response.body);
  }

  public getListagemAtivosUrl(listagemAtivos: IAtivosMapasOficiais): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report`, {
      nmapa: listagemAtivos.nMapa,
      reportname: listagemAtivos.reportName,
      natureza: listagemAtivos.natureza,
      metodo: listagemAtivos.metodo,
      estadouso: listagemAtivos.estadouso,
      anoeconomico: listagemAtivos.anoEconomico,
      mes: listagemAtivos.mes,
      codativode: listagemAtivos.codAtivoDe,
      codativoate: listagemAtivos.codAtivoAte,
      ncontade: listagemAtivos.nContaDe,
      ncontaate: listagemAtivos.nContaAte,
      nccustode: listagemAtivos.nCCustoDe,
      nccustoate: listagemAtivos.nCCustoAte,
      codamortde: listagemAtivos.codAmortDe,
      codamortate: listagemAtivos.codAmortAte,
      normal: listagemAtivos.normal,
      abatido: listagemAtivos.abatido,
      vendido: listagemAtivos.vendido,
      dataaquisicaode: listagemAtivos.dataAquisicaoDe,
      dataaquisicaoate: listagemAtivos.dataAquisicaoAte,
      dataabatede: listagemAtivos.dataAbateDe,
      dataabateate: listagemAtivos.dataAbateAte,
      tipoexportacao: listagemAtivos.tipoExportacao
    });
  }
}
