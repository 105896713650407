import {Pipe, PipeTransform} from '@angular/core';
import {isString} from '../common/utilities/utilities';

const NEW_LINE = /\r?\n/g;

@Pipe({
  name: 'cgcNewLine',
  standalone: true
})
export class CGCNewLinePipe implements PipeTransform {
  public transform(value: string): string {
    return newLine(value);
  }
}

export function newLine(value: string): string {
  return isString(value) ? value.replace(NEW_LINE, '<br/>') : value;
}
