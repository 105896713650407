import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EImportadorSaftDirectTipoAnaliticaIS, IImportadorSaftDirectConfigMascaraAnalitica, IImportadorSaftDirectTipoAnaliticaISItem} from '../../importadorSaftDirect.module.interface';
import {ImportadorSaftDirectService} from '../../importadorSaftDirect.module.service';
import {isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';

@Component({
  selector: 'importador-saft-direct-config-mascara-analitca-modal',
  templateUrl: './importadorSaftDirect.configMascaraAnalitica.modal.component.html'
})
export class ImportadorSaftDirectConfigMascaraAnaliticaModalComponent extends CGModalComponent<IImportadorSaftDirectConfigMascaraAnalitica> {
  @Input() public configModel: IImportadorSaftDirectConfigMascaraAnalitica;
  public applyPromise: Promise<void>;
  public tipoAnaliticaISItems: Array<IImportadorSaftDirectTipoAnaliticaISItem>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _importadorSaftDirectService: ImportadorSaftDirectService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.tipoAnaliticaISItems = [
      {value: EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumento, label: 'importadorSaftDirect.strings.porTipoDeDocumento'},
      {value: EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumentoReparticao, label: 'importadorSaftDirect.strings.porTipoDocumentoReparticao'},
      {value: EImportadorSaftDirectTipoAnaliticaIS.PorCliente, label: 'importadorSaftDirect.strings.porCliente'},
      {value: EImportadorSaftDirectTipoAnaliticaIS.PorClienteReparticao, label: 'importadorSaftDirect.strings.porClienteReparticao'},
      {value: EImportadorSaftDirectTipoAnaliticaIS.PorArtigo, label: 'importadorSaftDirect.strings.porArtigo'},
      {value: EImportadorSaftDirectTipoAnaliticaIS.PorArtigoReparticao, label: 'importadorSaftDirect.strings.porArtigoReparticao'}
    ];
  }

  public isEnabledMascaraAC(): boolean {
    return (
      this.configModel.usaAnalitica &&
      this.configModel.tipoAnaliticaIS in
        [EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumento, EImportadorSaftDirectTipoAnaliticaIS.PorCliente, EImportadorSaftDirectTipoAnaliticaIS.PorArtigoReparticao]
    );
  }

  public saveConfig(): void {
    this.configModel.mascara = this.configModel._mascaraObj.mascara;
    if (this.configModel.usaAnalitica) {
      if (
        this.configModel.tipoAnaliticaIS in [EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumento, EImportadorSaftDirectTipoAnaliticaIS.PorArtigo, EImportadorSaftDirectTipoAnaliticaIS.PorCliente]
      ) {
        if (isUndefinedOrNull(this.configModel.mascara) || this.configModel.mascara.length === 0) {
          this._plAlertService.error('importadorSaftDirect.messages.temIndicarMascaraAnalitica');
          return;
        }
      }
    } else {
      this.configModel.mascara = '';
    }

    this.applyPromise = this._importadorSaftDirectService.saveConfiguracoesMascaraAnalitica(this.configModel.usaAnalitica, this.configModel.mascara, this.configModel.tipoAnaliticaIS).then(() => {
      this.close(this.configModel);
    });
  }
}
