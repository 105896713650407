<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div class="toolbar" [ngClass]="[toolbarClass || '', config?.toolbarClass || '']">
  <ng-content></ng-content>
  <ul class="toolbar-list">
    <ng-container *ngFor="let item of config?.items; index as _index">
      <li
        *ngIf="isVisible(item)"
        class="toolbar-list-item list-inline-item"
        [ngClass]="'toolbar-list-item-' + item.type"
        [ngSwitch]="item.type"
        [class.toolbar-list-item-align-right]="item.align === 'right'">
        <!--<pl-animation-collapse [collapsed]="!isCollapsed && isCollapseVisible() && !isTypeExcluded(item.type)">-->
        <div *ngSwitchCase="'button'" class="toolbar-item">
          <pl-tooltip [config]="item.tooltip">
            <button
              *plTooltipContent
              type="button"
              class="btn btn-sm"
              [ngClass]="item.class"
              [click]="fnDoClick(item)"
              [disabled]="item.disabled"
              [attr.data-item-id]="item.id"
              [attr.title]="item.title | cgcTranslate"
              [plPromise]="item.promise">
              <span *ngIf="item.iconLeft" [innerHTML]="item.iconLeft"></span>
              <span *ngIf="item.caption && (!isMobile || !item.hideCaptionOnMobile)" [innerHTML]="item.caption | cgcTranslate"></span>
              <span *ngIf="item.iconRight" [innerHTML]="item.iconRight"></span>
            </button>
          </pl-tooltip>
        </div>

        <div *ngSwitchCase="'button-group'">
          <pl-tooltip [config]="item.tooltip">
            <div *plTooltipContent class="toolbar-item btn-group btn-group-sm" role="group">
              <ng-container *ngFor="let button of item.items">
                <pl-tooltip *ngIf="isVisible(button)" [config]="button.tooltip">
                  <button
                    *plTooltipContent
                    type="button"
                    class="btn btn-sm"
                    [ngClass]="button.class"
                    [click]="fnDoClick(button, item)"
                    [disabled]="item.disabled || button.disabled"
                    [attr.data-item-id]="button.id"
                    [attr.title]="button.title | cgcTranslate"
                    [plPromise]="button.promise">
                    <span *ngIf="button.iconLeft" [innerHTML]="button.iconLeft"></span>
                    <span *ngIf="button.caption && (!isMobile || !button.hideCaptionOnMobile)" [innerHTML]="button.caption | cgcTranslate"></span>
                    <span *ngIf="button.iconRight" [innerHTML]="button.iconRight"></span>
                  </button>
                </pl-tooltip>
              </ng-container>
            </div>
          </pl-tooltip>
        </div>

        <!-- Force download popup only works on same domain origin's -->
        <div *ngSwitchCase="'download'" class="toolbar-item">
          <pl-tooltip [config]="item.tooltip">
            <a
              *plTooltipContent
              rel="noopener noreferrer"
              class="btn btn-sm btn-download"
              [attr.href]="item.download.url"
              [ngClass]="item.class"
              [class.disabled]="!!!item.download.url || item.disabled"
              [attr.data-item-id]="item.id"
              [attr.title]="item.title | cgcTranslate"
              [download]="item.download.filename"
              [attr.target]="item.download.target || defaultDownloadHyperlinkTarget"
              (click)="doClick(item, $event)">
              <span *ngIf="item.iconLeft" [innerHTML]="item.iconLeft"></span>
              <span *ngIf="item.caption && (!isMobile || !item.hideCaptionOnMobile)" [innerHTML]="item.caption | cgcTranslate"></span>
              <span *ngIf="item.iconRight" [innerHTML]="item.iconRight"></span>
            </a>
          </pl-tooltip>
        </div>

        <div *ngSwitchCase="'page'" class="toolbar-item" [attr.data-item-id]="item.id">
          <span *ngIf="item.iconLeft" [innerHTML]="item.iconLeft"></span>
          <span *ngIf="item.caption && (!isMobile || !item.hideCaptionOnMobile)" [innerHTML]="item.caption | cgcTranslate"></span>
          <span *ngIf="item.iconRight" [innerHTML]="item.iconRight"></span>&nbsp;
          <pl-inline-edit type="integer" [onValidate]="fnChangedPage(item)" [properties]="{disabled: item.disabled}" [(model)]="item.page.num"></pl-inline-edit>
          <span>&nbsp;/&nbsp;{{ item.page.total }}</span>
        </div>

        <div *ngSwitchCase="'dropdown'">
          <div class="toolbar-item dropdown" ngbDropdown>
            <pl-tooltip [config]="item.tooltip">
              <button
                *plTooltipContent
                type="button"
                class="btn btn-sm dropdown-toggle"
                ngbDropdownToggle
                [attr.id]="'dropdown.' + item.id + '.' + _index"
                [ngClass]="item.class || 'btn-primary'"
                [click]="fnDoClick(item)"
                [attr.data-item-id]="item.id"
                [attr.title]="item.title | cgcTranslate"
                [disabled]="item.disabled"
                [plPromise]="item.promise">
                <span *ngIf="item.iconLeft" [innerHTML]="item.iconLeft"></span>
                <span *ngIf="item.caption && (!isMobile || !item.hideCaptionOnMobile)" [innerHTML]="item.caption | cgcTranslate"></span>
                <span *ngIf="item.iconRight" [innerHTML]="item.iconRight"></span>
                <span class="caret"></span>
              </button>
            </pl-tooltip>

            <div class="dropdown-menu" role="menu" ngbDropdownMenu [attr.aria-labelledby]="'dropdown.' + item.id + '.' + _index">
              <ng-container *ngFor="let mnu of item.menu">
                <pl-tooltip *ngIf="isVisible(mnu)" [config]="mnu.tooltip">
                  <ng-container *plTooltipContent [ngSwitch]="mnu.type">
                    <button
                      *ngSwitchDefault
                      type="button"
                      class="dropdown-item"
                      [ngClass]="mnu.class"
                      [disabled]="item.disabled || mnu.disabled"
                      [class.active]="mnu.active"
                      [attr.data-item-id]="mnu.id"
                      [attr.title]="mnu.title | cgcTranslate"
                      [click]="fnDoClick(mnu, item)"
                      [plPromise]="mnu.promise">
                      <span *ngIf="mnu.iconLeft" [innerHTML]="mnu.iconLeft"></span>
                      <span *ngIf="mnu.caption" [innerHTML]="mnu.caption | cgcTranslate"></span>
                      <span *ngIf="mnu.iconRight" [innerHTML]="mnu.iconRight"></span>
                    </button>

                    <div *ngSwitchCase="'divider' || 'separator'" class="dropdown-divider"></div>

                    <a
                      *ngSwitchCase="'link'"
                      rel="noopener noreferrer"
                      class="dropdown-item"
                      [attr.href]="mnu.href.value"
                      [attr.target]="mnu.href.target || defaultHyperlinkTarget"
                      [ngClass]="mnu.class"
                      [class.disabled]="item.disabled || mnu.disabled"
                      [class.active]="mnu.active"
                      [attr.data-item-id]="mnu.id"
                      [attr.title]="mnu.title | cgcTranslate"
                      (click)="doClick(mnu, $event, item)">
                      <span *ngIf="mnu.iconLeft" [innerHTML]="mnu.iconLeft"></span>
                      <span *ngIf="mnu.caption" [innerHTML]="mnu.caption | cgcTranslate"></span>
                      <span *ngIf="mnu.iconRight" [innerHTML]="mnu.iconRight"></span>
                    </a>

                    <a
                      *ngSwitchCase="'download'"
                      rel="noopener noreferrer"
                      class="dropdown-item"
                      [attr.href]="mnu.download.url"
                      [ngClass]="mnu.class"
                      [class.disabled]="!!!mnu.download.url || item.disabled || mnu.disabled"
                      [class.active]="mnu.active"
                      [attr.data-item-id]="mnu.id"
                      [attr.title]="mnu.title | cgcTranslate"
                      [attr.target]="mnu.download.target || defaultDownloadHyperlinkTarget"
                      [download]="mnu.download.filename"
                      (click)="doClick(item, $event, item)">
                      <span *ngIf="mnu.iconLeft" [innerHTML]="mnu.iconLeft"></span>
                      <span *ngIf="mnu.caption" [innerHTML]="mnu.caption | cgcTranslate"></span>
                      <span *ngIf="mnu.iconRight" [innerHTML]="mnu.iconRight"></span>
                    </a>
                  </ng-container>
                </pl-tooltip>
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="'dropdown-split'" class="toolbar-item" ngbDropdown>
          <pl-tooltip [config]="item.tooltip">
            <div *plTooltipContent class="btn-group">
              <button
                type="button"
                class="btn btn-sm"
                [attr.id]="'dropdown.' + item.id + '.' + _index"
                [ngClass]="item.class || 'btn-primary'"
                [disabled]="item.disabled"
                [attr.data-item-id]="item.id"
                [attr.title]="item.title | cgcTranslate"
                [click]="fnDoClick(item)"
                [plPromise]="item.promise">
                <span *ngIf="item.iconLeft" [innerHTML]="item.iconLeft"></span>
                <span *ngIf="item.caption && (!isMobile || !item.hideCaptionOnMobile)" [innerHTML]="item.caption | cgcTranslate"></span>
                <span *ngIf="item.iconRight" [innerHTML]="item.iconRight"></span>
              </button>
              <button type="button" class="btn btn-sm dropdown-toggle dropdown-toggle-split" ngbDropdownToggle [ngClass]="item.class" [disabled]="item.disabled">
                <span class="caret"></span>
              </button>
            </div>
          </pl-tooltip>

          <div class="dropdown-menu" role="menu" ngbDropdownMenu [attr.aria-labelledby]="'dropdown.' + item.id + '.' + _index">
            <ng-container *ngFor="let mnu of item.menu">
              <pl-tooltip *ngIf="isVisible(mnu)" [config]="mnu.tooltip">
                <ng-container *plTooltipContent [ngSwitch]="mnu.type">
                  <button
                    *ngSwitchDefault
                    type="button"
                    class="dropdown-item"
                    [ngClass]="mnu.class"
                    [disabled]="mnu.disabled"
                    [class.active]="mnu.active"
                    [attr.data-item-id]="mnu.id"
                    [attr.title]="mnu.title | cgcTranslate"
                    [click]="fnDoClick(mnu, item)"
                    [plPromise]="mnu.promise">
                    <span [innerHTML]="mnu.iconLeft"></span>
                    <span [innerHTML]="mnu.caption | cgcTranslate"></span>
                    <span *ngIf="mnu.iconRight" [innerHTML]="mnu.iconRight"></span>
                  </button>

                  <div *ngSwitchCase="'divider' || 'separator'" class="dropdown-divider"></div>

                  <a
                    *ngSwitchCase="'link'"
                    rel="noopener noreferrer"
                    class="dropdown-item"
                    [attr.href]="mnu.href.value"
                    [ngClass]="mnu.class"
                    [class.disabled]="mnu.disabled"
                    [class.active]="mnu.active"
                    [attr.target]="mnu.href.target || defaultHyperlinkTarget"
                    [attr.data-item-id]="mnu.id"
                    [attr.title]="mnu.title | cgcTranslate"
                    (click)="doClick(mnu, $event, item)">
                    <span *ngIf="mnu.iconLeft" [innerHTML]="mnu.iconLeft"></span>
                    <span *ngIf="mnu.caption" [innerHTML]="mnu.caption | cgcTranslate"></span>
                    <span *ngIf="mnu.iconRight" [innerHTML]="mnu.iconRight"></span>
                  </a>

                  <a
                    *ngSwitchCase="'download'"
                    rel="noopener noreferrer"
                    class="dropdown-item"
                    [attr.href]="mnu.download.url"
                    [ngClass]="mnu.class"
                    [class.disabled]="!!!mnu.download.url || mnu.disabled"
                    [class.active]="mnu.active"
                    [attr.data-item-id]="mnu.id"
                    [attr.title]="mnu.title | cgcTranslate"
                    [attr.target]="mnu.download.target || defaultDownloadHyperlinkTarget"
                    [download]="mnu.download.filename"
                    (click)="doClick(item, $event, item)">
                    <span *ngIf="mnu.iconLeft" [innerHTML]="mnu.iconLeft"></span>
                    <span *ngIf="mnu.caption" [innerHTML]="mnu.caption | cgcTranslate"></span>
                    <span *ngIf="mnu.iconRight" [innerHTML]="mnu.iconRight"></span>
                  </a>
                </ng-container>
              </pl-tooltip>
            </ng-container>
          </div>
        </div>

        <div *ngSwitchCase="'html'" class="toolbar-item" [innerHTML]="item.caption" [attr.data-item-id]="item.id"></div>

        <div *ngSwitchCase="'custom'" class="toolbar-item" [attr.data-item-id]="item.id">
          <ng-container *ngTemplateOutlet="item.templateRef; context: {$implicit: item, item: item}"></ng-container>
        </div>

        <div *ngSwitchCase="'title'" class="toolbar-item" [attr.data-item-id]="item.id">
          <pl-tooltip [config]="item.tooltip">
            <h5 *plTooltipContent [innerHTML]="item.caption | cgcTranslate" [attr.title]="item.title | cgcTranslate"></h5>
          </pl-tooltip>
        </div>

        <div *ngSwitchCase="'link'" class="toolbar-item">
          <pl-tooltip [config]="item.tooltip">
            <a
              *plTooltipContent
              rel="noopener noreferrer"
              [attr.href]="item.href.value"
              [attr.target]="item.href.target || defaultHyperlinkTarget"
              [ngClass]="item.class"
              [class.disabled]="item.disabled"
              [attr.data-item-id]="item.id"
              [attr.title]="item.title | cgcTranslate"
              (click)="doClick(item, $event)">
              <span *ngIf="item.iconLeft" [innerHTML]="item.iconLeft"></span>
              <span *ngIf="item.caption && (!isMobile || !item.hideCaptionOnMobile)" [innerHTML]="item.caption | cgcTranslate"></span>
              <span *ngIf="item.iconRight" [innerHTML]="item.iconRight"></span>
            </a>
          </pl-tooltip>
        </div>

        <ng-container *ngSwitchCase="'search'">
          <div *ngIf="config?.search.active" class="toolbar-item navbar-form pl-toolbar-form" [attr.name]="item.groupId + '-searchForm-' + item.id" [attr.data-item-id]="item.id">
            <div class="mb-3">
              <div class="input-group pl-input-group input-form" #elementSearchForm>
                <input
                  type="text"
                  class="form-control"
                  autocapitalize="off"
                  autocomplete="off"
                  [attr.placeholder]="config?.search.placeholder | cgcTranslate"
                  [plDebounce]="search.text"
                  [plDebounceAfter]="500"
                  [(ngModel)]="search.text"
                  (focus)="searchFocus(elementSearchForm)"
                  (blur)="searchBlur(elementSearchForm)"
                  (keyup)="searchKeyUp($event)"
                  (evtDebounced)="doSearch($event)" />
                <button *ngIf="search.text.length" type="button" class="btn hollow-form" (click)="clearSearch()">
                  <i class="pl-icon-close"></i>
                </button>
                <button *ngIf="config?.search.button" type="button" class="btn btn-primary btn-search" (click)="doSearch(search.text)">
                  <i class="pl-icon-search"></i>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <!--</pl-animation-collapse>-->
      </li>
    </ng-container>

    <!-- Do not delete, may be necessary later
    <li class="pl-toolbar-collapse" *ngIf="isCollapseVisible()">
      <button type="button" class="btn btn-sm btn-primary btn-circle" (click)="isCollapsed = !isCollapsed">
        <i class="fa fa-cog"></i>
      </button>
    </li>-->
  </ul>
</div>
