import {TDate} from '../../../common/dates';
import {ETipoContaContabilidade} from '../../datasources/tipospoc/tiposPoc.datasource.interface';

export enum EPOCAltTipoValidacao {
  ValidaGeral,
  NaoValida,
  ValidaAnalitica,
  ValidaRadicais
}

export interface IJsonPlanoContasAlternativoCab {
  pocAltCabID: string;
  nome: string;
  tipoValidacao: EPOCAltTipoValidacao;
  radicaisValidacao: string;
  inverteSinal: boolean;
  linhas: Array<IJsonPlanoContasAlternativoLin>;
}

export interface IJsonPlanoContasAlternativoLin {
  pocAltLinID: string;
  nconta: string;
  ncontaPai: string;
  tipo: ETipoContaContabilidade;
  nome: string;
  contas: string;
}

export interface IJsonPlanoContasAlternativoCubo {
  periodo: string;
  nconta: string;
  ncontaPai: string;
  nome: string;
  ano: number;
  mes: number;
  tipo: string;
  mesStr: string;
  periodoDebito: number;
  periodoCredito: number;
  acumuladoDebito: number;
  acumuladoCredito: number;
  saldoDebito: number;
  saldoCredito: number;
  saldoGeral: number;
  ncontaNome: string;
  nomeNConta: string;
  nmesNome: string;
}

export interface IJsonPlanoContasAlternativoExtrato {
  nconta: string;
  nome: string;
  data: TDate;
  periodo: string;
  nDiario: number;
  nDocInterno: string;
  nDocExterno: string;
  documento: string;
  debito: number;
  credito: number;
  saldo: number;
}

export interface IJsonPlanoContasAlternativoRadicais {
  nRadicalConta: string;
  nome: string;
  incExc: string;
}
