import {TDate} from '../../../common/dates';
import {ETipoAbonoDesconto} from '../../entities/abdes/abdes.entity.interface';

export enum ETipoEscolhaUtlViatPatronal {
  Ativas,
  Inativas,
  Todas
}

export enum ETipoOrdenacao {
  CodEmp,
  Nome
}

export interface IJsonUtlViatPatronal {
  nome: string;
  abdesNome: string;
  codABDESC: string;
  processaVenc: boolean;
  rhTipoProce: string;
  abonoDesc: ETipoAbonoDesconto;
  rhUltViatPatronalID: string;
  nCODABDESC: string;
  codEmp: number;
  vAquisicaoViatura: number;
  vMercadoViatura: number;
  anoProcessamento: number;
  mesInicioProce: number;
  observacoes: string;
  suspende: boolean;
  dataSuspensao: TDate;
  obsSupencao: string;
  tipoProcessamento: number;
  valorIncidenciaSS: number;
  valorIncidenciaIRS: number;
}

export interface IJsonUtlViatPatronalIncidencias {
  incidenciaSegSocial: number;
  incidenciaIRS: number;
}
