<pl-form (formInstanceChange)="changedFormGroup($event)" [properties]="{readonly: readonly}">
  <pl-group>
    <label><span [translate]="'reparticoesccustos.fields.nCodRepCC'"></span>:</label>
    <edit>
      <pl-edit
        type="cgsmallint"
        [(model)]="model.nCodRepCC"
        attrName="tabela"
        [properties]="{
          modelOptions: {updateOn: 'blur'},
          validators: {
            required: {value: true},
            codTabela: validatorCodTabela
          },
          disabled: !new
        }">
      </pl-edit>
    </edit>
  </pl-group>

  <pl-group>
    <label><span [translate]="'reparticoesccustos.fields.mascara'"></span>:</label>
    <edit>
      <entity-autocomplete
        entity="mascarasanalitica"
        attrName="mascaraReparticoesCCusto"
        [model]="model"
        [selectedKey]="model.mascara"
        (selectedKeyChange)="changedMascara($event)"
        [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}, disabled: !new, outputKey: 'mascara'}"
        [fieldsMap]="{mascara: 'mascara'}"
        [output]="'mascara'">
      </entity-autocomplete>
    </edit>
  </pl-group>

  <pl-group>
    <label><span [translate]="'reparticoesccustos.fields.descricao'"></span>:</label>
    <edit>
      <pl-edit
        type="text"
        [(model)]="model.descricao"
        attrName="descricao"
        [properties]="{
          validators: {
            maxlength: {value: 50},
            required: {value: true}
          },
          disabled: !new
        }">
      </pl-edit>
    </edit>
  </pl-group>

  <div [ngSwitch]="perDate">
    <div *ngSwitchCase="false">
      <ng-container *ngTemplateOutlet="templateSource; context: {item: model.items[0]}"></ng-container>
    </div>

    <div *ngSwitchCase="true">
      <pl-form formInstanceName="items">
        <pl-navpill attrName="datareferencia" [callback]="plNavPill" (evtSelected)="changedPill($event)">
          <pl-navpill-panel *ngFor="let item of model.items; index as i" id="_{{ i }}">
            <div *plNavPillPanelTitle>
              <ng-container [ngSwitch]="item.dataReferencia">
                <div class="clearfix">
                  <div class="pull-left">
                    <div *ngSwitchCase="undefined" [translate]="'reparticoesccustos.text.selectDate'"></div>
                    <div *ngSwitchDefault>{{ item.dataReferencia | cgcDate }}</div>
                  </div>

                  <div class="pull-right" *ngIf="model.items.length > 1 && !readonly">
                    <button type="button" class="btn btn-danger btn-xs action-remove-item" [click]="deleteItem(item)" plPromise>
                      <i class="fa fa-fw fa-minus" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>

            <div *plNavPillPanelContent>
              <pl-form formInstanceName="item{{ i }}">
                <pl-group [properties]="{orientation: 'vertical'}">
                  <label [translate]="'reparticoesccustos.fields.dataReferencia'"></label>
                  <edit>
                    <pl-edit
                      type="date"
                      attrName="dataReferencia"
                      [(model)]="item.dataReferencia"
                      [properties]="{validators: {required: {value: true}, dataReferencia: validatorDataReferencia}}"></pl-edit>
                  </edit>
                </pl-group>

                <ng-container *ngTemplateOutlet="templateSource; context: {item: item}"></ng-container>
              </pl-form>
            </div>
          </pl-navpill-panel>

          <ng-container *ngIf="!readonly">
            <div class="hyperlink" *plNavPillFooter [translate]="'reparticoesccustos.text.addDate'" (click)="addReferenceDate()"></div>
          </ng-container>
        </pl-navpill>
      </pl-form>
    </div>
  </div>
</pl-form>

<ng-template #templateSource let-item="item">
  <dx-data-grid
    [cgDxDataGrid]="definitionReparticao"
    cgDxDataGridInstanceName="reparticaoCentroCustoReparticao"
    [dataSource]="definitionReparticao.dataSource"
    (onInitialized)="onInitialized($event)"
    (onCellClick)="onCellClick($event)">
    <div *dxTemplate="let headerInfo of 'headerTemplateAddLinha'">
      <button type="button" class="btn btn-primary btn-xs action-add-line" *ngIf="!readonly" [click]="addLine(item)" plPromise>
        <i class="fa fa-fw fa-plus"></i>
      </button>
    </div>

    <div *dxTemplate="let linha of 'actions'">
      <button type="button" class="btn btn-danger btn-xs action-remove-line" *ngIf="!readonly" [click]="deleteLine(linha.data.repCCID, linha.data, item)" plPromise>
        <i class="fa fa-fw fa-minus"></i>
      </button>
    </div>

    <div *dxTemplate="let linha of 'detail'; let linhaIndex = index">
      <pl-form formInstanceName="linha{{ linhaIndex }}" [properties]="{readonly: readonly, orientation: 'horizontal', labelClass: 'col-md-3', hideActions: true}">
        <pl-group>
          <label><span [translate]="'reparticoesccustos.fields.perc'"></span>:</label>
          <edit>
            <pl-edit
              type="cgnumber"
              attrName="percentagem"
              [model]="linha.data.perc"
              (modelChange)="linha.data.perc = $event; updateTable()"
              [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group *ngIf="showCentroCusto">
          <label><span [translate]="'reparticoesccustos.mascara.cCusto'"></span>:</label>
          <edit>
            <entity-autocomplete
              entity="ccustos"
              attrName="cCustoReparticoesCCusto"
              [model]="linha.data"
              [(selectedKey)]="linha.data.cCusto"
              [fieldsMap]="{nCCusto: 'cCusto', nome: 'nomeCCusto'}"
              [properties]="{validators: {required: {value: true}}}"
              (evtSelectedDescriptionChanged)="linha.data.nomeCCusto = $event; populateDesignacao($event, linha.data)"
              [output]="'nome'"
              [filter]="'encerrado=0'">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="showZona">
          <label><span [translate]="'reparticoesccustos.mascara.zona'"></span>:</label>
          <edit>
            <entity-autocomplete
              entity="zonas"
              attrName="zonaReparticoesCCusto"
              [model]="linha.data"
              [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}}"
              [(selectedKey)]="linha.data.zona"
              (evtSelectedDescriptionChanged)="linha.data.nomeZona = $event; populateDesignacao($event, linha.data)"
              [fieldsMap]="{nZona: 'zona', nome: 'nomeZona'}"
              [output]="'nome'">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="showDepartamento">
          <label><span [translate]="'reparticoesccustos.mascara.departamento'"></span>:</label>
          <edit>
            <entity-autocomplete
              entity="depto"
              attrName="departamentoReparticoesCCusto"
              [model]="linha.data"
              [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}}"
              [(selectedKey)]="linha.data.departamento"
              (evtSelectedDescriptionChanged)="linha.data.nomeDepartamento = $event; populateDesignacao($event, linha.data)"
              [fieldsMap]="{nDepto: 'departamento', nome: 'nomeDepartamento'}"
              [output]="'nome'">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="showSubDepartamento">
          <label><span [translate]="'reparticoesccustos.mascara.SubDepartamento'"></span>:</label>
          <edit>
            <entity-autocomplete
              entity="subde"
              attrName="SubDepartamentoReparticoesCCusto"
              [model]="linha.data"
              [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}}"
              [(selectedKey)]="linha.data.subDepartamento"
              (evtSelectedDescriptionChanged)="linha.data.nomeSubDepartamento = $event; populateDesignacao($event, linha.data)"
              [fieldsMap]="{nSubde: 'subDepartamento', nome: 'nomeSubDepartamento'}"
              [output]="'nome'">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="showFamilia">
          <label><span [translate]="'reparticoesccustos.mascara.familia'"></span>:</label>
          <edit>
            <entity-autocomplete
              entity="familias"
              attrName="familiaReparticoesCCusto"
              [model]="linha.data"
              [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}}"
              [(selectedKey)]="linha.data.familia"
              (evtSelectedDescriptionChanged)="linha.data.nomeFamilia = $event; populateDesignacao($event, linha.data)"
              [fieldsMap]="{nFamilia: 'familia', nome: 'nomeFamilia'}"
              [output]="'nome'">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="showGrandeFamilia">
          <label><span [translate]="'reparticoesccustos.mascara.grandeFamilia'"></span>:</label>
          <edit>
            <entity-autocomplete
              entity="grandesfamilias"
              attrName="grandeFamiliaReparticoesCCusto"
              [model]="linha.data"
              [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}}"
              [(selectedKey)]="linha.data.grandeFamilia"
              (evtSelectedDescriptionChanged)="linha.data.nomeGrandeFamilia = $event; populateDesignacao($event, linha.data)"
              [fieldsMap]="{nDepart: 'grandeFamilia', nome: 'nomeGrandeFamilia'}"
              [output]="'nome'">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="showSubFamilia">
          <label><span [translate]="'reparticoesccustos.mascara.subFamilia'"></span>:</label>
          <edit>
            <entity-autocomplete
              entity="subfamilias"
              attrName="subFamiliaReparticoesCCusto"
              [model]="linha.data"
              [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}}"
              [(selectedKey)]="linha.data.subFamilia"
              (evtSelectedDescriptionChanged)="linha.data.nomeSubFamilia = $event; populateDesignacao($event, linha.data)"
              [fieldsMap]="{nSubFa: 'subFamilia', nome: 'nomeSubFamilia'}"
              [output]="'nome'">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="showCodContab">
          <label><span [translate]="'reparticoesccustos.mascara.codContab'"></span>:</label>
          <edit>
            <entity-autocomplete
              entity="codigoscontabilisticos"
              attrName="codContabReparticoesCCusto"
              [model]="linha.data"
              [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}}"
              [(selectedKey)]="linha.data.codContab"
              (evtSelectedDescriptionChanged)="linha.data.nomeContab = $event; populateDesignacao($event, linha.data)"
              [fieldsMap]="{codcontab: 'codContab', nome: 'nomeContab'}"
              [output]="'nome'">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="showTipoArtigo">
          <label><span [translate]="'reparticoesccustos.mascara.tipoArtigo'"></span>:</label>
          <edit>
            <entity-autocomplete
              entity="tiposartigo"
              attrName="tipoArtigoReparticoesCCusto"
              [model]="linha.data"
              [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}}"
              [(selectedKey)]="linha.data.tipoArtigo"
              (evtSelectedDescriptionChanged)="linha.data.nomeTipoArtigo = $event; populateDesignacao($event, linha.data)"
              [fieldsMap]="{codTpArt: 'tipoArtigo', nome: 'nomeTipoArtigo'}"
              [output]="'nome'">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="showClasse">
          <label><span [translate]="'reparticoesccustos.mascara.classe'"></span>:</label>
          <edit>
            <entity-autocomplete
              entity="artigoclasses"
              attrName="classeReparticoesCCusto"
              [model]="linha.data"
              [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}}"
              [(selectedKey)]="linha.data.classe"
              (evtSelectedDescriptionChanged)="linha.data.nomeClasse = $event; populateDesignacao($event, linha.data)"
              [fieldsMap]="{codTpArt: 'tipoArtigo', descricao: 'nomeTipoArtigo'}"
              [output]="'nome'">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="showVendedor">
          <label><span [translate]="'reparticoesccustos.mascara.vendedor'"></span>:</label>
          <edit>
            <entity-autocomplete
              entity="vendedores"
              attrName="categoriaReparticoesVendedores"
              [model]="linha.data"
              [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}}"
              [(selectedKey)]="linha.data.vendedor"
              (evtSelectedDescriptionChanged)="linha.data.nomeVendedores = $event; populateDesignacao($event, linha.data)"
              [fieldsMap]="{nVendedor: 'vendedor', descricao: 'nomeVendedores'}"
              [output]="'nome'">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="showCategoria">
          <label><span [translate]="'reparticoesccustos.mascara.categoria'"></span>:</label>
          <edit>
            <entity-autocomplete
              entity="categoriaartigo"
              attrName="categoriaReparticoesCCusto"
              [model]="linha.data"
              [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}}"
              [(selectedKey)]="linha.data.categoria"
              (evtSelectedDescriptionChanged)="linha.data.nomeCategoria = $event; populateDesignacao($event, linha.data)"
              [fieldsMap]="{categoria: 'categoria', descricao: 'nomeCategoria'}"
              [output]="'nome'">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="showNRefProcesso">
          <label><span [translate]="'reparticoesccustos.mascara.nRefProcesso'"></span>:</label>
          <edit>
            <entity-autocomplete
              entity="processos"
              attrName="nRefProcessoReparticoesCCusto"
              [model]="linha.data"
              [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}}"
              [(selectedKey)]="linha.data.nRefProcesso"
              (evtSelectedDescriptionChanged)="linha.data.nomeProcesso = $event; populateDesignacao($event, linha.data)"
              [fieldsMap]="{nRefProcesso: 'nRefProcesso', nome: 'nomeProcesso'}"
              [output]="'nome'">
            </entity-autocomplete>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </dx-data-grid>
</ng-template>
