<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'apuraiva.saveTitle'"></h4>
</div>

<div class="modal-body">
  <p *ngIf="message !== ''" [ngClass]="cssMessage" [translate]="message"></p>
  <span [translate]="'apuraiva.saveMessage'"></span>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-primary action-save" (click)="close('save')"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></button>
  <button type="button" class="btn btn-warning action-save-see" (click)="close('doc')"><i class="fa fa-fw fa-file-text-o"></i>&nbsp;<span [translate]="'apuraiva.saveSeeDoc'"></span></button>
  <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
