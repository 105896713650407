import {Component, Injector, OnInit} from '@angular/core';
import {isObject} from 'pl-comps-angular';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonCateg} from '../../jsonCateg.entity.interface';
import {IJsonQPIRT} from '../../../qpirt/jsonQPIRT.entity.interface';
import {IJsonQPCategoria} from '../../../qpcategorias/jsonQPCategorias.entity.interface';

@Component({
  selector: 'categorias-edit',
  templateUrl: './categorias.entity.edit.component.html'
})
export class CategoriasEditComponent extends ModuloEntityDetailComponent<IJsonCateg> implements OnInit {
  public qpirtOutput: string;
  public qpcategoriasOutput: string;
  public modelQPCategorias: IJsonQPCategoria;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.qpirtOutput = '{{codirct}} - {{nome}}';
    this.qpcategoriasOutput = '{{codCategoria}} - {{nome}}';
    this.modelQPCategorias = {
      codCategoria: this.model.codCategoriaMT ? this.model.codCategoriaMT : '',
      codIrct: this.model.codIrct ? this.model.codIrct : '',
      nome: this.model.nomeCategoria ? this.model.nomeCategoria : '',
      nomeIrct: this.model.nomeIrct ? this.model.nomeIrct : ''
    };
  }

  public onCodIrctChange(qpirt: IJsonQPIRT): void {
    if (isObject(qpirt)) {
      this.modelQPCategorias.codIrct = qpirt.codirct;
      this.modelQPCategorias.nomeIrct = qpirt.nome;
    } else {
      this.modelQPCategorias = {...this.modelQPCategorias, nome: '', codIrct: ''};
      this.model.codIrct = '';
    }
    this.model = {...this.model, codCategoriaMT: '', nomeCategoria: ''};
  }
}
