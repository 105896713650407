import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_GRUPO_DOC, EGrupoDoc, grupoDocToDocType, IGrupoDoc} from './grupoDoc.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {Writeable} from '../../../common/interfaces/interfaces';

export const DATA_SOURCE_GRUPO_DOC: IDataSource<EGrupoDoc, IGrupoDoc> = {
  name: DATA_SOURCE_NAME_GRUPO_DOC,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: 'nome',
    output: 'nome',
    outputKey: 'id'
  },
  data: deepFreeze(
    evaluateGrupoDocTipos([
      {id: EGrupoDoc.Outros, nome: 'grupoDoc.outros', tipo: undefined},
      {id: EGrupoDoc.ComprasEfectivas, nome: 'grupoDoc.comprasEfetivas', tipo: undefined},
      {id: EGrupoDoc.ConsultasAFornecedores, nome: 'grupoDoc.consultasAFornecedores', tipo: undefined},
      {id: EGrupoDoc.EncomendasClientes, nome: 'grupoDoc.encomendasClientes', tipo: undefined},
      {id: EGrupoDoc.EncomendasFornecedores, nome: 'grupoDoc.encomendasFornecedores', tipo: undefined},
      {id: EGrupoDoc.FolhasDeObra, nome: 'grupoDoc.folhasDeObra', tipo: undefined},
      {id: EGrupoDoc.GuiasTransporteRemessa, nome: 'grupoDoc.guiasTransporteRemessa', tipo: undefined},
      {id: EGrupoDoc.PropostasAClientes, nome: 'grupoDoc.propostasAClientes', tipo: undefined},
      {id: EGrupoDoc.ReservasClientes, nome: 'grupoDoc.reservasClientes', tipo: undefined},
      {id: EGrupoDoc.TrfArmazensEntradas, nome: 'grupoDoc.trfArmazensEntradas', tipo: undefined},
      {id: EGrupoDoc.TrfArmazensSaidas, nome: 'grupoDoc.trfArmazensSaidas', tipo: undefined},
      {id: EGrupoDoc.VendasEfectivas, nome: 'grupoDoc.vendasEfectivas', tipo: undefined},
      {id: EGrupoDoc.ConsignacoesClientes, nome: 'grupoDoc.consignacoesClientes', tipo: undefined},
      {id: EGrupoDoc.ConsignacoesFornecedores, nome: 'grupoDoc.consignacoesFornecedores', tipo: undefined},
      {id: EGrupoDoc.RecibosClientes, nome: 'grupoDoc.recibosClientes', tipo: undefined},
      {id: EGrupoDoc.PagamentosFornecedores, nome: 'grupoDoc.pagamentosFornecedores', tipo: undefined},
      {id: EGrupoDoc.None, nome: 'grupoDoc.none', tipo: undefined},
      {id: EGrupoDoc.PlanoProducao, nome: 'grupoDoc.planoProducao', tipo: undefined},
      {id: EGrupoDoc.PreEncomendasClientes, nome: 'grupoDoc.preEncomendasClientes', tipo: undefined},
      {id: EGrupoDoc.CustosAdicionais, nome: 'grupoDoc.custosAdicionais', tipo: undefined},
      {id: EGrupoDoc.NotaCredito, nome: 'grupoDoc.notaCredito', tipo: undefined},
      {id: EGrupoDoc.AcertoCustoPadrao, nome: 'grupoDoc.acertoCustoPadrao', tipo: undefined},
      {id: EGrupoDoc.EntradasDiversas, nome: 'grupoDoc.entradasDiversas', tipo: undefined},
      {id: EGrupoDoc.SaidasDiversas, nome: 'grupoDoc.saidasDiversas', tipo: undefined},
      {id: EGrupoDoc.GuiasTransporteFornecedores, nome: 'grupoDoc.guiasTransporteFornecedores', tipo: undefined}
    ])
  )
};

function evaluateGrupoDocTipos(data: Array<IGrupoDoc>): Array<IGrupoDoc> {
  for (const item of data) {
    (<Writeable<IGrupoDoc>>item).tipo = grupoDocToDocType(item.id);
  }
  return data;
}
