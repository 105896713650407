import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlPageWrapperModule} from '../pagewrapper/pagewrapper.module';
import {PlPipesModule} from '../pipes/pipes.module';
import {PlPromisesModule} from '../promises/promises.module';
import {PlTooltipModule} from '../tooltip/tooltip.module';
import {PlTranslateModule} from '../translate/translate.module';
import {CGCIconDirective} from '../icon';

import {PlNavWizardComponent} from './navwizard.component';
import {PlNavWizardStepDirective} from './navwizard.step.component';
import {PlNavWizardStepCaptionDirective} from './navwizard.step.caption.directive';
import {PlNavWizardStepContentDirective} from './navwizard.step.content.directive';
import {PlNavWizardActionsComponent} from './actions/navwizard.actions.component';

export * from './navwizard.interface';
export * from './navwizard.component';
export * from './navwizard.step.component';
export * from './navwizard.step.caption.directive';
export * from './navwizard.step.content.directive';
export * from './actions/navwizard.actions.component';

const DECLARATIONS = [PlNavWizardComponent, PlNavWizardStepDirective, PlNavWizardStepCaptionDirective, PlNavWizardStepContentDirective, PlNavWizardActionsComponent];

@NgModule({
  imports: [CommonModule, PlPageWrapperModule, PlPipesModule, PlPromisesModule, PlTooltipModule, PlTranslateModule, CGCIconDirective],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlNavWizardModule {}
