import {isArray, isEmpty, isObject} from 'pl-comps-angular';
import {IJsonUserEmpresa, IJsonUserRole} from '../../core/services/account/jsonUserApi.interface';
import {ROLE} from '../../core/services/role.const';

/**
 * Checks whether `userEmpresa` has role or <b>all</b> roles in the list
 * @returns True if `userEmpresa` has role or <b>all</b> roles in the list
 */
export function hasAuthority(userEmpresa: IJsonUserEmpresa, roleOrRoles: string | ROLE | Array<string | ROLE>): boolean {
  if (!isObject(userEmpresa) || !isArray(userEmpresa.roles)) {
    return false;
  }
  if (isEmpty(roleOrRoles)) {
    return true;
  }
  if (!isArray(roleOrRoles)) {
    roleOrRoles = [roleOrRoles];
  }
  const identityRoles: Array<string | ROLE> = userEmpresa.roles.map<string | ROLE>((userRole: IJsonUserRole) => userRole.role.toLowerCase());
  for (const role of roleOrRoles) {
    if (!identityRoles.includes(role.toLowerCase())) {
      return false;
    }
  }
  return true;
}

/**
 * Checks whether `userEmpresa` has role or <b>at least one</b> role in the list
 * @returns True if `userEmpresa` has role or <b>at least one</b> role in the list
 */
export function hasAnyAuthority(userEmpresa: IJsonUserEmpresa, roleOrRoles: string | ROLE | Array<string | ROLE>): boolean {
  if (!isObject(userEmpresa) || !isArray(userEmpresa.roles)) {
    return false;
  }
  if (!roleOrRoles?.length) {
    return true;
  }
  if (!isArray(roleOrRoles)) {
    roleOrRoles = [roleOrRoles];
  }
  const userRoles: Array<string | ROLE> = userEmpresa.roles.map<string | ROLE>((userRole: IJsonUserRole) => userRole.role.toUpperCase());
  for (let role of roleOrRoles) {
    role = role.toUpperCase();
    if (role === ROLE.ANY || userRoles.includes(role)) {
      return true;
    }
  }
  return false;
}

/**
 * Checks whether `userEmpresa` is an Admin (has ADMIN role)
 * @returns True if `userEmpresa` has ADMIN role
 */
export function hasAdminAuthority(userEmpresa: IJsonUserEmpresa): boolean {
  return hasAuthority(userEmpresa, ROLE.ADMIN);
}
