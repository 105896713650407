import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {debounce, isNumber} from '../common/utilities/utilities';

const DEBOUNCE_ID = 'pl-page-wrapper-debounce-set-showing';
const DEBOUNCE_TIME = 150;

@Injectable({
  providedIn: 'root'
})
export class PlPageWrapperService implements OnDestroy {
  private readonly _subjectToggled: BehaviorSubject<boolean>;
  private readonly _subjectShowing: BehaviorSubject<boolean>;
  private _showingDebounceId: number;
  private _observableToggled: Observable<boolean>;
  private _observableShowing: Observable<boolean>;

  constructor() {
    this._subjectToggled = new BehaviorSubject<boolean>(false);
    this._subjectShowing = new BehaviorSubject<boolean>(false);
  }

  public ngOnDestroy(): void {
    this.cancelSetShowingSidebar();
    this._subjectToggled.complete();
    this._subjectShowing.complete();
  }

  public toggled(): Observable<boolean> {
    if (!this._observableToggled) {
      this._observableToggled = this._subjectToggled.asObservable();
    }
    return this._observableToggled;
  }

  public setToggled(value: boolean): void {
    this._subjectToggled.next(value);
  }

  public toggleSidebar(): void {
    this.setToggled(!this._subjectToggled.value);
  }

  public showingSideBar(): Observable<boolean> {
    if (!this._observableShowing) {
      this._observableShowing = this._subjectShowing.asObservable();
    }
    return this._observableShowing;
  }

  public setShowingSideBar(value: boolean): void {
    this._subjectShowing.next(value);
  }

  public setShowingSidebarWithDebounce(value: boolean, debounceTime: number): void {
    this._showingDebounceId = debounce(() => {
      this.setShowingSideBar(value);
    }, debounceTime, DEBOUNCE_ID);
  }

  public cancelSetShowingSidebar(): void {
    if (isNumber(this._showingDebounceId)) {
      window.clearTimeout(this._showingDebounceId);
      this._showingDebounceId = undefined;
    }
  }

  public showSidebarWithDebounce(): void {
    this.setShowingSidebarWithDebounce(true, DEBOUNCE_TIME);
  }

  public hideSidebarWithDebounce(): void {
    this.setShowingSidebarWithDebounce(false, DEBOUNCE_TIME);
  }
}
