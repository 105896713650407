import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {GestaoComunicacoesConfiguracaoModalComponent} from '../../modais/config/gestaoComunicacoes.configuracao.modal.component';
import {
  IJsonGestaoComunicacoesConfigEmpresaReg,
  IJsonGestaoComunicacoesConfigUtilizador,
  IJsonGestaoComunicacoesEmpresa,
  IJsonGestaoComunicacoesEmpresaEstado,
  IJsonGestaoComunicacoesEmpresaEstados,
  IJsonGestaoComunicacoesTopico,
  IJsonGestoComunicacoesConfig
} from '../../jsonGestaoComunicacoesTopico.module.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {GestaoComunicacoesTopicoService} from '../../gestaoComunicacoesTopico.module.service';
import {HttpResponse} from '@angular/common/http';
import type {AddEvent, DragStartEvent, ReorderEvent} from 'devextreme/ui/sortable';
import {copy, isEmpty, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {GestaoComunicacoesEstadosEmpresaConfigModalComponent} from '../../modais/estadoEmpresaConfig/gestaoComunicacoes.estadosEmpresaConfig.modal.component';
import {GestaoComunicacoesEstadoService} from '../../gestaoComunicacoesEstado.module.service';
import {EGestaoComunicacoesEstadoTipo, IJsonGestaoComunicacoesEstadoOrdem} from '../../jsonGestaoComunicacoesEstado.module.interface';
import {ENTITY_NAME_EMPRESAS_ERP, IEmpresaExtended} from '../../../../../entities/empresas/empresas.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {GestaoComunicacoesTopicoModalComponent} from '../../modais/topico/new/gestaoComunicacoes.topico.modal.component';
import {GestaoComunicacoesTopicoDetailModalComponent} from '../../modais/topico/detail/gestaoComunicacoes.topico.detail.modal.component';
import {IJsonGestaoComunicacoesMensagem} from '../../jsonGestaoComunicacoesMensagem.module.interface';
import {GestaoComunicacoesTopicoEditModalComponent} from '../../modais/topico/edit/gestaoComunicacoes.topico.edit.modal.component';
import {IDevExpressDataGrid, IDevExpressDataGridLoadResult} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  generateGestaoComunicacoesDate,
  generateUtilizadorShow,
  GESTAO_COMUNICACAO_COLOR_PERCENT,
  IGestaoComunicacoesQueryParamAdditionalFields,
  lightenColor
} from '../../gestaoComunicacoes.module.interface';
import {IApiQueryResponse} from '../../../../../services/api/api.service.interface';
import dxDataGrid from 'devextreme/ui/data_grid';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized
} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {Properties} from 'devextreme/ui/button';
import CustomStore from 'devextreme/data/custom_store';
import {LoadOptions} from 'devextreme/data';
import {devExpressDataGridFiltersToQueryFilter} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DevExpressDatagridPaging} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.paging';
import {MIN_DATE_CGD} from '../../../../../../common/utils/utils';
import moment from 'moment';

const NUMBER_FOUR = 4;
const NUMBER_TEN = 10;

@Component({
  selector: 'module-gestao-comunicacoes',
  templateUrl: './gestaoComunicacoes.module.component.html'
})
export class GestaoComunicacoesModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public topicoEmpresas: Array<IJsonGestaoComunicacoesEmpresa>;

  public readonly eTipoEstados: typeof EGestaoComunicacoesEstadoTipo;
  public readonly dataGridListView: IDevExpressDataGrid<IJsonGestaoComunicacoesTopico, string>;
  public readonly dataGridListViewSource: CustomStore<IJsonGestaoComunicacoesTopico, string>;

  public empresaEstadosTopicos: IJsonGestaoComunicacoesEmpresaEstados;
  public empresaEstadosTopicosCopy: IJsonGestaoComunicacoesEmpresaEstados;
  public empresaTopicosCompact: Array<IJsonGestaoComunicacoesTopico>;
  public empresaEstadosCompact: Array<string>;
  public topicoEmpresasCopy: Array<IJsonGestaoComunicacoesEmpresa>;
  public filterEmpresas: string;
  public filterTopicos: string;
  public empresaSelected: string;
  public empresaSelectedDados: IEmpresaExtended;
  public vistaKanban: boolean;
  public promiseLoading: Promise<void>;

  private readonly _serviceEmpresas: IEntityService<IEmpresaExtended>;

  private _dataGridInstance: dxDataGrid<IJsonGestaoComunicacoesTopico, string>;
  private _dataGridPaging: DevExpressDatagridPaging<IJsonGestaoComunicacoesTopico, string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _gestaoComunicacoesTopicoService: GestaoComunicacoesTopicoService,
    private readonly _gestaoComunicacoesEstadoService: GestaoComunicacoesEstadoService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.topicoEmpresasCopy = [];
    this.empresaTopicosCompact = [];
    this.empresaEstadosCompact = [];
    this.dataGridListViewSource = new CustomStore({
      key: 'comTopicoId',
      load: this._getDatagridListSource.bind(this)
    });
    this.empresaEstadosTopicos = {nomeEmpresa: undefined, estados: []};
    this.vistaKanban = true;
    this.eTipoEstados = EGestaoComunicacoesEstadoTipo;
    this._serviceEmpresas = this._entityServiceBuilder.build<IEmpresaExtended>(ENTITY_NAME_EMPRESAS_ERP);

    // datagrid list view
    this.dataGridListView = {
      columnHidingEnabled: false,
      columns: [
        {cellTemplate: 'cellTemplateHasUnreadMessages'},
        {dataField: 'assunto', dataType: 'string', caption: 'gestaoComunicacoes.modal.topico.assunto'},
        {dataField: 'ultimaMensagem', dataType: 'string', caption: 'gestaoComunicacoes.modal.topico.lastMessage', visible: false},
        {dataField: 'categoriaNome', dataType: 'string', caption: 'gestaoComunicacoes.modal.topico.categoria', cellTemplate: 'cellTemplateCategoria'},
        {dataField: 'estadoNome', dataType: 'string', caption: 'gestaoComunicacoes.modal.topico.estado'},
        {dataField: '_utilizadorShow', dataType: 'string', caption: 'gestaoComunicacoes.modal.topico.user'},
        {dataField: 'stampUltimaMensagem', dataType: 'datetime', caption: 'gestaoComunicacoes.modal.topico.data'},
        {type: 'buttons', cellTemplate: 'cellTemplateActions', showInColumnChooser: false}
      ],
      toolbar: {
        items: [
          {
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'add',
              hint: this._translateService.instant('global.btn.addLine'),
              onClick: () => this.onClickAddTopico(undefined, true)
            } satisfies Properties
          }
        ]
      },
      editing: {allowAdding: true, mode: 'row'},
      export: {filename: 'gestaoComunicacoes.title'},
      columnChooser: {enabled: false},
      remoteOperations: {
        filtering: true,
        sorting: false,
        grouping: false,
        summary: false,
        paging: true,
        groupPaging: false
      },
      headerFilter: {visible: false}
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.topicoEmpresasCopy = copy(this.topicoEmpresas);
    this.empresaEstadosTopicosCopy = copy(this.empresaEstadosTopicos);

    // config toolbar
    this.btnConfig.visible = true;
    this.btnConfig.click = () => this._openConfig();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public async handleEmpresaChange(nEmpresa: string): Promise<void> {
    if (this._checkActivePromises()) {
      return Promise.resolve();
    }

    this.empresaSelected = nEmpresa;
    this.promiseLoading = (async () => {
      await this._getAndApplyEmpresaEstadosTopicos(nEmpresa);
      if (this._dataGridInstance) {
        await this._dataGridInstance.refresh();
      }
    })().finally(() => {
      this.promiseLoading = undefined;
    });
    return this.promiseLoading;
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    // if (this.toolbar) {
    //   this._buildToolbarResponsive(value);
    // }
  }

  public onInitializedDatagrid(event: IDevExpressDataGridEventOnInitialized<IJsonGestaoComunicacoesTopico, string>): void {
    this._dataGridInstance = event.component;
  }

  public async onCellClickDatagrid(event: IDevExpressDataGridEventOnCellClick<IJsonGestaoComunicacoesTopico, string>): Promise<void> {
    if (event.columnIndex !== this._dataGridInstance.getVisibleColumns().length - 1) {
      await this.onClickTopico(
        event.data,
        this.empresaEstadosTopicos.estados.find((estado: IJsonGestaoComunicacoesEmpresaEstado) => estado.estadoId === event.data.comEstadoId)
      );
    }
  }

  public onCellPreparedDatagrid(event: IDevExpressDataGridEventOnCellPrepared<IJsonGestaoComunicacoesTopico, string>): void {
    if (event.column.dataType === 'datetime') {
      if (moment(event.data.stampUltimaMensagem).year() === moment(MIN_DATE_CGD).year()) {
        event.cellElement.innerText = '';
      }
    }
  }

  public async onListReorder(event: ReorderEvent): Promise<void> {
    // Reorder empresaTopicosCompact
    const list: IJsonGestaoComunicacoesTopico = this.empresaTopicosCompact.splice(event.fromIndex, 1)[0];
    this.empresaTopicosCompact.splice(event.toIndex, 0, list);

    // Reorder empresaEstadosCompact
    const status: string = this.empresaEstadosCompact.splice(event.fromIndex, 1)[0];
    this.empresaEstadosCompact.splice(event.toIndex, 0, status);

    // Reorder empresaEstadosTopicos.estados
    const estado: IJsonGestaoComunicacoesEmpresaEstado = this.empresaEstadosTopicos.estados.splice(event.fromIndex, 1)[0];
    this.empresaEstadosTopicos.estados.splice(event.toIndex, 0, estado);

    // update order in the db
    const estadosListComOrdem: Array<IJsonGestaoComunicacoesEstadoOrdem> = this.empresaEstadosTopicos.estados.map((estadoItem: IJsonGestaoComunicacoesEmpresaEstado, index: number) => {
      return {
        ...estadoItem,
        comEstadoId: estadoItem.estadoId,
        todasEmpresas: undefined,
        nEmpresa: this.empresaSelected,
        ordem: index
      };
    });
    await this._gestaoComunicacoesEstadoService.saveEstadosOrdem(this.empresaSelected, this.session.userId, estadosListComOrdem);
  }

  public onDragStart(event: DragStartEvent): void {
    if (this.promiseLoading) {
      event.event.preventDefault();
      event.cancel = true;
    }
  }

  public onTaskDragStart(event: DragStartEvent): void {
    if (!this.promiseLoading) {
      event.itemData = event.fromData[event.fromIndex];
    }

    this.onDragStart(event);
  }

  public async onReorderTaskDrop(event: ReorderEvent, estado: IJsonGestaoComunicacoesEmpresaEstado): Promise<void> {
    event.fromData.splice(event.fromIndex, 1);
    event.toData.splice(event.toIndex, 0, event.itemData);

    await this._reorderTopicoAndChangeRevision(estado, event.itemData, event.toData);
  }

  public async onTaskDrop(event: AddEvent, estado: IJsonGestaoComunicacoesEmpresaEstado): Promise<void> {
    event.fromData.splice(event.fromIndex, 1);
    event.toData.splice(event.toIndex, 0, event.itemData);

    const updatedTopico: IJsonGestaoComunicacoesTopico = {...event.itemData, comEstadoId: estado.estadoId};
    (<IJsonGestaoComunicacoesTopico>event.toData[event.toIndex]).revision = (
      await this._gestaoComunicacoesTopicoService.updateTopico((<IJsonGestaoComunicacoesTopico>event.itemData).comTopicoId, updatedTopico)
    ).body.revision;

    await this._reorderTopicoAndChangeRevision(estado, updatedTopico, event.toData);
  }

  public async filterEmpresasChanged(event: string): Promise<void> {
    this.filterEmpresas = event;
    if (isEmpty(this.filterEmpresas)) {
      this.promiseLoading = (async () => {
        this.topicoEmpresas = (await this._gestaoComunicacoesTopicoService.getEmpresas()).body;
        this.topicoEmpresasCopy = copy(this.topicoEmpresas);
      })().finally(() => {
        this.promiseLoading = undefined;
      });
      return this.promiseLoading;
    }

    this.topicoEmpresas = this.topicoEmpresasCopy.filter(
      (empresa: IJsonGestaoComunicacoesEmpresa) =>
        empresa.nome?.toLowerCase().includes(this.filterEmpresas.toLowerCase()) || empresa.nEmpresa?.toLowerCase().includes(this.filterEmpresas.toLowerCase())
    );
    return Promise.resolve();
  }

  public async filterTopicosChanged(event: string): Promise<void> {
    this.filterTopicos = event;
    if (isEmpty(this.filterTopicos)) {
      this.promiseLoading = (async () => {
        await this._getAndApplyEmpresaEstadosTopicos(this.empresaSelected);
        this.empresaEstadosTopicosCopy = copy(this.empresaEstadosTopicos);
        if (this._dataGridInstance) {
          this._dataGridInstance.filter(this._generateSearchFilterArray(event));
        }
      })().finally(() => {
        this._evaluateCarregarMaisTopicosBtn();
        this.promiseLoading = undefined;
      });
      return this.promiseLoading;
    }

    this.promiseLoading = (async () => {
      for (const estado of this.empresaEstadosTopicos.estados) {
        estado.topicos = [];
        // eslint-disable-next-line no-await-in-loop
        await this._getQueryTopicos(estado);
      }
      this._evaluateCarregarMaisTopicosBtn();

      if (this._dataGridInstance) {
        this._dataGridInstance.filter(this._generateSearchFilterArray(event));
      }
    })().finally(() => {
      this.promiseLoading = undefined;
    });
    return this.promiseLoading;
  }

  public async onClickEmpresaEstadoConfig(): Promise<void> {
    if (this._checkActivePromises()) {
      return Promise.resolve();
    }

    this.promiseLoading = (async () => {
      const estadosEmpresa: Array<IJsonGestaoComunicacoesEstadoOrdem> = (await this._gestaoComunicacoesEstadoService.getEstadosOrdem(this.empresaSelected, this.session.userId)).body;
      const configUtilizadores: IJsonGestaoComunicacoesConfigEmpresaReg = (await this._gestaoComunicacoesTopicoService.getConfigEmpresa(this.empresaSelected)).body;
      const inheritedConfigUtilizadoresSelected: Array<IJsonGestaoComunicacoesConfigUtilizador> = (await this._gestaoComunicacoesTopicoService.getConfig()).body.utilizadoresSelecionados;

      const modalInstance = this._cgModalService.showVanilla(GestaoComunicacoesEstadosEmpresaConfigModalComponent, {size: 'xl'});
      const componentInstance: GestaoComunicacoesEstadosEmpresaConfigModalComponent = modalInstance.componentInstance;
      componentInstance.estadosEmpresa = estadosEmpresa;
      componentInstance.empresaSelectedDados = this.empresaSelectedDados;
      componentInstance.userId = this.session.userId;
      componentInstance.selectedNEmpresa = this.empresaSelected;
      componentInstance.configUtilizadores = configUtilizadores;
      componentInstance.inheritedConfigUtilizadores = inheritedConfigUtilizadoresSelected;

      try {
        await modalInstance.result;
      } finally {
        await this._getAndApplyEmpresaEstadosTopicos(this.empresaSelected);
      }
    })().finally(() => {
      this.promiseLoading = undefined;
    });
    return this.promiseLoading;
  }

  public async onClickTopico(topico: IJsonGestaoComunicacoesTopico, estado: IJsonGestaoComunicacoesEmpresaEstado): Promise<void> {
    if (this._checkActivePromises()) {
      return Promise.resolve();
    }

    this.promiseLoading = (async () => {
      const mensagens: Array<IJsonGestaoComunicacoesMensagem> = (await this._gestaoComunicacoesTopicoService.getTopicoMessages(topico.comTopicoId, true)).body;

      const modalInstance = this._cgModalService.showVanilla(GestaoComunicacoesTopicoDetailModalComponent, {size: 'lg'});
      const componentInstance: GestaoComunicacoesTopicoDetailModalComponent = modalInstance.componentInstance;
      componentInstance.topico = topico;
      componentInstance.estadoDados = {estadoCor: estado.cor, estadoNome: estado.nome};
      componentInstance.mensagens = mensagens.reverse();

      // update the topicos list
      try {
        await modalInstance.result;
      } finally {
        await this._getAndApplyEmpresas(this.empresaSelected);
        if (this._dataGridInstance) {
          await this._dataGridInstance.refresh();
        }
      }
    })().finally(() => {
      this.promiseLoading = undefined;
    });
    return this.promiseLoading;
  }

  public async onClickAddTopico(estado: IJsonGestaoComunicacoesEmpresaEstado, datagridView: boolean): Promise<void> {
    if (this._checkActivePromises()) {
      return Promise.resolve();
    }

    this.promiseLoading = (async () => {
      const modalInstance = this._cgModalService.showVanilla(GestaoComunicacoesTopicoModalComponent, {size: 'lg'});
      const componentInstance: GestaoComunicacoesTopicoModalComponent = modalInstance.componentInstance;
      componentInstance.estadoId = estado?.estadoId;
      componentInstance.nEmpresaSelected = this.empresaSelected;
      componentInstance.contabilistaView = true;
      componentInstance.datagridView = datagridView;
      const topicoResponse: IJsonGestaoComunicacoesTopico = await modalInstance.result; // response data returned from the modal

      const targetEstado: IJsonGestaoComunicacoesEmpresaEstado = this.empresaEstadosTopicos.estados.find((e: IJsonGestaoComunicacoesEmpresaEstado) => e.estadoId === topicoResponse.comEstadoId);
      if (targetEstado) {
        targetEstado.topicos.unshift(topicoResponse);
      }

      // update nComunicacoes in the topicoEmpresas when added new topico
      if (targetEstado.tipo !== EGestaoComunicacoesEstadoTipo.SistemaFinal) {
        const empresa: IJsonGestaoComunicacoesEmpresa = this.topicoEmpresas.find((item: IJsonGestaoComunicacoesEmpresa) => item.nEmpresa === this.empresaSelected);
        if (empresa) {
          empresa.nComunicacoes++;
        }
      }

      this._updateEmpresaEstadosList(true);
      this._generateCompactData(this.empresaEstadosTopicos);

      if (this._dataGridInstance) {
        await this._dataGridInstance.refresh();
      }
    })().finally(() => {
      this.promiseLoading = undefined;
    });
    return this.promiseLoading;
  }

  public async onClickCarregarMais(estado: IJsonGestaoComunicacoesEmpresaEstado): Promise<void> {
    if (this._checkActivePromises()) {
      return Promise.resolve();
    }

    this.promiseLoading = (async () => {
      await this._getQueryTopicos(estado);
    })().finally(() => {
      this.promiseLoading = undefined;
    });
    return this.promiseLoading;
  }

  public changeVistaKanban(changeToVistaKanban: boolean): void {
    if (this.vistaKanban === changeToVistaKanban) {
      return;
    }
    this.vistaKanban = changeToVistaKanban;
  }

  public async openEditTopico(topicoId: string, isKanban: boolean): Promise<void> {
    if (this._checkActivePromises()) {
      return Promise.resolve();
    }

    this.promiseLoading = (async () => {
      let topico: IJsonGestaoComunicacoesTopico = null;
      if (isKanban) {
        topico = this.empresaTopicosCompact.find((item: IJsonGestaoComunicacoesTopico) => item.comTopicoId === topicoId);
      } else {
        topico = this._dataGridInstance
          .getDataSource()
          .items()
          .find((item: IJsonGestaoComunicacoesTopico) => item.comTopicoId === topicoId);
      }

      const modalInstance = this._cgModalService.showVanilla(GestaoComunicacoesTopicoEditModalComponent, {size: 'lg'});
      const componentInstance: GestaoComunicacoesTopicoEditModalComponent = modalInstance.componentInstance;
      componentInstance.model = topico;
      componentInstance.nEmpresaSelected = this.empresaSelected;
      const topicoResponse: IJsonGestaoComunicacoesTopico = await modalInstance.result; // response data returned from the modal

      // find the topico in the compact list and update it
      // kanban view
      this.empresaEstadosTopicos.estados.find((estado: IJsonGestaoComunicacoesEmpresaEstado) => {
        const topicoIndex: number = estado.topicos.findIndex((topicoItem: IJsonGestaoComunicacoesTopico) => topicoItem.comTopicoId === topicoResponse.comTopicoId);
        if (topicoIndex > -1) {
          // modal changes the following values
          estado.topicos[topicoIndex] = {
            ...topico,
            assunto: topicoResponse.assunto,
            categoriaNome: topicoResponse.categoriaNome,
            categoriaCor: topicoResponse.categoriaCor,
            comCategoriaId: topicoResponse.comCategoriaId
          };
          return true;
        }
        return false;
      });
      this._generateCompactData(this.empresaEstadosTopicos);

      // datagrid view
      if (this._dataGridInstance) {
        await this._dataGridInstance.refresh();
      }
    })().finally(() => {
      this.promiseLoading = undefined;
    });
    return this.promiseLoading;
  }

  public async deleteTopico(topicoId: string, isKanban: boolean): Promise<void> {
    if (this._checkActivePromises()) {
      return Promise.resolve();
    }

    this.promiseLoading = (async () => {
      let topicoAssunto: string;
      if (isKanban) {
        topicoAssunto = this.empresaTopicosCompact.find((item: IJsonGestaoComunicacoesTopico) => item.comTopicoId === topicoId).assunto;
      } else {
        topicoAssunto = this._dataGridInstance
          .getDataSource()
          .items()
          .find((item: IJsonGestaoComunicacoesTopico) => item.comTopicoId === topicoId).assunto;
      }

      await this._cgModalService.showOkCancel(this._plTranslateService.translate('entity.delete.title', {id: topicoAssunto}), 'entity.delete.message');
      await this._gestaoComunicacoesTopicoService.deleteTopico(topicoId);
      this._plAlertService.success('gestaoComunicacoes.modal.topico.mensagens.deleteSuccess');

      // remove the topico from the compact list
      // kanban view
      this.empresaEstadosTopicos.estados.forEach((estado: IJsonGestaoComunicacoesEmpresaEstado) => {
        const topicoIndex: number = estado.topicos.findIndex((topico: IJsonGestaoComunicacoesTopico) => topico.comTopicoId === topicoId);
        if (topicoIndex > -1) {
          if (estado.tipo !== EGestaoComunicacoesEstadoTipo.SistemaFinal) {
            const empresa: IJsonGestaoComunicacoesEmpresa = this.topicoEmpresas.find((item: IJsonGestaoComunicacoesEmpresa) => item.nEmpresa === this.empresaSelected);
            if (empresa) {
              empresa.nComunicacoes--;

              // update nMensagens in the topicoEmpresas when deleted topico
              empresa.nMensagens -= estado.topicos[topicoIndex].hasUnreadMessages;
            }
          }

          estado.topicos.splice(topicoIndex, 1); // remove the topico from the estado
        }
      });
      this._generateCompactData(this.empresaEstadosTopicos);

      // datagrid view
      if (this._dataGridInstance) {
        await this._dataGridInstance.refresh();
      }
    })().finally(() => {
      this.promiseLoading = undefined;
    });
    return this.promiseLoading;
  }

  private async _openConfig(): Promise<void> {
    if (this._checkActivePromises()) {
      return Promise.resolve();
    }

    this.promiseLoading = (async () => {
      const configEmpresas: IJsonGestoComunicacoesConfig = (await this._gestaoComunicacoesTopicoService.getConfig()).body;

      const modalInstance = this._cgModalService.showVanilla(GestaoComunicacoesConfiguracaoModalComponent, {size: 'xl'});
      const componentInstance: GestaoComunicacoesConfiguracaoModalComponent = modalInstance.componentInstance;
      componentInstance.configEmpresas = configEmpresas;

      try {
        await modalInstance.result;
      } finally {
        this.topicoEmpresas = (await this._gestaoComunicacoesTopicoService.getEmpresas()).body;
        if (this.empresaSelected) {
          const empresa: IJsonGestaoComunicacoesEmpresa = this.topicoEmpresas.find((item: IJsonGestaoComunicacoesEmpresa) => item.nEmpresa === this.empresaSelected);
          if (!empresa) {
            this.empresaSelected = undefined;
          } else {
            await this.handleEmpresaChange(empresa.nEmpresa);
          }
        }
      }
    })().finally(() => {
      this.promiseLoading = undefined;
    });
    return this.promiseLoading;
  }

  private async _getAndApplyEmpresaEstadosTopicos(nEmpresa: string): Promise<void> {
    this.empresaSelectedDados = (await this._serviceEmpresas.get({id: this.empresaSelected})).body;
    this.empresaEstadosTopicos = (await this._gestaoComunicacoesTopicoService.getEmpresaEstadosTopicos(nEmpresa)).body;

    // update private properties
    this._updateEmpresaEstadosList(true);
    this._generateCompactData(this.empresaEstadosTopicos);
  }

  private _updateDatagridPrivateProperties(list: Array<IJsonGestaoComunicacoesTopico>): void {
    list.forEach((topico: IJsonGestaoComunicacoesTopico) => {
      topico._categoryBackgroundColor = lightenColor(topico?.categoriaCor, GESTAO_COMUNICACAO_COLOR_PERCENT);
      topico._utilizadorShow = generateUtilizadorShow(topico, true);
    });
  }

  private _updateEmpresaEstadosList(generateBtn: boolean): void {
    this.empresaEstadosTopicos.estados.forEach((estado: IJsonGestaoComunicacoesEmpresaEstado) => {
      estado.topicos.forEach((topico: IJsonGestaoComunicacoesTopico) => {
        topico._utilizadorShow = generateUtilizadorShow(topico, true);
        topico._dateShow = generateGestaoComunicacoesDate(topico.stampUltimaMensagem, true, this._plTranslateService);
      });
    });

    if (generateBtn) {
      this._evaluateCarregarMaisTopicosBtn();
    }
  }

  private async _getAndApplyEmpresas(nEmpresa: string): Promise<void> {
    this.topicoEmpresas = (await this._gestaoComunicacoesTopicoService.getEmpresas()).body;
    await this._getAndApplyEmpresaEstadosTopicos(nEmpresa);
  }

  private _generateCompactData(empresaEstadosResponse: IJsonGestaoComunicacoesEmpresaEstados): void {
    this._updateAndCopyLists(empresaEstadosResponse);
    this.empresaTopicosCompact = empresaEstadosResponse.estados.flatMap((estado: IJsonGestaoComunicacoesEmpresaEstado) => estado.topicos);
    this.empresaEstadosCompact = empresaEstadosResponse.estados.map((estado: IJsonGestaoComunicacoesEmpresaEstado) => estado.nome);
  }

  private _updateAndCopyLists(empresaEstadosResponse: IJsonGestaoComunicacoesEmpresaEstados): void {
    this.topicoEmpresasCopy = copy(this.topicoEmpresas);
    this.empresaEstadosTopicosCopy = copy(empresaEstadosResponse);
  }

  private async _reorderTopicos(estado: IJsonGestaoComunicacoesEmpresaEstado, topicosList: Array<IJsonGestaoComunicacoesTopico>): Promise<Array<IJsonGestaoComunicacoesTopico>> {
    return this._gestaoComunicacoesTopicoService.reorderTopicos(estado.estadoId, topicosList).then((response: HttpResponse<Array<IJsonGestaoComunicacoesTopico>>) => {
      return response.body;
    });
  }

  private _checkActivePromises(): boolean {
    return Boolean(this.promiseLoading);
  }

  private async _getDatagridListSource(loadOptions: LoadOptions): Promise<IDevExpressDataGridLoadResult<IJsonGestaoComunicacoesTopico>> {
    let search = '';
    if (!loadOptions.filter && this.filterTopicos) {
      loadOptions.filter = copy(this._generateSearchFilterArray(this.filterTopicos));
    }
    if (loadOptions.filter) {
      search = devExpressDataGridFiltersToQueryFilter(this._dataGridInstance, loadOptions.filter);
    }
    if (this.empresaSelected) {
      if (search) {
        search += '&';
      }
      search += `nEmpresa=${this.empresaSelected}`;
    }
    if (!this._dataGridPaging) {
      this._dataGridPaging = new DevExpressDatagridPaging(this._dataGridInstance);
    }
    const {page, perPage} = this._dataGridPaging.paginate(loadOptions);
    const perPageCalc: number = perPage === -1 ? NUMBER_TEN : perPage;
    const response: HttpResponse<IApiQueryResponse<IJsonGestaoComunicacoesTopico>> = await this._gestaoComunicacoesTopicoService.getTopicos({
      pesquisa: search,
      pagina: page,
      porpagina: !loadOptions.take ? perPageCalc : loadOptions.take,
      additionalfields: 'full'
    });
    this._updateDatagridPrivateProperties(response.body.list);
    return this._dataGridPaging.processResult(response);
  }

  private _evaluateCarregarMaisTopicosBtn(): void {
    this.empresaEstadosTopicos.estados.forEach((estado: IJsonGestaoComunicacoesEmpresaEstado) => {
      estado._carregarMais = estado.topicos.length >= NUMBER_TEN;
    });
  }

  private _generateSearchFilterArray(filterText: string): Array<string> {
    const searchFilterArray: Array<string> = [];
    this._dataGridInstance.getVisibleColumns().forEach((column) => {
      if (!isEmpty(column.dataField) && column.dataField !== '_utilizadorShow' && column.dataField !== 'stampUltimaMensagem') {
        searchFilterArray.push(column.dataField, 'contains', filterText);
      }
    });
    for (let i = 3; i < searchFilterArray.length; i += NUMBER_FOUR) {
      searchFilterArray.splice(i, 0, 'or');
    }
    searchFilterArray.unshift('(');
    searchFilterArray.push(')');
    return searchFilterArray;
  }

  private async _getQueryTopicos(estado: IJsonGestaoComunicacoesEmpresaEstado): Promise<void> {
    let searchParam = `nEmpresa=${this.empresaSelected}&comEstadoId=${estado.estadoId}`;
    if (this.filterTopicos) {
      searchParam += `&assunto=%%${this.filterTopicos}%`;
    }
    const params: IGestaoComunicacoesQueryParamAdditionalFields = {
      pesquisa: searchParam,
      pagina: Math.ceil(estado.topicos.length / NUMBER_TEN) + 1,
      porpagina: NUMBER_TEN,
      additionalfields: 'full'
    };
    const responseTopicos: Array<IJsonGestaoComunicacoesTopico> = (await this._gestaoComunicacoesTopicoService.getTopicos(params)).body.list;
    estado.topicos.push(...responseTopicos);

    if (responseTopicos.length < NUMBER_TEN) {
      estado._carregarMais = false;
    }
    this._updateEmpresaEstadosList(false);
  }

  private async _reorderTopicoAndChangeRevision(estado: IJsonGestaoComunicacoesEmpresaEstado, topico: IJsonGestaoComunicacoesTopico, topicosList: Array<IJsonGestaoComunicacoesTopico>): Promise<void> {
    this.empresaEstadosTopicos.estados
      .find((estadoItem: IJsonGestaoComunicacoesEmpresaEstado) => estadoItem.estadoId === estado.estadoId)
      .topicos.find((topicoItem: IJsonGestaoComunicacoesTopico) => topicoItem.comTopicoId === topico.comTopicoId).revision = (await this._reorderTopicos(estado, topicosList)).find(
      (item: IJsonGestaoComunicacoesTopico) => item.comTopicoId === topico.comTopicoId
    ).revision;
  }
}
