import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ConfiguracoesEventosModuleComponent} from './components/configuracoesEventos.module.component';

export const MODULE_CONFIGURACOES_EVENTOS: IModuleDefinition = {
  name: 'colaboradoresConfiguracoesEventos',
  state: {
    url: '/configuracoeseventos',
    component: ConfiguracoesEventosModuleComponent,
    data: {
      roles: [ROLE.GESTORRH],
      pageTitle: 'global.menu.prheventosconfig',
      sidebarTitle: 'portals.sidebar.modules.prheventosconfig',
      icon: 'fa-cog'
    }
  }
};
