import {firstValueFrom} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {CGLocalStorageGroupService} from '../../../services/storage/localstoragegroup.service';
import {EGroupName} from '../../../../config/constants';
import {SCHEMA_OFFICE_REPORTING_SELECTED_PAGINAS, STORAGE_KEY_OFFICE_REPORTING_SELECTED_PAGINAS} from './officeReporting.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class OfficeReportingService {
  private readonly _path: string;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _storageService: CGLocalStorageGroupService
  ) {
    this._path = `${this._apiService.path.restapi}/officereporting`;
  }

  public evolucaoDoNegocioPaginas(): TServiceResponse<Array<string>> {
    return this._apiService.get<Array<string>>({url: `${this._path}/evolucaodonegocio/paginas`});
  }

  public evolucaoDoNegocioToPDF(ano: number, mes: number, paginas: Array<number>): TServiceResponse<Blob> {
    return this._apiService.post<Blob, Array<number>>({
      url: `${this._path}/evolucaodonegocio/xls`,
      responseType: 'blob',
      body: paginas,
      params: {
        ano: ano,
        mes: mes
      }
    });
  }

  public persistSelectedPaginas(paginas: Array<number>): Promise<void> {
    return firstValueFrom(this._storageService.setItem(STORAGE_KEY_OFFICE_REPORTING_SELECTED_PAGINAS, paginas, SCHEMA_OFFICE_REPORTING_SELECTED_PAGINAS, EGroupName.CONTABILIDADE));
  }

  public fetchSelectedPaginas(): Promise<Array<number>> {
    return firstValueFrom(this._storageService.getItem<Array<number>>(STORAGE_KEY_OFFICE_REPORTING_SELECTED_PAGINAS, SCHEMA_OFFICE_REPORTING_SELECTED_PAGINAS, EGroupName.CONTABILIDADE));
  }
}
