import {AfterContentChecked, Component, ContentChildren, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChild} from '@angular/core';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import type {IPlTooltipCallback, IPlTooltipConfig} from './tooltip.interface';
import {identity as identityFn, isBoolean, isEmpty, isObject} from '../common/utilities/utilities';
import {PlTooltipContentDirective} from './tooltip.content.directive';
import {PlTooltipTemplateDirective} from './tooltip.template.directive';

@Component({
  selector: 'pl-tooltip',
  templateUrl: './tooltip.component.html',
  standalone: false
})
export class PlTooltipComponent implements OnChanges, AfterContentChecked {
  @Input() public config: IPlTooltipConfig;
  @Input() public callback: IPlTooltipCallback;
  @Output() public readonly evtHidden: EventEmitter<void>;
  @Output() public readonly evtShown: EventEmitter<void>;

  public readonly identity: NgbTooltip['popperOptions'];
  public templateTooltipContent: PlTooltipContentDirective;
  public templateTooltipTemplate: PlTooltipTemplateDirective;

  @ContentChildren(PlTooltipContentDirective) private readonly _templateContent: QueryList<PlTooltipContentDirective>;
  @ContentChildren(PlTooltipTemplateDirective) private readonly _templateTooltipTemplate: QueryList<PlTooltipTemplateDirective>;
  @ViewChild(NgbTooltip) private readonly _ngbTooltip: NgbTooltip;

  constructor() {
    this.evtHidden = new EventEmitter<void>();
    this.evtShown = new EventEmitter<void>();
    this.identity = identityFn;
  }

  public ngOnChanges({config, callback}: SimpleChanges): void {
    if (config) {
      let configValue: IPlTooltipConfig = config.currentValue;
      if (!isObject(configValue)) {
        this.config = {};
        configValue = this.config;
      }
      if (!isBoolean(configValue.autoClose)) {
        configValue.autoClose = true;
      }
    }
    if (callback && isObject(callback.currentValue)) {
      this.callback.open = () => {
        this.open();
      };
      this.callback.close = () => {
        this.close();
      };
      this.callback.toggle = () => {
        this.toggle();
      };
      this.callback.isOpen = () => this.isOpen();
    }
  }

  public ngAfterContentChecked(): void {
    this.templateTooltipContent = this._templateContent.first;
    this.templateTooltipTemplate = this._templateTooltipTemplate.first;
  }

  public open(): void {
    this._ngbTooltip.open();
  }

  public close(): void {
    this._ngbTooltip.close();
  }

  public toggle(): void {
    this._ngbTooltip.toggle();
  }

  public isOpen(): boolean {
    return this._ngbTooltip.isOpen();
  }

  public shouldAutoClose(): boolean {
    if (isBoolean(this.config?.autoClose)) {
      return this.config.autoClose;
    }
    return true;
  }

  public shouldDisableTooltip(): boolean {
    if (isBoolean(this.config?.disabled)) {
      return this.config.disabled;
    } else if (isEmpty(this.config?.text) && !this.templateTooltipTemplate && !this.config?.templateRef) {
      return true;
    }
    return false;
  }

  public onHidden(): void {
    this.evtHidden.emit();
  }

  public onShown(): void {
    this.evtShown.emit();
  }
}
