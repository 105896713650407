import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_VALOR_PENHORA, ETipoValorPenhora} from './tipoValorPenhora.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPO_VALOR_PENHORA: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_VALOR_PENHORA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoValorPenhora.EmFraccao, name: 'tipoValorPenhora.emfraccao'},
    {value: ETipoValorPenhora.EmPercentagem, name: 'tipoValorPenhora.empercentagem'},
    {value: ETipoValorPenhora.ValorAbsoluto, name: 'tipoValorPenhora.valorabsoluto'},
    {value: ETipoValorPenhora.PorInsolvencia, name: 'tipoValorPenhora.porinsolvencia'}
  ]
});
