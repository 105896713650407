import {deepFreeze} from 'pl-comps-angular';

export enum EAssistConfigPortaisGeralEmailTipoEmail {
  Gmail,
  Hotmail,
  Outro
}

export interface IAssistConfigPortaisGeralEmailDefaults {
  gmail: IAssistConfigPortaisGeralEmailModel;
  hotmail: IAssistConfigPortaisGeralEmailModel;
  outro: IAssistConfigPortaisGeralEmailModel;
}

export interface IAssistConfigPortaisGeralEmailModel {
  tipoEmail: EAssistConfigPortaisGeralEmailTipoEmail;
  contaEmail: string;
  smtp: string;
  portaSmtp: string;
  ssl: boolean;
}

export interface IAssistConfigPortaisGeralEmailEmpresa {
  nEmpresa: string;
  nomeEmpresa: string;
}

export const MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_GERAL_EMAIL = 'assistConfigPortaisGeralEmail';

export const ASSIST_CONFIG_PORTAIS_GERAL_EMAIL_DEFAULTS = deepFreeze<IAssistConfigPortaisGeralEmailDefaults>({
  gmail: {
    tipoEmail: EAssistConfigPortaisGeralEmailTipoEmail.Gmail,
    contaEmail: 'OSeuEmail@gmail.com',
    smtp: 'smtp.gmail.com',
    portaSmtp: '587',
    ssl: true
  },
  hotmail: {
    tipoEmail: EAssistConfigPortaisGeralEmailTipoEmail.Hotmail,
    contaEmail: 'OSeuEmail@hotmail.com',
    smtp: 'smtp-mail.outlook.com',
    portaSmtp: '25',
    ssl: true
  },
  outro: {
    tipoEmail: EAssistConfigPortaisGeralEmailTipoEmail.Outro,
    contaEmail: undefined,
    smtp: undefined,
    portaSmtp: undefined,
    ssl: false
  }
});
