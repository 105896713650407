import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {IJsonDependencias, IJsonTrfLancContas} from './jsonTrfLancContas.module.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class TrfLancContasService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/trflanccontas`;
  }

  public getDependencias(nContaOrigem: string): TServiceResponse<Array<IJsonDependencias>> {
    return this._apiService.get<Array<IJsonDependencias>>({url: `${this._path}/dependencias`, params: {nContaOrigem: nContaOrigem}});
  }

  public transferenciaContas(transferenciaContas: IJsonTrfLancContas): TServiceResponse<void> {
    return this._apiService.post<void, IJsonTrfLancContas>({url: this._path, body: transferenciaContas});
  }
}
