import {NgModule} from '@angular/core';
import {PlNumericOnlyDirective} from './keyboard.numericonly.directive';

export * from './keyboard.service';
export * from './keyboard.numericonly.directive';

const DECLARATIONS = [
  PlNumericOnlyDirective
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlKeyboardModule {
}
