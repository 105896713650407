import {STATE_NAME_OAUTH2} from '../oauth2.state.interface';

export interface IOAuth2DigitalSignStateParams {
  code: string;
  state: string;
}

export interface IOAuth2DigitalSignStoredState {
  stateName: string;
  nomeAutorizador: string;
  safetyKey: string;
  authenticate: boolean;
  ambientePreProducao: boolean;
  idAutorizador: string;
  authorizerSecretDigitalSign: string;
}

export interface IOAuth2DigitalSignState {
  stateID: string;
  redirectUrl: string;
}

export const STATE_NAME_OAUTH2_DIGITAL_SIGN = `${STATE_NAME_OAUTH2}.digitalsign`;

export const STATE_URL_OAUTH2_DIGITAL_SIGN = 'digitalsign';
