import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonCondicaoPagamento} from './jsonCondicaoPagamento.entity.interface';
import {DATA_SOURCE_NAME_TIPO_RESUMO_MENSAL} from '../../datasources/tiporesumomensal/tipoResumoMensal.datasource.interface';
import {ENTITY_NAME_CONDICOES_PAGAMENTOS} from './condicoesPagamento.entity.interface';

const condPagFields = 'nCondPag,descricao,nDiasPP,percDesconto,numDiasDataVenc,tipoResumoMensal';

export const ENTITY_CONDICOES_PAGAMENTO: IEntityDefinition<IJsonCondicaoPagamento> = {
  name: ENTITY_NAME_CONDICOES_PAGAMENTOS,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.FINANCEIRO],
  searchPlaceholder: 'condicoespagamento.pesquisa',
  metadata: {
    keyName: 'nCondPag',
    fields: [
      {
        name: 'nCondPag',
        type: 'cgsmallint',
        width: '120px',
        caption: 'condicoespagamento.fields.nCondPag',
        placeholder: 'condicoespagamento.fields.nCondPagPlaceholder',
        validators: {required: true}
      },
      {
        name: 'descricao',
        caption: 'condicoespagamento.fields.descricao',
        placeholder: 'condicoespagamento.fields.descricaoPlaceholder',
        validators: {required: true, maxlength: 50}
      },
      {
        name: 'nDiasPP',
        caption: 'condicoespagamento.fields.nDiasPP',
        placeholder: 'condicoespagamento.fields.nDiasPPPlaceholder',
        validators: {min: 1}
      },
      {
        name: 'percDesconto',
        type: 'number',
        caption: 'condicoespagamento.fields.percDesconto',
        value: 0,
        placeholder: 'condicoespagamento.fields.percDescontoPlaceholder',
        properties: {decimalsLimit: 2},
        validators: {max: 100}
      },
      {
        name: 'numDiasDataVenc',
        caption: 'condicoespagamento.fields.numDiasDataVenc',
        placeholder: 'condicoespagamento.fields.numDiasDataVencholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'tipoResumoMensal',
        caption: 'condicoespagamento.fields.tipoResumoMensal',
        placeholder: 'condicoespagamento.fields.tipoResumoMensalPlaceholder',
        type: DATA_SOURCE_NAME_TIPO_RESUMO_MENSAL
      }
    ],
    order: 'nCondPag',
    searchFields: 'nCondPag,descricao',
    detailFields: condPagFields,
    listFields: condPagFields,
    newFields: condPagFields,
    editFields: condPagFields
  },
  autocomplete: {
    rowTemplate: '{{nCondPag}} - {{descricao}}',
    output: 'descricao',
    searchFields: 'nCondPag,descricao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.condicoespagamento'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.condicoespagamento'
      }
    }
  }
};
