import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonRhCessaContratoTrabalhoEncerrar, IJsonRhCessaContratoTrabalhoForm} from '../../jsonRHCessaContratoTrabalho.module.interface';
import {RhCessaContratoTrabalhoService} from '../../rhCessaContratoTrabalho.module.service';
import {IRhCessaContratoTrabalhoGravar} from '../../rhCessaContratoTrabalho.module.interface';
import {PlAlertService} from 'pl-comps-angular';
import {TranslateService} from '@ngx-translate/core';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {AddAbonoDescontoModalComponent} from '../addabonodesconto/addAbonoDesconto.modal.component';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnRowInserting} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import ArrayStore from 'devextreme/data/array_store';

@Component({
  selector: 'modal-cessacaoconttrab',
  templateUrl: './cessacaoContTrab.modal.component.html'
})
export class CessacaoContTrabModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public cessaContTrabForm: IJsonRhCessaContratoTrabalhoForm;
  @Input() public cessaContTrabEncerrar: Array<IJsonRhCessaContratoTrabalhoEncerrar>;

  public readonly dataGridDefinition: IDevExpressDataGrid;

  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhcessaContratoTrabalhoService: RhCessaContratoTrabalhoService,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nCodABDESC', dataType: 'number', caption: 'rhcessacontratotrabalho.modal.encerrar.table.nCodABDESC', allowEditing: false},
        {dataField: 'descricaoAbono', dataType: 'string', caption: 'rhcessacontratotrabalho.modal.encerrar.table.descricaoAbono', width: 250, allowEditing: false},
        {dataField: 'valor', dataType: 'double', caption: 'rhcessacontratotrabalho.modal.encerrar.table.valor'},
        {dataField: 'observacoes', dataType: 'string', caption: 'rhcessacontratotrabalho.modal.encerrar.table.observacoes'}
      ],
      editing: {
        allowAdding: true,
        allowDeleting: true,
        allowUpdating: true,
        mode: 'cell',
        newRowPosition: 'last',
        selectTextOnEditStart: true
      },
      export: {filename: 'rhcessacontratotrabalho.modal.encerrar.cessacaocontrat'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'after',
            template: 'templateToolbarAddBtn',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = new ArrayStore({
      key: 'idLinha',
      data: this.cessaContTrabEncerrar
    });
  }

  public close(): Promise<void> {
    return this._dataGridInstance
      .getDataSource()
      .store()
      .load()
      .then((cessaContTrabEncerrar: Array<IJsonRhCessaContratoTrabalhoEncerrar>) => {
        const rhressacontratotrabalhogravar: IRhCessaContratoTrabalhoGravar = {
          rhCessaContratoTrabalhoForm: this.cessaContTrabForm,
          rhCessaContratoTrabalhoEncerrar: cessaContTrabEncerrar
        };
        return this._rhcessaContratoTrabalhoService.postGravaCessacaoContrato(rhressacontratotrabalhogravar).then(() => {
          this._plAlertService.success(this._translateService.instant('rhcessacontratotrabalho.infos.gravadocomsucessonotipoproc', {tipoprocessamento: this.cessaContTrabForm.tipoProcessamento}));
          super.close();
        });
      });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onRowInserting(event: IDevExpressDataGridEventOnRowInserting<IJsonRhCessaContratoTrabalhoEncerrar>): void {
    event.cancel = true;
    const modalInstance = this._cgModalService.showVanilla(AddAbonoDescontoModalComponent, {size: 'md'});
    const componentInstance: AddAbonoDescontoModalComponent = modalInstance.componentInstance;
    componentInstance.cessaContTrabForm = this.cessaContTrabForm;
    modalInstance.result.then((newLine: IJsonRhCessaContratoTrabalhoEncerrar) => {
      event.data = {...newLine};
    });
  }

  public addLineEncerrarList(): void {
    const modalInstance = this._cgModalService.showVanilla(AddAbonoDescontoModalComponent, {size: 'md'});
    const componentInstance: AddAbonoDescontoModalComponent = modalInstance.componentInstance;
    componentInstance.cessaContTrabForm = this.cessaContTrabForm;
    modalInstance.result.then((newLine: IJsonRhCessaContratoTrabalhoEncerrar) => {
      this.cessaContTrabEncerrar.push(newLine);
      this._dataGridInstance
        .getDataSource()
        .store()
        .push([
          {
            type: 'insert',
            data: newLine
          }
        ]);
      this._dataGridInstance.getDataSource().reload();
    });
  }
}
