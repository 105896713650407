<div [ngClass]="entityClassName">
  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [dataGridState]="dataGridState"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [toolbarInstanceId]="instanceName"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [properties]="listOptions"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridOnContentReady)="onDataGridOnContentReady($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
    <div *entityListItemActions="let item">
      <button type="button" class="btn btn-light btn-xs" [title]="'entity.list.secondaryClick.menu.openAsModal' | translate" (click)="itemActions(item.data)">
        <i class="fa fa-fw fa-window-restore"></i>
      </button>
    </div>
  </entity-list>
</div>
