import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {downloadStream, IPlToolbarInstance, isEmpty, PlAlertService, PlTranslateService, TPlTableItem, TPlToolbarItem} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DmrfEditModalComponent} from '../modals/dmrf.edit.modal.component';
import {DmrfService} from '../dmrf.module.service';
import {IDMRFItem, IDMRFLoadResults, IDMRFSummaryItem, IDMRFUI} from '../dmrf.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {TServiceResponse} from '../../../../services/api/api.service.interface';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import ArrayStore from 'devextreme/data/array_store';
import {HookMatchCriteria, StateDeclaration, StateObject, Transition, TransitionService} from '@uirouter/core';
import {STATE_NAME_LOGIN} from '../../../../states/account/login/login.state.interface';
import {STATE_NAME_DISCONNECTED} from '../../../../states/account/disconnected/disconnected.state.interface';
import {isTest} from '../../../../../config/constants';

const TOOLBAR_INSTANCE_ID_MAIN = 'main-toolbar';

@Component({
  selector: 'module-dmrf',
  templateUrl: './dmrf.module.component.html'
})
export class DmrfModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly dataGridDefinition: IDevExpressDataGrid<IDMRFItem>;
  public readonly dataGridDefinitionSummary: IDevExpressDataGrid<IDMRFSummaryItem>;
  public readonly model: IDMRFUI;
  public readonly outputPeriodo: string;
  public tableSummarySource: Array<TPlTableItem<IDMRFSummaryItem>>;
  public tbInstanceId: string;
  public showLoadingModal: boolean;
  public loadingModalMessage: string;

  private readonly _addNewRecordToolbarBtn: TPlToolbarItem;
  private readonly _deletePeriodoToolbarBtn: TPlToolbarItem;
  private readonly _refreshDeclaracaoToolbarBtn: TPlToolbarItem;
  private readonly _saveDeclaracaoToolbarBtn: TPlToolbarItem;
  private readonly _exportDeclaracaoToolbarBtn: TPlToolbarItem;
  private readonly _sendDeclaracaoToolbarBtn: TPlToolbarItem;
  private _dataGridInstance: dxDataGrid<IDMRFItem>;
  private _firstLoad: boolean;
  private _deRegisterOnStartFn: Function;
  private _msStarted: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _dmrfService: DmrfService,
    protected readonly _transitionService: TransitionService
  ) {
    super(_injector);
    this.outputPeriodo = '{{periodo}} - {{nome}}';
    this.model = {
      periodo: '',
      nome: '',
      frestran: false
    };
    this.tableSummarySource = [];
    this.dataGridDefinition = {
      columns: [
        {dataField: 'nContrib', dataType: 'string', caption: 'dmrf.fields.nContrib'},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'dmrf.fields.nDocExterno'},
        {dataField: 'zonaStr', dataType: 'string', caption: 'dmrf.fields.zona'},
        {dataField: 'rubricaStr', dataType: 'string', caption: 'dmrf.fields.rubrica'},
        {dataField: 'valorRetencao', dataType: 'double', caption: 'dmrf.fields.retencao'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn'}
      ],
      dataSource: new CustomStore({
        load: () => this._getTableSourceMain()
      }),
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplate',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionSummary = {
      columns: [
        {dataField: 'zona', dataType: 'string', caption: 'dmrf.fields.zona'},
        {dataField: 'rubrica', dataType: 'string', caption: 'dmrf.fields.rubrica'},
        {dataField: 'valor', dataType: 'double', caption: 'dmrf.fields.retencao'},
        {caption: '', cellTemplate: 'cellTemplateAlerts', showInColumnChooser: false, allowSorting: false, allowSearch: false, allowFiltering: false, allowHeaderFiltering: false, allowHiding: false}
      ],
      dataSource: [],
      headerFilter: {visible: false},
      filterRow: {visible: false},
      remoteOperations: false,
      toolbar: {visible: false}
    };
    this.showLoadingModal = false;
    this.loadingModalMessage = '';
    this._addNewRecordToolbarBtn = {
      id: `${TOOLBAR_INSTANCE_ID_MAIN}-new-record`,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-plus"></i>',
      class: 'btn-success btn-xs',
      caption: 'global.btn.add',
      disabled: true,
      click: () => this._addNewRecord()
    };
    this._deletePeriodoToolbarBtn = {
      id: 'deleteDeclaracao',
      type: 'button',
      order: 0,
      class: 'btn-light',
      iconLeft: '<i class="fa fa-trash"></i>&nbsp;',
      caption: 'dmrf.toolbar.deletePeriodo',
      disabled: true,
      click: () => this._deleteDeclaracao()
    };
    this._refreshDeclaracaoToolbarBtn = {
      id: 'refreshDeclaracao',
      type: 'button',
      order: 1,
      class: 'btn-light',
      iconLeft: '<i class="fa fa-refresh"></i>&nbsp;',
      caption: 'dmrf.toolbar.refresh',
      disabled: true,
      click: () => {
        this._refreshMainTable();
      }
    };
    this._saveDeclaracaoToolbarBtn = {
      id: 'saveDeclaracao',
      type: 'button',
      order: 2,
      class: 'btn-light',
      iconLeft: '<i class="fa fa-floppy-o"></i>&nbsp;',
      caption: 'dmrf.toolbar.save',
      disabled: true,
      click: () => this._saveDeclaracao()
    };
    this._exportDeclaracaoToolbarBtn = {
      id: 'exportDeclaracao',
      type: 'button',
      order: 2,
      class: 'btn-light',
      iconLeft: '<i class="fa fa-download"></i>&nbsp;',
      caption: 'dmrf.toolbar.export',
      disabled: true,
      click: () => this._exportDeclaracao()
    };
    this._sendDeclaracaoToolbarBtn = {
      id: 'copyTasks',
      type: 'button',
      order: 3,
      class: 'btn-success',
      iconLeft: '<i class="fa fa-send"></i>&nbsp;',
      caption: 'dmrf.toolbar.send',
      click: () => this._sendDeclaracao()
    };
    this._firstLoad = true;
    this._msStarted = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar
      .addButton(this._deletePeriodoToolbarBtn)
      .addButton(this._refreshDeclaracaoToolbarBtn)
      .addButton(this._saveDeclaracaoToolbarBtn)
      .addButton(this._exportDeclaracaoToolbarBtn)
      .addButton(this._sendDeclaracaoToolbarBtn);
    const toolbarInstance: IPlToolbarInstance = this._plToolbarService.getInstance(this.tbInstanceId);
    toolbarInstance.setItems([this._addNewRecordToolbarBtn]);
    setTimeout(() => {
      this._registerOnStart();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._plToolbarService.unRegisterInstance(this.tbInstanceId);
    this._deRegisterOnStart();
    this._dmrfService.stopMS();
  }

  public setInstanceName(value: string): void {
    super.setInstanceName(value);
    if (this.tbInstanceId) {
      this._plToolbarService.unRegisterInstance(this.tbInstanceId);
    }
    this.tbInstanceId = `${this.instanceName}-${TOOLBAR_INSTANCE_ID_MAIN}`;
  }

  public editRecord(item: IDMRFItem): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DmrfEditModalComponent, {size: 'md'});
    const componentInstance: DmrfEditModalComponent = modalInstance.componentInstance;
    componentInstance.item = item;
    componentInstance.periodo = this.model.periodo;
    return modalInstance.result.then(() => {
      this._refreshMainTable();
    });
  }

  public deleteRecord(item: IDMRFItem): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'dmrf.messages.promptDeleteRecord', {size: 'md'}).then(() => {
      this._showLoading('dmrf.messages.deletingRecord');
      return this._dmrfService
        .deleteRecord(item.dmrfId)
        .then(() => {
          this._refreshMainTable();
        })
        .finally(() => {
          this._hideLoading();
        });
    });
  }

  public onPeriodoChange(value: string): void {
    this.model.periodo = value;
    this._addNewRecordToolbarBtn.disabled = isEmpty(this.model.periodo);
    this._refreshMainTable();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IDMRFItem>): void {
    this._dataGridInstance = component;
  }

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._appService.sendBeacon(this._dmrfService.stopMSUrl());
  }

  private _getTableSourceMain(): Promise<Array<IDMRFItem>> {
    if (this._firstLoad) {
      return Promise.resolve([]);
    }
    this._toggleToolbar(true);
    this._addNewRecordToolbarBtn.disabled = true;
    return this._dmrfService.load(this.model.periodo).then((response: HttpResponse<IDMRFLoadResults>) => {
      this._msStarted = true;
      this.tableSummarySource = response.body.summary;
      this._toggleToolbar(response.body.data.length === 0);
      this._addNewRecordToolbarBtn.disabled = false;
      this.dataGridDefinitionSummary.dataSource = new ArrayStore({
        data: this.tableSummarySource
      });
      return response.body.data;
    });
  }

  private _hideLoading(): void {
    this.loadingModalMessage = '';
    this.showLoadingModal = false;
  }

  private _showLoading(message: string): void {
    this.loadingModalMessage = this._plTranslateService.translate(message);
    this.showLoadingModal = true;
  }

  private _addNewRecord(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DmrfEditModalComponent, {size: 'md'});
    const componentInstance: DmrfEditModalComponent = modalInstance.componentInstance;
    componentInstance.periodo = this.model.periodo;
    return modalInstance.result.then(() => {
      this._refreshMainTable();
    });
  }

  private _refreshMainTable(): void {
    this._firstLoad = false;
    if (this._dataGridInstance) {
      this._dataGridInstance.refresh();
    }
  }

  private _deleteDeclaracao(): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'dmrf.messages.promptDeleteDeclaracao', {size: 'md'}).then(() => {
      this._showLoading('dmrf.messages.deleting');
      return this._dmrfService
        .delete()
        .then(() => {
          this._refreshMainTable();
        })
        .finally(() => {
          this._hideLoading();
        });
    });
  }

  private _saveDeclaracao(): TServiceResponse<void> {
    this._showLoading('dmrf.messages.saving');
    return this._dmrfService.save(this.model.frestran).finally(() => {
      this._hideLoading();
    });
  }

  private _exportDeclaracao(): Promise<void> {
    this._showLoading('dmrf.messages.exporting');
    return this._dmrfService
      .exportXML()
      .then((blob) => {
        if (!blob) {
          this._plAlertService.error(this._plTranslateService.translate('dmrf.messages.erroExport'));
        } else {
          downloadStream(blob);
        }
      })
      .finally(() => {
        this._hideLoading();
      });
  }

  private _sendDeclaracao(): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'dmrf.messages.promptSendMessage', {size: 'md'}).then(() => {
      this._showLoading('dmrf.messages.sending');
      return this._dmrfService
        .sendAT(this.model.frestran)
        .then(() => {
          this._plAlertService.success(this._plTranslateService.translate('dmrf.messages.sendSuccess'));
        })
        .finally(() => {
          this._hideLoading();
        });
    });
  }

  private _toggleToolbar(isDisabled: boolean): void {
    this._deletePeriodoToolbarBtn.disabled = isDisabled;
    this._refreshDeclaracaoToolbarBtn.disabled = isDisabled;
    this._saveDeclaracaoToolbarBtn.disabled = isDisabled;
    this._exportDeclaracaoToolbarBtn.disabled = isDisabled;
    this._sendDeclaracaoToolbarBtn.disabled = isDisabled;
  }

  private _registerOnStart(): void {
    this._deRegisterOnStart();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_LOGIN && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFn = this._transitionService.onStart(criteria, () => this._navigationSafeGuard());
  }

  private _deRegisterOnStart(): void {
    if (this._deRegisterOnStartFn) {
      this._deRegisterOnStartFn();
      this._deRegisterOnStartFn = undefined;
    }
  }

  private _navigationSafeGuard(): Promise<void> {
    if (!isTest()) {
      if (this._msStarted) {
        return this._cgModalService.showOkCancel('dmrf.leavePromptTitle', 'dmrf.leavePromptMessage', {
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          btnOkText: 'global.btn.yes',
          btnCancelText: 'global.btn.no'
        });
      }
    }
    return Promise.resolve();
  }
}
