<div class="acrescimosencargosferias-gerar-encargos-multi-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'acrescimosencargosferias.modals.empresas.title'"></h5>
  </div>

  <div class="modal-body">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinitionEmpresas"
      cgDxDataGridInstanceName="empresasTable"
      [dataSource]="dataGridDefinitionEmpresas.dataSource"
      (onInitialized)="onInitialized($event)"
      (onCellPrepared)="onCellPrepared($event)">
      <div *dxTemplate="let item of 'toolbarTemplateBtnsEmpresas'"></div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-dismiss" [disabled]="closeDisabled" [onClick]="close">
      <i class="fa fa-fw fa-plus"></i>&nbsp;<span [translate]="'global.btn.add'"></span>
    </pl-button>

    <pl-button klass="btn-light btn-sm action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()"
      ><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
    </pl-button>
  </div>
</div>
