import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlTranslateModule} from '../translate/translate.module';

import {PlAlertComponent} from './alert.component';

export * from './alert.interface';
export * from './alert.component';

const DECLARATIONS = [PlAlertComponent];

@NgModule({
  imports: [CommonModule, PlTranslateModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlAlertModule {}
