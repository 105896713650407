<div class="modal-header">
  <h5 class="modal-title" [translate]="'importadorSaftDirect.messages.importInProcessModalTitle'"></h5>
</div>

<div class="modal-body" [translate]="modalMessage"></div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary btn-sm action-close" (evtClicked)="close('load')" plAutoFocus>
    <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'importadorSaftDirect.messages.importInProcessModalLoadProcess'"></span>
  </pl-button>

  <pl-button klass="btn-light btn-sm" (evtClicked)="close('reset')">
    <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'importadorSaftDirect.messages.importInProcessModalCancelProcess'"></span>
  </pl-button>
</div>
