import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ENTITY_NAME_ERP_UTILIZADORES, IErpUtilizador} from './erpUtilizadores.entity.interface';

export const ENTITY_ERP_UTILIZADORES: IEntityDefinition<IErpUtilizador> = {
  url: 'manager/erpusers',
  name: ENTITY_NAME_ERP_UTILIZADORES,
  roles: [ROLE.ADMIN, ROLE.ERP],
  searchPlaceholder: 'erputilizadores.pesquisa',
  metadata: {
    keyName: 'nutilizador',
    fields: [
      {
        name: 'nutilizador',
        width: '120px',
        caption: 'erputilizadores.fields.nutilizador',
        placeholder: 'erputilizadores.fields.nutilizadorPlaceholder',
        validators: {required: true, maxlength: 20}
      },
      {
        name: 'nomeUtilizador',
        caption: 'erputilizadores.fields.nomeUtilizador',
        placeholder: 'erputilizadores.fields.nomeUtilizadorPlaceholder',
        validators: {required: true, maxlength: 50}
      },
      {
        name: 'nomeCompleto',
        caption: 'erputilizadores.fields.nomeCompleto',
        placeholder: 'erputilizadores.fields.nomeCompletoPlaceholder',
        validators: {required: true, maxlength: 50}
      }
    ],
    order: 'nutilizador',
    searchFields: 'nutilizador,nomeUtilizador,nomeCompleto'
  },
  autocomplete: {
    rowTemplate: '{{nutilizador}} - {{nomeUtilizador}}',
    output: 'nomeUtilizador',
    searchFields: 'nutilizador,nomeCompleto'
  },
  actions: {
    new: false,
    detail: false,
    edit: false,
    delete: false,
    search: false
  }
};
