import {Component, Injector, OnInit} from '@angular/core';
import {IPlToolbarMenuItem, isEmptyObject, isObject, PlAlertService} from 'pl-comps-angular';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonMeioPagamento} from '../../jsonMeioPagamento.entity.interface';
import {ETipo, ETipoPagamento, ITipo, ITipoPagamento} from '../../meiosPagamento.entity.interface';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {IJsonConfigERP} from '../../../configserp/jsonConfigERP.entity.interface';
import {ConfigErpService} from '../../../../services/configErp.service';
import {HttpResponse} from '@angular/common/http';
import {IApiRequestConfigWithBody} from '../../../../services/api/api.service.interface';
import {IJsonPOC} from '../../../pocs/jsonPOC.entity.interface';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';

@Component({
  selector: 'meiosPagamento-edit',
  templateUrl: './meiosPagamento.entity.edit.component.html'
})
export class MeiosPagamentoEditComponent extends ModuloEntityDetailComponent<IJsonMeioPagamento> implements OnInit {
  public readonly tiposMeiosPagamento: typeof ETipoPagamento;
  public readonly pocFilter: string;
  public canEdit: boolean;
  public canSee: boolean;
  public tipoPagamento: Array<ITipoPagamento>;
  public tipoMPaga: Array<ITipo>;
  public requiresSwift: boolean;

  public porDefeitoPagamentos: boolean;
  public porDefeitoRecibos: boolean;
  public changedPorDefeitoPagamentos: boolean;
  public changedPorDefeitoRecibos: boolean;
  public configPagamentosMeioPagamOmissao: IJsonConfigERP;
  public configRecibosMeioPagamOmissao: IJsonConfigERP;
  public onInitPorDefeitoPagamentos: boolean;
  public onInitPorDefeitoRecibos: boolean;
  private _btnCanSee: IPlToolbarMenuItem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _configErpService: ConfigErpService
  ) {
    super(_injector);
    // Retirado a pedido de Tiago Carreira #EC-3144
    // const value: number = this._configService.configurations.acessos.erpcloud.gestaoComercial.editarMeioPagamento;
    // this.canEdit = isNumber(value) && value > 0;
    this.canEdit = true;
    this.canSee = false;
    this.porDefeitoPagamentos = false;
    this.porDefeitoRecibos = false;
    this.changedPorDefeitoPagamentos = false;
    this.changedPorDefeitoRecibos = false;
    this.tipoPagamento = [
      {value: ETipoPagamento.Indiferente, label: 'meiosPagamento.data.indiferente'},
      {value: ETipoPagamento.Recebimento, label: 'meiosPagamento.data.recebimento'},
      {value: ETipoPagamento.Pagamento, label: 'meiosPagamento.data.pagamento'}
    ];
    this.tipoMPaga = [
      {value: ETipo.Indiferente, label: 'meiosPagamento.data.indiferente'},
      {value: ETipo.Dinheiro, label: 'meiosPagamento.data.dinheiro'},
      {value: ETipo.Multibanco, label: 'meiosPagamento.data.multibanco'},
      {value: ETipo.CartaoCredito, label: 'meiosPagamento.data.cartaoCredito'},
      {value: ETipo.ValorAdicional, label: 'meiosPagamento.data.valorAdicional'},
      {value: ETipo.Pontos, label: 'meiosPagamento.data.pontos'},
      {value: ETipo.ContraReemsolso, label: 'meiosPagamento.data.contraReemsolso'},
      {value: ETipo.TrfBancaria, label: 'meiosPagamento.data.trfBancaria'},
      {value: ETipo.PayPal, label: 'meiosPagamento.data.payPal'}
    ];
    this.tiposMeiosPagamento = ETipoPagamento;
    this.pocFilter = `TIPO=${ETipoContaContabilidade.Movimento}`;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnEdit.visible = this.canEdit && this.type === EEntityStateDetailType.DETAIL;
    this.btnDuplicate.visible = Boolean(this._configService.configurations.acessos.erpcloud.gestaoComercial.editarMeioPagamento);
    if (this.type !== EEntityStateDetailType.NEW) {
      this._btnCanSee = {
        caption: 'meiosPagamento.btn.verContas',
        click: () => {
          this._btnOptions();
        }
      };
      this.toolbar.addButton({
        id: 'options',
        order: 140,
        type: 'dropdown',
        iconLeft: '<i class="fa fa-fw fa-cogs"></i>',
        class: 'btn-success',
        caption: 'meiosPagamento.btn.buttonOptions',
        menu: [this._btnCanSee]
      });
    }
    if (isEmptyObject(this.model)) {
      this.model = {
        nCaixa: undefined,
        nConta: '',
        nib: '',
        nome: '',
        nomeConta: '',
        tipo: 0,
        tipoMovimento: 0,
        codMoeda: 0
      };
    }
    this.model.codMoeda = this._configService.configurations.empresa.codMoeda;

    this.onInitPorDefeitoPagamentos = this.model.porDefeito === 'Pagamentos | Recibos' || this.model.porDefeito === 'Pagamentos';
    this.onInitPorDefeitoRecibos = this.model.porDefeito === 'Pagamentos | Recibos' || this.model.porDefeito === 'Recibos';
    this.porDefeitoPagamentos = this.onInitPorDefeitoPagamentos;
    this.porDefeitoRecibos = this.onInitPorDefeitoRecibos;

    this._configErpService.getConfiguration('contabilidade.pagamentos.meioPagamentoOmissao').then((result: HttpResponse<IJsonConfigERP>) => {
      this.configPagamentosMeioPagamOmissao = result.body;
    });

    this._configErpService.getConfiguration('contabilidade.recibos.meioPagamentoOmissao').then((result: HttpResponse<IJsonConfigERP>) => {
      this.configRecibosMeioPagamOmissao = result.body;
    });

    this.requiresSwift = this.model.tipo === ETipo.TrfBancaria && (this.model.tipoMovimento === ETipoPagamento.Indiferente || this.model.tipoMovimento === ETipoPagamento.Pagamento);
  }

  public fnBeforeChangePagamentosPorDefeito = (value: boolean): boolean => this._beforeChangePagamentosPorDefeito(value);
  public fnBeforeChangeRecibosPorDefeito = (value: boolean): boolean => this._beforeChangeRecibosPorDefeito(value);

  public onTipoChanged(value: number): void {
    this.model.tipo = value;
    this.requiresSwift = this.model.tipo === ETipo.TrfBancaria && (this.model.tipoMovimento === ETipoPagamento.Indiferente || this.model.tipoMovimento === ETipoPagamento.Pagamento);
  }

  public onIbanChanged(value: string): Promise<void> {
    return this.service.get<string>({url: `trfsepa/iban/${value}/swift`}).then((response: HttpResponse<string>) => {
      this.model.swift = response.body;
      this.model.nib = value;
    });
  }

  public onPorDefeitoPagamentosChanged(value: boolean): void {
    this.porDefeitoPagamentos = value;
  }

  public onPorDefeitoRecibosChanged(value: boolean): void {
    this.porDefeitoRecibos = value;
  }

  public onTipoMovimentoChange(value: number): void {
    this.model.tipoMovimento = value;
    this.porDefeitoPagamentos = this.onInitPorDefeitoPagamentos;
    this.porDefeitoRecibos = this.onInitPorDefeitoRecibos;
    this.requiresSwift = this.model.tipo === ETipo.TrfBancaria && (this.model.tipoMovimento === ETipoPagamento.Indiferente || this.model.tipoMovimento === ETipoPagamento.Pagamento);
  }

  public onContaChange(conta: IJsonPOC): void {
    if (isObject(conta)) {
      if (conta.tipo !== ETipoContaContabilidade.Movimento) {
        this._plAlertService.error(this._translateService.instant('meiosPagamento.mensagens.contaInvalida', {nConta: this.model.nConta}));
        this.model = {...this.model, nConta: '', nomeConta: ''};
      }
    }
  }

  public save(config?: IApiRequestConfigWithBody<IJsonMeioPagamento>): Promise<IJsonMeioPagamento> {
    this.model.codMoeda = this._configService.configurations.empresa.codMoeda;
    if (
      (this.onInitPorDefeitoPagamentos && this.model.tipoMovimento === ETipoPagamento.Recebimento.valueOf()) ||
      (this.onInitPorDefeitoRecibos && this.model.tipoMovimento === ETipoPagamento.Pagamento.valueOf())
    ) {
      const message = this._translateService.instant('meiosPagamento.mensagens.meioPagamentoAlteradoPorOmissao', {tipo: this.model.porDefeito});
      return this._cgModalService.showOkCancel('meiosPagamento.mensagens.meioPagamentoAlteradoPorOmissaoTitle', message).then(() => {
        return super.save(config).then((result) => {
          return result;
        });
      });
    }

    return super.save(config).then((result) => {
      if (this.porDefeitoRecibos !== this.onInitPorDefeitoRecibos && this.model.tipoMovimento !== ETipoPagamento.Pagamento.valueOf()) {
        this.configRecibosMeioPagamOmissao.value = this.model.nCaixa;
        this._configErpService.save(this.configRecibosMeioPagamOmissao, false);
      }
      if (this.porDefeitoPagamentos !== this.onInitPorDefeitoPagamentos && this.model.tipoMovimento !== ETipoPagamento.Recebimento.valueOf()) {
        this.configPagamentosMeioPagamOmissao.value = this.model.nCaixa;
        this._configErpService.save(this.configPagamentosMeioPagamOmissao, false);
      }
      return result;
    });
  }

  private _btnOptions(): void {
    this.canSee = !this.canSee;
    this._btnCanSee.caption = this.canSee ? 'meiosPagamento.btn.esconderContas' : 'meiosPagamento.btn.verContas';
  }

  private _beforeChangePagamentosPorDefeito(value: boolean): boolean {
    if (!value && !this.changedPorDefeitoPagamentos) {
      this._plAlertService.error('meiosPagamento.table.actions.errorDesactivate');
      return false;
    }
    return true;
  }

  private _beforeChangeRecibosPorDefeito(value: boolean): boolean {
    if (!value && !this.changedPorDefeitoRecibos) {
      this._plAlertService.error('meiosPagamento.table.actions.errorDesactivate');
      return false;
    }
    return true;
  }
}
