import {TDate} from '../../../../common/dates';
import {IRadioGroup} from '../../../../common/interfaces/interfaces';
import {deepFreeze} from 'pl-comps-angular';

const PARTIAL_EXTERNAL_PAG_GUID = 'D360F1C79B9A';

export const MODULE_NAME_MAPA_PAGAMENTOS = 'mapaPagamentos';

export type TMapaPagamJobStateIdleCallback = (success: boolean) => void;

export enum EMapaPagamTipoRegimeIva {
  ALL = -1,
  None,
  Normal,
  Isencao,
  PequenosRetalhistas,
  RegimeNormalBens2Mao,
  RegimeNormalRevendaCombustiveis,
  RegimeIvaCaixa
}

export enum EMapaPagamTipoPeriodoIva {
  ALL = -1,
  None,
  Mensal,
  Trimestral
}

export enum EJobMapaPagamState {
  IDLE = 'jmpsIdle',
  WORKING = 'jmpsWorking',
  ERROR = 'jmpsError'
}

export enum EMapaPagamResend {
  RETRY,
  RETRY_SKIP
}

export enum EMapaPagamPdfViewerMode {
  DOCUMENTO,
  DOCUMENTO_UNICO
}

export enum EMapaPagamDataGridProps {
  SELECTED = 'selected',
  PAGAMENTO = 'pagamento',
  PAGAMENTO_GUID = 'pagamentoGUID',
  ENVIADO = 'enviado',
  MONTANTETOTAL = 'montanteTotal',
  NRESPONSAVEL = 'nResponsavel'
}

export enum EMapaPagamImpostoEnum {
  Manual,
  IVA,
  IRCMod22,
  IMI,
  IUC,
  DMR,
  RetencaoNaFonte,
  PagAntIRC,
  DMRSS
}

export enum EJsonMapaPagamMessageCode {
  None,
  EmptyCredentialsAT,
  EmptyCredentialsSSD,
  LoginAT,
  LoginSSD,
  ServiceApiAT,
  ServiceApiSSD
}

export enum EJsonMapaPagamMessageType {
  Message,
  Error,
  Warning
}

export enum EMapaPagamEntity {
  AT,
  SSD
}

export enum EMapaPagamManualDeleteMethod {
  OnlyMonthOfYear,
  AllInYear,
  Everywhere
}

export const RADIO_GROUP_DELETE_METHODS: IRadioGroup<EMapaPagamManualDeleteMethod> = deepFreeze<IRadioGroup<EMapaPagamManualDeleteMethod>>({
  groupItems: [
    {value: EMapaPagamManualDeleteMethod.OnlyMonthOfYear, label: 'mapapagam.texts.apagarApenasMesCorrente'},
    {value: EMapaPagamManualDeleteMethod.AllInYear, label: 'mapapagam.texts.apagarTodosMesesNoAnoCurrente'},
    {value: EMapaPagamManualDeleteMethod.Everywhere, label: 'mapapagam.texts.apagarPorCompletoEmTodasEmpresas'}
  ]
});

export interface IJsonMapaPagamImposto {
  value: EMapaPagamImpostoEnum;
  label: string;
}

export interface IJsonMapaPagamResponse {
  sucesso: boolean;
  erro: string;
}

export interface IJsonMapaPagamKeyPair {
  text: string;
  value: string;
}

export interface IJsonMapaPagamEmpresaPagamentoDocumento {
  refPag: string;
  dataLimitePag: TDate;
  montante: number;
  gDocId: string;
  numNota: string;
  descricao: string;
  idDoc: string;
  pago: boolean;
  detalhes: Array<IJsonMapaPagamKeyPair>;
}

export interface IJsonMapaPagamEmpresaPagamentoItem {
  pagamentoGUID: string;
  nome: string;
  descricao: string;
  ano: number;
  mes: number;
  pago: boolean;
  lido: boolean;
  lidoDataHora: TDate;
  enviado: boolean;
  enviadoDataHora: TDate;
  nResponsavel: number;
  impostoEnum: EMapaPagamImpostoEnum;
  entityDocument: boolean;
  documentos: Array<IJsonMapaPagamEmpresaPagamentoDocumento>;
  pagManTaskId: string;
  haveDocWithAttachment: boolean;
  pagoUnknown?: boolean;
}

export interface IMapaPagamEmpresaDataGridPagamentoItem {
  selected: boolean;
  montanteTotal: number;
  pagamento: IJsonMapaPagamEmpresaPagamentoItem;
  havePayments: boolean;
  _sortValue: number;
  selectRowCheck?: boolean;
}

export interface IJsonMapaPagamEmpresaMessageItem {
  code: EJsonMapaPagamMessageCode;
  message: string;
  type: EJsonMapaPagamMessageType;
}

export interface IJsonMapaPagamEmpresaSimpleItem {
  nEmpresa: string;
  nomeEmpresa: string;
}

export interface IJsonMapaPagamEmpresaItem extends IJsonMapaPagamEmpresaSimpleItem {
  email: string;
  nif: string;
  regimeIva: EMapaPagamTipoRegimeIva;
  periodoIva: number;
  regimeIvaString: string;
  periodoIvaString: string;
  messageList: Array<IJsonMapaPagamEmpresaMessageItem>;
  pagamentos: Array<IJsonMapaPagamEmpresaPagamentoItem>;
  messagesAT?: Array<IJsonMapaPagamEmpresaMessageItem>;
  messagesSSD?: Array<IJsonMapaPagamEmpresaMessageItem>;
  stateCssClassAT?: string;
  stateCssClassSSD?: string;
  stateTooltipAT?: string;
  stateTooltipSSD?: string;
  selectRowCheck?: boolean;
}

export interface IJsonMapaPagamLoadResults {
  empresas: Array<IJsonMapaPagamEmpresaItem>;
  columns: Array<string>;
}

export interface IJsonMapaPagamStatus {
  statusText: string;
}

export interface IMapaPagamDataGridRecord {
  [key: string]: unknown;

  nEmpresa: string;
  nif: string;
  nomeEmpresa: string;
  email: string;
  messageList: Array<IJsonMapaPagamEmpresaMessageItem>;
  havePagamentos: boolean;
  messagesAT?: Array<IJsonMapaPagamEmpresaMessageItem>;
  messagesSSD?: Array<IJsonMapaPagamEmpresaMessageItem>;
  stateCssClassAT?: string;
  stateCssClassSSD?: string;
  stateTooltipAT?: string;
  stateTooltipSSD?: string;
  selectRowCheck?: boolean;
  totalPagamentosSel: number;
}

export interface IMapaPagamDataGridColumnCheckboxesModel {
  [key: string]: boolean;
}

export interface IJsonMapaPagamManualDadosPagamento {
  refPag: string;
  dataLimitePag: TDate;
  montante: number;
  pago: boolean;
}

export interface IJsonMapaPagamManual {
  nome: string;
  descricao: string;
  nResponsavel: number;
  nomeResponsavel?: string;
  dadosPagamento: IJsonMapaPagamManualDadosPagamento;
}

export interface IJsonMapaEditPagamManual extends IJsonMapaPagamManual {
  mes: number;
  ano: number;
  gDocId: string;
}

export interface IJsonMapaCreatePagamManual extends IJsonMapaPagamManual {
  empresas: Array<string>;
  meses: Array<number>;
  anos: Array<number>;
}

export interface IMapaPagamManualMesesModel {
  [key: string]: {
    checked: boolean;
  };
}

export interface IMapaPagamATDocumentsSourceItem {
  value: string;
  name: string;
}

export interface IJsonMapaPagamEnvioEmpresaItem {
  nEmpresa: string;
  email: string;
  listOfPagamentos: Array<string>;
}

export interface IJsonMapaPagamEmpresasEnvio {
  ano: number;
  mes: number;
  empresas: Array<IJsonMapaPagamEnvioEmpresaItem>;
}

export interface IJsonMapaPagamEmpresasEnvioResumoPagamento {
  nomePagamento: string;
  errorMessage: string;
}

export interface IJsonMapaPagamEmpresasEnvioResumoItem {
  nEmpresa: string;
  success: boolean;
  errorMessage: string;
  pagamentos: Array<IJsonMapaPagamEmpresasEnvioResumoPagamento>;
}

export interface IJsonMapaPagamEmpresasEnvioResumo {
  resumo: Array<IJsonMapaPagamEmpresasEnvioResumoItem>;
}

export interface IJsonMapaPagamState {
  state: EJobMapaPagamState;
  description: string;
  progress: number;
  max: number;
}

export interface IMapaPagamJaEnviadoItem {
  nEmpresa: string;
  nomeEmpresa: string;
  pagamentos: Array<IJsonMapaPagamEmpresaPagamentoItem>;
}

export interface IJsonMapaPagamATLoginModel {
  username: string;
  password: string;
}

export interface IMapaPagamFilters {
  ano: number;
  mes: number;
  responsavel: IMapaPagamentoResponsavel;
  regimeIva: EMapaPagamTipoRegimeIva;
  periodoIva: EMapaPagamTipoPeriodoIva;
  nomeResponsavel?: string;
}

export interface IMapaPagamFilterSelectItem {
  value: number;
  name: string;
}

export interface IMapaPagamentoModalResult {
  isDelete: boolean;
  results: IJsonMapaPagamLoadResults;
  newPagName: string;
  oldPagName: string;
}

export interface IMapaPagamentoResponsavel {
  nResponsavel: number;
  nomeResponsavel: string;
}

export enum EMapaPagamentoConfigStepsEnum {
  EMPRESAS,
  PAGAMENTOS
}

export interface IJsonMapaPagamentoUserConfig {
  selectedEmpresas: Array<IJsonMapaPagamEmpresaSimpleItem>;
  selectedPagamentos: Array<EMapaPagamImpostoEnum>;
}

export interface IJsonMapaPagamentoConfig extends IJsonMapaPagamentoUserConfig {
  empresas: Array<IJsonMapaPagamEmpresaSimpleItem>;
  isConfigured: boolean;
}

export interface IJsonMapaPagamentoLoadEmpresasFilters {
  regimeIva: EMapaPagamTipoRegimeIva;
  periodoIva: EMapaPagamTipoPeriodoIva;
}

export interface IMapaPagamentoLastRequestFilters {
  ano: number;
  mes: number;
}

export function isPagamentoManual(pagamentoGUID: string): boolean {
  return !pagamentoGUID.includes(PARTIAL_EXTERNAL_PAG_GUID);
}

export interface IMapaPagamentoEmpresaPaymentsSelected {
  [nEmpresa: string]: Array<IMapaPagamEmpresaDataGridPagamentoItem>;
}

export interface IJsonMapaPagamPagManuaisItem {
  nomePagamento: string;
  empresas: Array<string>;
  meses: Array<number>;
}

export interface IJsonMapaPagamPagManuaisResults {
  data: Array<IJsonMapaPagamPagManuaisItem>;
}

export interface IJsonMapaPagamPagManuaisSaveData extends IJsonMapaPagamPagManuaisItem {
  ano: number;
}
