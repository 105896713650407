/**
 * CentralGest Amalia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MessageBody } from './messageBody';


/**
 * A message payload.
 */
export interface Message { 
    /**
     * The message Id
     */
    messageId?: string;
    /**
     * The response to Id
     */
    parentId?: string;
    /**
     * The timestamp in UTC from the message
     */
    timestamp?: string;
    /**
     * The room Id
     */
    roomId?: string;
    /**
     * The Actor who wrote the message
     */
    userId?: string;
    /**
     * The interaction channel
     */
    channel?: Message.ChannelEnum;
    /**
     * The message subject
     */
    from?: Message.FromEnum;
    body?: Array<MessageBody>;
}
export namespace Message {
    export type ChannelEnum = 'CHAT';
    export const ChannelEnum = {
        Chat: 'CHAT' as ChannelEnum
    };
    export type FromEnum = 'CLIENT' | 'AGENT';
    export const FromEnum = {
        Client: 'CLIENT' as FromEnum,
        Agent: 'AGENT' as FromEnum
    };
}


