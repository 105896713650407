import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonRHRelatorioUnicoConfig, IJsonRHRelatorioUnicoConfigEstab} from '../../jsonRHRelatorioUnico.module.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {RHRelatorioUnicoService} from '../../rhRelatorioUnico.module.service';
import {PlAlertService} from 'pl-comps-angular';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';

@Component({
  selector: 'modal-rh-relatorio-unico-config',
  templateUrl: './rhRelatorioUnico.config.modal.component.html'
})
export class RHRelatorioUnicoConfigModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public ruConfig: IJsonRHRelatorioUnicoConfig;

  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonRHRelatorioUnicoConfigEstab, number>;

  private _dataGridInstance: dxDataGrid<IJsonRHRelatorioUnicoConfigEstab, number>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhrelatoriounicoService: RHRelatorioUnicoService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nestabelec', dataType: 'number', caption: 'rhrelatoriounico.modal.config.nestabelec', allowEditing: false},
        {dataField: 'nome', dataType: 'string', caption: 'rhrelatoriounico.modal.config.nome', allowEditing: false},
        {dataField: 'nestabQPessoal', dataType: 'string', caption: 'rhrelatoriounico.modal.config.nestabQPessoal'}
      ],
      dataSource: [],
      editing: {
        allowUpdating: true,
        mode: 'cell'
      },
      export: {filename: 'rhrelatoriounico.modal.config.title'},
      keyExpr: 'nestabelec',
      remoteOperations: false
    };
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonRHRelatorioUnicoConfigEstab, number>): void {
    this._dataGridInstance = component;
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = this.ruConfig.estabelecimentos;
  }

  public async close(): Promise<void> {
    if (!this.ruConfig.idEntidade) {
      this._plAlertService.error('rhrelatoriounico.modal.config.idEntidadeEmpty');
      return;
    }
    this.disableClose();
    await this._dataGridInstance.saveEditData();
    const config: IJsonRHRelatorioUnicoConfig = {estabelecimentos: this._dataGridInstance.getDataSource().items(), ...this.ruConfig};
    try {
      await this._rhrelatoriounicoService.saveRelatorioUnicoConfig(config);
      this._plAlertService.success('rhrelatoriounico.modal.config.savesuccess');
      super.close();
    } finally {
      this.enableClose();
    }
  }
}
