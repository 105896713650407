import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_MODELO22_PAISES} from '../modelo22.datasource.interface';
import {EPaisesMod22} from '../../../modules/portalcontabilidade/modelo22/modelo22.module.interface';

export const DATA_SOURCE_MODELO22_PAISES: IDataSource<EPaisesMod22> = deepFreeze({
  name: DATA_SOURCE_NAME_MODELO22_PAISES,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{value}} - {{name}}',
    output: '{{value}} - {{name}}'
  },
  data: [
    {value: EPaisesMod22.AFEGANISTAO, name: 'modelo22.datasources.paises.4'},
    {value: EPaisesMod22.ALBANIA, name: 'modelo22.datasources.paises.8'},
    {value: EPaisesMod22.ANTARCTICA, name: 'modelo22.datasources.paises.10'},
    {value: EPaisesMod22.ARGELIA, name: 'modelo22.datasources.paises.12'},
    {value: EPaisesMod22.SAMOA_AMERICANA, name: 'modelo22.datasources.paises.16'},
    {value: EPaisesMod22.ANDORRA, name: 'modelo22.datasources.paises.20'},
    {value: EPaisesMod22.ANGOLA, name: 'modelo22.datasources.paises.24'},
    {value: EPaisesMod22.ANTIGUA_E_BARBUDA, name: 'modelo22.datasources.paises.28'},
    {value: EPaisesMod22.AZERBAIJAO, name: 'modelo22.datasources.paises.31'},
    {value: EPaisesMod22.ARGENTINA, name: 'modelo22.datasources.paises.32'},
    {value: EPaisesMod22.AUSTRALIA, name: 'modelo22.datasources.paises.36'},
    {value: EPaisesMod22.AUSTRIA, name: 'modelo22.datasources.paises.40'},
    {value: EPaisesMod22.BAHAMAS, name: 'modelo22.datasources.paises.44'},
    {value: EPaisesMod22.BAREM, name: 'modelo22.datasources.paises.48'},
    {value: EPaisesMod22.BANGLADESH, name: 'modelo22.datasources.paises.50'},
    {value: EPaisesMod22.ARMENIA, name: 'modelo22.datasources.paises.51'},
    {value: EPaisesMod22.BARBADOS, name: 'modelo22.datasources.paises.52'},
    {value: EPaisesMod22.BELGICA, name: 'modelo22.datasources.paises.56'},
    {value: EPaisesMod22.BERMUDA, name: 'modelo22.datasources.paises.60'},
    {value: EPaisesMod22.BUTAO, name: 'modelo22.datasources.paises.64'},
    {value: EPaisesMod22.BOLIVIA, name: 'modelo22.datasources.paises.68'},
    {value: EPaisesMod22.BOSNIA_E_HERZEGOVINA, name: 'modelo22.datasources.paises.70'},
    {value: EPaisesMod22.BOTSWANA, name: 'modelo22.datasources.paises.72'},
    {value: EPaisesMod22.ILHAS_BOUVET, name: 'modelo22.datasources.paises.74'},
    {value: EPaisesMod22.BRASIL, name: 'modelo22.datasources.paises.76'},
    {value: EPaisesMod22.BELIZE, name: 'modelo22.datasources.paises.84'},
    {value: EPaisesMod22.TERRITORIO_BRITANICO_DO_OCEANO_INDICO, name: 'modelo22.datasources.paises.86'},
    {value: EPaisesMod22.ILHAS_SALOMAO, name: 'modelo22.datasources.paises.90'},
    {value: EPaisesMod22.ILHAS_VIRGENS_BRITANICAS, name: 'modelo22.datasources.paises.92'},
    {value: EPaisesMod22.BRUNEI_DARUSSALAM, name: 'modelo22.datasources.paises.96'},
    {value: EPaisesMod22.BULGARIA, name: 'modelo22.datasources.paises.100'},
    {value: EPaisesMod22.MYANMAR, name: 'modelo22.datasources.paises.104'},
    {value: EPaisesMod22.BURUNDI, name: 'modelo22.datasources.paises.108'},
    {value: EPaisesMod22.BIELORRUSSIA, name: 'modelo22.datasources.paises.112'},
    {value: EPaisesMod22.CAMBOJA, name: 'modelo22.datasources.paises.116'},
    {value: EPaisesMod22.CAMAROES, name: 'modelo22.datasources.paises.120'},
    {value: EPaisesMod22.CANADA, name: 'modelo22.datasources.paises.124'},
    {value: EPaisesMod22.CABO_VERDE, name: 'modelo22.datasources.paises.132'},
    {value: EPaisesMod22.ILHAS_CAIMAO, name: 'modelo22.datasources.paises.136'},
    {value: EPaisesMod22.CENTRO_AFRICANA_REPUBLICA, name: 'modelo22.datasources.paises.140'},
    {value: EPaisesMod22.SRI_LANKA, name: 'modelo22.datasources.paises.144'},
    {value: EPaisesMod22.CHADE, name: 'modelo22.datasources.paises.148'},
    {value: EPaisesMod22.CHILE, name: 'modelo22.datasources.paises.152'},
    {value: EPaisesMod22.CHINA, name: 'modelo22.datasources.paises.156'},
    {value: EPaisesMod22.TAIWAN, name: 'modelo22.datasources.paises.158'},
    {value: EPaisesMod22.ILHAS_NATAL, name: 'modelo22.datasources.paises.162'},
    {value: EPaisesMod22.ILHAS_COCOS_O_KEELING, name: 'modelo22.datasources.paises.166'},
    {value: EPaisesMod22.COLOMBIA, name: 'modelo22.datasources.paises.170'},
    {value: EPaisesMod22.COMORES, name: 'modelo22.datasources.paises.174'},
    {value: EPaisesMod22.MAYOTTE, name: 'modelo22.datasources.paises.175'},
    {value: EPaisesMod22.CONGO, name: 'modelo22.datasources.paises.178'},
    {value: EPaisesMod22.CONGO_REPUBLICA_DEMOCRATICA_DO, name: 'modelo22.datasources.paises.180'},
    {value: EPaisesMod22.ILHAS_COOK, name: 'modelo22.datasources.paises.184'},
    {value: EPaisesMod22.COSTA_RICA, name: 'modelo22.datasources.paises.188'},
    {value: EPaisesMod22.CROACIA, name: 'modelo22.datasources.paises.191'},
    {value: EPaisesMod22.CUBA, name: 'modelo22.datasources.paises.192'},
    {value: EPaisesMod22.CHIPRE, name: 'modelo22.datasources.paises.196'},
    {value: EPaisesMod22.REPUBLICA_CHECA, name: 'modelo22.datasources.paises.203'},
    {value: EPaisesMod22.BENIN, name: 'modelo22.datasources.paises.204'},
    {value: EPaisesMod22.DINAMARCA, name: 'modelo22.datasources.paises.208'},
    {value: EPaisesMod22.DOMINICA, name: 'modelo22.datasources.paises.212'},
    {value: EPaisesMod22.REPUBLICA_DOMINICANA, name: 'modelo22.datasources.paises.214'},
    {value: EPaisesMod22.EQUADOR, name: 'modelo22.datasources.paises.218'},
    {value: EPaisesMod22.EL_SALVADOR, name: 'modelo22.datasources.paises.222'},
    {value: EPaisesMod22.GUINE_EQUATORIAL, name: 'modelo22.datasources.paises.226'},
    {value: EPaisesMod22.ETIOPIA, name: 'modelo22.datasources.paises.231'},
    {value: EPaisesMod22.ERITREIA, name: 'modelo22.datasources.paises.232'},
    {value: EPaisesMod22.ESTONIA, name: 'modelo22.datasources.paises.233'},
    {value: EPaisesMod22.ILHAS_FAROE, name: 'modelo22.datasources.paises.234'},
    {value: EPaisesMod22.ILHAS_FALKLAND_MALVINAS, name: 'modelo22.datasources.paises.238'},
    {value: EPaisesMod22.GEORGIA_DO_SUL_E_ILHAS_SANDWICH, name: 'modelo22.datasources.paises.239'},
    {value: EPaisesMod22.ILHAS_FIJI, name: 'modelo22.datasources.paises.242'},
    {value: EPaisesMod22.FINLANDIA, name: 'modelo22.datasources.paises.246'},
    {value: EPaisesMod22.ALAND_ISLANDS, name: 'modelo22.datasources.paises.248'},
    {value: EPaisesMod22.FRANCA, name: 'modelo22.datasources.paises.250'},
    {value: EPaisesMod22.GUIANA_FRANCESA, name: 'modelo22.datasources.paises.254'},
    {value: EPaisesMod22.POLINESIA_FRANCESA, name: 'modelo22.datasources.paises.258'},
    {value: EPaisesMod22.TERRITORIOS_FRANCESES_DO_SUL, name: 'modelo22.datasources.paises.260'},
    {value: EPaisesMod22.JIBUTI, name: 'modelo22.datasources.paises.262'},
    {value: EPaisesMod22.GABAO, name: 'modelo22.datasources.paises.266'},
    {value: EPaisesMod22.GEORGIA, name: 'modelo22.datasources.paises.268'},
    {value: EPaisesMod22.GAMBIA, name: 'modelo22.datasources.paises.270'},
    {value: EPaisesMod22.TERRITORIO_PALESTINIANO_OCUPADO, name: 'modelo22.datasources.paises.275'},
    {value: EPaisesMod22.ALEMANHA, name: 'modelo22.datasources.paises.276'},
    {value: EPaisesMod22.REPUBLICA_DEMOCRATICA_ALEM, name: 'modelo22.datasources.paises.278'},
    {value: EPaisesMod22.GANA, name: 'modelo22.datasources.paises.288'},
    {value: EPaisesMod22.GIBRALTAR, name: 'modelo22.datasources.paises.292'},
    {value: EPaisesMod22.KIRIBATI, name: 'modelo22.datasources.paises.296'},
    {value: EPaisesMod22.GRECIA, name: 'modelo22.datasources.paises.300'},
    {value: EPaisesMod22.GRONELANDIA, name: 'modelo22.datasources.paises.304'},
    {value: EPaisesMod22.GRANADA, name: 'modelo22.datasources.paises.308'},
    {value: EPaisesMod22.GUADALUPE, name: 'modelo22.datasources.paises.312'},
    {value: EPaisesMod22.GUAM, name: 'modelo22.datasources.paises.316'},
    {value: EPaisesMod22.GUATEMALA, name: 'modelo22.datasources.paises.320'},
    {value: EPaisesMod22.GUINE, name: 'modelo22.datasources.paises.324'},
    {value: EPaisesMod22.GUIANA, name: 'modelo22.datasources.paises.328'},
    {value: EPaisesMod22.HAITI, name: 'modelo22.datasources.paises.332'},
    {value: EPaisesMod22.ILHAS_HEARD_E_ILHAS_MCDONALD, name: 'modelo22.datasources.paises.334'},
    {value: EPaisesMod22.SANTA_SE_CIDADE_ESTADO_DO_VATICANO, name: 'modelo22.datasources.paises.336'},
    {value: EPaisesMod22.HONDURAS, name: 'modelo22.datasources.paises.340'},
    {value: EPaisesMod22.HONG_KONG, name: 'modelo22.datasources.paises.344'},
    {value: EPaisesMod22.HUNGRIA, name: 'modelo22.datasources.paises.348'},
    {value: EPaisesMod22.ISLANDIA, name: 'modelo22.datasources.paises.352'},
    {value: EPaisesMod22.INDIA, name: 'modelo22.datasources.paises.356'},
    {value: EPaisesMod22.INDONESIA, name: 'modelo22.datasources.paises.360'},
    {value: EPaisesMod22.IRAO_REPUBLICA_ISLAMICA, name: 'modelo22.datasources.paises.364'},
    {value: EPaisesMod22.IRAQUE, name: 'modelo22.datasources.paises.368'},
    {value: EPaisesMod22.IRLANDA, name: 'modelo22.datasources.paises.372'},
    {value: EPaisesMod22.ISRAEL, name: 'modelo22.datasources.paises.376'},
    {value: EPaisesMod22.ITALIA, name: 'modelo22.datasources.paises.380'},
    {value: EPaisesMod22.COSTA_DO_MARFIM, name: 'modelo22.datasources.paises.384'},
    {value: EPaisesMod22.JAMAICA, name: 'modelo22.datasources.paises.388'},
    {value: EPaisesMod22.JAPAO, name: 'modelo22.datasources.paises.392'},
    {value: EPaisesMod22.CAZAQUISTAO, name: 'modelo22.datasources.paises.398'},
    {value: EPaisesMod22.JORDANIA, name: 'modelo22.datasources.paises.400'},
    {value: EPaisesMod22.KENYA, name: 'modelo22.datasources.paises.404'},
    {value: EPaisesMod22.COREIA_REPUBLICA_POPULAR_DEMOCRATICA_DA, name: 'modelo22.datasources.paises.408'},
    {value: EPaisesMod22.COREIA_REPUBLICA_DA, name: 'modelo22.datasources.paises.410'},
    {value: EPaisesMod22.KUWAIT, name: 'modelo22.datasources.paises.414'},
    {value: EPaisesMod22.QUIRGUIZISTAO, name: 'modelo22.datasources.paises.417'},
    {value: EPaisesMod22.LAOS_REPUBLICA_POPULAR_DEMOCRATICA_DO, name: 'modelo22.datasources.paises.418'},
    {value: EPaisesMod22.LIBANO, name: 'modelo22.datasources.paises.422'},
    {value: EPaisesMod22.LESOTO, name: 'modelo22.datasources.paises.426'},
    {value: EPaisesMod22.LETONIA, name: 'modelo22.datasources.paises.428'},
    {value: EPaisesMod22.LIBERIA, name: 'modelo22.datasources.paises.430'},
    {value: EPaisesMod22.LIBIA_JAMAHIRIYA_ARABE_DA, name: 'modelo22.datasources.paises.434'},
    {value: EPaisesMod22.LIECHTENSTEIN, name: 'modelo22.datasources.paises.438'},
    {value: EPaisesMod22.LITUANIA, name: 'modelo22.datasources.paises.440'},
    {value: EPaisesMod22.LUXEMBURGO, name: 'modelo22.datasources.paises.442'},
    {value: EPaisesMod22.MACAU, name: 'modelo22.datasources.paises.446'},
    {value: EPaisesMod22.MADAGASCAR, name: 'modelo22.datasources.paises.450'},
    {value: EPaisesMod22.MALAWI, name: 'modelo22.datasources.paises.454'},
    {value: EPaisesMod22.MALASIA, name: 'modelo22.datasources.paises.458'},
    {value: EPaisesMod22.MALDIVAS, name: 'modelo22.datasources.paises.462'},
    {value: EPaisesMod22.MALI, name: 'modelo22.datasources.paises.466'},
    {value: EPaisesMod22.MALTA, name: 'modelo22.datasources.paises.470'},
    {value: EPaisesMod22.MARTINICA, name: 'modelo22.datasources.paises.474'},
    {value: EPaisesMod22.MAURITANIA, name: 'modelo22.datasources.paises.478'},
    {value: EPaisesMod22.MAURICIAS, name: 'modelo22.datasources.paises.480'},
    {value: EPaisesMod22.MEXICO, name: 'modelo22.datasources.paises.484'},
    {value: EPaisesMod22.MONACO, name: 'modelo22.datasources.paises.492'},
    {value: EPaisesMod22.MONGOLIA, name: 'modelo22.datasources.paises.496'},
    {value: EPaisesMod22.MOLDOVA_REPUBLICA_DE, name: 'modelo22.datasources.paises.498'},
    {value: EPaisesMod22.MONTENEGRO, name: 'modelo22.datasources.paises.499'},
    {value: EPaisesMod22.MONSERRATE, name: 'modelo22.datasources.paises.500'},
    {value: EPaisesMod22.MARROCOS, name: 'modelo22.datasources.paises.504'},
    {value: EPaisesMod22.MOCAMBIQUE, name: 'modelo22.datasources.paises.508'},
    {value: EPaisesMod22.OMA, name: 'modelo22.datasources.paises.512'},
    {value: EPaisesMod22.NAMIBIA, name: 'modelo22.datasources.paises.516'},
    {value: EPaisesMod22.NAURU, name: 'modelo22.datasources.paises.520'},
    {value: EPaisesMod22.NEPAL, name: 'modelo22.datasources.paises.524'},
    {value: EPaisesMod22.PAISES_BAIXOS, name: 'modelo22.datasources.paises.528'},
    {value: EPaisesMod22.ANTILHAS_HOLANDESAS, name: 'modelo22.datasources.paises.530'},
    {value: EPaisesMod22.CURA_AO, name: 'modelo22.datasources.paises.531'},
    {value: EPaisesMod22.ARUBA, name: 'modelo22.datasources.paises.533'},
    {value: EPaisesMod22.SINT_MAARTEN, name: 'modelo22.datasources.paises.534'},
    {value: EPaisesMod22.BONAIRE_SAINT_EUSTATIUS_E_SABA, name: 'modelo22.datasources.paises.535'},
    {value: EPaisesMod22.NOVA_CALEDONIA, name: 'modelo22.datasources.paises.540'},
    {value: EPaisesMod22.VANUATU, name: 'modelo22.datasources.paises.548'},
    {value: EPaisesMod22.NOVA_ZELANDIA, name: 'modelo22.datasources.paises.554'},
    {value: EPaisesMod22.NICARAGUA, name: 'modelo22.datasources.paises.558'},
    {value: EPaisesMod22.NIGER, name: 'modelo22.datasources.paises.562'},
    {value: EPaisesMod22.NIGERIA, name: 'modelo22.datasources.paises.566'},
    {value: EPaisesMod22.NIUE, name: 'modelo22.datasources.paises.570'},
    {value: EPaisesMod22.ILHAS_NORFOLK, name: 'modelo22.datasources.paises.574'},
    {value: EPaisesMod22.NORUEGA, name: 'modelo22.datasources.paises.578'},
    {value: EPaisesMod22.ILHAS_MARIANAS_DO_NORTE, name: 'modelo22.datasources.paises.580'},
    {value: EPaisesMod22.ILHAS_MENORES_DISTANTES_DOS_ESTADOS_UNIDO, name: 'modelo22.datasources.paises.581'},
    {value: EPaisesMod22.MICRONESIA_ESTADOS_FEDERADOS_DA, name: 'modelo22.datasources.paises.583'},
    {value: EPaisesMod22.ILHAS_MARSHALL, name: 'modelo22.datasources.paises.584'},
    {value: EPaisesMod22.PALAU, name: 'modelo22.datasources.paises.585'},
    {value: EPaisesMod22.PAQUISTAO, name: 'modelo22.datasources.paises.586'},
    {value: EPaisesMod22.PANAMA, name: 'modelo22.datasources.paises.591'},
    {value: EPaisesMod22.PAPUASIA_NOVA_GUINE, name: 'modelo22.datasources.paises.598'},
    {value: EPaisesMod22.PARAGUAI, name: 'modelo22.datasources.paises.600'},
    {value: EPaisesMod22.PERU, name: 'modelo22.datasources.paises.604'},
    {value: EPaisesMod22.FILIPINAS, name: 'modelo22.datasources.paises.608'},
    {value: EPaisesMod22.PITCAIRN, name: 'modelo22.datasources.paises.612'},
    {value: EPaisesMod22.POLONIA, name: 'modelo22.datasources.paises.616'},
    {value: EPaisesMod22.PORTUGAL, name: 'modelo22.datasources.paises.620'},
    {value: EPaisesMod22.GUINE_BISSAU, name: 'modelo22.datasources.paises.624'},
    {value: EPaisesMod22.TIMOR_LESTE, name: 'modelo22.datasources.paises.626'},
    {value: EPaisesMod22.PORTO_RICO, name: 'modelo22.datasources.paises.630'},
    {value: EPaisesMod22.CATAR, name: 'modelo22.datasources.paises.634'},
    {value: EPaisesMod22.REUNIAO, name: 'modelo22.datasources.paises.638'},
    {value: EPaisesMod22.ROMENIA, name: 'modelo22.datasources.paises.642'},
    {value: EPaisesMod22.RUSSIA_FEDERACAO_DA, name: 'modelo22.datasources.paises.643'},
    {value: EPaisesMod22.RUANDA, name: 'modelo22.datasources.paises.646'},
    {value: EPaisesMod22.S_O_BARTOLOMEU, name: 'modelo22.datasources.paises.652'},
    {value: EPaisesMod22.SANTA_HELENA, name: 'modelo22.datasources.paises.654'},
    {value: EPaisesMod22.SAO_CRISTOVAO_E_NEVIS, name: 'modelo22.datasources.paises.659'},
    {value: EPaisesMod22.ANGUILA, name: 'modelo22.datasources.paises.660'},
    {value: EPaisesMod22.SANTA_LUCIA, name: 'modelo22.datasources.paises.662'},
    {value: EPaisesMod22.SAO_PEDRO_E_MIQUELON, name: 'modelo22.datasources.paises.666'},
    {value: EPaisesMod22.SAO_VICENTE_E_GRANADINAS, name: 'modelo22.datasources.paises.670'},
    {value: EPaisesMod22.SAO_MARINO, name: 'modelo22.datasources.paises.674'},
    {value: EPaisesMod22.SAO_TOME_E_PRINCIPE, name: 'modelo22.datasources.paises.678'},
    {value: EPaisesMod22.ARABIA_SAUDITA, name: 'modelo22.datasources.paises.682'},
    {value: EPaisesMod22.SENEGAL, name: 'modelo22.datasources.paises.686'},
    {value: EPaisesMod22.SERVIA, name: 'modelo22.datasources.paises.688'},
    {value: EPaisesMod22.SEYCHELLES, name: 'modelo22.datasources.paises.690'},
    {value: EPaisesMod22.SERRA_LEOA, name: 'modelo22.datasources.paises.694'},
    {value: EPaisesMod22.SINGAPURA, name: 'modelo22.datasources.paises.702'},
    {value: EPaisesMod22.ESLOVACA_REPUBLICA, name: 'modelo22.datasources.paises.703'},
    {value: EPaisesMod22.VIETNAME, name: 'modelo22.datasources.paises.704'},
    {value: EPaisesMod22.ESLOVENIA, name: 'modelo22.datasources.paises.705'},
    {value: EPaisesMod22.SOMALIA, name: 'modelo22.datasources.paises.706'},
    {value: EPaisesMod22.AFRICA_DO_SUL, name: 'modelo22.datasources.paises.710'},
    {value: EPaisesMod22.ZIMBABWE, name: 'modelo22.datasources.paises.716'},
    {value: EPaisesMod22.ESPANHA, name: 'modelo22.datasources.paises.724'},
    {value: EPaisesMod22.SOUTH_SUDAN, name: 'modelo22.datasources.paises.728'},
    {value: EPaisesMod22.SUDAN, name: 'modelo22.datasources.paises.729'},
    {value: EPaisesMod22.SARA_OCIDENTAL, name: 'modelo22.datasources.paises.732'},
    {value: EPaisesMod22.SUDAO, name: 'modelo22.datasources.paises.736'},
    {value: EPaisesMod22.SURINAME, name: 'modelo22.datasources.paises.740'},
    {value: EPaisesMod22.SVALBARD_E_A_ILHA_DE_JAN_MAYEN, name: 'modelo22.datasources.paises.744'},
    {value: EPaisesMod22.SUAZILANDIA, name: 'modelo22.datasources.paises.748'},
    {value: EPaisesMod22.SUECIA, name: 'modelo22.datasources.paises.752'},
    {value: EPaisesMod22.SUICA, name: 'modelo22.datasources.paises.756'},
    {value: EPaisesMod22.SIRIA_REPUBLICA_ARABE_DA, name: 'modelo22.datasources.paises.760'},
    {value: EPaisesMod22.TAJIQUISTAO, name: 'modelo22.datasources.paises.762'},
    {value: EPaisesMod22.TAILANDIA, name: 'modelo22.datasources.paises.764'},
    {value: EPaisesMod22.TOGO, name: 'modelo22.datasources.paises.768'},
    {value: EPaisesMod22.TOKELAU, name: 'modelo22.datasources.paises.772'},
    {value: EPaisesMod22.TONGA, name: 'modelo22.datasources.paises.776'},
    {value: EPaisesMod22.TRINDADE_E_TOBAGO, name: 'modelo22.datasources.paises.780'},
    {value: EPaisesMod22.EMIRATOS_ARABES_UNIDOS, name: 'modelo22.datasources.paises.784'},
    {value: EPaisesMod22.TUNISIA, name: 'modelo22.datasources.paises.788'},
    {value: EPaisesMod22.TURQUIA, name: 'modelo22.datasources.paises.792'},
    {value: EPaisesMod22.TURQUEMENISTAO, name: 'modelo22.datasources.paises.795'},
    {value: EPaisesMod22.TURCOS_E_CAICOS_ILHAS, name: 'modelo22.datasources.paises.796'},
    {value: EPaisesMod22.TUVALU, name: 'modelo22.datasources.paises.798'},
    {value: EPaisesMod22.UGANDA, name: 'modelo22.datasources.paises.800'},
    {value: EPaisesMod22.UCRANIA, name: 'modelo22.datasources.paises.804'},
    {value: EPaisesMod22.MACEDONIA_ANTIGA_REPUBLICA_JUGOSLAVA_DA, name: 'modelo22.datasources.paises.807'},
    {value: EPaisesMod22.UNI_O_SOVIETICA, name: 'modelo22.datasources.paises.810'},
    {value: EPaisesMod22.EGIPTO, name: 'modelo22.datasources.paises.818'},
    {value: EPaisesMod22.REINO_UNIDO, name: 'modelo22.datasources.paises.826'},
    {value: EPaisesMod22.ILHAS_DO_CANAL, name: 'modelo22.datasources.paises.830'},
    {value: EPaisesMod22.GUERNSEY, name: 'modelo22.datasources.paises.831'},
    {value: EPaisesMod22.JERSEY, name: 'modelo22.datasources.paises.832'},
    {value: EPaisesMod22.ILHA_DE_MAN, name: 'modelo22.datasources.paises.833'},
    {value: EPaisesMod22.TANZANIA_REPUBLICA_UNIDA_DA, name: 'modelo22.datasources.paises.834'},
    {value: EPaisesMod22.ESTADOS_UNIDOS, name: 'modelo22.datasources.paises.840'},
    {value: EPaisesMod22.ILHAS_VIRGENS_ESTADOS_UNIDOS, name: 'modelo22.datasources.paises.850'},
    {value: EPaisesMod22.BURKINA_FASO, name: 'modelo22.datasources.paises.854'},
    {value: EPaisesMod22.URUGUAI, name: 'modelo22.datasources.paises.858'},
    {value: EPaisesMod22.USBEQUISTAO, name: 'modelo22.datasources.paises.860'},
    {value: EPaisesMod22.VENEZUELA, name: 'modelo22.datasources.paises.862'},
    {value: EPaisesMod22.WALLIS_E_FUTUNA_ILHAS, name: 'modelo22.datasources.paises.876'},
    {value: EPaisesMod22.SAMOA, name: 'modelo22.datasources.paises.882'},
    {value: EPaisesMod22.IEMEN, name: 'modelo22.datasources.paises.887'},
    {value: EPaisesMod22.JUGOSL_VIA, name: 'modelo22.datasources.paises.891'},
    {value: EPaisesMod22.SERVIA_E_MONTENEGRO, name: 'modelo22.datasources.paises.892'},
    {value: EPaisesMod22.ZAMBIA, name: 'modelo22.datasources.paises.894'},
    {value: EPaisesMod22.OLIVEN_A, name: 'modelo22.datasources.paises.900'},
    {value: EPaisesMod22.REPUBLICA_DO_KOSOVO, name: 'modelo22.datasources.paises.901'},
    {value: EPaisesMod22.ALDERNE, name: 'modelo22.datasources.paises.956'}
  ]
});
