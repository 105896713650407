import {Injectable, Injector} from '@angular/core';
import type {IPlDynamicVisualsReferenceOptions, TPlDynamicVisualsStackOpenContent} from '../dynamicvisuals.stacks.interface';
import {PlDynamicVisualsReference} from './reference/dynamicvisuals.reference';
import {PlDynamicVisualsStackService} from './dynamicvisuals.stack.service';

@Injectable({
  providedIn: 'root'
})
export class PlDynamicVisualsService {
  constructor(protected readonly _injector: Injector, protected readonly _stack: PlDynamicVisualsStackService) {}

  public openVanilla<T = void>(content: TPlDynamicVisualsStackOpenContent, options?: IPlDynamicVisualsReferenceOptions): PlDynamicVisualsReference<T> {
    return this._stack.open<T>(this._injector, content, options);
  }

  public open<T = void>(content: TPlDynamicVisualsStackOpenContent, options?: IPlDynamicVisualsReferenceOptions): Promise<T> {
    return this.openVanilla<T>(content, options).result;
  }

  public dismissAll(reason?: any): void {
    this._stack.dismissAll(reason);
  }

  public hasOpenReferences(): boolean {
    return this._stack.hasOpenReferences();
  }
}
