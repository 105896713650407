import {Subject, Subscription} from 'rxjs';
import {Component, ContentChild, ContentChildren, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, TemplateRef} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {AbstractControl, FormGroupDirective, UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {
  copy,
  IPlCompsServiceConfig,
  IPlCompsServiceConfigEditForm,
  IPlFormDefinition,
  IPlFormFieldValueChangedEvent,
  IPlFormTemplate,
  isArray,
  isBoolean,
  isFunction,
  isObject,
  isString,
  isUndefined,
  Logger,
  PlAlertService,
  PlCompsService,
  PlEditFormComponent,
  TOrientation
} from 'pl-comps-angular';
import {CGExceptionService} from '../../exceptions/exceptions.service';
import {DEFAULT_FORM_ORIENTATION, isDev} from '../../../../config/constants';
import {EEntityStateDetailType} from '../../../../common/utils/entity.state.utils';
import {EntityDetailContentDirective} from './entity.detail.content.directive';
import {EntityDetailSuccessDirective} from './entity.detail.success.directive';
import {EntityRegistryService} from '../entity.registry.service';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {escapeSelector} from '../../../../common/utils/utils';
import {IApiRequestConfig, IApiRequestConfigWithBody, TServiceResponse} from '../../../services/api/api.service.interface';
import {IEntity, IEntityDetailServiceMethodsOverride, IEntityEvaluationMethods, IEntityMetadataField} from '../entity.definition.interface';
import {IEntityDetailCallback, IEntityDetailContentContext, IEntityDetailMessages, IEntityDetailUpdatableField} from './entity.detail.component.interface';
import {IEntityService, TEntityServiceRequestDataWithBody} from '../../../services/entity/entity.service.interface';
import {EntityDetailPartialContentDirective} from './entity.detail.partial.content.directive';

const ALERT_AUTO_CLOSE_DELAY = 10000; // 5 seconds

@Component({
  selector: 'entity-detail',
  templateUrl: './entity.detail.component.html',
  exportAs: 'entityDetail'
})
export class EntityDetailComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public entityName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public model: any;
  @Input() public type: EEntityStateDetailType;
  @Input() public maintenanceMode: boolean;
  @Input() public callback: IEntityDetailCallback;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public service: IEntityService<any>;
  @Input() public formInstance: FormGroupDirective | UntypedFormGroup;
  @Input() public showMessagesSuccess: boolean;
  @Input() public showMessagesError: boolean;
  @Input() public formOrientation: TOrientation;
  @Input() public serviceMethodsOverride: IEntityDetailServiceMethodsOverride;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public evaluationMethods: IEntityEvaluationMethods<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly modelChange: EventEmitter<any>;
  @Output() public readonly evtUpdate: EventEmitter<EEntityStateDetailType>;
  @ContentChild(EntityDetailContentDirective, {read: TemplateRef}) public templateEntityDetailContent: TemplateRef<IEntityDetailContentContext>;
  @ContentChild(EntityDetailPartialContentDirective, {read: TemplateRef}) public templateEntityDetailPartialContent: TemplateRef<IEntityDetailContentContext>;
  @ContentChild(EntityDetailSuccessDirective, {read: TemplateRef}) public templateEntityDetailSuccess: TemplateRef<EntityDetailSuccessDirective>;

  public readonly alertAutoCloseDelay: number;
  public definition: IPlFormDefinition;
  public template: IPlFormTemplate;
  public prettyId: string | number;
  public messages: IEntityDetailMessages;
  public readonly: boolean;
  public watchResult: Promise<unknown>;
  public formGroupDirective: FormGroupDirective;
  public formGroup: UntypedFormGroup;

  private readonly _fields: Array<IEntityDetailUpdatableField>;
  private readonly _subjectWatchers: Subject<IPlFormFieldValueChangedEvent<unknown>>;
  private readonly _formsWatchers: Set<Subscription>;
  private readonly _subscriptionConfig: Subscription;
  private _configForm: IPlCompsServiceConfigEditForm;
  private _entity: IEntity;
  private _modelBack: unknown;

  constructor(
    private readonly _injector: Injector,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _entityRegistryService: EntityRegistryService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _plCompsService: PlCompsService,
    private readonly _translateService: TranslateService,
    private readonly _logger: Logger
  ) {
    this.modelChange = new EventEmitter<unknown>();
    this.evtUpdate = new EventEmitter<EEntityStateDetailType>();
    this.alertAutoCloseDelay = ALERT_AUTO_CLOSE_DELAY;
    this._fields = [];
    this._subjectWatchers = new Subject<IPlFormFieldValueChangedEvent<unknown>>();
    this._formsWatchers = new Set<Subscription>();
    this._subscriptionConfig = this._plCompsService.config().subscribe((config: IPlCompsServiceConfig) => {
      this._configForm = config.plEditForm;
    });
  }

  public ngOnInit(): void {
    this.messages = {
      error: [],
      success: false,
      errorMsg: `${this.entityName}.error`,
      successMsg: `${this.entityName}.saved`
    };

    this._handleChanges();
    this._resetForm();

    if (this.service) {
      this._entity = this.service.entity;
      this.entityName = this._entity.name;
    } else {
      if (!this.entityName) {
        throw new Error('entityName or service is empty');
      }
      this._entity = this._entityRegistryService.getEntity(this.entityName);
      this.service = this._entityServiceBuilder.build(this._entity);
    }

    this.service.invokeEntity(this._injector);
    this.service.runObservers(this._subjectWatchers, this._fnModelCallback, this._fnWatchersCallback);

    this.type = this.type || EEntityStateDetailType.DETAIL;
    this.readonly = this.type === EEntityStateDetailType.DETAIL;

    if (isFunction(this.service.onInit)) {
      this.service.onInit(this.model, this.type);
    }

    if (this.model) {
      this._evaluatePrettyId(this.model);
    }

    this._updateType();
  }

  public ngOnChanges({model, callback, formInstance, showMessagesSuccess, showMessagesError, formOrientation}: SimpleChanges): void {
    if (model && !model.isFirstChange()) {
      this._evaluatePrettyId(model.currentValue);
    }
    if (callback && !callback.isFirstChange()) {
      this._changedCallback(callback.currentValue);
    }
    if (formInstance && !formInstance.isFirstChange()) {
      this._changedFormInstance(formInstance.currentValue);
    }
    if (showMessagesSuccess && !showMessagesSuccess.isFirstChange()) {
      this._changedShowMessagesSuccess(showMessagesSuccess.currentValue);
    }
    if (showMessagesError && !showMessagesError.isFirstChange()) {
      this._changedShowMessagesError(showMessagesError.currentValue);
    }
    if (formOrientation && !formOrientation.isFirstChange()) {
      this._changedFormOrientation(formOrientation.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._clearFormsWatchers();
    this._subscriptionConfig.unsubscribe();
    if (this.service) {
      this.service.clearObservers();
    }
  }

  public submit(): Promise<unknown> {
    return this._save();
  }

  public addField(field: IEntityDetailUpdatableField): void {
    this._fields.push(field);
  }

  public fieldValueChanged(event: IPlFormFieldValueChangedEvent<unknown>): void {
    if (this.type !== EEntityStateDetailType.DETAIL) {
      this._subjectWatchers.next(event);
    }
  }

  @ContentChildren(PlEditFormComponent, {descendants: true})
  public set templateForms(list: QueryList<PlEditFormComponent>) {
    this._clearFormsWatchers();
    for (const editFormComponent of list) {
      this._formsWatchers.add(
        editFormComponent.evtFieldValueChanged.subscribe((event: IPlFormFieldValueChangedEvent<unknown>) => {
          this.fieldValueChanged(event);
        })
      );
    }
  }

  private _handleChanges(): void {
    this._changedCallback();
    this._changedFormInstance();
    this._changedShowMessagesSuccess();
    this._changedShowMessagesError();
    this._changedFormOrientation();
  }

  private _changedCallback(value: EntityDetailComponent['callback'] = this.callback): void {
    if (isObject(value)) {
      value.cancel = () => {
        this._cancel();
      };
      value.delete = (config?: IApiRequestConfig) => this._delete(config);
      value.duplicate = () => this._duplicate();
      value.edit = () => {
        this._edit();
      };
      value.isEditing = () => this._isEditing();
      value.messages = () => this.messages;
      value.new = () => {
        this._new();
      };
      value.resetErrors = () => {
        this._resetErrors();
      };
      value.save = (config?: IApiRequestConfigWithBody<unknown>) => this._save(config);
      value.setFieldError = (field) => {
        this._setFieldError(field);
      };
      value.update = (stateType: EEntityStateDetailType) => {
        this._update(stateType);
      };
    }
  }

  private _changedFormInstance(value: EntityDetailComponent['formInstance'] = this.formInstance): void {
    if (value instanceof FormGroupDirective) {
      this.formGroupDirective = value;
      this.formGroup = value.form;
    } else if (value instanceof UntypedFormGroup) {
      this.formGroup = value;
    } else {
      this.formGroupDirective = undefined;
      this.formGroup = undefined;
    }
  }

  private _changedShowMessagesSuccess(value: boolean = this.showMessagesSuccess): void {
    this.showMessagesSuccess = isBoolean(value) ? value : true;
  }

  private _changedShowMessagesError(value: boolean = this.showMessagesError): void {
    this.showMessagesError = isBoolean(value) ? value : true;
  }

  private _changedFormOrientation(value: TOrientation = this.formOrientation): void {
    this.formOrientation = value || this._configForm?.orientation || DEFAULT_FORM_ORIENTATION;
  }

  private _resetForm(): void {
    if (this.formGroupDirective) {
      this.formGroupDirective.resetForm();
    }
    if (this.formGroup) {
      this.formGroup.markAsPristine();
      this.formGroup.markAsUntouched();
    }
  }

  private _resetErrors(): void {
    this.messages.error = [];
    this.messages.success = false;
  }

  private async _save(config?: IApiRequestConfigWithBody<unknown>): Promise<unknown> {
    this._resetErrors();

    if (this.formGroupDirective && !this.formGroupDirective.submitted) {
      this.formGroupDirective.onSubmit(new Event('submit'));
    }

    if (this.formGroup?.invalid) {
      this.messages.error.push(this._translateService.instant('entity.state.error'));
      if (isDev()) {
        const invalidControls: Array<[string, AbstractControl]> = Object.keys(this.formGroup.controls)
          .filter((key: string) => {
            return this.formGroup.controls[key].invalid;
          })
          .map<[string, AbstractControl]>((controlName: string) => {
            return [controlName, this.formGroup.controls[controlName]];
          });
        this._logger.error(invalidControls);
      }
      throw new Error(this.messages.error.join('\n'));
    }

    this.prettyId = '';

    const requestConfig: TEntityServiceRequestDataWithBody<unknown> = {
      body: this.model,
      reportExceptions: false,
      ...config
    };
    requestConfig.id = this._evaluateModelId(this.model);

    let promise: TServiceResponse<unknown>;
    if (isObject(this.serviceMethodsOverride)) {
      if (this.type === EEntityStateDetailType.NEW) {
        if (isString(this.serviceMethodsOverride.post) && isFunction(this.service[this.serviceMethodsOverride.post])) {
          promise = this.service[this.serviceMethodsOverride.post](requestConfig);
        } else if (isFunction(this.serviceMethodsOverride.post)) {
          promise = this.serviceMethodsOverride.post(requestConfig);
        }
      } else if (isString(this.serviceMethodsOverride.put) && isFunction(this.service[this.serviceMethodsOverride.put])) {
        promise = this.service[this.serviceMethodsOverride.put](requestConfig);
      } else if (isFunction(this.serviceMethodsOverride.put)) {
        promise = this.serviceMethodsOverride.put(requestConfig);
      }
    }
    if (!promise) {
      promise = this.type === EEntityStateDetailType.NEW ? this.service.post(requestConfig) : this.service.put(requestConfig);
    }

    try {
      const response: HttpResponse<unknown> = await promise;
      this.messages.success = true;
      if (response.body) {
        this.model = response.body;
        this.modelChange.emit(this.model);
        const id: string | number = this._evaluateModelId(this.model);
        this._entity.setId(this.model, id);
        this._evaluatePrettyId(this.model, id);
      }
      this._modelBack = copy(this.model);
      if (this._entity.actions.detail && !this.maintenanceMode) {
        this._update(EEntityStateDetailType.DETAIL);
        this.readonly = true;
        this._cancel();
      } else {
        this._plAlertService.success(this._translateService.instant(this.messages.successMsg, {id: this.prettyId}));
      }
      return this.model;
    } catch (error: unknown | Error | HttpErrorResponse) {
      if (error instanceof HttpErrorResponse) {
        const exception = this._cgExceptionService.get(error);
        if (exception?.fields && exception.fields.length > 0) {
          for (const field of exception.fields) {
            this._setFieldError(field);
          }
        } else {
          this._setFieldError({fieldname: 'error', message: exception.message});
        }
      } else {
        this._logger.error(error);
      }
      throw error;
    }
  }

  private _setFieldError(field): void {
    if (isArray(field)) {
      for (const element of field) {
        this._setFieldError(element);
      }
      return;
    }

    const fielddef: IEntityMetadataField<unknown> = this._entity.getFieldDef(field.fieldname);
    if (!fielddef) {
      this.messages.error.push(field.message);
      return;
    }

    const fieldName: string = fielddef.name;
    const elem = $(escapeSelector(`#${fieldName}`));
    const name = elem.attr('name');
    if (isUndefined(name)) {
      this.messages.error.push(field.message);
    }
  }

  private _new(): void {
    this._update(EEntityStateDetailType.NEW);
  }

  private _edit(): void {
    if (this.type === EEntityStateDetailType.DETAIL) {
      this._update(EEntityStateDetailType.EDIT);
    }
  }

  private _updateType(): void {
    if (this.type === EEntityStateDetailType.NEW) {
      this.definition = this._entity.new.definition;
      this.template = this._entity.new.template;
      this.readonly = false;
    }
    if (this.type === EEntityStateDetailType.DETAIL) {
      this.definition = this._entity.detail.definition;
      this.template = this._entity.detail.template;
      this.readonly = true;
    }
    if (this.type === EEntityStateDetailType.EDIT) {
      this._modelBack = copy(this.model);
      this.definition = this._entity.edit.definition;
      this.template = this._entity.edit.template;
      this.readonly = false;
    }
    this.formOrientation = this.template?.orientation || this.formOrientation;
  }

  private _update(stateType: EEntityStateDetailType): void {
    this.type = stateType;
    this._updateType();
    this.evtUpdate.emit(this.type);
  }

  private _cancel(): void {
    this._resetForm();
    if (this.type === EEntityStateDetailType.EDIT) {
      this.model = copy(this._modelBack);
      this.modelChange.emit(this.model);
      this._update(EEntityStateDetailType.DETAIL);
    }
  }

  private _delete(config?: TEntityServiceRequestDataWithBody<unknown>): TServiceResponse<unknown> {
    if (!isObject(config)) {
      config = {};
    }
    config.id = this._evaluateModelId(this.model);
    let promise: TServiceResponse<unknown>;
    if (isObject(this.serviceMethodsOverride)) {
      if (isString(this.serviceMethodsOverride.delete) && isFunction(this.service[this.serviceMethodsOverride.delete])) {
        promise = this.service[this.serviceMethodsOverride.delete](config);
      } else if (isFunction(this.serviceMethodsOverride.delete)) {
        promise = this.serviceMethodsOverride.delete(config);
      }
    }
    if (!promise) {
      promise = this.service.delete(config);
    }
    return promise.then((response: HttpResponse<unknown>) => {
      const prettyId = this._entity.getSuccessMessageId(this.model) || config.id;
      this._plAlertService.success(this._translateService.instant(`${this.entityName}.deleted`, {id: prettyId}));
      return response;
    });
  }

  private _duplicate(): unknown {
    const model: object = copy(this.model);
    this._entity.deleteId(model);
    return model;
  }

  private _isEditing(): boolean {
    return this.type === EEntityStateDetailType.NEW || this.type === EEntityStateDetailType.EDIT;
  }

  private _watchersCallback(returnValue: unknown | Promise<unknown>): void {
    this.watchResult = Promise.resolve(returnValue).finally(() => {
      this._updateFields();
    });
  }

  private _updateFields(): void {
    for (const field of this._fields) {
      field.updateField(this.model);
    }
  }

  private _evaluateModelId(model: unknown): string | number {
    let id: string | number;
    if (isFunction(this.evaluationMethods?.evaluateId)) {
      id = this.evaluationMethods.evaluateId(model);
    } else {
      id = this._entity.getId(model);
    }
    return id;
  }

  private _evaluatePrettyId(model: unknown, id?: string | number): void {
    this.prettyId = this._entity.getSuccessMessageId(model) || id || this._evaluateModelId(model);
  }

  private _clearFormsWatchers(): void {
    if (this._formsWatchers.size) {
      for (const formsWatcher of this._formsWatchers) {
        formsWatcher.unsubscribe();
      }
      this._formsWatchers.clear();
    }
  }

  private readonly _fnModelCallback = (): unknown => this.model;

  private readonly _fnWatchersCallback = (returnValue: unknown): void => {
    this._watchersCallback(returnValue);
  };
}
