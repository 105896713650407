import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MapaSindicatosComponent} from './components/mapaSindicatos.module.component';

export const MODULE_PCA_RH_MAPA_SINDICATOS: IModuleDefinition = {
  name: 'pcaMapaSindicatos',
  state: {
    url: '/rh/mapa/sindicatos',
    component: MapaSindicatosComponent,
    data: {
      roles: [ROLE.PCA],
      pageTitle: 'global.menu.pcaMapaSindicatos'
    }
  }
};
