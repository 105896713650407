import {Component, Injector, Input} from '@angular/core';
import {IPlTableDefinition, IPlTableOptions} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonImportadorSaftDirectContaFormada} from '../../importadorSaftDirect.module.interface';

@Component({
  selector: 'importador-saft-direct-ver-conta-formada-modal',
  templateUrl: './importadorSaftDirect.verContaFormada.modal.component.html'
})
export class ImportadorSaftDirectVerContaFormadaModalComponent extends CGModalComponent<void> {
  @Input() public modalTitle: string;
  @Input() public source: Array<IJsonImportadorSaftDirectContaFormada>;

  public readonly tableDefinition: IPlTableDefinition;
  public readonly tableProperties: IPlTableOptions;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.tableDefinition = {
      fields: [
        {name: 'nContaClassificada', caption: 'importadorSaftDirect.fields.nContaClassificada'},
        {name: 'digitosMercado', caption: 'importadorSaftDirect.fields.contaFormadaDigitosMercado'},
        {name: 'digitosIVA', caption: 'importadorSaftDirect.fields.contaFormadaDigitosIVA'},
        {name: 'nContaFormada', caption: 'importadorSaftDirect.fields.nContaFormada'},
        {name: 'contaFormadaNome', caption: 'importadorSaftDirect.fields.contaFormadaNome'},
        {name: 'estado', caption: 'global.text.error'}
      ]
    };
    this.tableProperties = {perPage: 20, suppressEmptyLines: true};
  }

  public readonly fnGetTableSource: () => Array<IJsonImportadorSaftDirectContaFormada> = () => this.source;
}
