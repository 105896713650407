import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAtivos} from '../../interfaces/jsonAtivos.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {AtivosMudarContaService} from '../../services/ativosMudarConta.service';
import {IJsonAtivosAjusteDepAntigas} from './jsonAtivosAjusteDepAntigas.interface';

@Component({
  selector: 'modal-ativos-ajuste-dep-antigas',
  templateUrl: './ativosAjusteDepAntigas.modal.component.html'
})
export class AtivosAjusteDepAntigasModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public ativo: IJsonAtivos;
  public ajusteDepAntigas: IJsonAtivosAjusteDepAntigas;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosMudarContaService: AtivosMudarContaService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this._ativosMudarContaService.getIniciaValoresAjuste(this.ativo.codAtivo).then((response: HttpResponse<IJsonAtivosAjusteDepAntigas>) => {
      this.ajusteDepAntigas = response.body;
    });
  }

  public close(): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'ativosajustedepantigas.temcerteza').then(() => {
      return this._ativosMudarContaService.getRegistaAjustesDeDepreciacoesAcumuladasDeAnosAnteriores(this.ativo.codAtivo, this.ajusteDepAntigas.periodo).then(() => {
        this._plAlertService.success('ativosajustedepantigas.message.success');
        super.close();
      });
    });
  }
}
