import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlAnimationModule} from '../animation/animation.module';
import {PlEventsModule} from '../events/events.module';
import {PlPaginationModule} from '../pagination/pagination.module';
import {PlScrollingModule} from '../scrolling/scrolling.module';
import {PlSpinnerModule} from '../spinner/spinner.module';
import {PlTranslateModule} from '../translate/translate.module';

import {PlTableComponent} from './table.component';
import {PlTableHeaderActionsDirective} from './table.header.actions.directive';
import {PlTableItemActionsDirective} from './table.item.actions.directive';
import {PlTableItemDetailDirective} from './table.item.detail.directive';
import {PlTableNavigationDirective} from './table.navigation.directive';

export * from './table.interface';
export * from './table.component';
export * from './table.header.actions.directive';
export * from './table.item.actions.directive';
export * from './table.item.detail.directive';
export * from './table.navigation.directive.interface';
export * from './table.navigation.directive';

const DECLARATIONS = [
  PlTableComponent,
  PlTableHeaderActionsDirective,
  PlTableItemActionsDirective,
  PlTableItemDetailDirective,
  PlTableNavigationDirective
];

@NgModule({
  imports: [
    CommonModule,
    PlAnimationModule,
    PlEventsModule,
    PlPaginationModule,
    PlScrollingModule,
    PlSpinnerModule,
    PlTranslateModule
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlTableModule {
}
