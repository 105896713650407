import {IPlFilterPanelEvtFiltered, TValueOrPromise} from 'pl-comps-angular';
import {IApiQueryResponse, THttpQueryResponse} from '../../../services/api/api.service.interface';
import {IEntityServiceQueryRequestConfig, TEntityServiceSourceFunction} from '../../../services/entity/entity.service.interface';

export type TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => IEntityServiceQueryRequestConfig;

export type TEntityListAfterRequestFn<T, S = T> = (response: THttpQueryResponse<T>) => void | Array<S> | IApiQueryResponse<S> | Promise<void | Array<S> | IApiQueryResponse<S>>;

export type TEntityListOnFilterFn = (event: IPlFilterPanelEvtFiltered<string>) => TValueOrPromise<void | IPlFilterPanelEvtFiltered<string>>;

export interface IEntityListProperties<T extends object = object> {
  beforeRequest?: TEntityListBeforeRequestFn;
  afterRequest?: TEntityListAfterRequestFn<T>;
  loadFn?: TEntityServiceSourceFunction<T>;
}

export interface IEntityListTemplateContext<T extends object = object> {
  readonly $implicit: T;
}

export const TOOLBAR_GROUP_ID_ENTITY_LIST = 'entity-list-toolbar';
export const TOOLBAR_ENTITY_LIST_BTN_REFRESH = 'refresh';
export const TOOLBAR_ENTITY_LIST_BTN_FILTER = 'filter';
