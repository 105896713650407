import {isEqual} from 'lodash-es';
import {hasCustomToString, isArray, isArrayLike, isFunction, isObject, isUndefined, lowercase} from '../common/utilities/utilities';

export type TFilterPredicateFn<T> = (value: T, index: number, array: Array<T>) => boolean;

export type TFilterExpression<T> = string | object | TFilterPredicateFn<T>;

export type TFilterComparatorFn<T> = (actual: T, expected: T) => boolean;

export type TFilterComparator<T> = true | false | TFilterComparatorFn<T>;

/**
 * Selects a subset of items from `array` and returns it as a new array.
 *
 * @param array The source array.
 *
 * @param expression The predicate to be used for selecting items from `array`.
 *
 *   Can be one of:
 *
 *   - `string`: The string is used for matching against the contents of the `array`. All strings or
 *     objects with string properties in `array` that match this string will be returned. This also
 *     applies to nested object properties.
 *     The predicate can be negated by prefixing the string with `!`.
 *
 *   - `Object`: A pattern object can be used to filter specific properties on objects contained
 *     by `array`. For example `{name:"M", phone:"1"}` predicate will return an array of items
 *     which have property `name` containing "M" and property `phone` containing "1". A special
 *     property name (`$` by default) can be used (e.g. as in `{$: "text"}`) to accept a match
 *     against any property of the object or its nested object properties. That's equivalent to the
 *     simple substring match with a `string` as described above. The special property name can be
 *     overwritten, using the `anyPropertyKey` parameter.
 *     The predicate can be negated by prefixing the string with `!`.
 *     For example `{name: "!M"}` predicate will return an array of items which have property `name`
 *     not containing "M".
 *
 *     Note that a named property will match properties on the same level only, while the special
 *     `$` property will match properties on the same level or deeper. E.g. an array item like
 *     `{name: {first: 'John', last: 'Doe'}}` will **not** be matched by `{name: 'John'}`, but
 *     **will** be matched by `{$: 'John'}`.
 *
 *   - `function(value, index, array)`: A predicate function can be used to write arbitrary filters.
 *     The function is called for each element of the array, with the element, its index, and
 *     the entire array itself as arguments.
 *
 *     The final result is an array of those elements that the predicate returned true for.
 *
 * @param [comparator] Comparator which is used in
 *     determining if values retrieved using `expression` (when it is not a function) should be
 *     considered a match based on the expected value (from the filter expression) and actual
 *     value (from the object in the array).
 *
 *   Can be one of:
 *
 *   - `function(actual, expected)`:
 *     The function will be given the object value and the predicate value to compare and
 *     should return true if both values should be considered equal.
 *
 *   - `true`: A shorthand for `function(actual, expected) { return isEqual(actual, expected)}`.
 *     This is essentially strict comparison of expected and actual.
 *
 *   - `false`: A short hand for a function which will look for a substring match in a case
 *     insensitive way. Primitive values are converted to strings. Objects are not compared against
 *     primitives, unless they have a custom `toString` method (e.g. `Date` objects).
 *
 *
 *   Defaults to `false`.
 *
 * @param [anyPropertyKey] The special property name that matches against any property. By default `$`.
 */
export function cgcFilter<T extends object>(array: Array<T>, expression: TFilterExpression<T>, comparator?: TFilterComparator<T>, anyPropertyKey: string = '$'): Array<T> {
  if (!isArrayLike(array)) {
    // Implicit conversion `==` is intended
    // eslint-disable-next-line eqeqeq,no-eq-null
    if (array == null) {
      return array;
    }
    throw new TypeError(`Expected array but received: {${String(array)}}`);
  }
  const expressionType = getTypeForFilter(expression);
  let predicateFn: TFilterPredicateFn<T>;
  switch (expressionType) {
    case 'function':
      predicateFn = <TFilterPredicateFn<T>>expression;
      break;
    case 'boolean':
    case 'null':
    case 'number':
    case 'string':
      predicateFn = createPredicateFn(expression, comparator, anyPropertyKey, true);
      break;
    case 'object':
      predicateFn = createPredicateFn(expression, comparator, anyPropertyKey, false);
      break;
    default:
      return array;
  }
  return Array.prototype.filter.call(array, predicateFn);
}

function createPredicateFn<T extends object>(expression: TFilterExpression<T>, comparator: TFilterComparator<T>, anyPropertyKey: string, matchAgainstAnyProp: boolean): TFilterPredicateFn<T> {
  const shouldMatchPrimitives: boolean = isObject(expression) && anyPropertyKey in <object>expression;

  if (comparator === true) {
    comparator = isEqual;
  } else if (!isFunction(comparator)) {
    comparator = (actual: any, expected: any) => {
      if (isUndefined(actual)) {
        // No substring matching against `undefined`
        return false;
      }
      if (actual === null || expected === null) {
        // No substring matching against `null`; only match against `null`
        return actual === expected;
      }
      if (isObject(expected) || (isObject(actual) && !hasCustomToString(actual))) {
        // Should not compare primitives against objects, unless they have custom `toString` method
        return false;
      }

      actual = lowercase(actual);
      expected = lowercase(expected);
      return actual.indexOf(expected) !== -1;
    };
  }

  const predicateFn: TFilterPredicateFn<T> = (item: T): boolean => {
    if (shouldMatchPrimitives && !isObject(item)) {
      return deepCompare(item, expression[anyPropertyKey], comparator, anyPropertyKey, false);
    }
    return deepCompare(item, expression, comparator, anyPropertyKey, matchAgainstAnyProp);
  };

  return predicateFn;
}

function deepCompare<T extends object>(actual: any, expected: any, comparator: TFilterComparator<T>, anyPropertyKey: string, matchAgainstAnyProp: boolean, dontMatchWholeObject?: boolean): boolean {
  const actualType: string = getTypeForFilter(actual);
  const expectedType: string = getTypeForFilter(expected);

  if (expectedType === 'string' && (<string>expected).startsWith('!')) {
    return !deepCompare(actual, expected.substring(1), comparator, anyPropertyKey, matchAgainstAnyProp);
  }
  if (isArray(actual)) {
    return actual.some((item: any) => {
      return deepCompare(item, expected, comparator, anyPropertyKey, matchAgainstAnyProp);
    });
  }

  switch (actualType) {
    case 'object':
      if (matchAgainstAnyProp) {
        for (const key of Object.keys(actual)) {
          // Under certain, rare, circumstances, key may not be a string and `charAt` will be undefined
          if (key.charAt && !key.startsWith('$') && deepCompare(actual[key], expected, comparator, anyPropertyKey, true)) {
            return true;
          }
        }
        return dontMatchWholeObject ? false : deepCompare(actual, expected, comparator, anyPropertyKey, false);
      }
      if (expectedType === 'object') {
        for (const key of Object.keys(expected)) {
          const expectedVal = expected[key];
          if (isFunction(expectedVal) || isUndefined(expectedVal)) {
            continue;
          }

          const matchAnyProperty = key === anyPropertyKey;
          const actualVal = matchAnyProperty ? actual : actual[key];
          if (!deepCompare(actualVal, expectedVal, comparator, anyPropertyKey, matchAnyProperty, matchAnyProperty)) {
            return false;
          }
        }
        return true;
      }
      return (<TFilterComparatorFn<T>>comparator)(actual, expected);
    case 'function':
      return false;
    default:
      return (<TFilterComparatorFn<T>>comparator)(actual, expected);
  }
}

// Used for easily differentiating between `null` and actual `object`
function getTypeForFilter(val: any): string {
  return val === null ? 'null' : typeof val;
}
