import {Observable} from 'rxjs';
import {IApiRequestConfig, IApiRequestConfigWithBody, TServiceResponse} from '../../services/api/api.service.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IGenericImporterExecutor} from '../../components/genericimporter/generic.importer.component.interface';
import {IJsonCalendario} from '../calendarios/jsonCalendarios.entity.interface';
import {IJsonDGEMP, IJsonDGEMPDetail, IJsonDGEMPMarcacoes, IJsonDGEMPPorAprovar, IJsonDGEMPRefeicoesPorAprovar, IJsonProcessamentoEmpregadoSalarios} from './jsonDGEMP.entity.interface';
import {IJsonDHEMPCalcularCustoHoraEmpregado, IJsonDHEMPCustoHoraEmpregado} from '../../modules/portalrh/dgempsfull/jsonDGEMPFull.interface';
import {IJsonPRHComIntLin, IJsonPRHComunicacaoInterna, IJsonPRHNumber} from '../prhcomunicacoesinternas/jsonPRHComunicacaoInterna.entity.interface';
import {IJsonPRHDocsPlata} from '../prhdocsplata/jsonPRHDocsPlata.entity.interface';
import {IJsonPRHLocal} from '../prhlocais/jsonPRHLocal.entity.interface';
import {IJsonPRHMarcarAbonos} from '../../interfaces/jsonPRHAbonos.interface';
import {IJsonPRHMarcarFaltas} from '../../interfaces/jsonPRHFaltas.interface';
import {IJsonPRHMarcarFerias} from '../../interfaces/jsonPRHFerias.interface';
import {Moment} from 'moment';
import {ICalendariosGetParams} from '../calendarios/calendarios.entity.interface';

export interface IDGEMPSEntityService<T extends IJsonDGEMP = IJsonDGEMP> extends IEntityService<T>, IGenericImporterExecutor {
  definirLocalPorDefeito(codEmp: number, codLocal: number): TServiceResponse<void>;

  getLocalDefeito(codEmp: number): TServiceResponse<IJsonPRHLocal>;

  getListaDocPlata(codEmp: number, dataValidade: Moment): TServiceResponse<Array<IJsonPRHDocsPlata>>;

  getListaRefeicoesPorAprovar(codEmp: number, codpapel: number): TServiceResponse<Array<IJsonDGEMPRefeicoesPorAprovar>>;

  getDetail(codEmp: number): TServiceResponse<IJsonDGEMPDetail>;

  getListaComunicacoes(codEmp: number, dataDe: Moment, dataAte: Moment, tipoComunicacao: number, soPorLer: boolean): TServiceResponse<Array<IJsonPRHComunicacaoInterna>>;

  getListaPendentesPorTratar(codEmp: number): TServiceResponse<IJsonDGEMPPorAprovar>;

  getCountComunicacoesPorLer(codEmp: number, dataDe: Moment, dataAte: Moment, tipo: number): TServiceResponse<IJsonPRHNumber>;

  marcarAbonos(codEmp: number, abonos: IJsonPRHMarcarAbonos): TServiceResponse<IJsonPRHMarcarAbonos>;

  marcarFaltas(codEmp: number, faltas: IJsonPRHMarcarFaltas, file: Blob): TServiceResponse<IJsonPRHMarcarFaltas>;

  marcarFerias(codEmp: number, ferias: IJsonPRHMarcarFerias): TServiceResponse<IJsonPRHMarcarFerias>;

  getCalendario(codEmp: number, params?: ICalendariosGetParams): TServiceResponse<IJsonCalendario>;

  getListaMarcacoes(codEmp: number, dataDe: Moment, dataAte: Moment): TServiceResponse<IJsonDGEMPMarcacoes>;

  getListaProcessamentos(codEmp: number, dataDe: Moment, dataAte: Moment, fichcriadosegsocial: boolean): TServiceResponse<Array<IJsonProcessamentoEmpregadoSalarios>>;

  exportReciboVencimentoToPdf(codEmp: number, nProcessamento?: number, nVias?: number, reportName?: string): TServiceResponse<Blob>;

  exportReciboVencimentoToPdfUrl(codEmp: number, nProcessamento?: number, nVias?: number, reportName?: string): Observable<string>;

  getLastPortaisReg(): TServiceResponse<string>;

  saveLastPortaisReg(portais: Array<string>): TServiceResponse<void>;

  marcarComunicacaoComoLida(codEmp: number, comintlinid: number): TServiceResponse<IJsonPRHComIntLin>;

  getFoto(codEmp: number, config?: IApiRequestConfig): TServiceResponse<Blob>;

  setFoto(codEmp: number, file: File, config?: IApiRequestConfig): TServiceResponse<void>;

  deleteFoto(codEmp: number, config?: IApiRequestConfig): TServiceResponse<void>;

  calcularCustoHoraEmpregado(
    codEmp: number,
    calcularCustoHoraEmpregado: IJsonDHEMPCalcularCustoHoraEmpregado,
    summary?: boolean,
    config?: IApiRequestConfigWithBody<IJsonDHEMPCalcularCustoHoraEmpregado>
  ): TServiceResponse<IJsonDHEMPCustoHoraEmpregado>;
}

export interface IDGEMPSEntity<T extends IJsonDGEMP = IJsonDGEMP> extends IEntityDefinition<T, IDGEMPSEntityService<T>> {}

export const ENTITY_NAME_DGEMPS = 'dgemps';
