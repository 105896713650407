import {Component, Injector} from '@angular/core';
import {DocContabilidadeFieldInput} from '../docContabilidade.field.input';
import {EDocContabilidadeField} from '../../docContabilidade.interface';

@Component({
  selector: 'doccontabilidade-descricao',
  templateUrl: './docContabilidade.descricao.component.html'
})
export class DocContabilidadeDescricaoComponent extends DocContabilidadeFieldInput<string> {
  constructor(protected readonly _injector: Injector) {
    super(EDocContabilidadeField.DESCRICAO, _injector, 'descricao');
  }

  public modelChanged(value: string): void {
    super.modelChanged(value);
    this.evtModelChanged.emit({
      linha: this.linha,
      value: value,
      oldValue: this.linha.descricao
    });
  }

  protected _hasGroup(): boolean {
    return false;
  }
}
