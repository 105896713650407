import {Injectable} from '@angular/core';
import {ApiService} from '../../../../services/api/api.service';
import {TServiceResponse} from '../../../../services/api/api.service.interface';
import {IJsonPRHFerias} from '../../../../interfaces/jsonPRHFerias.interface';
import {
  IJsonRHConflitosCheckResult,
  IJsonRHConflitosConfig,
  IJsonRHConflitosConfigColaboradorItem,
  IJsonRHConflitosCreateConflitoData,
  MODULE_NAME_RH_CONFLITOS
} from './configs.portalcolaborador.module.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigsPortalColaboradorModuleService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/${MODULE_NAME_RH_CONFLITOS}`;
  }

  public getConfig(): TServiceResponse<IJsonRHConflitosConfig> {
    return this._apiService.get({url: `${this._path}/config`});
  }

  public saveConfig(config: IJsonRHConflitosConfig): TServiceResponse<void> {
    return this._apiService.post<void, IJsonRHConflitosConfig>({url: `${this._path}/config`, body: config});
  }

  public getConflitos(): TServiceResponse<Array<IJsonRHConflitosConfigColaboradorItem>> {
    return this._apiService.get<Array<IJsonRHConflitosConfigColaboradorItem>>({url: `${this._path}/config/conflitos`});
  }

  public deleteConflito(idConflito: string): TServiceResponse<void> {
    return this._apiService.delete({url: `${this._path}/config/conflito`, params: {idConflito: idConflito}});
  }

  public deleteConflitosDoColaboradoro(codemp: number): TServiceResponse<void> {
    return this._apiService.delete({url: `${this._path}/config/conflito/${codemp}`});
  }

  public createConflito(data: IJsonRHConflitosCreateConflitoData): TServiceResponse<void> {
    return this._apiService.post<void, IJsonRHConflitosCreateConflitoData>({url: `${this._path}/config/conflito`, body: data});
  }

  public checkConflitoCodEmp(codemp: number, list: Array<IJsonPRHFerias>): TServiceResponse<IJsonRHConflitosCheckResult> {
    return this._apiService.post<IJsonRHConflitosCheckResult, Array<IJsonPRHFerias>>({url: `${this._path}/check/conflitos/${codemp}`, body: list});
  }
}
