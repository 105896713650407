import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {IExtratosClifos} from './extratosClifos.module.interface';
import {IApiQueryResponse, TServiceQueryResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ExtratosClifosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = this._apiService.path.restapi;
  }

  public extratosClifos(radical: string, order: string): TServiceQueryResponse<IExtratosClifos> {
    return this._apiService.get<IApiQueryResponse<IExtratosClifos>>({
      url: `${this._path}/extratosclifos`,
      params: {
        radical: radical,
        ordena: order
      }
    });
  }
}
