import {HttpResponse} from '@angular/common/http';
import {DossierFiscalComponent} from './components/dossierFiscal.module.component';
import {EDossierFiscalTipoElemento} from '../../../services/portalclientaccount/portalclientaccount.service.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {PortalClientAccountService} from '../../../services/portalclientaccount/portalclientaccount.service';
import {ROLE} from '../../../services/role.const';

export const MODULE_DOSSIER_FISCAL_BALANCO: IModuleDefinition = generateModuleDossierFiscal({
  tipo: EDossierFiscalTipoElemento.Balanco,
  moduleName: 'dossierBalanco',
  moduleNameAlias: ['DossierBalanco'],
  url: 'balanco'
});

export const MODULE_DOSSIER_FISCAL_DEMONSTRACAO_RESULTADOS: IModuleDefinition = generateModuleDossierFiscal({
  tipo: EDossierFiscalTipoElemento.DemonstracaoResultadosNatureza,
  moduleName: 'dossierDemonstracaoResultados',
  moduleNameAlias: ['DossierDemonstracaoResultados'],
  url: 'demonstracaoresultados'
});

export const MODULE_DOSSIER_FISCAL_RESULTADOS_POR_NATUREZA: IModuleDefinition = generateModuleDossierFiscal({
  tipo: EDossierFiscalTipoElemento.DemonstracaoResultadosPorFuncoes,
  moduleName: 'dossierResultadosPorNatureza',
  moduleNameAlias: ['DossierFluxoCaixa'],
  url: 'resultadospornatureza'
});

export const MODULE_DOSSIER_FISCAL_FLUXO_CAIXA: IModuleDefinition = generateModuleDossierFiscal({
  tipo: EDossierFiscalTipoElemento.DemonstracaoDeFluxosDeCaixa,
  moduleName: 'dossierFluxoCaixa',
  moduleNameAlias: ['DossierResultadosPorNatureza'],
  url: 'fluxocaixa'
});

export const MODULE_DOSSIER_FISCAL_ALTERACOES_CAPITAL_PROPRIO: IModuleDefinition = generateModuleDossierFiscal({
  tipo: EDossierFiscalTipoElemento.DemonstracaoDasAlteracoesDoCapitalProprio,
  moduleName: 'dossierAlteracoesCapitalProprio',
  moduleNameAlias: ['DossierAlteracoesCapitalProprio'],
  url: 'alteracoescapitalproprio'
});

export const MODULE_DOSSIER_FISCAL_ANEXO_DEMONSTRACOES_FINANCEIRAS: IModuleDefinition = generateModuleDossierFiscal({
  tipo: EDossierFiscalTipoElemento.DemonstracaoDasAlteracoesDoCapitalProprio,
  moduleName: 'dossierAnexoDemonstracoesFinanceiras',
  moduleNameAlias: ['DossierAnexoDemonstracoesFinanceiras'],
  url: 'anexodemonstracoesfinanceiras'
});

function generateModuleDossierFiscal({tipo, moduleName, moduleNameAlias, url}: {tipo: EDossierFiscalTipoElemento; moduleName: string; moduleNameAlias: Array<string>; url: string}): IModuleDefinition {
  return {
    name: moduleName,
    nameAlias: moduleNameAlias,
    state: {
      url: `/dossierfiscal/${url}`,
      component: DossierFiscalComponent,
      data: {
        roles: [ROLE.PCA]
      },
      resolve: [
        {provide: 'tipoDossierFiscal', useFactory: () => tipo},
        {
          provide: 'anos',
          deps: ['tipoDossierFiscal', PortalClientAccountService],
          useFactory: (tipoDossierFiscal: EDossierFiscalTipoElemento, portalClientAccountService: PortalClientAccountService) =>
            portalClientAccountService.getDossierFiscalAnos(tipoDossierFiscal).then((response: HttpResponse<Array<number>>) => response.body.reverse())
        }
      ]
    }
  };
}
