import {Component, Injector, Input} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {ContabilidadeDigitalService} from '../../../../services/contabilidadedigital/contabilidadedigital.service';
import {EObjScanDocTextInterpreterInvoiceType} from '../../../../../common/interfaces/objscandoctextinterpreter.interface';
import {IContabilidadeDigitalAtribuirTipoDocumentoInvoiceType} from './contabilidadedigital.atribuirtipodocumento.modal.interface';

@Component({
  selector: 'contabilidadedigital-atribuir-tipo-documento-modal',
  templateUrl: './contabilidadedigital.atribuirtipodocumento.modal.component.html'
})
export class ContabilidadeDigitalAtribuirTipoDocumentoModalComponent extends CGModalComponent<EObjScanDocTextInterpreterInvoiceType> {
  @Input() public docsID: Array<string>;
  public readonly rowTemplate: string;
  public readonly invoiceTypes: Array<IContabilidadeDigitalAtribuirTipoDocumentoInvoiceType>;
  public invoiceType: EObjScanDocTextInterpreterInvoiceType;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService
  ) {
    super(_injector);
    this.invoiceTypes = [
      {value: EObjScanDocTextInterpreterInvoiceType.None, label: 'objscandoctextinterpreter.invoiceType.none'},
      {value: EObjScanDocTextInterpreterInvoiceType.Fatura, label: 'objscandoctextinterpreter.invoiceType.fatura'},
      {value: EObjScanDocTextInterpreterInvoiceType.FaturaSimplificada, label: 'objscandoctextinterpreter.invoiceType.faturaSimplificada'},
      {value: EObjScanDocTextInterpreterInvoiceType.FaturaRecibo, label: 'objscandoctextinterpreter.invoiceType.faturaRecibo'},
      {value: EObjScanDocTextInterpreterInvoiceType.NotaDebito, label: 'objscandoctextinterpreter.invoiceType.notaDebito'},
      {value: EObjScanDocTextInterpreterInvoiceType.NotaCredito, label: 'objscandoctextinterpreter.invoiceType.notaCredito'},
      {value: EObjScanDocTextInterpreterInvoiceType.VendaDinheiro, label: 'objscandoctextinterpreter.invoiceType.vendaDinheiro'},
      {value: EObjScanDocTextInterpreterInvoiceType.TalaoVenda, label: 'objscandoctextinterpreter.invoiceType.talaoVenda'},
      {value: EObjScanDocTextInterpreterInvoiceType.TalaoDevolucao, label: 'objscandoctextinterpreter.invoiceType.talaoDevolucao'},
      {value: EObjScanDocTextInterpreterInvoiceType.AlienacaoAtivos, label: 'objscandoctextinterpreter.invoiceType.alienacaoAtivos'},
      {value: EObjScanDocTextInterpreterInvoiceType.DevolucaoAtivos, label: 'objscandoctextinterpreter.invoiceType.devolucaoAtivos'},
      {value: EObjScanDocTextInterpreterInvoiceType.Premio, label: 'objscandoctextinterpreter.invoiceType.premio'},
      {value: EObjScanDocTextInterpreterInvoiceType.Estorno, label: 'objscandoctextinterpreter.invoiceType.estorno'},
      {value: EObjScanDocTextInterpreterInvoiceType.ImputacaoCoSeguradoras, label: 'objscandoctextinterpreter.invoiceType.imputacaoCoSeguradoras'},
      {value: EObjScanDocTextInterpreterInvoiceType.ImputacaoCoSeguradoraLider, label: 'objscandoctextinterpreter.invoiceType.imputacaoCoSeguradoraLider'},
      {value: EObjScanDocTextInterpreterInvoiceType.ResseguroAceite, label: 'objscandoctextinterpreter.invoiceType.resseguroAceite'},
      {value: EObjScanDocTextInterpreterInvoiceType.Recibo, label: 'objscandoctextinterpreter.invoiceType.recibo'},
      {value: EObjScanDocTextInterpreterInvoiceType.NotaLancamento, label: 'objscandoctextinterpreter.invoiceType.notaLancamento'},
      {value: EObjScanDocTextInterpreterInvoiceType.ExtratoBancario, label: 'objscandoctextinterpreter.invoiceType.extratoBancario'},
      {value: EObjScanDocTextInterpreterInvoiceType.PagamentoAoEstado, label: 'objscandoctextinterpreter.invoiceType.pagamentoAoEstado'},
      {value: EObjScanDocTextInterpreterInvoiceType.Levantamento, label: 'objscandoctextinterpreter.invoiceType.levantamento'},
      {value: EObjScanDocTextInterpreterInvoiceType.Pagamento, label: 'objscandoctextinterpreter.invoiceType.pagamento'},
      {value: EObjScanDocTextInterpreterInvoiceType.Deposito, label: 'objscandoctextinterpreter.invoiceType.deposito'},
      {value: EObjScanDocTextInterpreterInvoiceType.Cheque, label: 'objscandoctextinterpreter.invoiceType.cheque'},
      {value: EObjScanDocTextInterpreterInvoiceType.Transferencia, label: 'objscandoctextinterpreter.invoiceType.transferencia'}
    ];
  }

  public changedInvoiceType(invoiceType: IContabilidadeDigitalAtribuirTipoDocumentoInvoiceType): void {
    this.invoiceType = invoiceType.value;
  }

  public close(): Promise<void> {
    return this._contabilidadeDigitalService.documents.atribuirTipoDocumento(this.docsID, this.invoiceType).then(() => {
      this._plAlertService.success('arquivodigital.atribuirtipodocumentomodal.success');
      super.close(this.invoiceType);
    });
  }
}
