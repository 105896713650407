import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {PerfilCategoriaDescontoEditComponent} from './components/perfilCategoriaDesconto.edit.component';
import {ROLE} from '../../../services/role.const';

export const MODULE_PERFIL_CATEGORIA_DESCONTO: IModuleDefinition = {
  name: 'perfilCategoriaDesconto',
  state: {
    url: '/perfiscategorias/descontos',
    component: PerfilCategoriaDescontoEditComponent,
    data: {
      roles: [ROLE.RH]
    }
  }
};
