import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IPlToolbarInstance, isObject, KEYCODES, PlToolbarService, toInteger} from 'pl-comps-angular';
import {ConfigService} from '../../../services/config/config.service';
import {ContabilidadeEstatisticaService} from '../../../modules/portalcontabilidade/estatistica/contabilidadeEstatistica.module.service';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {EReport} from '../../../entities/reports/reports.interface';
import {ICGContabExtratosCallback, ICGContabExtratosFilters} from './extratos.interfaces';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IJsonPeriodo} from '../../../entities/periodos/jsonPeriodo.entity.interface';
import {IJsonReport} from '../../../entities/reports/jsonReport.interface';
import {ReportsRegistryService} from '../../reports/reports.registry.service';

const BTN_ID_PROCESSAR = 'processar';

@Component({
  selector: 'cg-contab-extratos',
  templateUrl: './extratos.component.html'
})
export class CGContabExtratosComponent implements OnInit {
  @Input() public filters: ICGContabExtratosFilters;
  @Input() public processOnInit: boolean;
  @Input() public toolbarInstanceId: string;
  @Input() public callback: ICGContabExtratosCallback;
  @Output() public readonly evtChangedUrl: EventEmitter<string>;

  public deConta: string;
  public ateConta: string;
  public pdfUrl: string;
  public reportModel: IJsonReport;
  public periodo: IJsonPeriodo;
  public loadAllAnos: number;
  public loadOnlyAno: number;

  private readonly _entityPeriodos: IEntityService<IJsonPeriodo>;
  private _periodo: string;
  private _toolbarInstance: IPlToolbarInstance;

  constructor(
    private readonly _plToolbarService: PlToolbarService,
    private readonly _config: ConfigService,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _contabilidadeEstatisticaService: ContabilidadeEstatisticaService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this._entityPeriodos = this._entityServiceBuilder.build<IJsonPeriodo>('periodos');
    this.evtChangedUrl = new EventEmitter<string>();
  }

  public ngOnInit(): void {
    this.deConta = this.filters.deConta ? this.filters.deConta : (this.filters.deConta = '1');
    this.ateConta = this.filters.ateConta ? this.filters.ateConta : (this.filters.ateConta = '89999999');
    this._periodo = this.filters.periodo ? this.filters.periodo : this._config.configurations.empresa.periodoDaData;
    this._entityPeriodos.get({id: this._periodo}).then((reponse) => {
      this.periodo = reponse.body;
      this._periodo = this.periodo.periodo;
      this.filtersChanged();
      if (this.processOnInit) {
        this.processar();
      }
    });

    this._loadDefaultReport();
    if (isObject(this.callback)) {
      this.callback.processar = () => {
        this.processar();
      };
    }

    this.loadAllAnos = 1;
    this.loadOnlyAno = 0;
  }

  public processar(): void {
    this._contabilidadeEstatisticaService.getExtratosUrl(this.deConta, this.ateConta, this._periodo, this.reportModel.name, 0).subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }

  public filtersChanged(): void {
    this._contabilidadeEstatisticaService.getExtratosUrl(this.deConta, this.ateConta, this._periodo, this.reportModel.name, 1).subscribe((url: string) => {
      this.evtChangedUrl.emit(url);
    });
  }

  public deContaChanged(value: string): void {
    this.deConta = value;
    this.ateConta = value;
    this.filtersChanged();
  }

  public periodoChanged(value: IJsonPeriodo): void {
    this.periodo = value;
    this._periodo = value.periodo;
    if (value) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const ano = toInteger(value.periodo.substring(0, 4));
      if (ano === this._config.configurations.empresa.anoEmCursoIRC || ano === this._config.configurations.empresa.anoEmCursoIRC + 1) {
        this.loadOnlyAno = 0;
        this.loadAllAnos = 0;
      } else {
        this.loadOnlyAno = ano;
        this.loadAllAnos = 1;
      }
    }
    this.filtersChanged();
  }

  public readonly fnKeydownProcessar = (value: string, event: KeyboardEvent): void => {
    this._keydownProcessar(event);
  };

  private _loadDefaultReport(): Promise<void> {
    this.reportModel = {
      name: this._config.configurations.contabilidade.extratos.reportImpressao,
      title: this._config.configurations.contabilidade.extratos.reportImpressao
    };
    return this._reportsRegistryService
      .get(EReport.Extratos)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          let foundDefault = false;
          for (const report of reports) {
            if (report.name === this.reportModel.name) {
              this.reportModel = {name: report.name, title: report.title};
              foundDefault = true;
              break;
            }
          }
          if (!foundDefault) {
            this.reportModel = {
              name: reports[0].name,
              title: reports[0].title
            };
          }
        }
      });
  }

  private _keydownProcessar(event: KeyboardEvent): void {
    if (event.key !== KEYCODES.ENTER) {
      return;
    }
    if (!this._toolbarInstance || this._toolbarInstance.instanceId !== this.toolbarInstanceId) {
      if (!this.toolbarInstanceId) {
        return;
      }
      this._toolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceId);
    }
    if (!this._toolbarInstance) {
      return;
    }
    this._toolbarInstance.focusItem(BTN_ID_PROCESSAR);
  }
}
