import {BehaviorSubject} from 'rxjs';
import {LOCAL_STORAGE_APP_API_KEY} from '../config/constants';
import {IAppRuntimeProperties} from './interfaces/interfaces';

export const APP_RUNTIME_PROPERTIES: BehaviorSubject<IAppRuntimeProperties> = new BehaviorSubject<IAppRuntimeProperties>(undefined);

export const APP_API_KEY: BehaviorSubject<string> = new BehaviorSubject<string>(evaluateApiKey());

export const APP_USER_FEEDBACK_TOKEN: BehaviorSubject<string> = new BehaviorSubject<string>('');

APP_API_KEY.subscribe((apiKey: string) => {
  if (!window || !window.sessionStorage) {
    return;
  }
  if (apiKey) {
    window.sessionStorage.setItem(LOCAL_STORAGE_APP_API_KEY, apiKey);
  } else {
    window.sessionStorage.removeItem(LOCAL_STORAGE_APP_API_KEY);
  }
});

function evaluateApiKey(): string {
  if (window?.sessionStorage) {
    return window.sessionStorage.getItem(LOCAL_STORAGE_APP_API_KEY) || '';
  }
  return '';
}
