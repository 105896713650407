import {isArray, isNumber, paginate as cgcPaginate} from 'pl-comps-angular';
import {IPagination} from './pagination.interface';
import {DATAGRID_ALLOWED_PAGE_SIZES} from '../../config/constants';

export class Pagination {
  private _page: number;
  private _perPage: number;
  private _total: number;
  private _allowedPageSizes: Array<number>;
  private _offset: number;

  constructor(params?: IPagination) {
    this._allowedPageSizes = (isArray(params?.allowedPageSizes) ? params.allowedPageSizes : DATAGRID_ALLOWED_PAGE_SIZES).slice();
    this._page = isNumber(params?.page) ? params.page : 1;
    this._total = isNumber(params?.total) ? params.total : 0;
    this._perPage = isNumber(params?.perPage) ? params.perPage : this._allowedPageSizes[0] || this._total;
    this._calculateOffset();
  }

  public paginate<T>(source: Array<T>): Array<T> {
    return cgcPaginate(source, this._page, this._perPage);
  }

  public get page(): number {
    return this._page;
  }

  public set page(value: number) {
    this._page = value;
    this._calculateOffset();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get perPage(): number {
    return this._perPage;
  }

  public set perPage(value: number) {
    this._perPage = value;
    this._calculateOffset();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get total(): number {
    return this._total;
  }

  public set total(value: number) {
    this._total = value;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get allowedPageSizes(): Array<number> {
    return this._allowedPageSizes;
  }

  public set allowedPageSizes(value: Array<number>) {
    this._allowedPageSizes = value.slice();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public get offset(): number {
    return this._offset;
  }

  private _calculateOffset(): void {
    this._offset = (this._page - 1) * this._perPage;
  }
}
