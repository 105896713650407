import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonSubFamilia} from './jsonSubFamilia.entity.interface';
import {ENTITY_NAME_SUB_FAMILIAS} from './subFamilias.entity.interface';

export const ENTITY_SUB_FAMILIAS: IEntityDefinition<IJsonSubFamilia> = {
  name: ENTITY_NAME_SUB_FAMILIAS,
  roles: [ROLE.ERP, ROLE.ATIVOS],
  searchPlaceholder: 'subfamilias.pesquisa',
  metadata: {
    keyName: 'nSubFa',
    fields: [
      {
        name: 'nSubFa',
        type: 'cginteger',
        width: '120px',
        caption: 'subfamilias.fields.nSubFa',
        placeholder: 'subfamilias.fields.nSubFaPlaceholder',
        validators: {required: true, min: 1, max: 2147483647}
      },
      {
        name: 'nome',
        caption: 'subfamilias.fields.nome',
        placeholder: 'subfamilias.fields.nomePlaceholder',
        validators: {required: true, maxlength: 50}
      }
    ],
    order: 'nome',
    searchFields: 'nSubFa,nome'
  },
  autocomplete: {
    rowTemplate: '{{nSubFa}} - {{nome}}',
    output: 'nome',
    searchFields: 'nSubFa,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.subfamilias'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.subfamilias'
      }
    }
  }
};
