import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IJsonGrupoDocfaConfiguracoes} from './jsonGrupoDocfaConfiguracoes.module.interface';

@Injectable({
  providedIn: 'root'
})
export class GrupoDocfaConfiguracoesService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/grupodocfaconfiguracoes`;
  }

  public getConfiguracoes(): TServiceResponse<IJsonGrupoDocfaConfiguracoes> {
    return this._apiService.get<IJsonGrupoDocfaConfiguracoes>({url: this._path});
  }

  public save(configuracao: IJsonGrupoDocfaConfiguracoes): TServiceResponse<IJsonGrupoDocfaConfiguracoes> {
    return this._apiService.post<IJsonGrupoDocfaConfiguracoes>({url: this._path, body: configuracao});
  }
}
