import {Component, Injector, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {IPlEditComponentOptionsInputRadio, IPlNavWizardEventBeforeChange, IPlNavWizardEventStep, PlAlertService} from 'pl-comps-angular';
import {
  ASSIST_CONFIG_PORTAIS_GERAL_EMAIL_DEFAULTS,
  EAssistConfigPortaisGeralEmailTipoEmail,
  IAssistConfigPortaisGeralEmailEmpresa,
  IAssistConfigPortaisGeralEmailModel
} from '../assistConfigPortais.geral.email.module.interface';
import {AssistenteConfigPortaisService} from '../../../../assistente.configPortais.module.service';
import {CGExceptionService} from '../../../../../../../components/exceptions/exceptions.service';
import {EmailsService} from '../../../../../../../services/emails.service';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../../../../config/constants';
import {ICGExceptionError} from '../../../../../../../components/exceptions/exceptions.service.interface';
import {IJsonErpUser} from '../../../../../../../services/account/jsonUserApi.interface';
import {IJsonGDocEmailConfig} from '../../../../jsonGdocEmailConfig.module.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS} from '../../../../assistente.configPortais.module.service.interface';
import {ModuloComponent} from '../../../../../../../components/module/module.component';

const NAV_WIZARD_STEP_ID_EMPRESA = 'empresa';
const NAV_WIZARD_STEP_ID_EMAIL = 'email';

@Component({
  selector: 'module-assist-config-portais-geral-email',
  templateUrl: './assistConfigPortais.geral.email.module.component.html'
})
export class AssistConfigPortaisGeralEmailModuleComponent extends ModuloComponent implements OnInit {
  public readonly navWizardStepIdEmpresa: string;
  public readonly navWizardStepIdEmail: string;
  public readonly templateMultiSelectEmpresas: string;
  public readonly tiposEmail: Array<IPlEditComponentOptionsInputRadio<EAssistConfigPortaisGeralEmailTipoEmail>>;
  public readonly empresasDisponiveis: Array<IAssistConfigPortaisGeralEmailEmpresa>;
  public readonly empresasSelecionadas: Array<IAssistConfigPortaisGeralEmailEmpresa>;
  public readonly model: IJsonGDocEmailConfig;
  public tipoEmail: EAssistConfigPortaisGeralEmailTipoEmail;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _assistenteConfigPortaisService: AssistenteConfigPortaisService,
    private readonly _emailsService: EmailsService
  ) {
    super(_injector);
    this.navWizardStepIdEmpresa = NAV_WIZARD_STEP_ID_EMPRESA;
    this.navWizardStepIdEmail = NAV_WIZARD_STEP_ID_EMAIL;
    this.templateMultiSelectEmpresas = '{{nEmpresa}} - {{nomeEmpresa}}';
    this.tiposEmail = [
      {value: EAssistConfigPortaisGeralEmailTipoEmail.Gmail, label: 'assistenteconfigportais.tiposEmail.gmail'},
      {value: EAssistConfigPortaisGeralEmailTipoEmail.Hotmail, label: 'assistenteconfigportais.tiposEmail.hotmail'},
      {value: EAssistConfigPortaisGeralEmailTipoEmail.Outro, label: 'assistenteconfigportais.tiposEmail.outro'}
    ];
    this.empresasDisponiveis = this.session.erps.map((erp: IJsonErpUser) => {
      return {nEmpresa: erp.nEmpresa, nomeEmpresa: erp.nomeEmpresa};
    });
    this.empresasSelecionadas = [];
    this.model = {
      centralGestId: undefined,
      erpName: undefined,
      nEmpresa: undefined,
      contaEmail: undefined,
      password: undefined,
      pop3: undefined,
      pop3Port: undefined,
      smtp: undefined,
      smtpPort: undefined,
      ssl: undefined
    };
    this._setConfiguracaoEmail(ASSIST_CONFIG_PORTAIS_GERAL_EMAIL_DEFAULTS.gmail);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnBack.visible = true;
  }

  public back(): Promise<void> {
    const moduleName: string = this._assistenteConfigPortaisService.getModuleSelectPortal();
    return this._cgStateService.redirectToState({stateOrName: moduleName});
  }

  public changedStep({currentStep}: IPlNavWizardEventStep): void {
    if (currentStep.stepId === this.navWizardStepIdEmpresa) {
      this.changedTipoEMail(EAssistConfigPortaisGeralEmailTipoEmail.Gmail);
    } else if (currentStep.stepId === this.navWizardStepIdEmail) {
      if (this.empresasSelecionadas.length === 1) {
        const nEmpresa = this.empresasSelecionadas[0].nEmpresa;
        this._emailsService
          .getConfiguracaoEnvioEmail(nEmpresa)
          .then((response: HttpResponse<IJsonGDocEmailConfig>) => {
            if (response.body) {
              this._setConfiguracaoEmail({
                tipoEmail: EAssistConfigPortaisGeralEmailTipoEmail.Outro,
                contaEmail: response.body.contaEmail,
                smtp: response.body.smtp,
                portaSmtp: response.body.smtpPort,
                ssl: response.body.ssl
              });
            } else {
              this.changedTipoEMail(EAssistConfigPortaisGeralEmailTipoEmail.Gmail);
            }
          })
          .catch((error: HttpErrorResponse) => {
            const exception = this._cgExceptionService.get(error);
            if (exception) {
              this._plAlertService.error(exception.message);
            } else {
              this._plAlertService.error('assistenteconfigportais.info.emailconfigsaveerror');
            }
          });
      } else if (this.empresasSelecionadas.length > 1) {
        this.changedTipoEMail(EAssistConfigPortaisGeralEmailTipoEmail.Gmail);
      }
    }
  }

  public changedTipoEMail(tipoEmail: EAssistConfigPortaisGeralEmailTipoEmail): void {
    let emailModel: IAssistConfigPortaisGeralEmailModel;
    switch (tipoEmail) {
      case EAssistConfigPortaisGeralEmailTipoEmail.Gmail:
        emailModel = ASSIST_CONFIG_PORTAIS_GERAL_EMAIL_DEFAULTS.gmail;
        break;
      case EAssistConfigPortaisGeralEmailTipoEmail.Hotmail:
        emailModel = ASSIST_CONFIG_PORTAIS_GERAL_EMAIL_DEFAULTS.hotmail;
        break;
      case EAssistConfigPortaisGeralEmailTipoEmail.Outro:
        emailModel = ASSIST_CONFIG_PORTAIS_GERAL_EMAIL_DEFAULTS.outro;
        break;
    }
    this._setConfiguracaoEmail(emailModel);
  }

  public readonly fnBeforeChangedStep = (event: IPlNavWizardEventBeforeChange): boolean => this._beforeChangedStep(event);

  public readonly fnFinalize: () => Promise<void> = () => this._finalize();

  private _setConfiguracaoEmail({tipoEmail, contaEmail, smtp, portaSmtp, ssl}: IAssistConfigPortaisGeralEmailModel): void {
    this.tipoEmail = tipoEmail;
    this.model.contaEmail = contaEmail;
    this.model.password = undefined;
    this.model.pop3 = undefined;
    this.model.pop3Port = undefined;
    this.model.smtp = smtp;
    this.model.smtpPort = portaSmtp ? String(portaSmtp) : undefined;
    this.model.ssl = ssl;
  }

  private _beforeChangedStep({previousStep, currentStep, type, nextStep}: IPlNavWizardEventBeforeChange): boolean {
    if (currentStep && currentStep.stepId === this.navWizardStepIdEmpresa && !previousStep && !nextStep && type !== 'previous' && !this.empresasSelecionadas.length) {
      this._plAlertService.error('assistenteconfigportais.info.empresanaoselec');
      return false;
    }
    return true;
  }

  private _finalize(): Promise<void> {
    if (!this.empresasSelecionadas.length) {
      this._plAlertService.error('assistenteconfigportais.info.empresanaoselec');
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    if (!this.model.contaEmail) {
      this._plAlertService.error('assistenteconfigportais.info.contaemailnula');
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }

    if (!this.model.password) {
      this._plAlertService.error('assistenteconfigportais.info.passwordnula');
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }

    const emailModelList: Array<IJsonGDocEmailConfig> = this.empresasSelecionadas.map<IJsonGDocEmailConfig>((empresa: IAssistConfigPortaisGeralEmailEmpresa) => {
      return {
        centralGestId: this.model.centralGestId,
        erpName: this.model.erpName,
        nEmpresa: empresa.nEmpresa,
        contaEmail: this.model.contaEmail,
        password: this.model.password,
        pop3: this.model.pop3,
        pop3Port: this.model.pop3Port,
        smtp: this.model.smtp,
        smtpPort: this.model.smtpPort,
        ssl: this.model.ssl
      };
    });

    return this._assistenteConfigPortaisService
      .setConfiguracaoGDocEmailEmpresa(this.session.erp.centralGestId, this.session.erp.name, this.session.erp.nEmpresa, emailModelList)
      .then(() => {
        this._plAlertService.success('assistenteconfigportais.info.emailconfigsavesucess');
        return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS});
      })
      .catch((error: HttpErrorResponse) => {
        const exception: ICGExceptionError = this._cgExceptionService.get(error);
        if (exception) {
          this._plAlertService.error(exception.message);
        } else {
          this._plAlertService.error('assistenteconfigportais.info.emailconfigsaveerror');
        }
      });
  }
}
