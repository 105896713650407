<div class="morals">
  <entity-detail
    entityName="morals"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL}">
        <pl-group [properties]="{readonly: type === stateTypes.DETAIL || nContaReadonlyOnNew}">
          <label [translate]="'morals.fields.nConta'"></label>
          <edit>
            <entity-autocomplete
              entity="clifos"
              attrName="nConta"
              [model]="model"
              (selectedKeyChange)="model.nConta = $event"
              (evtSelectedDescriptionChanged)="model.nomeConta = $event"
              [properties]="{validators: {required: {value: true}, maxlength: {value: 75}}}"
              [fieldsMap]="{nome: 'nomeConta'}"
              [output]="clifosOutput"
              plAutoFocus
              [plAutoFocusDisabled]="type === stateTypes.EDIT || nContaReadonlyOnNew">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'morals.fields.nome'"></label>
          <edit>
            <pl-edit-text
              attrName="nome"
              [(model)]="model.nome"
              [properties]="{validators: {required: {value: true}, maxlength: {value: 50}}}"
              plAutoFocus
              [plAutoFocusDisabled]="!nContaReadonlyOnNew">
            </pl-edit-text>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'morals.fields.rua'"></label>
          <edit>
            <pl-edit-text attrName="rua" [(model)]="model.rua" [properties]="{validators: {maxlength: {value: 50}}}"></pl-edit-text>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'morals.fields.cPost'"></label>
          <edit>
            <entity-autocomplete
              entity="codpostais"
              attrName="codPostal"
              [model]="model"
              (modelChange)="codPostalChanged($event)"
              [fieldsMap]="{codPostal: 'cPost'}"
              [properties]="{validators: {maxlength: {value: 20}}}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'morals.fields.localidade'"></label>
          <edit>
            <pl-edit-text attrName="localidade" [(model)]="model.localidade" [properties]="{validators: {maxlength: {value: 50}}}"></pl-edit-text>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'morals.fields.codPais'"></label>
          <edit>
            <entity-autocomplete
              entity="paises"
              attrName="codPais"
              [model]="model"
              (selectedKeyChange)="model.codPais = $event"
              (evtSelectedDescriptionChanged)="model.nomePais = $event"
              [output]="paisOutput">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'morals.fields.desativado'"></label>
          <edit>
            <pl-edit type="boolean" attrName="desativado" [(model)]="model.desativado"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'morals.fields.contactoNome'"></label>
          <edit>
            <pl-edit-text attrName="contactoNome" [(model)]="model.contactoNome" [properties]="{validators: {maxlength: {value: 100}}}"></pl-edit-text>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'morals.fields.contactoTelefone'"></label>
          <edit>
            <pl-edit-mask
              attrName="telefone"
              [(model)]="model.contactoTelefone"
              [mask]="'[\\+][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9]'"
              [properties]="{validators: {minlength: {value: 9}, maxlength: {value: 20}}}">
            </pl-edit-mask>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
