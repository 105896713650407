/**
 * CentralGest Amalia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * List with the paginated resources and useful information.
 */
export interface Pagination { 
    /**
     * The total number of items.
     */
    total: number;
    /**
     * The number of the current page.
     */
    page?: number;
    /**
     * The number of items per page.
     */
    perPage?: number;
    /**
     * List with the paginated resources
     */
    items: Array<any>;
    /**
     * The total number of pages.
     */
    totalPages: number;
    /**
     * The number of items in the current page.
     */
    count: number;
}

