import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {IJsonConfigEmissoraDocFa} from '../../jsonMBConfigs.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import ArrayStore from 'devextreme/data/array_store';

@Component({
  selector: 'modal-mbconfigs-addtipodoc',
  templateUrl: './mbConfigs.addTipoDoc.modal.component.html'
})
export class MBConfigsAddTipoDocModalComponent extends CGModalComponent<Array<IJsonConfigEmissoraDocFa>> implements OnInit {
  @Input() public tiposDocList: Array<IJsonConfigEmissoraDocFa>;

  public readonly dataGridDefinition: IDevExpressDataGrid;

  public selectedRowKeys: Array<number>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.selectedRowKeys = [];
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [{dataField: 'internalDesc', dataType: 'string', caption: 'mbconfigs.fields.internalDesc'}],
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      remoteOperations: false,
      toolbar: {
        items: []
      }
    };
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = new ArrayStore({key: 'nDocFa', data: this.tiposDocList});
  }

  public close(): void {
    if (!this.selectedRowKeys.length) {
      this._plAlertService.error('mbconfigs.messages.ndocfaempty');
      return;
    }
    this._plAlertService.success('mbconfigs.messages.ndocfasuccess');
    const tiposDoc: Array<IJsonConfigEmissoraDocFa> = [];
    for (const key of this.selectedRowKeys) {
      const index = this.tiposDocList.findIndex((value: IJsonConfigEmissoraDocFa) => value.nDocFa === key);
      if (index !== -1) {
        tiposDoc.push(this.tiposDocList[index]);
      }
    }
    super.close(tiposDoc);
  }
}
