import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlToolbarItem, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {ContaPocSaldaModalComponent} from '../modals/contapocsalda/contaPocSalda.modal.component';
import {DATA_SOURCE_NAME_TIPOS_POC} from '../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {ENTITY_NAME_CLIFOS} from '../../../entities/clifos/clifos.entity.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {ENTITY_NAME_POCS} from '../../../entities/pocs/pocs.entity.interface';
import {IEntityMaintenanceInstance} from '../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../components/entity/maintenance/entity/entity.maintenance.service';
import {ETaxonomiasModoModalPoc, ETaxonomiasTab} from '../taxonomias.module.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGridState} from '../../../components/devexpress/datagrid/state/devexpress.datagrid.state.interface';
import {
  IJsonInfoLegal,
  IJsonPlanoOficialPublicado,
  IJsonRelacaoContasTaxonomia,
  IJsonValidacoesLancamentos,
  IJsonValidacoesPlano,
  IJsonValidacoesSVAT
} from '../../../entities/taxonomias/jsonTaxonomias.entity.interface';
import {ITrfLancContasModuleStateParams, MODULE_NAME_TRFLANCCONTAS} from '../../trflanccontas/trfLancContas.module.interface';
import {IModuleMaintenanceInstance} from '../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloComponent} from '../../../components/module/module.component';
import {TaxonomiasService} from '../taxonomias.module.service';
import {TDevExpressFilterOperationGroup} from '../../../components/devexpress/datalayer/filtering/devexpress.datalayer.filtering.interface';
import {IJsonEmpresaErp} from '../../../interfaces/jsonEmpresa.interface';
import {ETipoContabilidade} from '../../../datasources/tipocontabilidade/tipoContabilidade.datasource.interface';
import {MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID} from '../../portalcontabilidade/estatistica/extratosgrid/contabilidade.extratosGrid.module.interface';

@Component({
  selector: 'taxonomias',
  templateUrl: './taxonomias.module.component.html'
})
export class TaxonomiasModuleComponent extends ModuloComponent implements OnInit {
  @Input() public empresa: IJsonEmpresaErp;

  public readonly tabs: typeof ETaxonomiasTab;
  public readonly eTaxonomiasModoModalPoc: typeof ETaxonomiasModoModalPoc;
  public readonly tipoErro: string;
  public readonly definitionRelacaoContas: IDevExpressDataGrid;
  public readonly definitionInformacaoLegal: IDevExpressDataGrid;
  public readonly definitionPlanoOficial: IDevExpressDataGrid;
  public readonly definitionValidacoesSVAT: IDevExpressDataGrid;
  public readonly definitionValidacoesPlano: IDevExpressDataGrid;
  public readonly definitionValidacoesLancamentos: IDevExpressDataGrid;

  public activeId: ETaxonomiasTab;
  public svatFiltered: boolean;
  public existErrors: boolean;
  public existErrorsLancamentos: boolean;
  public titleTaxonomia: string;

  private readonly _maintenanceInstanceExtratosGrid: IModuleMaintenanceInstance;
  private readonly _maintenanceDocsContabilidade: IEntityMaintenanceInstance;
  private readonly _maintenanceTrfLancContas: IModuleMaintenanceInstance;
  private readonly _maintenanceClifos: IEntityMaintenanceInstance;
  private readonly _maintenancePocs: IEntityMaintenanceInstance;
  private readonly _btnGenerateXls: IPlToolbarItem;

  private _dataGridValidacoesPlanoInstance: dxDataGrid;
  private _dataGridValidacoesLancamentosInstance: dxDataGrid;
  private _validacoesLancamentos: Array<IJsonValidacoesLancamentos>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plALertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _taxonomiasService: TaxonomiasService
  ) {
    super(_injector);
    this.eTaxonomiasModoModalPoc = ETaxonomiasModoModalPoc;
    this._maintenanceInstanceExtratosGrid = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID);
    this.tabs = ETaxonomiasTab;
    this.tipoErro = this._translateService.instant('global.text.error');
    this.titleTaxonomia = '';
    this.definitionRelacaoContas = {
      columns: [
        {dataField: 'nContaDe', dataType: 'string', caption: 'taxonomias.relacaoContas.nContaDe'},
        {dataField: 'nContaAte', dataType: 'string', caption: 'taxonomias.relacaoContas.nContaAte'},
        {dataField: 'codigoTaxonomia', dataType: 'number', caption: 'taxonomias.relacaoContas.codTaxonomia'},
        {dataField: 'descricao', dataType: 'string', caption: 'taxonomias.relacaoContas.descricao'}
      ],
      dataSource: new CustomStore({
        key: 'codigoTaxonomia',
        load: () => this._getRelacaoContas()
      }),
      searchPanel: {
        visible: true
      },
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbartitle',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'revertButton',
          'searchPanel'
        ]
      }
    };
    this.definitionInformacaoLegal = {
      columns: [
        {dataField: 'codigoTaxonomia', dataType: 'number', caption: 'taxonomias.informacaoLegal.codTaxonomia'},
        {dataField: 'codSNC', dataType: 'string', caption: 'taxonomias.informacaoLegal.codSNC'},
        {dataField: 'descricao', dataType: 'string', caption: 'taxonomias.informacaoLegal.descricao'},
        {dataField: 'observacoes', dataType: 'string', caption: 'taxonomias.informacaoLegal.observacoes'},
        {dataField: 'classe', dataType: 'string', caption: 'taxonomias.informacaoLegal.classe'},
        {dataField: 'saldoEsperado', dataType: 'string', caption: 'taxonomias.informacaoLegal.saldoEsperado'},
        {dataField: 'demFinanceirasCampoDebito', dataType: 'string', caption: 'taxonomias.informacaoLegal.demFinanCampDeb'},
        {dataField: 'demFinanceirasCampoCredito', dataType: 'string', caption: 'taxonomias.informacaoLegal.demFinanCampCred'}
      ],
      dataSource: new CustomStore({
        key: 'codigoTaxonomia',
        load: () => this._getInformacaoLegal()
      }),
      searchPanel: {
        visible: true
      },
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbartitle',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'revertButton',
          'searchPanel'
        ]
      }
    };
    this.definitionPlanoOficial = {
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'taxonomias.planoOficial.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'taxonomias.planoOficial.nome'}
      ],
      dataSource: new CustomStore({
        key: 'nConta',
        load: () => this._getPlanoOficial()
      }),
      searchPanel: {
        visible: true
      },
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbartitle',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'revertButton',
          'searchPanel'
        ]
      }
    };
    const validacoesSVATFilterValue: TDevExpressFilterOperationGroup = [[['contaValida', '=', false], 'and', ['nConta', '<>', '']], 'or', [['saldoValido', '=', false], 'and', ['saldo', '<>', '']]];
    this.definitionValidacoesSVAT = {
      columns: [
        {
          fixed: true,
          fixedPosition: 'left',
          caption: 'taxonomias.validacoesSvat.tableFirstHeader.contasComSaldo',
          columns: [
            {
              caption: 'taxonomias.validacoesSvat.tableSecondHeader.contas',
              columns: [
                {dataField: 'nConta', dataType: 'string', caption: 'taxonomias.validacoesSvat.tableThirdHeader.nConta'},
                {dataField: 'nome', dataType: 'string', caption: 'taxonomias.validacoesSvat.tableThirdHeader.nome'},
                {dataField: 'tipo', dataType: 'string', caption: 'taxonomias.validacoesSvat.tableThirdHeader.tipo', lookup: {cgDataSource: DATA_SOURCE_NAME_TIPOS_POC}},
                {dataField: 'contaValida', dataType: 'boolean', visible: false, showInColumnChooser: false}
              ]
            }
          ]
        },
        {
          caption: 'taxonomias.validacoesSvat.tableFirstHeader.valSVAT',
          columns: [
            {
              caption: 'taxonomias.validacoesSvat.tableSecondHeader.contas',
              columns: [{dataField: 'conta', dataType: 'string', caption: 'taxonomias.validacoesSvat.tableThirdHeader.conta'}]
            },
            {
              caption: 'taxonomias.validacoesSvat.tableSecondHeader.saldos',
              columns: [
                {dataField: 'saldo', dataType: 'string', caption: 'global.text.saldo'},
                {dataField: 'codigoTaxonomia', dataType: 'number', caption: 'taxonomias.validacoesSvat.tableThirdHeader.codTaxonomia', format: {suppressZeros: true}},
                {dataField: 'descricao', dataType: 'string', caption: 'taxonomias.validacoesSvat.tableThirdHeader.descricao'},
                {dataField: 'classe', dataType: 'string', caption: 'taxonomias.validacoesSvat.tableThirdHeader.classe'},
                {dataField: 'saldoEsperado', dataType: 'string', caption: 'taxonomias.validacoesSvat.tableThirdHeader.saldoEsperado'},
                {dataField: 'dezembroNormal', dataType: 'double', caption: 'taxonomias.validacoesSvat.tableThirdHeader.dezembroNormal', format: {suppressZeros: true}},
                {dataField: 'dezembroPrimeiroEnc', dataType: 'double', caption: 'taxonomias.validacoesSvat.tableThirdHeader.dezembroFirst', format: {suppressZeros: true}},
                {dataField: 'dezembroSegundoEnc', dataType: 'double', caption: 'taxonomias.validacoesSvat.tableThirdHeader.dezembroSecond', format: {suppressZeros: true}},
                {dataField: 'saldoValido', dataType: 'boolean', visible: false, showInColumnChooser: false},
                {
                  dataField: 'actions',
                  caption: '',
                  cellTemplate: 'actions',
                  fixed: true,
                  fixedPosition: 'right',
                  allowEditing: false,
                  allowExporting: false,
                  allowFiltering: false,
                  allowGrouping: false,
                  allowHeaderFiltering: false,
                  allowHiding: true,
                  allowReordering: false,
                  allowResizing: false,
                  allowSearch: false,
                  allowSorting: false,
                  showInColumnChooser: false
                }
              ]
            }
          ]
        }
      ],
      dataSource: new CustomStore({
        key: 'nConta',
        load: () => {
          return this._getValidacoesSVAT();
        }
      }),
      columnHidingEnabled: false,
      filterSyncEnabled: false,
      filterValue: validacoesSVATFilterValue,
      searchPanel: {
        visible: true
      },
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbartitle',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'revertButton',
          'searchPanel'
        ]
      }
    };
    this.definitionValidacoesPlano = {
      columns: [
        {dataField: 'faseVerificacaoStr', dataType: 'string', caption: 'taxonomias.validacoesPlano.fase'},
        {dataField: 'conta', dataType: 'string', caption: 'taxonomias.validacoesPlano.conta'},
        {dataField: 'mensagem', dataType: 'string', caption: 'taxonomias.validacoesPlano.mensagem'},
        {dataField: 'mensagemCorrecaoAutomatica', dataType: 'string', caption: 'taxonomias.validacoesPlano.mensagemCorrecaoAutomatica'},
        {
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false
        }
      ],
      dataSource: new CustomStore({
        key: 'conta',
        load: () => this._getValidacoesPlano()
      }),
      searchPanel: {
        visible: true
      },
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbartitle',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'revertButton',
          'searchPanel'
        ]
      }
    };
    this.definitionValidacoesLancamentos = {
      columns: [
        {dataField: 'tipo', dataType: 'string', caption: 'taxonomias.validacoesLancamentos.tipo'},
        {dataField: 'fase', dataType: 'string', caption: 'taxonomias.validacoesLancamentos.fase'},
        {dataField: 'conta', dataType: 'string', caption: 'taxonomias.validacoesLancamentos.conta'},
        {dataField: 'periodo', dataType: 'string', caption: 'taxonomias.validacoesLancamentos.periodo'},
        {dataField: 'diario', dataType: 'string', caption: 'taxonomias.validacoesLancamentos.diario'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'taxonomias.validacoesLancamentos.numeroDocInterno'},
        {
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false
        }
      ],
      dataSource: new CustomStore({
        load: () => this._getValidacoesLancamentos()
      }),
      searchPanel: {
        visible: true
      },
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbartitle',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'revertButton',
          'searchPanel'
        ]
      },
      masterDetail: {
        enabled: true,
        template: 'detail',
        autoExpandAll: true
      }
    };
    this._maintenancePocs = this._entityMaintenanceService.build(ENTITY_NAME_POCS);
    this._maintenanceClifos = this._entityMaintenanceService.build(ENTITY_NAME_CLIFOS);
    this._maintenanceDocsContabilidade = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._maintenanceTrfLancContas = this._moduleMaintenanceService.build(MODULE_NAME_TRFLANCCONTAS);
    this._btnGenerateXls = {
      id: 'btnGenerateXls',
      order: 1,
      type: 'download',
      iconLeft: '<i class="fa fa-fw fa-file-excel-o"></i>',
      class: 'btn-success',
      caption: 'global.btn.exportXls',
      visible: true,
      download: {
        url: undefined
      }
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnGenerateXls);
    this.activeId = ETaxonomiasTab.RelacaoContas;
    this.svatFiltered = true;
    this.existErrors = false;
    this.existErrorsLancamentos = false;
    this._evaluateGenerateXlsUrl();
    this._loadGridsTitle();
  }

  public changedTab(tab: string | ETaxonomiasTab): void {
    this.activeId = <ETaxonomiasTab>tab;
    this._evaluateGenerateXlsUrl();
  }

  public onDataGridValidacoesPlanoInitialized(event: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridValidacoesPlanoInstance = event.component;
  }

  public onDataGridValidacoesSVATStateLoad(event: IDevExpressDataGridState): void {
    this.svatFiltered = event.filterPanel?.filterEnabled ?? true;
  }

  public onDataGridValidacoesLancamentosInitialized(event: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridValidacoesLancamentosInstance = event.component;
  }

  public onDataGridValidacoesLancamentosCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonValidacoesLancamentos>): void {
    devExpressDataGridExpandDetailHandler(event);
  }

  public refreshValidacoesPlano(): Promise<void> {
    return this._dataGridValidacoesPlanoInstance.refresh();
  }

  public aplicarCorrecoesDatas(): void {
    this._cgModalService.showOkCancel('taxonomias.autoCorrectDatasTitleOkCancel', 'taxonomias.autoCorrectDatasMessageOkCancel').then(() => {
      this._dataGridValidacoesLancamentosInstance.beginCustomLoading(undefined);
      this._taxonomiasService
        .aplicarCorrecoesDatas()
        .then((response: HttpResponse<Array<IJsonValidacoesLancamentos>>) => {
          this._plALertService.success('taxonomias.autoCorrectSuccess');
          this._validacoesLancamentos = response.body;
          return this._dataGridValidacoesLancamentosInstance.refresh();
        })
        .finally(() => {
          this._dataGridValidacoesLancamentosInstance.endCustomLoading();
        });
    });
  }

  public aplicarCorrecoesDocValor(): void {
    this._cgModalService.showOkCancel('taxonomias.autoCorrectDatasTitleOkCancel', 'taxonomias.autoCorrectMessageClasseDocValor').then(() => {
      this._dataGridValidacoesLancamentosInstance.beginCustomLoading(undefined);
      return this._taxonomiasService
        .aplicarCorrecoesAutomaticasDocsValor0()
        .then((response: HttpResponse<Array<IJsonValidacoesLancamentos>>) => {
          this._plALertService.success('taxonomias.autoCorrectSuccess');
          this._validacoesLancamentos = response.body;
          return this._dataGridValidacoesLancamentosInstance.refresh();
        })
        .finally(() => {
          this._dataGridValidacoesLancamentosInstance.endCustomLoading();
        });
    });
  }

  public aplicarCorrecoes(taxonomiasModoModalPoc: ETaxonomiasModoModalPoc): void {
    const modalInstance = this._cgModalService.showVanilla(ContaPocSaldaModalComponent);
    const componentInstance: ContaPocSaldaModalComponent = modalInstance.componentInstance;
    componentInstance.modoModalPoc = taxonomiasModoModalPoc;
    modalInstance.result.then((validacoesLancamentos: Array<IJsonValidacoesLancamentos>) => {
      this._plALertService.success('taxonomias.autoCorrectSuccess');
      this._validacoesLancamentos = validacoesLancamentos;
      return this._dataGridValidacoesLancamentosInstance.refresh();
    });
  }

  public readonly fnOpenManutencaoPlanoContas: (nConta: string) => () => Promise<void> = (nConta: string) => (): Promise<void> => this._openManutencaoPlanoContas(nConta);

  public readonly fnOpenCriarContaTerceiro: (nConta: string) => () => Promise<void> = (nConta: string) => (): Promise<void> => this._criarContaTerceiro(nConta);

  public readonly fnOpenMaintenanceExtratosGrid: (nConta: string) => () => Promise<void> = (nConta: string) => (): Promise<void> => this._openMaintenanceExtratosGrid(nConta);

  public readonly fnOpenTrfConta: (nConta: string) => () => Promise<void> = (nConta: string) => (): Promise<void> => this._openTrfConta(nConta);

  public readonly fnOpenDoc: (extPocCabId: string) => () => Promise<void> = (extPocCabId: string) => (): Promise<void> => this._openDoc(extPocCabId);

  public readonly fnAplicarCorrecoesAuto = (): Promise<void> => this._aplicarCorrecoesAuto();

  private _loadGridsTitle(): void {
    switch (this.empresa.codTipoContab) {
      case ETipoContabilidade.OrganizadaComSNCME:
        this.titleTaxonomia = this._translateService.instant('taxonomias.grids.title', {tipoTaxonomia: 'M', tipoContabilidade: this.empresa.tipoContab});
        break;
      case ETipoContabilidade.OrganizadaComSNC:
      case ETipoContabilidade.OrganizadaComSNCPE:
        this.titleTaxonomia = this._translateService.instant('taxonomias.grids.title', {tipoTaxonomia: 'S', tipoContabilidade: this.empresa.tipoContab});
        break;
      default:
        this.titleTaxonomia = this._translateService.instant('taxonomias.grids.title', {tipoTaxonomia: 'O', tipoContabilidade: this.empresa.tipoContab});
        break;
    }
  }

  private _getRelacaoContas(): Promise<Array<IJsonRelacaoContasTaxonomia>> {
    return this._taxonomiasService.getRelacaoContas().then((response: HttpResponse<Array<IJsonRelacaoContasTaxonomia>>) => response.body);
  }

  private _getInformacaoLegal(): Promise<Array<IJsonInfoLegal>> {
    return this._taxonomiasService.getInfoLegal().then((response: HttpResponse<Array<IJsonInfoLegal>>) => response.body);
  }

  private _getPlanoOficial(): Promise<Array<IJsonPlanoOficialPublicado>> {
    return this._taxonomiasService.getPlanoOficial().then((response: HttpResponse<Array<IJsonPlanoOficialPublicado>>) => response.body);
  }

  private async _getValidacoesSVAT(): Promise<Array<IJsonValidacoesSVAT>> {
    return this._taxonomiasService.getValidacoesSvat().then((response: HttpResponse<Array<IJsonValidacoesSVAT>>) => response.body);
  }

  private _getValidacoesPlano(): Promise<Array<IJsonValidacoesPlano>> {
    return this._taxonomiasService.getValidacoesPlano().then((response: HttpResponse<Array<IJsonValidacoesPlano>>) => {
      this.existErrors = response.body.length > 0;
      return response.body;
    });
  }

  private async _getValidacoesLancamentos(): Promise<Array<IJsonValidacoesLancamentos>> {
    if (this._validacoesLancamentos) {
      const validacoesLancamentos: Array<IJsonValidacoesLancamentos> = this._validacoesLancamentos;
      this._validacoesLancamentos = undefined;
      return Promise.resolve(validacoesLancamentos);
    }
    await this._cgModalService.showOkCancel('global.text.confirmation', 'taxonomias.confirmValidacoesLancamentos');
    const response: HttpResponse<Array<IJsonValidacoesLancamentos>> = await this._taxonomiasService.getValidacoesLancamentos();
    this.existErrorsLancamentos = response.body.length > 0;
    return response.body;
  }

  private _evaluateGenerateXlsUrl(): void {
    this._taxonomiasService.exportToXlsUrl(this.activeId).subscribe((url: string) => {
      this._btnGenerateXls.download.url = url;
    });
  }

  private _aplicarCorrecoesAuto(): Promise<void> {
    return this._dataGridValidacoesPlanoInstance
      .getDataSource()
      .store()
      .load()
      .then((validacoesPlano: Array<IJsonValidacoesPlano>) => {
        return this._taxonomiasService.aplicarCorrecoes(validacoesPlano).then(() => this._dataGridValidacoesPlanoInstance.refresh());
      });
  }

  private async _openManutencaoPlanoContas(nConta: string): Promise<void> {
    await this._maintenancePocs.maintenanceEdit(nConta);
    if (this._dataGridValidacoesPlanoInstance) {
      await this._dataGridValidacoesPlanoInstance.refresh();
    }
  }

  private async _criarContaTerceiro(nConta: string): Promise<void> {
    await this._taxonomiasService.criarContaTerceiro(nConta);
    await this._cgModalService.showOkCancel('global.text.confirmation', 'taxonomias.validacoesPlano.contaterceirosuccess');
    try {
      await this._maintenanceClifos.maintenanceEdit(nConta);
    } catch (error: unknown) {
      this._logger.error(error);
    }
    if (this.activeId === ETaxonomiasTab.ValidacoesPlano) {
      await this._dataGridValidacoesPlanoInstance.refresh();
    }
  }

  private _openTrfConta(nConta: string): Promise<void> {
    const params: ITrfLancContasModuleStateParams = {nContaOrigem: nConta};
    return this._maintenanceTrfLancContas.maintenance({params: params, modalOptions: {size: 'xl'}});
  }

  private _openDoc(extPocCabId: string): Promise<void> {
    return this._maintenanceDocsContabilidade.maintenanceEdit(extPocCabId).then(() => this._dataGridValidacoesLancamentosInstance.refresh());
  }

  private _openMaintenanceExtratosGrid(nConta: string): Promise<void> {
    return this._maintenanceInstanceExtratosGrid.maintenance({params: {nConta: nConta}, modalOptions: {size: 'xxl'}});
  }
}
