import {Component, Injector} from '@angular/core';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {IGrupoDocfaConfiguracoesCallback} from '../../../components/docscomerciais/grupoDocfaConfiguracoes/grupoDocfaConfiguracoes.component.interface';

@Component({
  selector: 'grupo-docfa-configuracoes-modal',
  templateUrl: './grupoDocfaConfiguracoes.modal.component.html'
})
export class GrupoDocfaConfiguracoesModalComponent extends CGModalComponent<void> {
  public grupoDocfaConfigCallback: IGrupoDocfaConfiguracoesCallback;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.grupoDocfaConfigCallback = {};
  }

  public async save(): Promise<void> {
    await new Promise((resolve) => {
      this.grupoDocfaConfigCallback.save();
      setTimeout(resolve, 0);
    });

    this.close();
  }
}
