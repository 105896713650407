<div class="contabdigital-adicionar-documentos-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.adicionardocumentos.escolhadocumentos'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <edit>
        <pl-upload
          [url]="uploadUrl"
          [autoProcessQueue]="true"
          [hideActionCancel]="true"
          [hideActionRetry]="true"
          [hideActionUploadAll]="true"
          [hideActionUpload]="true"
          [maxFiles]="maxFiles"
          [maxFileSize]="maxFileSize"
          [parallelUploads]="maxFiles"
          [params]="params"
          [resizeTargetSize]="maxFileSize"
          resizeMethod="contain"
          [resizeBeforeAccept]="true"
          [uploadMultiple]="true"
          [zip]="true"
          [zipStandalone]="false"
          [callback]="callback"
          (evtAcceptedFile)="evaluateAllowClose()"
          (evtRejectedFile)="evaluateAllowClose()"
          (evtRemovedFile)="evaluateAllowClose()"
          (evtFinishedUpload)="finishedUpload()"
          acceptedFiles=".pdf,.png,.jpg">
        </pl-upload>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-light" [disabled]="closeDisabled" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</div>
