import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {TrfBaService} from '../../trfBa.module.service';
import {ETemplateSEPASalarios, IJsonTrfBaConfig, IJsonTrfBaConfigIBAN} from '../../jsonTrfBa.module.interface';
import {merge} from 'lodash-es';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {HttpResponse} from '@angular/common/http';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {RADIO_GROUP_TRF_BA_CONFIG_TEMPLATE} from '../../trfBa.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import ArrayStore from 'devextreme/data/array_store';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';

@Component({
  selector: 'modal-trfba-config',
  templateUrl: './trfBa.config.modal.component.html'
})
export class TrfBaConfigModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public trfBaConfig: IJsonTrfBaConfig;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly radioGroupTemplate: IRadioGroup<ETemplateSEPASalarios>;

  public promise: Promise<void>;
  public iban: string;
  public outraIDPrivada: string;

  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _trfBaService: TrfBaService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'iban', dataType: 'string', caption: 'trfba.modal.config.fields.iban'},
        {dataField: 'outraIDPrivada', dataType: 'string', caption: 'trfba.modal.config.fields.outraIDPrivada'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn'}
      ],
      dataSource: [],
      export: {filename: 'trfba.btn.configSEPA'},
      remoteOperations: false
    };
    this.radioGroupTemplate = RADIO_GROUP_TRF_BA_CONFIG_TEMPLATE;
  }

  public ngOnInit(): void {
    this.trfBaConfig = merge(
      {
        regTempSal: ETemplateSEPASalarios.Geral,
        trfBaConfigIBANList: []
      },
      this.trfBaConfig
    );
    this.dataGridDefinition.dataSource = new ArrayStore({key: 'iban', data: this.trfBaConfig.trfBaConfigIBANList});
  }

  public addLine(): Promise<void> {
    if (isEmpty(this.iban)) {
      this._plAlertService.error('trfba.messages.ibannulo');
      return Promise.resolve();
    }

    let promise: Promise<void>;
    if (this.trfBaConfig.trfBaConfigIBANList.findIndex((value: IJsonTrfBaConfigIBAN) => value.iban === this.iban) !== -1) {
      promise = Promise.resolve(this._cgModalService.showOkCancel('global.text.confirmation', 'trfba.messages.ibanjaconfig'));
    }
    const trfBaConfigIBAN: IJsonTrfBaConfigIBAN = {
      iban: this.iban,
      outraIDPrivada: this.outraIDPrivada
    };
    return Promise.resolve(promise).then(() => {
      this._dataGridInstance.beginCustomLoading(undefined);
      this.promise = this._trfBaService
        .adicionarConfigIBAN(trfBaConfigIBAN)
        .then((response: HttpResponse<Array<IJsonTrfBaConfigIBAN>>) => {
          this.iban = '';
          this.outraIDPrivada = '';
          this.trfBaConfig.trfBaConfigIBANList = response.body;
          this.dataGridDefinition.dataSource = new ArrayStore({key: 'iban', data: this.trfBaConfig.trfBaConfigIBANList});
          this._plAlertService.success('trfba.messages.linhaaddsuccess');
        })
        .finally(() => {
          this._dataGridInstance.endCustomLoading();
        });
      return this.promise;
    });
  }

  public removeIban(line: IJsonTrfBaConfigIBAN): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    this.promise = this._trfBaService
      .eliminaConfigIBAN(line)
      .then((response: HttpResponse<Array<IJsonTrfBaConfigIBAN>>) => {
        this.trfBaConfig.trfBaConfigIBANList = response.body;
        this.dataGridDefinition.dataSource = new ArrayStore({key: 'iban', data: this.trfBaConfig.trfBaConfigIBANList});
        this._plAlertService.success('trfba.messages.linharemsuccess');
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
    return this.promise;
  }

  public templateChanged(): Promise<void> {
    this.promise = this._trfBaService.configTemplate(this.trfBaConfig.regTempSal).then(() => {
      this._plAlertService.success('trfba.messages.templatealterado');
    });
    return this.promise;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }
}
