import {TDate} from '../../../common/dates';

export enum ETipoEscolhaRendHabitPerm {
  Ativas,
  Inativas,
  Todas
}

export enum ETipoOrdenacao {
  CodEmp,
  Nome
}

export interface IJsonRendHabitPerm {
  rendHabitPermId: string;
  codAbdes: string;
  abdesNome: string;
  codEmp: string;
  nome: string;
  valorRenda: number;
  valorRendaMaxMuni: number;
  anoProcessamento: number;
  mesInicioProce: number;
  observacoes: string;
  suspende: boolean;
  dataSuspensao: TDate;
  obsSuspensao: string;
  tipoProcessamento: number;
  valorIncidendiaIRS: number;
  tipoProcessamentoStr: string;
}
