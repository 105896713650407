import {Injectable} from '@angular/core';
import {ContabilidadeDigitalService} from '../../../services/contabilidadedigital/contabilidadedigital.service';
import {ComercialDigitalServiceDocuments} from './comercialDigital.service.documents';
import {ComercialDigitalServiceConfigs} from './comercialDigital.service.configs';

@Injectable({
  providedIn: 'root'
})
export class ComercialDigitalService extends ContabilidadeDigitalService<ComercialDigitalServiceDocuments, ComercialDigitalServiceConfigs> {
  protected _buildPath(): string {
    return `${this._apiService.path.restapi}/comercialdigital`;
  }

  protected _buildDocuments(): ComercialDigitalServiceDocuments {
    return new ComercialDigitalServiceDocuments(this._apiService, this._path);
  }

  protected _buildConfigs(): ComercialDigitalServiceConfigs {
    return new ComercialDigitalServiceConfigs(this._apiService, this._configStoreService, this._path);
  }
}
