import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_VALORIZACAO, ETipoValorizacao} from './tipoValorizacao.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPOS_VALORIZACAO: IDataSource<ETipoValorizacao> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_VALORIZACAO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{label}}',
    output: 'label'
  },
  data: [
    {value: ETipoValorizacao.CustoMedioPonderado, label: 'tipoValorizacao.data.custoMedioPonderado'},
    {value: ETipoValorizacao.CustoMedio, label: 'tipoValorizacao.data.custoMedio'},
    {value: ETipoValorizacao.PrecoUltimaCompra, label: 'tipoValorizacao.data.precoUltimaCompra'},
    {value: ETipoValorizacao.CustoPadrao, label: 'tipoValorizacao.data.custoPadrao'},
    {value: ETipoValorizacao.PrecoBaseCusto, label: 'tipoValorizacao.data.precoBaseCusto'}
  ]
});
