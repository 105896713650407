export enum ETaxonomiasTab {
  RelacaoContas,
  InformacaoLegal,
  PlanoOficial,
  ValidacoesSvat,
  ValidacoesPlano,
  ValidacoesLancamentos
}

export enum ETaxonomiasModoModalPoc {
  ModalContaNormal,
  ModalContaAnalita,
  ModalContaClasse0
}

export const MODULE_NAME_TAXONOMIAS = 'taxonomias';
