<pl-form [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'arquivodigital.atribuirtipodocumentomodal.title'"></h4>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'arquivodigital.atribuirtipodocumentomodal.label'"></label>
      <edit>
        <pl-autocomplete
          [source]="invoiceTypes"
          [model]="invoiceType"
          (modelChange)="changedInvoiceType($event)"
          [allowInvalid]="false"
          [properties]="{validators: {required: {value: true, message: 'arquivodigital.atribuirtipodocumentomodal.required'}}}"
          rowTemplate="label"
          output="value">
        </pl-autocomplete>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn-primary action-submit"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

    <pl-button klass="btn-light action-cancel" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</pl-form>
