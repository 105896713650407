export enum EComercialChartType {
  VendasCompras,
  Clientes,
  PieVendas,
  PieClientes
}

export enum EComercialVendasCompras {
  Vendas,
  Compras,
  AnoAtual,
  AnoAnterior
}

export interface IDashboardComercialVendasCompras {
  month: string;
  valueVenda: number;
  valueCompra: number;
  valueDif: number;
}

export interface IDashboardComercialClientes {
  month: string;
  valueClientes: number;
  valueClientesAnoAnterior: number;
}

export interface IDashboardComercialPieVendas {
  name: string;
  value: number;
  percent: number;
}

export interface IDashboardComercialPieClientes {
  name: string;
  value: number;
  percent: number;
}

export const MODULE_NAME_DASHBOARDS_ERP = 'dashboarderp';
