<div class="inventarios-stock entity-detail-form">
  <ng-container *ngIf="view === viewTypes.NEW">
    <cg-card-panel #cardPanel="cgCardPanel" [collapsed]="false" [caption]="'global.text.searchfilter'">
      <div *cgCardPanelContent>
        <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>
        <pl-form [properties]="{readonly: invec.invecID > 0}" class="px-2 py-1" [submit]="fnPesquisar" plPromise>
          <pl-group>
            <pl-group>
              <label [translate]="'invec.fields.deNArtigo'"></label>
              <edit>
                <entity-autocomplete
                  entity="artigos"
                  attrName="deArtigo"
                  [model]="invec"
                  [(selectedKey)]="invec.deNArtigo"
                  [fieldsMap]="{nArtigo: 'deNArtigo'}"
                  [helperMode]="true"
                  output="key"
                  plAutoFocus>
                </entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete entity="artigos" attrName="ateArtigo" [model]="invec" [(selectedKey)]="invec.ateNArtigo" [fieldsMap]="{nArtigo: 'ateNArtigo'}" [helperMode]="true" output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'invec.fields.deNArmazem'"></label>
              <edit>
                <entity-autocomplete entity="armazens" attrName="deArmazem" [model]="invec" [(selectedKey)]="invec.deNArmazem" [fieldsMap]="{nArmazem: 'deNArmazem'}" [helperMode]="true" output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <entity-autocomplete
                  entity="armazens"
                  attrName="ateArmazem"
                  [model]="invec"
                  [(selectedKey)]="invec.ateNArmazem"
                  [fieldsMap]="{nArmazem: 'ateNArmazem'}"
                  [helperMode]="true"
                  output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
          <pl-group>
            <pl-group *ngIf="optionShowTipoArtigo">
              <pl-group>
                <label [translate]="'invec.fields.deTipoArtigo'"></label>
                <edit>
                  <entity-autocomplete
                    entity="tiposartigo"
                    attrName="deTipoArtigo"
                    [model]="invec"
                    [(selectedKey)]="invec.deTipoArtigo"
                    [fieldsMap]="{codTpArt: 'deTipoArtigo'}"
                    [helperMode]="true"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'global.text.to'"></label>
                <edit>
                  <entity-autocomplete
                    entity="tiposartigo"
                    attrName="ateTipoArtigo"
                    [model]="invec"
                    [(selectedKey)]="invec.ateTipoArtigo"
                    [fieldsMap]="{codTpArt: 'ateTipoArtigo'}"
                    [helperMode]="true"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>
            <pl-group *ngIf="optionShowGrandeFamilia">
              <pl-group>
                <label [translate]="'invec.fields.deGrandeFamilia'"></label>
                <edit>
                  <entity-autocomplete
                    entity="grandesfamilias"
                    attrName="deTipoArtigo"
                    [model]="invec"
                    [(selectedKey)]="invec.deGrandeFamilia"
                    [fieldsMap]="{nDepart: 'deGrandeFamilia'}"
                    [helperMode]="true"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'global.text.to'"></label>
                <edit>
                  <entity-autocomplete
                    entity="grandesfamilias"
                    attrName="ateTipoArtigo"
                    [model]="invec"
                    [(selectedKey)]="invec.ateGrandeFamilia"
                    [fieldsMap]="{nDepart: 'ateGrandeFamilia'}"
                    [helperMode]="true"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>
          </pl-group>
          <pl-group>
            <pl-group>
              <pl-group>
                <label [translate]="'invec.fields.deFamilia'"></label>
                <edit>
                  <entity-autocomplete entity="familias" attrName="deFamilia" [model]="invec" [(selectedKey)]="invec.deFamilia" [fieldsMap]="{nFamilia: 'deFamilia'}" [helperMode]="true" output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'global.text.to'"></label>
                <edit>
                  <entity-autocomplete
                    entity="familias"
                    attrName="ateFamilia"
                    [model]="invec"
                    [(selectedKey)]="invec.ateFamilia"
                    [fieldsMap]="{nFamilia: 'ateFamilia'}"
                    [helperMode]="true"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>
            <pl-group *ngIf="optionShowSubFamilia">
              <pl-group>
                <label [translate]="'invec.fields.deSubFamilia'"></label>
                <edit>
                  <entity-autocomplete
                    entity="subfamilias"
                    attrName="deSubFamilia"
                    [model]="invec"
                    [(selectedKey)]="invec.deSubFamilia"
                    [fieldsMap]="{nSubFa: 'deSubFamilia'}"
                    [helperMode]="true"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'global.text.to'"></label>
                <edit>
                  <entity-autocomplete
                    entity="subfamilias"
                    attrName="ateSubFamilia"
                    [model]="invec"
                    [(selectedKey)]="invec.ateSubFamilia"
                    [fieldsMap]="{nSubFa: 'ateSubFamilia'}"
                    [helperMode]="true"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>
          </pl-group>
          <pl-group>
            <pl-group *ngIf="optionShowClasse">
              <pl-group>
                <label [translate]="'invec.fields.deClasse'"></label>
                <edit>
                  <entity-autocomplete entity="artigoclasses" attrName="deClasse" [model]="invec" [(selectedKey)]="invec.deClasse" [fieldsMap]="{classe: 'deClasse'}" [helperMode]="true" output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'global.text.to'"></label>
                <edit>
                  <entity-autocomplete
                    entity="artigoclasses"
                    attrName="ateClasse"
                    [model]="invec"
                    [(selectedKey)]="invec.ateClasse"
                    [fieldsMap]="{classe: 'ateClasse'}"
                    [helperMode]="true"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>
            <pl-group *ngIf="optionShowCategoria">
              <pl-group>
                <label [translate]="'invec.fields.deCategoria'"></label>
                <edit>
                  <entity-autocomplete
                    entity="categorias"
                    attrName="deCategoria"
                    [model]="invec"
                    [(selectedKey)]="invec.deCategoria"
                    [fieldsMap]="{nCateg: 'deCategoria'}"
                    [helperMode]="true"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'global.text.to'"></label>
                <edit>
                  <entity-autocomplete
                    entity="categorias"
                    attrName="ateCategoria"
                    [model]="invec"
                    [(selectedKey)]="invec.ateCategoria"
                    [fieldsMap]="{nCateg: 'ateCategoria'}"
                    [helperMode]="true"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>
          </pl-group>
          <pl-group>
            <pl-group *ngIf="optionShowValorimetria">
              <pl-group>
                <label [translate]="'invec.fields.deValorimetria'"></label>
                <edit>
                  <pl-autocomplete
                    [source]="metodoSourceCriterio"
                    attrName="deValorimetria"
                    [(model)]="invec.deValorimetria"
                    [properties]="{validators: {required: {value: false}}}"
                    [rowTemplate]="mtdOutput"
                    output="valueMetodo">
                  </pl-autocomplete>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'global.text.to'"></label>
                <edit>
                  <pl-autocomplete
                    [source]="metodoSourceCriterio"
                    attrName="ateValorimetria"
                    [(model)]="invec.ateValorimetria"
                    [properties]="{validators: {required: {value: false}}}"
                    [rowTemplate]="mtdOutput"
                    output="valueMetodo">
                  </pl-autocomplete>
                </edit>
              </pl-group>
            </pl-group>
            <pl-group>
              <pl-group *ngIf="this.view === viewTypes.NEW">
                <label [translate]="'invec.fields.dataRef'"></label>
                <edit>
                  <pl-edit-datepicker [model]="invec.data" (modelChange)="onDataChanged($event)" [properties]="{disallowClear: true}"></pl-edit-datepicker>
                </edit>
              </pl-group>
              <pl-group [properties]="{orientation: 'horizontal'}" class="align-self-end">
                <div class="d-flex mt-3 flex-column flex-sm-row" style="gap: 1rem">
                  <div>
                    <edit>
                      <pl-edit-checkbox [model]="invec.porLote" (modelChange)="onPorLoteChanged($event)" [properties]="{label: 'invec.fields.porLote'}"></pl-edit-checkbox>
                    </edit>
                  </div>

                  <div>
                    <edit>
                      <pl-edit-checkbox [(model)]="invec.desactivados" [properties]="{label: 'invec.fields.desativados'}"></pl-edit-checkbox>
                    </edit>
                  </div>

                  <div>
                    <edit>
                      <pl-edit-checkbox [(model)]="invec.stockNulo" [properties]="{label: 'invec.fields.stockNulo'}"></pl-edit-checkbox>
                    </edit>
                  </div>
                </div>
              </pl-group>
            </pl-group>
          </pl-group>
          <pl-group class="align-self-end">
            <edit>
              <button type="submit" class="btn btn-sm btn-primary" name="pesquisar" id="btnPesquisar">
                <i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'extractosartigos.pesquisa'"></span>
              </button>
            </edit>
          </pl-group>
        </pl-form>
      </div>
    </cg-card-panel>
  </ng-container>

  <ng-container *ngIf="view === viewTypes.SAVED">
    <div class="d-flex flex-wrap mb-1" style="align-items: center; gap: 8px">
      <div class="d-flex" style="align-items: center; width: 100%; max-width: 370px">
        <label class="me-1" style="margin-bottom: 0">Inventários:</label>
        <edit style="max-width: 500px; width: 100%">
          <entity-autocomplete entity="invec" [rowTemplate]="inventarioStockTmpl" (modelChange)="inventarioStockChanged($event)" [model]="inventarioStock"></entity-autocomplete>
        </edit>
      </div>
      <div>
        <pl-toolbar [instanceId]="toolbarInstanceIdInventario"></pl-toolbar>
      </div>
    </div>
  </ng-container>

  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="inventariosStock" [dataSource]="dataGridDefinition.dataSource" (onInitialized)="onInitialized($event)">
    <div *dxTemplate="let item of 'toolbarTemplateDataGrid'">
      <div class="mt-1" *ngIf="view === viewTypes.SAVED && inventarioStock"><label>Data:</label>{{ inventarioStock?.data | cgcDate }}</div>
    </div>

    <div *dxTemplate="let item of 'actions'" class="text-center">
      <pl-tooltip [config]="{text: 'inven.tooltips.fichaArtigo', container: 'body'}">
        <button *plTooltipContent type="button" class="btn btn-primary btn-xs" [click]="fnFichaArtigo(item.data)" plPromise>
          <i class="fa fa-tags" aria-hidden="true"></i>
        </button>
      </pl-tooltip>

      <pl-tooltip [config]="{text: 'inven.tooltips.extratoArtigo', container: 'body'}">
        <button *plTooltipContent type="button" class="btn btn-info btn-xs ms-1" [click]="fnExtratoArtigo(item.data)" plPromise>
          <i class="fa fa-list-alt" aria-hidden="true"></i>
        </button>
      </pl-tooltip>
    </div>
  </dx-data-grid>

  <label>#{{ linhasInven.length }}</label>
  <div class="float-end">
    <label>Valor total: {{ saldoTotal | cgcDecimal }}</label>
  </div>
</div>
