<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'docscomerciais.auxiliares.motivoTitle'"></h4>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label class="control-label" [translate]="'docscomerciais.auxiliares.motivo'"></label>
      <edit>
        <textarea maxlength="50" [rows]="5" class="form-control" [(ngModel)]="motivo" required></textarea>
      </edit>
    </div>
    <div>
      <span
        [ngClass]="{'text-success': motivo.length < 45, 'text-warning': motivo.length > 44 && motivo.length < 50, 'text-danger': motivo.length === 50}"
        [translate]="'documentoFaturacao.modals.encerrar.caracteres'"
        [translateParams]="{numChar: motivo.length}"></span>
    </div>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-danger btn-sm" name="btnAnular" [disabled]="closeDisabled" [click]="close" plPromise>
      <i class="fa fa-scissors"></i>&nbsp;<span [translate]="'docscomerciais.auxiliares.anular'"></span>
    </button>

    <button type="button" class="btn btn-light btn-sm action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
      <i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span>
    </button>
  </div>
</pl-form>
