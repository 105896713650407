export enum ETrfLancContasSteps {
  INTRO = 'TRFLANCCONTAS_INTRO',
  CONTAS = 'TRFLANCCONTAS_CONTAS',
  FINALIZAR = 'TRFLANCCONTAS_FINALIZAR'
}

export interface IJsonTrfLancContas {
  nContaOrigem: string;
  nContaDestino: string;
  nomeContaDestino: string;
  manterContaOrigem: boolean;
  dependencias: Array<IJsonDependencias>;
}

export interface IJsonDependencias {
  nConta: string;
  nome: string;
  nContaGeralReal: string;
  valorMascara: string;
  radicalMascara: string;
}
