<div class="gestao-comunicacoes-config-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'global.btn.config'"></h4>
  </div>

  <div class="modal-body">
    <pl-tabs>
      <pl-tab [id]="'empresas'" [caption]="'gestaoComunicacoes.modal.config.selectEmpresa.title'">
        <div *plTabContent>
          <pl-multiselect
            [model]="configEmpresas.empresasExcluidas"
            [source]="configEmpresas.empresasPossiveis"
            key="nEmpresa"
            [template]="templateEmpresa"
            [properties]="{leftCaption: 'gestaoComunicacoes.modal.config.selectEmpresa.leftCaption', rightCaption: 'gestaoComunicacoes.modal.config.selectEmpresa.rightCaption'}">
          </pl-multiselect>
        </div>
      </pl-tab>
      <pl-tab [id]="'utilizadores'" [caption]="'gestaoComunicacoes.modal.config.selectUtilizador.title'">
        <div *plTabContent>
          <pl-multiselect
            [model]="configEmpresas.utilizadoresSelecionados"
            [source]="configEmpresas.utilizadoresPossiveis"
            key="nUtilizador"
            [template]="templateUtilizador"
            [properties]="{leftCaption: 'gestaoComunicacoes.modal.config.selectUtilizador.leftCaption', rightCaption: 'gestaoComunicacoes.modal.config.selectUtilizador.rightCaption'}">
          </pl-multiselect>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-primary btn-sm action-submit" (evtClicked)="close()" [disabled]="closeDisabled"
      ><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
    </pl-button>
    <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
