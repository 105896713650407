import {EnvironmentProviders, makeEnvironmentProviders} from '@angular/core';
import {DI_CGC_ICON_PREFIXES} from './icon.config.interface';
import {isArray} from '../../common/utilities/utilities';

const iconPrefixes: Array<[string, string]> = [
  ['fa-', 'fa'],
  ['pl-icon-', 'pl-icon']
];

export function provideIconPrefixes(prefixes?: Array<[string, string]>): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: DI_CGC_ICON_PREFIXES,
      useValue: isArray(prefixes) ? prefixes : iconPrefixes
    }
  ]);
}
