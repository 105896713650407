import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonDocComercial} from '../../jsonDocComercial.entity.interface';
import {DocumentosService} from '../../service/documentos.entity.service';

@Component({
  selector: 'documento-detail-modal',
  templateUrl: './documento.detail.modal.component.html'
})
export class DocumentoDetailModalComponent extends CGModalComponent<void> {
  @Input() public doc: IJsonDocComercial;

  constructor(
    protected readonly _injector: Injector,
    private readonly _documentosService: DocumentosService
  ) {
    super(_injector);
  }

  public readonly fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this.getPdf(doc);
  public readonly fnAnularDocumento = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.anularDocumento(doc);
  public readonly fnAnexarGDocComercial = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.anexarGDocComercial(doc);

  public getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc, doc.cab.nDocumento === 0 || !doc.cab.terminado);
  }

  public anexarGDocComercial(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.anexarGDocComercial(doc);
  }

  public anularDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.anularDocumento(doc).then((result) => {
      return result;
    });
  }
}
