import {Component, Injector, OnInit} from '@angular/core';
import {isArray} from 'pl-comps-angular';
import {INoAuthorityModuleStateParams} from '../noauthority.module.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {ROLE} from '../../../services/role.const';

@Component({
  selector: 'no-authority-module',
  templateUrl: './noauthority.module.component.html'
})
export class NoAuthorityModuleComponent extends ModuloComponent implements OnInit {
  public roles: Array<ROLE>;

  private readonly _params: INoAuthorityModuleStateParams;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._params = <INoAuthorityModuleStateParams>this._transition.params();
    this.roles = isArray(this._params.roles) ? this._params.roles : [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.setCaption(this._params.pageTitle);
  }
}
