import {Component, Injector, Input, OnInit} from '@angular/core';
import {copy, PlI18nService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {IJsonMoedaCambio} from '../../jsonMoeda.interface';
import {TDate} from '../../../../../../../common/dates';
import moment, {MomentInput} from 'moment';

@Component({
  selector: 'moeda-edit-cambio-modal',
  templateUrl: './moeda.edit.cambio.modal.component.html'
})
export class MoedaEditCambioModalComponent extends CGModalComponent<IJsonMoedaCambio> implements OnInit {
  @Input() public model: IJsonMoedaCambio;
  @Input() public onEdit: boolean;
  @Input() public list: Array<IJsonMoedaCambio>;

  public myModel: IJsonMoedaCambio;
  public disabledDates: Array<MomentInput>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plI18nService: PlI18nService
  ) {
    super(_injector);
    this.list = [];
    this.disabledDates = [];
    this.onEdit = false;
  }

  public ngOnInit(): void {
    this.myModel = copy(this.model);
    this.disabledDates = (this.onEdit ? this.list.filter((item) => !moment(item.dataReferencia).isSame(this.model.dataReferencia)) : this.list).map((item) => {
      return moment(item.dataReferencia);
    });
  }

  public getDateValue(value: TDate): string {
    return this._plI18nService.formatDate(value);
  }
}
