import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {Observable} from 'rxjs';
import {ApiService} from '../../../../../services/api/api.service';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {buildSessionUrlWithParams} from '../../../../../../common/utils/utils';

@Component({
  selector: 'proce-dmr-at-print-modal',
  templateUrl: './proceDMRAT.print.modal.component.html'
})
export class ProceDMRATPrintModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public modalTitle: string;

  public reportModel: IJsonReport;
  public pdfUrl: string;
  public reportType: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _apiService: ApiService,
    private readonly _reportsRegistryService: ReportsRegistryService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this.reportType = EReport.RHProceDMRATMapa;
    this._loadDefaultReport().then(() => {
      this.loadPdf();
    });
  }

  public onReportChange(report: IJsonReport): void {
    this.reportModel = report;
    this.loadPdf();
  }

  public loadPdf(): void {
    this._getPdfUrl().subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }

  private _getPdfUrl(): Observable<string> {
    return buildSessionUrlWithParams(`${this._apiService.path.restapi}/rhprocedmrat/report/pdf`, {reportName: this.reportModel.name});
  }

  private _loadDefaultReport(): Promise<void> {
    this.reportModel = {
      name: this._configService.configurations.rh.proceDMRAT.reports.reportImpressao,
      title: this._configService.configurations.rh.proceDMRAT.reports.reportImpressao
    };

    return this._reportsRegistryService
      .get(EReport.RHProceDMRATMapa)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          let foundDefault = false;
          for (const report of reports) {
            if (report.name.toUpperCase() === this.reportModel.name.toUpperCase()) {
              this.reportModel = {name: report.name, title: report.title};
              foundDefault = true;
              break;
            }
          }

          if (!foundDefault) {
            this.reportModel = {
              name: reports[0].name,
              title: reports[0].title
            };
          }
        }
      });
  }
}
