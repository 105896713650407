<entity-autocomplete
  [entity]="options.entity.name"
  [attrName]="attrName"
  [model]="value"
  (modelChange)="changedValue($event)"
  (selectedKeyChange)="changedKey($event)"
  (evtSelectedDescriptionChanged)="changedDescription($event)"
  [output]="options.entity.output || options.entity.outputTarget"
  [outputKey]="options.entity.outputKey"
  [outputDescription]="options.entity.outputDescription"
  [getDataConfig]="options.getDataConfig"
  [getItemConfig]="options.getItemConfig"
  [filter]="options.filter || options.entity.filter"
  [filterFields]="options.filterFields"
  [placeholder]="options.placeholder"
  [showFilter]="options.showFilter"
  [properties]="options">
</entity-autocomplete>
