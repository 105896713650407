import {ContabilidadeMovimentosEmAbertoComponent} from './components/contabilidade.movimentosEmAberto.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_PCA_ESTATISTICA_MOVIMENTOS_EM_ABERTO} from './contabilidade.movimentosEmAberto.module.interface';
import {ROLE} from '../../../../services/role.const';

export const MODULE_PCA_ESTATISTICA_MOVIMENTOS_EM_ABERTO: IModuleDefinition = {
  name: MODULE_NAME_PCA_ESTATISTICA_MOVIMENTOS_EM_ABERTO,
  state: {
    url: '/movimentosemaberto',
    component: ContabilidadeMovimentosEmAbertoComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.movimentosemaberto'
    },
    params: {
      deConta: {
        type: 'string',
        value: ''
      },
      ateConta: {
        type: 'string',
        value: ''
      },
      query: {
        type: 'string',
        value: ''
      },
      nConta: {
        type: 'string',
        value: ''
      }
    }
  }
};
