export enum EObjScanDocTextInterpreterInvoiceType {
  None,
  Fatura,
  FaturaSimplificada,
  FaturaRecibo,
  NotaDebito,
  NotaCredito,
  VendaDinheiro,
  TalaoVenda,
  TalaoDevolucao,
  AlienacaoAtivos,
  DevolucaoAtivos,
  Premio,
  Estorno,
  ImputacaoCoSeguradoras,
  ImputacaoCoSeguradoraLider,
  ResseguroAceite,
  Recibo,
  NotaLancamento,
  ExtratoBancario,
  PagamentoAoEstado,
  Levantamento,
  Pagamento,
  Deposito,
  Cheque,
  Transferencia
}
