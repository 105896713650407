import type {
  IPlFormDefinition,
  IPlFormDefinitionField,
  IPlFormTemplate,
  IPlFormTemplateField,
  IPlFormTemplateFieldGroup,
  IPlFormTemplateFieldItem,
  IPlFormTemplateFieldItemField,
  TPlFormFieldChangeEvent
} from './form.interface';
import {deepFreeze} from '../../../common/utilities/utilities';

export enum EPlFormInternalOrientationClasses {
  Vertical = 'form-vertical',
  Horizontal = 'form-horizontal'
}

export enum EPlFormInternalPropertiesToEvaluate {
  VISIBLE = 'visible',
  READONLY = 'readonly',
  DISABLED = 'disabled'
}

export type TPlFormInternalTemplateField = IPlFormInternalTemplateFieldItem | IPlFormInternalTemplateFieldGroup;

export interface IPlFormInternalTemplateField extends IPlFormTemplateField {
  field?: IPlFormTemplateFieldItemField;
  fields?: Array<IPlFormInternalTemplateFieldItem>;
  parentGroup?: IPlFormInternalTemplateFieldGroup | undefined;
}

export interface IPlFormInternalTemplateFieldItem extends IPlFormInternalTemplateField {
  field: IPlFormTemplateFieldItemField;
  parentGroup?: IPlFormInternalTemplateFieldGroup | undefined;
}

export interface IPlFormInternalTemplateFieldGroup extends IPlFormInternalTemplateField {
  fields: Array<IPlFormInternalTemplateFieldItem>;
}

export interface IPlFormInternalHandledTemplateItem {
  newItem: TPlFormInternalTemplateField;
  found: boolean;
}

export interface IPlFormInternalTemplateFieldToEvaluate {
  field: IPlFormTemplateFieldItemField;
  property: EPlFormInternalPropertiesToEvaluate;
}

export interface IPlFormInternalDefaults {
  readonly definition: IPlFormDefinition;
  readonly template: IPlFormTemplate;
  readonly definitionField: IPlFormDefinitionField;
  readonly templateField: IPlFormTemplateFieldItem;
  readonly templateGroup: IPlFormTemplateFieldGroup;
}

export interface IPlFormInternalFieldChangeEvents<T> {
  [fieldName: string]: IPlFormInternalFieldChangeObject<T>;
}

export interface IPlFormInternalFieldChangeObject<T> {
  field: IPlFormTemplateFieldItemField;
  event: TPlFormFieldChangeEvent<T>;
}

export const PL_FORM_DEFAULTS: IPlFormInternalDefaults = deepFreeze({
  definition: {
    fields: [],
    events: {
      beforeSubmit: undefined,
      afterSubmit: undefined
    }
  },
  template: {
    orientation: 'horizontal',
    items: [],
    events: {
      beforeSubmit: undefined,
      afterSubmit: undefined
    },
    /* Use this property to render missing template items which are defined in definition */
    addMissingFields: true
  },
  definitionField: {
    name: undefined,
    formName: undefined,
    type: 'text',
    caption: undefined,
    modelName: undefined,
    properties: {},
    validators: {},
    events: {}
  },
  templateField: {
    type: 'field',
    order: undefined,
    field: {
      name: undefined,
      formName: undefined,
      order: undefined,
      caption: undefined,
      class: '',
      actions: undefined,
      placeholder: undefined,
      properties: {},
      validators: {},
      events: {}
    },
    parentGroup: undefined
  },
  templateGroup: {
    type: 'group',
    fields: [],
    properties: {},
    order: undefined
  }
});
