import {Observable} from 'rxjs';
import {EMonth} from 'pl-comps-angular';
import {ApiService} from '../api/api.service';
import {buildSessionUrlWithParams} from '../../../common/utils/utils';
import {
  EContabDigitalDocumentRotateTo,
  EContabilidadeDigitalDocumentCommandType,
  TCommandContabDigitalDocumentResponse,
  TCommandContabDigitalDocumentResponseJsonDocOCRCab
} from './contabilidadedigital.interface';
import {EObjScanDocTextInterpreterInvoiceType} from '../../../common/interfaces/objscandoctextinterpreter.interface';
import {
  IJsonContabDigitalAdicionarVerbeteData,
  IJsonContabDigitalAssociaDoc,
  IJsonContabDigitalAssociaDocSemelhante,
  IJsonContabDigitalLancadoRobo,
  IJsonContabDigitalPreDefinido,
  IJsonContabDigitalPreDefinidoConfig,
  IJsonContabDigitalSemDocs,
  IJsonDocDigitalizado,
  IJsonDocOCRCab
} from './jsonContabDigital.interface';
import {
  IRestCommandContabDigitalDocument,
  IRestCommandContabDigitalDocumentAtribuirTipoDocumento,
  IRestCommandContabDigitalDocumentMoverParaDocsNaoClassificados,
  IRestCommandContabDigitalDocumentObterDadosEFatura,
  IRestCommandContabDigitalDocumentRemovePages,
  IRestCommandContabDigitalDocumentRotate,
  IRestCommandContabDigitalDocumentSepararDoc,
  IRestCommandContabDigitalDocumentUnirDocs
} from './restContabDigitalDocumentCommands.interface';
import {
  CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_DOCS_CLASSIFICADOS,
  CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_DOCS_NAO_CLASSIFICADOS,
  CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR
} from '../../components/arquivodigital/contabilidade/docviewerrecolha/contabilidadedigital.docviewer.recolha.component.interface';
import {DATE_MONTHS_LENGTH} from '../../../config/constants';
import {monthName} from '../../../common/dates';
import {TServiceResponse} from '../api/api.service.interface';

const SORT_DOC_DIGITALIZADO_COMPRAS = 'Compras';
const SORT_DOC_DIGITALIZADO_VENDAS = 'Vendas';
const SORT_DOC_DIGITALIZADO_OPERACOES_DIVERSAS = 'Operações diversas';
const SORT_DOC_DIGITALIZADO_INDEX_FOLDER = 0;
const SORT_DOC_DIGITALIZADO_INDEX_TYPE = 1;
const SORT_DOC_DIGITALIZADO_INDEX_YEAR = 2;
const SORT_DOC_DIGITALIZADO_INDEX_MONTH = 3;
const SORT_DOC_DIGITALIZADO_MAX_INDEX = 4;

export class ContabilidadeDigitalServiceDocuments {
  private readonly _path: string;

  constructor(
    protected readonly _apiService: ApiService,
    basePath: string
  ) {
    this._path = `${basePath}/documents`;
  }

  public getUrl(docID: string, download: boolean = false, withStamp: boolean = false): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/${docID}`, {
      download: download,
      withStamp: withStamp
    });
  }

  public addUrl(doOCR: boolean = true): Observable<string> {
    return buildSessionUrlWithParams(this._path, {doOCR: doOCR});
  }

  public defaultConfigPreDefinidos(nif: string): TServiceResponse<IJsonContabDigitalPreDefinido> {
    return this._apiService.get<IJsonContabDigitalPreDefinido>({
      url: `${this._path}/predefinidos`,
      params: {nif: nif}
    });
  }

  public savePreDefinidosConfig(preDefinidoConfig: IJsonContabDigitalPreDefinidoConfig): TServiceResponse<Array<IJsonDocDigitalizado>> {
    return this._apiService.post<Array<IJsonDocDigitalizado>, IJsonContabDigitalPreDefinidoConfig>({
      url: `${this._path}/predefinidos`,
      body: preDefinidoConfig
    });
  }

  public moveDocsFolder(folderid: string, docsDigitalizados: Array<IJsonDocDigitalizado>): TServiceResponse<Array<IJsonDocDigitalizado>> {
    return this._apiService.post<Array<IJsonDocDigitalizado>, Array<IJsonDocDigitalizado>>({
      url: `${this._path}/movedocsfolder`,
      body: docsDigitalizados,
      params: {
        folderId: folderid
      }
    });
  }

  public saveDocsDigitalizados(marcaTotaisComoOk: boolean, docsDigitalizados: Array<IJsonDocDigitalizado>): TServiceResponse<Array<IJsonDocDigitalizado>> {
    return this._apiService.post<Array<IJsonDocDigitalizado>, Array<IJsonDocDigitalizado>>({
      url: `${this._path}/savedocsdigitalizados`,
      body: docsDigitalizados,
      params: {
        marcatotaiscomook: marcaTotaisComoOk
      }
    });
  }

  public adicionarVerbete(data: IJsonContabDigitalAdicionarVerbeteData): TServiceResponse<string> {
    return this._apiService.post<string, IJsonContabDigitalAdicionarVerbeteData>({url: `${this._path}/attachverbete`, body: data});
  }

  public lancarPorRobo(docsDigitalizados: Array<IJsonDocDigitalizado>): TServiceResponse<IJsonContabDigitalLancadoRobo> {
    return this._apiService.put<IJsonContabDigitalLancadoRobo, Array<IJsonDocDigitalizado>>({url: `${this._path}/lancarobo`, body: docsDigitalizados});
  }

  public refazerOCR(docOCRCabid: string): TServiceResponse<IJsonDocDigitalizado> {
    return this._apiService.post<IJsonDocDigitalizado>({
      url: `${this._path}/refazerocr`,
      params: {
        dococrcabid: docOCRCabid
      }
    });
  }

  public delete(docID: string, folderId: string): TServiceResponse<void> {
    return this._apiService.delete<void>({url: `${this._path}/${docID}`, params: {folderId: folderId}});
  }

  public deleteDocsDigitalizados(docsDigitalizados: Array<IJsonDocDigitalizado>): TServiceResponse<void> {
    return this._apiService.delete<void, Array<IJsonDocDigitalizado>>({url: `${this._path}/deletedocs`, body: docsDigitalizados});
  }

  public contabDigitalSemDocs(
    periodo: string,
    periodoAte: string,
    diario: number,
    diarioAte: number,
    contrib: boolean,
    contaCorrente: boolean,
    nomeContaCorrente: boolean
  ): TServiceResponse<Array<IJsonContabDigitalSemDocs>> {
    return this._apiService.get<Array<IJsonContabDigitalSemDocs>>({
      url: `${this._path}/semdocsdigital`,
      params: {
        periodo: periodo,
        periodoate: periodoAte,
        diario: diario,
        diarioate: diarioAte,
        contrib: contrib,
        contacorrente: contaCorrente,
        nomecontacorrente: nomeContaCorrente
      }
    });
  }

  public getDocsSemelhantes(docOCRCabDI: string): TServiceResponse<Array<IJsonContabDigitalAssociaDocSemelhante>> {
    return this._apiService.get<Array<IJsonContabDigitalAssociaDocSemelhante>>({url: `${this._path}/associadocssemelhantes`, params: {dococrcabid: docOCRCabDI}});
  }

  public associarDocumentos(list: Array<IJsonContabDigitalAssociaDoc>, incluiAnulados: boolean = false): TServiceResponse<Array<IJsonContabDigitalAssociaDoc>> {
    return this._apiService.post<Array<IJsonContabDigitalAssociaDoc>>({url: `${this._path}/associar`, body: list, params: {incluianulados: incluiAnulados}});
  }

  public novaContaCC(nconta: string, nome: string, nif: string, radical: string): TServiceResponse<string> {
    return this._apiService.post<string>({
      url: `${this._path}/novacontacc`,
      params: {
        nconta: nconta,
        nome: nome,
        nif: nif,
        radical: radical
      }
    });
  }

  public obterDadosEFatura(docsID: Array<string>): TCommandContabDigitalDocumentResponse<Array<IJsonDocDigitalizado>> {
    const commandData: IRestCommandContabDigitalDocumentObterDadosEFatura = {
      docID: undefined,
      docsID: docsID
    };
    return this._sendCommand<Array<IJsonDocDigitalizado>>(EContabilidadeDigitalDocumentCommandType.ObterDadosEFatura, commandData);
  }

  public atribuirTipoDocumento(docsID: Array<string>, invoiceType: EObjScanDocTextInterpreterInvoiceType): TCommandContabDigitalDocumentResponseJsonDocOCRCab {
    const commandData: IRestCommandContabDigitalDocumentAtribuirTipoDocumento = {
      docID: undefined,
      invoiceType: invoiceType,
      docsID: docsID
    };
    return this._sendCommand<IJsonDocOCRCab>(EContabilidadeDigitalDocumentCommandType.AtribuirTipoDocumento, commandData);
  }

  public unirDocs(docID: string, docsAUnir: Array<string>): TCommandContabDigitalDocumentResponseJsonDocOCRCab {
    const commandData: IRestCommandContabDigitalDocumentUnirDocs = {
      docID: docID,
      docsAUnir: docsAUnir
    };
    return this._sendCommand<IJsonDocDigitalizado>(EContabilidadeDigitalDocumentCommandType.UnirDocs, commandData);
  }

  public separarDoc(docID: string, mantemOriginal: boolean): TCommandContabDigitalDocumentResponse<Array<IJsonDocDigitalizado>> {
    const commandData: IRestCommandContabDigitalDocumentSepararDoc = {
      docID: docID,
      mantemOriginal: mantemOriginal
    };
    return this._sendCommand<Array<IJsonDocDigitalizado>>(EContabilidadeDigitalDocumentCommandType.SepararDoc, commandData);
  }

  public copiarDoc(docID: string): TCommandContabDigitalDocumentResponseJsonDocOCRCab {
    const commandData: IRestCommandContabDigitalDocument = {
      docID: docID
    };
    return this._sendCommand<IJsonDocOCRCab>(EContabilidadeDigitalDocumentCommandType.CopiarDoc, commandData);
  }

  public removePages(docID: string, pages: string): TCommandContabDigitalDocumentResponseJsonDocOCRCab {
    const commandData: IRestCommandContabDigitalDocumentRemovePages = {
      docID: docID,
      pages: pages
    };
    return this._sendCommand<IJsonDocOCRCab>(EContabilidadeDigitalDocumentCommandType.RemovePages, commandData);
  }

  public rotate(docID: string, rotateTo: EContabDigitalDocumentRotateTo): TCommandContabDigitalDocumentResponseJsonDocOCRCab {
    const commandData: IRestCommandContabDigitalDocumentRotate = {
      docID: docID,
      rotateTo: rotateTo
    };
    return this._sendCommand<IJsonDocOCRCab>(EContabilidadeDigitalDocumentCommandType.Rotate, commandData);
  }

  public moverParaDocsNaoClassificados(docsID: Array<string>): TCommandContabDigitalDocumentResponse<Array<IJsonDocDigitalizado>> {
    const commandData: IRestCommandContabDigitalDocumentMoverParaDocsNaoClassificados = {
      docsID: docsID,
      docID: undefined
    };
    return this._sendCommand<Array<IJsonDocDigitalizado>>(EContabilidadeDigitalDocumentCommandType.MoverParaDocsNaoClassificados, commandData);
  }

  public sortDocsDigitalizados(docsDigitalizados: Array<IJsonDocDigitalizado>): Array<IJsonDocDigitalizado> {
    return docsDigitalizados.sort((a: IJsonDocDigitalizado, b: IJsonDocDigitalizado) => {
      const folderPathA: Array<string> = a.folder.split(CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR);
      const folderPathB: Array<string> = b.folder.split(CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR);

      if (folderPathA.length !== SORT_DOC_DIGITALIZADO_MAX_INDEX || folderPathB.length !== SORT_DOC_DIGITALIZADO_MAX_INDEX) {
        return -1;
      }

      const initialFolderA: string = folderPathA[SORT_DOC_DIGITALIZADO_INDEX_FOLDER];
      const initialFolderB: string = folderPathB[SORT_DOC_DIGITALIZADO_INDEX_FOLDER];
      if (initialFolderA !== initialFolderB) {
        const initialFolderAValue: number = this._docsDigitalizadoInitialFolder(initialFolderA);
        const initialFolderBValue: number = this._docsDigitalizadoInitialFolder(initialFolderB);
        if (initialFolderAValue !== -1 && initialFolderBValue !== -1) {
          return initialFolderAValue - initialFolderBValue;
        }
        if (initialFolderAValue !== -1) {
          return -1;
        }
        if (initialFolderBValue !== -1) {
          return 1;
        }
        return initialFolderAValue < initialFolderBValue ? -1 : 1;
      }

      const typeFolderA: string = folderPathA[SORT_DOC_DIGITALIZADO_INDEX_TYPE];
      const typeFolderB: string = folderPathB[SORT_DOC_DIGITALIZADO_INDEX_TYPE];
      if (typeFolderA !== typeFolderB) {
        const typeFolderAValue: number = this._docsDigitalizadoTypeFolder(typeFolderA);
        const typeFolderBValue: number = this._docsDigitalizadoTypeFolder(typeFolderB);
        if (typeFolderAValue !== -1 && typeFolderBValue !== -1) {
          return typeFolderAValue - typeFolderBValue;
        }
        if (typeFolderAValue !== -1) {
          return -1;
        }
        if (typeFolderBValue !== -1) {
          return 1;
        }
        return typeFolderA < typeFolderB ? -1 : 1;
      }

      const yearFolderA: string = folderPathA[SORT_DOC_DIGITALIZADO_INDEX_YEAR];
      const yearFolderB: string = folderPathB[SORT_DOC_DIGITALIZADO_INDEX_YEAR];
      const yearA = Number(yearFolderA);
      const yearB = Number(yearFolderB);
      if (yearA !== yearB) {
        return yearA - yearB;
      }

      const monthA = this._docsDigitalizadoMonthFolder(folderPathA[SORT_DOC_DIGITALIZADO_INDEX_MONTH]);
      const monthB = this._docsDigitalizadoMonthFolder(folderPathB[SORT_DOC_DIGITALIZADO_INDEX_MONTH]);
      return monthA - monthB;
    });
  }

  private _sendCommand<T extends object>(command: EContabilidadeDigitalDocumentCommandType, commandData?: IRestCommandContabDigitalDocument): TCommandContabDigitalDocumentResponse<T> {
    return this._apiService.patch<T, IRestCommandContabDigitalDocument>({url: this._path, body: commandData, params: {command: command}});
  }

  private _docsDigitalizadoInitialFolder(initialFolder: string): number {
    if (initialFolder === CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_DOCS_CLASSIFICADOS) {
      return 1;
    }
    if (initialFolder === CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_DOCS_NAO_CLASSIFICADOS) {
      return 2;
    }
    return -1;
  }

  private _docsDigitalizadoTypeFolder(typeFolder: string): number {
    if (typeFolder === SORT_DOC_DIGITALIZADO_COMPRAS) {
      return 1;
    }
    if (typeFolder === SORT_DOC_DIGITALIZADO_VENDAS) {
      return 2;
    }
    if (typeFolder === SORT_DOC_DIGITALIZADO_OPERACOES_DIVERSAS) {
      // Magic number 3 here is irrelevant, this is only used in sorting calculations
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      return 3;
    }
    return -1;
  }

  private _docsDigitalizadoMonthFolder(monthFolder: string): number {
    const folderPathMonth: string = monthFolder.toUpperCase();
    for (let i: EMonth = EMonth.January; i <= DATE_MONTHS_LENGTH; i++) {
      if (folderPathMonth === monthName(i).toUpperCase()) {
        return i;
      }
    }
    return -1;
  }
}
