import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonPRHFluxo} from './jsonPRHFluxo.entity.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface IPRHFluxosEntityService extends IEntityService<IJsonPRHFluxo> {
  mudarPosicao(codFluxo: number, inc: number): TServiceResponse<IJsonPRHFluxo>;
}

export interface IPRHFluxosEntity extends IEntityDefinition<IJsonPRHFluxo, IPRHFluxosEntityService> {}

export const ENTITY_NAME_PRH_FLUXOS = 'prhfluxos';
