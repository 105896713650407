import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_GESTAO_FICHEIROS_DMR_AT} from './rhGestaoFicheirosDMRAT.module.interface';
import {RHGestaoFicheirosDMRATModuleComponent} from './components/rhGestaoFicheirosDMRAT.module.component';

export const MODULE_RH_GESTAO_FICHEIROS_DMR_AT: IModuleDefinition = {
  name: MODULE_NAME_RH_GESTAO_FICHEIROS_DMR_AT,
  state: {
    url: MODULE_NAME_RH_GESTAO_FICHEIROS_DMR_AT,
    component: RHGestaoFicheirosDMRATModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhgestaoficheirosdmrat'
    },
    params: {
      ano: {
        type: 'int',
        value: -1
      },
      mes: {
        type: 'int',
        value: -1
      }
    }
  }
};
