import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_INVEC} from './invec.entity.interface';
import {IJsonInvec} from './jsonInvec.entity.interface';

export const ENTITY_INVEC: IEntityDefinition<IJsonInvec> = {
  name: ENTITY_NAME_INVEC,
  asModule: false,
  roles: [ROLE.ERP],
  searchPlaceholder: 'invec.pesquisa',
  metadata: {
    keyName: 'name',
    fields: [
      {
        name: 'invecID',
        type: 'cginteger',
        visible: false
      },
      {
        name: 'nome',
        caption: 'invec.fields.nome',
        placeholder: 'invec.fields.nome',
        validators: {maxlength: 10}
      },
      {
        name: 'descricao',
        caption: 'invec.fields.descricao',
        placeholder: 'invec.fields.descricao',
        validators: {required: true, maxlength: 60}
      },
      {
        name: 'data',
        caption: 'global.text.date',
        placeholder: 'global.text.date',
        validators: {maxlength: 150}
      }
    ],
    order: 'invecID',
    searchFields: 'nome,descricao,data'
  },
  autocomplete: {
    rowTemplate: '{{nome}} - {{descricao}}',
    searchFields: 'nome,descricao',
    output: 'nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  }
};
