import {TDate} from '../../../../common/dates';

export enum ETipoExport {
  PDF,
  EXCEL
}

export enum EModuleTipo {
  VENDAS,
  COMPRAS,
  TODOS
}

export enum EDocsComercisaisDocsGridLegend {
  DocsComerciaisDocsPorPagar = 'bg-por-pagar',
  DocsComerciaisDocsPago = 'bg-pago'
}

export interface IDocsComerciasEstatisticasCustomFilters {
  ncontade: string;
  ncontaate: string;
  datade: TDate;
  dataate: TDate;
  nvendedorde: number;
  nvendedorate: number;
  codzonade: number;
  codzonaate: number;
  ccustode: string;
  ccustoate: string;
  nrefprocessode: string;
  nrefprocessoate: string;
  docfalist: string;
}

export interface IDocsComerciasEstatisticasDocFilters extends IDocsComerciasEstatisticasCustomFilters {
  resumoiva: boolean;
  reportname?: string;
  tipoexportacao?: ETipoExport;
  nartigo?: string;
  tipoartigolist?: string;
  grandefamilialist?: string;
  familialist?: string;
  subfalist?: string;
  ididechavde?: string;
  ididechavate?: string;
  cliclde?: string;
  cliclate?: string;
}

export interface IDocsComerciasEstatisticasFilters extends IDocsComerciasEstatisticasCustomFilters {
  nartigode: string;
  nartigoate: string;
  tipoartigode: number;
  tipoartigoate: number;
  ngrfamiliade: number;
  ngrfamiliaate: number;
  nfamiliade: number;
  nfamiliaate: number;
  nsubfamiliade: number;
  nsubfamiliaate: number;
  classede: number;
  classeate: number;
  categoriade: number;
  categoriaate: number;
  socommovimstock: boolean;
  reportname?: string;
  tipoexportacao?: ETipoExport;
  ididechavde?: string;
  ididechavate?: string;
  cliclde?: string;
  cliclate?: string;
}

export interface IDocComercialEstatisticaTotaisPesquisa {
  totalLiquido: number;
  totalValorIva: number;
  total: number;
}

export interface IDocComercialEstatisticaDocItem {
  faccbId: number;
  nDocfa: number;
  docfaNome: string;
  nnumer: number;
  nDocumento: number;
  nDocumentoDraft: number;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  nAutoInc_Doc: number;
  dataDoc: TDate;
  nConta: string;
  contaNome: string;
  nif: string;
  totalDocumento: number;
  totalLiquido: number;
  totalIliquido: number;
  totalIva: number;
  totalDesconto: number;
  totalDocumentoMe: number;
  totalLiquidoMe: number;
  totalIliquidoMe: number;
  totalIvaMe: number;
  totalDescontoMe: number;
  codRet: string;
  taxaRet: number;
  retencaoIncidencia: number;
  retencaoValor: number;
  nDocStr: string;
  nFactFornec: string;
  dataDocExterno: TDate;
  nCCusto: string;
  centroCusto?: string;
  nRefProcesso?: string;
  nomeProcesso?: string;
  totalDocumentoMovimento?: number;
  totalLiquidoMovimento?: number;
  totalIliquidoMovimento?: number;
  totalIvaMovimento?: number;
  totalDescontoMovimento?: number;
  totaisPesquisa: IDocComercialEstatisticaTotaisPesquisa;
  valorPorPagar?: number;
  estado?: string;
  sectorId?: number;
  nomeSector?: string;
}

export interface IDocComercialEstatisticaItem extends IDocComercialEstatisticaDocItem {
  facliId: number;
  nArtigo: string;
  nomeArtigo: string;
  nDepart: number;
  nFamil: number;
  nSubfa: number;
  tipoArtigo: number;
  liquido: number;
  valorIva: number;
  totalLin: number;
  sinal: number;
  liquidoMovimento: number;
  valorIvaMovimento: number;
  totalMovimento: number;
  codIva: number;
  d1: number;
  d2: number;
  d3: number;
  d4: number;
  d5: number;
  d6: number;
  desconto: number;
  qtd1: number;
  unidade: string;
  qtdMovimento: number;
  prVendaQtd1: number;
  ano: number;
  mes: number;
  nomeDepart?: string;
  nomeFamilia?: string;
  nomeSubFamilia?: string;
  nomeTipoArtigo?: string;
  nVendedor?: number;
  nomeVendedor?: string;
  mesStr?: string;
}

export interface ITipoExport {
  value: ETipoExport;
  label: string;
}

export const CONTA_CLIENTES_MIN = '211';
export const CONTA_CLIENTES_MAX = '2119999999';
export const CONTA_FORNECEDORES_MIN = '221';
export const CONTA_FORNECEDORES_MAX = '2219999999';
export const MAX_STRING = 'ZZZZ';
export const MAX_NCCUSTO = '9999999999';

export const MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS = 'docsComerciaisEstatisticas';
export const MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS = 'listagens/docscomerciais';

export const MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_VENDAS = 'docsComerciaisEstatisticasVendas';
export const MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_VENDAS = 'listagens/vendas/docscomerciais';

export const MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_COMPRAS = 'docsComerciaisEstatisticasCompras';
export const MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_COMPRAS = 'listagens/compras/docscomerciais';

export const MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_DOC = 'docsComerciaisEstatisticasDoc';
export const MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_DOC = 'listagens/docscomerciaisdoc';

export const MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_DOC_VENDAS = 'docsComerciaisEstatisticasDocVendas';
export const MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_DOC_VENDAS = 'listagens/vendas/docscomerciaisdoc';

export const MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_DOC_COMPRAS = 'docsComerciaisEstatisticasDocCompras';
export const MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_DOC_COMPRAS = 'listagens/compras/docscomerciaisdoc';
