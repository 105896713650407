import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {LoadOptions} from 'devextreme/data';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IPlToolbarInstance} from 'pl-comps-angular';
import {devExpressDataGridFiltersToQueryFilter, devExpressDataGridSortToOrder} from '../../devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DevExpressDatagridPaging} from '../../devexpress/datagrid/utilities/devexpress.datagrid.paging';
import {IDevExpressDataGrid, IDevExpressDataGridColumn, IDevExpressDataGridLoadResult, IDevExpressDataGridSummary} from '../../devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonPendentesLinha} from '../../../interfaces/jsonContabilidade.interface';
import {ListagensService} from '../../../services/listagens.service';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';

const ORDER = 'nConta';

@Component({
  selector: 'movimentos-pendentes',
  templateUrl: './movimentos.pendentes.component.html'
})
export class MovimentosPendentesComponent implements OnInit {
  @Input() public dataGridColumns: Array<IDevExpressDataGridColumn>;
  @Input() public dataGridSummary: IDevExpressDataGridSummary;
  @Input() public listFields: string;
  @Input() public factoryFn: 'listagensMovimentosPendentesClientes' | 'listagensMovimentosPendentesFornecedores' | 'listagensMovimentosPendentesOutrosDevedoresCredores';
  @Input() public nConta: string;
  @Input() public searchFields: string;
  @Input() public toolbar: IPlToolbarInstance;
  @Output() public readonly evtDataGridInstance: EventEmitter<dxDataGrid>;
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly dataSource: CustomStore;

  private _dataGridInstance: dxDataGrid;
  private _dataGridPaging: DevExpressDatagridPaging;

  constructor(private readonly _listagensService: ListagensService) {
    this.evtDataGridInstance = new EventEmitter<dxDataGrid>();
    this.dataGridDefinition = {
      columns: [],
      columnHidingEnabled: false,
      remoteOperations: {
        filtering: true,
        sorting: true,
        grouping: false,
        summary: false,
        paging: true,
        groupPaging: false
      },
      searchPanel: {visible: true},
      export: {filename: 'movimentos.pendentes'}
    };
    this.dataSource = new CustomStore({
      key: 'nConta',
      load: this._source.bind(this)
    });
  }

  public ngOnInit(): void {
    this.dataGridDefinition.columns = this.dataGridColumns;
    this.dataGridDefinition.summary = this.dataGridSummary;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
    this.evtDataGridInstance.emit(component);
  }

  private _source(loadOptions: LoadOptions): Promise<IDevExpressDataGridLoadResult<IJsonPendentesLinha>> {
    let search = '';
    if (loadOptions.filter) {
      search = devExpressDataGridFiltersToQueryFilter(this._dataGridInstance, loadOptions.filter);
    }
    if (this.nConta) {
      if (search) {
        search += '&';
      }
      search += `nConta=${this.nConta}`;
    }
    const order: string = loadOptions.sort ? devExpressDataGridSortToOrder(loadOptions.sort) : ORDER;
    if (!this._dataGridPaging) {
      this._dataGridPaging = new DevExpressDatagridPaging(this._dataGridInstance);
    }
    const {page, perPage} = this._dataGridPaging.paginate(loadOptions);
    if (!this.searchFields) {
      this.searchFields = this.listFields;
    }
    return this._listagensService[this.factoryFn](search, order, page, perPage, this.searchFields).then((response: THttpQueryResponse<IJsonPendentesLinha>) => {
      return this._dataGridPaging.processResult(response);
    });
  }
}
