<dx-data-grid
  [cgDxDataGrid]="definition"
  cgDxDataGridInstanceName="comunicacoes"
  [dataSource]="definition.dataSource"
  (onInitialized)="onDataGridInitialized($event)"
  (onCellClick)="onDataGridCellClickComunicacoes($event)">
  <div *dxTemplate="let item of 'actions'">
    <div class="d-flex" style="gap: 5px">
      <a class="btn btn-link btn-xs btn-pdf" [href]="item.data.url" download target="_blank" *ngIf="item.data.docID !== emptyGuid">
        <i style="margin-top: 6px" class="fa fa-fw fa-file-pdf-o text-danger"></i>
      </a>
      <dx-button
        class="dx-button-icon-devexpress"
        [hint]="'global.btn.delete' | translate"
        (onClick)="deleteComunicacao($event, item.data.comIntCabID)"
        icon="trash"
        [stylingMode]="'text'"></dx-button>
    </div>
  </div>

  <div *dxTemplate="let item of 'detail'">
    <pl-form>
      <pl-group>
        <label><span [translate]="'gestaodgemps.text.servico'"></span>:</label>
        <edit>
          <entity-autocomplete entity="prhservicos" [model]="item.data.servico" (modelChange)="changedItemServico(item.data, $event)" [properties]="{allowInvalid: false}"></entity-autocomplete>
        </edit>
      </pl-group>
    </pl-form>

    <dx-data-grid [cgDxDataGrid]="definitionMasterDetail" cgDxDataGridInstanceName="comunicacoeslinha" [dataSource]="item.data.linhas" [filterValue]="item.data.filterValue">
      <div *dxTemplate="let item of 'actions'">
        <dx-button
          class="dx-button-icon-devexpress"
          [hint]="'global.btn.delete' | translate"
          (onClick)="deleteComunicacao($event, item.data.comIntCabID, item.data.codEmp)"
          icon="trash"
          [stylingMode]="'text'"></dx-button>
      </div>
    </dx-data-grid>
  </div>
</dx-data-grid>
