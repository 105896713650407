<div class="modal-proce-salarios-empregados">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'proceSalarios.pesqEmpregados'"></h5>
  </div>

  <div class="modal-body">
    <dx-data-grid [cgDxDataGrid]="dataGridDefinitionProceSalEmp" cgDxDataGridInstanceName="proceSalEmp" [dataSource]="dataGridDefinitionProceSalEmp.dataSource" [(selectedRowKeys)]="selectedKeysDGEmp">
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-close" [disabled]="closeDisabled || !selectedKeysDGEmp.length" [onClick]="close">
      <i class="fa fa-fw fa-plus"></i>&nbsp;<span [translate]="'proceSalarios.addSelected'"></span>
    </pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()">
      <i class="fa fa-fw fa-close"></i>&nbsp;<span [translate]="'global.btn.close'"></span>
    </pl-button>
  </div>
</div>
