import {deepFreeze} from 'pl-comps-angular';
import {ERadioTipoDeclaracao} from './jsonModelo25.module.interface';
import {IRadioGroup} from '../../../../common/interfaces/interfaces';

export enum ETipModelo25 {
  NewClean = 'NewClean',
  NewFromDB = 'NewFromDB'
}

export interface IModelo25DataGridValidation {
  isValid: boolean;
  errorMessage?: string;
}

export const RADIO_GROUP_TIPO_DECLARACAO: IRadioGroup<ERadioTipoDeclaracao> = deepFreeze<IRadioGroup<ERadioTipoDeclaracao>>({
  groupItems: [
    {value: ERadioTipoDeclaracao.Primeira, label: 'modelo25.groups.q4.radioTipoDeclaracao.primeira'},
    {value: ERadioTipoDeclaracao.Substituicao, label: 'modelo25.groups.q4.radioTipoDeclaracao.sub'}
  ]
});

export const MODULE_NAME_MODELO_25 = 'modelo25';
