import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {CGCIconDirective} from '../../../icon/directive/icon.directive';
import {PlPromisesModule} from '../../../promises/promises.module';
import {PlTabsModule} from '../../../tabs/tabs.module';
import {PlTooltipModule} from '../../../tooltip/tooltip.module';
import {PlTranslateModule} from '../../../translate/translate.module';
import {PlUploadModule} from '../../../upload/upload.module';

import {PlMediaDevicesCameraCaptureImageComponent} from './mediadevices.camera.captureimage.component';
import {PlMediaDevicesCameraCaptureImageChangeModalComponent} from './changemodal/mediadevices.camera.captureimage.change.modal.component';

export * from './mediadevices.camera.captureimage.interface';
export * from './mediadevices.camera.captureimage.component';
export * from './changemodal/mediadevices.camera.captureimage.change.modal.component';

const DECLARATIONS = [PlMediaDevicesCameraCaptureImageComponent, PlMediaDevicesCameraCaptureImageChangeModalComponent];

@NgModule({
  imports: [CommonModule, NgbModalModule, PlPromisesModule, PlTabsModule, PlTooltipModule, PlTranslateModule, PlUploadModule, CGCIconDirective],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlMediaDevicesCameraCaptureImageModule {}
