import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {TrfBaService} from '../../trfBa.module.service';
import {TDate} from '../../../../../common/dates';
import moment from 'moment';
import {ETrfBaRGFormato, IJsonTrfBaRegistoFich, IMetodoSourcePrioridade} from '../../jsonTrfBa.module.interface';
import {isObject, PlAlertService} from 'pl-comps-angular';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';

@Component({
  selector: 'modal-trfba-geraficheiro',
  templateUrl: './trfBa.geraFicheiro.modal.component.html'
})
export class TrfBaGeraFicheiroModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public trfBaIDS: string;
  @Input() public trfBaRegistoFich: IJsonTrfBaRegistoFich;
  @Input() public rgFormato: ETrfBaRGFormato;
  @Input() public showDadosFicheiroUploadDGT: boolean;
  @Input() public showDataLanc: boolean;
  @Input() public showPrioridade: boolean;
  @Input() public dataLanc: TDate;

  public readonly metodoSourcePrioridade: Array<IMetodoSourcePrioridade>;
  public readonly mtdOutput: string;
  public nomeFicheiroUploadDGT: string;
  public numeroSequencial: number;
  public prioridade: boolean;
  public nomePrioridade: string;
  public title: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _trfBaService: TrfBaService
  ) {
    super(_injector);
    this.showDadosFicheiroUploadDGT = false;
    this.showDataLanc = false;
    this.showPrioridade = false;
    this.dataLanc = moment();
    this.nomeFicheiroUploadDGT = '';
    this.numeroSequencial = 0;
    this.prioridade = false;
    this.nomePrioridade = 'Normal';
    this.title = '';
    this.mtdOutput = '{{nameMetodo}}';
    this.metodoSourcePrioridade = [
      {
        valueMetodo: false,
        nameMetodo: 'trfba.modal.geraficheiro.fields.normal'
      },
      {
        valueMetodo: true,
        nameMetodo: 'trfba.modal.geraficheiro.fields.alta'
      }
    ];
  }

  public ngOnInit(): void {
    if (this.showDadosFicheiroUploadDGT) {
      this.title = 'trfba.modal.geraficheiro.titleDGT';
    } else if (this.showPrioridade) {
      this.title = 'trfba.modal.geraficheiro.titlePrioridade';
    } else {
      this.title = 'trfba.modal.geraficheiro.titleData';
    }
  }

  public close(): Promise<void> {
    if (moment(this.dataLanc).isBefore(moment(), 'day')) {
      this._plAlertService.error('trfba.modal.erros.dataTrfbaNaoPodeSerInferiorDataHoje');
      return undefined;
    }

    return this._trfBaService
      .geraFicheiroEDescarregar(this.trfBaIDS, this.dataLanc, this.nomeFicheiroUploadDGT, this.numeroSequencial, this.prioridade, this.rgFormato, this.trfBaRegistoFich)
      .then(() => {
        super.close();
      })
      .catch((reason) => {
        const exception = this._cgExceptionService.get(reason);
        if (exception) {
          this._plAlertService.error(exception.message);
        } else {
          this._plAlertService.error('trfba.modal.erros.erroGerarFicheiro');
        }
      });
  }

  public metodoPrioridadeChanged(value: {inputValue: string; item: IMetodoSourcePrioridade}): void {
    if (isObject(value.item)) {
      this.prioridade = value.item.valueMetodo;
      this.nomePrioridade = value.item.nameMetodo;
    }
  }
}
