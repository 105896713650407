import {ENTITY_NAME_ROLES} from './roles.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonRole} from '../../interfaces/jsonUserManager.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_ROLES: IEntityDefinition<IJsonRole> = {
  name: ENTITY_NAME_ROLES,
  url: 'manager/roles',
  roles: [ROLE.ADMIN],
  pageTitle: 'global.menu.roles',
  metadata: {
    keyName: 'role',
    fields: [
      {name: 'role', width: '60px', caption: 'roles.fields.role', validators: {required: true}},
      {name: 'description', width: '60px', caption: 'roles.fields.description', validators: {required: true}}
    ],
    order: 'name',
    searchFields: 'name,description'
  },
  autocomplete: {
    rowTemplate: '{{role}} - {{description}}',
    output: 'description',
    searchFields: 'role,description'
  },
  actions: {
    new: false,
    edit: false,
    detail: false,
    delete: false,
    search: true
  }
};
