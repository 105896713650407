import {Component, Injector, Input, OnInit} from '@angular/core';
import {IPlUploadCallback, PlAlertService, PlGlobalEventsService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EmpresasService} from '../../../../services/empresas/empresas.service';
import {GLOBAL_EVENT_LOGO_CHANGED} from '../../../../../config/constants';

const FILE_MAX_SIZE = 1; // megabytes
const FILE_MAX_WIDTH = 1024;

@Component({
  selector: 'upload-logo-modal',
  templateUrl: './uploadLogo.modal.component.html'
})
export class UploadLogoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public nEmpresa: string;

  public readonly plUploadCallback: IPlUploadCallback;
  public readonly acceptedFiles: string;
  public readonly maxFileSize: number;
  public readonly resizeWidth: number;
  public uploadUrl: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _plGlobalEventsService: PlGlobalEventsService,
    private readonly _empresasService: EmpresasService
  ) {
    super(_injector);
    this.plUploadCallback = {};
    this.acceptedFiles = 'image/png,image/jpeg,image/webp';
    this.maxFileSize = FILE_MAX_SIZE;
    this.resizeWidth = FILE_MAX_WIDTH;
  }

  public ngOnInit(): void {
    this._empresasService.getUploadLogotipoUrl(this.nEmpresa).subscribe((value: string) => {
      this.uploadUrl = value;
    });
  }

  public close(): Promise<void> {
    const acceptedFiles = this.plUploadCallback.getAcceptedFiles();
    if (!acceptedFiles.length) {
      return Promise.reject(new Error('No file to upload.'));
    }
    this._empresasService.getUploadLogotipoUrl(this.nEmpresa).subscribe((value: string) => {
      this.uploadUrl = value;
    });
    return this.plUploadCallback.processQueue().then(() => {
      this._plAlertService.success('empresas.messages.success.saveLogotipo');
      this._plGlobalEventsService.broadcast(GLOBAL_EVENT_LOGO_CHANGED);
      super.close();
    });
  }
}
