<div class="dgempsfull-simular-taxa-fixa-irs-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'dgempsfull.modalSimularTaxaFixaIrs.modalTitle'"></h5>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <pl-form>
          <pl-group>
            <pl-group>
              <label [translate]="'dgempsfull.modalSimularTaxaFixaIrs.dataSimulacao'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="dataSimulacao" plAutoFocus></pl-edit-datepicker>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'dgempsfull.modalSimularTaxaFixaIrs.taxaEfetiva'"></label>
              <edit>
                <pl-edit type="number" attrName="taxaFixa" [(model)]="taxaFixa"></pl-edit>
              </edit>
              <actions>
                <pl-button klass="btn btn-primary" id="validaNifSituacao" (evtClicked)="simularTaxa()" [promise]="promise" [disabled]="!taxaFixa || !dataSimulacao" data-focus>
                  <i class="fa fa-fw fa-check"></i><span [translate]="'global.btn.calculate'"></span>
                </pl-button>
              </actions>
            </pl-group>
          </pl-group>
        </pl-form>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <p [translate]="'dgempsfull.modalSimularTaxaFixaIrs.infoModal'"></p>
        <hr />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <h5 [translate]="'dgempsfull.modalSimularTaxaFixaIrs.infoCalculos'"></h5>
      </div>
      <div class="col-sm-12 mt-2">
        <span [innerText]="taxaMarginalSimuladorDados.infoCalculos"></span>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-dismiss" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
