<div class="gto-por-empresa">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="fnPesquisar" plPromise>
        <pl-group>
          <pl-group>
            <label [translate]="'gto.tabs.filters.empresa'"></label>
            <edit>
              <pl-autocomplete
                attrName="empresa"
                [source]="sourceEmpresas"
                [model]="gtoSingleFilters"
                (modelChange)="evtEmpresaChanged($event)"
                [allowEmpty]="false"
                [properties]="{disallowClear: true}"
                [allowInvalid]="false"
                [output]="empresaOutput"
                [rowTemplate]="empresaOutput">
              </pl-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.year'"></label>
            <edit>
              <pl-autocomplete [source]="sourceAnos" [(model)]="gtoSingleFilters.ano" [allowInvalid]="false" [properties]="{allowEmpty: false, disallowClear: true}"></pl-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'gto.tabs.filters.tipotarefa'"></label>
            <edit>
              <pl-edit attrName="tipoTarefa" type="gtotipotarefa" [(model)]="gtoSingleFilters.tipoTarefa" [properties]="{disallowClear: true}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'gto.tabs.filters.responsavel'"></label>
            <edit>
              <pl-autocomplete [source]="sourceResponsaveis" [model]="gtoSingleFilters" (modelChange)="userSelect($event)" [rowTemplate]="usersTemplate" [output]="usersTemplate"></pl-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'gto.tabs.filters.mostrarfilter'"></label>
            <edit>
              <pl-edit
                attrName="mostrarfilter"
                type="gtomostrarfilter"
                [model]="gtoMostrarFilter"
                (modelChange)="gtoMostrarFilter = $event; gtoMostrarFilterChanged()"
                [properties]="{disallowClear: true}">
              </pl-edit>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group class="align-self-end">
          <edit>
            <pl-button type="submit" klass="btn btn-sm btn-primary action-register-value"><i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <hr />

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    [cgDxDataGridColumns]="dataGridDefinition.columns"
    cgDxDataGridInstanceName="gtoSingle"
    [dataSource]="dataGridDefinition.dataSource"
    (onCellPrepared)="onCellPrepared($event)"
    (onContextMenuPreparing)="onContextMenuPreparing($event)">
    <div *dxTemplate="let item of 'dataLimitePlusNotesTmpl'">
      <div class="gto-table-tmpl-cell" [innerHTML]="getDataLimitePlusNotesTmplValue(item.data, item.column)"></div>
    </div>

    <div *dxTemplate="let item of 'valuesPlusNotesTmpl'">
      <div class="gto-table-tmpl-cell" [innerHTML]="getValuesPlusNotesTmplValue(item.data, item.column)"></div>
    </div>

    <div *dxTemplate="let item of 'diasPlusNotesTmpl'">
      <div class="gto-table-tmpl-cell" [innerHTML]="getDiasPlusNotesTmplValue(item.data, item.column)"></div>
    </div>

    <div *dxTemplate="let item of 'dataExecucaoPlusNotesTmpl'">
      <div class="gto-table-tmpl-cell" [innerHTML]="getDataExecucaoPlusNotesTmplValue(item.data, item.column)"></div>
    </div>

    <div *dxTemplate="let item of 'toolbarTemplateTable'">
      <div class="gto-table-subfilters">
        <div class="item-group-dropdown btn-group" tabindex="-1" ngbDropdown container="body" [placement]="['right-bottom', 'bottom-right', 'right', 'auto']">
          <button type="button" class="btn btn-sm btn-info" [disabled]="!gtoTarefas.haveDMRAT && !gtoTarefas.haveDMRSS" (click)="checkStateDMR(gtoTarefas.haveDMRAT, gtoTarefas.haveDMRSS)">
            <span [translate]="captionCheckStateDMR"></span>
          </button>

          <button type="button" class="btn btn-sm btn-info dropdown-toggle dropdown-toggle-split" [disabled]="!gtoTarefas.haveDMRAT && !gtoTarefas.haveDMRSS" ngbDropdownToggle>
            <span class="visually-hidden"></span>
          </button>

          <div class="dropdown-menu" ngbDropdownMenu>
            <button type="button" class="dropdown-item" [translate]="'gto.tabs.btn.dmrat'" [disabled]="!gtoTarefas.haveDMRAT" (click)="checkStateDMR(true, false)"></button>

            <button type="button" class="dropdown-item" [translate]="'gto.tabs.btn.dmrss'" [disabled]="!gtoTarefas.haveDMRSS" (click)="checkStateDMR(false, true)"></button>
          </div>
        </div>

        <div class="ms-auto">
          <pl-tooltip [config]="configTooltip" *ngIf="gtoTarefas.haveAlerts">
            <button type="button" *plTooltipContent class="btn btn-warning btn-sm" (click)="showAlertsModal()">
              <i class="fa fa-bell" aria-hidden="true"></i>
            </button>
          </pl-tooltip>
        </div>
      </div>
    </div>
  </dx-data-grid>

  <dx-tooltip>
    <div *dxTemplate="let data of 'content'">
      <div [innerHTML]="cellToolTipText | translate"></div>
    </div>
  </dx-tooltip>

  <ul class="toolbar-legenda">
    <li class="toolbar-legenda-item">
      <div class="circle state-color-concluido-dentro-prazo"></div>
      <span [translate]="'gto.legends.concluidoDentroPrazo'"></span>
      <span class="ms-1">({{ concluidoDentroPrazoCount }})</span>
    </li>

    <li class="toolbar-legenda-item">
      <div class="circle state-color-guardar-validacao"></div>
      <span [translate]="'gto.legends.guardarValidacao'"></span>
      <span class="ms-1">({{ guardarValidacaoCount }})</span>
    </li>

    <li class="toolbar-legenda-item">
      <div class="circle state-color-em-atraso-justificado"></div>
      <span [translate]="'gto.legends.emAtrasoJustificado'"></span>
      <span class="ms-1">({{ emAtrasoJustificadoCount }})</span>
    </li>

    <li class="toolbar-legenda-item">
      <div class="circle state-color-concluido-fora-prazo"></div>
      <span [translate]="'gto.legends.concluidoForaPrazo'"></span>
      <span class="ms-1">({{ concluidoForaPrazoCount }})</span>
    </li>

    <li class="toolbar-legenda-item">
      <div class="circle state-color-em-atraso"></div>
      <span [translate]="'gto.legends.emAtraso'"></span>
      <span class="ms-1">({{ emAtrasoCount }})</span>
    </li>

    <li class="toolbar-legenda-item">
      <div class="circle state-color-prazo-a-decorrer"></div>
      <span [translate]="'gto.legends.prazoADecorrer'"></span>
      <span class="ms-1">({{ prazoADecorrerCount }})</span>
    </li>

    <li class="toolbar-legenda-item">
      <div class="circle state-color-nao-aplicavel"></div>
      <span [translate]="'gto.legends.naoAplicavel'"></span>
      <span class="ms-1">({{ naoAplicavelCount }})</span>
    </li>
  </ul>
</div>
