import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {PerfilCategoriaAbonoEditComponent} from './components/perfilCategoriaAbono.edit.component';
import {ROLE} from '../../../services/role.const';

export const MODULE_PERFIL_CATEGORIA_ABONO: IModuleDefinition = {
  name: 'perfilCategoriaAbono',
  state: {
    url: '/perfiscategorias/abonos',
    component: PerfilCategoriaAbonoEditComponent,
    data: {
      roles: [ROLE.RH]
    }
  }
};
