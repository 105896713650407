import {MODULE_NAME_ACRESCIMO_ENCARGOS_COM_FERIAS} from './acrescimosEncargosFerias.module.interface';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {AcrescimosEncargosFeriasModuleComponent} from './components/acrescimosEncargosFerias.module.component';

export const MODULE_ACRESCIMO_ENCARGOS_COM_FERIAS: IModuleDefinition = {
  name: MODULE_NAME_ACRESCIMO_ENCARGOS_COM_FERIAS,
  state: {
    url: `/${MODULE_NAME_ACRESCIMO_ENCARGOS_COM_FERIAS}`,
    component: AcrescimosEncargosFeriasModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      requiredRoles: [ROLE.RH],
      pluginsRoles: [ROLE.CONTABILIDADEPREMIUM],
      pageTitle: 'global.menu.acrescimosencargoscomferias'
    }
  }
};
