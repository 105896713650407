import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_MAPAS_OFICIAIS} from './mapasOficiais.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_MAPAS_OFICIAIS: IDataSource<string> = deepFreeze({
  name: DATA_SOURCE_NAME_MAPAS_OFICIAIS,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{value}}',
    output: 'value'
  },
  data: [
    {value: '31'},
    {value: '32'},
    {value: '33.1'},
    {value: '33.2'},
    {value: '33.3'},
    {value: '33.4'},
    {value: '33.5'},
    {value: '33.6'},
    {value: '33.7'},
    {value: '33.8'},
    {value: '33.9'},
    {value: '33.10'},
    {value: '33.11'},
    {value: '33.12'},
    {value: '33.13'},
    {value: '33.14'},
    {value: '33.15'},
    {value: '33.16'},
    {value: '33.17'},
    {value: '33.18'},
    {value: '33.19'}
  ]
});
