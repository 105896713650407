import {Injectable} from '@angular/core';
import {isNumber, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {IDocsComerciaisMeiosPagamentoEvaluateSaveParams} from './docsComerciais.meiosPagamento.service.interface';
import {IJsonDocComercialMeioPagam} from './jsonDocComercialMeioPagam.interface';
import {IJsonDocComercial} from '../../jsonDocComercial.entity.interface';
import {round} from '../../../../../common/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class DocsComerciaisMeiosPagamentoService {
  constructor(
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService
  ) {}

  public evaluateSaveMeiosPagamento({canSave, doc, totalEntregue, codMoeda}: IDocsComerciaisMeiosPagamentoEvaluateSaveParams): boolean {
    if (!canSave) {
      this._plAlertService.error(
        this._plTranslateService.translate('recibos.erros.invalidValorAPagar', {
          valorEntregue: totalEntregue,
          valorDocumento: doc.cab.totalDocumento
        })
      );
      return false;
    }

    // Preenche os campos da lista de acordo com quantidades entregues
    for (const meioPagamento of doc.meiosPagamento) {
      if (isNumber(meioPagamento.valorEntregue)) {
        meioPagamento.montanteEntregueMe = meioPagamento.valorEntregue;
        meioPagamento.montanteEntregue = round(meioPagamento.valorEntregue * meioPagamento.cambio);

        meioPagamento.montante = meioPagamento.montanteEntregue;
        meioPagamento.montanteMe = meioPagamento.montanteEntregueMe;
      }
    }

    // Verifica se existe lugar para troco
    if (totalEntregue !== doc.cab.totalDocumento) {
      const montanteTroco: number = this._getTroco(totalEntregue, doc);

      const meioPagamento: IJsonDocComercialMeioPagam = doc.meiosPagamento.find((meio: IJsonDocComercialMeioPagam) => meio.codMoeda === codMoeda && meio.montanteEntregue >= montanteTroco);
      if (!meioPagamento) {
        this._plAlertService.error('meiosPagamento.mensagens.meioPagSemMoedaEmpresaParaTroco');
        return false;
      }

      meioPagamento.montante = round(meioPagamento.montanteEntregue - montanteTroco);
      meioPagamento.montanteMe = round(meioPagamento.montante / meioPagamento.cambio);

      // Última validação é se o valor montante total é igual ao total do documento
      const montanteTotal: number = this._getMontanteTotalMeiosPagamento(doc);
      if (montanteTotal !== doc.cab.totalDocumento) {
        this._plAlertService.error(
          this._plTranslateService.translate('meiosPagamento.mensagens.montanteMeioPagNaoIgualTotalDoc', {
            montante: montanteTotal,
            total: doc.cab.totalDocumento
          })
        );
        return false;
      }
    }

    return true;
  }

  private _getTroco(totalEntregue: number, doc: IJsonDocComercial): number {
    return totalEntregue && doc ? Math.max(0, round(totalEntregue - doc.cab.totalDocumento)) : 0;
  }

  private _getMontanteTotalMeiosPagamento(doc: IJsonDocComercial): number {
    return round(
      doc.meiosPagamento.reduce((accumulator: number, meioPagamento: IJsonDocComercialMeioPagam) => {
        const montante = isNumber(meioPagamento.montante) && meioPagamento.montante > 0 ? meioPagamento.montante : 0;
        return accumulator + montante;
      }, 0)
    );
  }
}
