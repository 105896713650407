import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {IImpAvisosCobrancaFilters} from './avisosCobranca.module.interface';
import {isEmpty} from 'pl-comps-angular';
import {IJsonEmailClifo} from '../../../components/emailsCliFo/jsonEmailCliFo.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonAvisoDeCobranca, IJsonAvisoDeCobrancaEmailTemplate, IJsonConfigAvisosCobranca, IJsonEmailsAvisosCobranca} from './jsonAvisosCobranca.module.interface';
import {IJsonBoolean} from '../../../../common/interfaces/json';

@Injectable({
  providedIn: 'root'
})
export class AvisoscobrancaService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/avisoscobranca`;
  }

  public getAvisosCobrancaPDFUrl(filters: IImpAvisosCobrancaFilters, reportName: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report`, {...filters, reportname: reportName});
  }

  public sendEMail(filters: IImpAvisosCobrancaFilters, mailContent: IJsonEmailClifo, reportName: string, nConta: string = ''): TServiceResponse<void> {
    const nContaDe = isEmpty(nConta.trim()) ? filters.ncontade : nConta;
    const nContaAte = isEmpty(nConta.trim()) ? filters.ncontaate : nConta;
    return this._apiService.get<void>({
      url: `${this._path}/sendmail`,
      params: {
        ncontade: nContaDe,
        ncontaate: nContaAte,
        datadocde: filters.datadocde,
        datadocate: filters.datadocate,
        datavencimentode: filters.datavencimentode,
        datavencimentoate: filters.datavencimentoate,
        nvendedorde: filters.nvendedorde,
        nvendedorate: filters.nvendedorate,
        ncondpagamde: filters.ncondpagamde,
        ncondpagamate: filters.ncondpagamate,
        body: mailContent.body,
        subject: mailContent.subject,
        emails: mailContent.emails,
        reportname: reportName
      }
    });
  }

  public getEmailsToSend(filters: IImpAvisosCobrancaFilters): TServiceResponse<IJsonEmailsAvisosCobranca> {
    return this._apiService.get<IJsonEmailsAvisosCobranca>({
      url: `${this._path}/emailstosend`,
      params: {
        ncontade: filters.ncontade,
        ncontaate: filters.ncontaate,
        datadocde: filters.datadocde,
        datadocate: filters.datadocate,
        datavencimentode: filters.datavencimentode,
        datavencimentoate: filters.datavencimentoate,
        nvendedorde: filters.nvendedorde,
        nvendedorate: filters.nvendedorate,
        ncondpagamde: filters.ncondpagamde,
        ncondpagamate: filters.ncondpagamate
      }
    });
  }

  public getAvisosCobrancaList(filters: IImpAvisosCobrancaFilters): TServiceResponse<Array<IJsonAvisoDeCobranca>> {
    return this._apiService.get<Array<IJsonAvisoDeCobranca>>({
      url: `${this._path}/gridlist`,
      params: {
        ncontade: filters.ncontade,
        ncontaate: filters.ncontaate,
        datadocde: filters.datadocde,
        datadocate: filters.datadocate,
        datavencde: filters.datavencimentode,
        datavencate: filters.datavencimentoate,
        dataultavisode: filters.dataultavisode,
        dataultavisoate: filters.dataultavisoate,
        nvendedorde: filters.nvendedorde,
        nvendedorate: filters.nvendedorate,
        ncondpagamde: filters.ncondpagamde,
        ncondpagamate: filters.ncondpagamate,
        nzonade: filters.nzonade,
        nzonaate: filters.nzonaate,
        pesqprocinterrompido: filters.pesqprocinterrompido
      }
    });
  }

  public getAvisosCobrancaPDFUrlList(filters: IImpAvisosCobrancaFilters, ncontas: string, reportName: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/gridlist/report`, {...filters, ncontas: ncontas, reportname: reportName});
  }

  public multiFileAvisoCobPDFList(filters: IImpAvisosCobrancaFilters, ncontas: string, reportName: string): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: `${this._path}/gridlist/multireport`,
      responseType: 'blob',
      params: {
        ncontade: filters.ncontade,
        ncontaate: filters.ncontaate,
        datadocde: filters.datadocde,
        datadocate: filters.datadocate,
        datavencde: filters.datavencimentode,
        datavencate: filters.datavencimentoate,
        dataultavisode: filters.dataultavisode,
        dataultavisoate: filters.dataultavisoate,
        nvendedorde: filters.nvendedorde,
        nvendedorate: filters.nvendedorate,
        ncondpagamde: filters.ncondpagamde,
        ncondpagamate: filters.ncondpagamate,
        nzonade: filters.nzonade,
        nzonaate: filters.nzonaate,
        pesqprocinterrompido: filters.pesqprocinterrompido,
        ncontas: ncontas,
        reportname: reportName
      }
    });
  }

  public checkProcessamentoInterrompido(): TServiceResponse<IJsonBoolean> {
    return this._apiService.get<IJsonBoolean>({url: `${this._path}/gridlist/proceinterrompido`});
  }

  public getConfigAvisosCobranca(): TServiceResponse<IJsonConfigAvisosCobranca> {
    return this._apiService.get<IJsonConfigAvisosCobranca>({url: `${this._path}/gridlist/config`});
  }

  public getEmailTemplate(filters: IImpAvisosCobrancaFilters, ncontas: string): TServiceResponse<IJsonAvisoDeCobrancaEmailTemplate> {
    return this._apiService.get<IJsonAvisoDeCobrancaEmailTemplate>({
      url: `${this._path}/gridlist/emailtemplate`,
      params: {
        ncontade: filters.ncontade,
        ncontaate: filters.ncontaate,
        datadocde: filters.datadocde,
        datadocate: filters.datadocate,
        datavencde: filters.datavencimentode,
        datavencate: filters.datavencimentoate,
        dataultavisode: filters.dataultavisode,
        dataultavisoate: filters.dataultavisoate,
        nvendedorde: filters.nvendedorde,
        nvendedorate: filters.nvendedorate,
        ncondpagamde: filters.ncondpagamde,
        ncondpagamate: filters.ncondpagamate,
        nzonade: filters.nzonade,
        nzonaate: filters.nzonaate,
        pesqprocinterrompido: filters.pesqprocinterrompido,
        ncontas: ncontas
      }
    });
  }

  public saveConfigAvisosCobranca(config: IJsonConfigAvisosCobranca): TServiceResponse<void> {
    return this._apiService.post<void, IJsonConfigAvisosCobranca>({url: `${this._path}/gridlist/config`, body: config});
  }

  public alteraEstadoNaoPodeFaturar(nConta: string): TServiceResponse<void> {
    return this._apiService.post<void>({url: `${this._path}/gridlist/alterapodefaturar`, params: {nconta: nConta}});
  }

  public sendEmailAvisosCobrancaList(selectedRowsList: Array<IJsonAvisoDeCobranca>, subjectEmail: string, bodyEmail: string, reportName: string): TServiceResponse<void> {
    return this._apiService.post<void, Array<IJsonAvisoDeCobranca>>({
      url: `${this._path}/gridlist/sendemail`,
      params: {
        subject: subjectEmail,
        body: bodyEmail,
        reportname: reportName
      },
      body: selectedRowsList
    });
  }
}
