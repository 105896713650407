import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {MODULE_NAME_CONCIL_MOV_AB} from './concilMovAb.module.interface';
import {ConcilMovAbModuleComponent} from './components/concilMovAb.module.component';

export const MODULE_CONCIL_MOV_AB: IModuleDefinition = {
  name: MODULE_NAME_CONCIL_MOV_AB,
  state: {
    url: `/${MODULE_NAME_CONCIL_MOV_AB}`,
    component: ConcilMovAbModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.concilmovab',
      icon: ''
    }
  }
};
