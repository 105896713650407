import {AfterViewChecked, Directive, EmbeddedViewRef, Input, IterableChanges, IterableDiffers, NgIterable, Optional, TemplateRef, TrackByFunction, ViewContainerRef} from '@angular/core';
import {NgForOf, NgForOfContext} from '@angular/common';
import {PlVirtualScrollingComponent} from './virtual.scrolling.component';

const SELECTOR_EXCLUDE = 'table,thead,tbody,tfoot,tr,th,td';

@Directive({
  selector: '[plVirtualFor][plVirtualForOf]',
  standalone: false
})
// @ts-expect-error PlVirtualScrollingForOfDirective is an exception
export class PlVirtualScrollingForOfDirective<T> extends NgForOf<T> implements AfterViewChecked {
  constructor(
    protected readonly _viewContainerRef: ViewContainerRef,
    protected readonly _templateRef: TemplateRef<NgForOfContext<T>>,
    protected readonly _iterableDiffers: IterableDiffers,
    @Optional() private readonly _plVirtualScrollingComponent: PlVirtualScrollingComponent<T>
  ) {
    super(_viewContainerRef, _templateRef, _iterableDiffers);
  }

  public ngAfterViewChecked(): void {
    if (this._plVirtualScrollingComponent) {
      const element: HTMLElement = this._viewContainerRef.element.nativeElement;
      if (element) {
        const parentElement: HTMLElement = element.parentElement;
        if (parentElement && !parentElement.matches(SELECTOR_EXCLUDE)) {
          this._plVirtualScrollingComponent.elementListView = {nativeElement: element.parentElement};
        }
      }
    }
  }

  // Inherited from NgForOf
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public _applyChanges(changes: IterableChanges<T>): void {
    // @ts-expect-error Method `_applyChanges` is private in NgForOf
    super._applyChanges(changes);
    if (this._plVirtualScrollingComponent) {
      for (let i = 0; i < this._viewContainerRef.length; i++) {
        const viewRef = <EmbeddedViewRef<any>>this._viewContainerRef.get(i);
        viewRef.context.realIndex = this._plVirtualScrollingComponent.startIndex + i;
      }
    }
  }

  @Input()
  public set plVirtualForOf(value: NgIterable<T>) {
    this.ngForOf = value;
  }

  @Input()
  public set plVirtualForTrackBy(value: TrackByFunction<T>) {
    this.ngForTrackBy = value;
  }

  @Input()
  public set plVirtualForTemplate(value: TemplateRef<NgForOfContext<T>>) {
    this.ngForTemplate = value;
  }
}
