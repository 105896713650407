<entity-detail
  [entityName]="entityName"
  [(model)]="model"
  [type]="type"
  [maintenanceMode]="maintenanceMode"
  [callback]="callback"
  [serviceMethodsOverride]="serviceMethodsOverride"
  (evtUpdate)="onUpdate($event)">
  <div *entityDetailContent>
    <pl-form [properties]="{readonly: !callback.isEditing()}">
      <pl-group>
        <label>Titulo:</label>
        <edit>
          <pl-edit type="text" attrName="titulo" [(model)]="model.titulo" [properties]="{validators: {required: {value: true}}}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label>Sopa:</label>
        <edit>
          <pl-edit type="text" attrName="sopa" [model]="modelExtended.sopa" (modelChange)="modelExtended.sopa = $event; changeDescricao()" [properties]="{validators: {required: {value: true}}}">
          </pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label>Prato:</label>
        <edit>
          <pl-edit type="text" attrName="prato" [model]="modelExtended.prato" (modelChange)="modelExtended.prato = $event; changeDescricao()" [properties]="{validators: {required: {value: true}}}">
          </pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label>Sobremesa:</label>
        <edit>
          <pl-edit type="text" attrName="sobremesa" [model]="modelExtended.sobremesa" (modelChange)="modelExtended.sobremesa = $event; changeDescricao()"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label>Activo:</label>
        <edit>
          <pl-edit type="boolean" attrName="activo" [(model)]="model.activo"></pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>
</entity-detail>
