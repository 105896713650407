import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {IListInteg, TABLE_LEGEND_INT_FAC_LEGEND} from '../intFac.module.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {TTableLegend} from '../../../components/tablelegend/tablelegend.component.interface';

@Component({
  selector: 'intFac-modal',
  templateUrl: './intFac.modal.component.html'
})
export class IntegracaoDocumentosModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public resultados: Array<IListInteg>;

  public readonly dataGridDefinition: IDevExpressDataGrid<IListInteg, number>;
  public readonly intFacDataGridLegend: TTableLegend;

  private _dataGridInstance: dxDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.dataGridDefinition = {
      columns: [
        {dataField: 'nDocumentoFacturacao', dataType: 'string', caption: 'intfac.table.nDocumentoFacturacao', groupIndex: 0},
        {dataField: 'periodo', dataType: 'string', caption: 'intfac.table.periodo', visible: false},
        {dataField: 'nDiario', dataType: 'number', caption: 'intfac.table.nDiario', visible: false},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'intfac.table.nDocInterno', visible: false},
        {dataField: 'dataDoc', dataType: 'date', caption: 'intfac.table.dataDoc'},
        {dataField: 'nContaDebito', dataType: 'string', caption: 'intfac.table.nContaDebito'},
        {dataField: 'nContaCredito', dataType: 'string', caption: 'intfac.table.nContaCredito'},
        {dataField: 'valor', dataType: 'double', caption: 'intfac.table.valor'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'intfac.table.nomeConta'},
        {dataField: 'codMoeda', dataType: 'number', caption: 'intfac.table.codMoeda', visible: false},
        {dataField: 'valorME', dataType: 'double', caption: 'intfac.table.valorME', visible: false},
        {dataField: 'valorTaxa', dataType: 'double', caption: 'intfac.table.valorTaxa', visible: false},
        {dataField: 'valorTaxaME', dataType: 'double', caption: 'intfac.table.valorTaxaME', visible: false},
        {dataField: 'codIva', dataType: 'number', caption: 'intfac.table.codIva'},
        {dataField: 'valorRetencao', dataType: 'double', caption: 'intfac.table.valorRetencao', visible: false},
        {dataField: 'nContrib', dataType: 'string', caption: 'intfac.table.nContrib', visible: false},
        {dataField: 'dataVencimento', dataType: 'date', caption: 'intfac.table.dataVenci', visible: false},
        {dataField: 'dataDocExterno', dataType: 'date', caption: 'intfac.table.dataDocExterno', visible: false},
        {dataField: 'codIntegra', dataType: 'string', caption: 'intfac.table.codIntegra', visible: false},
        {dataField: 'descricao', dataType: 'string', caption: 'intfac.table.descricao', visible: false}
      ],
      keyExpr: '_index',
      masterDetail: {enabled: false, template: 'templateMasterDetail'}
    };
    this.intFacDataGridLegend = TABLE_LEGEND_INT_FAC_LEGEND;
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = this.resultados;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    for (const linha of this.resultados) {
      if (linha.error) {
        this._dataGridInstance.expandRow(linha._index);
      } else if (this._dataGridInstance.isRowExpanded(linha._index)) {
        this._dataGridInstance.collapseRow(linha._index);
      }
    }
  }
}
