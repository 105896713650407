import {merge} from 'lodash-es';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {copy, PlAlertService, PlLocationService} from 'pl-comps-angular';
import {AuthService} from '../../../../services/auth/auth.service';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../config/constants';
import {IApiQueryRequestConfig} from '../../../../services/api/api.service.interface';
import {emptyUserNewWithAccesses, IJsonErpUser, IJsonUserRole} from '../../../../services/account/jsonUserApi.interface';
import {IJsonERPUtilizador} from '../../../../interfaces/jsonERPUtilizador.interface';
import {IJsonErp} from '../../../../interfaces/jsonUserManager.interface';
import {IModuleActionDefinition} from '../../../../components/module/module.definition.interface';
import {IUserInstallationsCallback} from '../../installations/utilizadores.installations.interface';
import {IUtilizadoresUserNewWithAccesses, IUtilizadoresUserRole} from '../../utilizadores.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {MudaPasswordModalComponent} from '../../modals/mudapassword/mudapassword.modal.component';
import {ROLE} from '../../../../services/role.const';
import {STATE_NAME_CHANGE_PASSWORD} from '../../../../states/account/changepassword/changepassword.interface';

const INDEX_COUNT = 0;
const ROLE_ADMIN: Partial<IJsonUserRole> = Object.freeze<Partial<IJsonUserRole>>({role: ROLE.ADMIN});

@Component({
  selector: 'utilizadores-basic-edit',
  templateUrl: './utilizadores.basic.edit.component.html'
})
export class UtilizadoresBasicEditComponent extends ModuloEntityDetailComponent<IUtilizadoresUserNewWithAccesses> implements OnInit {
  @Input() public administrator: boolean;
  @Input() public erps: Array<IJsonErp>;

  public readonly detailStateTypes: typeof EEntityStateDetailType;
  public readonly callbackUserInstallations: IUserInstallationsCallback;
  public readonly configUtilizadorErp: IApiQueryRequestConfig;
  public isNew: boolean;
  public form: FormGroupDirective;
  public instalacao: IJsonErp;
  public utilizadorErp: Partial<IJsonERPUtilizador>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plLocationService: PlLocationService,
    private readonly _plAlertService: PlAlertService,
    private readonly _authService: AuthService
  ) {
    super(_injector);
    this.detailStateTypes = EEntityStateDetailType;
    this.callbackUserInstallations = {};
    this.configUtilizadorErp = {params: {centralGestId: 0}};
    this.isNew = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.model = merge(emptyUserNewWithAccesses(), {administrator: false} satisfies Partial<IUtilizadoresUserNewWithAccesses>, this.model);
    this.model.sendEmail = false;
    if (this.type === EEntityStateDetailType.NEW) {
      this.model.createUtilizador = true;
    }
    if (this.model.userId === this.session.userId) {
      this.btnDelete.disabled = true;
      this.btnDelete.tooltip = {
        text: 'usersbasic.text.deleteSameUserDisabled',
        placement: 'bottom',
        disabled: false
      };
    }
    if (this.maintenanceMode) {
      this.setMaintenanceModeFullscreen(true);
    }
    this._evaluateModel();
  }

  public async onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    await super.onUpdate(stateType);
    this.isNew = stateType === EEntityStateDetailType.NEW;
    const moduleActions: Array<IModuleActionDefinition> =
      stateType === EEntityStateDetailType.DETAIL
        ? [
            {
              iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
              caption: 'users.actions.changepassword',
              action: () => this._changePassword()
            },
            {
              caption: 'users.actions.sendEmail',
              iconLeft: '<i class="fa fa-fw fa-envelope-o"></i>',
              action: () => this._sendResetPasswordEmail()
            }
          ]
        : [];
    this.setModuleActions(moduleActions);
  }

  public async save(): Promise<IUtilizadoresUserNewWithAccesses> {
    this.callback.resetErrors();

    if (!this.form.submitted) {
      this.form.onSubmit(new Event('submit'));
    }

    if (!this.utilizadorErp && (this.type !== EEntityStateDetailType.NEW || !this.model.createUtilizador)) {
      this.callback.messages().error.push('usersbasic.errors.requiredUtilizador');
    }

    if (!this.form.valid) {
      throw new Error(FORM_INVALID_CANNOT_SUBMIT);
    }

    this.callbackUserInstallations.fromInstallationsToModel();

    const model = copy(this.model);

    if (model.erps.length) {
      const nUtilizador: number = this.utilizadorErp?.nutilizador;
      const nomeUtilizadorCG: string = this.utilizadorErp?.nomeUtilizador;
      for (const erp of model.erps) {
        erp.nUtilizador = nUtilizador;
        erp.nomeUtilizadorCG = nomeUtilizadorCG;

        const roleAdminIndex: number = erp.rolesAcess.findIndex((userRole: IUtilizadoresUserRole) => userRole.role === ROLE.ADMIN);
        if (model.administrator && roleAdminIndex === -1) {
          erp.rolesAcess.push({...(<IJsonUserRole>ROLE_ADMIN)});
        }
        if (!model.administrator && roleAdminIndex !== -1) {
          erp.rolesAcess.splice(roleAdminIndex, 1);
        }

        if (!model.erp || (model.erp.centralGestId === erp.centralGestId && model.erp.nEmpresa === erp.nEmpresa)) {
          model.erp = erp;
        }
      }
    }

    model.roles = model.erp?.rolesAcess;

    if (!model.roles?.length) {
      await this._cgModalService.showOkCancel('usersbasic.noRoles.title', 'usersbasic.noRoles.message', {size: 'md'});
    }

    const stateType = this.type;

    const url = `${this._plLocationService.getUrl()}/${STATE_NAME_CHANGE_PASSWORD}`;
    const response: IUtilizadoresUserNewWithAccesses = await super.save({body: model, params: {load: false, url: url}});

    if (stateType === EEntityStateDetailType.NEW && model.sendEmail) {
      this._plAlertService.success('users.emailSuccess');
    }

    this.model = response;

    this._evaluateModel();

    return this.model;
  }

  public changedInstalacao(instalacao: IJsonErp): void {
    this.instalacao = instalacao;
    this.configUtilizadorErp.params.centralGestId = this.instalacao?.centralGestId || 0;
  }

  private _evaluateModel(): void {
    this._evaluateModelInstalacao();
    this._evaluateModelUtilizadorErp();
    this._evaluateModelAdministrator();
  }

  private _evaluateModelInstalacao(): void {
    if (this.type === EEntityStateDetailType.NEW || this.erps.length === 1 || !this.model.erp?.centralGestId) {
      this.changedInstalacao(this.erps[0]);
    } else {
      const installation: IJsonErp = this.erps.find((item: IJsonErp) => item.centralGestId === this.model.erp.centralGestId);
      this.changedInstalacao({
        centralGestId: installation?.centralGestId,
        apiUrl: installation?.apiUrl,
        name: installation?.name
      });
    }
  }

  private _evaluateModelUtilizadorErp(): void {
    this.utilizadorErp = undefined;
    const usersCount: Map<number, [number, Partial<IJsonERPUtilizador>]> = new Map<number, [number, Partial<IJsonERPUtilizador>]>();
    for (const erp of this.model.erps) {
      const nUtilizador: number = erp.nUtilizador;
      let count: [number, Partial<IJsonERPUtilizador>] = usersCount.get(nUtilizador);
      if (!count) {
        count = [0, {nutilizador: erp.nUtilizador, nomeUtilizador: erp.nomeUtilizadorCG}];
        usersCount.set(nUtilizador, count);
      }
      count[INDEX_COUNT]++;
    }
    let maxCount = 0;
    for (const [count, utilizador] of usersCount.values()) {
      if (count > maxCount) {
        maxCount = count;
        this.utilizadorErp = utilizador;
      }
    }
  }

  private _evaluateModelAdministrator(): void {
    this.model.administrator = false;
    if (this.model.erps.length) {
      const roles: Array<IJsonUserRole> = this.model.erps
        .map<Array<IJsonUserRole>>((erpUser: IJsonErpUser) => erpUser.rolesAcess)
        .reduce((previousValue: Array<IJsonUserRole>, currentValue: Array<IJsonUserRole>) => {
          return !previousValue ? currentValue : previousValue.concat(currentValue);
        });
      for (const userRole of roles) {
        if (userRole.role === ROLE.ADMIN) {
          this.model.administrator = true;
          break;
        }
      }
    }
  }

  private _changePassword(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(MudaPasswordModalComponent, {size: 'md'});
    const componentInstance: MudaPasswordModalComponent = modalInstance.componentInstance;
    componentInstance.userId = this.model.userId;
    return modalInstance.result;
  }

  private async _sendResetPasswordEmail(): Promise<void> {
    await this._authService.resetPassword(this.model.email);
    this._plAlertService.success('users.emailSuccess');
  }
}
