<div class="reconstrucaoacumulados">
  <pl-form>
    <pl-nav-wizard [definition]="definition" [onFinalize]="fnFinalize">
      <pl-nav-wizard-step title="reconstrucaoacumulados.steps.info" icon="fa-info">
        <div *plNavWizardStepContent class="reconstrucao-acumulados-info">
          <pl-form formInstanceName="info">
            <pl-group>
              <edit>
                <h4 [translate]="'reconstrucaoacumulados.steps.info'"></h4>
              </edit>
            </pl-group>

            <pl-group>
              <edit>
                <div [translate]="'reconstrucaoacumulados.description.firstParagraph'"></div>
              </edit>
            </pl-group>

            <pl-group>
              <edit>
                <div [translate]="'reconstrucaoacumulados.description.secondParagraph'"></div>
              </edit>
            </pl-group>
          </pl-form>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step title="reconstrucaoacumulados.steps.variables" icon="fa-info">
        <div *plNavWizardStepContent class="reconstrucao-acumulados-variables">
          <pl-form formInstanceName="variables" [properties]="{validators: {required: {value: true}}}">
            <pl-group>
              <edit>
                <h4 [translate]="'reconstrucaoacumulados.steps.variables'"></h4>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'reconstrucaoacumulados.fields.tipoPoc'"></label>
              <edit>
                <pl-edit
                  type="radio"
                  [model]="model.tipoPoc"
                  (modelChange)="changedTipoPoc($event)"
                  [properties]="{value: reconstrucaoAcumTipoConta.Todas, label: 'reconstrucaoacumulados.fields.todasAsContas'}">
                </pl-edit>
                <pl-edit
                  type="radio"
                  [model]="model.tipoPoc"
                  (modelChange)="changedTipoPoc($event)"
                  [properties]="{value: reconstrucaoAcumTipoConta.Especifica, label: 'reconstrucaoacumulados.fields.contaEspecifica'}">
                </pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'reconstrucaoacumulados.fields.nConta'"></label>
              <edit>
                <entity-autocomplete entity="pocs" attrName="nConta" [(selectedKey)]="model.nConta" [properties]="{disabled: model.tipoPoc !== reconstrucaoAcumTipoConta.Especifica}" output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-form>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step title="reconstrucaoacumulados.steps.finished" icon="fa-info">
        <div *plNavWizardStepContent class="reconstrucao-acumulados-finished">
          <pl-form formInstanceName="finished">
            <pl-group>
              <edit>
                <h4 [translate]="'reconstrucaoacumulados.steps.finish'"></h4>
              </edit>
            </pl-group>

            <pl-group>
              <edit>
                <span><strong [translate]="'reconstrucaoacumulados.description.atencao'"></strong></span>
                <span [translate]="'reconstrucaoacumulados.description.finishMessage'"></span>
              </edit>
            </pl-group>
          </pl-form>
        </div>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </pl-form>
</div>
