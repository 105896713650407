import {deepFreeze} from 'pl-comps-angular';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {ETipoArredondamento} from './jsonMapaExploracao.module.interface';

export interface IMapaExploracaoFilters {
  atePeriodo: string;
  atePeriodoNome?: string;
  dePeriodo: string;
  dePeriodoNome?: string;
  dePeriodoAno: string;
  loadAllAnos: number;
  loadOnlyAno: number;
}

export interface IColumnMapaExploracaoTotal {
  column: string;
  value: string;
}

export const MODULE_NAME_MAPA_EXPLORACAO = 'mapaexploracao';

export const RADIO_GROUP_TIPO_ARREDONDAMENTO_TEMPLATE: IRadioGroup<ETipoArredondamento> = deepFreeze<IRadioGroup<ETipoArredondamento>>({
  groupItems: [
    {value: ETipoArredondamento.SemArredondamento, label: 'mapaexploracao.modal.config.tipoarredondamento.semArredondamento'},
    {value: ETipoArredondamento.ArredondamentoAoEuro, label: 'mapaexploracao.modal.config.tipoarredondamento.arredondamentoAoEuro'}
  ]
});
