<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div class="pl-nav-wizard-footer-actions">
  <button
    *ngIf="navWizardInstance.selected?.hidePrevious !== true && navWizardInstance.options.hidePrevious !== true"
    type="button"
    class="btn btn-light btn-sm action-previous-step"
    plPromise
    [disabled]="evaluateFooter('previous') || navWizardInstance.options.disableNavigation || navWizardInstance.options.disablePrevious"
    [click]="navWizardInstance.previousStep">
    <i class="fa fa-angle-left fa-fw"></i> <span [plTranslate]="navWizardInstance.selected?.captionBtnGoBack"></span>
  </button>

  <button
    *ngIf="!evaluateFooter('next') && navWizardInstance.selected?.hideNext !== true && navWizardInstance.options.hideNext !== true"
    type="button"
    class="btn btn-primary btn-sm action-next-step"
    plPromise
    [disabled]="navWizardInstance.options.disableNavigation || navWizardInstance.options.disableNext"
    [click]="navWizardInstance.nextStep">
    <span [plTranslate]="navWizardInstance.selected?.captionBtnGoForward"></span>&nbsp;<i class="fa fa-angle-right fa-fw"></i>
  </button>

  <ng-container *ngIf="navWizardInstance.selected?.actions?.length">
    <ng-container *ngFor="let action of navWizardInstance.selected?.actions">
      <pl-tooltip *ngIf="action.visible !== false" [config]="action.tooltip">
        <button *plTooltipContent type="button" class="btn btn-sm action-click" plPromise [ngClass]="action.class" [disabled]="action.disabled" [click]="fnActionClick(action)">
          <span *ngIf="action.icon"><i [cgcIcon]="action.icon"></i>&nbsp;</span><span [plTranslate]="action.caption"></span>
        </button>
      </pl-tooltip>
    </ng-container>
  </ng-container>

  <button
    *ngIf="evaluateFooter('next') && navWizardInstance.selected?.hideFinalize !== true && navWizardInstance.options.hideFinalize !== true"
    type="button"
    class="btn btn-success btn-sm action-finalize"
    plPromise
    [disabled]="navWizardInstance.options.disableFinalize"
    [click]="navWizardInstance.nextStep">
    <i class="fa fa-check fa-fw"></i>&nbsp;<span [plTranslate]="navWizardInstance.selected?.captionBtnFinalize"></span>
  </button>
</div>
