export enum ETipoTransmissaoActiva {
  NA,
  TransmissaoSujeita,
  RecebimentosAntecipadosDoAno,
  OpLocalizadasMNPorFornecEstrangeiroComReverseChargeIvaDevidoPeloAdquirente,
  TransmissaoIsentaSemDireitoADeducao,
  ServicosDeContrucaoCivilComReverseChargeIvaDevidoPeloAdquirente,
  TransmissaoDeSucatasComReverseChargeIvaDevidoPeloAdquirente,
  TransmissaoIsentaOuNaoSujeitasComDireitoADeducaoArt21lBDoN1,
  TransmissaoIsentaDeViaturasDeTurismoArt9N32QeNaoFoiDeduzidoIva,
  TransmissaoIsentasDeIvaPorSujeicaoAIMTArt9N30,
  RecebimentosAntecipadosDoAnoAnteriorComFacturacaoDefinitivaNoAno,
  TransmissaoDeEstabelecimentoArt3N4,
  OutrasOperacoesActivasForaDoCampoDoCIVA,
  TransmissaoIntraComunitaria,
  TransmissaoExportacao,
  OpLocalizadasCaPorFornecResidentesUEComReverseChargeIvaDevidoPeloAdquirente,
  TransmissaoOuroDL362de99ComReverseChargeIvaDevidoPeloAdquirente,
  TransmissaoDeImoveisComRenunciaIsencaoComReverseChargeIvaDevidoPeloAdquirente,
  OpDeAfectacaoAUsoProprioTransmissoesGratuitasEAfectacaoAUmSectorActividadeIsento,
  OpDeActividadeBancaria,
  OpDeActividadeSeguradora,
  OpDeActividadeDeLocacaoDeBensImoveis,
  TransmissaoIntraComunitariaTriangular,
  PrestacaoServicosIntraComunitaria,
  TransmissaoMeiosTransporteNovosAParticularesDaUE,
  EmissoesGasesEfeitoEstufaIvaDevidoPeloAdquirente,
  OperacoesEfetuadasNoEstrangeiro,
  OutrasOperacoesIsentasOuSemLiquidacaoDeIVAQueConferemDireitoADeducao
}

export const DATA_SOURCE_NAME_TIPO_TRANSMISSAO_ATIVA = 'tipoTransmissaoActiva';
