import {EExtratosDTTipo, MODULE_NAME_EXTRATOS_DT, MODULE_NAME_EXTRATOS_DT_CLIENTES, MODULE_NAME_EXTRATOS_DT_FORNECEDORES} from './extratosDT.module.interface';
import {ExtratosDTModuleComponent} from './components/extratosDT.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {RESOLVER_EMPRESA_ANO_EM_CURSO_IRC} from '../../../../services/config/config.service.router';
import {RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL} from '../../../../services/contabilidadedigital/contabilidadedigital.router.interface';
import {ROLE} from '../../../../services/role.const';

export const MODULE_ESTATISTICA_EXTRATOS_DT: IModuleDefinition = generateModule(MODULE_NAME_EXTRATOS_DT, EExtratosDTTipo.Geral, 'portals.sidebar.modules.extratosDT');
export const MODULE_ESTATISTICA_EXTRATOS_DT_CLIENTES: IModuleDefinition = generateModule(MODULE_NAME_EXTRATOS_DT_CLIENTES, EExtratosDTTipo.Clientes);
export const MODULE_ESTATISTICA_EXTRATOS_DT_FORNECEDORES: IModuleDefinition = generateModule(MODULE_NAME_EXTRATOS_DT_FORNECEDORES, EExtratosDTTipo.Fornecedores);

function generateModule(moduleName: string, moduleType: EExtratosDTTipo, sidebarTitle?: string): IModuleDefinition {
  return {
    name: moduleName,
    state: {
      url: `/${moduleName.toLowerCase()}`,
      component: ExtratosDTModuleComponent,
      data: {
        roles: [ROLE.CONTABILIDADE, ROLE.ERP, ROLE.PCA, ROLE.FINANCEIRO],
        sidebarTitle: sidebarTitle
      },
      params: {
        nConta: {
          type: 'string',
          value: ''
        },
        nomeConta: {
          type: 'string',
          value: ''
        },
        temCC: {
          type: 'bool',
          value: false
        }
      },
      resolve: [
        {
          provide: 'moduleType',
          useFactory: () => moduleType
        },
        {
          provide: 'moduleName',
          useFactory: () => moduleName
        },
        RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL,
        RESOLVER_EMPRESA_ANO_EM_CURSO_IRC
      ]
    }
  };
}
