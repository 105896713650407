import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {MODULE_NAME_RPTCONFIGS} from './rptConfigs.module.interface';
import {TServiceQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';
import {IJsonRptConfigModulo, IJsonRptConfigVariavel} from './jsonRptConfig.module.interface';

@Injectable({
  providedIn: 'root'
})
export class RptConfigsService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/${MODULE_NAME_RPTCONFIGS}`;
  }

  public getModulos(): TServiceQueryResponse<IJsonRptConfigModulo> {
    return this._apiService.query<IJsonRptConfigModulo>({url: this._path});
  }

  public getVariables(directoria: string): TServiceQueryResponse<IJsonRptConfigVariavel> {
    return this._apiService.query<IJsonRptConfigVariavel>({url: `${this._path}/${directoria}`});
  }

  public saveRptConfigs(directoria: string, variables: Array<IJsonRptConfigVariavel>): TServiceResponse<boolean> {
    return this._apiService.post<boolean, Array<IJsonRptConfigVariavel>>({url: `${this._path}/${directoria}`, body: variables});
  }
}
