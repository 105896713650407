import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {IJsonDocumFac, IJsonIntFac, IJsonListaInteg} from './jsonIntFac.module.interface';
import {MODULE_NAME_INTEGRACAO_DOCUMENTOS} from './intFac.module.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class IntegracaoDocumentosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/${MODULE_NAME_INTEGRACAO_DOCUMENTOS}`;
  }

  public getDocumentos(tipoDocumentos: string): TServiceResponse<Array<IJsonIntFac>> {
    return this._apiService.get<Array<IJsonIntFac>>({url: this._path, params: {tiposDocumento: tipoDocumentos}});
  }

  public getTiposDocumentos(): TServiceResponse<Array<IJsonDocumFac>> {
    return this._apiService.get<Array<IJsonDocumFac>>({url: `${this._path}/tiposdocumento`});
  }

  public integraDocumentos(encerraDocumentos: Array<IJsonIntFac>, simular: boolean): TServiceResponse<Array<IJsonListaInteg>> {
    return this._apiService.post<Array<IJsonListaInteg>, Array<IJsonIntFac>>({
      url: this._path,
      params: {simular: simular},
      body: encerraDocumentos
    });
  }
}
