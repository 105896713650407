<div class="pca-balancetes">
  <ng-container [ngSwitch]="isTableView">
    <div *ngSwitchDefault>
      <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
    </div>

    <div *ngSwitchCase="true">
      <ng-container>
        <balancetes-grid [balanceteComplList]="balanceteComplList" [myAccounting]="true"></balancetes-grid>
      </ng-container>
    </div>
  </ng-container>
</div>
