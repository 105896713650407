import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isEmpty, isNumber, isObject, PlAlertService, PlI18nService, PlTranslateService} from 'pl-comps-angular';
import {ECalendarioTipoDiaStr} from '../../../../../../../entities/calendarios/jsonCalendarios.entity.interface';
import {ENTITY_NAME_DESCONTOS} from '../../../../../../../entities/abdes/abdes.entity.interface';
import {ENTITY_NAME_RH_TIPOS_PROCESSAMENTO} from '../../../../../../../entities/rhTiposProcessamento/rhTiposProcessamento.entity.interface';
import {EntityServiceBuilder} from '../../../../../../../services/entity/entity.service.builder';
import {ETipoValorUnitarioRecolher} from '../../../../../../../datasources/tipovalorunitariorecolher/tipoValorUnitarioRecolher.datasource.interface';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../../../../config/constants';
import {GestaoDGEMPSMarcarAbstractModalComponent} from '../../../../../../../components/gestaodgemps/modals/marcar/gestaodgemps.marcar.abstract.modal.component';
import {gestaoDGEMPSMarcarHoraFim, gestaoDGEMPSMarcarHoraInicio} from '../../../../../../../components/gestaodgemps/gestaodgemps.utilities';
import {IEntityService} from '../../../../../../../services/entity/entity.service.interface';
import {IGestaoDGEMPSCalendario, IGestaoDGEMPSMarcarModalData} from '../../../../../../../components/gestaodgemps/gestaodgemps.interface';
import {IJsonABDES} from '../../../../../../../entities/abdes/jsonABDES.entity.interface';
import {IJsonDGEMPCalendario} from '../../../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHEventosConfig} from '../../../../../../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import {IJsonPRHMarcarFaltas} from '../../../../../../../interfaces/jsonPRHFaltas.interface';
import {IJsonRHTipoProcessamento} from '../../../../../../../entities/rhTiposProcessamento/jsonRhTiposProcessamento.entity.interface';
import moment, {Moment} from 'moment';
import {PortalClientAccountService} from '../../../../../../../services/portalclientaccount/portalclientaccount.service';
import {TDate} from '../../../../../../../../common/dates';

@Component({
  selector: 'pca-rh-gestao-dgemps-faltas-marcar-modal',
  templateUrl: './pca.rh.gestaoDGEMPS.faltas.marcar.modal.component.html'
})
export class PcaRhGestaoDGEMPSFaltasMarcarModalComponent extends GestaoDGEMPSMarcarAbstractModalComponent implements OnInit {
  public readonly outputTipoProcessamento: string;
  public readonly outputABDES: string;

  public tipoFalta: IJsonPRHEventosConfig;
  public processamento: IJsonRHTipoProcessamento;
  public desconto: IJsonABDES;
  public allDay: boolean;
  public horaInicio: TDate;
  public horaFim: TDate;
  public dataProcessamento: TDate;
  public dataProcessamentoChecked: boolean;
  public promise: Promise<void>;
  public endDate: TDate;

  private readonly _serviceRHTipoProcessamento: IEntityService<IJsonRHTipoProcessamento>;
  private readonly _serviceABDES: IEntityService<IJsonABDES>;
  private _userChangedProcessamento: boolean;
  private _userChangedDesconto: boolean;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _plI18nService: PlI18nService,
    protected readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _portalClientAccountService: PortalClientAccountService
  ) {
    super(_injector, _plI18nService, _plTranslateService);
    this.outputTipoProcessamento = '{{tipoProcessamento}} - {{descricao}}';
    this.outputABDES = '{{codAbDesc}} - {{designacaoBreve}}';
    this.allDay = true;
    this.horaInicio = gestaoDGEMPSMarcarHoraInicio();
    this.horaFim = gestaoDGEMPSMarcarHoraFim();
    this._serviceRHTipoProcessamento = this._entityServiceBuilder.build<IJsonRHTipoProcessamento>(ENTITY_NAME_RH_TIPOS_PROCESSAMENTO);
    this._serviceABDES = this._entityServiceBuilder.build<IJsonABDES>(ENTITY_NAME_DESCONTOS);
    this._userChangedProcessamento = false;
    this._userChangedDesconto = false;
    this.dataProcessamentoChecked = false;
    this.dataProcessamento = moment();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const {endDate}: IGestaoDGEMPSMarcarModalData = this.marcarData;
    this.endDate = endDate;
    this.dataProcessamento = this.endDate;
  }

  public changedTipoFalta(tipoFalta: IJsonPRHEventosConfig): void {
    this.tipoFalta = tipoFalta;

    this.promise = Promise.all([this._fetchProcessamento(this.tipoFalta?.tipoProcessamento), this._fetchDesconto(this.tipoFalta?.ncodAbdes)]).then(() => undefined);
  }

  public changedProcessamento(processamento: IJsonRHTipoProcessamento): void {
    this.processamento = processamento;
    this._userChangedProcessamento = isObject(this.processamento);
  }

  public changedDesconto(desconto: IJsonABDES): void {
    this.desconto = desconto;
    this._userChangedDesconto = isObject(this.desconto);
  }

  public dataProcessamentoCheckedChange(value: boolean): void {
    this.dataProcessamentoChecked = value;

    this.dataProcessamento = value ? moment(this.endDate).add(1, 'month').endOf('month') : this.endDate;
  }

  public close(): Promise<void> {
    if (!this.tipoFalta) {
      this._plAlertService.error('gestaodgemps.errors.invalidTipoFalta');
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    if (!this.processamento || !this.desconto) {
      if (!this.processamento) {
        this._plAlertService.error('gestaodgemps.errors.invalidTipoProcessamento');
      }
      if (!this.desconto) {
        this._plAlertService.error('gestaodgemps.errors.invalidDesconto');
      }
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }

    this.disableClose();

    const {viewDate, startDate, endDate}: IGestaoDGEMPSMarcarModalData = this.marcarData;
    const calendarios: Array<IGestaoDGEMPSCalendario> = this.marcarData.calendarios.slice();

    const fnMarcarFaltas = (): Promise<void> => {
      const calendario = calendarios.shift();
      if (!calendario) {
        return Promise.resolve();
      }
      return this._marcarFalta(viewDate, startDate, endDate, calendario).then(fnMarcarFaltas);
    };

    return fnMarcarFaltas()
      .then(() => {
        this.enableClose();
        super.close();
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }

  private _fetchProcessamento(tipoProcessamento: number): Promise<void> {
    if (this._userChangedProcessamento) {
      return Promise.resolve();
    }
    if (!isNumber(tipoProcessamento)) {
      if (this.processamento) {
        this.processamento = undefined;
      }
      return Promise.resolve();
    }
    return this._serviceRHTipoProcessamento.get({id: tipoProcessamento}).then((response: HttpResponse<IJsonRHTipoProcessamento>) => {
      this.processamento = response.body;
    });
  }

  private _fetchDesconto(nCodAbDesc: string): Promise<void> {
    if (this._userChangedDesconto) {
      return Promise.resolve();
    }
    if (isEmpty(nCodAbDesc)) {
      if (this.desconto) {
        this.desconto = undefined;
      }
      return Promise.resolve();
    }
    return this._serviceABDES.get({id: nCodAbDesc}).then((response: HttpResponse<IJsonABDES>) => {
      this.desconto = response.body;
    });
  }

  private _marcarFalta(viewDate: Moment, startDate: Moment, endDate: Moment, calendario: IGestaoDGEMPSCalendario): Promise<void> {
    const faltas: IJsonPRHMarcarFaltas = {
      cab: undefined,
      linhas: [],
      motivo: undefined,
      codEmpLanca: this.codEmpLanca,
      codServico: this.codServico,
      proximoPapel: -1
    };

    for (const dataAtual = startDate.clone(); dataAtual.diff(endDate, 'days') <= 0; dataAtual.add(1, 'day')) {
      const dataCalendario: IJsonDGEMPCalendario = calendario.listaCalendario.find((day: IJsonDGEMPCalendario) => dataAtual.isSame(day.data, 'day'));
      if (!dataCalendario || dataCalendario.tipoDiaStr === ECalendarioTipoDiaStr.DiaUtil || this.desconto?.tipoValorUnitRecolha === ETipoValorUnitarioRecolher.REmuneracaoPorDiaBase30) {
        const horaInicio: Moment = !this.allDay ? moment(this.horaInicio) : dataAtual.clone().startOf('day');
        const horaFim: Moment = !this.allDay ? moment(this.horaFim) : dataAtual.clone().endOf('day');

        const dataInicio: Moment = viewDate.clone().set({
          hours: horaInicio.hours(),
          minutes: horaInicio.minutes(),
          seconds: horaInicio.seconds(),
          milliseconds: horaInicio.milliseconds()
        });
        const dataFim: Moment = viewDate.clone().set({
          hours: horaFim.hours(),
          minutes: horaFim.minutes(),
          seconds: horaFim.seconds(),
          milliseconds: horaFim.milliseconds()
        });

        faltas.linhas.push({
          idFaltas: undefined,
          idTarefaCab: undefined,
          data: dataAtual.clone(),
          horaInicio: dataInicio,
          horaFim: dataFim,
          tipoProcessamento: this.processamento.tipoProcessamento,
          ncodAbdes: this.desconto.codAbDesc,
          tipoFalta: this.tipoFalta.tipo,
          ocupacaoId: undefined,
          posicao: undefined,
          dataProcessamento: this.dataProcessamento,
          efetuaMarcacaoDataProcessamento: this.dataProcessamentoChecked
        });
      }
    }

    return this._portalClientAccountService.marcarFaltasEmpregado(calendario.codEmp, faltas).then(() => {
      this._successfulCalendarios.add(calendario);
      for (let i = this.marcarData.calendarios.length - 1; i >= 0; i--) {
        if (this.marcarData.calendarios[i].codEmp === calendario.codEmp) {
          this.marcarData.calendarios.splice(i, 1);
        }
      }
    });
  }
}
