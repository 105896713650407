import {HttpResponse} from '@angular/common/http';
import {ENTITY_NAME_RHPENHORAS} from './rhPenhoras.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonPenhoraDecimais, IJsonPenhoraDescontos, IJsonRHPenhoras} from './jsonRHPenhoras.entity.interface';
import {RhPenhorasEditComponent} from './components/edit/rhPenhoras.entity.edit.component';
import {RhPenhorasListComponent} from './components/list/rhPenhoras.entity.list.component';
import {RHPenhorasService} from './rhPenhoras.entity.service';
import {ROLE} from '../../services/role.const';

export const ENTITY_RHPENHORAS: IEntityDefinition<IJsonRHPenhoras> = {
  name: ENTITY_NAME_RHPENHORAS,
  roles: [ROLE.RH],
  searchPlaceholder: 'rhpenhoras.pesquisa',
  pageTitle: 'global.menu.rhpenhoras',
  metadata: {
    keyName: 'rhpenhorasID',
    fields: [
      {name: 'rhpenhorasID', type: 'text', visible: false},
      {name: 'codEMP', caption: 'rhpenhoras.fields.codEMP', visible: false},
      {
        name: 'nomeEmpr',
        caption: 'rhrecolhadados.fields.codEMP',
        entity: {
          name: 'dgemps',
          keyTarget: 'codEMP',
          output: '{{codEmp}} - {{nome}}',
          outputTarget: 'nomeEmpr',
          fieldsMap: {codEmp: 'codEMP', nome: 'nomeEmpr'}
        },
        properties: {
          devExpress: {
            dataGrid: {
              calculateCellValue: (rowData: IJsonRHPenhoras) => `${rowData.codEMP} - ${rowData.nomeEmpr}`
            }
          }
        }
      },
      {
        name: 'ncodABDESC',
        type: 'text',
        caption: 'rhpenhoras.fields.ncodABDESC',
        properties: {
          devExpress: {
            dataGrid: {
              calculateCellValue: (rowData: IJsonRHPenhoras): string => {
                const codAbDes: string = rowData.ncodABDESC.substring(1);
                return `${codAbDes} - ${rowData.nomeAbdes}`;
              }
            }
          }
        }
      },
      {name: 'valorTotalPenhora', type: 'number', caption: 'rhpenhoras.fields.valorTotalPenhora'},
      {name: 'valor', type: 'number', caption: 'rhpenhoras.fields.tipoValor'},
      {name: 'dataInicio', type: 'date', caption: 'rhpenhoras.fields.dataInicio'},
      {name: 'tipoProcessamento', visible: false},
      {
        name: 'nomeTipoProcessamento',
        caption: 'rhpenhoras.fields.tipoProcessamento',
        entity: {
          name: 'rhtipoprocess',
          keyTarget: 'tipoProcessamento',
          output: '{{tipoProcessamento}} - {{descricao}}',
          outputTarget: 'nomeTipoProcessamento',
          fieldsMap: {descricao: 'nomeTipoProcessamento'}
        },
        properties: {
          devExpress: {
            dataGrid: {
              calculateCellValue: (rowData: IJsonRHPenhoras): string => {
                let processamento = '0 - Todos os processamentos';
                if (rowData.tipoProcessamento > 0) {
                  processamento = `${rowData.tipoProcessamento} - ${rowData.nomeTipoProcessamento}`;
                }
                return processamento;
              }
            }
          }
        }
      },
      {name: 'identificacaoPenhora', type: 'text', caption: 'rhpenhoras.fields.identificacaoPenhora', visible: false}
    ],
    order: 'codEMP',
    searchFields: 'valor,codEMP,dataInicio'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      component: RhPenhorasListComponent
    }
  },
  detail: {
    state: {
      component: RhPenhorasEditComponent,
      resolve: [
        {
          provide: 'decimalsLimit',
          deps: [RHPenhorasService],
          useFactory: (rhPenhorasService: RHPenhorasService): Promise<IJsonPenhoraDecimais> => {
            return rhPenhorasService.getDecimaisPorDefeito().then((response: HttpResponse<IJsonPenhoraDecimais>) => response.body);
          }
        },
        {
          provide: 'descontosList',
          deps: [RHPenhorasService],
          useFactory: (rhPenhorasService: RHPenhorasService): Promise<Array<IJsonPenhoraDescontos>> => {
            return rhPenhorasService.getDescontosPorCategoria().then((response: HttpResponse<Array<IJsonPenhoraDescontos>>) => response.body);
          }
        }
      ]
    }
  }
};
