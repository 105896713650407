export enum EDashboardAtivosRadical {
  Rdl431 = '431',
  Rdl432 = '432',
  Rdl433 = '433',
  Rdl434 = '434',
  Rdl435 = '435',
  Rdl436 = '436',
  Rdl437 = '437',
  Rdl441 = '441',
  Rdl442 = '442',
  Rdl443 = '443',
  Rdl444 = '444',
  Rdl445 = '445',
  Rdl446 = '446',
  Rdl447 = '447',
  Rdl421 = '421',
  Rdl422 = '422',
  Rdl423 = '423',
  Rdl424 = '424',
  Rdl425 = '425',
  Rdl426 = '426',
  Rdl427 = '427'
}

export interface IDashboardAtivosPie {
  nFichas: string;
  name: string;
  value: number;
  percent: number;
  descRadical: string;
}

export const MODULE_NAME_DASHBOARD_ATIVOS = 'dashboardativos';
