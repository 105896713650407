import type {Dictionary} from 'lodash';
import {groupBy, sortBy} from 'lodash-es';
import type {IPlToolbarItemDefinition, TPlToolbarItem} from './toolbar.interface';
import {isArray} from '../common/utilities/utilities';

export function sortToolbarItems<T extends IPlToolbarItemDefinition>(items: Array<T>, by: keyof TPlToolbarItem = 'order'): Array<T> {
  if (!isArray(items) || !items.length) {
    return items;
  }

  const groups: Dictionary<Array<T>> = groupBy(items, 'align');
  let alignStart: Array<T> = (groups.left || []).concat(groups.undefined || []);
  alignStart = sortBy(alignStart, by);

  let alignEnd: Array<T> = groups.right || [];
  alignEnd = sortBy(alignEnd, by);

  const sortedItems: Array<T> = alignStart.concat(alignEnd);

  for (const item of items) {
    if (isArray(item.items) && item.items.length) {
      item.items = sortToolbarItems(item.items, by);
    }
    if (isArray(item.menu) && item.menu.length) {
      item.menu = sortToolbarItems(item.menu, by);
    }
  }

  return sortedItems;
}
