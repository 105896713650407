import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {IPlTableDefinition, IPlToolbarItem, PlAlertService} from 'pl-comps-angular';
import {ModuloComponent} from '../../../../components/module/module.component';
import {AtivosCoeficienteCMService} from '../ativosCoeficienteCM.module.service';
import {IJsonAtivosCoeficienteCM} from '../jsonAtivosCoeficienteCM.module.interface';
import {HttpResponse} from '@angular/common/http';
import {IJsonAtivosNumber} from '../../../../entities/ativos/interfaces/jsonAtivos.entity.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';

@Component({
  selector: 'module-ativos-coeficientecm',
  templateUrl: './ativosCoeficienteCM.module.component.html'
})
export class AtivosCoeficienteCMModuleComponent extends ModuloComponent implements OnInit {
  @Input() public anosPesquisa: Array<IJsonAtivosNumber>;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly definition: IPlTableDefinition;
  public readonly metodoSource: Array<number>;

  public coeficienteCMList: Array<IJsonAtivosCoeficienteCM>;
  public selectedRowKeys: Array<number>;
  public selectedLine: IJsonAtivosCoeficienteCM;
  public ano: number;

  private readonly _btnGravarCoeficientes: IPlToolbarItem;
  private _dataGridInstance: dxDataGrid;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosCoeficienteCMService: AtivosCoeficienteCMService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.pesquisaCoeficientes = this.pesquisaCoeficientes.bind(this);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {
          caption: 'ativoscoeficientecm.table.ativo',
          alignment: 'center',
          columns: [
            {dataField: 'codAtivo', caption: 'ativoscoeficientecm.table.codativo'},
            {dataField: 'designacao', caption: 'ativoscoeficientecm.table.designacao', width: 150},
            {dataField: 'dataAquisicao', caption: 'ativoscoeficientecm.table.dataaquisicao', dataType: 'date'},
            {dataField: 'dataVenda', caption: 'ativoscoeficientecm.table.dataavenda', dataType: 'date'},
            {dataField: 'valor', caption: 'ativoscoeficientecm.table.valor', dataType: 'double'}
          ]
        },
        {
          caption: 'ativoscoeficientecm.table.coeficientes',
          alignment: 'center',
          columns: [
            {dataField: 'coefCorrMonet', caption: 'ativoscoeficientecm.table.coefatual', dataType: 'double'},
            {dataField: 'novoCoefCorrMonet', caption: 'ativoscoeficientecm.table.coefcorrigido', dataType: 'double'}
          ]
        },
        {
          caption: 'ativoscoeficientecm.table.maismenosvalias',
          alignment: 'center',
          columns: [
            {dataField: 'contabMaisMenosValiaValorSinal', caption: 'ativoscoeficientecm.table.contab', dataType: 'double'},
            {dataField: 'fiscalMaisMenosValiaValorSinal', caption: 'ativoscoeficientecm.table.fiscal', dataType: 'double'},
            {dataField: 'calcFiscalMaisMenosValiaValorSinal', caption: 'ativoscoeficientecm.table.fiscalcorrig', dataType: 'double'}
          ]
        }
      ],
      height: '60vh',
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      keyExpr: 'codAtivo',
      repaintChangesOnly: true,
      scrolling: {columnRenderingMode: 'virtual', rowRenderingMode: 'virtual'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'}
    };
    this.metodoSource = [];
    this.selectedRowKeys = [];
    this.selectedLine = undefined;
    this.coeficienteCMList = [];
    this._btnGravarCoeficientes = {
      id: 'gravar',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-save fa-fw"></i>',
      class: 'btn-primary',
      caption: 'global.btn.save',
      disabled: true,
      tooltip: {
        text: 'ativoscoeficientecm.btn.gravaTooltip',
        placement: ['bottom', 'bottom-left']
      },
      click: () => this._gravaCoeficientes()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnGravarCoeficientes);
    this._setSourceAnosPesquisa();
    if (this.anosPesquisa) {
      this.ano = this.anosPesquisa[0].value;
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onSelectionChanged(): void {
    this._btnGravarCoeficientes.disabled = this.selectedRowKeys.length < 1;
  }

  public async pesquisaCoeficientes(): Promise<void> {
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    try {
      const response: HttpResponse<Array<IJsonAtivosCoeficienteCM>> = await this._ativosCoeficienteCMService.getPesquisaCoeficientes(this.ano);
      this.coeficienteCMList = response.body;
      this._btnGravarCoeficientes.disabled = this.selectedRowKeys.length < 1;
      this.selectedRowKeys = [];

      if (response.body?.length) {
        this._cardPanel.collapse();
      } else {
        this._cardPanel.focusFirstElement();
        this._plAlertService.info('global.text.searchNoData');
      }
    } finally {
      this._dataGridInstance.endCustomLoading();
    }
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _setSourceAnosPesquisa(): void {
    if (this.anosPesquisa) {
      for (const source of this.anosPesquisa) {
        this.metodoSource.push(source.value);
      }
    }
  }

  private _gravaCoeficientes(): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'ativoscoeficientecm.message.temcerteza').then(() => {
      const selectedKeys: Array<number> = this._dataGridInstance.getSelectedRowKeys();
      for (const selectedKey of selectedKeys) {
        this.coeficienteCMList.find((item: IJsonAtivosCoeficienteCM) => item.codAtivo === selectedKey).sel = true;
      }
      return this._ativosCoeficienteCMService.postGravaCoeficientes(this.ano, this.coeficienteCMList).then((response: HttpResponse<Array<IJsonAtivosCoeficienteCM>>) => {
        this.coeficienteCMList = response.body;
        this.selectedRowKeys = [];
        this._plAlertService.success('ativoscoeficientecm.message.success');
        this._btnGravarCoeficientes.disabled = this.selectedRowKeys.length < 1;
      });
    });
  }
}
