import {Component, Injector, OnInit} from '@angular/core';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {MODULE_NAME_CONTROLO_IVA_ENTRE_EMPRESAS} from '../../../../modules/controloivaentreempresas/controloIVAEntreEmpresas.module.interface';
import {IJsonControloIVA} from '../../jsonControloIVA.entity.interface';

@Component({
  selector: 'controlo-iva-list',
  templateUrl: './controloIVA.entity.list.component.html'
})
export class ControloIVAListComponent extends ModuloEntityListComponent<IJsonControloIVA> implements OnInit {
  private readonly _maintenanceControloIvaEntreEmpresas: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this._maintenanceControloIvaEntreEmpresas = this._moduleMaintenanceService.build(MODULE_NAME_CONTROLO_IVA_ENTRE_EMPRESAS);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'btn-controlo-iva-entre-empresas',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-copy"></i>',
      class: 'btn-primary',
      caption: 'controloIVA.btn.controloIVAEntreEmpresas',
      click: () => this._openControloIvaEntreEmpresas()
    });
  }

  private _openControloIvaEntreEmpresas(): Promise<void> {
    return this._maintenanceControloIvaEntreEmpresas.maintenance();
  }
}
