import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EAnalisadorTesLancoForeignOrigin, IAnalisadorTesLanco} from '../../analisadorTes.module.interface';
import {EMPTY_GUID} from '../../../../../../config/constants';
import {EWeekDay, isDefinedNotNull, PlAlertService} from 'pl-comps-angular';
import {MIN_DATE_CG, MIN_DATE_CGD} from '../../../../../../common/utils/utils';
import moment from 'moment';
import {TDate} from '../../../../../../common/dates';

const SQL_MIN_DATETIME_FORMAT = '1753-01-01T00:00:00.000Z';

@Component({
  selector: 'analisador-tesouraria-teslanco-modal',
  templateUrl: './analisadorTes.tesLanco.modal.component.html'
})
export class AnalisadorTesTeslancoModalComponent extends CGModalComponent<IAnalisadorTesLanco> {
  @Input() public model: IAnalisadorTesLanco;
  @Input() public showValorField: boolean;
  public promise: Promise<unknown>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.model = {
      data: undefined,
      tesLancoID: EMPTY_GUID,
      nota: '',
      tesRubricaID: -1,
      notaLastUpd: undefined,
      valor: 0,
      descricao: '',
      foreignKeyID: '',
      foreignOrigin: EAnalisadorTesLancoForeignOrigin.None,
      nLanc: EMPTY_GUID
    };
  }

  public showNotaLastUpdField(): boolean {
    return isDefinedNotNull(this.model.notaLastUpd) && this.model.notaLastUpd !== MIN_DATE_CG && this.model.notaLastUpd !== MIN_DATE_CGD && this.model.notaLastUpd !== SQL_MIN_DATETIME_FORMAT;
  }

  public getNotaLastUpdField(): string {
    if (!this.showNotaLastUpdField()) {
      return '';
    }
    return moment(this.model.notaLastUpd).format(this.format.momentDatetime);
  }

  public closeModal(): void {
    if (moment().isAfter(this.model.data, 'day')) {
      this._plAlertService.error('analisadorTes.messages.aDataNaoPodeSerInferiorADataAtual');
      return;
    }
    if (this._isWeekEnd(this.model.data)) {
      this._plAlertService.error('analisadorTes.messages.aDataNaoPodeSerUmDiaDeFimDeSemana');
      return;
    }
    this.close(this.model);
  }

  private _isWeekEnd(date: TDate): boolean {
    const dw = moment(date).day();
    return dw === EWeekDay.Saturday || dw === EWeekDay.Sunday;
  }
}
