export enum ETipoContratoQuadroPessoal {
  SemTermo = '10',
  PrestacapoSubordinadaTeletrabalhoSemTermo = '11',
  ComissaoServicoSemTermo = '12',
  IntermitenteSemTermo = '13',
  TempoIndeterminadoCedenciaTemporaria = '14',
  TermoCerto = '20',
  PrestacaoSubordinadaTeletrabalhoTermoCerto = '21',
  ComissaoServicoTermoCerto = '22',
  TemporarioTermoCerto = '23',
  TermoIncerto = '30',
  PrestacaoSubordinadaTeletrabalhoTermoIncerto = '31',
  ComissaoServicoTermoIncerto = '32',
  TemporarioTermoIncerto = '33',
  OutraSituacao = '80'
}

export interface IJsonTiposContrato {
  nTipoContrato: string;
  designacaoBreve: string;
  designacaoCompleta: string;
  codTipCtQPessoal: ETipoContratoQuadroPessoal;
  naoEnviaparaDMR: boolean;
}
