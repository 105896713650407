import {AbstractControl, UntypedFormArray, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {skipIf} from 'pl-comps-angular';
import {ICGValidatorWithSubscription} from './validators.interface';
import {validatePasswordSecureMode} from '../../config/constants';

export class CGValidators {
  public static equals(path: Array<string | number> | string): ICGValidatorWithSubscription {
    let subscribeValue = false;

    const result: ICGValidatorWithSubscription = {validatorFn: undefined, subscription: undefined};

    result.validatorFn = (control: AbstractControl): ValidationErrors | null => {
      const root: UntypedFormGroup | UntypedFormArray = <UntypedFormGroup | UntypedFormArray>control.root;
      if (!root) {
        return null;
      }

      if (!result.subscription && root.valueChanges) {
        result.subscription = root.valueChanges.pipe(skipIf(() => subscribeValue)).subscribe(() => {
          subscribeValue = true;
          control.updateValueAndValidity();
          subscribeValue = false;
        });
      }

      const toCompare: AbstractControl = root.get(path);
      if (!toCompare) {
        return null;
      }
      const controlValue: unknown = control.value;
      const toCompareValue: unknown = toCompare.value;
      return controlValue === toCompareValue ? null : {equals: {control: controlValue, toCompare: toCompareValue}};
    };
    return result;
  }

  public static securePassword(control: AbstractControl): ValidationErrors | null {
    return validatePasswordSecureMode(control.value) ? null : {securePassword: true};
  }
}
