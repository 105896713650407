import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IDefinicaoEmail, TDefinicaoEmailsValidationCallbackData} from '../../components/component/definicaoemails/definicaoEmail.interface';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnInitNewRow} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonClifoExtraValueTipo} from '../../../clifoextravaluetipo/jsonClifoExtraValueTipo.entity.interface';
import {IJsonClifoExtraValue} from '../../../clifoextravalue/jsonClifoExtraValue.entity.interface';

const EMAILS_SEPARATOR = ';';
let emailListId = 0;

@Component({
  selector: 'definicao-emails-modal',
  templateUrl: './definicaoEmails.modal.component.html'
})
export class DefinicaoEmailsModalComponent extends CGModalComponent<IJsonClifoExtraValue> implements OnInit {
  @Input() public definicoesExistentes: Array<IJsonClifoExtraValue>;
  @Input() public definicaoEmail: IJsonClifoExtraValue;
  @Input() public nConta: string;

  public readonly dataGridDefinitionModalEmails: IDevExpressDataGrid;
  public emails: Array<IDefinicaoEmail>;
  public tipo: Partial<IJsonClifoExtraValueTipo>;
  public isEdit: boolean;

  private _dataGridInstance: dxDataGrid<IDefinicaoEmail, number>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.dataGridDefinitionModalEmails = {
      keyExpr: 'id',
      columns: [
        {
          dataField: 'email',
          dataType: 'string',
          caption: 'clifoExtraValues.modal.table.fields.email',
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('clifoExtraValues.errorEmailVazio')
            },
            {
              type: 'email',
              message: this._translateService.instant('clifoExtraValues.errorEmailInvalid')
            },
            {
              type: 'custom',
              message: this._translateService.instant('clifoExtraValues.errorEmailJaExiste'),
              validationCallback: (options: TDefinicaoEmailsValidationCallbackData) => this._validateEmailDuplicated(options)
            }
          ]
        }
      ],
      editing: {
        allowAdding: true,
        allowDeleting: true,
        allowUpdating: true,
        mode: 'cell',
        newRowPosition: 'last'
      },
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      remoteOperations: false,
      searchPanel: {visible: false}
    };
    this.emails = [];
    this.isEdit = false;
  }

  public ngOnInit(): void {
    if (this.definicaoEmail) {
      this.isEdit = true;
      if (this.definicaoEmail.valor) {
        this._emailsFromDefinition();
      }
    }
  }

  public close(): Promise<void> {
    return this._dataGridInstance.saveEditData().then(() => {
      this._emailsToDefinition().then(() => {
        super.close(this.definicaoEmail);
      });
    });
  }

  public changedTipoDefinicao(tipo: IJsonClifoExtraValueTipo): void {
    this.tipo = tipo;
    this.definicaoEmail = this.tipo ? this.definicoesExistentes.find((definicao: IJsonClifoExtraValue) => definicao.codigo === this.tipo.codigo) : undefined;
    if (this.definicaoEmail) {
      if (this.definicaoEmail.valor) {
        this._emailsFromDefinition();
      }
    } else {
      this.definicaoEmail = {
        codigo: this.tipo.codigo,
        nConta: this.nConta,
        descricao: this.tipo.descricao,
        valor: ''
      };
      this.emails = [];
    }
  }

  public onDataGridInitialized(event: IDevExpressDataGridEventOnInitialized<IDefinicaoEmail, number>): void {
    this._dataGridInstance = event.component;
  }

  public onDataGridInitNewRow(event: IDevExpressDataGridEventOnInitNewRow<IDefinicaoEmail, number>): void {
    event.data.id = emailListId++;
  }

  private _emailsFromDefinition(): void {
    const emails: Array<string> = this.definicaoEmail.valor.split(EMAILS_SEPARATOR);
    this.emails = emails.map<IDefinicaoEmail>((email: string) => {
      return {
        id: emailListId++,
        email: email
      };
    });
  }

  private async _emailsToDefinition(): Promise<void> {
    const emails: Array<IDefinicaoEmail> = <Array<IDefinicaoEmail>>await this._dataGridInstance.getDataSource().store().load();
    this.definicaoEmail.valor = emails.map((value: IDefinicaoEmail) => value.email).join(EMAILS_SEPARATOR);
  }

  private _validateEmailDuplicated(options: TDefinicaoEmailsValidationCallbackData): boolean {
    return !this.emails.find((emailItem: IDefinicaoEmail) => emailItem.email === options.data.email && emailItem.id !== options.data.id);
  }
}
