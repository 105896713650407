<pl-form [submit]="close" [plPromise]="promise">
  <div class="modal-body">
    <img alt="Portal" class="d-block simple-login-logo" [src]="logoImageSource" />

    <pl-group>
      <label [translate]="userNameLabel"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.username" plAutoFocus></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'simpleLogin.passwordFieldLabel'"></label>
      <edit>
        <pl-edit type="password" [(model)]="model.password"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="submit" class="btn btn-sm btn-primary" [disabled]="isSaveBtnDisabled()"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'simpleLogin.saveTitle'"></span></button>

    <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></button>
  </div>
</pl-form>
