import type {EFileReaderReadyState, IPlFileReaderPromise} from './filereader.interface';

export class PlFileReader {
  private readonly _blob: Blob;

  constructor(blob: Blob) {
    this._blob = blob;
  }

  public blob(): Blob {
    return this._blob;
  }

  public readAsArrayBuffer(): IPlFileReaderPromise<ArrayBuffer> {
    return this._readAs<ArrayBuffer>((fileReader: FileReader) => {
      fileReader.readAsArrayBuffer(this._blob);
    });
  }

  public readAsDataURL(): IPlFileReaderPromise<string> {
    return this._readAs<string>((fileReader: FileReader) => {
      fileReader.readAsDataURL(this._blob);
    });
  }

  public readAsText(): IPlFileReaderPromise<string> {
    return this._readAs<string>((fileReader: FileReader) => {
      fileReader.readAsText(this._blob);
    });
  }

  private _readAs<T>(callback: (fileReader: FileReader) => void): IPlFileReaderPromise<T> {
    let readyState: () => EFileReaderReadyState;
    let abort: () => void;
    const promise: Partial<IPlFileReaderPromise<T>> = new Promise<T>((resolve, reject) => {
      const fileReader: FileReader = new FileReader();
      readyState = () => fileReader.readyState;
      abort = () => {
        fileReader.abort();
      };
      const cleanUp = (): void => {
        fileReader.removeEventListener<'load'>('load', onLoad);
        fileReader.removeEventListener<'error'>('error', onError);
      };
      const onLoad = (): void => {
        cleanUp();
        resolve(<any>fileReader.result);
      };
      const onError = (event: ProgressEvent): void => {
        cleanUp();
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        reject(event);
      };
      fileReader.addEventListener<'load'>('load', onLoad);
      fileReader.addEventListener<'error'>('error', onError);
      callback(fileReader);
    });
    promise.readyState = readyState;
    promise.abort = abort;
    return <IPlFileReaderPromise<T>>promise;
  }
}
