<div [class.has-error]="linha._invalidNif">
  <entity-autocomplete
    entity="pocs"
    [attrName]="fieldName"
    [model]="model"
    (selectedKeyChange)="modelChanged($event)"
    [filter]="'nif<>'"
    [fieldsMap]="{nConta: 'nContrib', nif: 'nContrib'}"
    [properties]="properties"
    inputClass="input-sm"
    output="nif"
    outputKey="nif">
  </entity-autocomplete>
</div>
