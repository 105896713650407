import {Component, Injector, Input, OnInit} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonContribuintesNaoPtComRetencao, IJsonContribuintesNaoPtSemRetencao, IJsonModelo30, IJsonModelo30Q8List} from '../../jsonModelo30.module.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IModelo30VerificarLancamentos} from '../../modelo30.module.interface';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnSelectionChanged} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';

@Component({
  selector: 'modal-modelo30-vertificar-lancamentos',
  templateUrl: './modelo30.verificarLancamentos.modal.component.html'
})
export class Modelo30VerificarLancamentosModalComponent extends CGModalComponent<IModelo30VerificarLancamentos> implements OnInit {
  @Input() public modelo: IJsonModelo30;
  @Input() public contribuintesNaoPtComRetencao: Array<IJsonContribuintesNaoPtComRetencao>;
  @Input() public contribuintesNaoPtSemRetencao: Array<IJsonContribuintesNaoPtSemRetencao>;

  public readonly dataGridDefinitionContribuintesNaoPtComRetencao: IDevExpressDataGrid<IJsonContribuintesNaoPtComRetencao, IJsonContribuintesNaoPtComRetencao>;
  public readonly dataGridDefinitionContribuintesNaoPtSemRetencao: IDevExpressDataGrid<IJsonContribuintesNaoPtSemRetencao, IJsonContribuintesNaoPtSemRetencao>;
  public readonly selectedData: IModelo30VerificarLancamentos;
  public showTabs: boolean;

  private _dataGridInstanceContribuintesNaoPtComRetencao: dxDataGrid<IJsonContribuintesNaoPtComRetencao, IJsonContribuintesNaoPtComRetencao>;
  private _dataGridInstanceContribuintesNaoPtSemRetencao: dxDataGrid<IJsonContribuintesNaoPtSemRetencao, IJsonContribuintesNaoPtSemRetencao>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.showTabs = false;
    this.selectedData = {
      contribuintesComRetencao: [],
      contribuintesSemRetencao: []
    };

    this.dataGridDefinitionContribuintesNaoPtComRetencao = {
      columns: [
        {dataField: 'nif', dataType: 'string', caption: 'modelo30.groups.q8.dataGrid.nifPais'},
        {dataField: 'codPais', dataType: 'string', caption: 'modelo30.groups.q8.dataGrid.codPais'},
        {dataField: 'taxatributacao', dataType: 'number', caption: 'modelo30.groups.q8.dataGrid.tribTaxa'},
        {dataField: 'rendimentovalor', dataType: 'double', caption: 'modelo30.groups.q8.dataGrid.rendValor'},
        {dataField: 'montanteimpostoretido', dataType: 'double', caption: 'modelo30.groups.q8.dataGrid.montante'},
        {dataField: 'nguiam30', dataType: 'string', caption: 'modelo30.groups.q8.dataGrid.guia'},
        {dataField: 'extPocLigaID', dataType: 'string', caption: '', visible: false, showInColumnChooser: false}
      ],
      selection: {mode: 'multiple'},
      remoteOperations: false
    };

    this.dataGridDefinitionContribuintesNaoPtSemRetencao = {
      columns: [
        {dataField: 'nif', dataType: 'string', caption: 'modelo30.groups.q8.dataGrid.nifPais'},
        {dataField: 'codPais', dataType: 'string', caption: 'modelo30.groups.q8.dataGrid.codPais'},
        {dataField: 'rendimentovalor', dataType: 'double', caption: 'modelo30.groups.q8.dataGrid.rendValor'}
      ],
      selection: {mode: 'multiple'},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    this.showTabs = Boolean(this.contribuintesNaoPtComRetencao.length) || Boolean(this.contribuintesNaoPtSemRetencao.length);
  }

  public onInitializedContribuintesNaoPtComRetencao({component}: IDevExpressDataGridEventOnInitialized<IJsonContribuintesNaoPtComRetencao, IJsonContribuintesNaoPtComRetencao>): void {
    this._dataGridInstanceContribuintesNaoPtComRetencao = component;
  }

  public onInitializedContribuintesNaoPtSemRetencao({component}: IDevExpressDataGridEventOnInitialized<IJsonContribuintesNaoPtSemRetencao, IJsonContribuintesNaoPtSemRetencao>): void {
    this._dataGridInstanceContribuintesNaoPtSemRetencao = component;
  }

  public onContentReadyContribuintesNaoPtComRetencao(): void {
    const selectRows: Array<IJsonContribuintesNaoPtComRetencao> = [];
    this.modelo.q8List.forEach((item: IJsonModelo30Q8List) => {
      const itemExiste: IJsonContribuintesNaoPtComRetencao = this.contribuintesNaoPtComRetencao.find((findItem: IJsonContribuintesNaoPtComRetencao) => findItem.extPocLigaID === item.extPocLigaID);
      if (itemExiste) {
        selectRows.push(itemExiste);
      }
    });
    this._dataGridInstanceContribuintesNaoPtComRetencao.selectRows(selectRows, true);
  }

  public onContentReadyContribuintesNaoPtSemRetencao(): void {
    const selectRows: Array<IJsonContribuintesNaoPtSemRetencao> = [];
    this.modelo.q8List.forEach((item: IJsonModelo30Q8List) => {
      const itemExiste: IJsonContribuintesNaoPtSemRetencao = this.contribuintesNaoPtSemRetencao.find(
        (findItem: IJsonContribuintesNaoPtSemRetencao) => findItem.extPocLigaID === item.extPocLigaID && findItem.nif === item.nif
      );
      if (itemExiste) {
        selectRows.push(itemExiste);
      }
    });
    this._dataGridInstanceContribuintesNaoPtSemRetencao.selectRows(selectRows, true);
  }

  public onSelectionChangedContribuintesNaoPtComRetencao(event: IDevExpressDataGridEventOnSelectionChanged<IJsonContribuintesNaoPtComRetencao, IJsonContribuintesNaoPtComRetencao>): void {
    this.selectedData.contribuintesComRetencao = event.selectedRowsData;
  }

  public onSelectionChangedContribuintesNaoPtSemRetencao(event: IDevExpressDataGridEventOnSelectionChanged<IJsonContribuintesNaoPtSemRetencao, IJsonContribuintesNaoPtSemRetencao>): void {
    this.selectedData.contribuintesSemRetencao = event.selectedRowsData;
  }

  public close(): void {
    this._plAlertService.success('modelo30.verificarLancamentos.mensagens.success');
    super.close(this.selectedData);
  }
}
