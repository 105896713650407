<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h4 class="modal-title">Pagamento - {{ nomeConta }}</h4>
</div>

<div class="modal-body" [ngSwitch]="view">
  <div class="entity-detail-form" *ngSwitchDefault>
    <pl-form [properties]="{orientation: 'horizontal'}" [submit]="sendEmail" plPromise>
      <pl-group>
        <label [translate]="'impdoc.label.nvias'"></label>
        <edit>
          <pl-edit type="rowselect" [(model)]="nvias" [properties]="{start: 1, end: 10}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'pagamentos.modal.pagamentoimprimir.label.emailfornecedor'"></label>
        <edit>
          <pl-edit type="text" attrName="email" [(model)]="email" [properties]="{placeholder: 'pagamentos.modal.pagamentoimprimir.placeholder.email'}" plAutoFocus></pl-edit>
        </edit>
        <actions>
          <div class="d-inline-block">
            <pl-tooltip [config]="{text: 'pagamentos.modal.pagamentoimprimir.email.required', placement: 'top', disabled: !!email}">
              <button type="submit" *plTooltipContent class="btn btn-primary" [disabled]="!email"><i class="fa fa-fw fa-envelope-o"></i>&nbsp;<span [translate]="'impdoc.label.enviar'"></span></button>
            </pl-tooltip>
          </div>
        </actions>
      </pl-group>

      <pl-group>
        <label [translate]="'global.text.listing'"></label>
        <edit>
          <pl-autocomplete
            [source]="reportsSource"
            attrName="listagem"
            [model]="report"
            (evtSelected)="reportChanged($event)"
            [rowTemplate]="rowTemplateListagem"
            [output]="'title'"
            [properties]="{disallowClear: true}"></pl-autocomplete>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div *ngSwitchCase="'pdf'">
    <cg-pdf-viewer [pdfSrc]="url"></cg-pdf-viewer>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" *ngIf="params.trfbaId" class="btn btn-success" (click)="downloadSEPAFile()">
    <i class="fa fa-fw fa-file-code-o"></i>&nbsp;<span [translate]="'pagamentos.modal.pagamentoimprimir.btn.downloadSEPA'"></span>
  </button>
  <button type="button" class="btn btn-primary btn-sm action-switch" (click)="switchView()" *ngIf="!preview">
    <i *ngIf="view !== 'pdf'" class="fa fa-fw fa-file-pdf-o"></i>
    <i *ngIf="view === 'pdf'" class="fa fa-fw fa-chevron-left"></i>&nbsp;{{ view === 'pdf' ? 'Voltar' : 'PDF' }}
  </button>
  <button type="button" (click)="close()" class="btn btn-light"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
