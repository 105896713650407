import {ENTITY_NAME_PRH_REF_APROVA} from './pRHRefAprova.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonPRHRefAprova} from './jsonPRHRefAprova.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_PRH_REF_APROVA: IEntityDefinition<IJsonPRHRefAprova> = {
  name: ENTITY_NAME_PRH_REF_APROVA,
  asModule: false,
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  metadata: {
    keyName: 'codPRHRefAprova',
    descriptionName: 'codPRHRefAprova',
    fields: [{name: 'codPRHRefAprova', width: '120px', caption: 'Código', placeholder: 'Código'}],
    order: 'codPRHRefAprova',
    searchFields: 'codPRHRefAprova'
  },
  autocomplete: {
    rowTemplate: '{{codPRHRefAprova}}',
    output: 'codPRHRefAprova',
    searchFields: 'codPRHRefAprova'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  }
};
