import {Component, Injector, Input, OnInit} from '@angular/core';
import {PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {AtivosReavaliacoesNaoLegaisService} from './ativosReavaliacoesNaoLegais.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IJsonAtivosReavaliacoesNaoLegais} from './jsonAtivosReavaliacoesNaoLegais.interface';
import moment from 'moment';

@Component({
  selector: 'modal-ativos-reavaliacoes-nao-legais',
  templateUrl: './ativosReavaliacoesNaoLegais.modal.component.html'
})
export class AtivosReavaliacoesNaoLegaisModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public codAtivo: number;
  @Input() public valorLiquido: number;
  @Input() public ativosReavaliacoesNaoLegais: IJsonAtivosReavaliacoesNaoLegais;
  public subtitle: string;
  private _vidaUtilMeses: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosReavaliacoesNaoLegaisService: AtivosReavaliacoesNaoLegaisService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    if (!this.ativosReavaliacoesNaoLegais) {
      this.ativosReavaliacoesNaoLegais = {
        dataReavNaoLegal: moment().startOf('month'),
        jaHaDepEfetuadasNoAno: false,
        vidaUtilAnos: 0,
        vidaUtilMeses: 0,
        aumentoValor: 0
      };
    }
    this._vidaUtilMeses = this.ativosReavaliacoesNaoLegais.vidaUtilMeses;
    this.subtitle =
      this.valorLiquido > 0
        ? this._plTranslateService.translate('ativosreavaliacoesnaolegais.ativoparcial', {codAtivo: this.codAtivo})
        : this._plTranslateService.translate('ativosreavaliacoesnaolegais.ativototaldep', {codAtivo: this.codAtivo});
  }

  public close(): Promise<void> {
    const confirmText =
      this._vidaUtilMeses !== this.ativosReavaliacoesNaoLegais.vidaUtilMeses
        ? 'ativosreavaliacoesnaolegais.vidaUtilDefinidaEDiferente'
        : 'ativosreavaliacoesnaolegais.temACertezaQuePretendeRealizarAR';
    return this._cgModalService.showOkCancel('global.text.confirmation', confirmText).then(() => {
      return this._ativosReavaliacoesNaoLegaisService.saveReavaliacaoNaoLegal(this.codAtivo, this.ativosReavaliacoesNaoLegais).then(() => {
        this._plAlertService.success('ativosreavaliacoesnaolegais.success');
        super.close();
      });
    });
  }
}
