export type TPlEventListener = (this: HTMLElement, event: Event) => void;

export type TPlMouseEventListener = (this: HTMLElement, event: MouseEvent) => void;

export type TPlFocusEventListener = (this: HTMLElement, event: FocusEvent) => void;

export type TPlKeyboardEventListener = (this: HTMLElement, event: KeyboardEvent) => void;

export interface IPlEventListeners
  extends IPlResourceEventListeners,
    IPlNetworkEventListeners,
    IPlFocusEventListeners,
    IPlWebSocketEventListeners,
    IPlSessionHistoryEventListeners,
    IPlCSSAnimationEventListeners,
    IPlCSSTransitionEventListeners,
    IPlFormEventListeners,
    IPlPrintEventListeners,
    IPlClipboardEventListeners,
    IPlKeyboardEventListeners,
    IPlMouseEventListeners,
    IPlDragDropEventListeners,
    IPlHammerEventListeners {}

export interface IPlResourceEventListeners {
  error?(event: Event): void;

  abort?(event: Event): void;

  load?(event: Event): void;

  beforeunload?(event: Event): void;

  unload?(event: Event): void;
}

export interface IPlNetworkEventListeners {
  online?(event: Event): void;

  offline?(event: Event): void;
}

export interface IPlFocusEventListeners {
  blur?(event: FocusEvent): void;

  focus?(event: FocusEvent): void;

  focusin?(event: FocusEvent): void;

  focusout?(event: FocusEvent): void;
}

export interface IPlWebSocketEventListeners {
  open?(event: Event): void;

  message?(event: MessageEvent): void;

  error?(event: Event): void;

  close?(event: CloseEvent): void;
}

export interface IPlSessionHistoryEventListeners {
  pagehide?(event: PageTransitionEvent): void;

  pageshow?(event: PageTransitionEvent): void;

  popstate?(event: PopStateEvent): void;
}

export interface IPlCSSAnimationEventListeners {
  animationstart?(event: AnimationEvent): void;

  animationcancel?(event: AnimationEvent): void;

  animationend?(event: AnimationEvent): void;

  animationiteration?(event: AnimationEvent): void;
}

export interface IPlCSSTransitionEventListeners {
  transitionstart?(event: TransitionEvent): void;

  transitioncancel?(event: TransitionEvent): void;

  transitionend?(event: TransitionEvent): void;

  transitionrun?(event: TransitionEvent): void;
}

export interface IPlFormEventListeners {
  reset?(event: Event): void;

  submit?(event: Event): void;
}

export interface IPlPrintEventListeners {
  beforeprint?(event: Event): void;

  afterprint?(event: Event): void;
}

export interface IPlClipboardEventListeners {
  cut?(event: ClipboardEvent): void;

  copy?(event: ClipboardEvent): void;

  paste?(event: ClipboardEvent): void;
}

export interface IPlKeyboardEventListeners {
  keydown?(event: KeyboardEvent): void;

  keypress?(event: KeyboardEvent): void;

  keyup?(event: KeyboardEvent): void;
}

export interface IPlMouseEventListeners {
  auxclick?(event: MouseEvent): void;

  click?(event: MouseEvent): void;

  contextmenu?(event: MouseEvent): void;

  dblclick?(event: MouseEvent): void;

  mousedown?(event: MouseEvent): void;

  mouseenter?(event: MouseEvent): void;

  mouseleave?(event: MouseEvent): void;

  mousemove?(event: MouseEvent): void;

  mouseover?(event: MouseEvent): void;

  mouseout?(event: MouseEvent): void;

  mouseup?(event: MouseEvent): void;

  pointerlockchange?(event: Event): void;

  pointerlockerror?(event: Event): void;

  select?(event: Event): void;

  wheel?(event: WheelEvent): void;
}

export interface IPlDragDropEventListeners {
  drag?(event: Event): void;

  dragend?(event: Event): void;

  dragenter?(event: Event): void;

  dragstart?(event: Event): void;

  dragleave?(event: Event): void;

  dragover?(event: Event): void;

  drop?(event: Event): void;
}

export interface IPlHammerEventListeners
  extends IPlHammerPanEventListeners,
    IPlHammerPinchEventListeners,
    IPlHammerPressEventListeners,
    IPlHammerRotateEventListeners,
    IPlHammerSwipeEventListeners,
    IPlHammerTapEventListeners {}

export interface IPlHammerPanEventListeners {
  pan?(event: HammerInput): void;

  panstart?(event: HammerInput): void;

  panmove?(event: HammerInput): void;

  panend?(event: HammerInput): void;

  pancancel?(event: HammerInput): void;

  panleft?(event: HammerInput): void;

  panright?(event: HammerInput): void;

  panup?(event: HammerInput): void;

  pandown?(event: HammerInput): void;
}

export interface IPlHammerPinchEventListeners {
  pin?(event: HammerInput): void;

  pinstart?(event: HammerInput): void;

  pinmove?(event: HammerInput): void;

  pinend?(event: HammerInput): void;

  pincancel?(event: HammerInput): void;

  pinin?(event: HammerInput): void;

  pinout?(event: HammerInput): void;
}

export interface IPlHammerPressEventListeners {
  press?(event: HammerInput): void;

  pressup?(event: HammerInput): void;
}

export interface IPlHammerRotateEventListeners {
  rotate?(event: HammerInput): void;

  rotatestart?(event: HammerInput): void;

  rotatemove?(event: HammerInput): void;

  rotateend?(event: HammerInput): void;

  rotatecancel?(event: HammerInput): void;
}

export interface IPlHammerSwipeEventListeners {
  swipe?(event: HammerInput): void;

  swipeleft?(event: HammerInput): void;

  swiperight?(event: HammerInput): void;

  swipeup?(event: HammerInput): void;

  swipedown?(event: HammerInput): void;
}

export interface IPlHammerTapEventListeners {
  tap?(event: HammerInput): void;
}

export const PL_HAMMER_EVENT_LISTENERS: ReadonlyArray<string> = Object.freeze([
  'pan',
  'panstart',
  'panmove',
  'panend',
  'pancancel',
  'panleft',
  'panright',
  'panup',
  'pandown',
  'pin',
  'pinstart',
  'pinmove',
  'pinend',
  'pincancel',
  'pinin',
  'pinout',
  'press',
  'pressup',
  'rotate',
  'rotatestart',
  'rotatemove',
  'rotateend',
  'rotatecancel',
  'swipe',
  'swipeleft',
  'swiperight',
  'swipeup',
  'swipedown',
  'tap'
]);
