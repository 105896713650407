import {Component, Injector} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IFaturacaoEletronicaGuia} from '../../faturacaoEletronicaConfiguracoes.module.interface';
import {IPlNavWizardDefinition} from 'pl-comps-angular';

@Component({
  selector: 'faturacao-eletronica-configuracoes-guide-modal',
  templateUrl: './faturacaoEletronicaConfiguracoes.guide.modal.component.html'
})
export class FaturacaoEletronicaConfiguracoesGuideModalComponent extends CGModalComponent<void> {
  public readonly navWizardDefinition: IPlNavWizardDefinition;
  public readonly faturacaoEletronicaGuia: Array<IFaturacaoEletronicaGuia>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.navWizardDefinition = {
      items: []
    };
    this.faturacaoEletronicaGuia = [
      {
        caption: 'faturacaoeletronicaconfiguracoes.guide.atributosproftitle',
        info: 'faturacaoeletronicaconfiguracoes.guide.atributosprof',
        url: 'https://www.centralgest.com/documentos/faturacao-digital-configuracoes-safe-faq/faturacao_digital_configuracoes_safe_faq_1.JPG'
      },
      {
        caption: 'faturacaoeletronicaconfiguracoes.guide.autenticacaotitle',
        info: 'faturacaoeletronicaconfiguracoes.guide.autenticacao',
        url: 'https://www.centralgest.com/documentos/faturacao-digital-configuracoes-safe-faq/faturacao_digital_configuracoes_safe_faq_3.JPG'
      },
      {
        caption: 'faturacaoeletronicaconfiguracoes.guide.autorizacaotitle',
        info: 'faturacaoeletronicaconfiguracoes.guide.autorizacao',
        url: 'https://www.centralgest.com/documentos/faturacao-digital-configuracoes-safe-faq/faturacao_digital_configuracoes_safe_faq_4.JPG'
      },
      {
        caption: 'faturacaoeletronicaconfiguracoes.guide.autenticacaotitle',
        info: 'faturacaoeletronicaconfiguracoes.guide.autenticacaomovel',
        url: 'https://www.centralgest.com/documentos/faturacao-digital-configuracoes-safe-faq/faturacao_digital_configuracoes_safe_faq_5.JPG'
      },
      {
        caption: 'faturacaoeletronicaconfiguracoes.guide.autenticacaotitle',
        info: 'faturacaoeletronicaconfiguracoes.guide.autenticacaomovelcod',
        url: 'https://www.centralgest.com/documentos/faturacao-digital-configuracoes-safe-faq/faturacao_digital_configuracoes_safe_faq_6.JPG'
      }
    ];
  }
}
