import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_TAXA} from './tipoTaxa.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPO_TAXA: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_TAXA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'tipoTaxa.data.notApplicable'},
    {value: 1, name: 'tipoTaxa.data.exempt'},
    {value: 2, name: 'tipoTaxa.data.reduced'},
    {value: 3, name: 'tipoTaxa.data.intermediate'},
    {value: 4, name: 'tipoTaxa.data.normal'}
  ]
});
