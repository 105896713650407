<div class="contabilidade-digital-doc-viewer-recolha-choosefolder-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="title"></h5>
  </div>

  <div class="modal-body">
    <ng-container *ngIf="error">
      <pl-alert type="error">{{ error }}</pl-alert>
      <hr />
    </ng-container>

    <div class="row">
      <div class="col-sm-12 contabilidade-digital-doc-viewer-recolha-choosefolder-modal-treeviewer" [class.col-md-4]="showFiles">
        <contabilidadedigital-treeviewer
          [folderID]="treeViewerFolderId"
          [folderIdAsUpwards]="treeViewerFolderIdAsUpwards"
          [upwardsUntilFolderId]="treeViewerUpwardsUntilFolderId"
          [showFiles]="showFiles"
          [anoEmCursoIRC]="anoEmCursoIRC"
          [advancedDescription]="true"
          [loading]="loading"
          [disableActions]="true"
          (evtFolderChanged)="changedFolder($event)"
          (evtFileChanged)="changedFile($event)"
          (evtFolderDoubleClicked)="close()"
          (evtFileDoubleClicked)="close()"
          (evtResponseErrored)="responseErrored($event)">
        </contabilidadedigital-treeviewer>
      </div>

      <div class="col-sm-12 col-md-8" *ngIf="showFiles">
        <div class="contabilidade-digital-doc-viewer-recolha-choosefolder-modal-docviewer">
          <arquivodigital-docviewer [(docId)]="docViewerDocId"></arquivodigital-docviewer>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-primary" [disabled]="loading || !valid" (click)="close()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></button>

    <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
  </div>
</div>
