<div class="adiantamentos-print-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'adiantamentos.modals.print.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'global.text.listing'"></label>
      <edit>
        <pl-edit type="reports_adiantamenos" attrName="listagem" [model]="report" (modelChange)="report = $event; previsualizar()"></pl-edit>
      </edit>
    </pl-group>

    <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
