import {IJsonPRHPapel} from '../prhpapeis/jsonPRHPapel.entity.interface';

export enum EPRHFluxoIndiceTipo {
  Ferias,
  Faltas,
  Abonos
}

export enum EPRHFluxoIndice {
  FeriasMarcar = 1,
  FeriasAprovar,
  FeriasAprovarIntegrar,
  FeriasRejeitar,
  FaltasMarcar,
  FaltasAprovar,
  FaltasAprovarIntegrar,
  FaltasRejeitar,
  AbonosMarcar,
  AbonosAprovar,
  AbonosAprovarIntegrar,
  AbonosRejeitar
}

export interface IJsonPRHFluxo {
  idFluxo: number;
  posicao: number;
  codPapel: number;
  indice: EPRHFluxoIndice;
  integraCG: boolean;
  tipo: EPRHFluxoIndiceTipo;
  papel: IJsonPRHPapel;
}

/* eslint-disable @typescript-eslint/naming-convention */

export interface IPRHIndiceFluxos {
  readonly FeriasLanca: IPRHIndiceFluxo;
  readonly FeriasAprova: IPRHIndiceFluxo;
  readonly FeriasAprovaLanca: IPRHIndiceFluxo;
  readonly FeriasRejeitadas: IPRHIndiceFluxo;
  readonly FaltasLanca: IPRHIndiceFluxo;
  readonly FaltasAprova: IPRHIndiceFluxo;
  readonly FaltasAprovaLanca: IPRHIndiceFluxo;
  readonly FaltasRejeitado: IPRHIndiceFluxo;
  readonly AbonosLanca: IPRHIndiceFluxo;
  readonly AbonosAprova: IPRHIndiceFluxo;
  readonly AbonosAprovaLanca: IPRHIndiceFluxo;
  readonly AbonosRejeitado: IPRHIndiceFluxo;
}

/* eslint-enable @typescript-eslint/naming-convention */

export interface IPRHIndiceFluxo {
  readonly tipo: EPRHFluxoIndiceTipo;
  readonly indice: EPRHFluxoIndice;
  readonly nome: string;
  readonly integraCG: boolean;
  readonly tipoStr: string;
}

export const PRH_FLUXO_POSICAO_INVALIDO = 0;
export const PRH_FLUXO_POSICAO_MARCADO = 1;
export const PRH_FLUXO_POSICAO_REJEITADO = -1;
