import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ReconstrucaoAcumuladosComponent} from './components/reconstrucaoAcumulados.module.component';
import {ROLE} from '../../../../services/role.const';

export const MODULE_PCA_MANUTENCAO_RECONSTRUCAO_ACUMULADOS: IModuleDefinition = {
  name: 'reconstrucaoacumulados',
  state: {
    url: '/reconstrucaoacumulados',
    component: ReconstrucaoAcumuladosComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.reconstrucaoacumulados'
    }
  }
};
