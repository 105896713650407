import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonTxtar} from './jsonTxtar.entity.interface';
import {ENTITY_NAME_TXTAR} from './txtar.entity.interface';
import {TxtarEntityEditComponent} from './components/edit/txtar.entity.edit.component';

export const ENTITY_TXTAR: IEntityDefinition<IJsonTxtar> = {
  name: ENTITY_NAME_TXTAR,
  roles: [ROLE.ERP],
  searchPlaceholder: 'txtar.pesquisa',
  metadata: {
    keyName: 'txtarId',
    descriptionName: '{{nArtigo}} - {{nTexto}}',
    fields: [
      {
        name: 'nArtigo',
        type: 'string',
        caption: 'txtar.fields.nArtigo',
        placeholder: 'txtar.fields.nArtigoPlaceholder',
        entity: {name: 'artigos', keyTarget: 'nArtigo', outputTarget: 'nomeArtigo'},
        validators: {required: true}
      },
      {
        name: 'nTexto',
        type: 'cgsmallint',
        caption: 'txtar.fields.nTexto',
        placeholder: 'txtar.fields.nTextoPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'titulo',
        type: 'string',
        caption: 'txtar.fields.titulo',
        placeholder: 'txtar.fields.tituloPlaceholder',
        validators: {required: true, maxlength: 50}
      },
      {
        name: 'note',
        type: 'string',
        caption: 'txtar.fields.note',
        placeholder: 'txtar.fields.notePlaceholder',
        validators: {required: true}
      }
    ],
    order: 'nArtigo, nTexto',
    searchFields: 'nArtigo,nTexto,titulo,note'
  },
  autocomplete: {
    rowTemplate: '{{nArtigo}} - {{titulo}}',
    output: '{{nArtigo}} - {{titulo}}',
    searchFields: 'nArtigo,titulo'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.txtar'
      }
    }
  },
  detail: {
    state: {
      component: TxtarEntityEditComponent,
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.txtar'
      }
    }
  }
};
