import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EFEAEstado, IJsonFaturacaoPublicaConfig, IJsonFaturacaoPublicaConfigEstado} from '../../jsonFaturacaoPublica.module.interface';
import {IMetodoSourceEstadosConta} from '../../faturacaoPublica.module.interface';
import {isObject, PlTranslateService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {FaturacaopublicaConfigNovoestadoModalComponent} from '../novoestado/faturacaoPublica.config.novoEstado.modal.component';
import {FaturacaoPublicaService} from '../../faturacaoPublica.module.service';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {DATA_SOURCE_NAME_FATURACAO_PUBLICA_ESTADO} from '../../../../datasources/faturacaopublicaestado/faturacaoPublicaEstado.datasource.interface';

const CONFIG_SAPHETY_NAME = 'Saphety';
const CONFIG_FEAP_NAME = 'FEAP - eSPAP';

export const TAB_ID_SAPHETY = 'saphety';
export const TAB_ID_FEAP = 'feap';

@Component({
  selector: 'modal-faturacaopublica-config',
  templateUrl: './faturacaoPublica.config.modal.component.html'
})
export class FaturacaoPublicaConfigModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public configs: Array<IJsonFaturacaoPublicaConfig>;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly metodoSourceEstadosConta: Array<IMetodoSourceEstadosConta>;
  public readonly mtdOutput: string;

  public configSaphety: IJsonFaturacaoPublicaConfig;
  public configFEAP: IJsonFaturacaoPublicaConfig;
  public nomeEstadosContaSaphety: string;
  public nomeEstadosContaFEAP: string;
  public activeTabID: string;

  private _dataGridInstanceSaphety: dxDataGrid;
  private _dataGridInstanceFEAP: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _faturacaoPublicaService: FaturacaoPublicaService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columns: [
        {dataField: 'conta', dataType: 'string', caption: 'faturacaopublica.config.table.conta'},
        {dataField: 'estado', dataType: 'number', caption: 'faturacaopublica.config.table.estado', lookup: {cgDataSource: DATA_SOURCE_NAME_FATURACAO_PUBLICA_ESTADO}},
        {
          dataField: 'vf_actions',
          caption: ' ',
          cellTemplate: 'actions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false
        }
      ],
      toolbar: {
        items: [
          {
            location: 'after',
            template: 'templateToolbar',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };

    this.configSaphety = {
      configDados: {
        contasEstadoList: [],
        endereco: '',
        estado: EFEAEstado.Received,
        senha: '',
        utilizador: ''
      },
      configurado: false,
      nome: ''
    };

    this.configFEAP = {
      configDados: {
        contasEstadoList: [],
        endereco: '',
        estado: EFEAEstado.Received,
        senha: '',
        utilizador: ''
      },
      configurado: false,
      nome: ''
    };
    this.metodoSourceEstadosConta = [
      {
        valueMetodo: EFEAEstado.Received,
        nameMetodo: 'faturacaopublica.estado.received'
      },
      {
        valueMetodo: EFEAEstado.Paid,
        nameMetodo: 'faturacaopublica.estado.paid'
      }
    ];
    this.mtdOutput = '{{nameMetodo}}';
  }

  public ngOnInit(): void {
    this.activeTabID = TAB_ID_SAPHETY;
    for (const config of this.configs) {
      switch (config.nome) {
        case CONFIG_SAPHETY_NAME:
          this.configSaphety = config;
          break;
        case CONFIG_FEAP_NAME:
          this.configFEAP = config;
          break;
      }
    }
    this.nomeEstadosContaSaphety =
      this.configSaphety.configDados.estado === EFEAEstado.Received
        ? this._plTranslateService.translate('faturacaopublica.estado.received')
        : this._plTranslateService.translate('faturacaopublica.estado.paid');

    this.nomeEstadosContaFEAP =
      this.configFEAP.configDados.estado === EFEAEstado.Received
        ? this._plTranslateService.translate('faturacaopublica.estado.received')
        : this._plTranslateService.translate('faturacaopublica.estado.paid');
  }

  public close(): Promise<void> {
    const confs: Array<IJsonFaturacaoPublicaConfig> = [];
    confs.push(this.configSaphety);
    confs.push(this.configFEAP);
    return this._faturacaoPublicaService.postConfigFaturacaoPublica(confs).then(() => {
      super.close();
    });
  }

  public onInitializedSaphety({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstanceSaphety = component;
  }

  public onContentReadySaphety(): void {
    this._dataGridInstanceSaphety.endCustomLoading();
  }

  public onInitializedFEAP({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstanceFEAP = component;
  }

  public onContentReadyFEAP(): void {
    this._dataGridInstanceFEAP.endCustomLoading();
  }

  public addLineFatPubConfigEstado(): void {
    const instance = this._cgModalService.showVanilla(FaturacaopublicaConfigNovoestadoModalComponent, {size: 'md'});
    instance.result.then((result: IJsonFaturacaoPublicaConfigEstado) => {
      switch (this.activeTabID) {
        case TAB_ID_SAPHETY:
          const indexSaphety = this.configSaphety.configDados.contasEstadoList.findIndex((value: IJsonFaturacaoPublicaConfigEstado) => result.conta === value.conta);
          if (indexSaphety !== -1) {
            this.configSaphety.configDados.contasEstadoList[indexSaphety] = result;
          } else {
            this.configSaphety.configDados.contasEstadoList.push(result);
          }
          break;
        case TAB_ID_FEAP:
          const indexFEAP = this.configFEAP.configDados.contasEstadoList.findIndex((value: IJsonFaturacaoPublicaConfigEstado) => result.conta === value.conta);
          if (indexFEAP !== -1) {
            this.configFEAP.configDados.contasEstadoList[indexFEAP] = result;
          } else {
            this.configFEAP.configDados.contasEstadoList.push(result);
          }
          break;
      }
    });
  }

  public editLineFatPubConfigEstado(item: IJsonFaturacaoPublicaConfigEstado): void {
    const modalInstance = this._cgModalService.showVanilla(FaturacaopublicaConfigNovoestadoModalComponent, {size: 'md'});
    const componentInstance: FaturacaopublicaConfigNovoestadoModalComponent = modalInstance.componentInstance;
    componentInstance.fatPubConfEstadoConta = item;
    modalInstance.result.then((result: IJsonFaturacaoPublicaConfigEstado) => {
      switch (this.activeTabID) {
        case TAB_ID_SAPHETY:
          const indexSAPHETY = this.configSaphety.configDados.contasEstadoList.findIndex((value: IJsonFaturacaoPublicaConfigEstado) => result.conta === value.conta);
          if (indexSAPHETY !== -1) {
            this.configSaphety.configDados.contasEstadoList[indexSAPHETY] = result;
          }
          break;
        case TAB_ID_FEAP:
          const indexFEAP = this.configFEAP.configDados.contasEstadoList.findIndex((value: IJsonFaturacaoPublicaConfigEstado) => result.conta === value.conta);
          if (indexFEAP !== -1) {
            this.configFEAP.configDados.contasEstadoList[indexFEAP] = result;
          }
          break;
      }
    });
  }

  public deleteLineFatPubConfigEstado(item: IJsonFaturacaoPublicaConfigEstado): void {
    switch (this.activeTabID) {
      case TAB_ID_SAPHETY:
        const indexSAPHETY = this.configSaphety.configDados.contasEstadoList.findIndex((value: IJsonFaturacaoPublicaConfigEstado) => item.conta === value.conta);
        if (indexSAPHETY !== -1) {
          this.configSaphety.configDados.contasEstadoList.splice(indexSAPHETY, 1);
        }
        break;
      case TAB_ID_FEAP:
        const indexFEAP = this.configFEAP.configDados.contasEstadoList.findIndex((value: IJsonFaturacaoPublicaConfigEstado) => item.conta === value.conta);
        if (indexFEAP !== -1) {
          this.configFEAP.configDados.contasEstadoList.splice(indexFEAP, 1);
        }
        break;
    }
  }

  public metodoEstadosContaChanged(value: {inputValue: string; item: IMetodoSourceEstadosConta}): void {
    switch (this.activeTabID) {
      case TAB_ID_SAPHETY:
        if (isObject(value.item)) {
          this.configSaphety.configDados.estado = value.item.valueMetodo;
          this.nomeEstadosContaSaphety = value.item.nameMetodo;
        }
        break;
      case TAB_ID_FEAP:
        if (isObject(value.item)) {
          this.configFEAP.configDados.estado = value.item.valueMetodo;
          this.nomeEstadosContaFEAP = value.item.nameMetodo;
        }
        break;
    }
  }

  public changedTab(tabId: string): void {
    this.activeTabID = tabId;
  }
}
