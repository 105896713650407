import {HttpResponse} from '@angular/common/http';
import {IJsonDashboardsPercentagem} from '../../../interfaces/jsonDashboards.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ListagensService} from '../../../services/listagens.service';
import {MODULE_NAME_LISTAGENS_VENDAS_CLIENTE} from './vendasCliente.module.interface';
import moment from 'moment';
import {ROLE} from '../../../services/role.const';
import {VendasClienteModuleComponent} from './components/vendasCliente.module.component';

export const MODULE_LISTAGENS_VENDAS_CLIENTE: IModuleDefinition = {
  name: MODULE_NAME_LISTAGENS_VENDAS_CLIENTE,
  state: {
    url: '/listagens/vendas/cliente',
    component: VendasClienteModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.vendascliente'
    },
    resolve: [
      {
        provide: 'listagensClientes',
        deps: [ListagensService],
        useFactory: (listagensService: ListagensService): Promise<IJsonDashboardsPercentagem> => {
          const startOfYear = moment().startOf('year');
          const endOfYear = moment().endOf('year');
          return listagensService.listagensClientes(startOfYear, endOfYear).then((response: HttpResponse<IJsonDashboardsPercentagem>) => response.body);
        }
      }
    ]
  }
};
