import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {ControloIVAEntreEmpresasComponent} from './components/controloIVAEntreEmpresas.module.component';
import {MODULE_NAME_CONTROLO_IVA_ENTRE_EMPRESAS} from './controloIVAEntreEmpresas.module.interface';

export const MODULE_CONTROLO_IVA_ENTRE_EMPRESAS: IModuleDefinition = {
  name: MODULE_NAME_CONTROLO_IVA_ENTRE_EMPRESAS,
  state: {
    url: `/${MODULE_NAME_CONTROLO_IVA_ENTRE_EMPRESAS}`,
    component: ControloIVAEntreEmpresasComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.controloivaentreempresas'
    },
    params: {
      codControloIVA: {
        type: 'any',
        value: undefined
      }
    }
  }
};
