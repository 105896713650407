import {ENTITY_NAME_CATEGORIAS} from './categorias.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonCateg} from './jsonCateg.entity.interface';
import {ROLE} from '../../services/role.const';
import {CategoriasEditComponent} from './components/edit/categorias.entity.edit.component';

export const ENTITY_CATEGORIAS: IEntityDefinition<IJsonCateg> = {
  name: ENTITY_NAME_CATEGORIAS,
  roles: [ROLE.ERP, ROLE.RH],
  searchPlaceholder: 'categorias.pesquisa',
  metadata: {
    keyName: 'nCateg',
    descriptionName: 'designaBreve',
    fields: [
      {name: 'nCateg', type: 'string', caption: 'categorias.fields.nCateg', validators: {required: {value: true}, maxlength: 4}},
      {name: 'designaBreve', type: 'string', caption: 'categorias.fields.designaBreve', validators: {required: {value: true}}},
      {name: 'designaCompl', type: 'string', caption: 'categorias.fields.designaCompl', visible: false},
      {name: 'codCategoriaMT', type: 'string', caption: 'categorias.fields.codCategoriaMT'},
      {name: 'nomeCategoria', type: 'string', caption: 'categorias.fields.nomeCategoria'},
      {name: 'codIrct', type: 'string', caption: 'categorias.fields.codIrct'},
      {name: 'nomeIrct', type: 'string', caption: 'categorias.fields.nomeIrct'}
    ],
    order: 'nCateg',
    searchFields: 'nCateg,designaBreve,codCategoriaMT'
  },
  autocomplete: {
    rowTemplate: '{{nCateg}} - {{designaBreve}}',
    output: 'designaBreve',
    searchFields: 'nCateg,designaBreve,codCategoriaMT'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.COLABORADOR],
        pageTitle: 'global.menu.categorias'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.COLABORADOR],
        pageTitle: 'global.menu.categorias'
      },
      component: CategoriasEditComponent
    }
  }
};
