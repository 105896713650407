import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ENTITY_NAME_PROF} from './prof.entity.interface';
import {IJsonProf} from './jsonProf.entity.interface';

export const ENTITY_PROF: IEntityDefinition<IJsonProf> = {
  name: ENTITY_NAME_PROF,
  roles: [ROLE.RH],
  searchPlaceholder: 'prof.pesquisa',
  pageTitle: 'global.menu.prof',
  metadata: {
    keyName: 'nProfissao',
    fields: [
      {
        name: 'nProfissao',
        width: '120px',
        caption: 'profissao.fields.nProfissao',
        placeholder: 'profissao.fields.nProfissao',
        validators: {required: true, maxlength: 6}
      },
      {name: 'designaBreve', caption: 'profissao.fields.designaBreve', placeholder: 'profissao.fields.designaBreve', validators: {required: true, maxlength: 40}},
      {
        name: 'designaCompl',
        caption: 'profissao.fields.designaCompl',
        placeholder: 'profissao.fields.designaCompl',
        visible: false,
        validators: {required: true, maxlength: 150}
      },
      {
        name: 'codClassNaCProf',
        caption: 'profissao.fields.codClassNaCProf',
        placeholder: 'profissao.fields.codClassNaCProf',
        entity: {name: 'qpcnp', keyTarget: 'codClassNaCProf', output: 'codClassNaCProf', outputTarget: 'codClassNaCProf'},
        validators: {required: true}
      }
    ],
    order: 'nProfissao',
    searchFields: 'nProfissao,designaBreve,designaCompl',
    listFields: 'nProfissao,designaBreve,designaCompl'
  },
  autocomplete: {
    rowTemplate: '{{nProfissao}} - {{designaBreve}}',
    output: 'designaBreve',
    searchFields: 'nProfissao,designaBreve,designaCompl'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  }
};
