import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_CATEGORIA, ETipoCategoria} from './tipoCategoria.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPOS_CATEGORIA_ABONOS: IDataSource<ETipoCategoria> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_CATEGORIA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoCategoria.NA, name: 'tiposCategoria.data.notApplicable'},
    {value: ETipoCategoria.VENCIMENTO, name: 'tiposCategoria.data.vencimento'},
    {value: ETipoCategoria.DIUTURNIDADE, name: 'tiposCategoria.data.diuturnidade'},
    {value: ETipoCategoria.PREMIOS, name: 'tiposCategoria.data.premios'},
    {value: ETipoCategoria.COMISSOES, name: 'tiposCategoria.data.comissoes'},
    {value: ETipoCategoria.SUB_REFEICAO, name: 'tiposCategoria.data.subRefeicao'},
    {value: ETipoCategoria.AJUDA_CUSTO, name: 'tiposCategoria.data.ajudaCusto'},
    {value: ETipoCategoria.HORA_EXTRA, name: 'tiposCategoria.data.horaExtra'},
    {value: ETipoCategoria.FERIAS, name: 'tiposCategoria.data.ferias'},
    {value: ETipoCategoria.SUB_FERIAS, name: 'tiposCategoria.data.subFerias'},
    {value: ETipoCategoria.FERIAS_PAGAS, name: 'tiposCategoria.data.feriasPagas'},
    {value: ETipoCategoria.SUB_NATAL, name: 'tiposCategoria.data.subNatal'},
    {value: ETipoCategoria.TRAB_NOCTURNO, name: 'tiposCategoria.data.trabNocturno'},
    {value: ETipoCategoria.ISENCAOHORARIO, name: 'tiposCategoria.data.insencaoHorario'},
    {value: ETipoCategoria.SUB_TURNO, name: 'tiposCategoria.data.subTurno'},
    {value: ETipoCategoria.FALTAS_PAGAS, name: 'tiposCategoria.data.faltasPagas'},
    {value: ETipoCategoria.INDEMNIZACAO_CADUCIDADE, name: 'tiposCategoria.data.indemnizacaoCaducidade'},
    {value: ETipoCategoria.INDEMNIZACAO_DESPEDIMENTO, name: 'tiposCategoria.data.indemnizacaoDespedimento'},
    {value: ETipoCategoria.INDEMNIZACAO_GLOBAL, name: 'tiposCategoria.data.indemnizacaoGlobal'},
    {value: ETipoCategoria.DESLOCACAO, name: 'tiposCategoria.data.deslocacao'},
    {value: ETipoCategoria.REM_PROF_LIVRE, name: 'tiposCategoria.data.remProfLivre'},
    {value: ETipoCategoria.IVA, name: 'tiposCategoria.data.iva'},
    {value: ETipoCategoria.Virtual, name: 'tiposCategoria.data.virtual'},
    {value: ETipoCategoria.SuplementoRemuneratorio, name: 'tiposCategoria.data.suplementoRemuneratorio'},
    {value: ETipoCategoria.TicketValesRefeicao, name: 'tiposCategoria.data.ticketValesRefeicao'},
    {value: ETipoCategoria.PagamentosEmEspecie, name: 'tiposCategoria.data.pagamentosEmEspecie'},
    {value: ETipoCategoria.ValesEducacao, name: 'tiposCategoria.data.valesEducacao'},
    {value: ETipoCategoria.UtlViaturaPatronal, name: 'tiposCategoria.data.utlViaturaPatronal'},
    {value: ETipoCategoria.GratificacoesBombeiros, name: 'tiposCategoria.data.gratificacoesBombeiros'},
    {value: ETipoCategoria.CartaoOferta, name: 'tiposCategoria.data.cartaoOferta'},
    {value: ETipoCategoria.ApoioRegimeLayoff, name: 'tiposCategoria.data.apoioRegimeLayoff'},
    {value: ETipoCategoria.DespesasTeletrabalho, name: 'tiposCategoria.data.despesasTeletrabalho'},
    {value: ETipoCategoria.UtilizHabitaPropriaPermanente, name: 'tiposCategoria.data.utilizHabitaPropriaPermanente'},
    {value: ETipoCategoria.GratificacaoBalancoArt236, name: 'tiposCategoria.data.gratificacaoBalancoArt236'}
  ]
});
