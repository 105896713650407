import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ENTITY_NAME_TAXAS_IVA, IJsonTaxasIva} from './taxasIva.entity.interface';

export const ENTITY_TAXAS_IVA: IEntityDefinition<IJsonTaxasIva> = {
  name: ENTITY_NAME_TAXAS_IVA,
  roles: [ROLE.CONTABILIDADE],
  searchPlaceholder: 'taxasiva.pesquisa',
  metadata: {
    keyName: 'idTaxasIVA',
    fields: [
      {name: 'idTaxasIVA', type: 'cgsmallint', visible: false},
      {name: 'codIVA', caption: 'taxasiva.fields.codIva', visible: true},
      {
        name: 'codigoIva',
        caption: 'taxasiva.fields.codIva',
        visible: false,
        entity: {name: 'ivas', keyTarget: 'codIVA', outputTarget: 'codIVA'}
      },
      {name: 'dataAltLegal', type: 'date', caption: 'taxasiva.fields.dataAltLegal', placeholder: 'taxasiva.fields.dataAltLegal', validators: {required: true}},
      {name: 'taxa', type: 'number', caption: 'taxasiva.fields.taxa', placeholder: 'taxasiva.fields.taxa', validators: {required: true}},
      {name: 'observacao', caption: 'taxasiva.fields.observacao', placeholder: 'taxasiva.fields.observacao'}
    ],
    order: 'taxa',
    searchFields: 'taxa,dataAltLegal,codIva'
  },
  autocomplete: {
    rowTemplate: '{{taxa}} %',
    output: 'taxa',
    searchFields: 'taxa'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.CONTABILIDADE],
        pageTitle: 'global.menu.taxasiva'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.CONTABILIDADE],
        pageTitle: 'global.menu.taxasiva'
      }
    }
  }
};
