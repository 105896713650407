import type {Dictionary} from 'lodash';
import {groupBy, merge} from 'lodash-es';
import {Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {interpolate, isArray, isDefined, isDefinedNotNull, isFunction, isObject, isUndefinedOrNull} from '../../../common/utilities/utilities';
import type {IPlEditComponentOptionsInputSelect, IPlEditSelectGroup, IPlEditSelectItem, IPlEditSelectOption, TPlEditSelectSourceFn} from './edit.select.component.interface';
import {PlEditInputTypeComponent} from '../../generic/input/edit.input.type.component';

@Component({
  selector: 'pl-edit-select',
  templateUrl: './edit.select.component.html',
  standalone: false,
  exportAs: 'cgcEditSelect'
})
export class PlEditSelectComponent extends PlEditInputTypeComponent<any, IPlEditComponentOptionsInputSelect> implements OnInit, OnChanges {
  @Input() public size: string;
  @Input() public source: Array<unknown>;

  public itemSource: Array<IPlEditSelectOption | IPlEditSelectGroup>;
  public hasGroups: boolean;
  public output: string;

  constructor(protected readonly _injector: Injector) {
    super('select', _injector);
    this._defaultOptions = Object.freeze<IPlEditComponentOptionsInputSelect<unknown>>(
      merge({}, this._defaultOptions, {
        size: undefined,
        select: {
          list: undefined,
          key: undefined,
          labelProp: 'name',
          valueProp: 'value',
          groupProp: undefined
        }
      })
    );
    this.itemSource = [];
    this.hasGroups = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._handleChanges();
    this._buildItemSource();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {size, source, properties} = changes;
    if (size && !size.isFirstChange()) {
      this._handleChanges();
    }
    if ((properties && !properties.isFirstChange()) || (source && !source.isFirstChange())) {
      if (source && !source.isFirstChange()) {
        this._handleChanges();
      }
      this._buildItemSource();
    }
  }

  public updateComponent(properties: IPlEditComponentOptionsInputSelect<unknown>): void {
    super.updateComponent(properties);
    if (properties && properties !== this.options) {
      this._buildItemSource();
    }
  }

  public updateValue(value: unknown): void {
    super.updateValue(value);
    this._setValueFromModel();
  }

  public inputValueChanged(option: IPlEditSelectItem): void {
    if (option === this.model) {
      return;
    }
    this.value = this.options.select.valueProp && isObject(option) ? option[this.options.select.valueProp] : option;
    this._evaluateOutput();
    this.render();
  }

  public readonly fnCompareItems = (a: IPlEditSelectItem, b: IPlEditSelectItem): boolean => this._compareItems(a, b);

  public get itemSourceOptions(): Array<IPlEditSelectOption> {
    return <Array<IPlEditSelectOption>>this.itemSource;
  }

  public get itemSourceGroups(): Array<IPlEditSelectGroup> {
    return <Array<IPlEditSelectGroup>>this.itemSource;
  }

  private _handleChanges(): void {
    if (isDefinedNotNull(this.size)) {
      this.options.size = this.size;
    }
    if (this.source) {
      this.options.select.list = this.source;
    }
  }

  private _buildItemSource(): void {
    let source: Array<IPlEditSelectItem> | TPlEditSelectSourceFn<IPlEditSelectItem> = this.options.select.list;
    if (isFunction(source)) {
      source = source();
    }
    Promise.resolve(source).then((list: Array<IPlEditSelectItem>) => {
      let itemSource: Array<IPlEditSelectOption | IPlEditSelectGroup> = [];
      if (isArray(source)) {
        for (let sourceIndex = 0; sourceIndex < list.length; sourceIndex++) {
          const currentItem: IPlEditSelectItem = list[sourceIndex];
          const newOption: IPlEditSelectOption = this._buildOption(currentItem, sourceIndex);
          itemSource.push(newOption);
        }
      }
      this.hasGroups = false;
      if (this.options.select.groupProp) {
        const itemGroupSource: Array<IPlEditSelectOption> = <Array<IPlEditSelectOption>>itemSource;
        const groupedItemSource: Dictionary<Array<IPlEditSelectOption>> = groupBy(itemGroupSource, (item: IPlEditSelectOption) => item.value[this.options.select.groupProp]);
        const groups = Object.keys(groupedItemSource);
        if (groups.length) {
          this.hasGroups = true;
          itemSource = [];
          for (const key of groups) {
            itemSource.push({
              label: key,
              disabled: false,
              options: groupedItemSource[key]
            });
          }
        }
      }

      this.itemSource = itemSource;
      this._setValueFromModel();
    });
  }

  private _buildOption(item: IPlEditSelectItem, sourceIndex: number): IPlEditSelectOption {
    const newOption: IPlEditSelectOption = {
      value: item,
      disabled: (<any>item).disabled === true,
      sourceIndex: sourceIndex,
      rowTemplate: undefined,
      name: undefined
    };
    if (isObject(item)) {
      const labelProp: string = this.options.select.labelProp;
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      newOption.rowTemplate = String(labelProp.includes('{{') ? interpolate(labelProp)(item) : item[labelProp]);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      newOption.rowTemplate = String(item);
    }
    return newOption;
  }

  private _setValueFromModel(): void {
    if (!this.value && this.value !== 0) {
      return;
    }
    if (this.options.select.valueProp && !isObject(this.value)) {
      const newValue: IPlEditSelectOption | IPlEditSelectGroup = this.itemSource.find((item) => {
        return (<IPlEditSelectOption>item).value[this.options.select.valueProp] === this.value;
      });
      if (isDefined(newValue)) {
        this.formControl.setValue((<IPlEditSelectOption>newValue).value, {emitEvent: false});
      }
    }
    this._evaluateOutput();
  }

  private _compareItems(a: IPlEditSelectItem, b: IPlEditSelectItem): boolean {
    if (this.options.select.valueProp) {
      const isObjectA = isObject(a);
      if (isObjectA && isObject(b)) {
        return a[this.options.select.valueProp] === b[this.options.select.valueProp];
      }
      if (isObjectA) {
        return a[this.options.select.valueProp] === b;
      }
    }
    return a === b;
  }

  private _evaluateOutput(): void {
    const value: IPlEditSelectItem = this.formControl.value;
    if (isUndefinedOrNull(value)) {
      this.output = '';
    } else if (this.options.select.labelProp && isObject(value)) {
      const labelProp: string = this.options.select.labelProp;
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      this.output = String(labelProp.includes('{{') ? interpolate(labelProp)(value) : value[labelProp]);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      this.output = String(value);
    }
  }
}
