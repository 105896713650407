<entity-list
  [entityName]="entityABDES"
  [maintenanceMode]="maintenanceMode"
  [toolbarInstanceId]="instanceName"
  [dataGridState]="dataGridState"
  [searchValue]="searchValue"
  [filter]="filterValue"
  [initialFilter]="initialFilterValue"
  [serviceMethodsOverride]="serviceMethodsOverride"
  [properties]="listOptions"
  (evtOnRefresh)="onRefreshList()"
  (evtDataGridPreparing)="onDataGridPreparing($event)"
  (evtDataGridCellClick)="onDataGridCellClick($event)"
  (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
  (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
  (evtDataGridStateChanged)="onDataGridStateChanged($event)"
  (evtDataGridStateCleared)="onDataGridStateCleared()">
  <div *entityListItemDetail="let item">
    <div class="card">
      <div class="card-header">
        <h6 class="card-title">
          <strong class="fatura-title" [translate]="'crabd.tableFields.crabdList'"></strong>
          <button type="button" class="btn btn-primary btn-sm float-end" (click)="new(item.data)"><i class="fa fa-plus-circle fa-fw"></i>&nbsp;<span [translate]="'entity.action.new'"></span></button>
        </h6>
      </div>
      <div class="card-body">
        <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="ativosCoeficienteCM" [dataSource]="item.data._crabd" (onCellDblClick)="onCellDblClick($event)">
          <div *dxTemplate="let itemDetail of 'cellTemplateBtns'">
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-xs btn-warning" (click)="edit(itemDetail.data)">
                <i class="fa fa-fw fa-pencil" aria-hidden="true"></i>
              </button>
              <button type="button" class="btn btn-xs btn-danger" (click)="delete(itemDetail.data, item.data._crabd)">
                <i class="fa fa-fw fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </div>
  </div>
</entity-list>
