<pl-form>
  <div class="modal-header">
    <h4 class="modal-title" [translate]="modalTitle"></h4>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-6" *ngIf="showArtigoSaftInfo">
        <div class="card">
          <div class="card-header">
            <h6 class="card-title" [translate]="'importadorSaftDirect.strings.configArtigosSaftLabel'"></h6>
          </div>
          <div class="card-body">
            <pl-group>
              <edit>
                <pl-edit type="boolean" [(model)]="model.isNew" [properties]="{label: 'importadorSaftDirect.fields.isNew', disabled: true}"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.productCode'"></label>
              <edit>
                <pl-edit type="text" [model]="model.productCode" [properties]="{disabled: true}"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.productDescription'"></label>
              <edit>
                <pl-edit type="text" [model]="model.productDescription" [properties]="{disabled: true}"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.productType'"></label>
              <edit>
                <pl-edit type="text" [model]="model.productType" [properties]="{disabled: true}"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>
      </div>
      <div [ngClass]="{'col-md-12': !showArtigoSaftInfo, 'col-md-6': showArtigoSaftInfo}">
        <pl-group>
          <label [translate]="'importadorSaftDirect.fields.nConta'"></label>
          <edit>
            <entity-autocomplete entity="pocs" [model]="model" (modelChange)="modelChange($event)" [selectedKey]="model.nConta" [fieldsMap]="{nome: 'nContaNome'}" [output]="'key'">
            </entity-autocomplete>
            <div><small>Por exemplo: 711</small></div>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'importadorSaftDirect.fields.nContaNC'"></label>
          <edit>
            <entity-autocomplete
              entity="pocs"
              [model]="model"
              [fieldsMap]="{nConta: 'nContaNC', nome: 'nContaNCNome'}"
              [output]="'key'"
              (selectedKeyChange)="model.nContaNC = $event"
              (evtSelectedDescriptionChanged)="model.nContaNCNome = $event"></entity-autocomplete>
            <div><small>Por exemplo: 7181</small></div>
          </edit>
        </pl-group>
        <pl-group *ngIf="showCCusto">
          <label [translate]="'importadorSaftDirect.fields.nCCusto'"></label>
          <edit>
            <entity-autocomplete entity="ccustos" [model]="model" [(selectedKey)]="model.nCCusto" [output]="'key'" [filter]="'encerrado=0'" [properties]="{allowInvalid: false}"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group *ngIf="showRepCC">
          <label [translate]="'importadorSaftDirect.fields.nCodRepCC'"></label>
          <edit>
            <entity-autocomplete entity="reparticoesccustosfaturacao" [model]="model" [(selectedKey)]="model.nCodRepCC" [output]="'key'" [properties]="{allowInvalid: false}"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <edit>
            <pl-edit type="boolean" [(model)]="model.acresceDigMercado" [properties]="{label: 'importadorSaftDirect.fields.acresceDigMercado'}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <edit>
            <pl-edit type="boolean" [(model)]="model.acresceDigIVA" [properties]="{label: 'importadorSaftDirect.fields.acresceDigIVA'}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <edit>
            <pl-edit type="boolean" [(model)]="model.retencao" [properties]="{label: 'importadorSaftDirect.fields.retencao'}"></pl-edit>
          </edit>
        </pl-group>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12" *ngIf="showPb">
        <div class="pb-container">
          <div class="pb-LabelText" [translate]="'importadorSaftDirect.strings.applyProductConfig'"></div>
          <div class="progress">
            <div
              class="progress-bar bg-warning progress-bar-striped progress-bar-animated"
              role="progressbar"
              [attr.aria-valuenow]="100"
              [attr.aria-valuemin]="0"
              [attr.aria-valuemax]="100"
              style="width: 100%"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-sm btn-primary action-close" (evtClicked)="fnCloseModal()" [plPromise]="applyPromise" data-focus>
      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalSave'"></span>
    </pl-button>

    <pl-button klass="btn-sm btn-light" (evtClicked)="dismiss()" [plPromise]="applyPromise">
      <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalCancel'"></span>
    </pl-button>
  </div>
</pl-form>
