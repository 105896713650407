import {Injectable} from '@angular/core';
import {TDate} from '../../../common/dates';
import {ApiService} from '../../services/api/api.service';
import {EATAutoFaturacaoEnquadramento, IJsonDocfaNumATConsulta, IJsonDocfaNumATRegisto, IJsonDocfaNumATResultado, IJsonDocfanumPorComunicarAT} from './jsonDocfaNum.entity.interface';
import {IDocfasNumsService} from './docfasNums.entity.service.interface';
import {IApiQueryRequestConfig, THttpQueryResponse, TServiceQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';
import {ENTITY_NAME_DOCFAS_NUMS} from './docfasNums.entity.interface';

@Injectable({
  providedIn: 'root'
})
export class DocfasNumsService implements IDocfasNumsService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/${ENTITY_NAME_DOCFAS_NUMS}`;
  }

  public getSerieComunicadaAT(idDocfaNum: number): TServiceResponse<IJsonDocfaNumATConsulta> {
    return this._apiService.get<IJsonDocfaNumATConsulta>({
      url: `${this._path}/${idDocfaNum}/serieat`
    });
  }

  public consultaSerieNaAT(idDocfaNum: number): TServiceResponse<IJsonDocfaNumATConsulta> {
    return this._apiService.get<IJsonDocfaNumATConsulta>({
      url: `${this._path}/${idDocfaNum}/consultaserieat`
    });
  }

  public registaSerieATPorWebService(idDocfaNum: number, atNumInicialSeq: number, atDataInicioPrev: TDate, atcud: string): TServiceResponse<IJsonDocfaNumATRegisto> {
    return this._registaSerieAT(idDocfaNum, atNumInicialSeq, atDataInicioPrev, atcud, true);
  }

  public registaSerieATManual(
    idDocfaNum: number,
    atNumInicialSeq: number,
    atDataInicioPrev: TDate,
    atcud: string,
    nconta: string,
    enquadramentoterceiro: EATAutoFaturacaoEnquadramento
  ): TServiceResponse<IJsonDocfaNumATRegisto> {
    return this._registaSerieAT(idDocfaNum, atNumInicialSeq, atDataInicioPrev, atcud, false, nconta, enquadramentoterceiro);
  }

  public finalizaSerieAT(idDocfaNum: number, ultimoDocEmitido: number, justificacao: string): TServiceResponse<IJsonDocfaNumATResultado> {
    return this._apiService.put<IJsonDocfaNumATResultado>({
      url: `${this._path}/${idDocfaNum}/finalizaserieat`,
      params: {
        ultimodocemitido: ultimoDocEmitido,
        justificacao: justificacao
      }
    });
  }

  public getSeriesPorComunicarAT(search?: string, order?: string, page?: string | number, perpage?: string | number, searchFields?: string): TServiceQueryResponse<IJsonDocfanumPorComunicarAT> {
    const request: IApiQueryRequestConfig = {
      url: `${this._path}/seriesporcomunicarat`,
      params: {
        pagina: Number.isNaN(Number(page)) ? undefined : Number(page),
        porpagina: Number.isNaN(Number(perpage)) ? undefined : Number(perpage),
        pesquisa: search,
        ordena: order,
        campospesq: searchFields
      }
    };

    return new Promise<THttpQueryResponse<IJsonDocfanumPorComunicarAT>>((resolve, reject) => {
      this._apiService
        .query<IJsonDocfanumPorComunicarAT>(request)
        .then((response: THttpQueryResponse<IJsonDocfanumPorComunicarAT>) => {
          resolve(response);
        })
        .catch(reject);
    });
  }

  public comunicarSeriesAT(seriesPorComunicarAT: Array<IJsonDocfanumPorComunicarAT>): TServiceResponse<Array<IJsonDocfanumPorComunicarAT>> {
    return this._apiService.put<Array<IJsonDocfanumPorComunicarAT>, Array<IJsonDocfanumPorComunicarAT>>({
      url: `${this._path}/comunicarseriesat`,
      body: seriesPorComunicarAT
    });
  }

  private _registaSerieAT(
    idDocfaNum: number,
    atNumInicialSeq: number,
    atDataInicioPrev: TDate,
    atcud: string,
    porWebService: boolean,
    nconta: string = '',
    enquadramentoterceiro: EATAutoFaturacaoEnquadramento = EATAutoFaturacaoEnquadramento.None
  ): TServiceResponse<IJsonDocfaNumATRegisto> {
    return this._apiService.put<IJsonDocfaNumATRegisto>({
      url: `${this._path}/${idDocfaNum}/registaserieat`,
      params: {
        atnuminicialseq: atNumInicialSeq,
        atdatainicioprev: atDataInicioPrev,
        atcud: atcud,
        porwebservice: porWebService,
        nconta: nconta,
        enquadramentoterceiro: enquadramentoterceiro
      }
    });
  }
}
