<pl-form [submit]="close" [plPromise]="promise">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'fct.strings.accessoFCT'"></h4>
  </div>

  <div class="modal-body">
    <img alt="Fundo de Compensação" style="max-width: 100%" class="d-block" src="../../../../../../assets/images/logo-fc.webp" />

    <div style="margin: 0 -15px 8px -15px; font-size: 12px; font-weight: 600; padding: 15px; text-align: center" [innerHTML]="nomeEmpresa"></div>

    <pl-group>
      <label [translate]="'fct.strings.niss'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.niss" plAutoFocus></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'fct.strings.password'"></label>
      <edit>
        <pl-edit type="password" [(model)]="model.password"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="submit" class="btn btn-primary"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></button>

    <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></button>
  </div>
</pl-form>
