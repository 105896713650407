import moment, {Moment} from 'moment';
import {merge, orderBy} from 'lodash-es';
import {Subscription} from 'rxjs';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Component, ElementRef, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StateService} from '@uirouter/core';
import {
  copy,
  elementIndex,
  IPlEditComponentOptionsInput,
  IPlEditComponentOptionsInputNumber,
  isArray,
  isDefinedNotNull,
  isEmpty,
  isEmptyObject,
  isError,
  isFunction,
  isNumber,
  isObject,
  isString,
  isUndefined,
  isUndefinedOrNull,
  KEYCODES,
  Logger,
  moveItemInArray,
  PlAlertService,
  PlDocumentService,
  PlTranslateService,
  TEditInputKeyboardEvent,
  timeout
} from 'pl-comps-angular';
import {AdiantamentosRegistarModalComponent} from '../../../../modules/adiantamentos/modals/registar/adiantamentos.registar.modal.component';
import {AdiantamentosService} from '../../../../modules/adiantamentos/adiantamentos.module.service';
import {AlterarCamposSerieModalComponent} from '../../modals/alterarcamposserie/alterarCamposSerie.modal.component';
import {AuthService} from '../../../../services/auth/auth.service';
import {CG_DEFAULT_AT_NIF} from '../../../../services/config/config.service.interface';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {CGStateService} from '../../../../components/state/cg.state.service';
import {CGVatService} from '../../../../components/vat/cgvat.service';
import {ConfigService} from '../../../../services/config/config.service';
import {ConfigSiteService} from '../../../../services/configsite.service';
import {CUSTO_MEDIO_PONDERADO, FIFO} from '../../../../datasources/criteriodisponiveis/criterioDisponiveis.datasource.interface';
import {DevolucoesModalComponent} from '../../modals/devolucoes/devolucoes.modal.component';
import {docComercialEmptyClifo} from '../../service/documentos.entity.service.interface';
import {DocsComerciaisImportadorLinhasModalComponent} from '../../modals/importadorlinhas/docsComerciais.importadorLinhas.modal.component';
import {DocumentoArredondamentoManualModalComponent} from '../../modals/arredondamentomanual/documento.arredondamentoManual.modal.component';
import {DocumentoArtigosAdvancedSearchModalComponent} from '../../modals/advancedsearch/documento.artigosAdvancedSearch.modal.component';
import {DocumentoMeiosPagamentoModalComponent} from '../../modals/meiospagamento/documento.meiosPagamento.modal';
import {DocumentoPesqDocsGlobaisModalComponent} from '../../modals/pesqdocsglobais/documento.pesqDocsGlobais.modal.component';
import {DocumentoPesqDocsModalComponent} from '../../modals/pesqdocs/documento.pesqDocs.modal.component';
import {DocumentoRastreabilidadeModalComponent} from '../../modals/rastreabilidade/documento.rastreabilidade.modal.component';
import {DocumentoRastreabilidadeService} from '../../modals/rastreabilidade/documento.rastreabilidade.service';
import {DocumentosService} from '../../service/documentos.entity.service';
import {EConfigOptionsInstanceName, IDocsComerciaisEditConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {
  EDocsComerciaisClifoStatus,
  EFluxoMonetario,
  ESubTipoMovimentoSAFT,
  IDocComercial,
  IDocComercialArtigo,
  IDocComercialCab,
  IDocComercialClifoInfo,
  IDocComercialDrag,
  IDocComercialEditStateParams,
  IDocComercialEvaluateConfigOptionInvisibleParams,
  IDocComercialLinha,
  IDocComercialLinhaSerie,
  IDocComercialStocks,
  IDocsComerciaisEntity,
  IDocsComerciaisEntityService,
  IDocsComerciaisGroup
} from '../../docsComerciais.entity.interface';
import {EDocTipoEntity, EFolderType, IJsonGDFolder} from '../../../gdoc/JsonGDoc.entity.interface';
import {EEntityStateDetailType, entityStateDetailQueryParam, entityStateNameDetail} from '../../../../../common/utils/entity.state.utils';
import {EFACCBHASHEstado, IJsonDocComercial, IJsonDocComercialCab, IJsonDocComercialClifo, IJsonDocComercialLinha, IJsonDocResumoIvaItem} from '../../jsonDocComercial.entity.interface';
import {EFaturaCirculacaoLoginModalComponent} from '../../../../modules/efaturacirculacaologin/eFaturaCirculacaoLogin.modal.component';
import {EFolderDoc, ENTITY_NAME_GDOC, IGDocEntityService} from '../../../gdoc/gdoc.entity.interface';
import {EGroupName, EStatusCode} from '../../../../../config/constants';
import {EGrupoDoc, EGrupoDocType, grupoDocToDocType} from '../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {EncomendasSatisfazerClifoModalComponent} from '../../../../components/docscomerciais/encomendas/modals/satisfazerclifo/encomendas.satisfazerclifo.modal.component';
import {ENTITY_DOCS_COMERCIAIS_COMPRAS, ENTITY_DOCS_COMERCIAIS_VENDAS} from '../../docsComerciais.entity';
import {ENTITY_NAME_ARCLI} from '../../../arclis/arClis.entity.interface';
import {ENTITY_NAME_ARTIGOS, IArtigosEntityService} from '../../../artigos/artigos.entity.interface';
import {ENTITY_NAME_CLIENTES, ENTITY_NAME_CLIFOS, IClifo, IClifosEntityService, TClifos} from '../../../clifos/clifos.entity.interface';
import {ENTITY_NAME_CONDICOES_PAGAMENTOS} from '../../../condicoespagamento/condicoesPagamento.entity.interface';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../docfas/docFas.entity.interface';
import {ENTITY_NAME_DOCFAS_NUMS} from '../../../docfasnums/docfasNums.entity.interface';
import {ENTITY_NAME_MOEDA, IMoedaEntityService} from '../../../../modules/portalcontabilidade/manutencao/moeda/moeda.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ETipoAnalitica} from '../../../../datasources/tipoanalitica/tipoAnalitica.datasource.interface';
import {ETipoAutoFaturacao, IJsonDocfa} from '../../../docfas/jsonDocFa.entity.interface';
import {ExternalCodPostaisService} from '../../../../services/external.cod.postais.service';
import {ExternalNifsService} from '../../../../services/externalnifs/externalnifs.service';
import {ExtratosDTModalComponent} from '../../../../modules/portalcontabilidade/estatistica/extratosdt/modals/extratosDT.modal.component';
import {focusAndSelectElementWithDelay, focusElement} from '../../../../../common/utils/element.utils';
import {generateUUID} from '../../../../../common/utils/utils';
import {GrupoDocService} from '../../../../services/grupodoc/grupo.doc.service';
import {hasAuthority} from '../../../../../common/utils/roles.utils';
import {
  IAdiantamentosParams,
  IAdiantamentosRegisto,
  IAdiantamentosRegistoResult,
  MODULE_NAME_ADIANTAMENTOS_CLIENTES,
  MODULE_NAME_ADIANTAMENTOS_FORNECEDORES
} from '../../../../modules/adiantamentos/adiantamentos.module.interface';
import {IArquivoDigitalDocViewerEvtChangedAttachment} from '../../../../components/arquivodigital/common/docviewer/arquivodigital.docviewer.component.interface';
import {ICGExceptionError, ICGExceptionErrorField} from '../../../../components/exceptions/exceptions.service.interface';
import {IComercialDigitalGDocViewerDoc} from '../../../../components/arquivodigital/comercialdigital/docviewer/comercialDigital.docViewer.interface';
import {IContabDigitalDocViewerRecolhaCallback} from '../../../../components/arquivodigital/contabilidade/docviewerrecolha/contabilidadedigital.docviewer.recolha.component.interface';
import {IDiferimentosSaved, MODULE_NAME_CALCULO_DIFERIMENTOS} from '../../../../modules/diferimentos/diferimentos.module.interface';
import {IDocComercialArquivoDigitalAttachment, IDocComercialCallback, IDropdownActionsDocComercial} from './docsComerciais.component.interface';
import {IEntityAutocompleteOptions} from '../../../../components/entity/entity.autocomplete.definition.interface';
import {IEntityServiceMethodsOverride} from '../../../../components/entity/entity.definition.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IGDocsCallback, IGDocsOnAddingFile} from '../../../../components/gdocs/gdocs.component.interface';
import {IJsonAdiantamentoConfig} from '../../../../modules/adiantamentos/jsonAdiantamentos.module.interface';
import {IJsonArCli} from '../../../arclis/jsonArCli.entity.interface';
import {IJsonArmazem} from '../../../armazens/jsonArmazem.entity.interface';
import {IJsonArtigo, IJsonArtigoStockArmazens} from '../../../artigos/jsonArtigo.entity.interface';
import {IJsonBoolean} from '../../../../../common/interfaces/json';
import {IJsonClifo} from '../../../clifos/jsonClifo.entity.interface';
import {IJsonCodPostalHelper} from '../../../../interfaces/jsonCodPostalHelper.interface';
import {IJsonCodPostal} from '../../../codpostais/jsonCodPostal.entity.interface';
import {IJsonCondicaoPagamento} from '../../../condicoespagamento/jsonCondicaoPagamento.entity.interface';
import {IJsonDocComercialMeioPagam} from '../meiospagamento/jsonDocComercialMeioPagam.interface';
import {IJsonDocfaNum} from '../../../docfasnums/jsonDocfaNum.entity.interface';
import {IJsonDocOCRCab} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {IJsonExternalNif} from '../../../../services/externalnifs/externalnifs.interface';
import {IJsonIva} from '../../../ivas/jsonIva.entity.interface';
import {IJsonMoeda, IJsonMoedaUpdateCambio} from '../../../../modules/portalcontabilidade/manutencao/moeda/jsonMoeda.interface';
import {IJsonMoradaFaturacao} from '../../../moradasfaturacao/jsonMoradaFaturacao.entity.interface';
import {IJsonMorAl} from '../../../morals/jsonMoral.entity.interface';
import {IJsonRastreabilidadeFaturacao} from '../../modals/rastreabilidade/jsonRastreabilidade.interface';
import {IJsonTransportador} from '../../../../interfaces/jsonTransportador.interface';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {MovimentosAbertoModalComponent} from '../../../../modules/portalclientaccounts/contabilidade/movimentosaberto/modals/movimentosAberto.modal.component';
import {Pagination} from '../../../../../common/pagination/pagination';
import {ROLE} from '../../../../services/role.const';
import {SelecaoloteslinhaModalComponent} from '../../modals/selecaolotes/linha/selecaoLotes.linha.modal.component';
import {TDate} from '../../../../../common/dates';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';
import {TxtFaturacaoModalComponent} from '../../../clifos/modals/txtfaturacao/txtFaturacao.modal.component';
import {EFaturaCirculacaoLoginService} from '../../../../services/efaturacirculacaologin/eFaturaCirculacaoLogin.service';
import {IJsonEFaturaCirculacaoLogin} from '../../../../services/efaturacirculacaologin/eFaturaCirculacaoLogin.service.interface';
import {DocsComerciaisPrecoUnitarioModalComponent} from '../../modals/precoUnitario/docsComerciais.precoUnitario.modal.component';
import {IJsonTxtar} from '../../../txtar/jsonTxtar.entity.interface';
import {IJsonTxtcl} from '../../../txtcl/jsonTxtcl.entity.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';

const consFinalNome = 'Consumidor Final';
const INPUT_NAME_DESCRICAO = 'descricao';
const timeOutFocus = 350;
const ACTION_MEIOS_PAG_ORDER = 204;
const MENU_ALT_CAMPOS_SERIE_ORDER = 206;
const MENU_ITEM_ARRED_MANUAL_ORDER = 207;
const MENU_ITEM_RASTREABILIDADE_ORDER = 208;
const CACHE_VALIDITY_SECONDS = 10;
const MENU_ITEM_DEVOLUCOES = 213;
const MINIMUM_PER_PAGE = 25;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const DEFAULT_ALLOWED_PAGE_SIZES = Object.freeze([MINIMUM_PER_PAGE, 50, 75, 100]);
const DEFAULT_PER_PAGE = DEFAULT_ALLOWED_PAGE_SIZES[0];
const INVENTARIO_INICIAL_NDOCFA_CODE = 72;
const SELECTOR_ROW = 'tr.doccomercial-linha';
const CAMBIO_MOEDA_NDECIMAIS = 4;
const PERCENTAGE_HALF = 50;
const DEFAULT_MIN_INIC_TRANSP = 15;

@Component({
  selector: 'doccomercial',
  templateUrl: './docsComerciais.component.html'
})
export class DocsComerciaisComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public group: IDocsComerciaisGroup;
  @Input() public model: IDocComercial;
  @Input() public type: EEntityStateDetailType;
  @Input() public docComercialStateParams: IDocComercialEditStateParams;
  @Input() public entityName: string;
  @Input() public toolbarInstanceName: string;
  @Input() public callback: IDocComercialCallback;
  @Input() public lastDoc: IDocComercial;
  @Input() public maintenanceMode: boolean;
  @Input() public serviceMethodsOverride: IEntityServiceMethodsOverride;
  @Input() public statesDetailName: string;
  @Input() public arquivoDigital: boolean;
  @Output() public readonly evtModelChanged: EventEmitter<IDocComercial>;
  @Output() public readonly evtLastDocChanged: EventEmitter<IDocComercial>;
  @Output() public readonly evtDropdownActionsEvaluate: EventEmitter<IDropdownActionsDocComercial>;
  @Output() public readonly evtSetActionEnabled: EventEmitter<IDropdownActionsDocComercial>;
  @Output() public readonly evtComercialArquivoDigitalAttachmentChange: EventEmitter<IDocComercialArquivoDigitalAttachment>;
  @ViewChild('templateContentPerguntaFinalizaDoc') public readonly templateContentPerguntaFinalizaDoc: TemplateRef<HTMLElement>;
  @ViewChild('templateContentDevolucaoComLigacao') public readonly templateContentDevolucaoComLigacao: TemplateRef<HTMLElement>;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly cgDefaultAtNif: string;
  public readonly paisOutput: string;
  public readonly propertiesEdit: IPlEditComponentOptionsInput<unknown>;
  public readonly propertiesEditDisabled: IPlEditComponentOptionsInput<unknown>;
  public readonly propertiesEditArtigos: IEntityAutocompleteOptions;
  public readonly propertiesEditArtigosDisabled: IEntityAutocompleteOptions;
  public readonly propertiesEditUpdateOnBlur: IPlEditComponentOptionsInput<unknown>;
  public readonly propertiesEditUpdateOnBlurDisabled: IPlEditComponentOptionsInput<unknown>;
  public readonly propertiesEditDecimalsQtdLimit: IPlEditComponentOptionsInputNumber;
  public readonly propertiesEditDecimalsQtdLimitDisabled: IPlEditComponentOptionsInputNumber;
  public readonly propertiesEditDecimalsValoresLinhaLimit: IPlEditComponentOptionsInputNumber;
  public readonly propertiesEditDecimalsValoresLinhaLimitDisabled: IPlEditComponentOptionsInputNumber;
  public readonly propertiesEditDecimalsValoresUnitariosLimit: IPlEditComponentOptionsInputNumber;
  public readonly propertiesEditDecimalsValoresUnitariosLimitDisabled: IPlEditComponentOptionsInputNumber;
  public readonly stocks: IDocComercialStocks;
  public readonly paginationMinimumPerPage: number;
  public readonly paginationLinhas: Pagination;
  public readonly mainTabCargaId: string = 'mainTabCarga';
  public readonly mainTabFaturacaoPublicaId: string = 'mainTabFaturacaoPublica';
  public readonly mainTabLinhasId: string = 'mainTabFaturacaoLinhas';
  public readonly mainTabAnexosId: string = 'mainTabAnexos';
  public readonly gDocTipoDocEntity: EDocTipoEntity;
  public readonly condPagamentOutput: string;
  public readonly stateTypes: typeof EEntityStateDetailType;
  public readonly callbackGDocs: IGDocsCallback;
  public readonly callbackDocViewer: IContabDigitalDocViewerRecolhaCallback;
  public comercialArquivoDigitalAttachment: IDocComercialArquivoDigitalAttachment;
  public paginatedLinhas: Array<IDocComercialLinha>;
  public clifoDescription: string;
  public clifoEntName: TClifos;
  public clifoFilter: string;
  public dataDocAlertErrorsMsg: string;
  public docFa: Partial<IJsonDocfa>;
  public docfaNum: IJsonDocfaNum;
  public docFilter: string;
  public mostraEditCliente: boolean;
  public mainTabCargaEnabled: boolean;
  public nContaMoralFilter: string;
  public nContaNifSituacaoMsg: string;
  public promiseCalc: Promise<void>;
  public moeda: IJsonMoeda;
  public docfaNumFilter: string;
  public promise: Promise<void>;
  public optionShowObservacoes: boolean;
  public optionShowDocExterno: boolean;
  public optionShowDataDoc: boolean;
  public optionShowDataVenc: boolean;
  public optionShowDiferimentos: boolean;
  public optionShowCCusto: boolean;
  public optionShowMoeda: boolean;
  public optionShowNRefProcesso: boolean;
  public optionShowNArmazem: boolean;
  public optionShowNVendedor: boolean;
  public optionShowCodUnidMov: boolean;
  public optionShowValorCIva: boolean;
  public optionShowNNumer: boolean;
  public optionShowNRequisicao: boolean;
  public optionShowLiquido: boolean;
  public optionShowD2: boolean;
  public optionShowD3: boolean;
  public optionShowDescEmValor: boolean;
  public showDataEntregaPrev: boolean;
  public isTipoDocStocks: boolean;
  public isEntradaDiversa: boolean;
  public isSaidaDiversa: boolean;
  public codMoedaEmpresa: number;
  public abreviaturaMoedaEmpresa: string;
  public abreviaturaMoedaCliente: string;
  public docAssinadoNaoEncerrado: boolean;
  public hasShownEFaturaCirculacaoModal: boolean;
  public arredManualString: string;
  public showInfoStockArtigo: boolean;
  public showBtnDiferimentos: boolean;
  public isShowConfigOptions: boolean;
  public initting: boolean;
  public comercialAdvanced: boolean;
  public licencaModoCGOn: boolean;
  public portalName: string;
  public cgStoreUrlBackOffice: string;
  public isEntregaEfetivaSobreDocGlobal: boolean;
  public showMessageMoralError: boolean;
  public temAutoFaturacao: boolean;
  public isShowMoedaCambio: boolean;
  public isFornecedores: boolean;
  public isComprasFornecedor: boolean;
  public activeMainTab: string;
  public gDocFolderId: string;
  public gDocParams: string;
  public naoPerguntaSeFinalizaDoc: boolean;
  public clifoSaldoEmAberto: number;
  public isComprasEfectivas: boolean;
  public condicaoPagamento: IJsonCondicaoPagamento;
  public devolucaoEditar: boolean;
  public isDocumentoTerminado: boolean;
  public optionObtemDadosDocDigital: boolean;
  public isMobile: boolean;
  public editing: boolean;
  public comercialDigitalDoc: IComercialDigitalGDocViewerDoc;

  private readonly _servicosDocFas: IDocFasEntityService;
  private readonly _servicosDocfanums: IEntityService<IJsonDocfaNum>;
  private readonly _serviceMoedas: IMoedaEntityService;
  private readonly _serviceArtigos: IArtigosEntityService;
  private readonly _serviceArCli: IEntityService<IJsonArCli>;
  private readonly _serviceClifo: IClifosEntityService;
  private readonly _gDocService: IGDocEntityService;
  private readonly _serviceCondPagamento: IEntityService<IJsonCondicaoPagamento>;
  private readonly _element: HTMLElement;
  private readonly _subscriptionIsMobile: Subscription;
  private _docsComerciaisService: IDocsComerciaisEntityService;
  private readonly _textosLivresArtigo: Map<string, Array<IJsonTxtar>>;
  private readonly _textosLivresClifo: Map<string, Array<IJsonTxtcl>>;
  private _maintenanceCalculoDiferimentos: IModuleMaintenanceInstance<IDiferimentosSaved>;
  private _horaInicTran: Moment;
  private _subscription: Subscription;
  private _entArtigosPromise: Promise<void>;
  private _modalResult: boolean;
  private _obrigatorioMeiosPagam: boolean;
  private _firstEntryOptions: boolean;
  private _firstErrorInDocfaChanged: boolean;
  private _docComercialDrag: IDocComercialDrag;
  private _adiantamentoConfig: IJsonAdiantamentoConfig;
  private _editStateParams: IDocComercialEditStateParams;
  private _destroyed: boolean;
  private _docOCRCab: IJsonDocOCRCab;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _cgVatService: CGVatService,
    private readonly _documentosService: DocumentosService,
    private readonly _externalCodPostaisService: ExternalCodPostaisService,
    private readonly _externalNifsService: ExternalNifsService,
    private readonly _grupoDoc: GrupoDocService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _documentoRastreabilidadeService: DocumentoRastreabilidadeService,
    private readonly _configSiteService: ConfigSiteService,
    private readonly _adiantamentosService: AdiantamentosService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _configService: ConfigService,
    private readonly _translateService: TranslateService,
    private readonly _authService: AuthService,
    private readonly _cgModalService: CGModalService,
    private readonly _logger: Logger,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _stateService: StateService,
    private readonly _cgStateService: CGStateService,
    private readonly _plDocumentService: PlDocumentService,
    private readonly _eFaturaCirculacaoLoginService: EFaturaCirculacaoLoginService,
    private readonly _entityMaintenanceService: EntityMaintenanceService
  ) {
    this.configOptionsInstanceName = this._documentosService.configOptionsInstanceName;
    this.configOptionsGroupName = this._documentosService.configOptionsGroupName;
    this.evtModelChanged = new EventEmitter<IDocComercial>();
    this.evtLastDocChanged = new EventEmitter<IDocComercial>();
    this.evtDropdownActionsEvaluate = new EventEmitter<IDropdownActionsDocComercial>();
    this.evtSetActionEnabled = new EventEmitter<IDropdownActionsDocComercial>();
    this.evtComercialArquivoDigitalAttachmentChange = new EventEmitter<IDocComercialArquivoDigitalAttachment>();
    this.stateTypes = EEntityStateDetailType;
    this._element = this._elementRef.nativeElement;
    this.cgDefaultAtNif = CG_DEFAULT_AT_NIF;
    this.paisOutput = '{{codPais}} - {{nomePais}}';
    this.condPagamentOutput = '{{nCondPag}} - {{descricao}}';
    this.activeMainTab = this.mainTabLinhasId;
    this.gDocTipoDocEntity = EDocTipoEntity.GestaoComercial;
    this._gDocService = this._entityServiceBuilder.build<IJsonGDFolder, IGDocEntityService>(ENTITY_NAME_GDOC);
    this.comercialArquivoDigitalAttachment = {gDocId: '', gDocFolderId: '', docOCRCabID: ''};
    this.clifoSaldoEmAberto = undefined;
    this._destroyed = false;
    this.devolucaoEditar = false;
    this.callbackGDocs = {};
    this._editStateParams = {};
    this.callbackDocViewer = {};
    this._textosLivresArtigo = new Map<string, Array<IJsonTxtar>>();
    this._textosLivresClifo = new Map<string, Array<IJsonTxtcl>>();

    this.propertiesEdit = {
      events: {
        focus: (value: unknown, event: FocusEvent) => {
          this._tableEditFocus(event);
        }
      }
    };

    this.propertiesEditDisabled = {
      ...this.propertiesEdit,
      disabled: true
    };

    this.propertiesEditArtigos = {
      ...this.propertiesEdit,
      events: {
        focus: (value: unknown, event: FocusEvent) => {
          if (isDefinedNotNull(value) && !isEmpty(value)) {
            this._tableEditFocus(event);
          }
        }
      }
    };

    this.propertiesEditArtigosDisabled = {
      ...this.propertiesEditArtigos,
      disabled: true
    };

    this.propertiesEditUpdateOnBlur = {
      ...this.propertiesEdit,
      modelOptions: {updateOn: 'blur'}
    };

    this.propertiesEditUpdateOnBlurDisabled = {
      ...this.propertiesEditUpdateOnBlur,
      disabled: true
    };

    this.propertiesEditDecimalsQtdLimit = {
      ...this.propertiesEditUpdateOnBlur,
      decimalsLimit: this._configService.configurations.gestaoComercial.decimais.quantidades,
      events: {
        keydown: this._keydownQtd.bind(this)
      }
    };

    this.propertiesEditDecimalsQtdLimitDisabled = {
      ...this.propertiesEditDecimalsQtdLimit,
      disabled: true
    };

    this.propertiesEditDecimalsValoresLinhaLimit = {
      ...this.propertiesEditUpdateOnBlur,
      decimalsLimit: this._configService.configurations.gestaoComercial.decimais.valoresLinha
    };

    this.propertiesEditDecimalsValoresUnitariosLimit = {
      ...this.propertiesEditUpdateOnBlur,
      decimalsLimit: this._configService.configurations.gestaoComercial.decimais.valoresUnitarios
    };

    this.propertiesEditDecimalsValoresUnitariosLimitDisabled = {
      ...this.propertiesEditDecimalsValoresUnitariosLimit,
      disabled: true
    };

    this.stocks = {
      nArmazem: undefined,
      qtd: undefined,
      qtdTotal: undefined,
      item: undefined,
      cache: new Map<string, IDocComercialArtigo>()
    };
    this.paginationMinimumPerPage = MINIMUM_PER_PAGE;
    this.paginationLinhas = new Pagination({allowedPageSizes: DEFAULT_ALLOWED_PAGE_SIZES, perPage: DEFAULT_PER_PAGE});
    this._servicosDocFas = this._entityServiceBuilder.build<IJsonDocfa, IDocFasEntityService>(ENTITY_NAME_DOC_FAS);
    this._servicosDocfanums = this._entityServiceBuilder.build<IJsonDocfaNum, IEntityService<IJsonDocfaNum>>(ENTITY_NAME_DOCFAS_NUMS);
    this._serviceMoedas = this._entityServiceBuilder.build<IJsonMoeda, IMoedaEntityService>(ENTITY_NAME_MOEDA);
    this._serviceArtigos = this._entityServiceBuilder.build<IJsonArtigo, IArtigosEntityService>(ENTITY_NAME_ARTIGOS);
    this._serviceArCli = this._entityServiceBuilder.build<IJsonArCli, IEntityService<IJsonArCli>>(ENTITY_NAME_ARCLI);
    this._serviceClifo = this._entityServiceBuilder.build<IJsonClifo, IClifosEntityService>(ENTITY_NAME_CLIFOS);
    this._serviceCondPagamento = this._entityServiceBuilder.build<IJsonCondicaoPagamento>(ENTITY_NAME_CONDICOES_PAGAMENTOS);
    this.hasShownEFaturaCirculacaoModal = false;
    this.showInfoStockArtigo = false;
    this.initting = true;
    this._firstEntryOptions = true;
    this._authService.identity().then((session: TUserSession) => {
      this.comercialAdvanced = hasAuthority(session, ROLE.ERPADVANCED);
    });
    this.licencaModoCGOn = this._configService.configurations.licenca.modoCGOn;
    this.portalName = this._translateService.instant('portals.items.erp')?.toLowerCase();
    this._configSiteService.cgStoreUrlBackOffice().then((url) => {
      this.cgStoreUrlBackOffice = url;
    });
    this.isEntregaEfetivaSobreDocGlobal = false;
    this.showMessageMoralError = false;
    this.isShowMoedaCambio = false;
    this._adiantamentosService.adiantamentosConfig().then((response: HttpResponse<IJsonAdiantamentoConfig>) => {
      this._adiantamentoConfig = response.body;
    });
    this._subscriptionIsMobile = this._plDocumentService.isMobile().subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
    });
  }

  public ngOnInit(): void {
    this.isDocumentoTerminado = this.type !== EEntityStateDetailType.NEW && !this._docComercialEmEdicao(this.model?.cab);
    this._docsComerciaisService = this._entityServiceBuilder.build<IDocComercial, IDocsComerciaisEntityService>(this.entityName);
    this.statesDetailName = isEmpty(this.statesDetailName) ? entityStateNameDetail(this.entityName) : this.statesDetailName;
    this.editing = this.type === EEntityStateDetailType.EDIT;
    this.mostraEditCliente = false;
    this.mainTabCargaEnabled = false;
    this.activeMainTab = this.mainTabLinhasId;
    this.clifoDescription = this._plTranslateService.translate('docscomerciais.doc.clifo.nContribuint');
    this.temAutoFaturacao = this.group.properties?.autofaturacao;
    this._horaInicTran = moment().add(DEFAULT_MIN_INIC_TRANSP, 'minutes');
    this._buildFilter();

    this.codMoedaEmpresa = this._configService.configurations.empresa.codMoeda;
    this.abreviaturaMoedaEmpresa = this._configService.configurations.empresa.abreviaturaMoeda;
    if (isEmptyObject(this.model) || !isDefinedNotNull(this.model)) {
      this._setDefaultTipoDoc();
    } else {
      // Tipo de documento
      if (this.model.cab.nDocFa) {
        this._servicosDocFas.get({id: this.model.cab.nDocFa, reportExceptions: false}).then((response: HttpResponse<IJsonDocfa>) => {
          // sucesso
          this.docFa = response.body;
          this.model.cab.identificaCarga = this.docFa.identificaCarga;
          this.model.cab.tipoMovimentoStock = this.docFa.tipoMovimentoStock;
          this.model.cab.integraStocks = this.docFa.integraStocks;
          this._obrigatorioMeiosPagam = this.docFa.obrigatorioMeiosPagam;
          this.mainTabCargaEnabled = this.docFa.identificaCarga;
          this.evtSetActionEnabled.emit({id: ACTION_MEIOS_PAG_ORDER, validator: this._obrigatorioMeiosPagam});
          this.evtSetActionEnabled.emit({
            id: MENU_ITEM_DEVOLUCOES,
            validator: this.model.cab.isDevolucao && this.model.clifo.nConta !== '',
            visible: this.model.cab.isDevolucao
          });
        });
      }
      // Moeda
      if (this.model.cab.codMoeda) {
        this._getMoeda(this.model.cab.codMoeda);
      }
      this._initNewDocFromCopia(this.model.cab);
      this.model.clifo.action = this._evaluateClifoAction(this.model.clifo);
    }

    this.isEntradaDiversa = this._grupoDoc.isEntradaDiversa(this.group.grupo);
    this.isSaidaDiversa = this._grupoDoc.isSaidaDiversa(this.group.grupo);
    this.isTipoDocStocks = this.isEntradaDiversa || this.isSaidaDiversa;
    this.isComprasEfectivas = this.group.grupo === EGrupoDoc.ComprasEfectivas;

    this._subscription = this._documentosService.getOptions().subscribe((configOptions: TConfigOptions<boolean, IDocsComerciaisEditConfigOptions>) => {
      this.optionShowMoeda = configOptions.get('showMoeda').value;
      this.optionShowObservacoes = configOptions.get('showObservacoes').value;
      this.optionShowDocExterno = !this.isComprasEfectivas ? configOptions.get('showDocExterno').value : true;
      this.optionShowDataDoc = !this.isComprasEfectivas ? configOptions.get('showDataDoc').value : true;
      this.optionShowDataVenc = configOptions.get('showDataVenc').value;
      this.optionShowCCusto = configOptions.get('showCCusto').value || this._configService.configurations.empresa.tipoAnalitica === ETipoAnalitica.Usa;
      this.optionShowNRefProcesso = configOptions.get('showNRefProcesso').value;
      this.optionShowNArmazem = configOptions.get('showNArmazem').value;
      this.optionShowNVendedor = configOptions.get('showNVendedor').value;
      this.optionShowCodUnidMov = configOptions.get('showCodUnidMov').value;
      this.optionShowValorCIva = configOptions.get('showValorCIva').value;
      this.optionShowNNumer = configOptions.get('showNNumer').value;
      this.optionShowNRequisicao = configOptions.get('showNRequisicao').value;
      this.optionShowLiquido = configOptions.get('showLiquido').value;
      this.optionShowD2 = configOptions.get('showD2').value;
      this.optionShowD3 = configOptions.get('showD3').value;
      this.optionShowDescEmValor = configOptions.get('showDescEmValor').value;

      this.optionShowDiferimentos = configOptions.get('showDiferimentos').value;
      if (this.optionShowDiferimentos && !this._firstEntryOptions) {
        if (!this.model.cab?.integraContabilidade || (this.model.cab.grupoDocfa !== EGrupoDoc.VendasEfectivas && this.model.cab.grupoDocfa !== EGrupoDoc.ComprasEfectivas)) {
          this._documentosService.setOption('showDiferimentos', false);
        }
      }
      this._firstEntryOptions = false;
      this.isShowMoedaCambio = this.optionShowMoeda && this.model.cab.codMoeda !== this.codMoedaEmpresa;

      const optionShowPerguntaSeFinalizaDoc = configOptions.get('showPerguntaSeFinalizaDoc').value;
      this.naoPerguntaSeFinalizaDoc = !optionShowPerguntaSeFinalizaDoc;

      this.optionObtemDadosDocDigital = configOptions.get('obtemDadosDocDigital').value;
    });

    this.isFornecedores = this.group.clifos === 'fornecedores' && !this.isEntradaDiversa;
    this.showDataEntregaPrev = this.group.grupo === EGrupoDoc.EncomendasClientes || this.group.grupo === EGrupoDoc.EncomendasFornecedores;
    this.isComprasFornecedor = this.isFornecedores && !this.temAutoFaturacao;

    const temlinhas = this.model.linhas?.length > 0;

    if (temlinhas) {
      this.paginateLinhas();
      for (const linha of this.model.linhas) {
        if (linha.textoLivre) {
          linha.showTextoLivre = true;
          linha.uid = generateUUID();
        }
      }
    }

    if (this.model.resumoIva?.length > 0) {
      this.arredManualString = this._setArredManualInfo(this.model, this._translateService);
      this.evtDropdownActionsEvaluate.emit({id: MENU_ITEM_ARRED_MANUAL_ORDER, validator: false});
      // this.dropdownActions.menu.find((item) => item.order === MENU_ITEM_ARRED_MANUAL_ORDER).disabled = false;
    }

    this.evtDropdownActionsEvaluate.emit({id: MENU_ITEM_RASTREABILIDADE_ORDER, validator: !this.model.cab?.faccbId});
    // this.dropdownActions.menu.find((item) => item.order === MENU_ITEM_RASTREABILIDADE_ORDER).disabled = !this.model.cab?.faccbId;

    this.docAssinadoNaoEncerrado = this.type === EEntityStateDetailType.EDIT && this.model && this._docComercialSujeitoAssinatura(this.model.cab) && !this.model.cab.terminado;

    this._showBtnDiferimentos();
    this.isShowConfigOptions = this._checkShowConfigOptions();

    timeout().then(() => {
      this.initting = false;
    });

    this.mainTabCargaEnabled = this.model.cab?.identificaCarga;
    if (this.type === EEntityStateDetailType.EDIT) {
      if (temlinhas) {
        this.model.linhas = this.model.linhas.map((linha: IJsonDocComercialLinha) => merge(this._emptyLine(), linha));
        this._changedLinhas();
        this.addLine();
      }
    }
    if (this.docComercialStateParams?.adiantamentos && !isEmpty(this.docComercialStateParams.adiantamentos.adiantamento.nConta)) {
      setTimeout(() => {
        this._applyAdiantamentosFromParams();
      });
    }
    this.modelChanged();
  }

  public ngOnChanges({callback, type, model, group}: SimpleChanges): void {
    if (type && !type.firstChange) {
      this.type = type.currentValue;
      this.editing = this.type !== EEntityStateDetailType.DETAIL;
    }
    if (model && !model.firstChange) {
      this.model = model.currentValue;
    }
    if (group && !group.firstChange) {
      this.group = group.currentValue;
    }
    if (callback) {
      const cb: IDocComercialCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.copiarDocumento = () => this.copiarDocumento();
        cb.importadorLinhas = () => this._importadorLinhas();
        cb.addMeioPagamento = () => this.addMeioPagamento();
        cb.calc = () => this.calc();
        cb.arredondamentoManual = () => this._arredondamentoManual();
        cb.alterarCamposEmSerie = () => this.alterarCamposEmSerie();
        cb.showRastreabilidade = () => this._showRastreabilidade();
        cb.encomendasPendentes = () => this._encomendasPendentes();
        cb.adiantRegistaDoc = () => this.adiantRegistaDoc();
        cb.regularizarAdiantamentosModal = () => this.regularizarAdiantamentosModal();
        cb.perguntaSeFinalizaDoc = () => this._perguntaSeFinalizaDoc();
        cb.obrigatorioMeiosPagamento = () => this._obrigatorioMeiosPagamento();
        cb.showModalDevolucoes = () => this._showModalDevolucoes();
        cb.afterEntitySave = (doc: IDocComercial) => {
          this._afterEntitySave(doc);
        };
      }
    }
  }

  public ngOnDestroy(): void {
    this._destroyed = false;
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
    if (this._subscriptionIsMobile) {
      this._subscriptionIsMobile.unsubscribe();
    }
  }

  public getClifoUpdateDescrition(): string {
    if (!this.model.clifo) {
      return '';
    }
    const newContaCorrente = this._translateService.instant('docscomerciais.clifo.action.newContaCorrente');
    const newContaOcasional = this._translateService.instant('docscomerciais.clifo.action.newContaOcasional');
    const contaCorrente = this._translateService.instant('docscomerciais.clifo.action.contaCorrente');
    const contaOcasional = this._translateService.instant('docscomerciais.clifo.action.contaOcasional');

    if (this.model.clifo.action === EDocsComerciaisClifoStatus.Novo) {
      return newContaCorrente;
    }
    if (this.model.clifo.action === EDocsComerciaisClifoStatus.NovoAlternativo) {
      return newContaOcasional;
    }
    if (this.model.clifo.action === EDocsComerciaisClifoStatus.ExisteAlternativo || this.model.clifo.nIdAltern > 0) {
      return contaOcasional;
    }
    if (this.model.clifo.action === EDocsComerciaisClifoStatus.Existe) {
      return contaCorrente;
    }
    if (this.model.clifo.action === EDocsComerciaisClifoStatus.ExisteNifAlternativo || this.model.clifo.nIdAltern > 0) {
      return contaOcasional;
    }
    if (this.model.clifo.action === EDocsComerciaisClifoStatus.ExisteNif) {
      return contaCorrente;
    }
    if (
      this.model.clifo.action === EDocsComerciaisClifoStatus.ExisteNifAlternativoEspecifico ||
      this.model.clifo.action === EDocsComerciaisClifoStatus.ExisteNaoValidaNif ||
      this.model.clifo.action === EDocsComerciaisClifoStatus.ExisteNaoActualizaNaoValidaNif
    ) {
      return this.model.clifo.nIdAltern > 0 ? contaOcasional : contaCorrente;
    }
    return '';
  }

  public toggleContaCorrente(event: MouseEvent): void {
    event.preventDefault();
    if (this.model.cab.fluxoMonetario === EFluxoMonetario.Credito || this.model.cab.fluxoMonetario === EFluxoMonetario.CompraCredito) {
      this.model.clifo.action = EDocsComerciaisClifoStatus.Novo;
    } else if (this.model.clifo.action === EDocsComerciaisClifoStatus.Novo) {
      this.model.clifo.action = EDocsComerciaisClifoStatus.NovoAlternativo;
    } else if (this.model.clifo.action === EDocsComerciaisClifoStatus.NovoAlternativo) {
      this.model.clifo.action = EDocsComerciaisClifoStatus.Novo;
    }
    this.calc().finally(() => {
      if (
        this.model.clifo.action === EDocsComerciaisClifoStatus.NovoAlternativo &&
        (!this.model.clifo.nContribuint || this.model.clifo.nContribuint === CG_DEFAULT_AT_NIF) &&
        !this.model.clifo.nome &&
        this._grupoDoc.isVenda(this.model.cab.grupoDocfa)
      ) {
        this.model.clifo.nome = consFinalNome;
      } else if (this.model.clifo.nome === consFinalNome) {
        this.model.clifo.nome = '';
      }

      this._setFocusNClifo();
    });
    this.modelChanged();
  }

  public async artigoChanged(artigo: IJsonArtigo, item: IDocComercialLinha): Promise<void> {
    let artigoRegularizacao = false;
    if (artigo?.nArtigo === this._adiantamentoConfig.nArtigoRegAdiantamento) {
      artigoRegularizacao = true;
      this.regularizarAdiantamentosModal(this.model);
    }

    let linhaDevolucao = false;
    if (this.model.cab.isDevolucao) {
      linhaDevolucao = true;
      if (this.model?.clifo?.nConta !== '' && this.model?.clifo?.nome !== '') {
        this._showModalDevolucoes();
      } else {
        this._plAlertService.error(
          this._translateService.instant('docscomerciais.erros.notaCreditoSemCliente', {
            clifo: this._grupoDoc.isCompra(this.model.cab.grupoDocfa) ? 'fornecedor' : 'cliente'
          })
        );

        item.nArtigo = '';
        item.nomeArtigo = '';
        this._setFocusNClifo();
      }
    }

    if (!artigoRegularizacao && !linhaDevolucao) {
      item.dirty = true;
      this._artigoFieldHighlightError(item, false);
      if (!isObject(artigo) && isString(artigo)) {
        await this._serviceArCli.query({pesquisa: `nClifoArtigo=${artigo}&nConta=${this.model.cab.nConta}`}).then(async (responseArcli: THttpQueryResponse<IJsonArCli>): Promise<void> => {
          if (responseArcli?.body?.list?.length > 0) {
            item.nArtigo = responseArcli?.body?.list[0].nArtigo;
            this._serviceArtigos
              .get({id: item.nArtigo, reportExceptions: false})
              .then((response: HttpResponse<IJsonArtigo>) => {
                if (response) {
                  artigo = response.body;
                }
              })
              .finally(() => {
                this._artigoChanged(artigo, item);
              });
            return Promise.resolve();
          }
          await this._cgModalService.showOkCancel(this._plTranslateService.translate('docscomerciais.modals.newItem'), this._plTranslateService.translate('docscomerciais.modals.createNewItem'), {
            size: 'md'
          });
          if (!this.model.cab.isDevolucao) {
            const artigosMaintenance: IEntityMaintenanceInstance = this._entityMaintenanceService.build(ENTITY_NAME_ARTIGOS);
            return artigosMaintenance.maintenanceNew({params: {nArtigo: item.nArtigo}}).then((novoArtigo: IJsonArtigo): void => {
              item.nArtigo = novoArtigo.nArtigo;
              this._artigoChanged(novoArtigo, item);
            });
          }
          return Promise.resolve();
        });
      } else if (isDefinedNotNull(item.nArtigo)) {
        this._serviceArtigos
          .getStockArtigoNosArmazens(item.nArtigo)
          .then((response: HttpResponse<IJsonArtigoStockArmazens>) => {
            if (response) {
              artigo = {...artigo, qtdTotal: response.body.qtdTotal, stockArmazens: response.body.list};
            }
          })
          .finally(() => {
            this._artigoChanged(artigo, item);
          });
      } else {
        this._updateTextosLivrosUIEditorForLine(item);
      }
    } else {
      this._updateTextosLivrosUIEditorForLine(item);
    }
  }

  public armazemChanged(armazem: IJsonArmazem, item: IDocComercialLinha): void {
    const nArmazem: number = armazem?.nArmazem;
    this.stocks.nArmazem = nArmazem;
    item.nArmazem = nArmazem;
    this.calcStock(item, false, true);
    this._changed();
  }

  public codIvaChanged(iva: IJsonIva, item: IDocComercialLinha): void {
    item.taxaIva = iva?.taxaActual;
    item.codIvaDefined = true;
    this._changed();
    this.calcStock(item, false);
  }

  public onClifoNifSelected(clifo: IJsonDocComercialClifo): void {
    this.clifoSaldoEmAberto = undefined;
    if (isObject(clifo)) {
      merge(this.model.clifo, clifo);
      if (this.model.cab.nConta === '') {
        this._clifoToCab(clifo);
      }
      setTimeout(() => {
        if (this.model.clifo.nome) {
          if (this.isComprasFornecedor) {
            this._setFocusDataDocExterno();
          } else {
            this._setFocusDataDoc();
          }
        } else if (this.model.clifo.action === EDocsComerciaisClifoStatus.NovoAlternativo && (!this.model.clifo.nContribuint || this.model.clifo.nContribuint === CG_DEFAULT_AT_NIF)) {
          this.model.clifo.nome = consFinalNome;
          if (this.isComprasFornecedor) {
            this._setFocusDataDocExterno();
          } else {
            this._setFocusDataDoc();
          }
        } else {
          this._setFocusNomeClifo();
        }
      }, timeOutFocus);

      if (clifo.nConta) {
        this._serviceClifo
          .getSaldoEmAberto(clifo.nConta)
          .then((response: HttpResponse<number>) => {
            if (response) {
              this.clifoSaldoEmAberto = response.body;
            }
          })
          .catch((error) => {
            const exception = this._cgExceptionService.get(error);
            this._logger.error(exception.message);
          });
      }
      this.evtSetActionEnabled.emit({id: MENU_ITEM_DEVOLUCOES, validator: this.model.cab.isDevolucao && clifo?.nConta !== '', visible: this.model.cab.isDevolucao});
    } else {
      setTimeout(() => {
        if (this.model.clifo.action === EDocsComerciaisClifoStatus.NovoAlternativo && (!this.model.clifo.nContribuint || this.model.clifo.nContribuint === CG_DEFAULT_AT_NIF)) {
          this.model.clifo.nome = consFinalNome;
        } else {
          this._setFocusNomeClifo();
        }
        this.evtSetActionEnabled.emit({id: MENU_ITEM_DEVOLUCOES, validator: false, visible: this.model.cab.isDevolucao});
      }, timeOutFocus);
    }
    this.modelChanged();
    this.model.linhas.forEach((line) => {
      if (line.showTextoLivre) {
        this._updateTextosLivrosUIEditorForLine(line);
      }
    });
  }

  public onDocFaChanged(docFa: IJsonDocfa): void {
    let error = false;

    if (!docFa || !isNumber(docFa.nDocFa) || !this.model.cab) {
      this._plAlertService.error(this._translateService.instant('docfas.errors.tipoDocNotExist'));
      error = true;
    }

    if (docFa.grupoDocfa !== this.group.grupo) {
      this.docFa = {...this.docFa};
      error = true;
      this._plAlertService.error(this._translateService.instant('docfas.errors.soPodeSelecionarTpDocQuePertencemAoGrupo'));
    }

    if (this.group.grupo === EGrupoDoc.ComprasEfectivas) {
      if (this.group.properties?.autofaturacao ? docFa.autoFaturacao < 1 : docFa.autoFaturacao > 0) {
        this._plAlertService.error(this._translateService.instant('docfas.errors.autoFaturacaoInvalido'));
        error = true;
        return;
      }
    }

    if (error) {
      if (!this._firstErrorInDocfaChanged) {
        this._firstErrorInDocfaChanged = true;
        this._setFocusNDocfa();
      }
      return;
    }

    this._firstErrorInDocfaChanged = false;

    if (!this.docFa || this.docFa.nDocFa !== docFa.nDocFa) {
      this.docFa = docFa;
    }

    this.docfaNumFilter = `nDocFa=${docFa.nDocFa}&encerrado=0&visivelERPCloud=1`;

    this._obrigatorioMeiosPagam = docFa.obrigatorioMeiosPagam;
    this.evtSetActionEnabled.emit({id: ACTION_MEIOS_PAG_ORDER, validator: this._obrigatorioMeiosPagam});

    this.model.cab.nDocFa = docFa.nDocFa;
    this.model.cab.nNumer = this._getNNumerDefeitoDocfa(docFa.docfanumList);
    this.model.cab.docNome = docFa.nome;
    this.model.cab.identificaCarga = docFa.identificaCarga;
    this.model.cab.tipoMovimentoStock = docFa.tipoMovimentoStock;
    this.model.cab.integraStocks = docFa.integraStocks;
    this.model.cab.fluxoMonetario = docFa.fluxoMonetario;
    this.model.cab.saftTipoCodigo = docFa.codigoSAFTTipoDoc;
    this.model.cab.integraContabilidade = docFa.integraNaContabilidade;

    if (this.model.cab.grupoDocfa !== docFa.grupoDocfa || !this.model.clifo.nContribuint) {
      this.model.cab.grupoDocfa = docFa.grupoDocfa;
    }

    this.mainTabCargaEnabled = docFa.identificaCarga;

    this._emptyClifo(this.model);
    this._emptyClifoToDocCab(this.model.clifo, this.model.cab);

    if (this.temAutoFaturacao) {
      let idx = this.docFa.docfanumList.findIndex((item: IJsonDocfaNum) => item.atEstadoSerie === 1 && !item.encerrado);
      if (idx === -1 && this.docFa.docfanumList.length > 0) {
        idx = this.docFa.docfanumList.findIndex((item: IJsonDocfaNum) => !item.encerrado);
      }
      if (idx > -1) {
        this.model.cab.nNumer = this.docFa.docfanumList[idx].nNumer;
        if (this.docFa.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresComAcordo) {
          this.model.cab.nConta = this.docFa.docfanumList[idx].nConta;
          this.model.cab.nif = this.docFa.docfanumList[idx].atAfNifTerceiro;
          this.model.cab.nome = this.docFa.docfanumList[idx].nomeClifo;
          this.model.clifo.nConta = this.docFa.docfanumList[idx].nConta;
          this.model.clifo.nContribuint = this.docFa.docfanumList[idx].atAfNifTerceiro;
          this.model.clifo.nome = this.docFa.docfanumList[idx].nomeClifo;

          this.model.clifo.action = EDocsComerciaisClifoStatus.Existe;
        }
      } else {
        this.model.cab.nNumer = 1;
      }
    }

    this.clifoDescription = this._grupoDoc.isVenda(docFa.grupoDocfa) ? 'Cliente' : (this.clifoDescription = this._grupoDoc.isCompra(docFa.grupoDocfa) ? 'Fornecedor' : 'Terceiros');

    this._clearMoradasFaturacao();

    this.showInfoStockArtigo = false;

    this.evtSetActionEnabled.emit({id: MENU_ITEM_DEVOLUCOES, validator: this.model.cab.isDevolucao && this.model.clifo.nConta !== '', visible: this.model.cab.isDevolucao});

    if (!this.temAutoFaturacao || (this.temAutoFaturacao && this.model.cab.nConta && this.model.cab.nif)) {
      this._changed(true);
    }
    this._showBtnDiferimentos();
    this.isShowConfigOptions = this._checkShowConfigOptions();
    this.isEntregaEfetivaSobreDocGlobal = this._checkIsEntregaEfetivaSobreDocGlobal();
    this.modelChanged();
  }

  public onNNumerChanged(docfaNum: IJsonDocfaNum): void {
    if (!docfaNum || isUndefined(docfaNum.nNumer) || !this.model.cab) {
      this._plAlertService.error(this._translateService.instant('docfas.errors.tipoDocNotExist'));
      this._setFocusNDocfa();
      return;
    }

    if (!this.docfaNum || this.docfaNum.nDocfa !== docfaNum.nDocfa || this.docfaNum.nNumer !== docfaNum.nNumer) {
      this.docfaNum = docfaNum;
    }

    this.model.cab.nNumer = docfaNum.nNumer;

    if (this.temAutoFaturacao && this.docFa.nDocFa === docfaNum.nDocfa && this.docFa.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresComAcordo) {
      this._serviceClifo.get({id: docfaNum.nConta}).then((response: HttpResponse<IClifo>) => {
        if (response) {
          this._onClifoNifValidate(this.model.clifo.nContribuint, response.body);
        }
      });
    }

    this.modelChanged();
  }

  public onCodMoedaChanged(moeda: IJsonMoeda): void {
    if (!moeda || isUndefined(moeda.codMoeda) || !this.model.cab) {
      this._plAlertService.error(this._translateService.instant('moeda.errors.moedaNaoExiste'));
      this._setFocusMoeda();
      return;
    }

    if (this.model.linhas.length > 0 && this.model.linhas[0].nArtigo && moeda.codMoeda !== this.model.cab.codMoeda) {
      this._plAlertService.error(this._translateService.instant('moeda.errors.naoPodeAlterarMoedaSemRemoverLinhas'));
      this._setFocusMoeda();
      return;
    }

    if (!this.moeda || this.moeda.codMoeda !== moeda.codMoeda) {
      this.moeda = moeda;
    }

    this.model.cab.codMoeda = moeda.codMoeda;
    this.model.cab.nomeMoeda = moeda.nomeMoeda;
    this.model.cab.abreviaturaMoeda = moeda.abreviaturaMoeda;
    this.model.cab.cambio = 0;

    if (this.abreviaturaMoedaCliente !== moeda.abreviaturaMoeda) {
      this.abreviaturaMoedaCliente = moeda.abreviaturaMoeda;
    }

    this._changed();
    this.isShowMoedaCambio = this.optionShowMoeda && this.model.cab.codMoeda !== this.codMoedaEmpresa;
  }

  public onCambioChanged(value: number): void {
    this.model.cab.cambio = value;
    this._changed();
  }

  public onMoralChanged(moral: IJsonMorAl): void {
    if (!moral?.nConta || this.model.clifo.nConta !== moral.nConta) {
      return;
    }
    this.model.moradaDescarga.nConta = moral.nConta;
    this.model.moradaDescarga.nome = moral.nome;
    this.model.moradaDescarga.rua = moral.rua;
    this.model.moradaDescarga.codPostal = moral.cPost;
    this.model.moradaDescarga.localidade = moral.localidade;
    this.model.moradaDescarga.nipc = moral.nipc;
    this.model.moradaDescarga.id = moral.id;
    this.model.moradaDescarga.observacoes = moral.observacoes;
    this.model.moradaDescarga.morada = moral.morada;
    this.model.moradaDescarga.codPais = moral.codPais;
    this.model.moradaDescarga.nomePais = moral.nomePais;
    this.model.moradaDescarga.moralId = moral.moralID;
    this.model.moradaDescarga.nIdAltern = moral.nIdAltern;

    this.modelChanged();
  }

  public onCodPostalChange(codPostal: IJsonCodPostal | string): void {
    if (!isObject(codPostal)) {
      codPostal = {
        codPostal: <string>codPostal,
        localidade: '',
        codPais: 0,
        nomePais: ''
      };
    }
    this._onCodPostalValidate(<IJsonCodPostal>codPostal);
  }

  public onDataDocChange(value: TDate): void {
    const dataDocOld = this.model.cab.dataDoc;
    this.model.cab.dataDoc = value ? moment(value) : undefined;

    if (this.isComprasEfectivas) {
      this.model.cab.dataDocExterno = this.model.cab.dataDoc;
      this._changed(true);
    } else {
      const isSameDate: boolean = this._isSameDate(dataDocOld, this.model.cab.dataDocExterno);
      if (isSameDate) {
        this.model.cab.dataDocExterno = this.model.cab.dataDoc;
      }
      this._changed(isSameDate);
    }
  }

  public onDataDocExternoChange(value: TDate): void {
    const dateValue = value ? moment(value) : undefined;
    if (!this._isSameDate(this.model.cab.dataDocExterno, dateValue)) {
      this.model.cab.dataDocExterno = dateValue;
      this._changed(true);
    }
  }

  public isClifoNew(): boolean {
    return this.model?.clifo && (!this.model.clifo.action || this.model.clifo.action === EDocsComerciaisClifoStatus.Novo || this.model.clifo.action === EDocsComerciaisClifoStatus.NovoAlternativo);
  }

  public isClifoUpdate(): boolean {
    return (
      this.model?.clifo?.nContribuint &&
      this.model.clifo.nContribuint !== CG_DEFAULT_AT_NIF &&
      (this.model.clifo.action === EDocsComerciaisClifoStatus.Existe ||
        this.model.clifo.action === EDocsComerciaisClifoStatus.ExisteAlternativo ||
        this.model.clifo.action === EDocsComerciaisClifoStatus.ExisteNif ||
        this.model.clifo.action === EDocsComerciaisClifoStatus.ExisteNifAlternativo ||
        this.model.clifo.action === EDocsComerciaisClifoStatus.ExisteNifAlternativoEspecifico ||
        this.model.clifo.action === EDocsComerciaisClifoStatus.ExisteNaoValidaNif ||
        this.model.clifo.action === EDocsComerciaisClifoStatus.ExisteNaoActualizaNaoValidaNif)
    );
  }

  public calc(): Promise<void> {
    if (!this.promiseCalc) {
      this.promiseCalc = (async () => {
        this._horaInicTran = moment(this.model.cab.horaInicTran);
        const doc: IDocComercial = copy(this.model);
        doc.linhas = [];
        this.model.linhas.forEach((item: IDocComercialLinha) => {
          if (item.dirty) {
            item.dirty = false;
            item.prVenda1 = 0;
            item.codIva = 0;
            item.taxaIva = 0;
            item.nCCusto = '';
            item.nrefprocesso = '';
            item.nVendedor = undefined;
            item.codUnidMov = 0;
            item.liquido = 0;
            item.d1 = 0;
            item.d2 = 0;
            item.d3 = 0;
            item.desconto = 0;
            item.codIvaDefined = false;
            item.lotes = [];
            item.prVenda1Defined = false;
          }
          doc.linhas.push(item);
        });

        if (!this._modalResult && this.model.cab.grupoDocfa === EGrupoDoc.GuiasTransporteRemessa && !this.hasShownEFaturaCirculacaoModal) {
          const resultFaturaCirculacao: HttpResponse<IJsonEFaturaCirculacaoLogin> = await this._eFaturaCirculacaoLoginService.get();
          if (!resultFaturaCirculacao?.body.username || !resultFaturaCirculacao?.body.password) {
            this._modalResult = await this._callEFaturacaoCirculacaoLogin();
          }
        }

        let meiosPagamento = [];
        if (doc.meiosPagamento && doc.meiosPagamento.length > 0) {
          meiosPagamento = copy(doc.meiosPagamento);
        }

        // Calcula documento no servidor
        let dataResponse: HttpResponse<IDocComercial> | undefined;

        try {
          dataResponse = await this._documentosService.patchDoc(doc);
        } catch (reason: unknown) {
          this._logger.error(reason);
          if (reason instanceof HttpErrorResponse) {
            const exception: ICGExceptionError = this._cgExceptionService.get(reason);
            if (exception.fields.length) {
              const field: ICGExceptionErrorField = exception.fields.find((errorField: ICGExceptionErrorField) => errorField.fieldname === 'DATADOC');
              if (field) {
                this.dataDocAlertErrorsMsg = field.message;
              }
            }
          }
        }

        if (dataResponse) {
          const data = dataResponse.body;

          this.model.clifo.info = data.clifo.info;
          this.model.clifo.action = data.clifo.action;
          this.model.clifo.textofaturacao = data.clifo.textofaturacao;

          // update pelo nif
          if (data.clifo.action === EDocsComerciaisClifoStatus.ExisteNif || data.clifo.action === EDocsComerciaisClifoStatus.ExisteNifAlternativo) {
            this.model.clifo = data.clifo;
          }

          this._clifoToCab(this.model.clifo, false);

          this.model.cab = merge({}, this.model.cab, data.cab);
          this.model.cab.horaInicTran = this._horaInicTran;
          merge(this.model.moradaCarga, data.moradaCarga);
          merge(this.model.moradaDescarga, data.moradaDescarga);
          merge(this.model.transportador, data.transportador);
          this.model.meiosPagamento = [];
          if (meiosPagamento.length > 0) {
            meiosPagamento.forEach((meio: IJsonDocComercialMeioPagam) => {
              this.model.meiosPagamento.push(meio);
            });
          } else {
            data.meiosPagamento.forEach((meio: IJsonDocComercialMeioPagam) => {
              this.model.meiosPagamento.push(meio);
            });
          }
          this.model.resumoIva = [];
          data.resumoIva.forEach((resumoIvaItem: IJsonDocResumoIvaItem) => {
            this.model.resumoIva.push(resumoIvaItem);
          });
          this.arredManualString = this._setArredManualInfo(this.model, this._translateService);
          this.evtDropdownActionsEvaluate.emit({id: MENU_ITEM_ARRED_MANUAL_ORDER, validator: this.model.resumoIva?.length === 0});
          // this.dropdownActions.menu.find((item) => item.order === MENU_ITEM_ARRED_MANUAL_ORDER).disabled = this.model.resumoIva?.length === 0;

          data.linhas = orderBy(data.linhas, 'nSeq', 'asc');
          for (let i = 0; i < this.model.linhas.length; i++) {
            this.model.linhas[i].lotes = [];
            merge(this.model.linhas[i], data.linhas[i]);
          }
          this.dataDocAlertErrorsMsg = '';
        }
      })().finally(() => {
        this.promiseCalc = undefined;
        this.modelChanged();
      });
    }
    return this.promiseCalc;
  }

  public copiarDocumento(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DocumentoPesqDocsModalComponent);
    const componentInstance: DocumentoPesqDocsModalComponent = modalInstance.componentInstance;
    componentInstance.doc = copy(this.model);
    componentInstance.docStateType = this.type;
    return modalInstance.result
      .then((result) => {
        this.model = copy(result);
        this._changedLinhas();
        if (this.model.cab.nDocFa) {
          this._servicosDocFas.get({id: this.model.cab.nDocFa, reportExceptions: false}).then((response: HttpResponse<IJsonDocfa>) => {
            // sucesso
            this.docFa = response.body;
          });
        }
        this._changed();
        this.addLine();
        this._setFocusNArtigo();
      })
      .catch(() => {
        this._setFocusNDocfa();
      });
  }

  public alterarCamposEmSerie(): Promise<void> {
    const instance = this._cgModalService.showVanilla(AlterarCamposSerieModalComponent, {size: 'md'});
    instance.componentInstance.linhaDoc = this.model.linhas[0];
    return instance.result.then((result: IDocComercialLinhaSerie) => {
      for (const linha of this.model.linhas) {
        if (!linha.nArtigo) {
          continue;
        }
        if (result.nArmazemSelected) {
          linha.nArmazem = result.nArmazem;
          this.calcStock(linha);
        }
        if (result.nCCustoSelected) {
          linha.nCCusto = result.nCCusto;
        }
        if (result.nrefprocessoSelected) {
          linha.nrefprocesso = result.nrefprocesso;
        }
        if (result.nVendedorSelected) {
          linha.nVendedor = result.nVendedor;
        }
        if (result.d1Selected) {
          linha.d1 = result.d1;
        }
        if (result.codIvaSelected) {
          linha.codIva = result.codIva;
          linha.taxaIva = result.taxaIva;
          linha.codIvaDefined = true;
        }
      }
      return this.calc();
    });
  }

  public getpdf(): Promise<void> | void {
    return this._documentosService.getPdf(this.lastDoc, this.lastDoc.cab.nDocumento === 0 || !this.lastDoc.cab.terminado);
  }

  public addLine(onClickButton: boolean = false): void {
    if (!this._docComercialEmEdicao(this.model.cab)) {
      return;
    }

    if (onClickButton && this.model?.cab?.isDevolucao && this.model?.clifo?.nConta !== '' && this.model?.clifo?.nome !== '') {
      this._showModalDevolucoes();
      return;
    }

    if (this.model.linhas.length > 0 && !this.model.linhas[this.model.linhas.length - 1].nArtigo) {
      return;
    }

    this.model.linhas.push(this._emptyLine());

    this.showInfoStockArtigo = false;
    this.calcStock(null, false);
    this.paginateLinhas();

    // this.dropdownActions.menu.find((item) => item.order === MENU_ALT_CAMPOS_SERIE_ORDER).disabled = this.model.linhas.length < 2;
    this.evtDropdownActionsEvaluate.emit({id: MENU_ALT_CAMPOS_SERIE_ORDER, validator: this.model.linhas.length < 2});
    this.modelChanged();
  }

  public addLineAdiantamento(adiantamento: IAdiantamentosRegisto, config: IJsonAdiantamentoConfig): void {
    if (!this._docComercialEmEdicao(this.model.cab)) {
      return;
    }

    const line: IDocComercialLinha = {
      ...this._emptyLine(),
      nArtigo: config.nArtigoAdiantamento,
      nomeArtigo: config.nomeNArtigoAdiantamento,
      codIva: adiantamento.codIva,
      taxaIva: adiantamento.taxaIva,
      prVenda1: adiantamento.valorSemIva,
      valorIvaME: adiantamento.valorComIva
    };

    const lastItemIndex = this.model?.linhas?.length - 1;
    if (lastItemIndex === 0 && isEmpty(this.model?.linhas[lastItemIndex]?.nArtigo)) {
      this.model.linhas = [line];
    } else if (isEmpty(this.model?.linhas[lastItemIndex]?.nArtigo)) {
      this.model.linhas.splice(lastItemIndex, 0, line);
    } else {
      this.model.linhas.push(line);
    }

    this._evaluateNewLinesAdded();
  }

  public openMovAberto(): void {
    const modalRef = this._cgModalService.showVanilla(MovimentosAbertoModalComponent);
    const componentInstance: MovimentosAbertoModalComponent = modalRef.componentInstance;
    componentInstance.nConta = this.model.clifo.nConta;
    componentInstance.nomeConta = this.model.clifo.nome;
    componentInstance.showNconta = false;
    componentInstance.tipo = this.isFornecedores ? 'fornecedor' : 'cliente';
  }

  public openExtratoConta(): void {
    const modalRef = this._cgModalService.showVanilla(ExtratosDTModalComponent);
    const componentInstance: ExtratosDTModalComponent = modalRef.componentInstance;
    componentInstance.tipo = this.isFornecedores ? 'fornecedor' : 'cliente';
    componentInstance.params = {nConta: this.model.clifo.nConta, nomeConta: this.model.clifo.nome};
    componentInstance.readonlyConta = true;
  }

  public checkLine(event: KeyboardEvent, next: string): boolean {
    if ((event.key !== KEYCODES.DOWN && event.key !== KEYCODES.UP) || !next) {
      return false;
    }
    const nextElement: HTMLElement = document.querySelector<HTMLElement>(next);
    const rowIndex: number = elementIndex(nextElement.closest('tr'));

    if (rowIndex === -1) {
      return false;
    }

    const linha: IDocComercialLinha = this.model.linhas[rowIndex];

    this.calcStock(linha, false);

    if (rowIndex !== this.model.linhas.length - 1) {
      return false;
    }

    if (!isEmpty(linha.nArtigo)) {
      return false;
    }

    if (event.key === KEYCODES.UP) {
      return false;
    }

    this._setFocusNArtigo(rowIndex + 1);
    return true;
  }

  public remove(index: number): void {
    if (!this._docComercialEmEdicao(this.model.cab)) {
      return;
    }

    if (this.model?.cab?.isDevolucao) {
      this.model.linhas.splice(index, 1);
    } else {
      this.model.linhas.splice(index, 1);
      if (!this.model.linhas.length) {
        this.addLine();
      } else {
        this.paginateLinhas();
      }
    }
    this.evtDropdownActionsEvaluate.emit({id: MENU_ALT_CAMPOS_SERIE_ORDER, validator: this.model.linhas.length < 2});

    this._changed();
  }

  public onQdt1Changed(value: number, item: IDocComercialLinha): void {
    const artigo: IDocComercialArtigo = this.stocks.cache.get(item.nArtigo);
    item.qtd1 = value;
    if (
      !this.model?.cab?.integraStocks ||
      !artigo?.temLote ||
      (artigo?.codvaloriz !== 1 && (artigo?.codvaloriz !== FIFO || (!this._grupoDoc.isCompra(this.group.grupo) && !this._grupoDoc.isEntradaDiversa(this.group.grupo))))
    ) {
      this._changed();
      this.calcStock(item, true, true);
    }
  }

  public editarDevolucao(facliId: number): Promise<void> {
    return this._showModalDevolucoes(facliId);
  }

  public onPrVenda1Changed(value: number, item: IDocComercialLinha): void {
    item.prVenda1 = value;
    item.prVenda1Defined = true;
    this._changed();
    this.calcStock(item, false);
  }

  public onD1Changed(value: number, item: IDocComercialLinha): void {
    item.d1 = value;
    this._changed();
    this.calcStock(item, false);
  }

  public onD2Changed(value: number, item: IDocComercialLinha): void {
    item.d2 = value;
    this._changed();
    this.calcStock(item, false);
  }

  public onD3Changed(value: number, item: IDocComercialLinha): void {
    item.d3 = value;
    this._changed();
    this.calcStock(item, false);
  }

  public onDescontoChanged(value: number, item: IDocComercialLinha): void {
    item.desconto = value;
    this._changed();
    this.calcStock(item, false);
  }

  public storeModel(): void {
    this._documentosService.storeDocument(this.entityName, this.model);
    this.modelChanged();
  }

  public onClifoRuaChanged(value: string): void {
    this.model.clifo.rua = value;
    this.storeModel();
  }

  public onClifoCodPostalChanged(value: string): void {
    this.model.clifo.codPostal = value;
    this.storeModel();
  }

  public onClifoLocalidadeChanged(value: string): void {
    this.model.clifo.localidade = value;
    this.storeModel();
  }

  public toggleTextoLivre(item: IDocComercialLinha, index: number, event: MouseEvent | KeyboardEvent): void {
    if (this.model.cab.comunicadoAoEFatura) {
      return;
    }

    const key: string = (<KeyboardEvent>event).key;
    if (!key || key === KEYCODES.ENTER) {
      item.showTextoLivre = !item.showTextoLivre;
      if (item.showTextoLivre) {
        event.stopPropagation();
        this._updateTextosLivrosUIEditorForLine(item);
        setTimeout(() => {
          const element: HTMLInputElement = this._element.querySelector<HTMLInputElement>(`pl-edit-textarea[id="${INPUT_NAME_DESCRICAO}${index}"] textarea`);
          focusElement(element);
        });
      }
    }
  }

  public calcStock(item: IDocComercialLinha, doCals: boolean = true, forceUpdateCache: boolean = false): void {
    const showStock = (artigo: IJsonArtigo): void => {
      let qtd = 0;
      let qtdArmazem = 0;
      let qtdTotalArmazem = 0;
      let qtdTotal = 0;
      let qtdTotalAux = 0;

      this.showInfoStockArtigo = artigo?.movimStock && this.docFa?.integraStocks;

      for (const linha of this.model.linhas) {
        if (linha.nArtigo === item.nArtigo) {
          qtd += linha.qtd1 * artigo.qtdPorUnid1;
        }
      }

      if (!artigo.stockArmazens) {
        return;
      }

      for (const linha of this.model.linhas) {
        if (linha.nArtigo === item.nArtigo && linha.nArmazem === item.nArmazem) {
          qtdTotalArmazem += linha.qtd1 * artigo.qtdPorUnid1;
        }
      }

      for (const stockArmazem of artigo.stockArmazens) {
        if (stockArmazem.nArmazem === item.nArmazem) {
          qtdArmazem = stockArmazem.qtd;
        } else {
          qtdTotal += stockArmazem.qtd;
        }
      }

      if (this.model.cab.integraStocks && this.docFa) {
        if (doCals) {
          const sinalStock = this.docFa.tipoMovimentoStock === 0 ? 1 : -1;
          qtdTotalAux = qtdArmazem - qtd * sinalStock;
          this.stocks.qtd = qtdArmazem - qtdTotalArmazem * sinalStock;
        } else {
          this.stocks.qtd = qtdArmazem;
          qtdTotalAux = qtdArmazem;
        }
      } else {
        qtdTotalAux = qtdArmazem;
        this.stocks.qtd = qtdTotalArmazem;
      }
      this.stocks.qtdTotal = qtdTotal + qtdTotalAux;
    };

    this.stocks.nArmazem = item?.nArmazem ? item?.nArmazem : 0;
    this.stocks.qtd = 0;
    this.stocks.qtdTotal = 0;
    this.stocks.item = item;
    if (!item) {
      return;
    }

    this._artigoFieldHighlightError(item, false);
    const artigo: IDocComercialArtigo = this.stocks.cache.get(item.nArtigo);
    if (!forceUpdateCache && artigo && moment().diff(artigo._timestamp, 'seconds') <= CACHE_VALIDITY_SECONDS) {
      showStock(artigo);
    } else if (item.nArtigo && !this._entArtigosPromise) {
      (this.promiseCalc || Promise.resolve()).finally(() => {
        this._entArtigosPromise = this._serviceArtigos
          .get({id: item.nArtigo, reportExceptions: false})
          .then((response: HttpResponse<IDocComercialArtigo>) => {
            response.body._timestamp = moment();
            this.stocks.cache.set(response.body.nArtigo, response.body);
            this.showInfoStockArtigo = response.body?.movimStock && this.docFa?.integraStocks;
            const stockArmazen = response.body.stockArmazens.find((a) => a.nArmazem === item.nArmazem);
            this.stocks.qtd = stockArmazen ? stockArmazen.qtd : 0;
            this.stocks.qtdTotal = response.body.qtdTotal;
          })
          .catch((reason: HttpErrorResponse) => {
            if (reason.status === EStatusCode.NotFound) {
              this.showInfoStockArtigo = false;
              this._artigoFieldHighlightError(item, true);
            }
          })
          .finally(() => {
            this._entArtigosPromise = undefined;
          });
      });
    }

    this.modelChanged();
  }

  public trackByFn(index: number): number {
    return index;
  }

  public changedTab(tabId: string): void {
    if (tabId === this.mainTabCargaId) {
      this._setFocusDataInicTran();
    } else if (tabId === this.mainTabFaturacaoPublicaId) {
      this._setFocusCtRefExterna();
    } else if (tabId === this.mainTabAnexosId) {
      if (this.type === EEntityStateDetailType.EDIT) {
        this._getGDocFolder(this._generateNDoc(this.model.cab), EFolderDoc.DefaultFolderGestCom);
      }
    }
  }

  public openDiferimentos(): Promise<void> {
    if (!this._maintenanceCalculoDiferimentos) {
      this._maintenanceCalculoDiferimentos = this._moduleMaintenanceService.build<IDiferimentosSaved>(MODULE_NAME_CALCULO_DIFERIMENTOS);
    }
    this.model.cab.dataIniDiferimento = !this.model.cab.integraContabilidade ? moment() : this.model.cab.dataVenc;
    this.model.cab.dataFimDiferimento = !this.model.cab.integraContabilidade ? moment().add(1, 'year') : moment(this.model.cab.dataVenc).add(1, 'year');

    return this._maintenanceCalculoDiferimentos
      .maintenance({
        params: {
          fromComercial: true,
          dataIniDiferimento: this.model.cab.dataIniDiferimento,
          dataFimDiferimento: this.model.cab.dataFimDiferimento,
          valor: this.model.cab.totalIliquido
        },
        closeOnSaved: true
      })
      .then((savedDiferimentosComercial: IDiferimentosSaved) => {
        this.model.cab.dataIniDiferimento = savedDiferimentosComercial.dataIniDiferimento;
        this.model.cab.dataFimDiferimento = savedDiferimentosComercial.dataFimDiferimento;
        this.modelChanged();
      });
  }

  public addMeioPagamento(): Promise<void> {
    if (this.model.linhas.length === 0 || this.model.cab.totalDocumentoME === 0) {
      this._plAlertService.error(this._translateService.instant('docscomerciais.erros.docSemValorLinhasMP'));
      return Promise.resolve();
    }

    const modalInstance = this._cgModalService.showVanilla(DocumentoMeiosPagamentoModalComponent, {size: 'lg'});
    const componentInstance: DocumentoMeiosPagamentoModalComponent = modalInstance.componentInstance;
    componentInstance.doc = copy(this.model);
    componentInstance.codMoeda = this.codMoedaEmpresa;
    componentInstance.abreviaturaMoeda = this.abreviaturaMoedaEmpresa;
    componentInstance.emEdicao = true;
    return modalInstance.result
      .then((result) => {
        this.model = result;
        this._changedLinhas();
        if (this.model.cab.nDocFa) {
          this._servicosDocFas.get({id: this.model.cab.nDocFa, reportExceptions: false}).then((response: HttpResponse<IJsonDocfa>) => {
            // sucesso
            this.docFa = response.body;
          });
        }
        this._setFocusNArtigo();
      })
      .catch(() => {
        this._setFocusNDocfa();
      });
  }

  public paginateLinhas(): void {
    this.paginatedLinhas = this.model.linhas.length > this.paginationMinimumPerPage ? this.paginationLinhas.paginate(this.model.linhas) : this.model.linhas;
  }

  public showPesqDocsGlobaisModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DocumentoPesqDocsGlobaisModalComponent);
    const componentInstance: DocumentoPesqDocsGlobaisModalComponent = modalInstance.componentInstance;
    componentInstance.doc = copy(this.model);
    return modalInstance.result.then((result: IJsonDocComercialCab) => {
      this.model.cab.nDocGlobalOrigemNDoc = result.nDoc;
      this.model.cab.nDocGlobalOrigemFaccbId = result.faccbId;
      setTimeout(() => {
        this._setFocusNArtigo();
      }, timeOutFocus);
      this.modelChanged();
    });
  }

  public obterUltimoDocumentoGlobalCriado(): Promise<void> {
    return this._documentosService.obterUltimoDocumentoGlobalCriado().then((response) => {
      if (response.body.faccbId > 0) {
        this.model.cab.nDocGlobalOrigemNDoc = response.body.nDoc;
        this.model.cab.nDocGlobalOrigemFaccbId = response.body.faccbId;
        setTimeout(() => {
          this._setFocusNArtigo();
        }, timeOutFocus);
        this.modelChanged();
      } else {
        setTimeout(() => {
          this._setFocusBtnPesqDocsGlobais();
        }, timeOutFocus);
      }
    });
  }

  public atualizarCambio(): Promise<void> {
    const data: IJsonMoedaUpdateCambio = {
      codMoeda: this.model.cab.codMoeda,
      dataReferencia: this.model.cab.dataDoc,
      nDecimaisCambio1: CAMBIO_MOEDA_NDECIMAIS,
      nDecimaisCambio2: CAMBIO_MOEDA_NDECIMAIS
    };
    return this._serviceMoedas.updateCambio(-1, data, true).then((response) => {
      this.onCambioChanged(response.body.cambioMoedEstrEmEmp);
    });
  }

  public clearDocumentoGlobal(): void {
    this.model.cab.nDocGlobalOrigemFaccbId = -1;
    this.model.cab.nDocGlobalOrigemNDoc = '';
    this.modelChanged();
  }

  public async regularizarAdiantamentosModal(doc: IDocComercial = this.model): Promise<void> {
    if (!isEmpty(this._adiantamentoConfig?.nArtigoAdiantamento)) {
      let docHasAdiant = false;
      await this._adiantamentosService.validaLinhasDocComercial(doc.linhas, false).then((response: HttpResponse<IJsonBoolean>) => {
        docHasAdiant = response.body.value;
      });

      if (docHasAdiant) {
        this._plAlertService.error('adiantamentos.messages.docHasAdiant');
        return Promise.resolve();
      }
    }

    if (!this.model.cab.nConta) {
      this._plAlertService.error(
        this._translateService.instant('adiantamentos.messages.clifoNaoSel', {
          clifo: this._grupoDoc.isCompra(this.model.cab.grupoDocfa) ? 'fornecedor' : 'cliente'
        })
      );
      this._setFocusNClifo();
      return Promise.resolve();
    }

    const maintenanceInstanceAdiantamentos = this._moduleMaintenanceService.build<number>(
      this.clifoEntName === ENTITY_NAME_CLIENTES ? MODULE_NAME_ADIANTAMENTOS_CLIENTES : MODULE_NAME_ADIANTAMENTOS_FORNECEDORES
    );
    const params: IAdiantamentosParams = {
      clifo: doc.cab.nConta,
      nomeClifo: doc.cab.nome,
      faccbId: doc.cab.faccbId,
      nnumer: doc.cab.nNumer,
      ndocfa: doc.cab.nDocFa,
      doc: this.type !== EEntityStateDetailType.NEW ? undefined : this.model
    };
    return maintenanceInstanceAdiantamentos.maintenance({params: params, closeOnSaved: true}).then((result: number) => {
      if (this.type !== EEntityStateDetailType.NEW) {
        return this._docsComerciaisService.get({id: this.model.cab.faccbId}).then((response: HttpResponse<IDocComercial>) => {
          this.model.linhas = response.body.linhas;
          this.calcStock(null, false);
          this.paginateLinhas();
          // this.dropdownActions.menu.find((item) => item.order === MENU_ALT_CAMPOS_SERIE_ORDER).disabled = this.model.linhas.length < 2;
          this.evtDropdownActionsEvaluate.emit({id: MENU_ALT_CAMPOS_SERIE_ORDER, validator: this.model.linhas.length < 2});
          this.calc();
          this.addLine();
        });
      }

      const docsComerciaisEntity: IDocsComerciaisEntity = this.clifoEntName === ENTITY_NAME_CLIENTES ? ENTITY_DOCS_COMERCIAIS_VENDAS : ENTITY_DOCS_COMERCIAIS_COMPRAS;
      return this._cgStateService.redirectToState({stateOrName: docsComerciaisEntity.name, stateType: EEntityStateDetailType.EDIT, params: {id: result}});
    });
  }

  public async adiantRegistaDoc(doc: IDocComercial = this.model): Promise<void> {
    if (!isEmpty(this._adiantamentoConfig?.nArtigoRegAdiantamento)) {
      let docHasRegAdiant = false;
      await this._adiantamentosService.validaLinhasDocComercial(doc.linhas, true).then((response: HttpResponse<IJsonBoolean>) => {
        docHasRegAdiant = response.body.value;
      });

      if (docHasRegAdiant) {
        this._plAlertService.error('adiantamentos.messages.docHasRegAdiant');
        return Promise.resolve();
      }
    }

    if (!this.model.cab.nConta) {
      this._plAlertService.error(
        this._translateService.instant('adiantamentos.messages.clifoNaoSelReg', {
          clifo: this._grupoDoc.isCompra(this.model.cab.grupoDocfa) ? 'fornecedor' : 'cliente'
        })
      );
      this._setFocusNClifo();
      return Promise.resolve();
    }

    const docDefeito =
      this.clifoEntName === ENTITY_NAME_CLIENTES
        ? (this._configService.configurations.documentosComerciais.tipos.vendasEfectivas.documentoPorDefeito ?? undefined)
        : (this._configService.configurations.documentosComerciais.tipos.comprasEfectivas.documentoPorDefeito ?? undefined);
    let nomeDocFa = '';
    let nNumer: number;
    if (docDefeito) {
      const docFaService: IEntityService<IJsonDocfa> = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
      await docFaService.get({id: docDefeito}).then((responseDocFa: HttpResponse<IJsonDocfa>) => {
        nomeDocFa = responseDocFa.body.nome;
        nNumer = this._getNNumerDefeitoDocfa(responseDocFa.body.docfanumList);
      });
    }

    const modalInstance = this._cgModalService.showVanilla(AdiantamentosRegistarModalComponent);
    const componentInstance: AdiantamentosRegistarModalComponent = modalInstance.componentInstance;
    componentInstance.adiantRegisto = {
      nDocFa: docDefeito ?? undefined,
      nomeDocFa: nomeDocFa,
      nNumer: nNumer ?? undefined,
      nConta: doc?.clifo?.nConta,
      nomeNConta: doc?.clifo?.nome,
      codIva: this.clifoEntName === ENTITY_NAME_CLIENTES ? this._adiantamentoConfig.codIvaVenda : this._adiantamentoConfig.codIvaCompra,
      nomeCodIva: this.clifoEntName === ENTITY_NAME_CLIENTES ? this._adiantamentoConfig.nomeCodIvaVenda : this._adiantamentoConfig.nomeCodIvaCompra,
      taxaIva: this.clifoEntName === ENTITY_NAME_CLIENTES ? this._adiantamentoConfig.taxaIvaVenda : this._adiantamentoConfig.taxaIvaCompra,
      valorSemIva: 0,
      valorComIva: 0,
      grupoDocFa: this.group.grupo,
      nDoc: doc?.cab?.nDoc,
      faccbId: doc?.cab?.faccbId
    };
    componentInstance.clifoEntName = this.clifoEntName;
    componentInstance.atualDoc = this.group.grupo === EGrupoDoc.VendasEfectivas || this.group.grupo === EGrupoDoc.ComprasEfectivas;
    componentInstance.adiantamentoConfig = this._adiantamentoConfig;
    return modalInstance.result.then((result: IAdiantamentosRegistoResult) => {
      if (result.faccbID > 0) {
        const docsComerciaisEntity: IDocsComerciaisEntity = this.clifoEntName === ENTITY_NAME_CLIENTES ? ENTITY_DOCS_COMERCIAIS_VENDAS : ENTITY_DOCS_COMERCIAIS_COMPRAS;
        return this._cgStateService.redirectToState({stateOrName: docsComerciaisEntity.name, stateType: EEntityStateDetailType.EDIT, params: {id: result.faccbID}});
      }

      this.addLineAdiantamento(result.adiantamento, result.config);
      return Promise.resolve();
    });
  }

  public dragStart(event: MouseEvent, item: IDocComercialLinha, index: number): void {
    if (!item.nSeq || this.type === EEntityStateDetailType.DETAIL) {
      this._docComercialDrag.dragging = false;
      return;
    }
    event.stopImmediatePropagation();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (<any>event).dataTransfer.effectAllowed = 'all';

    this._docComercialDrag = {
      dragging: true,
      dragOriginalElement: <HTMLElement>event.target,
      dragOriginalIndex: index,
      dragOriginalNodeItem: item,
      dragCurrentIndex: index,
      dragCurrentNodeItem: item,
      dragPreviousIndex: index,
      dragPreviousClientY: undefined
    };
  }

  public dragOver(event: MouseEvent, item: IDocComercialLinha, index: number): void {
    if (!this._docComercialDrag.dragging || !item.nSeq) {
      return;
    }

    event.preventDefault();
    event.stopImmediatePropagation();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataTransfer: any = (<any>event).dataTransfer;
    dataTransfer.dropEffect = 'move';

    let element: HTMLElement = <HTMLElement>event.target;
    if (!element.matches('.doccomercial-linha')) {
      element = element.closest('.doccomercial-linha');
    }

    if (!this._docComercialDrag.dragPreviousClientY || this._docComercialDrag.dragPreviousClientY === event.clientY) {
      this._docComercialDrag.dragPreviousClientY = event.clientY;
      return;
    }

    let doTransferArrayItem = false;
    const directionUpwards: boolean = this._docComercialDrag.dragPreviousClientY > event.clientY;
    this._docComercialDrag.dragPreviousClientY = event.clientY;

    const elementRect: DOMRect = element.getBoundingClientRect();
    const offsetY: number = !directionUpwards ? event.clientY - elementRect.top : (event.clientY - elementRect.bottom) * -1;

    if (offsetY > 0) {
      const cursorVerticalPosition: number = Math.ceil((100 * offsetY) / elementRect.height);
      const cursorHalfWayElement: boolean = cursorVerticalPosition > PERCENTAGE_HALF;

      const dragCurrentIndex = index;

      if (cursorHalfWayElement) {
        if (dragCurrentIndex !== this._docComercialDrag.dragCurrentIndex) {
          this._docComercialDrag.dragCurrentIndex = dragCurrentIndex;

          doTransferArrayItem = true;
        }
      }

      if (doTransferArrayItem) {
        moveItemInArray(this.paginatedLinhas, this._docComercialDrag.dragCurrentIndex, this._docComercialDrag.dragPreviousIndex);

        this._docComercialDrag.dragPreviousIndex = dragCurrentIndex;
        this._docComercialDrag.dragPreviousClientY = undefined;
      }
    }
  }

  public dragEnd(): void {
    this._docComercialDrag = {
      dragging: false,
      dragOriginalElement: undefined,
      dragOriginalIndex: undefined,
      dragOriginalNodeItem: undefined,
      dragCurrentIndex: undefined,
      dragCurrentNodeItem: undefined,
      dragPreviousIndex: undefined,
      dragPreviousClientY: undefined
    };

    let nSeq = 1;
    for (const paginatedLinha of this.paginatedLinhas) {
      if (paginatedLinha.nSeq) {
        paginatedLinha.nSeq = nSeq;
        nSeq++;
      }
    }

    this._changed();
  }

  public openArtigosAdvancedSearch(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DocumentoArtigosAdvancedSearchModalComponent, {size: 'fullscreen'});
    const componentInstance: DocumentoArtigosAdvancedSearchModalComponent = modalInstance.componentInstance;
    componentInstance.emptyLineComercial = copy(this._emptyLine());
    componentInstance.maintenanceMode = this.maintenanceMode;
    componentInstance.serviceMethodsOverride = this.serviceMethodsOverride;
    return modalInstance.result
      .then((result: Array<IDocComercialLinha>) => {
        if (!this._docComercialEmEdicao(this.model.cab)) {
          return;
        }

        if (!this.model?.linhas[-1]?.nArtigo) {
          this.model.linhas.splice(this.model.linhas.length - 1, 1);
        }

        for (const linha of result) {
          this.model.linhas.push(linha);
        }

        this._evaluateNewLinesAdded();
      })
      .finally(() => {
        this._setFocusNArtigo();
      });
  }

  public openPrecoUnitarioModal(linha: IDocComercialLinha): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DocsComerciaisPrecoUnitarioModalComponent, {size: 'md'});
    const componentInstance: DocsComerciaisPrecoUnitarioModalComponent = modalInstance.componentInstance;
    componentInstance.modalInput = {cab: this.model.cab, linha: linha};
    return modalInstance.result
      .then(() => {
        this.onPrVenda1Changed(linha.prVenda1, linha);
      })
      .finally(() => {
        const rowElement: HTMLTableRowElement = document.querySelector(`tr[data-uid="${linha.uid}"]`);
        if (isDefinedNotNull(rowElement)) {
          setTimeout(() => {
            this._setFocusPrVenda(rowElement.rowIndex);
          }, timeOutFocus);
        }
      });
  }

  public onGDocsViewerAddingFile(event: IGDocsOnAddingFile): void {
    event.promise = (async () => {
      if (this.type === EEntityStateDetailType.NEW) {
        await this._cgModalService.showOkCancel('global.text.confirmation', 'docscomerciais.modals.anexosPromptSaveDraft', {
          btnCancelText: 'global.btn.no',
          btnOkText: 'global.btn.yes'
        });

        if (this._obrigatorioMeiosPagam) {
          const modalInstance = this._cgModalService.showVanilla(DocumentoMeiosPagamentoModalComponent, {size: 'lg'});
          const componentInstance: DocumentoMeiosPagamentoModalComponent = modalInstance.componentInstance;
          componentInstance.doc = copy(this.model);
          componentInstance.codMoeda = this.codMoedaEmpresa;
          componentInstance.abreviaturaMoeda = this.abreviaturaMoedaEmpresa;
          componentInstance.emEdicao = true;
          await modalInstance.result;
        }
        const response: HttpResponse<IDocComercial> = await this._documentosService.postDoc(this.model, false, false);

        if (this.model.meiosPagamento && this.model.meiosPagamento.length > 0) {
          response.body.meiosPagamento = [];
          response.body.meiosPagamento = copy(this.model.meiosPagamento);
        }

        response.body.cab.identificaCarga = this.model.cab.identificaCarga;

        this.model.cab = merge({}, this.model.cab, response.body.cab);
      }

      this.gDocParams = `NDocFaDraft=${this.model.cab.nDocFa}&NNumerDraft=${this.model.cab.nNumer}&NDocumentoDraft=${this.model.cab.nDocumentoDraft}&Faccbid=${this.model.cab.faccbId}`;

      const nomePasta = this._generateNDoc(this.model.cab);

      await this._addDocFolder(nomePasta, EFolderDoc.DefaultFolderGestCom);
      await new Promise((resolve) => {
        setTimeout(resolve, 0);
      });

      this._editStateParams = {id: this.model.cab.faccbId, doc: copy(this.model)};
      this._editStateParams[entityStateDetailQueryParam(this.entityName)] = EEntityStateDetailType.EDIT;
    })();
  }

  public onGDocsViewerUploadFile(): void {
    if (this.type === EEntityStateDetailType.NEW) {
      this._stateService.go(this.statesDetailName, this._editStateParams);
    }
  }

  public callSelecaoLotes(item: IJsonDocComercialLinha): Promise<void> {
    if (!item) {
      return Promise.reject(new Error('docscomerciais.erros.tipoDocNaoIntegraStock'));
    }

    if (!this.comercialAdvanced) {
      const errorMsg = !this.licencaModoCGOn
        ? this._plTranslateService.translate('blockedPlugin.text.portalNoLicense', {portal: this.portalName})
        : this._plTranslateService.translate('blockedPlugin.text.noLicenseCloudPublica', {portal: this.portalName, href: this.cgStoreUrlBackOffice});
      return Promise.reject(new Error(errorMsg));
    }

    const artigo: IDocComercialArtigo = this.stocks.cache.get(item.nArtigo);
    if (
      item.lotes.length === 0 &&
      (item.nArtigo === '' ||
        item.nArtigo !== artigo?.nArtigo ||
        !this.model?.cab?.integraStocks ||
        !artigo?.temLote ||
        (artigo?.codvaloriz !== CUSTO_MEDIO_PONDERADO && (artigo?.codvaloriz !== FIFO || (!this._grupoDoc.isCompra(this.group.grupo) && !this._grupoDoc.isEntradaDiversa(this.group.grupo)))))
    ) {
      return Promise.reject(new Error('Não tem lote.'));
    }

    const instance = this._cgModalService.showVanilla(SelecaoloteslinhaModalComponent, {size: 'md'});
    instance.componentInstance.doc = this.model;
    instance.componentInstance.linha = item;
    instance.componentInstance.detail = this.docAssinadoNaoEncerrado;
    return instance.result.then((result: IJsonDocComercialLinha) => {
      item = result;
      return this.calc();
    });
  }

  public artigoLinhaTemLote(item: IJsonDocComercialLinha): boolean {
    const artigo: IDocComercialArtigo = this.stocks.cache.get(item.nArtigo);
    return (
      item.nArtigo !== '' &&
      item.nArtigo === artigo?.nArtigo &&
      this.model?.cab?.integraStocks &&
      artigo?.temLote &&
      (artigo?.codvaloriz === CUSTO_MEDIO_PONDERADO || (artigo?.codvaloriz === FIFO && (this._grupoDoc.isCompra(this.group.grupo) || this._grupoDoc.isEntradaDiversa(this.group.grupo))))
    );
  }

  public changedPerguntaSeFinalizaDoc(value: boolean): void {
    this.naoPerguntaSeFinalizaDoc = value;
    this._documentosService.setOption('showPerguntaSeFinalizaDoc', !value);
  }

  public showTxtFaturacaoModal(): void {
    const modalRef = this._cgModalService.showVanilla(TxtFaturacaoModalComponent, {size: 'md'});
    const componentInstance: TxtFaturacaoModalComponent = modalRef.componentInstance;
    componentInstance.clifo = this.model.clifo;
  }

  public onCondicaoPagamentoChanged(value: number): void {
    if (value) {
      if (this.model.cab.nCondPag !== value) {
        this.model.cab.nCondPag = value;
      }

      this._serviceCondPagamento.get({id: value, reportExceptions: false}).then((response: HttpResponse<IJsonCondicaoPagamento>) => {
        if (response) {
          this.model.cab.dataVenc = moment(this.model.cab.dataDocExterno).add(response.body.numDiasDataVenc, 'days');
        }
      });
      this.modelChanged();
    }
  }

  public modelChanged(): void {
    this.evtModelChanged.emit(this.model);
  }

  public onResizerValuesChanged(): void {
    if (!this._destroyed && isObject(this.callbackDocViewer.pdf) && isFunction(this.callbackDocViewer.pdf.updateSize)) {
      setTimeout(this.callbackDocViewer.pdf.updateSize);
    }
  }

  public changedComercialDigitalAttachment({folder, attachment, doInitDocumento}: IArquivoDigitalDocViewerEvtChangedAttachment): Promise<void> {
    if (this.type !== EEntityStateDetailType.NEW || !this.arquivoDigital || !folder || folder.isFolderFromClassificados || attachment?.cab.associadoADocContab) {
      this._docOCRCab = undefined;
      this._setComercialArquivoDigitalAttachment('', '', '');
      return Promise.resolve();
    }
    this._docOCRCab = attachment?.cab;
    const gDocId: string = this._docOCRCab ? this._docOCRCab.docID : '';
    const gDocFolderId: string = folder.folderID || '';
    if (this.comercialArquivoDigitalAttachment.gDocId !== gDocId || this.comercialArquivoDigitalAttachment.gDocFolderId !== gDocFolderId) {
      this._setComercialArquivoDigitalAttachment(gDocId, gDocFolderId, this._docOCRCab?.docOCRCabID);
      if (doInitDocumento !== false) {
        const currentPromise: Promise<unknown> = this.promise;
        this.promise = new Promise<void>((resolve) => {
          Promise.resolve(currentPromise).finally(() => {
            return this._documentosService
              .novoByDocOCRCabID(this._docOCRCab.docOCRCabID)
              .then((response: HttpResponse<IDocComercial>) => {
                const nDocFaEvaluate = this.model.cab.nDocFa !== response.body.cab.nDocFa ? response.body.cab.nDocFa : undefined;

                this.model = merge(this.model, response.body);
                return this._evaluateParamsCab(nDocFaEvaluate, undefined, undefined).then(() => {
                  response.body.clifo.nContribuint = response.body.cab.nif;
                  this.model.clifo = merge(this.model.clifo, response.body.clifo);
                });
              })
              .finally(resolve);
            // this._initDocumento().finally(resolve);
          });
        });
        return this.promise;
      }
    }
    return Promise.resolve();
  }

  public devolucaoEditarChanged(emEdicao: boolean): void {
    this.devolucaoEditar = emEdicao;
  }

  public keydownTextoLivre(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
    }
  }

  public getTextoLivreNContaBtnCaption(): string {
    const docType = grupoDocToDocType(this.group.grupo);
    return docType === EGrupoDocType.Venda ? 'docscomerciais.fields.nome.clientes' : 'docscomerciais.fields.nome.fornecedores';
  }

  public getFieldProperties(field: string, item: IDocComercialLinha): IPlEditComponentOptionsInput<unknown> | IEntityAutocompleteOptions {
    const isEditable = !item.docRegularizMotivo && !this.isDocumentoTerminado && !this.docAssinadoNaoEncerrado;
    switch (field) {
      case 'nArtigo': {
        return isEditable ? this.propertiesEditArtigos : this.propertiesEditArtigosDisabled;
      }
      case 'qtd1': {
        return isEditable ? this.propertiesEditDecimalsQtdLimit : this.propertiesEditDecimalsQtdLimitDisabled;
      }
      case 'prVenda1': {
        return isEditable ? this.propertiesEditDecimalsValoresUnitariosLimit : this.propertiesEditDecimalsValoresUnitariosLimitDisabled;
      }
      case 'codIva': {
        return isEditable ? this.propertiesEdit : this.propertiesEditDisabled;
      }
      case 'nomeArtigo':
      case 'd1':
      case 'd2':
      case 'd3':
      case 'desconto': {
        return isEditable ? this.propertiesEditUpdateOnBlur : this.propertiesEditUpdateOnBlurDisabled;
      }
      case 'codUnidMov':
      case 'nArmazem':
      case 'nCCusto':
      case 'nVendedor':
      case 'nrefprocesso': {
        return !this.isDocumentoTerminado || this.docAssinadoNaoEncerrado ? this.propertiesEdit : this.propertiesEditDisabled;
      }
      default: {
        return this.propertiesEdit;
      }
    }
  }

  public readonly fnEvaluateConfigOptionInvisible = (params: IDocComercialEvaluateConfigOptionInvisibleParams): boolean => this._evaluateConfigOptionInvisible(params);

  public readonly fnCodPostalValidate = (codPostal: string): void => {
    if (codPostal) {
      const jsonCodPostal: IJsonCodPostal = {codPostal: codPostal, localidade: '', codPais: 0, nomePais: ''};
      this._onCodPostalValidate(jsonCodPostal);
    }
  };

  public readonly fnMoralValidate = (search: string, moral: IJsonMorAl | string): IJsonMorAl | string => this._onMoralValidate(moral);

  public readonly fnAddLine = (): void => {
    this.addLine();
  };

  public readonly fnCheckLine = (event: KeyboardEvent, next: string): boolean => this.checkLine(event, next);

  public readonly fnOnNNumerValidate: (nNumer: string, item: IJsonDocfaNum) => Promise<IJsonDocfaNum> = (nNumer: string, item: IJsonDocfaNum) => this._onNNumerValidate(nNumer, item);

  public readonly fnOnClifoNifValidate: (nif: string, item: IJsonDocComercialClifo) => Promise<IJsonDocComercialClifo> = (nif: string, item: IJsonDocComercialClifo) =>
    this._onClifoNifValidate(nif, item);

  public readonly fnKeyDownDataDoc: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._keyDownDataDoc(event);
  };

  public readonly fnKeyDownMoeda: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._keyDownMoeda(event);
  };

  public readonly fnKeyDownCambio: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._keyDownCambio(event);
  };

  public readonly fnKeyDownNFactFornec: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._keyDownNFactFornec(event);
  };

  public readonly fnKeyDownDataDocExterno: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._keyDownDataDocExterno(event);
  };

  public readonly fnKeyDownDataVenc: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._keyDownDataVenc(event);
  };

  public readonly fnKeyDownNCCusto: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._keyDownNCCusto(event);
  };

  public readonly fnKeyDownNRefProcesso: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._keyDownNRefProcesso(event);
  };

  public readonly fnKeyDownObservacoes: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._keyDownObservacoes(event);
  };

  public readonly fnKeyDownCompromisso: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._keyDownCompromisso(event);
  };

  public readonly fnKeyDownTextoLivre: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._keydownTextoLivre(event);
  };

  public readonly fnKeyDownNRequisicao: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._keyDownNRequisicao(event);
  };

  public readonly fnKeyDownCondPagamento: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._keyDownCondPagamento(event);
  };

  private _changedLinhas(): void {
    this.paginationLinhas.page = 1;
    this.paginateLinhas();
  }

  private _showRastreabilidade(): Promise<void> {
    return this._documentoRastreabilidadeService
      .getRastreabilidade(this.model.cab.nDocFa, this.model.cab.nNumer, this.model.cab.nDocumentoDraft)
      .then((response: HttpResponse<Array<IJsonRastreabilidadeFaturacao>>) => {
        const modalRef = this._cgModalService.showVanilla(DocumentoRastreabilidadeModalComponent, {size: 'fullscreen'});
        const componentInstance: DocumentoRastreabilidadeModalComponent = modalRef.componentInstance;
        componentInstance.rastreabilidade = response.body;
        componentInstance.faccbId = this.model.cab.faccbId;
      });
  }

  private _encomendasPendentes(): Promise<void> {
    if (!this.model.cab.nConta) {
      this._plAlertService.error(
        this._translateService.instant('encomendas.errors.clientenaoselecionado', {
          clifo: this._grupoDoc.isCompra(this.model.cab.grupoDocfa) ? 'fornecedor' : 'cliente'
        })
      );
      this._setFocusNClifo();
      return undefined;
    }

    const modalInstance = this._cgModalService.showVanilla(EncomendasSatisfazerClifoModalComponent, {size: 'xl'});
    const componentInstance: EncomendasSatisfazerClifoModalComponent = modalInstance.componentInstance;
    const grupoClifo: TClifos = this._grupoDoc.isCompra(this.model.cab.grupoDocfa) ? 'fornecedores' : 'clientes';
    componentInstance.grupo = this.model.cab.grupoDocfa;
    componentInstance.grupoClifos = grupoClifo;
    componentInstance.nConta = this.model.cab.nConta;
    componentInstance.nomeConta = this.model.clifo.nome;
    componentInstance.callFromFaturacao = true;
    componentInstance.doc = this.model;

    return modalInstance.result.then((response: IJsonDocComercial) => {
      if (response) {
        if (this.type !== EEntityStateDetailType.EDIT) {
          const stateParams: IDocComercialEditStateParams = {id: response.cab.faccbId, doc: copy(response)};
          stateParams[entityStateDetailQueryParam(this.entityName)] = EEntityStateDetailType.EDIT;
          this._stateService.go(this.statesDetailName, stateParams);
        } else {
          this.model.linhas = response.linhas.map((linha: IJsonDocComercialLinha) => merge(this._emptyLine(), linha));
          this._changedLinhas();
          this._changed();
          this.addLine();
        }
      }
    });
  }

  private _onCodPostalValidate(codPostal: IJsonCodPostal): boolean | void {
    if (!codPostal) {
      return false;
    }
    this._externalCodPostaisService.get(codPostal.codPostal).then(
      (externalCodPostal: Array<IJsonCodPostalHelper>) => {
        if (!externalCodPostal?.length) {
          this.model.clifo.localidade = codPostal.localidade || '';
          this.modelChanged();
          return;
        }
        this.model.clifo.codPostal = externalCodPostal[0].codpostal;
        this.model.clifo.localidade = externalCodPostal[0].designacao;
        this.model.clifo.rua = externalCodPostal[0].rua;
      },
      () => {
        this.model.clifo.localidade = '';
      }
    );

    this.modelChanged();
    return true;
  }

  private _onMoralValidate(moral: IJsonMorAl | string): IJsonMorAl | string {
    if (isObject(moral)) {
      this.showMessageMoralError = false;
      return moral;
    }

    this.showMessageMoralError = true;
    return undefined;
  }

  private _emptyLine(): IDocComercialLinha {
    return {
      nArtigo: '',
      textoLivre: '',
      qtd1: 1,
      prVenda1: 0,
      codIva: 0,
      taxaIva: 0,
      valorIva: 0,
      prVendaIvaInc: 0,
      valorIvaME: 0,
      prVendaIvaIncME: 0,
      nCCusto: '',
      d1: 0,
      showTextoLivre: false,
      nrefprocesso: '',
      nVendedor: undefined,
      codUnidMov: 0,
      liquido: 0,
      desconto: 0,
      codIvaDefined: false,
      lotes: [],
      dataEntregaPrev: moment(),
      prVenda1Defined: false,
      uid: generateUUID()
    };
  }

  private _getClifoInfo(): IDocComercialClifoInfo {
    const clifoinfo: IDocComercialClifoInfo = {nome: '', nContribuint: '', nConta: ''};
    if ((this.model?.cab && this._grupoDoc.isVenda(this.model?.cab?.grupoDocfa) && this.model?.cab?.fluxoMonetario !== EFluxoMonetario.Credito) || this.isTipoDocStocks) {
      clifoinfo.nome = consFinalNome;
      clifoinfo.nContribuint = this.isTipoDocStocks ? CG_DEFAULT_AT_NIF : '';
      clifoinfo.nConta =
        this.isTipoDocStocks && this.isEntradaDiversa
          ? this._configService.configurations.clientesFornecedores.contaIdentificacaoAlternativaFornecedores
          : this._configService.configurations.clientesFornecedores.contaIdentificacaoAlternativa;
    }
    return clifoinfo;
  }

  private _emptyClifo(obj: IDocComercial): void {
    const clifoinfo = this._getClifoInfo();
    obj.clifo = merge(docComercialEmptyClifo(), {
      nContribuint: clifoinfo.nContribuint,
      nConta: clifoinfo.nConta,
      nome: clifoinfo.nome
    });
  }

  private _emptyClifoToDocCab(clifo: IJsonDocComercialClifo, docCab: IDocComercialCab): void {
    if (isUndefinedOrNull(docCab) || isUndefinedOrNull(clifo)) {
      return;
    }
    docCab.nConta = clifo.nConta;
    docCab.nIdAltern = clifo.nIdAltern;
    docCab.nome = clifo.nome;
    docCab.nif = clifo.nContribuint;
    docCab.codCondComerciais = clifo.codCondComerciais;
    docCab.nCondPag = clifo.codPagamento;
    docCab.nomeCondPag = clifo.nomePagamento;
  }

  private _emptyDoc(item?: IJsonDocfa): IDocComercial {
    const docFa = item ? item : this.docFa;
    const myMomentObj = moment().add(DEFAULT_MIN_INIC_TRANSP, 'minutes');
    const now = moment();
    this.mostraEditCliente = false;
    this.mainTabCargaEnabled = docFa?.identificaCarga;
    const doc: IDocComercial = {
      cab: {
        nNumer: docFa ? (docFa.docfanumList.length > 0 ? this._getNNumerDefeitoDocfa(docFa.docfanumList) : 1) : 1,
        nDocFa: docFa ? docFa.nDocFa : 0,
        docNome: docFa ? docFa.nome : '',
        grupoDocfa: docFa ? docFa.grupoDocfa : 0,
        dataDoc: now,
        codMoeda: this.codMoedaEmpresa,
        cambio: 0,
        dataDocExterno: now,
        dataInicTran: now,
        horaInicTran: myMomentObj,
        taxaRet: 0,
        retencaoIncidencia: 0,
        retencaoValor: 0,
        totalIliquido: 0,
        totalDesconto: 0,
        totalLiquido: 0,
        totalIva: 0,
        totalDocumento: 0,
        totalIliquidoME: 0,
        totalDescontoME: 0,
        totalLiquidoME: 0,
        totalIvaME: 0,
        totalDocumentoME: 0,
        nFactFornec: '',
        nCCusto: '',
        nrefprocesso: '',
        observacoes: '',
        nCondPag: 0,
        nomeCondPag: '',
        docActions: []
      },
      clifo: merge({}, docComercialEmptyClifo(), {
        action:
          docFa && (docFa.fluxoMonetario === EFluxoMonetario.Credito || docFa.fluxoMonetario === EFluxoMonetario.CompraCredito)
            ? EDocsComerciaisClifoStatus.Novo
            : EDocsComerciaisClifoStatus.NovoAlternativo
      }),
      linhas: [this._emptyLine()],
      transportador: {
        nGuiaTransporte: '',
        dataGuiaTransporte: now,
        matricula: '',
        matriculaReboque: '',
        nomeTransportador: '',
        nomeViatura: '',
        nomeMotorista: '',
        moradaMotorista: '',
        codPostalMotorista: '',
        codPaisMotorista: 0
      },
      meiosPagamento: [],
      resumoIva: []
    };
    this._emptyClifo(doc);
    this.arredManualString = '';
    this.clifoSaldoEmAberto = undefined;
    return doc;
  }

  private _changed(clearDocActions: boolean = false, item: IDocComercialLinha = null): void {
    if (clearDocActions) {
      if (this.model.cab.docActions.length > 0) {
        this.model.cab.docActions.length = 0;
      }
    }

    this.storeModel();

    if (this.model.cab.nDocFa && this.model.cab.dataDoc) {
      this.calc().finally(() => {
        if (clearDocActions) {
          this.model.cab.docActions.push({action: 2, activo: true, info: 'Envia Data Vencimento'});
          this.modelChanged();
        }
        if (item !== null) {
          this.calcStock(item);
        }
      });
    }
  }

  private _clifoToCab(clifo: IJsonDocComercialClifo, calc: boolean = true): void {
    if (clifo) {
      const nConta = clifo.nConta;
      this.model.cab.nConta = nConta;
      this.model.cab.nIdAltern = clifo.nIdAltern;
      this.model.cab.nome = clifo.nome;
      this.model.cab.nif = clifo.nContribuint;
      this.model.cab.codCondComerciais = clifo.codCondComerciais;
      this.model.cab.nCondPag = clifo.codPagamento;
      this.model.cab.nomeCondPag = clifo.nomePagamento;
      if (calc) {
        if (this.model.cab.codMoeda !== clifo.codMoeda) {
          this.model.cab.codMoeda = clifo.codMoeda;
          this.model.cab.nomeMoeda = clifo.nomeMoeda;
          this.model.cab.cambio = 0;
          this._getMoeda(clifo.codMoeda);
        }
      }
      if (this.model.cab.identificaCarga) {
        if (this._grupoDoc.isVenda(this.model.cab.grupoDocfa)) {
          this.model.moradaDescarga.nConta = clifo.nConta;
          this.model.moradaDescarga.moralId = '';
          this.model.moradaDescarga.nome = '';
          this.model.moradaDescarga.id = 0;
          this.model.moradaDescarga.rua = clifo.rua;
          this.model.moradaDescarga.morada = clifo.lugar;
          this.model.moradaDescarga.codPostal = clifo.codPostal;
          this.model.moradaDescarga.localidade = clifo.localidade;
          this.model.moradaDescarga.codPais = clifo.codPais;
          this.model.moradaDescarga.nomePais = clifo.nomePais;
          this.model.moradaDescarga.observacoes = '';
        } else {
          this.model.moradaCarga.nConta = nConta;
          this.model.moradaCarga.moralId = '';
          this.model.moradaCarga.nome = '';
          this.model.moradaCarga.id = 0;
          this.model.moradaCarga.rua = clifo.rua;
          this.model.moradaCarga.morada = clifo.lugar;
          this.model.moradaCarga.codPostal = clifo.codPostal;
          this.model.moradaCarga.localidade = clifo.localidade;
          this.model.moradaCarga.codPais = clifo.codPais;
          this.model.moradaCarga.nomePais = clifo.nomePais;
          this.model.moradaCarga.observacoes = '';
        }
        this.nContaMoralFilter = `nConta=${nConta}&desativado=false`;
        if (clifo.nIdAltern > 0) {
          this.nContaMoralFilter = `${this.nContaMoralFilter}&nIdAltern=${clifo.nIdAltern}`;
        }
      }
    } else {
      this.model.cab.nConta = '';
      this.model.cab.nIdAltern = 0;
      this.model.cab.nome = '';
      this.model.cab.nif = '';
      this.model.cab.codCondComerciais = 0;
      this.model.cab.nCondPag = 0;
      this.model.cab.nomeCondPag = '';
      if (this.model.cab.identificaCarga) {
        if (this._grupoDoc.isVenda(this.model.cab.grupoDocfa)) {
          this.model.moradaDescarga = this._generateMoradaFaturacao();
        } else {
          this.model.moradaCarga = this._generateMoradaFaturacao();
        }
        this.nContaMoralFilter = '';
      }
    }

    if (calc) {
      this._changed(true);
    }
  }

  private _clearMoradasFaturacao(): void {
    this.model.moradaCarga = this._generateMoradaFaturacao();
    this.model.moradaDescarga = this._generateMoradaFaturacao();
    this.model.transportador = this._generateTransportador();
    this.modelChanged();
  }

  private _buildFilter(): void {
    this.docFilter = this.group.grupo ? `grupoDocfa=${this.group.grupo}` : undefined;
    this.clifoEntName = this.group.clifos ? this.group.clifos : undefined;
    this.clifoFilter = 'desactivado<>1';
    if (this.group.grupo === EGrupoDoc.ComprasEfectivas) {
      if (this.group.properties?.autofaturacao) {
        if (this.docFilter) {
          this.docFilter += '&autofaturacao>0';
        }
        this.clifoFilter += '&autofaturacao=1';
      } else {
        this.docFilter += '&autofaturacao=0';
      }
    }
    this.docfaNumFilter = `nDocFa=${this.group.nDocFa.documentoPorDefeito}&encerrado=0&visivelERPCloud=1`;
  }

  private _setDefaultTipoDoc(): void {
    this.model = this._emptyDoc();
    this._changedLinhas();
    if (!this.group.grupo) {
      return;
    }

    let promise: Promise<void>;
    if (this.group.nDocFa?.documentoPorDefeito) {
      promise = this._servicosDocFas.get({id: this.group.nDocFa.documentoPorDefeito, reportExceptions: false}).then(
        (response: HttpResponse<IJsonDocfa>) => {
          // sucesso
          this.docFa = response.body;
          this.onDocFaChanged(response.body);
          this.calc();
        },
        () => {
          // falha
          this.docFa = {};
          this.model.cab.nDocFa = 0;
          this.model.cab.docNome = '';
          this._setFocusNDocfa();
        }
      );
    } else if (this.group.properties?.autofaturacao) {
      promise = this._servicosDocFas.query({pesquisa: `grupoDocfa=${this.group.grupo}&autofaturacao>0`}).then(
        (response: THttpQueryResponse<IJsonDocfa>) => {
          if (response && response.body.list.length > 0) {
            this.docFa = response.body.list[0];
            this.onDocFaChanged(response.body.list[0]);
          }
        },
        () => {
          this.docFa = {};
          this.model.cab.nDocFa = 0;
          this.model.cab.docNome = '';
        }
      );
    }

    const promiseMoeda = this._getMoeda(this.codMoedaEmpresa);

    if (!promise && !promiseMoeda) {
      this.calc();
    }
  }

  private _getNNumerDefeitoDocfa(docfanumList: Array<IJsonDocfaNum>): number {
    for (const docfanum of docfanumList) {
      if (!docfanum.encerrado && docfanum.visivelERPCloud) {
        return docfanum.nNumer;
      }
    }
    return 1;
  }

  private _getMoeda(codMoeda: number): undefined | Promise<void> {
    if (!codMoeda) {
      return undefined;
    }
    return this._serviceMoedas
      .get({id: codMoeda, reportExceptions: false})
      .then((response: HttpResponse<IJsonMoeda>) => {
        this.moeda = response.body;
        this.abreviaturaMoedaCliente = this.moeda.abreviaturaMoeda;
      })
      .catch(() => {
        this.moeda = undefined;
        this.model.cab.codMoeda = this.codMoedaEmpresa;
        this.model.cab.nomeMoeda = '';
        this.model.cab.cambio = 0;
        this.modelChanged();
      });
  }

  private _onNNumerValidate(value: string, item: IJsonDocfaNum): Promise<IJsonDocfaNum> {
    return new Promise<IJsonDocfaNum>((resolve) => {
      const isItemValid: boolean = isObject(item);
      const nNumer = isItemValid ? item.nNumer : isNumber(item) ? <number>item : isNumber(Number(value)) ? Number(value) : 0;
      this._servicosDocfanums.query({pesquisa: `nDocfa=${this.model.cab.nDocFa}&nNumer=${nNumer}`}).then((response: THttpQueryResponse<IJsonDocfaNum>) => {
        if (response?.body?.list?.length > 0) {
          Promise.resolve().finally(() => {
            resolve(response.body.list[0]);
          });
          return;
        }
        Promise.resolve().finally(() => {
          resolve(undefined);
        });
      });
    });
  }

  private _onClifoNifValidate(nif: string, item: IJsonDocComercialClifo): Promise<IJsonDocComercialClifo> {
    this._clearMoradasFaturacao();
    return new Promise<IJsonDocComercialClifo>((resolve) => {
      const isItemValid: boolean = isObject(item);
      nif = isItemValid ? item.nContribuint : isString(item) && this._cgVatService.checkVAT(`PT${<string>item}`).isValid ? <string>item : nif;

      if (isItemValid && nif === item.nContribuint) {
        this.model.clifo = merge({}, this.model.clifo, item);
        this._clifoToCab(item);
        this.nContaNifSituacaoMsg = item.situacaoActual;
        Promise.resolve(this.calc()).finally(() => {
          resolve(this.model.clifo);
        });
        return;
      }

      if ((nif && this._cgVatService.checkVAT(`PT${nif}`).isValid) || nif !== CG_DEFAULT_AT_NIF) {
        this._externalNifsService
          .get(nif)
          .then((response: IJsonExternalNif) => {
            if (!response) {
              resolve(this._nifNovo(nif));
              return;
            }
            if (!isItemValid) {
              this.model.clifo = this._nifNovo(nif);
              merge(this.model.clifo, {
                nome: response.entidade,
                codPostal: response.codigoPostal,
                rua: response.morada,
                localidade: response.localidade,
                fax: response.fax,
                telefone: response.telefone,
                nContribuint: response.nif,
                email: response.email,
                action: EDocsComerciaisClifoStatus.Novo
              });
            }
            this._clifoToCab(this.model.clifo);
            this.nContaNifSituacaoMsg = response.situacaoActual;
            Promise.resolve(this.calc()).finally(() => {
              resolve(this.model.clifo);
            });
          })
          .catch((reason: unknown) => {
            this._logger.error(reason);
            resolve(this._nifNovo(nif));
          });
      } else {
        resolve(this._nifNovo(nif));
      }
    });
  }

  private _nifNovo(nif: string): IJsonDocComercialClifo {
    this.model.clifo = merge(docComercialEmptyClifo(), {
      nome: nif === CG_DEFAULT_AT_NIF ? this._getClifoInfo().nome : '',
      nContribuint: nif,
      action:
        this.model.cab.fluxoMonetario === EFluxoMonetario.Credito || this.model.cab.fluxoMonetario === EFluxoMonetario.CompraCredito
          ? EDocsComerciaisClifoStatus.Novo
          : EDocsComerciaisClifoStatus.NovoAlternativo
    });
    this.nContaNifSituacaoMsg = '';
    this._clifoToCab(this.model.clifo);
    return this.model.clifo;
  }

  private _generateMoradaFaturacao(): IJsonMoradaFaturacao {
    return {
      nConta: '',
      nome: '',
      rua: '',
      codPostal: '',
      localidade: '',
      nipc: '',
      id: 0,
      observacoes: '',
      morada: '',
      codPais: 0,
      nomePais: '',
      moralId: '',
      nIdAltern: 0
    };
  }

  private _generateTransportador(): IJsonTransportador {
    return {
      nGuiaTransporte: '',
      dataGuiaTransporte: moment(),
      matricula: '',
      matriculaReboque: '',
      nomeTransportador: '',
      nomeViatura: '',
      nomeMotorista: '',
      moradaMotorista: '',
      codPostalMotorista: '',
      codPaisMotorista: 0
    };
  }

  private _keyDownDataDoc(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      if (this.isEntregaEfetivaSobreDocGlobal) {
        event.stopImmediatePropagation();
        this._setFocusBtnObterUltimoDocGlobal();
      } else if (this.isTipoDocStocks) {
        this._setFocusNArtigo();
      } else {
        if (this.optionShowDocExterno || this.isComprasFornecedor) {
          this._setFocusDataDocExterno();
          return;
        } else if (this.optionShowDataVenc) {
          this._setFocusCondPagamento();
          return;
        } else if (this.optionShowMoeda) {
          this._setFocusMoeda();
          return;
        } else if (this.optionShowCCusto) {
          this._setFocusCCusto();
          return;
        } else if (this.optionShowNRefProcesso) {
          this._setFocusProcesso();
          return;
        } else if (this.optionShowNRequisicao) {
          this._setFocusNRequisicao();
          return;
        } else if (this.optionShowObservacoes) {
          this._setFocusObservacoes();
          return;
        } else if (this.activeMainTab === this.mainTabCargaId) {
          this._setFocusDataInicTran();
          return;
        } else if (this.activeMainTab === this.mainTabFaturacaoPublicaId && this.docFa?.faturacaoPublica && this.model?.clifo?.facPublicaModelo !== 0) {
          this._setFocusCtRefExterna();
          return;
        }
        this._setFocusNArtigo();
      }
    }
  }

  private _keyDownMoeda(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      if (this.optionShowCCusto) {
        this._setFocusCCusto();
        return;
      } else if (this.optionShowNRefProcesso) {
        this._setFocusProcesso();
        return;
      } else if (this.optionShowNRequisicao) {
        this._setFocusNRequisicao();
        return;
      } else if (this.optionShowObservacoes) {
        this._setFocusObservacoes();
        return;
      } else if (this.activeMainTab === this.mainTabCargaId) {
        this._setFocusDataInicTran();
        return;
      } else if (this.activeMainTab === this.mainTabFaturacaoPublicaId && this.docFa?.faturacaoPublica && this.model?.clifo?.facPublicaModelo !== 0) {
        this._setFocusCtRefExterna();
        return;
      }
      this._setFocusNArtigo();
    }
  }

  private _keyDownCambio(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      if (this.optionShowCCusto) {
        this._setFocusCCusto();
        return;
      } else if (this.optionShowNRefProcesso) {
        this._setFocusProcesso();
        return;
      } else if (this.optionShowNRequisicao) {
        this._setFocusNRequisicao();
        return;
      } else if (this.optionShowObservacoes) {
        this._setFocusObservacoes();
        return;
      } else if (this.activeMainTab === this.mainTabCargaId) {
        this._setFocusDataInicTran();
        return;
      } else if (this.activeMainTab === this.mainTabFaturacaoPublicaId && this.docFa?.faturacaoPublica && this.model?.clifo?.facPublicaModelo !== 0) {
        this._setFocusCtRefExterna();
        return;
      }
      this._setFocusNArtigo();
    }
  }

  private _keyDownNFactFornec(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      if (this.optionShowDataVenc) {
        this._setFocusCondPagamento();
        return;
      } else if (this.optionShowMoeda) {
        this._setFocusMoeda();
        return;
      } else if (this.optionShowCCusto) {
        this._setFocusCCusto();
        return;
      } else if (this.optionShowNRefProcesso) {
        this._setFocusProcesso();
        return;
      } else if (this.optionShowNRequisicao) {
        this._setFocusNRequisicao();
        return;
      } else if (this.optionShowObservacoes) {
        this._setFocusObservacoes();
        return;
      } else if (this.activeMainTab === this.mainTabCargaId) {
        this._setFocusDataInicTran();
        return;
      } else if (this.activeMainTab === this.mainTabFaturacaoPublicaId && this.docFa?.faturacaoPublica && this.model?.clifo?.facPublicaModelo !== 0) {
        this._setFocusCtRefExterna();
        return;
      }
      this._setFocusNArtigo();
    }
  }

  private _keyDownDataDocExterno(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      this._setFocusDocExterno();
    }
  }

  private _keyDownCondPagamento(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      this._setFocusDataVenc();
    }
  }

  private _keyDownDataVenc(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      if (this.isEntregaEfetivaSobreDocGlobal) {
        event.stopImmediatePropagation();
        this._setFocusBtnObterUltimoDocGlobal();
      } else if (this.isTipoDocStocks) {
        this._setFocusNArtigo();
      } else {
        if (this.optionShowMoeda) {
          this._setFocusMoeda();
          return;
        } else if (this.optionShowCCusto) {
          this._setFocusCCusto();
          return;
        } else if (this.optionShowNRefProcesso) {
          this._setFocusProcesso();
          return;
        } else if (this.optionShowNRequisicao) {
          this._setFocusNRequisicao();
          return;
        } else if (this.optionShowObservacoes) {
          this._setFocusObservacoes();
          return;
        } else if (this.activeMainTab === this.mainTabCargaId) {
          this._setFocusDataInicTran();
          return;
        } else if (this.activeMainTab === this.mainTabFaturacaoPublicaId && this.docFa?.faturacaoPublica && this.model?.clifo?.facPublicaModelo !== 0) {
          this._setFocusCtRefExterna();
          return;
        }
        this._setFocusNArtigo();
      }
    }
  }

  private _keyDownNCCusto(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      if (this.optionShowNRefProcesso) {
        this._setFocusProcesso();
        return;
      } else if (this.optionShowNRequisicao) {
        this._setFocusNRequisicao();
        return;
      } else if (this.optionShowObservacoes) {
        this._setFocusObservacoes();
        return;
      } else if (this.activeMainTab === this.mainTabCargaId) {
        this._setFocusDataInicTran();
        return;
      } else if (this.activeMainTab === this.mainTabFaturacaoPublicaId && this.docFa?.faturacaoPublica && this.model?.clifo?.facPublicaModelo !== 0) {
        this._setFocusCtRefExterna();
        return;
      }
      this._setFocusNArtigo();
    }
  }

  private _keyDownNRefProcesso(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      if (this.optionShowNRequisicao) {
        this._setFocusNRequisicao();
        return;
      } else if (this.optionShowObservacoes) {
        this._setFocusObservacoes();
        return;
      } else if (this.activeMainTab === this.mainTabCargaId) {
        this._setFocusDataInicTran();
        return;
      } else if (this.activeMainTab === this.mainTabFaturacaoPublicaId && this.docFa?.faturacaoPublica && this.model?.clifo?.facPublicaModelo !== 0) {
        this._setFocusCtRefExterna();
        return;
      }
      this._setFocusNArtigo();
    }
  }

  private _keyDownNRequisicao(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      if (this.optionShowObservacoes) {
        this._setFocusObservacoes();
        return;
      } else if (this.activeMainTab === this.mainTabCargaId) {
        this._setFocusDataInicTran();
        return;
      } else if (this.activeMainTab === this.mainTabFaturacaoPublicaId && this.docFa?.faturacaoPublica && this.model?.clifo?.facPublicaModelo !== 0) {
        this._setFocusCtRefExterna();
        return;
      }
      this._setFocusNArtigo();
    }
  }

  private _keyDownObservacoes(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      if (this.activeMainTab === this.mainTabCargaId) {
        this._setFocusDataInicTran();
        return;
      } else if (this.activeMainTab === this.mainTabFaturacaoPublicaId && this.docFa?.faturacaoPublica && this.model?.clifo?.facPublicaModelo !== 0) {
        this._setFocusCtRefExterna();
        return;
      }
      this._setFocusNArtigo();
    }
  }

  private _keyDownCompromisso(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      this._setFocusNArtigo();
    }
  }

  private _keydownTextoLivre(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
    }
  }

  private _keydownQtd(value: number, event: KeyboardEvent): void {
    if (event.key !== KEYCODES.ENTER) {
      return;
    }
    const element: HTMLInputElement = <HTMLInputElement>event.target;
    const linha: IDocComercialLinha = this._getLinhaByRowElement(element);
    let handlesFocus = true;
    this.callSelecaoLotes(linha)
      .catch((reason: unknown) => {
        if (isError(reason)) {
          handlesFocus = false;
        }
      })
      .finally(() => {
        if (!handlesFocus) {
          return;
        }
        const row: Element = element.closest('td');
        const input = row.nextElementSibling.querySelector('input');
        focusAndSelectElementWithDelay(input);
      });
  }

  private _tableEditFocus(event: FocusEvent): void {
    const linha: IDocComercialLinha = this._getLinhaByRowElement(<HTMLInputElement>event.target);
    if (linha) {
      this.calcStock(linha, isUndefinedOrNull(linha.facliId));
    }
  }

  private _setFocusNDocfa(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.docfa-edit input[name="docfa"]'));
  }

  private _setFocusMoeda(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.moeda-edit input[name="moeda"]'));
  }

  private _setFocusNArtigo(nline?: number): void {
    const selector: string = nline ? `.table-artigos tr:nth-child(${nline}) entity-autocomplete[entity="artigos"] input` : '.table-artigos input[name="artigo"]';
    focusElement(this._element.querySelector<HTMLInputElement>(selector));
  }

  private _setFocusPrVenda(nline?: number): void {
    const selector: string = nline ? `.table-artigos tbody tr:nth-child(${nline}) input[name="precoVenda"]` : '.table-artigos tbody input[name="precoVenda"]';
    focusElement(this._element.querySelector<HTMLInputElement>(selector));
  }

  private _setFocusBtnPesqDocsGlobais(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.btn-pesquisa-docs-globais'));
  }

  private _setFocusBtnObterUltimoDocGlobal(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.btn-obter-ultimo-doc-global'));
  }

  private _setFocusNClifo(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.clifo-edit input[name="clifo"]'));
  }

  private _setFocusNomeClifo(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.clifo-edit input[name="nomeClifo"]'));
  }

  private _setFocusDataDoc(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.cab-options-edit input[name="cabdata"]'));
  }

  private _setFocusDataVenc(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.cab-options-edit input[name="dataVenc"]'));
  }

  private _setFocusDocExterno(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.cab-options-edit input[name="nFactFornec"]'));
  }

  private _setFocusDataDocExterno(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.cab-options-edit input[name="dataDocExterno"]'));
  }

  private _setFocusCCusto(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.cab-options-edit input[name="nCCusto"]'));
  }

  private _setFocusProcesso(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.cab-options-edit input[name="nRefProcesso"]'));
  }

  private _setFocusNRequisicao(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.cab-options-edit input[name="nRequisicao"]'));
  }

  private _setFocusObservacoes(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.cab-options-edit input[name="observacoes"]'));
  }

  private _setFocusCondPagamento(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.cab-options-edit input[name="nCondPag"]'));
  }

  private _setFocusDataInicTran(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('input[name="dataInicTran"]'));
  }

  private _setFocusCtRefExterna(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.cab-faturacao-publica-options-edit input[name="ctRefExterna"]'));
  }

  private _callEFaturacaoCirculacaoLogin(): Promise<boolean> {
    this.hasShownEFaturaCirculacaoModal = true;
    return new Promise<boolean>((resolve) => {
      const modalRef = this._cgModalService.showVanilla(EFaturaCirculacaoLoginModalComponent, {size: 'sm'});
      modalRef.result
        .then((responseEFaturaCirculacaoLogin) => {
          if (responseEFaturaCirculacaoLogin) {
            this._plAlertService.success(this._translateService.instant('efaturacirculacaologin.messages.success'));
            resolve(true);
          } else {
            resolve(false);
          }
        })
        .catch(() => {
          resolve(false);
        });
    });
  }

  private _isSameDate(a: TDate, b: TDate): boolean {
    // Checks day, month and year
    return a && b ? moment(a).isSame(b, 'day') : false;
  }

  private _docComercialEmEdicao(cab: IDocComercialCab): boolean {
    return (
      cab &&
      (cab.hashEstado === EFACCBHASHEstado.Indefinido ||
        cab.hashEstado === EFACCBHASHEstado.Rascunho ||
        ((cab.hashEstado === EFACCBHASHEstado.DocSemAssinatura || cab.hashEstado === EFACCBHASHEstado.DocAssinado) && !cab.terminado))
    );
  }

  private _docComercialSujeitoAssinatura(cab: IDocComercialCab): boolean {
    return (
      cab &&
      cab.faccbId > 0 &&
      (cab.hashEstado === EFACCBHASHEstado.DocAssinado ||
        cab.hashEstado === EFACCBHASHEstado.DocOrigemExterna ||
        cab.hashEstado === EFACCBHASHEstado.DocRecuperacao ||
        cab.hashEstado === EFACCBHASHEstado.DocImportado)
    );
  }

  private _sujeitoAssinatura(hashEstado: EFACCBHASHEstado): boolean {
    return (
      hashEstado === EFACCBHASHEstado.DocAssinado || hashEstado === EFACCBHASHEstado.DocOrigemExterna || hashEstado === EFACCBHASHEstado.DocRecuperacao || hashEstado === EFACCBHASHEstado.DocImportado
    );
  }

  private _evaluateClifoAction(clifo: IJsonDocComercialClifo): EDocsComerciaisClifoStatus {
    return clifo.nConta
      ? clifo.action === EDocsComerciaisClifoStatus.None
        ? clifo.nIdAltern > 0
          ? EDocsComerciaisClifoStatus.ExisteNifAlternativo
          : (clifo.action = EDocsComerciaisClifoStatus.Existe)
        : clifo.action
      : clifo.action === EDocsComerciaisClifoStatus.None
        ? EDocsComerciaisClifoStatus.ExisteNifAlternativo
        : clifo.action;
  }

  private _initNewDocFromCopia(cab: IDocComercialCab, clifo?: IJsonDocComercialClifo): void {
    if (this.type !== EEntityStateDetailType.NEW) {
      return;
    }

    cab.faccbId = 0;
    cab.nDocumento = 0;
    cab.nDocumentoDraft = 0;
    cab.terminado = false;
    cab.hashEstado = this._sujeitoAssinatura(cab.hashEstado) ? EFACCBHASHEstado.Rascunho : EFACCBHASHEstado.DocSemAssinatura;

    if (clifo) {
      clifo.action = EDocsComerciaisClifoStatus.ExisteNaoActualizaNaoValidaNif;
    }
    this.modelChanged();
  }

  private _arredondamentoManual(): Promise<void> {
    const instance = this._cgModalService.showVanilla(DocumentoArredondamentoManualModalComponent);
    instance.componentInstance.docComercial = this.model;
    return instance.result.then((result: Array<IJsonDocResumoIvaItem>) => {
      this.model.resumoIva = result;
      this.calc();
    });
  }

  private _setArredManualInfo(model: IDocComercial, translateService: TranslateService): string {
    let arredManualString = '';
    let arredManualTotalIva = 0;
    let arredManualTotalLiquido = 0;
    if (model.resumoIva.length > 0) {
      model.resumoIva.forEach((resumoIvaItem: IJsonDocResumoIvaItem) => {
        arredManualTotalLiquido += resumoIvaItem.arredLiquidoManual;
        arredManualTotalIva += resumoIvaItem.arredIvaManual;
      });
      if (arredManualTotalIva > 0 && arredManualTotalLiquido > 0) {
        arredManualString = translateService.instant('arredondamentoManual.messages.totalIvaLiquido', {totalIva: arredManualTotalIva, totalLiquido: arredManualTotalLiquido});
      } else if (arredManualTotalIva > 0) {
        arredManualString = translateService.instant('arredondamentoManual.messages.totalIva', {totalIva: arredManualTotalIva});
      } else if (arredManualTotalLiquido) {
        arredManualString = translateService.instant('arredondamentoManual.messages.totalLiquido', {totalLiquido: arredManualTotalLiquido});
      }
      this.modelChanged();
    }
    return arredManualString;
  }

  private _showBtnDiferimentos(): void {
    this.showBtnDiferimentos = this.model.cab.integraContabilidade && (this.model.cab.grupoDocfa === EGrupoDoc.VendasEfectivas || this.model.cab.grupoDocfa === EGrupoDoc.ComprasEfectivas);
  }

  private _importadorLinhas(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DocsComerciaisImportadorLinhasModalComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    return modalInstance.result.then((linhas: Array<IJsonDocComercialLinha>) => {
      this.model.linhas = linhas.map((linha: IJsonDocComercialLinha) => merge(this._emptyLine(), linha));
      this._changedLinhas();
      this._changed();
      this.addLine();
      this.calc();
    });
  }

  private _checkShowConfigOptions(): boolean {
    return !this.isTipoDocStocks || this.model.cab.nDocFa === INVENTARIO_INICIAL_NDOCFA_CODE;
  }

  private _getLinhaByRowElement(element: Element): IDocComercialLinha {
    const tableRow: Element = element.closest(SELECTOR_ROW);
    if (tableRow) {
      const index = Number(tableRow.attributes.getNamedItem('data-row-id').value);
      return this.model.linhas[index];
    }
    return undefined;
  }

  private _checkIsEntregaEfetivaSobreDocGlobal(): boolean {
    return this.docFa.saftSubTipoMovimento === ESubTipoMovimentoSAFT.EntregaEfectivaSobreDocumentoGlobal;
  }

  private _artigoChanged(artigo: IJsonArtigo, item: IDocComercialLinha): void {
    if (artigo) {
      this.stocks.cache.set(artigo.nArtigo, artigo);
      item.lotes = [];
      if (
        this.model.cab.integraStocks &&
        artigo?.temLote &&
        (artigo?.codvaloriz === CUSTO_MEDIO_PONDERADO || (artigo?.codvaloriz === FIFO && (this._grupoDoc.isCompra(this.group.grupo) || this._grupoDoc.isEntradaDiversa(this.group.grupo))))
      ) {
        item.qtd1 = 0;
      }
    }

    if (item.showTextoLivre) {
      this._updateTextosLivrosUIEditorForLine(item);
    }

    this._changed(false, item);
  }

  private async _applyAdiantamentosFromParams(): Promise<void> {
    const nDocFaEvaluate = this.model.cab.nDocFa !== this.docComercialStateParams.adiantamentos.adiantamento.nDocFa ? this.docComercialStateParams.adiantamentos.adiantamento.nDocFa : undefined;
    const nNumerEvaluate = this.model.cab.nNumer !== this.docComercialStateParams.adiantamentos.adiantamento.nNumer ? this.docComercialStateParams.adiantamentos.adiantamento.nNumer : undefined;
    const nContaEvaluate = this.model.cab.nConta !== this.docComercialStateParams.adiantamentos.adiantamento.nConta ? this.docComercialStateParams.adiantamentos.adiantamento.nConta : undefined;

    this.model.cab = {
      ...this.model.cab,
      nDocFa: this.docComercialStateParams.adiantamentos.adiantamento.nDocFa,
      nNumer: this.docComercialStateParams.adiantamentos.adiantamento.nNumer,
      nConta: this.docComercialStateParams.adiantamentos.adiantamento.nConta
    };

    await this._evaluateParamsCab(nDocFaEvaluate, nNumerEvaluate, nContaEvaluate);

    this.addLineAdiantamento(this.docComercialStateParams.adiantamentos.adiantamento, this.docComercialStateParams.adiantamentos.config);
  }

  private _evaluateNewLinesAdded(): void {
    this.showInfoStockArtigo = false;
    this.calcStock(null, false);
    this.paginateLinhas();
    this.evtDropdownActionsEvaluate.emit({id: MENU_ALT_CAMPOS_SERIE_ORDER, validator: this.model.linhas.length < 2});
    // this.dropdownActions.menu.find((item) => item.order === MENU_ALT_CAMPOS_SERIE_ORDER).disabled = this.model.linhas.length < 2;
    this.calc();
    this.addLine();
  }

  private _artigoFieldHighlightError(linha: IDocComercialLinha, addError: boolean): void {
    const rowElement: Element = document.querySelector(`tr[data-uid="${linha.uid}"]`);
    if (isDefinedNotNull(rowElement)) {
      const tdElement: Element = rowElement.querySelector('td.doccomercial-linha-artigo-td');
      if (isDefinedNotNull(tdElement)) {
        if (addError) {
          tdElement.classList.add('artigo-error');
        } else {
          tdElement.classList.remove('artigo-error');
        }
      }
    }
  }

  private _addDocFolder(name: string, parentfolderId: string): Promise<unknown> {
    const nDocDraft: string = this._generateNDoc(this.model.cab);
    const gdocFolder: Partial<IJsonGDFolder> = {
      parentId: parentfolderId,
      name: name,
      folderType: EFolderType.System,
      additionalParams: `CGUID=GestCom&CGUIDValue=${nDocDraft}&GestCom=${nDocDraft}&TipoEntityRef=${EDocTipoEntity.GestaoComercial}`
    };
    return this._gDocService
      .post({body: gdocFolder})
      .then((response) => {
        if (response) {
          this.gDocFolderId = response.body.folderId;
        }
      })
      .catch((reason) => {
        const exception = this._cgExceptionService.get(reason);
        if (exception) {
          this._plAlertService.error(exception.message);
        } else {
          this._plAlertService.error('docscomerciais.erros.naoCriouPastaDoc');
        }
      });
  }

  private _generateNDoc(cab: IJsonDocComercialCab): string {
    return !cab ? '' : `${cab.nDocFa}.${cab.nNumer}.${cab.nDocumentoDraft}`;
  }

  private _getGDocFolder(name: string, parentfolderId: string): Promise<unknown> {
    this.gDocFolderId = undefined;
    return this._gDocService
      .getFolderId(parentfolderId, name)
      .then((response) => {
        if (response?.body) {
          this.gDocFolderId = response.body.folderId;
        }
      })
      .catch((reason) => {
        const exception = this._cgExceptionService.get(reason);
        if (exception) {
          this._plAlertService.error(exception.message);
        }
      });
  }

  private _evaluateConfigOptionInvisible({key}: IDocComercialEvaluateConfigOptionInvisibleParams): boolean {
    return ['showDataDoc', 'showDocExterno'].includes(<string>key) && this.isComprasEfectivas;
  }

  private _perguntaSeFinalizaDoc(): Promise<void> {
    if (!this.naoPerguntaSeFinalizaDoc) {
      return this._cgModalService.showOkCancel('global.text.info', 'docscomerciais.modals.saveMessageOkCancel', {
        templateRefContent: this.templateContentPerguntaFinalizaDoc,
        size: 'md',
        icon: 'info',
        btnOkIcon: 'fa fa-fw fa-check-circle-o',
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no'
      });
    }
    return Promise.resolve();
  }

  private _obrigatorioMeiosPagamento(): Promise<IDocComercial> {
    if (this._obrigatorioMeiosPagam) {
      const modalInstance = this._cgModalService.showVanilla(DocumentoMeiosPagamentoModalComponent, {size: 'lg'});
      const componentInstance: DocumentoMeiosPagamentoModalComponent = modalInstance.componentInstance;
      componentInstance.doc = copy(this.model);
      componentInstance.codMoeda = this.codMoedaEmpresa;
      componentInstance.abreviaturaMoeda = this.abreviaturaMoedaEmpresa;
      componentInstance.emEdicao = true;
      return modalInstance.result;
    }
    return Promise.resolve(this.model);
  }

  private _afterEntitySave(doc: IDocComercial): void {
    this.lastDoc = doc;
    this.model = this._emptyDoc();
    this._changedLinhas();
    this.stocks.item = undefined;
    this._setDefaultTipoDoc();
    this._documentosService.clearStoredDocument(this.entityName);
    this.evtLastDocChanged.emit(this.lastDoc);
    this.evtModelChanged.emit(this.model);
    this.gDocFolderId = undefined;
    this.activeMainTab = this.mainTabLinhasId;
  }

  private _setComercialArquivoDigitalAttachment(gDocId: string, gDocFolderId: string, docOCRCabID: string): void {
    // this._contabilidadeDigitalChangedFolder = !this.comercialArquivoDigitalAttachment.gDocFolderId || this.comercialArquivoDigitalAttachment.gDocFolderId !== gDocFolderId;
    this.comercialArquivoDigitalAttachment = {gDocId: gDocId, gDocFolderId: gDocFolderId, docOCRCabID: docOCRCabID};
    this.evtComercialArquivoDigitalAttachmentChange.emit(this.comercialArquivoDigitalAttachment);
  }

  private async _evaluateParamsCab(nDocFa: number, nNumer: number, nConta: string): Promise<void> {
    if (!isEmpty(nDocFa)) {
      const docFaService: IEntityService<IJsonDocfa> = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
      await docFaService.get({id: nDocFa}).then((responseNDocFa: HttpResponse<IJsonDocfa>) => {
        this.onDocFaChanged(responseNDocFa.body);
      });
    }

    if (!isEmpty(nConta)) {
      const clifosService: IEntityService<IJsonDocComercialClifo> = this._entityServiceBuilder.build(this.clifoEntName);
      await clifosService.get({id: nConta}).then((responseClifo: HttpResponse<IJsonDocComercialClifo>) => {
        return this._onClifoNifValidate(responseClifo.body.nContribuint, responseClifo.body).then(() => {
          this.onClifoNifSelected(responseClifo.body);
        });
      });
    }

    if (!isEmpty(nNumer)) {
      const nNumerService: IEntityService<IJsonDocfaNum> = this._entityServiceBuilder.build(ENTITY_NAME_DOCFAS_NUMS);
      await nNumerService.get({id: nNumer}).then((responseNNUmer: HttpResponse<IJsonDocfaNum>) => {
        return this._onNNumerValidate(String(nNumer), responseNNUmer.body);
      });
    }
  }

  private _showModalDevolucoes(facliId: number = 0): Promise<void> {
    if (this.model.linhas?.length <= 1 && this.model.cab.faccbId === 0) {
      const nArtigo = this.model.linhas?.length === 1 && this.model.linhas[0].nArtigo !== '' ? this.model.linhas[0].nArtigo : '';
      if (this.model.linhas?.length === 1) {
        this.model.linhas = [];
      }
      this._documentosService.postDocSemLinhas(this.model).then((response) => {
        if (response.body) {
          const modalRef = this._cgModalService.showVanilla(DevolucoesModalComponent, {size: 'xxl'});
          const componentInstance: DevolucoesModalComponent = modalRef.componentInstance;
          componentInstance.modoEdicao = false;
          componentInstance.facliIdLinhaEdicao = 0;
          componentInstance.nArtigo = nArtigo;
          componentInstance.doc = response.body;
          modalRef.result.then((responseAux: IJsonDocComercial) => {
            if (response) {
              const stateParams: IDocComercialEditStateParams = {id: responseAux.cab.faccbId, doc: responseAux};
              stateParams[entityStateDetailQueryParam(this.entityName)] = EEntityStateDetailType.EDIT;
              this._stateService.go(this.statesDetailName, stateParams);
            }
          });
        }
      });
    } else {
      const modalRef = this._cgModalService.showVanilla(DevolucoesModalComponent, {size: 'xxl'});
      const componentInstance: DevolucoesModalComponent = modalRef.componentInstance;
      componentInstance.modoEdicao = facliId !== 0;
      componentInstance.facliIdLinhaEdicao = facliId;
      componentInstance.doc = this.model;
      modalRef.result.then((response: IJsonDocComercial) => {
        if (response) {
          this.model = merge(this.model, response);
          this._changedLinhas();
          this._changed();
        }
      });
    }
    return Promise.resolve();
  }

  private _updateTextosLivrosUIEditorForLine(item: IDocComercialLinha): void {
    let needRequest = false;
    item.textosLivresClifo = [];
    item.textosLivresArtigo = [];
    if (this.model.cab.nConta.length || item.nArtigo.length) {
      if (this.model.cab.nConta.length) {
        const cacheClifo = this._textosLivresClifo.get(this.model.cab.nConta);
        if (isArray(cacheClifo)) {
          item.textosLivresClifo = cacheClifo;
        } else {
          needRequest = true;
        }
      }

      if (item.nArtigo.length) {
        const cacheArtigo = this._textosLivresArtigo.get(item.nArtigo);
        if (isArray(cacheArtigo)) {
          item.textosLivresArtigo = cacheArtigo;
        } else {
          needRequest = true;
        }
      }

      if (needRequest) {
        this._documentosService.obterTextosLivres(this.model.cab.nConta, item.nArtigo).then((response) => {
          item.textosLivresClifo = response.body.clifo;
          item.textosLivresArtigo = response.body.artigo;
          if (this.model.cab.nConta.length) {
            this._textosLivresClifo.set(this.model.cab.nConta, response.body.clifo);
          }
          if (item.nArtigo.length) {
            this._textosLivresArtigo.set(item.nArtigo, response.body.artigo);
          }
        });
      }
    }
  }

  // private _evaluateContabilidadeDigitalDoc(): void {
  // this.comercialDigitalDoc = {
  //   extPocCabID: this.model.extPocCabID,
  //   periodo: this.model.periodo,
  //   nDiario: this.model.nDiario,
  //   nDocInterno: this.model.nDocInterno,
  //   dataDoc: this.model.dataDoc
  // };
  // }
}
