export enum EPlDashboardType {
  Table,
  Tile
}

export interface IPlDashboardDataType {
  [EPlDashboardType.Table]: Array<object>;
  [EPlDashboardType.Tile]: string | number;
}

export interface IPlDashboard<TType extends EPlDashboardType> {
  type: TType;
  title: string;
  data: IPlDashboardDataType[TType];
  subtitle?: string;
  icon?: string;
  style?: string | Array<string>;
}

export interface IPlDashboardTableKey {
  keyName: string;
  formatRight: boolean;
}
