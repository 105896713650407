import type {Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {AfterViewInit, Directive, ElementRef, Injector, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import type {IPlPositioning, TPlPositioningPlacementArray} from '../../../positioning/positioning.interface';
import {isBoolean} from '../../../common/utilities/utilities';
import {plAutoClose} from '../../../autoclose/autoclose';
import {PlDynamicVisualsActiveReference} from '../../stacks/stack/reference/dynamicvisuals.active.reference';
import {positioning} from '../../../positioning/positioning';
import type {TPlAutoCloseType} from '../../../autoclose/autoclose.interface';
import {Logger} from '../../../logger/logger';

@Directive()
export abstract class PlDynamicVisualsAbstractComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public hostElement: HTMLElement;
  @Input() public autoClose: TPlAutoCloseType;
  @Input() public placement: TPlPositioningPlacementArray;
  @Input() public persistent: boolean;

  protected readonly _logger: Logger;
  protected readonly _ngZone: NgZone;
  protected readonly _document: Document;
  protected readonly _elementRef: ElementRef<HTMLElement>;
  protected readonly _plDynamicVisualsActiveReference: PlDynamicVisualsActiveReference;
  protected readonly _element: HTMLElement;
  protected readonly _positioning: IPlPositioning;
  protected _subscriptionZone: Subscription;

  protected constructor(protected readonly _injector: Injector) {
    this._logger = this._injector.get<Logger>(Logger);
    this._ngZone = this._injector.get<NgZone>(NgZone);
    this._document = this._injector.get<Document>(DOCUMENT);
    this._elementRef = this._injector.get<ElementRef<HTMLElement>>(ElementRef);
    this._plDynamicVisualsActiveReference = this._injector.get<PlDynamicVisualsActiveReference>(PlDynamicVisualsActiveReference);
    this._positioning = positioning();
    this._element = this._elementRef.nativeElement;
    this.close = this.close.bind(this);
  }

  public ngOnInit(): void {
    if (!isBoolean(this.persistent)) {
      this.persistent = false;
    }
  }

  public ngOnDestroy(): void {
    this._clearSubscriptionZone();
  }

  public ngAfterViewInit(): void {
    this._ngZone.runOutsideAngular(() => {
      this._positioning.createPopper({
        hostElement: this.hostElement,
        targetElement: this._element,
        placement: this.placement || ['bottom-start', 'bottom-end', 'top-start', 'top-end'],
        appendToBody: true
      });
      this._clearSubscriptionZone();
      this._subscriptionZone = this._ngZone.onStable.pipe(takeUntil(this._plDynamicVisualsActiveReference.closed())).subscribe({
        next: () => {
          this._positionElement();
        },
        error: (error: unknown) => {
          this._logger.error(error);
          this._positioning.destroy();
        },
        complete: () => {
          this._positioning.destroy();
        }
      });
    });
    this._setupAutoClose();
  }

  public close(): void {
    this._plDynamicVisualsActiveReference.close();
  }

  protected _positionElement(): void {
    if (!this.hostElement || !this._document.contains(this.hostElement)) {
      if (!this.persistent) {
        this.close();
      }
      return;
    }
    this._positioning.update();
  }

  protected _setupAutoClose(): void {
    plAutoClose(this._ngZone, this._document, this.autoClose, this.close, [this._element]);
  }

  private _clearSubscriptionZone(): void {
    if (this._subscriptionZone) {
      this._subscriptionZone.unsubscribe();
      this._subscriptionZone = undefined;
    }
  }
}
