<div class="entity-detail-form">
  <pl-form>
    <div class="row">
      <pl-group class="not-print col-sm-4">
        <label [translate]="'listagemMenus.form.local'"></label>
        <edit>
          <entity-autocomplete
            entity="prhlocais"
            edit-class="input-sm"
            [model]="local"
            (modelChange)="fillPresencaListByLocal($event)"
            [selectedKey]="local.codLocal"
            (evtSelectedDescriptionChanged)="local.nome = $event">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group class="not-print col-sm-4">
        <label [translate]="'listagemMenus.form.de'"></label>
        <edit>
          <pl-edit type="date" attr-name="data" [model]="dataDe" (modelChange)="fillPresencaListByData($event)" input-class="input-sm"></pl-edit>
        </edit>
      </pl-group>

      <pl-group class="not-print col-sm-4">
        <label></label>
        <edit>
          <pl-button klass="btn-outline btn-primary btn-sm" (evtClicked)="fillPresencaListByLocal(local)">
            <i class="fa fa-fw fa-search"></i> {{ 'listagemMenus.form.btns.pesquisar' | translate }}
          </pl-button>

          <pl-button klass="btn-primary btn-sm" (evtClicked)="imprimir()"><i class="fa fa-fw fa-print"></i> {{ 'listagemMenus.form.btns.imprimir' | translate }}</pl-button>
        </edit>
      </pl-group>
    </div>
  </pl-form>
</div>

<div class="print-header-margin listagem-menus-ementa">
  <div class="visible-print">
    <h2 [translate]="'listagemMenus.title.ementaSemanal'"></h2>
    <p>
      <strong> <span [translate]="'listagemMenus.title.local'"></span>:</strong> {{ local.nome }}
    </p>
  </div>
  <div class="cal-slide-box" uib-collapse="isCollapsed" mwl-collapse-fallback="isCollapsed" aria-expanded="true" aria-hidden="false">
    <div class="cal-slide-content cal-event-list">
      <dx-data-grid [cgDxDataGrid]="definition" [dataSource]="listagemArray"></dx-data-grid>
    </div>
  </div>
</div>
