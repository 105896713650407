import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {isDefinedNotNull, PlAlertService} from 'pl-comps-angular';
import {CGExceptionService} from '../../../../../components/exceptions/exceptions.service';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ConciliacaoBancariaService} from '../../conciliacaoBancaria.module.service';
import {DATA_SOURCE_DEBITO_CREDITO} from '../../../../../datasources/debitocredito/debitoCredito.datasource';
import {EDebitoCredito} from '../../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {ICGExceptionError} from '../../../../../components/exceptions/exceptions.service.interface';
import {IConciliacaoBancariaAddEditLinhaExtratoCGModal, IConciliacaoBancariaExtPocItem, IRestCmdConciliacaoBancariaNovaLinhaExtratoCG} from '../../conciliacaoBancaria.module.interface';
import {IDataSourceItem} from '../../../../../components/datasource/datasources.interface';
import {TDate} from '../../../../../../common/dates';

@Component({
  selector: 'conciliacaoBancaria-add-linha-extrato-cg-modal',
  templateUrl: './conciliacaoBancaria.addLinhaExtratoCG.modal.component.html'
})
export class ConciliacaoBancariaAddLinhaExtratoCGModalComponent extends CGModalComponent<IConciliacaoBancariaExtPocItem> implements OnInit {
  @Input() public nConta: string;
  @Input() public periodo: string;
  @Input() public editModel: IConciliacaoBancariaExtPocItem;
  @Input() public date: TDate;

  public readonly tipoMovimentoItems: ReadonlyArray<IDataSourceItem<EDebitoCredito>>;
  public model: IConciliacaoBancariaAddEditLinhaExtratoCGModal;
  public modalTitle: string;
  public btnSaveCaption: string;
  public promise: Promise<void>;

  private _isEditMode: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _conciliacaoBancariaService: ConciliacaoBancariaService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.tipoMovimentoItems = DATA_SOURCE_DEBITO_CREDITO.data;
    this.btnSaveCaption = 'global.btn.create';
  }

  public ngOnInit(): void {
    this._isEditMode = isDefinedNotNull(this.editModel);
    if (this._isEditMode) {
      this.btnSaveCaption = 'global.btn.save';
      this.modalTitle = 'conciliacaoBancaria.editLinhaPrimeiraConcilExtratoCGModalTitle';
      this.model = {
        nLanc: this.editModel.nLanc,
        descricao: this.editModel.descricao,
        valor: this.editModel.valor,
        dc: this.editModel.dc,
        data: this.editModel.data
      };
    } else {
      this.modalTitle = 'conciliacaoBancaria.addLinhaPrimeiraConcilExtratoCGModalTitle';
      this.model = {descricao: '', data: this.date, dc: EDebitoCredito.Credito, valor: 0};
    }
  }

  public close(): Promise<void> {
    if (this.model.valor <= 0) {
      this._plAlertService.error('conciliacaoBancaria.oValorMovimentoNaoPodeSer0ouInferior');
      return Promise.resolve();
    }
    this.disableClose();
    this.promise = this._conciliacaoBancariaService
      .addEditLinhaExtratoCG(this.model, this.nConta, this.periodo)
      .then((response: IRestCmdConciliacaoBancariaNovaLinhaExtratoCG) => {
        this.enableClose();
        super.close(response.extPocItem);
      })
      .catch((reason: unknown) => {
        this.enableClose();
        if (reason instanceof HttpErrorResponse) {
          const exception: ICGExceptionError = this._cgExceptionService.get(reason);
          this._plAlertService.error(exception.message);
        }
      });
    return this.promise;
  }
}
