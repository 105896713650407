import moment from 'moment';
import {EnvironmentProviders} from '@angular/core';
import {provideServiceWorker as ngProvideServiceWorker, SwRegistrationOptions} from '@angular/service-worker';
import {ENVIRONMENT} from '../../environments/environment';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const SERVICE_WORKER_DELAY: number = moment.duration(5, 'seconds').asMilliseconds();

const SERVICE_WORKER_FILE = 'ngsw-worker.js';

const SERVICE_WORKER_CONFIG: SwRegistrationOptions = {
  enabled: ENVIRONMENT.production,
  // Register the ServiceWorker as soon as the app is stable
  // or after 30 seconds (whichever comes first).
  registrationStrategy: `registerWithDelay:${SERVICE_WORKER_DELAY}`
};

export function provideServiceWorker(): EnvironmentProviders {
  return ngProvideServiceWorker(SERVICE_WORKER_FILE, SERVICE_WORKER_CONFIG);
}
