import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../../components/cg/modal/cgmodal.component';
import {IJsonModelo22, IJsonModelo22Prejuizos, IJsonModelo22PrejuizosFiscais, IJsonModelo22PrejuizosFiscaisDeducoes} from '../../../../../jsonModelo22.module.interface';
import {Modelo22Service} from '../../../../../modelo22.module.service';
import {IDevExpressDataGrid} from '../../../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'modal-modelo22-prejuizosfiscais',
  templateUrl: './modelo22.prejuizosFiscais.modal.component.html'
})
export class Modelo22PrejuizosFiscaisModalComponent extends CGModalComponent<IJsonModelo22> implements OnInit {
  @Input() public prejuizosFiscais: Array<IJsonModelo22PrejuizosFiscais>;
  @Input() public modelo22: IJsonModelo22;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly dataGridDefinitionDetail: IDevExpressDataGrid;

  public promise: Promise<void>;
  public anoPrejuizo: number;
  public valPrejuizo: number;
  public anoDeducao: number;
  public valDeducao: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'ano', dataType: 'number', caption: 'modelo22.modal.prejuizosfiscais.table.ano'},
        {dataField: 'valorPrejuizoFiscal', dataType: 'double', caption: 'modelo22.modal.prejuizosfiscais.table.valorPrejuizoFiscal'},
        {dataField: 'valorDeduzido', dataType: 'double', caption: 'modelo22.modal.prejuizosfiscais.table.valorDeduzido'},
        {dataField: 'valorPorDeduzir', dataType: 'double', caption: 'modelo22.modal.prejuizosfiscais.table.valorPorDeduzir'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnPrejFiscas'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.modal.rosto.contasdef.title'},
      keyExpr: 'ano',
      masterDetail: {enabled: true, template: 'masterDetailTemplatePrejuizosFiscais'},
      remoteOperations: false
    };
    this.dataGridDefinitionDetail = {
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      columns: [
        {dataField: 'anoDeducao', dataType: 'number', caption: 'modelo22.modal.prejuizosfiscais.table.anoDeducao'},
        {dataField: 'valorDeducao', dataType: 'double', caption: 'modelo22.modal.prejuizosfiscais.table.valorDeducao'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnDeducoes'}
      ],
      dataSource: [],
      export: {enabled: false},
      keyExpr: 'anoDeducao',
      remoteOperations: false
    };
    this.anoPrejuizo = undefined;
    this.valPrejuizo = 0;
    this.anoDeducao = undefined;
    this.valDeducao = 0;
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = this.prejuizosFiscais;
  }

  public close(): Promise<void> {
    const prejuizos: IJsonModelo22Prejuizos = {
      prejuizos: this.prejuizosFiscais,
      modelo22: this.modelo22
    };
    this.promise = this._modelo22Service.postPrejuizosFiscais(prejuizos).then((response: HttpResponse<IJsonModelo22>) => {
      super.close(response.body);
    });
    return this.promise;
  }

  public addLinePrejuizo(): void {
    if (this.prejuizosFiscais.findIndex((value: IJsonModelo22PrejuizosFiscais) => value.ano === this.anoPrejuizo) !== -1) {
      this._plAlertService.error('modelo22.messages.prejuizoAlredyExists');
      return;
    }
    this.prejuizosFiscais.push({
      ano: this.anoPrejuizo,
      valorPrejuizoFiscal: this.valPrejuizo,
      deducoes: [],
      valorDeduzido: 0,
      valorPorDeduzir: 0
    });
    this.dataGridDefinition.dataSource = this.prejuizosFiscais;
  }

  public addLineDeducao(prej: IJsonModelo22PrejuizosFiscais): void {
    if (this.anoDeducao <= prej.ano) {
      this._plAlertService.error('modelo22.messages.anoDedInv');
      return;
    }

    const indexPrej = this.prejuizosFiscais.findIndex((value: IJsonModelo22PrejuizosFiscais) => value.ano === prej.ano);
    if (indexPrej !== -1) {
      const index = this.prejuizosFiscais[indexPrej].deducoes.findIndex((value: IJsonModelo22PrejuizosFiscaisDeducoes) => value.anoDeducao === this.anoDeducao);
      if (index !== -1) {
        this.prejuizosFiscais[indexPrej].deducoes[index].valorDeducao = this.valDeducao;
      } else {
        this.prejuizosFiscais[indexPrej].deducoes.push({
          anoDeducao: this.anoDeducao,
          valorDeducao: this.valDeducao,
          anoPrejuizoFiscal: this.prejuizosFiscais[indexPrej].ano,
          prejuizosFiscaisDeID: ''
        });
      }
      this.anoDeducao = undefined;
      this.valDeducao = 0;
      this._calcDeducoes(this.prejuizosFiscais[indexPrej]);
      this.dataGridDefinition.dataSource = this.prejuizosFiscais;
    }
  }

  public removeLinePrejuizos(item: IJsonModelo22PrejuizosFiscais): void {
    const index = this.prejuizosFiscais.findIndex((value: IJsonModelo22PrejuizosFiscais) => item.ano === value.ano);
    if (index !== -1) {
      this.prejuizosFiscais.splice(index, 1);
    }
    this.dataGridDefinition.dataSource = this.prejuizosFiscais;
  }

  public removeLineDeducoes(prej: IJsonModelo22PrejuizosFiscais, item: IJsonModelo22PrejuizosFiscaisDeducoes): void {
    const indexPrej = this.prejuizosFiscais.findIndex((value: IJsonModelo22PrejuizosFiscais) => value.ano === prej.ano);
    if (indexPrej !== -1) {
      const index = this.prejuizosFiscais[indexPrej].deducoes.findIndex((value: IJsonModelo22PrejuizosFiscaisDeducoes) => item.anoDeducao === value.anoDeducao);
      if (index !== -1) {
        this.prejuizosFiscais[indexPrej].deducoes.splice(index, 1);
      }
      this._calcDeducoes(this.prejuizosFiscais[indexPrej]);
      this.dataGridDefinition.dataSource = this.prejuizosFiscais;
    }
  }

  private _calcDeducoes(prej: IJsonModelo22PrejuizosFiscais): void {
    let totalDeducoes = 0;
    for (const deducao of prej.deducoes) {
      totalDeducoes += deducao.valorDeducao;
    }
    prej.valorDeduzido = totalDeducoes;
    prej.valorPorDeduzir = prej.valorPrejuizoFiscal - totalDeducoes;
  }
}
