import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IJsonLote} from '../../jsonLote.entity.interface';
import {ConfigOptionsService} from '../../../../services/config/options/config.options.service';
import {EConfigOptionsInstanceName, ILotesListConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../../config/constants';
import {IConfigOptionsInstance} from '../../../../services/config/options/config.options.instance.interface';
import {Subscription} from 'rxjs';
import {TEntityListBeforeRequestFn, TOOLBAR_ENTITY_LIST_BTN_FILTER, TOOLBAR_ENTITY_LIST_BTN_REFRESH} from '../../../../components/entity/list/entity.list.component.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {ENTITY_NAME_LOTES} from '../../lotes.entity.interface';
import {LotesService} from '../../lotes.entity.service';
import {IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {PlTranslateService} from 'pl-comps-angular';
import {IEntityServiceQueryRequestConfig} from '../../../../services/entity/entity.service.interface';

const FILTER_TERMINADOS = 'terminado=1';
const FILTER_NAO_TERMINADOS = 'terminado=0';

@Component({
  selector: 'lotes-list',
  templateUrl: './lotes.entity.list.component.html'
})
export class LotesListComponent extends ModuloEntityListComponent<IJsonLote> implements OnInit, OnDestroy {
  @Input() public nArtigo: string;
  @Input() public showAtualizar: boolean;
  @Input() public showFiltro: boolean;
  @Input() public dataGridCellClickBlocked: boolean;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  private _subscriptionConfigOptions: Subscription;
  private _optionShowSoTerminados: boolean;
  private _maintenanceLotes: IEntityMaintenanceInstance;
  private _filterValue: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _configOptionsService: ConfigOptionsService,
    private readonly _lotesService: LotesService,
    private readonly _cgModalService: CGModalService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);

    this.configOptionsGroupName = EGroupName.ERP;
    this.configOptionsInstanceName = EConfigOptionsInstanceName.LOTES_LIST;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => {
      this.toolbar.find(TOOLBAR_ENTITY_LIST_BTN_REFRESH).visible = this.showAtualizar ? this.showAtualizar : true;
      this.toolbar.find(TOOLBAR_ENTITY_LIST_BTN_FILTER).visible = this.showFiltro ? this.showFiltro : true;
      if (this.dataGridCellClickBlocked) {
        this.entityListComponent.evtDataGridCellClick.unsubscribe();
      }
    });

    const configOptionsInstance: IConfigOptionsInstance<boolean, ILotesListConfigOptions> = this._configOptionsService.getGroupOptions(this.configOptionsGroupName).get(this.configOptionsInstanceName);
    this._subscriptionConfigOptions = configOptionsInstance.options().subscribe((configOptions: TConfigOptions<boolean, ILotesListConfigOptions>) => {
      this._optionShowSoTerminados = configOptions.get('showSoTerminados').value;
      this._filterValue = this.nArtigo ? `nArtigo=${this.nArtigo}&` : '';
      if (this._optionShowSoTerminados) {
        this._filterValue += FILTER_TERMINADOS;
      } else {
        this._filterValue += FILTER_NAO_TERMINADOS;
      }
      this.refreshList();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._configOptionsService.getGroupOptions(this.configOptionsGroupName).get(this.configOptionsInstanceName).setDefaultOptions();
    if (this._subscriptionConfigOptions) {
      this._subscriptionConfigOptions.unsubscribe();
    }
  }

  public onDataGridColumnsPreparing(fields: Array<IDevExpressDataGridColumn>): void {
    for (const field of fields) {
      if (field.dataField === 'nArtigo') {
        field.visible = !this.dataGridCellClickBlocked;
        field.showInColumnChooser = !this.dataGridCellClickBlocked;
      }
      if (field.dataField === 'nomeArtigo') {
        field.visible = !this.dataGridCellClickBlocked;
        field.showInColumnChooser = !this.dataGridCellClickBlocked;
      }
    }
  }

  public actionEditar(lote?: IJsonLote): Promise<void> {
    if (!this._maintenanceLotes) {
      this._maintenanceLotes = this._entityMaintenanceService.build(ENTITY_NAME_LOTES);
    }

    return this._maintenanceLotes
      .maintenanceEdit(lote.nLote)
      .then(() => {
        this.refreshList();
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
      });
  }

  public actionTerminar(lote?: IJsonLote): Promise<void> {
    return this._cgModalService
      .showOkCancel('lotes.okCancel.terminar.title', this._plTranslateService.translate('lotes.okCancel.terminar.message', {lote: lote.nLoteEspecifico, nartigo: lote.nArtigo}), {
        size: 'md',
        icon: 'warning',
        btnOkIcon: 'fa-lock',
        btnOkText: 'lotes.list.actions.terminar'
      })
      .then(() => {
        return this._lotesService
          .terminarLote(lote.nLote)
          .then(() => {
            this.refreshList();
          })
          .catch((reason: unknown) => {
            this._logger.error(reason);
          });
      });
  }

  public readonly fnBeforeRequest: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequest(queryRequestConfig);

  private _beforeRequest(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    if (!queryRequestConfig.pesquisa) {
      queryRequestConfig.pesquisa = '';
    } else {
      queryRequestConfig.pesquisa += '&';
    }
    queryRequestConfig.pesquisa += this._filterValue;
    return queryRequestConfig;
  }
}
