import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'modal-avisos-cobranca-email',
  templateUrl: './avisosCobranca.alterarEmailPontual.modal.component.html'
})
export class AvisosCobrancaAlterarEmailPontualModalComponent extends CGModalComponent<string> {
  @Input() public email: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public close(): void {
    super.close(this.email);
  }
}
