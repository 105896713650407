import moment from 'moment';
import {isEqual} from 'lodash-es';
import ArrayStore from 'devextreme/data/array_store';
import dxDataGrid, {ToolbarItem} from 'devextreme/ui/data_grid';
import {Properties as ButtonProperties} from 'devextreme/ui/button';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {HookMatchCriteria, StateDeclaration, StateObject, Transition, TransitionService} from '@uirouter/core';
import {copy, isEmpty, PlAlertService} from 'pl-comps-angular';
import {ApiService} from '../../../services/api/api.service';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {EMBEmissorasSTR} from '../mbConfigs.module.interface';
import {hasAuthority} from '../../../../common/utils/roles.utils';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonConfigEmissora, IJsonConfigEmissoraDocFa} from '../jsonMBConfigs.module.interface';
import {isTest} from '../../../../config/constants';
import {MBConfigsAddTipoDocModalComponent} from '../modals/addtipodoc/mbConfigs.addTipoDoc.modal.component';
import {MBConfigsService} from '../mbConfigs.module.service';
import {ModuloComponent} from '../../../components/module/module.component';
import {ROLE} from '../../../services/role.const';
import {STATE_NAME_DISCONNECTED} from '../../../states/account/disconnected/disconnected.state.interface';
import {STATE_NAME_LOGIN} from '../../../states/account/login/login.state.interface';

const EMISSORA_EASYPAY_ONLINE = '11683';
const BTN_ADD_DOCFA_TOOLBAR_NAME = 'btnAddDocFa';
const SEEK_ATTENTION_TIMER = 1000;

@Component({
  selector: 'module-mb-configs',
  templateUrl: './mbConfigs.module.component.html'
})
export class MBConfigsModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public mbConfigs: IJsonConfigEmissora;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly emissora: EMBEmissorasSTR;
  public readonly entidade: string;
  public mbConfigsInitial: IJsonConfigEmissora;
  public selectedRowKeys: Array<number>;
  public advancedMode: boolean;
  public showAdvancedMode: boolean;
  public captionEstado: string;
  public seekAttention: boolean;

  private _deRegisterOnStartFn: Function;
  private _firstTime: boolean;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _mbConfigsService: MBConfigsService,
    private readonly _cgModalService: CGModalService,
    private readonly _transitionService: TransitionService,
    private readonly _apiService: ApiService
  ) {
    super(_injector);
    this.emissora = EMBEmissorasSTR.EasyPayOnline;
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [{dataField: 'internalDesc', dataType: 'string', caption: 'mbconfigs.fields.internalDesc'}],
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'after',
            name: BTN_ADD_DOCFA_TOOLBAR_NAME,
            widget: 'dxButton',
            options: {
              icon: 'add',
              hint: this._translateService.instant('mbconfigs.btn.addTiposDoc'),
              onClick: () => this._addTipoDocumento()
            } satisfies ButtonProperties
          },
          {
            location: 'before',
            template: 'toolbarGridTemplate',
            locateInMenu: 'auto'
          }
        ]
      }
    };
    this.entidade = EMISSORA_EASYPAY_ONLINE;
    this._firstTime = true;
    this.testCredentials = this.testCredentials.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'save',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-save fa-fw"></i>',
      class: 'btn-primary',
      caption: 'global.btn.save',
      click: () => this._saveConfigsMB()
    });
    this.showAdvancedMode = hasAuthority(this.session, ROLE.CONTABILIDADE);
    this.advancedMode = this.showAdvancedMode;
    this._applyDataSourceGrid();
    this.captionEstado = this.mbConfigs.ativo ? this._translateService.instant('global.text.active') : this._translateService.instant('mbconfigs.fields.inativo');

    this.mbConfigs.dataInicioRef = moment();
    this.mbConfigsInitial = copy(this.mbConfigs);

    setTimeout(() => {
      this._registerOnStart();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._deRegisterOnStart();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
    this._evaluateDataGridAddDocFasVisibility();
  }

  public onContentReady(): void {
    if (this._firstTime) {
      this.selectedRowKeys = this.mbConfigs.docFaList.filter((emissora: IJsonConfigEmissoraDocFa) => emissora.selected).map((emissora: IJsonConfigEmissoraDocFa) => emissora.nDocFa);
      this._firstTime = false;
    }
    this._dataGridInstance.endCustomLoading();
  }

  public ativoChanged(): void {
    this.captionEstado = this.mbConfigs.ativo ? this._translateService.instant('global.text.active') : this._translateService.instant('mbconfigs.fields.inativo');
    if (this.mbConfigs.ativo) {
      this.seekAttention = true;
      setTimeout(() => {
        this.seekAttention = false;
      }, SEEK_ATTENTION_TIMER);
    }
    this._dataGridInstance.clearFilter();
    this.mbConfigs = copy(this.mbConfigs);
    this._evaluateDataGridAddDocFasVisibility();
  }

  public testCredentials(): Promise<void> {
    return this._mbConfigsService.testCredentials(this.emissora, this.mbConfigs).then(() => {
      this._plAlertService.success('As credênciais introduzidas são válidas');
    });
  }

  private _saveConfigsMB(): Promise<void> {
    if (isEmpty(this.mbConfigs.apiId) || isEmpty(this.mbConfigs.apiKey)) {
      this._plAlertService.error('mbconfigs.messages.loginempty');
      return Promise.resolve();
    }

    const mbConfigs: IJsonConfigEmissora = {...this.mbConfigs};
    mbConfigs.notificationEndpoint = this._apiService.path.host;

    if (!this.advancedMode) {
      mbConfigs.ncontaTransferencia = undefined;
      mbConfigs.ncontaTransTransf = undefined;
      mbConfigs.nDiario = undefined;
      mbConfigs.nDescritivo = undefined;
      mbConfigs.nomeContaTransferencia = undefined;
      mbConfigs.nomeDiario = undefined;
      mbConfigs.nomeDescritivo = undefined;
    }

    for (const item of mbConfigs.docFaList) {
      item.selected = this.selectedRowKeys.findIndex((docFa: number) => docFa === item.nDocFa) !== -1;
    }

    return this._mbConfigsService.postMBConfigs(this.emissora, mbConfigs).then((response: HttpResponse<IJsonConfigEmissora>) => {
      this.mbConfigs = response.body;
      this.mbConfigsInitial = copy(this.mbConfigs);
      this._firstTime = true;
      this._applyDataSourceGrid();
      this._plAlertService.success('mbconfigs.messages.configsavedsucess');
    });
  }

  private _addTipoDocumento(): Promise<void> {
    return this._mbConfigsService.getTiposDocList().then((result: HttpResponse<Array<IJsonConfigEmissoraDocFa>>) => {
      const modalInstance = this._cgModalService.showVanilla(MBConfigsAddTipoDocModalComponent);
      const componentInstance: MBConfigsAddTipoDocModalComponent = modalInstance.componentInstance;
      componentInstance.tiposDocList = result.body;
      return modalInstance.result.then((tiposDoc: Array<IJsonConfigEmissoraDocFa>) => {
        for (const doc of tiposDoc) {
          if (this.mbConfigs.docFaList.findIndex((value: IJsonConfigEmissoraDocFa) => value.nDocFa === doc.nDocFa) === -1) {
            this.mbConfigs.docFaList.push(doc);
          }
        }
        this._firstTime = true;
        this._applyDataSourceGrid();
      });
    });
  }

  private _applyDataSourceGrid(): void {
    this.mbConfigs.nDiario = !this.mbConfigs.nDiario ? undefined : this.mbConfigs.nDiario;
    this.mbConfigs.nDescritivo = !this.mbConfigs.nDescritivo ? undefined : this.mbConfigs.nDescritivo;
    this.dataGridDefinition.dataSource = new ArrayStore({key: 'nDocFa', data: this.mbConfigs.docFaList});
  }

  private _navigationSafeGuard(): Promise<void> {
    if (!isTest()) {
      if (!isEqual(copy(this.mbConfigs), this.mbConfigsInitial)) {
        return this._cgModalService.showOkCancel('global.prompt.exitTitle', 'global.prompt.exitMessage');
      }
    }
    return Promise.resolve();
  }

  private _registerOnStart(): void {
    this._deRegisterOnStart();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_LOGIN && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFn = this._transitionService.onStart(criteria, () => this._navigationSafeGuard());
  }

  private _deRegisterOnStart(): void {
    if (this._deRegisterOnStartFn) {
      this._deRegisterOnStartFn();
      this._deRegisterOnStartFn = undefined;
    }
  }

  private _evaluateDataGridAddDocFasVisibility(): void {
    const addRowButtonIndex: number = this.dataGridDefinition.toolbar.items.findIndex((toolbarItem: ToolbarItem) => toolbarItem.name === BTN_ADD_DOCFA_TOOLBAR_NAME);
    this._dataGridInstance.option(`toolbar.items[${addRowButtonIndex}].visible`, this.mbConfigs.ativo);
  }
}
