import {Component, Injector, Input, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {isDefinedNotNull} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {GtoConfigModalComponent} from '../modals/config/gto.config.modal.component';
import {GtoCopyTasksModalComponent} from '../modals/copytasks/gto.copyTasks.modal.component';
import {GtoCreateEditTaskModalComponent} from '../modals/createedittask/gto.createEditTask.modal.component';
import {IGTOConfigModalResult, IGTOResponsavel, IGTOSingleFilters, IGTOTabCallback, IGTOTabMultiCallback, IGTOTarefaMultiRow} from '../gto.module.interface';
import {IJsonGTOLicenseInfo} from '../jsonGTO.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment from 'moment';

const TAB_ID_POR_EMPRESA = 'porEmpresa';
const TAB_ID_MULTI_EMPRESA = 'multiEmpresa';
const NUMBER_3 = 3;
const MAX_LENGTH_EMPRESA = 3;

@Component({
  selector: 'gto',
  templateUrl: './gto.module.component.html'
})
export class GtoModuleComponent extends ModuloComponent implements OnInit {
  @Input() public licencaStoreModePublic: boolean;
  @Input() public gtoLicenseInfo: IJsonGTOLicenseInfo;
  @Input() public responsaveis: Array<IGTOResponsavel>;

  public readonly tabIdPorEmpresa: string;
  public readonly tabIdMultiEmpresa: string;
  public readonly anosList: Array<number>;
  public readonly gtoPorEmpresaCallback: IGTOTabCallback;
  public readonly gtoMultiEmpresaCallback: IGTOTabMultiCallback;

  public activeTab: string;
  public centralGestId: number;
  public gtoSingleFilters: IGTOSingleFilters;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.tabIdPorEmpresa = TAB_ID_POR_EMPRESA;
    this.tabIdMultiEmpresa = TAB_ID_MULTI_EMPRESA;
    this.anosList = [];
    this.gtoPorEmpresaCallback = {};
    this.gtoMultiEmpresaCallback = {};
    this.activeTab = this.tabIdPorEmpresa;
    this.gtoSingleFilters = {
      nempresa: undefined,
      ano: moment().year(),
      tipoTarefa: -1,
      nResponsavel: undefined,
      nome: undefined,
      nomeResponsavel: ''
    };
    this._setAnosList();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.centralGestId = this.session.erp.centralGestId;
    this.gtoSingleFilters.nempresa = this.session.erp.nEmpresa;
    this.gtoSingleFilters.nome = this.session.erp.nomeEmpresa;
    this.toolbar.addButton({
      id: 'createNewTask',
      type: 'button',
      order: 0,
      class: 'btn-success',
      iconLeft: '<i class="fa fa-plus"></i>&nbsp;',
      caption: 'gto.toolbar.createNewTask',
      click: () => this._showNewTaskModal()
    });

    this.toolbar.addButton({
      id: 'copyTasks',
      type: 'button',
      class: 'btn-info',
      order: 1,
      iconLeft: '<i class="fa fa-copy"></i>&nbsp;',
      caption: 'gto.toolbar.copyTasks',
      click: () => this._showCopyTasksModal()
    });

    this.btnConfig.visible = true;
    this.btnConfig.click = () => this._showConfigModal();
  }

  public activeIdChanged(id: string): void {
    this.activeTab = id;
    if (this.activeTab === this.tabIdMultiEmpresa) {
      this.gtoMultiEmpresaCallback.repaintGridOnMobile();
    }
  }

  private _showNewTaskModal(): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(GtoCreateEditTaskModalComponent, {size: 'lg'});
    const componentInstance: GtoCreateEditTaskModalComponent = modalInstance.componentInstance;
    if (this.activeTab === 'porEmpresa') {
      const filters = this.gtoPorEmpresaCallback.getFilters();
      if (this._isEE(filters.nempresa)) {
        componentInstance.nempresa = this.session.erp.nEmpresa;
        componentInstance.nomeEmpresa = this.session.erp.nomeEmpresa;
        componentInstance.entExtCod = filters.nempresa;
        componentInstance.entExtNome = filters.nome;
      } else {
        componentInstance.nempresa = filters.nempresa;
        componentInstance.nomeEmpresa = filters.nome;
      }
    } else {
      componentInstance.nempresa = this.session.erp.nEmpresa;
      componentInstance.nomeEmpresa = this.session.erp.nomeEmpresa;
    }
    componentInstance.nUtilizador = this.session.erp.nUtilizador;
    return modalInstance.result.then(() => {
      return this._callbackSelectedTab();
    });
  }

  private _showConfigModal(): Promise<void> {
    let nEmpresa;
    let nomeEmpresa;
    if (this.activeTab === 'porEmpresa') {
      nEmpresa = this.gtoSingleFilters.nempresa;
      nomeEmpresa = this.gtoSingleFilters.nome;
    } else {
      const selMultiRow: IGTOTarefaMultiRow = this.gtoMultiEmpresaCallback.getSelectedRow();
      if (isDefinedNotNull(selMultiRow)) {
        nEmpresa = selMultiRow.nEmpresa;
        nomeEmpresa = selMultiRow.nomeEmpresa;
      } else {
        nEmpresa = this.session.erp.nEmpresa;
        nomeEmpresa = this.session.erp.nomeEmpresa;
      }
    }

    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(GtoConfigModalComponent, {size: 'xl'});
    const componentInstance: GtoConfigModalComponent = modalInstance.componentInstance;
    componentInstance.nempresa = nEmpresa;
    componentInstance.nomeEmpresa = nomeEmpresa;
    componentInstance.centralGestId = this.session.erp.centralGestId;
    componentInstance.storeModePublic = this.licencaStoreModePublic;
    componentInstance.licenseInfo = this.gtoLicenseInfo;
    componentInstance.responsaveis = this.responsaveis;
    return modalInstance.result.then(({refresh, nempresa}: IGTOConfigModalResult) => {
      if (refresh) {
        if (this.activeTab === 'porEmpresa') {
          const filters = this.gtoPorEmpresaCallback.getFilters();
          if (filters.nempresa === nempresa) {
            return this.gtoPorEmpresaCallback.refresh();
          }
        } else {
          return this.gtoMultiEmpresaCallback.refresh();
        }
      }
      return Promise.resolve();
    });
  }

  private _showCopyTasksModal(): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(GtoCopyTasksModalComponent, {size: 'xl'});
    const componentInstance: GtoCopyTasksModalComponent = modalInstance.componentInstance;
    componentInstance.nempresa = this.session.erp.nEmpresa;
    componentInstance.nomeEmpresa = this.session.erp.nomeEmpresa;
    componentInstance.licenseInfo = this.gtoLicenseInfo;
    return modalInstance.result.then(() => this._callbackSelectedTab());
  }

  private _setAnosList(): void {
    const year: number = moment().year();
    this.anosList.push(year + 1);
    for (let i = 0; i <= NUMBER_3; i++) {
      this.anosList.push(year - i);
    }
  }

  private _callbackSelectedTab(): Promise<void> {
    if (this.activeTab === 'porEmpresa') {
      return this.gtoPorEmpresaCallback.refresh();
    }
    return this.gtoMultiEmpresaCallback.refresh();
  }

  private _isEE(cod: string): boolean {
    return cod.length > MAX_LENGTH_EMPRESA || cod.startsWith('_');
  }
}
