import {Component} from '@angular/core';
import {IDevExpressDataGridColumn, IDevExpressDataGridSummary} from '../../../devexpress/datagrid/devexpress.datagrid.interface';
import {MovimentosPendentesBaseComponent} from '../movimentos.pendentes.base.component';

@Component({
  selector: 'movimentos-pendentes-outros-devedores-credores',
  templateUrl: './movimentos.pendentes.outrosdevedorescredores.component.html'
})
export class MovimentosPendentesOutrosDevedoresCredoresComponent extends MovimentosPendentesBaseComponent {
  protected _buildDefinition(): Array<IDevExpressDataGridColumn> {
    return [
      {dataField: 'nConta', dataType: 'string', caption: 'movimentospendentes.outrosDevedoresCredores.nConta'},
      {dataField: 'nome', dataType: 'string', caption: 'movimentospendentes.outrosDevedoresCredores.nome'},
      {dataField: 'tipoDocumento', dataType: 'string', caption: 'movimentospendentes.outrosDevedoresCredores.tipoDocumento'},
      {dataField: 'numeroDocumento', dataType: 'string', caption: 'movimentospendentes.outrosDevedoresCredores.numeroDocumento'},
      {dataField: 'dataDoc', dataType: 'date', caption: 'movimentospendentes.outrosDevedoresCredores.dataDoc', visible: false},
      {dataField: 'dataVencimento', dataType: 'date', caption: 'movimentospendentes.outrosDevedoresCredores.dataVencimento'},
      {dataField: 'valor', dataType: 'double', alignment: 'right', caption: 'movimentospendentes.outrosDevedoresCredores.valor'},
      {dataField: 'valorRecebido', dataType: 'double', alignment: 'right', caption: 'movimentospendentes.outrosDevedoresCredores.valorRecebido'},
      {dataField: 'valorPorReceber', dataType: 'double', alignment: 'right', caption: 'movimentospendentes.outrosDevedoresCredores.valorPorReceber'},
      {dataField: 'nif', dataType: 'string', caption: 'movimentospendentes.outrosDevedoresCredores.nif'},
      {dataField: 'nDocExterno', dataType: 'string', caption: 'movimentospendentes.outrosDevedoresCredores.nDocExterno', visible: false}
    ];
  }

  protected _buildGridSummary(): IDevExpressDataGridSummary {
    return {
      totalItems: [
        {column: 'valor', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
        {column: 'valorRecebido', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
        {column: 'valorPorReceber', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}
      ]
    };
  }

  protected _listFields(): string {
    return 'nome,nif,tipoDocumento,numeroDocumento,dataDoc,dataVencimento,valor,valorRecebido,valorPorReceber';
  }
}
