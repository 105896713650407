<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<ng-container [ngSwitch]="isNested">
  <form
    *ngSwitchDefault
    #form="ngForm"
    rel="noopener noreferrer"
    autocapitalize="off"
    autocomplete="off"
    plFormNavigation
    plFormErrorNavigation
    [ngClass]="formClass"
    [formGroup]="formInstance"
    [noValidate]="true"
    (ngSubmit)="submit($event)">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </form>

  <div *ngSwitchCase="true" #form="ngForm" class="sub-form" [ngClass]="formClass" [formGroup]="formInstance">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-container>

<ng-template #content [ngSwitch]="!!definition">
  <div class="form-content">
    <div *ngSwitchCase="false" class="form-custom-content">
      <ng-content></ng-content>
    </div>

    <div *ngSwitchCase="true" class="form-definition-content">
      <ng-container *ngFor="let builtField of builtFields" [ngSwitch]="builtField.type">
        <ng-container *ngSwitchDefault>
          <pl-group *ngIf="builtField.field.properties.visible !== false" [ngClass]="builtField.field.class">
            <label class="form-label">{{ builtField.field.caption }}</label>
            <edit>
              <pl-edit
                plAutoFocus
                [type]="builtField.field.type"
                [attrName]="builtField.field.formName"
                [model]="getModelValue(builtField)"
                [properties]="builtField.field.properties"
                [plAutoFocusDisabled]="builtField.field.name !== autoFocusField"
                (modelChange)="setModelValue(builtField, $event)">
              </pl-edit>
            </edit>
            <actions [innerHTML]="builtField.field.actions || ''"></actions>
          </pl-group>
        </ng-container>

        <pl-group *ngSwitchCase="'group'" [properties]="builtField.properties">
          <ng-container *ngFor="let group of builtField.fields">
            <pl-group *ngIf="group.field?.properties.visible !== false && group.field?.type" [ngClass]="group.field?.class">
              <label class="form-label">{{ group.field?.caption }}</label>
              <edit>
                <pl-edit
                  plAutoFocus
                  [type]="group.field?.type"
                  [attrName]="group.field?.formName"
                  [model]="getModelValue(group)"
                  [properties]="group.field?.properties"
                  [plAutoFocusDisabled]="group.field?.name !== autoFocusField"
                  (modelChange)="setModelValue(group, $event)">
                </pl-edit>
              </edit>
              <actions [innerHTML]="group.field?.actions || ''"></actions>
            </pl-group>
          </ng-container>
        </pl-group>
      </ng-container>
    </div>
  </div>
</ng-template>
