import {difference} from 'lodash-es';
import {Directive, ElementRef, Inject, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {DI_CGC_ICON_PREFIXES, TCGCIconPrefixes} from '../config/icon.config.interface';
import {isArray, isBoolean, isString} from '../../common/utilities/utilities';

const CSS_CLASS_NORMALIZE_SIZE = 'cgc-icon-normalize-size';

@Directive({
  selector: '[cgcIcon]',
  standalone: true
})
export class CGCIconDirective implements OnInit, OnChanges {
  @Input() public cgcIcon: string | Array<string>;
  @Input() public normalizeSize: boolean;

  private readonly _prefixes: TCGCIconPrefixes;
  private readonly _element: HTMLElement;
  private _addedPrefixes: Set<string>;
  private _addedIcons: Set<string>;
  private _addedNormalizeSize: boolean;

  constructor(
    @Inject(DI_CGC_ICON_PREFIXES) prefixes: TCGCIconPrefixes | Array<TCGCIconPrefixes>,
    elementRef: ElementRef<HTMLElement>,
    private readonly _renderer: Renderer2
  ) {
    if (!isArray(prefixes)) {
      this._prefixes = [];
    } else if (isArray(prefixes[0]) && isArray(prefixes[0][0])) {
      // Multi: true was used, we have to normalize this
      this._prefixes = (<Array<TCGCIconPrefixes>>prefixes).reduce<TCGCIconPrefixes>((accumulator: TCGCIconPrefixes, currentValue: TCGCIconPrefixes) => accumulator.concat(currentValue), []);
    } else {
      this._prefixes = <TCGCIconPrefixes>prefixes;
    }
    this._element = elementRef.nativeElement;
    this._addedNormalizeSize = false;
  }

  public ngOnInit(): void {
    this._changedIcon();
    this._changedNormalizeSize();
  }

  public ngOnChanges({cgcIcon, normalizeSize}: SimpleChanges): void {
    if (cgcIcon && !cgcIcon.isFirstChange()) {
      this._changedIcon(cgcIcon.currentValue);
    }
    if (normalizeSize && !normalizeSize.isFirstChange()) {
      this._changedNormalizeSize(normalizeSize.currentValue);
    }
  }

  public addClass(className: string): void {
    this._renderer.addClass(this._element, className);
  }

  public removeClass(className: string): void {
    this._renderer.removeClass(this._element, className);
  }

  private _changedIcon(value: string | Array<string> = this.cgcIcon): void {
    const icons: Array<string> = isString(value) ? value.split(' ') : isArray(value) ? value : [];

    const addedPrefixes: Set<string> = new Set<string>();
    const addedIcons: Set<string> = new Set<string>();

    for (const icon of icons) {
      if (!isString(icon) || !icon) {
        continue;
      }

      for (const [prefix, className] of this._prefixes) {
        if (icon.startsWith(prefix)) {
          if (!addedPrefixes.has(className)) {
            this.addClass(className);
            addedPrefixes.add(className);
          }
          break;
        }
      }

      if (!addedIcons.has(icon)) {
        this.addClass(icon);
        addedIcons.add(icon);
      }
    }

    const prefixesToRemove: Array<string> = this._addedPrefixes?.size ? difference(Array.from(this._addedPrefixes), Array.from(addedPrefixes)) : undefined;
    if (prefixesToRemove) {
      for (const className of prefixesToRemove) {
        this.removeClass(className);
      }
    }
    this._addedPrefixes = addedPrefixes;

    const iconsToRemove: Array<string> = this._addedIcons?.size ? difference(Array.from(this._addedIcons), Array.from(addedIcons)) : undefined;
    if (iconsToRemove) {
      for (const className of iconsToRemove) {
        this.removeClass(className);
      }
    }
    this._addedIcons = addedIcons;
  }

  private _changedNormalizeSize(value: boolean = this.normalizeSize): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = true;
    }
    this.normalizeSize = val;

    if (this.normalizeSize && !this._addedNormalizeSize) {
      this.addClass(CSS_CLASS_NORMALIZE_SIZE);
      this._addedNormalizeSize = true;
    }
    if (!this.normalizeSize && this._addedNormalizeSize) {
      this.removeClass(CSS_CLASS_NORMALIZE_SIZE);
      this._addedNormalizeSize = false;
    }
  }
}
