import {Component, Injector} from '@angular/core';
import {isNumber, isObject} from 'pl-comps-angular';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonAplicabilidadeIRCT, IJsonCCT} from '../../jsonCCT.entity.interface';
import {CctService} from '../../cct.entity.service';
import {HttpResponse} from '@angular/common/http';
import {UntypedFormGroup} from '@angular/forms';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {MODULE_NAME_RH_QPIRCT} from '../../../../modules/portalrh/rhqpirct/rhQPIRCT.module.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {IEntityAutocompleteCustomActionDefinition} from '../../../../components/entity/entity.autocomplete.definition.interface';

@Component({
  selector: 'cct-edit',
  templateUrl: './cct.entity.edit.component.html'
})
export class CctEditComponent extends ModuloEntityDetailComponent<IJsonCCT> {
  public readonly aplicabilidadeIRCTTemplate: string;
  public readonly customActionsQPIRCT: Array<IEntityAutocompleteCustomActionDefinition>;

  public formInstance: UntypedFormGroup;
  public dtAplicabilidadeIRCT: Array<IJsonAplicabilidadeIRCT>;

  private readonly _maintenanceQPIRCT: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cctService: CctService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this._maintenanceQPIRCT = this._moduleMaintenanceService.build(MODULE_NAME_RH_QPIRCT);
    this.aplicabilidadeIRCTTemplate = '{{value}} - {{nome}}';
    this.dtAplicabilidadeIRCT = [];
    this._getDtAplicabilidadeIRCT();
    this.customActionsQPIRCT = [
      {
        caption: 'cct.actions.taboficialirct',
        icon: 'fa-fw fa-eye',
        action: () => {
          this._maintenanceQPIRCT.maintenance();
        }
      }
    ];
  }

  public aplicabilidadeChanged(value: {inputValue: string; item: IJsonAplicabilidadeIRCT}): void {
    if (isObject(value.item) && value.item.value !== this.model.aplicabilidadeIRCT) {
      this.model.aplicabilidadeIRCT = value.item.value;
      this.model.nomeAplicabilidadeIRCT = value.item.nome;
    } else if (isNumber(Number(value.inputValue))) {
      const index = this.dtAplicabilidadeIRCT.findIndex((aplicabilidade: IJsonAplicabilidadeIRCT) => aplicabilidade.value === Number(value.inputValue));
      if (index !== -1) {
        const aplicabilidadeIRCT: Array<IJsonAplicabilidadeIRCT> = this.dtAplicabilidadeIRCT.slice();
        this.model.aplicabilidadeIRCT = aplicabilidadeIRCT[index].value;
        this.model.nomeAplicabilidadeIRCT = aplicabilidadeIRCT[index].nome;
      }
    }
  }

  public readonly fnAplicabilidadeValidate: (value: string, item: IJsonAplicabilidadeIRCT) => Promise<IJsonAplicabilidadeIRCT> = (value: string, item: IJsonAplicabilidadeIRCT) =>
    this._aplicabilidadeValidate(value, item);

  private _aplicabilidadeValidate(value: string, item: IJsonAplicabilidadeIRCT): Promise<IJsonAplicabilidadeIRCT> {
    return new Promise<IJsonAplicabilidadeIRCT>((resolve, reject) => {
      if (isObject(item)) {
        const linha: IJsonAplicabilidadeIRCT = this.dtAplicabilidadeIRCT.find((aplicabilidade: IJsonAplicabilidadeIRCT) => aplicabilidade.value === item.value);
        if (linha) {
          resolve(linha);
        }
      } else if (isNumber(Number(value))) {
        const linha = this.dtAplicabilidadeIRCT.find((aplicabilidade: IJsonAplicabilidadeIRCT) => aplicabilidade.value === Number(value));
        if (linha) {
          resolve(linha);
        }
      }
      reject(new Error());
    });
  }

  private _getDtAplicabilidadeIRCT(): void {
    this._cctService.getDataSourceAplicabilidadeIRCT().then((response: HttpResponse<Array<IJsonAplicabilidadeIRCT>>) => {
      this.dtAplicabilidadeIRCT = response.body;
    });
  }
}
