import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IDocumentoFaturacao} from '../../../../components/documento/facturacao/documento.facturacao.component.interface';

@Component({
  selector: 'modal-faturacaopublica-docscomerciais',
  templateUrl: './faturacaoPublica.docComercial.modal.component.html'
})
export class FaturacaoPublicaDocComercialModalComponent extends CGModalComponent<void> {
  @Input() public doc: IDocumentoFaturacao;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }
}
