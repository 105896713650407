import type {Subscription} from 'rxjs';
import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges} from '@angular/core';
import {IPlCompsServiceConfig, IPlCompsServiceConfigToolbar} from '../common/interface';
import {
  IPlToolbarConfig,
  IPlToolbarConfigSearch,
  IPlToolbarInstance,
  IPlToolbarInstanceEvtGetItemElement,
  IPlToolbarItem,
  IPlToolbarMenuItem,
  TPlToolbarItem,
  TPlToolbarItemOrId,
  TPlToolbarItemType
} from './toolbar.interface';
import {isDefined, isFunction, isString} from '../common/utilities/utilities';
import {isMobile, KEYCODES, THyperlinkTarget} from '../common/constants';
import {PlCompsService} from '../common/service/comps.service';
import {PlDocumentService} from '../common/document/document.service';
import {PlToolbarService} from './toolbar.service';

const DEFAULT_HYPERLINK_TARGET: THyperlinkTarget = '_blank';
const SELECTOR_FOCUSABLE = 'input,select,textarea,button,a,[contentEditable],[tabIndex="0"]';

@Component({
  selector: 'pl-toolbar',
  templateUrl: './toolbar.component.html',
  standalone: false
})
export class PlToolbarComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() public instanceId: string;
  @Input() public initialConfig: IPlToolbarConfig;
  @Input() public collapseOnMobile: boolean;
  @Input() public toolbarClass: string;
  @Output() public readonly evtPageChanged: EventEmitter<number>;
  @Output() public readonly evtSearchChanged: EventEmitter<string>;

  public config: IPlToolbarConfig;
  public search: IPlToolbarConfigSearch;
  public isMobile: boolean;
  public isCollapsed: boolean;
  public defaultHyperlinkTarget: THyperlinkTarget;
  public defaultDownloadHyperlinkTarget: THyperlinkTarget;

  private readonly _element: HTMLElement;
  private readonly _subscriptionConfig: Subscription;
  private readonly _subscriptionIsMobile: Subscription;
  private _configs: IPlCompsServiceConfigToolbar;
  private _id: string;
  private _instance: IPlToolbarInstance;
  private _instanceOwned: boolean;
  private _subscriptionOnItemsChanged: Subscription;
  private _subscriptionOnFocusItem: Subscription;
  private _subscriptionOnGetItemElement: Subscription;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _renderer: Renderer2,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _plCompsService: PlCompsService,
    private readonly _plDocumentService: PlDocumentService,
    private readonly _plToolbarService: PlToolbarService
  ) {
    this._element = this._elementRef.nativeElement;
    this.evtPageChanged = new EventEmitter<number>();
    this.evtSearchChanged = new EventEmitter<string>();
    this.search = {text: ''};
    this.isMobile = isMobile();
    this.defaultHyperlinkTarget = DEFAULT_HYPERLINK_TARGET;
    this.defaultDownloadHyperlinkTarget = DEFAULT_HYPERLINK_TARGET;
    this._instanceOwned = false;
    this._subscriptionConfig = this._plCompsService.config().subscribe((config: IPlCompsServiceConfig) => {
      this._configs = config.plToolbar;
      this.defaultHyperlinkTarget = this._configs.defaultHyperlinkTarget || DEFAULT_HYPERLINK_TARGET;
      this.defaultDownloadHyperlinkTarget = this._configs.defaultDownloadHyperlinkTarget || DEFAULT_HYPERLINK_TARGET;
    });
    this._subscriptionIsMobile = this._plDocumentService.isMobile().subscribe((value: boolean) => {
      this.isMobile = value;
    });
  }

  public ngOnInit(): void {
    this._handleChanges();
  }

  public ngOnChanges({instanceId}: SimpleChanges): void {
    if (instanceId && !instanceId.isFirstChange()) {
      this._changedInstanceId(instanceId.currentValue);
    }
  }

  public ngAfterViewInit(): void {
    this._changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this._subscriptionConfig.unsubscribe();
    this._subscriptionIsMobile.unsubscribe();
    this._cleanToolbar();
  }

  public isVisible(item: TPlToolbarItem): boolean {
    if (isDefined(item.visible)) {
      if (isFunction(item.visible)) {
        return Boolean(item.visible(item));
      }
      return Boolean(item.visible);
    }
    return true;
  }

  public isCollapseVisible(): boolean {
    if (!this.config) {
      return false;
    }
    let visible = false;
    const itemTitle: TPlToolbarItem = this.config.items.find((item: TPlToolbarItem) => {
      return item.type === 'title';
    });
    const itemSearch: TPlToolbarItem = this.config.items.find((item: TPlToolbarItem) => {
      return item.type === 'search' && this.config.search.active;
    });
    const length: number = this.config.items.filter((item: TPlToolbarItem) => {
      if (item.type === 'search') {
        return item.visible !== false && this.config.search.active;
      }
      return item.visible !== false;
    }).length;
    if (itemTitle && itemSearch) {
      visible = length > 2;
    } else if (itemTitle || itemSearch) {
      visible = length > 1;
    }
    return this.collapseOnMobile === true && isMobile() && visible;
  }

  public doClick(item: IPlToolbarItem | IPlToolbarMenuItem, event: MouseEvent | KeyboardEvent, parent?: TPlToolbarItem): Promise<any> {
    if (item.disabled !== true && isFunction(item.click)) {
      const promise: Promise<any> = Promise.resolve<any>(item.click(item, event));
      if (parent) {
        parent.promise = promise;
      }
      return promise;
    }
    return Promise.resolve();
  }

  public fnDoClick(item: TPlToolbarItem, parent?: TPlToolbarItem): (event: MouseEvent | KeyboardEvent) => void | Promise<any> {
    return (event: MouseEvent | KeyboardEvent) => this.doClick(item, event, parent);
  }

  public isTypeExcluded(type: TPlToolbarItemType): boolean {
    return type === 'title' || type === 'html' || type === 'search';
  }

  public doSearch(value: string): void {
    this._instance.setSearch(value);
    this.evtSearchChanged.emit(value);
  }

  public clearSearch(): void {
    this.search.text = '';
    this.doSearch(this.search.text);
  }

  public searchFocus(element: HTMLElement): void {
    this._renderer.addClass(element, 'input-focus');
  }

  public searchBlur(element: HTMLElement): void {
    this._renderer.removeClass(element, 'input-focus');
  }

  public searchKeyUp(event: KeyboardEvent): void {
    if (this.search && event.key === KEYCODES.ESC) {
      this.clearSearch();
    }
  }

  public readonly fnChangedPage =
    (item: TPlToolbarItem) =>
    (value: number): void => {
      this._changedPage(value, item);
    };

  public get id(): string {
    return this._id;
  }

  private _handleChanges(): void {
    this._changedInstanceId();
  }

  private _changedInstanceId(value: string = this.instanceId): void {
    this._cleanToolbar();
    this._id = value || this._configs.defaultInstanceId || 'plToolbar_default_instance';
    this._instance = this._plToolbarService.getInstance(this._id, this.initialConfig);
    if (this._instance) {
      this.config = this._instance.config;
      this.search = this.config.search;
      this._toolbarItemsChanged();

      this._subscriptionOnItemsChanged = this._instance.onItemsChanged().subscribe(() => {
        this._toolbarItemsChanged();
      });

      this._subscriptionOnFocusItem = this._instance.onFocusItem().subscribe((idOrItem: TPlToolbarItemOrId) => {
        const element: HTMLElement = this._getItemElement(idOrItem, true);
        if (element) {
          element.focus();
        }
      });

      this._subscriptionOnGetItemElement = this._instance.onGetItemElement().subscribe((event: IPlToolbarInstanceEvtGetItemElement) => {
        return this._getItemElement(event.idOrItem, event.queryFocusable);
      });
    }
  }

  private _toolbarItemsChanged(): void {
    this._changeDetectorRef.detectChanges();
  }

  private _changedPage(value: number, item: TPlToolbarItem): void {
    if (item.type !== 'page') {
      return;
    }
    item.page.num = value;
    this._instance.setPage(item.page.num);
    this.evtPageChanged.emit(item.page.num);
  }

  private _getItemElement(idOrItem: TPlToolbarItemOrId, queryFocusable: boolean): HTMLElement {
    const id: string = isString(idOrItem) ? idOrItem : idOrItem.id;
    let element: HTMLElement = this._element.querySelector(`[data-item-id="${id}"]`);
    if (queryFocusable && element && !element.matches(SELECTOR_FOCUSABLE)) {
      element = element.querySelector(SELECTOR_FOCUSABLE);
    }
    return element;
  }

  private _cleanToolbar(): void {
    if (this._instance) {
      if (this._instanceOwned) {
        this._plToolbarService.unRegisterInstance(this._instance);
        this._instanceOwned = false;
      }
      this._instance = undefined;
      this.config = undefined;
    }
    if (this._subscriptionOnItemsChanged) {
      this._subscriptionOnItemsChanged.unsubscribe();
      this._subscriptionOnItemsChanged = undefined;
    }
    if (this._subscriptionOnFocusItem) {
      this._subscriptionOnFocusItem.unsubscribe();
      this._subscriptionOnFocusItem = undefined;
    }
    if (this._subscriptionOnGetItemElement) {
      this._subscriptionOnGetItemElement.unsubscribe();
      this._subscriptionOnGetItemElement = undefined;
    }
  }
}
