<div class="amalia-chat-content">
  <div class="d-flex">
    <span [class.w-100]="bodyFaq">
      <ng-container *ngIf="bodyIntent">
        <button type="button" class="btn btn-link p-0 text-start" *ngIf="!bodyIntentParametersEmpty" (click)="actionIntent(bodyIntent)">{{ bodyIntent.message }}</button>
        <ng-container *ngIf="bodyIntentParametersEmpty">
          <span [innerHTML]="bodyIntent.message" (click)="handleCustomLinkClick($event)"></span>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="bodyFaq">
        <div class="faq-content mb-2">
          <div type="button" (click)="isCollapsed = !isCollapsed" class="faq-title">
            <strong>{{ bodyFaq.title }}</strong>
            <i class="fa" [ngClass]="{'fa-minus': !isCollapsed, 'fa-plus': isCollapsed}"></i>
          </div>
          <div [ngbCollapse]="isCollapsed" class="faq-body-content">
            <span [innerHTML]="messageHTML"></span>
            <br />
            <a [href]="'https://www.centralgestcloud.com/faqs/topico/' + bodyFaq.externalId" target="_blank" [translate]="'amalia.text.openFaq'"></a>
          </div>
        </div>
        <hr *ngIf="!lastMessage" />
      </ng-container>

      <!--      <ng-container *ngIf="bodyMessage">{{ bodyMessage.message }}</ng-container>-->

      <ng-container *ngIf="bodyMessage">
        <span [class.text-white]="sentByAgent" [innerHTML]="bodyMessage.message"></span>
      </ng-container>

      <ng-container *ngIf="!bodyIntent && !bodyFaq && !bodyMessage" [translate]="'amalia.text.unsupported'" [translateParams]="{message: normalizedMessage}"></ng-container>
    </span>
  </div>
</div>
