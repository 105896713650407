import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonNaturezaContab} from './jsonNaturezaContab.entity.interface';
import {ENTITY_NAME_NATUREZAS_CONTAB} from './naturezasContab.entity.interface';

export const ENTITY_NATUREZASCONTAB: IEntityDefinition<IJsonNaturezaContab> = {
  name: ENTITY_NAME_NATUREZAS_CONTAB,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE],
  searchPlaceholder: 'naturezasContab.pesquisa',
  metadata: {
    keyName: 'idSubGrContaCorrente',
    fields: [
      {
        name: 'idGrContaCorrente',
        caption: 'naturezasContab.fields.idGrContaCorrente',
        placeholder: 'naturezasContab.fields.idGrContaCorrentePlaceholder',
        visible: false
      },
      {
        name: 'idSubGrContaCorrente',
        caption: 'naturezasContab.fields.idSubGrContaCorrente',
        placeholder: 'naturezasContab.fields.idSubGrContaCorrentePlaceholder',
        visible: false
      },
      {
        name: 'descricao',
        caption: 'naturezasContab.fields.descricao',
        placeholder: 'naturezasContab.fields.descricaoPlaceholder',
        visible: true,
        validators: {required: true, maxlength: 40}
      },
      {
        name: 'radical',
        caption: 'naturezasContab.fields.radical',
        placeholder: 'naturezasContab.fields.radicalPlaceholder',
        visible: true,
        validators: {required: true, maxlength: 40}
      },
      {
        name: 'nDigitosSequencia',
        caption: 'naturezasContab.fields.nDigitosSequencia',
        placeholder: 'naturezasContab.fields.nDigitosSequenciaPlaceholder',
        visible: true,
        validators: {required: true, maxlength: 40}
      },
      {name: 'tipo', caption: 'naturezasContab.fields.tipo', visible: false}
    ],
    order: 'radical',
    searchFields: 'radical,descricao',
    listFields: 'radical,descricao,nDigitosSequencia',
    newFields: 'descricao,radical,nDigitosSequencia',
    editFields: 'descricao,radical,nDigitosSequencia',
    detailFields: 'descricao,radical,nDigitosSequencia'
  },
  autocomplete: {
    rowTemplate: '{{idSubGrContaCorrente}} - {{descricao}}',
    output: '{{idSubGrContaCorrente}} - {{descricao}}',
    outputDescription: 'descricao',
    searchFields: 'radical,descricao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.naturezasContab'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.naturezasContab'
      }
    }
  },
  new: {
    state: {
      data: {
        pageTitle: 'global.menu.naturezasContab'
      }
    }
  }
};
