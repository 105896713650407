import type {TDevExpressDataGridColumnDataType} from '../devexpress.datagrid.types.interface';
import type {TDevExpressPredefinedFormat} from '../../widget/devexpress.widget.interface';

export interface IDevExpressDataGridExpandDetailHandlerOptions {
  readonly collapseOthers?: boolean;
}

export interface IDevExpressDataGridPaging {
  page: number;
  perPage: number;
}

export const DEV_EXPRESS_SUPPRESS_ZEROS_FORMATS: ReadonlyArray<TDevExpressDataGridColumnDataType | TDevExpressPredefinedFormat> = Object.freeze([
  'number',
  'double',
  'currency',
  'billions',
  'decimal',
  'exponential',
  'fixedPoint',
  'largeNumber',
  'millions',
  'thousands',
  'trillions'
]);

export const DEV_EXPRESS_PERCENT_FORMATS: ReadonlyArray<TDevExpressDataGridColumnDataType | TDevExpressPredefinedFormat> = Object.freeze(['number', 'double', 'decimal']);
