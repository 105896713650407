import {Component, Injector, Input, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IPlToolbarItem, PlTranslateService} from 'pl-comps-angular';
import {DATA_SOURCE_TIPO_EXCECAO_CRABD} from '../../../../datasources/tipoexcecaocrabd/tipoExcecaoCRABD.datasource';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {
  EDec119EnviaImprime,
  ENTITY_NAME_ABONOS,
  ENTITY_NAME_DESCONTOS,
  ESiglaTipoAbonoDesconto,
  ETipoAbonoDesconto,
  IABDESEntityService,
  IABDESStateParams,
  INaoImpDecIRS114
} from '../../abdes.entity.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {
  ENTITY_NAME_CRABD,
  ENTITY_NAME_CRUZAMENTOS_ABONOS,
  ENTITY_NAME_CRUZAMENTOS_DESCONTOS,
  ESiglasCategAbonos,
  ESiglasCategDescontos,
  ICRABDEditStateParams
} from '../../../crabd/crabd.entity.interface';
import {ETipoCategoriaDesc} from '../../../../datasources/tipocategoriadesc/tipoCategoriaDesc.datasource.interface';
import {ETipoCategoria} from '../../../../datasources/tipocategoria/tipoCategoria.datasource.interface';
import {ETipoContaDiasSubAlimentacao} from '../../../../datasources/tipodiascontasubalimen/tipoContaDiasSubAlimen.datasource.interface';
import {ETipoEntSuporteABDES} from '../../../../datasources/tipoentsuporteabdes/tipoEntSuporteABDES.datasource.interface';
import {ETipoTaxaRetencao} from '../../../../datasources/tipotaxaretencao/tipoTaxaRetencao.datasource.interface';
import {GenerateAbdesModalComponent} from '../../modals/generateabdes/generateAbdes.modal.component';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IAbDesCrabd, IJsonABDES, IJsonPerfilSistemaAbdes} from '../../jsonABDES.entity.interface';
import {ICrAbDGrid, IJsonCRABD} from '../../../crabd/jsonCrAbD.entity.interface';
import {IJsonGrAbono} from '../../../grabonos/jsonGrAbonos.entity.interface';
import {IJsonGrDesconto} from '../../../grdescontos/jsonGrDescontos.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IApiQueryResponse, IApiRequestConfigWithBody} from '../../../../services/api/api.service.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumnCustomizeTextCellInfo, TDevExpressDataGridColumnCustomizeTextFn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {HttpResponse} from '@angular/common/http';
import ArrayStore from 'devextreme/data/array_store';
import {DATA_SOURCE_TIPOS_CATEGORIA_DESCONTOS} from '../../../../datasources/tipocategoriadesc/tipoCategoriaDesc.datasource';
import {DATA_SOURCE_TIPOS_CATEGORIA_ABONOS} from '../../../../datasources/tipocategoria/tipoCategoria.datasource';
import {EditarCruzamentosModalComponent} from '../../modals/editarcruzamentos/editarCruzamentos.modal.component';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {IRHCopiaAbonosDescontosParams, MODULE_NAME_RH_COPIA_ABONOS_DESCONTOS} from '../../../../modules/portalrh/rhcopiaabonosdescontos/rhCopiaAbonosDescontos.module.interface';
import {ENTITY_NAME_GR_ABONOS} from '../../../grabonos/grAbonos.entity.interface';
import {ENTITY_NAME_GR_DESCONTOS} from '../../../grdescontos/grDescontos.entity.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {ERendiTipoDeclaracao} from '../../../rendis/jsonRendi.entity.interface';
import {AbdesEntityEditUnlockPromptModalComponent} from '../../modals/unlockprompt/abdes.entity.edit.unlockPrompt.modal.component';

const COD_ABONO_PREFIX = '0';
const COD_DESCONTO_PREFIX = '1';

@Component({
  selector: 'abdes-edit',
  templateUrl: './abdes.entity.edit.component.html'
})
export class AbDesEditComponent extends ModuloEntityDetailComponent<IJsonABDES, IABDESEntityService> implements OnInit {
  @Input() public generatedModel: IJsonABDES;
  @Input() public perfilSistemaAbdes: IJsonPerfilSistemaAbdes;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly rendisFilter: string;
  public readonly rendisRowTemplate: string;

  public btnGenerateAbdes: IPlToolbarItem;
  public btnCopyAbdes: IPlToolbarItem;
  public isAbono: boolean;
  public isNHorasDiaProvDescEnabled: boolean;
  public taxaRetencao: number;
  public isPerfilSistema: boolean;
  public canEditCruzamentos: boolean;
  public modelDec119: INaoImpDecIRS114;
  public titleAbonosDescontos: string;
  public abdescSystemLockedWarningMsg: string;
  public abdescSystemUnLockedWarningMsg: string;

  private readonly _maintenanceInstanceRHCopiaAbonosDescontos: IModuleMaintenanceInstance;
  private readonly _grAbonoService: IEntityService<IJsonGrAbono>;
  private readonly _grDescontoService: IEntityService<IJsonGrDesconto>;
  private readonly _crabdsService: IEntityService<IJsonCRABD>;
  private readonly _defaultNewAbdes: IJsonABDES;

  private _maintenanceInstanceCRABDS: IEntityMaintenanceInstance<IJsonCRABD>;
  private _defaultNewCRABDs: Array<IJsonCRABD>;
  private _grAbonos: Array<IJsonGrAbono>;
  private _grDescontos: Array<IJsonGrDesconto>;
  private _grAbonoAux: IJsonGrAbono;
  private _grDescontoAux: IJsonGrDesconto;
  private _cruzamentos: Array<ICrAbDGrid>;
  private _abdesModelBeforeUnlock: IJsonABDES;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this._grAbonoService = this._entityServiceBuilder.build<IJsonGrAbono>(ENTITY_NAME_GR_ABONOS);
    this._grDescontoService = this._entityServiceBuilder.build<IJsonGrDesconto>(ENTITY_NAME_GR_DESCONTOS);
    this._crabdsService = this._entityServiceBuilder.build<IJsonCRABD>(ENTITY_NAME_CRABD);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {
          caption: 'crabd.tableFields.categoria',
          allowFiltering: false,
          allowHeaderFiltering: false,
          columns: [{dataField: 'nomeCategoria', dataType: 'string', caption: 'generateAbdes.crabdTable.fields.nomeCategoria'}]
        },
        {
          caption: 'crabd.tableFields.empregado',
          allowFiltering: false,
          allowHeaderFiltering: false,
          columns: [
            {dataField: 'entSuporteEmpregado', dataType: 'boolean', caption: 'abdes.fields.tem'},
            {dataField: 'incidenciaEmpregado', dataType: 'number', caption: 'crabd.fields.incidenciaEmpregado', customizeText: this._fnCustomizeText}
          ]
        },
        {
          caption: 'crabd.tableFields.patronato',
          allowFiltering: false,
          allowHeaderFiltering: false,
          columns: [
            {dataField: 'entSuportePatronato', dataType: 'boolean', caption: 'abdes.fields.tem'},
            {dataField: 'incidenciaPatronato', dataType: 'number', caption: 'crabd.fields.incidenciaPatronato', customizeText: this._fnCustomizeText}
          ]
        },
        {
          caption: 'crabd.tableFields.desconto',
          allowFiltering: false,
          allowHeaderFiltering: false,
          columns: [
            {dataField: 'nCodDestino', dataType: 'number', caption: 'generateAbdes.crabdTable.fields.nDesconto'},
            {dataField: 'nomeABDESDestino', dataType: 'string', caption: 'generateAbdes.crabdTable.fields.nomeDesconto'}
          ]
        },
        {type: 'buttons', headerCellTemplate: 'headerCellTemplateBtnsEdit', cellTemplate: 'cellTemplateBtnsEdit'}
      ],
      dataSource: [],
      export: {filename: 'abdes.tabIntegracaoCruzamento'},
      remoteOperations: false
    };

    this.rendisFilter = `tipoDeclaracao=${ERendiTipoDeclaracao.Modelo10}|tipoDeclaracao=${ERendiTipoDeclaracao.Modelo39}`;
    this.rendisRowTemplate = '{{tipRendiment}} - {{nome}} ({{tipoDeclaracaoStr}})';

    const params: IABDESStateParams = <IABDESStateParams>this._transition.params();
    this._defaultNewAbdes = params.defaultABDES;
    this._defaultNewCRABDs = params.defaultCRABD || [];

    this._maintenanceInstanceRHCopiaAbonosDescontos = this._moduleMaintenanceService.build(MODULE_NAME_RH_COPIA_ABONOS_DESCONTOS);
    this.newCrabd = this.newCrabd.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.generatedModel) {
      this.model = this.generatedModel;
    }
    this.btnGenerateAbdes = {
      order: this.btnSave.order + 1,
      id: 'btnGenerateAbdes',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
      class: 'btn-primary',
      caption: `${this.entityName}.actions`,
      visible: this.type === EEntityStateDetailType.NEW,
      click: () => this._generateAbdes()
    };

    this.btnCopyAbdes = {
      order: this.btnDuplicate.order + 1,
      id: 'btnCopyAbdes',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-files-o"></i>',
      class: 'btn-primary',
      caption: 'gto.copiar',
      visible: this.type !== EEntityStateDetailType.NEW,
      click: () => this._copyAbdes()
    };

    this.toolbar.addButton(this.btnGenerateAbdes).addButton(this.btnCopyAbdes);
    this._grAbonos = [];
    this._grDescontos = [];
    if (this.entityName === ENTITY_NAME_ABONOS) {
      this._initAsAbono();
    } else {
      this._initAsDesconto();
    }
    this.isNHorasDiaProvDescEnabled = false;

    this.taxaRetencao = ETipoTaxaRetencao.Rendimentos;
    if (this.type !== EEntityStateDetailType.NEW) {
      if (this.model.procNatal === 1) {
        this.taxaRetencao = ETipoTaxaRetencao.SubNatal;
      } else if (this.model.procFerias === 1) {
        this.taxaRetencao = ETipoTaxaRetencao.SubFerias;
      }
    }

    if (this.entityName === ENTITY_NAME_ABONOS) {
      this.model.entiSuporte = ETipoEntSuporteABDES.Empregado;
    }
    this._getCruzamentos();
    if (this._defaultNewAbdes) {
      this.model = {...this.model, ...this._defaultNewAbdes};
    }

    this.isPerfilSistema = this.model?.perfilSistema && this.model?.perfilSistema > 0;
    this.canEditCruzamentos = !this.isPerfilSistema;
    this.btnDelete.disabled = this.isPerfilSistema;

    this._naoImpDecIRS114ToBoolean();

    if (this.type === EEntityStateDetailType.NEW) {
      this.service.get<number>({url: 'proximo'}).then((response: HttpResponse<number>) => {
        if (response) {
          const codAbdes: string = response.body.toString();
          this.model.codAbDesc = codAbdes;
          this.model.nCodABDESC = this.isAbono ? COD_ABONO_PREFIX + codAbdes : COD_DESCONTO_PREFIX + codAbdes;
          this._reindexCruzamentos();
        }
      });
    }

    this.titleAbonosDescontos = this.isAbono ? this._plTranslateService.translate('abdes.abonos') : this._plTranslateService.translate('abdes.descontos');
    this.abdescSystemLockedWarningMsg = this._plTranslateService.translate('abdes.messages.abdescSystemLocked', {perfilSistemaAbdesNome: this.perfilSistemaAbdes?.designaBreve});
    this.abdescSystemUnLockedWarningMsg = this._plTranslateService.translate('abdes.messages.abdescSystemUnLocked', {abonosDescontos: this.titleAbonosDescontos.toLowerCase()});
  }

  public cancel(): Promise<void> {
    return super.cancel().then(() => {
      this.isPerfilSistema = this.model.perfilSistema && this.model.perfilSistema > 0;
    });
  }

  public duplicate(): Promise<void> {
    this.model.perfilSistema = 0;
    this.model.designacaoBreve = '';
    this.model.designacaoCompleta = '';
    return super.duplicate({params: {defaultCRABD: this._cruzamentos}});
  }

  public async newCrabd(): Promise<void> {
    const params: ICRABDEditStateParams = {
      id: this.model.nCodABDESC,
      abdes: this.model.nCodABDESC,
      crabd: {
        tipoAbDescOrigem: this.model.abonoDesc,
        codOrigem: this.model.codAbDesc,
        nCodOrigem: this.model.nCodABDESC,
        tipoAbDescDestino: 1,
        codDestino: '',
        nCodDestino: '',
        entSuporteEmpregado: false,
        entSuportePatronato: false,
        incidenciaEmpregado: 0,
        incidenciaPatronato: 0,
        tipoExcecao: 0,
        excecoes: [],
        categoriaAbDescDestino: 0,
        nomeABDESDestino: '',
        siglaTipoAbdesDestino: '',
        siglaCategoriaAbDescDestino: '',
        nomeExcecao: ''
      }
    };
    await this._maintenanceInstanceCRABDS.maintenanceNew({params: params}).then(undefined);
    return this._getCruzamentos();
  }

  public editCrabd(crabd: ICrAbDGrid): void {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(EditarCruzamentosModalComponent);
    const componentInstance: EditarCruzamentosModalComponent = modalInstance.componentInstance;
    componentInstance.abono = this.isAbono;
    componentInstance.cruzamento = crabd;
    componentInstance.new = this.type === EEntityStateDetailType.NEW;
    modalInstance.result.then((cruzamento: ICrAbDGrid) => {
      if (this._defaultNewCRABDs) {
        const index = this._defaultNewCRABDs.findIndex((value: ICrAbDGrid) => value._index === cruzamento._index);
        this._defaultNewCRABDs[index] = cruzamento;
      }
      this._getCruzamentos();
    });
  }

  public deleteCrabd(crabd: ICrAbDGrid): Promise<void> {
    if (this.type !== EEntityStateDetailType.NEW) {
      return this._crabdsService.delete({id: crabd.nCodOrigem, params: {nCodDestino: crabd.nCodDestino}}).then(() => {
        return this._getCruzamentos();
      });
    }

    if (crabd._index !== -1) {
      this._cruzamentos.splice(crabd._index, 1);
      this._applyDataSourceCruzamentos(true);
    }
    return Promise.resolve();
  }

  public onCodAbDescChange(codAbDesc: string): void {
    this.model.codAbDesc = codAbDesc;
    if (this.isAbono) {
      this.model.nCodABDESC = COD_ABONO_PREFIX + codAbDesc;
    } else {
      this.model.nCodABDESC = COD_DESCONTO_PREFIX + codAbDesc;
    }
    this._reindexCruzamentos();
  }

  public taxaRetencaoChange(value: number): void {
    this.model.procFerias = 0;
    this.model.procNatal = 0;
    if (value === 1) {
      this.model.procNatal = 1;
    } else if (value === 2) {
      this.model.procFerias = 1;
    }
  }

  public grupoAbonoChange(codGrAbono: number): void {
    if (codGrAbono || codGrAbono === 0) {
      this.model.grupoAbono = codGrAbono;
      this._grAbonoAux = this._grAbonos.find((grAbono: IJsonGrAbono) => grAbono.codGrAbono === codGrAbono);
      if (this._grAbonoAux.categoria === ETipoCategoria.FERIAS) {
        this.isNHorasDiaProvDescEnabled = this._grAbonoAux.contaDiasSubAlimen === ETipoContaDiasSubAlimentacao.Sim;
      }
    }
  }

  public grupoDescontoChange(codGrDesconto: number): void {
    if (codGrDesconto || codGrDesconto === 0) {
      this.model.grupoDesconto = codGrDesconto;
      this._grDescontoAux = this._grDescontos.find((grDesconto: IJsonGrDesconto) => grDesconto.codGrDesconto === codGrDesconto);
      if (this._grDescontoAux.categoria === ETipoCategoriaDesc.Falta) {
        this.isNHorasDiaProvDescEnabled = this._grDescontoAux.contaDiasSubAlimen === ETipoContaDiasSubAlimentacao.Sim;
      }
    }
  }

  public save(config?: IApiRequestConfigWithBody<IJsonABDES>): Promise<IJsonABDES> {
    this._booleanToNaoImpDecIRS114();

    if (this.type !== EEntityStateDetailType.NEW) {
      return super.save(config);
    }
    const promise: Promise<IJsonABDES> = this.service.post({body: this.model}).then((response: HttpResponse<IJsonABDES>) => {
      const abdes: IJsonABDES = response.body;
      if (this._defaultNewCRABDs && this.type === EEntityStateDetailType.NEW) {
        for (const crabd of this._cruzamentos) {
          const urlRequest = this.isAbono ? ENTITY_NAME_CRUZAMENTOS_ABONOS : ENTITY_NAME_CRUZAMENTOS_DESCONTOS;
          if (crabd.codDestino) {
            // por defeito o desconto sindicato não vem preenchido, ou seja, não tem código de destino
            this._crabdsService.post({url: urlRequest, body: crabd});
          }
        }
      }
      if (!this.maintenanceMode) {
        return this._cgStateService
          .redirectToState({
            stateOrName: this.entityName === ENTITY_NAME_ABONOS ? ENTITY_NAME_ABONOS : ENTITY_NAME_DESCONTOS,
            stateType: EEntityStateDetailType.DETAIL,
            params: {id: abdes.codAbDesc}
          })
          .then(() => abdes);
      }
      return abdes;
    });
    this._subjectOnSave.next(promise);
    return promise;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async lockUnlockClicked(event: any): Promise<void> {
    if (this.isPerfilSistema) {
      if (!event) {
        return Promise.resolve();
      }
      const modalInstance = this._cgModalService.showVanilla(AbdesEntityEditUnlockPromptModalComponent, {size: 'md'});
      const componentInstance: AbdesEntityEditUnlockPromptModalComponent = modalInstance.componentInstance;
      componentInstance.abono = this.isAbono;
      componentInstance.perfilSistemaAbdesNome = this.perfilSistemaAbdes.designaBreve;
      await modalInstance.result.then((unlock: boolean) => {
        if (unlock) {
          this._abdesModelBeforeUnlock = {...this.model};
          this.model.perfilSistema = 0;
        }
      });
    } else {
      this.model = {...this._abdesModelBeforeUnlock};
      this._abdesModelBeforeUnlock = undefined;
      this._naoImpDecIRS114ToBoolean();
      await this._getCruzamentos();
    }

    this.isPerfilSistema = this.model.perfilSistema && this.model.perfilSistema > 0;
    this.canEditCruzamentos = !this.isPerfilSistema;
    return Promise.resolve();
  }

  private _initAsAbono(): void {
    this.isAbono = true;
    this._maintenanceInstanceCRABDS = this._entityMaintenanceService.build(ENTITY_NAME_CRUZAMENTOS_ABONOS);

    this._grAbonoService.query().then((response: HttpResponse<IApiQueryResponse<IJsonGrAbono>>) => {
      this._grAbonos = response.body.list;
      this.grupoAbonoChange(this.model.grupoAbono);
    });
  }

  private _initAsDesconto(): void {
    this.isAbono = false;
    this._maintenanceInstanceCRABDS = this._entityMaintenanceService.build(ENTITY_NAME_CRUZAMENTOS_DESCONTOS);
    this.model.abonoDesc = Number(COD_DESCONTO_PREFIX);
    this._grDescontoService.query().then((response: HttpResponse<IApiQueryResponse<IJsonGrDesconto>>) => {
      this._grDescontos = response.body.list;
      this.grupoDescontoChange(this.model.grupoDesconto);
    });
  }

  private _copyAbdes(): Promise<void> {
    const params: IRHCopiaAbonosDescontosParams = {
      abdes: this.isAbono ? ETipoAbonoDesconto.Abono : ETipoAbonoDesconto.Desconto,
      codAbdes: this.model.codAbDesc,
      nomeCodAbdes: this.model.designacaoBreve
    };

    return this._maintenanceInstanceRHCopiaAbonosDescontos.maintenance({params: params, closeOnSaved: true});
  }

  private async _generateAbdes(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(GenerateAbdesModalComponent, {size: 'lg'});
    const componentInstance: GenerateAbdesModalComponent = modalInstance.componentInstance;
    componentInstance.abono = this.isAbono;
    const result: IAbDesCrabd = await modalInstance.result;
    if (this.type !== EEntityStateDetailType.NEW) {
      const params: IABDESStateParams = {defaultABDES: result.abdesc, defaultCRABD: result.cruzamentos};
      return this._cgStateService.redirectToState({
        stateOrName: this.entityName === ENTITY_NAME_ABONOS ? ENTITY_NAME_ABONOS : ENTITY_NAME_DESCONTOS,
        stateType: EEntityStateDetailType.NEW,
        params: params
      });
    }
    this.model = result.abdesc;
    this._defaultNewCRABDs = result.cruzamentos;
    this.perfilSistemaAbdes = result.perfilSistemaAbdes;
    this._naoImpDecIRS114ToBoolean();
    await this._getCruzamentos();
    this.isPerfilSistema = this.model?.perfilSistema && this.model?.perfilSistema > 0;
    this.canEditCruzamentos = !this.isPerfilSistema;
    this.abdescSystemLockedWarningMsg = this._plTranslateService.translate('abdes.messages.abdescSystemLocked', {perfilSistemaAbdesNome: this.perfilSistemaAbdes.designaBreve});
    return Promise.resolve();
  }

  private async _getCruzamentos(): Promise<void> {
    this._cruzamentos = this._defaultNewCRABDs || [];
    if (!this._defaultNewCRABDs.length && this.type !== EEntityStateDetailType.NEW) {
      this._cruzamentos = [];
      const urlRequest = this.isAbono ? `crabonos/${this.model.nCodABDESC}` : `crdescontos/${this.model.nCodABDESC}`;
      const result: HttpResponse<IApiQueryResponse<ICrAbDGrid>> = await this._crabdsService.get<IApiQueryResponse<IJsonCRABD>>({url: urlRequest});
      let index = 0;
      for (const crabd of result.body.list) {
        crabd.siglaCategoriaAbDescDestino =
          crabd.tipoAbDescDestino === ETipoAbonoDesconto.Desconto ? ESiglasCategDescontos[crabd.categoriaAbDescDestino] : ESiglasCategAbonos[crabd.categoriaAbDescDestino];
        crabd.siglaTipoAbdesDestino = ESiglaTipoAbonoDesconto[crabd.tipoAbDescDestino];
        crabd.nomeCategoria =
          crabd.tipoAbDescDestino === ETipoAbonoDesconto.Desconto
            ? this._translateService.instant(DATA_SOURCE_TIPOS_CATEGORIA_DESCONTOS.data[crabd.categoriaAbDescDestino].name)
            : this._translateService.instant(DATA_SOURCE_TIPOS_CATEGORIA_ABONOS.data[crabd.categoriaAbDescDestino].name);
        crabd.nomeExcecao = this._translateService.instant(DATA_SOURCE_TIPO_EXCECAO_CRABD.data[crabd.tipoExcecao].name);
        crabd._index = index;
        index++;
        this._cruzamentos.push(crabd);
      }
    }

    this._applyDataSourceCruzamentos(true);
  }

  private _customizeText(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row' && cellInfo.value) {
      if (<number>cellInfo.value < 0) {
        return '0';
      }
    }
    return cellInfo.valueText;
  }

  private _applyDataSourceCruzamentos(reloadIndex: boolean = false): void {
    if (reloadIndex && this._cruzamentos) {
      let index = 0;
      for (const crabd of this._cruzamentos) {
        crabd._index = index;
        index++;
      }
    }
    this.dataGridDefinition.dataSource = new ArrayStore({
      data: this._cruzamentos,
      key: '_index'
    });
  }

  private _naoImpDecIRS114ToBoolean(): void {
    switch (this.model.naoImpDecIRS114) {
      case EDec119EnviaImprime.EnviaImprime:
        this.modelDec119 = {imprimeDec119: true, enviaAT: true};
        break;
      case EDec119EnviaImprime.NaoEnviaNaoImprime:
        this.modelDec119 = {imprimeDec119: false, enviaAT: false};
        break;
      case EDec119EnviaImprime.EnviaNaoImprime:
        this.modelDec119 = {imprimeDec119: false, enviaAT: true};
        break;
      case EDec119EnviaImprime.NaoEnviaImprime:
        this.modelDec119 = {imprimeDec119: true, enviaAT: false};
        break;
      default:
        this.modelDec119 = {imprimeDec119: false, enviaAT: false};
    }
  }

  private _booleanToNaoImpDecIRS114(): void {
    if (this.modelDec119.imprimeDec119) {
      if (this.modelDec119.enviaAT) {
        this.model.naoImpDecIRS114 = EDec119EnviaImprime.EnviaImprime;
      } else {
        this.model.naoImpDecIRS114 = EDec119EnviaImprime.NaoEnviaImprime;
      }
    } else if (this.modelDec119.enviaAT) {
      this.model.naoImpDecIRS114 = EDec119EnviaImprime.EnviaNaoImprime;
    } else {
      this.model.naoImpDecIRS114 = EDec119EnviaImprime.NaoEnviaNaoImprime;
    }
  }

  private _reindexCruzamentos(): void {
    if (this._defaultNewCRABDs) {
      for (const defaultNewCRABD of this._defaultNewCRABDs) {
        defaultNewCRABD.codOrigem = this.model.codAbDesc;
        defaultNewCRABD.nCodOrigem = this.model.nCodABDESC;
      }
    }
    this._getCruzamentos();
  }

  private readonly _fnCustomizeText: TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => this._customizeText(cellInfo);
}
