import {Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {isEmpty, isObject, PlEditBaseComponent} from 'pl-comps-angular';
import {EReport} from '../../../entities/reports/reports.interface';
import {IJsonReport} from '../../../entities/reports/jsonReport.interface';
import {IReportInstance, IReportInstanceOptions, TReportInstanceQueryRequest} from './reports.input.component.interface';
import {ReportsRegistryService} from '../reports.registry.service';
import {isFunction} from 'lodash-es';

@Component({
  selector: 'reports-input',
  templateUrl: './reports.input.component.html'
})
export class ReportsInputComponent extends PlEditBaseComponent<string | IJsonReport, IReportInstanceOptions> implements OnInit, OnChanges {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public selectedKey: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly selectedKeyChange: EventEmitter<any>;

  private _reportName: EReport;
  private _report: IReportInstance;
  private _keyName: string;
  private _source: Array<IJsonReport>;
  private _reFetch: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService
  ) {
    super(_injector);
    this.selectedKeyChange = new EventEmitter<unknown>();
    this._reFetch = true;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._changedProperties();
    this._changedModel();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {model} = changes;
    if (model && !model.isFirstChange()) {
      this._changedModel();
    }
  }

  public updateComponent(properties: IReportInstanceOptions): void {
    super.updateComponent(properties);
    this._changedProperties();
  }

  public updateValue(value: string): void {
    super.updateValue(value);
    this._changedModel(value);
  }

  public fnGetData = (search: string, page: number, perPage: number, filter: string): Promise<Array<IJsonReport>> => this._getData(search, page, perPage, filter);

  public fnSelect = (inputValue: string, item: string): void => {
    this._fnSelect(inputValue, item);
  };

  private _changedProperties(): void {
    if (this.options._report !== this._reportName) {
      this._reportName = this.options._report;
      this._report = this._reportsRegistryService.get(this._reportName);
      this._keyName = this._report.metadata.keyName || 'name';
      this.options.rowTemplate = this.options.rowTemplate || this._report.autocomplete.rowTemplate || `{{title}} - {{${this._keyName}}}`;
      this.options.output = this.options.output || this._report.autocomplete.output || 'title';
    }
    if (this.options.subfolder) {
      this._reFetch = true;
    }
  }

  private async _changedModel(value: string | IJsonReport = this.model): Promise<void> {
    if (isEmpty(value)) {
      return;
    }
    let source: Array<IJsonReport> = this._source;
    if (!source || this._reFetch) {
      this._reFetch = false;
      source = await this._getData();
    }
    const isModelAnObject = isObject(value);
    this.value = source.find((item: IJsonReport) => {
      return isModelAnObject ? item.name === (<IJsonReport>value).name : item[this.options.outputKey || this._keyName] === <string>value;
    });
  }

  private _getData(search?: string, page?: number, perPage?: number, filter?: string): Promise<Array<IJsonReport>> {
    if (page > 1) {
      return Promise.resolve([]);
    }
    const serviceMethod: TReportInstanceQueryRequest = isFunction(this.options.serviceMethodsOverride?.query) ? this.options.serviceMethodsOverride.query : this._report.query.bind(this._report);
    return serviceMethod({
      pesquisa: search,
      pagina: page,
      porpagina: perPage,
      filtro: filter,
      subfolder: this.options.subfolder
    }).then((reports: Array<IJsonReport>) => {
      this._source = reports;
      return this._source;
    });
  }

  private _fnSelect(inputValue: string, item: string): void {
    this.value = item;
    this.selectedKey = isObject(item) ? item[this.options.outputKey || this._keyName] : item;
    this.selectedKeyChange.emit(this.selectedKey);
    if (this.options.inlineMode !== true) {
      this.render();
    } else {
      let value: string = this.selectedKey;
      if (isEmpty(value)) {
        value = null;
      }
      this.render(value);
    }
  }
}
