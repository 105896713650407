import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonPlanoContasAlternativoLin, IJsonPlanoContasAlternativoRadicais} from '../../jsonPlanoContasAlternativo.entity.interface';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {TranslateService} from '@ngx-translate/core';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {ENTITY_NAME_PLANOS_CONTAS_ALTERNATIVOS, IPlanosContasAlternativosEntityService, RADIO_GROUP_TIPO_CONTA_ALT} from '../../planosContasAlternativos.entity.interface';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'planos-contas-alternativos-conta-modal',
  templateUrl: './planosContasAlternativos.conta.modal.component.html'
})
export class PlanosContasAlternativosContaModalComponent extends CGModalComponent<IJsonPlanoContasAlternativoLin> {
  @Input() public linha: IJsonPlanoContasAlternativoLin;
  @Input() public contasAlternativas: Array<IJsonPlanoContasAlternativoRadicais>;
  @Input() public pocAltCabID: string;

  public readonly radioGroupTipoConta: IRadioGroup<ETipoContaContabilidade>;
  public readonly incExcSource: Array<string>;
  public readonly dataGridDefinition: IDevExpressDataGrid;

  public radical: IJsonPlanoContasAlternativoRadicais;

  private readonly _servicePlanosContasAlt: IPlanosContasAlternativosEntityService;
  private readonly _inclui: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._servicePlanosContasAlt = this._entityServiceBuilder.build(ENTITY_NAME_PLANOS_CONTAS_ALTERNATIVOS);
    this.linha = {
      contas: '',
      tipo: ETipoContaContabilidade.Movimento,
      pocAltLinID: '',
      nconta: '',
      nome: '',
      ncontaPai: ''
    };
    this.dataGridDefinition = {
      columnAutoWidth: true,
      columns: [
        {dataField: 'nRadicalConta', caption: 'planoscontasalternativos.fields.nradical'},
        {dataField: 'nome', caption: 'planoscontasalternativos.fields.nome'},
        {dataField: 'incExc', caption: 'planoscontasalternativos.fields.incluiexclui'}
      ],
      dataSource: [],
      export: {enabled: false},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarContasAlt',
            locateInMenu: 'auto'
          }
        ]
      }
    };
    this.contasAlternativas = [];
    this.radioGroupTipoConta = RADIO_GROUP_TIPO_CONTA_ALT;
    this._inclui = this._translateService.instant('planoscontasalternativos.fields.inclui');
    this.incExcSource = [this._inclui, this._translateService.instant('planoscontasalternativos.fields.exclui')];
    this.radical = {nRadicalConta: '', nome: '', incExc: this._inclui};
  }

  public close(): Promise<void> {
    if (isEmpty(this.linha.nconta)) {
      this._plAlertService.error('planoscontasalternativos.modals.conta.ncontaemprty');
      return Promise.resolve();
    }

    const contas: Array<string> = [];
    for (const conta of this.contasAlternativas) {
      contas.push(`${conta.incExc === this._translateService.instant('planoscontasalternativos.fields.inclui') ? '' : '-'}${conta.nRadicalConta}`);
    }
    this.linha.contas = contas.join(',');
    this.disableClose();
    return this._servicePlanosContasAlt
      .updatePCAltLinha(this.pocAltCabID, this.linha)
      .then((response: HttpResponse<IJsonPlanoContasAlternativoLin>) => {
        super.close(response.body);
      })
      .finally(() => {
        this.enableClose();
      });
  }

  public nRadicalChanged(value: string): void {
    if (new RegExp('^[0-9]+$').test(value)) {
      this.radical.nRadicalConta = value;
    } else {
      this.radical = {...this.radical, nRadicalConta: '', nome: ''};
      this._plAlertService.error('planoscontasalternativos.modals.conta.radicalInvalid');
    }
  }

  public addRadical(): void {
    if (isEmpty(this.radical.nRadicalConta)) {
      this._plAlertService.error('planoscontasalternativos.modals.conta.radicalempty');
      return;
    }

    this.contasAlternativas.push({nRadicalConta: this.radical.nRadicalConta, nome: this.radical.nome, incExc: this.radical.incExc});
    this.radical = {nRadicalConta: '', nome: '', incExc: this._inclui};
  }
}
