import {fromEvent, Subscription} from 'rxjs';
import {Component, EventEmitter, HostListener, OnDestroy, Output} from '@angular/core';
import {PlDocumentService} from '../common/document/document.service';

@Component({
  selector: 'pl-split-view-resizer',
  templateUrl: './splitview.resizer.component.html',
  standalone: false
})
export class PlSplitViewResizerComponent implements OnDestroy {
  @Output() public readonly evtMouseDowned: EventEmitter<MouseEvent>;
  @Output() public readonly evtResizerMoved: EventEmitter<MouseEvent>;
  @Output() public readonly evtMouseUpped: EventEmitter<MouseEvent>;

  private _subscriptionMouseMove: Subscription;
  private _subscriptionMouseUp: Subscription;

  constructor(private readonly _plDocumentService: PlDocumentService) {
    this.evtMouseDowned = new EventEmitter<MouseEvent>();
    this.evtResizerMoved = new EventEmitter<MouseEvent>();
    this.evtMouseUpped = new EventEmitter<MouseEvent>();
    this._onMouseMove = this._onMouseMove.bind(this);
    this._onMouseUp = this._onMouseUp.bind(this);
  }

  public ngOnDestroy(): void {
    if (this._subscriptionMouseMove) {
      this._subscriptionMouseMove.unsubscribe();
    }
    if (this._subscriptionMouseUp) {
      this._subscriptionMouseUp.unsubscribe();
    }
  }

  @HostListener('mousedown', ['$event'])
  public onMouseDown(event: MouseEvent): void {
    this._plDocumentService.setDragging(true);
    this._subscriptionMouseMove = fromEvent(event.view.document, 'mousemove', {passive: true}).subscribe(this._onMouseMove);
    this._subscriptionMouseUp = fromEvent(event.view.document, 'mouseup', {passive: true}).subscribe(this._onMouseUp);
    this.evtMouseDowned.emit(event);
  }

  private _onMouseMove(event: MouseEvent): void {
    this.evtResizerMoved.emit(event);
  }

  private _onMouseUp(event: MouseEvent): void {
    this._plDocumentService.setDragging(false);
    if (this._subscriptionMouseMove) {
      this._subscriptionMouseMove.unsubscribe();
    }
    if (this._subscriptionMouseUp) {
      this._subscriptionMouseUp.unsubscribe();
    }
    this.evtMouseUpped.emit(event);
  }
}
