import {NgModule} from '@angular/core';
import {PlLifecycleDirective} from './lifecycle.directive';

export * from './lifecycle.interface';
export * from './lifecycle.directive';

const DECLARATIONS = [
  PlLifecycleDirective
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlLifecycleModule {
}
