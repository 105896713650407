import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IRhEstatisticasPorEmpresaCallback} from '../../rhEstatisticas.module.interface';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'rhestatisticas-por-empresa-modal',
  templateUrl: './rhEstatisticas.porEmpresa.modal.html'
})
export class RhEstatisticasPorEmpresaModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public nEmpresa: string;
  @Input() public nomeEmpresa: string;
  @Input() public ano: number;
  @Input() public mes: number;

  public porEmpresaCallback: IRhEstatisticasPorEmpresaCallback;
  public modalTitle: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.porEmpresaCallback = {};
  }

  public ngOnInit(): void {
    const title: string = this._translateService.instant('global.menu.rhestatisticas');
    this.modalTitle = `${title} - ${this.nomeEmpresa}`;
    setTimeout(() => {
      this.porEmpresaCallback?.search().then(() => undefined);
    });
  }
}
