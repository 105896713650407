import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_DIVIS_TIPO, EDivisTipo} from './divistipo.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_DIVIS_TIPO: IDataSource<EDivisTipo> = deepFreeze({
  name: DATA_SOURCE_NAME_DIVIS_TIPO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EDivisTipo.None, name: 'divisTipo.0', label: 'divisTipo.0'},
    {value: EDivisTipo.TempoCompleto, name: 'divisTipo.1', label: 'divisTipo.1'},
    {value: EDivisTipo.TempoParcial, name: 'divisTipo.2', label: 'divisTipo.2'}
  ]
});
