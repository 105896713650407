import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EMapaPagamImpostoEnum, IJsonMapaPagamEmpresaPagamentoDocumento, IJsonMapaPagamEmpresaPagamentoItem, IMapaPagamATDocumentsSourceItem} from '../../mapaPagamentos.module.interface';
import {DIGITS_INFO_TWO_DECIMALS} from '../../../../../../common/data';
import {PlI18nService} from 'pl-comps-angular';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'module-mapa-pagamentos-detalhes-modal',
  templateUrl: './mapaPagamentos.pagamento.detalhes.modal.component.html'
})
export class MapaPagamentosPagamentoDetalhesModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public pagamento: IJsonMapaPagamEmpresaPagamentoItem;

  public documentosSource: Array<IMapaPagamATDocumentsSourceItem>;
  public readonly docRowTemplate: string;
  public readonly docOutput: string;
  public docSelectedKey: string;
  public docSelected: IJsonMapaPagamEmpresaPagamentoDocumento;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plI18nService: PlI18nService,
    protected readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.docRowTemplate = '{{caption}}';
    this.docOutput = '{{caption}}';
    this.documentosSource = [];
  }

  public ngOnInit(): void {
    this.pagamento.documentos.forEach((item) => {
      this.documentosSource.push({value: item.idDoc, name: item.idDoc});
    });
    this.onDocumentChange(this.documentosSource[0].value);
  }

  public onDocumentChange(idDoc: string): void {
    this.docSelectedKey = idDoc;
    this.docSelected = this.pagamento.documentos.find((item) => {
      return item.idDoc === this.docSelectedKey;
    });
    if (this.docSelected.detalhes.length === 0) {
      this.docSelected.detalhes = [];
      if (this.pagamento.impostoEnum === EMapaPagamImpostoEnum.Manual) {
        this.docSelected.detalhes.push({text: this._translateService.instant('mapapagam.fields.dataLimitePag'), value: moment(this.docSelected.dataLimitePag).format(this.format.momentDate)});
      } else {
        this.docSelected.detalhes.push({text: this._translateService.instant('mapapagam.texts.documento'), value: this.docSelected.idDoc});
      }
      this.docSelected.detalhes.push({text: this._translateService.instant('mapapagam.fields.refPagDescricao'), value: this.docSelected.refPag});
      this.docSelected.detalhes.push({
        text: this._translateService.instant('mapapagam.fields.montante'),
        value: this._plI18nService.formatCurrency(this.docSelected.montante, DIGITS_INFO_TWO_DECIMALS)
      });
    }
  }
}
