export enum ENivelQualificacao {
  None,
  QdSuperiores,
  QdMedios,
  EncarregMestre,
  ProfiAltaQual,
  ProfiQual,
  ProfiSemiQual,
  ProfiNaoQual,
  Estagiarios
}

export interface IJsonNivQl {
  nNivQualific: string;
  designaBreve: string;
  designaCompl: string;
  ruNivQualific: ENivelQualificacao;
}
