<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'docscomerciais.auxiliares.duplicarDocumento'"></h4>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <label><span [translate]="'docscomerciais.doc.cab.nDocFa'"></span>:</label>
      <edit>
        <entity-autocomplete attrName="docfa" entity="docfas" [model]="docFa" [(selectedKey)]="nDocFa" [output]="docfasOutput" [outputKey]="'nDocFa'" outputDescription="nome" plAutoFocus>
        </entity-autocomplete>
      </edit>

      <label [translate]="'clifos.fields.nConta'"></label>
      <edit>
        <entity-autocomplete
          attrName="clifo"
          entity="clifos"
          [model]="clifo"
          (modelChange)="onNContaChanged($event)"
          [(selectedKey)]="nConta"
          [filter]="clifoFilter"
          output="nConta"
          [outputKey]="'nConta'"
          outputDescription="nome">
        </entity-autocomplete>
      </edit>
    </div>
  </div>

  <div class="modal-footer" plNavigation>
    <button [attr.name]="'btnDuplicarDoc'" type="button" class="btn btn-primary btn-sm" [disabled]="closeDisabled" [click]="close" plPromise>
      <i class="fa fa-fw fa-copy"></i><span [translate]="'docscomerciais.auxiliares.duplicarDocumento'"></span>
    </button>

    <button type="button" class="btn btn-light btn-sm action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
      <i class="fa fa-fw fa-times"></i> <span [translate]="'global.btn.close'"></span>
    </button>
  </div>
</pl-form>
