import {Subscription} from 'rxjs';
import {skip} from 'rxjs/operators';
import {Component, OnDestroy} from '@angular/core';
import {StateService, Transition} from '@uirouter/core';
import {AppService} from '../../../services/app/app.service';
import {AuthService} from '../../../services/auth/auth.service';
import {CGStateService} from '../../../components/state/cg.state.service';
import {EAppLaunchMode} from '../../../../common/site';
import {EContratoLockedReason} from '../../../../common/enums/contratolockedreason.enum';
import {IAppStatus} from '../../../services/app/app.service.interface';
import {ILockedContractStateParams} from './lockedcontract.state.interface';
import {STATE_NAME_LOGIN} from '../login/login.state.interface';
import {TUserSession} from '../../../services/account/jsonUserApi.interface';
import {UNDEFINED_ID} from '../../../../config/constants';

const TITLE = 'lockedcontract.title';
const TITLE_TEMPORARY = 'lockedcontract.titleTemporary';

@Component({
  selector: 'locked-contract',
  templateUrl: './lockedcontract.state.component.html'
})
export class LockedContractStateComponent implements OnDestroy {
  public title: string;
  public message: string;
  public partnerMode: boolean;
  public hybridMode: boolean;

  private readonly _subscriptionSession: Subscription;
  private readonly _subscriptionStatus: Subscription;

  constructor(
    private readonly _transition: Transition,
    private readonly _stateService: StateService,
    private readonly _appService: AppService,
    private readonly _authService: AuthService,
    private readonly _cgStateService: CGStateService
  ) {
    this.back = this.back.bind(this);
    this.login = this.login.bind(this);

    const {lockedReason}: ILockedContractStateParams = <ILockedContractStateParams>this._transition.params();
    if (!lockedReason || lockedReason === UNDEFINED_ID) {
      this._cgStateService.goHome();
      return;
    }

    this._evaluateLockedReason(lockedReason);

    this._subscriptionSession = this._authService
      .identityAsObservable()
      .pipe(skip(1))
      .subscribe((session: TUserSession) => {
        if (session) {
          this._evaluateLockedReason(session.lockedReason);
        }
      });

    this._subscriptionStatus = this._appService.status().subscribe((status: IAppStatus) => {
      this.partnerMode = status.launchMode === EAppLaunchMode.Partner;
      this.hybridMode = status.launchMode === EAppLaunchMode.Hybrid || status.launchMode === EAppLaunchMode.HybridPartial;
    });
  }

  public ngOnDestroy(): void {
    if (this._subscriptionSession) {
      this._subscriptionSession.unsubscribe();
    }
    if (this._subscriptionStatus) {
      this._subscriptionStatus.unsubscribe();
    }
  }

  public async back(): Promise<void> {
    await this._cgStateService.goBack();
  }

  public async login(): Promise<void> {
    await this._stateService.go(STATE_NAME_LOGIN);
  }

  private _evaluateLockedReason(lockedReason: EContratoLockedReason): void {
    switch (lockedReason) {
      case EContratoLockedReason.None:
        this._cgStateService.goHome();
        break;
      case EContratoLockedReason.MigratingInstallation:
        this.title = TITLE_TEMPORARY;
        break;
      default:
        this.title = TITLE;
        break;
    }
    this.message = `lockedcontract.reasons.${lockedReason}`;
  }
}
