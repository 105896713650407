import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {copy, isArray, isNumber, isUndefined, PlAlertService, PlGlobalEventsService} from 'pl-comps-angular';
import {API_PORTAL_PREFIX, EGlobalEvent, EStatusCode} from '../../../../../config/constants';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {EPortalType} from '../../../../../common/enums/portals.enums';
import {IJsonPortal} from '../../jsonPortal.entity.interface';
import {IPortalModule, trimPortalModuleIcons} from '../../portal.entity.interface';
import {IUtilizadoresUserRole} from '../../../../modules/utilizadores/utilizadores.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {ROLE} from '../../../../services/role.const';

const IGNORED_ROLES: ReadonlyArray<ROLE> = Object.freeze([ROLE.ANY, ROLE.API, ROLE.REGEX_ROLE]);

@Component({
  selector: 'portal-entity-edit',
  templateUrl: './portal.entity.edit.component.html'
})
export class PortalEntityEditComponent extends ModuloEntityDetailComponent<IJsonPortal> implements OnInit {
  @Input() public roles: Array<IUtilizadoresUserRole>;
  @Input() public portals: Array<IJsonPortal>;

  public menu: Array<IPortalModule>;
  public modules: Array<IPortalModule>;
  public rolesList: Array<ROLE>;
  public filter: string;

  private _userChangedRoles: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plGlobalEventsService: PlGlobalEventsService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._userChangedRoles = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (!isNumber(this.model.sortOrder)) {
      this.model.sortOrder = 0;
    }
    if (!isArray(this.model.data)) {
      this.model.data = [];
    }

    this.menu = this.model.data;

    if (this.type === EEntityStateDetailType.NEW) {
      this.model.tipo = EPortalType.Custom; // definido pelo utilizador
    }

    if (!isArray(this.model.roles)) {
      this.model.roles = [];
    }

    this.rolesList = this.roles
      ? this.roles.filter((role: IUtilizadoresUserRole) => !IGNORED_ROLES.includes(role.role) && !role.role.startsWith(API_PORTAL_PREFIX)).map((role: IUtilizadoresUserRole) => role.role)
      : [];

    for (const portal of this.portals) {
      portal.description = `${portal.name} (${portal.url})`;
    }

    this.btnEdit.tooltip = {disabled: true, text: 'portals.text.editNotAllowed', placement: 'bottom'};
    this.btnDelete.tooltip = {disabled: true, text: 'portals.text.deleteNotAllowed', placement: 'bottom'};
    if (!this.model.tipo) {
      this.btnEdit.disabled = true;
      this.btnEdit.tooltip.disabled = false;
      this.btnDelete.disabled = true;
      this.btnDelete.tooltip.disabled = false;
    } else {
      this.btnEdit.disabled = false;
      this.btnEdit.tooltip.disabled = true;
      this.btnDelete.disabled = false;
      this.btnDelete.tooltip.disabled = true;
    }
  }

  public async onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    await super.onUpdate(stateType);
    if (stateType === EEntityStateDetailType.NEW && this.model?.url) {
      this.model.url = undefined;
    }
  }

  public async save(): Promise<IJsonPortal> {
    // Clean up properties that shouldn't be stored on database
    const model: IJsonPortal = copy(this.model);

    const cleanUpPortalModules = (modules: Array<IPortalModule>): void => {
      for (const module of modules) {
        delete module.title;
        delete module.roles;
        if (!module.name) {
          delete module.name;
        }
        if (!module.pageTitle) {
          delete module.pageTitle;
        }
        if (!module.sidebarTitle) {
          delete module.sidebarTitle;
        }
        if (!module.icon) {
          delete module.icon;
        }
        if (module.visible !== false) {
          delete module.visible;
        }
        if (isArray(module.items) && module.items.length) {
          cleanUpPortalModules(module.items);
        } else {
          delete module.items;
        }
      }
    };

    if (model.icon) {
      model.icon = trimPortalModuleIcons(model.icon);
    }

    if (isArray(model.data) && model.data.length) {
      cleanUpPortalModules(model.data);
    } else {
      delete model.data;
    }

    const response: IJsonPortal = await super.save({body: model});
    this._plGlobalEventsService.broadcast(EGlobalEvent.USER_CHANGED);
    return response;
  }

  public async delete(): Promise<void> {
    const success = (): void => {
      this._plAlertService.success(this._translateService.instant('portals.deleted', {id: this.model.id}));
    };

    try {
      await super.delete();
      success();
    } catch (error: unknown) {
      if (error instanceof HttpErrorResponse && error.status === EStatusCode.Forbidden) {
        const title: string = this._translateService.instant('entity.delete.title', {id: this.model.id});
        await this._cgModalService.showOkCancel(title, 'portals.text.forbiddenMessage');
        await this.service.delete({id: this.model.id, params: {force: true}});
        success();
        await this.back();
      }
    }
  }

  public changedRoles(roles: Array<ROLE>): void {
    this.model.roles = roles;
    this._userChangedRoles = true;
  }

  public changedData(menu: Array<IPortalModule>): void {
    this.model.data = menu;
    if (!this._userChangedRoles) {
      const roles: Map<ROLE, number> = new Map<ROLE, number>();
      this._evaluateRolesCount(menu, roles);
      this.model.roles = [];
      if (roles.size) {
        const rolesCount: Array<[ROLE, number]> = Array.from<[ROLE, number]>(roles);
        const highestCount: [ROLE, number] = rolesCount.reduce((previousValue: [ROLE, number], currentValue: [ROLE, number]) => {
          if (isUndefined(previousValue)) {
            return currentValue;
          }
          return previousValue[1] > currentValue[1] ? previousValue : currentValue;
        });
        const mostFrequentRole: ROLE = highestCount[0];
        this.model.roles = [mostFrequentRole];
      }
    }
  }

  private _evaluateRolesCount(menu: Array<IPortalModule>, roles: Map<ROLE, number>): void {
    for (const menuItem of menu) {
      if (isArray(menuItem.roles)) {
        for (const role of menuItem.roles) {
          if (IGNORED_ROLES.includes(role) || role.startsWith(API_PORTAL_PREFIX)) {
            continue;
          }
          const count: number = roles.get(role) || 0;
          roles.set(role, count + 1);
        }
      }
      if (isArray(menuItem.items)) {
        this._evaluateRolesCount(menuItem.items, roles);
      }
    }
  }
}
