<div class="row">
  <div class="col-12">
    <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>
  </div>
</div>

<div class="conciliacaoBancaria-container" [ngClass]="{'tree-collapsed': treeCollapsed, 'm-0': !isMediumOrHigherScreen}">
  <div class="row">
    <div class="left-panel col-md-12 col-lg-3" *ngIf="!treeCollapsed">
      <div class="box-container">
        <conciliacao-bancaria-bancos-tree-viewer [model]="selectedNode" [collapseOnSelect]="true" [callback]="treeCallback" (modelChange)="changedSelectedNode($event)">
        </conciliacao-bancaria-bancos-tree-viewer>
      </div>
    </div>

    <div class="right-panel" [ngClass]="{'col-md-12 col-lg-9': !treeCollapsed, 'col-md-12 col-lg-12': treeCollapsed}">
      <ng-container *ngIf="blockedByUser">
        <div class="blocked-by-other-user-container">
          <div class="blocked-by-other-user-content">
            <div class="block-inline">
              <i class="fa fa-lock" aria-hidden="true"></i>
            </div>
            <div class="block-inline">
              <h5 [translate]="blockedByUserMessage"></h5>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!selectedBancoCabItem?.nConta">
        <div class="select-placeholder"><i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoBancaria.selectConciliacaoMessage'"></span></div>
      </ng-container>

      <ng-container *ngIf="selectedBancoCabItem?.nConta && !blockedByUser">
        <div class="row" [ngClass]="{'mb-1': isMediumOrHigherScreen}">
          <div class="col-md-12">
            <h6 class="p-2 fw-bold nome-conciliacao-title" [ngClass]="{'mb-1': isMediumOrHigherScreen}" [innerHTML]="conciliacaoTitle"></h6>
          </div>
        </div>
        <div class="box-container">
          <pl-tabs (evtSelected)="changedTab($event.nextId)" [activeId]="activeTab">
            <pl-tab [id]="conciliacaoBancariaMainTabId" caption="conciliacaoBancaria.tabMainTitle">
              <div *plTabContent>
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div class="conciliacao-title" [translate]="'conciliacaoBancaria.extratoContabilidadeTitle'"></div>

                    <ng-container *ngIf="conciliacao?.podeEditarConciliacao">
                      <pl-toolbar [instanceId]="toolbarInstanceIdExtratoCG"></pl-toolbar>
                      <hr />
                    </ng-container>

                    <div class="conciliacaoBancaria-table conciliacaoBancaria-table-header" [ngClass]="{'scroll-padding': extratoCGHaveScroll}">
                      <div class="conciliacaoBancaria-table-cell header col-action" *ngIf="conciliacao?.podeEditarConciliacao">
                        <pl-edit type="checkbox" [model]="extratoCGSelectAll" (modelChange)="extratoCGToggleSelectAll($event)"></pl-edit>
                      </div>
                      <div class="conciliacaoBancaria-table-cell header col-w-80" [translate]="'global.text.date'" *ngIf="colDataVisible"></div>
                      <div class="conciliacaoBancaria-table-cell header col-w-80" [translate]="'conciliacaoBancaria.fields.dataLacam'" *ngIf="colDataLancVisible"></div>
                      <div class="conciliacaoBancaria-table-cell header col-w-100">
                        <pl-tooltip [config]="{text: 'conciliacaoBancaria.ordenarPorDataDocExt', placement: 'top', container: 'body'}">
                          <div *plTooltipContent class="sortable" (click)="doSortList(listType.ExtratoCG, 'dataDocExt')">
                            <span [translate]="'conciliacaoBancaria.fields.dataDocExt'"></span>&nbsp;
                            <i
                              *ngIf="sortExtratoCG.column === 'dataDocExt'"
                              class="fa"
                              aria-hidden="true"
                              [ngClass]="{'fa-sort-amount-desc': sortExtratoCG.sort === 'desc', 'fa-sort-amount-asc': sortExtratoCG.sort === 'asc'}"></i>
                          </div>
                        </pl-tooltip>
                      </div>
                      <div class="conciliacaoBancaria-table-cell header">
                        <pl-tooltip [config]="{text: 'conciliacaoBancaria.ordenarPorNDoc', placement: 'top', container: 'body'}">
                          <div *plTooltipContent class="sortable" (click)="doSortList(listType.ExtratoCG, 'nDoc')">
                            <span [translate]="'conciliacaoBancaria.fields.ndoc'"></span>&nbsp;
                            <i
                              *ngIf="sortExtratoCG.column === 'nDoc'"
                              class="fa"
                              aria-hidden="true"
                              [ngClass]="{'fa-sort-amount-desc': sortExtratoCG.sort === 'desc', 'fa-sort-amount-asc': sortExtratoCG.sort === 'asc'}"></i>
                          </div>
                        </pl-tooltip>
                      </div>
                      <div class="conciliacaoBancaria-table-cell header" [translate]="'conciliacaoBancaria.fields.nDocExterno'" *ngIf="colNDocExtVisible"></div>
                      <div class="conciliacaoBancaria-table-cell header" *ngIf="colDescricaoVisible">
                        <pl-tooltip [config]="{text: 'conciliacaoBancaria.ordenarPorDescricao', placement: 'top', container: 'body'}">
                          <div *plTooltipContent class="sortable" (click)="doSortList(listType.ExtratoCG, 'descricao')">
                            <span [translate]="'conciliacaoBancaria.fields.descricao'"></span>&nbsp;
                            <i
                              *ngIf="sortExtratoCG.column === 'descricao'"
                              class="fa"
                              aria-hidden="true"
                              [ngClass]="{'fa-sort-amount-desc': sortExtratoCG.sort === 'desc', 'fa-sort-amount-asc': sortExtratoCG.sort === 'asc'}"></i>
                          </div>
                        </pl-tooltip>
                      </div>
                      <div class="conciliacaoBancaria-table-cell header col-w-100 text-right">
                        <pl-tooltip [config]="{text: 'conciliacaoBancaria.ordenarPorValor', placement: 'top', container: 'body'}">
                          <div *plTooltipContent class="sortable" (click)="doSortList(listType.ExtratoCG, 'valor')">
                            <span [translate]="getColCaption('valor')"></span>&nbsp;
                            <i
                              *ngIf="sortExtratoCG.column === 'valor'"
                              class="fa"
                              aria-hidden="true"
                              [ngClass]="{'fa-sort-amount-desc': sortExtratoCG.sort === 'desc', 'fa-sort-amount-asc': sortExtratoCG.sort === 'asc'}"></i>
                          </div>
                        </pl-tooltip>
                      </div>
                      <div class="conciliacaoBancaria-table-cell header col-w-60" [translate]="'conciliacaoBancaria.fields.dc'" *ngIf="colDCVisible"></div>
                      <div class="conciliacaoBancaria-table-cell header col-w-100 text-right" [translate]="getColCaption('valorSemDC')" *ngIf="colValorSemDCVisible"></div>
                      <div class="conciliacaoBancaria-table-cell header col-action" *ngIf="conciliacao?.podeEditarConciliacao"></div>
                      <div class="conciliacaoBancaria-table-cell header col-action" *ngIf="conciliacao?.podeEditarConciliacao"></div>
                    </div>

                    <cdk-virtual-scroll-viewport class="virtual-scroll-viewport" [style.height.px]="vScrollHeight" [itemSize]="virtualScrollItemSize" #cdkVirtualScrollViewport>
                      <ng-container *cdkVirtualFor="let extrato of conciliacao?.extratoCG?.extPocList; index as $index">
                        <div
                          (dblclick)="onExtratoRowDoubleClick(extrato)"
                          (click)="onExtratoRowClick($event, extrato)"
                          [attr.data-id]="extrato.nLanc"
                          class="conciliacaoBancaria-table data"
                          [id]="getExtPocRowId(extrato)"
                          [ngClass]="getExtPocRowClass(extrato)">
                          <ng-container>
                            <div class="conciliacaoBancaria-table-cell col-action" *ngIf="conciliacao?.podeEditarConciliacao">
                              <pl-edit type="checkbox" [(model)]="extrato._selected" (modelChange)="onExtPocItemSelect($event, extrato)"></pl-edit>
                            </div>
                            <div class="conciliacaoBancaria-table-cell col-w-80" *ngIf="colDataVisible">{{ extrato.data | cgcDate }}</div>
                            <div class="conciliacaoBancaria-table-cell col-w-80" *ngIf="colDataLancVisible">{{ extrato.dataLacam | cgcDate }}</div>
                            <div class="conciliacaoBancaria-table-cell col-w-100">{{ extrato.dataDocExt | cgcDate }}</div>
                            <div class="conciliacaoBancaria-table-cell">{{ extrato.nDoc }}</div>
                            <div class="conciliacaoBancaria-table-cell" [attr.title]="extrato.nDocExterno" *ngIf="colNDocExtVisible">{{ extrato.nDocExterno }}</div>
                            <div class="conciliacaoBancaria-table-cell" [attr.title]="extrato.descricao" *ngIf="colDescricaoVisible">{{ extrato.descricao }}</div>
                            <div class="conciliacaoBancaria-table-cell col-w-100 text-right col-valor" [ngClass]="{'valor-neg': extrato.valorSemDCInv < 0}" [innerHTML]="extrato.valorDisplay"></div>
                            <div class="conciliacaoBancaria-table-cell col-w-60" *ngIf="colDCVisible">{{ extrato.dcStr }}</div>
                            <div class="conciliacaoBancaria-table-cell col-w-100 text-right" *ngIf="colValorSemDCVisible" [innerHTML]="extrato.valorSemDCDisplay"></div>
                            <div class="conciliacaoBancaria-table-cell col-action">
                              <ng-container *ngIf="extrato._state === recordState.PENDING">
                                <button type="button" class="btn btn-xs btn-link btn-pending">
                                  <i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i>
                                </button>
                              </ng-container>

                              <div class="d-inline-block" ngbDropdown container="body" *ngIf="extrato._state !== recordState.PENDING">
                                <button type="button" class="btn btn-link btn-sm col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-bars" aria-hidden="true"></i></button>
                                <div ngbDropdownMenu>
                                  <button type="button" (click)="extratoCGEditLanc(extrato)" [disabled]="isDisabledExtratoCGEditLanc(extrato)" ngbDropdownItem>
                                    <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoBancaria.editLanc'"></span>
                                  </button>

                                  <button type="button" (click)="associarExtrato(extrato)" [disabled]="isDisabledAssociarExtrato(extrato)" ngbDropdownItem>
                                    <i class="fa fa-link" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoBancaria.associarExtratoTooltip'"></span>
                                  </button>

                                  <button type="button" (click)="extratoCGRemoveLinha(extrato)" [disabled]="isDisabledExtratoCGRemoveLinha(extrato)" ngbDropdownItem>
                                    <i class="fa fa-times" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoBancaria.removeLinha'"></span>
                                  </button>

                                  <button type="button" (click)="extratoCGDesassociarLinha(extrato)" [disabled]="isDisabledExtratoCGDesassociarLinha(extrato)" ngbDropdownItem>
                                    <i class="fa fa-chain-broken" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoBancaria.desassociarLinha'"></span>
                                  </button>

                                  <button type="button" (click)="extratoCGViewLanc(extrato)" [disabled]="isDisabledExtratoCGViewLanc(extrato)" ngbDropdownItem>
                                    <i class="fa fa-eye" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoBancaria.viewDocTooltip'"></span>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div class="conciliacaoBancaria-table-cell col-action">
                              <button
                                type="button"
                                class="btn-associar-fast"
                                [class.btn-associar-fast-both]="extrato._selected && countSelecionadosCG > 0 && countSelecionadosBanco > 0"
                                (click)="associarExtratoFast(extrato)"
                                [disabled]="isDisabledAssociarExtrato(extrato)"
                                [attr.title]="getAssociarExtratoTooltipText(extrato)">
                                <i class="fa fa-angle-right" aria-hidden="true"></i>
                              </button>
                            </div>
                          </ng-container>
                        </div>
                      </ng-container>
                    </cdk-virtual-scroll-viewport>

                    <hr />

                    <div class="row">
                      <div class="col-md-6">
                        <div class="footer-saldos" *ngIf="valorTotalSelecionadoCG !== 0">
                          <span class="footer-saldos-block">
                            <span><strong [translate]="'conciliacaoBancaria.totalSelecionado'"></strong></span>
                            <span class="badge text-bg-light" [innerHTML]="getSaldoString(valorTotalSelecionadoCG, true)"></span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="search-holder">
                      <pl-group *ngIf="conciliacao?.podeEditarConciliacao">
                        <edit>
                          <pl-edit
                            type="text"
                            attrName="expPocListFilterInput"
                            [model]="expPocListValueFilter"
                            (modelChange)="onExpPocListFilterChange($event)"
                            [properties]="{placeholder: 'conciliacaoBancaria.searchByValue', events: {keydown: fnKeydownExpPocFilter}}">
                          </pl-edit>
                        </edit>
                      </pl-group>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div class="conciliacao-title" [translate]="'conciliacaoBancaria.extratoBancoTitle'"></div>

                    <ng-container *ngIf="conciliacao?.podeEditarConciliacao">
                      <pl-toolbar [instanceId]="toolbarInstanceIdExtratoBanco"></pl-toolbar>

                      <hr />
                    </ng-container>

                    <div class="conciliacaoBancaria-table conciliacaoBancaria-table-header" [ngClass]="{'scroll-padding': extratoBancoHaveScroll}">
                      <div class="conciliacaoBancaria-table-cell header col-action" *ngIf="conciliacao?.podeEditarConciliacao">
                        <pl-edit type="checkbox" [model]="extratoBancoSelectAll" (modelChange)="extratoBancoToggleSelectAll($event)"></pl-edit>
                      </div>
                      <div class="conciliacaoBancaria-table-cell header col-w-80">
                        <pl-tooltip [config]="{text: 'conciliacaoBancaria.ordenarPorData', placement: 'top', container: 'body'}">
                          <div *plTooltipContent class="sortable" (click)="doSortList(listType.ExtratoBanco, 'data')">
                            <span [translate]="'global.text.date'"></span>&nbsp;
                            <i
                              *ngIf="sortExtratoBanco.column === 'data'"
                              class="fa"
                              aria-hidden="true"
                              [ngClass]="{'fa-sort-amount-desc': sortExtratoBanco.sort === 'desc', 'fa-sort-amount-asc': sortExtratoBanco.sort === 'asc'}"></i>
                          </div>
                        </pl-tooltip>
                      </div>
                      <div class="conciliacaoBancaria-table-cell header">
                        <pl-tooltip [config]="{text: 'conciliacaoBancaria.ordenarPorDescricao', placement: 'top', container: 'body'}">
                          <div *plTooltipContent class="sortable" (click)="doSortList(listType.ExtratoBanco, 'descricao')">
                            <span [translate]="'conciliacaoBancaria.fields.descricao'"></span>&nbsp;
                            <i
                              *ngIf="sortExtratoBanco.column === 'descricao'"
                              class="fa"
                              aria-hidden="true"
                              [ngClass]="{'fa-sort-amount-desc': sortExtratoBanco.sort === 'desc', 'fa-sort-amount-asc': sortExtratoBanco.sort === 'asc'}"></i>
                          </div>
                        </pl-tooltip>
                      </div>
                      <div class="conciliacaoBancaria-table-cell header col-w-60" [translate]="'conciliacaoBancaria.fields.dc'" *ngIf="colBancoDCVisible"></div>
                      <div class="conciliacaoBancaria-table-cell header col-w-100" [translate]="'conciliacaoBancaria.fields.tipo'" *ngIf="colTipoVisible"></div>
                      <div class="conciliacaoBancaria-table-cell header col-w-100 text-right">
                        <pl-tooltip [config]="{text: 'conciliacaoBancaria.ordenarPorValor', placement: 'top', container: 'body'}">
                          <div *plTooltipContent class="sortable" (click)="doSortList(listType.ExtratoBanco, 'valor')">
                            <span [translate]="getColCaption('valor')"></span>&nbsp;
                            <i
                              *ngIf="sortExtratoBanco.column === 'valor'"
                              class="fa"
                              aria-hidden="true"
                              [ngClass]="{'fa-sort-amount-desc': sortExtratoBanco.sort === 'desc', 'fa-sort-amount-asc': sortExtratoBanco.sort === 'asc'}"></i>
                          </div>
                        </pl-tooltip>
                      </div>
                      <div class="conciliacaoBancaria-table-cell header col-w-100 text-right" [translate]="'global.text.saldo'" *ngIf="colSaldoVisible"></div>
                      <div class="conciliacaoBancaria-table-cell header" [translate]="'conciliacaoBancaria.fields.stampUpdate'" *ngIf="colStampUpdateVisible"></div>
                      <div class="conciliacaoBancaria-table-cell header col-w-100 text-right" [translate]="getColCaption('valorSemDC')" *ngIf="colBancoValorSemDCVisible"></div>
                      <div class="conciliacaoBancaria-table-cell header col-w-100" [translate]="'global.text.note'"></div>
                      <div class="conciliacaoBancaria-table-cell header col-action"></div>
                      <div class="conciliacaoBancaria-table-cell header col-action"></div>
                    </div>

                    <cdk-virtual-scroll-viewport class="virtual-scroll-viewport" [style.height.px]="vScrollHeight" [itemSize]="virtualScrollItemSize" #cdkVirtualScrollViewportExtratoBanco>
                      <div class="conciliacaoBancaria-table">
                        <ng-container *cdkVirtualFor="let extrato of conciliacao?.extratoBanco?.concilBancoList">
                          <div
                            (click)="onExtratoBancoRowClick($event, extrato)"
                            (dblclick)="onExtratoBancoRowDoubleClick(extrato)"
                            [id]="getExtratoBancoRowId(extrato)"
                            class="conciliacaoBancaria-table data"
                            [ngClass]="getExtratoBancoRowClass(extrato)">
                            <div class="conciliacaoBancaria-table-cell col-action" *ngIf="conciliacao?.podeEditarConciliacao">
                              <pl-edit type="checkbox" [model]="extrato._selected" (modelChange)="onExtratoBancoItemSelect($event, extrato)"></pl-edit>
                            </div>
                            <div class="conciliacaoBancaria-table-cell col-w-80">{{ extrato.data | cgcDate }}</div>
                            <div class="conciliacaoBancaria-table-cell">{{ extrato.descricao }}</div>
                            <div class="conciliacaoBancaria-table-cell col-w-60" *ngIf="colBancoDCVisible">{{ extrato.dcStr }}</div>
                            <div class="conciliacaoBancaria-table-cell col-w-100" *ngIf="colTipoVisible">{{ extrato.tipoStr }}</div>
                            <div class="conciliacaoBancaria-table-cell col-w-100 text-right col-valor" [ngClass]="{'valor-neg': extrato.valorSemDC < 0}">{{ extrato.valorSemDC | cgcDecimal }}</div>
                            <div class="conciliacaoBancaria-table-cell col-w-100 text-right" *ngIf="colSaldoVisible">{{ extrato.saldo | cgcDecimal }}</div>
                            <div class="conciliacaoBancaria-table-cell" *ngIf="colStampUpdateVisible">{{ extrato.stampUpdate | cgcDateTime }}</div>
                            <div class="conciliacaoBancaria-table-cell col-w-100 text-right" *ngIf="colBancoValorSemDCVisible">{{ extrato.valor | cgcDecimal }}</div>
                            <div class="conciliacaoBancaria-table-cell col-nota col-w-100">
                              <pl-inline-edit
                                type="text"
                                class="clifo-rua-info"
                                [model]="extrato.nota"
                                (modelChange)="extrato.nota = $event; onNotaChanged(extrato)"
                                [properties]="{modelOptions: {debounce: 500}}">
                              </pl-inline-edit>
                            </div>
                            <div class="conciliacaoBancaria-table-cell col-action">
                              <ng-container *ngIf="extrato._state === recordState.PENDING">
                                <button type="button" class="btn btn-xs btn-link btn-pending">
                                  <i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i>
                                </button>
                              </ng-container>

                              <div class="d-inline-block" ngbDropdown container="body" *ngIf="extrato._state !== recordState.PENDING">
                                <button type="button" class="btn btn-link btn-sm col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-bars" aria-hidden="true"></i></button>
                                <div ngbDropdownMenu>
                                  <button type="button" (click)="extratoBancoEditLanc(extrato)" [disabled]="isDisabledExtratoBancoEditLanc(extrato)" ngbDropdownItem>
                                    <i class="fa fa-pencil" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoBancaria.editLanc'"></span>
                                  </button>

                                  <button type="button" (click)="removeLinhaExtratoBanco(extrato)" [disabled]="isDisabledExtratoBancoRemoveLinha(extrato)" ngbDropdownItem>
                                    <i class="fa fa-times" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoBancaria.removeLinha'"></span>
                                  </button>

                                  <button type="button" (click)="extratoBancoDesassociarLinha(extrato)" [disabled]="isDisabledExtratoBancoDesassociarLinha(extrato)" ngbDropdownItem>
                                    <i class="fa fa-chain-broken" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoBancaria.desassociarLinha'"></span>
                                  </button>

                                  <button type="button" (click)="addLinhaExtratoBancarioAContabilidade(extrato)" [disabled]="isDisabledAddLinhaExtratoBancarioAContabilidade(extrato)" ngbDropdownItem>
                                    <i class="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoBancaria.adicionarAContabilidade'"></span>
                                  </button>

                                  <button type="button" (click)="extratoBancoViewLanc(extrato)" [disabled]="isDisabledExtratoBancoViewLanc(extrato)" ngbDropdownItem>
                                    <i class="fa fa-eye" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoBancaria.viewDocTooltip'"></span>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div class="conciliacaoBancaria-table-cell col-action">
                              <button type="button" class="btn-associar-fast" (click)="removeLinhaExtratoBanco(extrato)" [disabled]="isDisabledExtratoBancoRemoveLinha(extrato)">
                                <i class="fa fa-angle-left" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </cdk-virtual-scroll-viewport>

                    <hr />

                    <div class="row">
                      <div class="col-md-6">
                        <div class="footer-saldos" *ngIf="valorTotalSelecionadoBanco !== 0">
                          <span class="footer-saldos-block">
                            <span><strong [translate]="'conciliacaoBancaria.totalSelecionado'"></strong></span>
                            <span class="badge text-bg-light" [innerHTML]="getSaldoString(valorTotalSelecionadoBanco)"></span>
                          </span>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="footer-saldos text-right">
                          <span class="footer-saldos-block">
                            <span><strong [translate]="'conciliacaoBancaria.fields.diferencaConciliacao'"></strong></span>
                            <pl-tooltip [config]="{text: 'conciliacaoBancaria.maisInfoSobreSaldos', placement: 'left', container: 'body'}">
                              <span *plTooltipContent (click)="verDetalhesSaldo()" class="badge bg-primary difConcilBadge" [ngClass]="{'bg-primary': !conciliado(), 'bg-success': conciliado()}">
                                <i class="fa fa-info-circle pull-left" aria-hidden="true"></i>
                                <span [innerHTML]="getSaldoString(conciliacao?.extratoBanco?.diferencaConciliacao)"></span>
                              </span>
                            </pl-tooltip>
                            <div class="is-conciliado-label" *ngIf="conciliado()">
                              <i class="fa fa-check" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoBancaria.conciliadoLabel'"></span>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-7">
                    <div class="legend-label"><span [translate]="'conciliacaoBancaria.legendaLabel'"></span>:</div>
                    <ul class="legend-list">
                      <li *ngFor="let legend of legends" [ngClass]="{white: legend.cssClass === 'linha-nao-conciliada'}">
                        <span class="legend-color" [ngClass]="legend.cssClass"></span>
                        <span [innerHTML]="legend.text"></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </pl-tab>

            <pl-tab [id]="conciliacaoBancariaAttachmentsTabId" caption="conciliacaoBancaria.tabAttachmentsTitle">
              <div *plTabContent>
                <gdocs-viewer
                  [folderId]="gdocFolderId"
                  [showTreeViewer]="false"
                  [treeCollapsed]="false"
                  [params]="gdocParams"
                  [tipoDocEntity]="gdocTipoDocEntity"
                  [callback]="gdocCallback"
                  [showExtension]="true">
                </gdocs-viewer>
              </div>
            </pl-tab>
          </pl-tabs>

          <div class="box-locker" *ngIf="lockPromise">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #templateMostrarLinhasConciliadasExtratoCG>
  <pl-edit
    type="checkbox"
    attrName="mostrarLinhasConciliadasLabel"
    [model]="mostrarLinhasConciliadasExtratoCG"
    (modelChange)="onMostrarLinhasConciliadasExtratoCG($event)"
    [properties]="{label: 'conciliacaoBancaria.mostrarLinhasConciliadasLabel', disabled: !conciliacao?.podeEditarConciliacao}">
  </pl-edit>
</ng-template>

<ng-template #templateMostrarLinhasConciliadasExtratoBanco>
  <pl-edit
    type="checkbox"
    attrName="mostrarLinhasConciliadasLabel"
    [model]="mostrarLinhasConciliadasExtratoBanco"
    (modelChange)="onMostrarLinhasConciliadasExtratoBanco($event)"
    [properties]="{label: 'conciliacaoBancaria.mostrarLinhasConciliadasLabel', disabled: !conciliacao?.podeEditarConciliacao}">
  </pl-edit>
</ng-template>
