import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {IPlTooltipConfig, isArray} from 'pl-comps-angular';
import {IKeyBindingsHelperAction} from './keybindings.helper.component.interface';

@Component({
  selector: 'keybindings-helper',
  templateUrl: './keybindings.helper.component.html'
})
export class KeyBindingsHelperComponent implements OnInit, OnChanges {
  @Input() public actions: IKeyBindingsHelperAction | Array<IKeyBindingsHelperAction>;
  @Input() public tooltipConfig: Partial<IPlTooltipConfig>;

  public helperActions: Array<IKeyBindingsHelperAction>;
  public tooltip: IPlTooltipConfig;

  private _templateActions: TemplateRef<void>;

  constructor() {
    this.helperActions = [];
  }

  public ngOnInit(): void {
    this._changedTooltipConfig();
    this._changedActions();
  }

  public ngOnChanges({tooltipConfig, actions}: SimpleChanges): void {
    if (tooltipConfig && !tooltipConfig.isFirstChange()) {
      this._changedTooltipConfig(tooltipConfig.currentValue);
    }
    if (actions && !actions.isFirstChange()) {
      this._changedActions(actions.currentValue);
    }
  }

  @ViewChild('templateActions')
  public set templateActions(value: TemplateRef<void>) {
    this._templateActions = value;
    if (!this.tooltip) {
      this._changedTooltipConfig();
    } else {
      this.tooltip = {...this.tooltip, templateRef: this._templateActions};
    }
  }

  private _changedTooltipConfig(value: Partial<IPlTooltipConfig> = this.tooltipConfig): void {
    this.tooltip = {...this.tooltip, ...value, templateRef: this._templateActions};
    this.tooltip.placement = ['bottom-left', 'left', 'auto'];
  }

  private _changedActions(value: IKeyBindingsHelperAction | Array<IKeyBindingsHelperAction> = this.actions): void {
    this.helperActions = isArray(value) ? value : [value];
  }
}
