import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_TRANS_SAL} from './rhSalToTrf.module.interface';
import {RHSalToTrfModuleComponent} from './components/rhSalToTrf.module.component';
import {ROLE} from '../../../services/role.const';

export const MODULE_RH_TRANS_SAL: IModuleDefinition = {
  name: MODULE_NAME_RH_TRANS_SAL,
  nameAlias: ['rhsaltotrfvencimentos'],
  state: {
    url: '/transferenciassalarios',
    component: RHSalToTrfModuleComponent,
    data: {
      roles: [ROLE.RH],
      sidebarTitle: 'portals.sidebar.modules.rhsaltotrf'
    }
  }
};
