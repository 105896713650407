<div class="rh-dgemps-list">
  <pl-alert type="warning" *ngIf="temInconsistenciasTabelaIRS && inconsistenciasTabelaIRS.mostrarnotificacao" [autoClose]="false" (evtClosed)="inconsistenciasTabelaIRSNotifClose()">
    <i class="fa fa-lg fa-fw fa-exclamation-circle me-1"></i>
    <span [translate]="'dgempsfull.tabelaIRSColaboradoresPorValidar'"></span>
    <ul>
      <li *ngFor="let item of inconsistenciasTabelaIRS.empregadosinconsistentes">
        <span class="hyperlink hyperlink-text-color-white" (click)="openDGEMP(item.codEmp)">{{ item.nome }}</span>
      </li>
    </ul>
  </pl-alert>

  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [dataGridState]="dataGridState"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [filterCollapsed]="false"
    [toolbarInstanceId]="instanceName"
    [properties]="listOptions"
    [onFiltered]="fnOnFiltered"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
  </entity-list>
</div>
