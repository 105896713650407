import {Component} from '@angular/core';
import moment from 'moment';
import {VERSION} from '../../../../environments/constants';

@Component({
  selector: 'account',
  templateUrl: './account.state.component.html'
})
export class AccountComponent {
  public readonly date: number;
  public readonly version: string;

  private _errorCount: number;
  private _errorMessage: string;

  constructor() {
    this.date = moment().year();
    this.version = VERSION;
    this._errorCount = 0;
    this._errorMessage = '';
  }

  public setHasError(value: boolean): void {
    this._errorCount = value ? this._errorCount + 1 : 0;
  }

  public get errorCount(): number {
    return this._errorCount;
  }

  public get errorMessage(): string {
    return this._errorMessage;
  }

  public set errorMessage(value: string) {
    this._errorMessage = value;
  }
}
