import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EClifoExtraValueTipo} from '../../../../clifoextravaluetipo/jsonClifoExtraValueTipo.entity.interface';
import {ENTITY_NAME_CLIFOEXTRAVALUES} from '../../../../clifoextravalue/clifoExtraValue.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonClifoExtraValue} from '../../../../clifoextravalue/jsonClifoExtraValue.entity.interface';
import {TServiceQueryResponse, TServiceResponse} from '../../../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class DefinicaoEmailService {
  private readonly _serviceClifoExtraValues: IEntityService<IJsonClifoExtraValue>;

  constructor(private readonly _entityServiceBuilder: EntityServiceBuilder) {
    this._serviceClifoExtraValues = this._entityServiceBuilder.build(ENTITY_NAME_CLIFOEXTRAVALUES);
  }

  public query(search?: string): TServiceQueryResponse<IJsonClifoExtraValue> {
    search = search ? `${search}&natureza=1` : 'natureza=1';
    return this._serviceClifoExtraValues.query({pesquisa: search});
  }

  public queryByNContaTipo(nConta: string, tipo: EClifoExtraValueTipo): TServiceQueryResponse<IJsonClifoExtraValue> {
    const search = `nconta=${nConta}&codigo=${tipo}&natureza=1`;
    return this._serviceClifoExtraValues.query({pesquisa: search});
  }

  public post(clifoExtraValue: IJsonClifoExtraValue): TServiceResponse<IJsonClifoExtraValue> {
    return this._serviceClifoExtraValues.post({body: clifoExtraValue});
  }

  public delete(clifoExtraValue: IJsonClifoExtraValue): TServiceResponse<HttpResponse<void>> {
    return this._serviceClifoExtraValues.delete({id: clifoExtraValue.nConta, params: {codigo: clifoExtraValue.codigo}});
  }
}
