<div class="cessacaoconttrab-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rhcessacontratotrabalho.modal.encerrar.cessacaocontrat'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <edit>
        <span [translate]="'rhcessacontratotrabalho.fields.tipoProcessamento'" class="pe-2"></span>
        <span>{{ cessaContTrabForm.tipoProcessamento }} - {{ cessaContTrabForm.nomeTipoProcessamento }}</span>
      </edit>
    </pl-group>

    <pl-group>
      <edit>
        <span [translate]="'rhcessacontratotrabalho.fields.codEmp'" class="pe-2"></span>
        <span>{{ cessaContTrabForm.codEmp }} - {{ cessaContTrabForm.nomeEmp }}</span>
      </edit>
    </pl-group>

    <pl-group>
      <edit>
        <span [translate]="'rhcessacontratotrabalho.fields.datacessacao'" class="pe-2"></span>
        <span>{{ cessaContTrabForm.dataCessacao | cgcDate }}</span>
      </edit>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'rhcessacontratotrabalho.fields.situacao'"></label>
        <edit>
          <entity-autocomplete
            entity="situacaoempregado"
            attrName="situacao"
            [model]="cessaContTrabForm"
            (selectedKeyChange)="cessaContTrabForm.situacao = $event"
            (evtSelectedDescriptionChanged)="cessaContTrabForm.nomeSituacao = $event"
            [fieldsMap]="{nSitEmpreg: 'situacao', designaBreve: 'nomeSituacao'}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'rhmanutencao.fields.motivoCessacao'"></label>
        <edit>
          <entity-autocomplete
            entity="tiposregistocadastro"
            attrName="motivo"
            [model]="cessaContTrabForm"
            (selectedKeyChange)="cessaContTrabForm.motivo = $event"
            (evtSelectedDescriptionChanged)="cessaContTrabForm.nomeMotivo = $event"
            [fieldsMap]="{nMotivoMov: 'motivo', designaBreve: 'nomeMotivo'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="cessacaoconttrabmodal"
      [dataSource]="dataGridDefinition.dataSource"
      (onInitialized)="onInitialized($event)"
      (onRowInserting)="onRowInserting($event)">
      <div *dxTemplate="let item of 'templateToolbarAddBtn'">
        <dx-button [hint]="'global.btn.addLine' | translate" (click)="addLineEncerrarList()" icon="fa fa-fw fa-plus"></dx-button>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-save" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
