import {ContabilidadeExtratosComponent} from '../extratosdecontas/contabilidade.extratosDeContas.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_PCA_ESTATISTICA_EXTRATOS} from './contabilidade.extratos.module.interface';
import {ROLE} from '../../../../services/role.const';

export const MODULE_PCA_ESTATISTICA_EXTRATOS: IModuleDefinition = {
  name: MODULE_NAME_PCA_ESTATISTICA_EXTRATOS,
  state: {
    url: '/extratos',
    component: ContabilidadeExtratosComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.ATIVOS, ROLE.ERP]
    },
    params: {
      deConta: {
        type: 'string',
        value: ''
      },
      ateConta: {
        type: 'string',
        value: ''
      }
    }
  }
};
