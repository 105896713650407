import {Component, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {
  ERHAnaliseMensalRecordType,
  ERHEstatisticasEstatisticasTabs,
  ERHEstatisticasFilterType,
  ERHEstatisticasNavTabs,
  ERHTipoRegisto,
  IJsonRHEstatisticasFilters,
  IJsonRHEstatisticasSegSocialResumoItem,
  IJsonRHEstatisticasSeguroResumoItem,
  IRHEstatisticasAnaliseAnualItem,
  IRHEstatisticasAnaliseAnualReciboTotais,
  IRHEstatisticasAnaliseMensalItem,
  IRHEstatisticasExclusionButtonState,
  IRHEstatisticasExclusionSourceItem,
  IRHEstatisticasFiltersHelper,
  IRHEstatisticasFolhaSalarioResumoItem,
  IRHEstatisticasModelUI,
  IRhEstatisticasPorEmpresaCallback,
  IRHEstatisticasSegSocialResumoValColumns,
  IRHEstatisticasSeguroResumoValColumns,
  IRHEstatisticasSindicatoItem
} from '../../rhEstatisticas.module.interface';
import moment from 'moment';
import {RhEstatisticasExclusaoModalComponent} from '../../modals/exclusao/rhEstatisticas.exclusao.modal';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {RhEstatisticasModuleService} from '../../rhEstatisticas.module.service';
import {EDelphiNumberTypes, EPlDatepickerKind, ERadix, isDefinedNotNull, isEmpty, isNumber, isObject, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGridEventOnCellPrepared, IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonDGEMP} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonDepto} from '../../../../../entities/depto/jsonDepto.entity.interface';
import {IJsonCCusto} from '../../../../../entities/ccustos/jsonCCusto.entity.interface';
import {IDevExpressDataGridColumn, IDevExpressDataGridSummaryGroupItems, IDevExpressDataGridSummaryTotalItems} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import dxDataGrid, {SummaryGroupItem, SummaryTotalItem} from 'devextreme/ui/data_grid';
import {monthName, TDate} from '../../../../../../common/dates';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';
import {TranslateService} from '@ngx-translate/core';

const RH_GRID_LEFT_BAND = 'rhEstatisticasLeftBand';
const RH_ESTATISTICAS_RESUMO_DETAIL_BAND_ABONOS = 'rhEstatisticasResumoDetailAbonosBand';
const RH_ESTATISTICAS_RESUMO_DETAIL_BAND_DESCONTOS = 'rhEstatisticasResumoDetailDescontosBand';
const RH_EXTRATO_RENUM_BAND_EMPREGADOS = 'rhEstatisticasExtratoRenumEmpregadosBand';
const RH_MAPA_ANUAL_BAND_LEFT = 'rhEstatisticasMapaAnualLeftBand';
const RH_MAPA_ANUAL_BAND_MONTHS = 'rhEstatisticasMapaAnualMesesBand';

const COL_CSS_CLASS_ABONO = 'rh-col-abonos';
const COL_CSS_CLASS_DESCONTOS = 'rh-col-descontos';
const COL_CSS_CLASS_IMPOSTOS = 'rh-col-impostos-colaborador';
const COL_CSS_CLASS_CUSTOS_EMPRESA = 'rh-col-custos-empresa';
const COL_CSS_CLASS_BOLD = 'rh-col-bold';
const RH_ESTATISTICAS_MAPA_AT_BAND = 'rh-col-tipos-rend';
const ROW_HEADER_CSS = 'rh-row-header';
const CELL_FORMAT_STRING = '#,##0.00';

@Component({
  selector: 'rh-estatisticas-por-empresa',
  templateUrl: './rhEstatisticas.porEmpresa.component.html'
})
export class RhEstatisticasPorEmpresaComponent implements OnInit, OnChanges {
  @Input() public ano: number;
  @Input() public mes: number;
  @Input() public nEmpresa: string;
  @Input() public callback: IRhEstatisticasPorEmpresaCallback;

  public readonly datePickerKind: typeof EPlDatepickerKind;
  public readonly filterExclusionField: typeof ERHEstatisticasFilterType;
  public readonly navItems: typeof ERHEstatisticasNavTabs;
  public readonly estatisticasTabs: typeof ERHEstatisticasEstatisticasTabs;

  public isFirstLoad: boolean;
  public formSearch: UntypedFormGroup;
  public modelFiltersUI: IRHEstatisticasFiltersHelper;
  public model: IRHEstatisticasModelUI;
  public anoMesDeKind: EPlDatepickerKind;
  public filtersCollapsed: boolean;

  private readonly _mapaATNonBandColumns: Array<string>;
  private readonly _mapaATBandColCaptions: Map<string, string>;
  private readonly _exclusionButtonState: IRHEstatisticasExclusionButtonState;

  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService,
    private readonly _rhEstatisticasModuleService: RhEstatisticasModuleService,
    private readonly _translateService: TranslateService
  ) {
    this.datePickerKind = EPlDatepickerKind;
    this.anoMesDeKind = EPlDatepickerKind.Month;
    this.filterExclusionField = ERHEstatisticasFilterType;
    this.navItems = ERHEstatisticasNavTabs;
    this.estatisticasTabs = ERHEstatisticasEstatisticasTabs;
    this.modelFiltersUI = {
      anoMesDe: moment(),
      anoMesAte: moment(),
      codEmpDe: 0,
      codEmpAte: EDelphiNumberTypes.MaxSmallInt,
      deptoDe: 0,
      deptoAte: EDelphiNumberTypes.MaxSmallInt,
      ccustoDe: '',
      ccustoAte: 'ZZZZ',
      exclusaoCCustoUI: [],
      exclusaoCodEmpUI: [],
      exclusaoDeptoUI: [],
      exclusaoAnoMesUI: []
    };
    this._exclusionButtonState = {
      yearMonthEnabled: false,
      codEmpEnabled: true,
      deptoEnabled: true,
      ccustoEnabled: true
    };

    this._mapaATNonBandColumns = [
      'codEmp',
      'nome',
      'nContribuinte',
      'rendimentosAno',
      'rendimentosAnoAnterior',
      'localRendimento',
      'rendimentosSujeito',
      'rendimentosNaoSujeito',
      'rendimentosRetencoesSobreTaxa',
      'rendimentosRetencaoIrs',
      'rendimentosContibuicoesObrigatorias',
      'rendimentosContibuicoesSindicais'
    ];

    this._mapaATBandColCaptions = new Map<string, string>([
      ['rendNaoSujeitosArt2A25', 'a25'],
      ['rendIsentoSujEnglobA81', 'a81'],
      ['rendIsentoSujEnglobA18', 'a18'],
      ['rendIsentosA40', 'a40'],
      ['rendIsentoSujEnglobA19', 'a19'],
      ['rendIsentoSujEnglobA12', 'a12'],
      ['rendTrabDependenteA68', 'a68'],
      ['rendIsentoSujEnglobA13', 'a13'],
      ['rendTrabDependenteA69', 'a69'],
      ['rendIsentoSujEnglobA11', 'a11'],
      ['rendIsentoSujEnglobA16', 'al6'],
      ['rendIsentoSujEnglobA17', 'al7'],
      ['rendIsentoSujEnglobA14', 'al4'],
      ['rendTrabDependenteA62', 'a62'],
      ['rendIsentoSujEnglobA15', 'a15'],
      ['rendTrabDependenteA63', 'a63'],
      ['rendTrabDependenteA61', 'a61'],
      ['rendTrabDependenteA66', 'a66'],
      ['rendTrabDependenteA67', 'a67'],
      ['rendTrabDependenteA2', 'a2'],
      ['rendTrabDependenteA64', 'a64'],
      ['rendTrabDependenteA3', 'a3'],
      ['rendTrabDependenteA65', 'a65'],
      ['rendNaoSujeitosArt12A32', 'a32'],
      ['rendNaoSujeitosArt12A33', 'a33'],
      ['rendNaoSujeitosArt12A30', 'a30'],
      ['rendNaoSujeitosArt12A31', 'a31'],
      ['rendTrabDependenteA4', 'a4'],
      ['rendTrabDependenteA5', 'a5'],
      ['rendNaoSujeitosArt2A22', 'a22'],
      ['rendNaoSujeitosArt2A23', 'a23'],
      ['rendTrabDependenteA', 'a'],
      ['rendNaoSujeitosArt2A20', 'a20'],
      ['rendNaoSujeitosArt2A21', 'a21'],
      ['rendNaoSujeitosArt2A26', 'a26'],
      ['rendIsentoSujEnglobA82', 'a80'],
      ['rendNaoSujeitosArt2A27', 'a27'],
      ['rendNaoSujeitosArt2A24', 'a24']
    ]);

    this.model = {
      activeItem: ERHEstatisticasNavTabs.ESTATISTICAS,
      estatisticas: {
        id: ERHEstatisticasNavTabs.ESTATISTICAS,
        caption: 'rhEstatisticas.string.analiseRemuneracoes',
        activeTab: ERHEstatisticasEstatisticasTabs.RESUMO,
        resumoDataGridDefinition: {
          allowColumnReordering: false,
          filterRow: {visible: false},
          headerFilter: {visible: false},
          groupPanel: {visible: false, allowColumnDragging: false},
          columnHidingEnabled: false,
          stateStoring: {enabled: false},
          paging: {pageSize: 100},
          columns: [
            {
              fixed: true,
              fixedPosition: 'left',
              name: RH_GRID_LEFT_BAND,
              columns: [
                {dataField: 'codEmp', dataType: 'number', caption: 'rhEstatisticas.fields.codEmp', allowEditing: false},
                {dataField: 'nome', dataType: 'string', caption: 'rhEstatisticas.fields.nome', allowEditing: false}
              ]
            },
            {
              columns: [
                {
                  dataField: 'totalAbonos',
                  cssClass: COL_CSS_CLASS_ABONO,
                  dataType: 'currency',
                  caption: 'rhEstatisticas.fields.totalAbonos',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                },
                {
                  dataField: 'totalDescontos',
                  cssClass: COL_CSS_CLASS_DESCONTOS,
                  dataType: 'currency',
                  caption: 'rhEstatisticas.fields.totalDescontos',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                },
                {
                  dataField: 'totalIliquido',
                  dataType: 'currency',
                  cssClass: COL_CSS_CLASS_BOLD,
                  caption: 'rhEstatisticas.fields.totalIliquido',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                }
              ]
            },
            {
              caption: 'rhEstatisticas.string.impostosColaborador',
              cssClass: COL_CSS_CLASS_IMPOSTOS,
              alignment: 'center',
              columns: [
                {
                  dataField: 'totalIRS',
                  cssClass: COL_CSS_CLASS_IMPOSTOS,
                  dataType: 'currency',
                  caption: 'rhEstatisticas.fields.irs',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                },
                {
                  dataField: 'totalSS',
                  cssClass: COL_CSS_CLASS_IMPOSTOS,
                  dataType: 'currency',
                  caption: 'rhEstatisticas.fields.segSocial',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                },
                {
                  dataField: 'totalCGA',
                  cssClass: COL_CSS_CLASS_IMPOSTOS,
                  dataType: 'currency',
                  caption: 'rhEstatisticas.fields.cga',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                },
                {
                  dataField: 'totalSindicato',
                  cssClass: COL_CSS_CLASS_IMPOSTOS,
                  dataType: 'currency',
                  caption: 'rhEstatisticas.fields.sindicato',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                },
                {
                  name: 'totalImpostos',
                  cssClass: `${COL_CSS_CLASS_IMPOSTOS} ${COL_CSS_CLASS_BOLD}`,
                  dataType: 'currency',
                  format: CELL_FORMAT_STRING,
                  caption: 'rhEstatisticas.fields.totalImpostos',
                  allowEditing: false,
                  calculateCellValue: (rowData: IRHEstatisticasFolhaSalarioResumoItem): number => {
                    return rowData.totalIRS + rowData.totalSS + rowData.totalCGA + rowData.totalSindicato;
                  }
                }
              ]
            },
            {
              caption: 'rhEstatisticas.string.recibo',
              alignment: 'center',
              columns: [
                {dataField: 'totalRecibo', dataType: 'currency', caption: 'rhEstatisticas.fields.totalLiquido', allowEditing: false, format: CELL_FORMAT_STRING},
                {dataField: 'totalEspecie', dataType: 'currency', caption: 'rhEstatisticas.fields.totalEspecie', allowEditing: false, format: CELL_FORMAT_STRING},
                {
                  dataField: 'totalAPagar',
                  dataType: 'currency',
                  cssClass: COL_CSS_CLASS_BOLD,
                  caption: 'rhEstatisticas.fields.totalAPagar',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                }
              ]
            },
            {
              caption: 'rhEstatisticas.string.custosEmpresa',
              cssClass: COL_CSS_CLASS_CUSTOS_EMPRESA,
              alignment: 'center',
              columns: [
                {
                  dataField: 'totalEncargos',
                  cssClass: COL_CSS_CLASS_CUSTOS_EMPRESA,
                  dataType: 'currency',
                  caption: 'rhEstatisticas.fields.encargos',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                },
                {
                  dataField: 'totalCustosEmpresa',
                  cssClass: COL_CSS_CLASS_CUSTOS_EMPRESA,
                  dataType: 'currency',
                  caption: 'rhEstatisticas.fields.custoEmpresa',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                }
              ]
            },
            {
              columns: [
                {
                  dataType: 'string',
                  caption: 'rhEstatisticas.fields.departamento',
                  allowEditing: false,
                  calculateCellValue: (rowData: IRHEstatisticasFolhaSalarioResumoItem): string => {
                    return `${rowData.departamento} - ${rowData.departamentoNome}`;
                  }
                },
                {
                  dataField: 'ccustoStr',
                  dataType: 'string',
                  caption: 'rhEstatisticas.fields.ccusto',
                  allowEditing: false,
                  calculateCellValue: (rowData: IRHEstatisticasFolhaSalarioResumoItem): string => {
                    return `${rowData.ccusto} - ${rowData.ccustoNome}`;
                  }
                }
              ]
            }
          ],
          dataSource: new CustomStore({
            key: 'codEmp',
            load: () => {
              return this.model.estatisticas.resumoSource;
            }
          }),
          summary: {
            totalItems: this._getDefaultFolhaSalariosSummaryTotalItems()
          },
          export: {
            enabledPdf: false
          }
        },
        resumoDataGrid: undefined,
        resumoDetailDataGrid: undefined,
        resumoSource: [],
        resumoDetailSource: [],
        resumoDetailDataGridDefinition: {
          allowColumnReordering: false,
          filterRow: {visible: false},
          headerFilter: {visible: false},
          groupPanel: {visible: false, allowColumnDragging: false},
          columnHidingEnabled: false,
          stateStoring: {enabled: false},
          paging: {pageSize: 100},
          columns: [
            {
              fixed: true,
              fixedPosition: 'left',
              name: RH_GRID_LEFT_BAND,
              columns: [
                {dataField: 'codEmp', dataType: 'number', caption: 'rhEstatisticas.fields.codEmp', allowEditing: false},
                {dataField: 'nome', dataType: 'string', caption: 'rhEstatisticas.fields.nome', allowEditing: false}
              ]
            },
            {
              caption: 'rhEstatisticas.fields.abonos',
              name: RH_ESTATISTICAS_RESUMO_DETAIL_BAND_ABONOS,
              cssClass: COL_CSS_CLASS_ABONO,
              columns: []
            },
            {
              caption: 'rhEstatisticas.fields.descontos',
              name: RH_ESTATISTICAS_RESUMO_DETAIL_BAND_DESCONTOS,
              cssClass: COL_CSS_CLASS_DESCONTOS,
              columns: []
            },
            {
              dataField: 'totalIliquido',
              dataType: 'currency',
              cssClass: COL_CSS_CLASS_BOLD,
              caption: 'rhEstatisticas.fields.totalIliquido',
              allowEditing: false,
              format: CELL_FORMAT_STRING
            },
            {
              caption: 'rhEstatisticas.string.impostosColaborador',
              cssClass: COL_CSS_CLASS_IMPOSTOS,
              alignment: 'center',
              columns: [
                {
                  dataField: 'totalIRS',
                  cssClass: COL_CSS_CLASS_IMPOSTOS,
                  dataType: 'currency',
                  caption: 'rhEstatisticas.fields.irs',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                },
                {
                  dataField: 'totalSS',
                  cssClass: COL_CSS_CLASS_IMPOSTOS,
                  dataType: 'currency',
                  caption: 'rhEstatisticas.fields.segSocial',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                },
                {
                  dataField: 'totalCGA',
                  cssClass: COL_CSS_CLASS_IMPOSTOS,
                  dataType: 'currency',
                  caption: 'rhEstatisticas.fields.cga',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                },
                {
                  dataField: 'totalSindicato',
                  cssClass: COL_CSS_CLASS_IMPOSTOS,
                  dataType: 'currency',
                  caption: 'rhEstatisticas.fields.sindicato',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                },
                {
                  name: 'totalImpostos',
                  cssClass: `${COL_CSS_CLASS_IMPOSTOS} ${COL_CSS_CLASS_BOLD}`,
                  dataType: 'currency',
                  format: CELL_FORMAT_STRING,
                  caption: 'rhEstatisticas.fields.totalImpostos',
                  allowEditing: false,
                  calculateCellValue: (rowData: IRHEstatisticasFolhaSalarioResumoItem): number => {
                    return rowData.totalIRS + rowData.totalSS + rowData.totalCGA + rowData.totalSindicato;
                  }
                }
              ]
            },
            {
              caption: 'rhEstatisticas.string.recibo',
              alignment: 'center',
              columns: [
                {dataField: 'totalRecibo', dataType: 'currency', caption: 'rhEstatisticas.fields.totalLiquido', allowEditing: false, format: CELL_FORMAT_STRING},
                {dataField: 'totalEspecie', dataType: 'currency', caption: 'rhEstatisticas.fields.totalEspecie', allowEditing: false, format: CELL_FORMAT_STRING},
                {
                  dataField: 'totalAPagar',
                  dataType: 'currency',
                  cssClass: COL_CSS_CLASS_BOLD,
                  caption: 'rhEstatisticas.fields.totalAPagar',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                }
              ]
            },
            {
              caption: 'rhEstatisticas.string.custosEmpresa',
              cssClass: COL_CSS_CLASS_CUSTOS_EMPRESA,
              alignment: 'center',
              columns: [
                {
                  dataField: 'totalEncargos',
                  cssClass: COL_CSS_CLASS_CUSTOS_EMPRESA,
                  dataType: 'currency',
                  caption: 'rhEstatisticas.fields.encargos',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                },
                {
                  dataField: 'totalCustosEmpresa',
                  cssClass: COL_CSS_CLASS_CUSTOS_EMPRESA,
                  dataType: 'currency',
                  caption: 'rhEstatisticas.fields.custoEmpresa',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                }
              ]
            },
            {
              columns: [
                {
                  dataType: 'string',
                  caption: 'rhEstatisticas.fields.departamento',
                  allowEditing: false,
                  calculateCellValue: (rowData: IRHEstatisticasFolhaSalarioResumoItem): string => {
                    return `${rowData.departamento} - ${rowData.departamentoNome}`;
                  }
                },
                {
                  dataField: 'ccustoStr',
                  dataType: 'string',
                  caption: 'rhEstatisticas.fields.ccusto',
                  allowEditing: false,
                  calculateCellValue: (rowData: IRHEstatisticasFolhaSalarioResumoItem): string => {
                    return `${rowData.ccusto} - ${rowData.ccustoNome}`;
                  }
                }
              ]
            }
          ],
          dataSource: new CustomStore({
            key: 'codEmp',
            load: () => {
              return this.model.estatisticas.resumoDetailSource;
            }
          }),
          summary: {
            totalItems: this._getDefaultFolhaSalariosSummaryTotalItems()
          },
          export: {
            enabledPdf: false
          }
        }
      },
      extratoRemuneracoes: {
        id: ERHEstatisticasNavTabs.EXTRATO_REMUNERACOES,
        caption: 'rhEstatisticas.string.analiseMensalDetalhada',
        dataGrid: undefined,
        dataGridSource: [],
        dataGridDefinition: {
          allowColumnReordering: false,
          filterRow: {visible: false},
          headerFilter: {visible: false},
          groupPanel: {visible: false, allowColumnDragging: false},
          columnHidingEnabled: false,
          stateStoring: {enabled: false},
          paging: {pageSize: 100},
          columns: [
            {
              dataField: 'recordType',
              caption: 'rhEstatisticas.string.tipo',
              dataType: 'number',
              allowEditing: false,
              sortOrder: 'asc',
              showInColumnChooser: false,
              groupIndex: 0,
              calculateDisplayValue: (row: IRHEstatisticasAnaliseMensalItem) => {
                return `${row.recordType} - ${row.recordTypeDesc}`;
              }
            },
            {dataField: 'nCodAbdesc', caption: 'nCodAbdesc', dataType: 'number', sortOrder: 'asc', sortIndex: 1, visible: false, showInColumnChooser: false},
            {dataField: 'nomeCodAbdesc', caption: '', name: RH_GRID_LEFT_BAND, dataType: 'string', allowEditing: false, fixed: true, fixedPosition: 'left', cssClass: ROW_HEADER_CSS},
            {
              name: RH_EXTRATO_RENUM_BAND_EMPREGADOS,
              caption: 'rhEstatisticas.fields.colaboradores',
              alignment: 'center',
              columns: []
            },
            {
              dataField: 'totalGlobal',
              dataType: 'currency',
              cssClass: COL_CSS_CLASS_BOLD,
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              alignment: 'right',
              fixed: true,
              fixedPosition: 'right',
              width: 150
            }
          ],
          dataSource: new CustomStore({
            key: 'recordType',
            load: () => {
              return this.model.extratoRemuneracoes.dataGridSource;
            }
          }),
          export: {
            enabledPdf: false
          },
          summary: {
            groupItems: []
          }
        }
      },
      analiseAnual: {
        id: ERHEstatisticasNavTabs.ANALISE_ANUAL,
        caption: 'rhEstatisticas.string.analiseAnual',
        dataGrid: undefined,
        dataGridSource: [],
        dataGridDefinition: {
          allowColumnReordering: false,
          filterRow: {visible: false},
          headerFilter: {visible: false},
          groupPanel: {visible: false, allowColumnDragging: false},
          columnHidingEnabled: false,
          stateStoring: {enabled: false},
          paging: {pageSize: 100},
          columns: [
            {
              name: RH_MAPA_ANUAL_BAND_LEFT,
              fixed: true,
              fixedPosition: 'left',
              columns: [
                {
                  dataField: 'recordType',
                  caption: 'rhEstatisticas.string.tipo',
                  dataType: 'number',
                  allowEditing: false,
                  sortOrder: 'asc',
                  showInColumnChooser: false,
                  groupIndex: 0,
                  calculateDisplayValue: (row: IRHEstatisticasAnaliseAnualItem) => {
                    return `${row.recordType} - ${row.recordTypeDesc}`;
                  }
                },
                {dataField: 'nCodAbdesc', caption: 'nCodAbdesc', dataType: 'number', sortOrder: 'asc', sortIndex: 1, visible: false, showInColumnChooser: false},
                {dataField: 'nomeCodAbdesc', caption: '', dataType: 'string', allowEditing: false, cssClass: ROW_HEADER_CSS},
                {
                  dataField: 'totalAnoAnterior',
                  cssClass: COL_CSS_CLASS_BOLD,
                  caption: 'rhEstatisticas.string.anoMenosUm',
                  dataType: 'currency',
                  allowEditing: false,
                  format: CELL_FORMAT_STRING
                }
              ]
            },
            {
              name: RH_MAPA_ANUAL_BAND_MONTHS,
              caption: '',
              alignment: 'center',
              columns: []
            },
            {
              dataField: 'totalAnoFilter',
              cssClass: COL_CSS_CLASS_BOLD,
              dataType: 'currency',
              caption: 'rhEstatisticas.string.totalN',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              alignment: 'right'
            }
          ],
          summary: {
            groupItems: []
          },
          dataSource: new CustomStore({
            key: 'rowKey',
            load: () => {
              return this.model.analiseAnual.dataGridSource;
            }
          }),
          export: {
            enabledPdf: false
          }
        }
      },
      analiseSegSocial: {
        id: ERHEstatisticasNavTabs.ANALISE_SEGSOCIAL,
        caption: 'rhEstatisticas.string.analiseSegSocial',
        dataGrid: undefined,
        dataGridSource: [],
        dataGridDefinition: {
          allowColumnReordering: false,
          filterRow: {visible: false},
          headerFilter: {visible: false},
          groupPanel: {visible: false, allowColumnDragging: false},
          columnHidingEnabled: false,
          stateStoring: {enabled: false},
          paging: {pageSize: 100},
          columns: [
            {
              alignment: 'center',
              fixed: true,
              fixedPosition: 'left',
              name: RH_GRID_LEFT_BAND,
              columns: [
                {dataField: 'codEmp', caption: 'rhEstatisticas.fields.codEmp', dataType: 'string', allowEditing: false},
                {dataField: 'nome', caption: 'rhEstatisticas.fields.nome', dataType: 'string', allowEditing: false}
              ]
            },
            {dataField: 'numSegSoc', caption: 'rhEstatisticas.fields.niss', dataType: 'string', allowEditing: false},
            {dataField: 'nomeRegime', caption: 'rhEstatisticas.fields.regimeSS', dataType: 'string', allowEditing: false},
            {dataField: 'dtNascimento', caption: 'rhEstatisticas.fields.dtNascimento', dataType: 'date', allowEditing: false},
            {dataField: 'ano', caption: 'global.text.year', dataType: 'number', allowEditing: false},
            {dataField: 'mes', caption: 'global.text.month', dataType: 'number', allowEditing: false},
            {
              dataField: 'totalSegSocialRemun',
              cssClass: COL_CSS_CLASS_BOLD,
              caption: 'rhEstatisticas.fields.totalRemun',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING
            },
            {
              dataField: 'valPRemperman',
              caption: 'rhEstatisticas.fields.codP',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valPRemperman === 0) {
                  return '';
                }
                return row.valPRemperman;
              }
            },
            {
              dataField: 'valRSubrefeicao',
              caption: 'rhEstatisticas.fields.codR',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valRSubrefeicao === 0) {
                  return '';
                }
                return row.valRSubrefeicao;
              }
            },
            {
              dataField: 'valFSubferias',
              caption: 'rhEstatisticas.fields.codF',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valFSubferias === 0) {
                  return '';
                }
                return row.valFSubferias;
              }
            },
            {
              dataField: 'valNSubnatal',
              caption: 'rhEstatisticas.fields.codN',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valNSubnatal === 0) {
                  return '';
                }
                return row.valNSubnatal;
              }
            },
            {
              dataField: 'valAAjudascusto',
              caption: 'rhEstatisticas.fields.codA',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valAAjudascusto === 0) {
                  return '';
                }
                return row.valAAjudascusto;
              }
            },
            {
              dataField: 'valBPremmensal',
              caption: 'rhEstatisticas.fields.codB',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valBPremmensal === 0) {
                  return '';
                }
                return row.valBPremmensal;
              }
            },
            {
              dataField: 'valCComissoes',
              caption: 'rhEstatisticas.fields.codC',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valCComissoes === 0) {
                  return '';
                }
                return row.valCComissoes;
              }
            },
            {
              dataField: 'valDCompcessacao',
              caption: 'rhEstatisticas.fields.codD',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valDCompcessacao === 0) {
                  return '';
                }
                return row.valDCompcessacao;
              }
            },
            {
              dataField: 'valHHonorarios',
              caption: 'rhEstatisticas.fields.codH',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valHHonorarios === 0) {
                  return '';
                }
                return row.valHHonorarios;
              }
            },
            {
              dataField: 'valMSubsregmensal',
              caption: 'rhEstatisticas.fields.codM',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valMSubsregmensal === 0) {
                  return '';
                }
                return row.valMSubsregmensal;
              }
            },
            {
              dataField: 'valOPrnaomensal',
              caption: 'rhEstatisticas.fields.codO',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valOPrnaomensal === 0) {
                  return '';
                }
                return row.valOPrnaomensal;
              }
            },
            {
              dataField: 'valSRsuplementar',
              caption: 'rhEstatisticas.fields.codS',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valSRsuplementar === 0) {
                  return '';
                }
                return row.valSRsuplementar;
              }
            },
            {
              dataField: 'valTTrabnocturno',
              caption: 'rhEstatisticas.fields.codT',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valTTrabnocturno === 0) {
                  return '';
                }
                return row.valTTrabnocturno;
              }
            },
            {
              dataField: 'valXOutsubsidios',
              caption: 'rhEstatisticas.fields.codX',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valXOutsubsidios === 0) {
                  return '';
                }
                return row.valXOutsubsidios;
              }
            },
            {
              dataField: 'val2Ferpagascess',
              caption: 'rhEstatisticas.fields.cod2',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.val2Ferpagascess === 0) {
                  return '';
                }
                return row.val2Ferpagascess;
              }
            },
            {
              dataField: 'val6Difvencim',
              caption: 'rhEstatisticas.fields.cod6',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.val6Difvencim === 0) {
                  return '';
                }
                return row.val6Difvencim;
              }
            },
            {
              dataField: 'valICtrintermit',
              caption: 'rhEstatisticas.fields.codI',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valICtrintermit === 0) {
                  return '';
                }
                return row.valICtrintermit;
              }
            },
            {
              caption: 'rhEstatisticas.string.totais',
              alignment: 'center',
              columns: [
                {dataField: 'encargosTrabalhador', caption: 'rhEstatisticas.fields.encargosTrabalhador', dataType: 'currency', allowEditing: false, format: CELL_FORMAT_STRING},
                {dataField: 'encargosEmpresa', caption: 'rhEstatisticas.fields.encargosEmpresa', dataType: 'currency', allowEditing: false, format: CELL_FORMAT_STRING},
                {dataField: 'totalEncargos', caption: 'rhEstatisticas.fields.totalEncargos', dataType: 'currency', allowEditing: false, format: CELL_FORMAT_STRING}
              ]
            }
          ],
          summary: {
            totalItems: [
              {column: 'encargosTrabalhador', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING},
              {column: 'encargosEmpresa', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING},
              {column: 'totalEncargos', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING}
            ]
          },
          dataSource: new CustomStore({
            key: 'codEmp',
            load: () => {
              return this.model.analiseSegSocial.dataGridSource;
            }
          }),
          export: {
            enabledPdf: false
          }
        }
      },
      analiseAT: {
        id: ERHEstatisticasNavTabs.ANALISE_AT,
        caption: 'rhEstatisticas.string.analiseAT',
        dataGrid: undefined,
        dataGridSource: [],
        dataGridDefinition: {
          allowColumnReordering: false,
          filterRow: {visible: false},
          headerFilter: {visible: false},
          groupPanel: {visible: false, allowColumnDragging: false},
          columnHidingEnabled: false,
          stateStoring: {enabled: false},
          paging: {pageSize: 100},
          columns: [
            {
              alignment: 'center',
              fixed: true,
              fixedPosition: 'left',
              name: RH_GRID_LEFT_BAND,
              columns: [
                {dataField: 'codEmp', caption: 'rhEstatisticas.fields.codEmp', dataType: 'string', allowEditing: false},
                {dataField: 'nome', caption: 'rhEstatisticas.fields.nome', dataType: 'string', allowEditing: false}
              ]
            },
            {dataField: 'localRendimento', caption: 'rhEstatisticas.fields.areaRendimento', dataType: 'string', allowEditing: false},
            {dataField: 'nContribuinte', caption: 'rhEstatisticas.fields.nif', dataType: 'string', allowEditing: false},
            {
              dataField: 'rendimentosAno',
              caption: 'rhEstatisticas.fields.totalRendimentos',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.rendimentosAno === 0) {
                  return '';
                }
                return row.rendimentosAno;
              }
            },
            {
              dataField: 'rendimentosAnoAnterior',
              caption: 'rhEstatisticas.fields.rendAnoAnterior',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.rendimentosAnoAnterior === 0) {
                  return '';
                }
                return row.rendimentosAnoAnterior;
              }
            },
            {
              dataField: 'rendimentosNaoSujeito',
              caption: 'rhEstatisticas.fields.rendNaoSujeito',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.rendimentosNaoSujeito === 0) {
                  return '';
                }
                return row.rendimentosNaoSujeito;
              }
            },
            {
              dataField: 'rendimentosSujeito',
              caption: 'rhEstatisticas.fields.rendSujeito',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.rendimentosSujeito === 0) {
                  return '';
                }
                return row.rendimentosSujeito;
              }
            },
            {
              name: RH_ESTATISTICAS_MAPA_AT_BAND,
              caption: 'rhEstatisticas.fields.tipoRendimento',
              alignment: 'center',
              columns: []
            },
            {
              dataField: 'rendimentosRetencaoIrs',
              caption: 'rhEstatisticas.fields.retencaoIrs',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.rendimentosRetencaoIrs === 0) {
                  return '';
                }
                return row.rendimentosRetencaoIrs;
              }
            },
            {dataField: 'rendimentosContibuicoesObrigatorias', caption: 'rhEstatisticas.fields.contibuicoesSS', dataType: 'currency', allowEditing: false, format: CELL_FORMAT_STRING}
          ],
          dataSource: new CustomStore({
            key: 'codEmp',
            load: () => {
              return this.model.analiseAT.dataGridSource;
            }
          }),
          export: {
            enabledPdf: false
          }
        }
      },
      analiseSindicato: {
        id: ERHEstatisticasNavTabs.ANALISE_SINDICATO,
        caption: 'rhEstatisticas.string.analiseSindicato',
        dataGrid: undefined,
        dataGridSource: [],
        dataGridDefinition: {
          allowColumnReordering: false,
          filterRow: {visible: false},
          headerFilter: {visible: false},
          groupPanel: {visible: false, allowColumnDragging: false},
          columnHidingEnabled: false,
          stateStoring: {enabled: false},
          paging: {pageSize: 100},
          columns: [
            {dataField: 'codEmp', caption: 'rhEstatisticas.fields.codEmp', dataType: 'string', allowEditing: false},
            {dataField: 'nome', caption: 'rhEstatisticas.fields.nome', dataType: 'string', allowEditing: false},
            {dataField: 'sindicatoNome', caption: 'rhEstatisticas.fields.sindicato', dataType: 'string', allowEditing: false},
            {dataField: 'sindicatoPerc', caption: 'rhEstatisticas.fields.sindicatoPerc', dataType: 'double', allowEditing: false},
            {dataField: 'valorSujeito', caption: 'rhEstatisticas.fields.valorSujeito', dataType: 'currency', allowEditing: false, format: CELL_FORMAT_STRING},
            {dataField: 'valorDesconto', caption: 'rhEstatisticas.fields.valorDesconto', dataType: 'currency', allowEditing: false, format: CELL_FORMAT_STRING}
          ],
          summary: {
            totalItems: [{column: 'valorDesconto', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING}]
          },
          dataSource: new CustomStore({
            key: 'uid',
            load: () => {
              return this.model.analiseSindicato.dataGridSource;
            }
          }),
          export: {
            enabledPdf: false
          }
        }
      },
      analiseCGA: {
        id: ERHEstatisticasNavTabs.ANALISE_CGA,
        caption: 'rhEstatisticas.string.analiseCGA'
      },
      analiseSeguro: {
        id: ERHEstatisticasNavTabs.ANALISE_SEGURO,
        caption: 'rhEstatisticas.string.analiseSeguro',
        dataGrid: undefined,
        dataGridSource: [],
        dataGridDefinition: {
          allowColumnReordering: false,
          filterRow: {visible: false},
          headerFilter: {visible: false},
          groupPanel: {visible: false, allowColumnDragging: false},
          columnHidingEnabled: false,
          stateStoring: {enabled: false},
          paging: {pageSize: 100},
          columns: [
            {
              alignment: 'center',
              fixed: true,
              fixedPosition: 'left',
              name: RH_GRID_LEFT_BAND,
              columns: [
                {dataField: 'codEmp', caption: 'rhEstatisticas.fields.codEmp', dataType: 'string', allowEditing: false},
                {dataField: 'nome', caption: 'rhEstatisticas.fields.nome', dataType: 'string', allowEditing: false}
              ]
            },
            {dataField: 'numSegSoc', caption: 'rhEstatisticas.fields.niss', dataType: 'string', allowEditing: false},
            {dataField: 'seguradora', caption: 'rhEstatisticas.fields.seguradora', dataType: 'string', allowEditing: false},
            {
              dataField: 'valPRemperman',
              caption: 'rhEstatisticas.fields.codP',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valPRemperman === 0) {
                  return '';
                }
                return row.valPRemperman;
              }
            },
            {
              dataField: 'valRSubrefeicao',
              caption: 'rhEstatisticas.fields.codR',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valRSubrefeicao === 0) {
                  return '';
                }
                return row.valRSubrefeicao;
              }
            },
            {
              dataField: 'valFSubferias',
              caption: 'rhEstatisticas.fields.codF',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valFSubferias === 0) {
                  return '';
                }
                return row.valFSubferias;
              }
            },
            {
              dataField: 'valNSubnatal',
              caption: 'rhEstatisticas.fields.codN',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valNSubnatal === 0) {
                  return '';
                }
                return row.valNSubnatal;
              }
            },
            {
              dataField: 'valAAjudascusto',
              caption: 'rhEstatisticas.fields.codA',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valAAjudascusto === 0) {
                  return '';
                }
                return row.valAAjudascusto;
              }
            },
            {
              dataField: 'valBPremmensal',
              caption: 'rhEstatisticas.fields.codB',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valBPremmensal === 0) {
                  return '';
                }
                return row.valBPremmensal;
              }
            },
            {
              dataField: 'valCComissoes',
              caption: 'rhEstatisticas.fields.codC',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valCComissoes === 0) {
                  return '';
                }
                return row.valCComissoes;
              }
            },
            {
              dataField: 'valDCompcessacao',
              caption: 'rhEstatisticas.fields.codD',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valDCompcessacao === 0) {
                  return '';
                }
                return row.valDCompcessacao;
              }
            },
            {
              dataField: 'valHHonorarios',
              caption: 'rhEstatisticas.fields.codH',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valHHonorarios === 0) {
                  return '';
                }
                return row.valHHonorarios;
              }
            },
            {
              dataField: 'valMSubsregmensal',
              caption: 'rhEstatisticas.fields.codM',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valMSubsregmensal === 0) {
                  return '';
                }
                return row.valMSubsregmensal;
              }
            },
            {
              dataField: 'valOPrnaomensal',
              caption: 'rhEstatisticas.fields.codO',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valOPrnaomensal === 0) {
                  return '';
                }
                return row.valOPrnaomensal;
              }
            },
            {
              dataField: 'valSRsuplementar',
              caption: 'rhEstatisticas.fields.codS',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valSRsuplementar === 0) {
                  return '';
                }
                return row.valSRsuplementar;
              }
            },
            {
              dataField: 'valTTrabnocturno',
              caption: 'rhEstatisticas.fields.codT',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valTTrabnocturno === 0) {
                  return '';
                }
                return row.valTTrabnocturno;
              }
            },
            {
              dataField: 'valXOutsubsidios',
              caption: 'rhEstatisticas.fields.codX',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valXOutsubsidios === 0) {
                  return '';
                }
                return row.valXOutsubsidios;
              }
            },
            {
              dataField: 'val2Ferpagascess',
              caption: 'rhEstatisticas.fields.cod2',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.val2Ferpagascess === 0) {
                  return '';
                }
                return row.val2Ferpagascess;
              }
            },
            {
              dataField: 'val6Difvencim',
              caption: 'rhEstatisticas.fields.cod6',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.val6Difvencim === 0) {
                  return '';
                }
                return row.val6Difvencim;
              }
            },
            {
              dataField: 'valICtrintermit',
              caption: 'rhEstatisticas.fields.codI',
              dataType: 'currency',
              allowEditing: false,
              format: CELL_FORMAT_STRING,
              calculateDisplayValue: (row) => {
                if (row.valICtrintermit === 0) {
                  return '';
                }
                return row.valICtrintermit;
              }
            }
          ],
          dataSource: new CustomStore({
            key: 'codEmp',
            load: () => {
              return this.model.analiseSeguro.dataGridSource;
            }
          }),
          export: {
            enabledPdf: false
          }
        }
      },
      listagensRU: {
        id: ERHEstatisticasNavTabs.LISTAGENS_RU,
        caption: 'rhEstatisticas.string.listagensRU'
      }
    };
  }

  public ngOnInit(): void {
    this.isFirstLoad = true;
    if (isDefinedNotNull(this.ano) && isDefinedNotNull(this.mes)) {
      this.modelFiltersUI.anoMesDe = moment()
        .year(this.ano)
        .month(this.mes - 1);
      this.modelFiltersUI.anoMesAte = moment()
        .year(this.ano)
        .month(this.mes - 1);
    }
    this.callback = {};
  }

  public ngOnChanges({callback}: SimpleChanges): void {
    if (callback) {
      const cb: IRhEstatisticasPorEmpresaCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.getFilters = () => {
          return this._getFilters();
        };
        cb.setFilterAnoMes = (ano: number, mes: number) => {
          this.modelFiltersUI.anoMesDe = new Date(ano, mes - 1, 1);
          this.modelFiltersUI.anoMesAte = new Date(ano, mes - 1, 1);
        };
        cb.search = () => {
          return this._search();
        };
      }
    }
  }

  public getTabTitle(): string {
    return this.model[this.model.activeItem].caption;
  }

  public anoMesDeChange(value: TDate): void {
    this.modelFiltersUI.anoMesDe = value;
    if (this.model.activeItem === ERHEstatisticasNavTabs.ANALISE_ANUAL) {
      this.modelFiltersUI.anoMesAte = moment(value).endOf('year');
    }
    this.checkExclusionButton(ERHEstatisticasFilterType.YEAR_MONTH);
  }

  public onResumoDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this.model.estatisticas.resumoDataGrid = component;
  }

  public onResumoDetailDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this.model.estatisticas.resumoDetailDataGrid = component;
  }

  public onAnaliseMensalDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this.model.extratoRemuneracoes.dataGrid = component;
  }

  public onAnaliseSegSocialDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this.model.analiseSegSocial.dataGrid = component;
  }

  public onAnaliseSindicatoDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this.model.analiseSindicato.dataGrid = component;
  }

  public onAnaliseSeguroDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this.model.analiseSeguro.dataGrid = component;
  }

  public onAnaliseATDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this.model.analiseAT.dataGrid = component;
  }

  public onAnaliseAnualDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this.model.analiseAnual.dataGrid = component;
  }

  public onAnaliseMensalCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IRHEstatisticasAnaliseMensalItem, number>): void {
    if (event.rowType === 'data' || event.rowType === 'group') {
      let row;
      if (event.rowType === 'data') {
        row = event.row.data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } else if (<IRHEstatisticasAnaliseMensalItem>(<any>event.row.data).items.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        row = <IRHEstatisticasAnaliseMensalItem>(<any>event.row.data).items[0];
      }
      if (isDefinedNotNull(row)) {
        let rowClass = '';
        if (row.recordType === ERHAnaliseMensalRecordType.Abono) {
          rowClass = COL_CSS_CLASS_ABONO;
        } else if (row.recordType === ERHAnaliseMensalRecordType.Desconto) {
          rowClass = COL_CSS_CLASS_DESCONTOS;
        } else if (row.recordType === ERHAnaliseMensalRecordType.Impostos) {
          rowClass = COL_CSS_CLASS_IMPOSTOS;
        } else if (row.recordType === ERHAnaliseMensalRecordType.CustosEmpresa) {
          rowClass = COL_CSS_CLASS_CUSTOS_EMPRESA;
        }
        if (rowClass.length) {
          event.cellElement.classList.add(rowClass);
        }
      }
    }
  }

  public setActiveItem(item: ERHEstatisticasNavTabs): void {
    this.model.activeItem = item;
    this._setFiltersCollapsed(false);
    this.anoMesDeKind = this.model.activeItem === ERHEstatisticasNavTabs.ANALISE_ANUAL ? EPlDatepickerKind.Year : EPlDatepickerKind.Month;
  }

  public isFilterMesAteDisabled(): boolean {
    return [
      ERHEstatisticasNavTabs.EXTRATO_REMUNERACOES,
      ERHEstatisticasNavTabs.ANALISE_SEGSOCIAL,
      ERHEstatisticasNavTabs.ANALISE_SINDICATO,
      ERHEstatisticasNavTabs.ANALISE_SEGURO,
      ERHEstatisticasNavTabs.ANALISE_ANUAL,
      ERHEstatisticasNavTabs.ANALISE_AT
    ].includes(this.model.activeItem);
  }

  public isFilterCodEmpDeDisabled(): boolean {
    return [ERHEstatisticasNavTabs.ANALISE_SEGSOCIAL, ERHEstatisticasNavTabs.ANALISE_SINDICATO, ERHEstatisticasNavTabs.ANALISE_SEGURO, ERHEstatisticasNavTabs.ANALISE_AT].includes(
      this.model.activeItem
    );
  }

  public isFilterCodEmpAteDisabled(): boolean {
    return [ERHEstatisticasNavTabs.ANALISE_SEGSOCIAL, ERHEstatisticasNavTabs.ANALISE_SINDICATO, ERHEstatisticasNavTabs.ANALISE_SEGURO, ERHEstatisticasNavTabs.ANALISE_AT].includes(
      this.model.activeItem
    );
  }

  public isFilterDeptoDeDisabled(): boolean {
    return [ERHEstatisticasNavTabs.ANALISE_SEGSOCIAL, ERHEstatisticasNavTabs.ANALISE_SINDICATO, ERHEstatisticasNavTabs.ANALISE_SEGURO, ERHEstatisticasNavTabs.ANALISE_AT].includes(
      this.model.activeItem
    );
  }

  public isFilterDeptoAteDisabled(): boolean {
    return [ERHEstatisticasNavTabs.ANALISE_SEGSOCIAL, ERHEstatisticasNavTabs.ANALISE_SINDICATO, ERHEstatisticasNavTabs.ANALISE_SEGURO, ERHEstatisticasNavTabs.ANALISE_AT].includes(
      this.model.activeItem
    );
  }

  public isFilterCCustoDeDisabled(): boolean {
    return [ERHEstatisticasNavTabs.ANALISE_SEGSOCIAL, ERHEstatisticasNavTabs.ANALISE_SINDICATO, ERHEstatisticasNavTabs.ANALISE_SEGURO, ERHEstatisticasNavTabs.ANALISE_AT].includes(
      this.model.activeItem
    );
  }

  public isFilterCCustoAteDisabled(): boolean {
    return [ERHEstatisticasNavTabs.ANALISE_SEGSOCIAL, ERHEstatisticasNavTabs.ANALISE_SINDICATO, ERHEstatisticasNavTabs.ANALISE_SEGURO, ERHEstatisticasNavTabs.ANALISE_AT].includes(
      this.model.activeItem
    );
  }

  public checkExclusionButton(type: ERHEstatisticasFilterType): void {
    const filters = this._getFilters();
    switch (type) {
      case ERHEstatisticasFilterType.YEAR_MONTH:
        this._exclusionButtonState.yearMonthEnabled = moment(this.modelFiltersUI.anoMesDe).format('YYYY-MM') !== moment(this.modelFiltersUI.anoMesAte).format('YYYY-MM');
        if (!this._exclusionButtonState.yearMonthEnabled) {
          this.modelFiltersUI.exclusaoAnoMesUI = [];
        }
        break;
      case ERHEstatisticasFilterType.CODEMP:
        this._exclusionButtonState.codEmpEnabled = filters.codEmpDe.toString() !== filters.codEmpAte.toString();
        if (!this._exclusionButtonState.codEmpEnabled) {
          this.modelFiltersUI.exclusaoCodEmpUI = [];
        }
        break;
      case ERHEstatisticasFilterType.DEPTO:
        this._exclusionButtonState.deptoEnabled = filters.deptoDe.toString() !== filters.deptoAte.toString();
        if (!this._exclusionButtonState.deptoEnabled) {
          this.modelFiltersUI.exclusaoDeptoUI = [];
        }
        break;
      case ERHEstatisticasFilterType.CCUSTO:
        this._exclusionButtonState.ccustoEnabled = filters.ccustoDe.toString() !== filters.ccustoAte.toString();
        if (!this._exclusionButtonState.ccustoEnabled) {
          this.modelFiltersUI.exclusaoCCustoUI = [];
        }
        break;
    }
  }

  public isFilterExclusionButtonDisabled(type: ERHEstatisticasFilterType): boolean {
    switch (type) {
      case ERHEstatisticasFilterType.YEAR_MONTH:
        return !this._exclusionButtonState.yearMonthEnabled || this.isFilterMesAteDisabled();
      case ERHEstatisticasFilterType.CODEMP:
        return !this._exclusionButtonState.codEmpEnabled || this.isFilterCodEmpAteDisabled();
      case ERHEstatisticasFilterType.DEPTO:
        return !this._exclusionButtonState.deptoEnabled || this.isFilterDeptoAteDisabled();
      case ERHEstatisticasFilterType.CCUSTO:
        return !this._exclusionButtonState.ccustoEnabled || this.isFilterCCustoAteDisabled();
      default:
        return false;
    }
  }

  public onContentReadyAnaliseSegSocial(): void {
    const rows: Array<IJsonRHEstatisticasSegSocialResumoItem> = this.model.analiseSegSocial.dataGrid.getDataSource().items();
    const totalValColumns: IRHEstatisticasSegSocialResumoValColumns = {
      valPRemperman: 0,
      valRSubrefeicao: 0,
      valFSubferias: 0,
      valNSubnatal: 0,
      valAAjudascusto: 0,
      valBPremmensal: 0,
      valCComissoes: 0,
      valDCompcessacao: 0,
      valHHonorarios: 0,
      valMSubsregmensal: 0,
      valOPrnaomensal: 0,
      valSRsuplementar: 0,
      valTTrabnocturno: 0,
      valXOutsubsidios: 0,
      val2Ferpagascess: 0,
      val6Difvencim: 0,
      valICtrintermit: 0
    };

    if (rows.length) {
      rows.forEach((row: IJsonRHEstatisticasSegSocialResumoItem) => {
        // eslint-disable-next-line guard-for-in
        for (const prop in totalValColumns) {
          const val = isDefinedNotNull(row[prop]) ? parseFloat(row[prop]) : 0;
          totalValColumns[prop] += val;
        }
      });

      for (const prop in totalValColumns) {
        if (totalValColumns[prop] === 0) {
          this.model.analiseSegSocial.dataGrid.columnOption(prop, 'visible', false);
        }
      }
    }
  }

  public onContentReadyAnaliseSeguro(): void {
    const rows: Array<IJsonRHEstatisticasSeguroResumoItem> = this.model.analiseSeguro.dataGrid.getDataSource().items();
    const totalValColumns: IRHEstatisticasSeguroResumoValColumns = {
      valPRemperman: 0,
      valRSubrefeicao: 0,
      valFSubferias: 0,
      valNSubnatal: 0,
      valAAjudascusto: 0,
      valBPremmensal: 0,
      valCComissoes: 0,
      valDCompcessacao: 0,
      valHHonorarios: 0,
      valMSubsregmensal: 0,
      valOPrnaomensal: 0,
      valSRsuplementar: 0,
      valTTrabnocturno: 0,
      valXOutsubsidios: 0,
      val2Ferpagascess: 0,
      val6Difvencim: 0,
      valICtrintermit: 0
    };

    if (rows.length) {
      rows.forEach((row: IJsonRHEstatisticasSeguroResumoItem) => {
        // eslint-disable-next-line guard-for-in
        for (const prop in totalValColumns) {
          const val = isDefinedNotNull(row[prop]) ? parseFloat(row[prop]) : 0;
          totalValColumns[prop] += val;
        }
      });

      for (const prop in totalValColumns) {
        if (totalValColumns[prop] === 0) {
          this.model.analiseSeguro.dataGrid.columnOption(prop, 'visible', false);
        }
      }
    }
  }

  public readonly fnSearch = (): Promise<void> => this._search();

  public readonly fnShowProceExclusionModal = (field: ERHEstatisticasFilterType) => (): Promise<void> => this._showProceExclusionModal(field);

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _search(): Promise<void> {
    return this._refreshGridOnActiveTab();
  }

  private _showProceExclusionModal(field: ERHEstatisticasFilterType): Promise<void> {
    return this._getExclusionSource(field).then((source) => {
      const modalInstance = this._cgModalService.showVanilla(RhEstatisticasExclusaoModalComponent, {size: 'lg', backdrop: 'static'});
      const componentInstance: RhEstatisticasExclusaoModalComponent = modalInstance.componentInstance;
      componentInstance.source = source;
      componentInstance.model = this._getExclusionModelUI(field);
      return modalInstance.result;
    });
  }

  private _getExclusionModelUI(field: ERHEstatisticasFilterType): Array<IRHEstatisticasExclusionSourceItem> {
    switch (field) {
      case ERHEstatisticasFilterType.YEAR_MONTH:
        return this.modelFiltersUI.exclusaoAnoMesUI;
      case ERHEstatisticasFilterType.CODEMP:
        return this.modelFiltersUI.exclusaoCodEmpUI;
      case ERHEstatisticasFilterType.DEPTO:
        return this.modelFiltersUI.exclusaoDeptoUI;
      case ERHEstatisticasFilterType.CCUSTO:
        return this.modelFiltersUI.exclusaoCCustoUI;
      default:
        return [];
    }
  }

  private _getExclusionSource(field: ERHEstatisticasFilterType): Promise<Array<IRHEstatisticasExclusionSourceItem>> {
    const source: Array<IRHEstatisticasExclusionSourceItem> = [];
    const modelFilters = this._getFilters();
    return new Promise((resolve, reject) => {
      switch (field) {
        case ERHEstatisticasFilterType.YEAR_MONTH: {
          const start = moment(this.modelFiltersUI.anoMesDe);
          const end = moment(this.modelFiltersUI.anoMesAte);

          if (start.isSame(end, 'month')) {
            resolve(source);
            break;
          }

          for (let m = start; m.diff(end, 'month') <= 0; m.add(1, 'month')) {
            source.push({key: m.format('MMYYYY'), caption: m.format('MMMM [de] YYYY')});
          }
          resolve(source);
          break;
        }
        case ERHEstatisticasFilterType.CODEMP: {
          if (modelFilters.codEmpDe === modelFilters.codEmpAte) {
            resolve(source);
            break;
          }

          if (modelFilters.codEmpDe > modelFilters.codEmpAte) {
            this._plAlertService.error('rhEstatisticas.messages.intervaloInvalidoEmpregado');
            reject();
          }
          this._rhEstatisticasModuleService.getEmpregados(this.nEmpresa, modelFilters.codEmpDe, modelFilters.codEmpAte).then((response) => {
            response.body.forEach((item) => {
              source.push({key: item.codEmp, caption: `${item.codEmp} - ${item.nome} ${item.apelido}`});
            });
            resolve(source);
          });
          break;
        }
        case ERHEstatisticasFilterType.DEPTO: {
          if (modelFilters.deptoDe === modelFilters.deptoAte) {
            resolve(source);
            break;
          }

          if (modelFilters.deptoDe > modelFilters.deptoAte) {
            this._plAlertService.error('rhEstatisticas.messages.intervaloInvalidoDepto');
            reject();
          }
          this._rhEstatisticasModuleService.getDepartamentos(this.nEmpresa, modelFilters.deptoDe, modelFilters.deptoAte).then((response) => {
            response.body.forEach((item) => {
              source.push({key: item.nDepto, caption: `${item.nDepto} - ${item.nome}`});
            });
            resolve(source);
          });
          break;
        }
        case ERHEstatisticasFilterType.CCUSTO: {
          if (modelFilters.ccustoDe === modelFilters.ccustoAte) {
            resolve(source);
            break;
          }

          if (modelFilters.ccustoDe > modelFilters.ccustoAte) {
            this._plAlertService.error('rhEstatisticas.messages.intervaloInvalidoDepto');
            reject();
          }
          this._rhEstatisticasModuleService.getCCustos(this.nEmpresa, modelFilters.ccustoDe, modelFilters.ccustoAte).then((response) => {
            response.body.forEach((item) => {
              source.push({key: item.nCCusto, caption: `${item.nCCusto} - ${item.nome}`});
            });
            resolve(source);
          });
          break;
        }
        default: {
          reject();
        }
      }
    });
  }

  private _getFolhaSalariosResumoTableSource(): Promise<void> {
    if (!this._validateFilters([ERHEstatisticasFilterType.YEAR_MONTH, ERHEstatisticasFilterType.CODEMP, ERHEstatisticasFilterType.DEPTO])) {
      return Promise.reject();
    }
    this.model.estatisticas.resumoSource = [];
    this.model.estatisticas.resumoDataGrid?.beginCustomLoading(undefined);
    return this._rhEstatisticasModuleService
      .getFolhaSalarios(this.nEmpresa, this._getFilters(), false)
      .then((response) => {
        this.model.estatisticas.resumoSource = response.body;
        this._setFiltersCollapsed(response.body.length > 0);

        const colVis = {showCGA: false, showSindicato: false};

        this.model.estatisticas.resumoSource.forEach((item) => {
          if (item.totalCGA > 0) {
            colVis.showCGA = true;
          }
          if (item.totalSindicato > 0) {
            colVis.showSindicato = true;
          }
        });

        this._setColumnVisibilityModel(this.model.estatisticas.resumoDataGridDefinition.columns, 'totalCGA', colVis.showCGA);
        this._setColumnVisibilityModel(this.model.estatisticas.resumoDataGridDefinition.columns, 'totalSindicato', colVis.showSindicato);

        const caption = this._getFilterPeriod();
        const col = this.model.estatisticas.resumoDataGridDefinition.columns.find((c) => c.name === RH_GRID_LEFT_BAND);
        if (isDefinedNotNull(col)) {
          col.caption = caption;
        }
        if (isDefinedNotNull(this.model.estatisticas.resumoDataGrid)) {
          this.model.estatisticas.resumoDataGrid.columnOption(RH_GRID_LEFT_BAND, 'caption', caption);
          this._setColumnVisibilityGrid(this.model.estatisticas.resumoDataGrid, 'totalCGA', colVis.showCGA);
          this._setColumnVisibilityGrid(this.model.estatisticas.resumoDataGrid, 'totalSindicato', colVis.showSindicato);
        }
        this.model.estatisticas.resumoDataGrid?.refresh();
      })
      .finally(() => {
        this.model.estatisticas.resumoDataGrid?.endCustomLoading();
      });
  }

  private _getFolhaSalariosResumoDetailsTableSource(): Promise<void> {
    if (!this._validateFilters([ERHEstatisticasFilterType.YEAR_MONTH, ERHEstatisticasFilterType.CODEMP, ERHEstatisticasFilterType.DEPTO])) {
      return Promise.reject();
    }
    this.model.estatisticas.resumoDetailSource = [];
    this.model.estatisticas.resumoDetailDataGrid?.beginCustomLoading(undefined);
    return this._rhEstatisticasModuleService
      .getFolhaSalarios(this.nEmpresa, this._getFilters(), true)
      .then((response) => {
        this.model.estatisticas.resumoDetailSource = response.body;
        this._setFiltersCollapsed(response.body.length > 0);
        this._configResumoDetailDataGrid();

        const caption = this._getFilterPeriod();
        const col = this.model.estatisticas.resumoDetailDataGridDefinition.columns.find((c) => c.name === RH_GRID_LEFT_BAND);
        if (isDefinedNotNull(col)) {
          col.caption = caption;
        }
        if (isDefinedNotNull(this.model.estatisticas.resumoDetailDataGrid)) {
          this.model.estatisticas.resumoDetailDataGrid.columnOption(RH_GRID_LEFT_BAND, 'caption', caption);
        }

        this.model.estatisticas.resumoDetailDataGrid?.refresh();
      })
      .finally(() => {
        this.model.estatisticas.resumoDetailDataGrid?.endCustomLoading();
      });
  }

  private _getAnaliseMensalTableSource(): Promise<void> {
    if (!this._validateFilters([ERHEstatisticasFilterType.CODEMP, ERHEstatisticasFilterType.DEPTO])) {
      return Promise.reject();
    }
    this.model.extratoRemuneracoes.dataGridSource = [];
    const filters = this._getFilters();
    filters.anoAte = -1;
    filters.mesAte = -1;
    filters.exclusaoAnoMes = [];

    this.model.extratoRemuneracoes.dataGrid?.beginCustomLoading(undefined);
    return this._rhEstatisticasModuleService
      .getFolhaSalarios(this.nEmpresa, filters, true)
      .then((response) => {
        this._setFiltersCollapsed(response.body.length > 0);
        const source: Array<IRHEstatisticasAnaliseMensalItem> = [];
        const mainColBand = this.model.extratoRemuneracoes.dataGridDefinition.columns.find((c) => c.name === RH_EXTRATO_RENUM_BAND_EMPREGADOS);
        if (isDefinedNotNull(mainColBand)) {
          mainColBand.columns = [];
        }
        const summaryGroupItems: Array<IDevExpressDataGridSummaryGroupItems> = [
          {
            column: 'totalGlobal',
            summaryType: 'sum',
            displayFormat: '{0}',
            valueFormat: CELL_FORMAT_STRING,
            showInGroupFooter: true
          }
        ];
        response.body.forEach((empregado) => {
          const colQtdDataField = `emp_${empregado.codEmp}_qtd`;
          const colValorUnitDataField = `emp_${empregado.codEmp}_valorUnit`;
          const colValorTotalDataField = `emp_${empregado.codEmp}_valorTotal`;
          const empregadoColBand: IDevExpressDataGridColumn = {
            name: `emp_${empregado.codEmp}_band`,
            caption: `${empregado.codEmp} - ${empregado.nome}`,
            alignment: 'center',
            columns: [
              {dataField: colQtdDataField, dataType: 'number', caption: this._translateService.instant('rhEstatisticas.fields.qtd'), allowEditing: false, alignment: 'right'},
              {
                dataField: colValorUnitDataField,
                dataType: 'currency',
                caption: this._translateService.instant('rhEstatisticas.fields.valorUnit'),
                allowEditing: false,
                format: CELL_FORMAT_STRING,
                alignment: 'right'
              },
              {
                dataField: colValorTotalDataField,
                dataType: 'currency',
                cssClass: COL_CSS_CLASS_BOLD,
                caption: this._translateService.instant('rhEstatisticas.fields.valorTotal'),
                allowEditing: false,
                format: CELL_FORMAT_STRING,
                alignment: 'right'
              }
            ]
          };

          summaryGroupItems.push({column: colValorTotalDataField, summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING, showInGroupFooter: true});

          empregado.detalhes.forEach((item) => {
            if (item.rhTipoRegisto === ERHTipoRegisto.CustosEmpresa && !['1-ENCARGOS', '2-CUSTOEMPRESA'].includes(item.nCodAbdesc)) {
              return;
            }
            const recordType = this._getRecordType(item.rhTipoRegisto);
            let row: IRHEstatisticasAnaliseMensalItem = source.find((s) => s.recordType === recordType && s.nCodAbdesc === item.nCodAbdesc);
            if (isUndefinedOrNull(row)) {
              row = {
                recordType: recordType,
                recordTypeDesc: this._getRecordTypeString(recordType),
                nCodAbdesc: item.nCodAbdesc,
                nomeCodAbdesc: item.nomeCodAbdesc,
                totalGlobal: 0
              };
              source.push(row);
            }

            if (item.qtd !== 0) {
              row[colQtdDataField] = item.qtd;
            }
            if (item.valorUnit !== 0) {
              row[colValorUnitDataField] = item.valorUnit;
            }
            if (item.valor !== 0) {
              row[colValorTotalDataField] = item.valor;
            }

            row.totalGlobal += item.valor;
          });

          // add Recibo Section
          let row: IRHEstatisticasAnaliseMensalItem = source.find((s) => s.nCodAbdesc === 'RECIBO-001');
          if (isUndefinedOrNull(row)) {
            row = {
              recordType: ERHAnaliseMensalRecordType.Recibo,
              recordTypeDesc: this._getRecordTypeString(ERHAnaliseMensalRecordType.Recibo),
              nCodAbdesc: 'RECIBO-001',
              nomeCodAbdesc: this._translateService.instant('rhEstatisticas.fields.totalLiquido'),
              totalGlobal: 0
            };
            source.push(row);
          }
          row[colValorTotalDataField] = empregado.totalRecibo;
          row.totalGlobal += empregado.totalRecibo;

          row = source.find((s) => s.nCodAbdesc === 'RECIBO-002');
          if (isUndefinedOrNull(row)) {
            row = {
              recordType: ERHAnaliseMensalRecordType.Recibo,
              recordTypeDesc: this._getRecordTypeString(ERHAnaliseMensalRecordType.Recibo),
              nCodAbdesc: 'RECIBO-002',
              nomeCodAbdesc: this._translateService.instant('rhEstatisticas.fields.totalEspecie'),
              totalGlobal: 0
            };
            source.push(row);
          }
          row[colValorTotalDataField] = empregado.totalEspecie;
          row.totalGlobal += empregado.totalEspecie;

          row = source.find((s) => s.nCodAbdesc === 'RECIBO-003');
          if (isUndefinedOrNull(row)) {
            row = {
              recordType: ERHAnaliseMensalRecordType.Recibo,
              recordTypeDesc: this._getRecordTypeString(ERHAnaliseMensalRecordType.Recibo),
              nCodAbdesc: 'RECIBO-003',
              nomeCodAbdesc: this._translateService.instant('rhEstatisticas.fields.totalAPagar'),
              totalGlobal: 0
            };
            source.push(row);
          }
          row[colValorTotalDataField] = empregado.totalAPagar;
          row.totalGlobal += empregado.totalAPagar;

          if (isDefinedNotNull(mainColBand)) {
            mainColBand.columns.push(empregadoColBand);
          }
        });

        const caption = this._getFilterPeriod();

        this.model.extratoRemuneracoes.dataGridDefinition.summary.groupItems = summaryGroupItems;
        const col = this.model.extratoRemuneracoes.dataGridDefinition.columns.find((c) => c.name === RH_GRID_LEFT_BAND);
        if (isDefinedNotNull(col)) {
          col.caption = caption;
        }
        if (isDefinedNotNull(this.model.extratoRemuneracoes.dataGrid)) {
          this.model.extratoRemuneracoes.dataGrid.columnOption(RH_EXTRATO_RENUM_BAND_EMPREGADOS, 'columns', mainColBand.columns);
          this.model.extratoRemuneracoes.dataGrid.columnOption(RH_GRID_LEFT_BAND, 'caption', caption);
          this.model.extratoRemuneracoes.dataGrid.option('summary', {
            groupItems: <Array<SummaryGroupItem>>summaryGroupItems
          });
        }
        this.model.extratoRemuneracoes.dataGridSource = source;
        this.model.extratoRemuneracoes.dataGrid?.refresh();
      })
      .finally(() => {
        this.model.extratoRemuneracoes.dataGrid?.endCustomLoading();
      });
  }

  private _getAnaliseSegSocialTableSource(): Promise<void> {
    const filters = this._getFilters();
    filters.anoAte = -1;
    filters.mesAte = -1;
    filters.exclusaoAnoMes = [];
    filters.exclusaoCodEmp = [];
    filters.exclusaoDepto = [];
    filters.exclusaoCCusto = [];
    this.model.analiseSegSocial.dataGridSource = [];
    this.model.analiseSegSocial.dataGrid?.beginCustomLoading(undefined);
    return this._rhEstatisticasModuleService
      .getSegSocial(this.nEmpresa, filters)
      .then((response) => {
        this._setFiltersCollapsed(response.body.length > 0);
        this.model.analiseSegSocial.dataGridSource = response.body;

        const caption = this._getFilterPeriod();
        const col = this.model.analiseSegSocial.dataGridDefinition.columns.find((c) => c.name === RH_GRID_LEFT_BAND);
        if (isDefinedNotNull(col)) {
          col.caption = caption;
        }
        if (isDefinedNotNull(this.model.analiseSegSocial.dataGrid)) {
          this.model.analiseSegSocial.dataGrid.columnOption(RH_GRID_LEFT_BAND, 'caption', caption);
        }

        this.model.analiseSegSocial.dataGrid?.refresh();
      })
      .finally(() => {
        this.model.analiseSegSocial.dataGrid?.endCustomLoading();
      });
  }

  private _getAnaliseSindicatoTableSource(): Promise<void> {
    const filters = this._getFilters();
    filters.anoAte = -1;
    filters.mesAte = -1;
    filters.exclusaoAnoMes = [];
    filters.exclusaoCodEmp = [];
    filters.exclusaoDepto = [];
    filters.exclusaoCCusto = [];
    this.model.analiseSindicato.dataGridSource = [];
    this.model.analiseSindicato.dataGrid?.beginCustomLoading(undefined);
    return this._rhEstatisticasModuleService
      .getSindicato(this.nEmpresa, filters)
      .then((response) => {
        this._setFiltersCollapsed(response.body.length > 0);
        response.body.forEach((item) => {
          const sourceItem: IRHEstatisticasSindicatoItem = {...item, uid: `${item.codEmp}-${item.nSindicato}`};
          this.model.analiseSindicato.dataGridSource.push(sourceItem);
        });
        this.model.analiseSindicato.dataGrid?.refresh();
      })
      .finally(() => {
        this.model.analiseSindicato.dataGrid?.endCustomLoading();
      });
  }

  private _getAnaliseSeguroTableSource(): Promise<void> {
    const filters = this._getFilters();
    filters.anoAte = -1;
    filters.mesAte = -1;
    filters.exclusaoAnoMes = [];
    filters.exclusaoCodEmp = [];
    filters.exclusaoDepto = [];
    filters.exclusaoCCusto = [];
    this.model.analiseSeguro.dataGridSource = [];
    this.model.analiseSeguro.dataGrid?.beginCustomLoading(undefined);
    return this._rhEstatisticasModuleService
      .getSeguro(this.nEmpresa, filters)
      .then((response) => {
        this._setFiltersCollapsed(response.body.length > 0);
        this.model.analiseSeguro.dataGridSource = response.body;

        const caption = this._getFilterPeriod();
        const col = this.model.analiseSeguro.dataGridDefinition.columns.find((c) => c.name === RH_GRID_LEFT_BAND);
        if (isDefinedNotNull(col)) {
          col.caption = caption;
        }
        if (isDefinedNotNull(this.model.analiseSeguro.dataGrid)) {
          this.model.analiseSeguro.dataGrid.columnOption(RH_GRID_LEFT_BAND, 'caption', caption);
        }
        this.model.analiseSeguro.dataGrid?.refresh();
      })
      .finally(() => {
        this.model.analiseSeguro.dataGrid?.endCustomLoading();
      });
  }

  private _getAnaliseAnualTableSource(): Promise<void> {
    if (!this._validateFilters([ERHEstatisticasFilterType.CODEMP, ERHEstatisticasFilterType.DEPTO])) {
      return Promise.reject();
    }

    this.model.analiseAnual.dataGridSource = [];
    const filters = this._getFilters();
    filters.anoAte = -1;
    filters.mesAte = -1;
    filters.exclusaoAnoMes = [];
    this.model.analiseAnual.dataGrid?.beginCustomLoading(undefined);
    return this._rhEstatisticasModuleService
      .getMapaAnual(this.nEmpresa, filters)
      .then((response) => {
        this._setFiltersCollapsed(response.body.data.length > 0 || response.body.anoAnterior.length > 0);
        const source: Array<IRHEstatisticasAnaliseAnualItem> = [];
        const colMonthsBand = this.model.analiseAnual.dataGridDefinition.columns.find((c) => c.name === RH_MAPA_ANUAL_BAND_MONTHS);
        if (isDefinedNotNull(colMonthsBand)) {
          colMonthsBand.caption = filters.anoDe.toString();
          colMonthsBand.columns = [];
        }

        const summaryGroupItems: Array<IDevExpressDataGridSummaryGroupItems> = [
          {column: 'totalAnoAnterior', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING, showInGroupFooter: true},
          {column: 'totalAnoFilter', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING, showInGroupFooter: true}
        ];

        // previous year
        const anoAnteriorReciboTotais: IRHEstatisticasAnaliseAnualReciboTotais = {
          totalAPagar: 0,
          totalRecibo: 0,
          totalEspecie: 0,
          totalCustosEmpresa: 0,
          totalEncargos: 0
        };
        response.body.anoAnterior.forEach((empregado) => {
          empregado.detalhes.forEach((detail) => {
            if (detail.rhTipoRegisto === ERHTipoRegisto.CustosEmpresa && !['1-ENCARGOS', '2-CUSTOEMPRESA'].includes(detail.nCodAbdesc)) {
              return;
            }
            const rowKey = `${detail.rhTipoRegisto}-${detail.nCodAbdesc}`;
            // check if source already have row
            const row = source.find((sourceItem) => sourceItem.rowKey === rowKey);
            if (isUndefinedOrNull(row)) {
              const recordType = this._getRecordType(detail.rhTipoRegisto);
              source.push({
                rowKey: rowKey,
                recordType: recordType,
                recordTypeDesc: this._getRecordTypeString(recordType),
                nCodAbdesc: detail.nCodAbdesc,
                nomeCodAbdesc: detail.nomeCodAbdesc,
                totalAnoAnterior: detail.valor,
                totalAnoFilter: 0
              });
            } else {
              row.totalAnoAnterior += detail.valor;
            }
          });

          empregado.reciboMesTotais.forEach((rmt) => {
            anoAnteriorReciboTotais.totalRecibo += rmt.totalRecibo;
            anoAnteriorReciboTotais.totalAPagar += rmt.totalAPagar;
            anoAnteriorReciboTotais.totalEspecie += rmt.totalEspecie;
            anoAnteriorReciboTotais.totalEncargos += rmt.totalEncargos;
            anoAnteriorReciboTotais.totalCustosEmpresa += rmt.totalCustosEmpresa;
          });
        });

        const anoReciboTotais: IRHEstatisticasAnaliseAnualReciboTotais = {
          totalAPagar: 0,
          totalRecibo: 0,
          totalEspecie: 0,
          totalCustosEmpresa: 0,
          totalEncargos: 0
        };
        // selected year
        const monthsCols: Array<IDevExpressDataGridColumn> = [];
        response.body.data.forEach((empregado) => {
          empregado.detalhes.forEach((detail) => {
            if (detail.rhTipoRegisto === ERHTipoRegisto.CustosEmpresa && !['1-ENCARGOS', '2-CUSTOEMPRESA'].includes(detail.nCodAbdesc)) {
              return;
            }
            const rowKey = `${detail.rhTipoRegisto}-${detail.nCodAbdesc}`;
            const dataField = `${detail.ano}-${detail.mes}`;

            // check and add month col in band
            const index = monthsCols.findIndex((c) => c.dataField === dataField);
            if (index < 0) {
              summaryGroupItems.push({column: dataField, summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING, showInGroupFooter: true});
              monthsCols.push({
                dataField: dataField,
                caption: monthName(detail.mes - 1),
                alignment: 'center',
                dataType: 'currency',
                format: CELL_FORMAT_STRING,
                calculateCellValue: (rowData: IRHEstatisticasAnaliseAnualItem) => (isNumber(rowData[dataField]) ? rowData[dataField] : 0)
              });
            }

            let row: IRHEstatisticasAnaliseAnualItem = source.find((sourceItem) => sourceItem.rowKey === rowKey);
            if (isUndefinedOrNull(row)) {
              const recordType = this._getRecordType(detail.rhTipoRegisto);
              row = {
                rowKey: rowKey,
                recordType: recordType,
                recordTypeDesc: this._getRecordTypeString(recordType),
                nCodAbdesc: detail.nCodAbdesc,
                nomeCodAbdesc: detail.nomeCodAbdesc,
                totalAnoAnterior: 0,
                totalAnoFilter: 0
              };
              source.push(row);
            }
            row.totalAnoFilter += detail.valor;
            row[dataField] = isDefinedNotNull(row[dataField]) ? parseFloat(row[dataField]) + detail.valor : detail.valor;
          });
          // totais ano
          empregado.reciboMesTotais.forEach((rmt) => {
            anoReciboTotais.totalRecibo += rmt.totalRecibo;
            anoReciboTotais.totalAPagar += rmt.totalAPagar;
            anoReciboTotais.totalEspecie += rmt.totalEspecie;
            anoReciboTotais.totalEncargos += rmt.totalEncargos;
            anoReciboTotais.totalCustosEmpresa += rmt.totalCustosEmpresa;
          });

          const customColOrderMap = new Map<string, number>([
            ['totalRecibo', 1],
            ['totalEspecie', 2],
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            ['totalAPagar', 3],
            ['totalEncargos', 1],
            ['totalCustosEmpresa', 2]
          ]);

          empregado.reciboMesTotais.forEach((rmt) => {
            const dataField = `${rmt.ano}-${rmt.mes}`;
            ['totalRecibo', 'totalEspecie', 'totalAPagar', 'totalEncargos', 'totalCustosEmpresa'].forEach((fieldName) => {
              const isCustoEmpresa = ['totalEncargos', 'totalCustosEmpresa'].includes(fieldName);
              const recordType = isCustoEmpresa ? ERHAnaliseMensalRecordType.CustosEmpresa : ERHAnaliseMensalRecordType.Recibo;
              let row = source.find((sourceItem) => sourceItem.rowKey === `${recordType}-${fieldName}`);
              if (isUndefinedOrNull(row)) {
                row = {
                  rowKey: `${recordType}-${fieldName}`,
                  recordType: recordType,
                  recordTypeDesc: this._getRecordTypeString(recordType),
                  nCodAbdesc: `${customColOrderMap.get(fieldName)} - ${fieldName}`,
                  nomeCodAbdesc: this._translateService.instant(`rhEstatisticas.fields.${fieldName}`),
                  totalAnoAnterior: anoAnteriorReciboTotais[fieldName],
                  totalAnoFilter: 0
                };
                source.push(row);
              }
              row[dataField] = isDefinedNotNull(row[dataField]) ? parseFloat(row[dataField]) + rmt[fieldName] : rmt[fieldName];
            });
          });
        });

        ['totalRecibo', 'totalEspecie', 'totalAPagar', 'totalEncargos', 'totalCustosEmpresa'].forEach((fieldName) => {
          const isCustoEmpresa = ['totalEncargos', 'totalCustosEmpresa'].includes(fieldName);
          const recordType = isCustoEmpresa ? ERHAnaliseMensalRecordType.CustosEmpresa : ERHAnaliseMensalRecordType.Recibo;
          const row = source.find((sourceItem) => sourceItem.rowKey === `${recordType}-${fieldName}`);
          if (isDefinedNotNull(row)) {
            row.totalAnoFilter = anoReciboTotais[fieldName];
          }
        });

        const monthsColBand = this.model.analiseAnual.dataGridDefinition.columns.find((c) => c.name === RH_MAPA_ANUAL_BAND_MONTHS);
        if (isDefinedNotNull(monthsColBand)) {
          monthsColBand.columns = monthsCols;
        }
        this.model.analiseAnual.dataGridDefinition.summary.groupItems = summaryGroupItems;

        const colLeftBand = this.model.analiseAnual.dataGridDefinition.columns.find((c) => c.name === RH_MAPA_ANUAL_BAND_LEFT);
        if (isDefinedNotNull(colLeftBand)) {
          const c = colLeftBand.columns.find((col) => col.dataField === 'totalAnoAnterior');
          if (isDefinedNotNull(c)) {
            c.caption = `Total ${filters.anoDe - 1}`;
          }
        }

        const c = this.model.analiseAnual.dataGridDefinition.columns.find((col) => col.dataField === 'totalAnoFilter');
        if (isDefinedNotNull(c)) {
          c.caption = `Total ${filters.anoDe}`;
        }

        if (isDefinedNotNull(this.model.analiseAnual.dataGrid)) {
          this.model.analiseAnual.dataGrid.columnOption(RH_MAPA_ANUAL_BAND_MONTHS, 'columns', monthsCols);
          this.model.analiseAnual.dataGrid.columnOption(RH_MAPA_ANUAL_BAND_MONTHS, 'caption', filters.anoDe);
          this.model.analiseAnual.dataGrid.columnOption('totalAnoFilter', 'caption', `Total ${filters.anoDe}`);
          this.model.analiseAnual.dataGrid.columnOption('totalAnoAnterior', 'caption', `Total ${filters.anoDe - 1}`);
          this.model.analiseAnual.dataGrid.option('summary', {
            groupItems: <Array<SummaryGroupItem>>summaryGroupItems
          });
        }
        this.model.analiseAnual.dataGridSource = source;
        this.model.analiseAnual.dataGrid?.refresh();
      })
      .finally(() => {
        this.model.analiseAnual.dataGrid?.endCustomLoading();
      });
  }

  private _getAnaliseATTableSource(): Promise<void> {
    const filters = this._getFilters();
    filters.anoAte = -1;
    filters.mesAte = -1;
    filters.exclusaoAnoMes = [];
    filters.exclusaoCodEmp = [];
    filters.exclusaoDepto = [];
    filters.exclusaoCCusto = [];
    this.model.analiseAT.dataGridSource = [];
    const colBand = this.model.analiseAT.dataGridDefinition.columns.find((c) => c.name === RH_ESTATISTICAS_MAPA_AT_BAND);
    if (isDefinedNotNull(colBand)) {
      colBand.columns = [];
    }
    this.model.analiseAT.dataGrid?.beginCustomLoading(undefined);
    return this._rhEstatisticasModuleService
      .getMapaAT(this.nEmpresa, filters)
      .then((response) => {
        this._setFiltersCollapsed(response.body.length > 0);
        response.body.forEach((item) => {
          for (const key in item) {
            if (!this._mapaATNonBandColumns.includes(key) && item[key] !== 0) {
              const col = colBand.columns.find((c) => c.dataField === key);
              if (isUndefinedOrNull(col)) {
                const codA = this._mapaATBandColCaptions.get(key);
                colBand.columns.push({
                  dataField: key,
                  caption: this._translateService.instant(`rhEstatisticas.fields.${codA}`),
                  dataType: 'currency',
                  alignment: 'right',
                  allowEditing: false,
                  calculateDisplayValue: (row) => {
                    if (row[key] === 0) {
                      return '';
                    }
                    return row[key];
                  },
                  format: CELL_FORMAT_STRING
                });
              }
            }
          }
        });

        const caption = this._getFilterPeriod();
        const col = this.model.analiseAT.dataGridDefinition.columns.find((c) => c.name === RH_GRID_LEFT_BAND);
        if (isDefinedNotNull(col)) {
          col.caption = caption;
        }

        if (isDefinedNotNull(this.model.analiseAT.dataGrid)) {
          colBand.columns = colBand.columns.sort((a, b) => a.caption.toString().localeCompare(b.caption.toString()));
          this.model.analiseAT.dataGrid.columnOption(RH_ESTATISTICAS_MAPA_AT_BAND, 'columns', colBand.columns);
          this.model.analiseAT.dataGrid.columnOption(RH_GRID_LEFT_BAND, 'caption', caption);
        }
        this.model.analiseAT.dataGridSource = response.body;
        this.model.analiseAT.dataGrid?.refresh();
      })
      .finally(() => {
        this.model.analiseAT.dataGrid?.endCustomLoading();
      });
  }

  private _refreshGridOnActiveTab(): Promise<void> {
    if (this.model.activeItem === ERHEstatisticasNavTabs.ESTATISTICAS) {
      if (this.model.estatisticas.activeTab === ERHEstatisticasEstatisticasTabs.RESUMO) {
        return this._getFolhaSalariosResumoTableSource();
      } else if (this.model.estatisticas.activeTab === ERHEstatisticasEstatisticasTabs.DETALHADA_AD) {
        return this._getFolhaSalariosResumoDetailsTableSource();
      }
    } else if (this.model.activeItem === ERHEstatisticasNavTabs.EXTRATO_REMUNERACOES) {
      return this._getAnaliseMensalTableSource();
    } else if (this.model.activeItem === ERHEstatisticasNavTabs.ANALISE_SEGSOCIAL) {
      return this._getAnaliseSegSocialTableSource();
    } else if (this.model.activeItem === ERHEstatisticasNavTabs.ANALISE_SINDICATO) {
      return this._getAnaliseSindicatoTableSource();
    } else if (this.model.activeItem === ERHEstatisticasNavTabs.ANALISE_SEGURO) {
      return this._getAnaliseSeguroTableSource();
    } else if (this.model.activeItem === ERHEstatisticasNavTabs.ANALISE_ANUAL) {
      return this._getAnaliseAnualTableSource();
    } else if (this.model.activeItem === ERHEstatisticasNavTabs.ANALISE_AT) {
      return this._getAnaliseATTableSource();
    }
    return Promise.resolve();
  }

  private _getFilters(): IJsonRHEstatisticasFilters {
    const filters = {
      anoDe: moment(this.modelFiltersUI.anoMesDe).year(),
      mesDe: moment(this.modelFiltersUI.anoMesDe).month() + 1,
      anoAte: moment(this.modelFiltersUI.anoMesAte).year(),
      mesAte: moment(this.modelFiltersUI.anoMesAte).month() + 1,
      codEmpDe: 0,
      codEmpAte: EDelphiNumberTypes.MaxSmallInt,
      deptoDe: 0,
      deptoAte: EDelphiNumberTypes.MaxSmallInt,
      ccustoDe: '',
      ccustoAte: 'ZZZZ',
      exclusaoAnoMes: this.modelFiltersUI.exclusaoAnoMesUI.map((item) => item.key.toString()),
      exclusaoCodEmp: this.modelFiltersUI.exclusaoCodEmpUI.map((item) => <number>item.key),
      exclusaoDepto: this.modelFiltersUI.exclusaoDeptoUI.map((item) => <number>item.key),
      exclusaoCCusto: this.modelFiltersUI.exclusaoCCustoUI.map((item) => item.key.toString())
    };

    if (!isEmpty(this.modelFiltersUI.codEmpDe)) {
      filters.codEmpDe = isObject(this.modelFiltersUI.codEmpDe) ? (<IJsonDGEMP>this.modelFiltersUI.codEmpDe).codEmp : <number>this.modelFiltersUI.codEmpDe;
    }

    if (!isEmpty(this.modelFiltersUI.codEmpAte)) {
      filters.codEmpAte = isObject(this.modelFiltersUI.codEmpAte) ? (<IJsonDGEMP>this.modelFiltersUI.codEmpAte).codEmp : <number>this.modelFiltersUI.codEmpAte;
    }

    if (!isEmpty(this.modelFiltersUI.deptoDe)) {
      filters.deptoDe = isObject(this.modelFiltersUI.deptoDe) ? (<IJsonDepto>this.modelFiltersUI.deptoDe).nDepto : <number>this.modelFiltersUI.deptoDe;
    }

    if (!isEmpty(this.modelFiltersUI.deptoAte)) {
      filters.deptoAte = isObject(this.modelFiltersUI.deptoAte) ? (<IJsonDepto>this.modelFiltersUI.deptoAte).nDepto : <number>this.modelFiltersUI.deptoAte;
    }

    if (!isEmpty(this.modelFiltersUI.ccustoDe)) {
      filters.ccustoDe = isObject(this.modelFiltersUI.ccustoDe) ? (<IJsonCCusto>this.modelFiltersUI.ccustoDe).nCCusto : <string>this.modelFiltersUI.ccustoDe;
    }

    if (!isEmpty(this.modelFiltersUI.ccustoAte)) {
      filters.ccustoAte = isObject(this.modelFiltersUI.ccustoAte) ? (<IJsonCCusto>this.modelFiltersUI.ccustoAte).nCCusto : <string>this.modelFiltersUI.ccustoAte;
    }

    return filters;
  }

  private _validateFilters(types: Array<ERHEstatisticasFilterType> = []): boolean {
    if (types.includes(ERHEstatisticasFilterType.YEAR_MONTH)) {
      if (moment(this.modelFiltersUI.anoMesDe).isAfter(this.modelFiltersUI.anoMesAte)) {
        this._plAlertService.error('rhEstatisticas.messages.intervaloInvalidoMesProc');
        return false;
      }
    }

    if (types.includes(ERHEstatisticasFilterType.CODEMP)) {
      if (this.modelFiltersUI.codEmpDe > this.modelFiltersUI.codEmpAte) {
        this._plAlertService.error('rhEstatisticas.messages.intervaloInvalidoEmpregado');
        return false;
      }
    }

    if (types.includes(ERHEstatisticasFilterType.DEPTO)) {
      if (this.modelFiltersUI.deptoDe > this.modelFiltersUI.deptoAte) {
        this._plAlertService.error('rhEstatisticas.messages.intervaloInvalidoDepto');
        return false;
      }
    }

    return true;
  }

  private _configResumoDetailDataGrid(): void {
    const abonosColumns: Array<IDevExpressDataGridColumn> = [];
    const descontosColumns: Array<IDevExpressDataGridColumn> = [];
    const summaryItems = this._getDefaultFolhaSalariosSummaryTotalItems();
    const colVis = {showCGA: false, showSindicato: false};
    this.model.estatisticas.resumoDetailSource.forEach((empregadoItem) => {
      if (empregadoItem.totalCGA > 0) {
        colVis.showCGA = true;
      }
      if (empregadoItem.totalSindicato > 0) {
        colVis.showSindicato = true;
      }

      empregadoItem.detalhes.forEach((detalheItem) => {
        const newDataField = `DYN_${detalheItem.nCodAbdesc}`;
        empregadoItem[newDataField] = detalheItem.valor;
        let index = summaryItems.findIndex((col) => col.column === newDataField);
        if (index < 0) {
          summaryItems.push({column: newDataField, summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING, alignment: 'right'});
        }

        if (detalheItem.rhTipoRegisto === ERHTipoRegisto.Abono) {
          index = abonosColumns.findIndex((c) => c.dataField === newDataField);
          if (index < 0) {
            abonosColumns.push({
              dataField: newDataField,
              cssClass: COL_CSS_CLASS_ABONO,
              caption: detalheItem.nomeCodAbdesc,
              dataType: 'currency',
              allowEditing: false,
              alignment: 'right',
              visibleIndex: parseInt(detalheItem.nCodAbdesc, ERadix.Decimal),
              format: CELL_FORMAT_STRING,
              calculateCellValue: (rowData: IRHEstatisticasFolhaSalarioResumoItem) => (isNumber(rowData[newDataField]) ? rowData[newDataField] : 0)
            });
          }
        } else if (detalheItem.rhTipoRegisto === ERHTipoRegisto.Desconto) {
          index = descontosColumns.findIndex((c) => c.dataField === newDataField);
          if (index < 0) {
            descontosColumns.push({
              dataField: newDataField,
              cssClass: COL_CSS_CLASS_DESCONTOS,
              caption: detalheItem.nomeCodAbdesc,
              dataType: 'currency',
              allowEditing: false,
              alignment: 'right',
              format: CELL_FORMAT_STRING,
              calculateCellValue: (rowData: IRHEstatisticasFolhaSalarioResumoItem) => (isNumber(rowData[newDataField]) ? rowData[newDataField] : 0)
            });
          }
        }
      });
    });

    abonosColumns.push({
      dataField: 'totalAbonos',
      cssClass: `${COL_CSS_CLASS_ABONO} ${COL_CSS_CLASS_BOLD}`,
      dataType: 'currency',
      caption: this._translateService.instant('rhEstatisticas.fields.totalAbonos'),
      allowEditing: false,
      alignment: 'right',
      visibleIndex: 99999,
      format: CELL_FORMAT_STRING
    });

    descontosColumns.push({
      dataField: 'totalDescontos',
      cssClass: `${COL_CSS_CLASS_DESCONTOS} ${COL_CSS_CLASS_BOLD}`,
      dataType: 'currency',
      caption: this._translateService.instant('rhEstatisticas.fields.totalDescontos'),
      allowEditing: false,
      alignment: 'right',
      format: CELL_FORMAT_STRING
    });

    let column = this.model.estatisticas.resumoDetailDataGridDefinition.columns.find((col) => col.name === RH_ESTATISTICAS_RESUMO_DETAIL_BAND_ABONOS);
    if (isDefinedNotNull(column)) {
      column.columns = abonosColumns;
    }

    column = this.model.estatisticas.resumoDetailDataGridDefinition.columns.find((col) => col.name === RH_ESTATISTICAS_RESUMO_DETAIL_BAND_DESCONTOS);
    if (isDefinedNotNull(column)) {
      column.columns = descontosColumns;
    }

    this._setColumnVisibilityModel(this.model.estatisticas.resumoDetailDataGridDefinition.columns, 'totalCGA', colVis.showCGA);
    this._setColumnVisibilityModel(this.model.estatisticas.resumoDetailDataGridDefinition.columns, 'totalSindicato', colVis.showSindicato);

    this.model.estatisticas.resumoDetailDataGridDefinition.summary.totalItems = summaryItems;

    if (isDefinedNotNull(this.model.estatisticas.resumoDetailDataGrid)) {
      this.model.estatisticas.resumoDetailDataGrid.columnOption(RH_ESTATISTICAS_RESUMO_DETAIL_BAND_ABONOS, 'columns', abonosColumns);
      this.model.estatisticas.resumoDetailDataGrid.columnOption(RH_ESTATISTICAS_RESUMO_DETAIL_BAND_DESCONTOS, 'columns', descontosColumns);
      this.model.estatisticas.resumoDetailDataGrid.option('summary', {
        totalItems: <Array<SummaryTotalItem>>summaryItems
      });
      this._setColumnVisibilityGrid(this.model.estatisticas.resumoDetailDataGrid, 'totalCGA', colVis.showCGA);
      this._setColumnVisibilityGrid(this.model.estatisticas.resumoDetailDataGrid, 'totalSindicato', colVis.showSindicato);
    }
  }

  private _getDefaultFolhaSalariosSummaryTotalItems(): Array<IDevExpressDataGridSummaryTotalItems> {
    return [
      {column: 'totalAbonos', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING},
      {column: 'totalDescontos', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING},
      {column: 'totalIliquido', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING},
      {column: 'totalIRS', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING},
      {column: 'totalSS', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING},
      {column: 'totalCGA', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING},
      {column: 'totalSindicato', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING},
      {column: 'totalImpostos', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING},
      {column: 'totalRecibo', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING},
      {column: 'totalEspecie', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING},
      {column: 'totalAPagar', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING},
      {column: 'totalEncargos', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING},
      {column: 'totalCustosEmpresa', summaryType: 'sum', displayFormat: '{0}', valueFormat: CELL_FORMAT_STRING}
    ];
  }

  private _getRecordType(rhTipoRegisto: ERHTipoRegisto): ERHAnaliseMensalRecordType {
    switch (rhTipoRegisto) {
      case ERHTipoRegisto.Abono:
        return ERHAnaliseMensalRecordType.Abono;
      case ERHTipoRegisto.Desconto:
        return ERHAnaliseMensalRecordType.Desconto;
      case ERHTipoRegisto.Impostos:
        return ERHAnaliseMensalRecordType.Impostos;
      case ERHTipoRegisto.CustosEmpresa:
        return ERHAnaliseMensalRecordType.CustosEmpresa;
      default:
        return ERHAnaliseMensalRecordType.Recibo;
    }
  }

  private _getRecordTypeString(recordType: ERHAnaliseMensalRecordType): string {
    switch (recordType) {
      case ERHAnaliseMensalRecordType.Abono:
        return this._translateService.instant('rhEstatisticas.fields.abonos');
      case ERHAnaliseMensalRecordType.Desconto:
        return this._translateService.instant('rhEstatisticas.fields.descontos');
      case ERHAnaliseMensalRecordType.Impostos:
        return this._translateService.instant('rhEstatisticas.fields.impostos');
      case ERHAnaliseMensalRecordType.CustosEmpresa:
        return this._translateService.instant('rhEstatisticas.fields.custoEmpresa');
      case ERHAnaliseMensalRecordType.Recibo:
        return this._translateService.instant('rhEstatisticas.string.recibo');
      default:
        return '';
    }
  }

  private _setFiltersCollapsed(collapsed: boolean): void {
    if (collapsed && this._cardPanel.fixedCardBody) {
      return;
    }
    this._cardPanel.setCollapsed(collapsed);
  }

  private _getFilterPeriod(): string {
    const m = moment(this.modelFiltersUI.anoMesDe);
    if (m.isSame(this.modelFiltersUI.anoMesAte, 'day') || this.isFilterMesAteDisabled()) {
      return m.format('MM/YYYY');
    }
    return `${moment(this.modelFiltersUI.anoMesDe).format('MM/YYYY')} - ${moment(this.modelFiltersUI.anoMesAte).format('MM/YYYY')}`;
  }

  private _setColumnVisibilityModel(columns: Array<IDevExpressDataGridColumn>, columnName: string, visible: boolean): void {
    const col = columns.find((c) => c.name === columnName);
    if (isDefinedNotNull(col)) {
      col.visible = visible;
    }
  }

  private _setColumnVisibilityGrid(grid: dxDataGrid, columnName: string, visible: boolean): void {
    if (isDefinedNotNull(grid)) {
      grid.columnOption(columnName, 'visible', visible);
    }
  }
}
