import {NgModule} from '@angular/core';
import {PlDynamicVisualsSecondaryClickModule} from './implementations/secondaryclick/dynamicvisuals.secondaryclick.module';

export * from './implementations/abstract/dynamicvisuals.abstract.component';
export * from './implementations/secondaryclick/dynamicvisuals.secondaryclick.module';
export * from './stacks/dynamicvisuals.stacks.interface';
export * from './stacks/dynamicvisuals.stacks.service';
export * from './stacks/stack/dynamicvisuals.service';
export * from './stacks/stack/dynamicvisuals.stack.service';
export * from './stacks/stack/reference/dynamicvisuals.active.reference';
export * from './stacks/stack/reference/dynamicvisuals.content.reference';
export * from './stacks/stack/reference/dynamicvisuals.reference';

@NgModule({
  imports: [PlDynamicVisualsSecondaryClickModule]
})
export class PlDynamicVisualsModule {}
