import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {TDate} from '../../../../../common/dates';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {AtivosService} from '../../services/ativos.service';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'modal-ativos-aumentoifrs16',
  templateUrl: './ativos.aumentoIFRS16.modal.component.html'
})
export class AtivosAumentoIFRS16ModalComponent extends CGModalComponent<number> implements OnInit {
  @Input() public codAtivo: number;
  @Input() public designacaoAtivo: string;

  public data: TDate;
  public valor: number;
  public designacao: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _ativosService: AtivosService
  ) {
    super(_injector);
    this.valor = 0;
    this.data = moment().startOf('month');
  }

  public ngOnInit(): void {
    const initDesc: string = this._translateService.instant('ativos.aumentoifrs16.initdescription');
    this.designacao = initDesc + this.designacaoAtivo;
  }

  public close(): Promise<void> {
    if (moment(this.data).year() < this._configService.configurations.empresa.anoEmCursoIRC) {
      this._plAlertService.error('ativos.aumentoifrs16.messages.invaliddate');
      return Promise.resolve();
    }
    if (this.valor <= 0) {
      this._plAlertService.error('ativos.aumentoifrs16.messages.invalidvalue');
      return Promise.resolve();
    }
    if (isEmpty(this.designacao)) {
      this._plAlertService.error('ativos.aumentoifrs16.messages.descempty');
      return Promise.resolve();
    }
    return this._ativosService.postAumentoIFRS16(this.codAtivo, this.data, this.valor, this.designacao).then((response: HttpResponse<number>) => {
      this._plAlertService.success('ativos.aumentoifrs16.messages.registedsuccess');
      super.close(response.body);
    });
  }
}
