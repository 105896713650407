import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {IJsonAquisicaoContabDoc} from '../../../ativosaquisicaocontab/jsonAtivosAquisicaoContab.module.interface';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonAquisicaoInvestEmCursoPorDoc} from '../../jsonAtivosAquisicaoInvestEmCursoPorDoc.module.interface';
import {IAquisicaoAdicionaDocumento, IAtivoAquisicaoPesquisaContab} from '../../../ativosaquisicaocontab/ativosAquisicaoContab.module.interface';
import {IAquisicaoInvestEmCursoPorDocItem} from '../../ativosAquisicaoInvestEmCursoPorDoc.module.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {IJsonDocContabilidade} from '../../../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import dxDataGrid from 'devextreme/ui/data_grid';
import {AtivosAquisicaoInvestEmCursoPorDocService} from '../../ativosAquisicaoInvestEmCursoPorDoc.module.service';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';

const NUMBER_3 = 3;

@Component({
  selector: 'modal-ativos-aquisicao-invest-em-curso-por-doc-contab',
  templateUrl: './ativosAquisicaoInvestEmCursoPorDoc.contab.modal.component.html'
})
export class AtivosAquisicaoInvestEmCursoPorDocContabModalComponent extends CGModalComponent<IJsonAquisicaoInvestEmCursoPorDoc> implements OnInit {
  @Input() public docsAddedList: Array<IAquisicaoInvestEmCursoPorDocItem>;
  @Input() public docsList: Array<IJsonAquisicaoContabDoc>;
  @Input() public aquisicaoContabList: Array<IAtivoAquisicaoPesquisaContab>;
  @Input() public radical: string;
  @Input() public nLancsList: Array<string>;

  public readonly dataGridDefinition: IDevExpressDataGrid<IAtivoAquisicaoPesquisaContab, string>;

  public promise: Promise<void>;
  public saldosIniciais: boolean;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private readonly _mapDocsContabilidade: Map<string, IJsonDocContabilidade>;

  private _dataGridInstance: dxDataGrid<IAtivoAquisicaoPesquisaContab, string>;
  private _hasContabilidadeDigital: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _ativosAquisicaoInvestEmCursoPorDocService: AtivosAquisicaoInvestEmCursoPorDocService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._mapDocsContabilidade = new Map<string, IJsonDocContabilidade>();
    this.dataGridDefinition = {
      keyExpr: 'nLanc',
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'ativosaquisicaocontab.table.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'ativosaquisicaocontab.table.nome', width: 200},
        {dataField: 'periodo', dataType: 'string', caption: 'ativosaquisicaocontab.table.periodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'ativosaquisicaocontab.table.nDiario'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'ativosaquisicaocontab.table.nDocInterno'},
        {dataField: 'dCDescription', dataType: 'number', caption: 'ativosaquisicaocontab.table.dCDescription'},
        {dataField: 'valor', dataType: 'double', caption: 'ativosaquisicaocontab.table.valor'},
        {dataField: 'valorCalculado', dataType: 'double', caption: 'ativosaquisicaocontab.table.valorCalculado'}
      ],
      columnHidingEnabled: false,
      export: {filename: 'ativosaquisicaocontab.modal.ativoaquisicaocontabmodaltitle'},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocContabilidade'},
      remoteOperations: false,
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbar',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.aquisicaoContabList = [];
    this.saldosIniciais = false;
    this.searchAquisicaoContab = this.searchAquisicaoContab.bind(this);
  }

  public ngOnInit(): void {
    this._hasContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
  }

  public close(): Promise<void> {
    if (this.promise) {
      return this.promise;
    }

    if (!this._validarLinhasMesmoRadical()) {
      this._plAlertService.error('ativosaquisicaocontab.error.naoMesmosRadicais');
      return Promise.resolve();
    }

    this.disableClose();
    const adicionarDocumentos: IAquisicaoAdicionaDocumento = this._parseSelectedItemsToDocumentosAdicionar();

    this.promise = this._ativosAquisicaoInvestEmCursoPorDocService
      .adicionarDocumentoInvestEmCursoPorDoc(adicionarDocumentos)
      .then((response: HttpResponse<IJsonAquisicaoInvestEmCursoPorDoc>) => {
        super.close(response.body);
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
      })
      .finally(() => {
        this.enableClose();
        this.promise = undefined;
      });
    return this.promise;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IAtivoAquisicaoPesquisaContab, string>): void {
    this._dataGridInstance = component;
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IAtivoAquisicaoPesquisaContab, string>): void {
    devExpressDataGridExpandDetailHandler(event, async () => {
      if (!event.data._docContabilidade) {
        const response: HttpResponse<IJsonDocContabilidade> = await this._serviceDocsContabilidade.get({id: event.data.extPocCabID});
        event.data._docContabilidade = response.body;
        if (this._hasContabilidadeDigital) {
          event.data._contabDigitalDoc = {
            extPocCabID: response.body.extPocCabID,
            periodo: response.body.periodo,
            nDiario: response.body.nDiario,
            nDocInterno: response.body.nDocInterno,
            dataDoc: response.body.dataDoc
          };
        }
      }

      const extPocCabID: string = event.data.extPocCabID;
      let docContabilidade: IJsonDocContabilidade = this._mapDocsContabilidade.get(extPocCabID);
      if (!docContabilidade) {
        const response: HttpResponse<IJsonDocContabilidade> = await this._serviceDocsContabilidade.get({id: extPocCabID});
        docContabilidade = response.body;
        this._mapDocsContabilidade.set(extPocCabID, docContabilidade);
      }
      if (!event.data._docContabilidade) {
        event.data._docContabilidade = docContabilidade;
        event.data._contabDigitalDoc = {
          extPocCabID: docContabilidade.extPocCabID,
          periodo: docContabilidade.periodo,
          nDiario: docContabilidade.nDiario,
          nDocInterno: docContabilidade.nDocInterno,
          dataDoc: docContabilidade.dataDoc
        };
      }
    });
  }

  public async searchAquisicaoContab(): Promise<void> {
    const list: HttpResponse<Array<IAtivoAquisicaoPesquisaContab>> = await this._ativosAquisicaoInvestEmCursoPorDocService.pesquisaAquisicoesInvestEmCurso(this.saldosIniciais);
    const selectedKeys: Array<string> = this.nLancsList.slice();
    this._mapDocsContabilidade.clear();

    if (list?.body) {
      this.aquisicaoContabList = list.body;
      this.nLancsList = selectedKeys;
    }
  }

  private _validarLinhasMesmoRadical(): boolean {
    const selectedLines: Array<IAtivoAquisicaoPesquisaContab> = this._dataGridInstance.getSelectedRowsData();
    const radical: string = Boolean(this.radical) ? this.radical : selectedLines[0].nConta.substring(0, NUMBER_3);
    for (const line of selectedLines) {
      if (!line.nConta.startsWith(radical)) {
        return false;
      }
    }
    return true;
  }

  private _parseSelectedItemsToDocumentosAdicionar(): IAquisicaoAdicionaDocumento {
    const selectedLinhas: Array<IJsonAquisicaoContabDoc> = [];
    for (const documento of this.aquisicaoContabList) {
      if (this.nLancsList.includes(documento.nLanc)) {
        selectedLinhas.push({
          extPocCabID: documento.extPocCabID,
          nDiario: documento.nDiario,
          nDocInterno: documento.nDocInterno,
          periodo: documento.periodo,
          nLanc: documento.nLanc,
          nConta: documento.nConta,
          valor: documento.valor,
          dCDescription: documento.dCDescription
        });
      }
    }
    return {
      listaContas: this.docsAddedList,
      listaDocumentos: this.docsList,
      listaDocumentosAdicionar: selectedLinhas
    };
  }
}
