import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlSplitViewComponent} from './splitview.component';
import {PlSplitViewLeftSideDirective} from './splitview.leftside.directive';
import {PlSplitViewResizerComponent} from './splitview.resizer.component';
import {PlSplitViewRightSideDirective} from './splitview.rightside.directive';

export * from './splitview.component';
export * from './splitview.component.interface';
export * from './splitview.leftside.directive';
export * from './splitview.resizer.component';
export * from './splitview.rightside.directive';

const DECLARATIONS = [
  PlSplitViewComponent,
  PlSplitViewLeftSideDirective,
  PlSplitViewResizerComponent,
  PlSplitViewRightSideDirective
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlSplitViewModule {
}
