import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {Observable} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {IFiltersImpde119} from './impDe119.module.interface';

@Injectable({
  providedIn: 'root'
})
export class Impde119Service {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/impde119`;
  }

  public getListagemIMPDE119RUrl(filters: IFiltersImpde119): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report`, {
      filtrarpesquisa: filters.filtrarpesquisa,
      dedata: filters.dedata,
      atedata: filters.atedata,
      decodemp: filters.decodemp,
      atecodemp: filters.atecodemp,
      dencontribuinte: filters.dencontribuinte,
      atencontribuinte: filters.atencontribuinte,
      listaempresas: filters.listaempresas,
      reportname: filters.reportname
    });
  }
}
