export enum ESolicTipoTexto {
  Cabecalho,
  Rodape
}

export interface IJsonSOLIC {
  tipoTexto: ESolicTipoTexto;
  nTexto: number;
  tituloTexto: string;
  nDiasAtraso: number;
  note: string;
}
