import {Component, Injector, OnInit} from '@angular/core';
import {IPlToolbarMenuItem} from 'pl-comps-angular';
import {EAtivosComponentState} from '../component/ativos.component.interface';
import {EAtivosEstado, EAtivosOrigem, IJsonAtivos} from '../../interfaces/jsonAtivos.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {AtivosAbateModalComponent} from '../../modals/ativosabate/ativosAbate.modal.component';
import {AtivosAbateService} from '../../../../modules/ativos/ativosabate/ativosAbate.module.service';
import {HttpResponse} from '@angular/common/http';
import {IJsonAtivosAbate} from '../../../../modules/ativos/ativosabate/jsonAtivosAbate.module.interface';
import {AtivosVendaModalComponent} from '../../modals/ativosvenda/ativosVenda.modal.component';
import {AtivosPesquisaContabilidadeModalComponent} from '../../modals/ativospesquisacontabilidade/ativosPesquisaContabilidade.modal.component';
import {AtivosService} from '../../services/ativos.service';
import {IJsonAtivosDepreciacoes} from '../../interfaces/jsonAtivosDepreciacoes.interface';
import {AtivosDeprecriacoesModalComponent} from '../../modals/ativosdepreciacoes/ativosDepreciacoes.modal.component';
import {IJsonAtivosDepreciacoesDetalhadas} from '../../interfaces/jsonAtivosDepreciacoesDetalhadas.interface';
import {AtivosDeprecriacoesDetalhadasModalComponent} from '../../modals/ativosdepreciacoesdetalhadas/ativosDepreciacoesDetalhadas.modal.component';
import {IJsonPlanning} from '../../../ativosaquisicao/jsonAtivosAquisicao.entity.interface';
import {AtivosPlanningModalComponent} from '../../modals/ativosplanning/ativosPlanning.modal.component';
import {IJsonAtivosHistorico} from '../../interfaces/jsonAtivosHistorico.interface';
import {AtivosHistoricoModalComponent} from '../../modals/historico/ativosHistorico.modal.component';
import {AtivosMudarContaAtivoModalComponent} from '../../modals/mudarcontaativo/ativosMudarContaAtivo.modal.component';
import {AtivosMudarContaDepModalComponent} from '../../modals/mudarcontadep/ativosMudarContaDep.modal.component';
import {AtivosMudarTaxasDepModalComponent} from '../../modals/mudartaxasdep/ativosMudarTaxasDep.modal.component';
import {AtivosNotaCreditoModalComponent} from '../../modals/ativosnotacredito/ativosNotaCredito.modal.component';
import {AtivosDivisaoModalComponent} from '../../modals/ativosdivisao/ativosDivisao.modal.component';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {AtivosAjusteDepAntigasModalComponent} from '../../modals/ajustedepantigas/ativosAjusteDepAntigas.modal.component';
import {AtivosImpressaoModalComponent} from '../../../../modules/ativos/ativosimpressao/modal/ativosImpressao.modal.component';
import {IAtivosImpressaoHeader} from '../../../../modules/ativos/ativosimpressao/ativosImpressao.module.interface';
import {maxDateCG, minDateCG} from '../../../../../common/utils/utils';
import {AtivosReavaliacoesNaoLegaisModalComponent} from '../../modals/reavaliacoesnaolegais/ativosReavaliacoesNaoLegais.modal.component';
import {AtivosReavaliacoesNaoLegaisService} from '../../modals/reavaliacoesnaolegais/ativosReavaliacoesNaoLegais.service';
import {IJsonAtivosReavaliacoesNaoLegais} from '../../modals/reavaliacoesnaolegais/jsonAtivosReavaliacoesNaoLegais.interface';
import {AtivosAumentoIFRS16ModalComponent} from '../../modals/aumentoifrs16/ativos.aumentoIFRS16.modal.component';
import {AtivosOpJustoValorABPModalComponent} from '../../modals/opjustovalorabp/ativosOpJustoValorABP.modal.component';
import {TDate} from '../../../../../common/dates';
import {EAtivosMapasOficiaisEstadoUso} from '../../../../modules/ativos/ativosmapasoficiais/ativosMapasOficiais.module.interface';
import {IApiRequestConfig} from '../../../../services/api/api.service.interface';
import {AtivosGrandeReparacaoModelComponent} from '../../modals/grandereparacao/ativos.grandeReparacao.model.component';

@Component({
  selector: 'ativos-edit',
  templateUrl: './ativos.entity.edit.component.html'
})
export class AtivosEditComponent extends ModuloEntityDetailComponent<IJsonAtivos> implements OnInit {
  public readonly ativosStateTypes: typeof EAtivosComponentState;
  public readonly: boolean;

  private _dpdownAddAcontecimentos: IPlToolbarMenuItem;
  private _dpdownAddDeprPlan: IPlToolbarMenuItem;
  private _dpdownAddRegContab: IPlToolbarMenuItem;
  private _dpdownAddConsulta: IPlToolbarMenuItem;

  private _btnAddAbate: IPlToolbarMenuItem;
  private _btnAddVenda: IPlToolbarMenuItem;
  private _btnAddPlanning: IPlToolbarMenuItem;
  private _btnAddDepreciacoes: IPlToolbarMenuItem;
  private _btnAddDepreciacoesDtl: IPlToolbarMenuItem;
  private _btnAddRegContab: IPlToolbarMenuItem;
  private _btnAddHistorico: IPlToolbarMenuItem;
  private _btnAddMudarContaAtivo: IPlToolbarMenuItem;
  private _btnAddMudarContaDepExerc: IPlToolbarMenuItem;
  private _btnAddMudarContaDepAcumu: IPlToolbarMenuItem;
  private _btnAddMudarTaxasDep: IPlToolbarMenuItem;
  private _btnAddNotaCredito: IPlToolbarMenuItem;
  private _btnAddDivisao: IPlToolbarMenuItem;
  private _btnAddAjusteDepAntigas: IPlToolbarMenuItem;
  private _btnAddImprimeAtivo: IPlToolbarMenuItem;
  private _btnAddReavaliacoesNaoLegais: IPlToolbarMenuItem;
  private _btnAddAumentoIFRS16: IPlToolbarMenuItem;
  private _btnAddOpJustoValoABP: IPlToolbarMenuItem;
  private _btnAddGrandeReparacao: IPlToolbarMenuItem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosAbateService: AtivosAbateService,
    private readonly _ativosService: AtivosService,
    private readonly _ativosReavaliacoesNaoLegaisService: AtivosReavaliacoesNaoLegaisService
  ) {
    super(_injector);
    this.ativosStateTypes = EAtivosComponentState;
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    return super.onUpdate(stateType).then(() => {
      this.readonly = stateType === EEntityStateDetailType.DETAIL;
      this._dpdownAddConsulta = {
        id: 'dropdownAddConsulta',
        order: this.btnDuplicate.order + 1,
        type: 'dropdown',
        caption: 'ativos.toolbar.dropdownConsulta',
        class: 'btn-primary',
        menu: []
      };
      this._dpdownAddAcontecimentos = {
        id: 'dropdownAcontecimentos',
        order: this._dpdownAddConsulta.order + 1,
        type: 'dropdown',
        // iconLeft: '<i class="fa fa-search fa-fw"></i>',
        caption: 'ativos.toolbar.acontecimentos',
        class: 'btn-primary',
        menu: []
      };
      this._dpdownAddDeprPlan = {
        id: 'dropdownDeprPlan',
        order: this._dpdownAddAcontecimentos.order + 1,
        type: 'dropdown',
        caption: 'ativos.toolbar.dropdownDeprPlan',
        class: 'btn-primary',
        menu: []
      };
      this._dpdownAddRegContab = {
        id: 'dropdownAddRegContab',
        order: this._dpdownAddDeprPlan.order + 1,
        type: 'dropdown',
        caption: 'ativos.toolbar.dropdownRegContab',
        class: 'btn-primary',
        menu: []
      };
      this.toolbar.addButton(this._dpdownAddAcontecimentos);
      this.toolbar.addButton(this._dpdownAddDeprPlan);
      this.toolbar.addButton(this._dpdownAddRegContab);
      this.toolbar.addButton(this._dpdownAddConsulta);

      this._btnAddAbate = {
        id: 'abate',
        caption: 'ativos.actions.abate',
        tooltip: {
          text: 'ativos.toolbar.tooltipAtivoAbateVendido',
          placement: 'bottom-left',
          container: 'body',
          disabled: this.model.estado === EAtivosEstado.Normal
        },
        disabled: this.model.estado !== EAtivosEstado.Normal,
        click: () => this._openModalAbate()
      };
      this._btnAddDivisao = {
        id: 'divisao',
        caption: 'ativos.actions.divisao',
        tooltip: {
          text: 'ativos.toolbar.tooltipAtivoEstadoNormal',
          placement: 'bottom-left',
          container: 'body',
          disabled: this.model.estado === EAtivosEstado.Normal
        },
        disabled: this.model.estado !== EAtivosEstado.Normal,
        click: () => this._openModalDivisao()
      };
      this._btnAddAumentoIFRS16 = {
        id: 'aumentoifrs16',
        caption: 'ativos.actions.aumentoifrs16',
        tooltip: {
          text: 'ativos.toolbar.tooltipAtivoEstadoNormal',
          placement: 'bottom-left',
          container: 'body',
          disabled: this.model.estado === EAtivosEstado.Normal
        },
        disabled: this.model.estado !== EAtivosEstado.Normal,
        click: () => this._openModalAumentoIFRS16()
      };
      this._btnAddOpJustoValoABP = {
        id: 'opjustovalorabp',
        caption: 'ativos.actions.opjustovalorabp',
        tooltip: {
          text: 'ativos.toolbar.tooltipOpJustoValorABP',
          placement: 'bottom-left',
          container: 'body',
          disabled: this.model.isAtivoBiologicoDeProducao && this.model.estado === EAtivosEstado.Normal && this.model.origem !== EAtivosOrigem.OperacaoJustoValor
        },
        disabled: !this.model.isAtivoBiologicoDeProducao || this.model.estado !== EAtivosEstado.Normal || this.model.origem === EAtivosOrigem.OperacaoJustoValor,
        click: () => this._openModalOpJustoValoABP()
      };
      this._btnAddGrandeReparacao = {
        id: 'grandereparacao',
        caption: 'ativos.actions.grandereparacao',
        tooltip: {
          text: 'ativos.toolbar.tooltipAtivoEstadoNormal',
          placement: 'bottom-left',
          container: 'body',
          disabled: this.model.estado === EAtivosEstado.Normal
        },
        disabled: this.model.estado !== EAtivosEstado.Normal,
        click: () => this._openModalGrandeReparacao()
      };
      this._btnAddReavaliacoesNaoLegais = {
        id: 'reavaliacoesNaoLegais',
        caption: 'ativos.actions.reavaliacoesNaoLegais',
        tooltip: {
          text: 'ativos.toolbar.tooltipAtivoEstadoNormal',
          placement: 'bottom-left',
          container: 'body',
          disabled: this.model.estado === EAtivosEstado.Normal
        },
        disabled: this.model.estado !== EAtivosEstado.Normal,
        click: () => this._openModalReavaliacoesNaoLegais()
      };
      this._btnAddAjusteDepAntigas = {
        id: 'ajustedepantigas',
        caption: 'ativos.actions.ajustedepantigas',
        tooltip: {
          text: 'ativos.toolbar.tooltipAtivoEstadoNormal',
          placement: 'bottom-left',
          container: 'body',
          disabled: this.model.estado === EAtivosEstado.Normal
        },
        disabled: this.model.estado !== EAtivosEstado.Normal,
        click: () => this._openModalAjusteDepAntigas()
      };
      this._btnAddMudarContaAtivo = {
        id: 'mudarContaAtivo',
        caption: 'ativos.actions.mudarcontaativo',
        tooltip: {
          text: 'ativos.toolbar.tooltipAtivoEstadoNormal',
          placement: 'bottom-left',
          container: 'body',
          disabled: this.model.estado === EAtivosEstado.Normal
        },
        disabled: this.model.estado !== EAtivosEstado.Normal,
        click: () => this._openModalMudarContaAtivo()
      };
      this._btnAddNotaCredito = {
        id: 'notaCredito',
        caption: 'ativos.actions.notacredito',
        tooltip: {
          text: 'ativos.toolbar.tooltipAtivoEstadoNormal',
          placement: 'bottom-left',
          container: 'body',
          disabled: this.model.estado === EAtivosEstado.Normal
        },
        disabled: this.model.estado !== EAtivosEstado.Normal,
        click: () => this._openModalNotaCredito()
      };
      this._btnAddMudarContaDepExerc = {
        id: 'mudarContaDepExerc',
        caption: 'ativos.actions.mudarcontadepexerc',
        tooltip: {
          text: 'ativos.toolbar.tooltipAtivoEstadoNormal',
          placement: 'bottom-left',
          container: 'body',
          disabled: this.model.estado === EAtivosEstado.Normal
        },
        disabled: this.model.estado !== EAtivosEstado.Normal,
        click: () => this._openModalMudarContaDep(false)
      };
      this._btnAddMudarContaDepAcumu = {
        id: 'mudarContaDepAcumu',
        caption: 'ativos.actions.mudarcontadepacumu',
        tooltip: {
          text: 'ativos.toolbar.tooltipAtivoEstadoNormal',
          placement: 'bottom-left',
          container: 'body',
          disabled: this.model.estado === EAtivosEstado.Normal
        },
        disabled: this.model.estado !== EAtivosEstado.Normal,
        click: () => this._openModalMudarContaDep(true)
      };
      this._btnAddMudarTaxasDep = {
        id: 'mudarTaxasDep',
        caption: 'ativos.actions.mudastaxasdep',
        tooltip: {
          text: 'ativos.toolbar.tooltipAtivoEstadoNormal',
          placement: 'bottom-left',
          container: 'body',
          disabled: this.model.estado === EAtivosEstado.Normal
        },
        disabled: this.model.estado !== EAtivosEstado.Normal,
        click: () => this._openModalMudarTaxasDep()
      };
      this._btnAddVenda = {
        id: 'venda',
        caption: 'ativos.actions.venda',
        tooltip: {
          text: 'ativos.toolbar.tooltipAtivoAbateVendido',
          placement: 'bottom-left',
          container: 'body',
          disabled: this.model.estado === EAtivosEstado.Normal
        },
        disabled: this.model.estado !== EAtivosEstado.Normal,
        click: () => this._openModalVenda()
      };
      this._btnAddPlanning = {
        id: 'planning',
        caption: 'ativos.actions.planning',
        click: () => this._openModalPlanning()
      };
      this._btnAddDepreciacoes = {
        id: 'depreciacoes',
        caption: 'ativos.actions.depreciacoes',
        click: () => this._openModalDepreciacoes()
      };
      this._btnAddHistorico = {
        id: 'historico',
        caption: 'ativoshistorico.title.historico',
        click: () => this._openModalHistorico()
      };
      this._btnAddDepreciacoesDtl = {
        id: 'depreciacoesDtl',
        caption: 'ativos.actions.depreciacoesDtl',
        click: () => this._openModalDepreciacoesDetalhadas()
      };
      this._btnAddRegContab = {
        id: 'depreciacoesDtl',
        caption: 'ativos.actions.regcontab',
        click: () => this._openModalPesquisaContabilidade()
      };
      this._btnAddImprimeAtivo = {
        id: 'imprimeativo',
        caption: 'ativos.actions.imprimeativo',
        click: () => this._openModalImprimeAtivo()
      };

      this._setDropdownownAcontecimentos();
      this._setDropdownDeprPlan();
      this._setDropdownRegContab();
      this._setDropdownConsulta();
    });
  }

  public beforeDelete(): Promise<void | IApiRequestConfig> {
    if (this.model.origem === EAtivosOrigem.Contabilidade) {
      return this._ativosService.checkHaveMoreAtivosFromContab(this.model.codAtivo).then((response: HttpResponse<boolean>) => {
        if (response.body) {
          return this._cgModalService.showOkCancel('global.text.warning', 'ativos.messages.existemmaisativoscontab');
        }
        return super.beforeDelete();
      });
    }

    if (this.model.origem === EAtivosOrigem.DivisaoAtivo) {
      return this._cgModalService.showOkCancel('global.text.warning', 'ativos.messages.existemmaisativosdivididos');
    }

    return super.beforeDelete();
  }

  private _setDropdownownAcontecimentos(): void {
    this._dpdownAddAcontecimentos.menu = [];
    this._dpdownAddAcontecimentos.menu.push(this._btnAddAbate);
    this._dpdownAddAcontecimentos.menu.push(this._btnAddVenda);
    this._dpdownAddAcontecimentos.menu.push(this._btnAddMudarContaAtivo);
    this._dpdownAddAcontecimentos.menu.push(this._btnAddMudarContaDepExerc);
    this._dpdownAddAcontecimentos.menu.push(this._btnAddMudarContaDepAcumu);
    this._dpdownAddAcontecimentos.menu.push(this._btnAddMudarTaxasDep);
    this._dpdownAddAcontecimentos.menu.push(this._btnAddNotaCredito);
    this._dpdownAddAcontecimentos.menu.push(this._btnAddDivisao);
    this._dpdownAddAcontecimentos.menu.push(this._btnAddReavaliacoesNaoLegais);
    this._dpdownAddAcontecimentos.menu.push(this._btnAddAjusteDepAntigas);
    this._dpdownAddAcontecimentos.menu.push(this._btnAddAumentoIFRS16);
    this._dpdownAddAcontecimentos.menu.push(this._btnAddOpJustoValoABP);
    this._dpdownAddAcontecimentos.menu.push(this._btnAddGrandeReparacao);
  }

  private _setDropdownDeprPlan(): void {
    this._dpdownAddDeprPlan.menu = [];
    this._dpdownAddDeprPlan.menu.push(this._btnAddPlanning);
    this._dpdownAddDeprPlan.menu.push(this._btnAddDepreciacoes);
    this._dpdownAddDeprPlan.menu.push(this._btnAddDepreciacoesDtl);
  }

  private _setDropdownRegContab(): void {
    this._dpdownAddRegContab.menu = [];
    this._dpdownAddRegContab.menu.push(this._btnAddRegContab);
  }

  private _setDropdownConsulta(): void {
    this._dpdownAddConsulta.menu = [];
    this._dpdownAddConsulta.menu.push(this._btnAddHistorico);
    this._dpdownAddConsulta.menu.push(this._btnAddImprimeAtivo);
  }

  private _openModalMudarContaAtivo(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AtivosMudarContaAtivoModalComponent);
    const componentInstance: AtivosMudarContaAtivoModalComponent = modalInstance.componentInstance;
    componentInstance.ativo = this.model;
    return modalInstance.result.then((result: IJsonAtivos) => {
      this.model = result;
      this._validateBtnAcontecimentos();
    });
  }

  private _openModalDivisao(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AtivosDivisaoModalComponent);
    const componentInstance: AtivosDivisaoModalComponent = modalInstance.componentInstance;
    componentInstance.ativo = this.model;
    return modalInstance.result.then((result: IJsonAtivos) => {
      this.model = result;
      this._validateBtnAcontecimentos();
    });
  }

  private _openModalAumentoIFRS16(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AtivosAumentoIFRS16ModalComponent);
    const componentInstance: AtivosAumentoIFRS16ModalComponent = modalInstance.componentInstance;
    componentInstance.codAtivo = this.model.codAtivo;
    componentInstance.designacaoAtivo = this.model.designacao;
    return modalInstance.result.then((result: number) => {
      this._stateService.go(this.states.detail.name, {id: result});
    });
  }

  private _openModalOpJustoValoABP(): Promise<void> {
    return this._ativosService.getDateDefaultOpJustoValorABP().then((response: HttpResponse<TDate>) => {
      const modalInstance = this._cgModalService.showVanilla(AtivosOpJustoValorABPModalComponent);
      const componentInstance: AtivosOpJustoValorABPModalComponent = modalInstance.componentInstance;
      componentInstance.ativo = this.model;
      componentInstance.dataOperacao = response.body;
      return modalInstance.result.then((result: IJsonAtivos) => {
        this.model = result;
        this._validateBtnAcontecimentos();
      });
    });
  }

  private _openModalGrandeReparacao(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AtivosGrandeReparacaoModelComponent);
    const componentInstance: AtivosGrandeReparacaoModelComponent = modalInstance.componentInstance;
    componentInstance.codAtivo = this.model.codAtivo;
    componentInstance.codAtivoPai = this.model.codAtivoPai;
    componentInstance.nomeAtivoPai = this.model.nomeAtivoPai;
    return modalInstance.result.then((result: IJsonAtivos) => {
      this.model = result;
      this._validateBtnAcontecimentos();
    });
  }

  private _openModalReavaliacoesNaoLegais(): Promise<void> {
    return this._ativosReavaliacoesNaoLegaisService.getReavaliacaoNaoLegal(this.model.codAtivo).then((response: HttpResponse<IJsonAtivosReavaliacoesNaoLegais>) => {
      const modalInstance = this._cgModalService.showVanilla(AtivosReavaliacoesNaoLegaisModalComponent);
      const componentInstance: AtivosReavaliacoesNaoLegaisModalComponent = modalInstance.componentInstance;
      componentInstance.codAtivo = this.model.codAtivo;
      componentInstance.valorLiquido = this.model.valorLiquidoContab;
      componentInstance.ativosReavaliacoesNaoLegais = response.body;
    });
  }

  private _openModalAjusteDepAntigas(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AtivosAjusteDepAntigasModalComponent);
    const componentInstance: AtivosAjusteDepAntigasModalComponent = modalInstance.componentInstance;
    componentInstance.ativo = this.model;
    return modalInstance.result.then(() => {
      this._validateBtnAcontecimentos();
    });
  }

  private _openModalNotaCredito(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AtivosNotaCreditoModalComponent);
    const componentInstance: AtivosNotaCreditoModalComponent = modalInstance.componentInstance;
    componentInstance.ativo = this.model;
    return modalInstance.result.then((result: IJsonAtivos) => {
      this.model = result;
      this._validateBtnAcontecimentos();
    });
  }

  private _openModalMudarContaDep(acumulado: boolean): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AtivosMudarContaDepModalComponent, {size: 'md'});
    const componentInstance: AtivosMudarContaDepModalComponent = modalInstance.componentInstance;
    componentInstance.ativo = this.model;
    componentInstance.acumulada = acumulado;
    return modalInstance.result.then((result: IJsonAtivos) => {
      this.model = result;
      this._validateBtnAcontecimentos();
    });
  }

  private _openModalMudarTaxasDep(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AtivosMudarTaxasDepModalComponent);
    const componentInstance: AtivosMudarTaxasDepModalComponent = modalInstance.componentInstance;
    componentInstance.ativo = this.model;
    return modalInstance.result.then((result: IJsonAtivos) => {
      this.model = result;
      this._validateBtnAcontecimentos();
    });
  }

  private _openModalAbate(): Promise<void> {
    return this._ativosAbateService.getRadicalAbate().then((response: HttpResponse<IJsonAtivosAbate>) => {
      const abate: IJsonAtivosAbate = response.body;
      const modalInstance = this._cgModalService.showVanilla(AtivosAbateModalComponent);
      const componentInstance: AtivosAbateModalComponent = modalInstance.componentInstance;
      componentInstance.ativo = this.model;
      componentInstance.abate = abate;
      return modalInstance.result.then((result: IJsonAtivos) => {
        this.model = result;
        this._validateBtnAcontecimentos();
      });
    });
  }

  private _openModalPlanning(): Promise<void> {
    return this._ativosService.commandPlanning(this.model).then((response: IJsonAtivos) => {
      const planning: Array<IJsonPlanning> = response.aquisicao.planningList;
      const modalInstance = this._cgModalService.showVanilla(AtivosPlanningModalComponent);
      const componentInstance: AtivosPlanningModalComponent = modalInstance.componentInstance;
      componentInstance.ativosPlanning = planning;
    });
  }

  private _openModalDepreciacoes(): Promise<void> {
    return this._ativosService.getDepreciacoes(this.model.codAtivo).then((response: HttpResponse<Array<IJsonAtivosDepreciacoes>>) => {
      const depreciacoes: Array<IJsonAtivosDepreciacoes> = response.body;
      const modalInstance = this._cgModalService.showVanilla(AtivosDeprecriacoesModalComponent);
      const componentInstance: AtivosDeprecriacoesModalComponent = modalInstance.componentInstance;
      componentInstance.ativoDepreciacao = depreciacoes;
    });
  }

  private _openModalHistorico(): Promise<void> {
    return this._ativosService.getHistorico(this.model.codAtivo).then((response: HttpResponse<Array<IJsonAtivosHistorico>>) => {
      const historico: Array<IJsonAtivosHistorico> = response.body;
      const modalInstance = this._cgModalService.showVanilla(AtivosHistoricoModalComponent);
      const componentInstance: AtivosHistoricoModalComponent = modalInstance.componentInstance;
      componentInstance.ativoHistorico = historico;
    });
  }

  private _openModalImprimeAtivo(): Promise<void> {
    const modelHeader: IAtivosImpressaoHeader = {
      codAmortAte: this.model.aquisicao.codAmortizacao,
      codAmortDe: this.model.aquisicao.codAmortizacao,
      codAtivoAte: this.model.codAtivo,
      codAtivoDe: this.model.codAtivo,
      codMapaAte: this.model.aquisicao.nMapaAmortizacao,
      codMapaDe: this.model.aquisicao.nMapaAmortizacao,
      dataAbateAte: maxDateCG(),
      dataAbateDe: minDateCG(),
      dataAquisicaoAte: this.model.aquisicao.dataAquisicao,
      dataAquisicaoDe: this.model.aquisicao.dataAquisicao,
      nCCustoAte: this.model.aquisicao.nCCusto,
      nCCustoDe: this.model.aquisicao.nCCusto,
      nContaAte: this.model.aquisicao.nContaDoAtivo,
      nContaDe: this.model.aquisicao.nContaDoAtivo,
      abatido: this.model.estado === EAtivosEstado.Abatido,
      normal: this.model.estado === EAtivosEstado.Normal,
      vendido: this.model.estado === EAtivosEstado.Vendido,
      dividido: this.model.estado === EAtivosEstado.Divido,
      reportName: 'Ativos01.FR3',
      tipoExportacao: 0,
      dataReferencia: maxDateCG(),
      estadoUso: this.model.aquisicao.usado ? EAtivosMapasOficiaisEstadoUso.Usado : EAtivosMapasOficiaisEstadoUso.Novo
    };
    const modalInstance = this._cgModalService.showVanilla(AtivosImpressaoModalComponent);
    const componentInstance: AtivosImpressaoModalComponent = modalInstance.componentInstance;
    componentInstance.modelHeader = modelHeader;
    return modalInstance.result;
  }

  private _openModalDepreciacoesDetalhadas(): Promise<void> {
    return this._ativosService.getDepreciacoesDetalhadas(this.model.codAtivo).then((response: HttpResponse<Array<IJsonAtivosDepreciacoesDetalhadas>>) => {
      const depreciacoesdetalhadas: Array<IJsonAtivosDepreciacoesDetalhadas> = response.body;
      const modalInstance = this._cgModalService.showVanilla(AtivosDeprecriacoesDetalhadasModalComponent);
      const componentInstance: AtivosDeprecriacoesDetalhadasModalComponent = modalInstance.componentInstance;
      componentInstance.ativoDepreciacaoDetalhadas = depreciacoesdetalhadas;
    });
  }

  private _openModalVenda(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AtivosVendaModalComponent);
    const componentInstance: AtivosVendaModalComponent = modalInstance.componentInstance;
    componentInstance.ativo = this.model;
    return modalInstance.result.then((result: IJsonAtivos) => {
      this.model = result;
      this._validateBtnAcontecimentos();
    });
  }

  private _openModalPesquisaContabilidade(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AtivosPesquisaContabilidadeModalComponent);
    const componentInstance: AtivosPesquisaContabilidadeModalComponent = modalInstance.componentInstance;
    componentInstance.ativo = this.model;
    return modalInstance.result.then(() => {
      return this._ativosService.getAtivo(this.model.codAtivo).then((response: HttpResponse<IJsonAtivos>) => {
        this.model = response.body;
        this._validateBtnAcontecimentos();
      });
    });
  }

  private _validateBtnAcontecimentos(): void {
    this._btnAddVenda.disabled = this.model.estado !== EAtivosEstado.Normal;
    this._btnAddVenda.tooltip.disabled = this.model.estado === EAtivosEstado.Normal;
    this._btnAddAbate.disabled = this.model.estado !== EAtivosEstado.Normal;
    this._btnAddAbate.tooltip.disabled = this.model.estado === EAtivosEstado.Normal;
    this._btnAddMudarContaAtivo.disabled = this.model.estado !== EAtivosEstado.Normal;
    this._btnAddMudarContaAtivo.tooltip.disabled = this.model.estado === EAtivosEstado.Normal;
    this._btnAddMudarContaDepExerc.disabled = this.model.estado !== EAtivosEstado.Normal;
    this._btnAddMudarContaDepExerc.tooltip.disabled = this.model.estado === EAtivosEstado.Normal;
    this._btnAddMudarContaDepAcumu.disabled = this.model.estado !== EAtivosEstado.Normal;
    this._btnAddMudarContaDepAcumu.tooltip.disabled = this.model.estado === EAtivosEstado.Normal;
    this._btnAddMudarTaxasDep.disabled = this.model.estado !== EAtivosEstado.Normal;
    this._btnAddMudarTaxasDep.tooltip.disabled = this.model.estado === EAtivosEstado.Normal;
    this._btnAddNotaCredito.disabled = this.model.estado !== EAtivosEstado.Normal;
    this._btnAddNotaCredito.tooltip.disabled = this.model.estado === EAtivosEstado.Normal;
    this._btnAddDivisao.disabled = this.model.estado !== EAtivosEstado.Normal;
    this._btnAddDivisao.tooltip.disabled = this.model.estado === EAtivosEstado.Normal;
    this._btnAddAumentoIFRS16.disabled = this.model.estado !== EAtivosEstado.Normal;
    this._btnAddAumentoIFRS16.tooltip.disabled = this.model.estado === EAtivosEstado.Normal;
    this._btnAddOpJustoValoABP.disabled = !this.model.isAtivoBiologicoDeProducao || this.model.estado !== EAtivosEstado.Normal || this.model.origem === EAtivosOrigem.OperacaoJustoValor;
    this._btnAddOpJustoValoABP.tooltip.disabled = this.model.isAtivoBiologicoDeProducao && this.model.estado === EAtivosEstado.Normal && this.model.origem !== EAtivosOrigem.OperacaoJustoValor;
    this._btnAddReavaliacoesNaoLegais.disabled = this.model.estado !== EAtivosEstado.Normal;
    this._btnAddReavaliacoesNaoLegais.tooltip.disabled = this.model.estado === EAtivosEstado.Normal;
    this._btnAddGrandeReparacao.disabled = this.model.estado !== EAtivosEstado.Normal;
    this._btnAddGrandeReparacao.tooltip.disabled = this.model.estado === EAtivosEstado.Normal;
  }
}
