import {BehaviorSubject, EMPTY, from, isObservable, Observable, Subscription, throwError} from 'rxjs';
import {catchError, mergeMap, takeWhile} from 'rxjs/operators';
import {isBoolean} from '../../utilities/utilities';
import {ILogger} from '../../../logger/logger.interface';

export class LoadingQueue {
  private readonly _subjectLoading: BehaviorSubject<boolean>;
  private readonly _loadingQueue: Array<Observable<unknown>>;
  private _observableLoading: Observable<boolean>;
  private _subscriptionLoadingQueue: Subscription;
  private _promiseLoading: Promise<void>;
  private _promiseLoadingResolveFn: () => void;
  private _promiseLoadingRejectFn: () => void;
  private _disposed: boolean;

  constructor(private readonly _logger: ILogger) {
    this._subjectLoading = new BehaviorSubject<boolean>(false);
    this._loadingQueue = [];
    this._disposed = false;
  }

  public setLoading(value: boolean | Promise<unknown> | Observable<unknown>, clearLoadingQueue: boolean = false): void {
    if (this._disposed) {
      return;
    }
    const bool: boolean = isBoolean(value);
    if (bool || clearLoadingQueue) {
      this._clearSubscriptionLoadingQueue();
      this._loadingQueue.length = 0;
      if (bool) {
        this._subjectLoading.next(<boolean>value);
        return;
      }
    }
    if (!isObservable(value)) {
      value = from(<Promise<unknown>>value);
    }
    this._loadingQueue.push(value);
    if (!this._subscriptionLoadingQueue || this._subscriptionLoadingQueue.closed) {
      this._promiseLoading = new Promise<void>((resolve, reject) => {
        this._promiseLoadingResolveFn = resolve;
        this._promiseLoadingRejectFn = reject;
      });
      this._subjectLoading.next(true);
      this._subscriptionLoadingQueue = this._flushLoadingQueue().subscribe({
        complete: () => {
          if (!this._subjectLoading.closed) {
            this._subjectLoading.next(false);
          }
          this._subscriptionLoadingQueue = undefined;
          this._promiseLoadingResolveFn();
          this._promiseLoadingRejectFn = undefined;
        }
      });
    }
  }

  public loading(): Observable<boolean> {
    if (this._disposed) {
      return throwError(() => new Error('Loading queue has already been cleaned up.'));
    }
    if (!this._observableLoading) {
      this._observableLoading = this._subjectLoading.asObservable();
    }
    return this._observableLoading;
  }

  public loadingState(): boolean {
    return this._subjectLoading.value;
  }

  public loadingPromise(): Promise<void> {
    return this._promiseLoading && !this._disposed ? this._promiseLoading : Promise.resolve();
  }

  public loadingCount(): number {
    if (this._disposed) {
      return 0;
    }
    return this._loadingQueue.length;
  }

  public dispose(): void {
    if (this._disposed) {
      return;
    }
    this._disposed = true;
    this._subjectLoading.complete();
    this._clearSubscriptionLoadingQueue();
    if (this._promiseLoadingRejectFn) {
      this._promiseLoadingRejectFn();
    }
  }

  private _flushLoadingQueue(): Observable<unknown> {
    const observable: Observable<unknown> = this._loadingQueue.shift();
    if (!observable) {
      return EMPTY;
    }
    return observable
      .pipe(takeWhile(() => !this._disposed))
      .pipe(
        mergeMap(() => {
          return this._flushLoadingQueue();
        })
      )
      .pipe(
        catchError((reason: unknown) => {
          this._logger.error(reason);
          return this._flushLoadingQueue();
        })
      );
  }

  private _clearSubscriptionLoadingQueue(): void {
    if (this._subscriptionLoadingQueue) {
      this._subscriptionLoadingQueue.unsubscribe();
      this._subscriptionLoadingQueue = undefined;
    }
  }
}
