<div class="modal-header">
  <h5 class="modal-title" [translate]="'rhregistarempregadosss.editarContratoModalTitle'"></h5>
</div>

<div class="modal-body">
  <div class="text-center rhregistarempregadosss-editar-contrato-emp-info mb-3" [translate]="empregadoInfo"></div>

  <div class="d-flex" style="gap: 10px">
    <div class="rhregistarempregadosss-filter-group mb-2">
      <label><span [translate]="'rhregistarempregadosss.strings.niss'"></span>:</label>
      <pl-group>
        <edit>
          <pl-edit type="text" [(model)]="model.nissTrabalhador"></pl-edit>
        </edit>
      </pl-group>
    </div>

    <div class="rhregistarempregadosss-filter-group mb-2">
      <label><span [translate]="'rhregistarempregadosss.strings.prestacaoTrabalho'"></span>:</label>
      <pl-group>
        <edit>
          <pl-edit type="select" [attrName]="'motivoCtrSS'" [(model)]="model.codprestacaoTrabalhoEnum" [properties]="{select: {list: prestacaoTrabalhoList}, disallowClear: true}"></pl-edit>
        </edit>
      </pl-group>
    </div>
  </div>

  <div class="d-flex" style="gap: 10px">
    <div class="rhregistarempregadosss-filter-group mb-2">
      <label><span [translate]="'rhregistarempregadosss.strings.assinadoEm'"></span>:</label>
      <pl-group style="max-width: 150px">
        <edit>
          <pl-edit-datepicker [(model)]="model.inicioContrato"></pl-edit-datepicker>
        </edit>
      </pl-group>
    </div>

    <div class="rhregistarempregadosss-filter-group mb-2">
      <label><span [translate]="'rhregistarempregadosss.strings.validaAte'"></span>:</label>
      <pl-group style="max-width: 150px">
        <edit>
          <pl-edit-datepicker [(model)]="model.fimContrato"></pl-edit-datepicker>
        </edit>
      </pl-group>
    </div>
  </div>

  <div class="d-flex" style="gap: 10px">
    <div class="rhregistarempregadosss-filter-group mb-2">
      <label><span [translate]="'rhregistarempregadosss.strings.vencimentoBase'"></span>:</label>
      <pl-group style="max-width: 150px">
        <edit>
          <pl-edit type="currency" [(model)]="model.remuneracaoBase"></pl-edit>
        </edit>
      </pl-group>
    </div>

    <div class="rhregistarempregadosss-filter-group mb-2">
      <label><span [translate]="'rhregistarempregadosss.strings.diuturnidades'"></span>:</label>
      <pl-group style="max-width: 150px">
        <edit>
          <pl-edit type="currency" [(model)]="model.diuturnidades"></pl-edit>
        </edit>
      </pl-group>
    </div>
  </div>

  <div class="rhregistarempregadosss-filter-group mb-2">
    <label><span [translate]="'rhregistarempregadosss.strings.modalidade'"></span>:</label>
    <pl-group>
      <edit>
        <pl-edit
          type="select"
          [attrName]="'modalidade'"
          [model]="model.codmodalidadeContratoRnum"
          (modelChange)="modalidadeCtrSSChanged($event)"
          [properties]="{select: {list: modalidadeList}, disallowClear: true}"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="rhregistarempregadosss-filter-group mb-2">
    <label><span [translate]="'rhregistarempregadosss.strings.motivo'"></span>:</label>
    <pl-group>
      <edit>
        <pl-edit
          type="select"
          [attrName]="'motivoCtrSS'"
          [model]="model.codmotivoContratoEnum"
          (modelChange)="motivoCtrSSChanged($event)"
          [properties]="{select: {list: motivoCtrList}, disallowClear: true, disabled: !motivoCtrSSEnabled}"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <h4 class="rhregistarempregadosss-title mt-4" [translate]="'rhregistarempregadosss.strings.tempoParcial'"></h4>

  <div class="d-flex" style="gap: 10px">
    <div class="rhregistarempregadosss-filter-group mb-2">
      <label><span [translate]="'rhregistarempregadosss.strings.percentagemTrabalho'"></span>:</label>
      <pl-group style="max-width: 250px">
        <edit>
          <pl-edit type="currency" [(model)]="model.percentagemTrabalho" [properties]="{disabled: !percTrabalhoCtrSSEnabled}"></pl-edit>
        </edit>
      </pl-group>
    </div>

    <div class="rhregistarempregadosss-filter-group mb-2">
      <label><span [translate]="'rhregistarempregadosss.strings.horasTrabalho'"></span>:</label>
      <pl-group style="min-width: 250px; max-width: 350px">
        <edit>
          <pl-edit type="currency" [(model)]="model.horasTrabalho" [properties]="{disabled: !horasTrabalhoCtrSSEnabled}"></pl-edit>
        </edit>
      </pl-group>
    </div>
  </div>

  <div class="d-flex" style="gap: 10px">
    <div class="rhregistarempregadosss-filter-group mb-2">
      <label><span [translate]="'rhregistarempregadosss.strings.diasTrabalho'"></span>:</label>
      <pl-group style="max-width: 250px">
        <edit>
          <pl-edit type="currency" [(model)]="model.diasTrabalho" [properties]="{disabled: !diasTrabalhoCtrSSEnabled}"></pl-edit>
        </edit>
      </pl-group>
    </div>

    <div class="rhregistarempregadosss-filter-group mb-2">
      <label><span [translate]="'rhregistarempregadosss.strings.codEmpSubstituir'"></span>:</label>
      <pl-group>
        <edit>
          <entity-autocomplete
            entity="dgemps"
            [model]="empregadoSubstituir"
            (modelChange)="onEmpregadoSubstituirChanged($event)"
            [properties]="{disabled: !empregadoSubsttituitSSctrEnabled}"
            [filter]="registarCodEmpFilter"></entity-autocomplete>
        </edit>
      </pl-group>
    </div>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></button>

  <button type="button" class="btn btn-sm btn-success" (click)="applyModel()"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'rhregistarempregadosss.configWS.saveButton'"></span></button>
</div>
