import {IArtigo} from '../../../artigos/artigos.entity.interface';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {deepFreeze} from 'pl-comps-angular';

export type TChangeArtigoField = 'artBloqueado' | 'nFamilia' | 'nGrFamilia' | 'nTpArt' | 'nSubFa' | 'unidadeMov' | 'movimStock' | 'naoPermiteExistNeg' | 'temLote' | 'codIvaVenda' | 'codIvaCompra';

export enum EChangeArtigoField {
  BLOQUEADO,
  NFAMILIA,
  NGRFAMILIA,
  NTPART,
  NSUBFA,
  UNIDADEMOV,
  MOVIMSTOCK,
  NAOPERMITEEXISTNEG,
  TEMLOTE,
  CODIVAVENDA,
  CODIVACOMPRA
}

export enum EChangeArtigosFieldsInSerieWizardSteps {
  PESQUISA = 'pesquisaStep',
  ATUALIZACAO = 'selecaoStep',
  RELATORIO = 'relatorioStep'
}

export enum EChangeArtigosFieldsInSerieCalculoPreco {
  PRECO_COM_IVA,
  PRECO_SEM_IVA
}

export const RADIO_GROUP_CALCULO_PRECO: IRadioGroup<EChangeArtigosFieldsInSerieCalculoPreco> = deepFreeze<IRadioGroup<EChangeArtigosFieldsInSerieCalculoPreco>>({
  groupItems: [
    {value: EChangeArtigosFieldsInSerieCalculoPreco.PRECO_COM_IVA, label: 'changeArtigosFieldsInSerie.strings.precoComIva'},
    {value: EChangeArtigosFieldsInSerieCalculoPreco.PRECO_SEM_IVA, label: 'changeArtigosFieldsInSerie.strings.precoSemIva'}
  ]
});

export interface IChangeArtigo extends IArtigo {
  nFamiliaString: string;
  nGrFamiliaString: string;
  nTpArtString: string;
  nSubFaString: string;
  unidadeMovString: string;
  codIvaVendaString: string;
  codIvaCompraString: string;
}

export type TChangeArtigoFieldsUI = {
  [key in TChangeArtigoField]: {
    checked: boolean;
    value: unknown;
    entityName: string;
    entityModel: unknown;
    entityModelKey: string;
    uiLabel: string;
    uiType: 'entity' | 'booleanSelect';
  };
};

export interface IChangeArtigoFieldSaveDataItem {
  artigoField: EChangeArtigoField;
  value: string | number;
  extraData?: string;
}

export interface IChangeArtigoFieldSaveData {
  artigos: Array<string>;
  fields: Array<IChangeArtigoFieldSaveDataItem>;
}

export interface IChangeArtigoFieldResultChange {
  field: EChangeArtigoField;
  oldValue: string | number;
  oldValueDesc: string;
  newValue: string | number;
  newValueDesc: string;
}

export interface IChangeArtigoFieldResult {
  nArtigo: string;
  changes: Array<IChangeArtigoFieldResultChange>;
}

export interface IChangeArtigoResumo extends IArtigo {
  artBloqueadoOldValue?: boolean;
  artBloqueadoNewValue?: boolean;
  nFamiliaOldString?: string;
  nFamiliaNewString?: string;
  nGrFamiliaOldString?: string;
  nGrFamiliaNewString?: string;
  nTpArtOldString?: string;
  nTpArtNewString?: string;
  nSubFaOldString?: string;
  nSubFaNewString?: string;
  unidadeMovOldString?: string;
  unidadeMovNewString?: string;
  codIvaVendaOldString?: string;
  codIvaVendaNewString?: string;
  codIvaCompraOldString?: string;
  codIvaCompraNewString?: string;
  movimStockOldValue?: boolean;
  movimStockNewValue?: boolean;
  naoPermiteExistNegOldValue?: boolean;
  naoPermiteExistNegNewValue?: boolean;
  temLoteOldValue?: boolean;
  temLoteNewValue?: boolean;
}
