<div class="gestao-dgemps" [plPromise]="promise">
  <gestao-dgemps-header
    [view]="view"
    [viewDate]="viewDate"
    [servicos]="servicos"
    [(servico)]="servico"
    [showView]="manager"
    [showPending]="view === views.Month"
    [disabledPendingPrevious]="disabledPendingPrevious"
    [disabledPendingNext]="disabledPendingNext"
    [loading]="loading"
    (viewChange)="setView($event)"
    (viewDateChange)="setViewDate($event)"
    (evtPendingPrevious)="onPendingPrevious()"
    (evtPendingNext)="onPendingNext()">
  </gestao-dgemps-header>

  <gestao-dgemps-year-view
    *ngIf="view === views.Year"
    [type]="type"
    [manager]="manager"
    [configStructure]="configStructure"
    [configEvents]="configEvents"
    [fluxos]="fluxos"
    [empregado]="empregado"
    [holidaysDates]="holidaysDates"
    [servico]="servico"
    [viewDate]="viewDate"
    [forceDelete]="forceDelete"
    [loading]="viewLoading"
    [colaboradoresPendentes]="selectedColaboradoresKeys"
    (viewDateChange)="setViewDate($event)"
    (loadingChange)="onLoadingChange($event)"
    (selectedColaboradoresKeysChange)="selectedColaboradoresKeys = $event">
  </gestao-dgemps-year-view>

  <gestao-dgemps-month-view
    *ngIf="view === views.Month"
    [type]="type"
    [manager]="manager"
    [configStructure]="configStructure"
    [configEvents]="configEvents"
    [fluxos]="fluxos"
    [empregado]="empregado"
    [holidaysDates]="holidaysDates"
    [servico]="servico"
    [viewDate]="viewDate"
    [forceDelete]="forceDelete"
    [loading]="viewLoading"
    (viewDateChange)="setViewDate($event)"
    (loadingChange)="onLoadingChange($event)"
    (evtChangedPendingPrevious)="pendingPrevious = $event"
    (evtChangedPendingNext)="pendingNext = $event"
    (evtChangedDisabledPendingPrevious)="disabledPendingPrevious = $event"
    (evtChangedDisabledPendingNext)="disabledPendingNext = $event">
  </gestao-dgemps-month-view>
</div>
