<div class="docscomerciais-list-module">
  <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="configOptionsToolbarInstanceId"></config-options>

  <pl-tabs [activeId]="activeTabId" (activeIdChange)="changedTab($event)">
    <pl-tab [id]="tabs.Documentos">
      <div *plTabTitle>
        <i class="fa fa-file-text-o fa-flip-horizontal fa-fw"></i>&nbsp;
        <span *ngIf="tabEditaveisVisible" class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.finalizados'"></span>
        <span *ngIf="!tabEditaveisVisible" class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.documentos'"></span>
      </div>
      <div *plTabContent>
        <entity-list
          [entityName]="entityName"
          [maintenanceMode]="maintenanceMode"
          [beforeRequest]="fnBeforeRequest"
          [searchValue]="searchValue"
          [filter]="filterValue"
          [initialFilter]="initialFilterValue"
          [serviceMethodsOverride]="serviceMethodsOverride"
          [toolbarInstanceId]="toolbarInstanceIdDocumentos"
          [properties]="listOptions"
          (evtOnRefresh)="onRefreshList()"
          (evtDataGridPreparing)="onDataGridPreparing($event)"
          (evtDataGridCellClick)="onDataGridCellClick($event)"
          (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
          (evtDataGridCellPrepared)="onDataGridCellPrepared($event)"
          (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
          (evtDataGridStateChanged)="onDataGridStateChanged($event)"
          (evtDataGridStateCleared)="onDataGridStateCleared()"
          (evtDataGridRowPrepared)="onDataGridRowPrepared($event)"
          #entityListDocumentos>
          <div *entityListItemActions="let item">
            <div ngbDropdown container="body" class="col-main-table-actions">
              <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-cog"></i></button>
              <div ngbDropdownMenu>
                <button type="button" ngbDropdownItem [click]="fnGetDocPdf(item.data)" plPromise>
                  <i class="fa fa-file-pdf-o fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.actions.pdf'"></span>
                </button>

                <button *ngIf="showGDocs" type="button" ngbDropdownItem [click]="fnAnexarDocumentoActions(item.data)" plPromise>
                  <i class="fa fa-fw fa-paperclip"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.anexos'"></span>
                </button>

                <button
                  *ngIf="hasComercialAdvanced && !item.data.transformado && !item.data.anulado && item.data.nDocumento > 0"
                  type="button"
                  ngbDropdownItem
                  [click]="fnTransformarDocumentoActions(item.data)"
                  plPromise>
                  <i class="fa fa-fw fa-stack-overflow"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.transformarDoc'"></span>
                </button>

                <button *ngIf="item.data.nDocumento === 0 || !item.data.terminado" type="button" ngbDropdownItem [click]="fnEditarDocumentoActions(item.data)" plPromise>
                  <i class="fa fa-fw fa-pencil-square-o"></i>&nbsp;<span [translate]="'docscomerciais.actions.editarDocumento'"></span>
                </button>

                <button *ngIf="hasComercial && !item.data.anulado && item.data.terminado" type="button" ngbDropdownItem [click]="fnReAbreDocumento(item.data)" plPromise>
                  <i class="fa fa-fw fa-pencil-square-o"></i>&nbsp;<span [translate]="'docscomerciais.actions.editarDocumento'"></span>
                </button>

                <button
                  *ngIf="item.data.faturaWebServSujAComunicacao && item.data.atEstado !== 2 && item.data.atEstado !== 4"
                  type="button"
                  ngbDropdownItem
                  [click]="fnComunicarDocWebServiceATCab(item.data)"
                  plPromise>
                  <i class="fa fa-send fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.actions.comunicarDocAt'"></span>
                </button>

                <button *ngIf="!item.data.anulado" type="button" ngbDropdownItem [click]="fnEmitirRecibo(item.data)" plPromise>
                  <i class="fa fa-send fa-fw"></i>&nbsp;<span
                    [translate]="group.grupo === groupDoc.ComprasEfectivas ? 'docscomerciais.actions.emitirPagamento' : 'docscomerciais.actions.emitirRecibo'"></span>
                </button>

                <button *ngIf="item.data.podeFazerDevolucao" type="button" ngbDropdownItem (click)="fnDevolucoes(item.data)" plPromise>
                  <i class="fa fa-fw fa-share-square-o"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.notaCredito'"></span>
                </button>

                <button type="button" ngbDropdownItem [click]="fnDuplicarDocumentoEditavelActions(item.data)" plPromise>
                  <i class="fa fa-copy fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.actions.duplicarDoc'"></span>
                </button>

                <button *ngIf="!item.data.terminado" type="button" ngbDropdownItem [click]="fnEncerrarDocumentoActions(item.data)" plPromise>
                  <i class="fa fa-fw fa-file-archive-o"></i>&nbsp;<span [translate]="'docscomerciais.actions.encerrarDocumento'"></span>
                </button>

                <button *ngIf="!item.data.anulado" type="button" ngbDropdownItem [click]="fnRastreabilidadeDocumentoActions(item.data)" plPromise>
                  <i class="fa fa-binoculars fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.actions.rastreabilidade'"></span>
                </button>

                <button
                  *ngIf="!item.data.anulado && (!item.data.porPagar || item.data.porPagar === item.data.totalDocumento)"
                  type="button"
                  ngbDropdownItem
                  [click]="fnAnularDocumentoActions(item.data)"
                  plPromise>
                  <i class="fa fa-fw fa-scissors"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.anular'"></span>
                </button>
              </div>
            </div>
          </div>

          <div *entityListItemDetail="let item">
            <pl-split-view [initialSizeLeft]="60" [initialSizeRight]="40">
              <div *plSplitViewLeftSide>
                <documento-faturacao
                  [doc]="item.data._doc"
                  [entityNameClifos]="group.clifos"
                  [grupoDoc]="group.grupo"
                  [actionPdf]="fnGetPdf"
                  [actionEditarDocumento]="fnEditarDocumento"
                  [actionAnexarGDocComercial]="fnAnexarGDocComercial"
                  [actionComunicarDocWebServiceAT]="fnComunicarDocWebServiceAT"
                  [actionComunicarDocCirculacaoAT]="fnComunicarDocCirculacaoAT"
                  [actionInserirCodTranspAT]="fnInserirCodigoTransporteAT"
                  [actionAnularDoc]="fnAnularDocumento"
                  [actionNotaCreditoDoc]="fnNotaCreditoDocumento"
                  [actionTransformarLinhasDoc]="fnTransformarLinhasDocumento"
                  [actionDesmarcarDocTransformado]="fnDesmarcarDocTransformado"
                  [actionGetDoc]="fnGetDoc"
                  [actionDuplicarParaNovoDoc]="fnDuplicarDocumentoEditavel"
                  [actionMeiosPagamentoComercial]="fnMeiosPagamentoComercial"
                  [actionEncerrarDocumento]="fnEncerrarDocumento"
                  [actionReAbreDocumento]="fnReAbreDocumentoActions"
                  [docCabFieldsVisibilityOptions]="docCabVisibilityOptions"
                  (evtRefresh)="refreshList()">
                </documento-faturacao>
              </div>

              <div *plSplitViewRightSide>
                <div *ngIf="!!item.data._doc.cab.ocrCabDocId">
                  <comercialdigital-docviewer [doc]="item.data._digitalGDocViewerDoc"></comercialdigital-docviewer>
                  <!--                    [callback]="callbackDocViewer"-->
                  <!--                    (evtChangedAttachment)="changedComercialDigitalAttachment($event)">-->
                </div>
                <div *ngIf="!item.data._doc.cab.ocrCabDocId">
                  <cg-pdf-viewer [filename]="item.data._fileNamePDF" [pdfSrc]="item.data._urlPDF"></cg-pdf-viewer>
                </div>
              </div>
            </pl-split-view>
          </div>
        </entity-list>

        <br />

        <div class="bancos-extrato-module-legend">
          <cg-table-legend [legend]="tableLegend"></cg-table-legend>
        </div>
      </div>
    </pl-tab>

    <pl-tab [id]="tabs.Editaveis" *ngIf="tabEditaveisVisible">
      <div *plTabTitle><i class="fa fa-edit fa-flip-horizontal fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.rascunho'"></span></div>
      <div *plTabContent>
        <entity-list
          [entityName]="entityName"
          [maintenanceMode]="maintenanceMode"
          [beforeRequest]="fnBeforeRequestEditaveis"
          [dataGridState]="dataGridState"
          [searchValue]="searchValue"
          [serviceMethodsOverride]="serviceMethodsOverride"
          [toolbarInstanceId]="toolbarInstanceIdDocumentosEditaveis"
          [properties]="listOptions"
          (evtOnRefresh)="onRefreshList()"
          (evtDataGridPreparing)="onDataGridPreparing($event)"
          (evtDataGridCellClick)="onDataGridCellClick($event)"
          (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
          (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
          (evtDataGridStateChanged)="onDataGridStateChanged($event)"
          (evtDataGridStateCleared)="onDataGridStateCleared()"
          #entityListDocumentosEditaveis>
          <div *entityListItemActions="let item">
            <div ngbDropdown container="body" class="col-main-table-actions">
              <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-cog"></i></button>
              <div ngbDropdownMenu>
                <button type="button" ngbDropdownItem [click]="fnGetDocPdf(item.data)" plPromise>
                  <i class="fa fa-file-pdf-o fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.actions.pdf'"></span>
                </button>

                <button *ngIf="showGDocs" type="button" ngbDropdownItem [click]="fnAnexarDocumentoActions(item.data)" plPromise>
                  <i class="fa fa-fw fa-paperclip"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.anexos'"></span>
                </button>

                <button *ngIf="item.data.nDocumento === 0 || !item.data.terminado" type="button" ngbDropdownItem [click]="fnEditarDocumentoActions(item.data)" plPromise>
                  <i class="fa fa-fw fa-pencil-square-o"></i>&nbsp;<span [translate]="'docscomerciais.actions.editarDocumento'"></span>
                </button>

                <button type="button" ngbDropdownItem [click]="fnDuplicarDocumentoEditavelActions(item.data)" plPromise>
                  <i class="fa fa-copy fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.actions.duplicarDoc'"></span>
                </button>

                <button *ngIf="!item.data.terminado" type="button" ngbDropdownItem [click]="fnEncerrarDocumentoActions(item.data)" plPromise>
                  <i class="fa fa-fw fa-file-archive-o"></i>&nbsp;<span [translate]="'docscomerciais.actions.encerrarDocumento'"></span>
                </button>

                <button type="button" ngbDropdownItem [click]="fnRastreabilidadeDocumentoActions(item.data)" plPromise>
                  <i class="fa fa-binoculars fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.actions.rastreabilidade'"></span>
                </button>

                <button
                  *ngIf="!item.data.anulado && (!item.data.porPagar || item.data.porPagar === item.data.totalDocumento)"
                  type="button"
                  ngbDropdownItem
                  [click]="fnAnularDocumentoActions(item.data)"
                  plPromise>
                  <i class="fa fa-fw fa-scissors"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.anular'"></span>
                </button>
              </div>
            </div>
          </div>

          <div *entityListItemDetail="let item">
            <documento-faturacao
              [doc]="item.data._doc"
              [entityNameClifos]="group.clifos"
              [grupoDoc]="group.grupo"
              [actionPdf]="fnGetPdf"
              [actionEditarDocumento]="fnEditarDocumento"
              [actionAnexarGDocComercial]="fnAnexarGDocComercial"
              [actionComunicarDocWebServiceAT]="fnComunicarDocWebServiceAT"
              [actionComunicarDocCirculacaoAT]="fnComunicarDocCirculacaoAT"
              [actionInserirCodTranspAT]="fnInserirCodigoTransporteAT"
              [actionAnularDoc]="fnAnularDocumento"
              [actionNotaCreditoDoc]="fnNotaCreditoDocumento"
              [actionTransformarDoc]="fnTransformarDocumento"
              [actionTransformarLinhasDoc]="fnTransformarLinhasDocumento"
              [actionDesmarcarDocTransformado]="fnDesmarcarDocTransformado"
              [actionGetDoc]="fnGetDoc"
              [actionDuplicarParaNovoDoc]="fnDuplicarDocumentoEditavel"
              [actionMeiosPagamentoComercial]="fnMeiosPagamentoComercial"
              [actionEncerrarDocumento]="fnEncerrarDocumento"
              [docCabFieldsVisibilityOptions]="docCabVisibilityOptions"
              (evtRefresh)="refreshList()">
            </documento-faturacao>
          </div>
        </entity-list>
      </div>
    </pl-tab>

    <pl-tab *ngIf="group.grupo === groupDoc.EncomendasClientes || group.grupo === groupDoc.EncomendasFornecedores" [id]="tabs.PorProcessar">
      <div *plTabTitle><i class="fa fa-pencil-square-o fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.porProcessar'"></span></div>
      <div *plTabContent>
        <cg-blocked-plugin [pluginRoles]="roleComercialAdvanced"></cg-blocked-plugin>
        <encomendas *ngIf="hasComercialAdvanced" [grupo]="group.grupo" [grupoClifos]="group.clifos" [toolbarInstanceName]="toolbarInstanceIdPorProcessar"></encomendas>
      </div>
    </pl-tab>

    <pl-tab *ngIf="group.grupo === groupDoc.EncomendasClientes || group.grupo === groupDoc.EncomendasFornecedores" [id]="tabs.Processado">
      <div *plTabTitle><i class="fa fa-list-alt fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.processado'"></span></div>
      <div *plTabContent>
        <cg-blocked-plugin [pluginRoles]="roleComercialAdvanced"></cg-blocked-plugin>
        <encomendas *ngIf="hasComercialAdvanced" [grupo]="group.grupo" [grupoClifos]="group.clifos" [processado]="true" [toolbarInstanceName]="toolbarInstanceIdProcessados"></encomendas>
      </div>
    </pl-tab>
  </pl-tabs>
</div>

<ng-template #templateKeyboardShortcuts>
  <div class="keyboard-shortcuts" *ngIf="(group.grupo === groupDoc.EncomendasClientes || group.grupo === groupDoc.EncomendasFornecedores) && this.activeTabId === docsComerciaisListTab.PorProcessar">
    <keybindings-helper [actions]="keyboardShortcuts" [tooltipConfig]="{placement: 'bottom'}"></keybindings-helper>
  </div>
</ng-template>
