import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonRHAtualizaFeriasEmpregado} from './jsonRHAtualizaFeriasEmp.module.interface';

@Injectable({
  providedIn: 'root'
})
export class RHAtualizaFeriasEmpService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/rhatualizaferiasemp`;
  }

  public getFeriasEmpregados(ano: number): TServiceResponse<Array<IJsonRHAtualizaFeriasEmpregado>> {
    return this._apiService.get<Array<IJsonRHAtualizaFeriasEmpregado>>({url: this._path, params: {ano: ano}});
  }

  public postFeriasEmpregado(listatualizaferiasemp: Array<IJsonRHAtualizaFeriasEmpregado>): TServiceResponse<void> {
    return this._apiService.put<void, Array<IJsonRHAtualizaFeriasEmpregado>>({url: this._path, body: listatualizaferiasemp});
  }
}
