<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'atFRV.configModalTitle'"></h4>
  </div>

  <div class="modal-body">
    <h4 class="atfrv-nifconfig-title">{{ nifAdquirente }} - {{ nomeAdquirente }}</h4>

    <pl-group>
      <label><span [translate]="'atFRV.modal.nifConfig.confPreDefinido'"></span>:</label>
      <edit>
        <entity-autocomplete
          entity="predefinidoscontabilidade"
          attrName="predefinido"
          [model]="model"
          (selectedKeyChange)="model.preDefinido = $event"
          (evtSelectedDescriptionChanged)="model.preDefinidoStr = $event"
          [properties]="{disabled: model.usaSempreRecolha, allowInvalid: false}"
          [fieldsMap]="{preDefinidosID: 'preDefinido', descricao: 'preDefinidoStr'}"
          plAutoFocus>
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <label><span [translate]="'atFRV.modal.nifConfig.contaCorrente'"></span>:</label>
      <edit>
        <entity-autocomplete
          entity="pocs"
          attrName="contacorrente"
          [model]="model"
          (selectedKeyChange)="model.contaCorrente = $event"
          (evtSelectedDescriptionChanged)="model.contaCorrenteStr = $event"
          [properties]="{disabled: model.usaSempreRecolha, allowInvalid: false}"
          [filter]="pocFilter"
          [fieldsMap]="{nConta: 'contaCorrente', nome: 'contaCorrenteStr'}">
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <pl-edit type="checkbox" [(model)]="model.usaSempreRecolha" [properties]="{label: 'Lança sempre documentos na recolha de lançamentos'}"></pl-edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-primary" [disabled]="closeDisabled" [onClick]="close"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
    <pl-button klass="btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</pl-form>
