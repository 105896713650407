<div class="modal-header">
  <button type="button" class="btn-close" (click)="closeModal()"></button>
  <h4 class="modal-title" [translate]="'docscomerciais.importadorLinhas.modalTitle'"></h4>
</div>

<div class="modal-body">
  <pl-nav-wizard [definition]="navWizardDefinition" [callback]="navWizardCallback" [onFinalize]="fnFinalize">
    <pl-nav-wizard-step [stepId]="wizardStepIdWelcome" caption="docscomerciais.importadorLinhas.steps.welcome.title" icon="fa-home">
      <div *plNavWizardStepContent>
        <p [translate]="'docscomerciais.importadorLinhas.steps.welcome.text1'"></p>
        <p><span [translate]="'docscomerciais.importadorLinhas.steps.welcome.text2'"></span>:</p>

        <ng-container *ngFor="let item of checkboxList">
          <pl-edit type="checkbox" [(model)]="item.checked" [properties]="{label: item.label}"></pl-edit>
        </ng-container>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="wizardStepIdDownloadModel" caption="docscomerciais.importadorLinhas.steps.downloadModel.title" icon="fa-download" [validator]="fnValidateStepDownloadModel">
      <div *plNavWizardStepContent>
        <p [translate]="'docscomerciais.importadorLinhas.steps.downloadModel.text1'"></p>
        <p [translate]="'docscomerciais.importadorLinhas.steps.downloadModel.text2'"></p>
        <button type="button" class="btn btn-sm btn-info" [click]="fnDownloadModel" plPromise plAutoFocus>
          <i class="fa fa-download" aria-hidden="true"></i>&nbsp;<span [translate]="'docscomerciais.importadorLinhas.steps.downloadModel.downloadButton'"></span>
        </button>
        <p class="text-danger mt-2">
          <strong [translate]="'docscomerciais.importadorLinhas.steps.downloadModel.text3'"></strong>
        </p>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      [stepId]="wizardStepIdUploadModel"
      caption="docscomerciais.importadorLinhas.steps.uploadModel.title"
      icon="fa-upload"
      [valid]="uploadedFilledModel"
      [validator]="fnValidatorStepUploadModel"
      [hideNext]="uploadingFilledModel">
      <div *plNavWizardStepContent>
        <p [translate]="'docscomerciais.importadorLinhas.steps.uploadModel.text1'"></p>
        <p [translate]="'docscomerciais.importadorLinhas.steps.uploadModel.text2'"></p>
        <pl-upload
          [url]="uploadUrl"
          acceptedFiles=".xlsx"
          [hideActions]="true"
          [maxFiles]="1"
          [maxFileSize]="1"
          [uploadMultiple]="false"
          [callback]="uploadCallback"
          (evtAcceptedFile)="uploadingFilledModel = true"
          (evtUploadedFile)="onUploadedFilledModel($event)"
          (evtUploadErrored)="uploadingFilledModel = false">
        </pl-upload>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="wizardStepIdResumo" caption="docscomerciais.importadorLinhas.steps.resumo.title" icon="fa-check">
      <div *plNavWizardStepContent>
        <p [ngClass]="{'text-success': !importResult.erros.length}" [translate]="'docscomerciais.importadorLinhas.steps.resumo.text1'"></p>
        <p [translate]="'docscomerciais.importadorLinhas.steps.resumo.text2'" [translateParams]="{total: importResult.linhas.length}"></p>
        <ng-container *ngIf="importResult.erros.length">
          <p class="text-danger fw-bolder" [translate]="'docscomerciais.importadorLinhas.steps.resumo.text3'"></p>
          <button type="button" class="btn btn-warning btn-sm" (click)="collapsedErrosList = !collapsedErrosList">
            <i class="fa fa-fw fa-list me-2" aria-hidden="true"></i>
            <span [translate]="'docscomerciais.importadorLinhas.steps.resumo.listaErros'"></span>&nbsp;<small>({{ importResult.erros.length }})</small>
            <i class="fa fa-fw fa-caret-right ms-2" aria-hidden="true" [plAnimationRotate]="!collapsedErrosList"></i>
          </button>
          <pl-animation-collapse [collapsed]="collapsedErrosList">
            <div class="list-group docscomerciais-importadorLinhas-error-list mt-2">
              <div class="list-group-item" *ngFor="let error of importResult.erros">
                <i class="fa fa-fw fa-exclamation-triangle text-danger me-1" aria-hidden="true"></i><span [translate]="error"></span>
              </div>
            </div>
          </pl-animation-collapse>
        </ng-container>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
