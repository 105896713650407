import JSZip from 'jszip';
import {forkJoin, from, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {generateUniqueID, isNumber, isObject} from '../../../common/utilities/utilities';
import type {IPlUploadProperties} from '../../upload.component.interface';
import {isFileOfCompressedType, newFile} from '../../../common/files/files';
import {PlUploadTransformer} from '../upload.transform';

const ONE_KIBIBYTE = 1024;
const EXTENSION_ZIP = '.zip';

export class PlUploadZipTransformer extends PlUploadTransformer {
  protected _doTransformFiles(files: Array<File>, properties: Partial<IPlUploadProperties>): Observable<Array<File>> {
    if (!isObject(properties) || properties.zip !== true) {
      return of<Array<File>>(files);
    }
    return properties.zipStandalone === false ? this._zipAsGroup(files) : this._zipStandalone(files, properties);
  }

  private _zipAsGroup(files: Array<File>): Observable<Array<File>> {
    const zip: JSZip = new JSZip();
    for (const file of files) {
      zip.file<'blob'>(file.name, file);
    }
    return from<Promise<Blob>>(zip.generateAsync<'blob'>({type: 'blob', compression: 'DEFLATE'})).pipe(
      map<Blob, Array<File>>((zippedFile: Blob) => {
        const zippedFilename: string = generateUniqueID('upload') + EXTENSION_ZIP;
        const transformedFile: File = newFile(zippedFile, zippedFilename, {type: 'application/zip'});
        return [transformedFile];
      })
    );
  }

  private _zipStandalone(files: Array<File>, properties: Partial<IPlUploadProperties>): Observable<Array<File>> {
    return forkJoin(files.map<Observable<File>>((file: File) => this._zipStandaloneFile(file, properties)));
  }

  private _zipStandaloneFile(file: File, properties: Partial<IPlUploadProperties>): Observable<File> {
    const extensionIndex: number = file.name.lastIndexOf('.');
    const isAlreadyCompressed = isFileOfCompressedType(file);
    if (isAlreadyCompressed || (isNumber(properties.zipMinSize) && file.size < properties.zipMinSize * ONE_KIBIBYTE * ONE_KIBIBYTE)) {
      return of<File>(file);
    }
    const filename = file.name.substring(0, extensionIndex);
    const zippedFilename: string = filename + EXTENSION_ZIP;
    const zip: JSZip = new JSZip();
    const zipPromise: Promise<Blob> = zip.file<'blob'>(file.name, file).generateAsync<'blob'>({type: 'blob', compression: 'DEFLATE'});
    return from<Promise<Blob>>(zipPromise).pipe(
      map<Blob, File>((zippedFile: Blob) => {
        return newFile(zippedFile, zippedFilename, {type: 'application/zip'});
      })
    );
  }
}
