import {Directive, Injector, OnDestroy, OnInit} from '@angular/core';
import {ConfigOptionsService} from '../../services/config/options/config.options.service';
import {EConfigOptionsInstanceName} from '../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../config/constants';
import {ModuloComponent} from '../../components/module/module.component';

@Directive()
export abstract class MovimentosPendentesModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly configOptionsGroupName: EGroupName;
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public searchValue: string;
  public showDocExterno: boolean;

  protected constructor(
    protected readonly _injector: Injector,
    protected readonly _configOptionsService: ConfigOptionsService
  ) {
    super(_injector);
    this.configOptionsGroupName = EGroupName.ERP;
    this.configOptionsInstanceName = EConfigOptionsInstanceName.MOVIMENTOS_PENDENTES;
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
