import {HttpResponse} from '@angular/common/http';
import {ETipModelo10, MODULE_NAME_MODELO_10} from './modelo10.module.interface';
import {IJsonModelo10} from './jsonModelo10.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {Modelo10ModuleComponent} from './components/modelo10.module.component';
import {Modelo10Service} from './modelo10.module.service';
import {ROLE} from '../../../services/role.const';

export const MODULE_MODELO_10: IModuleDefinition = {
  name: MODULE_NAME_MODELO_10,
  state: {
    url: '/modelo10',
    component: Modelo10ModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.RH],
      pageTitle: 'global.menu.modelo10'
    },
    resolve: [
      {
        provide: 'modelo10',
        deps: [Modelo10Service],
        useFactory: (modelo10Service: Modelo10Service): Promise<IJsonModelo10> => {
          return modelo10Service.getModelo10(ETipModelo10.NewFromDB).then((response: HttpResponse<IJsonModelo10>) => response.body);
        }
      }
    ]
  }
};
