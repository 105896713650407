import {HttpResponse} from '@angular/common/http';
import {AtivosCoeficienteCMModuleComponent} from './components/ativosCoeficienteCM.module.component';
import {AtivosCoeficienteCMService} from './ativosCoeficienteCM.module.service';
import {IJsonAtivosNumber} from '../../../entities/ativos/interfaces/jsonAtivos.entity.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_COEFICIENTE_CORRECAO_MONETARIA} from './ativosCoeficienteCM.module.interface';
import {ROLE} from '../../../services/role.const';

export const MODULE_ATIVOS_COEFICIENTE_CORRECAO_MONETARIA: IModuleDefinition = {
  name: MODULE_NAME_COEFICIENTE_CORRECAO_MONETARIA,
  state: {
    url: `/${MODULE_NAME_COEFICIENTE_CORRECAO_MONETARIA}`,
    component: AtivosCoeficienteCMModuleComponent,
    data: {
      roles: [ROLE.ATIVOS],
      sidebarTitle: 'portals.sidebar.modules.ativoscoeficientecm'
    },
    resolve: [
      {
        provide: 'anosPesquisa',
        deps: [AtivosCoeficienteCMService],
        useFactory: (ativosCoeficienteCMService: AtivosCoeficienteCMService): Promise<Array<IJsonAtivosNumber>> => {
          return ativosCoeficienteCMService.getAnosDisponiveis().then((response: HttpResponse<Array<IJsonAtivosNumber>>) => response.body);
        }
      }
    ]
  }
};
