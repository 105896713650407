import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ROLE} from '../../../../services/role.const';
import {ImpAvisosCobrancaModuleComponent} from './components/impAvisosCobranca.module.component';
import {MODULE_NAME_IMP_AVISOS_COBRANCA} from '../avisosCobranca.module.interface';

export const MODULE_IMPAVISOSCOBRANCA: IModuleDefinition = {
  name: MODULE_NAME_IMP_AVISOS_COBRANCA,
  state: {
    url: `/${MODULE_NAME_IMP_AVISOS_COBRANCA}`,
    component: ImpAvisosCobrancaModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.impAvisosCobranca'
    }
  }
};
