<div class="pl-pdf" [attr.data-debug-url]="debugUrl">
  <pl-alert *ngIf="!isLoading && (!src || (!src.url && !src.data && !src.range)) && !hasError && !renderNotSupported" type="info">
    <i class="fa fa-exclamation-circle"></i>&nbsp;<span>{{ locale.noDocument }}</span>
  </pl-alert>

  <pl-alert *ngIf="!isLoading && (src?.url || src?.data || src?.range) && !hasError && renderNotSupported" type="warning">
    <i class="fa fa-exclamation-circle"></i>&nbsp;<span>{{ locale.renderNotSupported }}</span>
  </pl-alert>

  <pl-alert *ngIf="(!!errorMessage || hasError) && !isLoading" type="danger" [closeable]="false">
    <ng-container *ngIf="errorMessage"><i class="fa fa-exclamation-triangle"></i>&nbsp;<span [innerHTML]="errorMessage | cgcTranslate"></span></ng-container>

    <ng-container *ngIf="hasError">
      <i class="fa fa-exclamation-triangle"></i>&nbsp;<span>{{ localErrorMessage }}</span>
    </ng-container>
  </pl-alert>

  <div class="pl-pdf-header">
    <ng-container *ngTemplateOutlet="templateHeader"></ng-container>
  </div>

  <div class="card">
    <div class="card-body pl-pdf-wrapper" [class.pl-pdf-loading]="isLoading">
      <div *ngIf="isLoading" class="loading-card">
        <div class="loading-card-content-text">{{ locale.loading }}</div>
        <div class="circle-spinner">
          <div class="spinner"></div>
        </div>
      </div>

      <div #pdfViewer class="pl-pdf-viewer" [class.invisible]="isLoading || !src || (!src.url && !src.data && !src.range)">
        <pdf-viewer
          #pdfViewerComponent
          zoom-scale="page-width"
          [src]="src"
          [autoresize]="false"
          [external-link-target]="externalLinkTarget"
          [fit-to-page]="false"
          [original-size]="true"
          [page]="toolbarPage"
          [render-text-mode]="renderTextMode"
          [render-text]="renderText"
          [rotation]="rotation"
          [show-all]="showAll"
          [show-borders]="showBorders"
          [stick-to-page]="stickToPage"
          [zoom]="zoom"
          (after-load-complete)="afterLoadComplete($event)"
          (page-rendered)="pageRendered($event)"
          (text-layer-rendered)="textLayerRendered($event)"
          (error)="onError($event)"
          (on-progress)="onProgress($event)">
        </pdf-viewer>
      </div>
    </div>
  </div>
</div>
