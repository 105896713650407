<div class="pl-switch" [ngClass]="{readonly: options.readonly, disabled: options.disabled, checked: val}" (click)="toggleValue()" (keydown.space)="onKeydownEnter($event)">
  <div class="pl-switch-edit">
    <span class="pl-switch-slider" [tabindex]="options.readonly || options.disabled ? -1 : 0">
      <span class="pl-slider round"></span>
    </span>
  </div>

  <div *ngIf="currentLabel" class="pl-switch-label" [plTranslate]="currentLabel"></div>
</div>

<pl-messages *ngIf="validate" [instance]="self" [formControlInstance]="formControl" [modelValue]="value" [validate]="validate" [validators]="validators" [ngFormInstance]="ngFormInstance">
</pl-messages>
