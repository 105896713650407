<div class="rhpenhoras">
  <entity-detail [entityName]="entityName" [(model)]="model" [type]="type" [maintenanceMode]="maintenanceMode" [callback]="callback" (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <div [plPromise]="promise">
        <pl-form [properties]="{readonly: type === stateTypes.DETAIL}">
          <pl-group>
            <label [translate]="'rhpenhoras.fields.todosProcessamentos'"></label>
            <edit>
              <pl-edit attrName="todosProcessamentos" type="boolean" [model]="model.todosTiposProce" (modelChange)="setTodosProcessos($event)"></pl-edit>
            </edit>
          </pl-group>

          <pl-group *ngIf="!model.todosTiposProce">
            <label [translate]="'rhpenhoras.fields.tipoProcessamento'"></label>
            <edit>
              <entity-autocomplete
                entity="rhtipoprocess"
                attrName="rhtipoprocess"
                [properties]="{disabled: model.todosTiposProce}"
                [model]="model"
                (selectedKeyChange)="model.tipoProcessamento = $event"
                (evtSelectedDescriptionChanged)="model.nomeTipoProcessamento = $event"
                [fieldsMap]="{descricao: 'nomeTipoProcessamento'}"
                [output]="rhtipoprocessOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'rhpenhoras.fields.ncodABDESC'"></label>
            <edit>
              <pl-autocomplete
                attrName="ncodABDESC"
                [source]="descontosList"
                [model]="abdesComp"
                (evtSelected)="evtCodAbdesChanged($event)"
                [allowInvalid]="false"
                [rowTemplate]="descontosTemplate"
                [output]="descontosTemplate"
                [properties]="{validators: {required: {value: true}}}">
                <div class="item-group-dropdown" *inputGroup="{klass: 'hollow-form', tabindex: -1}" ngbDropdown [placement]="['bottom-right', 'bottom-left', 'top-right', 'top-left']">
                  <div class="item-group-dropdown-toggle" ngbDropdownToggle>
                    <i class="pl-icon-more-vertical" aria-hidden="true"></i>
                  </div>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    <button type="button" class="dropdown-item" (click)="openDescontosMaintenance()" plPromise>
                      <i class="fa fa-fw fa-list-alt" aria-hidden="true"></i><span [translate]="'rhpenhoras.maintenance.descontos'"></span>
                    </button>
                  </div>
                </div>
              </pl-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'rhpenhoras.fields.codEMP'"></label>
            <edit>
              <entity-autocomplete
                entity="dgempsfull"
                attrName="codEMP"
                [model]="model"
                (selectedKeyChange)="model.codEMP = $event"
                (evtSelectedDescriptionChanged)="model.nomeEmpr = $event"
                [fieldsMap]="{codEmp: 'codEMP', nome: 'nomeEmpr'}"
                output="nome"
                [properties]="{validators: {required: {value: true}}}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'rhpenhoras.fields.dataInicio'"></label>
              <edit>
                <pl-edit type="date" attrName="dataInicio" [(model)]="model.dataInicio"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'rhpenhoras.fields.penhoraPorInsolvenci'"></label>
              <edit>
                <pl-edit type="boolean" attrName="penhoraPorInsolvenci" [(model)]="model.penhoraPorInsolvenci"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group style="max-width: 100px">
              <label [translate]="'rhpenhoras.fields.suspende'"></label>
              <edit>
                <pl-edit type="boolean" attrName="suspende" [(model)]="model.suspende"></pl-edit>
              </edit>
            </pl-group>

            <pl-group *ngIf="model.suspende">
              <label [translate]="'rhpenhoras.fields.dataSuspensao'"></label>
              <edit>
                <pl-edit type="date" attrName="dataSuspensao" [(model)]="model.dataSuspensao"></pl-edit>
              </edit>
            </pl-group>

            <pl-group *ngIf="model.suspende">
              <label [translate]="'rhpenhoras.fields.obsSupencao'"></label>
              <edit>
                <pl-edit type="text" attrName="obsSupencao" [(model)]="model.obsSupencao"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'rhpenhoras.fields.valorTotalPenhora'"></label>
              <edit>
                <pl-edit
                  type="number"
                  attrName="valorTotalPenhora"
                  [(model)]="model.valorTotalPenhora"
                  [properties]="{validators: {required: {value: true}, decimalsLimit: decimalsLimit.valorTotalPenhora}}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'rhpenhoras.fields.totalValorPenhoraPaga'"></label>
              <edit>
                <pl-edit type="number" attrName="totalValorPenhoraPaga" [(model)]="model.totalValorPenhoraPaga" [properties]="{readonly: true}"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'rhpenhoras.fields.tipoValorPenhora'"></label>
              <edit>
                <pl-edit [type]="dtTipoValorPenhora" attrName="tipoValorPenhora" [(model)]="model.tipoValorPenhora" [properties]="{disabled: isPenhoraPensaoAlimentos}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'rhpenhoras.fields.valor'"></label>
              <edit>
                <pl-edit type="number" attrName="valor" [(model)]="model.valor" [properties]="{decimalsLimit: decimalsLimit.valoraPenhorar}"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'rhpenhoras.fields.temValorLimiteMinimoImpenhoravel'"></label>
              <edit>
                <pl-edit type="boolean" attrName="temValorLimiteMinimoImpenhoravel" [(model)]="temValorLimiteMinimoImpenhoravel"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'rhpenhoras.fields.valorLimImpenhoravel'"></label>
              <edit>
                <pl-edit
                  type="number"
                  attrName="valorLimImpenhoravel"
                  [(model)]="model.valorLimImpenhoravel"
                  [properties]="{disabled: !temValorLimiteMinimoImpenhoravel, decimalsLimit: decimalsLimit.valorLimiteImpenhoravel}"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <label [translate]="'rhpenhoras.fields.valorExternoPenhora'"></label>
            <edit>
              <pl-edit type="number" attrName="valorExternoPenhora" [(model)]="model.valorExternoPenhora"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'rhpenhoras.fields.obsPenhora'"></label>
            <edit>
              <pl-edit type="text" attrName="obsPenhora" [(model)]="model.obsPenhora"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'rhpenhoras.fields.identificacaoPenhora'"></label>
            <edit>
              <pl-edit type="text" attrName="identificacaoPenhora" [(model)]="model.identificacaoPenhora"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'rhpenhoras.fields.formaPagamento'"></label>
              <edit>
                <pl-select
                  attrName="formaPagamento"
                  [model]="formaPagamentoData"
                  [source]="formaPagamentoDataSource"
                  [clearable]="false"
                  [searchable]="false"
                  [labelField]="'label'"
                  [rowTemplate]="'label'"
                  [output]="'label'"
                  (evtChanged)="formaPagamentoChanged($event.item)"
                  [properties]="{readonly: type === stateTypes.DETAIL}">
                </pl-select>
              </edit>
            </pl-group>

            <pl-group *ngIf="formaPagamentoData.value === formaPagamentoEnum.None">
              <label [translate]="'rhpenhoras.fields.referenciaPagamento'"></label>
              <edit>
                <pl-edit type="text" attrName="referenciaPagamento" [(model)]="model.referenciaPagamento"></pl-edit>
              </edit>
            </pl-group>

            <pl-group *ngIf="formaPagamentoData.value === formaPagamentoEnum.IBAN">
              <label [translate]="'rhpenhoras.fields.iban'"></label>
              <edit>
                <pl-edit type="text" attrName="iban" [(model)]="model.iban" [properties]="{validators: {required: {value: true}}}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group *ngIf="formaPagamentoData.value === formaPagamentoEnum.MBRef">
              <label [translate]="'rhpenhoras.fields.mbEntidade'"></label>
              <edit>
                <pl-edit type="text" attrName="mbEntidade" [(model)]="model.mbEntidade" [properties]="{validators: {required: {value: true}, maxlength: {value: 5}}}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group *ngIf="formaPagamentoData.value === formaPagamentoEnum.MBRef || formaPagamentoData.value === formaPagamentoEnum.MBRefEstado">
              <label [translate]="'rhpenhoras.fields.mbReferencia'"></label>
              <edit>
                <pl-edit type="text" attrName="mbReferencia" [(model)]="model.mbReferencia" [properties]="{validators: {required: {value: true}, maxlength: {value: mbReferenciaMaxLength}}}"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <div class="card mt-4">
            <div class="card-body">
              <h5 class="card-title mb-2" [translate]="'rhpenhoras.card.catabo'"></h5>
              <pl-edit
                type="checkbox"
                attrName="calcLiqTodasCateg"
                [model]="model.calcLiqTodasCateg"
                (modelChange)="model.calcLiqTodasCateg = $event; calcLiqTodasCategChanged()"
                [properties]="{label: 'rhpenhoras.card.calcLiqTodasCateg', readonly: type === stateTypes.DETAIL, disabled: isPenhoraPensaoAlimentos}">
              </pl-edit>
              <hr />
              <div class="row">
                <div class="col-6 col-md-3" *ngFor="let item of categAbono; index as i">
                  <pl-edit
                    type="checkbox"
                    [attrName]="item.categ.name"
                    [model]="item.selected"
                    (modelChange)="item.selected = $event; categAbonoChanged()"
                    [properties]="{label: item.categ.name, readonly: type === stateTypes.DETAIL, disabled: isPenhoraPensaoAlimentos}">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </pl-form>
      </div>
    </div>
  </entity-detail>
</div>
