import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {FaturacaoEletronicaConfiguracoesUIService} from '../../service/faturacaoEletronicaConfiguracoes.module.ui.service';
import moment from 'moment';
import {TDate} from '../../../../../common/dates';
import {copy} from 'pl-comps-angular';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {ETipoCidadao, RADIO_GROUP_TIPO_CIDADAO} from './faturacaoEletronicaConfiguracoes.addSafeCertificado.modal.interface';

@Component({
  selector: 'faturacao-eletronica-configuracoes-add-safe-certificado-modal',
  templateUrl: './faturacaoEletronicaConfiguracoes.addSafeCertificado.modal.component.html'
})
export class FaturacaoEletronicaConfiguracoesAddSafeCertificadoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public ambientePreProducao: boolean;
  @Input() public nomeConta: string;
  @Input() public email: string;
  @Input() public tipoCidadao: ETipoCidadao;
  public readonly propertiesTipoCidadao: IRadioGroup<ETipoCidadao>;
  public modalTitle: string;
  public validade: TDate;

  constructor(
    protected readonly _injector: Injector,
    private readonly _faturacaoEletronicaConfiguracoesService: FaturacaoEletronicaConfiguracoesUIService
  ) {
    super(_injector);
    this.validade = moment().add(1, 'year').startOf('year');
    this.propertiesTipoCidadao = copy(RADIO_GROUP_TIPO_CIDADAO);
  }

  public ngOnInit(): void {
    this.modalTitle = !this.ambientePreProducao ? 'faturacaoeletronicaconfiguracoes.addsafecertificadomodal.title' : 'faturacaoeletronicaconfiguracoes.addsafecertificadomodal.titlePreProduction';
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._faturacaoEletronicaConfiguracoesService
      .addSAFECertificate(this.nomeConta, this.email, this.validade, this.tipoCidadao === ETipoCidadao.NACIONAL, this.ambientePreProducao)
      .finally(() => {
        this.enableClose();
        super.close();
      });
  }
}
