import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../cg/modal/cgmodal.component';

@Component({
  selector: 'contabilidade-digital-client-connect-deletedocs-modal',
  templateUrl: './contabilidadedigital.clientconnect.deletedocsmodal.component.html'
})
export class ContabilidadeDigitalClientConnectDeleteDocsModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public folderId: string;
  @Input() public foldersNamePath: Array<string>;

  public path: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnInit(): void {
    this.path = this.foldersNamePath.join(' / ');
  }
}
