import {deepFreeze} from '../../utilities/utilities';
import type {IPlLocale} from '../locales.interface';

/* eslint-disable @typescript-eslint/naming-convention */

const locale: IPlLocale = deepFreeze<IPlLocale>({
  autocomplete: {
    textSearching: 'buscando...',
    textEmpty: 'no hay resultados',
    textNotFound: 'no encontrado'
  },
  btn: {
    goBack: 'Regresa',
    goForward: 'Avance',
    finalize: 'Finalizar',
    submit: 'Entregar',
    search: 'Buscar',
    ok: 'DE ACUERDO',
    cancel: 'Cancelar',
    close: 'Cerrar',
    apply: 'Aplicar',
    addFilter: 'Añadir filtro'
  },
  calendar: {
    views: {
      month: {
        items: 'Elementos'
      },
      year: {
        months: 'Meses'
      }
    },
    detailsBar: {
      simultaneousEvents: 'Eventos simultáneos'
    },
    sidebar: {
      filter: 'Filtrar'
    }
  },
  datepicker: {
    nextCentury: 'Próximo siglo',
    nextDecade: 'La próxima década',
    nextMillennium: 'El próximo milenio',
    nextMonth: 'Próximo mes',
    nextYear: 'El próximo año',
    previousCentury: 'Siglo anterior',
    previousDecade: 'Década anterior',
    previousMillennium: 'Milenio anterior',
    previousMonth: 'Mes pasado',
    previousYear: 'El año pasado',
    toggleContext: 'Cambiar vista',
    validators: {
      minimumDate: 'Este campo no puede tener una fecha anterior a {{value}}',
      maximumDate: 'Este campo no puede tener una fecha mayor que {{value}}'
    }
  },
  mimeTypes: {
    'application/epub+zip': 'EPUB',
    'application/gzip': 'GZ',
    'application/java-archive': 'JAVA',
    'application/json': 'JSON',
    'application/ld+json': 'JSON (formato LD)',
    'application/msword': 'DOC (Word)',
    'application/octet-stream': 'BIN',
    'application/ogg': 'OGG',
    'application/pdf': 'PDF',
    'application/php': 'PHP',
    'application/rtf': 'RTF',
    'application/vnd.amazon.ebook': 'AZW',
    'application/vnd.apple.installer+xml': 'MPKG',
    'application/vnd.mozilla.xul+xml': 'XUL',
    'application/vnd.ms-excel': 'XLS (Excel)',
    'application/vnd.ms-fontobject': 'EOT',
    'application/vnd.ms-powerpoint': 'PPT (Powerpoint)',
    'application/vnd.oasis.opendocument.presentation': 'ODP',
    'application/vnd.oasis.opendocument.spreadsheet': 'ODS',
    'application/vnd.oasis.opendocument.text': 'ODT',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPT (Powerpoint)',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLS (Excel)',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOC (Word)',
    'application/vnd.visio': 'VSD',
    'application/x-7z-compressed': '7z',
    'application/x-abiword': 'ABW',
    'application/x-bzip': 'BZ',
    'application/x-bzip2': 'BZ2',
    'application/x-csh': 'CSH',
    'application/x-freearc': 'ARC',
    'application/x-rar-compressed': 'RAR',
    'application/x-sh': 'SH',
    'application/x-shockwave-flash': 'SWF',
    'application/x-tar': 'TAR',
    'application/xhtml+xml': 'XHTML',
    'application/zip': 'ZIP',
    'audio/aac': 'AAC',
    'audio/midi': 'MID(I)',
    'audio/mpeg': 'MP3',
    'audio/ogg': 'OGG',
    'audio/wav': 'WAV',
    'audio/webm': 'WEBM',
    'font/otf': 'OTF',
    'font/ttf': 'TTF',
    'font/woff': 'WOFF',
    'font/woff2': 'WOFF2',
    'image/*': 'imagens',
    'image/bmp': 'BMP',
    'image/gif': 'GIF',
    'image/jpeg': 'JPG/JPEG',
    'image/png': 'PNG',
    'image/svg+xml': 'SVG',
    'image/tiff': 'TIFF',
    'image/vnd.microsoft.icon': 'ICON',
    'image/webp': 'WEBP',
    'image/x-icon': 'ICON',
    'text/calendar': 'ICS',
    'text/css': 'CSS',
    'text/csv': 'CSV',
    'text/html': 'HTML',
    'text/javascript': 'JS',
    'text/plain': 'TXT',
    'text/xml': 'XML',
    'video/3gpp': '3GP',
    'video/3gpp2': '3G2',
    'video/mp2t': 'TS',
    'video/mpeg': 'MPEG',
    'video/ogg': 'OGV',
    'video/webm': 'WEBM',
    'video/x-msvideo': 'AVI'
  },
  months: {
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Puede',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre'
  },
  placeholders: {
    search: 'Busca en el menú...',
    tableGroupBy: 'Arrastra una columna a esta zona para agruparla por esa columna'
  },
  plColorPicker: {
    presetLabel: 'Colores predefinidos',
    okButtonText: 'DE ACUERDO',
    cancelButtonText: 'Cancelar',
    addColorButtonText: 'agregar color',
    presetEmptyMessage: 'No se agregó color'
  },
  plCookiesConsent: {
    header: 'Este sitio web utiliza cookies',
    footer: 'La declaración de cookies se actualizó por última vez en {{value}}',
    necessaryTitle: 'Obligatorio',
    necessaryDescription:
      'Las cookies obligatorias son imprescindibles para el uso de este sitio web, posibilitando funciones básicas como la navegación por las páginas y el acceso a áreas restringidas.',
    preferencesTitle: 'Preferencias',
    preferencesDescription: 'Las cookies de preferencias permiten que un sitio web recuerde información que cambia su apariencia o comportamiento.',
    statisticsTitle: 'Estadísticas',
    statisticsDescription: 'Las cookies estadísticas ayudan a comprender cómo interactúan los usuarios con el sitio web, recopilando y reportando información de forma anónima.',
    marketingTitle: 'Marketing',
    marketingDescription:
      'Las cookies de marketing se utilizan para mejorar los anuncios que se muestran al usuario, por ejemplo, mostrando solo los anuncios más relevantes y atractivos para el usuario.',
    unclassifiedTitle: 'No clasificado',
    unclassifiedDescription: 'Las cookies no clasificadas son aquellas cuya función aún no ha sido definida o están pendientes de clasificación.',
    showDetails: 'Mostrar detalles',
    hideDetails: 'Ocultar detalles',
    ok: 'DE ACUERDO',
    declaration: 'Declaración de cookies',
    about: 'Acerca de las cookies',
    tableHeaderName: 'Nombre',
    tableHeaderProvider: 'Entidad',
    tableHeaderPurpose: 'Intención',
    tableHeaderExpiresOn: 'Vencimiento',
    tableHeaderType: 'Tipo',
    session: 'Sesión',
    persistent: 'Persistente',
    emptyGroup: 'No utilizamos cookies de este tipo.',
    invalidExpireTime: 'valor no válido'
  },
  plDashboard: {
    emptyData: 'No hay datos para mostrar',
    total: 'Total'
  },
  plMediaDevicesCameraCaptureImage: {
    imageContentAlt: 'Imagen previa',
    titleChange: 'Cambiar imagen',
    titleTabUpload: 'Cargar imagen',
    titleTabCapture: 'Capturar imagen',
    textChange: 'alterar',
    textRemove: 'Para eliminar',
    textDisabledTabCapture: 'Su dispositivo no admite la captura de imágenes.',
    textRetry: 'Intentar nuevamente',
    textCapture: 'Captura',
    textPause: 'Pausa',
    textResume: 'Resumir',
    errorAbortError: 'Se ha producido un problema desconocido que le impide utilizar la cámara de su dispositivo.',
    errorNotAllowedError: 'La cámara de su dispositivo no se puede utilizar en este momento.',
    errorNotFoundError: 'No se encontraron cámaras en su dispositivo.',
    errorNotReadableError: 'Se ha producido un error de hardware que impide el acceso a la cámara de su dispositivo.',
    errorOverconstrainedError: 'No se encontró ninguna cámara en su dispositivo que cumpliera con los criterios requeridos.',
    errorSecurityError: 'La compatibilidad con el uso de la cámara de su dispositivo está deshabilitada.',
    errorTypeError: 'Se ha producido un problema desconocido que le impide utilizar la cámara de su dispositivo.',
    errorTimedOutError: 'La solicitud de permisos necesarios para utilizar la cámara de su dispositivo ha caducado.',
    errorUnavailableMediaDevices: 'Su dispositivo no admite la captura de imágenes.'
  },
  plMultiSelect: {
    available: 'Disponible',
    selected: 'Seleccionado'
  },
  plNavbar: {
    toggleMenu: 'Alternar menú'
  },
  plPdf: {
    loadError: 'Error al leer el documento',
    noDocument: 'Sin documento',
    renderNotSupported: 'Su documento ha sido procesado.',
    pages: 'Páginas',
    zoomAuto: 'Zoom automático',
    zoomPageActual: 'Tamaño real',
    zoomPageFit: 'Ajustar a la página',
    zoomPageWidth: 'Ajustar al ancho',
    zoomIn: 'Acercarse',
    zoomOut: 'Disminuir el zoom',
    presentationMode: 'Cambiar al modo de presentación',
    download: 'transferir',
    print: 'Imprimir',
    loading: 'Cargando documento...'
  },
  plSelect: {
    clearAllText: 'Limpiar todo',
    loadingText: 'A cargar...',
    notFoundText: 'No se encontraron artículos'
  },
  plSideBar: {
    toggleSideBar: 'Alternar barra lateral'
  },
  text: {
    today: 'Hoy',
    day: 'Día',
    days: 'Días',
    week: 'Semana',
    weeks: 'Semanas',
    month: 'Mes',
    months: 'Meses',
    year: 'Año',
    years: 'Años',
    weekend: 'Fin de semana',
    weekends: 'Fines de semana',
    lines: 'Líneas',
    close: 'Cerrar',
    now: 'Ahora',
    clear: 'Limpiar',
    open: 'Abierto',
    initialize: 'Arranque',
    noFilters: 'Sin filtros',
    activeFilters: 'Filtros activos',
    clearFilters: 'Borrar filtros activos',
    true: 'VERDADERO',
    false: 'FALSO',
    and: 'Es',
    or: 'o',
    of: 'en'
  },
  uploader: {
    defaultMessage: 'Suelta archivos aquí para enviar',
    fileTooBig: 'El archivo es muy grande, el tamaño máximo permitido es: <strong>{{maxFilesize}} MB</strong>.',
    invalidFileType: 'No puedes enviar archivos de este tipo.',
    cancelUpload: 'Cancelar carga',
    uploadCanceled: 'Subida cancelada.',
    removeAllFiles: 'Eliminar todo',
    removeFile: 'Para eliminar',
    maxFileExceeded: 'No puedes enviar más archivos.',
    maxFilesExceeded: 'No puedes enviar más archivos.',
    acceptedFileType: 'Solo se admite el tipo de archivo {{acceptedFiles}}.',
    acceptedFileTypes: 'Solo se admiten los tipos de archivos {{acceptedFiles}}.',
    maximumFileSize: 'El tamaño máximo de archivo es {{maximumFileSize}}.',
    maximumPerFileSize: 'El tamaño máximo por archivo es {{maximumFileSize}}.',
    dropHere: 'soltar archivos',
    clickToUpload: 'haga clic aquí para enviar',
    files: 'Archivos',
    uploadAll: 'Envia todo',
    upload: 'Mandar',
    rejected: 'Archivo rechazado',
    retry: 'Intenta enviar de nuevo',
    uploadSuccess: 'El archivo ha subido correctamente',
    uploadError: 'Se produjo un error al cargar el archivo.',
    units: {b: 'B', kb: 'KB', mb: 'MB', gb: 'GB', tb: 'TB', pb: 'PB', eb: 'EB', zb: 'ZB', yb: 'YB'}
  },
  validators: {
    date: 'Fecha invalida',
    datetimelocal: 'Fecha invalida',
    email: 'Email inválido',
    equals: 'El valor de este campo debe ser igual al valor del campo {{value}}',
    max: 'Este campo no puede tener un valor mayor que {{value}}',
    maxlength: 'Este campo tiene una longitud máxima de {{value}} caracteres',
    maxSelected: 'Este campo no puede tener más de {{value}} elemento(s) seleccionado(s)',
    min: 'Este campo no puede tener un valor menor que {{value}}',
    minlength: 'Este campo tiene una longitud mínima de {{value}} caracteres',
    minSelected: 'Este campo debe tener al menos {{value}} elemento(s) seleccionado(s)',
    month: 'Mes no válido',
    number: 'Número invalido',
    pattern: 'Campo no válido',
    required: 'Este campo es obligatorio',
    time: 'Campo no válido',
    url: 'Campo no válido',
    week: 'Campo no válido'
  },
  weekdays: {
    sunday: 'Domingo',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado'
  }
});

/* eslint-enable @typescript-eslint/naming-convention */

export default locale;
