import {ROLE} from '../../../services/role.const';
import {IJsonClasseContrato} from '../jsonClasseContrato.entity.interface';
import {IEntityDefinition} from '../../../components/entity/entity.definition.interface';
import {ENTITY_NAME_CLASSES_CONTRATOS_AVENCAS} from '../classesContratos.entity.interface';

export const ENTITY_CLASSES_CONTRATOS_AVENCAS: IEntityDefinition<IJsonClasseContrato> = {
  name: ENTITY_NAME_CLASSES_CONTRATOS_AVENCAS,
  roles: [ROLE.ERP],
  searchPlaceholder: 'classescontratosservicos.pesquisa',
  pageTitle: 'global.menu.classescontratosservicos',
  metadata: {
    keyName: 'nClasseCtr',
    fields: [
      {
        name: 'nClasseCtr',
        type: 'smallint',
        placeholder: 'classescontratosservicos.fields.nClasseCtrPlaceholder',
        width: '200px',
        caption: 'classescontratosservicos.fields.nClasseCtr',
        validators: {maxlength: 4}
      },
      {
        name: 'nomeClasseCtr',
        placeholder: 'classescontratosservicos.fields.nomeClasseCtrPlaceholder',
        caption: 'classescontratosservicos.fields.nomeClasseCtr',
        validators: {required: true}
      }
    ],
    order: 'nClasseCtr',
    searchFields: 'nClasseCtr,nomeClasseCtr',
    listFields: 'nClasseCtr,nomeClasseCtr',
    newFields: 'nClasseCtr,nomeClasseCtr',
    editFields: 'nClasseCtr,nomeClasseCtr',
    detailFields: 'nClasseCtr,nomeClasseCtr'
  },
  autocomplete: {
    rowTemplate: '{{nClasseCtr}} - {{nomeClasseCtr}}',
    output: 'nomeClasseCtr',
    searchFields: 'nClasseCtr,nomeClasseCtr'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    duplicate: false,
    search: true,
    delete: true,
    filter: true
  }
};
