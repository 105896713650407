import {Component, Injector} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'modal-ativos-import-progressbar',
  templateUrl: './ativosImport.progressBar.modal.component.html'
})
export class AtivosImportProgressBarModalComponent extends CGModalComponent<void> {
  public pbProcLabel: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.pbProcLabel = '';
  }
}
