<div class="addabonodesconto-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rhcessacontratotrabalho.modal.addabonodesconto.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-form plPromise>
      <pl-group>
        <pl-group>
          <label [translate]="'rhcessacontratotrabalho.modal.addabonodesconto.fields.abonoDesc'"></label>
          <edit>
            <pl-edit type="tipoAbonoDesc" attrName="abonoDesc" [(model)]="model.abonoDesc" (modelChange)="model.abonoDesc = $event; abonoDescChanged()"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'rhcessacontratotrabalho.modal.addabonodesconto.fields.codABDESC'"></label>
          <edit>
            <div [ngSwitch]="model.abonoDesc">
              <entity-autocomplete
                *ngSwitchDefault
                entity="abonos"
                attrName="codABDESC"
                [model]="model"
                (selectedKeyChange)="model.codABDESC = $event"
                (evtSelectedDescriptionChanged)="model.descricaoAbono = $event"
                [fieldsMap]="{codAbDesc: 'codABDESC', designacaoBreve: 'descricaoAbono'}">
              </entity-autocomplete>

              <entity-autocomplete
                *ngSwitchCase="tipoAbonoDesconto.Desconto"
                entity="descontos"
                attrName="codABDESC"
                [model]="model"
                (selectedKeyChange)="model.codABDESC = $event"
                (evtSelectedDescriptionChanged)="model.descricaoAbono = $event"
                [fieldsMap]="{codAbDesc: 'codABDESC', designacaoBreve: 'descricaoAbono'}">
              </entity-autocomplete>
            </div>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <label [translate]="'rhcessacontratotrabalho.modal.addabonodesconto.fields.valor'"></label>
        <edit>
          <pl-edit type="number" attrName="valor" [(model)]="model.valor"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'rhcessacontratotrabalho.modal.addabonodesconto.fields.observacoes'"></label>
        <edit>
          <pl-edit type="text" attrName="observacoes" [(model)]="model.observacoes"></pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button" plPromise>
    <pl-button klass="btn-success action-save" (evtClicked)="close()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

    <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
