import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonDataValor} from '../datavalor/jsonDataValor.entity.interface';
import {IJsonImtab} from './jsonImtab.entity.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface IImTabEntityService extends IEntityService<IJsonImtab> {
  adicionarLinha(nimtab: string, linhaValor: IJsonDataValor): TServiceResponse<IJsonDataValor>;

  editarLinha(nimtab: string, linhaValor: IJsonDataValor): TServiceResponse<IJsonDataValor>;

  eliminarLinha(nimtab: string, dataValorID: string): TServiceResponse<void>;
}

export interface IImTabEntity extends IEntityDefinition<IJsonImtab, IImTabEntityService> {}

export const ENTITY_NAME_AMORTIZACAO = 'amortizacao';
