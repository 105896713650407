import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {PlAlertService} from 'pl-comps-angular';
import {IJsonGestaoComunicacoesTopico} from '../../../jsonGestaoComunicacoesTopico.module.interface';
import {IJsonComCategoria} from '../../../../../../entities/comcategoria/jsonComCategoria.entity.interface';
import {GestaoComunicacoesTopicoService} from '../../../gestaoComunicacoesTopico.module.service';
import {FormGroupDirective} from '@angular/forms';

@Component({
  selector: 'gestao-comunicacoes-edit-topico-modal',
  templateUrl: './gestaoComunicacoes.topico.edit.modal.component.html'
})
export class GestaoComunicacoesTopicoEditModalComponent extends CGModalComponent<IJsonGestaoComunicacoesTopico> implements OnInit {
  @Input() public model: IJsonGestaoComunicacoesTopico;
  @Input() public nEmpresaSelected: string;

  public readonly utilizadorTemplate: string;
  public categoriasSource: Array<IJsonComCategoria>;
  public form: FormGroupDirective;
  public searchCategoriasParam: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _gestaoComunicacoesTopicoService: GestaoComunicacoesTopicoService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.utilizadorTemplate = '{{nUtilizador}} - {{nome}}';
    this.categoriasSource = [];
  }

  public ngOnInit(): void {
    this.searchCategoriasParam = `nEmpresa=${this.nEmpresaSelected}|nEmpresa=000`;
  }

  public async close(): Promise<void> {
    this.model = (await this._gestaoComunicacoesTopicoService.updateTopico(this.model.comTopicoId, this.model)).body;
    this._plAlertService.success('gestaoComunicacoes.modal.topico.mensagens.updateSuccess');
    super.close(this.model);
  }
}
