<div class="classescaixa">
  <entity-detail entityName="classescaixa" [(model)]="model" [type]="type" [maintenanceMode]="maintenanceMode" [callback]="callback" (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL}" (formInstanceChange)="formInstance = $event">
        <pl-group>
          <label [translate]="'classescaixa.fields.ncrss'"></label>
          <edit>
            <entity-autocomplete
              entity="crss"
              attrName="ncrss"
              [model]="model"
              (selectedKeyChange)="model.ncrss = $event"
              (evtSelectedDescriptionChanged)="model.nomeCRSS = $event"
              [fieldsMap]="{nome: 'nomeCRSS'}"
              [reportExceptions]="false"
              [properties]="{validators: {required: {value: true}}, allowInvalid: false}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'classescaixa.fields.nClasseCaixa'"></label>
            <edit>
              <pl-edit type="text" attrName="nclcx" [(model)]="model.nclcx" [properties]="{validators: {required: {value: true}, maxlength: {value: 4}}, disabled: type !== stateTypes.NEW}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'classescaixa.fields.designacaoBreve'"></label>
            <edit>
              <pl-edit type="text" attrName="designacaoBreve" [(model)]="model.designacaoBreve" [properties]="{validators: {required: {value: true}, maxlength: {value: 100}}}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'classescaixa.fields.tipoRegime'"></label>
            <edit>
              <pl-edit type="tipoRegime" attrName="tipoRegime" [(model)]="model.tipoRegime" [properties]="{validators: {required: {value: true}}, disallowClear: true}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'classescaixa.fields.descricao'"></label>
            <edit>
              <pl-edit type="text" attrName="descricao" [(model)]="model.descricao"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <edit>
              <pl-edit type="checkbox" attrName="naoProcSubNatal" [(model)]="model.naoProcSubNatal" [properties]="{label: 'classescaixa.fields.naoProcSubNatal'}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <edit>
              <pl-edit type="checkbox" attrName="naoProcSubFerias" [(model)]="model.naoProcSubFerias" [properties]="{label: 'classescaixa.fields.naoProcSubFerias'}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-tabs>
          <pl-tab caption="classescaixa.fields.taxas">
            <div *plTabContent>
              <pl-group>
                <edit>
                  <pl-edit type="checkbox" attrName="naoVaiSegSocial" [(model)]="model.naoVaiSegSocial" [properties]="{label: 'classescaixa.fields.naoVaiSegSocial'}"></pl-edit>
                </edit>
              </pl-group>

              <fieldset>
                <legend [translate]="'classescaixa.fields.percenttitle'"></legend>

                <pl-group>
                  <label [translate]="'classescaixa.fields.percentPATR'"></label>
                  <edit>
                    <pl-edit type="number" attrName="percentPatr" [(model)]="model.percentPATR"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'classescaixa.fields.percentEMPR'"></label>
                  <edit>
                    <pl-edit type="number" attrName="percentEmpr" [(model)]="model.percentEMPR"></pl-edit>
                  </edit>
                </pl-group>
              </fieldset>
            </div>
          </pl-tab>

          <pl-tab caption="classescaixa.fields.config">
            <div *plTabContent>
              <fieldset class="mb-3">
                <legend [translate]="'classescaixa.fields.limites'"></legend>

                <pl-group>
                  <pl-group>
                    <edit>
                      <pl-edit type="checkbox" attrName="temLimiteMaximo" [(model)]="model.temLimiteMaximo" [properties]="{label: 'classescaixa.fields.temLimiteMaximo'}"></pl-edit>
                    </edit>
                  </pl-group>

                  <pl-group>
                    <edit>
                      <pl-edit type="checkbox" attrName="temLimiteMinimo" [(model)]="model.temLimiteMinimo" [properties]="{label: 'classescaixa.fields.temLimiteMaximo'}"></pl-edit>
                    </edit>
                  </pl-group>
                </pl-group>

                <pl-group>
                  <pl-group>
                    <label [translate]="'classescaixa.fields.limMaxNSalariosMin'"></label>
                    <edit>
                      <pl-edit type="number" attrName="limMaxNSalariosMin" [(model)]="model.limMaxNSalariosMin" [properties]="{disabled: !model.temLimiteMaximo}"></pl-edit>
                    </edit>
                  </pl-group>

                  <pl-group>
                    <label [translate]="'classescaixa.fields.limMinNSalariosMin'"></label>
                    <edit>
                      <pl-edit type="number" attrName="limMinNSalariosMin" [(model)]="model.limMinNSalariosMin" [properties]="{disabled: !model.temLimiteMinimo}"></pl-edit>
                    </edit>
                  </pl-group>
                </pl-group>
              </fieldset>

              <fieldset>
                <legend [translate]="'classescaixa.fields.codIntegracaotitle'"></legend>
                <pl-group>
                  <label [translate]="'classescaixa.fields.codIntegracao'"></label>
                  <edit>
                    <pl-edit type="text" attrName="codIntegracao" [(model)]="model.codIntegracao"></pl-edit>
                  </edit>
                </pl-group>
              </fieldset>
            </div>
          </pl-tab>
        </pl-tabs>
      </pl-form>
    </div>
  </entity-detail>
</div>
