import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ENTITY_NAME_EMPRESAS, IEmpresa} from './empresas.entity.interface';

export const ENTITY_EMPRESAS: IEntityDefinition<IEmpresa> = {
  name: ENTITY_NAME_EMPRESAS,
  url: 'manager/empresas',
  roles: [ROLE.ERP, ROLE.PCA, ROLE.RH],
  pageTitle: 'global.menu.empresa',
  icon: 'fa-building',
  searchPlaceholder: 'empresas.pesquisa',
  metadata: {
    keyName: 'nempresa',
    fields: [
      {
        name: 'nempresa',
        width: '120px',
        caption: 'empresas.fields.nempresa',
        placeholder: 'empresas.fields.nempresaPlaceholder',
        validators: {required: true, maxlength: 20}
      },
      {name: 'nome', caption: 'empresas.fields.nome', placeholder: 'empresas.fields.nomePlaceholder', validators: {required: true, maxlength: 50}}
    ]
  },
  autocomplete: {
    rowTemplate: '{{nempresa}} - {{nome}}',
    output: 'nome',
    searchFields: 'nempresa,nome'
  },
  actions: {
    new: false,
    edit: false,
    detail: false,
    delete: false,
    search: false
  }
};
