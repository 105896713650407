import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_PAISES_ISO_3166, IPaisesISO3166} from './paisesISO3166.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_PAISES_ISO_3166: IDataSource<string, IPaisesISO3166> = deepFreeze({
  name: DATA_SOURCE_NAME_PAISES_ISO_3166,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{codigo}} - {{designacaoPT}} ({{designacao}})',
    output: '{{codigo}} - {{designacaoPT}}',
    outputKey: 'codigo'
  },
  data: [
    // jshint ignore:start
    {codigo: '000', codigoAlpha2: '00', codigoAlpha3: '', designacao: 'Por Definir', designacaoPT: 'Por Definir', nomePaisPL: '', abrevMoeda: ''},
    {codigo: '020', codigoAlpha2: 'AD', codigoAlpha3: 'AND', designacao: 'Andorra', designacaoPT: 'Andorra', nomePaisPL: "Principat d'Andorra", abrevMoeda: 'EUR'}, //  AD
    {
      codigo: '784',
      codigoAlpha2: 'AE',
      codigoAlpha3: 'ARE',
      designacao: 'United Arab Emirates',
      designacaoPT: 'Emirados Árabes Unidos',
      nomePaisPL: 'دولة الإمارات العربية المتحدة',
      abrevMoeda: 'AED'
    }, //  AE
    {codigo: '004', codigoAlpha2: 'AF', codigoAlpha3: 'AFG', designacao: 'Afghanistan', designacaoPT: 'Afeganistão', nomePaisPL: 'Afġānistān', abrevMoeda: 'AFN'}, //  AF
    {
      codigo: '028',
      codigoAlpha2: 'AG',
      codigoAlpha3: 'ATG',
      designacao: 'Antigua and Barbuda',
      designacaoPT: 'Antígua e Barbuda',
      nomePaisPL: 'Antigua and Barbuda',
      abrevMoeda: 'XCD'
    }, //  AG
    {codigo: '660', codigoAlpha2: 'AI', codigoAlpha3: 'AIA', designacao: 'Anguilla', designacaoPT: 'Anguilha', nomePaisPL: 'Anguilla', abrevMoeda: 'XCD'}, //  AI
    {codigo: '008', codigoAlpha2: 'AL', codigoAlpha3: 'ALB', designacao: 'Albania', designacaoPT: 'Albânia', nomePaisPL: 'Shqipëria', abrevMoeda: 'ALL'}, //  AL
    {codigo: '051', codigoAlpha2: 'AM', codigoAlpha3: 'ARM', designacao: 'Armenia', designacaoPT: 'Arménia ', nomePaisPL: 'Հայաստան', abrevMoeda: 'AMD'}, //  AM
    {
      codigo: '530',
      codigoAlpha2: 'AN',
      codigoAlpha3: 'ANT',
      designacao: 'Netherlands Antilles',
      designacaoPT: 'Antilhas Holandesas',
      nomePaisPL: 'Nederlandse Antillen',
      abrevMoeda: 'ANG'
    }, //  AN
    {codigo: '024', codigoAlpha2: 'AO', codigoAlpha3: 'AGO', designacao: 'Angola', designacaoPT: 'Angola', nomePaisPL: 'Angola', abrevMoeda: 'AOA'}, //  AO
    {codigo: '010', codigoAlpha2: 'AQ', codigoAlpha3: 'ATA', designacao: 'Antarctica', designacaoPT: 'Antártida', nomePaisPL: 'Antarctica', abrevMoeda: 'GBP'}, //  AQ
    {codigo: '032', codigoAlpha2: 'AR', codigoAlpha3: 'ARG', designacao: 'Argentina', designacaoPT: 'Argentina', nomePaisPL: 'Argentina', abrevMoeda: 'ARS'}, //  AR
    {
      codigo: '016',
      codigoAlpha2: 'AS',
      codigoAlpha3: 'ASM',
      designacao: 'American Samoa',
      designacaoPT: 'Samoa Americana',
      nomePaisPL: 'Amerika Sāmoa',
      abrevMoeda: 'USD'
    }, //  AS
    {codigo: '040', codigoAlpha2: 'AT', codigoAlpha3: 'AUT', designacao: 'Austria', designacaoPT: 'Áustria', nomePaisPL: 'Österreich', abrevMoeda: 'EUR'}, //  AT
    {codigo: '036', codigoAlpha2: 'AU', codigoAlpha3: 'AUS', designacao: 'Australia', designacaoPT: 'Austrália', nomePaisPL: 'Australia', abrevMoeda: 'AUD'}, //  AU
    {codigo: '533', codigoAlpha2: 'AW', codigoAlpha3: 'ABW', designacao: 'Aruba', designacaoPT: 'Aruba', nomePaisPL: 'Aruba', abrevMoeda: 'AWG'}, //  AW
    {codigo: '248', codigoAlpha2: 'AX', codigoAlpha3: 'ALA', designacao: 'Åland Islands', designacaoPT: 'Ilhas Åland', nomePaisPL: 'Åland', abrevMoeda: 'EUR'}, //  AX
    {codigo: '031', codigoAlpha2: 'AZ', codigoAlpha3: 'AZE', designacao: 'Azerbaijan', designacaoPT: 'Azerbaijão', nomePaisPL: 'Azərbaycan', abrevMoeda: 'AZN'}, //  AZ
    {
      codigo: '070',
      codigoAlpha2: 'BA',
      codigoAlpha3: 'BIH',
      designacao: 'Bosnia and Herzegovina',
      designacaoPT: 'Bósnia e Herzegovina',
      nomePaisPL: 'Bosna i Hercegovina',
      abrevMoeda: 'BAM'
    }, // BA
    {codigo: '052', codigoAlpha2: 'BB', codigoAlpha3: 'BRB', designacao: 'Barbados', designacaoPT: 'Barbados', nomePaisPL: 'Barbados', abrevMoeda: 'BBD'}, // BB
    {codigo: '050', codigoAlpha2: 'BD', codigoAlpha3: 'BGD', designacao: 'Bangladesh', designacaoPT: 'Bangladesh', nomePaisPL: 'Bangladesh', abrevMoeda: 'BDT'}, // BD
    {codigo: '056', codigoAlpha2: 'BE', codigoAlpha3: 'BEL', designacao: 'Belgium', designacaoPT: 'Bélgica', nomePaisPL: 'België/Belgique/Belgien', abrevMoeda: 'EUR'}, // BE
    {codigo: '854', codigoAlpha2: 'BF', codigoAlpha3: 'BFA', designacao: 'Burkina Faso', designacaoPT: 'Burkina Faso', nomePaisPL: 'Burkina Faso', abrevMoeda: 'XOF'}, // BF
    {codigo: '100', codigoAlpha2: 'BG', codigoAlpha3: 'BGR', designacao: 'Bulgaria', designacaoPT: 'Bulgária ', nomePaisPL: 'България', abrevMoeda: 'BGN'}, // BG
    {codigo: '048', codigoAlpha2: 'BH', codigoAlpha3: 'BHR', designacao: 'Bahrain', designacaoPT: 'Bahrein', nomePaisPL: ' ‏البحرين', abrevMoeda: 'BHD'}, // BH
    {codigo: '108', codigoAlpha2: 'BI', codigoAlpha3: 'BDI', designacao: 'Burundi', designacaoPT: 'Burúndi', nomePaisPL: 'Uburundi/Burundi', abrevMoeda: 'BIF'}, // BI
    {codigo: '204', codigoAlpha2: 'BJ', codigoAlpha3: 'BEN', designacao: 'Benin', designacaoPT: 'Benim', nomePaisPL: 'Bénin', abrevMoeda: 'XOF'}, // BJ
    {
      codigo: '652',
      codigoAlpha2: 'BL',
      codigoAlpha3: 'BLM',
      designacao: 'Saint Barthélemy',
      designacaoPT: 'Coletividade de São Bartolomeu',
      nomePaisPL: 'Saint-Barthélemy',
      abrevMoeda: 'EUR'
    }, // BL
    {codigo: '060', codigoAlpha2: 'BM', codigoAlpha3: 'BMU', designacao: 'Bermuda', designacaoPT: 'Bermudas', nomePaisPL: 'Bermuda', abrevMoeda: 'BMD'}, // BM
    {codigo: '096', codigoAlpha2: 'BN', codigoAlpha3: 'BRN', designacao: 'Brunei Darussalam', designacaoPT: 'Brunei', nomePaisPL: 'Brunei Darussalam', abrevMoeda: 'BND'}, // BN
    {
      codigo: '068',
      codigoAlpha2: 'BO',
      codigoAlpha3: 'BOL',
      designacao: 'Bolivia, Plurinational State of',
      designacaoPT: 'Bolívia',
      nomePaisPL: 'Bolivia',
      abrevMoeda: 'BOB'
    }, // BO
    {codigo: '076', codigoAlpha2: 'BR', codigoAlpha3: 'BRA', designacao: 'Brazil', designacaoPT: 'Brasil', nomePaisPL: 'Brasil', abrevMoeda: 'BRL'}, // BR
    {codigo: '044', codigoAlpha2: 'BS', codigoAlpha3: 'BHS', designacao: 'Bahamas', designacaoPT: 'Bahamas', nomePaisPL: 'Bahamas', abrevMoeda: 'BSD'}, // BS
    {codigo: '064', codigoAlpha2: 'BT', codigoAlpha3: 'BTN', designacao: 'Bhutan', designacaoPT: 'Butão', nomePaisPL: 'འབྲུག་ཡུལ་', abrevMoeda: 'BTN'}, // BT
    {codigo: '074', codigoAlpha2: 'BV', codigoAlpha3: 'BVT', designacao: 'Bouvet Island', designacaoPT: 'Ilha Bouvet', nomePaisPL: 'Bouvetøya', abrevMoeda: 'NOK'}, // BV
    {codigo: '072', codigoAlpha2: 'BW', codigoAlpha3: 'BWA', designacao: 'Botswana', designacaoPT: 'Botswana', nomePaisPL: 'Botswana', abrevMoeda: 'BWP'}, // BW
    {codigo: '112', codigoAlpha2: 'BY', codigoAlpha3: 'BLR', designacao: 'Belarus', designacaoPT: 'Bielorrússia', nomePaisPL: 'Белару́сь', abrevMoeda: 'BYR'}, // BY
    {codigo: '084', codigoAlpha2: 'BZ', codigoAlpha3: 'BLZ', designacao: 'Belize', designacaoPT: 'Belize', nomePaisPL: 'Belize', abrevMoeda: 'BZD'}, // BZ
    {codigo: '124', codigoAlpha2: 'CA', codigoAlpha3: 'CAN', designacao: 'Canada', designacaoPT: 'Canadá', nomePaisPL: 'Canada', abrevMoeda: 'CAD'}, // CA
    {
      codigo: '166',
      codigoAlpha2: 'CC',
      codigoAlpha3: 'CCK',
      designacao: 'Cocos (Keeling) Islands',
      designacaoPT: 'Ilhas Cocos',
      nomePaisPL: 'Keeling Islands',
      abrevMoeda: 'AUD'
    }, // CC
    {
      codigo: '180',
      codigoAlpha2: 'CD',
      codigoAlpha3: 'COD',
      designacao: 'Congo, the Democratic Republic of the',
      designacaoPT: 'República Democrática do Congo',
      nomePaisPL: 'Jamhuri ya Kidemokrasia ya Cong',
      abrevMoeda: 'CDF'
    }, // CD
    {
      codigo: '140',
      codigoAlpha2: 'CF',
      codigoAlpha3: 'CAF',
      designacao: 'Central African Republic',
      designacaoPT: 'República Centro-Africana',
      nomePaisPL: 'République Centrafricaine',
      abrevMoeda: 'XAF'
    }, // CF
    {codigo: '178', codigoAlpha2: 'CG', codigoAlpha3: 'COG', designacao: 'Congo', designacaoPT: 'Congo', nomePaisPL: 'Congo', abrevMoeda: 'XAF'}, // CG
    {
      codigo: '756',
      codigoAlpha2: 'CH',
      codigoAlpha3: 'CHE',
      designacao: 'Switzerland',
      designacaoPT: 'Suíça',
      nomePaisPL: 'Schweiz/Suisse/Svizzera/Svizra',
      abrevMoeda: 'CHF'
    }, // CH
    {
      codigo: '384',
      codigoAlpha2: 'CI',
      codigoAlpha3: 'CIV',
      designacao: "Côte d'Ivoire",
      designacaoPT: 'Costa do Marfim',
      nomePaisPL: "Côte d'Ivoire",
      abrevMoeda: 'XOF'
    }, // CI
    {codigo: '184', codigoAlpha2: 'CK', codigoAlpha3: 'COK', designacao: 'Cook Islands', designacaoPT: 'Ilhas Cook', nomePaisPL: 'Cook Islands', abrevMoeda: 'NZD'}, // CK
    {codigo: '152', codigoAlpha2: 'CL', codigoAlpha3: 'CHL', designacao: 'Chile', designacaoPT: 'Chile', nomePaisPL: 'Chile', abrevMoeda: 'CLP'}, // CL
    {codigo: '120', codigoAlpha2: 'CM', codigoAlpha3: 'CMR', designacao: 'Cameroon', designacaoPT: 'Camarões', nomePaisPL: 'Cameroun', abrevMoeda: 'XAF'}, // CM
    {codigo: '156', codigoAlpha2: 'CN', codigoAlpha3: 'CHN', designacao: 'China', designacaoPT: 'China', nomePaisPL: '中華人民共和國', abrevMoeda: 'CNY'}, // CN
    {codigo: '170', codigoAlpha2: 'CO', codigoAlpha3: 'COL', designacao: 'Colombia', designacaoPT: 'Colômbia', nomePaisPL: 'Colombia', abrevMoeda: 'COP'}, // CO
    {codigo: '188', codigoAlpha2: 'CR', codigoAlpha3: 'CRI', designacao: 'Costa Rica', designacaoPT: 'Costa Rica', nomePaisPL: 'Costa Rica', abrevMoeda: 'CRC'}, // CR
    {codigo: '192', codigoAlpha2: 'CU', codigoAlpha3: 'CUB', designacao: 'Cuba', designacaoPT: 'Cuba', nomePaisPL: 'Cuba', abrevMoeda: 'CUP'}, // CU
    {codigo: '132', codigoAlpha2: 'CV', codigoAlpha3: 'CPV', designacao: 'Cape Verde', designacaoPT: 'Cabo Verde', nomePaisPL: 'Cabo Verde', abrevMoeda: 'CVE'}, // CV
    {
      codigo: '162',
      codigoAlpha2: 'CX',
      codigoAlpha3: 'CXR',
      designacao: 'Christmas Island',
      designacaoPT: 'Ilha do Natal',
      nomePaisPL: 'Christmas Island',
      abrevMoeda: 'AUD'
    }, // CX
    {codigo: '196', codigoAlpha2: 'CY', codigoAlpha3: 'CYP', designacao: 'Cyprus', designacaoPT: 'Chipre', nomePaisPL: 'Κύπρος/Kıbrıs', abrevMoeda: 'EUR'}, // CY
    {
      codigo: '203',
      codigoAlpha2: 'CZ',
      codigoAlpha3: 'CZE',
      designacao: 'Czech Republic',
      designacaoPT: 'República Checa',
      nomePaisPL: 'Česká republika',
      abrevMoeda: 'CZK'
    }, // CZ
    {codigo: '276', codigoAlpha2: 'DE', codigoAlpha3: 'DEU', designacao: 'Germany', designacaoPT: 'Alemanha', nomePaisPL: 'Deutschland', abrevMoeda: 'EUR'}, // DE
    {codigo: '262', codigoAlpha2: 'DJ', codigoAlpha3: 'DJI', designacao: 'Djibouti', designacaoPT: 'Djibuti', nomePaisPL: 'جيبوتي', abrevMoeda: 'DJF'}, // DJ
    {codigo: '208', codigoAlpha2: 'DK', codigoAlpha3: 'DNK', designacao: 'Denmark', designacaoPT: 'Dinamarca', nomePaisPL: 'Danmark', abrevMoeda: 'DKK'}, // DK
    {codigo: '212', codigoAlpha2: 'DM', codigoAlpha3: 'DMA', designacao: 'Dominica', designacaoPT: 'Domínica', nomePaisPL: 'Dominica', abrevMoeda: 'XCD'}, // DM
    {
      codigo: '214',
      codigoAlpha2: 'DO',
      codigoAlpha3: 'DOM',
      designacao: 'Dominican Republic',
      designacaoPT: 'República Dominicana',
      nomePaisPL: 'República Dominicana',
      abrevMoeda: 'DOP'
    }, // DO
    {codigo: '012', codigoAlpha2: 'DZ', codigoAlpha3: 'DZA', designacao: 'Algeria', designacaoPT: 'Argélia', nomePaisPL: 'الجزائر', abrevMoeda: 'DZD'}, // DZ
    {codigo: '218', codigoAlpha2: 'EC', codigoAlpha3: 'ECU', designacao: 'Ecuador', designacaoPT: 'Equador', nomePaisPL: 'Ecuador', abrevMoeda: 'USD'}, //  EC
    {codigo: '233', codigoAlpha2: 'EE', codigoAlpha3: 'EST', designacao: 'Estonia', designacaoPT: 'Estónia', nomePaisPL: 'Eesti', abrevMoeda: 'EUR'}, //  EE
    {codigo: '818', codigoAlpha2: 'EG', codigoAlpha3: 'EGY', designacao: 'Egypt', designacaoPT: 'Egito', nomePaisPL: 'مصر', abrevMoeda: 'EGP'}, //  EG
    {
      codigo: '732',
      codigoAlpha2: 'EH',
      codigoAlpha3: 'ESH',
      designacao: 'Western Sahara',
      designacaoPT: 'Saara Ocidental',
      nomePaisPL: 'الصحراء الغربية',
      abrevMoeda: 'MAD'
    }, //  EH
    {codigo: '232', codigoAlpha2: 'ER', codigoAlpha3: 'ERI', designacao: 'Eritrea', designacaoPT: 'Eritreia', nomePaisPL: 'ኤርትራ', abrevMoeda: 'ERN'}, //  ER
    {codigo: '724', codigoAlpha2: 'ES', codigoAlpha3: 'ESP', designacao: 'Spain', designacaoPT: 'Espanha', nomePaisPL: 'España', abrevMoeda: 'EUR'}, //  ES
    {codigo: '231', codigoAlpha2: 'ET', codigoAlpha3: 'ETH', designacao: 'Ethiopia', designacaoPT: 'Etiópia', nomePaisPL: 'ኢትዮጵያ', abrevMoeda: 'ETB'}, //  ET
    {codigo: '246', codigoAlpha2: 'FI', codigoAlpha3: 'FIN', designacao: 'Finland', designacaoPT: 'Finlândia', nomePaisPL: 'Suomi', abrevMoeda: 'EUR'}, // FI
    {codigo: '242', codigoAlpha2: 'FJ', codigoAlpha3: 'FJI', designacao: 'Fiji', designacaoPT: 'Fiji', nomePaisPL: 'Viti', abrevMoeda: 'FJD'}, // FJ
    {
      codigo: '238',
      codigoAlpha2: 'FK',
      codigoAlpha3: 'FLK',
      designacao: 'Falkland Islands (Malvinas)',
      designacaoPT: 'Ilhas Malvinas',
      nomePaisPL: 'Falkland Islands',
      abrevMoeda: 'FKP'
    }, // FK
    {
      codigo: '583',
      codigoAlpha2: 'FM',
      codigoAlpha3: 'FSM',
      designacao: 'Micronesia, Federated States of',
      designacaoPT: 'Estados Federados da Micronésia',
      nomePaisPL: 'Micronesia, Federated States of',
      abrevMoeda: 'USD'
    }, // FM
    {codigo: '234', codigoAlpha2: 'FO', codigoAlpha3: 'FRO', designacao: 'Faroe Islands', designacaoPT: 'Ilhas Faroé', nomePaisPL: 'Føroyar', abrevMoeda: 'DKK'}, // FO
    {codigo: '250', codigoAlpha2: 'FR', codigoAlpha3: 'FRA', designacao: 'France', designacaoPT: 'França', nomePaisPL: 'France', abrevMoeda: 'EUR'}, // FR
    {codigo: '266', codigoAlpha2: 'GA', codigoAlpha3: 'GAB', designacao: 'Gabon', designacaoPT: 'Gabão', nomePaisPL: 'Gabon', abrevMoeda: 'XAF'}, // GA
    {codigo: '826', codigoAlpha2: 'GB', codigoAlpha3: 'GBR', designacao: 'United Kingdom', designacaoPT: 'Reino Unido', nomePaisPL: 'United Kingdom', abrevMoeda: 'GBP'}, // GB
    {codigo: '308', codigoAlpha2: 'GD', codigoAlpha3: 'GRD', designacao: 'Grenada', designacaoPT: 'Granada ', nomePaisPL: 'Grenada', abrevMoeda: 'XCD'}, // GD
    {codigo: '268', codigoAlpha2: 'GE', codigoAlpha3: 'GEO', designacao: 'Georgia', designacaoPT: 'Geórgia ', nomePaisPL: 'საქართველო', abrevMoeda: 'GEL'}, // GE
    {
      codigo: '254',
      codigoAlpha2: 'GF',
      codigoAlpha3: 'GUF',
      designacao: 'French Guiana',
      designacaoPT: 'Guiana Francesa',
      nomePaisPL: 'Guyane Française',
      abrevMoeda: 'EUR'
    }, // GF
    {codigo: '831', codigoAlpha2: 'GG', codigoAlpha3: 'GGY', designacao: 'Guernsey', designacaoPT: 'Guérnsei', nomePaisPL: 'Guernsey', abrevMoeda: 'GGP'}, // GG
    {codigo: '288', codigoAlpha2: 'GH', codigoAlpha3: 'GHA', designacao: 'Ghana', designacaoPT: 'Gana', nomePaisPL: 'Ghana', abrevMoeda: 'GHS'}, // GH
    {codigo: '292', codigoAlpha2: 'GI', codigoAlpha3: 'GIB', designacao: 'Gibraltar', designacaoPT: 'Gibraltar', nomePaisPL: 'Gibraltar', abrevMoeda: 'GIP'}, // GI
    {codigo: '304', codigoAlpha2: 'GL', codigoAlpha3: 'GRL', designacao: 'Greenland', designacaoPT: 'Gronelândia', nomePaisPL: 'Kalaallit Nunaat', abrevMoeda: 'DKK'}, // GL
    {codigo: '270', codigoAlpha2: 'GM', codigoAlpha3: 'GMB', designacao: 'Gambia', designacaoPT: 'Gâmbia', nomePaisPL: 'Gambia', abrevMoeda: 'GMD'}, // GM
    {codigo: '324', codigoAlpha2: 'GN', codigoAlpha3: 'GIN', designacao: 'Guinea', designacaoPT: 'Guiné', nomePaisPL: 'Guinée', abrevMoeda: 'GNF'}, // GN
    {codigo: '312', codigoAlpha2: 'GP', codigoAlpha3: 'GLP', designacao: 'Guadeloupe', designacaoPT: 'Guadalupe', nomePaisPL: 'Guadeloupe', abrevMoeda: 'EUR'}, // GP
    {
      codigo: '226',
      codigoAlpha2: 'GQ',
      codigoAlpha3: 'GNQ',
      designacao: 'Equatorial Guinea',
      designacaoPT: 'Guiné Equatorial',
      nomePaisPL: 'Guinea Ecuatorial',
      abrevMoeda: 'XAF'
    }, // GQ
    // Exceção para Grécia, é preconizada pela sigla EL em vez de GR
    {codigo: '300', codigoAlpha2: 'EL', codigoAlpha3: 'GRC', designacao: 'Greece', designacaoPT: 'Grécia', nomePaisPL: 'Ελλάδα', abrevMoeda: 'EUR'}, // EL
    {
      codigo: '239',
      codigoAlpha2: 'GS',
      codigoAlpha3: 'SGS',
      designacao: 'South Georgia and the South Sandwich Islands',
      designacaoPT: 'Ilhas Geórgia do Sul e Sandwich do Sul',
      nomePaisPL: 'South Georgia and the South Sandwich Islands',
      abrevMoeda: 'GBP'
    }, // GS
    {codigo: '320', codigoAlpha2: 'GT', codigoAlpha3: 'GTM', designacao: 'Guatemala', designacaoPT: 'Guatemala', nomePaisPL: 'Guatemala', abrevMoeda: 'GTQ'}, // GT
    {codigo: '316', codigoAlpha2: 'GU', codigoAlpha3: 'GUM', designacao: 'Guam', designacaoPT: 'Guame', nomePaisPL: 'Guåhån', abrevMoeda: 'USD'}, // GU
    {codigo: '624', codigoAlpha2: 'GW', codigoAlpha3: 'GNB', designacao: 'Guinea-Bissau', designacaoPT: 'Guiné-Bissau', nomePaisPL: 'Guinea-Bissau', abrevMoeda: 'XOF'}, // GW
    {codigo: '328', codigoAlpha2: 'GY', codigoAlpha3: 'GUY', designacao: 'Guyana', designacaoPT: 'Guiana', nomePaisPL: 'Guyana', abrevMoeda: 'GYD'}, // GY
    {codigo: '344', codigoAlpha2: 'HK', codigoAlpha3: 'HKG', designacao: 'Hong Kong', designacaoPT: 'Hong Kong', nomePaisPL: 'Hong Kong', abrevMoeda: 'HKD'}, // HK
    {
      codigo: '334',
      codigoAlpha2: 'HM',
      codigoAlpha3: 'HMD',
      designacao: 'Heard Island and McDonald Islands',
      designacaoPT: 'Ilha Heard e as Ilhas McDonald',
      nomePaisPL: 'Heard Island and McDonald Islands',
      abrevMoeda: 'AUD'
    }, // HM
    {codigo: '340', codigoAlpha2: 'HN', codigoAlpha3: 'HND', designacao: 'Honduras', designacaoPT: 'Honduras', nomePaisPL: 'Honduras', abrevMoeda: 'HNL'}, // HN
    {codigo: '191', codigoAlpha2: 'HR', codigoAlpha3: 'HRV', designacao: 'Croatia', designacaoPT: 'Croácia', nomePaisPL: 'Hrvatska', abrevMoeda: 'HRK'}, // HR
    {codigo: '332', codigoAlpha2: 'HT', codigoAlpha3: 'HTI', designacao: 'Haiti', designacaoPT: 'Haiti', nomePaisPL: 'Haïti', abrevMoeda: 'HTG'}, // HT
    {codigo: '348', codigoAlpha2: 'HU', codigoAlpha3: 'HUN', designacao: 'Hungary', designacaoPT: 'Hungria', nomePaisPL: 'Magyarország', abrevMoeda: 'HUF'}, // HU
    {codigo: '360', codigoAlpha2: 'ID', codigoAlpha3: 'IDN', designacao: 'Indonesia', designacaoPT: 'Indonésia', nomePaisPL: 'Indonesia', abrevMoeda: 'IDR'}, // ID
    {codigo: '372', codigoAlpha2: 'IE', codigoAlpha3: 'IRL', designacao: 'Ireland', designacaoPT: 'Irlanda', nomePaisPL: 'Ireland', abrevMoeda: 'EUR'}, // IE
    {codigo: '376', codigoAlpha2: 'IL', codigoAlpha3: 'ISR', designacao: 'Israel', designacaoPT: 'Israel', nomePaisPL: 'מְדִינַת יִשְׂרָאֵל', abrevMoeda: 'ILS'}, // IL
    {codigo: '833', codigoAlpha2: 'IM', codigoAlpha3: 'IMN', designacao: 'Isle of Man', designacaoPT: 'Ilha de Man', nomePaisPL: 'Isle of Man', abrevMoeda: 'IMP'}, // IM
    {codigo: '356', codigoAlpha2: 'IN', codigoAlpha3: 'IND', designacao: 'India', designacaoPT: 'Índia', nomePaisPL: 'Bharat Ganrajya', abrevMoeda: 'INR'}, // IN
    {
      codigo: '086',
      codigoAlpha2: 'IO',
      codigoAlpha3: 'IOT',
      designacao: 'British Indian Ocean Territory',
      designacaoPT: 'Território Britânico do Oceano Índico',
      nomePaisPL: 'British Indian Ocean Territory',
      abrevMoeda: 'GBP'
    }, // IO
    {codigo: '368', codigoAlpha2: 'IQ', codigoAlpha3: 'IRQ', designacao: 'Iraq', designacaoPT: 'Iraque', nomePaisPL: 'العراق', abrevMoeda: 'IQD'}, // IQ
    {
      codigo: '364',
      codigoAlpha2: 'IR',
      codigoAlpha3: 'IRN',
      designacao: 'Iran, Islamic Republic of',
      designacaoPT: 'Irão',
      nomePaisPL: 'جمهوری اسلامی ایران',
      abrevMoeda: 'IRR'
    }, // IR
    {codigo: '352', codigoAlpha2: 'IS', codigoAlpha3: 'ISL', designacao: 'Iceland', designacaoPT: 'Islândia', nomePaisPL: 'Ísland', abrevMoeda: 'ISK'}, // IS
    {codigo: '380', codigoAlpha2: 'IT', codigoAlpha3: 'ITA', designacao: 'Italy', designacaoPT: 'Itália', nomePaisPL: 'Italia', abrevMoeda: 'EUR'}, // IT
    {codigo: '832', codigoAlpha2: 'JE', codigoAlpha3: 'JEY', designacao: 'Jersey', designacaoPT: 'Jersey', nomePaisPL: 'Jersey', abrevMoeda: 'JEP'}, // JE
    {codigo: '388', codigoAlpha2: 'JM', codigoAlpha3: 'JAM', designacao: 'Jamaica', designacaoPT: 'Jamaica', nomePaisPL: 'Jamaica', abrevMoeda: 'JMD'}, // JM
    {codigo: '400', codigoAlpha2: 'JO', codigoAlpha3: 'JOR', designacao: 'Jordan', designacaoPT: 'Jordânia', nomePaisPL: 'اَلأُرْدُنّ', abrevMoeda: 'JOD'}, // JO
    {codigo: '392', codigoAlpha2: 'JP', codigoAlpha3: 'JPN', designacao: 'Japan', designacaoPT: 'Japão', nomePaisPL: '日本', abrevMoeda: 'JPY'}, // JP
    {codigo: '404', codigoAlpha2: 'KE', codigoAlpha3: 'KEN', designacao: 'Kenya', designacaoPT: 'Quênia', nomePaisPL: 'Kenya', abrevMoeda: 'KES'}, // KE
    {codigo: '417', codigoAlpha2: 'KG', codigoAlpha3: 'KGZ', designacao: 'Kyrgyzstan', designacaoPT: 'Quirguistão', nomePaisPL: 'Кыргызстан', abrevMoeda: 'KGS'}, // KG
    {codigo: '116', codigoAlpha2: 'KH', codigoAlpha3: 'KHM', designacao: 'Cambodia', designacaoPT: 'Camboja', nomePaisPL: 'ព្រះរាជាណាចក្រកម្ពុជា', abrevMoeda: 'KHR'}, // KH
    {codigo: '296', codigoAlpha2: 'KI', codigoAlpha3: 'KIR', designacao: 'Kiribati', designacaoPT: 'Quiribáti', nomePaisPL: 'Kiribati', abrevMoeda: 'AUD'}, // KI
    {codigo: '174', codigoAlpha2: 'KM', codigoAlpha3: 'COM', designacao: 'Comoros', designacaoPT: 'União das Comores', nomePaisPL: 'Udzima wa Komori', abrevMoeda: 'KMF'}, // KM
    {
      codigo: '659',
      codigoAlpha2: 'KN',
      codigoAlpha3: 'KNA',
      designacao: 'Saint Kitts and Nevis',
      designacaoPT: 'São Cristóvão e Nevis',
      nomePaisPL: 'Saint Kitts and Nevis',
      abrevMoeda: 'XCD'
    }, // KN
    {
      codigo: '408',
      codigoAlpha2: 'KP',
      codigoAlpha3: 'PRK',
      designacao: "Korea, Democratic People's Republic of",
      designacaoPT: 'Coreia do Norte',
      nomePaisPL: 'North Korea',
      abrevMoeda: 'KPW'
    }, // KP
    {
      codigo: '410',
      codigoAlpha2: 'KR',
      codigoAlpha3: 'KOR',
      designacao: 'Korea, Republic of',
      designacaoPT: 'Coreia do Sul',
      nomePaisPL: 'South Korea',
      abrevMoeda: 'KRW'
    }, // KR
    {codigo: '414', codigoAlpha2: 'KW', codigoAlpha3: 'KWT', designacao: 'Kuwait', designacaoPT: 'Koweit', nomePaisPL: 'دولة الكويت', abrevMoeda: 'KWD'}, // KW
    {codigo: '136', codigoAlpha2: 'KY', codigoAlpha3: 'CYM', designacao: 'Cayman Islands', designacaoPT: 'Ilhas Caimão', nomePaisPL: 'Cayman Islands', abrevMoeda: 'KYD'}, // KY
    {codigo: '398', codigoAlpha2: 'KZ', codigoAlpha3: 'KAZ', designacao: 'Kazakhstan', designacaoPT: 'Cazaquistão', nomePaisPL: 'Қазақстан Qazaqstan', abrevMoeda: 'KZT'}, // KZ
    {
      codigo: '418',
      codigoAlpha2: 'LA',
      codigoAlpha3: 'LAO',
      designacao: "Lao People's Democratic Republic",
      designacaoPT: 'Laos',
      nomePaisPL: 'ສາທາລະນະລັດ ປະຊາທິປະໄຕ ປະຊາຊົນລາວ',
      abrevMoeda: 'LAK'
    }, // LA
    {codigo: '422', codigoAlpha2: 'LB', codigoAlpha3: 'LBN', designacao: 'Lebanon', designacaoPT: 'Líbano', nomePaisPL: 'لبنان', abrevMoeda: 'LBP'}, // LB
    {codigo: '662', codigoAlpha2: 'LC', codigoAlpha3: 'LCA', designacao: 'Saint Lucia', designacaoPT: 'Santa Lúcia', nomePaisPL: 'Saint Lucia', abrevMoeda: 'XCD'}, // LC
    {
      codigo: '438',
      codigoAlpha2: 'LI',
      codigoAlpha3: 'LIE',
      designacao: 'Liechtenstein',
      designacaoPT: 'Liechtenstein',
      nomePaisPL: 'Fürstentum Liechtenstein',
      abrevMoeda: 'CHF'
    }, // LI
    {codigo: '144', codigoAlpha2: 'LK', codigoAlpha3: 'LKA', designacao: 'Sri Lanka', designacaoPT: 'Sri Lanka', nomePaisPL: 'ශ්‍රී ලංකාව', abrevMoeda: 'LKR'}, // LK
    {codigo: '430', codigoAlpha2: 'LR', codigoAlpha3: 'LBR', designacao: 'Liberia', designacaoPT: 'Libéria', nomePaisPL: 'Liberia', abrevMoeda: 'LRD'}, // LR
    {codigo: '426', codigoAlpha2: 'LS', codigoAlpha3: 'LSO', designacao: 'Lesotho', designacaoPT: 'Lesoto', nomePaisPL: 'Lesotho', abrevMoeda: 'LSL'}, // LS
    {codigo: '440', codigoAlpha2: 'LT', codigoAlpha3: 'LTU', designacao: 'Lithuania', designacaoPT: 'Lituânia', nomePaisPL: 'Lietuva', abrevMoeda: 'LTL'}, // LT
    {codigo: '442', codigoAlpha2: 'LU', codigoAlpha3: 'LUX', designacao: 'Luxembourg', designacaoPT: 'Luxemburgo', nomePaisPL: 'Lëtzebuerg', abrevMoeda: 'EUR'}, // LU
    {codigo: '428', codigoAlpha2: 'LV', codigoAlpha3: 'LVA', designacao: 'Latvia', designacaoPT: 'Letónia', nomePaisPL: 'Latvija', abrevMoeda: 'LVL'}, // LV
    {codigo: '434', codigoAlpha2: 'LY', codigoAlpha3: 'LBY', designacao: 'Libyan Arab Jamahiriya', designacaoPT: 'Líbia', nomePaisPL: 'ليبيا', abrevMoeda: 'LYD'}, // LY
    {codigo: '504', codigoAlpha2: 'MA', codigoAlpha3: 'MAR', designacao: 'Morocco', designacaoPT: 'Marrocos', nomePaisPL: 'المغرب', abrevMoeda: 'MAD'}, // MA
    {codigo: '492', codigoAlpha2: 'MC', codigoAlpha3: 'MCO', designacao: 'Monaco', designacaoPT: 'Mónaco', nomePaisPL: 'Monaco', abrevMoeda: 'EUR'}, // MC
    {codigo: '498', codigoAlpha2: 'MD', codigoAlpha3: 'MDA', designacao: 'Moldova, Republic of', designacaoPT: 'Moldávia', nomePaisPL: 'Moldova', abrevMoeda: 'MDL'}, // MD
    {codigo: '499', codigoAlpha2: 'ME', codigoAlpha3: 'MNE', designacao: 'Montenegro', designacaoPT: 'Montenegro', nomePaisPL: 'Crna Gora', abrevMoeda: 'EUR'}, // ME
    {
      codigo: '663',
      codigoAlpha2: 'MF',
      codigoAlpha3: 'MAF',
      designacao: 'Saint Martin (French part)',
      designacaoPT: 'São Martinho (Parte Francesa)',
      nomePaisPL: 'Saint Martin',
      abrevMoeda: 'EUR'
    }, // MF
    {codigo: '450', codigoAlpha2: 'MG', codigoAlpha3: 'MDG', designacao: 'Madagascar', designacaoPT: 'Madagáscar', nomePaisPL: 'Madagasikara', abrevMoeda: 'MGA'}, // MG
    {
      codigo: '584',
      codigoAlpha2: 'MH',
      codigoAlpha3: 'MHL',
      designacao: 'Marshall Islands',
      designacaoPT: 'Ilhas Marshall',
      nomePaisPL: 'Marshall Islands',
      abrevMoeda: 'USD'
    }, // MH
    {
      codigo: '807',
      codigoAlpha2: 'MK',
      codigoAlpha3: 'MKD',
      designacao: 'Macedonia, the former Yugoslav Republic of',
      designacaoPT: 'República da Macedónia',
      nomePaisPL: 'Македонија',
      abrevMoeda: 'MKD'
    }, // MK
    {codigo: '466', codigoAlpha2: 'ML', codigoAlpha3: 'MLI', designacao: 'Mali', designacaoPT: 'Mali', nomePaisPL: 'Mali', abrevMoeda: 'XOF'}, // ML
    {codigo: '104', codigoAlpha2: 'MM', codigoAlpha3: 'MMR', designacao: 'Myanmar', designacaoPT: 'Birmânia', nomePaisPL: 'Myanmar', abrevMoeda: 'MMK'}, // MM
    {codigo: '496', codigoAlpha2: 'MN', codigoAlpha3: 'MNG', designacao: 'Mongolia', designacaoPT: 'Mongólia', nomePaisPL: 'Монгол улс', abrevMoeda: 'MNT'}, // MN
    {codigo: '446', codigoAlpha2: 'MO', codigoAlpha3: 'MAC', designacao: 'Macao', designacaoPT: 'Macau', nomePaisPL: '澳門', abrevMoeda: 'MOP'}, // MO
    {
      codigo: '580',
      codigoAlpha2: 'MP',
      codigoAlpha3: 'MNP',
      designacao: 'Northern Mariana Islands',
      designacaoPT: 'Ilhas Marianas do Norte',
      nomePaisPL: 'Sankattan Siha Na Islas Mariånas',
      abrevMoeda: 'USD'
    }, // MP
    {codigo: '474', codigoAlpha2: 'MQ', codigoAlpha3: 'MTQ', designacao: 'Martinique', designacaoPT: 'Martinica', nomePaisPL: 'Martinique', abrevMoeda: 'EUR'}, // MQ
    {codigo: '478', codigoAlpha2: 'MR', codigoAlpha3: 'MRT', designacao: 'Mauritania', designacaoPT: 'Mauritânia', nomePaisPL: 'موريتانيا', abrevMoeda: 'MRO'}, // MR
    {codigo: '500', codigoAlpha2: 'MS', codigoAlpha3: 'MSR', designacao: 'Montserrat', designacaoPT: 'Montserrat', nomePaisPL: 'Montserrat', abrevMoeda: 'XCD'}, // MS
    {codigo: '470', codigoAlpha2: 'MT', codigoAlpha3: 'MLT', designacao: 'Malta', designacaoPT: 'Malta', nomePaisPL: 'Malta', abrevMoeda: 'EUR'}, // MT
    {codigo: '480', codigoAlpha2: 'MU', codigoAlpha3: 'MUS', designacao: 'Mauritius', designacaoPT: 'Maurícia', nomePaisPL: 'Maurice', abrevMoeda: 'MUR'}, // MU
    {
      codigo: '462',
      codigoAlpha2: 'MV',
      codigoAlpha3: 'MDV',
      designacao: 'Maldives',
      designacaoPT: 'Maldivas',
      nomePaisPL: 'ދިވެހިރާއްޖޭގެ ޖުމްހޫރިއްޔާ',
      abrevMoeda: 'MVR'
    }, // MV
    {codigo: '454', codigoAlpha2: 'MW', codigoAlpha3: 'MWI', designacao: 'Malawi', designacaoPT: 'Malawi', nomePaisPL: 'Malawi', abrevMoeda: 'MWK'}, // MW
    {codigo: '484', codigoAlpha2: 'MX', codigoAlpha3: 'MEX', designacao: 'Mexico', designacaoPT: 'México', nomePaisPL: 'Mexico', abrevMoeda: 'MXN'}, // MX
    {codigo: '458', codigoAlpha2: 'MY', codigoAlpha3: 'MYS', designacao: 'Malaysia', designacaoPT: 'Malásia', nomePaisPL: 'Malaysia', abrevMoeda: 'MYR'}, // MY
    {codigo: '508', codigoAlpha2: 'MZ', codigoAlpha3: 'MOZ', designacao: 'Mozambique', designacaoPT: 'Moçambique', nomePaisPL: 'Moçambique', abrevMoeda: 'MZN'}, // MZ
    {codigo: '516', codigoAlpha2: 'NA', codigoAlpha3: 'NAM', designacao: 'Namibia', designacaoPT: 'Namíbia', nomePaisPL: 'Namibia', abrevMoeda: 'NAD'}, //  NA
    {
      codigo: '540',
      codigoAlpha2: 'NC',
      codigoAlpha3: 'NCL',
      designacao: 'New Caledonia',
      designacaoPT: 'Nova Caledónia',
      nomePaisPL: 'Nouvelle-Calédonie',
      abrevMoeda: 'XPF'
    }, //  NC
    {codigo: '562', codigoAlpha2: 'NE', codigoAlpha3: 'NER', designacao: 'Niger', designacaoPT: 'Níger', nomePaisPL: 'Niger', abrevMoeda: 'XOF'}, //  NE
    {codigo: '574', codigoAlpha2: 'NF', codigoAlpha3: 'NFK', designacao: 'Norfolk Island', designacaoPT: 'Ilha Norfolk', nomePaisPL: 'Norfolk Island', abrevMoeda: 'AUD'}, //  NF
    {codigo: '566', codigoAlpha2: 'NG', codigoAlpha3: 'NGA', designacao: 'Nigeria', designacaoPT: 'Nigéria', nomePaisPL: 'Nigeria', abrevMoeda: 'NGN'}, //  NG
    {codigo: '558', codigoAlpha2: 'NI', codigoAlpha3: 'NIC', designacao: 'Nicaragua', designacaoPT: 'Nicarágua', nomePaisPL: 'Nicaragua', abrevMoeda: 'NIO'}, //  NI
    {codigo: '528', codigoAlpha2: 'NL', codigoAlpha3: 'NLD', designacao: 'Netherlands', designacaoPT: 'Holanda', nomePaisPL: 'Nederland ', abrevMoeda: 'EUR'}, //  NL
    {codigo: '578', codigoAlpha2: 'NO', codigoAlpha3: 'NOR', designacao: 'Norway', designacaoPT: 'Noruega', nomePaisPL: 'Norge', abrevMoeda: 'NOK'}, //  NO
    {codigo: '524', codigoAlpha2: 'NP', codigoAlpha3: 'NPL', designacao: 'Nepal', designacaoPT: 'Nepal', nomePaisPL: 'नेपाल ', abrevMoeda: 'NPR'}, //  NP
    {codigo: '520', codigoAlpha2: 'NR', codigoAlpha3: 'NRU', designacao: 'Nauru', designacaoPT: 'Nauru', nomePaisPL: 'Nauru', abrevMoeda: 'AUD'}, //  NR
    {codigo: '570', codigoAlpha2: 'NU', codigoAlpha3: 'NIU', designacao: 'Niue', designacaoPT: 'Niue', nomePaisPL: 'Niuē', abrevMoeda: 'NZD'}, //  NU
    {codigo: '554', codigoAlpha2: 'NZ', codigoAlpha3: 'NZL', designacao: 'New Zealand', designacaoPT: 'Nova Zelândia', nomePaisPL: 'New Zealand', abrevMoeda: 'NZD'}, //  NZ
    {codigo: '512', codigoAlpha2: 'OM', codigoAlpha3: 'OMN', designacao: 'Oman', designacaoPT: 'Omã', nomePaisPL: 'عمان', abrevMoeda: 'OMR'}, // OM
    {codigo: '591', codigoAlpha2: 'PA', codigoAlpha3: 'PAN', designacao: 'Panama', designacaoPT: 'Panamá', nomePaisPL: 'Panamá', abrevMoeda: 'PAB'}, // PA
    {codigo: '604', codigoAlpha2: 'PE', codigoAlpha3: 'PER', designacao: 'Peru', designacaoPT: 'Peru', nomePaisPL: 'Peru', abrevMoeda: 'PEN'}, // PE
    {
      codigo: '258',
      codigoAlpha2: 'PF',
      codigoAlpha3: 'PYF',
      designacao: 'French Polynesia',
      designacaoPT: 'Polinésia Francesa',
      nomePaisPL: 'Polynésie Française',
      abrevMoeda: 'XPF'
    }, // PF
    {
      codigo: '598',
      codigoAlpha2: 'PG',
      codigoAlpha3: 'PNG',
      designacao: 'Papua New Guinea',
      designacaoPT: 'Papua-Nova Guiné',
      nomePaisPL: 'Papua New Guinea',
      abrevMoeda: 'PGK'
    }, // PG
    {codigo: '608', codigoAlpha2: 'PH', codigoAlpha3: 'PHL', designacao: 'Philippines', designacaoPT: 'Filipinas', nomePaisPL: 'Pilipínas', abrevMoeda: 'PHP'}, // PH
    {codigo: '586', codigoAlpha2: 'PK', codigoAlpha3: 'PAK', designacao: 'Pakistan', designacaoPT: 'Paquistão', nomePaisPL: 'پاكِستان', abrevMoeda: 'PKR'}, // PK
    {codigo: '616', codigoAlpha2: 'PL', codigoAlpha3: 'POL', designacao: 'Poland', designacaoPT: 'Polónia', nomePaisPL: 'Polska', abrevMoeda: 'PLN'}, // PL
    {
      codigo: '666',
      codigoAlpha2: 'PM',
      codigoAlpha3: 'SPM',
      designacao: 'Saint Pierre and Miquelon',
      designacaoPT: 'São Pedro e Miquelão',
      nomePaisPL: 'Saint-Pierre-et-Miquelon',
      abrevMoeda: 'EUR'
    }, // PM
    {codigo: '612', codigoAlpha2: 'PN', codigoAlpha3: 'PCN', designacao: 'Pitcairn', designacaoPT: 'Ilhas Pitcairn', nomePaisPL: 'Pitcairn', abrevMoeda: 'NZD'}, // PN
    {codigo: '630', codigoAlpha2: 'PR', codigoAlpha3: 'PRI', designacao: 'Puerto Rico', designacaoPT: 'Porto Rico', nomePaisPL: 'Puerto Rico', abrevMoeda: 'USD'}, // PR
    {
      codigo: '275',
      codigoAlpha2: 'PS',
      codigoAlpha3: 'PSE',
      designacao: 'Palestinian Territory, Occupied',
      designacaoPT: 'Territórios Palestinianos',
      nomePaisPL: 'Palestinian Territory, Occupied',
      abrevMoeda: ''
    }, // PS
    {codigo: '620', codigoAlpha2: 'PT', codigoAlpha3: 'PRT', designacao: 'Portugal', designacaoPT: 'Portugal', nomePaisPL: 'Portugal', abrevMoeda: 'EUR'}, //  PT
    {codigo: '585', codigoAlpha2: 'PW', codigoAlpha3: 'PLW', designacao: 'Palau', designacaoPT: 'Palau', nomePaisPL: 'Beluu er a Belau', abrevMoeda: 'USD'}, //  PW
    {codigo: '600', codigoAlpha2: 'PY', codigoAlpha3: 'PRY', designacao: 'Paraguay', designacaoPT: 'Paraguai', nomePaisPL: 'Paraguay', abrevMoeda: 'PYG'}, //  PY
    {codigo: '634', codigoAlpha2: 'QA', codigoAlpha3: 'QAT', designacao: 'Qatar', designacaoPT: 'Catar', nomePaisPL: 'قطر', abrevMoeda: 'QAR'}, //  QA
    {codigo: '638', codigoAlpha2: 'RE', codigoAlpha3: 'REU', designacao: 'Réunion', designacaoPT: 'Reunião', nomePaisPL: 'La Réunion', abrevMoeda: 'EUR'}, //  RE
    {codigo: '642', codigoAlpha2: 'RO', codigoAlpha3: 'ROU', designacao: 'Romania', designacaoPT: 'Roménia', nomePaisPL: 'România', abrevMoeda: 'RON'}, //  RO
    {codigo: '688', codigoAlpha2: 'RS', codigoAlpha3: 'SRB', designacao: 'Serbia', designacaoPT: 'Sérvia', nomePaisPL: 'Србија', abrevMoeda: 'RSD'}, //  RS
    {codigo: '643', codigoAlpha2: 'RU', codigoAlpha3: 'RUS', designacao: 'Russian Federation', designacaoPT: 'Rússia', nomePaisPL: 'Россия', abrevMoeda: 'RUB'}, //  RU
    {codigo: '646', codigoAlpha2: 'RW', codigoAlpha3: 'RWA', designacao: 'Rwanda', designacaoPT: 'Ruanda', nomePaisPL: 'Rwanda', abrevMoeda: 'RWF'}, //  RW
    {codigo: '682', codigoAlpha2: 'SA', codigoAlpha3: 'SAU', designacao: 'Saudi Arabia', designacaoPT: 'Arábia Saudita', nomePaisPL: 'السعودية', abrevMoeda: 'SAR'}, //  SA
    {
      codigo: '090',
      codigoAlpha2: 'SB',
      codigoAlpha3: 'SLB',
      designacao: 'Solomon Islands',
      designacaoPT: 'Ilhas Salomão',
      nomePaisPL: 'Solomon Islands',
      abrevMoeda: 'SBD'
    }, //  SB
    {codigo: '690', codigoAlpha2: 'SC', codigoAlpha3: 'SYC', designacao: 'Seychelles', designacaoPT: 'Seicheles', nomePaisPL: 'Seychelles', abrevMoeda: 'SCR'}, //  SC
    {codigo: '736', codigoAlpha2: 'SD', codigoAlpha3: 'SDN', designacao: 'Sudan', designacaoPT: 'Sudão', nomePaisPL: 'السودان', abrevMoeda: 'SDG'}, //  SD
    {codigo: '752', codigoAlpha2: 'SE', codigoAlpha3: 'SWE', designacao: 'Sweden', designacaoPT: 'Suécia', nomePaisPL: 'Sverige', abrevMoeda: 'SEK'}, //  SE
    {codigo: '702', codigoAlpha2: 'SG', codigoAlpha3: 'SGP', designacao: 'Singapore', designacaoPT: 'Singapura', nomePaisPL: 'Singapore', abrevMoeda: 'SGD'}, //  SG
    {
      codigo: '654',
      codigoAlpha2: 'SH',
      codigoAlpha3: 'SHN',
      designacao: 'Saint Helena, Ascension and Tristan da Cunha',
      designacaoPT: 'Santa Helena, Ascensão e Tristão da Cunha',
      nomePaisPL: 'Saint Helena, Ascension and Tristan da Cunha',
      abrevMoeda: 'SHP'
    }, //  SH
    {codigo: '705', codigoAlpha2: 'SI', codigoAlpha3: 'SVN', designacao: 'Slovenia', designacaoPT: 'Eslovénia', nomePaisPL: 'Slovenija', abrevMoeda: 'EUR'}, //  SI
    {
      codigo: '744',
      codigoAlpha2: 'SJ',
      codigoAlpha3: 'SJM',
      designacao: 'Svalbard and Jan Mayen',
      designacaoPT: 'Svalbard e Jan Mayen',
      nomePaisPL: 'Svalbard og Jan Mayen',
      abrevMoeda: 'NOK'
    }, //  SJ
    {codigo: '703', codigoAlpha2: 'SK', codigoAlpha3: 'SVK', designacao: 'Slovakia', designacaoPT: 'Eslováquia', nomePaisPL: 'Slovensko', abrevMoeda: 'EUR'}, //  SK
    {codigo: '694', codigoAlpha2: 'SL', codigoAlpha3: 'SLE', designacao: 'Sierra Leone', designacaoPT: 'Serra Leoa', nomePaisPL: 'Sierra Leone', abrevMoeda: 'SLL'}, //  SL
    {codigo: '674', codigoAlpha2: 'SM', codigoAlpha3: 'SMR', designacao: 'San Marino', designacaoPT: 'São Marino', nomePaisPL: 'San Marino', abrevMoeda: 'EUR'}, //  SM
    {codigo: '686', codigoAlpha2: 'SN', codigoAlpha3: 'SEN', designacao: 'Senegal', designacaoPT: 'Senegal', nomePaisPL: 'Le Sénégal', abrevMoeda: 'XOF'}, //  SN
    {codigo: '706', codigoAlpha2: 'SO', codigoAlpha3: 'SOM', designacao: 'Somalia', designacaoPT: 'Somália', nomePaisPL: 'Soomaaliya', abrevMoeda: 'SOS'}, //  SO
    {codigo: '740', codigoAlpha2: 'SR', codigoAlpha3: 'SUR', designacao: 'Suriname', designacaoPT: 'Suriname', nomePaisPL: 'Suriname', abrevMoeda: 'SRD'}, //  SR
    {
      codigo: '678',
      codigoAlpha2: 'ST',
      codigoAlpha3: 'STP',
      designacao: 'Sao Tome and Principe',
      designacaoPT: 'São Tomé e Príncipe',
      nomePaisPL: 'São Tomé e Príncipe',
      abrevMoeda: 'STD'
    }, //  ST
    {codigo: '222', codigoAlpha2: 'SV', codigoAlpha3: 'SLV', designacao: 'El Salvador', designacaoPT: 'Salvador', nomePaisPL: 'El Salvador', abrevMoeda: 'SVC'}, //  SV
    {codigo: '760', codigoAlpha2: 'SY', codigoAlpha3: 'SYR', designacao: 'Syrian Arab Republic', designacaoPT: 'Síria', nomePaisPL: 'سوريا', abrevMoeda: 'SYP'}, //  SY
    {codigo: '748', codigoAlpha2: 'SZ', codigoAlpha3: 'SWZ', designacao: 'Swaziland', designacaoPT: 'Suazilândia', nomePaisPL: 'Umbuso weSwatini', abrevMoeda: 'SZL'}, //  SZ
    {
      codigo: '796',
      codigoAlpha2: 'TC',
      codigoAlpha3: 'TCA',
      designacao: 'Turks and Caicos Islands',
      designacaoPT: 'Turcas e Caicos',
      nomePaisPL: 'Turks and Caicos Islands',
      abrevMoeda: 'USD'
    }, //  TC
    {codigo: '148', codigoAlpha2: 'TD', codigoAlpha3: 'TCD', designacao: 'Chad', designacaoPT: 'Chade', nomePaisPL: 'تشاد', abrevMoeda: 'XAF'}, //  TD
    {
      codigo: '260',
      codigoAlpha2: 'TF',
      codigoAlpha3: 'ATF',
      designacao: 'French Southern Territories',
      designacaoPT: 'Terras Austrais e Antárticas Francesas',
      nomePaisPL: 'Terres Australes et Antarctiques Françaises',
      abrevMoeda: 'EUR'
    }, //  TF
    {codigo: '768', codigoAlpha2: 'TG', codigoAlpha3: 'TGO', designacao: 'Togo', designacaoPT: 'Togo', nomePaisPL: 'Togo', abrevMoeda: 'XOF'}, //  TG
    {codigo: '764', codigoAlpha2: 'TH', codigoAlpha3: 'THA', designacao: 'Thailand', designacaoPT: 'Tailândia', nomePaisPL: 'ประเทศไทย', abrevMoeda: 'THB'}, //  TH
    {codigo: '762', codigoAlpha2: 'TJ', codigoAlpha3: 'TJK', designacao: 'Tajikistan', designacaoPT: 'Tajiquistão', nomePaisPL: 'Тоҷикистон', abrevMoeda: 'TJS'}, //  TJ
    {codigo: '772', codigoAlpha2: 'TK', codigoAlpha3: 'TKL', designacao: 'Tokelau', designacaoPT: 'Toquelau', nomePaisPL: 'Tokelau', abrevMoeda: 'NZD'}, //  TK
    {codigo: '626', codigoAlpha2: 'TL', codigoAlpha3: 'TLS', designacao: 'Timor-Leste', designacaoPT: 'Timor-Leste', nomePaisPL: 'Timor-Leste', abrevMoeda: 'USD'}, //  TL
    {codigo: '795', codigoAlpha2: 'TM', codigoAlpha3: 'TKM', designacao: 'Turkmenistan', designacaoPT: 'Turquemenistão', nomePaisPL: 'Türkmenistan', abrevMoeda: 'TMT'}, //  TM
    {codigo: '788', codigoAlpha2: 'TN', codigoAlpha3: 'TUN', designacao: 'Tunisia', designacaoPT: 'Tunísia', nomePaisPL: 'تونس', abrevMoeda: 'TND'}, //  TN
    {codigo: '776', codigoAlpha2: 'TO', codigoAlpha3: 'TON', designacao: 'Tonga', designacaoPT: 'Tonga', nomePaisPL: 'Tonga', abrevMoeda: 'TOP'}, //  TO
    {codigo: '792', codigoAlpha2: 'TR', codigoAlpha3: 'TUR', designacao: 'Turkey', designacaoPT: 'Turquia', nomePaisPL: 'Türkiye', abrevMoeda: 'TRY'}, //  TR
    {
      codigo: '780',
      codigoAlpha2: 'TT',
      codigoAlpha3: 'TTO',
      designacao: 'Trinidad and Tobago',
      designacaoPT: 'Trindade e Tobago',
      nomePaisPL: 'Trinidad and Tobago',
      abrevMoeda: 'TTD'
    }, //  TT
    {codigo: '798', codigoAlpha2: 'TV', codigoAlpha3: 'TUV', designacao: 'Tuvalu', designacaoPT: 'Tuvalu', nomePaisPL: 'Tuvalu', abrevMoeda: 'TVD'}, //  TV
    {codigo: '158', codigoAlpha2: 'TW', codigoAlpha3: 'TWN', designacao: 'Taiwan, Province of China', designacaoPT: 'Taiwan', nomePaisPL: '臺灣', abrevMoeda: 'TWD'}, //  TW
    {
      codigo: '834',
      codigoAlpha2: 'TZ',
      codigoAlpha3: 'TZA',
      designacao: 'Tanzania, United Republic of',
      designacaoPT: 'Tanzânia',
      nomePaisPL: 'Tanzania',
      abrevMoeda: 'TZS'
    }, //  TZ
    {codigo: '804', codigoAlpha2: 'UA', codigoAlpha3: 'UKR', designacao: 'Ukraine', designacaoPT: 'Ucrânia', nomePaisPL: 'Україна', abrevMoeda: 'UAH'}, //  UA
    {codigo: '800', codigoAlpha2: 'UG', codigoAlpha3: 'UGA', designacao: 'Uganda', designacaoPT: 'Uganda', nomePaisPL: 'Uganda', abrevMoeda: 'UGX'}, //  UG
    {
      codigo: '581',
      codigoAlpha2: 'UM',
      codigoAlpha3: 'UMI',
      designacao: 'United States Minor Outlying Islands',
      designacaoPT: 'Ilhas Menores Distantes dos Estados Unidos',
      nomePaisPL: 'United States Minor Outlying Islands',
      abrevMoeda: 'USD'
    }, //  UM
    {
      codigo: '840',
      codigoAlpha2: 'US',
      codigoAlpha3: 'USA',
      designacao: 'United States',
      designacaoPT: 'Estados Unidos da América',
      nomePaisPL: 'United States of America',
      abrevMoeda: 'USD'
    }, //  US
    {codigo: '858', codigoAlpha2: 'UY', codigoAlpha3: 'URY', designacao: 'Uruguay', designacaoPT: 'Uruguai', nomePaisPL: 'Uruguay', abrevMoeda: 'UYU'}, //  UY
    {codigo: '860', codigoAlpha2: 'UZ', codigoAlpha3: 'UZB', designacao: 'Uzbekistan', designacaoPT: 'Usbequistão', nomePaisPL: 'O‘zbekiston', abrevMoeda: 'UZS'}, //  UZ
    {
      codigo: '336',
      codigoAlpha2: 'VA',
      codigoAlpha3: 'VAT',
      designacao: 'Holy See (Vatican City State)',
      designacaoPT: 'Estado da Cidade do Vaticano',
      nomePaisPL: 'Stato della Città del Vaticano',
      abrevMoeda: 'EUR'
    }, //  VA
    {
      codigo: '670',
      codigoAlpha2: 'VC',
      codigoAlpha3: 'VCT',
      designacao: 'Saint Vincent and the Grenadines',
      designacaoPT: 'São Vicente e Granadinas',
      nomePaisPL: 'Saint Vincent and the Grenadines',
      abrevMoeda: 'XCD'
    }, //  VC
    {
      codigo: '862',
      codigoAlpha2: 'VE',
      codigoAlpha3: 'VEN',
      designacao: 'Venezuela, Bolivarian Republic of',
      designacaoPT: 'Venezuela',
      nomePaisPL: 'Venezuela',
      abrevMoeda: 'VEF'
    }, //  VE
    {
      codigo: '092',
      codigoAlpha2: 'VG',
      codigoAlpha3: 'VGB',
      designacao: 'Virgin Islands, British',
      designacaoPT: 'Ilhas Virgens Britânicas',
      nomePaisPL: 'Virgin Islands, British',
      abrevMoeda: 'USD'
    }, //  VG
    {
      codigo: '850',
      codigoAlpha2: 'VI',
      codigoAlpha3: 'VIR',
      designacao: 'Virgin Islands, U.S.',
      designacaoPT: 'Ilhas Virgens Americanas',
      nomePaisPL: 'Virgin Islands, U.S.',
      abrevMoeda: 'USD'
    }, //  VI
    {codigo: '704', codigoAlpha2: 'VN', codigoAlpha3: 'VNM', designacao: 'Viet Nam', designacaoPT: 'Vietname', nomePaisPL: 'Việt Nam', abrevMoeda: 'VND'}, //  VN
    {codigo: '548', codigoAlpha2: 'VU', codigoAlpha3: 'VUT', designacao: 'Vanuatu', designacaoPT: 'Vanuatu', nomePaisPL: 'Vanuatu', abrevMoeda: 'VUV'}, //  VU
    {
      codigo: '876',
      codigoAlpha2: 'WF',
      codigoAlpha3: 'WLF',
      designacao: 'Wallis and Futuna',
      designacaoPT: 'Wallis e Futuna',
      nomePaisPL: 'Wallis et Futuna',
      abrevMoeda: 'XPF'
    }, //  WF
    {codigo: '882', codigoAlpha2: 'WS', codigoAlpha3: 'WSM', designacao: 'Samoa', designacaoPT: 'Samoa', nomePaisPL: 'Sāmoa', abrevMoeda: 'WST'}, //  WS
    {codigo: '887', codigoAlpha2: 'YE', codigoAlpha3: 'YEM', designacao: 'Yemen', designacaoPT: 'Iémen', nomePaisPL: 'الجمهورية اليمنية', abrevMoeda: 'YER'}, //  YE
    {codigo: '175', codigoAlpha2: 'YT', codigoAlpha3: 'MYT', designacao: 'Mayotte', designacaoPT: 'Maiote', nomePaisPL: 'Mayotte', abrevMoeda: 'EUR'}, //  YT
    {codigo: '710', codigoAlpha2: 'ZA', codigoAlpha3: 'ZAF', designacao: 'South Africa', designacaoPT: 'África do Sul', nomePaisPL: 'South Africa', abrevMoeda: 'ZAR'}, //  ZA
    {codigo: '894', codigoAlpha2: 'ZM', codigoAlpha3: 'ZMB', designacao: 'Zambia', designacaoPT: 'Zâmbia', nomePaisPL: 'Zambia', abrevMoeda: 'ZMW'}, //  ZM
    {codigo: '716', codigoAlpha2: 'ZW', codigoAlpha3: 'ZWE', designacao: 'Zimbabwe', designacaoPT: 'Zimbabué', nomePaisPL: 'Zimbabwe', abrevMoeda: 'ZWD'}, //  ZM
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'AAA',
      designacao: 'Organização de Cooperação e Desenvolvimento Económicos (OCDE)',
      designacaoPT: 'Organização de Cooperação e Desenvolvimento Económicos (OCDE)',
      nomePaisPL: 'Organisation for Economic Co-operation and Development',
      abrevMoeda: ''
    }, //  AAA
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'AAB',
      designacao: 'Outros Organismos Internacionais',
      designacaoPT: 'Outros Organismos Internacionais',
      nomePaisPL: 'Outros Organismos Internacionais',
      abrevMoeda: ''
    }, //  AAB
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'AAF',
      designacao: 'Banque Centrale des Etats de l’Afrique de l’Ouest',
      designacaoPT: 'Banco Central dos Estados da África Ocidental',
      nomePaisPL: 'Banque Centrale des Etats de l’Afrique de l’Ouest',
      abrevMoeda: ''
    }, //  AAF
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'AAI',
      designacao: 'Banco Latinoamericano de Exportaciones',
      designacaoPT: 'Banco Latino Americano de Exportações',
      nomePaisPL: 'Banco Latinoamericano de Exportaciones',
      abrevMoeda: ''
    }, //  AAI
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'EUB',
      designacao: 'Banco Central Europeu',
      designacaoPT: 'Banco Central Europeu',
      nomePaisPL: 'Banco Central Europeu',
      abrevMoeda: ''
    }, //  EUB
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'NTZ',
      designacao: 'Zona Neutra, entre Arábia Saudita/Iraque',
      designacaoPT: 'Zona Neutra, entre Arábia Saudita/Iraque',
      nomePaisPL: 'Saudi–Iraqi Neutral Zone',
      abrevMoeda: ''
    }, //  NTZ
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'QMA',
      designacao: 'Organização do Tratado do Atlântico Norte (NATO/OTAN)',
      designacaoPT: 'Organização do Tratado do Atlântico Norte (NATO/OTAN)',
      nomePaisPL: 'North Atlantic Treaty Organization',
      abrevMoeda: ''
    }, //  QMA
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'QNA',
      designacao: 'Banco Asiático de Desenvolvimento',
      designacaoPT: 'Banco Asiático de Desenvolvimento',
      nomePaisPL: 'Asian Development Bank',
      abrevMoeda: ''
    }, //  QNA
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'QOA',
      designacao: 'Banco Árabe para o Desenvolvimento em África',
      designacaoPT: 'Banco Árabe para o Desenvolvimento em África',
      nomePaisPL: 'Arab Bank for Economic Development in Africa',
      abrevMoeda: ''
    }, //  QOA
    {codigo: '', codigoAlpha2: '', codigoAlpha3: 'QPA', designacao: 'Banco Mundial', designacaoPT: 'Banco Mundial', nomePaisPL: 'World Bank', abrevMoeda: ''}, //  QPA
    {codigo: '', codigoAlpha2: '', codigoAlpha3: 'QQA', designacao: 'Eurofima', designacaoPT: 'Eurofima', nomePaisPL: 'Eurofima', abrevMoeda: ''}, //  QQA
    {codigo: '', codigoAlpha2: '', codigoAlpha3: 'QSA', designacao: 'EFTA', designacaoPT: 'EFTA', nomePaisPL: 'EFTA', abrevMoeda: ''}, //  QSA
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'QVA',
      designacao: 'Conselho de Ajuda Económica Mútua (CAEM)',
      designacaoPT: 'Conselho de Ajuda Económica Mútua (CAEM)',
      nomePaisPL: 'Council for Mutual Economic Assistance',
      abrevMoeda: ''
    }, //  QVA
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'QXA',
      designacao: 'Organização dos Países Exportadores de Petróleo (OPEP)',
      designacaoPT: 'Organização dos Países Exportadores de Petróleo (OPEP)',
      nomePaisPL: 'Organization of the Petroleum Exporting Countries',
      abrevMoeda: ''
    }, //  QXA
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XAA',
      designacao: 'Comunidade Europeia',
      designacaoPT: 'Comunidade Europeia',
      nomePaisPL: 'Comunidade Europeia',
      abrevMoeda: ''
    }, //  XAA
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XAG',
      designacao: 'Comunidade Europeia do Carvão e do Aço (CECA)',
      designacaoPT: 'Comunidade Europeia do Carvão e do Aço (CECA)',
      nomePaisPL: 'Comunidade Europeia do Carvão e do Aço',
      abrevMoeda: ''
    }, //  XAG
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XAK',
      designacao: 'Abastecimento e Provisões a Bordo',
      designacaoPT: 'Abastecimento e Provisões a Bordo',
      nomePaisPL: 'Abastecimento e Provisões a Bordo',
      abrevMoeda: ''
    }, //  XAK
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XAL',
      designacao: 'Países e Territórios não Especificados',
      designacaoPT: 'Países e Territórios não Especificados',
      nomePaisPL: 'Países e Territórios não Especificados',
      abrevMoeda: ''
    }, //  XAL
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XAN',
      designacao: 'Fundo Monetário Internacional (FMI/IMF)',
      designacaoPT: 'Fundo Monetário Internacional (FMI/IMF)',
      nomePaisPL: 'International Monetary Fund',
      abrevMoeda: ''
    }, //  XAN
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XAP',
      designacao: 'Banco de Pagamentos Internacionais (BPI/BIS/BRI)',
      designacaoPT: 'Banco de Pagamentos Internacionais (BPI/BIS/BRI)',
      nomePaisPL: 'Bank for International Settlements',
      abrevMoeda: ''
    }, //  XAP
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XAQ',
      designacao: 'Fundo Europeu de Cooperação Monetária (FECOM/EMCF)',
      designacaoPT: 'Fundo Europeu de Cooperação Monetária (FECOM/EMCF)',
      nomePaisPL: 'Fundo Europeu de Cooperação Monetária',
      abrevMoeda: ''
    }, //  XAQ
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XAR',
      designacao: 'Banco Internacional para a Reconstrução e Desenvolvimento (BIRD)',
      designacaoPT: 'Banco Internacional para a Reconstrução e Desenvolvimento (BIRD)',
      nomePaisPL: 'International Bank for Reconstruction and Development',
      abrevMoeda: ''
    }, //  XAR
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XAS',
      designacao: 'Associação Internacional para o Desenvolvimento',
      designacaoPT: 'Associação Internacional para o Desenvolvimento',
      nomePaisPL: 'Agency for International Development',
      abrevMoeda: ''
    }, //  XAS
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XAT',
      designacao: 'Sociedade Financeira Internacional (SFI – Grupo Banco Mundial)',
      designacaoPT: 'Sociedade Financeira Internacional (SFI – Grupo Banco Mundial)',
      nomePaisPL: 'International Finance Corporation',
      abrevMoeda: ''
    }, //  XAT
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XAU',
      designacao: 'Banco Europeu de Investimento (BEI)',
      designacaoPT: 'Banco Europeu de Investimento (BEI)',
      nomePaisPL: 'Banco Europeu de Investimento',
      abrevMoeda: ''
    }, //  XAU
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XAV',
      designacao: 'Banco Africano de Desenvolvimento (BAD)',
      designacaoPT: 'Banco Africano de Desenvolvimento (BAD)',
      nomePaisPL: 'African Development Bank',
      abrevMoeda: ''
    }, //  XAV
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XAW',
      designacao: 'Fundo Africano de Desenvolvimento (BAD)',
      designacaoPT: 'Fundo Africano de Desenvolvimento (BAD)',
      nomePaisPL: 'African Development Fund',
      abrevMoeda: ''
    }, //  XAW
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XAY',
      designacao: 'Banco Interamericano de Desenvolvimento (BID)',
      designacaoPT: 'Banco Interamericano de Desenvolvimento (BID)',
      nomePaisPL: 'Inter-American Development Bank',
      abrevMoeda: ''
    }, //  XAY
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XBA',
      designacao: 'Banco de Desenvolvimento das Caraíbas',
      designacaoPT: 'Banco de Desenvolvimento das Caraíbas',
      nomePaisPL: 'Caribbean Development Bank',
      abrevMoeda: ''
    }, //  XBA
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XBB',
      designacao: 'Fundo Europeu de Desenvolvimento',
      designacaoPT: 'Fundo Europeu de Desenvolvimento',
      nomePaisPL: 'Fundo Europeu de Desenvolvimento',
      abrevMoeda: ''
    }, //  XBB
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XBC',
      designacao: 'Agência Multilateral de Garantia dos Investimentos',
      designacaoPT: 'Agência Multilateral de Garantia dos Investimentos',
      nomePaisPL: 'Multilateral Investment Guarantee Agency',
      abrevMoeda: ''
    }, //  XBC
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XBD',
      designacao: 'Fundo de Financiamento Internacional para a Imunização',
      designacaoPT: 'Fundo de Financiamento Internacional para a Imunização',
      nomePaisPL: 'International Finance Facility for Immunisation',
      abrevMoeda: ''
    }, //  XBD
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XBE',
      designacao: 'Banco Islâmico de Desenvolvimento',
      designacaoPT: 'Banco Islâmico de Desenvolvimento',
      nomePaisPL: 'Islamic Development Bank',
      abrevMoeda: ''
    }, //  XBE
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XBF',
      designacao: 'Sociedade Interamericana de Investimento',
      designacaoPT: 'Sociedade Interamericana de Investimento',
      nomePaisPL: 'Inter-American Investment Corporation',
      abrevMoeda: ''
    }, //  XBF
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XBG',
      designacao: 'Fundo de Desenvolvimento Social do Conselho da Europa',
      designacaoPT: 'Fundo de Desenvolvimento Social do Conselho da Europa',
      nomePaisPL: 'Fundo de Desenvolvimento Social do Conselho da Europa',
      abrevMoeda: ''
    }, //  XBG
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XBH',
      designacao: 'Conselho da Europa',
      designacaoPT: 'Conselho da Europa',
      nomePaisPL: 'Conselho da Europa',
      abrevMoeda: ''
    }, //  XBH
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XBZ',
      designacao: 'Fundo de Reinstalação do Conselho da Europa (FRCE)',
      designacaoPT: 'Fundo de Reinstalação do Conselho da Europa (FRCE)',
      nomePaisPL: 'Fundo de Reinstalação do Conselho da Europa',
      abrevMoeda: ''
    }, //  XBZ
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XCZ',
      designacao: 'Nordic Investment Bank',
      designacaoPT: 'Banco Nórdico de Investimento',
      nomePaisPL: 'Nordic Investment Bank',
      abrevMoeda: ''
    }, //  XCZ
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XEB',
      designacao: 'Banco de Desenvolvimento do Conselho da Europa',
      designacaoPT: 'Banco de Desenvolvimento do Conselho da Europa',
      nomePaisPL: 'Banco de Desenvolvimento do Conselho da Europa',
      abrevMoeda: ''
    }, //  XEB
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XXA',
      designacao: 'Banco Europeu para a Reconstrução e Desenvolvimento (BERD)',
      designacaoPT: 'Banco Europeu para a Reconstrução e Desenvolvimento (BERD)',
      nomePaisPL: 'Banco Europeu para a Reconstrução e Desenvolvimento',
      abrevMoeda: ''
    }, //  XXA
    {
      codigo: '',
      codigoAlpha2: '',
      codigoAlpha3: 'XXI',
      designacao: 'Banco Internacional para a Cooperação Económica',
      designacaoPT: 'Banco Internacional para a Cooperação Económica',
      nomePaisPL: 'International Bank for Economic Cooperation',
      abrevMoeda: ''
    } // XXI
    // jshint ignore:end
  ]
});
