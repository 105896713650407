export enum EEntityStateListQueryParam {
  Search = 'search',
  Filter = 'filter',
  InitialFilter = 'initialfilter',
  Skip = 'skip',
  Take = 'take',
  Page = 'page',
  PerPage = 'perpage',
  DataGridState = 'datagridstate'
}

export enum EEntityStateDetailQueryParam {
  Type = 'type'
}

export enum EEntityStateDetailType {
  NEW = 'new',
  DETAIL = 'detail',
  EDIT = 'edit'
}

export interface IEntityDetailParams {
  id: string | number;
  model?: unknown;
}

export function entityStateListQueryParam(entityName: string, queryParam: EEntityStateListQueryParam, instanceId?: string): string {
  let entityState: string = entityName + queryParam;
  if (instanceId) {
    entityState = `${instanceId}_${entityState}`;
  }
  return entityState;
}

export function entityStateDetailQueryParam(entityName: string, queryParam: EEntityStateDetailQueryParam = EEntityStateDetailQueryParam.Type, instanceId?: string): string {
  let entityState: string = entityName + queryParam;
  if (instanceId) {
    entityState = `${instanceId}_${entityState}`;
  }
  return entityState;
}

export function entityStateName(entityName: string, entityStateDetailType: EEntityStateDetailType): string {
  let affix: EEntityStateDetailType;
  switch (entityStateDetailType) {
    case EEntityStateDetailType.NEW:
    case EEntityStateDetailType.DETAIL:
      affix = entityStateDetailType;
      break;
    case EEntityStateDetailType.EDIT:
      affix = EEntityStateDetailType.DETAIL;
      break;
    default:
      return entityName;
  }
  return `${entityName}_${affix}`;
}

export function entityStateNameNew(entityName: string): string {
  return entityStateName(entityName, EEntityStateDetailType.NEW);
}

export function entityStateNameDetail(entityName: string): string {
  return entityStateName(entityName, EEntityStateDetailType.DETAIL);
}
