import {Injectable} from '@angular/core';
import {ApiService} from '../../api/api.service';
import {IApiRequestConfigWithBody, TServiceResponse} from '../../api/api.service.interface';
import {IJsonApiPartnerMillenniumAssociate} from './partnermillennium.service.interface';
import {IJsonApiPartner} from '../partners.interface';

@Injectable({
  providedIn: 'root'
})
export class PartnerMillenniumService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/partners/millennium`;
  }

  public associate(partnerMillenniumAssociate: IJsonApiPartnerMillenniumAssociate, config?: IApiRequestConfigWithBody<IJsonApiPartnerMillenniumAssociate>): TServiceResponse<IJsonApiPartner> {
    return this._apiService.post<IJsonApiPartner, IJsonApiPartnerMillenniumAssociate>({
      url: `${this._path}/associate`,
      body: partnerMillenniumAssociate,
      ...config
    });
  }
}
