import {Component, Injector, Input, OnInit} from '@angular/core';
import {isObject, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {EPaisesMod22} from '../../../../modelo22.module.interface';
import {IDataSourceItem} from '../../../../../../../components/datasource/datasources.interface';
import {IJsonModelo22AnexoG} from '../../../../jsonModelo22.module.interface';

@Component({
  selector: 'modal-modelo22-anexog',
  templateUrl: './modelo22.anexoG.modal.component.html'
})
export class Modelo22AnexoGModalComponent extends CGModalComponent<IJsonModelo22AnexoG> implements OnInit {
  @Input() public newLineFor: string;
  @Input() public anexoG: IJsonModelo22AnexoG;
  public readonly dsTemplate: string;

  public dsPaisRegisto: Array<IDataSourceItem<string>>;
  public dsPaisGestao: Array<IDataSourceItem<string>>;
  public dsRegime: Array<IDataSourceItem<string>>;

  public periodo: number;
  public montanteDedutiveis: number;
  public montanteDeduzidos: number;
  public saldo: number;

  public nIdentificacao: string;
  public paisDeRegisto: string;
  public paisDeRegistoView: string;
  public regime: string;
  public regimeView: string;
  public paisDeGestao: string;
  public paisDeGestaoView: string;
  public percTripulantes: number;
  public arqueacao: number;
  public percRendimentos: number;
  public numeroDias: number;
  public reducaoMateria: number;
  public materiaColectavel: number;

  public isQuadro3: boolean;
  public isQuadro4: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this.dsTemplate = '{{value}} - {{name}}';
    this.dsPaisRegisto = [];
    this.dsPaisGestao = [];
    this.dsRegime = [];
    this.periodo = undefined;
    this.montanteDedutiveis = undefined;
    this.montanteDeduzidos = undefined;
    this.saldo = undefined;
    this.nIdentificacao = '';
    this.paisDeRegisto = '';
    this.paisDeRegistoView = '';
    this.regime = '';
    this.regimeView = '';
    this.paisDeGestao = '';
    this.paisDeGestaoView = '';
    this.percTripulantes = undefined;
    this.arqueacao = undefined;
    this.percRendimentos = undefined;
    this.numeroDias = undefined;
    this.reducaoMateria = undefined;
    this.materiaColectavel = undefined;
  }

  public ngOnInit(): void {
    this.isQuadro3 = this.newLineFor === 'quadro3';
    this.isQuadro4 = this.newLineFor === 'quadro4';
    this._setDSPaisRegisto();
    this._setDSPaisGestao();
    this._setDSRegime();
  }

  public close(): void {
    if (this.isQuadro3) {
      this.anexoG.quadro03.lista032.push({
        periodo: this.periodo,
        montanteDedutiveis: this.montanteDedutiveis,
        montanteDeduzidos: this.montanteDeduzidos,
        saldo: this.saldo
      });
      super.close(this.anexoG);
    }
    if (this.isQuadro4) {
      this.anexoG.quadro04.lista.push({
        nIdentificacao: this.nIdentificacao,
        paisDeRegisto: this.paisDeRegisto,
        regime: this.regime,
        paisDeGestao: this.paisDeGestao,
        percTripulantes: this.percTripulantes,
        arqueacao: this.arqueacao,
        percRendimentos: this.percRendimentos,
        numeroDias: this.numeroDias,
        reducaoMateria: this.reducaoMateria,
        materiaColectavel: this.materiaColectavel
      });
      super.close(this.anexoG);
    }
  }

  public evtPaisRegistoChanged(value: {inputValue: string; item: IDataSourceItem<string>}): void {
    if (isObject(value.item)) {
      this.paisDeRegistoView = `${value.item.value} - ${value.item.name}`;
      this.paisDeRegisto = value.item.value;
    }
  }

  public evtPaisGestaoChanged(value: {inputValue: string; item: IDataSourceItem<string>}): void {
    if (isObject(value.item)) {
      this.paisDeGestaoView = `${value.item.value} - ${value.item.name}`;
      this.paisDeGestao = value.item.value;
    }
  }

  public evtRegimeChanged(value: {inputValue: string; item: IDataSourceItem<string>}): void {
    if (isObject(value.item)) {
      this.regimeView = `${value.item.value} - ${value.item.name}`;
      this.regime = value.item.value;
    }
  }

  private _setDSPaisRegisto(): void {
    this.dsPaisRegisto.push(
      {
        value: EPaisesMod22.AFEGANISTAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.AFEGANISTAO}`)
      },
      {
        value: EPaisesMod22.AFRICA_DO_SUL,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.AFRICA_DO_SUL}`)
      },
      {
        value: EPaisesMod22.ALBANIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ALBANIA}`)
      },
      {
        value: EPaisesMod22.ALEMANHA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ALEMANHA}`)
      },
      {
        value: EPaisesMod22.ANDORRA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ANDORRA}`)
      },
      {
        value: EPaisesMod22.ANGOLA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ANGOLA}`)
      },
      {
        value: EPaisesMod22.ANGUILA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ANGUILA}`)
      },
      {
        value: EPaisesMod22.ANTARCTICA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ANTARCTICA}`)
      },
      {
        value: EPaisesMod22.ANTIGUA_E_BARBUDA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ANTIGUA_E_BARBUDA}`)
      },
      {
        value: EPaisesMod22.ANTILHAS_HOLANDESAS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ANTILHAS_HOLANDESAS}`)
      },
      {
        value: EPaisesMod22.ARABIA_SAUDITA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ARABIA_SAUDITA}`)
      },
      {
        value: EPaisesMod22.ARGELIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ARGELIA}`)
      },
      {
        value: EPaisesMod22.ARGENTINA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ARGENTINA}`)
      },
      {
        value: EPaisesMod22.ARMENIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ARMENIA}`)
      },
      {
        value: EPaisesMod22.ARUBA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ARUBA}`)
      },
      {
        value: EPaisesMod22.AUSTRALIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.AUSTRALIA}`)
      },
      {
        value: EPaisesMod22.AUSTRIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.AUSTRIA}`)
      },
      {
        value: EPaisesMod22.AZERBAIJAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.AZERBAIJAO}`)
      },
      {
        value: EPaisesMod22.BAHAMAS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BAHAMAS}`)
      },
      {
        value: EPaisesMod22.BANGLADESH,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BANGLADESH}`)
      },
      {
        value: EPaisesMod22.BARBADOS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BARBADOS}`)
      },
      {
        value: EPaisesMod22.BAREM,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BAREM}`)
      },
      {
        value: EPaisesMod22.BELGICA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BELGICA}`)
      },
      {
        value: EPaisesMod22.BELIZE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BELIZE}`)
      },
      {
        value: EPaisesMod22.BENIN,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BENIN}`)
      },
      {
        value: EPaisesMod22.BERMUDA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BERMUDA}`)
      },
      {
        value: EPaisesMod22.BIELORRUSSIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BIELORRUSSIA}`)
      },
      {
        value: EPaisesMod22.BOLIVIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BOLIVIA}`)
      },
      {
        value: EPaisesMod22.BOSNIA_E_HERZEGOVINA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BOSNIA_E_HERZEGOVINA}`)
      },
      {
        value: EPaisesMod22.BOTSWANA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BOTSWANA}`)
      },
      {
        value: EPaisesMod22.BRASIL,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BRASIL}`)
      },
      {
        value: EPaisesMod22.BRUNEI_DARUSSALAM,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BRUNEI_DARUSSALAM}`)
      },
      {
        value: EPaisesMod22.BULGARIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BULGARIA}`)
      },
      {
        value: EPaisesMod22.BURKINA_FASO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BURKINA_FASO}`)
      },
      {
        value: EPaisesMod22.BURUNDI,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BURUNDI}`)
      },
      {
        value: EPaisesMod22.BUTAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BUTAO}`)
      },
      {
        value: EPaisesMod22.CABO_VERDE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CABO_VERDE}`)
      },
      {
        value: EPaisesMod22.CAMAROES,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CAMAROES}`)
      },
      {
        value: EPaisesMod22.CAMBOJA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CAMBOJA}`)
      },
      {
        value: EPaisesMod22.CANADA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CANADA}`)
      },
      {
        value: EPaisesMod22.CATAR,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CATAR}`)
      },
      {
        value: EPaisesMod22.CAZAQUISTAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CAZAQUISTAO}`)
      },
      {
        value: EPaisesMod22.CENTRO_AFRICANA_REPUBLICA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CENTRO_AFRICANA_REPUBLICA}`)
      },
      {
        value: EPaisesMod22.CHADE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CHADE}`)
      },
      {
        value: EPaisesMod22.CHILE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CHILE}`)
      },
      {
        value: EPaisesMod22.CHINA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CHINA}`)
      },
      {
        value: EPaisesMod22.CHIPRE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CHIPRE}`)
      },
      {
        value: EPaisesMod22.COLOMBIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.COLOMBIA}`)
      },
      {
        value: EPaisesMod22.COMORES,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.COMORES}`)
      },
      {
        value: EPaisesMod22.CONGO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CONGO}`)
      },
      {
        value: EPaisesMod22.CONGO_REPUBLICA_DEMOCRATICA_DO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CONGO_REPUBLICA_DEMOCRATICA_DO}`)
      },
      {
        value: EPaisesMod22.COREIA_REPUBLICA_DA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.COREIA_REPUBLICA_DA}`)
      },
      {
        value: EPaisesMod22.COREIA_REPUBLICA_POPULAR_DEMOCRATICA_DA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.COREIA_REPUBLICA_POPULAR_DEMOCRATICA_DA}`)
      },
      {
        value: EPaisesMod22.COSTA_DO_MARFIM,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.COSTA_DO_MARFIM}`)
      },
      {
        value: EPaisesMod22.COSTA_RICA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.COSTA_RICA}`)
      },
      {
        value: EPaisesMod22.CROACIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CROACIA}`)
      },
      {
        value: EPaisesMod22.CUBA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CUBA}`)
      },
      {
        value: EPaisesMod22.DINAMARCA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.DINAMARCA}`)
      },
      {
        value: EPaisesMod22.DOMINICA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.DOMINICA}`)
      },
      {
        value: EPaisesMod22.EGIPTO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.EGIPTO}`)
      },
      {
        value: EPaisesMod22.EL_SALVADOR,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.EL_SALVADOR}`)
      },
      {
        value: EPaisesMod22.EMIRATOS_ARABES_UNIDOS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.EMIRATOS_ARABES_UNIDOS}`)
      },
      {
        value: EPaisesMod22.EQUADOR,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.EQUADOR}`)
      },
      {
        value: EPaisesMod22.ERITREIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ERITREIA}`)
      },
      {
        value: EPaisesMod22.ESLOVENIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ESLOVENIA}`)
      },
      {
        value: EPaisesMod22.ESPANHA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ESPANHA}`)
      },
      {
        value: EPaisesMod22.ESTADOS_UNIDOS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ESTADOS_UNIDOS}`)
      },
      {
        value: EPaisesMod22.ESTONIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ESTONIA}`)
      },
      {
        value: EPaisesMod22.ETIOPIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ETIOPIA}`)
      },
      {
        value: EPaisesMod22.FINLANDIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.FINLANDIA}`)
      },
      {
        value: EPaisesMod22.FILIPINAS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.FILIPINAS}`)
      },
      {
        value: EPaisesMod22.FRANCA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.FRANCA}`)
      },
      {
        value: EPaisesMod22.GABAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GABAO}`)
      },
      {
        value: EPaisesMod22.GAMBIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GAMBIA}`)
      },
      {
        value: EPaisesMod22.GANA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GANA}`)
      },
      {
        value: EPaisesMod22.GEORGIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GEORGIA}`)
      },
      {
        value: EPaisesMod22.GEORGIA_DO_SUL_E_ILHAS_SANDWICH,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GEORGIA_DO_SUL_E_ILHAS_SANDWICH}`)
      },
      {
        value: EPaisesMod22.GIBRALTAR,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GIBRALTAR}`)
      },
      {
        value: EPaisesMod22.GRANADA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GRANADA}`)
      },
      {
        value: EPaisesMod22.GRECIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GRECIA}`)
      },
      {
        value: EPaisesMod22.GRONELANDIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GRONELANDIA}`)
      },
      {
        value: EPaisesMod22.GUADALUPE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GUADALUPE}`)
      },
      {
        value: EPaisesMod22.GUAM,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GUAM}`)
      },
      {
        value: EPaisesMod22.GUATEMALA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GUATEMALA}`)
      },
      {
        value: EPaisesMod22.GUIANA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GUIANA}`)
      },
      {
        value: EPaisesMod22.GUIANA_FRANCESA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GUIANA_FRANCESA}`)
      },
      {
        value: EPaisesMod22.GUINE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GUINE}`)
      },
      {
        value: EPaisesMod22.GUINE_EQUATORIAL,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GUINE_EQUATORIAL}`)
      },
      {
        value: EPaisesMod22.GUINE_BISSAU,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GUINE_BISSAU}`)
      },
      {
        value: EPaisesMod22.HAITI,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.HAITI}`)
      },
      {
        value: EPaisesMod22.HONDURAS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.HONDURAS}`)
      },
      {
        value: EPaisesMod22.HONG_KONG,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.HONG_KONG}`)
      },
      {
        value: EPaisesMod22.HUNGRIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.HUNGRIA}`)
      },
      {
        value: EPaisesMod22.IEMEN,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.IEMEN}`)
      },
      {
        value: EPaisesMod22.ILHA_DE_MAN,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHA_DE_MAN}`)
      },
      {
        value: EPaisesMod22.ILHAS_BOUVET,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_BOUVET}`)
      },
      {
        value: EPaisesMod22.ILHAS_CAIMAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_CAIMAO}`)
      },
      {
        value: EPaisesMod22.ILHAS_COCOS_O_KEELING,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_COCOS_O_KEELING}`)
      },
      {
        value: EPaisesMod22.ILHAS_COOK,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_COOK}`)
      },
      {
        value: EPaisesMod22.ILHAS_DO_CANAL,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_DO_CANAL}`)
      },
      {
        value: EPaisesMod22.ILHAS_FALKLAND_MALVINAS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_FALKLAND_MALVINAS}`)
      },
      {
        value: EPaisesMod22.ILHAS_FAROE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_FAROE}`)
      },
      {
        value: EPaisesMod22.ILHAS_FIJI,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_FIJI}`)
      },
      {
        value: EPaisesMod22.ILHAS_HEARD_E_ILHAS_MCDONALD,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_HEARD_E_ILHAS_MCDONALD}`)
      },
      {
        value: EPaisesMod22.ILHAS_MARIANAS_DO_NORTE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_MARIANAS_DO_NORTE}`)
      },
      {
        value: EPaisesMod22.ILHAS_MARSHALL,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_MARSHALL}`)
      },
      {
        value: EPaisesMod22.ILHAS_MENORES_DISTANTES_DOS_ESTADOS_UNIDO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_MENORES_DISTANTES_DOS_ESTADOS_UNIDO}`)
      },
      {
        value: EPaisesMod22.ILHAS_NORFOLK,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_NORFOLK}`)
      },
      {
        value: EPaisesMod22.ILHAS_SALOMAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_SALOMAO}`)
      },
      {
        value: EPaisesMod22.ILHAS_VIRGENS_BRITANICAS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_VIRGENS_BRITANICAS}`)
      },
      {
        value: EPaisesMod22.ILHAS_VIRGENS_ESTADOS_UNIDOS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ILHAS_VIRGENS_ESTADOS_UNIDOS}`)
      },
      {
        value: EPaisesMod22.INDIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.INDIA}`)
      },
      {
        value: EPaisesMod22.INDONESIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.INDONESIA}`)
      },
      {
        value: EPaisesMod22.IRAO_REPUBLICA_ISLAMICA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.IRAO_REPUBLICA_ISLAMICA}`)
      },
      {
        value: EPaisesMod22.IRAQUE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.IRAQUE}`)
      },
      {
        value: EPaisesMod22.IRLANDA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.IRLANDA}`)
      },
      {
        value: EPaisesMod22.ISLANDIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ISLANDIA}`)
      },
      {
        value: EPaisesMod22.ISRAEL,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ISRAEL}`)
      },
      {
        value: EPaisesMod22.ITALIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ITALIA}`)
      },
      {
        value: EPaisesMod22.JAMAICA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.JAMAICA}`)
      },
      {
        value: EPaisesMod22.JAPAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.JAPAO}`)
      },
      {
        value: EPaisesMod22.JERSEY,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.JERSEY}`)
      },
      {
        value: EPaisesMod22.JIBUTI,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.JIBUTI}`)
      },
      {
        value: EPaisesMod22.JORDANIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.JORDANIA}`)
      },
      {
        value: EPaisesMod22.KENYA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.KENYA}`)
      },
      {
        value: EPaisesMod22.KIRIBATI,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.KIRIBATI}`)
      },
      {
        value: EPaisesMod22.KUWAIT,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.KUWAIT}`)
      },
      {
        value: EPaisesMod22.LAOS_REPUBLICA_POPULAR_DEMOCRATICA_DO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.LAOS_REPUBLICA_POPULAR_DEMOCRATICA_DO}`)
      },
      {
        value: EPaisesMod22.LESOTO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.LESOTO}`)
      },
      {
        value: EPaisesMod22.LETONIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.LETONIA}`)
      },
      {
        value: EPaisesMod22.LIBANO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.LIBANO}`)
      },
      {
        value: EPaisesMod22.LIBERIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.LIBERIA}`)
      },
      {
        value: EPaisesMod22.LIBIA_JAMAHIRIYA_ARABE_DA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.LIBIA_JAMAHIRIYA_ARABE_DA}`)
      },
      {
        value: EPaisesMod22.LIECHTENSTEIN,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.LIECHTENSTEIN}`)
      },
      {
        value: EPaisesMod22.LITUANIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.LITUANIA}`)
      },
      {
        value: EPaisesMod22.LUXEMBURGO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.LUXEMBURGO}`)
      },
      {
        value: EPaisesMod22.MACAU,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MACAU}`)
      },
      {
        value: EPaisesMod22.MACEDONIA_ANTIGA_REPUBLICA_JUGOSLAVA_DA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MACEDONIA_ANTIGA_REPUBLICA_JUGOSLAVA_DA}`)
      },
      {
        value: EPaisesMod22.MADAGASCAR,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MADAGASCAR}`)
      },
      {
        value: EPaisesMod22.MALASIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MALASIA}`)
      },
      {
        value: EPaisesMod22.MALAWI,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MALAWI}`)
      },
      {
        value: EPaisesMod22.MALDIVAS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MALDIVAS}`)
      },
      {
        value: EPaisesMod22.MALI,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MALI}`)
      },
      {
        value: EPaisesMod22.MALTA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MALTA}`)
      },
      {
        value: EPaisesMod22.MARROCOS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MARROCOS}`)
      },
      {
        value: EPaisesMod22.MAURICIAS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MAURICIAS}`)
      },
      {
        value: EPaisesMod22.MAURITANIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MAURITANIA}`)
      },
      {
        value: EPaisesMod22.MAYOTTE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MAYOTTE}`)
      },
      {
        value: EPaisesMod22.MEXICO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MEXICO}`)
      },
      {
        value: EPaisesMod22.MICRONESIA_ESTADOS_FEDERADOS_DA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MICRONESIA_ESTADOS_FEDERADOS_DA}`)
      },
      {
        value: EPaisesMod22.MOCAMBIQUE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MOCAMBIQUE}`)
      },
      {
        value: EPaisesMod22.MOLDOVA_REPUBLICA_DE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MOLDOVA_REPUBLICA_DE}`)
      },
      {
        value: EPaisesMod22.MONACO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MONACO}`)
      },
      {
        value: EPaisesMod22.MONGOLIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MONGOLIA}`)
      },
      {
        value: EPaisesMod22.MONSERRATE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MONSERRATE}`)
      },
      {
        value: EPaisesMod22.MONTENEGRO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MONTENEGRO}`)
      },
      {
        value: EPaisesMod22.MYANMAR,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MYANMAR}`)
      },
      {
        value: EPaisesMod22.NAMIBIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.NAMIBIA}`)
      },
      {
        value: EPaisesMod22.NAURU,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.NAURU}`)
      },
      {
        value: EPaisesMod22.NEPAL,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.NEPAL}`)
      },
      {
        value: EPaisesMod22.NICARAGUA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.NICARAGUA}`)
      },
      {
        value: EPaisesMod22.NIGER,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.NIGER}`)
      },
      {
        value: EPaisesMod22.NIGERIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.NIGERIA}`)
      },
      {
        value: EPaisesMod22.NIUE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.NIUE}`)
      },
      {
        value: EPaisesMod22.NORUEGA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.NORUEGA}`)
      },
      {
        value: EPaisesMod22.NOVA_CALEDONIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.NOVA_CALEDONIA}`)
      },
      {
        value: EPaisesMod22.NOVA_ZELANDIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.NOVA_ZELANDIA}`)
      },
      {
        value: EPaisesMod22.OMA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.OMA}`)
      },
      {
        value: EPaisesMod22.PAISES_BAIXOS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.PAISES_BAIXOS}`)
      },
      {
        value: EPaisesMod22.PALAU,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.PALAU}`)
      },
      {
        value: EPaisesMod22.PANAMA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.PANAMA}`)
      },
      {
        value: EPaisesMod22.PAPUASIA_NOVA_GUINE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.PAPUASIA_NOVA_GUINE}`)
      },
      {
        value: EPaisesMod22.PAQUISTAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.PAQUISTAO}`)
      },
      {
        value: EPaisesMod22.PARAGUAI,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.PARAGUAI}`)
      },
      {
        value: EPaisesMod22.PERU,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.PERU}`)
      },
      {
        value: EPaisesMod22.PITCAIRN,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.PITCAIRN}`)
      },
      {
        value: EPaisesMod22.POLINESIA_FRANCESA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.POLINESIA_FRANCESA}`)
      },
      {
        value: EPaisesMod22.POLONIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.POLONIA}`)
      },
      {
        value: EPaisesMod22.PORTO_RICO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.PORTO_RICO}`)
      },
      {
        value: EPaisesMod22.PORTUGAL,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.PORTUGAL}`)
      },
      {
        value: EPaisesMod22.QUIRGUIZISTAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.QUIRGUIZISTAO}`)
      },
      {
        value: EPaisesMod22.REINO_UNIDO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.REINO_UNIDO}`)
      },
      {
        value: EPaisesMod22.REPUBLICA_CHECA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.REPUBLICA_CHECA}`)
      },
      {
        value: EPaisesMod22.REPUBLICA_DOMINICANA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.REPUBLICA_DOMINICANA}`)
      },
      {
        value: EPaisesMod22.REUNIAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.REUNIAO}`)
      },
      {
        value: EPaisesMod22.ROMENIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ROMENIA}`)
      },
      {
        value: EPaisesMod22.RUANDA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.RUANDA}`)
      },
      {
        value: EPaisesMod22.RUSSIA_FEDERACAO_DA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.RUSSIA_FEDERACAO_DA}`)
      },
      {
        value: EPaisesMod22.SAMOA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SAMOA}`)
      },
      {
        value: EPaisesMod22.SAMOA_AMERICANA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SAMOA_AMERICANA}`)
      },
      {
        value: EPaisesMod22.SANTA_HELENA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SANTA_HELENA}`)
      },
      {
        value: EPaisesMod22.SANTA_LUCIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SANTA_LUCIA}`)
      },
      {
        value: EPaisesMod22.SANTA_SE_CIDADE_ESTADO_DO_VATICANO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SANTA_SE_CIDADE_ESTADO_DO_VATICANO}`)
      },
      {
        value: EPaisesMod22.SAO_CRISTOVAO_E_NEVIS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SAO_CRISTOVAO_E_NEVIS}`)
      },
      {
        value: EPaisesMod22.SAO_MARINO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SAO_MARINO}`)
      },
      {
        value: EPaisesMod22.SAO_PEDRO_E_MIQUELON,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SAO_PEDRO_E_MIQUELON}`)
      },
      {
        value: EPaisesMod22.SAO_TOME_E_PRINCIPE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SAO_TOME_E_PRINCIPE}`)
      },
      {
        value: EPaisesMod22.SAO_VICENTE_E_GRANADINAS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SAO_VICENTE_E_GRANADINAS}`)
      },
      {
        value: EPaisesMod22.SARA_OCIDENTAL,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SARA_OCIDENTAL}`)
      },
      {
        value: EPaisesMod22.SENEGAL,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SENEGAL}`)
      },
      {
        value: EPaisesMod22.SERRA_LEOA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SERRA_LEOA}`)
      },
      {
        value: EPaisesMod22.SERVIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SERVIA}`)
      },
      {
        value: EPaisesMod22.SERVIA_E_MONTENEGRO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SERVIA_E_MONTENEGRO}`)
      },
      {
        value: EPaisesMod22.SEYCHELLES,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SEYCHELLES}`)
      },
      {
        value: EPaisesMod22.SINGAPURA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SINGAPURA}`)
      },
      {
        value: EPaisesMod22.SIRIA_REPUBLICA_ARABE_DA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SIRIA_REPUBLICA_ARABE_DA}`)
      },
      {
        value: EPaisesMod22.SOMALIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SOMALIA}`)
      },
      {
        value: EPaisesMod22.SRI_LANKA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SRI_LANKA}`)
      },
      {
        value: EPaisesMod22.SUAZILANDIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SUAZILANDIA}`)
      },
      {
        value: EPaisesMod22.SUDAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SUDAO}`)
      },
      {
        value: EPaisesMod22.SUECIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SUECIA}`)
      },
      {
        value: EPaisesMod22.SUICA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SUICA}`)
      },
      {
        value: EPaisesMod22.SURINAME,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SURINAME}`)
      },
      {
        value: EPaisesMod22.SVALBARD_E_A_ILHA_DE_JAN_MAYEN,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SVALBARD_E_A_ILHA_DE_JAN_MAYEN}`)
      },
      {
        value: EPaisesMod22.TAILANDIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TAILANDIA}`)
      },
      {
        value: EPaisesMod22.TAIWAN,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TAIWAN}`)
      },
      {
        value: EPaisesMod22.TAJIQUISTAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TAJIQUISTAO}`)
      },
      {
        value: EPaisesMod22.TANZANIA_REPUBLICA_UNIDA_DA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TANZANIA_REPUBLICA_UNIDA_DA}`)
      },
      {
        value: EPaisesMod22.TERRITORIO_BRITANICO_DO_OCEANO_INDICO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TERRITORIO_BRITANICO_DO_OCEANO_INDICO}`)
      },
      {
        value: EPaisesMod22.TERRITORIO_PALESTINIANO_OCUPADO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TERRITORIO_PALESTINIANO_OCUPADO}`)
      },
      {
        value: EPaisesMod22.TERRITORIOS_FRANCESES_DO_SUL,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TERRITORIOS_FRANCESES_DO_SUL}`)
      },
      {
        value: EPaisesMod22.TIMOR_LESTE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TIMOR_LESTE}`)
      },
      {
        value: EPaisesMod22.TOGO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TOGO}`)
      },
      {
        value: EPaisesMod22.TOKELAU,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TOKELAU}`)
      },
      {
        value: EPaisesMod22.TONGA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TONGA}`)
      },
      {
        value: EPaisesMod22.TRINDADE_E_TOBAGO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TRINDADE_E_TOBAGO}`)
      },
      {
        value: EPaisesMod22.TUNISIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TUNISIA}`)
      },
      {
        value: EPaisesMod22.TURCOS_E_CAICOS_ILHAS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TURCOS_E_CAICOS_ILHAS}`)
      },
      {
        value: EPaisesMod22.TURQUEMENISTAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TURQUEMENISTAO}`)
      },
      {
        value: EPaisesMod22.TURQUIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TURQUIA}`)
      },
      {
        value: EPaisesMod22.TUVALU,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.TUVALU}`)
      },
      {
        value: EPaisesMod22.UCRANIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.UCRANIA}`)
      },
      {
        value: EPaisesMod22.UGANDA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.UGANDA}`)
      },
      {
        value: EPaisesMod22.URUGUAI,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.URUGUAI}`)
      },
      {
        value: EPaisesMod22.USBEQUISTAO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.USBEQUISTAO}`)
      },
      {
        value: EPaisesMod22.VANUATU,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.VANUATU}`)
      },
      {
        value: EPaisesMod22.VENEZUELA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.VENEZUELA}`)
      },
      {
        value: EPaisesMod22.VIETNAME,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.VIETNAME}`)
      },
      {
        value: EPaisesMod22.WALLIS_E_FUTUNA_ILHAS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.WALLIS_E_FUTUNA_ILHAS}`)
      },
      {
        value: EPaisesMod22.ZAMBIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ZAMBIA}`)
      },
      {
        value: EPaisesMod22.ZIMBABWE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ZIMBABWE}`)
      }
    );
  }

  private _setDSPaisGestao(): void {
    this.dsPaisGestao.push(
      {
        value: EPaisesMod22.ALEMANHA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ALEMANHA}`)
      },
      {
        value: EPaisesMod22.AUSTRIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.AUSTRIA}`)
      },
      {
        value: EPaisesMod22.BELGICA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BELGICA}`)
      },
      {
        value: EPaisesMod22.BULGARIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.BULGARIA}`)
      },
      {
        value: EPaisesMod22.CHIPRE,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CHIPRE}`)
      },
      {
        value: EPaisesMod22.CROACIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.CROACIA}`)
      },
      {
        value: EPaisesMod22.DINAMARCA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.DINAMARCA}`)
      },
      {
        value: EPaisesMod22.ESLOVENIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ESLOVENIA}`)
      },
      {
        value: EPaisesMod22.ESPANHA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ESPANHA}`)
      },
      {
        value: EPaisesMod22.ESTONIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ESTONIA}`)
      },
      {
        value: EPaisesMod22.FINLANDIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.FINLANDIA}`)
      },
      {
        value: EPaisesMod22.FRANCA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.FRANCA}`)
      },
      {
        value: EPaisesMod22.GRECIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.GRECIA}`)
      },
      {
        value: EPaisesMod22.HUNGRIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.HUNGRIA}`)
      },
      {
        value: EPaisesMod22.IRLANDA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.IRLANDA}`)
      },
      {
        value: EPaisesMod22.ISLANDIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ISLANDIA}`)
      },
      {
        value: EPaisesMod22.ITALIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ITALIA}`)
      },
      {
        value: EPaisesMod22.LETONIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.LETONIA}`)
      },
      {
        value: EPaisesMod22.LIECHTENSTEIN,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.LIECHTENSTEIN}`)
      },
      {
        value: EPaisesMod22.LITUANIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.LITUANIA}`)
      },
      {
        value: EPaisesMod22.LUXEMBURGO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.LUXEMBURGO}`)
      },
      {
        value: EPaisesMod22.MALTA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.MALTA}`)
      },
      {
        value: EPaisesMod22.NORUEGA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.NORUEGA}`)
      },
      {
        value: EPaisesMod22.PAISES_BAIXOS,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.PAISES_BAIXOS}`)
      },
      {
        value: EPaisesMod22.POLONIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.POLONIA}`)
      },
      {
        value: EPaisesMod22.PORTUGAL,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.PORTUGAL}`)
      },
      {
        value: EPaisesMod22.REINO_UNIDO,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.REINO_UNIDO}`)
      },
      {
        value: EPaisesMod22.REPUBLICA_CHECA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.REPUBLICA_CHECA}`)
      },
      {
        value: EPaisesMod22.ROMENIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.ROMENIA}`)
      },
      {
        value: EPaisesMod22.SUECIA,
        name: this._plTranslateService.translate(`modelo22.datasources.paises.${EPaisesMod22.SUECIA}`)
      }
    );
  }

  private _setDSRegime(): void {
    this.dsRegime.push(
      {
        value: '1',
        name: this._plTranslateService.translate('modelo22.datasources.regimeexploracao.1')
      },
      {
        value: '2',
        name: this._plTranslateService.translate('modelo22.datasources.regimeexploracao.2')
      },
      {
        value: '3',
        name: this._plTranslateService.translate('modelo22.datasources.regimeexploracao.3')
      },
      {
        value: '4',
        name: this._plTranslateService.translate('modelo22.datasources.regimeexploracao.4')
      }
    );
  }
}
