import {copy, IPlTableCallback, PlAlertService} from 'pl-comps-angular';
import moment, {Moment} from 'moment';
import {Component, Injector, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../../../../components/module/module.component';
import {EntityServiceBuilder} from '../../../../../../services/entity/entity.service.builder';
import {IJsonPRHLocal} from '../../../../../../entities/prhlocais/jsonPRHLocal.entity.interface';
import {IEntityService} from '../../../../../../services/entity/entity.service.interface';
import {IJsonPRHRefeicoes} from '../../../../../../entities/prhrefeicoes/jsonPRHRefeicoes.entity.interface';
import {IJsonPRHRefAprova} from '../../../../../../entities/prhrefaprova/jsonPRHRefAprova.entity.interface';
import {ENTITY_NAME_PRH_LOCAIS} from '../../../../../../entities/prhlocais/pRHLocais.entity.interface';
import {ENTITY_NAME_PRH_REFEICOES} from '../../../../../../entities/prhrefeicoes/pRHRefeicoes.entity.interface';
import {ENTITY_NAME_PRH_REF_APROVA} from '../../../../../../entities/prhrefaprova/pRHRefAprova.entity.interface';
import {TDate} from '../../../../../../../common/dates';

@Component({
  selector: 'listagem-refeicoes',
  templateUrl: './listagemRefeicoes.module.component.html'
})
export class ListagemRefeicoesComponent extends ModuloComponent implements OnInit {
  public readonly locaisTemplate: string = '{{codLocal}} - {{nome}}';
  public grupoRefeicao: number;
  public source: Array<IJsonPRHRefeicoes>;
  public usufruiu: number;
  public definition: unknown;
  public template: unknown;
  public callback: IPlTableCallback;
  public locais: Array<IJsonPRHLocal>;
  public locaisSelected: Array<IJsonPRHLocal>;
  public canProcess: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public sourceToProcessRefNaoCons: Array<any>;
  public dayDe: Moment;
  public dayAte: Moment;

  private readonly _servicePRHLocais: IEntityService<IJsonPRHLocal>;
  private readonly _servicePRHRefeicoes: IEntityService<IJsonPRHRefeicoes>;
  private readonly _servicePRHRefAprova: IEntityService<IJsonPRHRefAprova>;
  private _prhRefeicao: IJsonPRHRefeicoes;
  private _refAprova: IJsonPRHRefAprova;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);

    this._servicePRHLocais = this._entityServiceBuilder.build<IJsonPRHLocal>(ENTITY_NAME_PRH_LOCAIS);
    this._servicePRHRefeicoes = this._entityServiceBuilder.build<IJsonPRHRefeicoes>(ENTITY_NAME_PRH_REFEICOES);
    this._servicePRHRefAprova = this._entityServiceBuilder.build<IJsonPRHRefAprova>(ENTITY_NAME_PRH_REF_APROVA);
    this.grupoRefeicao = -1;
    this.source = [];
    this.usufruiu = -1;
    this.definition = {};
    this.template = {};
    this.callback = {};
    this.locais = [];
    this.locaisSelected = [];
    this.canProcess = true;
    this.dayDe = moment().startOf('month');
    this.dayAte = this.dayDe.clone().endOf('month');
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.definition = {
      fields: [
        {name: 'codColaborador', caption: 'prhrefeicoes.fields.CODEMPPlaceholder'},
        {name: 'colaborador', caption: 'prhrefeicoes.fields.CODEMP'},
        {name: 'consumiu', caption: 'prhrefeicoes.fields.consumiu'},
        {name: 'local', caption: 'prhrefeicoes.fields.local'},
        {name: 'tipoRefeicao', caption: 'prhrefeicoes.fields.tipoRefeicao'},
        {name: 'cor', caption: 'prhrefeicoes.fields.cor'},
        {name: 'menu', caption: 'prhrefeicoes.fields.menu'},
        {name: 'ementa', caption: 'prhrefeicoes.fields.ementa'},
        {name: 'data.year', caption: 'global.text.date', type: 'date'}
      ]
    };
    this.template = {
      rows: ['local'],
      type: 'ui'
    };

    this._servicePRHLocais.query().then((response) => {
      this.locais = response.body.list;
    });
  }

  public changedDataDe(value: TDate): void {
    this.dayDe = moment(value);
    this.fillPresencaList();
  }

  public changedDataAte(value: TDate): void {
    this.dayAte = moment(value);
    this.fillPresencaList();
  }

  public proceRefNaoConsumidas(): void {
    const newDate = moment();
    let porProcessar = false;
    for (const item of this.sourceToProcessRefNaoCons) {
      const dateRef = moment(item.dataRefeicao);
      if (newDate.valueOf() > dateRef.valueOf() && !item.consumiu && !item.faturado) {
        porProcessar = true;
        this._setAlteraMarca(item);
      }
    }
    if (!porProcessar) {
      this._plAlertService.success('prhrefeicoes.message.porProcessar');
    }
    this.canProcess = false;
  }

  public fillPresencaList(): void {
    this.source = [];
    this.sourceToProcessRefNaoCons = [];
    if (this.locaisSelected.length === 0) {
      return;
    }
    const myDateDeAux = moment(this.dayDe);
    const myDateDe = `${myDateDeAux.year()}-${myDateDeAux.month() + 1}-${myDateDeAux.date()}`;
    const myDateAteAux = moment(this.dayAte);
    const myDateAte = `${myDateAteAux.year()}-${myDateAteAux.month() + 1}-${myDateAteAux.date()}`;

    let localfilter = '';
    let filterprhrefeicoes = `dataRefeicao>=${myDateDe} &&dataRefeicao<=${myDateAte}`;
    if (this.usufruiu !== -1) {
      filterprhrefeicoes += `&&usufruiu=${this.usufruiu}`;
    }

    if (this.grupoRefeicao !== -1) {
      filterprhrefeicoes += `&&tpRefeicaoGrupoRefeicao=${this.grupoRefeicao}`;
    }

    for (const local of this.locaisSelected) {
      localfilter += !localfilter ? `&&(codLocal=${local.codLocal}` : `||codLocal=${local.codLocal}`;
    }
    localfilter += ')';
    filterprhrefeicoes += localfilter;
    this._servicePRHRefeicoes.query({pesquisa: filterprhrefeicoes}).then((response) => {
      const listAux = [];
      for (const refeicao of response.body.list) {
        listAux.push({
          codColaborador: refeicao.codEmp,
          colaborador: refeicao.nomeEmpregado,
          consumiu: refeicao.usufruiu,
          data: refeicao.dataRefeicao,
          dataRefeicao: refeicao.dataRefeicao,
          local: refeicao.relEmentas.local.nome,
          tipoRefeicao: refeicao.relEmentas.tpRefeicao.nome,
          cor: refeicao.relEmentas.tpRefeicao.corPrimaria,
          menu: refeicao.relEmentas.menu.titulo,
          ementa: refeicao.relEmentas.menu.descricao,
          codPRHRelEmentas: refeicao.relEmentas.codPRHRelEmentas,
          codRefeicao: refeicao.codRefeicao,
          faturado: refeicao.faturado
        });
      }
      this.sourceToProcessRefNaoCons = copy(listAux);
      this.source = listAux;
    });
  }

  public imprimir(): void {
    setTimeout(() => {
      window.print();
    });
  }

  private _setAlteraMarca(item): void {
    this._refAprova = {
      codPRHRefAprova: 0,
      codPRHRelEmentasOld: item.codPRHRelEmentas,
      codPRHRelEmentas: item.codPRHRelEmentas,
      codEmp: item.codColaborador,
      estado: item.Estado,
      codEmpAprova: item.codEmpAprova,
      dataAprova: item.dataAprova
    };
    this._servicePRHRefAprova.post({body: this._refAprova}).then((responseRefAprova) => {
      if (!responseRefAprova.body) {
        return undefined;
      }
      this._prhRefeicao = {
        codRefeicao: item.codRefeicao,
        codEmp: item.codColaborador,
        codPRHRelEmentas: item.codPRHRelEmentas,
        dataRefeicao: item.data,
        faturado: false,
        usufruiu: false,
        relEmentas: item.relEmentas,
        nomeEmpregado: item.nomeEmpregado
      };

      const promise = this._servicePRHRefeicoes.put({id: item.codRefeicao, body: this._prhRefeicao});
      promise.then(
        (responsePrhRef) => {
          const data = responsePrhRef.body;
          item.faturado = true;
          this._plAlertService.success('prhrefeicoes.message.procComSucesso');
          return data;
        },
        (error) => error
      );
      return promise;
    });
  }
}
