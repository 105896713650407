import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isUndefined, PlAlertService} from 'pl-comps-angular';
import {DocsComerciaisModalComponent} from '../docsComerciais.modal.component';
import {ENTITY_NAME_ARMAZENS} from '../../../armazens/armazens.entity.interface';
import {ENTITY_NAME_DOC_FAS} from '../../../docfas/docFas.entity.interface';
import {ENTITY_NAME_TRDOC_CONFIG} from '../../../trdocconfig/trDocConfig.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonArmazem} from '../../../armazens/jsonArmazem.entity.interface';
import {IJsonDocfa} from '../../../docfas/jsonDocFa.entity.interface';
import {ITrDocConfig} from './trdoc.modal.interface';
import moment from 'moment';
import {TDate} from '../../../../../common/dates';

@Component({
  selector: 'trdoc-modal',
  templateUrl: './trdoc.modal.component.html'
})
export class TrdocModalComponent extends DocsComerciaisModalComponent implements OnInit {
  public readonly docfasOutput: string;
  public readonly armazensOutput: string;
  public readonly model: ITrDocConfig;
  public docFa: IJsonDocfa;
  public armazem: IJsonArmazem;
  public promise: Promise<void>;
  public docfaNumFilter: string;

  private readonly _serviceTrDocConfig: IEntityService<ITrDocConfig>;
  private readonly _serviceDocFas: IEntityService<IJsonDocfa>;
  private readonly _serviceArmazens: IEntityService<IJsonArmazem>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.docfasOutput = '{{nDocFa}} - {{nome}} ({{descricao}})';
    this.armazensOutput = '{{nArmazem}} - {{nome}}';
    this.model = {
      nDocfaDestino: 0,
      dataDoc: moment(),
      dataDocOrigem: false,
      dataVenc: moment(),
      dataVencCondVenda: true,
      nFactFornec: '',
      nFactFornecOrigem: false,
      dataDocExterno: moment(),
      dataDocExternoOrigem: true,
      nRefProcesso: '',
      nRefProcessoOrigem: false,
      nArmazem: 1,
      nArmazemOrigem: true,
      encerraDocumentoOrigem: false,
      encerraDocumentoDestino: true
    };
    this._serviceTrDocConfig = this._entityServiceBuilder.build<ITrDocConfig>(ENTITY_NAME_TRDOC_CONFIG);
    this._serviceDocFas = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
    this._serviceArmazens = this._entityServiceBuilder.build(ENTITY_NAME_ARMAZENS);
  }

  public ngOnInit(): void {
    this.promise = this._serviceTrDocConfig.get({id: 0}).then((response) => {
      if (response) {
        this._setModel(
          response.body.nDocfaDestino,
          response.body.nNumerDestino,
          response.body.dataDocOrigem === true ? this.doc.cab.dataDoc : moment(),
          response.body.dataDocOrigem,
          response.body.dataVencCondVenda === true ? this.doc.cab.dataVenc : moment(),
          response.body.dataVencCondVenda,
          response.body.nFactFornecOrigem === true ? this.doc.cab.nFactFornec : '',
          response.body.nFactFornecOrigem,
          response.body.dataDocExternoOrigem === true ? this.doc.cab.dataDocExterno : moment(),
          response.body.dataDocExternoOrigem,
          response.body.nRefProcessoOrigem === true ? this.doc.cab.nrefprocesso : '',
          response.body.nRefProcessoOrigem,
          response.body.nArmazemOrigem === true ? 1 : response.body.nArmazem === 0 ? 1 : response.body.nArmazem,
          response.body.nArmazemOrigem,
          response.body.encerraDocumentoOrigem,
          response.body.encerraDocumentoDestino
        );
      } else {
        this._setModel(0, 0, moment(), false, moment(), true, '', false, moment(), true, '', false, 1, true, false, true);
      }
      this._setDefaultTipoDoc(this.model.nDocfaDestino);
      this.docfaNumFilter = `nDocFa=${this.model.nDocfaDestino}&encerrado=0&visivelERPCloud=1`;
      this._setDefaultNArmazem(this.model.nArmazem);
    });
  }

  public close(): Promise<void> {
    if (!this.model.nDocfaDestino || this.model.nDocfaDestino === 0) {
      this._plAlertService.error('trdocconfig.messages.tipoDocNulo');
      return Promise.reject(new Error('trdocconfig.messages.tipoDocNulo'));
    }
    this.disableClose();
    return this.service
      .put({url: `${this.doc.cab.faccbId}/transformadoccomercial`, body: this.model, params: {savetrdocconfig: true}})
      .then((response) => {
        if (response.body) {
          this.doc.cab = response.body.cab;
        }
        this.enableClose();
        super.close(this.doc);
      })
      .finally(() => {
        this.enableClose();
      });
  }

  public onDocFaChanged(docFa: IJsonDocfa): void {
    if (!docFa || isUndefined(docFa.nDocFa) || !this.model) {
      return;
    }
    if (this.model.nDocfaDestino !== docFa.nDocFa) {
      this.model.nDocfaDestino = docFa.nDocFa;
      this.docfaNumFilter = `nDocFa=${docFa.nDocFa}&encerrado=0&visivelERPCloud=1`;
    }
  }

  public onArmazemChanged(armazem: IJsonArmazem): void {
    if (!armazem || isUndefined(armazem.nArmazem) || !this.model) {
      return;
    }
    if (this.model.nArmazem !== armazem.nArmazem) {
      this.model.nArmazem = armazem.nArmazem;
    }
  }

  private _setModel(
    nDocfaDestino: number,
    nNumerDestino: number,
    dataDoc: TDate,
    dataDocOrigem: boolean,
    dataVenc: TDate,
    dataVencCondVenda: boolean,
    nFactFornec: string,
    nFactFornecOrigem: boolean,
    dataDocExterno: TDate,
    dataDocExternoOrigem: boolean,
    nRefProcesso: string,
    nRefProcessoOrigem: boolean,
    nArmazem: number,
    nArmazemOrigem: boolean,
    encerraDocumentoOrigem: boolean,
    encerraDocumentoDestino: boolean
  ): void {
    this.model.nDocfaDestino = nDocfaDestino;
    this.model.nNumerDestino = nNumerDestino;
    this.model.dataDoc = dataDoc;
    this.model.dataDocOrigem = dataDocOrigem;
    this.model.dataVenc = dataVenc;
    this.model.dataVencCondVenda = dataVencCondVenda;
    this.model.nFactFornec = nFactFornec;
    this.model.nFactFornecOrigem = nFactFornecOrigem;
    this.model.dataDocExterno = dataDocExterno;
    this.model.dataDocExternoOrigem = dataDocExternoOrigem;
    this.model.nRefProcesso = nRefProcesso;
    this.model.nRefProcessoOrigem = nRefProcessoOrigem;
    this.model.nArmazem = nArmazem;
    this.model.nArmazemOrigem = nArmazemOrigem;
    this.model.encerraDocumentoOrigem = encerraDocumentoOrigem;
    this.model.encerraDocumentoDestino = encerraDocumentoDestino;
  }

  private _setDefaultTipoDoc(nDocfaDestino: number): void {
    if (nDocfaDestino > 0) {
      this._serviceDocFas
        .get({id: nDocfaDestino})
        .then((response: HttpResponse<IJsonDocfa>) => {
          this.docFa = response.body;
          this.onDocFaChanged(response.body);
        })
        .catch(() => {
          (<Partial<IJsonDocfa>>this.docFa) = {};
        });
    }
  }

  private _setDefaultNArmazem(nArmazem: number): void {
    if (nArmazem > 0) {
      this._serviceArmazens
        .get({id: nArmazem})
        .then((response: HttpResponse<IJsonArmazem>) => {
          this.armazem = response.body;
          this.onArmazemChanged(response.body);
        })
        .catch(() => {
          (<Partial<IJsonArmazem>>this.docFa) = {};
        });
    }
  }
}
