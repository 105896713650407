import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonDepto} from './jsonDepto.entity.interface';
import {ENTITY_NAME_DEPTO} from './depto.entity.interface';

export const ENTITY_DEPTO: IEntityDefinition<IJsonDepto> = {
  name: ENTITY_NAME_DEPTO,
  roles: [ROLE.ERP, ROLE.ATIVOS, ROLE.RH, ROLE.CONTABILIDADE],
  searchPlaceholder: 'depto.pesquisa',
  metadata: {
    keyName: 'nDepto',
    fields: [
      {name: 'nDepto', width: '120px', caption: 'depto.fields.nDepto', placeholder: 'depto.fields.nDeptoPlaceholder', validators: {required: true, min: 1}},
      {name: 'nome', caption: 'depto.fields.nome', placeholder: 'depto.fields.nomePlaceholder', validators: {required: true, maxlength: 50}}
    ],
    order: 'nome',
    searchFields: 'nDepto,nome'
  },
  autocomplete: {
    rowTemplate: '{{nDepto}} - {{nome}}',
    output: 'nome',
    searchFields: 'nDepto,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.depto'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.depto'
      }
    }
  }
};
