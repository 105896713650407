import {TDate} from '../../../common/dates';

export enum EObjTesourariaPeriodicidade {
  None,
  Diaria,
  Semanal,
  Quinzenal,
  Mensal,
  Bimensal,
  Trimestral,
  Quadrimestral,
  Semestral,
  Anual
}

export enum ETesRubricaReceitaDespesa {
  Receita,
  Despesa
}

export interface IJsonTesRubrica {
  tesRubricaID: number;
  nome: string;
  receitaDespesa: ETesRubricaReceitaDespesa;
}

export interface IJsonPreviTes {
  _edited: boolean;
  tesRubricaID: number;
  tesLancoID: string;
  nLanc: string;
  descricao: string;
  data: TDate;
  valor: number;
  rubricaStr: string;
  nConta: string;
  nomeConta: string;
}

export interface IJsonPreviTesNew {
  codRubrica: number;
  descricao: string;
  data: TDate;
  valor: number;
  nLanc: string;
  numOcorr: number;
  ocorrFinalPeriodo: boolean;
  periodicidade: EObjTesourariaPeriodicidade;
}

export interface IJsonPreviTesAuto {
  isDataTesFixed: boolean;
  porPagar: number;
  nDOCFA: number;
  debito: number;
  credito: number;
  seleccionado: boolean;
  nDocString: string;
  nome: string;
  extPocCabID: string;
  nDocExterno: string;
  nConta: string;
  descricao: string;
  dataDocExt: TDate;
  dataVencimento: TDate;
  dataTes: TDate;
  nLanc: string;
}

export interface IJsonPreviTesAutoHeader {
  contaDeCliente: string;
  contaAteCliente: string;
  contaDeFornecedor: string;
  contaAteFornecedor: string;
  dataTesDe: TDate;
  dataTesAte: TDate;
}
