import {IEntityDefinition} from '../../../components/entity/entity.definition.interface';
import {ROLE} from '../../../services/role.const';
import {IJsonPerfilCategoriaAbono} from '../jsonPerfilCategoriaAbDes.module.interface';
import {ENTITY_NAME_PERFIL_CATEGORIA_ABONO} from '../perfilCategoriaAbDes.module.interface';

export const ENTITY_PERFIL_CATEGORIA_ABONO: IEntityDefinition<IJsonPerfilCategoriaAbono> = {
  name: ENTITY_NAME_PERFIL_CATEGORIA_ABONO,
  asModule: false,
  entityUrl: 'perfiscategabdes/abonos',
  roles: [ROLE.RH],
  searchPlaceholder: 'perfilCategoriaAbono.pesquisa',
  metadata: {
    keyName: 'ncategoriaabdes',
    fields: [
      {name: 'nCategoriaAbDes', width: '200px', caption: 'perfilCategoriaAbono.fields.nCategoriaAbDes', validators: {required: true}},
      {name: 'tipoArredondamento', caption: 'perfilCategoriaAbono.fields.tipoArredondamento', validators: {required: true}},
      {name: 'tipoRecolha', caption: 'perfilCategoriaAbono.fields.tipoRecolha', validators: {required: true}},
      {name: 'unidadeDefeito', caption: 'perfilCategoriaAbono.fields.unidadeDefeito', validators: {required: true}},
      {name: 'nomeUnidadeDefeito', caption: 'perfilCategoriaAbono.fields.nomeUnidadeDefeito', validators: {required: true}},
      {name: 'tipoRendimento', caption: 'perfilCategoriaAbono.fields.tipoRendimento', validators: {required: true}},
      {name: 'codRem', caption: 'perfilCategoriaAbono.fields.codRem', validators: {required: true}},
      {name: 'nomeCodRem', caption: 'perfilCategoriaAbono.fields.nomeCodRem', validators: {required: true}},
      {name: 'nContaDebitoEmp', caption: 'perfilCategoriaAbono.fields.nContaDebitoEmp', validators: {required: true}},
      {name: 'nContaCreditoEmp', caption: 'perfilCategoriaAbono.fields.nContaCreditoEmp', validators: {required: true}},
      {name: 'nContaDebitoPatronato', caption: 'perfilCategoriaAbono.fields.nContaDebitoPatronato', validators: {required: true}},
      {name: 'nContaCreditoPatronato', caption: 'perfilCategoriaAbono.fields.nContaCreditoPatronato', validators: {required: true}},
      {name: 'contribuiCalculoCustoPrecoH', caption: 'perfilCategoriaAbono.fields.contribuiCalculoCustoPrecoH', validators: {required: true}}
    ]
  },
  actions: {
    new: false,
    detail: false,
    edit: true,
    delete: false,
    search: false,
    filter: false
  }
};
