import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonERPUtilizador} from '../../interfaces/jsonERPUtilizador.interface';
import {ENTITY_NAME_ERP_USERS} from './erpUsers.entity.interface';

export const ENTITY_ERP_USERS: IEntityDefinition<IJsonERPUtilizador> = {
  name: ENTITY_NAME_ERP_USERS,
  url: 'manager/erpusers',
  roles: [ROLE.ADMIN],
  searchPlaceholder: 'users.pesquisa',
  metadata: {
    keyName: 'nutilizador',
    fields: [
      {name: 'nutilizador', caption: 'erputilizadores.fields.nutilizador', type: 'integer', validators: {required: true, maxlength: 20}},
      {name: 'nomeUtilizador', caption: 'erputilizadores.fields.nomeUtilizador', validators: {required: true, maxlength: 50}},
      {name: 'nomeCompleto', caption: 'erputilizadores.fields.nomeCompleto', validators: {required: true, maxlength: 50}}
    ],
    order: 'nutilizador',
    searchFields: 'nutilizador,nomeUtilizador,nomeCompleto'
  },
  autocomplete: {
    rowTemplate: '{{nutilizador}} - {{nomeCompleto}}',
    output: 'nomeCompleto',
    searchFields: 'nutilizador,nomeUtilizador,nomeCompleto'
  },
  actions: {
    new: false,
    detail: false,
    edit: false,
    delete: false,
    search: false
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ADMIN],
        pageTitle: 'global.menu.erpusers'
      }
    }
  }
};
