import {HttpResponse} from '@angular/common/http';
import {ConfigService} from '../../../../../services/config/config.service';
import {ContabilidadeDeclaracaoIvaPeriodicaModuleComponent} from './contabilidade.declaracaoIvaPeriodica.module.component';
import {ContabilidadeDeclaracaoIvaService} from '../../../../../services/contabilidade.declaracaoiva.service';
import {IJsonDecIvaPeriodica} from '../../../../../interfaces/jsonDeclaracaoIva.interface';
import {IModuleDefinition} from '../../../../../components/module/module.definition.interface';
import {MODULE_NAME_PCA_MANUTENCAO_DECLARACAOIVA} from '../contabilidade.declaracaoIva.module.interface';
import {ROLE} from '../../../../../services/role.const';

export const MODULE_PCA_MANUTENCAO_DECLARACAOIVA: IModuleDefinition = {
  name: MODULE_NAME_PCA_MANUTENCAO_DECLARACAOIVA,
  state: {
    url: `/${MODULE_NAME_PCA_MANUTENCAO_DECLARACAOIVA}`,
    component: ContabilidadeDeclaracaoIvaPeriodicaModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.declaracaoiva'
    },
    resolve: [
      {
        provide: 'decIvaPeriodica',
        deps: [ContabilidadeDeclaracaoIvaService, ConfigService],
        useFactory: (declaracaoIvaService: ContabilidadeDeclaracaoIvaService): Promise<IJsonDecIvaPeriodica> => {
          return declaracaoIvaService.loadDeclaracaoIVAPerio().then((response: HttpResponse<IJsonDecIvaPeriodica>) => response.body);
        }
      }
    ]
  }
};
