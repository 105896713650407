import {Component, Injector} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {AuthService} from '../../../../services/auth/auth.service';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {IJsonUserLicenca} from '../../../../services/account/jsonUserLicensa.interface';
import {IJsonUserRole, TUserSession} from '../../../../services/account/jsonUserApi.interface';

@Component({
  selector: 'portal-about-modal',
  templateUrl: './portal.about.modal.component.html'
})
export class PortalAboutModalComponent extends CGModalComponent<void> {
  public licenca: IJsonUserLicenca;
  public userRoles: ReadonlyArray<IJsonUserRole>;
  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _authService: AuthService
  ) {
    super(_injector);
    this.licenca = {
      nUtilizadores: 0,
      totalNUtilizadores: 0,
      portalErp: 0,
      totalPortalErp: 0,
      portalPca: 0,
      totalPortalPca: 0,
      portalColaborador: 0,
      totalPortalColaborador: 0,
      portalContabilidade: 0,
      totalPortalContabilidade: 0,
      portalRH: 0,
      totalPortalRH: 0,
      portalAtivos: 0,
      totalPortalAtivos: 0
    };
    this.userRoles = [];
    this.promise = Promise.all([
      this._authService.licenca().then((response: HttpResponse<IJsonUserLicenca>) => {
        this.licenca = {...this.licenca, ...response.body};
      }),
      this._authService.identity().then((session: TUserSession) => {
        this.userRoles = session.roles;
      })
    ]).then(() => undefined);
  }
}
