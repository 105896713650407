import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MapaPagamentosModuleComponent} from './components/mapaPagamentos.module.component';
import {MapaPagamentosService} from './mapaPagamentos.module.service';
import {MODULE_NAME_MAPA_PAGAMENTOS} from './mapaPagamentos.module.interface';
import {RESOLVER_LICENCA_STORE_MODE_PUBLIC} from '../../../services/config/config.service.router';
import {ROLE} from '../../../services/role.const';

export const MODULE_MAPA_PAGAMENTOS: IModuleDefinition = {
  name: MODULE_NAME_MAPA_PAGAMENTOS,
  state: {
    url: `/${MODULE_NAME_MAPA_PAGAMENTOS}`,
    component: MapaPagamentosModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pluginsRoles: [ROLE.CONTABILIDADEPREMIUM],
      icon: 'fa-suitcase'
    },
    resolve: [
      RESOLVER_LICENCA_STORE_MODE_PUBLIC,
      {
        provide: 'userConfig',
        deps: [MapaPagamentosService],
        useFactory: (service: MapaPagamentosService) => service.getConfig().then((response) => response.body)
      }
    ]
  }
};
