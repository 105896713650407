import {Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {isNumber} from '../../common/utilities/utilities';

const defaultClass = 'pl-animation-shake';
const defaultDuration = 85;

@Directive({
  selector: '[plAnimationShake]',
  standalone: false
})
export class PlAnimationShakeDirective implements OnInit, OnChanges {
  @Input() public plAnimationShake: any;
  @Input() public plAnimationShakeClass: string;
  @Input() public plAnimationShakeDuration: number;

  private readonly _element: HTMLElement;

  constructor(private readonly _elementRef: ElementRef) {
    this._element = this._elementRef.nativeElement;
  }

  public ngOnInit(): void {
    this._addClass();
  }

  public ngOnChanges({plAnimationShake}: SimpleChanges): void {
    if (plAnimationShake && !plAnimationShake.isFirstChange()) {
      this._removeClass();
      const duration = isNumber(this.plAnimationShakeDuration) ? this.plAnimationShakeDuration : defaultDuration;
      window.setTimeout(() => {
        this._addClass();
      }, duration);
    }
  }

  private _addClass(): void {
    this._element.classList.add(this.plAnimationShakeClass || defaultClass);
  }

  private _removeClass(): void {
    this._element.classList.remove(this.plAnimationShakeClass || defaultClass);
  }
}
