import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IApiQueryParams, TServiceQueryResponse, TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonGestaoComunicacoesEstado, IJsonGestaoComunicacoesEstadoOrdem} from './jsonGestaoComunicacoesEstado.module.interface';

@Injectable({
  providedIn: 'root'
})
export class GestaoComunicacoesEstadoService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/comestados`;
  }

  public deleteEstado(estadoId: string): TServiceResponse<void> {
    return this._apiService.delete<void>({
      url: `${this._path}/${estadoId}`
    });
  }

  public getEstado(estadoId: string): TServiceResponse<IJsonGestaoComunicacoesEstado> {
    return this._apiService.get<IJsonGestaoComunicacoesEstado>({
      url: `${this._path}/${estadoId}`
    });
  }

  public getEstados(params?: IApiQueryParams): TServiceQueryResponse<IJsonGestaoComunicacoesEstado> {
    return this._apiService.query<IJsonGestaoComunicacoesEstado>({
      url: this._path,
      params: {...params}
    });
  }

  public getEstadosOrdem(nEmpresa: string, nUtilizador: number): TServiceResponse<Array<IJsonGestaoComunicacoesEstadoOrdem>> {
    return this._apiService.get<Array<IJsonGestaoComunicacoesEstadoOrdem>>({
      url: `${this._path}/estadosordem/${nEmpresa}/${nUtilizador}`
    });
  }

  public createEstado(estado: IJsonGestaoComunicacoesEstado): TServiceResponse<IJsonGestaoComunicacoesEstado> {
    return this._apiService.post<IJsonGestaoComunicacoesEstado>({
      url: this._path,
      body: estado
    });
  }

  public saveEstadosOrdem(nEmpresa: string, nUtilizador: number, estadosList: Array<IJsonGestaoComunicacoesEstadoOrdem>): TServiceResponse<IJsonGestaoComunicacoesEstadoOrdem> {
    return this._apiService.post<IJsonGestaoComunicacoesEstadoOrdem, Array<IJsonGestaoComunicacoesEstadoOrdem>>({
      url: `${this._path}/estadosordem/${nEmpresa}/${nUtilizador}`,
      body: estadosList
    });
  }

  public updateEstado(estadoId: string, estado: IJsonGestaoComunicacoesEstado): TServiceResponse<IJsonGestaoComunicacoesEstado> {
    return this._apiService.put<IJsonGestaoComunicacoesEstado>({
      url: `${this._path}/${estadoId}`,
      body: estado
    });
  }
}
