import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EMapaPagamResend, IJsonMapaPagamEmpresasEnvioResumoItem} from '../../mapaPagamentos.module.interface';

@Component({
  selector: 'module-mapa-pagamentos-resumo-envio-modal',
  templateUrl: './mapaPagamentos.resumoEnvio.modal.component.html'
})
export class MapaPagamentosResumoEnvioModalComponent extends CGModalComponent<EMapaPagamResend> {
  @Input() public list: Array<IJsonMapaPagamEmpresasEnvioResumoItem>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public resend(): void {
    super.close(EMapaPagamResend.RETRY);
  }

  public resendSkipErrors(): void {
    super.close(EMapaPagamResend.RETRY_SKIP);
  }
}
