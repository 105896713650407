import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonRHQPIRA, IJsonRHQPIRCT, IJsonRHQPIRCTImpQPIRCT} from './jsonRHQPIRCT.module.interface';
import {Observable, of} from 'rxjs';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class RHQPIRCTService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/rhqpirct`;
  }

  public getListaQPIRCT(): TServiceResponse<Array<IJsonRHQPIRCT>> {
    return this._apiService.get<Array<IJsonRHQPIRCT>>({url: this._path});
  }

  public getListaQPIRA(codIRCT: string): TServiceResponse<Array<IJsonRHQPIRA>> {
    return this._apiService.get<Array<IJsonRHQPIRA>>({url: `${this._path}/${codIRCT}/categorias`});
  }

  public getSaveIRCTCateg(codirct: string, nomeirct: string, codcategoria: string, nomecategoria: string, iscategoria: boolean): TServiceResponse<Array<IJsonRHQPIRCT>> {
    return this._apiService.put<Array<IJsonRHQPIRCT>>({
      url: `${this._path}/guardairctcateg`,
      params: {
        codirct: codirct,
        nomeirct: nomeirct,
        codcategoria: codcategoria,
        nomecategoria: nomecategoria,
        iscategoria: iscategoria
      }
    });
  }

  public postImportFileCSV(): Observable<string> {
    return of(`${this._path}/importarcsv`);
  }

  public postSaveImportCSV(rhqpirctimp: IJsonRHQPIRCTImpQPIRCT): TServiceResponse<Array<IJsonRHQPIRCT>> {
    return this._apiService.post<Array<IJsonRHQPIRCT>, IJsonRHQPIRCTImpQPIRCT>({
      url: `${this._path}/saveimportcsv`,
      body: rhqpirctimp
    });
  }
}
