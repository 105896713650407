import {Component, Injector, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../components/module/module.component';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IImportXLS} from '../importXLS.module.interface';
import {EGenericImporterType} from '../../../components/genericimporter/generic.importer.component.interface';
import {GenericImporterService} from '../../../components/genericimporter/generic.importer.service';

@Component({
  selector: 'module-import-xls',
  templateUrl: './importXLS.module.component.html'
})
export class ImportXLSModuleComponent extends ModuloComponent implements OnInit {
  public dataGridDefinition: IDevExpressDataGrid;
  public importXLSList: Array<IImportXLS>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _genericImporterService: GenericImporterService
  ) {
    super(_injector);
    this.importXLSList = [
      {type: EGenericImporterType.Clientes, descricao: this._translateService.instant('importxls.fields.fichasClientes')},
      {type: EGenericImporterType.Fornecedores, descricao: this._translateService.instant('importxls.fields.fichasFornecedores')},
      {type: EGenericImporterType.Artigos, descricao: this._translateService.instant('importxls.fields.artigos')},
      {type: EGenericImporterType.CCClientes, descricao: this._translateService.instant('importxls.fields.contasCorrentesClientes')},
      {type: EGenericImporterType.CCFornecedores, descricao: this._translateService.instant('importxls.fields.contasCorrentesFornecedores')}
    ];
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'descricao', dataType: 'string', caption: 'importxls.fields.descricao'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn'}
      ],
      dataSource: this.importXLSList,
      export: {enabled: false},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public openImportModal(item: IImportXLS): Promise<void> {
    return this._genericImporterService.modal(item.type);
  }
}
