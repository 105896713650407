<div class="row">
  <div class="col-md-4">
    <pl-form>
      <pl-group>
        <label>Local:</label>
        <edit>
          <entity-autocomplete entity="prhlocais" attrName="local" inputClass="input-sm" [model]="local" (modelChange)="changedLocal($event)" [properties]="{allowInvalid: false}">
          </entity-autocomplete>
        </edit>
        <actions>
          <button type="button" class="btn btn-primary" name="clonemenus" title="Clonar menus" [disabled]="!local" [click]="fnCloneMenus" plPromise>
            <i class="fa fa-fw fa-clone"></i>
          </button>
        </actions>
      </pl-group>

      <pl-group>
        <label>Ref.:</label>
        <edit>
          <select class="form-select" name="tiporefeicao" [(ngModel)]="tipoRefeicao">
            <option *ngFor="let tipo of tiposRefeicoes" [ngValue]="tipo">{{ tipo.nome }}</option>
          </select>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div class="col-md-8 text-center">
    <h4>{{ viewDate | cgcDate: 'MMMM yyyy' }}</h4>
  </div>
</div>

<div class="row">
  <div class="col-md-4">
    <dx-data-grid [cgDxDataGrid]="dataGridDefition" cgDxDataGridInstanceName="associarmenus" [dataSource]="menus" (onCellClick)="onDataGridCellClick($event)">
      <div *dxTemplate="let cell of 'dataGridCellTemplateBtn'">
        <button type="button" class="btn btn-success btn-xs" *ngIf="!cell.data.alreadyUsed" [click]="fnAssociaMenu(cell.data)" plPromise>
          <i class="fa fa-fw fa-plus"></i>
        </button>
      </div>

      <div *dxTemplate="let detail of 'dataGridTemplateDetail'">{{ detail.data.descricao }}</div>
    </dx-data-grid>
  </div>

  <div class="col-md-8"></div>
</div>
