import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {generateName, isArray, isObject, isString} from '../common/utilities/utilities';
import type {IPlToolbarConfig, IPlToolbarInstance, IPlToolbarInstanceEvtGetItemElement, TPlToolbarItem, TPlToolbarItemOrId} from './toolbar.interface';
import {sortToolbarItems} from './toolbar.utilities';

export class PlToolbarInstance implements IPlToolbarInstance {
  private readonly _config?: IPlToolbarConfig;
  private readonly _subjectItemsChanged: Subject<Array<TPlToolbarItem>>;
  private readonly _subjectFocusItem: Subject<TPlToolbarItemOrId>;
  private readonly _subjectGetItemElement: Subject<IPlToolbarInstanceEvtGetItemElement>;
  private readonly _subjectPageChange: BehaviorSubject<number>;
  private readonly _subjectSearchChange: BehaviorSubject<string>;
  private _observableItemsChanged: Observable<Array<TPlToolbarItem>>;
  private _observableFocusItem: Observable<TPlToolbarItemOrId>;
  private _observableGetItemElement: Observable<IPlToolbarInstanceEvtGetItemElement>;
  private _observablePageChange: Observable<number>;
  private _observableSearchChange: Observable<string>;

  constructor(
    private readonly _instanceId: string,
    config?: IPlToolbarConfig
  ) {
    this._config = config;
    if (!isObject(this._config)) {
      this._config = {};
    }
    if (!isObject(this._config.search)) {
      this._config.search = {
        active: true,
        text: '',
        placeholder: '',
        button: true
      };
    }
    if (!isArray(this._config.items)) {
      this._config.items = [];
    } else {
      this._config.items = sortToolbarItems(this._config.items);
    }
    this._subjectItemsChanged = new Subject<Array<TPlToolbarItem>>();
    this._subjectFocusItem = new Subject<TPlToolbarItemOrId>();
    this._subjectGetItemElement = new Subject<IPlToolbarInstanceEvtGetItemElement>();
    this._subjectPageChange = new BehaviorSubject<number>(0);
    this._subjectSearchChange = new BehaviorSubject<string>(this._config.search.text);
  }

  public cleanup(): void {
    this._subjectItemsChanged.complete();
    this._subjectFocusItem.complete();
    this._subjectGetItemElement.complete();
    this._subjectPageChange.complete();
    this._subjectSearchChange.complete();
  }

  public find(idOrItem: TPlToolbarItemOrId, deep: boolean = false): TPlToolbarItem {
    function findInMenu(toolbarIdOrItem: TPlToolbarItemOrId, searchDeep: boolean, menu: Array<TPlToolbarItem>): TPlToolbarItem {
      for (const item of menu) {
        if (toolbarIdOrItem === item.id || toolbarIdOrItem === item) {
          return item;
        } else if (searchDeep && isArray(item.menu) && item.menu.length) {
          const menuItem: TPlToolbarItem = findInMenu(toolbarIdOrItem, true, item.menu);
          if (menuItem) {
            return menuItem;
          }
        }
      }
      return undefined;
    }

    return findInMenu(idOrItem, deep, this._config.items);
  }

  public findIndex(idOrItem: TPlToolbarItemOrId): number {
    for (let i = 0; i < this._config.items.length; i++) {
      if (idOrItem === this._config.items[i].id || idOrItem === this._config.items[i]) {
        return i;
      }
    }
    return -1;
  }

  public sortItems(by: keyof TPlToolbarItem = 'order'): this {
    this._config.items = sortToolbarItems(this._config.items, by);
    this._itemsChanged();
    return this;
  }

  public removeButton(idOrItem: TPlToolbarItemOrId): this {
    const idx = this.findIndex(idOrItem);
    if (idx > -1) {
      this._config.items.splice(idx, 1);
      this.sortItems();
    }
    return this;
  }

  public removeGroupId(id: string, deep: boolean = true): this {
    const removed: boolean = this._removeGroupId(id, deep, this._config.items);
    if (removed) {
      this.sortItems();
    }
    return this;
  }

  public addButton(button: TPlToolbarItem): this {
    if (button.id) {
      for (let i = 0; i < this._config.items.length; i++) {
        if (this._config.items[i].id === button.id) {
          this._config.items[i] = button;
          return this.sortItems();
        }
      }
    } else {
      button.id = generateName(button.id, 'plToolbarItem');
    }
    this._config.items.push(button);
    return this.sortItems();
  }

  public setItems(items: TPlToolbarItem | Array<TPlToolbarItem>): this {
    if (!isArray(items)) {
      if (!isObject(items)) {
        items = [];
      } else {
        items = [items];
      }
    }
    this._config.items = items;
    this.sortItems();
    return this;
  }

  public focusItem(idOrItem: TPlToolbarItemOrId): this {
    this._subjectFocusItem.next(idOrItem);
    return this;
  }

  public getItemElement(idOrItem: TPlToolbarItemOrId, queryFocusable: boolean = false): HTMLElement {
    const event: IPlToolbarInstanceEvtGetItemElement = {idOrItem: idOrItem, queryFocusable: queryFocusable, element: undefined};
    this._subjectGetItemElement.next(event);
    return event.element;
  }

  public setPage(value: number): this {
    this._subjectPageChange.next(value);
    return this;
  }

  public setSearch(value: string): this {
    this._config.search.text = value;
    this._subjectSearchChange.next(this._config.search.text);
    return this;
  }

  public onItemsChanged(): Observable<Array<TPlToolbarItem>> {
    if (!this._observableItemsChanged) {
      this._observableItemsChanged = this._subjectItemsChanged.asObservable();
    }
    return this._observableItemsChanged;
  }

  public onFocusItem(): Observable<TPlToolbarItemOrId> {
    if (!this._observableFocusItem) {
      this._observableFocusItem = this._subjectFocusItem.asObservable();
    }
    return this._observableFocusItem;
  }

  public onGetItemElement(): Observable<IPlToolbarInstanceEvtGetItemElement> {
    if (!this._observableGetItemElement) {
      this._observableGetItemElement = this._subjectGetItemElement.asObservable();
    }
    return this._observableGetItemElement;
  }

  public onPageChanged(): Observable<number> {
    if (!this._observablePageChange) {
      this._observablePageChange = this._subjectPageChange.asObservable();
    }
    return this._observablePageChange;
  }

  public onSearchChanged(): Observable<string> {
    if (!this._observableSearchChange) {
      this._observableSearchChange = this._subjectSearchChange.asObservable();
    }
    return this._observableSearchChange;
  }

  public get instanceId(): string {
    return this._instanceId;
  }

  public get config(): IPlToolbarConfig {
    return this._config;
  }

  private _removeGroupId(id: string, deep: boolean, items: Array<TPlToolbarItem>): boolean {
    if (!isString(id) || !id) {
      return false;
    }
    let removed = false;
    for (let i = items.length - 1; i >= 0; i--) {
      const item: TPlToolbarItem = items[i];
      let removedItem = false;
      if (id === item.groupId) {
        if (!removed) {
          removed = true;
        }
        items.splice(i, 1);
        removedItem = true;
      }
      if (deep && !removedItem && isArray(item.menu) && item.menu.length) {
        const removedFromChildren: boolean = this._removeGroupId(id, true, item.menu);
        if (removedFromChildren && !removed) {
          removed = true;
        }
      }
    }
    return removed;
  }

  private _itemsChanged(): void {
    this._subjectItemsChanged.next(this._config.items);
  }
}
