import {Component, Injector} from '@angular/core';
import {PlEditInputTypeComponent} from '../../generic/input/edit.input.type.component';

@Component({
  selector: 'pl-edit-email',
  templateUrl: '../../generic/input/edit.input.type.component.html',
  standalone: false,
  exportAs: 'cgcEditEmail'
})
export class PlEditEmailComponent extends PlEditInputTypeComponent<string> {
  constructor(protected readonly _injector: Injector) {
    super('email', _injector);
  }
}
