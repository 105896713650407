import {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {BancosExtratoService} from '../../service/bancosExtrato.module.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IJsonBancosExtratoConfigs} from '../../jsonBancosExtrato.module.interface';
import {IJsonBankAccount} from '../../../../interfaces/jsonBankAccount.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';

@Component({
  selector: 'bancos-extrato-module-edit-bank-account-modal',
  templateUrl: './bancosExtrato.editbankaccount.modal.component.html'
})
export class BancosExtratoModuleEditBankAccountModalComponent extends CGModalComponent<IJsonBankAccount> implements OnInit, OnDestroy {
  @Input() public bankAccount: Partial<IJsonBankAccount>;

  public readonly filterConta: string;
  public model: IJsonBankAccount;
  public placeholderDiarioRecebimentos: string;
  public placeholderDiarioPagamentos: string;

  private readonly _subscriptionConfigs: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _bancosExtratoService: BancosExtratoService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.filterConta = `tipo=${ETipoContaContabilidade.Movimento}&bloqueada=false`;
    this.model = {
      bankAccountId: undefined,
      descricao: undefined,
      nomeBanco: undefined,
      iban: undefined,
      visivel: undefined,
      nConta: undefined,
      nomeConta: undefined,
      saldo: undefined,
      nDiarioRecebimentos: undefined,
      nomeDiarioRecebimentos: undefined,
      nDiarioPagamentos: undefined,
      nomeDiarioPagamentos: undefined,
      saldoData: undefined,
      saldoValor: undefined
    };
    this._subscriptionConfigs = this._bancosExtratoService.configs().subscribe((configs: IJsonBancosExtratoConfigs) => {
      this.placeholderDiarioRecebimentos = configs.nDiarioRecebimentos ? `${configs.nDiarioRecebimentos} - ${configs.nomeDiarioRecebimentos}` : undefined;
      this.placeholderDiarioPagamentos = configs.nDiarioPagamentos ? `${configs.nDiarioPagamentos} - ${configs.nomeDiarioPagamentos}` : undefined;
    });
    this.deleteAccount = this.deleteAccount.bind(this);
  }

  public ngOnInit(): void {
    this.model = {
      ...this.model,
      ...this.bankAccount
    };
    if (!this.model.nDiarioRecebimentos) {
      this.model.nDiarioRecebimentos = undefined;
      this.model.nomeDiarioRecebimentos = undefined;
    }
    if (!this.model.nDiarioPagamentos) {
      this.model.nDiarioPagamentos = undefined;
      this.model.nomeDiarioPagamentos = undefined;
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionConfigs.unsubscribe();
  }

  public async close(): Promise<void> {
    this.disableClose();
    try {
      const response: HttpResponse<IJsonBankAccount> = await this._bancosExtratoService.updateBankAccount(this.model);
      this.enableClose();
      this._plAlertService.success('bancosextrato.text.successUpdateBankAccount');
      super.close(response.body);
    } catch (reason) {
      this.enableClose();
      this._logger.error(reason);
    }
  }

  public async deleteAccount(): Promise<void> {
    await this._cgModalService.showOkCancel('global.text.warning', 'entity.delete.message', {size: 'md'});
    this.disableClose();
    try {
      const response: HttpResponse<IJsonBankAccount> = await this._bancosExtratoService.deleteBankAccount(this.model);
      this.enableClose();
      this._plAlertService.success('bancosextrato.text.successUpdateBankAccount');
      super.close(response.body);
    } catch (reason) {
      this.enableClose();
      this._logger.error(reason);
    }
  }
}
