import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {EPlDatepickerKind, PlDynamicVisualsAbstractComponent} from 'pl-comps-angular';
import {EGestaoDGEMPSView} from '../../gestaodgemps.interface';
import moment, {Moment, MomentInput} from 'moment';

@Component({
  selector: 'gestao-dgemps-view-date-picker',
  templateUrl: './gestaodgemps.viewdate.picker.component.html'
})
export class GestaoDGEMPSViewDatePickerComponent extends PlDynamicVisualsAbstractComponent implements OnInit {
  @Input() public view: EGestaoDGEMPSView;
  @Input() public viewDate: MomentInput;
  @Output() public readonly viewDateChange: EventEmitter<Moment>;

  public kind: EPlDatepickerKind;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.viewDateChange = new EventEmitter<Moment>();
  }

  public ngOnInit(): void {
    switch (this.view) {
      case EGestaoDGEMPSView.Year:
        this.kind = EPlDatepickerKind.Year;
        break;
      case EGestaoDGEMPSView.Month:
        this.kind = EPlDatepickerKind.Month;
        break;
    }
  }

  public changedViewDate(value: MomentInput): void {
    this.viewDate = moment(value);
    this.viewDateChange.emit(this.viewDate);
    this.close();
  }
}
