import {Component, Injector, Input, OnInit} from '@angular/core';
import {isEmpty, isNumber, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {IJsonModelo22AnexoF} from '../../../../jsonModelo22.module.interface';
import moment from 'moment';
import {TDate} from '../../../../../../../../common/dates';

@Component({
  selector: 'modal-modelo22-anexof',
  templateUrl: './modelo22.anexoF.modal.component.html'
})
export class Modelo22AnexoFModalComponent extends CGModalComponent<IJsonModelo22AnexoF> implements OnInit {
  @Input() public newLineFor: string;
  @Input() public anexoF: IJsonModelo22AnexoF;

  public codFreguesia: string;
  public tipo: string;
  public artigo: string;
  public fraccao: string;
  public montanteRegimeTransitorio: number;
  public designacao: string;
  public imposto: number;
  public dataAquisicao: TDate;
  public dataAlienacao: TDate;
  public montante: number;

  public isA: boolean;
  public isB: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.codFreguesia = '';
    this.tipo = '';
    this.artigo = '';
    this.fraccao = '';
    this.montanteRegimeTransitorio = 0;
    this.designacao = '';
    this.imposto = 0;
    this.dataAquisicao = moment();
    this.dataAlienacao = moment();
    this.montante = 0;
  }

  public ngOnInit(): void {
    this.isA = this.newLineFor === 'A';
    this.isB = this.newLineFor === 'B';
  }

  public close(): void {
    if (this.isA) {
      if (isEmpty(this.codFreguesia)) {
        this._plAlertService.error('modelo22.messages.freguesiavazio');
        return;
      }
      if (isEmpty(this.tipo)) {
        this._plAlertService.error('modelo22.messages.tipovazio');
        return;
      }

      this.anexoF.quadro06.lista6A.push({
        codFreguesia: this.codFreguesia,
        tipo: this.tipo,
        artigo: this.artigo,
        fraccao: this.fraccao,
        dataAquisicao: this.dataAquisicao,
        dataAlienacao: this.dataAlienacao,
        montanteRealizado: this.montante,
        montanteRegimeTransitorio: this.montanteRegimeTransitorio
      });
      super.close(this.anexoF);
    }
    if (this.isB) {
      if (isEmpty(this.designacao)) {
        this._plAlertService.error('modelo22.messages.designacaovazio');
        return;
      }
      if (!isNumber(this.montante) || this.montante < 1) {
        this._plAlertService.error('modelo22.messages.montantevazio');
        return;
      }

      this.anexoF.quadro06.lista6B.push({
        dataAlienacao: this.dataAlienacao,
        designacao: this.designacao,
        dataAquisicao: this.dataAquisicao,
        montante: this.montante,
        imposto: this.imposto
      });
      super.close(this.anexoF);
    }
  }
}
