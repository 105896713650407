<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'proceDMRAT.viewInfoModalTitle'" [translateParams]="{ano: ano, mes: mes}"></h5>
</div>

<div class="modal-body" [plPromise]="mainPromise">
  <div class="row">
    <div class="col-md-12">
      <pl-tabs>
        <pl-tab id="proce-dmr-at-dados-auto-tab" caption="proceDMRAT.viewInfoDadosAutoTab">
          <div *plTabContent>
            <dx-data-grid [cgDxDataGrid]="dataGridDefinitionControloAuto" cgDxDataGridInstanceName="controloAutoTable" [dataSource]="dataGridDefinitionControloAuto.dataSource">
              <div *dxTemplate="let item of 'cellTemplateStatusImage'">
                <i aria-hidden="true" [ngClass]="item.data.statusImage"></i>
              </div>

              <div *dxTemplate="let item of 'masterDetailProcResults'">
                <pl-alert type="error" *ngIf="item.data.erro > 0"><i class="fa fa-times-circle" aria-hidden="true"></i>&nbsp;<span [translate]="item.data.erroDescricao"></span></pl-alert>
                <h6 class="resumo-title mb-2" [translate]="'proceDMRAT.resumoDadosFuncionario'"></h6>
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionResumoDadosFuncionario" [dataSource]="item.data.resumo"></dx-data-grid>
              </div>
            </dx-data-grid>
          </div>
        </pl-tab>

        <pl-tab id="proce-dmr-at-dados-manuais-tab" caption="proceDMRAT.viewInfoDadosManuaisTab">
          <div *plTabContent>
            <dx-data-grid [cgDxDataGrid]="dataGridDefinitionControloManual" cgDxDataGridInstanceName="controloManualTable" [dataSource]="dataGridDefinitionControloManual.dataSource"></dx-data-grid>
          </div>
        </pl-tab>

        <pl-tab id="proce-dmr-at-dados-globais-tab" caption="proceDMRAT.viewInfoDadosGlobaisTab">
          <div *plTabContent>
            <pl-tabs>
              <pl-tab caption="proceDMRAT.viewInfoDadosGlobaisControloTab">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridDefinitionGlobalControl" cgDxDataGridInstanceName="globalControl" [dataSource]="dataGridDefinitionGlobalControl.dataSource"></dx-data-grid>
                </div>
              </pl-tab>

              <pl-tab caption="proceDMRAT.viewInfoDadosGlobaisValorRendimentosTab">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridDefinitionGlobValorRendim" cgDxDataGridInstanceName="globValorRendim" [dataSource]="dataGridDefinitionGlobValorRendim.dataSource">
                  </dx-data-grid>
                </div>
              </pl-tab>

              <pl-tab caption="proceDMRAT.viewInfoDadosGlobaisRetencaoIRSTab">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridDefinitionGlobRetencaoIRS" cgDxDataGridInstanceName="globRetencaoIRS" [dataSource]="dataGridDefinitionGlobRetencaoIRS.dataSource">
                  </dx-data-grid>
                </div>
              </pl-tab>

              <pl-tab caption="proceDMRAT.viewInfoDadosGlobaisContribObrigTab">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridDefinitionGlobContribObrig" cgDxDataGridInstanceName="globContribObrig" [dataSource]="dataGridDefinitionGlobContribObrig.dataSource">
                  </dx-data-grid>
                </div>
              </pl-tab>

              <pl-tab caption="proceDMRAT.viewInfoDadosGlobaisQuotizSindicaisTab">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridDefinitionGlobQuotizSindicais" cgDxDataGridInstanceName="globQuotizSindicais" [dataSource]="dataGridDefinitionGlobQuotizSindicais.dataSource">
                  </dx-data-grid>
                </div>
              </pl-tab>

              <pl-tab caption="proceDMRAT.viewInfoDadosGlobaisRetencaoSobretaxaTab">
                <div *plTabContent>
                  <dx-data-grid
                    [cgDxDataGrid]="dataGridDefinitionGlobRetencaoSobretaxa"
                    cgDxDataGridInstanceName="globRetencaoSobretaxa"
                    [dataSource]="dataGridDefinitionGlobRetencaoSobretaxa.dataSource">
                  </dx-data-grid>
                </div>
              </pl-tab>

              <pl-tab caption="proceDMRAT.viewInfoDadosGlobaisRendimAnosAntTab">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridDefinitionGlobRendimAnosAnt" cgDxDataGridInstanceName="globRendimAnosAnt" [dataSource]="dataGridDefinitionGlobRendimAnosAnt.dataSource">
                  </dx-data-grid>
                </div>
              </pl-tab>
            </pl-tabs>
          </div>
        </pl-tab>
      </pl-tabs>
    </div>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-close"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
