import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {ILotesService} from './lotes.entity.service.interface';
import {ENTITY_NAME_LOTES} from './lotes.entity.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IJsonLote} from './jsonLote.entity.interface';

@Injectable({
  providedIn: 'root'
})
export class LotesService implements ILotesService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/${ENTITY_NAME_LOTES}`;
  }

  public terminarLote(nLote: number): TServiceResponse<IJsonLote> {
    return this._apiService.put<IJsonLote>({
      url: `${this._path}/${nLote}/terminarlote`
    });
  }
}
