<div class="pl-row-select" [class.disabled]="options.disabled" [class.readonly]="options.readonly" [class.spaced]="options.spaced">
  <div class="pl-row-select-items" [class.btn-group]="!options.spaced">
    @switch (isArray) {
      @case (true) {
        @for (item of sourceArray; track item) {
          <button type="button" class="btn btn-sm pl-row-select-item" [ngClass]="options.btnCSSClass" [class.active]="item === value" (click)="select(item)">{{ item }}</button>
        }
      }
      @case (false) {
        @for (key of listKeys; track key) {
          <button type="button" class="btn btn-sm pl-row-select-item" [ngClass]="options.btnCSSClass" [class.active]="sourceObject[key] === value" (click)="select(sourceObject[key])"
            >{{ key }}
          </button>
        }
      }
    }
  </div>
</div>
