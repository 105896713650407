import {HttpResponse} from '@angular/common/http';
import {ConfigErpService} from '../../../services/configErp.service';
import {ErpCheckModuleComponent} from './components/erp.check.module.component';
import {IJsonConfigERPCheck} from '../../../entities/configserp/jsonConfigERP.entity.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_CONFIGS_ERP_CHECK} from './erp.check.module.interface';
import {ROLE} from '../../../services/role.const';

export const MODULE_CONFIGS_ERP_CHECK: IModuleDefinition = {
  name: MODULE_NAME_CONFIGS_ERP_CHECK,
  state: {
    url: '/configs/erp/check',
    component: ErpCheckModuleComponent,
    data: {
      roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
      pageTitle: 'global.menu.erpCheck',
      icon: 'fa-check'
    },
    resolve: [
      {
        provide: 'list',
        deps: [ConfigErpService],
        useFactory: (configErpService: ConfigErpService) => configErpService.get().then((response: HttpResponse<Array<IJsonConfigERPCheck>>) => response.body)
      }
    ]
  }
};
