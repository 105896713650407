<div class="modal-header">
  <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'arredondamentoManual.title'"></h4>
</div>

<div class="modal-body">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    [dataSource]="dataGridDefinition.dataSource"
    cgDxDataGridInstanceName="arredondamentoManualGrid"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()"
    (onRowUpdating)="onRowUpdating($event)"
    (onRowUpdated)="onRowUpdated($event)">
  </dx-data-grid>

  <div class="float-end col-md-5">
    <div class="float-end">
      <dx-data-grid [cgDxDataGrid]="dataGridResumoDefinition" [dataSource]="resumoIvaTotais"></dx-data-grid>
    </div>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-success action-save" (click)="close()"><i class="fa fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></button>

  <button type="button" class="btn btn-sm btn-danger" (click)="delete()"><i class="fa fa-fw fa-trash"></i>&nbsp;<span [translate]="'global.btn.delete'"></span></button>

  <button type="button" class="btn btn-sm btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
