import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonNotaCredito, IJsonNotaCreditoPesquisaContab} from '../jsonAtivosNotaCredito.interface';
import {AtivosNotaCreditoService} from '../../../services/ativosNotaCredito.service';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../../../modules/portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellDblClick,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowExpanding
} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonDocContabilidade} from '../../../../../modules/portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {INotaCreditoPesquisaContab} from '../ativosNotaCredito.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';

@Component({
  selector: 'modal-ativos-nota-credito-submodal',
  templateUrl: './ativosNotaCredito.modal.subModal.component.html'
})
export class AtivosNotaCreditoSubModalModalComponent extends CGModalComponent<IJsonNotaCredito> implements OnInit {
  @Input() public codAtivo: number;
  @Input() public notaCreditoList: Array<INotaCreditoPesquisaContab>;
  public readonly dataGridDefinition: IDevExpressDataGrid;

  public selected: INotaCreditoPesquisaContab;
  public promise: Promise<void>;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosNotaCreditoService: AtivosNotaCreditoService,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'ativosnotacredito.modaltable.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'ativosnotacredito.modaltable.nome', width: 200},
        {dataField: 'periodo', dataType: 'string', caption: 'ativosnotacredito.modaltable.periodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'ativosnotacredito.modaltable.nDiario'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'ativosnotacredito.modaltable.nDocInterno'},
        {dataField: 'dCDescription', dataType: 'string', caption: 'ativosnotacredito.modaltable.dCDescription'},
        {dataField: 'valor', dataType: 'double', caption: 'ativosnotacredito.modaltable.valor'},
        {dataField: 'valorCalculado', dataType: 'double', caption: 'ativosnotacredito.modaltable.valorCalculado'}
      ],
      dataSource: [],
      export: {filename: 'ativosnotacredito.submodal.title'},
      keyExpr: 'extPocCabID',
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocContabilidade'},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = this.notaCreditoList;
  }

  public close(): Promise<void> {
    if (this.promise) {
      return this.promise;
    }
    if (!this.selected) {
      this._plAlertService.error('ativosnotacredito.message.temdeselecionarum');
      return Promise.resolve();
    }
    this.disableClose();
    return this._ativosNotaCreditoService
      .postSelecionaNotaCredito(this.selected, this.codAtivo)
      .then((response: HttpResponse<IJsonNotaCredito>) => {
        this.enableClose();
        super.close(response.body);
      })
      .catch(() => {
        this.enableClose();
      });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onCellClick({rowType, data}: IDevExpressDataGridEventOnCellClick<INotaCreditoPesquisaContab>): void {
    if (rowType === 'data') {
      this.selected = data;
    }
  }

  public onCellDblClick({rowType, data}: IDevExpressDataGridEventOnCellDblClick<INotaCreditoPesquisaContab>): void {
    if (rowType === 'data') {
      this.selected = data;
      if (this.selected && !this.promise) {
        this.promise = this.close();
        this.promise.finally(() => {
          this.promise = undefined;
        });
      }
    }
  }

  public onRowExpanding(event: IDevExpressDataGridEventOnRowExpanding<INotaCreditoPesquisaContab, string>): void {
    const items: Array<INotaCreditoPesquisaContab> = this._dataGridInstance.getDataSource().items();
    const index: number = items.findIndex((item: INotaCreditoPesquisaContab) => item.extPocCabID === event.key);
    if (index !== -1 && !this.dataGridDefinition.dataSource[index]._docContabilidade) {
      this._serviceDocsContabilidade.get({id: (<INotaCreditoPesquisaContab>this.dataGridDefinition.dataSource[index]).extPocCabID}).then((response: HttpResponse<IJsonDocContabilidade>) => {
        this.dataGridDefinition.dataSource[index]._docContabilidade = response.body;
      });
    }
  }

  public readonly fnGetContaPesq: () => Promise<void> = () => this._getContaPesq();

  private _getContaPesq(): Promise<void> {
    return this._ativosNotaCreditoService.getPesquisaNotaCreditoContab().then((response: HttpResponse<Array<IJsonNotaCreditoPesquisaContab>>) => {
      this.dataGridDefinition.dataSource = response.body;
    });
  }
}
