import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonQpcae} from './jsonQpcae.entity.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_QPCAE} from './qpcae.entity.interface';

export const ENTITY_QPCAE: IEntityDefinition<IJsonQpcae> = {
  name: ENTITY_NAME_QPCAE,
  roles: [ROLE.ADMINBASIC, ROLE.ATIVOS, ROLE.RH],
  searchPlaceholder: 'qpcae.pesquisa',
  metadata: {
    keyName: 'codcae',
    fields: [
      {name: 'codcae', caption: 'qpcae.fields.codcae', placeholder: 'qpcae.fields.codcaePlaceholder', validators: {required: true, maxlength: 10}},
      {name: 'nome', caption: 'qpcae.fields.nome', placeholder: 'qpcae.fields.nomePlaceholder', validators: {required: true, maxlength: 150}},
      {name: 'codSubSec', caption: 'qpcae.fields.codSubSec', placeholder: 'qpcae.fields.codSubSecPlaceholder', validators: {required: true, maxlength: 5}}
    ],
    order: 'codcae',
    searchFields: 'codcae,nome',
    listFields: 'codcae,nome,codSubSec',
    newFields: 'codcae,nome,codSubSec'
  },
  autocomplete: {
    rowTemplate: '{{codcae}} - {{nome}}',
    output: 'nome',
    searchFields: 'codcae,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.qpcae'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.qpcae'
      }
    }
  }
};
