import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonDivisaoTrabalho} from './jsonDivisaoTrabalho.entity.interface';
import {ENTITY_NAME_DIVISOES_TRABALHO} from './divisoesTrabalho.entity.interface';
import {ENTITY_NAME_QPDUR} from '../qpdur/qpdur.entity.interface';

const tipoTempo = [
  {value: 1, label: 'tipotempo.data.completo'},
  {value: 2, label: 'tipotempo.data.parcial'}
];

export const ENTITY_DIVISOES_TRABALHO: IEntityDefinition<IJsonDivisaoTrabalho> = {
  name: ENTITY_NAME_DIVISOES_TRABALHO,
  roles: [ROLE.RH],
  searchPlaceholder: 'divisoesTrabalho.pesquisa',
  metadata: {
    keyName: 'nDivisao',
    fields: [
      {name: 'nDivisao', type: 'string', width: '120px', caption: 'divisoesTrabalho.fields.nDivisao', validators: {required: true, maxlength: 4}},
      {name: 'designacaoBreve', caption: 'divisoesTrabalho.fields.designacaoBreve', validators: {required: true, maxlength: 20}},
      {name: 'designacaoCompleta', caption: 'divisoesTrabalho.fields.designacaoCompleta', validators: {required: true, maxlength: 40}},
      {name: 'codDuracaoQPessoal', caption: 'divisoesTrabalho.fields.codDuracaoQPessoal', visible: false},
      {
        name: 'nomeDuracaoQPessoal',
        caption: 'divisoesTrabalho.fields.codDuracaoQPessoal',
        entity: {name: ENTITY_NAME_QPDUR, keyTarget: 'codDuracaoQPessoal', output: 'nomeDuracaoQPessoal', outputTarget: 'nomeDuracaoQPessoal'},
        validators: {required: true}
      },
      {name: 'tipo', type: 'radiogroup', caption: 'divisoesTrabalho.fields.tipo', properties: {groupItems: tipoTempo}, validators: {required: true}}
    ],
    order: 'nDivisao',
    searchFields: 'nDivisao,designacaoBreve,designacaoCompleta',
    listFields: 'nDivisao,designacaoBreve,designacaoCompleta',
    newFields: 'nDivisao,designacaoBreve,designacaoCompleta,nomeDuracaoQPessoal,tipo',
    editFields: 'nDivisao,designacaoBreve,designacaoCompleta,nomeDuracaoQPessoal,tipo',
    detailFields: 'nDivisao,designacaoBreve,designacaoCompleta,nomeDuracaoQPessoal,tipo'
  },
  autocomplete: {
    rowTemplate: '{{nDivisao}} - {{designacaoBreve}}',
    output: '{{nDivisao}} - {{designacaoBreve}}',
    outputDescription: 'designacaoBreve',
    searchFields: 'nDivisao,designacaoBreve'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.divisoesTrabalho'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.divisoesTrabalho'
      }
    }
  },
  new: {
    state: {
      data: {
        pageTitle: 'global.menu.divisoesTrabalho'
      }
    }
  }
};
