import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../../components/cg/modal/cgmodal.component';
import {IJsonModelo22, IJsonModelo22ContasDef} from '../../../../../jsonModelo22.module.interface';
import {IJsonNumber} from '../../../../../../../../../common/interfaces/json';
import {IJsonPOC} from '../../../../../../../../entities/pocs/jsonPOC.entity.interface';
import {Modelo22Service} from '../../../../../modelo22.module.service';
import {IDevExpressDataGrid} from '../../../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'modal-modelo22-contasdef',
  templateUrl: './modelo22.contasDef.modal.component.html'
})
export class Modelo22ContasDefModalComponent extends CGModalComponent<IJsonModelo22> implements OnInit {
  @Input() public campo: string;
  @Input() public contasDef: Array<IJsonModelo22ContasDef>;
  @Input() public modelo22: IJsonModelo22;
  public readonly dataGridDefinition: IDevExpressDataGrid;

  public promise: Promise<void>;
  public nContaPocDe: Partial<IJsonPOC>;
  public nContaPocAte: Partial<IJsonPOC>;
  public perioAte: string;
  public sinal: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nContaDe', dataType: 'string', caption: 'modelo22.modal.rosto.contasdef.table.nContaDe'},
        {dataField: 'nContaAte', dataType: 'string', caption: 'modelo22.modal.rosto.contasdef.table.nContaAte'},
        {dataField: 'perioAte', dataType: 'string', caption: 'modelo22.modal.rosto.contasdef.table.perioAte'},
        {dataField: 'sinal', dataType: 'boolean', caption: 'modelo22.modal.rosto.contasdef.table.sinal'},
        {dataField: 'valor', dataType: 'double', caption: 'modelo22.modal.rosto.contasdef.table.valor'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnContasDef'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.modal.rosto.contasdef.title'},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    this.nContaPocDe = {nConta: ''};
    this.nContaPocAte = {nConta: 'ZZZZ'};
    this.perioAte = '121';
    this.sinal = true;
    this.dataGridDefinition.dataSource = this.contasDef;
  }

  public close(): Promise<void> {
    this.promise = this._modelo22Service.saveCamposContasDef(this.contasDef, this.campo).then((response: HttpResponse<IJsonNumber>) => {
      this._setValueByContasDef(this.campo, response.body.value);
      return this._modelo22Service.evaluateModelo22(this.modelo22).then((responseModelo22: HttpResponse<IJsonModelo22>) => {
        super.close(responseModelo22.body);
      });
    });
    return this.promise;
  }

  public nContaDeChanged(event: string): void {
    this.nContaPocDe.nConta = event;
    this.nContaPocAte = {nConta: event};
  }

  public addLine(): Promise<void> {
    if (this.contasDef.findIndex((value: IJsonModelo22ContasDef) => value.nContaDe === this.nContaPocDe.nConta) !== -1) {
      this._plAlertService.error('modelo22.messages.containicialAlredyExists');
      return Promise.resolve();
    }
    this.promise = this._modelo22Service
      .calcNewLineCamposContasDef(this.nContaPocDe.nConta, this.nContaPocAte.nConta, this.perioAte, this.sinal)
      .then((response: HttpResponse<IJsonModelo22ContasDef>) => {
        this.contasDef.push(response.body);
        this.nContaPocDe = {nConta: ''};
        this.nContaPocAte = {nConta: 'ZZZZ'};
        this.perioAte = '121';
        this.sinal = true;
        this.dataGridDefinition.dataSource = this.contasDef;
      });
    return this.promise;
  }

  public removeLine(item: IJsonModelo22ContasDef): void {
    const index = this.contasDef.findIndex((value: IJsonModelo22ContasDef) => item.nContaDe === value.nContaDe);
    if (index !== -1) {
      this.contasDef.splice(index, 1);
    }
    this.dataGridDefinition.dataSource = this.contasDef;
  }

  private _setValueByContasDef(campo: string, result: number): void {
    const value = result === 0 ? undefined : result;
    switch (campo) {
      case '701':
        this.modelo22.rosto.quadro07.campo701 = value;
        // this.calcQuadro7Field708();
        break;
      case '702':
        this.modelo22.rosto.quadro07.campo702 = value;
        // this.calcQuadro7Field708();
        break;
      case '703':
        this.modelo22.rosto.quadro07.campo703 = value;
        // this.calcQuadro7Field708();
        break;
      case '704':
        this.modelo22.rosto.quadro07.campo704 = value;
        // this.calcQuadro7Field708();
        break;
      case '705':
        this.modelo22.rosto.quadro07.campo705 = value;
        // this.calcQuadro7Field708();
        break;
      case '706':
        this.modelo22.rosto.quadro07.campo706 = value;
        // this.calcQuadro7Field708();
        break;
      case '707':
        this.modelo22.rosto.quadro07.campo707 = value;
        // this.calcQuadro7Field708();
        break;
      case '709':
        this.modelo22.rosto.quadro07.campo709 = value;
        // this.calcQuadro7Field753();
        break;
      case '710':
        this.modelo22.rosto.quadro07.campo710 = value;
        // this.calcQuadro7Field753();
        break;
      case '711':
        this.modelo22.rosto.quadro07.campo711 = value;
        // this.calcQuadro7Field753();
        break;
      case '782':
        this.modelo22.rosto.quadro07.campo782 = value;
        // this.calcQuadro7Field753();
        break;
      case '712':
        this.modelo22.rosto.quadro07.campo712 = value;
        // this.calcQuadro7Field753();
        break;
      case '713':
        this.modelo22.rosto.quadro07.campo713 = value;
        // this.calcQuadro7Field753();
        break;
      case '714':
        this.modelo22.rosto.quadro07.campo714 = value;
        // this.calcQuadro7Field753();
        break;
      case '715':
        this.modelo22.rosto.quadro07.campo715 = value;
        // this.calcQuadro7Field753();
        break;
      case '717':
        this.modelo22.rosto.quadro07.campo717 = value;
        // this.calcQuadro7Field753();
        break;
      case '721':
        this.modelo22.rosto.quadro07.campo721 = value;
        // this.calcQuadro7Field753();
        break;
      case '724':
        this.modelo22.rosto.quadro07.campo724 = value;
        // this.calcQuadro7Field753();
        break;
      case '725':
        this.modelo22.rosto.quadro07.campo725 = value;
        // this.calcQuadro7Field753();
        break;
      case '716':
        this.modelo22.rosto.quadro07.campo716 = value;
        // this.calcQuadro7Field753();
        break;
      case '731':
        this.modelo22.rosto.quadro07.campo731 = value;
        // this.calcQuadro7Field753();
        break;
      case '726':
        this.modelo22.rosto.quadro07.campo726 = value;
        // this.calcQuadro7Field753();
        break;
      case '783':
        this.modelo22.rosto.quadro07.campo783 = value;
        // this.calcQuadro7Field753();
        break;
      case '728':
        this.modelo22.rosto.quadro07.campo728 = value;
        // this.calcQuadro7Field753();
        break;
      case '727':
        this.modelo22.rosto.quadro07.campo727 = value;
        // this.calcQuadro7Field753();
        break;
      case '729':
        this.modelo22.rosto.quadro07.campo729 = value;
        // this.calcQuadro7Field753();
        break;
      case '730':
        this.modelo22.rosto.quadro07.campo730 = value;
        // this.calcQuadro7Field753();
        break;
      case '732':
        this.modelo22.rosto.quadro07.campo732 = value;
        // this.calcQuadro7Field753();
        break;
      case '733':
        this.modelo22.rosto.quadro07.campo733 = value;
        // this.calcQuadro7Field753();
        break;
      case '784':
        this.modelo22.rosto.quadro07.campo784 = value;
        // this.calcQuadro7Field753();
        break;
      case '734':
        this.modelo22.rosto.quadro07.campo734 = value;
        // this.calcQuadro7Field753();
        break;
      case '735':
        this.modelo22.rosto.quadro07.campo735 = value;
        // this.calcQuadro7Field753();
        break;
      case '780':
        this.modelo22.rosto.quadro07.campo780 = value;
        // this.calcQuadro7Field753();
        break;
      case '785':
        this.modelo22.rosto.quadro07.campo785 = value;
        // this.calcQuadro7Field753();
        break;
      case '746':
        this.modelo22.rosto.quadro07.campo746 = value;
        // this.calcQuadro7Field753();
        break;
      case '737':
        this.modelo22.rosto.quadro07.campo737 = value;
        // this.calcQuadro7Field753();
        break;
      case '786':
        this.modelo22.rosto.quadro07.campo786 = value;
        // this.calcQuadro7Field753();
        break;
      case '718':
        this.modelo22.rosto.quadro07.campo718 = value;
        // this.calcQuadro7Field753();
        break;
      case '719':
        this.modelo22.rosto.quadro07.campo719 = value;
        // this.calcQuadro7Field753();
        break;
      case '720':
        this.modelo22.rosto.quadro07.campo720 = value;
        // this.calcQuadro7Field753();
        break;
      case '722':
        this.modelo22.rosto.quadro07.campo722 = value;
        // this.calcQuadro7Field753();
        break;
      case '723':
        this.modelo22.rosto.quadro07.campo723 = value;
        // this.calcQuadro7Field753();
        break;
      case '736':
        this.modelo22.rosto.quadro07.campo736 = value;
        // this.calcQuadro7Field753();
        break;
      case '738':
        this.modelo22.rosto.quadro07.campo738 = value;
        // this.calcQuadro7Field753();
        break;
      case '739':
        this.modelo22.rosto.quadro07.campo739 = value;
        // this.calcQuadro7Field753();
        break;
      case '740':
        this.modelo22.rosto.quadro07.campo740 = value;
        // this.calcQuadro7Field753();
        break;
      case '741':
        this.modelo22.rosto.quadro07.campo741 = value;
        // this.calcQuadro7Field753();
        break;
      case '742':
        this.modelo22.rosto.quadro07.campo742 = value;
        // this.calcQuadro7Field753();
        break;
      case '743':
        this.modelo22.rosto.quadro07.campo743 = value;
        // this.calcQuadro7Field753();
        break;
      case '787':
        this.modelo22.rosto.quadro07.campo787 = value;
        // this.calcQuadro7Field753();
        break;
      case '744':
        this.modelo22.rosto.quadro07.campo744 = value;
        // this.calcQuadro7Field753();
        break;
      case '745':
        this.modelo22.rosto.quadro07.campo745 = value;
        // this.calcQuadro7Field753();
        break;
      case '747':
        this.modelo22.rosto.quadro07.campo747 = value;
        // this.calcQuadro7Field753();
        break;
      case '748':
        this.modelo22.rosto.quadro07.campo748 = value;
        // this.calcQuadro7Field753();
        break;
      case '749':
        this.modelo22.rosto.quadro07.campo749 = value;
        // this.calcQuadro7Field753();
        break;
      case '788':
        this.modelo22.rosto.quadro07.campo788 = value;
        // this.calcQuadro7Field753();
        break;
      case '750':
        this.modelo22.rosto.quadro07.campo750 = value;
        // this.calcQuadro7Field753();
        break;
      case '789':
        this.modelo22.rosto.quadro07.campo789 = value;
        // this.calcQuadro7Field753();
        break;
      case '790':
        this.modelo22.rosto.quadro07.campo790 = value;
        // this.calcQuadro7Field753();
        break;
      case '751':
        this.modelo22.rosto.quadro07.campo751 = value;
        // this.calcQuadro7Field753();
        break;
      case '779':
        this.modelo22.rosto.quadro07.campo779 = value;
        // this.calcQuadro7Field753();
        break;
      case '797':
        this.modelo22.rosto.quadro07.campo797 = value;
        // this.calcQuadro7Field753();
        break;
      case '799':
        this.modelo22.rosto.quadro07.campo799 = value;
        // this.calcQuadro7Field753();
        break;
      case '752':
        this.modelo22.rosto.quadro07.campo752 = value;
        // this.calcQuadro7Field753();
        break;
      case '754':
        this.modelo22.rosto.quadro07.campo754 = value;
        // this.calcQuadro7Field776();
        break;
      case '755':
        this.modelo22.rosto.quadro07.campo755 = value;
        // this.calcQuadro7Field776();
        break;
      case '756':
        this.modelo22.rosto.quadro07.campo756 = value;
        // this.calcQuadro7Field776();
        break;
      case '757':
        this.modelo22.rosto.quadro07.campo757 = value;
        // this.calcQuadro7Field776();
        break;
      case '791':
        this.modelo22.rosto.quadro07.campo791 = value;
        // this.calcQuadro7Field776();
        break;
      case '758':
        this.modelo22.rosto.quadro07.campo758 = value;
        // this.calcQuadro7Field776();
        break;
      case '759':
        this.modelo22.rosto.quadro07.campo759 = value;
        // this.calcQuadro7Field776();
        break;
      case '760':
        this.modelo22.rosto.quadro07.campo760 = value;
        // this.calcQuadro7Field776();
        break;
      case '761':
        this.modelo22.rosto.quadro07.campo761 = value;
        // this.calcQuadro7Field776();
        break;
      case '762':
        this.modelo22.rosto.quadro07.campo762 = value;
        // this.calcQuadro7Field776();
        break;
      case '763':
        this.modelo22.rosto.quadro07.campo763 = value;
        // this.calcQuadro7Field776();
        break;
      case '781':
        this.modelo22.rosto.quadro07.campo781 = value;
        // this.calcQuadro7Field776();
        break;
      case '764':
        this.modelo22.rosto.quadro07.campo764 = value;
        // this.calcQuadro7Field776();
        break;
      case '765':
        this.modelo22.rosto.quadro07.campo765 = value;
        // this.calcQuadro7Field776();
        break;
      case '766':
        this.modelo22.rosto.quadro07.campo766 = value;
        // this.calcQuadro7Field776();
        break;
      case '792':
        this.modelo22.rosto.quadro07.campo792 = value;
        // this.calcQuadro7Field776();
        break;
      case '767':
        this.modelo22.rosto.quadro07.campo767 = value;
        // this.calcQuadro7Field776();
        break;
      case '768':
        this.modelo22.rosto.quadro07.campo768 = value;
        // this.calcQuadro7Field776();
        break;
      case '769':
        this.modelo22.rosto.quadro07.campo769 = value;
        // this.calcQuadro7Field776();
        break;
      case '770':
        this.modelo22.rosto.quadro07.campo770 = value;
        // this.calcQuadro7Field776();
        break;
      case '793':
        this.modelo22.rosto.quadro07.campo793 = value;
        // this.calcQuadro7Field776();
        break;
      case '771':
        this.modelo22.rosto.quadro07.campo771 = value;
        // this.calcQuadro7Field776();
        break;
      case '794':
        this.modelo22.rosto.quadro07.campo794 = value;
        // this.calcQuadro7Field776();
        break;
      case '772':
        this.modelo22.rosto.quadro07.campo772 = value;
        // this.calcQuadro7Field776();
        break;
      case '795':
        this.modelo22.rosto.quadro07.campo795 = value;
        // this.calcQuadro7Field776();
        break;
      case '773':
        this.modelo22.rosto.quadro07.campo773 = value;
        // this.calcQuadro7Field776();
        break;
      case '796':
        this.modelo22.rosto.quadro07.campo796 = value;
        // this.calcQuadro7Field776();
        break;
      case '774':
        this.modelo22.rosto.quadro07.campo774 = value;
        // this.calcQuadro7Field776();
        break;
      case '800':
        this.modelo22.rosto.quadro07.campo800 = value;
        // this.calcQuadro7Field776();
        break;
      case '801':
        this.modelo22.rosto.quadro07.campo801 = value;
        // this.calcQuadro7Field776();
        break;
      case '798':
        this.modelo22.rosto.quadro07.campo798 = value;
        // this.calcQuadro7Field776();
        break;
      case '775':
        this.modelo22.rosto.quadro07.campo775 = value;
        // this.calcQuadro7Field776();
        break;
      case '347A':
        this.modelo22.rosto.quadro10.campo347A = value;
        // this.calcQuadro10Field351();
        break;
      case '347B':
        this.modelo22.rosto.quadro10.campo347B = value;
        // this.calcQuadro10Field351();
        break;
      case '349':
        this.modelo22.rosto.quadro10.campo349 = value;
        // this.calcQuadro10Field351();
        break;
      case '350':
        this.modelo22.rosto.quadro10.campo350 = value;
        // this.calcQuadro10Field351();
        break;
      case '370':
        this.modelo22.rosto.quadro10.campo370 = value;
        // this.calcQuadro10Field351();
        break;
      case '373':
        this.modelo22.rosto.quadro10.campo373 = value;
        // this.calcQuadro10Field378();
        break;
      case '353':
        this.modelo22.rosto.quadro10.campo353 = value;
        // this.calcQuadro10Field357();
        break;
      case '375':
        this.modelo22.rosto.quadro10.campo375 = value;
        // this.calcQuadro10Field357();
        break;
      case '355':
        this.modelo22.rosto.quadro10.campo355 = value;
        // this.calcQuadro10Field357();
        break;
      case '470':
        this.modelo22.rosto.quadro10.campo470 = value;
        // this.calcQuadro10Field357();
        break;
      case '356':
        this.modelo22.rosto.quadro10.campo356 = value;
        // this.calcQuadro10Field356();
        break;
      case '371':
        this.modelo22.rosto.quadro10.campo371 = value;
        // this.calcQuadro10Field361and362();
        break;
      case '359':
        this.modelo22.rosto.quadro10.campo359 = value;
        // this.calcQuadro10Field361and362();
        break;
      case '360':
        this.modelo22.rosto.quadro10.campo360 = value;
        // this.calcQuadro10Field361and362();
        break;
      case '374':
        this.modelo22.rosto.quadro10.campo374 = value;
        // this.calcQuadro10Field361and362();
        break;
      case '363':
        this.modelo22.rosto.quadro10.campo363 = value;
        // this.calcQuadro10Field367and368and364();
        break;
      case '372':
        this.modelo22.rosto.quadro10.campo372 = value;
        // this.calcQuadro10Field367and368and364();
        break;
      case '379':
        this.modelo22.rosto.quadro10.campo379 = value;
        // this.calcQuadro10Field367and368and364();
        break;
      case '365':
        this.modelo22.rosto.quadro10.campo365 = value;
        // this.calcQuadro10Field367and368and364();
        break;
      case '364':
        this.modelo22.rosto.quadro10.campo364 = value;
        // this.calcQuadro10Field367and368and364();
        break;
      case '366':
        this.modelo22.rosto.quadro10.campo366 = value;
        // this.calcQuadro10Field367and368and364();
        break;
      case '369':
        this.modelo22.rosto.quadro10.campo369 = value;
        // this.calcQuadro10Field367and368and364();
        break;
      case '410':
        this.modelo22.rosto.quadro11.campo410 = value;
        // this.calculateQuadro11();
        break;
      case '411':
        this.modelo22.rosto.quadro11.campo411 = value;
        // this.calculateQuadro11();
        break;
      case '416':
        this.modelo22.rosto.quadro11.campo416 = value;
        // this.calculateQuadro11();
        break;
    }
  }
}
