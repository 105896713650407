<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'conciliacaoEFaturas.pesquisaDocPossivelModalTitle'"></h4>
</div>

<div class="modal-body">
  <contabilidade-pesquisa-docs-linha [filters]="filters" (evtDocSelect)="assocDoc = $event"></contabilidade-pesquisa-docs-linha>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-submit" [disabled]="!assocDoc" (evtClicked)="close()">
    <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalAssociar'"></span>
  </pl-button>

  <pl-button klass="btn-light action-close" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
