import type {ToolbarItem} from 'devextreme/ui/data_grid';
import {Directive, Input, TemplateRef} from '@angular/core';
import {generateUniqueID, Logger} from 'pl-comps-angular';

@Directive({
  selector: 'ng-template[entityListDataGridToolbarItem]'
})
export class EntityListDatagridToolbarItemTemplateDirective {
  private _item: ToolbarItem;

  constructor(
    public readonly templateRef: TemplateRef<unknown>,
    private readonly _logger: Logger
  ) {}

  public get item(): ToolbarItem {
    return this._item;
  }

  @Input()
  public set entityListDataGridToolbarItemItem(value: ToolbarItem) {
    this._item = value;
    if (!this._item) {
      return;
    }
    if (!this._item.name) {
      this._logger.error('Entity list toolbar items must have a `name` property.');
    }
    if (!this._item.widget && !this._item.template) {
      this._item.template = generateUniqueID(this._item.name);
    }
    if (!this._item.location) {
      this._item.location = 'before';
    }
  }
}
