import {Injectable} from '@angular/core';
import {ApiService} from '../../../../services/api/api.service';
import {TServiceResponse} from '../../../../services/api/api.service.interface';
import {IChangeArtigoFieldResult, IChangeArtigoFieldSaveData} from './changeArtigosFieldsInSerie.interface';

@Injectable({
  providedIn: 'root'
})
export class ChangeArtigosFieldsInSerieService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/changeArtigosFields`;
  }

  public process(data: IChangeArtigoFieldSaveData): TServiceResponse<Array<IChangeArtigoFieldResult>> {
    return this._apiService.post<Array<IChangeArtigoFieldResult>, IChangeArtigoFieldSaveData>({
      url: `${this._path}/processar`,
      body: data
    });
  }
}
