import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {
  ERHRegistarEmpregadosSSContratoModalidadeEnum,
  ERHRegistarEmpregadosSSContratoMotivoEnum,
  ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum,
  IRHRegistarEmpregadosSSEditarContratoModel
} from '../../rhRegistarEmpregadosSS.module.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {ENTITY_NAME_DGEMPS_FULL, IDGEMPSFullEntityService} from '../../../dgempsfull/dgempsFull.interface';
import {ERadix, isDefinedNotNull} from 'pl-comps-angular';
import {IJsonDGEMP} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';

@Component({
  selector: 'rh-registar-empregados-ss-editar-contrato-modal',
  templateUrl: './rhRegistarEmpregadosSS.editarContrato.modal.component.html'
})
export class RHRegistarEmpregadosSSEditarContratoModalComponent extends CGModalComponent<IRHRegistarEmpregadosSSEditarContratoModel> implements OnInit {
  @Input() public empregadoInfo: string;
  @Input() public model: IRHRegistarEmpregadosSSEditarContratoModel;
  @Input() public nEmpresa: string;
  public motivoCtrSSEnabled: boolean;
  public modalidadeList: Array<{value: ERHRegistarEmpregadosSSContratoModalidadeEnum; name: string}>;
  public motivoCtrList: Array<{value: ERHRegistarEmpregadosSSContratoMotivoEnum; name: string}>;
  public prestacaoTrabalhoList: Array<{value: ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum; name: string}>;
  public percTrabalhoCtrSSEnabled: boolean;
  public horasTrabalhoCtrSSEnabled: boolean;
  public diasTrabalhoCtrSSEnabled: boolean;
  public empregadoSubsttituitSSctrEnabled: boolean;
  public registarCodEmpFilter: string;
  public empregadoSubstituir: IJsonDGEMP;

  private readonly _motivoCtrTermoCertoList: Array<{value: ERHRegistarEmpregadosSSContratoMotivoEnum; name: string}>;
  private readonly _motivoCtrTermoInCertoList: Array<{value: ERHRegistarEmpregadosSSContratoMotivoEnum; name: string}>;
  private readonly _serviceDGEMPSFull: IDGEMPSFullEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceDGEMPSFull = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS_FULL);
    this._motivoCtrTermoCertoList = [
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.NONE, name: ''},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.AEAT, name: 'rhregistarempregadosss.enums.motivoContrato.AEAT'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.ATSA, name: 'rhregistarempregadosss.enums.motivoContrato.ATSA'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.CTSD, name: 'rhregistarempregadosss.enums.motivoContrato.CTSD'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.EXTO, name: 'rhregistarempregadosss.enums.motivoContrato.EXTO'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.IFEE, name: 'rhregistarempregadosss.enums.motivoContrato.IFEE'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.LNAT, name: 'rhregistarempregadosss.enums.motivoContrato.LNAT'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.STAJ, name: 'rhregistarempregadosss.enums.motivoContrato.STAJ'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.STAT, name: 'rhregistarempregadosss.enums.motivoContrato.STAT'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.STLR, name: 'rhregistarempregadosss.enums.motivoContrato.STLR'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.STTC, name: 'rhregistarempregadosss.enums.motivoContrato.STTC'}
    ];
    this._motivoCtrTermoInCertoList = [
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.NONE, name: ''},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.AEAT, name: 'rhregistarempregadosss.enums.motivoContrato.AEAT'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.ATSA, name: 'rhregistarempregadosss.enums.motivoContrato.ATSA'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.EXTO, name: 'rhregistarempregadosss.enums.motivoContrato.EXTO'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.STAJ, name: 'rhregistarempregadosss.enums.motivoContrato.STAJ'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.STAT, name: 'rhregistarempregadosss.enums.motivoContrato.STAT'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.STLR, name: 'rhregistarempregadosss.enums.motivoContrato.STLR'}
    ];
    this.modalidadeList = [
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.NONE, name: ''},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.A, name: 'rhregistarempregadosss.enums.modalidade.A'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.B, name: 'rhregistarempregadosss.enums.modalidade.B'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.C, name: 'rhregistarempregadosss.enums.modalidade.C'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.D, name: 'rhregistarempregadosss.enums.modalidade.D'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.E, name: 'rhregistarempregadosss.enums.modalidade.E'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.EA, name: 'rhregistarempregadosss.enums.modalidade.EA'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.EB, name: 'rhregistarempregadosss.enums.modalidade.EB'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.F, name: 'rhregistarempregadosss.enums.modalidade.F'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.FA, name: 'rhregistarempregadosss.enums.modalidade.FA'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.FB, name: 'rhregistarempregadosss.enums.modalidade.FB'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.G, name: 'rhregistarempregadosss.enums.modalidade.G'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.H, name: 'rhregistarempregadosss.enums.modalidade.H'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.I, name: 'rhregistarempregadosss.enums.modalidade.I'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.J, name: 'rhregistarempregadosss.enums.modalidade.J'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.K, name: 'rhregistarempregadosss.enums.modalidade.K'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.L, name: 'rhregistarempregadosss.enums.modalidade.L'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.M, name: 'rhregistarempregadosss.enums.modalidade.M'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.N, name: 'rhregistarempregadosss.enums.modalidade.N'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.O, name: 'rhregistarempregadosss.enums.modalidade.O'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.P, name: 'rhregistarempregadosss.enums.modalidade.P'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.Q, name: 'rhregistarempregadosss.enums.modalidade.Q'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.R, name: 'rhregistarempregadosss.enums.modalidade.R'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.S, name: 'rhregistarempregadosss.enums.modalidade.S'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.AA, name: 'rhregistarempregadosss.enums.modalidade.AA'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.AB, name: 'rhregistarempregadosss.enums.modalidade.AB'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.BA, name: 'rhregistarempregadosss.enums.modalidade.BA'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.BB, name: 'rhregistarempregadosss.enums.modalidade.BB'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.GA, name: 'rhregistarempregadosss.enums.modalidade.GA'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.GB, name: 'rhregistarempregadosss.enums.modalidade.GB'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.HA, name: 'rhregistarempregadosss.enums.modalidade.HA'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.HB, name: 'rhregistarempregadosss.enums.modalidade.HB'}
    ];
    this.prestacaoTrabalhoList = [
      {value: ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum.P, name: 'rhregistarempregadosss.enums.prestacaoTrabalhoContrato.P'},
      {value: ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum.T, name: 'rhregistarempregadosss.enums.prestacaoTrabalhoContrato.T'}
    ];
    this.motivoCtrSSEnabled = false;
    this.percTrabalhoCtrSSEnabled = false;
    this.horasTrabalhoCtrSSEnabled = false;
    this.diasTrabalhoCtrSSEnabled = false;
    this.empregadoSubsttituitSSctrEnabled = false;
    this.registarCodEmpFilter = '';
    this.empregadoSubstituir = undefined;
  }

  public ngOnInit(): void {
    this.registarCodEmpFilter = `nempresa=${this.nEmpresa}&ativo=true`;
    if (this.model.codmodalidadeContrato.length) {
      this.model.codmodalidadeContratoRnum = ERHRegistarEmpregadosSSContratoModalidadeEnum[this.model.codmodalidadeContrato];
      this.modalidadeCtrSSChanged(this.model.codmodalidadeContratoRnum);
    }

    if (this.model.codmotivoContrato.length) {
      this.model.codmotivoContratoEnum = ERHRegistarEmpregadosSSContratoMotivoEnum[this.model.codmotivoContrato];
      this.motivoCtrSSChanged(this.model.codmotivoContratoEnum);
    }
    if (this.model.codprestacaoTrabalho.length) {
      this.model.codprestacaoTrabalhoEnum = ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum[this.model.codprestacaoTrabalho];
    }
    if (this.model.nissTrabalhadorSubstituir > 0) {
      this._serviceDGEMPSFull.getEmpregadoByNISS(this.model.nissTrabalhadorSubstituir.toString()).then((response) => {
        this.empregadoSubstituir = response.body;
      });
    }
  }

  public modalidadeCtrSSChanged(value: ERHRegistarEmpregadosSSContratoModalidadeEnum): void {
    this.model.codmodalidadeContratoRnum = value;
    this.model.codmotivoContratoEnum = ERHRegistarEmpregadosSSContratoMotivoEnum.NONE;
    if (
      [
        ERHRegistarEmpregadosSSContratoModalidadeEnum.E,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.EA,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.EB,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.F,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.FA,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.FB,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.G,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.GA,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.GB,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.H,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.HA,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.HB,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.O,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.Q,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.N,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.P
      ].includes(this.model.codmodalidadeContratoRnum)
    ) {
      this.motivoCtrSSEnabled = true;
      if (
        [
          ERHRegistarEmpregadosSSContratoModalidadeEnum.E,
          ERHRegistarEmpregadosSSContratoModalidadeEnum.EA,
          ERHRegistarEmpregadosSSContratoModalidadeEnum.EB,
          ERHRegistarEmpregadosSSContratoModalidadeEnum.F,
          ERHRegistarEmpregadosSSContratoModalidadeEnum.FA,
          ERHRegistarEmpregadosSSContratoModalidadeEnum.FB,
          ERHRegistarEmpregadosSSContratoModalidadeEnum.O,
          ERHRegistarEmpregadosSSContratoModalidadeEnum.N
        ].includes(this.model.codmodalidadeContratoRnum)
      ) {
        this.motivoCtrList = this._motivoCtrTermoCertoList;
      } else {
        this.motivoCtrList = this._motivoCtrTermoInCertoList;
      }
    }

    this.percTrabalhoCtrSSEnabled = [
      ERHRegistarEmpregadosSSContratoModalidadeEnum.B,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.BA,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.BB,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.F,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.FA,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.FB,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.H,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.HA,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.HB,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.D,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.R,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.N,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.P
    ].includes(this.model.codmodalidadeContratoRnum);

    this.horasTrabalhoCtrSSEnabled = this.percTrabalhoCtrSSEnabled;
    this.diasTrabalhoCtrSSEnabled = this.percTrabalhoCtrSSEnabled;
  }

  public motivoCtrSSChanged(value: ERHRegistarEmpregadosSSContratoMotivoEnum): void {
    this.model.codmotivoContratoEnum = value;
    if (
      [
        ERHRegistarEmpregadosSSContratoMotivoEnum.STAJ,
        ERHRegistarEmpregadosSSContratoMotivoEnum.STAT,
        ERHRegistarEmpregadosSSContratoMotivoEnum.STLR,
        ERHRegistarEmpregadosSSContratoMotivoEnum.STTC
      ].includes(this.model.codmotivoContratoEnum)
    ) {
      this.empregadoSubsttituitSSctrEnabled = true;
    } else {
      this.empregadoSubsttituitSSctrEnabled = false;
    }
  }

  public onEmpregadoSubstituirChanged(empregado: IJsonDGEMP): void {
    this.empregadoSubstituir = empregado;
    if (isDefinedNotNull(this.empregadoSubstituir)) {
      this._serviceDGEMPSFull.getFull({id: this.empregadoSubstituir.codEmp}).then((response) => {
        this.model.nissTrabalhadorSubstituir = response.body.numSegSoc.length ? parseInt(response.body.numSegSoc, ERadix.Decimal) : 0;
      });
      this.model.nissTrabalhadorSubstituirNome = this.empregadoSubstituir.nome;
    } else {
      this.model.nissTrabalhadorSubstituir = 0;
      this.model.nissTrabalhadorSubstituirNome = '';
    }
  }

  public applyModel(): void {
    this.model.codmodalidadeContrato = ERHRegistarEmpregadosSSContratoModalidadeEnum[this.model.codmodalidadeContratoRnum];
    this.model.codmotivoContrato = ERHRegistarEmpregadosSSContratoMotivoEnum[this.model.codmotivoContratoEnum];
    this.model.codprestacaoTrabalho = ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum[this.model.codprestacaoTrabalhoEnum];
    this.close(this.model);
  }
}
