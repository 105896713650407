<div class="cg-user-nav">
  <button type="button" class="btn-close" (click)="dropdownRef.close()" *ngIf="dropdownRef"></button>

  <div class="cg-user-nav-profile-image">
    <cg-user-profile-image></cg-user-profile-image>
  </div>

  <div class="cg-user-nav-profile-name" [translate]="'components.usernav.name'" [translateParams]="{name: name}"></div>

  <div class="cg-user-nav-email">{{ email }}</div>

  <div class="cg-user-nav-feedback" *ngIf="userFeedback">
    <cg-user-feedback></cg-user-feedback>
  </div>

  <div class="cg-user-nav-store" *ngIf="storeModePublic">
    <a class="btn btn-outline-primary action-store" [href]="cgStoreUrl" target="_blank"> <i cgcIcon="fa-shopping-cart"></i>&nbsp;<span [translate]="'components.usernav.store'"></span> </a>
  </div>

  <div class="btn-group cg-user-nav-actions" role="group">
    <button type="button" class="btn btn-link action-change-empresa-dialog" (click)="changeEmpresaDialog()">
      <i cgcIcon="fa-institution">&nbsp;</i><span [translate]="'global.menu.account.changeEmpresa'"></span>
    </button>

    <a [uiSref]="stateNameLogin" class="btn btn-link action-logout"><i cgcIcon="fa-sign-out"></i>&nbsp;<span [translate]="'global.menu.account.logout'"></span></a>
  </div>

  <div *ngIf="!storeModePublic && !mobile && recentCompanies.length" class="card cg-user-nav-recent-companies" [class.cg-user-nav-recent-companies-collapsed]="collapseRecentCompanies">
    <div class="card-header p-0">
      <button type="button" class="btn cg-user-nav-recent-companies-toggler" (click)="toggleCollapseRecentCompanies()">
        <span [translate]="!collapseRecentCompanies ? 'components.usernav.hideRecentCompanies' : 'components.usernav.showRecentCompanies'"></span>
        <i class="cg-user-nav-recent-companies-toggler-icon" cgcIcon="fa-chevron-circle-up"></i>
      </button>
    </div>

    <div class="list-group list-group-flush" [ngbCollapse]="collapseRecentCompanies">
      <button *ngFor="let company of recentCompanies" type="button" class="list-group-item list-group-item-action cg-user-nav-recent-company pt-1 pb-1" [click]="fnChangeCompany(company)" plPromise>
        {{ company.nEmpresa }} - {{ company.nomeEmpresa }}
      </button>
    </div>
  </div>

  <div *ngIf="localeChooser || skinChooser" class="cg-user-nav-footer">
    <hr />

    <div class="d-flex gap-3 justify-content-center">
      <cg-user-locale-chooser *ngIf="localeChooser"></cg-user-locale-chooser>

      <cg-user-skin-chooser *ngIf="skinChooser"></cg-user-skin-chooser>
    </div>
  </div>
</div>
