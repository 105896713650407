<div class="configsSite entity-detail-form">
  <pl-form [properties]="{validators: {required: {value: true}}}" (ngFormChange)="form = $event">
    <div *ngFor="let config of configsEmail">
      <pl-group>
        <label [translate]="'configsSite.configs.' + config.configId"></label>
        <edit>
          <pl-edit [type]="config.type" [attrName]="config.configId" [(model)]="config.value" [properties]="{readonly: config.readonly, trueValue: '1', falseValue: '0'}"></pl-edit>
        </edit>
      </pl-group>
    </div>
  </pl-form>
</div>
