import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IJsonRHQPIRA, IJsonRHQPIRCT, IJsonRHQPIRCTImpQPIRCT} from '../jsonRHQPIRCT.module.interface';
import {ITree, ITreeItem} from '../../../../components/treeviewer/treeviewer.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RHQPIRCTImportedListModalComponent} from '../modals/importedlist/rhQPIRCT.importedList.modal.component';
import {RHQPIRCTImportFileModalComponent} from '../modals/importfile/rhQPIRCT.importFile.modal.component';
import {RHQPIRCTService} from '../rhQPIRCT.module.service';
import {IPlToolbarItem, isEmpty} from 'pl-comps-angular';

@Component({
  selector: 'module-rhqpirct',
  templateUrl: './rhQPIRCT.module.component.html'
})
export class RHQPIRCTModuleComponent extends ModuloComponent implements OnInit {
  @Input() public listQPIRCT: Array<IJsonRHQPIRCT>;

  public readonly saveBtn: IPlToolbarItem;
  public listQPIRCTTree: ITree;
  public qpirctTreeSelected: ITreeItem;
  public qpirctSelected: IJsonRHQPIRCT;
  public qpiratSelected: IJsonRHQPIRA;
  public codIRCT: string;
  public nomeIRCT: string;
  public codCategoria: string;
  public nomeCategoria: string;
  public isCategoria: boolean;
  public promise: Promise<void>;
  public isVisible: boolean;
  public isVisibleSaveAndAddButtons: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhQPIRCTService: RHQPIRCTService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.isVisible = true;
    this.isVisibleSaveAndAddButtons = false;
    this._initValues();
    this.saveBtn = {
      id: 'save',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>',
      class: 'btn-primary',
      caption: 'global.btn.save',
      visible: this.isVisibleSaveAndAddButtons,
      click: () => this._saveIRCTCateg()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._convertListQPIRCTtoTree();
    this.toolbar.addButton(this.saveBtn);
  }

  public modelChanged(item: ITreeItem): Promise<void> {
    this.saveBtn.promise = this.promise;
    if (!item.parentNodeID && !item.childNodes.length) {
      this.promise = this._rhQPIRCTService.getListaQPIRA(String(item.nodeId)).then((response: HttpResponse<Array<IJsonRHQPIRA>>) => {
        const index: number = this.listQPIRCT.findIndex((value: IJsonRHQPIRCT) => value.codIRCT === String(item.nodeId));
        if (index !== -1) {
          this.listQPIRCT[index].listQPIRA = response.body;
          for (const rhQPIRA of response.body) {
            const childItem: ITreeItem = {
              selectable: true,
              nodeId: rhQPIRA.codCategoria,
              nodeText: `${rhQPIRA.codCategoria} - ${rhQPIRA.nome}`,
              parentNodeID: item.nodeId
            };
            item.childNodes.push(childItem);
          }
        }
      });
    }

    return Promise.resolve(this.promise)
      .then(() => {
        const codIRCT = !item.parentNodeID ? item.nodeId : item.parentNodeID;

        const index: number = this.listQPIRCT.findIndex((value: IJsonRHQPIRCT) => value.codIRCT === String(codIRCT));
        if (index !== -1) {
          this.qpirctSelected = this.listQPIRCT[index];
          this.qpiratSelected = undefined;
          this.codIRCT = this.listQPIRCT[index].codIRCT;
          this.nomeIRCT = this.listQPIRCT[index].nome;
          this.codCategoria = '';
          this.nomeCategoria = '';
          this.isCategoria = !isEmpty(item.parentNodeID);

          if (!isEmpty(item.parentNodeID)) {
            const indexA: number = this.listQPIRCT[index].listQPIRA.findIndex((value: IJsonRHQPIRA) => value.codCategoria === item.nodeId);
            if (indexA !== -1) {
              this.qpiratSelected = this.listQPIRCT[index].listQPIRA[indexA];
              this.codCategoria = this.listQPIRCT[index].listQPIRA[indexA].codCategoria;
              this.nomeCategoria = this.listQPIRCT[index].listQPIRA[indexA].nome;
            }
          }
        }
      })
      .finally(() => {
        this.promise = undefined;
      });
  }

  public createIRCT(): void {
    this.codIRCT = '';
    this.nomeIRCT = '';
    this.codCategoria = '';
    this.nomeCategoria = '';
    this.isCategoria = false;
  }

  public createCateg(): void {
    this.codIRCT = this.qpirctSelected.codIRCT;
    this.nomeIRCT = this.qpirctSelected.nome;
    this.codCategoria = '';
    this.nomeCategoria = '';
    this.isCategoria = true;
  }

  public importFile(): Promise<void> {
    return this._cgModalService.show<IJsonRHQPIRCTImpQPIRCT>(RHQPIRCTImportFileModalComponent).then((response: IJsonRHQPIRCTImpQPIRCT) => {
      const modalInstance: NgbModalRef = this._cgModalService.showVanilla(RHQPIRCTImportedListModalComponent);
      const componentInstance: RHQPIRCTImportedListModalComponent = modalInstance.componentInstance;
      componentInstance.qpirctImp = response;
      return modalInstance.result.then((result: Array<IJsonRHQPIRCT>) => {
        this.listQPIRCT = result;
        this._initValues();
        this._convertListQPIRCTtoTree();
      });
    });
  }

  private _initValues(): void {
    this.codIRCT = '';
    this.nomeIRCT = '';
    this.codCategoria = '';
    this.nomeCategoria = '';
    this.isCategoria = false;
    this.qpirctSelected = undefined;
    this.qpiratSelected = undefined;
    this.qpirctTreeSelected = {
      nodeText: ''
    };
    this.listQPIRCTTree = {
      nodes: []
    };
  }

  private _convertListQPIRCTtoTree(): void {
    for (const rhQPIRCT of this.listQPIRCT) {
      const childNodes: Array<ITreeItem> = [];
      for (const rhQPIRA of rhQPIRCT.listQPIRA) {
        const childItem: ITreeItem = {
          selectable: true,
          nodeId: rhQPIRA.codCategoria,
          nodeText: `${rhQPIRA.codCategoria} - ${rhQPIRA.nome}`,
          parentNodeID: rhQPIRCT.codIRCT
        };
        childNodes.push(childItem);
      }
      const treeItem: ITreeItem = {
        collapsed: true,
        selectable: true,
        nodeId: rhQPIRCT.codIRCT,
        nodeText: `${rhQPIRCT.codIRCT} - ${rhQPIRCT.nome}`,
        parentNodeID: '',
        childNodes: childNodes
      };
      this.listQPIRCTTree.nodes.push(treeItem);
    }
  }

  private _saveIRCTCateg(): Promise<void> {
    if (this.promise) {
      return Promise.resolve();
    }
    this.saveBtn.promise = this.promise;
    this.promise = this._rhQPIRCTService
      .getSaveIRCTCateg(this.codIRCT, this.nomeIRCT, this.codCategoria, this.nomeCategoria, this.isCategoria)
      .then((response: HttpResponse<Array<IJsonRHQPIRCT>>) => {
        this.listQPIRCT = response.body;
        this._initValues();
        this._convertListQPIRCTtoTree();
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }
}
