<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'efatura.comunicaDocsModal.title'"></h4>
</div>

<div class="modal-body">
  <div class="entity-detail-form">
    <dx-data-grid
      [cgDxDataGrid]="definition"
      (onInitialized)="onDataGridInitialized($event)"
      (onContentReady)="onContentReady()"
      [cgDxDataGridInstanceName]="dataGridInstanceName"
      [dataSource]="docsList">
    </dx-data-grid>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-primary btn-sm action-submit" (click)="close()"><i class="fa fa-fw fa-copy"></i>&nbsp;<span [translate]="'efatura.btnComunicaDocs'"></span></button>

  <button type="button" (click)="dismiss()" class="btn btn-light btn-sm action-cancel"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
