<div class="colaboradores-docs-plataforma">
  <div class="entity-detail-form">
    <pl-form>
      <pl-split-view [initialSizeLeft]="50">
        <div *plSplitViewLeftSide>
          <pl-group>
            <label><span [translate]="'colaboradores.docsplata.text.servicos'"></span></label>
            <edit>
              <pl-multiselect class="servicos" [(model)]="servicosSelected" [source]="servicos" key="codServico" [template]="templateServicosSelected"></pl-multiselect>
            </edit>
          </pl-group>
        </div>

        <div *plSplitViewRightSide>
          <pl-group>
            <label><span [translate]="'colaboradores.docsplata.text.papeis'"></span></label>
            <edit>
              <pl-multiselect class="papeis" [(model)]="papeisSelected" [source]="papeis" key="codPapel" [template]="templatePapeisSelected"></pl-multiselect>
            </edit>
          </pl-group>
        </div>
      </pl-split-view>

      <pl-group class="not-print">
        <edit>
          <button type="button" class="btn btn-outline btn-primary btn-sm btn-pesquisar" [click]="fnGetDocsPlataList" plPromise>
            <i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
          </button>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="tableDocsPlataforma" [dataSource]="dataGridDefinition.dataSource" (onInitialized)="onInitialized($event)">
    <div *dxTemplate="let item of 'cellTemplateBtn'">
      <a class="btn btn-primary btn-xs btn-pdf" [href]="item.data.url" [attr.download]="item.data.titulo" target="_blank">
        <i class="fa fa-fw fa-file-o"></i>
      </a>

      <pl-button klass="btn btn-xs btn-danger" [onClick]="fnDelete(item.data)">
        <i class="fa fa-fw fa-trash"></i>
      </pl-button>
    </div>

    <div *dxTemplate="let item of 'masterDetailTemplate'">
      <p>
        <strong name="servico"><span [translate]="'colaboradores.docsplata.text.servico'"></span>:</strong> {{ item.data.servico }}
      </p>
      <p>
        <strong name="papel"><span [translate]="'colaboradores.docsplata.text.papel'"></span>:</strong> {{ item.data.papel }}
      </p>
    </div>
  </dx-data-grid>
</div>
