import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_CONCELHOS, IConcelhosEntityService} from '../../../../../../entities/concelhos/concelhos.entity.interface';
import {EntityServiceBuilder} from '../../../../../../services/entity/entity.service.builder';
import {ETiposDadosDerrama} from '../../../modelo22.module.interface';
import {IJsonConcelho} from '../../../../../../entities/concelhos/jsonConcelho.entity.interface';
import {IJsonModelo22, IJsonModelo22CompDerrama} from '../../../jsonModelo22.module.interface';
import {Modelo22Service} from '../../../modelo22.module.service';

@Component({
  selector: 'modal-modelo22-derrama',
  templateUrl: './modelo22.derrama.modal.component.html'
})
export class Modelo22DerramaModalComponent extends CGModalComponent<IJsonModelo22> implements OnInit {
  @Input() public derrama: IJsonModelo22CompDerrama;
  @Input() public modelo22: IJsonModelo22;
  @Input() public fromConcelhos: boolean;

  public hasPostosTrabalhoCriados: boolean;
  public hasVolumeNegocios: boolean;
  public hasInvestimentoNoAno: boolean;
  public hasTemSedeNoConcelho: boolean;
  public hasAnoFixacaoDaSede: boolean;
  public hasMicroPequenaMediaEmpresa: boolean;
  public hasAcrescimoTrabalhadores: boolean;
  public hasPostosTrabalhoCriados2019: boolean;
  public hasPostosTrabalhoCriados2020: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.hasPostosTrabalhoCriados = false;
    this.hasVolumeNegocios = false;
    this.hasInvestimentoNoAno = false;
    this.hasTemSedeNoConcelho = false;
    this.hasAnoFixacaoDaSede = false;
    this.hasMicroPequenaMediaEmpresa = false;
    this.hasAcrescimoTrabalhadores = false;
    this.hasPostosTrabalhoCriados2019 = false;
    this.hasPostosTrabalhoCriados2020 = false;
    this.fromConcelhos = false;
  }

  public ngOnInit(): void {
    this._evaluateTiposDerrama();
  }

  public async close(): Promise<void> {
    if (this.fromConcelhos) {
      await this._entityServiceBuilder
        .build<IJsonConcelho, IConcelhosEntityService>(ENTITY_NAME_CONCELHOS)
        .postCalculoDerrama(this.derrama)
        .then(() => {
          super.close(undefined);
        });
    }
    await this._modelo22Service.saveCalculoDerrama(this.derrama).then(() => {
      return this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
        super.close(response.body);
      });
    });
  }

  private _evaluateTiposDerrama(): void {
    for (const tipo of this.derrama.listaTipos) {
      switch (tipo) {
        case ETiposDadosDerrama.PostosTrabalhoCriados:
          this.hasPostosTrabalhoCriados = true;
          break;
        case ETiposDadosDerrama.VolumeNegocios:
          this.hasVolumeNegocios = true;
          break;
        case ETiposDadosDerrama.InvestimentoNoAno:
          this.hasInvestimentoNoAno = true;
          break;
        case ETiposDadosDerrama.TemSedeNoConcelho:
          this.hasTemSedeNoConcelho = true;
          break;
        case ETiposDadosDerrama.AnoFixacaoDaSede:
          this.hasAnoFixacaoDaSede = true;
          break;
        case ETiposDadosDerrama.MicroPequenaMediaEmpresa:
          this.hasMicroPequenaMediaEmpresa = true;
          break;
        case ETiposDadosDerrama.AcrescimoTrabalhadores:
          this.hasAcrescimoTrabalhadores = true;
          break;
        case ETiposDadosDerrama.PostosTrabalhoCriados2019:
          this.hasPostosTrabalhoCriados2019 = true;
          break;
        case ETiposDadosDerrama.PostosTrabalhoCriados2020:
          this.hasPostosTrabalhoCriados2020 = true;
          break;
      }
    }
  }
}
