import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {HttpResponse} from '@angular/common/http';
import {EFEAEstado, IJsonFaturacaoPublica} from '../../jsonFaturacaoPublica.module.interface';
import {FaturacaoPublicaService} from '../../faturacaoPublica.module.service';

@Component({
  selector: 'modal-faturacaopublica-observacoes',
  templateUrl: './faturacaoPublica.observacoes.modal.component.html'
})
export class FaturacaoPublicaObservacoesModalComponent extends CGModalComponent<IJsonFaturacaoPublica> {
  @Input() public faccbID: number;
  @Input() public estado: EFEAEstado;

  public observacoes: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _faturacaoPublicaService: FaturacaoPublicaService
  ) {
    super(_injector);
    this.observacoes = '';
  }

  public close(): Promise<void> {
    if (isEmpty(this.observacoes)) {
      this._plAlertService.error('faturacaopublica.messages.observacoesvazia');
      return Promise.resolve();
    }
    return this._faturacaoPublicaService.marcarComoComunicadoManual(this.faccbID, this.estado, this.observacoes).then((response: HttpResponse<IJsonFaturacaoPublica>) => {
      super.close(response.body);
    });
  }
}
