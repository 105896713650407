import {Component, Injector, Input} from '@angular/core';
import {IPlUploadCallback, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_DOCS_PARTILHADOS, IDocsPartilhadosEntityService} from '../../../../entities/docspartilhados/docsPartilhados.entity.interface';
import {IJsonGDFile} from '../../../../entities/gdoc/JsonGDoc.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {TDate} from '../../../../../common/dates';

@Component({
  selector: 'documentospartilhados-upload-modal',
  templateUrl: './documentosPartilhados.upload.modal.component.html'
})
export class DocumentosPartilhadosUploadModalComponent extends CGModalComponent<void> {
  @Input() public uploadUrl: string;
  @Input() public refDate: TDate;

  public readonly plUpload: IPlUploadCallback;
  private readonly _files: Array<File>;
  private readonly _docPartilhado: IJsonGDFile;
  private readonly _documentosPartilhadosService: IDocsPartilhadosEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._documentosPartilhadosService = this._entityServiceBuilder.build(ENTITY_NAME_DOCS_PARTILHADOS);
    this.plUpload = {};
    this._files = [];
    this._docPartilhado = {
      docID: '',
      nome: '',
      nDocExterno: '',
      tamanho: '',
      extensao: '',
      deleted: false,
      dataModificacao: undefined,
      horaModificacao: undefined,
      dataCriacaoReg: undefined,
      horaCriacaoReg: undefined,
      nomeUtilizActuReg: '',
      path: '',
      folderID: '',
      visualizado: false,
      nomeExtensao: '',
      mimeType: ''
    };
  }

  public readonly fnSendFile: () => Promise<void> = () => this._sendFiles();

  private _sendFiles(): Promise<void> {
    for (const plUploadFile of this.plUpload.getAcceptedFiles()) {
      this._files.push(plUploadFile.file);
    }
    this._docPartilhado.dataModificacao = this.refDate;
    this._docPartilhado.horaModificacao = this.refDate;
    return this._documentosPartilhadosService.uploadDoc(this._files, this._docPartilhado).then(() => {
      this._plAlertService.success('docsPartilhados.info.fileUploadSuccess');
      this.close();
    });
  }
}
