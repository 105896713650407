import {Component, Injector} from '@angular/core';
import {ConfigsErpBaseModuleComponent} from '../../configs.erp.base.module.component';
import {EConfigsErpDepth, IConfigsErpEditGroup} from '../../components/configedit/configs.erp.edit.component.interface';
import {IJsonConfigERP} from '../../../../../entities/configserp/jsonConfigERP.entity.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

const CONFIG_TYPES: Array<string> = [
  'contabilidade.decimais',
  'contabilidade.pagamentos',
  'contabilidade.recibos',
  'contabilidade.documentos',
  'contabilidade.balancetes',
  'contabilidade.extratos',
  'contabilidade.movimentosemaberto',
  'contabilidade.apuraiva',
  'contabilidade.reparticaocc',
  'contabilidade.listagemdiarios'
];

@Component({
  selector: 'module-configs-erp-contabilidade',
  templateUrl: './configs.contabilidade.module.component.html'
})
export class ConfigsErpContabilidadeModuleComponent extends ConfigsErpBaseModuleComponent {
  public readonly definition: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.definition = {
      columns: [
        {dataField: 'description', dataType: 'string', caption: 'entity.detail.field'},
        {dataField: 'value', caption: 'entity.detail.value', alignment: 'left'}
      ]
    };
  }

  public get depthLevel(): EConfigsErpDepth {
    return EConfigsErpDepth.Multiple;
  }

  protected _parseList(rawConfigurations: Array<IJsonConfigERP>): Array<IConfigsErpEditGroup> {
    const groupsMap: Map<string, IConfigsErpEditGroup> = new Map<string, IConfigsErpEditGroup>();
    for (const item of rawConfigurations) {
      for (const type of CONFIG_TYPES) {
        if (item.name.startsWith(`${type}.`)) {
          this._evaluateItemForReport(item);

          const groupStartIndex: number = item.name.indexOf('.') + 1;
          const groupEndIndex: number = item.name.indexOf('.', groupStartIndex);
          const groupProperty: string = item.name.substring(groupStartIndex, groupEndIndex);

          let group: IConfigsErpEditGroup = groupsMap.get(groupProperty);
          if (!group) {
            group = {
              id: groupProperty,
              title: `configsErp.items.${type}Title`,
              items: []
            };
            groupsMap.set(groupProperty, group);
          }

          let filter = '';
          if (item.name === 'contabilidade.pagamentos.meioPagamentoOmissao') {
            filter = 'tipoMovimento<>1';
          }
          if (item.name === 'contabilidade.recibos.meioPagamentoOmissao') {
            filter = 'tipoMovimento<>2';
          }
          if (item.name === 'contabilidade.pagamentos.lotesEstadoOmissao') {
            filter = 'grupoEstadop=19';
          }

          group.items.push({...item, index: group.items.length, saveField: this.fnSaveField, filter: filter});

          break;
        }
      }
    }
    return Array.from(groupsMap.values());
  }

  private _evaluateItemForReport(item: IJsonConfigERP): void {
    switch (item.name) {
      case 'contabilidade.recibos.reportImpressao':
        item.cguid = 'reports_recibos';
        break;
      case 'contabilidade.pagamentos.reportImpressao':
        item.cguid = 'reports_pagamentos';
        break;
      case 'contabilidade.documentos.reportImpressao':
        item.cguid = 'reports_documentos';
        break;
      case 'contabilidade.balancetes.reportImpressao':
        item.cguid = 'reports_balancetes_normal';
        break;
      case 'contabilidade.extratos.reportImpressao':
        item.cguid = 'reports_extratos';
        break;
      case 'contabilidade.movimentosemaberto.reportImpressao':
        item.cguid = 'reports_movabs';
        break;
      case 'contabilidade.impdecpe.reportImpressao':
        item.cguid = 'reports_impdecpe';
        break;
      case 'contabilidade.svat.reportImpressao':
        item.cguid = 'reports_svat';
        break;
      case 'contabilidade.listagemdisarios.reportImpressao':
        item.cguid = 'reports_listagemdiarios';
        break;
      case 'contabilidade.conciliacaoBancaria.reportImpressao':
        item.cguid = 'reports_conciliacao_bancaria';
        break;
    }
  }
}
