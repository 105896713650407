<div class="txtcl">
  <entity-detail
    entityName="txtcl"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [definition]="definition" [model]="model" [properties]="{readonly: isDetailState}"></pl-form>
    </div>
  </entity-detail>
</div>
