import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_TIPO_CONTRATO} from './tipoContrato.datasource.interface';

export const DATA_SOURCE_TIPO_CONTRATO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_CONTRATO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'tipoContrato.data.semtipocontrato'},
    {value: 1, name: 'tipoContrato.data.contratosemtermo'},
    {value: 2, name: 'tipoContrato.data.contratoatermo'},
    {value: 3, name: 'tipoContrato.data.contratotrabtempoindeterm'},
    {value: 4, name: 'tipoContrato.data.contratotrabalhotermocedtemp'},
    {value: 5, name: 'tipoContrato.data.contratoprestoserviço'}
  ]
});
