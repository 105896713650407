export enum EChartType {
  Vendas,
  TesourariaLiquida,
  Rendimentos,
  GastosCompras
}

export enum EDashboardType {
  NotApplicable,
  Table,
  Chart
}

export interface IJsonDashboardBase {
  type: EDashboardType;
  typeName: string;
  title: string;
}

export interface IJsonDashboard extends IJsonDashboardBase {
  data: Array<IJsonDashboardData>;
}

export interface IJsonPieDashboard extends IJsonDashboardBase {
  data: Array<IJsonDashboardPieData>;
}

export interface IJsonDashboardData {
  label: string;
  periodo: string;
  serie: number;
  title: string;
  type: string;
  value: number;
}

export interface IJsonDashboardPieData {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Nome: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'Nº Conta': string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Total: number;
}

export interface IDashboardChartVendas {
  month: string;
  valueVenda: number;
}

export interface IDashboardChartTesourariaLiquida {
  month: string;
  valueDisponibilidade: number;
  valueFinanciamento: number;
}

export interface IDashboardChartRendimentos {
  name: string;
  value: number;
}

export interface IDashboardChartGastosCompras {
  name: string;
  value: number;
}
