import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonAtivosGeraMapasAuto} from './jsonAtivosGeraMapasAuto.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AtivosGeraMapasAutoService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/ativosgeramapasauto`;
  }

  public getBens(): TServiceResponse<Array<IJsonAtivosGeraMapasAuto>> {
    return this._apiService.get<Array<IJsonAtivosGeraMapasAuto>>({url: this._path});
  }

  public getExistGeneratedMaps(): TServiceResponse<boolean> {
    return this._apiService.get<boolean>({url: `${this._path}/existgeneratedmaps`});
  }

  public gerarMapasAutomaticos(): TServiceResponse<unknown> {
    return this._apiService.post({url: `${this._path}/gerarmapasautomaticos`, body: null});
  }
}
