<div class="ativoscoeficientecm entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="pesquisaCoeficientes" plPromise>
        <pl-group>
          <label [translate]="'ativoscoeficientecm.header.anoPesquisa'"></label>
          <edit>
            <pl-autocomplete [source]="metodoSource" [model]="ano" (modelChange)="ano = $event" [allowInvalid]="false"></pl-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <edit>
            <pl-button type="submit" klass="btn btn-sm btn-primary"><i class="fa fa-fw fa-search"></i><span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="ativosCoeficienteCM"
    [dataSource]="coeficienteCMList"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()"
    (onSelectionChanged)="onSelectionChanged()"
    [(selectedRowKeys)]="selectedRowKeys">
  </dx-data-grid>
</div>
