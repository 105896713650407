import {IJsonDGEMP} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';

export enum EDgempsFichaChangableFields {
  NOME = 'nome',
  APELIDO = 'apelido',
  MORADA = 'morada',
  RUA = 'rua',
  COD_POSTAL = 'codPostal',
  NOME_LOCALIDA = 'nomeLocalida',
  EMAIL = 'email',
  NTELEFONE = 'nTelefone',
  TELEMOVELPART1 = 'telemovelPart1',
  IBAN = 'iban',
  SWIFT = 'swift'
}

export interface IDgempsFichaModel extends IJsonDGEMP {
  iban: string;
  swift: string;
}
