import {assign} from 'lodash-es';
import {MomentInput} from 'moment';
import {Component, DoCheck, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChildren} from '@angular/core';
import {
  IPlScheduler,
  IPlSchedulerDataset,
  IPlSchedulerDatasetDetailTemplateContext,
  IPlSchedulerDatasetHeaderDetailTemplateContext,
  IPlSchedulerDatasetItem,
  IPlSchedulerDatasetItemTemplateContext,
  IPlSchedulerDatasetTemplateContext,
  IPlSchedulerEvtCellClick,
  IPlSchedulerEvtRangeSelect,
  IPlSchedulerEvtSelectionChanged,
  IPlSchedulerHeaderTemplateContext,
  IPlSchedulerRange,
  SCHEDULER_DEFAULT_RANGE
} from './scheduler.component.interface';
import {isBoolean, isNumber, isObject} from '../common/utilities/utilities';
import {ngClassAdd, ngClassRemove} from '../common/utilities/angular.utilities';
import {Logger} from '../logger/logger';

const KLASS_SELECTED = 'pl-scheduler-cell-selected';

@Component({
  selector: 'pl-scheduler',
  templateUrl: './scheduler.component.html',
  standalone: false
})
export class PlSchedulerComponent implements OnInit, OnChanges, DoCheck {
  @Input() public data: IPlScheduler<any, any, any>;
  @Input() public rangeSelect: boolean;
  @Input() public rangeSelectMultiline: boolean;
  @Input() public datasetDetail: boolean;
  @Input() public datasetDetailOnSelect: boolean;
  @Input() public datasetDetailActive: boolean;
  @Input() public showTitle: boolean;
  @Input() public showLabel: boolean;
  @Input() public selectedRange: IPlSchedulerRange;
  @Input() public templateTitle: TemplateRef<void>;
  @Input() public templateLabel: TemplateRef<void>;
  @Input() public templateHeader: TemplateRef<IPlSchedulerHeaderTemplateContext<any>>;
  @Input() public templateDataset: TemplateRef<IPlSchedulerDatasetTemplateContext<any, any>>;
  @Input() public templateDatasetItem: TemplateRef<IPlSchedulerDatasetItemTemplateContext<any, any>>;
  @Input() public templateDatasetHeaderDetail: TemplateRef<IPlSchedulerDatasetHeaderDetailTemplateContext<any, any>>;
  @Input() public templateDatasetDetail: TemplateRef<IPlSchedulerDatasetDetailTemplateContext<any, any>>;
  @Output() public readonly datasetDetailActiveChange: EventEmitter<boolean>;
  @Output() public readonly selectedRangeChange: EventEmitter<IPlSchedulerRange>;
  @Output() public readonly evtCellClick: EventEmitter<IPlSchedulerEvtCellClick<any, any>>;
  @Output() public readonly evtRangeSelect: EventEmitter<IPlSchedulerEvtRangeSelect<any>>;
  @Output() public readonly evtSelectionChanged: EventEmitter<IPlSchedulerEvtSelectionChanged<any, any>>;

  public schedulerHeaderItemDetailHeight: number;
  public detailActive: boolean;
  public activeDatasets: Array<IPlSchedulerDataset<unknown, unknown>>;
  public activeDatasetsItems: Array<IPlSchedulerDatasetItem<unknown>>;

  @ViewChildren('schedulerDatasetDetail') private readonly _elementDatasetDetail: QueryList<ElementRef<HTMLElement>>;
  private readonly _selectedCells: Set<IPlSchedulerDatasetItem<unknown>>;
  private _range: IPlSchedulerRange;
  private _rangeBackward: boolean;
  private _rangeForward: boolean;

  constructor(private readonly _logger: Logger) {
    this.data = {
      header: [],
      datasets: [],
      title: '',
      label: ''
    };
    this.datasetDetailActiveChange = new EventEmitter<boolean>();
    this.selectedRangeChange = new EventEmitter<IPlSchedulerRange>();
    this.evtCellClick = new EventEmitter<IPlSchedulerEvtCellClick<any, any>>();
    this.evtRangeSelect = new EventEmitter<IPlSchedulerEvtRangeSelect<any>>();
    this.evtSelectionChanged = new EventEmitter<IPlSchedulerEvtSelectionChanged<any>>();
    this.selectedRange = {
      datasetStart: -1,
      datasetEnd: -1,
      datasetItemStart: -1,
      datasetItemEnd: -1
    };
    this.detailActive = false;
    this._selectedCells = new Set<IPlSchedulerDatasetItem<unknown>>();
    this._cleanupSelection();
  }

  public ngOnInit(): void {
    this._handleChanges();
  }

  public ngOnChanges({data, rangeSelect, rangeSelectMultiline, datasetDetail, datasetDetailOnSelect, datasetDetailActive, showTitle, showLabel, selectedRange}: SimpleChanges): void {
    if (data && !data.isFirstChange()) {
      this._changedData(data.currentValue);
    }
    if (rangeSelect && !rangeSelect.isFirstChange()) {
      this._changedRangeSelect(rangeSelect.currentValue);
    }
    if (rangeSelectMultiline && !rangeSelectMultiline.isFirstChange()) {
      this._changedRangeSelectMultiline(rangeSelectMultiline.currentValue);
    }
    if (datasetDetail && !datasetDetail.isFirstChange()) {
      this._changedDatasetDetail(datasetDetail.currentValue);
    }
    if (datasetDetailOnSelect && !datasetDetailOnSelect.isFirstChange()) {
      this._changedDatasetDetailOnSelect(datasetDetailOnSelect.currentValue);
    }
    if (datasetDetailActive && !datasetDetailActive.isFirstChange()) {
      this._changedDatasetDetailActive(datasetDetailActive.currentValue);
    }
    if (showTitle && !showTitle.isFirstChange()) {
      this._changedShowTitle(showTitle.currentValue);
    }
    if (showLabel && !showLabel.isFirstChange()) {
      this._changedShowLabel(showLabel.currentValue);
    }
    if (selectedRange && !selectedRange.isFirstChange()) {
      this._changedSelectedRange(selectedRange.currentValue);
    }
  }

  public ngDoCheck(): void {
    this.schedulerHeaderItemDetailHeight = this._elementDatasetDetail?.first?.nativeElement.getBoundingClientRect().height;
  }

  public cellClick(dataset: IPlSchedulerDataset<unknown, unknown>, datasetItem: IPlSchedulerDatasetItem<unknown>, datasetIndex: number, datasetItemIndex: number): void {
    let prevented = false;
    let preventedDetail = false;
    const event: IPlSchedulerEvtCellClick<unknown, unknown> = {
      dataset: dataset,
      datasetItem: datasetItem,
      preventDefault: () => {
        prevented = true;
      },
      preventDetail: () => {
        preventedDetail = true;
      }
    };
    this.evtCellClick.emit(event);
    if (!this.datasetDetail || !datasetItem.active || prevented) {
      this._cleanupSelection();
      this._setDetailActive(false);
      return;
    }
    let datasetDetailActive: boolean;
    if (!preventedDetail && (this.activeDatasets.length !== 1 || this.activeDatasetsItems.length !== 1 || dataset !== this.activeDatasets[0] || datasetItem !== this.activeDatasetsItems[0])) {
      this.activeDatasets = [dataset];
      this.activeDatasetsItems = [datasetItem];
      this.selectedRange.datasetStart = datasetIndex;
      this.selectedRange.datasetEnd = datasetIndex;
      this.selectedRange.datasetItemStart = datasetItemIndex;
      this.selectedRange.datasetItemEnd = datasetItemIndex;
      this._selectionChanged();
      datasetDetailActive = true;
    } else {
      this._cleanupSelection();
      datasetDetailActive = false;
    }
    this._setDetailActive(datasetDetailActive);
  }

  public onCellMousedown(dataset: IPlSchedulerDataset<unknown, unknown>, cell: IPlSchedulerDatasetItem<unknown>, datasetIndex: number, cellIndex: number): void {
    if (!this.rangeSelect || this._range || !this._validDate(cell.date) || !cell.selectable) {
      return;
    }
    this._range = {
      datasetStart: datasetIndex,
      datasetEnd: datasetIndex,
      datasetItemStart: cellIndex,
      datasetItemEnd: cellIndex
    };
    this._rangeBackward = false;
    this._rangeForward = false;
    this._cleanupSelectedDays();
    this._addSelectedCell(cell);
  }

  public onCellMouseEnter(cellDataset: IPlSchedulerDataset<unknown, unknown>, cell: IPlSchedulerDatasetItem<unknown>, cellDatasetIndex: number, cellIndex: number): void {
    if (!this._range || !this._validDate(cell.date) || (!this.rangeSelectMultiline && cellDatasetIndex !== this._range.datasetStart)) {
      return;
    }

    if (this.detailActive) {
      this._cleanupSelection();
      this._setDetailActive(false);
    }

    if (!this._rangeBackward && !this._rangeForward) {
      if (cellDatasetIndex < this._range.datasetStart || (cellDatasetIndex === this._range.datasetStart && cellIndex < this._range.datasetItemStart)) {
        this._rangeBackward = true;
      } else {
        this._rangeForward = true;
      }
    }

    if (this._rangeForward) {
      this._range.datasetEnd = cellDatasetIndex;
      this._range.datasetItemEnd = cellIndex;
    } else {
      this._range.datasetStart = cellDatasetIndex;
      this._range.datasetItemStart = cellIndex;
    }

    for (let datasetIndex = 0; datasetIndex < this.data.datasets.length; datasetIndex++) {
      const dataset: IPlSchedulerDataset<unknown, unknown> = this.data.datasets[datasetIndex];
      for (let datasetItemIndex = 0; datasetItemIndex < dataset.items.length; datasetItemIndex++) {
        const datasetItem: IPlSchedulerDatasetItem<unknown> = dataset.items[datasetItemIndex];
        if (!this._validDate(datasetItem.date)) {
          continue;
        }
        if (
          datasetIndex < this._range.datasetStart ||
          (datasetIndex === this._range.datasetStart && datasetItemIndex < this._range.datasetItemStart) ||
          datasetIndex > this._range.datasetEnd ||
          (datasetIndex === this._range.datasetEnd && datasetItemIndex > this._range.datasetItemEnd)
        ) {
          this._removeSelectedCell(datasetItem);
          continue;
        }
        this._addSelectedCell(datasetItem);
      }
    }

    if (this._rangeForward && cellDatasetIndex === this._range.datasetStart && cellIndex === this._range.datasetItemStart) {
      this._rangeForward = false;
    }
    if (this._rangeBackward && cellDatasetIndex === this._range.datasetEnd && cellIndex === this._range.datasetItemEnd) {
      this._rangeBackward = false;
    }
  }

  @HostListener('document:mouseup')
  public onCellMouseup(): void {
    if (!this._range) {
      return;
    }
    if (this._range.datasetEnd > this._range.datasetStart || (this._range.datasetEnd === this._range.datasetStart && this._range.datasetItemEnd > this._range.datasetItemStart)) {
      let doSelectionChanged = true;
      let selectionChanged = false;

      let datasets: Array<IPlSchedulerDataset<unknown, unknown>> = [];
      let datasetsItems: Array<IPlSchedulerDatasetItem<unknown>> = [];

      const datasetsEndIndex = this.rangeSelectMultiline ? this.data.datasets.length : this._range.datasetStart + 1;
      for (let datasetIndex = this._range.datasetStart; datasetIndex < datasetsEndIndex; datasetIndex++) {
        const dataset: IPlSchedulerDataset<unknown, unknown> = this.data.datasets[datasetIndex];
        for (let datasetItemIndex = 0; datasetItemIndex < dataset.items.length; datasetItemIndex++) {
          const datasetItem: IPlSchedulerDatasetItem<unknown> = dataset.items[datasetItemIndex];
          if (
            this._validDate(datasetItem.date) &&
            datasetIndex >= this._range.datasetStart &&
            datasetIndex <= this._range.datasetEnd &&
            (datasetIndex !== this._range.datasetStart || datasetItemIndex >= this._range.datasetItemStart) &&
            (datasetIndex !== this._range.datasetEnd || datasetItemIndex <= this._range.datasetItemEnd)
          ) {
            if (!datasets.includes(dataset)) {
              datasets.push(dataset);
            }
            datasetsItems.push(datasetItem);
            if (doSelectionChanged && !datasetItem.active && datasetItem.selectable) {
              doSelectionChanged = false;
            }
          }
        }
      }

      let prevented = false;
      let preventedDetail = false;

      const rangeSelectEvent: IPlSchedulerEvtRangeSelect<unknown> = {
        ...this._range,
        datasetItems: datasetsItems,
        setRange: undefined,
        preventDefault: () => {
          prevented = true;
        },
        preventDetail: () => {
          preventedDetail = true;
        }
      };
      this.evtRangeSelect.emit(rangeSelectEvent);

      if (!prevented && doSelectionChanged && this.datasetDetail && this.datasetDetailOnSelect) {
        selectionChanged = !preventedDetail;

        if (isObject(rangeSelectEvent.setRange)) {
          if (this._validateRange(rangeSelectEvent.setRange)) {
            this._range = {...this._range, ...rangeSelectEvent.setRange};
            datasets = [];
            datasetsItems = [];
            for (let i = this._range.datasetStart; i <= this._range.datasetEnd; i++) {
              const dataset: IPlSchedulerDataset<unknown, unknown> = this.data.datasets[i];
              datasets.push(dataset);

              const start: number = i === this._range.datasetStart ? this._range.datasetItemStart : 0;
              const end: number = i === this._range.datasetEnd ? this._range.datasetItemEnd : dataset.items.length - 1;
              for (let j = start; j <= end; j++) {
                const datasetItem: IPlSchedulerDatasetItem<unknown> = dataset.items[j];
                datasetsItems.push(datasetItem);
              }
            }
          } else {
            this._logger.error('Provided range is invalid.');
          }
        }

        this.activeDatasets = datasets;
        this.activeDatasetsItems = datasetsItems;
        this.selectedRange = {...this._range};
        this._selectionChanged();
      }
      if (!selectionChanged) {
        this._cleanupSelection();
      }
      this._setDetailActive(selectionChanged);
    }
    this._range = undefined;
    this._cleanupSelectedDays();
  }

  private _handleChanges(): void {
    this._changedData();
    this._changedRangeSelect();
    this._changedRangeSelectMultiline();
    this._changedDatasetDetail();
    this._changedDatasetDetailOnSelect();
    this._changedDatasetDetailActive();
    this._changedShowTitle();
    this._changedShowLabel();
    this._changedSelectedRange();
  }

  private _changedData(value: IPlScheduler<unknown, unknown, unknown> = this.data): void {
    this.data = assign<object, IPlScheduler<unknown, unknown, unknown>, IPlScheduler<unknown, unknown, unknown>>(
      {},
      {
        header: [],
        datasets: [],
        title: '',
        label: ''
      },
      value
    );
  }

  private _changedRangeSelect(value: boolean = this.rangeSelect): void {
    this.rangeSelect = isBoolean(value) ? value : true;
  }

  private _changedRangeSelectMultiline(value: boolean = this.rangeSelectMultiline): void {
    this.rangeSelectMultiline = isBoolean(value) ? value : true;
  }

  private _changedDatasetDetail(value: boolean = this.datasetDetail): void {
    this.datasetDetail = isBoolean(value) ? value : false;
  }

  private _changedDatasetDetailOnSelect(value: boolean = this.datasetDetailOnSelect): void {
    this.datasetDetailOnSelect = isBoolean(value) ? value : true;
  }

  private _changedDatasetDetailActive(value: boolean = this.datasetDetailActive): void {
    this.datasetDetailActive = isBoolean(value) ? value : false;
    if (!this.datasetDetailActive) {
      if (this.activeDatasets.length) {
        this.activeDatasets = [];
      }
      if (this.activeDatasetsItems.length) {
        this.activeDatasetsItems = [];
      }
    }
  }

  private _changedShowTitle(value: boolean = this.showTitle): void {
    this.showTitle = isBoolean(value) ? value : true;
  }

  private _changedShowLabel(value: boolean = this.showLabel): void {
    this.showLabel = isBoolean(value) ? value : true;
  }

  private _changedSelectedRange(value: IPlSchedulerRange = this.selectedRange): void {
    this.selectedRange = isObject(value) ? value : {...SCHEDULER_DEFAULT_RANGE};
    if (!isNumber(this.selectedRange.datasetStart)) {
      this.selectedRange.datasetStart = -1;
    }
    if (!isNumber(this.selectedRange.datasetEnd)) {
      this.selectedRange.datasetEnd = -1;
    }
    if (!isNumber(this.selectedRange.datasetItemStart)) {
      this.selectedRange.datasetItemStart = -1;
    }
    if (!isNumber(this.selectedRange.datasetItemEnd)) {
      this.selectedRange.datasetItemEnd = -1;
    }
  }

  private _validDate(date: MomentInput): boolean {
    return Boolean(date || date === 0);
  }

  private _addSelectedCell(cell: IPlSchedulerDatasetItem<unknown>): void {
    if (!this._selectedCells.has(cell)) {
      this._selectedCells.add(cell);
      this._addSelectedCellKlass(cell);
    }
  }

  private _removeSelectedCell(cell: IPlSchedulerDatasetItem<unknown>): void {
    if (this._selectedCells.has(cell)) {
      this._selectedCells.delete(cell);
      this._removeSelectedCellKlass(cell);
    }
  }

  private _addSelectedCellKlass(cell: IPlSchedulerDatasetItem<unknown>): void {
    cell.cssClass = ngClassAdd(cell.cssClass, KLASS_SELECTED);
  }

  private _removeSelectedCellKlass(cell: IPlSchedulerDatasetItem<unknown>): void {
    cell.cssClass = ngClassRemove(cell.cssClass, KLASS_SELECTED);
  }

  private _cleanupSelectedDays(): void {
    if (this._selectedCells.size) {
      for (const cell of this._selectedCells) {
        this._removeSelectedCellKlass(cell);
      }
      this._selectedCells.clear();
    }
  }

  private _setDetailActive(value: boolean): void {
    this.detailActive = value;
    if (this.datasetDetailActive !== this.detailActive) {
      this.datasetDetailActive = this.detailActive;
      this.datasetDetailActiveChange.emit(this.datasetDetailActive);
    }
  }

  private _selectionChanged(): void {
    this.selectedRangeChange.emit(this.selectedRange);
    this.evtSelectionChanged.emit({
      ...this.selectedRange,
      datasets: this.activeDatasets.slice(),
      dataset: this.activeDatasets[this.activeDatasets.length - 1],
      datasetsItems: this.activeDatasetsItems.slice(),
      datasetItem: this.activeDatasetsItems[this.activeDatasetsItems.length - 1]
    });
  }

  private _cleanupSelection(): void {
    this.activeDatasets = [];
    this.activeDatasetsItems = [];
    this.selectedRange.datasetStart = -1;
    this.selectedRange.datasetEnd = -1;
    this.selectedRange.datasetItemStart = -1;
    this.selectedRange.datasetItemEnd = -1;
    this._selectionChanged();
  }

  private _validateRange(range: IPlSchedulerRange): boolean {
    if (
      range.datasetStart < 0 ||
      range.datasetStart > range.datasetEnd ||
      (range.datasetStart >= 0 && !this.data.datasets.length) ||
      range.datasetEnd >= this.data.datasets.length ||
      range.datasetItemStart < 0 ||
      range.datasetItemStart > range.datasetItemEnd
    ) {
      const datasetStart: IPlSchedulerDataset<unknown, unknown> = this.data.datasets[range.datasetStart];
      const datasetEnd: IPlSchedulerDataset<unknown, unknown> = this.data.datasets[range.datasetEnd];
      if (
        (range.datasetItemStart >= 0 && !datasetStart.items.length) ||
        range.datasetItemStart >= datasetStart.items.length ||
        (range.datasetItemEnd >= 0 && !datasetEnd.items.length) ||
        range.datasetItemEnd >= datasetEnd.items.length
      ) {
        return false;
      }
    }
    return true;
  }
}
