import {Ng2StateDeclaration} from '@uirouter/angular';
import {StateService, TargetState, Transition} from '@uirouter/core';
import {EEntidadeBancariaProvider, IEntidadeBancariaAddNewAccountState} from '../../../interfaces/jsonEntidadeBancaria.interface';
import {IEntidadeBancariaResult, IEntidadeBancariaResultParams, IStateEntidadeBancariaParams, STATE_NAME_TINK_LINK, STATE_URL_ENTIDADE_BANCARIA} from './oauth2.entidadebancaria.state.interface';
import {STATE_NAME_PORTAL} from '../../../services/portals/portals.service.interface';
import {STATE_NAME_SITE} from '../../../../common/site';
import {fromJson} from 'pl-comps-angular';

export const STATE_ENTIDADE_BANCARIA: Ng2StateDeclaration = {
  parent: STATE_NAME_SITE,
  name: STATE_NAME_TINK_LINK,
  url: `/${STATE_URL_ENTIDADE_BANCARIA}?code&credentialsId&error&message&state&credentials`,
  redirectTo: stateEntidadeBancariaRedirectTo,
  data: {
    disableRecover: true
  },
  params: {
    code: {type: 'string', value: '', squash: true},
    credentialsId: {type: 'string', value: '', squash: true},
    error: {type: 'string', value: '', squash: true},
    message: {type: 'string', value: '', squash: true},
    state: {type: 'string', value: '', squash: true},
    credentials: {type: 'string', value: '', squash: true}
  }
};

export function stateEntidadeBancariaRedirectTo(transition: Transition): string | TargetState {
  const params: IStateEntidadeBancariaParams = <IStateEntidadeBancariaParams>transition.params();
  if (!params.state) {
    return STATE_NAME_PORTAL;
  }
  const injector = transition.injector();
  const stateService: StateService = injector.get<StateService>(StateService);
  const newAccountState: IEntidadeBancariaAddNewAccountState = fromJson(decodeURIComponent(<string>params.state));
  const entidadeBancariaResult: IEntidadeBancariaResult = {
    provider: newAccountState.provider === <EEntidadeBancariaProvider>-1 ? undefined : newAccountState.provider,
    code: params.code,
    credentialsId: params.credentialsId,
    error: params.error,
    message: params.message,
    credentials: params.credentials
  };
  const entidadeBancariaParam: IEntidadeBancariaResultParams = {entidadeBancaria: entidadeBancariaResult};
  return stateService.target(newAccountState.state, entidadeBancariaParam, {location: 'replace'});
}
