import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {isDefinedNotNull, PlAlertService} from 'pl-comps-angular';
import {IntegracaoSalariosService} from '../../integracaoSalarios.module.service';
import {EAPIJOBRHINTEGRACAOSALARIOSNOTFOUND, IIntegracaoSalariosProcessResult} from '../../integracaoSalarios.module.interface';
import {TranslateService} from '@ngx-translate/core';
import {EDebitoCredito} from '../../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnCellPrepared, IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {CGExceptionService} from '../../../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ICGExceptionError} from '../../../../../components/exceptions/exceptions.service.interface';
import {EStatusCode} from '../../../../../../config/constants';

@Component({
  selector: 'integracao-salarios-simulador-modal',
  templateUrl: './integracaoSalarios.simulador.modal.component.html'
})
export class IntegracaoSalariosSimuladorModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public nEmpresa: string;
  @Input() public nomeEmpresa: string;
  @Input() public source: Array<IIntegracaoSalariosProcessResult>;

  public readonly dataGridDefinition: IDevExpressDataGrid<IIntegracaoSalariosProcessResult>;

  public modalTitle: string;
  public onlyErrors: boolean;
  public totalDebito: number;
  public totalCredito: number;

  private _integSalariosSimulatorTableSource: Array<IIntegracaoSalariosProcessResult>;
  private _dataGridInstance: dxDataGrid<IIntegracaoSalariosProcessResult>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _integracaoSalariosService: IntegracaoSalariosService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'temErroUI', dataType: 'string', caption: 'global.text.error', alignment: 'center', cellTemplate: 'cellTemplateSimulator'},
        {dataField: 'integId', dataType: 'number', caption: 'integracaoSalarios.fields.integId'},
        {dataField: 'nConta', dataType: 'string', caption: 'integracaoSalarios.fields.nConta'},
        {dataField: 'periodo', dataType: 'string', caption: 'integracaoSalarios.fields.periodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'integracaoSalarios.fields.nDiario'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'integracaoSalarios.fields.nDocInterno'},
        {dataField: 'nDescritivo', dataType: 'number', caption: 'integracaoSalarios.fields.nDescritivo'},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'integracaoSalarios.fields.nDocExterno'},
        {dataField: 'descricao', dataType: 'string', caption: 'integracaoSalarios.fields.descricao'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'integracaoSalarios.fields.dataDoc'},
        {dataField: 'dataValor', dataType: 'date', caption: 'integracaoSalarios.fields.dataValor'},
        {dataField: 'dataVencimento', dataType: 'date', caption: 'integracaoSalarios.fields.dataVencimento'},
        {dataField: 'valor', dataType: 'double', caption: 'integracaoSalarios.fields.valor'},
        {dataField: 'codMoeda', dataType: 'string', caption: 'integracaoSalarios.fields.codMoeda'},
        {dataField: 'valorME', dataType: 'double', caption: 'integracaoSalarios.fields.valorME'},
        {dataField: 'cambio', dataType: 'number', caption: 'integracaoSalarios.fields.cambio'},
        {dataField: 'nContaDebito', dataType: 'string', caption: 'integracaoSalarios.fields.nContaDebito'},
        {dataField: 'nContaCredito', dataType: 'string', caption: 'integracaoSalarios.fields.nContaCredito'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'integracaoSalarios.fields.nomeConta'},
        {dataField: 'nCCusto', dataType: 'string', caption: 'integracaoSalarios.fields.nCCusto'},
        {dataField: 'nContrib', dataType: 'string', caption: 'integracaoSalarios.fields.nContrib'},
        {dataField: 'nRefProcesso', dataType: 'string', caption: 'integracaoSalarios.fields.nRefProcesso'},
        {dataField: 'contaOrigem', dataType: 'string', caption: 'integracaoSalarios.fields.contaOrigem'},
        {dataField: 'mascaraOrigem', dataType: 'string', caption: 'integracaoSalarios.fields.mascaraOrigem'}
      ],
      dataSource: new CustomStore({
        load: () => this._getIntegSalariosSimulatorTableSource()
      }),
      masterDetail: {enabled: true, template: 'masterDetailSimulator'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateSimulator',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this._integSalariosSimulatorTableSource = [];
  }

  public ngOnInit(): void {
    this._integSalariosSimulatorTableSource = this.source || [];
    if (this._integSalariosSimulatorTableSource.length) {
      this._processResults();
    }
    this.totalDebito = 0;
    this.totalCredito = 0;
    this.onlyErrors = false;
    this.modalTitle = this._translateService.instant(this.source.length ? 'integracaoSalarios.integModalTitle' : 'integracaoSalarios.simulatorModalTitle', {
      empresa: `${this.nEmpresa} - ${this.nomeEmpresa}`
    });
  }

  public changedOnlyErrors(value: boolean): void {
    this.onlyErrors = value;
    this._updateTable();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IIntegracaoSalariosProcessResult>): void {
    this._dataGridInstance = component;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IIntegracaoSalariosProcessResult>): void {
    if (event.rowType === 'data') {
      if (event.column.command === 'expand' && !event.data.temErro) {
        (<HTMLElement>event.cellElement.childNodes[0]).classList.remove('dx-datagrid-group-closed');
        event.cellElement.classList.remove('dx-datagrid-expand');
      }
      if (event.data.temErro) {
        event.cellElement.classList.add('text-danger');
      }
    }
  }

  private _getIntegSalariosSimulatorTableSource(): Promise<Array<IIntegracaoSalariosProcessResult>> {
    if (this._integSalariosSimulatorTableSource?.length) {
      return Promise.resolve(this.onlyErrors ? this._integSalariosSimulatorTableSource.filter((item: IIntegracaoSalariosProcessResult) => item.temErro) : this._integSalariosSimulatorTableSource);
    }
    return this._integracaoSalariosService
      .getProcessResults(this.nEmpresa)
      .then((response: Array<IIntegracaoSalariosProcessResult>) => {
        this._integSalariosSimulatorTableSource = response;
        this._processResults();
        return this.onlyErrors ? this._integSalariosSimulatorTableSource.filter((item: IIntegracaoSalariosProcessResult) => item.temErro) : this._integSalariosSimulatorTableSource;
      })
      .catch((reason) => {
        if (isDefinedNotNull(reason)) {
          const exception: ICGExceptionError = this._cgExceptionService.get(reason);
          if (exception.status === EStatusCode.InternalServerError && exception.class === EAPIJOBRHINTEGRACAOSALARIOSNOTFOUND) {
            this._cgModalService.showOkCancelVanilla('integracaoSalarios.jobTimeoutModalTitle', 'integracaoSalarios.jobTimeoutModalMessage', {
              size: 'md',
              showCancelBtn: false,
              backdrop: 'static',
              keyboard: false
            });
          } else {
            this._plAlertService.error(exception.message);
          }
        }
        return [];
      });
  }

  private _processResults(): void {
    this._integSalariosSimulatorTableSource.forEach((item: IIntegracaoSalariosProcessResult, index: number) => {
      item.integId = index + 1;
      item.temErroUI = item.temErro ? 'fa fa-exclamation-triangle text-danger' : 'fa fa-check text-success';
      const nContaCode = item.nConta.charCodeAt(0);
      const minChr = '1'.charCodeAt(0);
      const maxChr = '8'.charCodeAt(0);
      if (nContaCode >= minChr && nContaCode <= maxChr) {
        if (item.dC === EDebitoCredito.Credito) {
          this.totalCredito += item.valor;
        } else {
          this.totalDebito += item.valor;
        }
      }
    });
  }

  private _updateTable(): void {
    if (isDefinedNotNull(this._dataGridInstance)) {
      this._dataGridInstance.refresh();
    }
  }
}
