import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isString, PlAlertService} from 'pl-comps-angular';
import {AnaliticaConfigService} from '../analiticaConfig.module.service';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonAnaliticaConfig} from '../jsonAnaliticaConfig.module.interface';
import {IRadicais, IRadicaisExcluidos} from '../analiticaConfig.module.interface';
import {ModuloComponent} from '../../../components/module/module.component';

@Component({
  selector: 'module-analitica-config',
  templateUrl: './analiticaConfig.module.component.html'
})
export class AnaliticaConfigModuleComponent extends ModuloComponent implements OnInit {
  @Input() public model: IJsonAnaliticaConfig;

  public readonly dataGridDefinitionRadicais: IDevExpressDataGrid;
  public readonly dataGridDefinitionRadicaisExcluido: IDevExpressDataGrid;
  public dataSourceRadicalList: Array<IRadicais>;
  public dataSourceRadicalExcluidoList: Array<IRadicaisExcluidos>;
  public radical: IRadicais;
  public radicalExcluido: IRadicaisExcluidos;
  public radicalInt: number;
  public radicalExcluidoInt: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _analiticaConfigService: AnaliticaConfigService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.dataGridDefinitionRadicais = {
      columns: [
        {dataField: 'radical', dataType: 'string', caption: 'analiticaconfig.radicaisList'},
        {
          width: 50,
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: true,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false
        }
      ],
      columnChooser: {enabled: false},
      export: {enabled: false},
      filterRow: {visible: false},
      remoteOperations: false,
      searchPanel: {visible: false},
      headerFilter: {visible: false},
      sorting: {mode: 'none'},
      hoverStateEnabled: true
    };
    this.dataGridDefinitionRadicaisExcluido = {
      columns: [
        {dataField: 'radicalExcluido', dataType: 'string', caption: 'analiticaconfig.radicaisExcluidoList'},
        {
          width: 50,
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: true,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false
        }
      ],
      columnChooser: {enabled: false},
      export: {enabled: false},
      filterRow: {visible: false},
      remoteOperations: false,
      searchPanel: {visible: false},
      headerFilter: {visible: false},
      sorting: {mode: 'none'},
      hoverStateEnabled: true
    };
    this.dataSourceRadicalList = [];
    this.dataSourceRadicalExcluidoList = [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnSave.visible = true;
    this.btnSave.click = () => this._guardar();
    this.toolbar.addButton({
      id: 'cancel',
      order: this.btnSave.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-ban"></i>',
      class: 'btn-light',
      caption: 'global.btn.cancel',
      click: () => this._cancelar()
    });
    this._modelToRadicais();
    this._modelToRadicaisExcluidos();
  }

  public addLine(): void {
    const radicalStr = this.radicalInt.toString();
    if (this.dataSourceRadicalList.find((item: IRadicais) => item.radical === radicalStr)) {
      this._plAlertService.error('analiticaconfig.messages.radicalJaExistente');
      return;
    }
    this.radicalInt = undefined;
    this.dataSourceRadicalList.push({radical: radicalStr});
  }

  public addLineExcluido(): void {
    const radicalExcluidoStr = this.radicalExcluidoInt.toString();
    if (this.dataSourceRadicalExcluidoList.find((item: IRadicaisExcluidos) => item.radicalExcluido === radicalExcluidoStr)) {
      this._plAlertService.error('analiticaconfig.messages.radicalExcluidoJaExistente');
      return;
    }
    this.radicalExcluidoInt = undefined;
    this.dataSourceRadicalExcluidoList.push({radicalExcluido: radicalExcluidoStr});
  }

  public readonly fnDeleteLine = (indexLine: number) => (): Promise<void> => this._deleteLine(indexLine);

  public readonly fnDeleteLineExcluido = (indexLine: number) => (): Promise<void> => this._deleteLineExcluido(indexLine);

  private _modelToRadicais(): void {
    this.dataSourceRadicalList =
      isString(this.model.radicais) && this.model.radicais
        ? this.model.radicais.split(',').map<IRadicais>((value: string) => {
            return {radical: value};
          })
        : [];
  }

  private _modelToRadicaisExcluidos(): void {
    this.dataSourceRadicalExcluidoList =
      isString(this.model.radicaisExcluidos) && this.model.radicaisExcluidos
        ? this.model.radicaisExcluidos.split(',').map<IRadicaisExcluidos>((value: string) => {
            return {radicalExcluido: value};
          })
        : [];
  }

  private async _deleteLine(indexLine: number): Promise<void> {
    if (!this.dataSourceRadicalList.length) {
      return;
    }
    await this._cgModalService.showOkCancel('analiticaconfig.messages.apagarItem', 'analiticaconfig.messages.desejaApagarItem', {size: 'md'});
    this.dataSourceRadicalList.splice(indexLine, 1);
  }

  private async _deleteLineExcluido(indexLine: number): Promise<void> {
    if (!this.dataSourceRadicalExcluidoList.length) {
      return;
    }
    await this._cgModalService.showOkCancel('analiticaconfig.messages.apagarItem', 'analiticaconfig.messages.desejaApagarItem', {size: 'md'});
    this.dataSourceRadicalExcluidoList.splice(indexLine, 1);
  }

  private _guardar(): Promise<void> {
    this.model.radicais = this.dataSourceRadicalList.map((value: IRadicais) => value.radical).join(',');
    this.model.radicaisExcluidos = this.dataSourceRadicalExcluidoList.map((value: IRadicaisExcluidos) => value.radicalExcluido).join(',');
    return this._analiticaConfigService.postAnaliticaConfig(this.model).then((response: HttpResponse<IJsonAnaliticaConfig>) => {
      this._plAlertService.success('analiticaconfig.messages.successfullySaved');
      this._applyModel(response.body);
    });
  }

  private _cancelar(): Promise<void> {
    return this._analiticaConfigService.getAnaliticaConfig().then((response: IJsonAnaliticaConfig) => {
      this._applyModel(response);
    });
  }

  private _applyModel(value: IJsonAnaliticaConfig): void {
    this.model = value;
    this._modelToRadicais();
    this._modelToRadicaisExcluidos();
  }
}
