<div class="acrescimos-encargos-ferias-integ-contab-multi-errors-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'acrescimosencargosferias.integContabMultiErrors.title'"></h5>
  </div>

  <div class="modal-body">
    <dx-data-grid [cgDxDataGrid]="dataGridDefinitionEmpresas" cgDxDataGridInstanceName="empresasTableErrors" [dataSource]="dataGridDefinitionEmpresas.dataSource">
      <div *dxTemplate="let item of 'cellTemplateIntegrado'">
        <pl-tooltip *ngIf="!item.data.multiLinhaErro" [config]="{text: 'acrescimosencargosferias.integContabMultiErrors.integ', tooltipClass: 'tooltip-success', placement: 'right'}">
          <i *plTooltipContent class="fa fa-fw fa-check text-success"></i>
        </pl-tooltip>
        <pl-tooltip *ngIf="item.data.multiLinhaErro" [config]="{text: 'acrescimosencargosferias.integContabMultiErrors.naoInteg', tooltipClass: 'tooltip-danger', placement: 'right'}">
          <i *plTooltipContent class="fa fa-fw fa-times text-danger"></i>
        </pl-tooltip>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
