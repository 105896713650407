<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [innerHTML]="title | translate"></h5>
</div>

<div class="modal-body" [innerHTML]="message | translate"></div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-danger action-close" (evtClicked)="close()" plAutoFocus><i class="fa fa-fw fa-trash"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

  <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
