import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {isDefinedNotNull, PlAlertService} from 'pl-comps-angular';
import {TributAutonomasModuleService} from '../../../tributAutonomas.module.service';
import {IJsonErpUser, TUserSession} from '../../../../../services/account/jsonUserApi.interface';
import {AuthService} from '../../../../../services/auth/auth.service';
import {IJsonTributAutonomaHeader} from '../../../jsonTributAutonomas.module.interface';

@Component({
  selector: 'manut-tribut-autonomas-empresas-modal',
  templateUrl: './manutTributAutonomas.empresas.modal.component.html'
})
export class ManutTributAutonomasEmpresasModalComponent extends CGModalComponent<void> {
  @Input() public nEmpresa: string;
  @Input() public header: IJsonTributAutonomaHeader;

  public readonly empresasTemplate: string;

  public empresasSource: Array<IJsonErpUser>;
  public empresas: Array<IJsonErpUser>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _tributAutonomasService: TributAutonomasModuleService,
    private readonly _authService: AuthService
  ) {
    super(_injector);
    this.empresasTemplate = '{{nEmpresa}} - {{nomeEmpresa}}';
    this.empresas = [];
    this.empresasSource = [];
    this._loadEmpresas();
  }

  public close(): Promise<void> {
    const empresas: string = this.empresas
      .map<string>((empresa: IJsonErpUser) => {
        return empresa.nEmpresa;
      })
      .join(',');
    return this._tributAutonomasService.copiaConfiguracaoParaOutrasEmpresas(empresas, this.header).then(() => {
      this._plAlertService.success('tributAutonomas.messages.configuracoesDaTributacaoAutonom');
      super.close();
    });
  }

  private async _loadEmpresas(): Promise<void> {
    const session: TUserSession = await this._authService.identity();
    const empresassel: Array<IJsonErpUser> = [...session.erps];
    let idx = -1;
    const foundItem = empresassel.find((item: IJsonErpUser, index: number) => {
      if (item.nEmpresa === session.erp.nEmpresa) {
        idx = index;
        return true;
      }
      return false;
    });
    if (idx > -1 && isDefinedNotNull(foundItem)) {
      empresassel.splice(idx, 1);
      this.empresas = [];
    }
    this.empresasSource = empresassel;
  }
}
