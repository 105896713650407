import {Injectable} from '@angular/core';
import {ERadix, Logger} from 'pl-comps-angular';
import moment from 'moment';

export function bustUrl(url: string): string {
  const uri: URL = new URL(url);
  uri.searchParams.set('cachebuster', moment().valueOf().toString(ERadix.Decimal));
  return uri.toJSON();
}

@Injectable({
  providedIn: 'root'
})
export class CacheBusterService {
  private _matchList: Array<RegExp>;
  private _black: boolean;
  private _logRequests: boolean;

  constructor(private readonly _logger: Logger) {
    this._black = false;
    this._logRequests = false;
  }

  public setMatchlist(list: Array<RegExp>, black: boolean = false): void {
    this._black = black;
    this._matchList = list;
  }

  public checkBust(url: string): boolean {
    let busted = !this._black;
    for (const regExp of this._matchList) {
      if (regExp.exec(url)) {
        busted = this._black;
        break;
      }
    }
    return busted;
  }

  public bustUrl(url: string): string {
    return bustUrl(url);
  }

  public log(url: string, busted: boolean): void {
    if (this._logRequests) {
      const message = `request.url=${url}`;
      if (busted) {
        this._logger.warn(message);
      } else {
        this._logger.log(message);
      }
    }
  }

  public get logRequests(): boolean {
    return this._logRequests;
  }

  public set logRequests(value: boolean) {
    this._logRequests = value;
  }
}
