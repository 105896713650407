<entity-list
  [entityName]="entityName"
  [maintenanceMode]="maintenanceMode"
  [toolbarInstanceId]="instanceName"
  [dataGridState]="dataGridState"
  [searchValue]="searchValue"
  [filter]="filterValue"
  [initialFilter]="initialFilterValue"
  [serviceMethodsOverride]="serviceMethodsOverride"
  [properties]="listOptions"
  (evtOnRefresh)="onRefreshList()"
  (evtDataGridPreparing)="onDataGridPreparing($event)"
  (evtDataGridCellClick)="onDataGridCellClick($event)"
  (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
  (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
  (evtDataGridStateChanged)="onDataGridStateChanged($event)"
  (evtDataGridStateCleared)="onDataGridStateCleared()">
  <div *entityListItemDetail="let item">
    <div class="card" *ngIf="!item.data.empty">
      <div class="card-header">
        <h6 class="card-title">
          <strong class="fatura-title" [translate]="'fimperiodoenc.titleListaEncerrados'"></strong>
          <span class="doc-toolbar">
            <button type="button" class="btn btn-warning btn-sm" [click]="fnEdit(item.data)" plPromise>
              <i class="fa fa-pencil-square-o fa-fw"></i>&nbsp;<span [translate]="'entity.action.edit'"></span>
            </button>
          </span>
        </h6>
      </div>
      <div class="card-body card-documento">
        <dx-data-grid [cgDxDataGrid]="definition" [dataSource]="item.data.diarios"></dx-data-grid>
      </div>
    </div>
  </div>
</entity-list>
