import {TDate} from '../../../common/dates';

export enum EIesState {
  Inactive = 'iessInactive',
  Scheduled = 'iessScheduled',
  Started = 'iessStarted',
  Ended = 'iessEnded',
  Timeout = 'iessTimeout',
  Error = 'iessError'
}

export interface IIesError {
  anexo: string;
  campo: string;
  descricao: string;
  mensagem: string;
}

export interface IIesStatus {
  userStarted: string;
  requestDate: TDate;
  plannedStartDate: TDate;
  description: string;
  state: EIesState;
  userStartedId: number;
  filename: string;
  ficheiroProcessado: boolean;
  errorList: Array<IIesError>;
  ficheiroProcessadoDate: TDate;
  ficheiroProcessadoSize: number;
  ano: number;
}

export interface IIesFilters {
  ano: number;
  anexos: IIesAnexos;
}

export enum EIesStep {
  Configuration,
  Processing,
  Errors
}

export enum EIesAnexosTypes {
  Invalido,
  Rosto,
  AnexoA,
  AnexoASNC,
  AnexoD,
  AnexoE,
  AnexoF,
  AnexoG,
  AnexoH,
  AnexoI,
  AnexoL,
  AnexoM,
  AnexoN,
  AnexoO,
  AnexoP,
  AnexoQ,
  AnexoR,
  AnexoRSNC
}

export interface IIesAnexos {
  rosto: IIesAnexoData;
  anexoASNC: IIesAnexoData;
  anexoD: IIesAnexoData;
  anexoE: IIesAnexoData;
  anexoF: IIesAnexoData;
  anexoG: IIesAnexoData;
  anexoH: IIesAnexoData;
  anexoI: IIesAnexoData;
  anexoL: IIesAnexoData;
  anexoM: IIesAnexoData;
  anexoN: IIesAnexoData;
  anexoO: IIesAnexoData;
  anexoP: IIesAnexoData;
  anexoQ: IIesAnexoData;
  anexoRSNC: IIesAnexoData;
}

export interface IIesAnexoData {
  name: string;
  description: string;
  checked: boolean;
  type: EIesAnexosTypes;
}
