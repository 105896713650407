import {IJsonConfigRHDadosFicha} from '../../rhconfiguracoes/jsonRHConfiguracoes.module.interface';
import {IJsonDGEMP} from '../../../entities/dgemps/jsonDGEMP.entity.interface';
import {TDate} from '../../../../common/dates';
import {ETipoContratoQuadroPessoal} from '../../../entities/tiposcontrato/jsonTiposContrato.entity.interface';
import {ERHDependentes, ERHLocalTributacaoRendimento, ERHNaturezaRendimento, ERHSituacaoCivilTitular, ERHTipoDeficiencia} from './dgempsFull.interface';

export enum ESexo {
  Feminino,
  Masculino,
  NaoDefinido
}

export enum EResidenciaFiscal {
  ResidePT,
  NaoResidePTAuferemEntidadeUnica,
  NaoResidePTAuferemMaisQueUmaEntidade
}

export enum EDHEmpMetodoCalcSalario {
  None,
  Vencimento,
  HorasTrabalho
}

export enum EDHEmpRegimeTrabalho {
  Privado,
  FuncaoPublica
}

export enum EDHEmpTipoCalcDuodecimos {
  SubNatalSubFerias,
  SubNatal,
  SubFerias,
  NaoEfectuaProcessamento
}

export enum EDHEmpValoresDuodecimos {
  Zero = 0,
  Cinquenta = 50,
  Cem = 100
}

export interface IJsonDGEMPFull extends IJsonDGEMP {
  situacao: number;
  sexo: ESexo;
  ultRegisto: number;
  sitEmpreg: string;
  dtNascimento: TDate;
  freguesia: string;
  nomeFreguesia: string;
  concelho: number;
  nomeConcelho: string;
  distrito: number;
  nomeDistrito: string;
  nacionalidad: string;
  designacaoBreveNacionalidad: string;
  nbIdentidade: string;
  biLocalEmissao: string;
  dtBiValidade: TDate;
  residenciaFiscal: EResidenciaFiscal;
  numSegSoc: string;
  dtAdmiSegSoc: TDate;
  nCartaoTicketRef: string;
  dhemp: IJsonDHEMP;
  comentarios: Array<IJsonDGEMPComentario>;
  repFinancas: string;
  codRepFinancas: number;
  sindicato: number;
  nomeSindicato: string;
  numSindicato: string;
}

export interface IJsonDHEMP {
  dhempId: string;
  codEmp: number;
  registoNum: number;
  dtMotMov: TDate;
  banco: string;
  nomeBanco: string;
  iban: string;
  swift: string;
  codNHABQPessoal: string;
  nomeNHABQPessoal: string;
  dtAdmEmpresa: string;
  vencimBase: number;
  nDiuturnidad: number;
  valorUnDiutu: number;
  horasDia: number;
  horasSemana: number;
  horasMes: number;
  remunHora: number;
  temSubAlim: boolean;
  valorSubAlim: number;
  temPreProcessamentoSubAlimen: boolean;
  valorSFerias: number;
  valorSNatal: number;
  metodPaga: EDHEmpMetodoCalcSalario;
  divisaoTrab: string;
  designaBreveDivisaoTrab: string;
  tipocontrato: string;
  designaBreveTipoContrato: string;
  tipocontratoCtQPessoal: ETipoContratoQuadroPessoal;
  dtIniContrat: TDate;
  dtFimContrat: TDate;
  dataEfetividade: TDate;
  temSegurAcTr: boolean;
  nSeguradAcTr: string;
  nomeNSeguradAcTr: string;
  caixaPrev: number;
  nomeCaixaPrev: string;
  classeCaixa: string;
  designaBreveClasseCaixa: string;
  nTabIrs: string;
  designaComplNTabIrs: string;
  numDepend: number;
  numDependDef: number;
  regimeTrabalho: EDHEmpRegimeTrabalho;
  tipoCalcDuodecimos: EDHEmpTipoCalcDuodecimos;
  calcDuodecVariavel: boolean;
  eExResidente: boolean;
  nPocRemuLiq: string;
  nomeNPocRemuLiq: string;
  classeTrab: string;
  designaBreveClasseTrab: string;
  funcao: string;
  designaBreveFuncao: string;
  seccao: string;
  designaBreveSeccao: string;
  nZona: number;
  nomeZona: string;
  departamento: number;
  nomeDepartamento: string;
  nSubDe: string;
  nomeSubDe: string;
  ccusto: string;
  nomeCCusto: string;
  nCodRepCC: number;
  descricaoCodRepCC: string;
  categoria: string;
  designaBreveCategoria: string;
  profissao: string;
  designaBreveProfissao: string;
  sitProfissao: string;
  designaBreveSitProfissao: string;
  codcct: number;
  descricaoCodcct: string;
  nivQualific: string;
  designaBreveNivQualific: string;
  outrosDados: IJsonConfigRHDadosFicha;
  datasDadosHistoricos: Array<TDate>;
  nFormRemun: string;
  descFormRemun: string;
  temDescIRSTxFixa: boolean;
  percDescTaxaFixaIRS: number;
  naoEfetuaFCT: number;
  crlCalcLiquido: boolean;
  crlValorLiquido: number;
  crlValorLiquidoEmMe: boolean;
  crlAbonoLiquido: string;
  crlAbonoLiquidoDesignBreve: string;
  nDiasFeriasAnoAnteri: number;
  usaDuodecimosPorEmp: boolean;
  duodecimosSubNatal: number;
  duodecimosSubFerias: number;
  temRedIrsCredHabitac: boolean;
  conjugeIncapacidad60: boolean;
  deficienteForcasArma: boolean;
  temRedCtrArrendameHP: boolean;
  nAcrescDependIncap60: number;
  percDefic: number;
  localObterRendimento: number;
  localTributacaoRendi: number;
  naturezaRendimento: number;
  situacaoCivilTitular: number;
}

export interface IJsonDHEMPCalcularCustoHoraEmpregado {
  horasSemana: number;
  nCodABVencimento: string;
  nCodABDiuturnidades: string;
  vencimBase: number;
  nDiuturnidad: number;
  valorUnDiutu: number;
  dtMotMov: TDate;
}

export interface IJsonDHEMPCustoHoraEmpregado {
  meses: number;
  semanas: number;
  horasSemana: number; // Em horas
  abonos: Array<IJsonDHEMPCustoHoraEmpregadoAbono>;
  total: number;
}

export interface IJsonDHEMPCustoHoraEmpregadoAbono {
  valor: number;
  designacao: string;
  cruzamentos: Array<IJsonDHEMPCustoHoraEmpregadoCruzamentoAbono>;
  valorCalculado: number;
  valorCruzamentos: number;
  nDiuturnidades?: number;
}

export interface IJsonDHEMPCustoHoraEmpregadoCruzamentoAbono {
  valor: number;
  percentagem: number;
  designacao: string;
  valorCalculado: number;
  nDiuturnidades?: number;
}

export interface IJsonDGEMPComentario {
  comentarioID: string;
  comentarioParentID: string;
  docID: string;
  titulo: string;
  texto: string;
  utilizador: string;
  data: TDate;
}

export interface IJsonDGEMPSelecaoTabelaIRS {
  dataReferencia: TDate;
  residencia: ERHLocalTributacaoRendimento;
  situacao: ERHSituacaoCivilTitular;
  tipoRendimento: ERHNaturezaRendimento;
  declaranteDeficiente: ERHTipoDeficiencia;
  dependentes: ERHDependentes;
  dependentesDeficiente: ERHDependentes;
  nTabIRS?: string;
  designacaoIRS?: string;
  parcelaAbaterDepende?: number;
}

export interface IJsonDGEMPDadosDefeitoTabelaIRS {
  residencia: ERHLocalTributacaoRendimento;
  situacao: ERHSituacaoCivilTitular;
  tipoRendimento: ERHNaturezaRendimento;
  nTabIRS: string;
  designacaoIRS: string;
}

export interface IJsonDGEMPInconsistenciasTabelaIRSEmpregado {
  codEmp: number;
  nome: string;
}

export interface IJsonDGEMPInconsistenciasTabelaIRS {
  empregadosinconsistentes: Array<IJsonDGEMPInconsistenciasTabelaIRSEmpregado>;
  mostrarnotificacao: boolean;
}
