import {EArquivoDigitalGestaoDocsDigitalizadosTypeAction, IDocDigitalizado} from '../../../../modules/contabilidadedigital/documentosdigitais/contabDigital.documentosDigitais.module.interface';
import {TTableLegend} from '../../../tablelegend/tablelegend.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {EDataGridLegendCellColors} from '../../../devexpress/datagrid/configuration/devexpress.datagrid.configuration';

export enum EGestaoDocumentosDigitalizadosMode {
  Contabilidade,
  Comercial,
  Conciliacao
}

export interface IArquivoDigitalGestaoDocumentosDigitalizadosEvtCalledAction {
  action: EArquivoDigitalGestaoDocsDigitalizadosTypeAction;
  selectedLine: IDocDigitalizado;
  selectedLines: Array<IDocDigitalizado>;
}

export function tableLegendDocumentosJaLanacados(modoConciliacao: boolean): TTableLegend {
  return deepFreeze([
    {
      caption: 'arquivodigital.gestaodocsdigitalizados.legend.comdocparaassociar',
      badgeCSSClass: EDataGridLegendCellColors.GreenLegendColor,
      hide: modoConciliacao
    },
    {
      caption: 'arquivodigital.gestaodocsdigitalizados.legend.docassociado',
      badgeCSSClass: EDataGridLegendCellColors.BlueLegendColor,
      hide: modoConciliacao
    },
    {
      caption: 'arquivodigital.gestaodocsdigitalizados.legend.error',
      badgeCSSClass: EDataGridLegendCellColors.RedLegendColor
    },
    {
      caption: 'arquivodigital.gestaodocsdigitalizados.legend.warning',
      badgeCSSClass: EDataGridLegendCellColors.YellowLegendColor
    },
    {
      caption: 'arquivodigital.gestaodocsdigitalizados.legend.waitingocr',
      badgeCSSClass: EDataGridLegendCellColors.OrangeLegendColor
    }
  ]);
}
