import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonConfigTransfArmazem} from '../../jsonTransferenciasArmazens.entity.interface';
import {TransferenciasArmazensService} from '../../transferenciasArmazens.entity.service';
import {IJsonDocfa} from '../../../docfas/jsonDocFa.entity.interface';

@Component({
  selector: 'modal-transferencias-armazens-config',
  templateUrl: './transferenciasArmazens.config.modal.component.html'
})
export class TransferenciasArmazensConfigModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public configTransfArmazem: IJsonConfigTransfArmazem;

  public readonly docfasOutput: string;
  public readonly clifosOutput: string;
  public readonly armazensOutput: string;

  public docFaDestino: Partial<IJsonDocfa>;
  public docfaOrigemNumFilter: string;
  public docfaDestinoNumFilter: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _transferenciasArmazensService: TransferenciasArmazensService
  ) {
    super(_injector);
    this.docfasOutput = '{{nDocFa}} - {{nome}}';
    this.clifosOutput = '{{nConta}} - {{nome}}';
    this.armazensOutput = '{{nArmazem}} - {{nome}}';
  }

  public ngOnInit(): void {
    this.docfaOrigemNumFilter = `nDocFa=${this.configTransfArmazem.nDocfaOrigem}&encerrado=0&visivelERPCloud=1`;
    this.docfaDestinoNumFilter = `nDocFa=${this.configTransfArmazem.nDocfaDestino}&encerrado=0&visivelERPCloud=1`;
    this.configTransfArmazem.nDocfaOrigem = this.configTransfArmazem.nDocfaOrigem === 0 ? undefined : this.configTransfArmazem.nDocfaOrigem;
    this.configTransfArmazem.nDocfaDestino = this.configTransfArmazem.nDocfaDestino === 0 ? undefined : this.configTransfArmazem.nDocfaDestino;
    this.configTransfArmazem.nNumerOrigem = this.configTransfArmazem.nNumerOrigem === 0 ? undefined : this.configTransfArmazem.nNumerOrigem;
    this.configTransfArmazem.nNumerDestino = this.configTransfArmazem.nNumerDestino === 0 ? undefined : this.configTransfArmazem.nNumerDestino;
    this.configTransfArmazem.nArmazemOrigem = this.configTransfArmazem.nArmazemOrigem === 0 ? undefined : this.configTransfArmazem.nArmazemOrigem;
    this.configTransfArmazem.nArmazemDestino = this.configTransfArmazem.nArmazemDestino === 0 ? undefined : this.configTransfArmazem.nArmazemDestino;
    this.docFaDestino = {nDocFa: this.configTransfArmazem.nDocfaDestino, nome: this.configTransfArmazem.nomeDocfaDestino};
  }

  public close(): Promise<void> {
    return this._transferenciasArmazensService.postConfiguracoesTransfArmazens(this.configTransfArmazem).then(() => {
      return super.close();
    });
  }

  public nDocfaDestinoChanged(docfa: IJsonDocfa): void {
    this.docFaDestino = docfa;
    this.configTransfArmazem.nDocfaDestino = docfa ? docfa.nDocFa : undefined;
    this.configTransfArmazem.nomeDocfaDestino = docfa ? docfa.nome : '';
  }
}
