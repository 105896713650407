import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {isDefined, PlTranslateService} from 'pl-comps-angular';
import {ConciliacaoEFaturasService} from '../../service/conciliacaoEFaturas.service';
import {IDocContabilidade} from '../../../docscontabilidade/docsContabilidade.interface';
import {IConciliacaoEFaturasConnectDocument} from '../../conciliacaoEFaturas.module.interface';
import {IContabDigitalGDocViewerRecolhaDoc} from '../../../../../services/contabilidadedigital/contabilidadedigital.interface';

@Component({
  selector: 'module-conciliacao-e-faturas-view-doc-modal',
  templateUrl: './conciliacaoEFaturas.module.viewDoc.modal.html'
})
export class ConciliacaoEFaturasModuleViewDocModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public dirtyDoc: IConciliacaoEFaturasConnectDocument;

  public contabDigitalDoc: IContabDigitalGDocViewerRecolhaDoc;
  public doc: IDocContabilidade;
  public promise: Promise<void>;
  public empresaTemContabilidadeDigital: boolean;
  public modalTitle: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _conciliacaoEFaturasService: ConciliacaoEFaturasService
  ) {
    super(_injector);
    this.empresaTemContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
  }

  public ngOnInit(): void {
    this.modalTitle = this._plTranslateService.translate('conciliacaoEFaturas.viewDocModalTitleSimple');
    if (isDefined(this.dirtyDoc)) {
      if (this.dirtyDoc.periodo && this.dirtyDoc.nDiario && this.dirtyDoc.nDocInterno) {
        this.modalTitle = this._plTranslateService.translate('conciliacaoEFaturas.viewDocModalTitle', {ndoc: `${this.dirtyDoc.periodo}.${this.dirtyDoc.nDiario}.${this.dirtyDoc.nDocInterno}`});
      }
      this.promise = this._conciliacaoEFaturasService.getDocDetailsContabilidade(this.dirtyDoc.extPocCabID, this.dirtyDoc.ano).then((doc: IDocContabilidade) => {
        this.doc = doc;
        if (this.empresaTemContabilidadeDigital) {
          this.contabDigitalDoc = {
            extPocCabID: doc.extPocCabID,
            periodo: doc.periodo,
            nDiario: doc.nDiario,
            nDocInterno: doc.nDocInterno,
            dataDoc: doc.dataDoc
          };
        }
      });
    }
  }
}
