import {Component, Injector} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlNavWizardDefinition, IPlNavWizardEventStep, isObject} from 'pl-comps-angular';
import {AtivosImportService} from '../../ativosImport.service';
import {EAtivosAquisicaoBaseCalculo, EAtivosAquisicaoMetodoCalculo} from '../../../ativosaquisicao/jsonAtivosAquisicao.entity.interface';
import {EAtivosEstado, IRadioItemAtivos} from '../../../ativos/interfaces/jsonAtivos.entity.interface';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {focusElement} from '../../../../../common/utils/element.utils';
import {IJsonAtivosImport, IJsonAtivosImportTaxa} from '../../jsonAtivosImport.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import moment from 'moment';
import {AtivosConfiguracoesService} from '../../../../modules/ativos/ativosconfiguracoes/ativosConfiguracoes.module.service';
import {IJsonAtivosconfiguracoes} from '../../../../modules/ativos/ativosconfiguracoes/jsonAtivosConfiguracoes.module.interface';

const STEP_INDEX_CONTAS = 1;
const STEP_INDEX_DEPRECIACOES = 2;

@Component({
  selector: 'ativosimport-edit',
  templateUrl: './ativosImport.entity.edit.component.html'
})
export class AtivosImportEditComponent extends ModuloEntityDetailComponent<IJsonAtivosImport> {
  public readonly definition: IPlNavWizardDefinition;
  public readonly amortizacaoOutput: string;
  public readonly pocsOutput: string;
  public readonly filterMovimento: string;
  public readonly estadoAtivosSource: Array<IRadioItemAtivos>;
  public readonly metodoCaclAtivosSource: Array<IRadioItemAtivos>;
  public readonly baseCaclAtivosSource: Array<IRadioItemAtivos>;
  public readonly enumAtivosEstado: typeof EAtivosEstado;
  public filterContaDoAtivo: string;
  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosImportService: AtivosImportService,
    private readonly _ativosConfiguracoesService: AtivosConfiguracoesService
  ) {
    super(_injector);
    this.enumAtivosEstado = EAtivosEstado;
    this.definition = {
      items: []
    };
    this.estadoAtivosSource = [
      {
        value: EAtivosEstado.Normal,
        label: 'ativosimport.radioOptions.normal'
      },
      {
        value: EAtivosEstado.Abatido,
        label: 'ativosimport.radioOptions.abatido'
      },
      {
        value: EAtivosEstado.Vendido,
        label: 'ativosimport.radioOptions.vendido'
      }
    ];
    this.metodoCaclAtivosSource = [
      {
        value: EAtivosAquisicaoMetodoCalculo.QuotasConstantes,
        label: 'ativosimport.radioOptions.quotasConstantes'
      },
      {
        value: EAtivosAquisicaoMetodoCalculo.QuotasDecrescentes,
        label: 'ativosimport.radioOptions.quotasDecrescentes'
      }
    ];
    this.baseCaclAtivosSource = [
      {
        value: EAtivosAquisicaoBaseCalculo.InicioUtilizacao,
        label: 'ativosimport.radioOptions.inicioUtilizacao'
      },
      {
        value: EAtivosAquisicaoBaseCalculo.BaseAnual,
        label: 'ativosimport.radioOptions.baseAnual'
      }
    ];
    this.amortizacaoOutput = '{{nImtab}} - {{nome}}';
    this.pocsOutput = '{{nConta}} - {{nome}}';
    this.filterMovimento = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.filterContaDoAtivo = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.finalize = this.finalize.bind(this);
    this._ativosConfiguracoesService.get().then((response: HttpResponse<IJsonAtivosconfiguracoes>) => {
      if (response.body.radicaisContasImobilizado) {
        const filterRadicaisAtivos: string = response.body.radicaisContasImobilizado
          .split(',')
          .map((radicalAtivo: string) => `nconta=%${radicalAtivo}%`)
          .join('|');
        this.filterContaDoAtivo += `&(${filterRadicaisAtivos})`;
      }
    });
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    return super.onUpdate(stateType).then(() => {
      if (!this.model || stateType === EEntityStateDetailType.NEW) {
        this.model = {
          ativosImportID: '',
          baseCalculo: 0,
          codAmortizacao: '',
          codAtivoGerado: 0,
          codIVAAquisicao: 0,
          dataAbate: moment(),
          dataAquisicao: moment([this._configService.configurations.empresa.anoEmCursoIRC - 1]).startOf('year'),
          dataInicioUtiliza: moment([this._configService.configurations.empresa.anoEmCursoIRC - 1]).startOf('year'),
          dataUltProcessamento: moment([this._configService.configurations.empresa.anoEmCursoIRC - 1]).endOf('year'),
          depreciacaoAcumulada: 0,
          depreciacaoAcumuladaCalculada: 0,
          depreciacaoDiferenca: 0,
          depreciacaoExercicio: 0,
          depreciacaoFiscalAcumuladaCalculada: 0,
          depreciacaoFiscalDiferenca: 0,
          designacao: '',
          erro: false,
          estado: EAtivosEstado.Normal,
          fiscalDepAcumulada: 0,
          fiscalDepExercicio: 0,
          fiscalPercReducao: 0,
          fiscalTaxaAmortiza: 0,
          iVADeduzido: 0,
          importado: false,
          metodoCalculo: 0,
          nContaAmortAcumu: '',
          nContaAmortExerc: '',
          nContaDoAtivo: '',
          nContaFornecedor: '',
          nContaIVA: '',
          nLanc: '',
          nMapaAmortizacao: '32',
          ndiario: 0,
          ndocInterno: '',
          percReducao: 0,
          periodo: '',
          refExterna: '',
          taxaAmortizacao: 0,
          usado: false,
          valorAquisicao: 0,
          valorAquisicaoFiscal: 0,
          valorResidual: 0,
          valorVenda: 0,
          valoresDepManuais: false,
          viatLigArtigo11DR: false,
          quantidade: 1,
          nomeCodAmortizacao: '',
          nomeContaAtivo: '',
          nomeContaFornecedor: '',
          nomeMapaAmortizacao: 'Mapa de depreciações e amortizações'
        };
      }
    });
  }

  public calculaTaxasCodAmortizacao(value: string): Promise<void> {
    this.promise = this._ativosImportService.getValorTaxas(value).then((response: HttpResponse<IJsonAtivosImportTaxa>) => {
      const tax = response.body.value.replace(',', '.');
      if (this.model) {
        this.model.taxaAmortizacao = Number(tax);
        this.model.fiscalTaxaAmortiza = Number(tax);
      }
    });
    return this.promise;
  }

  public stepChanged({currentStep}: IPlNavWizardEventStep): Promise<void> | void {
    if (currentStep === this.definition.items[STEP_INDEX_CONTAS]) {
      setTimeout(() => {
        const input: HTMLElement = this._element.querySelector('input[name="nContaDoAtivo"]');
        if (input) {
          focusElement(input);
        }
      });
    }
    if (currentStep === this.definition.items[STEP_INDEX_DEPRECIACOES]) {
      setTimeout(() => {
        const input: HTMLElement = this._element.querySelector('input[name="depreciacaoAcumulada"]');
        if (input) {
          focusElement(input);
        }
      });
    }
  }

  public finalize(): Promise<void> {
    return this.save().then(() => undefined);
  }

  protected _defaultCaption(id?: string | number): string {
    if (isObject(this.model) && this.model.designacao) {
      id = this.model.designacao;
    }
    return super._defaultCaption(id);
  }
}
