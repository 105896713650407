<div class="configs-erp-pca-module table-responsive">
  <pl-navpill attrName="configs-erp-pca-module-nav">
    <pl-navpill-panel [id]="'geral'" [caption]="'global.text.general'">
      <div *plNavPillPanelContent>
        <dx-data-grid [cgDxDataGrid]="dataGridConfigPCA" cgDxDataGridInstanceName="configPCA" [dataSource]="configList">
          <div *dxTemplate="let cellTemplateInfo of 'cellTemplateValues'">
            <pl-edit-select
              *ngIf="cellTemplateInfo.data.name === 'pca.periodo'"
              attrName="periodo"
              [source]="optionsPeriodo"
              [model]="cellTemplateInfo.data.value"
              (modelChange)="cellTemplateInfo.data.value = $event; saveField(cellTemplateInfo.data)"
              [properties]="{readonly: cellTemplateInfo.data.readOnly, disallowClear: true}">
            </pl-edit-select>

            <div class="configs-erp-pca-module-inline-edit">
              <pl-inline-edit
                *ngIf="cellTemplateInfo.data.name === 'pca.reportrecibosal'"
                [type]="cellTemplateInfo.data.cguid || cellTemplateInfo.data.kind"
                [(model)]="cellTemplateInfo.data.value"
                [onValidate]="fnOnValidateReport"
                [properties]="{readonly: cellTemplateInfo.data.readOnly, inlineMode: true, entity: {output: 'key'}}">
              </pl-inline-edit>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-navpill-panel>

    <pl-navpill-panel [id]="'configEvents'" [caption]="'prheventosconfig.title_small'">
      <div *plNavPillPanelContent>
        <colaboradores-configuracoes-eventos></colaboradores-configuracoes-eventos>
      </div>
    </pl-navpill-panel>
  </pl-navpill>
</div>
