import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {PCADashboardsComponent} from './components/dashBoards.module.component';

export const MODULE_PCA_DASHBOARDS: IModuleDefinition = {
  name: 'pcaDashboards',
  state: {
    url: '/dashboard',
    component: PCADashboardsComponent,
    data: {
      roles: [ROLE.PCA, ROLE.CONTABILIDADE],
      icon: 'fa-bar-chart',
      pageTitle: 'global.menu.dashboard'
    }
  }
};
