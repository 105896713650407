import {EnvironmentProviders, LOCALE_ID, makeEnvironmentProviders} from '@angular/core';
import {APP_LOCALE} from '../constants';

export function provideLocaleId(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: LOCALE_ID,
      useFactory: () => APP_LOCALE.value
    }
  ]);
}
