import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {MODULE_NAME_EXTRATOS_CLIENTES, MODULE_NAME_EXTRATOS_FORNECEDORES} from './extratosClifos.module.interface';
import {ENTITY_NAME_CLIENTES, ENTITY_NAME_FORNECEDORES} from '../../entities/clifos/clifos.entity.interface';
import {ExtratosClifosComponent} from './components/extratosClifos.module.component';
import {ProviderLike} from '@uirouter/core';

export const MODULE_EXTRATOS_FORNECEDORES: IModuleDefinition = {
  name: MODULE_NAME_EXTRATOS_FORNECEDORES,
  state: {
    url: `/${MODULE_NAME_EXTRATOS_FORNECEDORES}`,
    component: ExtratosClifosComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.extratosFornecedores'
    },
    resolve: [resolverTipo(ENTITY_NAME_FORNECEDORES)]
  }
};

export const MODULE_EXTRATOS_CLIENTES: IModuleDefinition = {
  name: MODULE_NAME_EXTRATOS_CLIENTES,
  state: {
    url: `/${MODULE_NAME_EXTRATOS_CLIENTES}`,
    component: ExtratosClifosComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.extratosClientes'
    },
    resolve: [resolverTipo(ENTITY_NAME_CLIENTES)]
  }
};

export function resolverTipo(tipo: string): ProviderLike {
  return {provide: 'tipo', useValue: tipo};
}
