<div class="contabilidade-digital-client-connect contabilidade-digital-client-connect-native">
  <div class="row contabilidade-digital-client-connect-error" *ngIf="!!error">
    <div class="col-12">
      <pl-alert type="error" [closeable]="!criticalError">{{ error }}</pl-alert>
      <hr *ngIf="!criticalError" />
    </div>
  </div>

  <ng-container *ngIf="!criticalError">
    <div class="contabilidadedigital-genericviewer-current-folder contabilidadedigital-flatviewer-current-folder" *ngIf="!isMobile">
      <nav aria-label="contabilidadedigital-genericviewer-current-folder">
        <ol class="breadcrumb">
          <li class="contabilidadedigital-genericviewer-current-folder-label">
            <i class="fa fa-folder" aria-hidden="true"></i>&nbsp;<span [translate]="'arquivodigital.genericviewer.text.currentFolder'"></span>:
          </li>
          <li class="breadcrumb-item contabilidadedigital-genericviewer-current-folder-item" *ngFor="let folderPath of currentFolderPath">
            <button type="button" class="btn btn-link" (click)="setFolder(folderPath.folderID)">{{ folderPath.name }}</button>
          </li>
        </ol>
      </nav>
    </div>

    <ng-container [ngSwitch]="isMobile">
      <div class="row" *ngSwitchCase="false">
        <div class="col-sm-12 col-md-4 contabilidade-digital-client-connect-treeviewer-wrapper">
          <div class="contabilidade-digital-client-connect-treeviewer" #elementTreeViewer (scroll)="treeViewerScrolled($event)">
            <contabilidadedigital-treeviewer
              [folderID]="defaultFolderId"
              [folderIdAsUpwards]="true"
              [upwardsUntilFolderId]="upwardsUntilFolderId"
              [upwardsIncludeSelf]="true"
              [showFiles]="true"
              [showCountFolderFiles]="true"
              [callback]="callback"
              (evtFolderChanged)="changedFolder($event)"
              (evtFileChanged)="changedFile($event)"
              (evtActionAddDoc)="onActionAdd()"
              (evtActionCaptureDoc)="onActionCapture()"
              (evtActionDeleteDocs)="onActionDeleteDocs()"
              (evtActionDeleteDoc)="onActionDeleteDoc()"
              (evtResponseErrored)="responseErrored($event)">
            </contabilidadedigital-treeviewer>
          </div>

          <div class="contabilidade-digital-client-connect-treeviewer-back-top" [class.visible]="treeViewerScrollToTopVisible" (click)="treeViewerScrollToTop()">
            <i class="fa fa-fw fa-angle-up" aria-hidden="true"></i>
          </div>
        </div>

        <div class="col-sm-12 col-md-8">
          <div class="contabilidade-digital-client-connect-docviewer">
            <arquivodigital-docviewer [documentsService]="documentsService" [(docId)]="docID" [toolbarInstanceId]="docviewerToolbarId"></arquivodigital-docviewer>
          </div>
        </div>
      </div>

      <div class="row" *ngSwitchCase="true">
        <div class="col-12 contabilidade-digital-client-connect-flatviewer">
          <contabilidadedigital-flatviewer
            [folderID]="defaultFolderId"
            [folderIdAsUpwards]="true"
            [upwardsUntilFolderId]="upwardsUntilFolderId"
            [upwardsIncludeSelf]="true"
            [showFiles]="true"
            [callback]="callback"
            (evtFolderChanged)="changedFolder($event)"
            (evtFileChanged)="changedFile($event)"
            (evtResponseErrored)="responseErrored($event)">
          </contabilidadedigital-flatviewer>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
