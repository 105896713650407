import cssVars from 'css-vars-ponyfill';
import {PlTranslateService} from 'pl-comps-angular';

/* eslint-disable @typescript-eslint/naming-convention */

interface II18nScss {
  or: string;
  and: string;
  'locked-portal': string;
  'locked-portal-cgstore': string;
  'locked-portal-expired': string;
}

const prefix = 'i18n-';

const i18nKeys: ReadonlyArray<keyof II18nScss> = Object.freeze(['or', 'and', 'locked-portal', 'locked-portal-cgstore', 'locked-portal-expired']);

export function applyScssI18n(service: PlTranslateService): void {
  const i18n: Partial<II18nScss> = {};
  for (const i18nKey of i18nKeys) {
    i18n[prefix + i18nKey] = `"${service.translate(`scss.${i18nKey}`)}"`;
  }
  cssVars({
    exclude: '',
    include: 'link[rel=stylesheet]',
    onlyLegacy: true,
    preserveStatic: true,
    preserveVars: false,
    rootElement: document,
    shadowDOM: false,
    silent: false,
    updateDOM: true,
    updateURLs: true,
    variables: i18n,
    watch: false
  });
}
