import {HttpResponse} from '@angular/common/http';
import {IJsonModelo22} from './jsonModelo22.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {Modelo22ModuleComponent} from './components/modelo22.module.component';
import {Modelo22Service} from './modelo22.module.service';
import {MODULE_NAME_MODELO_22} from './modelo22.module.interface';
import {ROLE} from '../../../services/role.const';

export const MODULE_MODELO_22: IModuleDefinition = {
  name: MODULE_NAME_MODELO_22,
  state: {
    url: `/${MODULE_NAME_MODELO_22}`,
    component: Modelo22ModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.modelo22'
    },
    resolve: [
      {
        provide: 'modelo22',
        deps: [Modelo22Service],
        useFactory: (modelo22Service: Modelo22Service): Promise<IJsonModelo22> => {
          return modelo22Service.getModelo22().then((response: HttpResponse<IJsonModelo22>) => response.body);
        }
      },
      {
        provide: 'anosAnteriores',
        deps: [Modelo22Service],
        useFactory: (modelo22Service: Modelo22Service): Promise<Array<number>> => {
          return modelo22Service.getAnosAnteriores().then((response: HttpResponse<Array<number>>) => response.body);
        }
      },
      {
        provide: 'showAlertDerrama',
        deps: ['modelo22', Modelo22Service],
        useFactory: (modelo22: IJsonModelo22, modelo22Service: Modelo22Service): Promise<boolean> => {
          return modelo22Service.checkCalculoDerrama(modelo22.anoDaDeclaracao).then((response: HttpResponse<boolean>) => response.body);
        }
      }
    ]
  }
};
