import {ENTITY_NAME_TRDOC_CONFIG} from './trDocConfig.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_TRDOC_CONFIG: IEntityDefinition = {
  name: ENTITY_NAME_TRDOC_CONFIG,
  roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
  asModule: false,
  metadata: {
    keyName: 'nDocfaDestino',
    fields: [
      {name: 'nDocfaDestino', type: 'number', caption: 'trdocconfig.fields.nDocfaDestino', validators: {required: true, min: 1}},
      {name: 'nNumerDestino', type: 'number', caption: 'trdocconfig.fields.nNumerDestino', validators: {required: true, min: 1}},
      {name: 'dataDoc', type: 'date', caption: 'trdocconfig.fields.dataDoc'},
      {name: 'dataDocOrigem', type: 'checkbox', caption: 'trdocconfig.fields.dataDocOrigem'},
      {name: 'nFactFornec', caption: 'trdocconfig.fields.nFactFornec'},
      {name: 'nFactFornecOrigem', type: 'checkbox', caption: 'trdocconfig.fields.nFactFornecOrigem'},
      {name: 'dataDocExterno', type: 'date', caption: 'trdocconfig.fields.dataDocExterno'},
      {name: 'dataDocExternoOrigem', type: 'checkbox', caption: 'trdocconfig.fields.dataDocExternoOrigem'},
      {name: 'nRefProcesso', caption: 'trdocconfig.fields.nRefProcesso'},
      {name: 'nRefProcessoOrigem', type: 'checkbox', caption: 'trdocconfig.fields.nRefProcessoOrigem'},
      {name: 'nArmazem', type: 'number', caption: 'trdocconfig.fields.nArmazem'},
      {name: 'nArmazemOrigem', type: 'checkbox', caption: 'trdocconfig.fields.nArmazemOrigem'},
      {name: 'encerraDocumentoOrigem', type: 'checkbox', caption: 'trdocconfig.fields.encerraDocumentoOrigem'},
      {name: 'encerraDocumentoDestino', type: 'checkbox', caption: 'trdocconfig.fields.encerraDocumentoDestino'}
    ]
  }
};
