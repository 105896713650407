import {Component, Injector} from '@angular/core';
import {EDocContabilidadeField} from '../../docContabilidade.interface';
import {DocContabilidadeFieldAutocomplete} from '../docContabilidade.field.autocomplete';

@Component({
  selector: 'doccontabilidade-ncontribuinte',
  templateUrl: './docContabilidade.nContribuinte.component.html'
})
export class DocContabilidadeNContribuinteComponent extends DocContabilidadeFieldAutocomplete<string> {
  constructor(protected readonly _injector: Injector) {
    super(EDocContabilidadeField.NCONTRIBUINTE, _injector, 'nContribuinte');
  }

  public modelChanged(value: string): void {
    super.modelChanged(value);
    this.evtModelChanged.emit({
      linha: this.linha,
      value: value,
      oldValue: this.linha.nContrib
    });
  }
}
