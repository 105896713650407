<div class="contratostiposervicos">
  <entity-detail
    entityName="contratostiposervicos"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL, validators: {required: {value: true}}}">
        <pl-group>
          <label [translate]="'contratostiposervicos.fields.nomeClasse'"></label>
          <edit>
            <entity-autocomplete
              entity="classescontratosservicos"
              attrName="classescontratosservicos"
              [model]="model"
              (modelChange)="onClasseContratoChanged($event)"
              (selectedKeyChange)="model.nClasseCtr = $event"
              (evtSelectedDescriptionChanged)="model.nClasseCtr = $event"
              [fieldsMap]="{nClasseCtr: 'nClasseCtr', nomeClasseCtr: 'nomeClasse'}"
              [output]="'nomeClasseCtr'"
              [properties]="{readonly: type === stateTypes.DETAIL || type === stateTypes.EDIT}"
              plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group [properties]="{readonly: type === stateTypes.DETAIL}">
            <label [translate]="'contratostiposervicos.fields.nTipoContrato'"></label>
            <edit>
              <pl-edit-number
                attrName="nTipoContrato"
                [model]="nTipoContrato"
                (modelChange)="nTipoContrato = $event; changedNTipoContrato(nTipoContrato)"
                [placeholder]="'contratostiposervicos.fields.nTipoContratoPlaceholder'"
                [properties]="{readonly: type === stateTypes.DETAIL || type === stateTypes.EDIT, validators: {required: {value: false}}, decimalsLimit: 0}">
              </pl-edit-number>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'contratostiposervicos.fields.nome'"></label>
            <edit>
              <pl-edit-text
                attrName="nome"
                [(model)]="model.nome"
                [placeholder]="'contratostiposervicos.fields.nomePlaceholder'"
                [properties]="{validators: {required: {value: true}, maxlength: {value: 100}}}">
              </pl-edit-text>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'contratostiposervicos.fields.periocidade'"></label>
          <edit>
            <pl-edit [(model)]="model.periocidade" [type]="dataSourceNamePeriocidadeContratoTipo"></pl-edit>
          </edit>
        </pl-group>

        <pl-group class="docfa-edit">
          <label [translate]="'contratostiposervicos.fields.nDocfa'"></label>
          <edit>
            <entity-autocomplete
              entity="docfas"
              attrName="tipodocumento"
              [model]="model"
              (modelChange)="onDocFaChanged($event)"
              (selectedKeyChange)="model.nDocfa = $event"
              [fieldsMap]="{nDocFa: 'nDocfa', nome: 'nomeDocfa'}"
              [output]="docfasOutput">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group class="docfanum-edit">
          <label [translate]="'contratostiposervicos.fields.nNumer'"></label>
          <edit>
            <entity-autocomplete entity="docfasnums" attrName="numeracao" [filter]="docfaNumFilter" [model]="model" [(selectedKey)]="model.nNumer" [output]="'nNumer'" [outputKey]="'nNumer'">
            </entity-autocomplete>
          </edit>
          <actions *ngIf="type !== stateTypes.DETAIL">
            <pl-tooltip [config]="{text: 'contratostiposervicos.actions.nnumer', placement: 'left'}">
              <button *plTooltipContent type="button" class="btn btn-primary btn-sm action-add-line" [disabled]="!model.nDocfa" (click)="addNumer()">
                <i class="fa fa-fw fa-plus"></i>
              </button>
            </pl-tooltip>
          </actions>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'contratostiposervicos.fields.faturacaomes'"></label>
            <edit>
              <pl-edit type="integer" [model]="model.faturacaoNoMes" (modelChange)="faturacaoNoMesChanged($event)" [properties]="{readonly: true, step: 1}"></pl-edit>
            </edit>
            <actions>
              <div class="btn-group">
                <button class="btn btn-danger" type="button" [disabled]="type === stateTypes.DETAIL" (click)="decMes()">
                  <pl-tooltip [config]="{text: 'contratostiposervicos.actions.decrementafaturacaomes'}">
                    <i *plTooltipContent class="fa fa-fw fa-minus"></i>
                  </pl-tooltip>
                </button>

                <button class="btn btn-success" type="button" [disabled]="type === stateTypes.DETAIL" (click)="incMes()">
                  <pl-tooltip [config]="{text: 'contratostiposervicos.actions.incrementafaturacaomes'}">
                    <i *plTooltipContent class="fa fa-fw fa-plus"></i>
                  </pl-tooltip>
                </button>
              </div>
            </actions>
          </pl-group>

          <pl-group>
            <label></label>
            <edit>
              <span>{{ infoFaturacaoMes }}</span>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <edit>
            <pl-edit type="checkbox" [(model)]="model.processaDiferimento" [properties]="{readonly: type === stateTypes.DETAIL, label: 'contratostiposervicos.fields.processaDiferimento'}"></pl-edit>
          </edit>
        </pl-group>

        <hr />

        <div class="row">
          <div class="col-12 col-md-8" id="autocomplete-container">
            <pl-group>
              <label class="d-flex">
                <span [translate]="'contratostiposervicos.fields.txtContrato'"></span>
                <pl-tooltip class="tooltip-info-label ms-2" [config]="{text: 'contratostiposervicos.messages.txtContrato', container: 'body', placement: ['bottom', 'left']}">
                  <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                </pl-tooltip>
              </label>
              <edit>
                <pl-edit-textarea id="text-area-contrato" [(model)]="model.txtContrato" [properties]="{validators: {required: {value: false}}}"></pl-edit-textarea>
              </edit>
            </pl-group>
          </div>

          <div class="col-12 col-md-4">
            <fieldset class="fieldset-mt-match-input">
              <legend [translate]="'contratostiposervicos.fields.variables'"></legend>
              <div class="templates-vars-list">
                <ng-container *ngFor="let variable of variablesTxtContratoSource">
                  <div class="templates-var-item" (click)="applyVariable(variable)">{{ variable }}</div>
                </ng-container>
              </div>
            </fieldset>
          </div>
        </div>

        <fieldset>
          <legend [translate]="'contratostiposervicos.fields.proceAuto'"></legend>
          <cg-blocked-plugin [pluginRoles]="roleComercialAdvanced"></cg-blocked-plugin>
          <div *ngIf="comercialAdvanced">
            <pl-group>
              <pl-group>
                <label [translate]="'contratostiposervicos.fields.procAutoTipo'"></label>
                <edit>
                  <pl-edit type="procAutoTipo" [(model)]="model.procAutoTipo" [properties]="{validators: {required: {value: false}}}"></pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'contratostiposervicos.fields.procAutoDiaFixo'"></label>
                <edit>
                  <pl-edit type="number" [(model)]="model.procAutoDiaFixo" [properties]="{disabled: model.procAutoTipo !== procAutoTipoEnum.DiaFixo, decimalsLimit: 0}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <edit>
                <pl-edit type="checkbox" [(model)]="model.procAutoEnviaMail" [properties]="{label: 'contratostiposervicos.fields.procAutoEnviaMail'}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label class="d-flex">
                <span [translate]="'contratostiposervicos.fields.proAutoEmailResponsavel'"></span>
                <pl-tooltip class="tooltip-info-label ms-2" [config]="{text: 'contratostiposervicos.messages.emailNotification', container: 'body', placement: ['bottom', 'left']}">
                  <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                </pl-tooltip>
              </label>
              <edit>
                <pl-edit type="email" [(model)]="model.proAutoEmailResponsavel" [properties]="{validators: {required: {value: false}}}"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </fieldset>
      </pl-form>
    </div>
  </entity-detail>
</div>
