import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonAtivosCoeficienteCM} from './jsonAtivosCoeficienteCM.module.interface';
import {IJsonAtivosNumber} from '../../../entities/ativos/interfaces/jsonAtivos.entity.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AtivosCoeficienteCMService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/ativoscoeficientecm`;
  }

  public getAnosDisponiveis(): TServiceResponse<Array<IJsonAtivosNumber>> {
    return this._apiService.get<Array<IJsonAtivosNumber>>({
      url: `${this._path}/anosdisponiveis`
    });
  }

  public getPesquisaCoeficientes(ano: number): TServiceResponse<Array<IJsonAtivosCoeficienteCM>> {
    return this._apiService.get<Array<IJsonAtivosCoeficienteCM>>({
      url: `${this._path}/pesquisacoeficientes`,
      params: {
        ano: ano
      }
    });
  }

  public postGravaCoeficientes(ano: number, coeficientecm: Array<IJsonAtivosCoeficienteCM>): TServiceResponse<Array<IJsonAtivosCoeficienteCM>> {
    return this._apiService.post<Array<IJsonAtivosCoeficienteCM>>({
      url: `${this._path}/gravacoeficientes`,
      body: coeficientecm,
      params: {
        ano: ano
      }
    });
  }
}
