import {
  IAtDpIvaItem,
  IAtDrIvaItem,
  IAtIESItem,
  IAtImoveisNotasCobranca,
  IAtIRCMod22Item,
  IAtIRCPagamentosAntecipadosItem,
  IAtIRSMod10Item,
  IAtIUCDocument,
  IAtIUCDocumentDetails,
  IAtIUCDocumentDetailsLiquidacoes,
  IAtIUCEmitir,
  IAtReciboRendaItem,
  IAtRecibosRendasContratoLocatario,
  IAtRecibosRendasReciboDetails,
  IAtReciboVerdeItem,
  IAtRetencoesIRCIRSItem
} from '../../../../../common/interfaces/at.interfaces';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {TDate} from '../../../../../common/dates';

export enum EDocumentosFiscaisIUCEmitir {
  GRUPO_LIGEIROS = 1,
  GRUPO_MERCADORIAS = 2
}

export enum EDocumentosFiscaisMainMenu {
  IVA,
  IRC,
  RECIBOS,
  IES,
  IRS,
  RETENCOES,
  IUC,
  VEICULOS,
  CERTIDOES,
  IMOVEIS,
  PENHORAS,
  SS_SITUACAO_CONTRIB,
  VIACTT_CAIXA_CORREIO
}

export enum EDocumentosFiscaisTab {
  None,
  DpIva,
  DrIva,
  IrcPagAnt,
  IrcMod22,
  RecibosRV,
  RecibosRR,
  IucDoc,
  IucEmitir,
  CertidoesConsulta,
  CertidoesEmitir,
  ImoveisPP,
  ImoveisNC
}

export enum EDocumentosFiscaisCertidaoTipo {
  Todos = 'TODOS',
  LiquidacaoIRS = 'L',
  RenunciaIsencaoIvaImovel = 'R',
  ResidenciaFiscal = 'M',
  DividaNaoDivida = 'N',
  SujPassivoRecupIVAEstrangeiro = 'C',
  ResidenteNaoHabitual = '60',
  DomicilioFiscal = 'O',
  PredialNegativa = '20',
  Predial = '21'
}

export enum EDocumentosFiscaisCertidaoEstado {
  Todos = 'TODOS',
  Activa = 'A',
  Pendente = 'P',
  PendenteConfirmacao = 'C',
  Expirada = 'E',
  Anulada = 'X'
}

export enum EDocumentosFiscaisCredentialsModalType {
  NONE,
  AT,
  SSD,
  VIACTT
}

export interface IDocumentosFiscaisDpIvaItem extends IAtDpIvaItem {
  _hasComprovativo?: boolean;
  _hasDocPagamento?: boolean;
}

export interface IDocumentosFiscaisDrIvaItem extends IAtDrIvaItem {
  _hasComprovativo?: boolean;
}

export interface IDocumentosFiscaisIRCPagAntItem extends IAtIRCPagamentosAntecipadosItem {
  _hasDetalhes?: boolean;
}

export interface IDocumentosFiscaisIRCMod22Item extends IAtIRCMod22Item {
  _hasComprovativo?: boolean;
  _hasDocPagamento?: boolean;
}

export interface IDocumentosFiscaisRecibosRVItem extends IAtReciboVerdeItem {
  _hasRecibo?: boolean;
  _hasReciboEco?: boolean;
}

export interface IDocumentosFiscaisRecibosRRItem extends IAtReciboRendaItem {
  _hasDocument?: boolean;
  recRefContrato?: string;
  periodoFull?: string;
  contrato?: IAtRecibosRendasContratoLocatario;
  reciboDetails?: IAtRecibosRendasReciboDetails;
}

export interface IDocumentosFiscaisIESItem extends IAtIESItem {
  _hasComprovativo?: boolean;
}

export interface IDocumentosFiscaisIRSMod10Item extends IAtIRSMod10Item {}

export interface IDocumentosFiscaisRetencoesIRCIRSItem extends IAtRetencoesIRCIRSItem {
  _hasComprovativo?: boolean;
}

export interface IDocumentosFiscaisDetailsLiquidacoes extends IAtIUCDocumentDetailsLiquidacoes {
  anoImposto?: number;
  numDocumento?: number;
}

export interface IDocumentosFiscaosIUCDocumentDetails extends IAtIUCDocumentDetails {
  liquidacoes: Array<IDocumentosFiscaisDetailsLiquidacoes>;
}

export interface IDocumentosFiscaisIUCDocument extends IAtIUCDocument {
  details: IDocumentosFiscaosIUCDocumentDetails;
}

export interface IDocumentosFiscaisIUCItem extends IDocumentosFiscaisIUCDocument {
  _hasDocPagamento?: boolean;
}

export interface IDocumentosFiscaisEmitirIUCItem extends IAtIUCEmitir {
  _canIssue?: boolean;
}

export interface IDocumentosFiscaisImoveisNotasCobrancaItem extends IAtImoveisNotasCobranca {
  _haveGuia?: boolean;
}

export interface IDocumentosFiscaisModule {
  dataGridDefinition: IDevExpressDataGrid;
  dataGridInstanceName: string;
  dataGridInstance: dxDataGrid;
  menu: EDocumentosFiscaisMainMenu;
  firstLoad: boolean;
}

export interface IDocumentosFiscaisDpIvaModule extends IDocumentosFiscaisModule {}

export interface IDocumentosFiscaisDrIvaModule extends IDocumentosFiscaisModule {}

export interface IDocumentosFiscaisIRCPagAntModule extends IDocumentosFiscaisModule {}

export interface IDocumentosFiscaisIRCMod22Module extends IDocumentosFiscaisModule {}

export interface IDocumentosFiscaisRecibosRVModule extends IDocumentosFiscaisModule {
  startDate: TDate;
  endDate: TDate;
  nifPrestador: string;
}

export interface IDocumentosFiscaisRecibosRRModule extends IDocumentosFiscaisModule {
  startDate: TDate;
  endDate: TDate;
}

export interface IDocumentosFiscaisIESModule extends IDocumentosFiscaisModule {}

export interface IDocumentosFiscaisIRSModule extends IDocumentosFiscaisModule {}

export interface IDocumentosFiscaisRetencoesIRCIRSModule extends IDocumentosFiscaisModule {}

export interface IDocumentosFiscaisIUCModule extends IDocumentosFiscaisModule {
  emitirFirstLoad: boolean;
  emitirGrupo: EDocumentosFiscaisIUCEmitir;
  emitirDataGridDefinition: IDevExpressDataGrid;
  emitirDataGridInstanceName: string;
  emitirDataGridInstance: dxDataGrid;
  detailsDataGridDefinition: IDevExpressDataGrid;
  detailsDataGridInstanceName: string;
  liquidacoesDataGridDefinition: IDevExpressDataGrid;
  liquidacoesDataGridInstanceName: string;
}

export interface IDocumentosFiscaisVeiculosModule extends IDocumentosFiscaisModule {}

export interface IDocumentosFiscaisImoveisModule extends IDocumentosFiscaisModule {
  ncDataGridDefinition: IDevExpressDataGrid;
  ncDataGridInstanceName: string;
  ncDataGridInstance: dxDataGrid;
  ncFirstLoad: boolean;
}

export interface IDocumentosFiscaisCertidoesModule extends IDocumentosFiscaisModule {
  startDate: TDate;
  untilDate: TDate;
  tipo: EDocumentosFiscaisCertidaoTipo;
  estado: EDocumentosFiscaisCertidaoEstado;
  pedidoTipoCertidao: EDocumentosFiscaisCertidaoTipo;
  pedidoLiqIRSAno: number;
}

export interface IDocumentosFiscaisPenhorasModule extends IDocumentosFiscaisModule {
  detailsDataGridDefinition: IDevExpressDataGrid;
  detailsDataGridInstanceName: string;
}

export interface IDocumentosFiscaisSSSitContribModule extends IDocumentosFiscaisModule {}

export interface IDocumentosFiscaisViaCTTModule extends IDocumentosFiscaisModule {}

export interface IDocumentosFiscaisDpIvaDocPagementoModel {
  type: EDpIvaDocPagamento;
  ivaEntregar: number;
  ivaRecuperar: number;
  ivaPago: number;
}

export enum EDpIvaDocPagamento {
  PrimeiraDeclaracao,
  DeclaracaoSubstituicao
}

export interface ITipoDpIvaDocPagamento {
  value: EDpIvaDocPagamento;
  label: string;
}

export interface IDocumentosFiscaisMainMenuItem {
  caption: string;
  isHeader?: boolean;
  menu: EDocumentosFiscaisMainMenu | undefined;
}

export interface IDocumentosFiscaisModuleParams {
  menu: EDocumentosFiscaisMainMenu;
}

export const MODULE_NAME_DOCUMENTOS_FISCAIS = 'documentosFiscais';
