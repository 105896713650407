import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonCodigoContabilistico} from './jsonCodigoContabilistico.entity.interface';
import {ENTITY_NAME_CODIGOS_CONTABILISTICOS} from './codigosContabilisticos.entity.interface';

export const ENTITY_CODIGOS_CONTABILISTICOS: IEntityDefinition<IJsonCodigoContabilistico> = {
  name: ENTITY_NAME_CODIGOS_CONTABILISTICOS,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.ATIVOS],
  searchPlaceholder: 'codigoscontabilisticos.pesquisa',
  metadata: {
    keyName: 'codcontab',
    fields: [
      {name: 'codcontab', caption: 'codigoscontabilisticos.fields.codcontab', placeholder: 'codigoscontabilisticos.placeholders.codcontab', validators: {required: true}},
      {name: 'nome', caption: 'codigoscontabilisticos.fields.nome', placeholder: 'codigoscontabilisticos.placeholders.nome', validators: {required: true}}
    ],
    order: 'codcontab',
    searchFields: 'codcontab,nome'
  },
  autocomplete: {
    rowTemplate: '{{codcontab}} - {{nome}}',
    output: 'nome',
    searchFields: 'codcontab,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.codigoscontabilisticos'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.codigoscontabilisticos'
      }
    }
  }
};
