import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {copy, IPlUITreeDrag, IPlUITreeDragNode, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {EGestaoComunicacoesEstadoTipo, IJsonGestaoComunicacoesEstado, IJsonGestaoComunicacoesEstadoOrdem} from '../../jsonGestaoComunicacoesEstado.module.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {GestaoComunicacoesEstadosEmpresaConfigDetailModalComponent} from './detail/gestaoComunicacoes.estadosEmpresaConfig.detail.modal.component';
import {IJsonGestaoComunicacoesConfigEmpresaReg, IJsonGestaoComunicacoesTopico} from '../../jsonGestaoComunicacoesTopico.module.interface';
import {GestaoComunicacoesTopicoService} from '../../gestaoComunicacoesTopico.module.service';
import {GestaoComunicacoesEstadoService} from '../../gestaoComunicacoesEstado.module.service';
import {HttpResponse} from '@angular/common/http';
import {IEmpresaExtended} from '../../../../../entities/empresas/empresas.entity.interface';
import {IGestaoComunicacoesConfigUtilizador} from '../../gestaoComunicacoes.module.interface';

@Component({
  selector: 'gestao-comunicacoes-estados-empresa-config-modal',
  templateUrl: './gestaoComunicacoes.estadosEmpresaConfig.modal.component.html'
})
export class GestaoComunicacoesEstadosEmpresaConfigModalComponent extends CGModalComponent<void> implements OnInit, OnDestroy {
  @Input() public estadosEmpresa: Array<IJsonGestaoComunicacoesEstadoOrdem>;
  @Input() public empresaSelectedDados: IEmpresaExtended;
  @Input() public userId: number;
  @Input() public selectedNEmpresa: string;
  @Input() public configUtilizadores: IJsonGestaoComunicacoesConfigEmpresaReg;
  @Input() public inheritedConfigUtilizadores: Array<IGestaoComunicacoesConfigUtilizador>;

  public readonly templateUtilizador: string;
  public estados: IPlUITreeDrag<IJsonGestaoComunicacoesEstadoOrdem>;
  public editing: boolean;
  public inherited: boolean;

  private _idGenerated: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _gestaoComunicacoesTopicoService: GestaoComunicacoesTopicoService,
    private readonly _gestaoComunicacoesEstadoService: GestaoComunicacoesEstadoService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.templateUtilizador = '{{nUtilizador}} - {{nomeUtilizador}}';
    this.estados = {items: []};
    this.editing = true;
    this._idGenerated = 1;
  }

  public ngOnInit(): void {
    this.inherited = !this.configUtilizadores.utilizadoresSelecionados.length;
    this.estadosEmpresa.forEach((item: IJsonGestaoComunicacoesEstadoOrdem) => {
      this.estados.items.push({meta: {_empresaSistema: this._isEmpresaSistema(item), ...item}});
    });

    this.inheritedConfigUtilizadores.forEach((item: IGestaoComunicacoesConfigUtilizador) => {
      item.nomeUtilizador = `${item.nomeUtilizador} (${this._plTranslateService.translate('gestaoComunicacoes.modal.empresaConfig.empresaConfigEstados.herdado')})`;
      item.id = this._generateId();
      item.inherited = true;
    });

    this.configUtilizadores.utilizadoresPossiveis.forEach((item: IGestaoComunicacoesConfigUtilizador) => {
      item.id = this._generateId();
      item.inherited = false;
    });
    this.configUtilizadores.utilizadoresSelecionados.forEach((item: IGestaoComunicacoesConfigUtilizador) => {
      item.id = this._generateId();
      item.inherited = false;
    });

    if (!this.configUtilizadores.utilizadoresSelecionados.length && this.configUtilizadores.utilizadoresPossiveis) {
      this.configUtilizadores.utilizadoresSelecionados.push(...this.inheritedConfigUtilizadores);
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public async close(): Promise<void> {
    // check if deleted in runtime UI, if so delete when saving
    this.estadosEmpresa.forEach((estado: IJsonGestaoComunicacoesEstadoOrdem, index: number) => {
      const exists: boolean = this.estados.items.some((item: IPlUITreeDragNode<IJsonGestaoComunicacoesEstadoOrdem>) => item.meta.comEstadoId === estado.comEstadoId);
      if (!exists) {
        this._gestaoComunicacoesEstadoService.deleteEstado(estado.comEstadoId);
        this.estadosEmpresa.splice(index, 1);
      }
    });

    // save the new order
    await this._gestaoComunicacoesEstadoService.saveEstadosOrdem(
      this.empresaSelectedDados.nempresa,
      this.userId,
      this.estados.items.map((item: IPlUITreeDragNode<IJsonGestaoComunicacoesEstadoOrdem>) => item.meta)
    );

    this._checkAndRemoveInheritedModelItems();
    await this._gestaoComunicacoesTopicoService.saveConfigEmpresa(this.selectedNEmpresa, this.configUtilizadores);

    this._plAlertService.success('gestaoComunicacoes.modal.empresaConfig.empresaConfigEstados.mensagens.successEstados');
    super.close();
  }

  public utilizadoresModelChange(selectedUsers: Array<IGestaoComunicacoesConfigUtilizador>): void {
    this.inherited = !selectedUsers.some((item: IGestaoComunicacoesConfigUtilizador) => !item.inherited);
    const hasNonInherited: boolean = selectedUsers.some((item: IGestaoComunicacoesConfigUtilizador) => !item.inherited);
    if (hasNonInherited) {
      this.configUtilizadores.utilizadoresSelecionados = this.configUtilizadores.utilizadoresSelecionados.filter((item: IGestaoComunicacoesConfigUtilizador) => !item.inherited);
    } else {
      this.configUtilizadores.utilizadoresSelecionados = [...this.inheritedConfigUtilizadores];
    }

    this.configUtilizadores.utilizadoresPossiveis = this.configUtilizadores.utilizadoresPossiveis.filter((item: IGestaoComunicacoesConfigUtilizador) => !item.inherited);
  }

  public async addEstado(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(GestaoComunicacoesEstadosEmpresaConfigDetailModalComponent, {size: 'xl'});
    const componentInstance: GestaoComunicacoesEstadosEmpresaConfigDetailModalComponent = modalInstance.componentInstance;
    componentInstance.update = false;
    componentInstance.selectedNEmpresa = this.selectedNEmpresa;

    try {
      await modalInstance.result;
    } finally {
      await this._refreshEstadosUI();
    }
  }

  public async editNode(data: IJsonGestaoComunicacoesEstadoOrdem): Promise<void> {
    const estadoResponse: IJsonGestaoComunicacoesEstado = (await this._gestaoComunicacoesEstadoService.getEstado(data.comEstadoId)).body;

    const modalInstance = this._cgModalService.showVanilla(GestaoComunicacoesEstadosEmpresaConfigDetailModalComponent, {size: 'xl'});
    const componentInstance: GestaoComunicacoesEstadosEmpresaConfigDetailModalComponent = modalInstance.componentInstance;
    componentInstance.estadoDetail = {ordem: 0, ...estadoResponse};
    componentInstance.update = true;
    componentInstance.selectedNEmpresa = this.selectedNEmpresa;

    try {
      await modalInstance.result;
    } finally {
      await this._refreshEstadosUI();
    }
  }

  public async removeNode(data: IJsonGestaoComunicacoesEstadoOrdem): Promise<void> {
    await this._cgModalService.showOkCancel('global.text.deleteRecord', 'entity.delete.message', {size: 'md'});

    // check if there are topics associated with the estado
    const responseTopicos: Array<IJsonGestaoComunicacoesTopico> = (await this._gestaoComunicacoesTopicoService.getTopicos({pesquisa: `comEstadoId=${data.comEstadoId}`})).body.list;
    if (responseTopicos.length > 0) {
      this._plAlertService.warning('gestaoComunicacoes.modal.empresaConfig.empresaConfigEstados.mensagens.errorDeleteEstado');
      return;
    }

    // no topics associated, remove the estado from the UI
    const index: number = this.estados.items.findIndex((item: IPlUITreeDragNode<IJsonGestaoComunicacoesEstadoOrdem>) => item.meta.comEstadoId === data.comEstadoId);
    if (index !== -1) {
      this.estados.items.splice(index, 1);
      this.estados = copy(this.estados);
    }
  }

  private async _refreshEstadosUI(): Promise<void> {
    return this._gestaoComunicacoesEstadoService.getEstadosOrdem(this.empresaSelectedDados.nempresa, this.userId).then((response: HttpResponse<Array<IJsonGestaoComunicacoesEstadoOrdem>>) => {
      this.estados = {items: response.body.map((item: IJsonGestaoComunicacoesEstadoOrdem) => ({meta: {_empresaSistema: this._isEmpresaSistema(item), ...item}}))};
    });
  }

  private _isEmpresaSistema(estado: IJsonGestaoComunicacoesEstadoOrdem): boolean {
    return estado.tipo === EGestaoComunicacoesEstadoTipo.SistemaInicial || estado.tipo === EGestaoComunicacoesEstadoTipo.SistemaFinal;
  }

  private _generateId(): number {
    return this._idGenerated++;
  }

  private _checkAndRemoveInheritedModelItems(): void {
    this.configUtilizadores.utilizadoresSelecionados = this.configUtilizadores.utilizadoresSelecionados.filter((item: IGestaoComunicacoesConfigUtilizador) => !item.inherited);
  }
}
