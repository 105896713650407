import dxChart, {InitializedEvent, LegendClickEvent, PointHoverChangedEvent} from 'devextreme/viz/chart';
import dxChartPie, {InitializedEvent as PieChartInitializedEvent} from 'devextreme/viz/pie_chart';
import {Component, Injector} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EPlDashboardType, IPlDashboard, PlI18nService, PlTranslateService} from 'pl-comps-angular';
import {CHART_LEGEND, CHART_TITLE, chartCombineSeriesTooltip, chartLabelWithPercent, chartToggleSeriesVisibility} from '../../../components/devexpress/charts/utilities/devexpress.chart.utilities';
import {currentYear} from '../../../../common/dates';
import {DashboardsERPService} from '../../../services/dashboardserp/dashboards.erp.service';
import {DIGITS_INFO_TWO_DECIMALS} from '../../../../common/data';
import {
  EComercialChartType,
  EComercialVendasCompras,
  IDashboardComercialClientes,
  IDashboardComercialPieClientes,
  IDashboardComercialPieVendas,
  IDashboardComercialVendasCompras
} from '../dashBoardsERP.module.interface';
import {ETipoDashboard, IDashboardsERP, IDashboardsERPValor} from '../../../services/dashboardserp/dashboards.erp.service.interface';
import {
  DEVEXPRESS_CHART_BLUE_COLOR,
  DEVEXPRESS_CHART_GREEN_COLOR,
  DEVEXPRESS_CHART_YELLOW_COLOR,
  IDevExpressChart,
  IDevExpressChartLabelCustomizeText
} from '../../../components/devexpress/charts/devexpress.charts.interface';
import {IDevExpressPieChart} from '../../../components/devexpress/charts/pie/devexpress.pie.chart.interface';
import {IJsonDashboardsValorPercentagem} from '../../../interfaces/jsonDashboards.interface';
import {ModuloComponent} from '../../../components/module/module.component';

const TOP_ONE = 1;
const TOP_FIVE = 5;
const TOP_TWELVE = 12;

@Component({
  selector: 'module-dashboards-erp',
  templateUrl: './dashBoardsERP.module.component.html'
})
export class DashboardsERPModuleComponent extends ModuloComponent {
  public readonly chartVendasComprasTotais: IDevExpressChart<'BarSeries'>;
  public readonly chartClientes: IDevExpressChart<'BarSeries'>;
  public readonly chartPieVendas: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly chartPieClientes: IDevExpressPieChart<'DoughnutSeries'>;

  public vendasTotaisDia: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public vendasTotaisMes: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public vendasTotaisAno: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public vendasTotaisAnoTop: Promise<IPlDashboard<EPlDashboardType.Table>>;
  public clientesNovosDia: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public clientesNovosMes: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public clientesNovosAno: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public clientesTopVendas: Promise<IPlDashboard<EPlDashboardType.Table>>;

  public chartVendasComprasTotaisData: Array<IDashboardComercialVendasCompras>;
  public chartClientesData: Array<IDashboardComercialClientes>;
  public chartPieVendasData: Array<IDashboardComercialPieVendas>;
  public chartPieClientesData: Array<IDashboardComercialPieClientes>;

  private readonly _year: number;
  private _chartVendasComprasInstance: dxChart;
  private _chartClientesInstance: dxChart;
  private _chartPieVendasInstance: dxChartPie;
  private _chartPieClientesInstance: dxChartPie;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plI18nService: PlI18nService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _dashboardsService: DashboardsERPService
  ) {
    super(_injector);
    this._year = currentYear();

    this.chartVendasComprasTotais = {
      commonSeriesSettings: {
        argumentField: 'month',
        type: 'bar',
        ignoreEmptyPoints: true,
        hoverMode: 'allArgumentPoints'
      },
      title: {
        ...CHART_TITLE,
        text: this._translateService.instant('dashboards.erp.comprasVendas.title')
      },
      series: [
        {valueField: 'valueVenda', color: DEVEXPRESS_CHART_BLUE_COLOR, name: this._translateService.instant('dashboards.erp.comprasVendas.legendas.vendas')},
        {valueField: 'valueCompra', color: DEVEXPRESS_CHART_GREEN_COLOR, name: this._translateService.instant('dashboards.erp.comprasVendas.legendas.compras')},
        {type: 'spline', valueField: 'valueDif', color: DEVEXPRESS_CHART_YELLOW_COLOR, name: this._translateService.instant('dashboards.erp.comprasVendas.legendas.diference'), point: {size: 6}}
      ],
      tooltip: {
        location: 'edge',
        paddingLeftRight: 15,
        paddingTopBottom: 10,
        contentTemplate: 'customTooltip',
        shared: true
      },
      legend: CHART_LEGEND,
      stickyHovering: true
    };
    this.chartClientes = {
      commonSeriesSettings: {
        argumentField: 'month',
        type: 'bar',
        ignoreEmptyPoints: true
      },
      title: {
        ...CHART_TITLE,
        text: this._translateService.instant('dashboards.erp.clientes.title')
      },
      series: [
        {valueField: 'valueClientes', color: DEVEXPRESS_CHART_BLUE_COLOR, name: this._translateService.instant('dashboards.erp.clientes.legendaClienteAno', {year: this._year})},
        {valueField: 'valueClientesAnoAnterior', color: DEVEXPRESS_CHART_GREEN_COLOR, name: this._translateService.instant('dashboards.erp.clientes.legendaClienteAno', {year: this._year - 1})}
      ],
      tooltip: {
        location: 'edge',
        paddingLeftRight: 15,
        paddingTopBottom: 10,
        contentTemplate: 'customTooltip',
        shared: true
      },
      legend: CHART_LEGEND,
      stickyHovering: true
    };
    this.chartPieVendas = {
      type: 'doughnut',
      title: {
        ...CHART_TITLE,
        text: this._translateService.instant('dashboards.erp.vendas.anoTop', {year: this._year})
      },
      series: [
        {
          argumentField: 'name',
          valueField: 'value',
          label: {
            visible: true,
            customizeText: this.customizeLabelRendimentosAndGastosFn,
            connector: {
              visible: true
            }
          }
        }
      ],
      legend: CHART_LEGEND
    };
    this.chartPieClientes = {
      type: 'doughnut',
      title: {
        ...CHART_TITLE,
        text: this._translateService.instant('dashboards.erp.clientes.anoTop', {year: this._year})
      },
      series: [
        {
          argumentField: 'name',
          valueField: 'value',
          label: {
            visible: true,
            customizeText: this.customizeLabelRendimentosAndGastosFn,
            connector: {
              visible: true
            }
          }
        }
      ],
      legend: CHART_LEGEND
    };

    this._loadData(false).then(() => {
      this.btnRefresh.visible = true;
      this.btnRefresh.click = () => this._loadData(true);
    });
  }

  public onInitializedChartVendasComprasTotais(event: InitializedEvent): void {
    this._chartVendasComprasInstance = event.component;
    this._chartVendasComprasInstance.showLoadingIndicator();
  }

  public onInitializedChartClientes(event: InitializedEvent): void {
    this._chartClientesInstance = event.component;
    this._chartClientesInstance.showLoadingIndicator();
  }

  public onInitializedChartPieVendas(event: PieChartInitializedEvent): void {
    this._chartPieVendasInstance = event.component;
    this._chartPieVendasInstance.showLoadingIndicator();
  }

  public onInitializedChartPieClientes(event: PieChartInitializedEvent): void {
    this._chartPieClientesInstance = event.component;
    this._chartPieClientesInstance.showLoadingIndicator();
  }

  public onChartPointHoverChanged(event: PointHoverChangedEvent): void {
    chartCombineSeriesTooltip(event);
  }

  public onChartLegendClick(event: LegendClickEvent): void {
    chartToggleSeriesVisibility(event);
  }

  public readonly customizeLabelRendimentosAndGastosFn = (pointInfo: IDevExpressChartLabelCustomizeText): string => chartLabelWithPercent(pointInfo, this._plI18nService, false);

  private _loadData(force: boolean): Promise<void> {
    this.vendasTotaisDia = this._refreshVendasTotaisDia(force);
    this.vendasTotaisMes = this._refreshVendasTotaisMes(force);
    this.vendasTotaisAno = this._refreshVendasTotaisAno(force);
    this.vendasTotaisAnoTop = this._refreshVendasTotaisAnoTop(force);
    this.clientesNovosDia = this._refreshClientesNovosDia(force);
    this.clientesNovosMes = this._refreshClientesNovosMes(force);
    this.clientesNovosAno = this._refreshClientesNovosAno(force);
    this.clientesTopVendas = this._refreshClientesTopVendas(force);
    return Promise.all([
      this.vendasTotaisDia,
      this.vendasTotaisMes,
      this.vendasTotaisAno,
      this.vendasTotaisAnoTop,
      this.clientesNovosDia,
      this.clientesNovosMes,
      this.clientesNovosAno,
      this.clientesTopVendas,
      this._refreshVendasComprasChart(force),
      this._refreshClientesChart(force)
    ]).then(() => undefined);
  }

  private _refreshVendasComprasChart(force: boolean): Promise<void> {
    return Promise.all([
      this._dashboardsService.vendasTotais(ETipoDashboard.MES, TOP_TWELVE, this._year, force),
      this._dashboardsService.comprasTotais(ETipoDashboard.MES, TOP_TWELVE, this._year, force)
    ])
      .then(([responseVendas, responseCompras]) => {
        if (this.destroyed) {
          return;
        }
        this._formatChartData(EComercialChartType.VendasCompras, responseVendas.body.valores.reverse(), EComercialVendasCompras.Vendas);
        this._formatChartData(EComercialChartType.VendasCompras, responseCompras.body.valores.reverse(), EComercialVendasCompras.Compras);
      })
      .finally(() => {
        if (!this.destroyed) {
          this._chartVendasComprasInstance.hideLoadingIndicator();
        }
      });
  }

  private _refreshClientesChart(force: boolean): Promise<void> {
    return Promise.all([
      this._dashboardsService.clientesNumero(ETipoDashboard.MES, TOP_TWELVE, this._year, force),
      this._dashboardsService.clientesNumero(ETipoDashboard.MES, TOP_TWELVE, this._year - 1, force)
    ])
      .then(([responseCurrentYear, responsePreviousYear]) => {
        if (this.destroyed) {
          return;
        }
        this._formatChartData(EComercialChartType.Clientes, responseCurrentYear.body.valores.reverse(), EComercialVendasCompras.AnoAtual);
        this._formatChartData(EComercialChartType.Clientes, responsePreviousYear.body.valores.reverse(), EComercialVendasCompras.AnoAnterior);
      })
      .finally(() => {
        if (!this.destroyed) {
          this._chartClientesInstance.hideLoadingIndicator();
        }
      });
  }

  private _refreshVendasTotaisDia(force: boolean): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    return this._dashboardsService.vendasTotais(ETipoDashboard.DIA, TOP_ONE, undefined, force).then((response: HttpResponse<IDashboardsERP>) => {
      const dashboards: IDashboardsERP = response.body;
      const valores: IDashboardsERPValor = dashboards.valores[0];
      return {
        type: EPlDashboardType.Tile,
        title: this._plTranslateService.translate('dashboards.erp.vendas.dia'),
        subtitle: this._plI18nService.formatDate(dashboards.dataHoraDados),
        icon: 'fa-shopping-basket',
        style: 'primary',
        data: this._plI18nService.formatCurrency(valores.valor, DIGITS_INFO_TWO_DECIMALS)
      };
    });
  }

  private _refreshVendasTotaisMes(force: boolean): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    return this._dashboardsService.vendasTotaisMes(ETipoDashboard.MES, undefined, undefined, force).then((response: HttpResponse<IDashboardsERP>) => {
      const valores: IDashboardsERPValor = response.body.valores[0];
      return {
        type: EPlDashboardType.Tile,
        title: 'dashboards.erp.vendas.mes',
        subtitle: valores.labelEx,
        icon: 'fa-shopping-basket',
        style: 'primary',
        data: this._plI18nService.formatCurrency(valores.valor, DIGITS_INFO_TWO_DECIMALS)
      };
    });
  }

  private _refreshVendasTotaisAno(force: boolean): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    return this._dashboardsService.vendasTotaisMes(ETipoDashboard.ANO, undefined, undefined, force).then((response: HttpResponse<IDashboardsERP>) => {
      const valores: IDashboardsERPValor = response.body.valores[0];
      return {
        type: EPlDashboardType.Tile,
        title: 'dashboards.erp.vendas.vendasAno',
        subtitle: valores.nome,
        icon: 'fa-shopping-basket',
        style: 'primary',
        data: this._plI18nService.formatCurrency(valores.valor, DIGITS_INFO_TWO_DECIMALS)
      };
    });
  }

  private _refreshVendasTotaisAnoTop(force: boolean): Promise<IPlDashboard<EPlDashboardType.Table>> {
    return <Promise<IPlDashboard<EPlDashboardType.Table>>>this._dashboardsService
      .vendasTotaisAnoTop(undefined, undefined, undefined, force)
      .then((response: HttpResponse<IDashboardsERP>) => {
        if (!this.destroyed) {
          this._formatChartData(EComercialChartType.PieVendas, response.body.valores);
          this._chartPieVendasInstance.option('title.text', this._translateService.instant('dashboards.erp.vendas.anoTop', {year: this._year}));
        }
        return {
          type: EPlDashboardType.Table,
          title: this._plTranslateService.translate('dashboards.erp.vendas.anoTop', {year: this._year}),
          data: this._formatTable(response.body.valores)
        };
      })
      .finally(() => {
        if (!this.destroyed) {
          this._chartPieVendasInstance.hideLoadingIndicator();
        }
      });
  }

  private _refreshClientesNovosDia(force: boolean): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    return this._dashboardsService.clientesNovos(ETipoDashboard.DIA, undefined, undefined, force).then((response: HttpResponse<IDashboardsERP>) => {
      const dashboards: IDashboardsERP = response.body;
      const valores: IDashboardsERPValor = dashboards.valores[0];
      return {
        type: EPlDashboardType.Tile,
        title: this._plTranslateService.translate('dashboards.erp.clientes.dia'),
        subtitle: this._plI18nService.formatDate(dashboards.dataHoraDados),
        icon: 'fa-users',
        style: 'success',
        data: valores.valor
      };
    });
  }

  private _refreshClientesNovosMes(force: boolean): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    return this._dashboardsService.clientesNovos(ETipoDashboard.MES, undefined, undefined, force).then((response: HttpResponse<IDashboardsERP>) => {
      const valores: IDashboardsERPValor = response.body.valores[0];
      return {
        type: EPlDashboardType.Tile,
        title: 'dashboards.erp.clientes.mes',
        subtitle: valores.labelEx,
        icon: 'fa-users',
        style: 'success',
        data: valores.valor
      };
    });
  }

  private _refreshClientesNovosAno(force: boolean): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    return this._dashboardsService.clientesNovos(ETipoDashboard.ANO, undefined, undefined, force).then((response: HttpResponse<IDashboardsERP>) => {
      const valores: IDashboardsERPValor = response.body.valores[0];
      return {
        type: EPlDashboardType.Tile,
        title: 'dashboards.erp.clientes.clientesAno',
        subtitle: valores.nome,
        icon: 'fa-users',
        style: 'success',
        data: valores.valor
      };
    });
  }

  private _refreshClientesTopVendas(force: boolean): Promise<IPlDashboard<EPlDashboardType.Table>> {
    return <Promise<IPlDashboard<EPlDashboardType.Table>>>this._dashboardsService
      .clientesTopVendas(TOP_FIVE, undefined, undefined, force)
      .then((response: HttpResponse<IDashboardsERP>) => {
        if (!this.destroyed) {
          this._formatChartData(EComercialChartType.PieClientes, response.body.valores);
          this._chartPieClientesInstance.option('title.text', this._translateService.instant('dashboards.erp.clientes.anoTop', {year: this._year}));
        }
        return {
          type: EPlDashboardType.Table,
          title: this._plTranslateService.translate('dashboards.erp.clientes.anoTop', {year: this._year}),
          data: this._formatTable(response.body.valores)
        };
      })
      .finally(() => {
        if (!this.destroyed) {
          this._chartPieClientesInstance.hideLoadingIndicator();
        }
      });
  }

  private _formatChartData(type: EComercialChartType, data: Array<IDashboardsERPValor>, typeData?: EComercialVendasCompras): void {
    if (type === EComercialChartType.VendasCompras) {
      if (typeData === EComercialVendasCompras.Vendas) {
        this.chartVendasComprasTotaisData = data.map<IDashboardComercialVendasCompras>((dataItem: IDashboardsERPValor) => {
          const numberVenda: number = dataItem.valor ?? 0;
          return {
            month: dataItem.label,
            valueVenda: numberVenda,
            valueCompra: 0,
            valueDif: numberVenda
          };
        });
      } else if (typeData === EComercialVendasCompras.Compras) {
        if (data.length === this.chartVendasComprasTotaisData.length) {
          data.forEach((value, index: number) => {
            const item: IDashboardComercialVendasCompras = this.chartVendasComprasTotaisData[index];
            item.valueCompra = value.valor;
            item.valueDif = item.valueVenda - value.valor;
          });
        }
      }
    } else if (type === EComercialChartType.Clientes) {
      if (typeData === EComercialVendasCompras.AnoAtual) {
        this.chartClientesData = data.map<IDashboardComercialClientes>((dataItem: IDashboardsERPValor) => {
          return {
            month: dataItem.label,
            valueClientes: dataItem.valor,
            valueClientesAnoAnterior: 0
          };
        });
      } else if (typeData === EComercialVendasCompras.AnoAnterior) {
        if (data.length === this.chartClientesData.length) {
          data.forEach((value, index: number) => {
            this.chartClientesData[index].valueClientesAnoAnterior = value.valor;
          });
        }
      }
    } else if (type === EComercialChartType.PieVendas) {
      this.chartPieVendasData = data.map<IDashboardComercialPieVendas>((dataItem: IDashboardsERPValor) => {
        return {
          name: dataItem.nome,
          value: dataItem.valor,
          percent: dataItem.percentagem
        };
      });
    } else if (type === EComercialChartType.PieClientes) {
      this.chartPieClientesData = data.map<IDashboardComercialPieClientes>((dataItem: IDashboardsERPValor) => {
        return {
          name: dataItem.nome,
          value: dataItem.valor,
          percent: dataItem.percentagem
        };
      });
    }
  }

  private _formatTable(items: Array<IDashboardsERPValor>): Array<object> {
    return items.map<object>((item: IDashboardsERPValor) => {
      const tableItem: object = {};
      tableItem[this._translateService.instant('dashboards.erp.table.nome')] = item.nome;
      tableItem[this._translateService.instant('dashboards.erp.table.valor')] = item.valor;
      tableItem[this._translateService.instant('dashboards.erp.table.percentagem')] = (<IJsonDashboardsValorPercentagem>item).percentagem;
      return tableItem;
    });
  }
}
