import {HttpResponse} from '@angular/common/http';
import {Component, Injector} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IPlNavWizardCallback, IPlNavWizardDefinition, IPlNavWizardEventBeforeChange, IPlNavWizardStep, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ERHCopiaAbonosDescontosStep, IRHCopiaAbonoDescontoHeader, IRHCopiaAbonosDescontosParams} from '../rhCopiaAbonosDescontos.module.interface';
import {ETipoAbonoDesconto} from '../../../../entities/abdes/abdes.entity.interface';
import {ETipoErroLoad, IJsonRelatorioLoadAux, IJsonRHCopiaAbonoDesconto} from '../jsonrhCopiaAbonosDescontos.module.interface';
import {IDevExpressDataGridEventOnSelectionChanged} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonABDES} from '../../../../entities/abdes/jsonABDES.entity.interface';
import {IJsonErpUser} from '../../../../services/account/jsonUserApi.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RHCopiaAbonosDescontosInformacaoConfirmacaoModalComponent} from '../modals/informacaoconfirmacao/rhCopiaAbonosDescontos.informacaoConfirmacao.modal.component';
import {RHCopiaAbonosDescontosService} from '../rhCopiaAbonosDescontos.module.service';

@Component({
  selector: 'rhcopiaabonosdescontos-module-component',
  templateUrl: './rhCopiaAbonosDescontos.module.component.html'
})
export class RHCopiaAbonosDescontosModuleComponent extends ModuloComponent {
  public readonly steps: typeof ERHCopiaAbonosDescontosStep;
  public readonly tipoAbonoDesconto: typeof ETipoAbonoDesconto;
  public readonly abdesOutput: string;
  public readonly dataGridDefinitionSelecao: IDevExpressDataGrid;
  public readonly dataGridDefinitionConfirmacao: IDevExpressDataGrid;
  public readonly dataGridDefinitionEstado: IDevExpressDataGrid;
  public readonly wizardDefinition: IPlNavWizardDefinition;
  public readonly wizardCallback: IPlNavWizardCallback;

  public selectedEmpresasSelecao: Array<string>;
  public selectedEmpresasConfirmacao: Array<string>;
  public header: IRHCopiaAbonoDescontoHeader;
  public errors: Array<string>;
  public copiaAbonosDescontos: Array<IJsonRHCopiaAbonoDesconto>;

  private readonly _callParms: IRHCopiaAbonosDescontosParams;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _rhCopiaAbonosDescontosService: RHCopiaAbonosDescontosService
  ) {
    super(_injector);

    this.finalize = this.finalize.bind(this);
    this.beforeStepChange = this.beforeStepChange.bind(this);

    this.steps = ERHCopiaAbonosDescontosStep;
    this.tipoAbonoDesconto = ETipoAbonoDesconto;
    this.abdesOutput = '{{codAbDesc}} - {{designacaoBreve}}';

    this.dataGridDefinitionSelecao = {
      columns: [
        {dataField: 'nEmpresa', dataType: 'string', caption: 'changefirm.fields.nEmpresa', width: 100, sortOrder: 'asc'},
        {dataField: 'nomeEmpresa', dataType: 'string', caption: 'changefirm.fields.nomeEmpresa'}
      ],
      keyExpr: 'nEmpresa',
      // Todas as empresas menos a atual,
      dataSource: this.session.erps.filter((erp: IJsonErpUser) => erp.nEmpresa !== this.session.erp.nEmpresa),
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      export: {filename: 'rhcopiaabonosdescontos.titles.selecaoDeEmpresas'},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      searchPanel: {visible: true},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always', selectAllMode: 'allPages'},
      groupPanel: {
        allowColumnDragging: false,
        visible: false
      }
    };

    this.dataGridDefinitionConfirmacao = {
      columns: [
        {dataField: 'copiaAbDes.empresa', dataType: 'string', caption: 'rhcopiaabonosdescontos.dataGrid.caption.empresa'},
        {dataField: 'copiaAbDes.nomeEmpresa', dataType: 'string', caption: 'rhcopiaabonosdescontos.dataGrid.caption.nomeEmpresa'},
        {dataField: 'copiaAbDes.codAbdesDestino', dataType: 'string', caption: 'rhcopiaabonosdescontos.dataGrid.caption.codAbdesDestino'},
        {dataField: 'copiaAbDes.nomeAbdesDestino', dataType: 'string', caption: 'rhcopiaabonosdescontos.dataGrid.caption.nomeAbdesDestino'},
        {dataField: 'copiaAbDes.temCruzamento', dataType: 'boolean', caption: 'rhcopiaabonosdescontos.dataGrid.caption.temCruzamento'},
        {
          dataField: 'copiaAbDes.mensagem',
          dataType: 'string',
          caption: 'rhcopiaabonosdescontos.dataGrid.caption.mensagem.descricao',
          cellTemplate: 'cellTemplatePermiteCopiaConfirmacao',
          alignment: 'center'
        }
      ],
      keyExpr: 'copiaAbDes.empresa',
      dataSource: [],
      columnHidingEnabled: false,
      export: {filename: 'rhcopiaabonosdescontos.titles.confirmacaoDasOperacoes'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always', selectAllMode: 'allPages'},
      remoteOperations: false
    };

    this.dataGridDefinitionEstado = {
      columns: [
        {dataField: 'copiaAbDes.empresa', dataType: 'string', caption: 'rhcopiaabonosdescontos.dataGrid.caption.empresa'},
        {dataField: 'copiaAbDes.nomeEmpresa', dataType: 'string', caption: 'rhcopiaabonosdescontos.dataGrid.caption.nomeEmpresa'},
        {dataField: 'copiaAbDes.copiouABDES', dataType: 'boolean', caption: 'rhcopiaabonosdescontos.dataGrid.caption.copiouABDES'},
        {dataField: 'copiaAbDes.copiouGrupo', dataType: 'boolean', caption: 'rhcopiaabonosdescontos.dataGrid.caption.copiouGrupo'},
        {dataField: 'copiaAbDes.copiouCruzamento', dataType: 'boolean', caption: 'rhcopiaabonosdescontos.dataGrid.caption.copiouCruzamento'},
        {dataField: 'copiaAbDes.copiouIntegracaoSalarios', dataType: 'boolean', caption: 'rhcopiaabonosdescontos.dataGrid.caption.copiouIntegracaoSalarios'},
        {
          dataField: 'copiaAbDes.mensagem',
          dataType: 'string',
          caption: 'rhcopiaabonosdescontos.dataGrid.caption.mensagem.resultado',
          cellTemplate: 'cellTemplatePermiteCopiaEstado',
          alignment: 'center'
        }
      ],
      keyExpr: 'copiaAbDes.empresa',
      dataSource: [],
      columnHidingEnabled: false,
      export: {filename: 'rhcopiaabonosdescontos.titles.estadoDaCopia'},
      remoteOperations: false
    };

    this.wizardDefinition = {
      items: []
    };
    this.wizardCallback = {};

    const {abdes, codAbdes, nomeCodAbdes}: IRHCopiaAbonosDescontosParams = <IRHCopiaAbonosDescontosParams>this._transition.params();
    this._callParms = {abdes, codAbdes, nomeCodAbdes};
    this._init();
  }

  public codDestinoChange(item: IJsonABDES): void {
    this.header.codAbdes = item?.codAbDesc;
    this.header.nomeCodAbdes = item?.designacaoBreve;
  }

  public tipoAbonoDescChange(abDesc: ETipoAbonoDesconto): void {
    this._initHeader(abDesc, '', '');
    this.header.abdes = abDesc;
    this.header.codAbdes = '';
    this.header.nomeCodAbdes = '';
  }

  public async beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    let empresas: Array<string>;

    switch (currentStep?.stepId) {
      case this.steps.Selecao: {
        empresas = this.selectedEmpresasSelecao;
        break;
      }
      case this.steps.Confirmacao: {
        empresas = this.selectedEmpresasConfirmacao;
        break;
      }
    }

    if (type === 'previous') {
      return true;
    }

    if (currentStep?.stepId === this.steps.Inicio && (nextStep?.stepId === this.steps.Selecao || (type && type === 'next'))) {
      return true;
    }

    if (currentStep?.stepId === this.steps.Selecao && (nextStep?.stepId === this.steps.Confirmacao || (type && type === 'next'))) {
      this._validateData(empresas);
      if (this.errors.length) {
        return false;
      }

      await this._rhCopiaAbonosDescontosService
        .getDadosPorEmpresa(this.header.abdes, this.header.codAbdes, this.header.copiaCruzamentos, empresas)
        .then((response: HttpResponse<Array<IJsonRHCopiaAbonoDesconto>>) => {
          this.copiaAbonosDescontos = response.body;
          for (const copia of this.copiaAbonosDescontos) {
            copia.relatorioLoad.codEmpresaOrigem = this.configurations.empresa.nEmpresa;
            copia.relatorioLoad.nomeEmpresaOrigem = this.configurations.empresa.nome;
            copia.relatorioLoad.abdesCopia = this._translateService.instant('rhcopiaabonosdescontos.fmt.relatorioLoadABDESCopia', {
              codAbdes: this.header.codAbdes,
              nomeCodAbdes: this.header.nomeCodAbdes
            });
          }
          this.dataGridDefinitionConfirmacao.dataSource = this.copiaAbonosDescontos;
        });
    } else if (currentStep.stepId === this.steps.Confirmacao && (nextStep?.stepId === this.steps.Estado || (type && type === 'next'))) {
      this._validateData(empresas);
      if (this.errors.length) {
        return false;
      }

      const relatorio: Array<IJsonRelatorioLoadAux> = [];
      for (const copia of this.copiaAbonosDescontos) {
        if (this.selectedEmpresasConfirmacao.includes(copia.copiaAbDes.empresa)) {
          relatorio.push({
            codGrupoSemelhanteDestino: copia.relatorioLoad.codGrupoSemelhanteDestino,
            geraNovoGrupoDestino: copia.relatorioLoad.geraNovoGrupoDestino
          });
        }
      }

      await this._rhCopiaAbonosDescontosService
        .copiaDados(this.header.abdes, this.header.codAbdes, this.header.copiaCruzamentos, this.header.copiaIntSalarios, empresas, relatorio)
        .then((response: HttpResponse<Array<IJsonRHCopiaAbonoDesconto>>) => {
          this.copiaAbonosDescontos = response.body;
          this.dataGridDefinitionEstado.dataSource = this.copiaAbonosDescontos;
        });
    }

    return true;
  }

  public onDataGridConfirmacaoSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IJsonRHCopiaAbonoDesconto>): void {
    for (const row of event.selectedRowsData) {
      switch (row.copiaAbDes.permiteCopia) {
        case ETipoErroLoad.Erro: {
          event.component.deselectRows([row.copiaAbDes.empresa]);
          this._plAlertService.error('rhcopiaabonosdescontos.errors.selecionarLinhaComErro');
          break;
        }
        case ETipoErroLoad.Warning: {
          // Lança a Modal e dependendo do que faz lá, seleciona ou não
          this.informacaoConfirmacaoModal(row.copiaAbDes.permiteCopia, row.copiaAbDes.empresa).then((result: boolean): void => {
            if (!result) {
              event.component.deselectRows([row.copiaAbDes.empresa]);
              this._plAlertService.error('rhcopiaabonosdescontos.errors.selecionarLinhaComAviso');
            }
          });
          break;
        }
        default:
          break;
      }
    }
  }

  public async informacaoConfirmacaoModal(tipoErroLoad: ETipoErroLoad, empresa: string): Promise<boolean> {
    const copia = this.copiaAbonosDescontos.find((item: IJsonRHCopiaAbonoDesconto) => item.copiaAbDes.empresa === empresa);
    if (!copia) {
      return false;
    }
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(RHCopiaAbonosDescontosInformacaoConfirmacaoModalComponent);
    const componentInstance: RHCopiaAbonosDescontosInformacaoConfirmacaoModalComponent = modalInstance.componentInstance;
    componentInstance.tipoErroLoad = tipoErroLoad;
    componentInstance.relatorioLoad = copia.relatorioLoad;
    try {
      await modalInstance.result;
      return true;
    } catch (e: unknown) {
      return false;
    }
  }

  public iconByTipoErro(tipoErroLoad: ETipoErroLoad): string {
    switch (tipoErroLoad) {
      case ETipoErroLoad.Erro:
        return 'fa fa-times fa-fw';
      case ETipoErroLoad.Ok:
        return 'fa fa-check fa-fw';
      case ETipoErroLoad.Warning:
        return 'fa fa-exclamation-triangle fa-fw';
      case ETipoErroLoad.Info:
        return 'fa fa-info-circle fa-fw';
      default:
        return '';
    }
  }

  public finalize(): Promise<void> {
    this._init();
    this._invalidateSteps();
    return this.wizardCallback.setStep(ERHCopiaAbonosDescontosStep.Inicio, true).then(() => {
      const rhCopiaAbonosDescontosParams: Promise<IRHCopiaAbonosDescontosParams> = Promise.resolve({
        abdes: this.header.abdes,
        codAbdes: this.header.codAbdes,
        nomeCodAbdes: this.header.nomeCodAbdes
      });
      this._subjectOnSave.next(rhCopiaAbonosDescontosParams);
    });
  }

  private _initHeader(abdes: ETipoAbonoDesconto, codAbdes: string, nomeCodAbdes: string): void {
    this.header = {
      abdes: abdes,
      codAbdes: codAbdes,
      nomeCodAbdes: nomeCodAbdes,
      copiaCruzamentos: false,
      copiaIntSalarios: false
    };
  }

  private _init(): void {
    this.selectedEmpresasSelecao = [];
    this.selectedEmpresasConfirmacao = [];
    this._initHeader(this._callParms.abdes ?? ETipoAbonoDesconto.Abono, this._callParms.codAbdes ?? '', this._callParms.nomeCodAbdes ?? '');
    this.dataGridDefinitionConfirmacao.dataSource = [];
    this.dataGridDefinitionEstado.dataSource = [];
  }

  private _validateData(empresas: Array<string>): void {
    this.errors = [];
    if (!this.header.codAbdes) {
      this.errors.push('rhcopiaabonosdescontos.errors.semCodAbDesc');
    }
    if (!empresas.length) {
      this.errors.push('rhcopiaabonosdescontos.errors.semEmpresas');
    }
  }

  private _invalidateSteps(): void {
    this.wizardDefinition.items.forEach((step: IPlNavWizardStep) => {
      step.visited = false;
      step.setIncomplete();
    });
  }
}
