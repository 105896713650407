export interface IErrosControloIVAEntreEmpresas {
  nEmpresa: string;
  nomeEmpresa: string;
  codControloIVA: string;
  nomeControloIVA: string;
  erros: string;
  copiar: boolean;
  haErrosImpeditivos: boolean;
}

export interface IControloIVAEntreEmpresas {
  controloIVAEntreEmpresasList: Array<IErrosControloIVAEntreEmpresas>;
}

export interface IControloIVAEntreEmpresasStateParams {
  codControloIVA: string;
}

export const MODULE_NAME_CONTROLO_IVA_ENTRE_EMPRESAS = 'controloivaentreempresas';
