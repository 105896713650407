import type {TemplateRef} from '@angular/core';
import type {FormGroupDirective} from '@angular/forms';
import type {IPlCompsResponsiveTheme} from '../common/interface';
import type {IPlTooltipConfig} from '../tooltip/tooltip.interface';
import type {TValueOrPromise} from '../common/utilities/utilities.interface';

export type TPlNavWizardOrientation = 'vertical' | 'horizontal';

export type TPlNavWizardEventChangeType = 'previous' | 'next' | 'set';

export type TPlNavWizardBeforeStepChangeFn = (event: IPlNavWizardEventBeforeChange) => void | boolean | Promise<void | boolean>;

export type TPlNavWizardStepValidateFn = (event: IPlNavWizardEventValidator) => void | boolean | Promise<void | boolean>;

export type TPlNavWizardOnFinalizeFn = () => void | Promise<void>;

export interface IPlNavWizardOrientation {
  progressLine?: string;
  step?: string;
  stepIcon?: string;
}

export interface IPlNavWizardInstance {
  definition: IPlNavWizardDefinition;
  instanceId: string;
  beforeStepChange: TPlNavWizardBeforeStepChangeFn;
  onFinalize: TPlNavWizardOnFinalizeFn;
  destroyOnHide: boolean;
  hideFooter: boolean;
  model: IPlNavWizardStep;
  properties: IPlNavWizardOptions;
  callback: IPlNavWizardCallback;
  options: IPlNavWizardOptions;
  separatorSize: number;
  itemSize: number;
  activeId: any;
  selected: IPlNavWizardStep;

  setStep(stepToSet: unknown | IPlNavWizardStep, force?: boolean, evaluatedBeforeStepChange?: boolean, preventEventStepChange?: boolean): Promise<void>;

  previousStep(force?: boolean): Promise<void>;

  nextStep(force?: boolean): Promise<void>;

  addStep(step: IPlNavWizardStep): void;

  getStep(index: number): IPlNavWizardStep;

  animateCurrent(): void;
}

export interface IPlNavWizardStep extends IPlNavWizardStepOptions {
  stepId?: any;
  actions?: Array<IPlNavWizardStepAction>;
  options?: IPlNavWizardStepOptions;
  responsiveTheme?: IPlCompsResponsiveTheme;
  visited?: boolean;
  formGroup?: FormGroupDirective;
  templateCaption?: IPlNavWizardStepCaption;
  templateContent?: IPlNavWizardStepContent;

  setComplete?(): void;

  setIncomplete?(): void;

  setCompletion?(value: boolean): void;

  setValid?(): void;

  setInvalid?(): void;

  setValidation?(value: boolean): void;

  setOrientation?(orientation: TPlNavWizardOrientation): void;

  updateComponent?(properties: any): void;

  validator?(event: IPlNavWizardEventValidator): void | boolean | Promise<void | boolean>;
}

export interface IPlNavWizardDefinition {
  caption?: string;
  force?: boolean;
  items?: Array<IPlNavWizardStep>;
  type?: TPlNavWizardOrientation;
  vertical?: boolean;
}

export interface IPlNavWizardOptions {
  disableFinalize?: boolean;
  disableNavigation?: boolean;
  disableNext?: boolean;
  disableNextStep?: boolean;
  disablePrevious?: boolean;
  disablePreviousStep?: boolean;
  hideFinalize?: boolean;
  hideFooter?: boolean;
  hideNext?: boolean;
  hidePrevious?: boolean;
  vertical?: boolean;
}

export interface IPlNavWizardStepOptions {
  caption?: string;
  captionBtnFinalize?: string;
  captionBtnGoBack?: string;
  captionBtnGoForward?: string;
  complete?: boolean;
  disabled?: boolean;
  footerOffset?: string;
  hideFinalize?: boolean;
  hideFooter?: boolean;
  hideNext?: boolean;
  hidePrevious?: boolean;
  icon?: string;
  required?: boolean;
  valid?: boolean;
  validator?: TPlNavWizardStepValidateFn;
  visible?: boolean;
}

export interface IPlNavWizardCallback {
  previousStep?(force?: boolean): Promise<void>;

  nextStep?(force?: boolean): Promise<void>;

  setStep?(step: any | IPlNavWizardStep, force?: boolean, evaluatedBeforeStepChange?: boolean): Promise<void>;
}

export interface IPlNavWizardStepAction {
  caption?: string;
  class?: string | {[klass: string]: boolean} | Array<string>;
  visible?: boolean;
  disabled?: boolean;
  icon?: string;
  order?: number;
  tooltip?: IPlTooltipConfig;

  click?(action: IPlNavWizardStepAction): TValueOrPromise<void>;
}

export interface IPlNavWizardStepCaption {
  templateRef: TemplateRef<IPlNavWizardStepTemplateContext>;
}

export interface IPlNavWizardStepContent {
  templateRef: TemplateRef<IPlNavWizardStepTemplateContext>;
}

export interface IPlNavWizardStepTemplateContext {
  step: IPlNavWizardStep;
  definition: IPlNavWizardDefinition;
}

export interface IPlNavWizardEventStep {
  currentStep: IPlNavWizardStep;
  previousStep: IPlNavWizardStep;
}

export interface IPlNavWizardEventValidator extends IPlNavWizardEventStep {
  nextStep: IPlNavWizardStep;
}

export interface IPlNavWizardEventBeforeChange extends IPlNavWizardEventValidator {
  type: TPlNavWizardEventChangeType;
}

export const SELECTOR_NAV_WIZARD_COMPONENT = 'pl-nav-wizard';

export const DEFAULT_NAV_WIZARD_DEFINITION: IPlNavWizardDefinition = Object.freeze<IPlNavWizardDefinition>({
  items: [],
  type: 'horizontal',
  vertical: false
});

export const DEFAULT_NAV_WIZARD_ORIENTATION: IPlNavWizardOrientation = Object.freeze<IPlNavWizardOrientation>({
  progressLine: '.pl-nav-wizard-progress-line',
  step: '.pl-nav-wizard-step',
  stepIcon: '.pl-nav-wizard-step-icon'
});
