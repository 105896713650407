<pl-form class="recibos-modal-distribuir">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'recibos.modalDistribuir.tile'"></h4>
  </div>

  <div class="modal-body">
    <pl-group>
      <div>
        <div class="row radio-group">
          <div class="col-7">
            <div class="d-flex" style="gap: 10px; align-items: center">
              <input
                id="tipoDistribuicaoAutomatico"
                type="radio"
                name="tipoDistribuicao"
                [checked]="model.tipoDistribuicao === tipoDistribuicao.Automatico"
                (change)="tipoDistribuicaoChanged(tipoDistribuicao.Automatico)" />
              <label class="label-radio-item" for="tipoDistribuicaoAutomatico">
                <span class="me-1" [translate]="'recibos.modalDistribuir.automatico'"></span>
                <pl-tooltip class="tooltip-info-label" [config]="{text: 'recibos.modalDistribuir.tooltip.automatico', placement: 'right'}">
                  <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                </pl-tooltip>
              </label>
            </div>
          </div>
          <div class="col-6">
            <div class="d-flex" style="gap: 10px">
              <input
                id="tipoDistribuicaoManual"
                type="radio"
                name="tipoDistribuicao"
                [checked]="model.tipoDistribuicao === tipoDistribuicao.Manual"
                (change)="tipoDistribuicaoChanged(tipoDistribuicao.Manual)" />
              <label class="label-radio-item" for="tipoDistribuicaoManual">
                <span class="me-1" [translate]="'recibos.modalDistribuir.manual'"></span>
                <pl-tooltip class="tooltip-info-label" [config]="{text: 'recibos.modalDistribuir.tooltip.manual', placement: 'right'}">
                  <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                </pl-tooltip>
              </label>
            </div>
          </div>
        </div>
      </div>
    </pl-group>
    <pl-group>
      <label [translate]="'recibos.text.amountToReceive'"></label>
      <edit>
        <pl-edit type="number" attrName="valorRecibo" [(model)]="model.valor" plAutoFocus></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-primary action-save" (click)="close()" data-focus><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></button>
    <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
  </div>
</pl-form>
