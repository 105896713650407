<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h5 class="modal-title" [translate]="'empresas.storewelcome.title'"></h5>
</div>

<div class="modal-body">
  <div class="h6" [innerHTML]="'empresas.storewelcome.message1' | translate"></div>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-close" (evtClicked)="close()" plAutoFocus><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>
</div>
