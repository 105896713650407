import {ContabilidadeDigitalClientConnectModuleComponent} from './components/contabDigital.clientConnect.module.component';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_CONTABILIDADE_DIGITAL_CLIENT_CONNECT} from './contabDigital.clientConnect.module.interface';
import {ROLE} from '../../../services/role.const';

export const MODULE_CONTABILIDADE_DIGITAL_CLIENT_CONNECT: IModuleDefinition = {
  name: MODULE_NAME_CONTABILIDADE_DIGITAL_CLIENT_CONNECT,
  state: {
    url: `/${MODULE_NAME_CONTABILIDADE_DIGITAL_CLIENT_CONNECT}`,
    component: ContabilidadeDigitalClientConnectModuleComponent,
    data: {
      roles: [ROLE.CLIENTCONNECT, ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.clientconnect',
      icon: 'fa-connectdevelop'
    }
  }
};
