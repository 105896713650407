import {lastValueFrom} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {PlPdfOptionsService} from './pdf.component.options.service';

export function plPdfFetchServiceFactory(httpClient: HttpClient, plPdfOptionsService: PlPdfOptionsService): PlPdfFetchService {
  return new PlPdfGenericFetchService(httpClient, plPdfOptionsService);
}

@Injectable({
  providedIn: 'root',
  useFactory: plPdfFetchServiceFactory,
  deps: [HttpClient, PlPdfOptionsService]
})
export abstract class PlPdfFetchService {
  public abstract fetchDocument(url: string, withCredentials?: boolean): Promise<HttpResponse<Uint8Array>>;
}

@Injectable()
export class PlPdfGenericFetchService extends PlPdfFetchService {
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _plPdfOptionsService: PlPdfOptionsService
  ) {
    super();
  }

  public fetchDocument(url: string, withCredentials: boolean = this._plPdfOptionsService.withCredentials): Promise<HttpResponse<Uint8Array>> {
    return lastValueFrom(this._httpClient.get(url, {withCredentials: withCredentials, observe: 'response', responseType: 'arraybuffer'})).then((response: HttpResponse<ArrayBuffer>) => {
      return response.clone({body: new Uint8Array(response.body)});
    });
  }
}
