import {ENTITY_NAME_ESTABELECIMENTO} from './estabelecimentos.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonEstabelecimento} from './jsonEstabelecimentos.entity.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_DISTRITOS} from '../distritos/distritos.entity.interface';
import {ENTITY_NAME_FREGUESIAS} from '../freguesias/freguesias.entity.interface';
import {ENTITY_NAME_LOCAL} from '../local/local.entity.interface';
import {ENTITY_NAME_QPCAE} from '../qpcae/qpcae.entity.interface';
import {ENTITY_NAME_QPDCF} from '../qpdcf/qpdcf.entity.interface';

export const ENTITY_ESTABELECIMENTO: IEntityDefinition<IJsonEstabelecimento> = {
  name: ENTITY_NAME_ESTABELECIMENTO,
  roles: [ROLE.ATIVOS, ROLE.RH],
  searchPlaceholder: 'estabelecimento.pesquisa',
  pageTitle: 'global.menu.estabelecimentos',
  metadata: {
    keyName: 'nEstabElec',
    fields: [
      {
        name: 'nEstabElec',
        type: 'cgsmallint',
        caption: 'estabelecimento.fields.nEstabElec',
        placeholder: 'estabelecimento.fields.nEstabElecPlaceholder',
        validators: {required: true}
      },
      {name: 'nome', caption: 'estabelecimento.fields.nome', placeholder: 'estabelecimento.fields.nomePlaceholder', validators: {required: true, maxlength: 60}},
      {
        name: 'designaBreve',
        caption: 'estabelecimento.fields.designaBreve',
        placeholder: 'estabelecimento.fields.designaBrevePlaceholder',
        validators: {required: true, maxlength: 20}
      },
      {name: 'sede', type: 'boolean', caption: 'estabelecimento.fields.sede'},
      {name: 'morada', caption: 'estabelecimento.fields.morada', placeholder: 'estabelecimento.fields.moradaPlaceholder', validators: {required: true, maxlength: 40}},
      {name: 'localidade', visible: false},
      {
        name: 'nomeLocalidade',
        caption: 'estabelecimento.fields.localidade',
        placeholder: 'estabelecimento.fields.localidadePlaceholder',
        validators: {required: true, maxlength: 4},
        entity: {name: ENTITY_NAME_LOCAL, keyTarget: 'localidade', outputTarget: 'nomeLocalidade'}
      },
      {name: 'nPostal', visible: false},
      {
        name: 'nomeCodPostal',
        caption: 'estabelecimento.fields.nPostal',
        placeholder: 'estabelecimento.fields.nPostalPlaceholder',
        validators: {required: true, maxlength: 20},
        entity: {name: 'codpostais', keyTarget: 'nPostal', outputTarget: 'nomeCodPostal'}
      },
      {name: 'distrito', visible: false},
      {
        name: 'nomeDistrito',
        caption: 'estabelecimento.fields.distrito',
        validators: {required: true},
        entity: {name: ENTITY_NAME_DISTRITOS, keyTarget: 'distrito', outputTarget: 'nomeDistrito'}
      },
      {name: 'concelho', visible: false},
      {
        name: 'nomeConcelho',
        caption: 'estabelecimento.fields.concelho',
        validators: {required: true},
        entity: {name: 'concelhos', keyTarget: 'concelho', outputTarget: 'nomeConcelho'}
      },
      {name: 'freguesia', visible: false},
      {
        name: 'nomeFreguesia',
        caption: 'estabelecimento.fields.freguesia',
        placeholder: 'estabelecimento.fields.freguesiaPlaceholder',
        validators: {required: true, maxlength: 4},
        entity: {name: ENTITY_NAME_FREGUESIAS, keyTarget: 'freguesia', outputTarget: 'nomeFreguesia'}
      },
      {name: 'caeaCTPrinc', visible: false},
      {
        name: 'nomeCaeaCTPrinc',
        caption: 'estabelecimento.fields.caeaCTPrincPlaceholder',
        placeholder: 'estabelecimento.fields.caeaCTPrincPlaceholder',
        validators: {required: false, maxlength: 10},
        entity: {name: ENTITY_NAME_QPCAE, keyTarget: 'caeaCtPrinc', outputTarget: 'nomeCaeaCTPrinc'}
      },
      {name: 'codDdCcFf', visible: false},
      {
        name: 'nomeCodDdCcFf',
        caption: 'estabelecimento.fields.codDdCcFf',
        placeholder: 'estabelecimento.fields.codDdCcFfPlaceholder',
        validators: {required: true, maxlength: 6},
        entity: {name: ENTITY_NAME_QPDCF, keyTarget: 'codDdCcFf', outputTarget: 'nomeCodDdCcFf'}
      },
      {
        name: 'nEstabQPessoal',
        caption: 'estabelecimento.fields.nEstabQPessoal',
        placeholder: 'estabelecimento.fields.nEstabQPessoalPlaceholder',
        validators: {required: true, maxlength: 10}
      },
      {
        name: 'nEstabSegSocial',
        caption: 'estabelecimento.fields.nEstabSegSocial',
        placeholder: 'estabelecimento.fields.nEstabSegSocialPlaceholder',
        validators: {required: true, maxlength: 4}
      },
      {
        name: 'activPrincip',
        caption: 'estabelecimento.fields.activPrincip',
        placeholder: 'estabelecimento.fields.activPrincipPlaceholder',
        validators: {required: true, maxlength: 30}
      },
      {
        name: 'nContrib',
        caption: 'estabelecimento.fields.nContRib',
        placeholder: 'estabelecimento.fields.nContRibPlaceholder',
        validators: {required: true, maxlength: 25}
      },
      {name: 'nCaixaPrev', caption: 'estabelecimento.fields.nCaixaPrev', visible: false},
      {
        name: 'nomeNCaixaPrev',
        caption: 'estabelecimento.fields.nCaixaPrev',
        entity: {
          name: 'crss',
          keyTarget: 'nCaixaPrev',
          outputTarget: 'nomeNCaixaPrev'
        },
        validators: {required: true}
      },
      {name: 'codCct', type: 'cgsmallint', caption: 'estabelecimento.fields.codCCt', validators: {required: true}},
      {
        name: 'telefone',
        caption: 'estabelecimento.fields.telefone',
        placeholder: 'estabelecimento.fields.telefonePlaceholder',
        validators: {required: true, maxlength: 14}
      },
      {name: 'calenCABId', visible: false},
      {
        name: 'nomeCalenCABId',
        caption: 'estabelecimento.fields.calenCABId',
        placeholder: 'estabelecimento.fields.calenCABId',
        validators: {required: true, max: 4},
        entity: {name: 'calendarios', keyTarget: 'calenCABId', outputTarget: 'nomeCalenCABId'}
      }
    ],
    order: 'nome',
    searchFields: 'nEstabElec,nome,nomeDistrito,nomeConcelho,nomeLocalidade',
    listFields: 'nEstabElec,nome,nomeDistrito,nomeConcelho,nomeLocalidade',
    filterFields: 'nEstabElec,nome,nomeDistrito,nomeConcelho,nomeLocalidade'
  },
  autocomplete: {
    rowTemplate: '{{nEstabElec}} - {{nome}}',
    output: 'nome',
    searchFields: 'nEstabElec,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  }
};
