import {PlTranslateService} from 'pl-comps-angular';
import {EMascaraAnaliticaTipo} from './jsonMascaraAnalitica.entity.interface';
import {ENTITY_NAME_MASCARAS_ANALITICA, IMascaraAnalitica} from './mascaraAnalitica.entity.interface';
import {IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {MascarasAnaliticaEditComponent} from './components/edit/mascarasAnalitica.entity.edit.component';
import {ROLE} from '../../services/role.const';
import {TInjectorResolvable} from '../../../common/injectors/common';
import {ETipoContaContabilidade} from '../../datasources/tipospoc/tiposPoc.datasource.interface';
import {EPocsClasse} from '../pocs/pocs.entity.interface';

const tipoMascara = [
  {value: 0, label: 'mascarasanalitica.tipos.invoicing'},
  {value: 1, label: 'mascarasanalitica.tipos.salary'},
  {value: 2, label: 'mascarasanalitica.tipos.fixed'}
];

export const ENTITY_MASCARAS_ANALITICA: TInjectorResolvable = [
  PlTranslateService,
  (plTranslateService: PlTranslateService): IEntityDefinition<IMascaraAnalitica> => {
    const definition: IEntityDefinition<IMascaraAnalitica> = {
      name: ENTITY_NAME_MASCARAS_ANALITICA,
      roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.ATIVOS, ROLE.RH],
      searchPlaceholder: 'mascarasanalitica.pesquisa',
      metadata: {
        keyName: 'manalID',
        fields: [
          {
            name: 'manalID',
            type: 'integer',
            width: '120px',
            visible: false,
            properties: {validators: {min: 1}}
          },
          {
            name: 'mascara',
            caption: 'mascarasanalitica.fields.mascara',
            placeholder: 'mascarasanalitica.placeholders.mascara',
            properties: {disabled: true, validators: {required: true, maxlength: 100}}
          },
          {
            name: 'descricao',
            caption: 'mascarasanalitica.fields.descricao',
            placeholder: 'mascarasanalitica.placeholders.descricao',
            validators: {required: true, maxlength: 100}
          },
          {
            name: 'contaContrapartida',
            visible: false
          },
          {
            name: 'nomeContaContrapartida',
            caption: 'mascarasanalitica.fields.contaContrapartida',
            placeholder: 'artigos.fields.ncontapoc',
            entity: {
              name: 'pocs',
              keyTarget: 'contaContrapartida',
              outputTarget: 'nomeContaContrapartida',
              output: '{{nConta}} - {{nome}}',
              filter: `tipo=${ETipoContaContabilidade.Movimento}&nConta=%${EPocsClasse.ANALITICA}%`,

              fieldsMap: {nConta: 'contaContrapartida', nome: 'nomeContaContrapartida'}
            }
          },
          {
            name: 'nCaracteresGeral',
            type: 'integer',
            caption: 'mascarasanalitica.fields.nCaracteresGeral',
            placeholder: 'mascarasanalitica.placeholders.nCaracteresGeral',
            properties: {editClass: 'col-sm-3 col-md-2 col-lg-1', validators: {required: true, min: 1, max: 60}}
          },
          {
            name: 'isDefault',
            type: 'boolean',
            caption: 'mascarasanalitica.fields.isDefault',
            placeholder: 'mascarasanalitica.placeholders.isDefault',
            validators: {required: true}
          },
          {
            name: 'subTotaisDaGeral',
            type: 'boolean',
            caption: 'mascarasanalitica.fields.subTotaisDaGeral',
            placeholder: 'mascarasanalitica.placeholders.subTotaisDaGeral',
            validators: {required: true}
          },
          {
            name: 'tipo',
            caption: 'mascarasanalitica.fields.tipo',
            type: 'radiogroup',
            value: EMascaraAnaliticaTipo.FATURACAO,
            placeholder: 'mascarasanalitica.placeholders.tipo',
            validators: {required: true},
            properties: {
              groupItems: tipoMascara,
              devExpress: {
                dataGrid: {
                  customizeText: (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => {
                    if (cellInfo.target === 'row') {
                      const tipo = tipoMascara[<number>cellInfo.value];
                      if (tipo) {
                        return plTranslateService.translate(tipo.label);
                      }
                    }
                    return cellInfo.valueText;
                  }
                }
              }
            }
          },
          {
            name: 'indicesSubTotais',
            caption: 'mascarasanalitica.fields.indicesSubTotais',
            placeholder: 'mascarasanalitica.placeholders.indicesSubTotais',
            validators: {required: true}
          }
        ],
        order: 'descricao',
        listFields: 'mascara,descricao,tipo,isDefault',
        detailFields: 'mascara,descricao,nomeContaContrapartida,tipo,nCaracteresGeral,isDefault,subTotaisDaGeral',
        newFields: 'mascara,descricao,nomeContaContrapartida,tipo,nCaracteresGeral,isDefault,subTotaisDaGeral',
        searchFields: 'mascara,descricao,tipo,isDefault'
      },
      autocomplete: {
        rowTemplate: '{{mascara}} - {{descricao}}',
        output: 'descricao',
        searchFields: 'mascara,descricao'
      },
      actions: {
        new: true,
        detail: true,
        edit: true,
        duplicate: false,
        delete: true,
        search: true,
        filter: true
      },
      list: {
        state: {
          data: {
            roles: [ROLE.ERP, ROLE.CONTABILIDADE],
            pageTitle: 'global.menu.mascarasanalitica'
          }
        }
      },
      detail: {
        state: {
          component: MascarasAnaliticaEditComponent,
          data: {
            roles: [ROLE.ERP, ROLE.CONTABILIDADE],
            pageTitle: 'global.menu.mascarasanalitica',
            tipoMascara: tipoMascara
          }
        }
      }
    };
    return definition;
  }
];
