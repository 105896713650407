import {HttpResponse} from '@angular/common/http';
import {IJsonConfigRHDadosFicha} from '../jsonRHConfiguracoes.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_CONFIGURACOES_DADOS_CONFIG_FICHA} from '../rhConfiguracoes.module.interface';
import {RHConfiguracoesService} from '../rhConfiguracoes.module.service';
import {RHDadosConfigFichaModuleComponent} from './components/rhDadosConfigFicha.module.component';
import {ROLE} from '../../../services/role.const';

export const MODULE_RH_CONFIGURACOES_DADOS_CONFIG_FICHA: IModuleDefinition = {
  name: MODULE_NAME_RH_CONFIGURACOES_DADOS_CONFIG_FICHA,
  state: {
    url: `/${MODULE_NAME_RH_CONFIGURACOES_DADOS_CONFIG_FICHA}`,
    component: RHDadosConfigFichaModuleComponent,
    data: {
      roles: [ROLE.RH],
      sidebarTitle: 'portals.sidebar.modules.rhdadosconfigficha'
    },
    resolve: [
      {
        provide: 'dadosFicha',
        deps: [RHConfiguracoesService],
        useFactory: (rhConfiguracoesService: RHConfiguracoesService): Promise<IJsonConfigRHDadosFicha> => {
          return rhConfiguracoesService.getDadosFicha().then((response: HttpResponse<IJsonConfigRHDadosFicha>) => response.body);
        }
      }
    ]
  }
};
