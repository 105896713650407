{
	"abdes": {
		"title_detail": "Asignación/descuento {{id}}",
		"title_new": "Nueva asignación/descuento",
		"title_plural": "Bonificaciones/Descuentos",
		"pesquisa": "buscar",
		"saved": "Asignación/Descuento {{id}}, guardado exitosamente.",
		"error": "Asignación/Descuento {{id}}, no se puede guardar.",
		"deleted": "Asignación/Descuento {{id}}, eliminado exitosamente.",
		"fields": {
			"abonoDesc": "Asignación / Descuento",
			"codAbDesc": "Código",
			"designacaoBreve": "Nombre",
			"designacaoCompleta": "Descripción",
			"valorFixo": "Valor unitario",
			"valorLimIncidencia": "Limitar la incidencia",
			"tipoArredondamento": "redondeo",
			"procNatal": "Tasa de retención",
			"taxaRetencao": "Tasa de retención",
			"procFerias": "",
			"mapaSeguro": "mapa seguro",
			"mapaSindic": "mapa de la unión",
			"colQuadroP": "",
			"colMapaCX": "",
			"nCodABDESC": "",
			"entiSuporte": "Entidad",
			"contaMovDeb": "Asignaciones y descuentos para empleados - Cuenta de débito",
			"contaMovCred": "Asignaciones y descuentos para empleados - Cuenta de crédito",
			"calcCustHora": "Contribuye al cálculo del coste precio/hora.",
			"coefHExtra": "% de aumento en horas extras",
			"horaDiaValor": "",
			"tipoHoraExtr": "",
			"diaHoraExtra": "",
			"grupoAbono": "Grupo",
			"grupoDesconto": "Grupo",
			"naoImpDecIRS114": "No imprime declaración",
			"tipoValorUnitRecolha": "Tipo de colección",
			"percLimValorHExtra": "% Valor límite H. informes extra.",
			"codCGASit": "Código de estado",
			"contaEncDEB": "Cargos del empleador/empresa - Cuenta de débito",
			"contaEncCRE": "Cargos del empleador/empresa - Cuenta de crédito",
			"percInciSegSocial": "(%) Incidencia",
			"tipoRendimento": "Tipo de rendimiento.",
			"codSSCol": "código remoto.",
			"codRubrOrcAbon": "",
			"codRubrOrcEnc": "",
			"abonoDescDesativado": "Inactivo",
			"tipoDescontoProporcionalABDES": "Tipo de descuento proporcional",
			"nHorasDescontoProporcional": "N° Horas",
			"sPReducaoRemunatoria": "Hay una reducción salarial",
			"nHorasDiaProvDesc": "Número de horas por día por pérdida del subsidio de alimentación",
			"perctDescSeguro": "Porcentaje pagado por el seguro",
			"usaSPReducaoRemuneratoria": "Trabajadores de servicios públicos - Tienen reducción salarial",
			"tem": "Él tiene",
			"irs": "IRS",
			"segsocial": "Seguridad social",
			"perfilSistema": "Perfil del sistema"
		},
		"virtualFields": {
			"imprimeDec119": "Imprimir en diciembre.",
			"enviaAT": "Enviar a AT"
		},
		"tabIntegracaoCruzamento": "Integración / Cruces",
		"tabOutrosDados": "Otros datos",
		"naotem": "no tiene",
		"abono": "Prestación",
		"abonos": "Asignaciones",
		"desconto": "Descuento",
		"descontos": "Descuentos",
		"messages": {
			"abdescSystemLocked": "Perfil del sistema.",
			"abdescSystemUnLocked": "Perfil editable.",
			"perfilSistema": "Perfil del sistema."
		},
		"unlockPrompt": {
			"title": "Perfil del sistema",
			"message": "<p>¿Quieres desbloquear todos los campos administrados por el sistema, según el perfil ''{{perfilSistemaAbdesNome}}'', para cambiar valores?</p> <p>Al desbloquear la configuración, el sistema dejar de actualizar automáticamente estos campos, si surgen cambios en el perfil ''{{perfilSistemaAbdesNome}}'' (ejemplo: cambios en la legislación).</p> <p>Se recomienda utilizarlos siempre."
		}
	},
	"descontos": {
		"title_detail": "Descuento {{id}}",
		"title_new": "Nuevo descuento",
		"title_plural": "Descuentos",
		"pesquisa": "buscar",
		"saved": "Descuento {{id}}, guardado exitosamente.",
		"error": "Descuento {{id}}, no se puede guardar.",
		"deleted": "Descuento {{id}}, eliminado correctamente.",
		"actions": "Generar nuevo descuento"
	},
	"abonos": {
		"title_detail": "Asignación {{id}}",
		"title_new": "Nueva asignación",
		"title_plural": "Asignaciones",
		"pesquisa": "buscar",
		"saved": "Asignación {{id}}, guardada exitosamente.",
		"error": "Asignación {{id}}, no se puede guardar.",
		"deleted": "Asignación {{id}}, eliminada correctamente.",
		"actions": "Generar nueva asignación"
	},
	"about": {
		"title": "Acerca de CentralGest Cloud",
		"licenca": {
			"licId": "Licencia No.",
			"nome": "Nombre",
			"descricao": "Licenciado para",
			"nUtilizadores": "Número de usuarios",
			"portal": "Portal {{nombre}}"
		},
		"erp": {
			"versao": "Versión ERP",
			"dataVersao": "Fecha de versión",
			"emManutencao": "En mantenimiento"
		},
		"user": {
			"roles": "Acceso de usuario"
		}
	},
	"account": {
		"messages": {
			"error": {
				"invalidRecaptcha": "Marque la casilla de verificación para confirmar que no es una máquina.",
				"invalidSecurePassword": "La contraseña no es válida, debe contener al menos 8 caracteres, un número, letras minúsculas y mayúsculas.",
				"notAuthorized": "No tiene acceso a la plataforma."
			}
		}
	},
	"login": {
		"title": "Autenticar",
		"header": "Inicie sesión para comenzar a utilizar CentralGest Cloud.",
		"form": {
			"password": "Contraseña",
			"password.placeholder": "Contraseña",
			"button": "Autenticar",
			"resetpassword": "Olvidé mi contraseña"
		},
		"messages": {
			"error": {
				"authentication": "¡La autenticación falló!",
				"invalidCredentials": "Ingrese su nombre de usuario y contraseña."
			}
		}
	},
	"resetpassword": {
		"success": "Por favor revisa tu casilla de correo electrónico",
		"title": "Recupera tu contraseña",
		"message": "Por favor ingrese su correo electrónico o nombre de usuario.<br/>Pronto recibirá un mensaje en su correo electrónico con información para cambiar su contraseña.",
		"email.placeholder": "Correo electrónico o nombre de usuario",
		"action": {
			"back": "para volver",
			"resetpassword": "Recupera tu contraseña"
		}
	},
	"changepassword": {
		"title": "Cambia tu contraseña",
		"message": "Por favor escriba su nueva contraseña.",
		"success": "Su contraseña ha sido cambiada.",
		"action": {
			"back": "para volver",
			"changepassword": "Cambiar la contraseña"
		},
		"erro": {
			"minlength": "¡La contraseña debe tener 5 caracteres o más!",
			"repetirinvalido": "La contraseña no coincide"
		},
		"placeholder": {
			"password": "Contraseña",
			"confirmPassword": "Repita la contraseña"
		}
	},
	"changefirm": {
		"fields": {
			"nEmpresa": "empresa no.",
			"nomeEmpresa": "Nombre de empresa"
		}
	},
	"acessos": {
		"erpcloud": {
			"addons": {
				"bankingAutomation": {
					"acesso": "Acceso a la automatización bancaria"
				}
			},
			"ativos": {
				"fichas": {
					"visualizarFichas": "Ver hojas de activos",
					"criarFichas": "Crear hojas de activos",
					"editarFichas": "Editar hojas de activos",
					"apagarFichas": "Eliminar hojas de activos",
					"operacoesAbatesVendas": "Operaciones de sacrificio y venta.",
					"calcularAnularDepreciacoes": "Calcular y cancelar la depreciación."
				}
			},
			"contabilidade": {
				"documentos": {
					"criarDocumentos": "Crear documentos",
					"editarDocumentos": "Editar documentos",
					"anularDocumentos": "Cancelar documentos",
					"visualizarDocumentos": "Ver documentos"
				}
			},
			"gestaocomercial": {
				"editarContaArtigoFamilia": "Puedes editar la cuenta para la compra y venta de artículos y familias.",
				"editarMeioPagamento": "Puedes editar los métodos de pago.",
				"documentos": {
					"comprasefetivas": {
						"title": "Compras reales"
					},
					"encomendasclientes": {
						"title": "Pedidos de clientes"
					},
					"encomendasfornecedores": {
						"title": "Pedidos de proveedores"
					},
					"guiastransporte": {
						"title": "Guías de transporte"
					},
					"guiastransportefornecedores": {
						"title": "Proveedores de guías de transporte."
					},
					"propostasclientes": {
						"title": "Propuestas de clientes"
					},
					"vendasefetivas": {
						"title": "Ventas efectivas"
					},
					"entradasdiversas": {
						"title": "Entradas varias"
					},
					"saidasdiversas": {
						"title": "Salidas varias"
					},
					"propostasfornecedores": {
						"title": "Propuestas de proveedores"
					}
				}
			}
		},
		"manager": {
			"generic": {
				"view": "Vista",
				"add": "para crear",
				"edit": "Editar",
				"delete": "Cancelar"
			},
			"addons": {
				"title": "Complementos"
			},
			"ativos": {
				"title": "Activos"
			},
			"contabilidade": {
				"title": "Contabilidad"
			},
			"gestaoComercial": {
				"title": "Comercial"
			}
		}
	},
	"acrescimosencargosferias": {
		"porEmpresa": "Por empresa",
		"multiEmpresa": "Multiempresa",
		"fields": {
			"empregado": "Empleado",
			"totaisEncargos": "Cargos totales",
			"codEmpregado": "Empleado",
			"nomeEmpregado": "Nombre",
			"dataAdmissao": "Fecha de admisión",
			"nMesesTrabalhados": "Nº de meses trabajados.",
			"ccusto": "C. Costo",
			"nCodRepCC": "Descomponer",
			"estadoID": "Estado",
			"vBaseEncargosFerias": "Base de cargo de vacaciones",
			"valorAcertoManual": "Ajuste manual",
			"valorSubFerias": "Sub.",
			"valorFerias": "Vacaciones",
			"valorEncargosSS": "Social lunes.",
			"valorEncargoAcTrab": "antes de Cristo",
			"descrBaseEncFerias": "Información",
			"dados": "Datos",
			"sel": "Sel.",
			"nEmpresa": "Empresa",
			"nomeEmpresa": "Nombre de empresa",
			"tipoID": "Tipo",
			"descricaoErro": "Información",
			"dataIntegracao": "Integración de fechas"
		},
		"cab": {
			"totalEncAnuais": "Cargos anuales totales por empleado",
			"tipo": "Tipo",
			"acTrabalho": "%C.A.",
			"estado": "Estado",
			"dataInt": "Integración de fechas",
			"tiposProce": {
				"tipoproce0": "",
				"tipoproce1": "Anual",
				"tipoproce2": "Mensual"
			}
		},
		"modals": {
			"config": {
				"title": "Configuración de acumulaciones de cargos por vacaciones",
				"contasDebito": "cuentas de débito",
				"contasCredito": "cuentas de crédito",
				"percActTrabalho": "% seg. accidentes de trabajo",
				"diario": "A diario",
				"descritivo": "Descriptivo",
				"pocSubFerias": "Sub.",
				"pocFerias": "Vacaciones",
				"pocEncargosSegSocial": "Cargos seg.",
				"pocEncargosActTrabalho": "Cargas ácidas.",
				"pocOutrosEncargos": "Otros cargos",
				"tipo": "Tipo",
				"percSeguradora": "% seg. accidentes de trabajo",
				"dadosProce": "Datos para procesamiento",
				"intContab": "Integración contable"
			},
			"resumoAnual": {
				"title": "Cargos anuales",
				"totaisAnual": "Totales anuales",
				"encargos": "Cargos",
				"acertoManual": "Ajuste manual",
				"subferias": "Sub.",
				"ferias": "Vacaciones",
				"encSegSocial": "Cargos seg.",
				"encAcTrab": "Cargas de CA.",
				"segSocial": "Social lunes.",
				"acTrab": "antes de Cristo",
				"outrosEnc": "Otros cargos",
				"custosEmp": "Costos de la empresa",
				"totaisAno": "Totales del año"
			},
			"empresas": {
				"title": "Aumento de cargos por vacaciones multiempresa",
				"nEmpresa": "Empresa",
				"nomeEmpresa": "Nombre de empresa",
				"tipo": "Tipo",
				"percSeguradora": "%C.A."
			},
			"multiempresadetail": {
				"title": "Cargos adicionales por vacaciones"
			},
			"print": {
				"title": "Impresión de acumulaciones para cargos de vacaciones",
				"deCodEmp": "código de empleado",
				"deCCusto": "centro de costos",
				"deReparticao": "Desglose de"
			}
		},
		"tipoAcrescimoEncFeriasSource": {
			"anual": "Anual",
			"mensal": "Mensual"
		},
		"estadoRegEmp": {
			"novo": "Nuevo",
			"ok": "DE ACUERDO",
			"alterado": "Datos modificados",
			"apagado": "Dejó la empresa"
		},
		"estadoAcrescimoEncFerias": {
			"processado": "Procesado",
			"integrado": "Integrado",
			"comInconsistencias": "Con inconsistencias",
			"proceInteg": "Procesado e integrado",
			"proceNotInteg": "Procesado y no integrado"
		},
		"integContabMultiErrors": {
			"title": "Informe de integración contable",
			"naoInteg": "No integrado",
			"integ": "Integrado"
		},
		"btns": {
			"geraEncargos": "Generar cargos",
			"removeEncargos": "Eliminar cargos",
			"resumoAnual": "Resumen anual",
			"editCab": "Editar encabezado",
			"empregado": "Empleado",
			"assistente": "Asistente",
			"lancarDocumento": "Publicar documento",
			"apagarDocumento": "Eliminar documento",
			"lancarDocumentoMulti": "Publicar documento",
			"apagarDocumentoMulti": "Eliminar documento",
			"verificarInconsistenciasMulti": "Verificar inconsistencias",
			"apagaEncargosMulti": "Eliminar",
			"details": "Detalles",
			"integraContab": "Integración contable",
			"consultarLanc": "Consultar lanzamientos"
		},
		"messages": {
			"naotemencargos": "no hay cargos",
			"naoSelEmp": "Debe seleccionar al menos una empresa.",
			"docsIntegSuccess": "Documentos integrados con éxito",
			"docsDeletedSuccess": "Documentos integrados eliminados con éxito"
		}
	},
	"adiantamentos": {
		"cliente": "Cliente",
		"fornecedor": "Proveedor",
		"tabs": {
			"porRegularizar": "Para regularizar",
			"regularizado": "Regularizado",
			"fields": {
				"documentoStr": "Documento",
				"origemDocStr": "Origen",
				"nClifo": "Cuenta",
				"nomeClifo": "Nombre de cuenta",
				"liquido": "Líquido",
				"valorIva": "TINA",
				"porRegularizar": "Para regularizar",
				"estadoStr": "Estado",
				"valorRegularizar": "Para regularizar",
				"dateDocTo": "Fecha del doctor.",
				"dataRef": "Fecha de referencia",
				"intervalo": "Intervalo",
				"datarefradio": "Fecha de referencia",
				"modofunc": "Modo de funcionamiento",
				"dataUltRegular": "Última fecha",
				"valorRegularizadoAData": "Valor ajustado a la fecha ref.",
				"estadoNaDataRefStr": "Estado en fecha ref."
			},
			"pesquisaDatas": "Buscar por fechas",
			"pesquisaIntervalo": "Buscar pagos anticipados entre fechas",
			"pesquisaNaData": "Estado de búsqueda de avances a la fecha"
		},
		"modals": {
			"familia": "Familia",
			"nArtigoAdiantamento": "Artículo avanzado",
			"nArtigoRegAdiantamento": "Artículo de liquidación anticipada",
			"autoConfig": {
				"title": "Gestión de anticipos - Configuración automática",
				"steps": {
					"intro": {
						"title": "Introducción",
						"desc": "Asistente para configuración automática de gestión de anticipos"
					},
					"familiaBase": {
						"title": "familia base",
						"desc": "Familia base para crear pagos iniciales",
						"assistente1": "Este asistente creará",
						"assistente2": "Familia de artículos",
						"assistente3": "Artículo de liquidación anticipada",
						"assistente4": "Artículo avanzado",
						"familiaEmpty": "Debes seleccionar una familia."
					},
					"familiaArtigos": {
						"title": "Familia y artículos",
						"desc": "Familia y artículos que se crearán."
					}
				}
			},
			"config": {
				"title": "Configuración de pago inicial",
				"desc": "Es necesario configurar los ítems que utilizará el módulo de gestión de avances."
			},
			"registar": {
				"title": "Registrarse anticipado",
				"emitAdiant": "Emisión anticipada",
				"emitAdiantDoc": "Emisión de anticipo relativo al documento {{doc}}",
				"taxaIva": "tipo de IVA",
				"valorSIva": "Valor sin IVA",
				"valorCIva": "Valor con IVA",
				"codiva": "código de IVA"
			},
			"print": {
				"title": "Impresión de anticipos"
			}
		},
		"messages": {
			"notConfigured": "¡El sistema aún no está configurado para utilizar la gestión de anticipos!",
			"criaConfigTooltip": "Se crearán las configuraciones necesarias.",
			"noDocSel": "No seleccionó ningún documento para regularizar.",
			"configDocFields": "Para crear un nuevo documento debes configurar los campos.",
			"valueInvalid": "El valor debe ser mayor que cero.",
			"clifoNaoSel": "Debes insertar un {{clip}} para regularizar anticipos.",
			"clifoNaoSelReg": "Debes insertar un {{clip}} para registrar el avance.",
			"sistemNotConfigured": "Debe configurar el sistema para utilizar la gestión de anticipos.",
			"docHasAdiant": "El documento ya contiene una línea de anticipo.",
			"docHasRegAdiant": "El documento ya contiene una línea de regularización de anticipos."
		},
		"btns": {
			"autoConfig": "Configurar automáticamente",
			"regularizar": "Regularizar",
			"rastreabilidade": "Trazabilidad"
		}
	},
	"amalia": {
		"title": "amalia",
		"text": {
			"promptPlaceholder": "Pregúntame cualquier cosa...",
			"openFaq": "Ver en el sitio web",
			"unsupported": "Aún no admitido: {{message}}"
		},
		"actions": {
			"premade": {
				"balanceteMesAnterior": "Balance del mes anterior",
				"possoUsarTelemovel": "¿Puedo usarlo en mi celular?",
				"currentTime": "¿Qué hora es?",
				"configurarProRata": "¿Cómo configurar el prorrateo?",
				"comoAtribuirPaisCliente": "¿Cómo asignar padres a un cliente?"
			}
		}
	},
	"amortizacao": {
		"add": "para agregar",
		"title_detail": "Código de depreciación {{id}}",
		"title_new": "Nuevo código de depreciación",
		"title_plural": "Códigos de depreciación",
		"pesquisa": "buscar",
		"saved": "Código de depreciación {{id}}, guardado exitosamente.",
		"error": "Código de depreciación {{id}}, no se puede guardar.",
		"deleted": "Código de depreciación {{id}}, eliminado exitosamente",
		"fields": {
			"nImtab": "Código.",
			"nImtabPlaceholder": "Código de amortización",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre de amortización",
			"tipo": "Tipo",
			"tabela": "Mesa",
			"divisao": "División",
			"grupo": "Grupo",
			"alinea": "Punto",
			"artigo": "Artículo",
			"keyValue": "Valor clave",
			"valor1": "Límite de impuestos",
			"valor2": "Límite contable",
			"valor3": "Tasa",
			"dataInicio": "Fecha de inicio"
		}
	},
	"analisadorTes": {
		"btns": {
			"toolbarShortcuts": "Atajos",
			"toolbarConfig": "Ajustes",
			"toolbarPocMenu": "Clientes y proveedores",
			"syncToolbar": "Sincronizar",
			"collapse": "Colapsar",
			"expand": "Expandir",
			"collapseAll": "Contraer todo",
			"expandAll": "Expandir todo",
			"pesquisar": "buscar",
			"editarPrevisaoRecebimento": "Editar previsión de recibos",
			"alterarDataTesouraria": "Cambiar fecha de tesorería",
			"removerDocumentoTesouraria": "Retirar documento de tesorería",
			"toolbarPreviTes": "Lanzamiento manual",
			"toolbarPreviTesAuto": "Lanzamiento automático",
			"extratoConta": "Estado de cuenta",
			"toolbarPrevTesObras": "Importador de planillas financieras para obras.",
			"criarTesRubricas": "Crear rúbrica",
			"editarRubrica": "Editar rúbrica",
			"pagamento": "Pago",
			"recebimento": "Recibo",
			"verDocumentoContabilidade": "Ver documento en contabilidad",
			"verDocumentoGestComercial": "Ver documento en gestión comercial",
			"removerTodosDocumentosDaConta": "Eliminar todos los documentos de la cuenta."
		},
		"messages": {
			"syncDados": "Sincronizando datos...",
			"deleteTesLancoMessage": "¿Está seguro de que desea eliminar el registro de tesorería?",
			"datasAlteradasComSucesso": "Las nuevas fechas de tesorería se han guardado correctamente.",
			"temACertezaQueQuerEliminarTodosNConta": "¿Está seguro de que desea eliminar todos los documentos de tesorería de la cuenta \"{{nConta}}\"?",
			"aDataNaoPodeSerUmDiaDeFimDeSemana": "La fecha no puede ser un día de fin de semana.",
			"aDataNaoPodeSerInferiorADataAtual": "¡La fecha no puede ser menor que la fecha actual!"
		},
		"strings": {
			"saldoInicial": "Saldo inicial",
			"saldoFinal": "saldo final",
			"configModalTitle": "Ajustes",
			"tabLotesTitle": "Pagos por lotes",
			"manual": "Manual",
			"contabilidade": "Contabilidad",
			"cgBanking": "Banca GC",
			"origemSaldoInicial": "Origen del saldo inicial",
			"intervaloContas": "Rango de cuenta",
			"contaDe": "cuenta",
			"intervaloContasBancarias": "Rango de cuentas bancarias",
			"importaAutomaticamente": "Importa automáticamente",
			"rubricaParaRecEmLote": "Artículo para recibos de lotes",
			"rubricaParaPagEmLote": "Rumbo a pagos por lotes",
			"periodicidadeAnalise": "Frecuencia de análisis",
			"periodicidadeAnaliseMobile": "Periodicidad",
			"updateSaldoInicial": "Actualizar saldo inicial",
			"valor": "Valor",
			"dataUltimaAtualizacaoNota": "Nota última fecha de actualización",
			"diaria": "A diario",
			"semanal": "Semanalmente",
			"mensal": "Mensual",
			"rubrica": "Rúbrica",
			"descricao": "Descripción",
			"documento": "Documento",
			"nConta": "Nro. de cuenta",
			"nomeConta": "Nombre de cuenta",
			"novaData": "Nueva fecha",
			"valoresAnt": "Valores con fecha de tesorería anterior",
			"novaDataTesParaTodos": "Nueva fecha de tesorería para todos"
		}
	},
	"analiticaconfig": {
		"radicaisList": "Lista de radicales",
		"radicaisExcluidoList": "Lista de radicales excluidos",
		"novoRadical": "Nuevo radical",
		"novoRadicalPlaceholder": "Ingrese cuentas del libro mayor con análisis, por ejemplo: 31",
		"novoRadicalExcluido": "Nuevo radical eliminado",
		"novoRadicalExcluidoPlaceholder": "Ingrese cuentas del libro mayor sin análisis, por ejemplo 3112.",
		"messages": {
			"radicalJaExistente": "El radical insertado ya existe en la lista.",
			"radicalExcluidoJaExistente": "La raíz eliminada insertada ya existe en la lista.",
			"apagarItem": "Eliminar línea",
			"desejaApagarItem": "¿Quieres eliminar la línea?",
			"successfullySaved": "Los cambios se guardaron correctamente.",
			"campoRadicalVazio": "El campo radical no puede estar vacío.",
			"campoRadicalExcluidoVazio": "El campo radical eliminado no puede estar vacío."
		}
	},
	"anosFaturacao": {
		"pesquisa": "buscar"
	},
	"aplicabilidadeIRCT": {
		"data": {
			"filiacao": "Afiliación",
			"portariaExt": "Ordenanza de extensión",
			"escolha": "Elección",
			"actoGest": "acto de gestión",
			"sem": "Sin aplicabilidad",
			"auto": "Automático"
		}
	},
	"application": {
		"alt": {
			"logo": "Logotipo de la aplicación",
			"companyLogo": "Logotipo de la empresa"
		},
		"versionUpdate": {
			"title": "Nueva versión disponible",
			"description": "Una nueva versión de la aplicación está disponible, por favor",
			"reload": "Haga clic aquí para actualizar la aplicación."
		},
		"maintenance": {
			"title": "Sistema en mantenimiento",
			"description": "El sistema está en mantenimiento.",
			"endedTitle": "Mantenimiento del sistema completado",
			"endedDescription": "Puede cerrar este aviso y reanudar su trabajo.",
			"lastChecked": "Última comprobación: {{time}}"
		},
		"disconnected": {
			"title": "Se perdió la comunicación con el servidor.",
			"description": "La comunicación con el servidor está teniendo problemas.",
			"restoredTitle": "Comunicación con el servidor restaurada",
			"restoredDescription": "Se ha restablecido la comunicación con el servidor.",
			"offline": "Está desconectado del servidor."
		}
	},
	"apurainventperma": {
		"title": "Cálculo mensual de inventario permanente.",
		"module": {
			"steps": {
				"inventario": "Inventario de fin de período",
				"documents": "Documentos de liquidación a generar",
				"preview": "Vista previa del documento"
			},
			"datagrids": {
				"infoApuramento": {
					"periodo": "Período",
					"apuramentoEfetuado": "Despacho realizado",
					"existeDocumento": "Hay un documento",
					"temErros": "tiene errores"
				},
				"contas": {
					"codConta": "código de cuenta",
					"designacao": "Designación",
					"masterDetail": {
						"nConta": "Nro. de cuenta",
						"nome": "Nombre",
						"valor": "Valor"
					}
				},
				"previsualDocumentos": {
					"extPocCabID": "PocCabID",
					"periodo": "Período",
					"nContaDebito": "Deuda",
					"nContaCredito": "Crédito",
					"valor": "Valor",
					"descricao": "Descripción"
				}
			}
		},
		"modal": {
			"configContas": {
				"title": "Configurar cuentas de liquidación",
				"mercadorias": {
					"title": "Bienes",
					"contaDeCompras": "Transferir cuentas de compra de bienes",
					"contaDeMercadorias": "Compra de cuentas de transferencia de mercancías",
					"contaDeConsumo": "Cuenta de consumo de bienes",
					"contaDeTransferencia": "Cuenta de transferencia de liquidación de mercancías"
				},
				"materiaPrima": {
					"title": "Materias primas",
					"contaComprasTransferenciaParaMateriasPrimas": "Transferir cuenta de compras de materias primas",
					"contaMateriasPrimasParaTransferenciaCompras": "Cuenta de materias primas para transferencia de compras.",
					"contaConsumoMateriasPrimas": "Cuenta de consumo de materias primas",
					"contaMateriasPrimasParaRegularizacao": "Cuenta de materias primas para regularización"
				},
				"materiasSubsidiarias": {
					"title": "Asuntos subsidiarios",
					"contaComprasTransferenciaParaMateriasSubsidiarias": "Transferir Cuenta de Compra para Asuntos Subsidiarios",
					"contaMateriasSubsidiariasParaTransferenciaCompras": "Cuenta de materiales subsidiaria para transferencia de compras.",
					"contaConsumoMateriasSubsidiarias": "Cuenta de consumo de materiales subsidiaria",
					"contaMateriasSubsidiariasParaRegularizacao": "Cuenta de asuntos subsidiarios para regularización"
				},
				"embalagens": {
					"title": "Materiales de embalaje",
					"contaComprasTransferenciaParaMateriaisEmbalagens": "Transferencias de cuentas de compra de materiales de embalaje.",
					"contaMateriaisEmbalagensParaTransferenciaCompras": "Cuenta de materiales de embalaje para transferencias de compra.",
					"contaConsumoEmbalagensParaConsumo": "Cuenta de consumo de envases de consumo",
					"contaMateriaisEmbalagensParaRegularizacao": "Cuenta de materiales de embalaje para regularización."
				},
				"materiaisDiversos": {
					"title": "Materiales diversos",
					"contaComprasTransferenciaParaMateriaisDiversos": "Transferencias de cuentas de compras para materiales varios.",
					"contaMateriaisDiversosParaTransferenciaCompras": "Cuenta de materiales varios para transferencias de compra",
					"contaConsumoMateriaisDiversosParaConsumo": "Cuenta de consumo de materiales varios.",
					"contaMateriaisDiversosParaRegularizacao": "Lista de materiales varios para regularización"
				},
				"materiasTransito": {
					"title": "Asuntos en tránsito",
					"contaComprasTransferenciaParaMateriasTransito": "Cuenta de compras de transferencias de materiales varios",
					"contaMateriasTransitoParaTransferenciaCompras": "Cuenta de materiales en tránsito para transferencias de compra",
					"contaConsumoMateriasTransitoParaConsumo": "Cuenta de consumo de materiales en tránsito",
					"contaMateriasTransitoParaRegularizacao": "Cuenta de materiales en tránsito para regularización"
				},
				"produtosAcabados": {
					"title": "Productos terminados",
					"contaVariacaoProducaoParaProdutosAcabados": "Cuenta de variación de producción para productos terminados",
					"contaRegularizacaoExistenciaParaProdutosAcabados": "Cuenta de regularización de existencia de productos terminados."
				},
				"subProdutos": {
					"title": "Subproductos",
					"contaVariacaoProducaoParaSubprodutos": "Cuenta de variación de producción de subproductos.",
					"contaRegularizacaoExistenciaParaSubprodutos": "Cuenta de regularización de existencia de subproductos"
				},
				"produtosTrabalhosEmCurso": {
					"title": "Productos y trabajos en curso",
					"contaVariacaoProducaoParaProdutosTrabalhosEmCurso": "Contabilizar la variación en la producción de productos y el trabajo en curso.",
					"contaRegularizacaoExistenciaParaProdutosTrabalhosEmCurso": "Regularización de cuentas de existencia de productos y trabajos en curso"
				},
				"activosBiologicosCompras": {
					"title": "Compra de activos biológicos",
					"contaComprasTransferenciaParaActivosBiologicosConsumo": "Cuenta de compras de transferencias de activos de consumo biológico",
					"contaActivosBiologicosConsumoParaTransferenciaCompras": "Cuenta de activos de consumo biológico para transferencias de compra.",
					"contaConsumoActivosBiologicosConsumoParaConsumo": "Consumo de cuentas de activos de consumo biológico.",
					"contaActivosBiologicosConsumoParaRegularizacao": "Cuenta de activos de consumo biológico para regularización"
				},
				"activosBiologicosProducao": {
					"title": "Activos de producción biológica",
					"contaExistenciaActivosBiologicosProducao": "Cuenta de existencia de activos de producción biológica.",
					"contaVariacaoProducaoParaActivosBiologicosProducao": "Contabilizaciones de variación de producción para la producción de activos biológicos",
					"contaRegularizacaoExistenciaParaActivosBiologicosProducao": "Regularización de cuentas de existencia de activos de producción biológica."
				}
			}
		},
		"btns": {
			"configContas": "Configuración de cuenta"
		},
		"actions": {
			"verDocumentoApuramento": "Ver documento de liquidación para este período.",
			"apagarDocumentoDeApuramento": "Eliminar documento de autorización"
		},
		"menagens": {
			"valoresOriginaisRepostos": "Valores originales restablecidos exitosamente",
			"savedSuccess": "La configuración de la cuenta se guardó correctamente",
			"apuramentoJaExiste": "El despacho ya se ha realizado",
			"periodoAlreadyHasApuramento": "Ya has realizado el cálculo mensual del inventario permanente para el periodo '{{period}}'.",
			"documentosGravadosSucesso": "Documentos guardados exitosamente",
			"apagarDocumentoApuramentoTitle": "Eliminar documento de autorización",
			"apagarDocumentoApuramentoMessage": "¿Realmente desea eliminar el documento de cálculo del período {{period}}?",
			"naoDeveFazerOApuramentoDeInventario": "El cálculo mensual del inventario permanente en el período 12 deberá realizarse mediante la aplicación de cálculo de resultados."
		}
	},
	"apuraiva": {
		"success": "Liquidación de IVA completada con éxito",
		"promptAlreadyExistsTitle": "El cálculo del IVA ya se ha realizado",
		"promptAlreadyExistsMessage": "Ya se ha realizado el cálculo del IVA para el periodo \"{{period}}\".",
		"errorShortValue": "Tiene {{value}} más que el valor calculado",
		"errorExtraValue": "Quedan {{value}} para completar el conteo",
		"saveTitle": "Liquidación de IVA",
		"saveMessage": "¿Estás seguro de que quieres completar el cálculo del IVA?",
		"docsporintegrar": "Hay documentos a incluir en las cuentas correspondientes al período de IVA que se está calculando.",
		"saveSeeDoc": "Guardar y ver el documento generado",
		"errorInvalidPeriodoMensal": "El período {{period}} no es válido en la lista de períodos mensuales",
		"errorInvalidPeriodoTrimestral": "El período {{period}} no es válido en la lista de períodos trimestrales.",
		"apurado": "determinado",
		"steps": {
			"filter": "Filtro de liquidación de IVA",
			"apuramento": "Cálculo del IVA en el período.",
			"fimperiodo": "Fin del período contable"
		},
		"fields": {
			"descriptive": "Descriptivo",
			"period": "Período",
			"periodCurrent": "Periodo actual",
			"periodNext": "Nuevo periodo",
			"diarios": "Diarios para cerrar",
			"ivaDedutivel": "Importe del IVA deducible",
			"ivaLiquidado": "importe del IVA pagado",
			"ivaApurado": "Importe calculado",
			"aFavorDaEmpresa": "Beneficiario",
			"aFavorDaEmpresa0": "A favor de la empresa",
			"aFavorDaEmpresa1": "A favor del estado",
			"ivaRegFavEmpresa": "Pagos mensuales/trimestrales a favor de la empresa",
			"ivaRegFavEstado": "Mensual/trimestral a favor del estado",
			"ivaRegCalculoProRata": "Anualmente en base al cálculo de la prorrata definitiva",
			"ivaVariacoesProRata": "Anual por variaciones en la prorrata definitiva",
			"ivaRegComunicadasPeloSIVA": "A favor de la empresa comunicada por SIVA",
			"ivaRegOutrasAnuais": "Otras regularizaciones anuales",
			"saldoAnterApuramento": "Autorización anterior",
			"saldoAnterIVAAPagar": "IVA a pagar",
			"saldoAnterIVAARecuperar": "IVA a recuperar",
			"saldoAnterIVAAReembolsar": "IVA a devolver",
			"ivaAPagar": "IVA a pagar",
			"ivaARecuperar": "IVA a recuperar",
			"ivaAReembolsar": "IVA a devolver"
		},
		"titles": {
			"regularizations": "Regularizaciones",
			"details": "Información detallada de autorización",
			"information": "Información sobre saldos anteriores",
			"destination": "Destino de los valores calculados",
			"tabDestination": "Destino del cálculo y saldos anteriores",
			"tabDetails": "Detalles de liquidación de IVA",
			"change": "Cambio de período contable"
		}
	},
	"apuramentoresultados": {
		"title_complex": "Cálculo de resultados netos - {{año}}",
		"title_complex_apurado": "Cálculo de resultados netos - {{año}} (calculado)",
		"pesquisa": "buscar",
		"success": "Cálculo de resultados realizado con éxito",
		"deleted": "Cálculo de resultados eliminado con éxito",
		"beforeDeleteModalMessage": "¿Estás seguro de que quieres eliminar la tabulación de resultados?",
		"promptAlreadyExistsTitle": "El cálculo de resultados ya se ha realizado.",
		"promptAlreadyExistsMessage": "Ya se ha realizado el cálculo de resultados del año \"{{year}}\".",
		"anoapuramento": "Año de cálculo: {{año}}",
		"tipoinvpermanente": "Tipo de inventario perpetuo",
		"steps": {
			"variables": "Variables de liquidación",
			"documents": "Documentos de liquidación a generar",
			"preview": "Vista previa del documento",
			"finished": "Finalizado"
		},
		"fields": {
			"codConta": "código de cuenta",
			"nConta": "Nro. de cuenta",
			"nomeConta": "Designación",
			"nome": "Nombre",
			"valor": "Valor",
			"descritivo": "Descriptivo",
			"dataDoc": "fecha doc",
			"description": "Descripción"
		},
		"titles": {
			"generatedDocs": "Documentos generados"
		},
		"errorInvalidData": "Por favor complete los campos para procesar",
		"errorMissingProcess": "Por favor procese los datos antes de guardarlos.",
		"toolbar": {
			"analise": "Análisis",
			"infoSaldosInv": "Información sobre saldos de cuentas de inventario.",
			"docsSaldosErrados": "Documentos incompatibles con el inv.",
			"docsContasInventario": "Documentos con entradas manuales en cuentas de inventario.",
			"delete": "Eliminar autorización"
		},
		"modal": {
			"saldoscontinv": {
				"title": "Información sobre saldos de cuentas de inventario.",
				"periodo": "Período",
				"compras": "Compras",
				"produtos": "Productos",
				"fields": {
					"nomeConta": "",
					"saldoInicial": "Saldo inicial",
					"compras": "Compras ilíquidas",
					"devolucao": "Devolver",
					"descontos": "Descuentos",
					"totCompras": "Compras totales",
					"comprasTrf": "Centro comercial.",
					"difCompras": "Dif.",
					"saldoFinal": "saldo final",
					"regularizacoesRegistadas": "Registros registrados",
					"regularizacoesTrfConsumo": "registro trf.",
					"difRegularizacoes": "Dif.",
					"consumo": "Consumo",
					"consCalculado": "Contras.",
					"difConsumo": "Dif.",
					"regularizacoesTrfVariacao": "registro trf.",
					"difRegularizacoesProducao": "Dif.",
					"variacaoProducao": "Variación de producción",
					"varProducaoCalc": "Variación de producción calculada",
					"difProducao": "Dif."
				}
			},
			"docscontasinventario": {
				"naoExistemDocumentosComMovimenta": "No existen documentos con movimiento de cuentas de inventario.",
				"naoExistemDocumentosComSaldosDeC": "No existen documentos con saldos de cuentas de inventario incorrectos.",
				"title": "Documentos con entradas manuales en cuentas de inventario.",
				"titleIcons": "Documentos incompatibles con el inv.",
				"fields": {
					"inconsistenciasDocApu": "Inconsecuencia",
					"periodo": "Período",
					"nDiario": "A diario",
					"nDocinterno": "Número de documento"
				}
			}
		}
	},
	"arclis": {
		"title_detail": "Artículo de cliente/proveedor",
		"title_new": "Nuevo artículo de cliente/proveedor",
		"title_plural": "Artículos de clientes/proveedores",
		"pesquisa": "buscar",
		"saved": "Artículo de cliente/proveedor guardado correctamente.",
		"error": "El artículo del cliente/proveedor no se puede guardar.",
		"deleted": "Artículo de cliente/proveedor, eliminado exitosamente.",
		"deleteModalText": "¿Eliminar registro \"{{nClifoArtigo}}\"?",
		"fields": {
			"nClifoArtigo": "Código de artículo CLI.",
			"nArtigo": "Código del artículo",
			"nConta": "Cliente/proveedor",
			"descricaoFornecedor": "Descripción del artículo CLI."
		},
		"btn": {
			"newArtigo": "Nuevo"
		},
		"list": {
			"actions": {
				"editar": "Editar"
			}
		}
	},
	"areaRegionalTaxa": {
		"pesquisa": "Áreas fiscales regionales",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"continent": "Continente",
			"madeira": "Madera",
			"acores": "Azores"
		}
	},
	"areasRegionais": {
		"data": {
			"0": "Continente",
			"1": "Madera",
			"2": "Azores",
			"continente": "Continente",
			"madeira": "Madera",
			"acores": "Azores"
		}
	},
	"areasRegionaisControloIVA": {
		"data": {
			"0": "N / A",
			"1": "Continente",
			"2": "Madera",
			"3": "Azores",
			"notApplicable": "N / A",
			"continente": "Continente",
			"madeira": "Madera",
			"acores": "Azores"
		}
	},
	"armazens": {
		"title_detail": "Almacén {{id}}",
		"title_new": "Nuevo almacén",
		"title_plural": "Almacenes",
		"pesquisa": "buscar",
		"saved": "Almacén {{id}}, guardado exitosamente.",
		"error": "Almacenamiento {{id}}, no se puede almacenar.",
		"deleted": "Almacén {{id}}, eliminado correctamente.",
		"fields": {
			"nArmazem": "Código.",
			"nArmazemPlaceholder": "código de almacén",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del almacén",
			"morada1": "Familiar",
			"morada1Placeholder": "Dirección del almacén",
			"morada2": "Familiar",
			"codPostal": "Código Postal",
			"codPostalPlaceholder": "Código postal del almacén",
			"nomeCPostal": "Ubicación",
			"nomeCPostalPlaceholder": "Ubicación del almacén",
			"codPais": "Código del país",
			"codPaisPlaceholder": "País de almacén",
			"nomePais": "País",
			"nomePaisPlaceholder": "País de almacén",
			"nTelefone": "Teléfono",
			"nTelefonePlaceholder": "Teléfono del almacén",
			"nFax": "Fax",
			"nFaxPlaceholder": "Fax de almacén"
		}
	},
	"arquivodigital": {
		"errors": {
			"licenseNotActivated": "La empresa actual no tiene activada la licencia de archivo digital.",
			"licenseNotActivatedCGOn": "La empresa actual no tiene activada la licencia de archivo digital.<br/>Por favor, acceda a la <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\">tienda</a> CentralGest Cloud y activar o renovar la licencia de contabilidad digital."
		},
		"gestaodocsdigitalizados": {
			"actions": {
				"obterDadosEFatura": "Obtener datos del documento seleccionado de eFatura (necesita cumplimentar NIF y fecha del documento)",
				"atribuirTipoDocumento": "Asignar tipo de documento",
				"unirDocs": "Fusionar documentos seleccionados",
				"separarDoc": "Separe las páginas del documento",
				"copiarDoc": "Copiar documento",
				"removePages": "Eliminar páginas del documento",
				"apagar": "Eliminar documentos seleccionados",
				"removeassociation": "Eliminar asociación con documentos contables",
				"verDoc": "Ver documento contable",
				"novaContaCC": "Crear nueva cuenta corriente",
				"exportarArquivo": "Exportar archivo digital",
				"adicionardoc": "Agregar documento(s)",
				"mudardocpasta": "Cambiar carpeta de documentos seleccionados",
				"reaplicarocr": "Vuelva a aplicar OCR al documento",
				"predefinidos": "Configuración predefinida para la entidad.",
				"docsContabSemContabDig": "Documentos contables sin documentos digitales",
				"robo": "Lanzar documentos usando CentralGest AI Robot",
				"extratoConta": "Ver estado de cuenta",
				"associardocsauto": "Asociar automáticamente todos los documentos",
				"associardocsincluidoanuladosauto": "Asociar automáticamente con todos los documentos, incluidos los documentos cancelados",
				"possiveisDocsRepetidos": "Ver posibles documentos duplicados"
			},
			"pesqavancada": {
				"title": "Búsqueda avanzada",
				"nifDe": "NIF de",
				"docExternoDe": "Documento externo.",
				"dataDocExternoDe": "Fecha del doctor.",
				"dataRececaoDe": "Fecha de recepción de",
				"mostrarDocClassificados": "Mostrar también documentos ya clasificados.",
				"mostraApenasLancRobot": "Mostrar sólo documentos ya clasificados por el robot"
			},
			"table": {
				"fields": {
					"folder": "Carpeta",
					"nif": "NIF",
					"entidade": "Entidad",
					"temNIFEmpresa": "Tiene NIF de empresa",
					"invoiceTypeStr": "Tipo de documento",
					"nDocumento": "Documento externo.",
					"totalBase": "base total",
					"totalIVA": "IVA total",
					"pasta": "Carpeta",
					"ficheiro": "Archivo",
					"fiscalmenteRelevante": "Fiscalmente relevante",
					"dataRececao": "fecha de recibo",
					"classficadoPor": "Ordenado por",
					"ndocContabilidade": "Doc no.",
					"ndocumentoSAFT": "Número de documento SAFT",
					"temNIFEmpresamin": "Tiene NIF de empresa",
					"invoiceTypeStrmin": "Tipo de documento.",
					"fiscalmenteRelevantemin": "Fiscalmente relevante",
					"classficadoPormin": "Clase.",
					"ndocContabilidademin": "Doc no.",
					"ndocumentoSAFTmin": "Doc no.",
					"preDefenidosID": "Predefinido",
					"actions": {
						"adicionardoc": "Agregar documento(s)",
						"mudardocpasta": "Cambiar carpeta de documentos seleccionados",
						"reaplicarocr": "Vuelva a aplicar OCR al documento",
						"obterDadosEFatura": "Obtener datos de los documentos de eFatura",
						"atribuirTipoDocumento": "Asignar tipo de documento",
						"unirDocs": "Fusionar documentos seleccionados",
						"separarDoc": "documento separado",
						"copiarDoc": "Copiar documento",
						"removePages": "Eliminar página(s)",
						"apagar": "Eliminar documentos seleccionados",
						"verDoc": "Ver documento contable",
						"novaContaCC": "Crear nueva cuenta corriente"
					},
					"docSemelhante": {
						"nDocumento": "Número de documento",
						"nDocExterno": "Doc no.",
						"nContrib": "número de contribuyente",
						"descricao": "Descripción",
						"dataDocExt": "Fecha del documento",
						"docspossiveis": "Documentos posibles de asociar."
					}
				},
				"havechanges": "Hay cambios aún no guardados."
			},
			"legend": {
				"error": "Valor no obtenido o incorrecto",
				"warning": "Valor obtenido pero debe ser revisado",
				"waitingocr": "Documento sin OCR realizado",
				"comdocparaassociar": "Con documento contable para asociar",
				"docassociado": "Ya asociado al documento contable."
			},
			"messages": {
				"confirmDelete": "¿Está seguro de que desea eliminar el documento seleccionado?",
				"confirmDeletePlural": "¿Está seguro de que desea eliminar los documentos seleccionados?",
				"exitTitle": "¿Quieres salir sin guardar los cambios?",
				"exitMessage": "Al hacer clic en Aceptar, se saldrá sin guardar y se eliminarán todos los cambios.",
				"updateWithChangesTitle": "¿Quieres actualizar los datos sin guardar los cambios?",
				"updateWithChangesMessage": "Al hacer clic en Aceptar se actualizarán los datos sin guardarlos y se borrarán todos los cambios.",
				"naotemalteracoes": "No hay cambios para guardar.",
				"docvalidadoefatura": "Documento encontrado y validado por eFatura.",
				"docQR": "Documento subido por Código QR",
				"contabDigitalLancadoRoboSuccess": "Documentos seleccionados liberados exitosamente",
				"lancrobotdoc": "Documento {{nombre de archivo}}.",
				"deleteAttachment": {
					"titleDelete": "Eliminar documento digital",
					"titleRemoveAssociation": "Eliminar asociación de documento contable",
					"messageRemoveAssociation": "¿Quiere eliminar la asociación de este documento digital con el documento contable?<br/>Cuando elimina la asociación, el documento vuelve a estar disponible en la gestión de documentos digitales como sin clasificar."
				},
				"modal": {
					"treedocsnaoclassificados": {
						"escolhapastaadd": "Elija la carpeta donde desea agregar los documentos",
						"errorMoveDocSameFolder": "No puede mover el documento a la misma carpeta donde se encuentra."
					},
					"adicionardocumentos": {
						"escolhadocumentos": "Elija el documento(s)",
						"success": "¡Documentos agregados exitosamente!"
					},
					"export": {
						"title": "Exportar el archivo digital",
						"periodoDe": "período de",
						"diarioDe": "diario",
						"nifDe": "NIF de",
						"exportaNaoClassificados": "Exporta sólo documentos no clasificados",
						"exportaApenasRelevantes": "Exporta sólo documentos fiscalmente relevantes.",
						"exportar": "Exportar",
						"folder": "Carpeta",
						"searchAllFolders": "Buscar todas las carpetas",
						"searchExportFolder": "Buscar carpeta para exportar"
					},
					"exportfolder": {
						"documentosClassificados": "Documentos clasificados",
						"documentosNaoClassificados": "Documentos no clasificados"
					}
				},
				"docsassociados": "Documento/s asociados exitosamente.",
				"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmación",
				"leavePromptMessage": "Al hacer clic en SÍ, saldrá y descartará el archivo generado."
			},
			"alerts": {
				"deletesuccess": "¡Documentos eliminados exitosamente!",
				"associacaoremoved": "¡La asociación de documentos se eliminó correctamente!",
				"selsamefolder": "Debe seleccionar documentos de la misma carpeta."
			},
			"btn": {
				"alterapasta": "Cambiar carpeta",
				"refresh": "Actualizar datos",
				"associar": "Asociado"
			},
			"export": {
				"processLabel": "Exportar: Preparando el archivo...",
				"processDownloadTooltip": "Descarga el archivo",
				"downloadText": "Descargar",
				"zipReadyText": "El archivo está listo.",
				"downloadError": "¡Ocurrió un error al descargar el archivo!",
				"jobTimeoutModalTitle": "Tarea",
				"jobTimeoutModalMessage": "La tarea ha expirado.",
				"fileNotProcessed": "Lo sentimos, pero por alguna razón el archivo no se generó."
			},
			"errors": {
				"addFilesDisallowed": "Su licencia del módulo de archivo digital actualmente no le permite agregar más documentos digitales.<br/>Vaya a <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\">Tienda CentralGest Cloud< /a> y active o renueve la licencia del archivo digital."
			},
			"docscontabsemdocdigital": {
				"title": "Documentos contables sin documentos digitales",
				"header": {
					"periodo": "Período",
					"diario": "A diario"
				},
				"table": {
					"periodo": "Período",
					"nDiario": "A diario",
					"nDocInterno": "Doc interno No.",
					"debito": "Deuda",
					"credito": "Crédito",
					"nDocExterno": "Doc externo No.",
					"dataDoc": "Fecha del doctor.",
					"dataDocExterno": "Fecha del doctor.",
					"descritivo": "Descriptivo",
					"descricao": "Descripción",
					"contrib": "número de contribuyente",
					"contaCorrente": "Nro. de cuenta",
					"nomeContaCorrente": "Nombre"
				}
			}
		},
		"genericviewer": {
			"text": {
				"currentFolder": "Carpeta actual",
				"root": "Fuente"
			}
		},
		"treeviewer": {
			"actions": {
				"addDoc": "Agregar documentos a esta carpeta",
				"captureDoc": "Capturar documento y agregarlo a la carpeta actual",
				"deleteDocs": "Eliminar todos los documentos de esta carpeta",
				"deleteDoc": "Eliminar documento"
			}
		},
		"flatviewer": {
			"titles": {
				"folders": "Carpetas",
				"files": "Archivos",
				"empty": "(Vacío)"
			}
		},
		"docviewer": {
			"actions": {
				"zoomIn": "Dar un golpe de zoom",
				"zoomOut": "alejar",
				"rotateLeft": "Girar a la izquierda",
				"rotateRight": "Girar a la derecha"
			}
		},
		"docviewerrecolha": {
			"title": {
				"location": "Ubicación",
				"attachment": "Anexo",
				"addDocs": "Agregar documentos",
				"addDocsCurrentFolder": "Agregar documentos a la carpeta actual",
				"addDocsWithPath": "Agregar documentos a la carpeta actual ({{folderPath}})",
				"attachDoc": "Adjuntar documento digital",
				"chooseFolder": "Elige carpeta",
				"scannedDocumentManagement": "Gestión de documentos escaneados",
				"config": "Ajustes",
				"deleteAttachment": "Quitar archivo adjunto",
				"download": "Transferir documento",
				"noAttachment": "Sin documento digital",
				"attachmentNotFound": "Documento digital no encontrado",
				"attachmentFound": "Documento digital encontrado",
				"attachmentPossiblyFound": "Hemos encontrado un posible documento digital, verifique si el documento digital sugerido coincide con el documento que está publicando.",
				"attachmentMultipleFound": "Hemos encontrado varios documentos digitales posibles, verifique si el documento digital sugerido coincide con el documento que está publicando.",
				"linhasIVA": {
					"valorBase": "Incidencia",
					"valorIVA": "TINA",
					"tipoTaxa": "tipo de tarifa",
					"areaRegional": "Área regional",
					"taxa": "Tasa"
				},
				"totais": {
					"baseTributavel": "Base imponible",
					"totalIVA": "IVA total",
					"valorRetencao": "Valor de retención"
				}
			},
			"navigation": {
				"selectFirstAttachment": "Ir al primer archivo adjunto",
				"selectPreviousAttachment": "Anterior",
				"selectNextAttachment": "Siguiente",
				"selectLastAttachment": "Ir al último archivo adjunto"
			},
			"actions": {
				"attachExistingDoc": "Adjuntar un documento existente en contabilidad digital al documento actual",
				"attachNewDoc": "Agregar nuevo documento en contabilidad digital y adjuntarlo al documento actual",
				"attachVerbete": "Agregar entrada al documento digital",
				"deleteAttachment": {
					"titleDelete": "Eliminar documento digital",
					"titleRemoveAssociation": "Eliminar asociación de documento contable",
					"messageDelete": "¿Está seguro de que desea eliminar el documento?<br/><span class=\"text-danger\">Atención:</span> si el documento fue enviado por su cliente de servicios contables al eliminarlo, deberá solicitarlo resentirse de.",
					"messageRemoveAssociation": "¿Quiere eliminar la asociación de este documento digital con el documento contable?<br/>Cuando elimina la asociación, el documento vuelve a estar disponible en la gestión de documentos digitales como sin clasificar."
				}
			},
			"text": {
				"warningHasDigitalDoc": "El documento \"{{periodo}}.{{nDiario}}.{{nDocInterno}}\" ya tiene al menos un documento digital asociado.<br/>¿Estás seguro de que también quieres adjuntar otro documento digital?",
				"successAttachDoc": "Documento digital adjunto exitosamente",
				"successAttachVerbete": "Entrada agregada exitosamente al documento digital",
				"warningDateMismatch": "Estás adjuntando un documento digital que ha sido configurado con la fecha \"{{dataDoc}}\" y tu documento contable tiene la fecha \"{{dataDocDigital}}\".<br/>¿Estás seguro de que deseas continuar?",
				"valuesSource": {
					"0": "Lectura OCR",
					"1": "Lectura de código de barras",
					"2": "factura electrónica"
				},
				"documento": "Documento: {{nDocumento}}",
				"emptyLocation": "Haga clic aquí para elegir las carpetas de documentos contables digitales.",
				"emptyAttachments1": "No hay documentos en la carpeta \"{{ubicación}}\".",
				"emptyAttachments2": "Haga clic aquí para cambiar de carpeta."
			},
			"choosefoldermodal": {
				"title": "Elegir carpetas de documentos",
				"titleFileRequired": "Elegir un documento digital"
			},
			"attachexistingdocmodal": {
				"title": "Adjuntar nuevo documento"
			},
			"helptopicsmodal": {
				"faq": {
					"basicConcepts": "Conceptos básicos de archivo digital",
					"addDocuments": "Agregar documentos digitales al archivo digital",
					"showHideColumns": "Mostrar y ocultar columnas en la cuadrícula de documentos digitales",
					"setFileFolders": "Definir carpetas de archivos digitales",
					"moveAttachmentToAnotherFolder": "Mover el documento digital a otra carpeta",
					"createCheckingAccountFromAttachment": "Crear una cuenta corriente a partir del documento digital",
					"speedUpManualAccountingRecordingWithAttachmentData": "Acelere el registro contable manual con datos de documentos digitales",
					"addDocumentsThroughPreDefined": "Publicar documentos digitales en contabilidad a través de documentos predefinidos."
				}
			}
		},
		"clientconnect": {
			"actions": {
				"addDocs": "Agregar documentos a la carpeta actual",
				"captureDoc": "Capturar documento y agregarlo a la carpeta actual",
				"deleteDoc": {
					"btn": "Eliminar documento",
					"title": "¿Eliminar el documento \"{{name}}\"?",
					"message": "¿Está seguro de que desea eliminar el documento seleccionado?"
				}
			},
			"tooltips": {
				"addDocs": "Seleccione una carpeta válida para agregar documentos",
				"captureDoc": "Seleccione una carpeta válida para capturar un documento",
				"deleteDoc": "Por favor seleccione un documento para eliminarlo"
			},
			"text": {
				"successDeleteDoc": "Documento eliminado exitosamente"
			},
			"adddocsmodal": {
				"title": "Agregar documentos",
				"sendDocs": "enviar archivos",
				"success": "Documentos agregados exitosamente"
			},
			"deletedocsmodal": {
				"title": "Eliminar documentos",
				"message": "¿Está seguro de que desea eliminar documentos de la carpeta \"{{path}}\"?"
			}
		},
		"activatelicensemodal": {
			"text": {
				"total": "Número total de empresas: {{total}}",
				"totalConsumed": "Número total de empresas ya agregadas: {{totalConsumed}}",
				"totalToConsume": "Número total de empresas que aún puedes agregar: {{totalToConsume}}"
			},
			"types": {
				"0": {
					"title": "Activar expediente digital en la empresa \"{{nEmpresa}} - {{nomeEmpresa}}\"",
					"license1": "Si hace clic en \"Sí\", el sistema agregará una nueva empresa a su licencia de archivo digital y no será posible revertir la situación más adelante.",
					"license2": "En este momento, aún puedes agregar {{totalToConsume}} empresas a tu licencia de archivo digital.",
					"dadosLicenca": "Datos de licencia de archivos digitales",
					"limitReached": "No puedes agregar más empresas a tu licencia de archivo digital, porque ya tienes registradas las empresas \"{{consumed}}\" \"{{total}}\".",
					"errorCGOnMode": "No se puede activar el archivo digital, porque se trata de una empresa de CGOn."
				},
				"1": {
					"title": "Activar conexión de cliente en la empresa \"{{nCompany}} - {{nomeEmpresa}}\"",
					"license1": "Si hace clic en \"Sí\", el sistema agregará una nueva empresa a su licencia de conexión de cliente y no será posible revertir la situación más adelante.",
					"license2": "En este momento, aún puede agregar {{totalToConsume}} empresas a su licencia de conexión de cliente.",
					"dadosLicenca": "Detalles de la licencia de conexión del cliente",
					"limitReached": "No puede agregar más empresas a su licencia de conexión de cliente porque ya tiene registradas las empresas \"{{consumed}}\" \"{{total}}\".",
					"errorCGOnMode": "No se puede activar la conexión de cliente porque se trata de una empresa de CGOn."
				}
			}
		},
		"atribuirtipodocumentomodal": {
			"title": "Indique el tipo de documento que desea asignar",
			"label": "Tipo de documento",
			"required": "Debes indicar el tipo de documento que deseas.",
			"success": "Tipo de documento asignado correctamente."
		},
		"checkfolderscreatedmodal": {
			"title": "Inicializando archivo digital",
			"message": "Espere mientras se inicializa el archivo digital."
		},
		"copiardocmodal": {
			"title": "Copiar documento",
			"body": "¿Está seguro de que desea crear una copia del documento \"{{name}}\"?",
			"success": "Copia del documento creada exitosamente.<br/>Nombre de archivo: \"{{name}}\"."
		},
		"novacontaccmodal": {
			"title": "Crear nueva cuenta corriente",
			"fields": {
				"radical": "Radical",
				"nConta": "Número de cuenta para crear",
				"nome": "Nombre"
			},
			"successModal": {
				"title": "Ver el perfil de {{type}}",
				"message": "{{type}} cuenta actual creada correctamente.<br/>¿Quieres editar su cuenta?",
				"types": {
					"fornecedor": "proveedor",
					"cliente": "cliente",
					"outroDevedorCredor": "otro deudor/acreedor"
				}
			}
		},
		"removepagesmodal": {
			"title": "Indique las páginas que desea eliminar",
			"label": "Página(s) para eliminar",
			"help1": "Si desea eliminar más de una página, sepárelas con una coma o indique un rango.",
			"help2": "Por ejemplo: 1,4,5: elimina las páginas 1, 4 y 5.",
			"help3": "Por ejemplo: 6-10: elimina las páginas 6, 7, 8, 9 y 10.",
			"success": "Páginas eliminadas correctamente."
		},
		"separardocmodal": {
			"title": "documento separado",
			"body1": "¿Quieres conservar el documento original?",
			"success": "¡Documento separado exitosamente!"
		},
		"unirdocsmodal": {
			"title": "Fusionar documentos",
			"body1": "Al realizar esta operación fusionarás el(los) documento(s)",
			"body2": "al documento",
			"body3": "¿Estás seguro de que quieres continuar?",
			"success": "Los documentos se fusionaron exitosamente."
		},
		"predefinidosconfig": {
			"title": "Configuraciones predefinidas por NIF/Tipo de documento",
			"fatura": "Factura",
			"faturaSimplificada": "factura simplificada",
			"faturaRecibo": "Factura/Recibo",
			"notaDebito": "nota de débito",
			"notaCredito": "nota de crédito",
			"vendaDinheiro": "Vender por dinero en efectivo",
			"talaoVenda": "recibo de venta",
			"talaoDevolucao": "Resguardo de devolución",
			"alienacaoAtivos": "Enajenación de activos",
			"devolucaoAtivos": "Devolución de activos",
			"premio": "Premio o recibo de premio",
			"estorno": "Reembolso o recibo de reembolso",
			"imputacaoSeguradoras": "Imputación a coaseguradores",
			"imputacaoSegLider": "Imputación a co-sec.",
			"recibo": "Recibo",
			"pagamentoEstado": "Pago al estado",
			"levantamento": "Encuesta",
			"pagamento": "Pago",
			"deposito": "Depósito",
			"cheque": "Controlar",
			"transferencia": "Transferir",
			"efaturainfo": "Este valor se obtuvo de la configuración de eFatura para este contribuyente.",
			"success": "Configuración guardada exitosamente.",
			"predefnotacredito": "El valor predeterminado para las notas de crédito no debe ser el mismo que para otros tipos de documentos.",
			"warningEFatura": "*Si desea publicar documentos a través de eFatura, no necesita configurar los predefinidos",
			"lancfaturahint": "Haga clic para obtener ayuda con esta función",
			"lancfaturahinttitle": "Lanzamientos automáticos por Robot",
			"lancfaturahintinfo": "Si activa las publicaciones de Robot en esta entidad, el sistema publicará los documentos en contabilidad automáticamente después de que se agreguen.",
			"nif": "Contribuyente",
			"nomeFornecedor": "Nombre del proveedor"
		},
		"configs": {
			"tabs": {
				"defgerais": {
					"title": "Configuraciones generales",
					"carddefgerais": "Configuraciones generales",
					"cardverbete": "Entrada de documentos",
					"cardconfigpastas": "Configuración de carpetas"
				},
				"efatura": {
					"title": "factura electrónica",
					"comprasefatura": "Compras con factura electrónica",
					"defoticocaracteres": "Configuración de reconocimiento óptico de caracteres (OCR)"
				},
				"lancautorobo": {
					"title": "Lanzamientos automáticos por Robot",
					"lancaDocsAuto": "Realiza publicaciones de Robot para todos los documentos automáticamente después de agregarlos.",
					"origemPeriodoLancAuto": "Origen del periodo en lanzamientos por Robot o por sugerencia",
					"tabelaempresa": "tabla de empresa",
					"datadoc": "Fecha del documento",
					"message1": "Si activa las publicaciones de Robot para todos los documentos, el sistema publicará los documentos en contabilidad automáticamente después de agregarlos.",
					"message2": "El sistema podrá contabilizar documentos con código de barras o que existan en eFatura y para los que encuentre un modelo de contabilización previamente realizado en contabilidad del NIF del documento y con los mismos tipos de IVA.",
					"message3": "A medida que publica documentos contables digitales, el sistema aprende a realizar los asientos por usted.",
					"message4": "También puede activar esta funcionalidad entidad por entidad en la cuadrícula de documentos digitales haciendo clic con el botón derecho del mouse en la línea de entidad deseada y luego en configuración de entidad."
				},
				"opcoesocr": {
					"titleSimple": "Opciones de reconocimiento óptico de caracteres",
					"title": "Opciones de OCR (reconocimiento óptico de caracteres)",
					"cardtitle": "Contribuyentes para separar documentos mediante código QR (por ejemplo, facturas EDP o Via Verde)",
					"removePaginasBrancoAuto": "Elimina automáticamente las páginas en blanco (esta función solo debe usarse si utiliza un escáner recomendado por CentralGest)",
					"table": {
						"nif": "número de contribuyente",
						"descricao": "Nombre",
						"addedpviaverde": "Añadir EDP/Vía Verde",
						"addedp": "Añadir PED",
						"addviaverde": "Añadir Vía Verde"
					}
				}
			},
			"title": "Configuración de contabilidad digital",
			"success": "Configuración guardada exitosamente",
			"fields": {
				"anexaVerbete": "Agregar entrada a documentos escaneados",
				"dataLancamIgualDataDoc": "La fecha de publicación de la colección es igual a la fecha del documento al obtener los datos del documento.",
				"abreSempreVisualizador": "Al abrir una colección muestra contabilidad digital",
				"ordenacaoVisualizador": "Orden de documentos",
				"usaEFaturaOCR": "Utiliza datos de eFatura (obtiene mejores resultados al completar totales)",
				"recolhaAnexaAuto": "Al recoger, automáticamente adjunta un documento.",
				"pasta": "Carpeta",
				"nomePasta": "Nombre de la carpeta",
				"nDiario": "A diario",
				"nDescritivo": "Descriptivo",
				"visivel": "Visible",
				"searchEFaturaSoNdocParcial": "Al buscar documentos en modo eFatura solo muestra posibles documentos con un número de documento similar.",
				"searchEFaturaSoMesmoDiario": "Al buscar documentos en modo eFatura solo muestra posibles documentos con el mismo diario entre carpeta predefinida y de documentos",
				"entidadeHabitual": "entidad habitual",
				"nCodRepcc": "Distribución c.",
				"usaDiarioConfigSugestLancam": "En la sugerencia de lanzamiento, utilice diarios configurados en las carpetas.",
				"eFaturaOCR": "Utilice eFatura OCR",
				"fazOCR": "Realiza OCR",
				"sugereBanking": "Sugerir en banca",
				"nDocfaFT": "factura de crédito",
				"nDocfaFR": "Factura/Recibo",
				"nDocfaNC": "nota de crédito",
				"nDocfaNCPagaNoAto": "Número de crédito de compra pagado inmediatamente",
				"nCCusto": "centro de costos",
				"nRefProcesso": "Proceso"
			},
			"actions": {
				"obterChaveSincro": "Obtenga la clave de sincronización de conexión del cliente de esta empresa",
				"ativarClientConnect": "Activar conexión de cliente en esta empresa"
			},
			"messages": {
				"clientConnectAlreadyActivated": "Esta empresa ya tiene la conexión de cliente activa.",
				"activatedClientConnect": "Conexión de cliente activada exitosamente en su empresa",
				"opcocraddednif": "Número de contribuyente agregado a la lista exitosamente",
				"opcocraddednifedp": "Número de contribuyentes de EDP agregados exitosamente a la lista",
				"opcocraddednifviaverde": "Número de contribuyente de Via Verde agregado exitosamente a la lista",
				"opcocranifalreadyexists": "El número de contribuyente ya existe en la lista.",
				"repccinfo": "Código de distribución del centro de costos (se puede usar para iniciar automáticamente un centro de costos según una sugerencia de lanzamiento de Robot)"
			},
			"configPastas": {
				"title": "Configuración de carpetas"
			},
			"licencas": {
				"message": "'Si hace clic en \"Sí\", el sistema agregará una nueva empresa a su licencia de contabilidad digital de conexión de cliente y no será posible revertir la situación más adelante.<br/>En este momento, aún puede agregar a su cliente conectar empresas con licencia de contabilidad digital {{nCompaniesAdd}}."
			},
			"synchronizationKey": {
				"title": "Clave de sincronización para Client Connect",
				"key": "Tecla de sincronización"
			},
			"tipoOrdenacaoVisualizador": {
				"0": "No. de contribuyente |",
				"1": "Fecha |",
				"2": "fecha de recibo",
				"3": "Nombre |",
				"4": "Fecha |",
				"5": "Numeración de archivos",
				"nifData": "No. de contribuyente |",
				"dataNif": "Fecha |",
				"dataRececao": "fecha de recibo",
				"nomeData": "Nombre |",
				"dataNome": "Fecha |",
				"nomeFicheiro": "Numeración de archivos"
			}
		}
	},
	"artars": {
		"title_detail": "Artículo de almacén",
		"title_new": "Nuevo artículo de almacén",
		"title_plural": "Artículos de almacén",
		"pesquisa": "buscar",
		"saved": "Artículo del almacén, almacenado correctamente.",
		"error": "Artículo del almacén, no se puede almacenar.",
		"deleted": "Artículo del almacén, eliminado correctamente.",
		"fields": {
			"nArtigo": "Artículo no.",
			"nArmazem": "Almacén nro.",
			"stockMinimo": "Stock mínimo",
			"stockMaxIMO": "Stock máximo",
			"stockReposic": "Reposición de stock",
			"qtdMinEncome": "Cant. mín.",
			"qtdMaxEncome": "Cantidad máxima.",
			"qtdRepEncome": "Cant. representante",
			"despPorEncom": "Gastos",
			"txCustoPosse": "Tasa de costo de propiedad",
			"nClifoHabitual": "Número de cliph habitual",
			"nDiasValidade": "Número de días de validez",
			"nSectorLocal": "Sector local no.",
			"qtdLimArmazenamento": "Cantidad límite.",
			"localExcedentes": "Ubicación excedente",
			"stockAlerta": "Alerta de stock"
		}
	},
	"artigoclasses": {
		"title_detail": "Clase de artículo {{id}}",
		"title_new": "Nueva clase de artículo",
		"title_plural": "Clases de artículos",
		"pesquisa": "buscar",
		"saved": "Clase de artículo {{id}}, guardado correctamente.",
		"error": "Clase de artículo {{id}}, no se puede guardar.",
		"fields": {
			"classe": "Código.",
			"classePlaceholder": "Código de clase de artículo",
			"descricao": "Descripción",
			"descricaoPlaceholder": "Descripción de la clase de artículo"
		}
	},
	"artigos": {
		"title_detail": "Artículo {{id}}",
		"title_new": "Nuevo artículo",
		"title_plural": "Artículos",
		"pesquisa": "buscar",
		"saved": "Artículo {{id}}, guardado exitosamente",
		"error": "El artículo {{id}}, no se puede guardar.",
		"deleted": "Artículo {{id}}, eliminado exitosamente",
		"texto_livre": "Texto libre",
		"fields": {
			"nArtigo": "Código del artículo",
			"nArtigoPlaceholder": "El código del artículo, si no se completa, se generará automáticamente.",
			"nome": "Nombre del artículo",
			"nomePlaceholder": "Nombre del artículo",
			"nFamilia": "familia no.",
			"nFamiliaPlaceholder": "codigo familiar",
			"nomeFamilia": "Familia",
			"nomeFamiliaPlaceholder": "Apellido",
			"nTpArt": "Tipo",
			"nTpArtPlaceholder": "Tipo de artículo",
			"nomeTpArt": "Tipo de artículo",
			"nomeTpArtPlaceholder": "Nombre del tipo de artículo",
			"nGrFamilia": "familia gr.",
			"nGrFamiliaPlaceholder": "gran familia",
			"nomeGrFamilia": "familia gr.",
			"nomeGrFamiliaPlaceholder": "gran apellido",
			"nSubFa": "Subfamilia",
			"nSubFaPlaceholder": "subfamilia",
			"nomeSubFa": "Subfamilia",
			"nomeSubFaPlaceholder": "Nombre de subfamilia",
			"nomeUnidadeMov": "Unidad",
			"nomeUnidadeMovPlaceholder": "unidad de movimiento",
			"qtdPorUnid1": "Cant.",
			"qtdPorUnid1Placeholder": "Cantidad por unidad",
			"movimStock": "Mover acciones",
			"movimStockPlaceholder": "Mover acciones",
			"codvaloriz": "Valuación",
			"codvalorizPlaceholder": "Valuación",
			"nomeIvaVenda": "venta IVA",
			"nomeIvaVendaPlaceholder": "Nombre del IVA",
			"taxaIvaVenda": "tipo de IVA",
			"taxaIvaVendaPlaceholder": "tipo de IVA",
			"nomeIvaCompra": "compra con IVA",
			"nomeIvaCompraPlaceholder": "Nombre IVA compra",
			"taxaIvaCompra": "Tasa de IVA de compra",
			"taxaIvaCompraPlaceholder": "Tasa de IVA de compra",
			"precoSemIva": "Precio sin IVA",
			"precoSemIvaPlaceholder": "Precio sin IVA",
			"precoComIva": "Precio con IVA",
			"precoComIvaPlaceholder": "Precio con IVA",
			"qtdTotal": "Cantidad de existencias",
			"qtdTotalPlaceholder": "Cantidad total de existencias",
			"temRetencaoNaFonte": "Tiene retención de impuestos",
			"ncontapoc": "número de cuenta de ventas",
			"nContaPocCompra": "Número de cuenta de compra",
			"grupoartigo": "Propiedad",
			"artDesactivado": "Artículo desactivado",
			"artBloqueado": "Artículo bloqueado",
			"categoria": "Categoría",
			"precoBaseCusto": "precio base de costo",
			"precoBaseCustoPlaceholder": "precio base de costo",
			"nArmazem": "código de almacén",
			"nomeArmazem": "Nombre del almacén",
			"qtd": "Stock actual",
			"qtdStockPotencial": "Stock potencial",
			"contaExt": "cuenta externa",
			"artigoExt": "Código de artículo externo",
			"descContaExt": "Descripción de la cuenta externa",
			"precoCompUlt": "Valor de la última compra",
			"precoCompUltPlaceholder": "Valor de la última compra",
			"prVendaUlti": "Valor de la última venta",
			"prVendaUltiPlaceholder": "Valor de la última venta",
			"dataPrCompra": "Última fecha de compra",
			"dataPrVendUl": "Última fecha de venta",
			"naoPermiteExistNeg": "No permite stock negativo",
			"temLote": "hay mucho",
			"temDataValidade": "Fecha de expiración",
			"diasValidade": "Número de días de validez",
			"diasValidadePlaceholder": "Número de días de validez",
			"segNArtigo": "códigos de barras",
			"segNome": "Descripción alternativa",
			"qtdStockEncCliente": "Orden",
			"qtdStockEncForn": "Orden",
			"qtdTotalStockPotencial": "Stock potencial",
			"qtdTotalStockEncCliente": "Orden",
			"qtdTotalStockEncForn": "Orden",
			"taxaIvaTip": "{{fee}}% de tarifa",
			"qtdStockMinimo": "Stock mínimo",
			"qtdStockMaximo": "Stock máximo",
			"qtdAdd": "Cant."
		},
		"btn": {
			"buttonOptions": "Opciones",
			"verContas": "Muestra cuentas de ventas y compras.",
			"verPrecoBaseCusto": "Muestra el precio base de costos",
			"verCategoria": "Mostrar categoría",
			"esconderContas": "Ocultar cuentas",
			"mostraTodas": "Todo",
			"extratoArtigo": "Extracto del artículo",
			"verPropriedade": "Mostrar propiedad",
			"verApenasDesativado": "Ver solo discapacitados",
			"verApenasBloqueado": "Ver solo bloqueado"
		},
		"message": {
			"diferefamilia": "El número de cuenta de este artículo difiere del número de cuenta de la familia.",
			"mostraTodas": "Ver todas las cuentas de integración posibles",
			"contaPocFormadaVenda": "La cuenta de integración a formar posiblemente será {{nAccount}}.",
			"contaPocFormadaCompra": "La cuenta de integración a formar posiblemente será {{nAccount}}.",
			"contaPocFormadaMovimento": "La cuenta de integración a formar será {{nAccount}}.",
			"infofield": "Este campo es sólo para fines informativos.",
			"extratoArtigoTooltip": "Ver el extracto del artículo"
		},
		"actions": {
			"familias": "manutención familiar"
		},
		"datasource": {
			"grupoartigo": {
				"Geral": "General",
				"Mercadoria": "Mercancías",
				"Produto": "Producto",
				"MateriaPrima": "Materia prima",
				"Servico": "Servicio",
				"ServicoExterno": "Servicio externo",
				"Componente": "Componente",
				"Equipamento": "Equipo",
				"Ferramenta": "Herramienta",
				"MaoDeObra": "Mano de obra",
				"ArtigoReferencia": "Artículo de referencia",
				"MaterialSubsidiario": "Material subsidiario",
				"Embalagem": "Embalaje",
				"Veiculo": "Vehículo",
				"Motorista": "Conductor",
				"TipoTarefa": "Tipo de tarea",
				"TarefaEspecifica": "tarea especifica",
				"TarefaGeral": "tarea general",
				"Transportadora": "Transportador",
				"MaquinaMotriz": "maquina de conducir",
				"Configuracao": "Ajustes",
				"ArtigoTara": "artículo de tara",
				"Infraestrutura": "Infraestructura",
				"Utensilio": "Utensilio",
				"ParteInfraestrutura": "parte de infraestructura",
				"Adiantamentos": "Insinuaciones",
				"AlienacaoDeImobilizado": "Enajenación de activos fijos"
			}
		},
		"navigator": {
			"tabs": {
				"ficha": "Registro",
				"extrato": "Extracto",
				"anexos": "Adjuntos",
				"lotes": "Lotes",
				"estatistica": "Estadística"
			},
			"groups": {
				"indentificacao": "Identificación",
				"classificadores": "Clasificadores",
				"precos": "Precios",
				"stocks": "cepo",
				"classificacao": "Impuestos y otros",
				"clifo": "Cliente / Proveedor",
				"contab": "Contabilidad"
			}
		},
		"text": {
			"detalheArmazem": "Detalle por almacén",
			"tipoprod": "Tipo de producto (Inventario): {{cod}} - {{desc}}",
			"codBarras": "EAN13 - Identificador único leído mediante un escáner apto para lectura.",
			"artigoBloqueado": "Normalmente se utiliza para bloquear temporalmente.",
			"artigoDesativado": "Normalmente se utiliza para bloquear permanentemente.",
			"familia": "Clasificador que permite inicializar varios campos en el formulario del artículo.",
			"stockPotencial": "Se obtiene de la siguiente manera: Stock - Enc.",
			"lotesBloqueadosMovStock": "Muchos artículos bloqueados debido a que el stock no se mueve",
			"lotesBloqueadosLicencaPremium": "Lote bloqueado debido a que el artículo no se mueve en stock",
			"fileNamePicture": "Imagen_ {{nArtículo}}",
			"deletePicture": "Eliminar imagen"
		}
	},
	"assistenteconfigportais": {
		"pages": {
			"empresa": "Configuración de la empresa",
			"utilizador": "Configuración de usuario",
			"empregado": "Configuración de empleados",
			"email": "Configuración de correo electrónico por empresa"
		},
		"fields": {
			"portal": "Selecciona el portal",
			"operacao": "Seleccione la operación",
			"novoutilizador": "Nuevo usuario",
			"nomeutilizador": "Nombre de usuario",
			"nome": "Nombre",
			"apelido": "Apellido",
			"email": "Correo electrónico",
			"enviaEmail": "Enviar correo electrónico con contraseña",
			"codempregado": "código de empleado",
			"nomeempregado": "Nombre del empleado",
			"addempregado": "Empleado asociado",
			"contaemail": "Cuenta",
			"password": "Contraseña",
			"smtp": "SMTP",
			"portasmtp": "Puerto SMTP",
			"ssl": "SSL",
			"empresa": "Empresa",
			"addempresa": "Añadir empresa",
			"removeempresa": "Eliminar empresa",
			"utilizador": "Usuario"
		},
		"opcoes": {
			"selecionarportal": "Seleccione un portal",
			"operacoesportal": "Seleccione una operación"
		},
		"outros": {
			"empresa": "Empresa",
			"utilizador": "Usuario",
			"gabinete": "Gabinete"
		},
		"info": {
			"naocriautilizador": "No creará un nuevo usuario",
			"savesucess": "Usuario creado exitosamente",
			"saveerror": "Error al crear usuario",
			"empresagabinetenaoselec": "¡No hay ninguna empresa de gabinetes seleccionada!",
			"empresaclientenaoselec": "¡No hay ninguna empresa cliente seleccionada!",
			"empresanaoselec": "¡No hay ninguna empresa para seleccionar o ninguna seleccionada!",
			"associateempsucess": "Empleado asociado exitosamente",
			"associateemperror": "Error al asociar empleado con usuario",
			"utilizadornaosel": "No hay ningún usuario seleccionado",
			"empresanaosel": "No hay ninguna empresa seleccionada",
			"empregadonaosel": "No hay empleados seleccionados",
			"naotemempresasparaconfigurar": "El usuario ya tiene el(los) empleado(s) asociado(s) a la(s) empresa(s)",
			"empresaClienteConfigurada": "La empresa cliente se ha configurado correctamente.",
			"empresaClienteConfigError": "Error al configurar la empresa cliente",
			"contaemailnula": "Se debe rellenar la cuenta de correo electrónico.",
			"passwordnula": "Se debe completar la contraseña de la cuenta de correo electrónico.",
			"smtpnulo": "Se debe completar SMTP",
			"portasmtpnula": "El puerto SMTP debe estar poblado",
			"emailconfigsavesucess": "Correo electrónico configurado correctamente",
			"emailconfigsaveerror": "Error al configurar el correo electrónico",
			"utilizadorexistentesuccess": "Usuario actualizado exitosamente"
		},
		"operations": {
			"myAccounting": {
				"addUser": "Agregar usuario al portal",
				"addEmpresa": "Haga que su empresa esté disponible en el portal",
				"associarEmpregado": "Asociar empleado con usuario en el portal"
			},
			"portalclientconnect": {
				"createUser": "Crear nuevo usuario en el portal",
				"addUser": "Agregar usuario existente al portal"
			}
		},
		"steps": {
			"stepGabinete": "Gabinete",
			"stepEmpresa": "Empresa",
			"stepUtilizador": "Usuario",
			"stepEmpregado": "Empleado",
			"stepEmail": "Correo electrónico"
		},
		"tiposEmail": {
			"gmail": "Gmail",
			"hotmail": "hotmail",
			"outro": "Otro"
		}
	},
	"atFRV": {
		"tabDocsEFaturaCaption": "Recibos verdes emitidos",
		"tabJaLancadosCaption": "Recibos ya ingresados ​​en contabilidad o ignorados",
		"viewDocModalTitle": "Vista de documento {{ndoc}}",
		"viewDocModalTitleSimple": "Vista previa del documento",
		"extratosModalTitle": "Estados de cuenta",
		"expandAllBtn": "Expandir todo",
		"cantSelectRowDueInvalidPredefinido": "No puede seleccionar el registro porque no está predefinido.",
		"cantSelectRowsDueInvalidPredefinido": "¡No se seleccionaron registros sin valores predeterminados!",
		"selectAll": "Seleccionar todo",
		"lancdocserie": "Lanzar documentos en serie.",
		"createDoc": "Crear documento",
		"messages": {
			"docIgnoradoSuccessMsg": "El recibo {{ndoc}} se ha agregado a la lista de ignorados.",
			"nifIgnoradoSuccessMsg": "{{nif}} - {{name}} se ha agregado a la lista de ignorados.",
			"docRemIgnoringSuccessMsg": "El recibo {{ndoc}} se ha eliminado de la lista de ignorados.",
			"docRemIgnoringFornecedorSuccessMsg": "El colaborador {{nif}} ha sido eliminado de la lista de ignorados."
		},
		"fields": {
			"documentoLancado": "Número de documento",
			"nifAdquirente": "NIF",
			"nomeAdquirente": "Nombre",
			"numDocumento": "Recibo no.",
			"dataEmissao": "Fecha de asunto",
			"tipoDocumento": "Tipo",
			"situacao": "Situación",
			"atividadePrestador": "Actividad del proveedor",
			"observacoes": "Observaciones",
			"importanciaRecebida": "Importancia recibida",
			"valorBase": "Valor base",
			"valorIVA": "valor del IVA",
			"valorIRS": "Valor del IRS",
			"valorImpostoSelo": "Valor del impuesto de timbre",
			"taxaIVA": "tipo de IVA",
			"preDefinido": "Predefinido",
			"numeroUnico": "Recibo no."
		},
		"btn": {
			"dropdown": {
				"extratosConta": "Estados de cuenta",
				"ignorarSempreTodosDocumentos": "Ignore siempre todos los recibos de este contribuyente",
				"ignorarDocumento": "ignorar este recibo",
				"nifConfig": "Configurar NIF",
				"gerarNovaCC": "Generar nueva cuenta corriente",
				"pdf": "Ver recibo en PDF"
			}
		},
		"tabJaLancadosCaptionContainer": {
			"table": {
				"badges": {
					"naoIgnorar": "no ignores",
					"remover": "Eliminar"
				}
			},
			"legends": {
				"documentoIgnorado": "Recibo ignorado",
				"documentoFornecedorIgnorado": "Documento del contribuyente ignorado"
			}
		},
		"formFields": {
			"dataDe": "Fecha de",
			"tipo": "Tipo",
			"situacao": "Situación",
			"placeholder": {
				"contribuinte": "número de contribuyente"
			}
		},
		"configModalTitle": "Configuración preestablecida",
		"reciboVerdeLabel": "",
		"novacontaccmodal": {
			"title": "Crear nueva cuenta corriente",
			"fields": {
				"radical": "Radical",
				"nConta": "Número de cuenta para crear",
				"nome": "Nombre"
			},
			"successModal": {
				"title": "Ver el perfil del proveedor",
				"message": "Cuenta actual del proveedor creada correctamente.<br/>¿Quieres editar la cuenta?"
			}
		},
		"modal": {
			"nifConfig": {
				"confPreDefinido": "Configuración predefinida por contribuyente",
				"contaCorrente": "Cuenta corriente a utilizar"
			}
		}
	},
	"ativos": {
		"title_detail": "Activo {{id}}",
		"title_new": "Nuevo activo",
		"title_plural": "Activos",
		"pesquisa": "buscar",
		"saved": "Activo {{id}}, guardado exitosamente",
		"error": "Activo {{id}}, no se puede guardar.",
		"deleted": "Activo {{id}}, eliminado exitosamente",
		"fields": {
			"codAtivo": "Código",
			"refExterna": "Ref. externa.",
			"designacao": "Designación",
			"estado": "Estado",
			"dataEstado": "Fecha de estado",
			"viatLigArtigo11DR": "Vehículo ligero - Artículo 11 del DR",
			"codAtivoPai": "Código activo principal",
			"documentoDeAquisicao": "Documento de adquisición",
			"origem": "Origen",
			"ignoraImpostosDiferidos": "Ignora la contabilidad de impuestos diferidos.",
			"header": {
				"estado": "Estado:",
				"valorAquisicao": "Valor de adquisición",
				"depAcumulada": "Dep. acumulada",
				"valorLiquido": "patrimonio neto",
				"diario": "A diario",
				"periodo": "Período",
				"ndocinterno": "Doc no.",
				"contabTaxaAmortiza": "Tasa de amortización contable",
				"fiscalTaxaAmortiza": "Tasa de amortización de impuestos",
				"contabBaseCalculo": "Base para el cálculo contable.",
				"fiscalBaseCalculo": "Base para el cálculo del impuesto"
			},
			"totals": {
				"estado": "Estado",
				"qtdEstado": "",
				"totalValAquisicao": "",
				"totalDepAcumulada": "",
				"totalValLiquido": ""
			}
		},
		"modal": {
			"btn": {
				"marcarGR": "para marcar",
				"desmarcarGR": "Desmarcar"
			},
			"abatedoativo": "Cancelación de activos",
			"registodevendaativo": "Registro de venta de activos",
			"radicalAbate": "Número de cuenta de sacrificio",
			"dataAbate": "fecha de sacrificio",
			"grMarcar": "Marcar activo como reparación importante",
			"grDesmarcar": "Deseleccionar un activo para que no sea una reparación importante"
		},
		"header": {
			"estado": "Estado:",
			"valorAquisicao": "Valor de compra:",
			"depAcumulada": "Depósito acumulado:",
			"valorLiquido": "Patrimonio neto:"
		},
		"estado": {
			"0": "Normal",
			"1": "Derribado ({{fecha}})",
			"2": "Vendido ({{fecha}})",
			"3": "Dividir ({{fecha}})"
		},
		"titles": {
			"dadosDoAtivo": "Datos de activos",
			"dadosDoFornecedor": "Detalles del proveedor",
			"dadosContabilisticos": "Datos contables",
			"dadosFiscais": "Datos fiscales",
			"contas": "Cuentas",
			"outrosDados": "Otros datos",
			"impostosDiferidos": "Impuestos diferidos",
			"classificadores": "Clasificadores",
			"grandeReparacao": "Activo principal"
		},
		"toolbar": {
			"acontecimentos": "Operaciones",
			"dropdownDeprPlan": "Depreciación y planificación",
			"dropdownRegContab": "Contabilidad",
			"tooltipAtivoAbateVendido": "Activo ya castigado/vendido",
			"dropdownConsulta": "Consulta",
			"tooltipAtivoEstadoNormal": "El activo debe estar en estado normal.",
			"tooltipOpJustoValorABP": "El activo debe ser producido biológicamente y en estado normal."
		},
		"actions": {
			"abate": "Registrar matanza",
			"venda": "Registrar venta",
			"planning": "Planificación",
			"depreciacoes": "Depreciaciones",
			"depreciacoesDtl": "Depreciaciones detalladas",
			"regcontab": "Registros contables",
			"mudarcontaativo": "Cambiar cuenta de activos",
			"mudarcontadepexerc": "Cambiar departamento de cuenta.",
			"mudarcontadepacumu": "Cambiar departamento de cuenta.",
			"mudastaxasdep": "Cambiar las tasas de depreciación",
			"notacredito": "Descuento (nota de crédito)",
			"divisao": "División de activos",
			"ajustedepantigas": "Ajustar la depreciación acumulada en años anteriores.",
			"imprimeativo": "Imprimir hoja de activos",
			"reavaliacoesNaoLegais": "Revalorizaciones no legales",
			"aumentoifrs16": "Incremento de valor en NIIF16",
			"opjustovalorabp": "Opción de valor razonable en ABP",
			"grandereparacao": "Reparación mayor (Marcar/Desmarcar)"
		},
		"steps": {
			"aquisicaoAtivo": "Adquisición de activos",
			"dadosContabilisticosDadosFiscais": "Datos contables y datos fiscales.",
			"contasPlanoOutrosDados": "Cuentas del plan y otros datos",
			"classificadoresDoAtivo": "Clasificadores de activos",
			"planningAmortizacoes": "Planificación de amortización",
			"ativoDepreciacoesDetalhadas": "Depreciaciones detalladas",
			"ativoDepreciacoes": "Depreciaciones",
			"pesquisaContabilidade": "Registros contables"
		},
		"enum": {
			"origem": {
				"0": "Adquisición",
				"1": "Conversión",
				"2": "Contabilidad",
				"3": "Importación de Excel",
				"4": "Importación manual",
				"5": "División de activos",
				"6": "Revalorizaciones no legales",
				"7": "Incremento de valor NIIF 16",
				"8": "Operación de valor razonable",
				"9": "Inversiones en curso"
			}
		},
		"table": {
			"dadosContabilisticos": "Datos contables",
			"dadosFiscais": "Datos fiscales",
			"depreciacaocontabilistica": "Depreciación contable",
			"depreciacaofiscalaceite": "Se acepta depreciación fiscal",
			"taxascontab": "Honorarios de contabilidad",
			"taxasfiscal": "Tasas impositivas",
			"limiteminimofiscal": "Límite mínimo de impuestos",
			"limitemaximofiscal": "Límite máximo de impuestos",
			"perdasimparidadecontabilisticas": "Pérdidas por deterioro contable",
			"perdasimparidadefiscais": "Pérdidas por deterioro fiscal",
			"valoresperdidosfiscais": "Valores perdidos de impuestos",
			"taxasperdidasfiscais": "Tarifas de impuestos perdidos",
			"depreciacoesnaoaceites": "Depreciación no aceptada",
			"imparidadesnaoaceites": "Deficiencias no aceptadas",
			"depreciacoesarecuperar": "Depreciación a recuperar",
			"depreciacoesrecuperadas": "Depreciaciones recuperadas",
			"imparidadesrecuperadasnoperiodo": "Deterioros recuperados en el periodo",
			"acrescemodelo22": "Se suma al modelo 22.",
			"deduzmodelo22": "Deduce al modelo 22",
			"valortributavelativosimpostosdiferidos": "Valor imponible de los activos por impuestos diferidos",
			"valortributaveldopassivoimpostosdiferidos": "Valor imponible del pasivo por impuesto diferido",
			"valornaoaceitefiscalmenteporreavaliacoes": "Valor no aceptado a efectos fiscales por revaluaciones",
			"impostosdiferidos": "Impuestos diferidos",
			"rendimentos": "Ingresos por subsidios",
			"impostosdifSubsidios": "Impuestos de subsidio diferidos",
			"tipoOperacao": "Operación",
			"ndiario": "A diario",
			"ndocInterno": "Doc no.",
			"dataDoc": "fecha de lanzamiento",
			"dataDocExterno": "Fecha del doctor.",
			"periodo": "Período",
			"depreciacoes": "Depreciaciones",
			"body": {
				"mesde": "Mes de",
				"mesate": "Mes hasta",
				"anoEconomico": "Ejercicio económico",
				"valorAquisicao": "Valor de adquisición",
				"valorResidual": "valor residual",
				"taxa": "Tasa",
				"redTaxa": "%rojo.",
				"depreciacao": "Depreciación",
				"depreciacoes": {
					"anodoubledot": "Año:",
					"contabtaxamedia": "Tasa contable promedio",
					"fiscaltaxamedia": "Tasa impositiva promedio",
					"periodo": "Período",
					"periodoano": "Período del año",
					"periodomes": "Período del mes",
					"depmes": "en el mes",
					"depmesaquisicao": "En el mes de adquisición.",
					"depmesreavalicao": "En el mes de revalorización.",
					"depacumano": "en el año",
					"depacumant": "En el año anterior",
					"taxa": "Tasa",
					"percreducao": "% de reducción",
					"reforco": "Reforzamiento",
					"taxairc": "tasa de IRC"
				}
			}
		},
		"configuracoes": {
			"tab": {
				"title": {
					"aquisicao": "Adquisición",
					"impostosDiferidos": "Impuestos diferidos",
					"depreciacoes": "Depreciaciones",
					"ajustes": "Ajustes",
					"errosTitle": "Errores de depreciación de años anteriores",
					"vendaTitle1": "factura de venta",
					"vendaTitle2": "Valor bruto y depreciación acumulada",
					"abate": "Sacrificio",
					"contabilidadeanaliticatitle": "Radicales sujetos a contabilidad analítica.",
					"reavaliacoesnaolegais": "Revalorizaciones no legales",
					"impdifreavaliacoesnaolegais": "Impuestos diferidos por revalorizaciones no estatutarias",
					"operacoesjustovalor": "Transacciones de valor razonable",
					"impdifoperacaojustovalor": "Impuestos diferidos sobre transacciones de valor razonable"
				},
				"diversos": "Varios",
				"contasDepreciacao": "Cuentas de depreciación",
				"aquisicao": "Adquisición",
				"depreciacoes": "Depreciaciones",
				"ajustes": "Ajustes",
				"erros": "Errores",
				"venda": "Venta",
				"abate": "Sacrificio",
				"regraAbateVenda": "Normas de sacrificio/venta",
				"contabilidadeAnalitica": "contabilidad analítica",
				"classificadores": "Clasificadores",
				"reavaliacoesnaolegais": "Revalorizaciones no legales",
				"ativosbioprod": "Activos de producción biológica (ABP)",
				"contasInvestCurso": "Cuentas de inversión en curso"
			},
			"btn": {
				"adicionar": "para agregar",
				"guardar": "Ahorrar",
				"cancelar": "Cancelar"
			},
			"messages": {
				"realizadacomsucesso": "Operación realizada con éxito.",
				"grcodativoempty": "El código.",
				"radicalRequired": "El radical no puede estar vacío.",
				"radicalInvalid": "Radical solo permite caracteres numéricos.",
				"radicalJaExistente": "El radical insertado ya está configurado.",
				"contaImobilizadoRequired": "El radical de la cuenta de activos no puede estar vacío.",
				"contaImobilizadoInvalid": "La raíz de la cuenta de activos solo permite caracteres numéricos.",
				"contaImobilizadoJaExiste": "La cuenta de activo ingresada ya existe.",
				"pocInvalid": "La cuenta de depreciación o amortización no existe.",
				"successfullySaved": "Los cambios se guardaron correctamente.",
				"ativoguardadosuccess": "Activo guardado exitosamente.",
				"contaTransicaoInvalid": "La cuenta de transición no es válida."
			},
			"radiooptions": {
				"ativoperiodo": "Publica un documento para cada activo, en cada período.",
				"ativoperiodoult": "Lanza un documento para cada activo, en un solo período (último)",
				"ativosperiodo": "Lanza un documento con todos los activos, en cada período.",
				"ativosperiodoult": "Lanzar un documento con todos los activos, en un solo período (último)"
			},
			"trataAtivosNoCentralGest": "Trata los activos en CentralGest",
			"radicaisContasImobilizado": "Radicales activos",
			"radicalIVA": "IVA radical",
			"taxaIRCEstimada": "Tasa IRC estimada",
			"codDiario": "código diario",
			"codDescritivo": "código descriptivo",
			"radicaisFornecedorAquisicao": "Radicales proveedores",
			"impostosDiferidosContaPassivo": "código de cuenta pasiva",
			"impostosDiferidosContaAjustamentos": "Código de cuenta de ajustes",
			"impostosDiferidosContaImpostoDiferido": "Código de cuenta imp.",
			"mostraContabilidadeConversao": "En mantenimiento de activos, muestra las relaciones de conversión de la estructura antigua.",
			"depreciacaoDataEncerramento": "fecha de cierre",
			"contaContrapartida": "Código de cuenta de compensación",
			"contaMaisValia": "Cuenta de plusvalía",
			"contaMenosValia": "cuenta de pérdidas de capital",
			"vendaRadicalMaisValiaFatura": "Valor añadido radical",
			"vendaRadicalMenosValiaFatura": "Valor negativo radical",
			"abateRadical": "Masacre radical",
			"ccusto": "Código C. Costo",
			"zona": "Zona",
			"departamento": "Departamento",
			"subdepartamento": "Subdepartamento",
			"tipoartigo": "Tipo de artículo",
			"grandefamilia": "gran familia",
			"familia": "Familia",
			"subfamilia": "Subfamilia",
			"contabilidade": "Contabilidad",
			"classe": "Clase",
			"categoria": "Categoría",
			"vendedor": "Vendedor",
			"processo": "Proceso",
			"dataLimiteParaCalculoContabilisticoIgualFiscalTrue": "Fecha límite para el cálculo contable, igual a la fecha impositiva (último día del mes anterior)",
			"dataLimiteParaCalculoContabilisticoIgualFiscalFalse": "La fecha límite para el cálculo contable es el día anterior al sacrificio/venta.",
			"contabilidadeAnaliticaUsa": "Utilice la contabilidad analítica en la integración de activos",
			"contabilidadeAnaliticaMascara": "Máscara de análisis",
			"analiticaContaContraPartida": "cuenta de compensación",
			"radicaisSujeitosList": "Lista de radicales",
			"novoRadicalSujeito": "Nuevo radical",
			"contaImobilizado": "cuenta de activo fijo",
			"contaDepreciacao": "cuenta de depreciación",
			"contaDepreciacaoAcumulada": "Cuenta de depreciación acumulada",
			"infodep": "Ya existen depreciaciones realizadas para el año en curso.",
			"depreciacaoSoRegistaNaContabilidadeEmPeriodoAposAquisicao": "Sólo se registra en Contabilidad en un período igual o mayor a la adquisición",
			"depreciacoesDoAnoPesquisaPeloEstadoDepreciacoes": "Buscar depreciaciones anuales por estado de depreciación",
			"nContaContrapartida": "Número de cuenta de compensación",
			"nContaPassivo": "Número de cuenta de responsabilidad",
			"nContaAjustamentos": "Número de recuento de ajustes",
			"radicalabp": "ABC radical",
			"nContaParaReducao": "Número de cuenta para reducción",
			"nContaParaAumento": "Número de cuenta para aumento",
			"nContaAtivo": "Número de cuenta activa",
			"cursoRadicalInvestimentos": "Inversión radical en marcha",
			"cursoRadicalAtivo": "cuenta de activo radical",
			"cursoContaTransicao": "Cuenta de transición"
		},
		"verificacaoDeConsistenciaVenda": {
			"error": "En las cuentas existen documentos de venta de activos, ajenos a los activos.<br>Registrar las ventas respectivas.",
			"btn": {
				"redirectToVendaSerie": "Haga clic aquí para ser redirigido a ventas de activos."
			}
		},
		"listTotals": {
			"totalativos": "Número total de activos",
			"totalvalaquisicao": "Valor total de adquisición",
			"totaldepacumulada": "Total del depto.",
			"totalvalliquido": "Valor neto total",
			"resumo": "Resumen",
			"estado": "Estado"
		},
		"aumentoifrs16": {
			"title": "Incremento de valor en NIIF16",
			"initdescription": "[Aumento de valor] -",
			"info": "Esta acción permite llevar a cabo el aumento de la NIIF 16.",
			"data": "fecha de aumento",
			"valor": "Aumentar valor",
			"designacao": "Nueva designación de activos",
			"messages": {
				"descempty": "¡La designación no puede ser nula!",
				"invalidvalue": "¡El importe del aumento no es válido!",
				"invaliddate": "¡La fecha del aumento de valor debe ser mayor que el comienzo del año en curso!",
				"registedsuccess": "Incremento del valor registrado."
			}
		},
		"ativosopjustovalorabp": {
			"title": "Operación de valor razonable en activos de producción biológica (ABP)",
			"dataOperacao": "Fecha de operación",
			"valorOperacao": "Valor de operación",
			"temcertezaOPJustoValor": "¿Tiene intención de realizar la transacción de valor razonable?",
			"successOPJustoValor": "Transacción de valor razonable realizada con éxito"
		},
		"messages": {
			"existemmaisativoscontab": "El activo se originó en contabilidad.<br/>Al eliminarlo, eliminarás todos los demás que resultaron del mismo documento.<br/>¿Estás seguro de que deseas continuar?",
			"existemmaisativosdivididos": "¿Está seguro de que desea eliminar el activo?<br/>Este es un activo que resultó de una división.<br/>Al eliminar este activo se eliminarán todos los activos que resultaron de la división.<br/>El activo que se dividió volverá al estado normal."
		}
	},
	"ativosreavaliacoesnaolegais": {
		"titleList": "Revalorizaciones no legales",
		"title": "Reevaluación no legal",
		"ativoparcial": "Activo {{codAtivo}} parcialmente depreciado.",
		"ativototaldep": "Activo {{codAtivo}} totalmente depreciado.",
		"vidaUtilDefinidaEDiferente": "La vida útil definida es diferente a la del activo corriente.",
		"temACertezaQuePretendeRealizarAR": "¿Estás seguro de que quieres realizar la revalorización no legal del activo?",
		"success": "Reevaluación no jurídica realizada con éxito.",
		"jahadepreciacoes": "¡Ya hay depreciaciones realizadas en el año para el activo!",
		"fields": {
			"dataReavNaoLegal": "Fecha de reevaluación",
			"vidaUtilMeses": "Vida útil (meses)",
			"vidaUtilAnos": "Vida útil (años)",
			"aumentoValor": "Aumento de valor"
		}
	},
	"ativosAquisicao": {
		"title_detail": "Adquisición {{id}}",
		"title_new": "Nueva adquisición",
		"title_plural": "Adquisiciones",
		"pesquisa": "buscar",
		"saved": "Adquisición {{id}}, guardada exitosamente",
		"error": "Adquisición {{id}}, no se puede guardar.",
		"deleted": "Adquisición {{id}}, eliminada exitosamente",
		"warn": {
			"tooltipTaxa": "Tarifa diferente a la del decreto reglamentario!",
			"baseFiscDifContab": "Base para el cálculo del impuesto distinta a la contable",
			"confirmBaseFiscDifContab": "Base para el cálculo de impuestos distintos a los contables!<br>¿Quieres continuar?"
		},
		"fields": {
			"nMapaAmortizacao": "Número de tarjeta de amortización",
			"codAmortizacao": "Código de amortización",
			"dataAquisicao": "fecha de adquisición",
			"dataUtilizacao": "Fecha de uso",
			"quantidade": "Número de unidades (Cant.)",
			"grandeReparacao": "Es una gran reparación (de otro activo)",
			"usado": "Usado",
			"nDocExterno": "Doc no.",
			"dataDocExterno": "Fecha del doctor.",
			"nContaFornecedor": "Número de cuenta del proveedor",
			"nomeFornecedor": "Nombre del proveedor",
			"contabValorAquisicao": "Valor de adquisición",
			"contabTaxaAmortiza": "Tasa de amortización",
			"contabNumAnosUteis": "Número de años trabajados",
			"contabPercReducao": "Perc.",
			"contabValorResidual": "valor residual",
			"contabDataDispUso": "Fecha de disponibilidad para su uso.",
			"contabBaseCalculo": "Base para el cálculo",
			"contabMetodoCalculo": "Método de cálculo",
			"fiscalValorAquisicao": "Valor de adquisición",
			"fiscalTaxaAmortiza": "Tasa de amortización",
			"fiscalNumAnosUteis": "Número de años trabajados",
			"fiscalPercReducao": "Perc.",
			"fiscalValorResidual": "valor residual",
			"fiscalDataInicioUtil": "Fecha de inicio de uso",
			"fiscalBaseCalculo": "Base para el cálculo",
			"fiscalMetodoCalculo": "Método de cálculo",
			"nContaDoAtivo": "Número de cuenta de activo",
			"nContaAmortExerc": "Cuenta de amortización no.",
			"nContaAmortAcumu": "Cuenta de amortización no.",
			"nContaIVADedutivel": "Número de cuenta de IVA deducible",
			"codIVAAquisicao": "Código de IVA de compra",
			"iVADeduzido": "IVA deducido",
			"codRubOrcam": "Código de línea presupuestaria",
			"nCodRepCC": "Código de distribución",
			"nCCusto": "Centro de costos no.",
			"nZona": "Zona",
			"nDepto": "Departamento",
			"nSubde": "Subdepartamento",
			"tipoArtigo": "Tipo de artículo",
			"nGrFamilia": "gran familia",
			"nFamilia": "Familia",
			"nSubfa": "Subfamilia",
			"codContab": "código contable",
			"classe": "Clase",
			"categoria": "Categoría",
			"nVendedor": "Vendedor",
			"nRefProcesso": "Proceso",
			"nSeccao": "Sección"
		},
		"radioOptions": {
			"baseAnual": "Base anual",
			"inicioUtilizacao": "Desde el inicio del uso",
			"quotasConstantes": "Cuotas constantes",
			"quotasDecrescentes": "Participaciones decrecientes"
		},
		"tooltip": {
			"contabDataDispUso": "Introduce el día 1 del mes de inicio de uso."
		}
	},
	"ativosabate": {
		"ativosporabater": "Activos a amortizar",
		"dataAbate": "fecha de sacrificio",
		"radicalAbate": "Número de cuenta de sacrificio",
		"selecionarativos": "Seleccione los activos a cancelar",
		"message": {
			"success": "¡Masacre grabada!"
		},
		"table": {
			"codAtivo": "código activo",
			"designacao": "Designación",
			"refExterna": "Ref. externa.",
			"dataAquisicao": "Dt.",
			"valorAtivoBrutoContab": "Activos brutos",
			"valorDepreciacoesAcumuladasContab": "Dpto acumulado",
			"ativosporabater": "Activos a amortizar",
			"ativosaabater": "Activos a amortizar"
		},
		"btn": {
			"realizarabates": "Realizar matanzas"
		}
	},
	"ativosajustedepantigas": {
		"title": "Ajuste de depreciación acumulada en años anteriores",
		"temcerteza": "¿Está seguro de que desea registrar la depreciación faltante de años anteriores y registrar el valor como ajustes en Contabilidad?",
		"infoajustes": "Esta funcionalidad registrará la depreciación faltante de años anteriores y publicará el valor como ajustes en Contabilidad.",
		"message": {
			"success": "¡Ajuste de depreciación registrado exitosamente!",
			"haveDepEmFalta": "No faltan depreciaciones de años anteriores."
		},
		"fields": {
			"periodo": "Período de liberación para ajustes",
			"valoremfalta": "valor faltante"
		}
	},
	"ativosaquisicaocontab": {
		"prompt": {
			"exitTitle": "<i class='fa fa-triángulo de exclamación-advertencia de texto' estilo='color: naranja;'",
			"exitMessage": "Al hacer clic en Aceptar, saldrá sin guardar el documento y se eliminarán todos los cambios que haya realizado en este documento."
		},
		"header": {
			"fornecedor": "Proveedor",
			"totalIva": "Total documento IVA",
			"valorAssociado": "Valor de adquisición ya asociado con activos existentes.",
			"total": "Total"
		},
		"btn": {
			"pesquisa": "Buscar documento contable",
			"adiciona": "Añadir otro documento contable",
			"guardarAtivos": "Ahorrar"
		},
		"message": {
			"ativoguardado": "¡Recurso guardado exitosamente!",
			"confirmacao": "Registro de adquisición de activos",
			"desejaregistaraquisicao": "¿Quieres registrar la adquisición del activo?"
		},
		"table": {
			"nLanc": "nLanc",
			"nConta": "Nro. de cuenta",
			"nome": "Nombre",
			"valor": "Valor de adquisición",
			"valorCalculado": "Ya asociado",
			"valorCalculado2": "En nuevos activos",
			"periodo": "Período",
			"nDiario": "Número diario",
			"nDocInterno": "Doc no.",
			"dCDescription": "Deb./Cré",
			"nContaDebito": "Deuda",
			"nContaCredito": "Crédito",
			"descricao": "Descripción",
			"designacao": "Designación",
			"codAtivo": "Código.",
			"nContaDoAtivo": "Número de cuenta de activo",
			"contabValorAquisicao": "Valor"
		},
		"tooltip": {
			"novoativo": "Nuevo activo",
			"removedoc": "Quitar documento",
			"valorEsgotado": "¡El precio de compra de esta cuenta ya se ha agotado!",
			"removeAtivo": "Eliminar activo"
		},
		"error": {
			"contabValorAquisicaoSup": "¡El valor de adquisición no puede ser superior al valor restante!",
			"fiscalValorAquisicaoSup": "¡El valor fiscal de adquisición no puede ser superior al valor contable!",
			"invalidStep": "¡Hay campos que no están cumplimentados o contienen algún error!",
			"somainvalida": "La suma de los valores de adquisición {{valorAssociado}} no coincide con el total del doc.",
			"temdeselecionarum": "¡Tienes que seleccionar una adquisición!",
			"naoMesmosRadicais": "¡Las líneas seleccionadas deben tener el mismo radical!"
		},
		"half": {
			"contasAquisicao": "Cuentas de Adquisición",
			"ativos": "Activos"
		},
		"modal": {
			"ativoaquisicaocontabmodaltitle": "Investigación de adquisiciones en contabilidad",
			"saldosIniciais": "Buscar saldos iniciales"
		}
	},
	"ativosaquisicaoinvestemcurso": {
		"tabs": {
			"aquisicaoPorConta": "Adquisición a cuenta",
			"aquisicaoPorDocumento": "Adquisición por documento"
		},
		"infocontainvest": "Información de la cuenta de inversión actual",
		"contasAquisicaoCurso": "Cuentas de adquisición en curso",
		"fields": {
			"nconta": "Cuenta",
			"saldo": "Saldo {{año}}",
			"saldo2": "Saldo {{el próximo año}}",
			"transferido": "Transferido",
			"codAtivo": "Código.",
			"designacao": "Descripción",
			"nContaDoAtivo": "Número de cuenta de activo",
			"contabValorAquisicao": "Valor"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-triángulo de exclamación-advertencia de texto' estilo='color: naranja;'",
			"exitMessage": "Al hacer clic en Aceptar, saldrá sin guardar el documento y se eliminarán todos los cambios que haya realizado en este documento.",
			"desejaregistaraquisicao": "¿Quieres registrar la adquisición del activo?",
			"ativoguardado": "¡Los activos se guardaron correctamente!"
		},
		"messages": {
			"invalidDateNewAtivo": "La fecha de adquisición debe ser en el año: {{year}} o {{nextyear}}",
			"invalidvalue": "El valor no puede ser mayor que: {{value}}",
			"nContaEmpty": "¡Tienes que investigar y seleccionar una cuenta adquirente!",
			"saldoTranferidoCompleto": "Saldo de cuenta ya asociado a activos.",
			"desejaApagarDados": "Al cambiar de pestaña se cambiará el método de búsqueda y se borrarán los datos ingresados."
		},
		"tooltip": {
			"novoativo": "Nuevo activo",
			"removedoc": "Quitar documento",
			"valorEsgotado": "¡El precio de compra de esta cuenta ya se ha agotado!",
			"removeAtivo": "Eliminar activo"
		},
		"modal": {
			"title": "Búsqueda continua de cuentas de inversión",
			"fields": {
				"nConta": "Nro. de cuenta",
				"nome": "Nombre",
				"virtualDouble01": "Saldo {{año}}",
				"virtualDouble02": "Saldo {{el próximo año}}"
			}
		},
		"btns": {
			"search": "Buscar cuentas de inversión en curso"
		}
	},
	"ativosaquisicaoinvestemcursopordoc": {
		"infocontainvest": "Información de la cuenta de inversión actual por documento",
		"listadocs": "Listar documentos",
		"documentos": "Documentos",
		"ativos": "Activos",
		"ativosFieldset": "Lista de activos para crear",
		"fields": {
			"radical": "Radical",
			"valor": "Valor",
			"transferido": "Monto transferido",
			"codAtivo": "Código.",
			"designacao": "Descripción",
			"nContaDoAtivo": "Número de cuenta de activo",
			"contabValorAquisicao": "Valor"
		},
		"prompt": {
			"desejaregistaraquisicao": "¿Quieres registrar la adquisición del activo?",
			"ativoguardado": "¡Los activos se guardaron correctamente!"
		},
		"messages": {
			"invalidvalue": "El valor no puede ser mayor que: {{value}}",
			"docsEmpty": "¡Debes agregar al menos un documento!",
			"nextStepAtivosList": "Para agregar activos, haga clic en el botón '+' en la cuadrícula 'Lista de activos para crear'"
		},
		"tooltip": {
			"novoativo": "Nuevo activo",
			"removeAtivo": "Eliminar activo"
		},
		"btn": {
			"search": "Buscar documentos de inversión en curso",
			"searchMobile": "Buscar documentos"
		}
	},
	"ativoscoeficientecm": {
		"header": {
			"anoPesquisa": "Año para actualizar"
		},
		"btn": {
			"pesquisa": "Buscar",
			"grava": "para grabar"
		},
		"table": {
			"ativo": "Datos de activos",
			"coeficientes": "Coeficientes",
			"maismenosvalias": "Más o menos valor",
			"sel": "Sel.",
			"codativo": "código activo",
			"designacao": "Designación",
			"dataaquisicao": "fecha de adquisición",
			"dataavenda": "fecha de venta",
			"valor": "Valor",
			"coefatual": "Coef.",
			"coefcorrigido": "Coef.",
			"contab": "Contabilidad",
			"fiscal": "Impuesto",
			"fiscalcorrig": "Impuesto corregido",
			"emptyline": "Sin datos"
		},
		"message": {
			"temcerteza": "¿Quieres actualizar el coeficiente de los activos seleccionados?",
			"success": "¡Los activos se han actualizado correctamente!"
		}
	},
	"ativosconsistencia": {
		"tab": {
			"ativosconsistenciaContab": "Contabilidad sin conexión con activos",
			"ativosconsistenciaResumo": "Resumen por cuenta",
			"ativosconsistenciaAnaliseConta": "Análisis por cuenta",
			"ativosconsistenciaPeriodoDiferente": "Activos con diferente periodo",
			"ativosconsistenciaAnosErrados": "Activos con el año equivocado",
			"ativosconsistenciaVendaDiferente": "Ventas en diferentes años.",
			"ativosconsistenciaDetalhe": "Detalle por activo",
			"ativosconsistenciaIgnorados": "Documentos ignorados de la relación con los bienes",
			"ativosajustesgerados": "Ajustes generados",
			"resumoerros": "Resumen de errores"
		},
		"table": {
			"codErro": "Código.",
			"nConta": "Nro. de cuenta",
			"nomeConta": "Nombre de cuenta",
			"periodo": "Período",
			"nDiario": "A diario",
			"nDocInterno": "Doc no.",
			"valorContabilidade": "Valor contable",
			"dCAsStr": "Débito/Crédito",
			"valorAtivos": "Valor en activos",
			"diferenca": "Diferencia",
			"contabValorAquisicao": "Valor contable",
			"debito": "Deuda",
			"credito": "Crédito",
			"nContaDebito": "Deuda",
			"nContaCredito": "Crédito",
			"descricao": "Descripción",
			"valor": "Valor",
			"tipo0": "Tipo: activo bruto",
			"tipo1": "Tipo: depreciación acumulada",
			"tipo2": "Tipo: depreciación de ejercicio",
			"tipo3": "Tipo: otros"
		},
		"analiseconta": {
			"ano": "Ejercicio económico",
			"registoMes": "hasta el periodo"
		},
		"btn": {
			"lancadifajustes": "Publicar diferencias como ajustes",
			"pesquisa": "buscar",
			"ignorardocumentossel": "Ignorar documentos seleccionados",
			"retiradocignoradossel": "Eliminar documentos seleccionados",
			"tipo": {
				"ativobruto": "Tipo: activo bruto",
				"depreciacaoAcumulada": "Tipo: depreciación acumulada",
				"depreciacaoExercicio": "Tipo: depreciación de ejercicio",
				"outros": "Tipo: otros"
			},
			"accao": "Comportamiento",
			"dropdown": {
				"listaativos": "Activos de la cuenta",
				"ignorardocumento": "ignorar documento",
				"apagarIgnorados": "Eliminar documento de los ignorados",
				"planocontas": "plan de cuentas",
				"extratocontas": "Estado de cuenta",
				"aquisicaobydocumento": "Nueva adquisición del documento"
			}
		},
		"modal": {
			"listaativostitle": "Activos pertenecientes a la cuenta",
			"periodotitle": "Plazo para liberar ajustes",
			"periodo": "Período",
			"table": {
				"codAtivo": "Código",
				"designacao": "Designación",
				"dataAquisicao": "fecha de adquisición",
				"virtualValor": "Valor"
			}
		},
		"message": {
			"temcertezaignora": "¿Está seguro de que desea ignorar los documentos de la lista de activos seleccionados?",
			"temcertezaretira": "¿Está seguro de que desea eliminar los documentos seleccionados de los ignorados en la lista de activos?",
			"temcertezaajustes": "¿Está seguro de que desea publicar las diferencias como ajustes?",
			"successLanca": "¡Las diferencias se lanzaron con éxito!",
			"semerros": "Sin errores",
			"comerros": "tiene errores"
		}
	},
	"ativosdepreciacao": {
		"header": {
			"anoDeCalculo": "Ejercicio económico al que se refiere el cálculo",
			"codAtivoDe": "código activo",
			"dataAquisicaoDe": "fecha de adquisición",
			"pesquisaAnosAnteriores": "Búsqueda de activos depreciados en años anteriores",
			"alertativosdataposterior": "Existen activos con fecha de inicio de depreciación posterior a la fecha de adquisición."
		},
		"btn": {
			"pesquisar": "buscar",
			"calcular": "Depreciación récord",
			"anulaCalculo": "Deshacer depreciaciones seleccionadas",
			"consultar": "Consultar"
		},
		"tab": {
			"ativosDepreciadosEmAnoAnterior": "Activos depreciados en años anteriores",
			"ativosDepreciadosNoAno": "Activos depreciados en el año",
			"ativosPorDepreciarNoAno": "Activos a depreciar en el año",
			"registoMes": "Regístrate por periodo"
		},
		"table": {
			"title": {
				"ultimaDepreciacao": "última depreciación",
				"depreciacaoContabilisticos": "Depreciación contable",
				"depreciacaoFiscal": "Depreciación fiscal"
			},
			"subtitle": {
				"sel": "Sel.",
				"atCodAtivo": "código activo",
				"atDesignacao": "Designación",
				"atDataAquisicao": "fecha de adquisición",
				"atEstadoSTR": "Estado",
				"atDataAbate": "fecha de sacrificio",
				"atRefExterna": "Ref. externa.",
				"ultPeriodo": "Período",
				"valorAquisicaoComResidual": "Valor de adquisición",
				"taxaAmortiza": "Tasa de amortización",
				"percReducao": "% de reducción",
				"valorAnualJaRegistado": "Ya registrado",
				"valorAnual": "Valor a depreciar"
			}
		},
		"message": {
			"calculado": "Activos no depreciados calculados correctamente.",
			"calculoAnulado": "Los activos a depreciar se amortizaron exitosamente.",
			"percentAbove": "Hay porcentajes de reducción del 100%.",
			"jaforamdepreciadostodosano": "Todos los activos del año ya han sido depreciados.",
			"hasujeitosadepreciacaoano": "Hay activos sujetos a depreciación del año.",
			"naohanenhumsujeitodepreciacaoano": "No existen activos sujetos a depreciación durante el año."
		},
		"title": {
			"titlePercentAbove": "Cálculo de activos a depreciar"
		},
		"tooltip": {
			"selecionarumadep": "¡Debes seleccionar al menos una depreciación!"
		},
		"modal": {
			"dataspostetitle": "Activos con una fecha de inicio de depreciación muy superior a la fecha de adquisición",
			"table": {
				"codAtivo": "Código",
				"designacao": "Designación",
				"contabBaseCalculoStr": "Método de cálculo",
				"dataAquisicao": "fecha de adquisición",
				"contabDataDispUso": "Fecha de disponibilidad"
			}
		}
	},
	"ativosdivisao": {
		"message": {
			"temcerteza": "¿Está seguro de que desea dividir el activo?",
			"success": "¡Activo dividido exitosamente!",
			"info": "Esta acción permite dividir un activo en 2. <br> El activo existente será dado de baja, y se crearán 2 nuevos, descendientes del actual, que heredan todas las características (fechas, adquisición, depreciación)."
		},
		"title": {
			"divisao": "División de activos"
		},
		"header": {
			"unidades": "Número de unidades/cantidad",
			"valoraquisicao": "Valor de adquisición",
			"depacumu": "Dep. acumulada",
			"valorliquido": "patrimonio neto"
		},
		"fields": {
			"designacao": "Designación",
			"datadivisao": "fecha de división"
		}
	},
	"ativosestado": {
		"data": {
			"normal": "Normal",
			"abatido": "Derribado",
			"vendido": "Vendido",
			"dividido": "Dividido"
		}
	},
	"ativosgeramapasauto": {
		"tableDefinition": {
			"codAtivo": "código activo",
			"designacao": "Designación",
			"estadoStr": "Estado",
			"dataAquisicao": "fecha de adquisición",
			"dataAbateVenda": "fecha de sacrificio",
			"nMapaAmortizacao": "Mapa",
			"naturezaDosAtivosStr": "Naturaleza"
		},
		"btn": {
			"pesquisar": "buscar",
			"geraativos": "Generar mapas"
		},
		"tab": {
			"bens": "Bienes",
			"mapasarquivados": "Mapas archivados"
		},
		"nav": {
			"mapa": "Mapa"
		},
		"modal": {
			"jaexistemmapas": "Ya hay mapas generados para el año.",
			"jaexistemmapastext": "¿Quieres continuar y reemplazar los mapas existentes?"
		},
		"message": {
			"mapasgerados": "mapas generados",
			"benspesquisados": "Bienes buscados exitosamente.",
			"errorFolderMessage": "No hay mapas archivados o aún no se han generado."
		}
	},
	"ativoshistorico": {
		"table": {
			"tipoAcontecimentoSTR": "Tipo",
			"dataReferencia": "Fecha de referencia",
			"nomeUtilizador": "Usuario",
			"stampInsert": "Fecha/Hora de registro",
			"observacoes": "Observaciones"
		},
		"title": {
			"historico": "Historia"
		}
	},
	"ativosimport": {
		"title_detail": "Activo importado \"{{id}}\"",
		"title_new": "Nueva Importación",
		"title_plural": "Importaciones",
		"saved": "Importar {{id}}, guardado exitosamente.",
		"error": "Importar {{id}}, no se puede guardar.",
		"deleted": "Importar {{id}}, eliminado correctamente",
		"deletedsuccess": "Activo eliminado correctamente",
		"calculoaceite": "Cálculos aceptados exitosamente.",
		"calculobaseanual": "Cálculos de base anual realizados con éxito",
		"calculoinicioutilizacao": "Cálculos de inicio de uso realizados con éxito",
		"valoreslimposcomsucesso": "Los valores asignados manualmente se borraron correctamente",
		"atpercred": "Los valores asignados manualmente se borraron correctamente",
		"valsemarredondanual": "Importes sin redondeo anual distribuidos con éxito",
		"valoranosanterior": "Valor atribuido por años anteriores distribuido con éxito",
		"pesquisa": "Buscar",
		"onlyByImportar": "Muestra solo los activos que se importarán",
		"table": {
			"semdados": "Sin datos",
			"header": {
				"aquisicao": "Adquisición",
				"depacumucontab": "Depreciación acumulada contable",
				"depacumufiscais": "Depreciación acumulada fiscal",
				"decregulamentar": "Doc. Normativo.",
				"contas": "Cuentas",
				"associacaocontab": "asociación de contabilidad",
				"sel": "Sel."
			},
			"estado0": "Normal",
			"estado1": "Derribado",
			"estado2": "Vendido",
			"estado3": "Compartir",
			"baseCalculo0": "Base anual",
			"baseCalculo1": "Desde el inicio del uso",
			"metodoCalculo0": "Cuotas constantes",
			"metodoCalculo1": "Participaciones decrecientes"
		},
		"fields": {
			"importado": "Importado",
			"codAtivo": "código activo",
			"designacao": "Designación",
			"refExterna": "Ref. externa.",
			"usado": "Usado",
			"estado": "Estado",
			"dataAbate": "fecha de sacrificio",
			"valorVenda": "Valor de ventas",
			"dataAquisicao": "fecha de adquisición",
			"valorAquisicao": "Valor de adquisición contable.",
			"valorAquisicaoFiscal": "Valor de adquisición fiscal",
			"mapa": "Mapa",
			"dtInicioUtilizacao": "Dt.",
			"baseParaCalculo": "Base para el cálculo",
			"metodoParaCalculo": "Método de cálculo",
			"dtUltProcessamento": "Dt.",
			"valoresManuais": "Valores manuales",
			"depAcumulada": "Dep. acumulada",
			"depExercicio": "departamento de ejercicio",
			"percReducao": "% de reducción",
			"depCalculada": "Dep. calculada",
			"diferenca": "Diferencia",
			"codDR": "Código DR",
			"viatLigArtigo11DR": "Vehículo ligero - Art. 11.º DR",
			"taxaContab": "Tarifa contable.",
			"taxaFiscal": "Tasa impositiva",
			"ncontaAtivo": "Cuenta activa",
			"nomeDaContaDoAtivo": "Nombre de cuenta de activo",
			"ncontaFornecedor": "Cuenta de proveedor",
			"nomeDaContaDoFornecedor": "Nombre de cuenta del proveedor",
			"periodo": "Período",
			"diario": "A diario",
			"nDocInterno": "Doc no.",
			"quantidade": "Cantidad"
		},
		"form": {
			"designacao": "Designación",
			"refExterna": "Ref. externa.",
			"dataAquisicao": "fecha de adquisición",
			"dataInicioUtiliza": "Fecha de inicio de uso",
			"valorAquisicao": "Valor de adquisición",
			"valorAquisicaoFiscal": "Valor de adquisición fiscal",
			"codAmortizacao": "Código de amortización",
			"valorResidual": "valor residual",
			"nMapaAmortizacao": "Mapa",
			"viatLigArtigo11DR": "Vehículo ligero - Art. 11.º DR",
			"usado": "Usado",
			"taxaAmortizacao": "Tarifa de contabilidad",
			"percReducao": "% Reducción contable.",
			"fiscalTaxaAmortiza": "Tasa impositiva",
			"fiscalPercReducao": "% Reducción de impuestos",
			"nContaDoAtivo": "Número de cuenta de activo",
			"nContaFornecedor": "Número de cuenta del proveedor",
			"baseCalculo": "Base para el cálculo",
			"metodoCalculo": "Método de cálculo",
			"estado": "Estado",
			"dataUltProcessamento": "Último procesamiento",
			"depreciacaoAcumulada": "Depreciación acumulada contable",
			"depreciacaoExercicio": "departamento de ejercicio",
			"fiscalDepAcumulada": "Depreciación acumulada fiscal",
			"fiscalDepExercicio": "Ejercicio del departamento.",
			"dataAbate": "Fecha de sacrificio/venta",
			"valorVenda": "Valor de ventas",
			"quantidade": "Cantidad"
		},
		"radioOptions": {
			"normal": "Normal",
			"abatido": "Derribado",
			"vendido": "Vendido",
			"quotasConstantes": "Cuotas constantes",
			"quotasDecrescentes": "Participaciones decrecientes",
			"inicioUtilizacao": "Desde el inicio del uso",
			"baseAnual": "Base anual"
		},
		"steps": {
			"aquisicaoAtivo": "Adquisición",
			"contas": "Cuentas",
			"depreciacoes": "Depreciación acumulada"
		},
		"tabs": {
			"porimportar": "Para importar",
			"importados": "Importado"
		},
		"btn": {
			"excel": "Sobresalir",
			"apagasel": "Eliminar seleccionado",
			"delativossel": "Eliminar diablillo.",
			"delativosselimportados": "Eliminar activos importados sel.",
			"dropdown": {
				"editar": "Editar",
				"aceitarcalculos": "Aceptar cálculos",
				"alterarmetodocalculo": "Cambiar método de cálculo",
				"atribuivaloranualmanual": "Asignar valores anuales manualmente",
				"distribuivalorsemarredond": "Distribuir valores sin redondeo anual",
				"distribuivaloranosanterior": "Distribuir el valor asignado a años anteriores",
				"atribuipercredu": "Asignar porcentaje de reducción",
				"limpavaloratribuid": "Borra los valores asignados manualmente",
				"abrirdoccontab": "Abrir documento contable",
				"apagaativo": "Eliminar importación de activos",
				"associarativos": "Activos asociados",
				"alterarmetodocalculoanual": "Cambiar método de cálculo - base anual",
				"alterarmetodocalculoinicio": "Cambiar método de cálculo - inicio de uso",
				"apagaativoimport": "Eliminar activo importado"
			},
			"actions": {
				"criafolhaimportar": "Crear hoja para importar",
				"importarficheiro": "Importar archivo"
			}
		},
		"modal": {
			"contasinconsistencias": "Cuentas con diferencias entre libro mayor y activos",
			"tipo": "Tipo",
			"ajustescontastitle": "Documentos de ajuste generados.",
			"criaficheiroexcel": "creación de archivos excel",
			"importaficheiro": "importación de archivos excel",
			"ficheirocriadocomsucesso": "Archivo de Excel creado exitosamente",
			"atpercreducao": "Asignar porcentaje de reducción",
			"dadoscontab": "Datos contables",
			"dadosfiscais": "Datos fiscales",
			"atvaloranualman": "Asignar valores manualmente",
			"valoresemcadano": "Valores en cada año",
			"difcontab": "diferencia contable",
			"diffiscal": "diferencia de impuestos",
			"folhaimport": "creación de archivos excel",
			"colunasfich": "Columnas para el archivo",
			"reavaliacoes": "Reevaluaciones",
			"filesuccess": "¡Archivo creado exitosamente!",
			"fields": {
				"taxaamort": "Tasa de amortización",
				"amortacumu": "Amortización acumulada",
				"percred": "% de reducción",
				"valoraquisicao": "Valor de adquisición",
				"inseridomanual": "(Ingresado manualmente)",
				"aplicarred": "Aplicar % de reducción",
				"dadosfiscaisiguais": "Datos fiscales iguales a datos contables",
				"todosanos": "Cada año",
				"perc": "Porcentaje de reducción",
				"nConta": "Número de cuenta",
				"nomeConta": "Nombre de cuenta",
				"valorContabilidade": "Valor contable",
				"valorAtivos": "Valor en activos",
				"diferenca": "Diferencia",
				"periodo": "Período",
				"ndiario": "A diario",
				"ndocInterno": "Doc no."
			},
			"btn": {
				"aplicarpercred": "Aplicar % de reducción",
				"excel": "Sobresalir",
				"importarativos": "Importar activos",
				"inconsistenciabtn": "Publicar diferencias como ajustes",
				"fechar": "para cerrar",
				"actions": {
					"criafolhaimportar": "Crear hoja para importar",
					"importarficheiro": "Importar archivo"
				}
			},
			"table": {
				"depcontab": "Depreciación contable",
				"ajuste": "Ajuste",
				"depfiscal": "Depreciación fiscal"
			},
			"msg": {
				"ativosimportados": "¡Activos importados!",
				"avisoinconsistenciasfirst": "Hay cuentas con diferencias entre contabilidad y activo.",
				"avisoinconsistenciassecond": "Si lo deseas, puedes publicar las diferencias como ajustes.",
				"ajustesnascontas": "Activos importados y ajustes a cuentas registradas.",
				"desejaimportar": "¿Quieres importar los activos?",
				"haativosdataaquisicao": "Existen activos con fecha de adquisición en el año en curso.",
				"haativoserro": "Hay activos con errores.",
				"aprepararimportacao": "Preparando el proceso de importación..."
			},
			"camposexport": {
				"basecalculo": "Base de cálculo",
				"designacao": "Designación",
				"mapa": "Mapa",
				"CodAmortizacao": "Código de amortización",
				"ViatLigArt11DR": "Vehículo ligero - Art. 11.º DR",
				"TaxaAmortizacao": "Tasa de depósito.",
				"FiscalTaxaAmortizacao": "Tasa de depósito.",
				"DataAquisicao": "fecha de adquisición",
				"DataInicioUtiliza": "Fecha de inicio de uso",
				"NContaFornecedor": "Número de cuenta del proveedor",
				"ValorAquisicao": "Valor de adquisición",
				"ValorAquisicaoFiscal": "Valor de adquisición fiscal",
				"NContaDoAtivo": "Número de cuenta activa",
				"PercReducao": "Perc.",
				"FiscalPercReducao": "Perc.",
				"BaseCalculo": "Base de cálculo",
				"MetodoCalculo": "Método de cálculo",
				"DepreciacaoAcumulada": "Depreciación acumulada",
				"DepreciacaoExercicio": "Depreciación del año",
				"FiscalDepreciacaoAcumulada": "Departamento de impuestos acumulados",
				"FiscalDepreciacaoExercicio": "Departamento de Año Fiscal.",
				"DataUltProcessamento": "Última fecha",
				"Usado": "Usado",
				"Qtd": "Cantidad",
				"RefExterna": "Ref. externa.",
				"Estado": "Estado",
				"DataAbate": "Fecha de sacrificio/venta",
				"ValorVenda": "Valor de venta",
				"ValorResidual": "valor residual",
				"NCCusto": "C. Costo",
				"NZona": "Zona",
				"NDepto": "Departamento",
				"NSubde": "Subdepartamento",
				"TipoArtigo": "Tipo de artículo",
				"NGrFamilia": "gran familia",
				"NFamilia": "Familia",
				"NSubfa": "Subfamilia",
				"CodContab": "código contable",
				"Classe": "Clase",
				"Categoria": "Categoría",
				"NVendedor": "Vendedor",
				"NRefProcesso": "Proceso",
				"NSeccao": "Sección",
				"DL126": "DL 126/77",
				"DL24": "DL 24/82",
				"DL219": "DL 219/82",
				"DL399G": "DL 399G/84",
				"DL118B": "DL 118B/86",
				"DL111": "DL 111/88",
				"DL49": "DL 49/91",
				"DL264": "DL 264/92",
				"DL31": "DL 31/98"
			},
			"importProgressTitle": "Importar"
		},
		"messages": {
			"temQueTerValorAtribuido": "El año {{ultYear}} debe tener un valor asignado.",
			"temqueselecionarativo": "Debe seleccionar al menos un activo."
		},
		"jobImportTimeoutModalTitle": "Importar",
		"jobImportTimeoutModalMessage": "La tarea ha expirado."
	},
	"ativosimpressao": {
		"header": {
			"codAtivoDe": "código activo",
			"nContaDe": "Número de cuenta",
			"nCCustoDe": "No. de centro de costo",
			"codAmortDe": "Código de amortización",
			"codMapaDe": "código de mapa",
			"dataAquisicaoDe": "Fecha de adquisición",
			"dataAbateDe": "fecha de sacrificio",
			"situacao": "Situación",
			"datareferencia": "Fecha de referencia (Para estado de activos y análisis de depreciación)",
			"pesqavancada": "Filtro avanzado",
			"estadoUso": "Estado de uso"
		},
		"radioOptions": {
			"todos": "Normal y sacrificado/vendido en el año",
			"dividido": "Dividido",
			"normal": "Normal",
			"abatido": "Derribado",
			"vendido": "Vendido",
			"indiferente": "cualquier estado",
			"usado": "Usado",
			"novo": "Nuevo"
		},
		"table": {
			"aquisicao": "Adquisición",
			"classificadores": "Clasificadores",
			"contas": "Cuentas",
			"dadoscontabilisticos": "Datos contables",
			"dadosfiscais": "Datos fiscales",
			"abatevenda": "Masacre/Venta",
			"limiteminimofiscal": "Límite mínimo de impuestos",
			"perdasimparidadecontabilistica": "Pérdidas por deterioro contable",
			"perdasimparidadefiscais": "Pérdidas por deterioro fiscal",
			"valoresperdidosfiscais": "Valores perdidos de impuestos",
			"taxasperdidasfiscais": "Tarifas de impuestos perdidos",
			"depreciacoesnaoaceites": "Depreciaciones no aceptadas",
			"imparidadesnaoaceites": "Deficiencias no aceptadas",
			"depreciacoesarecuperar": "Depreciación a recuperar",
			"depreciacoesrecuperadas": "Depreciaciones recuperadas",
			"imparidadesrecuperadasnoperiodo": "Deterioros recuperados en el periodo",
			"acrescemodelo22": "Se suma al modelo 22.",
			"deduzmodelo22": "Deduce al modelo 22",
			"valortributavelativosporimpos": "Valor imponible del activo por impuestos diferidos",
			"valortributaveldopassivoporimpos": "Valor imponible del pasivo por impuesto diferido",
			"valornaoaceitefiscalmenteporreavaliacoes": "Valor no IVA aceptado por revalorizaciones",
			"impostosdiferidos": "Impuestos diferidos",
			"rendimentosdossubsidios": "Ingresos por subsidios",
			"impostosdifSubsidios": "Impuestos diferidos sobre subsidios",
			"semdados": "Sin datos",
			"sub": {
				"codativo": "código activo",
				"designacao": "Designación",
				"estado": "Estado",
				"refexterna": "Ref. externa.",
				"usado": "Usado",
				"valoraquisicaocontabilisticocomr": "Valor de adquisición contable, con residual",
				"dataaquisicao": "fecha de adquisición",
				"mapa": "Mapa",
				"coddecregulamentar": "Código de diciembre.",
				"nomeDecRegulamentar": "Nombre diciembre.",
				"taxaDoDecRegulamentar": "Tasa de diciembre.",
				"viatLigArtigo11": "Viat.",
				"codAtivoPai": "Código activo principal",
				"numContaAtivo": "Número de cuenta activa",
				"numContaDepAcum": "Dep. de cuenta no.",
				"numContaDepExerc": "Dep. de cuenta no.",
				"valAquisicao": "Valor de adquisición",
				"valResidual": "valor residual",
				"taxaDepreciacaoAdoptada": "Tasa de depreciación adoptada",
				"reducao": "Reducción",
				"depNoAno": "Dpto. en el año",
				"depAcumNoAnoAnterior": "Departamento de acumulación.",
				"depAcumTotal": "Departamento de acumulación.",
				"valor": "Valor",
				"coefCorrecaoMonetaria": "Coef.",
				"contaVenda": "cuenta de ventas",
				"maisMenosValiaContabilistica": "Más/Menos valor contable",
				"maisMenosValiaFiscal": "Más/Menos valor fiscal",
				"noAno": "en el año",
				"acumNoAnoAnterior": "Acumulación.",
				"acumTotal": "Acumulación.",
				"taxaIRC": "tasa de IRC",
				"noAnoAnterior": "En el año anterior",
				"situacaoa": "Situación a",
				"situacaoAtDataSTR": "Situación en {{dataReferencia}}",
				"doccontabilidade": "Doc Contable (adquisición)",
				"codProcesso": "código de proceso",
				"nomeProcesso": "Nombre del proceso",
				"codCCusto": "Código C. Costo",
				"nomeCCusto": "Nombre C. Costo",
				"metodoCalculo": "Método de cálculo",
				"baseCalculo": "Base para el cálculo"
			}
		},
		"btn": {
			"pesquisar": "Ver en cuadrícula",
			"previsualizar": "Avance",
			"processar": "Proceso",
			"ok": "para cerrar",
			"dropdown": {
				"depreciacoes": "Consultar depreciaciones",
				"fichaativo": "Hoja de activos",
				"extaquisicao": "Estado de cuenta de adquisición",
				"extexercicio": "Estado de cuenta del departamento.",
				"extacumulada": "Estado de cuenta del departamento.",
				"doccontab": "Doc. Contable."
			}
		},
		"modal": {
			"ativoimpressaomodaltitle": "Impresión de activos",
			"ativoimpressaodepmodaltitle": "Detalle de depreciación de activos",
			"depresumo": "Resumen",
			"depDetalhe": "Detalle",
			"ativoimpressaodocmodaltitle": "Doc. Contable."
		}
	},
	"ativosmapasoficiais": {
		"header": {
			"codAtivoDe": "código activo",
			"nContaDe": "Número de cuenta",
			"nCCustoDe": "No. de centro de costo",
			"codAmortDe": "Código de amortización",
			"nMapa": "Mapa",
			"dataAquisicaoDe": "Fecha de adquisición",
			"dataAbateDe": "fecha de sacrificio",
			"anoEconomico": "Ejercicio económico",
			"situacao": "Situación",
			"registoMes": "Mes",
			"natureza": "Naturaleza"
		},
		"radioOptions": {
			"todos": "Normal y sacrificado/vendido en el año",
			"normal": "Normal",
			"abatido": "Derribado",
			"vendido": "Vendido",
			"qualquer": "Cualquier método",
			"quotasConstantes": "Cuotas constantes",
			"quotasDecrescentes": "Participaciones decrecientes",
			"indiferente": "cualquier estado",
			"usado": "Usado",
			"novo": "Nuevo"
		},
		"btn": {
			"previsualizar": "Avance",
			"processar": "Proceso",
			"ok": "para cerrar"
		},
		"print": {
			"ativoimpressaomodaltitle": "Impresión de activos",
			"metodos": "Métodos",
			"estadouso": "Estado"
		},
		"natureza": {
			"qualquer": "cualquier naturaleza",
			"tangiveis": "Activos fijos tangibles",
			"intangiveis": "Activos intangibles",
			"bioNaoConsumiveis": "Activos biológicos no consumibles",
			"propInvetimento": "Propiedades de inversión"
		},
		"card": {
			"selecao": "Selección"
		},
		"modal": {
			"title": "Activos - Mapa oficial"
		}
	},
	"ativosmudarcontaativo": {
		"temcerteza": "¿Está seguro de que desea cambiar la cuenta de activos?",
		"title": {
			"mudarcontaativo": "Cambiar cuenta de activos"
		},
		"fields": {
			"ncontanovo": "Nuevo número de cuenta",
			"ncontaatual": "Número de cuenta corriente"
		},
		"message": {
			"success": "¡La cuenta de activos se cambió correctamente!"
		}
	},
	"ativosmudarcontadep": {
		"temcerteza": "¿Estás seguro de que quieres cambiar la cuenta?",
		"title": {
			"mudarcontadepexerc": "Cambiar cuenta de depreciación del año fiscal",
			"mudarcontadepacumu": "Cambiar cuenta de depreciación del año fiscal"
		},
		"fields": {
			"ncontanovo": "Nuevo número de cuenta",
			"ncontaatual": "Número de cuenta corriente"
		},
		"message": {
			"successExercicio": "¡La cuenta de depreciación del año se cambió exitosamente!",
			"successAcumulado": "¡La cuenta de depreciación acumulada se cambió exitosamente!",
			"jahadep": "Ya hay depreciaciones registradas para el año en curso, ¡no es posible cambiar la cuenta!",
			"contaVazio": "El número de cuenta no puede estar vacío."
		}
	},
	"ativosmudartaxasdep": {
		"temcerteza": "¿Está seguro de que desea cambiar las tasas de depreciación?",
		"title": {
			"mudartaxasdep": "Cambiar las tasas de depreciación",
			"depcontab": "Depreciaciones contables",
			"depfiscal": "Depreciaciones fiscales"
		},
		"fields": {
			"taxanovo": "Nueva tarifa",
			"taxaatual": "Tasa actual"
		},
		"message": {
			"success": "¡Las tasas de depreciación cambiaron exitosamente!"
		}
	},
	"ativosnotacredito": {
		"title": "Descuento de activos",
		"header": {
			"fornecedor": "Proveedor",
			"valoraquisicao": "Valor de adquisición",
			"valornotacredito": "Valor de la nota de crédito",
			"novovaloraquisicao": "Nuevo valor de adquisición"
		},
		"btn": {
			"pesquisanota": "Buscar nota de crédito"
		},
		"table": {
			"nContaDebito": "Deuda",
			"nContaCredito": "Crédito",
			"valor": "Valor",
			"descricao": "Descripción"
		},
		"modaltable": {
			"nConta": "Nro. de cuenta",
			"nome": "Nombre",
			"valor": "Valor de adquisición",
			"valorCalculado": "Ya asociado",
			"valorCalculado2": "En nuevos activos",
			"periodo": "Período",
			"nDiario": "Número diario",
			"nDocInterno": "Doc no.",
			"dCDescription": "Deb./Cré",
			"nContaDebito": "Deuda",
			"nContaCredito": "Crédito",
			"descricao": "Descripción",
			"designacao": "Designación",
			"codAtivo": "Código.",
			"nContaDoAtivo": "Número de cuenta de activo",
			"contabValorAquisicao": "Valor"
		},
		"message": {
			"temdeselecionarum": "¡Debes seleccionar un descuento!",
			"temcerteza": "¿Estás seguro de querer asociar la Nota de Crédito y reducir el valor neto del activo?",
			"success": "¡Descuento asociado exitosamente!"
		},
		"submodal": {
			"title": "Buscar nota de crédito"
		}
	},
	"ativosvenda": {
		"ativosporvender": "Activos a vender",
		"datavenda": "fecha de venta",
		"selecionarativos": "Seleccionar activos para vender",
		"distribuicaovalor": "Distribución del valor de venta entre activos.",
		"radiooptions": {
			"liquido": "Proporcional por valor neto",
			"bruto": "Proporcional por valor bruto",
			"manual": "Asignar manualmente"
		},
		"message": {
			"success": "Venta registrada!",
			"selecionarfatura": "Debes seleccionar una venta",
			"docnaosaldado": "¡El documento contable seleccionado no está equilibrado!"
		},
		"steps": {
			"tabeladocumentos": "Búsqueda de nuevas ventas en contabilidad.",
			"detalhesparavenda": "Valores y cuentas de la venta del activo"
		},
		"card": {
			"valorsemiva": "Valor sin IVA",
			"valordoiva": "valor del IVA",
			"total": "Total",
			"docliente": "Del cliente",
			"maismenosvalia": "Más / Menos valor",
			"iva": "TINA",
			"contas": "Cuentas",
			"valores": "Valores",
			"coef": "Coef."
		},
		"table": {
			"codAtivo": "código activo",
			"designacao": "Designación",
			"refExterna": "Ref. externa.",
			"dataAquisicao": "Dt.",
			"valorAtivoBrutoContab": "Activos brutos",
			"valorDepreciacoesAcumuladasContab": "Dpto acumulado",
			"ativosporvender": "Activos a vender",
			"ativosavender": "Activos para vender",
			"valorVenda": "Valor de ventas"
		},
		"tooltip": {
			"valorNaoValido": "¡La suma de los valores de venta del activo no es igual al valor del documento!"
		},
		"btn": {
			"realizarvenda": "hacer venta",
			"pesquisafaturavenda": "Buscar factura de venta"
		},
		"error": {
			"invalidStep": "¡Debes seleccionar un documento para registrar la venta!"
		}
	},
	"auditlogin": {
		"fields": {
			"username": "Nombre de usuario",
			"centralGestName": "Instalación",
			"cgId": "Código de instalación",
			"ip": "IP",
			"action": "Acción",
			"stampInsert": "Fecha",
			"nEmpresa": "empresa no.",
			"nomeEmpresa": "Nombre de empresa"
		}
	},
	"contratosservicos": {
		"title_detail": "Pacto {{id}}",
		"title_new": "Nuevo Pacto",
		"title_plural": "Pactos",
		"pesquisa": "buscar",
		"saved": "Pacto {{id}}, guardado exitosamente",
		"error": "Pacto {{id}}, no se puede salvar.",
		"deleted": "Pacto {{id}}, eliminado exitosamente",
		"fields": {
			"cab": {
				"refCtrCab": "código de recibo",
				"nClasseCtr": "Clase",
				"classeCtrNome": "nombre de clase",
				"nClasseCtrPlaceholder": "clase de subsidio",
				"nTipoContrato": "Tipo",
				"tipoContratoNome": "Nombre del tipo",
				"nTipoContratoPlaceholder": "Tipo de contrato",
				"nContrato": "Número de recibo",
				"nContratoPlaceholder": "Número de recibo",
				"verContrato": "Versión",
				"verContratoPlaceHolder": "Versión del recibo",
				"nContratoAsString": "Pacto",
				"nContratoSemVersaoAsString": "Pacto",
				"descricao": "Descripción",
				"descricaoPlaceHolder": "Descripción del acuerdo",
				"nClifo": "Cliente",
				"nClifoPlaceHolder": "Cliente",
				"clifoNome": "Nombre del cliente",
				"idIdeChav": "grupo de cuentas",
				"idIdeChavPlaceHolder": "grupo de cuentas",
				"dataCtr": "fecha de pago",
				"dataCtrPlaceHolder": "fecha de pago",
				"dataInicio": "Fecha de inicio",
				"dataInicioPlaceHolder": "Fecha de inicio",
				"dataFim": "Fecha de finalización",
				"dataFimPlaceHolder": "Fecha de finalización",
				"estado": "Estado",
				"estadoPlaceHolder": "código de estado",
				"ccusto": "centro de costos",
				"ccustoPlaceHolder": "Código del centro de costos",
				"nRefProcesso": "Proceso",
				"nRefProcessoPlaceHolder": "código de proceso",
				"codMoeda": "Acuñar",
				"codMoedaPlaceHolder": "Código de moneda",
				"refExterna": "Referencia externa",
				"terminado": "Finalizado",
				"totalLiquido": "Valor",
				"valorGlobal": "Valor total",
				"codCondComerciais": "Condición comercial",
				"codCondComerciaisPlaceHolder": "Código de condición comercial",
				"estadoContrato": "Estado",
				"estadoContratoPlaceHolder": "Estado del contrato"
			},
			"linhas": {
				"nArtigo": "Código del artículo",
				"nomeArtigo": "Descripción",
				"qtd1": "Cantidad",
				"prVenda": "unidad pr.",
				"d1": "D1",
				"d2": "D2",
				"d3": "D3",
				"desconto": "Descuento",
				"d4": "D4",
				"d5": "D5",
				"d6": "D6",
				"codIva": "código de IVA",
				"taxaIva": "tipo de IVA",
				"nArmazem": "Almacenamiento",
				"cunimo": "Elementos.",
				"qtdPUnid": "Cant. / Unidades",
				"nVendedor": "Vendedor",
				"ccusto": "centro de costos",
				"nZona": "Zona",
				"nDepto": "Departamento",
				"nSubde": "Subdepartamento",
				"qtd1Fact": "Cantidad facturada",
				"nRefProcesso": "Proceso",
				"estado": "Estado",
				"obs": "Observaciones",
				"valorLiquido": "Líquido"
			}
		},
		"tabs": {
			"linhas": "Pauta"
		},
		"config": {
			"verGrupoConta": "Mostrar cuenta de grupo",
			"verMoeda": "Mostrar moneda",
			"verRefExterna": "Mostrar referencia externa",
			"verCondComerciais": "Mostrar condiciones comerciales",
			"verVendedor": "Mostrar vendedor",
			"verNCCusto": "Mostrar centro de costos",
			"verD1": "Mostrar D1",
			"verArmazem": "Mostrar almacén",
			"verUnidadeMovimento": "Mostrar unidad de movimiento"
		},
		"errors": {
			"notSelected": "No hay ningún contrato seleccionado",
			"terminado": "El acuerdo {{avenca}} ya está finalizado",
			"notTerminado": "El acuerdo {{avenca}} no está terminado"
		},
		"btn": {
			"configurar": "Configurar"
		},
		"info": {
			"terminado": "Finalizado",
			"reabrir": "Reabrir contrato",
			"terminar": "Terminar contrato",
			"apagar": "Eliminar contrato"
		},
		"message": {
			"temCertezaReabrir": "¿Estás seguro de que quieres reabrir el acuerdo {{avenca}}?",
			"temCertezaTerminar": "¿Estás seguro de que quieres finalizar el acuerdo {{avenca}}?",
			"temCertezaApagar": "¿Estás seguro de que deseas eliminar la avenca {{avenca}}?"
		},
		"modulos": {
			"classes": "clases de pacto",
			"tipos": "Tipos de pactos",
			"faturacao": "Facturación"
		},
		"modal": {
			"faturacaoAuto": {
				"title": "Facturación automática",
				"cards": {
					"configurar": "Tipo de contrato a configurar"
				}
			}
		}
	},
	"avisoscobranca": {
		"fields": {
			"clifoNaoPodeFacturar": "no puedo facturar",
			"dataDocDe": "Fecha del doctor.",
			"dataPrimeiroVencimento": "1ra fecha de vencimiento",
			"dataUltimoAvisoProc": "Última fecha/hora",
			"dataUltimoComentario": "Última fecha",
			"dataUltimoRecebimento": "Última fecha",
			"dataVencimento": "Fecha de vencimiento",
			"dataVencimentoDe": "fecha de vencimiento de",
			"dividaVencida": "Deuda vencida",
			"emailDestino": "Correo electrónico",
			"grupoConta": "cuenta de grupo",
			"grupoContaStr": "Descripción gr.",
			"nCondPagamDe": "Número de condición de pago",
			"nConta": "Nro. de cuenta",
			"nContaDe": "Número de cuenta",
			"nContaNome": "Nombre",
			"nDiario": "A diario",
			"nDiasUltimoRecebimento": "Último número de días",
			"nDocExterno": "Doc no.",
			"nDocInterno": "Número de documento",
			"nDocsPorRegularizar": "Número de documentos a regularizar",
			"nVendedorDe": "Número de vendedor de",
			"observacoes": "Observaciones",
			"periodo": "Período",
			"ultimoComentario": "último comentario",
			"valor": "Valor",
			"valorCredito": "Crédito",
			"valorDebito": "Deuda",
			"valorPago": "Monto pagado",
			"valorPorPagar": "Monto a pagar"
		},
		"btn": {
			"previsualizar": "Avance",
			"sendMail": "Enviar correo electrónico",
			"pesqinterrompido": "Búsqueda con procesamiento interrumpido"
		},
		"modal": {
			"avisoscobranca": "Avisos de facturación",
			"avisoscobrancaemail": "Notificaciones de facturación seleccionadas por correo electrónico",
			"avisoscobrancaAlterarEmailProntual": "Cambiar de correo electrónico ocasionalmente",
			"erros": {
				"title": "Lista de errores",
				"noEmails": "No se encontró ninguna cuenta con transacciones pendientes que tengan un correo electrónico configurado"
			},
			"emissora": {
				"configuradaEasyPay": "Se configura el emisor de referencia de MB: EasyPay",
				"defaultReport": "Informe predeterminado",
				"desativado": "Desactivado",
				"easypay": "PagoFácilEn Línea",
				"emissaorefmb": "Emisión de referencia multibanco",
				"emissora": "Locutor",
				"emitirAvisosCobranca": "¿Quieres emitir referencias de MB en avisos de facturación?",
				"naoExisteConfig": "No hay ningún emisor de referencia de MB activo",
				"title": "Configurar avisos de facturación"
			}
		},
		"messages": {
			"erroRadicais": "Sólo puedes buscar clientes en este módulo (Cuentas que comienzan con 22).",
			"naoselconta": "No has seleccionado ninguna cuenta",
			"estadochangedsuccess": "¡El estado cambió exitosamente!",
			"procseminterrompido": "Hay registros con procesamiento interrumpido.",
			"contaSemMail1": "Las siguientes cuentas seleccionadas no tienen un correo electrónico asignado:",
			"contaSemMail2": "¿Tiene intención de continuar?",
			"allContasSemMail": "No hay correos electrónicos de cuenta configurados",
			"savedsuccessfully": "¡Datos guardados exitosamente!",
			"emailSentSuccessfully": "Correo electrónico enviado correctamente."
		},
		"actions": {
			"cliente": "Cliente",
			"extratoconta": "Estado de cuenta",
			"movaberto": "Movimientos abiertos",
			"previsualiza": "Vista previa del listado",
			"naopodefaturar": "Cambiar el estado del cliente a \"No se puede facturar\"",
			"podefaturar": "Cambiar el estado del cliente a \"Puede facturar\"",
			"doccontab": "Mostrar documento.",
			"doccomercial": "Mostrar documento.",
			"alterarEmailPontual": "Cambiar de correo electrónico ocasionalmente"
		},
		"config": {
			"showVendedor": "Mostrar vendedor",
			"showCondPagamento": "Mostrar condiciones de pago"
		},
		"datagrid": {
			"legend": {
				"campoAlterado": "El campo cambia ocasionalmente"
			}
		}
	},
	"balancetes": {
		"title": "Balances",
		"fields": {
			"doPeriodo": "período de",
			"deConta": "cuenta",
			"deCCusto": "C. Costo de",
			"planoAlt": "Plan alternativo"
		},
		"naoTemTipoConta": "¡Debes seleccionar al menos un tipo de cuenta para procesar!",
		"btn": {
			"processar": "Procesar a PDF",
			"processarexcel": "Procesar y exportar excel.",
			"processargrelha": "Proceso",
			"exportardefir": "Exportar Defir",
			"exportargabmov": "Exportar Gabmov",
			"dropdown": {
				"planocontas": "plan de cuentas",
				"extratosgrid": "Estados de cuenta",
				"historicoextratos": "Historial de estados de cuenta",
				"clifos": "Clientes y proveedores",
				"movaberto": "Movimientos abiertos",
				"extratoPlanoAlt": "Extractos de planes alternativos",
				"acumuPlanoAlt": "Acumulado del plan alternativo"
			}
		},
		"messages": {
			"withoutData": "¡No hay datos para presentar!"
		},
		"modal": {
			"title": "Balanza de comprobación contable",
			"tipoperfil": "Perfil",
			"pesq": "Buscar",
			"pesquisar": "buscar",
			"table": {
				"empresa": "{{nempresa}} - {{descempresa}}",
				"descano": "{{año}} - {{periodDesc}} ({{fromperiod}} a {{untilperiod}})",
				"conta": "Cuenta",
				"movperiodo": "Movimientos del periodo",
				"movacumu": "Movimientos acumulados",
				"total": "Totales",
				"semdados": "Sin datos",
				"header": {
					"nconta": "Nro. de cuenta",
					"nome": "Nombre",
					"debito": "Deuda",
					"credito": "Crédito",
					"saldodebito": "Saldo deudor",
					"saldocredito": "Saldo crediticio",
					"saldogeral": "equilibrio general"
				}
			},
			"tipocontacontab": {
				"0": "Movimiento",
				"1": "Total parcial",
				"2": "Razón",
				"3": "Clase"
			}
		},
		"tipoperfil": {
			"data": {
				"todas": "Todas las columnas",
				"saldo": "Equilibrar columnas"
			}
		},
		"tab": {
			"reportpdf": "PDF",
			"reportgrelha": "Parrilla",
			"grelhaprocessar": "¡Tienes que procesar la parrilla!"
		}
	},
	"balancetesanalitica": {
		"periodoDe": "período de",
		"nContaCGeralDe": "cuenta",
		"cCustoDe": "C. Costo de",
		"zonaDe": "Zona",
		"departamentoDe": "Departamento de",
		"subDepartDe": "Sub.",
		"familiaDe": "familia de",
		"grFamiliaDe": "gran familia de",
		"subFamiliaDe": "Sub.",
		"codContabDe": "código contable de",
		"tipoArtigoDe": "tipo de artículo",
		"classeDe": "Clase de",
		"categoriaDe": "categoría de",
		"vendedorDe": "vendedor",
		"processoDe": "Proceso"
	},
	"bancos": {
		"title_detail": "Banco {{id}}",
		"title_new": "Nuevo banco",
		"title_plural": "Identificación bancaria",
		"pesquisa": "buscar",
		"saved": "Identificación bancaria {{id}}, guardada exitosamente.",
		"error": "Identificación bancaria {{id}}, no se puede guardar.",
		"deleted": "ID del banco {{id}}, eliminado correctamente",
		"fields": {
			"codBanco": "Código",
			"nome": "Nombre",
			"designaBreve": "Breve designación",
			"idInterBancaria": "Identificación interbancaria",
			"nomeInterBancaria": "Nombre de identificación interbancaria",
			"codSwift": "código SWIFT"
		}
	},
	"bancosextrato": {
		"config": {
			"autoSuggestDoc": "Sugerir lanzamiento automáticamente"
		},
		"fields": {
			"bankAccount": "Cuenta bancaria",
			"description": "Descripción",
			"amount": "Valor",
			"predefinido": "Predefinido",
			"observacoes": "Observaciones",
			"saldo": "Saldo actual",
			"dataSyncDados": "fecha de sincronización",
			"nConta": "Número de cuenta",
			"nomeBanco": "nombre del banco",
			"iban": "IBAN",
			"visivel": "Visible",
			"diarioRecebimentos": "Diario de recibos",
			"diarioPagamentos": "Pagos diarios"
		},
		"actions": {
			"refreshAccount": "Realizar una solicitud para actualizar su saldo y extracto de cuenta bancaria (se realiza automáticamente cada 6 horas)",
			"editBankAccount": "Editar cuenta bancaria",
			"addBankAccount": "Agregar cuentas bancarias de un nuevo banco",
			"importByReconciliation": "Importar extracto de conciliación bancaria",
			"search": "buscar",
			"viewDocument": "Ver documento",
			"ignoreDocuments": "Ignorar líneas de declaración seleccionadas",
			"stopIgnoreDocuments": "Dejar de ignorar la línea de declaración seleccionada",
			"addPredefDesc": "Asignar cuenta predefinida o por descripción de extracto",
			"editPredefDesc": "Editar asignación o cuenta predefinida por descripción del extracto",
			"removePredefDesc": "Eliminar asignación o cuenta predefinida por descripción del extracto",
			"mergeMode": "Unir líneas de declaración en modo completo",
			"fullExtract": "Ver extracto completo (también muestra los ya lanzados y los ignorados)",
			"doReceipt": "hacer recibo",
			"doPayment": "realizar el pago",
			"configurations": "Configuración general",
			"suggestDoc": "Sugerir lanzamiento",
			"lancarDocs": "Publicar documentos",
			"lancarDocsEmSerie": "Lanzar documentos en serie.",
			"selectAll": "Seleccionar todo",
			"selectAllMesmoPredefinido": "Seleccionar líneas con el mismo predefinido.",
			"selectAllComPredefinido": "Seleccione todas las líneas que tengan configuración predefinida o de cuenta",
			"selectAllComDocDigital": "Selecciona todos los documentos con el documento digital correspondiente.",
			"selectAllMesmaConfgPorDesc": "Selecciona todas las filas con la misma configuración por descripción",
			"deselectAll": "Borrar selección",
			"seeSimilarPostedDocs": "Ver documentos similares ya publicados"
		},
		"actionssimple": {
			"addPredefDesc": "Asignar cuenta por descripción del extracto",
			"editPredefDesc": "Editar asignación de cuenta por descripción del extracto",
			"removePredefDesc": "Eliminar asignación de cuenta por descripción del extracto"
		},
		"text": {
			"noLicense": "La empresa actual no tiene activada la licencia de automatización bancaria.",
			"accessDenied": "No tienes permisos para acceder a la automatización bancaria.",
			"accessDeniedStore": "No tiene permisos para acceder a la automatización bancaria, comuníquese con su gerente para administrar el acceso.",
			"activate": "Haga clic aquí para activar.",
			"selectedTransactions": "Transacciones seleccionadas",
			"confirmEditBankAccount": "Esta cuenta bancaria aún no tiene asignado el plan de cuentas de la cuenta bancaria.<br/>¿Quieres asignarlo ahora?",
			"confirmLancarDocsEmSerie": "¿Está seguro de que desea liberar los documentos seleccionados?",
			"confirmLancarDocsEmSerieContabilidadeDigital": "Ha elegido publicar documentos en serie en una empresa con archivo digital.<br/>El sistema adjuntará automáticamente los documentos digitales sugeridos, verifique si están correctamente clasificados.<br/>¿Está seguro de que desea publicar los documentos? documentos seleccionados?",
			"successRefreshBankAccount": "La solicitud de actualización de saldo y estado de cuenta bancaria se realizó exitosamente.",
			"successUpdateBankAccount": "Cuenta bancaria actualizada correctamente.",
			"successAddBankAccount": "Cuenta bancaria agregada exitosamente.",
			"successLancarDocsEmSerie": "Documentos lanzados exitosamente.",
			"successIgnoreDocuments": "Las líneas del extracto se omitieron correctamente.",
			"successStopIgnoreDocuments": "La línea del extracto se omitió correctamente.",
			"confirmIgnoreDocumentsTitle": "Ignorar líneas de declaración seleccionadas",
			"confirmIgnoreDocumentsMessage": "¿Está seguro de que desea ignorar las líneas de declaración seleccionadas?",
			"confirmDeletePredefDescTitle": "Eliminar configuración por descripción de declaración",
			"confirmDeletePredefDescMessage": "¿Está seguro de que desea eliminar la configuración por descripción de declaración?",
			"deletePredefDesc": "La configuración por descripción del extracto se eliminó correctamente.",
			"promptPrintReceipt": "¿Quieres imprimir el recibo?",
			"promptPrintPayment": "¿Quieres imprimir el pago?",
			"bankAccountConsentExpiredTitle": "La credencial para acceder al banco está vencida",
			"bankAccountConsentExpiredMessage": "La credencial para acceder al banco ha caducado.",
			"infoEditBankAccountDiarioInheritance": "*Si no configuras los diarios, se utilizarán los diarios de la configuración general"
		},
		"legend": {
			"lancado": "Ya liberado",
			"ignorado": "ignorado",
			"comDocumentoSemelhante": "Ya existe un documento publicado en esa fecha con el mismo valor.",
			"comConfig": "Con configuración preestablecida o de cuenta"
		},
		"legendsimple": {
			"comConfig": "Con configuración de cuenta"
		},
		"estado": {
			"0": "Declaración que se publicará",
			"1": "Extracto liberado",
			"2": "Extracto ignorado",
			"3": "Extracto que se publicará con el doc.",
			"naoLancado": "Declaración que se publicará",
			"lancado": "Extracto liberado",
			"ignorado": "Extracto ignorado",
			"naoLancadoComDocPossivel": "Extracto que se publicará con el doc."
		},
		"docDigital": {
			"0": "No hay documentos posibles",
			"1": "Con un posible documento",
			"2": "Con varios documentos posibles.",
			"naoTem": "No hay documentos posibles",
			"tem": "Con un posible documento",
			"varios": "Con varios documentos posibles."
		},
		"dataRegistoRecebimentosPagamentos": {
			"label": "Fecha de liberación de recibos y pagos.",
			"dataAtual": "fecha actual",
			"dataMovimentoBancario": "fecha de movimiento bancario"
		},
		"errors": {
			"licenseNotActivatedCGOn": "La empresa actual no tiene activada la licencia de automatización bancaria.<br/>Por favor, acceda a la <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\">tienda</a> CentralGest Cloud y activar o renovar la licencia de automatización bancaria.",
			"licenseCGOnExpired": "Su licencia de automatización bancaria ha caducado.",
			"activateCGBankingLimitReached": "No puedes agregar más empresas a tu licencia de automatización bancaria, porque ya tienes registradas las empresas \"{{consumed}}\" \"{{total}}\".",
			"activateCGBankingCGOnMode": "No se puede activar la automatización bancaria, porque se trata de una empresa CGOn.",
			"emptyNewBankAccountUrl": "No se puede obtener la dirección para agregar una nueva cuenta bancaria.",
			"emptyRenewBankAccountUrl": "No se puede obtener la dirección para renovar la credencial de la cuenta bancaria.",
			"newBankAccount": "No se puede agregar la cuenta bancaria: \"{{error}}\".",
			"emptyBankAccount": "Debes indicar una cuenta bancaria.",
			"emptyDocs": "No ha seleccionado ningún documento para publicar."
		},
		"errorsTinkLink": {
			"BAD_REQUEST": "La URL del enlace Tink está configurada incorrectamente",
			"AUTHENTICATION_ERROR": "No te has autenticado exitosamente con la institución financiera",
			"INTERNAL_ERROR": "Error interno en el servicio Tink",
			"TEMPORARY_ERROR": "Error temporal en el servicio Tink."
		},
		"errorsUnifiedPost": {
			"BAD_REQUEST": "La URL de UnifiedPost está configurada incorrectamente",
			"AUTHENTICATION_ERROR": "No te has autenticado exitosamente con la institución financiera",
			"INTERNAL_ERROR": "Error interno en el servicio UnifiedPost",
			"TEMPORARY_ERROR": "Error temporal en el servicio UnifiedPost."
		},
		"configmodal": {
			"title": "Configuración general",
			"success": "Configuración guardada exitosamente",
			"fields": {
				"descricao": "Descripción predeterminada",
				"movAbAuto": "Abre automáticamente transacciones pendientes al pasar al siguiente registro",
				"showDescritivo": "Campo de descripción visible"
			}
		},
		"activatelicensemodal": {
			"title": "Activar la automatización bancaria en la empresa \"{{nEmpresa}} - {{nomeEmpresa}}\"",
			"message1": "Si hace clic en \"Sí\", el sistema agregará una nueva empresa a su licencia de automatización bancaria y no será posible revertir la situación más adelante.",
			"message2": "En este momento, aún puede agregar {{totalToConsume}} empresas a su licencia de automatización bancaria.",
			"dadosLicenca": "Datos de licencia de automatización bancaria",
			"total": "Número total de empresas: {{total}}",
			"totalConsumed": "Número total de empresas ya agregadas: {{totalConsumed}}",
			"totalToConsume": "Número total de empresas que aún puedes agregar: {{totalToConsume}}"
		},
		"authorizationsmodal": {
			"title": "Consentimientos",
			"fields": {
				"externalId": "Identificador",
				"accountsId": "ID de cuenta",
				"credentialsId": "ID de credenciales",
				"providerName": "Nombre del proveedor",
				"status": "Estado",
				"errorMessage": "Mensaje",
				"errorType": "Tipo",
				"errorReason": "Razón",
				"statusUpdated": "Fecha",
				"sessionExpiryDate": "Fecha de expiración"
			},
			"text": {
				"deleteConsent": "Eliminar consentimiento",
				"deleteConsentMessage": "¿Está seguro de que desea eliminar este consentimiento?<br/>Tendrá que realizar un nuevo consentimiento después de realizar esta operación.",
				"successDeleteConsent": "Consentimiento eliminado exitosamente"
			}
		},
		"docssemelhantesmodal": {
			"title": "Documentos similares ya publicados en contabilidad.",
			"fields": {
				"periodo": "Período",
				"diario": "A diario",
				"nDocInterno": "Número de documento",
				"nDocExterno": "Doc no.",
				"dataLancamento": "fecha de lanzamiento",
				"dataDocExterno": "Fecha del documento",
				"dc": "ANUNCIO",
				"valor": "Valor",
				"descricao": "Descripción",
				"contaCorrente": "Cuenta corriente"
			},
			"text": {
				"mostrarOutrasDatas": "Mostrar documentos con el mismo valor pero publicados con fechas diferentes"
			},
			"actions": {
				"connect": "Asociado"
			}
		},
		"editbankaccountmodal": {
			"title": "Editando cuenta bancaria \"{{nameBank}}\"",
			"fields": {
				"descricao": "Descripción",
				"nomeBanco": "nombre del banco",
				"iban": "IBAN",
				"visivel": "Visible",
				"conta": "Cuenta bancaria"
			},
			"text": {
				"mostrarOutrasDatas": "Mostrar documentos con el mismo valor pero publicados con fechas diferentes"
			},
			"actions": {
				"connect": "Asociado"
			}
		},
		"unifiedPostModal": {
			"title": "Agregar cuenta",
			"fields": {
				"iban": "IBAN",
				"nometitular": "Nombre del titular"
			}
		},
		"importbyreconciliationmodal": {
			"title": "Elegir qué conciliación bancaria importar"
		},
		"movabmodal": {
			"titleReceipt": "Recibos a realizar al cliente.",
			"titlePayment": "Pagos a realizar al proveedor.",
			"save": "Registro",
			"fields": {
				"nContaClient": "cuenta de cliente",
				"nContaSupplier": "Cuenta de proveedor",
				"valorExtrato": "Valor del movimiento bancario",
				"valorSelecionado": "Valor seleccionado",
				"nDocumento": "Documento",
				"descricao": "Descripción",
				"nDocExterno": "Doc no.",
				"dataDoc": "Fecha del doctor.",
				"dataVencimento": "Fecha de expiración",
				"dc": "Débito/Crédito",
				"valor": "Valor",
				"valorPago": "Monto pagado",
				"valorPorPagar": "Monto a pagar",
				"valorAReceber": "Cantidad a recibir",
				"valorAPagar": "cantidad a pagar",
				"valorDesconto": "Descuento",
				"data": "Fecha"
			},
			"text": {
				"receberTudoReceipt": "Recibir todo (atajo \"+\" o \"F9\")",
				"receberTudoPayment": "Pagar todo (atajo \"+\" o \"F9\")",
				"limparValor": "Borrar valor",
				"successReceipt": "Recibo registrado exitosamente",
				"successPayment": "Pago registrado exitosamente"
			},
			"errors": {
				"recPagAmmountNoMatch": "El valor seleccionado \"{{valorSelected}}\" es diferente del valor de la transacción bancaria \"{{amount}}\"."
			}
		},
		"predefdescmodal": {
			"title": "Asignar cuenta predefinida o por descripción de extracto",
			"titlesimple": "Asignar cuenta por descripción del extracto",
			"fields": {
				"descriptionKey": "La descripción contiene",
				"conta": "cuenta de compensación",
				"predefinido": "Predefinido"
			}
		},
		"simplemodefilter": {
			"filter": "Filtrar",
			"onlyentradas": "Ver sólo las entradas",
			"onlysaidas": "Ver solo resultados",
			"onlynaoLancado": "Ver sólo documentos inéditos",
			"onlylancado": "Ver sólo documentos ya publicados"
		}
	},
	"blockedPlugin": {
		"text": {
			"noAccessModule": "No tienes acceso a este módulo debido a:",
			"noAccess": "Esta funcionalidad no está disponible debido a:",
			"noLicenseSingle": "Tu licencia de CentralGest debe tener activo el componente {{portals}}.",
			"noLicenseMulti": "Su licencia de CentralGest debe tener activo uno de los siguientes componentes: {{portales}}.",
			"noLicenseStoreMode": "La empresa actual no tiene una licencia de {{portals}} activa o está caducada.<br/>Por favor, vaya a <a href=\"{{href}}\" target=\"_blank\" rel=\"noopener noreferrer\" CentralGest Cloud >store</a> y active la licencia requerida.",
			"requiredRolesSingle": "Este módulo requiere acceso al portal {{portals}}.",
			"requiredRolesMulti": "Este módulo requiere acceso a {{portals}} portales."
		}
	},
	"cae": {
		"fields": {
			"codCAE": "Código",
			"nome": "Nombre",
			"codSubSec": "Código de subsección"
		}
	},
	"calcPrVenda": {
		"pesquisa": "Tipos de cálculo",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"marginOnSellingPrice": "Margen sobre el precio de venta",
			"marginOnPurchasePrice": "Margen sobre el precio de compra"
		}
	},
	"calculodiferimentos": {
		"intervalo": "Intervalo",
		"filtrosCalculo": "Filtros de cálculo",
		"fields": {
			"valor": "Valor",
			"dataini": "Fecha de inicio",
			"datafim": "Fecha de finalización",
			"tipodiferimento": "Método",
			"tipoDataRefDiferimento": "Fecha de cálculo"
		},
		"metodos": {
			"anual": "Anual",
			"mensal": "Mensual"
		},
		"tipoDataRef": {
			"dataDoDocumento": "Fecha del documento",
			"dataDoLancamento": "fecha de lanzamiento"
		},
		"success": "Cálculo realizado con éxito.",
		"linhas": {
			"dataDoc": "Fecha del doctor.",
			"ano": "Año calendario",
			"numeroDias": "No. de días",
			"valor": "Valor"
		},
		"btn": {
			"lancacontabilidade": "Aplicar al documento"
		},
		"messages": {
			"naobalanceado": "El documento no está equilibrado.",
			"semcontaparadif": "No fue posible encontrar una cuenta con un monto a agregar/diferir."
		},
		"modals": {
			"config": {
				"title": "Configurar acumulaciones y aplazamientos",
				"cardrendimentos": "Ingreso",
				"cardgastos": "Gastos",
				"fields": {
					"novoRadical": "Nuevo radical",
					"radicalrendimentos": "Radical",
					"contaAcrescimos": "Cuenta de acumulaciones",
					"contaDiferimentos": "Aplazamientos de cuentas",
					"radicalgastos": "Radical",
					"contadif": "cuenta de aplazamiento",
					"contaacresc": "Cuenta de acumulaciones",
					"calculo": "Cálculo",
					"nDiario": "Número de diario",
					"descritivoAnulacaoInicial": "Descripción para cancelar la versión inicial",
					"descritivo": "Descripción del valor(es) a diferir",
					"descritivoAcrescimos": "Descripción del valor(es) a agregar"
				},
				"messages": {
					"radicalJaExistente": "El radical insertado ya existe en la lista.",
					"apagarItem": "Eliminar línea",
					"desejaApagarItem": "¿Quieres eliminar la línea?",
					"successfullySaved": "Los cambios se guardaron correctamente.",
					"campoRadicalVazio": "El campo radical no puede estar vacío.",
					"nContaDiferimentosEmpty": "La cuenta de aplazamiento no puede estar vacía.",
					"nContaAcrescimosEmpty": "La cuenta de acumulaciones no puede estar vacía.",
					"nDiarioEmpty": "El diario no puede estar vacío.",
					"descritivoEmpty": "El descriptivo no puede estar vacío."
				}
			},
			"info": {
				"title": "Información de provisiones y aplazamientos",
				"infodetalhada": "Información detallada",
				"infomes": "Información por mes",
				"exportDetalhes": "Información detallada sobre el aplazamiento",
				"exportTotais": "Información de aplazamientos por mes",
				"fields": {
					"nDocumento": "Número de documento",
					"nConta": "Nro. de cuenta",
					"nomeConta": "Nombre",
					"valorDebito": "Deuda",
					"valorCredito": "Crédito",
					"descricao": "Descripción",
					"valor": "Valor",
					"valorAcrescimoNoMes": "Suma",
					"valorDiferimentoNoMes": "Aplazamiento",
					"saldoAcrescimoNoMes": "Saldo devengado",
					"saldoDiferimentoNoMes": "Saldo aplazado"
				}
			}
		}
	},
	"diferimentosglobal": {
		"diferimentos": "Aplazamientos",
		"acrescimos": "Adiciones",
		"acrescimosDiferimentos": "Devengos y Aplazamientos",
		"docsselmes": "Documentos en el mes seleccionado.",
		"docscontribuem": "Documentos que contribuyen al saldo en el mes seleccionado",
		"fields": {
			"nDocumento": "Número de documento",
			"valor": "Valor",
			"descricao": "Descripción"
		},
		"infoAction": "Información de provisiones y aplazamientos"
	},
	"mapadiferimentosacrescimos": {
		"diferimentos": "Aplazamientos",
		"acrescimos": "Adiciones",
		"diferimento": "Aplazamiento",
		"acrescimo": "Suma",
		"acrescimosDiferimentos": "Devengos y Aplazamientos",
		"anon": "Año {{año}}",
		"fields": {
			"nDocumento": "Documento",
			"descricaoDoc": "Descripción",
			"nomeConta": "Nombre de cuenta",
			"nConta": "Cuenta",
			"dataInicio": "Fecha de inicio",
			"dataFim": "Fecha de finalización",
			"dias": "Días",
			"valor": "Valor",
			"valorAno1N": "año anterior",
			"valorAnoN": "año total",
			"valorAnoN1": "Al año que viene",
			"nDocExterno": "Documento externo",
			"dataDocExterno": "Fecha del doctor.",
			"nomeDescriti": "Descriptivo"
		},
		"tableTitle": "Mapa de {{tipoOperacao}} de {{tipodiferimento}}",
		"tipoOperacao": "Tipo de especialización",
		"tipodiferimento": "Naturaleza",
		"btn": {
			"infoglobaldiferimentosacrescimos": "Aplazamientos/adiciones de información global"
		}
	},
	"tipoDiferimento": {
		"naoDefinido": "Gastos e ingresos",
		"rendimento": "Actuación",
		"gasto": "Gastado"
	},
	"calendarios": {
		"title_detail": "Calendario {{id}}",
		"title_plural": "Calendarios",
		"pesquisa": "buscar",
		"fields": {
			"calenCabID": "Código.",
			"nome": "Nombre",
			"sabadosDiaUtil": "Sábados como día laborable.",
			"domingosDiaUtil": "Domingos como día laborable.",
			"naoTemFeriados": "no hay vacaciones"
		}
	},
	"cargveic": {
		"title_detail": "Vehículo",
		"title_new": "Vehículo nuevo",
		"title_plural": "Vehículos",
		"pesquisa": "buscar",
		"saved": "Vehículo almacenado exitosamente.",
		"error": "El vehículo no se puede almacenar.",
		"deleted": "Vehículo borrado exitosamente.",
		"deleteModalText": "¿Eliminar vehículo con matrícula \"{{matricula}}\"?",
		"reboqueJaExiste": "El trailer con número de registro \"{{matricula}}\" ya existe.",
		"reboques": "Remolques",
		"adicionarReboque": "Añadir tráiler",
		"fields": {
			"matricula": "Registro",
			"reboque": "Tráiler",
			"desativado": "Desactivado",
			"nConta": "Nro. de cliente",
			"pesoTara": "Tara del vehículo",
			"nomeTranspor": "Nombre del transportista",
			"nomeViatura": "Nombre del vehículo",
			"modelo": "Modelo",
			"motor": "Motor",
			"marca": "Marca",
			"chassi": "Chasis",
			"kilometros": "Kilómetros",
			"matriculaReboque": "Registro de remolque"
		},
		"btn": {
			"newArtigo": "Nuevo"
		},
		"list": {
			"actions": {
				"editar": "Editar"
			}
		},
		"messages": {
			"temAlteracoes": "Hay cambios aún no guardados.",
			"apagarReboque": "Eliminar tráiler"
		}
	},
	"categoriaartigo": {
		"title_detail": "Artículo de categoría {{id}}",
		"title_new": "Artículo de nueva categoría",
		"title_plural": "Categorías Artículo",
		"pesquisa": "buscar",
		"saved": "Artículo de categoría {{id}}, guardado exitosamente",
		"error": "Artículo de categoría {{id}}, no se puede guardar.",
		"deleted": "Artículo de categoría {{id}}, eliminado correctamente",
		"fields": {
			"categoria": "Categoría",
			"descricao": "Descripción",
			"categoriaPlaceholder": "Categoría",
			"descricaoPlaceholder": "Descripción"
		}
	},
	"categoriaProdutos": {
		"pesquisa": "Categoría Productos",
		"fields": {
			"value": "Código",
			"name": "Descripción"
		},
		"data": {
			"mercadorias": "M - Bienes",
			"materiasPrimas": "P - Materias primas, auxiliares y consumibles",
			"produtosAcabados": "A - Productos terminados e intermedios",
			"subprodutos": "S - Subproductos, desperdicios y desechos",
			"produtosTrabalhosEmCurso": "T - Productos y trabajos en curso",
			"naoAplicaveis": "N/A - No aplicable",
			"ativosBiologicos": "B - Activos biológicos"
		}
	},
	"categorias": {
		"title_detail": "Categoría {{id}}",
		"title_new": "Nueva categoría",
		"title_plural": "Categorías",
		"pesquisa": "buscar",
		"deleted": "Categoría {{id}}, eliminada correctamente",
		"saved": "Categoría {{id}}, guardada exitosamente",
		"error": "Categoría {{id}}, no guardada.",
		"fields": {
			"nCateg": "Categoría No.",
			"designaBreve": "Designación",
			"designaCompl": "Designación completa",
			"codCategoriaMT": "Código de categoría IRCT / DGEEP",
			"nomeCategoria": "Nombre de la categoría IRCT / DGEEP",
			"codIrct": "Código IRCT",
			"nomeIrct": "Nombre del IRCT"
		}
	},
	"cct": {
		"title_detail": "Convenio Colectivo de Trabajo {{id}}",
		"title_new": "Nuevo Convenio Colectivo de Trabajo",
		"title_plural": "Contratos Colectivos de Trabajo",
		"pesquisa": "buscar",
		"saved": "Convenio Colectivo de Trabajo {{id}}, guardado exitosamente.",
		"error": "Convenio Colectivo de Trabajo {{id}}, no se puede guardar.",
		"deleted": "Convenio Colectivo de Trabajo {{id}}, eliminado exitosamente",
		"fields": {
			"ncct": "Código CCT",
			"designaBreve": "Breve descripción",
			"nome": "Diseño.",
			"numBolTrab": "Número de boletín de trabajo",
			"dtBolTrab": "fecha del boletín de trabajo",
			"mediaTabelas": "Promedio de la tabla",
			"dtIniValidad": "Fecha de inicio de validez",
			"codIRCT": "Código IRCT para personal",
			"aplicabilidadeIRCT": "Aplicabilidad de la IRCT"
		},
		"actions": {
			"taboficialirct": "Mesas oficiales del personal del IRCT"
		}
	},
	"ccustos": {
		"title_detail": "Centro de costos {{id}}",
		"title_new": "Nuevo centro de costos",
		"title_plural": "Centro de costos",
		"pesquisa": "buscar",
		"saved": "Centro de costos {{id}}, guardado exitosamente",
		"deleted": "Centro de costos {{id}}, eliminado exitosamente",
		"error": "Centro de costos {{id}}, no se puede guardar.",
		"fields": {
			"nCCusto": "C. Costo",
			"nCCustoPlaceholder": "Código del centro de costos",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del centro de costos",
			"encerrado": "Cerrado"
		}
	},
	"cgasi": {
		"title_detail": "Código de situación de Caixa Geral de Aposentações {{id}}",
		"title_new": "Nuevo Código de Situación de Caixa Geral de Aposentações",
		"title_plural": "Códigos de Situación de Caixa Geral de Aposentações",
		"pesquisa": "buscar",
		"saved": "Código de estado de Caixa Geral de Aposentações {{id}}, guardado correctamente.",
		"error": "Código de estado de Caixa Geral de Aposentações {{id}}, no se puede guardar.",
		"deleted": "Código de estado de Caixa Geral de Aposentações {{id}}, eliminado correctamente.",
		"fields": {
			"codCGASit": "código de estado CGA",
			"nomeCGASit": "Descripción",
			"tipoCodCGASit": "Tipo de código",
			"obs": "Observaciones"
		}
	},
	"changeArtigosFieldsInSerie": {
		"modalTitle": "Cambios de campo de serie",
		"strings": {
			"pesquisa": "Buscar",
			"atualizacao": "Actualizar",
			"relatorio": "Informe",
			"artigosSelecionados": "Artículos seleccionados",
			"artigos": "Artículos",
			"camposAAtualizar": "Campos a modificar",
			"anterior": "Valor anterior",
			"novo": "Nuevo valor",
			"camposAlterados": "Campos modificados",
			"calculoPreco": "Calcular precio",
			"precoComIva": "Precio IVA incluido",
			"precoSemIva": "Precio sin IVA"
		},
		"temSelecionarPeloMenosUmArtigo": "Debe seleccionar al menos un activo.",
		"valorCampoNaoPodeSerNulo": "¡El Valor del campo {{campo}} a modificar no puede ser nulo ni estar vacío!",
		"naoSelecinouNenhumCampoParaAlterar": "No has seleccionado ningún campo para cambiar"
	},
	"classescontratos": {
		"title_detail": "Clase de contrato {{id}}",
		"title_new": "Nueva clase de contrato",
		"title_plural": "Clases de contratos",
		"pesquisa": "buscar",
		"saved": "Clase de contrato {{id}}, guardada exitosamente",
		"error": "Clase de contrato {{id}}, no se puede guardar.",
		"deleted": "Clase de contrato {{id}}, eliminada correctamente",
		"fields": {
			"nClasseCtr": "Código",
			"nClasseCtrPlaceholder": "Código de clase de contrato",
			"nomeClasseCtr": "Nombre",
			"nomeClasseCtrPlaceholder": "Nombre de clase de contrato",
			"classeTipo": "Tipo de clase",
			"classeTipoPlaceholder": "Clase de tipo de contrato"
		}
	},
	"classescontratosservicos": {
		"title_detail": "Clase de pacto {{id}}",
		"title_new": "Nueva clase de acuerdo",
		"title_plural": "Clases de pacto",
		"pesquisa": "buscar",
		"saved": "Clase de Pacto {{id}}, guardada exitosamente",
		"error": "Clase de pacto {{id}}, no se puede guardar.",
		"deleted": "Clase Covenant {{id}}, eliminada exitosamente",
		"fields": {
			"nClasseCtr": "Código",
			"nClasseCtrPlaceholder": "Código de clase de pago, si no se completa se generará automáticamente.",
			"nomeClasseCtr": "Nombre",
			"nomeClasseCtrPlaceholder": "Nombre de la clase de contrato"
		}
	},
	"classescaixa": {
		"title_detail": "Clase de caja {{id}}",
		"title_new": "Nueva clase de efectivo",
		"title_plural": "Clases en efectivo",
		"pesquisa": "buscar",
		"saved": "Clase de caja {{id}}, guardada correctamente",
		"error": "Clase de caja {{id}}, no se puede guardar.",
		"deleted": "Clase de caja {{id}}, eliminada correctamente",
		"fields": {
			"nClasseCaixa": "Código de clase de caja",
			"designacaoBreve": "Breve designación",
			"percentPatr": "Porcentaje del empleador",
			"percentEmpr": "Porcentaje empleado",
			"naoProcSubNatal": "No procesa subs.",
			"naoProcSubFerias": "No procesa subs.",
			"ncrss": "Centro RSS núm.",
			"tipoRegime": "Tipo de régimen",
			"descricao": "Descripción",
			"taxas": "Honorarios",
			"config": "Ajustes",
			"naoVaiSegSocial": "No envía datos al seg.",
			"percentPATR": "Empleador",
			"percentEMPR": "Empleado",
			"percenttitle": "% de contribuciones",
			"codIntegracaotitle": "Integración contable",
			"limites": "Límites a las cotizaciones mensuales en número de salarios mínimos",
			"codIntegracao": "código de integración",
			"temLimiteMaximo": "Tiene un límite máximo",
			"limMaxNSalariosMin": "Sin sal.",
			"temLimiteMinimo": "Tiene un límite mínimo",
			"limMinNSalariosMin": "Sin sal."
		}
	},
	"tipoRegime": {
		"empregados": "Empleados",
		"gerenteMOE": "Gerentes/MOE",
		"primeiroEmprego": "1er trabajo",
		"pensionistas": "Pensionistas",
		"deficientes": "Desactivado",
		"trabEntSFimLuc": "Trabajar.",
		"trabOutros": "Otros trabajos",
		"praticantesDesportivos": "Practicantes de deportes profesionales",
		"servicoDomesticoConvencional": "Servicio doméstico - Remuneración convencional",
		"servicoDomesticoReal": "Servicio doméstico - Salario real"
	},
	"clicls": {
		"title_detail": "Clase de cliente/proveedor {{id}}",
		"title_new": "Nueva clase de cliente/proveedor",
		"title_plural": "Clases de clientes/proveedores",
		"pesquisa": "buscar",
		"saved": "Clase de cliente/proveedor {{id}}, guardado exitosamente.",
		"error": "Clase de cliente/proveedor {{id}}, no se puede guardar.",
		"deleted": "Clase de cliente/proveedor {{id}}, eliminada correctamente.",
		"fields": {
			"codigo": "Código",
			"descricao": "Descripción"
		}
	},
	"clifoExtraValueTipos": {
		"fields": {
			"tipo": "Tipo",
			"descricao": "Descripción",
			"natureza": "Naturaleza",
			"codigo": "Código"
		}
	},
	"clifoExtraValues": {
		"save": "¡Guardado exitosamente!",
		"errorEmailVazio": "El correo electrónico no puede ser nulo.",
		"errorEmailJaExiste": "El correo electrónico ya existe.",
		"errorEmailInvalid": "El correo electrónico debe ser válido.",
		"modal": {
			"title": "Agregar correo electrónico",
			"editTitle": "Editar correos electrónicos ({{title}})",
			"table": {
				"fields": {
					"email": "Correo electrónico"
				}
			}
		},
		"modalConfirmDelete": {
			"title": "¡Atención!",
			"message": "¿Está seguro de que desea eliminar este registro?"
		},
		"fields": {
			"valor": "Correo electrónico",
			"codigo": "Tipo"
		},
		"table": {
			"descricao": "Tipo de correo electrónico",
			"valor": "Valor"
		},
		"btn": {
			"adicionar": "para agregar",
			"remover": "Eliminar",
			"addEmail": "Agregar correo electrónico",
			"new": "Nuevo"
		},
		"tooltips": {
			"addNewTypeEmail": "Agregar tipo de correo electrónico",
			"editTypeEmail": "Editar tipo de correo electrónico",
			"removeTypeEmail": "Eliminar tipo de correo electrónico"
		}
	},
	"clifos": {
		"pesquisa": "buscar",
		"tab": {
			"ficha": "Registro",
			"dadosconta": "Detalles de la cuenta",
			"extratos": "Extractos",
			"artigos": "Artículos",
			"anexos": "Adjuntos",
			"encomendas": "Órdenes"
		},
		"title_detail": "Cliente/Proveedor {{id}}",
		"title_new": "Nuevo cliente/proveedor",
		"title_plural": "Clientes / Proveedores",
		"saved": "Cliente/Proveedor {{id}}, guardado exitosamente",
		"error": "Cliente/Proveedor {{id}}, no se puede guardar.",
		"deleted": "Tercer {{id}}, eliminado exitosamente",
		"navigation": {
			"tooltipFirst": "Primero",
			"tooltipPrevious": "Anterior",
			"tooltipNext": "Siguiente",
			"tooltipLast": "Último"
		},
		"groups": {
			"indentificacao": "Identificación",
			"estadoAtVies": "Estado en VIES",
			"dadosprincipais": "Datos principales",
			"dadosfinan": "Datos financieros",
			"condECaract": "Características",
			"classificadores": "Clasificadores",
			"moradasAlt": "Direcciones alternativas",
			"tiposEmail": "Tipos de correo electrónico"
		},
		"fields": {
			"nConta": "Nro. de cuenta",
			"nContaPlaceholder": "Número de cuenta",
			"nIdAltern": "Identificación alternativa.",
			"nIdAlternPlaceholder": "Número de identificación alternativo",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre",
			"nContribuint": "número de contribuyente",
			"nContribuintPlaceholder": "número de contribuyente",
			"rua": "Camino",
			"ruaPlaceholder": "Camino",
			"codPostal": "Código Postal",
			"codPostalPlaceholder": "Código postal",
			"localidade": "Ubicación",
			"localidadePlaceholder": "Ubicación",
			"telefone": "Teléfono",
			"telefonePlaceholder": "Teléfono",
			"fax": "Fax",
			"faxPlaceholder": "Fax",
			"nomePais": "País",
			"nomePaisPlaceholder": "País",
			"email": "Correo electrónico",
			"emailPlaceholder": "Correo electrónico",
			"codRet": "Código de retención",
			"codRetPlaceholder": "Código de tasa de retención",
			"situacaoActual": "Situación actual",
			"desactivado": "Desactivado",
			"naoPodeFacturar": "no puedo facturar",
			"codCondComerciais": "Cond.",
			"codCondComerciaisPlaceholder": "Código de condición comercial",
			"codPagamento": "Cond.",
			"codPagamentoPlaceholder": "Código de condición de pago",
			"observacoes": "Observaciones",
			"observacoes2": "Observaciones (Cont.)",
			"idSubGrContaCorrente": "La naturaleza cuenta",
			"idSubGrContaCorrentePlaceholder": "El registro seleccionado genera número de cuenta según la naturaleza",
			"iban": "IBAN",
			"ibanPlaceholder": "IBAN",
			"swift": "RÁPIDO",
			"swiftPlaceholder": "RÁPIDO",
			"nomeMoeda": "Código de moneda de facturación",
			"nomeMoedaPlaceholder": "Código de moneda de facturación",
			"facPublicaModelo": "Modelo EDI / Factura electrónica pública",
			"idideChav": "grupo de cuentas",
			"idideChavPlacholder": "grupo de cuentas",
			"nTxtCabeca": "Encabezado de aviso de facturación",
			"nTxtCabecaPlacholder": "Encabezado de aviso de facturación",
			"nTxtPE": "Pie de página de aviso de facturación",
			"nTxtPEPlacholder": "Pie de página de aviso de facturación",
			"codZona": "Zona",
			"codVendedor": "Vendedor",
			"ruaCont": "Calle (Continuación)",
			"telemovel": "Teléfono móvil",
			"telemovelPlaceholder": "Teléfono móvil",
			"wwwSite": "Página web",
			"wwwSitePlaceholder": "Página web",
			"utilizaIVAFichaClifo": "Aplicar tasa de IVA en el formulario",
			"codIva": "tipo de IVA",
			"obrigaNumRequisicao": "Requiere \"Solicitud\"",
			"classeClifo": "Clase",
			"situacaoComercial": "Situación comercial",
			"nifSituacaoAtDescricao": "EN",
			"nifSituacaoViesDescricao": "INCLINACIÓN",
			"autoFaturacao": "Autofacturación",
			"textoFaturacao": "notas de facturación",
			"sector": "Sector"
		},
		"errors": {
			"contaNaoExiste": "La cuenta no existe",
			"nifInvalido": "¡NIF no válido!"
		},
		"avisodadosrepetidosmodal": {
			"title": "Cliente/Proveedor con datos repetidos",
			"bodyMessage": "Ya existen clientes/proveedores con el mismo número o nombre de contribuyente."
		},
		"anexos": "Adjuntos",
		"selectNavTipoEmail": "Ir a tipos de correo electrónico",
		"text": {
			"iban": "Obligatorio para pagos SEPA a proveedores",
			"modeloFatPublic": "El comportamiento de facturación cambia según el modelo utilizado: Manual - CIUS-PT / Webservice - Saphety y FEAP",
			"aplicaTaxaIva": "Ignora el tipo de IVA del artículo.",
			"codRetencao": "Información de facturación adicional",
			"codMoeda": "Información adicional sobre facturación.",
			"textoFaturacao": "Texto visible al emitir documentos."
		},
		"btn": {
			"validaNif": "Validar NIF en VIES",
			"btnNewMoral": "Nuevo"
		},
		"nifInvalidoModal": {
			"title": "NIF no válido",
			"bodyMessage": "El NIF {{nContribuint}} no es Válido.<br/>¿Aún quieres registrar el NIF como No Válido/Inexistente?"
		}
	},
	"clientes": {
		"pesquisa": "buscar",
		"tab": "Registro",
		"title_detail": "Cliente {{id}}",
		"title_new": "Nuevo cliente",
		"title_plural": "Clientes",
		"title_singular": "Cliente",
		"saved": "Cliente {{id}}, guardado exitosamente",
		"error": "Cliente {{id}}, no se puede guardar.",
		"deleted": "Cliente {{id}}, eliminado exitosamente",
		"showZona": "Mostrar campo de zona",
		"showDesativados": "Mostrar solo deshabilitado",
		"showVendedor": "Mostrar campo de vendedor",
		"showSetor": "Muestra el campo del sector."
	},
	"fornecedores": {
		"pesquisa": "buscar",
		"tab": "Registro",
		"title_detail": "Proveedor {{id}}",
		"title_new": "Nuevo proveedor",
		"title_plural": "Proveedores",
		"title_singular": "Proveedor",
		"saved": "Proveedor {{id}}, guardado exitosamente",
		"error": "Proveedor {{id}}, no se puede guardar.",
		"deleted": "Proveedor {{id}}, eliminado exitosamente",
		"showDesativados": "Mostrar solo deshabilitado"
	},
	"outrosdevedorescredores": {
		"pesquisa": "buscar",
		"title_detail": "Otro Deudor/Acreedor {{id}}",
		"title_new": "Nuevo deudor/acreedor",
		"title_plural": "Deudores/acreedores",
		"title_singular": "Deudor/acreedor",
		"saved": "Deudor/Acreedor {{id}}, guardado exitosamente",
		"error": "Deudor/Acreedor {{id}}, no se puede guardar.",
		"deleted": "Deudor/Acreedor {{id}}, eliminado exitosamente"
	},
	"modal": {
		"txtFaturacao": {
			"title": "notas de facturación"
		}
	},
	"cltrb": {
		"title_detail": "Clase de trabajo {{id}}",
		"title_new": "Nueva clase de trabajo",
		"title_plural": "Clases de Trabajo",
		"pesquisa": "buscar",
		"saved": "Clase de trabajo {{id}}, guardada correctamente.",
		"error": "Clase de trabajo {{id}}, no se puede guardar.",
		"deleted": "Clase de trabajo {{id}}, eliminada correctamente.",
		"fields": {
			"nclasTrab": "código de clase",
			"designaBreve": "Breve designación",
			"designaCompl": "Designación completa"
		}
	},
	"codigoscontabilisticos": {
		"title_detail": "Código contable {{id}}",
		"title_new": "Nuevo código contable",
		"title_plural": "Códigos contables",
		"pesquisa": "buscar",
		"saved": "Código de contabilidad {{id}}, guardado exitosamente.",
		"error": "Código de contabilidad {{id}}, no se puede guardar.",
		"deleted": "Código de cuenta {{id}}, eliminado correctamente.",
		"fields": {
			"codcontab": "Código",
			"nome": "Nombre"
		},
		"placeholders": {
			"codcontab": "código contable",
			"nome": "Nombre del código contable"
		}
	},
	"codpostais": {
		"title_detail": "Código postal {{id}}",
		"title_new": "Nuevo código postal",
		"title_plural": "códigos postales",
		"pesquisa": "buscar",
		"saved": "Código postal {{id}}, guardado correctamente",
		"error": "Código postal {{id}}, no se puede guardar.",
		"deleted": "Código postal {{id}}, eliminado correctamente",
		"fields": {
			"codPostal": "Código Postal",
			"codPostalPlaceholder": "Código postal",
			"localidade": "Ubicación",
			"localidadePlaceholder": "localidad del código postal",
			"codPais": "Código del país",
			"nomePais": "País"
		}
	},
	"colaboradores": {
		"pesquisa": "buscar",
		"title": "Colaboradores",
		"sentSuccess": "Enviado exitosamente",
		"uploadSuccess": "Archivo cargado exitosamente",
		"uploadError": "Se produjo un error al intentar cargar el archivo.",
		"comunicacoesinternas": {
			"titlenew": "Nueva comunicación interna",
			"title": "Comunicación interna",
			"fields": {
				"tipoComunicacao": "Tipo de comunicación",
				"titulo": "Título",
				"texto": "Texto"
			},
			"you": "Tú",
			"confirmDeleteMessage": "¿Estás seguro de que deseas eliminar la comunicación?",
			"anexo": "Anexo"
		},
		"docsplata": {
			"title": "Documentos.",
			"text": {
				"servicos": "Servicios",
				"servico": "Servicio",
				"papeis": "Papeles",
				"papel": "Papel"
			},
			"modal": {
				"title": "Nuevo documento de la plataforma de RRHH",
				"titulo": "Título",
				"dataValidade": "Fecha de caducidad",
				"errorFiles": "Debes subir al menos un archivo.",
				"errorServicosSelected": "Debes elegir al menos un servicio al que estará asociado el documento.",
				"errorPapeisSelected": "Debe elegir al menos un rol al que se asociará el documento."
			}
		},
		"board": {
			"fields": {
				"servico": "Servicio",
				"dadosColaborador": "Datos del colaborador",
				"colaborador": "Colaborador",
				"saldoFerias": "Saldo de vacaciones",
				"feriasGozadas": "Disfrutamos de las vacaciones",
				"faltas": "Faltas",
				"horasSemanais": "Horas semanales",
				"localTrabalho": "Lugar de trabajo",
				"tipoHorario": "tipo de horario",
				"tipoContrato": "Tipo de contrato",
				"validadeContrato": "Validez del contrato",
				"centroCusto": "centro de costos",
				"reparticao": "Descomponer",
				"ferias": "Vacaciones",
				"abonos": "Asignaciones"
			},
			"msg": {
				"nenhumColabSelected": "Ningún colaborador seleccionado"
			}
		}
	},
	"comcategorias": {
		"title_detail": "Categoría de comunicación",
		"title_new": "Nueva categoría de comunicación",
		"title_plural": "Categorías de comunicaciones",
		"pesquisa": "buscar",
		"saved": "Categoría de comunicación, guardada exitosamente.",
		"error": "Categoría de comunicación, no se puede guardar.",
		"deleted": "Categoría de comunicación, eliminada correctamente.",
		"categoriaSistema": "Las categorías del sistema no se pueden editar",
		"fields": {
			"nome": "Categoría",
			"nEmpresa": "Empresa No.",
			"tipo": "Tipo",
			"cor": "Color",
			"todasEmpresas": "Disponible para todas las empresas"
		},
		"tipoComCategorias": {
			"data": {
				"none": "No definido",
				"sistema": "Sistema",
				"utilizador": "Usuario"
			}
		}
	},
	"companystatus": {
		"header": "La empresa \"<em>{{nCompany}} - {{NameCompany}}\"</em> no está disponible debido a la programación o ejecución de la siguiente tarea",
		"status": "Estado",
		"tasks": {
			"ECGOnFimAno": {
				"title": "fin de año",
				"state": {
					"fasInactive": "Inactivo",
					"fasScheduled": "Programado",
					"fasStarted": "Correr...",
					"fasEnded": "Finalizado"
				}
			}
		},
		"text": {
			"back": "para volver",
			"description": "Tarea solicitada en <em>\"{{requested}}\"</em> por el usuario <em>\"{{user}}\"</em> para la empresa <em>\"{{company}}\"</em>.",
			"scheduled": "Programado para comenzar a las <em>\"{{scheduled}}\"</em>."
		},
		"cancel": {
			"title": "Cancelar cita",
			"message": "¿Está seguro de que desea cancelar la programación de la tarea \"{{taskName}}\"?"
		}
	},
	"components": {
		"usernav": {
			"name": "¡Hola, {{nombre}}!",
			"store": "Gestiona tus planes y empresas",
			"showRecentCompanies": "Mostrar empresas recientes",
			"hideRecentCompanies": "Ocultar empresas recientes",
			"theme": "Tema"
		},
		"cgmodal": {
			"choosecountry": {
				"title": "Seleccionar país",
				"btn": "Copiar plantilla",
				"codigo": "Código.",
				"designacaoPT": "Designación (PT)",
				"codigoAlpha2": "ISO3166 Alfa-2",
				"codigoAlpha3": "ISO3166 Alfa-3",
				"designacao": "Idioma del nombre"
			}
		},
		"filter": {
			"advancedFilter": "Filtro avanzado"
		},
		"multiselect": {
			"available": "Disponible",
			"selected": "Seleccionado"
		},
		"devextreme": {
			"datagrid": {
				"preferences": "Ajustes",
				"statestoring": {
					"clear": "Borrar configuración"
				}
			}
		},
		"contabilidade": {
			"listagens": {
				"account": "Cuenta",
				"fromAccount": "cuenta",
				"fromContrib": "Del contribuyente",
				"fromPeriod": "período de",
				"fromdiary": "diario",
				"toPeriod": "Periodo hasta"
			},
			"balancetes": {
				"accountTypes": "Tipos de cuenta",
				"yearsToCompare": "Años para comparar",
				"hideAccountsZeroBalance": "Ocultar cuentas con saldo cero",
				"calculaSaldosIniciaisAnoNMaisUm": "Calcule los saldos iniciales de {{nextYear}} con el año {{year}} abierto"
			},
			"extratosgrid": {
				"contaInvalida": "Campo de cuenta no válido.",
				"linhas": {
					"nConta": "Cuenta",
					"nomeConta": "Nombre de cuenta",
					"nDocExterno": "Documento externo.",
					"dataVenc": "Fecha de expiración",
					"descricao": "Descripción",
					"valorDebito": "Monto del débito",
					"valorCredito": "Valor del crédito",
					"porPagar": "No pagado",
					"dataDoc": "Fecha del doctor.",
					"moeda": "Acuñar",
					"nCCusto": "NCCosto",
					"valorDebitoSegMoeda": "Monto del débito seg.",
					"valorCreditoSegMoeda": "Valor del crédito seg.",
					"cambioSegMoeda": "Intercambio lunes.",
					"descritivo": "Descriptivo",
					"perioDiarioDoc": "Número de documento",
					"temDocDigital": "documento digital",
					"stampInsert": "fecha de inserción",
					"periodo": "Período",
					"diario": "A diario",
					"ndocInterno": "Doc. Interno."
				},
				"options": {
					"showDiarios": "Mostrar diarios"
				},
				"btn": {
					"processar": "Proceso",
					"showpdf": "Procesar PDF",
					"sendMail": "Enviar correo electrónico"
				}
			}
		},
		"keybindingshelper": {
			"header": "Teclas de acceso directo"
		},
		"configOptions": {
			"title": "Opciones",
			"revertToDefault": "Volver a las opciones predeterminadas"
		},
		"genericImporter": {
			"title": "{{tipo}} importar",
			"steps": {
				"downloadModel": {
					"title": "Descargar plantilla",
					"content": {
						"1": "Bienvenido al asistente de importación de {{type}}.",
						"2": "Haga clic en el botón a continuación para descargar la plantilla de importación {{type}}.",
						"3": "Después de descargar el archivo, rellénelo con los datos que desea importar.",
						"4": "Atención: Debes tener un software de edición de hojas de cálculo."
					}
				},
				"uploadModel": {
					"title": "Subir plantilla completa",
					"content": {
						"1": "Complete el archivo con los datos que desea importar.",
						"2": "Después de completar el archivo, cárguelo para continuar con la importación."
					}
				},
				"analyzeErrors": {
					"title": "Analizar errores",
					"content": {
						"2": "Para analizar el archivo con errores, descárguelo haciendo clic en el botón a continuación.",
						"1.1": "Se produjo al menos un error.",
						"1.2": "al importar {{tipo}}."
					}
				},
				"success": {
					"title": "Finalizar",
					"content": {
						"1": "La importación de {{type}} se completó correctamente.",
						"2": "Haga clic en \"Finalizar\" para finalizar el proceso de importación de {{type}}."
					}
				}
			},
			"types": {
				"0": "artículos",
				"1": "clientes",
				"2": "proveedores",
				"3": "otros deudores/acreedores",
				"4": "colaboradores",
				"5": "cuentas corrientes de clientes",
				"6": "cuentas corrientes de proveedores",
				"7": "matriz de conversión"
			},
			"actions": {
				"downloadModel": "Descargar plantilla de importación",
				"downloadErrors": "Descargar archivo con errores de importación",
				"retry": "Intentar otra vez",
				"import": "Importar"
			},
			"errors": {
				"requiredUploadModel": "Para continuar con la importación, debes subir el archivo con los datos que deseas importar."
			}
		},
		"scheduler": {
			"text": {
				"colaborador": "Colaborador"
			}
		},
		"refeicoesscheduler": {
			"header": {
				"previous": "Anterior",
				"today": "Hoy",
				"next": "Próximo"
			}
		},
		"panelmultipleselectionexecute": {
			"labels": {
				"selectedDocs": "Documentos seleccionados"
			},
			"actions": {
				"lancarDocs": "Publicar documentos",
				"lancarDoc": "Publicar documento"
			}
		},
		"userSatisfaction": {
			"suggestions": "Comentarios y sugerencias",
			"feedback": "Danos tu opinión"
		}
	},
	"concelhos": {
		"title_detail": "Consejo {{id}}",
		"title_new": "Nuevo Municipio",
		"title_plural": "Condados",
		"pesquisa": "buscar",
		"saved": "Municipio {{id}}, guardado exitosamente",
		"error": "Consejo {{id}}, no se puede guardar.",
		"deleted": "Consejo {{id}}, eliminado exitosamente",
		"fields": {
			"codConcelho": "código de condado",
			"nomeConcelho": "Nombre del municipio",
			"codDistrito": "código de distrito",
			"nomeDistrito": "Distrito",
			"taxaDerrama": "La tasa se vierte",
			"codConcelhoAT": "En el condado"
		},
		"cardderrama": "Cálculo de la tasa de vertido",
		"modal": {
			"title": "Cálculo de la tasa de vertido",
			"editardados": "Editar datos",
			"fields": {
				"tipoDadosSTR": "Tipo",
				"valor": "Valor",
				"condicaoCumprida": "DE ACUERDO",
				"tipoTaxaSTR": "Tipo de tarifa",
				"taxa": "Tasa",
				"ambito": "Alcance"
			}
		}
	},
	"conciliacaoBancaria": {
		"mostrarLinhasConciliadasLabel": "Mostrar líneas coincidentes",
		"addDocNaoConciliadosTitle": "Agregar documentos no conciliados de períodos anteriores",
		"addExtradoBancario": "para agregar",
		"addLinhaExtradoBancario": "Agregar entrada en contabilidad y banco",
		"addLancPrimeiraConciliacaoExtradoBancario": "Agregar la primera entrada de conciliación bancaria",
		"addExtradoBancarioBtn": "para agregar",
		"novaConciliacao": "Nueva conciliación",
		"printConciliacao": "Imprimir conciliación",
		"print": "Imprimir",
		"editarConciliacao": "Editar conciliación",
		"refreshConciliacao": "Actualizar conciliación",
		"eliminaConciliacao": "Eliminar",
		"terminarConcilidacao": "Finalizar",
		"terminadaConcilidacao": "Conciliación completada.",
		"terminarConcilidacaoModalMsg": "¿Estás seguro de que quieres poner fin a la conciliación?",
		"eliminaConciliacaoModalMsg": "¿Está seguro de que desea eliminar la coincidencia \"{{name}}\"?",
		"eliminaTodasConciliacoesModalMsg": "¿Está seguro de que desea eliminar todas las conciliaciones de la cuenta \"{{conta}}\"?",
		"selectConciliacaoMessage": "Seleccione el banco que desea conciliar o cree una nueva conciliación bancaria.",
		"seraLancadaNaConta": "La nueva línea se publicará en la cuenta.",
		"seraCriadaNovaConta": "Se creará una nueva cuenta",
		"searchByValue": "Buscar por valor...",
		"addDocsNaoConciliadosPeriodoAnt": "Agregar documentos no conciliados de períodos anteriores",
		"addDocsNaoConciliadosPeriodoAntBtn": "Agregar documento(s)",
		"naoExistemDocs": "No existen documentos no conciliados de períodos anteriores",
		"totalSelecionado": "Total seleccionado",
		"hideTreeText": "Ocultar árbol",
		"showTreeText": "Mostrar árbol",
		"removeTodasLinhasExtratoBancoTitle": "Para apagar",
		"removeTodasLinhasExtratoBancoMsg": "¿Está seguro de que desea eliminar todas las líneas?",
		"extratoContabilidadeTitle": "extracto contable",
		"extratoBancoTitle": "Extracto de cuenta",
		"viewDocTooltip": "Ver comunicado",
		"viewDocModalTitle": "Ver documento",
		"associarExtratoTooltip": "Declaración asociada",
		"associarLinhasDosExtratosEmAmbosLadosTooltip": "Los asociados seleccionaron líneas de extracción en ambos lados",
		"removeLinhaExtratoBancoTooltip": "Quitar línea",
		"eliminarTodasConciliacoesBanco": "Eliminar todas las conciliaciones bancarias",
		"lancamentoContab": "asiento contable",
		"printModalTitle": "Imprimir",
		"deNConta": "Número de cuenta",
		"dePeriodo": "período de",
		"applyFilters": "Aplicar filtros",
		"saldoExtratoBancarioPerspetivaBancoTitle": "Saldo del extracto bancario desde la perspectiva del banco",
		"periodoNaoEValidoDeveIndicarOPeriodpSeg": "El periodo no es válido, deberá indicar el periodo siguiente a la última conciliación",
		"legendaLabel": "Subtítulo",
		"adicionarLinhaPrimeiraConcilExtratoCG": "Agregar la primera línea de conciliación al estado contable",
		"adicionarDocsPrimeiraConcilExtratoCG": "Agregar los primeros documentos de conciliación",
		"importaDocsPendentesConcilAntiga": "Importar documentos pendientes en la antigua contabilidad de conciliación",
		"extratoContaPendentes": "Estado de cuenta pendiente",
		"importaDocsPendentesBancoConcilAntiga": "Importar documentos pendientes en el antiguo banco de conciliación",
		"importarExtratoBancario": "Importar extracto bancario",
		"adicionarLancamentoBanco": "Agregar entrada bancaria",
		"conciliarAutomaticamente": "Conciliar automáticamente",
		"addLinhaPrimeiraConcilExtratoCGModalTitle": "Agregar la primera línea de conciliación a la cuenta de Contabilidad",
		"editLinhaPrimeiraConcilExtratoCGModalTitle": "Editar la primera línea de conciliación a la cuenta contable",
		"lancNaContaContabilidadeNaPerspetivaEmpresaTitle": "Entrada en la cuenta contable desde la perspectiva de la empresa",
		"tipoMovimentoNaPerspectivaEmpresaTitle": "Tipo de movimiento desde la perspectiva de la empresa",
		"oValorMovimentoNaoPodeSer0ouInferior": "El valor del movimiento no puede ser 0 o menos.",
		"editLanc": "Editar lanzamiento",
		"removeLinha": "Quitar línea",
		"desassociarLinha": "Línea disociada",
		"editLinhaExtradoBancarioModalTitle": "Editar línea del extracto bancario",
		"extPocItemNotFound": "Entrada contable no encontrada",
		"adicionarDocsExtratoBancarioAContabilidadeModalTitle": "Agregar documentos de extractos bancarios a la contabilidad",
		"maisInfoSobreSaldos": "Haga clic para ver más detalles sobre saldos",
		"viewSaldoDetalhesModalTitle": "Detalles del saldo de conciliación",
		"saldoContabilidade": "Saldos contables",
		"saldoBanco": "Saldos bancarios",
		"associarTodoExtratoCGAoExtradoDoBanco": "Las líneas que no estén conciliadas en el extracto de la empresa se crearán en el extracto bancario y se conciliarán automáticamente.",
		"removerTodasLinhasExtrato": "Eliminar todas las líneas del extracto bancario",
		"temQueSelecionarLinhas": "Hay que seleccionar líneas para realizar la operación.",
		"sAntesDeProsseguirDeveVerificarSe": "Antes de continuar, debe verificar si la última conciliación (Módulo Antiguo) es correcta.",
		"s01Contrapartida2": "{{radical}}{{nconta}} - Colgantes sin clasificar.",
		"adicionarLinhaExtratoBancario": "Agregar línea al extracto bancario",
		"adicionarDocsDoExtratoBancarioAContabilidade": "Agregar documentos de extractos bancarios a la contabilidad",
		"saldosNaPerspetivaDoBanco": "Saldos desde la perspectiva del banco",
		"import": "Extracto de importación",
		"noFiles": "Seleccione el archivo a procesar...",
		"importTab": "Importador",
		"importResultsTab": "Resultados",
		"osSaldosDoExtratoBancarioImporta": "Los saldos del extracto bancario importado no coinciden con los saldos indicados en la conciliación.",
		"adicionarAContabilidade": "Agregar a Contabilidad",
		"ordenarPorDataDocExt": "Ordenar por fecha del documento.",
		"ordenarPorValor": "Ordenar por valor",
		"ordenarPorData": "Ordenar por fecha",
		"ordenarPorDescricao": "Ordenar por descripción",
		"ordenarPorNDoc": "Ordenar por número de documento",
		"sMsgOQueFazercomVariaslinhas": "Haga clic en Sí para conciliar las líneas seleccionadas y mostrar solo una línea de suma en el lado del banco.",
		"conciliadoLabel": "reconciliado",
		"configImportModalTitle": "Configuración de plantilla de importación de extractos",
		"configDebitoCreditoNaPerspectivaBancoTitle": "Configuración de débito y crédito desde la perspectiva del banco.",
		"comoEObtidoValorMovimentoNoExtrato": "¿Cómo se obtiene el valor del movimiento en el enunciado?",
		"configImportTooltip": "Configurar importación",
		"estaNaPropriaColunaLabel": "Está en la propia columna.",
		"estaEmColunasSeparadasLabel": "esta en columnas separadas",
		"estEmUmaColunaENoutraColunaLabel": "Está en una columna y la identificación está en otra columna.",
		"removeTemplatePromptMsg": "¿Está seguro de que desea eliminar la plantilla?",
		"templateSavedMsg": "El modelo se guardó correctamente.",
		"templateRemovedMsg": "La plantilla se eliminó correctamente.",
		"outrosanos": "Otros años",
		"contaPendentesBanco": "cuenta bancaria pendiente",
		"configContaPendentesBanco": "Configurar cuenta bancaria pendiente",
		"radicalContaGuardadoComSucesso": "La cuenta radical se guardó exitosamente.",
		"fields": {
			"nconta": "Cuenta",
			"periodo": "Período",
			"dataInicio": "Fecha de inicio",
			"dataFim": "Fecha de finalización",
			"saldoInicialBanco": "Saldo inicial en el banco.",
			"saldoFinalBanco": "Saldo final en el banco.",
			"saldoInicial": "Saldo inicial",
			"saldoFinal": "saldo final",
			"saldoFinalCalc": "Saldo final calculado",
			"dc": "ANUNCIO",
			"valor": "Valor",
			"descricao": "Descripción",
			"conciliado": "reconciliado",
			"tipo": "Tipo",
			"radicalConta": "Cuenta radical",
			"contaBanco": "Cuenta bancaria",
			"diario": "A diario",
			"descritivo": "Descriptivo",
			"tipoMovimento": "Tipo de movimiento desde la perspectiva del banco",
			"ndoc": "Doc No.",
			"nDocExterno": "Ext. doc. no.",
			"dataLacam": "Fecha de lanzamiento",
			"dataDocExt": "Fecha del doctor.",
			"nSeq": "Sec.",
			"stampUpdate": "Tiempo conciso.",
			"diferencaConciliacao": "Diferencia de la conciliación",
			"valorSemDC": "Valor sin firmar",
			"paramImport": "Importar parámetros",
			"message": "Mensaje",
			"template": "Modelo",
			"numPrimeiraLinhaDados": "No. de 1.ª línea de datos",
			"colunaDataMovimento": "Columna de fecha de movimiento",
			"colunaDataValor": "Columna de valor de fecha",
			"colunaDescrição": "Columna de descripción",
			"colunaSaldo": "Columna de equilibrio",
			"colunaValorDoMovimento": "Columna de valor de movimiento",
			"seValorNegativoInverteSinalELancaA": "Si un valor negativo invierte el signo y lanza el",
			"seValorPositivoNaoInverteSinalELancaA": "Si un valor positivo no invierte el signo y lanza el",
			"colunaValorADebito": "Columna de monto de débito",
			"colunaValorACredito": "Columna de monto de crédito",
			"inverteSinalDoValorADebito": "Invierte el signo del importe del débito",
			"colunaDoTipoDeMovimento": "Columna de tipo de movimiento",
			"caraterParaDebito": "Carácter de débito",
			"caraterParaCredito": "Carácter para crédito"
		},
		"config": {
			"showColumnDescricao": "Mostrar la columna \"Descripción\" en la cuadrícula del estado contable",
			"showColumnNDocExt": "Mostrar la columna \"Número de documento externo\" en la cuadrícula del estado contable",
			"showColumnTipo": "Mostrar la columna \"Tipo\" en la cuadrícula del extracto bancario",
			"showColumnSaldo": "Mostrar la columna \"Saldo\" en la cuadrícula del extracto bancario",
			"showColumnStampUpdate": "Mostrar la columna \"Hora de conciliación\" en la cuadrícula del extracto bancario",
			"showColumnDataLanc": "Mostrar la columna \"Fecha de lanzamiento\" en la cuadrícula del estado contable",
			"showColumnData": "Mostrar la columna \"Fecha\" en la cuadrícula del estado contable",
			"showColumnValorSemDC": "Mostrar la columna \"Valor sin signo\" en la cuadrícula del estado contable",
			"showColumnBancoValorSemDC": "Mostrar la columna \"Monto sin signo\" en la cuadrícula del extracto bancario",
			"showColumnDC": "Mostrar la columna \"DC\" en la cuadrícula del estado contable",
			"showColumnBancoDC": "Mostrar la columna \"DC\" en la cuadrícula del extracto bancario"
		},
		"btns": {
			"toolbarConciliacaoBtn": "Acciones de conciliación",
			"selecionados": "Seleccionado...",
			"toolbarShortcuts": "Atajos",
			"toolbarBancoPoc": "Ver plan de cuenta bancaria",
			"toolbarPesqDocs": "Búsqueda de documentos"
		},
		"messages": {
			"deleteSelectedLine": "¿Quieres eliminar la línea seleccionada?"
		},
		"tabMainTitle": "Conciliación",
		"tabAttachmentsTitle": "Adjuntos",
		"fromTo": "{{de}} a {{a}}"
	},
	"conciliacaoEFaturas": {
		"tabDocsEFaturaCaption": "Documentos en e-Fatura no relacionados con contabilidad",
		"tabJaLancadosCaption": "Documentos ya ingresados ​​en contabilidad o ignorados.",
		"viewDocModalTitle": "Vista de documento {{ndoc}}",
		"viewDocModalTitleSimple": "Vista previa del documento",
		"extratosModalTitle": "Estados de cuenta",
		"pesquisaDocPossivelModalTitle": "Búsqueda de documentos contables",
		"expandAllBtn": "Expandir todo",
		"associarTodosPossiveis": "Asociar documentos con 1 documento posible",
		"associarTodosPossiveisPromptMsg": "Esta operación asociará automáticamente los documentos e-Fatura a un posible documento.<br>Siempre debe comprobar documento por documento si el posible documento asociado corresponde al documento e-Fatura.<br><br>¿Está seguro de que desea ¿continuar?",
		"associarTodosPossiveisNoSourceTitle": "Asociación automática...",
		"associarTodosPossiveisNoSourceMsg": "No tienes documentos o tienes documentos con más de 1 documento posible.",
		"associarDocSemAlterarDocExterno": "Asociar documento sin cambiar documento externo",
		"associarDocComAlteracaoDocExterno": "Asociar documento con cambio de documento externo",
		"verDocumento": "Ver documento",
		"deixarIgnorarDocumento": "Deja de ignorar este documento",
		"deixarIgnorarPorFornecedor": "Deja de ignorar documentos de este proveedor",
		"removeAssociacao": "Eliminar asociación de este documento",
		"withoutPossibleDocs": "No hay documentos posibles",
		"filtropesq": "Buscar",
		"messages": {
			"docAssociadoSuccessMsg": "El documento {{ndoc}} ha sido vinculado.",
			"docsAssociadoSuccessMsg": "Todos los documentos con 1 documento posible se asociaron exitosamente.",
			"docIgnoradoSuccessMsg": "El documento {{ndoc}} se ha agregado a la lista de ignorados.",
			"fornecedorIgnoradoSuccessMsg": "El proveedor {{nif}} - {{name}} se ha agregado a la lista de ignorados.",
			"docRemAssociacaoSuccessMsg": "Se ha eliminado la asociación del documento {{ndoc}}",
			"docRemIgnoringSuccessMsg": "El documento {{ndoc}} ha sido eliminado de la lista de ignorados.",
			"docRemIgnoringFornecedorSuccessMsg": "El proveedor {{nif}} ha sido eliminado de la lista de ignorados.",
			"docDigital1": "Con doctor.",
			"docDigital2": "Con doctor."
		},
		"fields": {
			"numPossiveisDocs": "Número de documentos posibles",
			"numPossiveisDocsAbbr": "Posible",
			"numSemelhantesDocs": "Número de documentos similares",
			"numSemelhantesDocsAbbr": "Similar",
			"documentoLancado": "Número de documento",
			"nifEmitente": "NIF",
			"nomeEmitente": "Nombre",
			"numerodocumento": "Doc no.",
			"dataEmissaoDocumento": "Fecha de asunto",
			"tipoDocumentoDesc": "Tipo",
			"estadoBeneficioDesc": "Situación",
			"actividadeProfDesc": "Ámbito de actividad",
			"valorTotalBaseTributavel": "Base imponible",
			"valorTotalIva": "valor del IVA",
			"valorTotal": "Valor total",
			"baseTributavel": "Base imponible",
			"taxa": "Base de la tarifa",
			"impostoSelo": "Impuesto de timbre",
			"total": "Valor total",
			"nDocInterno": "Número de documento",
			"nDocExterno": "Doc no.",
			"valor": "Valor",
			"dataDocExterno": "Fecha del doctor.",
			"docDigitalAbbr": "documento digital"
		},
		"btn": {
			"dropdown": {
				"extratosConta": "Estados de cuenta",
				"ignorarSempreTodosDocumentos": "Ignore siempre todos los documentos de este proveedor.",
				"ignorarDocumento": "ignorar este documento",
				"configSupplier": "Configurar proveedor"
			}
		},
		"tabJaLancadosCaptionContainer": {
			"table": {
				"badges": {
					"ligacaoDireta": "llamada directa",
					"remover": "Eliminar",
					"naoIgnorar": "no ignores"
				}
			},
			"legends": {
				"documentoIgnorado": "Documento ignorado",
				"associadoDocExtDiferente": "Asociado con el doc no.",
				"documentoFornecedorIgnorado": "Documento del proveedor ignorado"
			}
		},
		"tabDocsEFaturaCaptionContainer": {
			"mostradocpossiveis": "Mostrar solo no es posible ningún documento",
			"tooltip": {
				"associarAutTodosPossiveis": "Asociar automáticamente todos los documentos con 1 documento posible."
			}
		},
		"formFields": {
			"tipo": "Tipo",
			"fornecedor": "Proveedor",
			"placeholder": {
				"contribuinte": "número de contribuyente"
			}
		},
		"modal": {
			"config": {
				"title": "Configuración de conciliación de facturas electrónicas",
				"diariosList": "Lista de revistas para eliminar",
				"fields": {
					"nCaracteresAValidar": "Número de caracteres Doc Externo a validar:",
					"filtraPorDataDocExterno": "Los datos del documento externo contribuyen a posibles documentos.",
					"diarioAExcluir": "Diario para eliminar",
					"addDiarioAExcluir": "Agregar diario para eliminar",
					"diario": "A diario",
					"nomeDiario": "Nombre"
				},
				"messages": {
					"diarioAlreadyInList": "La revista seleccionada ya existe en la lista."
				}
			}
		}
	},
	"concilmovab": {
		"title": "Justificación de los movimientos abiertos",
		"conta": "Cuenta",
		"deconta": "cuenta",
		"table": {
			"periodo": "Período",
			"nDiario": "Número diario",
			"nDoc": "Documento",
			"nDocExterno": "Ext. Doc.",
			"descricao": "Descripción",
			"dataDocExt": "Fecha del doctor.",
			"isDocumentoOrigemRegimeDeIvaDeCaixa": "Régimen de IVA en efectivo",
			"valor": "Valor",
			"porPagar": "No pagado",
			"dc": "ANUNCIO",
			"aImputar": "para justificar",
			"dataVenc": "Madurez",
			"moeda": "Acuñar",
			"valorActualAImputar": "cuenta de valor actual",
			"montanteAReter": "Monto a retener"
		},
		"totals": {
			"valajustificar": "Valores a justificar",
			"totconta": "Totales de cuenta"
		},
		"messages": {
			"success": "¡Masacre grabada!",
			"saldomovinvalido": "¡El saldo de movimientos debe ser 0 para realizar esta operación!",
			"jobTimeoutModalTitle": "Justificar automáticamente valores en serie",
			"jobTimeoutModalMessage": "La tarea ha expirado.",
			"aprepararjustvalautoserie": "Preparando el proceso de justificación de valor automáticamente en serie...",
			"aefetuarjustvalautoserie": "Procesamiento de justificaciones de valor automáticamente en serie...",
			"successjustvalautoserie": "¡Justificaciones de valores en serie procesadas automáticamente con éxito!",
			"noValjustauto": "No hay valores que justificar.",
			"valjustautosuccess": "¡Valores justificados automáticamente!",
			"savjustautosuccess": "¡Los movimientos justificados automáticamente se guardaron con éxito!",
			"desejaefetuarjustmovi": "¿Quieres justificar movimientos abiertos para los valores ingresados?",
			"valimputnaopodsermaior": "¡El importe a imputar no puede ser superior al importe a pagar!"
		},
		"modal": {
			"justemseriecontas": "Justificar automáticamente valores en serie",
			"limiteperiodomaximo": "Limita el plazo máximo."
		},
		"btn": {
			"justDropdownTitle": "Justificar valores",
			"justvalauto": "Justificar valores automáticamente",
			"justvalautoserie": "Justificar automáticamente valores en serie",
			"justificarvalor": "Justificar valor",
			"justserie": "Justificar en serie"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmación",
		"leavePromptMessage": "Al hacer clic en SÍ, saldrá y descartará todos los datos de procesamiento.",
		"searchlimiteperiodomaximo": "Limita el período máximo de búsqueda."
	},
	"condicoescomerciais": {
		"title_detail": "Condición Comercial {{id}}",
		"title_new": "Nueva Condición Comercial",
		"title_plural": "Condiciones Comerciales",
		"pesquisa": "buscar",
		"saved": "Condición Comercial {{id}}, guardada exitosamente.",
		"error": "Condición Comercial {{id}}, no se puede guardar.",
		"deleted": "Condición Comercial {{id}}, eliminada exitosamente.",
		"fields": {
			"codCondComerciais": "Código",
			"codCondComerciaisPlaceholder": "código de condición comercial",
			"descricao": "Descripción",
			"descricaoPlaceholder": "descripción",
			"nomeCondPagamento": "condición de pago",
			"nomeCondPagamentoPlaceholder": "condición de pago",
			"numDiasDataVenc": "No. de días",
			"numDiasDataVencPlaceholder": "Número de días",
			"tipoResumoMensal": "Tipo de resumen mensual",
			"tipoResumoMensalPlaceholder": "tipo de resumen mensual",
			"origemCondPagamento": "Condición de pago, proviene del cliente.",
			"origemDataVencimento": "Número de días y tipo de resumen mensual, se origina en la condición de pago"
		}
	},
	"condicoespagamento": {
		"title_detail": "Condiciones de pago {{id}}",
		"title_new": "Nueva condición de pago",
		"title_plural": "Condiciones de pago",
		"pesquisa": "buscar",
		"saved": "Condición de pago {{id}}, guardada exitosamente.",
		"error": "Condición de pago {{id}}, no se puede guardar.",
		"deleted": "Condición de pago {{id}}, eliminada exitosamente.",
		"fields": {
			"nCondPag": "Código.",
			"nCondPagPlaceholder": "Código de condición de pago",
			"descricao": "Descripción",
			"descricaoPlaceholder": "Descripción",
			"nDiasPP": "Número de días PP",
			"nDiasPPPlaceholder": "Número de días PP",
			"percDesconto": "Perc.",
			"percDescontoPlaceholder": "Porcentaje de descuento",
			"numDiasDataVenc": "Número de días (Vencimiento)",
			"numDiasDataVencholder": "Número de días de vencimiento",
			"tipoResumoMensal": "Tipo de resumen mensual",
			"tipoResumoMensalPlaceholder": "Tipo de resumen mensual"
		}
	},
	"configEfaturaDocPorLanc": {
		"success": "La configuración se ha guardado correctamente.",
		"fields": {
			"fatura": "Factura",
			"faturaRecibo": "Factura/Recibo",
			"notaDebito": "nota de débito",
			"notaCredito": "nota de crédito",
			"reciboVerde": "Recibo verde electrónico",
			"faturaSimplificada": "factura simplificada",
			"docConferencia": "Documento de conferencia",
			"faturaEstrangeiro": "factura emitida en el extranjero"
		},
		"text": {
			"mesmoPredefinido": {
				"title": "Posible error de configuración",
				"message": "El valor predeterminado para las <strong>notas de crédito</strong> no debe ser el mismo que para otros tipos de documentos."
			},
			"maintenancePreDefinidos": "Mantenimiento de contabilidad predefinida."
		},
		"global": {
			"title": "Configuración de documentos para publicar facturas electrónicas",
			"fields": {
				"autoFixATDocWithDiffOneCent": "Corrige automáticamente documentos con una diferencia de 1 centavo",
				"criterioOrdenacao": "Orden de cuadrícula",
				"mostraDocsFornIgnorados": "Muestra documentos de proveedores ignorados en la conciliación",
				"posicionaAutoNextDoc": "Se posiciona automáticamente en el siguiente documento después de publicar el documento."
			},
			"criterioOrdenacao": {
				"dataNome": "Fecha de la factura |",
				"nomeData": "Nombre del proveedor |"
			}
		},
		"supplier": {
			"title": "Configuraciones específicas del proveedor",
			"fields": {
				"contaCorrente": "Cuenta corriente a utilizar"
			}
		}
	},
	"configsERPCheck": {
		"state": {
			"load": "Cargando validaciones",
			"genDefaultData": "Validar y generar datos predeterminados",
			"validate": "Validaciones",
			"validating": "Para ser validado",
			"repair": "para ser reparado",
			"finished": "Validación finalizada"
		},
		"text": {
			"correction": "Corrección"
		},
		"actions": {
			"validate": "Validar",
			"validateAll": "Validar todo",
			"repair": "Reparar",
			"repairAll": "Reparar todo"
		},
		"validateAllModal": {
			"title": "Generar datos predeterminados de la empresa",
			"message": "Quiero generar datos de la empresa por defecto (tipos de documentos, familias, IVA's, tipos de artículos, etc.)"
		}
	},
	"configsErp": {
		"title_detail": "Valor {{id}}",
		"title_plural": "Configuración de ERP",
		"pesquisa": "buscar",
		"fields": {
			"name": "Nombre",
			"description": "Descripción",
			"value": "Valor",
			"readOnly": "Solo lectura"
		},
		"items": {
			"updated": "Valor actualizado",
			"clientesFornecedoresTitle": "Clientes/Proveedores",
			"clientesFornecedores": {
				"contaIdentificacaoAlternativaTitle": "Cuenta de identificación de cliente alternativa",
				"contaIdentificacaoAlternativaFornecedoresTitle": "Cuenta de identificación de proveedor alternativa"
			},
			"contabilidadeTitle": "Contabilidad",
			"contabilidade": {
				"recibosTitle": "Ingresos",
				"recibos": {
					"aplicaPercDescontoSobreValorIVATitle": "Aplicar % de descuento sobre el valor con IVA",
					"reportImpressaoTitle": "Imprimir informe",
					"diarioLancamentosTitle": "Número diario",
					"descritivoLancamentosTitle": "Descriptivo no.",
					"contaPocDescontProntoPagamentoTitle": "Número de cuenta de descuento POC P.P.",
					"meioPagamentoOmissaoTitle": "Método de pago predeterminado"
				},
				"pagamentosTitle": "Pagos",
				"pagamentos": {
					"reportImpressaoTitle": "Imprimir informe",
					"diarioLancamentosTitle": "Número diario",
					"descritivoLancamentosTitle": "Descriptivo no.",
					"contaPocDescontProntoPagamentoTitle": "Número de cuenta de descuento POC P.P.",
					"meioPagamentoOmissaoTitle": "Método de pago predeterminado"
				},
				"decimaisTitle": "decimales",
				"decimais": {
					"valorTitle": "Números decimales para valor",
					"descontoTitle": "Números decimales para descuento.",
					"percDescontoTitle": "Números decimales para porcentaje de descuento"
				},
				"balancetesTitle": "Balances",
				"balancetes": {
					"reportImpressaoTitle": "Imprimir informe"
				},
				"extratosTitle": "Extractos",
				"extratos": {
					"reportImpressaoTitle": "Imprimir informe"
				},
				"movimentosemabertoTitle": "Movimientos abiertos",
				"movimentosemaberto": {
					"reportImpressaoTitle": "Imprimir informe"
				},
				"apuraivaTitle": "Liquidación de IVA",
				"documentosTitle": "Documentos",
				"reparticaoccTitle": "Desgloses por centro de coste",
				"reparticaocc": {
					"usadataTitle": "Utilizar desgloses por fecha"
				},
				"listagemdiariosTitle": "Lista de diarios"
			},
			"documentosComerciaisTitle": "Documentos comerciales",
			"documentosComerciais": {
				"tiposTitle": "tipos de documentos",
				"tipos": {
					"outros": {
						"documentoPorDefeitoTitle": "Documento predeterminado",
						"visivelTitle": "Visible",
						"groupProperty": "otros"
					},
					"comprasEfectivasTitle": "Compras reales",
					"comprasEfectivas": {
						"documentoPorDefeitoTitle": "Documento predeterminado",
						"groupProperty": "compras efectivas"
					},
					"consultasaFornecedoresTitle": "Consultas de proveedores",
					"consultasaFornecedores": {
						"documentoPorDefeitoTitle": "Documento predeterminado",
						"groupProperty": "consultaproveedores",
						"otherTitle": "Presupuestos para proveedores"
					},
					"encomendasClientesTitle": "Pedidos de clientes",
					"encomendasClientes": {
						"documentoPorDefeitoTitle": "Documento predeterminado",
						"groupProperty": "pedidosClientes"
					},
					"encomendasFornecedoresTitle": "Pedidos de proveedores",
					"encomendasFornecedores": {
						"documentoPorDefeitoTitle": "Documento predeterminado",
						"groupProperty": "pedidosProveedores"
					},
					"folhasdeObraTitle": "Hojas de trabajo",
					"folhasdeObra": {
						"documentoPorDefeitoTitle": "Documento predeterminado"
					},
					"guiasTransporteRemessaTitle": "Guías de transporte marítimo.",
					"guiasTransporteRemessa": {
						"documentoPorDefeitoTitle": "Documento predeterminado",
						"groupProperty": "guíasTransporteEnvío"
					},
					"propostasaClientesTitle": "Propuestas a los clientes.",
					"propostasaClientes": {
						"documentoPorDefeitoTitle": "Documento predeterminado",
						"groupProperty": "propuestaClientes",
						"otherTitle": "Presupuestos para clientes"
					},
					"reservasClientesTitle": "Reservas de clientes",
					"reservasClientes": {
						"documentoPorDefeitoTitle": "Documento predeterminado"
					},
					"trfArmazensEntradasTitle": "Trf.",
					"trfArmazensEntradas": {
						"documentoPorDefeitoTitle": "Documento predeterminado"
					},
					"trfArmazensSaidasTitle": "Trf.",
					"trfArmazensSaidas": {
						"documentoPorDefeitoTitle": "Documento predeterminado"
					},
					"vendasEfectivasTitle": "Ventas efectivas",
					"vendasEfectivas": {
						"documentoPorDefeitoTitle": "Documento predeterminado",
						"groupProperty": "ventas efectivas"
					},
					"consignacoesClientesTitle": "Envíos de clientes",
					"consignacoesClientes": {
						"documentoPorDefeitoTitle": "Documento predeterminado"
					},
					"consignacoesFornecedoresTitle": "Envíos de proveedores",
					"consignacoesFornecedores": {
						"documentoPorDefeitoTitle": "Documento predeterminado"
					},
					"recibosClientesTitle": "Recibos de clientes",
					"recibosClientes": {
						"documentoPorDefeitoTitle": "Documento predeterminado"
					},
					"pagamentosFornecedoresTitle": "Pagos a proveedores",
					"pagamentosFornecedores": {
						"documentoPorDefeitoTitle": "Documento predeterminado"
					},
					"noneTitle": "Sin información",
					"none": {
						"documentoPorDefeitoTitle": "Documento predeterminado"
					},
					"planoProducaoTitle": "plan de producción",
					"planoProducao": {
						"documentoPorDefeitoTitle": "Documento predeterminado"
					},
					"preEncomendasClientesTitle": "Pedidos anticipados de clientes",
					"preEncomendasClientes": {
						"documentoPorDefeitoTitle": "Documento predeterminado"
					},
					"custosAdicionaisTitle": "Costos adicionales",
					"custosAdicionais": {
						"documentoPorDefeitoTitle": "Documento predeterminado"
					},
					"notaCreditoTitle": "nota de crédito",
					"notaCredito": {
						"documentoPorDefeitoTitle": "Documento predeterminado"
					},
					"guiasTransporteFornecedoresTitle": "Proveedores de guías de transporte.",
					"guiasTransporteFornecedores": {
						"documentoPorDefeitoTitle": "Documento predeterminado",
						"groupProperty": "guíasTransporteProveedores"
					},
					"entradasDiversasTitle": "Insumos varios",
					"entradasDiversas": {
						"documentoPorDefeitoTitle": "Documento predeterminado",
						"groupProperty": "entradasVarios"
					},
					"saidasDiversasTitle": "Varias salidas",
					"saidasDiversas": {
						"documentoPorDefeitoTitle": "Documento predeterminado",
						"groupProperty": "salidasVarios"
					}
				}
			},
			"validacaoDasConfiguracoesTitle": "Validación de configuraciones",
			"validacaoDasConfiguracoes": {
				"state": {
					"Load": "Cargando validaciones",
					"GenDefaultData": "Validar y generar datos predeterminados",
					"Validate": "Validaciones",
					"Validating": "Para ser validado",
					"Repair": "para ser reparado",
					"Finished": "Validación finalizada"
				},
				"errors": {
					"GenDefaultDataError": "Error al generar datos predeterminados"
				}
			},
			"empresaTitle": "Empresa",
			"empresa": {
				"nomeTitle": "Nombre de empresa",
				"islockedTitle": "Empresa bloqueada",
				"motivoislockedTitle": "Razón por la que la empresa fue bloqueada",
				"verificacaoEfetuadaTitle": "Configuración verificada",
				"verificacaoNaoEfetuadaError": "La configuración de esta empresa aún no se ha verificado.",
				"periodoIvaTrimestralTitle": "Período de IVA trimestral"
			},
			"licencaTitle": "Licencia",
			"licenca": {
				"licIdTitle": "Número de licencia de CentralGest",
				"nomeTitle": "Nombre de licencia",
				"descricaoTitle": "Descripción de la licencia",
				"nempresasTitle": "Número máximo de empresas",
				"nutilizadoresTitle": "Número máximo de usuarios",
				"portalcolaboradorTitle": "Portal colaborativo"
			},
			"erpTitle": "CentralGest",
			"erp": {
				"versaoTitle": "Versión CentralGest Nube",
				"dataversaoTitle": "Fecha de versión de CentralGest Cloud",
				"emManutencaoTitle": "ERP en mantenimiento"
			},
			"rhTitle": "HORA",
			"rh": {
				"refeicoesTitle": "Comidas",
				"refeicoes": {
					"numMaxTitle": "Número máximo"
				}
			},
			"pcaTitle": "Mi contabilidad",
			"portalColaborador": {
				"title": "Portal colaborativo",
				"notificacao": "Notificación",
				"desativado": "Desactivado",
				"diaria": "A diario",
				"diaDaSemana": "Día de la semana",
				"hora": "Tiempo",
				"configGuardadaComSucesso": "La configuración de notificaciones del portal del empleado se ha guardado correctamente.",
				"conflitos": {
					"title": "Conflictos",
					"colaboradores": "Colaboradores",
					"permite": "Permite",
					"naoPermite": "no permite",
					"permiteMarcarFeriasComConflitos": "Le permite reservar vacaciones con conflictos.",
					"fields": {
						"nEmpresa": "empresa no.",
						"nomeEmpresa": "Nombre de empresa",
						"colaborador1": "Colaborador 1",
						"colaborador2": "Colaborador 2"
					},
					"aplicarGeralEmTodas": "Aplicar generalmente a todos",
					"tooltips": {
						"aplicarGeralEmTodas": "Aplicar la configuración general en todas las empresas.",
						"apagarTodosConflitosDoEmpregado": "Eliminar todos los conflictos de empleados"
					},
					"alteracoesNaoGuardadas": "Hay cambios aún no guardados.",
					"descartarAlteracoes": "Descartar cambios",
					"criarConflito": "Crear conflicto",
					"temQueSelecionarColaborador1": "Tienes que seleccionar colaborador 1",
					"temQueSelecionarPeloMenosUmColaborador": "Debes seleccionar al menos un colaborador",
					"temACertezaApagarTodosConflitosDoEmpregado": "¿Está seguro de que desea eliminar todos los conflictos del empleado \"{{employee}}\"?",
					"temACertezaApagarEsteConflito": "¿Está seguro de que desea eliminar este conflicto?",
					"colaborador1": "Colaborador 1"
				},
				"prhtipocom": {
					"temACertezaApagarEsteTipoCom": "¿Estás seguro de que deseas eliminar este tipo de comunicación?",
					"criarTipoCom": "Crear tipo de comunicación"
				}
			}
		},
		"check": {
			"disabledValidate": "El proceso de reparación está en curso, espere.",
			"disabledRepair": "El proceso de validación está en curso, espere."
		},
		"groups": {
			"comercial": {
				"requiredDefaultDoc": "Es obligatorio tener seleccionado un documento predeterminado"
			},
			"pca": {
				"optionsPeriodo": {
					"todos": "Todos los periodos",
					"atePeriodoAtivoEmpresa": "Hasta el período activo de la empresa.",
					"ateUltimoPeriodoEncerrado": "Hasta el último periodo cerrado"
				}
			}
		}
	},
	"configsSite": {
		"title_detail": "Valor {{id}}",
		"title_plural": "Configuración de correo electrónico",
		"pesquisa": "buscar",
		"fields": {
			"configId": "Nombre",
			"value": "Valor"
		},
		"saved": "Configuración grabada.",
		"error": "La configuración del correo electrónico no se puede guardar.",
		"testMail": "correo de prueba",
		"configs": {
			"database.version": "Versión de la base de datos",
			"mail.smtp.adminemail": "Correo electrónico del administrador",
			"mail.smtp.siteemail": "Sitio web de correo electrónico",
			"mail.smtp.smtppassword": "contraseña SMTP",
			"mail.smtp.smtpport": "Puerto SMTP",
			"mail.smtp.smtpserver": "servidor SMTP",
			"mail.smtp.smtpuser": "usuario SMTP",
			"mail.smtp.smtpusessl": "SMTP usa ssl",
			"termsRGPDVersion": "Versión de los términos del RGPD"
		}
	},
	"contabAdvancedOperations": {
		"fields": {
			"dePeriodo": "período de",
			"deNDiario": "diario",
			"deNDoc": "Número de documento de",
			"periodoDestino": "Período objetivo",
			"diarioDestino": "Diario de destino",
			"listaDocsNaoPermitidos": "Documentos que no se pueden cambiar.",
			"listaDocsPermitidos": "Documentos que se pueden cambiar."
		},
		"list": {
			"fields": {
				"nPeriodo": "Período",
				"nDiario": "A diario",
				"nDoc": "Número de documento",
				"tipoErroNaoPermitido": "Razón",
				"seleccionado": "Selección",
				"processado": "Procesado"
			}
		},
		"messages": {
			"warning": "Al realizar esta operación, todos los documentos seleccionados serán cancelados y se crearán copias de los mismos en el período {{period}}.",
			"warningAnular": "Al realizar esta operación se cancelarán todos los documentos seleccionados.",
			"temQueSeleccionar": "Debes seleccionar al menos un documento",
			"success": "Operación realizada con éxito"
		},
		"fieldsAnulado": {
			"listaDocsNaoPermitidos": "Documentos que no se pueden cancelar.",
			"listaDocsPermitidos": "Documentos que se pueden cambiar."
		}
	},
	"continentes": {
		"pesquisa": "Continentes",
		"fields": {
			"codContinente": "código continental",
			"nomeContinente": "Nombre del continente"
		},
		"data": {
			"portugal": "Continente",
			"acores": "Región de las Azores",
			"madeira": "Región de Madeira",
			"estrangeiro": "Extranjero",
			"outros": "Otros"
		}
	},
	"contratos": {
		"title_detail": "Contrato {{id}}",
		"title_new": "Nuevo contrato",
		"title_plural": "Contratos",
		"pesquisa": "buscar",
		"saved": "Contrato {{id}}, guardado exitosamente",
		"error": "Contrato {{id}}, no se puede guardar.",
		"deleted": "Contrato {{id}}, eliminado exitosamente",
		"fields": {
			"cab": {
				"refCtrCab": "Código de contrato",
				"nClasseCtr": "Clase",
				"nClasseCtrPlaceholder": "Código de clase de contrato",
				"nTipoContrato": "Tipo",
				"nTipoContratoPlaceholder": "Tipo de contrato",
				"nContrato": "Número de contrato",
				"nContratoPlaceholder": "Número de contrato",
				"verContrato": "Versión",
				"verContratoPlaceHolder": "Versión del contrato",
				"nContratoAsString": "Contrato",
				"nContratoSemVersaoAsString": "Contrato",
				"descricao": "Descripción",
				"descricaoPlaceHolder": "Descripción del contrato",
				"nClifo": "Cliente / Proveedor",
				"nClifoPlaceHolder": "Cliente / Proveedor",
				"clifoNome": "Nombre del cliente",
				"idIdeChav": "grupo de cuentas",
				"idIdeChavPlaceHolder": "grupo de cuentas",
				"dataCtr": "Fecha del contrato",
				"dataCtrPlaceHolder": "Fecha del contrato",
				"dataInicio": "Fecha de inicio",
				"dataInicioPlaceHolder": "Fecha de inicio",
				"dataFim": "Fecha de finalización",
				"dataFimPlaceHolder": "Fecha de finalización",
				"estado": "Estado",
				"estadoPlaceHolder": "código de estado",
				"ccusto": "centro de costos",
				"ccustoPlaceHolder": "Código del centro de costos",
				"nRefProcesso": "Proceso",
				"nRefProcessoPlaceHolder": "código de proceso",
				"codMoeda": "Acuñar",
				"codMoedaPlaceHolder": "Código de moneda",
				"refExterna": "Referencia externa",
				"terminado": "Finalizado",
				"totalLiquido": "Valor",
				"valorGlobal": "Valor total",
				"codCondComerciais": "Condición comercial",
				"codCondComerciaisPlaceHolder": "Código de condición comercial"
			},
			"linhas": {
				"nArtigo": "Código del artículo",
				"nomeArtigo": "Descripción",
				"qtd1": "Cantidad",
				"prVenda": "unidad pr.",
				"d1": "D1",
				"d2": "D2",
				"d3": "D3",
				"desconto": "Descuento",
				"d4": "D4",
				"d5": "D5",
				"d6": "D6",
				"codIva": "código de IVA",
				"nArmazem": "Almacenamiento",
				"cunimo": "Elementos.",
				"qtdPUnid": "Cant./Unidad.",
				"nVendedor": "Vendedor",
				"ccusto": "centro de costos",
				"nZona": "Zona",
				"nDepto": "Departamento",
				"nSubde": "Subdepartamento",
				"qtd1Fact": "Cantidad facturada",
				"nRefProcesso": "Proceso",
				"estado": "Estado",
				"obs": "Observaciones",
				"valorLiquido": "Líquido",
				"taxaIva": "tipo de IVA"
			}
		},
		"detail": {
			"cab": {
				"dataCtr": "Fecha del contrato",
				"nClifo": "Cliente / Proveedor",
				"dataFim": "Fecha de finalización",
				"dataInicio": "Fecha de inicio",
				"nContribuinte": "número de contribuyente",
				"codPostal": "Código Postal"
			}
		},
		"tabs": {
			"linhas": "Pauta"
		},
		"config": {
			"verGrupoConta": "Mostrar cuenta de grupo",
			"verMoeda": "Mostrar moneda",
			"verRefExterna": "Mostrar referencia externa",
			"verCondComerciais": "Mostrar condiciones comerciales",
			"verVendedor": "Mostrar vendedor",
			"verNCCusto": "Mostrar centro de costos",
			"verD1": "Mostrar D1",
			"verArmazem": "Mostrar almacén",
			"verUnidadeMovimento": "Mostrar unidad de movimiento"
		},
		"totais": {
			"resumo": "Resumen"
		},
		"print": {
			"config": {
				"showClasses": "Mostrar filtro de clase"
			},
			"filters": {
				"classes": "Clases",
				"declifo": "Cliente de",
				"dencontrato": "número de contrato",
				"dtdedatactr": "Fecha del contrato",
				"dtdefim": "fecha de finalización de",
				"dtdeini": "fecha de inicio de",
				"tipos": "Tipos de contrato"
			}
		},
		"errors": {
			"artigoBloqueadoDesativado": "¡El artículo {{nArtigo}} está bloqueado o deshabilitado!",
			"notSelected": "No hay ningún contrato seleccionado",
			"terminado": "El contrato {{contract}} ya ha finalizado",
			"notTerminado": "El contrato {{contract}} no se rescinde",
			"reabrir": "Reapertura cancelada",
			"terminar": "Finalizar acción cancelada",
			"apagar": "Borrar acción cancelada"
		},
		"btn": {
			"editar": "Editar",
			"reabrir": "Reabrir",
			"terminar": "Finalizar",
			"apagar": "Para apagar"
		},
		"info": {
			"terminado": "Finalizado",
			"reabrir": "Reabrir contrato",
			"terminar": "Terminar contrato",
			"apagar": "Eliminar contrato"
		},
		"list": {
			"config": {
				"showNaoAtivosOnly": "Ver sólo terminados y caducados"
			}
		}
	},
	"contratostiposervicos": {
		"title_detail": "Tipo de pacto {{id}}",
		"title_new": "Nuevo tipo de acuerdo",
		"title_plural": "Tipos de pactos",
		"pesquisa": "buscar",
		"saved": "Tipo de convenio {{id}}, guardado exitosamente",
		"error": "Tipo de pacto {{id}}, no se puede guardar.",
		"deleted": "Tipo de convenio {{id}}, eliminado exitosamente",
		"fields": {
			"id": "Código de tipo de contrato de clase",
			"nClasseCtr": "Código",
			"nClasseCtrPlaceholder": "Código de clase de recibo",
			"classeTipo": "Tipo de clase",
			"classeTipoPlaceholder": "Clase de tipo de contrato",
			"nTipoContrato": "Número de tipo de acuerdo",
			"nTipoContratoPlaceholder": "Tipo de contrato, si no se cumplimenta se generará automáticamente.",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del tipo de contrato",
			"periocidade": "Periodicidad",
			"periocidadePlaceholder": "Frecuencia del contrato",
			"nDocfa": "Tipo de documento",
			"nDocfaPlaceholder": "Tipo de documento",
			"nNumer": "Numeración",
			"nNumerPlaceholder": "Numeración de tipos de documentos",
			"nomeClasse": "Clase",
			"nomeClassePlaceholder": "Clase de pago",
			"processaDiferimento": "Aplazamiento del proceso",
			"faturacaomes": "facturación mensual",
			"docComercialAssinar": "para firmar",
			"docComercialEncerrar": "Firmar/Cerrar",
			"txtContrato": "Observaciones",
			"variables": "variables",
			"proceAuto": "Procesamiento automático",
			"procAutoTipo": "Programación",
			"procAutoDiaFixo": "dia fijo",
			"procAutoEnviaMailTitle": "El procesamiento se ejecutará durante el día especificado.",
			"procAutoEnviaMail": "Enviar automáticamente un correo electrónico al cliente",
			"proAutoEmailResponsavel": "Correo electrónico responsable"
		},
		"showFaturacaoMes": "Muestra la facturación del mes.",
		"actions": {
			"nnumer": "Generar automáticamente un nuevo número",
			"decrementafaturacaomes": "Disminuir el mes de facturación",
			"incrementafaturacaomes": "Aumentar el mes de facturación"
		},
		"infoFaturacaoMes": {
			"info": "La facturación y la prestación del servicio se refieren al mismo mes.",
			"infoPositiveValue": "Ejemplo: la facturación de agosto se refiere a un servicio ya prestado en el mes de {{mes}}.",
			"infoNegativeValue": "Ejemplo: la facturación de agosto se refiere a un servicio que se prestará en el próximo mes de {{mes}}."
		},
		"messages": {
			"emailNotification": "Envío de notificación con informe de procesamiento",
			"txtContrato": "Notas visibles en la factura."
		},
		"datasource": {
			"none": "Inactivo",
			"primeiroDiaUtil": "1er día hábil",
			"ultimoDiaUtil": "último día hábil",
			"primeiroDiaDoPeriodo": "1er día del período",
			"ultimoDiaPeriodo": "Último día del periodo",
			"diaFixo": "dia fijo"
		}
	},
	"contratostipos": {
		"title_detail": "Tipo de contrato {{id}}",
		"title_new": "Nuevo tipo de contrato",
		"title_plural": "Tipos de contratos",
		"pesquisa": "buscar",
		"saved": "Tipo de contrato {{id}}, guardado exitosamente",
		"error": "Tipo de contrato {{id}}, no se puede guardar.",
		"deleted": "Tipo de contrato {{id}}, eliminado correctamente",
		"fields": {
			"id": "Código de tipo de contrato de clase",
			"nClasseCtr": "Código",
			"nClasseCtrPlaceholder": "Código de clase de contrato",
			"classeTipo": "Tipo de clase",
			"classeTipoPlaceholder": "Clase de tipo de contrato",
			"nTipoContrato": "Tipo de contrato",
			"nTipoContratoPlaceholder": "Tipo de contrato",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre tipo de contrato",
			"periocidade": "Periodicidad",
			"periocidadePlaceholder": "Periodicidad del tipo de contrato",
			"nDocfa": "Tipo de documento",
			"nDocfaPlaceholder": "Tipo de documento",
			"nNumer": "Numeración",
			"nNumerPlaceholder": "Numeración de tipos de documentos",
			"nomeClasse": "Clase",
			"nomeClassePlaceholder": "Clase de tipo de contrato"
		}
	},
	"controloIVA": {
		"title_detail": "Control del IVA {{id}}",
		"title_new": "Nuevo código.",
		"title_plural": "control del IVA",
		"pesquisa": "buscar",
		"saved": "Código de control de IVA {{id}}, guardado correctamente.",
		"error": "Código de control de IVA {{id}}, no se puede guardar.",
		"deleted": "Código de control de IVA {{id}}, eliminado correctamente.",
		"fields": {
			"codControloIVA": "código de control del IVA",
			"descAbreviada": "Descripción",
			"descCompleta": "Descripción completa",
			"tipoBem": "tipo de bien",
			"tipoTaxa": "tipo de tarifa",
			"tipoOperacao": "Tipo de operación",
			"tipoLiquidacao": "Tipo de liquidación",
			"tipoTransmissaoAct": "Tipo de transmisión activa",
			"tipoTransmissaoPas": "Tipo transm.",
			"mercado": "Mercado",
			"areaRegional": "Área regional",
			"inversaoSujPassivo": "inversión sucia",
			"tipoDeducao": "Tipo de deducción",
			"decPERBASETRIBUT": "Código de diciembre.",
			"decANUBASETRIBUT": "Código de diciembre.",
			"codIVA": "código de IVA",
			"nContaIVADEDLIQREG": "Cuenta de IVA neto",
			"nContaVTNAODEDUT": "Cuenta VT no deducible",
			"nContaIVANAODEDUT": "Cuenta IVA no deducible",
			"nContaVTNDEDPROR": "Cuenta VT no deducida.",
			"nContaIVANDEDPROR": "Cuenta IVA no deducida.",
			"nContaVTLIQUIDRCH": "Cuenta 99val.",
			"nContaIVALIQUIDRCH": "Cuenta 24 IVA neto.",
			"decPERIVADEDLIQREG": "",
			"decPERVTNAODEDUT": "",
			"decPERIVANAODEDUT": "",
			"decPERVTNDEDPROR": "",
			"decPERIVANDEDPROR": "",
			"decPERVTLIQUIDRCH": "Código de diciembre.",
			"decPERIVALIQUIDRCH": "",
			"decANUIVADEDLIQREG": "",
			"decANUVTNAODEDUT": "",
			"decANUIVANAODEDUT": "",
			"decANUVTNDEDPROR": "",
			"decANUIVANDEDPROR": "",
			"decANUVTLIQUIDRCH": "",
			"decANUIVALIQUIDRCH": "",
			"tipoValidacaoNIF": "Tipo de validación NIF",
			"usaVTNAODEDUTOrigem": "Usar cuenta de origen",
			"usaIVANAODEDUTOrigem": "Usar cuenta de origen",
			"usaVTNDEDPROROrigem": "Usar cuenta de origen",
			"usaIVANDEDPROROrigem": "Usar cuenta de origen",
			"isRegimeIvaCaixa": "Régimen de IVA en efectivo",
			"regularizacoesC40": "Campo 40 tipo de regularización",
			"regularizacoesC41": "Campo 41 tipo de regularización",
			"regularizacoesC40RCH": "Campo 40 tipo de regularización",
			"regularizacoesC41RCH": "Campo 41 tipo de regularización",
			"codIVANome": "código de IVA",
			"decPERBASETRIBUTNome": "Código de diciembre.",
			"decANUBASETRIBUTNome": "Código de diciembre.",
			"nCONTAIVADEDLIQREGNome": "Cuenta de IVA neto",
			"nCONTAVTNAODEDUTNome": "Cuenta VT no deducible",
			"nCONTAIVANAODEDUTNome": "Cuenta IVA no deducible",
			"nCONTAVTNDEDPRORNome": "Cuenta VT no prorrateada",
			"nCONTAIVANDEDPRORNome": "Cuenta IVA no deducida.",
			"nCONTAVTLIQUIDRCHNome": "Cuenta 99val.",
			"nCONTAIVALIQUIDRCHNome": "Cuenta 24 IVA neto.",
			"tipoRegularizacaoCampo40": "Campo 40 tipo de regularización",
			"tipoRegularizacaoCampo41": "Campo 41 tipo de regularización",
			"nContaIvaCativo": "Cuenta Cautiva de IVA",
			"tipoRegularizacao": "Tipo de regularización",
			"declaracaoPerio": "El campo de declaración periódica: {{info}}"
		},
		"steps": {
			"operacaoIVA": "Tipo de transacción de IVA",
			"camposIVA": "Campos de IVA y cuentas relacionadas con la base imponible"
		},
		"helper": {
			"taxaAtual": "Tasa actual",
			"percDedGasoleo": "Perc.",
			"percDedProRata": "Perc.",
			"decPeriodicaIva": "Decreto periódico del IVA",
			"decAnualIva": "Decreto anual del IVA"
		},
		"btn": {
			"controloIVAEntreEmpresas": "Copiar control de IVA para empresas"
		}
	},
	"controlosIvaEntreEmpresas": {
		"navwizard": {
			"controlosIva": "Controles del IVA",
			"empresas": "Empresas",
			"erros": "Errores/Advertencias",
			"final": "Fin"
		},
		"grid": {
			"fields": {
				"nEmpresa": "código de empresa",
				"nomeEmpresa": "Nombre de empresa",
				"codControloIVA": "código de control del IVA",
				"nomeControloIVA": "Nombre control del IVA",
				"erros": "Errores/Advertencias",
				"copiar": "Ignora las advertencias y copia"
			}
		},
		"confirmacaoOkCancel": {
			"message": "¿Está seguro de que desea copiar los códigos de control de IVA seleccionados para empresas?"
		},
		"success": "Copia realizada con éxito"
	},
	"conttrabbaselegal": {
		"title_detail": "Base jurídica del Contrato de Trabajo {{id}}",
		"title_new": "Nueva base jurídica para los contratos de trabajo",
		"title_plural": "Bases jurídicas de los Contratos de Trabajo",
		"pesquisa": "buscar",
		"saved": "Base legal del Contrato de Trabajo {{id}}, guardado exitosamente.",
		"error": "Base jurídica del Contrato de Trabajo {{id}}, no se puede guardar.",
		"deleted": "Base jurídica del Contrato de Trabajo {{id}}, eliminada correctamente.",
		"fields": {
			"contTrabBaseLegal": "Código de base jurídica",
			"tipoContrato": "Tipo de contrato",
			"descricao": "Descripción",
			"duracaoMinima": "Número mínimo de días",
			"duracaoMaxima": "Número de días de duración máxima (Renovación Ind.)",
			"duracaoMaxPrimEmpreg": "Número de días de duración máxima primer emp.",
			"dilacaoNovoContrato": "Número de días de prórroga para nuevo contrato",
			"numRenovNormal": "Renovación nº.",
			"numRenovExcepcionais": "Renovación nº.",
			"passagemSemTermo": "Número de días después de que la fecha para el den., entre en vigor",
			"contTBLNovaSeq": "Nuevo código legal tras renovaciones",
			"periodoExpNormalInd": "Normal",
			"periodoExpTecnicoInd": "Plazo técnico indeterminado",
			"periodoExpSuperioInd": "Término indeterminado superior",
			"periodoExpTermoCerto": "término correcto",
			"preAvisoDenunNormal": "Normal",
			"preAvisoDenunTecnico": "Plazo técnico indeterminado",
			"preAvisoDenunSuperio": "Plazo técnico indeterminado",
			"preAvisoDenunTermoC": "término correcto",
			"feriasPorMesCompl": "Vacaciones por mes completo",
			"feriasMaximo": "Vacaciones máximas",
			"aviDesvincTrab": "Aviso de terminación del trabajo.",
			"aviCaducidadeTrab": "Aviso de caducidad de obra",
			"aviCaducidadeEmpresa": "Aviso de vencimiento de la empresa",
			"compCaducidadeEmpre": "Compensación por vencimiento"
		}
	},
	"conttrabcab": {
		"title_detail": "Contrato de Trabajo {{id}}",
		"title_new": "Nuevo contrato de trabajo",
		"title_plural": "Contratos de Trabajo",
		"pesquisa": "buscar",
		"saved": "Contrato de Trabajo {{id}}, guardado exitosamente.",
		"error": "Contrato de Trabajo {{id}}, no se puede guardar.",
		"deleted": "Contrato de Trabajo {{id}}, eliminado exitosamente.",
		"fields": {
			"nContrato": "Número de contrato",
			"codEmp": "código de empleado",
			"descricao": "Descripción",
			"nCodBaseLegalContr": "Código de base jurídica",
			"codEstado": "Estado",
			"vigor": "En efecto",
			"dataCelebracaoContr": "Fecha de celebración",
			"dataInicioTrab": "fecha de inicio del trabajo",
			"dataCessacao": "fecha de terminación",
			"actContratada": "Actividad contratada",
			"retribuicao": "Venganza",
			"localTrab": "Lugar de trabajo",
			"perioNormalTrab": "Periodo normal de trabajo",
			"termo": "Indicación de términos estipulados.",
			"motivoTermo": "Razón que justifica el término.",
			"codContratoMotivo": "código de razón",
			"observacoes": "Observaciones"
		}
	},
	"conttrabmotivo": {
		"title_detail": "Motivo del contrato de trabajo {{id}}",
		"title_new": "Nuevo motivo de contrato de trabajo",
		"title_plural": "Razones del contrato de trabajo",
		"pesquisa": "buscar",
		"saved": "Motivo del Contrato de Trabajo {{id}}, guardado exitosamente.",
		"error": "Motivo del contrato de trabajo {{id}}, no se puede guardar.",
		"deleted": "Motivo del contrato de trabajo {{id}}, eliminado correctamente.",
		"fields": {
			"codContTrabMotivo": "Código de motivo del contrato de trabajo",
			"descrBreve": "Breve descripción",
			"descrCompleta": "Descripción completa",
			"propriedadeMotivo": "Propiedad"
		}
	},
	"cookies": {
		"consent": {
			"header": "<p>CentralGest Cloud utiliza cookies para personalizar el contenido de la aplicación.</p><p>Antes de comenzar <strong>necesitamos tu consentimiento</strong> para almacenar cookies en tu dispositivo.</p>",
			"about": {
				"1": "Las cookies son pequeños archivos de texto que utiliza la aplicación para hacer que la experiencia del usuario sea más eficiente.",
				"2": "La ley establece que podemos almacenar cookies en su dispositivo si son estrictamente necesarias para el normal funcionamiento de la aplicación.",
				"3": "CentralGest Cloud utiliza diferentes tipos de cookies, algunas de las cuales son colocadas por servicios externos que aparecen en nuestras páginas."
			}
		},
		"purpose": {
			"x-auth-token": "Identifica al usuario y le permite autenticarlo en el servidor.",
			"reCaptcha": "reCAPTCHA protege la aplicación contra spam y otros tipos de abuso automatizado"
		}
	},
	"crabd": {
		"title_detail": "Cruce de Asignación/Descuento {{id}}",
		"title_new": "Nuevo cruce de asignación/descuento",
		"title_plural": "Cruce de Bonificaciones/Descuentos",
		"pesquisa": "buscar",
		"deleted": "Cruce eliminado con éxito.",
		"saved": "El cruce se guardó correctamente.",
		"error": "El cruce no se puede guardar.",
		"updated": "Crossover actualizado exitosamente.",
		"fields": {
			"tipoAbDescOrigem": "Tipo",
			"codOrigem": "Asignación / Descuento",
			"nCodOrigem": "Código",
			"tipoAbDescDestino": "Asignación / Descuento",
			"codDestino": "Asignación / Descuento",
			"nCodDestino": "Código",
			"entSuporteEmpregado": "Empleado",
			"entSuportePatronato": "Mecenazgo",
			"incidenciaEmpregado": "(%) Incidencia",
			"incidenciaPatronato": "(%) Incidencia",
			"tipoExcecao": "Tipo de excepción",
			"categoriaAbDescDestino": "Categoría",
			"excecoes": "Excepciones"
		},
		"tableFields": {
			"crabdList": "Lista de cruces",
			"categoria": "Categoría",
			"cruzamento": "Cruce",
			"empregado": "Empleado",
			"patronato": "Mecenazgo",
			"abonoDesconto": "ANUNCIO",
			"desconto": "Descuento",
			"cod": "Código",
			"nome": "Nombre",
			"cat": "gato",
			"excecao": "Hay una excepción"
		},
		"removeCruzamento": "¿Está seguro de que desea eliminar la intersección?",
		"cruzdelsuccess": "El cruce se eliminó con éxito."
	},
	"crabonos": {
		"title_detail": "Cruce de Abono {{id}}",
		"title_new": "Nuevo cruce de permisos",
		"title_plural": "Cruces de permisos",
		"pesquisa": "buscar",
		"deleted": "Cruce eliminado con éxito.",
		"saved": "El cruce se guardó correctamente.",
		"error": "El cruce no se puede guardar."
	},
	"crdescontos": {
		"title_detail": "Cruce de descuento {{id}}",
		"title_new": "Nuevo crossover de descuento",
		"title_plural": "Cruces de descuento",
		"pesquisa": "buscar",
		"deleted": "Cruce eliminado con éxito.",
		"saved": "El cruce se guardó correctamente.",
		"error": "El cruce no se puede guardar."
	},
	"criterioDisponiveis": {
		"pesquisa": "Criterios disponibles",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"weightedAverageCost": "Costo promedio ponderado",
			"lifo": "Lifo (el último en entrar, el primero en salir)",
			"fifo": "Fifo (primero en entrar, primero en salir)",
			"standardCost": "Costo estándar",
			"averageCost": "Costo promedio",
			"specificLot": "Lote específico"
		}
	},
	"crneg": {
		"title_detail": "Excepción de cruce del empleado {{id}}",
		"title_new": "Nueva excepción de cruce",
		"title_plural": "Excepciones de cruce",
		"pesquisa": "buscar",
		"deleted": "La excepción de cruce se borró con éxito.",
		"saved": "La excepción de cruce se guardó correctamente.",
		"error": "La excepción de cruce no se puede guardar.",
		"fields": {
			"nCodEmp": "código de empleado",
			"nomeEmp": "Nombre del empleado",
			"nCodOrigem": "código fuente",
			"nCodDestino": "código de destino",
			"entSuporte": "Entidad de apoyo"
		},
		"modalCRNEGTitle": "Indique los empleados a incluir"
	},
	"crss": {
		"title_detail": "Régimen de Seguridad Social {{id}}",
		"title_new": "Nuevo régimen de seguridad social",
		"title_plural": "Seguridad social",
		"listRegimes": "Planes de seguridad social",
		"pesquisa": "buscar",
		"deleted": "Plan de Seguridad Social eliminado con éxito.",
		"saved": "Régimen de Seguridad Social {{id}}, guardado exitosamente.",
		"error": "Régimen de Seguridad Social {{id}}, no se puede guardar.",
		"nifentidades": "NIF de entidades",
		"fields": {
			"ncrss": "Código CRSS",
			"nome": "Nombre",
			"designaBreve": "Breve designación",
			"morada": "Familiar",
			"localidade": "Ubicación",
			"npostal": "Código postal",
			"telefone": "Teléfono",
			"fax": "Fax",
			"nbenefCaixa": "Número de identificación.",
			"crssSedeSocial": "CRSS de SEDE",
			"nifSegSocial": "NIF seg.",
			"nifCGA": "NIF CGA",
			"nifADSE": "NIF ADSE"
		},
		"table": {
			"nclcx": "Clase de caja",
			"nomeregime": "Nombre del esquema",
			"percentPatr": "% contribución.",
			"percentEmpr": "% contribución.",
			"codIntegracao": "Código de integración",
			"naoVaiSegSocial": "Enviar datos a SS"
		}
	},
	"dashboards": {
		"erp": {
			"pageTitle": {
				"vendas": "Volumen de negocios",
				"tesourariaLiquida": "Tesorería líquida",
				"rendimentos": "Ingreso",
				"gastosCompras": "Gastos de compras"
			},
			"vendas": {
				"ano": "{{año}} ventas",
				"dia": "Ventas del dia",
				"mes": "Ventas del mes",
				"vendasAno": "Ventas del año",
				"anoTop": "Los 5 artículos más vendidos en {{year}}"
			},
			"clientes": {
				"title": "Clientes",
				"ano": "Nuevos clientes de {{year}}",
				"dia": "Nuevos clientes del día.",
				"mes": "Nuevos clientes del mes",
				"clientesAno": "Nuevos clientes del año",
				"anoTop": "Los 5 clientes más importantes de {{year}}",
				"legendaClienteAno": "{{año}} clientes"
			},
			"table": {
				"nome": "Nombre",
				"valor": "Valor",
				"percentagem": "Porcentaje",
				"legends": {
					"vendasAno": "Ventas por mes",
					"tesourariaLiquidaDisponibilidade": "Disponibilidad",
					"tesourariaLiquidaFinanciamento": "Financiación"
				}
			},
			"compras": {
				"title": "Compras",
				"ano": "Compras de {{año}}",
				"dia": "compras del dia",
				"mes": "Compras del mes",
				"anoTop": "Los 5 artículos principales comprados en {{year}}"
			},
			"fornecedores": {
				"title": "Proveedores",
				"ano": "Nuevos proveedores de {{year}}",
				"dia": "Nuevos proveedores del día.",
				"mes": "Nuevos proveedores del mes",
				"anoTop": "Los 5 principales proveedores de {{year}}"
			},
			"comprasVendas": {
				"title": "Ventas y compras",
				"titleParam": "{{año}} ventas y compras",
				"legendas": {
					"vendas": "Ventas",
					"compras": "Compras",
					"diference": "Diferencia"
				}
			}
		},
		"financeiro": {
			"tableContasAOrdem": {
				"title": "Conéctese a los bancos en tiempo real",
				"descricao": "Descripción",
				"nomeBanco": "nombre del banco",
				"iban": "IBAN",
				"saldoAtual": "Saldo actual"
			},
			"tableValoresEmAberto": {
				"title": "Montos totales abiertos",
				"descricao": "Descripción",
				"valorEmAberto": "Valor abierto",
				"prazoMedio": "Plazo medio (días)"
			},
			"receberPagarTables": {
				"titleReceber": "Cantidades a recibir de los clientes",
				"titlePagar": "Importes a pagar a proveedores",
				"titleTableReceber": "Los 5 principales clientes con cuentas por cobrar",
				"titleTablePagar": "Top 5 proveedores con importes a pagar",
				"naoVencido": "No caducado",
				"vencido": "Atrasado"
			}
		},
		"rh": {
			"tiles": {
				"funcionariosAtivos": "Empleados activos",
				"entradas": "Entradas",
				"saidas": "Salidas",
				"subtitleMeses": "Últimos {{months}} meses",
				"tempoInteiro": "tiempo completo",
				"tempoParcial": "Tiempo parcial"
			},
			"graficos": {
				"funcionariosPorGenero": "Empleados por género",
				"funcionariosPorDepartamento": "Empleados por departamento",
				"funcionariosPorIdade": "Empleados por edad",
				"funcionariosPorTipoContrato": "Empleados por tipo de contrato",
				"custosFuncionarios": {
					"title": "Gastos mensuales",
					"vencimentosFaltas": "Salarios - Ausencias",
					"subRefeicao": "Sub.",
					"impostos": "Impuestos"
				}
			}
		},
		"ativos": {
			"bensAtivos": "Activos activos",
			"nFichasAtivos": "Papas fritas",
			"nAquisicoesAno": "Adquisiciones",
			"nVendasAbatesAno": "Ventas y matanzas",
			"valorAquisicao": "Valor de adquisición",
			"depreciacoesAcumuladas": "Depreciación acumulada.",
			"valorAtual": "Valor actual",
			"pie": {
				"ativosTangiveis": "Activos fijos tangibles",
				"ativosInTangiveis": "Activos intangibles / Prop.",
				"nFichas": "Número de fichas: {{chips}}"
			},
			"table": {
				"topAtivos": "Top 10 - Valor de adquisición",
				"codAmortizacao": "Código de diciembre.",
				"nomeAmortizacao": "Descripción",
				"nFichas": "Número de fichas",
				"valorAq": "Valor de adquisición",
				"valorDep": "Valor de depreciación",
				"valorLiq": "Valor neto",
				"percentDep": "% Depreciado"
			},
			"bar": {
				"title": "Depreciaciones mensuales",
				"depMonth": "Depreciaciones por mes",
				"depAcumulado": "Depreciación acumulada"
			},
			"radical": {
				"rdl431": "Tierra y recursos naturales",
				"rdl432": "Edificios y otras construcciones.",
				"rdl433": "Equipo básico",
				"rdl434": "Equipo de transporte",
				"rdl435": "Equipo administrativo",
				"rdl436": "Equipo biológico",
				"rdl437": "Otros activos fijos tangibles",
				"rdl441": "Buena voluntad",
				"rdl442": "Proyectos de desarrollo",
				"rdl443": "programas de computadora",
				"rdl444": "propiedad industrial",
				"rdl445": "Otros activos intangibles",
				"rdl446": "Otros activos intangibles",
				"rdl447": "Otros activos intangibles",
				"rdl421": "Tierra y recursos naturales",
				"rdl422": "Edificios y otras construcciones.",
				"rdl423": "Otras propiedades de inversión",
				"rdl424": "Otras propiedades de inversión",
				"rdl425": "Otras propiedades de inversión",
				"rdl426": "Otras propiedades de inversión",
				"rdl427": "Otras propiedades de inversión"
			},
			"messages": {
				"contab": "¡Hay adquisiciones realizadas en contabilidad que no están relacionadas con los activos!",
				"contabLink": "Crear formulario a partir de registro contable",
				"investEmCurso": "Hay inversiones en curso sin registro creado.",
				"investEmCursoLink": "Crear un formulario basado en inversiones en curso"
			}
		}
	},
	"datavalor": {
		"title_detail": "Valor de fecha {{id}}",
		"title_new": "Nueva fecha valor",
		"title_plural": "Fechas Valor",
		"pesquisa": "buscar",
		"saved": "Valor de datos {{id}}, guardado correctamente.",
		"error": "Valor de fecha {{id}}, no se puede guardar.",
		"deleted": "Valor de fecha {{id}}, eliminado correctamente",
		"fields": {
			"tipo": "Tipo",
			"keyValue": "Valor clave",
			"valor1": "Límite de impuestos",
			"valor2": "Límite contable",
			"valor3": "Tasa",
			"dataInicio": "Fecha de inicio"
		}
	},
	"debDiretosSEPA": {
		"buttons": {
			"toolbarEmissao": "Emisión",
			"toolbarImportar": "Importar",
			"clientesAderentes": "Clientes suscritos",
			"notificar": "Notificar",
			"imprimir": "Imprimir",
			"consultaFicheirosSEPA": "Consulta de expediente SEPA"
		},
		"fields": {
			"tipo": "Tipo",
			"valor": "Valor",
			"digitos": "No. de dígitos",
			"estado": "Estado",
			"meioPagamento": "Medio pago",
			"iban": "IBAN",
			"idDebitoDireto": "documento de domiciliación bancaria",
			"exampleIdDebito": "Ej: PTPTXXZZZZXXXXXXXXXX",
			"nConta": "Nro. de cuenta",
			"contaBanco": "Cuenta bancaria",
			"nDiario": "Número diario",
			"diario": "A diario",
			"nDescritivo": "Descriptivo no.",
			"descritivo": "Descriptivo",
			"nome": "Nombre",
			"emailDD": "Débitos directos por correo electrónico",
			"nAutorizacao": "Número de autorización",
			"inicio": "Comenzar",
			"fim": "Fin",
			"contrato": "Contrato",
			"firstEnviado": "FRST enviado",
			"codMeioPagamento": "Código.",
			"dataInicio": "Fecha de inicio",
			"contaIntermedia": "cuenta intermedia",
			"calendario": "Calendario",
			"docFaturacao": "documento de facturación",
			"dataDoc": "Fecha del doctor.",
			"docContabilidade": "Doc. Contable.",
			"docContabilidadeLiquidacao": "Documento contable de liquidación",
			"valorDD": "Valor de la deuda.",
			"erroLiquidacao": "Error de liquidación",
			"dataHora": "Fecha/Hora",
			"utilizador": "Usuario",
			"operacao": "Operación",
			"observacao": "Observación",
			"nDocFa": "Tipo de documento.",
			"nNumer": "Número de serie",
			"nDocumento": "Número de documento",
			"documento": "Documento",
			"valorEmAberto": "Valor abierto",
			"enviadoEmail": "Notificación por correo electrónico",
			"xmlGerado": "XML generado",
			"titularIBAN": "Titular del IBAN",
			"tipoFicheiro": "tipo de archivo",
			"nLoteFicheiro": "Lote de archivos",
			"nLotePagamento": "Lote de pago",
			"firstAsSTR": "Sec.",
			"nRegistos": "No. de registros",
			"fileName": "Archivo",
			"campo": "Campo",
			"mensagem": "Mensaje",
			"notificado": "notificado",
			"liquidacao": "Liquidación",
			"devolucao": "Devolver",
			"comErro": "con error",
			"valorTotal": "Valor total",
			"nErroCG": "Número de errores de CG",
			"importValorImputado": "Valor imputado",
			"valorLiquidacao": "Valor de liquidación",
			"recibo": "Recibo",
			"autorizacao": "Autorización",
			"dataLiquidacao": "Fecha de liquidación",
			"valorDoc": "Valor del documento."
		},
		"strings": {
			"tabConfigGerais": "Config.",
			"tabContasCredoras": "cuentas de crédito",
			"tabTiposDocumento": "Tipos de documentos",
			"tabClientes": "Clientes",
			"naoTemAcessoEstaConfiguracao": "No tienes acceso a esta configuración.",
			"dataVencimento": "Fecha de expiración",
			"nDiasPrimeiraComunicacao": "Número de días 1ª comunicación",
			"seguintes": "Próximo",
			"dataLiquidacao": "Fecha de liquidación",
			"chaveNumeroAutorizacao": "Clave del número de autorización",
			"exemploChave": "Ejemplo clave",
			"modalTitleConfiguracao": "Débitos directos SEPA - Configuración",
			"semDebitosDiretos": "Sin domiciliaciones",
			"comDebitosDiretos": "Con domiciliaciones bancarias",
			"modalTitleContaCredora": "Débitos directos SEPA - Cuenta acreedor",
			"modalTitleConfigClientes": "Débitos directos SEPA - Clientes suscriptores",
			"integracaoEmContabilidade": "Integración contable",
			"oFormatoIDDebitoDiretoErrado": "El formato del \"ID de domiciliación bancaria\" no sigue el ejemplo.",
			"haCamposNaoPreenchidos": "Hay campos sin completar que pueden causar errores en el futuro.",
			"titularConta": "Titular de la cuenta",
			"nomeFichaCliente": "Nombre del registro del cliente",
			"outroNome": "otro nombre",
			"ibanCredor": "IBAN (Acreedor)",
			"contratos": "Contratos",
			"contrato": "Contrato",
			"autorizacao": "Autorización",
			"consultar": "Consultar",
			"temSelecionarConta": "Tienes que seleccionar la cuenta.",
			"jaFoiEnviadaPrimeiraComunicacaoFRST": "La primera comunicación ya ha sido enviada (FRST)",
			"emailDebitosDiretosObrigatorio": "El correo electrónico para domiciliaciones bancarias es obligatorio.",
			"ibanClienteObrigatorio": "El IBAN del cliente es obligatorio.",
			"nContaObrigatorio": "El número de cuenta es obligatorio.",
			"nAutorizacaoDDObrigatorioGerarAutomaticamente": "El número de autorización de domiciliación bancaria es obligatorio.",
			"dataInicioNaoPodeSerSuperiorDataFim": "La Fecha de Inicio no puede ser mayor que la Fecha de Finalización",
			"naoAnexouAutorizacaoPertendeContinuar": "No adjuntó autorización para domiciliaciones bancarias.",
			"manual": "Manual",
			"auto": "Ser",
			"descricao": "Descripción",
			"documento": "Documento",
			"documentoAutorizacao": "Documento de autorización",
			"dataParaLiquidacaoSeraADataVencDocumento": "La fecha de liquidación será la fecha de vencimiento del documento.",
			"dataParaLiquidacaoSeraCalcPelosDiasConfig": "La fecha de liquidación se calculará según los días configurados.",
			"dataParaLiquidacaoSeraCalcPelosDiasConfig2": "Atención: Los días indicados son recomendados por instituciones bancarias.",
			"contaIntermediaParaLiquidacao": "Cuenta intermedia para liquidación",
			"configSaved": "La configuración de domiciliación bancaria SEPA se ha guardado correctamente.",
			"configClientesSaved": "La configuración del cliente se ha guardado correctamente.",
			"configClienteDeleted": "El cliente ha sido eliminado exitosamente.",
			"codMeioPagamentoExisteNaLista1": "El código de medio pago seleccionado ya existe en la configuración.",
			"codMeioPagamentoExisteNaLista2": "Si guarda, actualizará el registro existente.",
			"ficheirosEmitidos": "Archivos emitidos",
			"porLiquidar": "Por resolver",
			"liquidado": "Establecido",
			"emitido": "Emitido",
			"estado": "Estado",
			"valor": "Valor",
			"disponivelParaNovaEmissao": "Disponible para nueva edición",
			"documentoFaturacao": "Documento (Facturación)",
			"reciboLiquidacao": "Recibo (Liquidación)",
			"cliente": "Cliente",
			"historico": "Historia",
			"logModalTitle": "Registro de documentos {{ndoc}}",
			"anexos": "Adjuntos",
			"comentarios": "Comentarios",
			"ddsepaEmissaoPorContaCorrente": "Débitos directos SEPA - Emisión vía cuenta corriente",
			"gerarFicheiro": "Generar archivo",
			"gerar": "para generar",
			"resumo": "Resumen",
			"notificacao": "Notificación",
			"resumoPorContaCorrente": "Resumen por cuenta corriente",
			"registos": "Archivos",
			"dataVencimentoAte": "Fecha de vencimiento antes de",
			"dataLiquidacaoFirst": "Fecha de liquidación (1ª vez)",
			"ficheirosGerados": "Archivos generados",
			"errosEAvisos": "Errores y advertencias",
			"notificarDevedores": "Notificar a los deudores",
			"dadosAvancados": "Datos avanzados",
			"apagado": "Apagado",
			"alterado": "Cambió",
			"novo": "Nuevo",
			"contaCredora": "cuenta de crédito",
			"impressaoEmissoesModalTitle": "Impresión de emisiones de domiciliaciones bancarias - SEPA",
			"importadorModalTitle": "Débitos directos SEPA - Importación",
			"processarFicheiro": "Procesando el archivo...",
			"importarFicheiro": "Importando el archivo...",
			"dados": "Datos",
			"resumoLiquidacao": "Resumen del acuerdo",
			"processadosComSucesso": "Procesado exitosamente",
			"dadosDoFicheiro": "Datos del archivo",
			"errosImportacao": "Errores de importación",
			"importadosComSucesso": "Importado exitosamente",
			"importadosComErro": "Importado con error",
			"ficheiroProcessadoComSucesso": "Archivo procesado exitosamente",
			"resumoProcessamento": "Procesamiento de resumen",
			"liquidados": "Establecido",
			"devolvidos": "Devuelto",
			"naoLiquidados": "sin liquidar",
			"imputado": "imputado",
			"liquidadoSemImputacao": "Resuelto sin imputación",
			"devolvido": "Devuelto",
			"naoLiquidado": "sin liquidar",
			"valores": "Valores",
			"nDeRegistos": "Número de registros",
			"erroLiquidacao": "Error de liquidación",
			"nDocumentos": "# Documentos",
			"dataEmissao": "Fecha de asunto",
			"porNotificar": "Al notificar",
			"mostrar": "Espectáculo",
			"xNaoTemAutorizacaoAnexada": "{{count}} cliente(s) no tienen autorización adjunta.",
			"obterAutorizacao": "Obtener autorización",
			"mostrarTodosOsRegistos": "{{count}} cliente(s) configurado(s).",
			"dataLiquidacaoFRST": "Fecha de liquidación (primera vez)",
			"dataLiquidacaoRCUR": "Fecha de Liquidación (Siguiente)"
		},
		"messages": {
			"temCertezaMarcarRegistosSelDisponiveisParaEmissao": "¿Está seguro de que desea marcar los registros seleccionados como disponibles para una nueva emisión?",
			"temCertezaMarcarRegistoDisponiveisParaEmissao": "¿Está seguro de que desea marcar el registro como disponible para una nueva emisión?",
			"semRegistosSelecionados": "Debes seleccionar al menos un registro",
			"contaCredoraObrigatorio": "La cuenta de crédito es obligatoria.",
			"nAutorizacaoObrigatorio": "El número de autorización es obligatorio.",
			"desejaImprimir": "¿Quieres imprimir?",
			"devedoresNotificadosSucesso": "{{count}} deudores han sido notificados exitosamente.",
			"noFiles": "Seleccione el archivo a importar",
			"existemNdocumentosDisponiveisParaEmissao": "Hay {{count}} documentos disponibles para su emisión.",
			"naoTemAutorizacaoAnexada": "No hay ninguna autorización adjunta.",
			"alteracaoMeioPagamentoMsg": "Todos los clientes con domiciliación bancaria asociada al método de pago {{codMeioPagamento}} ({{nomeMeioPagamento}}) ahora están asociados al nuevo método de pago indicado y respectivo IBAN.",
			"alteracaoIbanMsg": "El método de pago {{codMeioPagamento}} ({{nomeMeioPagamento}}) con IBAN \"{{iban}}\" se actualizará al nuevo IBAN indicado.",
			"clienteComDDAlterados": "{{countClientes}} cliente(s) con domiciliación bancaria en la cuenta acreedora con IBAN: \"{{iban}}\" ahora tendrán el IBAN: \"{{newIban}}\" como cuenta acreedora.",
			"emailEnviadoComSucesso": "El correo electrónico fue enviado exitosamente.",
			"emailEnviadoComErro": "Se produjo un error al enviar el correo electrónico.",
			"temACertezaQuePertendeEnviarEmail": "¿Estás seguro de que quieres enviar el email con el formulario de afiliación por domiciliación bancaria?",
			"temDocumentosPorLiquidarAteDataIni": "Tiene {{count}} documentos por liquidar antes de la fecha de inicio.",
			"filtarRegistosQueNaoTemAutorizacao": "Mostrar clientes que no tienen autorización adjunta.",
			"temACertezaQuePertendeAnularFichEmitido": "¿Está seguro de que desea cancelar el archivo emitido: {{loteFicheiro}}?",
			"haErrosNosDocumentos": "Hay errores en los documentos de importación.",
			"naoHaNenhumDadoParaImportar": "No hay datos para importar.",
			"soPodeAnexarPdf": "El tipo de archivo no está permitido.",
			"mostrarTodosOsClientes": "Mostrar todos los clientes con y sin autorización adjunta.",
			"naoPodeGerarFichComValorTotalZero": "No puede generar el archivo con un valor total de 0.",
			"codMeioPagamentoObrigatorio": "El código."
		},
		"tooltips": {
			"geradoAutomatico": "Generado automáticamente",
			"atencaoSoFicaraoDisponiveisDataDocSuperiorADataIni": "Atención: Sólo estarán disponibles las deudas cuya fecha del documento sea superior a la fecha de inicio.",
			"ibanDiffDoMeioPagamento": "El IBAN \"{{iban}}\" es diferente al IBAN \"{{ibanMeioPagamento}}\" del método de pago {{codMeioPagamento}} ({{nomeMeioPagamento}})."
		},
		"tipoChaveAutorizacao": {
			"texto": "Texto",
			"conta": "Cuenta",
			"classeContrato": "Clase de contrato",
			"tipoContrato": "Tipo de contrato",
			"nContrato": "Número de contrato",
			"ibanCredor": "IBAN acreedor",
			"ibanCliente": "IBAN del cliente",
			"dia": "Día",
			"hora": "Tiempo",
			"minutos": "Minutos",
			"sequencial": "Secuencial"
		}
	},
	"declaracaoiva": {
		"configured": "<i class='fa fa-check' aria-hidden='true'></i> El servicio ya está configurado.",
		"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> El servicio no está configurado.",
		"btns": {
			"getXML": "Obtener archivo",
			"getRecapitulativaXML": "Obtener archivo de resumen",
			"btnValidarNaAT": "Validar en AT",
			"btnConfiguracao": "Ajustes",
			"repor": "Restablecer declaración",
			"previsualizar": "Avance",
			"balancete": "Balance",
			"documentosFiscais": "Documentos fiscales",
			"entregarDeclaracao": "Entregar",
			"configATLogin": "Configuración AT"
		},
		"tooltips": {
			"btnGuardarNotValid": "Para guardar la tabla 4 debe estar debidamente cumplimentada.",
			"btnGuardar": "Se debe cancelar la declaración para poder volver a registrar",
			"btnGetXML": "Se debe guardar la declaración para obtener el xml de declaración.",
			"btnGetRecapitulativaXML": "Se debe guardar la declaración para obtener el xml de declaración resumida",
			"validarNaAT": "Es necesario guardar la declaración para poder realizar operaciones en el AT",
			"guardarPrimeiroParaRealizarOperacao": "Es necesario guardar la declaración para poder realizar esta operación."
		},
		"messages": {
			"successValidarNaAt": "La declaración no contiene errores y/o advertencias.",
			"successSubmeterNaAt": "La declaración periódica de IVA se presentó correctamente a través del servicio web de AT.",
			"help": "<strong>Si es necesario realizar cambios en la declaración de IVA, deberá:</strong></br>1 - Exportar el archivo xml haciendo clic en el botón \"Obtener archivo xml\";</br>2 - Acceder Sitio web de la Autoridad Tributaria: <a class=\"text-white\" href=\"https://iva.portaldasfinancas.gov.pt/dpiva/portal/entregar-declaracao\"><strong> enlace",
			"submitAtPromptMessage": "¿Estás seguro de que quieres presentar la declaración?",
			"anuladasuccess": "¡Declaración anulada!",
			"temDeGuardar": "Debes guardar los datos para poder crear el archivo.",
			"savedsucessfully": "Declaración guardada",
			"errorTextDataGridRowNotOk": "Se deben completar todos los datos de la línea.",
			"entreguesuccess": "La declaración resumida del IVA se presentó correctamente",
			"validateOperacoesSedeValoresIguais": "Los valores de los campos 63 y 64 de {{nomeAnexo}} no coinciden con los valores de los campos {{campo1}} y {{campo2}} del anexo de la sede."
		},
		"showmessage": {
			"title": "No volver a mostrar",
			"message": "\"Ok\" si no quieres volver a ver este mensaje."
		},
		"text": {
			"attachment": "Anexo",
			"saved": "grabado"
		},
		"tabs": {
			"anexos": "Adjuntos",
			"rosto": "Rostro",
			"anexoRContinente": "Continente Anexo R",
			"anexoRAcores": "Anexo R Azores",
			"anexoRMadeira": "Anexo R madera",
			"anexoCampo40": "Anexo campo 40",
			"anexoCampo41": "Anexo campo 41",
			"adicionarAnexo": "Agregar archivo adjunto",
			"sede": "(Sed)"
		},
		"anexos": {
			"comum": {
				"sim": "Sí",
				"nao": "No"
			},
			"rosto": {
				"title": "Comenzar",
				"quadro0": {
					"desc1": "Identificación del contribuyente",
					"nif": "Número de identificación fiscal",
					"locSede": "Ubicación de la sede",
					"desc2": "Identificación de declaración",
					"periodo": "Periodo actual",
					"prazodecl": "Plazo de declaración",
					"dentroprazo": "Dentro del plazo",
					"foraprazo": "Fuera de fecha",
					"desc3": "Operaciones en un espacio distinto al de la sede",
					"continente": "Continente",
					"acores": "Azores",
					"madeira": "Madera",
					"desc4A": "Declaración recapitulativa",
					"check4A": "Indique si durante el período de referencia presentó alguna declaración sumaria",
					"desc5": "Sin operaciones",
					"check5": "Si durante el período al que se refiere la declaración no realizó operaciones pasivas o activas que deban incluirse en la tabla 06, marque en esta tabla y pase a la tabla 20",
					"desc20": "contador certificado",
					"nifcert": "TIN del contador público"
				}
			},
			"anexoR": {
				"extratodecperio": "Extracto de campo de la declaración periódica.",
				"valorReembolsoSolicitado": "Ha solicitado una devolución del IVA, pero aún no ha procesado las relaciones de devolución.",
				"quadro6": {
					"title": "Autorización",
					"desc": "¿Realizó operaciones de esta naturaleza en las que, como comprador, pagó el impuesto?",
					"point1": "¿A qué se refieren los incisos a), b) y c) del artículo 42 del CIVA?",
					"point2": "¿A qué se refieren los incisos f) y g) del núm. 3 del artículo 3 y los incisos a) y b) del núm. 2 del artículo 4 del CIVA?",
					"desc1": "Transferencia de bienes y prestación de servicios realizadas sobre las que se ha pagado impuesto",
					"basetribut": "Base imponible",
					"impfavorestado": "Impuesto a favor del estado",
					"field1e2": "A tarifa reducida",
					"field5e6": "A tasa intermedia",
					"field3e4": "A ritmo normal",
					"isentaounaotrib": "Exentos o no gravados",
					"field7": "Transferencias intracomunitarias de bienes y servicios mencionadas en los estados resumidos",
					"field8": "Operaciones que dan derecho a deducción",
					"field9": "Operaciones que no confieren derecho a deducción",
					"desc2": "Adquisiciones intracomunitarias de bienes y operaciones asimiladas",
					"field12e13": "cuyo impuesto fue pagado por el declarante",
					"field14": "Amparados por los artículos 15 de CIVA o RITI",
					"field15": "Amparados por los apartados 3, 4 y 5 del artículo 22 del RITI",
					"field10e11": "Totales",
					"desc3": "Servicios realizados por contribuyentes de otros estados miembros cuyo impuesto fue pagado por el declarante",
					"desc4": "Importaciones de bienes cuyo impuesto fue pagado por el declarante",
					"field62": "Base imponible total",
					"desc5": "Deducible de impuestos",
					"impostfavSujPassivo": "Impuesto a favor de la suciedad.",
					"field20": "Activos no corrientes (activos fijos)",
					"inventarios": "Inventarios (existencias)",
					"field21": "A tarifa reducida",
					"field23": "A tasa intermedia",
					"field22": "A ritmo normal",
					"field24": "Otros bienes y servicios",
					"desc6": "Regularizaciones mensuales/trimestrales y anuales",
					"desc6NotSedeAnexoTotalPassivo": "Impuesto total a favor del contribuyente",
					"desc6NotSedeAnexoTotalImposto": "Impuesto total a favor del Estado",
					"desc7": "Excedente a declarar del periodo anterior",
					"field61": "Campo 96 de la declaración anterior - apartado 4 del artículo 22.",
					"desc8": "Anexo R (1) Operaciones en un espacio distinto del cuartel general",
					"impdedutivel": "Deducible de impuestos",
					"impliquidado": "Impuesto pagado",
					"desc9": "Anexo R (2) Operaciones en un espacio distinto del cuartel general",
					"desc10": "Regularizaciones a favor del Sujeto Sujeto Comunicadas por DS Cobranças (Mod. - BH008)",
					"desc11": "Totales",
					"field90": "Base imponible total",
					"field91": "Impuesto total a favor del contribuyente",
					"field92": "Impuesto total a favor del Estado",
					"field93": "Impuesto a pagar al Estado",
					"field94": "Crédito fiscal recuperable",
					"field95": "solicito un reembolso",
					"field96": "Exceso a reportar"
				},
				"quadro6A": {
					"title": "Desarrollo",
					"descA": "Operaciones situadas en Portugal en las que, como adquirente, usted pagó el IVA adeudado",
					"efetuadentresident": "Realizadas por entidades residentes en",
					"basetrib": "Base imponible",
					"field97": "Países comunitarios",
					"field98": "Terceros territorios",
					"descB": "Operaciones en las que pagó el IVA adeudado aplicando la regla de inversión del sujeto pasivo",
					"field99": "Oro",
					"field100": "Adquisiciones de inmuebles sin exención de exención",
					"field101": "Residuos",
					"field102": "Servicios de construcción civil",
					"field105": "Emisiones de gases de efecto invernadero",
					"field107": "Adquisición de corcho y otros productos de origen forestal",
					"descC": "Las operaciones a que se refieren los incisos f) y g) del n. 3 del artículo 3 y los incisos a) y b) del n. 2 del artículo 4 del CIVA.",
					"field103": "Si realizó operaciones de esta naturaleza, indique su valor",
					"descD": "Operaciones a que se refieren los incisos a), b) y c) del artículo 42 del CIVA",
					"field104": "Si realizó operaciones de esta naturaleza, indique su valor",
					"soma6A": "Suma del cuadro 06-A",
					"desc10": "Las operaciones a que se refieren los incisos f) y g) del n. 3 del artículo 3 y los incisos a) y b) del n.",
					"field264": "En caso afirmativo, indique el monto del impuesto pagado, sin perjuicio de que aparezca en los campos 2, 6 o 4 de la P.06"
				},
				"modal": {
					"title": "Extracto del campo de declaración periódica - Campo {{field}}",
					"fields": {
						"periodo": "Período",
						"nDiario": "Número diario",
						"nDocInterno": "Número de documento",
						"nConta": "Nro. de cuenta",
						"nContrib": "número de contribuyente",
						"valorDebito": "Monto del débito",
						"valorCredito": "Valor del crédito",
						"codIva": "código de IVA",
						"areaRegionalStr": "Área regional del IVA",
						"nCampoOficial": "Campo oficial",
						"contaOrigem": "Cuenta base imponible",
						"nomeContaOrigem": "Nombre de la base imponible",
						"valorBase": "Valor base"
					}
				}
			},
			"anexoCampo40": {
				"title": "Regularizaciones",
				"desc1": "Regularizaciones a favor del contribuyente",
				"desc2": "Regularizaciones cuyo crédito no supere los 750€, IVA incluido",
				"baseincidenciareg": "Base de incidencia de la regularización",
				"ivareg": "IVA regularizado",
				"field3e4": "El valor del crédito no supera los 750€",
				"desc3": "Otras regularizaciones",
				"field3Ae4A": "Regularizaciones reguladas en los artículos 23 a 26",
				"field3Be4B": "Otras regularizaciones (Ej: fusión)",
				"desc4": "Valor total del campo 40",
				"desc5": "Certificación por Auditor Oficial (ROC)",
				"certirevisoroficialcontas": "Certificación por revisor fiscal.",
				"nifroc": "Número de identificación fiscal de la República de China",
				"modal": {
					"roc": {
						"title": "Número de identificación fiscal de la República de China",
						"naopodeservazio": "El número de identificación fiscal de la República de China no puede estar vacío.",
						"jaexiste": "El número de identificación fiscal de la República de China ya está ingresado."
					}
				}
			},
			"anexoCampo41": {
				"title": "Regularizaciones",
				"desc1": "Regularizaciones a favor del estado",
				"desc2": "Otras regularizaciones",
				"baseincidenciareg": "Base de incidencia de la regularización",
				"ivareg": "IVA regularizado",
				"field3Ae4A": "Regularizaciones reguladas en los artículos 23 a 26",
				"field3Be4B": "Otras regularizaciones",
				"field3Ce4C": "Artículo 6 del Decreto-Ley n° 198/90, de 19 de junio",
				"field3De4D": "Decreto-Ley N° 19/2017, de 14 de febrero",
				"desc3": "Valor Total del Campo 41"
			},
			"table40e41": {
				"fields": {
					"tipoRegularizacaoCampo": "Tipo de regularización",
					"nif": "número de contribuyente",
					"nPedido": "Nro. de orden",
					"baseIncidencia": "Base de incidencia de la regularización",
					"ivaRegularizado": "IVA regularizado",
					"dataEmissao": "Fecha de asunto"
				}
			}
		},
		"recapitulativa": {
			"title": "Declaración recapitulativa del IVA",
			"table": {
				"paisDestinoStr": "País de destino (2)",
				"nContribuinte": "NIF del adquirente (3)",
				"valorOperacoes": "Valor en EUROS (4)",
				"tipoOperacaoStr": "Tipo de operación (5)"
			},
			"quadros": {
				"quadro1": {
					"title": "Cuadro 01",
					"desc1": "Identificación del contribuyente",
					"nif": "Número de identificación fiscal"
				},
				"quadro2": {
					"title": "Cuadro 02",
					"desc": "Tipo de declaración",
					"primeira": "Primero",
					"substituicao": "Reemplazo",
					"desc1": "¿Hubo un cambio en la frecuencia de envío de trimestral a mensual?",
					"field21": "Cambiar la frecuencia de envío de trimestral a mensual",
					"desc21": "¿El monto mensual reemplaza completamente el monto trimestral declarado anteriormente o no?",
					"field22": "No hay operaciones intracomunitarias en el periodo respectivo",
					"field23": "Otros cambios en las operaciones intracomunitarias",
					"field24": "Transferencias de mercancías en consignación",
					"field241": "Sin cambios en el período respectivo",
					"field2411": "No hay transferencias de mercancías declaradas en consignación.",
					"field2412": "Con transferencias de mercancías en consignación declaradas",
					"field242": "Con cambios en el respectivo período.",
					"field243": "Declarar por primera vez en el período respectivo"
				},
				"quadro3": {
					"title": "Tabla 03",
					"desc": "Periodo al que se refiere",
					"mensal": "Mensual",
					"trimestral": "Trimestral",
					"mesincltrimestre": "Mes(es) incluidos en el trimestre"
				},
				"quadro45": {
					"title": "Tabla 04/05",
					"desc4": "Descripción de operaciones intracomunitarias",
					"modalTitle": "Descripción de operaciones intracomunitarias",
					"modalmessages": {
						"tipoopvazio": "El tipo de operación no puede estar vacío.",
						"paisdestvazio": "El país de destino no puede estar vacío."
					},
					"table": {
						"prefpaisdest": "Prefijo del país de destino (2)",
						"nif": "NIF del adquirente (3)",
						"valor": "Valor (4)",
						"tipoop": "Tipo de operación (5)"
					},
					"desc5": "Suma de valores declarados en la tabla 4 (agrupados por tipo de operación)",
					"field10": "Suma (solo de valores que se relacionan con las operaciones tipificadas en la columna 5 con 1)",
					"field11": "Ventas totales de medios de transporte nuevos a particulares y medios similares procedentes de otros estados miembros",
					"field17": "Suma (solo de los valores que se relacionan con las operaciones indicadas en la columna 5 con 4)",
					"field18": "Suma (solo de los valores que se relacionan con las operaciones indicadas en la columna 5 con 5)",
					"field19": "Totales (10+11+18)"
				},
				"quadro6": {
					"title": "Cuadro 06",
					"desc6": "Descripción de las transferencias de mercancías en consignación.",
					"table": {
						"prefpaisdest": "Prefijo del país de destino (2)",
						"nif": "NIF del destinatario (3)",
						"nifOriginal": "NIF del destinatario original (4)",
						"codigo": "Código (5)"
					}
				}
			}
		}
	},
	"demfinsnc": {
		"fields": {
			"processafluxo": "Procesar flujos de efectivo",
			"vercomdados": "Ver solo artículos con valores",
			"processaIntercalares": "Procesar demostraciones provisionales",
			"variaveisapuramento": "Variables del despacho provisional",
			"periointercalartable": "Inventario final del período intermedio del año \"{{year}}\""
		},
		"tabs": {
			"balancoTitle": "Balance",
			"demresultNatTitle": "RD por naturaleza",
			"demresultFuncTitle": "DR por funciones",
			"demfluxocaixaTitle": "Estado de flujos de efectivo",
			"demAltCPTitle": "DACP {{dataDemAltCP}}",
			"demAltCP2Title": "DACP {{dataDemAltCP2}}",
			"intContaTitle": "Rangos de cuenta",
			"balanceteTitle": "Balance",
			"fluxosCaixaTitle": "Flujos de efectivo",
			"table": {
				"nContaDe": "Número de cuenta",
				"nContaAte": "El número cuenta hasta",
				"mesPeriodo": "Período del mes",
				"nConta": "Nro. de cuenta",
				"nomeConta": "Nombre",
				"designacao": "Designación",
				"codFluxo": "Código.",
				"parent": {
					"parent0": "Activo",
					"parent1": "Equidad",
					"parent2": "Pasivo"
				}
			},
			"capitalRealizado": "Capital",
			"accoesQuotasProprias": "Comportamiento",
			"premiosDeEmissao": "Premios",
			"reservasLegais": "Reservas",
			"outrasReservas": "Otros",
			"resultadosTransitados": "Resultados",
			"ajustamentosEmActivosFinanceirosOutrasVariacoes": "Ajustes",
			"excedentesDeRevalorizacao": "Superávit",
			"resultadoLiquidoDoPeriodo": "Resultado",
			"outrosInstrumentosCapProprio": "Otros"
		},
		"radio": {
			"sncncrf": "SNC-NCRF",
			"microentidade": "Microentidades"
		},
		"btn": {
			"notas": "Notas",
			"valdefeito": "Valores predeterminados",
			"previsualizargrid": "Procesar en parrilla",
			"demfinsncsaved": "Versiones finlandesas.",
			"repvaloriginal": "Restablecer todos los valores originales",
			"periodo": "Período intermedio:",
			"anexoRelatorioGestao": "Anexo / Informe de Gestión"
		},
		"modal": {
			"title": "Notas de los estados financieros",
			"titlelistsaved": "Versiones de estados financieros guardadas",
			"titleobs": "Indicar observaciones",
			"titlepreviewpdf": "SNC estados financieros",
			"obs": "Observaciones",
			"badgecaptionsaved": "Estado financiero seleccionado",
			"tabs": {
				"table": {
					"rubrica": "Rúbricas",
					"notas": "Notas"
				},
				"balanco": {
					"title": "Balance",
					"tab": {
						"titles": {
							"passivo": "Pasivo"
						},
						"ativonaocorrente": "Activo no corriente",
						"ativocorrente": "Activo corriente",
						"capitalproprio": "Equidad",
						"passivonaocorrente": "Pasivos no corrientes",
						"passivocorrente": "Pasivos corrientes"
					}
				},
				"demresult": {
					"title": "Estado de resultados",
					"pornaturezas": "Por naturalezas",
					"porfuncoes": "Por funciones"
				},
				"capproprioperio": {
					"title": "Patrimonio del Período",
					"demaltcptproprio": "Dem. de cambios en el patrimonio del período"
				},
				"fluxocaixa": {
					"title": "Flujos de efectivo",
					"atvop": "Actividades operativas",
					"atvinvest": "Actividades de inversión",
					"atvfin": "Actividades de financiación"
				}
			},
			"listsaved": {
				"hora": "Tiempo",
				"obs": "Observaciones"
			},
			"message": {
				"valdef": "<b>Atención:</b> Si continúa con esta operación, restablecerá los valores predeterminados.",
				"delidsaved": "¿Eliminar el estado financiero previamente guardado?",
				"badgecaptioncellmanuallyedited": "Celda editada manualmente por el usuario",
				"delversaodemfinant": "¡La versión del estado financiero se eliminó correctamente!",
				"savedemfin": "¡Estado financiero de SNC guardado exitosamente!"
			}
		},
		"messages": {
			"periodoempty": "El período no puede estar vacío.",
			"notastemdeserumnumero": "Las notas deben ser un número."
		},
		"language": {
			"language0": "portugués",
			"language1": "Inglés",
			"idioma": "Idioma: {{idioma}}"
		}
	},
	"depto": {
		"title_detail": "Departamento {{id}}",
		"title_new": "Nuevo Departamento",
		"title_plural": "Departamentos",
		"pesquisa": "buscar",
		"saved": "Departamento {{id}}, guardado exitosamente.",
		"error": "Departamento {{id}}, no se puede guardar.",
		"fields": {
			"nDepto": "Código.",
			"nDeptoPlaceholder": "Código de departamento",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del departamento"
		}
	},
	"descritivos": {
		"title_detail": "Descripción contable {{id}}",
		"title_new": "Nueva descripción contable",
		"title_plural": "Descripciones contables",
		"pesquisa": "buscar",
		"saved": "Descripción de la cuenta {{id}}, guardada correctamente.",
		"error": "Descripción de contabilidad {{id}}, no se puede guardar.",
		"deleted": "Descripción de cuenta {{id}}, eliminada correctamente.",
		"fields": {
			"nDescrit": "Código.",
			"nDescritPlaceholder": "Código de descripción contable",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre de la descripción contable",
			"descricao": "Descripción",
			"descricaoPlaceholder": "Descripción de la descripción contable",
			"tipoDescritivo": "Tipo",
			"tipoDescritivoPlaceholder": "Tipo de descripción contable"
		}
	},
	"dgemps": {
		"title_detail": "Colaborador {{id}}",
		"title_new": "Nuevo colaborador",
		"title_plural": "Colaboradores",
		"pesquisa": "buscar",
		"saved": "Empleado {{id}} guardado exitosamente",
		"error": "El empleado {{id}} no se puede guardar.",
		"fields": {
			"apelido": "Apellido",
			"apelidoPlaceholder": "apellido del empleado",
			"codEmp": "Colaboración.",
			"codEmpPlaceholder": "código de empleado",
			"codPostal": "Código Postal",
			"codPostalPlaceholder": "código postal del empleado",
			"codservicoprh": "Servicio",
			"codservicoprhPlaceholder": "Código de servicio",
			"email": "Correo electrónico",
			"emailPlaceholder": "Correo electrónico del empleado",
			"formRemun": "Forma de remuneración",
			"formRemunPlaceholder": "Forma de remuneración",
			"local": "Ubicación",
			"masterDetail": {
				"antigo": "valor antiguo",
				"campo": "Campo",
				"novo": "Nuevo valor"
			},
			"morada": "Familiar",
			"moradaPlaceholder": "dirección del empleado",
			"nTelefone": "Número de teléfono.",
			"nTelefonePlaceholder": "Número de teléfono del empleado",
			"nome": "Nombre",
			"nomeCategoria": "Categoría",
			"nomeCategoriaPlaceHolder": "Categoría",
			"nomeCompleto": "nombre completo",
			"nomeLocalida": "Ubicación",
			"nomePlaceholder": "Nombre del colaborador",
			"nomelocalidaPlaceholder": "Ubicación del empleado",
			"operacao": "Operación",
			"rua": "Camino",
			"ruaPlaceholder": "Calle del empleado",
			"telemovelPart1": "Teléfono móvil",
			"telemovelpart1Placeholder": "El celular del empleado.",
			"tipoRefeição": "tipo de comida"
		},
		"recibos": {
			"title": "Recibo de nómina",
			"fields": {
				"nrecibo": "Recibo no.",
				"nprocessamento": "Nro. de tramitación.",
				"dataprocessamento": "Fecha de procesamiento",
				"datarecibo": "fecha de recibo",
				"lido": "Leer"
			}
		},
		"sexes": {
			"0": "Femenino",
			"1": "Masculino",
			"2": "N / A"
		},
		"taxResidence": {
			"0": "Residente en territorio portugués",
			"1": "No residente que obtiene ingresos de un solo empleador",
			"2": "No residente que obtiene ingresos de más de un empleador"
		},
		"workRegime": {
			"0": "Privado",
			"1": "servicio civil"
		},
		"salaryCalculationMethod": {
			"0": "N / A",
			"1": "Por salario base",
			"2": "Para horas de trabajo"
		},
		"twelfthsCalculationType": {
			"0": "Subs.",
			"1": "subsidio de navidad",
			"2": "subsidio de vacaciones",
			"3": "No realiza procesamiento"
		},
		"twelfthsValuesType": {
			"0": "0",
			"50": "50",
			"100": "100"
		},
		"home": {
			"altDeFichasPorAprov": "Hay cambios en los registros de los empleados para ser aprobados.",
			"pedidosFeriasPorAprov": "Tiene solicitudes de vacaciones no aprobadas para el(los) servicio(s)",
			"faltasPorAprov": "Hay ausencias por aprobar en el(los) servicio(s)",
			"abonosPorAprov": "Tiene asignaciones para ser aprobado para el(los) servicio(s)",
			"intFerias": "vacaciones",
			"intFaltas": "Intervalo de falta",
			"recibos": "Ingresos",
			"comunicacoes": "Comunicaciones",
			"ferias": "Vacaciones",
			"feriasMarc": "Vacaciones programadas",
			"feriasAprov": "Vacaciones aprobadas",
			"feriasReje": "vacaciones rechazadas",
			"faltas": "Faltas",
			"faltasMarc": "Faltas sancionadas",
			"faltasAprov": "Faltas aprobadas",
			"faltasReje": "Faltas rechazadas",
			"aprovadas": "Aprobado",
			"restantes": "Restante",
			"marcadas": "Marcado",
			"rejeitadas": "Rechazado"
		}
	},
	"dgempsfull": {
		"title_detail": "Colaborador {{id}}",
		"title_new": "Nuevo colaborador",
		"title_plural": "Colaboradores",
		"pesquisa": "buscar",
		"saved": "Empleado {{id}} guardado exitosamente",
		"error": "El empleado {{id}} no se puede guardar.",
		"deleted": "Colaborador {{id}} eliminado exitosamente",
		"btn": {
			"configWSS": "Configuración de WSS"
		},
		"tabelaIRSColaboradoresPorValidar": "Por favor valide la tabla del IRS de los empleados:",
		"fields": {
			"morada": "Camino",
			"moradaPlaceholder": "Calle del empleado",
			"nTelefone": "Teléfono",
			"nTelefonePlaceholder": "Número de teléfono del empleado",
			"dtMotMov": "Última actualización de registro",
			"dtMotMovPlaceholder": "Fecha de registro del empleado",
			"situacao": "Situación",
			"situacaoPlaceholder": "Estado de empleado",
			"sexo": "Sexo",
			"dtNascimento": "Fecha de nacimiento",
			"dtNascimentoPlaceholder": "Fecha de nacimiento del empleado",
			"nacionalidad": "Nacionalidad",
			"nacionalidadPlaceholder": "Nacionalidad del empleado",
			"concelho": "Condado",
			"concelhoPlaceholder": "Municipio del colaborador",
			"freguesia": "Parroquia",
			"freguesiaPlaceholder": "parroquia del colaborador",
			"nbIdentidade": "número de tarjeta de ciudadano",
			"nbIdentidadePlaceholder": "Número de cédula ciudadana del empleado",
			"dtBiValidade": "Vigencia de la tarjeta ciudadana",
			"dtBiValidadePlaceholder": "Fecha de caducidad de la tarjeta de ciudadano/cédula de identidad",
			"numSegSoc": "niss",
			"numSegSocPlaceholder": "Número de identificación del seguro social del empleado",
			"numContrib": "NIF",
			"numContribPlaceholder": "Número de identificación fiscal del empleado",
			"nCartaoTicketRef": "Número de tarjeta de comida",
			"nCartaoTicketRefPlaceholder": "Número de tarjeta de comida del empleado",
			"banco": "Banco de empleados",
			"iban": "IBAN del empleado",
			"swift": "Empleado SWIFT",
			"codNHABQPessoal": "Nivel de calificación",
			"codNHABQPessoalPlaceholder": "Nivel de calificación del empleado",
			"dtAdmEmpresa": "Fecha de admisión",
			"dtAdmEmpresaPlaceholder": "Fecha de admisión del empleado",
			"vencimBase": "Salario base",
			"vencimBasePlaceholder": "Salario base del empleado",
			"nDiuturnidad": "Número de períodos de antigüedad",
			"valorUnDiutu": "Valor de cada pago de antigüedad",
			"horasDia": "Horas de trabajo por día",
			"horasDiaPlaceholder": "Horas de trabajo por día",
			"horasSemana": "Horas de trabajo por semana",
			"horasSemanaPlaceholder": "Horas de trabajo por semana",
			"horasMes": "Horas de trabajo por mes",
			"horasMesPlaceholder": "Horas de trabajo por mes",
			"remunHora": "pago por hora",
			"temSubAlim": "tiene un subsidio",
			"valorSubAlim": "Valor diario",
			"temPreProcessamentoSubAlimen": "Tiene preprocesamiento",
			"valorSFerias": "Monto del subsidio de vacaciones",
			"valorSFeriasPlaceholder": "Valor del subsidio de vacaciones del empleado",
			"valorSNatal": "Monto del subsidio de Navidad",
			"valorSNatalPlaceholder": "Valor del aguinaldo del empleado",
			"metodPaga": "Método de cálculo del salario",
			"divisaoTrab": "División (duración) del trabajo",
			"divisaoTrabPlaceholder": "División (duración) del trabajo de los empleados.",
			"tipoContrato": "Tipo de contrato",
			"tipoContratoPlaceholder": "Tipo de contrato de empleado",
			"dtIniContrat": "Fecha de firma",
			"dtIniContratPlaceholder": "Fecha de firma del empleado",
			"dtFimContrat": "Fecha de renovación del contrato",
			"dtFimContratPlaceholder": "Fecha de renovación del contrato de empleado",
			"dataEfetividade": "Fecha de vigencia",
			"dataEfetividadePlaceholder": "Fecha de vigencia del contrato del empleado.",
			"temSegurAcTr": "¿Tienes seguro de accidentes de trabajo?",
			"nSeguradAcTr": "aseguradora de accidentes de trabajo",
			"nSeguradAcTrPlaceholder": "Compañía de seguros contra accidentes de empleados",
			"caixaPrev": "centro de registro de seguridad social",
			"caixaPrevPlaceholder": "Centro de registro de seguridad social de empleados",
			"classeCaixa": "Régimen de seguridad social",
			"classeCaixaPlaceholder": "Régimen de seguridad social de los empleados",
			"nTabIrs": "tabla del IRS",
			"nTabIrsPlaceholder": "Tabla del IRS de empleados",
			"numDepend": "Número de dependientes sin discapacidad",
			"numDependPlaceholder": "Número total de dependientes sin discapacidad del empleado",
			"numDependDef": "Número de dependientes con discapacidad",
			"numDependDefPlaceholder": "Número total de dependientes discapacitados con discapacidad del empleado",
			"numDependDefLabel": "Número de dependientes discapacitados con incapacidad permanente igual o superior al 60%",
			"regimeTrabalho": "Régimen de trabajo",
			"tipoCalcDuodecimos": "Cálculo del valor",
			"calcDuodecVariavel": "Cálculo de doceavos sobre valores variables.",
			"eExResidente": "Fiscalidad de antiguos residentes",
			"eExResidenteLabel": "Sujetos pasivos que se convierten en residentes fiscales de conformidad con los apartados 1 y 2 del artículo 16 del CIRS",
			"nPocRemuLiq": "Cuenta contable donde se registra el importe a cobrar",
			"classeTrab": "clase de trabajo",
			"classeTrabPlaceholder": "clase de trabajo de empleado",
			"funcao": "Función",
			"funcaoPlaceholder": "Papel del colaborador",
			"seccao": "Operación/sección",
			"seccaoPlaceholder": "Sección de operación/empleado",
			"nZona": "Zona",
			"nZonaPlaceholder": "Zona de empleados",
			"departamento": "Departamento",
			"departamentoPlaceholder": "Departamento de empleados",
			"nSubDe": "Subdepartamento",
			"nSubDePlaceholder": "Subdepartamento de empleados",
			"ccusto": "centro de costos",
			"ccustoPlaceholder": "centro de costos de empleados",
			"nCodRepCC": "Desglose de puntos personales",
			"nCodRepCCPlaceholder": "Desglose de puntos personales de los empleados",
			"categoria": "Categoría",
			"categoriaPlaceholder": "Categoría de empleado",
			"profissao": "Profesión",
			"profissaoPlaceholder": "Profesión del empleado",
			"sitProfissao": "Estatus profesional",
			"sitProfissaoPlaceholder": "Situación profesional del empleado.",
			"codcct": "Contrato colectivo de trabajo",
			"codcctPlaceholder": "Contrato colectivo de trabajo para empleados",
			"nivQualific": "Nivel de calificación",
			"nivQualificPlaceholder": "Nivel de calificación del empleado",
			"motivoRegisto": "Razón de la última actualización",
			"situacaoEmprego": "Estado de empleado",
			"residenciaFiscal": "Residencia fiscal",
			"nCodABVencimento": "Madurez",
			"nCodABSubAlimentacao": "Subsidio de comida",
			"nCodABDiuturnidades": "Pagos de antigüedad",
			"nCodABSubFerias": "subsidio de vacaciones",
			"nCodABSubNatal": "subsidio de navidad",
			"nCodABFerias": "Vacaciones",
			"nCodDescIRS": "IRS",
			"codRepFinancas": "oficina de impuestos",
			"codRepFinancasPlaceholder": "oficina de impuestos",
			"conjugeIncapacidad60": "Cónyuge con discapacidad igual o superior al 60%",
			"temDescIRSTxFixa": "Aplicar descuento de tarifa fija del IRS",
			"percDescTaxaFixaIRS": "Tasa fija del IRS (tasa marginal)",
			"deficienteForcasArmadas": "Fuerzas armadas discapacitadas",
			"naoEfetuaFCT": "Fondos de Compensación",
			"naoEfetuaFCTLabel": "No calcula fondos de compensación.",
			"sindicato": "Unión",
			"ncartsindicato": "número de tarjeta sindical",
			"temRendJovensTrab": "Tiene exención parcial de ingresos.",
			"temRendResNaoHabit": "Los residentes no habituales están exentos",
			"dtInicio": "Fecha de inicio",
			"crlCalcLiquido": "Calcula el ingreso neto",
			"crlValorLiquido": "patrimonio neto",
			"crlValorLiquidoEmMe": "Valor neto en moneda extranjera",
			"crlAbonoLiquido": "Prestación",
			"nDiasFeriasAnoAnteri": "Días de vacaciones el año pasado.",
			"duodecimosConfigEmpresa": "Doceavos configurados por empresa",
			"usaDuodecimosPorEmp": "Utilice doceavos por empleado",
			"duodecimosSubNatal": "Sub.",
			"duodecimosSubFerias": "Sub.",
			"temRedIrsCredHabitac": "Aplica reducción a personas físicas con crédito habitacional para vivienda propia y permanente (medida solo vigente durante 2023)",
			"temRedCtrArrendameHP": "Aplicar reducción sujeta a titulares de contratos de arrendamiento/crédito para vivienda permanente",
			"localTributacaoRendi": "Residencia",
			"situacaoCivilTitular": "Situación",
			"naturezaRendimento": "Tipo de rendimiento"
		},
		"groups": {
			"estadoSS": {
				"title": "seguridad social estatal",
				"naoVerificado": "No verificado",
				"verificar": "para comprobar",
				"registar": "Registro",
				"consultar": "Consultar"
			},
			"addressContacts": {
				"title": "Dirección y contactos"
			},
			"identification": {
				"title": "Identificación"
			},
			"bankData": {
				"title": "Datos bancarios"
			},
			"taxData": {
				"title": "Datos fiscales",
				"sections": {
					"irs": "IRS",
					"socialSecurity": "Seguridad social",
					"financas": "Finanzas",
					"rendJovensTrab": "Exención parcial para los rendimientos del trabajo obtenidos por contribuyentes entre 18 y 26 años",
					"titCredHab": "Reducción de las retenciones en origen para los titulares de contratos",
					"temRendResNaoHabit": "Residente no habitual - Rentas exentas del régimen fiscal durante 10 años"
				}
			},
			"contractualData": {
				"title": "Datos contractuales",
				"sections": {
					"timeAndPlaceOfWork": "Horas de trabajo y ubicación",
					"remuneration": "Remuneración",
					"calcPorRendLiq": "Cálculo por ingreso neto"
				}
			},
			"professionalData": {
				"title": "Detalles profesionales"
			},
			"subsidies": {
				"title": "Subvenciones y seguros",
				"sections": {
					"food": "Subsidio de comida",
					"vacations": "subsidio de vacaciones",
					"christmas": "subsidio de navidad",
					"workAccidentInsurance": "Seguro de accidentes de trabajo"
				}
			},
			"twelfths": {
				"title": "duodécimos",
				"sections": {
					"rulesProcessingTwelfths": "Reglas para el procesamiento de duodécimos."
				}
			},
			"integration": {
				"title": "Integración",
				"sections": {
					"integrationGeneralAccounting": "Integración en la contabilidad general",
					"integrationAnalyticalAccounting": "Integración en la contabilidad analítica"
				}
			},
			"otherData": {
				"title": "Otros datos",
				"sections": {
					"allowanceCodesEmployeeWageProcessing": "Códigos de asignación que se utilizarán en el procesamiento de salarios de los empleados",
					"discountCodeEmployeeWage": "Código de descuento para usar en el procesamiento de salarios de los empleados",
					"comments": "Comentarios"
				}
			}
		},
		"actions": {
			"calculateRemunHora": "Calcular el salario por hora de los empleados",
			"applySuggestedRemunHora": "Aplicar el salario por hora sugerido",
			"simula": "Simular",
			"selecaoTabelaIRS": "Sel."
		},
		"text": {
			"datasHistoricoDadosCaption": "Historial de registro",
			"datasHistoricoDadosCurrent": "en vigor",
			"fileNamePicture": "Foto_ {{codeEmp}}",
			"configurarOutrosDados1": "Parece que aún no has configurado los datos predeterminados en la ficha del empleado.",
			"configurarOutrosDados2": "hazlo ahora",
			"oldRecordWarning": "<strong>Atención:</strong> estás viendo un registro del historial de datos.",
			"total": "Total: {{value}} (pago por hora sugerido)",
			"configWSS": "Configuración del acceso al sistema webservice de la seguridad social",
			"percDescTaxaFixaIRSShowWarning": "Un \"{{description}}\" tiene una tarifa legal mínima de {{value}}.",
			"naoResidente": "No residente",
			"residenteNaoHabitual": "Residente no habitual"
		},
		"titles": {
			"abono": "{{designación}}: {{valor calculado}} = ({{valor}} × {{meses}}) / ({{horassemana}} × {{semanas}})",
			"abonoCruzamento": "{{designación}}: {{valor calculado}} = (({{valor}} × ({{porcentaje}} / 100) × {{meses}}) / ({{horasSemana}} × {{semanas}} )",
			"valorCalculado": "Valor calculado",
			"valorDesignacao": "Valor {{designación}}",
			"nDiuturnidades": "Número de períodos de antigüedad",
			"percentagem": "Porcentaje",
			"meses": "Meses",
			"horasSemana": "Número de horas por semana",
			"semanas": "Número de semanas por año"
		},
		"errors": {
			"invalidNumSegSoc": "Número de identificación del Seguro Social no válido",
			"invalidDtFimContrat": "La fecha de finalización del contrato es anterior a la fecha de inicio del contrato.",
			"invalidDTNascimento": "¡La fecha de nacimiento no es válida!"
		},
		"deletePrompt": {
			"title": "¿Eliminar colaborador {{id}}?",
			"message": "¿Cómo pretendes eliminar al colaborador?",
			"lastOnly": "Sólo el registro actual (el más reciente)",
			"all": "toda tu historia",
			"invalid": "Por favor seleccione cómo desea eliminar al colaborador"
		},
		"saveCadastro": {
			"title": "Guardar registro",
			"message": "Los datos de registro han sido modificados.",
			"newCadastro": "Crear nuevo registro en el registro",
			"updateLatestCadastro": "Actualizar el último registro en el registro.",
			"dataRegisto": "Selecciona la fecha de inscripción"
		},
		"prompt": {
			"exitMessage": "¿Está seguro de que desea salir sin guardar los datos modificados?",
			"inconsistenciasTabelaIRSNotifCloseMessage": "¿Quiere ocultar permanentemente la alerta de validación de la tabla del IRS a los empleados?"
		},
		"comments": {
			"modalTitle": "Mantenimiento de comentarios",
			"title": "Título",
			"text": "Texto",
			"user": "Usuario"
		},
		"modalSimularTaxaFixaIrs": {
			"modalTitle": "Simulador de tasa marginal del IRS",
			"taxaEfetiva": "Tarifa mensual efectiva",
			"infoModal": "En el cálculo se mostrará la tasa marginal, teniendo en cuenta los diferentes tipos de remuneración definidos para el empleado (salario, pagos de antigüedad, subsidio de alimentación, datos fijos).",
			"taxaMarginal": "tasa marginal",
			"semDados": "No hay datos para presentar",
			"infoCalculos": "Información de cálculos",
			"taxaNaoValida": "La tasa mensual efectiva debe estar entre 0% y 100%",
			"dataSimulacao": "Fecha de simulación"
		},
		"modalSelecaoTabelaIRS": {
			"modalTitle": "Selección de mesa del IRS",
			"fields": {
				"dataEmVigor": "Fecha de vigencia",
				"residencia": "Residencia",
				"situacao": "Situación",
				"tipoRendimento": "Tipo de rendimiento",
				"declaranteDeficiente": "Declarante incapacitado",
				"dependentes": "Dependientes",
				"dependentesDeficiente": "Dependientes discapacitados"
			},
			"groupSelection": {
				"title": "Selección de mesa del IRS"
			},
			"groupSelected": {
				"title": "Con los datos seleccionados, la tabla a aplicar es:"
			},
			"tabelaDesignacaoIRS": "Tabla: {{designación del IRS}}.",
			"tabelaNaoDeterminada": "Tabla del IRS no determinada.",
			"parcelaAbaterDepende": "Porción por dependiente: {{parcelaAbaterDepende}}."
		},
		"enumStr": {
			"rhLocalTributacaoRendimento": {
				"rhltrNone": "No aplicable",
				"rhltrContinente": "Continente",
				"rhltrRegiaoAcores": "Región de las Azores",
				"rhltrRegiaoMadeira": "Región de Madeira"
			},
			"rhNaturezaRendimento": {
				"rhnrNone": "No aplicable",
				"rhnrDependente": "Dependiente",
				"rhnrPensoes": "Pensiones"
			},
			"rhTipoDeficiencia": {
				"rhtdNone": "No aplicable",
				"rhtdDeficienciaGeral": "Discapacidad General",
				"rhtdDeficienciaForcasArmadas": "Fuerzas Armadas de Discapacidad"
			},
			"rhSituacaoCivilTitular": {
				"rhsctNone": "No aplicable",
				"rhsctNaoCasado": "No casado",
				"rhsctCasado1Titular": "Casado 1 Titular",
				"rhsctCasado2Titulares": "Casado 2 Titulares"
			},
			"rhDependentes": {
				"rhdSemDependentes": "Sin dependientes",
				"rhdComDependentes": "Con dependientes"
			}
		},
		"gridIRSDTEmVigor": {
			"fields": {
				"valorILIQ": "Hasta renum.",
				"taxaMarginalMaxima": "Tasa marginal máxima",
				"parcelaAbaterDepende": "Parte dependiente (€)",
				"txEfetivaMensalEscal": "Hay un límite de nivel",
				"parcelaAbaterFormulaInfo": "Parte a deducir (€)"
			}
		}
	},
	"impdadosemp": {
		"filtropesq": "Investigación",
		"previsualizar": "Avance",
		"deleteFoto": "eliminar foto",
		"fields": {
			"deCod": "código de empleado",
			"deNome": "Nombre de",
			"deIdade": "Edad de",
			"deSituacao": "Situación de",
			"deCategoria": "categoría de",
			"deDepartamento": "Departamento de",
			"deDtNasc": "Fecha de nacimiento",
			"deDtValCt": "Fecha de caducidad",
			"deDtValBI": "Fecha de caducidad"
		}
	},
	"diarios": {
		"title_detail": "Diario contable {{id}}",
		"title_new": "Nuevo diario contable",
		"title_plural": "Diarios contables",
		"pesquisa": "buscar",
		"saved": "Diario contable {{id}}, guardado exitosamente.",
		"error": "Diario contable {{id}}, no se puede guardar.",
		"deleted": "Diario contable {{id}}, eliminado correctamente.",
		"fields": {
			"nDiario": "Código.",
			"nDiarioPlaceholder": "Código de diario contable",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del diario contable",
			"formaIncrementoNDoc": "Formulario de incremento doc no.",
			"formaIncrementoNDocPlaceholder": "formulario de incremento",
			"aberturaContab": "Diario de apertura contable (no contabiliza IVA)",
			"aberturaContabPlaceHolder": "Diario de apertura contable"
		}
	},
	"distritos": {
		"title_detail": "Distrito {{id}}",
		"title_new": "Nuevo Distrito",
		"title_plural": "Distritos",
		"pesquisa": "buscar",
		"saved": "Distrito {{id}}, guardado exitosamente",
		"error": "Distrito {{id}}, no se puede guardar.",
		"deleted": "Distrito {{id}}, eliminado exitosamente",
		"fields": {
			"codDistrito": "código de distrito",
			"nomeDistrito": "Nombre del distrito",
			"localObtencaoRendimentos": "Lugar para obtener ingresos",
			"codContinente": "Continente",
			"codPais": "Código del país",
			"nomePais": "País"
		}
	},
	"divisoesTrabalho": {
		"title_detail": "División del Trabajo {{id}}",
		"title_new": "Nueva división del trabajo",
		"title_plural": "Divisiones del trabajo",
		"pesquisa": "buscar",
		"saved": "División del trabajo {{id}}, guardado exitosamente.",
		"error": "División del trabajo {{id}}, no se puede guardar.",
		"deleted": "División del Trabajo {{id}}, eliminada exitosamente.",
		"fields": {
			"nDivisao": "Código.",
			"designacaoBreve": "Breve designación",
			"designacaoCompleta": "Designación completa",
			"codDuracaoQPessoal": "Código.",
			"tipo": "tipo de tiempo"
		}
	},
	"divisTipo": {
		"0": "Desconocido",
		"1": "Tiempo completo",
		"2": "Tiempo parcial"
	},
	"dmrf": {
		"frestran": "Retenciones a no residentes",
		"edit": "Editar",
		"delete": "Para apagar",
		"newModalTitle": "Crear registro",
		"editModalTitle": "Editar registro",
		"summaryTitle": "Cómo completar el formulario de retención de impuestos del IRC/IRS",
		"fields": {
			"nContrib": "número de contribuyente",
			"nDocExterno": "Doc no.",
			"zona": "Zona",
			"rubrica": "Título",
			"retencao": "Retención"
		},
		"messages": {
			"contribrequired": "¡Debe ingresar un número de contribuyente!",
			"savedSuccess": "Registro guardado exitosamente",
			"promptDeleteRecord": "¿Está seguro de que desea eliminar el registro?",
			"promptDeleteDeclaracao": "¿Está seguro de que desea eliminar la declaración?",
			"erroExport": "Se produjo un error al exportar el archivo de declaración.",
			"promptSendMessage": "Esta operación registrará los datos y enviará la declaración de retención IRC/IRS en el portal de finanzas (AT).<br/>¿Estás seguro de que quieres continuar?",
			"sendSuccess": "El archivo de declaración fue enviado exitosamente.",
			"sending": "Enviando... por favor espere un momento.",
			"exporting": "Exportando... espere un momento.",
			"saving": "Guardando... por favor espera un momento.",
			"deleting": "Eliminando... por favor espere un momento.",
			"deletingRecord": "Eliminando registro... por favor espere un momento.",
			"valueRetNegative": "Valor de retención negativo.",
			"valueRetNull": "Valor de retención nulo."
		},
		"toolbar": {
			"deletePeriodo": "Cancelar declaración",
			"refresh": "Obtener datos",
			"save": "declaración récord",
			"export": "Generar archivo",
			"send": "Enviar declaración"
		},
		"rubrica": {
			"102": "102: IRS - Empresas y profesionales",
			"103": "103: IRS - Pensiones",
			"104": "104: IRS - Propiedad",
			"105": "105: IRS - Capital - Valores - Entidades emisoras",
			"106": "106: IRS - Capital - Valores - Entidades registrales, depositarias y otras",
			"107": "107: IRS - Capital - Intereses sobre depósitos a la vista o a plazo",
			"108": "108: IRS - Capital - Otros ingresos",
			"109": "109: IRS - Premios de juegos, loterías, rifas, apuestas mutuas, sorteos, concursos",
			"110": "110: IRS - Compensaciones y otros aumentos de activos",
			"112": "112: IRS - Recargo extraordinario",
			"114": "114: IRS - Recargo extraordinario - Cat. H",
			"115": "115: IRS - Otras rentas de capital sujetas a las tasas previstas en el artículo 71 del CIRS",
			"116": "116: IRS - Ingresos.",
			"117": "117: IRS - Ingresos.",
			"118": "118: SII - Capital-Títulos representativos de deuda (D.L. 193/2005 de 7 de noviembre)",
			"199": "199: IRS - Interés compensatorio",
			"201": "201: IRC - Edificios",
			"202": "202: IRC - Comisiones por intermediación en cualesquiera contratos y servicios prestados",
			"203": "203: IRC - Capital - Valores - Entidades emisoras",
			"204": "204: IRC - Capital - Valores - Entidades de registro o depósito",
			"205": "205: IRC - Capital - Intereses sobre depósitos corrientes o a plazo",
			"206": "206: IRC - Capitales - Otros ingresos",
			"207": "207: IRC - Premios de juegos, loterías, sorteos, apuestas mutuas, sorteos, concursos",
			"208": "208: IRC - Remuneraciones de los miembros de los órganos estatutarios de las Personas Jurídicas",
			"209": "209: IRC - Fondos de Inversión - Rentas.",
			"210": "210: IRC - Uni de Rescate.",
			"211": "211: IRC - Organización Inv. Colectiva-Reg.",
			"212": "212: IRC - Org. Inv. Colectivo duración determinada - Reg.",
			"213": "213: IRC - Org. Inv. Colectivo duración determinada - Reg.",
			"299": "299: IRC - Interés compensatorio",
			"000": ""
		},
		"zona": {
			"desconhecido": "Desconocido",
			"continente": "Continente",
			"madeira": "Madera",
			"acores": "Azores"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmación",
		"leavePromptMessage": "Al hacer clic en SÍ, saldrá y descartará todos los datos de procesamiento."
	},
	"docfas": {
		"title_detail": "Tipo de documento {{id}}",
		"title_new": "Nuevo tipo de documento",
		"title_plural": "Tipo de documento",
		"pesquisa": "buscar",
		"saved": "Tipo de documento {{id}}, guardado exitosamente",
		"error": "Tipo de documento {{id}}, no se puede guardar.",
		"documentoGlobal": "Documento global",
		"entregaEfectivaSobreDocumentoGlobal": "Entrega efectiva del documento global",
		"loginAT": "AT Webservice - Comunicación en serie",
		"accoes": "Comportamiento",
		"autofaturacaoComAcordo": "Autofacturación para proveedores con Convenio",
		"autofaturacaoSemAcordo": "Autofacturación para proveedores sin Convenio",
		"fields": {
			"nDocFa": "Número",
			"nome": "Nombre",
			"descricao": "Descripción",
			"codigoSAFTTipoDoc": "SAFT - tipo de documento",
			"identificaCarga": "Identifica carga",
			"faturaComIvaIncluido": "Factura con IVA incluido",
			"integraStocks": "Integra acciones",
			"integraNaContabilidade": "Integrar",
			"integraEmContasCorrentes": "Se integra en cuentas corrientes",
			"reportDefeito": "Informe predeterminado",
			"tipoMovimentoStock": "Movimiento de acciones",
			"grupoDocfa": "Grupo",
			"fluxoMonetario": "flujo de dinero",
			"nDocfaDestino": "Tipo de documento.",
			"faturacaoPublica": "Facturación pública",
			"obrigatorioMeiosPagam": "Utilice métodos de pago",
			"descricaoSAFTTipoDoc": "Descripción SAFT - Tipo de documento.",
			"codigoSAFTTipoDocDescStr": "SAFT - Tipo de documento",
			"entregaEfetivaSobreDocGlobal": "Entrega efectiva en documento global",
			"sujeitoAHASHStr": "Firma",
			"cae": "CAE",
			"nViasDefeito": "Número predeterminado de copias",
			"tipoMovimento": "tipo de movimiento",
			"movimentaStock": "Movimiento de acciones",
			"diario": "A diario",
			"descriti": "Descriptivo"
		},
		"list": {
			"actions": {
				"new": "Nuevo basado en esto"
			}
		},
		"captions": {
			"definicoes": "Configuración empresarial y de integración",
			"numeracoes": "Serie",
			"dadosAT": "En datos",
			"reports": "Informes",
			"dadosGerais": "Datos Generales",
			"integraContabilidade": "Integración contable"
		},
		"warnings": {
			"canceladaNovaSerie": "Creación de la nueva serie cancelada",
			"novaserie": "Nueva serie",
			"addnovaserie": "Agregar nueva serie",
			"novaSerieAdd": "Nueva serie agregada exitosamente",
			"temAlteracoes": "Hay cambios aún no guardados."
		},
		"errors": {
			"tipoDocNotExist": "El tipo de documento no existe",
			"erroNovaSerie": "Error al crear nueva serie",
			"autofaturacaoNome": "El tipo de documento es el de autofacturación, lo que requiere que en la denominación se incluya el término “autofacturación o autofacturación”.",
			"soPodeSelecionarTpDocQuePertencemAoGrupo": "Sólo puede seleccionar tipos de documentos que pertenecen al grupo",
			"autoFaturacaoInvalido": "El tipo de documentos pertenece al Grupo, pero tiene un campo \"autoFaturacao\" no válido."
		},
		"tooltip": {
			"cae": "Campo no requerido."
		},
		"messages": {
			"semMovimentoStock": "Sin movimiento",
			"entradaStock": "Entrada de acciones",
			"saidaStock": "Agotado"
		},
		"modal": {
			"new": {
				"cardBase": "Base para el nuevo tipo de documento",
				"cardNew": "Nuevo tipo de documento",
				"title": "Asistente de creación de nuevo tipo de documento",
				"nDocfaBase": "documento base",
				"nDocFaPlaceHolder": "Si no se completa, genera automáticamente",
				"saftTipoDoc": "SAFT - Tipo de documento",
				"fatura": "Factura",
				"faturaRecibo": "Recibo de factura"
			}
		},
		"modals": {
			"comunicacaoSeriesAT": "Comunicación serie a AT"
		},
		"reports": {
			"fields": {
				"visible": "Disponible",
				"defaultReport": "Por defecto",
				"nome": "Nombre",
				"descricao": "Descripción",
				"tipoDescricao": "Tipo"
			},
			"descricao": {
				"system": "Sistema",
				"user": "Usuario",
				"userEmpresa": "Usuario/Empresa"
			}
		}
	},
	"docfasNums": {
		"title_detail": "Serie Id. del tipo de documento {{id}}",
		"title_new": "Nueva serie de tipos de documentos",
		"title_plural": "Serie de tipo de documento",
		"pesquisa": "buscar",
		"saved": "Tipo de documento Serie {{id}}, guardado exitosamente",
		"error": "Tipo de documento Serie {{id}}, no se puede guardar.",
		"fields": {
			"idDocfaNum": "ID de serie",
			"nDocfa": "Tipo de documento",
			"nNumer": "Número de serie",
			"nNumerPlaceholder": "El número de serie, si no se completa, se generará automáticamente.",
			"num": "Doc no.",
			"descNumer": "Descripción",
			"numDraft": "Doc no.",
			"nConta": "Nro. de cuenta",
			"encerrado": "Cerrado",
			"atcud": "AT - Código de validación",
			"visivelERPCloud": "Nube visible",
			"codLocalEmissao": "Lugar de emisión",
			"atEstadoSerieDescricao": "Estado en AT",
			"paraTodosDoGrupo": "Crear para todos los tipos de documentos en el grupo {{GroupName}}"
		},
		"auxiliares": {
			"novaserie": "Nueva serie",
			"addnovaserie": "Agregar nueva serie",
			"novaSerieAdd": "Nueva serie agregada exitosamente"
		},
		"errors": {
			"SerietipoDocNotExist": "La serie de tipo de documento no existe",
			"serieNaoPodeSerNula": "La serie de tipo de documento no puede ser nula",
			"seriesTipoDocumentoNaoDaParaComunicarAT": "El tipo de documento no está sujeto a firma y por tanto no puede comunicarse a AT",
			"seriesTipoDocumentoNaoDaParaVerLogAT": "El tipo de documento no está sujeto a firma y por lo tanto no tiene registro de comunicaciones al AT",
			"seriesTipoDocumentoNaoDaParaConsultarComunicacaoAT": "El tipo de documento no está sujeto a firma, por lo que no es posible consultar la comunicación a AT",
			"atAFEnquadramentoRequired": "El encuadre es obligatorio.",
			"nContaRequired": "El número de cuenta es obligatorio."
		},
		"btn": {
			"comunicarSeries": "Comunicar serie de facturación a AT",
			"logSeries": "LOG de comunicación de serie a AT",
			"consultarSeries": "Consultar serie sobre AT"
		},
		"modal": {
			"comunicacao": {
				"title": "Comunicación de series de facturación a AT",
				"selecionar": "Seleccionar",
				"enquadramentoDesperdicios": "Línea i) del párrafo 1 del artículo 2 del CIVA - Sector de desechos, desperdicios y chatarra reciclable",
				"enquadramentoCortica": "Línea m) del párrafo 1 del artículo 2 del CIVA - Compradores de corcho, madera, piñas y piñones con cáscara",
				"atSeriesLinkTooltip": "Registro de series en el portal financiero.",
				"fields": {
					"serie": "Serie AT",
					"tipoSerie": "Tipo de serie",
					"classeDoc": "Clase de documento",
					"tipoDoc": "Tipo de documento",
					"numInicialSeq": "Inicio de secuencia",
					"dataInicioPrevUtiliz": "Inicio de uso previsto",
					"seqUltimoDocEmitido": "Último doctor.",
					"codValidacaoSerie": "Código de validación (ATCUD)",
					"estado": "Estado de la serie",
					"meioProcessamento": "Medio de procesamiento",
					"numCertificado": "Número de certificado",
					"nifFornecedor": "TIN del proveedor",
					"enquadramento": "Estructura"
				},
				"btn": {
					"finalizar": "Fin de serie",
					"registarWebService": "Comunicarse a través del servicio web",
					"registarManual": "Comunicarse manualmente"
				},
				"finalizar": {
					"title": "Finalizar la comunicación de la serie.",
					"justificacao": "Justificación",
					"erroJustificacaoVazia": "La justificación no puede ser nula."
				},
				"autoFaturacaoComAcordoMessage1": "Serie de autofacturación con convenio",
				"autoFaturacaoComAcordoMessage2": "Para activar una serie de autofacturación con contrato por cuenta de tu proveedor nacional o extranjero, primero debes hacerlo en el portal de finanzas en el área correspondiente, introduciendo los datos que te presentamos.",
				"autoFaturacaoComAcordoMessage3": "Tras registrarse en el portal, deberá introducir en este formulario el ATCUD y NIF del proveedor, y realizar el registro.",
				"autoFaturacaoSemAcordoMessage1": "Serie de autofacturación sin acuerdo",
				"autoFaturacaoSemAcordoMessage2": "Para activar una serie de autofacturación sin contrato por parte de tu proveedor según el marco previsto, primero deberás hacerlo en el portal financiero en el área correspondiente, introduciendo los datos que te presentamos.",
				"autoFaturacaoSemAcordoMessage3": "Luego de registrarse en el portal, deberá ingresar a la ATCUD y al marco en este formulario, y realizar la grabación."
			},
			"consulta": {
				"fields": {
					"serie": "Serie",
					"tipoSerie": "Tipo de serie",
					"classeDoc": "Clase de doctorado.",
					"tipoDoc": "Tipo de documento.",
					"numInicialSeq": "numero inicial",
					"dataInicioPrevUtiliz": "Fecha de inicio",
					"seqUltimoDocEmitido": "Último no.",
					"meioProcessamento": "Medio de procesamiento",
					"numCertSWFatur": "Certificado SW No.",
					"codValidacaoSerie": "Código de validación (ATCUD)",
					"dataRegisto": "Fecha de registro",
					"estado": "Estado",
					"dataEstado": "Fecha de estado",
					"nifComunicou": "comunicación NIF"
				}
			},
			"logs": {
				"title": "",
				"fields": {
					"atDataInicioPrev": "Fecha/Hora",
					"operacaoSTR": "Operación",
					"respostaCodigo": "Código de respuesta",
					"respostaMensagem": "Mensaje de respuesta",
					"codValidacaoSerie": "Código de validación de serie",
					"atTipoSerie": "Tipo de serie",
					"atTipoSerieDescricao": "Tipo de serie (descripción)",
					"atClasseDoc": "Clase de doctorado.",
					"atClasseDocDescricao": "Clase de doctorado.",
					"atTipoDocSTR": "Tipo de documento.",
					"atEstadoSerie": "Serie estatal",
					"atEstadoSerieDescricao": "Estado de la serie (descripción)"
				}
			},
			"seriesPorComunicarAT": {
				"title": "Comunicación de series documentales a AT",
				"fields": {
					"nDocfa": "Tipo de documento.",
					"nNumer": "Número de serie",
					"nome": "Nombre del tipo de documento.",
					"tipoDocSaft": "tipo SAFT",
					"numAtual": "Numeración actual",
					"numInicial": "Numeración inicial",
					"dataInicio": "Fecha de inicio"
				},
				"btn": {
					"comunicarseriesAT": "Se comunica vía servicio web"
				}
			}
		}
	},
	"docfasnumscomuncicacaologin": {
		"title": "Comunicación de la serie de inicio de sesión",
		"saveTitle": "Ahorrar",
		"fields": {
			"username": "Nombre de usuario (NIF/ID de usuario)",
			"password": "Contraseña"
		},
		"messages": {
			"success": "Inicie sesión exitosamente.",
			"errorUsernameEmpty": "Nombre de usuario vacío.",
			"errorPasswordEmpty": "Contraseña vacía.",
			"noPermissions": "Advertencia: ¡Para cambiar valores debes tener permisos de administrador!"
		}
	},
	"configNomeExportFile": {
		"caption": {
			"filesType": "Tipo de configuración",
			"nDocFa": "Tipo de documento",
			"groupConfigurador": "Configurador",
			"fieldType": "Tipo de campo",
			"valor": "Valor",
			"groupConfigs": "Configuración de nombre de archivo",
			"mascaraLabel": "Mascarilla"
		},
		"grids": {
			"ordem": "Orden",
			"tipo": "Tipo",
			"valor": "Valor",
			"doc": "Documento"
		},
		"apagarModal": {
			"title": "Borrar configuración",
			"message": "¿Está seguro de que desea eliminar la configuración \"{{configuracao}}\"?"
		},
		"messages": {
			"erroMaskLength": "No hay ninguna máscara configurada.",
			"erroNDocFa": "Debe seleccionar un tipo de documento.",
			"erroFieldType": "Debe seleccionar un tipo de campo.",
			"erroValorNulo": "El valor no puede ser nulo.",
			"erroValorInvalido": "El valor tiene caracteres no válidos.",
			"deleteSucesso": "La configuración se eliminó correctamente."
		}
	},
	"docsComerciaisEstatisticas": {
		"title_plural": "Declaraciones de ventas",
		"pesquisa": "buscar",
		"verMaisFiltros": "Ver más filtros",
		"verMaisFiltrosHide": "Ocultar filtros",
		"tabs": {
			"cubo": "Cubo"
		},
		"fields": {
			"nDocStr": "Doc.",
			"nArtigo": "Artículo",
			"artigo": "Artículo",
			"nArtigoPivot": "Artículo no.",
			"nomeArtigo": "Nombre del artículo",
			"nConta": "Nro. de cuenta",
			"contaNome": "Nombre",
			"nif": "nif",
			"dataDoc": "Fecha del doctor.",
			"liquido": "Líquido",
			"valorIva": "valor del IVA",
			"sinal": "Señal",
			"liquidoMovimento": "Movimiento líquido.",
			"valorIvaMovimento": "IVA muebles.",
			"totalMovimento": "movimiento total",
			"qtd1": "Cant.",
			"prVendaQtd1": "Precio unitario.",
			"d1": "Desc.",
			"totalDocumento": "Documento total.",
			"totalLiquido": "Total neto",
			"totalIliquido": "Totalmente ilíquido.",
			"totalIva": "IVA total",
			"totalDesconto": "Descuento total.",
			"taxaRet": "Tasa de retención",
			"retencaoIncidencia": "Incidencia rec.",
			"retencaoValor": "Valor correcto.",
			"nFactFornec": "Documento externo.",
			"dataDocExterno": "Fecha del doctor.",
			"nVendedor": "código de vendedor",
			"nomeVendedor": "Vendedor",
			"nDocfa": "Código de tipo de documento.",
			"docfaNome": "Tipo de documento",
			"nnumer": "Serie documental.",
			"nDepart": "código gr.",
			"nomeDepart": "gran familia",
			"nFamil": "código familiar",
			"nomeFamilia": "Familia",
			"nSubfa": "Código de subfamilia",
			"nomeSubFamilia": "subfamilia",
			"tipoArtigo": "Código de tipo de artículo",
			"nomeTipoArtigo": "Tipo de artículo",
			"nCCusto": "Código C. Costo",
			"centroCusto": "centro de costos",
			"nRefProcesso": "código de proceso",
			"nomeProcesso": "Proceso",
			"totalDocumentoMovimento": "Documento total.",
			"totalLiquidoMovimento": "Total neto",
			"totalIliquidoMovimento": "Totalmente ilíquido.",
			"totalIvaMovimento": "IVA móvil total.",
			"totalDescontoMovimento": "Descuento total.",
			"valorEmAberto": "Valor abierto",
			"estado": "Estado",
			"sectorId": "Código de sector",
			"nomeSector": "Sector"
		},
		"caption": {
			"ncontade": "Número de cuenta",
			"nartigode": "Código del artículo",
			"tipoartigode": "tipo de artículo",
			"ngrfamiliade": "gran familia de",
			"nfamiliade": "familia de",
			"nsubfamiliade": "Subfamilia de",
			"classede": "Clase de",
			"categoriade": "categoría de",
			"datade": "Fecha del doctor.",
			"nvendedorde": "vendedor",
			"codzonade": "Zona",
			"ccustode": "C. Costo de",
			"nrefprocessode": "Número de proceso",
			"artigoCaption": "Artículo",
			"clienteCaption": "Cliente",
			"docCaption": "Documento",
			"socommovimstock": "Sólo artículos que mueven stock",
			"docFa": "tipos de documentos",
			"obter": "para obtener",
			"printModalTitle": "Estadísticas de gestión comercial",
			"ididechavde": "grupo de cuentas",
			"cliclde": "Clase de"
		},
		"info": {
			"erroDataDeMaiorQueAte": "La fecha Desde es mayor que la fecha Hasta",
			"docPago": "Pagado",
			"docPagoParcial": "Parcialmente pagado",
			"docPorPagar": "No pagado"
		},
		"configs": {
			"showFilterTipoArtigo": "Mostrar filtro por tipo de artículo",
			"showFilterGrFamilia": "Mostrar filtro por familia numerosa",
			"showFilterFamilia": "Mostrar filtro por familia",
			"showFilterSubFamilia": "Mostrar filtro por subfamilia",
			"showFilterClasseArtigo": "Mostrar filtro por clase de artículo",
			"showFilterCategoriaArtigo": "Mostrar filtro por categoría de artículo",
			"showFilterSoComMovimStock": "Mostrar filtrar solo artículos que mueven stock",
			"showFilterConta": "Mostrar filtro por cuenta",
			"showFilterVendedor": "Mostrar filtro por vendedor",
			"showFilterZona": "Mostrar filtro por zona",
			"showFilterCentroCusto": "Mostrar filtro por centro de coste",
			"showFilterProcesso": "Mostrar filtro por proceso",
			"showFilterDocfaList": "Mostrar filtro por tipo de documento",
			"showFilterNArtigo": "Mostrar filtro para artículo",
			"showFilterClasse": "Mostrar filtro por clase",
			"showFilterGrupoConta": "Mostrar filtro por grupo de cuentas"
		},
		"errors": {
			"naoExisteTipoDocSelecionado": "No hay ningún tipo de documento seleccionado.",
			"naoExisteTipoDocSelecionadoCheckOpcoes": "No hay ningún tipo de documento seleccionado."
		}
	},
	"docsPartilhados": {
		"title_detail": "Documento de plataforma de recursos humanos {{id}}",
		"title_new": "Nuevo documento de la plataforma de RR.HH.",
		"title_plural": "Documentos de la plataforma de recursos humanos",
		"pesquisa": "buscar",
		"saved": "Documento de plataforma de recursos humanos {{id}}, guardado exitosamente",
		"error": "El documento de la plataforma de recursos humanos {{id}} no se puede guardar.",
		"fields": {
			"cab": {
				"docId": "Identificación.",
				"nome": "Nombre",
				"horaCriacaoReg": "Tiempo",
				"visualizado": "Visto",
				"tamanho": "Tamaño",
				"extensao": "Extensión"
			}
		},
		"docs": {
			"title": "Documento"
		},
		"uploadmodal": {
			"titlenew": "Enviar archivos al contador",
			"dataRef": "Fecha de referencia del archivo"
		},
		"info": {
			"apagardocumentos": "Eliminar documentos",
			"temCertezaApagarDocumentos": "¿Está seguro de que desea eliminar los documentos seleccionados?",
			"emailParaGabSucess": "Correo electrónico enviado a la oficina con éxito",
			"fileUploadSuccess": "Archivo cargado exitosamente"
		},
		"toolbar": {
			"year": "Año: {{año}}",
			"month": "Mes: {{mes}}"
		},
		"btn": {
			"enviarFicheiros": "enviar archivos"
		}
	},
	"guias": {
		"title_detail": "Tabulación {{id}}",
		"title_new": "Nueva pestaña",
		"title_plural": "Guías",
		"saved": "Guía {{id}} guardada correctamente.",
		"error": "La Guía no se puede guardar.",
		"toolbar": {
			"btnConfigAt": ""
		}
	},
	"vendas": {
		"title_detail": "Vender {{id}}",
		"title_new": "Nueva venta",
		"title_plural": "Ventas",
		"saved": "Vender {{id}} guardado correctamente.",
		"error": "La Venta no se puede guardar."
	},
	"compras": {
		"title_detail": "Comprar {{id}}",
		"title_new": "Nueva compra",
		"title_plural": "Compras",
		"saved": "Compra {{id}} guardada correctamente.",
		"error": "La compra no se puede guardar."
	},
	"entradasdiversas": {
		"title_detail": "Entrada miscelánea {{id}}",
		"title_new": "Nueva entrada miscelánea",
		"title_plural": "Entradas varias",
		"saved": "Entrada miscelánea {{id}} guardada correctamente.",
		"error": "La entrada miscelánea no se puede guardar."
	},
	"saidasdiversas": {
		"title_detail": "Salida miscelánea {{id}}",
		"title_new": "Nueva producción diversa",
		"title_plural": "Salidas varias",
		"saved": "Varios Salida {{id}} guardada correctamente.",
		"error": "Los resultados varios no se pueden guardar."
	},
	"propostasaclientes": {
		"title_detail": "Propuesta/presupuesto {{id}}",
		"title_new": "Nueva propuesta/presupuesto",
		"title_plural": "Propuestas a Clientes",
		"saved": "La propuesta al Cliente {{id}} se guardó correctamente.",
		"error": "La propuesta del cliente no se puede guardar."
	},
	"encomendasclientes": {
		"title_detail": "Pedido del cliente {{id}}",
		"title_new": "Nuevo pedido de cliente",
		"title_plural": "Pedidos de clientes",
		"saved": "El pedido del cliente {{id}} se guardó correctamente.",
		"error": "El pedido del cliente no se puede guardar."
	},
	"encomendasfornecedores": {
		"title_detail": "Proveedor del pedido {{id}}",
		"title_new": "Nuevo pedido de proveedor",
		"title_plural": "Pedidos Proveedores",
		"saved": "Proveedor del pedido {{id}} guardado correctamente.",
		"error": "El pedido del proveedor no se puede guardar."
	},
	"guiasfornecedores": {
		"title_detail": "Tabulación {{id}}",
		"title_new": "Nueva pestaña",
		"title_plural": "Guías",
		"saved": "Guía {{id}} guardada correctamente.",
		"error": "La Guía no se puede guardar.",
		"toolbar": {
			"btnConfigAt": "AT iniciar sesión para la comunicación de documentos de transporte"
		}
	},
	"comprasautofaturacao": {
		"title_detail": "Documento de autofacturación {{id}}",
		"title_new": "Nuevo doctor.",
		"title_plural": "Autofacturación",
		"saved": "El documento de autofacturación {{id}} se guardó correctamente.",
		"error": "El doctor."
	},
	"consultasafornecedores": {
		"title_detail": "Presupuesto {{id}}",
		"title_new": "Nuevo presupuesto",
		"title_plural": "Presupuestos",
		"saved": "Presupuesto {{id}} guardado correctamente.",
		"error": "El Presupuesto no se puede salvar."
	},
	"docscomerciais": {
		"addLine": "Agregar línea",
		"title_detail": "Documento {{id}}",
		"title_new": "Nuevo documento",
		"title_plural": "Documentos Comerciales",
		"pesquisa": "buscar",
		"saved": "Documento Comercial {{id}} guardado exitosamente.",
		"error": "El Documento Comercial no se puede guardar.",
		"fields": {
			"nDocFa": "Tipo de documento.",
			"nNumer": "Serie",
			"nDocumento": "Número de documento",
			"nDocumentoDraft": "Número de documento borrador",
			"nDoc": "Doc No.",
			"nDocFilter": "Número de documento (Ej.: 1.23.10)",
			"nome": {
				"clifos": "Nombre",
				"clientes": "Cliente",
				"fornecedores": "Proveedor"
			},
			"nConta": "Cuenta",
			"nif": "Contribuyente",
			"estado": "Estado",
			"porPagar": "No pagado",
			"porReceber": "para recibir",
			"stampUpdate": "Último.",
			"modoEnvio": "Modo de envío",
			"estadoEnvioStr": "Estado de envío",
			"nomeMoeda": "Acuñar",
			"transformado": "transformado",
			"nFactFornec": "Documento externo.",
			"dataDocExterno": "Fecha del doctor.",
			"observacoes": "Observaciones",
			"anulado": "anulado",
			"dataVenc": "Fecha de expiración",
			"textoLivre": "texto libre"
		},
		"toolbar": {
			"importDoc": "Documento de importación",
			"meiosPagamento": "Métodos de pago",
			"calcular": "Calcular",
			"arredondamentoManual": "Redondeo manual",
			"alterarCamposSerie": "Cambiar campos en serie",
			"rastreabilidade": "Trazabilidad",
			"preView": "Vista previa/borrador",
			"salvageLastDoc": "Recuperar el último documento",
			"guardar": "Ahorrar",
			"guardarRascunho": "Guardar borrador",
			"finalizarDocumento": "Finalizar documento",
			"transformacaoDocumentos": "Transformación de documentos",
			"arquivoDigital": "Archivo digital",
			"configNameFileExport": "Nombre de los archivos a exportar",
			"faturacaoPublica": "EDI/Factura Electrónica Pública"
		},
		"doc": {
			"cab": {
				"nDocFa": "Tipo de documento.",
				"nNumer": "Serie",
				"dataDoc": "Fecha del doctor.",
				"nDocumento": "Número de documento",
				"nDocumentoDraft": "Doc no.",
				"nome": "Nombre",
				"totalLiquido": "Incidencia",
				"totalIva": "TINA",
				"totalDesconto": "Descuentos",
				"totalIliquido": "Total parcial",
				"taxaRet": "Tasa",
				"retencaoIncidencia": "Incidencia",
				"retencaoValor": "Valor",
				"observacoes": "Observaciones",
				"nFactFornec": "Documento externo.",
				"dataDocExterno": "Fecha del doctor.",
				"dataInicTran": "Fecha de inicio del transporte",
				"horaInicTran": "Hora de inicio del transporte",
				"estadoEnvioStr": "Estado de envío",
				"atDocCodeID": "Código AT",
				"nCCusto": "Centro de costos no.",
				"dataVenc": "Fecha de vencimiento",
				"codMoeda": "Código de moneda",
				"nomeMoeda": "Acuñar",
				"cambio": "Intercambio",
				"nRefProcesso": "Proceso",
				"comunicadoAoEFatura": "Estado de envío",
				"tipoComunicacao": "Modo de envío",
				"ctRefExterna": "Contrato",
				"acordoQuadro": "Acuerdo marco",
				"compromisso": "Compromiso",
				"nRequisicao": "Número de solicitud",
				"nDocGlobalOrigem": "Documento mundial.",
				"nCondPagam": "Condiciones de pago"
			},
			"linhas": {
				"nArtigo": "Artículo",
				"nome": "Nombre",
				"qtd": "Cant.",
				"qtdStock": "Cantidad de existencias",
				"preco": "Precio",
				"codIva": "código de IVA",
				"taxaIva": "% IVA",
				"desconto": "Descuento %",
				"d1": "Desc.",
				"d2": "Desc.",
				"d3": "Desc.",
				"d4": "Desc.",
				"d5": "Desc.",
				"d6": "Desc.",
				"valorComIva": "Valor incluido IVA",
				"nCCusto": "C. Costo",
				"valorIva": "valor del IVA",
				"nRefProcesso": "Proceso",
				"nArmazem": "Almacenamiento",
				"nVendedor": "Vendedor",
				"codUnidMov": "Elementos.",
				"valorSemIVA": "Valor sin IVA",
				"unidMovAbrev": "Elementos.",
				"liquido": "Líquido",
				"descEmValor": "Desc.",
				"dataEntregaPrev": "Fecha de entrega prevista",
				"nSeq": "Sec."
			},
			"tabela": {
				"tooltipCellNomeArtigo": "Agregar descripción"
			},
			"iva": {
				"taxa": "Tasa",
				"incidencia": "Incidencia",
				"valorIva": "valor del IVA"
			},
			"clifo": {
				"nome": "Nombre",
				"nContribuint": "NIF",
				"rua": "Camino",
				"codPostal": "Código Postal",
				"localidade": "Ubicación",
				"telefone": "Teléfono",
				"email": "Correo electrónico"
			},
			"moradaCarga": {
				"rua": "Camino",
				"codPostal": "Código Postal",
				"localidade": "Ubicación",
				"observacoes": "Nota.",
				"morada": "Calle (Continuación)",
				"codPais": "Código del país"
			},
			"moradaDescarga": {
				"rua": "Camino",
				"codPostal": "Código Postal",
				"localidade": "Ubicación",
				"observacoes": "Nota.",
				"morada": "Calle (Continuación)",
				"codPais": "Código del país",
				"moralId": "Dirección alternativa"
			},
			"transportador": {
				"nGuiaTransporte": "Guía de transporte nº.",
				"dataGuiaTransporte": "fecha guía",
				"matricula": "Registro",
				"matriculaReboque": "Registro de remolque",
				"nomeTransportador": "Nombre del transportista",
				"nomeViatura": "Nombre del vehículo",
				"nomeMotorista": "Nombre del conductor",
				"moradaMotorista": "Dirección del conductor",
				"codPostalMotorista": "Código postal del conductor",
				"codPaisMotorista": "Código de país del conductor"
			},
			"novo": {
				"cliente": "Cliente",
				"observacoes": "Observaciones",
				"docExterno": "Documento externo."
			}
		},
		"info": {
			"comunicacaoFaturaAT": "Factura comunicada a AT exitosamente.",
			"comunicacaoDocTransporteAT": "Documento de transporte comunicado con éxito a AT.",
			"inserirCodComunicacaoAT": "Código telefónico ingresado exitosamente.",
			"anularDocumento": "Documento cancelado exitosamente.",
			"notaCreditoSucesso": "Devolución realizada con éxito.",
			"comunicadoAoEFatura": "Documento comunicado a AT, no se permite cancelación mediante Devolución",
			"comunicadoAT": "declaración en",
			"transformarDocumento": "Documento transformado exitosamente.",
			"duplicarDocumento": "Documento duplicado exitosamente.",
			"copiarLinhas": "Las líneas del documento se copiaron correctamente.",
			"meiosPagamentoVazios": "Valor del documento no asignado a los métodos de pago",
			"meiosPagamentoOk": "Métodos de pago asignados",
			"encerrarDocumento": "Documento completado exitosamente",
			"desmarcadoDocTransformado": "Documento marcado como sin transformar",
			"transformarLinhasDocumento": "Las líneas del documento se transformaron correctamente."
		},
		"erros": {
			"comunicacaoAT": "Error al comunicarse con AT.",
			"notselected": "No se seleccionaron documentos.",
			"emptycodAT": "Código AT vacío.",
			"anularDocumento": "Error al cancelar el documento.",
			"emptyMotivo": "Motivo de cancelación vacío.",
			"inserirCodComunicacaoAT": "Error al ingresar el código AT.",
			"notaCreditoErro": "Error al realizar una devolución.",
			"emptyMotivoNotaCredito": "Motivo de la devolución vacío.",
			"anulacaoFisica": "Cancelación física",
			"transformarDocumento": "Error al transformar el documento.",
			"docJaTransformado": "El documento ya ha sido transformado.",
			"docAnulado": "El documento está cancelado.",
			"naoCriouPastaDoc": "Error al crear la carpeta de documentos en la carpeta de gestión comercial",
			"anexarGDoc": "Error al adjuntar archivos",
			"duplicarDocumentoErro": "Error al duplicar el documento",
			"copiarLinhas": "Error al copiar líneas",
			"duplicarDocumentoNDocFaNulo": "El tipo de documento no puede ser nulo",
			"duplicarDocumentoNContaNulo": "El número de cuenta no puede ser nulo.",
			"docSemLinhas": "El documento no tiene líneas.",
			"docSemValorLinhasMP": "El documento no tiene valor para distribuir a través de métodos de pago.",
			"meiosPagam": "Error al mostrar los métodos de pago",
			"editarDocumento": "Error al editar el documento",
			"encerrarDocumento": "Error al finalizar el documento",
			"desmarcadoDocTransformado": "Error al marcar el documento como no transformado",
			"moralInvalidSelected": "No ha seleccionado una dirección alternativa existente.",
			"tipoDocNaoIntegraStock": "El tipo de documento no está incluido en existencias.",
			"noSelArtigos": "Debes seleccionar al menos un elemento.",
			"notaCreditoSemCliente": "Se requiere un {{clip}} para hacer una nota de crédito.",
			"reAbreDocumento": "Error al volver a abrir el documento",
			"anularDocumentoComNotaCredito": "No se puede cancelar un documento, ya que existe un documento rectificativo."
		},
		"auxiliares": {
			"motivoNotaCredito": "Motivo de la devolución",
			"motivo": "Razón",
			"motivoTitle": "Motivo de la cancelación",
			"anular": "Cancelar",
			"anexarDocumento": "Adjuntar documento",
			"duplicarDocumento": "Documento duplicado",
			"copiarLinhasDocumento": "Copiar líneas",
			"meiosPagamento": "Métodos de pago",
			"documento": "Documento",
			"copiaDocumentoLinhas": "Copia del documento/líneas",
			"importarLinhasTemplate": "Importar líneas – Plantilla",
			"calcular": "Calcular",
			"arredondamentoManual": "Redondeo manual",
			"alterarCamposSerie": "Cambiar campos en serie",
			"rastreabilidade": "Trazabilidad",
			"gestaoEncomendas": "Pedidos pendientes",
			"selecionarDocumento": "Seleccionar documento",
			"saldoEmAberto": "Saldo abierto",
			"anularDocumento": "Cancelar documento",
			"tooltips": {
				"addTextoLivre": "Añadir texto libre",
				"copiaDocumentoLinhasTooltip": "Le permite duplicar un documento o copiar solo las líneas.",
				"importarLinhasTemplateTooltip": "Le permite importar una plantilla preparada para una hoja de cálculo.",
				"pesquisaDocsGlobais": "Búsqueda global de documentos",
				"limparCampo": "Borrar campo",
				"obterUltimoDocumentoGlobalCriado": "Obtener el último documento global creado",
				"atualizarCambio": "Actualizar tipo de cambio para la fecha del documento",
				"comprasDataDoc": "Fecha de alta en el sistema.",
				"comprasNDocExterno": "Referencia externa impresa en el documento.",
				"comprasDataDocExterno": "Fecha externa impresa en el Documento"
			},
			"recibosPagamRelComDocSeraoAnulados": "También se cancelarán los recibos/pagos relacionados con el documento a cancelar.",
			"recibosPagamRelComDocFinalizadoSeraoAnulados": "Se cancelarán los recibos/pagos relacionados con el documento final a editar.",
			"nDocAsString": "Número de documento",
			"docFinalizadoApenasEditarCamposNaoComunicEFatura": "El documento se encuentra en estado Finalizado, sólo se permite editar campos no comunicados en eFatura."
		},
		"codATPerPhone": "EN comunicación de documentos",
		"markAsCompleted": "Marcar como \"Completado\"",
		"configs": {
			"showObservacoes": "Mostrar observaciones",
			"showDocExterno": "Mostrar documento.",
			"showDataDoc": "Mostrar fecha doc.",
			"showDataDocExterno": "Mostrar fecha doc.",
			"showDataVenc": "Mostrar fecha de vencimiento",
			"showCCusto": "Mostrar centro de costos",
			"showMoeda": "Mostrar moneda",
			"showNRefProcesso": "Mostrar proceso",
			"showDescontos": "Mostrar columnas de descuento",
			"showNDoc": "Mostrar número de documento",
			"showNArmazem": "Mostrar almacén",
			"showCodUnidMov": "Mostrar unidad de movimiento",
			"showValorCIva": "Mostrar valor con IVA incluido",
			"showValorSIva": "Mostrar valor sin IVA",
			"showNVendedor": "Mostrar vendedor",
			"showNNumer": "Mostrar serie",
			"showNRequisicao": "Mostrar número de solicitud",
			"showLiquido": "Mostrar líquido",
			"showNDocExterno": "Mostrar el documento no.",
			"showD2": "Mostrar descuento 2",
			"showD3": "Mostrar descuento 3",
			"showDescEmValor": "Mostrar descripción.",
			"showDiferimentos": "Mostrar aplazamientos",
			"showPerguntaSeFinalizaDoc": "Mostrar confirmación de finalización",
			"obtemDadosDocDigital": "Obtiene datos del documento digital."
		},
		"actions": {
			"emitirRecibo": "Recibo de emisión",
			"pdf": "PDF",
			"duplicarDoc": "Documento duplicado.",
			"comunicarDocAt": "Comunicarse en",
			"editarDocumento": "Editar",
			"encerrarDocumento": "Finalizar",
			"artigos": "Abrir artículo",
			"rastreabilidade": "Trazabilidad",
			"lotes": "Ver lotes",
			"adiantRegDocAtual": "Anticipos - Regístrate en el doc.",
			"adiantRegDocNew": "Anticipos - Regístrate en documento nuevo.",
			"adiantRegDoc": "Registrarse anticipado",
			"adiantConsult": "Regularizar anticipos",
			"movimentosEmAberto": "Movimientos abiertos",
			"extratoConta": "Estado de cuenta",
			"txtFaturacao": "notas de facturación",
			"emitirPagamento": "Pago de emisión"
		},
		"btn": {
			"importDoc": "Documento de importación",
			"meiosPagam": "Métodos de pago",
			"addMeiosPagam": "Agregar métodos de pago",
			"closeMeiosPagam": "Cerrar métodos de pago",
			"calculate": "Calcular",
			"arredManual": "Redondeo manual",
			"altCamposSerie": "Cambiar campos en serie",
			"rastreabilidade": "Trazabilidad",
			"save": "Ahorrar",
			"saveDraft": "Bosquejo",
			"savePdf": "PDF",
			"anexarDoc": "Adjuntar",
			"cancelar": "Cancelar",
			"gestaoDocsEditaveis": "Gestión de documentos editables",
			"encerrarDocumentos": "Finalizar documentos",
			"anularDocumentos": "Cancelar documentos",
			"diferimentos": "Aplazamientos",
			"finalizar": "Finalizar",
			"anular": "Cancelar",
			"gestaoRascunhos": "Gestión de borradores"
		},
		"tabs": {
			"carga": "Carga",
			"descarga": "Descargar",
			"transportadora": "Transportador",
			"faturacaoPublica": "Facturación pública",
			"linhas": "Pauta",
			"documentos": "Documentos",
			"docsEditaveis": "Documentos editables",
			"gestaoenc": "Colgantes",
			"porProcessar": "Para ser procesado",
			"processado": "Procesado",
			"transporte": "Transporte",
			"anexos": "Adjuntos",
			"finalizados": "Finalizado",
			"rascunho": "En borrador"
		},
		"modals": {
			"saveDoc": "guardar documento",
			"gestaoDocsEditaveis": "Gestión de documentos editables",
			"saveMessageOkCancel": "Esta acción es irreversible.",
			"newItem": "Nuevo articulo",
			"createNewItem": "El artículo no existe.",
			"info": {
				"naoMostraSaveMsgOkCancel": "No volver a mostrar esta pregunta"
			},
			"anexosPromptSaveDraft": "Para adjuntar el archivo, debe guardar el documento.",
			"precoUnitario": {
				"modalTitle": "Calcular precio",
				"strings": {
					"artigo": "Artículo",
					"taxa": "Tasa",
					"qtd": "Cantidad",
					"precoUnitarioSemIva": "Precio sin IVA",
					"precoUnitarioComIva": "Precio IVA incluido"
				}
			}
		},
		"questions": {
			"confirmSave": "¿Está seguro de que desea guardar el documento?"
		},
		"legend": {
			"anulado": "anulado"
		},
		"importadorLinhas": {
			"modalTitle": "Importador de filas",
			"extraFields": {
				"nomeartigo": "Descripción del artículo",
				"cunimo": "unidad de movimiento",
				"narmazem": "Almacenamiento",
				"nvendedor": "Vendedor",
				"nccusto": "centro de costos",
				"nrefprocesso": "Proceso"
			},
			"steps": {
				"welcome": {
					"title": "Bienvenido",
					"text1": "Bienvenido al asistente de importación de filas.",
					"text2": "Puede seleccionar campos adicionales para exportar"
				},
				"downloadModel": {
					"title": "Descargar plantilla",
					"text1": "Haga clic en el botón a continuación para descargar la plantilla de importación de líneas.",
					"text2": "Después de descargar el archivo, rellénelo con los datos que desea importar.",
					"text3": "Atención: Debes tener un software de edición de hojas de cálculo.",
					"downloadButton": "Descargar plantilla de importación"
				},
				"uploadModel": {
					"title": "Subir plantilla completa",
					"text1": "Complete el archivo con los datos que desea importar.",
					"text2": "Después de completar el archivo, cárguelo para continuar con la importación."
				},
				"resumo": {
					"title": "Resumen",
					"text1": "Importación de línea finalizada.",
					"text2": "Se importaron {{total}} filas.",
					"text3": "Se produjo al menos un error al importar filas",
					"listaErros": "Lista de errores"
				}
			},
			"promptMsg": "Tiene datos de importación en la memoria."
		},
		"artigosAdvancedSearch": {
			"title": "Búsqueda avanzada de artículos"
		},
		"clifo": {
			"action": {
				"newContaCorrente": "Se creará una nueva cuenta corriente",
				"newContaOcasional": "Se creará una nueva cuenta ocasional.",
				"contaCorrente": "Cuenta corriente",
				"contaOcasional": "cuenta ocasional"
			}
		}
	},
	"encomendas": {
		"title": "Órdenes",
		"btn": {
			"initProce": "Proceso"
		},
		"header": {
			"clientes": "Clientes",
			"artigos": "Artículos",
			"dataDoc": "Fecha del documento",
			"dataPrevEnt": "Fecha de entrega prevista",
			"clifoPlaceHolder": "Seleccione un cliente/proveedor para procesar",
			"fornecedorPlaceHolder": "Seleccione un proveedor para procesar",
			"clientePlaceHolder": "Seleccione un cliente para procesar"
		},
		"table": {
			"nDoc": "Nro. de pedido.",
			"dataDoc": "Fecha del doctor.",
			"nArtigo": "Código del artículo",
			"nomeArtigo": "Artículo",
			"nClifo": "Nro. de cuenta",
			"nomeClifo": "Cuenta",
			"nArmaz": "Almacenamiento",
			"nomeArmaz": "Nombre del almacén",
			"prVendaQtd1": "Precio",
			"qtdStockNoArmazemOrigem": "Existencias",
			"qtd1": "Oficial",
			"qtd1Fact": "Procesado",
			"ligacaoQtd": "Tratamiento",
			"dataEntregaPrev": "Fecha de entrega prevista",
			"nRequisicao": "Pedido",
			"nRefProcesso": "Proceso",
			"nCCusto": "centro de costos"
		},
		"badges": {
			"partSatif": "Parcialmente procesado",
			"totalSatif": "Totalmente procesado",
			"linhaTextLivre": "Línea con texto libre",
			"satisfMais": "Sobreprocesado"
		},
		"actions": {
			"satTotal": "Cantidad total (F9)",
			"satTotalArtigo": "Cantidad total en todas las líneas (Artículo)",
			"satTotalDocumento": "Cantidad total en todas las líneas (Documento)",
			"satLinhaSemFat": "Línea de proceso sin facturación (F7)",
			"limparQtdSat": "Borrar cantidad a procesar",
			"limparQtdSatTodas": "Borrar cantidad para procesar desde todas las líneas",
			"selecionarClifo": "Seleccione {{cliph}}",
			"verDocumento": "Ver documento",
			"satisfClifo": "Proceso {{clip}}",
			"showArtigos": "Mostrar artículos",
			"showArmazens": "Muestra almacenes",
			"showDocFas": "Mostrar tipos de documentos",
			"verDadosClifo": "Ver procesado desde {{clifo}}",
			"showTextoLivre": "Mostrar texto libre"
		},
		"modal": {
			"btns": {
				"finalize": "Finalizar",
				"finalizePreviewDoc": "Finalizar y ver documento",
				"finalizeEditarDoc": "Finalizar y editar documento",
				"editDoc": "Editar documento",
				"preencherAuto": "Lotes de autocompletar",
				"lotesOk": "DE ACUERDO"
			},
			"processamento": {
				"title": "Procesamiento de pedidos",
				"steps": {
					"ident": "Identificación",
					"carga": "Carga",
					"descarga": "Descargar",
					"lote": "Lotes",
					"preview": "Resumen"
				},
				"fields": {
					"nDocFa": "Tipo de documento",
					"nNumer": "Serie",
					"cliente": "Cliente",
					"fornecedor": "Proveedor",
					"clifos": "Clientes/Proveedores",
					"dataDoc": "Fecha del documento",
					"dataInicTran": "Transporte a domicilio",
					"morada": "Familiar",
					"codPostal": "Código postal",
					"localidade": "Ubicación",
					"codPais": "País",
					"moradaAlt": "Dirección alternativa",
					"observacoes": "Observaciones",
					"horaInicTran": "Hora de inicio del transporte"
				},
				"messages": {
					"proceSuccess": "El procesamiento se completó con éxito.",
					"atribuirLotes": "Debe asignar lotes a líneas con artículos con lotes para poder simular.",
					"notAutoSelLotesEntradaStock": "No es posible seleccionar lotes automáticamente en una entrada de stock.",
					"naoTemLinhas": "No hay filas para procesar.",
					"naoTemLinhasComLotes": "No hay filas con lotes para completar automáticamente."
				}
			},
			"documento": {
				"title": "Documento"
			},
			"impressao": {
				"title": "Imprimir",
				"fields": {
					"dataDocDe": "Fecha del doctor.",
					"dataEntregaPrevDe": "fecha prevista",
					"estadoEncomendas": "Estado del pedido",
					"armazens": "Almacenes",
					"docfaclist": "Tipos de documentos"
				},
				"estado": {
					"soPendentes": "Para ser procesado",
					"soSatisfeitas": "Procesado"
				}
			},
			"simulacao": {
				"title": "Simulación",
				"fields": {
					"nConta": "Nro. de cuenta",
					"nome": "Nombre",
					"totalIva": "TINA",
					"totalLiquido": "Líquido"
				}
			},
			"satisfazerclifo": {
				"title": "Pedidos pendientes"
			},
			"lotes": {
				"title": "Selección de lote",
				"messages": {
					"existemLinhasSemLotesAtribuidos": "Hay lote(s) sin cantidad a procesar llenada para el(los) pedido(s)",
					"qtdLotesNaoEIgualLinha": "La cantidad ({{qtdLotes}}) del artículo {{nArtigo}} distribuida entre los lotes no es la misma que la de la línea: {{ligacaoQtd}}."
				}
			}
		},
		"messages": {
			"semLinhasSati": "No hay cantidad para procesar completada para los pedidos.",
			"atribuicaoDeLotesCancelada": "Se canceló la asignación de lotes en el procesamiento de pedidos.",
			"processamentoLotesEncomendaFalhou": "Error en el procesamiento por lotes de pedidos.",
			"infoProcessamentoClifo": "Es necesario buscar y luego seleccionar un {{clifo}} debajo o en el grid para procesar pedidos",
			"linhasComValorNulo": "Hay líneas con valor nulo en la cantidad a procesar"
		},
		"errors": {
			"clientenaoselecionado": "Ingrese {{clifo}} para procesar órdenes pendientes",
			"valorAProcessarNulo": "El valor a procesar no puede ser nulo"
		},
		"keyboardShortcuts": {
			"processarTotalmente": "Ingrese la cantidad total a procesar desde la línea de pedido",
			"processarLinhaSemFaturar": "Procesar línea de pedido sin facturación"
		}
	},
	"documentoFaturacao": {
		"btn": {
			"communicateFaturaAT": "Comunicarse en",
			"communicateDocTransporteAT": "Comunicarse doc.",
			"insertATCode": "Insertar código.",
			"notaCredito": "Devolver",
			"anular": "Cancelar",
			"transformarDoc": "Transformar documento.",
			"transformarLinhasDoc": "Transformar líneas",
			"pdf": "PDF",
			"opcoes": "Opciones",
			"anexos": "Adjuntos",
			"duplicarDoc": "Documento duplicado.",
			"copiarLinhas": "Copie las líneas del documento.",
			"meiosPagam": "Métodos de pago",
			"editarDocumento": "Editar",
			"encerrarDocumento": "Finalizar",
			"desmarcadoDocTransformado": "Deseleccionar transformado"
		},
		"modals": {
			"encerrar": {
				"title": "Finalizar documento",
				"caracteres": "Caracteres: {{numChar}}/50"
			},
			"questions": {
				"confirmEncerrar": "¿Está seguro de que desea finalizar el documento con el número {{id}}?"
			}
		},
		"summary": "Resumen",
		"retention": "Retención"
	},
	"grupoDoc": {
		"outros": "Otros",
		"comprasEfetivas": "Compras reales",
		"consultasAFornecedores": "Consultas de proveedores",
		"encomendasClientes": "Pedidos de clientes",
		"encomendasFornecedores": "Pedidos de proveedores",
		"folhasDeObra": "Hojas de trabajo",
		"guiasTransporteRemessa": "Guías de transporte marítimo.",
		"propostasAClientes": "Propuestas a los clientes.",
		"reservasClientes": "Reservas de clientes",
		"trfArmazensEntradas": "Trf.",
		"trfArmazensSaidas": "Trf.",
		"vendasEfectivas": "Ventas efectivas",
		"consignacoesClientes": "Envíos de clientes",
		"consignacoesFornecedores": "Envíos de proveedores",
		"recibosClientes": "Recibos de clientes",
		"pagamentosFornecedores": "Pagos a proveedores",
		"none": "Sin información",
		"planoProducao": "plan de producción",
		"preEncomendasClientes": "Pedidos anticipados de clientes",
		"custosAdicionais": "Costos adicionales",
		"notaCredito": "nota de crédito",
		"guiasTransporteFornecedores": "Proveedores de guías de transporte.",
		"entradasDiversas": "Insumos varios",
		"saidasDiversas": "Varias salidas"
	},
	"arredondamentoManual": {
		"title": "Documento comercial - Redondeo manual",
		"gridHeader": {
			"documento": "Documento",
			"arredondamento": "redondeo"
		},
		"gridFields": {
			"codIva": "código de IVA",
			"taxaIva": "Tasa",
			"prVenda1": "Incidencia",
			"valorIva": "TINA",
			"arredLiquidoManualEditavel": "Incidencia",
			"arredIVAManualEditavel": "IVA redondeado",
			"incidenciaTotalEditavel": "Incidencia",
			"ivaTotalEditavel": "IVA total"
		},
		"messages": {
			"warningMaxValue": "El redondeo previsto supera absolutamente el máximo permitido ({{value}})",
			"totalIvaLiquido": "Redondeo manual: Neto {{totalLiquido}} e IVA {{totalIva}}",
			"totalLiquido": "Redondeo manual: neto {{totalNet}}",
			"totalIva": "Redondeo manual: IVA {{totalIva}}"
		},
		"resumo": {
			"totalIva": "IVA total",
			"totalIncidencia": "Incidencia total"
		}
	},
	"alterarCamposSerie": {
		"title": "Seleccionar campos para cambiar"
	},
	"gestaoDocsComerciais": {
		"title": "Gestión de borradores de documentos.",
		"filters": {
			"nDocFa": "Tipo de documento.",
			"deDataDoc": "Fecha del doctor."
		},
		"fields": {
			"mensagemErro": "Mensaje"
		},
		"messages": {
			"encerrarFullSuccess": "Todos los documentos se completaron con éxito.",
			"encerrarSuccessWithErrors": "Se finalizaron {{nDocsSuccess}} de los {{nDocs}} seleccionados.",
			"encerrarError": "Se produjo un error al finalizar los documentos, no se cerró ningún documento.",
			"anularFullSuccess": "Todos los documentos fueron anulados con éxito.",
			"anularSuccessWithErrors": "Se cancelaron {{nDocsSuccess}} de los {{nDocs}} seleccionados.",
			"anularError": "Se produjo un error al cancelar los documentos, no se cerró ningún documento.",
			"anularExistemDocumentosEncerrados": "Hay documentos cerrados, ¿piensa proceder a su anulación?",
			"existemDocumentosEncerrados": "Hay documentos seleccionados que ya están cerrados.",
			"naoExistemDocsSelecionados": "No hay documentos seleccionados.",
			"artigoNaoExiste": "El artículo no existe.",
			"anularAviso": "Esta acción es irreversible."
		},
		"legend": {
			"documentoComErro": "Documento con error"
		}
	},
	"pesquisaDocumentos": {
		"title": "Búsqueda de documentos"
	},
	"selecaoLotes": {
		"title": "Selección de lote",
		"tooltips": {
			"lote": "Lotes"
		}
	},
	"devolucao": {
		"caption": {
			"artigoQuantidade": "Devolución del artículo",
			"artigoValor": "Descuento del artículo",
			"documentoValor": "Descuento de documentos",
			"documentoExterno": "Externo",
			"stepTipo": "Tipo",
			"documentoDestino": "Documento de destino",
			"emQuantidade": "En cantidad",
			"emValor": "En valor",
			"tipoDocOrigem": "Tipo de documento fuente",
			"docExternoOrigem": "Documento externo (fuente)",
			"dataDocExternoOrigem": "Fecha del documento externo (origen)",
			"tipoDocumento": "Tipo de documento",
			"devolucao": "Devolver",
			"deArtigo": "Artículo de",
			"docExternoPlaceholder": "Ejemplo: FT 001/1",
			"artigoDesconto": "Artículo con descuento",
			"valorDocExterno": "Valor total",
			"adicionar": "para agregar",
			"dataDoc": "Fecha del doctor.",
			"desdeDataDoc": "Fecha del doctor.",
			"quantidade": "Cantidad",
			"codIva": "código de IVA"
		},
		"messages": {
			"sucesso": "Documento de devolución generado exitosamente.",
			"escolherTipo": "Elige el tipo de devolución que quieres realizar",
			"erroTipoDoc": "Debe seleccionar un tipo de documento de origen.",
			"erroDocExterno": "Es obligatorio el documento externo (origen).",
			"erroMotivo": "Deberás indicar el motivo de la devolución.",
			"erroDocExternoValor": "El importe a devolver debe ser superior a cero.",
			"erroLinhas": "No hay líneas seleccionadas.",
			"erroArtigoDevolucao": "Debes indicar un artículo de devolución.",
			"erroValor": "El valor a devolver no puede ser 0.",
			"erroValorSuperior": "El valor insertado a regularizar excede el valor a regularizar (A regularizar: {{byRevolver}} | Insertado: {{aRevolver}}).",
			"devolucaoEditar": "Este documento ya contiene líneas de retorno.<br>Elija la opción que desea realizar."
		},
		"table": {
			"facliId": "Faclid",
			"nDoc": "Documento",
			"porDevolver": "Para regresar",
			"aDevolver": "Para ser devuelto"
		},
		"tooltip": {
			"devolveTudo": "Devolver todo",
			"limpaTudo": "Borrar todos los valores devueltos",
			"limpaDevolvido": "Borrar importe devuelto",
			"adicionarMotivo": "Añadir a la lista de motivos",
			"artigoQuantidade": "Se utiliza para realizar devoluciones de cantidad de productos o mercancías.",
			"documentoValor": "Se utiliza para hacer un descuento en uno o varios documentos.",
			"artigoValor": "Se utiliza para hacer un descuento en un artículo específico.",
			"documentoExterno": "Se utiliza para regularizar un documento que no está presente en el sistema."
		},
		"placeholder": {
			"motivo": "Escribe o busca",
			"artigoParaDesconto": "Artículo con descuento"
		}
	},
	"gruposdocfas": {
		"configuracoes": {
			"title": "Ajustes",
			"modofuncionamento": {
				"label": "Modo de funcionamiento",
				"options": {
					"processo": "Proceso",
					"centrocusto": "centro de costos"
				}
			},
			"gastos": {
				"title": "Gastos",
				"estimado": {
					"gridtitle": "Estimado"
				},
				"real": {
					"gridtitle": "Real"
				}
			},
			"rendimentos": {
				"title": "Ingreso",
				"estimado": {
					"gridtitle": "Estimado"
				},
				"real": {
					"gridtitle": "Real"
				}
			},
			"grids": {
				"fields": {
					"id": "Tipo de documento",
					"name": "Nombre",
					"desc": "Descripción",
					"sinal": "Señal"
				},
				"enums": {
					"sinal": {
						"mais": "+",
						"menos": "-"
					}
				}
			},
			"avisos": {
				"tipodocnecessario": "Debe seleccionar un tipo de documento para agregar.",
				"tipodocexistente": "El tipo de documento '{{id}}' ya está en la lista."
			},
			"messages": {
				"configsavedsucess": "Configuración de grupos de tipos de documentos guardados exitosamente."
			},
			"modal": {
				"title": "Configuración del grupo de tipos de documentos"
			}
		}
	},
	"analisemargemlucro": {
		"title": "Análisis de margen de beneficio",
		"bands": {
			"gastos": "Gastos",
			"rendimentos": "Ingreso",
			"resultado": "Resultado",
			"margem": "Margen %"
		},
		"fields": {
			"nrefprocesso": "código de proceso",
			"nomeprocesso": "Proceso",
			"nccusto": "Código del centro de costos",
			"nomeccusto": "centro de costos",
			"nconta": "Nro. de cuenta",
			"nomeconta": "Nombre de cuenta",
			"classificador": "Clasificador",
			"estimado": "Estimado",
			"real": "Real",
			"gastoestimado": "Gasto estimado",
			"gastoreal": "Gasto real",
			"rendimentoestimado": "Rendimiento estimado",
			"rendimentoreal": "Ingresos reales",
			"resultadoestimado": "Resultado estimado",
			"resultadoreal": "Resultado real",
			"margemestimado": "Margen estimado",
			"margemreal": "Margen real"
		},
		"messages": {
			"semregistos": "No existen registros para el análisis del margen de utilidad.",
			"dataAteMenorDataDe": "La fecha \"Hasta\" debe ser mayor o igual a la fecha \"Desde el documento\"."
		},
		"detail": {
			"title": "Detalle del registro de análisis de margen de beneficio",
			"fields": {
				"tipologia": "Tipología",
				"ndocfa": "Código de tipo de documento.",
				"nomedocfa": "Tipo de documento",
				"nnumer": "Serie",
				"ndocumento": "Número de documento",
				"ndocasstring": "Documento",
				"datadoc": "Fecha del doctor.",
				"nconta": "Nro. de cuenta",
				"nomeconta": "Nombre de cuenta",
				"nartigo": "Código del artículo",
				"nomeartigo": "Artículo",
				"qtd1": "Cant.",
				"unidade": "Unidad",
				"prvendaqtd1": "Precio unitario",
				"liquido": "Líquido",
				"valoriva": "TINA",
				"nFamilia": "código familiar",
				"nomeFamilia": "Familia",
				"nTpArt": "Código de tipo de artículo",
				"nomeTpArt": "Tipo de artículo",
				"nGrFamilia": "código gr.",
				"nomeGrFamilia": "familia gr.",
				"nSubFa": "Código de subfamilia",
				"nomeSubFa": "Subfamilia"
			},
			"actions": {
				"documento": "Documento"
			}
		},
		"estado": {
			"aberto": "Abierto",
			"terminado": "Finalizado",
			"ambos": "Ambos"
		},
		"caption": {
			"estado": "Estado",
			"nrefprocesso": "Proceso",
			"nccusto": "centro de costos",
			"datade": "Fecha del doctor."
		},
		"modal": {
			"print": {
				"title": "Impresión del análisis del margen de beneficio"
			}
		}
	},
	"docscontabilidade": {
		"title_new": "Nuevo documento",
		"title_edit": "Documento {{id}}",
		"title_plural": "Documentos contables",
		"title_singular": "Documento contable",
		"pesquisa": "buscar",
		"goSearch": "Búsqueda de documentos",
		"goSearchMobile": "Buscar",
		"clearForm": "Borrar campos",
		"saved": "Documento contable {{id}} guardado exitosamente.",
		"error": "El documento contable no se puede guardar.",
		"tooltipNDocumento": "Este identificador es sólo sugerente, puede cambiar después de guardar el documento.",
		"tooltipNextDocumento": "Siguiente documento para este período/diario<br>(Ctrl + Flecha derecha)",
		"tooltipPreviousDocumento": "Documento anterior de este período/diario<br>(Ctrl + Flecha izquierda)",
		"tooltipFirstDocumento": "Primer documento de este período/diario<br>(Ctrl + Mayús + Flecha izquierda)",
		"tooltipLastDocumento": "Último documento de este período/diario<br>(Ctrl + Mayús + Flecha derecha)",
		"fields": {
			"extPocCabID": "Identificador del documento",
			"periodo": "Período",
			"nomePeriodo": "Período",
			"nDiario": "A diario",
			"nomeDiario": "A diario",
			"nDocInterno": "Doc no.",
			"totalDebitoGeral": "Deuda total",
			"totalCreditoGeral": "Crédito completo",
			"dataLancamento": "Fecha de publicación contable",
			"dataVencimento": "Fecha de vencimiento",
			"dataDoc": "Fecha del documento",
			"codDescritivo": "código descriptivo",
			"nomeDescritivo": "Descriptivo",
			"descricao": "Descripción",
			"codMoeda": "Acuñar",
			"nomeMoeda": "Acuñar",
			"nContribuinte": "número de contribuyente",
			"documentoExterno": "Documento externo.",
			"stampUpdate": "Último.",
			"nDocumento": "Número de documento",
			"nDocumentoFilter": "Número de documento (ej: 2024011.1.345)",
			"preDefinido": "Predefinido",
			"anulado": "anulado",
			"nconta": "Nro. de cuenta",
			"valor": "Valor",
			"temDocDigital": "documento digital"
		},
		"doc": {
			"cab": {
				"extPocCabID": "Identificador del documento",
				"periodo": "Período",
				"nomePeriodo": "Período",
				"nDiario": "A diario",
				"nomeDiario": "A diario",
				"nDocInterno": "Doc no.",
				"totalDebitoGeral": "Deuda total",
				"totalCreditoGeral": "Crédito completo",
				"dataLancamento": "fecha de lanzamiento",
				"dataVencimento": "Fecha de vencimiento",
				"dataDoc": "Fecha del documento",
				"codDescritivo": "código descriptivo",
				"nomeDescritivo": "Descriptivo",
				"descricao": "Descripción",
				"codMoeda": "Acuñar",
				"nomeMoeda": "Acuñar",
				"nContribuinte": "Contribuyente",
				"documentoExterno": "Documento externo.",
				"stampUpdate": "Último.",
				"nDocumento": "Número de documento",
				"totalCredidoBaseTributavel": "Crédito base imponible total",
				"totalDebitoBaseTributavel": "Deuda base imponible total",
				"totalCredidoIva": "Crédito de IVA completo",
				"totalDebitoIva": "Deuda total del IVA",
				"cambioRef": "Referencia de intercambio",
				"moeda": "Acuñar",
				"anulado": "anulado"
			},
			"linhas": {
				"nLanc": "Identificador de línea",
				"nLancImput": "Identificador de imputación",
				"extPocCabID": "Identificador del documento",
				"nConta": "Nro. de cuenta",
				"periodo": "Período",
				"nDiario": "A diario",
				"nDocInterno": "Doc no.",
				"nSeq": "Secuencia",
				"dc": "Débito/Crédito",
				"valor": "Valor",
				"valorME": "Valor YO",
				"codMovAberto": "código de movimiento abierto",
				"valorPago": "Monto pagado",
				"valorPagoME": "Monto pagado ME",
				"codMoeda": "Código de moneda",
				"cambio": "Intercambio",
				"nDescr": "código descriptivo",
				"dataLancamento": "Fecha de publicación contable",
				"dataDoc": "Fecha del documento",
				"dataVencimento": "Fecha de vencimiento",
				"nDocExterno": "Documento externo.",
				"dataDocExt": "Fecha del doctor.",
				"descricao": "Descripción",
				"nContrib": "número de contribuyente",
				"codIva": "código de IVA",
				"valorTaxa": "valor del IVA",
				"valorExistente": "{{tipo}} actual",
				"valorOriginal": "{{tipo}} original",
				"valorTaxaME": "Valor de la tarifa ME",
				"cDecPer": "Diciembre periódico.",
				"cDecAnual": "Anual diciembre.",
				"codControloIva": "código de control del IVA",
				"nGrupo": "Grupo",
				"classificControlo": "Clase.",
				"valorDesconto": "Valor de descuento",
				"valorRetencao": "Valor de retención",
				"nUtilizador": "Número de usuario",
				"nLancOrigemIva": "nLancOrigemIva",
				"nLancDestinoIva": "nLancDestinoIva",
				"valorOrigemIva": "valorOrigenIva",
				"valorDestinoIva": "valorDestinoIVA",
				"nLancOrigemRetencao": "nLancOrigemRetencao",
				"nLancDestinoRetencao": "nLancDestinoRetencao",
				"valorOrigemRetencao": "valorOrigenRetención",
				"valorDestinoRetencao": "valorDestinoRetención",
				"codRetencao": "Código de retención",
				"taxaRetencaoUsada": "Tasa de retención utilizada",
				"nGrupoRetencao": "Agarre de retención",
				"classificControloRetencao": "Clasificación.",
				"tipoRetServNIF": "Tipo de retención NIF",
				"extPocLigaIDOrigemRetNaoDispo": "extPocLigaIDOrigemRetNaoDispo",
				"nLancOrigemImputacaoCCusto": "nLancOrigemImputacaoCCusto",
				"nLancDestinoImputacaoCCusto": "nLancDestinoImputacaoCCusto",
				"nContaDebito": "cuenta de débito",
				"nContaCredito": "cuenta de crédito",
				"temMovAberto": "Hay movimientos abiertos",
				"poc": {
					"nConta": "Nro. de cuenta",
					"nContaPlaceholder": "código de cuenta",
					"nome": "Nombre",
					"nomePlaceholder": "Nombre de cuenta",
					"tipo": "Tipo de cuenta",
					"cc": "Tener una cuenta corriente",
					"nif": "número de contribuyente",
					"temCCusto": "Tiene análisis",
					"temIVA": "Tiene control del IVA",
					"registaRetencao": "Retención de registros",
					"temRetencao": "Tiene retención",
					"bloqueada": "Obstruido",
					"dpq06NCampo": "Decreto periódico del IVA",
					"dpq06NCampoNome": "Decreto periódico del IVA",
					"daq08NCampo": "Dic. Anual IVA - Cuadro 03 y 05",
					"daq08NCampoNome": "Dic. Anual IVA - Cuadro 03 y 05",
					"daq09NCampo": "Dic. Anual IVA - Tablas 04 y 05",
					"daq09NCampoNome": "Dic. Anual IVA - Tablas 04 y 05",
					"codControloIVADebito": "Código de control de IVA débito",
					"codControloIVADebitoNome": "Código de control de IVA débito",
					"codControloIVACredito": "Código de control de crédito de IVA",
					"codControloIVACreditoNome": "Código de control de crédito de IVA"
				},
				"dcDebito": "Deuda",
				"dcCredito": "Crédito"
			},
			"totais": {
				"baseTributavel": "Base imponible",
				"totalIVA": "IVA total",
				"moedaCorrente": "Divisa",
				"moedaEstrangeira": "Moneda extranjera",
				"totalGeral": "gran total",
				"porPagar": "No pagado"
			},
			"tooltipDataLancamento": "3.4.3.8.",
			"tooltipDataDoc": "3.4.3.3."
		},
		"erros": {
			"notselected": "¡No se han seleccionado documentos!",
			"optionsNotAvailable": "No se pueden cambiar las opciones cuando un ajuste preestablecido está activo",
			"changeMoedaNotAvailable": "Para cambiar la moneda primero debes eliminar todas las líneas.",
			"initDoc": "El documento no ha sido inicializado.",
			"notSaldado": "¡El documento no está equilibrado!",
			"periodoFinalInferiorInicial": "¡El período final es más corto que el inicial!",
			"nifAlreadyRegistered": "El número de contribuyente \"{{nContribuinte}}\" ya está registrado."
		},
		"configs": {
			"valorComIvaIncluido": "Valor con IVA incluido",
			"obtemDadosDocDigital": "Obtiene datos del documento digital.",
			"showFieldPredefinido": "Mostrar campo predefinido (encabezado)",
			"showFieldDataVencimento": "Mostrar campo de fecha de vencimiento (encabezado)",
			"showFieldMoeda": "Mostrar campo de moneda (encabezado)",
			"showFieldLinhaNif": "Mostrar campo de número de contribuyente (líneas)",
			"showFieldLinhaValorTaxa": "Mostrar campo de valor de IVA (líneas)",
			"showFieldLinhaDataDoc": "Mostrar campo de fecha del documento (líneas)",
			"showFieldLinhaDescricao": "Mostrar campo de descripción (líneas)",
			"showFieldLinhaCDecPerAnual": "Mostrar campos de declaración periódica/anual (líneas)",
			"showFieldLinhaNomeConta": "Mostrar campo de nombre de cuenta (líneas)",
			"seletorObtemDadosDocDigital": "Botón Mostrar para obtener datos del documento digital en la sección de líneas",
			"seletorValorComIvaIncluido": "Mostrar valor del botón con IVA incluido en la sección de líneas",
			"skipDescricao": "Saltar campo de descripción en líneas",
			"retainDescription": "Mantener descripción entre documentos.",
			"showFormMoeda": "Mostrar formulario de moneda extranjera",
			"skipPromptSave": "No volver a pedir confirmación al guardar el documento"
		},
		"changeValorTitle": "Cambiar {{tipo}}",
		"changeValorTaxaTitle": "Cambiar importe del IVA",
		"changeCCustoTitle": "Asignación de valores analíticos",
		"changeTitleInvalidLines": "Debes registrar al menos un valor.",
		"valorValidatorMessage": "El {{type}} tiene un límite máximo de diferencia de {{value}}{{currency}} en comparación con el {{type}} original.",
		"contaTemLigacaoContasCorrentes": "La cuenta {{nConta}} está vinculada a cuentas corrientes.",
		"desejaEfetuarPagamentos": "¿Quieres realizar pagos de documentos a cuentas corrientes?",
		"contaAImputarJaExiste": "¡La cuenta de análisis que se imputará {{nConta}} ya existe en la lista de imputación!",
		"valorTemDeSerSuperior0": "El valor debe ser mayor que 0.",
		"disponibilidadeRendimento": "Disponibilidad de ingresos",
		"contaCorrenteTemRetencaoDeduzida": "El importe en la cuenta corriente ya tiene deducida la retención",
		"montanteTributavel": "Base imponible",
		"montanteRetido": "Monto retenido",
		"nDocumento": "Número de documento",
		"changeregularizacaocampo40Title": "Regularizaciones a favor del contribuyente - Cobertura del artículo 78",
		"nDocumentoRegularicacaoJaExiste": "El documento número {{nDocumento}} ya existe",
		"valorSomadoJaExistente": "El valor {{valor}} agregado al valor existente {{ValueExisting}} excede el valor de la base imponible {{ValueBase}}.",
		"documentoEliminadoComSucesso": "Documento eliminado exitosamente.",
		"diferimentosAnuladosComSucesso": "Aplazamientos/acumulaciones cancelados con éxito.",
		"viewDocImputado": "Ver documento imputado",
		"viewDocTitle": "Documento {{nDocumento}}",
		"keyboardShortcuts": {
			"save": "guardar el documento",
			"saveAndAttach": "Guardar y adjuntar el documento",
			"toggleIva": "Alternar \"Valor con IVA incluido\"",
			"obterSaldoConta": "Obtener saldo de cuenta (solo en la columna \"Monto\" de la línea seleccionada)",
			"firstDocumento": "Primer documento de este período/diario",
			"lastDocumento": "Último documento de este período/diario",
			"previousDocumento": "Documento anterior de este período/diario",
			"nextDocumento": "Próximo documento para este período/diario"
		},
		"ccusto": {
			"badTotalTitle": "Confirmación de la asignación de valores analíticos.",
			"badTotalMessage": "El valor asignado al centro de costo es diferente del valor total a asignar."
		},
		"movab": {
			"title": "Pagos por documentos en cuentas corrientes.",
			"nDocumento": "Número de documento",
			"dc": "ANUNCIO",
			"nDocExterno": "Documento externo.",
			"dataDoc": "Fecha del doctor.",
			"valor": "Valor",
			"porPagar": "No pagado",
			"valorPagar": "cantidad a pagar",
			"valordesconto": "Descuento",
			"imputarTudo": "Imputar todo (todas las filas)",
			"imputarTudoLinha": "Imputar todo (línea)",
			"limparValores": "Borrar todos los valores imputados",
			"limparValorLinha": "Borrar valor",
			"dcDebito": "D",
			"dcCredito": "W.",
			"totalImputar": "Total a imputar",
			"totalJaImputado": "Total ya imputado",
			"totalImputado": "Total imputado",
			"descricao": "Descripción",
			"descritivo": "Descriptivo",
			"totalConta": "Totales de cuenta",
			"options": {
				"showDocExt": "Mostrar campo de documento externo",
				"showDataDoc": "Mostrar campo de fecha del documento",
				"showDescricao": "Mostrar campo de descripción",
				"showDescritivo": "Mostrar campo descriptivo"
			},
			"movmesmoid": "Estas entradas pertenecen al mismo documento digital.",
			"movmesmoidval": "Estas entradas pertenecen al mismo documento digital con el valor {{value}}.",
			"valorPagarValidationInferior": "El valor no puede ser menor que 0.",
			"valorPagarValidationSuperior": "El valor no puede ser superior al importe a pagar.",
			"valorDescontoValidationSuperior": "El valor no puede ser superior al importe a pagar."
		},
		"regularizacaoCampo40": {
			"title": "Cancelar regularizaciones a favor del contribuyente",
			"message": "La no indicación de los documentos de regularización generará inconsistencias en la declaración del IVA.",
			"validateMessage": "Para continuar debes activar la opción \"Me he dado cuenta y tengo intención de continuar\"",
			"label": "Lo descubrí y pienso continuar"
		},
		"retencao": {
			"title": "Retención de {{value}}",
			"imediata": "Inmediato",
			"noPagamento": "Al pagar",
			"disabledRetencaoDeduzida": "Para cambiar este valor es necesario tener una cuenta corriente abierta previamente.",
			"modelo30": {
				"title": "Modelo 30",
				"nGuiaPagamento": "Pestaña de pago"
			},
			"messages": {
				"needCCBefore": "Para que los ingresos estén disponibles en el momento del pago, primero debe contabilizar la cuenta corriente."
			}
		},
		"clearPreDefinido": "Quitar preajuste",
		"clearSuggestion": "Cancelar sugerencia",
		"clearSuggestionTitle": "Cancelar sugerencia de lanzamiento",
		"moedaestrangeira": {
			"title": "Liberación en moneda extranjera",
			"conta": "Cuenta",
			"moeda": "Moneda extranjera",
			"cambioME": "ME intercambio",
			"valorME": "Valor YO",
			"valorNacional": "valor nacional"
		},
		"toolbar": {
			"saveAndAttach": "Guardar y adjuntar",
			"saveWithoutAttach": "Guardar sin adjuntar",
			"contabilidadeDigital": "Contabilidad",
			"criarPreDefinido": "Crear preajuste",
			"criarPreDefinidoTooltip": "Cree un documento contable predefinido basado en esto",
			"infoDiferimentos": "Información de aplazamiento",
			"infoGlobalDiferimentos": "Información de aplazamiento global",
			"infoGlobalAcrescimos": "Información de adición global",
			"infoDiferimentosHint": "Información de acumulación/diferimiento",
			"anularDiferimento": "Cancelar acumulación/aplazamiento"
		},
		"nDocSeq": "Doc no.",
		"text": {
			"maintenancePreDefinidos": "Mantenimiento de contabilidad predefinida.",
			"maintenanceExtratos": "Ver estados de cuenta",
			"maintenanceMovab": "Ver movimientos abiertos",
			"maintenanceClifos": "Mantenimiento cliente/proveedor",
			"recolha": "Recopilación",
			"conta": "Cuenta: {{nCuenta}} - {{nombre}}",
			"saldoConta": "Saldo: {{saldoConta}}",
			"obterSaldoConta": "Obtener saldo de cuenta",
			"modificarRegularizacoesSujeitoPassivo": "Regularizaciones a favor del contribuyente",
			"calculodiferimentos": "Devengos y aplazamientos",
			"registarNif": "Registrar número de contribuyente",
			"marcadoComoConsistente": "Documento integrado con error y marcado como consistente por {{name}} el {{date}}",
			"extratosconta": "Estados de cuenta",
			"mudarperiodocontab": "Cambiar el período contable de la empresa",
			"confirmMudaPerio": "¿Está seguro de que desea cambiar el período contable de la empresa a \"{{period}}\"?",
			"periodoempmudadosuccesso": "¡El período contable de la empresa cambió con éxito!",
			"docativosconfirm": "Ha registrado un activo en contabilidad.<br>¿Quiere registrar la adquisición del activo en el módulo de gestión de activos?",
			"editValorIva": "Editar importe del IVA",
			"incValorIva": "Aumentar el valor del IVA",
			"decValorIva": "Disminuir el valor del IVA",
			"calculouDiferimentos1": "Se realizó el cálculo de adiciones/diferimientos y por este motivo se bloqueó el documento.",
			"calculouDiferimentos2": "haga clic aquí para cancelar el cálculo",
			"calculouDiferimentos3": "Debe hacer clic en \"Guardar\" para aplicar el aplazamiento al documento.",
			"calculouDiferimentosLocked": "Documento con aumento/diferimiento asociado.",
			"confirmAnulaDiferimento": "¿Está seguro de que desea cancelar el aumento/aplazamiento?",
			"docImputacao": "Imputado al documento: {{ndocImput}}"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-triángulo de exclamación-advertencia de texto' estilo='color: naranja;'",
			"exitMessage": "Al hacer clic en Aceptar, saldrá sin guardar el documento contable y se eliminarán todos los cambios que haya realizado en este documento.",
			"doNotAskAgain": "no vuelvas a preguntar",
			"perguntaCC": "No volver a mostrar esta cuenta cuando la señal esté en {{sign}}."
		},
		"saveprompt": {
			"saveDocAndAttach": "Guardar documento y adjuntar documentos digitales",
			"saveDocIdAndAttach": "Guardar el documento {{id}} y adjuntar documentos digitales"
		}
	},
	"docscontabilidadeimprimir": {
		"label": {
			"email": "Correo electrónico",
			"enviar": "para enviar"
		},
		"email": {
			"emailEnviado": "Correo electrónico enviado a {{email}}",
			"emailErro": "Error al enviar el correo electrónico a {{email}}."
		}
	},
	"docsnaosaldados": {
		"title": "Documentos desequilibrados",
		"fields": {
			"doPeriodo": "período de",
			"deConta": "cuenta"
		},
		"alertNaoHaDocs": "No se encontraron documentos sin resolver."
	},
	"documentosFiscais": {
		"tabIva": "TINA",
		"tabIRC": "IRC",
		"tabRecibos": "Ingresos",
		"tabIES": "IES",
		"tabIRS": "IRS",
		"tabIvaDp": "Declaraciones periódicas de IVA",
		"tabIvaDr": "Declaraciones resumidas de IVA",
		"tabIRCPagAnt": "Pagos por adelantado",
		"tabIRCMod22": "Modelo 22",
		"tabRecibosRVClient": "Recibos verdes como cliente",
		"tabRecibosRR": "Recibos de ingresos",
		"tabIESConsult": "Consultar",
		"tabIRSMod10": "Modelo 10",
		"tabRetencoes": "Retenciones",
		"tabRetencoesIRCIRS": "Retenciones IRC/IRS y I. Sello",
		"tabIUC": "IUC",
		"tabVeiculos": "Vehículos",
		"tabCertidoes": "Certificados",
		"tabImoveis": "Bienes raíces",
		"tabImoveisPP": "Patrimonio constructivo",
		"tabImoveisNC": "notas de facturación",
		"tabPenhoras": "Gravámenes",
		"tabSSSituacaoContrib": "Situación contributiva",
		"tabViaCTTInbox": "Buzón",
		"tabIUCDocumentos": "Documentos",
		"tabIUCEmitir": "Asunto",
		"tabConsultarCertidoes": "Consultar certificados",
		"tabEmitirCertidoes": "Certificado de emisión",
		"buttons": {
			"get": "para obtener",
			"search": "buscar",
			"efetuarPedido": "Realizar pedido",
			"mb": "cajero automático"
		},
		"fields": {
			"periodo": "Período",
			"identificacao": "Identificación",
			"dataRececao": "fecha de recibo",
			"valor1": "entrega de impuestos",
			"valor2": "recuperación de impuestos",
			"comprovativo": "Prueba",
			"certidao": "Certificado",
			"docPagamento": "Documento de pago",
			"tipo": "Tipo",
			"situacao": "Situación",
			"numero": "Número",
			"dataSubmissao": "Fecha de presentación",
			"submissaoValor": "Valor de envío",
			"dataPagamento": "fecha de pago",
			"pagamentoValor": "Monto del pago",
			"obterDetalhes": "Detalles del documento",
			"numDocumento": "Doc No.",
			"dataEmissao": "Fecha de asunto",
			"nif": "NIF",
			"nome": "Nombre",
			"domicilioFiscal": "domicilio fiscal",
			"atividadePrestador": "Actividad realizada",
			"titulo": "Título",
			"dataRef": "fecha de referencia",
			"descricao": "Descripción",
			"valorBase": "Valor base",
			"ivaDesc": "Descripción del IVA",
			"valorIVA": "valor del IVA",
			"valorImpostoSelo": "Impuesto de timbre",
			"valorIRS": "Valor del IRS",
			"importanciaRecebida": "Importancia recibida",
			"reciboEco": "Recibo económico",
			"reciboNormal": "recibo normal",
			"periodoFull": "Período",
			"dataRecebimento": "fecha de recibo",
			"tipoImportancia": "Tipo de importancia",
			"valor": "Valor",
			"retencao": "Retención",
			"document": "Documento",
			"recRefContrato": "Referencia del contrato",
			"numeroRecibo": "nº recibo",
			"importancia": "Importancia",
			"anexos": "Adjuntos",
			"declaracao": "Declaración",
			"dataRecepcao": "fecha de recibo",
			"via2comprovativo": "2da copia",
			"aPagar": "Importancia de pagar",
			"tipoDocumento": "Tipo de documento.",
			"dataSituacao": "Situación de la fecha",
			"guiaPagamento": "Pestaña de pago",
			"matricula": "Registro",
			"catIUC": "Gato IUC.",
			"dataLimitePagamento": "Plazo de pago",
			"valorImposto": "Valor fiscal",
			"valorJuros": "Valor de interés",
			"situacaoNota": "Nota situación",
			"refPagamento": "Página de referencia",
			"emissao": "Emisión",
			"dataMatricula": "Fecha de registro",
			"marca": "Marca",
			"modelo": "Modelo",
			"propLoc": "Propietario/arrendatario",
			"tipoNota": "tipo de nota",
			"valorEmitido": "Monto emitido",
			"valorPago": "Monto pagado",
			"qtdVeiculosLiquidados": "Cantidad de vehículos",
			"categoria": "Categoría",
			"mesMatricula": "Registro del mes",
			"adicionalIUC": "IUC adicional",
			"dataPedido": "fecha de pedido",
			"tipoCertidao": "Tipo de certificado",
			"estado": "Estado",
			"nPedidoPenhora": "Número de solicitud de archivo adjunto",
			"descricaoBem": "Descripción del activo",
			"codigoBem": "código de bien",
			"qtdReconhecida": "Cantidad reconocida",
			"unidade": "Unidad",
			"valorUnit": "Valor unitario.",
			"niss": "niss",
			"dataResposta": "Fecha de respuesta",
			"resposta": "Respuesta",
			"entidade": "Entidad",
			"conta": "Cuenta",
			"dataEntrega": "fecha de entrega",
			"lido": "Leer",
			"tratado": "tratado",
			"freguesia": "Parroquia",
			"artigo": "Artículo",
			"parte": "Parte",
			"valorInicial": "Valor inicial",
			"idNotaCobranca": "número de nota de facturación",
			"numPredios": "Número de edificios",
			"cadernetaPredial": "Folleto de construcción",
			"guia": "Guía",
			"pagamento": "Pago",
			"dataRegisto": "Fecha de registro",
			"pedidoPor": "Solicitud por"
		},
		"strings": {
			"headerAT": "Autoridad Tributaria (AT)",
			"headerSS": "Seguridad social",
			"headerViaCTT": "Vía CTT",
			"comprovativo": "{{title}} - Prueba: {{id}}",
			"via2comprovativo": "{{title}} - 2da copia: {{id}}",
			"docPagamento": "{{title}} - Documento de pago: {{id}}",
			"primeiraDeclaracao": "Primera declaración",
			"declaracaoSubstituicao": "Declaración de reemplazo",
			"detalhesDoc": "{{title}} - Detalles del documento: {{id}}",
			"recibo": "{{title}} - Recibo: {{id}}",
			"naoDisponivel": "No disponible",
			"modalTitleGuiaPagamento": "{{title}} - Pestaña Pago: {{id}}",
			"iucEmitirLigeiros": "Coches y motos",
			"iucEmitirMercadorias": "Mercancías mixtas y ligeras con un peso bruto superior a 2500 kg.",
			"emitido": "Emitido",
			"modalMessageEmitirIUC": "¿Estás seguro de querer emitir el documento de pago de la matrícula: {{matricula}}?",
			"emitidoComSucesso": "¡Documento de pago emitido exitosamente!",
			"emitir": "Asunto",
			"liquidacaoIRS": "Acuerdo del IRS",
			"renunciaIsencaoIvaImovel": "Renuncia a la exención del IVA - Propiedad",
			"residenciaFiscal": "Residencia fiscal",
			"dividaNaoDivida": "Deuda y no deuda",
			"sujPassivoRecupIVAEstrangeiro": "Sucio.",
			"residenteNaoHabitual": "Residente no habitual",
			"domicilioFiscal": "domicilio fiscal",
			"predialNegativa": "propiedad negativa",
			"predial": "Edificio",
			"activa": "Activo",
			"pendente": "Pendiente",
			"pendenteConfirmacao": "Pendiente de confirmación",
			"expirada": "Venció",
			"anulada": "Cancelado",
			"modalMessagePedirCertidao": "¿Estás seguro de que quieres solicitar el certificado: {{name}}?",
			"comprovativoSimpleTtile": "Prueba",
			"ssSitContribInfoText": "Declaraciones de estado de cotización emitidas después del 24/10/2020",
			"viaCTTInfoText": "La aplicación sólo muestra los últimos 20 registros.",
			"alterarCredenciais": "Cambiar credenciales",
			"portalAT": "Portal de Finanzas (AT)",
			"portalSSD": "Seguridad Social Directa (SSD)",
			"portalViaCTT": "Vía CTT",
			"cadernetaPredial": "{{title}} - Folleto de propiedad - Artículo: {{article}} - Año: {{year}}",
			"emptyDocs": "No has seleccionado ningún documento.",
			"associarDocumentoContabDigital": "Agregar documento a la contabilidad digital",
			"associarDocumentosContabDigital": "Agregar documentos a la contabilidad digital",
			"successAssociarComprovativoContabDigital": "Documento agregado exitosamente",
			"verDocumento": "Ver documento"
		},
		"progress": {
			"message": "Asociar documentos con contabilidad digital"
		},
		"modules": {
			"iuc": {
				"emitir": {
					"catA": "Categoría A - Vehículos ligeros de pasajeros y mixtos con un peso bruto no superior a 2500 kg (adquiridos antes del 30 de junio de 2007)",
					"catB": "Categoría B - Vehículos ligeros de pasajeros y mixtos con un peso bruto no superior a 2500 kg (adquiridos a partir del 1 de julio de 2007)",
					"catE": "Categoría E - Motocicletas y equivalentes",
					"catC": "Categoría C - Mercancías Mixtas y Vehículos Ligeros con un peso bruto superior a 2500 kg por Cuenta Propia",
					"catD": "Categoría D - Mercancías Mixtas y Vehículos Ligeros con un peso bruto superior a 2500 kg para Cuenta Subcontratada"
				}
			}
		}
	},
	"efatura": {
		"showComunicacaoInexistencia": "Sin facturación (PRUEBA)",
		"summary": "Resumen del archivo",
		"summaryErros": "Advertencias/Errores de archivos",
		"invoiceTotal": "Número de facturas",
		"totalCredit": "Crédito completo",
		"totalDebit": "Deuda total",
		"summaryByDoc": "Resumen por tipo de documento",
		"summaryByType": "Resumen por código de IVA",
		"btnComunicaDocs": "Comunicar documentos",
		"btnSaftGlobal": "SAF-T Global",
		"btnSaftAutofaturacao": "Autofacturación SAF-T",
		"btnLoginWebserviceAT": "AT Webservice - Comunicación de facturación",
		"btnGenerateDoc": "Generar factura electrónica",
		"btnDownload": "Descargar",
		"btnComunicar": "Comunicar",
		"btnComunicaInexistenciaFaturacao": "Reportar falta de facturación",
		"btnMonthsJan": "Enero",
		"btnPesquisar": "buscar",
		"naoGeradoString": "(no generado)",
		"geradoString": "(generado)",
		"inexistenciaFaturacaoModalTitle": "Reportar falta de facturación",
		"btnRemoverInexistencia": "Eliminar sin facturación",
		"table": {
			"tipoSAFT": "Tipo",
			"docNumber": "Doc no.",
			"nif": "NIF",
			"nomeClifo": "Nombre",
			"valorIVA": "Impuesto total",
			"valorTributavel": "Valor imponible",
			"valorParaSAFT": "Valor SAFT",
			"valorTotal": "Monto total",
			"estado": "Estado",
			"comunicado": "Anuncio",
			"erros": "Errores/Advertencias",
			"name": "Nombre",
			"vatValue": "valor del IVA",
			"rate": "Tasa",
			"tipoComunicacao": "Tipo de comunicación"
		},
		"tipoComunicacao": {
			"saft": "Archivo SAFT",
			"webService": "Servicio web"
		},
		"resumoFicheiro": {
			"comunicados": "Anuncios",
			"porComunicar": "Para comunicarse"
		},
		"notices": "Advertencias",
		"errors": "Errores",
		"docState": {
			"normal": "norte-normal",
			"nullified": "A - Cancelado",
			"billed": "F - Facturado"
		},
		"messageConfigLoginAt": "Los documentos de venta se configurarán para su comunicación vía webservice a eFatura.",
		"nDocsNComunicados": "Número de documentos no comunicados:",
		"messagePermissions": "Advertencia: ¡Para cambiar los campos debes tener permisos de administrador!",
		"comunicacaoRealizadaSucesso": "La comunicación se realizó con éxito.",
		"comunicaDocsModal": {
			"title": "Lista de documentos"
		},
		"geraDocModal": {
			"title": "Generar documento de factura electrónica",
			"soDocsPorComunicar": "Sólo documentos a comunicar",
			"messageTodosComunicados": "Al continuar, el archivo generado contendrá todos los ya comunicados.",
			"messageSoDocsPorComunicarFalse": "Genera un archivo con todos los documentos del periodo.",
			"messageSoDocsPorComunicarTrue": "Genera un archivo con solo los documentos a comunicar"
		},
		"mesDeclaracao": "Mes de declaración",
		"confirmComunicacaoCheckLabel": "Confirmo la intención de comunicar la falta de facturación.",
		"confirmAnulacaoComunicacaoCheckLabel": "Confirmo la intención de eliminar sin facturación",
		"comunicacaoSuccessMsg": "La comunicación de no facturación se realizó con éxito.",
		"anulacaoComunicacaoSuccessMsg": "La cancelación de la comunicación de no facturación se realizó con éxito."
	},
	"efaturacirculacaologin": {
		"title": "Iniciar sesión en AT-Doc.",
		"saveTitle": "Ahorrar",
		"fields": {
			"username": "Nombre de usuario (NIF/ID de usuario)",
			"password": "Contraseña"
		},
		"messages": {
			"success": "Inicie sesión exitosamente.",
			"errorUsernameEmpty": "Nombre de usuario vacío.",
			"errorPasswordEmpty": "Contraseña vacía.",
			"noPermissions": "Advertencia: ¡Para cambiar valores debes tener permisos de administrador!"
		}
	},
	"efaturalogin": {
		"title": "Iniciar sesión con factura electrónica",
		"saveTitle": "Ahorrar",
		"fields": {
			"tipoComunicacao": "Tipo de comunicación AT",
			"username": "Nombre de usuario (NIF/ID de usuario)",
			"password": "Contraseña"
		},
		"messages": {
			"success": "Inicie sesión exitosamente.",
			"errorUsernameEmpty": "Nombre de usuario vacío.",
			"errorPasswordEmpty": "Contraseña vacía.",
			"noPermissions": "Advertencia: ¡Para cambiar valores debes tener permisos de administrador!"
		}
	},
	"emailclifos": {
		"sendMail": {
			"title": "Enviar correo electrónico: {{nAccount}} - {{nameAccount}}",
			"messageSuccess": "El correo electrónico se envió correctamente a {{emailList}}",
			"sendAllCheckbox": "Quiero enviar automáticamente los correos electrónicos restantes.",
			"sendAllMessage": "Enviar correos electrónicos",
			"sendAllSuccess": "Correos electrónicos enviados exitosamente",
			"btn": {
				"ignore": "Ignorar",
				"sendMail": "Enviar correo electrónico",
				"send": "para enviar"
			},
			"tooltips": {
				"ignore": "Cuando se ignora, pasa al siguiente correo electrónico sin enviarlo.",
				"cancel": "Al darse de baja, se dará de baja este y los siguientes correos electrónicos",
				"cancelCheck": "Cuando canceles, no podrás continuar y los correos electrónicos anteriores serán cancelados."
			},
			"messages": {
				"success": "Correo electrónico enviado correctamente"
			},
			"email": "Correo electrónico",
			"assunto": "Sujeto",
			"anexos": "Adjuntos",
			"texto": "Texto"
		}
	},
	"empresabloqueada": {
		"title": "La empresa no está disponible",
		"message": "<strong>La empresa no está disponible</strong>, comuníquese con el administrador del sistema para obtener más información.",
		"changeCompany": "Cambiar de empresa",
		"reasons": {
			"companyEmptyRoles": "Empresa no disponible, porque no tiene acceso a ningún portal."
		}
	},
	"empresas": {
		"title_detail": "Empresa {{id}}",
		"title_new": "Nueva empresa",
		"title_plural": "Empresas",
		"pesquisa": "buscar",
		"saved": "Empresa {{id}}, guardada correctamente",
		"error": "Empresa {{id}}, no se puede guardar.",
		"fields": {
			"primeiroMesContab": "Primer mes contable",
			"nempresa": "empresa no.",
			"nempresaPlaceholder": "Número de empresa",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre de empresa",
			"nomeAlternativo": "Nombre comercial",
			"morada": "Familiar",
			"codpostal": "Código Postal",
			"localidade": "Ubicación",
			"nif": "número de contribuyente",
			"pais": "País",
			"moeda": "Acuñar",
			"tipoEmpresa": "Tipo de empresa",
			"alvara": "Permiso",
			"textoRelatorio": "Texto del informe",
			"textoRelatorioPlaceholder": "(Introduzca aquí el IBAN de la empresa, por ejemplo)",
			"capitalSocial": "Capital social",
			"telefone": "Teléfono",
			"email": "Correo electrónico",
			"web": "dirección web",
			"repFinancas": "Servicio de finanzas",
			"tipoContab": "Tipo de contabilidad",
			"regimeIRC": "Régimen IRC/IRS",
			"regimeIva": "Régimen del IVA",
			"periodoIva": "Período de IVA",
			"anoCursoIRC": "Año del curso IRC",
			"periodoContabilistico": "Periodo contable",
			"tipoAnalitica": "Analítica",
			"capPublico": "Capital público",
			"capPrivado": "capital privado nacional",
			"capEstrangeiro": "Capital extranjero",
			"capPublicoPerc": "% Capital público",
			"capPrivadoPerc": "% Capital privado",
			"capEstrangeiroPerc": "% Capital extranjero",
			"nomeComarca": "nombre del condado",
			"nomeConservatoria": "Nombre del conservatorio",
			"registoConservatoria": "Inscripción en la oficina de registro.",
			"nifCC": "Número de contribuyente del contador certificado",
			"nOCC": "Número de registro del contador en la OCC.",
			"nifAdmin": "Número de contribuyente del representante legal",
			"nContribSegSocial": "Número de contribuyente en sec.",
			"cae": "CAE",
			"atividadeprincipal": "Actividad principal",
			"seMesCivilDifPerioComecaAnoSeg": "Si el mes calendario es diferente del período, el año comienza en el año siguiente.",
			"concelhoEmp": "Condado",
			"telemovel": "Teléfono móvil",
			"mostraTarifasNasListagens": "Mostrar tipo de tarifa de línea telefónica en listados (para contacto con el consumidor)",
			"codNatJuridica": "código nativo",
			"codAssocPatronal": "código de asociación de empleadores",
			"codddccff": "código de distrito"
		},
		"steps": {
			"dadosGerais": "Datos generales",
			"contactos": "Contactos",
			"dadosContab": "Datos contables y fiscales",
			"logotipo": "Logo",
			"dadosRH": "Datos para RR.HH."
		},
		"messages": {
			"success": {
				"save": "Grabado con éxito",
				"saveLogotipo": "Logotipo cargado exitosamente.",
				"deleteLogoTipo": "Logotipo eliminado correctamente."
			},
			"error": {
				"save": "Error al grabar"
			},
			"logoAlt": "Logotipo de la empresa",
			"changeLogo": "Cambiar logotipo",
			"uploadLogo": "Subir logotipo",
			"deleteLogo": "Eliminar logotipo",
			"changedperiocontabfirstmonth": "Los cambios se guardaron correctamente.",
			"configDivulgacaoTiposTarifa": "Configurar la divulgación del tipo de tarifa"
		},
		"changePerioContabTooltip": "Establecer el año calendario diferente del año fiscal",
		"modal": {
			"confirmTitle": "Análisis: inicie siempre análisis",
			"confirmMessage": "¿Quieres configurar el plan de cuentas de análisis de cuentas?",
			"configanocivildiftitle": "Configuración del año calendario diferente al año fiscal",
			"tarifas": {
				"modalTitle": "Divulgación de tipos de tarifas",
				"title1": "Publicación de líneas telefónicas de contacto con el consumidor",
				"title2": "Decreto-Ley N° 59/2021 (vigente desde el 11-01-2021)",
				"redeFixaNacional": "(Red fija nacional)",
				"redeMovelNacional": "(Red móvil nacional)"
			}
		},
		"maintenance": {
			"analiticaconfig": "Configurar cuentas de análisis"
		},
		"storewelcome": {
			"title": "Bienvenido a la Nube CentralGest",
			"message1": "Antes de comenzar a utilizar la aplicación es necesario <strong>rellenar los datos identificativos de la empresa</strong>.",
			"message2": "Por favor complete los siguientes campos",
			"required": {
				"nif": "Número de contribuyente de la empresa"
			},
			"errors": {
				"nif": "Por favor introduzca el número fiscal de la empresa."
			}
		}
	},
	"entidadeexterna": {
		"title_detail": "Entidad Externa",
		"title_new": "Nueva entidad externa",
		"title_plural": "Entidades Externas",
		"pesquisa": "buscar",
		"deleted": "Entidad externa eliminada exitosamente.",
		"saved": "Entidad externa guardada exitosamente.",
		"error": "La entidad externa no se puede guardar.",
		"fields": {
			"entidadeExternaID": "",
			"codigo": "Código",
			"nome": "Nombre",
			"morada": "Dirección 1",
			"morada2": "Dirección 2",
			"nContribuinte": "número de contribuyente",
			"contacto": "Contacto",
			"email": "Correo electrónico",
			"tipoEmpresa": "Tipo de empresa",
			"tipoContabilidade": "Tipo de contabilidad",
			"nEmpresaGabinete": "empresa de gabinetes",
			"periodoIVA": "Período de IVA",
			"regimeIRC": "Régimen IRC/IRS"
		}
	},
	"entidadehabitual": {
		"desconhecido": "Desconocido",
		"clientes": "Clientes",
		"fornecedores": "Proveedores"
	},
	"erps": {
		"title_detail": "ERP {{id}}",
		"title_new": "Nuevo ERP",
		"title_plural": "ERP",
		"pesquisa": "buscar",
		"saved": "ERP {{id}}, guardado exitosamente",
		"error": "ERP {{id}}, no se puede guardar.",
		"fields": {
			"apiUrl": "URL de la API",
			"apiUrlPlaceholder": "URL de la API",
			"name": "nombre del ERP",
			"nomePlaceholder": "nombre del ERP",
			"centralGestId": "ID de CentralGest"
		}
	},
	"erputilizadores": {
		"title_detail": "Usuario {{id}}",
		"title_new": "Nuevo usuario",
		"pesquisa": "buscar",
		"saved": "Usuario {{id}}, guardado exitosamente",
		"error": "Usuario {{id}}, no se puede guardar.",
		"removecompany": "Eliminar empresa",
		"fields": {
			"erp": "ERP",
			"nutilizador": "Número de usuario",
			"nutilizadorPlaceholder": "Número de usuario",
			"nomeUtilizador": "Nombre de usuario",
			"nomeUtilizadorPlaceholder": "Nombre de usuario",
			"nomeCompleto": "nombre completo",
			"nomeCompletoPlaceholder": "nombre completo"
		},
		"companies": {
			"title": "Empresas",
			"available": "Disponible",
			"selected": "Seleccionado"
		},
		"addMultiEmpresa": "Agregar varias empresas",
		"options": {
			"showApi": "Mostrar visitas a la API"
		},
		"editRow": {
			"title": "Editar ERP",
			"erp": "ERP",
			"nEmpresa": "Empresa No.",
			"nUtilizador": "Usuario No.",
			"ativo": "Activo",
			"codEmpregado": "Código de empleado",
			"disponiveis": "Disponible",
			"temAcesso": "tener acceso",
			"naoTemAcesso": "Sin acceso"
		}
	},
	"estabelecimento": {
		"title_detail": "Establecimiento {{id}}",
		"title_new": "Nuevo Establecimiento",
		"title_plural": "Establecimientos",
		"pesquisa": "buscar",
		"saved": "Establecimiento {{id}}, guardado exitosamente.",
		"error": "Establecimiento {{id}}, no se puede guardar.",
		"deleted": "Establecimiento {{id}}, eliminado exitosamente",
		"fields": {
			"nEstabElec": "Código.",
			"nEstabElecPlaceholder": "Código de establecimiento",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del establecimiento",
			"designaBreve": "Designar breve",
			"designaBrevePlaceholder": "Designar breve",
			"morada": "Familiar",
			"moradaPlaceholder": "Familiar",
			"localidade": "Ubicación",
			"localidadePlaceholder": "Ubicación",
			"nPostal": "Código Postal",
			"nPostalPlaceholder": "Código Postal",
			"distrito": "Distrito",
			"concelho": "Condado",
			"freguesia": "Parroquia",
			"freguesiaPlaceholder": "Parroquia",
			"caeaCTPrinc": "caeaCTPrinc",
			"caeaCTPrincPlaceholder": "número CAE",
			"codDdCcFf": "Código de distrito, conc., freg.",
			"codDdCcFfPlaceholder": "Código de distrito, conc., freg.",
			"nEstabQPessoal": "Establecimiento nro.",
			"nEstabQPessoalPlaceholder": "Establecimiento nro.",
			"nEstabSegSocial": "Establecimiento nro.",
			"nEstabSegSocialPlaceholder": "Establecimiento nro.",
			"activPrincip": "Actividad principal",
			"activPrincipPlaceholder": "Actividad principal",
			"nContRib": "número de contribuyente",
			"nContRibPlaceholder": "número de contribuyente",
			"nCaixaPrev": "Número de fondo de pensiones",
			"codCCt": "Código CCT",
			"telefone": "Teléfono",
			"telefonePlaceholder": "Teléfono",
			"calenCABId": "Calendario",
			"sede": "Sed"
		}
	},
	"estadoconta": {
		"ativa": "Activo",
		"desactivada": "Desactivado",
		"bloqueada": "Obstruido"
	},
	"estadoemp": {
		"ativo": "Activos",
		"inativo": "Inactivo",
		"ambos": "Todo"
	},
	"estadoregisto": {
		"ativo": "Activo",
		"inativo": "Inactivo"
	},
	"estados": {
		"title_detail": "Estado {{id}}",
		"title_plural": "Estados",
		"pesquisa": "buscar",
		"fields": {
			"codEstado": "código de estado",
			"nome": "Nombre",
			"nomeAbr": "Abreviatura",
			"tipoEstado": "tipo de estado"
		}
	},
	"estadosValidacao": {
		"data": {
			"naoPossivel": "No se pueden crear lanzamientos",
			"criadoNaoValidado": "Versión creada, pero aún no validada",
			"criadoValidado": "Lanzamiento creado, pero ya ha sido validado"
		}
	},
	"exportacaoCompras": {
		"fields": {
			"documentosPorExportar": "Enviar sólo documentos no exportados"
		},
		"dataGrid": {
			"doc": "Doc.",
			"nDocExterno": "Doc no.",
			"nFornecedor": "Proveedor No.",
			"nomeFornecedor": "Nombre del proveedor",
			"exportado": "Exportado",
			"exportId": "ID de exportación"
		},
		"config": {
			"title": "Configuración - Exportación de compras",
			"familiasDataGrid": {
				"title": "Familias",
				"nFamCG": "número de familia CG",
				"nomeFamCG": "apellido CG",
				"idFamGeo": "familia geofolia",
				"nomeFamGeo": "Apellido Geofolia",
				"operacao": "Operación",
				"legend": {
					"naoConfiged": "No configurado",
					"campoEdit": "Campo editable"
				}
			},
			"docsDataGrid": {
				"titleDocsCompras": "Documentos de compra",
				"titleDocsNotaCredito": "Documentos de notas de crédito",
				"id": "identificación del documento",
				"name": "Nombre",
				"desc": "Descripción",
				"contectMenu": {
					"desmarcaDocsExported": "Deseleccionar documentos exportados (seleccionados)",
					"abrirDoc": "Abrir documento"
				}
			}
		},
		"mensagens": {
			"configSuccess": "Configuración guardada exitosamente",
			"okModalTitle": "Deseleccionar la exportación de documentos seleccionados",
			"okModalMensagem": "¿Está seguro de que desea anular la selección de la exportación de los documentos seleccionados?",
			"documentosDupsNotAllowed": "El documento '{{docName}}' ya está configurado",
			"linhaObrigatoria": "Obligatorio seleccionar un documento para agregar exitosamente",
			"familiasDupsNotAllowed": "Ya hay una familia CentralGest configurada con el id '{{id}}'"
		}
	},
	"extractosartigos": {
		"title_plural": "Extractos de artículos",
		"pesquisa": "buscar",
		"fields": {
			"nArtigo": "Código del artículo",
			"nomeArtigo": "Nombre del artículo",
			"dataDoc": "Fecha del doctor.",
			"nDocAsString": "Doc no.",
			"nDocfa": "Tipo de documento.",
			"descricaoDocfa": "Descripción del tipo de documento.",
			"nArmazem": "código de almacén",
			"nomeArmazem": "Nombre del almacén",
			"nLoteEspecifico": "Lote específico",
			"preco": "Precio",
			"valorEntrada": "Valor de entrada",
			"valorSaida": "valor de salida",
			"saldoValor": "Valor del saldo",
			"qtdEntrada": "Cantidad de entrada",
			"qtdSaida": "Cantidad de salida",
			"saldoQtd": "Cant. de saldo",
			"nConta": "Nro. de cuenta",
			"nomeClifo": "Nombre del cliente/proveedor",
			"unidadeMov": "Unidad de movimiento."
		},
		"btn": {
			"options": {
				"showNArtigo": "Mostrar código.",
				"showNomeArtigo": "Mostrar nombre del artículo",
				"showDataDoc": "Muestra la fecha del documento.",
				"showNDocAsString": "Muestra el documento no.",
				"showNDocfa": "Muestra el tipo de documento.",
				"showDescricaoDocfa": "Muestra la descripción del tipo de documento.",
				"showNArmazem": "Mostrar código.",
				"showNomeArmazem": "Mostrar nombre del almacén",
				"showNLoteEspecifico": "Mostrar lote específico",
				"showPreco": "Mostrar precio",
				"showValorEntrada": "Mostrar valor de entrada",
				"showValorSaida": "Mostrar valor de salida",
				"showSaldoValor": "Muestra el valor del saldo",
				"showQtdEntrada": "Muestra cantidad.",
				"showQtdSaida": "Muestra cantidad.",
				"showSaldoQtd": "Muestra la cantidad del saldo.",
				"showNConta": "Mostrar número de cuenta",
				"showNomeClifo": "Muestra el nombre del cliente/proveedor",
				"showUnidadeMov": "Muestra la unidad de movimiento."
			}
		},
		"caption": {
			"deCodArtigo": "Código del artículo",
			"deNArmazem": "código de almacén",
			"deData": "Fecha de",
			"calcMovAntesDepoisData": "Calcula movimientos antes y después de la fecha."
		},
		"info": {
			"erroDataDeMaiorQueAte": "La fecha Desde es mayor que la fecha Hasta",
			"valormaximonarmazem": "El valor máximo para el código."
		},
		"contextmenu": {
			"openDocCcomercial": "Abrir documento",
			"openArtigo": "Abrir hoja de artículo"
		}
	},
	"extratosClifos": {
		"radical": "Radical de cuentas",
		"table": {
			"fields": {
				"nConta": "Cuenta",
				"nomeConta": "Nombre de cuenta",
				"nDocExterno": "Documento externo.",
				"dataVenc": "Fecha de expiración",
				"descricao": "Descripción",
				"valorDebito": "Monto del débito",
				"valorCredito": "Valor del crédito",
				"porPagar": "No pagado",
				"dataDoc": "Fecha del doctor.",
				"moeda": "Acuñar",
				"nCCusto": "NCCosto",
				"valorDebitoSegMoeda": "Monto del débito seg.",
				"valorCreditoSegMoeda": "Valor del crédito seg.",
				"cambioSegMoeda": "Intercambio lunes.",
				"descritivo": "Descriptivo",
				"nDocumento": "Número de documento",
				"periodo": "Período",
				"nDiario": "A diario"
			}
		},
		"btn": {
			"processar": "Proceso"
		}
	},
	"extratosDT": {
		"fields": {
			"periodo": "Período",
			"diario": "A diario",
			"nDocInterno": "Doc.",
			"nDocExterno": "Documento externo.",
			"dataVencimento": "Fecha de vencimiento",
			"descricao": "Descripción",
			"valorDebito": "Deuda",
			"valorCredito": "Crédito",
			"porPagar": "No pagado",
			"dataDoc": "Fecha del doctor.",
			"moeda": "Acuñar",
			"debitoEmDivisa": "Deuda en moneda extranjera",
			"creditoEmDivisa": "Crédito en moneda",
			"porPagarEmDivisa": "Para pagar en moneda",
			"cCusto": "C. Costo",
			"cambioSegMoeda": "Intercambio en lun.",
			"debitoSegMoeda": "Débito en seg.",
			"creditoSegMoeda": "Crédito en seg.",
			"nLanc": "Lanzamiento no.",
			"dataDocExterno": "Fecha del doctor.",
			"cambio": "Intercambio",
			"debitoME": "Débito en ME",
			"creditoME": "Crédito en ME",
			"abreviaturaMoeda": "Acuñar"
		},
		"config": {
			"showMoeda": "Mostrar columna de moneda",
			"showDebitoEmDivisa": "Mostrar columna de débito de moneda",
			"showCreditoEmDivisa": "Mostrar columna de crédito en moneda",
			"showPorPagarEmDivisa": "Mostrar columna de moneda impaga",
			"showCCusto": "Mostrar columna de c.",
			"showCambioSegMoeda": "Mostrar la columna de tipo de cambio en la seg.",
			"showDebitoSegMoeda": "Mostrar columna de débito en seg.",
			"showCreditoSegMoeda": "Mostrar columna de crédito en seg.",
			"showPorPagar": "Mostrar columna no pagada",
			"showDataVencimento": "Mostrar columna de fecha de vencimiento"
		},
		"filterFields": {
			"nConta": "Cuenta",
			"deData": "Fecha de"
		},
		"btn": {
			"processar": "Proceso"
		},
		"msg": {
			"contaInvalida": "Campo de cuenta no válido.",
			"naoMostraDetalhe": "No es posible presentar los detalles de este documento, ya que es un documento contable."
		},
		"caption": {
			"recibos": "Ingresos"
		},
		"modal": {
			"title": "Estado de cuenta {{tipo}}"
		},
		"export": {
			"account": "Cuenta"
		},
		"print": {
			"title": "Imprimir estado de cuenta",
			"obter": "para obtener"
		}
	},
	"familias": {
		"title_detail": "Familia {{id}}",
		"title_new": "Nueva familia",
		"title_plural": "Familias",
		"pesquisa": "buscar",
		"deleted": "Familia eliminada correctamente.",
		"saved": "Familia {{id}}, guardada exitosamente.",
		"error": "Familia {{id}}, no se puede guardar.",
		"fields": {
			"nFamilia": "Código.",
			"nFamiliaPlaceholder": "codigo familiar",
			"nome": "Nombre",
			"nomePlaceholder": "Apellido",
			"codtipoartigo": "Código de tipo de artículo",
			"grandesfamilias": "código gr.",
			"subfamilia": "Código de subfamilia",
			"unimovimento": "Naciones Unidas.",
			"contaVenda": "número de cuenta de ventas",
			"contaCompra": "Número de cuenta de compra",
			"metodoCalcPrecVend": "Método de cálculo.",
			"nomeIvaVenda": "IVA en venta",
			"nomeIvaCompra": "IVA en la compra",
			"movimentaStock": "Mover acciones",
			"criterio": "Criterio de valoración",
			"naoPExistNeg": "No permite stock negativo"
		},
		"metodos": {
			"margemprecovenda": "Margen sobre el precio de venta",
			"margemprecocompra": "Margen sobre el precio de compra",
			"customedioponderado": "Costo promedio ponderado",
			"lifo": "lifo",
			"fifo": "Fifo",
			"custopadrao": "Costo estándar",
			"customedio": "Costo promedio",
			"loteespecifico": "Lote específico"
		},
		"btn": {
			"buttonOptions": "Opciones",
			"verContas": "Muestra cuentas de ventas y compras.",
			"esconderContas": "Ocultar cuentas"
		}
	},
	"faturacaoContratos": {
		"btn": {
			"search": "buscar",
			"processar": "Proceso",
			"gestaoAvencas": "Gestión de pagos facturados.",
			"faturar": "Factura"
		},
		"fields": {
			"sel": "Sel.",
			"classeContrato": "Clase",
			"tipoContrato": "Tipo",
			"nContrato": "Número de recibo",
			"versao": "Versión",
			"descricao": "Descripción",
			"nClifo": "Nro. de cuenta",
			"nomeClifo": "Nombre",
			"totalLiquido": "Total neto",
			"nFaturasEmAberto": "Número de facturas abiertas",
			"dataUltimoPagamento": "última fecha de pago",
			"dataUltimaFatura": "Fecha de la última factura",
			"obsUltimaFatura": "Notas de la última factura",
			"temDebitoDirectoStr": "Cliente de domiciliación bancaria",
			"temErro": "con error",
			"contratoAsString": "Pacto"
		},
		"caption": {
			"classeContrato": "Clase",
			"tipoClasseContrato": "Tipo",
			"nZonaDe": "código de zona",
			"nContaDe": "Número de cuenta",
			"dataDoc": "Fecha del doctor.",
			"dataVenc": "Fecha de expiración",
			"dataInicTransp": "Fecha de inicio.",
			"horaInicTransp": "Hora de inicio.",
			"txtContrato": "Observaciones",
			"txtCtrNoCabecalho": "Colocar en el pie de página",
			"txtCtrNoCabecalhoTooltip": "Complete el campo de comentarios en el encabezado del documento.",
			"txtCtrNaLinha": "Colocar en el cuerpo",
			"txtCtrNaLinhaTooltip": "Después de la última línea del documento.",
			"origemTxtContrato": "Origen del texto",
			"localizacao": "Ubicación"
		},
		"config": {
			"verDataInicTransp": "Mostrar fecha de inicio.",
			"verHoraInicTransp": "Mostrar hora de inicio.",
			"verZonaDeAte": "Mostrar filtro por zona",
			"verErrosContratos": "Mostrar errores de acuerdo",
			"verContasDeAte": "Mostrar filtro por cuenta",
			"verDataVenc": "Mostrar fecha de vencimiento."
		},
		"table": {
			"semdados": "Sin datos"
		},
		"messages": {
			"success": "Convenios facturados exitosamente.",
			"sucessContratosFaturados": "{{nregistos}} acuerdos facturados correctamente.",
			"semClasseSelecionada": "No se seleccionó ninguna clase de suscripción.",
			"semTiposSelecionado": "Debes seleccionar al menos un tipo de contrato",
			"semDataDocDeSelecionado": "Debes completar la fecha del documento inicial.",
			"semDataDocAteSelecionado": "Debes completar la fecha del documento final.",
			"semNContaDeSelecionado": "Debe completar la cuenta inicial.",
			"semNContaAteSelecionado": "Debe completar la factura final.",
			"infoFaturacaoAuto": "Para utilizar la facturación automática, configura el tipo de pago aquí"
		},
		"errors": {
			"classeContratoNaoPodeSerNula": "La clase de contrato no puede ser nula.",
			"naoExisteTipoContratoSelecionado": "No hay ningún tipo de suscripción seleccionado.",
			"naoExisteContratoSelecionadoParaFaturar": "No hay ningún contrato seleccionado para facturar.",
			"contratosAFaturarComErros": "Hay {{nregistrations}} acuerdo(s) para facturar por error."
		},
		"legend": {
			"contratoComErro": "Acuerdo con error"
		},
		"info": {
			"dadosProcessamento": "Proceso de datos"
		},
		"data": {
			"tipoDeAvenca": "Tipo de contrato",
			"manual": "Manual"
		},
		"text": {
			"dataParaProc": "Fecha de procesamiento"
		}
	},
	"gestaoContratos": {
		"title": "Gestión de pagos facturados.",
		"filters": {
			"nClasseContrato": "Clase",
			"nTiposContrato": "Tipo",
			"dataDocDe": "Fecha del doctor.",
			"nContaDe": "Número de cuenta",
			"estadoDoc": "Estado",
			"estadosDoc": {
				"encerrados": "Cerrado",
				"naoEncerrados": "No cerrado"
			}
		}
	},
	"faturacaoContratosLog": {
		"title": "Procesamiento de pactos",
		"tabs": {
			"faturados": {
				"title": "facturado",
				"fields": {
					"contratoAsString": "Pacto",
					"nClasseCtr": "Clase",
					"nTipoContrato": "Tipo",
					"nContrato": "Número de recibo",
					"descricao": "Descripción",
					"nConta": "Cliente",
					"contaNome": "Nombre del cliente",
					"dataDoc": "Fecha del doctor.",
					"nDocAsString": "Documento",
					"email": "Correo electrónico",
					"emailEnviado": "Enviado",
					"docJaImprimido": "Documento impreso"
				},
				"btn": {
					"previsualizar": "Avance",
					"encerrarImprimir": "Cerrar e imprimir/enviar",
					"encerrarTudo": "Cerrar documentos",
					"enviarFatEletronica": "Enviar facturas",
					"editarDocumento": "Editar documento",
					"visualizarDocumento": "Ver documento"
				}
			},
			"erros": {
				"title": "No facturado",
				"fields": {
					"contratoAsString": "Pacto",
					"nClasseCtr": "Clase",
					"nTipoContrato": "Tipo",
					"nContrato": "Número de recibo",
					"descricao": "Descripción",
					"nConta": "Cliente",
					"contaNome": "Nombre del cliente"
				},
				"warningMsg": {
					"naoFaturados": "Hay pagos que no han sido facturados",
					"consulteNaoFaturados": "Hay pagos que no han sido facturados, ver la pestaña de no facturados"
				}
			}
		},
		"success": {
			"documentosEncerrados": "Los acuerdos facturados se cerraron exitosamente.",
			"encerradosNumDocumentos": "Se cerraron {{value}} documentos correctamente.",
			"emailsEnviados": "{{value}} correos electrónicos enviados correctamente."
		},
		"errors": {
			"documentoJaEncerrado": "El documento {{nDoc}} ya está cerrado.",
			"emailsNaoEnviados": "No se envió ningún correo electrónico.",
			"naoConfigurados": "No hay correos electrónicos configurados o los documentos no están cerrados.",
			"erroAEncerrarDoc": "Error al cerrar el documento",
			"erroAEncerrarDocs": "Error al cerrar documentos",
			"erroAEnviarEmail": "Error al enviar correo electrónico",
			"naoExistemItensSelecionados": "No hay elementos seleccionados",
			"documentosEnvioEmailNaoEncerrados": "Los documentos del acuerdo seleccionados para ser enviados por correo electrónico no están cerrados"
		},
		"info": {
			"emailsEnviados": "Los documentos seleccionados ya han sido enviados por correo electrónico.",
			"documentosEncerrados": "Los documentos seleccionados se cierran.",
			"docsNaoEncerradosSelecionadosParaEnvioEmail": "Hay documentos sin cerrar para los acuerdos seleccionados y no se enviará ningún correo electrónico a estos clientes."
		},
		"confirm": {
			"emailsEnviados": "Los documentos ya han sido enviados por correo electrónico, ¿quieres volver a enviarlos?"
		},
		"okCancel": {
			"encerrardocs": {
				"title": "Cerrar documentos",
				"message": "¿Está seguro de que desea cerrar los documentos seleccionados?"
			},
			"enviardocs": {
				"title": "Enviar facturas de contrato por correo electrónico",
				"message": "¿Está seguro de que desea enviar los documentos de los acuerdos seleccionados?",
				"message2": "Hay documentos del pacto seleccionados que ya han sido enviados."
			}
		}
	},
	"faturacaoeletronicaconfiguracoes": {
		"fields": {
			"nDocFa": "Escriba Doc.",
			"nome": "Nombre",
			"estado": "Estado",
			"reportDefault": "Impreso por defecto",
			"nConta": "Nro. de cuenta",
			"emails": "Correos electrónicos",
			"email": "Correo electrónico",
			"emailgeral": "correo electrónico general"
		},
		"legend": {
			"gravado": "Grabado",
			"porgravar": "Para grabar"
		},
		"tabs": {
			"tiposdocumento": "Tipos de documentos",
			"clientes": "Clientes",
			"certificado": "Certificado"
		},
		"text": {
			"certificados": "Certificados",
			"certificadosCount": "Certificados ({{total}})",
			"pendentes": "Colgantes",
			"pendentesCount": "Pendiente ({{total}})",
			"semFaturacaoEletronica": "Sin factura electrónica",
			"comFaturacaoEletronica": "Con factura electrónica",
			"obterAtributos": "Obtener atributos",
			"authenticate": "Autenticar",
			"register": "Registro",
			"tipoCertificadoNotSelected": "La plataforma de firma de documentos electrónicos no está definida, defínela en alguna de las casillas disponibles a continuación.",
			"selectedTipoCertificado": "Facturación electrónica activa, la firma electrónica de los documentos definida es: \"{{plataforma}}\".",
			"setTipoCerficadoAtivo": "Establecer como plataforma de suscripción",
			"tipoCerficadoAtivo": "Definida como una plataforma de suscripción",
			"faturacaoEletronicaNaoAtiva": "Factura electrónica sin plataforma de suscripción configurada",
			"infoDigitalSignMessage": "Para poder utilizar el proveedor de Señal Digital es necesario obtener los datos del Autorizador para poder habilitar la firma.",
			"infoSAFEMessage": "Para utilizar el proveedor SAFE es necesario tener acceso al portal Autentição.GOV y tener atributos profesionales.",
			"faq": "Accede a nuestras preguntas frecuentes para cualquier duda.",
			"allTiposDocs": "Todos los tipos de documentos SAF-T (FT, FR, ND, NC) se seleccionan para la firma de documentos digitales.",
			"allClientes": "Todos los clientes son seleccionados para firmar documentos digitales.",
			"savedInfoIsAnonimous": "Nota: Esta información se almacenará de forma anónima.",
			"checkAcessoSAFEMsg": "El Servicio de Firma Electrónica de Facturas (SAFE) permite al administrador, gerente o director de una empresa firmar facturas electrónicas en un software de integración integrado con SAFE.",
			"checkAcessoSAFE": "¿Tienes acceso al portal Authentication.GOV y tienes atributos profesionales?",
			"apagar": "Para apagar",
			"dataValidade": "Fecha de expiración"
		},
		"messages": {
			"clientAddedSucess": "¡Cliente añadido!",
			"clientEditedSucess": "¡Cliente editado!",
			"emailsEmpty": "Debes asignar al menos un correo electrónico.",
			"warningAddDigitalSign": "Ya hay cuentas de suscripción definidas.<br/>¿Está seguro de que desea crear una cuenta nueva?",
			"successAddSafeCertificate": "Certificado SAFE agregado exitosamente.",
			"successSAFEObterAtributos": "Atributos del certificado SAFE obtenidos con éxito.",
			"successAddDigitalSignAuthorizer": "Certificado de firma digital agregado exitosamente.",
			"successSetTipoCerficadoAtivo": "Plataforma de firma electrónica definida con éxito.",
			"authenticateDigitalSignAuthorizer": "Certificado de firma digital autenticado exitosamente.",
			"safependentinfo": "Debes tener asignados los atributos profesionales que permitan la firma de facturas electrónicas.",
			"promptDeleteDigitalSignAuthData": "¿Estás seguro de que quieres eliminar tus datos de acceso?",
			"successDeleteDigitalSignAuthorizer": "El certificado de firma digital se eliminó correctamente.",
			"successConfigSaved": "Configuración guardada exitosamente."
		},
		"errors": {
			"emptyNewSafeCertificateUrl": "No se puede obtener la dirección para agregar un certificado SAFE.",
			"emptyNewDigitalSignUrl": "No se puede obtener la dirección para agregar un certificado de firma digital.",
			"safeObterAtributos": "Se produjo un error desconocido al intentar obtener los atributos del certificado SAFE.",
			"invalidNomeConta": "Nombre de cuenta no válido, solo puede contener caracteres y números y no puede contener espacios.",
			"noAuthFound": "No se encontró ninguna autenticación válida"
		},
		"safe": {
			"nomeDaConta": "Nombre",
			"nomeUtilizador": "Dueño",
			"accountExpiration": "Validez",
			"autenticacaoGov": "Abrir autenticación.Gov",
			"state": "Estado",
			"errors": {
				"semItems": "No tiene ningún titular de atributo profesional registrado"
			}
		},
		"digitalsign": {
			"nomeAutorizador": "Nombre del autorizador",
			"idAutorizador": "ID del autorizador",
			"nomeUtilizador": "Dueño",
			"secret": "Secreto",
			"accountExpiration": "Validez",
			"usoGlobal": "Uso global",
			"dropdown": {
				"cliente": "Área de clientes",
				"certificado": "Certificado abierto",
				"gestao": "Gestión de certificados"
			},
			"errors": {
				"nomeAutorizadorNulo": "El nombre del autorizador no puede ser nulo",
				"idAutorizadorNulo": "El ID del autorizador no puede ser nulo",
				"secretNula": "El secreto no puede ser nulo."
			}
		},
		"addsafecertificadomodal": {
			"title": "Agregar certificado SAFE",
			"titlePreProduction": "Añadir certificado SAFE (preproducción)",
			"fields": {
				"nomeConta": "Nombre de cuenta",
				"email": "Correo electrónico",
				"validade": "Válido hasta"
			},
			"tipoCidadao": {
				"nacional": "ciudadano nacional",
				"estrangeiro": "ciudadano extranjero"
			}
		},
		"digitalsignauthorizermodal": {
			"add": "Agregar certificado de firma digital",
			"addPreProduction": "Agregar certificado de firma digital (preproducción)",
			"update": "Editar certificado de firma digital",
			"updatePreProduction": "Editar certificado de Señal Digital (preproducción)"
		},
		"configemailsclientemodal": {
			"title": "Configurar el correo electrónico de la cuenta"
		},
		"postAddSafeCertificadoModal": {
			"message": "Espere mientras completamos el proceso de agregar un certificado SAFE...",
			"error": "Se produjo un error al completar el proceso de agregar un certificado SAFE."
		},
		"certificadoAvailable": {
			"safe": "SAFE (Servicio de firma electrónica de facturas)",
			"safeTestes": "Pruebas SAFE (Servicio de firma electrónica de facturas)",
			"digitalSign": "Señal Digital - Sello electrónico cualificado",
			"digitalSignTestes": "Señal Digital - Pruebas cualificadas de sello electrónico",
			"safeShort": "SEGURO",
			"safeFullname": "Servicio de firma electrónica de facturas",
			"digitalSignShort": "Señal digital",
			"digitalSignFullname": "Sello electrónico cualificado"
		},
		"configOptions": {
			"ambientePreProducao": "Entorno de preproducción"
		},
		"faq": {
			"dropdowntitle": "Preguntas frecuentes - Ayuda",
			"faq1": "<h3>¿Qué es una factura electrónica y para qué sirve?</h3><br><p>La tramitación de facturas en papel es un proceso costoso para las empresas, con costes para los ciudadanos y empresas que consumen recursos para la economía.",
			"faq2": "<h3>¿Qué debo hacer si la aplicación está comprometida (en términos de seguridad)?</h3><br><p>Una aplicación se considera comprometida siempre que los tokens SAFE hayan sido expuestos.</p> <p>Si la aplicación está comprometida, debe informar a AMA en menos de 4 horas (después de tener conocimiento del incidente de seguridad) y cancelar todas las cuentas dentro del mismo período.</p><p>Para hacer esto, simplemente seleccione la cuenta que fue comprometida, y haga clic derecho para seleccionar",
			"faq3": "<h3>¿Cómo debo proceder para crear una nueva cuenta de suscripción?</h3><br><p>Para crear una nueva cuenta de suscripción, proceda como se explica en la \"Guía para crear una nueva cuenta\", en el botón en la lado inferior</p>",
			"faq4": "<h3>¿Qué es el artículo 12 del Decreto-Ley 28/2019, de 15 de febrero?</h3><br><p>El artículo 12 se refiere a la emisión de facturas electrónicamente.",
			"faq5": "<h3>¿Qué es el Decreto-Ley 12/2021, de 9 de febrero?</h3><br><p>Este Decreto-Ley asegura la aplicación en el ordenamiento jurídico interno del Reglamento (UE) 910/2014, relativo a la identificación electrónica y servicios de confianza para transacciones electrónicas en el mercado interior.</p>",
			"faq6": "<h3>¿Cómo se utilizarán los tokens SAFE para realizar suscripciones?</h3><br><p>Para responder a la legislación antes mencionada, CentralGest necesita almacenar el token (clave privada) de la cuenta de suscripción creada para poder solicitar la firma de un documento de facturación.",
			"faq7": "<h3>¿Los tokens se almacenan de forma segura?</h3><br><p>Sí, los tokens se cifran utilizando un algoritmo seguro antes de almacenarse, siguiendo estándares SEGUROS, por lo que no son descifrables en una consulta a la base de datos.< /p>",
			"faq8": "<h3>¿Cuándo firma CentralGest una factura?</h3><br><p>Después de una correcta parametrización, CentralGest firma una factura cada vez que se envía por correo electrónico, o cada vez que se exporta en el módulo de impresión de documentos.</p> <p>Siempre que se va a firmar un documento, se informa previamente al usuario.</p>"
		},
		"guide": {
			"dropdowntitle": "Guía para crear una nueva cuenta",
			"title": "Flujo de creación de cuenta",
			"atributosproftitle": "Atributos profesionales",
			"atributosprof": "Antes de iniciar el proceso de creación de cuenta, deberás tener asignados los atributos profesionales que te permitan firmar facturas electrónicas.<br>Para ello deberás acceder a la web de autenticación.gov y en el área privada acceder a \"mis atributos profesionales\".",
			"novacontatitle": "Nueva cuenta",
			"novaconta": "Debes hacer clic en \"Nuevo\" para comenzar el proceso de creación de una nueva cuenta.",
			"autenticacaotitle": "Autenticación",
			"autenticacao": "Después del paso anterior, será dirigido a una página de autenticación en authentication.gov.<br>En este paso debe seleccionar el método de autenticación.",
			"autorizacaotitle": "Autorización",
			"autorizacao": "Deberá autorizar la recogida de algunos datos personales para poder continuar con el proceso.",
			"autenticacaomovel": "En este caso, al autenticarse con la Clave Móvil Digital, deberá introducir los datos necesarios para la autenticación.",
			"autenticacaomovelcod": "Debes ingresar el código de seguridad que fue enviado al celular.",
			"obteratributostitle": "Obtener atributos",
			"obteratributos": "Luego de una correcta autenticación, el proceso quedará pendiente, por lo que será necesario obtener los atributos para el certificado.",
			"certificadotitle": "Certificado",
			"certificado": "Una vez completado el proceso podremos verificar que tenemos el certificado activo."
		},
		"btn": {
			"ativar": "Activar",
			"desativar": "Desactivar"
		},
		"radioOptions": {
			"caption": "Firma de documentos comerciales para",
			"docfas": {
				"todos": "Todo tipo de documentos SAF-T (FT,FR,ND,NC)",
				"selecionados": "Sólo tipos de documentos seleccionados"
			},
			"clientes": {
				"todos": "Todos los clientes",
				"selecionados": "Sólo clientes seleccionados"
			}
		}
	},
	"faturacaopublica": {
		"tab": {
			"porComunicarManual": "Documentos.",
			"porComunicarWebServ": "Documentos.",
			"comunicadoEmValidacao": "Documentos.",
			"comunicadoComErro": "Documentos.",
			"saphety": "seguridad",
			"feap": "FEAP-eSPAP",
			"comunicados": "Anuncios"
		},
		"btn": {
			"config": "Ajustes",
			"comunicarWebServ": "Comunicarse a través del servicio web.",
			"comunicarManual": "Comunicarse manualmente",
			"enviarEmail": "Enviar correo electrónico",
			"exportarAnexos": "Exportar archivos adjuntos"
		},
		"menu": {
			"historico": "Historia",
			"comunicamanual": "Marcar como informado manualmente",
			"comunicacaomanual": "comunicación manual",
			"consutCliente": "Consultar cliente",
			"consultDoc": "Consultar documento"
		},
		"table": {
			"sel": "Sel.",
			"ndocAsStringDraftSufixo": "Documento",
			"datadoc": "Fecha del documento.",
			"nClifo": "código de cuenta",
			"nomeClifo": "Nombre de cuenta",
			"facPublicaModeloStr": "Plantilla de cara.",
			"estadoDescricao": "Observaciones",
			"semdados": "Sin datos"
		},
		"historico": {
			"title": "Historia",
			"data": "Fecha/Hora",
			"documento": "Documento",
			"estado": "Estado",
			"tipo": "Tipo",
			"codigo": "Código",
			"descricao": "Descripción",
			"nConta": "Nro. de cuenta",
			"nomeConta": "Nombre de cuenta",
			"nomeUtilizador": "Nombre de usuario"
		},
		"observacoes": {
			"title": "Observaciones",
			"observacoes": "Observaciones"
		},
		"doccomercial": {
			"title": "Doc. Comercial."
		},
		"config": {
			"title": "Configurar la factura electrónica pública",
			"utilizador": "Usuario",
			"senha": "Contraseña",
			"endereco": "URL",
			"estados": "Estados",
			"estadosConta": "Por defecto",
			"table": {
				"estado": "Estado",
				"conta": "Cuenta"
			}
		},
		"messages": {
			"refreshsuccess": "¡Actualizado con éxito!",
			"observacoesvazia": "Debes ingresar una nota.",
			"contavazia": "Tienes que crear una cuenta.",
			"estadodesc": "Estado no válido"
		},
		"modal": {
			"comunicacaomanual": {
				"title": "Comunicación manual: archivos adjuntos generados",
				"anexos": "Adjuntos",
				"btn": {
					"sendmail": "Enviar por correo electrónico",
					"export": "Exportar"
				}
			}
		},
		"estado": {
			"porComunicar": "Para comunicarse",
			"comunicadoEmValidacao": "Anuncio en validación",
			"comunicadoValidado": "Declaración validada",
			"comunicadoComErro": "Declaración de error",
			"erroTratado": "Error manejado",
			"received": "Recibió",
			"rejected": "Rechazado",
			"paid": "Pagado",
			"comunicadoEstruturaEmValidacao": "Estructura del anuncio bajo validación"
		},
		"errors": {
			"faturacaoeletronicanaoconfigurada": "Para comunicar archivos vía CIUS-PT es necesario configurar la facturación electrónica",
			"errogerarficheiros": "Error al generar archivos, confirmar configuración de facturación pública y facturación electrónica"
		}
	},
	"fct": {
		"tabEmpresas": "Empresas",
		"tabDocumentos": "Documentos",
		"tableDefinition": {
			"nempresa": "empresa no.",
			"nome": "Nombre de empresa",
			"emitido": "Doc. emitido",
			"valor": "Valor total",
			"valorMesAnterior": "Valor del mes anterior",
			"mensagem": "Mensaje",
			"dataEmissao": "Fecha de asunto",
			"documento": "Documento",
			"dataPagamento": "fecha de pago",
			"tipoPagamento": "Tipo de pago",
			"emitirTipoDocumento": "Asunto",
			"pdf": "PDF",
			"valorFCTNoCG": "Valor total en CG",
			"email": "Correo electrónico",
			"valorEmAtraso": "Monto vencido",
			"valorAPagar": "cantidad a pagar",
			"identificacao": "Identificación",
			"contrato": "Contrato",
			"rendimentos": "Ingreso",
			"totalRetidoFundos": "Fondos totales retenidos",
			"fctAno": "año FCT",
			"fctMes": "mes FCT",
			"codemp": "código emp.",
			"nomeEmp": "Nombre",
			"niss": "niss",
			"iban": "IBAN",
			"modalidadeContrato": "Contrato",
			"dataEntrada": "fecha de entrada",
			"dataInicioContrato": "Fecha de inicio",
			"dataFimContrato": "Fecha de finalización",
			"valorRetribuicaoMensal": "pago mensual",
			"valorDiuturnidade": "Valor diurno",
			"qtdFaltasInjustificadas": "Número de ausencias injustificadas",
			"valorFaltasInjustificadas": "Valor de las ausencias",
			"totalFCT": "FCT totales",
			"totalFGCT": "Total TGF",
			"valorPago": "Monto pagado",
			"dataCessacaoContrato": "Fecha de terminación del contrato",
			"motivo": "Razón",
			"entregasPagas": "Entregas pagadas",
			"numeroUPs": "número de UP",
			"valorEstimadoReembolso": "Monto estimado del reembolso",
			"numTrabalhadores": "Número de trabajadores"
		},
		"toolbar": {
			"obterValores": "Obtener valor FCT",
			"emitirDocumento": "Emitir documento de pago",
			"downloadDoc": "Descargar documento",
			"viewTodosTrabComFct": "Todos los trabajadores con FQ",
			"comSelecionados": "Operación con seleccionado...",
			"pedirReembolso": "Solicitar un reembolso"
		},
		"messages": {
			"selectRecords": "Debe seleccionar al menos una empresa.",
			"erroDownload": "Error al descargar el documento.",
			"emitidoComSucesso": "El documento de pago fue emitido exitosamente.",
			"configurarCredentials": "Configurar credenciales FCT",
			"valorObtidoComSucesso": "Valor obtenido del portal FCT con éxito",
			"porPagar": "No pagado",
			"emailEnviado": "¡Correo electrónico enviado exitosamente!",
			"emailNaoEnviado": "El correo electrónico no fue enviado.",
			"modalPromptMessage": "¿Está seguro de que desea emitir documentos de pago al {{numEmpSelected}} seleccionado?",
			"verificaEmail": "Debes rellenar el correo electrónico",
			"enviarEmailTooltip": "Enviar el documento de pago por correo electrónico",
			"verifiqueEmails": "Comprueba que los correos electrónicos estén completos y sean correctos.",
			"httpErrorTenteNovamente": "Ha ocurrido un error inesperado.",
			"selecionaEmpresa": "Tienes que seleccionar la empresa.",
			"selecionarCriterio": "Para realizar la búsqueda debe seleccionar al menos un criterio.",
			"selectAtLeastOneRecord": "Debe seleccionar al menos un registro.",
			"operacaoConcluidaVerifiqueMsgs": "La operación se completó con éxito.",
			"naoFoiPossivelPedirReembolsoParaAlgunsTrabalhadores": "No fue posible realizar una solicitud de reembolso para algunos trabajadores.",
			"dataFimIgualPosteriorDataInicio": "La fecha de finalización de la búsqueda debe ser igual o posterior a la fecha de inicio de la búsqueda.",
			"dataCessInicialEmpty": "Debes rellenar la fecha de inicio de la extinción del contrato.",
			"dataCessFinalEmpty": "Debes rellenar la fecha de finalización de la rescisión del contrato.",
			"pedidoReembolsoEfetuadoComSucesso": "La solicitud de reembolso se realizó con éxito.",
			"visualizarPedidosEfetuados": "Ver pedidos realizados."
		},
		"strings": {
			"de": "De",
			"tipoSearchDoc": "Tipo de documento.",
			"empresa": "Empresa",
			"pesquisar": "buscar",
			"searchTipoDocTodos": "Todo",
			"searchTipoDocADEF": "Documento de afiliación a la FCT",
			"searchTipoDocADEG": "Documento de afiliación a la FGCT",
			"searchTipoDocDOCP": "Documento de pago",
			"detalhes": "Detalles",
			"valorTotal": "Valor total",
			"valorMesAnterior": "Valor del mes anterior",
			"loginFCT": "Iniciar sesión",
			"niss": "niss",
			"password": "Contraseña",
			"sendEmail": "Enviar correo electrónico con documento de pago",
			"downloadDoc": "Descarga el documento de pago",
			"enviarEmail": "Enviar correo electrónico",
			"accessoFCT": "Acceso FCT",
			"viewTodosTrabComFctModalTitle": "Todos los trabajadores con un fondo de compensación.",
			"CDCI": "Terminado por despido con derecho a indemnización",
			"CDSI": "Terminado por despido sin derecho a indemnización",
			"CRV": "Terminado por terminación voluntaria",
			"efetuarPedido": "Realizar pedido",
			"efetuarPedidos": "Realizar pedidos",
			"pedidosReembolsos": "Solicitudes de reembolso",
			"pesquisarTrabalhadores": "Buscar trabajadores",
			"dataCessInicial": "Fecha de terminación del contrato",
			"a": "el",
			"estadoContrato": "Estado del contrato",
			"selecionarTodos": "Seleccionar todo",
			"deselecionarTodos": "Borrar selección",
			"pedidosReembolsosEfetuados": "{{nEmpresa}} - Solicitudes de reembolso realizadas"
		},
		"tooltips": {
			"obterValores": "Obtenga valor del portal de Cajas de Compensación",
			"emitirDocumento": "Emitir el documento de pago",
			"enviarEmail": "Enviar el email con el documento de pago",
			"downloadDoc": "Descarga el documento de pago",
			"viewTodosTrabComFct": "Ver datos de todos los trabajadores con un fondo de compensación",
			"comSelecionados": "Seleccione la operación que desea realizar con los registros seleccionados",
			"pedirReembolso": "Hacer una solicitud de reembolso",
			"selecionarTodos": "Seleccionar todos los trabajadores",
			"deselecionarTodos": "Borrar selección",
			"expandAllHaveEmployees": "Ampliar a todos los que tengan trabajadores.",
			"collapseAll": "Contraer todo",
			"efetuarPedidosDestaEmpresa": "Realizar una solicitud de reembolso para empleados seleccionados de esta empresa"
		}
	},
	"fileviewer": {
		"table": {
			"nome": "Nombre",
			"tamanho": "Tamaño",
			"datamod": "Fecha de modificación",
			"horamod": "Hora de modificación.",
			"datacriacao": "Fecha de creación",
			"horacriacao": "tiempo de creación",
			"utilizador": "Usuario",
			"docexterno": "Documento externo.",
			"classificador": "Clasificador",
			"pasta": "Carpeta"
		},
		"header": {
			"descBtn": "Mira como"
		},
		"tooltip": {
			"visualizar": "Vista",
			"download": "Descargar",
			"onlyPDF": "Visor solo para formato PDF.",
			"delete": "Eliminar",
			"upload": "para cargar"
		}
	},
	"fimano": {
		"steps": {
			"welcome": "Bienvenido",
			"data": "Datos",
			"validations": "Validaciones",
			"execute": "para ejecutar"
		},
		"text": {
			"welcome": {
				"warning0": "¡La operación de fin de año debe ser realizada por un administrador!",
				"warning1": "Asegúrate de haber realizado copias de seguridad de tu empresa actual.",
				"warning2": "Haga clic en \"Siguiente\" para continuar con la operación de fin de año.",
				"errorNext": "No es posible continuar con el fin de año debido a los siguientes errores:"
			},
			"data": {
				"company": "Empresa",
				"accountingPeriod": "Periodo contable",
				"ircYear": "Año del IRC",
				"yearToClose": "Año que termina",
				"nDiario": "Diario (saldos iniciales)",
				"nDescritivo": "Descripción (Saldos iniciales)",
				"scheduleFor": "Horario para",
				"saldaContasClasse0": "Cuentas de pago Clase 0",
				"saldaContasClasse9": "Pagar cuentas Clase 9",
				"fazTratamentoME": "Maneja saldos en moneda extranjera."
			},
			"validations": {
				"beforeExecute0": "Antes de ejecutar el cierre de año es necesario realizar algunas validaciones.",
				"beforeExecute1": "Nota: Este proceso puede tardar algún tiempo.",
				"btnValidate": "Validar",
				"executing": "Ejecutando validaciones...",
				"noErrors": "No hay errores."
			},
			"execute": {
				"text0": "Todo está listo para iniciar el proceso de cierre de año.",
				"text1": "Tenga en cuenta que luego de programarlo, la empresa actual quedará <strong>bloqueada</strong> y <strong>inaccesible</strong> hasta que se complete el proceso.",
				"doExecute": "Haga clic en \"Finalizar\" para cerrar el año."
			}
		}
	},
	"fimperiodoenc": {
		"title_detail": "Período {{id}}",
		"title_new": "Fin del período",
		"title_plural": "Periodos",
		"pesquisa": "buscar",
		"saved": "Periodo {{id}}, guardado exitosamente.",
		"error": "Periodo {{id}}, no se puede guardar.",
		"fields": {
			"periodo": "Período",
			"periodos": "Periodos",
			"nomePeriodo": "Nombre",
			"observacoes": "Observaciones",
			"flagRigorAbsoluto": "Cerrado"
		},
		"placeholders": {
			"periodo": "Código de período",
			"observacoes": "Observaciones"
		},
		"messages": {
			"successfullychanged": "Período cambiado exitosamente",
			"docsnaosaldados": "¡Hay documentos sin resolver!"
		},
		"btn": {
			"applyperio": "Cambiar periodo de empresa",
			"wontchangeperio": "No cambiar el período de la empresa"
		},
		"titleListaEncerrados": "Lista de diarios cerrados",
		"titleDiariosDisponiveis": "Disponible",
		"titleDiariosSelecionados": "Seleccionado",
		"changePeriodo": "Cambiar el período contable de la empresa",
		"fazerVerificacaoDocsNSaldTitle": "Consultar documentos no liquidados",
		"fazerVerificacaoDocsNSald": "Presione Aceptar si desea verificar si hay documentos no liquidados o Cancelar si desea continuar sin verificar.",
		"temDocsNSald": "Hay documentos impagos en el periodo."
	},
	"fluxoMonetario": {
		"pesquisa": "Flujos de dinero",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"undefined": "No definido",
			"doesNotHave": "no tiene",
			"paymentInTheAct": "Pago en el acto",
			"credit": "Crédito"
		}
	},
	"formaIncrementoNDoc": {
		"pesquisa": "Formularios de incremento de número de documento",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"numberingRestartsEachPeriod": "La numeración se reinicia en cada período.",
			"sequentialNumbering": "Numeración secuencial (independientemente del período)"
		}
	},
	"fregVig": {
		"data": {
			"AGUEDA": "ÁGUEDA",
			"ALBERGARIA_A_VELHA": "ALBERGARIA-A-VELHA",
			"ANADIA": "ANADIA",
			"AROUCA": "AROUCA",
			"AVEIRO_1": "AVEIRO-1.",
			"AVEIRO_2": "AVEIRO-2.",
			"CASTELO_DE_PAIVA": "CASTILLO DE PAIVA",
			"ESPINHO": "ESPINA",
			"ESTARREJA": "ESTARREJA",
			"FEIRA_2": "FERIA-2",
			"FEIRA_1": "FERIA-1",
			"FEIRA_4": "FERIA-4",
			"FEIRA_3": "FERIA-3",
			"ILHAVO": "ÍLHAVO",
			"MEALHADA": "MEALHADA",
			"MURTOSA": "MURTOSA",
			"OLIVEIRA_AZEMEIS": "OLIVEIRA AZEMEIS",
			"OLIVEIRA_DO_BAIRRO": "OLIVEIRA DEL BAIRRO",
			"OVAR": "OVAR",
			"S_JOAO_DA_MADEIRA": "S. JOAO DA MADEIRA",
			"SEVER_DO_VOUGA": "SEVER DO VOUGA",
			"VAGOS": "VACANTES",
			"VALE_DE_CAMBRA": "VALLE DE CAMBRA",
			"ALJUSTREL": "ALJUSTREL",
			"ALMODOVAR": "ALMODOVAR",
			"ALVITO": "ALVITO",
			"BARRANCOS": "BARRANCOS",
			"BEJA": "BEJA",
			"CASTRO_VERDE": "CASTRO VERDE",
			"CUBA": "CUBA",
			"FERREIRA_DO_ALENTEJO": "FERREIRA DEL ALENTEJO",
			"MERTOLA": "MÉRTOLA",
			"MOURA": "MOURA",
			"ODEMIRA": "ODEMIRA",
			"OURIQUE": "OURIQUE",
			"SERPA": "SERPA",
			"VIDIGUEIRA": "VIDIGUEIRA",
			"AMARES": "AMARES",
			"BARCELOS": "BARCELOS",
			"BRAGA_1": "BRAGA-1.",
			"BRAGA_2": "BRAGA-2.",
			"CABECEIRAS_DE_BASTO": "CABECEROS BASTÓ",
			"CELORICO_DE_BASTO": "CELORICO DE BASTO",
			"ESPOSENDE": "ESPOSENDE",
			"FAFE": "FAFE",
			"GUIMARAES_2": "GUIMARAES-2.",
			"GUIMARAES_1": "GUIMARAES-1.",
			"POVOA_DE_LANHOSO": "PUEBLO DE LANHOSO",
			"TERRAS_DE_BOURO": "TIERRAS DE BOURO",
			"VIEIRA_DO_MINHO": "VIEIRA DEL MINHO",
			"VILA_N_FAMALICAO_1": "VILA N.FAMALICAO-1.",
			"VILA_N_FAMALICA0_2": "VILA N.FAMALICAO 2.",
			"VILA_VERDE": "VILLA VERDE",
			"VIZELA": "VIZELA",
			"ALFANDEGA_DA_FE": "ADUANAS FE",
			"BRAGANCA": "BRAGANCA",
			"CARRAZEDA_DE_ANSIAES": "CARRAZEDA DE ANSIAES",
			"FR_DE_ESPADA_A_CINTA": "FR DE LA ESPADA AL CINTURÓN",
			"MACEDO_DE_CAVALEIROS": "MACEDO DE CAVALEIROS",
			"MIRANDA_DO_DOURO": "MIRANDA DEL DUERO",
			"MIRANDELA": "MIRANDELA",
			"MOGADOURO": "MOGADOURO",
			"TORRE_DE_MONCORVO": "TORRE MONCORVO",
			"VILA_FLOR": "VILA FLOR",
			"VIMIOSO": "VIMIO",
			"VINHAIS": "VIÑEDOS",
			"BELMONTE": "BELMONTE",
			"CASTELO_BRANCO_2": "CASTILLO BLANCO-2.",
			"CASTELO_BRANCO_1": "CASTILLO BLANCO-1.",
			"COVILHA": "COVILHÁ",
			"FUNDAO": "FUNDAO",
			"IDANHA_A_NOVA": "IDANHA-A-NOVA",
			"OLEIROS": "ALfareros",
			"PENAMACOR": "PENAMACOR",
			"PROENCA_A_NOVA": "PROENCA-A-NOVA",
			"SERTA": "SERTA",
			"VILA_DE_REI": "VILA DE REI",
			"VILA_VELHA_DE_RODAO": "VILA VELHA DE RODAO",
			"ARGANIL": "ARGANIL",
			"CANTANHEDE": "CANTANHEDO",
			"COIMBRA_1": "COÍBRA-1.",
			"COIMBRA_2": "COÍBRA-2.",
			"CONDEIXA_A_NOVA": "CONDEIXA-A-NOVA",
			"FIGUEIRA_DA_FOZ_1": "FIGUEIRA DA FOZ-1.",
			"FIGUEIRA_DA_FOZ_2": "FIGUEIRA DE FOZ 2.",
			"GOIS": "GOIS",
			"LOUSA": "PIZARRA",
			"MIRA": "APUNTAR",
			"MIRANDA_DO_CORVO": "MIRANDA DO CORVO",
			"MONTEMOR_O_VELHO": "MONTEMOR-O-VELHO",
			"OLIVEIRA_DO_HOSPITAL": "HOSPITAL DE OLIVEIRA",
			"PAMPILHOSA_DA_SERRA": "PAMPILHOSA DA SERRA",
			"PENACOVA": "UN PIE EN LA TUMBA",
			"PENELA": "PENELA",
			"SOURE": "AGRIO",
			"TABUA": "JUNTA",
			"VILA_NOVA_DE_POIARES": "VILA NOVA DE POIARES",
			"ALANDROAL": "ALANDROAL",
			"ARRAIOLOS": "ARRAIOLOS",
			"BORBA": "BORBA",
			"ESTREMOZ": "ESTREMOZ",
			"EVORA": "ÉVORA",
			"MONTEMOR_O_NOVO": "MONTEMOR-O-NOVO",
			"MORA": "VIDAS",
			"MOURAO": "MOURAO",
			"PORTEL": "PORTEL",
			"REDONDO": "REDONDO",
			"REGUENGOS_DE_MONSARAZ": "REGUENGOS DE MONSARAZ",
			"VENDAS_NOVAS": "NUEVAS VENTAS",
			"VIANA_DO_ALENTEJO": "VIANA DEL ALENTEJO",
			"VILA_VICOSA": "VILA VICOSA",
			"ALBUFEIRA": "ALBUFEIRA",
			"ALCOUTIM": "ALCOUTIM",
			"ALJEZUR": "ALJEZUR",
			"CASTRO_MARIM": "CASTRO MARIM",
			"FARO": "FARAÓN",
			"LAGOA_ALGARVE": "LAGOA (ALGARVE)",
			"LAGOS": "LAGOS",
			"LOULE_1": "LOULE-1",
			"LOULE_2": "LOULE-2",
			"MONCHIQUE": "MONCHIQUE",
			"OLHAO": "OLHÃO",
			"PORTIMAO": "PORTIMÁO",
			"S_BRAS_DE_ALPORTEL": "S. BRAS DE ALPORTEL",
			"SILVES": "PLATAS",
			"TAVIRA": "TAVIRA",
			"VILA_DO_BISPO": "VILA DEL BISPO",
			"VILA_REAL_S_ANTONIO": "VILA REAL S.ANTONIO",
			"AGUIAR_DA_BEIRA": "AGUIAR DA BEIRA",
			"ALMEIDA": "ALMEIDA",
			"CELORICO_DA_BEIRA": "CELORICO DA BEIRA",
			"FIG_CASTELO_RODRIGO": "HIGO.",
			"FORNOS_DE_ALGODRES": "HORNOS DE ALGODÓN",
			"GOUVEIA": "GOUVEIA",
			"GUARDA": "GUARDIA",
			"MANTEIGAS": "MANTEQUILLAS",
			"MEDA": "YO DE",
			"PINHEL": "PINHEL",
			"SABUGAL": "SABUGAL",
			"SEIA": "SEIA",
			"TRANCOSO": "TRANCOSO",
			"VILA_NOVA_DE_FOZ_COA": "VILA NOVA DE FOZ COA",
			"ALCOBACA": "ALCOBACA",
			"ALVAIAZERE": "ALVAIAZERE",
			"ANSIAO": "ANSIEDAD",
			"BATALHA": "BATALLA",
			"BOMBARRAL": "BOMBARRAL",
			"CALDAS_DA_RAINHA": "JARABE DE REINA",
			"CASTANHEIRA_DE_PERA": "PERAL CASTAÑO",
			"FIGUEIRO_DOS_VINHOS": "FIGUEIRO DOS VINOS",
			"LEIRIA_2": "LEIRIA-2.",
			"LEIRIA_1": "LEIRIA-1.",
			"MARINHA_GRANDE": "MARINO GRANDE",
			"NAZARE": "NAZARE",
			"OBIDOS": "ÓBIDOS",
			"PEDROGAO_GRANDE": "PEDROGAO GRANDE",
			"PENICHE": "PENICE",
			"POMBAL": "POMBAL",
			"PORTO_DE_MOS": "PUERTO DE MOS",
			"ALENQUER": "ALENQUER",
			"ARRUDA_DOS_VINHOS": "ARRUDA DOS VINOS",
			"AZAMBUJA": "AZAMBUJA",
			"CADAVAL": "CADAVAL",
			"CASCAIS_1": "CASCAIS-1.",
			"CASCAIS_2": "CASCAIS-2.",
			"LISBOA_7": "LISBOA-7",
			"LISBOA_9": "LISBOA-9",
			"LISBOA_5": "LISBOA-5",
			"LISBOA_10": "LISBOA-10",
			"LISBOA_11": "LISBOA-11",
			"LISBOA_6": "LISBOA-6",
			"LISBOA_8": "LISBOA-8",
			"LISBOA_1": "LISBOA-1",
			"LISBOA_4": "LISBOA-4",
			"LISBOA_2": "LISBOA-2",
			"LISBOA_3": "LISBOA-3",
			"LOURES_1": "LOURES-1",
			"LOURES_3": "LOURES-3",
			"LOURES_4": "LOURES-4.",
			"LOURINHA": "LOURINHA",
			"MAFRA": "MAFRA",
			"OEIRAS_2": "OEIRAS-2.",
			"OEIRAS_3_ALGES": "OEIRAS-3.ALGES",
			"OEIRAS_1": "OEIRAS-1.",
			"SINTRA_2_ALGUEIRAO": "SINTRA-2.",
			"SINTRA_1": "SINTRA-1",
			"SINTRA_4_QUELUZ": "SINTRA-4.",
			"SINTRA_3_CACEM": "SINTRA-3.",
			"SOBRAL_MONTE_AGRACO": "SOBRAL MONTE AGRACO",
			"TORRES_VEDRAS": "TORRES VEDRAS",
			"VILA_FRANCA_XIRA_2": "VILA FRANCA XIRA-2.",
			"VILA_FRANCA_XIRA_1": "VILA FRANCA XIRA-1",
			"AMADORA_3": "AMATEUR-3.",
			"AMADORA_2": "AFICIONADO-2.",
			"AMADORA_1": "AFICIONADO-1.",
			"ODIVELAS": "ODIVELAS",
			"ALTER_DO_CHAO": "CAMBIAR EL SUELO",
			"ARRONCHES": "ARRONCHAS",
			"AVIS": "AVIS",
			"CAMPO_MAIOR": "CAMPO MÁS GRANDE",
			"CASTELO_DE_VIDE": "VIDEO CASTILLO",
			"CRATO": "CRATO",
			"ELVAS": "ELVAS",
			"FRONTEIRA": "BORDE",
			"GAVIAO": "HALCÓN",
			"MARVAO": "MARVAO",
			"MONFORTE": "MONFORTE",
			"NISA": "NISA",
			"PONTE_DE_SOR": "PONTE DE SOR",
			"PORTALEGRE": "PORTALEGRE",
			"SOUSEL": "SOUSEL",
			"AMARANTE": "AMARANTE",
			"BAIAO": "BAIAO",
			"FELGUEIRAS": "FELGUEIRAS",
			"GONDOMAR_2": "GONDOMAR-2",
			"GONDOMAR_1": "GONDOMAR-1",
			"LOUSADA": "LOUSADA",
			"MAIA": "MAIA",
			"MARCO_DE_CANAVESES": "MARCO DE CANAVESES",
			"MATOSINHOS_2": "MATOSINHOS-2.",
			"MATOSINHOS_1": "MATOSINHOS-1",
			"PACOS_DE_FERREIRA": "PACOS DE FERREIRA",
			"PAREDES": "PAREDES",
			"PENAFIEL": "PENAFIEL",
			"PORTO_1": "OPORTO-1",
			"PORTO_3": "OPORTO-3",
			"PORTO_4": "OPORTO-4",
			"PORTO_2": "OPORTO-2",
			"PORTO_5": "OPORTO-5",
			"POVOA_DE_VARZIM": "PÓVOA DE VARZIM",
			"SANTO_TIRSO": "SANTO TIRSO",
			"VALONGO_2_ERMESINDE": "VALONGO-2.",
			"VALONGO_1": "VALONGO-1.",
			"VILA_DO_CONDE": "VILA DEL CONDE",
			"VILA_NOVA_DE_GAIA_3": "VILA NOVA DE GAIA-3",
			"VILA_NOVA_DE_GAIA_1": "VILA NOVA DE GAIA-1.",
			"VILA_NOVA_DE_GAIA_2": "VILA NOVA DE GAIA-2.",
			"TROFA": "TROFEO",
			"ABRANTES": "INTEGRAL",
			"ALCANENA": "ALCANENA",
			"ALMEIRIM": "ALMEIRIM",
			"ALPIARCA": "ALPIARCA",
			"BENAVENTE": "BENAVENTE",
			"CARTAXO": "CARTAXO",
			"CHAMUSCA": "CHAMUSCAR",
			"CONSTANCIA": "CONSTANCIA",
			"CORUCHE": "CORUCHE",
			"ENTRONCAMENTO": "ENTRADA",
			"FERREIRA_DO_ZEZERE": "FERREIRA DO ZEZERE",
			"GOLEGA": "GOLEGA",
			"MACAO": "MACAO",
			"RIO_MAIOR": "RIO MAYOR",
			"SALVATERRA_DE_MAGOS": "SAVETERRA DE MAGIA",
			"SANTAREM": "SANTAREM",
			"SARDOAL": "SARDOAL",
			"TOMAR": "TOMAR",
			"TORRES_NOVAS": "NUEVAS TORRES",
			"VILA_N_DA_BARQUINHA": "VILA N.DA BARQUINHA",
			"OUREM": "OURÉM",
			"ALCACER_DO_SAL": "ALCACER DO SAL",
			"ALCOCHETE": "ALCOCHETE",
			"ALMADA_1": "ALMADA-1.",
			"ALMADA_3_C_CAPARICA": "ALMADA-3.",
			"ALMADA_2_C_PIEDADE": "ALMADA-2.",
			"BARREIRO": "BARREIRO",
			"GRANDOLA": "GRANDOLA",
			"MOITA": "MOITA",
			"MONTIJO": "MONTIJO",
			"PALMELA": "PALMELA",
			"SANTIAGO_DO_CACEM": "SANTIAGO DEL CACEM",
			"SEIXAL_2": "SEIXAL-2.",
			"SEIXAL_1": "SEIXAL 1.",
			"SESIMBRA": "SESIMBRA",
			"SETUBAL_1": "SETÚBAL-1.",
			"SETUBAL_2": "SETÚBAL 2.",
			"SINES": "SENOS",
			"ARCOS_DE_VALDEVEZ": "ARCOS DE VALDÉVEZ",
			"CAMINHA": "CAMINAR",
			"MELGACO": "MELGACO",
			"MONCAO": "MONZÓN",
			"PAREDES_DE_COURA": "PAREDES DE CUERO",
			"PONTE_DA_BARCA": "PUENTE DE BARCA",
			"PONTE_DE_LIMA": "PONTE DE LIMA",
			"VALENCA": "VALENCIA",
			"VIANA_DO_CASTELO": "VIANA DEL CASTILLO",
			"VILA_NOVA_CERVEIRA": "VILA NOVA CERVEIRA",
			"ALIJO": "ALIJO",
			"BOTICAS": "BOTICAS",
			"CHAVES": "LLAVES",
			"MESAO_FRIO": "MESAO FRÍO",
			"MONDIM_DE_BASTO": "MONDIM DE BASTO",
			"MONTALEGRE": "MONTALEGRE",
			"MURCA": "MURCA",
			"PESO_DA_REGUA": "PESO DE LA REGLA",
			"RIBEIRA_DE_PENA": "RIBEIRA DE PENA",
			"SABROSA": "SABROSO",
			"SANTA_MARTA_PENAGUIAO": "SANTA MARTA PENAGUÍAO",
			"VALPACOS": "VALPACOS",
			"VILA_POUCA_DE_AGUIAR": "VILA POUCA DE AGUIAR",
			"VILA_REAL": "PUEBLO REAL",
			"ARMAMAR": "BRAZO",
			"CARREGAL_DO_SAL": "CARREGAL DE SAL",
			"CASTRO_DAIRE": "CASTRO DAIRE",
			"CINFAES": "CINFAES",
			"LAMEGO": "LAMEGO",
			"MANGUALDE": "MANGUALDE",
			"MOIMENTA_DA_BEIRA": "MOIMENTA DA BEIRA",
			"MORTAGUA": "MORTAGUA",
			"NELAS": "EN ELLOS",
			"OLIVEIRA_DE_FRADES": "OLIVEIRA DE FRADES",
			"PENEDONO": "PENEDÓN",
			"RESENDE": "RESENDE",
			"SANTA_COMBA_DAO": "SANTA COMBA DAO",
			"S_JOAO_DA_PESQUEIRA": "S.JOAO DA PESQUEIRA",
			"S_PEDRO_DO_SUL": "S.PEDRO DEL SUL",
			"SATAO": "SATAO",
			"SERNANCELHE": "SERNANCELHE",
			"TABUACO": "TABUACO",
			"TAROUCA": "TAROUCA",
			"TONDELA": "TONDELA",
			"VILA_NOVA_DE_PAIVA": "VILA NOVA DE PAIVA",
			"VISEU": "viseu",
			"VOUZELA": "VOZELA",
			"ANGRA_DO_HEROISMO": "ANGRA DO HEROÍSMO",
			"CALHETA_S_JORGE": "CALHETA (S.JORGE)",
			"S_CRUZ_DA_GRACIOSA": "S.CRUZ DA GRACIOSA",
			"VELAS": "VELAS",
			"PRAIA_DA_VITORIA": "PLAYA VICTORIA",
			"CORVO": "CUERVO",
			"HORTA": "HUERTA",
			"LAJES_DAS_FLORES": "LOSAS DE FLORES",
			"LAGES_DO_PICO": "LAGES DEL PICO",
			"MADALENA": "MADALENA",
			"S_CRUZ_DAS_FLORES": "S.CRUZ DAS FLORES",
			"S_ROQUE_DO_PICO": "S.ROQUE DO PICO",
			"LAGOA_S_MIGUEL": "LAGOA (S. MIGUEL)",
			"NORDESTE": "NORESTE",
			"PONTA_DELGADA": "PUNTA DELGADA",
			"POVOACAO": "ALDEA",
			"RIBEIRA_GRANDE": "RIBEIRA GRANDE",
			"VILA_FRANCA_DO_CAMPO": "VILA FRANCA DO CAMPO",
			"VILA_DO_PORTO": "VILA DEL OPORTO",
			"CALHETA_MADEIRA": "CALHETA - MADERA",
			"CAMARA_DE_LOBOS": "CÁMARA DE LOBOS",
			"FUNCHAL_2": "FUNCHAL-2.",
			"FUNCHAL_1": "FUNCHAL-1.",
			"MACHICO": "MACHICO",
			"PONTA_DO_SOL": "PONTA DEL SOL",
			"PORTO_MONIZ": "PORTO MONIZ",
			"RIBEIRA_BRAVA": "RIBEIRA BRAVA",
			"SANTA_CRUZ_MADEIRA": "SANTA CRUZ (MADEIRA)",
			"SANTANA": "SANTA",
			"S_VICENTE_MADEIRA": "S.VICENTE (MADEIRA)"
		}
	},
	"freguesias": {
		"title_detail": "Parroquia {{id}}",
		"title_new": "Nueva Parroquia",
		"title_plural": "Parroquias",
		"pesquisa": "buscar",
		"saved": "Parroquia {{id}}, guardada exitosamente",
		"error": "Parroquia {{id}}, no se puede guardar.",
		"deleted": "Parroquia {{id}}, eliminada exitosamente",
		"fields": {
			"nFreguesia": "código parroquial",
			"designacaoBreve": "Breve designación",
			"designacaoCompleta": "Designación completa"
		}
	},
	"fremun": {
		"title_detail": "Forma de Remuneración {{id}}",
		"title_new": "Nueva forma de remuneración",
		"title_plural": "Formas de remuneración",
		"pesquisa": "buscar",
		"saved": "Formulario de Remuneración {{id}}, guardado exitosamente",
		"error": "Formulario de remuneración {{id}}, no se puede guardar.",
		"deleted": "Formulario de remuneración {{id}}, eliminado exitosamente",
		"fields": {
			"nFRemun": "Código formulario de remuneración",
			"designacaoBreve": "Breve designación",
			"designacaoCompleta": "Designación completa"
		}
	},
	"funcoes": {
		"title_detail": "Función {{id}}",
		"title_new": "Nueva función",
		"title_plural": "Funciones",
		"pesquisa": "buscar",
		"saved": "Función {{id}}, guardada exitosamente",
		"error": "Función {{id}}, no se puede guardar.",
		"deleted": "Función {{id}}, eliminada correctamente",
		"fields": {
			"nFuncao": "Código de función",
			"designaBreve": "Breve designación",
			"designaComp": "Designación completa"
		}
	},
	"gdoc": {
		"fields": {
			"cab": {
				"folderId": "Identificación de carpeta",
				"name": "Nombre"
			}
		},
		"messages": {
			"maxFicheirosAtingido": "¡Se ha alcanzado el número máximo de archivos permitidos!"
		}
	},
	"generateAbdes": {
		"title": "Generar nuevo {{abonoDesconto}} automáticamente",
		"gerar": "para generar",
		"perfilNotSelected": "¡Debes seleccionar un {{profilAbDesc}}!",
		"abono": "Prestación",
		"desconto": "Descuento",
		"abonoDesconto": "{{abonoDesconto}}",
		"tipoConfig": "Tipo de configuración",
		"selecionarAbonoDescontoPretendido": "Seleccione {{abonoDesconto}} deseado",
		"criarNovoAbonoDesconto": "Crear nuevo {{abonoDesconto}} desde:",
		"codigoAbonoDescontoNotSelected": "¡Debes seleccionar el {{abonoDesconto}}!",
		"formPerfilInvalid": "La validación del campo falló.",
		"fields": {
			"categoria": "Categoría",
			"grupo": "Grupo",
			"nome": "Nombre",
			"descricao": "Descripción",
			"tipoArredondamento": "redondeo",
			"tipoRecolha": "Tipo de colección",
			"contribuiCalculoCustoPrecoH": "Contribuye al cálculo del coste precio/hora.",
			"codRem": "Código de remuneración SEC.",
			"codUnidade": "Unidad defectuosa",
			"tipoRendimento": "Tipo de ingreso",
			"contaMovDeb": "número de cuenta de débito",
			"contaMovCred": "Número de cuenta de crédito",
			"contaEncDEB": "número de cuenta de débito",
			"contaEncCRE": "Número de cuenta de crédito",
			"ausencia": "Ausencia",
			"percAumentHExtra": "% Incrementar horas extras",
			"nHorasDiaProvDesc": "Número de horas por día por pérdida del subsidio de alimentación",
			"perfilAbDesc": "{{abonoDesconto}} perfil",
			"semPerfil": "Sin perfil",
			"abonoDescontoPreConfigurado": "{{abonoDesconto}} preconfigurado",
			"abonoDescontoJaExistente": "{{abonoDesconto}} ya existente",
			"tipo": "Tipo",
			"perfil": "Perfil"
		},
		"crabdTable": {
			"fields": {
				"categoria": "Categoría",
				"empregado": "Ent.",
				"patronato": "Ent.",
				"desconto": "Descuento",
				"nomeCategoria": "Categoría",
				"temIncidenciaEmpregado": "Él tiene",
				"incidenciaEmpregado": "Incidencia",
				"temIncidenciaPatronato": "Él tiene",
				"incidenciaPatronato": "Incidencia",
				"nDesconto": "Código.",
				"nomeDesconto": "Descripción"
			}
		},
		"stepCategoria": "Categoría",
		"stepPerfil": "Perfil",
		"editarcruzamentos": {
			"title": "Cruces Editar",
			"subtitle": "Descuento {{categoría}}",
			"desconto": "Descuento",
			"temcruzent": "Tiene cruce con entidad",
			"empregado": "Empleado",
			"incidencia": "(%) Incidencia",
			"patronato": "Mecenazgo",
			"temseleentidade": "¡Debes seleccionar al menos una entidad para realizar el cruce!",
			"teminserirdesc": "¡Tienes que introducir un descuento!"
		},
		"abdesemp": "Asignaciones y descuentos para empleados",
		"encpatronempresa": "Cargos del empleador/empresa"
	},
	"objscandoctextinterpreter": {
		"invoiceType": {
			"none": "Desconocido",
			"fatura": "Factura",
			"faturaSimplificada": "factura simplificada",
			"faturaRecibo": "Recibo de factura",
			"notaDebito": "nota de crédito",
			"notaCredito": "nota de débito",
			"vendaDinheiro": "Vender por dinero en efectivo",
			"talaoVenda": "recibo de venta",
			"talaoDevolucao": "Resguardo de devolución",
			"alienacaoAtivos": "Enajenación de activos",
			"devolucaoAtivos": "Devolución de activos",
			"premio": "Premio o recibo de premio",
			"estorno": "Recibo de devolución o reembolso",
			"imputacaoCoSeguradoras": "Imputación a coaseguradores",
			"imputacaoCoSeguradoraLider": "Imputación al coasegurador líder",
			"resseguroAceite": "Reaseguro aceptado",
			"recibo": "Recibo",
			"notaLancamento": "Nota de lanzamiento",
			"extratoBancario": "Extracto de cuenta",
			"pagamentoAoEstado": "Pago al estado",
			"levantamento": "Encuesta",
			"pagamento": "Pago",
			"deposito": "Depósito",
			"cheque": "Controlar",
			"transferencia": "Transferir"
		}
	},
	"gestaoComunicacoes": {
		"title": "contador de gestion de comunicaciones",
		"titleCliente": "Gestión de comunicaciones con clientes.",
		"messageSidebarSelector": {
			"empresas": "Empresas",
			"clienteMessage": "Mensajes",
			"comunicacoes": "Comunicaciones",
			"newMessages": "Nuevos mensajes",
			"noComunicacoes": "No hay comunicación disponible",
			"ontem": "Ayer"
		},
		"modal": {
			"config": {
				"selectEmpresa": {
					"title": "Configuración de la empresa",
					"leftCaption": "Empresas disponibles",
					"rightCaption": "Empresas excluidas"
				},
				"selectUtilizador": {
					"title": "Configuración de usuario",
					"leftCaption": "Posibles usuarios",
					"rightCaption": "Usuarios seleccionados"
				},
				"mensagens": {
					"success": "Configuración guardada exitosamente"
				}
			},
			"empresaConfig": {
				"title": "Configuración {{nCompany}} - {{companyname}}",
				"empresaConfigEstados": {
					"title": "Configuración de estado",
					"estados": "Estados",
					"herdado": "Heredado de la configuración general",
					"detail": {
						"title": "Estado - {{NameState}}",
						"titleAdd": "Agregar estado",
						"todasEmpresas": "Disponible para todas las empresas",
						"cor": "Color"
					},
					"mensagens": {
						"successEstados": "Estado guardado exitosamente",
						"successEstado": "Estado guardado exitosamente",
						"errorDeleteEstado": "No puedes eliminar este estado, permanece asociado a un tema.",
						"notaOrdenarEstados": "Para ordenar los estados, simplemente arrastre y suelte",
						"notaInherited": "Usuarios heredados de la configuración general."
					}
				}
			},
			"topico": {
				"newMessage": "Nueva comunicación",
				"editMessage": "Editar comunicación",
				"deleteMessage": "Eliminar comunicación",
				"assunto": "Sujeto",
				"user": "Usuario",
				"categoria": "Categoría",
				"estado": "Estado",
				"data": "Fecha",
				"mensagem": "Mensaje",
				"mensagensTitle": "Mensajes",
				"mensagemPlaceholder": "Escribe tu mensaje aquí...",
				"mensagensNaoLidas": "Mensajes no leídos",
				"mensagens": {
					"success": "Comunicación enviada exitosamente",
					"updateSuccess": "Comunicación editada con éxito",
					"deleteSuccess": "Comunicación eliminada exitosamente"
				}
			}
		},
		"common": {
			"filtrarPorPalavraChave": "Filtrar por palabra clave",
			"semMensagens": "Sin mensajes",
			"carregarMais": "Cargar más",
			"typeYourMessage": "Escribe tu mensaje...",
			"selectEmpresa": "Seleccione una empresa",
			"selectComunicacao": "Seleccione una comunicación",
			"nothingSelected": "No hay nada seleccionado",
			"ficheiroSelecionadoSucesso": "Archivo seleccionado exitosamente"
		}
	},
	"gestaoFicheirosSepa": {
		"grid": {
			"fields": {
				"nContaForn": "Nro. de cuenta",
				"nomeBeneficiario": "Nombre",
				"ibanContaDest": "IBAN",
				"swiftDest": "RÁPIDO",
				"valor": "Valor",
				"dataLanc": "Fecha de creación",
				"nomeFicheiro": "Nombre del archivo",
				"estadoNotificacao": "Notificación de proveedor"
			},
			"actions": {
				"imprimirNotificar": "Imprimir/Notificar",
				"download": "Descargar archivo SEPA",
				"retrocederPorEnviar": "Cambiar estado a \"No enviado\" del archivo SEPA"
			},
			"toolbar": {
				"meioPagamento": "Banco (método de pago)",
				"dataDe": "fecha de pago"
			}
		},
		"tabs": {
			"title": {
				"enviados": "Enviado",
				"porEnviar": "para enviar"
			}
		},
		"btn": {
			"gerarFicheiroSEPA": "Generar archivo SEPA"
		},
		"error": {
			"pesquisar": "Todos los parámetros de búsqueda deben ser completados."
		}
	},
	"gestaodgemps": {
		"text": {
			"abonos": "Asignaciones",
			"faltas": "Faltas",
			"ferias": "Vacaciones",
			"servico": "Servicio",
			"view": "Vista",
			"yearly": "Anual",
			"monthly": "Mensual",
			"finsSemanaFeriados": "Fines de semana/vacaciones",
			"pendentes": "Colgantes",
			"marcadas": "Marcado",
			"aprovadas": "Aprobado",
			"integradas": "Integrado",
			"rejeitadas": "Rechazado",
			"gozadas": "Corridas",
			"restantes": "Restante",
			"conflito": "Conflicto",
			"attachments": "Adjuntos",
			"fromDate": "de",
			"toDate": "hacia",
			"addNew": "Agregar nuevo evento",
			"motivoRejeicao": "Motivo del rechazo",
			"day": "Día {{fecha}}",
			"allDay": "{{fecha}}, todo el día",
			"fromTo": "Desde {{start}} hasta {{end}}",
			"fromToHours": "{{fecha}}, del {{start}} al {{end}}",
			"recordedWageProcessing": "Registrado en procesamiento de salario",
			"details": "Detalles",
			"integraAll": "Integrar eventos seleccionados",
			"integraAllGroup": "Integrar eventos para empleados",
			"approveAll": "Aprobar eventos seleccionados",
			"approveAllGroup": "Aprobar eventos de empleados",
			"rejectAll": "Rechazar eventos seleccionados",
			"rejectAllGroup": "Rechazar eventos de empleados",
			"setMotivoAll": "Establecer motivo para eventos seleccionados",
			"setMotivoAllGroup": "Definir el motivo de los eventos de los empleados",
			"rewind": "Revertir estado",
			"rewindAll": "Revertir el estado de los eventos seleccionados",
			"rewindAllGroup": "Revertir el estado del evento del empleado",
			"markOff": "Desmarcar",
			"markOffAll": "Deseleccionar eventos seleccionados",
			"markOffAllGroup": "Deseleccionar eventos de empleados",
			"addAttachment": "Agregar archivo adjunto",
			"downloadAttachment": "Descargar archivo adjunto",
			"replaceAttachment": "Reemplazar archivo adjunto",
			"deleteAttachment": "Quitar archivo adjunto",
			"history": "Historial de eventos",
			"noEvents": "No hay eventos en la fecha seleccionada.",
			"integrar": "Integrar",
			"prettyFeriasMarcadas": "{{marcado}} Marcado / {{total}} Total",
			"confirm": {
				"integraAll": "¿Está seguro de que desea integrar todos los eventos seleccionados?",
				"integraAllGroup": "¿Está seguro de que desea integrar todos los eventos del colaborador \"{{codeEmp}} - {{nomeEmp}}\"?",
				"approveAll": "¿Está seguro de que desea aprobar todos los eventos seleccionados?",
				"approveAllGroup": "¿Está seguro de que desea aprobar todos los eventos para el colaborador \"{{codeEmp}} - {{nomeEmp}}\"?",
				"rejectAll": "¿Está seguro de que desea rechazar todos los eventos seleccionados?",
				"rejectAllGroup": "¿Está seguro de que desea rechazar todos los eventos del colaborador \"{{codeEmp}} - {{nomeEmp}}\"?",
				"rewindAll": "¿Está seguro de que desea revertir el estado de todos los eventos seleccionados?",
				"rewindAllGroup": "¿Está seguro de que desea revertir el estado de todos los eventos del colaborador \"{{codeEmp}} - {{nomeEmp}}\"?",
				"markOffAll": "¿Está seguro de que desea anular la selección de todos los eventos seleccionados?",
				"markOffAllGroup": "¿Está seguro de que desea anular la selección de todos los eventos para el colaborador \"{{codeEmp}} - {{nomeEmp}}\"?",
				"deleteAttachment": "¿Está seguro de que desea eliminar el archivo adjunto seleccionado?"
			},
			"success": {
				"changed": "cambiado con éxito",
				"integrado": "Integrado exitosamente",
				"rejeitado": "Rechazado con éxito",
				"aprovado": "Aprobado exitosamente",
				"retrocede": "Retrocedido con éxito",
				"addAttachment": "Adjunto agregado exitosamente",
				"replaceAttachment": "El archivo adjunto se reemplazó exitosamente",
				"deleteAttachment": "El archivo adjunto se eliminó correctamente"
			},
			"warning": {
				"rewindAllPosicao": "¡Atención!"
			},
			"error": {
				"aprovado": "Se produjo un error al intentar aprobar"
			},
			"motivos": {
				"default": "razón",
				"retrocede": "Retroceder posición"
			},
			"status": {
				"male": {
					"marcado": "MARCADO (pendiente de aprobación)",
					"aprovado": "APROBADO",
					"aprovadoParcialmente": "APROBADO (parcialmente)",
					"rejeitado": "RECHAZADO",
					"integrado": "INTEGRADO",
					"gozado": "DISFRUTÉ"
				},
				"female": {
					"marcado": "MARCADO (pendiente de aprobación)",
					"aprovado": "APROBADO",
					"aprovadoParcialmente": "APROBADO (parcialmente)",
					"rejeitado": "RECHAZADO",
					"integrado": "INTEGRADO",
					"gozado": "SEMEN"
				}
			}
		},
		"errors": {
			"invalidTipoAbono": "Debes definir un tipo de asignación.",
			"invalidTipoFalta": "Debes definir un tipo de ausencia.",
			"invalidTipoFeria": "Debes definir un tipo de vacaciones.",
			"invalidQuantidade": "Debes definir una cantidad.",
			"invalidTipoProcessamento": "Debe definir un tipo de procesamiento.",
			"invalidDesconto": "Debes definir un descuento.",
			"naoPodeMarcarFeriasPorqueTemConflitos": "No puedes reservar vacaciones en esa fecha o intervalo porque hay conflictos."
		},
		"fields": {
			"codEmp": "Código.",
			"nomeCategoria": "Categoría",
			"motivo": "Razón",
			"nomeEmpregado": "Colaborador",
			"tipo": "Tipo",
			"tipoFalta": "tipo de falta",
			"tipoProcessamento": "Tipo de procesamiento",
			"designacaoAbDes": "Descuento",
			"dataTarefa": "Día",
			"datasTarefas": "Días",
			"alteraFaltas": "Cambiar faltas",
			"novoTipo": "Nuevo tipo",
			"anexaFicheiro": "Adjuntar archivo",
			"historico": "Historia",
			"quantidade": "Cantidad",
			"novaQuantidade": "Nueva cantidad",
			"allDay": "Todo el día",
			"horaInicio": "Hora de inicio",
			"horaFim": "Hora final.",
			"valor": "Valor",
			"dadosFixos": "Datos fijos",
			"anexo": "Anexo",
			"daData": "Fecha de",
			"codEmpDe": "código de empleado",
			"activeOnly": "Sólo activo",
			"valorUnitario": "Valor unitario",
			"dataProcessamento": "Fecha de procesamiento",
			"marcacoes": "Equipo"
		},
		"header": {
			"previous": "Anterior",
			"today": "Hoy",
			"next": "Próximo",
			"previousYear": "año anterior",
			"nextYear": "Al año que viene",
			"previousMonth": "Mes anterior",
			"nextMonth": "Mes próximo"
		},
		"abonos": {
			"pageTitle": "Gestión de prestaciones",
			"sidebarTitle": "Asignaciones",
			"motivos": {
				"marcar": "Asignaciones de programación",
				"aprovar": "Aprobación de asignaciones",
				"rejeitar": "Rechazo de asignaciones",
				"integrar": "Integración de asignaciones"
			},
			"marcarmodal": {
				"title": "Solicitud de reserva de dietas"
			},
			"cancelarmodal": {
				"title": "Deseleccionar asignación",
				"message": "¿Está seguro de que desea desmarcar las asignaciones?"
			}
		},
		"faltas": {
			"pageTitle": {
				"colaborador": "Marcación de faltas",
				"gestor": "Gestión de ausencias"
			},
			"sidebarTitle": {
				"colaborador": "Marcar faltas",
				"gestor": "Faltas"
			},
			"motivos": {
				"marcar": "Marcación de faltas",
				"aprovar": "Aprobación de ausencias",
				"rejeitar": "Rechazo de faltas",
				"integrar": "Integración de fallas"
			},
			"marcarmodal": {
				"title": "Solicitud de calificación de ausencias"
			},
			"cancelarmodal": {
				"title": "Faltas claras",
				"message": "¿Estás seguro de que quieres cancelar las faltas?"
			}
		},
		"ferias": {
			"pageTitle": {
				"colaborador": "Reserva de vacaciones",
				"gestor": "Gestión de vacaciones"
			},
			"sidebarTitle": {
				"colaborador": "reservar unas vacaciones",
				"gestor": "Vacaciones"
			},
			"motivos": {
				"marcar": "Reserva de vacaciones",
				"aprovar": "Aprobación de vacaciones",
				"rejeitar": "Rechazo de vacaciones",
				"integrar": "Integración vacacional"
			},
			"titles": {
				"marcadas": "Vacaciones programadas",
				"rejeitadas": "vacaciones rechazadas",
				"integradas": "Vacaciones integradas"
			},
			"marcarmodal": {
				"title": "Solicitud de reserva de vacaciones",
				"message": "¿Estás seguro de que quieres reservar unas vacaciones?",
				"marcarPara": "Marca para colaboradores"
			},
			"cancelarmodal": {
				"title": "Deseleccionar vacaciones",
				"message": "¿Estás seguro de que deseas cancelar unas vacaciones?"
			},
			"existemConflitosPertendeContinuar": "Hay conflictos en esta fecha o rango."
		},
		"details": {
			"titleAbonos": "Asignaciones {{fecha}}",
			"titleRangeAbonos": "Asignaciones {{start}} hasta {{end}}",
			"titleFaltas": "Faltas {{fecha}}",
			"titleRangeFaltas": "Faltas {{start}} a {{end}}",
			"titleFerias": "Vacaciones {{fecha}}",
			"titleRangeFerias": "Vacaciones {{start}} hasta {{end}}"
		},
		"summary": {
			"title": "Resumen",
			"integradas": "{{valor}} integrado"
		},
		"historymodal": {
			"title": "Historia"
		},
		"motivomodal": {
			"title": "Establecer motivo",
			"titleGlobal": "Establecer el motivo de los eventos seleccionados",
			"titleGroup": "Establezca el motivo de los eventos de los empleados: \"{{EmpName}}\""
		},
		"printmodal": {
			"title": "Impresión de eventos"
		}
	},
	"gestorrh": {
		"servicos": {
			"fields": {
				"colaborador": "Colaborador",
				"nome": "Nombre",
				"papel": "Papel"
			},
			"errors": {
				"unicoServico": "Ya existe un colaborador asociado al rol seleccionado en este servicio"
			}
		}
	},
	"global": {
		"centralgestLoginName": "CentralGest - Producción de Software, SA",
		"title": "Nube CentralGest",
		"menu": {
			"developer": "Revelador",
			"empresa": "Empresa",
			"home": "Hogar",
			"demo": "Manifestación",
			"artigos": "Artículos",
			"recibos": "Ingresos",
			"pagamentos": "Pagos",
			"clifos": "Terceros",
			"clientes": "Registros de clientes",
			"fornecedores": "Fichas de proveedores",
			"outrosdevedorescredores": "Registros de otros deudores y acreedores",
			"options": "Opciones",
			"configs": "Ajustes",
			"configsSite": "Configuración de correo electrónico",
			"configsErp": "Configuración ERP",
			"erpCheck": "Validación de configuraciones",
			"docscomerciais": "Documentos",
			"docscontabilidade": "Colección de lanzamientos",
			"vendas": "Ventas",
			"compras": "Compras",
			"guias": "Guías",
			"docfas": "Tipos de documentos",
			"familias": "Familias",
			"grandesfamilias": "familias grandes",
			"processos": "Procesos",
			"ccustos": "Centros de costos",
			"armazens": "Almacenes",
			"codpostais": "códigos postales",
			"subfamilias": "Subfamilias",
			"tiposartigo": "Tipos de artículos",
			"unidadesmovimento": "Unidades de movimiento",
			"unece": "Unidades de medida del comercio internacional",
			"ivas": "tablas de IVA",
			"condicoescomerciais": "Condiciones comerciales",
			"condicoespagamento": "Condiciones de pago",
			"meiosPagamento": "Métodos de pago",
			"naturezasContab": "Naturalezas contables",
			"moradasFaturacao": "Direcciones de facturación",
			"listagensMovimentosPendentesClientes": "Movimientos de clientes pendientes",
			"listagensMovimentosPendentesFornecedores": "Movimientos de proveedores pendientes",
			"listagensMovimentosPendentesOutrosDevedoresCredores": "Movimientos pendientes de otros deudores y acreedores",
			"taxasretencao": "Tasas de retención",
			"users": "Usuarios",
			"tokens": "Fichas",
			"roles": "Permisos",
			"templates": "Plantillas",
			"auditlogin": "Auditoría de inicio de sesión",
			"pocs": "plan de cuentas",
			"descritivos": "Descripciones contables",
			"diarios": "Diarios contables",
			"anosFaturacao": "Número de años de facturación",
			"portals": "Portales",
			"periodos": "Períodos contables",
			"qivaps": "Campos de declaración periódica de IVA",
			"qivaas": "Campos de declaración anual de IVA",
			"controloIVA": "control del IVA",
			"vendedores": "Vendedores",
			"artigoclasses": "Clases de artículos",
			"mascarasanalitica": "Máscaras analíticas",
			"amortizacao": "Códigos de depreciación",
			"datavalor": "fecha valor",
			"estabelecimentos": "Establecimientos",
			"estabelecimento": "Establecimientos",
			"localidades": "Ubicaciones",
			"local": "Ubicaciones",
			"qpcae": "Actividad Económica",
			"qpdcf": "Distrito/Municipio/Parroquia",
			"calendarios": "Calendarios",
			"nacionalidades": "Nacionalidades",
			"tirs": "tabla del IRS",
			"irsdt": "fecha de la tabla del IRS",
			"classescaixa": "Clases en efectivo",
			"classescontratos": "clases de contratos",
			"classescontratosservicos": "clases de pacto",
			"ativos": "Fichas de activos",
			"ativosAquisicao": "Adquisiciones",
			"categoriaartigo": "Categoría de artículo",
			"reparticoesccustosfaturacao": "Desgloses por centro de coste",
			"reparticoesccustossalarios": "Desgloses por centro de coste",
			"account": {
				"main": "perfil",
				"login": "Autenticar",
				"logout": "Cerrar sesión en la cuenta",
				"changeEmpresa": "Cambiar de empresa",
				"changeUser": "Cambiar usuario",
				"pesquisarEmpresa": "Buscar empresa:"
			},
			"language": "Idioma",
			"prhlocais": "Ubicaciones",
			"prhmenus": "Menús",
			"dgemps": "Colaboradores",
			"dgempsfull": "Ficha de colaborador",
			"prhrelementas": "Menús de relaciones",
			"prhrefeicoes": "Comidas",
			"prhtiporefeicoes": "tipos de comidas",
			"prhservicos": "Servicios",
			"prhpapeis": "Papeles",
			"prhservicoempregados": "Informe de servicio/empleo.",
			"prhfluxos": "Corrientes",
			"prhcomunicacoesinternas": "Comunicaciones internas.",
			"prhcomunicacoesinternasList": "Comunicación interna",
			"paises": "Países",
			"prheventosconfig": "Configuración de eventos",
			"prhdocsplata": "Documentos.",
			"categorias": "Categorías",
			"efatura": "SAF-T eFatura",
			"configefaturadocporlancar": "Configuración de documentos para el lanzamiento de eFatura",
			"mercados": "Mercados",
			"criterioDisponiveis": "Criterios disponibles",
			"calcPrVenda": "Cálculo del precio de venta",
			"tipoResumoMensal": "Tipo de resumen mensual",
			"origemCondPagamento": "Pago de condiciones de origen",
			"origemDataVencimento": "Fecha de vencimiento de origen",
			"tipoOperacao": "Tipo de operación",
			"areaRegional": "Área regional",
			"inversaoSujPassivo": "Inversión del sujeto imponible",
			"tipoLiquidacao": "Tipo de liquidación",
			"tipoDeducao": "Tipo de deducción",
			"tipoTransmissaoActiva": "Tipo de transmisión activa",
			"tipoTransmissaoPassiva": "Tipo de transmisión pasiva",
			"tipoBem": "tipo de bien",
			"tipoTaxa": "tipo de tarifa",
			"tipoValidacaoNif": "Tipo de validación NIF",
			"tipoRegularizacaoCampo40": "Tipo de campo 40 regularizaciones",
			"tipoRegularizacaoCampo41": "Tipo de campo 41 regularizaciones",
			"tipoDescritivo": "Tipo de descripción",
			"formaIncrementoNDoc": "Formulario de incremento de número de documento",
			"fluxoMonetario": "flujo de dinero",
			"tipoTaxaIva": "tipo de tasa de IVA",
			"areaRegionalTaxa": "Tarifa de área regional",
			"tipoDeducaoTaxa": "Tipo de deducción de impuestos",
			"tipoConta": "Tipo de cuenta",
			"tiposRefeicoes": "Tipos de comidas",
			"tiposMovimento": "Tipos de movimiento",
			"zonas": "Zonas",
			"codigoscontabilisticos": "Códigos contables",
			"reembolsosiva": "devolución del IVA",
			"fimperiodoenc": "Cierre de periodos",
			"depto": "Departamento",
			"subde": "Subdepartamento",
			"predefinidoscontabilidade": "Contabilidad predefinida",
			"moeda": "monedas",
			"moedaNormasIso": "Normas ISO para monedas",
			"paisesISO3166": "Países ISO 3166",
			"prhgruporefeicoes": "grupo de comida",
			"reconstrucaoacumulados": "Reconstrucción de provisiones",
			"verificacaoacumulados": "Comprobación de acumulaciones",
			"erpusers": "Usuarios de ERP",
			"terceiros": "Terceros",
			"concelhos": "Condados",
			"distritos": "Distritos",
			"freguesias": "Parroquias",
			"continentes": "Continentes",
			"repfinancas": "Oficinas de impuestos",
			"assistenteConfigPortais": "Asistente de configuración",
			"mapasamortizacao": "Mapas de amortización",
			"tiposcontrato": "Tipos de contrato",
			"divisoesTrabalho": "Divisiones de trabajo",
			"qppaises": "Países del personal",
			"qpdur": "Duraciones del marco personal",
			"seccoes": "Secciones",
			"nifs": "Números de identificación fiscal",
			"taxasiva": "tipos de IVA",
			"fimano": "fin de año",
			"calculodiferimentos": "Cálculo de aplazamientos",
			"extratos": "Estados de cuenta",
			"extratosgrid": "Estado de cuenta",
			"impdecpe": "Extracto del campo de declaración periódica",
			"ativosconfiguracoes": "Ajustes",
			"intfac": "Integración de documentos",
			"listagemdiarios": "Diarios contables",
			"ativosdepreciacao": "Depreciación de activos",
			"ativosimpressao": "Imprimir desde activos",
			"ativosmapasoficiais": "Imprimir mapas oficiales",
			"ativosgeramapasauto": "expediente fiscal",
			"analiticaconfig": "Configuración de la cuenta de análisis del plan de cuentas",
			"ativosaquisicaocontab": "Adquisición a partir de documento contable",
			"ativosabate": "Registrar asesinatos en serie",
			"ativosvenda": "Registrar ventas en serie",
			"entradasdiversas": "Insumos varios",
			"saidasdiversas": "Varias salidas",
			"extractosartigos": "Extracto del artículo",
			"grabonos": "Grupos de subsidio",
			"grdescontos": "Grupos de descuento",
			"ativosimport": "Importación de activos",
			"rhtipoprocess": "Tipos de procesamiento de recursos humanos",
			"rhconfiguracoes": "Configuración de recursos humanos",
			"rhdadosconfigficha": "Configuración de datos de la tarjeta",
			"tiposregistocadastro": "Tipos de registros de registro",
			"situacaoempregado": "Estado de empleado",
			"tipohorario": "Tipo de horario",
			"rhassistentetrabalhador": "Configurar la integración salarial",
			"rhtransferenciasbancarias": "Transferencias bancarias",
			"bancos": "Bancos",
			"idbancos": "Identificación bancaria",
			"sitpr": "Situación de las profesiones",
			"funcoes": "Funciones",
			"nivql": "Niveles de calificación",
			"qpsit": "Situaciones de búsqueda",
			"segur": "Compañías de seguros",
			"crss": "Centros del Régimen de Seguridad Social",
			"prof": "Profesión",
			"profissao": "Profesión",
			"conttrabcab": "Contrato de trabajo",
			"qpcnp": "Búsqueda de profesión",
			"cltrb": "Clases de Trabajo",
			"conttrabbaselegal": "Base jurídica del contrato de trabajo.",
			"conttrabmotivo": "Razones del contrato de trabajo.",
			"rhunidades": "Unidades de recursos humanos",
			"abdes": "Bonificaciones/Descuentos",
			"qphab": "Calificaciones",
			"qptco": "Tipo de contrato de personal",
			"abonos": "Asignaciones",
			"descontos": "Descuentos",
			"cgasi": "Códigos de situación C.G.A.",
			"remun": "Recopilación de datos",
			"remfx": "Recopilación de datos fijos",
			"rhpenhoras": "Gravámenes",
			"rhprocsegsocialconsultenvios": "Mon social - consulta de envío",
			"crdescontos": "Cruces de descuento",
			"crabonos": "Cruces de subsidios",
			"procseguradorafolhaferias": "Procesamiento de nómina de vacaciones para compañía de seguros.",
			"pcaRecibosVencimentos": "Recibos de nómina",
			"rhgestaoficheirosdmrat": "Gestión de archivos DMR AT",
			"estados": "Estados",
			"rendis": "Tipos de ingresos",
			"situacaoprofissional": "Estatus profesional",
			"qpmte": "Razones para la entrada de empleados",
			"cct": "Contratos colectivos de trabajo",
			"qpirt": "Investigación del IRCT",
			"qpisp": "código proveedor de servicios de Internet",
			"trfLancContas": "Transferencia de cuenta",
			"usersbasic": "Usuarios",
			"ativoscoeficientecm": "Actualizar coeficiente de corrección monetaria",
			"propostasaclientes": "Presupuestos",
			"encomendas": "Órdenes",
			"encomendasclientes": "Pedidos de clientes",
			"encomendasfornecedores": "Pedidos de proveedores",
			"ativosconsistencia": "Coherencia de valores entre activos y contabilidad",
			"docsComerciaisEstatisticasVendas": "Volumen de Negocio - Ventas por artículo",
			"docsComerciaisEstatisticasDocVendas": "Vol negocio - Ventas por documento",
			"docsComerciaisEstatisticasCompras": "Volumen de negocio: compras por artículo",
			"docsComerciaisEstatisticasDocCompras": "Vol negocio - Compras por documento",
			"clientconnect": "Conexión del cliente",
			"assistenteConfigPortaisPortalClientConnectUtilizador": "Portal de conexión del cliente: nueva configuración de usuario",
			"assistenteConfigPortaisPortalClientConnectUtilizadorExistente": "Portal de Client Connect: configuración de usuario existente",
			"documentosdigitais": "Documentos digitales",
			"documentosdigitaisConciliacao": "Conciliación de documentos digitales",
			"documentosDigitaisClientConnect": "Documentos digitales",
			"rhcessacontratotrabalho": "Genera rescisión de contrato",
			"rhmanutencao": "Mantenimiento de recursos humanos.",
			"rhcalculorendimentoliquido": "Cálculo por ingreso neto",
			"rhrecolhaseriesubsidiosferias": "Pago serial de subsidio de vacaciones",
			"rhrecolhaseriesubsidiosnatal": "Pago serial de asignación navideña",
			"rhrecolhaseriesubsidiosalimentacao": "Preprocesamiento en serie del subsidio de alimentación",
			"rhqpirct": "Mesas oficiales del personal del IRCT",
			"rhprocessamentosalarios": "Configurar el procesamiento de nómina",
			"rhfolfer": "Hoja de salario",
			"rhestatisticas": "Estadísticas de Recursos Humanos",
			"rhestatisticasmulti": "Estadísticas de Recursos Humanos (Multiempresa)",
			"modelo10": "Modelo 10",
			"retencoes": "Visualización de retenciones",
			"modelo22": "Modelo 22",
			"modelo25": "Modelo 25",
			"modelo30": "Modelo 30",
			"modelo39": "Modelo 39",
			"rhimpvenct": "Listados de estadísticas de vencimiento",
			"impctrirs": "Listados de control del IRS",
			"rhagencias": "Mantenimiento de bancos, agencias y cuentas.",
			"impfferpoc": "Clasificación de la hoja de salario",
			"rhtranssal": "Transferencias de salario",
			"trfba": "Pagos por transferencia bancaria",
			"trfbarh": "Pagos por transferencia bancaria",
			"trfbacomercial": "Pagos por transferencia bancaria",
			"contabAdvancedOperationsPeriodo": "Cambio de periodo del documento",
			"contabAdvancedOperationsDiario": "Cambiar el diario de documentos",
			"contabAdvancedOperationsAnular": "Cancelar documentos",
			"faturacaopublica": "Gestión de documentos públicos de facturación electrónica",
			"idide": "Grupos de cuentas",
			"impencar": "Lista de cargos",
			"impAvisosCobranca": "Imprimir avisos de facturación",
			"declaracaoiva": "Declaración periódica de IVA",
			"declaracaoivarecapitulativa": "declaración resumida del IVA",
			"vendascliente": "Resumen por cliente",
			"vendasartigo": "Resumen por artículo",
			"resumoiva": "Resumen del IVA",
			"definicoesemail": "Definición de correos electrónicos",
			"dashboard": "Panel",
			"marcarRefeicoes": "Reservar comidas",
			"visualizarRefeicoes": "Ver comidas",
			"extratosFornecedores": "Declaraciones de proveedores",
			"extratosClientes": "Declaraciones de clientes",
			"gto": "Gestión de tareas y obligaciones.",
			"entidadeexterna": "Entidades externas",
			"solic": "Textos de aviso de solicitud de cobro",
			"impdadosemp": "Lista de datos de empleados",
			"dmrf": "Declaración de retención mensual",
			"simulacaovencimento": "Simulación de recibo",
			"contratostipos": "Tipos de contratos",
			"contratostiposervicos": "Tipos de pactos",
			"concilmovab": "Justificación de los movimientos abiertos",
			"faturacaocontratos": "Recibo de facturación",
			"conciliacaobancaria": "conciliación bancaria",
			"bancosextrato": "Automatización bancaria",
			"bancosextratosimple": "Automatización bancaria",
			"sind": "expediente sindical",
			"utlviatpatronal": "Uso de vehículos del empleador.",
			"demfinsnc": "SNC estados financieros",
			"contratos": "Contratos",
			"contratosservicos": "Pactos",
			"transferenciasarmazens": "Traslado de artículos entre almacenes",
			"rhrelatoriounico": "Informe único",
			"contratosprint": "Impresión de contratos",
			"impde119": "Declaración Artº 119 CIRS",
			"tesrubrica": "Estados de validación de pronósticos del Tesoro",
			"prevites": "Previsiones del Tesoro",
			"previtesauto": "Lanzamiento automático de previsiones de tesorería.",
			"prevtesobras": "Importador de planillas financieras para obras.",
			"qpmts": "Razones de la salida del empleado",
			"officereporting": "Evolución empresarial",
			"ativosaquisicaoinvestemcurso": "Adquisición basada en inversiones en curso",
			"faturacaoeletronicaconfiguracoes": "Configuración de facturación electrónica",
			"segsocialfolhaferias": "Nómina de seguridad social/vacaciones",
			"integracaoSalarios": "Integración salarial",
			"proceSalarios": "Procesamiento de salario",
			"proceDMRAT": "Procesamiento DMR - AT",
			"proceSegSocial": "Tramitación de la seguridad social",
			"integracaoSalariosMulti": "Integración salarial (multiempresa)",
			"gestaoFicheirosSepa": "Gestión de archivos SEPA",
			"rhgozoferias": "disfruto de las vacaciones",
			"mbconfigs": "Configuración de referencias de cajeros automáticos",
			"rhatualizaferiasemp": "Actualizar los registros de vacaciones de los empleados",
			"rhmapassindicatos": "Mapa para sindicatos",
			"extratosDT": "Historial de estados de cuenta",
			"extratosDTClientes": "Estado de cuenta del cliente",
			"extratosDTFornecedores": "Estado de cuenta del proveedor",
			"infoglobaldiferimentosacrescimos": "Aplazamientos/adiciones de información global",
			"infoglobaldiferimentos": "Información de aplazamientos globales",
			"infoglobalacrescimos": "Adiciones de información global",
			"rhregistarempregadosss": "Consultar/Registrar empleados ante la seguridad social",
			"rhmanutencaocampos": "Actualizar campos de salario",
			"analisadorTes": "Planificación de tesorería",
			"importxls": "Importaciones",
			"pocscopy": "Copiar cuentas plan para empresas",
			"controloivaentreempresas": "Copiar control de IVA para empresas",
			"avisoscobrancagrid": "Avisos de facturación",
			"saftcontab": "Importador contable SAF-T",
			"tesestados": "Estados de validación de pronósticos del Tesoro",
			"guiasfornecedores": "Guías de proveedores",
			"lotes": "Lotes",
			"morals": "Direcciones alternativas",
			"arclis": "Artículo de cliente/proveedor",
			"clicls": "Clase",
			"mapaPagamentos": "Mapa de pago",
			"exportacaocompras": "exportación de geofolia",
			"planoscontasalternativos": "Planes de cuenta alternativos",
			"balancetes": "Balances contables",
			"balancetesalternativos": "Balances de planes alternativos",
			"balancetesanalitica": "Balances de contabilidad analítica",
			"comprasautofaturacao": "Autofacturación",
			"acrescimosencargoscomferias": "Cargos adicionales por vacaciones",
			"adiantamentosclientes": "Avances del cliente",
			"adiantamentosfornecedores": "Avances de proveedores",
			"rhCopiaAbonosDescontos": "Copiar asignaciones/descuentos",
			"trdocs": "Transformación de documentos",
			"cargveic": "Configuración del vehículo",
			"ativosaquisicaoinvestemcursopordoc": "Adquisición basada en inversiones en curso por documento",
			"manuttributautonomas": "Mantenimiento de la tributación autónoma",
			"tributautonomas": "Fiscalidad autonómica",
			"rendHabitPerm": "Uso de la vivienda del empleador",
			"mapaExploracao": "mapa de exploración",
			"colaboradoresAlteracoesEntidades": "Datos para cambiar",
			"colaboradoresAprovacoesRefeicoes": "Comidas a aprobar",
			"colaboradorboard": "Resumen",
			"colaboradoresassociaservicos": "asociado con el servicio",
			"colaboradoreshome": "Hogar",
			"colaboradoresservicos": "Como. roles a servicios",
			"colaboradorescomunicacoesinternas": "Comunicaciones",
			"colaboradoresdgemps": "Usuarios",
			"dgempsficha": "Datos personales",
			"documentosPartilhados": "Documentos compartidos",
			"dossierAlteracoesCapitalProprio": "Cambios en el patrimonio",
			"dossierAnexoDemonstracoesFinanceiras": "Anexo",
			"dossierBalanco": "Balance",
			"dossierDemonstracaoResultados": "Demonios.",
			"dossierFluxoCaixa": "Flujos de efectivo",
			"dossierResultadosPorNatureza": "Resultados por funciones",
			"empresas": "Empresa",
			"fct": "Fondo de Compensación",
			"colaboradoresfluxos": "Corrientes",
			"ies": "Exportar archivo IES-DA",
			"modules": "Módulos",
			"conciliacaoEfaturas": "Conciliación de facturas electrónicas",
			"pcaBalancetes": "Balances",
			"pcaExtratos": "Extractos",
			"pcaIdadeSaldosClientes": "Saldos de clientes",
			"pcaIdadeSaldosFornecedores": "Saldos de proveedores",
			"documentosFiscais": "Documentos fiscales",
			"movimentosemaberto": "Movimientos contables abiertos",
			"svat": "SVAT estados financieros",
			"pcaContas": "Identificación",
			"pcaDocumentosComerciais": "Facturación",
			"pcaExtratosConta": "Extracto",
			"pcaMovimentosAbertoCliente": "Movimientos abiertos",
			"pcaMapaFaltas": "Mapa de faltas",
			"pcaMapaFerias": "Mapa de vacaciones",
			"pcaFundosCompensacao": "Fondo de Compensación",
			"pcaMapaSeguradora": "mapa de seguros",
			"pcaSegurancaSocial": "Seguridad social",
			"pcaMapaSindicatos": "Mapa de sindicatos",
			"colaboradoresrecibosvencimento": "Ingresos",
			"colaboradoresAssociarMenus": "Menús asociados",
			"colaboradoresListagemMenus": "Listados de menú",
			"colaboradoresListagemRefeicoes": "Listados de comidas",
			"rptconfigs": "Configuración de informes",
			"inventarios": "Inventarios",
			"listagensVendasAnoMes": "Ventas por mes",
			"saft": "Exportar archivo SAF-T",
			"artars": "Artículos de almacén",
			"crneg": "Excepciones cruzadas",
			"docfasnums": "Serie de tipos de documentos",
			"docfasnumscomunicacaologin": "Comunicación de la serie de inicio de sesión",
			"docspartilhados": "Documentos de la plataforma de recursos humanos",
			"erputilizadores": "Usuarios de CentralGest",
			"erps": "Instalaciones",
			"fremun": "Formas de remuneración",
			"gdoc": "Gestión de archivos adjuntos",
			"indicefluxos": "Índices de flujo",
			"ivamotivostaxa": "Razones para la exención del IVA",
			"perfilCategoriaAbono": "Asignaciones de categorías de perfiles",
			"perfilCategoriaDesconto": "Descuentos en categorías de perfiles",
			"sscol": "Pagos de seguridad social",
			"taxonomias": "Taxonomías y validaciones de SVAT",
			"importadorEfatura": "Documentos a publicar eFatura",
			"importadorSaftDirect": "Importador SAF-T PT facturación para contabilidad",
			"atFaturasRecibosVerdes": "AT - Facturas y recibos verdes",
			"apuraiva": "Cálculo del IVA y fin de período",
			"apuramentoresultados": "Cálculo de resultados netos",
			"pcaContabilidadeMovimentosAbertoCliente": "Abrir transacciones de clientes",
			"pcaContabilidadeMovimentosAbertoFornecedor": "Movimientos abiertos de proveedores",
			"qpapa": "Asociación de empleadores",
			"qpnj": "Naturaleza Jurídica",
			"sectores": "Sectores",
			"docsComerciaisConfigNomeExportFile": "Configurar el nombre de los archivos a exportar",
			"txtar": "Textos de artículos gratuitos",
			"txtcl": "Textos sin entidades",
			"mapadiferimentosacrescimos": "Mapa de Altas y Aplazamientos",
			"prhtipocom": "Tipos de comunicación",
			"consultasafornecedores": "Presupuestos",
			"grupodocfaconfiguracoes": "Configuración del grupo de tipo de documento",
			"analisemargemlucro": "Análisis de margen de beneficio",
			"comcategorias": "Categorías de comunicación",
			"gestaoComunicacoes": "Gestión de comunicaciones",
			"gestaoComunicacoesCliente": "Gestión de comunicaciones con clientes."
		},
		"form": {
			"username": "Usuario",
			"username.placeholder": "Su nombre para autenticación",
			"usernameemail.placeholder": "Nombre de usuario o correo electrónico",
			"newpassword": "Nueva contraseña",
			"newpassword.placeholder": "tu nueva contraseña",
			"confirmpassword": "Confirmación de contraseña",
			"confirmpassword.placeholder": "Confirmación de contraseña",
			"email": "Correo electrónico",
			"email.placeholder": "tu correo electrónico",
			"notApplicable": "N / A"
		},
		"text": {
			"notDefined": "N / A",
			"note": "Aviso",
			"filters": "Filtros",
			"errorNum": "Error #{{error}}.",
			"contactAdmin": "Por favor contacte al administrador.",
			"empty": " ",
			"success": "Éxito",
			"info": "Información",
			"warning": "Aviso",
			"error": "Error",
			"confirmation": "Confirmación",
			"help": "Ayuda",
			"faq": "Preguntas frecuentes",
			"other": "Otros",
			"year": "Año",
			"month": "Mes",
			"date": "Fecha",
			"fromDate": "Fecha de",
			"toDate": "Fecha hasta",
			"to": "Hasta",
			"total": "Total",
			"saldo": "Balance",
			"listing": "Listado",
			"searchfilter": "Filtros de búsqueda",
			"keeppanel": "Mantener el panel abierto",
			"all": "Todo",
			"general": "General",
			"actions": "Comportamiento",
			"attention": "Atención",
			"preview": "Avance",
			"movimentosPendentes": "Movimientos pendientes",
			"active": "Activo",
			"initProcess": "Iniciando procesamiento...",
			"searchNoData": "No se encontraron datos",
			"and": "y",
			"or": "o",
			"myAccount": "mi cuenta",
			"deleteRecord": "Eliminar registro",
			"name": "Nombre",
			"today": "Hoy"
		},
		"language": {
			"pt": "portugués",
			"en": "Inglés"
		},
		"btn": {
			"actions": "Comportamiento",
			"add": "para agregar",
			"addLine": "Agregar línea",
			"advancedSearch": "Búsqueda avanzada",
			"apply": "Aplicar",
			"approve": "Aprobar",
			"attach": "Adjuntar",
			"back": "para volver",
			"calculate": "Calcular",
			"cancel": "Cancelar",
			"change": "alterar",
			"changeEmpresa": "Cambiar de empresa",
			"clone": "Clon",
			"close": "para cerrar",
			"config": "Ajustes",
			"configWS": "Configuración de WS",
			"confirm": "Confirmar",
			"create": "para crear",
			"createFile": "Crear archivo",
			"delete": "Para apagar",
			"downloadPdf": "Descargar PDF",
			"edit": "Editar",
			"email": "Correo electrónico",
			"excel": "Sobresalir",
			"export": "Exportar",
			"exportPdf": "Exportar PDF",
			"exportXls": "exportar excel",
			"finalize": "Finalizar",
			"goBack": "Rebobinar",
			"goForward": "Próximo",
			"help": "Ayuda",
			"import": "Importar",
			"importExport": "Importar/Exportar",
			"load": "para cargar",
			"mark": "para marcar",
			"new": "Nuevo",
			"next": "Siguiente",
			"no": "No",
			"nullify": "Cancelar",
			"ok": "DE ACUERDO",
			"options": "Opciones",
			"pdf": "PDF",
			"preview": "Avance",
			"previous": "Anterior",
			"print": "Imprimir",
			"process": "Proceso",
			"processExcel": "Proceso en Excel",
			"processGrelha": "Procesar en parrilla",
			"processPDF": "Procesar PDF",
			"refresh": "Para actualizar",
			"register": "Registro",
			"reject": "Rechazar",
			"remove": "Eliminar",
			"replenish": "Reiniciar",
			"replenishOriginalValues": "Restablecer valores originales",
			"retry": "Intentar otra vez",
			"revert": "Contrarrestar",
			"save": "Ahorrar",
			"search": "buscar",
			"send": "para enviar",
			"sendFiles": "enviar archivos",
			"sendMail": "Enviar correo electrónico",
			"shortcuts": "Atajos",
			"simulate": "Simular",
			"store": "Almacenar",
			"submeterAt": "Enviar AT",
			"update": "Para actualizar",
			"validarAt": "Validar en AT",
			"validarFicheiro": "Validar archivo",
			"visualize": "Vista",
			"yes": "Sí"
		},
		"saveDoc": "guardar documento",
		"saveDocId": "Guardar documento {{id}}",
		"saveDocSimulation": "Guardar documento (simulación)",
		"saveDocMessage": "¿Está seguro de que desea guardar el documento?",
		"saveDocMessageSimulation": "¿Está seguro de que desea simular guardar el documento?",
		"advancedFilter": "Filtro avanzado",
		"debitCredit": {
			"debit": "Deuda",
			"credit": "Crédito"
		},
		"states": {
			"admin": {
				"title": "Administración"
			}
		},
		"allRightsReserved": "Reservados todos los derechos",
		"export": {
			"toExcel": "Exportar a Excel",
			"toPDF": "Exportar a PDF"
		},
		"keyboard": {
			"arrowLeft": "Flecha izquierda",
			"arrowRight": "Flecha derecha"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-triángulo de exclamación-advertencia de texto' estilo='color: naranja;'",
			"exitMessage": "Al hacer clic en Aceptar, saldrá sin guardar y se eliminarán todos los cambios que haya realizado."
		},
		"months": {
			"january": "Enero",
			"february": "Febrero",
			"march": "Marzo",
			"april": "Abril",
			"may": "Puede",
			"june": "Junio",
			"july": "Julio",
			"august": "Agosto",
			"september": "Septiembre",
			"october": "Octubre",
			"november": "Noviembre",
			"december": "Diciembre"
		},
		"dayOfWeek": {
			"monday": "Lunes",
			"tuesday": "Martes",
			"wednesday": "Miércoles",
			"thursday": "Jueves",
			"friday": "Viernes",
			"saturday": "Sábado",
			"sunday": "Domingo"
		},
		"monthsShort": {
			"january": "Ene",
			"february": "Feb",
			"march": "Mar",
			"april": "Abr",
			"may": "Puede",
			"june": "Junio",
			"july": "Jul",
			"august": "Ago",
			"september": "Colocar",
			"october": "Oct",
			"november": "Nov",
			"december": "Diez"
		}
	},
	"disconnected": {
		"title": "Desconectado del servidor",
		"message": "Por favor verifique su conexión e inténtelo nuevamente."
	},
	"entity": {
		"action": {
			"addblob": "Agregar gota",
			"addimage": "Agregar imagen",
			"back": "para volver",
			"cancel": "Cancelar",
			"delete": "Para apagar",
			"edit": "Editar",
			"save": "Ahorrar",
			"view": "Ver",
			"new": "Nuevo",
			"filter": "Filtrar",
			"refresh": "Para actualizar",
			"retrieveBackup": "Recuperar el último documento",
			"duplicate": "Duplicado",
			"actions": "Comportamiento",
			"config": "Ajustes",
			"tooltip": {
				"duplicate": "Crear nuevo basado en esto"
			}
		},
		"state": {
			"saved": "Guardado exitosamente",
			"error": "No se puede guardar, verifique los datos ingresados"
		},
		"list": {
			"withoutdata": "sin datos",
			"loading": "lectura...",
			"secondaryClick": {
				"menu": {
					"openAsModal": "Vista",
					"openAsNewTabOrWindow": "Abrir en nueva ventana o pestaña"
				}
			}
		},
		"placeholder": {
			"search": "Buscar"
		},
		"detail": {
			"field": "Campo",
			"value": "Valor"
		},
		"delete": {
			"title": "¿Eliminar registro {{id}}?",
			"message": "¿Estás seguro de que deseas eliminar?"
		},
		"validation": {
			"required": "Este campo es obligatorio",
			"minlength": "Este campo debe tener al menos {{min}} caracteres.",
			"maxlength": "Este campo no puede tener más de {{max}} caracteres.",
			"min": "Este campo debe ser mayor que {{min}}.",
			"max": "Este campo debe ser menor que {{max}}.",
			"minbytes": "Este campo debe tener más de {{min}} bytes.",
			"maxbytes": "Este campo no puede tener más de {{max}} bytes.",
			"pattern": "Este campo debe seguir el patrón {{pattern}}.",
			"datetimelocal": "Este campo debe ser una fecha y hora.",
			"email": "El correo electrónico no es válido.",
			"patern": "El valor no es correcto {{info}}",
			"number": "No es un número válido",
			"fieldMatch": "no coincide",
			"vat": "El número de impuesto no es válido"
		},
		"autocomplete": {
			"errorAllowInvalid": "El valor ingresado no es válido",
			"newItem": "Nuevo {{attrName}}",
			"createNewItem": "{{attrName}} no existe."
		},
		"maintenance": {
			"headerSearch": "Buscar {{entityName}}",
			"headerMaintenance": "Mantenimiento de {{entityName}}"
		}
	},
	"error": {
		"server": {
			"unavailable": "El servidor no es accesible.",
			"badRequest": "El servidor rechazó su solicitud.",
			"unauthorized": "No está autenticado.",
			"forbidden": "No está autorizado a realizar esta solicitud.",
			"notFound": "Página o pedido no encontrado.",
			"internalServerError": "Error Interno del Servidor.",
			"serviceUnavailable": "El sistema está en mantenimiento."
		},
		"invalidForm": "No se puede guardar, verifique los datos ingresados"
	},
	"toolbar": {
		"year": "Año: {{valor}}",
		"month": "Mes: {{valor}}",
		"period": "Período: {{valor}}"
	},
	"grdescontos": {
		"title_detail": "Grupo de descuento {{id}}",
		"title_new": "Nuevo grupo de descuento",
		"title_plural": "Grupos de descuento",
		"pesquisa": "buscar",
		"saved": "Grupo de descuento {{id}}, guardado correctamente",
		"error": "Grupo de descuento {{id}}, no se puede guardar.",
		"deleted": "Grupo de descuento {{id}}, eliminado correctamente",
		"fields": {
			"codGrDesconto": "Código",
			"descricao": "Descripción",
			"categoria": "Categoría",
			"assiduidadeSegSocial": "Asistencia por segundo.",
			"absentismo": "Absentismo",
			"suspensaoContrato": "Suspensión de contrato",
			"remunerado": "Pagado",
			"recibo": "Recibo",
			"entidadeSuporta": "Entidad de apoyo",
			"ausencias": "Ausencias",
			"justificacaoAusencia": "Justificación de la ausencia",
			"balancoSocial": "equilibrio social",
			"horasNaoTrabalhadas": "Horas no trabajadas",
			"contaDiasFeriasNorm": "Cuenta para días normales de vacaciones.",
			"contaDiasFeriasAcima": "Cuenta de días de vacaciones superiores a lo normal",
			"contaDiasSubNatal": "Cuenta de días subnatales",
			"contaDiasSubAlimen": "Cuenta para días de subalimentación"
		}
	},
	"grabonos": {
		"title_detail": "Grupo de asignación {{id}}",
		"title_new": "Nuevo grupo de asignación",
		"title_plural": "Grupos de subsidio",
		"pesquisa": "buscar",
		"saved": "Grupo de asignación {{id}}, guardado exitosamente",
		"error": "Grupo de asignación {{id}}, no se puede guardar.",
		"deleted": "Grupo de asignación {{id}}, eliminado correctamente",
		"fields": {
			"codGrAbono": "Código",
			"descricao": "Descripción",
			"categoria": "Categoría",
			"assiduidadeSegSocial": "Asistencia por segundo.",
			"presencas": "Asistencia",
			"remunerado": "Pagado",
			"recibo": "Recibo",
			"entidadeSuporta": "Entidad de apoyo",
			"tipoTrabalho": "tipo de trabajo",
			"balancoSocial": "equilibrio social",
			"contaDiasFeriasNorm": "Cuenta para días normales de vacaciones.",
			"contaDiasFeriasAcima": "Cuenta de días de vacaciones superiores a lo normal",
			"contaDiasSubNatal": "Cuenta de días subnatales",
			"contaDiasSubAlimen": "Cuenta para días de subalimentación",
			"formacao": "Capacitación"
		}
	},
	"grandesfamilias": {
		"title_detail": "Gran familia {{id}}",
		"title_new": "Nueva gran familia",
		"title_plural": "Familias numerosas",
		"pesquisa": "buscar",
		"deleted": "Grande Família {{id}}, eliminado con éxito.",
		"saved": "Gran Familia {{id}}, guardada exitosamente.",
		"error": "Gran Familia {{id}}, no se puede guardar.",
		"fields": {
			"nDepart": "Código.",
			"nDepartPlaceholder": "código de familia grande",
			"nome": "Nombre",
			"nomePlaceholder": "gran apellido"
		}
	},
	"gto": {
		"title": "Gestión de tareas y obligaciones",
		"tabs": {
			"title": {
				"porEmpresa": "Por empresa",
				"multiEmpresa": "Multiempresa",
				"ativarTarefas": "Activar tareas",
				"configuracoes": "Ajustes",
				"configuracoesMulti": "Configuraciones multiempresa"
			},
			"filters": {
				"empresa": "Empresa",
				"tipotarefa": "Tipo de tarea",
				"responsavel": "Responsable",
				"mostrarfilter": "Espectáculo",
				"periodoiva": "Período de IVA",
				"regimeirc": "Régimen IRC/IRS",
				"nomeTarefa": "Nombre de la tarea"
			},
			"table": {
				"fields": {
					"nometarefa": "Nombre de la tarea",
					"nempresa": "empresa no.",
					"sel": "Sel."
				},
				"semdados": "Sin datos"
			},
			"btn": {
				"checkstatedmratss": "Verifique el estado de DMR AT y SS",
				"checkstatedmrss": "Verifique el estado de DMR SS",
				"checkstatedmrat": "Verifique el estado de DMR AT",
				"dmrat": "DMR EN",
				"dmrss": "DMR SS",
				"alertas": "Alertas",
				"filtroavanc": "Filtro avanzado"
			},
			"actions": {
				"executartarefa": "Ejecutar tarea",
				"concluirtarefa": "tarea completa",
				"conctarefdentroprazo": "Completar la tarea dentro del plazo",
				"editartarefa": "Editar tarea",
				"desativartarefa": "Desactivar tarea",
				"estatisticas": "Estadística"
			}
		},
		"toolbar": {
			"createNewTask": "Crear tarea",
			"config": "Configurar",
			"copyTasks": "Copiar tareas"
		},
		"nomeTarefa": "Nombre de la tarea",
		"tipoTarefa": "Tipo de tarea",
		"obrigacoes": "Obligaciones",
		"pagamentos": "Pagos",
		"outra": "Otro",
		"dataInicioPeriodo": "Fecha de inicio del periodo",
		"mes1anterior": "Mes - 1",
		"mes2anterior": "Mes - 2",
		"ano1anterior": "Año - 1",
		"entidadeExterna": "Entidad externa",
		"createNewTaskTitle": "Crear nueva tarea",
		"editTaskTitle": "Editar tarea",
		"applyAllEmpresas": "Actualiza el calendario en todas las empresas que tienen esta tarea",
		"tarefa": "Tarea",
		"tarefas": "Tareas",
		"config": "Ajustes",
		"empresa": "Empresa",
		"ativarGTONaEmpresa": "Activar la gestión de tareas y obligaciones.",
		"alertasNaApp": "Alertas en la aplicación",
		"inicio": "Comenzar",
		"fields": {
			"ativar": "Activar",
			"nomeTarefa": "Nombre de la tarea",
			"responsavel": "Responsable",
			"nEmpresa": "empresa no.",
			"nomeEmpresa": "Nombre",
			"regimeIRCIRS": "Régimen IRC/IRS",
			"periodoIVA": "Período de IVA",
			"sel": "Sel."
		},
		"invalidSelectedDay": "Día no válido",
		"saveConfig": "Guardar configuración",
		"legends": {
			"concluidoDentroPrazo": "Completado a tiempo",
			"guardarValidacao": "Bajo validación",
			"emAtrasoJustificado": "En retraso justificado",
			"concluidoForaPrazo": "Completado después de la fecha límite",
			"emAtraso": "Atrasado",
			"prazoADecorrer": "Fecha límite para correr",
			"naoAplicavel": "No aplicable",
			"pendente": "Pendiente"
		},
		"datasources": {
			"tipotarefa": {
				"obrigacoes": "Obligaciones",
				"pagamentos": "Pagos",
				"outra": "Otro",
				"todos": "Todo"
			},
			"mostrarfilter": {
				"datalimite": "Fecha límite",
				"datalimitePlusNotes": "Plazo y notas",
				"values": "Valor",
				"valuesPlusNotes": "Valor y notas",
				"dias": "Días",
				"diasPlusNotes": "dias y notas",
				"dataexecucao": "Fecha de ejecución",
				"dataexecucaoPlusNotes": "Fecha de ejecución y notas."
			}
		},
		"copyTasksModalTitle": "Copiar tareas",
		"copiar": "Copiar",
		"copyFor": "Copiar a",
		"iraSubstituirTarefasNoDestino": "El procedimiento creará o reemplazará las tareas en el objetivo.",
		"iraCriarAtualizarTarefasNoDestino": "El procedimiento creará o actualizará las tareas en el objetivo.",
		"temQueSelecionarUmaTarefa": "¡Debes seleccionar al menos una tarea!",
		"temQueSelecionarUmaEmpresa": "¡Debes seleccionar al menos una empresa!",
		"tarefasCopiadasComSucesso": "Las tareas seleccionadas se copiaron exitosamente.",
		"paraAno": "Por año",
		"paraEmpresas": "Para empresa(s)",
		"naoPodeCopiarParaMesmoAno": "No puedes copiar tareas del mismo año.",
		"executarTarefa": "Ejecutar tarea",
		"estado": "Estado",
		"estados": {
			"semAlteracao": "Sin cambios",
			"concluir": "Finalizar",
			"adicionarJustificacao": "Agregar justificación de retraso",
			"naoAplicavel": "No aplicable",
			"new": "No completado",
			"guardarValidacao": "Bajo validación"
		},
		"messages": {
			"taskfromotheruser": "Esta tarea corresponde al otro responsable.",
			"validacaosuccess": "¡Verificación completada exitosamente!",
			"selempreforcheck": "¡Seleccione empresas para verificar el estado del envío!",
			"temACertezaQuePertendeConcluirTarefasSelecionadas": "¿Está seguro de que desea completar las tareas seleccionadas?",
			"temACertezaQuePertendeReporTarefas": "¿Está seguro de que desea restablecer las tareas predeterminadas de la empresa \"{{nCompany}}\" al año \"{{year}}\"?",
			"jaExistemTarefasCriadas": "Ya hay tareas creadas para la empresa."
		},
		"alertsModalTitle": "Alertas",
		"valor": "Valor",
		"justificacao": "Justificación",
		"dataExecucao": "Fecha de ejecución",
		"estaTarefaPertenceAoOutroResponsavel": "Esta tarea corresponde al otro responsable.",
		"operacaoConcluidaComSucesso": "La operación se completó con éxito.",
		"temACertezaQuePertendeApagarEstaTarefa": "¿Estás seguro de que deseas eliminar esta tarea?",
		"tarefaEliminadaComSucesso": "¡Tarea eliminada exitosamente!",
		"licensemodal": {
			"total": "Número total de empresas: {{total}}",
			"totalConsumed": "Número total de empresas ya agregadas: {{totalConsumed}}",
			"totalToConsume": "Número total de empresas que aún puedes agregar: {{totalToConsume}}",
			"license1": "Si hace clic en \"Sí\", el sistema agregará una nueva empresa a su licencia de gestión de tareas y obligaciones, y no será posible revertir la situación posteriormente.",
			"license2": "En este momento, aún puede agregar {{totalToConsume}} empresas a su licencia de administración de tareas y obligaciones.",
			"license3": "Datos de licencia de gestión de tareas y obligaciones",
			"license4": "En este momento, no puede agregar empresas a su licencia de gestión de tareas y obligaciones."
		},
		"aSuaLicencaNaoTemGTO": "Tu licencia no tiene gestión de tareas y obligaciones.",
		"selectAll": "Seleccionar todo",
		"unSelectAll": "Borrar selección",
		"loadingLicenseData": "Cargando datos de licencia...",
		"naoTemEmpresasSuficientes": "No puedes continuar con la operación porque tu licencia no tiene suficientes empresas para consumir.<br/><br/>Número total de empresas que aún puedes agregar: {{totalToConsume}}<br/>Número total de empresas que agregarás: {{totalToAdd}}",
		"temACertezaQuePretendeAddEmpresas": "¿Estás seguro de que quieres agregar {{nempresas}} empresa(s) a tu licencia de gestión de tareas y obligaciones?",
		"hints": {
			"concluidoDentroPrazo": "La tarea fue ejecutada el {{dataExec}} (a tiempo) por {{user}}",
			"concluidoForaPrazo": "La tarea fue ejecutada en {{dataExec}} (fuera de tiempo) por {{user}}",
			"emAtrasoJustificado": "La tarea atrasada.",
			"valor": "<b>Valor</b>: {{valor}}",
			"nota": "<b>Nota</b>: {{nota}}",
			"selecaoMultiplaText": "Presione Ctrl y haga clic con el botón izquierdo en un elemento para realizar una selección no consecutiva, o presione el botón izquierdo del mouse y arrastre sobre los elementos.",
			"inicializaRepoeTarefasPorDefeito": "Inicializa/restablece las tareas predeterminadas."
		},
		"selecaoMultipla": "Selección múltiple"
	},
	"helperLinks": {
		"global": {
			"all": "Ver todos los vídeos",
			"faqs": "Ver preguntas frecuentes"
		}
	},
	"helptopics": {
		"titles": {
			"tutorialVideos": "Tutorial - Vídeos",
			"formationsVideos": "Entrenamientos - Vídeos",
			"forumCG": "Foro CentralGest",
			"facebookUserGroup": "Grupo de usuarios de CentralGest en Facebook"
		}
	},
	"tiposCategoriasNaoTrabalhadas": {
		"data": {
			"tDhntNA": "EN",
			"tDhntPorFormacaoProf": "por Formación Profesional",
			"tDhntPorReducaoLegalActividade": "por Reducción Legal de Actividad",
			"tDhntPorDesempregoInterno": "por Desempleo Interno",
			"tDhntPorDescansoSuplementar": "por descanso suplementario"
		}
	},
	"idadesaldos": {
		"label": {
			"grupoConta": "grupo de cuentas",
			"showGrupoConta": "Mostrar grupo de cuentas"
		}
	},
	"idbancos": {
		"title_detail": "ID del banco {{id}}",
		"title_new": "Nueva identificación bancaria",
		"title_plural": "Identificación bancaria",
		"pesquisa": "buscar",
		"saved": "La identificación bancaria {{id}} se guardó correctamente.",
		"error": "El ID bancario {{id}} no se puede guardar.",
		"deleted": "ID del banco {{id}} eliminado correctamente",
		"fields": {
			"idinterBancario": "código interbancario",
			"nomeInterBancario": "Nombre interbancario",
			"descricao": "Descripción",
			"formatoTRFAG": "Formato TrfPag",
			"formatoTRFREC": "Formato TRFRec",
			"inativo": "Inactivo"
		}
	},
	"idide": {
		"title_detail": "Grupo de cuentas {{id}}",
		"title_new": "Nuevo grupo de cuentas",
		"title_plural": "Grupos de cuentas",
		"pesquisa": "buscar",
		"saved": "Grupo de cuentas {{id}}, guardado correctamente",
		"error": "Grupo de cuentas {{id}}, no se puede guardar.",
		"deleted": "Grupo de cuentas {{id}}, eliminado correctamente",
		"fields": {
			"idIdeChav": "Código",
			"descricao": "Descripción",
			"tipoCred": "Tipo de crédito",
			"credito1": "Crédito 1",
			"credito2": "Crédito 2",
			"credito3": "Crédito 3",
			"credito4": "Crédito 4",
			"credito5": "Crédito 5"
		}
	},
	"ies": {
		"tabProcess": "Exportar",
		"tabDownload": "Archivo IES-DA",
		"generation": "Generación de archivos",
		"fileNotProcessed": "Lo sentimos, pero por alguna razón el archivo no se generó.",
		"jobTimeoutModalTitle": "Tarea",
		"jobTimeoutModalMessage": "La tarea ha expirado.",
		"contabilidade": "Contabilidad",
		"faturacao": "Facturación",
		"processar": "Proceso",
		"processingMessage": "Procesando, espere un momento.",
		"erros": "Errores",
		"fileReady": "El archivo IES-DA está listo.",
		"promptReprocessTitle": "Ya existe un archivo IES-DA generado previamente",
		"promptReprocessMessage": "Si continúa, el sistema generará el archivo nuevamente.",
		"warningMessage": "Atención: Luego de iniciar el proceso, tienes 60 minutos para descargar el archivo generado.",
		"promptCancelProcessMessage": "¿Estás seguro de que deseas cancelar el proceso?",
		"dataCriacao": "Fecha de creación",
		"tamanho": "Tamaño",
		"utilizador": "Usuario",
		"documentos": "Documentos",
		"buttons": {
			"reiniciar": "Reanudar",
			"download": "Descargar",
			"yes": "Sí",
			"no": "No",
			"fechar": "para cerrar"
		},
		"erroDownload": "¡Ocurrió un error al descargar el archivo!",
		"exportInProcessModalMessageByOtherUser": "No se puede iniciar el proceso de exportación en esta empresa porque ya lo inició el otro usuario",
		"enviarDeclaracao": "Enviar declaración",
		"fields": {
			"anexo": "Anexo",
			"campo": "Campo",
			"descricao": "Descripción",
			"mensagem": "Mensaje"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmación",
		"leavePromptMessage": "Al hacer clic en SÍ, saldrá y descartará todos los datos de procesamiento."
	},
	"impctrirs": {
		"fields": {
			"deCodEmp": "código de empleado"
		},
		"btn": {
			"previsualizar": "Avance"
		}
	},
	"impde119": {
		"filters": {
			"dedata": "Fecha de",
			"decodemp": "Empleado de",
			"dencontribuinte": "Número de contribuyente de",
			"filtrarPor": "Filtrar por",
			"empregado": "Colaboradores",
			"outros": "Proveedores de servicios",
			"ambos": "Ambos"
		}
	},
	"impdecpe": {
		"doPeriodo": "período de",
		"doNCampo": "campo nro.",
		"labelCriterios": "Criterios de consulta"
	},
	"impdoc": {
		"title": "Impresión de documentos comerciales",
		"modalTitle": "Documento {{nDoc}} - {{clifo}}",
		"label": {
			"emailcliente": "Correo electrónico",
			"enviar": "para enviar",
			"documento": "Documento",
			"nvias": "Número de carriles",
			"segundaVia": "Segunda copia",
			"rptName": "Informe"
		},
		"email": {
			"emailEnviado": "Correo electrónico enviado a {{email}}",
			"emailErro": "Error al enviar el correo electrónico a {{email}}."
		},
		"placeholder": {
			"email": "\"correo@ejemplo.com\" o \"correo@ejemplo.com; correo2@ejemplo.com\""
		},
		"faturaDigital": {
			"seraEmitidaPor": "La factura electrónica será emitida por {{certificate}}",
			"emitida": "Documento con factura electrónica emitida",
			"clienteSemFatDigital": "Cliente sin factura electrónica",
			"tipoDocSaftNaoConfigurada": "Tipo de documento SAF-T (FT,FR,ND,NC) no configurado para emitir factura electrónica",
			"semConfiguracao": "No hay factura electrónica configurada",
			"hintSemConfiguracao": "Producto sujeto a licencia, encuentre información en www.centralgestcloud.com"
		},
		"btn": {
			"faturacaoPublica": "Facturación pública"
		},
		"tooltip": {
			"avisoEnviar": "Sólo puedes enviar documentos terminados."
		}
	},
	"impencar": {
		"fields": {
			"dataDe": "Fecha de",
			"codEmpDe": "código de empleado",
			"codEncargoDe": "código de carga"
		},
		"btn": {
			"previsualizar": "Avance"
		},
		"infos": {
			"tooltipExcepcoesProcessamento": "Procesamiento de excepciones"
		}
	},
	"impfferpoc": {
		"btn": {
			"previsualizar": "Avance"
		},
		"filtropesq": "Búsqueda avanzada",
		"fields": {
			"dataProcessamento": "Fecha de procesamiento"
		}
	},
	"importadorEfatura": {
		"configPreDefinidosTitle": "Configuración de entidad",
		"showLastDocs": "Mostrar los últimos documentos",
		"hideLastDocs": "Ocultar documentos más recientes",
		"importarEmSerieModalTitle": "Lanzando documentos en serie...",
		"importarEmSerieModalMessage": "Lanzamiento de documentos en serie.",
		"definirPredefinidoModalTitle": "Establecer ajustes preestablecidos",
		"cantSelectRowDueInvalidPredefinido": "No puede seleccionar el registro porque no está predefinido.",
		"fields": {
			"nifEmitente": "NIF",
			"nomeEmitente": "Nombre",
			"numerodocumento": "Doc no.",
			"dataEmissaoDocumento": "Fecha de asunto",
			"actividadeDesc": "Actividad",
			"tipoDocumentoDesc": "Tipo",
			"fornecedor": "Proveedor",
			"registadoPor": "Registrado por",
			"actividadeProfDesc": "Ámbito de actividad",
			"valorTotalBaseTributavel": "Base imponible",
			"valorTotalIva": "valor del IVA",
			"valorTotal": "Valor total",
			"preDefinido": "Predefinido",
			"baseTributavel": "Base imponible",
			"taxa": "Base de la tarifa",
			"impostoSelo": "Impuesto de timbre",
			"total": "Valor total"
		},
		"configs": {
			"showColumnActividade": "Mostrar columna <Actividad>",
			"showColumnAmbitoActividade": "Mostrar columna <Alcance de la actividad>",
			"showColumnRegistadoPor": "Mostrar columna <Registrado por>"
		},
		"itemShowDetalhesToolbarBtn": "Mostrar detalles",
		"itemHideDetalhesToolbarBtn": "Ocultar detalles",
		"docSearchTabCaption": "Búsqueda de documentos",
		"criarDoc": "Crear documento",
		"actions": {
			"configSupplier": "Configurar proveedor",
			"newAccount": "Generar nueva cuenta corriente",
			"extratosConta": "Estados de cuenta"
		},
		"modal": {
			"createNewContaCorrente": {
				"title": "Crear nueva cuenta corriente",
				"confirmModal": {
					"confirmTitle": "Ver el perfil del proveedor",
					"confirmMessage": "Cuenta actual del proveedor <strong>creada con éxito</strong>.<br/>¿Quieres ver el archivo del proveedor creado?"
				},
				"body": {
					"radical": "Radical",
					"ncontacriar": "Número de cuenta para crear",
					"nome": "Nombre",
					"contrib": "Contribución nº."
				}
			}
		},
		"tooltip": {
			"criarNovaContaCorrente": "Crear nueva cuenta corriente",
			"extratoCorrente": "Estados de cuenta"
		},
		"warningImportarEmSerieContabilidadeDigital": "Ha elegido publicar documentos seriados en una empresa con archivo digital.<br/>El sistema adjuntará automáticamente los documentos digitales sugeridos, verifique que estén correctamente clasificados.<br/>¿Está seguro de que desea continuar?",
		"docDigital": {
			"0": "No hay documentos posibles",
			"1": "Con un posible documento",
			"2": "Con varios documentos posibles.",
			"naoTem": "No hay documentos posibles",
			"tem": "Con un posible documento",
			"temSemelhante": "Con varios documentos posibles."
		},
		"atMessagePrefix": "El portal de finanzas (AT) devolvió el siguiente mensaje"
	},
	"importadorSaftDirect": {
		"strings": {
			"modalTitleEdit": "Editar",
			"modalSave": "Aplicar",
			"modalCancel": "Cancelar",
			"correspContasContaSaftLabel": "Cuenta SAFT",
			"correspContasContaCGLabel": "Cuenta CentralGest",
			"taxasIvaSaftLabel": "Tarifas SAFT",
			"taxasIvaCGLabel": "Tarifas de CentralGest",
			"artigosConfigModalTitle": "Configuración del artículo(s)",
			"artigosNewConfigModalTitle": "Configurar nuevos artículos",
			"correspCCAssociarModalTitle": "Cuenta corriente asociada",
			"modalAssociar": "Asociado",
			"modalAttribuir": "Asignar",
			"configArtigosSaftLabel": "Artículos SAFT",
			"configArtigosCGLabel": "Datos para crear una cuenta de base imponible de CentralGest",
			"jobTimeoutModalTitle": "Tarea",
			"jobTimeoutModalMessage": "La tarea ha expirado.",
			"verContasFormadasPeloArtigo": "Ver base imponible cuentas a formar por artículo",
			"verGenDoc": "Ver documento",
			"applyProductConfig": "Aplicando configuración...",
			"savingProductsConfig": "Aplicando configuración del artículo...",
			"noDocsPaymentsToImports": "Los documentos contenidos en el fichero SAFT ya han sido importados a contabilidad previamente",
			"timeoutModalMessage": "Lo sentimos, pero el límite de tiempo para importar el archivo SAF-T PT ha expirado.",
			"porTipoDeDocumento": "Centro de Costo por tipo de documento",
			"porTipoDocumentoReparticao": "Desglose por tipo de documento",
			"porCliente": "Centro de coste por cliente",
			"porClienteReparticao": "Desglose por cliente",
			"porArtigo": "Centro de costo por artículo",
			"porArtigoReparticao": "Desglose por artículo"
		},
		"steps": {
			"common": {
				"semDados": "¡No hay datos para mostrar!",
				"semValoresImportar": "Debes seleccionar al menos uno de los valores a importar"
			},
			"welcome": {
				"wizardCaption": "Bienvenido",
				"contentText1": "Bienvenido al asistente de facturación del importador SAF-T PT para contabilidad directa.",
				"contentText2": "Este asistente lo guiará a través del proceso de importación.",
				"contentText3": "Haga clic en \"Siguiente\" para continuar con la importación.",
				"contentText4": "Luego de iniciar el proceso, tendrás 2 horas para completar la importación.",
				"uploadLimit": "Su plan actual le permite procesar archivos de hasta 20 MB.",
				"uploadLimitInfo": "El procesamiento de archivos de más de 20 MB sólo está permitido después de las 6:00 p. m. todos los días.",
				"uploadLimitPremium": "Su plan actual le permite procesar archivos de hasta 20 MB.",
				"uploadLimitPremiumExtended": "Su plan actual le permite procesar archivos de hasta 50 MB."
			},
			"escolhaTipoImportacao": {
				"wizardCaption": "Tipo de importación",
				"text1": "Por favor elige el tipo de importación que deseas realizar",
				"text2": "Si elige importar CentralGest Cloud, solicite a su cliente que cree un usuario en la plataforma en la nube de CentralGest.",
				"tiposImportacao": {
					"title": "Tipo de importación",
					"ficheiroSaft": "Archivo de facturación SAF-T",
					"centralGestCloud": "Nube CentralGest",
					"centralGestPOS": "Zona TPV Suave",
					"centralGestPOSWinTouch": "POS Wintouch"
				}
			},
			"upload": {
				"wizardCaption": "Archivo SAFT",
				"title": "Por favor elija el archivo que desea importar",
				"processarFicheiroLabel": "Procesando archivo SAF-T...",
				"cancel": "Cancelar"
			},
			"processaFileCloud": {
				"wizardCaption": "Nube CentralGest",
				"text1": "Por favor complete los siguientes detalles",
				"periodo": "Período",
				"user": "Usuario",
				"password": "Contraseña",
				"valoresAImportar": "Valores a importar",
				"importarVendas": "Ventas de importación",
				"importarRecibos": "Recibos de importación",
				"importarCompras": "Compras de importación",
				"importarPagamentos": "Pagos de importación",
				"processarNaCentralGestCloud": "Tratamiento..."
			},
			"processaFilePOS": {
				"wizardCaption": "Zona TPV Suave",
				"text1": "Por favor complete los siguientes detalles",
				"periodo": "Período",
				"user": "Usuario",
				"password": "Contraseña",
				"valoresAImportar": "Valores a importar",
				"importarVendas": "Ventas de importación",
				"importarRecibos": "Recibos de importación",
				"importarCompras": "Compras de importación",
				"importarPagamentos": "Pagos de importación",
				"processarNaCentralGestPOS": "Tratamiento...",
				"contactPOS": "Por favor contacte con nuestro departamento comercial para conocer esta característica.",
				"emailSubject": "Solicitud de información sobre importación de datos Soft de Zona POS",
				"emailComercial": "comercial@centralgest.com"
			},
			"processaFilePOSWinTouch": {
				"wizardCaption": "POS Wintouch",
				"text1": "Por favor complete los siguientes detalles",
				"periodo": "Período",
				"user": "Usuario",
				"password": "Contraseña",
				"valoresAImportar": "Valores a importar",
				"importarVendas": "Ventas de importación",
				"importarRecibos": "Recibos de importación",
				"importarCompras": "Compras de importación",
				"importarPagamentos": "Pagos de importación",
				"processarNaCentralGestPOS": "Tratamiento...",
				"contactPOS": "Por favor contacte con nuestro departamento comercial para conocer esta característica.",
				"emailSubject": "Solicitud de información sobre importación de datos POS Wintouch",
				"emailComercial": "comercial@centralgest.com"
			},
			"viewDocsToImport": {
				"wizardCaption": "Documentos",
				"title": "Documentos y recibos para importar",
				"helpText": "Por favor verifique los documentos y recibos que se importarán.",
				"valoresNoFicheiroSAFT": "Valores en el Archivo SAF-T",
				"nTotalFaturas": "Número de facturas",
				"totalBaseTributavel": "Base imponible total",
				"totalIva": "IVA total",
				"legendError": "Documentos de error",
				"legendWarning": "Documentos con diferencia en totales corregidos automáticamente",
				"validatorErrorMessage": "Hay documentos con errores.",
				"showDocsWithErrorOrWarningOnly": "Mostrar sólo documentos con advertencias o errores",
				"tabDocsTitle": "Documentos",
				"tabPaymentsTitle": "Ingresos",
				"existemDocsComErroDesejaContinuar": "Hay documentos con errores."
			},
			"viewRecibosToImport": {
				"legendError": "Recibos de error",
				"legendWarning": "Recibos con diferencia en totales corregidos automáticamente",
				"showRecibosWithErrorOrWarningOnly": "Mostrar solo recibos con advertencias o errores",
				"nTotalRecibos": "Número de recibos",
				"totalCredito": "Total a crédito",
				"totalDebito": "Débito total"
			},
			"cfgTiposDocs": {
				"wizardCaption": "Tipos de documentos",
				"title": "Configurar tipos de documentos",
				"analiticaTitle": "Analítica",
				"usaAnalitica": "Utilice análisis",
				"criaDiariosEDescritivos": "Crea y asigna automáticamente diarios y transcripciones a nuevos tipos de documentos",
				"mascaraLabel": "Máscara analítica",
				"configMascaraAnalitica": "Configuración de análisis"
			},
			"cfgMercados": {
				"wizardCaption": "Mercados",
				"title": "Estableciendo mercados",
				"helpText": "Por favor indique el monto que se utilizará para formar cuentas corrientes y cuentas gravables dependiendo del mercado.",
				"valorMercadoNacionalLabel": "Carácter de mercado nacional para la formación de cuentas.",
				"valorOutrosMercadosLabel": "Carácter de otros mercados para la formación de cuentas.",
				"valorMercadoComunitarioLabel": "Carácter de mercado comunitario para la creación de cuentas."
			},
			"contasCorrentes": {
				"wizardCaption": "cuentas corrientes",
				"title": "Formación de cuentas corrientes.",
				"helpText": "Indique cómo desea que se creen las cuentas actuales.",
				"tipoFormacaoContaTitle": "Creación de cuenta",
				"criterioFormacaoTitle": "Criterios para la formación de cuentas.",
				"radicalLabel": "Radical",
				"nDigitosLabel": "No. de dígitos",
				"nDigitosHelperText1": "(número de dígitos en la secuencia)",
				"nDigitosHelperText2": "(incluye dígito de mercado + secuencia)",
				"nContaDiversos": "Cuenta",
				"usaDigitosIdentExterna": "Usar número de dígitos en identificación externa"
			},
			"correspContasCorrentes": {
				"wizardCaption": "Coincidencia de cuentas corrientes",
				"title": "Coincidencia de cuentas corrientes",
				"helpText": "El sistema sugiere automáticamente un número de cuenta basado en la configuración de formación de cuenta actual ingresada previamente.",
				"filterLabel": "Filtrar",
				"selectAll": "Seleccionar todo",
				"clearAll": "Borrar selección",
				"associarContaCorrente": "Cuenta corriente asociada",
				"removerAssociarContaCorrente": "Eliminar asociación c.",
				"mostrarContasComErro": "Mostrar cuentas con errores",
				"mostrarNIFInvalid": "Mostrar NIF no válidos",
				"pbSaveText": "Guardar correspondencia de cuentas corrientes...",
				"pbLoadCorrespTaxas": "Cargando correspondencia de tipos de IVA...",
				"atribuirCCusto": "Centro de costos asociado",
				"removerCCusto": "Eliminar asociación de centro de costos",
				"atribuirRepCC": "Desglose del centro de costos asociado",
				"removerRepCC": "Eliminar asociación de desglose del centro de costos",
				"selecionados": "Con seleccionado..."
			},
			"correspTaxasIva": {
				"wizardCaption": "tipos de IVA",
				"title": "Tasas de IVA coincidentes",
				"helpText": "Por favor indique el tipo de IVA correspondiente en CentralGest para el tipo de IVA en el fichero SAFT."
			},
			"cfgArtigos": {
				"wizardCaption": "Artículos",
				"title": "Configuración del artículo",
				"helpText": "Por favor indique la cuenta CentralGest correspondiente por artículo.",
				"selectAll": "Seleccionar todo",
				"selectNews": "Seleccionar nuevo",
				"clearAll": "Borrar selección",
				"configArtigosSelected": "Configurar artículos seleccionados",
				"configTodosArtigosTipoProduto": "Configurar todos los artículos del tipo de producto.",
				"configTodosArtigosTipoServicos": "Configurar todos los artículos de tipo servicios.",
				"configTodosArtigosTipoOutros": "Configurar todos los artículos de tipo otros",
				"configTodosArtigosTipoImpostosTaxasEncargosParafiscais": "Configurar todos los conceptos de tipo impuestos, tasas y cargas parafiscales",
				"configTodosArtigosTipoImpostosEspeciaisConsumo": "Configurar todos los artículos de tipo impuesto especial",
				"toolbarRefresh": "Fresco",
				"toolbarMostrarApenasComErro": "Mostrar solo artículos con errores",
				"toolbarPlanoContas": "plan de cuentas",
				"filterLabel": "Filtrar"
			},
			"dataDocs": {
				"wizardCaption": "Fechas del documento",
				"title": "Fechas del documento",
				"dataLancContabTitle": "Fecha de los asientos contables"
			},
			"genDocsContab": {
				"wizardCaption": "Documentos creados",
				"title": "Documentos contables creados.",
				"legendError": "Documentos de error",
				"legendWarning": "Documentos con valor de redondeo superior a 1€",
				"valoresNoFicheiroSAFT": "Valores del archivo SAFT",
				"valoresCG": "Valores Gestales Centrales",
				"selectAll": "Seleccionar todo",
				"clearAll": "Borrar selección",
				"viewDoc": "Ver documento",
				"deleteDocSelected": "Eliminar documentos seleccionados",
				"toolbarMostrarApenasComErro": "Mostrar solo documentos con errores",
				"gravarDocsMessage": "Guardar documentos"
			}
		},
		"messages": {
			"temACertezaQuePertendeContinuar": "¿Estás seguro de que quieres seguir operando?",
			"obgEscolherTipoImportacao": "Debes seleccionar un tipo de importación para continuar",
			"noFiles": "Seleccione el archivo a procesar...",
			"errorProcessFile": "Se produjo un error inesperado al procesar el archivo SAFT.",
			"importInProcessModalTitle": "Importador SAF-T PT",
			"importInProcessModalMessageByOtherUser": "No se puede iniciar el proceso de importación en esta empresa porque ya lo inició el otro usuario",
			"importInProcessModalMessageCancelJob": "El proceso de importación ya ha comenzado.",
			"importInProcessModalLoadProcess": "Proceso de carga",
			"importInProcessModalCancelProcess": "Cancelar proceso",
			"jobCancelled": "El proceso de importación se canceló exitosamente.",
			"nContaDestinoDiffNContrib": "No es posible asignar la cuenta \"{{conta}}\" a la identificación externa \"{{customerID}}\" porque el aportante de las cuentas es diferente.",
			"contasCorrentesNoSelection": "Debes seleccionar al menos una línea",
			"datasDocumentosDataLancInvalid": "La fecha \"{{date}}\" debe pertenecer al año \"{{year}}\" y al mes \"{{month}}\"",
			"configTiposDocsTemSelLancaCaixaOuLancaCC": "Debes seleccionar al menos una opción: Lanzar efectivo o lanzar c.",
			"configTiposDocsTemIndicarContaCaixa": "Debes indicar una cuenta de efectivo",
			"SoPodeAgruparDocsTipoFaturaSimple": "Sólo se pueden agrupar documentos del tipo Factura|Factura simplificada|Factura de recibo|Venta al contado|Recibo de venta.",
			"configFormacaoContasNContaDiversosInvalid": "¡El Cliente/Proveedor no existe!",
			"configFormacaoContasRadicalInvalid": "Debes indicar un radical para formar cuentas corrientes.",
			"configFormacaoContasNDigitosInvalid": "Deberá indicar el número de dígitos para la constitución de cuentas corrientes.",
			"correspTaxasIvaCodIvaInvalid": "Debe definir un Código de IVA para la tasa de IVA SAFT \"{{rate}}\"",
			"correspTaxasIvaDigitosIvaEmpty": "Debes definir los dígitos de IVA para la tasa de IVA SAFT \"{{rate}}\"",
			"finalizedMessageModal": "La operación se completó con éxito.",
			"finalizedCloseModal": "para cerrar",
			"importHaveErrorsOrWarningsModalMessage": "Hay documentos con advertencias o errores en el archivo SAFT.",
			"correspCCHaveInvalidNifsModalMessage": "Hay cuentas corrientes con números de contribuyente no válidos.",
			"temIndicarMascaraAnalitica": "Debe especificar una máscara de análisis.",
			"temIndicarCentroCusto": "Debes indicar un centro de coste",
			"temIndicarCodRepCC": "Debes indicar un código de distribución por centro de coste"
		},
		"fields": {
			"jaImportado": "Importado",
			"invoiceNo": "Número de factura",
			"invoiceTypeStr": "Tipo",
			"totalBase": "Valor base",
			"totalIVA": "valor del IVA",
			"invoice": "Serie",
			"nDiario": "A diario",
			"descritivo": "Descriptivo",
			"lancaCaixa": "caja de lanzamiento",
			"contaCaixa": "cuenta de efectivo",
			"lancaContaCorrente": "Lanza c.",
			"nCCusto": "C. Costo",
			"agrupamentoDocumentos": "Documentos de grupo",
			"tipoRetencao": "Tipo de retención",
			"loja": "Almacenar",
			"isNew": "Nuevo",
			"customerID": "Identificación externa",
			"customerTaxID": "número de contribuyente",
			"name": "Nombre",
			"country": "País",
			"mercado": "Mercado",
			"jaExiste": "Hay",
			"contaDestino": "Número de cuenta sugerido",
			"codRetencao": "Código de retención",
			"nomeContaCG": "Nombre de cuenta CentralGest",
			"taxCountryRegion": "Región",
			"taxCode": "Tipo",
			"taxPercentage": "Tasa",
			"codIva": "código de IVA",
			"taxaIva": "Tasa",
			"motivoTaxa": "Razón de la exención",
			"digitosIva": "Caracteres de IVA para la formación de cuentas",
			"productCode": "Artículo",
			"productDescription": "Nombre",
			"productType": "Tipo",
			"nConta": "facturas de cuenta",
			"nContaNC": "cuenta de nota de crédito",
			"acresceDigMercado": "Añade carácter de mercado",
			"acresceDigIVA": "Agregar caracteres de IVA",
			"retencao": "Retención",
			"dataUltimoDoc": "Último doctor.",
			"dataLancamento": "fecha de lanzamiento",
			"select": "Selección",
			"nDocumento": "Número de documento",
			"invoices": "Número de factura",
			"valorBase": "Valor base",
			"valorIVA": "valor del IVA",
			"valor": "Valor",
			"valorBaseCG": "valor base del CG",
			"valorIVACG": "Valor del IVA del GC",
			"valorCG": "valor de CG",
			"valorArredBase": "Arred.",
			"valorArredIVA": "Arred.",
			"paymentRefNo": "Número de recibo",
			"nomeCliente": "Cliente",
			"valorLinhasDebito": "Monto del débito",
			"valorLinhasCredito": "Valor del crédito",
			"importa": "importa",
			"nContaClassificada": "Número de cuenta clasificado",
			"contaFormadaDigitosMercado": "Carácter del mercado",
			"contaFormadaDigitosIVA": "Caracteres de IVA",
			"nContaFormada": "Número de cuenta formada",
			"contaFormadaNome": "Nombre",
			"selectNode": "Sel.",
			"settlementAmount": "Descuento",
			"contaDescRecibo": "Recibos de descuentos de cuenta",
			"anulado": "anulado",
			"nCodRepCC": "Descomponer"
		},
		"configMascaraAnalitica": {
			"modalTitle": "Configuración de análisis",
			"comoPretendeAtribuirAnalitica": "¿Cómo desea asignar análisis?"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmación",
		"leavePromptMessage": "Al hacer clic en SÍ, saldrá y descartará todos los datos de procesamiento."
	},
	"importxls": {
		"headerinfo": "Esta aplicación le permite importar diferente información al Software CentralGest.",
		"fields": {
			"descricao": "Importar",
			"fichasClientes": "Importar registros de clientes",
			"fichasFornecedores": "Importación de registros de proveedores.",
			"artigos": "Importación de artículos",
			"contasCorrentesClientes": "Importación de cuentas corrientes de clientes",
			"contasCorrentesFornecedores": "Importación de cuentas corrientes de proveedores"
		}
	},
	"indicefluxos": {
		"title_detail": "Flujos de índice {{id}}",
		"title_new": "Nuevos flujos de índice",
		"title_plural": "Índices de flujo",
		"pesquisa": "buscar",
		"saved": "Índice de flujo {{id}}, guardado correctamente",
		"error": "Índice de flujos {{id}}, no se puede guardar.",
		"fields": {
			"indice": "Índice",
			"indicePlaceholder": "Índice de flujo",
			"tipo": "Tipo",
			"tipoPlaceholder": "Tipo de índice de flujo",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del índice de secuencia"
		}
	},
	"integracaoSalarios": {
		"tabIntegrarPorEmpregadoCaption": "Integrar por empleado",
		"tabIntegrarPorProcessamentoCaption": "Integrar mediante procesamiento",
		"tabRemoverIntegracao": "Eliminar integración",
		"selectAll": "Seleccionar todo",
		"unSelectAll": "Borrar selección",
		"config": "Configurar",
		"labelTodosProcs": "Todo tipo de procesamiento",
		"dataDe": "Fecha de procesamiento",
		"codEmpDe": "Del empleado",
		"configModalTitle": "Configurar la integración salarial",
		"configModalTipoIntegTabCaption": "Tipo de integración",
		"configModalConfigAcertosSegSocTabCaption": "Config.",
		"configModalTipoIntegRadioGroupLabel": "Resultado de la integración en contabilidad.",
		"tipoIntegUmDocPorEmpregado": "Un documento por empleado",
		"tipoIntegUmDocPorProcessamento": "Un documento por trámite",
		"diarioLabel": "A diario",
		"descritivoLabel": "Descriptivo",
		"codEstadoLabel": "código de estado",
		"contasContrapartidaAcertoSSTitle": "Cuentas de compensación de liquidación de la seguridad social",
		"contaGastoAcertoLabel": "Cuenta de gastos devengados",
		"contaRendimentoAcertoLabel": "Ir a la cuenta de ingresos",
		"processMessage": "Procesando... por favor espere un momento.",
		"jobTimeoutModalTitle": "Tarea",
		"jobTimeoutModalMessage": "Lo sentimos, pero la tarea ha expirado.",
		"integrarPorEmpregadoMainTitle": "Integrar salarios por empleado",
		"integrarPorProcessamentoMainTitle": "Integrar salarios de procesamiento",
		"descricaoLabel": "Descripción",
		"simularInteg": "Simular integración",
		"execInteg": "Ejecutar integración",
		"removeInteg": "Eliminar integración",
		"nDocExtLabel": "Doc no.",
		"canceladoPeloUtiliz": "Operación cancelada por el usuario.",
		"processDoneModalTitle": "Integración salarial",
		"processDoneModalMsg": "Se ha completado la integración salarial.",
		"simulatorModalTitle": "Simulación de integración salarial: {{company}}",
		"integModalTitle": "Integración salarial: {{empresa}}",
		"totalDebitoLabel": "Deuda total",
		"totalCreditoLabel": "Crédito completo",
		"onlyErrorsLabel": "solo con errores",
		"integRemovidaComSucesso": "La integración salarial se ha eliminado con éxito.",
		"integrar": "Integrar",
		"temSelecionarRegistos": "Tienes que seleccionar registros.",
		"visualizarErros": "¿Quieres ver los errores?",
		"errosIntegSalariosModalTitle": "Errores de integración salarial",
		"verErrosModal": "Ver errores",
		"verSimuladorModal": "Ver simulador",
		"fields": {
			"selected": "SEL",
			"nProcessamento": "Proceso no.",
			"nomeProcessamento": "Descripción",
			"tipoProcessamento": "Tipo",
			"tipoProcessamentoDescricao": "Tipo de descripción.",
			"totalVencAbonos": "Asignaciones totales",
			"totalVencDescontos": "Descuentos completos",
			"totalEncargos": "Cargos totales",
			"codEmp": "código emp.",
			"nomeEmpregado": "Nombre",
			"nConta": "Nro. de cuenta",
			"periodo": "Período",
			"nDiario": "Número diario",
			"nDescritivo": "Descriptivo no.",
			"nDocInterno": "Doc no.",
			"nDocExterno": "Doc no.",
			"descricao": "Descripción",
			"dataDoc": "Fecha del doctor.",
			"dataValor": "fecha valor",
			"dataVencimento": "Fecha de expiración",
			"valor": "Valor",
			"nContaCredito": "Número de cuenta de crédito",
			"nContaDebito": "Cuenta deudora no.",
			"nomeConta": "Nombre de cuenta",
			"integId": "Integración",
			"cambio": "Intercambio",
			"codMoeda": "Código de moneda",
			"nCCusto": "No. C. Costo",
			"nContrib": "NIF",
			"nRefProcesso": "Número de referencia.",
			"contaOrigem": "cuenta de origen",
			"mascaraOrigem": "Máscara de origen",
			"valorME": "Valor YO",
			"tipoVencimentosEncargos": "Tipo",
			"nCodAbdesc": "Asignación / Descuento"
		}
	},
	"integracaoSalariosMulti": {
		"promptViewResultsTitle": "Integración salarial (Multiempresa)",
		"promptViewResultsMessage": "La integración salarial ha terminado.",
		"selMesIntegrarTitle": "Selecciona el mes",
		"viewResultBtn": "Vista",
		"initNewProc": "Comenzar",
		"jobTimeoutModalTitle": "Tarea",
		"jobTimeoutModalMessage": "La tarea ha expirado.",
		"comecar": "para empezar",
		"temSeleccionarUmaEmpresa": "Debe seleccionar al menos una empresa.",
		"tabIntroCaption": "Introducción",
		"tabIntroTitle": "Bienvenido al asistente para realizar la Integración Salarial Multiempresa.",
		"tabIntroSubTitle": "Este asistente le guiará a través del proceso de integración automática de salarios para todas las empresas que estén seleccionadas y con los ajustes configurados correctamente.",
		"tabIntroSubTitle2": "Al final, se le muestran los documentos que se ingresaron en Contabilidad.",
		"tabIntroSubTitle3": "Haga clic en \"Siguiente\" para continuar.",
		"tabEmpresasCaption": "Empresas",
		"tabProcCaption": "Tratamiento",
		"tabResultsCaption": "Informe",
		"configLabel": "Ajustes",
		"fields": {
			"selected": "SEL.,",
			"nEmpresa": "Empresa",
			"nomeEmpresa": "Nombre de empresa",
			"codEmpresa": "empresa no.",
			"nDocInterno": "Doc no.",
			"descricaoErro": "Informe"
		}
	},
	"intfac": {
		"title": {
			"vendas": "Ventas",
			"compras": "Compras",
			"selectAll": "Seleccionar todo",
			"docnaointcontab": "Documentos no integrados en la contabilidad"
		},
		"btn": {
			"encerrar": "Cerca",
			"pesquisar": "buscar",
			"editDoc": "Editar documento contable",
			"simular": "Simular"
		},
		"tooltip": {
			"doccomerro": "Documento con error."
		},
		"table": {
			"selected": "Sel.",
			"nDoc": "Documento",
			"dataDoc": "Fecha del documento",
			"dataVenci": "Fecha de expiración",
			"estado": "Estado",
			"nConta": "Cuenta",
			"nDocExt": "Doc no.",
			"nDocumentoFacturacao": "Número de documento de facturación",
			"periodo": "Período",
			"nDiario": "A diario",
			"nDocInterno": "Número de documento",
			"nContaDebito": "Deuda",
			"nContaCredito": "Crédito",
			"valor": "Valor",
			"nomeConta": "Nombre",
			"codMoeda": "Código de moneda",
			"valorME": "Valor YO",
			"valorTaxa": "Valor de la tarifa",
			"valorTaxaME": "Valor de la tarifa ME",
			"codIva": "código de IVA",
			"valorRetencao": "Valor de retención",
			"nContrib": "número de contribuyente",
			"dataDocExterno": "Fecha del doctor.",
			"codIntegra": "código de integración",
			"descricao": "Código de descripción."
		},
		"tooltips": {
			"cantBtnPesquisar": "Seleccione al menos un tipo de documento para buscar",
			"cantBtnEncerrar": "Seleccione al menos un documento para cerrar"
		},
		"messages": {
			"successfullySaved": "Completado con éxito.",
			"docscomerro": "Los documentos seleccionados contienen errores.",
			"semgruposelecionado": "Seleccione tipos de documentos.",
			"dataNotFound": "Los tipos seleccionados no contienen documentos para cerrar.",
			"simErro": "Error al procesar la simulación."
		},
		"errors": {
			"integradoMasComErro": "Integrado pero con error."
		},
		"modal": {
			"title": "Resultados de la simulación",
			"legend": {
				"legendErro": "con error"
			}
		}
	},
	"invec": {
		"pesquisa": "buscar",
		"messages": {
			"reprocessaSuccess": "¡El inventario se reprocesó exitosamente!",
			"errorName": "Se debe completar el nombre del inventario.",
			"saveSuccess": "¡Inventario guardado exitosamente!",
			"deleteSuccess": "¡El inventario se eliminó correctamente!",
			"revalorizaSuccess": "¡Inventario revalorizado exitosamente!",
			"deletePrompt": "¿Está seguro de que desea eliminar el inventario?",
			"sistemaNaoDevolveuFicheiro": "El sistema no devolvió el archivo.",
			"dataRequired": "La fecha es obligatoria."
		},
		"fields": {
			"nome": "Nombre",
			"descricao": "Descripción",
			"deNArmazem": "Depósito",
			"deTipoArtigo": "tipo de artículo",
			"deGrandeFamilia": "gran familia de",
			"deFamilia": "familia de",
			"deSubFamilia": "Sub.",
			"deClasse": "Clase de",
			"deCategoria": "categoría de",
			"deValorimetria": "Valoración de",
			"deNArtigo": "Artículo de",
			"stockNulo": "Stock nulo",
			"desativados": "Desactivado",
			"porLote": "Por lote",
			"dataRef": "Fecha de referencia"
		},
		"valorizar": {
			"modal": {
				"title": "Inventarios - Valoración",
				"valorizacao": "Valuación"
			}
		},
		"btn": {
			"carregarDados": "Cargar datos",
			"save": "Ahorrar",
			"mostrar": "Espectáculo",
			"exportAT": "Exportar/Comunicar AT Inventario",
			"exportATHint": "Exportar archivo inv.",
			"retroceder": "Rebobinar",
			"pesquisarArtigos": "Buscar artículos",
			"criar": "Crear inventario",
			"ficheiroCSV": "archivo CSV",
			"ficheiroXML": "archivo XML"
		},
		"newModalTitle": "Registro de inventario",
		"pesquisarArtigosTooltip": "Buscar artículos",
		"criarTooltip": "Crear inventario con elementos de la mesa.",
		"options": {
			"showTipoArtigo": "Mostrar tipo de artículo",
			"showGrandeFamilia": "Mostrar gran familia",
			"showSubFamilia": "Mostrar sub.",
			"showClasse": "Mostrar clase",
			"showCategoria": "Mostrar categoría",
			"showValorimetria": "Mostrar valoración"
		}
	},
	"inven": {
		"fields": {
			"nArtigo": "Código del artículo",
			"nomeArtigo": "Nombre del artículo",
			"nomeArmazem": "Nombre del almacén",
			"nArmazem": "Almacenamiento",
			"lote": "Lote",
			"qtd": "Cantidad",
			"precoValor": "Precio unitario",
			"valor": "Valor",
			"nLoteEspecifico": "Lote específico"
		},
		"filename": "stock_inventario",
		"tooltips": {
			"fichaArtigo": "Ficha del artículo",
			"extratoArtigo": "Extracto del artículo"
		}
	},
	"inventarios": {
		"title": "Inventarios",
		"print": {
			"modalTitle": "Imprimir inventario"
		}
	},
	"inversaoSujPassivo": {
		"pesquisa": "Tipos de inversión de sujeto pasivo",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"notApplicable": "N / A",
			"withInversion": "Con inversión",
			"noInversion": "Sin inversión"
		}
	},
	"invoiceType": {
		"fields": {
			"None": "Desconocido",
			"Fatura": "Factura",
			"FaturaSimplificada": "factura simplificada",
			"FaturaRecibo": "Recibo de factura",
			"NotaDebito": "nota de débito",
			"NotaCredito": "nota de crédito",
			"VendaDinheiro": "Vender por dinero en efectivo",
			"TalaoVenda": "recibo de venta",
			"TalaoDevolucao": "Resguardo de devolución",
			"AlienacaoAtivos": "Enajenación de activos",
			"DevolucaoAtivos": "Devolución de activos",
			"Premio": "Premio o recibo de premio",
			"Estorno": "Recibo de devolución o reembolso",
			"ImputacaoCoSeguradoras": "Imputación a coaseguradores",
			"ImputacaoCoSeguradoraLider": "Imputación al coasegurador líder",
			"ResseguroAceite": "Reaseguro aceptado",
			"Recibo": "Recibo",
			"NotaLancamento": "Nota de lanzamiento",
			"ExtratoBancario": "Extracto de cuenta",
			"PagamentoAoEstado": "Pago al estado",
			"Levantamento": "Encuesta",
			"Pagamento": "Pago",
			"Deposito": "Depósito",
			"Cheque": "Controlar",
			"Transferencia": "Transferir"
		}
	},
	"irsdt": {
		"title_detail": "Fecha de vigencia de la tabla del IRS",
		"title_new": "Nueva fecha de vigencia de la tabla del IRS",
		"title_plural": "Fechas de vigencia de la tabla del IRS",
		"pesquisa": "buscar",
		"saved": "Fecha de vigencia de la tabla del IRS, guardada exitosamente.",
		"error": "Fecha de vigencia de la tabla del IRS, no se puede guardar.",
		"deleted": "Fecha de vigencia de la tabla del IRS, eliminada exitosamente.",
		"fields": {
			"tabIRSDT": "fecha de impuestos del IRS"
		}
	},
	"ivamotivostaxa": {
		"title_detail": "Motivo de la exención del IVA {{id}}",
		"title_new": "Nuevo motivo de exención del IVA",
		"title_plural": "Razones para la exención del IVA",
		"pesquisa": "buscar",
		"saved": "Motivo de la exención de IVA {{id}}, guardado correctamente.",
		"error": "Motivo de exención de IVA {{id}}, no se puede guardar.",
		"deleted": "Motivo de exención de IVA {{id}}, eliminado correctamente",
		"fields": {
			"ivaMotivosTaxaId": "Código",
			"ivaMotivosTaxaIdPlaceholder": "código de exención de IVA",
			"ivaMotivosTaxaCod": "código de razón",
			"ivaMotivosTaxaCodPlaceholder": "Código de motivo de exención de IVA",
			"descricao": "Descripción",
			"descricaoPlaceholder": "Descripción",
			"normaAplicavel": "Norma aplicable",
			"normaAplicavelPlaceholder": "Norma aplicable"
		},
		"novaTaxaIVA": "Nuevo motivo de exención del IVA"
	},
	"ivas": {
		"title_detail": "Código de IVA {{id}}",
		"title_new": "Nuevo código de IVA",
		"title_plural": "Códigos de IVA",
		"pesquisa": "buscar",
		"saved": "Código de IVA {{id}}, guardado correctamente.",
		"error": "Código de IVA {{id}}, no se puede guardar.",
		"deleted": "Código de IVA {{id}}, eliminado correctamente",
		"fields": {
			"codIva": "Código",
			"codIvaPlaceholder": "código de IVA",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre Código IVA",
			"taxaActual": "Tasa",
			"taxaActualPlaceholder": "Tasa",
			"dataTaxaActual": "Fecha ref.",
			"percDedGasoleo": "Perc.",
			"percDedGasoleoPlaceholder": "Porcentaje deducible de diésel",
			"percDedProRata": "Perc.",
			"percDedProRataPlaceholder": "Porcentaje deducible prorrateado",
			"codAlternInfac": "código alternativo",
			"codAlternInfacPlaceholder": "código alternativo",
			"tipoTaxa": "tipo de tarifa",
			"areaRegional": "Área regional",
			"tipoDeducao": "Tipo de deducción",
			"ivaMotivosTaxaId": "Razón de la exención"
		},
		"novaTaxaIVA": "Nuevo tipo de IVA",
		"tooltip": {
			"codAlternInfacTooltip": "Completa la integración de la base imponible."
		}
	},
	"justificacaoAusencia": {
		"data": {
			"tjaNA": "N / A",
			"tjaSim": "Sí",
			"tjaNao": "No"
		}
	},
	"listagemdiarios": {
		"btn": {
			"previsualizar": "Avance"
		},
		"header": {
			"fromAccount": "Cuenta",
			"doPeriodo": "período de",
			"doDiario": "diario",
			"docInterDo": "Documento interno.",
			"datadocde": "Fecha del doctor."
		}
	},
	"listagemMenus": {
		"table": {
			"almoco": "Almuerzo",
			"jantar": "para almorzar",
			"sopa": "Sopa",
			"ligeira": "Luz",
			"sobremesa": "Postre"
		},
		"form": {
			"local": "Ubicación",
			"de": "De",
			"btns": {
				"pesquisar": "buscar",
				"imprimir": "Imprimir"
			}
		},
		"title": {
			"ementaSemanal": "menú semanal",
			"local": "Ubicación"
		}
	},
	"loadmodule": {
		"title": "Cargando..."
	},
	"localObtencaoRendimentos": {
		"data": {
			"continente": "Continente",
			"regiaoAcores": "Región Autónoma de las Azores",
			"regiaoMadeira": "Región Autónoma de Madeira",
			"estrangeiro": "Extranjero"
		}
	},
	"locale": {
		"descriptive": {
			"en": "Inglés",
			"es": "Español",
			"fr": "Francés",
			"pt-PT": "Portugués"
		}
	},
	"local": {
		"title_detail": "Ubicación {{id}}",
		"title_new": "Nueva ubicación",
		"title_plural": "Ubicaciones",
		"pesquisa": "buscar",
		"saved": "Ubicación {{id}}, guardada correctamente.",
		"error": "Ubicación {{id}}, no se puede guardar.",
		"deleted": "Ubicación {{id}}, eliminada correctamente",
		"fields": {
			"nLocalidade": "Código.",
			"nLocalidadePlaceholder": "código de localidad",
			"designaBreve": "Breve designación",
			"designaBrevePlaceholder": "Breve designación",
			"designaCompl": "Designación completa",
			"designaComplPlaceholder": "Designación completa"
		}
	},
	"lockedcontract": {
		"title": "Tu contrato está bloqueado",
		"titleTemporary": "Tu contrato está bloqueado temporalmente",
		"changeCompany": "Cambiar de empresa",
		"reasons": {
			"0": "N / A",
			"1": "Su contrato está en mantenimiento, este proceso puede tardar algún tiempo, espere."
		}
	},
	"loginAT": {
		"title": "Configuración AT",
		"success": "Detalles de inicio de sesión guardados exitosamente",
		"panels": {
			"efatura": "Factura Electrónica AT Iniciar sesión",
			"efaturaCirculacao": "AT Webservice Login para comunicación de documentos de transporte",
			"nifAt": "Inicio de sesión de la empresa AT",
			"docfasNumsComunicacaoLogin": "Inicio de sesión de comunicación en serie"
		},
		"fields": {
			"campo": "Campo",
			"valor": "Valor",
			"usernif": "(NIF/Usuario)",
			"password": "Contraseña",
			"nif": "Número de valor agregado"
		}
	},
	"loginWsSS": {
		"title": "Autenticación de seguridad social - SSD",
		"success": "Detalles de inicio de sesión guardados exitosamente",
		"dadosEmpresa": "Detalles de la empresa",
		"dadosMandatario": "Detalles representativos",
		"fields": {
			"niss": "niss",
			"password": "Contraseña"
		},
		"messages": {
			"credentialsSaved": "Las credenciales se guardaron correctamente.",
			"errorNISSEmpresaEmpty": "El NISS de la empresa está vacío.",
			"errorPasswordEmpresaEmpty": "La contraseña de la empresa está vacía.",
			"errorPasswordMandatarioEmpty": "La contraseña del agente está vacía."
		},
		"nissAutenticacaoDiferenteFichaEmpresa": "El NISS de la autenticación \"{{nissAuth}}\" es diferente al registro de la empresa \"{{nissEmpresa}}\"."
	},
	"lotes": {
		"title_detail": "Lote {{id}}",
		"title_new": "Nuevo lote",
		"title_plural": "Lotes",
		"pesquisa": "buscar",
		"saved": "Lote {{id}}, guardado exitosamente",
		"error": "Lote {{id}}, no se puede guardar.",
		"deleted": "Lote {{id}}, eliminado correctamente",
		"fields": {
			"nLote": "código de lote",
			"nArtigo": "Código del artículo",
			"nLoteEspecifico": "Lote específico",
			"loteFornecedor": "Lote de proveedor",
			"nConta": "Proveedor",
			"dataFabrico": "Fecha de fabricación",
			"dataValidade": "Fecha de caducidad",
			"qtdTotal": "Cantidad total",
			"terminado": "Finalizado",
			"nomeArtigo": "Nombre del artículo",
			"nomeConta": "Nombre del proveedor"
		},
		"errors": {
			"artigoDaLinhaNaoTemLote": "No hay ningún lote seleccionado para el artículo {{nartigo}}.",
			"artigoNaoTemLote": "El código de artículo {{nArtigo}} no tiene lote."
		},
		"btn": {
			"new": "Nuevo"
		},
		"list": {
			"config": {
				"showSoTerminados": "ver recién terminado"
			},
			"actions": {
				"editar": "Editar",
				"terminar": "Finalizar",
				"teminado": "Finalizado",
				"verSoLotesTerminados": "Ver solo lotes terminados",
				"verSoLotesAtivos": "Ver solo lotes activos"
			}
		},
		"okCancel": {
			"terminar": {
				"title": "Terminar lote",
				"message": "¿Estás seguro de que quieres terminar el lote {{lot}} del artículo {{nartigo}}?"
			}
		}
	},
	"maintenance": {
		"pageTitle": "Mantenimiento programado",
		"title": "Estamos realizando mantenimiento programado.",
		"message": "Disculpe las molestias.<br/>Nos comunicaremos con usted lo antes posible."
	},
	"mapaexploracao": {
		"title": "mapa de exploración",
		"table": {
			"header": {
				"meses": "Meses"
			}
		},
		"fields": {
			"periodoDe": "período de",
			"conta": "Cuenta",
			"descricao": "Descripción",
			"saldo01": "Enero",
			"saldo02": "Febrero",
			"saldo03": "Marzo",
			"saldo04": "Abril",
			"saldo05": "Puede",
			"saldo06": "Junio",
			"saldo07": "Julio",
			"saldo08": "Agosto",
			"saldo09": "Septiembre",
			"saldo10": "Octubre",
			"saldo11": "Noviembre",
			"saldo12": "Diciembre"
		},
		"summary": {
			"rendimentos": "Ingreso",
			"compras": "Compras y gastos",
			"totalrendimentos": "Ingresos totales",
			"totalcompras": "Compras y gastos totales",
			"totalexploracao": "Resultado de la exploración"
		},
		"btn": {
			"dropdown": {
				"balancete": "Balance"
			}
		},
		"modal": {
			"config": {
				"title": "Configuración del mapa de exploración",
				"label": {
					"tipoarredondamento": "Tipo de redondeo"
				},
				"tipoarredondamento": {
					"semArredondamento": "Sin redondeo",
					"arredondamentoAoEuro": "Redondear al euro"
				}
			}
		}
	},
	"mapapagam": {
		"texts": {
			"comSelecionados": "Seleccionado...",
			"carregarPagamentos": "para cargar",
			"empresas": "Empresas",
			"pagamentos": "Pagos",
			"pagamentosManuais": "Pagos manuales",
			"adiconarPagamentoManual": "Crear pago manual",
			"criarPagamento": "Crear pago",
			"editarPagamento": "Editar pago",
			"periocidade": "Periodicidad",
			"dadosPagamento": "Detalles de pago",
			"verDocumento": "Ver documento(s)",
			"enviarPagamento": "para enviar",
			"reEnviarPagamento": "Reenviar pago(s)",
			"marcarComoPago": "Marcar como pagado",
			"visualizacaoDocumento": "Vista previa del documento",
			"documento": "Documento",
			"selecionarTodosPagamentos": "Seleccionar todos los pagos",
			"selecionarEstePagEmTodasEmpresa": "Selecciona este pago en todas las empresas",
			"enviarPagamentos": "Enviar pagos",
			"continuar": "Continuar",
			"configurarCredentials": "Configurar credenciales",
			"resumoEnvioErros": "Resumen de envío: errores",
			"existemEmpresasComErros": "Hay empresas con errores de envío.",
			"listaDePagamentosAEnviar": "Lista de pagos a enviar",
			"viewDocumentoUnico": "Vista previa de un solo documento con pagos seleccionados",
			"verDetalhesPagamento": "Ver detalles",
			"apagarPagamento": "Eliminar pago",
			"detalhesPagamento": "Detalles de pago",
			"semDetalhesPagamento": "Sin detalles de pago.",
			"naoPossivelVisualizarDocNaoEmitido": "No se puede ver el documento.",
			"podeEmitirDocNosDocsFiscais": "Puedes emitir el documento en documentos tributarios o en el portal de finanzas (AT)",
			"selecionarEmpresasSemErro": "Seleccionar empresas sin errores",
			"portalAT": "Portal financiero (AT)",
			"portalFCT": "Fondo de Compensación",
			"portalSSD": "Seguridad social directa",
			"enviarSelecionados": "Enviar seleccionado",
			"enviarApenas": "Seleccionar y enviar solo {{name}}",
			"mostrarApenasSelecionadas": "Mostrar solo los seleccionados",
			"mostrarComFalhaLogin": "Mostrar error de inicio de sesión",
			"pagEstado": "Pag.",
			"configuracao": "Ajustes",
			"pagamentosDisponiveis": "Pagos disponibles",
			"pagamentosSelecionados": "Pagos seleccionados",
			"disponivelBrevemente": "Esta funcionalidad estará disponible pronto.",
			"regimeIva": {
				"normal": "Normal",
				"isencao": "Exención",
				"pequenosRetalhistas": "Pequeños minoristas",
				"regimeNormalBens2Mao": "Régimen normal + bienes de 2ª mano",
				"regimeNormalRevendaCombustíveis": "Régimen normal + reventa de combustible",
				"regimeNormalIvaCaixa": "Régimen normal + IVA al contado"
			},
			"periodoIva": {
				"mensal": "Mensual",
				"trimestral": "Trimestral"
			},
			"limparFiltros": "Limpiar filtros",
			"aplicar": "Aplicar",
			"alertMessage": "No recomendamos utilizar el mapa de pagos entre las 12:00 y las 14:00 horas, ya que estos horarios sirven de mantenimiento para algunas entidades públicas.",
			"criarPagamentoManual": "Crear pago manual",
			"editarPagamentoManual": "Editar pago manual",
			"pagamentoManual": "Pago manual",
			"todasEmpresas": "Todas las empresas",
			"todosMeses": "Cada mes",
			"durante": "Durante",
			"anos": "años)",
			"selecioneOQuePertendeApagar": "Selecciona lo que quieres eliminar",
			"apagarApenasMesCorrente": "Eliminar solo del mes actual",
			"apagarTodosMesesNoAnoCurrente": "Eliminar todos los meses del año actual",
			"apagarPorCompletoEmTodasEmpresas": "Eliminar completamente y en todas las empresas",
			"confirmar": "Confirmar",
			"documentosFiscais": "Documentos fiscales",
			"anexo": "Anexo",
			"removerAnexo": "Quitar archivo adjunto",
			"documentoUnicoPdfName": "{{año}}{{mes}}_DU_{{nEmpresa}}_{{ahora}}.pdf",
			"pagamentoPdfName": "{{año}}{{mes}}_{{nEmpresa}}_{{nombre}}_{{ahora}}.pdf",
			"aPreparar": "Preparando... por favor espere un momento.",
			"recarregar": "Recargar"
		},
		"fields": {
			"nEmpresa": "empresa no.",
			"nomeEmpresa": "Nombre",
			"nomePagamento": "Nombre del pago",
			"descricaoPagamento": "Descripción del pago",
			"refPagDescricao": "Referencia de pago (Descripción)",
			"dataLimitePag": "Plazo de pago",
			"montante": "Cantidad",
			"email": "Correo electrónico",
			"pago": "Pagado",
			"status": "Estado",
			"nif": "NIF",
			"regimeIva": "Régimen del IVA",
			"periodoIva": "Período de IVA",
			"responsavel": "Responsable",
			"iva": "TINA",
			"ircMod22": "IRC MOD 22",
			"imi": "IMI",
			"iuc": "IUC",
			"dmr": "DMR EN",
			"retencaoNaFonte": "retirado",
			"pagAntIRC": "Pag.",
			"dmrSS": "DMR SS"
		},
		"messages": {
			"temSeleccionarEmpresa": "Debe seleccionar al menos una empresa.",
			"temQuePreencherNomePagamento": "Tienes que rellenar el nombre del pago.",
			"temQueSelecionarPeloMenosUmMes": "Debes seleccionar al menos un mes",
			"marcarComoPagoPrompt": "¿Está seguro de que desea marcar este pago como pagado?",
			"empresaNaoTemPagamentosSelecionados": "La empresa \"{{company}}\" no tiene pagos seleccionados",
			"empresaNaoTemEmailPreenchido": "La empresa \"{{company}}\" no tiene una dirección de correo electrónico completa",
			"empresaNaoTemPagamentosCarregados": "La empresa \"{{company}}\" no tiene pagos cargados.",
			"processMessage": "Procesando... por favor espere un momento.",
			"empresaNaoTemEmailInvalido": "La empresa \"{{company}}\" tiene una dirección de correo electrónico no válida",
			"existemPagamentoEnviados": "Hay pagos seleccionados que ya han sido enviados.",
			"documentoUnicoEnviadoSucesso": "El documento único con pagos fue enviado exitosamente.",
			"carregarEmpresas": "Cargando empresas...",
			"pagManGuardadoSucesso": "El pago manual se guardó correctamente"
		},
		"tooltips": {
			"emailLido": "El correo electrónico con este pago fue leído el {{lidoDataHora}}",
			"emailEnviado": "El correo electrónico con este pago se envió el {{sentDateTime}}",
			"emailNaoEnviado": "El correo electrónico con este pago no fue enviado.",
			"pago": "El pago ya fue pagado",
			"obterDadosPortaisATeSSD": "Obtener datos de los portales AT y SSD",
			"enviarPagamentosSelParaEmpresasSel": "Enviar pagos seleccionados a empresas seleccionadas",
			"emailEnviadoELido": "El correo electrónico con este pago fue enviado el {{enviadoDataHora}} y leído el {{lidoDataHora}}",
			"enviarPagamentosSelecionadosGrelha": "Enviar pagos seleccionados en la grilla",
			"naoEPossivelObterEstadoPagamento": "No se puede obtener el estado del pago."
		},
		"btn": {
			"resend": "Reenviar",
			"resendSkipErrors": "Volver a enviar (ignorar errores)"
		}
	},
	"mapasamortizacao": {
		"title_detail": "Mapa de Amortización {{id}}",
		"title_new": "Nuevo Mapa de Amortización",
		"title_plural": "Mapas de Amortización",
		"pesquisa": "buscar",
		"saved": "Mapa de Amortización {{id}}, guardado exitosamente.",
		"error": "Mapa de Amortización {{id}}, no se puede guardar.",
		"deleted": "Mapa de Amortización {{id}}, eliminado exitosamente",
		"fields": {
			"nImmap": "Código.",
			"nImmapPlaceholder": "Código de mapa de amortización",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del mapa de amortización"
		}
	},
	"mascarasanalitica": {
		"title_detail": "Máscara de análisis {{id}}",
		"title_new": "Nueva máscara de análisis",
		"title_plural": "Máscaras analíticas",
		"pesquisa": "buscar",
		"saved": "Máscara analítica {{id}}, guardada correctamente.",
		"error": "Máscara de análisis {{id}}, no se puede guardar.",
		"errorAdd": "Este tipo de personaje ya existe en una posición anterior de la máscara.",
		"deleted": "Máscara de análisis {{id}}, eliminada correctamente.",
		"fields": {
			"manalID": "Código.",
			"descricao": "Descripción",
			"mascara": "Mascarilla",
			"tipo": "Tipo de máscara",
			"nCaracteresGeral": "No. caracteres",
			"isDefault": "Máscara predeterminada",
			"subTotaisDaGeral": "Crea subtotales basados ​​en la estructura general del acumulador.",
			"indicesSubTotais": "Índices de subtotales",
			"contaContrapartida": "cuenta de compensación"
		},
		"placeholders": {
			"descricao": "Descripción de la máscara de análisis",
			"mascara": "Mascarilla",
			"tipo": "Tipo de máscara",
			"nCaracteresGeral": "Número de personajes en la cuenta general.",
			"isDefault": "Máscara predeterminada",
			"subTotaisDaGeral": "Crea subtotales basados ​​en la estructura general del acumulador.",
			"indicesSubTotais": "Índices de subtotales"
		},
		"tipos": {
			"invoicing": "Facturación",
			"salary": "Salarios",
			"fixed": "Activos fijos"
		},
		"tabs": {
			"definition": "Definición de máscara",
			"definitionTitle": "Mascarilla",
			"definitionNames": "Definición de nombres de cuentas para generar",
			"definitionNamesTitle": "Disponible",
			"nameTitle": "Nombre de la cuenta creada",
			"nameItemsTitle": "Campos disponibles"
		},
		"table": {
			"description": "Descripción",
			"char": "Personaje",
			"subTotal": "Crear subtotal",
			"type": "Tipo",
			"field": "Campo por definición",
			"text": "Texto"
		},
		"enum": {
			"definition": {
				"0": "Constante 0",
				"1": "Constante 1",
				"2": "Constante 2",
				"3": "Constante 3",
				"4": "Constante 4",
				"5": "constante 5",
				"6": "Constante 6",
				"7": "constante 7",
				"8": "constante 8",
				"9": "constante 9"
			},
			"nomes": {
				"poc": "Nombre de cuenta general de origen"
			},
			"definitionFaturacao": {
				"ccusto": "centro de costos",
				"zone": "Zona",
				"department": "Departamento",
				"subDepartment": "Subdepartamento",
				"family": "Familia",
				"bigFamily": "gran familia",
				"subFamily": "Subfamilia",
				"accountingCode": "código contable",
				"itemType": "Tipo de artículo",
				"class": "Clase",
				"category": "Categoría",
				"seller": "Vendedor",
				"process": "Proceso"
			},
			"definitionSalarios": {
				"ccusto": "centro de costos",
				"accountingCode": "código contable",
				"workerClass": "Código de clase trabajadora",
				"roleName": "Código de función",
				"employeeCCusto": "centro de costos de empleados",
				"categoryFamily": "código de familia de categoría",
				"zone": "Zona",
				"department": "Departamento"
			},
			"definitionImobilizado": {
				"ccusto": "centro de costos",
				"department": "Departamento",
				"bigFamily": "gran familia",
				"itemType": "Tipo de artículo",
				"accountingCode": "código contable"
			},
			"nomesFaturacao": {
				"ccusto": "Nombre del centro de costos",
				"zone": "Nombre de zona",
				"department": "Nombre del departamento",
				"subDepartment": "Nombre del subdepartamento",
				"family": "Apellido",
				"bigFamily": "gran apellido",
				"subFamily": "Nombre de subfamilia",
				"accountingCode": "Nombre en clave.",
				"itemType": "Nombre del tipo de artículo",
				"class": "nombre de clase",
				"category": "Nombre de categoría",
				"seller": "nombre del vendedor",
				"process": "Nombre del proceso"
			},
			"nomesSalarios": {
				"ccusto": "Nombre del centro de costos",
				"accountingCode": "Nombre en clave.",
				"workerClass": "Nombre de la clase trabajadora",
				"roleName": "Nombre de la función",
				"employeeCCusto": "Nombre del centro de costos del empleado",
				"categoryFamily": "Nombre de la familia de la categoría",
				"zone": "Nombre de zona",
				"department": "Nombre del departamento"
			},
			"nomesImobilizado": {
				"ccusto": "Nombre del centro de costos",
				"department": "Nombre del departamento",
				"family": "Apellido",
				"bigFamily": "gran apellido",
				"itemType": "Nombre del tipo de artículo",
				"accountingCode": "Nombre en clave."
			}
		},
		"changeTypePrompt": {
			"title": "¿Estás seguro de que quieres cambiar el tipo de máscara?",
			"message": "Cambiar el tipo de máscara borrará los cambios realizados."
		},
		"textPrompt": {
			"title": "Cambiar texto del campo"
		}
	},
	"mbconfigs": {
		"titles": {
			"pagRec": "Pagos/Recibos",
			"addtipodoc": "Agregar tipo de documento"
		},
		"fields": {
			"entidade": "Entidad",
			"accountID": "ID de API",
			"apiKey": "API clave",
			"contaTransf": "Transferir cuenta",
			"contaTransitoriaTransf": "Cuenta de transición",
			"transferenciaLancaSaldo": "Iniciar transferencia con monto de saldo",
			"contaComissao": "cuenta de comisión",
			"diario": "A diario",
			"descritivo": "Descriptivo",
			"codEstadoDocPago": "Estado del documento.",
			"enviarrecibo": "Enviar recibo del cliente por correo electrónico",
			"enviarreciboTooltip": "Validar correos electrónicos en archivos de clientes",
			"enviarrecibotitle": "Notificación de importación de pago",
			"enviarreciboinfo": "El sistema importará automáticamente",
			"dataInicioRef": "Crear referencias de",
			"geraRefMovimentoAberto": "Pretendo generar una segunda referencia con el saldo pendiente del cliente",
			"internalDesc": "Posibles tipos de documentos a configurar",
			"inativo": "Inactivo",
			"estado": "Estado",
			"advancedMode": "Modo avanzado",
			"tipoDoc": "Tipo de documento",
			"nDiasValidadeReferenciaMB": "Número de días de validez ref."
		},
		"messages": {
			"configsavedsucess": "¡Configuración de referencias de cajeros automáticos guardada con éxito!",
			"fornecedorRefMult": "Proveedor de referencia",
			"advancedModeInfo": "Sólo debes utilizar este modo si deseas configurar las cuentas en el plan de cuentas.",
			"ndocfaempty": "Debe seleccionar un tipo de documento.",
			"ndocfasuccess": "El tipo de documento se ha agregado correctamente.",
			"infogeral": "Para poder utilizar la plataforma EasyPay integrada con Centralgest Cloud, deberás obtener un nuevo CIN de EasyPay. Esto se aplica tanto a los nuevos clientes como a aquellos que ya tienen una cuenta creada con EasyPay.<br>Esto no significa que tendrás que hacerlo. cree una nueva cuenta, solo un nuevo CIN.",
			"loginempty": "Debe completar los campos \"API ID\" y \"API key\".",
			"functioning": "Tan pronto como EasyPay notifique al sistema, emitiremos el recibo respectivo.",
			"dataEasyPay": "Los datos anteriores son proporcionados por EasyPay.",
			"selectDocFas": "Seleccione los tipos de documentos en los que desea configurar Referencias: MB",
			"nDiasValidadeReferenciaMB": "Cuando el valor es \"0\", la referencia no caduca."
		},
		"btn": {
			"acessobackend": "Acceso al back office",
			"addTiposDoc": "Agregar más tipos de documentos",
			"criareasypay": "Crear una cuenta PagoFácil",
			"faq": "Preguntas frecuentes - Ayuda",
			"testConnection": "Conexión de prueba"
		},
		"tooltips": {
			"contaTransferencia": "Cuenta final para transferir el importe.",
			"contaTransitoria": "Cuenta temporal, utilizada después del recibo real",
			"contaComissao": "Cuenta donde se depositarán los costos de procesamiento"
		}
	},
	"meiosPagamento": {
		"title_detail": "Método de pago {{id}}",
		"title_new": "Nuevo método de pago",
		"title_plural": "Métodos de pago",
		"pesquisa": "buscar",
		"saved": "Método de pago {{id}}, guardado exitosamente",
		"error": "El método de pago {{id}}, no se puede guardar.",
		"deleted": "Método de pago {{id}}, eliminado correctamente",
		"fields": {
			"nCaixa": "Número",
			"nCaixaPlaceholder": "Si no se cumplimenta, se generará automáticamente.",
			"nome": "Nombre",
			"tipoMovimento": "Tipo",
			"nConta": "Nro. de cuenta",
			"moeda": "Código de moneda",
			"tipo": "Tipo de método de pago",
			"nib": "IBAN",
			"swift": "RÁPIDO",
			"porDefeito": "Por defecto"
		},
		"data": {
			"indiferente": "Indiferente",
			"recebimento": "Recibo",
			"pagamento": "Pago",
			"dinheiro": "Dinero",
			"multibanco": "cajero automático",
			"cartaoCredito": "tarjeta de crédito",
			"cheque": "Controlar",
			"valorAdicional": "Valor adicional",
			"pontos": "Agujas",
			"contraReemsolso": "Contra reembolso",
			"trfBancaria": "transferencia bancaria",
			"payPal": "PayPal"
		},
		"btn": {
			"verContas": "Ver cuentas",
			"buttonOptions": "Opciones",
			"esconderContas": "Ocultar cuentas"
		},
		"info": {
			"entregue": "Entregado",
			"troco": "Cambiar"
		},
		"mensagens": {
			"meioPagSemMoedaEmpresaParaTroco": "No se puede encontrar un método de pago en efectivo en la moneda de la empresa para depositar el cambio.",
			"montanteMeioPagNaoIgualTotalDoc": "El monto en los métodos de pago {{amount}} no es igual al total del documento {{total}}",
			"adicionarMeiosPagCancelado": "Agregar métodos de pago cancelados",
			"meioPagamentoAlteradoPorOmissao": "Este método de pago está configurado por defecto en {{type}}, al cambiar el tipo de movimiento dejará de ser elegible y automáticamente se seleccionará otro.",
			"meioPagamentoAlteradoPorOmissaoTitle": "Confirmar el cambio del valor predeterminado para el tipo de pago",
			"erroTipoMeioPagamentoOmissao": "El método de pago elegido no tiene el mismo tipo de pago que la configuración",
			"contaInvalida": "La cuenta {{nConta}} no es una cuenta de transacciones."
		},
		"table": {
			"actions": {
				"porDefeitoPara": "Por defecto para",
				"alterarPorDefeito": "Cambiar los métodos de pago predeterminados",
				"recibos": "Ingresos",
				"pagamentos": "Pagos",
				"errorDesactivate": "No puedes dejar un tipo de pago sin un método de pago predeterminado"
			}
		}
	},
	"mercados": {
		"pesquisa": "Mercados",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"notApplicable": "N / A",
			"national": "Nacional",
			"cee": "UE",
			"thirdPartyCountries": "Terceros países"
		}
	},
	"modelo10": {
		"groups": {
			"atencao": {
				"p1": "Cuadro 00",
				"p2": "A la hora de cumplimentar el modelo de declaración 10 se deben tener en cuenta algunos aspectos declarativos, destacando los siguientes:",
				"p3": {
					"p3-1": "Para las declaraciones del año 2019 y años siguientes, si han sido",
					"p3-2": "pagado o puesto a disposición",
					"p3-3": "ingreso relativo",
					"p3-4": "a años anteriores",
					"p3-5": ", la tabla 05 campo 02 deberá cumplimentarse de acuerdo con la ayuda proporcionada en esta tabla/campo, destacando la necesidad de indicar el año al que se refiere la renta en lugar del número de años."
				},
				"p4": "Las personas o entidades obligadas a presentar la Declaración de Remuneraciones Mensuales (DMR) no pueden declarar rentas por trabajo dependiente (categoría A) en la declaración del Modelo 10.",
				"p5": "Las personas físicas que deban ingresos por empleo dependiente y que no estén obligadas a presentar el DMR podrán optar por presentar la declaración Modelo 10, siempre y cuando estos ingresos no hayan estado sujetos a ninguna retención en origen.",
				"p6": {
					"p6-1": "La declaración Modelo 10 tiene como objetivo declarar los ingresos sujetos a impuestos, exentos y no sujetos a impuestos recibidos por los contribuyentes del IRS.",
					"p6-2": "residentes",
					"p6-3": "en el territorio nacional, así como las respectivas retenciones en origen."
				},
				"p7": "Las rentas de la propiedad siempre deben declararse, con códigos F, incluso si tributarán como rentas de categoría B por ser atribuibles a actividades empresariales o profesionales, de conformidad con los incisos a) del párrafo 2 del artículo 3 del Código IRS.",
				"p8": {
					"p8-1": "El espacio geográfico que se indicará en la tabla 5, campo 05 (",
					"p8-2": "Península, Región Autónoma de las Azores, Región Autónoma de Madeira o Extranjero",
					"p8-3": ") debe corresponder al lugar donde se obtuvieron los ingresos."
				}
			},
			"financAreaDomi": {
				"title": "Servicio de finanzas del área del hogar",
				"codservfinan": "Código de servicio financiero"
			},
			"nif": {
				"title": "Número de identificación fiscal",
				"nomepassivo": "Nombre del sujeto pasivo",
				"nif": "NIF"
			},
			"importanciaretidas": {
				"title": "Importancias retenidas",
				"quadro4": {
					"campo1": "A - TRABAJO DEPENDIENTE",
					"campo2": "B - INGRESOS EMPRESARIALES Y PROFESIONALES",
					"campo3": "E - OTROS INGRESOS DE CAPITAL",
					"campo4": "EE - SALDOS DE ACREEDORES A/C (Artículo 12-A, núm. 3, letra a) del Decreto-Ley 41/91, de 22 de enero)",
					"campo5": "F - EDIFICIOS",
					"campo6": "G - AUMENTOS DE PATRIMONIO",
					"campo7": "H - PENSIONES",
					"campo8": "RETENCIONES DEL IRC (Artículo 94 del CIRC)",
					"campo9": "SUMA (01 A 08)",
					"campo10": "RETENCIONES SOBRE HONORARIOS DE LIBERACIÓN",
					"campo11": "COMPENSACIONES DEL IRS/IRC",
					"campo12": "TOTAL (09+10-11)",
					"campo13": "RETENCIÓN DE RECARGO (aplicable de 2013 a 2017)"
				}
			},
			"reltitrendimentos": {
				"title": "Lista de titulares de ingresos",
				"quadro5": {
					"table": {
						"sujpassivo": "sujeto pasivo",
						"rendAnoAnt": "Ingresos de años anteriores",
						"rendAno": "Ingresos del año",
						"tipoRend": "Tipo de ingreso",
						"locobtrend": "Lugar de obtención de ingresos",
						"retIrsIrc": "Retenciones del IRS/IRC",
						"contribObr": "Contribuciones obligatorias",
						"quotSindicais": "Cuotas sindicales",
						"retSobreTaxa": "Retención de recargos (años 2013 a 2017)",
						"valores": "Valores",
						"nrAnos": "Número de años",
						"semdados": "Sin datos",
						"addlinha": "Agregar línea"
					},
					"tableTot": {
						"rendAnoAnt": "Ingresos de años anteriores",
						"rendAno": "Ingresos del año",
						"retIrsIrc": "Retenciones del IRS/IRC",
						"contribObr": "Contribuciones obligatorias",
						"quotSindicais": "Cuotas sindicales",
						"retSobreTaxa": "Retención de Recargo (años 2013 a 2017)",
						"somacontr": "Suma de control"
					}
				}
			},
			"tipodec": {
				"title": "Tipo de Declaración",
				"campo1": "1ra Declaración del Año",
				"campo2": "Declaración de reemplazo",
				"campo3": "Declaración presentada de conformidad con el apartado d), n.º 1, art.",
				"campo4": "Fecha del hecho que determinó la obligación de declarar o modificar rentas ya declaradas"
			},
			"identSujPass": {
				"title": "Identificación del Sujeto Tributario o Representación Legal y la CC",
				"campo1": "NIF del Contador Público",
				"campo2": "Número del Sujeto Tributario o Representante Legal"
			}
		},
		"modal": {
			"config": {
				"title": "Configuración de la plantilla de contabilidad de rendimiento de cuentas radicales 10",
				"radicais": "Radicales de cuenta"
			},
			"retencaomod10": {
				"title": "Mantenimiento de ingresos",
				"nif": "Sujeto imponible (NIF)",
				"rendAnoAnt": "Ingresos de años anteriores",
				"rendimentosAnterioresValores": "Valores",
				"rendimentosAnterioresNAnos": "Número de años",
				"rendimentosDoAno": "Ingresos del año",
				"tipoRendimentos": "Tipo de ingreso",
				"localObtencao": "Lugar de obtención de ingresos",
				"retencoesIRSIRC": "Retenciones del IRS/IRC",
				"contribuicoes": "Contribuciones obligatorias",
				"quotizacoes": "Cuotas sindicales",
				"retencaoSobretaxa": "Retención de recargos (años 2013 a 2017)"
			},
			"configws": {
				"title": "Configuración del servicio web",
				"username": "Nombre de usuario del colaborador",
				"password": "Contraseña del contribuyente",
				"servicoInvCC": "Servicio invocado por Contador Público (CC)",
				"ccPowers": "CC con plenas facultades declarativas para Contrib.",
				"usernameCC": "nombre de usuario CC",
				"passwordCC": "contraseña CC",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> El servicio ya está configurado.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> El servicio no está configurado.",
				"configsaved": "Configuración guardada exitosamente."
			}
		},
		"btn": {
			"config": "Ajustes",
			"limpo": "Limpio",
			"valorescarre": "Cargar valores guardados",
			"valoresauto": "Cargar valores automáticos",
			"criarficheiro": "Crear archivo",
			"retencoes": "Ver reservas",
			"btnSubmeterAt": "Enviar AT",
			"btnValidarAt": "Validar en AT",
			"btnConfigWS": "Configuración de WS"
		},
		"message": {
			"registosdel": "Registros eliminados exitosamente.",
			"dadoscarregados": "¡Datos cargados exitosamente!",
			"dadoscarregadosClean": "¡Campos despejados exitosamente!",
			"dadosguardados": "¡Datos guardados exitosamente!",
			"temDeGuardar": "Debes guardar los datos para poder crear el archivo.",
			"q6c4NotNull": "Debes rellenar el campo 4 de la tabla 6",
			"servicoAT": {
				"validarAtSuccess": "Declaración validada con éxito",
				"validarAtFailed": "Error al validar la declaración en AT",
				"submeterAtSuccess": "Declaración enviada exitosamente",
				"submeterAtFailed": "Error al presentar la declaración en AT"
			}
		}
	},
	"modelo22": {
		"tabs": {
			"anexos": "Adjuntos",
			"rosto": "Rostro",
			"anexoA": "Anexo A",
			"anexoB": "Anexo B",
			"anexoC": "Anexo C",
			"anexoD": "Anexo D",
			"anexoE": "Anexo E",
			"anexoF": "Anexo F",
			"anexoG": "Anexo G",
			"anexoAIMI": "Anexo AIMI",
			"adicionarAnexo": "Agregar archivos adjuntos"
		},
		"btn": {
			"gravar": "para grabar",
			"validar": "Validar",
			"criarficheiro": "Archivo",
			"entregar": "Entregar",
			"anular": "Cancelar todo",
			"calcular": "Calcular",
			"repor": "Restablecer todo",
			"contasdefall": "Cuentas definidas para los campos.",
			"contasdefcopy": "Copiar cuentas definidas para campos a otra empresa",
			"limpaValores": "Borrar valores",
			"balancetes": "Balances",
			"extratos": "Estado de cuenta",
			"calcDerrama": "Cálculo del derrame",
			"tributAutonomas": "Impuestos Autonómicos",
			"prejFiscais": "Pérdidas Fiscales",
			"retencoes": "Retenciones",
			"documentosFiscais": "Documentos fiscales"
		},
		"comum": {
			"de": "De",
			"a": "el",
			"ate": "hasta",
			"periodo": "Período",
			"semdados": "Sin datos",
			"addline": "Agregar línea",
			"sim": "Sí",
			"nao": "No",
			"tooltipContasDef": "Definición de cuentas para el campo."
		},
		"rosto": {
			"quadro01": {
				"title": "Período impositivo"
			},
			"quadro02": {
				"title": "Zona de sede, dirección efectiva o establecimiento permanente",
				"campo01": "Código del Servicio Financiero de Sede o Establecimiento Permanente",
				"campo02": "Código de servicio financiero de gestión eficaz"
			},
			"quadro03": {
				"title": "Identificación y caracterización del contribuyente",
				"sujPass": "Designación",
				"campo02": "Número de Identificación Fiscal (NIF)",
				"campo03": {
					"title": "Tipo de contribuyente",
					"radio1": "Residente que realiza principalmente actividad comercial, industrial o agrícola",
					"radio2": "Residente que no realiza, como actividad principal, actividad comercial, industrial o agrícola",
					"radio3": "No residente con establecimiento estable",
					"radio4": "No residente sin establecimiento permanente"
				},
				"campo3A": {
					"title": "Calificación de la empresa en los términos del Anexo del Decreto-Ley nº 372/2007, de 6 de noviembre o como cooperativa",
					"desc": "Si marcó las casillas 1 o 3 en la Tabla 03 - 3, indique cómo califica en los términos establecidos en el Anexo del Decreto-Ley n° 372/2007, de 6 de noviembre.",
					"radio1": "Mediana empresa",
					"radio2": "No PYME",
					"radio3": "microempresa",
					"radio4": "Pequeña empresa",
					"check5": "Cooperativa",
					"check6": "Indique si califica como empresa de pequeña y mediana capitalización [(Small Mid Cap (SMC)]"
				},
				"campo3B": {
					"title": "Organismos de inversión colectiva",
					"field1": "Indique si se trata de una Organización de Inversión Colectiva gravada según el artículo 22 de la EBF"
				},
				"campo3C": {
					"title": "Imputación de ingresos (artículo 5, párrafo 9)",
					"desc": "¿Se considera establecimiento permanente a los efectos de la imputación prevista en el apartado 9 del artículo 5?",
					"field1": "Sí"
				},
				"campo4": {
					"title": "Regímenes de impuestos sobre la renta",
					"field3": "Exención definitiva",
					"field4": "Exención temporal",
					"field5": "Reducción de tarifas",
					"field6": "Simplificado",
					"field7": "Transparencia fiscal",
					"field8": "grupos de empresas",
					"field9": "NIF de la sociedad controladora / Responsable (artículo 69.º-A, párrafos 3 y 4)",
					"field10": "¿Tiene intención de ejercer la opción por las tarifas del artículo 87, núm. 1?",
					"field11": "¿Se produjo alguna de las situaciones mencionadas en el párrafo 7 del antiguo artículo 87?",
					"field12": "Artículo 36-A del FFE",
					"field13": "Régimen especial de las actividades de transporte marítimo (Decreto-ley núm. 92/2019, de 13 de noviembre)"
				},
				"campo4A": {
					"title": "Traslado de residencia/cese de actividad de establecimiento permanente/asignación de bienes (artículos 83, 84 y 54-A, núm. 11)",
					"desc": "Si durante el período impositivo se produjo un traslado de residencia, afectación de bienes y establecimiento permanente situado fuera del territorio portugués, cese de actividad o transmisión de bienes de un establecimiento permanente situado en territorio portugués, indique el lugar de destino",
					"field1": "Países de la UE/EEE"
				}
			},
			"quadro04": {
				"title": "Características de la declaración.",
				"campo1": {
					"title": "Tipo de declaración",
					"radio1": "1ª Declaración del período",
					"radio2": "Declaración de sustitución (artículo 122, apartados 1 y 2)",
					"radio3": "Declaración de sustitución (art. 64, n. 4)",
					"radio4": "Declaración de sustitución (artículo 120, apartados 8 y 9)",
					"radio5": "Declaración de sustitución (art. 64, n. 4) fuera del plazo legal",
					"radio6": "Declaración de sustitución (art. 122, n. 3)",
					"data": "Fecha de notificación de decisión/sentencia"
				},
				"campo2": {
					"title": "Declaraciones especiales",
					"check1": "Declaración de grupo",
					"check2": "Declaración del período de liquidación",
					"check3": "Declaración de periodo de cesación",
					"check4": "antes del cambio",
					"check5": "despues del cambio",
					"check7": "Declaración del periodo de inicio de actividad",
					"check9": "Antes de la disolución",
					"check10": "Después de la disolución",
					"data6": "Fecha de cese",
					"data8": "Fecha de transmisión/adquisición (entidades no residentes sin establecimiento permanente)",
					"data11": "Fecha de disolución",
					"subtitle1": "Declaración con periodo impositivo especial"
				}
			},
			"quadro05": {
				"title": "Identificación del representante legal y contador público",
				"campo1": "NIF del representante legal",
				"campo2": "TIN del contador público",
				"desc": "Si hubiera impedimento justo (artículo 12.º-A del DL n. 452/99, de 5 de noviembre), indique:",
				"campo4": "Hecho que determinó el justo impedimento",
				"campo5": "Fecha de inicio de la ocurrencia del hecho",
				"campo6": "Fecha de cese del evento"
			},
			"quadro07": {
				"title": "(Período 2010 y posteriores) Cálculo de la utilidad imponible",
				"acrescer": "PARA AÑADIR",
				"deduzir": "PARA DEDUCIR",
				"campo701": "UTILIDAD NETA DEL PERIODO",
				"campo702": "Los cambios patrimoniales positivos no se reflejan en el resultado neto del período (art. 21) y la participación en la subvención relativa a los activos no corrientes, no depreciables/no amortizables [art. 22 núm.",
				"campo703": "Variaciones patrimoniales positivas (régimen transitorio previsto en el artículo 5, apartados 1, 5 y 6 del DL nº 159/2009, de 13/7)",
				"campo704": "Cambios patrimoniales negativos no reflejados en la utilidad neta del período (art. 24)",
				"campo705": "Variaciones patrimoniales negativas (régimen transitorio previsto en el artículo 5, apartados 1, 5 y 6 del DL nº 159/2009, de 13/7)",
				"campo706": "Cambio al régimen fiscal de los contratos de construcción (correcciones positivas)",
				"campo707": "Cambio en el régimen fiscal de los contratos de construcción (correcciones negativas)",
				"campo708": "SUMA (campos 701 + 702 + 703 - 704 - 705 + 706 - 707)",
				"campo709": "Renta imponible/beneficio imponible imputado por empresas transparentes, ACE o AEIE (art. 6)",
				"campo710": "Correcciones relativas a períodos impositivos anteriores (art. 18, n. 2)",
				"campo711": "Ventas y prestación de servicios con pago aplazado: diferencia entre el importe nominal de la contraprestación y el valor razonable (art. 18, n. 5)",
				"campo782": "Gastos relativos a existencias y suministros y servicios exteriores con pago aplazado: gastos por intereses (art. 18, n. 5)",
				"campo712": "Anulación de los efectos del método de equivalencia patrimonial y del método de consolidación proporcional en el caso de negocios conjuntos que estén sujetos al IRC (artículo 18, apartado 8)",
				"campo713": "Ajustes no deducibles derivados de la aplicación del valor razonable (art. 18, n. 9)",
				"campo714": "Pagos basados ​​en acciones (art. 18, n. 11)",
				"campo715": "Gastos por indemnizaciones por cese, prestaciones de jubilación y otras prestaciones a los empleados post-empleo o de larga duración (art. 18, n. 12)",
				"campo717": "Gastos ocasionados por la costosa transmisión de acciones de capital (antiguo artículo 23, apartados 3, 4 y primera parte del apartado 5)",
				"campo721": "Provisiones no deducibles o fuera de los límites legales (artículos 19, núm. 4 y 39) y pérdidas por deterioro de activos financieros no deducibles fiscalmente",
				"campo724": "IRC, incluida la tributación autonómica, y otros impuestos que afecten directa o indirectamente a las ganancias [art. 23.º-A, núm.",
				"campo725": "Impuestos diferidos [art. 23.º-A, núm.",
				"campo716": "Gastos indocumentados [art. 23.º-A, núm.",
				"campo731": "Cargos no debidamente documentados [art. 23.º-A, núm.",
				"campo726": "Cargos acreditados en documentos emitidos por contribuyentes con NIF inexistente o inválido o por contribuyentes terminados de oficio [art. 23.º-A, núm.",
				"campo783": "Gastos ilícitos [art. 23.º-A, núm.",
				"campo728": "Multas, multas y otros cargos, incluidos intereses compensatorios y moratorios, por la comisión de infracciones [art. 23.º-A, núm.",
				"campo727": "Impuestos, tasas y demás impuestos que gravan a terceros y que el contribuyente no está legalmente obligado a soportar [art. 23.º-A, núm.",
				"campo729": "Indemnización por siniestros asegurables [art. 23.º-A, núm.",
				"campo730": "Dietas y gastos de compensación por desplazamiento en vehículo propio [art. 23.º-A, núm.",
				"campo732": "Cargos por alquiler de vehículos sin conductor [art. 23.º-A, núm.",
				"campo733": "Cargos por combustible [art. 23.º-A, núm. 1, al.",
				"campo784": "Cargas relativas a embarcaciones de recreo y aeronaves de pasajeros [art. 23.º-A, núm.",
				"campo734": "Intereses y otras formas de remuneración sobre suministros y préstamos hechos por los socios a la sociedad [art. 23.º-A, núm.",
				"campo735": "Gastos no deducibles relativos a la participación en beneficios de los miembros de órganos sociales [art. 23.º-A, núm.",
				"campo780": "Contribución al sector bancario [art. 23.º-A, núm. 1, al.",
				"campo785": "Contribución extraordinaria al sector energético [art. 23.º-A, núm. 1, al.",
				"campo802": "Contribución extraordinaria a la industria farmacéutica [art. 23.º-A, núm. 1, al.",
				"campo746": "Cantidades pagadas o adeudadas a entidades no residentes sujetas a régimen fiscal privilegiado [art. 23.º-A, núm.",
				"campo737": "50% de otras pérdidas relativas a acciones del capital u otros componentes del patrimonio (ex-art. 45, n° 3, parte final)",
				"campo786": "Otras pérdidas relativas a instrumentos de patrimonio y gastos incurridos con la transmisión onerosa de instrumentos de patrimonio de entidades no residentes sujetas a un régimen fiscal privilegiado (art. 23.º-A, apartados 2 y 3)",
				"campo718": "Pérdidas por deterioro en existencias fuera de los límites legales (artículo 28) y en créditos no deducibles fiscalmente o fuera de los límites legales (artículos 28-A al 28-C)",
				"campo719": "Pérdidas por deterioro de activos no corrientes (art. 31-B) y depreciaciones y amortizaciones (art. 34, n. 1), no aceptadas como gastos",
				"campo720": "40% del incremento de la amortización del inmovilizado material como consecuencia de la revalorización fiscal (art. 15, núm. 2 del DR 25/2009, de 14/9)",
				"campo722": "Deudas incobrables no aceptadas como gastos (art. 41)",
				"campo723": "Logros de utilidad social no deducibles (art. 43)",
				"campo736": "Pérdidas contables",
				"campo738": "Impuesto sobre el valor añadido resultante de cambios en el modelo de valoración [art. 46, núm. 5, al.",
				"campo739": "Diferencia positiva entre ganancias fiscales y pérdidas fiscales sin intención de reinversión (art. 46)",
				"campo740": "El 50% de la diferencia positiva entre ganancias y pérdidas fiscales con expresa intención de reinversión (art. 48, n. 1)",
				"campo741": "Devengo por no reinversión o falta de mantenimiento de activos en poder del adquirente (artículo 48, párrafo 6)",
				"campo742": "Ganancias fiscales - régimen transitorio [art. 7, núm. 7, al.",
				"campo743": "Correcciones relativas a instrumentos financieros derivados (art. 49)",
				"campo787": "Pérdidas procedentes de establecimientos permanentes situados fuera del territorio portugués (art. 54-A)",
				"campo744": "Correcciones relativas a precios de transferencia (art. 63, n. 8)",
				"campo745": "Diferencia positiva entre el valor activo fiscal definitivo del inmueble y el valor contenido en el contrato [art. 64, núm. 3, al.",
				"campo747": "Atribución de rentas de entidades no residentes sujetas a régimen fiscal privilegiado (art. 66)",
				"campo748": "Limitación a la deducibilidad de los gastos netos de financiación (art. 67)",
				"campo749": "Correcciones en los supuestos de crédito fiscal por doble imposición legal internacional (art. 68, n. 1)",
				"campo788": "Correcciones en los casos de crédito fiscal por doble imposición económica internacional (art. 68, n. 3)",
				"campo750": "Correcciones resultantes de la opción por el régimen especial aplicable a las fusiones, escisiones, transmisiones de activos y canjes de acciones (artículos 74, 76 y 77)",
				"campo789": "Traslado de residencia, asignación de activos a un establecimiento permanente situado fuera del territorio portugués, cese de actividad o transferencia de activos desde un establecimiento permanente situado en territorio portugués: saldo positivo referido a activos transferidos a otro Estado miembro de la UE o del EEE o asignados a un establecimiento permanente establecimiento ubicado allí (artículos 83, 84 y 54-A, párrafo 11)",
				"campo790": "Traslado de residencia, asignación de activos a un establecimiento permanente situado fuera del territorio portugués, cese de actividad o transferencia de activos desde un establecimiento permanente situado en territorio portugués: saldo positivo referido a activos transferidos a países fuera de la UE o del EEE o adscritos a un establecimiento permanente ubicado allí (artículos 83, 84 y 54 -A, núm. 11)",
				"campo751": "Donaciones imprevistas o fuera de los límites legales (artículos 62, 62-A y 62-B de la EBF)",
				"campo803": "Asimetrías híbridas y asimetrías de residencia fiscal (artículos 68.º-B, núm. 1 y 68.º-D, núm. 1)",
				"campo779": "Gastos financieros no deducibles (ex art. 32, n. 2 del EBF)",
				"campo797": "Adicional al Impuesto Municipal sobre Bienes Inmuebles (artículo 135.º-J del Código IMI)",
				"campo799": "Gastos y pérdidas relacionados con las actividades de transporte marítimo a las que se aplica el régimen especial de determinación de la base imponible (artículo 6 del Anexo del Decreto-Ley núm. 92/2018, de 13 de noviembre)",
				"campo804": "Aportaciones solidarias temporales en los sectores de energía y distribución de alimentos (art. 16 de la Ley n. 24-B/2022, de 30 de diciembre)",
				"campo752": "Otras adiciones",
				"campo753": "SUMA (campos 708 al 752)",
				"campo754": "Gastos o cargas de proyección económica plurianual registrados como gastos durante el POC y aún no tributados [art. 22, al.",
				"campo755": "Pérdida fiscal imputada por ACE o AEIE (art. 6)",
				"campo756": "Correcciones relativas a períodos impositivos anteriores (art. 18, n. 2)",
				"campo757": "Ventas y prestación de servicios con pago aplazado: ingresos por intereses (art. 18, n. 5)",
				"campo791": "Gastos relativos a existencias y suministros y servicios externos con pago aplazado: diferencia entre el importe nominal de la contraprestación y el valor razonable (art. 18, núm. 5)",
				"campo758": "Anulación de los efectos del método de equivalencia patrimonial y del método de consolidación proporcional en el caso de negocios conjuntos que estén sujetos al IRC (artículo 18, apartado 8)",
				"campo759": "Ajustes no imponibles derivados de la aplicación del valor razonable (art. 18, n. 9)",
				"campo760": "Pagos basados ​​en acciones (art. 18(11))",
				"campo761": "Pago o puesta a disposición de los beneficiarios de indemnizaciones por terminación de la relación laboral, prestaciones de jubilación y otras prestaciones a los empleados post-empleo o de larga duración (art. 18, n. 12)",
				"campo762": "Reversión de pérdidas por deterioro gravadas (artículos 28, apartado 3 y 28-A, apartado 3)",
				"campo763": "Depreciaciones y amortizaciones gravadas en períodos impositivos anteriores (art. 20 del DR 25/2009, de 14/9)",
				"campo781": "Pérdidas por deterioro gravadas en períodos impositivos anteriores (artículos 28, 28 -A, n. 1 y 31 -B, n. 7)",
				"campo764": "Reversión de provisiones gravadas (artículos 19, n. 4 y 39, n. 4)",
				"campo765": "Devolución de impuestos no deducibles y estimación de exceso de impuestos",
				"campo766": "Impuestos diferidos [art. 23.º-A, núm.",
				"campo792": "Gastos tributarios relativos a activos intangibles, propiedades de inversión y activos biológicos no consumibles (art. 45-A)",
				"campo767": "Ganancias contables",
				"campo768": "50% de la pérdida fiscal resultante de cambios en el modelo de valoración [art. 46, n. 5, al.",
				"campo769": "Diferencia negativa entre ganancias fiscales y pérdidas fiscales (art. 46)",
				"campo770": "Correcciones relativas a instrumentos financieros derivados (art. 49)",
				"campo793": "Deducción de rentas provenientes de derechos de autor y derechos de propiedad industrial dentro del límite del párrafo 8 del artículo 50-A",
				"campo771": "Eliminación de la doble imposición económica de las utilidades distribuidas y de las reservas (art. 51 y 51 -D)",
				"campo794": "Beneficios procedentes de establecimientos permanentes situados fuera del territorio portugués (art. 54 -A)",
				"campo772": "Corrección por parte del adquirente del inmueble al adoptar el valor fiscal definitivo del activo para determinar el resultado imponible en la respectiva transferencia [art.",
				"campo795": "Declaración de gastos de financiación netos de períodos impositivos anteriores (art. 67)",
				"campo773": "Correcciones resultantes de la opción por el régimen especial aplicable a las fusiones, escisiones, transmisiones de activos y permutas de partes sociales (artículos 74, 76 y 77)",
				"campo796": "Traslado de residencia, asignación de activos a un establecimiento permanente situado fuera del territorio portugués, cese de actividad o transferencia de activos desde un establecimiento permanente situado en territorio portugués: saldo negativo referido a activos transferidos fuera del territorio portugués o asignados a un establecimiento permanente situado allí ( artículos 83, 84 y 54 -A, núm. 11)",
				"campo774": "Beneficios fiscales",
				"campo800": "Ingresos y rentas correspondientes a actividades de transporte marítimo a las que es aplicable el régimen especial para la determinación de la base imponible (artículo 6 del Anexo del Decreto-Ley núm. 92/2018, de 13 de noviembre)",
				"campo801": "Incremento de la depreciación o amortización resultante de revalorizaciones realizadas de conformidad con el Decreto-Ley n.º 66/2016, de 3 de noviembre (art. 8 del Decreto-Ley)",
				"campo798": "Pérdidas por deterioro de créditos y prestaciones post-empleo o a largo plazo a los empleados (art. 4 del anexo de la Ley núm. 61/2014, de 26 de agosto)",
				"campo775": "Otras deducciones",
				"campo776": "SUMA (campos 754 a 798 + 775 + 801)",
				"campo777": "PÉRDIDA POR EFECTOS FISCALES (Si 776 > 753)",
				"campo778": "UTILIDAD IMPONIBLE (Si 753 >= 776) (A llevar a la tabla 09)"
			},
			"quadro07Desativado": {
				"title": "(Periodo 2009 y anteriores) Cálculo de la ganancia imponible"
			},
			"quadro08": {
				"title": "Regímenes de tarifas",
				"sect81": {
					"title": "Esquemas de reducción de tarifas",
					"taxas": "Tasas impositivas",
					"check242": "Establecimientos educativos privados (ex-art. 56 de la EBF)",
					"check245": "Beneficios relacionados con la interioridad (art. 41.º-B y ex-art. 43.º de la EBF)",
					"check269": "Beneficios fiscales aplicables a los territorios del interior (R.A. Madeira) (artículo 19.º-A del Decreto Legislativo Regional n.º 28.º-A/2021/M, de 30 de diciembre)",
					"check270": "Beneficios fiscales aplicables a los territorios del interior (R. A. Azores) (artículo 38 del Decreto Legislativo Regional nº 1/2023/A, de 5 de enero)",
					"check248": "Antiguo Estatuto Tributario de las Cooperativas (artículo 7, apartado 3 de la Ley nº 85/98, de 16/12)",
					"check260": "Entidades autorizadas en la Zona Franca de Madeira (ex-art. 35 de la EBF)",
					"check265": "Entidades autorizadas en la Zona Franca de Madeira (artículos 36 y 36-A de la EBF)",
					"check3": "Beneficios relacionados con la interioridad (art. 41.º-B y ex-art. 43.º de la EBF)"
				},
				"sect82": {
					"title": "Régimen general",
					"taxas": "Tasas impositivas",
					"check246": "Región Autónoma de las Azores (Decreto Legislativo Regional nº 2/1999/A, de 20/1)",
					"check249": "Región Autónoma de Madeira (Decreto Legislativo Regional nº 2/2001/M, de 20/2)",
					"check262": "Rentas de la propiedad de entidades no residentes sin establecimiento permanente (art. 87, núm. 4)",
					"check263": "Plusvalías/incrementos patrimoniales obtenidos por entidades no residentes sin establecimiento permanente (art. 87, n. 4)",
					"check266": "Ganancias de capital sobre valores obtenidos por entidades no residentes sin establecimiento permanente (art. 87, núm. 4)",
					"check267": "Rentas derivadas de la enajenación de participaciones en FII y participaciones en SII, obtenidas por entidades no residentes sin establecimiento permanente (art. 22.º-A, núm. 1, inciso c) de la EBF)",
					"check268": "Rentas de capital no sujetas a retención en origen definitiva",
					"check264": "Otras rentas obtenidas por entidades no residentes sin establecimiento permanente"
				}
			},
			"quadro09": {
				"title": "Determinación de la renta imponible",
				"transq7": "(Q.07 transporte)",
				"reggeral": "Régimen general",
				"redtaxa": "Con reducción de tarifas",
				"isencao": "Con exención",
				"regsimp": "Régimen simplificado (vigente hasta 2010)",
				"prejFiscal": "1. PÉRDIDA TRIBUTARIA",
				"lucrotrib": "2. BENEFICIO IMPONIBLE",
				"regespecial": "Régimen especial para grupos de empresas",
				"field380": "Suma algebraica de resultados fiscales",
				"field381": "Beneficios distribuidos (ex-art. 70, n. 2)",
				"field395": "Gastos netos de financiación (opción prevista en el artículo 67, nº 5)",
				"field500": "Ajuste REAID (art. 5, núm. 1 al. b) del Anexo de la Ley 61/2014, de 26 de agosto)",
				"field376": "Resultados internos eliminados bajo el RTLC anterior, a incluirse en la utilidad imponible del ejercicio",
				"field382": "Resultado fiscal del grupo",
				"table396": {
					"title": "Pérdidas individuales deducidas, comprobadas en períodos anteriores al inicio de la aplicación del régimen:",
					"fields": {
						"prej": "Pérdidas",
						"periodo": "Período",
						"nif": "NIF"
					}
				},
				"table398": {
					"title": "Participación en las pérdidas fiscales deducidas en caso de adquisición de grupos de empresas (artículo 71, apartados 4 y 5)",
					"fields": {
						"prej": "Pérdidas",
						"periodo": "Período",
						"nif": "NIF"
					}
				},
				"prejfiscded": "Pérdidas fiscales deducibles",
				"prejfiscaut": "Pérdidas fiscales autorizadas/transmitidas (art. 75, apartados 1 y 3)",
				"prejfiscaut2": "Pérdidas fiscales autorizadas/transmitidas [art. 15, núm. 1, al.",
				"alttitularidade": "Cambio de titularidad de más del 50% del capital social o de la mayoría de los derechos de voto - Pérdidas fiscales no deducibles (art. 52, n. 8)",
				"deducoes": "3. DEDUCCIONES",
				"subtitledesc": "Desglose de bases imponibles negativas deducidas, por periodo de cálculo e importe",
				"subtitleregesp": "Régimen especial aplicable a los adquirentes de entidades consideradas empresas en crisis - artículo 15 de la Ley 27-A/2020, de 24 de julio.",
				"table309": {
					"fields": {
						"periodo": "309.1 - Periodo",
						"montante": "309.2 - Cantidad"
					}
				},
				"table320": {
					"fields": {
						"periodo": "320.1 - Periodo",
						"montante": "320.2 - Importe"
					}
				},
				"table331": {
					"fields": {
						"periodo": "331.1 - Periodo",
						"montante": "331.2 - Importe"
					}
				},
				"benfisc": "Beneficios fiscales",
				"metcoletavel": "4. MATERIAL COLECCIONABLE (2-3)",
				"field336": "ZFM - Base imponible que excede los topes máximos (artículos 36, n. 3 y 36-A, n. 4 de la EBF)",
				"field399": "COLECTIVOS DEPORTIVOS - Deducción de las cantidades invertidas hasta el 50% de la base imponible (art. 54, n. 2 de la EBF)",
				"existprej": "Si existen pérdidas fiscales autorizadas/transmitidas, indique:",
				"field397": "Monto total utilizado en el período (397-A + 397-B)",
				"table397a": {
					"title": "Valor utilizado en el período [art. 15, núm. 1 al.",
					"fields": {
						"valor": "397.A - Valor utilizado en el período",
						"nif": "NIF",
						"periodo": "397.C - Período"
					}
				},
				"table397b": {
					"title": "Valor utilizado en el período (artículo 75, párrafos 1 y 3)",
					"fields": {
						"valor": "397.B - Valor utilizado en el período",
						"nif": "NIF",
						"periodo": "397.D - Período"
					}
				},
				"table309B": {
					"fields": {
						"montante": "309.4 - Importe",
						"nif": "309.5 - NIF de la empresa considerada empresa en crisis",
						"periodo": "309.3 - Periodo"
					}
				},
				"field300": "Base imponible del régimen especial (campo 11 de la tabla 04 del anexo G)",
				"field346": "MATERIAL COLECCIONABLE NO EXENTO [(311 - 399) + 322 + 336] o 409 o campo 42 del Anexo E, excepto campo 300"
			},
			"quadro10": {
				"title": "Cálculo de impuestos",
				"field347A": "Impuesto al tipo normal (art. 87, núm. 2, 1º 50.000,00 € de base imponible para Pymes o Pymes) (c. 311 de la Q.09 del M22 o C. 42 del Anexo E) x 17 %",
				"field347B": "Impuesto a la tasa normal (art. 87, no. 1) (c. 311 del q.09 del m22 o c. 42 del anexo E) x 21%",
				"impoutrataxas": "Impuesto a otras tasas",
				"field350": "Impuesto atribuible a la Región Autónoma de las Azores",
				"field370": "Impuesto atribuible a la Región Autónoma de Madeira",
				"field351": "COLECCIÓN (347-A + 347-B + 349 + 350 + 370)",
				"field373": "Derrame estatal (art. 87 - A)",
				"field378": "RECAUDACIÓN TOTAL (351 +373)",
				"deducoes": "Deducciones",
				"field353": "Doble imposición legal internacional (DTJI - art. 91)",
				"field375": "Doble imposición económica internacional (art. 91-A)",
				"field355": "Beneficios fiscales",
				"field470": "Adicional al Impuesto Predial Municipal (artículo 135.º-J del CIMI)",
				"field356": "Pago especial a cuenta (art. 93)",
				"field357": "TOTAL DEDUCCIONES (353 + 375 + 355 + 356 + 470) <= 378",
				"field358": "TOTAL IRD PAGADO (378 - 357) >= 0",
				"field371": "Resultado de la liquidación (art. 92)",
				"field359": "Retenciones en fuente",
				"field360": "Pagos a cuenta (art. 105) y Pago a cuenta autónomo (Ley n. 7-A/2016, de 30 de marzo, art. 136, n. 2)",
				"field374": "Pagos adicionales a cuenta (art. 105 - A)",
				"field361": "IRC A PAGAR (358 + 371 - 359 - 360 - 374) > 0",
				"field362": "IRC A RECUPERAR (358 + 371 - 359 - 360 - 374) < 0",
				"field363": "IRC de periodos anteriores",
				"field372": "Reemplazo de beneficios fiscales",
				"field364": "Derrame municipal",
				"field379": "Doble imposición legal internacional (art. 91) - Países con CDT y cuando DTJI > 378",
				"field365": "Fiscalidad autonómica",
				"field366": "Interés compensatorio",
				"field369": "Intereses de demora",
				"field367": "TOTAL A PAGAR [361 o (-362) + 363 + 372 + 364 - 379 + 365 + 366 + 369] > 0",
				"field368": "TOTAL A RECUPERAR [(-362) + 363 + 372 + 364 - 379 + 365 + 366 + 369] < 0",
				"campo10A": {
					"title": "Interés compensatorio",
					"desc": "Desglose del valor indicado en el campo 366 de la tabla 10:",
					"field366A": "Interés compensatorio declarado por retraso en la presentación de la declaración",
					"field366B": "Intereses compensatorios declarados por otros motivos"
				},
				"campo10b": {
					"title": "Traslado de residencia/cese de actividad de establecimiento permanente/asignación de bienes (artículos 83, 84 y 54-A, núm. 11)",
					"data4": "Fecha de ocurrencia",
					"desc": "Forma de pago del impuesto correspondiente (art. 83, n. 2)",
					"radio1": "inmediato [al.",
					"radio2": "diferido [al.",
					"radio3": "fraccionado [al.",
					"valpagdiffracio": "Monto del pago diferido o fraccionado",
					"field377A": "IRC + Recargo estatal",
					"field377B": "Derrame municipal"
				},
				"field377": "Total de pagos diferidos o fraccionados (377-A + 377-B)",
				"field430": "TOTAL A PAGAR (367 -377) > 0",
				"field431": "TOTAL A RECUPERAR [367 o (- 368) - 377] < 0",
				"modal": {
					"newtaxa": {
						"title": "Cambiar la tasa de vertido",
						"field": "La tasa se vierte",
						"tooltip": "La tasa se vierte"
					}
				},
				"consideraPrejuizoFiscal": "Pérdida de impuestos",
				"tributAutonomas": "Fiscalidad autonómica"
			},
			"quadro11": {
				"title": "Otra información",
				"field410": "Ingresos totales del periodo",
				"field411": "Volumen de negocios del período (a desglosar en la tabla 11-B, si corresponde)",
				"field416": "Diferencia positiva entre el valor considerado para los efectos de la liquidación del IMT y el valor contenido en el contrato, en los casos en que se utilizó el procedimiento previsto en el artículo 139.",
				"data418": "Fecha en que se produjo la transmisión de las acciones sociales (artículo 51, párrafo 9 y artículo 88, párrafo 11)",
				"check423": "En el caso de una microentidad, indique si, como alternativa a las normas contables para microentidades (NC-ME), opta por aplicar las normas contables y de información financiera para pequeñas entidades (NCRF-PE) o las normas contables y financieras normas de presentación de informes (NCRF) [art. 9.º-D del DL núm. 158/2009, de 13 de julio]",
				"check429": "¿Se produjo una fusión con efecto retroactivo durante el período impositivo (nº 11 del artículo 8) del que usted es sociedad beneficiaria?",
				"check455": "¿Se produjo alguna fusión durante el año 2020 al amparo del régimen especial previsto en los artículos 73 y siguientes del Código IRC?",
				"sim": "¿Sí?"
			},
			"quadro11A": {
				"title": "Activos por impuestos diferidos (AID) - Ley 61/2014, de 26 de agosto",
				"AID": "Desglose de las AID incluidas en los estados financieros a que se refiere el Mod 22.",
				"field460": "AID de pérdidas por deterioro de créditos amparados por REAID",
				"field461": "AID de beneficios post-empleo o de largo plazo de los empleados cubiertos por REAID",
				"field462": "Otras SIDA",
				"field463": "Equidad",
				"field464": "Crédito fiscal",
				"data465": "Fecha de entrada en liquidación",
				"infoadd": "Información adicional"
			},
			"quadro11B": {
				"title": "Desglose de la facturación anual del periodo por distritos (Península, Azores y Madeira)",
				"desc": "¿Tiene la empresa sucursales, delegaciones, agencias, oficinas, instalaciones o cualquier forma de representación permanente sin personalidad jurídica propia en más de un distrito?",
				"radio6": "Sí",
				"radio7": "No",
				"desc2": "Si su respuesta es afirmativa, indique qué circunscripciones:",
				"radio8": "Continente",
				"radio9": "Madera",
				"radio10": "Azores",
				"field1": "Volumen de negocios global no exento",
				"field2": "Volumen de negocios, no exento, atribuible a instalaciones ubicadas en la Región Autónoma de Madeira (RAM)",
				"field3": "Facturación, no exenta, atribuible a instalaciones situadas en la Región Autónoma de las Azores (RAA)",
				"field4": "Relación 1 (RAM) = (campo 2: campo 1)",
				"field5": "Relación 2 (RAA) = (campo 3 : campo 1)",
				"field22": "Proporción 3 (CONTINENTE) = 1 - (proporción 1 + proporción 2)"
			},
			"quadro12": {
				"title": "Retenciones en fuente",
				"table": {
					"fields": {
						"nif": "Número de identificación fiscal (TIN)",
						"retfonte": "Retención en fuente"
					}
				}
			},
			"quadro13": {
				"title": "Fiscalidad autonómica",
				"desc": "Descripción",
				"basetrib": "Base imponible",
				"field414": "Gastos de representación (art. 88, núm. 7)",
				"field415": "Gastos ocasionados o soportados con dietas y compensaciones por desplazamiento en el vehículo propio (art. 88, n. 9)",
				"field417": "Utilidades distribuidas por entidades sujetas al IRC a contribuyentes que se benefician de la exención total o parcial (artículo 88, párrafo 11)",
				"field420": "Tasas de vehículos (antigua redacción del artículo 88, núm. 3) (régimen vigente hasta el 31/12/2013)",
				"field421": "Tasas de vehículos (ex-art. 88, n. 4) (régimen vigente hasta el 31/12/2013)",
				"field422": "Indemnización por cese de funciones como directivo, administrador o directivo [art. 88, núm. 13, al.",
				"field424": "Gastos o cargas relativas a bonificaciones y otras remuneraciones variables pagadas a directivos, administradores o directivos [art. 88, núm. 13, al.",
				"field425": "Gastos no deducibles en los términos del art.",
				"field426": "Tasas para vehículos ligeros de pasajeros y mercancías - Si CA < 27.500,00 € [art. 88, núm.",
				"field427": "Tasas para vehículos ligeros de pasajeros y mercancías - Si CA >= 27.500,00 € y < 35.000,00 € [art.",
				"field428": "Tasas para vehículos ligeros de pasajeros y mercancías - Si CA >= 35.000,00 € [art. 88, núm.",
				"field432": "Tarifas para turismos híbridos enchufables, cuya batería pueda cargarse mediante conexión a la red eléctrica y que tengan una autonomía mínima, en modo eléctrico, de 50 km y unas emisiones oficiales inferiores a 50 gCO(índice 2)/km, y vehículos ligeros de pasajeros propulsados ​​por gas natural vehicular (GNC) - Si CA < 27.500,00 € [art.",
				"field433": "Tarifas para turismos híbridos enchufables, cuya batería pueda cargarse mediante conexión a la red eléctrica y que tengan una autonomía mínima, en modo eléctrico, de 50 km y unas emisiones oficiales inferiores a 50 gCO(índice 2)/km, y Vehículos ligeros de pasajeros propulsados ​​por gas natural vehicular (GNC) - Si CA >= 27.500,00 € y < 35.000,00 €",
				"field434": "Tarifas para turismos híbridos enchufables, cuya batería pueda cargarse mediante conexión a la red eléctrica y que tengan una autonomía mínima, en modo eléctrico, de 50 km y unas emisiones oficiales inferiores a 50 gCO(índice 2)/km, y vehículos ligeros de pasajeros propulsados ​​por gas natural vehicular (GNC) - Si CA >= 35.000,00 € [art.",
				"field435": "Tasas para vehículos ligeros de pasajeros propulsados ​​por GNC - Si CA < 27.500,00 € [art. 88, núm.",
				"field436": "Tasas para vehículos ligeros de pasajeros propulsados ​​por GNC - Si CA >= 27.500,00 € y < 35.000,00 € [art.",
				"field437": "Tasas para vehículos ligeros de pasajeros propulsados ​​por GNC - Si CA >= 35.000,00 € [art. 88, núm.",
				"field438": "Gastos indocumentados [art.",
				"field439": "Cantidades pagadas o adeudadas a entidades no residentes sujetas a un régimen fiscal privilegiado [art. 22, núm. 8 de la EBF)",
				"field456": "Cargos relativos a vehículos propulsados ​​exclusivamente por electricidad con coste de adquisición superior a 62.500,00 € [art. 88, núm.",
				"campoIniciouAtividade": "¿Inició la actividad en el período impositivo anterior a aquel al que se refiere la declaración?",
				"field13e3": "Si inició su actividad en alguno de los períodos impositivos de 2018, 2019, 2020 o 2021, indique la fecha en que inició su actividad"
			},
			"quadro13A": {
				"title": "Tributación autónoma - Zona Franca de Madeira (art. 36.º-A, n.º 14 de la EBF)",
				"desc": "Descripción",
				"basetrib": "Base imponible",
				"field440": "Gastos de representación (art. 88, núm. 7)",
				"field441": "Gastos ocasionados o soportados con dietas y compensaciones por desplazamiento en el vehículo propio (art. 88, n. 9)",
				"field442": "Utilidades distribuidas por entidades sujetas al IRC a contribuyentes que se benefician de la exención total o parcial (artículo 88, párrafo 11)",
				"field443": "Indemnización por cese de funciones como directivo, administrador o directivo [art. 88, núm. 13, al.",
				"field444": "Gastos o cargas relativas a bonificaciones y otras remuneraciones variables pagadas a directivos, administradores o directivos [art. 88, núm. 13, al.",
				"field445": "Gastos no deducibles en los términos del art.",
				"field446": "Tasas para vehículos ligeros de pasajeros y mercancías - Si CA < 27.500,00 € [art. 88, núm.",
				"field447": "Tasas para vehículos ligeros de pasajeros y mercancías - Si CA >= 27.500,00 € y < 35.000,00 € [art.",
				"field448": "Tasas para vehículos ligeros de pasajeros y mercancías - Si CA >= 35.000,00 € [art. 88, núm.",
				"field449": "Tarifas para turismos híbridos enchufables, cuya batería pueda cargarse mediante conexión a la red eléctrica y que tengan una autonomía mínima, en modo eléctrico, de 50 km y unas emisiones oficiales inferiores a 50 gCO(índice 2)/km, y vehículos ligeros de pasajeros propulsados ​​por gas natural vehicular (GNC) - Si CA < 27.500,00 € [art.",
				"field450": "Tarifas para turismos híbridos enchufables, cuya batería pueda cargarse mediante conexión a la red eléctrica y que tengan una autonomía mínima, en modo eléctrico, de 50 km y unas emisiones oficiales inferiores a 50 gCO(índice 2)/km, y Vehículos ligeros de pasajeros propulsados ​​por gas natural vehicular (GNC) - Si CA >= 27.500,00 € y < 35.000,00 €",
				"field451": "Tarifas para turismos híbridos enchufables, cuya batería pueda cargarse mediante conexión a la red eléctrica y que tengan una autonomía mínima, en modo eléctrico, de 50 km y unas emisiones oficiales inferiores a 50 gCO(índice 2)/km, y vehículos ligeros de pasajeros propulsados ​​por gas natural vehicular (GNC) - Si CA >= 35.000,00 € [art.",
				"field452": "Tasas para vehículos ligeros de pasajeros propulsados ​​por GNC - Si CA < 27.500,00 € [art. 88, núm.",
				"field453": "Tasas para vehículos ligeros de pasajeros propulsados ​​por GNC - Si CA >= 27.500,00 € y < 35.000,00 € [art.",
				"field454": "Tasas para vehículos ligeros de pasajeros propulsados ​​por GNC - Si CA >= 35.000,00 € [art. 88, núm.",
				"field457": "Cargos relativos a vehículos propulsados ​​exclusivamente por electricidad con coste de adquisición superior a 62.500,00 € [art. 88, núm."
			},
			"quadro14": {
				"title": "Crédito fiscal por doble imposición legal internacional (CIDTJI)",
				"table": {
					"fields": {
						"codpais": "Código del país",
						"tiprend": "Tipo de ingreso",
						"periodo": "periodo CIDTJI",
						"salcaducado": "Saldo vencido",
						"saldnaored": "Saldo no deducido",
						"apurapreio": "Cálculo en el periodo",
						"dedperio": "Deducción realizada en el período",
						"saldotrans": "Saldo arrastrado",
						"imppagestr": "Impuesto pagado en el extranjero [art. 91, núm. 1, al.",
						"facimprendest": "Fracción del impuesto relativa a las rentas obtenidas en el extranjero [art. 91, núm. 1, al.",
						"credpreio": "Crédito fiscal del período",
						"paisComCDT": "País con CDT"
					}
				},
				"totCIDTJIcomCDT": "TOTAL CIDTJI con CDT",
				"totCIDTJIsemCDT": "TOTAL CIDTJI sin CDT",
				"totCIDTJIJI": "TOTAL CIDTJI"
			}
		},
		"anexoA": {
			"quadro3": {
				"title": "Información relevante",
				"dadosgerais": "Datos generales",
				"dadosesp": "Datos específicos - Centros de Producción de Electricidad/Mina",
				"field1": "Utilidad imponible total (campo 302 + 313 de la Q. 09)",
				"field2": "Beneficio imponible en la Zona Franca de Madeira (campo 313 de la P. 09) (art. 36-A de la EBF)",
				"field10": "Suma algebraica de la base imponible del régimen especial y la base imponible del régimen general (campo 300 + campo 302)",
				"field3": "Masa salarial total",
				"check4": "¿Es este el primer año de aplicación del régimen?",
				"field5": "Área total de instalación o exploración (ha2)",
				"field6": "Potencia total instalada (MW)",
				"field7": "Electricidad total producida (GWh)",
				"field8": "Valor total de la producción en bocamina (en euros)",
				"field9": "Masa salarial total + prestación de servicios"
			},
			"quadro4": {
				"title": "Cálculo del recargo municipal",
				"massasaltot": "Masa salarial total",
				"somacontr": "Suma de control",
				"massasalprestmun": "Masa salarial + prestación de servicios municipales",
				"areintexplomun": "Zona de instalación.",
				"poteinstmun": "Potencia instalada en el municipio",
				"toteletrprodmun": "Totalmente eléctrico.",
				"prodbocaminamun": "Producción en boca de mina en el municipio",
				"sect04-A": {
					"title": "Criterios generales",
					"table": {
						"fields": {
							"field1": "Código de distrito/municipio (1)",
							"field2": "Tasa de derrame (2)",
							"field3": "Masa salarial municipal (3)",
							"field4": "Ratio de distribución (4) = (3) / (Q.03, C3)",
							"field5": "DERRAME CALCULADO (5) = (Q.03, C1 x (2) x (4)) o (Q.03, C10) x (2) x (4)"
						}
					}
				},
				"sect04-B": {
					"title": "Criterios generales - Zona Franca de Madeira (Art. 36.º -A, nº 12 de la EBF)",
					"table": {
						"fields": {
							"field1": "Código de distrito/municipio (1)",
							"field2": "Tasa de derrame (2)",
							"field3": "Masa salarial municipal en ZFM (3)",
							"field4": "Ratio de distribución (4) = (3) / (Q.03, C3)",
							"field5": "Derrame calculado (5) = [(Q.03, C2 x (2) x (4))] x 0,2"
						}
					}
				},
				"sect04-C": {
					"title": "Criterio específico - centros de producción eléctrica",
					"table": {
						"fields": {
							"field10": "Indique si este es el 1er año de aplicación del criterio (10)",
							"field1": "Código de distrito/municipio (1)",
							"field2": "Tasa de derrame (2)",
							"field3": "Masa salarial + prestación de servicios municipales (MSPSMunic) (3)",
							"field4": "Zona de instalación.",
							"field5": "Potencia instalada en el municipio (PIMunic) (5)",
							"field6": "Electricidad total.",
							"field7": "Ratio de municipios (7)",
							"field8": "Derrame calculado (8) = (Q03 C1) x (2) x (7)"
						}
					}
				},
				"sect04-D": {
					"title": "Criterios específicos - Minas",
					"table": {
						"fields": {
							"field9": "Indique si este es el 1er año de aplicación del criterio (9)",
							"field1": "Código de distrito/municipio (1)",
							"field2": "Tasa de derrame (2)",
							"field3": "Masa salarial + prestación de servicios municipales (MSPSMunic) (3)",
							"field4": "Zona de instalación.",
							"field5": "Producción en bocamina del municipio (PBMunic) (5)",
							"field6": "Ratio de municipios (6)",
							"field7": "Derrame calculado (7) = (Q03 C1) x (2) x (6)"
						}
					}
				}
			}
		},
		"anexoC": {
			"quadro04": {
				"title": "Régimen general y régimen simplificado con aplicación de tipos autonómicos",
				"varglob": "Valores globales",
				"field6": "MATERIAL COLECCIONABLE (campos 311 + 336, excepto campo 300, en la tabla 09 de la declaración o campo 42 del Anexo E)",
				"field6A": "RÉGIMEN ESPECIAL DE CANTIDAD COBRABLE (Decreto-ley n° 92/2018, de 13/11) - (campo 300 de la tabla 09 de la declaración)",
				"field7A": "COBRO: Si es PYME o PyME - hasta 50.000,00 € (importe hasta 50.000,00 € del campo 6 x 17%)",
				"field7B": "RECAUDACIÓN: Si PYME o PyME - superior a 50.000,00 € [(campo 6 - 50.000,00 €) x 21%] o si Gran Empresa (campo 6 x 21%)",
				"field7C": "RECAUDACIÓN del RÉGIMEN ESPECIAL (Decreto-ley nº 92/2018, de 13/11) - (campo 6-A x 21%)",
				"ram": "RAM",
				"field8A": "RECOGIDA RAM: Si PYME o SMC - hasta 50.000,00 € (campo 4 de la tabla 11-B de la declaración x importe hasta 50.000,00 € x 11,9%) - se transportará al campo 370 de la declaración",
				"field8B": "RECAUDACIÓN RAM: Si Pyme o SMC - superior a 50.000,00 € [campo 4 de la tabla 11-B de la declaración x (campo 6 - 50.000,00 €) x 14,7%] o si Gran Empresa [campo 4 de la tabla 11-B de la declaración x campo 6 x 14,7%] - se transferirá al campo 370 de la declaración",
				"field8C": "RECOGIDA del RÉGIMEN ESPECIAL (Decreto-ley nº 92/2018, de 13/11) en RAM - (campo 4 de la tabla 11-B de la declaración x campo 6-A x 14,7%) - para ser transportado al campo 370 de la declaración",
				"raa": "RAA",
				"field9A": "RECOGIDA RAA: Si PYME o SMC - hasta 50.000,00 € [campo 5 de la tabla 11-B de la declaración x (importe hasta 50.000,00 € del campo 6) x 11,9%] - se transportará al campo 350 de la declaración",
				"field9B": "RECAUDACIÓN RAA: Si Pyme o SMC - superior a 50.000,00 € [campo 5 de la tabla 11-B de la declaración x (campo 6 - 50.000,00 €) x 14,7%] o si Gran Empresa (campo 5 de la tabla 11-B de la declaración x campo 6 x 14,7%) - se transferirá al campo 350 de la declaración",
				"field9C": "RECOGIDA del RÉGIMEN ESPECIAL (Decreto-ley nº 92/2018, de 13/11) en el RAA - (campo 5 de la tabla 11-B de la declaración x campo 6-A x 14,7%) - para ser transportado al campo 350 de la declaración",
				"continente": "Continente",
				"field10A": "RECOGIDA PENINSULAR: Si PYME o SMC - hasta 50.000,00 € (campo 22 de la tabla 11-B de la declaración x campo 7-A) - a transportar al campo 347-A de la declaración",
				"field10B": "RECAUDACIÓN PENINSULAR: Si PYME o PyME - superior a 50.000,00 € (campo 22 de la tabla 11-B de la declaración x campo 7-B) o si Gran empresa (campo 22 de la tabla 11-B de la declaración x campo 7-B) - para ser transportado al campo 347-B de la declaración",
				"field10C": "RECOGIDA del RÉGIMEN ESPECIAL (Decreto-ley n° 92/2018, de 13/11) sobre el CONTINENTE (campo 22 de la tabla 11-B de la declaración x campo 7-C) - para ser transportado al campo 347-B de la declaracion"
			},
			"quadro05": {
				"title": "Antiguo régimen simplificado (ex artículo 58 de la CIRC), regímenes de reducción de tarifas, entidades que no realicen como actividad principal actividades comerciales, industriales o agrícolas",
				"field11": "Material gravable (campos 311-399 o campo 322 o campo 409 de la tabla 09 de la declaración)",
				"coleta": "Cobro: (campo 11 x tarifa)",
				"field13": "RECOGIDA DE RAM: (campo 4 de la tabla 11-B de la declaración x campo 12) - para ser transportado al campo 370 de la declaración",
				"field14": "RECOGIDA RAA: (campo 5 de la tabla 11-B de la declaración x campo 12 x 0,7) - para ser transportado al campo 350 de la declaración",
				"field15": "RECOGIDA del CONTINENTE: (campo 22 de la tabla 11-B de la declaración x campo 12) - para ser transportado al campo 349 de la declaración"
			},
			"quadro06": {
				"title": "Régimen general sin aplicación de tasas autonómicas",
				"varglob": "Valores globales",
				"field16": "Material gravable (campo 311 de la tabla 09 de la declaración)",
				"field17A": "COBRO: Si es PYME o PyME - hasta 50.000,00 € (importe hasta 50.000,00 € del campo 16 x 17%)",
				"field17B": "RECAUDACIÓN: Si PYME o PyME - superior a 50.000,00 € [(campo 16 - 50.000,00 €) x 21%] o si Gran Empresa (campo 16 x 21%)",
				"ram": "RAM",
				"field18A": "RECAUDACIÓN RAM: Si PYME o SMC - hasta 50.000,00 € (campo 4 de la tabla 11-B de la declaración x campo 17-A) - a transferir al campo 370 de la declaración",
				"field18B": "RECAUDACIÓN RAM: Si Pyme o SMC - superior a 50.000,00 € (campo 4 de la tabla 11-B de la declaración x campo 17-B) o si Gran empresa (campo 4 de la tabla 11-B de la declaración x campo 17-B) - para ser transferido al campo 370 de la declaración",
				"raa": "RAA",
				"field19A": "COBRO RAA: Si PYME o SMC - hasta 50.000,00 € (campo 5 de la tabla 11-B de la declaración x campo 17-A) a transferir al campo 350 de la declaración",
				"field19B": "RECAUDACIÓN RAA: Si Pyme o SMC - superior a 50.000,00 € (campo 5 de la tabla 11-B de la declaración x campo 17-B) o si Gran empresa (campo 5 de la tabla 11-B de la declaración x campo 17-B) - para ser transferido al campo 350 de la declaración",
				"continente": "Continente",
				"field20A": "RECOGIDA PENINSULAR: Si PYME o SMC - hasta 50.000,00 € (campo 22 de la tabla 11-B de la declaración x campo 17-A) - a transportar al campo 347-A de la declaración",
				"field20B": "RECAUDACIÓN PENINSULAR: Si PYME o PyME - superior a 50.000,00 € (campo 22 de la tabla 11-B de la declaración x campo 17-B) o si Gran Empresa (campo 22 de la tabla 11-B de la declaración x campo 17-B) - para ser transportado al campo 347-B de la declaración"
			},
			"quadro06A": {
				"title": "Régimen de grupos de empresas con aplicación de tarifas autonómicas",
				"varglob": "Valores globales",
				"field22": "Material gravable (Campo 311 de la Tabla 09 de la declaración)",
				"field22A": "COBRO: Si es PYME o PyME - hasta 50.000,00 € (importe hasta 50.000,00 € del campo 22 x 17%)",
				"field22B": "RECAUDACIÓN: Si PYME o PyME - superior a 50.000,00 € [(campo 22 - 50.000,00 €) x 21%] o si Gran empresa (campo 22 x 21%)",
				"ram": "RAM",
				"field23A": "RECOGIDA DE RAM: Si es PYME o SMC - hasta 50.000,00 € (campo 4 de la tabla 11-B de la declaración x importe hasta 50.000,00 del campo 22 x 11,9%) - se transportará al campo 370 de la declaración",
				"field23B": "RECAUDACIÓN RAM: Si PYME o SMC - superior a 50.000,00 € (campo 4 de la tabla 11-B de la declaración x (campo 22 - 50.000,00) x 14,7%) o si Gran Empresa (campo 4 de la tabla 11-B de la declaración x campo 22 x 14,7%) - se transferirá al campo 370 de la declaración",
				"raa": "RAA",
				"field24A": "RECAUDACIÓN RAA: Si PYME o SMC - hasta 50.000,00 € (campo 5 de la tabla 11-B de la declaración x importe hasta 50.000,00 del campo 22 x 11,9%) a transportar al campo 350 de la declaración",
				"field24B": "RECAUDACIÓN RAA: Si Pyme o SMC - superior a 50.000,00 € (campo 5 de la tabla 11-B de la declaración x (campo 22 - 50.000,00) x 14,7%) o si Gran Empresa (campo 5 de la tabla 11-B de la declaración x campo 22 x 14,7%) - se transferirá al campo 350 de la declaración"
			},
			"quadro07": {
				"title": "Beneficio de reducción de impuestos aplicable a los territorios interiores en el ámbito de R. A. Madeira",
				"varglob": "Valores globales",
				"ram": "RAM",
				"raa": "RAA",
				"continente": "Continente",
				"field1": "MATERIAL COLECCIONABLE (Campo 42 del Anexo E o Campo 322 de la tabla 09)",
				"field2A": "RECAUDACIÓN: PYME o PyME - hasta 50.000,00 € (importe hasta 50.000,00 € del campo 1 x 17%)",
				"field2B": "RECAUDACIÓN: Pyme o PyME - más de 50.000,00 € [(campo 1 - 50.000,00 €) x 21%]",
				"field3A": "RECAUDACIÓN RAM: PYME o SMC - hasta 50.000,00 € (campo 4 de la tabla 11-B de la declaración x importe hasta 50.000,00 € x 8,75%) - a transferir al campo 370 de la declaración",
				"field3B": "RECAUDACIÓN RAM: PYME o SMC - superior a 50.000,00 € [campo 4 de la tabla 11-B de la declaración x (campo 1 - 50.000,00 €) x 14,7%] - a transferir al campo 370 de la declaración",
				"field4A": "RECAUDACIÓN RAA: PYME - hasta 50.000,00 € [campo 5 de la tabla 11-B de la declaración x (importe hasta 50.000,00 € del campo 1) x 8,75%] - a transferir al campo 350 de la declaración",
				"field4B": "RECAUDACIÓN RAA: PYME - superior a 50.000,00 € [campo 5 de la tabla 11-B de la declaración x (campo 1 - 50.000,00 €) x 14,7%] - se transferirá al campo 350 de la declaración",
				"field5A": "RECOGIDA PENINSULAR: PYME o SMC - hasta 50.000,00 € (campo 22 de la tabla 11-B de la declaración x campo 2-A) - a transportar al campo 349 de la declaración",
				"field5B": "RECOGIDA PENINSULAR: PYME o SMC - superior a 50.000,00 € (campo 22 de la tabla 11-B de la declaración x campo 2-B) - a transportar al campo 347-B de la declaración"
			}
		},
		"anexoD": {
			"quadro03": {
				"title": "Ingresos exentos",
				"desc31": "Exención definitiva",
				"rendliqu": "Lngresos netos",
				"field301": "Personas jurídicas de utilidad pública y solidaridad social (artículo 10 de la CIRC)",
				"field302": "Actividades culturales, recreativas y deportivas (art. 11 del CIRC y art. 54, n. 1 de la EBF)",
				"field303": "Cooperativas (art. 66-A de la EBF)",
				"field313": "Contratistas o licitadores, en relación con los beneficios derivados de obras y trabajos en infraestructuras comunes de la OTAN (art. 14, núm. 2 de la CIRC)",
				"field314": "Fondos de pensiones y asimilados (art. 16, n. 1 del EBF) y otros fondos definitivamente exentos",
				"field316": "Entidad central de almacenamiento: resultado neto del ejercicio registrado en la gestión de reservas estratégicas de petróleo (art. 25.º-A del Decreto-Ley núm. 165/2013, de 16 de diciembre)",
				"field304": "Otras exenciones definitivas",
				"desc31A": "Campo 314 - Fondos de pensiones y asimilados (art. 16, n. 1 de la EBF) y otros fondos definitivamente exentos",
				"desc31B": "Campo 304 - Otras exenciones definitivas",
				"desc32": "Exención temporal",
				"field305": "Zona Franca de Madeira e Isla Santa María (art. 33, n. 1 de la EBF)",
				"field306": "Entidades gestoras de denominaciones de origen e indicaciones geográficas (art. 52 de la EBF)",
				"field307": "Entidades que gestionan sistemas integrados de gestión de flujos de residuos específicos (art. 53 de la EBF)",
				"field308": "Asociaciones públicas, confederaciones, sindicatos y asociaciones de empresarios y asociaciones de padres (art. 55 de la EBF)",
				"field309": "Sociedades o asociaciones científicas internacionales (ex-art. 57 de la EBF)",
				"field310": "Tierras baldías y comunidades locales (art. 59 de la EBF)",
				"field311": "Medidas de apoyo al transporte por carretera de viajeros y mercancías [plusvalías exentas (art. 70 de la EBF)]",
				"field315": "Fondos de ahorro compartido (art. 26 del EBF) y otros fondos temporalmente exentos",
				"field317": "Rentas obtenidas por entidades de gestión forestal (EGF) y unidades de gestión forestal (UGF) (art. 59.º-G de la EBF)",
				"field312": "Otras exenciones temporales",
				"desc32A": "Campo 315 - Fondos de ahorro en acciones (art. 26 de la EBF) y otros fondos temporalmente exentos",
				"desc32B": "Campo 312 - Otras exenciones temporales",
				"table": {
					"fields": {
						"codben": "código de beneficio",
						"montante": "Cantidad"
					}
				}
			},
			"quadro04": {
				"title": "Deducciones de renta (a deducir en el campo 774 de la tabla 07 de la declaración)",
				"normalegal": "Regulaciones legales",
				"dedefetuada": "Deducción hecha",
				"field401": "Incremento a la creación de empleo (art. 19 del EBF)",
				"field402": "Fondos de inversión [art. 22, núm. 14, al.",
				"field403": "Eliminación de la doble imposición económica de los beneficios distribuidos por empresas residentes en PALOP y Timor Oriental (ex art. 42 del EBF)",
				"field404": "Aumentos aplicados a los beneficios fiscales para las zonas interiores [ex-art. 43, núm. 1, al.",
				"field405": "Compañías navieras nacionales de marina mercante (art. 51 de la EBF)",
				"field406": "Aumentos aplicados a las donaciones previstas en los artículos 62, 62-A y 62-B de la EBF",
				"field426": "Incrementos aplicados a las donaciones previstas en el artículo 62 de la EBF - Mecenazgo social, deportivo y medioambiental",
				"field427": "Aumentos aplicados a las donaciones previstas en el artículo 62-A de la EBF - Mecenazgo científico",
				"field428": "Aumentos aplicados a las donaciones previstas en el artículo 62-B de la EBF - Mecenazgo Cultural",
				"field432": "Incrementos aplicados a las donaciones relativas al mecenazgo cultural extraordinario para los años 2021 y 2022 (art. 397.º de la Ley n.75-B/2020, de 31 de diciembre y art. 27 de junio)",
				"field407": "Incremento de las aportaciones sociales (artículo 44 de la CIRC)",
				"field408": "Incremento aplicado a los gastos ocasionados por la adquisición, en territorio portugués, de carburantes para el repostaje de vehículos (art. 70, n. 4 de la EBF)",
				"field409": "Retribución convencional del capital social (art. 136 de la Ley n. 55-A/2010, de 31/12 y art. 41.-A de la EBF)",
				"field412": "Aumento de gastos relacionados con guarderías, lactancias y jardines de infancia (art. 43, n. 9 de la CIRC)",
				"field413": "Aumento de los gastos incurridos por las cooperativas en aplicación de la reserva para educación y formación (art. 66-A, n. 7 de la EBF)",
				"field414": "Utilidades puestas a disposición y rendimientos por intereses obtenidos por socios o accionistas de empresas autorizadas en la ZFM (artículo 36-A, párrafos 10 y 11, de la EBF)",
				"field415": "Incremento de los gastos incurridos con la adquisición de energía eléctrica, GNC para alimentación de vehículos (art. 59.º-A de la EBF)",
				"field416": "Incremento de gastos con sistemas de car-sharing y bike-sharing (art. 59.º-B del EBF)",
				"field417": "Incremento de gastos con flotas de bicicletas (art. 59.º-C de la EBF)",
				"field418": "Incremento de los gastos a cargo de los propietarios y productores forestales adheridos a la zona de intervención forestal con aportaciones financieras destinadas al fondo común y cargas de defensa forestal (art. 59.º-D, núm. 12 de la EBF)",
				"field419": "Aumento de gastos con certificación de finca orgánica (art. 59-E de la EBF)",
				"field420": "Aumento de gastos y pérdidas en el ámbito de las asociaciones de bonos de impacto social (art. 19-A de la EBF)",
				"field421": "Aumentos de gastos y pérdidas relacionados con obras de conservación y mantenimiento de edificios o partes de edificios destinados a comercios con historia reconocida por el municipio (art. 59.º-I de la EBF)",
				"field422": "Incremento del incremento de las depreciaciones y amortizaciones, previsto en el artículo 8, núm. 3 del Decreto-Ley núm. 66/2016, de 3 de noviembre.",
				"field423": "Incremento de la amortización fiscalmente aceptada de los elementos del inmovilizado material correspondientes a embarcaciones electrosolares o exclusivamente eléctricas (art. 59.º-J de la EBF)",
				"field424": "Rentas y ganancias que no sean plusvalías tributarias a que se refieren los apartados 1 y 2 del artículo 268 del Código de Insolvencia y Recuperación de Empresas (CIRE), aprobado por el Decreto-Ley n° 53/2004, de 18 de marzo.",
				"field425": "Rentas de la propiedad resultantes de contratos de alquiler o subarrendamiento de viviendas incluidas en el Programa de Alquiler Económico (art. 20, n. 1 del Decreto-Ley n. 68/2019, de 22 de mayo)",
				"field429": "Rentas de la propiedad obtenidas en el marco de programas municipales que ofrecen alquileres de viviendas asequibles (art. 71, n. 27 de la EBF)",
				"field430": "Incrementos de los gastos ocasionados por la adquisición de pases sociales en beneficio del personal (art. 43, n. 15 de la CIRC)",
				"field431": "Gastos con la adquisición de bienes y servicios directamente necesarios para la ejecución de desarrollos que aseguren la presentación previa del expediente SAFT-PT relativo a la contabilidad en el ámbito de las IES y el código único de documento (ATCUD), en los términos establecidos en apartado 1 del artículo 316 de la Ley 12/2022, de 27 de junio.",
				"field433": "Incremento de los gastos subvencionables, incurridos en los periodos de 2021 y 2022, en el ámbito de la participación conjunta en proyectos de promoción exterior (art. 400, núm. 1 de la Ley núm. 75.º-B/2020, de 31 de diciembre)",
				"field434": "Los aumentos de los gastos relacionados con el consumo de electricidad y gas natural, en la parte en que excedan a los del período anterior, deducidos del posible apoyo en los términos del Decreto-Ley n° 30-B/2022, de 18 de abril (art. 231). 24-D/2022, de 30 de diciembre)",
				"field435": "Aumentos de gastos relacionados con el régimen extraordinario de apoyo a las cargas ocasionadas en la producción agrícola (art. 232 de la Ley núm. 24-D/2022, de 30 de diciembre)",
				"field436": "Incrementos de cargas correspondientes al incremento determinado por un instrumento dinámico de regulación colectiva del trabajo relativo a los trabajadores con contrato de trabajo por tiempo indefinido (art. 19.º-B de la EBF)",
				"field437": "Régimen tributario para fomentar la capitalización de empresas [(ICE) art. 43.º-D de la EBF]",
				"field438": "Incremento de las cargas correspondientes a la creación neta de empleo (art. 41.º-B, núm. 6 de la EBF)",
				"field439": "Diferencial del coeficiente de apoyo extraordinario al alquiler (art. 3, núm. 3 de la Ley núm. 19/2022, de 21 de octubre)",
				"field410": "Otras deducciones de ingresos",
				"field411": "TOTAL DEDUCCIONES (401 + ... + 409 + 412 + ... + 435 + ... + 439 + 410)",
				"desc4A": "Campo 410 - Otras deducciones sobre ingresos",
				"desc4B": "Información adicional (art. 268 de CIRE)",
				"radio1-2": "¿Ocurrió durante el período impositivo alguno de los hechos previstos en el artículo 268 de la CIRE?",
				"casoafirma": "En caso afirmativo, indique:",
				"field3": "Impuesto sobre las ganancias de capital exentas en los términos del artículo 268, núm.",
				"field4": "Cambios patrimoniales positivos exentos que no se reflejan en la utilidad neta del período (art. 268, párrafos 1 y 2)",
				"field5": "Gastos o pérdidas deducibles determinados por el acreedor como consecuencia de la reducción de créditos (art. 268, n. 3)",
				"desc41": "Transmisión de beneficios fiscales de la sociedad fusionada o escindida o de la sociedad aportante (artículo 75-A de la CIRC)",
				"table": {
					"fields": {
						"codben": "código de beneficio",
						"montante": "Cantidad",
						"nifsoc": "NIF soc."
					}
				}
			},
			"quadro05": {
				"title": "Soc. Gestoras de Participación Social (SGPS), soc.",
				"field501": "Ganancias de capital no gravadas (ex-art. 32.º, núm. 2 y ex-art. 32.º-A, núm. 1 de la EBF)",
				"field502": "Pérdidas fiscales no deducibles (ex-art. 32.º, n.º 2 y ex-art. 32.º-A, n.º 1 de la EBF)"
			},
			"quadro06": {
				"title": "Entidades autorizadas en la Zona Franca de Madeira",
				"ano": "603.1 - Año de inversión",
				"montante": "603.2 - Monto invertido",
				"field601": "Fecha de licencia",
				"desc603": "Discriminación",
				"desc604": "Código NACE Rev. 1 (art. 36, n. 6 EBF)",
				"desc605": "Código NACE Rev. 2 (art. 36-A, n. 7 de la EBF)",
				"field602": "Número de empleos creados en los primeros seis meses de actividad y mantenidos durante el período",
				"nrpostocriadmantidos": "Número de empleos creados/mantenidos:",
				"field606": "Al inicio del período impositivo",
				"field607": "Al final del período impositivo",
				"field603": "Inversión realizada en la adquisición de inmovilizado material e intangible, en los dos primeros años de actividad",
				"desc06-1": "Determinación del límite máximo aplicable a los beneficios fiscales correspondientes al período (a completar en el caso de aplicación del régimen del artículo 36.º-A de la EBF)",
				"benefdif": "Beneficio correspondiente a la diferencia:",
				"field608": "Tasa IRC (artículo 36-A, n° 1 de la EBF)",
				"field609": "Derrame regional (artículo 36-A, n° 12 de la EBF)",
				"field610": "Recargo municipal (artículo 36-A, n. 12 de la EBF)",
				"field611": "Tasas impositivas autónomas (artículo 36-A, párrafo 14 de la EBF)",
				"field612": "Deducción del 50% de la recaudación del IRC (artículo 36-A, n° 6 de la EBF)",
				"field613": "Otros beneficios esperados (artículo 36-A, párrafo 12 de la EBF)",
				"field614": "BENEFICIOS FISCALES TOTALES (608 + 609 + 610 + 611 + 612 + 613)",
				"field615": "Valor añadido bruto en el período y en la Zona Franca de Madeira x 20,1% [art. 36.-ºA, núm. 3, a) de la EBF]",
				"field616": "Costes laborales anuales incurridos en la Zona Franca de Madeira x 30,1% [art. 36.º-A, núm. 3, b) de la EBF]",
				"field617": "Volumen de negocios del período en la Zona Franca de Madeira x 15,1% [art. 36.º-A, núm. 3, c) de la EBF]",
				"field618": "Exceso a regularizar (art. 36-A, n. 3 de la EBF) (a transferir al campo 372 de la tabla 10 de la declaración)"
			},
			"quadro07": {
				"title": "Deducciones fiscales (a deducir en el campo 355 de la tabla 10 de la declaración)",
				"somacontrolo": "Suma de control",
				"desc71": "Beneficios fiscales contractuales para la inversión (antiguo artículo 41, apartado 1 del EBF, artículos 15 a 21 del CFI (derogado) y artículos 2 a 21 del CFI aprobado por el Decreto-Ley nº 162/2014, de 31/10 y artículos 2 a 21 del TPI",
				"table71": {
					"fields": {
						"field700": "NIF de la empresa.",
						"field1": "Diploma",
						"field2": "Período al que se refiere el beneficio",
						"field3": "Saldo vencido",
						"field701": "Saldo no deducido en el período anterior",
						"field702": "Asignación de períodos",
						"field703": "Deducción por período",
						"field704": "Saldo transferido al siguiente periodo",
						"tot1": "Total - Saldo vencido",
						"tot2": "Total - Saldo no deducido en el período anterior",
						"tot3": "Total - Asignación del período",
						"tot4": "Total - Deducción del período",
						"tot5": "Total - Saldo transferido al siguiente período"
					}
				},
				"desc71A": "RETGS - INFORMACIÓN ADICIONAL (a completar por empresas que integran el grupo) - utilización del beneficio dentro del grupo",
				"table71A": {
					"fields": {
						"field1": "Diploma",
						"field2": "Período al que se refiere el beneficio",
						"field3": "Saldo real vencido en la declaración grupal",
						"field4": "Saldo no deducido en el periodo anterior al cobro grupal",
						"field5": "Asignación del plazo en la declaración de grupo",
						"field6": "Deducción utilizada en la declaración colectiva",
						"field7": "Saldo transferido al siguiente período en la declaración grupal",
						"tot1": "Total: saldo vencido real en la declaración grupal",
						"tot2": "Total - Saldo no deducido en el período anterior al cobro grupal",
						"tot3": "Total - Asignación de períodos en la declaración de grupo",
						"tot4": "Total - Deducción utilizada en la declaración colectiva",
						"tot5": "Total - Saldo transferido al siguiente período en la declaración del grupo"
					}
				},
				"desc72": "Proyectos de inversión para la internacionalización (ex-art. 41, n. 4 del EBF y art. 22 del CFI derogados por la Ley n. 83-C/2013, de 31/12)",
				"field705": "Saldo no deducido en el período anterior",
				"field706": "Asignación de períodos",
				"field707": "Deducción por período",
				"field708": "Saldo transferido al siguiente periodo",
				"desc73": "SIFIDE - Sistema de incentivos fiscales a la investigación y al desarrollo empresarial (Ley 40/2005, de 3/8) y SIFIDE II (art. 133 de la Ley 55-A/2010, de 31/12, art. 33.º a 40.º del TPI (derogado) y artículos 35.º a 42.º del TPI aprobado por el",
				"table73": {
					"fields": {
						"field743": "NIF de la empresa.",
						"field1": "Diploma",
						"field2": "Período al que se refiere el beneficio",
						"field3": "Saldo vencido",
						"field709": "Saldo no deducido en el período anterior",
						"field710": "Asignación de períodos",
						"field711": "Deducción por período",
						"field712": "Saldo transferido al siguiente periodo",
						"tot1": "Total - Saldo vencido",
						"tot2": "Total - Saldo no deducido en el período anterior",
						"tot3": "Total - Asignación del período",
						"tot4": "Total - Deducción del período",
						"tot5": "Total - Saldo transferido al siguiente período"
					}
				},
				"desc73A": "RETGS - INFORMACIÓN ADICIONAL (a completar por empresas que integran el grupo) - utilización del beneficio dentro del grupo",
				"table73A": {
					"fields": {
						"field1": "Diploma",
						"field2": "Período al que se refiere el beneficio",
						"field3": "Saldo real vencido en la declaración grupal",
						"field4": "Saldo no deducido en el periodo anterior al cobro grupal",
						"field5": "Asignación del plazo en la declaración de grupo",
						"field6": "Deducción utilizada en la declaración colectiva",
						"field7": "Saldo transferido al siguiente período en la declaración grupal",
						"tot1": "Total: saldo vencido real en la declaración grupal",
						"tot2": "Total - Saldo no deducido en el período anterior al cobro grupal",
						"tot3": "Total - Asignación del período",
						"tot4": "Total - Deducción del período",
						"tot5": "Total - Saldo transferido al siguiente período"
					}
				},
				"desc74": "Régimen fiscal de apoyo a la inversión (Ley nº 10/2009, de 10/3 (prorrogada sucesivamente), artículos 26 a 32 del CFI (derogados) y artículos 22 a 26 del CFI aprobado por el Decreto-Ley nº 162/2014 , de 31/10 y artículos 22 a",
				"table74": {
					"fields": {
						"field744": "NIF de la empresa.",
						"field1": "Diploma",
						"field2": "Período al que se refiere el beneficio",
						"field3": "Saldo vencido",
						"field713": "Saldo no deducido en el período anterior",
						"field714": "Asignación de períodos",
						"field715": "Deducción por período",
						"field716": "Saldo transferido al siguiente periodo",
						"tot1": "Total - Saldo vencido",
						"tot2": "Total - Saldo no deducido en el período anterior",
						"tot3": "Total - Asignación del período",
						"tot4": "Total - Deducción del período",
						"tot5": "Total - Saldo transferido al siguiente período"
					}
				},
				"desc74A": "RETGS - INFORMACIÓN ADICIONAL (a completar por empresas que integran el grupo) - utilización del beneficio dentro del grupo",
				"table74A": {
					"fields": {
						"field1": "Diploma",
						"field2": "Período al que se refiere el beneficio",
						"field3": "Saldo real vencido en la declaración grupal",
						"field4": "Saldo no deducido en el periodo anterior al cobro grupal",
						"field5": "Asignación del plazo en la declaración de grupo",
						"field6": "Deducción utilizada en la declaración colectiva",
						"field7": "Saldo transferido al siguiente período en la declaración grupal",
						"tot1": "Total: saldo vencido real en la declaración grupal",
						"tot2": "Total - Saldo no deducido en el período anterior al cobro grupal",
						"tot3": "Total - Asignación de períodos en la declaración de grupo",
						"tot4": "Total - Deducción utilizada en la declaración colectiva",
						"tot5": "Total - Saldo transferido al siguiente período en la declaración del grupo"
					}
				},
				"desc76": "Crédito fiscal extraordinario por inversión - CFEI II (artículo 16 de la Ley n. 27-A/2020, de 24 de julio)",
				"table76": {
					"field1": "NIF de la empresa.",
					"field2": "Período al que se refiere el beneficio",
					"field722": "Saldo no deducido en el período anterior",
					"field723": "Asignación de períodos",
					"field724": "Deducción por período",
					"field725": "Saldo transferido al siguiente periodo",
					"tot1": "Total - Saldo no deducido en el período anterior",
					"tot2": "Total - Asignación del período",
					"tot3": "Total - Deducción del período",
					"tot4": "Total - Saldo transferido al siguiente período"
				},
				"desc76A": "RETGS - INFORMACIÓN ADICIONAL (a cumplimentar por todas las empresas que componen el grupo) - utilización del beneficio dentro del grupo",
				"table76A": {
					"field1": "Período al que se refiere el beneficio",
					"field2": "Saldo no deducido en el periodo anterior al cobro grupal",
					"field3": "Asignación del plazo en la declaración de grupo",
					"field4": "Deducción utilizada en la declaración colectiva",
					"field5": "Saldo transferido al siguiente período en la declaración grupal",
					"tot1": "Total - Saldo no deducido en el período anterior al cobro grupal",
					"tot2": "Total - Asignación de períodos en la declaración de grupo",
					"tot3": "Total - Deducción utilizada en la declaración colectiva",
					"tot4": "Total - Saldo transferido al siguiente período en la declaración del grupo"
				},
				"desc79": "IFPC - Incentivo fiscal a la producción cinematográfica y audiovisual (Artículo 59.º-F de la EBF y Ordenanza nº 89.º-A/2017, de 19 de abril)",
				"table79": {
					"fields": {
						"field790": "Número de identificación laboral",
						"field791": "Fecha de inicio del trabajo",
						"field792": "Fecha de finalización de la obra",
						"field793": "Saldo no deducido en el período anterior",
						"field794": "Valor del incentivo en el período",
						"field795": "Deducción por período",
						"field796": "Saldo transferido al siguiente periodo",
						"field797": "Monto a reembolsar",
						"field798": "Valor a restituir (a transportar al C.372 de la Q.10 del M.22)",
						"tot1": "Total - Saldo no deducido en el período anterior",
						"tot2": "Total - Valor del incentivo en el período",
						"tot3": "Total - Deducción del período",
						"tot4": "Total - Saldo transferido al siguiente período",
						"tot5": "Total - Importe a reembolsar",
						"tot6": "Total - Valor a reponer (a transportar al C.372 de la Q.10 del M.22)"
					}
				},
				"desc79A": "IFPC - Incentivo fiscal a la producción cinematográfica y audiovisual - Gravámenes soportados con vehículos ligeros de pasajeros, vehículos ligeros, motos y motocicletas, excluidos de la tributación autonómica en los términos del art 59.º-H de la EBF.",
				"desc710": "Incentivo Fiscal a la Recuperación - IFR (artículo 307 de la Ley núm. 12/2022, de 27 de junio)",
				"field710A": "Importe de los gastos de inversión elegibles en el período",
				"field710B": "Importe de la media aritmética simple de los gastos de inversión subvencionables en períodos impositivos anteriores (artículo 3, apartado 3, del anexo III)",
				"table710": {
					"fields": {
						"field71001": "NIF de la empresa.",
						"field71002": "Saldo no deducido en el período anterior",
						"field71003": "Monto del beneficio fiscal",
						"field71004": "Deducción por período",
						"field71005": "Saldo transferido al siguiente periodo",
						"tot1": "Total - Saldo no deducido en el período anterior",
						"tot2": "Total - Monto del beneficio fiscal",
						"tot3": "Total - Deducción del período",
						"tot4": "Total - Saldo transferido al siguiente período"
					}
				},
				"desc710A": "RETGS - INFORMACIÓN ADICIONAL (a cumplimentar por todas las empresas que componen el grupo) - utilización del beneficio dentro del grupo",
				"table710A": {
					"fields": {
						"field71001A": "Saldo no deducido en el periodo anterior al cobro grupal",
						"field71002A": "Monto del beneficio fiscal",
						"field71003A": "Deducción por período",
						"field71004A": "Saldo transferido al siguiente periodo",
						"tot1": "Total - Saldo no deducido en el período anterior",
						"tot2": "Total - Monto del beneficio fiscal",
						"tot3": "Total - Deducción del período",
						"tot4": "Total - Saldo transferido al siguiente período"
					}
				},
				"tipoviat": "Tipo de vehículos",
				"montencarg": "cantidad de cargos",
				"field1": "Tasas para vehículos ligeros de pasajeros y mercancías - Si CA < 27.500,00 € [art. 88, núm.",
				"field2": "Tasas para vehículos ligeros de pasajeros y mercancías - Si CA >= 27.500,00 € y < 35.000,00 € [art.",
				"field3": "Tasas para vehículos ligeros de pasajeros y mercancías - Si CA >= 35.000,00 € [art. 88, núm.",
				"field4": "Tarifas para turismos híbridos enchufables, cuya batería pueda cargarse mediante conexión a la red eléctrica y que tengan una autonomía mínima, en modo eléctrico, de 50 km y unas emisiones oficiales inferiores a 50 gCO(índice 2)/km, y vehículos ligeros de pasajeros propulsados ​​por gas natural vehicular (GNC) - Si CA < 27.500,00 € [art.",
				"field5": "Tarifas para turismos híbridos enchufables, cuya batería pueda cargarse mediante conexión a la red eléctrica y que tengan una autonomía mínima, en modo eléctrico, de 50 km y unas emisiones oficiales inferiores a 50 gCO(índice 2)/km, y Vehículos ligeros de pasajeros propulsados ​​por gas natural vehicular (GNC) - Si CA >= 27.500,00 € y < 35.000,00 €",
				"field6": "Tarifas para turismos híbridos enchufables, cuya batería pueda cargarse mediante conexión a la red eléctrica y que tengan una autonomía mínima, en modo eléctrico, de 50 km y unas emisiones oficiales inferiores a 50 gCO(índice 2)/km, y vehículos ligeros de pasajeros propulsados ​​por gas natural vehicular (GNC) - Si CA >= 35.000,00 € [art.",
				"field7": "Tasas para vehículos ligeros de pasajeros propulsados ​​por GNC - Si CA < 27.500,00 € [art. 88, núm.",
				"field8": "Tasas para vehículos ligeros de pasajeros propulsados ​​por GNC - Si CA >= 27.500,00 € y < 35.000,00 € [art.",
				"field9": "Tasas para vehículos ligeros de pasajeros propulsados ​​por GNC - Si CA >= 35.000,00 € [art. 88, núm.",
				"desc75": "Otras deducciones de cobro",
				"normlegal": "Regulaciones legales",
				"dedefet": "Deducción hecha",
				"field717": "Incentivos fiscales para la reinversión de beneficios en la Región Autónoma de Madeira (Decreto Legislativo Regional nº 2/2009/M, de 22/1)",
				"field726": "Incentivos fiscales para los beneficios reinvertidos en la Región Autónoma de las Azores (art. 6 del Decreto Legislativo Regional nº 2/99/A de 20/1)",
				"field718": "Entidades autorizadas en la Zona Franca de Madeira (art. 35, n. 6 y 36, n. 5 y 36-A, n. 6 de la EBF)",
				"field719": "Sociedades de capital riesgo e inversores de capital riesgo (art. 32-A, n. 4 de la EBF)",
				"field727": "Deducción por beneficios retenidos y reinvertidos por las PYME (artículos 27 a 34 del CFI) aprobado por el Decreto-Ley n° 162/2014, de 31/10 y artículos 27 a 34 del CFI en RAM aprobado por el Dic. Leg.",
				"field728": "Deducción del 50% de la recaudación por entidades autorizadas para operar en la Zona Franca Industrial de Madeira (art. 36-A, n. 6 de la EBF)",
				"field720": "",
				"field721": "TOTAL DEDUCCIONES [(703 + 707 + 711 + 715 + 724 + 795 + 04(Q.0710) + 717 + 726 + 718 + 719 + 727 + 728 + 720)]",
				"desc77": "Transmisión de beneficios fiscales de la sociedad fusionada o cindicada o de la sociedad aportante (Artículo 75.º-A de la CIRC)",
				"table77": {
					"fields": {
						"field729": "NIF empresa fusionada, escindida o aportante",
						"field1": "Diploma",
						"field2": "Período al que se refiere el beneficio",
						"field730": "Saldo de beneficio transmitido",
						"field731": "Asignación de períodos",
						"tot1": "Total - Saldo del beneficio transmitido",
						"tot2": "Total - Asignación del período"
					}
				},
				"desc78": "Incentivos sujetos a tipos máximos de ayuda regional (CFI aprobado por Decreto-Ley nº 162/2014, de 31 de octubre) (Para los períodos impositivos 2015 y 2016)",
				"table78": {
					"fields": {
						"field746": "código de beneficio",
						"field735": "Región elegible (artículo 43 del CFI)",
						"field736": "Código CAE de la actividad a la que se destina la inversión (artículo 2 del Port. n° 282/2014, de 31/12)",
						"field737": "Cantidad de solicitudes relevantes (artículos 11, 22 y 30 del TPI)",
						"incenfisc": "Incentivos: Impuestos",
						"field738": "IRC",
						"field739": "IMI, IMT y SELO",
						"field740": "No tributario"
					}
				},
				"radio1-2": "Indique si califica como microentidad en los términos establecidos en el Anexo del Decreto-Ley N° 372/2007, de 6 de noviembre",
				"desc78A": "Incentivos sujetos a tipos máximos de ayuda regional (CFI aprobado por Decreto-Ley nº 162/2014, de 31 de octubre) (Para los períodos impositivos 2017 y siguientes)",
				"desc78-A1": "Información sobre proyectos de inversión regionales.",
				"table78A1": {
					"fields": {
						"field782": "Línea No.",
						"projinvinc": "Proyecto de inversión/Incentivo",
						"field750": "Tipo",
						"field751": "Número de proyecto/código de incentivo",
						"field752": "Fecha de inicio de la inversión",
						"field753": "Fecha de finalización de la inversión",
						"field754": "Tipología de inversión",
						"field755": "Identificación oficial del incentivo económico.",
						"appreleprev": "Aplicaciones previstas relevantes",
						"field756": "Región elegible",
						"field757": "Código CAE",
						"field758": "Monto total",
						"field759": "Importe total actualizado"
					}
				},
				"desc78-A2": "Incentivos financieros disfrutados e impuestos utilizados - Valores del período impositivo",
				"table78A2": {
					"fields": {
						"field760": "Número de proyecto/código de incentivo",
						"apprelreali": "Solicitudes relevantes realizadas",
						"field761": "Cantidad",
						"field762": "Monto actualizado",
						"financeiro": "Financiero",
						"field763": "cantidad disfrutada",
						"field764": "Cantidad actualizada utilizada",
						"irc": "IRC",
						"field765": "Cantidad utilizada",
						"field766": "Monto actualizado",
						"imi": "IMI",
						"field767": "Cantidad utilizada",
						"field768": "Monto actualizado",
						"imt": "TMI",
						"field769": "Cantidad utilizada",
						"selo": "ESTAMPILLA",
						"field770": "Cantidad utilizada",
						"field771": "Cantidad total actualizada de beneficios disfrutados/utilizados"
					}
				},
				"desc78-A3": "Incentivos financieros disfrutados y impuestos utilizados - Valores actualizados acumulados",
				"table78A3": {
					"fields": {
						"field772": "Número de proyecto/código de incentivo",
						"apprelreali": "Solicitudes relevantes realizadas",
						"field773": "Monto acumulado actualizado",
						"financeiro": "Financiero",
						"field774": "Cantidad actualizada utilizada",
						"irc": "IRC",
						"field775": "Monto actualizado",
						"imi": "IMI",
						"field776": "Monto actualizado",
						"imt": "TMI",
						"field777": "Cantidad utilizada",
						"selo": "ESTAMPILLA",
						"field778": "Cantidad utilizada",
						"field779": "Cantidad total actualizada de beneficios disfrutados/utilizados",
						"field780": "Intensidad de ayuda acumulada (en %)",
						"field781": "Monto a ingresar en el campo 372 de la Q. 10 del M. 22"
					}
				}
			},
			"quadro08": {
				"title": "Donaciones (artículos 62, 62-A y 62-B de la EBF)",
				"table": {
					"fields": {
						"tipdona": "Tipo de donación",
						"nif": "NIF de la entidad donataria",
						"donativo": "Valor de la donación"
					}
				}
			},
			"quadro09": {
				"title": "Incentivos fiscales sujetos a la regla de minimis",
				"desc1": "Total Incentivos de años anteriores (carácter fiscal y no fiscal)",
				"field901": "N-2",
				"field902": "N-1",
				"desc2": "Incentivos del año",
				"field903": "Incentivo no fiscal",
				"desc3": "Incentivos fiscales",
				"field904A": "Retribución convencional del capital social (Ley nº 55-A/2010, de 31/12 y art. 41.º-A de la EBF) x tipo IRC",
				"field904B": "Reducción del tipo del IRC aplicable a pymes y empresas de pequeña mediana capitalización (Small Mid Cap), por los primeros 50.000,00 € de base imponible (art. 87, núm. 2 del CIRC)",
				"field904C": "Reducción de tipos - Beneficios fiscales aplicables a los territorios del interior (ex-art. 43 y art. 41-B de la EBF)",
				"field904D": "Gastos con proyecto de inversión productiva (art. 18, n. 1, al. b) y n. 5 del CFI, derogados por el Decreto-Ley n. 162/2014, de 31/10) x tasa IRC.",
				"field904E": "Impuesto municipal (art. 18, núm. 25 de la Ley núm. 73/2013, de 3 de septiembre)",
				"field904F": "Incremento del 20% de la deducción máxima por beneficios retenidos y reinvertidos (DLRR) por parte de las PYMES (art. 41.º-B, núm. 4 de la EBF)",
				"field904G": "Incremento de los gastos de inversión relacionados con la promoción de la internacionalización (art. 400. 7 y 8 de la Ley 75.º-B/2020, de 31 de diciembre) x tipo IRC",
				"field904H": "Incremento de los gastos de inversión relacionados con el fomento de la internacionalización en los sectores de la pesca y la acuicultura (art. 400.º núm. 9 de la Ley núm. 75.º-B/2020, de 31 de diciembre) x tipo impositivo IRC",
				"field904I": "Incremento de los gastos de inversión relacionados con el fomento de la internacionalización en el sector de producción agrícola primaria (art. 400.º núm. 10 de la Ley núm. 75.º-B/2020, de 31 de diciembre) x tipo IRC",
				"field904J": "Reducción de tipos - Beneficios fiscales aplicables a los territorios del interior (artículo 19.º-A del Decreto Legislativo Regional n.º 28.º-A/2021/M, de 30 de diciembre (R. A. Madeira) y artículo 38.º del Decreto Legislativo Regional n.º 1/2023/A, de 5 de enero (R.A.Açores)",
				"field904K": "Incrementos de gastos relacionados con el régimen extraordinario de apoyo a cargas incurridas en la producción agrícola x tasa IRC",
				"field904": "Incentivos totales del ejercicio de carácter fiscal (904-A + ... + 904-K)",
				"field905": "Incentivos totales del trienio (901 + 902 + 903 + 904)",
				"field906": "IRC a regularizar (a indicar en el campo 372 de la tabla 10 de la declaración)",
				"desc4": "Identificación de empresas asociadas (concepto de empresa única a efectos del límite de minimis)",
				"table": {
					"field": "NIF"
				}
			},
			"quadro10": {
				"title": "Incentivos fiscales interiores vinculados a la inversión sujetos a tipos máximos de ayuda regional (antiguo artículo 43 del EBF) - que se indicarán en el campo 372 de la pregunta 10 de la declaración",
				"invelegiveis": "Inversiones elegibles",
				"field1001": "Tangible",
				"field1002": "Intangible",
				"field1003": "TOTAL",
				"desc1": "Ayuda a la inversión",
				"field1004": "Reducción de cargas sociales x (1 - tasa IRC)",
				"majoracaodep": "Aumento de la depreciación",
				"field1005": "Mayorización",
				"field1006": "tasa de IRC",
				"field1007": "Valor de la ayuda",
				"majoracaoencsegsoc": "Aumento de los costos de la seguridad social",
				"field1008": "Mayorización",
				"field1009": "tasa de IRC",
				"field1010": "Valor de la ayuda",
				"field1011": "Aumento del crédito fiscal a la inversión",
				"field1013": "TOTAL AYUDA (1004 + 1007 + 1010 + 1011 + 1012)",
				"field1014": "Tasa de ayuda",
				"field1015": "Tarifa legal máxima aplicable",
				"field1016": "EXCESO A REGULARIZAR (a trasladar al campo 372 de la tabla 10 de la declaración)"
			},
			"quadro11": {
				"title": "Deducciones de la Base Imponible (a deducirse en el campo 399 de la tabla 09 de la declaración)",
				"desc111": "Colectivos deportivos (art. 54, n. 2 de la EBF)",
				"field1111": "Saldo no deducido en el período anterior",
				"field1112": "Asignación de períodos",
				"field1113": "Deducción por período",
				"field1114": "Saldo transferido al siguiente(s) período(s)"
			},
			"quadro11A": {
				"title": "Información adicional sobre el régimen aplicable a las entidades autorizadas en la ZFM y las ayudas estatales con fines autonómicos",
				"desc": "Si tiene socio o sociedad asociada según se definen en los apartados 2 y 3 del artículo 3 del anexo del Decreto-Ley n° 372/2007, de 6 de noviembre, indique el(los) NIF(s) respectivo(s).",
				"table": {
					"field": "NIF"
				}
			},
			"quadro12": {
				"title": "Régimen Especial de Transferibilidad de Pérdidas Fiscales Aplicable a Adquirentes hasta el 31 de diciembre de 2020, de Participaciones de Entidades Consideradas Empresas en Dificultad (Artículo 15 de la Ley N° 27-A/2020, de 24 de julio)",
				"desc121": "Información que debe comunicar la empresa adquirente",
				"table121": {
					"fields": {
						"field1": "NIF de la empresa en crisis",
						"field2": "Fecha de adquisición de participación",
						"field3": "Porcentaje medio de participación directa de la sociedad adquirente en el capital con derecho a voto de la sociedad en crisis",
						"field4": "Período de cálculo de las pérdidas fiscales corrientes transmitidas",
						"field5": "Pérdidas fiscales corrientes (saldo)",
						"field6": "Monto de pérdidas fiscales corrientes transmitidas (Columna 03 x columna 05)"
					}
				},
				"desc122": "Información que debe comunicar la empresa considerada empresa en crisis",
				"campo122": "Autorizo ​​la transferencia de bases imponibles negativas a la sociedad adquirente (nº 3 del artículo 3 del anexo IV de la Ley núm. 27-A/2020, de 24 de julio)",
				"table122": {
					"fields": {
						"field1": "NIF de la sociedad que adquiere la participación",
						"field2": "Porcentaje medio de participación directa de la sociedad adquirente en el capital con derecho a voto de la sociedad en crisis",
						"field3": "Período de cálculo de las pérdidas fiscales corrientes",
						"field4": "Pérdidas fiscales corrientes (saldo)",
						"field5": "Monto de pérdidas fiscales corrientes transmitidas (inciso b del n° 1 del artículo 3 del anexo IV) (Columna 02 x columna 04)"
					}
				}
			}
		},
		"anexoE": {
			"quadro03": {
				"title": "Determinación de la renta imponible",
				"rend": "Ingreso",
				"matcole": "Material coleccionable",
				"field1-16": "Ventas de bienes y productos.",
				"field2-17": "Prestación de servicios en el ámbito de las actividades de restauración y bebidas y hotelería y actividades similares, con excepción de los realizados en el ámbito de la actividad de explotación de establecimientos locales de alojamiento en forma de casa o apartamento.",
				"field3-18": "Prestación de servicios en el ámbito de actividades profesionales específicamente previstas en la lista adjunta al CIRS",
				"field4-19": "Otros servicios prestados",
				"field5-20": "Subvenciones a la exploración",
				"table": {
					"desc": "Subvenciones no destinadas a la exploración",
					"rend": "Ingreso",
					"matcole": "Material coleccionable = [(6) x 0,30 x t]",
					"totrend": "Total - Ingresos",
					"totmatcol": "Total - Material gravable",
					"soma": "Suma de control"
				},
				"field7-22": "Cesión temporal o uso de propiedad intelectual o industrial",
				"field8-23": "Suministro de información sobre la experiencia adquirida en el sector industrial, comercial o científico.",
				"field9-24": "Otros ingresos de capital",
				"field10-25": "Resultado positivo de las rentas de la propiedad",
				"field11-26": "Saldo positivo de ganancias y pérdidas fiscales",
				"field12-27": "Aumentos de capital restantes",
				"field13-28": "Valor de adquisición de los incrementos patrimoniales obtenidos gratuitamente",
				"field32-33": "Ingresos procedentes de la explotación de establecimientos locales de alojamiento en forma de casa o apartamento (inciso h del apartado 1 del artículo 86-B)",
				"field35-36": "Rentas procedentes de la explotación de establecimientos locales de alojamiento en forma de casa o apartamento, ubicados en una zona de contención (inciso g del apartado 1 del artículo 86-B)",
				"field14-29": "Ajuste positivo en los términos del artículo 64, núm. 3, al.",
				"field15-30": "Ajuste positivo en los términos del artículo 64, núm. 3, al.",
				"field37-38": "Los ingresos procedentes de la minería de criptoactivos y demás ingresos previstos en el inciso e) del apartado 1 del artículo 86-B.",
				"field39-46": "Las rentas relacionadas con criptoactivos, excluidas las provenientes de la minería, que no se consideran rentas de capital, ni resultan del saldo positivo de ganancias y pérdidas de capital y otros aumentos patrimoniales (inciso i) del apartado 1 del artículo 86.º-B) .",
				"field40": "INGRESOS TOTALES",
				"field41": "SUBTOTAL MATERIAL DE RECAUDACIÓN (suma de campos 16 al 30 + 33)",
				"field34": "Aumento de los aportes financieros de los propietarios y productores forestales participantes en una zona de intervención forestal destinados al fondo creado por la respectiva entidad gestora (art. 59-D, n. 14 de la EBF)",
				"field31": "Incremento por no reinversión (art. 86-B, núm. 11 de la CIRC)",
				"field42": "TOTAL MATERIAL COLECCIONABLE (Campos 41 + 31 - 34)",
				"field3142desc": "(para ser transportado al campo 346 de la tabla 09 del mod.22)",
				"tdesc": "t - Correspondiente a la tasa mínima de depreciación/amortización de los activos subsidiados."
			},
			"quadro04": {
				"title": "Otra información",
				"campo43": "Fecha en que comenzó la actividad"
			}
		},
		"anexoF": {
			"quadro03": {
				"title": "Cálculo del beneficio imponible (artículo 22, apartados 2 y 3 de la EBF)",
				"field1": "UTILIDAD NETA DEL PERIODO",
				"acrescer": "PARA AÑADIR",
				"field2": "Las pérdidas de capital (realizadas o potenciales) previstas en el artículo 10 del Código IRS no son deducibles",
				"field3": "Gastos derivados de la aplicación del valor razonable a instrumentos financieros y propiedades no deducibles",
				"field4": "Pérdidas por variaciones del tipo de cambio no deducibles",
				"field5": "Otros gastos y pérdidas asociados a la obtención de ingresos excluidos de tributación",
				"field6": "Gastos o pérdidas no deducibles según el artículo 23-A de la CIRC",
				"field19": "Gastos de honorarios de gestión y otros",
				"field7": "",
				"field8": "SUMA (campos 2 al 7 + 19)",
				"deduzir": "PARA DEDUCIR",
				"field9": "Rentas de capital previstas en el artículo 5 del Código IRS excluidas de impuestos",
				"field10": "Rentas de la propiedad previstas en el artículo 8 del Código IRS excluidas de impuestos",
				"field11": "Ganancias de capital (realizadas o potenciales) previstas en el artículo 10 del Código IRS excluidas de impuestos",
				"field12": "Ingresos derivados de la aplicación del valor razonable a instrumentos financieros y propiedades excluidas de tributación",
				"field13": "Ganancias derivadas de variaciones del tipo de cambio excluidas de tributación",
				"field14": "Ingresos por honorarios de gestión y otros",
				"field15": "",
				"field16": "SUMA (campos 9 al 15)",
				"field17": "PÉRDIDA POR EFECTOS TRIBUTARIOS [si (1+8-16) < 0]",
				"field18": "BENEFICIO IMPONIBLE [si (1+8-16) >= 0]"
			},
			"quadro04": {
				"title": "Determinación de la renta imponible",
				"field1": "PÉRDIDA TRIBUTARIA (transporte desde el campo 17 de la P. 03)",
				"field2": "UTILIDAD IMPONIBLE (arrastre del campo 18 de la P. 03)",
				"field3": "Pérdidas fiscales deducibles (art. 22, n. 4 de la EBF)",
				"field4": "PÉRDIDA TRIBUTARIA DEDUCIDA",
				"field5": "MATERIAL COLECCIONABLE (2-4)"
			},
			"quadro05": {
				"title": "Liquidación de cobro",
				"field1": "Impuesto a tasa normal (art. 22, no. 5 de la EBF) (campo 5 de Q.04) x 21%",
				"desc": "Régimen transitorio (art. 7 del DL núm. 7/2015, de 13 de enero):",
				"field2": "Impuesto relativo al saldo positivo entre ganancias y pérdidas de capital sobre bienes inmuebles (transporte del campo 10 de la tabla 06-A)",
				"field3": "Impuesto relativo a las ganancias de capital sobre otros bienes (transporte del campo 6 de la tabla 06-B)",
				"field4": "RECOGIDA (agrega campos 1 a 3) (para ser transportado al C347-B, C350 o C370 de la Q. 10 del Mod. 22)"
			},
			"quadro06": {
				"title": "Ganancias patrimoniales realizadas acogidas al régimen transitorio previsto en el artículo 7, n. 6, del DL n. 7/2015, de 13/01)",
				"descA": "Ganancias y minusvalías resultantes de la enajenación de inmuebles adquiridos conforme a la redacción anterior del artículo 22 de la EBF",
				"descB": "Ganancias de capital resultantes de la venta de otros activos",
				"tableA": {
					"fields": {
						"identmatimoveis": "Identificación matricial de propiedades",
						"codfreg": "código parroquial",
						"tipo": "Tipo",
						"artigo": "Artículo",
						"faccaoseccao": "Fracción/Sección",
						"dataaqui": "Fecha de adquisición (año/mes/día)",
						"dataalienacao": "Fecha de disposición (año/mes/día)",
						"montantemaismenosvalia": "Cantidad de plusvalía y pérdida realizadas",
						"maismenosvaiaabrangida": "Ganancias y pérdidas de valor cubiertas por el régimen transitorio"
					}
				},
				"tableB": {
					"fields": {
						"designacao": "Designación",
						"dataaqui": "Fecha de adquisición (año/mes/día)",
						"dataalienacao": "Fecha de disposición (año/mes/día)",
						"montantemaisvalia": "Importe del valor añadido determinado con referencia a la fecha 30-06-2015",
						"impcorrespondente": "Impuesto correspondiente"
					}
				},
				"field9": "BALANCE",
				"field10": "Impuesto correspondiente al saldo positivo entre ganancias de capital realizadas y pérdidas de capital realizadas (a trasladar al C2 de Q.05) [saldo C9 x 50%] x 25%",
				"field6": "SUMA (a llevar al campo 3 de la P.05)"
			}
		},
		"anexoG": {
			"quadro03": {
				"title": "Pérdidas fiscales determinadas antes del régimen especial deducibles en el régimen general (art. 7, núm. 2 del Anexo al DL 92/2018, de 13 de noviembre)",
				"field1": "Volumen de negocios de actividades planificadas (art. 3, nº 1 del Anexo del DL) y realizadas por buques/buques elegibles (art. 4 del Anexo del DL)",
				"field2": "Volumen de negocios de actividades no previstas o realizadas por buques/buques no elegibles",
				"field3": "Ratio a aplicar en el periodo a las bases imponibles negativas deducibles calculadas antes del régimen especial: (Cifración de actividades no previstas o realizadas por buques/buques no elegibles / Cifra de negocios total)",
				"table": {
					"fields": {
						"perio": "Periodo al que se refiere la pérdida",
						"montprejregespecial": "Importe de las pérdidas fiscales deducibles antes de la entrada en el régimen especial",
						"montprejperio": "Importe de las pérdidas fiscales deducidas en el período",
						"saldo": "Saldo transferido al siguiente(s) período(s)"
					}
				},
				"field10": "Monto a agregar en el campo 309 de la tabla 09 de la declaración"
			},
			"quadro04": {
				"title": "Determinación de la base imponible - Régimen especial",
				"desc": "Barcos / Embarcaciones",
				"table": {
					"fields": {
						"field1": "Número de identificación (OMI)",
						"field2": "País de Registro (artículo 9 del DL)",
						"field3": "Régimen de explotación del buque/buque (art. 4, núm. 8 del Anexo al DL)",
						"field4": "País de gestión estratégica y comercial (art. 4, n° 2, inciso b) del Anexo del DL)",
						"field5": "Porcentaje de tripulantes elegibles (art. 3, n. 3 del DL)",
						"field6": "Arqueo neto (artículo 5 del Anexo del DL)",
						"field7": "Porcentaje de los ingresos de actividades auxiliares sobre los ingresos totales (art. 3, n. 3 del Anexo del DL)",
						"field8": "Número de días (art. 5 del Anexo del DL)",
						"field9": "Reducción Base imponible (art. 5, n. 6 del Anexo del DL)",
						"field10": "Material gravable (artículo 5 del Anexo del DL)"
					}
				},
				"field11": "Base imponible determinada (a transportar al campo 300 de la tabla 09 de la declaración)"
			},
			"quadro05": {
				"title": "Información adicional",
				"desc": "En el caso de fletamento a terceros, indicar:",
				"field1": "El tonelaje neto de los buques/buques alquilados",
				"field2": "El arqueo neto de toda la flota (art. 4, n. 8 del Anexo del DL)",
				"field3": "Los ingresos de buques/embarcaciones alquilados",
				"field4": "Los ingresos de los restantes buques/embarcaciones de propiedad o asimilados (art. 4, n. 8 del Anexo del DL)"
			},
			"quadro06": {
				"title": "Otra información",
				"field1": "Fecha de inicio de la actividad del IRC (artículo 5, párrafo 4 del Anexo del DL)",
				"field2-3": "Hubo un cese del IVA hace menos de cinco años (art. 5, n. 5 del Anexo del DL)",
				"field2": "Sí",
				"field3": "No"
			}
		},
		"modal": {
			"prejuizos": "Pérdidas",
			"nif": "NIF",
			"periodo": "Período",
			"montante": "Cantidad",
			"valPerio": "Valor utilizado en el periodo",
			"retFonte": "Retención en fuente",
			"rosto": {
				"titles": {
					"309": "Régimen general",
					"320": "Reducción de tarifas",
					"331": "Exención",
					"396": "Pérdidas individuales deducidas, verificadas en períodos anteriores al inicio de la aplicación del régimen",
					"398": "Participación en las pérdidas fiscales deducidas en caso de adquisición de grupos de empresas (artículo 71, apartados 4 y 5)",
					"397A": "Valor utilizado en el período [art. 15, núm. 1 al.",
					"397B": "Valor utilizado en el período (artículo 75, párrafos 1 y 3)",
					"309B": "Régimen especial aplicable a los adquirentes de entidades consideradas empresas en crisis",
					"quadro12List": "Retenciones en fuente",
					"quadro14": "Crédito fiscal por doble imposición legal internacional (CIDTJI)"
				},
				"contasdef": {
					"title": "Definir cuentas para campos",
					"campo": "Campo {{campo}}",
					"nContaDe": "Número de cuenta",
					"perio": "Periodo hasta",
					"deznormal": "diciembre normal",
					"primeiroence": "1er cierre",
					"segence": "2do cierre",
					"sinal": "Señal",
					"positivo": "Positivo",
					"negativo": "Negativo",
					"addline": "Agregar línea",
					"table": {
						"nContaDe": "Número de cuenta",
						"nContaAte": "Número de cuentas hasta",
						"perioAte": "Periodo hasta",
						"sinal": "Positivo",
						"valor": "Valor"
					}
				},
				"contasdefall": {
					"title": "Cuentas definidas para los campos.",
					"table": {
						"campo": "Campo"
					},
					"btn": {
						"copiarparaempresas": "Copiar a otras empresas",
						"reporcontas": "Restablecer configuración"
					}
				},
				"contasdefcopy": {
					"title": "Copiar cuentas definidas para campos a otra empresa",
					"checkbox": {
						"deletedestino": "Elimina la configuración existente en la empresa de destino."
					}
				}
			},
			"anexoa": {
				"titles": {
					"A": "Criterios generales",
					"B": "Criterios generales - Zona Franca de Madeira (Art. 36.º -A, nº 12 de la EBF)",
					"C": "Criterio específico - centros de producción eléctrica",
					"D": "Criterios específicos - Minas"
				},
				"distmuni": "Código de distrito/municipio",
				"taxaderra": "Tasa de derrame",
				"massasalA": "Masa salarial municipal",
				"massasalB": "Masa salarial municipal en ZFM",
				"massasalC": "Masa salarial + prestación de servicios municipales (MSPSMunic)",
				"massasalD": "Masa salarial + prestación de servicios municipales (MSPSMunic)",
				"racioA": "Ratio de distribución (4) = (3) / (Q.03, C3)",
				"racioB": "Ratio de distribución (4) = (3) / (Q.03, C3)",
				"racioC": "Proporción de municipios",
				"racioD": "Proporción de municipios",
				"areainstexpl": "Zona de instalación.",
				"totalProduzido": "Electricidad total.",
				"producao": "Producción en bocamina del municipio (PBMunic)",
				"derramaA": "DERRAME CALCULADO (5) = (Q.03, C1 x (2) x (4)) o (Q.03, C10) x (2) x (4)",
				"derramaB": "Derrame calculado (5) = [(Q.03, C2 x (2) x (4))] x 0,2",
				"derramaC": "Derrame calculado (8) = (Q03 C1) x (2) x (7)",
				"derramaD": "Derrame calculado (7) = (Q03 C1) x (2) x (6)",
				"primeiroAno": "Indique si este es el 1er año de aplicación del criterio",
				"potencia": "Potencia instalada en el municipio"
			},
			"anexod": {
				"titles": {
					"71": "Beneficios fiscales contractuales para la inversión (antiguo artículo 41, apartado 1 del EBF, artículos 15 a 21 del CFI (derogado) y artículos 2 a 21 del CFI aprobado por el Decreto-Ley nº 162/2014, de 31/10 y artículos 2 a 21 del TPI",
					"73": "SIFIDE - Sistema de incentivos fiscales a la investigación y al desarrollo empresarial (Ley 40/2005, de 3/8) y SIFIDE II (art. 133 de la Ley 55-A/2010, de 31/12, art. 33.º a 40.º del TPI (derogado) y artículos 35.º a 42.º del TPI aprobado por el",
					"74": "Régimen fiscal de apoyo a la inversión (Ley nº 10/2009, de 10/3 (prorrogada sucesivamente), artículos 26 a 32 del CFI (derogados) y artículos 22 a 26 del CFI aprobado por el Decreto-Ley nº 162/2014 , de 31/10 y artículos 22 a",
					"77": "Transmisión de beneficios fiscales de la sociedad fusionada o cindicada o de la sociedad aportante (Artículo 75.º-A de la CIRC)",
					"78": "Incentivos sujetos a tipos máximos de ayuda regional (CFI aprobado por Decreto-Ley nº 162/2014, de 31 de octubre) (Para los períodos impositivos 2015 y 2016)",
					"79": "IFPC - Incentivo fiscal a la producción cinematográfica y audiovisual (Artículo 59.º-F de la EBF y Ordenanza nº 89.º-A/2017, de 19 de abril)",
					"121": "Información que debe comunicar la empresa adquirente",
					"122": "Información que debe comunicar la empresa considerada empresa en crisis",
					"604": "Código NACE Rev. 1 (art. 36, n. 6 EBF)",
					"605": "Código NACE Rev. 2 (art. 36-A, n. 7 de la EBF)",
					"710": "Incentivo Fiscal a la Recuperación - IFR (artículo 307 de la Ley núm. 12/2022, de 27 de junio)",
					"31A": "Fondos de pensiones y asimilados (art. 16, n. 1 del EBF) y otros fondos definitivamente exentos",
					"31B": "Otras exenciones definitivas",
					"32A": "Fondos de ahorro compartido (art. 26 del EBF) y otros fondos temporalmente exentos",
					"32B": "Otras exenciones temporales",
					"04A": "Otras deducciones de ingresos",
					"041": "Transmisión de beneficios fiscales de la sociedad fusionada o escindida o de la sociedad aportante (artículo 75-A de la CIRC)",
					"quadro8": "Donaciones (artículos 62, 62-A y 62-B de la EBF)",
					"quadro9": "Identificación de empresas asociadas (concepto de empresa única a efectos del límite de minimis)",
					"quadro11A": "Socio o sociedad(s) asociada(s) según se definen en los apartados 2 y 3 del artículo 3 del anexo del Decreto-Ley n.º 372/2007, de 6 de noviembre, indicar el(los) NIF(s) respectivo(s).",
					"71A": "RETGS - INFORMACIÓN ADICIONAL (a completar por empresas que integran el grupo) - utilización del beneficio dentro del grupo",
					"73A": "RETGS - INFORMACIÓN ADICIONAL (a completar por empresas que integran el grupo) - utilización del beneficio dentro del grupo",
					"74A": "RETGS - INFORMACIÓN ADICIONAL (a completar por empresas que integran el grupo) - utilización del beneficio dentro del grupo",
					"710A": "RETGS - INFORMACIÓN ADICIONAL (a cumplimentar por todas las empresas que componen el grupo) - utilización del beneficio dentro del grupo",
					"78A1": "Información sobre proyectos de inversión regionales.",
					"78A2": "Incentivos financieros disfrutados e impuestos utilizados - Valores del período impositivo",
					"78A3": "Incentivos financieros disfrutados y impuestos utilizados - Valores actualizados acumulados"
				},
				"quadro7": {
					"nif71": "NIF de la empresa.",
					"nif73": "NIF de la empresa.",
					"nif74": "NIF de la empresa.",
					"nif76": "NIF de la empresa.",
					"nif77": "NIF empresa fusionada, escindida o aportante",
					"nIdentificacao": "Número de identificación laboral",
					"diploma": "Diploma",
					"periodo": "Período al que se refiere el beneficio",
					"saldoCaducado71": "Saldo vencido",
					"saldoCaducado73": "Saldo vencido",
					"saldoCaducado74": "Saldo vencido",
					"saldoCaducado71A": "Saldo real vencido en la declaración grupal",
					"saldoCaducado73A": "Saldo real vencido en la declaración grupal",
					"saldoCaducado74A": "Saldo real vencido en la declaración grupal",
					"saldoNaoDeduzido71": "Saldo no deducido en el período anterior",
					"saldoNaoDeduzido73": "Saldo no deducido en el período anterior",
					"saldoNaoDeduzido74": "Saldo no deducido en el período anterior",
					"saldoNaoDeduzido76": "Saldo no deducido en el período anterior",
					"saldoNaoDeduzido71A": "Saldo no deducido en el periodo anterior al cobro grupal",
					"saldoNaoDeduzido73A": "Saldo no deducido en el periodo anterior al cobro grupal",
					"saldoNaoDeduzido74A": "Saldo no deducido en el periodo anterior al cobro grupal",
					"saldoNaoDeduzido76A": "Saldo no deducido en el periodo anterior al cobro grupal",
					"saldoNaoDeduzido79": "Saldo no deducido en el período anterior",
					"dotacaoDoPeriodo71": "Asignación de períodos",
					"dotacaoDoPeriodo73": "Asignación de períodos",
					"dotacaoDoPeriodo74": "Asignación de períodos",
					"dotacaoDoPeriodo76": "Asignación de períodos",
					"dotacaoDoPeriodo71A": "Asignación del plazo en la declaración de grupo",
					"dotacaoDoPeriodo73A": "Asignación del plazo en la declaración de grupo",
					"dotacaoDoPeriodo74A": "Asignación del plazo en la declaración de grupo",
					"dotacaoDoPeriodo76A": "Asignación del plazo en la declaración de grupo",
					"deducaoNoPeriodo": "Deducción por período",
					"deducaoUtilizada": "Deducción utilizada en la declaración colectiva",
					"saldoQueTransita71": "Saldo transferido al siguiente periodo",
					"saldoQueTransita73": "Saldo transferido al siguiente periodo",
					"saldoQueTransita74": "Saldo transferido al siguiente periodo",
					"saldoQueTransita76": "Saldo transferido al siguiente periodo",
					"saldoQueTransita71A": "Saldo transferido al siguiente período en la declaración grupal",
					"saldoQueTransita73A": "Saldo transferido al siguiente período en la declaración grupal",
					"saldoQueTransita74A": "Saldo transferido al siguiente período en la declaración grupal",
					"saldoQueTransita76A": "Saldo transferido al siguiente período en la declaración grupal",
					"saldoQueTransita79": "Saldo transferido al siguiente periodo",
					"dataInicio": "Fecha de inicio del trabajo",
					"dataConclusao": "Fecha de finalización de la obra",
					"valorIncentivo": "Valor del incentivo en el período",
					"deducaoDoPeriodo": "Deducción por período",
					"valorAReembolsar": "Monto a reembolsar",
					"valorARepor": "Valor a restituir (a transportar al C.372 de la Q.10 del M.22)",
					"saldo": "Saldo de beneficio transmitido",
					"dotacao": "Asignación de períodos",
					"montanteBeneficio": "Monto del beneficio fiscal",
					"dsdiploma": {
						"1": "Estatuto de Beneficios Tributarios (ex-art. 41) y Decreto-Ley n° 409/99, de 15 de octubre",
						"2": "Código Fiscal de Inversiones, aprobado por el Decreto-Ley núm. 249/2009, de 23 de septiembre, modificado por la Ley núm. 20/2012, de 14 de mayo, por el Decreto-Ley núm. N° 83-C/2013, de 31 de diciembre y derogado por Decreto-Ley",
						"3": "Código Fiscal de Inversiones, aprobado por Decreto-Ley n° 162/2014, de 31 de octubre",
						"4": "Decreto Legislativo Regional N° 18/99/M, de 28 de junio",
						"5": "Decreto Legislativo Regional núm. 2/99/A, de 20 de enero (art. 9.º)",
						"6": "Código Tributario de Inversiones en RAM aprobado por Decreto Legislativo Regional N° 24/2016/M, de 28 de junio",
						"720": "720",
						"724": "Crédito fiscal extraordinario por inversión (Ley 49/2013, de 16 de julio)",
						"731": "Ley 40/2005, de 3 de agosto",
						"732": "Código Tributario de Inversiones, aprobado por Decreto-Ley N° 249/2009, de 23 de septiembre y derogado por Decreto-Ley N° 162/2014, de 31 de octubre",
						"733": "Código Fiscal de Inversiones, aprobado por Decreto-Ley n° 162/2014, de 31 de octubre",
						"734": "Código Fiscal de Inversiones en la R. A. de Madeira aprobado por Decreto Legislativo Regional n° 24/2016/M, de 28 de junio",
						"741": "RFAI - Régimen fiscal de apoyo a la inversión [Ley núm. 10/2009, de 10 de marzo (prorrogada sucesivamente) y arts.",
						"742": "RFAI - Régimen fiscal de apoyo a la inversión (arts. 22 a 26 del CFI aprobado por Decreto-Ley n. 162/2014, de 31 de octubre)",
						"743": "RFAI - Régimen fiscal de apoyo a la inversión en la Región Autónoma de Madeira - Decreto Legislativo Regional n.º 24/2016/M, de 28 de junio)"
					},
					"tabelas78": {
						"regiao78": "Región elegible (artículo 43 del CFI)",
						"regiao78A1": "Región elegible",
						"cae78": "Código CAE de la actividad a la que se destina la inversión (artículo 2 del Port. n° 282/2014, de 31/12)",
						"cae78A1": "Código CAE",
						"montanteDasAplicacoes": "Cantidad de solicitudes relevantes (artículos 11, 22 y 30 del TPI)",
						"irc": "IRC",
						"imiIMTSelo": "IMI, IMT y SELO",
						"naoFiscais": "No tributario",
						"numeroLinha": "Línea No.",
						"numProjecto": "Número de proyecto/código de incentivo",
						"dataInicio": "Fecha de inicio de la inversión",
						"dataFim": "Fecha de finalización de la inversión",
						"tipologia": "Tipología de inversión",
						"identificacao": "Identificación oficial del incentivo económico.",
						"montante": "Monto total",
						"montanteActualizado": "Importe total actualizado",
						"aplicacoesMontante78A2": "Solicitudes relevantes realizadas - Importe",
						"aplicacoesMontante78A3": "Solicitudes relevantes realizadas - Importe acumulado actualizado",
						"aplicacoesActualizado": "Monto actualizado",
						"financeiroUsufruido": "Financiero - Monto disfrutado",
						"financeiroActualizado": "Financiero: importe actualizado utilizado",
						"ircUtilizado": "IRC - Cantidad utilizada",
						"ircActualizado": "IRC - Importe actualizado",
						"imiUtilizado": "IMI - Cantidad utilizada",
						"imiActualizado": "IMI - Importe actualizado",
						"imtUtilizado": "IMT: cantidad utilizada",
						"seloUtilizado": "SELLO - Cantidad utilizada",
						"financeiroMontante": "Financiero: importe actualizado utilizado",
						"ircMontante": "IRC - Importe actualizado",
						"imiMontante": "IMI - Importe actualizado",
						"imtMontante": "IMT: cantidad utilizada",
						"seloMontante": "SELLO - Cantidad utilizada",
						"intensidade": "Intensidad de ayuda acumulada (en %)",
						"montanteAInscrever": "Monto a ingresar en el campo 372 de la Q. 10 del M. 22",
						"montanteTotal": "Cantidad total actualizada de beneficios disfrutados/utilizados",
						"tipo": "Tipo",
						"datasources": {
							"beneficio": {
								"71": "Beneficios fiscales contractuales para la inversión (ex-art. 41, n. 1 del EBF, artículos 15 a 21 del CFI (derogado), artículos 2 a 21 del CFI aprobado por el Decreto-Ley n. 162/2014, de 31 /10 y artículos 2 a 21 del TPI",
								"727": "DLRR - Deducción por beneficios retenidos y reinvertidos por las PYME (artículos 27 a 34 del CFI aprobado por el Decreto-Ley nº 162/2014, de 31/10 y artículos 27 a 34. del CFI en RAM aprobado por el Decreto Regional nº. 24/2016/M, de 28/06)",
								"741": "RFAI - Régimen fiscal de apoyo a la inversión [Ley núm. 10/2009, de 10 de marzo (prorrogada sucesivamente) y arts.",
								"742": "RFAI - Régimen fiscal de apoyo a la inversión (arts. 22 a 26 del CFI aprobado por Decreto-Ley n. 162/2014, de 31 de octubre)",
								"743": "RFAI - Régimen fiscal de apoyo a la inversión en la Región Autónoma de Madeira - Decreto Legislativo Regional n.º 24/2016/M, de 28 de junio)"
							},
							"regiao78": {
								"1": "Norte",
								"2": "Centro",
								"3": "Alentejo",
								"4": "Región Autónoma de las Azores",
								"5": "Región Autónoma de Madeira",
								"6": "Algarve",
								"7": "Mafra",
								"8": "lures",
								"9": "Península de Setúbal",
								"10": "Vila Franca de Xira",
								"11": "S. João das Lampas y Terrugem (Sintra)"
							},
							"regiao78A1": {
								"PT111": "Norte - Alto Miño",
								"PT112": "Norte - Cávado",
								"PT119": "Norte - Avenida",
								"PT11A": "Norte - Área Metropolitana de Oporto",
								"PT11B": "Norte - Alto Támega",
								"PT11C": "Norte - Tâmega e Sousa",
								"PT11D": "Norte - Duero",
								"PT11E": "Norte - Tierras de Trás-os-Montes",
								"PT16B": "Centro - Oeste",
								"PT16D": "Centro - Región de Aveiro",
								"PT16E": "Centro - Región de Coimbra",
								"PT16F": "Centro - Región de Leiria",
								"PT16G": "Centro - Viseu Dão Lafões",
								"PT16H": "Centro - Beira Baixa",
								"PT16I": "Centro - Tajo Medio",
								"PT16J": "Centro - Beiras y Serra da Estrela",
								"PT1109": "Gran Lisboa - Mafra",
								"PT1107": "Gran Lisboa - Loures",
								"PT1114": "Gran Lisboa - Vila Franca de Xira",
								"PT111127": "Gran Lisboa - S. João das Lampas y Terrugem (Sintra)",
								"PT172": "Península de Setúbal",
								"PT181": "Alentejo - Costa del Alentejo",
								"PT184": "Alentejo - Bajo Alentejo",
								"PT185": "Alentejo - Leziria do Tejo",
								"PT186": "Alentejo - Alto Alentejo",
								"PT187": "Alentejo - Centro de Alentejo",
								"PT150": "Algarve",
								"PT200": "Región Autónoma de las Azores",
								"PT300": "Región Autónoma de Madeira"
							},
							"tipo": {
								"D": "Distintivo",
								"U": "Soltero"
							},
							"tipologia": {
								"001": "Creación de un nuevo establecimiento.",
								"002": "Aumentar la capacidad de un establecimiento existente",
								"003": "Diversificación de la producción de un establecimiento respecto de productos no fabricados previamente en ese establecimiento",
								"004": "Cambio fundamental en el proceso de producción general de un establecimiento existente."
							},
							"identificacao": {
								"10": "NORTE 2020",
								"11": "República Democrática Popular 2020",
								"12": "PO SEUR",
								"13": "PATA 2020",
								"14": "PRODERAM 2020",
								"15": "PRORURAL+",
								"99": "OTROS",
								"01": "AZORES 2020",
								"02": "ALENTEJO 2020",
								"03": "CENTRO 2020",
								"04": "COMERCIO INVIERTE",
								"05": "COMPETIR 2020",
								"06": "CRESC ALGARVE 2020",
								"07": "LISBOA 2020",
								"08": "MADERA 14-20",
								"09": "MARZO 2020"
							}
						}
					}
				},
				"codBeneficio": "código de beneficio",
				"montante": "Cantidad",
				"nif041": "NIF soc.",
				"nifquadro8": "NIF de la entidad donataria",
				"nifquadro9": "907-NIF",
				"nifquadro11A": "3-NIF",
				"nif121": "Columna NIF de la empresa en crisis",
				"nif122": "Columna NIF de la sociedad que adquiere la participación",
				"nace604": "Código NACE Rev. 1 (art. 36, n. 6 EBF)",
				"nace605": "Código NACE Rev. 2 (art. 36-A, n. 7 de la EBF)",
				"valorDonativo": "Valor de la donación",
				"tipoDonativo": "Tipo de donación",
				"ano603": "Año de inversión",
				"percentagem": "Porcentaje promedio",
				"periodo": "Período de liquidación",
				"prejuizosMontante": "Cantidad de pérdidas",
				"prejuizosSaldo": "Pérdidas fiscales corrientes"
			},
			"anexoe": {
				"title": "Subvenciones no destinadas a la exploración",
				"rendimento": "Actuación",
				"materiacol": "Material coleccionable = [(6) x 0,30 x t]"
			},
			"anexof": {
				"titles": {
					"A": "Ganancias y minusvalías resultantes de la enajenación de inmuebles adquiridos conforme a la redacción anterior del artículo 22 de la EBF",
					"B": "Ganancias de capital resultantes de la venta de otros activos"
				},
				"identificacao": "Identificación matricial de propiedades",
				"codFreguesia": "código parroquial",
				"tipo": "Tipo",
				"artigo": "Artículo",
				"fraccao": "Fracción/Sección",
				"dataAquisicao": "fecha de adquisición",
				"dataAlienacao": "Fecha de eliminación",
				"montanteA": "Cantidad de plusvalía y pérdida realizadas",
				"montanteB": "Importe del valor añadido determinado con referencia a la fecha 30-06-2015",
				"montanteRegimeTransitorio": "Ganancias y pérdidas de valor cubiertas por el régimen transitorio",
				"designacao": "Designación",
				"imposto": "Impuesto correspondiente"
			},
			"anexog": {
				"titles": {
					"quadro3": "",
					"quadro4": ""
				},
				"periodo": "Periodo al que se refiere la pérdida",
				"montanteDedutiveis": "Importe de las pérdidas fiscales deducibles antes de la entrada en el régimen especial",
				"montanteDeduzidos": "Importe de las pérdidas fiscales deducidas en el período",
				"saldo": "Saldo transferido al siguiente(s) período(s)",
				"nIdentificacao": "Número de identificación (OMI)",
				"paisDeRegisto": "País de Registro (artículo 9 del DL)",
				"regime": "Régimen de explotación del buque/buque (art. 4, núm. 8 del Anexo al DL)",
				"paisDeGestao": "País de gestión estratégica y comercial (art. 4, n° 2, inciso b) del Anexo del DL)",
				"percTripulantes": "Porcentaje de tripulantes elegibles (art. 3, n. 3 del DL)",
				"arqueacao": "Arqueo neto (artículo 5 del Anexo del DL)",
				"percRendimentos": "Porcentaje de los ingresos de actividades auxiliares sobre los ingresos totales (art. 3, n. 3 del Anexo del DL)",
				"numeroDias": "Número de días (art. 5 del Anexo del DL)",
				"reducaoMateria": "Reducción Base imponible (art. 5, n. 6 del Anexo del DL)",
				"materiaColectavel": "Material gravable (artículo 5 del Anexo del DL)"
			},
			"prejuizosfiscais": {
				"title": "Pérdidas Fiscales",
				"anoprej": "año de perdida",
				"valprej": "Valor de la pérdida fiscal",
				"anoded": "Año de deducción",
				"valded": "Monto de la deducción",
				"adicionarprej": "Añadir pérdida",
				"adicionarded": "Agregar deducción",
				"table": {
					"ano": "año de perdida",
					"valorPrejuizoFiscal": "Valor de pérdida",
					"valorDeduzido": "Monto deducido",
					"valorPorDeduzir": "Monto a deducir",
					"anoDeducao": "Año de deducción",
					"valorDeducao": "Monto de la deducción"
				}
			},
			"derrama": {
				"title": "Datos adicionales para calcular el vertido",
				"concelho": "Condado: {{cod}} - {{name}}",
				"ano": "Año: {{año}}",
				"postosTrabalhoCriados": "Número de empleos creados",
				"volumeNegocios": "Volumen de negocio en el año anterior",
				"investimentoNoAno": "Inversión en el año",
				"temSedeNoConcelho": "La empresa tiene su sede en el municipio.",
				"anoFixacaoDaSede": "Año de establecimiento de la sede",
				"microEmpresa": "microempresa",
				"pequenaEmpresa": "Pequeña empresa",
				"mediaEmpresa": "Mediana empresa",
				"acrescimoTrabalhadores": "Incremento del número de trabajadores (%)",
				"postosTrabalhoCriados2019": "Número de empleos creados en 2019",
				"postosTrabalhoCriados2020": "Número de empleos creados en 2020"
			},
			"configws": {
				"title": "Configuración del servicio web",
				"username": "Nombre de usuario del colaborador",
				"password": "Contraseña del contribuyente",
				"servicoInvCC": "Servicio invocado por Contador Público (CC)",
				"ccPowers": "CC con plenas facultades declarativas para Contrib.",
				"usernameCC": "nombre de usuario CC",
				"passwordCC": "contraseña CC",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> El servicio ya está configurado.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> El servicio no está configurado.",
				"configsaved": "Configuración guardada exitosamente."
			}
		},
		"datasources": {
			"tiporend": {
				"lucrosestav": "Beneficios del establecimiento.",
				"outrosrend": "Otros ingresos"
			},
			"beneficio": {
				"100": "Régimen de interioridad - artículo 43 del EBF - Régimen transitorio",
				"119": "Otras deducciones de ingresos",
				"120": "Concesionario de la Zona Franca de Madeira - Exención hasta 2017 (art. 33, n. 12 de la EBF)",
				"121": "Beneficios derivados de obras en la Base de Lajes e instalaciones de apoyo - artículo XI del Anexo I del Acuerdo Técnico, aprobado por Resolución de la Asamblea de la República 38/95, de 11 de octubre - Acuerdo de Cooperación y Defensa entre la República Portuguesa y Estados Unidos",
				"122": "Decreto-Ley N° 43 335/1960 de 19/11",
				"129": "Otras exenciones temporales",
				"140": "Fondos de pensiones y similares (art. 16, n. 1 del EBF)",
				"141": "Ahorro para la jubilación, ahorro para la educación y fondos de ahorro para la jubilación/educación (art. 21, n. 1 de la EBF)",
				"142": "Fondos de capital riesgo (art. 23 del FBE)",
				"143": "Fondos de inversión inmobiliaria en recursos forestales (art. 24, n. 1 de la EBF)",
				"149": "Otros fondos definitivamente exentos",
				"150": "Fondos de ahorro compartidos (art. 26, n. 1 de la EBF)",
				"151": "Fondos de inversión inmobiliaria - rehabilitación urbana (art. 71, n. 1 de la EBF)",
				"159": "Otros fondos temporalmente exentos",
				"401": "Incremento a la creación de empleo (art. 19 del EBF)",
				"409": "Renumeración convencional del capital social - PYME (art. 136 de la Ley nº 55-A/2010, de 31/12 y art. 41-A de la EBF)",
				"410": "Otras deducciones de ingresos",
				"040": "Entidades marítimas y de navegación aérea (artículo 13 de la CIRC)",
				"049": "Otras exenciones definitivas"
			},
			"tipodonativo": {
				"10": "Mecenazgo social (art. 62)",
				"11": "Mecenazgo social - apoyo especial (art. 62)",
				"12": "Patrocinio familiar (art. 62)",
				"13": "Mecenazgo cultural (art. 62.º-B)",
				"14": "Mecenazgo cultural - contratos plurianuales (art. 62-B)",
				"15": "Mecenazgo a entidades asociativas (art. 62)",
				"16": "Mecenazgo de la sociedad de la información (art. 65).",
				"17": "Mecenazgo - sociedad de la información - contratos plurianuales (art. 65).",
				"18": "Estado - mecenazgo científico (art. 62-A)",
				"19": "Estado - mecenazgo científico - contratos plurianuales (art. 62-A)",
				"20": "Mecenazgo científico – entidades privadas (art. 62-A)",
				"21": "Mecenazgo científico - entidades privadas - contratos plurianuales (art. 62-A)",
				"22": "Regímenes especiales (legislación separada)",
				"23": "Mecenazgo cultural - otros (art. 62.º-B)",
				"24": "Donaciones en especie (n° 11 del artículo 62, n° 5 del artículo 62-A de la EBF y n° 7 del artículo 62-B)",
				"25": "Donaciones destinadas a la Estructura de Misión para las Celebraciones del V Centenario de la Circun-Navegación, creada por Resolución del Consejo de Ministros n. 24/2017, de 26 de enero – Mecenazgo cultural (art. 294 de la Ley n. 71/2018, del 31 de diciembre)",
				"01": "Estado - mecenazgo social (art. 62)",
				"02": "Estado - mecenazgo cultural (art. 62.º-B)",
				"03": "Estado - mecenazgo ambiental (art. 62)",
				"04": "Estado - mecenazgo educativo (art. 62)",
				"06": "Estado - mecenazgo cultural - contratos plurianuales (art. 62-B)",
				"07": "Estado - mecenazgo ambiental - contratos plurianuales (art. 62)",
				"08": "Estado - mecenazgo deportivo - contratos plurianuales (art. 62)",
				"09": "Estado - mecenazgo educativo - contratos plurianuales (art. 62)"
			},
			"tipoidentmatricial": {
				"u": "Urbano",
				"r": "Rústico",
				"o": "Omitido"
			},
			"paises": {
				"4": "AFGANISTÁN",
				"8": "ALBANIA",
				"10": "ANTÁRTIDA",
				"12": "ARGELIA",
				"16": "SAMOA AMERICANA",
				"20": "ANDORRA",
				"24": "ANGOLA",
				"28": "ANTIGUA Y BARBUDA",
				"31": "AZERBAIYÁN",
				"32": "ARGENTINA",
				"36": "AUSTRALIA",
				"40": "AUSTRIA",
				"44": "BAHAMAS",
				"48": "BAREM",
				"50": "BANGLADESH",
				"51": "ARMENIA",
				"52": "BARBADOS",
				"56": "BÉLGICA",
				"60": "ISLAS BERMUDAS",
				"64": "BUTAO",
				"68": "BOLIVIA",
				"70": "BOSNIA Y HERZEGOVINA",
				"72": "BOTSUANA",
				"74": "ISLAS BOUVET",
				"76": "BRASIL",
				"84": "BELICE",
				"86": "TERRITORIO BRITÁNICO DEL OCÉANO ÍNDICO",
				"90": "ISLAS SOLOMAO",
				"92": "ISLAS VÍRGENES (BRITÁNICAS)",
				"96": "BRUNEI DARUSSALAM",
				"100": "BULGARIA",
				"104": "MIANMAR",
				"108": "BURUNDI",
				"112": "BIELORRUSIA",
				"116": "CAMBOYA",
				"120": "CAMERÚN",
				"124": "CANADÁ",
				"132": "CABO VERDE",
				"136": "ISLAS CAYMAO",
				"140": "REPÚBLICA CENTROAFRICANA)",
				"144": "SRI LANKA",
				"148": "CHAD",
				"152": "CHILE",
				"156": "PORCELANA",
				"158": "TAIWÁN",
				"162": "ISLAS DE NAVIDAD",
				"166": "ISLAS COCOS O KEELING",
				"170": "COLOMBIA",
				"174": "COMORAS",
				"175": "MAYOTTE",
				"178": "Congo",
				"180": "CONGO (REPÚBLICA DEMOCRÁTICA DEL)",
				"184": "ISLAS COOK",
				"188": "COSTA RICA",
				"191": "CROACIA",
				"192": "CUBA",
				"196": "CHIPRE",
				"203": "REPÚBLICA CHECA",
				"204": "BENÍN",
				"208": "DINAMARCA",
				"212": "DOMINICA",
				"214": "REPÚBLICA DOMINICANA",
				"218": "ECUADOR",
				"222": "EL SALVADOR",
				"226": "GUINEA ECUATORIAL",
				"231": "ETIOPÍA",
				"232": "ERITREA",
				"233": "ESTONIA",
				"234": "ISLAS FEROE",
				"238": "ISLAS MALVINAS (MALVINAS)",
				"239": "GEORGIA DEL SUR E ISLAS SANDWICH",
				"242": "ISLAS FIJI",
				"246": "FINLANDIA",
				"248": "ISLAS ALAND",
				"250": "FRANCIA",
				"254": "GUAYANA FRANCESA",
				"258": "POLINESIA FRANCESA",
				"260": "TERRITORIOS DEL SUR DE FRANCIA",
				"262": "Yibuti",
				"266": "GABÓN",
				"268": "GEORGIA",
				"270": "GAMBIA",
				"275": "TERRITORIO PALESTINO OCUPADO",
				"276": "ALEMANIA",
				"278": "REPÚBLICA DEMOCRÁTICA ALEMANA",
				"288": "GHANA",
				"292": "GIBRALTAR",
				"296": "KIRIBATI",
				"300": "GRECIA",
				"304": "TIERRA VERDE",
				"308": "GRANADA",
				"312": "GUADALUPE",
				"316": "GUAM",
				"320": "GUATEMALA",
				"324": "GUINEA",
				"328": "GUAYANA",
				"332": "HAITÍ",
				"334": "ISLAS HEARD E ISLAS MCDONALD",
				"336": "SANTA SE (CIUDAD DEL ESTADO DEL VATICANO)",
				"340": "HONDURAS",
				"344": "HONG KONG",
				"348": "HUNGRÍA",
				"352": "ISLANDIA",
				"356": "INDIA",
				"360": "INDONESIA",
				"364": "IRÁN (REPÚBLICA ISLÁMICA)",
				"368": "IRAK",
				"372": "IRLANDA",
				"376": "ISRAEL",
				"380": "ITALIA",
				"384": "COSTA DE MARFIL",
				"388": "JAMAICA",
				"392": "JAPÓN",
				"398": "KAZAJSTÁN",
				"400": "JORDÁN",
				"404": "KENIA",
				"408": "COREA (REPÚBLICA POPULAR DEMOCRÁTICA DE)",
				"410": "COREA (REPÚBLICA DE)",
				"414": "KUWAIT",
				"417": "KIRGUIZ",
				"418": "LAOS (REPÚBLICA POPULAR DEMOCRÁTICA DE)",
				"422": "LÍBANO",
				"426": "LESOTO",
				"428": "LETONIA",
				"430": "LIBERIA",
				"434": "LIBIA (JAMAHIRIYA ÁRABE DA)",
				"438": "LIECHTENSTEIN",
				"440": "LITUANIA",
				"442": "LUXEMBURGO",
				"446": "MACAO",
				"450": "MADAGASCAR",
				"454": "MALAUI",
				"458": "MALASIA",
				"462": "MALDIVAS",
				"466": "MALÍ",
				"470": "MALTA",
				"474": "MARTINICA",
				"478": "MAURITANIA",
				"480": "MAURICIAS",
				"484": "MÉXICO",
				"492": "MÓNACO",
				"496": "MONGOLIA",
				"498": "MOLDAVIA (REPÚBLICA DE)",
				"499": "MONTENEGRO",
				"500": "MONSERRATE",
				"504": "MARRUECOS",
				"508": "MOZAMBIQUE",
				"512": "OMÁN",
				"516": "NAMIBIA",
				"520": "NAURÚ",
				"524": "NEPAL",
				"528": "PAÍSES BAJOS",
				"530": "ANTILLAS HOLANDESAS",
				"531": "CICATRIZACIÓN",
				"533": "ARUBA",
				"534": "SAN MARTIN",
				"535": "BONAIRE, SAN EUSTACIO Y SABA",
				"540": "NUEVA CALEDONIA",
				"548": "VANUATU",
				"554": "NUEVA ZELANDA",
				"558": "NICARAGUA",
				"562": "NÍGER",
				"566": "NIGERIA",
				"570": "NIUE",
				"574": "ISLAS NORFOLK",
				"578": "NORUEGA",
				"580": "ISLAS MARIANAS DEL NORTE",
				"581": "ISLAS LEJANAS MÁS PEQUEÑAS DE ESTADOS UNIDOS",
				"583": "MICRONESIA (ESTADOS FEDERADOS DE)",
				"584": "ISLAS MARSHALL",
				"585": "PALAU",
				"586": "PAKISTÁN",
				"591": "PANAMÁ",
				"598": "PAPUASIA - NUEVA GUINEA",
				"600": "PARAGUAY",
				"604": "PERÚ",
				"608": "FILIPINAS",
				"612": "PITCAIRN",
				"616": "POLONIA",
				"620": "PORTUGAL",
				"624": "GUINE-BISSAU",
				"626": "TIMOR ORIENTAL",
				"630": "PUERTO RICO",
				"634": "KATAR",
				"638": "REUNIÓN",
				"642": "RUMANIA",
				"643": "RUSIA (FEDERACIÓN DE)",
				"646": "RUANDA",
				"652": "S.O BARTOLOMEU",
				"654": "SANTA ELENA",
				"659": "SAN CHITTS Y NIEVES",
				"660": "ANGUILA",
				"662": "SANTA LUCÍA",
				"666": "SAN PEDRO Y MIQUELÓN",
				"670": "SAN VICENTE Y LAS GRANADINAS",
				"674": "SAN MARINO",
				"678": "SANTO TOMÉ Y PRÍNCIPE",
				"682": "ARABIA SAUDITA",
				"686": "SENEGAL",
				"688": "SERBIA",
				"690": "SEYCHELLES",
				"694": "SIERRA LEONA",
				"702": "SINGAPUR",
				"703": "ESLOVACA (REPÚBLICA)",
				"704": "VIETNAM",
				"705": "ESLOVENIA",
				"706": "SOMALIA",
				"710": "SUDÁFRICA",
				"716": "ZIMBABUE",
				"724": "ESPAÑA",
				"728": "SUDÁN DEL SUR",
				"729": "SUDÁN",
				"732": "SARAH OCCIDENTAL",
				"736": "SUDÁN",
				"740": "SURINAME",
				"744": "SVALBARD Y LA ISLA DE JAN MAYEN",
				"748": "SUAZILANDIA",
				"752": "SUECIA",
				"756": "SUIZA",
				"760": "SIRIA (REPÚBLICA ÁRABE DE)",
				"762": "TAJIQUISTAO",
				"764": "TAILANDIA",
				"768": "IR",
				"772": "TOKELAU",
				"776": "TONGA",
				"780": "TRINIDAD Y TOBAGO",
				"784": "EMIRATOS ÁRABES UNIDOS",
				"788": "TÚNEZ",
				"792": "Turquía",
				"795": "TURKMENISTA",
				"796": "TURCAS Y CAICOS (ISLAS)",
				"798": "TUVALU",
				"800": "UGANDA",
				"804": "UCRANIA",
				"807": "MACEDONIA (EX REPÚBLICA YUGOSLAVA DE)",
				"810": "UNIÓN SOVIÉTICA",
				"818": "EGIPTO",
				"826": "REINO UNIDO",
				"830": "ISLAS DEL CANAL",
				"831": "GUERNSEY",
				"832": "JERSEY",
				"833": "ISLA DE MAN",
				"834": "TANZANIA, REPÚBLICA UNIDA DE",
				"840": "ESTADOS UNIDOS",
				"850": "ISLAS VÍRGENES (ESTADOS UNIDOS)",
				"854": "BURKINAFASO",
				"858": "URUGUAY",
				"860": "USBEQUISTAO",
				"862": "VENEZUELA",
				"876": "WALLIS Y FUTUNA (ISLAS)",
				"882": "SAMOA",
				"887": "YEMEN",
				"891": "JUGOSL.VIA",
				"892": "SERVIA Y MONTENEGRO",
				"894": "ZAMBIA",
				"900": "OLIVEN.A",
				"901": "REPÚBLICA DE KOSOVO",
				"956": "ALDERNE"
			},
			"regimeexploracao": {
				"1": "Adquisición directa",
				"2": "Alquiler a largo plazo",
				"3": "Arrendamiento",
				"4": "Carta a terceros"
			},
			"factoimpedimento": {
				"naoaplica": "No aplica",
				"falecimentoconjuge": "Muerte de un cónyuge no separado de personas y bienes, de una persona con la que convivan en condiciones análogas a las de los cónyuges, o de un pariente o asimilado en el 1º grado de la línea recta (inciso a) del apartado 1 del artículo 12.-A del Decreto-Ley n° 452/99, de 5 de noviembre)",
				"falecimentooutro": "Fallecimiento de otro familiar o asimilado en línea directa o en 2º grado de línea colateral (inciso b) del n° 1 del artículo 12.º-A del Decreto-Ley n° 452/99, de 5 de noviembre)",
				"doencagrave": "Enfermedad u hospitalización grave y repentina, que haga absolutamente imposible al contador público cumplir con sus obligaciones, así como en situaciones de parto (inciso c) del numeral 1 del artículo 12.º-A del Decreto-Ley n° 452/. 99, de 5 de noviembre)",
				"sitparentalidade": "Situaciones de crianza (inciso d) del núm. 1 del artículo 12.º-A del Decreto-Ley núm."
			}
		},
		"messages": {
			"nifAlredyExists": "El número de contribuyente ya existe.",
			"nifEmpty": "Debes indicar tu número de contribuyente.",
			"periodoAlredyExists": "El año indicado ya tiene pérdida atribuida.",
			"paisTipoRendAlredyExists": "Ya existe un país con un tipo de ingreso.",
			"codbenefvazioAlredyExists": "El código ya existe.",
			"codbenefvazio": "No seleccionó ningún código.",
			"naceAlredyExists": "El código ya existe.",
			"naceVazio": "No seleccionó ningún código.",
			"tipoDonativovazio": "No has seleccionado un tipo de donación",
			"niftipoDonativoAlredyExists": "Ya existe un número de contribuyente con el tipo de donación seleccionado",
			"diplomavazio": "No seleccionó un título",
			"nIdentificacaovazio": "No ingresó el número de identificación de la obra.",
			"datavazia": "La fecha no puede estar vacía.",
			"regiaovazio": "Debes seleccionar una región.",
			"caevazio": "Debe seleccionar un código CAE.",
			"regeiaocaeAlredyExists": "Ya existe una región con el código CAE.",
			"tipovazio": "Debes seleccionar un Tipo.",
			"numProjectovazio": "Debes indicar un número de proyecto/código de incentivo.",
			"rendimentovalido": "Debes ingresar un ingreso.",
			"tvalido": "El número de años debe ser mayor que cero.",
			"freguesiavazio": "Debes indicar un código de parroquia.",
			"designacaovazio": "La designación no puede estar vacía.",
			"montantevazio": "La cantidad de plusvalía debe ser mayor que cero.",
			"prejuizoAlredyExists": "El año indicado ya tiene pérdida fiscal atribuida.",
			"dadosguardados": "¡Datos guardados exitosamente!",
			"registosdel": "Model22 eliminado con éxito.",
			"temDeGuardar": "Debes guardar los datos para poder crear el archivo.",
			"avisoAnexos": "¿Está seguro de que desea eliminar el archivo adjunto <strong>\"{{attachment}}\"</strong>?",
			"mod22calculado": "modelo calculado",
			"containicialAlredyExists": "¡La cuenta inicial ya está configurada!",
			"derramaErrorMessage": "Derrame Municipal.",
			"anoAlredyExists": "El año indicado en el desglose ya existe",
			"anoVazio": "Debes indicar el año de inversión.",
			"prejEmpty": "El daño no puede ser nulo o 0.",
			"cleanValuesInfo": "¿Quieres cancelar los valores ingresados ​​manualmente?",
			"cleanValuesTooltip": "Cancela los valores ingresados ​​manualmente para que pueda comenzar el proceso nuevamente",
			"anoDedInv": "El año de la deducción no tiene que ser mayor que el año de la pérdida fiscal.",
			"servicoAT": {
				"validarAtSuccess": "Declaración validada con éxito",
				"validarAtFailed": "Error al validar la declaración en AT",
				"submeterAtSuccess": "Declaración enviada exitosamente",
				"submeterAtFailed": "Error al presentar la declaración en AT"
			},
			"modalSubmeterAT": {
				"title": "Confirmar enviando nuevamente la declaración ignorando las advertencias",
				"message": "La declaración solo presenta advertencias, ¿pretende enviar nuevamente la declaración ignorando las advertencias?"
			},
			"contasdef": {
				"reposicaocomsucesso": "Las definiciones de cuenta para los campos fueron respuestas.",
				"contascopiadascomsucesso": "Las definiciones de cuenta para los campos se han copiado correctamente en las empresas seleccionadas."
			}
		},
		"errors": {
			"campo346": "¡Material imponible negativo!"
		},
		"prompt": {
			"exitTitle": "<i class='fa fa-triángulo de exclamación-advertencia de texto' estilo='color: naranja;'",
			"exitMessage": "Al hacer clic en Aceptar, saldrá sin guardar y se eliminarán todos los cambios que haya realizado."
		}
	},
	"modelo25": {
		"title": "Modelo 25",
		"config": {
			"modalTitle": "Configuración: cuentas para donaciones recibidas",
			"titleTableDonativosNumerarios": "Donaciones en efectivo: cuentas de ingresos/patrimonio",
			"titleTableDonativosEspecie": "Donaciones en especie — Cuentas de ingresos/patrimonio",
			"contaDe": "cuenta",
			"contaAte": "contar hasta",
			"alerts": {
				"success": "¡Configuración guardada exitosamente!",
				"error": "¡Ha ocurrido un problema!",
				"hasEditData": "Nota: ¡Aún quedan datos por guardar!"
			}
		},
		"groups": {
			"q0": {
				"title": "Introducción",
				"p1": "Cuadro 00",
				"p2": "Donaciones recibidas",
				"p3": "En el ámbito de las obligaciones accesorias de las entidades beneficiarias de donaciones, este modelo sirve para dar cumplimiento a lo dispuesto legalmente en la letra c) del apartado 1 del art.",
				"p4": "El cumplimiento de esta obligación tributaria deberá completarse completando y enviando este formulario por transmisión electrónica, antes del final del mes de febrero de cada año, referido a las donaciones recibidas en el año anterior."
			},
			"q1": {
				"title": "Número de identificación fiscal del declarante",
				"campoNifDeclarante": "Número de identificación fiscal del declarante",
				"campoNomeDeclarante": "Nombre del contribuyente"
			},
			"q2": {
				"title": "Años de donaciones"
			},
			"q3": {
				"title": "Código del servicio financiero de la sede o domicilio",
				"codDeServico": "Código de servicio financiero"
			},
			"q4": {
				"title": "Tipo de declaración",
				"radioTipoDeclaracao": {
					"primeira": "Primero",
					"sub": "Reemplazo"
				}
			},
			"q5": {
				"title": "Listado de entidades donantes y donaciones",
				"dataGridRegistoList": {
					"colunas": {
						"entidade": "entidad donante",
						"codigoDonativo": "código de donación",
						"valorNum": "Valor de la donación en efectivo",
						"valorEsp": "Valor de la donación en especie",
						"donativoStr": "Tipo de donación",
						"linhas": {
							"periodo": "Período",
							"nDiario": "A diario",
							"nDocInterno": "Doc no.",
							"nConta": "Nro. de cuenta",
							"nomePOC": "Nombre de cuenta",
							"valor": "Valor"
						}
					}
				}
			}
		},
		"errors": {
			"errorHeader": "El modelo actual 25 tiene los siguientes errores.",
			"errorCodigoDonMensagem": "Error en el campo del código de donación de la entidad {{entidade}}",
			"errorCodigoDonRequired": "El campo es obligatorio",
			"errorCodigoDonName": "código de donación",
			"errorNoDataList": "*Es obligatorio contener datos para crear el archivo.",
			"errorValoresMensagem": "Error en uno de los campos de valor de la entidad {{entidade}}",
			"errorValoresRequired": "Al menos uno de los campos es obligatorio.",
			"errorDadosInseridos": "Hay al menos un error en los datos insertados en la tabla {{datagrid}}",
			"errorContasOverlapping": "Hay una superposición entre las 'cuentas de' y las 'cuentas de' en las cuadrículas",
			"errorOnlyDigitsAllowed": "Solo se permiten dígitos como valores en los campos 'cuenta desde' y 'cuenta hasta'"
		},
		"helpers": {
			"naoExistemContasConfiguradas": "No hay cuentas configuradas para la carga de datos.",
			"clickConfig": "<a>Haga clic aquí para configurar.</a>"
		},
		"servicoAT": {
			"validarAtSuccess": "Declaración validada con éxito",
			"validarAtFailed": "Error al validar la declaración en AT",
			"submeterAtSuccess": "Declaración enviada exitosamente",
			"submeterAtFailed": "Error al presentar la declaración en AT"
		},
		"global": {
			"btns": {
				"btnRefresh": "Para actualizar",
				"btnCriarFicheiro": "Crear archivo",
				"btnSubmeterAt": "Enviar AT",
				"btnValidarAt": "Validar en AT",
				"btnConfigWS": "Configuración de WS"
			}
		},
		"modal": {
			"configws": {
				"title": "Configuración del servicio web",
				"username": "Nombre de usuario del colaborador",
				"password": "Contraseña del contribuyente",
				"servicoInvCC": "Servicio invocado por Contador Público (CC)",
				"ccPowers": "CC con plenas facultades declarativas para Contrib.",
				"usernameCC": "nombre de usuario CC",
				"passwordCC": "contraseña CC",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> El servicio ya está configurado.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> El servicio no está configurado.",
				"configsaved": "Configuración guardada exitosamente."
			}
		}
	},
	"modelo30": {
		"title": "Modelo 30",
		"groups": {
			"q1": {
				"title": "Número de identificación fiscal",
				"c01": "Número de identificación fiscal de la entidad declarante"
			},
			"q2": {
				"title": "Número de identificación fiscal del contador público / Impedimento justo",
				"c02": "Número de identificación fiscal del contador certificado",
				"casoJustoImpedimento": "Si hubiera impedimento justo (artículo 124 del DL nº 452/99, de 5 de noviembre), indique:",
				"c02a": "Hecho que determinó el justo impedimento",
				"c02b": "Fecha de ocurrencia del hecho"
			},
			"q3": {
				"title": "Año/Mes"
			},
			"q4": {
				"title": "Código del servicio de finanzas de la sede o domicilio fiscal",
				"c04": "Código del servicio de finanzas de la sede o domicilio fiscal"
			},
			"q5": {
				"title": "Tipo de declaración",
				"c05": "Datos de declaración/tipo de declaración",
				"primeira": "Primero",
				"sub": "Reemplazo"
			},
			"q6": {
				"title": "Resumen de cantidades retenidas",
				"c06": "Dividendos o Utilidades Derivadas de Participaciones",
				"c07": "Intereses o Rentas Derivadas de la Inversión de Capital",
				"c08": "Regalías",
				"c09": "Trabajo Dependiente",
				"c10": "Trabajo Independiente",
				"c11": "Comisiones",
				"c12": "Edificios",
				"c13": "Pensiones",
				"c14": "Provisiones de servicio",
				"c40": "Retribuciones públicas",
				"c41": "Pensiones Públicas"
			},
			"q7": {
				"title": "Lista de recibos de pago",
				"dataGrid": {
					"nGuia": "Guía de pago no.",
					"valorTotal": "Valor total de la guía."
				}
			},
			"q8": {
				"title": "Lista de beneficiarios de ingresos",
				"dataGrid": {
					"nif": "NIF portugués",
					"nifPais": "Residencia de campo nif",
					"codPais": "Código de país de residencia",
					"partCapD": "Parte.",
					"partCapS": "Parte.",
					"rendTipo": "Producir",
					"rendValor": "Producir",
					"tribCod": "tribu.",
					"tribTaxa": "tribu.",
					"guia": "número de guía de pago",
					"montante": "cantidad de imp.",
					"nifEntidade": "Nif ent.",
					"legends": {
						"existeLinhasParaMesmoContribuinte": "Hay varias líneas para el mismo contribuyente"
					}
				}
			}
		},
		"verificarLancamentos": {
			"title": "Consulta posibles lanzamientos",
			"contribuintesNaoPTComRetencao": "Contribuyentes no PT con retención",
			"contribuintesNaoPTSemRetencao": "Contribuyentes no PT sin retención",
			"mensagens": {
				"success": "Versiones aplicadas con éxito en la tabla 8",
				"semDados": "No se encontraron registros de posibles lanzamientos."
			}
		},
		"errors": {
			"errorHeader": "El modelo 30 actual tiene los siguientes errores.",
			"q08MontandeSemGuias": "¡Hay líneas con valor retenido donde no se completó el número de guía!",
			"q08LinhasDuplicadas": "Hay líneas con el mismo colaborador, cuando se genere el archivo se agruparán.",
			"q08": "Tabla 8",
			"q08WithoutData": "No está debidamente cumplimentado.",
			"validarFailed": "Error al validar la declaración",
			"linhaSemNif": "La línea seleccionada no contiene NIF para editar",
			"pais": "País",
			"paisMessage": "El código de país debe contener 3 dígitos.",
			"sf": "Servicio de finanzas",
			"sfMessage": "Debes indicar el código del servicio financiero"
		},
		"servicoAT": {
			"validarAtSuccess": "Declaración validada con éxito",
			"validarAtFailed": "Error al validar la declaración en AT",
			"submeterAtSuccess": "Declaración enviada exitosamente",
			"submeterAtFailed": "Error al presentar la declaración en AT"
		},
		"btns": {
			"verificarLancamentos": "Consulta posibles lanzamientos",
			"btnRepor": "Cargar datos por defecto",
			"btnRepFinancas": "Departamento de Finanzas"
		}
	},
	"modelo39": {
		"title": "Modelo 39",
		"initModal": {
			"message": "¿Quiere generar el archivo Modelo 39 con datos de procesamiento de salarios para el año '{{year}}' del tipo de renta de capital?"
		},
		"groups": {
			"q0": {
				"title": "Comenzar",
				"p2": "Indicaciones generales",
				"p3": "El modelo de declaración nº 39 es obligatorio para las entidades deudoras y las entidades que pagan o ponen a disposición de sus respectivos titulares personas físicas residentes en territorio portugués y que no se benefician de la exención, exención de retención o reducción fiscal, rentas a las que se refiere el artículo 71 de del Código del Impuesto sobre la Renta de las Personas Físicas o cualquier renta sujeta a retención definitiva en la fuente.",
				"p4": "No se requiere la identificación de los contribuyentes cuyos ingresos totales, para todos los códigos, sean iguales o inferiores a 25,00 €."
			},
			"q1": {
				"title": "Número de identificación fiscal del declarante",
				"campoNifDeclarante": "Número de identificación fiscal"
			},
			"q2": {
				"title": "Número de identificación fiscal CC / Impedimento justo",
				"q02C02": "Número de identificación fiscal",
				"q02Caso": "Si existió impedimento justo (artículo 12-A del DL n° 452/99, de 5 de noviembre), indicar:",
				"q02C06": "Hecho que determinó el justo impedimento",
				"q02C07": "Fecha de ocurrencia del hecho",
				"q02C08": "Fecha de cese del evento"
			},
			"q4": {
				"title": "Código del servicio financiero de la sede o domicilio",
				"q04C04": "Código de servicio financiero"
			},
			"q5": {
				"title": "Tipo de declaración",
				"radioTipoDeclaracao": {
					"primeira": "Primero",
					"sub": "Reemplazo"
				}
			},
			"q6": {
				"title": "Lista de titulares de ingresos",
				"dataGridRegistoList": {
					"colunas": {
						"nif": "6.1 - NIF del Titular de la Renta",
						"codRend": "6.2 - Código de Ingreso",
						"rendimento": "6.3 - Cantidad de Ingresos",
						"retido": "6.4 - Cantidad retenida por el IRS",
						"nifEmitente": "6.5 - NIF de Entidad Emisora"
					},
					"extras": {
						"ajuda": "Consultar ayuda código ingreso",
						"ajudaTitle": "Ayuda con el código de ingresos",
						"cod": "Código"
					}
				}
			}
		},
		"codigosJustoImpedimento": {
			"01": "Muerte del cónyuge no separado de personas y bienes, de una persona con la que conviva en condiciones análogas a las de sus cónyuges, o de un pariente o asimilado en el 1.º grado de la línea recta",
			"02": "Muerte de otro familiar o persona relacionada en línea directa o en 2º grado de línea colateral",
			"03": "Enfermedad u hospitalización grave y repentina del contador, que le haga absolutamente imposible cumplir con sus obligaciones, o situaciones de parto o asistencia urgente e imprescindible a su cónyuge o persona que viva en unión civil o economía común y a un familiar o asimilado en el 1er grado de la recta, en caso de enfermedad o accidente de estos",
			"04": "Situaciones de crianza"
		},
		"codigosRendimentos": {
			"10": {
				"1001": "Rentas pagadas por fondos de ahorro para el retiro que no se benefician de exclusión alguna - párrafo 5 del artículo 21 del Estatuto de Beneficios Tributarios (1ª parte)."
			},
			"11": {
				"1101": "Rentas procedentes de participaciones en fondos de capital riesgo, fondos de inversión inmobiliaria o participaciones en sociedades de inversión inmobiliaria -recursos forestales- y en fondos de inversión inmobiliaria de rehabilitación urbana -artículos 23, 24 y 71 del Estatuto de Beneficios Fiscales-."
			},
			"12": {
				"1201": "Régimen Transitorio (antes del 1 de enero de 1991 y después de esta fecha hasta el 31 de diciembre de 1994) - Diferencia positiva entre las cantidades pagadas en concepto de rescate, anticipo o vencimiento de seguros y operaciones en el sector \"Vida\" y regímenes complementarios que se benefician de la exclusión de tributación de todos los ingresos correspondientes a contratos celebrados antes del 1 de enero de 1991 y a contratos celebrados entre el 1 de enero de 1991 y el 31 de diciembre"
			},
			"13": {
				"1301": "Régimen Transitorio (1 de enero de 1991 a 31 de diciembre de 1994) - Diferencia positiva entre las cantidades pagadas en concepto de rescate, anticipo o vencimiento de seguros y operaciones en el sector \"Vida\" y los regímenes complementarios que se benefician de la exclusión de tributación del 1/2 - el párrafo a) del apartado 3 del artículo 5 del Código IRS (redacción del Decreto-Ley n."
			},
			"14": {
				"1401": "Régimen Transitorio (1 de enero de 1995 al 31 de diciembre de 2000) - Diferencia positiva entre los montos pagados en concepto de rescate, anticipo o vencimiento de seguros y operaciones en el sector \"Vida\" y regímenes complementarios que se benefician de la exclusión del 2/5 - párrafo a) del nº 3 del artículo 5 del Código IRS (modificado por la Ley nº 39-B/94, de"
			},
			"15": {
				"1501": "Régimen Transitorio (1 de enero de 1995 al 31 de diciembre de 2000) - Diferencia positiva entre los montos pagados en concepto de rescate, anticipo o vencimiento de seguros y operaciones en el sector \"Vida\" y regímenes complementarios que se benefician de la exclusión del 4/5 - inciso b) del nº 3 del artículo 5 del Código IRS (modificado por la Ley nº 39-B/1994, de"
			},
			"16": {
				"1601": "Régimen Transitorio (Planes celebrados hasta el 31.12.2005) - Las cantidades pagadas por los fondos de ahorro para el retiro, PPE y PPR/E que se benefician de la exclusión del 4/5 - artículo 21, n° 3, inciso b) n° 1 del Impuesto. Estatuto de Beneficios, de conformidad con lo dispuesto en el artículo 55, n° 3 de la Ley núm."
			},
			"17": {
				"1701": "Rentas a que se refieren los códigos 01, 03, 19 a 31 y 33 cuando se paguen o se pongan a disposición en cuentas abiertas a nombre de uno o más titulares pero por cuenta de terceros no identificados (excepto cuando se identifique al beneficiario efectivo) - párrafo a) del párrafo 12 del artículo 71 del Código IRS."
			},
			"18": {
				"1801": "Rentas pagadas o puestas a disposición de sus respectivos titulares, residentes en territorio portugués, adeudadas por entidades no residentes sin establecimiento permanente en territorio portugués y que estén domiciliadas en un país, territorio o región sujetos a un régimen fiscal claramente más favorable, a través de entidades que sean mandatados por deudores o tenedores o actúen en nombre de uno u otro - inciso c) del n° 12 del artículo 71 del Código IRS (anterior n° 13 del mismo artículo)."
			},
			"19": {
				"1901": "Intereses y otras formas de remuneración derivadas de contratos de préstamo, apertura de crédito, reporto y otros que prevean, a título oneroso, la disponibilidad temporal de dinero u otras cosas fungibles - inciso a) del párrafo 2 del artículo 5 del Código IRS - aplicable a 2015 y años posteriores."
			},
			"20": {
				"2001": "Ingresos por contratos de cesión temporal, cuando no sean obtenidos por el titular original, de derechos de propiedad intelectual e industrial, o por el suministro de información basada en la experiencia adquirida en el sector industrial, comercial o científico, así como los derivados de asistencia técnica - inciso m ) del No. 2, artículo 5 del Código IRS - aplicable a 2015 y años siguientes."
			},
			"21": {
				"2101": "Las rentas derivadas del uso o concesión de uso de equipos agrícolas industriales, comerciales o científicos, cuando no constituyan rentas de la propiedad, así como las rentas provenientes del suministro, esporádico o continuo, de equipos y redes de computación, incluida la transmisión de datos. o provisión de capacidad informática instalada en cualquiera de sus formas posibles - inciso n) del párrafo 2, artículo 5 del Código IRS - aplicable a 2015 y años siguientes."
			},
			"22": {
				"2201": "Saldos de intereses calculados en un contrato o contabilizados en una cuenta corriente - incisos f) y o), párrafo 2 del artículo 5 del Código IRS - aplicables a 2015 y años siguientes."
			},
			"23": {
				"2301": "Intereses, por demora o retraso en el pago, con excepción de los adeudados al Estado y otras entidades públicas - inciso g) del párrafo 2 del artículo 5 del Código IRS - aplicables a 2015 y años siguientes."
			},
			"24": {
				"2401": "Ganancias provenientes de operaciones de swap de tasas de interés - párrafo q) del párrafo 2 del artículo 5 del Código IRS - aplicable a 2015 y años posteriores."
			},
			"25": {
				"2501": "Remuneración por certificados que garantizan al titular el derecho a recibir un valor mínimo superior al valor de suscripción - inciso r) del apartado 2 del artículo 5 del Código IRS - aplicable a 2015 y siguientes."
			},
			"26": {
				"2601": "Compensación destinada a compensar pérdidas en ingresos de la categoría E - párrafo s) del párrafo 2 del artículo 5 del Código IRS - aplicable a 2015 y años posteriores."
			},
			"27": {
				"2701": "Cantidades pagadas o puestas a disposición del contribuyente por estructuras fiduciarias, cuando dichas cantidades no estén asociadas a su liquidación, revocación o extinción, y no hayan sido ya gravadas - inciso t) del párrafo 2 del artículo 5 del Código del SII - aplicable a 2015 y años siguientes."
			},
			"28": {
				"2801": "Las rentas distribuidas provenientes de participaciones en fondos de inversión de valores o participaciones en sociedades de inversión de valores a las que les resulta aplicable el régimen previsto en el inciso i) del inciso a) del n° 1 del artículo 22-A del Estatuto de Beneficios Tributarios - aplicable a partir del 1. Julio de 2015."
			},
			"29": {
				"2901": "Rentas distribuidas procedentes de participaciones en fondos de inversión inmobiliaria o participaciones en sociedades de inversión inmobiliaria a las que se les aplica el régimen previsto en el apartado i) del apartado a) del apartado 1 y en el apartado 13 del artículo º-A del Estatuto de Beneficios Fiscales. -- aplicable a partir del 1 de julio de 2015."
			},
			"30": {
				"3001": "Rescate y liquidación de participaciones en fondos de inversión en valores o acciones en sociedades de inversión en valores, a las que se aplica el régimen previsto en el inciso b) del apartado 1 del artículo 22-A del Estatuto de Beneficios Fiscales - aplicable a partir del 1 de julio de 2015."
			},
			"31": {
				"3101": "Rescate y liquidación de participaciones en fondos de inversión inmobiliaria o participaciones en sociedades de inversión inmobiliaria, a las que se aplica el régimen previsto en el inciso b) del apartado 1 del artículo 22-A del Estatuto de Beneficios Fiscales - aplicable a partir del 1 de julio de 2015 ."
			},
			"32": {
				"3201": "Rentas provenientes de terrenos baldíos - párrafo 4 del artículo 59 del Estatuto de Beneficios Tributarios."
			},
			"33": {
				"3301": "Otros ingresos derivados de la simple inversión de capital no incluidos en los párrafos anteriores - párrafo p) del párrafo 2 del artículo 5 del Código IRS - aplicables a 2015 y años siguientes."
			},
			"34": {
				"3401": "Utilidades brutas puestas a disposición de los contribuyentes que posean participación accionaria en empresas que cumplan con la condición establecida en el artículo 35 del Código de Sociedades Comerciales, a favor de las cuales realizaron aportes de capital en efectivo (artículo 43-B del Estatuto de Beneficios Tributarios)."
			},
			"35": {
				"3501": "Ingresos distribuidos en el ámbito de la gestión de recursos forestales por las entidades de gestión forestal (EGF) y unidades de gestión forestal (UGF) - apartados 2 y 14 del artículo 59.º-G del Estatuto de Beneficios Fiscales."
			},
			"desc": "Ver ayuda para más detalles",
			"01": {
				"0101": "Utilidades y reservas puestas a disposición de los socios o tenedores y anticipos a cuenta de utilidades adeudadas por entidades residentes (incluye dividendos) - inciso h) del párrafo 2 del artículo 5 del Código IRS, con excepción de las utilidades identificadas con el código 34.",
				"0102": "Las rentas resultantes de la participación califican como inversión de capital (aplicable a 2013 y años anteriores).",
				"0103": "El valor atribuido a los asociados en la amortización de acciones sin reducción de capital - inciso i) del párrafo 2 del artículo 5 del Código IRS.",
				"0104": "Los ingresos obtenidos por el socio en la asociación de participación y en la asociación de cuota, así como, en esta última, los ingresos a que se refieren los incisos h) e i) del n° 1 del artículo 5 del Código IRS obtenidos por el socio. después de deducir el beneficio adeudado al miembro."
			},
			"02": {
				"0201": "Rentas de valores pagados o puestos a disposición de sus respectivos tenedores, residentes en territorio portugués, adeudados por entidades no domiciliadas en territorio portugués a las que se puede atribuir el pago, a través de entidades que están mandatadas por deudores o tenedores o actúan por cuenta de uno u otro - párrafo b) del párrafo 1 del artículo 71 del Código IRS."
			},
			"03": {
				"0301": "Intereses y otras formas de remuneración sobre depósitos corrientes o a plazo, así como certificados de depósito y cuentas de valores con garantía de precio u otras operaciones similares o similares - párrafo b) del párrafo 2 del artículo 5 del Código IRS.",
				"0302": "Primas de intereses, amortización o reembolso y otras remuneraciones sobre títulos de deuda, bonos, títulos de participación, certificados de consignación, bonos en efectivo u otros valores similares y otros instrumentos financieros de inversión - inciso c) del párrafo 2 del artículo 5 del Código del IRS.",
				"0303": "Intereses y otras formas de remuneración sobre suministros, asignaciones o anticipos de capital hechos por los socios a la empresa - párrafo d) del párrafo 2 del artículo 5 del Código IRS.",
				"0304": "Intereses y otras formas de remuneración debidas por el hecho de que los socios no retiran las ganancias o remuneraciones puestas a su disposición - párrafo e) del párrafo 2 del artículo 5 del Código IRS.",
				"0305": "Ingresos por operaciones de reporto y cesiones de crédito - aplicables a 2014 y años anteriores.",
				"0306": "Ganancias provenientes de operaciones swap u operaciones forward de divisas - aplicables al año 2014 y años anteriores."
			},
			"04": {
				"0401": "Diferencia positiva entre las cantidades pagadas en concepto de rescate, anticipo o vencimiento de seguros y operaciones en el sector \"Vida\" y otros planes complementarios que no se benefician de la exclusión - párrafo 3 del artículo 5 del Código IRS.",
				"0402": "Ingresos de ahorros a largo plazo o planes de ahorro en acciones que no se benefician de la exclusión - párrafo 3 del artículo 5 del Código del IRS (por referencia a los artículos 20-A y 26 del Estatuto de Beneficios Fiscales) y artículo 25 del Estatuto de Beneficios Fiscales ."
			},
			"05": {
				"0501": "Diferencia positiva entre las cantidades pagadas en concepto de rescate, anticipo o vencimiento de seguros y operaciones en el sector \"Vida\" y otros regímenes complementarios que se benefician de la exclusión de 1/5 de la tributación - párrafo a) del apartado 3 del artículo 5.º del Código del IRS.",
				"0502": "Ingresos provenientes de ahorros a largo plazo o planes de ahorro en acciones que se benefician de la exclusión de impuestos de 1/5 - párrafo a) del párrafo 3 del artículo 5 del Código IRS (por referencia a los artículos 20-A y 26 del Estatuto de Beneficios Tributarios) y el artículo 25 del Estatuto de Beneficios Tributarios."
			},
			"06": {
				"0601": "Diferencia positiva entre los montos pagados en concepto de rescate, anticipo o vencimiento de seguros y operaciones en el sector \"Vida\" y esquemas complementarios que se benefician de la exclusión de 3/5 de tributación - párrafo b) del párrafo 3 del artículo 5. del Código IRS .",
				"0602": "Ingresos provenientes de ahorros a largo plazo o planes de ahorro en acciones que se benefician de la exclusión de impuestos de 3/5 - párrafo b) del párrafo 3 del artículo 5 del Código IRS (por referencia a los artículos 20-A y 26 del Estatuto de Beneficios Tributarios) y el artículo 25 del Estatuto de Beneficios Tributarios."
			},
			"07": {
				"0701": "Rentas pagadas por fondos de ahorro para el retiro, incluidas las realizadas a plazos, durante un período superior a diez años, que se benefician de una exclusión de 3/5 - inciso b) del párrafo 3 del artículo 21 del Estatuto de Beneficios Fiscales."
			},
			"08": {
				"0801": "Rentas pagadas por fondos de ahorro para el retiro que se benefician de la exclusión del impuesto sobre la renta de 1/5 - n° 5 del artículo 21 del Estatuto de Beneficios Tributarios y el inciso a) del n° 3 del artículo 5 del Código del IRS."
			},
			"09": {
				"0901": "Rentas pagadas por fondos de ahorro para el retiro que se benefician de la exclusión del impuesto sobre la renta 3/5 - n° 5 del artículo 21 del Estatuto de Beneficios Tributarios y el inciso b) del n° 3 del artículo 5 del Código del IRS."
			}
		},
		"errors": {
			"errorNoDataList": "*Es obligatorio contener datos para crear el archivo.",
			"errorHeader": "El modelo actual 39 tiene los siguientes errores."
		},
		"servicoAT": {
			"validarAtSuccess": "Declaración validada con éxito",
			"validarAtFailed": "Error al validar la declaración en AT",
			"submeterAtSuccess": "Declaración enviada exitosamente",
			"submeterAtFailed": "Error al presentar la declaración en AT"
		}
	},
	"moeda": {
		"title_new": "Nueva moneda",
		"title_detail": "Moneda {{id}}",
		"title_edit": "Moneda {{id}}",
		"title_plural": "monedas",
		"pesquisa": "buscar",
		"saved": "Moneda {{id}}, guardada correctamente.",
		"error": "Moneda {{id}}, no se puede guardar.",
		"errorOnNew": "No se puede guardar la nueva moneda.",
		"deleted": "Moneda {{id}}, eliminada correctamente.",
		"fields": {
			"codMoeda": "Código de moneda",
			"nomeMoeda": "Nombre de la moneda",
			"abreviaturaMoeda": "Abreviatura de moneda",
			"extenso1Unidade": "Unidad completa (singular)",
			"extensoNUnidades": "Unidad completa (plural)",
			"extensoCentimos": "Centavos en su totalidad",
			"nDecimais": "Número de decimales",
			"dataReferencia": "Fecha de referencia",
			"exchange": "Intercambio"
		},
		"tabs": {
			"activeExchange": "Cambio de moneda activo",
			"otherExchange": "Otros cambios de moneda"
		},
		"titles": {
			"cambioMoedaEmpresaEmMoedaEstrangeira": "Cambio moneda de empresa por moneda extranjera",
			"cambioMoedaEstrangeiraEmMoedaEmpresa": "Cambio de moneda extranjera por moneda de empresa."
		},
		"errors": {
			"moedaNaoExiste": "La moneda no existe.",
			"naoPodeAlterarMoedaSemRemoverLinhas": "No puede cambiar la moneda sin antes eliminar todas las líneas del documento comercial."
		},
		"chooseTemplate": "Elige plantilla",
		"invalidTemplate": "El país elegido no contiene un modelo de moneda válido.",
		"editCambio": "Editar cambio de moneda {{date}}",
		"choosecounty": "Copiar plantilla",
		"updateCambio": "Actualizar tipo de cambio"
	},
	"moedaNormasIso": {
		"title": "Normas monetarias ISO",
		"pesquisa": "Buscar estándares de moneda ISO",
		"fields": {
			"codigo": "Código",
			"paisNomeMoeda": "País (moneda)",
			"nomeMoeda": "Nombre de la moneda",
			"extensoUnidade": "Unidad completa (singular)",
			"extensoNUnidades": "Unidad completa (plural)",
			"extensoSubUnidade": "Unidad completa (sub)"
		}
	},
	"moradasFaturacao": {
		"title_detail": "Dirección de facturación {{id}}",
		"title_new": "Nueva dirección de facturación",
		"title_plural": "Direcciones de facturación",
		"pesquisa": "buscar",
		"saved": "Dirección de facturación {{id}}, guardada correctamente",
		"error": "Dirección de facturación {{id}}, no se puede guardar.",
		"fields": {
			"nConta": "No.",
			"nome": "Nombre",
			"rua": "Camino",
			"codPostal": "Código Postal",
			"localidade": "Ubicación",
			"nipc": "NIPC",
			"id": "Índice",
			"observacoes": "Nota",
			"morada": "Familiar",
			"codPais": "Código del país",
			"moralId": "identificación moral.",
			"nIdAltern": "Identificación alternativa"
		}
	},
	"morals": {
		"title_detail": "Dirección alternativa",
		"title_new": "Nueva dirección alternativa",
		"title_plural": "Direcciones alternativas",
		"pesquisa": "buscar",
		"saved": "Dirección alternativa, guardada exitosamente.",
		"error": "Dirección alternativa, no se puede guardar.",
		"deleted": "Dirección alternativa, eliminada con éxito.",
		"deleteModalText": "¿Eliminar registro \"{{name}}\"?",
		"fields": {
			"nConta": "Nro. de cuenta",
			"nome": "Nombre",
			"rua": "Camino",
			"cPost": "Código Postal",
			"localidade": "Ubicación",
			"observacoes": "Observaciones",
			"morada": "Familiar",
			"codPais": "Código del país",
			"desativado": "Desactivado",
			"contactoNome": "Nombre de contacto",
			"contactoTelefone": "Teléfono de contacto",
			"codigoPontoPickup": "Código del punto de recogida"
		}
	},
	"motivoContrato": {
		"data": {
			"semmotivo": "Sin motivo",
			"temporariamenteimpedidoprestarserviço": "Impedido temporalmente para prestar servicio",
			"impendaaccaojuizoapreciacaolicitudedespedimento": "En relación con qué acciones se deben emprender ante los tribunales para valorar la legalidad del despido",
			"situacaolicencasemretribuicao": "Situación de licencia no remunerada",
			"substituicaotrabalhadorportempoindeterminado": "Sustitución de un trabajador a tiempo completo que pasa a trabajar a tiempo parcial por tiempo indefinido",
			"cctividadessazonais": "Actividades estacionales",
			"acrescimoexcepcionalactividadeempresa": "Incremento excepcional de la actividad de la empresa",
			"execucaotarefaserviçodefinidonaoduradouro": "Ejecución de una tarea ocasional o servicio específico, definido con precisión y no duradero.",
			"execucaoprojectoactividadedefinidatemporaria": "Ejecución de una obra, proyecto u otra actividad definida y temporal",
			"lancamentonovaactividadeduraçãoincerta": "Lanzamiento de una nueva actividad de duración incierta, así como el inicio de funcionamiento de una empresa o establecimiento",
			"contratacaotrabalhadoresprimeiroemprego": "Contratación de trabajadores que buscan su primer empleo o parados de larga duración",
			"actividadecontinuidadeousemtermo": "Continuidad o actividad interminable",
			"outros": "Otros"
		}
	},
	"movimentosaberto": {
		"btn": {
			"sendMail": "Enviar correo electrónico",
			"processarexcel": "Procesar y exportar excel."
		},
		"modal": {
			"sendMail": {
				"title": "Enviar correo electrónico: {{nAccount}} - {{nameAccount}}",
				"fields": {
					"email": "Correo electrónico",
					"subject": "Sujeto",
					"body": "Texto"
				}
			}
		},
		"label": {
			"grupoConta": "grupo de cuentas",
			"showGrupoConta": "Mostrar grupo de cuentas",
			"movimentosEmAberto": "Movimientos abiertos {{type}}"
		}
	},
	"movimentospendentes": {
		"clientes": {
			"nConta": "Cuenta",
			"nome": "Nombre",
			"tipoDocumento": "Tipo de documento",
			"numeroDocumento": "Número de documento",
			"dataDoc": "Fecha del doctor.",
			"dataVencimento": "Fecha de expiración",
			"valor": "Valor del documento",
			"valorRecebido": "Cantidad ya recibida",
			"valorPorReceber": "Valor a recibir",
			"nif": "NIF",
			"nDocExterno": "Doc no."
		},
		"fornecedores": {
			"nConta": "Cuenta",
			"nome": "Nombre",
			"tipoDocumento": "Tipo de documento",
			"numeroDocumento": "Número de documento",
			"dataDoc": "Fecha del doctor.",
			"dataVencimento": "Fecha de expiración",
			"valor": "Valor del documento",
			"valorRecebido": "Cantidad ya pagada",
			"valorPorReceber": "Monto a pagar",
			"nif": "NIF",
			"nDocExterno": "Doc no."
		},
		"outrosDevedoresCredores": {
			"nConta": "Cuenta",
			"nome": "Nombre",
			"tipoDocumento": "Tipo de documento",
			"numeroDocumento": "Número de documento",
			"dataDoc": "Fecha del doctor.",
			"dataVencimento": "Fecha de expiración",
			"valor": "Valor del documento",
			"valorRecebido": "Cantidad ya pagada",
			"valorPorReceber": "Monto a pagar",
			"nif": "NIF",
			"nDocExterno": "Doc no."
		}
	},
	"nacionalidades": {
		"title_detail": "Nacionalidad {{id}}",
		"title_new": "Nueva Nacionalidad",
		"title_plural": "Nacionalidades",
		"pesquisa": "buscar",
		"saved": "Nacionalidad {{id}}, guardado exitosamente.",
		"error": "Nacionalidad {{id}}, no se puede guardar.",
		"deleted": "Nacionalidad {{id}}, eliminado exitosamente.",
		"fields": {
			"nNacionalidade": "Código.",
			"codNacionQPessoal": "Q. Código de Nacionalidad Personal",
			"nomeNacionQPessoal": "Nombre Nacionalidad P. Personal",
			"designacaoBreve": "Breve designación",
			"designacaoCompleta": "Designación completa"
		}
	},
	"naturezasContab": {
		"title_detail": "Naturaleza contable {{id}}",
		"title_new": "Nueva naturaleza contable",
		"title_plural": "Naturalezas contables",
		"pesquisa": "buscar",
		"saved": "Naturaleza contable {{id}}, guardado exitosamente.",
		"error": "Naturaleza contable {{id}}, no se puede guardar.",
		"deleted": "Naturaleza contable {{id}}, eliminada correctamente.",
		"fields": {
			"idGrContaCorrente": "Código de naturaleza contable.",
			"idGrContaCorrentePlaceholder": "código de naturaleza contable",
			"idSubGrContaCorrente": "Código de subnaturaleza contable.",
			"idSubGrContaCorrentePlaceholder": "código de subnaturaleza contable",
			"descricao": "Descripción",
			"descricaoPlaceholder": "descripción de la naturaleza contable",
			"radical": "Radical",
			"radicalPlaceholder": "radical de naturaleza contable ej.: 21111",
			"nDigitosSequencia": "Número de dígitos en la secuencia",
			"nDigitosSequenciaPlaceholder": "Número de secuencia de dígitos, por ejemplo: si radical=21111 y tiene una secuencia de 4 dígitos, entonces la nueva cuenta es 211110001",
			"tipo": "Tipo"
		}
	},
	"nifs": {
		"title_detail": "NIF {{id}}",
		"title_new": "Nuevo NIF",
		"title_plural": "Números de identificación fiscal",
		"pesquisa": "buscar",
		"saved": "NIF {{id}}, guardado con éxito",
		"error": "NIF {{id}}, no se puede guardar.",
		"deleted": "NIF {{id}}, eliminado con éxito",
		"fields": {
			"codPais": "País",
			"nContribuinte": "número de contribuyente",
			"codRet": "Tasa de retención",
			"tipoRetServNIF": "Entidad generalmente",
			"temCativo": "Tiene IVA cautivo",
			"taxaCativo": "Tasa de IVA cautiva"
		},
		"modelo30": {
			"title": "Modelo 30",
			"fields": {
				"nifPt": "NIF portugués",
				"nifEmitente": "NIF ent.",
				"partCapD": "Parte.",
				"partCapS": "Parte."
			}
		},
		"registar": "Registrar NIF",
		"registado": "NIF {{nif}} se registró correctamente",
		"naoregistado": "El NIF no está registrado",
		"desejaregistar": "¿Quieres registrar el NIF?"
	},
	"nivql": {
		"title_detail": "Nivel de calificación {{id}}",
		"title_new": "Nuevo nivel de calificación",
		"title_plural": "Niveles de calificación",
		"pesquisa": "buscar",
		"saved": "Nivel de calificación {{id}}, guardado exitosamente",
		"error": "Nivel de calificación {{id}}, no se puede guardar.",
		"deleted": "Nivel de calificación {{id}}, eliminado correctamente",
		"fields": {
			"nNivQualific": "Código de nivel de calificación",
			"designaBreve": "Breve designación",
			"designaComp": "Designación completa",
			"ruNivQualific": "Nivel de calificación"
		},
		"niveisqualificacao": {
			"0": "Sin definición",
			"1": "Marcos superiores",
			"2": "Marcos medianos",
			"3": "Supervisores, capataces, capitanes y jefes de equipo.",
			"4": "Profesionales altamente cualificados",
			"5": "Profesionales cualificados",
			"6": "Profesionales semicalificados (especializados)",
			"7": "Profesionales no cualificados (indiferenciados)",
			"8": "Pasantes, practicantes y aprendices"
		}
	},
	"noauthority": {
		"title": "¡Sin autorización!",
		"message": "Póngase en contacto con el administrador de su sistema para obtener más información."
	},
	"notificationcenter": {
		"title": "Notificaciones",
		"openAll": "Ver todo",
		"new": "Noticias",
		"old": "Anterior",
		"image": "Imagen",
		"newNotification": "Nuevo ({{count}} notificación)",
		"newNotifications": "Nuevo ({{count}} notificaciones)",
		"noNotifications": "No hay notificaciones",
		"categories": {
			"0": "Todo",
			"1": "Noticias",
			"2": "Mantenimiento programado",
			"all": "Todo",
			"news": "Noticias",
			"maintenance": "Mantenimiento programado"
		},
		"actions": {
			"readAll": "Marcar todo como leído"
		}
	},
	"obrigacoesAcessorias": {
		"title": "Obligaciones adicionales",
		"modal": {
			"configws": {
				"title": "Configuración del servicio web",
				"username": "Nombre de usuario del colaborador",
				"password": "Contraseña del contribuyente",
				"servicoInvCC": "Servicio invocado por contador certificado (CC)",
				"ccPowers": "CC con plenas facultades declarativas para Contrib.",
				"usernameCC": "nombre de usuario CC",
				"passwordCC": "contraseña CC",
				"configured": "<i class='fa fa-check' aria-hidden='true'></i> El servicio ya está configurado.",
				"notConfigured": "<i class='fa fa-exclamation-triangle' aria-hidden='true'></i> El servicio no está configurado.",
				"configsaved": "Configuración guardada exitosamente."
			}
		}
	},
	"officereporting": {
		"ano": "Año a analizar: {{valor}}",
		"mes": "Mes a analizar: {{nameMonth}}",
		"fields": {
			"pagina": "Mapas"
		},
		"messages": {
			"erroDownload": "¡Ocurrió un error al descargar el archivo!"
		}
	},
	"origemCondPagamento": {
		"pesquisa": "Orígenes de la condición de pago",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"tabela": "Mesa",
			"cliente": "Cliente"
		}
	},
	"origemContasRetencao": {
		"data": {
			"ambos": "Ambos",
			"porTerceiros": "Por terceros (Clientes)",
			"aTerceiros": "A terceros (Proveedores)"
		}
	},
	"origemDataVencimento": {
		"pesquisa": "Orígenes de la fecha de vencimiento",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"tabela": "Mesa",
			"condicaoPagamento": "Cond."
		}
	},
	"pagamentoimprimir": {
		"label": {
			"emailfornecedor": "Correo electrónico del proveedor",
			"enviar": "Mandar"
		},
		"email": {
			"required": "Para enviar, por favor rellene el campo \"<em>Correo electrónico del proveedor</em>\"",
			"emailEnviado": "Correo electrónico enviado a {{email}}",
			"emailErro": "Error al enviar el correo electrónico a {{email}}."
		},
		"placeholder": {
			"email": "\"correo@ejemplo.com\" o \"correo@ejemplo.com; correo2@ejemplo.com\""
		},
		"btn": {
			"downloadSEPA": "Descargar archivo SEPA"
		}
	},
	"pagamentos": {
		"title_new": "Nuevo pago",
		"title_detail": "Nuevo pago {{id}}",
		"title_plural": "Pagos",
		"pesquisa": "buscar",
		"saved": "Pago guardado exitosamente.",
		"error": "El pago no se puede guardar.",
		"fields": {
			"extPocCabID": "Identificación.",
			"numero": "Nro. de pago",
			"nome": "Proveedor",
			"nconta": "Cuenta",
			"stampUpdate": "Último.",
			"origem": "Origen del documento",
			"abreviaturaMoeda": "Acuñar",
			"nDocExterno": "Doc no."
		},
		"pagamento": {
			"nCaixaAux": "Método de pago",
			"nomeCaixaAux": "Nombre significa pago",
			"tipoCaixaAux": "Tipo de medio pago",
			"valorNCaixa": "Valor",
			"totalCaixa": "Entregado",
			"troco": "Cambiar",
			"fornecedor": "Proveedor",
			"data": "fecha de pago",
			"cab": {
				"extPocCabID": "ID de encabezado",
				"numero": "Número",
				"nConta": "Cuenta",
				"descricao": "Descripción",
				"nome": "Nombre",
				"nif": "NIF",
				"valor": "Valor",
				"valorPlaceHolder": "cantidad a pagar",
				"stampUpdate": "Cambiar fecha",
				"totalDescontos": "Descuentos completos",
				"totalPagar": "Total a pagar",
				"temRetencao": "Tiene retención",
				"totalRetencao": "Retención total",
				"abreviaturaMoeda": "Acuñar"
			},
			"linhas": {
				"summary": "Resumen",
				"nlanc": "identificación de línea",
				"tipoDocumento": "Tipo de documento",
				"numeroDocumento": "Documento",
				"nDocExterno": "Doc no.",
				"dataVencimento": "Fecha de expiración",
				"valor": "Valor",
				"valorPorPagar": "Monto a pagar",
				"percDesconto": "Descuento %",
				"valorDesconto": "Valor de descuento",
				"valorAPagar": "cantidad a pagar",
				"valorIVA": "valor del IVA",
				"montanteAReter": "Retención",
				"montanteRetido": "Valor de retención",
				"abreviaturaMoeda": "Acuñar",
				"cambio": "Intercambio",
				"cambioOrigem": "Tipo de cambio de origen",
				"cambioPagam": "Pago de cambio",
				"dataDoc": "Fecha del doctor.",
				"dataDocExterno": "Fecha del doctor.",
				"descricao": "Descripción",
				"valorDoc": "Valor del documento"
			},
			"disableRevoke": "El pago no se puede cancelar porque no se realizó en CentralGest Cloud",
			"pdf": "PDF",
			"revoke": "Cancelar",
			"summary": "Resumen",
			"totalPago": "Total pagado",
			"downloadSepa": "Descargar SEPA",
			"generateAndDownloadSepa": "Generar SEPA"
		},
		"info": {
			"anular": "¡Pago cancelado exitosamente!",
			"anularpagamento": "Cancelar pago",
			"temCertezaAnularpagamento": "¿Está seguro de que desea eliminar el pago?",
			"pagamentoPorMeioPagamSepa": "Pago realizado mediante pago SEPA.",
			"avisoAnularPagamSepa": "Tenga en cuenta que esto cancelará un pago realizado mediante pago SEPA y, al continuar, se eliminará el archivo de transferencia SEPA que puede contener otros pagos.",
			"semMovimentos": "¡Sin movimientos abiertos!",
			"vencido": "Atrasado",
			"naoVencido": "No caducado",
			"aPagar": "Para apagar"
		},
		"erros": {
			"notselected": "¡No se seleccionó ningún pago!",
			"anular": "¡Error al cancelar el pago!",
			"valorDescontoMaiorValor": "Valor de descuento superior al importe recibido",
			"percentagemDescontoMaior100": "El porcentaje de descuento no puede exceder el 100%.",
			"moedasdiferentesnosrecibos": "No es posible tener diferentes monedas en los pagos a contabilizar",
			"errodesconhecido": "Error desconocido",
			"invalidHeaderDate": "La fecha de pago no puede ser mayor a 1 año desde la fecha actual.",
			"valorUltrapassado": "Se superó el monto a distribuir",
			"faltaDistribuirValor": "Debes distribuir la totalidad del importe ingresado"
		},
		"text": {
			"autoDistribute": "Distribuir valor automáticamente",
			"amountToPay": "cantidad a pagar"
		},
		"list": {
			"gestaoFicheirosSepa": "Gestión de archivos SEPA",
			"notifyMessage": "¿Quiere notificar a los proveedores sobre los pagos seleccionados?",
			"notifyTitle": "Enviar notificaciones"
		},
		"saveModal": {
			"title": "Guardar pago",
			"paymentMean": "Método de pago",
			"paymentValue": "Valor del método de pago",
			"payInFull": "Pagar todo",
			"clearValue": "Borrar valor",
			"document": "Documento",
			"save": "Ahorrar",
			"saveCheck": "Guardar y ver PDF",
			"saveGenerateSEPA": "Guardar y crear SEPA",
			"saveGestaoSEPA": "Ahorra en Gestión SEPA",
			"erros": {
				"naoDefiniuMeioPagamento": "No has definido el método de pago.",
				"naoSatisfazValorDoc": "El valor entregado no satisface el valor del documento.",
				"trfSepaSoPodeTerUmMeio": "El pago \"Transferencia Bancaria\" mediante fichero SEPA sólo puede tener un método de pago definido.",
				"trfSepaMeioPagamentoNaoValido": "El método de pago definido no es del tipo \"Transferencia Bancaria\".",
				"trfSepaMeioPagamentoSemIban": "El método de pago no tiene definido un IBAN.",
				"trfSepaMeioPagamentoSemSwift": "El método de pago no tiene definido SWIFT."
			},
			"success": {
				"saveGestaoSepa": "Guardado en Gestión de archivos SEPA, puedes generarlo más tarde."
			}
		},
		"btn": {
			"pagaTudo": "paga todo",
			"limpaTudo": "Borrar todos los montos pagados",
			"distribuirValores": "Distribuir valor",
			"limpaValor": "Monto claro pagado"
		},
		"modal": {
			"pagamentoimprimir": {
				"label": {
					"emailfornecedor": "Correo electrónico del proveedor",
					"enviar": "para enviar"
				},
				"email": {
					"required": "Para enviar, por favor rellene el campo \"<em>Correo electrónico del proveedor</em>\"",
					"emailEnviado": "Correo electrónico enviado a {{email}}",
					"emailErro": "Error al enviar el correo electrónico a {{email}}."
				},
				"placeholder": {
					"email": "\"correo@ejemplo.com\" o \"correo@ejemplo.com; correo2@ejemplo.com\""
				},
				"btn": {
					"downloadSEPA": "Descargar archivo SEPA"
				}
			}
		},
		"modalDistribuir": {
			"tile": "Distribuye valor",
			"automatico": "Automático",
			"manual": "Manual",
			"tooltip": {
				"automatico": "Ordenados del más antiguo al más nuevo.",
				"manual": "Permite distribuir hasta alcanzar el valor indicado."
			}
		}
	},
	"paises": {
		"title_detail": "País {{id}}",
		"title_new": "Nuevo país",
		"title_plural": "Países",
		"pesquisa": "buscar",
		"saved": "País {{id}}, guardado exitosamente",
		"error": "País {{id}}, no se puede guardar.",
		"deleted": "País {{id}}, eliminado correctamente",
		"fields": {
			"codPais": "Código del país",
			"nomePais": "Nombre",
			"abrevFiscal": "Abreviatura fiscal",
			"abrevPais": "Abreviatura del país",
			"nomePaisPropriaLingua": "Nombre del país en su propio idioma.",
			"codMoeda": "Código de moneda",
			"codLingua": "código de idioma",
			"mercado": "Mercado",
			"codISO": "codigo internacional"
		},
		"mercado": {
			"data": {
				"intraComuni": "Intracomunitario",
				"extraComuni": "Comunidad adicional"
			}
		}
	},
	"paisesISO3166": {
		"title": "Países ISO 3166",
		"pesquisa": "Buscar países ISO 3166",
		"fields": {
			"codigo": "Código.",
			"designacaoPT": "Designación (PT)",
			"codigoAlpha2": "ISO3166 Alfa-2",
			"codigoAlpha3": "ISO3166 Alfa-3",
			"designacao": "Idioma del nombre"
		}
	},
	"partners": {
		"millennium": {
			"title": "Milenio",
			"erros": {
				"invalidTargetOrigin": "Se produjo un error al cargar la configuración.",
				"invalidUsernameOrPassword": "Por favor complete los campos de autenticación.",
				"invalidRecaptcha": "Marque la casilla de verificación para confirmar que no es una máquina.",
				"partnerAssociateInvalidToken": "Se produjo un error al intentar asociar su empresa.",
				"partnerAssociateUserNotFound": "La autenticación falló.",
				"noCompaniesWereFoundWithTheProvi": "No se encontró ninguna empresa con el NIF aportado.",
				"partnerIsActivePleaseSetUnactive": "Ya existe una asociación para esta empresa."
			}
		}
	},
	"pca": {
		"contabilidade": {
			"anos": "Años",
			"ficheiros": "Archivos",
			"periodo": "Período",
			"encerrado": "cerrado",
			"semFicheiros": "Sin archivos"
		},
		"empresagabinete": {
			"extratosconta": {
				"fields": {
					"nDiario": "Código.",
					"nomeDiario": "A diario",
					"nConta": "código de cliente",
					"nome": "Nombre del cliente",
					"periodo": "Período",
					"nDocumento": "Doc no.",
					"nDescritivo": "código descriptivo",
					"nomeDescritivo": "Nombre descriptivo",
					"nDocExterno": "Doc no.",
					"dataLancamento": "fecha de lanzamiento",
					"valorDebito": "Deuda",
					"valorCredito": "Crédito",
					"valor": "Valor",
					"dataDoc": "Fecha del doctor."
				}
			},
			"movimentosaberto": {
				"fields": {
					"tipoDocumento": "Tipo de documento.",
					"numeroDocumento": "Doc no.",
					"dataVencimento": "Fecha de expiración",
					"valorPorReceber": "Cantidad a recibir",
					"valorRecebido": "Cantidad recibida",
					"valorDesconto": "Valor de descuento",
					"valorIVA": "valor del IVA",
					"valor": "Valor",
					"nConta": "código de cliente",
					"nome": "Nombre del cliente",
					"nif": "Contribución nº."
				}
			}
		}
	},
	"perfilCategoriaAbono": {
		"title": "Asignaciones de categorías de perfiles",
		"fields": {
			"tipoArredondamento": "redondeo",
			"tipoRecolha": "Tipo de colección",
			"contribuiCalculoCustoPrecoH": "Contribuye al cálculo del coste precio/hora.",
			"codRem": "código remoto.",
			"codUnidade": "Unidad defectuosa",
			"tipoRendimento": "Tipo de ingreso del IRS",
			"contaMovDeb": "Provisión - Cuenta de débito",
			"contaMovCred": "Provisión - Cuenta de crédito",
			"contaEncDEB": "Cargos del empleador/empresa - Cuenta de débito",
			"contaEncCRE": "Cargos del empleador/empresa - Cuenta de crédito"
		},
		"crabdTable": {
			"fields": {
				"categoria": "Categoría",
				"empregado": "Ent.",
				"patronato": "Ent.",
				"desconto": "Descuento",
				"nomeCategoria": "Categoría",
				"temIncidenciaEmpregado": "Él tiene",
				"incidenciaEmpregado": "Incidencia",
				"temIncidenciaPatronato": "Él tiene",
				"incidenciaPatronato": "Incidencia",
				"nDesconto": "Código.",
				"nomeDesconto": "Descripción"
			}
		}
	},
	"perfilCategoriaDesconto": {
		"title": "Descuentos en categorías de perfiles",
		"fields": {
			"categoria": "Categoría",
			"tipoArredondamento": "redondeo",
			"tipoRecolha": "Tipo de colección",
			"contribuiCalculoCustoPrecoH": "Contribuye al cálculo del coste precio/hora.",
			"codRem": "código remoto",
			"codUnidade": "Unidad defectuosa",
			"tipoRendimento": "Tipo de ingreso",
			"contaMovDeb": "Descuento - Cuenta de débito",
			"contaMovCred": "Descuento - Cuenta de crédito",
			"contaEncDEB": "Cargos del empleador/empresa - Cuenta de débito",
			"contaEncCRE": "Cargos del empleador/empresa - Cuenta de crédito"
		},
		"crabdTable": {
			"fields": {
				"categoria": "Categoría",
				"empregado": "Ent.",
				"patronato": "Ent.",
				"desconto": "Descuento",
				"nomeCategoria": "Categoría",
				"temIncidenciaEmpregado": "Él tiene",
				"incidenciaEmpregado": "Incidencia",
				"temIncidenciaPatronato": "Él tiene",
				"incidenciaPatronato": "Incidencia",
				"nDesconto": "Código.",
				"nomeDesconto": "Descripción"
			}
		}
	},
	"periocidadeContratoTipo": {
		"data": {
			"sempre": "Siempre",
			"diario": "A diario",
			"semanal": "Semanalmente",
			"quinzenal": "Quincenal",
			"mensal": "Mensual",
			"bimensal": "Bimensual",
			"trimestral": "Trimestral",
			"semestral": "Semestral",
			"anual": "Anual"
		}
	},
	"periodos": {
		"title_detail": "Período contable {{id}}",
		"title_plural": "Períodos contables",
		"pesquisa": "buscar",
		"fields": {
			"periodo": "Período",
			"periodoPlaceholder": "Periodo contable",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del período contable"
		}
	},
	"periodosIVA": {
		"data": {
			"mensal": "Mensual",
			"trimestral": "Trimestral"
		}
	},
	"planoscontasalternativos": {
		"title_detail": "Plan de cuenta alternativo \"{{id}}\"",
		"title_new": "Nuevo plan de cuenta alternativo",
		"title_plural": "Planes de cuenta alternativos",
		"pesquisa": "buscar",
		"saved": "Plan de cuentas alternativo, guardado exitosamente.",
		"error": "El plan de cuenta alternativo no se puede guardar.",
		"deleted": "Plan de cuenta alternativo, eliminado exitosamente",
		"fields": {
			"nome": "Nombre",
			"tipoValidacao": "Tipo de validación",
			"contas": "Cuentas",
			"nconta": "Nro. de cuenta",
			"ncontaEdit": "Número de cuenta alternativo",
			"nomeEdit": "Descripción de cuenta alternativa",
			"tipoConta": {
				"title": "Tipo de cuenta",
				"movimento": "Movimiento",
				"subtotal": "Total parcial",
				"razao": "Razón",
				"classe": "Clase"
			},
			"contasradicais": "Cuentas/Radicales",
			"nradical": "N° de cuenta/Radical",
			"incluiexclui": "Incluye/Excluye",
			"inclui": "Incluye",
			"exclui": "Excluye",
			"contasalt": "Cuentas alternativas",
			"radicaisValidacao": "Validación radical",
			"escondeRubricasSemMovimento": "Ocultar títulos que no se mueven",
			"inverteSinal": "Signo contable invertido",
			"inverteSinalInfo": "Al activar esta opción, los valores se mostrarán con signo negativo y los valores de crédito con signo positivo."
		},
		"actions": {
			"importPAlt": "Importar plan alternativo",
			"exportPlano": "Exportar este plan",
			"getModImportPAlt": "Obtenga una plantilla de importación de plan alternativo"
		},
		"datasource": {
			"data": {
				"validaGeral": "Validación general",
				"naoValida": "No válido",
				"validaAnalitica": "Valida análisis",
				"validaRadicais": "Valida radicales"
			}
		},
		"tipoconta": {
			"0": "Movimiento",
			"1": "Total parcial",
			"2": "Razón",
			"3": "Clase"
		},
		"messages": {
			"exportedsuccess": "¡Plan alternativo exportado exitosamente!",
			"modeloImportContasAlt": "¡Se obtuvo un modelo de importación de plan alternativo exitoso!",
			"errorFiles": "Debes subir al menos un archivo.",
			"importPlanoAlt": "¡Plan alternativo importado exitosamente!",
			"notSelectPlanoContAlt": "No has seleccionado ningún plan de cuenta alternativo."
		},
		"modals": {
			"conta": {
				"title": "Cuenta de plan de cuenta alternativa",
				"ncontaemprty": "El número de cuenta no puede estar vacío.",
				"radicalInvalid": "El número de cuenta/valor radical no es válido.",
				"radicalempty": "El número de cuenta/radical no puede estar vacío."
			},
			"import": {
				"title": "Importar plan de cuentas alternativo"
			},
			"cubo": {
				"btn": "Cubo",
				"title": "Cubo de plan de cuenta alternativo",
				"layoutCubo": {
					"caption": "Estilo de vista previa del cubo",
					"saldoGeral": "equilibrio general",
					"saldoCredDeb": "Saldo de crédito/débito"
				},
				"fields": {
					"acumuladoCredito": "Crédito acumulado",
					"acumuladoDebito": "Deuda acumulada",
					"nconta": "Nro. de cuenta",
					"ncontaNome": "Número de cuenta - nombre",
					"nmesNome": "Número de mes - nombre",
					"nome": "Nombre",
					"nomeNConta": "Nombre - Número de cuenta",
					"periodo": "Período",
					"periodoCredito": "Período de crédito",
					"periodoDebito": "Período de débito",
					"saldoCredito": "Saldo crediticio",
					"saldoDebito": "Saldo deudor",
					"saldoGeral": "equilibrio general"
				}
			},
			"extrato": {
				"titleAcumu": "Devengos del plan de cuentas alternativo",
				"titleExtrato": "Estado de plan de cuentas alternativo",
				"fields": {
					"credito": "Crédito",
					"debito": "Deuda",
					"documento": "Documento",
					"nDiario": "Número diario",
					"nDocExterno": "Doc no.",
					"nDocInterno": "Doc no.",
					"nconta": "Nro. de cuenta",
					"nome": "Nombre",
					"periodo": "Período"
				}
			}
		}
	},
	"pocs": {
		"title_detail": "Cuenta del plan de cuentas {{id}}",
		"title_new": "Nueva cuenta del plan de cuentas",
		"title_plural": "Plan de cuentas",
		"pesquisa": "buscar",
		"saved": "Cuenta del plan de cuentas {{id}}, guardada correctamente",
		"error": "La cuenta del plan de cuentas {{id}} no se puede guardar.",
		"deleted": "Cuenta del plan de cuentas {{id}}, eliminada correctamente",
		"actions": {
			"maintenance": {
				"new": "Crear nueva cuenta",
				"edit": "Editar cuenta"
			}
		},
		"fields": {
			"nConta": "Nro. de cuenta",
			"nContaPlaceholder": "código de cuenta",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre de cuenta",
			"tipo": "Tipo de cuenta",
			"cc": "Tener una cuenta corriente",
			"nif": "número de contribuyente",
			"temCCusto": "Tiene análisis",
			"temIVA": "Tiene control del IVA",
			"registaRetencao": "Retención de registros",
			"temRetencao": "Tiene retención",
			"bloqueada": "Obstruido",
			"dpq06NCampo": "Decreto periódico del IVA",
			"dpq06NCampoNome": "Decreto periódico del IVA",
			"daq08NCampo": "Dic. Anual IVA - Cuadro 03 y 05",
			"daq08NCampoNome": "Dic. Anual IVA - Cuadro 03 y 05",
			"daq09NCampo": "Dic. Anual IVA - Tablas 04 y 05",
			"daq09NCampoNome": "Dic. Anual IVA - Tablas 04 y 05",
			"codControloIVADebito": "Código de control de IVA débito",
			"codControloIVADebitoNome": "Código de control de IVA débito",
			"codControloIVACredito": "Código de control de crédito de IVA",
			"codControloIVACreditoNome": "Código de control de crédito de IVA",
			"codTaxonomia": "código de taxonomía"
		},
		"text": {
			"maintenancePocs": "Mantenimiento de números de contribuyentes.",
			"avisoAlteracaoCC1": "La cuenta ya tiene asientos realizados en contabilidad.",
			"avisoAlteracaoCC2": "Al realizar esta operación todas las entradas quedarán abiertas.",
			"avisoAlteracaoCC3": "¿Estás seguro de que quieres continuar?",
			"pretendeContinuar": "Lo descubrí y pienso continuar"
		},
		"list": {
			"dpq06": "Diciembre periódico.",
			"daqAnual": "Anual diciembre."
		},
		"tipocontacontab": {
			"0": "Movimiento",
			"1": "Total parcial",
			"2": "Razón",
			"3": "Clase"
		},
		"validations": {
			"deConta": "La cuenta ingresada no puede ser mayor que la cuenta en el campo \"Hasta cuenta\"",
			"ateConta": "La cuenta ingresada no puede ser menor que la cuenta en el campo \"Cuenta\""
		},
		"alerts": {
			"contaCorrente": "La cuenta que está creando es una cuenta corriente, este tipo de cuenta debe crearse en el módulo de mantenimiento de registros de clientes/proveedores u otros deudores y acreedores."
		},
		"exportXls": "Exportar",
		"btns": {
			"copiaContasPlanoEmpresa": "Copiar cuentas plan para empresas"
		}
	},
	"pocscopy": {
		"exportcontas": "Cuentas_plan_cuentas",
		"exportempresas": "empresas_disponibles",
		"exporterror": "Errores_cuentas",
		"fields": {
			"nconta": "Nro. de cuenta",
			"nome": "Nombre",
			"tipo": "Tipo",
			"temccusto": "Hay C. Costo",
			"temiva": "Tiene IVA",
			"ncontade": "Número de cuenta",
			"nEmpresaNome": "Empresa",
			"nContaNome": "Cuenta"
		},
		"steps": {
			"intro": {
				"step": "Introducción",
				"title": "Bienvenido al Asistente para copiar facturas del Plan Contras entre empresas.",
				"description": "Este asistente lo guiará a través del proceso de copia de cuentas.",
				"description2": "Puedes cancelar la operación en cualquier momento."
			},
			"contas": {
				"step": "Cuentas",
				"title": "Elegir cuentas para copiar.",
				"descriptions": "Seleccione las cuentas que desea copiar."
			},
			"empresas": {
				"step": "Empresas",
				"title": "Elección de empresas.",
				"descriptions": "Elija las empresas a las que desea copiar las cuentas."
			},
			"relatorio": {
				"step": "Informe",
				"title": "Configuración completa.",
				"descriptions": "Cuenta(s) copiada(s) exitosamente.",
				"titleerror": "Errores al copiar cuentas",
				"descriptionserror": "Hubo errores al copiar las cuentas, no se realizaron cambios."
			},
			"errorcontassel": "No has seleccionado ninguna cuenta para copiar.",
			"errorempresassel": "No ha seleccionado ninguna empresa donde desee copiar la(s) cuenta(s).",
			"success": ""
		},
		"messages": {
			"pesqsuccess": "Búsqueda realizada con éxito."
		}
	},
	"portals": {
		"title_detail": "Portal {{id}}",
		"title_new": "Nuevo Portal",
		"title_plural": "Portales",
		"pesquisa": "buscar",
		"saved": "Portal {{id}}, guardado exitosamente",
		"deleted": "Portal {{id}}, eliminado exitosamente",
		"error": "Portal {{id}}, no se puede guardar.",
		"fields": {
			"id": "identificación",
			"name": "Nombre",
			"description": "Descripción",
			"icon": "icono",
			"url": "URL",
			"data": "Datos de configuración del portal",
			"sticky": "Fijado",
			"sortOrder": "Realizar pedidos",
			"roles": "Permisos",
			"includes": "Incluye"
		},
		"actions": {
			"sort": "Ordenar portales"
		},
		"text": {
			"noAccess": "No tienes acceso a este portal.",
			"editNotAllowed": "No se pueden editar los portales del sistema",
			"deleteNotAllowed": "No se pueden eliminar portales del sistema.",
			"forbiddenMessage": "Hay usuarios con acceso a este portal, si lo eliminas también se eliminarán los accesos existentes.",
			"showModuleName": "Mostrar nombre del módulo",
			"tooltipIncludes": "Los usuarios con acceso a este portal también tendrán acceso a los portales incluidos",
			"sidebarTitle": "Título en la barra lateral"
		},
		"btn": {
			"copy": "Copiar el nombre del módulo",
			"remove": "Eliminar",
			"edit": "Editar",
			"toggleVisibility": "Visible"
		},
		"items": {
			"erp": "Comercial",
			"financeiro": "Financiero",
			"contabilidade": "Contabilidad",
			"ativos": "Activos",
			"rh": "Recursos humanos",
			"pca": "Mi contabilidad",
			"clientconnect": "Conexión del cliente",
			"gestorrh": "Gerente de Recursos Humanos",
			"gestorservico": "Gerente de servicio",
			"colaborador": "Colaborador",
			"gestorementas": "Administrador de menú",
			"configuracoes": "Ajustes",
			"contabilidadepremium": "Contabilidad Premium",
			"erpadvanced": "Comercial Avanzado"
		},
		"module": {
			"newMenu": "Nuevo menú",
			"menu": "Menú",
			"modulosdisponiveis": "Módulos disponibles"
		},
		"menu": {
			"erp": {
				"clientes": "Clientes",
				"fornecedores": "Proveedores",
				"consultasvendas": "Consultas de ventas",
				"consultascompras": "Consultas de compra",
				"contascorrentes": "Cuentas Corrientes",
				"stocks": "cepo",
				"tesouraria": "Tesorería",
				"integracoes": "Integraciones",
				"configuracoes": "Ajustes",
				"manutencoes": "Mantenimiento"
			},
			"pca": {
				"contabilidade": "Contabilidad",
				"abonosdescontos": "Bonificaciones y descuentos",
				"mapassalarios": "Mapas salariales",
				"prestacaocontas": "Responsabilidad",
				"minhaconta": "mi cuenta"
			},
			"gestorementas": {
				"colaborador": "Colaborador",
				"menus": "Menús",
				"refeicoes": "Comidas"
			},
			"gestorrh": {
				"colaborador": "Colaborador",
				"servicos": "Servicios",
				"tabelas": "Mesas",
				"marcacaoeventos": "Reserva de eventos"
			},
			"gestorservico": {
				"colaborador": "Colaborador",
				"marcacaoeventos": "Reserva de eventos"
			},
			"contabilidade": {
				"lancamentos": "Lanzamientos",
				"consultas": "Consultas",
				"manutencaoContas": "Mantenimiento de cuenta",
				"declaracoesFiscais": "Declaraciones de impuestos",
				"fechoContas": "Cerrar cuentas",
				"ferramentas": "Herramientas",
				"manutencoes": "Mantenimiento"
			},
			"configuracoes": {
				"atalhos": "Accesos directos del asistente de configuración",
				"configuracaoerp": "Configuración ERP"
			},
			"ativos": {
				"manutencoesTitle": "Mantenimiento",
				"manutencoes": "Mantenimiento",
				"consultarTitle": "Consultar",
				"consultar": "Consultar",
				"atalhoscontabilidadeTitle": "Atajos a la contabilidad",
				"atalhoscontabilidade": "Atajos a la contabilidad",
				"tabelasTitle": "Mesas",
				"tabelas": "Mesas"
			},
			"rh": {
				"transferencias": "Transferencias",
				"recolhadados": "Recopilación de datos",
				"marcacaocalendario": "Marcado de calendario",
				"processamento": "Tratamiento",
				"obrigacoeslegaisfiscais": "Obligaciones legales y fiscales",
				"integracaocontabilidade": "Integración contable",
				"consultas": "Consultas",
				"abonosdescontos": "Bonificaciones/Descuentos",
				"ferramentas": "Herramientas",
				"configuracoes": "Ajustes",
				"manutencoes": "Mantenimiento"
			},
			"financeiro": {
				"clientes": "Clientes",
				"fornecedores": "Proveedores",
				"manutencoes": "Mantenimiento"
			}
		},
		"sidebar": {
			"modules": {
				"adiantamentos": "Insinuaciones",
				"analiticaconfig": "Configurar cuentas de análisis",
				"apuraiva": "Liquidación de IVA",
				"apuramentoresultados": "Cálculo de resultados",
				"atFaturasRecibosVerdes": "Recibos de ventas verdes",
				"ativosaquisicaocontab": "Registrar activos con base en contabilidad.",
				"ativosaquisicaoinvestemcurso": "Inversiones en curso",
				"ativoscoeficientecm": "Coeficientes de corrección monetaria",
				"ativosconsistencia": "Coherencia con la contabilidad",
				"balancetes": "Balances",
				"balancetesalternativos": "Balances planos alternativos",
				"balancetesanalitica": "Balances analíticos",
				"controloivaentreempresas": "Copiar control de IVA para empresas",
				"declaracaoiva": "Decreto periódico del IVA",
				"declaracaoivarecapitulativa": "Decreto recapitulativo del IVA",
				"demfinsnc": "Demócrata financiero del SNC.",
				"descritivos": "Descriptivos",
				"diarios": "Diarios",
				"dmrf": "Dic. retenciones en fuente",
				"docsComerciaisEstatisticas": "Estadísticas por artículo",
				"docsComerciaisEstatisticasDoc": "Estadísticas por documento",
				"docscontabilidade": "Recopilación",
				"documentosdigitaisConciliacao": "Documentos de conciliación.",
				"extratosDT": "Extracto histórico",
				"faturacaoeletronicaconfiguracoes": "Factura electrónica",
				"faturacaopublica": "Facturación pública",
				"gto": "Gestión de tareas",
				"impdecpe": "Extrae diciembre.",
				"importadorEfatura": "Compras con factura electrónica",
				"importadorSaftDirect": "Ventas de importadores",
				"infoglobalacrescimos": "Información.",
				"infoglobaldiferimentos": "Información.",
				"infoglobaldiferimentosacrescimos": "Información.",
				"listagemdiarios": "Diarios",
				"listagensVendasAnoMes": "Resumen por mes",
				"manuttributautonomas": "Mantenimiento",
				"mapasamortizacao": "Mapas oficiales",
				"mbconfigs": "Referencias MB",
				"meiosPagamento": "Métodos de pago",
				"movimentosemaberto": "Movimientos abiertos",
				"pcaContabilidadeMovimentosAbertoCliente": "Movimientos abiertos",
				"pcaContabilidadeMovimentosAbertoFornecedor": "Movimientos abiertos",
				"pcaMovimentosAbertoCliente": "Mover.",
				"periodos": "Periodos",
				"pocscopy": "Copiar cuentas plan para empresas",
				"predefinidoscontabilidade": "Predefinido",
				"prheventosconfig": "Config.",
				"proceDMRAT": "DMR EN",
				"proceSalarios": "Salarios",
				"proceSegSocial": "DMR SS (DRI)",
				"procseguradorafolhaferias": "Hoja de vacaciones para compañía de seguros.",
				"qivaas": "Campos dic.",
				"qivaps": "Campos dic.",
				"remfx": "Datos fijos",
				"remun": "Datos manuales",
				"reparticoesccustosfaturacao": "C. Desglose de costos",
				"reparticoesccustossalarios": "C. Desglose de costos",
				"rhassistentetrabalhador": "Integración salarial",
				"rhdadosconfigficha": "Datos de la tarjeta",
				"rhmanutencao": "Mantenimiento",
				"rhprocessamentosalarios": "Procesamiento de salario",
				"rhrecolhaseriesubsidiosalimentacao": "Subsidio de comida",
				"rhrecolhaseriesubsidiosferias": "subsidio de vacaciones",
				"rhrecolhaseriesubsidiosnatal": "subsidio de navidad",
				"rhsaltotrf": "Salarios",
				"saft": "SAF-T completo",
				"solic": "Textos de aviso de facturación",
				"svat": "Dem financiera SVAT.",
				"tesrubrica": "Artículos del tesoro",
				"trfba": "Pagos SEPA",
				"trfbacomercial": "Pagos SEPA",
				"trfbarh": "Pagos SEPA",
				"mapadiferimentosacrescimos": "Mapa de provisiones y aplazamientos",
				"grupodocfaconfiguracoes": "Grupos de tipos de documentos",
				"analisemargemlucro": "Análisis del margen de beneficio"
			},
			"contabilidade": {
				"clientes": "Clientes",
				"fornecedores": "Proveedores",
				"outrosdevedorescredores": "Otros deudores y acreedores"
			},
			"rh": {
				"integracaoSalarios": "Empresa",
				"integracaoSalariosMulti": "Multiempresas"
			}
		},
		"modals": {
			"editnode": {
				"title": "Cambiar datos de entrada del menú",
				"fields": {
					"menuTitle": "Título del menú",
					"pageTitle": "Título del módulo",
					"sidebarTitle": "Título del módulo en la barra lateral",
					"sidebarTitleTooltip": "Si este campo no se completa, el valor será el mismo que el campo \"Título del módulo\"",
					"icon": "icono"
				}
			},
			"sort": {
				"title": "Definir el orden de los portales.",
				"success": "Portales ordenados exitosamente"
			}
		}
	},
	"predefinidoscontabilidade": {
		"title_plural": "Ajustes preestablecidos de contabilidad",
		"pesquisa": "buscar",
		"success": {
			"save": "El ajuste preestablecido de contabilidad se guardó correctamente",
			"edit": "La contabilidad predefinida \"{{id}}\" se guardó correctamente",
			"delete": "La cuenta predefinida \"{{id}}\" se eliminó correctamente",
			"simulate": "Documento contable guardado exitosamente (simulación)"
		},
		"steps": {
			"operation": "Operación a realizar",
			"basic": "Configuración de datos",
			"header": "Configurar datos de encabezado",
			"lines": "Configuración de datos de fila",
			"preview": "Vista previa preestablecida",
			"companies": "Elección de empresas",
			"finalize": "Finalizar"
		},
		"fields": {
			"preDefinidosID": "Código",
			"description": "Descripción",
			"data": "Datos",
			"revision": "Revisión",
			"cgBanking": "Para utilizar en Banca CentralGest",
			"document": "Documento",
			"copyExisting": "Preestablecido para copiar"
		},
		"titles": {
			"chooseOperation": "Por favor elija la operación que desea realizar",
			"notAvailable": "Esta opción aún no está disponible",
			"suggestCodigo": "Sugerir código",
			"suggestCodigoToolTip": "Sugerir código único para todas las empresas.",
			"legend": "Subtítulo",
			"verified": "Campo marcado",
			"notVerified": "Campo no verificado",
			"availableCompanies": "Empresas disponibles",
			"companiesToSave": "Empresas donde se grabará lo predefinido",
			"companiesToRemove": "Empresas donde se eliminarán los predefinidos",
			"selectColumns": "Por favor elija las empresas donde desea crear el predefinido",
			"selectColumnsEdit": "Por favor elija las empresas donde desea cambiar el valor predeterminado",
			"selectColumnsDelete": "Por favor elija las empresas donde desea eliminar el predefinido"
		},
		"operations": {
			"new": "Crear nuevo ajuste preestablecido",
			"newBasedOnDoc": "Crear un nuevo ajuste preestablecido basado en un documento contable",
			"newBasedOnExisting": "Crear un nuevo ajuste preestablecido basado en uno existente",
			"newAdvanced": "Crear nuevo ajuste preestablecido avanzado",
			"edit": "Cambiar preestablecido",
			"delete": "Eliminar preajuste"
		},
		"errors": {
			"invalidDocContab": "El documento contable \"{{doc}}\" no existe",
			"invalidPreDefinido": "El \"{{predefinido}}\" predefinido no existe"
		},
		"btn": {
			"simulate": "Simular grabación",
			"clear": "Borrar documento"
		}
	},
	"predefinidocontabcab": {
		"periodo": "Período",
		"diario": "A diario",
		"nDocInterno": "Doc no.",
		"dataLancamento": "Fecha de publicación contable",
		"dataVencimento": "Fecha de vencimiento",
		"dataDoc": "Fecha del documento",
		"nContribuinte": "número de contribuyente",
		"nDocExterno": "Doc no.",
		"descricao": "Descripción",
		"descritivo": "Descriptivo",
		"dataTransacaoBanco": "fecha de transacción bancaria",
		"titles": {
			"fieldProperties": "Propiedades de campo",
			"visualProperties": "Propiedades visuales"
		},
		"fields": {
			"readonly": "Solo lectura",
			"tabStop": "El cursor se detiene en el campo",
			"visible": "Visible",
			"tipoValorPeriodo": "origen del valor",
			"tipoValorDiario": "origen del valor",
			"valorFixo": "Valor fijo",
			"valorFixoDiario": "diario fijo",
			"valorFixoNDocExterno": "Doc no.",
			"valorFixoDescricao": "Descripción fija",
			"valorFixoDescritivo": "Descripción fija",
			"valorFixoConta": "cuenta fija",
			"origem": "Origen",
			"incrementaAutomaticamente": "incremento automático",
			"origemValor": "origen del valor"
		},
		"errorInvalid": "Debes marcar todos los campos del encabezado para continuar.",
		"enums": {
			"tipoValorPeriodo": {
				"0": "tabla de empresa",
				"1": "fecha del sistema",
				"2": "Fecha del documento.",
				"3": "fecha de transacción bancaria"
			},
			"tipoValorDiario": {
				"0": "No definido",
				"1": "Fijado"
			},
			"origemDataDoc": {
				"0": "De la época del doc.",
				"1": "Fecha del documento."
			},
			"origemDataVencimento": {
				"0": "De la época del doc.",
				"1": "Cond."
			},
			"origemDataLancamento": {
				"0": "Último día del periodo",
				"1": "fecha actual",
				"2": "Fecha del documento."
			},
			"valorDescricao": {
				"0": "No definido",
				"1": "Fijado",
				"2": "Fórmula"
			},
			"valorDescritivo": {
				"0": "No definido",
				"1": "Igual que el último documento.",
				"2": "Fijado"
			}
		}
	},
	"predefinidocontablinhas": {
		"addLine": "Agregar línea",
		"duplicateLine": "Duplicar línea seleccionada",
		"moveLineUp": "Mover fila",
		"moveLineDown": "Mover línea hacia abajo",
		"removeLine": "Eliminar línea seleccionada",
		"reorder": "Reordenar columnas",
		"errorInvalid": "No definió ninguna línea para el predefinido",
		"titles": {
			"fieldProperties": "Propiedades del campo \"{{field}}\" ({{cell}})",
			"visualProperties": "Propiedades visuales",
			"emptyLines": "La tabla de filas está vacía.",
			"baseTributavelProperties": "Propiedades de base imponible",
			"contaCorrenteProperties": "Propiedades de la cuenta corriente",
			"ask": "Pregunta",
			"salda": "salda",
			"arredEFatura": "Arred.",
			"hintFormula": "Para editar, haga clic en la calculadora a la derecha."
		},
		"fields": {
			"conta": "Cuenta",
			"valor": "Valor",
			"valorIva": "valor del IVA",
			"nContribuinte": "número de contribuyente",
			"descricao": "Descripción",
			"nDocExterno": "Doc no.",
			"descritivo": "Descriptivo",
			"dataLancamento": "Fecha de publicación contable",
			"dataVencimento": "Fecha de vencimiento",
			"dataDoc": "Fecha del documento",
			"nomeConta": "Nombre de cuenta",
			"dC": "Débito/Crédito",
			"radical": "Radical",
			"tipoConta": "Tipo de cuenta",
			"temImputacao": "Seleccionar montos a liquidar",
			"vaiImputarALinha": "imputará a la línea",
			"codRepCC": "Código de distribución c.",
			"tipoValor": "Tipo de valor",
			"valorFormula": "Valor por fórmula",
			"colocaValorEFaturaDocDigital": "Introduzca valor eFatura o doc.",
			"incrementaAutomaticamente": "incremento automático",
			"tipoValorBaseTributavel": "Valor con/sin IVA",
			"importEFaturaSuportaTaxaIsenta": "Soporta imp exento de impuestos.",
			"importEFaturaValorImpSelo": "Colocar sello fiscal imp.",
			"importEFaturaContaUnicaSemDeducao": "Cuenta única sin deducción de impuestos",
			"importFRVSuportaTaxaIsenta": "Admite rec exento de tarifa.",
			"importFRVValorImpSelo": "Colocar sello fiscal rec."
		},
		"enums": {
			"tipoConta": {
				"0": "No definido",
				"1": "Cuenta corriente",
				"2": "Base imponible",
				"3": "Cuenta bancaria de CG Bank"
			},
			"tipoValor": {
				"0": "Pregunta",
				"1": "Fijado",
				"2": "Fórmula",
				"3": "documento de saldo",
				"4": "Redondeo de facturas electrónicas"
			},
			"tipoValorBaseTributavel": {
				"0": "Pregunta",
				"1": "Base",
				"2": "Base + IVA"
			},
			"tipoValorDescritivo": {
				"0": "No definido",
				"1": "Igual que el último documento.",
				"2": "Fijado"
			}
		}
	},
	"predefinidocontabformula": {
		"title": "Fórmula para el campo {{field}} de la línea {{line}}",
		"operators": "Operadores",
		"variables": "variables",
		"formula": "Fórmula",
		"validator": "Aún no he definido una fórmula para el campo",
		"extraVariables": {
			"valorPorPagar": "Valor por línea de pago"
		},
		"headerVariables": {
			"periodo": "Encabezado.Período",
			"nomePeriodo": "Encabezado.NombrePeríodo"
		}
	},
	"prevites": {
		"manual": {
			"new": {
				"title": "Previsión del Tesoro",
				"previtessaved": "La previsión del Tesoro se guardó correctamente.",
				"rubrica": "Rúbrica",
				"descricao": "Descripción",
				"valor": "Valor",
				"cardtitle": "Ocurrencias sucesivas",
				"periodicas": "Publicaciones periódicas",
				"ocrrfimperio": "Ocurrencia al final de cada periodo",
				"ocorrencias": "Número de ocurrencias",
				"periodicidade": "Periodicidad",
				"temdefnocurr": "¡Ha definido la Periodicidad pero aún no ha definido el Número de Ocurrencias!",
				"temdefocurr": "¡Ha definido el Número de Ocurrencias pero aún no ha definido la Periodicidad!",
				"rubricanull": "¡La rúbrica no puede ser nula!",
				"valornull": "¡El valor no puede ser nulo!",
				"datainf": "¡La fecha no puede ser menor que la fecha actual!",
				"nConta": "Número de cuenta",
				"nomeConta": "Nombre de cuenta"
			},
			"tooltip": {
				"btnDelete": "Eliminar registro",
				"btnDeleteMultiple": "Eliminar registros seleccionados"
			},
			"messages": {
				"certezaeliminar": "¿Está seguro de que desea eliminar los registros?"
			},
			"btnDeleteMulti": "Eliminar registros seleccionados"
		},
		"automatica": {
			"modalTitle": "Rúbrica de Tesorería",
			"valselecionado": "Valor seleccionado",
			"clientefornecedor": "Clientes / Proveedores",
			"clientes": "Clientes",
			"fornecedores": "Proveedores",
			"datatitle": "Seleccionar fecha de tesorería",
			"datade": "Fecha de expiración",
			"contade": "cuenta",
			"selecaoAutomatica": "Seleccione Automático",
			"selecaoAutomaticaTooltip": "Cuando se selecciona, selecciona automáticamente todas las líneas del documento.",
			"fields": {
				"seleccionado": "Seleccionado",
				"nConta": "Número de cuenta",
				"nome": "Nombre",
				"nDocString": "Doc No.",
				"nDocExterno": "Doc externo No.",
				"descricao": "Descripción",
				"debito": "Deuda",
				"credito": "Crédito",
				"porPagar": "No pagado",
				"dataDocExt": "Fecha.",
				"dataVencimento": "Fecha de vencimiento",
				"dataTes": "fecha de tesorería"
			},
			"messages": {
				"procesuccess": "¡Datos procesados ​​exitosamente!",
				"temselecionar": "Debes seleccionar las entradas que deseas procesar."
			},
			"actions": {
				"verdoc": "Ver documento",
				"aplicardata": "Aplicar fecha de tesorería",
				"selecionaDocConta": "Seleccionar documentos de cuenta",
				"removeSeleconta": "Borrar selección de documentos de cuenta",
				"docfaturacao": "Ver documento de facturación"
			}
		},
		"datasource": {
			"tesourariaperiodicidade": {
				"none": "Ninguno",
				"diaria": "A diario",
				"semanal": "Semanalmente",
				"quinzenal": "Quincenal",
				"mensal": "Mensual",
				"bimensal": "Bimensual",
				"trimestral": "Trimestral",
				"quadrimestral": "Trimestral",
				"semestral": "Semestral",
				"anual": "Anual"
			}
		},
		"legend": {
			"correcaoAutomatica": "Autocorrección - Fecha actual"
		}
	},
	"prevtesobras": {
		"codTipoPlaneamentoDe": "Código de tipo de planificación",
		"codEstadoObraDe": "Código de estado de trabajo",
		"cabCodigo": "código de trabajo",
		"cabDescricao": "nombre del trabajo",
		"codPlaneamenTipo": "código de planificación",
		"descricao": "Desc.",
		"codEstado": "código de estado",
		"codEstadoDesc": "Desc.",
		"modaltitle": "artículo del tesoro",
		"rubrica": "código de rúbrica",
		"rubricanull": "El código del artículo no puede ser nulo.",
		"importsuccess": "¡Datos procesados ​​exitosamente!"
	},
	"prhcomunicacoesinternas": {
		"title_detail": "Comunicación interna {{id}}",
		"title_new": "Nueva comunicación interna",
		"title_plural": "Comunicaciones internas",
		"pesquisa": "buscar",
		"saved": "Comunicación interna {{id}}, guardada exitosamente",
		"error": "Comunicación interna {{id}}, no se puede guardar.",
		"fields": {
			"comIntCabID": "Código.",
			"comIntCabIDPlaceholder": "Código de comunicación interna",
			"titulo": "Título",
			"tituloPlaceholder": "Título de la comunicación interna.",
			"estadoLido": "Leer",
			"de": "De",
			"texto": "Texto",
			"ficheiro": "Archivo"
		},
		"masterDetail": {
			"emp": "código de empleado",
			"nome": "Nombre",
			"lido": "Leer",
			"dateHora": "Fecha/hora de lectura"
		}
	},
	"prhdocsplata": {
		"title_detail": "Documento de plataforma de recursos humanos {{id}}",
		"title_new": "Nuevo documento de la plataforma de RR.HH.",
		"title_plural": "Documentos de la plataforma de recursos humanos",
		"pesquisa": "buscar",
		"saved": "Documento de plataforma de recursos humanos {{id}}, guardado exitosamente",
		"error": "El documento de la plataforma de recursos humanos {{id}} no se puede guardar.",
		"fields": {
			"cab": {
				"codprhdocsplata": "Código.",
				"codprhdocsplataPlaceholder": "Código de documento de la plataforma de recursos humanos",
				"titulo": "Título",
				"tituloPlaceholder": "Título del documento de la plataforma de recursos humanos",
				"dataPlaceholder": "Fecha del documento de la plataforma de RRHH",
				"dataValidade": "Fecha de expiración",
				"dataValidadePlaceholder": "Fecha de expiración"
			}
		},
		"steps": {
			"documento": "Datos del documento",
			"papeis": "Papeles",
			"servicos": "Servicios"
		}
	},
	"prhentalters": {
		"erroDoCampo": "Ocurrió un error al enviar la solicitud de cambio de datos del campo: \"{{campo}}\"",
		"naoExistemAlteracoesParaGuardar": "No hay cambios para guardar.",
		"foiEnviadoPedidoDeAlteracaoDados": "La solicitud de cambio de datos ha sido enviada.",
		"foiEnviadoPedidoDeAlteracaoDadosMasAlgunsComErro": "Se envió la solicitud de cambio de datos, pero algunos campos tienen errores.",
		"ibanInvalido": "El IBAN \"{{iban}}\" no es válido",
		"tooltips": {
			"ibanInvalido": "Este IBAN no es válido."
		}
	},
	"prheventosconfig": {
		"title_detail": "{{id}} configuración del evento",
		"title_new": "Nueva configuración de eventos",
		"title_plural": "Configuración de eventos de recursos humanos",
		"title_small": "Config.",
		"pesquisa": "buscar",
		"saved": "Configuración del evento {{id}}, guardada exitosamente",
		"error": "La configuración del evento {{id}} no se puede guardar.",
		"fields": {
			"codPRHEventosConfig": "Configuración de eventos",
			"codPRHEventosConfigPlaceholder": "Configuración de eventos",
			"tipoEvento": "Tipo de evento",
			"tipoEventoPlaceholder": "Tipo de evento",
			"tipo": "Tipo",
			"tipoPlaceholder": "Tipo",
			"nCodAbdes": "Código de bonificación/descuento",
			"nCodAbdesPlaceholder": "Código de bonificación/descuento",
			"descricao": "Descripción",
			"descricaoPlaceholder": "Descripción",
			"tipoProcessamento": "Tipo de procesamiento",
			"tipoProcessamentoPlaceholder": "Tipo de procesamiento",
			"tipofalta": "tipo de falla",
			"desconto": "Descuento",
			"abono": "Prestación"
		},
		"tipos": {
			"abonos": "Asignaciones",
			"faltas": "Faltas",
			"ferias": "Vacaciones"
		},
		"messages": {
			"error": "La configuración del evento no se puede guardar.",
			"addsuccess": "Agregado exitosamente",
			"editsuccess": "cambiado con éxito",
			"deleteSuccess": "Eliminado exitosamente",
			"deleteTitle": "¡Atención!",
			"deleteConfirm": "¿Realmente deseas eliminar este registro?",
			"fieldsUnfiled": "¡Hay campos que no están cumplimentados!"
		}
	},
	"prhfluxos": {
		"title_detail": "Flujo {{id}}",
		"title_new": "Nuevo flujo",
		"title_plural": "Flujos de recursos humanos",
		"pesquisa": "buscar",
		"saved": "Flujo {{id}}, guardado exitosamente",
		"error": "Flujo {{id}}, no se puede guardar.",
		"fields": {
			"idFluxo": "Fluir",
			"idFluxoPlaceholder": "código de flujo",
			"posicao": "Posición",
			"posicaoPlaceholder": "Posición de flujo",
			"indice": "Índice",
			"indicePlaceholder": "Índice de flujo",
			"codPapel": "código de papel",
			"codPapelPlaceholder": "Código de rol activo",
			"integraCG": "Integra CentralGest",
			"integraCGPlaceholder": "Integra CentralGest",
			"papel": "Papel",
			"papelCGPlaceholder": "Papel",
			"tipo": "Tipo",
			"tipoPlaceholder": "Tipo"
		}
	},
	"prhgruporefeicoes": {
		"title_detail": "Grupo de comidas {{id}}",
		"title_new": "Nuevo grupo de comida",
		"title_plural": "Grupos de comidas",
		"pesquisa": "buscar",
		"saved": "Grupo de comidas {{id}}, guardado correctamente",
		"error": "Grupo de comidas {{id}}, no se puede guardar.",
		"fields": {
			"grupo": "Grupo",
			"grupoPlaceholder": "Grupo",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del grupo de comidas",
			"permiteAgendamento": "Permite programar",
			"permiteAgendamentoPlaceholder": "Permite programar",
			"horaInicio": "Hora de inicio",
			"horaInicioPlaceholder": "Hora de inicio",
			"horaFim": "Hora de finalización",
			"horaFimPlaceholder": "Hora de finalización"
		}
	},
	"prhlocais": {
		"title_detail": "Ubicación {{id}}",
		"title_new": "Nueva ubicación",
		"title_plural": "Ubicaciones de Recursos Humanos",
		"pesquisa": "buscar",
		"saved": "Ubicación {{id}}, guardada correctamente",
		"error": "Ubicación {{id}}, no se puede guardar.",
		"fields": {
			"codLocal": "Ubicación",
			"codLocalPlaceholder": "Código de ubicación",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre de la ubicación"
		}
	},
	"prhmenus": {
		"title_detail": "Menú {{id}}",
		"title_new": "Nuevo menú",
		"title_plural": "Menús de RRHH",
		"pesquisa": "buscar",
		"saved": "Menú {{id}}, guardado exitosamente",
		"error": "Menú {{id}}, no se puede guardar.",
		"fields": {
			"codMenu": "Menú",
			"codMenuPlaceholder": "Código de menú",
			"titulo": "Título",
			"tituloPlaceholder": "Título del menú",
			"descricao": "Descripción",
			"descricaoPlaceholder": "Descripción del menú"
		}
	},
	"prhpapeis": {
		"title_detail": "Papel {{id}}",
		"title_new": "Nuevo rol",
		"title_plural": "Documentos de recursos humanos",
		"pesquisa": "buscar",
		"saved": "Rol {{id}}, guardado exitosamente",
		"error": "Papel {{id}}, no se puede guardar.",
		"fields": {
			"codPapel": "Papel",
			"codPapelPlaceholder": "Código de papel",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del papel",
			"unicoServico": "Sencillo por servicio",
			"unicoServicoPlaceholder": "Sencillo por servicio"
		}
	},
	"prhrefeicoes": {
		"title_detail": "Comida {{id}}",
		"title_new": "Bocadillo",
		"title_plural": "Comidas RH",
		"pesquisa": "buscar",
		"saved": "Comida {{id}}, guardada correctamente",
		"error": "Comida {{id}}, no se puede guardar.",
		"fields": {
			"CodRefeicao": "Bocadillo",
			"CodRefeicaoPlaceholder": "código de comida",
			"CODEMP": "Colaborador",
			"CODEMPPlaceholder": "código de empleado",
			"codPRHRelEmentas": "relación",
			"codPRHRelEmentasPlaceholder": "Código RelEmenta",
			"consumiu": "Consumado",
			"local": "Ubicación",
			"tipoRefeicao": "tipo de comida",
			"cor": "Color",
			"menu": "Menú",
			"ementa": "Menú",
			"refConsumida": "comida consumida",
			"locais": "Ubicaciones"
		},
		"message": {
			"porProcessar": "No hay datos para procesar.",
			"procComSucesso": "Procesado exitosamente"
		},
		"grupoRefeicao": {
			"almoco": "Almuerzo",
			"jantar": "para almorzar",
			"nao": "No",
			"sim": "Sí"
		},
		"btn": {
			"procRefNCons": "Proc."
		}
	},
	"prhrelementas": {
		"title_detail": "RelEmenta {{id}}",
		"title_new": "relación",
		"title_plural": "Relaciones del menú de RR.HH.",
		"pesquisa": "buscar",
		"saved": "RelEmenta {{id}}, guardado exitosamente",
		"error": "RelEmenta {{id}}, no se puede guardar.",
		"menusAssociados": "El menú no se puede eliminar porque ya hay comidas programadas.",
		"fields": {
			"codPRHRelEmentas": "Informe del menú",
			"codPRHRelEmentasPlaceholder": "código de relación de menús",
			"codMenu": "Menú",
			"codMenuPlaceholder": "Código de menú",
			"codLocal": "Ubicación",
			"codLocalPlaceholder": "Código de ubicación",
			"tipoRefeicao": "tipo de comida",
			"tipoRefeicaoPlaceholder": "tipo de comida"
		}
	},
	"prhservicoempregados": {
		"title_detail": "Informe de servicio/empleado {{id}}",
		"title_new": "Nuevo informe de servicio/empleado",
		"title_plural": "Informe de servicio/empleado de recursos humanos",
		"pesquisa": "buscar",
		"saved": "Informe de servicio/empleado guardado correctamente.",
		"error": "Los informes de servicio/empleado no se pueden guardar.",
		"fields": {
			"codServicoEmpregado": "código relacionado",
			"codServicoEmpregadoPlaceholder": "Código de retransmisión.",
			"codPapel": "Papel",
			"codPapelPlaceholder": "código de papel",
			"codServico": "Servicio",
			"codServicoPlaceholder": "Código de servicio",
			"codEmp": "Empleado",
			"codEmpPlaceholder": "código de empleado"
		},
		"errors": {
			"servicoAtualNaoSelecionado": "Seleccione un servicio actual.",
			"servicoDestinoNaoSelecionado": "Seleccione un servicio de destino.",
			"servicosAtualDestinoIguais": "El servicio actual no puede ser el mismo que el servicio de destino.",
			"naoExistemEmpregadosSelecionados": "No hay empleados seleccionados."
		}
	},
	"prhservicos": {
		"title_detail": "Servicio {{id}}",
		"title_new": "Nuevo servicio",
		"title_plural": "Servicios de Recursos Humanos",
		"pesquisa": "buscar",
		"saved": "Servicio {{id}}, guardado exitosamente",
		"error": "Servicio {{id}}, no se puede guardar.",
		"deleted": "Servicio {{id}}, eliminado exitosamente.",
		"fields": {
			"codServico": "Servicio",
			"codServicoPlaceholder": "Código de servicio",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del servicio"
		}
	},
	"prhtipocom": {
		"title_detail": "Tipo de comunicación {{id}}",
		"title_new": "Nuevo tipo de comunicación",
		"title_plural": "Tipos de comunicación",
		"pesquisa": "buscar",
		"saved": "Escriba Comunicación {{id}}, guardado exitosamente.",
		"error": "Tipo de comunicación {{id}}, no se puede guardar.",
		"deleted": "Escriba comunicación {{id}}, eliminada correctamente",
		"fields": {
			"codPRHTipoCom": "Código de tipo de comunicación",
			"codPRHTipoComPlaceholder": "Código de tipo de comunicación",
			"nome": "Nombre",
			"sistema": "Sistema"
		}
	},
	"prhtiporefeicoes": {
		"title_detail": "Tipo de comida {{id}}",
		"title_new": "Nuevo tipo de comida",
		"title_plural": "Tipos de comidas RH",
		"pesquisa": "Buscar",
		"saved": "Tipo de comida {{id}}, guardado correctamente",
		"error": "Tipo de comida {{id}}, no guardada.",
		"fields": {
			"tipoRefeicao": "Tipo de comida",
			"tipoRefeicaoPlaceholder": "tipo de comida",
			"nome": "Nombre",
			"nomePlaceholder": "tipo de nombre de comida",
			"horaRefeicao": "hora de comer",
			"horaRefeicaoPlaceholder": "hora de comer",
			"numMinutosMarcacao": "Número de minutos para marcar",
			"numMinutosMarcacaoPlaceholder": "Número de minutos para marcar",
			"corPrimaria": "color primario",
			"corPrimariaPlaceholder": "color primario",
			"corSecundaria": "color secundario",
			"corSecundariaPlaceholder": "color secundario",
			"grupoRefeicao": "grupo de comida",
			"grupoRefeicaoPlaceholder": "grupo de comida"
		}
	},
	"proceDMRAT": {
		"comecar": "para empezar",
		"comoResolver": "Consultar el estado de la empresa en cuestión y/o empleado.",
		"decSubstituicao": "Declaración de sustitución",
		"enviadoComErros": "Hubo errores en los archivos enviados.",
		"enviadoComSuccess": "Archivo(s) enviado(s) exitosamente.",
		"erros": "Errores",
		"genDRIFile": "Generar archivo",
		"geracaoImpressao": "Generación e impresión",
		"initNewProc": "Empezar nuevo",
		"jobTimeoutModalMessage": "La tarea ha expirado.",
		"jobTimeoutModalTitle": "Tarea",
		"obterPdf": "Obtener pdf",
		"printMapPerEmp": "Imprimir mapa",
		"printMapaModalTitle": "Impresión de mapas de declaraciones de remuneraciones mensuales - AT",
		"procAlertMsg1": "De forma predeterminada, generará DMR-AT para todos los procesamientos del mes.",
		"procAlertMsg2": "Si desea verificar los procesos existentes, seleccione el campo a continuación",
		"procExistentesReprocMessage": "Hay empresas con tramitación de la declaración de retribución mensual - AT.",
		"promptAlertModalTitle": "¡Atención!",
		"promptSendModalMessage": "Enviará el(los) archivo(s) de declaración de remuneración mensual a través de WebService.",
		"promptViewFilesMessage": "Los expedientes han sido comunicados.",
		"promptViewResultsMessage": "Ha finalizado la tramitación de la declaración de retribuciones mensuales.",
		"promptViewResultsTitle": "Declaración de remuneración mensual",
		"resumoDadosFuncionario": "Resumen de datos de empleados",
		"semAlteracaoParaGerar": "El sistema no generó ningún archivo porque ya fue generado anteriormente.",
		"sendFile": "Enviar vía WebService",
		"tabConfigCaption": "Ajustes",
		"tabEmpresasCaption": "Empresas",
		"tabIntroCaption": "Introducción",
		"tabIntroSubTitle": "El asistente le ayudará a generar el archivo de declaración de remuneración mensual - AT",
		"tabIntroTitle": "Bienvenido al asistente de procesamiento de extractos de remuneraciones mensuales - AT",
		"tabProcCaption": "Tratamiento",
		"tabResultsCaption": "Informe",
		"temSeleccionarDatas": "Debes seleccionar al menos una fecha.",
		"temSeleccionarUmMesParaProcessar": "Debes seleccionar un mes para procesar",
		"temSeleccionarUmaEmpresa": "Debes seleccionar al menos una empresa para continuar.",
		"viewInfoDadosAutoTab": "Datos automáticos",
		"viewInfoDadosGlobaisContribObrigTab": "Contribuciones obligatorias",
		"viewInfoDadosGlobaisControloTab": "Control",
		"viewInfoDadosGlobaisQuotizSindicaisTab": "Cuotas sindicales",
		"viewInfoDadosGlobaisRendimAnosAntTab": "Ingresos de años anteriores",
		"viewInfoDadosGlobaisRetencaoIRSTab": "Retención del IRS",
		"viewInfoDadosGlobaisRetencaoSobretaxaTab": "Retención de recargo",
		"viewInfoDadosGlobaisTab": "Datos globales",
		"viewInfoDadosGlobaisValorRendimentosTab": "Valor de ingresos",
		"viewInfoDadosManuaisTab": "Datos manuales",
		"viewInfoModalTitle": "Año: {{year}} Mes: {{month}} - Muestra información salarial",
		"viewResultBtn": "Vista",
		"viewSends": "Prueba/Doc.",
		"vizProcExistentes": "Ver procesos existentes",
		"fields": {
			"abonosOutros": "Otras asignaciones",
			"abonosRendimentosNaoSujeitos": "Ingresos no sujetos",
			"abonosRendimentosSujeitos": "Asunto de ingresos",
			"anoAntRend": "Ingresos del año anterior",
			"codEmp": "código emp.",
			"contribuicosObrigatoriasNIF1": "Contribución",
			"contribuicosObrigatoriasNIF2": "Contribución",
			"contribuicosObrigatoriasNIF3": "Contribución",
			"contribuicosObrigatoriasValores": "Contribución",
			"dataProcessamento": "Fecha de proceso",
			"descObrigSSocial": "Contribución",
			"descontosContribuicoesObrigatorias": "Contribuciones obligatorias",
			"descontosIRS": "Retención del IRS",
			"descontosOutros": "Otros descuentos",
			"descontosSindicatos": "Sindicatos",
			"descontosSobretaxa": "Retención de recargo",
			"descrInsManual": "Tipo de inserción",
			"descricaoAbono": "Desc.",
			"dmrAno": "Año de proceso.",
			"dmrMes": "Mes de proceso.",
			"empresa": "Empresa",
			"enviaparaDMR": "Enviar a DMR",
			"irsRetido": "Retención del IRS",
			"liquidoCalculado": "Líquido calculado",
			"liquidoVencimentos": "Vencimientos netos",
			"localObtencaoRend": "Ubicación de los ingresos",
			"mesAno": "Mes/Año",
			"nCodAbdes": "Asignación/descuento",
			"nProcessamento": "Proceso no.",
			"nif": "número de contribuyente",
			"nome": "Nombre",
			"nomeEmpresa": "Nombre de empresa",
			"origemRendiDescr": "Origen",
			"outrosAbonos": "Otras asignaciones",
			"outrosDescontos": "Otros descuentos",
			"queFazer": "como resolver",
			"quotizSindical": "Cuotas sindicales",
			"quotizacaoSindical": "Cuotas sindicales",
			"rendAnoAnteriorAno": "Producir",
			"rendAnoAnteriorValores": "Producir",
			"rendNaoSujeitos": "Producir",
			"rendSujeitos": "Producir",
			"rendimentodoano": "Producir",
			"rendimentosAnoAnterior": "Valor de ingresos (años anteriores)",
			"retencaoIRS": "Retención del IRS",
			"retencaoSobretaxa": "Retención de recargo",
			"selected": "SEL",
			"sobretaxaExtraord": "Retenciones de Recargos",
			"statusImage": "Estado",
			"tipRendiment": "Consejo.",
			"tipoProcessamento": "Tipo de proceso.",
			"tipoProcessamentoDescricao": "Descripción",
			"tipoRendimento": "Tipo de rendimiento.",
			"totalDMRATRemun": "Total - Remuneración seg.",
			"totalValorContribuicoesObrigatorias": "Total - Contribuciones obligatorias",
			"totalValorQuotizacaoSindicais": "Total - Valor de las cuotas sindicales",
			"totalValorRendimentos": "Total - Valor de los ingresos",
			"totalValorRetencaoIRS": "Total - Retención del IRS",
			"totalValorRetencaoSobretaxa": "Total: importe de retención del recargo",
			"totalVencRemun": "Total - Remuneración proc.",
			"valorInciden": "Valor de ingresos",
			"valorNaoSujeito": "Producir",
			"wsEstadoFicheiroStr": "EN estado de envío"
		},
		"btn": {
			"ordenar": "Ordenar por: {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "código de empresa",
				"sortEmpresasByName": "Nombre de empresa"
			}
		}
	},
	"proceSalarios": {
		"tabIntroCaption": "Introducción",
		"tabIntroTitle": "Bienvenido al Asistente de Procesamiento de Nómina",
		"tabIntroSubTitle": "El asistente te ayudará a procesar los salarios.",
		"tabEmpresasCaption": "Empresas",
		"tabConfigCaption": "Ajustes",
		"tabProcCaption": "Tratamiento",
		"tabResultsCaption": "Informe",
		"jobTimeoutModalTitle": "Tarea",
		"jobTimeoutModalMessage": "La tarea ha expirado.",
		"processar": "Proceso",
		"processingMessage": "Procesando, espere un momento.",
		"modalRemoveSelEmpConfirmMsg": "¿Está seguro de que desea eliminar a los empleados seleccionados de este procesamiento?",
		"comecar": "para empezar",
		"tipoProcessamentoGerarTitle": "Tipo de procesamiento a generar",
		"tipoProceMensalCaption": "Procesamiento mensual",
		"tipoProceFeriasCaption": "Procesamiento de vacaciones",
		"tipoProceNatalCaption": "Procesamiento natal",
		"dataParaProcessamentoCaption": "Fecha de procesamiento",
		"dia": "Día",
		"podeReprocessarSalariosCaption": "Reprocesamiento de salario (Con procesamiento en el mes)",
		"podeReprocessarSalariosHelpText1": "Sólo puedes reprocesar salarios",
		"podeReprocessarSalariosHelpText2": "- Si solo hay un Tratamiento en el mes seleccionado.",
		"podeReprocessarSalariosHelpText3": "- El tipo de procesamiento existente no se puede cambiar.",
		"procExistentesTitle": "Proc.",
		"processModalMessage": "Procesando... espere un momento.",
		"estimativa": "estimé",
		"avisosErros": "Advertencias/Errores",
		"fields": {
			"selected": "SEL",
			"mesAno": "Mes/Año",
			"nEmpresa": "Empresa",
			"nomeEmpresa": "Nombre de empresa",
			"dataProce": "Fecha de procesamiento",
			"dADataProc": "Fecha de inicio",
			"ateDataProc": "Fecha de finalización",
			"nDiasUteis": "Número de días laborables",
			"descricao": "Descripción",
			"nDiasTotalMes": "Número total de días por mes",
			"calendar": "Calendario",
			"tipoProcessamento": "Tipo de procesamiento",
			"codEmp": "Código.",
			"nome": "Nombre",
			"nBIdentidade": "Número BI / Tarjeta de ciudadano",
			"nContribuinte": "NIF",
			"dataNascimento": "Fecha de nacimiento",
			"strEstadoProcEmpregado": "Estado",
			"nProcessamento": "Proceso no.",
			"nRecibo": "Recibo no.",
			"dataProcessamento": "Fecha de proceso",
			"identificacaoEmpregado": "Empleado",
			"dataInicoProc": "Fecha de inicio",
			"dataFimProce": "Fecha de finalización",
			"nDiasParaProcessamen": "Número de días hábiles para el procesamiento.",
			"anoParaSegSocial": "Año (SS)",
			"mesParaSegSocial": "Mes (SS)",
			"proceImportado": "Proc.",
			"tipoProcessamentoDescricao": "Tipo de proceso."
		},
		"temSeleccionarUmMesParaProcessar": "Debes seleccionar un mes para procesar",
		"temSeleccionarPeloMenosTipoProcessamento": "Debe seleccionar al menos un tipo de procesamiento",
		"promptViewResultsTitle": "Procesamiento de nómina",
		"promptViewResultsMessage": "El procesamiento de nómina ha finalizado.",
		"viewResultBtn": "Vista",
		"initNewProc": "Empezar nuevo",
		"mostrarSoComErros": "Mostrar solo con errores",
		"segSocialRelativaTitle": "Seguridad social relativa a",
		"novoProcessamentoTitle": "Nuevo procesamiento",
		"empregadosTitle": "Empleados",
		"selectAll": "Seleccionar todo",
		"unSelectAll": "Borrar selección",
		"temSeleccionarUmEmpregado": "Debe seleccionar al menos un empleado para procesar.",
		"singleProcDescDef": "Proc.",
		"temSeleccionarUmaEmpresa": "Debes seleccionar al menos una empresa para continuar.",
		"addEmpregado": "Agregar empleado(s)",
		"removeSelEmpregado": "Eliminar empleados seleccionados",
		"addSelected": "Agregar seleccionado",
		"pesqEmpregados": "Búsqueda de empleados",
		"reprocEmpregadoTooltip": "Reprocesar empleado",
		"reprocEncargosTooltip": "Cargos de reprocesamiento",
		"confirmReProcMsg": "¿Está seguro de que desea reprocesar al empleado {{codEmp}} de este procesamiento?",
		"confirmReProcEncargoMsg": "¿Está seguro de que desea volver a procesar los cargos del empleado {{codEmp}} - {{name}} para este procesamiento?",
		"confirmReProcSelMsg": "¿Está seguro de que desea reprocesar a los empleados seleccionados de este procesamiento?",
		"empregadoReprocessado": "El empleado {{codEmp}} ha sido reprocesado.",
		"encargosReprocessados": "Se reprocesaron los cargos de {{codEmp}} del empleado.",
		"empregadoSelReprocessado": "Los empleados seleccionados fueron reprocesados.",
		"deleteProcess": "Eliminar el procesamiento",
		"empregadoTemTransfBancariaPrompt": "Atención, el empleado {{codEmp}} - {{nome}} ya ha creado una transferencia bancaria para este procesamiento.",
		"naoPodeReprocExisteTransfBancaria": "No puede reprocesar el procesamiento del empleado {{codEmp}} - {{name}} hay una transferencia bancaria.",
		"empregadoTemFichSegSocialPrompt": "Atención, el empleado {{codEmp}} - {{nome}} ya ha creado un expediente de seguridad social.",
		"naoPodeReprocExisteFichSegSocial": "No se puede reprocesar el trámite del empleado {{codEmp}} - {{nome}}, el expediente de seguridad social ya fue creado.",
		"existemEmpregadosQueNaoPodemSerReProc": "Hay empleados que no se pueden reprocesar: <br/><br/> {{content}} <br/><br/>¿Quieres ignorarlos y continuar con los que se pueden reprocesar?",
		"naoPodeReprocIntegContab": "No puede reprocesar el procesamiento.",
		"messages": {
			"empEliminadoSucesso": "¡Empleado eliminado exitosamente!",
			"empAdicionadoSucesso": "¡Empleado agregado exitosamente!",
			"naoContemEmpSelecionado": "¡Debes seleccionar al menos un empleado!"
		},
		"btn": {
			"ordenar": "Ordenar por: {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "código de empresa",
				"sortEmpresasByName": "Nombre de empresa"
			}
		},
		"consultarRecibo": "Consultar recibos",
		"consultarReciboEmpregado": "Consultar recibos de empleados.",
		"existemCamposComErros": "Hay campos con errores.",
		"promptDeleteProcTitle": "Eliminar el procesamiento",
		"promptDeleteProcMessage": "¿Está seguro de que desea eliminar el procesamiento?",
		"reprocSelected": "Reproducir."
	},
	"proceSegSocial": {
		"tabIntroCaption": "Introducción",
		"tabIntroTitle": "Bienvenido al Asistente de Tramitación del Seguro Social",
		"tabIntroSubTitle": "El asistente te ayudará a generar el expediente de seguridad social",
		"tabEmpresasCaption": "Empresas",
		"tabConfigCaption": "Ajustes",
		"tabProcCaption": "Tratamiento",
		"tabResultsCaption": "Informe",
		"temSeleccionarUmaEmpresa": "Debes seleccionar al menos una empresa para continuar.",
		"temSeleccionarUmMesParaProcessar": "Debes seleccionar un mes para procesar",
		"vizProcExistentes": "Ver procesos existentes",
		"procAlertMsg1": "Por defecto generará seg.",
		"procAlertMsg2": "Si desea verificar los procesos existentes, seleccione el campo a continuación",
		"temSeleccionarDatas": "Debes seleccionar al menos una fecha.",
		"procExistentesReprocMessage": "Hay empresas con procesamiento generado para la seguridad social.",
		"reprocEmpSelected": "Sí",
		"loadProcSavedAndGenNewsForNotProccessed": "No",
		"geracaoImpressao": "Generación e impresión",
		"promptViewResultsTitle": "Tramitación de la seguridad social",
		"promptViewResultsMessage": "La tramitación de la Seguridad Social ha finalizado.",
		"viewResultBtn": "Vista",
		"initNewProc": "Empezar nuevo",
		"erros": "Errores",
		"printMapPerEmp": "Imprimir mapa por empleado",
		"printSummary": "Imprimir resumen",
		"genDRIFile": "Generar archivo para DRI",
		"sendFile": "Enviar archivo de servicio web",
		"viewSends": "Consultar envío WebService",
		"printMapPerEmpModalTitle": "Impresión de mapas de seguridad social.",
		"printSummaryModalTitle": "Impresión de mapas resumen de expedientes de seguridad social",
		"semAlteracaoParaGerar": "El sistema no generó ningún archivo porque ya fue generado anteriormente.",
		"viewInfoSSModalTitle": "Año: {{year}} Mes: {{month}} - Muestra información de seguridad social",
		"codPTitle": "Código P",
		"codITitle": "Código I",
		"nDiasCodPLabel": "Código P - Número de días",
		"nDias2Label": "Código 2 - Número de días",
		"nDiasILabel": "Código I - Número de días",
		"valLabel": "Valor",
		"valRLabel": "Código R - Valor",
		"valFLabel": "Código F - Valor",
		"valNLabel": "Código N - Valor",
		"valALabel": "Código A - Valor",
		"valBLabel": "Código B - Valor",
		"valCLabel": "Código C - Valor",
		"valDLabel": "Código D - Valor",
		"valHLabel": "Código H - Valor",
		"valMLabel": "Código M - Valor",
		"valOLabel": "Código O - Valor",
		"valSLabel": "Código S - Valor",
		"valTLabel": "Código T - Valor",
		"valXLabel": "Código X - Valor",
		"val6Label": "Código 6 - Valor",
		"val2Label": "Código 2 - Valor",
		"valILabel": "Código I - Valor",
		"valPLabel": "Código P - Valor",
		"infoSSSaved": "Datos guardados exitosamente.",
		"promptInfoSSSave": "Modificarás los datos del fichero que se enviará a la seguridad social.",
		"nibADebitar": "IBAN a Débito",
		"obterPdf": "Obtener PDF",
		"promptAlertModalTitle": "¡Atención!",
		"promptSendModalMessage": "Enviará los archivos a la seguridad social a través de WebService.",
		"enviadoComSuccess": "Los archivos se enviaron correctamente.",
		"enviadoComSuccesso": "Los archivos se enviaron correctamente.",
		"enviadoComErros": "Hubo errores en los archivos enviados.",
		"jobTimeoutModalTitle": "Tarea",
		"jobTimeoutModalMessage": "La tarea ha expirado.",
		"comecar": "para empezar",
		"fields": {
			"selected": "SEL",
			"mesAno": "Mes/Año",
			"tipoProcessamento": "Tipo de proceso.",
			"tipoProcessamentoDescricao": "Descripción",
			"dataProcessamento": "Fecha de proceso",
			"empresa": "Empresa",
			"nomeEmpresa": "Nombre de empresa",
			"ssAno": "Año lun.",
			"ssMes": "Mes lun.",
			"fichGeradoPodeReprocessarFicheiro": "Puede reprocesar",
			"estadoFicheiroStr": "Estado del archivo",
			"ssInfoEstadoProcessamentoStr": "estado SS",
			"totalRemuneracaoSegSocial": "Total - Remuneración",
			"totalContribuicaoSegSocial": "Total - Contribución seg.",
			"totalRemuneracaoVencimento": "Total - Remuneración",
			"queFazer": "como resolver",
			"codEmp": "código emp.",
			"nome": "Nombre",
			"numSS": "SS No.",
			"totalSegSocialRemun": "Total - Remuneración seg.",
			"totalVencRemun": "Total - Remuneración proc.",
			"taxaAAplicar": "Tx (%)"
		},
		"configWsSSTooltip": "Configuración del WebService de la Seguridad Social",
		"btn": {
			"ordenar": "Ordenar por: {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "código de empresa",
				"sortEmpresasByName": "Nombre de empresa"
			}
		}
	},
	"processos": {
		"title_detail": "Proceso {{id}}",
		"title_new": "Nuevo proceso",
		"title_plural": "Procesos",
		"pesquisa": "buscar",
		"saved": "Proceso {{id}}, guardado exitosamente.",
		"error": "Proceso {{id}}, no se puede guardar.",
		"deleted": "Proceso {{id}}, eliminado exitosamente",
		"fields": {
			"nRefProcesso": "Proceso",
			"nRefProcessoPlaceholder": "Número de proceso",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del proceso",
			"nCCusto": "No. C. Costo",
			"nomeCCusto": "Nombre C. Costo",
			"nomeCCustoPlaceholder": "centro de costos",
			"nClifo": "Cliente",
			"nClifoPlaceholder": "cuenta de cliente",
			"nomeClifo": "Nombre del cliente",
			"nomeClifoPlaceholder": "Nombre del cliente",
			"terminado": "Finalizado"
		}
	},
	"procseguradorafolhaferias": {
		"steps": {
			"introducao": "Introducción",
			"empresas": "Empresas",
			"configuracao": "Ajustes",
			"relatorio": "Informe",
			"title": {
				"titleintro": "Bienvenido al asistente de procesamiento de nómina de vacaciones de seguros",
				"subtitleintro": "El Asistente te ayudará a generar el archivo de Hoja de Vacaciones para la compañía de seguros.",
				"titleempresas": "Empresas con procesamiento",
				"subtitleempresas": "Elija empresas para generar archivo",
				"titleconfig": "Fechas de procesamiento",
				"subtitleconfig": "Selección de fechas de procesamiento de fecha de vencimiento",
				"titlerelatorio": "Generación e impresión de hojas de vacaciones para compañías de seguros.",
				"errossegSocial": "Errores de la compañía de seguros",
				"erros": "Errores"
			}
		},
		"fields": {
			"selected": "Sel.",
			"mesAno": "Mes/Año",
			"tipoProcessamento": "Tipo de proceso.",
			"tipoProcessamentoDescricao": "Descripción",
			"dataProcessamento": "Fecha de procesamiento",
			"empresa": "Empresa",
			"nomeEmpresa": "Nombre de empresa",
			"temFichGerado": "Hay un archivo generado",
			"nempresa": "Empresa",
			"queFazer": "como resolver",
			"totalRemuneracaoSegSocial": "Total - Remuneración seg.",
			"email": {
				"caption": "Correo electrónico",
				"placeholder": "Email para enviar archivo"
			}
		},
		"messages": {
			"temSeleccionarDatas": "Debes seleccionar al menos una fecha.",
			"invalidStepEmp": "Debe seleccionar al menos una empresa para procesar.",
			"invalidStepConf": "No hay procesamiento para el año/mes seleccionado.",
			"empresascomerros": "¡Hay empresas que cometen errores!",
			"email": {
				"enviado": "¡Correo electrónico enviado exitosamente!",
				"naoenviado": "El correo electrónico no fue enviado."
			}
		},
		"prompt": {
			"confMessage": "Hay empresas con el trámite generado con la hoja de Vacaciones de la compañía aseguradora.",
			"geraFich": "Generará el archivo de la hoja de vacaciones de la compañía aseguradora."
		},
		"btn": {
			"printmap": "Imprimir mapa por empleado",
			"genfichsegu": "Generar archivo para compañía de seguros.",
			"previsualizar": "Avance",
			"ordenar": "Ordenar por: {{orderby}}",
			"dropdown": {
				"sortEmpresasByNEmpresa": "código de empresa",
				"sortEmpresasByName": "Nombre de empresa"
			},
			"enviaEmail": "Enviar archivo por correo electrónico"
		},
		"modal": {
			"impressaomapas": "Impresión de hojas de vacaciones para compañías de seguros."
		}
	},
	"profissao": {
		"title_detail": "Profesión {{id}}",
		"title_new": "Profesión",
		"title_plural": "Profesiones",
		"pesquisa": "buscar",
		"deleted": "Profesión eliminada con éxito.",
		"saved": "Profesión {{id}}, guardada exitosamente.",
		"error": "Profesión {{id}}, no se puede guardar.",
		"fields": {
			"nProfissao": "código de profesión",
			"designaBreve": "Designación",
			"designaCompl": "Designación completa",
			"codClassNaCProf": "código nacional de profesión"
		}
	},
	"protecaoPassword": {
		"modal": {
			"save": "El tipo de contraseña elegido se guardó correctamente",
			"tipoPassword": "¿Protegen con contraseña los recibos que se envían por correo electrónico?",
			"title": "Seleccione tipo de protección"
		}
	},
	"qivaas": {
		"title_detail": "Campo de declaración anual de IVA {{id}}",
		"title_new": "Nuevo Campo de la declaración anual de IVA",
		"title_plural": "Campos de declaración anual de IVA",
		"pesquisa": "buscar",
		"saved": "Campo de declaración anual de IVA {{id}}, guardado correctamente.",
		"error": "Campo de declaración anual de IVA {{id}}, no se puede guardar.",
		"deleted": "Campo de declaración anual de IVA {{id}}, eliminado correctamente.",
		"fields": {
			"nCampo": "Código de campo",
			"nCampoPlaceholder": "Código de campo de declaración anual de IVA",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del campo de la declaración anual de IVA",
			"nQuadro": "cuadro no.",
			"nQuadroPlaceholder": "Número de tabla del campo de declaración anual de IVA"
		}
	},
	"qivaps": {
		"title_detail": "Campo de declaración periódica de IVA {{id}}",
		"title_new": "Nuevo campo de declaración periódica de IVA",
		"title_plural": "Campos de declaración periódica de IVA",
		"pesquisa": "buscar",
		"saved": "Campo de declaración periódica de IVA {{id}}, guardado correctamente.",
		"error": "Campo de declaración periódica de IVA {{id}}, no se puede guardar.",
		"deleted": "Campo de declaración periódica de IVA {{id}}, eliminado correctamente.",
		"fields": {
			"nCampo": "Código de campo",
			"nCampoPlaceholder": "Código de campo de declaración periódica de IVA",
			"nomeCampo": "Nombre",
			"nomeCampoPlaceholder": "Nombre del campo de la declaración periódica de IVA",
			"nQuadro": "cuadro no.",
			"nQuadroPlaceholder": "Tabla No. del campo de declaración periódica de IVA",
			"nCampoOficial": "Código de campo oficial",
			"nCampoOficialPlaceholder": "Código de campo de la declaración periódica oficial de IVA"
		}
	},
	"qpapa": {
		"title_detail": "Asociación de empleadores {{id}}",
		"title_new": "Asociación de nuevos empleadores",
		"title_plural": "Asociaciones de empleadores",
		"pesquisa": "buscar",
		"saved": "Asociación de empleadores {{id}}, guardado exitosamente.",
		"error": "Asociación de empleadores {{id}}, no se puede guardar.",
		"deleted": "Asociación de empleadores {{id}}, eliminada correctamente",
		"fields": {
			"codAssocPatronal": "Código.",
			"codAssocPatronalPlaceholder": "código de asociación de empleadores",
			"nomeAssocPatronal": "Nombre",
			"nomeAssocPatronalPlaceholder": "Nombre",
			"morada": "Familiar",
			"moradaPlaceholder": "Familiar",
			"codPostal": "Código postal",
			"codPostalPlaceholder": "Código postal",
			"localidade": "Ubicación",
			"localidadePlaceholder": "Ubicación"
		}
	},
	"qpcae": {
		"title_detail": "Código de Actividad Económica {{id}}",
		"title_new": "Nueva actividad económica",
		"title_plural": "Códigos de actividad económica",
		"pesquisa": "buscar",
		"saved": "Actividad económica {{id}}, guardada exitosamente.",
		"error": "Actividad económica {{id}}, no se puede guardar.",
		"deleted": "Actividad económica {{id}}, eliminada correctamente",
		"fields": {
			"codcae": "Código.",
			"codcaePlaceholder": "Código CAE",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre",
			"codSubSec": "Subsección",
			"codSubSecPlaceholder": "Subsección"
		}
	},
	"qpcnp": {
		"title_detail": "Profesión {{id}}",
		"title_new": "nueva profesion",
		"title_plural": "Profesiones",
		"pesquisa": "buscar",
		"saved": "Profesión {{id}}, guardada exitosamente.",
		"error": "Profesión {{id}}, no se puede guardar.",
		"deleted": "Profesión {{id}}, eliminada correctamente.",
		"fields": {
			"codNacProfissao": "Código",
			"nome": "Nombre",
			"tipo": "Tipo"
		}
	},
	"qpdcf": {
		"title_detail": "Código de distrito/condado/parroquia {{id}}",
		"title_new": "Nuevo código de distrito/condado/parroquia",
		"title_plural": "Códigos de distrito/condado/parroquia",
		"pesquisa": "buscar",
		"saved": "Código de Distrito/Municipio/Parroquia {{id}}, guardado exitosamente.",
		"error": "Código de Distrito/Municipio/Parroquia {{id}}, no se puede guardar.",
		"deleted": "Código de Distrito/Municipio/Parroquia {{id}}, eliminado exitosamente",
		"fields": {
			"codDistConcFreg": "Código.",
			"codDistConcFregPlaceholder": "Código de distrito/condado/parroquia",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre",
			"codDistrito": "código de distrito",
			"codDistritoPlaceholder": "código de distrito",
			"codConcelho": "código del condado",
			"codConcelhoPlaceholder": "código del condado",
			"codFreguesia": "código parroquial",
			"codFreguesiaPlaceholder": "código parroquial",
			"codServicoFinancas": "Código de servicio financiero",
			"codServicoFinancasPlaceholder": "Código de servicio financiero",
			"nomeServicoFinancas": "Nombre del servicio financiero",
			"nomeServicoFinancasPlaceholder": "Nombre del servicio financiero"
		}
	},
	"qpdur": {
		"title_detail": "Duración del tablero personal {{id}}",
		"title_new": "Duración del nuevo marco personal",
		"title_plural": "Duración de los tableros personales",
		"pesquisa": "buscar",
		"saved": "Duración del perfil personal {{id}}, guardado correctamente.",
		"error": "Duración del perfil personal {{id}}, no se puede guardar.",
		"deleted": "Duración del perfil personal {{id}}, eliminado correctamente.",
		"fields": {
			"codDuracao": "Código de duración",
			"nome": "Nombre"
		}
	},
	"qphab": {
		"title_plural": "Calificaciones",
		"pesquisa": "buscar",
		"fields": {
			"codHabilitacao": "Código de calificación",
			"nome": "Nombre"
		}
	},
	"qpirt": {
		"title_detail": "Búsqueda IRCT {{id}}",
		"title_new": "Nueva investigación del IRCT",
		"title_plural": "IRCT",
		"pesquisa": "buscar",
		"saved": "Búsqueda IRCT {{id}}, guardada correctamente.",
		"error": "Búsqueda IRCT {{id}}, no se puede guardar.",
		"deleted": "Búsqueda IRCT {{id}}, eliminada correctamente",
		"fields": {
			"codIRCT": "Código IRCT",
			"nome": "Nombre del IRCT"
		}
	},
	"qpisp": {
		"title_detail": "Código ISP {{id}}",
		"title_plural": "códigos ISP",
		"pesquisa": "buscar",
		"fields": {
			"codISP": "código proveedor de servicios de Internet",
			"nome": "Nombre"
		}
	},
	"qpmte": {
		"title_detail": "Motivo de entrada del empleado {{id}}",
		"title_new": "Nuevo motivo de entrada de empleados",
		"title_plural": "Razones para la entrada de empleados",
		"pesquisa": "buscar",
		"saved": "Motivo de entrada del empleado {{id}}, guardado correctamente.",
		"error": "Motivo de entrada del empleado {{id}}, no se puede guardar.",
		"deleted": "Motivo de entrada del empleado {{id}}, eliminado correctamente",
		"fields": {
			"codmte": "Código MTE",
			"nome": "Nombre"
		}
	},
	"qpmts": {
		"title_detail": "Motivo por el que el empleado se va {{id}}",
		"title_new": "Nueva razón para la salida del empleado",
		"title_plural": "Razones para la salida del empleado",
		"pesquisa": "buscar",
		"saved": "Motivo por la salida del empleado {{id}}, guardado correctamente.",
		"error": "Motivo por el que el empleado dejó {{id}}, no se puede guardar.",
		"deleted": "Motivo para dejar al empleado {{id}}, eliminado exitosamente",
		"fields": {
			"codmts": "código MTS",
			"nome": "Nombre"
		}
	},
	"qpnj": {
		"title_detail": "Naturaleza Jurídica {{id}}",
		"title_new": "Nueva Naturaleza Jurídica",
		"title_plural": "Naturalezas Jurídicas",
		"pesquisa": "buscar",
		"saved": "Naturaleza Jurídica {{id}}, guardado exitosamente.",
		"error": "Naturaleza Jurídica {{id}}, no se puede guardar.",
		"deleted": "Naturaleza Jurídica {{id}}, eliminado exitosamente",
		"fields": {
			"codNatJuridica": "Código.",
			"codNatJuridicaPlaceholder": "código de naturaleza jurídica",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre"
		}
	},
	"qppaises": {
		"title_detail": "País {{id}}",
		"title_new": "Nuevo país",
		"title_plural": "Países del personal",
		"pesquisa": "buscar",
		"saved": "País {{id}}, guardado exitosamente.",
		"error": "País {{id}}, no se puede guardar.",
		"deleted": "País {{id}}, eliminado correctamente.",
		"fields": {
			"codPais": "Código.",
			"name": "Nombre",
			"abrev": "Abreviatura."
		}
	},
	"qpsit": {
		"title_detail": "Situación {{id}}",
		"title_new": "Nueva situación",
		"title_plural": "Situaciones",
		"pesquisa": "buscar",
		"saved": "Situación {{id}}, guardada exitosamente.",
		"error": "Situación {{id}}, no se puede guardar.",
		"deleted": "Estado {{id}}, eliminado correctamente",
		"fields": {
			"codsituacao": "Código de estado",
			"nome": "Nombre"
		}
	},
	"qptco": {
		"title_detail": "Tipo de contrato de personal {{id}}",
		"title_new": "Nuevo tipo de contrato de personal",
		"title_plural": "Tipo de contrato de personal",
		"pesquisa": "buscar",
		"saved": "Tipo de contrato de perfil personal {{id}}, guardado correctamente",
		"error": "Tipo de contrato de perfil personal {{id}}, no se puede guardar.",
		"deleted": "Tipo de contrato de perfil personal {{id}}, eliminado correctamente",
		"fields": {
			"codTContrato": "Tipo de contrato de código de personal",
			"nome": "Nombre"
		}
	},
	"rastreabilidade": {
		"title": "Trazabilidad",
		"export": {
			"svg": "Exportar SVG",
			"jpg": "Exportar JPG",
			"png": "Exportar PNG"
		}
	},
	"recaptcha": {
		"branding": {
			"1": "Este sitio está protegido por reCAPTCHA y se aplica a",
			"2": "de Google.",
			"privacy": "política de privacidad",
			"and": "y el",
			"terms": "Términos de servicio"
		},
		"v2modal": {
			"title": "Por favor, comprueba que no eres un robot."
		}
	},
	"reciboimprimir": {
		"label": {
			"emailcliente": "Correo electrónico del cliente",
			"enviar": "para enviar"
		},
		"email": {
			"required": "Para enviar, por favor rellene el campo \"<em>Correo electrónico del cliente",
			"emailEnviado": "Correo electrónico enviado a {{email}}",
			"emailErro": "Error al enviar el correo electrónico a {{email}}."
		}
	},
	"recibos": {
		"title_new": "Nuevo recibo",
		"title_detail": "Nuevo recibo {{id}}",
		"title_plural": "Ingresos",
		"pesquisa": "buscar",
		"saved": "Recibo guardado exitosamente.",
		"error": "El recibo no se puede guardar.",
		"fields": {
			"extPocCabID": "Identificación.",
			"numero": "Recibo no.",
			"nome": "Cliente",
			"nconta": "Cuenta",
			"stampUpdate": "Último.",
			"origem": "Origen del documento",
			"descricao": "Descripción",
			"abreviaturaMoeda": "Acuñar",
			"deData": "Fecha de",
			"nDocExterno": "Doc no."
		},
		"text": {
			"autoDistribute": "Distribuir valor automáticamente",
			"amountToReceive": "Cantidad a recibir"
		},
		"recibo": {
			"title": "Recibo",
			"nCaixaAux": "Método de pago",
			"nomeCaixaAux": "Nombre significa pago",
			"tipoCaixaAux": "Tipo de medio pago",
			"valorNCaixa": "Valor",
			"totalCaixa": "Entregado",
			"troco": "Cambiar",
			"cliente": "Cliente",
			"data": "Fecha",
			"percDescSobreValorIVA": "Descuento sobre el valor del IVA",
			"cab": {
				"extPocCabID": "ID de encabezado",
				"numero": "Número",
				"nConta": "Cuenta",
				"nome": "Nombre",
				"nif": "NIF",
				"valor": "Valor",
				"valorPlaceHolder": "Cantidad a recibir",
				"stampUpdate": "Cambiar fecha",
				"totalDescontos": "Descuentos completos",
				"totalReceber": "Total por cobrar",
				"totalRecebido": "Total recibido",
				"temRetencao": "Tiene retención",
				"totalRetencao": "Retención total",
				"descricao": "Descripción",
				"resumo": "Resumen",
				"valorEntregueCredito": "Total entregado a crédito"
			},
			"linhas": {
				"nlanc": "identificación de línea",
				"tipoDocumento": "Tipo de documento",
				"numeroDocumento": "Documento",
				"dataVencimento": "Fecha de expiración",
				"valor": "Valor",
				"valorPorReceber": "Valor a recibir",
				"percDesconto": "% Descuento",
				"valorDesconto": "Valor de descuento",
				"valorRecebido": "Cantidad recibida",
				"valorIVA": "valor del IVA",
				"montanteAReter": "Retención",
				"montanteRetido": "Valor de retención",
				"cambio": "Intercambio",
				"cambioOrigem": "Tipo de cambio de origen",
				"cambioPagam": "Pago de cambio",
				"dataDoc": "Fecha del doctor.",
				"dataDocExterno": "Fecha del doctor.",
				"nDocExterno": "Doc no.",
				"descricao": "Descripción",
				"valorDoc": "Valor del documento"
			}
		},
		"saveModal": {
			"title": "Guardar recibo",
			"paymentMeans": "Métodos de pago",
			"paymentMean": "Método de pago",
			"paymentValue": "Valor entregado",
			"payInFull": "Pagar todo",
			"clearValue": "Borrar valor",
			"document": "Documento",
			"save": "Ahorrar",
			"saveCheck": "Guardar y ver PDF"
		},
		"imprimirModal": {
			"title": "Recibo: {{accountname}}",
			"fields": {
				"numeroDeVias": "Número de carriles",
				"emailCliente": "Correo electrónico",
				"relatorio": "Informe"
			},
			"actions": {
				"send": "para enviar"
			},
			"placeholder": {
				"email": "\"correo@ejemplo.com\" o \"correo@ejemplo.com; correo2@ejemplo.com\""
			}
		},
		"info": {
			"anular": "¡Recibo cancelado exitosamente!",
			"anularRecibo": "Cancelar recibo",
			"temCertezaAnularRecibo": "¿Está seguro de que desea eliminar el recibo?",
			"naoefetuadoCG": "El recibo no se puede cancelar porque no se realizó en CentralGest Cloud",
			"semMovimentos": "¡Sin movimientos abiertos!",
			"vencido": "Atrasado",
			"naoVencido": "No caducado",
			"aReceber": "Cuenta por cobrar"
		},
		"erros": {
			"notselected": "¡No se seleccionó ningún recibo!",
			"anular": "¡Error al cancelar el recibo!",
			"valorDescontoMaiorValor": "Valor de descuento superior al importe recibido",
			"percentagemDescontoMaior100": "El porcentaje de descuento no puede exceder el 100%.",
			"invalidValorAPagar": "El valor entregado ({{valorEntregue}}) debe ser igual o mayor al valor del documento ({{valorDocumento}}).",
			"moedasdiferentesnosrecibos": "No es posible tener diferentes monedas en los recibos a contabilizar.",
			"errodesconhecido": "Error desconocido",
			"cambiocomvalorerrado": "Tipo de cambio incorrecto",
			"invalidHeaderDate": "La fecha de recepción no puede ser superior a 1 año a partir de la fecha actual.",
			"valorUltrapassado": "Se superó el monto a distribuir",
			"faltaDistribuirValor": "Debes distribuir la totalidad del importe ingresado"
		},
		"btn": {
			"receberTudo": "Recibe todo",
			"limpaTudo": "Borrar todos los valores recibidos",
			"distribuirValores": "Distribuir valor",
			"limpaValor": "Borrar cantidad recibida"
		},
		"modalDistribuir": {
			"tile": "Distribuye valor",
			"automatico": "Automático",
			"manual": "Manual",
			"tooltip": {
				"automatico": "Ordenados del más antiguo al más nuevo.",
				"manual": "Permite distribuir hasta alcanzar el valor indicado."
			}
		}
	},
	"recibosvencimentos": {
		"todos": "Todos los colaboradores",
		"colaborador": "Colaborador",
		"gravado": "Grabado",
		"reciboimpresso": "Recibo impreso",
		"processadosegsocial": "Procesado en seg.",
		"integradocontabilidade": "Integrado en la contabilidad",
		"mostraGrafico": "Muestra gráfico al recibir",
		"email": {
			"emailEnviado": "¡Correo electrónico enviado exitosamente!",
			"emailNaoEnviado": "Se produjo un error al enviar el correo electrónico.",
			"insiraemail": "Debes ingresar un correo electrónico."
		},
		"modal": {
			"enviaremail": "Correo electrónico",
			"byEmailAlternativo": "Correo electrónico alternativo",
			"emailAlternativo": "Correo electrónico"
		}
	},
	"reconstrucaoacumulados": {
		"title": "Reconstrucción acumulada",
		"pesquisa": "buscar",
		"steps": {
			"info": "Inicio de la reconstrucción de los activos acumulados",
			"variables": "Datos para reconstruir el acumulado",
			"finish": "Finalizar"
		},
		"fields": {
			"tipoPoc": "Tipo de cuenta",
			"nConta": "Número de cuenta",
			"contaEspecifica": "Solo acumulado de la cuenta.",
			"todasAsContas": "Todas las cuentas"
		},
		"description": {
			"firstParagraph": "Esta operación reconstruirá todas las cuentas acumuladas en el plan de cuentas.",
			"secondParagraph": "Esta operación es exclusiva.",
			"finishMessage": "Esta operación puede tardar algún tiempo, para continuar presione finalizar.",
			"atencao": "¡Atención!"
		},
		"alerts": {
			"reconstrucaoSucesso": "La reconstrucción de la acumulación se completó con éxito"
		}
	},
	"reembolsosiva": {
		"title_detail": "Reembolso por periodo de declaración \"{{id}}\"",
		"title_detail_relacao": "Reembolso por periodo de relación \"{{id}}\"",
		"title_new": "Nuevo reembolso",
		"title_plural": "Devoluciones de IVA",
		"pesquisa": "buscar",
		"saved": "Devolución del IVA con periodo de declaración \"{{id}}\" procesada correctamente.",
		"savedAlt": "Reembolso de IVA con período de declaración \"{{id}}\" reprocesado exitosamente.",
		"error": "No se puede procesar la devolución del IVA con período de declaración \"{{id}}\".",
		"errorEmptyPeriodosRelacao": "No hay períodos de relación disponibles para el período de declaración seleccionado",
		"errorClosed": "Atención: el período de declaración seleccionado pertenece a un ejercicio contable que ya finalizó",
		"pristineEmptyPeriodosRelacao": "Seleccione un período de informe para elegir un período de informe",
		"deleteReembolsoTitle": "¿Borrar devolución del IVA?",
		"deleteReembolsoMessage": "¿Está seguro de que desea eliminar la devolución del IVA con el período de declaración \"{{declaracao}}\"?",
		"deleteReembolsoComplexMessage": "¿Está seguro de que desea eliminar la devolución del IVA con el período de declaración \"{{declaracao}}\" y período de relación \"{{relacao}}\"?",
		"deleteDocumentTitle": "¿Eliminar el documento?",
		"deleteDocumentMessage": "¿Está seguro de que desea eliminar el documento con el período de declaración \"{{declaracao}}\" y el período de relación \"{{relacao}}\"?",
		"process": "Proceso",
		"reProcess": "Reprocesar reembolso",
		"disabledHasErrors": "No es posible cambiar el período de relación si hay errores en los documentos.",
		"document": "Documento No.",
		"documentNoErrors": "El documento no tiene errores.",
		"documentHasErrors": "El documento tiene errores.",
		"fields": {
			"periodoDeclaracao": "Periodo de declaración",
			"periodoRelacao": "Período de relación",
			"periodosRelacao": "Períodos de relación",
			"nCampoDeducao": "Número de campo de deducción",
			"mercado": "Mercado",
			"periodo": "Período",
			"nDiario": "A diario",
			"nDocInterno": "Número de documento",
			"codPais": "Código del país",
			"abrevFiscal": "Prefijo",
			"nContribuinte": "Número de contribución",
			"dC": "Débito/Crédito",
			"valorTransacaoAbsoluto": "Valor de adquisición sin IVA",
			"valorIvaDeduzidoAbsoluto": "Valor del IVA deducido",
			"nDocExterno": "Documento externo.",
			"dataDocExterno": "Fecha del doctor.",
			"nDeclarImportExport": "Líquido no.",
			"nConta": "Nro. de cuenta",
			"nomeConta": "Nombre",
			"anoDocExterno": "Año doctor.",
			"mesDocExterno": "Mes del doctor.",
			"all": "Todos los periodos de relación",
			"error": "error de documento"
		},
		"tabs": {
			"clients": "Clientes",
			"suppliers": "Proveedores",
			"regularizations": "Regularizaciones",
			"documents": "Documentos",
			"relations": "Relaciones",
			"clientDoc": "Clientes_Documentos_{{nreembolso}}",
			"clientRel": "Clientes_Relacoes_{{nreembolso}}",
			"suppliersDoc": "Clientes_Documentos_{{nreembolso}}",
			"suppliersRel": "Clientes_Relacoes_{{nreembolso}}"
		},
		"messages": {
			"successDeleteDocument": "Devolución del IVA eliminada correctamente"
		},
		"clientes": {
			"0": {
				"periodo": "Período"
			},
			"1": {
				"title": "Operaciones realizadas con clientes nacionales",
				"datagrid": {
					"nif": "Número de identificación fiscal",
					"valor": "Valor"
				}
			},
			"3": {
				"title": "exportacion de bienes",
				"datagrid": {
					"nIdnDeclaracaoExportacao": "Número de identificación de la declaración de exportación",
					"valor": "Valor"
				}
			},
			"4": {
				"title": "Operaciones realizadas en el extranjero",
				"valor": "Valor"
			},
			"5": {
				"title": "Otras operaciones exentas o sin liquidación del IVA que dan derecho a deducción",
				"valor": "Valor"
			},
			"6": {
				"valor": "Valor"
			},
			"title": "Relación de clientes cuyas operaciones resultaron en deducción de impuestos del período"
		},
		"fornecedores": {
			"0": {
				"periodo": "Período"
			},
			"1": {
				"campo20": "Campo 20"
			},
			"2": {
				"campo21": "Campo 21"
			},
			"3": {
				"campo22": "Campo 22"
			},
			"4": {
				"campo23": "Campo 23"
			},
			"5": {
				"campo24": "Campo 24"
			},
			"7": {
				"totalValorAquisicoes": "Valor total de las adquisiciones",
				"totalIvaDeduzido": "Total IVA deducido"
			},
			"title": "Relación de proveedores cuyas operaciones resultaron en deducción de impuestos del período",
			"datagrid": {
				"identificacao": "Identificación",
				"periodoEmissao": "Periodo de emisión",
				"prefixo": "Prefijo",
				"nif": "NIF",
				"nLiquidacao": "Número de liquidación",
				"valorAquisicoes": "Valor de las adquisiciones",
				"ivaDeduzido": "IVA deducido"
			}
		},
		"mercadoControloIva": {
			"notApplicable": "N / A",
			"national": "Nacional",
			"cee": "UE",
			"thirdPartyCountries": "Terceros países"
		},
		"action": {
			"editDoc": "Editar documento",
			"deleteLine": "Eliminar línea"
		},
		"showDocsWithErrorOnly": "Mostrar solo documentos con errores"
	},
	"refeicoes": {
		"text": {
			"local": "Ubicación",
			"menu": "Menú",
			"marcar": "para marcar",
			"desmarcar": "Desmarcar",
			"refeicoes": "Comidas"
		},
		"clonarmodal": {
			"title": "Clonar menús",
			"fields": {
				"localDestino": "Ubicación de destino",
				"dataInicio": "Fecha de inicio",
				"dataFim": "Fecha de finalización"
			}
		}
	},
	"regIRC": {
		"data": {
			"geral": "General",
			"naoSujeicao": "Sin sujeción",
			"isencaoDefinitiva": "Exención definitiva",
			"isencaoTemporaria": "Exención temporal",
			"reducaoTaxa": "Reducción de tarifas",
			"transicaoBenificiosFiscais": "Beneficios fiscales de transición",
			"transparenciaFiscal": "Transparencia fiscal",
			"lucroConsolidado": "Beneficio consolidado",
			"simplificado": "Simplificado",
			"normal": "Normal",
			"isencao": "Exención",
			"pequenosRetalhistas": "Pequeños minoristas",
			"regimeNormalBensSegMao": "Régimen normal + bienes de 2ª mano",
			"regimeNormalRevendaCombustiveis": "Régimen normal + reventa de combustible",
			"regimeNormalIvaCaixa": "Régimen normal + IVA al contado"
		}
	},
	"rendhabitperm": {
		"fields": {
			"codEmp": "Empleado",
			"codAbdes": "Prestación",
			"anoProcessamento": "Año de procesamiento",
			"valorRend": "Valor de ingresos",
			"rhTipoProce": "Tratamiento"
		},
		"filename": "Ingresos de vivienda permanente",
		"tipoRendHabitPerm": {
			"todas": "Todo",
			"ativas": "Activo",
			"inativas": "Inactivo"
		},
		"toolbar": {
			"tipoRendHabitPerm": "Ver rendimiento."
		},
		"modal": {
			"title": "Mantenimiento de ingresos mediante el uso de vivienda permanente.",
			"fields": {
				"tipoProcessamento": "Tipo de procesamiento",
				"codAbdes": "Prestación",
				"codEmp": "código de empleado",
				"anoProcessamento": "Año de procesamiento",
				"mesInicioProce": "Mes de inicio",
				"suspende": "Suspendido",
				"valor": "Valor",
				"obs": "Observaciones",
				"obsSupensao": "Observaciones de suspensión",
				"valorRenda": "Valor de ingresos",
				"valorRendaMaxMuni": "Valor máximo de ingresos (Por Tipología de Municipio)"
			},
			"message": {
				"msgRendaMaxMuni": "Ingresos máximos permitidos para el año 2024 de acuerdo con lo dispuesto en el decreto-ley n° 90-c/2022, de 30 de diciembre (ordenado por municipio)"
			}
		}
	},
	"rendis": {
		"title_detail": "Tipo de ingreso {{id}}",
		"title_new": "Nuevo tipo de ingresos",
		"title_plural": "Tipos de ingresos",
		"pesquisa": "buscar",
		"saved": "Guardado exitosamente.",
		"error": "Tipo de ingreso {{id}}, no se puede guardar.",
		"deleted": "Tipo de ingreso {{id}}, eliminado correctamente.",
		"deleteModalText": "¿Eliminar registro \"{{tipRendiment}}\"?",
		"fields": {
			"tipRendiment": "Tipo de ingreso",
			"nome": "Nombre del rendimiento",
			"tipRendimentId": "Código de tipo de ingreso",
			"tipoDeclaracao": "Tipo de declaración"
		}
	},
	"tiposDeclaracao": {
		"data": {
			"modelo10": "Modelo 10",
			"modelo22": "Modelo 22",
			"modelo30": "Modelo 30",
			"modelo39": "Modelo 39"
		}
	},
	"repfinancas": {
		"title_detail": "Departamento de Finanzas {{id}}",
		"title_new": "Nuevo Departamento de Finanzas{{id}}",
		"title_plural": "Oficinas de Impuestos",
		"pesquisa": "buscar",
		"saved": "Oficina de Impuestos {{id}}, guardado exitosamente",
		"error": "Oficina de Impuestos {{id}}, no se puede guardar.",
		"deleted": "Oficina de Impuestos {{id}}, eliminada exitosamente",
		"fields": {
			"codFiscal": "código de división",
			"nome": "nombre del departamento",
			"tipo": "tipo de financiación",
			"morada": "Familiar",
			"codPostal": "Código postal",
			"codDistrito": "código de distrito",
			"nomeDistrito": "Nombre del distrito",
			"codPais": "Código del país",
			"nomePais": "Nombre del país",
			"telefone": "Teléfono",
			"fax": "Fax",
			"codFinancasAT": "En el código de finanzas"
		},
		"tipo": {
			"bairroFiscal": "Distrito fiscal",
			"reparticao": "Descomponer"
		}
	},
	"reparticoesccustos": {
		"title_detail": "Desglose por centro de coste {{id}}",
		"title_new": "Nuevo desglose por centro de coste",
		"title_plural": "Desgloses por centro de coste",
		"pesquisa": "buscar",
		"add": "para agregar",
		"saved": "Desglose por Centro de Costo {{id}}, guardado exitosamente",
		"error": "Desglose por Centro de Costo {{id}}, no se puede guardar.",
		"deleted": "Desglose por centro de costos {{id}}, eliminado exitosamente",
		"fields": {
			"nCodRepCC": "Mesa",
			"descricao": "Descripción",
			"designacao": "Designación",
			"nCodRepCCPlaceholder": "Mesa",
			"descricaoPlaceholder": "Designación",
			"valorMascara": "Centro de costos",
			"perc": "Porcentaje",
			"dataReferencia": "Fecha de referencia",
			"mascara": "Máscara C. Costo"
		},
		"mascara": {
			"cCusto": "Centro de costos",
			"zona": "Zona",
			"departamento": "Departamento",
			"SubDepartamento": "Subdepartamento",
			"familia": "Familia",
			"grandeFamilia": "familia gr.",
			"subFamilia": "Subfamilia",
			"codContab": "código contable",
			"tipoArtigo": "Tipo de artículo",
			"classe": "Clase",
			"categoria": "Categoría",
			"vendedor": "Vendedor",
			"nRefProcesso": "Proceso"
		},
		"title": {
			"apagarDatadeReferencia": "Eliminar fecha de referencia",
			"apagarItemPerDate": "Eliminar fecha",
			"apagarItem": "Eliminar el desglose por centro de costes"
		},
		"message": {
			"desejaApagarDataReferencia": "¿Quieres eliminar la fecha de referencia?",
			"reparticaoccustoEliminadoComSucesso": "Desglose por centro de costes eliminado con éxito",
			"desejaApagarItemPerDate": "Si elimina la última línea de la fecha seleccionada, se eliminará.",
			"desejaApagarItem": "Si elimina la última línea del desglose del centro de costos, se eliminará."
		},
		"text": {
			"addDate": "Agregar fecha",
			"selectDate": "Seleccionar fecha"
		}
	},
	"reparticoesccustosfaturacao": {
		"title_detail": "Desglose por centro de coste {{id}}",
		"title_new": "Nuevo desglose por centro de coste",
		"title_plural": "Desgloses por centro de coste",
		"pesquisa": "buscar",
		"saved": "Desglose por Centro de Costo {{id}}, guardado exitosamente",
		"error": "Desglose por Centro de Costo {{id}}, no se puede guardar.",
		"deleted": "Desglose por centro de costos {{id}}, eliminado exitosamente"
	},
	"reparticoesccustossalarios": {
		"title_detail": "Desglose por centro de coste {{id}}",
		"title_new": "Nuevo desglose por centro de coste",
		"title_plural": "Desgloses por centro de coste",
		"pesquisa": "buscar",
		"saved": "Desglose por Centro de Costo {{id}}, guardado exitosamente",
		"error": "Desglose por Centro de Costo {{id}}, no se puede guardar.",
		"deleted": "Desglose por centro de costos {{id}}, eliminado exitosamente"
	},
	"reports": {
		"pesquisa": "buscar",
		"fields": {
			"name": "Nombre",
			"namePlaceholder": "Nombre del informe",
			"title": "Título",
			"titlePlaceholder": "Título del informe"
		},
		"messages": {
			"noReports": "No se encontraron informes"
		}
	},
	"resumoiva": {
		"fields": {
			"codigo": "Código",
			"nome": "Nombre",
			"infoString": "NIF",
			"valor": "Valor",
			"percentagem": "%",
			"tipodocumento": "Tipo de documento",
			"iva": "TINA",
			"basetributavel": "Base imponible",
			"totais": "Totales"
		},
		"totalvendas": "Ventas totales",
		"btn": {
			"mes": "Mes",
			"primeiroTrimestre": "1er trimestre",
			"segundoTrimestre": "2do trimestre",
			"terceiroTrimestre": "3er trimestre",
			"quartoTrimestre": "4to trimestre"
		},
		"toolbar": {
			"month": "Mes: {{nameMonth}}"
		}
	},
	"retencoes": {
		"header": {
			"tipoVisualizacao": "Tipo de vista",
			"nifDe": "número de contribuyente",
			"tipRetServNif": "Entidad generalmente",
			"estadoRet": "Estado de retención",
			"origemContas": "Origen de la cuenta",
			"radio": {
				"retEfeutadas": "Retenciones realizadas",
				"docsSujeitosRet": "Documentos.",
				"sujeitoRetencao": "Para ser llevado a cabo",
				"retido": "Realizado",
				"ambos": "Ambos"
			}
		},
		"table": {
			"fields": {
				"isContaPocOrigemFornecedor": "Origen",
				"nContribuinte": "número de contribuyente",
				"conta": "Cuenta",
				"codRetencao": "Retención",
				"taxaRetUsada": "Tasa",
				"tipoRendimento": "Tipo de ingreso",
				"periodo": "Período",
				"nDiario": "A diario",
				"nDocInterno": "Número de documento",
				"descricaoDC": "Débito/Crédito",
				"nDocExterno": "Doc no.",
				"valorOrigem": "Valor base de incidencia",
				"valorDestino": "Valor a retener",
				"valorDestDispo": "Valor retenido",
				"dataDoc": "Fecha del documento"
			}
		},
		"doc": "Ver documento",
		"config": {
			"showColumnIsContaPocOrigemFornecedor": "Mostrar origen",
			"showColumnNContribuinte": "Mostrar número de contribuyente",
			"showColumnConta": "Mostrar cuenta",
			"showColumnCodRetencao": "Mostrar retención",
			"showColumnTaxaRetUsada": "Mostrar tarifa",
			"showColumnTipoRendimento": "Mostrar tipo de ingreso",
			"showColumnPeriodo": "Mostrar periodo",
			"showColumnNDiario": "Mostrar número diario",
			"showColumnNDocInterno": "Mostrar el documento no.",
			"showColumnDescricaoDC": "Mostrar débito/crédito",
			"showColumnDataDoc": "Mostrar fecha",
			"showColumnNDocExterno": "Mostrar el documento no.",
			"showColumnValorOrigem": "Mostrar valor de incidencia base",
			"showColumnValorDestino": "Mostrar valor para retener",
			"showColumnValorDestDispo": "Mostrar monto retenido"
		}
	},
	"rhEstatisticas": {
		"porEmpresa": "Por empresa",
		"multiEmpresa": "Multiempresa",
		"fields": {
			"codEmp": "Código.",
			"nome": "Nombre",
			"totalAbonos": "Asignaciones totales",
			"totalDescontos": "Descuentos completos",
			"totalIliquido": "Total bruto",
			"irs": "IRS",
			"segSocial": "Social lunes.",
			"cga": "CGA",
			"sindicato": "Unión",
			"totalImpostos": "Impuestos totales",
			"totalRecibo": "Total neto",
			"totalLiquido": "Total neto",
			"totalEspecie": "Especies totales",
			"totalAPagar": "Total a pagar",
			"encargos": "Cargos",
			"custoEmpresa": "Costo de la empresa",
			"totalCustosEmpresa": "Costo de la empresa",
			"departamento": "Departamento",
			"ccusto": "centro de costos",
			"abonos": "Asignaciones",
			"descontos": "Descuentos",
			"colaboradores": "Colaboradores",
			"impostos": "Impuestos",
			"qtd": "Cantidad",
			"valorUnit": "V. unidad",
			"valorTotal": "Valor total",
			"dtNascimento": "Fecha de nacimiento",
			"regimeSS": "Régimen sec.",
			"totalRemun": "Remuneración total",
			"codP": "Código.",
			"codR": "Código.",
			"codF": "Código.",
			"codN": "Código.",
			"codA": "Código.",
			"codB": "Código.",
			"codC": "Código.",
			"codD": "Código.",
			"codH": "Código.",
			"codM": "Código.",
			"codO": "Código.",
			"codS": "Código.",
			"codT": "Código.",
			"codX": "Código.",
			"cod2": "Código.",
			"cod6": "Código.",
			"codI": "Código.",
			"encargosTrabalhador": "Cargos de los trabajadores",
			"encargosEmpresa": "Cargos de la empresa",
			"totalEncargos": "Cargos totales",
			"sindicatoPerc": "% Unión",
			"valorSujeito": "Valor del sujeto",
			"valorDesconto": "Valor de descuento",
			"niss": "niss",
			"seguradora": "Compañía aseguradora",
			"nEmpresa": "empresa no.",
			"totalSalarios": "Salarios totales",
			"totalCustos": "Costos totales",
			"nif": "NIF",
			"totalRendimentos": "Ingresos totales",
			"rendAnoAnterior": "Producir",
			"rendNaoSujeito": "Producir",
			"rendSujeito": "Producir",
			"retencaoIrs": "Retención del IRS",
			"areaRendimento": "Ingresos del área",
			"contibuicoesSS": "Contribuciones a las SS",
			"tipoRendimento": "Tipo de ingreso",
			"a25": "A25",
			"a81": "A81",
			"a18": "A18",
			"a40": "A40",
			"a19": "A19",
			"a12": "A12",
			"a68": "A68",
			"a13": "A13",
			"a69": "A69",
			"a11": "A11",
			"al6": "Al6",
			"al7": "Al7",
			"al4": "Al4",
			"a62": "A62",
			"a15": "A15",
			"a63": "A63",
			"a61": "A61",
			"a66": "A66",
			"a67": "A67",
			"a2": "A2",
			"a64": "A64",
			"a3": "A3",
			"a65": "A65",
			"a32": "A32",
			"a33": "A33",
			"a30": "A30",
			"a31": "A31",
			"a4": "A4",
			"a5": "A5",
			"a22": "A22",
			"a23": "A23",
			"a": "EL",
			"a20": "A20",
			"a21": "A21",
			"a26": "A26",
			"a80": "A80",
			"a27": "A27",
			"a24": "A24",
			"totalBruto": "Total bruto"
		},
		"filters": {
			"mesProce": "Mes de procesamiento",
			"codEmpregado": "código de empleado",
			"depto": "Departamento",
			"ccusto": "centro de costos",
			"anoProce": "Año de procesamiento"
		},
		"modals": {
			"exclusao": {
				"title": "Eliminar registros"
			}
		},
		"messages": {
			"intervaloInvalidoEmpregado": "El rango de filtro del código de empleado no es válido.",
			"intervaloInvalidoDepto": "El rango de filtro de departamento no es válido.",
			"intervaloInvalidoMesProc": "El rango de filtro del mes de procesamiento no es válido."
		},
		"string": {
			"estatisticas": "Estadística",
			"analiseRemuneracoes": "Análisis retributivo",
			"analiseMensalDetalhada": "Análisis mensual detallado",
			"extratoRemuneracoes": "Declaración de remuneración",
			"analiseAnual": "Análisis anual",
			"analiseSegSocial": "Análisis seg.",
			"analiseAT": "Análisis AT",
			"analiseSindicato": "Análisis sindical",
			"analiseCGA": "Análisis CGA",
			"analiseSeguro": "Análisis de seguros",
			"listagensRU": "Listados del Reino Unido",
			"vistaResumo": "Vista de resumen",
			"vistaDetalhadaPorAbonoDesconto": "Vista detallada por asignación y descuento.",
			"impostosColaborador": "Impuestos a los empleados",
			"recibo": "Recibo",
			"custosEmpresa": "Costos de la empresa",
			"excluidos": "excluido",
			"tipo": "Tipo",
			"funcionario": "Colaborador",
			"totais": "Totales",
			"anoMenosUm": "Año N - 1",
			"totalN": "N totales",
			"pesquisar": "buscar",
			"config": "Ajustes"
		}
	},
	"rhMapasSindicatos": {
		"btn": {
			"previsualizar": "Avance"
		},
		"filters": {
			"dedata": "Fecha de",
			"deSindicato": "unión de"
		}
	},
	"rhtipoprocess": {
		"title_detail": "Tipo de procesamiento {{id}}",
		"title_new": "Nuevo tipo de procesamiento",
		"title_plural": "Tipos de procesamiento",
		"pesquisa": "buscar",
		"saved": "Escriba Procesando {{id}}, guardado exitosamente.",
		"error": "Tipo de procesamiento {{id}}, no se puede guardar.",
		"deleted": "Escriba Procesando {{id}}, eliminado exitosamente.",
		"fields": {
			"tipoProcessamento": "Tipo de procesamiento",
			"descricao": "Descripción",
			"dadosVencimento": "Datos de caducidad",
			"dadosManuais": "Datos manuales",
			"dadosFixos": "Datos fijos",
			"subsidioRefeicao": "Subsidio de comida",
			"diuturnidades": "Pagos de antigüedad",
			"subsidioNatal": "subsidio de navidad",
			"subsidioFerias": "subsidio de vacaciones",
			"valorCaducidadeCTR": "Valor de vencimiento del contrato a plazo",
			"tipoPeriodicidade": "Periodicidad",
			"desativado": "Tipo de procesamiento deshabilitado",
			"func": "Función"
		}
	},
	"rhagencias": {
		"onlyativos": "Mostrar solo activos",
		"onlyiban": "Mostrar solo con IBAN",
		"fields": {
			"codAgencia": "Código de agencia",
			"nome": "Nombre",
			"iban": "IBAN",
			"descricao": "Descripción"
		},
		"btn": {
			"addAgencia": "Añadir agencia",
			"addBanco": "Agregar banco",
			"addConta": "Agregar cuenta",
			"editAgencia": "Editar agencia",
			"editBanco": "Editar banco",
			"editConta": "Editar cuenta",
			"deleteAgencia": "Eliminar agencia",
			"deleteBanco": "Eliminar banco",
			"deleteConta": "Eliminar cuenta"
		},
		"messages": {
			"delAgencia": "¿Estás seguro de que deseas eliminar la agencia?",
			"delBanco": "¿Estás seguro de que quieres eliminar el banco?",
			"delConta": "¿Estás seguro de que deseas eliminar la cuenta?",
			"delsuccAgencia": "¡Agencia eliminada exitosamente!",
			"delsuccConta": "¡Cuenta eliminada exitosamente!",
			"newSuccAgencia": "¡Agencia agregada exitosamente!",
			"newSuccConta": "¡Cuenta agregada exitosamente!",
			"updateSuccAgencia": "¡Agencia actualizada exitosamente!",
			"updateSuccConta": "¡Cuenta actualizada exitosamente!"
		},
		"modal": {
			"agencia": {
				"title": "Agencias",
				"fields": {
					"banco": "Banco",
					"codAgencia": "Código de agencia",
					"nome": "Nombre",
					"descBreve": "Breve descripción",
					"morada": "Familiar",
					"codPostal": "Código postal",
					"localidade": "Ubicación",
					"telefone": "Teléfono",
					"contacto": "Contacto",
					"contaagencia": "Identificación interna.",
					"fax": "Fax",
					"estado": "Estado",
					"tipoInstal": "Tipo de instalación",
					"sede": "Sed",
					"agencia": "Agencia"
				}
			},
			"conta": {
				"title": "Cuentas",
				"fields": {
					"banco": "Banco",
					"agencia": "Agencia",
					"nib": "PUNTA",
					"nContaPoc": "Nro. de cuenta",
					"gestorBanco": "Gerente",
					"descricao": "Descripción",
					"nProjecto": "Número de proyecto",
					"iban": "IBAN",
					"swift": "RÁPIDO",
					"debitoDirectoID": "ID de domiciliación bancaria",
					"nContaEmprestimos": "No c.",
					"codPais": "Código del país",
					"status": "Estado de la cuenta",
					"validaIBAN": "Valida IBAN"
				}
			}
		}
	},
	"rhintegracaosalarios": {
		"titles": {
			"dadosadicionaisint": "Datos adicionales para Integración Salarial",
			"dadosadicionaisuni": "Datos adicionales para el Informe Único",
			"dadosadicionaisuniint": "Datos adicionales para el Informe Único e Integración Salarial",
			"confgintegracaosalarios": "Configuración de integración salarial",
			"mascaraanalitica": "Máscara analítica"
		},
		"fields": {
			"integraSalarios": "Integración salarial",
			"pocRemuLiq": "Cuenta por cobrar neta",
			"classeTrab": "clase de trabajo",
			"funcao": "Función",
			"operacaoSeccao": "Operación/Sección",
			"codZona": "código de zona",
			"departamento": "Departamento",
			"subDepartamento": "Sub.",
			"centroCusto": "centro de costos",
			"reparticaoCentroCusto": "Desglose del centro de costos",
			"integraRU": "Se integra en el informe único",
			"profissao": "Profesión",
			"situacaoProfissao": "Situación profesional",
			"codCCT": "Código CCT",
			"nivelQualif": "Nivel de calificación",
			"categoria": "Categoría",
			"integraSalariosPorDefeito": "integración normal",
			"integraSalariosPorOrgaoSocial": "Integración por órganos sociales/personal",
			"validamascara": "Tiene configuración de máscara analítica.",
			"mascara": "Máscara C. Costo"
		},
		"tab": {
			"integracaosalarios": "Integración de salarios",
			"mascaraanaliticaabono": "Asignaciones de máscara analítica",
			"mascaraanaliticadesconto": "Descuentos en mascarillas analíticas"
		},
		"message": {
			"savedsuccessfully": "¡Datos guardados exitosamente!",
			"resetsuccessfully": "¡Restablecimiento de datos exitoso!",
			"retiraropcaointegsal": "Al eliminar esta opción ya no tendrás integración salarial."
		},
		"btn": {
			"repor": "Reiniciar"
		},
		"configApoiosCOVID19": "Configuración de apoyo para recibir seguridad social - COVID-19",
		"configApoiosCOVID19Fields": {
			"card": {
				"apoioexcepcionalfamilia": "Apoyo familiar excepcional a los empleados (COVID-19)",
				"apoioLayoffSimplificadoPSuspensaoTrabalho": "Ayuda simplificada al despido por suspensión de trabajo (COVID-19)",
				"apoioLayoffSimplificadoPReducaoTrabalho": "Ayudas simplificadas al despido por reducción de trabajo (COVID-19)"
			},
			"nContaCovid19ApoioFamilia": "Nro. de cuenta",
			"nContaCovid19ApoioFamiliaPerc": "Perc.",
			"nContaCovid19LayoffSimplSuspencao": "Nro. de cuenta",
			"nContaCovid19LayoffSimplSuspencaoPerc": "Perc.",
			"nContaCovid19LayoffSimplParcial": "Nro. de cuenta",
			"nContaCovid19LayoffSimplParcialPerc": "Perc.",
			"mensagemPercDeApoioAtribuidoSS": "% de apoyo otorgado por la seguridad social"
		}
	},
	"rhatualizaferiasemp": {
		"atualizadatacadastro": "Actualizar fecha de registro",
		"fields": {
			"empregado": "Empleado",
			"feriasano": "Número de días de vacaciones en el año {{year}}",
			"temregano": "Tiene registro en el año {{year}}",
			"codEmp": "Empleado",
			"nomeEmpregado": "Nombre",
			"anoAnteriorNDiasFeriasAnoAnterior": "año anterior",
			"anoAnteriorGozadasNDiasFeriasAnoAnterior": "Corridas el año pasado",
			"anoAnteriorGozadasNDiasFeriasAnoAtual": "Corridas en el año",
			"nDiasFeriasAnoAnterior": "año anterior",
			"dataMotivoCadastro": "Fecha de registro",
			"livre": "Información"
		},
		"messages": {
			"temselecionaremp": "No seleccionó ningún empleado.",
			"savedsuccess": "La grabación se completó con éxito.",
			"datainferior": "La fecha no puede ser inferior a la fecha ya registrada.",
			"valNegativo": "El valor no puede ser negativo."
		}
	},
	"rhcalculorendimentoliquido": {
		"success": "¡Cálculo por ingresos guardado con éxito!",
		"fields": {
			"codEmp": "código de empleado",
			"valorLiquido": "patrimonio neto",
			"nCodABDESC": "Prestación",
			"adicionaRecolha": "Agregar valor de beneficio calculado en la recopilación de datos"
		},
		"steps": {
			"processos": "Procesos",
			"empregados": "Empleados",
			"calcular": "Calcular"
		},
		"titles": {
			"empregados": "Procesamiento de empleados",
			"processamentos": "Lista de procesamiento"
		},
		"empregado": {
			"codEmpregado": "Código.",
			"nomeEmpregado": "Nombre",
			"strEstadoProcEmpregado": "Estado",
			"nProcessamento": "Proceso no.",
			"nRecibo": "Recibo no.",
			"dataProcessamento": "Fecha del proceso.",
			"identificacaoEmpregado": "Empleado"
		},
		"processo": {
			"nProcessamento": "Proceso no.",
			"dataProcessamento": "Fecha del proceso.",
			"tipoProcessamento": "Tipo de proceso.",
			"proceDescricao": "Descripción",
			"dataInicoProc": "Fecha de inicio",
			"dataFimProce": "Fecha de finalización"
		},
		"messages": {
			"invalidStepProc": "¡Tienes que seleccionar un proceso!",
			"invalidStepEmp": "¡Debes seleccionar un Empleado!"
		},
		"btn": {
			"finalizar": "Calcular"
		}
	},
	"rhcessacontratotrabalho": {
		"fields": {
			"datacessacao": "fecha de terminación",
			"entidade": "Entidad que rescindió el contrato",
			"tipoContrato": "Tipo de contrato",
			"datainicio": "Fecha de inicio",
			"horasSemana": "Horas por semana",
			"dataPrimeiraRenovacaoExtra": "Fecha de la 1ª renovación extraordinaria",
			"retribuicaoBase": "Salario base",
			"diuturnidades": "Pagos de antigüedad",
			"complementos": "Complementos",
			"feriasGozadas": "vacaciones ya tomadas",
			"subsidioFerias": "Asignación de vacaciones ya recibida",
			"subSidioNatalJaRecebido": "Asignación navideña ya recibida",
			"codEmp": "código de empleado",
			"duracao": "Duración:",
			"situacao": "Nuevo código.",
			"motivoCessacao": "Nuevo motivo de registro",
			"duracaodata": "{{años}} año(s) {{meses}} mes(es) {{días}} día(s)",
			"tipoProcessamento": "Tipo de procesamiento:",
			"radio": {
				"empregador": "Empleador",
				"trabalhador": "Obrero",
				"justacausa": "¿Con justa causa?"
			},
			"calculado": {
				"compensacao": "Compensación:",
				"valorFeriasVencidas": "Vacaciones no tomadas:",
				"valorSubsidioFerias": "Subsidio de vacaciones a percibir:",
				"feriasPropValor": "Vacaciones:",
				"subsidioFeriasPropValor": "Asignación de vacaciones:",
				"subsidioNatalPropValor": "Asignación navideña:",
				"compensacaoTotal": "Monto global:"
			}
		},
		"card": {
			"confirmedados": "Confirma los siguientes datos",
			"valorescalc": "Valores de cálculo",
			"valoremfalta": "Vacaciones - Cantidad faltante",
			"proporcionais": "Proporcional en el año de cese",
			"retribuicao": "Venganza",
			"ferias": "Vacaciones"
		},
		"btn": {
			"simularcessacao": "Calcular valor",
			"dadosemp": "Mantenimiento de datos de empleados",
			"validar": "Validar/Editar valores",
			"manutencaocessacontrato": "Configurar la terminación del contrato"
		},
		"infos": {
			"tooltipRetribuicaoBase": "La remuneración base corresponde a la actividad del trabajador durante el período normal de trabajo.",
			"tooltipComplementos": "Cuotas periódicas regulares.",
			"tooltipTipoContrato": "Un contrato por tiempo indefinido comúnmente se llama efectivo.",
			"tooltipJustCausa": "La causa justa sólo puede ser evaluada por un tribunal.",
			"tooltipFeriasPropValor": "Para efectos de cálculo, el simulador considera: <br> - el valor de las vacaciones y las proporciones respectivas: remuneración básica, pagos de antigüedad y beneficios retributivos indicados por el usuario como compensación por la forma específica en que se realiza el trabajo.",
			"tooltipExcecpoes": "Excepciones",
			"tooltipReCalcular": "Calcular complementos",
			"temcalcularvalor": "Debe realizar el cálculo para validar los valores.",
			"temcorrigiremp": "Hay que corregir los errores del empleado.",
			"insiraemp": "Debes ingresar un empleado.",
			"situacaoandmotivodefault": "La configuración predeterminada para el estado de terminación y el motivo del registro no se completa.",
			"calculadocomplementossuccess": "¡Complementos calculados con éxito!",
			"calcsuccess": "¡Valores calculados exitosamente!",
			"gravadocomsucessonotipoproc": "Los datos de caducidad se escribieron correctamente en la recopilación de datos en el tipo de procesamiento '{{processingtype}}'.",
			"abonodescsuccess": "¡Asignación/descuento agregado exitosamente!"
		},
		"modal": {
			"defsitandmot": "¿Quiere configurar los códigos de 'Situación' y 'Motivo' de forma predeterminada?",
			"encerrar": {
				"cessacaocontrat": "Terminación del contrato de trabajo",
				"table": {
					"nCodABDESC": "Código de asignación/dec",
					"descricaoAbono": "Descripción",
					"valor": "Valor",
					"observacoes": "Nota.",
					"semdados": "Sin datos"
				}
			},
			"complementosexcepcoes": {
				"title": "Excepciones",
				"indiqueexcluidos": "Indique los artículos que desea excluir de la selección",
				"possiveis": "Posible",
				"excluidos": "excluido",
				"fields": {
					"codABDESC": "Código",
					"designaBreve": "Nombre"
				}
			},
			"addabonodesconto": {
				"title": "Añadir nuevo bono/descuento al finalizar el contrato",
				"fields": {
					"abonoDesc": "Tipo",
					"codABDESC": "Código",
					"valor": "Valor",
					"observacoes": "Nota."
				}
			}
		}
	},
	"rhcopiaabonosdescontos": {
		"fmt": {
			"relatorioLoadABDESCopia": "{{CodAbdes}}-{{NombreCodAbdes}}",
			"abonoDescontoACopiar": "Asignación/Descuento a copiar: {{abdesCopia}}",
			"empresaDeOrigem": "Empresa de origen: {{codCompanyOrigem}}-{{nomeEmpresaOrigem}}",
			"empresaDeDestino": "Empresa de destino: {{codeDestinoCompany}}-{{nomeEmpresaDestino}}"
		},
		"titles": {
			"copiaDeAbonosDescontosMultiEmpresa": "Copia de bonificaciones / Descuentos multiempresa",
			"selecaoDeEmpresas": "Selección de empresa",
			"confirmacaoDasOperacoes": "Confirmación de operaciones",
			"estadoDaCopia": "Estado de copia"
		},
		"text": {
			"1-1": "Este asistente lo guiará a través del proceso de copiar o actualizar automáticamente la asignación/descuento para todas las empresas seleccionadas.",
			"1-2": "También será posible copiar los cruces, pero no permite actualizarlos en la empresa de destino.",
			"1-3": "Finalmente, se mostrará un informe con información detallada sobre aciertos o errores, para que puedan ser corregidos.",
			"2-1": "Encuéntrate a ti mismo",
			"3-1": "Confirma si deseas reemplazar empresas que ya tienen bonos/descuentos configurados"
		},
		"fields": {
			"tipo": "Tipo",
			"codigo": "Asignación/descuento",
			"abono": "Prestación",
			"desconto": "Descuento",
			"copiaCruzamentos": "Copiar intersecciones",
			"copiaIntSalarios": "Copiar integración salarial"
		},
		"modal": {
			"title": "Información sobre la copia de bonificaciones/descuentos",
			"radio": {
				"gerarNovo": "Generar nuevo código de grupo según el origen",
				"utilizarExistente": "Utilizar un grupo existente en el destino con todas las características del origen"
			}
		},
		"dataGrid": {
			"caption": {
				"empresa": "código de empresa",
				"nomeEmpresa": "Nombre de empresa",
				"copiouABDES": "Policía.",
				"copiouGrupo": "Policía.",
				"copiouCruzamento": "Policía.",
				"copiouIntegracaoSalarios": "Policía.",
				"permiteCopia": "Información.",
				"codAbdesDestino": "Código de destino",
				"nomeAbdesDestino": "Nombre de destino",
				"temCruzamento": "Hay un cruce",
				"mensagem": {
					"resultado": "Resultado",
					"descricao": "Descripción"
				}
			}
		},
		"errors": {
			"semCodAbDesc": "Debes seleccionar una asignación/descuento",
			"semEmpresas": "Debes seleccionar al menos una empresa",
			"selecionarLinhaComErro": "La línea no se puede seleccionar porque contiene un error.",
			"selecionarLinhaComAviso": "Elija una acción antes de seleccionar la línea"
		}
	},
	"rhdadosconfigficha": {
		"titles": {
			"registo": "Registro",
			"situacaoemprego": "Situación laboral",
			"dadosrenumeracao": "Datos de remuneración",
			"outrosdados": "Otros datos",
			"subsidioferias": "subsidio de vacaciones",
			"subsidionatal": "subsidio de navidad",
			"residenciafiscal": "Residencia fiscal"
		},
		"fields": {
			"motivoRegisto": "Razón de registro",
			"situacaoEmprego": "Situación",
			"nCodABVencimento": "Subsidio salarial",
			"nCodABSubAlimentacao": "Subsidio de comida",
			"nCodABSubFerias": "Subsidio de subsidio de vacaciones",
			"nCodABSubNatal": "subsidio de navidad",
			"nCodABDiuturnidades": "Subsidio de antigüedad",
			"nCodDescIRS": "descuento del IRS",
			"localTrabalho": "Ubicación o establecimiento",
			"tipoHorarioTrabalho": "tipo de tiempo",
			"nMaxDiasUteisFerias": "Número máximo de días laborables",
			"nDiasTemDireitoFerias": "Número de días laborables a los que tiene derecho",
			"nMaxDiasUteisNatal": "Número máximo de días",
			"nDiasTemDireitoNatal": "Número de días que es elegible",
			"nCodABFerias": "Bono de vacaciones",
			"localObtencaoRendimentos": "Lugar para obtener ingresos"
		},
		"text": {
			"suggestion1": "Parece que aún no has configurado los datos predeterminados en la ficha del empleado.",
			"suggestion2": "aplicar la configuración recomendada"
		}
	},
	"rhfolfer": {
		"fields": {
			"dataProcesDE": "Fecha de procesamiento",
			"deAbono": "Asignación de",
			"deCCusto": "centro de costos",
			"deDepto": "Departamento de",
			"deDesconto": "Descuento de",
			"deSeccao": "Sección",
			"deSubDepto": "Subdepartamento",
			"deZona": "Zona",
			"doCodNum": "código de empleado",
			"mostraAbonosVirtuais": "Mostrar asignaciones virtuales"
		},
		"infos": {
			"tooltipExcecpoesAbono": "Subsidios de excepciones",
			"tooltipExcecpoesDesconto": "Descuentos de excepciones",
			"tooltipExcepcoesProcessamento": "Procesamiento de excepciones"
		},
		"btn": {
			"previsualizar": "Avance"
		},
		"messages": {
			"exclprocessamentoemp": "¡Solo puedes tener seleccionada la empresa actual!"
		}
	},
	"rhgestaoficheirosdmrat": {
		"comprovativo": "Prueba",
		"docpagamento": "Documento de pago",
		"emaildest": "Correo electrónico del destinatario",
		"semdados": "Sin datos",
		"header": {
			"dataAquisicaoDe": "Mes/Año"
		},
		"btn": {
			"pesquisar": "buscar",
			"verificarenvios": "Verificar envíos",
			"gerarficheiro": "Generar archivos",
			"reenviarwebservice": "Reenviar a través de WebService"
		},
		"table": {
			"dados": "Datos",
			"infoficheiro": "Información del archivo",
			"sel": "Sel.",
			"empresa": "Empresa",
			"nomeempresa": "Nombre de empresa",
			"contribemp": "Contribución",
			"info": "Información",
			"erros": "Errores",
			"infoerros": "Errores de información",
			"estado": "Estado",
			"docpagamento": "Documento de pago",
			"comprovativo": "Prueba",
			"email": "Correo electrónico"
		},
		"tab": {
			"dadosAutomaticos": "Datos automáticos",
			"dadosManuais": "Datos manuales",
			"dadosGlobais": "Datos globales",
			"controlo": "Control",
			"valorRendimentos": "Valor de ingresos",
			"retencaoIRS": "Retención del IRS",
			"contribObrigatorias": "Contribuciones obligatorias",
			"quotizacaoSindicais": "Cuotas sindicales",
			"retencaoSobretaxa": "Retención de recargo",
			"rendiAnosAnt": "Ingresos de años anteriores"
		},
		"modal": {
			"rhgestaoficheirosdmrattitle": "Información de datos retributivos",
			"table": {
				"ok": "DE ACUERDO",
				"nif": "número de contribuyente",
				"codemp": "Número de empleado",
				"nome": "Nombre",
				"abonosRendimentosSujeitos": "Asunto de ingresos",
				"abonosRendimentosNaoSujeitos": "Ingresos no sujetos",
				"abonosOutros": "Otras asignaciones",
				"descontosContribuicoesObrigatorias": "Contribuciones obligatorias",
				"descontosIRS": "Retención del IRS",
				"descontosSindicatos": "Sindicatos",
				"descontosSobretaxa": "Retención de recargo",
				"descontosOutros": "Otros descuentos",
				"rendimentosAnoAnterior": "Ingresos del año anterior",
				"liquidoCalculado": "Neto calculado",
				"liquidoVencimentos": "Salarios netos",
				"erroDescricao": "Descripción de error/advertencia",
				"origemRendiDescr": "Origen",
				"tipRendiment": "Tipo de rendimiento.",
				"valorInciden": "Valor de ingresos",
				"descrInsManual": "Tipo de inserción",
				"quotizSindical": "Cuotas sindicales",
				"irsRetido": "Retención del IRS",
				"descobrigSSocial": "Contribuciones obligatorias",
				"sobretaxaExtraord": "Retención de recargo",
				"rendimentosAnoAnteriorTable": "Valor de ingresos (años anteriores)",
				"rendianosanteriores": "Ingresos de años anteriores",
				"valores": "Valores",
				"rendimentosano": "Ingresos del año",
				"tipoRendi": "Tipo de ingreso",
				"localorigemrendi": "Ubicación de los ingresos",
				"nipc1": "NIPC entidad 1",
				"nipc2": "entidad NIPC 2",
				"nipc3": "entidad NIPC 3",
				"enviaparaDMR": "Enviar a DMR",
				"valorNaoSujeito": "Ingresos no sujetos",
				"outrosDescontos": "Otros descuentos",
				"outrosAbonos": "Otras asignaciones",
				"ncodAbdes": "Asignación/descuento",
				"descricaoAbono": "Descripción del beneficio/descuento"
			}
		},
		"email": {
			"enviado": "¡Correo electrónico enviado exitosamente!",
			"naoenviado": "El correo electrónico no fue enviado."
		},
		"messages": {
			"envioscomsucesso": "Envíos verificados exitosamente!"
		},
		"tooltip": {
			"naotemlinhasselecionadas": "No hay líneas seleccionadas."
		},
		"prompt": {
			"geraFich": "Generará el fichero de declaración de remuneración mensual - AT.",
			"errogerarficheiro": "¡El archivo no se generó correctamente!",
			"ficheirosenviadosconfirmcolum": "Archivos enviados."
		}
	},
	"gozoferias": {
		"usaMarcacaoGozoFerias": "Utilice la marca de disfrute de vacaciones",
		"motivoRegistoFerias": "Razón del cambio",
		"nCodAbdesDescontoGozoFeriasAnoAtual": "Descuento del año en curso",
		"nCodAbdesDescontoGozoFeriasAnoAnterior": "Descuento del año anterior"
	},
	"rhimpvenct": {
		"fields": {
			"doCodNum": "código de empleado",
			"deZona": "Zona",
			"deDepto": "Departamento de",
			"deSubDepto": "Subdepartamento",
			"deCCusto": "centro de costos",
			"deAbono": "Asignación de",
			"deDesconto": "Descuento de",
			"deSeccao": "Sección",
			"mostraAbonosVirtuais": "Mostrar asignaciones virtuales",
			"dataProcesDE": "Fecha de procesamiento"
		},
		"infos": {
			"tooltipExcecpoesAbono": "Subsidios de excepciones",
			"tooltipExcecpoesDesconto": "Descuentos de excepciones",
			"tooltipExcepcoesProcessamento": "Procesamiento de excepciones"
		},
		"btn": {
			"previsualizar": "Avance"
		},
		"messages": {
			"exclprocessamentoemp": "¡Solo puedes tener seleccionada la empresa actual!"
		}
	},
	"rhmanutencao": {
		"tabs": {
			"vencimentos": "Salarios"
		},
		"message": {
			"savedsuccessfully": "¡Datos guardados exitosamente!"
		},
		"fields": {
			"situacaoCessacao": "Estado de cesación",
			"motivoCessacao": "Razón de registro",
			"nCodAbonoIndemnizacaoCaducidade": "Compensación por decomiso no sujeta a impuestos",
			"nCodAbonoIndemnizacaoDespedimento": "Indemnización por despido",
			"nCodAbonoIndemnizacaoGlobal": "Compensación integral",
			"nCodAbonoSubFeriasProp": "Subsidio de vacaciones proporcional",
			"nCodAbonoFeriasProp": "Vacaciones proporcionales"
		},
		"titles": {
			"abcessaconttrab": "Definiciones de bonificaciones/deducciones por terminación de contratos de trabajo",
			"abpropanoatual": "Definiciones de asignaciones/deducciones proporcionales en el año en curso"
		},
		"steps": {
			"cessacaocontrato": "Terminación del contrato"
		},
		"segsocialfolhaferias": {
			"title": "Hoja de vacaciones (Aseguradora)",
			"cardseguradora": {
				"usaTipodeRemunEspecificaFichSeguradora": "Utilice el tipo de remuneración específico para el expediente de seguro (\"A\", \"B\", \"C\", \"F\", \"M\", \"N\", \"O\", \"P\", \"R\", \"S\", \" T \", \"X\", \"SR\")",
				"fichSeguradoraEnvioFichPelaExclAbDescCampo": "Enviar ficha de seguro - Enviar bonificaciones/descuentos confirmando el campo \"Mapa de Seguros\""
			}
		},
		"rmmg": {
			"title": "Remuneración Mínima Mensual Garantizada (RMMG)",
			"info": "La actualización de RMMG la realiza automáticamente CentralGest",
			"saved": "Configuración de la Remuneración Mínima Mensual Garantizada (RMMG) actualizada con éxito",
			"tooltip": {
				"needSave": "Debe guardar la configuración para realizar el procesamiento.",
				"needCheck": "Debe seleccionar el campo administrador para realizar el procesamiento",
				"needSaveAndCheck": "Debe seleccionar el campo del administrador y guardarlo para poder realizar el procesamiento."
			},
			"fields": {
				"dataAtualizacao": "Fecha de actualización",
				"gerente": "Gerente",
				"funcionario": "Empleado"
			}
		}
	},
	"rhmanutencaocampos": {
		"pesquisar": "buscar",
		"camposModificar": "Campos a modificar",
		"processamentos": "Lista de procesamiento",
		"valorCampoNaoPodeSerNulo": "¡El Valor del campo {{campo}} no puede ser nulo ni estar vacío!",
		"nenhumCampoSelecionadoParaAtualizar": "No hay campos seleccionados para actualizar",
		"nenhumProceSelecionadoParaAtualizar": "No se ha seleccionado ningún procesamiento para actualizar",
		"naoFoiEncontradoValorNoModel": "El valor no se encontró en el modelo: {{field}}",
		"atualizarLista": "Lista de actualización",
		"promptMessage": "¿Está seguro de que desea cambiar los campos en los procesos seleccionados?",
		"dataProcFinalNaoPodeInferiorInicial": "La fecha de procesamiento final no puede ser menor que la fecha de inicio.",
		"infoHistoricoEmpregado": "Información del historial del empleado",
		"dadosProcessamento": "Procesamiento de datos",
		"processamento": "Tratamiento",
		"fields": {
			"dateProc": "Fecha de procesamiento",
			"codEmp": "Código emp.",
			"nome": "Nombre",
			"nProcessamento": "Proceso no.",
			"dataProcessamento": "Fecha de proceso",
			"funcao": "Función",
			"nZona": "código de zona",
			"seccao": "Sección",
			"ccusto": "centro de costos",
			"nPOCRemuLiq": "Cuenta POC de licor.",
			"departamento": "Departamento",
			"classeTrab": "Clase de trabajo",
			"nSubDe": "Subdepartamento",
			"descricaoErro": "Informe"
		},
		"wizard": {
			"stepOne": {
				"wizardCaption": "Bienvenido",
				"text1": "Este asistente le permitirá corregir campos que fueron registrados incorrectamente en el procesamiento de nómina.",
				"text2": "Sólo realizarás estas correcciones en procesos que aún no hayan sido integrados a la contabilidad.",
				"text3": "Haga clic en \"Siguiente\" para continuar."
			},
			"stepTwo": {
				"wizardCaption": "Actualizar campos",
				"text1": "Búsqueda de procesamientos (no integrados) realizados en el rango de fechas.",
				"text2": "La modificación de registros se puede realizar campo por campo, o seleccionar varios y cambiarlos todos a la vez."
			},
			"stepThree": {
				"wizardCaption": "Informe"
			}
		}
	},
	"rhpenhoras": {
		"title_detail": "Empeñar",
		"title_new": "Nuevo archivo adjunto",
		"title_plural": "Gravámenes",
		"pesquisa": "buscar",
		"saved": "Promesa, guardada con éxito.",
		"error": "El apego no se puede mantener.",
		"deleted": "Adjunto, eliminado exitosamente.",
		"apagarregisto": "Eliminar registro",
		"card": {
			"catabo": "Seleccione las asignaciones para calcular el valor del salario neto para asignar el embargo.",
			"calcLiqTodasCateg": "Todas las categorías de beneficios"
		},
		"toolbar": {
			"tipoEscolha": "Ver archivos adjuntos: {{nameTipoEscolha}}"
		},
		"fields": {
			"ncodABDESC": "Descuento",
			"codEMP": "Empleado",
			"tipoValor": "Tipo de valor",
			"valorTotalPenhora": "Valor total del archivo adjunto",
			"dataInicio": "Fecha de inicio",
			"tipoProcessamento": "Tratamiento",
			"todosProcessamentos": "Todo el procesamiento",
			"penhoraPorInsolvenci": "Embargo por insolvencia",
			"suspende": "Suspendido",
			"dataSuspensao": "Fecha de suspensión",
			"obsSupencao": "Descripción de la suspensión",
			"totalValorPenhoraPaga": "Monto del embargo pagado",
			"tipoValorPenhora": "Tipo de valor adjunto",
			"valor": "Valor del tipo de archivo adjunto",
			"temValorLimiteMinimoImpenhoravel": "Tiene un valor límite mínimo que no se puede embargar",
			"valorLimImpenhoravel": "Valor límite inapoderable",
			"valorExternoPenhora": "Valor neto externo: (Se suma al valor neto calculado)",
			"obsPenhora": "Observaciones",
			"referenciaPagamento": "Descripción",
			"identificacaoPenhora": "Identificación del archivo adjunto",
			"iban": "IBAN",
			"mbEntidade": "Entidad",
			"mbReferencia": "Referencia",
			"formaPagamento": "Método de pago"
		},
		"enumStr": {
			"formaPagamento": {
				"none": "No especificado",
				"mbref": "Entidad/Referencia",
				"iban": "IBAN",
				"mbrefestado": "Ref. pago al estado"
			}
		},
		"maintenance": {
			"descontos": "Configuración de descuento"
		}
	},
	"rhprocessamentosalariosconfig": {
		"tab": {
			"procesalarios": "Procesamiento de nómina",
			"duodecimos": "duodécimos",
			"recibovenc": "recibo de sueldo"
		},
		"procesalarios": {
			"usaFiltroFuncaoNosTiposProcessamento": "Utilice la función de filtro en el procesamiento de salarios",
			"calcNDiasParaAdmissaoEmpEmDiasBase30": "Calcula el valor de procesamiento de salario para la admisión de empleados cada 30 días"
		},
		"duodecimos": {
			"acertoDuodecimosFaltouMesInteiro": "Eliminar doceavos si el empleado estuvo ausente durante todo el mes",
			"efetuaAcertoDuodecimos": "Realizar ajuste de doceavos en el último pago de diciembre",
			"percentagemEmSubFerias": "Porcentaje de sub.",
			"percentagemEmSubNatal": "Porcentaje de sub."
		},
		"recibovenc": {
			"reciboVencimentosMostraGraficoCustos": "Muestra la distribución de los costes totales del empleado a la empresa en la nómina.",
			"reciboVencimentosMostraGraficoCustosAcumuladosAnuais": "Mostrar acumulaciones anuales en los detalles del costo total"
		},
		"card": {
			"percduodecimos": "Porcentaje de doceavos del subsidio de vacaciones y Navidad"
		},
		"errors": {
			"invalidpercentage": "¡Porcentaje no válido, debe ser 0, 50, 100!",
			"verificarFerias": "Comprobar que el porcentaje de doceavos ingresados ​​en el sub.",
			"verificarNatal": "Comprobar que el porcentaje de doceavos ingresados ​​en el sub."
		}
	},
	"rhprocsegsocialconsultenvios": {
		"semdados": "Sin datos",
		"comprovativo": "Prueba",
		"header": {
			"dataAquisicaoDe": "Mes/Año"
		},
		"btn": {
			"pesquisar": "buscar",
			"gerarficheiro": "Generar archivos",
			"reenviarwebservice": "Reenviar a través de WebService",
			"reprocessarSegSocial": "Reprocesar seg.",
			"configWsSS": "Configuración del WebService de la Seguridad Social",
			"impresumo": "Imprimir resumen",
			"gerarEstorno": "Generar contracargo",
			"apagarEstorno": "Eliminar contracargo"
		},
		"table": {
			"dados": "Datos",
			"dadosEmpresa": "Datos de la empresa",
			"infoficheiro": "Información del archivo",
			"infowebservice": "Servicio web de información",
			"sel": "Sel.",
			"empresa": "Empresa",
			"nomeempresa": "Nombre de empresa",
			"totalRemuneracoes": "Total - Remuneración seg.",
			"totalContribuicoes": "Total - Contribución seg.",
			"totalVencRemun": "Total - Remuneración proc.",
			"procficheiro": "Proc.",
			"info": "Información",
			"erros": "Errores",
			"reprocessado": "reprocesado",
			"gerado": "Generado",
			"infoerros": "Errores de información",
			"estado": "Estado",
			"alertaserros": "Alerta/Errores",
			"dataentrega": "fecha de entrega",
			"datalimitesub": "Reemplazar fecha límite",
			"nome": "Nombre",
			"comprovativo": "Prueba",
			"modal": {
				"title": "Muestra información del archivo de hoja de vacaciones.",
				"identificacao": "Identificación",
				"codp": "Código P",
				"codr": "Código R",
				"codsr": "Código SR",
				"codf": "Código F",
				"codn": "Código N",
				"coda": "Código A",
				"codb": "Código B",
				"codc": "Código C",
				"codd": "Código D",
				"codh": "Código H",
				"codm": "Código M",
				"codo": "Código",
				"cods": "Código S",
				"codt": "Código T",
				"codx": "Código X",
				"cod2": "Código 2",
				"cod6": "Código 6",
				"codi": "Código I",
				"nss": "SS No.",
				"nfuncionario": "Número de empleado",
				"nome": "Nombre",
				"ndias": "No. de días",
				"valor": "Valor"
			},
			"config": "Config."
		},
		"messages": {
			"infosuccess": "¡Datos registrados exitosamente!",
			"infoerror": "Ocurrió un error al guardar los datos, por favor confirme los datos ingresados.",
			"empresasSelecionasHouveEmpresnaoGeradas": "De las empresas seleccionadas hubo empresas que no se crearon.",
			"reprocessamentoSuccess": "Reprocesamiento previsional realizado con éxito.",
			"empresasSelecionasHouveEmpres": "De las empresas seleccionadas, hubo empresas con errores.",
			"enviowebserviceSuccess": "Envíos realizados a todas las empresas.",
			"enviadosSucess": "Los archivos fueron enviados exitosamente.",
			"semRegistosSelecionadas": "Debe seleccionar al menos un registro.",
			"verificadosuccess": "Envíos verificados exitosamente",
			"soPodeSelecionarUmRegisto": "Sólo puede seleccionar un registro."
		},
		"prompt": {
			"geraFich": "Generará el fichero de declaración de remuneraciones de la seguridad social.",
			"errogerarficheiro": "¡El archivo no se generó correctamente!",
			"ficheirosenviadosconfirmcolum": "Archivos enviados.",
			"semAlteracaoParaGerar": "El sistema no generó ningún archivo porque ya fue generado anteriormente."
		},
		"tooltip": {
			"naotemlinhasselecionadas": "No hay líneas seleccionadas.",
			"configWsSS": "Configuración del WebService de la Seguridad Social",
			"gerarEstorno": "Generar contracargo",
			"apagarEstorno": "Eliminar contracargo"
		},
		"verificarEnvios": "Verificar envíos",
		"modal": {
			"title": "Impresión de mapas resumen del expediente de la seguridad social"
		}
	},
	"rhqpirct": {
		"card": {
			"irct": "IRCT",
			"irctcateg": "IRCT \\ Categoría",
			"codirct": "Código IRCT",
			"nomeirct": "Nombre del IRCT",
			"codcategoria": "código de categoría",
			"nomecategoria": "Nombre de categoría"
		},
		"btn": {
			"addirct": "Añadir IRCT",
			"addcateg": "Añadir categoría",
			"infooficialirct": "Obtener información oficial sobre los códigos a asignar al IRCT y categorías",
			"importlistacateg": "Importación del listado de categorías profesionales (archivo CSV)"
		},
		"messages": {
			"filesuccess": "¡Archivo importado exitosamente!",
			"importedsucess": "¡Importado exitosamente!",
			"irctexists": "¡El IRCT que deseas importar ya existe!"
		},
		"modal": {
			"title": "Importación de categorías profesionales",
			"table": {
				"codIRCT": "Código",
				"nome": "Nombre"
			}
		}
	},
	"rhrecolhadados": {
		"apagarregisto": "Eliminar registro",
		"radio": {
			"dadosmanual": "Recopilación manual de datos",
			"dadosfixos": "Recopilar datos fijos",
			"byabondesc": "Recopilación de datos por bonificación/descuento",
			"byemp": "Recopilación de datos por empleado"
		},
		"error": {
			"invalidStep": "¡Hay campos que no están cumplimentados o contienen algún error!",
			"invalidStepTodosProcessamentos": "¡Debe seleccionar un tipo de procesamiento válido!"
		},
		"fields": {
			"todosProcessamentos": "Todo el procesamiento",
			"nomeEmp": "Empleado",
			"tipoProcessamento": "Tipo de procesamiento",
			"abonoDesc": "Asignación/descuento",
			"codABDESC": "Código de bonificación/descuento",
			"codEMP": "código de empleado",
			"dataDados": "Fecha del evento",
			"dataDadosFixos": "Fecha de procesamiento",
			"dataProcess": "Fecha de procesamiento",
			"dataParaProcess": "Fecha de procesamiento",
			"quantidade": "Cantidad",
			"valorUNIT": "Valor unitario",
			"valor": "Valor",
			"vALINCIDESC": "Incidencia",
			"descricao": "Nota.",
			"cambioMEParaMC": "Intercambio",
			"valorME": "Valor YO",
			"codMOEDA": "Acuñar",
			"tipoALUNIT": "Unidad",
			"ccusto": "C. Costo",
			"nsubde": "Subdepartamento.",
			"departamento": "Departamento",
			"nzona": "Zona",
			"contador": "Número de registro en C. Costo",
			"htrabDia": "trabajo por dia",
			"htrabSemana": "trabajo por semana",
			"htrabMes": "trabajo por mes",
			"nomeAbonoDesconto": "Código de bonificación/descuento",
			"nomeTipoProcessamento": "Tipo de procesamiento"
		},
		"tabs": {
			"infoemp": "Información del empleado",
			"outrosdados": "Otros datos",
			"title": {
				"horasempregado": "Horas de empleado",
				"ndiasempregado": "Número de días empleados",
				"valoresuniemp": "Valores unitarios de empleados"
			}
		},
		"table": {
			"sel": "SEL",
			"tipo": "Tipo",
			"valor": "Valor",
			"valorme": "Valor YO",
			"horaextra": "% Con el tiempo",
			"pagaseguro": "% Paga seguro"
		},
		"modal": {
			"continuar": "¿Está seguro de que desea cambiar el registro grabado?",
			"avisodesc": "El subsidio/descuento '{{abodesc}}' que estás introduciendo supondrá la pérdida del subsidio para comidas.",
			"labelnhoras": "Número de ausencias marcadas para el descuento {{abodesc}}",
			"labelndias": "Número de días para el subsidio de alimentación",
			"nhoras": "No. de horas",
			"ndias": "No. de días",
			"title": "Subsidio de comida"
		},
		"toolbar": {
			"month": "Mes: {{nameMonth}}",
			"stateemp": "Empleados: {{nameState}}"
		},
		"steps": {
			"tiporecolha": "Tipo de colección",
			"dadosrecolha": "Datos de recogida"
		}
	},
	"remfx": {
		"title_detail": "Recopilar datos fijos",
		"title_new": "Nueva recopilación de datos fijos",
		"title_plural": "Colecciones de datos fijos",
		"pesquisa": "buscar",
		"saved": "Recopilación de datos fija, guardada exitosamente.",
		"error": "Recopilación de datos fijos, no se puede guardar.",
		"deleted": "Recopilación de datos fija, eliminada correctamente."
	},
	"remun": {
		"title_detail": "Recopilar datos",
		"title_new": "Nueva recopilación de datos",
		"title_plural": "Colecciones de datos",
		"pesquisa": "buscar",
		"saved": "Recolección de datos, guardada exitosamente.",
		"error": "Recopilación de datos, no se puede guardar.",
		"deleted": "Recopilación de datos, eliminada con éxito."
	},
	"rhrecolhaseriesubsidios": {
		"empTable": {
			"codEmp": "código de empleado",
			"nomeEmp": "Nombre",
			"nBI": "BI No.",
			"contribuinte": "número de contribuyente",
			"dataNascimento": "Fecha de nacimiento"
		},
		"steps": {
			"pesquisa": "Buscar",
			"processar": "Proceso"
		},
		"titles": {
			"pesquisa": "Buscar",
			"processar": "Seleccionar día de pago"
		},
		"fields": {
			"codEmpDe": "código emp.",
			"codEmpAte": "código emp.",
			"situacaoEmprego": "Situación laboral",
			"tipoProcessamento": "Tipo de procesamiento",
			"dataReferencia": "Fecha de referencia",
			"dataParaPagarSubsidio": "Fecha de cálculo del importe a pagar en concepto de subvención",
			"nDiasSubsidioAlimentacao": "Número de días de defecto",
			"radio": {
				"inativo": "Inactivo"
			}
		},
		"messages": {
			"selEmp": "¡Debes seleccionar al menos un empleado!",
			"tipoProcEmpty": "¡Debe ingresar un tipo de procesamiento!",
			"semdadossave": "No hay datos para registrar.",
			"dadossaved": "Datos guardados exitosamente."
		},
		"modal": {
			"subssavedtitlenatal": "Pago serial de asignación navideña",
			"subssavedtitleferias": "Pago serial del subsidio de vacaciones",
			"subssavedtitlealimentacao": "Preprocesamiento en serie de la asignación de alimentos.",
			"semdados": "Sin datos",
			"btn": {
				"options": {
					"showCodEmpregado": "Mostrar código.",
					"showNomeEmpregado": "Mostrar nombre",
					"showDataAdmissao": "Mostrar fecha de admisión",
					"showNDiasAPagar": "Muestra el número de días para pagar",
					"showValorAPagar": "Muestra monto a pagar",
					"showNMAxDiasUteisSubsidio": "Muestra el número máximo.",
					"showNDiasPagosAnoAnterior": "Muestra el número de días pagados en el año anterior.",
					"showValorPagoAnoAnterior": "Muestra el monto pagado el año pasado",
					"showNDiasPorPagarAnoAnterior": "Muestra el número de días sin pagar en el año anterior.",
					"showNDiasTrabalhados": "Muestra el número de días trabajados.",
					"showNDiasDescontarPorFaltasEmpregado": "Muestra el número de días deducidos por ausencias.",
					"showNDiasPagos": "Muestra el número de días ya pagados.",
					"showInfo": "Mostrar información"
				}
			},
			"fields": {
				"codEmpregado": "código emp.",
				"nomeEmpregado": "Nombre",
				"dataAdmissao": "Fecha de admisión",
				"nMAxDiasUteisSubsidio": "Máximo no.",
				"nDiasPagosAnoAnterior": "Número de días pagados en el año anterior",
				"valorPagoAnoAnterior": "Cantidad pagada el año anterior",
				"nDiasPorPagarAnoAnterior": "Número de días sin pagar en el año anterior",
				"nDiasTrabalhados": "Número de días trabajados",
				"nDiasDescontarPorFaltasEmpregado": "Número de días deducidos por ausencias",
				"nDiasPagos": "Número de días ya pagados",
				"nDiasAPagar": "Número de días para pagar",
				"valorAPagar": "cantidad a pagar",
				"info": "Información",
				"valorEmMoedaEstrangeira": "Valor de moneda extranjera",
				"valorCambio": "Valor de cambio",
				"moedaCambio": "Cambio de divisas"
			}
		}
	},
	"rhregistarempregadosss": {
		"consultarMainTabTitle": "Consultar empleados",
		"registarMainTabTitle": "Registrar empleados",
		"verificarEmpregadosTabTitle": "comprobar empleados",
		"verificarRegistarContratosTabTitle": "Consultar/Registrar contratos antiguos",
		"registarEmpregadosRegistarTabTitle": "Registrar empleado",
		"registarEmpregadosCessarTabTitle": "Despedir al empleado",
		"registarEmpregadosPerioRendTabTitle": "Periodo de ingresos",
		"promptRegistarEmpregado": "¿Está seguro de que desea registrar al empleado?",
		"promptCessarEmpregado": "¿Está seguro de que desea despedir al empleado?",
		"promptRegPeriodoRendimento": "¿Está seguro de que desea registrar el período de ingresos?",
		"editarContratoModalTitle": "Editar contrato",
		"configWS": {
			"modalTitle": "Configuración para el alta de trabajadores en sec.",
			"instruction1Title": "Acceso a la seguridad social directa",
			"instruction1Text": "Para afiliarse a la plataforma de servicios de interoperabilidad, deberá acceder a la seguridad social directa (SSD), a través del NISS y la contraseña que tenga para tal efecto.",
			"instruction2Title": "Únase a la plataforma de Servicios de Interoperabilidad",
			"instruction2Text": "Tras acceder al SSD, encontrarás las condiciones para unirte a la plataforma de servicios de interoperabilidad en la opción del menú perfil.",
			"saveButton": "para grabar",
			"seguinte": "Siguiente",
			"voltar": "para volver",
			"utilizadorNiss": "Usuario (NISS)",
			"senha": "Contraseña",
			"warnignMsg": "El usuario y contraseña son los de la empresa, y no los que se accede como agente"
		},
		"strings": {
			"empregado": "Empleado",
			"consultarEmpregados": "Consultar empleados",
			"verificarContratos": "Consultar contratos",
			"dados": "Datos",
			"taxas": "Honorarios",
			"vinculo": "Vínculo",
			"localTrabalho": "Lugar de trabajo",
			"codemp": "código emp.",
			"niss": "No. seg.",
			"nome": "Nombre",
			"datanascimento": "Fecha de nacimiento",
			"vinculocomunicadoem": "Enlace comunicado en",
			"iniciodoVinculo": "inicio del enlace",
			"fimdoVinculo": "Fin del enlace",
			"inicioaplicacaoTaxa": "Tarifa de solicitud de inicio",
			"fimaplicacaotaxa": "Solicitud de tarifa final",
			"valortaxa": "Valor de la tarifa",
			"contratos": "Contratos",
			"isContratoEnviadoParaSS": "Tiene un nuevo contrato en SS",
			"modalidade": "Modalidad",
			"temErros": "hay errores",
			"prestacaoTrabalho": "Provisión de trabajo",
			"inicioContrato": "Iniciar contrato",
			"fimContrato": "Fin del contrato",
			"inicioInformacao": "Información de inicio",
			"fimInformacao": "Información final",
			"profissao": "Profesión",
			"remuneracaoBase": "salario base",
			"diuturnidades": "Pagos de antigüedad",
			"percentagemTrabalho": "Perc.",
			"horasTrabalho": "horas de trabajo",
			"diasTrabalho": "Días laborables",
			"motivoTrabalho": "motivo del trabajo",
			"nissTrabalhador": "Trabajador del NISS",
			"nomeTrabalhador": "Nombre del trabajador",
			"descErros": "Errores",
			"atualizarEstadoEmpregados": "Actualizar el estado del empleado",
			"registarContratos": "Registrar contratos",
			"codempregado": "código de empleado",
			"dadosEmpregado": "Datos de los empleados",
			"dataAdmissao": "Fecha de admisión",
			"numEstabelecimentoSS": "No. establecimiento sec.",
			"tipoContratoTrabalho": "Tipo de contrato de trabajo",
			"assinadoEm": "Iniciado sesión",
			"validaAte": "Válido hasta",
			"vencimentoBase": "Salario base",
			"naoVerificado": "No verificado",
			"contratoSegSocial": "Contrato seg.",
			"motivo": "Razón",
			"tempoParcial": "Tiempo parcial",
			"codEmpSubstituir": "código emp.",
			"dataFimVinculo": "Fecha de finalización del bono",
			"comunicaDesemprego": "Comunicación a efectos de desempleo",
			"fundamentacao": "Razón fundamental",
			"registarPerioRendSS": "Período de ingreso de registro sec.",
			"dataNovoRegistoPeriodo": "fecha del nuevo periodo",
			"vencimentoRegistoPeriodo": "Nuevo salario base",
			"diuturnidadeRegistoPeriodo": "Nuevo valor diurno",
			"registar": "Registro",
			"confiWsSaved": "La configuración se guardó correctamente.",
			"empregadosAtualizadosComSucesso": "Empleados actualizados con éxito",
			"editarContrato": "Editar contrato",
			"authenticationError": "La autenticación falló."
		},
		"enums": {
			"modalidade": {
				"A": "A - NO PLAZO, TIEMPO COMPLETO",
				"B": "B - NO PLAZO, A TIEMPO PARCIAL",
				"C": "C - TRABAJO INTERMITENTE, TIEMPO COMPLETO",
				"D": "D - TRABAJO INTERMITENTE A TIEMPO PARCIAL",
				"E": "E - PLAZO DERECHO, TIEMPO COMPLETO",
				"EA": "EA - CON PLAZO, DERECHO, TIEMPO COMPLETO, COMISIÓN DE SERVICIO",
				"EB": "EB - CON PLAZO, DERECHO, TIEMPO COMPLETO, TELETRABAJO",
				"F": "F - PLAZO FIJO, TIEMPO PARCIAL",
				"FA": "FA - CON PLAZO, DERECHO, TIEMPO PARCIAL, COMISIÓN DE SERVICIO",
				"FB": "FB - CON PLAZO, DERECHO, TIEMPO PARCIAL, TELETRABAJO",
				"G": "G - PLAZO INCIERTO, TIEMPO COMPLETO",
				"H": "H - PLAZO INCIERTO, A TIEMPO PARCIAL",
				"I": "I - TRABAJO DE MUY CORTA DURACIÓN",
				"J": "J - TELETRABAJO, TIEMPO COMPLETO",
				"K": "K - TELETRABAJO A TIEMPO PARCIAL",
				"L": "L - COMISIÓN DE SERVICIO, TIEMPO COMPLETO",
				"M": "M - COMISIÓN DE SERVICIO, A TIEMPO PARCIAL",
				"N": "N - EL TÉRMINO CORRECTO, TRABAJO.",
				"O": "O - EL TÉRMINO CORRECTO, TRABAJO.",
				"P": "P - PLAZO INCIERTO, TRABAJO.",
				"Q": "Q - PLAZO INCIERTO, TRABAJO.",
				"R": "A - POR TIEMPO INDETERMINADO, TRABAJAR.",
				"S": "S - POR TIEMPO INDETERMINADO, TRABAJAR.",
				"AA": "AA - Sin plazo, tiempo completo, comisión de servicio",
				"AB": "AB - Sin plazo, tiempo completo, teletrabajo",
				"BA": "BA - Sin plazo, a tiempo parcial, comisión de servicio",
				"BB": "BB - Sin plazo, a tiempo parcial, teletrabajo",
				"GA": "GA - Plazo incierto, tiempo completo, comisión de servicio",
				"GB": "GB - Plazo incierto, jornada completa, teletrabajo",
				"HA": "HA - Plazo incierto, tiempo parcial, comisión de servicio",
				"HB": "HB - Plazo incierto, teletrabajo a tiempo parcial"
			},
			"motivoContrato": {
				"AEAT": "AEAT - Incremento excepcional de la actividad",
				"ATSA": "ATSA - Actividad estacional",
				"CTSD": "CTSD - Contratación de trabajadores situación de desempleo de muy larga duración",
				"EOPA": "EOPA - Ejecución de obra, proyecto o actividad definida y temporal",
				"EXTO": "EXTO: ejecución de tareas ocasionales",
				"IFEE": "IFEE - Inicio de operación de una empresa/establecimiento con menos de 250 empleados, dentro de los próximos 2 años",
				"LNAT": "LNAT - Inicio de nueva actividad de duración incierta en una empresa/establecimiento de menos de 250 trabajadores, en los 2 años siguientes",
				"STAJ": "STAJ - Trabajador sustituto con demanda por despido",
				"STAT": "STAT - Reemplazo de trabajadores ausentes o incapacitados temporalmente",
				"STLR": "STLR - Reemplazo de trabajadores en licencia no remunerada",
				"STTC": "STTC - Reemplazo de trabajadores a tiempo completo por trabajos a tiempo parcial por un período específico"
			},
			"prestacaoTrabalhoContrato": {
				"P": "P - En persona",
				"T": "T - Teletrabajo"
			},
			"motivoCessar": {
				"CCAI": "CCAI - Despido por el administrador concursal",
				"CCCT": "CCCT - Vencimiento del contrato de duración determinada",
				"CCEE": "CCEE - Extinción de persona jurídica / cierre de la empresa / muerte del empleador",
				"CCFM": "CCFM - Vencimiento del contrato militar",
				"CCMT": "CCMT - Caducidad del contrato por fallecimiento del trabajador",
				"CCRI": "CCRI - Vencimiento del contrato por jubilación por incapacidad",
				"CCRV": "CCRV - Caducidad del contrato por jubilación por vejez",
				"CDT": "CDT - Asignación permanente de trabajador (Asignación de puesto contractual)",
				"IECC": "IECC - Terminación de comisión de servicio o situación similar por iniciativa del empleador",
				"IEDC": "IEDC - Despido colectivo por iniciativa del empresario",
				"IEEX": "IEEX - Despido por cese del trabajo por iniciativa del empleador",
				"IEIN": "IEIN - Despido por incapacidad por iniciativa del empleador",
				"IEJC": "IEJC - Causa justa por iniciativa del empleador",
				"IEPE": "IEPE - Terminación del contrato durante el período de prueba por iniciativa del empleador",
				"IIAT": "IIAT - Abandono del trabajo",
				"IIDD": "IIDD - Extinción del contrato de trabajo/despido por iniciativa del trabajador",
				"IIDE": "IIDE - Terminación del contrato durante el período de prueba por iniciativa del trabajador",
				"IIJC": "IIJC - Causa justa por iniciativa del trabajador",
				"IISA": "IISA - Causa justa de impago de salarios por iniciativa del trabajador",
				"RADC": "RADC - Acuerdo de revocación en los términos del párrafo 4 del artículo 10",
				"RANE": "RANE - Acuerdo de revocación - sin reducir el nivel de empleo, para reforzar la cualificación técnica de la empresa",
				"RAOT": "RAOT - Acuerdo de revocación no previsto en números anteriores",
				"RARC": "RARC - Acuerdo de revocación - empresa en proceso de recuperación",
				"RARD": "RARD - Acuerdo de revocación - empresa en proceso de reestructuración por orden",
				"RARE": "RARE - Acuerdo de revocación - empresa en difícil situación económica",
				"RARR": "RARR - Acuerdo de revocación - empresa en reestructuración perteneciente a un sector específico",
				"TE": "TE - Transferencia de empresa"
			}
		},
		"messages": {
			"selectRecords": "Por favor seleccione al menos un registro",
			"existemEmpregadosComErro": "Hay empleados que cometen errores.",
			"empregadoRegistadoComSucesso": "Empleado registrado exitosamente",
			"empregadoCessadoComSucesso": "Empleado despedido exitosamente",
			"erroInesperado": "Ha ocurrido un error inesperado.",
			"periodoRendimentoRegistadoComSucesso": "El período de rendimiento se registró con éxito."
		}
	},
	"rhrelatoriounico": {
		"title": "Informe único",
		"titleExport": "Exportando el archivo de informe único {{year}}",
		"tabProcess": "Exportar",
		"tabDownload": "Archivo de informe único",
		"generation": "Generación de archivos",
		"fileNotProcessed": "Lo sentimos, pero por alguna razón el archivo no se generó.",
		"jobTimeoutModalTitle": "Tarea",
		"jobTimeoutModalMessage": "La tarea ha expirado.",
		"processar": "Proceso",
		"processingMessage": "Procesando, espere un momento.",
		"erros": "Se produjeron los siguientes errores durante el procesamiento (es posible que el archivo aún esté disponible para descargar en la pestaña del archivo de informe único)",
		"fileReady": "El archivo de informe único está listo.",
		"promptReprocessTitle": "Ya existe un archivo de informe único generado previamente",
		"promptReprocessMessage": "Si continúa, el sistema generará el archivo nuevamente.",
		"warningMessage": "Atención: Luego de iniciar el proceso, tienes 60 minutos para descargar el archivo generado.",
		"promptCancelProcessMessage": "¿Estás seguro de que deseas cancelar el proceso?",
		"dataCriacao": "Fecha de creación",
		"tamanho": "Tamaño",
		"utilizador": "Usuario",
		"buttons": {
			"reiniciar": "Reanudar",
			"download": "Descargar",
			"yes": "Sí",
			"no": "No",
			"fechar": "para cerrar"
		},
		"erroDownload": "¡Ocurrió un error al descargar el archivo!",
		"modal": {
			"config": {
				"title": "Informe único - Configuración",
				"nestabelec": "Código",
				"nome": "Nombre",
				"nestabQPessoal": "ID del establecimiento",
				"savesuccess": "¡Configuración guardada exitosamente!",
				"idEntidadeEmpty": "Es necesario indicar el ID de la entidad.",
				"idEntidade": "ID de entidad"
			}
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmación",
		"leavePromptMessage": "Al hacer clic en SÍ, saldrá y descartará todos los datos de procesamiento."
	},
	"rhsaltotrf": {
		"fields": {
			"codEmpDe": "Empleado de",
			"dataProcesDE": "Fecha de procesamiento",
			"bancoDe": "banco de",
			"fRemuDe": "Forma de remuneración"
		},
		"tabs": {
			"0": "Salarios",
			"1": "Tarjeta/billete de comida",
			"vencimentos": "Salarios",
			"cartaoTicketRefeicao": "Tarjeta/billete de comida"
		},
		"table": {
			"sel": "Sel.",
			"estado": "Estado",
			"emp": "Empleado",
			"nome": "Nombre",
			"valor": "Valor",
			"datalanc": "fecha de lanzamiento",
			"iban": "IBAN",
			"swift": "RÁPIDO",
			"semdados": "Sin datos"
		},
		"messages": {
			"pesqsuccess": "Búsqueda completada exitosamente",
			"pesqsemdados": "No hay vencimientos para el filtro seleccionado.",
			"tooltipExcepcoesProcessamento": "Procesamiento de excepciones",
			"linesnotselected": "No hay líneas seleccionadas para procesar",
			"corrigecartao": "Arreglar tarjeta",
			"removetrans": "Eliminar transferencia",
			"temcertezaremtransf": "¿Está seguro de que desea eliminar la línea de transferencia?",
			"ncontaRequired": "Debe seleccionar un número de cuenta D/O",
			"listatransfsuccess": "Lista de transferencias guardada correctamente"
		},
		"modal": {
			"title": "Generar datos de transferencia",
			"fields": {
				"dataTransf": "fecha de transferencia",
				"nContaPoc": "Número de cuenta D/O"
			}
		},
		"config": {
			"title": "Configuración de bancos, sucursales y cuentas."
		},
		"imprimir": {
			"title": "Pago por banco",
			"titleWithTab": "Pago por banco - {{activeTab}}"
		}
	},
	"rhtransferenciasbancarias": {
		"tab": {
			"listabancos": "Bancos con vales de comida",
			"exclusaoabonos": "Exclusión de bonificaciones/descuentos",
			"transfBancarias": {
				"title": "Transferencias bancarias",
				"formatoPS2": {
					"title": "formato de archivo ps2",
					"ficheiroFormatoPS2": "Transferencias de archivos formato PS2"
				},
				"cgd": {
					"title": "Banco Caixa General de Depósitos",
					"separacaoNibs": "Exportación CGD TXT: separa NIB"
				},
				"dgt": {
					"title": "Dirección General del Tesoro",
					"dgtFormatoSEPA": "Archivo de transferencia DGT en formato SEPA"
				}
			},
			"codigo": "Código"
		},
		"options": {
			"ticketFormatSEPA": "Transferir archivo"
		},
		"fields": {
			"codBanco": "código bancario",
			"codOpTrfBa": "código de operación",
			"nClienteBanco": "Número de cliente en el banco",
			"nCartaoTrfBaIBAN": "Tarjeta de transferencia no.",
			"cartaoDifBanco": "Ref. tarjeta",
			"configTrfBaTicket": "Transferir.",
			"tipoFichTrf": "Transferir tipo de archivo.",
			"tipo": "Tipo",
			"abonodesconto": "Asignación/descuento",
			"designacao": "Designación"
		},
		"modal": {
			"configransfticket": "Configuración de transferencia bancaria de boletos",
			"codBanco": "Banco",
			"codOpTrfBa": "Código de operación de transferencia bancaria (BCO = '48')",
			"nClienteBanco": "Número de cliente en el banco",
			"nCartaoTrfBaIBAN": "El número de tarjeta de transferencia bancaria es IBAN",
			"cartaoDifBanco": "El banco de tarjetas de comida es diferente del banco de pagos.",
			"configTrfBaTicket": "Tienes configurado un ticket de transferencia bancaria",
			"tipoFichTrf": "Tipo de archivo de transferencia ('0 - PS2')",
			"bancoValidator": "Introduce un banco válido"
		},
		"message": {
			"addsuccess": "¡Banco agregado exitosamente!",
			"editsuccess": "¡Banco actualizado exitosamente!",
			"abdescExclusionRepeated": "¡La exclusión ya entró!",
			"abdescempty": "¡Debes seleccionar un subsidio/descuento!",
			"savedSuccess": "¡La configuración se guardó correctamente!"
		}
	},
	"rhunidades": {
		"title_detail": "Unidad de Recursos Humanos {{id}}",
		"title_new": "Nueva Unidad de Recursos Humanos",
		"title_plural": "Unidades de Recursos Humanos",
		"pesquisa": "buscar",
		"saved": "Unidad de Recursos Humanos {{id}}, guardado exitosamente.",
		"error": "Unidad de Recursos Humanos {{id}}, no se puede guardar.",
		"deleted": "Unidad de Recursos Humanos {{id}}, eliminada exitosamente.",
		"fields": {
			"codUnidade": "Código",
			"nome": "Nombre",
			"nomeAbreviado": "nombre corto",
			"tipoUnidade": "Tipo de unidad"
		}
	},
	"roles": {
		"title_detail": "Función {{id}}",
		"title_plural": "Roles",
		"pesquisa": "buscar",
		"fields": {
			"role": "Nombre",
			"description": "Descripción"
		}
	},
	"rptconfigs": {
		"fields": {
			"modulo": "Módulo",
			"nome": "Nombre de la fórmula",
			"descricao": "Descripción",
			"valor": "Valor"
		},
		"modulos": "Módulos",
		"variaveisRelatorio": "Variables del informe",
		"saveSuccess": "La configuración se guardó correctamente."
	},
	"saft": {
		"tabProcess": "Exportar",
		"tabDownload": "Archivo SAF-T",
		"generation": "Generación de archivos",
		"fileNotProcessed": "Lo sentimos, pero por alguna razón el archivo no se generó.",
		"jobTimeoutModalTitle": "Tarea",
		"jobTimeoutModalMessage": "La tarea ha expirado.",
		"contabilidade": "Contabilidad",
		"faturacao": "Facturación",
		"processar": "Proceso",
		"processingMessage": "Procesando, espere un momento.",
		"erros": "Errores",
		"fileReady": "El archivo SAF-T está listo.",
		"promptReprocessTitle": "Ya existe un archivo SAF-T previamente generado",
		"promptReprocessMessage": "Si continúa, el sistema generará el archivo nuevamente.",
		"warningMessage": "Atención: Luego de iniciar el proceso, tienes 60 minutos para descargar el archivo generado.",
		"promptCancelProcessMessage": "¿Estás seguro de que deseas cancelar el proceso?",
		"dataCriacao": "Fecha de creación",
		"tamanho": "Tamaño",
		"utilizador": "Usuario",
		"documentos": "Documentos",
		"buttons": {
			"reiniciar": "Reanudar",
			"download": "Descargar",
			"yes": "Sí",
			"no": "No",
			"fechar": "para cerrar"
		},
		"erroDownload": "¡Ocurrió un error al descargar el archivo!",
		"recibos": "Ingresos",
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmación",
		"leavePromptMessage": "Al hacer clic en SÍ, saldrá y descartará todos los datos de procesamiento.",
		"contabilidadeFaturacaoRecibos": "Contabilidad, Facturación y Recibos",
		"autofaturacao": "Autofacturación",
		"nConta": "Proveedor",
		"title": {
			"normal": "Exportar archivo SAF-T",
			"autofaturacao": "Exportar archivo de Autofacturación SAF-T"
		}
	},
	"saftcontab": {
		"steps": {
			"welcome": {
				"step": "Bienvenido",
				"title": "Bienvenido al asistente de importación de SAF-T PT para contabilidad.",
				"desc2": "Haga clic en \"Siguiente\" para continuar con la importación.",
				"desc": "Este importador está destinado a importar movimientos contables a través de un archivo SAF-T PT a contabilidad."
			},
			"importtype": {
				"step": "Tipo de importación",
				"importtypes": "Tipos de importaciones disponibles",
				"fields": {
					"importextratocomsi": "Declaración de importación (Con importación de saldos iniciales)",
					"importextratosemsi": "Estado de cuenta de importación (Sin Importación de saldos iniciales)",
					"importsaldosiniciais": "Importar saldos iniciales únicamente",
					"importterceiros": "Importar solo cuentas de terceros"
				}
			},
			"upload": {
				"step": "Archivo SAFT",
				"title": "Por favor elija el archivo que desea importar",
				"import": "Importar Importar archivo de configuración",
				"export": "Exportar Importar archivo de configuración"
			},
			"fields": {
				"nDiario": "Número diario",
				"nome": "Nombre",
				"diarioOriem": "Diario fuente",
				"diarioDestino": "Diario de destino",
				"contaOrigem": "Cuenta de origen",
				"contaDestino": "Cuenta objetivo",
				"nConta": "Nro. de cuenta",
				"criaConta": "para crear",
				"codControlIvaDebito": "Control del IVA débito",
				"codControlIvaCredito": "Control de crédito de IVA",
				"nif": "Contribuyente",
				"documento": "Documento",
				"valorBase": "Valor base",
				"valorIvaCalculado": "Valor probable del IVA",
				"taxaCalculada": "tasa probable",
				"nContasBase": "Número de cuentas base",
				"nContasIVA": "Número de cuentas de IVA",
				"valorBaseDoc": "Valor base doc.",
				"valorIVADoc": "Valor IVA doc.",
				"taxaSobreTotalIVA": "Tarifa sin IVA total",
				"contaCredito": "cuenta de crédito",
				"contaDebito": "cuenta de débito",
				"descricao": "Nombre",
				"valor": "Valor",
				"contaExistente": "Cuentas existentes",
				"convertConta": "Cuentas con conversión",
				"contasSemConversao": "Cuentas sin conversión",
				"nContaDestino": "Número de cuenta de destino",
				"mercado": "Mercado"
			},
			"associacaoDiarios": {
				"step": "Asociación de revistas",
				"title": "Asociación de revistas contables",
				"desc": "Indique si desea asociar revistas con revistas existentes."
			},
			"planoContas": {
				"step": "plan de cuentas",
				"title": "plan de cuentas",
				"filters": {
					"contasComErro": "Ver sólo cuentas con errores",
					"contasComConversao": "Ver solo cuentas con conversión",
					"contasSemConversao": "Ver solo cuentas que no se convierten"
				},
				"tooltip": {
					"importMatriz": "Importar matriz de conversión"
				},
				"btn": {
					"importMatriz": "Matriz de importación"
				}
			},
			"convertContas": {
				"step": "Conversión de cuenta",
				"title": "Conversión de cuenta",
				"desc": "Indique la matriz de conversión que desea para las cuentas en el plan de cuentas."
			},
			"contasExist": {
				"step": "Cuentas existentes",
				"title": "Cuentas que ya existen en el plan de cuentas.",
				"desc": "Por favor verifique las cuentas que se importarán y que ya existen en el plan de cuentas.",
				"desc1": "Si lo desea, puede reemplazar la cuenta de destino."
			},
			"contasSemConvert": {
				"step": "Cuentas sin conversión",
				"title": "Cuentas sin conversión definida",
				"desc": "Indique las cuentas no convertibles que desea crear."
			},
			"contasCorrentes": {
				"step": "cuentas corrientes",
				"title": "Cuentas corrientes a crear",
				"apenasContasMovimentadas": "Sólo importa cuentas activas",
				"efetuaConversaoContasCorrentes": "Realiza la conversión a la cuenta objetivo según el mercado."
			},
			"configImport": {
				"step": "Ajustes",
				"title": "Importar configuración",
				"fields": {
					"diario": "Número diario",
					"periodo": "Período",
					"ndescritivo": "Descripción de lanzamientos",
					"descritivosaldosiniciais": "Descriptivo no.",
					"saldosiniciais": "Saldos iniciales",
					"diarioapura": "Diario de resultados en el archivo SAF-T",
					"transactiondate": "Realizar la importación en la que se forma el período a partir de <TransactionDate> (atención, utilizar sólo cuando el archivo SAF-T haya cambiado de fechas)",
					"diaroAbertura": "Apertura",
					"descritivoSaldosIniciais": "Saldo inicial",
					"justificaContasCorrenteMovAb": "Justificar movimientos abiertos en cuentas corrientes"
				},
				"messages": {
					"nDescritivoError": "Las entradas descriptivas no pueden estar vacías.",
					"periodoSaldosIniciaisError": "El período no puede estar vacío.",
					"nDiarioSaldosIniciaisError": "El número diario no puede estar vacío.",
					"nDescritivoSaldosIniciaisError": "El número descriptivo no puede estar vacío.",
					"justificaContasCorrenteMovAbInfo": "Los movimientos de la cuenta corriente se justificarán automáticamente hasta el último período en el que el fichero SAFT presente asientos.",
					"importarSaftContab": "Tramitando la importación de contabilidad SAF-T..."
				}
			},
			"finalized": {
				"step": "Resultado",
				"successfully": "¡Operación completada con éxito!"
			}
		},
		"modals": {
			"processing": {
				"title": "Importador Contable SAF-T",
				"processModalLoad": "Proceso de carga",
				"processModalCancel": "Cancelar proceso"
			},
			"planos": {
				"title": "Marco del plan de cuentas de la cuenta \"{{nConta}}\"",
				"hint": "Marco del plan de cuentas"
			},
			"convertcontas": {
				"title": "Conversión de cuenta",
				"contaorigem": "cuenta de origen",
				"contadestino": "Cuenta de destino"
			},
			"balanceteNaoSaldado": "La balanza de comprobación del archivo SAFT no está equilibrada.",
			"import": {
				"title": "Importar",
				"configimportedsuccess": "Configuración importada exitosamente."
			},
			"importMatriz": {
				"title": "Importación de matriz de conversión",
				"content": {
					"1": "Bienvenido al asistente de importación de matrices de conversión.",
					"2": "Seleccione el modelo que desea descargar.",
					"3": "Después de descargar el archivo, rellénelo con los datos que desea importar."
				},
				"btn": {
					"modClean": "Descargar plantilla en blanco",
					"modFilled": "Descargar plantilla completa"
				}
			}
		},
		"messages": {
			"timeoutModalMessage": "Lo sentimos, pero el plazo para importar el archivo contable SAF-T ha expirado.",
			"importProcessModalMessageCancelJob": "El proceso de importación ya ha comenzado.",
			"jobCancelled": "El proceso de importación se canceló exitosamente.",
			"alertTimerEndProcess": "Atención: Luego de iniciar el proceso, tienes 60 minutos para finalizar la importación.",
			"jobTimeoutModalTitle": "Importación contable SAF-T",
			"jobTimeoutModalMessage": "La tarea ha expirado.",
			"processarFicheiroLabel": "Procesando el expediente contable SAF-T...",
			"noFiles": "Seleccione el archivo a procesar...",
			"noTypeImport": "No seleccionó el tipo de importación.",
			"accountsWithError": "Hay cuentas que contienen errores.",
			"accountsWithoutSel": "Hay cuentas que no están marcadas para ser creadas.",
			"errorocur": "Se ha producido un error interno.",
			"contaOrigemVazia": "No seleccionó una cuenta de origen.",
			"contaDestinoVazia": "No ha seleccionado una cuenta de destino.",
			"erroDownload": "¡Ocurrió un error al descargar el archivo!",
			"importsuccess": "¡Importación completada exitosamente!",
			"operationsuccess": "¡Operación completada con éxito!",
			"aprepararImportSaft": "Preparando el proceso de importación contable SAF-T..."
		},
		"btns": {
			"processLoad": "",
			"processReset": ""
		},
		"strings": {
			"jobTimeoutModalTitle": "Tarea",
			"planoSaft": "Plan SAFT",
			"planoCG": "Plan CentralGest"
		},
		"leavePromptTitle": "<i class='fa fa-fw fa-question-circle text-info'></i> Confirmación",
		"leavePromptMessage": "Al hacer clic en SÍ, saldrá y descartará todos los datos de procesamiento.",
		"importTypePromptMessage": "Cambiar el tipo de importación descartará todos los datos de procesamiento."
	},
	"scss": {
		"or": "o",
		"and": "y",
		"locked-portal": "No tienes acceso a este portal",
		"locked-portal-cgstore": "No tiene acceso a este portal, vaya al portal de compras haciendo clic en el botón \"Tienda\".",
		"locked-portal-expired": "Este portal ha sido bloqueado porque el plan asociado ha caducado."
	},
	"seccoes": {
		"title_detail": "Sección {{id}}",
		"title_new": "Nueva sección",
		"title_plural": "Secciones",
		"pesquisa": "buscar",
		"saved": "Sección {{id}}, guardada exitosamente",
		"error": "La sección {{id}} no se puede guardar.",
		"deleted": "Sección {{id}}, eliminada exitosamente",
		"fields": {
			"nSeccao": "Código de sección",
			"designacaoBreve": "Breve designación",
			"designacaoCompleta": "Designación completa"
		}
	},
	"sectores": {
		"title_detail": "Sector {{id}}",
		"title_new": "Nuevo Sector",
		"title_plural": "Sectores",
		"pesquisa": "buscar",
		"saved": "Sector {{id}}, guardado exitosamente",
		"error": "Sector {{id}}, no se puede guardar.",
		"deleted": "Sector {{id}}, eliminado exitosamente",
		"fields": {
			"sector": "código de industria",
			"nome": "Nombre",
			"idExterno": "Identificación externa"
		}
	},
	"segur": {
		"title_detail": "Compañía de seguros {{id}}",
		"title_new": "Nueva compañía de seguros",
		"title_plural": "Compañías de seguros",
		"pesquisa": "buscar",
		"saved": "Compañía de seguros {{id}}, guardado exitosamente.",
		"error": "Compañía de seguros {{id}}, no se puede guardar.",
		"deleted": "Compañía de seguros {{id}}, eliminada correctamente",
		"fields": {
			"nsegur": "código de seguro",
			"designaBreve": "Breve descripción",
			"nome": "Descripción completa",
			"morada": "Familiar",
			"npostal": "Código postal",
			"localidade": "Ubicación",
			"telefone": "Teléfono",
			"napolice": "Política",
			"codSeguradoraISP": "Código de seguro (ISP)"
		},
		"errors": {
			"invalidApolice": "El formato de archivo de la hoja de vacaciones requiere que el número de póliza tenga 20 caracteres."
		}
	},
	"simpleLogin": {
		"at": {
			"userNameFieldLabel": "Contribuyente No."
		},
		"ssd": {
			"userNameFieldLabel": "niss"
		},
		"viactt": {
			"userNameFieldLabel": "Usuario"
		},
		"saveTitle": "Ahorrar",
		"passwordFieldLabel": "Contraseña",
		"messages": {
			"successLogin": "Inicie sesión exitosamente.",
			"credentialsEmpty": "El nombre de usuario o contraseña está vacío."
		}
	},
	"simulacaoVencimento": {
		"fields": {
			"dgemp": "Colaborador",
			"nDiasUteis": "Número de días laborables",
			"valorLiquido": "Valor neto a obtener",
			"dataSimulacao": "Fecha de simulación"
		},
		"text": {
			"simularValor": "Simular patrimonio neto",
			"reciboSimulado": "Recibo simulado",
			"vencimento": "Valor bruto de vencimiento simulado",
			"totalIliquido": "Total bruto",
			"totalDesconto": "Descuento total",
			"totalLiquido": "Total neto",
			"totalEspecie": "Especies totales",
			"totalAPagar": "Total a pagar"
		},
		"table": {
			"tipoAbono": "Tipo de subsidio",
			"codAbDesc": "código de asignación",
			"descricao": "Descripción",
			"qtd": "Cant.",
			"valorUnit": "Valor unitario.",
			"valor": "Valor",
			"incidencia": "Incidencia",
			"percentagem": "Porcentaje"
		}
	},
	"sinalTaxaRetencao": {
		"data": {
			"negativo": "-",
			"positivo": "+"
		}
	},
	"sind": {
		"title_detail": "Archivo de unión {{id}}",
		"title_new": "Nuevo expediente de la Unión",
		"title_plural": "Archivos de la Unión",
		"pesquisa": "buscar",
		"saved": "Archivo de unión {{id}}, guardado exitosamente.",
		"error": "Archivo de unión {{id}}, no se puede guardar.",
		"deleted": "Archivo de unión {{id}}, eliminado exitosamente",
		"fields": {
			"nSindicato": "número de unión",
			"nome": "Nombre",
			"designaBreve": "Breve designación",
			"morada": "Familiar",
			"npostal": "Código postal",
			"localidade": "Ubicación",
			"telefone": "Teléfono",
			"fax": "Fax",
			"nContrib": "número de contribuyente",
			"nBanco": "código bancario",
			"nAgencia": "Código de agencia",
			"contaGenBanc": "número de cuenta bancaria",
			"bairroFiscal": "departamento de finanzas",
			"valorMinQuot": "Valor mínimo de la acción",
			"nomeLocalidade": "Ubicación",
			"nomeBairroFiscal": "departamento de finanzas"
		}
	},
	"situacaoprofissional": {
		"title_detail": "Estado de la profesión {{id}}",
		"title_new": "Nueva situación profesional",
		"title_plural": "Situación de las profesiones",
		"pesquisa": "buscar",
		"saved": "Estado de profesión {{id}}, guardado exitosamente",
		"error": "Estado de profesión {{id}}, no se puede guardar.",
		"deleted": "Estado de profesión {{id}}, eliminado correctamente",
		"fields": {
			"nSitProfiss": "Código de estado de profesión",
			"designaBreve": "Breve designación",
			"designaComp": "Designación completa",
			"codQuadroPessoal": "mesa de personal"
		}
	},
	"situacaoempregado": {
		"title_detail": "Estado del empleado {{id}}",
		"title_new": "Nuevo estado de empleado",
		"title_plural": "Situaciones de empleados",
		"pesquisa": "buscar",
		"saved": "Estado del empleado {{id}}, guardado exitosamente",
		"error": "Estado del empleado {{id}}, no se puede guardar.",
		"deleted": "Estado de empleado {{id}}, eliminado correctamente",
		"fields": {
			"nSitEmpreg": "código de situación",
			"designaBreve": "Breve designación",
			"designaCompl": "Designación completa",
			"processaVenc": "Fecha de vencimiento del proceso"
		}
	},
	"solic": {
		"title_detail": "Texto del aviso de solicitud de facturación {{id}}",
		"title_new": "Nuevo texto de aviso de solicitud de facturación",
		"title_plural": "Textos de aviso de solicitud de cobro",
		"pesquisa": "buscar",
		"saved": "Texto de aviso de solicitud de facturación {{id}}, guardado correctamente.",
		"error": "El texto del aviso de solicitud de facturación {{id}} no se puede guardar.",
		"deleted": "Texto de aviso de solicitud de facturación {{id}}, eliminado correctamente.",
		"fields": {
			"tipoTexto": "Tipo",
			"nTexto": "Número",
			"tituloTexto": "Título",
			"nDiasAtraso": "Número de días de retraso",
			"note": "Descripción"
		},
		"datasource": {
			"0": "Encabezamiento",
			"1": "Zócalo",
			"cabecalho": "Encabezamiento",
			"rodape": "Zócalo"
		}
	},
	"sscol": {
		"title_detail": "Compensación del Seguro Social {{id}}",
		"title_new": "Nuevas Retribuciones de la Seguridad Social",
		"title_plural": "Remuneraciones de la Seguridad Social",
		"pesquisa": "buscar",
		"saved": "Remuneración de la Seguridad Social {{id}}, guardada con éxito.",
		"error": "Retribución de la Seguridad Social {{id}}, no se puede guardar.",
		"deleted": "Remuneración de la Seguridad Social {{id}}, eliminada con éxito.",
		"fields": {
			"codSSCol": "Código",
			"designacao": "Designación",
			"dias": "Días",
			"valor": "Valor"
		}
	},
	"subde": {
		"title_detail": "Subdepartamento {{id}}",
		"title_new": "Nuevo Subdepartamento",
		"title_plural": "Subdepartamentos",
		"pesquisa": "buscar",
		"saved": "Subdepartamento {{id}}, guardado exitosamente.",
		"error": "Subdepartamento {{id}}, no se puede guardar.",
		"deleted": "Subdepartamento {{id}}, eliminado exitosamente",
		"fields": {
			"nSubDe": "Código.",
			"nSubDePlaceholder": "Código de subdepartamento",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del subdepartamento"
		}
	},
	"subfamilias": {
		"title_detail": "Subfamilia {{id}}",
		"title_new": "Nueva subfamilia",
		"title_plural": "Subfamilias",
		"pesquisa": "buscar",
		"saved": "Subfamilia {{id}}, guardada correctamente.",
		"error": "Subfamilia {{id}}, no se puede guardar.",
		"deleted": "Subfamilia {{id}}, eliminada correctamente",
		"fields": {
			"nSubFa": "Código.",
			"nSubFaPlaceholder": "Código de subfamilia",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre de subfamilia"
		}
	},
	"suspensaoContrato": {
		"data": {
			"tscNA": "N / A",
			"tscSim": "Sí",
			"tscNao": "No"
		}
	},
	"svat": {
		"messages": {
			"noData": "Por favor elija el año y presione el botón \"Procesar\" para ver los datos."
		},
		"modal": {
			"title": "Estados financieros del SVAT - Año: {{currentyear}}",
			"titleBalancete": "Balance"
		},
		"fields": {
			"descricao": "Rúbrica",
			"notas": "Notas",
			"anoN": "Año N",
			"anoN1": "Año N-1",
			"nContaDe": "Número de cuenta",
			"nContaAte": "El número cuenta hasta",
			"taxonomyCode": "Taxonomía",
			"taxonomyDescription": "Descripción",
			"taxonomyClasse": "Clase",
			"taxonomySaldoEsperado": "Saldo esperado",
			"mesPeriodo": "Período del mes",
			"tipoSaldo": "Tipo de saldo",
			"nomeCliente": "Nombre",
			"nConta": "Nro. de cuenta"
		},
		"strings": {
			"mainTabBalanco": "Balance",
			"mainTabDemoResultadosPorNaturezas": "Demostración de resultados por naturaleza.",
			"rubricaSubTabIntervaloContas": "Rangos de cuenta",
			"rubricaSubTabBalancete": "Balance",
			"verBalanceteTitulo": "Ver balance de comprobación"
		}
	},
	"taxasiva": {
		"title_detail": "Califica {{id}}",
		"title_new": "Nueva tarifa",
		"title_plural": "tipos de IVA",
		"pesquisa": "buscar",
		"saved": "Califica {{id}}, guardado exitosamente",
		"error": "Califica{{id}}, no se puede guardar.",
		"deleted": "Califica {{id}}, eliminado exitosamente",
		"fields": {
			"codIva": "código de IVA",
			"dataAltLegal": "Fecha de cambio legal",
			"taxa": "Tasa",
			"observacao": "Observación"
		},
		"criar": "Mantenimiento del tipo de IVA",
		"criada": "Tasa de IVA creada correctamente.",
		"codIvaNull": "El código de IVA no puede ser nulo."
	},
	"taxasretencao": {
		"title_new": "Nueva tasa de retención",
		"title_detail": "{{id}} tasa de retención",
		"title_plural": "Tasas de retención",
		"saved": "Tasa de retención {{id}}, guardada correctamente",
		"error": "Tasa de retención {{id}}, no se puede guardar.",
		"deleted": "Tasa de retención {{id}}, eliminada correctamente",
		"pesquisa": "buscar",
		"fields": {
			"codRet": "Código de retención",
			"nomeRet": "Nombre",
			"taxaRet": "Tasa (%)",
			"tipoCodRet": "Tipo de código",
			"sinal": "señal de movimiento",
			"tipRendiment": "Tipo de ingreso",
			"nContaRetencao": "cuenta de retención",
			"nomeTipRendiment": "Tipo de ingreso",
			"nomeContaRetencao": "cuenta de retención"
		},
		"tipoRendimento": {
			"title": "Tipo de ingreso",
			"fields": {
				"declaracao": "Declaración",
				"tipoRendimento": "Tipo de ingreso",
				"regTrib": "Régimen fiscal"
			}
		},
		"declaracaoRetFonte": {
			"title": "Declaración de retención de impuestos",
			"fields": {
				"zona": "Zona",
				"rubrica": "Rúbrica"
			}
		}
	},
	"taxonomias": {
		"exportXls": "Exportar cuadrícula",
		"autoCorrect": "Aplicar correcciones automáticas",
		"autoCorrectSuccess": "Correcciones aplicadas correctamente",
		"autoCorrectDatas": "Aplicar correcciones automáticas a las fechas.",
		"autoCorrectDatasMessageOkCancel": "Al aplicar la corrección automática a las fechas del documento, las fechas no válidas se cambiarán al último día del período al que se refiere el documento.<br/>¿Está seguro de que desea continuar?",
		"autoCorrectDatasTitleOkCancel": "¡Atención!",
		"autoCorrectDoc0": "Aplicar correcciones automáticas a documentos con una sola entrada y con valor 0",
		"autoCorrectClasse9": "Aplicar correcciones automáticas a documentos con clase 9 sin resolver",
		"autoCorrectClasse0": "Aplicar correcciones automáticas a documentos con clase 0 sin resolver",
		"autoCorrectDocsSemLinhas": "Aplicar correcciones automáticas a documentos sin líneas.",
		"autoCorrectMessageClasse0": "Al aplicar la corrección automática a documentos con clase 0 impaga, el sistema contabilizará automáticamente la cuenta {{nconta}} para liquidar la clase 0.<br/>¿Estás seguro de que deseas continuar?",
		"autoCorrectMessageClasse9": "Al aplicar la corrección automática a documentos con clase 9 no liquidada, el sistema contabilizará automáticamente la cuenta {{nconta}} para liquidar el análisis.<br/>¿Estás seguro de que deseas continuar?",
		"autoCorrectMessageClasseDocValor": "Al aplicar la corrección automática a documentos con una sola línea de valor 0, el sistema ingresará la misma cuenta con el signo opuesto en todos los documentos en esta situación.<br/>¿Está seguro de que desea continuar?",
		"relacaoContas": {
			"nContaDe": "Número de cuenta",
			"nContaAte": "El número cuenta hasta",
			"codTaxonomia": "código de taxonomía",
			"descricao": "Descripción"
		},
		"informacaoLegal": {
			"codTaxonomia": "código de taxonomía",
			"codSNC": "Código SNC",
			"descricao": "Descripción",
			"observacoes": "Observaciones",
			"classe": "Clase",
			"saldoEsperado": "Saldo esperado",
			"demFinanCampDeb": "Campo de débito de estados financieros",
			"demFinanCampCred": "Campo de crédito de estados financieros"
		},
		"planoOficial": {
			"nConta": "Nro. de cuenta",
			"nome": "Nombre"
		},
		"validacoesPlano": {
			"fase": "Fase",
			"conta": "Nro. de cuenta",
			"mensagem": "Mensaje",
			"mensagemCorrecaoAutomatica": "Mensaje de autocorrección",
			"contaterceirosuccess": "Cuenta corriente de terceros creada correctamente.<br/>¿Quieres editar la cuenta de terceros?"
		},
		"validacoesSvat": {
			"showOnlyErrors": "Ver solo cuentas con errores",
			"tableFirstHeader": {
				"contasComSaldo": "Cuentas con Saldo",
				"valSVAT": "Validaciones SVAT para tipo de contabilidad - \"Organizado con SNC\""
			},
			"tableSecondHeader": {
				"contas": "Cuentas",
				"saldos": "Saldos - Sin cálculo de resultados"
			},
			"tableThirdHeader": {
				"nConta": "Nro. de cuenta",
				"nome": "Nombre",
				"tipo": "Tipo",
				"conta": "Cuenta",
				"codTaxonomia": "código de taxonomía",
				"descricao": "Descripción",
				"classe": "Clase",
				"saldoEsperado": "Saldo esperado",
				"dezembroNormal": "Diciembre (Normal)",
				"dezembroFirst": "Diciembre (1er Clausura)",
				"dezembroSecond": "Diciembre (2do Clausura)"
			}
		},
		"validacoesLancamentos": {
			"tipo": "Tipo",
			"fase": "Fase",
			"conta": "Cuenta",
			"periodo": "Período",
			"diario": "A diario",
			"numeroDocInterno": "Documento interno no."
		},
		"relacaocontasTab": "Relación de cuentas con taxonomías",
		"informacaoLegalTab": "Información legal",
		"planoOficialTab": "Plan oficial publicado",
		"validacoesSvatTab": "Validaciones SVAT",
		"validacoesPlanoTab": "Validaciones de planes",
		"validacoesLancamentosTab": "Validaciones de lanzamiento",
		"confirmValidacoesLancamentos": "¿Quieres validar las entradas?<br/>Esta operación puede tardar unos minutos.",
		"actions": {
			"criarContaTerceiro": "Crear cuenta de terceros",
			"trfConta": "Transferencia de cuenta",
			"manutencaoPoc": "Mantenimiento del plan de cuentas.",
			"extratos": "Estado de cuenta"
		},
		"modalContaSalda": {
			"titleAnalitica": "Por favor elija la cuenta con la que desea pagar los análisis en los documentos",
			"titleClasse0": "Por favor elija la cuenta con la que desea pagar clase 0 en los documentos",
			"titleNormal": "Por favor elija la cuenta que desea para la corrección de documentos",
			"conta": "Cuenta"
		},
		"grids": {
			"title": "Taxonomías {{typeTaxonomy}} - {{typeAccounting}}"
		}
	},
	"templates": {
		"title_detail": "Plantilla {{id}}",
		"title_new": "Nueva plantilla",
		"title_plural": "Plantillas",
		"pesquisa": "buscar",
		"saved": "Plantilla {{id}}, guardada correctamente",
		"error": "La plantilla {{id}} no se puede guardar.",
		"fields": {
			"name": "Nombre",
			"subject": "Sujeto",
			"body": "Cuerpo",
			"variables": "variables"
		},
		"text": {
			"sendEmail": "Enviar correo electrónico"
		}
	},
	"terceiros": {
		"clientes": "Clientes",
		"fornecedores": "Proveedores",
		"outros": "Otros deudores y acreedores"
	},
	"terms": {
		"title": "Términos, condiciones y política de privacidad CentralGest Cloud",
		"version": "Versión <strong>{{versión}}</strong>",
		"introduction": "Antes de utilizar CentralGest Cloud, debes aceptar nuestros términos y condiciones de uso del servicio y nuestra política de privacidad.",
		"titleTerms": "Términos y condiciones",
		"titlePrivacyPolicy": "política de privacidad",
		"changedTerms": "La política de privacidad o términos y condiciones de uso del servicio CentralGest Cloud han <strong>actualizado</strong> desde la última vez que los aceptó, por favor revíselos y acéptelos.",
		"acceptLabel": "He leído y acepto los términos y condiciones de uso del servicio y la política de privacidad",
		"acceptTooltip": "Para continuar debes aceptar los términos y condiciones de uso del servicio y la política de privacidad.",
		"errorRequired": "Debe aceptar los términos, condiciones y política de privacidad de CentralGest Cloud para acceder a la aplicación."
	},
	"tesestados": {
		"title_detail": "Estado de validación de la previsión de tesorería {{id}}",
		"title_new": "Nuevo estado de validación de previsión de tesorería",
		"title_plural": "Estados de validación de previsión de efectivo",
		"pesquisa": "buscar",
		"saved": "Estado de validación de la previsión de tesorería {{id}}, guardado correctamente.",
		"error": "Estado de validación de previsión de tesorería {{id}}, no se puede guardar.",
		"deleted": "Estado de validación de previsión de tesorería {{id}}, eliminado correctamente",
		"fields": {
			"codEstado": "código de estado",
			"codEstadoPlaceholder": "Código de estado de validación de previsión de efectivo",
			"descricao": "Descripción",
			"descricaoPlaceholder": "Descripción del estado",
			"estadoValidacao": "Estado de validación",
			"estadoValidacaoPlaceholder": "Elija el estado de validación"
		}
	},
	"tesrubrica": {
		"title_detail": "Estado de validación de Previsiones de Tesorería {{id}}",
		"title_new": "Nuevo estado de validación del Pronóstico del Tesoro",
		"title_plural": "Estados de validación de pronósticos del Tesoro",
		"pesquisa": "buscar",
		"deleted": "El estado de validación del Pronóstico de Tesorería se eliminó exitosamente.",
		"saved": "Estado de validación de Previsiones de Tesorería {{id}}, guardado correctamente.",
		"error": "Estado de validación de Previsión de Tesorería {{id}}, no se puede guardar.",
		"fields": {
			"tesRubricaID": "código de rúbrica",
			"nome": "Nombre",
			"receitaDespesa": "Ingresos/Gastos"
		},
		"tipoReceitaDespesa": {
			"receita": "Ganancia",
			"despesa": "Gastos"
		}
	},
	"themes": {
		"light": "Claire",
		"dark": "Oscuro",
		"auto": "Automático"
	},
	"tipoAbonoAgrupado": {
		"data": {
			"nd": "N / A",
			"vencimentos": "Madurez",
			"subRefeicao": "Subsidios de comidas",
			"remunAdicionais": "Remuneración Adicional",
			"subsidios": "Subvenciones",
			"rendEspecie": "Rendimiento de especies",
			"cessacaoContrato": "Terminación del Contrato",
			"gratificacoes": "Propinas"
		}
	},
	"tipoAbonoDesc": {
		"data": {
			"abono": "Prestación",
			"desconto": "Descuento"
		}
	},
	"tipoAnalitica": {
		"data": {
			"lancaConformeDefinidoIntegracao": "Lanzamientos como se define en la integración",
			"naoLancaAnalitica": "No inicia análisis",
			"lancaSempreAnalitica": "Lanzar siempre análisis"
		}
	},
	"tipoAssiduidadeSegSocial": {
		"data": {
			"taaNA": "N / A",
			"taaSim": "Sí",
			"taaNao": "No"
		}
	},
	"tiposAusencias": {
		"data": {
			"na": "N / A",
			"porFerias": "Para vacaciones",
			"porDoencaImprevista": "Por enfermedad imprevista",
			"porDoencaProf": "Por enfermedad profesional",
			"porDoencaProlongada": "Debido a una enfermedad prolongada",
			"porTribunal": "Por corte",
			"porServicoMilitar": "Para el servicio militar",
			"porAssistenciaFamiliar": "Para asistencia familiar",
			"porPaternidadeMaternidade": "Por paternidad/maternidad",
			"porLicencaAurorizada": "Por licencia autorizada",
			"porPenaDisciplinar": "Por sanción disciplinaria",
			"porLicencaSabatica": "Por licencia sabática",
			"porCasamento": "Por matrimonio",
			"porNascimentoFamiliar": "Por nacimiento de un familiar",
			"porFalecimentoFamiliar": "Por fallecimiento de un familiar",
			"porTrabalhadorEstudante": "Por estudiante trabajador",
			"porGreveParalizacao": "Por huelga/paro",
			"porConsultaMedico": "Por consulta médica, pruebas diagnósticas.",
			"outras": "Otros",
			"porFaltaInjustificada": "Por ausencia injustificada",
			"actividadeSindical": "Por actividad sindical",
			"acidenteTrabalho": "Debido a un accidente de trabajo"
		}
	},
	"tiposBalancoSocial": {
		"data": {
			"tabsNA": "N / A",
			"tabsSim": "Sí",
			"tabsNao": "No"
		}
	},
	"tipoBem": {
		"pesquisa": "tipos de bien",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"notApplicable": "N / A",
			"stocks": "Cepo",
			"otherGoodsServices": "Otros bienes y servicios",
			"immobilized": "Activos fijos"
		}
	},
	"tiposCategoria": {
		"data": {
			"notApplicable": "Ningún grupo",
			"vencimento": "Madurez",
			"diuturnidade": "Antigüedad",
			"premios": "Premios",
			"comissoes": "Comisiones",
			"subRefeicao": "Sub.",
			"ajudaCusto": "Asignación de costos",
			"horaExtra": "Con el tiempo",
			"ferias": "Vacaciones",
			"subFerias": "Sub.",
			"feriasPagas": "vacaciones pagadas",
			"subNatal": "Sub.",
			"trabNocturno": "trabajo nocturno",
			"insencaoHorario": "Exención de horario",
			"subTurno": "Sub.",
			"faltasPagas": "Ausencias pagadas",
			"indemnizacaoCaducidade": "Compensación por decomiso",
			"indemnizacaoDespedimento": "Indemnización por despido",
			"indemnizacaoGlobal": "Compensación integral",
			"deslocacao": "Viajar",
			"remProfLivre": "Retribución profesional gratuita",
			"iva": "TINA",
			"virtual": "Virtual",
			"suplementoRemuneratorio": "Complemento retributivo (Previsión por averías)",
			"ticketValesRefeicao": "Entradas/vales de comida",
			"pagamentosEmEspecie": "Pago en especie",
			"valesEducacao": "Vales de educación",
			"utlViaturaPatronal": "Uso del vehículo del empleador",
			"gratificacoesBombeiros": "Bonos de bombero",
			"cartaoOferta": "Tarjeta de regalo",
			"apoioRegimeLayoff": "Apoyo al régimen de despidos",
			"despesasTeletrabalho": "Gastos de teletrabajo",
			"utilizHabitaPropriaPermanente": "Uso de la vivienda proporcionada por el empleador",
			"gratificacaoBalancoArt236": "Arte de bonificación de saldo."
		}
	},
	"tipoClasseContrato": {
		"data": {
			"contratoServico": "Contrato de servicio",
			"contratoPrecoCliente": "Contrato de lista de precios del cliente",
			"contratoPrecoFornecedor": "Acuerdo de lista de precios de proveedores",
			"contratoSubContratacao": "Contrato de subcontratación",
			"contratoFornecimentoCliente": "Contrato de suministro al cliente",
			"contratoFornecimentoFornecedor": "Contrato de suministro del proveedor",
			"contratoAlocacaoRecurso": "Contrato de asignación de recursos"
		}
	},
	"tipoConta": {
		"pesquisa": "Tipos de cuenta",
		"fields": {
			"value": "Valor",
			"label": "Nombre"
		},
		"data": {
			"movement": "Movimiento",
			"subtotal": "Total parcial",
			"reason": "Razón",
			"class": "Clase",
			"cc": "Cuenta corriente"
		}
	},
	"tipoContaDiasSubAlimentacao": {
		"data": {
			"notApplicable": "N / A",
			"yes": "Sí",
			"no": "No"
		}
	},
	"tipoContabilidade": {
		"data": {
			"none": "no tiene",
			"organizadaPOCDL35": "Organizado con POC DL 35",
			"naoOrganizadaPOCDL35": "No organizado con POC DL 35",
			"organizadaSNC": "Organizado con SNC",
			"naoOrganizadaSNC": "No organizado con SNC",
			"organizadaPOCIPSS": "Organizado con POC IPSS",
			"organizadaPOCAL": "Organizado con POC AL",
			"organizadaPOCP": "Organizado con POC P",
			"organizadaPOCMZ": "Organizado con POC MZ",
			"naoOrganizadaPOCMZ": "No organizado con POC MZ",
			"organizadaPGCAN": "Organizado con PGC AN",
			"naoOrganizadaPGCAN": "No organizado con PGC AN",
			"planoGeralContasNIRFMZ": "Plan general cuentas NIRF MZ",
			"organizadaSNCIPSS": "Organizado con SNC IPSS",
			"organizadaSNCPE": "Organizado con SNC PE",
			"organizadaSNCME": "Organizado con SNC ME",
			"organizadaEntSectorNLucrativo": "Organizadas - Entidades del sector sin fines de lucro",
			"naoOrganizadaArt50CodIVA": "No organizado - Artículo 50 del código del IVA",
			"organizadaSNCAP": "Organizado con SNC AP"
		}
	},
	"tipoContrato": {
		"data": {
			"semtipocontrato": "Sin tipo de contrato",
			"contratosemtermo": "Contrato sin plazo (permanente)",
			"contratoatermo": "Contrato a plazo",
			"contratotrabtempoindeterm": "Contrato de trabajo.",
			"contratotrabalhotermocedtemp": "Contrato de trabajo de duración determinada por asignación temporal",
			"contratoprestoserviço": "Contrato de prestación de servicios"
		}
	},
	"tipoContratotrab": {
		"data": {
			"tempoIndeterminado": "Contrato por tiempo indefinido",
			"termoCerto": "Contrato de duración determinada",
			"termoIncerto": "Contrato de duración incierta"
		}
	},
	"tipoDeducao": {
		"pesquisa": "Tipos de deducción",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"notApplicable": "N / A",
			"withDeduction": "con deducción",
			"noDeduction": "Sin deducción",
			"withCountryRefundOrigin": "Con reembolso desde el país de origen."
		}
	},
	"tipoDeducaoTaxa": {
		"pesquisa": "Tipos de deducción fiscal",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"normal": "Normal",
			"intraCommunity": "Intracomunitario",
			"passiveSubjectInversion": "Inversión del sujeto imponible",
			"notApplicable": "N / A"
		}
	},
	"tipoDescontoAgrupado": {
		"data": {
			"nd": "N / A",
			"faltas": "Faltas",
			"penhoras": "Gravámenes",
			"impostos": "Impuestos"
		}
	},
	"tipoDescritivo": {
		"pesquisa": "Tipos de descripción",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"receipt": "Recibo",
			"acceptedLetter": "Carta aceptada",
			"acceptedDiscounted": "Descuento aceptado",
			"acceptedPaymentDiscounted": "Pago aceptado con descuento",
			"acceptedReturnDiscounted": "Devolución aceptada con descuento",
			"acceptedReformDiscounted": "Jubilación aceptada con descuento.",
			"acceptedPaymentNotDiscounted": "Pago aceptado no descontado",
			"acceptedReturnNotDiscounted": "Devolución aceptada no cobrada",
			"acceptedReformNotDiscounted": "Jubilación aceptada no descontada.",
			"preDatedCheck": "cheque posfechado",
			"preDatedCheckDiscount": "Descuento por cheque posfechado",
			"preDatedCheckPaymentDiscounted": "Pago de cheque posfechado cobrado",
			"preDatedCheckReturnDiscounted": "Devolución de cheque posfechado cobrado",
			"preDatedCheckRetirementDiscounted": "Reforma cheque posfechado cobrado",
			"preDatedCheckPaymentNotDiscounted": "Pago de cheque posfechado no cobrado",
			"preDatedCheckReturnNotDiscounted": "Devolución de cheque posfechado no cobrado",
			"preDatedCheckRetirementNotDiscounted": "Reforma de cheques posfechados no cobrados",
			"saqueSemAceite": "Retiro sin aceptación",
			"DescontoSaqueSemAceite": "Retiro de descuento sin aceptación",
			"pagamentoSaqueSemAceiteDiscounted": "Pago retirado sin aceptación descontado",
			"devoluçãoSaqueSemAceiteDiscounted": "Devolución de desistimiento sin aceptación descontada",
			"reformaSaqueSemAceiteDiscounted": "Retiro de jubilación sin aceptación con descuento",
			"pagamentoSaqueSemAceiteNotDiscounted": "Pago retirado sin aceptación no descontado",
			"devoluçãoSaqueSemAceiteNotDiscounted": "Devolución de desistimiento sin aceptación no descontada",
			"reformaSaqueSemAceiteNotDiscounted": "Reforma baja sin aceptación no descontada",
			"doesNotControlVAT": "No controla el IVA",
			"documentManagement": "Gestión de documentos",
			"firstAdoptionNewAccountingReference": "Primera adopción del nuevo marco contable",
			"accountingPolicyChanges": "Cambios en la política contable",
			"differencesTranslationFinancialStatements": "Diferencias de conversión de estados financieros",
			"realizationExcessesRevaluationTangibleIntangibleAssets": "Realización de excedentes de revalorización de activos tangibles e intangibles",
			"excessesRevaluationTangibleIntangibleAssets": "Excedentes de revalorización de activos tangibles e intangibles",
			"deferredTaxAdjustments": "Ajustes de impuestos diferidos",
			"otherChangesShareholdersEquity": "Otros cambios en el patrimonio",
			"netIncome": "lngresos netos",
			"capitalRealizations": "Realizaciones de capital",
			"performanceEmissionPremiums": "Logros de primas de emisión",
			"distributions": "Distribuciones",
			"lossCoverageEntries": "Entradas para cubrir pérdidas.",
			"otherOperations": "Otras operaciones"
		}
	},
	"tipoEntSuporteABDES": {
		"data": {
			"None": "N / A",
			"Empregado": "Empleado",
			"Patronato": "Mecenazgo",
			"EmpregadoPatronato": "Empleado y empleador"
		}
	},
	"tipoEntidadeSuporta": {
		"data": {
			"taesNA": "EN",
			"taesTrabalhador": "Obrero",
			"taesEntidadePatronal": "Empleador",
			"taesSegSocial": "Social lunes",
			"taesSeguro": "Seguro",
			"taesSindicato": "Sindicato",
			"taesOutros": "Otros"
		}
	},
	"tipoExcecaoCRABD": {
		"data": {
			"SemExcecoes": "Sin excepciones",
			"ExcluirEmpregados": "Exclusión",
			"IncluirEmpregados": "Inclusión"
		}
	},
	"tipoInventario": {
		"data": {
			"naotemInvPerm": "Sin inventario perpetuo mensual",
			"temInvPermMensalCG": "Se realiza un inventario permanente mensual en CentralGest",
			"temInvPermMensalManual": "Tiene un inventario permanente mensual realizado manualmente."
		}
	},
	"tipoLiquidacao": {
		"pesquisa": "Tipos de liquidación",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"notApplicable": "N / A",
			"withSettlement": "Con liquidación",
			"noSettlement": "Sin acuerdo"
		}
	},
	"tipoOperacao": {
		"pesquisa": "Tipos de operación",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"notApplicable": "N / A",
			"active": "Activo",
			"passive": "Pasivo",
			"regularizationFavorState": "Regularización a favor del estado",
			"regularizationFavorCompany": "Regularización a favor de la empresa"
		}
	},
	"tipoOrdDocumentos": {
		"data": {
			"dataNContribuinte": "Fecha |",
			"nContribuinteData": "Número de contribuyente |",
			"dataRececao": "fecha de recibo",
			"nomeData": "Nombre |",
			"dataNome": "Fecha |"
		}
	},
	"tipoPasswordRecibo": {
		"data": {
			"nao": "No",
			"igualNIF": "Sí, con contraseña igual al NIF",
			"igualNumeroCC": "Sí, con una contraseña igual a tu número de cédula ciudadana",
			"igualNumeroSS": "Sí, con una contraseña igual a su número de seguro social",
			"igualCodigoFuncionario": "Sí, con una contraseña igual al código de empleado"
		}
	},
	"tipoRecibo": {
		"data": {
			"taSim": "Sí",
			"taNao": "No"
		}
	},
	"tipoValorUnitarioRecolher": {
		"data": {
			"Indiferente": "Por defecto",
			"ValorVariavel": "valor variable",
			"ValorFixo": "Valor fijo",
			"REmuneracaoHora": "pago por hora",
			"REmuneracaoHoraBase": "salario base por hora",
			"VencimentoBase": "Salario base",
			"REmuneracaoMensalTotal": "Remuneración mensual total",
			"RemuneracaoPorDiaUtil": "Remuneración por jornada de trabajo",
			"REmuneracaoPorDiaBase30": "Remuneración por día / 30",
			"REmuneracaoMensalSubsidioBase22": "Salario base mensual / 22",
			"REmuneracaoMensalSubsidioBase30": "Salario base mensual / 30",
			"REmuneracaoHora2": "Hora de pago 2"
		}
	},
	"tipoRegularizacaoCampo40": {
		"pesquisa": "Tipos de regularizaciones del Campo 40",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"notApplicable": "N / A",
			"art78N2": "Art.º 78 nº 2 - Reducción o cancelación del valor de una transacción",
			"art78N3": "Art.º 78 nº 3 - Rectificación del impuesto pagado en exceso",
			"art78N6": "Art.º 78 nº 6 - Corrección de errores materiales adicionales o de cálculo",
			"art78N7Pre2013ItemA": "Art.º 78 nº 7 créditos incobrables antes de 2013 inciso a) en proceso de ejecución",
			"art78N7Pre2013ItemB": "Art.º 78 nº 7 insolvencias anteriores a 2013 apartado b) en procedimientos concursales",
			"art78N7Pre2013ItemC": "Art.º 78 nº 7 créditos incobrables antes de 2013 inciso c) en proceso especial de revitalización",
			"art78N7Pre2013ItemD": "Art.º 78 nº 7 créditos incobrables antes de 2013 apartado d) en el Sistema de Recuperación Extrajudicial de Empresas (SIREVE)",
			"art78N7Post2013ItemA": "Art.º 78 nº 7 créditos incobrables posteriores a 2013 inciso a) en proceso de ejecución",
			"art78N7Post2013ItemB": "Art.º 78 nº 7 insolvencias posteriores a 2013 apartado b) en procedimientos concursales",
			"art78N7Post2013ItemC": "Art.º 78 nº 7 créditos incobrables posteriores a 2013 inciso c) en proceso especial de revitalización",
			"art78N7Post2013ItemD": "Art.º 78 nº 7 créditos incobrables posteriores a 2013 inciso d) en proceso especial de revitalización",
			"art78N8ItemD": "Art.º 78 nº 8 párrafo d) - Créditos, SP, inferiores a 6.000 € reconocidos en procedimiento judicial - ROC",
			"art78N8ItemB": "Art.º 78 nº 8 apartado b) - Los créditos, distintos del SP, superiores a 750 e inferiores a 8.000 € reconocidos en proceso judicial, sin patrimonio - ROC",
			"art78N8ItemC": "Art.º 78 nº 8 inciso c) - Los créditos, distintos del SP, superiores a 750 e inferiores a 8.000 € reconocidos en proceso judicial, con patrimonio - ROC",
			"art78N8ItemE": "Art.º 78 nº 8 párrafo e) - Los créditos, distintos del SP, superiores a 750 e inferiores a 8.000 € reconocidos en procedimiento judicial, en la lista de quiebras - ROC",
			"art78AN4ItemA": "Art. 78 - N° 4 (a) - Créditos en proceso de ejecución, sin bienes",
			"art78AN4ItemB": "Art.º 78 - Inciso 4 b) - Créditos en concurso después de haber sido decretados",
			"art78AN4ItemC": "Art. 78 - N° 4 (c) - Créditos del proceso especial de revitalización, previa aprobación",
			"art78AN4ItemD": "Art.º 78 - N° 4 inciso d) - En el Sistema de Recuperación Extrajudicial de Empresas (SIREVE), previa suscripción del acuerdo",
			"art78AN2ItemA": "Art.º 78 - Apartado 2 (a)",
			"art78BN4": "Art.º 78 - B nº 4 - En deudas de dudoso cobro, con aprobación tácita de la deducción",
			"adjustmentsWhoseCreditDoesNotExceed750VATIncluded": "Regularizaciones cuyo crédito no supere los 750€, IVA incluido",
			"otherRegularizationsNotCoveredArt78RegularizationCoveredArticles23to26": "Otras regularizaciones no previstas en el artículo 78 - Regularizaciones previstas en los artículos 23 a 26",
			"otherRegularizationsNotCoveredByArt78": "Otras regularizaciones no previstas en el artículo 78 - Otras regularizaciones (por ejemplo, fusión)"
		}
	},
	"tipoRegularizacaoCampo41": {
		"pesquisa": "Tipos de regularizaciones del Campo 41",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"notApplicable": "N / A",
			"art78N3": "Art.º 78 nº 3 - Rectificación del impuesto pagado de menos",
			"art78N4": "Art.º 78 nº 4 - Rectificación del impuesto deducido en exceso",
			"art78N6": "Art.º 78 nº 6 - Corrección de errores materiales o de cálculo a menos que",
			"art78N7ItemA": "Arte.",
			"art78N7ItemB": "Artículo 78, apartado 7, apartado b) - Artículo 78, apartado 11, por comunicación de regularización, en procedimientos concursales",
			"art78N7ItemC": "Art.º 78 nº 7 inciso c) - Art.º 78, nº 11, por comunicación de regularización, en proceso especial de revitalización",
			"art78N7ItemD": "Arte.",
			"art78N8ItemD": "Art.º 78 nº 8 apartado d) - Art.º 78, nº 11, por comunicación de regularización, SP, sea inferior a 6.000 € reconocidos en procedimiento judicial",
			"art78N12": "Art.º 78 nº 12 - En recuperación de créditos en los que ya se haya deducido el IVA",
			"art78CN1": "Art.º 78 - C nº1 - Por comunicación de regularización, SP, en deudas de cobro dudoso, con aprobación tácita de la deducción",
			"art78CN3": "Art.º 78 - C nº3 - En recuperación de deudas de dudoso cobro en las que ya se haya deducido el IVA",
			"otherRegularizationsNotCoveredByArt78RegularizationCoveredArticles23to26": "Otras regularizaciones no previstas en el artículo 78 - Regularizaciones previstas en los artículos 23 a 26",
			"otherRegularizationsNotCoveredByArticle78": "Otras regularizaciones no previstas en el artículo 78 - Otras regularizaciones (por ejemplo, fusión)",
			"article6OfDecreeLawN198/90of19June": "Artículo 6 del Decreto-Ley N° 198/90 de 19 de junio",
			"decreeLawNo19/2017OfFebruary14": "Decreto-Ley N° 19/2017, de 14 de febrero"
		}
	},
	"tipoResumoMensal": {
		"pesquisa": "Tipos de resumen mensuales",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"naoUsa": "no usa",
			"aPartirDoUltimoDia": "Desde el ultimo dia",
			"aPartirDoUltimoDiaUtil": "Desde el último día hábil",
			"aPartirDoPrimeiroDiaDoMesSeguinte": "Desde el primer día del mes siguiente.",
			"aPartirDoPrimeiroDiaUtilDoMesSeguinte": "A partir del primer día hábil del mes siguiente"
		}
	},
	"tipoTaxa": {
		"pesquisa": "Tipos de tarifas",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"notApplicable": "N / A",
			"exempt": "Gratis",
			"reduced": "Reducido",
			"intermediate": "Intermedio",
			"normal": "Normal"
		}
	},
	"tipoTaxaIva": {
		"pesquisa": "Tipos de tipos de IVA",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"exempt": "Eximir",
			"reduced": "Reducido",
			"normal": "Normal",
			"intermediate": "Intermedio",
			"doesNotDeduct": "no deduce",
			"diesel50Percent": "Diésel 50%",
			"exemptEU": "Exento de la UE",
			"exemptNotEU": "Exento fuera de la UE"
		}
	},
	"tipoTaxaRetencao": {
		"data": {
			"rendimentos": "Ingreso",
			"subNatal": "Sub.",
			"subFerias": "Sub."
		}
	},
	"tipoTransmissaoActiva": {
		"pesquisa": "Tipos de transmisión activa",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"notApplicable": "N / A",
			"transmissionSubject": "Transmisión de tema",
			"advanceReceiptsYear": "Ingresos previstos para el año.",
			"opLocalizedForeignProviderReverseCharge": "Op. ubicado aquí por suministro.",
			"transmitFreeWithoutDeductible": "Transmisión exenta sin derecho a deducción",
			"civilConstructionServicesReverseCharge": "Servicios de construcción civil con cargo invertido (IVA a cargo del comprador)",
			"transmissionScrapReverseCharge": "Transmisión de chatarra con cargo invertido (IVA a cargo del comprador)",
			"transmissionExemptOrNotSubjectDeduction": "Transferencia exenta o no exenta con derecho a deducción - art. 21, al.",
			"transmissionExemptTourismVehicles": "Transmisión exenta de vehículos turísticos art. 9, n° 32 (que no dedujeron el IVA)",
			"transmissionExemptVAT": "Transferencia exenta de IVA sujeta al art. 9, n° 30 del IMT.",
			"priorYearReceiptsWithDefinitiveBillingYear": "Cobros anticipados del año anterior con facturación final en el año",
			"establishmentTransmission": "Transferencia de establecimiento art.",
			"otherActiveOperationsOutsideCIVAField": "Otras operaciones activas fuera del ámbito CIVA",
			"intraCommunityTransmission": "Transmisión intracomunitaria - Art.º 14 RITI",
			"transmissionExport": "Transmisión de exportación",
			"opMNLocalizedProviderEUResidentsReverseCharge": "Op. ubicado MN por suministro.",
			"transmissionGoldReverseCharge": "Transmisión de oro (DL 362/99) con inversión del sujeto pasivo (IVA a cargo del adquirente)",
			"transmissionRealEstateWaiverExemptionReverseCharge": "Transmisión de inmuebles sin exención de inversión del sujeto pasivo (IVA a cargo del comprador)",
			"opFromAllocationOwnUseFreeTransmissionsAllocationSectorExemptActivity": "Opción de asignación para uso propio, transmisiones gratuitas y asignación a un sector de actividad exento",
			"opBankingActivity": "Actividad bancaria op.",
			"insuranceBusinessOpportunity": "Actividad aseguradora op.",
			"opportunityRentalRealEstate": "Opción de actividad de arrendamiento inmobiliario",
			"triangularIntraCommunityTransmission": "Transmisión intracomunitaria triangular - Art. 8 y 15 RITI",
			"intraCommunityService": "Prestación de servicios intracomunitarios - Art. 6 CIVA",
			"transmissionNewMeansTransportEUIndividuals": "Cesión de nuevos medios de transporte a particulares de la UE",
			"greenhouseGasEmissionReverseCharge": "Emisión de gases de efecto invernadero con carga inversa",
			"operacoesEfetuadasNoEstrangeiro": "Operaciones realizadas en el extranjero",
			"outrasOperacoesIsentasOuSemLiquidacaoDeIVAQueConferemDireitoADeducao": "Otras operaciones exentas o sin liquidación del IVA que dan derecho a deducción"
		}
	},
	"tipoTransmissaoPassiva": {
		"pesquisa": "Tipos de transmisión pasiva",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"notApplicable": "N / A",
			"acquisitionTotalTaxDeductionOthers": "Adquisición con deducción total del IVA - Otros",
			"acquisitionPartialDeductionVATOthers": "Adquisición con deducción parcial del IVA - Otros",
			"acquisitionPartialDeductionVAT": "Adquisición con deducción parcial del IVA - (artículo 21 del CIVA - Gasóleo)",
			"acquisitionPartiallyTotallyDeductibleDeductionTourismVehicles": "Adquisición con franquicia parcial o total - Vehículos turísticos",
			"acquisitionGasolineFullyNonDeductibleTax": "Compra de gasolina con IVA completamente no deducible",
			"acquisitionTourismTravelTotallyNonDeductibleTaxAcquisition": "Adquisición de vía.",
			"acquisitionTourismTravelFullyNonDeductibleTaxRentOtherExpenses": "Adquisición de vía.",
			"acquisitionGoodsServicesTotallyNonDeductibleTaxOther": "Adquisición de bienes y servicios con IVA completamente no deducible - Otros",
			"acquisitionTaxExemptGoodsServices": "Adquisición de bienes y servicios exentos del IVA",
			"acquisitionGoodsServicesCoveredSchemeRetailingPetitioners": "Adquisición de bienes y servicios acogidos a la exención y pequeño régimen.",
			"acquisitionExemptionVATSubmissionIMT": "Adquisición exenta de IVA sujeta al art. 9, núm. 30 del IMT.",
			"acquisitionEstablishment": "Adquisición del establecimiento art. 3, núm.",
			"otherCIVAOutFieldLiabilities": "Otras operaciones pasivas fuera del ámbito CIVA",
			"acquisitionCivilConstructionServices": "Adquisición de servicios de construcción civil (IVA a cargo del comprador)",
			"acquisitionScrap": "Adquisición de chatarra (IVA a cargo del comprador)",
			"intraCommunityAcquisitionExemptOrigin": "Adquisición intracomunitaria - Exenta en origen",
			"acquisitionCEESubjectOrigin": "Adquisición en la CEE - Sujeto en origen",
			"importAcquisition": "Adquisición de importación",
			"mNAcquisitionForeignSupplierReverseCharge": "Adquisición de MN por oferta.",
			"mNAcquisitionEUResidentsReverseCharge": "Adquisición de MN por oferta.",
			"goldAcquisitionReverseCharge": "Adquisición de oro (DL 362/99) con inversión automática (IvaDevidoPeloAcquirante)",
			"acquisitionPropertiesWaiverExemptionReverseCharge": "Adquisición de inmuebles sin exención de inversión del sujeto pasivo (IVA a cargo del comprador)",
			"emissionGreenhouseGases": "Emisión de gases de efecto invernadero",
			"aquisicaoDeCorticaEOutrosProdutosDeOrigemSilvicola": "Adquisición de corcho y otros productos de origen forestal"
		}
	},
	"tipoUnidadesValue": {
		"data": {
			"valor": "Valor",
			"hora": "Tiempo",
			"minuto": "Minuto",
			"segundo": "Segundo",
			"dia": "Día",
			"mes": "Mes",
			"ano": "Año",
			"horautil": "Horas de trabajo",
			"horabase30": "tiempo base 30",
			"diautil": "día hábil",
			"diabase30": "Día base 30"
		}
	},
	"tipoNif": {
		"pesquisa": "Tipos de validación NIF",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"validoseminformacaodeActividade": "Válido sin información de actividad",
			"nifInvalidoInexistente": "NIF no válido inexistente",
			"validoSemActividade": "Válido sin actividad",
			"validoComActividade": "Válido con actividad",
			"validoComActividadeCessada": "Válido con actividad cesada"
		}
	},
	"tipoValorPenhora": {
		"emfraccao": "en fracción",
		"empercentagem": "En porcentaje",
		"valorabsoluto": "valor absoluto",
		"porinsolvencia": "Por insolvencia"
	},
	"tipoValorizacao": {
		"data": {
			"custoMedioPonderado": "Costo promedio ponderado",
			"precoUltimaCompra": "último precio de compra",
			"precoBaseCusto": "precio base de costo",
			"custoMedio": "Costo promedio",
			"custoPadrao": "Costo estándar"
		}
	},
	"tipofacpublicamodelo": {
		"data": {
			"nd": "No aplica",
			"cius": "CIUS-PT",
			"ciusip": "Infraestructuras en Portugal",
			"ciussaphety": "Seguridad CIUS-PT",
			"ciusfeap": "FE-AP eSPAP"
		}
	},
	"tipohorario": {
		"title_detail": "Tipo de hora {{id}}",
		"title_new": "Nuevo tipo de horario",
		"title_plural": "Tipos de horarios",
		"pesquisa": "buscar",
		"saved": "Tipo de horario {{id}}, guardado exitosamente",
		"error": "Tipo de programación {{id}}, no se puede guardar.",
		"deleted": "Tipo de hora {{id}}, eliminado correctamente",
		"fields": {
			"nTipoHorario": "tipo de horario",
			"designaBreve": "Breve designación",
			"designaCompl": "Designación completa"
		}
	},
	"tiposArredondamentoABDES": {
		"data": {
			"NaoArredonda": "No redondea",
			"UnidMaisProxima": "A la unidad, lo más cercano",
			"DezenaMaisProxima": "A la decena más cercana",
			"Baixo1": "Bajo 1",
			"Cima1": "Arriba 1",
			"Baixo10": "Bajo 10",
			"Cima10": "Hasta 10",
			"ParaDecimaMaisProxima": "Al décimo, más cercano",
			"ParaCentesimaMaisProxima": "A la centésima más cercana",
			"ParaMilesimaMaisProxima": "A la milésima, más cercana",
			"ParaDezenaMaisInferior": "A diez, bajar",
			"ParaDezenaMaisSuperior": "Por diez, más superior",
			"ParaDecimaMaisInferior": "Al décimo, el más bajo.",
			"ParaDecimaMaisSuperior": "Al décimo, el más superior",
			"ParaCentesimaMaisInferior": "A la centésima, más baja",
			"ParaCentesimaMaisSuperior": "A la centésima, la más superior",
			"ParaMilesimaMaisInferior": "A la milésima, la más baja",
			"ParaMilesimaMaisSuperior": "A la milésima, más superior",
			"ParaCentenaMaisProxima": "A cien, más cerca"
		}
	},
	"tiposCategoriaDesc": {
		"data": {
			"segGrupo": "Ningún grupo",
			"irs": "IRS",
			"segSocial": "Seguridad social",
			"cga": "CGA",
			"impostoSelo": "Impuesto de timbre",
			"sindicato": "Unión",
			"seguro": "Seguro",
			"falta": "Falta",
			"ferias": "Vacaciones",
			"punicao": "Castigo",
			"retImpTerceiros": "Retenciones impuestas por terceros",
			"subsidioRefeicao": "Subsidio de comidas",
			"outra": "Otro",
			"sobretaxaExtra": "Recargo Extraordinario",
			"adse": "ADSE",
			"penhora": "Empeñar",
			"fct": "FCT - Caja de Compensación del Trabajo",
			"fgct": "FGCT - Fondo de Garantía de Compensación Laboral",
			"penhoraPensaoAlimentos": "Embargo de pensión alimenticia",
			"indemnizacaoFaltaAvisoPrevio": "La compensación carece de aviso previo"
		}
	},
	"tipoComunicacaoAt": {
		"data": {
			"manual": "por archivo",
			"emTempoReal": "por servicio web"
		}
	},
	"tipoCredito": {
		"data": {
			"credito1": "Crédito 1",
			"credito2": "Crédito 2",
			"credito3": "Crédito 3",
			"credito4": "Crédito 4",
			"credito5": "Crédito 5"
		}
	},
	"tiposDescontoProporcionalABDES": {
		"data": {
			"naoProcessa": "No procesa",
			"porQuantidade": "Por cantidad",
			"porNHorasDia": "Por número de horas por día"
		}
	},
	"tiposEmpresa": {
		"data": {
			"nomeIndividual": "empresa individual",
			"sociedadeQuotas": "sociedad de responsabilidad limitada",
			"sociedadeAnonima": "sociedad anónima",
			"sociedadeCooperativa": "Sociedad cooperativa",
			"associacao": "Asociación",
			"servicosMunicipalizados": "Servicios municipalizados",
			"sociedadeUnipessoal": "Empresa unipersonal",
			"agrupComplementarEmrpesas": "Grupo complementario de empresas",
			"camaraMunicipal": "Ayuntamiento",
			"empresaMunicipal": "corporación municipal",
			"institutoPublico": "instituto publico",
			"federacaoDesportiva": "federación deportiva",
			"sociedadeAnonimaDesportiva": "Sociedad Anónima Deportiva",
			"escolaSuperior": "escuela superior",
			"estabelecimentoEstavelSocEstrangeira": "Establecimiento social estable."
		}
	},
	"tipoFormacao": {
		"data": {
			"tfNA": "N / A",
			"tfSim": "Sí",
			"tfnao": "No"
		}
	},
	"tiposIVA": {
		"data": {
			"0": "Eximir",
			"1": "Reducido",
			"2": "Normal",
			"3": "Intermedio",
			"4": "no deduce",
			"5": "Diésel 50%",
			"6": "Exento CEE (Comisión Económica Europea)",
			"7": "Exento fuera de la CEE (Comisión Económica Europea)",
			"isenta": "Eximir",
			"reduzida": "Reducido",
			"normal": "Normal",
			"intermedia": "Intermedio",
			"naoDeduz": "no deduce",
			"gasoleo50": "Diésel 50%",
			"isentoCEE": "Exento CEE (Comisión Económica Europea)",
			"isentoForaCEE": "Exento fuera de la CEE (Comisión Económica Europea)"
		}
	},
	"tiposMovimento": {
		"pesquisa": "Tipos de movimiento",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"notDefined": "N / A",
			"default": "Por defecto",
			"squareMeters": "Metros cuadrados",
			"cubicMeters": "Metros cúbicos",
			"linearMeters": "Metros lineales",
			"grams": "gramos",
			"kilos": "kilos",
			"tons": "Montones",
			"liters": "Litros",
			"hectoliters": "Hectolitros",
			"perimeter": "Perímetro",
			"packing": "Embalaje",
			"hours": "Horas"
		}
	},
	"tipoValidacaoNif": {
		"pesquisa": "Buscar tipos de situaciones NIF",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"notApplicable": "N / A",
			"requiresValidNIF": "Requiere NIF válido",
			"requiresValidNIFAllowsInexistent": "Requiere NIF válido pero permite inexistente",
			"doesNotRequireNIF": "No requiere NIF"
		}
	},
	"tipoPeriocidade": {
		"data": {
			"Sempre": "Siempre",
			"Mensal": "Mensual",
			"Anual": "Anual",
			"Quinzenal": "Quincenal",
			"Semanal": "Semanalmente"
		}
	},
	"tiposPresenca": {
		"data": {
			"tapNA": "N / A",
			"tapNao": "No",
			"tapSim": "Sí"
		}
	},
	"tiposRefeicoes": {
		"pesquisa": "Tipos de cuenta",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"notDefined": "N / A",
			"launch": "Almuerzo",
			"lightLaunch": "Almuerzo ligero",
			"dinner": "para almorzar",
			"lightDinner": "cena ligera"
		}
	},
	"tipoRemunerado": {
		"data": {
			"notApplicable": "N / A",
			"yes": "Sí",
			"no": "No"
		}
	},
	"tiposRetServNIF": {
		"data": {
			"adquirenteDeServicos": "Comprador de servicios",
			"prestadoraDeServicos": "Proveedor de servicios"
		}
	},
	"tipoTrabalho": {
		"data": {
			"tatNA": "N / A",
			"tatTrabalhoNormal": "trabajo normal",
			"tatTrabEXTRANormal": "Trabajo de horas extras normales",
			"tatTrabEXTRACompPeriodoNormaTrabalho": "Horas extras compensadas por horas de trabajo normales.",
			"tatTrabEXTRACompPeriodoFerias": "Trabajo de horas extras compensadas con tiempo de vacaciones.",
			"tatTrabDiasDescansoComplementar": "Trabajar en días de descanso complementario','Trabajar en días de descanso semanal",
			"tatTrabDiasDescansoSemanal": "Trabajar en los días de descanso semanal.",
			"tatTrabDiasFeriado": "Trabajar en días festivos"
		}
	},
	"tiposanexo": {
		"continente": "Continente",
		"madeira": "Madera",
		"acores": "Azores",
		"decRecapitulativa": "Declaración recapitulativa",
		"campo40": "Campo 40",
		"campo41": "Campo 41"
	},
	"tiposartigo": {
		"title_detail": "Tipo de artículo {{id}}",
		"title_new": "Nuevo tipo de artículo",
		"title_plural": "Tipos de artículos",
		"pesquisa": "buscar",
		"saved": "Tipo de artículo {{id}}, guardado exitosamente.",
		"error": "Tipo de artículo {{id}}, no se puede guardar.",
		"deleted": "Tipo de artículo {{id}} eliminado correctamente",
		"fields": {
			"codTpArt": "Código.",
			"CodPlaceholder": "Código de tipo de artículo",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre del tipo de artículo",
			"productCategory": "Tipo de producto (Inventario)",
			"productCategoryPlaceholder": "Tipo de producto en inventario"
		}
	},
	"tiposcontrato": {
		"title_detail": "Tipo de contrato {{id}}",
		"title_new": "Nuevo tipo de contrato",
		"title_plural": "Tipos de contrato",
		"pesquisa": "buscar",
		"saved": "Tipo de contrato {{id}}, guardado exitosamente.",
		"error": "Tipo de contrato {{id}}, no se puede guardar.",
		"deleted": "Tipo de contrato {{id}}, eliminado correctamente.",
		"fields": {
			"nTipoContrato": "Código.",
			"designacaoBreve": "Breve designación",
			"designacaoCompleta": "Designación completa",
			"codTipCtQPessoal": "Tipo de Contrato Q. Personal",
			"naoEnviaparaDMR": "No envía a DMR"
		}
	},
	"tiposregistocadastro": {
		"title_detail": "Tipo de registro {{id}}",
		"title_new": "Nuevo tipo de registro",
		"title_plural": "Tipos de registro",
		"pesquisa": "buscar",
		"deleted": "Tipo de registro eliminado exitosamente.",
		"saved": "Tipo de registro {{id}}, guardado exitosamente.",
		"error": "Tipo de registro {{id}}, no se puede guardar.",
		"fields": {
			"codQPMOT": "Código de motivo Q. Personal",
			"designaBreve": "Breve descripción",
			"designaCompl": "Descripción completa",
			"infobalancosoc": "Información para el informe social:",
			"nMotivoMov": "Tipos de registro"
		},
		"nodes": {
			"altRegimeContr": "Cambio de régimen de contratación",
			"antDaCessacao": "Anticipación del cese",
			"aumVencimento": "aumento de salario",
			"cessacao": "Cesación",
			"comJustaCausa": "con justa causa",
			"contComTermo": "Contrato a plazo",
			"contComTermoCerto": "Contrato a plazo fijo",
			"contComTermoIncerto": "Contrato con plazo incierto",
			"contSemTermo": "Contrato indefinido (efectivo)",
			"despColetivo": "Despido colectivo",
			"despedimento": "Despido",
			"entradas": "Entradas",
			"falecimento": "Muerte",
			"impedProlong": "Fuera de juego prolongado",
			"iniEmpresa": "Iniciativa de la empresa",
			"inicTrabalhador": "Iniciativa de los trabajadores",
			"movPessoas": "movimiento de personas",
			"mutuoAcordo": "Acuerdo mutuo",
			"passouASemTermo": "Continuó para siempre",
			"porAntigui": "Por antigüedad",
			"porMerito": "Por mérito",
			"porOutroMotiv": "Por otra razón",
			"preReforma": "Pre-reforma",
			"promo": "Promociones",
			"reconvReclass": "Reconversiones/Reclasificación",
			"reformaAntec": "Jubilación anticipada",
			"reformaPorInval": "Jubilación por invalidez",
			"reformaPorVelh": "jubilación por vejez",
			"saidas": "Salidas",
			"semJustaCausa": "sin justa causa",
			"termIncerto": "Término incierto",
			"termoCerto": "término correcto"
		}
	},
	"tipotempo": {
		"pesquisa": "tipos de tiempo",
		"fields": {
			"value": "Valor",
			"name": "Nombre"
		},
		"data": {
			"completo": "tiempo completo",
			"parcial": "Tiempo parcial"
		}
	},
	"tirs": {
		"title_detail": "Impuesto del IRS {{id}}",
		"title_new": "Nueva tasa del IRS",
		"title_plural": "Tarifas del IRS",
		"pesquisa": "buscar",
		"saved": "Tasa del IRS {{id}}, guardada correctamente.",
		"error": "Tasa del IRS {{id}}, no se puede guardar.",
		"deleted": "Impuesto del IRS {{id}}, eliminado correctamente.",
		"fields": {
			"nTabIrs": "código de tabla del IRS",
			"designaBreve": "Breve designación",
			"designaCompl": "Designación completa",
			"dtulTabela": "fecha de entrada",
			"datasTaxasIrs": "fechas de impuestos del IRS",
			"anoVigor": "Año de vigencia"
		},
		"table": {
			"title": "Niveles - IRS",
			"ateRemunMes": "Hasta el salario.",
			"maiorigual5": ">=5",
			"vabater5": "Valor.",
			"vabater4": "Valor.",
			"vabater3": "Valor.",
			"vabater2": "Valor.",
			"vabater1": "Valor.",
			"vabater0": "Valor.",
			"taxaMarginalMaxima": "Tasa marginal máxima",
			"parcelaAbaterValor": "Valor de descuento a plazos",
			"parcelaAbaterFormula": "Fórmula de sacrificio de paquetes",
			"parcelaAbaterDepende": "El sacrificio de porciones depende",
			"txEfetivaMensalEscal": "Escala de tarifas mensuales efectivas",
			"parcelaAbaterFormulaInfo": "Información sobre la fórmula de sacrificio de paquetes"
		},
		"btn": {
			"novoEscalao": "Nuevo nivel",
			"apagarEscalao": "Eliminar nivel"
		},
		"messages": {
			"naoPodeRemoverTabelasSistema": "No puede eliminar tablas del IRS del sistema.",
			"naoPodeAtualizarTabelasSistema": "No puede cambiar las tablas del IRS del sistema."
		}
	},
	"tokens": {
		"title_detail": "Ficha {{id}}",
		"title_plural": "Fichas",
		"pesquisa": "buscar",
		"fields": {
			"token": "simbólico",
			"validUntil": "Válido hasta"
		}
	},
	"trDocsCabsWizard": {
		"captions": {
			"transformacaoDocumentos": "Transformación de documentos",
			"documento": "Documento",
			"dataDoc": "Fecha del documento",
			"nConta": "Nro. de cuenta",
			"conta": "Cuenta",
			"nif": "NIF",
			"liquido": "Líquido",
			"iva": "TINA",
			"totalDocumento": "documento total",
			"nCCusto": "No. C. Costo",
			"nRefProcesso": "Proceso",
			"nFactFornec": "Documento externo no.",
			"onlyPossibleToTransform": "Muestra sólo posible transformar",
			"regraTransformacao": "Regla de transformación",
			"agruparPorCliente": "Agrupar por {{nombre}}",
			"naoAgrupar": "no agrupar",
			"dataDocExterno": "Fecha del doctor.",
			"texto": "Texto",
			"processar": "Proceso",
			"avancar": "Próximo",
			"encerrarDocumentos": "Finalizar documentos",
			"docsGerados": "Documentos generados",
			"erros": "Errores",
			"transformacaoDocumentosErros": "Transformación de documentos - Errores",
			"existemErrosATranformDocs": "Hay {{count}} errores en la transformación del documento.",
			"verErros": "Ver errores",
			"tipoDocumento": "Tipo de documento",
			"ateDataDoc": "Documento actualizado",
			"cliente": "Cliente",
			"fornecedor": "Proveedor",
			"homePage": "pagina de inicio"
		},
		"steps": {
			"pesquisa": "Buscar"
		},
		"tooltips": {
			"documentoTransformado": "Documento transformado",
			"editarDocumento": "Editar documento",
			"finalizarDocumento": "Finalizar documento",
			"existemErrosATranformDocs": "Haga clic aquí para ver errores en la transformación de documentos.",
			"criarLoteParaArtigo": "Crear nuevo lote para el artículo"
		}
	},
	"transferenciasarmazens": {
		"title_detail": "Nuevo traslado de artículos entre almacenes",
		"title_list": "Transferencias de artículos entre almacenes.",
		"fields": {
			"origemNArtigo": "Código del artículo",
			"origemNArmazem": "Almacén de origen",
			"origemQtd": "Cantidad",
			"destinoNArmazem": "Almacén de destino",
			"destinoQtd": "Cantidad de destino"
		},
		"modal": {
			"newlinetitle": "Transferencias de artículos",
			"titleorigem": "Documento fuente (Salida)",
			"titledestino": "Documento de destino (entrada)",
			"fields": {
				"data": "Fecha del documento",
				"tipodoc": "Tipo de documento.",
				"nnumer": "No. número.",
				"ndoc": "Doc no.",
				"clientfor": "Cliente / Para.",
				"armazemorigem": "Almacén de origen",
				"armazemdestino": "Almacén de destino",
				"armazem": "Almacenamiento",
				"codartigo": "Código del artículo",
				"qtd": "Cantidad",
				"stock": "Existencias"
			}
		},
		"messages": {
			"transfarmsuccess": "Transferencias de artículos entre almacenes procesadas con éxito.",
			"origemartigonulo": "El artículo fuente no puede ser nulo.",
			"destinoartigonulo": "El artículo de destino no puede ser nulo.",
			"origemarmazemnulo": "El almacén de origen no puede ser nulo.",
			"destinoarmazemnulo": "El Almacén de destino no puede ser nulo.",
			"qtdnula": "La cantidad no puede ser cero.",
			"qtdsup": "El valor de la cantidad ingresada no puede ser mayor que el valor del stock disponible.",
			"qtdtotalsup": "El valor de la cantidad ingresada más la cantidad de líneas ya agregadas del artículo {{article}} es mayor que el valor del stock disponible.",
			"configGuardadasSucesso": "La configuración de transferencia de almacén se ha guardado correctamente."
		},
		"tooltip": {
			"addNewLine": "Nueva línea de transferencia"
		},
		"detail": {
			"table": {
				"fields": {
					"artigo": "Artículo",
					"qtd": "Cantidad",
					"armazemorigem": "Almacén de origen",
					"armazemdestino": "Almacén de destino"
				}
			},
			"cab": {
				"documentos": "Documentos"
			}
		},
		"list": {
			"fields": {
				"faccbIdOrigem": "IDENTIFICACIÓN",
				"nDocfaOrigem": "Tipo de documento.",
				"nDocOrigem": "Doc no.",
				"nNumerOrigem": "Serie de origen no.",
				"nDocfaDestino": "Tipo de documento.",
				"nDocDestino": "Doc no.",
				"nNumerDestino": "Serie de destino no.",
				"numeroOrigem": "Documento fuente",
				"numeroDestino": "Documento de destino",
				"dataDoc": "Fecha del doctor."
			}
		}
	},
	"trdoc": {
		"title": "Transformación de documentos",
		"saveTitle": "Proceso",
		"fields": {
			"nDocfaOrigem": "Tipo de documento.",
			"nDocfaDestino": "Tipo de documento.",
			"nNumerDestino": "serie del destino",
			"dataDoc": "Fecha.",
			"dataDocOrigem": "Fecha del doctor.",
			"dataVenc": "Fecha de vencimiento",
			"dataVencCondVenda": "Fecha de caducidad por condición comercial",
			"nFactFornec": "Doc no.",
			"nFactFornecOrigem": "Doc no.",
			"dataDocExterno": "Fecha del doctor.",
			"dataDocExternoOrigem": "Fecha del doctor.",
			"nRefProcesso": "Proceso",
			"nRefProcessoOrigem": "El proceso es el de origen.",
			"nArmazem": "Almacenamiento",
			"nArmazemOrigem": "El almacén es el origen.",
			"encerraDocumentoOrigem": "Cerrar documento fuente",
			"encerraDocumentoDestino": "Cerrar documento de destino"
		},
		"actions": {
			"cancel": "Cancelar"
		},
		"modal": {
			"linhas": {
				"title": "Transformación de líneas de documentos.",
				"steps": {
					"linhasDoc": "Pauta",
					"lotes": "Lotes",
					"ident": "Identificación",
					"resumo": "Resumen",
					"carga": "Carga",
					"descarga": "Descargar"
				},
				"info": {
					"transformado": "El documento ya ha sido transformado.",
					"linhasTransformadas": "El documento ya tiene líneas transformadas."
				},
				"label": {
					"continuarTransformacao": "Pretendo continuar con la transformación",
					"naoContinuarTransformacao": "no tengo intención de continuar",
					"aposTransformarEncerrarDoc": "Después de la transformación, cierre los documentos.",
					"origem": "Origen",
					"destino": "Destino"
				},
				"btn": {
					"editDoc": "Editar documento",
					"preencherAuto": "Lotes de autocompletar"
				},
				"messages": {
					"proceSuccess": "El procesamiento se completó con éxito.",
					"atribuirLotes": "Debe asignar lotes a líneas con artículos con lotes para poder simular.",
					"naoTemLinhasSelecionadas": "No hay líneas para procesar, seleccione las líneas para transformar del documento.",
					"qtdLotesDifQtdLinha": "La cantidad ({{qty}}) del artículo {{nArtigo}} distribuida entre los lotes no es la misma que la de la línea {{nSeq}} ({{qty1}}).",
					"naoTransformou": "No se generaron documentos.",
					"proceFalhou": "Error al procesar la transformación."
				}
			}
		},
		"legend": {
			"linhaTransformada": "Línea ya transformada"
		}
	},
	"trdocconfig": {
		"title": "Transformación de documentos",
		"saveTitle": "Proceso",
		"fields": {
			"nDocfaOrigem": "Tipo de documento.",
			"nDocfaDestino": "Tipo de documento.",
			"nNumerDestino": "serie del destino",
			"dataDoc": "Fecha.",
			"dataDocOrigem": "Fecha del doctor.",
			"dataVenc": "Fecha de vencimiento",
			"dataVencCondVenda": "Fecha de caducidad por condición comercial",
			"nFactFornec": "Doc no.",
			"nFactFornecOrigem": "Doc no.",
			"dataDocExterno": "Fecha del doctor.",
			"dataDocExternoOrigem": "Fecha del doctor.",
			"nRefProcesso": "Proceso",
			"nRefProcessoOrigem": "El proceso es el de origen.",
			"nArmazem": "Almacenamiento",
			"nArmazemOrigem": "El almacén es el origen.",
			"encerraDocumentoOrigem": "Cerrar documento fuente",
			"encerraDocumentoDestino": "Cerrar documento de destino",
			"referenciaTextoBase": "Texto de referencia"
		},
		"actions": {
			"cancel": "Cancelar",
			"variables": "variables",
			"variaveis": {
				"nomeDoc": "@DocName: nombre del documento",
				"nDoc": "@NDoc - Número de documento",
				"dtDoc": "@DtDoc - Fecha del documento",
				"docExt": "@DocExt - Documento externo",
				"processo": "@Processo - Proceso",
				"contrato": "@Contrato - Contrato",
				"obs1": "@OBS1 - Observaciones"
			}
		},
		"messages": {
			"success": "Documento transformado exitosamente.",
			"tipoDocNulo": "El tipo de documento no puede ser nulo."
		},
		"tooltips": {
			"nDocExterno": "Referencia externa impresa en el documento.",
			"dataDocExterno": "Fecha externa impresa en el documento.",
			"aposTransformarEncerrarDocsFinalizar": "Estas acciones son irreversibles."
		}
	},
	"trfLancContas": {
		"title": "Transferencia de cuenta",
		"success": "Transferencia de cuenta completada exitosamente",
		"fields": {
			"nContaOrigem": "Número de cuenta de origen",
			"nContaDestino": "Número de cuenta de destino",
			"nomeContaDestino": "Nombre de la cuenta de destino",
			"manterContaOrigem": "Mantener la cuenta de origen",
			"dependencias": "Dependencias"
		},
		"fieldsTableDependencias": {
			"nConta": "Número de cuenta de origen",
			"nome": "Nombre de origen",
			"nContaGeralReal": "Número de cuenta general de origen",
			"valorMascara": "Valor de máscara",
			"radicalMascara": "Mascarilla"
		},
		"stepIntroCaption": "Introducción",
		"stepContasCaption": "Transferir",
		"stepIntroMessage": "Este programa le permite convertir una cuenta de origen en otra cuenta de destino.",
		"stepFinalizeCaption": "Conclusión",
		"stepFinalizeMessage": "Conversión completada.",
		"messages": {
			"contadestjaexiste": "Atención: la cuenta de destino ya existe en el plan de cuentas actual."
		}
	},
	"trfba": {
		"pagreg": "Pagos registrados",
		"documentos": "Documentos",
		"tree": {
			"docporenviar": "Documentos a enviar",
			"docenviados": "Documentos enviados",
			"operacao": {
				"op0": "No definido",
				"op1": "Pagos a proveedores",
				"op2": "ordenado",
				"op3": "Transferir",
				"op4": "ingresos de casa",
				"op5": "Tarjeta/ticket de comida"
			}
		},
		"table": {
			"semdados": "Sin datos",
			"sel": "Sel.",
			"nContaCaixa": "Número de cuenta D/O",
			"nContaForn": "Cuenta de abastecimiento no.",
			"valor": "Valor",
			"nomeBeneficiario": "Nombre del beneficiario",
			"nDocInternoLanc": "Doc. Interno.",
			"dataLanc": "Fecha de lanzamiento",
			"nDocPago": "Médico pagado",
			"codBancoEMI": "Código emi bancario.",
			"codagenciaEMI": "Código de agencia EMI.",
			"ibanContaEMI": "IBAN emitido.",
			"swiftEmi": "Emisión SWIFT.",
			"codBancoDest": "Código del banco de destino.",
			"codAgenciaDest": "Código de agencia de destino.",
			"ibanContaDest": "Destino IBAN",
			"swiftDest": "Destino SWIFT.",
			"nomeFicheiro": "Nombre del archivo",
			"trfBaID": "Trf.",
			"chaveToPS2": "Clave de PS2",
			"operacao": "Operación",
			"codEmpregado": "Empleado",
			"nomeFuncionario": "Nombre del empleado",
			"formatoTransferencia": "Formato de transferencia",
			"efectuada": "Realizado"
		},
		"radio": {
			"ps2xml": "PS2/XML",
			"cgd": "CGD",
			"rgFormato": "Formato del archivo a generar",
			"igcp": "PICG"
		},
		"btn": {
			"geraficheiro": "Generar archivo",
			"alterarestado": "Cambiar estado a no enviado",
			"editarregisto": "Editar registro",
			"eliminartransf": "Eliminar seleccionado",
			"configSEPA": "Configuración SEPA"
		},
		"modal": {
			"config": {
				"steps": {
					"outraidprivadas": "Otra identificación.",
					"template": "Plantillas SEPA"
				},
				"fields": {
					"iban": "IBAN",
					"outraIDPrivada": "Otra identificación.",
					"regTempSal": "Salarios"
				}
			},
			"geraficheiro": {
				"titleData": "fecha de transferencia",
				"titlePrioridade": "Fecha de transferencia y prioridad",
				"titleDGT": "Datos para el fichero de carga de la DGT",
				"fields": {
					"nomeFicheiroUploadDGT": "Nombre del archivo",
					"numeroSequencial": "numero secuencial",
					"dataLanc": "Realizar transferencia en fecha",
					"prioridade": "Prioridad",
					"normal": "Normal",
					"alta": "Alto"
				}
			},
			"erros": {
				"dataTrfbaNaoPodeSerInferiorDataHoje": "La fecha de transferencia no puede ser inferior a la fecha de hoy.",
				"erroGerarFicheiro": "Error al generar el archivo de transferencia bancaria."
			}
		},
		"messages": {
			"temselecionar": "No hay ninguna transferencia bancaria seleccionada.",
			"confaltestado": "¿Quiere cambiar el estado de los registros seleccionados?",
			"deltransf": "¿Quieres eliminar los registros seleccionados?",
			"deltransfsuccess": "Registros eliminados exitosamente",
			"fichformPS2": "Generará un archivo en formato PS2.",
			"fichformSEPAXML": "Generará un archivo en formato SEPA XML.",
			"fichformCGD": "Generará un archivo en formato CGD (.txt).",
			"fichHomeBanking": "El archivo SUBIR para Home Banking se generó exitosamente.",
			"ibanjaconfig": "El IBAN ya está configurado.",
			"linhaaddsuccess": "Agregado exitosamente",
			"linharemsuccess": "IBAN eliminado con éxito",
			"templatealterado": "Plantilla cambiada exitosamente",
			"removeline": "Quitar línea",
			"ibannulo": "El IBAN no puede ser nulo"
		}
	},
	"tributAutonomas": {
		"header": {
			"modeloTributAutonoma": "Impuestos aplicados",
			"prejuizoFiscal": "Pérdida fiscal",
			"contasDe": "Número de cuenta",
			"perioDezembroCaption": "Diciembre - Normal"
		},
		"fields": {
			"nConta": "Nro. de cuenta",
			"contaDescricao": "Cuenta",
			"taxa": "Tasa",
			"taxaAgravada": "% empeoramiento",
			"descricao": "Clasificador",
			"nome": "Nombre",
			"saldoGeral": "Saldo acumulado",
			"valorTributAutonoma": "Fiscalidad autonómica",
			"fromPeriod": "período de"
		},
		"modeloTributAutonoma": {
			"modelo0": "IRC - Continente",
			"modelo1": "IRC - Zona Franca de Madeira",
			"modelo3": "IRC-Azores",
			"modelo2": "IRS"
		},
		"messages": {
			"desejaAtribuirOClassificadorAsCo": "¿Quieres asignar el clasificador a las cuentas marcadas?",
			"configuracoesDaTributacaoAutonom": "Configuración de tributación autónoma copiada a las empresas seleccionadas.",
			"modeloChangedsuccess": "El modelo de impuestos se cambió a \"{{modelTribut}}\" con éxito.",
			"changedModeloTriubt": "¿Quieres empezar a utilizar los tipos impositivos autónomos \"{{modelTributAfter}}\" en lugar de \"{{modelTribut}}\"?",
			"modeloTributAutonomaInfo": "Para cambiar el régimen fiscal hay que acceder al mantenimiento de la tributación autonómica"
		},
		"modals": {
			"empresas": {
				"title": "Copia para otras empresas"
			},
			"print": {
				"title": "Impresión de impuestos autonómicos"
			}
		},
		"btn": {
			"copiaParaOutraEmpresas": "Copia para otras empresas",
			"manutTributAutonoma": "Mantenimiento de la tributación autónoma"
		},
		"actions": {
			"poc": "plan de cuentas"
		}
	},
	"compilerOptions": {
		"module": "comúnjs",
		"target": "es5",
		"sourceMap": "verdadero"
	},
	"exclude": {
		"0": "módulos_nodo"
	},
	"txtar": {
		"title_detail": "Texto libre {{id}}",
		"title_new": "Nuevo texto libre",
		"title_plural": "Textos Gratis",
		"pesquisa": "buscar",
		"saved": "Texto libre {{id}}, guardado exitosamente.",
		"error": "Texto libre {{id}}, no se puede guardar.",
		"deleted": "Texto libre {{id}}, eliminado correctamente",
		"fields": {
			"nArtigo": "Artículo",
			"nArtigoPlaceholder": "Código del artículo",
			"nTexto": "Texto no.",
			"nTextoPlaceholder": "Texto no.",
			"titulo": "Título",
			"tituloPlaceholder": "Título",
			"note": "Texto",
			"notePlaceholder": "Texto"
		}
	},
	"txtcl": {
		"title_detail": "Texto libre {{id}}",
		"title_new": "Nuevo texto libre",
		"title_plural": "Textos Gratis",
		"pesquisa": "buscar",
		"saved": "Texto libre {{id}}, guardado exitosamente.",
		"error": "Texto libre {{id}}, no se puede guardar.",
		"deleted": "Texto libre {{id}}, eliminado correctamente",
		"fields": {
			"nClifo": "Nro. de cuenta",
			"nClifoPlaceholder": "Nro. de cuenta",
			"nTexto": "Texto no.",
			"nTextoPlaceholder": "Texto no.",
			"titulo": "Título",
			"tituloPlaceholder": "Título",
			"note": "Texto",
			"notePlaceholder": "Texto"
		}
	},
	"unidadesMedidaComercioInternacional": {
		"title_detail": "Unidad de medida de comercio internacional {{id}}",
		"title_plural": "Unidades de medida para el comercio internacional.",
		"pesquisa": "buscar",
		"fields": {
			"codigo": "Código",
			"designacao": "Designación",
			"designacaoEN": "En designación",
			"simbolo": "Símbolo",
			"tipoUnidadeCG": "Tipo de unidad"
		}
	},
	"unidadesmovimento": {
		"title_detail": "Unidad de movimiento {{id}}",
		"title_new": "Nueva unidad de movimiento",
		"title_plural": "Unidades de movimiento",
		"pesquisa": "buscar",
		"saved": "Unidad de movimiento {{id}}, guardada correctamente.",
		"error": "Unidad de movimiento {{id}}, no se puede guardar.",
		"deleted": "Unidad de movimiento {{id}}, eliminada con éxito.",
		"fields": {
			"codUni": "Código",
			"codUniPlaceholder": "Código de unidad de movimiento",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre de la unidad de movimiento",
			"nomeAbrev": "nombre abreviado",
			"nomeAbrevPlaceholder": "Nombre corto de la unidad de movimiento.",
			"tipo": "Tipo",
			"tipoPlaceholder": "Tipo de unidad de movimiento",
			"qtdRefUnidad": "Cantidad por unidad",
			"qtdRefUnidadPlaceholder": "Cantidad por unidad",
			"codUnece": "El código une",
			"codUnecePlaceholder": "Código de unidad de medida del comercio internacional"
		}
	},
	"users": {
		"title_detail": "Usuario {{id}} (avanzado)",
		"title_new": "Nuevo usuario (avanzado)",
		"title_plural": "Usuarios (configuración avanzada)",
		"pesquisa": "buscar",
		"saved": "Usuario \"{{id}}\" guardado exitosamente",
		"error": "El usuario \"{{id}}\" no se puede guardar.",
		"deleted": "Usuario eliminado exitosamente",
		"passwordchanged": "¡La contraseña se cambió correctamente!",
		"instrucoes.email": "Las instrucciones para cambiar la contraseña han sido enviadas al correo electrónico del usuario.",
		"instrucoes.emailerro": "No se puede enviar un correo electrónico para crear una contraseña de usuario",
		"acessosPortaisApi": "No hay accesos seleccionados",
		"actions": {
			"changepassword": "Cambiar la contraseña",
			"cancel": "Cancelar",
			"change": "alterar",
			"adicionarEmpresas": "Añadir empresas",
			"adicionarEmpresa": "Añadir empresa",
			"empresas": "Cambiar de empresa",
			"sendEmail": "Enviar correo electrónico de recuperación de contraseña"
		},
		"fields": {
			"userId": "IDENTIFICACIÓN",
			"username": "Autenticación",
			"firstName": "Nombre",
			"lastName": "Apellido",
			"password": "Contraseña",
			"email": "Correo electrónico",
			"active": "Activo",
			"nEmpresa": "empresa no.",
			"nUtilizador": "Número de usuario",
			"admin": "Administrador",
			"apiv1": "Usuario",
			"colaborador": "Colaborador",
			"erp": "Usuario de ERP",
			"gestorRH": "Gerente de Recursos Humanos",
			"gestorServico": "Gerente de servicio",
			"gestorEmentas": "Administrador de menú",
			"regex": "Filtro API",
			"administrator": "Administrador",
			"formUsername": "Autenticación (campo utilizado para que el usuario inicie sesión en la plataforma)",
			"formEmail": "Correo electrónico (campo utilizado para que el usuario inicie sesión en la plataforma)"
		},
		"password": {
			"title": "Cambiar la contraseña",
			"newpassword": "Contraseña",
			"confirmpassword": "Confirmar",
			"errorEqualsPassword": "Las contraseñas no coinciden"
		},
		"delete": {
			"title": "¿Quieres eliminar el usuario {{id}}?"
		},
		"steps": {
			"utilizador": "Usuario",
			"portaisApi": "Acceso a portales/api",
			"empresas": "Acceso a empresas"
		},
		"emailSuccess": "Correo electrónico enviado correctamente"
	},
	"usersbasic": {
		"title_detail": "Usuario {{id}}",
		"title_new": "Nuevo usuario",
		"title_plural": "Usuarios",
		"pesquisa": "buscar",
		"saved": "Usuario \"{{id}}\" guardado exitosamente",
		"error": "El usuario \"{{id}}\" no se puede guardar.",
		"deleted": "Usuario eliminado exitosamente",
		"fields": {
			"instalacao": "Instalación",
			"utilizador": "Usuario de CentralGest"
		},
		"text": {
			"deleteSameUserDisabled": "No puedes eliminar tu propio usuario",
			"createUtilizador": "Crear usuario de CentralGest y asociarlo automáticamente",
			"acessos": "Accesos",
			"selectInstallation": "Seleccione una instalación arriba para configurar el acceso a las empresas",
			"emptyCompanies": "No hay empresas disponibles para esta instalación.",
			"allRoles": "Todos los portales",
			"filterCompanies": "Buscar empresas",
			"nEmpresa": "empresa no.",
			"nomeEmpresa": "Nombre de empresa",
			"configuracaoEmpresa": "Configuración de la empresa",
			"configuracaoGeral": "Configuración general",
			"acessosEmpresa": "Acceso empresa",
			"allPolicies": "Todas las empresas",
			"config": "Ajustes",
			"loadingEmpresas": "Cargando empresas instaladoras...",
			"blockedAcessosEmpresa": "Por favor, en la tabla anterior, dé acceso a al menos una empresa para configurar el acceso de las empresas en esta instalación.",
			"loadPolicies": "Para ver y/o editar el acceso de la empresa, haga clic aquí.",
			"loadingPolicies": "Cargando acceso de empresa...",
			"loadPoliciesError": "Se produjeron los siguientes errores al cargar los accesos de la empresa",
			"portalInclude": "Las empresas con acceso a este portal también tendrán acceso al portal",
			"portalIncludes": "Las empresas con acceso a este portal también tendrán acceso a los siguientes portales"
		},
		"errors": {
			"requiredUtilizador": "Es obligatorio seleccionar un Usuario de CentralGest para asociarlo"
		},
		"accesses": {
			"generic": {
				"view": "Vista",
				"add": "para crear",
				"edit": "Editar",
				"delete": "Cancelar"
			},
			"addons": {
				"title": "Complementos"
			},
			"ativos": {
				"title": "Activos"
			},
			"contabilidade": {
				"title": "Contabilidad"
			},
			"comercial": {
				"title": "Gestión Comercial"
			}
		},
		"noRoles": {
			"title": "Confirmar guardar usuario sin acceso",
			"message": "<p>No has seleccionado ningún acceso para el usuario, <b>el usuario no podrá ingresar a la plataforma</b>.</p><p>¿Estás seguro de que quieres continuar?</p>"
		}
	},
	"utlviatpatronal": {
		"fields": {
			"codEmp": "Empleado",
			"codABDESC": "Prestación",
			"anoProcessamento": "Datos",
			"vAquisicaoViatura": "Valor de adquisición",
			"vMercadoViatura": "Valor comercial",
			"rhTipoProce": "Tratamiento"
		},
		"tipoUtlViatPatronal": {
			"todas": "Todo",
			"ativas": "Activo",
			"inativas": "Inactivo"
		},
		"toolbar": {
			"tipoUtlViatPatronal": "Ver uso."
		},
		"modal": {
			"title": "Mantenimiento del uso del vehículo de un empleador.",
			"fields": {
				"tipoProcessamento": "Tipo de procesamiento",
				"codABDESC": "Prestación",
				"codEmp": "código de empleado",
				"anoProcessamento": "Año de procesamiento",
				"mesInicioProce": "Mes de inicio",
				"suspende": "Suspendido",
				"valor": "Valor",
				"vAquisicaoViatura": "Valor de compra del vehículo o valor de referencia en el contrato de alquiler (IVA incluido)",
				"vMercadoViatura": "Valor de mercado del coche",
				"valorIncidenciaSS": "Social lunes",
				"valorIncidenciaIRS": "IRS",
				"valincidencia": "Valores de incidencia",
				"simvalmensal": "Simulación de valores mensuales del importe de los ingresos en especie por utilización de un vehículo de empresa",
				"obs": "Observaciones",
				"obsSupencao": "Observaciones de suspensión",
				"simulacaodesc": "Simulación de valores mensuales del importe de los ingresos en especie por utilización de un vehículo de empresa"
			}
		}
	},
	"datagrid": {
		"column": {
			"required": "Esta columna es obligatoria",
			"dupsNotAllowed": "No se permiten duplicados",
			"digitsOnly": "Sólo se permiten números"
		}
	},
	"vendasanomes": {
		"chart": {
			"title": "Ventas por mes",
			"diference": "Diferencia"
		},
		"table": {
			"valorVariacao": "Valor de variación",
			"percentVariacao": "% cambiar"
		},
		"campos": {
			"checkboxAnoAnterior": "Ver año anterior completo"
		}
	},
	"vendasartigo": {
		"fields": {
			"codigo": "Código",
			"nome": "Nombre",
			"infoString": "NIF",
			"valor": "Valor",
			"percentagem": "%"
		},
		"totalvendas": "Ventas totales",
		"btn": {
			"mes": "Mes"
		},
		"toolbar": {
			"month": "Mes: {{nameMonth}}"
		}
	},
	"vendascliente": {
		"fields": {
			"codigo": "Código",
			"nome": "Nombre",
			"infoString": "NIF",
			"valor": "Valor total",
			"valorIva": "valor del IVA",
			"valorLiquido": "patrimonio neto",
			"percentagem": "%"
		},
		"totalvendas": "Ventas totales",
		"btn": {
			"mes": "Mes"
		},
		"toolbar": {
			"month": "Mes: {{nameMonth}}"
		}
	},
	"vendedores": {
		"title_detail": "Vendedor {{id}}",
		"title_new": "Nuevo vendedor",
		"title_plural": "Vendedores",
		"pesquisa": "buscar",
		"saved": "Vendedor {{id}}, guardado exitosamente.",
		"error": "Vendedor {{id}}, no se puede guardar.",
		"fields": {
			"nVendedor": "Código.",
			"nVendedorPlaceholder": "código de vendedor",
			"nome": "Nombre",
			"nomePlaceholder": "nombre del vendedor"
		}
	},
	"verificaEFaturas": {
		"tabDocsEFaturaCaption": "Documentos en e-Fatura no relacionados con contabilidad",
		"tabJaLancadosCaption": "Documentos ya ingresados ​​en contabilidad o ignorados.",
		"viewDocModalTitle": "Vista de documento {{ndoc}}",
		"viewDocModalTitleSimple": "Vista previa del documento",
		"extratosModalTitle": "Estados de cuenta",
		"pesquisaDocPossivelModalTitle": "Búsqueda de documentos contables",
		"expandAllBtn": "Expandir todo",
		"associarTodosPossiveis": "Asociar documentos con 1 documento posible",
		"associarTodosPossiveisPromptTitle": "Atención",
		"associarTodosPossiveisPromptMsg": "Esta operación asociará automáticamente los documentos e-Fatura a un posible documento.<br>Siempre debe comprobar documento por documento si el posible documento asociado corresponde al documento e-Fatura.<br><br>¿Está seguro de que desea ¿continuar?",
		"associarTodosPossiveisNoSourceTitle": "Asociación automática...",
		"associarTodosPossiveisNoSourceMsg": "No tienes documentos o tienes documentos con más de 1 documento posible.",
		"associarDocSemAlterarDocExterno": "Asociar documento sin cambiar documento externo",
		"associarDocComAlteracaoDocExterno": "Asociar documento con cambio de documento externo",
		"verDocumento": "Ver documento",
		"deixarIgnorarDocumento": "Deja de ignorar este documento",
		"deixarIgnorarPorFornecedor": "Deja de ignorar documentos de este proveedor",
		"removeAssociacao": "Eliminar asociación de este documento",
		"filtropesq": "Buscar",
		"messages": {
			"docAssociadoSuccessMsg": "El documento {{ndoc}} ha sido vinculado.",
			"docsAssociadoSuccessMsg": "Todos los documentos con 1 documento posible se asociaron exitosamente.",
			"docIgnoradoSuccessMsg": "El documento {{ndoc}} se ha agregado a la lista de ignorados.",
			"fornecedorIgnoradoSuccessMsg": "El proveedor {{nif}} - {{name}} se ha agregado a la lista de ignorados.",
			"docRemAssociacaoSuccessMsg": "Se ha eliminado la asociación del documento {{ndoc}}",
			"docRemIgnoringSuccessMsg": "El documento {{ndoc}} ha sido eliminado de la lista de ignorados.",
			"docRemIgnoringFornecedorSuccessMsg": "El proveedor {{nif}} ha sido eliminado de la lista de ignorados.",
			"docDigital1": "Con doctor.",
			"docDigital2": "Con doctor."
		},
		"fields": {
			"numPossiveisDocs": "No. de posibles documentos",
			"numPossiveisDocsAbbr": "Posible",
			"numSemelhantesDocs": "Número de documentos similares",
			"numSemelhantesDocsAbbr": "Similar",
			"documentoLancado": "Documento núm.:",
			"nifEmitente": "NIF",
			"nomeEmitente": "Nombre",
			"numerodocumento": "Doc externo No.",
			"dataEmissaoDocumento": "Fecha de emisión",
			"tipoDocumentoDesc": "Tipo",
			"estadoBeneficioDesc": "Situación",
			"actividadeProfDesc": "Alcance de la actividad",
			"valorTotalBaseTributavel": "Base imponible",
			"valorTotalIva": "valor del IVA",
			"valorTotal": "Valor total",
			"baseTributavel": "Base imponible",
			"taxa": "Tasa básica",
			"impostoSelo": "Impuesto de sello",
			"total": "Valor total",
			"nDocInterno": "Documento núm.:",
			"nDocExterno": "Doc externo No.",
			"valor": "Valor",
			"dataDocExterno": "Fecha de documento externo",
			"docDigitalAbbr": "Documento digital"
		},
		"btn": {
			"accao": "Comportamiento",
			"dropdown": {
				"extratosConta": "Estados de cuenta",
				"ignorarSempreTodosDocumentos": "Ignore siempre todos los documentos de este proveedor.",
				"ignorarDocumento": "ignorar este documento"
			}
		},
		"tabJaLancadosCaptionContainer": {
			"table": {
				"badges": {
					"ligacaoDireta": "Enlace directo",
					"naoIgnorar": "No ignores",
					"remover": "Para eliminar"
				}
			},
			"legends": {
				"documentoIgnorado": "Documento ignorado",
				"associadoDocExtDiferente": "Asociado con el doc no.",
				"documentoFornecedorIgnorado": "Documento del proveedor ignorado"
			}
		},
		"tabDocsEFaturaCaptionContainer": {
			"mostradocpossiveis": "Mostrar solo no es posible ningún documento",
			"tooltip": {
				"associarAutTodosPossiveis": "Asociar automáticamente todos los documentos con 1 documento posible."
			}
		},
		"formFields": {
			"dataDe": "Fecha:",
			"dataAte": "Fecha hasta:",
			"tipo": "Tipo",
			"fornecedor": "Proveedor",
			"placeholder": {
				"contribuinte": "Número de valor agregado"
			}
		},
		"modal": {
			"config": {
				"title": "Configuración de conciliación de facturas electrónicas",
				"fields": {
					"nCaracteresAValidar": "Número de caracteres a validar"
				}
			}
		}
	},
	"verificacaoacumulados": {
		"title": "Cheque de acumulación",
		"periodo": "Acumulado por periodo con movimiento.",
		"movimentos": "Acumulado con movimiento.",
		"extratos": "Acumulado con extractos",
		"inconsistencias": "Todas las inconsistencias",
		"consistencias": "Coherencia de la cuenta corriente",
		"fields": {
			"deNconta": "cuenta",
			"periodo": "Periodo hasta"
		},
		"btn": {
			"verificar": "para comprobar",
			"corrigir": "Corrección seleccionada"
		},
		"tooltip": {
			"corrigir": "Para corregirlo, debe seleccionar al menos un elemento de la lista."
		},
		"alerts": {
			"reconstrucaoSucesso": "La reconstrucción de la cuenta fue exitosa",
			"reconstrucaoErro": "Se produjo un error al crear la cuenta.",
			"correcaoContasCorrentesSucesso": "Las cuentas seleccionadas han sido reparadas exitosamente.",
			"correcaoContasCorrentesErro": "Se produjo un error al corregir las cuentas seleccionadas."
		},
		"tables": {
			"nConta": "Número de cuenta",
			"nome": "Nombre",
			"saldoAcumulado": "Saldo acumulado",
			"saldoMovAberto": "equilibrio en movimiento",
			"tipo": "Tipo",
			"saldoAcumuladoAte": "Saldo acumulado hasta el periodo",
			"saldoExtratoAte": "Estado de saldo hasta el periodo",
			"saldoExtrato": "Saldo del estado de cuenta",
			"fase": "Fase",
			"conta": "Cuenta",
			"periodo": "Período",
			"diario": "A diario",
			"docInterno": "Doc no.",
			"sel": "Sel.",
			"origem": "Origen"
		},
		"actions": {
			"reconstruir": "Reconstruir"
		}
	},
	"zonas": {
		"title_detail": "Zona {{id}}",
		"title_new": "Nueva Zona",
		"title_plural": "Zonas",
		"pesquisa": "buscar",
		"saved": "Zona {{id}}, guardada exitosamente.",
		"error": "Zona {{id}}, no se puede guardar.",
		"deleted": "Zona {{id}}, eliminada exitosamente",
		"fields": {
			"nZona": "Código.",
			"nZonaPlaceholder": "código de zona",
			"nome": "Nombre",
			"nomePlaceholder": "Nombre de zona"
		}
	}
}