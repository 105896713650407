import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {merge} from 'lodash-es';
import {isArray, isFunction, PlAlertService} from 'pl-comps-angular';
import {Subscription} from 'rxjs';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {EGroupName} from '../../../../../config/constants';
import moment from 'moment';
import {ConfigService} from '../../../../services/config/config.service';
import {ConfigOptionsService} from '../../../../services/config/options/config.options.service';
import {EConfigOptionsInstanceName, IContratosConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {ENTITY_NAME_ARTIGOS} from '../../../artigos/artigos.entity.interface';
import {IJsonContrato, IJsonContratoLin, TContratoActionFn, TContratoActionResultFn} from '../../jsonContrato.entity.interface';

@Component({
  selector: 'contratos-detail',
  templateUrl: './contratos.entity.detail.component.html'
})
export class ContratosDetailComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public contrato: IJsonContrato;
  @Input() public actionEditarContrato: TContratoActionFn;
  @Input() public actionReabrirContrato: TContratoActionResultFn;
  @Input() public actionTerminarContrato: TContratoActionResultFn;
  @Input() public actionApagarContrato: TContratoActionFn;
  @Output() public readonly contratoChange: EventEmitter<IJsonContrato>;
  @Output() public readonly evtRefresh: EventEmitter<void>;

  public readonly codMoedaEmpresa: number;
  public readonly abreviaturaMoedaEmpresa: string;
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public dataGridTable: IDevExpressDataGrid<IJsonContrato, IJsonContrato>;
  public mobile: boolean;
  public abreviaturaMoedaCliente: string;
  public totalContrato: number;

  public optionShowGrupoConta: boolean;
  public optionShowMoeda: boolean;
  public optionShowCondComerciais: boolean;
  public optionShowRefExterna: boolean;
  public optionShowVendedor: boolean;
  public optionShowNCCusto: boolean;
  public optionShowD1: boolean;
  public optionShowArmazem: boolean;
  public optionShowUnidadeMovimento: boolean;

  private readonly _defaultContrato: IJsonContrato;
  private readonly _entityMaintenanceInstanceArtigos: IEntityMaintenanceInstance;
  private readonly _subscriptionConfigOptions: Subscription;
  private _dataGridInstance: dxDataGrid<IJsonContrato, IJsonContrato>;

  constructor(
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _configService: ConfigService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _configOptionsService: ConfigOptionsService
  ) {
    this.contratoChange = new EventEmitter<IJsonContrato>();
    this.evtRefresh = new EventEmitter<void>();

    this.configOptionsInstanceName = EConfigOptionsInstanceName.CONTRATOS;
    this.configOptionsGroupName = EGroupName.ERP;
    this.codMoedaEmpresa = this._configService.configurations.empresa.codMoeda;
    this.abreviaturaMoedaEmpresa = this._configService.configurations.empresa.abreviaturaMoeda;

    this.dataGridTable = {
      columns: [
        {dataField: 'nArtigo', caption: 'contratos.fields.linhas.nArtigo', dataType: 'string'},
        {dataField: 'nomeArtigo', caption: 'contratos.fields.linhas.nomeArtigo', dataType: 'string'},
        {dataField: 'qtd1', caption: 'contratos.fields.linhas.qtd1', dataType: 'double'},
        {dataField: 'prVenda', caption: 'contratos.fields.linhas.prVenda', dataType: 'double'},
        {dataField: 'd1', caption: 'contratos.fields.linhas.d1', dataType: 'double', visible: false},
        {dataField: 'codIva', caption: 'contratos.fields.linhas.codIva', dataType: 'string'},
        {dataField: 'taxaIva', caption: 'contratos.fields.linhas.taxaIva', dataType: 'number', format: {percent: true}},
        {dataField: 'nArmazem', caption: 'contratos.fields.linhas.nArmazem', dataType: 'string', visible: false},
        {dataField: 'cunimo', caption: 'contratos.fields.linhas.cunimo', dataType: 'string', visible: false},
        {dataField: 'nVendedor', caption: 'contratos.fields.linhas.nVendedor', dataType: 'string', visible: false},
        // Verificar com Diogo Simoes e ou Rodrigo se este narmazem 2 é para mostrar com a option do CCusto
        // {dataField: 'nArmazem2', caption: 'contratos.fields.linhas.ccusto', dataType: 'number', visible: false},
        {dataField: 'ccusto', caption: 'contratos.fields.linhas.ccusto', dataType: 'string', visible: false},
        {dataField: 'valorLiquido', caption: 'contratos.fields.linhas.valorLiquido', dataType: 'double'},
        {
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false,
          width: 100
        }
      ],
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false},
      headerFilter: {visible: false, search: {enabled: false}},
      showBorders: true,
      sorting: {mode: 'none'}
    };

    this._defaultContrato = {
      cab: {
        refCtrCab: 0,
        nClasseCtr: '',
        nTipoContrato: '',
        nContrato: 0,
        verContrato: 0,
        descricao: '',
        nClifo: '',
        idIdeChav: '',
        dataCtr: moment(),
        dataInicio: moment(),
        dataFim: moment(),
        estado: '',
        terminado: false,
        totalLiquido: 0,
        valorGlobal: 0,
        ccusto: '',
        nRefProcesso: '',
        codMoeda: 0,
        refExterna: '',
        nContratoAsString: '',
        nContratoSemVersaoAsString: '',
        codCondComerciais: 0,
        classeCtrNome: '',
        tipoContratoNome: '',
        clifoNome: '',
        idideDescricao: '',
        estadoNome: '',
        ccustoNome: '',
        moedaNome: '',
        abreviaturaMoeda: '',
        condComerciaisNome: '',
        localidade: '',
        codPostal: '',
        nContribuint: ''
      },
      linhas: []
    };
    this._entityMaintenanceInstanceArtigos = this._entityMaintenanceService.build(ENTITY_NAME_ARTIGOS);
    this._subscriptionConfigOptions = this._configOptionsService
      .getGroupOptions(this.configOptionsGroupName)
      .get(this.configOptionsInstanceName)
      .options()
      .subscribe((configOptions: TConfigOptions<boolean, IContratosConfigOptions>) => {
        this.optionShowGrupoConta = configOptions.get('showGrupoConta').value;
        this.optionShowMoeda = configOptions.get('showMoeda').value;
        this.optionShowCondComerciais = configOptions.get('showCondComerciais').value;
        this.optionShowRefExterna = configOptions.get('showRefExterna').value;
        this.optionShowVendedor = configOptions.get('showVendedor').value;
        this.optionShowNCCusto = configOptions.get('showNCCusto').value;
        this.optionShowD1 = configOptions.get('showD1').value;
        this.optionShowArmazem = configOptions.get('showArmazem').value;
        this.optionShowUnidadeMovimento = configOptions.get('showUnidadeMovimento').value;
        if (this._dataGridInstance) {
          this._evaluateColumnsVisibility();
        }
      });
  }

  public ngOnInit(): void {
    this._calculaTotalContrato(this.contrato);
  }

  public ngOnChanges({contrato}: SimpleChanges): void {
    if (contrato) {
      this._changedContrato(contrato.currentValue);
      this.abreviaturaMoedaCliente = this.contrato.cab.abreviaturaMoeda;
      this.evaluateContratoLinhas();
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigOptions.unsubscribe();
  }

  public onDataGridInitialized(event: IDevExpressDataGridEventOnInitialized<IJsonContrato, IJsonContrato>): void {
    this._dataGridInstance = event.component;
    this._evaluateColumnsVisibility();
  }

  public evaluateContratoLinhas(): void {
    for (const item of this.contrato.linhas) {
      item.nArmazem2 = item.nArmazem;
    }
  }

  public editarContrato(): Promise<void> | void {
    if (!isFunction(this.actionEditarContrato)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionEditarContrato(this.contrato)).catch((error: unknown) => {
      this._handleError(error, 'contratos.erros.editar');
    });
  }

  public reabrirContrato(): Promise<void> | void {
    if (!isFunction(this.actionReabrirContrato)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionReabrirContrato(this.contrato))
      .then((response) => {
        if (response) {
          this._setContrato(response);
        }
      })
      .catch((error: unknown) => {
        this._handleError(error, 'contratos.errors.reabrir');
      });
  }

  public terminarContrato(): Promise<void> | void {
    if (!isFunction(this.actionTerminarContrato)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionTerminarContrato(this.contrato))
      .then((response) => {
        if (response) {
          this._setContrato(response);
        }
      })
      .catch((error: unknown) => {
        this._handleError(error, 'contratos.errors.terminar');
      });
  }

  public apagarContrato(): Promise<void> {
    if (!isFunction(this.actionApagarContrato)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionApagarContrato(this.contrato)).catch((error: unknown) => {
      this._handleError(error, 'contratos.errors.apagar');
    });
  }

  public openArtigo(item: IJsonContratoLin): Promise<void> {
    return this._entityMaintenanceInstanceArtigos.maintenanceEdit(item.nArtigo).then(() => undefined);
  }

  private _changedContrato(value: IJsonContrato = this.contrato): void {
    this.contrato = merge<object, IJsonContrato, IJsonContrato>({}, this._defaultContrato, value);
    this._calculaTotalContrato(this.contrato);
  }

  private _setContrato(value: IJsonContrato): void {
    this.contrato = value;
    this.contratoChange.emit(this.contrato);
  }

  private _handleError(error: unknown, defaultMessage: string): void {
    if (error instanceof HttpErrorResponse) {
      const exception = this._cgExceptionService.get(error);
      if (exception?.message) {
        this._plAlertService.error(exception.message);
        return;
      }
    }
    this._plAlertService.error(defaultMessage);
  }

  private _calculaTotalContrato(contrato: IJsonContrato): void {
    this.totalContrato = 0;
    if (isArray(contrato?.linhas)) {
      for (const item of contrato.linhas) {
        this.totalContrato += item.valorLiquido;
      }
    }
  }

  private _evaluateColumnsVisibility(): void {
    this._dataGridInstance.columnOption('d1', 'visible', this.optionShowD1);
    this._dataGridInstance.columnOption('nArmazem', 'visible', this.optionShowArmazem);
    this._dataGridInstance.columnOption('cunimo', 'visible', this.optionShowUnidadeMovimento);
    this._dataGridInstance.columnOption('nVendedor', 'visible', this.optionShowVendedor);
    this._dataGridInstance.columnOption('ccusto', 'visible', this.optionShowNCCusto);
    // Verificar com Diogo Simoes e ou Rodrigo se este narmazem 2 é para mostrar com a option do CCusto
    // this._dataGridInstance.columnOption('nArmazem2', 'visible', this.optionShowNCCusto);
  }
}
