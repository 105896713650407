<div class="mapa-pagamentos-enviados-prompt-modal">
  <div class="modal-header">
    <h5 class="modal-title" [translate]="'global.text.confirmation'"></h5>
  </div>

  <div class="modal-body">
    <div [translate]="'mapapagam.messages.existemPagamentoEnviados'"></div>

    <ng-container *ngFor="let item of listJaEnviados">
      <div class="mapa-pagamentos-enviados-prompt-box">
        <h4>{{ item.nEmpresa }} - {{ item.nomeEmpresa }}</h4>
        <ul>
          <li *ngFor="let pagamento of item.pagamentos">
            <span
              ><strong>{{ pagamento.nome }}</strong> - Enviado: {{ pagamento.enviadoDataHora | cgcDateTime }}</span
            >
          </li>
        </ul>
      </div>
    </ng-container>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></button>
    <button type="button" class="btn btn-sm btn-success" (click)="close()"><span [translate]="'mapapagam.texts.continuar'">&nbsp;</span><i class="fa fa-fw fa-arrow-right"></i></button>
  </div>
</div>
