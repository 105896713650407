import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {downloadStream} from 'pl-comps-angular';
import {EModuleTipo, ETipoExport, IDocsComerciasEstatisticasDocFilters, ITipoExport} from '../../docsComerciaisEstatisticas.module.interface';
import {HttpResponse} from '@angular/common/http';
import {DocsComerciaisEstatisticasDocService} from '../../docsComerciaisEstatisticasDoc.module.service';

@Component({
  selector: 'docs-comercias-estatisticas-doc-print-modal',
  templateUrl: './docsComerciaisEstatisticasDoc.print.modal.html'
})
export class DocsComerciaisEstatisticasDocPrintModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public filters: IDocsComerciasEstatisticasDocFilters;
  @Input() public moduleType: EModuleTipo;

  public reportModel: IJsonReport;
  public pdfUrl: string;
  public reportType: string;
  public tipoExport: ETipoExport;
  public tipoExportItems: Array<ITipoExport>;
  public readonly tiposExport: typeof ETipoExport;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _docsComerciasEstatisticasDocService: DocsComerciaisEstatisticasDocService
  ) {
    super(_injector);
    this.tipoExport = ETipoExport.PDF;
    this.tiposExport = ETipoExport;
    this.tipoExportItems = [
      {value: ETipoExport.PDF, label: 'global.btn.pdf'},
      {value: ETipoExport.EXCEL, label: 'global.btn.excel'}
    ];
  }

  public ngOnInit(): void {
    this.reportType = EReport.DocsComerciaisEstatisticasDoc;
    this._loadDefaultReport();
  }

  public onReportChange(report: IJsonReport): void {
    this.reportModel = report;
  }

  public loadPdf(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.tipoExport === ETipoExport.PDF || this.tipoExport === ETipoExport.EXCEL) {
        const params: IDocsComerciasEstatisticasDocFilters = {...this.filters, reportname: this.reportModel.name, tipoexportacao: this.tipoExport};
        this._docsComerciasEstatisticasDocService
          .exportToDoc(params, this.moduleType)
          .then((response: HttpResponse<Blob>) => {
            if (response) {
              if (this.tipoExport === ETipoExport.EXCEL) {
                const doc: Blob = response.body;
                if (doc?.size) {
                  downloadStream(doc);
                }
              } else {
                this.pdfUrl = response.url;
              }
            }
          }, reject)
          .finally(resolve);
      }
    });
  }

  private _loadDefaultReport(): Promise<void> {
    this.reportModel = {
      name: this._configService.configurations.gestaoComercial.docsComerciasEstatisticasDoc.reports.reportImpressao,
      title: this._configService.configurations.gestaoComercial.docsComerciasEstatisticasDoc.reports.reportImpressao
    };

    return this._reportsRegistryService
      .get(EReport.DocsComerciaisEstatisticasDoc)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          let foundDefault = false;
          for (const report of reports) {
            if (report.name.toUpperCase() === this.reportModel.name.toUpperCase()) {
              this.reportModel = {name: report.name, title: report.title};
              foundDefault = true;
              break;
            }
          }

          if (!foundDefault) {
            this.reportModel = {
              name: reports[0].name,
              title: reports[0].title
            };
          }
        }
      });
  }
}
