import {StateService, TargetState, Transition, UIInjector} from '@uirouter/core';
import {Ng2StateDeclaration} from '@uirouter/angular';
import {fromJson} from 'pl-comps-angular';
import {
  EFatEletronicaConfCertificadoPill,
  EFatEletronicaConfTab,
  IFatEletronicaConfStateParams
} from '../../../modules/faturacaoeletronicaconfiguracoes/faturacaoEletronicaConfiguracoes.module.interface';
import {IOAuth2SAFEStateParams, IOAuth2SAFEStoredState, STATE_NAME_OAUTH2_SAFE, STATE_URL_OAUTH2_SAFE} from './oauth2.safe.state.interface';
import {STATE_NAME_PORTAL} from '../../../services/portals/portals.service.interface';

export const STATE_OAUTH2_SAFE: Ng2StateDeclaration = {
  name: STATE_NAME_OAUTH2_SAFE,
  url: `/${STATE_URL_OAUTH2_SAFE}?token_type&expires_in&access_token&state`,
  redirectTo: stateSAFERedirectTo,
  data: {
    disableRecover: true
  },
  params: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    token_type: {type: 'string', value: '', squash: true},
    // eslint-disable-next-line @typescript-eslint/naming-convention
    expires_in: {type: 'int', value: -1, squash: true},
    // eslint-disable-next-line @typescript-eslint/naming-convention
    access_token: {type: 'string', value: '', squash: true},
    state: {type: 'string', value: '', squash: true}
  }
};

export function stateSAFERedirectTo(transition: Transition): string | TargetState {
  const params: IOAuth2SAFEStateParams = <IOAuth2SAFEStateParams>transition.params();
  // State will be the stored stateID
  if (!params.state) {
    return STATE_NAME_PORTAL;
  }
  const state: IOAuth2SAFEStoredState = fromJson(window.sessionStorage.getItem(params.state) || null);
  if (!state) {
    return STATE_NAME_PORTAL;
  }
  const fatEletronicaConfStateParams: IFatEletronicaConfStateParams = {
    tab: EFatEletronicaConfTab.Certificado,
    certificadoPill: EFatEletronicaConfCertificadoPill.Safe,
    safe: {
      nomeDaConta: state.nomeDaConta,
      accessToken: params.access_token,
      safetyKey: state.safetyKey,
      expiresIn: params.expires_in,
      obterAtributos: state.obterAtributos,
      ambientePreProducao: state.ambientePreProducao
    }
  };
  const injector: UIInjector = transition.injector();
  const stateService: StateService = injector.get<StateService>(StateService);
  return stateService.target(state.stateName, fatEletronicaConfStateParams, {location: 'replace'});
}
