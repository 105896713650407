import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ENTITY_NAME_CLICLS} from './clicls.entity.interface';
import {IJsonCLICL} from './jsonCliCl.entity.interface';

export const ENTITY_CLICLS: IEntityDefinition<IJsonCLICL> = {
  name: ENTITY_NAME_CLICLS,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.ATIVOS],
  searchPlaceholder: 'clicls.pesquisa',
  metadata: {
    keyName: 'codigo',
    fields: [
      {name: 'codigo', caption: 'clicls.fields.codigo', validators: {required: true, maxlength: 8}},
      {name: 'descricao', caption: 'clicls.fields.descricao', validators: {required: true, maxlength: 50}}
    ],
    order: 'descricao',
    searchFields: 'codigo,descricao'
  },
  autocomplete: {
    rowTemplate: '{{codigo}} - {{descricao}}',
    output: 'descricao',
    searchFields: 'codigo,descricao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  }
};
