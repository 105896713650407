/**
 * CentralGest Amalia
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MessageBodyIntent { 
    /**
     * The message
     */
    message?: string;
    /**
     * The detected intent
     */
    intent?: string;
    /**
     * The parameters of message
     */
    parameters?: object;
}

