export enum EDashboardFinanceiroChartType {
  ReceberDeCliente,
  PagarFornecedores
}

export interface IDashboardFinanceiroPieReceberClientesPagarFornecedores {
  name: string;
  value: number;
  percent: number;
}

export const MODULE_NAME_DASHBOARD_FINANCEIRO = 'dashboardfinanceiro';
