import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonAtivosAquisicao} from './jsonAtivosAquisicao.entity.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_ATIVOS_AQUISICAO} from './ativosAquisicao.entity.interface';
import {ENTITY_NAME_CODIGOS_CONTABILISTICOS} from '../codigoscontabilisticos/codigosContabilisticos.entity.interface';
import {ENTITY_NAME_DEPTO} from '../depto/depto.entity.interface';
import {ENTITY_NAME_MAPAS_AMORTIZACAO} from '../mapasAmortizacao/mapasAmortizacao.entity.interface';
import {ENTITY_NAME_PROCESSOS} from '../processos/processos.entity.interface';
import {ENTITY_NAME_VENDEDORES} from '../vendedores/vendedores.entity.interface';

export const ENTITY_ATIVOS_AQUISICAO: IEntityDefinition<IJsonAtivosAquisicao> = {
  name: ENTITY_NAME_ATIVOS_AQUISICAO,
  roles: [ROLE.ATIVOS],
  searchPlaceholder: 'ativosAquisicao.pesquisa',
  metadata: {
    keyName: 'ativosAquisicaoAquisicaoID',
    fields: [
      {name: 'ativosAquisicaoAquisicaoID', caption: 'ativosAquisicao.fields.ativosAquisicaoAquisicaoID'},
      {name: 'codAmortizacao', visible: false},
      {
        name: 'nomeAmortizacao',
        caption: 'ativosAquisicao.fields.codAmortizacao',
        entity: {name: 'amortizacao', keyTarget: 'codAmortizacao', outputTarget: 'nomeAmortizacao'}
      },
      {name: 'nMapaAmortizacao', visible: false},
      {
        name: 'nomeMapaAmortizacao',
        caption: 'ativosAquisicao.fields.nMapaAmortizacao',
        entity: {name: ENTITY_NAME_MAPAS_AMORTIZACAO, keyTarget: 'nMapaAmortizacao', outputTarget: 'nomeMapaAmortizacao'}
      },
      {name: 'dataAquisicao', type: 'date', caption: 'ativosAquisicao.fields.dataAquisicao'},
      {name: 'dataUtilizacao', type: 'date', caption: 'ativosAquisicao.fields.dataUtilizacao'},
      {name: 'nDocExterno', caption: 'ativosAquisicao.fields.nDocExterno'},
      {name: 'dataDocExterno', type: 'date', caption: 'ativosAquisicao.fields.dataDocExterno'},
      {name: 'nomeFornecedor', caption: 'ativosAquisicao.fields.nomeFornecedor'},
      {name: 'quantidade', type: 'number', caption: 'ativosAquisicao.fields.quantidade'},
      {name: 'usado', type: 'boolean', caption: 'ativosAquisicao.fields.usado'},
      {name: 'contabValorAquisicao', type: 'number', caption: 'ativosAquisicao.fields.contabValorAquisicao'},
      {name: 'contabTaxaAmortiza', type: 'number', caption: 'ativosAquisicao.fields.contabTaxaAmortiza'},
      {name: 'contabNumAnosUteis', type: 'number', caption: 'ativosAquisicao.fields.contabNumAnosUteis'},
      {name: 'contabPercReducao', type: 'number', caption: 'ativosAquisicao.fields.contabPercReducao'},
      {name: 'contabDataDispUso', type: 'date', caption: 'ativosAquisicao.fields.contabDataDispUso'},
      {name: 'contabBaseCalculo', type: 'cgsmallint', caption: 'ativosAquisicao.fields.contabBaseCalculo'},
      {name: 'contabMetodoCalculo', type: 'cgsmallint', caption: 'ativosAquisicao.fields.contabMetodoCalculo'},
      {name: 'contabValorResidual', type: 'number', caption: 'ativosAquisicao.fields.contabValorResidual'},
      {name: 'fiscalValorAquisicao', type: 'number', caption: 'ativosAquisicao.fields.fiscalValorAquisicao'},
      {name: 'fiscalTaxaAmortiza', type: 'number', caption: 'ativosAquisicao.fields.fiscalTaxaAmortiza'},
      {name: 'fiscalNumAnosUteis', type: 'number', caption: 'ativosAquisicao.fields.fiscalNumAnosUteis'},
      {name: 'fiscalPercReducao', type: 'number', caption: 'ativosAquisicao.fields.fiscalPercReducao'},
      {name: 'fiscalDataInicioUtil', type: 'date', caption: 'ativosAquisicao.fields.fiscalDataInicioUtil'},
      {name: 'fiscalBaseCalculo', type: 'cgsmallint', caption: 'ativosAquisicao.fields.fiscalBaseCalculo'},
      {name: 'fiscalMetodoCalculo', type: 'cgsmallint', caption: 'ativosAquisicao.fields.fiscalMetodoCalculo'},
      {name: 'fiscalValorResidual', type: 'number', caption: 'ativosAquisicao.fields.fiscalValorResidual'},
      {name: 'fiscalTribAutonTipo', type: 'cgsmallint', caption: 'ativosAquisicao.fields.fiscalTribAutonTipo'},
      {name: 'nContaDoAtivo', visible: false},
      {
        name: 'nomeContaDoAtivo',
        caption: 'ativosAquisicao.fields.nContaDoAtivo',
        entity: {name: 'pocs', keyTarget: 'nContaDoAtivo', outputTarget: 'nomeContaDoAtivo'}
      },
      {name: 'nContaAmortAcumu', caption: 'ativosAquisicao.fields.nContaAmortAcumu'},
      {name: 'nContaAmortExerc', caption: 'ativosAquisicao.fields.nContaAmortExerc'},
      {name: 'nContaFornecedor', visible: false},
      {
        name: 'nomeContaFornecedor',
        caption: 'ativosAquisicao.fields.nContaFornecedor',
        entity: {name: 'fornecedores', keyTarget: 'nContaFornecedor', outputTarget: 'nomeContaFornecedor'}
      },
      {name: 'nContaIVADedutivel', caption: 'ativosAquisicao.fields.nContaIVADedutivel'},
      {name: 'iVADeduzido', type: 'number', caption: 'ativosAquisicao.fields.iVADeduzido'},
      {name: 'codIVAAquisicao', type: 'cgsmallint', caption: 'ativosAquisicao.fields.codIVAAquisicao'},
      {name: 'codRubOrcam', caption: 'ativosAquisicao.fields.codRubOrcam'},
      {name: 'nCCusto', visible: false},
      {
        name: 'nomeCCusto',
        caption: 'ativosAquisicao.fields.nCCusto',
        entity: {name: 'ccustos', keyTarget: 'nCCusto', outputTarget: 'nomeCCusto'}
      },
      {name: 'nCodRepCC', type: 'cgsmallint', caption: 'ativosAquisicao.fields.nCodRepCC'},
      {name: 'nZona', visible: false},
      {
        name: 'nomeZona',
        type: 'cgsmallint',
        caption: 'ativosAquisicao.fields.nZona',
        entity: {name: 'zonas', keyTarget: 'nZona', outputTarget: 'nomeZona'}
      },
      {name: 'nDepto', visible: false},
      {
        name: 'nomeDepto',
        type: 'cgsmallint',
        caption: 'ativosAquisicao.fields.nDepto',
        entity: {name: ENTITY_NAME_DEPTO, keyTarget: 'nDepto', outputTarget: 'nomeDepto'}
      },
      {name: 'nSubde', visible: false},
      {
        name: 'nomeSubde',
        caption: 'ativosAquisicao.fields.nSubde',
        entity: {name: 'subde', keyTarget: 'nSubde', outputTarget: 'nomeSubde'}
      },
      {name: 'tipoArtigo', visible: false},
      {
        name: 'nometipoArtigo',
        type: 'integer',
        caption: 'ativosAquisicao.fields.tipoArtigo',
        entity: {name: 'tiposartigo', keyTarget: 'tipoArtigo', outputTarget: 'nometipoArtigo'}
      },
      {name: 'nGrFamilia', visible: false},
      {
        name: 'nomeGrFamilia',
        type: 'integer',
        caption: 'ativosAquisicao.fields.nGrFamilia',
        entity: {name: 'grandesfamilias', keyTarget: 'nGrFamilia', outputTarget: 'nomeGrFamilia'}
      },
      {name: 'nFamilia', visible: false},
      {
        name: 'nomeFamilia',
        type: 'integer',
        caption: 'ativosAquisicao.fields.nFamilia',
        entity: {name: 'familias', keyTarget: 'nFamilia', outputTarget: 'nomeFamilia'}
      },
      {name: 'nSubfa', visible: false},
      {
        name: 'nomeSubfa',
        type: 'integer',
        caption: 'ativosAquisicao.fields.nSubfa',
        entity: {name: 'subfamilias', keyTarget: 'nSubfa', outputTarget: 'nomeSubfa'}
      },
      {name: 'codContab', visible: false},
      {
        name: 'nomecodContab',
        caption: 'ativosAquisicao.fields.codContab',
        entity: {name: ENTITY_NAME_CODIGOS_CONTABILISTICOS, keyTarget: 'codContab', outputTarget: 'nomecodContab'}
      },
      {name: 'classe', visible: false},
      {
        name: 'nomeclasse',
        type: 'integer',
        caption: 'ativosAquisicao.fields.classe',
        entity: {name: 'artigoclasses', keyTarget: 'classe', outputTarget: 'nomeclasse'}
      },
      {name: 'categoria', visible: false},
      {
        name: 'nomecategoria',
        type: 'integer',
        caption: 'ativosAquisicao.fields.categoria',
        entity: {name: 'categorias', keyTarget: 'categoria', outputTarget: 'nomecategoria'}
      },
      {name: 'nVendedor', visible: false},
      {
        name: 'nomeVendedor',
        type: 'cgsmallint',
        caption: 'ativosAquisicao.fields.nVendedor',
        entity: {name: ENTITY_NAME_VENDEDORES, keyTarget: 'nVendedor', outputTarget: 'nomeVendedor'}
      },
      {name: 'nRefProcesso', visible: false},
      {
        name: 'nomeRefProcesso',
        caption: 'ativosAquisicao.fields.nRefProcesso',
        entity: {name: ENTITY_NAME_PROCESSOS, keyTarget: 'nRefProcesso', outputTarget: 'nomeRefProcesso'}
      },
      {name: 'nSeccao', visible: false},
      {
        name: 'nomeSeccao',
        caption: 'ativosAquisicao.fields.nSeccao',
        entity: {name: 'seccoes', keyTarget: 'nSeccao', outputTarget: 'nomeSeccao'}
      }
    ],
    order: 'codAtivo',
    searchFields: 'codAtivo',
    listFields: 'codAtivo'
  },
  autocomplete: {
    rowTemplate: '{{codAtivo}}',
    output: 'codAtivo',
    searchFields: 'codAtivo'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.ativosAquisicao'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.ativosAquisicao'
      }
    }
  }
};
