import {HttpResponse} from '@angular/common/http';
import {GtoModuleComponent} from './components/gto.module.component';
import {GtoService} from './gto.module.service';
import {IJsonGTOLicenseInfo} from './jsonGTO.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_GTO} from './gto.module.interface';
import {RESOLVER_LICENCA_STORE_MODE_PUBLIC} from '../../../services/config/config.service.router';
import {ROLE} from '../../../services/role.const';

export const MODULE_GTO: IModuleDefinition = {
  name: MODULE_NAME_GTO,
  state: {
    url: `/${MODULE_NAME_GTO}`,
    component: GtoModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.ERP],
      pluginsRoles: [ROLE.CONTABILIDADEPREMIUM, ROLE.ERPADVANCED],
      icon: 'fa-calendar-check-o',
      sidebarTitle: 'portals.sidebar.modules.gto'
    },
    resolve: [
      RESOLVER_LICENCA_STORE_MODE_PUBLIC,
      {
        provide: 'gtoLicenseInfo',
        deps: [GtoService],
        useFactory: (gtoService: GtoService) => gtoService.getLicenseInfo().then((response: HttpResponse<IJsonGTOLicenseInfo>) => response.body)
      },
      {
        provide: 'responsaveis',
        deps: [GtoService],
        useFactory: (gtoService: GtoService) => gtoService.getResponsaveis()
      }
    ]
  }
};
