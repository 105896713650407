<pl-form>
  <div class="adiantamentos-registar-modal">
    <div class="modal-header">
      <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'adiantamentos.modals.registar.title'"></h5>
    </div>

    <div class="modal-body">
      <pl-alert type="warning" *ngIf="!configured" [closeable]="false">
        <div class="d-flex align-content-center">
          <i class="fa fa-exclamation-circle"></i>&nbsp;
          <span class="hyperlink hyperlink-text-color-white" [translate]="'adiantamentos.messages.notConfigured'" (click)="autoConfigModal()"></span>
        </div>
      </pl-alert>

      <h4 [translate]="description"></h4>

      <pl-group>
        <pl-group>
          <label><span [translate]="'docscomerciais.doc.cab.nDocFa'"></span>:</label>
          <edit>
            <entity-autocomplete
              attrName="docfa"
              entity="docfas"
              [model]="adiantRegisto"
              (selectedKeyChange)="adiantRegisto.nDocFa = $event"
              (evtSelectedDescriptionChanged)="adiantRegisto.nomeDocFa = $event"
              [filter]="docFilter"
              [output]="nDocFaOutput"
              [fieldsMap]="{nome: 'nomeDocFa'}"
              plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group class="docfanum-edit">
          <label><span [translate]="'docscomerciais.doc.cab.nNumer'"></span>:</label>
          <edit>
            <entity-autocomplete
              attrName="nnumer"
              entity="docfasnums"
              [model]="adiantRegisto"
              (selectedKeyChange)="adiantRegisto.nNumer = $event"
              [filter]="docfaNumFilter"
              [output]="'nNumer'"
              [outputKey]="'nNumer'">
            </entity-autocomplete>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <label [translate]="clifoCaption"></label>
        <edit>
          <entity-autocomplete
            [entity]="clifoEntName"
            attrName="clifos"
            [model]="adiantRegisto"
            (selectedKeyChange)="adiantRegisto.nConta = $event"
            (evtSelectedDescriptionChanged)="adiantRegisto.nomeNConta = $event"
            [fieldsMap]="{nome: 'nomeNConta'}"
            [properties]="{disabled: disableNConta}"
            [output]="clifosOutput">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'adiantamentos.modals.registar.codiva'"></label>
          <edit>
            <entity-autocomplete
              entity="ivas"
              attrName="codIva"
              [model]="adiantRegisto"
              (modelChange)="changeCodIva($event)"
              (selectedKeyChange)="adiantRegisto.codIva = $event"
              (evtSelectedDescriptionChanged)="adiantRegisto.nomeCodIva = $event"
              [fieldsMap]="{nome: 'nomeCodIva'}"
              [output]="ivasOutput">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'adiantamentos.modals.registar.taxaIva'"></label>
          <edit>
            <pl-edit type="number" attrName="taxaIva" [(model)]="adiantRegisto.taxaIva" [properties]="{disabled: true}"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'adiantamentos.modals.registar.valorSIva'"></label>
          <edit>
            <pl-edit
              type="number"
              attrName="valorSemIva"
              [model]="adiantRegisto.valorSemIva"
              (modelChange)="adiantRegisto.valorSemIva = $event; calcValComIva()"
              [properties]="{decimalsLimit: decimals}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'adiantamentos.modals.registar.valorCIva'"></label>
          <edit>
            <pl-edit
              type="number"
              attrName="valorComIva"
              [model]="adiantRegisto.valorComIva"
              (modelChange)="adiantRegisto.valorComIva = $event; calcValSemIva()"
              [properties]="{decimalsLimit: decimals}">
            </pl-edit>
          </edit>
        </pl-group>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn btn-sm btn-success action-ok" data-focus [disabled]="closeDisabled" [onClick]="close">
        <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.register'"></span>
      </pl-button>

      <pl-button klass="btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
    </div>
  </div>
</pl-form>
