import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_ORIGEM_CONTAS_RETENCAO} from './origemContasRetencao.datasource.interface';
import {EOrigemContasRetencoes} from '../../modules/portalcontabilidade/retencoes/retencoes.module.interface';

export const DATA_SOURCE_ORIGEM_CONTAS_RETENCAO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_ORIGEM_CONTAS_RETENCAO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EOrigemContasRetencoes.Ambos, name: 'origemContasRetencao.data.ambos'},
    {value: EOrigemContasRetencoes.PorTerceiros, name: 'origemContasRetencao.data.porTerceiros'},
    {value: EOrigemContasRetencoes.ATerceiros, name: 'origemContasRetencao.data.aTerceiros'}
  ]
});
