<div class="entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h4 class="modal-title" [translate]="'gruposdocfas.configuracoes.modal.title'"></h4>
  </div>

  <div class="modal-body">
    <grupo-docfa-configuracoes [callback]="grupoDocfaConfigCallback"></grupo-docfa-configuracoes>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="submit" class="btn btn-sm btn-primary action-save" (click)="save()"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></button>
    <button type="button" class="btn btn-sm btn-light action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
      <i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
    </button>
  </div>
</div>
