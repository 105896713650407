import {ContabilidadeImpdecpeComponent} from './components/contabilidade.impDecPe.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_PCA_ESTATISTICA_IMPDECPE} from './contabilidade.impDecPe.module.interface';
import {ROLE} from '../../../../services/role.const';

export const MODULE_PCA_ESTATISTICA_IMPDECPE: IModuleDefinition = {
  name: MODULE_NAME_PCA_ESTATISTICA_IMPDECPE,
  state: {
    url: '/impdecpe',
    component: ContabilidadeImpdecpeComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.impdecpe'
    }
  }
};
