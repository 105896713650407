export enum ETipoAbonoAgrupado {
  ND,
  Vencimentos,
  SubRefeicao,
  RemunAdicionais,
  Subsidios,
  RendEspecie,
  CessacaoContrato,
  Gratificacoes
}

export const DATA_SOURCE_NAME_TIPO_ABONO_AGRUPADO = 'tipoAbonoAgrupado';
