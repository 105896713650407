<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'debDiretosSEPA.strings.modalTitleContaCredora'"></h4>
</div>

<div class="modal-body">
  <pl-group>
    <label [translate]="'debDiretosSEPA.fields.meioPagamento'"></label>
    <edit>
      <entity-autocomplete
        entity="meiosPagamento"
        [model]="model"
        (modelChange)="codMeioPagamentoChange($event)"
        (selectedKeyChange)="model.codMeioPagamento = $event"
        (evtSelectedDescriptionChanged)="model.nomeMeioPagamento = $event"
        [output]="meiosPagamentoOutput"
        [properties]="{disabled: isEditMode && !inCustomMeioPagamEdition, allowInvalid: false}"
        [fieldsMap]="{nCaixa: 'codMeioPagamento', nome: 'nomeMeioPagamento'}">
      </entity-autocomplete>
    </edit>
    <actions>
      <pl-button *ngIf="isEditMode" [disabled]="inCustomIbanEdition || inCustomMeioPagamEdition" klass="btn btn-sm btn-outline-warning" (evtClicked)="editMeioPagamento()">
        <i class="fa fa-fw fa-pencil"></i>
      </pl-button>
    </actions>
  </pl-group>

  <div *ngIf="showCodMeioPagamentoMessage" class="text-warning" style="display: flex; align-items: center; padding-bottom: 5px; font-size: 12px; gap: 5px">
    <i class="fa fa-fw fa-exclamation-triangle me-1" aria-hidden="true"></i>
    <div>
      <div [translate]="'debDiretosSEPA.strings.codMeioPagamentoExisteNaLista1'"></div>
      <div [translate]="'debDiretosSEPA.strings.codMeioPagamentoExisteNaLista2'"></div>
    </div>
  </div>

  <div *ngIf="inCustomMeioPagamEdition" class="alert alert-sm ddsepa-alert" style="align-items: baseline; font-size: 12px">
    <i class="fa fa-fw fa-info-circle me-1" aria-hidden="true"></i>
    <div>
      <div class="mb-2" [translate]="meioPagamentoEditMessage"></div>
      <div class="d-flex gap-2">
        <pl-button klass="btn btn-xs btn-success" [onClick]="fnSaveCustomMeioPagamento"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
        <pl-button klass="btn btn-xs btn-light" (evtClicked)="cancelCustomEdition()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></pl-button>
      </div>
    </div>
  </div>

  <pl-group>
    <label [translate]="'debDiretosSEPA.fields.iban'"></label>
    <edit>
      <pl-edit type="string" [(model)]="model.iBAN" [properties]="{disabled: !inCustomIbanEdition}"></pl-edit>
    </edit>
    <actions>
      <pl-tooltip [config]="{text: getBanDiffMeioPagamentoTooltip()}" *ngIf="isEditMode && iBanDiffMeioPagamento">
        <ng-container *plTooltipContent>
          <pl-button klass="btn btn-sm btn-outline-primary me-1" (evtClicked)="updateIbanFromMeioPagamento()">
            <i class="fa fa-fw fa-refresh"></i>
          </pl-button>
        </ng-container>
      </pl-tooltip>
      <pl-button *ngIf="isEditMode" [disabled]="inCustomMeioPagamEdition || inCustomIbanEdition" klass="btn btn-sm btn-outline-warning" (evtClicked)="editIban()">
        <i class="fa fa-fw fa-pencil"></i>
      </pl-button>
    </actions>
  </pl-group>

  <div *ngIf="inCustomIbanEdition" class="alert alert-sm ddsepa-alert" style="align-items: baseline; font-size: 12px">
    <i class="fa fa-fw fa-info-circle me-1" aria-hidden="true"></i>
    <div>
      <div class="mb-2" [translate]="iBanEditMessage"></div>
      <div class="d-flex gap-2">
        <pl-button klass="btn btn-xs btn-success" [onClick]="fnSaveCustomIban"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
        <pl-button klass="btn btn-xs btn-light" (evtClicked)="cancelCustomEdition()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></pl-button>
      </div>
    </div>
  </div>

  <pl-group>
    <label [translate]="'debDiretosSEPA.fields.idDebitoDireto'"></label>
    <edit>
      <pl-edit type="string" [(model)]="model.idDebitoDireto" [properties]="{disabled: inCustomIbanEdition || inCustomMeioPagamEdition}"></pl-edit>
    </edit>
  </pl-group>
  <div style="padding-bottom: 5px; margin-top: -5px; font-size: 12px"><small class="text-muted" [translate]="'debDiretosSEPA.fields.exampleIdDebito'"></small></div>

  <pl-group *ngIf="userAcessos.acessoContabilidade">
    <label [translate]="'debDiretosSEPA.fields.nConta'"></label>
    <edit>
      <entity-autocomplete
        entity="pocs"
        [model]="model"
        (selectedKeyChange)="model.nContaBanco = $event"
        (evtSelectedDescriptionChanged)="model.nomePOC = $event"
        [output]="pocsOutput"
        [properties]="{allowInvalid: false, disabled: isEditMode || forceDisabledNContaBanco}"
        [fieldsMap]="{nConta: 'nContaBanco', nome: 'nomePOC'}">
      </entity-autocomplete>
    </edit>
  </pl-group>

  <fieldset class="mt-3 mb-2" *ngIf="userAcessos.acessoContabilidade">
    <legend [translate]="'debDiretosSEPA.strings.integracaoEmContabilidade'"></legend>
    <pl-group>
      <label [translate]="'debDiretosSEPA.fields.diario'"></label>
      <edit>
        <entity-autocomplete
          entity="diarios"
          [model]="model"
          (selectedKeyChange)="model.nDiario = $event"
          [properties]="{allowInvalid: false, disabled: inCustomIbanEdition || inCustomMeioPagamEdition}"
          (evtSelectedDescriptionChanged)="model.nomeDiario = $event"
          [fieldsMap]="{nDiario: 'nDiario', nome: 'nomeDiario'}">
        </entity-autocomplete>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'debDiretosSEPA.fields.descritivo'"></label>
      <edit>
        <entity-autocomplete
          entity="descritivos"
          [model]="model"
          [properties]="{allowInvalid: false, disabled: inCustomIbanEdition || inCustomMeioPagamEdition}"
          (selectedKeyChange)="model.nDescritivo = $event"
          (evtSelectedDescriptionChanged)="model.nomeDescritivo = $event"
          [fieldsMap]="{nDescrit: 'nDescritivo', nome: 'nomeDescritivo'}">
        </entity-autocomplete>
      </edit>
    </pl-group>
  </fieldset>
</div>

<div class="modal-footer" plNavigation>
  <button type="submit" [disabled]="inCustomIbanEdition || inCustomMeioPagamEdition" class="btn btn-sm btn-primary" (click)="closeModal()">
    <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
  </button>
  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
