import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonPRHAbonos} from '../../interfaces/jsonPRHAbonos.interface';
import {IJsonPRHFaltas} from '../../interfaces/jsonPRHFaltas.interface';
import {IJsonPRHFerias} from '../../interfaces/jsonPRHFerias.interface';
import {IJsonPRHTarefaCab} from './jsonPRHTarefaCab.entity.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface IPRHTarefasCabEntityService extends IEntityService<IJsonPRHTarefaCab> {
  mudarPosicao(idTarefaCab: number, codEmpLanca: number, motivo: string): TServiceResponse<IJsonPRHTarefaCab>;

  retrocedePosicao(idTarefaCab: number, codEmpLanca: number, motivo: string): TServiceResponse<IJsonPRHTarefaCab>;

  rejeitar(idTarefaCab: number, codEmpLanca: number, motivo: string): TServiceResponse<IJsonPRHTarefaCab>;

  updateAbonos(idTarefaCab: number, abonos: Array<IJsonPRHAbonos>): TServiceResponse<void>;

  updateFaltas(idTarefaCab: number, faltas: Array<IJsonPRHFaltas>): TServiceResponse<void>;

  updateFerias(idTarefaCab: number, ferias: Array<IJsonPRHFerias>): TServiceResponse<void>;

  uploadDoc(idTarefaCab: number, file: Blob, filename?: string): TServiceResponse<IJsonPRHTarefaCab>;

  uploadDocUrl(idTarefaCab: number, filename?: string): Promise<string>;

  uploadDocTarefas(idsTarefasCab: Array<number>, file: Blob, filename?: string): TServiceResponse<void>;

  uploadDocTarefasUrl(filename?: string): Promise<string>;

  downloadDoc(idTarefaCab: number): TServiceResponse<Blob>;

  downloadDocUrl(idTarefaCab: number): string;

  deleteTarefaDoc(idTarefaCab: number): TServiceResponse<void>;
}

export interface IPRHTarefasCabEntity extends IEntityDefinition<IJsonPRHTarefaCab, IPRHTarefasCabEntityService> {}

export const ENTITY_NAME_PRH_TAREFAS_CAB = 'prhtarefascab';
