import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonAtivosListaAtivos} from '../../jsonAtivosConsistencia.module.interface';

@Component({
  selector: 'modal-ativos-consistencia-lista-ativos',
  templateUrl: './ativosConsistencia.listaAtivos.modal.component.html'
})
export class AtivosConsistenciaListaAtivosModalComponent extends CGModalComponent<void> {
  @Input() public listaAtivos: Array<IJsonAtivosListaAtivos>;

  public readonly definition: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.definition = {
      columns: [
        {dataField: 'codAtivo', dataType: 'string', caption: 'ativosconsistencia.modal.table.codAtivo', allowGrouping: false},
        {dataField: 'designacao', dataType: 'string', caption: 'ativosconsistencia.modal.table.designacao'},
        {dataField: 'dataAquisicao', dataType: 'date', caption: 'ativosconsistencia.modal.table.dataAquisicao'},
        {dataField: 'virtualValor', dataType: 'double', caption: 'ativosconsistencia.modal.table.virtualValor', allowGrouping: false}
      ],
      keyExpr: 'codAtivo',
      searchPanel: {visible: true}
    };
  }
}
