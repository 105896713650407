import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {
  IRHProceDMRATDMRATList,
  IRHProceDMRATModel,
  IRHProceDMRATProcessExistentesItem,
  IRHProceDMRATProcessExistentesParams,
  IRHProceDMRATProcessStatus,
  IRHProceDMRATSendWebService,
  IRHProceDMRATStatus,
  IRHProceDMRATViewInfoResults
} from './proceDMRAT.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ProceDMRATService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${_apiService.path.restapi}/rhproceDMRAT`;
  }

  public getJobStatus(): Promise<IRHProceDMRATStatus> {
    return new Promise<IRHProceDMRATStatus>((resolve, reject) => {
      this._apiService
        .get<IRHProceDMRATStatus>({
          url: `${this._path}/status`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public stopJob(): TServiceResponse<void> {
    return this._apiService.post({
      url: this.stopJobUrl(),
      body: null
    });
  }

  public stopJobUrl(): string {
    return `${this._path}/stop-job`;
  }

  public getProcessStatus(): Promise<IRHProceDMRATProcessStatus> {
    return new Promise<IRHProceDMRATProcessStatus>((resolve, reject) => {
      this._apiService
        .get<IRHProceDMRATProcessStatus>({
          url: `${this._path}/get-process-results`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public getProcessExistentes(params: IRHProceDMRATProcessExistentesParams): Promise<Array<IRHProceDMRATProcessExistentesItem>> {
    return new Promise<Array<IRHProceDMRATProcessExistentesItem>>((resolve, reject) => {
      this._apiService
        .post<Array<IRHProceDMRATProcessExistentesItem>, IRHProceDMRATProcessExistentesParams>({
          url: `${this._path}/processos-existentes`,
          body: params
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public processar(model: IRHProceDMRATModel): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/processar`,
      body: model
    });
  }

  public getProcResults(): Promise<IRHProceDMRATDMRATList> {
    return new Promise<IRHProceDMRATDMRATList>((resolve, reject) => {
      this._apiService
        .get<IRHProceDMRATDMRATList>({
          url: `${this._path}/get-process-results`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public generateAndDownloadFileUrl(): string {
    return `${this._path}/generate-file`;
  }

  public sendFileWebService(): Promise<void> {
    return this._apiService.post<IRHProceDMRATSendWebService>({url: `${this._path}/send-file-webservice`}).then(() => undefined);
  }

  public genDRIFile(): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: this.generateAndDownloadFileUrl(),
      responseType: 'blob'
    });
  }

  public getVDMRATViewInfo(nempresa: string, ano: number, mes: number): Promise<IRHProceDMRATViewInfoResults> {
    return new Promise<IRHProceDMRATViewInfoResults>((resolve, reject) => {
      this._apiService
        .get<IRHProceDMRATViewInfoResults>({
          url: `${this._path}/get-info-remun/${nempresa}/${ano}/${mes}`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }
}
