import {IJsonAnosCalculo} from './jsonAtivosDepreciacao.module.interface';
import {TDate} from '../../../../common/dates';

export interface IAtivosDepreciacaoHeader {
  anoDeCalculo: number;
  codAtivoDe: number;
  codAtivoAte: number;
  dataAquisicaoDe: TDate;
  dataAquisicaoAte: TDate;
  pesquisaAnosAnteriores: boolean;
}

export interface IAnosCalculo extends IJsonAnosCalculo {
  dataDeFormated: string;
  dataAteFormated: string;
}

export const MODULE_NAME_ATIVOS_DEPRECIACAO = 'ativosdepreciacao';
