export enum EDMRFRubrica {
  R000,
  R102,
  R103,
  R104,
  R105,
  R106,
  R107,
  R108,
  R109,
  R110,
  R112,
  R114,
  R115,
  R116,
  R117,
  R118,
  R199,
  R201,
  R202,
  R203,
  R204,
  R205,
  R206,
  R207,
  R208,
  R209,
  R210,
  R211,
  R212,
  R213,
  R299
}

export const DATA_SOURCE_NAME_DMRF_RUBRICA = 'dmrfrubrica';
