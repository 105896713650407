import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isEmpty, KEYCODES, TEditInputKeyboardEvent} from 'pl-comps-angular';
import {ConfigService} from '../../../../services/config/config.service';
import {EFaturaConfigDocsUIService} from '../../eFaturaConfigDocs.module.ui.service';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {focusElement} from '../../../../../common/utils/element.utils';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonEFaturaConfigDocsSupplier} from '../../jsonEFaturaConfigDocs.module.interface';
import {IJsonPOC} from '../../../../entities/pocs/jsonPOC.entity.interface';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';

@Component({
  selector: 'config-docs-efatura-supplier',
  templateUrl: './configdocsefatura.supplier.component.html'
})
export class ConfigDocsEFaturaSupplierComponent implements OnInit {
  @Input() public nContribuinte: string;
  @Input() public nomeFornecedor: string;
  @Input() public model: IJsonEFaturaConfigDocsSupplier;
  @Output() public readonly evtLastFieldKeydown: EventEmitter<KeyboardEvent>;

  public filterContaCorrente: string;
  public promise: Promise<void>;

  private _pocsService: IEntityService<IJsonPOC>;
  private _elementContaAUtilizar: HTMLElement;

  constructor(
    private readonly _configService: ConfigService,
    private readonly _eFaturaConfigDocsUIService: EFaturaConfigDocsUIService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this.evtLastFieldKeydown = new EventEmitter<KeyboardEvent>();
  }

  public ngOnInit(): void {
    this._pocsService = this._entityServiceBuilder.build<IJsonPOC>('pocs');
    const filterRadicalFornecedores = `nConta=%'${this._configService.configurations.clientesFornecedores.radicalFornecedores}%'`;
    const filterRadicalOutrosDevedoresCredores = `nConta=%'${this._configService.configurations.clientesFornecedores.radicalOutrosDevedoresCredores}%'`;
    const filterRadical = `(${filterRadicalFornecedores}|${filterRadicalOutrosDevedoresCredores})`;
    this.filterContaCorrente = `tipo=${ETipoContaContabilidade.Movimento}&bloqueada=false&cc=true&nif=%${this.nContribuinte}%&${filterRadical}`;
    if (!this.model) {
      this.model = {
        contaCorrente: undefined,
        contaCorrenteDesc: undefined,
        docConferencia: undefined,
        docConferenciaDesc: undefined,
        fatura: undefined,
        faturaDesc: undefined,
        faturaEstrangeiro: undefined,
        faturaEstrangeiroDesc: undefined,
        faturaRecibo: undefined,
        faturaReciboDesc: undefined,
        faturaSimplificada: undefined,
        faturaSimplificadaDesc: undefined,
        notaCredito: undefined,
        notaCreditoDesc: undefined,
        notaDebito: undefined,
        notaDebitoDesc: undefined,
        reciboVerde: undefined,
        reciboVerdeDesc: undefined
      };
      this._getEFaturaConfigsDocsSupplier();
    }
  }

  public nContribuinteChanged(nContribuinte: string): void {
    this.nContribuinte = nContribuinte;
    this._getEFaturaConfigsDocsSupplier();
  }

  public save(): Promise<HttpResponse<IJsonEFaturaConfigDocsSupplier>> {
    return this._eFaturaConfigDocsUIService.saveEFaturaConfigDocsSupplier(this.nContribuinte, this.nomeFornecedor, this.model);
  }

  public onConfigDocsEFaturaHeaderLastFieldKeydown(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopImmediatePropagation();
      if (this._elementContaAUtilizar) {
        const inputContaAUtilizar: HTMLInputElement = this._elementContaAUtilizar.querySelector<HTMLInputElement>('input');
        focusElement(inputContaAUtilizar);
      }
    }
  }

  public readonly lastFieldKeydown: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._lastFieldKeydown(event);
  };

  public readonly fnKeydownNContribuinte = (value: string, event: KeyboardEvent): void => {
    this._keydownNContribuinte(event);
  };

  @ViewChild('elementContaAUtilizar', {read: ElementRef})
  public set elementContaAUtilizar(value: ElementRef<HTMLElement>) {
    this._elementContaAUtilizar = value?.nativeElement;
  }

  private _lastFieldKeydown(event: KeyboardEvent): void {
    this.evtLastFieldKeydown.emit(event);
  }

  private _keydownNContribuinte(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      const input: HTMLElement = document.querySelector('[data-attr-name="fatura"] input');
      if (input) {
        setTimeout(() => {
          input.focus();
        });
      }
    }
  }

  private async _getEFaturaConfigsDocsSupplier(): Promise<void> {
    this.promise = this._eFaturaConfigDocsUIService
      .getEFaturaConfigsDocsSupplier(this.nContribuinte)
      .then((response: HttpResponse<IJsonEFaturaConfigDocsSupplier>) => {
        this.model = response.body;
        this.nomeFornecedor = this.model.contaCorrenteDesc;
      })
      .finally(() => {
        this.promise = undefined;
      });
    await Promise.resolve(this.promise).then(() => {
      if (
        isEmpty(this.model.fatura) &&
        isEmpty(this.model.faturaDesc) &&
        isEmpty(this.model.faturaEstrangeiro) &&
        isEmpty(this.model.faturaEstrangeiroDesc) &&
        isEmpty(this.model.faturaRecibo) &&
        isEmpty(
          this.model.faturaReciboDesc &&
            isEmpty(this.model.faturaSimplificada) &&
            isEmpty(this.model.faturaSimplificadaDesc) &&
            isEmpty(this.model.notaCredito) &&
            isEmpty(this.model.notaCreditoDesc) &&
            isEmpty(this.model.notaDebito) &&
            isEmpty(this.model.notaDebitoDesc) &&
            isEmpty(this.model.reciboVerde) &&
            isEmpty(this.model.reciboVerdeDesc)
        )
      ) {
        this.promise = this._pocsService
          .query({pesquisa: this.filterContaCorrente})
          .then((response: THttpQueryResponse<IJsonPOC>) => {
            if (response.body.list.length === 1) {
              this.model = {...this.model, contaCorrente: response.body.list[0].nConta, contaCorrenteDesc: response.body.list[0].nome};
              this.nomeFornecedor = this.model.contaCorrenteDesc;
            }
          })
          .finally(() => {
            this.promise = undefined;
          });
      }
    });
    return this.promise;
  }
}
