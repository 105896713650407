import {Component, ElementRef, Host, Optional, Renderer2} from '@angular/core';
import {PlEditGroupComponent} from '../edit.group.component';

@Component({
  selector: 'label',
  template: '<ng-content></ng-content>',
  standalone: false
})
export class PlEditGroupLabelComponent {
  constructor(@Host() @Optional() group: PlEditGroupComponent, elementRef: ElementRef<HTMLElement>, renderer: Renderer2) {
    if (group) {
      renderer.addClass(elementRef.nativeElement, 'form-label');
    }
  }
}
