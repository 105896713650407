<div class="cg-cookies-consent-wrapper" [class.open]="consentOpened">
  <div class="cg-cookies-consent-overlay"></div>
  <div class="cg-cookies-consent">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-10 col-lg-8 col-xl-5 ms-auto me-auto">
          <pl-cookies-consent (evtConsented)="consented($event)">
            <div *plCookiesConsentHeader [innerHTML]="'cookies.consent.header' | translate"></div>
            <div *plCookiesConsentAbout>
              <p [translate]="'cookies.consent.about.1'"></p>
              <p [translate]="'cookies.consent.about.2'"></p>
              <p [translate]="'cookies.consent.about.3'"></p>
            </div>
          </pl-cookies-consent>
        </div>
      </div>
    </div>
  </div>
</div>
