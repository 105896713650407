import {HttpResponse} from '@angular/common/http';
import {IJsonDocumFac} from './jsonIntFac.module.interface';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {IntegracaoDocumentosModuleComponent} from './components/intFac.module.component';
import {IntegracaoDocumentosService} from './intFac.module.sevice';
import {MODULE_NAME_INTEGRACAO_DOCUMENTOS} from './intFac.module.interface';
import {ROLE} from '../../services/role.const';

export const MODULE_INTEGRACAO_DOCUMENTOS: IModuleDefinition = {
  name: MODULE_NAME_INTEGRACAO_DOCUMENTOS,
  state: {
    url: `/${MODULE_NAME_INTEGRACAO_DOCUMENTOS}`,
    component: IntegracaoDocumentosModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.intfac'
    },
    resolve: [
      {
        provide: 'header',
        deps: [IntegracaoDocumentosService],
        useFactory: (integracaoDocumentosService: IntegracaoDocumentosService): Promise<Array<IJsonDocumFac>> => {
          return integracaoDocumentosService.getTiposDocumentos().then((response: HttpResponse<Array<IJsonDocumFac>>) => response.body);
        }
      }
    ]
  }
};
