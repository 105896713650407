import moment, {Moment} from 'moment';
import {firstValueFrom} from 'rxjs';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EDateMonth, ICGTableOnSelect, IPlToolbarItem, IPlToolbarMenuItem, PlAlertService, TCGTableOnSelectFunction} from 'pl-comps-angular';
import {ApiService} from '../../../services/api/api.service';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {DATA_SOURCE_MESES} from '../../../datasources/meses/meses.datasource';
import {DocumentosPartilhadosUploadModalComponent} from '../modals/upload/documentosPartilhados.upload.modal.component';
import {EDocTipoEntity, IJsonGDFile, IJsonGDFolder, IJsonGDFolderContent} from '../../../entities/gdoc/JsonGDoc.entity.interface';
import {EFolderDoc, ENTITY_NAME_GDOC, IGDocEntityService} from '../../../entities/gdoc/gdoc.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IDataSourceItem} from '../../../components/datasource/datasources.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDocPartilhado} from '../../../entities/docspartilhados/docsPartilhados.entity.interface';
import {IJsonBoolean} from '../../../../common/interfaces/json';
import {ModuloComponent} from '../../../components/module/module.component';
import {momentDateTime} from '../../../../common/utils/moment.utils';
import {TServiceResponse} from '../../../services/api/api.service.interface';

const currentDate: Moment = moment();
const currentYear: number = currentDate.year();
const currentMonth: number = currentDate.month();
const ALL_ID = -1;
const ALL_CAPTION = 'global.text.all';

const docsPartilhadosFolderID = EFolderDoc.DefaultFolderDocsPartilhadosCliente;

@Component({
  selector: 'documentospartilhados',
  templateUrl: './documentosPartilhados.module.component.html'
})
export class DocumentosPartilhadosComponent extends ModuloComponent implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly folderId: string = docsPartilhadosFolderID;
  public readonly errorFolderMessage: string;

  private readonly _gdoc: IGDocEntityService;
  private readonly _meses: Array<IPlToolbarMenuItem<number>>;
  private readonly _mnuAnos: IPlToolbarItem<number>;
  private readonly _mnuMes: IPlToolbarItem<number>;
  private readonly _anos: Array<number>;
  private _fileList: Array<IDocPartilhado>;
  private _uploadUrl: string;
  private _docId: string;
  private _anoEmCurso: number;
  private _mesEmCurso: number;
  private _selectedAno: IPlToolbarMenuItem<number>;
  private _selectedMes: IPlToolbarMenuItem<number>;

  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _apiService: ApiService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columns: [
        {dataField: 'nomeExtensao', caption: 'docsPartilhados.fields.cab.nome'},
        {dataField: 'dataModificacao', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'horaModificacao', dataType: 'datetime', caption: 'docsPartilhados.fields.cab.horaCriacaoReg'},
        {dataField: 'tamanho', caption: 'docsPartilhados.fields.cab.tamanho'},
        {dataField: 'visualizado', dataType: 'boolean', caption: 'docsPartilhados.fields.cab.visualizado'},
        {dataField: 'vf_actions', cellTemplate: 'actions', caption: ' '}
      ],
      dataSource: [],
      remoteOperations: false,
      pager: {
        displayMode: 'full'
      }
    };
    this.errorFolderMessage = 'ativosgeramapasauto.message.errorFolderMessage';
    this._gdoc = this._entityServiceBuilder.build<IJsonGDFolder, IGDocEntityService>(ENTITY_NAME_GDOC);
    this._anoEmCurso = currentYear;
    this._mesEmCurso = currentMonth;
    let addedCurrentYear = false;
    this._anos = [];
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    for (let i = 0; i <= 10; i++) {
      this._anos.push(this._anoEmCurso - i);
    }

    const anos: Array<IPlToolbarMenuItem<number>> = [];
    for (const item of this._anos) {
      if (item === currentYear) {
        addedCurrentYear = true;
      }
      const itemAno: IPlToolbarMenuItem<number> = {
        caption: String(item),
        active: item === this._anoEmCurso,
        data: item,
        click: (menuItem: IPlToolbarMenuItem<number>) => {
          this._anoChanged(menuItem);
        }
      };
      if (itemAno.active) {
        this._selectedAno = itemAno;
      }
      anos.push(itemAno);
    }
    if (!addedCurrentYear) {
      anos.unshift({
        caption: String(currentYear),
        active: currentYear === this._anoEmCurso,
        data: this._anoEmCurso,
        click: (menuItem: IPlToolbarMenuItem<number>) => {
          this._anoChanged(menuItem);
        }
      });
      const itemAno: IPlToolbarMenuItem<number> = anos[0];
      if (itemAno.active) {
        this._selectedAno = itemAno;
      }
    }
    anos.unshift({
      caption: this._translateService.instant(ALL_CAPTION),
      id: String(ALL_ID),
      data: ALL_ID,
      click: (menuItem: IPlToolbarMenuItem<number>) => {
        this._anoChanged(menuItem);
      }
    });
    this._mnuAnos = {order: 1, id: 'listMenuAnos', caption: this._getAnoCaption(this._anoEmCurso), type: 'dropdown', menu: anos};
    this._meses = DATA_SOURCE_MESES.data.map<IPlToolbarMenuItem<number>>((item: IDataSourceItem<EDateMonth>) => {
      const month: number = item.value - 1;
      const itemMes: IPlToolbarMenuItem<number> = {
        id: String(month),
        caption: this._translateService.instant(item.name),
        active: month === this._mesEmCurso,
        data: month,
        click: (menuItem: IPlToolbarMenuItem<number>) => {
          this._mesChanged(menuItem);
        }
      };
      if (itemMes.active) {
        this._selectedMes = itemMes;
      }
      return itemMes;
    });
    this._meses.unshift({
      id: String(ALL_ID),
      caption: this._translateService.instant(ALL_CAPTION),
      data: ALL_ID,
      click: (menuItem: IPlToolbarMenuItem<number>) => {
        this._mesChanged(menuItem);
      }
    });
    const mesesMenu: Array<IPlToolbarMenuItem<number>> = this._anoEmCurso === currentYear ? this._meses.slice(0, currentMonth + 2) : this._meses;
    this._mnuMes = {order: 2, id: 'listMenuMes', caption: this._getMesCaption(this._mesEmCurso), type: 'dropdown', menu: mesesMenu};
    this._initUploadUrl();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnNovo.visible = true;
    this.btnNovo.click = this._uploadModal.bind(this);
    this.btnDelete.click = this._deleteSelectedDocs.bind(this);
    this.toolSearch.visible = true;
    this.toolbar.config.search.active = this.toolSearch.visible;
    this.toolbar.config.search.placeholder = 'entity.placeholder.search';
    this.toolbar.addButton(this._mnuAnos);
    this.toolbar.addButton(this._mnuMes);
    this._getList();
  }

  public itemCheck(item: IDocPartilhado): void {
    item.isChecked = !item.isChecked;
    this.btnDelete.visible = this._isOneOrMoreSelected();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public readonly fnOnSelect: TCGTableOnSelectFunction<IDocPartilhado> = ({item}: ICGTableOnSelect<IDocPartilhado>) => this._getPdf(item);

  public readonly fnGetPdf = (item: IDocPartilhado) => (): Promise<void> => this._getPdf(item);

  private _initUploadUrl(): void {
    this._gdoc.uploadFileUrl(docsPartilhadosFolderID, 'teste.pdf', undefined, EDocTipoEntity.DocsPartilhadosCliente).then((url: string) => {
      this._uploadUrl = url;
    });
  }

  private async _getList(): Promise<void> {
    this._docId = undefined;
    const response = await this._gdoc.get<IJsonGDFolderContent>({id: docsPartilhadosFolderID});
    const files: Array<IJsonGDFile> = response.body.files;
    this._fileList = await Promise.all(
      files.map<Promise<IDocPartilhado>>(async (file: IJsonGDFile) => {
        const isPdf = file.mimeType.toLowerCase() === 'application/pdf' || file.extensao.toLowerCase() === 'pdf';
        const docPartilhado: IDocPartilhado = {...file, isChecked: false, isPdf: isPdf, url: undefined};
        if (!isPdf) {
          docPartilhado.url = await this._getFileUrl(docPartilhado);
        }
        return docPartilhado;
      })
    );
    if (this._fileList.length) {
      this._docId = this._fileList[this._fileList.length - 1].docID;
    }
    if (this._selectedMes.data === ALL_ID && this._selectedAno.data === ALL_ID) {
      this.dataGridDefinition.dataSource = this._fileList;
    }
    this.dataGridDefinition.dataSource = this._fileList.filter((file) => {
      const dataModificacao: Moment = moment(file.dataModificacao);
      return (dataModificacao.month() === this._selectedMes.data || this._selectedMes.data === ALL_ID) && (dataModificacao.year() === this._selectedAno.data || this._selectedAno.data === ALL_ID);
    });
  }

  private async _getPdf(docPartilhado: IDocPartilhado): Promise<void> {
    if (!docPartilhado.isPdf) {
      return;
    }
    docPartilhado.visualizado = true;
    this._setVisualizado(docPartilhado.folderID, docPartilhado.docID).catch(() => {
      docPartilhado.visualizado = false;
    });
    const pdfUrl = await this._getFileUrl(docPartilhado);
    await this._cgModalService.showPdf('docsPartilhados.docs.title', pdfUrl);
  }

  private _getFileUrl(docPartilhado: IDocPartilhado): Promise<string> {
    const params: {alt?: string} = {};
    if (!docPartilhado.isPdf) {
      params.alt = 'media';
    }
    return firstValueFrom(buildSessionUrlWithParams(`${this._apiService.path.restapi}/gdoc/${docPartilhado.folderID}/${docPartilhado.docID}/downloadfile`, params));
  }

  private _uploadModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DocumentosPartilhadosUploadModalComponent);
    const componentInstance: DocumentosPartilhadosUploadModalComponent = modalInstance.componentInstance;
    componentInstance.uploadUrl = this._uploadUrl;
    const year: number = this._selectedAno.data === ALL_ID ? currentYear : this._selectedAno.data;
    const month: number = this._selectedMes.data === ALL_ID ? currentMonth : this._selectedMes.data;
    componentInstance.refDate = momentDateTime(year, month, currentDate.date());
    return modalInstance.result.then(() => {
      return this._getList().then(() => {
        return this._sendEmailNotification(docsPartilhadosFolderID, '', this._docId);
      });
    });
  }

  private async _deleteSelectedDocs(): Promise<void> {
    const title = this._translateService.instant('docsPartilhados.info.apagardocumentos');
    const message = this._translateService.instant('docsPartilhados.info.temCertezaApagarDocumentos');
    await this._cgModalService.showOkCancel(title, message);
    const fileListToDelete = this._fileList.filter((docPartilhado: IDocPartilhado) => docPartilhado.isChecked);
    await Promise.all(
      fileListToDelete.map(async (docPartilhado: IDocPartilhado) => {
        await this._gdoc.delete({url: `${docPartilhado.folderID}/${docPartilhado.docID}`});
        const index = this._fileList.indexOf(docPartilhado);
        if (index !== -1) {
          this._fileList.splice(index, 1);
        }
      })
    );
    await this._getList();
    this.btnDelete.visible = false;
  }

  private _setVisualizado(folderId: string, dodId: string): TServiceResponse<void> {
    return this._apiService.post<void>({url: `${this._apiService.path.restapi}/gdoc/${folderId}/${dodId}/setviewed`});
  }

  private _sendEmailNotification(folderId: string, emailTo: string, docId: string): Promise<void> {
    return this._apiService
      .put<IJsonBoolean, void>({
        url: `${this._apiService.path.restapi}/emails/folder/${folderId}/sendemailnotification`,
        params: {
          emailpara: emailTo,
          template: 'AvisoDocPartilhado',
          docid: docId,
          mostraerro: false
        }
      })
      .then((response: HttpResponse<IJsonBoolean>) => {
        if (response.body.value === true) {
          this._plAlertService.success('docsPartilhados.info.emailParaGabSucess');
        }
      });
  }

  private _isOneOrMoreSelected(): boolean {
    for (const iDocPartilhado of this._fileList) {
      if (iDocPartilhado.isChecked) {
        return true;
      }
    }
    return false;
  }

  private _anoChanged(menuItem: IPlToolbarMenuItem<number>, refresh: boolean = true): void {
    if (menuItem.data !== this._anoEmCurso) {
      if (this._selectedAno) {
        this._selectedAno.active = false;
      }
      this._selectedAno = menuItem;
      this._selectedAno.active = true;
      this._anoEmCurso = menuItem.data;
      this._mnuMes.menu = this._anoEmCurso === currentYear ? this._meses.slice(0, currentMonth + 2) : this._meses;
      if (this._mesEmCurso > this._mnuMes.menu.length - 1) {
        this._mesChanged(this._mnuMes.menu[this._mnuMes.menu.length - 1], false);
      }
      this._mnuAnos.caption = this._getAnoCaption(menuItem.data);
      if (refresh) {
        this._getList();
      }
    }
  }

  private _mesChanged(menuItem: IPlToolbarMenuItem<number>, refresh: boolean = true): void {
    if (menuItem.data !== this._mesEmCurso) {
      if (this._selectedMes) {
        this._selectedMes.active = false;
      }
      this._selectedMes = menuItem;
      this._selectedMes.active = true;
      this._mesEmCurso = menuItem.data;
      // menuItem data + 1 because of 'Todos' (ALL_ID) unshift
      this._mnuMes.caption = this._getMesCaption(menuItem.data + 1);
      if (refresh) {
        this._getList();
      }
    }
  }

  private _getAnoCaption(year: number): string {
    const key: string | number = year === ALL_ID ? this._translateService.instant(ALL_CAPTION) : year;
    return this._translateService.instant('docsPartilhados.toolbar.year', {year: key});
  }

  private _getMesCaption(month: number): string {
    const monthName: string = month === ALL_ID ? this._translateService.instant(ALL_CAPTION) : this._meses[month].caption;
    return this._translateService.instant('docsPartilhados.toolbar.month', {month: monthName});
  }
}
