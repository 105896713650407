import {ROLE} from '../../../../../services/role.const';
import {IModuleDefinition} from '../../../../../components/module/module.definition.interface';
import {ListagemRefeicoesComponent} from './components/listagemRefeicoes.module.component';

export const MODULE_REFEICOES_LISTAGEM_REFEICOES: IModuleDefinition = {
  name: 'colaboradoresListagemRefeicoes',
  state: {
    url: '/listagemrefeicoes',
    component: ListagemRefeicoesComponent,
    data: {
      roles: [ROLE.GESTOREMENTAS],
      pageTitle: 'global.menu.colaboradoresListagemRefeicoes'
    }
  }
};
