import {Component, Injector, OnInit} from '@angular/core';
import {ConfigsErpBaseModuleComponent} from '../../configs.erp.base.module.component';
import {EConfigsErpDepth, IConfigsErpEditConfigERP} from '../../components/configedit/configs.erp.edit.component.interface';
import {EConfigsPCAPeriodo, IConfigsPCAPeriodoItem} from '../configs.pca.module.interface';
import {IJsonConfigERP} from '../../../../../entities/configserp/jsonConfigERP.entity.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

const CONFIG_TYPE = 'pca';

@Component({
  selector: 'module-configs-erp-pca',
  templateUrl: './configs.pca.module.component.html'
})
export class ConfigsErpPCAModuleComponent extends ConfigsErpBaseModuleComponent implements OnInit {
  public readonly optionsPeriodo: Array<IConfigsPCAPeriodoItem>;

  public dataGridConfigPCA: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.optionsPeriodo = [
      {value: EConfigsPCAPeriodo.Todos, name: 'configsErp.groups.pca.optionsPeriodo.todos'},
      {value: EConfigsPCAPeriodo.AtePeriodoAtivoEmpresa, name: 'configsErp.groups.pca.optionsPeriodo.atePeriodoAtivoEmpresa'},
      {value: EConfigsPCAPeriodo.AteUltimoPeriodoEncerrado, name: 'configsErp.groups.pca.optionsPeriodo.ateUltimoPeriodoEncerrado'}
    ];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.dataGridConfigPCA = {
      columns: [
        {dataField: 'description', caption: 'entity.detail.field', dataType: 'string', allowEditing: false},
        {caption: 'entity.detail.value', dataType: 'string', editCellTemplate: 'cellTemplateValues', showEditorAlways: true, allowEditing: true}
      ],
      allowColumnReordering: false,
      allowColumnResizing: true,
      columnAutoWidth: true,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      editing: {mode: 'cell', allowUpdating: true},
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {allowColumnDragging: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      remoteOperations: false,
      searchPanel: {visible: true},
      showBorders: true,
      showColumnLines: true,
      sorting: {mode: 'none'}
    };
  }

  public readonly fnOnValidateReport = (): Promise<IJsonConfigERP> => this._onValidateReport();

  public get depthLevel(): EConfigsErpDepth {
    return EConfigsErpDepth.Single;
  }

  protected _parseList(rawConfigurations: Array<IJsonConfigERP>): Array<IConfigsErpEditConfigERP> {
    return rawConfigurations
      .filter((item) => item.name.startsWith(`${CONFIG_TYPE}.`))
      .map<IConfigsErpEditConfigERP>((item: IJsonConfigERP, index: number) => {
        if (item.name === 'pca.reportrecibosal') {
          item.cguid = 'reports_recibossal';
        }
        const transformedItem: IConfigsErpEditConfigERP = {...item, saveField: this.fnSaveField, index: index};
        return transformedItem;
      });
  }

  private _onValidateReport(): Promise<IJsonConfigERP> {
    const object: IConfigsErpEditConfigERP = <IConfigsErpEditConfigERP>this.configList.find((item: IConfigsErpEditConfigERP) => item.name === 'pca.reportrecibosal');
    return this.saveField(object);
  }
}
