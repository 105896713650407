<div class="mapa-pagamentos-enviados-prompt-modal">
  <div class="modal-header">
    <h5 class="modal-title" [translate]="'global.text.confirmation'"></h5>
  </div>

  <div class="modal-body">
    <div class="mb-2"><span [translate]="'mapapagam.texts.selecioneOQuePertendeApagar'"></span>:</div>
    <pl-group>
      <edit>
        <pl-edit type="radiogroup" attrName="estado" [(model)]="deleteMethod" [properties]="deleteMethodProperties"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></button>
    <button type="button" class="btn btn-sm btn-danger" [click]="fnApagar" plPromise><i class="fa fa-fw fa-check"></i><span [translate]="'mapapagam.texts.confirmar'"></span></button>
  </div>
</div>
