export enum EClassificadorArtigoTemplate {
  TipoArtigo = 1,
  GrandeFamilia,
  Familia,
  SubFamilia
}

export enum EFACCBStatus {
  EmAberto,
  Terminado,
  TerminadoComErro
}
