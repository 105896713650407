import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlAnimationCollapseModule} from '../../animation/collapse/animation.collapse.module';
import {PlEditModule} from '../../edit/edit.module';
import {PlNavPillModule} from '../../navpill/navpill.module';
import {PlPromisesModule} from '../../promises/promises.module';
import {PlTabsModule} from '../../tabs/tabs.module';
import {PlTranslateModule} from '../../translate/translate.module';

import {PlCookiesConsentComponent} from './cookiesconsent.component';
import {PlCookiesConsentHeaderDirective} from './cookiesconsent.header.directive';
import {PlCookiesConsentAboutDirective} from './cookiesconsent.about.directive';

export * from './cookiesconsent.service';
export * from './cookiesconsent.interface';
export * from './cookiesconsent.component';
export * from './cookiesconsent.header.directive';
export * from './cookiesconsent.about.directive';

const DECLARATIONS = [
  PlCookiesConsentComponent,
  PlCookiesConsentHeaderDirective,
  PlCookiesConsentAboutDirective
];

@NgModule({
  imports: [
    CommonModule,
    PlAnimationCollapseModule,
    PlEditModule,
    PlNavPillModule,
    PlPromisesModule,
    PlTabsModule,
    PlTranslateModule
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlCookiesConsentModule {
}
