import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TDate} from '../../../../common/dates';
import {GESTAO_COMUNICACAO_COLOR_PERCENT, lightenColor} from '../../../modules/portalcontabilidade/gestaocomunicacoes/gestaoComunicacoes.module.interface';
import {MIN_DATE_CG, MIN_DATE_CGD} from '../../../../common/utils/utils';

@Component({
  selector: 'cg-sortable-card',
  templateUrl: './sortableCard.component.html'
})
export class CGSortableCardComponent implements OnInit {
  @Input() public topicoId: string;
  @Input() public categoria: string;
  @Input() public categoriaCor: string;
  @Input() public name: string;
  @Input() public subTitle: string;
  @Input() public author: string;
  @Input() public date: TDate;
  @Input() public numberUnreadMessages: number;
  @Output() public readonly evtOpenId: EventEmitter<string>;
  @Output() public readonly evtDeleteId: EventEmitter<string>;

  public categoryBackgroundColor: string;

  constructor() {
    this.evtOpenId = new EventEmitter<string>();
    this.evtDeleteId = new EventEmitter<string>();
  }

  public ngOnInit(): void {
    this.categoryBackgroundColor = lightenColor(this.categoriaCor, GESTAO_COMUNICACAO_COLOR_PERCENT);
    if (this.date === MIN_DATE_CGD || this.date === MIN_DATE_CG || !this.date) {
      this.date = undefined;
    }
  }

  public onClickOpenDropdownActions(event: MouseEvent): void {
    event.stopPropagation();
  }

  public onClickOpenTopico(event: MouseEvent, openId: string): void {
    event.stopPropagation();
    this.evtOpenId.emit(openId);
  }

  public onClickDeleteTopico(event: MouseEvent, deleteId: string): void {
    event.stopPropagation();
    this.evtDeleteId.emit(deleteId);
  }
}
