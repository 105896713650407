<div class="cg-panel-slider" *ngIf="showContent" [@slide]="{value: collapsedValue, params: {duration: paramDuration}}">
  <div class="cg-panel-slider-header">
    <div class="cg-panel-slider-header-slider">
      <div class="slider" (swipedown)="close()"></div>
    </div>
    <button type="button" class="btn-close" *ngIf="closeable" (click)="close()"></button>
  </div>

  <div class="cg-panel-slider-content">
    <ng-container *ngTemplateOutlet="templateContent?.templateRef"></ng-container>
  </div>
</div>
