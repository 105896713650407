import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {KEYCODES} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ERendiTipoDeclaracao} from '../../../../../entities/rendis/jsonRendi.entity.interface';
import {IJsonModelo10, IJsonModelo10Quadro5Item} from '../../jsonModelo10.module.interface';
import {IQuadro5} from '../../modelo10.module.interface';
import {Modelo10Service} from '../../modelo10.module.service';

@Component({
  selector: 'modal-modelo10-retencaomod10',
  templateUrl: './modelo10.retencaoMod10.modal.component.html'
})
export class Modelo10RetencaoMod10ModalComponent extends CGModalComponent<IJsonModelo10> implements OnInit {
  @Input() public itemQuadro5: IQuadro5;
  @Input() public modelo10: IJsonModelo10;

  public readonly filterTiposRendimento: string;

  public isNewLine: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo10Service: Modelo10Service
  ) {
    super(_injector);
    this.filterTiposRendimento = `tipoDeclaracao=${ERendiTipoDeclaracao.Modelo10}`;
    this.isNewLine = false;
  }

  public ngOnInit(): void {
    if (!this.itemQuadro5) {
      this.itemQuadro5 = {
        nif: undefined,
        rendimentosAnterioresValores: undefined,
        rendimentosAnterioresNAnos: undefined,
        rendimentosAnterioresAno: undefined,
        rendimentosDoAno: undefined,
        tipoRendimentos: '',
        localObtencao: 'C',
        retencoesIRSIRC: undefined,
        contribuicoes: undefined,
        quotizacoes: undefined,
        retencaoSobretaxa: undefined,
        nifValido: undefined,
        _isManuallyAdded: true
      };
      this.isNewLine = true;
    }
  }

  public keydownAnoAnt(value: string, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      const button: HTMLElement = document.querySelector('#saveNewLine button');
      event.stopPropagation();
      if (button) {
        setTimeout(() => {
          button.focus();
        });
      }
    }
  }

  public close(): void {
    const indexLine = this.isNewLine ? 0 : this.itemQuadro5._index - 1;
    const modelo10Quadro5Item: IJsonModelo10Quadro5Item = {
      modelo10: this.modelo10,
      quadro5Item: this.itemQuadro5
    };
    this._modelo10Service.quadro05AddEditLine(modelo10Quadro5Item, this.isNewLine, indexLine).then((response: HttpResponse<IJsonModelo10>) => {
      const index = response.body.quadro5.findIndex(
        (item: IQuadro5) =>
          item.nif === this.itemQuadro5.nif &&
          item.tipoRendimentos === this.itemQuadro5.tipoRendimentos &&
          item.localObtencao === this.itemQuadro5.localObtencao &&
          response.body.quadro3Campo3 === this.modelo10.quadro3Campo3
      );
      if (index !== -1) {
        response.body.quadro5[index]._isManuallyAdded = this.itemQuadro5._isManuallyAdded;
      }
      super.close(response.body);
    });
  }
}
