import {merge} from 'lodash-es';
import {Inject, Injectable, Type} from '@angular/core';
import type {IPlEditRegisteredComponent, IPlEditRegisteredMap} from './edit.interface';
import {CGC_EDIT_COMPONENTS, CGC_EDIT_MAPS} from './edit.di';
import type {IPlEdit, IPlEditBaseComponent, IPlEditDefinition, IPlEditMapDefinition, IPlEditMapRegistry, IPlEditRegistry, IPlEditRegistryOptions} from './component/edit.component.interface';
import {Logger} from '../logger/logger';
import {normalizeInjectedValue} from '../common/utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class PlEditRegistryService {
  private readonly _edits: IPlEditRegistry;
  private readonly _maps: IPlEditMapRegistry;

  constructor(
    @Inject(CGC_EDIT_COMPONENTS) private readonly _registeredComponents: Array<IPlEditRegisteredComponent | Array<IPlEditRegisteredComponent>>,
    @Inject(CGC_EDIT_MAPS) private readonly _registeredMaps: Array<IPlEditRegisteredMap | Array<IPlEditRegisteredMap>>,
    private readonly _logger: Logger
  ) {
    this._edits = new Map<string, IPlEditDefinition>();
    this._maps = new Map<string, IPlEditMapDefinition>();
    const registeredComponents: Array<IPlEditRegisteredComponent> = normalizeInjectedValue<IPlEditRegisteredComponent>(this._registeredComponents);
    for (const {name, component, defaultProperties} of registeredComponents) {
      this.register(name, component, defaultProperties);
    }
    const registeredMaps: Array<IPlEditRegisteredMap> = normalizeInjectedValue<IPlEditRegisteredMap>(this._registeredMaps);
    for (const {target, alias, defaultProperties} of registeredMaps) {
      this.map(target, alias, defaultProperties);
    }
  }

  public register(name: string, component: Type<IPlEditBaseComponent<any>>, defaultProperties?: IPlEditRegistryOptions): this {
    this._edits.set(
      name,
      Object.freeze({
        name: name,
        component: component,
        defaultOptions: defaultProperties ? Object.freeze<IPlEditRegistryOptions>(defaultProperties) : undefined
      })
    );
    return this;
  }

  public map(target: string, alias: string, defaultProperties?: object | any): this {
    if (!target) {
      this._logger.error(`Invalid target [${target}].`);
      return this;
    }
    if (!alias) {
      this._logger.error(`Invalid alias [${target}].`);
      return this;
    }
    let mapDefinition: IPlEditMapDefinition;
    const existingEdit: IPlEditDefinition = this._edits.get(target);
    if (existingEdit) {
      mapDefinition = {
        name: alias,
        target: target,
        path: Object.freeze([target, alias]),
        defaultOptions: Object.freeze<IPlEditRegistryOptions>(merge({}, existingEdit.defaultOptions, defaultProperties))
      };
    } else {
      const existingMap: IPlEditMapDefinition = this._maps.get(target);
      if (!existingMap) {
        this._logger.error(`The mapping target [${target}] is not registered`);
        return this;
      }
      mapDefinition = {
        name: alias,
        target: existingMap.target,
        path: Object.freeze([...existingMap.path, alias]),
        defaultOptions: Object.freeze<IPlEditRegistryOptions>(merge({}, existingMap.defaultOptions, defaultProperties))
      };
    }
    this._maps.set(alias, mapDefinition);
    return this;
  }

  public get(nameOrAlias: string): IPlEdit {
    if (!nameOrAlias) {
      this._logger.warn(`Edit type [${nameOrAlias}] is invalid`);
      return undefined;
    }
    let edit: IPlEditDefinition = this._getEdit(nameOrAlias);
    let map: IPlEditMapDefinition;
    if (!edit) {
      const mapDefinition: IPlEditMapDefinition = this._maps.get(nameOrAlias);
      if (!mapDefinition) {
        this._logger.warn(`Edit type [${nameOrAlias}] was not found, using the default type (text) instead.`);
        edit = this._getEdit('text');
      } else {
        edit = {...this._getEdit(mapDefinition.target), defaultOptions: mapDefinition.defaultOptions};
        map = mapDefinition;
      }
    }
    return {...edit, map: map};
  }

  public getEditNameFromAlias(alias: string): string {
    const plEditDefinition: IPlEditMapDefinition = this._maps.get(alias);
    return plEditDefinition ? plEditDefinition.target : undefined;
  }

  public getAll(): IPlEditRegistry {
    return this._edits;
  }

  public getMaps(): IPlEditMapRegistry {
    return this._maps;
  }

  private _getEdit(name: string): IPlEditDefinition {
    return this._edits.get(name);
  }
}
