import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EImportadorSaftDirectCorrespContasAtribuirFieldEnum, IImportadorSaftDirectCorrespContasAtribuirModel} from '../../importadorSaftDirect.module.interface';

@Component({
  selector: 'importador-saft-direct-cc-atribuir-modal',
  templateUrl: './importadorSaftDirect.correspContas.atribuir.modal.component.html'
})
export class ImportadorSaftDirectCorrespContasAtribuirModalComponent extends CGModalComponent<IImportadorSaftDirectCorrespContasAtribuirModel> {
  @Input() public field: EImportadorSaftDirectCorrespContasAtribuirFieldEnum;
  public model: IImportadorSaftDirectCorrespContasAtribuirModel;
  public fieldType: typeof EImportadorSaftDirectCorrespContasAtribuirFieldEnum;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.fieldType = EImportadorSaftDirectCorrespContasAtribuirFieldEnum;
    this.model = {
      nCCusto: undefined,
      nCodRepCC: undefined
    };
  }
}
