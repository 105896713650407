import {RecibosVencimentoComponent} from './components/recibosVencimento.module.component';
import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';

export const MODULE_RECIBOS_VENCIMENTO: IModuleDefinition = {
  name: 'colaboradoresrecibosvencimento',
  state: {
    url: '/recibosvencimento',
    component: RecibosVencimentoComponent,
    data: {
      roles: [ROLE.COLABORADOR],
      icon: 'fa-list-alt',
      pageTitle: 'global.menu.colaboradoresrecibosvencimento'
    }
  }
};
