<div class="contabilidadedigital-genericviewer contabilidadedigital-treeviewer" [ngSwitch]="loading || !!promise">
  <div class="contabilidadedigital-genericviewer-loading contabilidadedigital-treeviewer-nodes-loading" *ngSwitchCase="true">
    <i class="fa fa-spin fa-circle-o-notch" aria-hidden="true"></i>
  </div>

  <div class="contabilidadedigital-treeviewer-nodes" *ngSwitchCase="false">
    <contabilidadedigital-treeviewer-node
      *ngFor="let folderItem of root?.folders"
      [folderItem]="folderItem"
      [selectedFolder]="selectedFolder"
      [selectedFile]="selectedFile"
      [showFiles]="showFiles"
      [showCountFolderFiles]="showCountFolderFiles"
      [advancedDescription]="advancedDescription"
      [mobile]="mobile"
      [disableActions]="disableActions"
      [disableActionAddDoc]="disableActionAddDoc"
      [disableActionCaptureDoc]="disableActionCaptureDoc"
      [disableActionDeleteDocs]="disableActionDeleteDocs"
      [disableActionDeleteDoc]="disableActionDeleteDoc"
      (evtSelectedFolder)="selectFolder($event)"
      (evtFolderCollapseChanged)="changedFolderItemCollapse($event)"
      (evtSelectedFile)="selectFile($event)"
      (evtFolderDoubleClicked)="folderDoubleClicked($event)"
      (evtFileDoubleClicked)="fileDoubleClicked($event)"
      (evtActionAddDoc)="onActionAdd()"
      (evtActionCaptureDoc)="onActionCapture()"
      (evtActionDeleteDocs)="onActionDeleteDocs()"
      (evtActionDeleteDoc)="onActionDeleteDoc()">
    </contabilidadedigital-treeviewer-node>
  </div>
</div>
