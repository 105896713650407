/* eslint-disable @typescript-eslint/naming-convention */

export interface IPlLocale {
  readonly autocomplete: IPlLocaleAutocomplete;
  readonly btn: IPlLocaleBtn;
  readonly calendar: IPlLocaleCalendar;
  readonly datepicker: IPlLocaleDatepicker;
  readonly mimeTypes: IPlLocaleMimeTypes;
  readonly months: IPlLocaleMonths;
  readonly placeholders: IPlLocalePlaceholders;
  readonly plColorPicker: IPlLocaleColorPicker;
  readonly plCookiesConsent: IPlLocaleCookiesConsent;
  readonly plDashboard: IPlLocaleDashboard;
  readonly plMediaDevicesCameraCaptureImage: IPlLocaleMediaDevicesCameraCaptureImage;
  readonly plMultiSelect: IPlLocaleMultiselect;
  readonly plNavbar: IPlLocaleNavbar;
  readonly plPdf: IPlLocalePdf;
  readonly plSelect: IPlLocaleSelect;
  readonly plSideBar: IPlLocaleSidebar;
  readonly text: IPlLocaleText;
  readonly uploader: IPlLocaleUploader;
  readonly validators: IPlLocaleValidators;
  readonly weekdays: IPlLocaleWeekdays;
}

export interface IPlLocaleMonths {
  readonly january: string;
  readonly february: string;
  readonly march: string;
  readonly april: string;
  readonly may: string;
  readonly june: string;
  readonly july: string;
  readonly august: string;
  readonly september: string;
  readonly october: string;
  readonly november: string;
  readonly december: string;
}

export interface IPlLocaleWeekdays {
  readonly sunday: string;
  readonly monday: string;
  readonly tuesday: string;
  readonly wednesday: string;
  readonly thursday: string;
  readonly friday: string;
  readonly saturday: string;
}

export interface IPlLocaleAutocomplete {
  readonly textSearching: string;
  readonly textEmpty: string;
  readonly textNotFound: string;
}

export interface IPlLocaleCalendar {
  readonly views: IPlLocaleCalendarViews;
  readonly detailsBar: IPlLocaleCalendarDetailsBar;
  readonly sidebar: IPlLocaleCalendarSidebar;
}

export interface IPlLocaleCalendarViews {
  readonly month: IPlLocaleCalendarMonthView;
  readonly year: IPlLocaleCalendarYearView;
}

export interface IPlLocaleCalendarMonthView {
  readonly items: string;
}

export interface IPlLocaleCalendarYearView {
  readonly months: string;
}

export interface IPlLocaleCalendarDetailsBar {
  readonly simultaneousEvents: string;
}

export interface IPlLocaleCalendarSidebar {
  readonly filter: string;
}

export interface IPlLocaleDatepicker {
  readonly nextCentury: string;
  readonly nextDecade: string;
  readonly nextMillennium: string;
  readonly nextMonth: string;
  readonly nextYear: string;
  readonly previousCentury: string;
  readonly previousDecade: string;
  readonly previousMillennium: string;
  readonly previousMonth: string;
  readonly previousYear: string;
  readonly toggleContext: string;
  readonly validators: IPlLocaleDatepickerValidators;
}

export interface IPlLocaleDatepickerValidators {
  readonly minimumDate: string;
  readonly maximumDate: string;
}

export interface IPlLocalePlaceholders {
  readonly search: string;
  readonly tableGroupBy: string;
}

export interface IPlLocaleBtn {
  readonly goBack: string;
  readonly goForward: string;
  readonly finalize: string;
  readonly submit: string;
  readonly search: string;
  readonly ok: string;
  readonly cancel: string;
  readonly close: string;
  readonly apply: string;
  readonly addFilter: string;
}

export interface IPlLocaleText {
  readonly today: string;
  readonly day: string;
  readonly days: string;
  readonly week: string;
  readonly weeks: string;
  readonly month: string;
  readonly months: string;
  readonly year: string;
  readonly years: string;
  readonly weekend: string;
  readonly weekends: string;
  readonly lines: string;
  readonly close: string;
  readonly now: string;
  readonly clear: string;
  readonly open: string;
  readonly initialize: string;
  readonly noFilters: string;
  readonly activeFilters: string;
  readonly clearFilters: string;
  readonly true: string;
  readonly false: string;
  readonly and: string;
  readonly or: string;
  readonly of: string;
}

export interface IPlLocaleValidators {
  readonly date: string;
  readonly datetimelocal: string;
  readonly email: string;
  readonly equals: string;
  readonly max: string;
  readonly maxlength: string;
  readonly maxSelected: string;
  readonly min: string;
  readonly minlength: string;
  readonly minSelected: string;
  readonly month: string;
  readonly number: string;
  readonly pattern: string;
  readonly required: string;
  readonly time: string;
  readonly url: string;
  readonly week: string;
}

export interface IPlLocaleUploader {
  readonly defaultMessage: string;
  readonly fileTooBig: string;
  readonly invalidFileType: string;
  readonly cancelUpload: string;
  readonly uploadCanceled: string;
  readonly removeAllFiles: string;
  readonly removeFile: string;
  readonly maxFileExceeded: string;
  readonly maxFilesExceeded: string;
  readonly acceptedFileType: string;
  readonly acceptedFileTypes: string;
  readonly maximumFileSize: string;
  readonly maximumPerFileSize: string;
  readonly dropHere: string;
  readonly clickToUpload: string;
  readonly files: string;
  readonly uploadAll: string;
  readonly upload: string;
  readonly rejected: string;
  readonly retry: string;
  readonly uploadSuccess: string;
  readonly uploadError: string;
  readonly units: IPlLocaleUploaderUnits;
}

export interface IPlLocaleUploaderUnits {
  readonly b: string;
  readonly kb: string;
  readonly mb: string;
  readonly gb: string;
  readonly tb: string;
  readonly pb: string;
  readonly eb: string;
  readonly zb: string;
  readonly yb: string;
}

export interface IPlLocaleMultiselect {
  readonly available: string;
  readonly selected: string;
}

export interface IPlLocaleNavbar {
  readonly toggleMenu: string;
}

export interface IPlLocalePdf {
  readonly loadError: string;
  readonly noDocument: string;
  readonly renderNotSupported: string;
  readonly pages: string;
  readonly zoomAuto: string;
  readonly zoomPageActual: string;
  readonly zoomPageFit: string;
  readonly zoomPageWidth: string;
  readonly zoomIn: string;
  readonly zoomOut: string;
  readonly presentationMode: string;
  readonly download: string;
  readonly print: string;
  readonly loading: string;
}

export interface IPlLocaleSelect {
  readonly clearAllText: string;
  readonly loadingText: string;
  readonly notFoundText: string;
}

export interface IPlLocaleSidebar {
  readonly toggleSideBar: string;
}

export interface IPlLocaleColorPicker {
  readonly presetLabel: string;
  readonly okButtonText: string;
  readonly cancelButtonText: string;
  readonly addColorButtonText: string;
  readonly presetEmptyMessage: string;
}

export interface IPlLocaleCookiesConsent {
  readonly header: string;
  readonly footer: string;
  readonly necessaryTitle: string;
  readonly necessaryDescription: string;
  readonly preferencesTitle: string;
  readonly preferencesDescription: string;
  readonly statisticsTitle: string;
  readonly statisticsDescription: string;
  readonly marketingTitle: string;
  readonly marketingDescription: string;
  readonly unclassifiedTitle: string;
  readonly unclassifiedDescription: string;
  readonly showDetails: string;
  readonly hideDetails: string;
  readonly ok: string;
  readonly declaration: string;
  readonly about: string;
  readonly tableHeaderName: string;
  readonly tableHeaderProvider: string;
  readonly tableHeaderPurpose: string;
  readonly tableHeaderExpiresOn: string;
  readonly tableHeaderType: string;
  readonly session: string;
  readonly persistent: string;
  readonly emptyGroup: string;
  readonly invalidExpireTime: string;
}

export interface IPlLocaleDashboard {
  readonly emptyData: string;
  readonly total: string;
}

export interface IPlLocaleMediaDevicesCameraCaptureImage {
  readonly imageContentAlt: string;
  readonly titleChange: string;
  readonly titleTabUpload: string;
  readonly titleTabCapture: string;
  readonly textChange: string;
  readonly textRemove: string;
  readonly textDisabledTabCapture: string;
  readonly textRetry: string;
  readonly textCapture: string;
  readonly textPause: string;
  readonly textResume: string;
  readonly errorAbortError: string;
  readonly errorNotAllowedError: string;
  readonly errorNotFoundError: string;
  readonly errorNotReadableError: string;
  readonly errorOverconstrainedError: string;
  readonly errorSecurityError: string;
  readonly errorTypeError: string;
  readonly errorTimedOutError: string;
  readonly errorUnavailableMediaDevices: string;
}

export interface IPlLocaleMimeTypes {
  readonly 'application/epub+zip': string;
  readonly 'application/gzip': string;
  readonly 'application/java-archive': string;
  readonly 'application/json': string;
  readonly 'application/ld+json': string;
  readonly 'application/msword': string;
  readonly 'application/octet-stream': string;
  readonly 'application/ogg': string;
  readonly 'application/pdf': string;
  readonly 'application/php': string;
  readonly 'application/rtf': string;
  readonly 'application/vnd.amazon.ebook': string;
  readonly 'application/vnd.apple.installer+xml': string;
  readonly 'application/vnd.mozilla.xul+xml': string;
  readonly 'application/vnd.ms-excel': string;
  readonly 'application/vnd.ms-fontobject': string;
  readonly 'application/vnd.ms-powerpoint': string;
  readonly 'application/vnd.oasis.opendocument.presentation': string;
  readonly 'application/vnd.oasis.opendocument.spreadsheet': string;
  readonly 'application/vnd.oasis.opendocument.text': string;
  readonly 'application/vnd.openxmlformats-officedocument.presentationml.presentation': string;
  readonly 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': string;
  readonly 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': string;
  readonly 'application/vnd.visio': string;
  readonly 'application/x-7z-compressed': string;
  readonly 'application/x-abiword': string;
  readonly 'application/x-bzip': string;
  readonly 'application/x-bzip2': string;
  readonly 'application/x-csh': string;
  readonly 'application/x-freearc': string;
  readonly 'application/x-rar-compressed': string;
  readonly 'application/x-sh': string;
  readonly 'application/x-shockwave-flash': string;
  readonly 'application/x-tar': string;
  readonly 'application/xhtml+xml': string;
  readonly 'application/zip': string;
  readonly 'audio/aac': string;
  readonly 'audio/midi': string;
  readonly 'audio/mpeg': string;
  readonly 'audio/ogg': string;
  readonly 'audio/wav': string;
  readonly 'audio/webm': string;
  readonly 'font/otf': string;
  readonly 'font/ttf': string;
  readonly 'font/woff': string;
  readonly 'font/woff2': string;
  readonly 'image/*': string;
  readonly 'image/bmp': string;
  readonly 'image/gif': string;
  readonly 'image/jpeg': string;
  readonly 'image/png': string;
  readonly 'image/svg+xml': string;
  readonly 'image/tiff': string;
  readonly 'image/vnd.microsoft.icon': string;
  readonly 'image/webp': string;
  readonly 'image/x-icon': string;
  readonly 'text/calendar': string;
  readonly 'text/css': string;
  readonly 'text/csv': string;
  readonly 'text/html': string;
  readonly 'text/javascript': string;
  readonly 'text/plain': string;
  readonly 'text/xml': string;
  readonly 'video/3gpp': string;
  readonly 'video/3gpp2': string;
  readonly 'video/mp2t': string;
  readonly 'video/mpeg': string;
  readonly 'video/ogg': string;
  readonly 'video/webm': string;
  readonly 'video/x-msvideo': string;
}

/* eslint-enable @typescript-eslint/naming-convention */
