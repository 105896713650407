import {Component, Injector, Input} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {ContabilidadeDigitalService} from '../../../../services/contabilidadedigital/contabilidadedigital.service';
import {IJsonDocDigitalizado, IJsonDocOCRCab} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'contabilidadedigital-unir-docs-modal',
  templateUrl: './contabilidadedigital.unirdocs.modal.component.html'
})
export class ContabilidadeDigitalUnirDocsModalComponent extends CGModalComponent<IJsonDocDigitalizado> {
  @Input() public docPrincipal: IJsonDocOCRCab;
  @Input() public docsAUnir: Array<IJsonDocOCRCab>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService
  ) {
    super(_injector);
  }

  public close(): Promise<void> {
    const docsAUnir: Array<string> = this.docsAUnir.map((docOCRCab: IJsonDocOCRCab) => docOCRCab.docID);
    return this._contabilidadeDigitalService.documents.unirDocs(this.docPrincipal.docID, docsAUnir).then((response: HttpResponse<IJsonDocDigitalizado>) => {
      this._plAlertService.success('arquivodigital.unirdocsmodal.success');
      super.close(response.body);
    });
  }
}
