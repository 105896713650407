import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {copy, IPlValidator, isObject, PlAlertService} from 'pl-comps-angular';
import {IJsonProcessamentoSalariosConfig} from '../../jsonRHConfiguracoes.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RHConfiguracoesService} from '../../rhConfiguracoes.module.service';

const VALUE_FIFTY = 50;

@Component({
  selector: 'module-rhprocessamentosalarios',
  templateUrl: './rhProcessamentoSalarios.module.component.html'
})
export class RHProcessamentoSalariosModuleComponent extends ModuloComponent implements OnInit {
  @Input() public processamentosalariosconfig: IJsonProcessamentoSalariosConfig;

  public readonly percentagemEmSubFerias: IPlValidator<string, number>;
  public readonly percentagemEmSubNatal: IPlValidator<string, number>;
  public promise: Promise<void>;
  public processamentosalariosconfigOriginal: IJsonProcessamentoSalariosConfig;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhConfiguracoesService: RHConfiguracoesService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.percentagemEmSubFerias = {
      message: 'rhprocessamentosalariosconfig.errors.invalidpercentage',
      validate: this._validatePercentagemEmSub.bind(this)
    };
    this.percentagemEmSubNatal = {
      message: 'rhprocessamentosalariosconfig.errors.invalidpercentage',
      validate: this._validatePercentagemEmSub.bind(this)
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.processamentosalariosconfigOriginal = copy<IJsonProcessamentoSalariosConfig>(this.processamentosalariosconfig);
    this.btnSave.visible = true;
    this.btnSave.click = this._onSave.bind(this);
    this.btnCancel.visible = true;
    this.btnCancel.click = this._onCancel.bind(this);
  }

  private _onSave(): Promise<void> {
    const validPercFerias = this._validatePercentagemEmSub(this.processamentosalariosconfig.processamentoSalariosDuodecimos.percentagemEmSubFerias);
    if (!validPercFerias) {
      this._plAlertService.error('rhprocessamentosalariosconfig.errors.verificarFerias');
      return Promise.resolve();
    }
    const validPercNatal = this._validatePercentagemEmSub(this.processamentosalariosconfig.processamentoSalariosDuodecimos.percentagemEmSubNatal);
    if (!validPercNatal) {
      this._plAlertService.error('rhprocessamentosalariosconfig.errors.verificarNatal');
      return Promise.resolve();
    }
    this.promise = this._rhConfiguracoesService.updateProcessamentoSalarios(this.processamentosalariosconfig).then((response: HttpResponse<IJsonProcessamentoSalariosConfig>) => {
      this.processamentosalariosconfig = response.body;
      this.processamentosalariosconfigOriginal = copy<IJsonProcessamentoSalariosConfig>(this.processamentosalariosconfigOriginal);
      this._plAlertService.success('rhmanutencao.message.savedsuccessfully');
    });
    return this.promise;
  }

  private _onCancel(): void {
    this.processamentosalariosconfig = copy<IJsonProcessamentoSalariosConfig>(this.processamentosalariosconfigOriginal);
  }

  private _validatePercentagemEmSub(modelValue): boolean {
    const value = isObject(modelValue) ? modelValue.modelValue : modelValue;
    return value === 0 || value === VALUE_FIFTY || value === 100;
  }
}
