import moment, {Moment} from 'moment';

export enum ERefeicoesSchedulerMode {
  Mark,
  View
}

export function refeicoesSchedulerDefaultViewDate(): Moment {
  return moment().startOf('month');
}
