import {TDate} from '../../../../common/dates';

export interface IRhMapasSindicatosFilters {
  dataDe: TDate;
  dataAte: TDate;
  deSindicato: number;
  ateSindicato: number;
}

export const MODULE_NAME_RH_MAPAS_SINDICATOS = 'lsindic';
