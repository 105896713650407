import moment, {Duration, Moment, MomentInput, unitOfTime} from 'moment';
import {TranslateService} from '@ngx-translate/core';
import {IPlSchedulerDatasetItem, isFunction, momentDurationFormat} from 'pl-comps-angular';
import {ECalendarioTipoDiaStr} from '../../entities/calendarios/jsonCalendarios.entity.interface';
import {EGestaoDGEMPSClasses, EGestaoDGEMPSOutlineClasses, EGestaoDGEMPSType, EGestaoDGEMPSView, IGestaoDGEMPSEvent, IGestaoDGEMPSEventStatus, IGestaoDGEMPSPRHServico} from './gestaodgemps.interface';
import {EPRHFluxoIndice, EPRHFluxoIndiceTipo, IJsonPRHFluxo} from '../../entities/prhfluxos/jsonPRHFluxo.entity.interface';
import {IJsonDGEMPCalendario, IJsonDGEMPFerias} from '../../entities/dgemps/jsonDGEMP.entity.interface';
import {IPRHMarcacoes} from '../../interfaces/jsonPRHMarcacoes.interface';
import {momentAllDay, normalizeDate} from '../../../common/utils/moment.utils';

export function gestaoDGEMPSDefaultViewDate(): Moment {
  return normalizeDate(moment().startOf('month'));
}

export function gestaoDGEMPSViewUnit(view: EGestaoDGEMPSView): unitOfTime.Base {
  switch (view) {
    case EGestaoDGEMPSView.Year:
      return 'year';
    case EGestaoDGEMPSView.Month:
      return 'month';
    default:
      return 'year';
  }
}

export function gestaoDGEMPSTypeToPRHFluxo(type: EGestaoDGEMPSType): EPRHFluxoIndiceTipo {
  switch (type) {
    case EGestaoDGEMPSType.Abonos:
      return EPRHFluxoIndiceTipo.Abonos;
    case EGestaoDGEMPSType.Faltas:
      return EPRHFluxoIndiceTipo.Faltas;
    case EGestaoDGEMPSType.Ferias:
      return EPRHFluxoIndiceTipo.Ferias;
    default:
      return undefined;
  }
}

export function gestaoDGEMPSRangeHasValidDates(startDate: Moment, endDate: Moment, calendarioColaborador: Array<IJsonDGEMPCalendario>): boolean {
  for (const dataAtual = startDate.clone(); dataAtual.diff(endDate, 'days') <= 0; dataAtual.add(1, 'day')) {
    const diaCalendario: IJsonDGEMPCalendario = calendarioColaborador.find((day: IJsonDGEMPCalendario) => dataAtual.isSame(day.data, 'day'));
    if (!diaCalendario || diaCalendario.tipoDiaStr === ECalendarioTipoDiaStr.DiaUtil) {
      return true;
    }
  }
  return false;
}

export function gestaoDGEMPSAllDay(horaInicio: MomentInput, horaFim: MomentInput): boolean {
  if ((horaInicio || horaInicio === 0) && (horaFim || horaFim === 0)) {
    return momentAllDay(horaInicio, horaFim);
  }
  return false;
}

export function gestaoDGEMPSMarcarHoraInicio(): Moment {
  return moment().set({hours: 9, minutes: 0, seconds: 0, milliseconds: 0});
}

export function gestaoDGEMPSMarcarHoraFim(): Moment {
  return moment().set({hours: 18, minutes: 0, seconds: 0, milliseconds: 0});
}

const TODAY: Moment = moment();

export function gestaoDGEMPSEvaluateEventStatus(
  type: EGestaoDGEMPSType,
  fluxos: Array<IJsonPRHFluxo>,
  servicoEmpregado: IGestaoDGEMPSPRHServico,
  evento: IPRHMarcacoes,
  date: Moment,
  forceDeletable: boolean,
  conflito: boolean
): IGestaoDGEMPSEventStatus {
  const fluxoAtual = evento.cab.fluxo;
  let indiceMarcado: EPRHFluxoIndice;
  let indiceAprovado: EPRHFluxoIndice;
  let indiceIntegrado: EPRHFluxoIndice;
  let indiceRejeitado: EPRHFluxoIndice;
  switch (type) {
    case EGestaoDGEMPSType.Abonos:
      indiceMarcado = EPRHFluxoIndice.AbonosMarcar;
      indiceAprovado = EPRHFluxoIndice.AbonosAprovar;
      indiceIntegrado = EPRHFluxoIndice.AbonosAprovarIntegrar;
      indiceRejeitado = EPRHFluxoIndice.AbonosRejeitar;
      break;
    case EGestaoDGEMPSType.Faltas:
      indiceMarcado = EPRHFluxoIndice.FaltasMarcar;
      indiceAprovado = EPRHFluxoIndice.FaltasAprovar;
      indiceIntegrado = EPRHFluxoIndice.FaltasAprovarIntegrar;
      indiceRejeitado = EPRHFluxoIndice.FaltasRejeitar;
      break;
    case EGestaoDGEMPSType.Ferias:
      indiceMarcado = EPRHFluxoIndice.FeriasMarcar;
      indiceAprovado = EPRHFluxoIndice.FeriasAprovar;
      indiceIntegrado = EPRHFluxoIndice.FeriasAprovarIntegrar;
      indiceRejeitado = EPRHFluxoIndice.FeriasRejeitar;
      break;
  }
  const marcado: boolean = fluxoAtual.indice === indiceMarcado;
  const rejeitado: boolean = fluxoAtual.indice === indiceRejeitado;
  const integrado: boolean = fluxoAtual.indice === indiceIntegrado;

  const pendente: boolean = !rejeitado && !integrado;
  const allowApproveReject: boolean = pendente && servicoEmpregado.papeis.has(evento.proximoPapel);
  const rewindable: boolean = !marcado && !integrado && (servicoEmpregado.papeis.has(evento.cab.fluxo.codPapel) || rejeitado);

  let aprovado = true;
  let aprovadoParcialmente = false;
  for (const fluxo of fluxos) {
    if (fluxo.tipo !== fluxoAtual.tipo) {
      continue;
    }
    if (fluxo.posicao <= fluxoAtual.posicao) {
      if (!aprovadoParcialmente && fluxo.indice === indiceAprovado) {
        aprovadoParcialmente = true;
      }
    }
    if (fluxo.posicao > fluxoAtual.posicao && aprovado && fluxo.indice === indiceAprovado) {
      aprovado = false;
    }
  }
  if (aprovado && !aprovadoParcialmente) {
    aprovado = false;
  }

  return {
    marcado: marcado,
    aprovado: aprovado,
    aprovadoParcialmente: aprovadoParcialmente,
    rejeitado: fluxoAtual.indice === indiceRejeitado,
    integrado: integrado,
    pendente: pendente,
    gozado: type === EGestaoDGEMPSType.Ferias && integrado && date.isSameOrBefore(TODAY, 'minutes'),
    allowApproveReject: allowApproveReject,
    rewindable: rewindable,
    desmarcavel: forceDeletable || marcado,
    conflito: conflito
  };
}

export function gestaoDGEMPSEvaluateEventStatusIntegrado(type: EGestaoDGEMPSType, date: Moment, forceDeletable: boolean, conflito: boolean): IGestaoDGEMPSEventStatus {
  return {
    marcado: false,
    aprovado: false,
    aprovadoParcialmente: false,
    rejeitado: false,
    integrado: true,
    pendente: false,
    gozado: type === EGestaoDGEMPSType.Ferias && date.isSameOrBefore(TODAY, 'minutes'),
    allowApproveReject: false,
    rewindable: false,
    desmarcavel: forceDeletable,
    conflito: conflito
  };
}

export function gestaoDGEMPSEvaluateEventCSSClassOutline(eventStatus: IGestaoDGEMPSEventStatus): string {
  if (eventStatus.gozado) {
    return EGestaoDGEMPSOutlineClasses.Gozadas;
  }
  if (eventStatus.integrado) {
    return EGestaoDGEMPSOutlineClasses.Integradas;
  }
  if (eventStatus.rejeitado) {
    return EGestaoDGEMPSOutlineClasses.Rejeitadas;
  }
  if (eventStatus.aprovado) {
    return EGestaoDGEMPSOutlineClasses.Aprovadas;
  }
  if (eventStatus.aprovadoParcialmente) {
    return EGestaoDGEMPSOutlineClasses.AprovadasParcialmente;
  }
  if (eventStatus.pendente) {
    return EGestaoDGEMPSOutlineClasses.Marcadas;
  }
  return '';
}

export function gestaoDGEMPSEvaluateDayCSSClass(events: Array<IPlSchedulerDatasetItem<IGestaoDGEMPSEvent>>, callback?: (event: IPlSchedulerDatasetItem<IGestaoDGEMPSEvent>) => void): string {
  const validCallback: boolean = isFunction(callback);

  let hasAllowApproveReject = false;
  let hasMarcada = false;
  let hasAprovadasParcialmente = false;
  let hasAprovada = false;
  let hasIntegrada = false;
  let hasGozada = false;
  let hasRejeitada = false;
  let hasConflito = false;

  for (const event of events) {
    if (validCallback) {
      callback(event);
    }

    if (event.meta.status.allowApproveReject) {
      hasAllowApproveReject = true;
    }
    if (event.meta.status.marcado) {
      hasMarcada = true;
    }
    if (event.meta.status.aprovadoParcialmente) {
      hasAprovadasParcialmente = true;
    }
    if (event.meta.status.aprovado) {
      hasAprovada = true;
    }
    if (event.meta.status.integrado) {
      hasIntegrada = true;
      if (!hasGozada) {
        hasGozada = moment(event.meta.date).isSameOrBefore(TODAY, 'date');
      }
    }
    if (event.meta.status.rejeitado) {
      hasRejeitada = true;
    }
    if (event.meta.status.conflito) {
      hasConflito = true;
    }
  }

  if (hasAllowApproveReject) {
    if (hasMarcada) {
      return EGestaoDGEMPSClasses.Marcadas;
    }
    if (hasAprovadasParcialmente) {
      return EGestaoDGEMPSClasses.AprovadasParcialmente;
    }
    if (hasAprovada) {
      return EGestaoDGEMPSClasses.Aprovadas;
    }
  }

  if (hasConflito) {
    return EGestaoDGEMPSClasses.Conflito;
  }
  if (hasMarcada) {
    return EGestaoDGEMPSClasses.Marcadas;
  }
  if (hasRejeitada) {
    return EGestaoDGEMPSClasses.Rejeitadas;
  }
  if (hasAprovada) {
    return EGestaoDGEMPSClasses.Aprovadas;
  }
  if (hasAprovadasParcialmente) {
    return EGestaoDGEMPSClasses.AprovadasParcialmente;
  }
  if (hasGozada) {
    return EGestaoDGEMPSClasses.Gozadas;
  }
  if (hasIntegrada) {
    return EGestaoDGEMPSClasses.Integradas;
  }

  return '';
}

const SALDO_UNITS: ReadonlyArray<keyof Duration> = Object.freeze(['asDays', 'hours', 'minutes', 'seconds']);

export function gestaoDGEMPSPrettyFeriasMarcadas(translateService: TranslateService, ferias: IJsonDGEMPFerias): string {
  const hoursPerDay: number = moment.duration(ferias.horasDia, 'seconds').asHours();

  function prettyHours(hours: number): string {
    let days = 0;
    while (hours >= 1) {
      hours--;
      days++;
    }
    hours *= hoursPerDay;
    return momentDurationFormat(moment.duration({days: days, hours: hours}), SALDO_UNITS);
  }

  const prettySaldo: string = prettyHours(ferias.nDiasFeriasMarcadasAprovadas + ferias.nDiasSFeriasIntegradas);
  const prettyTotal: string = prettyHours(ferias.nDiasSFerias);

  return translateService.instant('gestaodgemps.text.prettyFeriasMarcadas', {
    marcadas: prettySaldo || '0',
    total: prettyTotal || '0'
  });
}
