import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonDistrito} from './jsonDistrito.entity.interface';
import {DATA_SOURCE_NAME_CONTINENTES} from '../../datasources/continentes/continentes.datasource.interface';
import {ENTITY_NAME_DISTRITOS} from './distritos.entity.interface';
import {ENTITY_NAME_PAISES} from '../paises/paises.entity.interface';

export const ENTITY_DISTRITOS: IEntityDefinition<IJsonDistrito> = {
  name: ENTITY_NAME_DISTRITOS,
  roles: [ROLE.CONTABILIDADE, ROLE.RH],
  searchPlaceholder: 'distritos.pesquisa',
  pageTitle: 'global.menu.distritos',
  metadata: {
    keyName: 'codDistrito',
    fields: [
      {name: 'codDistrito', type: 'cgsmallint', width: '120px', caption: 'distritos.fields.codDistrito', validators: {required: {value: true}}},
      {name: 'nomeDistrito', caption: 'distritos.fields.nomeDistrito', validators: {required: {value: true}}},
      {name: 'codContinente', type: DATA_SOURCE_NAME_CONTINENTES, caption: 'distritos.fields.localObtencaoRendimentos', validators: {required: {value: true}}},
      {name: 'codPais', visible: false},
      {
        name: 'nomePais',
        caption: 'distritos.fields.nomePais',
        validators: {required: {value: true}},
        entity: {name: ENTITY_NAME_PAISES, keyTarget: 'codPais', output: 'nomePais', outputTarget: 'nomePais'}
      }
    ],
    order: 'nomeDistrito',
    searchFields: 'codDistrito,nomeDistrito'
  },
  autocomplete: {
    rowTemplate: '{{codDistrito}} - {{nomeDistrito}}',
    output: 'nomeDistrito',
    searchFields: 'codDistrito,nomeDistrito'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  }
};
