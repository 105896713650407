import {TDate} from '../../../../common/dates';
import {ETipoAbonoDesconto} from '../../../entities/abdes/abdes.entity.interface';

export enum ETipoEntidadeCessaCont {
  Empregador,
  Trabalhador
}

export enum ETipoContratoTrab {
  TempoIndeterminado,
  TermoCerto,
  TermoIncerto
}

export enum ETipoResultadoFerias {
  AnoAnterior,
  AnoAtual
}

export interface IJsonRhCessaContratoTrabalhoForm {
  entidade: ETipoEntidadeCessaCont;
  tipoContrato: ETipoContratoTrab;
  dataInicio: TDate;
  horasSemana: number;
  dataCessacao: TDate;
  dataPrimeiraRenovacaoExtra: TDate;
  complementos: number;
  diuturnidades: number;
  retribuicaoBase: number;
  feriasGozadas: number;
  subsidioFerias: number;
  justaCausa: boolean;
  subsidioNatal: number;
  codEmp: number;
  motivo: string;
  situacao: string;
  tipoProcessamento: number;
  subSidioNatalJaRecebido: number;
  erro: string;
  nomeEmp: string;
  nomeMotivo: string;
  nomeSituacao: string;
  nomeTipoProcessamento: string;
}

export interface IJsonRhCessaContratoTrabalhoSimulacao {
  compensacaoTotal: number;
  valorSubsidioFerias: number;
  feriasPropValor: number;
  subsidioFeriasPropValor: number;
  compensacao: number;
  subsidioNatalPropValor: number;
  valorFeriasVencidas: number;
}

export interface IJsonRhCessaContratoTrabalhoEncerrar {
  idLinha: string;
  valor: number;
  codABDESC: string;
  codEMP: number;
  resultadoFerias: ETipoResultadoFerias;
  abonoDesc: ETipoAbonoDesconto;
  data: TDate;
  observacoes: string;
  nCodABDESC: string;
  descricaoAbono: string;
  quantidade: number;
  orderbyProc: number;
  infoProcessamentoGerado: string;
}

export interface IJsonRhCessaContratoTrabalho {
  rhCessaContratoTrabalhoForm: IJsonRhCessaContratoTrabalhoForm;
  rhCessaContratoTrabalhoSimulacao: IJsonRhCessaContratoTrabalhoSimulacao;
}
