<div class="tiposregistocadastro">
  <entity-detail
    entityName="tiposregistocadastro"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL}">
        <pl-group>
          <label [translate]="'tiposregistocadastro.fields.nMotivoMov'"></label>
          <edit>
            <pl-edit type="text" attrName="nMotivoMov" [(model)]="model.nMotivoMov" [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'tiposregistocadastro.fields.designaBreve'"></label>
          <edit>
            <pl-edit type="text" attrName="designaBreve" [(model)]="model.designaBreve" [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'tiposregistocadastro.fields.designaCompl'"></label>
          <edit>
            <pl-edit type="text" attrName="designaCompl" [(model)]="model.designaCompl"></pl-edit>
          </edit>
        </pl-group>

        <pl-group *ngIf="motivoSaida">
          <label [translate]="'tiposregistocadastro.fields.codQPMOT'"></label>
          <edit>
            <entity-autocomplete
              entity="qpmts"
              attrName="qpmts"
              [model]="model"
              (selectedKeyChange)="model.codQPMOT = $event"
              (evtSelectedDescriptionChanged)="model.nomeCodQPMOT = $event"
              [properties]="{disabled: !disabledMQPessoal}"
              [fieldsMap]="{codmts: 'codQPMOT', nome: 'nomeCodQPMOT'}">
            </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group *ngIf="!motivoSaida">
          <label [translate]="'tiposregistocadastro.fields.codQPMOT'"></label>
          <edit>
            <entity-autocomplete
              entity="qpmte"
              attrName="qpmte"
              [model]="model"
              (selectedKeyChange)="model.codQPMOT = $event"
              (evtSelectedDescriptionChanged)="model.nomeCodQPMOT = $event"
              [properties]="{disabled: !disabledMQPessoal}"
              [fieldsMap]="{codmte: 'codQPMOT', nome: 'nomeCodQPMOT'}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <div>
          <label [translate]="'tiposregistocadastro.fields.infobalancosoc'"></label> <span class="ms-2">{{ selectedName | translate }}</span>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="tiposregistocadastro-tree">
              <tree-viewer [tree]="tiposRegistoCadastroTree" [model]="infbalancosocialSelected" [disabled]="type === stateTypes.DETAIL" (modelChange)="tiposRegistoCadastroChanged($event)">
              </tree-viewer>
            </div>
          </div>
        </div>
      </pl-form>
    </div>
  </entity-detail>
</div>
