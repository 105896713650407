import moment from 'moment';
import {firstValueFrom} from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {copy, downloadStream, EDateMonth, IPlToolbarItem, isNumber, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DATA_SOURCE_MESES} from '../../../../datasources/meses/meses.datasource';
import {ESSEstadoFicheiro} from '../../rhprocsegsocialconsultenvios/rhProcCegCocialConsultEnvios.module.interface';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {IJsonInfoDadosRemuneracao, IJsonRHGestaoFicheirosDMRAT} from '../jsonRHGestaoFicheirosDMRAT.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RHGestaoFicheirosDMRATModalComponent} from '../modals/rhGestaoFicheirosDMRAT.modal.component';
import {RHGestaoFicheirosDMRATService} from '../rhGestaoFicheirosDMRAT.module.service';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {Writeable} from '../../../../../common/interfaces/interfaces';
import {IRHGestaoFicheirosDMRATParams} from '../rhGestaoFicheirosDMRAT.module.interface';

const SUBSTRACT_YEARS = 10;

@Component({
  selector: 'module-rhgestaoficheirosdmrat',
  templateUrl: './rhGestaoFicheirosDMRAT.module.component.html'
})
export class RHGestaoFicheirosDMRATModuleComponent extends ModuloComponent implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly estadoFicheiro: typeof ESSEstadoFicheiro;
  public readonly yearsSource: Array<number>;
  public readonly mesesSource: Array<Writeable<IDataSourceItem<EDateMonth>>>;

  public selectedRowKeys: Array<string>;
  public year: number;
  public month: number;
  public pdfUrl: string;

  private readonly _btnPesquisarEnvios: IPlToolbarItem;
  private readonly _btnGerarFicheiro: IPlToolbarItem;
  private readonly _btnReenviarWebService: IPlToolbarItem;
  private readonly _params: IRHGestaoFicheirosDMRATParams;

  private _rhgestaoFicheirosDMRATList: Array<IJsonRHGestaoFicheirosDMRAT>;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhGestaoFicheirosDMRATService: RHGestaoFicheirosDMRATService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._params = <IRHGestaoFicheirosDMRATParams>this._transition.params();
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {
          caption: 'rhgestaoficheirosdmrat.table.dados',
          alignment: 'center',
          columns: [
            {dataField: 'nEmpresa', caption: 'rhgestaoficheirosdmrat.table.empresa', allowEditing: false},
            {dataField: 'nomeEmpresa', caption: 'rhgestaoficheirosdmrat.table.nomeempresa', width: '200px', allowEditing: false},
            {dataField: 'nContribuinteEmp', caption: 'rhgestaoficheirosdmrat.table.contribemp', allowEditing: false},
            {dataField: 'ssMes', caption: 'global.text.month', allowEditing: false},
            {dataField: 'ssAno', caption: 'global.text.year', allowEditing: false},
            {dataField: '', caption: 'rhgestaoficheirosdmrat.table.info', cellTemplate: 'cellTemplateInfo', alignment: 'center', allowEditing: false},
            {dataField: '', caption: 'rhgestaoficheirosdmrat.table.erros', cellTemplate: 'cellTemplateErros', alignment: 'center', allowEditing: false}
          ]
        },
        {
          caption: 'rhgestaoficheirosdmrat.table.infoficheiro',
          alignment: 'center',
          columns: [
            {dataField: 'descricaoErro', caption: 'rhgestaoficheirosdmrat.table.infoerros', width: '150px', allowEditing: false},
            {dataField: 'estadoFicheiroSTRDMR', caption: 'rhgestaoficheirosdmrat.table.estado', allowEditing: false},
            {dataField: '', caption: 'rhgestaoficheirosdmrat.table.docpagamento', cellTemplate: 'cellTemplateDocPagamento', alignment: 'center', allowEditing: false},
            {dataField: '', caption: 'rhgestaoficheirosdmrat.table.comprovativo', cellTemplate: 'cellTemplateComprovativo', alignment: 'center', allowEditing: false},
            {dataField: 'email', caption: 'rhgestaoficheirosdmrat.table.email', minWidth: '250px', editCellTemplate: 'editCellTemplateEmail', showEditorAlways: true}
          ]
        }
      ],
      dataSource: new CustomStore({
        key: 'nEmpresa',
        load: () => this._loadSSDecRemunList()
      }),
      height: '80vh',
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      scrolling: {rowRenderingMode: 'virtual'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      editing: {
        allowUpdating: true,
        mode: 'cell',
        selectTextOnEditStart: true,
        startEditAction: 'click'
      },
      toolbar: {
        items: [
          {
            location: 'before',
            locateInMenu: 'auto',
            template: 'toolbarTemplatePesquisa'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this._rhgestaoFicheirosDMRATList = [];
    this.estadoFicheiro = ESSEstadoFicheiro;
    const minYear = moment().subtract(SUBSTRACT_YEARS, 'years').year();
    const maxYear = moment().add(1, 'years').year();
    this.yearsSource = [];
    for (let i = minYear; i <= maxYear; i++) {
      this.yearsSource.push(i);
    }
    this.mesesSource = copy(<Array<Writeable<IDataSourceItem<EDateMonth>>>>DATA_SOURCE_MESES.data);
    for (const mes of this.mesesSource) {
      mes.name = this._translateService.instant(mes.name);
    }

    this._btnPesquisarEnvios = {
      id: 'verificarenvios',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-check-circle fa-fw"></i>',
      class: 'btn-light',
      caption: 'rhgestaoficheirosdmrat.btn.verificarenvios',
      disabled: true,
      tooltip: {text: 'rhgestaoficheirosdmrat.tooltip.naotemlinhasselecionadas', placement: 'bottom-right', container: 'body', disabled: false},
      click: () => this._verificarEnvios()
    };
    this._btnGerarFicheiro = {
      id: 'gerarficheiro',
      order: 3,
      type: 'button',
      iconLeft: '<i class="fa fa-bolt fa-fw"></i>',
      class: 'btn-light',
      caption: 'rhgestaoficheirosdmrat.btn.gerarficheiro',
      disabled: true,
      tooltip: {text: 'rhgestaoficheirosdmrat.tooltip.naotemlinhasselecionadas', placement: 'bottom-right', container: 'body', disabled: false},
      click: () => this._gerarFicheiro()
    };
    this._btnReenviarWebService = {
      id: 'reenviarwebservice',
      order: 4,
      type: 'button',
      iconLeft: '<i class="fa fa-upload fa-fw"></i>',
      class: 'btn-light',
      caption: 'rhgestaoficheirosdmrat.btn.reenviarwebservice',
      disabled: true,
      tooltip: {text: 'rhgestaoficheirosdmrat.tooltip.naotemlinhasselecionadas', placement: 'bottom-right', container: 'body', disabled: false},
      click: () => this._reenviarWebservice()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.year = isNumber(this._params.ano) && this._params.ano !== -1 ? this._params.ano : moment().year();
    this.month = isNumber(this._params.mes) && this._params.mes !== -1 ? this._params.mes : moment().month() + 1;

    this.toolbar.addButton(this._btnPesquisarEnvios);
    this.toolbar.addButton(this._btnGerarFicheiro);
    this.toolbar.addButton(this._btnReenviarWebService);
  }

  public btnDadosInfoClick(item: IJsonRHGestaoFicheirosDMRAT): void {
    this._rhGestaoFicheirosDMRATService.postDadosInfo(item).then((response: HttpResponse<IJsonInfoDadosRemuneracao>) => {
      const modalInstance = this._cgModalService.showVanilla(RHGestaoFicheirosDMRATModalComponent, {size: 'xl'});
      const componentInstance: RHGestaoFicheirosDMRATModalComponent = modalInstance.componentInstance;
      componentInstance.infoDados = response.body;
    });
  }

  public pesquisaSSDecRemunList(): Promise<void> {
    return this._dataGridInstance.deselectAll().then(() => {
      return this._dataGridInstance.refresh();
    });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public selectedRowKeysChange(): void {
    const gestaoFicheirosList: Array<IJsonRHGestaoFicheirosDMRAT> = this._dataGridInstance.getSelectedRowsData();
    const havefichSel = gestaoFicheirosList.length > 0;

    this._btnPesquisarEnvios.tooltip.disabled = havefichSel;
    this._btnPesquisarEnvios.disabled = !havefichSel;

    this._btnReenviarWebService.tooltip.disabled = havefichSel;
    this._btnReenviarWebService.disabled = !havefichSel;

    this._btnGerarFicheiro.tooltip.disabled = havefichSel;
    this._btnGerarFicheiro.disabled = !havefichSel;
  }

  public readonly fnComprovativo = (line: IJsonRHGestaoFicheirosDMRAT) => (): Promise<void> => this._comprovativo(line);

  public readonly fnDocPagamento = (line: IJsonRHGestaoFicheirosDMRAT) => (): Promise<void> => this._docPagamento(line);

  public readonly fnEnviarEmail = (line: IJsonRHGestaoFicheirosDMRAT) => (): Promise<void> => this._enviarEmail(line);

  private _verificarEnvios(): Promise<void> {
    const gestaoFicheirosList: Array<IJsonRHGestaoFicheirosDMRAT> = this._dataGridInstance.getSelectedRowsData();
    for (const item of gestaoFicheirosList) {
      item.sel = true;
    }

    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    return this._rhGestaoFicheirosDMRATService
      .verificarEnvios(gestaoFicheirosList)
      .then((response: HttpResponse<Array<IJsonRHGestaoFicheirosDMRAT>>) => {
        for (const item of response.body) {
          const key: string = item.nEmpresa;
          this._dataGridInstance
            .getDataSource()
            .store()
            .push([
              {
                type: 'update',
                key,
                data: item
              }
            ]);
        }
        this._plAlertService.success('rhgestaoficheirosdmrat.messages.envioscomsucesso');
      })
      .finally(() => {
        if (this._dataGridInstance) {
          this._dataGridInstance.endCustomLoading();
        }
      });
  }

  private async _comprovativo(line: IJsonRHGestaoFicheirosDMRAT): Promise<void> {
    this.pdfUrl = await firstValueFrom(this._rhGestaoFicheirosDMRATService.getComprovativo(line.ssDecREmunID));
    this._cgModalService.showPdf('rhgestaoficheirosdmrat.comprovativo', this.pdfUrl);
  }

  private async _docPagamento(line: IJsonRHGestaoFicheirosDMRAT): Promise<void> {
    this.pdfUrl = await firstValueFrom(this._rhGestaoFicheirosDMRATService.getDocPagamento(line.ssDecREmunID));
    this._cgModalService.showPdf('rhgestaoficheirosdmrat.docpagamento', this.pdfUrl);
  }

  private _enviarEmail(line: IJsonRHGestaoFicheirosDMRAT): Promise<void> {
    return this._rhGestaoFicheirosDMRATService.getEnviarDocPagamentoEmail(line.ssDecREmunID, line.email).then((response) => {
      if (response.body.sent) {
        this._plAlertService.success('rhgestaoficheirosdmrat.email.enviado');
      } else {
        this._plAlertService.error(response.body.errorMessage.length ? response.body.errorMessage : 'rhgestaoficheirosdmrat.email.naoenviado');
      }
    });
  }

  private _gerarFicheiro(): Promise<void> {
    const gestaoFicheirosList: Array<IJsonRHGestaoFicheirosDMRAT> = this._dataGridInstance.getSelectedRowsData();
    for (const item of gestaoFicheirosList) {
      item.sel = true;
    }

    return this._cgModalService
      .showOkCancel('global.text.confirmation', 'rhgestaoficheirosdmrat.prompt.geraFich', {
        btnCancelText: 'global.btn.no',
        btnOkText: 'global.btn.yes'
      })
      .then(() => {
        return this._rhGestaoFicheirosDMRATService
          .postGerarFicheiros(gestaoFicheirosList)
          .then((response: HttpResponse<Blob>) => {
            const doc: Blob = response.body;
            if (!doc?.size) {
              this._plAlertService.error('rhgestaoficheirosdmrat.prompt.errogerarficheiro');
            } else {
              downloadStream(response);
            }
          })
          .finally(() => {
            return this._dataGridInstance.refresh();
          });
      });
  }

  private _reenviarWebservice(): Promise<void> {
    const gestaoFicheirosList: Array<IJsonRHGestaoFicheirosDMRAT> = this._dataGridInstance.getSelectedRowsData();
    for (const item of gestaoFicheirosList) {
      item.sel = true;
    }

    return this._cgModalService
      .showOkCancel('global.text.confirmation', 'rhgestaoficheirosdmrat.prompt.geraFich', {
        btnCancelText: 'global.btn.no',
        btnOkText: 'global.btn.yes'
      })
      .then(() => {
        return this._rhGestaoFicheirosDMRATService
          .postReenviarWebservice(gestaoFicheirosList)
          .then((response: HttpResponse<Blob>) => {
            const doc: Blob = response.body;
            if (!doc?.size) {
              this._plAlertService.error('rhgestaoficheirosdmrat.prompt.errogerarficheiro');
            } else {
              downloadStream(response);
              this._plAlertService.success('rhgestaoficheirosdmrat.prompt.ficheirosenviadosconfirmcolum');
            }
          })
          .finally(() => {
            return this._dataGridInstance.refresh();
          });
      });
  }

  private _loadSSDecRemunList(): Promise<Array<IJsonRHGestaoFicheirosDMRAT>> {
    return this._rhGestaoFicheirosDMRATService
      .getSSDecRemunList(this.year, this.month)
      .then((response: HttpResponse<Array<IJsonRHGestaoFicheirosDMRAT>>) => {
        this._rhgestaoFicheirosDMRATList = response.body;
        return this._rhgestaoFicheirosDMRATList;
      })
      .finally(() => {
        this.selectedRowKeysChange();
      });
  }
}
