import {EDebitoCredito} from '../../../../datasources/debitocredito/debitoCredito.datasource.interface';

export enum EPreDefinidoContabTipoConta {
  NaoDefinido,
  ContaCorrente,
  BaseTributavel,
  CGBanking
}

export enum EPreDefinidoContabTipoValorCabPeriodo {
  TabelaEmpresa,
  DataSistema,
  DataDocEFatura
}

export enum EPreDefinidoContabTipoValorCabDiario {
  NaoDefinido,
  Fixo
}

export enum EPreDefinidoContabTipoValorCabDescricao {
  NaoDefinido,
  Fixo
}

export enum EPreDefinidoContabTipoValorDescricao {
  NaoDefinido,
  Fixo,
  Formula
}

export enum EPreDefinidoContabTipoValorDescritivo {
  NaoDefinido,
  ObtidoPorUltimoDocumento,
  Fixo
}

export enum EPreDefinidoContabTipoValorMoeda {
  NaoDefinido,
  IgualMoedaEmpresa,
  Fixo
}

export enum EPreDefinidoContabTipoValorLinha {
  Pergunta,
  Fixo,
  Formula,
  SaldaDoc,
  ArredondamentoeFatura
}

export enum EPreDefinidoContabTipoValorPerguntaBTIntroduzido {
  None,
  Base,
  BaseMaisIva
}

export enum EPreDefinidoContabTipoValorBaseTributavelLinha {
  Pergunta,
  Base,
  BaseMaisIva
}

export enum EPreDefinidoContabOrigemDataDoc {
  NaoDefinido,
  DataDocEFatura
}

export enum EPreDefinidoContabOrigemDataVenc {
  NaoDefinido,
  CondPagamentoClifoEFatura
}

export enum EPreDefinidoContabOrigemDataLancamento {
  UltimoDiaPeriodo,
  DataAtual,
  DataDocEFatura
}

export type TPredefinidoFieldCabecalho = keyof IJsonPreDefinidoContabCabecalho;

export type TPredefinidoFieldLinha = keyof Omit<IJsonPreDefinidoContabLinha, 'fake'>;

export type TPredefinidoField = TPredefinidoFieldCabecalho | TPredefinidoFieldLinha;

export type TPredefinidoVisibilidadeField = keyof IJsonPreDefinidoContabItemVisibilidadeProperties;

export interface IJsonPreDefinidoContab {
  preDefinidosID: number;
  descricao: string;
  revision: number;
  cgBanking: boolean;
  isGeneric: boolean;
  cabecalho: IJsonPreDefinidoContabCabecalho;
  linhas: Array<IJsonPreDefinidoContabLinha>;
  empresasSelecionadas: Array<string>;
  ordemColunas: Array<number>;
}

export interface IJsonPreDefinidoContabItemVisibilidadeProperties {
  visible: boolean;
  readonly: boolean;
  tabStop: boolean;
  tabOrder: number;
}

export interface IJsonPreDefinidoContabItemDefault {
  visibilidadeProperties: IJsonPreDefinidoContabItemVisibilidadeProperties;
}

export interface IJsonPreDefinidoContabCabecalho {
  periodo: IJsonPreDefinidoContabCabPeriodo;
  diario: IJsonPreDefinidoContabCabDiario;
  nDocInterno: IJsonPreDefinidoContabItemDefault;
  dataDoc: IJsonPreDefinidoContabCabDataDoc;
  dataVencimento: IJsonPreDefinidoContabCabDataVencimento;
  dataLancamento: IJsonPreDefinidoContabCabDataLancamento;
  nContribuinte: IJsonPreDefinidoContabItemDefault;
  nDocExterno: IJsonPreDefinidoContabCabNDocExterno;
  descricao: IJsonPreDefinidoContabCabDescricao;
  descritivo: IJsonPreDefinidoContabCabDescritivo;
  moeda: IJsonPreDefinidoContabCabMoeda;
}

export interface IJsonPreDefinidoContabCabPeriodo extends IJsonPreDefinidoContabItemDefault {
  tipoValorPeriodo: EPreDefinidoContabTipoValorCabPeriodo;
}

export interface IJsonPreDefinidoContabCabDiario extends IJsonPreDefinidoContabItemDefault {
  tipoValorDiario: EPreDefinidoContabTipoValorCabDiario;
  valorFixo: number;
}

export interface IJsonPreDefinidoContabCabDataDoc extends IJsonPreDefinidoContabItemDefault {
  origem: EPreDefinidoContabOrigemDataDoc;
}

export interface IJsonPreDefinidoContabCabDataVencimento extends IJsonPreDefinidoContabItemDefault {
  origem: EPreDefinidoContabOrigemDataVenc;
}

export interface IJsonPreDefinidoContabCabDataLancamento extends IJsonPreDefinidoContabItemDefault {
  origem: EPreDefinidoContabOrigemDataLancamento;
}

export interface IJsonPreDefinidoContabCabNDocExterno extends IJsonPreDefinidoContabItemDefault {
  incrementaAutomaticamente: boolean;
  valorFixo: string;
}

export interface IJsonPreDefinidoContabCabDescricao extends IJsonPreDefinidoContabItemDefault {
  tipoValorDescricao: EPreDefinidoContabTipoValorCabDescricao;
  valorFixo: string;
}

export interface IJsonPreDefinidoContabCabDescritivo extends IJsonPreDefinidoContabItemDefault {
  tipoValorDescritivo: EPreDefinidoContabTipoValorDescritivo;
  valorFixo: number;
}

export interface IJsonPreDefinidoContabCabMoeda extends IJsonPreDefinidoContabItemDefault {
  tipoValorMoeda: EPreDefinidoContabTipoValorMoeda;
  valorFixo: number;
}

export interface IJsonPreDefinidoContabLinha {
  conta: IJsonPreDefinidoContabLinhaConta;
  valor: IJsonPreDefinidoContabLinhaValor;
  valorIva: IJsonPreDefinidoContabItemDefault;
  descricao: IJsonPreDefinidoContabLinhaDescricao;
  nContribuinte: IJsonPreDefinidoContabItemDefault;
  nDocExterno: IJsonPreDefinidoContabCabNDocExterno;
  descritivo: IJsonPreDefinidoContabCabDescritivo;
  dataDoc: IJsonPreDefinidoContabCabDataDoc;
  dataVencimento: IJsonPreDefinidoContabCabDataVencimento;
  nomeConta: IJsonPreDefinidoContabItemDefault;
  fake: boolean;
}

export interface IJsonPreDefinidoContabLinhaContaBaseTribProperties {
  codRepCC: number;
}

export interface IJsonPreDefinidoContabLinhaContaCorrenteProperties {
  temImputacao: boolean;
  vaiImputarALinha: number;
}

export interface IJsonPreDefinidoContabLinhaConta extends IJsonPreDefinidoContabItemDefault {
  tipoConta: EPreDefinidoContabTipoConta;
  valorFixo: string;
  dC: EDebitoCredito;
  radical: string;
  baseTributavelProperties: IJsonPreDefinidoContabLinhaContaBaseTribProperties;
  contaCorrenteProperties: IJsonPreDefinidoContabLinhaContaCorrenteProperties;
}

export interface IJsonPreDefinidoContabLinhaValorBaseTribProperties {
  tipoValorPerguntaIntroduzido: EPreDefinidoContabTipoValorPerguntaBTIntroduzido;
  tipoValorBaseTributavel: EPreDefinidoContabTipoValorBaseTributavelLinha;
  importEFaturaSuportaTaxaIsenta: boolean;
  importEFaturaValorImpSelo: boolean;
  importEFaturaContaUnicaSemDeducao: boolean;
  importFRVSuportaTaxaIsenta: boolean;
  importFRVValorImpSelo: boolean;
}

export interface IJsonPreDefinidoContabLinhaValor extends IJsonPreDefinidoContabItemDefault {
  tipoValor: EPreDefinidoContabTipoValorLinha;
  valorFixo: number;
  valorFormula: string;
  baseTributavelProperties: IJsonPreDefinidoContabLinhaValorBaseTribProperties;
  colocaValorEFaturaDocDigital: boolean;
}

export interface IJsonPreDefinidoContabLinhaDescricao extends IJsonPreDefinidoContabItemDefault {
  tipoValorDescricao: EPreDefinidoContabTipoValorDescricao;
  valorFixo: string;
  valorFormula: string;
}
