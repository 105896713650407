<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'documentoFaturacao.modals.encerrar.title'"></h5>
  </div>

  <div class="modal-body">
    <span>{{ question }}</span>
  </div>

  <div class="modal-footer" plNavigation>
    <button class="btn btn-success btn-sm" name="btnEncerrar" type="button" [disabled]="closeDisabled" [click]="close" plPromise>
      <i class="fa fa-fw fa-file-archive-o"></i>&nbsp;<span [translate]="'docscomerciais.actions.encerrarDocumento'"></span>
    </button>

    <button type="button" class="btn btn-light btn-sm action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
      <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
    </button>
  </div>
</pl-form>
