<div class="clifos-ficha entity-detail-form">
  <pl-navigator contentMaxHeight="68vh" [callback]="callbackNav">
    <pl-navigator-item caption="clifos.groups.indentificacao" [order]="-1" [id]="-1">
      <div *plNavigatorItemContent>
        <div class="d-flex align-items-center" style="gap: 15px">
          <div style="flex: 1">
            <pl-group>
              <pl-group *ngIf="type === entityStateType.NEW">
                <label [translate]="'clifos.fields.idSubGrContaCorrente'"></label>
                <edit>
                  <entity-autocomplete
                    entity="naturezasContab"
                    attrName="naturezaContab"
                    [model]="model"
                    [(selectedKey)]="model.naturezaContab"
                    (selectedKeyChange)="model.naturezaContab = $event"
                    [placeholder]="'clifos.fields.idSubGrContaCorrentePlaceholder'"
                    [properties]="{validators: {required: {value: true}}}"
                    [filter]="filterNaturezasContab">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'clifos.fields.nConta'"></label>
                <edit>
                  <pl-edit-text attrName="nConta" [(model)]="model.nConta" [placeholder]="'clifos.fields.nContaPlaceholder'" [properties]="{validators: {maxlength: {value: 75}}, disabled: true}">
                  </pl-edit-text>
                </edit>
              </pl-group>

              <pl-group *ngIf="model.nIdAltern && model.nIdAltern > 0">
                <label [translate]="'clifos.fields.nIdAltern'"></label>
                <edit>
                  <pl-edit type="cginteger" attrName="nIdAltern" [(model)]="model.nIdAltern" [properties]="{disabled: true}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'clifos.fields.nomePais'"></label>
                <edit>
                  <entity-autocomplete
                    entity="paises"
                    attrName="codPais"
                    [model]="model"
                    [(selectedKey)]="model.codPais"
                    (selectedKeyChange)="model.codPais = $event"
                    (evtSelectedDescriptionChanged)="model.nomePais = $event"
                    [properties]="{validators: {required: {value: true}}}">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group [ngClass]="{'nif-invalid': nifInvalido}">
                <label [translate]="'clifos.fields.nContribuint'"></label>
                <edit>
                  <entity-autocomplete
                    entity="nifs"
                    attrName="nContribuint"
                    [model]="model.nContribuint"
                    (modelChange)="nContribuinteChanged($event)"
                    [properties]="{validators: {max: {value: 25}}}"
                    plAutoFocus>
                  </entity-autocomplete>
                  <div class="nif-invalid-message"><i class="fa fa-fw fa-exclamation-triangle me-1" aria-hidden="true"></i><span [translate]="'clifos.errors.nifInvalido'"></span></div>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'clifos.fields.nome'"></label>
                <edit>
                  <pl-edit-text attrName="nome" [(model)]="model.nome" [placeholder]="'clifos.fields.nomePlaceholder'" [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}}">
                  </pl-edit-text>
                </edit>
              </pl-group>

              <pl-group>
                <label></label>
                <edit>
                  <pl-edit attrName="desactivado" type="boolean" [(model)]="model.desactivado" [properties]="{label: 'clifos.fields.desactivado'}"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>
          </div>
          <div class="d-flex flex-column" [plPromise]="promiseClifoPicture">
            <pl-media-devices-camera-capture-image
              [model]="clifoPicture"
              (modelChange)="changedClifoPicture($event)"
              [disabled]="type === entityStateType.DETAIL"
              icon="fa fa-image"
              [properties]="propertiesCaptureImage">
            </pl-media-devices-camera-capture-image>
          </div>
        </div>
      </div>
    </pl-navigator-item>

    <pl-navigator-item caption="clifos.groups.estadoAtVies" [order]="0" [id]="0">
      <div *plNavigatorItemContent>
        <pl-group>
          <pl-group>
            <label [translate]="'clifos.fields.nifSituacaoViesDescricao'"></label>
            <edit>
              <pl-edit-text attrName="nifSituacaoViesDescricao" [(model)]="model.nifSituacaoViesDescricao" [properties]="{disabled: true}"></pl-edit-text>
            </edit>
            <actions>
              <pl-button *ngIf="type !== entityStateType.DETAIL" klass="btn btn-info" id="validaNifSituacao" (evtClicked)="validaNifSituacao()">
                <i class="fa fa-fw fa-check"></i><span [translate]="'clifos.btn.validaNif'"></span>
              </pl-button>
            </actions>
          </pl-group>
        </pl-group>
      </div>
    </pl-navigator-item>

    <pl-navigator-item caption="clifos.groups.dadosprincipais" [order]="1" [id]="1">
      <div *plNavigatorItemContent>
        <pl-group>
          <pl-group>
            <label [translate]="'clifos.fields.rua'"></label>
            <edit>
              <pl-edit-text attrName="rua" [(model)]="model.rua" [placeholder]="'clifos.fields.ruaPlaceholder'" [properties]="{validators: {maxlength: {value: 90}}}"></pl-edit-text>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'clifos.fields.ruaCont'"></label>
            <edit>
              <pl-edit-text attrName="lugar" [(model)]="model.lugar" [placeholder]="'clifos.fields.ruaPlaceholder'" [properties]="{validators: {maxlength: {value: 50}}}"></pl-edit-text>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'clifos.fields.codPostal'"></label>
            <edit>
              <entity-autocomplete
                entity="codpostais"
                attrName="codPostal"
                [(selectedKey)]="model.codPostal"
                (selectedKeyChange)="model.codPostal = $event"
                [outputKey]="'codPostal'"
                [placeholder]="'clifos.fields.codPostalPlaceholder'"
                [properties]="{validators: {maxlength: {value: 20}}}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'clifos.fields.localidade'"></label>
            <edit>
              <pl-edit-text attrName="localidade" [(model)]="model.localidade" [placeholder]="'clifos.fields.localidadePlaceholder'" [properties]="{validators: {maxlength: {value: 50}}}">
              </pl-edit-text>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'clifos.fields.telefone'"></label>
            <edit>
              <pl-edit-mask
                attrName="telefone"
                [(model)]="model.telefone"
                [placeholder]="'clifos.fields.telefonePlaceholder'"
                [mask]="'[\\+][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9]'"
                [properties]="{validators: {maxlength: {value: 20}, minlength: {value: 9}}}">
              </pl-edit-mask>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'clifos.fields.telemovel'"></label>
            <edit>
              <pl-edit-mask
                attrName="telemovel"
                [(model)]="model.telemovel"
                [placeholder]="'clifos.fields.telemovelPlaceholder'"
                [mask]="'[\\+][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9]'"
                [properties]="{validators: {maxlength: {value: 20}, minlength: {value: 9}}}">
              </pl-edit-mask>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'clifos.fields.email'"></label>
            <edit>
              <pl-edit-email attrName="email" [(model)]="model.email" [placeholder]="'clifos.fields.emailPlaceholder'"></pl-edit-email>
            </edit>
            <actions>
              <pl-button
                *ngIf="type !== entityStateType.DETAIL"
                klass="btn btn-sm btn-primary ms-auto"
                id="selectNavTipoEmail"
                [title]="'clifos.selectNavTipoEmail' | translate"
                (evtClicked)="selectNavTiposEmails()">
                <i class="fa fa-fw fa-envelope-open"></i>
              </pl-button>
            </actions>
          </pl-group>

          <pl-group>
            <label [translate]="'clifos.fields.fax'"></label>
            <edit>
              <pl-edit-text attrName="fax" [(model)]="model.fax" [placeholder]="'clifos.fields.faxPlaceholder'" [properties]="{validators: {maxlength: {value: 20}}}"></pl-edit-text>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'clifos.fields.wwwSite'"></label>
          <edit>
            <pl-edit-text attrName="wwwSite" [(model)]="model.wwwsite" [placeholder]="'clifos.fields.wwwSitePlaceholder'"></pl-edit-text>
          </edit>
        </pl-group>
      </div>
    </pl-navigator-item>

    <pl-navigator-item caption="clifos.groups.dadosfinan" [order]="2" [id]="2">
      <div *plNavigatorItemContent>
        <pl-group *ngIf="!model.nIdAltern">
          <pl-group>
            <label>
              <span [translate]="'clifos.fields.iban'"></span>
              <pl-tooltip class="tooltip-info-label" [config]="{text: 'clifos.text.iban', placement: ['bottom-left', 'left']}">
                <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
              </pl-tooltip>
            </label>
            <edit>
              <pl-edit-text attrName="iban" [(model)]="model.iban" [placeholder]="'clifos.fields.ibanPlaceholder'"></pl-edit-text>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'clifos.fields.swift'"></label>
            <edit>
              <pl-edit-text attrName="swift" [(model)]="model.swift" [placeholder]="'clifos.fields.swiftPlaceholder'"></pl-edit-text>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="grupo.name === 'clientes' || grupo.name === 'fornecedores'">
          <pl-group>
            <label [translate]="'clifos.fields.codPagamento'"></label>
            <edit>
              <entity-autocomplete
                attrName="codPagamento"
                entity="condicoespagamento"
                [model]="model"
                [selectedKey]="model.codPagamento"
                (selectedKeyChange)="model.codPagamento = $event"
                (evtSelectedDescriptionChanged)="model.nomePagamento = $event"
                [placeholder]="'clifos.fields.codPagamentoPlaceholder'"
                [outputKey]="'nCondPag'"
                [outputDescription]="'descricao'"
                [fieldsMap]="{nCondPag: 'codPagamento', descricao: 'nomePagamento'}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'clifos.fields.codCondComerciais'"></label>
            <edit>
              <entity-autocomplete
                attrName="codCondComerciais"
                entity="condicoescomerciais"
                [model]="model"
                [selectedKey]="model.codCondComerciais"
                (selectedKeyChange)="model.codCondComerciais = $event"
                (evtSelectedDescriptionChanged)="model.nomeCondComerciais = $event"
                [placeholder]="'clifos.fields.codCondComerciaisPlaceholder'"
                [outputKey]="'codCondComerciais'"
                [outputDescription]="'descricao'"
                [fieldsMap]="{descricao: 'nomeCondComerciais'}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label>
              <span [translate]="'clifos.fields.nomeMoeda'"></span>
              <pl-tooltip class="tooltip-info-label" [config]="{text: 'clifos.text.codMoeda', placement: ['bottom', 'left']}">
                <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
              </pl-tooltip>
            </label>
            <edit>
              <entity-autocomplete
                attrName="codMoeda"
                entity="moeda"
                [model]="model"
                (selectedKeyChange)="model.codMoeda = $event"
                (evtSelectedDescriptionChanged)="model.nomeMoeda = $event"
                [placeholder]="'clifos.fields.nomeMoedaPlaceholder'">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group *ngIf="grupo.name === 'clientes' || grupo.name === 'fornecedores' || grupo.name === 'outrosdevedorescredores'">
            <label>
              <span [translate]="'clifos.fields.codRet'"></span>
              <pl-tooltip class="tooltip-info-label" [config]="{text: 'clifos.text.codRetencao', placement: ['bottom', 'left']}">
                <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
              </pl-tooltip>
            </label>
            <edit>
              <entity-autocomplete
                attrName="codRet"
                entity="taxasretencao"
                [model]="model"
                [(selectedKey)]="model.codRet"
                (evtSelectedDescriptionChanged)="model.nomeRet = $event"
                [placeholder]="'clifos.fields.codRetPlaceholder'"
                [outputKey]="'codRet'"
                [outputDescription]="'nomeRet'"
                [fieldsMap]="{codRet: 'codRet', nomeRet: 'nomeRet'}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label></label>
            <edit>
              <pl-edit-checkbox attrName="utilizaIVAFichaClifo" [(model)]="model.utilizaIVAFichaClifo" [properties]="{label: 'clifos.fields.utilizaIVAFichaClifo'}"></pl-edit-checkbox>
            </edit>
          </pl-group>

          <pl-group>
            <label>
              <span [translate]="'clifos.fields.codIva'"></span>
              <pl-tooltip class="tooltip-info-label" [config]="{text: 'clifos.text.aplicaTaxaIva', placement: ['bottom', 'left']}">
                <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
              </pl-tooltip>
            </label>
            <edit>
              <entity-autocomplete
                entity="ivas"
                [model]="model"
                (selectedKeyChange)="model.codIva = $event"
                (evtSelectedDescriptionChanged)="model.nomeCodIva = $event"
                [fieldsMap]="{nome: 'nomeCodIva'}"
                [properties]="{disabled: !model.utilizaIVAFichaClifo}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
      </div>
    </pl-navigator-item>

    <pl-navigator-item caption="clifos.groups.condECaract" [order]="3" [id]="3">
      <div *plNavigatorItemContent>
        <pl-group>
          <pl-group>
            <edit>
              <pl-edit-checkbox attrName="obrigaNumRequisicao" [(model)]="model.obrigaNumRequisicao" [properties]="{label: 'clifos.fields.obrigaNumRequisicao'}"></pl-edit-checkbox>
            </edit>
          </pl-group>

          <pl-group>
            <edit>
              <pl-edit type="boolean" attrName="naoPodeFacturar" [(model)]="model.naoPodeFacturar" [properties]="{label: 'clifos.fields.naoPodeFacturar'}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group *ngIf="grupo.name === 'fornecedores'">
            <edit>
              <pl-edit type="boolean" attrName="autoFaturacao" [(model)]="model.autoFaturacao" [properties]="{label: 'clifos.fields.autoFaturacao'}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="grupo.name === 'clientes' || grupo.name === 'fornecedores' || grupo.name === 'outrosdevedorescredores'">
          <label>
            <span [translate]="'clifos.fields.facPublicaModelo'"></span>
            <pl-tooltip class="tooltip-info-label" [config]="{text: 'clifos.text.modeloFatPublic', placement: ['bottom', 'left']}">
              <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
            </pl-tooltip>
          </label>
          <edit>
            <pl-edit type="tipofacpublicamodelo" attrName="facPublicaModelo" [model]="model.facPublicaModelo" (modelChange)="model.facPublicaModelo = $event"></pl-edit>
          </edit>
        </pl-group>

        <pl-group *ngIf="grupo.name === 'clientes' || grupo.name === 'fornecedores' || grupo.name === 'outrosdevedorescredores'">
          <pl-group>
            <label [translate]="'clifos.fields.nTxtCabeca'"></label>
            <edit>
              <entity-autocomplete
                attrName="nTxtCabeca"
                entity="solic"
                [model]="model"
                [(selectedKey)]="model.nTxtCabeca"
                (evtSelectedDescriptionChanged)="model.tituloTextoCab = $event"
                [placeholder]="'clifos.fields.nTxtCabecaPlacholder'"
                [outputKey]="'nTexto'"
                [outputDescription]="'tituloTextoCab'"
                [filter]="'tipoTexto=0'"
                [fieldsMap]="{nTexto: 'nTxtCabeca', tituloTexto: 'tituloTextoCab'}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'clifos.fields.nTxtPE'"></label>
            <edit>
              <entity-autocomplete
                attrName="nTxtPE"
                entity="solic"
                [model]="model"
                [(selectedKey)]="model.nTxtPE"
                (evtSelectedDescriptionChanged)="model.tituloTextoRod = $event"
                [placeholder]="'clifos.fields.nTxtPEPlacholder'"
                [outputKey]="'nTexto'"
                [outputDescription]="'tituloTextoRod'"
                [filter]="'tipoTexto=1'"
                [fieldsMap]="{nTexto: 'nTxtPE', tituloTexto: 'tituloTextoRod'}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label>
              <span [translate]="'clifos.fields.textoFaturacao'"></span>
              <pl-tooltip class="tooltip-info-label" [config]="{text: 'clifos.text.textoFaturacao', placement: ['bottom', 'left']}">
                <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
              </pl-tooltip>
            </label>
            <edit>
              <pl-edit-textarea attrName="textofaturacao" [(model)]="model.textofaturacao" [placeholder]="'clifos.fields.textoFaturacao'" [properties]="{validators: {maxlength: {value: 250}}}">
              </pl-edit-textarea>
            </edit>
          </pl-group>
        </pl-group>
      </div>
    </pl-navigator-item>

    <pl-navigator-item caption="clifos.groups.classificadores" [order]="4" [id]="4">
      <div *plNavigatorItemContent>
        <pl-group>
          <pl-group>
            <label [translate]="'clifos.fields.idideChav'"></label>
            <edit>
              <entity-autocomplete
                entity="idide"
                attrName="idIdeChav"
                [model]="model"
                (selectedKeyChange)="model.idIdeChav = $event"
                (evtSelectedDescriptionChanged)="model.idIdeDescricao = $event"
                [placeholder]="'clifos.fields.idideChavPlacholder'"
                [fieldsMap]="{descricao: 'idIdeDescricao'}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'clifos.fields.classeClifo'"></label>
            <edit>
              <entity-autocomplete
                entity="clicls"
                attrName="classeClifo"
                [model]="model"
                (selectedKeyChange)="model.classeClifo = $event"
                (evtSelectedDescriptionChanged)="model.classeClifoDescricao = $event"
                [fieldsMap]="{codigo: 'classeClifo', descricao: 'classeClifoDescricao'}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group *ngIf="optionShowSector">
            <label [translate]="'clifos.fields.sector'"></label>
            <edit>
              <entity-autocomplete
                entity="sectores"
                attrName="sectorId"
                [model]="model"
                (selectedKeyChange)="model.sector = $event"
                (evtSelectedDescriptionChanged)="model.nomeSector = $event"
                [fieldsMap]="{sectorId: 'sector', nome: 'nomeSector'}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group *ngIf="optionShowZona">
            <label [translate]="'clifos.fields.codZona'"></label>
            <edit>
              <entity-autocomplete
                entity="zonas"
                attrName="codZona"
                [model]="model"
                (selectedKeyChange)="model.codZona = $event"
                (evtSelectedDescriptionChanged)="model.nomeZona = $event"
                [fieldsMap]="{nZona: 'codZona', nome: 'nomeZona'}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group *ngIf="optionShowVendedor">
            <label [translate]="'clifos.fields.codVendedor'"></label>
            <edit>
              <entity-autocomplete
                entity="vendedores"
                attrName="codVendedor"
                [model]="model"
                (selectedKeyChange)="model.codVendedor = $event"
                (evtSelectedDescriptionChanged)="model.nomeVendedor = $event"
                [fieldsMap]="{nVendedor: 'codVendedor', nome: 'nomeVendedor'}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'clifos.fields.observacoes'"></label>
          <edit>
            <pl-edit-textarea attrName="observacoes" [(model)]="model.observacoes" [placeholder]="'clifos.fields.observacoes'" [properties]="{validators: {maxlength: {value: 50}}}"></pl-edit-textarea>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'clifos.fields.observacoes2'"></label>
          <edit>
            <pl-edit-textarea attrName="observacoes2" [(model)]="model.observacoes2" [placeholder]="'clifos.fields.observacoes'" [properties]="{validators: {maxlength: {value: 50}}}">
            </pl-edit-textarea>
          </edit>
        </pl-group>
      </div>
    </pl-navigator-item>

    <ng-container *ngIf="type !== entityStateType.NEW">
      <pl-navigator-item caption="clifos.groups.moradasAlt" [order]="5" [id]="5">
        <div *plNavigatorItemContent>
          <entity-list
            entityName="morals"
            [maintenanceMode]="maintenanceMode"
            (evtDataGridOnInitialized)="onDataGridEventOnInitializedMoral($event)"
            filter="nConta={{ model.nConta }}"
            [serviceMethodsOverride]="serviceMethodsOverride">
            <div *entityListDataGridToolbarItem="let toolbarItem; item: {name: 'addMoral'}">
              <pl-button *ngIf="type !== entityStateType.DETAIL" klass="btn btn-primary btn-sm ng-star-inserted" [onClick]="novoMoral">
                <i class="fa fa-plus-circle"></i>&nbsp;<span [translate]="'clifos.btn.btnNewMoral'"></span>
              </pl-button>
            </div>

            <div *entityListItemActions="let itemAction" class="text-center">
              <pl-button
                *ngIf="type !== entityStateType.DETAIL"
                klass="btn-warning btn-xs action-edit-line me-1"
                [attr.title]="'global.btn.edit' | translate"
                [onClick]="fnEditarMoral(itemAction.data)">
                <i class="fa fa-fw fa-pencil-square-o"></i>
              </pl-button>
              &nbsp;
              <pl-button
                *ngIf="!itemAction.data.terminado && type !== entityStateType.DETAIL"
                klass="btn-danger btn-xs action-delete-line"
                [attr.title]="'global.btn.delete' | translate"
                [onClick]="fnEliminarMoral(itemAction.data)">
                <i class="fa fa-fw fa-trash-o"></i>
              </pl-button>
            </div>
          </entity-list>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="clifos.groups.tiposEmail" [order]="6" [id]="6">
        <div *plNavigatorItemContent>
          <clifo-definicao-emails [readonly]="type === entityStateType.DETAIL" [nConta]="model.nConta" [hideRefresh]="true"></clifo-definicao-emails>
        </div>
      </pl-navigator-item>
    </ng-container>
  </pl-navigator>
</div>
