import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {AtivosAquisicaoInvestEmCursoPorDocModuleComponent} from './components/ativosAquisicaoInvestEmCursoPorDoc.module.component';
import {MODULE_NAME_ATIVOS_AQUISICAO_INVEST_EM_CURSO_POR_DOC} from './ativosAquisicaoInvestEmCursoPorDoc.module.interface';

export const MODULE_ATIVOS_AQUISICAO_INVEST_EM_CURSO_POR_DOC: IModuleDefinition = {
  name: MODULE_NAME_ATIVOS_AQUISICAO_INVEST_EM_CURSO_POR_DOC,
  state: {
    url: `/${MODULE_NAME_ATIVOS_AQUISICAO_INVEST_EM_CURSO_POR_DOC}`,
    component: AtivosAquisicaoInvestEmCursoPorDocModuleComponent,
    data: {
      roles: [ROLE.ATIVOS],
      icon: 'fa-outdent',
      sidebarTitle: 'portals.sidebar.modules.ativosaquisicaoinvestemcurso'
    },
    params: {
      doSearch: {
        type: 'bool',
        value: false
      }
    }
  }
};
