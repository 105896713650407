import {AssistConfigPortaisGeralEmailModuleComponent} from './components/assistConfigPortais.geral.email.module.component';
import {IModuleDefinition} from '../../../../../../components/module/module.definition.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_GERAL_EMAIL} from './assistConfigPortais.geral.email.module.interface';
import {ROLE} from '../../../../../../services/role.const';

export const MODULE_ASSISTENTE_CONFIG_PORTAIS_GERAL_EMAIL: IModuleDefinition = {
  name: MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_GERAL_EMAIL,
  state: {
    url: '/assistenteconfigportais/geral/email',
    component: AssistConfigPortaisGeralEmailModuleComponent,
    data: {
      roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
      pageTitle: 'assistenteconfigportais.pages.email'
    }
  }
};
