import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {isEmpty} from 'pl-comps-angular';
import {ApiService} from '../api/api.service';
import {buildSessionUrlWithParams} from '../../../common/utils/utils';
import {currentYear, TDate} from '../../../common/dates';
import {EGenericImporterType, IGenericImporterExecutor} from '../../components/genericimporter/generic.importer.component.interface';
import {EGestaoDGEMPSType} from '../../components/gestaodgemps/gestaodgemps.interface';
import {EDossierFiscalTipoElemento, TPortalClientAccountDashboard, TPortalClientAccountDashboardResult} from './portalclientaccount.service.interface';
import {EPRHFluxoIndiceTipo, IJsonPRHFluxo} from '../../entities/prhfluxos/jsonPRHFluxo.entity.interface';
import {IJsonBalanceteAno, IJsonBalanceteCompl} from '../../modules/portalcontabilidade/estatistica/balancetes/jsonContabilidade.balancetes.module.interface';
import {IJsonClifo} from '../../entities/clifos/jsonClifo.entity.interface';
import {IJsonConfigEstrutura, IJsonPRHEventosConfig} from '../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import {IJsonDGEMP, IJsonDGEMPMarcacoes, IJsonEstadosProcessamento, IJsonProcessamentoSalarios, IJsonProcessamentoSegurancaSocial} from '../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonDocComercial, IJsonDocComercialCab} from '../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {IJsonEmailClifo} from '../../components/emailsCliFo/jsonEmailCliFo.interface';
import {IJsonExtrato, IJsonPendentes} from '../../interfaces/jsonContabilidade.interface';
import {IJsonGDocAnexo} from '../../interfaces/jsonGDocAnexo.interface';
import {IJsonPRHMarcarAbonos} from '../../interfaces/jsonPRHAbonos.interface';
import {IJsonPRHMarcarFaltas} from '../../interfaces/jsonPRHFaltas.interface';
import {IJsonPRHMarcarFerias} from '../../interfaces/jsonPRHFerias.interface';
import {IJsonPRHServicoDatasMarcacoesPendentes, IJsonPRHServicoMarcacoes} from '../../entities/prhservicos/jsonPRHServico.entity.interface';
import {IJsonPRHServicoEmpregado} from '../../entities/prhservicoempregados/jsonPRHServicoEmpregado.entity.interface';
import {TServiceQueryResponse, TServiceResponse} from '../api/api.service.interface';
import {IJsonReport} from '../../entities/reports/jsonReport.interface';

@Injectable({
  providedIn: 'root'
})
export class PortalClientAccountService implements IGenericImporterExecutor {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/portalclientaccount`;
  }

  public getBalancetesUrl(ano: number, periodo: string, tipoExportacao: number): Observable<string> {
    if (isEmpty(ano)) {
      ano = currentYear();
    }
    if (isEmpty(periodo)) {
      periodo = '123';
    }
    return buildSessionUrlWithParams(`${this._path}/balancetes`, {ano: ano, periodo: periodo, tipoExportacao: tipoExportacao});
  }

  public getBalancetesGrid(ano: number, periodo: string): TServiceResponse<Array<IJsonBalanceteCompl>> {
    return this._apiService.get<Array<IJsonBalanceteCompl>>({
      url: `${this._path}/balancetes/grelha`,
      params: {ano: ano, periodo: periodo}
    });
  }

  public getAnosContabilidade(): TServiceResponse<Array<IJsonBalanceteAno>> {
    return this._apiService.get<Array<IJsonBalanceteAno>>({url: `${this._path}/anosContabilidade`});
  }

  public getExtractosUrl(nconta: string, ano: number, periodo: string, tipoExportacao: number): Observable<string> {
    if (isEmpty(ano)) {
      ano = currentYear();
    }
    if (isEmpty(periodo)) {
      periodo = '123';
    }
    return buildSessionUrlWithParams(`${this._path}/extratos`, {nconta: nconta, ano: ano, periodo: periodo, tipoExportacao: tipoExportacao});
  }

  public getMovimentosAbertoUrl(ano: number, periodo: string, nconta: string, tipoExportacao: number, reportName: string): Observable<string> {
    if (isEmpty(ano)) {
      ano = currentYear();
    }
    if (isEmpty(periodo)) {
      periodo = '123';
    }
    if (isEmpty(nconta)) {
      nconta = '';
    }
    return buildSessionUrlWithParams(`${this._path}/movimentosemaberto`, {ano: ano, periodo: periodo, nconta: nconta, tipoExportacao: tipoExportacao, reportName: reportName});
  }

  public getMovimentosAbertoClientesUrl(ano: number, periodo: string, tipoExportacao: number, reportName: string, idIdeChav: string): Observable<string> {
    if (isEmpty(ano)) {
      ano = currentYear();
    }
    if (isEmpty(periodo)) {
      periodo = `${ano}123`;
    }
    return buildSessionUrlWithParams(`${this._path}/movimentosemaberto/clientes`, {ano: ano, periodo: periodo, tipoExportacao: tipoExportacao, reportName: reportName, idIdeChav: idIdeChav});
  }

  public getEmailContent(nConta: string): TServiceResponse<IJsonEmailClifo> {
    return this._apiService.get<IJsonEmailClifo>({url: `${this._path}/movimentosemaberto/emailscontent`, params: {nconta: nConta}});
  }

  public sendMailMovimentosAberto(ano: number, periodo: string, emailContent: IJsonEmailClifo, reportName: string): TServiceResponse<Array<IJsonBalanceteAno>> {
    return this._apiService.get<Array<IJsonBalanceteAno>>({
      url: `${this._path}/movimentosemaberto/sendmail`,
      params: {
        ano: ano,
        periodo: periodo,
        email: emailContent.emails,
        subject: emailContent.subject,
        body: emailContent.body,
        nconta: emailContent.nConta,
        reportname: reportName
      }
    });
  }

  public getMovimentosAbertoFornecedoresUrl(ano: number, periodo: string, tipoExportacao: number, reportName: string, idIdeChav: string): Observable<string> {
    if (isEmpty(ano)) {
      ano = currentYear();
    }
    if (isEmpty(periodo)) {
      periodo = `${ano}123`;
    }
    return buildSessionUrlWithParams(`${this._path}/movimentosemaberto/fornecedores`, {ano: ano, periodo: periodo, tipoExportacao: tipoExportacao, reportName: reportName, idIdeChav: idIdeChav});
  }

  public getIdadeSaldosUrl(tipo: string, ano: number, periodo: string, tipoExportacao: number, nConta: string, idIdeChav: string, reportName: string): Observable<string> {
    if (isEmpty(ano)) {
      ano = currentYear();
    }
    if (isEmpty(periodo)) {
      periodo = '123';
    }
    return buildSessionUrlWithParams(`${this._path}/idadedesaldos/${tipo}`, {ano: ano, periodo: periodo, tipoExportacao: tipoExportacao, nConta: nConta, idIdeChav: idIdeChav, reportName: reportName});
  }

  public getDashboard<T extends TPortalClientAccountDashboard>(
    nome: TPortalClientAccountDashboard,
    tipo: number,
    ano: number,
    force: boolean = false
  ): TServiceResponse<TPortalClientAccountDashboardResult<T>> {
    return this._apiService.get<TPortalClientAccountDashboardResult<T>>({
      url: `${this._path}/dashboards/${nome}`,
      params: {
        tipo: tipo,
        ano: ano,
        force: force
      }
    });
  }

  public getDossierFiscalAnos(tipo: EDossierFiscalTipoElemento): TServiceResponse<Array<number>> {
    return this._apiService.get<Array<number>>({url: `${this._path}/dossierfiscal/${tipo}`});
  }

  public getDossierFiscalFiles(tipo: EDossierFiscalTipoElemento, ano: number): TServiceQueryResponse<IJsonGDocAnexo> {
    return this._apiService.query<IJsonGDocAnexo>({url: `${this._path}/dossierfiscal/${tipo}/${ano}`});
  }

  public getDossierFiscalAnexoUrl(tipo: EDossierFiscalTipoElemento, ano: number, filename: string): Observable<string> {
    return of(`${this._path}/dossierfiscal/${tipo}/${ano}/${filename}`);
  }

  public getMapaSindicatosUrl(ano: number, mes: number): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/recursoshumanos/mapasindicatos`, {ano: ano, mes: mes});
  }

  public getMapaFeriasUrl(ano: number, mes: number): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/recursoshumanos/mapaferias`, {ano: ano, mes: mes});
  }

  public getMapaFaltasUrl(ano: number, mes: number): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/recursoshumanos/mapafaltas`, {ano: ano, mes: mes});
  }

  public getMapaSeguradoraUrl(ano: number, mes: number): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/recursoshumanos/mapaseguradora`, {ano: ano, mes: mes});
  }

  public getMapaSegurancaSocialUrl(ano: number, mes: number): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/recursoshumanos/mapasegurancasocial`, {ano: ano, mes: mes});
  }

  public getMapaFundosCompensacaoUrl(ano: number, mes: number): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/recursoshumanos/mapafundocompensacaotrabalho`, {ano: ano, mes: mes});
  }

  public getRecibosVencimentosUrl(codEmpDe: number, codEmpAte: number, nProcessamento: number, reportName: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/recursoshumanos/recibosvencimento`, {
      codEmpDe: codEmpDe,
      codEmpAte: codEmpAte,
      nProcessamento: nProcessamento,
      reportName: reportName
    });
  }

  public getReportsSupportGrafico(): TServiceResponse<Array<IJsonReport>> {
    return this._apiService.get<Array<IJsonReport>>({url: `${this._path}/recursoshumanos/reportsSupportGrafico`});
  }

  public getProcessamentosSalarios(): TServiceResponse<Array<IJsonProcessamentoSalarios>> {
    return this._apiService.get<Array<IJsonProcessamentoSalarios>>({url: `${this._path}/recursoshumanos/processamentos/salarios`});
  }

  public getProcessamentosSegurancaSocial(): TServiceResponse<Array<IJsonProcessamentoSegurancaSocial>> {
    return this._apiService.get<Array<IJsonProcessamentoSegurancaSocial>>({url: `${this._path}/recursoshumanos/processamentos/segurancaSocial`});
  }

  public getContasCliente(): TServiceQueryResponse<IJsonClifo> {
    return this._apiService.query<IJsonClifo>({url: `${this._path}/empresagabinete/contascliente`});
  }

  public getDocumentosComerciais(nconta: string, pagina: number, porpagina: number, ordena: string): TServiceQueryResponse<IJsonDocComercialCab> {
    return this._apiService.query<IJsonDocComercialCab>({
      url: `${this._path}/empresagabinete/documentoscomerciais`,
      params: {
        nconta: nconta,
        pagina: pagina,
        porpagina: porpagina,
        ordena: ordena
      }
    });
  }

  public getMovimentosAbertoCliente(nconta: string, pagina: number, porpagina: number, ordena: string): TServiceQueryResponse<IJsonPendentes> {
    return this._apiService.query<IJsonPendentes>({
      url: `${this._path}/empresagabinete/movimentosaberto`,
      params: {
        nconta: nconta,
        pagina: pagina,
        porpagina: porpagina,
        ordena: ordena
      }
    });
  }

  public getExtratoConta(nconta: string, pagina: number, porpagina: number, ordena: string): TServiceQueryResponse<IJsonExtrato> {
    return this._apiService.query<IJsonExtrato>({
      url: `${this._path}/empresagabinete/extratoconta`,
      params: {
        nconta: nconta,
        pagina: pagina,
        porpagina: porpagina,
        ordena: ordena
      }
    });
  }

  public getDocumento(faccbId: number): TServiceResponse<IJsonDocComercial> {
    return this._apiService.get<IJsonDocComercial>({url: `${this._path}/empresagabinete/documentoscomerciais/${faccbId}`});
  }

  public getPdfUrlDocumento(faccbId: number): Observable<string> {
    return of(`${this._path}/empresagabinete/documentoscomerciais/${faccbId}/pdf`);
  }

  public getPrhEventosConfig(tipoevento?: EGestaoDGEMPSType): TServiceQueryResponse<IJsonPRHEventosConfig> {
    return this._apiService.query<IJsonPRHEventosConfig>({
      url: `${this._path}/recursoshumanos/eventos`,
      params: {tipoevento: tipoevento}
    });
  }

  public getPrhServicoListaEmpregados(codservico: number): TServiceResponse<Array<IJsonDGEMP>> {
    return this._apiService.get<Array<IJsonDGEMP>>({url: `${this._path}/recursoshumanos/servicos/${codservico}/listaempregados`});
  }

  public getEmpregado(codEmp: number): TServiceResponse<IJsonDGEMP> {
    return this._apiService.get<IJsonDGEMP>({url: `${this._path}/recursoshumanos/empregados/${codEmp}`});
  }

  public getServicosEmpregado(codEmp: number): TServiceQueryResponse<IJsonPRHServicoEmpregado> {
    return this._apiService.query<IJsonPRHServicoEmpregado>({url: `${this._path}/recursoshumanos/empregados/${codEmp}/servicos`});
  }

  public getMarcacoesAbonos(codservico: number, dataDe: TDate, dateAte: TDate, dataContext?: TDate): TServiceResponse<IJsonPRHServicoMarcacoes> {
    return this._apiService.get<IJsonPRHServicoMarcacoes>({
      url: `${this._path}/recursoshumanos/servicos/${codservico}/marcacoesabonos`,
      params: {datade: dataDe, dataate: dateAte, datacontext: dataContext}
    });
  }

  public getMarcacoesFaltas(codservico: number, dataDe: TDate, dateAte: TDate, dataContext?: TDate): TServiceResponse<IJsonPRHServicoMarcacoes> {
    return this._apiService.get({
      url: `${this._path}/recursoshumanos/servicos/${codservico}/marcacoesfaltas`,
      params: {datade: dataDe, dataate: dateAte, datacontext: dataContext}
    });
  }

  public getMarcacoesFerias(codservico: number, dataDe: TDate, dateAte: TDate, dataContext?: TDate): TServiceResponse<IJsonPRHServicoMarcacoes> {
    return this._apiService.get<IJsonPRHServicoMarcacoes>({
      url: `${this._path}/recursoshumanos/servicos/${codservico}/marcacoesferias`,
      params: {datade: dataDe, dataate: dateAte, datacontext: dataContext}
    });
  }

  public getDatasMarcacoesPendentes(tipo: EPRHFluxoIndiceTipo, codservico: number, dataContext: TDate): TServiceResponse<IJsonPRHServicoDatasMarcacoesPendentes> {
    return this._apiService.get<IJsonPRHServicoMarcacoes>({
      url: `${this._path}/recursoshumanos/servicos/${codservico}/dataspendentes`,
      params: {tipo: tipo, datacontext: dataContext}
    });
  }

  public getConfiguracoesByTipo(tipo: EGestaoDGEMPSType): TServiceResponse<IJsonConfigEstrutura> {
    return this._apiService.get<IJsonConfigEstrutura>({url: `${this._path}/recursoshumanos/eventos/tipo/${tipo}`});
  }

  public getListaMarcacoes(codEmp: number, dataDe: TDate, dataAte: TDate): TServiceResponse<IJsonDGEMPMarcacoes> {
    return this._apiService.get<IJsonDGEMPMarcacoes>({
      url: `${this._path}/recursoshumanos/empregados/${codEmp}/listamarcacoes`,
      params: {datade: dataDe, dataate: dataAte}
    });
  }

  public getPrhFluxosByTipo(tipo: EPRHFluxoIndiceTipo): TServiceQueryResponse<IJsonPRHFluxo> {
    return this._apiService.query<IJsonPRHFluxo>({url: `${this._path}/recursoshumanos/fluxos`, params: {tipo: tipo}});
  }

  public marcarAbonosEmpregado(codEmp: number, prhAbono: IJsonPRHMarcarAbonos): TServiceResponse<IJsonPRHMarcarAbonos> {
    return this._apiService.post<IJsonPRHMarcarAbonos>({
      url: `${this._path}/recursoshumanos/abonos/empregados/${codEmp}`,
      body: prhAbono
    });
  }

  public marcarFaltasEmpregado(codEmp: number, prhFaltas: IJsonPRHMarcarFaltas): TServiceResponse<IJsonPRHMarcarFaltas> {
    return this._apiService.post<IJsonPRHMarcarFaltas>({
      url: `${this._path}/recursoshumanos/faltas/empregados/${codEmp}`,
      params: {codEmp: codEmp},
      body: prhFaltas
    });
  }

  public marcarFeriasEmpregado(codEmp: number, prhFerias: IJsonPRHMarcarFerias): TServiceResponse<IJsonPRHMarcarFerias> {
    return this._apiService.post<IJsonPRHMarcarFerias>({
      url: `${this._path}/recursoshumanos/ferias/empregados/${codEmp}`,
      params: {codEmp: codEmp},
      body: prhFerias
    });
  }

  public deleteAbono(codEmp: number, idTarefaCab: number, remunId: string): TServiceResponse<void> {
    return this._apiService.delete<void>({url: `${this._path}/recursoshumanos/abonos/empregados/${codEmp}/tarefas/${idTarefaCab}/${remunId}`});
  }

  public deleteFalta(codEmp: number, idTarefaCab: number, ocupacaoId: number): TServiceResponse<void> {
    return this._apiService.delete<void>({url: `${this._path}/recursoshumanos/faltas/empregados/${codEmp}/tarefas/${idTarefaCab}/${ocupacaoId}`});
  }

  public deleteDiaFerias(codEmp: number, idTarefaCab: number, ocupacaoId: number): TServiceResponse<void> {
    return this._apiService.delete<void>({url: `${this._path}/recursoshumanos/ferias/empregados/${codEmp}/tarefas/${idTarefaCab}/${ocupacaoId}`});
  }

  public getEstadosProcessamentoSalario(codemp: number, nProcessamento: number): TServiceResponse<IJsonEstadosProcessamento> {
    return this._apiService.get<IJsonEstadosProcessamento>({
      url: `${this._apiService.path.restapi}/dgemps/${codemp}/estadoprocessamento`,
      params: {nprocessamento: nProcessamento}
    });
  }

  public getSendEmailReciboVencimentoPdf(codEmpDe: number, codEmpAte: number, nProcessamento: number, reportName: string, targetEmail: string): TServiceResponse<boolean> {
    return this._apiService.get<boolean>({
      url: `${this._path}/recursoshumanos/enviaemailrecibosvencimento`,
      params: {
        codEmpDe: codEmpDe,
        codEmpAte: codEmpAte,
        nProcessamento: nProcessamento,
        reportName: reportName,
        targetEmail: targetEmail
      }
    });
  }

  public getTipoPasswordRecibos(): TServiceResponse<number> {
    return this._apiService.get<number>({
      url: `${this._path}/recursoshumanos/recibosvencimento/password`
    });
  }

  public saveTipoPasswordRecibos(tipoPassword: number): TServiceResponse<void> {
    return this._apiService.post<void>({
      url: `${this._path}/recursoshumanos/recibosvencimento/password/${tipoPassword}`
    });
  }

  public getPrintUrl(type: EGestaoDGEMPSType, deData: TDate, ateData: TDate, codEmpDe: number, codEmpAte: number, soAtivos: boolean, reportName: string): Observable<string> {
    if (type === EGestaoDGEMPSType.Abonos) {
      return of('');
    }
    const printUrl: string = type === EGestaoDGEMPSType.Faltas ? 'printfaltas' : 'printferias';
    return buildSessionUrlWithParams(`${this._path}/recursoshumanos/${printUrl}`, {
      deData: deData,
      ateData: ateData,
      codEmpDe: codEmpDe,
      codEmpAte: codEmpAte,
      soAtivos: soAtivos,
      reportName: reportName
    });
  }

  public getRHMapaSindicatosUrl(deSindicato: number, ateSindicato: number, deData: TDate, ateData: TDate, reportName: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/recursoshumanos/rh/mapasindicatos`, {
      desindicato: deSindicato,
      atesindicato: ateSindicato,
      dedata: deData,
      atedata: ateData,
      reportname: reportName
    });
  }

  public getModeloImportacao(type: EGenericImporterType): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({url: `${this._path}/movimentosemaberto/import/modelo/xls`, responseType: 'blob', params: {tipomodelo: type}});
  }

  public getExecuteImportacaoUrl(): string {
    return `${this._path}/movimentosemaberto/import/execute/xls`;
  }

  public executeImportacao(type: EGenericImporterType): TServiceResponse<Blob> {
    return this._apiService.post<Blob, void>({url: this.getExecuteImportacaoUrl(), responseType: 'blob', params: {tipomodelo: type}});
  }
}
