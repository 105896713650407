import {Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {isBoolean, isNumber, isUndefined, timeout} from 'pl-comps-angular';
import {CGPanelSliderContentDirective} from './panelslider.content.directive';

const DEFAULT_DURATION = 150;

@Component({
  selector: 'cg-panel-slider',
  templateUrl: './panelslider.component.html',
  animations: [
    trigger('slide', [
      state(
        'true',
        style({
          top: '100%',
          borderColor: 'transparent'
        })
      ),
      state('false', style({})),
      transition('false <=> true', animate('{{duration}}'))
    ])
  ]
})
export class CGPanelSliderComponent implements OnInit, OnChanges {
  @Input() public collapsed: boolean;
  @Input() public duration: number;
  @Input() public closeable: boolean;
  @Output() public readonly collapsedChange: EventEmitter<boolean>;

  @ContentChild(CGPanelSliderContentDirective, {static: true}) public templateContent: CGPanelSliderContentDirective;
  public paramDuration: string;
  public collapsedValue: boolean;
  public showContent: boolean;

  constructor() {
    this.collapsedChange = new EventEmitter<boolean>();
    this.collapsedValue = true;
    this.showContent = !this.collapsedValue;
  }

  public ngOnInit(): void {
    if (isUndefined(this.collapsed)) {
      this.collapsed = true;
    }
    this._handleChanges();
  }

  public ngOnChanges({duration, collapsed, closeable}: SimpleChanges): void {
    if (duration && !collapsed.isFirstChange()) {
      this._changedDuration(duration.currentValue);
    }
    if (collapsed && !collapsed.isFirstChange()) {
      this._changedCollapsed(collapsed.currentValue);
    }
    if (closeable && !closeable.isFirstChange()) {
      this._changedCloseable(closeable.currentValue);
    }
  }

  public close(): void {
    if (!this.closeable) {
      return;
    }
    this._changedCollapsed(true).then(() => {
      this.collapsedChange.emit(this.collapsedValue);
    });
  }

  private _handleChanges(): void {
    this._changedDuration();
    this._changedCollapsed();
    this._changedCloseable();
  }

  private _changedDuration(value: number = this.duration): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = DEFAULT_DURATION;
    }
    this.duration = val;
    this.paramDuration = `${this.duration}ms`;
  }

  private _changedCollapsed(value: boolean = this.collapsed): Promise<void> {
    const collapsed = Boolean(value);
    const showContent = !collapsed;
    if (collapsed) {
      this.collapsedValue = collapsed;
      return timeout(this.duration).then(() => {
        this.showContent = showContent;
      });
    }
    this.showContent = true;
    return timeout(this.duration).then(() => {
      this.collapsedValue = collapsed;
    });
  }

  private _changedCloseable(value: boolean = this.closeable): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = true;
    }
    this.closeable = val;
  }
}
