import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_COD_POSTAIS} from '../codpostais/codPostais.entity.interface';
import {ENTITY_NAME_LOCAL} from '../local/local.entity.interface';
import {ENTITY_NAME_REP_FINANCAS} from '../repfinancas/repFinancas.entity.interface';
import {IJsonSIND} from './jsonSind.entity.interface';
import {ENTITY_NAME_SIND} from './sind.entity.interface';

export const ENTITY_SIND: IEntityDefinition<IJsonSIND> = {
  name: ENTITY_NAME_SIND,
  roles: [ROLE.RH],
  pageTitle: 'global.menu.sind',
  searchPlaceholder: 'sind.pesquisa',
  metadata: {
    keyName: 'nSindicato',
    fields: [
      {name: 'nSindicato', type: 'cgsmallint', caption: 'sind.fields.nSindicato', placeholder: 'sind.fields.nSindicato', validators: {required: true}},
      {name: 'nome', caption: 'sind.fields.nome', placeholder: 'sind.fields.nome', validators: {required: true}},
      {name: 'designaBreve', caption: 'sind.fields.designaBreve', placeholder: 'sind.fields.designaBreve', validators: {required: true, maxlength: 150}},
      {name: 'morada', caption: 'sind.fields.morada', placeholder: 'sind.fields.morada'},
      {
        name: 'localidade',
        caption: 'sind.fields.localidade',
        placeholder: 'sind.fields.localidade',
        entity: {name: ENTITY_NAME_LOCAL, keyTarget: 'localidade', outputTarget: 'nomeLocalidade', fieldsMap: {nLocalidade: 'localidade', designaBreve: 'nomeLocalidade'}},
        validators: {required: true}
      },
      {
        name: 'nPostal',
        caption: 'sind.fields.npostal',
        placeholder: 'sind.fields.npostal',
        entity: {name: ENTITY_NAME_COD_POSTAIS, keyTarget: 'nPostal', fieldsMap: {codPostal: 'nPostal'}},
        validators: {required: true}
      },
      {
        name: 'bairroFiscal',
        type: 'cgsmallint',
        caption: 'sind.fields.bairroFiscal',
        placeholder: 'sind.fields.bairroFiscal',
        entity: {name: ENTITY_NAME_REP_FINANCAS, keyTarget: 'bairroFiscal', outputTarget: 'nomeBairroFiscal', fieldsMap: {codFiscal: 'bairroFiscal', nome: 'nomeBairroFiscal'}},
        validators: {required: true}
      },
      {name: 'valorMinQuot', type: 'double', caption: 'sind.fields.valorMinQuot', placeholder: 'sind.fields.valorMinQuot', validators: {min: 0}},
      {name: 'nContrib', caption: 'sind.fields.nContrib', placeholder: 'sind.fields.nContrib'},
      {name: 'telefone', caption: 'sind.fields.telefone', placeholder: 'sind.fields.telefone'},
      {name: 'fax', caption: 'sind.fields.fax', placeholder: 'sind.fields.fax'},
      {name: 'nomeLocalidade', caption: 'sind.fields.nomeLocalidade', placeholder: 'sind.fields.nomeLocalidade'},
      {name: 'nomeBairroFiscal', caption: 'sind.fields.nomeBairroFiscal', placeholder: 'sind.fields.nomeBairroFiscal'}
    ],
    order: 'nSindicato',
    searchFields: 'nSindicato,nome,designaBreve,morada,localidade,telefone,npostal,bairroFiscal',
    listFields: 'nSindicato,nome,designaBreve,morada,nomeLocalidade,npostal,nomeBairroFiscal,valorMinQuot,nContrib,telefone,fax',
    detailFields: 'nSindicato,nome,designaBreve,morada,localidade,npostal,bairroFiscal,valorMinQuot,nContrib,telefone,fax'
  },
  autocomplete: {
    rowTemplate: '{{nSindicato}} - {{nome}}',
    output: 'nome',
    searchFields: 'nSindicato,nome,designaBreve,morada,localidade,telefone,npostal,bairroFiscal'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  }
};
