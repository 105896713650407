import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EMapaPagamManualDeleteMethod, RADIO_GROUP_DELETE_METHODS} from '../../mapaPagamentos.module.interface';
import {IRadioGroup} from '../../../../../../common/interfaces/interfaces';
import {MapaPagamentosService} from '../../mapaPagamentos.module.service';
import {TServiceResponse} from '../../../../../services/api/api.service.interface';

@Component({
  selector: 'module-mapa-pagamentos-apagar-prompt-modal',
  templateUrl: './mapaPagamentos.apagar.prompt.modal.component.html'
})
export class MapaPagamentosApagarPromptModalComponent extends CGModalComponent<EMapaPagamManualDeleteMethod> {
  @Input() public nEmpresa: string;
  @Input() public pagamentoGUID: string;
  @Input() public ano: number;
  @Input() public mes: number;
  public deleteMethod: EMapaPagamManualDeleteMethod;
  public deleteMethodProperties: IRadioGroup<EMapaPagamManualDeleteMethod>;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _mapaPagamentosService: MapaPagamentosService
  ) {
    super(_injector);
    this.deleteMethod = EMapaPagamManualDeleteMethod.OnlyMonthOfYear;
    this.deleteMethodProperties = RADIO_GROUP_DELETE_METHODS;
  }

  public readonly fnApagar: () => TServiceResponse<void> = () => this._apagarDoc();

  private _apagarDoc(): TServiceResponse<void> {
    return this._mapaPagamentosService.deletePagamentoManual(this.nEmpresa, this.pagamentoGUID, this.deleteMethod, this.ano, this.mes).then(() => {
      this.close(this.deleteMethod);
      return undefined;
    });
  }
}
