import {Component, Injector, OnDestroy} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isNumber} from 'pl-comps-angular';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {ContabilidadeDigitalService} from '../../../../services/contabilidadedigital/contabilidadedigital.service';

const INTERVAL_TIMEOUT = 5000; // 5 seconds

@Component({
  selector: 'contabilidadedigital-check-folders-created-modal',
  templateUrl: './contabilidadedigital.checkfolderscreated.modal.component.html'
})
export class ContabilidadeDigitalCheckFoldersCreatedModalComponent extends CGModalComponent<void> implements OnDestroy {
  private _intervalId: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService
  ) {
    super(_injector);
    this._intervalId = window.setInterval(() => {
      this._checkFoldersCreated();
    }, INTERVAL_TIMEOUT);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._clearInterval();
  }

  private _checkFoldersCreated(): void {
    this._contabilidadeDigitalService.configs.getEmpresaTemPastasCriadas().then((response: HttpResponse<boolean>) => {
      if (response.body === true) {
        this._clearInterval();
        this.close();
      }
    });
  }

  private _clearInterval(): void {
    if (isNumber(this._intervalId)) {
      window.clearInterval(this._intervalId);
      this._intervalId = undefined;
    }
  }
}
