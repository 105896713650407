<div class="ativosconsistencia-lacandif-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'ativosconsistencia.modal.periodotitle'"></h5>
  </div>

  <div class="modal-body">
    <entity-autocomplete
      entity="periodos"
      attrName="periodo"
      [model]="model"
      (selectedKeyChange)="model.periodo = $event"
      [fieldsMap]="{nome: 'nomePeriodo'}"
      [output]="outputPeriodo"
      outputDescription="nome"
      plAutoFocus>
    </entity-autocomplete>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-dismiss" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
