import {HttpResponse} from '@angular/common/http';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {IApiQueryResponse} from '../../services/api/api.service.interface';
import {ROLE} from '../../services/role.const';
import {DocsComerciaisConfigNomeExportFileModuleComponent} from './components/docsComerciaisConfigNomeFileExport.module.component';
import {MODULE_NAME_DOCS_COMERCIAIS_CONFIG_NOME_FILE_EXPORT} from './docsComerciaisConfigNomeFileExport.module.interface';
import {DocsComerciaisConfigNomeFileExportService} from './docsComerciaisConfigNomeFileExport.module.service';
import {IJsonExportMaskNameFilesType, IJsonMascaraNomeFicheirosExportConfig} from './jsonDocsComerciaisConfigNomeFileExport.module.interface';

export const MODULE_DOCS_COMERCIAIS_CONFIG_NOME_FILE_EXPORT: IModuleDefinition = {
  name: MODULE_NAME_DOCS_COMERCIAIS_CONFIG_NOME_FILE_EXPORT,
  state: {
    url: `/${MODULE_NAME_DOCS_COMERCIAIS_CONFIG_NOME_FILE_EXPORT}`,
    component: DocsComerciaisConfigNomeExportFileModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.docsComerciaisConfigNomeExportFile'
    },
    params: {
      group: {
        type: 'any'
      }
    },
    resolve: [
      {
        provide: 'exportMaskNameFilesTypeList',
        deps: [DocsComerciaisConfigNomeFileExportService],
        useFactory: (docsComerciaisConfigExportService: DocsComerciaisConfigNomeFileExportService): Promise<Array<IJsonExportMaskNameFilesType>> => {
          return docsComerciaisConfigExportService.getTiposConfiguracao().then((response: HttpResponse<IApiQueryResponse<IJsonExportMaskNameFilesType>>) => response.body.list);
        }
      },
      {
        provide: 'dataSourceConfig',
        deps: [DocsComerciaisConfigNomeFileExportService],
        useFactory: (docsComerciaisConfigExportService: DocsComerciaisConfigNomeFileExportService): Promise<Array<IJsonMascaraNomeFicheirosExportConfig>> => {
          return docsComerciaisConfigExportService.getConfiguracoes().then((response: HttpResponse<IApiQueryResponse<IJsonMascaraNomeFicheirosExportConfig>>) => response.body.list);
        }
      }
    ]
  }
};
