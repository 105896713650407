import {IJsonAtivosAquisicao} from '../../ativosaquisicao/jsonAtivosAquisicao.entity.interface';
import {TDate} from '../../../../common/dates';

export enum EAtivosEstado {
  Normal,
  Abatido,
  Vendido,
  Divido
}

export enum EAtivosDepreciacoes {
  EmCurso,
  Concluido
}

export enum EAtivosOrigem {
  Aquisicao,
  Conversao,
  Contabilidade,
  Importacao,
  ImportacaoManual,
  DivisaoAtivo,
  ReavaliacoesNaoLegais,
  AumentoValorIFRS16,
  OperacaoJustoValor,
  InvestimentosEmCurso
}

export interface IRadioItemAtivos {
  value: number;
  label: string;
}

export interface IJsonAtivosCab {
  codAtivo: number;
  designacao: string;
  refExterna: string;
  estado: EAtivosEstado;
  codAtivoPai: number;
  viatLigArtigo11DR: boolean;
  ignoraImpostosDif: boolean;
  origem: EAtivosOrigem;
  depreciacoesEstado: EAtivosDepreciacoes;
  depreciacoesLastAno: number;
  depreciacoesLastMes: number;
  documentoDeAquisicao: string;
  dataEstado: TDate;
  isAtivoBiologicoDeProducao: boolean;
  valorAquisicaoContab: number;
  valorDepreciacaoContab: number;
  valorLiquidoContab: number;
  nomeAtivoPai?: string;
}

export interface IJsonAtivos extends IJsonAtivosCab {
  aquisicao: IJsonAtivosAquisicao;
}

export interface IJsonAtivosError {
  error: string;
}

export interface IJsonAtivosNumber {
  value: number;
}

export interface IJsonAtivosTotals {
  estado: number;
  qtdEstado: number;
  qtdAtivos: number;
  totalValAquisicao: number;
  totalDepAcumulada: number;
  totalValLiquido: number;
}
