import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EMonth, isNumber} from 'pl-comps-angular';
import {ApiService} from './api/api.service';
import {IDashboardsERP, parseDashboardsERP} from './dashboardserp/dashboards.erp.service.interface';
import {IJsonDashboards, IJsonDashboardsPercentagem} from '../interfaces/jsonDashboards.interface';
import {IJsonIvaListagem} from '../entities/ivas/jsonIva.entity.interface';
import {IJsonPendentesLinha} from '../interfaces/jsonContabilidade.interface';
import {Moment} from 'moment';
import {monthDateRange} from '../../common/dates';
import {TServiceQueryResponse, TServiceResponse} from './api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ListagensService {
  constructor(private readonly _apiService: ApiService) {}

  public convertAnoMesToDate(year: number, month: number): {start: Moment; end: Moment} {
    let startDate;
    let endDate;
    /* eslint-disable @typescript-eslint/no-magic-numbers */
    switch (month) {
      case -1: // all year
        startDate = monthDateRange(EMonth.January, year).start;
        endDate = monthDateRange(EMonth.December, year).end;
        break;
      case 13: // 1º trimestre
        startDate = monthDateRange(EMonth.January, year).start;
        endDate = monthDateRange(EMonth.March, year).end;
        break;
      case 14: // 2º trimestre
        startDate = monthDateRange(EMonth.April, year).start;
        endDate = monthDateRange(EMonth.June, year).end;
        break;
      case 15: // 3º trimestre
        startDate = monthDateRange(EMonth.July, year).start;
        endDate = monthDateRange(EMonth.September, year).end;
        break;
      case 16: // 4º trimestre
        startDate = monthDateRange(EMonth.October, year).start;
        endDate = monthDateRange(EMonth.December, year).end;
        break;
      default:
        startDate = monthDateRange(month, year).start;
        endDate = monthDateRange(month, year).end;
        break;
    }
    /* eslint-enable @typescript-eslint/no-magic-numbers */
    return {start: startDate, end: endDate};
  }

  public listagensArtigos(deData: Moment = monthDateRange(EMonth.January).start, ateData: Moment = monthDateRange(EMonth.December).end): TServiceResponse<IJsonDashboardsPercentagem> {
    return this._apiService.get<IJsonDashboardsPercentagem>({
      url: `${this._apiService.path.restapi}/dashboards/listagens/artigos`,
      params: {deData: deData, ateData: ateData, force: true}
    });
  }

  public listagensClientes(deData: Moment = monthDateRange(EMonth.January).start, ateData: Moment = monthDateRange(EMonth.December).end): TServiceResponse<IJsonDashboardsPercentagem> {
    return this._apiService.get<IJsonDashboardsPercentagem>({
      url: `${this._apiService.path.restapi}/dashboards/listagens/clientes`,
      params: {deData: deData, ateData: ateData, force: true}
    });
  }

  public listagensIva(deData: Moment = monthDateRange(EMonth.January).start, ateData: Moment = monthDateRange(EMonth.December).end): TServiceResponse<IJsonIvaListagem> {
    return this._apiService.get<IJsonIvaListagem>({
      url: `${this._apiService.path.restapi}/dashboards/listagens/iva`,
      params: {deData: deData, ateData: ateData, force: true}
    });
  }

  public listagensVendasDoAnoPorMes(ano: number): Promise<IDashboardsERP> {
    if (!isNumber(ano)) {
      ano = 0;
    }
    return this._apiService
      .get<IJsonDashboards>({
        url: `${this._apiService.path.restapi}/dashboards/listagens/vendasdoanopormes`,
        params: {ano: String(ano), force: true}
      })
      .then((response: HttpResponse<IJsonDashboards>) => {
        return this._transformValues(response.body);
      });
  }

  public listagensMovimentosPendentesClientes(search: string, order: string, page: number, perPage: number, searchFields: string): TServiceQueryResponse<IJsonPendentesLinha> {
    return this._apiService.query<IJsonPendentesLinha>({
      url: `${this._apiService.path.restapi}/dashboards/listagens/movimentospendentesclientes`,
      params: {
        pagina: page,
        porpagina: perPage,
        pesquisa: search,
        ordena: order,
        campospesq: searchFields,
        force: true
      }
    });
  }

  public listagensMovimentosPendentesFornecedores(search: string, order: string, page: number, perPage: number, searchFields: string): TServiceQueryResponse<IJsonPendentesLinha> {
    return this._apiService.query<IJsonPendentesLinha>({
      url: `${this._apiService.path.restapi}/dashboards/listagens/movimentospendentesfornecedores`,
      params: {
        pagina: page,
        porpagina: perPage,
        pesquisa: search,
        ordena: order,
        campospesq: searchFields,
        force: true
      }
    });
  }

  public listagensMovimentosPendentesOutrosDevedoresCredores(search: string, order: string, page: number, perPage: number, searchFields: string): TServiceQueryResponse<IJsonPendentesLinha> {
    return this._apiService.query<IJsonPendentesLinha>({
      url: `${this._apiService.path.restapi}/dashboards/listagens/movimentospendentesoutrosdevedorescredores`,
      params: {
        pagina: page,
        porpagina: perPage,
        pesquisa: search,
        ordena: order,
        campospesq: searchFields,
        force: true
      }
    });
  }

  private _transformValues(dashboards: IJsonDashboards): IDashboardsERP {
    return parseDashboardsERP(dashboards);
  }
}
