import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EDateMonth, IPlToolbarItemDefinition, IPlToolbarMenuItem, toInteger} from 'pl-comps-angular';
import {DATA_SOURCE_MESES} from '../../../../datasources/meses/meses.datasource';
import {ENTITY_NAME_ANOS_FATURACAO} from '../../../../entities/anosfaturacao/anosFaturacao.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonAnosFaturacao} from '../../../../entities/anosfaturacao/jsonAnosFaturacao.entity.interface';
import {IJsonDashboardsPercentagem, IJsonDashboardsValorPercentagem} from '../../../../interfaces/jsonDashboards.interface';
import {ListagensService} from '../../../../services/listagens.service';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment, {Moment} from 'moment';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {orderBy} from 'lodash-es';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IApiQueryResponse} from '../../../../services/api/api.service.interface';
import {minDateCG} from '../../../../../common/utils/utils';

const currentYear: number = moment().year();

const PAGE = 1;
const PER_PAGE = 10;
const ORDER = 'ano desc';

@Component({
  selector: 'module-vendas-cliente',
  templateUrl: './vendasCliente.module.component.html'
})
export class VendasClienteModuleComponent extends ModuloComponent implements OnInit {
  @Input() public listagensClientes: IJsonDashboardsPercentagem;

  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonDashboardsValorPercentagem, IJsonDashboardsValorPercentagem>;

  public dataGridSource: Array<IJsonDashboardsValorPercentagem>;

  private readonly _mnuAnos: IPlToolbarItemDefinition;
  private readonly _mnuMeses: IPlToolbarItemDefinition;
  private readonly _anosFaturacaoService: IEntityService<IJsonAnosFaturacao>;

  private _meses: Array<IPlToolbarMenuItem>;
  private _selectedAno: IPlToolbarMenuItem;
  private _selectedMes: IPlToolbarMenuItem;
  private _promise: Promise<void>;
  private _anoEmCurso: number;
  private _mesEmCurso: number;
  private _dataGridInstance: dxDataGrid<IJsonDashboardsValorPercentagem, IJsonDashboardsValorPercentagem>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _listagensService: ListagensService
  ) {
    super(_injector);
    this._anosFaturacaoService = this._entityServiceBuilder.build(ENTITY_NAME_ANOS_FATURACAO);
    this._mnuAnos = {
      id: 'anos',
      order: 1,
      caption: 'global.text.year',
      type: 'dropdown',
      class: 'btn-primary',
      menu: []
    };
    this._mnuMeses = {
      id: 'meses',
      order: this._mnuAnos.order + 1,
      caption: 'vendascliente.btn.mes',
      type: 'dropdown',
      class: 'btn-primary',
      menu: []
    };

    this.dataGridDefinition = {
      columns: [
        {dataField: 'codigo', dataType: 'string', caption: 'vendascliente.fields.codigo'},
        {dataField: 'nome', dataType: 'string', caption: 'vendascliente.fields.nome'},
        {dataField: 'infoString', dataType: 'string', caption: 'vendascliente.fields.infoString'},
        {dataField: 'valor', dataType: 'double', alignment: 'right', caption: 'vendascliente.fields.valorLiquido'},
        {dataField: 'valorIva', dataType: 'double', alignment: 'right', caption: 'vendascliente.fields.valorIva', visible: false},
        {dataField: 'valorTotal', dataType: 'double', alignment: 'right', caption: 'vendascliente.fields.valor', visible: false},
        {dataField: 'percentagem', dataType: 'double', alignment: 'right', caption: 'vendascliente.fields.percentagem'}
      ],
      export: {filename: 'vendas.clientes'},
      remoteOperations: false,
      scrolling: {
        rowRenderingMode: 'virtual'
      },
      searchPanel: {
        visible: true
      },
      paging: {
        enabled: false,
        pageSize: 100
      },
      filterRow: {
        visible: false
      }
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolSearch.visible = true;
    this._loadMenus();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonDashboardsValorPercentagem, IJsonDashboardsValorPercentagem>): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public get promise(): Promise<void> {
    return this._promise;
  }

  public set promise(value: Promise<void>) {
    this._promise = value;
    this._promise.finally(() => {
      this._promise = undefined;
    });
  }

  private _loadMenus(): void {
    this._anoEmCurso = currentYear;
    this._mesEmCurso = -1;
    const anos: Array<IPlToolbarMenuItem> = [];
    let addedCurrentYear = false;
    this._anosFaturacaoService
      .query({ordena: ORDER, pagina: PAGE, porpagina: PER_PAGE})
      .then((response: HttpResponse<IApiQueryResponse<IJsonAnosFaturacao>>) => {
        for (const item of response.body.list) {
          if (item.ano === currentYear) {
            addedCurrentYear = true;
          }
          const itemAno: IPlToolbarMenuItem = {
            caption: String(item.ano),
            active: item.ano === this._anoEmCurso,
            click: (menuItem: IPlToolbarMenuItem) => {
              this._anoChanged(menuItem);
            }
          };
          if (itemAno.active) {
            this._selectedAno = itemAno;
          }
          anos.push(itemAno);
        }
      })
      .finally(() => {
        if (!addedCurrentYear) {
          if (anos.length > 0) {
            const yearIndex0 = toInteger(anos[0].caption);
            if (yearIndex0 > minDateCG().year()) {
              const diffAnos = currentYear - yearIndex0;
              for (let i = 1; i <= diffAnos; i++) {
                const yearToAdd = yearIndex0 + i;
                this._unshiftAnos(anos, yearToAdd);
              }
            } else {
              this._unshiftAnos(anos, currentYear);
            }
          } else {
            this._addAnos(anos, currentYear);
          }
          const itemAno: IPlToolbarMenuItem = anos[0];
          if (itemAno.active) {
            this._selectedAno = itemAno;
          }
        }
        this._mnuAnos.caption = this._getAnoCaption();
        this._mnuAnos.menu = anos;

        this._meses = DATA_SOURCE_MESES.data.map<IPlToolbarMenuItem>((item: IDataSourceItem<EDateMonth>) => {
          const month: number = item.value - 1;
          const itemMes: IPlToolbarMenuItem = {
            id: String(month),
            caption: this._translateService.instant(item.name),
            active: month === this._mesEmCurso,
            click: (menuItem: IPlToolbarMenuItem) => {
              this._mesChanged(menuItem);
            }
          };
          return itemMes;
        });
        const todosMeses: IPlToolbarMenuItem = {
          id: String(-1),
          caption: this._translateService.instant('global.text.all'),
          active: true,
          click: (menuItem: IPlToolbarMenuItem) => {
            this._mesChanged(menuItem);
          }
        };
        this._selectedMes = todosMeses;
        this._meses.unshift(todosMeses);
        this._mnuMeses.caption = this._getMesCaption();
        this._mnuMeses.menu = this._meses;

        this.toolbar.addButton(this._mnuAnos);
        this.toolbar.addButton(this._mnuMeses);
        this._loadListagemClientes();
      });
  }

  private _anoChanged(menuItem: IPlToolbarMenuItem): void {
    if (menuItem.caption !== String(this._anoEmCurso)) {
      if (this._selectedAno) {
        this._selectedAno.active = false;
      }
      this._selectedAno = menuItem;
      this._selectedAno.active = true;
      this._anoEmCurso = Number(menuItem.caption);
      this._mnuAnos.caption = this._getAnoCaption();
      this._loadListagemClientes();
    }
  }

  private _mesChanged(menuItem: IPlToolbarMenuItem): void {
    if (menuItem.id !== String(this._mesEmCurso)) {
      if (this._selectedMes) {
        this._selectedMes.active = false;
      }
      this._selectedMes = menuItem;
      this._selectedMes.active = true;
      this._mesEmCurso = Number(menuItem.id);
      this._mnuMeses.caption = this._getMesCaption();
      this._loadListagemClientes();
    }
  }

  private _getAnoCaption(): string {
    return this._translateService.instant('toolbar.year', {value: this._anoEmCurso});
  }

  private _getMesCaption(): string {
    const nomeMesEmCurso = this._meses[this._mesEmCurso + 1].caption;
    return this._translateService.instant('vendascliente.toolbar.month', {nameMonth: nomeMesEmCurso});
  }

  private _loadListagemClientes(): Promise<void> {
    const date: {start: Moment; end: Moment} = this._listagensService.convertAnoMesToDate(Number(this._selectedAno.caption), Number(this._selectedMes.id));
    this.promise = this._listagensService.listagensClientes(date.start, date.end).then((response: HttpResponse<IJsonDashboardsPercentagem>) => {
      this.listagensClientes = response.body;
      this.dataGridSource = orderBy(response.body.valores, 'percentagem', 'desc');
      this.dataGridSource.forEach((item: IJsonDashboardsValorPercentagem) => {
        item.valorTotal = item.valor + item.valorIva;
      });
    });
    return this.promise;
  }

  private _unshiftAnos(anos: Array<IPlToolbarMenuItem>, year): void {
    anos.unshift({
      caption: String(year),
      active: year === this._anoEmCurso,
      click: (menuItem: IPlToolbarMenuItem) => {
        this._anoChanged(menuItem);
      }
    });
  }

  private _addAnos(anos: Array<IPlToolbarMenuItem>, year): void {
    anos.push({
      caption: String(year),
      active: year === this._anoEmCurso,
      click: (menuItem: IPlToolbarMenuItem) => {
        this._anoChanged(menuItem);
      }
    });
  }
}
