<pl-form (evtSubmitted)="save($event)">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'portals.modals.editnode.title'"></h4>
  </div>

  <div class="modal-body">
    <pl-group>
      <label><span [translate]="!moduleName ? 'portals.modals.editnode.fields.menuTitle' : 'portals.modals.editnode.fields.pageTitle'"></span>:</label>
      <edit>
        <pl-edit type="string" [(model)]="pageTitle" [properties]="{validators: {required: true}}" plAutoFocus></pl-edit>
        <div class="mb-2">
          <small>
            <span [translate]="'global.text.preview'"></span>:&nbsp;"<span>{{ pageTitle | translate }}</span
            >"
          </small>
        </div>
      </edit>
    </pl-group>

    <pl-group *ngIf="moduleName">
      <label
        ><span [translate]="'portals.modals.editnode.fields.sidebarTitle'"></span
        ><i [ngbTooltip]="'portals.modals.editnode.fields.sidebarTitleTooltip' | translate" cgcIcon="fa-info-circle text-info"></i>:</label
      >
      <edit>
        <pl-edit type="string" [(model)]="sidebarTitle" plAutoFocus></pl-edit>
        <div class="mb-2">
          <small>
            <span [translate]="'global.text.preview'"></span>:&nbsp;"<span>{{ sidebarTitle | translate }}</span
            >"
          </small>
        </div>
      </edit>
    </pl-group>

    <pl-group>
      <label><span [translate]="'portals.modals.editnode.fields.icon'"></span>:</label>
      <edit>
        <pl-edit type="string" [(model)]="icon"></pl-edit>
        <small> <span [translate]="'global.text.preview'"></span>:&nbsp;<i [cgcIcon]="icon"></i> </small>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="submit" class="btn btn-primary action-close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></button>

    <button type="button" class="btn btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
  </div>
</pl-form>
