<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h4 class="modal-title" [translate]="'colaboradores.comunicacoesinternas.titlenew'"></h4>
</div>

<div class="modal-body">
  <pl-form>
    <pl-group *ngIf="!home">
      <label [translate]="'colaboradores.comunicacoesinternas.fields.tipoComunicacao'"></label>
      <edit>
        <entity-autocomplete
          entity="prhtipocom"
          [model]="tipoComunicacao"
          (modelChange)="selectTipoComunicacao($event)"
          [properties]="{validators: {required: {value: true}}}"
          plAutoFocus></entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'colaboradores.comunicacoesinternas.fields.titulo'"></label>
      <edit>
        <pl-edit
          type="text"
          attrName="titulo"
          [(model)]="titulo"
          [properties]="{disabled: comIntCab || answer, validators: {required: true, maxlength: 100}}"
          plAutoFocus
          [plAutoFocusDisabled]="!home"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'colaboradores.comunicacoesinternas.fields.texto'"></label>
      <edit>
        <textarea [rows]="answer ? 3 : 1" style="resize: none" class="form-control" [(ngModel)]="texto"></textarea>
      </edit>
    </pl-group>

    <pl-group *ngIf="isTipoRecrutamento && !answer && !comIntCab">
      <label>Categorias:</label>
      <edit>
        <pl-multiselect
          [model]="categoriasSelected"
          attrName="categorias"
          [source]="categoriasSource"
          key="nCateg"
          [template]="'{{nCateg}} - {{designaBreve}}'"
          (evtSelectedRight)="fillColaboradoresRecrutamento()"
          (evtSelectedLeft)="fillColaboradoresRecrutamento()"
          (evtAllRight)="fillColaboradoresRecrutamento()"
          (evtAllLeft)="fillColaboradoresRecrutamento()">
        </pl-multiselect>
      </edit>
    </pl-group>

    <pl-group *ngIf="!answer && !comIntCab">
      <label>Serviço:</label>
      <edit>
        <pl-select
          [model]="servicos"
          [multiple]="true"
          [source]="servicosSource"
          (modelChange)="selectServicos($event)"
          [multipleCheckbox]="true"
          [rowTemplate]="'nome'"
          [valueField]="'codServico'"
          [output]="'nome'"
          [plPromise]="servicosPromise"
          appendTo="body"></pl-select>
      </edit>
    </pl-group>

    <pl-group *ngIf="!isTipoRecrutamento && !answer && !comIntCab">
      <label>Colaboradores:</label>
      <edit>
        <pl-multiselect [(model)]="colaboradoresSelected" attrName="colaboradores" [source]="colaboradoresSource" key="codEmp" [template]="'{{codEmp}} - {{nome}}'"></pl-multiselect>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'gestaodgemps.fields.anexaFicheiro'"></label>
      <edit>
        <pl-edit type="boolean" attrName="anexaFicheiro" [(model)]="anexaPDF"></pl-edit>
      </edit>
    </pl-group>

    <pl-group *ngIf="anexaPDF">
      <edit>
        <pl-upload
          [url]="uploadUrl"
          [hideGlobalActions]="true"
          [hideActions]="true"
          [autoProcessQueue]="false"
          [callback]="plUpload"
          acceptedFiles=".bmp,.png,.jpeg,.jpg,.pdf,.docx,.doc,.csv,.xlsx,.xls">
        </pl-upload>
      </edit>
    </pl-group>
  </pl-form>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <ng-container [ngSwitch]="!!comIntCab">
    <pl-button klass="btn-primary btn-sm action-enviar" *ngSwitchCase="false" [onClick]="gravarComInt"
      ><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.send'"></span>
    </pl-button>

    <pl-button klass="btn-primary btn-sm action-sendFile" *ngSwitchCase="true" [onClick]="sendFile"
      ><i class="fa fa-fw fa-upload"></i>&nbsp;<span [translate]="'global.btn.sendFiles'"></span>
    </pl-button>
  </ng-container>

  <pl-button klass="btn-light btn-sm action-close" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>Fechar</pl-button>
</div>
