import {Injectable, Injector} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {StateService, UIRouterGlobals} from '@uirouter/core';
import {generateUniqueID, isEmpty, PlAlertService, PlTranslateService, toJson} from 'pl-comps-angular';
import {AppService} from '../../../services/app/app.service';
import {FaturacaoEletronicaConfiguracoesService} from './faturacaoEletronicaConfiguracoes.module.service';
import {IOAuth2DigitalSignState, IOAuth2DigitalSignStoredState, STATE_NAME_OAUTH2_DIGITAL_SIGN} from '../../../states/oauth2/digitalsign/oauth2.digitalsign.state.interface';
import {IOAuth2SAFEState, IOAuth2SAFEStoredState, STATE_NAME_OAUTH2_SAFE} from '../../../states/oauth2/safe/oauth2.safe.state.interface';
import {TDate} from '../../../../common/dates';
import {IJsonFatEletronicaUrlResponse} from '../jsonFaturacaoEletronicaConfiguracoes.module.interface';

@Injectable({
  providedIn: 'root'
})
export class FaturacaoEletronicaConfiguracoesUIService extends FaturacaoEletronicaConfiguracoesService {
  private _promiseAddSafeCertificate: Promise<void>;
  private _promiseSAFEObterAtributos: Promise<boolean>;
  private _promiseAddDigitalSign: Promise<void>;
  private _promiseAuthenticateDigitalSign: Promise<void>;

  constructor(
    protected _injector: Injector,
    private readonly _uiRouterGlobals: UIRouterGlobals,
    private readonly _stateService: StateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _appService: AppService
  ) {
    super(_injector);
  }

  public addSAFECertificate(nomeDaConta: string, email: string, validade: TDate, cidadaoNacional: boolean, ambientePreProducao: boolean = false): Promise<void> {
    if (this._promiseAddSafeCertificate) {
      return this._promiseAddSafeCertificate;
    }
    this._promiseAddSafeCertificate = this.getSAFEAuthenticationUrl(nomeDaConta, email, validade, cidadaoNacional, ambientePreProducao)
      .then((response: HttpResponse<IJsonFatEletronicaUrlResponse>) => {
        let newSafeCertificateUrl: string = response.body.url;
        if (isEmpty(newSafeCertificateUrl)) {
          const message: string = this._plTranslateService.translate('faturacaoeletronicaconfiguracoes.errors.emptyNewSafeCertificateUrl');
          this._plAlertService.error(message);
          return Promise.reject(new Error(message));
        }
        newSafeCertificateUrl = this._handleSAFECertificateRedirectUrl(newSafeCertificateUrl, nomeDaConta, true, ambientePreProducao);
        window.open(newSafeCertificateUrl, '_blank');
        return Promise.resolve();
      })
      .finally(() => {
        this._promiseAddSafeCertificate = undefined;
      });
    return this._promiseAddSafeCertificate;
  }

  public safeObterAtributosUI(nomeDaConta: string, validade: TDate, ambientePreProducao: boolean = false): Promise<boolean> {
    if (this._promiseSAFEObterAtributos) {
      return this._promiseSAFEObterAtributos;
    }
    this._promiseSAFEObterAtributos = this.safeObterAtributos(nomeDaConta, validade, ambientePreProducao)
      .then((response: HttpResponse<string>) => {
        let redirectUrl: string = response.body;
        if (!isEmpty(redirectUrl)) {
          redirectUrl = this._handleSAFECertificateRedirectUrl(redirectUrl, nomeDaConta, true, ambientePreProducao);
          window.location.href = redirectUrl;
          return false;
        }
        return true;
      })
      .finally(() => {
        this._promiseSAFEObterAtributos = undefined;
      });
    this._appService.setGlobalLoading(this._promiseSAFEObterAtributos);
    return this._promiseSAFEObterAtributos;
  }

  public addDigitalSignAuthorizer(nomeAutorizador: string, id: string, secret: string, ambientePreProducao: boolean = false): Promise<void> {
    if (this._promiseAddDigitalSign) {
      return this._promiseAddDigitalSign;
    }
    this._promiseAddDigitalSign = this.getDigitalSignAuthenticationUrl(nomeAutorizador, id, secret, ambientePreProducao)
      .then((response: HttpResponse<IJsonFatEletronicaUrlResponse>) => {
        let newDigitalSignUrl: string = response.body.url;
        if (isEmpty(newDigitalSignUrl)) {
          const message: string = this._plTranslateService.translate('faturacaoeletronicaconfiguracoes.errors.emptyNewDigitalSignUrl');
          this._plAlertService.error(message);
          return Promise.reject(new Error(message));
        }
        newDigitalSignUrl = this._handleDigitalSignCertificateRedirectUrl(newDigitalSignUrl, nomeAutorizador, false, ambientePreProducao, id, secret);
        window.location.href = newDigitalSignUrl;
        return Promise.resolve();
      })
      .finally(() => {
        this._promiseAddDigitalSign = undefined;
      });
    return this._promiseAddDigitalSign;
  }

  public authenticateDigitalSignAuthorizerUI(nomeAutorizador: string, authorizerSecretDigitalSign: string, idAutorizador: string, ambientePreProducao: boolean = false): Promise<void> {
    if (this._promiseAuthenticateDigitalSign) {
      return this._promiseAuthenticateDigitalSign;
    }
    this._promiseAuthenticateDigitalSign = this.authenticateDigitalSignAuthorizer(nomeAutorizador, ambientePreProducao)
      .then((response: HttpResponse<IJsonFatEletronicaUrlResponse>) => {
        let redirectUrl: string = response.body.url;
        if (isEmpty(redirectUrl)) {
          const message: string = this._plTranslateService.translate('faturacaoeletronicaconfiguracoes.errors.emptyNewDigitalSignUrl');
          this._plAlertService.error(message);
          return Promise.reject(new Error(message));
        }
        redirectUrl = this._handleDigitalSignCertificateRedirectUrl(redirectUrl, nomeAutorizador, true, ambientePreProducao, authorizerSecretDigitalSign, idAutorizador);
        window.location.href = redirectUrl;
        return Promise.resolve();
      })
      .finally(() => {
        this._promiseAuthenticateDigitalSign = undefined;
      });
    this._appService.setGlobalLoading(this._promiseAuthenticateDigitalSign);
    return this._promiseAuthenticateDigitalSign;
  }

  private _handleSAFECertificateRedirectUrl(url: string, nomeDaConta: string, obterAtributos: boolean, ambientePreProducao: boolean): string {
    const redirectUrl: URL = new URL(url);

    const safeState: IOAuth2SAFEStoredState = {
      stateName: this._uiRouterGlobals.current.name,
      nomeDaConta: nomeDaConta,
      safetyKey: redirectUrl.searchParams.get('state'),
      obterAtributos: obterAtributos,
      ambientePreProducao: ambientePreProducao
    };

    /** Due to a limitation on AMA's state maximum string length we have to store a temporary state associated
     with a unique ID on session storage and send that ID as the state */
    const stateID: string = generateUniqueID('oauth2safe');
    const serializedState: string = toJson(safeState);
    window.sessionStorage.setItem(stateID, serializedState);

    const state: IOAuth2SAFEState = {
      stateID: stateID,
      redirectUrl: this._stateService.href(STATE_NAME_OAUTH2_SAFE, undefined, {absolute: true})
    };

    redirectUrl.searchParams.set('state', encodeURIComponent(toJson(state)));
    return redirectUrl.toJSON();
  }

  private _handleDigitalSignCertificateRedirectUrl(
    url: string,
    nomeAutorizador: string,
    authenticate: boolean,
    ambientePreProducao: boolean,
    authorizerSecretDigitalSign: string,
    idAutorizador: string
  ): string {
    const redirectUrl: URL = new URL(url);

    const digitalSignState: IOAuth2DigitalSignStoredState = {
      stateName: this._uiRouterGlobals.current.name,
      nomeAutorizador: nomeAutorizador,
      safetyKey: redirectUrl.searchParams.get('state'),
      authenticate: authenticate,
      ambientePreProducao: ambientePreProducao,
      idAutorizador: idAutorizador,
      authorizerSecretDigitalSign: authorizerSecretDigitalSign
    };

    const stateID: string = generateUniqueID('oauth2digitalSign');
    const serializedState: string = toJson(digitalSignState);
    window.sessionStorage.setItem(stateID, serializedState);

    const state: IOAuth2DigitalSignState = {
      stateID: stateID,
      redirectUrl: this._stateService.href(STATE_NAME_OAUTH2_DIGITAL_SIGN, undefined, {absolute: true})
    };

    redirectUrl.searchParams.set('state', encodeURIComponent(toJson(state)));
    return redirectUrl.toJSON();
  }
}
