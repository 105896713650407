import {Injectable} from '@angular/core';
import {IJsonPrevTesObra} from './jsonPrevTesObras.module.interface';
import {ApiService} from '../../../services/api/api.service';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class PrevTesObrasService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/prevtesobras`;
  }

  public getListaPrevTesObras(codtipoplaneamentode: number, codtipoplaneamentoate: number, codestadoobrade: string, codestadoobraate: string): TServiceResponse<Array<IJsonPrevTesObra>> {
    return this._apiService.get<Array<IJsonPrevTesObra>>({
      url: this._path,
      params: {
        codtipoplaneamentode: codtipoplaneamentode,
        codtipoplaneamentoate: codtipoplaneamentoate,
        codestadoobrade: codestadoobrade,
        codestadoobraate: codestadoobraate
      }
    });
  }

  public importPrevTesObras(
    codtipoplaneamentode: number,
    codtipoplaneamentoate: number,
    codestadoobrade: string,
    codestadoobraate: string,
    codrubrica: number,
    prevtesobras: Array<IJsonPrevTesObra>
  ): TServiceResponse<Array<IJsonPrevTesObra>> {
    return this._apiService.post<Array<IJsonPrevTesObra>>({
      url: this._path,
      body: prevtesobras,
      params: {
        codtipoplaneamentode: codtipoplaneamentode,
        codtipoplaneamentoate: codtipoplaneamentoate,
        codestadoobrade: codestadoobrade,
        codestadoobraate: codestadoobraate,
        codrubrica: codrubrica
      }
    });
  }
}
