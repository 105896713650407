import {NgModule} from '@angular/core';
import {PlFallbackSrcDirective} from './fallback.src.directive';

export * from './fallback.src.directive';

const DECLARATIONS = [
  PlFallbackSrcDirective
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlFallbackSrcModule {
}
