import {ENTITY_NAME_CLTRB} from './cltrb.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonCLTRB} from './jsonClTrb.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_CLTRB: IEntityDefinition<IJsonCLTRB> = {
  name: ENTITY_NAME_CLTRB,
  roles: [ROLE.RH],
  pageTitle: 'global.menu.cltrb',
  searchPlaceholder: 'cltrb.pesquisa',
  metadata: {
    keyName: 'nclasTrab',
    fields: [
      {name: 'nclasTrab', type: 'text', caption: 'cltrb.fields.nclasTrab', validators: {required: true, maxlength: 4}},
      {name: 'designaBreve', type: 'text', caption: 'cltrb.fields.designaBreve', validators: {required: true, maxlength: 20}},
      {name: 'designaCompl', type: 'text', caption: 'cltrb.fields.designaCompl', validators: {required: true, maxlength: 40}}
    ],
    order: 'nclasTrab',
    searchFields: 'nclasTrab,designaBreve,designaCompl'
  },
  autocomplete: {
    rowTemplate: '{{nclasTrab}} - {{designaBreve}}',
    output: 'designaBreve',
    searchFields: 'nclasTrab,designaBreve,designaCompl'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  }
};
