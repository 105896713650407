import {orderBy} from 'lodash-es';
import {Subscription} from 'rxjs';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {cgcFilter, IPlUITreeDrag, IPlUITreeDragNode, IPlUITreeDragNodeInstance, isArray, isBoolean, isString} from 'pl-comps-angular';
import {CGModalService} from '../cg/modal/cgmodal.service';
import {ConfigOptionsService} from '../../services/config/options/config.options.service';
import {EConfigOptionsInstanceName, IAdminPortaisConfigOptions, TConfigOptions} from '../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../config/constants';
import {EntityRegistryService} from '../entity/entity.registry.service';
import {IEntity} from '../entity/entity.definition.interface';
import {IModule} from '../module/module.definition.interface';
import {IPortalModule, trimPortalModuleIcons} from '../../entities/portal/portal.entity.interface';
import {IPortalUITreeEditNodeModalResult} from './editnodemodal/portal.uitree.editnode.modal.component.interface';
import {IPortalUITreeMeta, IPortalUITreeMetaTitle} from './portal.uitree.component.interface';
import {ModuleRegistryService} from '../module/module.registry.service';
import {PortalUITreeEditNodeModalComponent} from './editnodemodal/portal.uitree.editnode.modal.component';
import {ROLE} from '../../services/role.const';

// eslint-disable-next-line @typescript-eslint/no-base-to-string
const OBJECT_NAME: string = Object.prototype.toString();

@Component({
  selector: 'portal-ui-tree',
  templateUrl: './portal.uitree.component.html'
})
export class PortalUITreeComponent implements OnInit, OnDestroy {
  @Input() public menu: Array<IPortalModule>;
  @Input() public editing: boolean;
  @Input() public maxLevels: number;
  @Input() public toolbarInstance: string;
  @Output() public readonly evtChangedMenu: EventEmitter<Array<IPortalModule>>;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public modulos: IPlUITreeDrag<IPortalUITreeMeta>;
  public modulosDisponiveis: IPlUITreeDrag<IPortalUITreeMeta>;
  public filter: string;
  public showModuleName: boolean;

  private readonly _subscriptionConfigOptions: Subscription;
  private _modulosDisponiveis: Array<IPlUITreeDragNode<IPortalUITreeMeta>>;

  constructor(
    private readonly _moduleRegistryService: ModuleRegistryService,
    private readonly _entityRegistryService: EntityRegistryService,
    private readonly _translateService: TranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _configOptionsService: ConfigOptionsService
  ) {
    this.configOptionsGroupName = EGroupName.ADMINITRACAO;
    this.configOptionsInstanceName = EConfigOptionsInstanceName.ADMIN_PORTAIS;
    this.evtChangedMenu = new EventEmitter<Array<IPortalModule>>();
    this.modulos = {items: []};
    this.filter = '';

    this._loadModulosDisponiveis();

    this.modulosDisponiveis = {items: this._modulosDisponiveis};

    this._subscriptionConfigOptions = this._configOptionsService
      .getOptionsAdministracao()
      .get(this.configOptionsInstanceName)
      .options()
      .subscribe((configOptions: TConfigOptions<boolean, IAdminPortaisConfigOptions>) => {
        this.showModuleName = configOptions.get('showModuleName').value;
      });
  }

  public ngOnInit(): void {
    if (isArray(this.menu)) {
      this.modulos = {items: this._loadMenuUiTree(this.menu)};
      if (!this.modulos.items.length) {
        this.modulos.items.push(this._newMenu());
        this.menuChanged();
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigOptions.unsubscribe();
  }

  public menuChanged(): void {
    this.menu = this._updateMenu(this.modulos.items);
    this.evtChangedMenu.emit(this.menu);
  }

  public filterModulosDisponiveis(value: string): void {
    this.filter = value;
    this.modulosDisponiveis = {items: cgcFilter(this._modulosDisponiveis, this.filter)};
  }

  public async droppedNode(node: IPlUITreeDragNode<IPortalUITreeMeta>): Promise<void> {
    const newMenu: string = this._translate('portals.module.newMenu');
    if (!isArray(node.items) || node.items.length > 0 || node.meta.pageTitle.title !== newMenu) {
      return;
    }
    await this.editNode(node.meta);
  }

  public toggleVisibility(node: IPlUITreeDragNode<IPortalUITreeMeta>): void {
    if (!isBoolean(node.meta.visible)) {
      node.meta.visible = true;
    }
    node.meta.visible = !node.meta.visible;
    if (isArray(node.items)) {
      for (const subNode of node.items) {
        subNode.meta.visible = node.meta.visible;
      }
    }
    this.menuChanged();
  }

  public async editNode(meta: IPortalUITreeMeta): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(PortalUITreeEditNodeModalComponent, {size: 'md'});
    const componentInstance: PortalUITreeEditNodeModalComponent = modalInstance.componentInstance;
    componentInstance.moduleName = meta.name;
    componentInstance.pageTitle = meta.pageTitle.title;
    componentInstance.sidebarTitle = meta.sidebarTitle.title;
    componentInstance.icon = meta.icon;
    const {pageTitle, sidebarTitle, icon}: IPortalUITreeEditNodeModalResult = await modalInstance.result;
    if (pageTitle) {
      meta.pageTitle.title = pageTitle;
      meta.pageTitle.translatedTitle = this._translate(pageTitle);
    }
    if (sidebarTitle) {
      meta.sidebarTitle.title = sidebarTitle;
      meta.sidebarTitle.translatedTitle = this._translate(sidebarTitle);
    }
    meta.icon = icon || undefined;
    if (meta.icon) {
      meta.icon = trimPortalModuleIcons(meta.icon);
    }
    this.menuChanged();
  }

  public removeNode(nodeInstance: IPlUITreeDragNodeInstance): void {
    nodeInstance.removeNode();
  }

  public async copyToClipboard(name: string): Promise<void> {
    await navigator.clipboard.writeText(name);
  }

  private _loadModulosDisponiveis(): void {
    this._modulosDisponiveis = [];

    const modules: Array<IModule> = this._moduleRegistryService.getAll(false);
    const entities: Array<IEntity> = this._entityRegistryService.getAll(false);

    for (const module of modules) {
      if (module.state.data.invisible) {
        continue;
      }

      const pageTitle: string = module.state.data.pageTitle;
      const sidebarTitle: string = module.state.data.sidebarTitle || pageTitle;
      const translatedPageTitle: string = this._translate(pageTitle);
      const translatedSidebarTitle: string = this._translate(sidebarTitle);

      this._modulosDisponiveis.push({
        meta: {
          pageTitle: {
            title: pageTitle,
            original: pageTitle,
            translatedTitle: translatedPageTitle,
            translatedOriginal: translatedPageTitle
          },
          sidebarTitle: {
            title: sidebarTitle,
            original: sidebarTitle,
            translatedTitle: translatedSidebarTitle,
            translatedOriginal: translatedSidebarTitle
          },
          icon: module.state.data.icon,
          iconOriginal: module.state.data.icon,
          name: module.name,
          roles: (module.state.data.roles || []).concat(module.state.data.pluginsRoles || []),
          visible: true
        }
      });
    }

    for (const entity of entities) {
      if (entity.asModule === false) {
        continue;
      }

      const pageTitle: string = entity.pageTitle;
      const sidebarTitle: string = entity.sidebarTitle || pageTitle;
      const translatedPageTitle: string = this._translate(pageTitle);
      const translatedSidebarTitle: string = this._translate(sidebarTitle);

      this._modulosDisponiveis.push({
        meta: {
          pageTitle: {
            title: pageTitle,
            original: pageTitle,
            translatedTitle: translatedPageTitle,
            translatedOriginal: translatedPageTitle
          },
          sidebarTitle: {
            title: sidebarTitle,
            original: sidebarTitle,
            translatedTitle: translatedSidebarTitle,
            translatedOriginal: translatedSidebarTitle
          },
          icon: entity.icon,
          iconOriginal: entity.icon,
          name: entity.name,
          roles: (entity.roles || []).concat(entity.pluginsRoles || []),
          visible: true
        }
      });
    }

    this._modulosDisponiveis = orderBy(this._modulosDisponiveis, (modulo) => modulo.meta.pageTitle.translatedTitle.toLowerCase().replace(/[^a-z0-9]/g, ''));
    this._modulosDisponiveis.unshift(this._newMenu());
  }

  private _loadMenuUiTree(menus: Array<IPortalModule>): Array<IPlUITreeDragNode<IPortalUITreeMeta>> {
    return menus.map<IPlUITreeDragNode<IPortalUITreeMeta>>((menu: IPortalModule) => {
      const pageTitle: IPortalUITreeMetaTitle = {title: menu.pageTitle || menu.title, original: undefined, translatedTitle: undefined, translatedOriginal: undefined};
      const sidebarTitle: IPortalUITreeMetaTitle = {title: menu.sidebarTitle, original: undefined, translatedTitle: undefined, translatedOriginal: undefined};
      let icon = menu.icon;
      let iconOriginal: string;
      let roles: Array<ROLE>;
      let pluginsRoles: Array<ROLE>;

      const moduleName: string = menu.name;
      if (moduleName) {
        const module: IModule = this._moduleRegistryService.get(moduleName, false);
        if (module) {
          pageTitle.original = module.state.data.pageTitle;
          if (!pageTitle.title) {
            pageTitle.title = pageTitle.original;
          }
          sidebarTitle.original = module.state.data.sidebarTitle || module.state.data.pageTitle;
          if (!sidebarTitle.title) {
            sidebarTitle.title = sidebarTitle.original;
          }
          iconOriginal = module.state.data.icon;
          if (!icon) {
            icon = iconOriginal;
          }
          roles = module.state.data.roles || [];
          pluginsRoles = module.state.data.pluginsRoles || [];
        } else {
          const entity: IEntity = this._entityRegistryService.getEntity(moduleName, false);
          if (entity) {
            pageTitle.original = entity.pageTitle;
            if (!pageTitle.title) {
              pageTitle.title = entity.pageTitle;
            }
            sidebarTitle.original = entity.sidebarTitle || entity.pageTitle;
            if (!sidebarTitle.title) {
              sidebarTitle.title = sidebarTitle.original;
            }
            iconOriginal = entity.icon;
            if (!icon) {
              icon = iconOriginal;
            }
            roles = entity.roles || [];
            pluginsRoles = entity.pluginsRoles || [];
          }
        }
        if (roles && pluginsRoles?.length) {
          for (const role of pluginsRoles) {
            if (!roles.includes(role)) {
              roles.push(role);
            }
          }
        }
      } else {
        roles = [];
      }

      pageTitle.translatedTitle = pageTitle.title ? this._translate(pageTitle.title) : undefined;
      pageTitle.translatedOriginal = pageTitle.original ? this._translate(pageTitle.original) : undefined;
      sidebarTitle.translatedTitle = sidebarTitle.title ? this._translate(sidebarTitle.title) : undefined;
      sidebarTitle.translatedOriginal = sidebarTitle.original ? this._translate(sidebarTitle.original) : undefined;

      const hasItems = isArray(menu.items) && menu.items.length > 0;

      return {
        meta: {
          name: moduleName,
          pageTitle: pageTitle,
          sidebarTitle: sidebarTitle,
          icon: icon,
          iconOriginal: iconOriginal,
          roles: roles,
          visible: menu.visible
        },
        items: hasItems ? this._loadMenuUiTree(menu.items) : undefined,
        collapsed: hasItems ? true : undefined
      };
    });
  }

  private _updateMenu(menuUiTree: Array<IPlUITreeDragNode<IPortalUITreeMeta>>): Array<IPortalModule> {
    return menuUiTree.map<IPortalModule>((menuUI: IPlUITreeDragNode<IPortalUITreeMeta>) => {
      return {
        name: menuUI.meta.name,
        pageTitle: menuUI.meta.pageTitle.translatedTitle !== menuUI.meta.pageTitle.translatedOriginal ? menuUI.meta.pageTitle.title : undefined,
        sidebarTitle: !menuUI.meta.name || menuUI.meta.sidebarTitle.translatedTitle !== menuUI.meta.sidebarTitle.translatedOriginal ? menuUI.meta.sidebarTitle.title : undefined,
        icon: menuUI.meta.icon !== menuUI.meta.iconOriginal ? (menuUI.meta.icon ? trimPortalModuleIcons(menuUI.meta.icon) : undefined) : undefined,
        roles: menuUI.meta.roles,
        visible: menuUI.meta.visible,
        items: isArray(menuUI.items) ? this._updateMenu(menuUI.items) : undefined
      };
    });
  }

  private _translate(value: string): string {
    if (!isString(value)) {
      return '';
    }
    let translatedValue = String(this._translateService.instant(value));
    if (translatedValue === OBJECT_NAME) {
      translatedValue = value;
    }
    return translatedValue;
  }

  private _newMenu(): IPlUITreeDragNode<IPortalUITreeMeta> {
    const title = 'portals.module.newMenu';
    const translatedTitle: string = this._translate(title);
    return {
      meta: {
        pageTitle: {
          title: title,
          original: title,
          translatedTitle: translatedTitle,
          translatedOriginal: translatedTitle
        },
        sidebarTitle: {
          title: undefined,
          original: undefined,
          translatedTitle: undefined,
          translatedOriginal: undefined
        },
        roles: [],
        name: '',
        icon: '',
        iconOriginal: '',
        visible: true
      },
      items: []
    };
  }
}
