<div class="tribut-autonomas-analise">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="search" plPromise>
        <pl-group>
          <label>
            <span [translate]="'tributAutonomas.header.modeloTributAutonoma'"></span>
            <pl-tooltip class="ms-1" [config]="{text: 'tributAutonomas.messages.modeloTributAutonomaInfo', placement: 'right', container: 'body'}">
              <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
            </pl-tooltip>
          </label>
          <edit>
            <pl-edit-radio-group attrName="modeloTributAutonoma" [(model)]="header.modeloTributAutonoma" [properties]="propertiesModeloTributAutonoma"></pl-edit-radio-group>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'tributAutonomas.header.contasDe'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                attrName="contasDe"
                [model]="header"
                (selectedKeyChange)="header.contasDe = $event"
                [filter]="filterMovimento"
                [fieldsMap]="{nConta: 'contasDe'}"
                output="key"
                plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                attrName="contasAte"
                [model]="header"
                (selectedKeyChange)="header.contasAte = $event"
                [filter]="filterMovimento"
                [fieldsMap]="{nConta: 'contasAte'}"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group *ngIf="header.modeloTributAutonoma !== typeModeloTributAutonoma.IRS" class="align-self-end">
            <label></label>
            <edit>
              <pl-edit type="checkbox" attrName="prejuizoFiscal" [(model)]="header.prejuizoFiscal" [properties]="{label: 'tributAutonomas.header.prejuizoFiscal'}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'tributAutonomas.fields.fromPeriod'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="dePeriodo"
                [model]="header"
                (selectedKeyChange)="periodoDeChanged($event)"
                (evtSelectedDescriptionChanged)="header.nomePeriodoDe = $event"
                [filter]="periodosFilterDe"
                [fieldsMap]="{periodo: 'periodoDe', nome: 'nomePeriodoDe'}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="atePeriodo"
                [model]="header"
                (selectedKeyChange)="header.periodoAte = $event"
                (evtSelectedDescriptionChanged)="header.nomePeriodoAte = $event"
                [filter]="periodosFilterAte"
                [fieldsMap]="{periodo: 'periodoAte', nome: 'nomePeriodoAte'}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <edit>
            <pl-button type="submit" klass="btn btn-sm btn-primary"><i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="tributAutonomasAnalise"
    [dataSource]="dataGridDefinition.dataSource"
    (onContextMenuPreparing)="onContextMenuPreparing($event)">
  </dx-data-grid>
</div>
