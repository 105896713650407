import {MonoTypeOperatorFunction, Observable, Subscriber} from 'rxjs';
import {operate} from 'rxjs/internal/util/lift';

export function skipIf<T>(predicate: (value: T, index: number) => boolean): MonoTypeOperatorFunction<T> {
  return operate((source: Observable<T>, subscriber: Subscriber<T>) => {
    let skipping = true;
    let index = 0;
    source.subscribe({
      next: (value: T) => {
        skipping = Boolean(predicate(value, index++));
        if (!skipping) {
          subscriber.next(value);
        }
      }
    });
  });
}
