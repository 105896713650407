import {Component, OnInit} from '@angular/core';
import {IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonNIFs} from '../../jsonNifs.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';

@Component({
  selector: 'nifs-list',
  templateUrl: './nifs.entity.list.component.html'
})
export class NifsListComponent extends ModuloEntityListComponent<IJsonNIFs> implements OnInit {
  public onDataGridColumnsPreparing(fields: Array<IDevExpressDataGridColumn>): void {
    for (const field of fields) {
      if (field.dataField === 'temCativo' || field.dataField === 'taxaCativo') {
        field.visible = this._configService.configurations.empresa.sujeitaAsLeisVigoramEmAngola;
      }
    }
  }
}
