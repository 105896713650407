import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_TIPO_PERFIL_BALANCETES} from './tipoPerfilBalancetes.datasource.interface';
import {ETipoPerfilBalancetes} from '../../modules/portalcontabilidade/estatistica/balancetes/contabilidade.balancetes.module.interface';

export const DATA_SOURCE_TIPO_PERFIL_BALANCETES: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_PERFIL_BALANCETES,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoPerfilBalancetes.TODAS, name: 'balancetes.tipoperfil.data.todas'},
    {value: ETipoPerfilBalancetes.SALDO, name: 'balancetes.tipoperfil.data.saldo'}
  ]
});
