import {PlTranslateService} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_NIVEL_QUALIFICACAO} from '../../datasources/nivelqualificacao/nivelQualificacao.datasource.interface';
import {ENTITY_NAME_NIVQL} from './nivql.entity.interface';
import {IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonNivQl} from './jsonNivql.entity.interface';
import {ROLE} from '../../services/role.const';
import {TInjectorResolvable} from '../../../common/injectors/common';

export const ENTITY_NIVQL: TInjectorResolvable = [
  PlTranslateService,
  (plTranslateService: PlTranslateService): IEntityDefinition<IJsonNivQl> => {
    const definition: IEntityDefinition<IJsonNivQl> = {
      name: ENTITY_NAME_NIVQL,
      url: 'nivelqualifica',
      roles: [ROLE.RH],
      searchPlaceholder: 'nivql.pesquisa',
      pageTitle: 'global.menu.nivql',
      metadata: {
        keyName: 'nNivQualific',
        fields: [
          {name: 'nNivQualific', caption: 'nivql.fields.nNivQualific', validators: {required: true, maxlength: 4}},
          {name: 'designaBreve', caption: 'nivql.fields.designaBreve', validators: {required: true, maxlength: 20}},
          {name: 'designaComp', caption: 'nivql.fields.designaComp', validators: {maxlength: 40}},
          {
            name: 'ruNivQualific',
            type: DATA_SOURCE_NAME_NIVEL_QUALIFICACAO,
            caption: 'nivql.fields.ruNivQualific',
            validators: {required: true},
            properties: {
              devExpress: {
                dataGrid: {
                  customizeText: (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => {
                    if (cellInfo.target === 'row') {
                      return plTranslateService.translate(`nivql.niveisqualificacao.${cellInfo.valueText}`);
                    }
                    return cellInfo.valueText;
                  }
                }
              }
            }
          }
        ],
        order: 'nNivQualific',
        searchFields: 'nNivQualific,designaBreve,designaComp,ruNivQualific'
      },
      autocomplete: {
        rowTemplate: '{{nNivQualific}} - {{designaBreve}}',
        output: 'designaBreve'
      },
      actions: {
        new: true,
        detail: true,
        edit: true,
        delete: true,
        search: true,
        filter: true
      }
    };
    return definition;
  }
];
