<div class="colaboradores-servicos">
  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="colaboradoresServicos" [dataSource]="dataGridDefinition.dataSource" (onCellClick)="onCellClick($event)">
    <div class="colaboradores-servicos-detail" *dxTemplate="let item of 'masterDetailTemplate'">
      <pl-form>
        <div class="colaboradores-servicos-detail-form">
          <pl-group>
            <label><span [translate]="'gestorrh.servicos.fields.colaborador'"></span>:</label>
            <edit>
              <entity-autocomplete entity="dgemps" [(model)]="item.data.dgemp"></entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label><span [translate]="'gestorrh.servicos.fields.papel'"></span>:</label>
            <edit>
              <entity-autocomplete entity="prhpapeis" [(model)]="item.data.papel" [filter]="filterPapeis"></entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label>&nbsp;</label>
            <edit>
              <pl-button klass="btn-sm btn-primary action-adicionar" [onClick]="fnGravarServicoEmpregado(item.data)">
                <i class="fa fa-plus-circle"></i>&nbsp;<span [translate]="'global.btn.add'"></span>
              </pl-button>
            </edit>
          </pl-group>
        </div>
      </pl-form>

      <div class="colaboradores-servicos-detail-grid">
        <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDetail" [dataSource]="item.data.empregados" (onInitialized)="onInitializedDetail($event, item.data)">
          <div *dxTemplate="let detailItem of 'cellTemplateBtnDetail'">
            <pl-button klass="btn-xs btn-danger action-apagar" [onClick]="fnApagarServicoEmpregado(item.data, detailItem.data.codServicoEmpregado)">
              <i class="fa fa-fw fa-trash"></i>
            </pl-button>
          </div>
        </dx-data-grid>
      </div>
    </div>
  </dx-data-grid>
</div>
