import type {MomentInput, unitOfTime} from 'moment';
import type {IIterableTemplateContext, ITemplateContext, TNgClassSupportedTypes} from '../common/angular.interface';

export interface IPlScheduler<HeaderMeta = void, DatasetMeta = void, DatasetItemMeta = void> {
  header: Array<IPlSchedulerHeader<HeaderMeta>>;
  datasets: Array<IPlSchedulerDataset<DatasetMeta, DatasetItemMeta>>;
  title?: string | number;
  label?: string | number;
}

export interface IPlSchedulerHeader<HeaderMeta = void> {
  label?: string | number;
  cssClass?: TNgClassSupportedTypes;
  meta?: HeaderMeta;
}

export interface IPlSchedulerDataset<DatasetMeta = void, DatasetItemMeta = void> {
  items: Array<IPlSchedulerDatasetItem<DatasetItemMeta>>;
  label?: string | number;
  highlight?: boolean;
  meta?: DatasetMeta;
}

export interface IPlSchedulerDatasetItem<DatasetItemMeta = void> {
  date: MomentInput;
  label?: string | number;
  cssClass?: TNgClassSupportedTypes;
  selectable?: boolean;
  holiday?: boolean;
  active?: boolean;
  meta?: DatasetItemMeta;
}

export interface IPlSchedulerRange {
  datasetStart: number;
  datasetEnd: number;
  datasetItemStart: number;
  datasetItemEnd: number;
}

export interface IPlSchedulerEvtCellClick<DatasetMeta = void, DatasetItemMeta = void> {
  dataset: IPlSchedulerDataset<DatasetMeta, DatasetItemMeta>;
  datasetItem: IPlSchedulerDatasetItem<DatasetItemMeta>;

  preventDefault(): void;

  preventDetail(): void;
}

export interface IPlSchedulerEvtRangeSelect<DatasetItemMeta = void> extends IPlSchedulerRange {
  datasetItems: Array<IPlSchedulerDatasetItem<DatasetItemMeta>>;
  setRange: IPlSchedulerRange; // Set this value to override selected range

  preventDefault(): void;

  preventDetail(): void;
}

export interface IPlSchedulerEvtSelectionChanged<DatasetMeta = void, DatasetItemMeta = void> extends IPlSchedulerRange, IPlSchedulerDatasetDetailTemplateContext<DatasetMeta, DatasetItemMeta> {}

export interface IPlSchedulerTemplateContext<T> extends IIterableTemplateContext<T> {
  item: T;
}

export interface IPlSchedulerHeaderTemplateContext<HeaderMeta = void> extends IPlSchedulerTemplateContext<IPlSchedulerHeader<HeaderMeta>> {}

export interface IPlSchedulerDatasetTemplateContext<DatasetMeta = void, DatasetItemMeta = void> extends IPlSchedulerTemplateContext<IPlSchedulerDataset<DatasetMeta, DatasetItemMeta>> {}

export interface IPlSchedulerDatasetItemTemplateContext<DatasetMeta = void, DatasetItemMeta = void> extends IPlSchedulerTemplateContext<IPlSchedulerDatasetItem<DatasetItemMeta>> {
  dataset: IPlSchedulerDataset<DatasetMeta, DatasetItemMeta>;
}

export interface IPlSchedulerDatasetHeaderDetailTemplateContext<DatasetMeta = void, DatasetItemMeta = void> extends ITemplateContext<IPlSchedulerDataset<DatasetMeta, DatasetItemMeta>> {
  dataset: IPlSchedulerDataset<DatasetMeta, DatasetItemMeta>;
}

export interface IPlSchedulerDatasetDetailTemplateContext<DatasetMeta = void, DatasetItemMeta = void> {
  datasets: Array<IPlSchedulerDataset<DatasetMeta, DatasetItemMeta>>;
  dataset: IPlSchedulerDataset<DatasetMeta, DatasetItemMeta>;
  datasetsItems: Array<IPlSchedulerDatasetItem<DatasetItemMeta>>;
  datasetItem: IPlSchedulerDatasetItem<DatasetItemMeta>;
}

export const SCHEDULER_GRANULARITY: unitOfTime.StartOf = 'date';

export const SCHEDULER_DEFAULT_RANGE: Readonly<IPlSchedulerRange> = Object.freeze({
  datasetStart: -1,
  datasetEnd: -1,
  datasetItemStart: -1,
  datasetItemEnd: -1
});

export const SCHEDULER_CSS_CLASS_HOLIDAY = 'pl-calendar-view-holiday';

export const SCHEDULER_CSS_CLASS_TODAY = 'pl-calendar-view-today';
