<div class="amalia-window" [style.width]="!isMobile && windowWidth ? windowWidth + 'px' : ''">
  <div *ngIf="!isMobile" class="amalia-window-resizer" (mousedown)="onResizerMouseDown($event)"></div>

  <div class="card amalia-chat">
    <div class="card-header amalia-card-header">
      <div class="card-tools d-flex align-items-center gap-2">
        <img ngSrc="../../assets/images/amalia.png" alt="logo-amalia" [width]="50" [height]="50" />
        <h4 class="amalia-chat-title text-white" [translate]="'amalia.title'"></h4>
        <div class="ms-auto d-flex align-items-center gap-2 amalia-header-buttons">
          <button *ngIf="windowViewCompact" type="button" class="amalia-expand-button amalia-header-button" (click)="changeWindowView()"><i class="fa fa-expand"></i></button>
          <button *ngIf="!windowViewCompact" type="button" class="amalia-compress-button amalia-header-button" (click)="changeWindowView()"><i class="fa fa-compress"></i></button>
          <button type="button" class="amalia-close-button amalia-header-button" (click)="closeWindow()"><i class="fa fa-times"></i></button>
        </div>
      </div>
    </div>

    <div class="card-body amalia-chat-messages-wrapper" #elementChat>
      <div class="amalia-chat-messages" *ngIf="agentContext && messages?.length">
        <amalia-chat-message
          *ngFor="let message of messages"
          [agentName]="agentContext.agentName"
          [messageBody]="message.body"
          [timestamp]="message.timestamp"
          [sentByAgent]="message.from === amaliaMessageFrom.Client"
          [timeRead]="1"
          (evtActionIntent)="actionIntent($event)"
          (evtLinkClicked)="handleCustomLinkClick($event)">
        </amalia-chat-message>
      </div>

      <div *ngIf="botLoading" class="amalia-chat-messages">
        <div class="d-flex flex-row">
          <div class="amalia-chat-img d-flex align-self-end">
            <img ngSrc="../../assets/images/amalia.png" alt="logo-amalia" [width]="30" [height]="30" />
          </div>

          <div class="amalia-chat-message-content-wrapper">
            <div class="amalia-chat-content">
              <div class="loader-container">
                <div class="amalia-message-loader">
                  <span class="dot"></span>
                  <span class="dot"></span>
                  <span class="dot"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="amalia-chat-footer-wrapper">
      <div class="d-flex flex-row align-items-center gap-2">
        <div class="input-group amalia-chat-inner-wrapper">
          <input
            type="text"
            class="form-control amalia-chat-input-message"
            [(ngModel)]="textMessage"
            (keydown.enter)="addTextMessage(false)"
            [attr.placeholder]="'amalia.text.promptPlaceholder' | translate"
            plAutoFocus
            #inputElementTextMessage />

          <button type="button" *ngIf="recognition" [disabled]="loading" class="btn amalia-toggle-transcript" [class.btn-outline-danger]="recognizing" (click)="toggleTranscript()">
            <i [cgcIcon]="!recognizing ? 'fa-microphone' : 'fa-microphone-slash'" aria-hidden="true"></i>
          </button>
        </div>
        <button type="button" class="btn amalia-send-message-button" [disabled]="botLoading || loading || !textMessage" (click)="addTextMessage(true)">
          <i cgcIcon="fa-paper-plane" class="text-white" [normalizeSize]="false" aria-hidden="true"></i>
        </button>
      </div>

      <ng-container *ngIf="showPremadeActions">
        <hr />

        <div class="d-flex gap-2 flex-wrap button-group">
          <button *ngFor="let action of premadeActions" type="button" class="btn btn-primary" [disabled]="loading" (click)="addMessage(action)">{{ action }}</button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
