import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../cg/modal/cgmodal.component';

@Component({
  selector: 'contab-digital-client-connect-synchronization-key-modal',
  templateUrl: './contabdigital.clientconnectsynchronizationkey.modal.component.html'
})
export class ContabDigitalClientConnectSynchronizationKeyModalComponent extends CGModalComponent<void> {
  @Input() public key: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }
}
