import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_TES_RUBRICA} from './tesRubrica.entity.interface';
import {ETesRubricaReceitaDespesa, IJsonTesRubrica} from './jsonTesRubrica.entity.interface';
import {IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {TInjectorResolvable} from '../../../common/injectors/common';
import {TranslateService} from '@ngx-translate/core';

const tipoReceitaDespesa = [
  {value: ETesRubricaReceitaDespesa.Receita, label: 'tesrubrica.tipoReceitaDespesa.receita'},
  {value: ETesRubricaReceitaDespesa.Despesa, label: 'tesrubrica.tipoReceitaDespesa.despesa'}
];

export const ENTITY_TES_RUBRICA: TInjectorResolvable = [
  TranslateService,
  (translateService: TranslateService): IEntityDefinition<IJsonTesRubrica> => {
    return {
      name: ENTITY_NAME_TES_RUBRICA,
      roles: [ROLE.CONTABILIDADE, ROLE.ERP],
      pluginsRoles: [ROLE.CONTABILIDADEPREMIUM, ROLE.ERPADVANCED],
      searchPlaceholder: 'tesrubrica.pesquisa',
      sidebarTitle: 'portals.sidebar.modules.tesrubrica',
      metadata: {
        keyName: 'tesRubricaID',
        fields: [
          {name: 'tesRubricaID', caption: 'tesrubrica.fields.tesRubricaID', placeholder: 'tesrubrica.fields.tesRubricaID', validators: {required: true, maxlength: 4}},
          {name: 'nome', caption: 'tesrubrica.fields.nome', placeholder: 'tesrubrica.fields.nome', validators: {required: true, maxlength: 50}},
          {
            name: 'receitaDespesa',
            type: 'radiogroup',
            caption: 'tesrubrica.fields.receitaDespesa',
            properties: {
              groupItems: tipoReceitaDespesa,
              properties: {
                devExpress: {
                  dataGrid: {
                    customizeText: (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => {
                      if (cellInfo.target === 'row') {
                        const tipo = tipoReceitaDespesa[<number>cellInfo.value];
                        if (tipo) {
                          return translateService.instant(tipo.label);
                        }
                      }
                      return cellInfo.valueText;
                    }
                  }
                }
              }
            }
          }
        ],
        order: 'tesRubricaID',
        searchFields: 'tesRubricaID,nome'
      },
      autocomplete: {
        rowTemplate: '{{tesRubricaID}} - {{nome}}',
        output: 'tesRubricaID',
        searchFields: 'tesRubricaID,nome'
      },
      actions: {
        new: true,
        detail: true,
        edit: true,
        search: true,
        delete: true,
        filter: true
      }
    };
  }
];
