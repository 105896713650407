import {IArquivoDigitalDocViewerCallback} from '../../common/docviewer/arquivodigital.docviewer.component.interface';
import {IJsonContabDigitalGDocViewerRecolhaSearch, IJsonContabDigitalGDocViewerRecolhaSearchResult} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {IContabDigitalGDocViewerRecolhaSearchParams} from '../../../../services/contabilidadedigital/contabilidadedigital.interface';
import {ICGHelpTopic, ICGHelpTopicItem} from '../../../cg/modal/helptopics/helptopics.modal.component.interface';

export enum EContabDigitalDocViewerRecolhaValuesSource {
  OCR,
  QrCode,
  EFatura
}

export interface IContabDigitalDocViewerRecolhaCallback extends IArquivoDigitalDocViewerCallback {
  loadDocAttachments?(extPocCabID?: string, year?: number): Promise<void>;

  searchContabilidadeAttachments?(
    search?: IJsonContabDigitalGDocViewerRecolhaSearch,
    searchParams?: IContabDigitalGDocViewerRecolhaSearchParams
  ): Promise<IJsonContabDigitalGDocViewerRecolhaSearchResult>;
}

export interface IContabDigitalDocViewerRecolhaSelectAttachmentOptions {
  emitEvent?: boolean;
  emitEventWithDebounce?: boolean;
  doInitDocumento?: boolean;
}

export const CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR = '\\';

export const CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_CONTABILIDADE = 'Contabilidade';

export const CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_DOCS_NAO_CLASSIFICADOS = 'Documentos não classificados';

export const CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_DOCS_CLASSIFICADOS = 'Documentos classificados';

export const CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_BASIC_CONCEPTS: Readonly<ICGHelpTopicItem> = Object.freeze<ICGHelpTopicItem>({
  title: 'arquivodigital.docviewerrecolha.helptopicsmodal.faq.basicConcepts',
  icon: 'fa-link',
  href: 'https://www.centralgestcloud.com/faqs/topico/183/arquivo-digital/conceitos-basicos-do-arquivo-digital.'
});

export const CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_ADD_DOCUMENTS: Readonly<ICGHelpTopicItem> = Object.freeze<ICGHelpTopicItem>({
  title: 'arquivodigital.docviewerrecolha.helptopicsmodal.faq.addDocuments',
  icon: 'fa-link',
  href: 'https://www.centralgestcloud.com/faqs/topico/178/arquivo-digital/adicionar-documentos-digitais-ao-arquivo-digital.'
});

export const CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_SHOW_HIDE_COLUMNS: Readonly<ICGHelpTopicItem> = Object.freeze<ICGHelpTopicItem>({
  title: 'arquivodigital.docviewerrecolha.helptopicsmodal.faq.showHideColumns',
  icon: 'fa-link',
  href: 'https://www.centralgestcloud.com/faqs/topico/179/arquivo-digital/mostrar-e-ocultar-colunas-na-grelha-de-documentos-digitais.'
});

export const CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_SET_FILE_FOLDERS: Readonly<ICGHelpTopicItem> = Object.freeze<ICGHelpTopicItem>({
  title: 'arquivodigital.docviewerrecolha.helptopicsmodal.faq.setFileFolders',
  icon: 'fa-link',
  href: 'https://www.centralgestcloud.com/faqs/topico/181/arquivo-digital/definir-as-pastas-do-arquivo-digital.'
});

export const CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_MOVE_ATTACHMENT_TO_ANOTHER_FOLDER: Readonly<ICGHelpTopicItem> = Object.freeze<ICGHelpTopicItem>({
  title: 'arquivodigital.docviewerrecolha.helptopicsmodal.faq.moveAttachmentToAnotherFolder',
  icon: 'fa-link',
  href: 'https://www.centralgestcloud.com/faqs/topico/182/arquivo-digital/mudar-o-documento-digital-para-outra-pasta.'
});

export const CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_CREATE_CHECKING_ACCOUNT_FROM_ATTACHMENT: Readonly<ICGHelpTopicItem> = Object.freeze<ICGHelpTopicItem>({
  title: 'arquivodigital.docviewerrecolha.helptopicsmodal.faq.createCheckingAccountFromAttachment',
  icon: 'fa-link',
  href: 'https://www.centralgestcloud.com/faqs/topico/180/arquivo-digital/criar-uma-conta-corrente-a-partir-do-documento-digital.'
});

export const CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_SPEED_UP_MANUAL_ACCOUNT_RECORDING_WITH_ATTACHMENT_DATA: Readonly<ICGHelpTopicItem> = Object.freeze<ICGHelpTopicItem>({
  title: 'arquivodigital.docviewerrecolha.helptopicsmodal.faq.speedUpManualAccountingRecordingWithAttachmentData',
  icon: 'fa-link',
  href: 'https://www.centralgestcloud.com/faqs/topico/184/arquivo-digital/acelerar-o-registo-contabilistico-manual-com-os-dados-do-documento-digital.'
});

export const CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_ADD_DOCUMENTS_THROUGH_PRE_DEFINED: Readonly<ICGHelpTopicItem> = Object.freeze<ICGHelpTopicItem>({
  title: 'arquivodigital.docviewerrecolha.helptopicsmodal.faq.addDocumentsThroughPreDefined',
  icon: 'fa-link',
  href: 'https://www.centralgestcloud.com/faqs/topico/185/arquivo-digital/lancar-na-contabilidade-os-documentos-digitais-atraves-de-pre-definidos./lancar-na-contabilidade-os-documentos-digitais-atraves-de-pre-definidos.'
});

export const CG_HELP_TOPIC_CONTAB_DIGITAL_FAQ: Readonly<ICGHelpTopic> = Object.freeze<ICGHelpTopic>({
  title: 'global.text.faq',
  collapsed: false,
  items: [
    CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_BASIC_CONCEPTS,
    CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_ADD_DOCUMENTS,
    CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_SHOW_HIDE_COLUMNS,
    CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_SET_FILE_FOLDERS,
    CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_MOVE_ATTACHMENT_TO_ANOTHER_FOLDER,
    CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_CREATE_CHECKING_ACCOUNT_FROM_ATTACHMENT,
    CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_SPEED_UP_MANUAL_ACCOUNT_RECORDING_WITH_ATTACHMENT_DATA,
    CG_HELP_TOPIC_CONTAB_DIGITAL_ITEM_ADD_DOCUMENTS_THROUGH_PRE_DEFINED
  ]
});
