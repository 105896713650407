<div class="cg-dashboards dashboard-ativos">
  <div class="row" *ngIf="inconsistContab">
    <div class="col-sm-12">
      <pl-alert type="info" [closeable]="false">
        <span [innerHTML]="'dashboards.ativos.messages.contab' | translate"></span>&nbsp;
        <span class="hyperlink" [translate]="'dashboards.ativos.messages.contabLink'" (click)="redirectToAquisicaoContab()"></span>
      </pl-alert>
    </div>
  </div>

  <div class="row" *ngIf="inconsistInvestEmCurso">
    <div class="col-sm-12">
      <pl-alert type="info" [closeable]="false">
        <span [innerHTML]="'dashboards.ativos.messages.investEmCurso' | translate"></span>&nbsp;
        <span class="hyperlink" [translate]="'dashboards.ativos.messages.investEmCursoLink'" (click)="redirectToInvestEmCurso()"></span>
      </pl-alert>
    </div>
  </div>

  <div class="row cg-dashboards-spacing">
    <div class="col-sm-12 col-lg-4 col-xl-2">
      <pl-dashboard [dashboard]="nFichasAtivos"></pl-dashboard>
    </div>

    <div class="col-sm-12 col-lg-4 col-xl-2">
      <pl-dashboard [dashboard]="nAquisicoesAno"></pl-dashboard>
    </div>

    <div class="col-sm-12 col-lg-4 col-xl-2">
      <pl-dashboard [dashboard]="nVendasAbatesAno"></pl-dashboard>
    </div>

    <div class="col-sm-12 col-lg-4 col-xl-2">
      <pl-dashboard [dashboard]="valorAquisicao"></pl-dashboard>
    </div>

    <div class="col-sm-12 col-lg-4 col-xl-2">
      <pl-dashboard [dashboard]="depreciacoesAcumuladas"></pl-dashboard>
    </div>

    <div class="col-sm-12 col-lg-4 col-xl-2">
      <pl-dashboard [dashboard]="valorAtual"></pl-dashboard>
    </div>
  </div>

  <div class="row cg-dashboards-spacing">
    <div class="col-md-6">
      <div class="card p-3">
        <dx-pie-chart [cgDxPieChart]="chartPieAtivosTangiveis" [dataSource]="chartPieAtivosTangiveisData" (onInitialized)="onInitializedChartPieAtivosTangiveis($event)">
          <div *dxTemplate="let tooltip of 'customTooltipTangiveis'">
            <div>
              <b>{{ tooltip.argument }} - {{ tooltip.point.data.descRadical }}</b>
            </div>
            <span [translate]="'dashboards.ativos.pie.nFichas'" [translateParams]="{fichas: tooltip.point.data.nFichas}"></span>
          </div>
        </dx-pie-chart>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card p-3">
        <dx-pie-chart [cgDxPieChart]="chartPieAtivosIntangiveis" [dataSource]="chartPieAtivosIntangiveisData" (onInitialized)="onInitializedChartPieAtivosIntangiveis($event)">
          <div *dxTemplate="let tooltip of 'customTooltipIntangiveis'">
            <div>
              <b>{{ tooltip.argument }} - {{ tooltip.point.data.descRadical }}</b>
            </div>
            <span [translate]="'dashboards.ativos.pie.nFichas'" [translateParams]="{fichas: tooltip.point.data.nFichas}"></span>
          </div>
        </dx-pie-chart>
      </div>
    </div>

    <div class="col-md-6">
      <div class="card p-3">
        <dx-chart
          [cgDxChart]="chartAtivosDepMonth"
          [dataSource]="chartAtivosDepMonthData"
          (onInitialized)="onInitializedChartTotDepMonth($event)"
          (onPointHoverChanged)="onChartPointHoverChanged($event)"
          (onLegendClick)="onChartLegendClick($event)">
          <div *dxTemplate="let tooltip of 'customTooltipDepMonth'">
            <div>
              <b>{{ tooltip.argument }}</b>
            </div>
            <div *ngFor="let point of tooltip.points">
              <span>{{ point.seriesName }}: </span>
              <span>{{ point.originalValue | cgcCurrency }}</span>
            </div>
            <div>
              <span> {{ 'dashboards.ativos.bar.depAcumulado' | translate }}: </span>
              <span>{{ tooltip.point.data.valorAcumulado | cgcCurrency }}</span>
            </div>
          </div>
        </dx-chart>
      </div>
    </div>

    <div class="col-md-6">
      <pl-dashboard class="cg-dashboards-table" [dashboard]="ativosTopPorDoc"></pl-dashboard>
    </div>
  </div>
</div>
