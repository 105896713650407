<pl-form [submit]="close" [plPromise]="promise">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'docfasnumscomuncicacaologin.title'"></h4>
  </div>

  <div class="modal-body">
    <img alt="Portal" class="d-block simple-login-logo" [src]="'../../../assets/images/logo-at.webp'" />

    <pl-group>
      <label [translate]="'docfasnumscomuncicacaologin.fields.username'"></label>
      <edit>
        <pl-edit type="text" attrName="username" [(model)]="model.username" [properties]="{disabled: !isAdmin}" plAutoFocus></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'docfasnumscomuncicacaologin.fields.password'"></label>
      <edit>
        <pl-edit type="password" attrName="password" [(model)]="model.password" [properties]="{disabled: !isAdmin}"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation>
    <span *ngIf="!isAdmin" [translate]="'docfasnumscomuncicacaologin.messages.noPermissions'"></span>

    <button type="submit" class="btn btn-primary btn-sm" [disabled]="!isAdmin" [plPromise]="promise">
      <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'docfasnumscomuncicacaologin.saveTitle'"></span>
    </button>

    <button type="button" class="btn btn-light btn-sm" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></button>
  </div>
</pl-form>
