import {IDevExpressDataGridState} from '../state/devexpress.datagrid.state.interface';
import {isNumber} from 'pl-comps-angular';
import {pagePerPageToSkipTake} from '../../../../../common/utils/utils';

export function devExpressDataGridExtendState(state: IDevExpressDataGridState): void {
  if (isNumber(state.pageIndex) && isNumber(state.pageSize)) {
    const [skip, take] = pagePerPageToSkipTake(state.pageIndex + 1, state.pageSize);
    state.skip = skip;
    state.take = take;
  }
}
