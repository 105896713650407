<div class="modelo22-anexoA">
  <pl-form>
    <pl-navigator contentMaxHeight="80vh" [plPromise]="promise">
      <pl-navigator-item caption="modelo22.anexoA.quadro3.title">
        <div *plNavigatorItemCaption><span class="number-title-box">03</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoA.quadro3.title'"></span></div>

        <div *plNavigatorItemContent>
          <div><label class="fw-bold" [translate]="'modelo22.anexoA.quadro3.dadosgerais'"></label></div>

          <div class="inputs-currency-inline mt-1 ms-2">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoA.quadro3.field1'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">1</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoAQuadro03Campo01"
                    [model]="anexoA.quadro03.campo01"
                    (modelChange)="anexoA.quadro03.campo01 = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoA.quadro3.field2'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">2</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoAQuadro03Campo02"
                    [model]="anexoA.quadro03.campo02"
                    (modelChange)="anexoA.quadro03.campo02 = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoA.quadro3.field10'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">10</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoAQuadro03Campo10"
                    [model]="anexoA.quadro03.campo10"
                    (modelChange)="anexoA.quadro03.campo10 = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoA.quadro3.field3'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">3</div>
                  <pl-edit type="currency" attrName="anexoAQuadro03Campo3" [model]="anexoA.quadro03.campo03" (modelChange)="anexoA.quadro03.campo03 = $event; emitModelo22()" [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>

          <div><label class="fw-bold" [translate]="'modelo22.anexoA.quadro3.dadosesp'"></label></div>

          <div class="inputs-currency-inline mt-1 ms-2">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoA.quadro3.check4'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex align-items-center">
                  <div class="input-box-numbers">4</div>
                  <pl-edit type="checkbox" attrName="anexoAQuadro03Campo4" [model]="anexoA.quadro03.campo04" (modelChange)="anexoA.quadro03.campo04 = $event; emitModelo22()"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoA.quadro3.field5'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">5</div>
                  <pl-edit type="currency" attrName="anexoAQuadro03Campo5" [model]="anexoA.quadro03.campo05" (modelChange)="anexoA.quadro03.campo05 = $event; emitModelo22()" [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoA.quadro3.field6'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">6</div>
                  <pl-edit type="currency" attrName="anexoAQuadro03Campo6" [model]="anexoA.quadro03.campo06" (modelChange)="anexoA.quadro03.campo06 = $event; emitModelo22()" [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoA.quadro3.field7'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">7</div>
                  <pl-edit type="currency" attrName="anexoAQuadro03Campo7" [model]="anexoA.quadro03.campo07" (modelChange)="anexoA.quadro03.campo07 = $event; emitModelo22()" [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoA.quadro3.field8'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">8</div>
                  <pl-edit type="currency" attrName="anexoAQuadro03Campo8" [model]="anexoA.quadro03.campo08" (modelChange)="anexoA.quadro03.campo08 = $event; emitModelo22()" [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoA.quadro3.field9'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">9</div>
                  <pl-edit type="currency" attrName="anexoAQuadro03Campo9" [model]="anexoA.quadro03.campo09" (modelChange)="anexoA.quadro03.campo09 = $event; emitModelo22()" [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoA.quadro4.title">
        <div *plNavigatorItemCaption><span class="number-title-box">04</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoA.quadro4.title'"></span></div>

        <div *plNavigatorItemContent>
          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">04-A</div>
              <label [translate]="'modelo22.anexoA.quadro4.sect04-A.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionListaA" cgDxDataGridInstanceName="quadro4Sect04A" [dataSource]="dataGridDefinitionListaA.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtnListaA'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoAQuadro4(item.rowIndex, 'A')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid
                  [cgDxDataGrid]="dataGridDefinitionQuadro04Lista04ASomaControlo"
                  cgDxDataGridInstanceName="Modelo22AnexoAQuadro04Lista04ASomaControlo"
                  [dataSource]="anexoAQuadro4ListaATotArray">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">04-B</div>
              <label [translate]="'modelo22.anexoA.quadro4.sect04-B.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionListaB" cgDxDataGridInstanceName="quadro4Sect04B" [dataSource]="dataGridDefinitionListaB.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtnListaB'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoAQuadro4(item.rowIndex, 'B')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid
                  [cgDxDataGrid]="dataGridDefinitionQuadro04Lista04BSomaControlo"
                  cgDxDataGridInstanceName="Modelo22AnexoAQuadro04Lista04BSomaControlo"
                  [dataSource]="anexoAQuadro4ListaBTotArray">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">04-C</div>
              <label [translate]="'modelo22.anexoA.quadro4.sect04-C.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionListaC" cgDxDataGridInstanceName="quadro4Sect04C" [dataSource]="dataGridDefinitionListaC.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtnListaC'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoAQuadro4(item.rowIndex, 'C')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid
                  [cgDxDataGrid]="dataGridDefinitionQuadro04Lista04CSomaControlo"
                  cgDxDataGridInstanceName="Modelo22AnexoAQuadro04Lista04CSomaControlo"
                  [dataSource]="anexoAQuadro4ListaCTotArray">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">04-D</div>
              <label [translate]="'modelo22.anexoA.quadro4.sect04-D.title'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionListaD" cgDxDataGridInstanceName="quadro4Sect04D" [dataSource]="dataGridDefinitionListaD.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtnListaD'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoAQuadro4(item.rowIndex, 'D')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid
                  [cgDxDataGrid]="dataGridDefinitionQuadro04Lista04DSomaControlo"
                  cgDxDataGridInstanceName="Modelo22AnexoAQuadro04Lista04DSomaControlo"
                  [dataSource]="anexoAQuadro4ListaDTotArray">
                </dx-data-grid>
              </div>
            </div>
          </section>
        </div>
      </pl-navigator-item>
    </pl-navigator>
  </pl-form>
</div>
