import {Transition} from '@uirouter/core';
import {Ng2StateDeclaration} from '@uirouter/angular';
import {ChangePasswordComponent} from './changepassword.component';
import {IChangePasswordStateParams, STATE_NAME_CHANGE_PASSWORD} from './changepassword.interface';
import {RESOLVER_PASSWORD_SECURE_MODE} from '../../../../config/uirouter/uirouter.resolvers';
import {RESOLVER_RECAPTCHA_CONFIG} from '../../../services/recaptcha/recaptcha.router';
import {STATE_NAME_ACCOUNT} from '../account.state.interface';
import {STATE_NAME_LOGIN} from '../login/login.state.interface';

export const STATE_CHANGE_PASSWORD: Ng2StateDeclaration = {
  parent: STATE_NAME_ACCOUNT,
  name: STATE_NAME_CHANGE_PASSWORD,
  url: `/${STATE_NAME_CHANGE_PASSWORD}?{token}`,
  component: ChangePasswordComponent,
  data: {
    pageTitle: 'changepassword.title'
  },
  params: {
    token: {
      type: 'string',
      value: '',
      squash: true,
      dynamic: true
    }
  },
  redirectTo: stateChangePasswordRedirectTo,
  resolve: [RESOLVER_RECAPTCHA_CONFIG, RESOLVER_PASSWORD_SECURE_MODE]
};

export function stateChangePasswordRedirectTo(transition: Transition): string {
  const params: IChangePasswordStateParams = <IChangePasswordStateParams>transition.params();
  if (!params.token) {
    console.error('Invalid reset password token.');
    return STATE_NAME_LOGIN;
  }
  return undefined;
}
