import {Injectable, Injector} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {
  IFaturacaoAvencasProcessParams,
  IJsonContratoFaturado,
  IJsonContratoFaturadoDoc,
  IJsonContratoFaturadoDocSel,
  IJsonFaturacaoContrato,
  MODULE_NAME_FATURACAO_CONTRATOS
} from './faturacaoContratos.module.interface';
import {TDate} from '../../../common/dates';
import {EConfigOptionsInstanceName, IConfigOptionsGroupErp, IFaturacaoContratosConfigOptions} from '../../services/config/options/config.options.service.interface';
import {ConfigOptionsInstanceService} from '../../services/config/options/config.options.instance.service';
import {EGroupName} from '../../../config/constants';
import {IApiQueryResponse, TServiceQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class FaturacaoContratosService extends ConfigOptionsInstanceService<boolean, IFaturacaoContratosConfigOptions, IConfigOptionsGroupErp> {
  private readonly _path: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _apiService: ApiService
  ) {
    super(_injector, EGroupName.ERP, EConfigOptionsInstanceName.FATURACAO_CONTRATOS);
    this._path = `${this._apiService.path.restapi}/${MODULE_NAME_FATURACAO_CONTRATOS}`;
  }

  public get(
    nClasseCtr: string,
    nTipoContratoList: string,
    dataDoc: TDate,
    dataDocExterno: TDate,
    nZonaDe: number,
    nZonaAte: number,
    nContaDe: string,
    nContaAte: string,
    loadDadosCC: boolean
  ): Promise<IApiQueryResponse<IJsonFaturacaoContrato>> {
    return new Promise<IApiQueryResponse<IJsonFaturacaoContrato>>((resolve) => {
      this._apiService
        .get<IApiQueryResponse<IJsonFaturacaoContrato>>({
          url: `${this._path}/servicos`,
          params: {
            nclassectr: nClasseCtr,
            tiposcontrato: nTipoContratoList,
            datadoc: dataDoc,
            datadocexterno: dataDocExterno,
            nzonade: nZonaDe,
            nzonaate: nZonaAte,
            ncontade: nContaDe,
            ncontaate: nContaAte,
            loaddadoscc: loadDadosCC,
            skipcontrolaestados: true
          }
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(resolve);
    });
  }

  public post(processParams: IFaturacaoAvencasProcessParams): TServiceResponse<void> {
    return this._apiService.post<void, IFaturacaoAvencasProcessParams>({
      url: `${this._path}/servicos`,
      body: processParams
    });
  }

  public getServicosFaturados(): TServiceResponse<IJsonContratoFaturado> {
    return this._apiService.get<IJsonContratoFaturado>({
      url: `${this._path}/servicos/results`
    });
  }

  public getContratosServicosFaturados(
    nClasseCtr: string,
    tiposContrato: string,
    dataDocDe: TDate,
    dataDocAte: TDate,
    nContaDe: string,
    nContaAte: string,
    estadoDoc: number
  ): TServiceQueryResponse<IJsonContratoFaturadoDoc> {
    return this._apiService.get({
      url: `${this._path}/servicos/faturados`,
      params: {
        nclassectr: nClasseCtr,
        tiposcontrato: tiposContrato,
        datadocde: dataDocDe,
        datadocate: dataDocAte,
        ncontade: nContaDe,
        ncontaate: nContaAte,
        estadodoc: estadoDoc
      }
    });
  }

  public encerrarDocsGeradosServicos(contratosFaturados: Array<IJsonContratoFaturadoDocSel>): TServiceResponse<void> {
    return this._apiService.put({
      url: `${this._path}/servicos/docs/encerrar`,
      body: contratosFaturados
    });
  }

  public enviarEmailDocsGeradosServicos(contratosFaturados: Array<IJsonContratoFaturadoDocSel>): TServiceResponse<void> {
    return this._apiService.put({
      url: `${this._path}/servicos/docs/sendemail`,
      body: contratosFaturados
    });
  }

  public getProcContratoGeradosOperationsResults(): TServiceResponse<Array<IJsonContratoFaturadoDocSel>> {
    return this._apiService.get<Array<IJsonContratoFaturadoDocSel>>({
      url: `${this._path}/servicos/docs/results`
    });
  }

  public cancel(): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/cancel`
    });
  }

  public cancelUrl(): string {
    return `${this._path}/cancel`;
  }
}
