import {DxHtmlEditorComponent} from 'devextreme-angular';
import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {ConfigSiteService} from '../../../../services/configsite.service';
import {ENTITY_NAME_TEMPLATES, ITemplatesEntityService} from '../../templates.entity.interface';
import {IJsonTemplateMail, IJsonTemplateVar} from '../../jsonTemplateConfig.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';

@Component({
  selector: 'templates-detail-component',
  templateUrl: './templates.entity.detail.component.html'
})
export class TemplatesDetailComponent extends ModuloEntityDetailComponent<IJsonTemplateMail> implements OnInit {
  public variaveis: Array<IJsonTemplateVar>;
  public promise: Promise<void>;

  @ViewChild(DxHtmlEditorComponent, {static: false}) private readonly _dxHtmlEditorComponent: DxHtmlEditorComponent;
  private readonly _serviceTemplates: ITemplatesEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _configSiteService: ConfigSiteService
  ) {
    super(_injector);
    this.variaveis = [];
    this._serviceTemplates = this._entityServiceBuilder.build<IJsonTemplateMail, ITemplatesEntityService>(ENTITY_NAME_TEMPLATES);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'btnSendEmailTemplates',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-envelope-o"></i>',
      class: 'btn-primary',
      caption: 'templates.text.sendEmail',
      click: () => this._sendTestEmail()
    });
    if (this.model?.name) {
      this.promise = this._serviceTemplates.getVariaveis(this.model.name).then((response: THttpQueryResponse<IJsonTemplateVar>) => {
        this.variaveis = response.body.list;
      });
    }
  }

  public applyVariable(variable: IJsonTemplateVar): void {
    if (this._dxHtmlEditorComponent?.instance) {
      const selection = this._dxHtmlEditorComponent.instance.getSelection(true);
      this._dxHtmlEditorComponent.instance.insertText(selection.index, variable.name, undefined);
    }
  }

  private async _sendTestEmail(): Promise<void> {
    const response = await this._configSiteService.testeEmail();
    this._plAlertService.success(response.body);
  }
}
