import {HttpResponse} from '@angular/common/http';
import {AvisosCobrancaGridModuleComponent} from './components/avisosCobrancaGrid.module.component';
import {AvisoscobrancaService} from '../avisosCobranca.module.service';
import {IJsonBoolean} from '../../../../../common/interfaces/json';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_AVISOS_COBRANCA_GRID} from '../avisosCobranca.module.interface';
import {RESOLVER_EMPRESA_ANO_EM_CURSO_IRC} from '../../../../services/config/config.service.router';
import {RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL} from '../../../../services/contabilidadedigital/contabilidadedigital.router.interface';
import {ROLE} from '../../../../services/role.const';
import {IJsonConfigAvisosCobranca} from '../jsonAvisosCobranca.module.interface';

export const MODULE_AVISOS_COBRANCA_GRID: IModuleDefinition = {
  name: MODULE_NAME_AVISOS_COBRANCA_GRID,
  state: {
    url: `/${MODULE_NAME_AVISOS_COBRANCA_GRID}`,
    component: AvisosCobrancaGridModuleComponent,
    data: {
      roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.FINANCEIRO],
      icon: 'fa-calendar-check-o'
    },
    resolve: [
      {
        provide: 'proceInterrompido',
        deps: [AvisoscobrancaService],
        useFactory: (avisosCobrancaService: AvisoscobrancaService) => avisosCobrancaService.checkProcessamentoInterrompido().then((response: HttpResponse<IJsonBoolean>) => response.body.value)
      },
      {
        provide: 'config',
        deps: [AvisoscobrancaService],
        useFactory: (avisosCobrancaService: AvisoscobrancaService) => avisosCobrancaService.getConfigAvisosCobranca().then((response: HttpResponse<IJsonConfigAvisosCobranca>) => response.body)
      },
      RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL,
      RESOLVER_EMPRESA_ANO_EM_CURSO_IRC
    ]
  }
};
