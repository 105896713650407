import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {PlAnimationModule} from '../animation/animation.module';
import {PlEditModule} from '../edit/edit.module';
import {PlPromisesModule} from '../promises/promises.module';
import {PlToolbarComponent} from './toolbar.component';
import {PlTooltipModule} from '../tooltip/tooltip.module';
import {PlTranslateModule} from '../translate/translate.module';

export * from './toolbar.interface';
export * from './toolbar.instance';
export * from './toolbar.service';
export * from './toolbar.component';
export * from './toolbar.utilities';

const DECLARATIONS = [PlToolbarComponent];

@NgModule({
  imports: [CommonModule, FormsModule, NgbDropdownModule, PlAnimationModule, PlEditModule, PlPromisesModule, PlTooltipModule, PlTranslateModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlToolbarModule {}
