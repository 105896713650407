export enum ETipoTransmissaoPassiva {
  NA,
  AquisicaoComDeducaoTotalDeIvaOutros,
  AquisicaoComDeducaoParcialDeIvaOutros,
  AquisicaoComDeducaoParcialDeIvaArt21DoCIVAGasoleo,
  AquisicaoComDeducaoParcialOuTotalmenteDedutivelViaturasDeTurismo,
  AquisicaoDeGasolinaComIvaTotalmenteNaoDedutivel,
  AquisicaoDeViatDeTurismoComIvaTotalmenteNaoDedutivelAquisicao,
  AquisicaoDeViatDeTurismoComIvaTotalmenteNaoDedutivelLocacaoEOutrasDespesas,
  AquisicaoDeBensEServicosComIvaTotalmenteNaoDedutivelOutros,
  AquisicaoDeBensEServicosIsentosDeIva,
  AquisicaoDeBensEServicosAbrangidosPeloRegimeDeIsecaoEPeqRetalhistas,
  AquisicaoIsentaDeIvaPorSujeicaoAIMTArt9N30,
  AquisicaoDeEstabelecimentoArt3N4,
  OutrasOperacoesPassivasForaDoCampoDoCIVA,
  AquisicaoDeServicosDeContrucaoCivilIvaDevidoPeloAdquirente,
  AquisicaoDeSucatasIvaDevidoPeloAdquirente,
  AquisicaoIntraComunitariaIsentaNaOrigem,
  AquisicaoNaCEESujeitaNaOrigem,
  AquisicaoImportacao,
  AquisicaoMNPorFornecEstrangeiroComReverseChargeIvaDevidoPeloAdquirente,
  AquisicaoMNPorFornecResidentesUEComReverseChargeIvaDevidoPeloAdquirente,
  AquisicaoOuroDL362de99ComReverseChargeIvaDevidoPeloAdquirente,
  AquisicaoDeImoveisComRenunciaIsencaoComReverseChargeIvaDevidoPeloAdquirente,
  AquisicaoEmissoesGasesEfeitoEstufaIvaDevidoPeloAdquirente,
  AquisicaoDeCorticaEOutrosProdutosDeOrigemSilvicola
}

export const DATA_SOURCE_NAME_TIPO_TRANSMISSAO_PASSIVA = 'tipoTransmissaoPassiva';
