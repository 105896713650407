import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ExtratosContaComponent} from './components/extratosConta.module.component';

export const MODULE_PCA_MYACCOUNT_EXTRATOS_CONTA: IModuleDefinition = {
  name: 'pcaExtratosConta',
  state: {
    url: '/extratosconta',
    component: ExtratosContaComponent,
    data: {
      roles: [ROLE.PCA],
      pageTitle: 'global.menu.pcaExtratosConta'
    }
  }
};
