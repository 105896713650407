import {merge} from 'lodash-es';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {copy, isNumber} from 'pl-comps-angular';
import {CGModalService} from '../../../../../../components/cg/modal/cgmodal.service';
import {IAnexoFQuadro6ListaA, IAnexoFQuadro6ListaB} from '../../../modelo22.module.interface';
import {IJsonModelo22, IJsonModelo22AnexoF} from '../../../jsonModelo22.module.interface';
import {Modelo22AnexoFModalComponent} from './modals/modelo22.anexoF.modal.component';
import {round} from '../../../../../../../common/utils/utils';
import {IDevExpressDataGrid, TDevExpressDataGridToolbar} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {Properties as ButtonOptions} from 'devextreme/ui/button';
import {TranslateService} from '@ngx-translate/core';
import {HttpResponse} from '@angular/common/http';
import {Modelo22Service} from '../../../modelo22.module.service';

const NUMBER_25 = 25;
const NUMBER_50 = 50;

@Component({
  selector: 'modelo22-anexos-anexof',
  templateUrl: './modelo22.anexos.anexoF.component.html'
})
export class Modelo22AnexosAnexoFComponent implements OnInit, OnChanges {
  @Input() public modelo22: IJsonModelo22;
  @Output() public readonly evtChangedModelo22: EventEmitter<IJsonModelo22>;

  public readonly dataGridDefinitionQuadro6A: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro6B: IDevExpressDataGrid;

  constructor(
    private readonly _cgModalService: CGModalService,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _translateService: TranslateService
  ) {
    this.evtChangedModelo22 = new EventEmitter<IJsonModelo22>();
    this.dataGridDefinitionQuadro6A = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {
          caption: 'modelo22.anexoF.quadro06.tableA.fields.identmatimoveis',
          columns: [
            {dataField: 'codFreguesia', dataType: 'string', caption: 'modelo22.anexoF.quadro06.tableA.fields.codfreg'},
            {dataField: 'tipo', dataType: 'string', caption: 'modelo22.anexoF.quadro06.tableA.fields.tipo'},
            {dataField: 'artigo', dataType: 'string', caption: 'modelo22.anexoF.quadro06.tableA.fields.artigo'},
            {dataField: 'fraccao', dataType: 'string', caption: 'modelo22.anexoF.quadro06.tableA.fields.faccaoseccao'}
          ]
        },
        {dataField: 'dataAquisicao', dataType: 'date', caption: 'modelo22.anexoF.quadro06.tableA.fields.dataaqui'},
        {dataField: 'dataAlienacao', dataType: 'date', caption: 'modelo22.anexoF.quadro06.tableA.fields.dataalienacao'},
        {dataField: 'montanteRealizado', dataType: 'double', caption: 'modelo22.anexoF.quadro06.tableA.fields.montantemaismenosvalia'},
        {dataField: 'montanteRegimeTransitorio', dataType: 'double', caption: 'modelo22.anexoF.quadro06.tableA.fields.maismenosvaiaabrangida'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnQuadro6A'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoF.quadro06.descA'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoFQuadro6('A'))
    };
    this.dataGridDefinitionQuadro6B = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'designacao', dataType: 'string', caption: 'modelo22.anexoF.quadro06.tableB.fields.designacao'},
        {dataField: 'dataAquisicao', dataType: 'date', caption: 'modelo22.anexoF.quadro06.tableB.fields.dataaqui'},
        {dataField: 'dataAlienacao', dataType: 'date', caption: 'modelo22.anexoF.quadro06.tableB.fields.dataalienacao'},
        {dataField: 'montante', dataType: 'double', caption: 'modelo22.anexoF.quadro06.tableB.fields.montantemaisvalia'},
        {dataField: 'imposto', dataType: 'double', caption: 'modelo22.anexoF.quadro06.tableB.fields.impcorrespondente'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnQuadro6B'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoF.quadro06.descB'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoFQuadro6('B'))
    };
  }

  public ngOnInit(): void {
    this._setListIndexes();
  }

  public ngOnChanges({modelo22}: SimpleChanges): void {
    if (modelo22 && !modelo22.isFirstChange()) {
      this.modelo22 = modelo22.currentValue;
      this._setListIndexes();
    }
  }

  public addLineAnexoFQuadro6(field: string): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22AnexoFModalComponent);
    const componentInstance: Modelo22AnexoFModalComponent = modalInstance.componentInstance;
    componentInstance.anexoF = copy(this.anexoF);
    componentInstance.newLineFor = field;
    return modalInstance.result.then((result: IJsonModelo22AnexoF) => {
      this._applyAnexoF(result);
      if (field === 'A') {
        this._calcCampo9(result.quadro06.lista6A);
      }
      if (field === 'B') {
        this._calcCampo6(result.quadro06.lista6B);
      }
    });
  }

  public deleteLineAnexoFQuadro6(line: number, field: string): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      if (field === 'A') {
        this.anexoF.quadro06.lista6A.splice(line, 1);
        this._calcCampo9(this.anexoF.quadro06.lista6A);
      }
      if (field === 'B') {
        this.anexoF.quadro06.lista6B.splice(line, 1);
        this._calcCampo6(this.anexoF.quadro06.lista6B);
      }
      this._setListIndexes();
      this.emitModelo22();
    });
  }

  public async emitModelo22(evaluateModelo22: boolean = false): Promise<void> {
    if (evaluateModelo22) {
      await this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
        this.modelo22 = response.body;
      });
    }
    this.evtChangedModelo22.emit(this.modelo22);
  }

  public calcCamp8(): void {
    const f02 = isNumber(this.anexoF.quadro03.campo02) ? this.anexoF.quadro03.campo02 : 0;
    const f03 = isNumber(this.anexoF.quadro03.campo03) ? this.anexoF.quadro03.campo03 : 0;
    const f04 = isNumber(this.anexoF.quadro03.campo04) ? this.anexoF.quadro03.campo04 : 0;
    const f05 = isNumber(this.anexoF.quadro03.campo05) ? this.anexoF.quadro03.campo05 : 0;
    const f06 = isNumber(this.anexoF.quadro03.campo06) ? this.anexoF.quadro03.campo06 : 0;
    const f19 = isNumber(this.anexoF.quadro03.campo19) ? this.anexoF.quadro03.campo19 : 0;
    const f07 = isNumber(this.anexoF.quadro03.campo07) ? this.anexoF.quadro03.campo07 : 0;

    this.anexoF.quadro03.campo08 = f02 + f03 + f04 + f05 + f06 + f19 + f07;
    this.calcCamp17and18();
  }

  public calcCamp16(): void {
    const f09 = isNumber(this.anexoF.quadro03.campo09) ? this.anexoF.quadro03.campo09 : 0;
    const f10 = isNumber(this.anexoF.quadro03.campo10) ? this.anexoF.quadro03.campo10 : 0;
    const f11 = isNumber(this.anexoF.quadro03.campo11) ? this.anexoF.quadro03.campo11 : 0;
    const f12 = isNumber(this.anexoF.quadro03.campo12) ? this.anexoF.quadro03.campo12 : 0;
    const f13 = isNumber(this.anexoF.quadro03.campo13) ? this.anexoF.quadro03.campo13 : 0;
    const f14 = isNumber(this.anexoF.quadro03.campo14) ? this.anexoF.quadro03.campo14 : 0;
    const f15 = isNumber(this.anexoF.quadro03.campo15) ? this.anexoF.quadro03.campo15 : 0;

    this.anexoF.quadro03.campo16 = f09 + f10 + f11 + f12 + f13 + f14 + f15;
    this.calcCamp17and18();
  }

  public calcCamp17and18(): void {
    const f01 = isNumber(this.anexoF.quadro03.campo01) ? this.anexoF.quadro03.campo01 : 0;
    const f08 = isNumber(this.anexoF.quadro03.campo08) ? this.anexoF.quadro03.campo08 : 0;
    const f16 = isNumber(this.anexoF.quadro03.campo16) ? this.anexoF.quadro03.campo16 : 0;

    const calc = f01 + f08 - f16;

    this.anexoF.quadro03.campo17 = round(calc) <= 0 ? calc * -1 : 0;
    this.anexoF.quadro03.campo18 = round(calc) >= 0 ? calc : 0;
    this.emitModelo22();
  }

  public calcQuadro4(): void {
    const f02 = isNumber(this.anexoF.quadro04.campo02) ? this.anexoF.quadro04.campo02 : 0;
    const f04 = isNumber(this.anexoF.quadro04.campo04) ? this.anexoF.quadro04.campo04 : 0;

    this.anexoF.quadro04.campo05 = f02 - f04;
    this.emitModelo22();
  }

  public calcQuadro5(): void {
    const f01 = isNumber(this.anexoF.quadro05.campo01) ? this.anexoF.quadro05.campo01 : 0;
    const f02 = isNumber(this.anexoF.quadro05.campo02) ? this.anexoF.quadro05.campo02 : 0;
    const f03 = isNumber(this.anexoF.quadro05.campo03) ? this.anexoF.quadro05.campo03 : 0;

    this.anexoF.quadro05.campo04 = f01 + f02 + f03;
    this.emitModelo22();
  }

  public get anexoF(): IJsonModelo22AnexoF {
    return this.modelo22.anexoF;
  }

  public set anexoF(value: IJsonModelo22AnexoF) {
    this.modelo22.anexoF = value;
  }

  private _applyAnexoF(anexoF: IJsonModelo22AnexoF): void {
    this.anexoF = merge(this.anexoF, anexoF);
    this._setListIndexes();
    this.emitModelo22();
  }

  private _setListIndexes(): void {
    let indexA = 1;
    for (const listElement of this.anexoF.quadro06.lista6A) {
      listElement._index = indexA;
      indexA++;
    }
    this.dataGridDefinitionQuadro6A.dataSource = this.anexoF.quadro06.lista6A;

    let indexB = 1;
    for (const listElement of this.anexoF.quadro06.lista6B) {
      listElement._index = indexB;
      indexB++;
    }
    this.dataGridDefinitionQuadro6B.dataSource = this.anexoF.quadro06.lista6B;
  }

  private _calcCampo9(list: Array<IAnexoFQuadro6ListaA>): void {
    this.anexoF.quadro06.campoA09 = 0;
    for (const item of list) {
      this.anexoF.quadro06.campoA09 += item.montanteRegimeTransitorio;
    }
    this.anexoF.quadro06.campoA10 = round(this.anexoF.quadro06.campoA09 * (NUMBER_50 / 100) * (NUMBER_25 / 100));
  }

  private _calcCampo6(list: Array<IAnexoFQuadro6ListaB>): void {
    this.anexoF.quadro06.campoB06 = 0;
    for (const item of list) {
      this.anexoF.quadro06.campoB06 += item.imposto;
    }
  }

  private _generateDataGridToolbar(callback: () => void): TDevExpressDataGridToolbar {
    return {
      items: [
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'add',
            hint: this._translateService.instant('modelo22.comum.addline'),
            onClick: () => {
              callback();
            }
          } satisfies ButtonOptions
        },
        'exportButton',
        'columnChooserButton'
      ]
    };
  }
}
