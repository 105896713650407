import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_ENT_SUPORTE_ABDES, ETipoEntSuporteABDES} from './tipoEntSuporteABDES.datasource.interface';

export const DATA_SOURCE_TIPOS_ENT_SUPORTE_ABDES: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_ENT_SUPORTE_ABDES,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoEntSuporteABDES.None, name: 'tipoEntSuporteABDES.data.None'},
    {value: ETipoEntSuporteABDES.Empregado, name: 'tipoEntSuporteABDES.data.Empregado'},
    {value: ETipoEntSuporteABDES.Patronato, name: 'tipoEntSuporteABDES.data.Patronato'},
    {value: ETipoEntSuporteABDES.EmpregadoPatronato, name: 'tipoEntSuporteABDES.data.EmpregadoPatronato'}
  ]
});
