import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_GESTAO_COMUNICACOES_CLIENTE, MODULE_NAME_GESTAO_COMUNICACOES_CONTABILISTA} from './gestaoComunicacoes.module.interface';
import {GestaoComunicacoesModuleComponent} from './components/contabilista/gestaoComunicacoes.module.component';
import {GestaoComunicacoesTopicoService} from './gestaoComunicacoesTopico.module.service';
import {HttpResponse} from '@angular/common/http';
import {IJsonGestaoComunicacoesEmpresa, IJsonGestaoComunicacoesTopico} from './jsonGestaoComunicacoesTopico.module.interface';
import {GestaoComunicacoesClienteModuleComponent} from './components/cliente/gestaoComunicacoesCliente.module.component';

export const MODULE_GESTAO_COMUNICACOES: IModuleDefinition = {
  name: MODULE_NAME_GESTAO_COMUNICACOES_CONTABILISTA,
  state: {
    url: `/${MODULE_NAME_GESTAO_COMUNICACOES_CONTABILISTA}`,
    component: GestaoComunicacoesModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.gestaoComunicacoes'
    },
    resolve: [
      {
        provide: 'topicoEmpresas',
        deps: [GestaoComunicacoesTopicoService],
        useFactory: (gestaoComunicacoesTopicoService: GestaoComunicacoesTopicoService): Promise<Array<IJsonGestaoComunicacoesEmpresa>> => {
          return gestaoComunicacoesTopicoService.getEmpresas().then((response: HttpResponse<Array<IJsonGestaoComunicacoesEmpresa>>) => response.body);
        }
      }
    ]
  }
};

export const MODULE_GESTAO_COMUNICACOES_CLIENTE: IModuleDefinition = {
  name: MODULE_NAME_GESTAO_COMUNICACOES_CLIENTE,
  state: {
    url: `/${MODULE_NAME_GESTAO_COMUNICACOES_CLIENTE}`,
    component: GestaoComunicacoesClienteModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.CLIENTCONNECT],
      pageTitle: 'global.menu.gestaoComunicacoesCliente'
    },
    resolve: [
      {
        provide: 'clienteTopicos',
        deps: [GestaoComunicacoesTopicoService],
        useFactory: (gestaoComunicacoesTopicoService: GestaoComunicacoesTopicoService): Promise<Array<IJsonGestaoComunicacoesTopico>> => {
          return gestaoComunicacoesTopicoService.getClienteTopicos().then((response: HttpResponse<Array<IJsonGestaoComunicacoesTopico>>) => response.body);
        }
      }
    ]
  }
};
