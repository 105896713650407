import {Component, Injector, OnInit} from '@angular/core';
import {IPlFilterPanelEvtFiltered} from 'pl-comps-angular';
import {EGenericImporterType} from '../../../../../components/genericimporter/generic.importer.component.interface';
import {GenericImporterService} from '../../../../../components/genericimporter/generic.importer.service';
import {IJsonDGEMP} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {ModuloEntityListComponent} from '../../../../../components/module/entitylist/module.entitylist.component';
import {HttpResponse} from '@angular/common/http';
import {IJsonDGEMPInconsistenciasTabelaIRS} from '../../jsonDGEMPFull.interface';
import {ENTITY_NAME_DGEMPS_FULL, IDGEMPSFullEntityService} from '../../dgempsFull.interface';
import {EEntityMaintenanceEditMode, IEntityMaintenanceInstance} from '../../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';

const FILTER = 'ativo=true';

@Component({
  selector: 'dgemps-full-list',
  templateUrl: './dgempsFull.list.component.html'
})
export class DgempsFullListComponent extends ModuloEntityListComponent<IJsonDGEMP> implements OnInit {
  public inconsistenciasTabelaIRS: IJsonDGEMPInconsistenciasTabelaIRS;
  public temInconsistenciasTabelaIRS: boolean;

  private readonly _serviceDGEMPSFull: IDGEMPSFullEntityService;
  private readonly _maintenanceDGEMPFull: IEntityMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _genericImporterService: GenericImporterService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this._serviceDGEMPSFull = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS_FULL);
    this._maintenanceDGEMPFull = this._entityMaintenanceService.build(ENTITY_NAME_DGEMPS_FULL);
    this.inconsistenciasTabelaIRS = undefined;
    this.temInconsistenciasTabelaIRS = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.filterValue = FILTER;
    this.toolbar.addButton(this._genericImporterService.moduleToolbarButton(EGenericImporterType.Dgemps, this));
    this._serviceDGEMPSFull.getInconsistenciasTabelaIRS().then((response: HttpResponse<IJsonDGEMPInconsistenciasTabelaIRS>) => {
      this.inconsistenciasTabelaIRS = response.body;
      this.temInconsistenciasTabelaIRS = this.inconsistenciasTabelaIRS && this.inconsistenciasTabelaIRS.empregadosinconsistentes.length > 0;
    });
  }

  public openDGEMP(codEmp: number): void {
    this._maintenanceDGEMPFull.maintenanceEdit(codEmp, {mode: EEntityMaintenanceEditMode.EditOnly});
  }

  public inconsistenciasTabelaIRSNotifClose(): void {
    const title = 'global.text.confirmation';
    const message = 'dgempsfull.prompt.inconsistenciasTabelaIRSNotifCloseMessage';
    const modalInstance = this._cgModalService.showOkCancelVanilla(title, message);
    modalInstance.result.then(() => this._serviceDGEMPSFull.setMostrarNotificacaoInconsistenciasTabelaIRS(false));
  }

  public readonly fnOnFiltered: (event: IPlFilterPanelEvtFiltered<string>) => IPlFilterPanelEvtFiltered<string> = (event: IPlFilterPanelEvtFiltered<string>) => this._onFiltered(event);

  private _onFiltered(event: IPlFilterPanelEvtFiltered<string>): IPlFilterPanelEvtFiltered<string> {
    if (!event.serializedFilters.includes(FILTER)) {
      this.filterValue = '';
    }
    return {...event};
  }
}
