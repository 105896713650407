import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EATAutoFaturacaoEnquadramento, IJsonDocfaNum} from '../../../docfasnums/jsonDocfaNum.entity.interface';
import {ETipoAutoFaturacao, IJsonDocfa} from '../../jsonDocFa.entity.interface';
import moment from 'moment';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ENTITY_NAME_DOCFAS_NUMS, IDocfaNumEntityService} from '../../../docfasnums/docfasNums.entity.interface';
import {GrupoDocService} from '../../../../services/grupodoc/grupo.doc.service';
import {IPlValidator, IPlValidatorValidateParams, isDefinedNotNull, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {TranslateService} from '@ngx-translate/core';
import {ISelectKeyPair} from '../../docFas.entity.interface';

@Component({
  selector: 'docfas-novaserie-modal',
  templateUrl: './docFas.novaSerie.modal.component.html'
})
export class DocfasNovaSerieModalComponent extends CGModalComponent<IJsonDocfaNum> implements OnInit {
  @Input() public docfa: IJsonDocfa;
  @Input() public editAction: boolean;

  public readonly fornecedoresOutput: string = '{{nConta}}';
  public readonly fornecedoresFilter: string = 'autoFaturacao=true';
  public readonly validatorAtAFEnquadramento: IPlValidator<string, number>;

  public model: IJsonDocfaNum;
  public toggleParaTodosDoGrupo: boolean;
  public nomeGrupoDoc: string;
  public docfaStr: string;
  public isAutoFaturacao: boolean;
  public isAutoFaturacaoComAcordo: boolean;
  public isAutoFaturacaoSemAcordo: boolean;
  public enquadramentoList: Array<ISelectKeyPair>;
  public promise: Promise<void>;

  private readonly _serviceDocFaNum: IDocfaNumEntityService;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _grupoDoc: GrupoDocService,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._serviceDocFaNum = this._entityServiceBuilder.build<IJsonDocfaNum, IDocfaNumEntityService>(ENTITY_NAME_DOCFAS_NUMS);
    this.toggleParaTodosDoGrupo = false;
    this.nomeGrupoDoc = '';
    this.isAutoFaturacaoComAcordo = false;
    this.isAutoFaturacaoSemAcordo = false;
    this.enquadramentoList = [
      {value: EATAutoFaturacaoEnquadramento.None, name: 'docfasNums.modal.comunicacao.selecionar'},
      {value: EATAutoFaturacaoEnquadramento.Cortica, name: 'docfasNums.modal.comunicacao.enquadramentoDesperdicios'},
      {value: EATAutoFaturacaoEnquadramento.Desperdicios, name: 'docfasNums.modal.comunicacao.enquadramentoCortica'}
    ];
    this.validatorAtAFEnquadramento = {
      message: 'docfasNums.errors.atAFEnquadramentoRequired',
      validate: this._validateAtAFEnquadramento.bind(this)
    };
  }

  public ngOnInit(): void {
    this.docfaStr = `${this.docfa.nDocFa.toString()} - ${this.docfa.nome} (${this.docfa.descricao})`;
    const grupoItem = this._grupoDoc.getGrupoDoc(this.docfa.grupoDocfa);
    if (isDefinedNotNull(grupoItem)) {
      this.nomeGrupoDoc = this._translateService.instant(grupoItem.nome);
    }
    this.model = {
      idDocfaNum: 0,
      nDocfa: this.docfa ? this.docfa.nDocFa : 1,
      nNumer: null,
      num: 0,
      descNumer: '',
      data: moment(),
      numDraft: 0,
      nConta: '',
      encerrado: false,
      atcud: '',
      visivelERPCloud: true,
      atAFEnquadramento: EATAutoFaturacaoEnquadramento.None
    };
    this.isAutoFaturacaoComAcordo = this.docfa.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresComAcordo;
    this.isAutoFaturacaoSemAcordo = this.docfa.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresSemAcordo;
    this.isAutoFaturacao = this.isAutoFaturacaoComAcordo || this.isAutoFaturacaoSemAcordo;
  }

  public close(): Promise<void> {
    if (this.isAutoFaturacaoSemAcordo && this.model.atAFEnquadramento === EATAutoFaturacaoEnquadramento.None) {
      this._plAlertService.error('docfasNums.errors.atAFEnquadramentoRequired');
      return Promise.resolve();
    }
    if (this.isAutoFaturacaoComAcordo && (isUndefinedOrNull(this.model.nConta) || !this.model.nConta.length)) {
      this._plAlertService.error('docfasNums.errors.nContaRequired');
      return Promise.resolve();
    }
    this.disableClose();
    this.promise = this._serviceDocFaNum
      .post({body: this.model}, this.toggleParaTodosDoGrupo)
      .then((response: HttpResponse<IJsonDocfaNum>) => {
        if (response.body) {
          this.model = response.body;
        }
        this.enableClose();
        super.close(this.model);
      })
      .finally(() => {
        this.enableClose();
      });
    return this.promise;
  }

  private _validateAtAFEnquadramento({modelValue}: IPlValidatorValidateParams<string, number>): boolean {
    return !this.isAutoFaturacaoSemAcordo || (this.isAutoFaturacaoSemAcordo && modelValue !== EATAutoFaturacaoEnquadramento.None);
  }
}
