import {IJsonDGEMP} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {TDate} from '../../../../../common/dates';

export interface IRecibosVencimento {
  dgemp: IJsonDGEMP;
  todosColaboradores: boolean;
}

export interface IRecibosVencimentoParams {
  codEmp: number;
  nProcessamento: number;
}

export interface IReportsComSuporteGraficoStorage {
  date: TDate;
  list: Array<IJsonReport>;
}

export const MODULE_NAME_PCA_RH_RECIBOS_VENCIMENTOS = 'pcaRecibosVencimentos';
