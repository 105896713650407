<div class="mapa-pagamentos-config-modal">
  <div class="modal-header">
    <button *ngIf="allowCancel" type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'mapapagam.texts.configuracao'"></h5>
  </div>

  <div class="modal-body">
    <ng-container *ngIf="currentStep === modalSteps.EMPRESAS">
      <h4 class="mapa-pagamento-config-modal-header" [translate]="'mapapagam.texts.empresas'"></h4>
      <pl-multiselect
        [model]="empresasSelecionadas"
        [source]="empresasDisponiveis"
        key="nEmpresa"
        [template]="templateMultiSelectEmpresas"
        [properties]="{leftCaption: 'erputilizadores.companies.available', rightCaption: 'erputilizadores.companies.selected'}">
      </pl-multiselect>
    </ng-container>

    <ng-container *ngIf="currentStep === modalSteps.PAGAMENTOS">
      <h4 class="mapa-pagamento-config-modal-header" [translate]="'mapapagam.texts.pagamentos'"></h4>
      <pl-multiselect
        [model]="pagamentosSelecionadas"
        [source]="pagamentosDisponiveis"
        key="value"
        [template]="templateMultiSelectPagamentos"
        [properties]="{leftCaption: 'mapapagam.texts.pagamentosDisponiveis', rightCaption: 'mapapagam.texts.pagamentosSelecionados'}">
      </pl-multiselect>
    </ng-container>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <button type="button" *ngIf="allowCancel" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></button>
    <button type="button" *ngIf="currentStep === modalSteps.PAGAMENTOS" class="btn btn-sm btn-light" (click)="stepPrevClick()" plPromise>
      <i class="fa fa-fw fa-angle-left"></i>&nbsp;<span [translate]="'global.btn.previous'"></span>
    </button>
    <button type="button" *ngIf="currentStep === modalSteps.EMPRESAS" class="btn btn-sm btn-primary" (click)="stepNextClick()" plPromise>
      <span [translate]="'global.btn.next'"></span>&nbsp;<i class="fa fa-fw fa-angle-right"></i>
    </button>
    <button type="button" *ngIf="currentStep === modalSteps.PAGAMENTOS" class="btn btn-sm btn-success" [click]="fnSaveConfig" plPromise>
      <i class="fa fa-fw fa-check"></i><span [translate]="'mapapagam.texts.confirmar'"></span>
    </button>
  </div>
</div>
