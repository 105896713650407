<div class="modal-header">
  <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'recibos.saveModal.paymentMeans'"></h4>
</div>

<div class="modal-body">
  <docscomerciais-meios-pagamento
    [meiosPagamento]="doc.meiosPagamento"
    [grupoDocfa]="doc.cab.grupoDocfa"
    [totalDocumento]="doc.cab.totalDocumento"
    [codMoeda]="codMoeda"
    [abreviaturaMoeda]="abreviaturaMoeda"
    [emEdicao]="emEdicao"
    (evtChangedStatus)="changedStatus($event)"
    (evtMeioValorKeydown)="meioValorKeydown($event)">
  </docscomerciais-meios-pagamento>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-primary action-submit" [disabled]="closeDisabled || !emEdicao || !canSave" [click]="close" plPromise>
    <i class="fa fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
  </button>

  <button type="button" class="btn btn-sm btn-light action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
    <i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
  </button>
</div>
