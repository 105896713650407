import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {AtFaturasRecibosVerdesService} from '../../atFaturasRecibosVerdes.service';
import {IJsonFRVNIFConfig} from '../../jsonATFaturasRecibosVerdes.interface';
import {IFRVImportDoc} from '../../atFaturasRecibosVerdes.module.interface';

@Component({
  selector: 'at-frv-nif-config-modal',
  templateUrl: './atFaturasRecibosVerdes.nifConfig.modal.html'
})
export class AtFaturasRecibosVerdesNifConfigModalComponent extends CGModalComponent<IJsonFRVNIFConfig> implements OnInit {
  @Input() public nifAdquirente: string;
  @Input() public nomeAdquirente: string;
  @Input() public docsList: Array<IFRVImportDoc>;
  @Input() public model: IJsonFRVNIFConfig;

  public pocFilter: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _atFaturasRecibosVerdesService: AtFaturasRecibosVerdesService
  ) {
    super(_injector);
    this.model = {nif: '', preDefinido: null, usaSempreRecolha: false, contaCorrente: null, nome: '', preDefinidoStr: '', contaCorrenteStr: ''};
  }

  public ngOnInit(): void {
    const filterRadicalClientes = `nConta=%'${this._configService.configurations.clientesFornecedores.radicalClientes}%'`;
    const filterRadicalOutrosDevedoresCredores = `nConta=%'${this._configService.configurations.clientesFornecedores.radicalOutrosDevedoresCredores}%'`;
    const filterRadical = `(${filterRadicalClientes}|${filterRadicalOutrosDevedoresCredores})`;
    this.pocFilter = `nif='${this.nifAdquirente}'&cc=true&${filterRadical}`;
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._atFaturasRecibosVerdesService
      .saveNifConfig({nifConfig: this.model, docsList: this.docsList})
      .then(() => {
        this.enableClose();
        super.close(this.model);
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }
}
