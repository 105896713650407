import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlDynamicVisualsSecondaryClickAction, IPlNavWizardDefinition, IPlNavWizardEventBeforeChange, IPlNavWizardStep, IPlToolbarItem, isObject, PlAlertService} from 'pl-comps-angular';
import {ModuloComponent} from '../../../../components/module/module.component';
import {EApuraInventPermaSteps} from '../contabilidade.apurainventperma.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonDocContabilidadeCab} from '../../docscontabilidade/jsonDocContabilidade.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ContabilidadeApuramentoResultadosService} from '../../manutencao/apuramentoresultados/contabilidade.apuramentoResultados.module.service';
import {IJsonDocsContasInventario, IJsonSaldosContasInventario} from '../../manutencao/apuramentoresultados/jsonApuramentoResultados.module.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContextMenuPreparing
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../docscontabilidade/docsContabilidade.interface';
import {DocsContasInventarioModalComponent} from '../../manutencao/apuramentoresultados/modals/docscontasinventario/docsContasInventario.modal.component';
import {SaldosContasInventarioModalComponent} from '../../manutencao/apuramentoresultados/modals/saldoscontasinventario/saldosContasInventario.modal.component';
import {ContabilidadeApuraInventPermaService} from '../contabilidade.apurainventperma.module.service';
import {IJsonApuraStockInfoApuramento, IJsonApuraStockParamsDoc, IJsonConfigContas, IJsonTiposContasPoc, IJsonValoresInventario} from '../jsonapurainventperma.module';
import {DevExpressDataGridUIService} from '../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {ContabilidadeApuraInventPermaConfigContasModalComponent} from '../modal/configcontas/configContas.modal.component';

@Component({
  selector: 'contabilidade-apura-stock',
  templateUrl: './contabilidade.apurainventperma.module.component.html'
})
export class ContabilidadeApuraInventPermaComponent extends ModuloComponent implements OnInit {
  @Input() public infoApuramento: Array<IJsonApuraStockInfoApuramento>;
  @Input() public contasApuraStock: Array<IJsonTiposContasPoc>;
  @Input() public paramApuraStockDados: IJsonApuraStockParamsDoc;

  public readonly steps: typeof EApuraInventPermaSteps;
  public readonly definitionInfoApuramento: IDevExpressDataGrid<IJsonApuraStockInfoApuramento, string>;
  public readonly definitionContas: IDevExpressDataGrid<IJsonTiposContasPoc, string>;
  public readonly definitionContasDetail: IDevExpressDataGrid<IJsonValoresInventario, string>;
  public readonly definitionPreVisualDocumentos: IDevExpressDataGrid<IJsonDocContabilidadeCab, string>;
  public readonly definitionNavWizard: IPlNavWizardDefinition;

  public valores: Array<IJsonDocContabilidadeCab>;
  public selectedStep: IPlNavWizardStep;

  private readonly _maintenanceDocsContabilidade: IEntityMaintenanceInstance;
  private readonly _btnAnalise: IPlToolbarItem;
  private readonly _btnConfig: IPlToolbarItem;

  private _shouldSimular: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _apuramentoResultadosService: ContabilidadeApuramentoResultadosService,
    private readonly _apuraInventPermaService: ContabilidadeApuraInventPermaService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this._maintenanceDocsContabilidade = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.valores = [];
    this.steps = EApuraInventPermaSteps;
    this.definitionNavWizard = {
      items: []
    };
    this.definitionInfoApuramento = {
      keyExpr: 'periodo',
      columns: [
        {dataField: 'periodo', dataType: 'string', caption: 'apurainventperma.module.datagrids.infoApuramento.periodo'},
        {dataField: 'apuramentoEfetuado', dataType: 'string', caption: 'apurainventperma.module.datagrids.infoApuramento.apuramentoEfetuado', alignment: 'center'},
        {dataField: 'existeDocDaNaturezaNoPeriodo', dataType: 'boolean', caption: 'apurainventperma.module.datagrids.infoApuramento.existeDocumento', visible: false},
        {dataField: 'temErros', dataType: 'boolean', caption: 'apurainventperma.module.datagrids.infoApuramento.temErros', visible: false},
        {
          name: 'actions',
          cellTemplate: 'dataGridTemplateActions',
          alignment: 'center',
          width: 50,
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSorting: false,
          showInColumnChooser: false
        }
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      export: {enabled: false},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      pager: {visible: false},
      paging: {enabled: false},
      sorting: {mode: 'none'}
    };
    this.definitionContas = {
      keyExpr: 'nConta',
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'apurainventperma.module.datagrids.contas.codConta', sortOrder: 'asc'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'apurainventperma.module.datagrids.contas.designacao'},
        {dataField: 'total', dataType: 'double', caption: 'global.text.total'}
      ],
      masterDetail: {
        enabled: true,
        template: 'detail',
        autoExpandAll: false
      },
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      export: {enabled: false},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      pager: {visible: false},
      paging: {enabled: false},
      sorting: {mode: 'none'}
    };
    this.definitionContasDetail = {
      keyExpr: 'nConta',
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'apurainventperma.module.datagrids.contas.masterDetail.nConta', allowEditing: false},
        {dataField: 'nomeConta', dataType: 'string', caption: 'apurainventperma.module.datagrids.contas.masterDetail.nome', allowEditing: false},
        {dataField: 'valor', dataType: 'double', caption: 'apurainventperma.module.datagrids.contas.masterDetail.valor'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      editing: {
        allowUpdating: true,
        mode: 'cell',
        selectTextOnEditStart: true
      },
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false},
      headerFilter: {visible: false},
      paging: {enabled: false},
      pager: {visible: false},
      repaintChangesOnly: true,
      sorting: {mode: 'none'}
    };
    this.definitionPreVisualDocumentos = {
      keyExpr: 'extPocCabID',
      columns: [
        {dataField: 'extPocCabID', dataType: 'string', caption: 'apurainventperma.module.datagrids.previsualDocumentos.extPocCabID', visible: false},
        {dataField: 'periodo', dataType: 'string', caption: 'apurainventperma.module.datagrids.previsualDocumentos.periodo', groupIndex: 0},
        {dataField: 'nContaDebito', dataType: 'string', caption: 'apurainventperma.module.datagrids.previsualDocumentos.nContaDebito'},
        {dataField: 'nContaCredito', dataType: 'string', caption: 'apurainventperma.module.datagrids.previsualDocumentos.nContaCredito'},
        {dataField: 'valor', dataType: 'double', caption: 'apurainventperma.module.datagrids.previsualDocumentos.valor'},
        {dataField: 'descricao', dataType: 'string', caption: 'apurainventperma.module.datagrids.previsualDocumentos.descricao'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      pager: {visible: false},
      paging: {enabled: false},
      sorting: {mode: 'none'}
    };
    this._btnAnalise = {
      id: 'dropdownAnalise',
      order: 1,
      type: 'dropdown',
      class: 'btn-primary',
      caption: 'apuramentoresultados.toolbar.analise',
      menu: [
        {
          id: 'infoSaldosInv',
          caption: 'apuramentoresultados.toolbar.infoSaldosInv',
          click: () => this._openModalInfoSaldosInv()
        },
        {
          id: 'docsContasInventario',
          caption: 'apuramentoresultados.toolbar.docsContasInventario',
          click: () => this._openDocsContasInventario()
        },
        {
          id: 'docsSaldosErrados',
          caption: 'apuramentoresultados.toolbar.docsSaldosErrados',
          click: () => this._openDocsSaldosErrados()
        }
      ]
    };
    this._btnConfig = {
      id: 'config',
      order: this._btnAnalise.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      class: 'btn-light',
      caption: 'apurainventperma.btns.configContas',
      click: () => this._openConfigModal()
    };
    this._shouldSimular = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnAnalise).addButton(this._btnConfig);
  }

  public onCellPreparedInfoApuramento(event: IDevExpressDataGridEventOnCellPrepared<IJsonApuraStockInfoApuramento, string>): void {
    if (event.rowType === 'data' && event.column.dataField === 'apuramentoEfetuado') {
      if (event.data.existeDocDaNaturezaNoPeriodo) {
        if (event.data.temErros) {
          event.cellElement.innerHTML = '<i class="fa fa-fw fa-exclamation-triangle text-warning"></i>';
        } else {
          event.cellElement.innerHTML = '<i class="fa fa-fw fa-check text-success"></i>';
        }
      }
    }
  }

  public onContextMenuPreparingInfoApuramento(event: IDevExpressDataGridEventOnContextMenuPreparing<IJsonApuraStockInfoApuramento, string>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data) && event.row.data.existeDocDaNaturezaNoPeriodo) {
      event.event.preventDefault();
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(event.row.data);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public onCellClickApuraStockContas(event: IDevExpressDataGridEventOnCellClick<IJsonTiposContasPoc, string>): void {
    devExpressDataGridExpandDetailHandler(event).then(() => undefined);
  }

  public onRowUpdatedChangeValorParentRow(item: IJsonTiposContasPoc): void {
    item.total = 0;
    if (item.valoresInventario) {
      for (const itemValor of item.valoresInventario) {
        item.total += itemValor.valor;
      }
    }
  }

  public async changedPeriodoValidate(periodo: string): Promise<void> {
    await this._validatePeriodo(true, periodo);
  }

  public openRowActions(transaction: IJsonApuraStockInfoApuramento, event: Event): Promise<void> {
    event.stopPropagation();
    const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(transaction);
    return this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.target, actions).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public readonly fnBeforeChangedStep = (event: IPlNavWizardEventBeforeChange): boolean | Promise<void> => this._beforeChangedStep(event);

  public readonly fnFinalize = (): Promise<void> => this._finalize();

  private _beforeChangedStep({nextStep, currentStep, previousStep, type}: IPlNavWizardEventBeforeChange): boolean | Promise<void> {
    let promise = null;
    if (currentStep.stepId === EApuraInventPermaSteps.Variables && previousStep?.stepId !== EApuraInventPermaSteps.Variables && !nextStep && type !== 'previous') {
      this._validatePeriodo(false, this.paramApuraStockDados.nPeriodo).then(() => undefined);
    }
    if (currentStep.stepId === EApuraInventPermaSteps.Documents && previousStep?.stepId !== EApuraInventPermaSteps.Documents && !nextStep && type !== 'previous') {
      if (this._checkPeriodoAlreadyHasApuramento()) {
        promise = this._promptReplace();
      }
      return Promise.resolve(promise).then(async () => {
        this._shouldSimular = true;
        await this._apagaDocumentoDeApuramento(this.paramApuraStockDados.nPeriodo);
        return this._simularApuraStock().then((valores: Array<IJsonDocContabilidadeCab>) => {
          this.valores = valores;
        });
      });
    }
    if (nextStep?.stepId === EApuraInventPermaSteps.Preview && !this.definitionNavWizard.items[1].complete) {
      nextStep.complete = false;
      return false;
    }
    return true;
  }

  private _simularApuraStock(): Promise<Array<IJsonDocContabilidadeCab>> {
    if (!this._shouldSimular) {
      return Promise.resolve([]);
    }
    return this._apuraInventPermaService.simularApuraStock(this.paramApuraStockDados, this.contasApuraStock).then((response: HttpResponse<Array<IJsonDocContabilidadeCab>>) => response.body);
  }

  private _promptReplace(): Promise<void> {
    const message = this._translateService.instant('apurainventperma.menagens.periodoAlreadyHasApuramento', {periodo: this.paramApuraStockDados.nPeriodo});
    return this._cgModalService.showOkCancel('apurainventperma.menagens.apuramentoJaExiste', message);
  }

  private _executarSave(): Promise<void> {
    return this._apuraInventPermaService.executarApuraStock(this.paramApuraStockDados.nPeriodo, this.valores).then(() => {
      this._plAlertService.success('apurainventperma.menagens.documentosGravadosSucesso');
    });
  }

  private _finalize(): Promise<void> {
    return this._executarSave().then(() => {
      return this._reloadPageApuraStock();
    });
  }

  private _openModalInfoSaldosInv(periodo?: string): Promise<void> {
    return this._apuramentoResultadosService.getLoadInformacaoSaldosApuramento(periodo).then((response: HttpResponse<IJsonSaldosContasInventario>) => {
      const modalInstance = this._cgModalService.showVanilla(SaldosContasInventarioModalComponent, {size: 'xxl'});
      const componentInstance: SaldosContasInventarioModalComponent = modalInstance.componentInstance;
      componentInstance.saldosContasInventario = response.body;
    });
  }

  private _openModalDocsContasInventario(onlyContaInventario: boolean, docsContasInventario: Array<IJsonDocsContasInventario>): void {
    const modalInstance = this._cgModalService.showVanilla(DocsContasInventarioModalComponent);
    const componentInstance: DocsContasInventarioModalComponent = modalInstance.componentInstance;
    componentInstance.docsContasInventario = docsContasInventario;
    componentInstance.onlyContaInventario = onlyContaInventario;
  }

  private _openDocsContasInventario(): Promise<void> {
    return this._apuramentoResultadosService.getLoadDocsContasInventario().then((response: HttpResponse<Array<IJsonDocsContasInventario>>) => {
      if (response.body.length < 1) {
        this._plAlertService.info('apuramentoresultados.modal.docscontasinventario.naoExistemDocumentosComMovimenta');
        return;
      }
      this._openModalDocsContasInventario(true, response.body);
    });
  }

  private _openDocsSaldosErrados(): Promise<void> {
    return this._apuramentoResultadosService.getLoadDocsSaldosErrados().then((response: HttpResponse<Array<IJsonDocsContasInventario>>) => {
      if (response.body.length < 1) {
        this._plAlertService.info('apuramentoresultados.modal.docscontasinventario.naoExistemDocumentosComSaldosDeC');
        return;
      }
      this._openModalDocsContasInventario(false, response.body);
    });
  }

  private async _openConfigModal(): Promise<void> {
    const configContaDados: IJsonConfigContas = await this._apuraInventPermaService.getConfigContas().then((response: HttpResponse<IJsonConfigContas>) => response.body);
    const modalInstance = this._cgModalService.showVanilla(ContabilidadeApuraInventPermaConfigContasModalComponent);
    const componentInstance: ContabilidadeApuraInventPermaConfigContasModalComponent = modalInstance.componentInstance;
    componentInstance.configModal = configContaDados;
    await modalInstance.result;
    return Promise.resolve();
  }

  private _checkPeriodoAlreadyHasApuramento(): boolean {
    const apuramentoItem: IJsonApuraStockInfoApuramento = this.infoApuramento.find((item: IJsonApuraStockInfoApuramento) => item.periodo === this.paramApuraStockDados.nPeriodo);
    return apuramentoItem?.existeDocDaNaturezaNoPeriodo;
  }

  private _apagaDocumentoDeApuramento(periodo: string): Promise<void> {
    return this._apuraInventPermaService.apagaDocumentosDeApuramentoApuraStock(periodo).then((response: HttpResponse<void>) => response.body);
  }

  private _confirmarApagarDocumentoApuramento(periodo: string): Promise<void> {
    const message = this._translateService.instant('apurainventperma.menagens.apagarDocumentoApuramentoMessage', {periodo: periodo});
    return this._cgModalService.showOkCancel('apurainventperma.menagens.apagarDocumentoApuramentoTitle', message).then(() => {
      return this._apagaDocumentoDeApuramento(periodo).then(() => {
        return this._reloadPageApuraStock();
      });
    });
  }

  private _generateSecondaryClickActions(infoApuraStockData: IJsonApuraStockInfoApuramento): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'apuramentoresultados.toolbar.infoSaldosInv',
        icon: 'fa-file-o',
        click: () => this._openModalInfoSaldosInv(infoApuraStockData.periodo)
      },
      {
        caption: 'apurainventperma.actions.verDocumentoApuramento',
        icon: 'fa-file-o',
        click: () => {
          this._maintenanceDocsContabilidade.maintenanceEdit(infoApuraStockData.extPocCabID).then(() => undefined);
        }
      },
      {
        caption: 'apurainventperma.actions.apagarDocumentoDeApuramento',
        icon: 'fa-trash-o',
        click: () => this._confirmarApagarDocumentoApuramento(infoApuraStockData.periodo)
      }
    ];
  }

  private _reloadPageApuraStock(): Promise<void> {
    return this._stateService.go(this._uiRouterGlobals.current, null, {reload: true, inherit: false}).then(() => undefined);
  }

  private async _validatePeriodo(force: boolean = false, periodo?: string): Promise<void> {
    if (force) {
      this.paramApuraStockDados = await this._apuraInventPermaService.getInfoDadosParamsApuraStock(periodo).then((response: HttpResponse<IJsonApuraStockParamsDoc>) => response.body);
    }
    if (this.paramApuraStockDados.naoDeveFazerOApuramentoDeInventario) {
      return this._cgModalService.showOkCancel('global.text.warning', 'apurainventperma.menagens.naoDeveFazerOApuramentoDeInventario', {showCancelBtn: false, backdrop: 'static'});
    }
    return Promise.resolve();
  }
}
