import type {Subscription} from 'rxjs';
import {Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {calculateResizeInfo} from '../../../../common/imageresizer/image.resizer';
import {CGCModalComponent} from '../../../../modal/components/modal.component';
import {EPlMediaDevicesCameraCaptureImageModalTab, IPlMediaDevicesCameraCaptureProperties} from '../mediadevices.camera.captureimage.interface';
import {EPlMediaDevicesGetMediaStreamErrorKind, IPlMediaDevicesSupports} from '../../../service/mediadevices.service.interface';
import {EPlUploadStatus, IPlUploadCallback, IPlUploadFile} from '../../../../upload/upload.component.interface';
import {generateUniqueID, isArray, isBoolean, isEmpty, isNumber, isObject} from '../../../../common/utilities/utilities';
import type {IPlLocale, IPlLocaleBtn, IPlLocaleMediaDevicesCameraCaptureImage} from '../../../../common/locale/locales.interface';
import type {IPlResizedImageInfo} from '../../../../common/imageresizer/image.resizer.interface';
import type {IPlTabsEventSelected} from '../../../../tabs/tab.interface';
import type {IPlTooltipConfig} from '../../../../tooltip/tooltip.interface';
import {Logger} from '../../../../logger/logger';
import {newFile} from '../../../../common/files/files';
import {PlLocaleService} from '../../../../common/locale/locale.service';
import {PlMediaDevicesAdapter} from '../../../service/mediadevices.service';

const BORDER_MIN_WIDTH = 4;

@Component({
  selector: 'pl-media-devices-camera-capture-image-change-modal',
  templateUrl: './mediadevices.camera.captureimage.change.modal.component.html',
  standalone: false
})
export class PlMediaDevicesCameraCaptureImageChangeModalComponent extends CGCModalComponent<File> implements OnInit, OnDestroy {
  @Input() public properties: IPlMediaDevicesCameraCaptureProperties;

  public readonly tabs: typeof EPlMediaDevicesCameraCaptureImageModalTab;
  public readonly tooltipTabCapture: IPlTooltipConfig;
  public readonly callbackUpload: IPlUploadCallback;
  public locale: IPlLocaleMediaDevicesCameraCaptureImage;
  public localeBtn: IPlLocaleBtn;
  public activeTab: EPlMediaDevicesCameraCaptureImageModalTab;
  public title: string;
  public titleTabUpload: string;
  public titleTabCapture: string;
  public disableTabCapture: boolean;
  public errorTabCapture: string;
  public promiseTabCapture: Promise<any>;
  public paused: boolean;
  public showOverlay: boolean;

  private readonly _subscriptionLocale: Subscription;
  private readonly _subscriptionSupports: Subscription;
  private _mediaStream: MediaStream;
  private _elementVideo: HTMLVideoElement;
  private _elementVideoOverlay: HTMLDivElement;
  private _promisePlay: Promise<void>;
  private _videoOverlayStyle: Partial<CSSStyleDeclaration>;
  private _lastError: unknown;

  constructor(
    protected readonly _injector: Injector,
    private readonly _logger: Logger,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plMediaDevicesAdapter: PlMediaDevicesAdapter
  ) {
    super(_injector);
    this.tabs = EPlMediaDevicesCameraCaptureImageModalTab;
    this.tooltipTabCapture = {text: '', disabled: true};
    this.callbackUpload = {};
    this.disableTabCapture = false;
    this.paused = false;
    this.showOverlay = false;
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      const changedLocale = Boolean(this.locale);
      this.locale = locale.plMediaDevicesCameraCaptureImage;
      this.localeBtn = locale.btn;
      this.tooltipTabCapture.text = this.locale.textDisabledTabCapture;
      if (changedLocale) {
        this._evaluateTitles();
        if (this._lastError) {
          this._handleError(this._lastError);
        }
      }
    });
    this._subscriptionSupports = this._plMediaDevicesAdapter.supports().subscribe((devicesSupports: IPlMediaDevicesSupports) => {
      this.disableTabCapture = !devicesSupports.camera;
      this.tooltipTabCapture.disabled = !this.disableTabCapture;
      if (this.disableTabCapture && this.activeTab === EPlMediaDevicesCameraCaptureImageModalTab.Capture) {
        this.activeTab = EPlMediaDevicesCameraCaptureImageModalTab.Upload;
      }
    });
  }

  public ngOnInit(): void {
    if (!isObject(this.properties)) {
      this.properties = {};
    }
    this._evaluateTitles();
    if (!isObject(this.properties.uploadProperties)) {
      this.properties.uploadProperties = {};
    }
    if (isEmpty(this.properties.uploadProperties.acceptedFiles)) {
      this.properties.uploadProperties.acceptedFiles = 'image/*';
    }
    if (isEmpty(this.properties.defaultFileName)) {
      this.properties.defaultFileName = 'image';
    }
    if (!isNumber(this.properties.maxVideoWidth)) {
      this.properties.maxVideoWidth = this.properties.videoWidth;
    }
    if (!isNumber(this.properties.maxVideoHeight)) {
      this.properties.maxVideoHeight = this.properties.videoHeight;
    }
    if (!isBoolean(this.properties.resizeOverlay)) {
      this.properties.resizeOverlay = true;
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
    this._subscriptionSupports.unsubscribe();
    this._cleanupMediaStream();
  }

  public changedTab({nextId}: IPlTabsEventSelected): void {
    this.activeTab = <EPlMediaDevicesCameraCaptureImageModalTab>nextId;
    switch (this.activeTab) {
      case EPlMediaDevicesCameraCaptureImageModalTab.Upload:
        this._cleanupMediaStream();
        break;
      case EPlMediaDevicesCameraCaptureImageModalTab.Capture:
        this.initMediaStream();
        break;
    }
  }

  public onAcceptedFile(uploadFile: IPlUploadFile): void {
    if (isEmpty(this.properties.uploadProperties.url)) {
      this.close(uploadFile.file);
    } else {
      this.callbackUpload.processQueue().catch((reason: unknown) => {
        this._logger.error(reason);
      });
    }
  }

  public onFileUpload(uploadFile: IPlUploadFile): void {
    if (uploadFile.status === EPlUploadStatus.Success) {
      this.close(uploadFile.file);
    }
  }

  public initMediaStream(): void {
    this.errorTabCapture = undefined;
    if (!this.promiseTabCapture) {
      this._cleanupMediaStream();
      this.promiseTabCapture = new Promise<void>((resolve, reject) => {
        const constraints: MediaStreamConstraints = {
          video: {
            width: {ideal: this.properties.videoWidth, max: this.properties.maxVideoWidth},
            height: {ideal: this.properties.videoHeight, max: this.properties.maxVideoHeight}
          }
        };
        this._plMediaDevicesAdapter.mediaStream(constraints).subscribe({
          next: (mediaStream: MediaStream) => {
            this._mediaStream = mediaStream;
            this._initVideoElement(this._mediaStream);
            resolve();
          },
          error: (error: DOMException) => {
            const errorKind: EPlMediaDevicesGetMediaStreamErrorKind = <EPlMediaDevicesGetMediaStreamErrorKind>error.name;
            this._handleError(errorKind);
            reject(error);
          }
        });
      });
      this.promiseTabCapture.finally(() => {
        this.promiseTabCapture = undefined;
      });
    }
  }

  public capture(): Promise<void> {
    return this.pause().then(() => {
      if (this._elementVideo) {
        const mediaStream: MediaStream = <MediaStream>this._elementVideo.srcObject;
        const videoTrack: MediaStreamTrack = mediaStream.getVideoTracks().find((track: MediaStreamTrack) => track.enabled && track.readyState === 'live');
        if (!videoTrack) {
          return;
        }
        const canvasElement = window.document.createElement<'canvas'>('canvas');
        canvasElement.width = this._elementVideo.videoWidth;
        canvasElement.height = this._elementVideo.videoHeight;
        const context: CanvasRenderingContext2D = canvasElement.getContext('2d');
        context.drawImage(this._elementVideo, 0, 0);
        const fileName: string = generateUniqueID(this.properties.defaultFileName);
        const fileType = this.properties.resizeMimeType || 'image/png';
        canvasElement.toBlob(
          (blob: Blob) => {
            const file: File = newFile(blob, fileName, {type: fileType, lastModified: Date.now()});
            this.close(file);
          },
          fileType,
          this.properties.resizeQuality
        );
      }
    });
  }

  public pause(): Promise<void> {
    if (this._elementVideo && !this._elementVideo.paused) {
      return Promise.resolve(this._promisePlay).then(() => {
        this._elementVideo.pause();
      });
    }
    return Promise.resolve();
  }

  public resume(): Promise<void> {
    if (this._elementVideo?.paused) {
      return Promise.resolve(this._promisePlay).then(() => {
        return this._play();
      });
    }
    return Promise.resolve();
  }

  @ViewChild('elementVideo')
  public set elementVideo(value: ElementRef<HTMLVideoElement>) {
    // Clean old events
    if (this._elementVideo) {
      this._detachVideoElementListeners();
    }
    this._elementVideo = value?.nativeElement;
    if (this._elementVideo) {
      this._elementVideo.controls = false;
      this._attachVideoElementListeners();
    }
  }

  @ViewChild('elementVideoOverlay')
  public set elementVideoOverlay(value: ElementRef<HTMLDivElement>) {
    this._elementVideoOverlay = value?.nativeElement;
    if (this._elementVideoOverlay && this._videoOverlayStyle) {
      const {borderTopWidth, borderLeftWidth, borderRightWidth, borderBottomWidth} = this._videoOverlayStyle;
      this._elementVideoOverlay.style.borderTopWidth = borderTopWidth;
      this._elementVideoOverlay.style.borderLeftWidth = borderLeftWidth;
      this._elementVideoOverlay.style.borderRightWidth = borderRightWidth;
      this._elementVideoOverlay.style.borderBottomWidth = borderBottomWidth;
    }
  }

  private _evaluateTitles(): void {
    this.title = !isEmpty(this.properties.title) ? this.properties.title : this.locale?.titleChange;

    this.titleTabUpload = !isEmpty(this.properties.titleTabUpload) ? this.properties.titleTabUpload : this.locale?.titleTabUpload;

    this.titleTabCapture = !isEmpty(this.properties.titleTabCapture) ? this.properties.titleTabCapture : this.locale?.titleTabCapture;
  }

  private _cleanupMediaStream(): void {
    if (this._mediaStream) {
      for (const track of this._mediaStream.getTracks()) {
        track.stop();
      }
      this._mediaStream = undefined;
    }
    if (this._elementVideo) {
      this._detachVideoElementListeners();
      this._elementVideo.pause();
      this._elementVideo.srcObject = undefined;
    }
  }

  private _initVideoElement(mediaStream: MediaStream): void {
    if (mediaStream && this._elementVideo) {
      const videoTracks: Array<MediaStreamTrack> = mediaStream.getVideoTracks();
      if (isArray(videoTracks) && videoTracks.length) {
        const videoTrack: MediaStreamTrack = videoTracks.find((track: MediaStreamTrack) => track.enabled && track.readyState === 'live');
        if (videoTrack) {
          this._evaluateOverlay(videoTrack);
        }
      }
      this._elementVideo.srcObject = mediaStream;
      this.errorTabCapture = undefined;
      this._play();
    }
  }

  private _evaluateOverlay(videoTrack: MediaStreamTrack): void {
    this.showOverlay = false;
    this._videoOverlayStyle = undefined;
    if (this.properties.resizeMethod === 'crop' && isObject(videoTrack)) {
      const settings = videoTrack.getSettings();
      if (isObject(settings) && isNumber(settings.width) && settings.width > 0 && isNumber(settings.height) && settings.height > 0) {
        const hasResizeWidth: boolean = isNumber(this.properties.resizeWidth);
        if (!hasResizeWidth) {
          return;
        }
        let hasResizeHeight: boolean = isNumber(this.properties.resizeHeight);
        if (hasResizeWidth && !hasResizeHeight) {
          // Formula: (original height / original width) x new width = new height
          this.properties.resizeHeight = (settings.height / settings.width) * this.properties.resizeWidth;
          hasResizeHeight = this.properties.resizeHeight > 0;
        }

        const resizingWidth: boolean = hasResizeWidth && settings.width > this.properties.resizeWidth;
        const resizingHeight: boolean = hasResizeHeight && settings.height > this.properties.resizeHeight;
        this.showOverlay = this.properties.resizeOverlay && (resizingWidth || resizingHeight);

        if (this.showOverlay) {
          const resizeInfo: IPlResizedImageInfo = calculateResizeInfo(settings.width, settings.height, this.properties.resizeWidth, this.properties.resizeHeight, this.properties.resizeMethod);
          const suffix = 'px';
          /* eslint-disable @typescript-eslint/restrict-plus-operands */
          const widthTopBottom = resizeInfo.srcY + BORDER_MIN_WIDTH + suffix;
          const widthLeftRight = resizeInfo.srcX + BORDER_MIN_WIDTH + suffix;
          /* eslint-enable @typescript-eslint/restrict-plus-operands */
          this._videoOverlayStyle = {
            borderTopWidth: widthTopBottom,
            borderLeftWidth: widthLeftRight,
            borderRightWidth: widthLeftRight,
            borderBottomWidth: widthTopBottom
          };
        }
      }
    }
  }

  private _handleError(error: unknown): void {
    this._lastError = error;
    switch (error) {
      case EPlMediaDevicesGetMediaStreamErrorKind.AbortError:
        this.errorTabCapture = this.locale.errorAbortError;
        break;
      case EPlMediaDevicesGetMediaStreamErrorKind.NotAllowedError:
        this.errorTabCapture = this.locale.errorNotAllowedError;
        break;
      case EPlMediaDevicesGetMediaStreamErrorKind.NotFoundError:
        this.errorTabCapture = this.locale.errorNotFoundError;
        break;
      case EPlMediaDevicesGetMediaStreamErrorKind.NotReadableError:
        this.errorTabCapture = this.locale.errorNotReadableError;
        break;
      case EPlMediaDevicesGetMediaStreamErrorKind.OverconstrainedError:
        this.errorTabCapture = this.locale.errorOverconstrainedError;
        break;
      case EPlMediaDevicesGetMediaStreamErrorKind.SecurityError:
        this.errorTabCapture = this.locale.errorSecurityError;
        break;
      case EPlMediaDevicesGetMediaStreamErrorKind.TypeError:
        this.errorTabCapture = this.locale.errorTypeError;
        break;
      case EPlMediaDevicesGetMediaStreamErrorKind.TimedOutError:
        this.errorTabCapture = this.locale.errorTimedOutError;
        break;
      case EPlMediaDevicesGetMediaStreamErrorKind.UnavailableMediaDevices:
        this.errorTabCapture = this.locale.errorUnavailableMediaDevices;
        break;
      default:
        this.errorTabCapture = this.locale.errorTypeError;
        break;
    }
  }

  private _play(): Promise<void> {
    if (this._elementVideo) {
      if (!this._promisePlay) {
        this._promisePlay = Promise.resolve(this._elementVideo.play()).catch((reason: unknown) => {
          this._handleError(reason);
        });
        this._promisePlay.finally(() => {
          this._promisePlay = undefined;
        });
      }
      return this._promisePlay;
    }
    return Promise.reject(new Error('Video element not available.'));
  }

  private _attachVideoElementListeners(): void {
    if (this._elementVideo) {
      this._detachVideoElementListeners();
      this._elementVideo.addEventListener<'play'>('play', this._fnOnPlay, {passive: true});
      this._elementVideo.addEventListener<'pause'>('pause', this._fnOnPause, {passive: true});
    }
  }

  private _detachVideoElementListeners(): void {
    if (this._elementVideo) {
      this._elementVideo.removeEventListener<'play'>('play', this._fnOnPlay);
      this._elementVideo.removeEventListener<'pause'>('pause', this._fnOnPause);
    }
  }

  private _onPlay(): void {
    this.paused = false;
  }

  private _onPause(): void {
    this.paused = true;
  }

  private readonly _fnOnPlay = (): void => {
    this._onPlay();
  };

  private readonly _fnOnPause = (): void => {
    this._onPause();
  };
}
