import {deepFreeze} from 'pl-comps-angular';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';

export enum ETipoCidadao {
  NACIONAL,
  ESTRANGEIRO
}

export const RADIO_GROUP_TIPO_CIDADAO: IRadioGroup<ETipoCidadao> = deepFreeze<IRadioGroup<ETipoCidadao>>({
  groupItems: [
    {value: ETipoCidadao.NACIONAL, label: 'faturacaoeletronicaconfiguracoes.addsafecertificadomodal.tipoCidadao.nacional'},
    {value: ETipoCidadao.ESTRANGEIRO, label: 'faturacaoeletronicaconfiguracoes.addsafecertificadomodal.tipoCidadao.estrangeiro'}
  ]
});
