<div class="gestao-dgemps-events-details">
  <div class="gestao-dgemps-events-details-header">
    <div class="gestao-dgemps-events-details-title" *ngIf="manager" ngbDropdown>
      <div ngbDropdownToggle>{{ title }}</div>
      <div ngbDropdownMenu>
        <button type="button" ngbDropdownItem *ngIf="allowIntegraAll" [disabled]="executing" [click]="fnIntegraAll" plPromise>
          <i class="fa fa-fw fa-calendar-check-o" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.integraAll'"></span>
        </button>

        <button type="button" ngbDropdownItem [disabled]="!allowApproveAll || executing" [click]="fnApproveAll" plPromise>
          <i class="fa fa-fw fa-check" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.approveAll'"></span>
        </button>

        <button type="button" ngbDropdownItem [disabled]="!allowRejectAll || executing" [click]="fnRejectAll" plPromise>
          <i class="fa fa-fw fa-ban" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.rejectAll'"></span>
        </button>

        <button type="button" ngbDropdownItem [disabled]="!allowRewindAll || executing" [click]="fnRewindAll" plPromise>
          <i class="fa fa-fw fa-angle-double-left" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.rewindAll'"></span>
        </button>

        <button type="button" ngbDropdownItem [disabled]="!allowDeleteAll || executing" [click]="fnDeleteAll" plPromise>
          <i class="fa fa-fw fa-minus-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.markOffAll'"></span>
        </button>

        <button type="button" ngbDropdownItem [disabled]="!allowSetMotivoAll || executing" [click]="fnSetMotivoAll" plPromise>
          <i class="fa fa-fw fa-pencil-square-o" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.setMotivoAll'"></span>
        </button>
      </div>
    </div>

    <div class="gestao-dgemps-events-details-title" *ngIf="!manager">{{ title }}</div>

    <button type="button" class="btn-close" [disabled]="executing" (click)="closeDetail()"></button>
  </div>

  <hr />

  <pl-alert type="info" [closeable]="false" *ngIf="!groups.length">
    <span [translate]="'gestaodgemps.text.noEvents'"></span>
  </pl-alert>

  <div class="gestao-dgemps-events-details-groups" *ngIf="groups.length">
    <div class="gestao-dgemps-events-details-group" *ngFor="let group of groups">
      <div class="card">
        <div class="card-body">
          <div class="gestao-dgemps-events-details-group-header">
            <h6 class="gestao-dgemps-events-details-group-title" title="{{ group.codEmp }} - {{ group.nomeEmp }}">
              <span [translate]="'gestaodgemps.fields.nomeEmpregado'"></span>: {{ group.nomeEmp }}
            </h6>

            <div class="gestao-dgemps-events-details-group-actions" *ngIf="manager" ngbDropdown container="body">
              <u class="btn btn-sm btn-outline-secondary" ngbDropdownToggle [translate]="'entity.action.actions'"></u>
              <div class="gestao-dgemps-events-details-group-actions" ngbDropdownMenu>
                <button type="button" ngbDropdownItem *ngIf="group.allowAdd" [disabled]="executing" (click)="addEvent(group)">
                  <i class="fa fa-fw fa-plus" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.addNew'"></span>
                </button>

                <button type="button" ngbDropdownItem *ngIf="group.allowIntegraAll" [disabled]="executing" [click]="fnIntegraAllGroup(group)" plPromise>
                  <i class="fa fa-fw fa-calendar-check-o" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.integraAllGroup'"></span>
                </button>

                <button type="button" ngbDropdownItem [disabled]="!group.allowApproveAll || executing" [click]="fnApproveAllGroup(group)" plPromise>
                  <i class="fa fa-fw fa-check" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.approveAllGroup'"></span>
                </button>

                <button type="button" ngbDropdownItem [disabled]="!group.allowRejectAll || executing" [click]="fnRejectAllGroup(group)" plPromise>
                  <i class="fa fa-fw fa-ban" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.rejectAllGroup'"></span>
                </button>

                <button type="button" ngbDropdownItem [disabled]="!group.allowRewindAll || executing" [click]="fnRewindAllGroup(group)" plPromise>
                  <i class="fa fa-fw fa-angle-double-left" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.rewindAllGroup'"></span>
                </button>

                <button type="button" ngbDropdownItem [disabled]="!group.allowDeleteAll || executing" [click]="fnDeleteAllGroup(group)" plPromise>
                  <i class="fa fa-fw fa-minus-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.markOffAllGroup'"></span>
                </button>

                <button type="button" ngbDropdownItem [disabled]="!group.allowSetMotivoAll || executing" [click]="fnSetMotivoGroup(group)" plPromise>
                  <i class="fa fa-fw fa-pencil-square-o" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.setMotivoAllGroup'"></span>
                </button>
              </div>
            </div>

            <button
              type="button"
              class="btn btn-sm btn-outline-secondary gestao-dgemps-events-details-group-action-add-new"
              *ngIf="!manager"
              [disabled]="!group.allowAdd || executing"
              (click)="addEvent(group)">
              <i class="fa fa-fw fa-plus" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.addNew'"></span>
            </button>
          </div>

          <ul class="gestao-dgemps-events-details-events list-group list-group-flush">
            <li class="gestao-dgemps-event-details list-group-item" *ngFor="let event of group.events">
              <div class="gestao-dgemps-event-details-heading">
                <div class="gestao-dgemps-event-details-heading-title" [ngSwitch]="event.allDay">
                  <span *ngSwitchCase="true" [translate]="'gestaodgemps.text.allDay'" [translateParams]="{date: event.date | cgcDate}"></span>
                  <span
                    *ngSwitchCase="false"
                    [translate]="'gestaodgemps.text.fromToHours'"
                    [translateParams]="{
                      date: event.date | cgcDate,
                      start: event.horaInicio | cgcTime,
                      end: event.horaFim | cgcTime
                    }"></span>
                </div>

                <div class="gestao-dgemps-event-details-attachment-group btn-group btn-group-sm" role="group" *ngIf="event.downloadUrl || !event.status.integrado" ngbDropdown>
                  <a
                    class="btn btn-primary gestao-dgemps-event-details-attachment"
                    *ngIf="event.downloadUrl"
                    [attr.title]="'gestaodgemps.text.downloadAttachment' | translate"
                    [href]="event.downloadUrl"
                    download
                    target="_blank"
                    rel="noopener noreferrer">
                    <i class="fa fa-fw fa-file-o" aria-hidden="true"></i>
                  </a>

                  <ng-container *ngIf="type === types.Faltas && !event.status.integrado">
                    <input
                      type="file"
                      *ngIf="!event.status.integrado"
                      [id]="'gestao-dgemps-event-details-add-attachment-' + event.idTarefaCab"
                      class="visually-hidden"
                      accept=".bmp,.png,.jpeg,.jpg,.pdf,.docx,.doc,.csv,.xlsx,.xls"
                      (change)="changedEventAttachment(event, $event)" />

                    <label
                      *ngIf="!event.downloadUrl"
                      class="btn btn-sm btn-primary gestao-dgemps-event-details-add-attachment"
                      [for]="'gestao-dgemps-event-details-add-attachment-' + event.idTarefaCab"
                      [attr.title]="'gestaodgemps.text.addAttachment' | translate"
                      [plPromise]="promiseAttachment">
                      <i class="fa fa-fw pl-icon-adicionar-docs" aria-hidden="true"></i>
                    </label>

                    <div class="gestao-dgemps-event-details-attachment-sub-group btn-group btn-group-sm" *ngIf="event.downloadUrl" role="group" ngbDropdown>
                      <button type="button" class="btn btn-light dropdown-toggle-split" ngbDropdownToggle></button>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <label ngbDropdownItem class="btn btn-light gestao-dgemps-event-details-replace-attachment" [for]="'gestao-dgemps-event-details-add-attachment-' + event.idTarefaCab">
                          <i class="fa fa-fw pl-icon-adicionar-docs" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.replaceAttachment'"></span>
                        </label>
                        <button type="button" ngbDropdownItem [disabled]="executing" [click]="fnDeleteEventAttachment(event)" plPromise>
                          <i class="fa fa-fw fa-trash" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaodgemps.text.deleteAttachment'"></span>
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </div>

                <button
                  type="button"
                  class="btn btn-sm btn-light"
                  *ngIf="manager"
                  [attr.title]="'gestaodgemps.text.history' | translate"
                  [disabled]="executing"
                  [click]="fnEventHistory(event)"
                  plPromise>
                  <i class="fa fa-fw fa-list-alt" aria-hidden="true"></i>
                </button>

                <button
                  type="button"
                  class="btn btn-sm btn-light"
                  *ngIf="event.status.desmarcavel"
                  [attr.title]="'gestaodgemps.text.markOff' | translate"
                  [disabled]="executing"
                  [click]="fnDeleteEvent(event)"
                  [plPromise]="event.promiseDelete">
                  <i class="fa fa-fw fa-minus-circle" aria-hidden="true"></i>
                </button>
              </div>

              <p *ngIf="event.tipo">{{ event.tipo }}</p>
              <p *ngIf="event.processamento">{{ event.processamento }}</p>
              <p *ngIf="event.abdes">{{ event.abdes }}</p>
              <p *ngIf="event.qtd"><span [translate]="'gestaodgemps.fields.quantidade'"></span>: {{ event.qtd }}</p>
              <p *ngIf="event.motivo && !event.defaultMotivo"><span [translate]="'gestaodgemps.fields.motivo'"></span>: {{ event.motivo }}</p>

              <div class="gestao-dgemps-event-details-actions">
                <div class="gestao-dgemps-event-details-status" *ngIf="!event.status.allowApproveReject || event.status.aprovadoParcialmente" [ngClass]="event.statusCSSClass">
                  {{ event.statusMessage }}
                </div>

                <ng-container *ngIf="manager && event.status.rewindable">
                  <button type="button" class="btn btn-sm btn-link action-rewind" [disabled]="executing" [click]="fnRewindEvent(event)" [plPromise]="event.promiseRewind">
                    <i class="fa fa-fw fa-angle-left" aria-hidden="true"></i>&nbsp;<span [translate]="'global.btn.revert'"></span>
                  </button>

                  <div class="flex-break" *ngIf="event.status.allowApproveReject"></div>
                </ng-container>

                <ng-container *ngIf="manager && event.status.allowApproveReject">
                  <div class="btn-group btn-group-sm">
                    <button
                      type="button"
                      class="btn"
                      [ngClass]="{'btn-info action-approve': !event.status.aprovado, 'btn-success action-integrar': event.status.aprovado}"
                      [disabled]="executing"
                      [click]="fnApproveEvent(event)"
                      [plPromise]="event.promiseApprove">
                      <i class="fa fa-fw" aria-hidden="true" [class.fa-check]="!event.status.aprovado" [class.fa-calendar-check-o]="event.status.aprovado"></i>&nbsp;
                      <span [translate]="!event.status.aprovado ? 'global.btn.approve' : 'gestaodgemps.text.integrar'"></span>
                    </button>

                    <button type="button" class="btn btn-danger action-reject" *ngIf="!event.status.aprovado" [disabled]="executing" [click]="fnRejectEvent(event)" [plPromise]="event.promiseReject">
                      <i class="fa fa-fw fa-ban" aria-hidden="true"></i>&nbsp;<span [translate]="'global.btn.reject'"></span>
                    </button>
                  </div>

                  <div class="gestao-dgemps-events-details-motivo">
                    <ng-container *ngIf="event.editableMotivo && !event.editingMotivo"><span [translate]="'gestaodgemps.fields.motivo'"></span>:&nbsp;</ng-container>
                    <pl-inline-edit
                      type="string"
                      [(model)]="event.editableMotivo"
                      [(editMode)]="event.editingMotivo"
                      [properties]="{
                        placeholder: 'gestaodgemps.text.motivos.default',
                        validators: {maxlength: {value: 250}}
                      }">
                    </pl-inline-edit>
                  </div>
                </ng-container>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
