import {ENTITY_NAME_ARMAZENS} from './armazens.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonArmazem} from './jsonArmazem.entity.interface';
import {IJsonCodPostal} from '../codpostais/jsonCodPostal.entity.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_COD_POSTAIS} from '../codpostais/codPostais.entity.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {HttpResponse} from '@angular/common/http';

export const ENTITY_ARMAZENS: IEntityDefinition<IJsonArmazem> = {
  name: ENTITY_NAME_ARMAZENS,
  roles: [ROLE.ERP],
  pageTitle: 'global.menu.armazens',
  searchPlaceholder: 'armazens.pesquisa',
  metadata: {
    keyName: 'nArmazem',
    fields: [
      {
        name: 'nArmazem',
        type: 'cgsmallint',
        width: '120px',
        caption: 'armazens.fields.nArmazem',
        placeholder: 'armazens.fields.nArmazemPlaceholder',
        validators: {required: true, min: 1}
      },
      {name: 'nome', caption: 'armazens.fields.nome', placeholder: 'armazens.fields.nomePlaceholder', validators: {required: true, maxlength: 50}},
      {name: 'morada1', caption: 'armazens.fields.morada1', placeholder: 'armazens.fields.morada1Placeholder', validators: {maxlength: 50}},
      {name: 'morada2', caption: 'armazens.fields.morada2', visible: false},
      {
        name: 'codPostal',
        caption: 'armazens.fields.codPostal',
        placeholder: 'armazens.fields.codPostalPlaceholder',
        validators: {required: true, maxlength: 20},
        entity: {name: 'codpostais', keyTarget: 'codPostal', outputTarget: 'codPostal'}
      },
      {name: 'nomeCPostal', caption: 'armazens.fields.nomeCPostal', validators: {required: true, maxlength: 20}},
      {name: 'codPais', type: 'integer', caption: 'armazens.fields.codPais', visible: false},
      {name: 'nomePais', caption: 'armazens.fields.nomePais', visible: false},
      {name: 'nTelefone', caption: 'armazens.fields.nTelefone', placeholder: 'armazens.fields.nTelefonePlaceholder', validators: {maxlength: 20}},
      {name: 'nFax', caption: 'armazens.fields.nFax', placeholder: 'armazens.fields.nFaxPlaceholder', validators: {maxlength: 19}}
    ],
    order: 'nome',
    searchFields: 'nArmazem,nome,morada1,codPostal,nomeCPostal,nTelefone,nFax'
  },
  autocomplete: {
    rowTemplate: '{{nArmazem}} - {{nome}}',
    output: 'nome',
    searchFields: 'nArmazem,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  serviceInjectable: [
    EntityServiceBuilder,
    function (entityServiceBuilder: EntityServiceBuilder): void {
      const serviceCodPostais: IEntityService<IJsonCodPostal> = entityServiceBuilder.build<IJsonCodPostal>(ENTITY_NAME_COD_POSTAIS);

      this.observe('codPostal', (newValue: string, model: IJsonArmazem): Promise<void> => {
        if (newValue) {
          return serviceCodPostais.get({id: newValue}).then((response: HttpResponse<IJsonCodPostal>) => {
            if (response.body.localidade) {
              model.nomeCPostal = response.body.localidade;
            }
          });
        }
        return Promise.resolve();
      });
    }
  ]
};
