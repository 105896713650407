import {Component, Injector} from '@angular/core';
import {IPlNavWizardDefinition, PlAlertService} from 'pl-comps-angular';
import {ContabilidadeToolsService} from '../../../../../services/contabilidade.tools.service';
import {EReconstrucaoAcumTipoConta, IReconstrucaoAcum} from '../reconstrucaoAcumulados.module.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';

@Component({
  selector: 'module-reconstrucao-acumulados',
  templateUrl: './reconstrucaoAcumulados.module.component.html'
})
export class ReconstrucaoAcumuladosComponent extends ModuloComponent {
  public readonly reconstrucaoAcumTipoConta: typeof EReconstrucaoAcumTipoConta;
  public readonly definition: IPlNavWizardDefinition;
  public readonly model: IReconstrucaoAcum;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _contabilidadeToolsService: ContabilidadeToolsService
  ) {
    super(_injector);
    this.reconstrucaoAcumTipoConta = EReconstrucaoAcumTipoConta;
    this.definition = {
      items: []
    };
    this.model = {
      nConta: undefined,
      tipoPoc: 1
    };
  }

  public changedTipoPoc(value: number): void {
    this.model.tipoPoc = value;
    if (this.model.tipoPoc === EReconstrucaoAcumTipoConta.Todas) {
      this.model.nConta = undefined;
    }
  }

  public readonly fnFinalize: () => Promise<void> = () => this._finalize();

  private _finalize(): Promise<void> {
    return this._contabilidadeToolsService.reconstruirAcumulados({nConta: this.model.nConta}).then(() => {
      this._plAlertService.success('reconstrucaoacumulados.alerts.reconstrucaoSucesso');
      return this._stateService.reload().then(() => undefined);
    });
  }
}
