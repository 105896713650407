import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {
  EDateMonth,
  IPlNavWizardCallback,
  IPlNavWizardDefinition,
  IPlNavWizardEventValidator,
  IPlNavWizardOptions,
  IPlNavWizardStep,
  isDefinedNotNull,
  isUndefinedOrNull,
  PlAlertService
} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {
  EIntegracaoSalariosMultiStatus,
  EIntegracaoSalariosMultiSteps,
  IIntegracaoSalariosMultiEmpresa,
  IIntegracaoSalariosMultiProcResult,
  IIntegracaoSalariosMultiProcResultTableItem,
  IIntegracaoSalariosMultiStatus
} from '../integracaoSalariosMulti.module.interface';
import {IntegracaoSalariosConfigModalComponent} from '../../integracaosalarios/modals/config/integracaoSalarios.config.modal.component';
import {IntegracaoSalariosMultiService} from '../integracaoSalariosMulti.module.service';
import {IntegracaoSalariosService} from '../../integracaosalarios/integracaoSalarios.module.service';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment from 'moment';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';

const INTERVAL_TIMEOUT = 2000;
const ERROR_TYPE_SUCCESS_CODE = 1;
const STEP_INTRO_INDEX = 0;
const STEP_EMPRESAS_SEL_INDEX = 1;
const STEP_PROC_INDEX = 2;
const STEP_PROC_RESULTS_INDEX = 3;
const SUBSTRACT_YEARS = 2;

@Component({
  selector: 'integracaoSalariosMulti',
  templateUrl: './integracaoSalariosMulti.module.component.html'
})
export class IntegracaoSalariosMultiComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly integracaoSalariosMultiSteps: typeof EIntegracaoSalariosMultiSteps;
  public readonly definitionNavWizard: IPlNavWizardDefinition;
  public readonly yearsSource: Array<number>;

  public selectedRowKeysEmpresas: Array<string>;
  public dataGridDefinitionEmpresas: IDevExpressDataGrid<IIntegracaoSalariosMultiEmpresa, string>;
  public dataGridDefinitionProcResults: IDevExpressDataGrid<IIntegracaoSalariosMultiProcResultTableItem>;
  public dataGridDefinitionProcResultsMessages: IDevExpressDataGrid<IIntegracaoSalariosMultiProcResult>;
  public isBlocked: boolean;
  public propertiesNavWizard: IPlNavWizardOptions;
  public plWizardCallback: IPlNavWizardCallback;
  public pbProcLabel: string;
  public pbProcPos: number;
  public currentStatus: IIntegracaoSalariosMultiStatus;
  public selYear: number;
  public selMonth: EDateMonth;

  private readonly _currentMonth: number;
  private _dataGridInstanceEmpresas: dxDataGrid<IIntegracaoSalariosMultiEmpresa, string>;
  private _dataGridInstanceProcResults: dxDataGrid<IIntegracaoSalariosMultiProcResultTableItem>;
  private _empresasTableSource: Array<IIntegracaoSalariosMultiEmpresa>;
  private _procResultsTableSource: Array<IIntegracaoSalariosMultiProcResultTableItem>;
  private _timeoutModalRef: NgbModalRef;
  private _errorModalRef: NgbModalRef;
  private _intervalId: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _integracaoSalariosMultiService: IntegracaoSalariosMultiService,
    private readonly _plAlertService: PlAlertService,
    private readonly _integracaoSalariosService: IntegracaoSalariosService
  ) {
    super(_injector);
    this._currentMonth = moment().month() + 1;
    this.selYear = moment().year();
    this.selMonth = this._currentMonth;
    this.yearsSource = [];
    this.selectedRowKeysEmpresas = [];

    const minYear = moment().subtract(SUBSTRACT_YEARS, 'years').year();
    const maxYear = moment().add(1, 'years').year();

    for (let i = minYear; i <= maxYear; i++) {
      this.yearsSource.push(i);
    }

    this.definitionNavWizard = {
      items: []
    };
    this.integracaoSalariosMultiSteps = EIntegracaoSalariosMultiSteps;
    this.propertiesNavWizard = {
      disableNavigation: false,
      disablePreviousStep: false,
      disableNextStep: false
    };
    this.plWizardCallback = {};

    this._init();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._integracaoSalariosMultiService.getJobStatus().then((response) => {
      this.currentStatus = response;
      this._init();
      if (response.state === EIntegracaoSalariosMultiStatus.Timeout) {
        this._showTimeoutModal();
        this._resetWizard();
      } else if (response.state === EIntegracaoSalariosMultiStatus.Error) {
        this._handleStateError(response);
        this._resetWizard();
      } else if (response.state === EIntegracaoSalariosMultiStatus.Ended) {
        this._cgModalService
          .showOkCancel('integracaoSalariosMulti.promptViewResultsTitle', 'integracaoSalariosMulti.promptViewResultsMessage', {
            size: 'md',
            btnOkText: 'integracaoSalariosMulti.viewResultBtn',
            btnCancelText: 'integracaoSalariosMulti.initNewProc',
            btnOkIcon: 'fa-eye',
            btnCancelIcon: 'fa-home',
            backdrop: 'static',
            showCloseBtn: false,
            keyboard: false
          })
          .then(() => {
            this._loadResults();
          })
          .catch(() => {
            this._resetWizard();
            this._init();
          });
      } else if (response.state === EIntegracaoSalariosMultiStatus.Started) {
        this.isBlocked = response.userStartedId !== this.session.userId;
        if (!this.isBlocked) {
          this._setWizActiveStep(EIntegracaoSalariosMultiSteps.PROC, true);
          this._startProcessChecker(true);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    clearInterval(this._intervalId);
    if (isDefinedNotNull(this.currentStatus) && this.currentStatus.state !== EIntegracaoSalariosMultiStatus.Started) {
      this._integracaoSalariosMultiService.resetJob(true);
    }
  }

  public showConfigModal(item: IIntegracaoSalariosMultiEmpresa): void {
    this._integracaoSalariosService.getConfig(item.nEmpresa).then((config) => {
      config.errorList.forEach((error) => {
        this._plAlertService.error(error);
      });
      const modalRef = this._cgModalService.showVanilla(IntegracaoSalariosConfigModalComponent, {size: 'md'});
      const componentInstance: IntegracaoSalariosConfigModalComponent = modalRef.componentInstance;
      componentInstance.nEmpresa = item.nEmpresa;
      componentInstance.configInput = config;
      return modalRef.result.then(() => {
        // refresh item
      });
    });
  }

  public onInitializedEmpresas({component}: IDevExpressDataGridEventOnInitialized<IIntegracaoSalariosMultiEmpresa, string>): void {
    this._dataGridInstanceEmpresas = component;
  }

  public onInitializedProcResults({component}: IDevExpressDataGridEventOnInitialized<IIntegracaoSalariosMultiProcResultTableItem>): void {
    this._dataGridInstanceProcResults = component;
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IIntegracaoSalariosMultiProcResultTableItem>): void {
    devExpressDataGridExpandDetailHandler(event);
  }

  public readonly fnValidateStepEmpresasSel: (event: IPlNavWizardEventValidator) => boolean = () => this._validateStepEmpresasSel();

  public readonly fnFinalize: () => void = () => {
    this._finalize();
  };

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._appService.sendBeacon(this._integracaoSalariosMultiService.resetJobUrl(true));
  }

  private _init(): void {
    this._empresasTableSource = [];
    this._procResultsTableSource = [];
    this.dataGridDefinitionEmpresas = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nEmpresa', dataType: 'string', caption: 'integracaoSalariosMulti.fields.nEmpresa', width: 150},
        {dataField: 'nomeEmpresa', dataType: 'string', caption: 'integracaoSalariosMulti.fields.nomeEmpresa'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnEmpresas'}
      ],
      dataSource: new CustomStore({
        key: 'nEmpresa',
        load: () => this._getEmpresasTableSource()
      }),
      height: '60vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      scrolling: {rowRenderingMode: 'virtual', columnRenderingMode: 'virtual'},
      remoteOperations: false
    };
    this.dataGridDefinitionProcResults = {
      columnHidingEnabled: false,
      columns: [{dataField: 'empresa', dataType: 'string', caption: 'integracaoSalariosMulti.fields.nEmpresa'}],
      dataSource: new CustomStore({
        load: () => this._getProcResultsTableSource()
      }),
      masterDetail: {enabled: true, template: 'masterDetailProcResults'},
      remoteOperations: false
    };

    this.dataGridDefinitionProcResultsMessages = {
      columnHidingEnabled: false,
      columnChooser: {enabled: false},
      columns: [
        {dataField: 'icon', dataType: 'string', caption: '', cellTemplate: 'cellTemplateProcResultsMessages', alignment: 'center'},
        {dataField: 'descricaoErro', dataType: 'string', caption: 'integracaoSalariosMulti.fields.descricaoErro'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'integracaoSalariosMulti.fields.nDocInterno'}
      ],
      export: {enabled: false},
      remoteOperations: false
    };

    this.pbProcLabel = '';
    this.pbProcPos = 0;
    this.pbProcLabel = 'global.text.initProcess';
  }

  private _showTimeoutModal(): void {
    if (isDefinedNotNull(this._timeoutModalRef)) {
      this._timeoutModalRef.close();
    }
    this._timeoutModalRef = this._cgModalService.showOkCancelVanilla('integracaoSalariosMulti.jobTimeoutModalTitle', 'integracaoSalariosMulti.jobTimeoutModalMessage', {
      size: 'md',
      showCancelBtn: false,
      btnOkText: 'integracaoSalariosMulti.comecar',
      backdrop: 'static',
      keyboard: false
    });
  }

  private _handleStateError(state: IIntegracaoSalariosMultiStatus): void {
    if (isDefinedNotNull(this._errorModalRef)) {
      this._errorModalRef.close();
    }
    this._errorModalRef = this._cgModalService.showOkCancelVanilla('global.text.error', state.description, {
      size: 'md',
      showCancelBtn: false,
      backdrop: 'static',
      keyboard: false
    });
  }

  private _finalize(): void {
    this._wizNavControl(true, false);
    this._init();
    this._invalidateSteps();
    this._setWizActiveStep(EIntegracaoSalariosMultiSteps.INTRO);
    this._refreshEmpresasTable();
    this.definitionNavWizard.items[STEP_INTRO_INDEX].setIncomplete();
  }

  private _validateStepEmpresasSel(): boolean {
    if (this.selectedRowKeysEmpresas.length > 0) {
      this._processar();
      return true;
    }
    this._plAlertService.error(this._translateService.instant('integracaoSalariosMulti.temSeleccionarUmaEmpresa'));
    return false;
  }

  private _checkStatus(fromInit: boolean = false): void {
    this._integracaoSalariosMultiService.getJobStatus().then((response: IIntegracaoSalariosMultiStatus) => {
      this.currentStatus = response;
      if (response.state === EIntegracaoSalariosMultiStatus.Timeout) {
        clearInterval(this._intervalId);
        this._showTimeoutModal();
        this._resetWizard();
        this._init();
      } else if (response.state === EIntegracaoSalariosMultiStatus.Error) {
        clearInterval(this._intervalId);
        this._handleStateError(response);
        if (fromInit) {
          this._init();
          this._resetWizard();
        } else {
          this._wizNavControl(true, false);
          this.definitionNavWizard.items[STEP_EMPRESAS_SEL_INDEX].setIncomplete();
          this._setWizActiveStep(EIntegracaoSalariosMultiSteps.EMPRESAS_SEL);
        }
      } else if (response.state === EIntegracaoSalariosMultiStatus.Ended) {
        clearInterval(this._intervalId);
        this._loadResults();
      }
      this.pbProcPos = Math.round((response.position / response.max) * 100);
      this.pbProcLabel = response.description;
    });
  }

  private _startProcessChecker(fromInit: boolean = false): void {
    this._wizNavControl(false, false);
    this._checkStatus(fromInit);
    this._intervalId = window.setInterval(() => {
      this._checkStatus(fromInit);
    }, INTERVAL_TIMEOUT);
  }

  private _resetWizard(): void {
    this._wizNavControl(true, true);
    this._invalidateSteps();
    this._setWizActiveStep(EIntegracaoSalariosMultiSteps.INTRO);
    this._refreshEmpresasTable();
    this.definitionNavWizard.items[STEP_INTRO_INDEX].setIncomplete();
  }

  private _processar(): Promise<void> {
    return this._integracaoSalariosMultiService.process(this.selectedRowKeysEmpresas, this.selYear, this.selMonth).then(() => {
      this._startProcessChecker();
    });
  }

  private _getWizStepIndex(wizStepId: EIntegracaoSalariosMultiSteps): number {
    switch (wizStepId) {
      case EIntegracaoSalariosMultiSteps.INTRO:
        return STEP_INTRO_INDEX;
      case EIntegracaoSalariosMultiSteps.EMPRESAS_SEL:
        return STEP_EMPRESAS_SEL_INDEX;
      case EIntegracaoSalariosMultiSteps.PROC:
        return STEP_PROC_INDEX;
      case EIntegracaoSalariosMultiSteps.PROC_RESULTS:
        return STEP_PROC_RESULTS_INDEX;
      default:
        return -1;
    }
  }

  private _setWizActiveStep(wizStepId: EIntegracaoSalariosMultiSteps, markPrevAsVisited: boolean = false): void {
    const stepIndex = this._getWizStepIndex(wizStepId);
    if (markPrevAsVisited) {
      for (let i = 0; i < stepIndex; i++) {
        this.definitionNavWizard.items[i].visited = true;
        this.definitionNavWizard.items[i].setComplete();
      }
    }
    this.definitionNavWizard.items[stepIndex].visited = true;
    this.plWizardCallback.setStep(this.definitionNavWizard.items[stepIndex], true);
  }

  private _wizNavControl(nav: boolean, finilize: boolean = true): void {
    this.propertiesNavWizard = {disableNavigation: !nav, disableFinalize: !finilize};
  }

  private _loadResults(): void {
    this._setWizActiveStep(EIntegracaoSalariosMultiSteps.PROC_RESULTS, true);
    this._wizNavControl(false, true);
    this._integracaoSalariosMultiService.getProcResults().then((response: Array<IIntegracaoSalariosMultiProcResult>) => {
      this._procResultsTableSource = [];
      response.forEach((item: IIntegracaoSalariosMultiProcResult) => {
        item.icon = item.errorType !== ERROR_TYPE_SUCCESS_CODE ? 'fa fa-exclamation-triangle text-danger' : 'fa fa-check text-success';

        const srcItem = this._procResultsTableSource.find((obj: IIntegracaoSalariosMultiProcResultTableItem) => obj.codEmpresa === item.codEmpresa);

        if (isDefinedNotNull(srcItem)) {
          if (isUndefinedOrNull(srcItem.messages)) {
            srcItem.messages = [];
          }
          srcItem.messages.push(item);
        } else {
          this._procResultsTableSource.push({
            codEmpresa: item.codEmpresa,
            messages: [item],
            empresa: `Empresa: ${item.codEmpresa} - ${item.nomeEmpresa}`
          });
        }
      });
      this._refreshProcResultsTable();
    });
  }

  private _invalidateSteps(): void {
    this.definitionNavWizard.items.forEach((step: IPlNavWizardStep) => {
      step.visited = false;
      step.setIncomplete();
    });
  }

  private _getEmpresasTableSource(): Promise<Array<IIntegracaoSalariosMultiEmpresa>> {
    this.selectedRowKeysEmpresas = [];
    return this._integracaoSalariosMultiService.getEmpresas().then((response: Array<IIntegracaoSalariosMultiEmpresa>) => {
      this._empresasTableSource = response;
      for (const item of this._empresasTableSource) {
        if (item.sel) {
          this.selectedRowKeysEmpresas.push(item.nEmpresa);
        }
      }
      return this._empresasTableSource;
    });
  }

  private _getProcResultsTableSource(): Array<IIntegracaoSalariosMultiProcResultTableItem> {
    return this._procResultsTableSource;
  }

  private _refreshEmpresasTable(): void {
    if (this._dataGridInstanceEmpresas) {
      this._dataGridInstanceEmpresas.refresh();
    }
  }

  private _refreshProcResultsTable(): void {
    if (this._dataGridInstanceProcResults) {
      this._dataGridInstanceProcResults.refresh();
    }
  }
}
