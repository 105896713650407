<div class="templates-entity">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <div class="row">
        <div class="col-12 col-md-8">
          <pl-form class="entity-detail-form" [properties]="{validators: {required: {value: true}}}">
            <pl-group>
              <label [translate]="'templates.fields.name'"></label>
              <edit>
                <div class="form-control-align">{{ model.name }}</div>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'templates.fields.subject'"></label>
              <edit>
                <pl-edit type="text" [(model)]="model.subject" attrName="subject"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'templates.fields.body'"></label>
              <edit>
                <dx-html-editor [(ngModel)]="model.body" [height]="400" [disabled]="type === stateTypes.DETAIL">
                  <dxo-toolbar [multiline]="true">
                    <dxi-item name="undo"></dxi-item>
                    <dxi-item name="redo"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"></dxi-item>
                    <dxi-item name="font" [acceptedValues]="['Arial', 'Georgia', 'Tahoma', 'Times New Roman', 'Verdana']"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="bold"></dxi-item>
                    <dxi-item name="italic"></dxi-item>
                    <dxi-item name="strike"></dxi-item>
                    <dxi-item name="underline"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="alignLeft"></dxi-item>
                    <dxi-item name="alignCenter"></dxi-item>
                    <dxi-item name="alignRight"></dxi-item>
                    <dxi-item name="alignJustify"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="orderedList"></dxi-item>
                    <dxi-item name="bulletList"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="color"></dxi-item>
                    <dxi-item name="background"></dxi-item>
                    <dxi-item name="separator"></dxi-item>
                    <dxi-item name="link"></dxi-item>
                    <dxi-item name="image"></dxi-item>
                  </dxo-toolbar>
                </dx-html-editor>
              </edit>
            </pl-group>
          </pl-form>
        </div>

        <div class="col-12 col-md-4" [plPromise]="promise">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title" [translate]="'templates.fields.variables'"></h5>
            </div>
            <div class="card-body">
              <div class="templates-vars-list">
                <ng-container *ngFor="let variable of variaveis">
                  <pl-tooltip [config]="{text: variable.value}">
                    <div class="templates-var-item" *plTooltipContent (click)="applyVariable(variable)">{{ variable.name }}</div>
                  </pl-tooltip>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </entity-detail>
</div>
