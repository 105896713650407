export enum EProcAutoTipo {
  None,
  PrimeiroDiaUtil,
  UltimoDiaUtil,
  PrimeiroDiaDoPeriodo,
  UltimoDiaPeriodo,
  DiaFixo
}

export interface IJsonClasseContrato {
  nClasseCtr: string;
  nomeClasseCtr: string;
  classeTipo: number;
}

export interface IJsonClasseContratoSelect extends IJsonClasseContrato {
  selected?: boolean;
}
