<div class="contabilidade-digital-client-connect-adddocs-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'arquivodigital.clientconnect.adddocsmodal.title'"></h4>
  </div>

  <div class="modal-body">
    <div class="contabilidadedigital-genericviewer-current-folder">
      <nav aria-label="contabilidadedigital-genericviewer-current-folder">
        <ol class="breadcrumb">
          <li class="breadcrumb-item contabilidadedigital-genericviewer-current-folder-label">
            <i class="fa fa-folder" aria-hidden="true"></i>&nbsp;<span [translate]="'arquivodigital.genericviewer.text.currentFolder'"></span>:
          </li>
          <li class="breadcrumb-item contabilidadedigital-genericviewer-current-folder-item" *ngFor="let folderName of foldersNamePath">{{ folderName }}</li>
        </ol>
      </nav>
    </div>

    <hr />

    <pl-upload
      [url]="url"
      [autoProcessQueue]="true"
      [hideActionCancel]="true"
      [hideActionRetry]="true"
      [hideActionUploadAll]="true"
      [hideActionUpload]="true"
      [maxFiles]="maxFiles"
      [maxFileSize]="maxFileSize"
      [parallelUploads]="maxFiles"
      [params]="params"
      [resizeTargetSize]="maxFileSize"
      resizeMethod="contain"
      [resizeBeforeAccept]="true"
      [uploadMultiple]="true"
      [zip]="true"
      [zipStandalone]="false"
      [callback]="callback"
      (evtAcceptedFile)="evaluateAllowClose()"
      (evtRejectedFile)="evaluateAllowClose()"
      (evtRemovedFile)="evaluateAllowClose()"
      (evtUploadedFile)="uploadedFile()"
      (evtFinishedUpload)="finishedUpload()"
      acceptedFiles=".pdf,.png,.jpg">
    </pl-upload>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-dismiss" plAutoFocus [disabled]="closeDisabled" (evtClicked)="dismiss()">
      <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="!uploadedSome ? 'global.btn.cancel' : 'global.btn.close'"></span>
    </pl-button>
  </div>
</div>
