import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {AtivosDepreciacaoModuleComponent} from './components/ativosDepreciacao.module.component';
import {MODULE_NAME_ATIVOS_DEPRECIACAO} from './ativosDepreciacao.module.interface';
import {RESOLVER_ATIVOS_DEPRECIACAO_ANOS_CALCULO} from './ativosDepreciacao.module.service';

export const MODULE_ATIVOS_DEPRECIACAO: IModuleDefinition = {
  name: MODULE_NAME_ATIVOS_DEPRECIACAO,
  state: {
    url: `/${MODULE_NAME_ATIVOS_DEPRECIACAO}`,
    component: AtivosDepreciacaoModuleComponent,
    data: {
      roles: [ROLE.ATIVOS],
      pageTitle: 'global.menu.ativosdepreciacao',
      icon: 'fa-percent'
    },
    resolve: [RESOLVER_ATIVOS_DEPRECIACAO_ANOS_CALCULO]
  }
};
