<div class="colaboradores-gestao-dgemps-module">
  <gestao-dgemps
    [type]="moduleType"
    [manager]="managerMode"
    [toolbarInstanceName]="instanceName"
    [configStructure]="configStructure"
    [configEvents]="configEvents"
    [fluxos]="fluxos"
    [empregado]="empregado"
    [holidaysDates]="holidaysDates"
    [codServico]="codServico"
    [colaboradoresPendentes]="colaboradoresPendentes">
  </gestao-dgemps>
</div>
