<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="modalTitle"></h4>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12" *ngIf="modalMode === modalModes.Both || modalMode === modalModes.OnlyContabilidade">
        <div class="card mb-3">
          <div class="card-header" [translate]="'conciliacaoBancaria.lancamentoContab'"></div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <pl-group>
                  <label [translate]="'conciliacaoBancaria.fields.nconta'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      [selectedKey]="model.radical"
                      (selectedKeyChange)="onPocChange($event)"
                      [properties]="{validators: {required: {value: true}}}"
                      [filter]="pocsFilter"
                      output="key"
                      outputDescription="key"
                      plAutoFocus>
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'conciliacaoBancaria.fields.diario'"></label>
                  <edit>
                    <entity-autocomplete entity="diarios" [selectedKey]="model.diario" (selectedKeyChange)="onDiarioChange($event)" output="key" outputDescription="key"></entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
              <div class="col-md-6">
                <pl-group>
                  <label class="text-success"><span [translate]="modalMode !== modalModes.OnlyContabilidade ? notaCaption : '&nbsp;'"></span>:</label>
                  <edit>
                    <div class="nota" [translate]="notaMsg">&nbsp;</div>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'conciliacaoBancaria.fields.descritivo'"></label>
                  <edit>
                    <entity-autocomplete entity="descritivos" [selectedKey]="model.descritivo" (selectedKeyChange)="onDescritivoChange($event)" output="key" outputDescription="key">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12" *ngIf="modalMode === modalModes.Both || modalMode === modalModes.OnlyBanco">
        <div class="row">
          <div class="col-md-6">
            <pl-group>
              <label [translate]="'conciliacaoBancaria.fields.descricao'"></label>
              <edit>
                <pl-edit type="text" [(model)]="model.descricao" plAutoFocus></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'conciliacaoBancaria.fields.valor'"></label>
              <edit>
                <pl-edit type="number" [(model)]="model.valor"></pl-edit>
              </edit>
            </pl-group>
          </div>

          <div class="col-md-6">
            <pl-group>
              <label [translate]="'global.text.date'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="model.data"></pl-edit-datepicker>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'conciliacaoBancaria.fields.tipoMovimento'"></label>
              <edit>
                <pl-edit type="radiogroup" [(model)]="model.dc" [properties]="{groupItems: tipoMovimentoItems}"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-primary action-save" [onClick]="close" data-focus><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="saveBtnTitle"></span></pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</pl-form>
