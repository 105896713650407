import {Subscription} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';
import {deepFreeze} from 'pl-comps-angular';
import {APP_LOCALES, LOCALE_FLAGS_MAP} from '../../../../../common/i18n/locales';
import {AppService} from '../../../../services/app/app.service';
import {IUserLocaleItem} from './cg.user.locale.chooser.component.interface';

const LANGUAGES: ReadonlyArray<IUserLocaleItem> = deepFreeze(
  APP_LOCALES.map(
    (locale: string) =>
      ({
        value: locale,
        label: `locale.descriptive.${locale}`,
        flag: LOCALE_FLAGS_MAP.get(locale)
      }) satisfies IUserLocaleItem
  )
);

let COUNT = 0;

@Component({
  selector: 'cg-user-locale-chooser',
  templateUrl: './cg.user.locale.chooser.component.html'
})
export class CGUserLocaleChooserComponent implements OnDestroy {
  public readonly locales: ReadonlyArray<IUserLocaleItem>;
  public readonly count: number;
  public locale: IUserLocaleItem;

  private readonly _subscriptionLocale: Subscription;

  constructor(private readonly _appService: AppService) {
    this.locales = LANGUAGES;
    this.count = ++COUNT;

    this._subscriptionLocale = this._appService.locale().subscribe((locale: string) => {
      this.locale = this.locales.find((localeItem: IUserLocaleItem) => localeItem.value === locale);
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
  }

  public async changedLocale(locale: IUserLocaleItem): Promise<void> {
    await this._appService.setLocale(locale.value);
  }
}
