import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_REGIONAL_TAXA} from './areaRegionalTaxa.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_REGIONAL_TAXA: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_REGIONAL_TAXA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'areaRegionalTaxa.data.continent'},
    {value: 1, name: 'areaRegionalTaxa.data.madeira'},
    {value: 2, name: 'areaRegionalTaxa.data.acores'}
  ]
});
