<div class="pl-inline-edit" [ngSwitch]="editMode">
  <div *ngSwitchCase="true" class="pl-inline-edit-wrapper">
    <pl-edit class="pl-inline-edit" [type]="type" [attrName]="attrName" [inputClass]="inputClass" [model]="value" [properties]="options" (modelChange)="value = $event; render(value)">
      <ng-container *ngFor="let itemGroup of itemGroups">
        <ng-container *inputGroup="itemGroup.config">
          <ng-container *ngTemplateOutlet="itemGroup.templateRef"></ng-container>
        </ng-container>
      </ng-container>
    </pl-edit>

    <div class="pl-inline-edit-close" (click)="toggleMode()">
      <ng-container *ngTemplateOutlet="options.labelSet ? options.labelSet : defaultLabelSet"></ng-container>
    </div>
  </div>

  <ng-container *ngSwitchDefault [ngSwitch]="showMask">
    <ng-container *ngSwitchDefault>
      <div class="pl-inline-edit-label" [ngClass]="inputClass" [ngSwitch]="options.readonly">
        <div *ngSwitchDefault class="pl-inline-edit-open" (click)="toggleMode()">{{ viewValue }}</div>
        <span *ngSwitchCase="true">{{ viewValue }}</span>
      </div>
    </ng-container>

    <div *ngSwitchCase="true" class="pl-inline-edit-open" [ngSwitch]="!!options.placeholder" (click)="toggleMode()">
      <span *ngSwitchCase="true">(<span [plTranslate]="options.placeholder"></span>)</span>
      <span *ngSwitchCase="false">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </div>
  </ng-container>
</div>

<ng-template #defaultLabelSet>
  <i class="fa fa-check text-success" aria-hidden="true"></i>
</ng-template>
