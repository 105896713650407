import {AssistConfigPortaisUtilizadorModuleComponent} from './components/assistConfigPortais.utilizador.module.component';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {ENTITY_NAME_EMPRESAS, IEmpresa} from '../../../../../../entities/empresas/empresas.entity.interface';
import {EntityServiceBuilder} from '../../../../../../services/entity/entity.service.builder';
import {EPortal} from '../../../../../../../common/enums/portals.enums';
import {IEntityService} from '../../../../../../services/entity/entity.service.interface';
import {IModuleDefinition} from '../../../../../../components/module/module.definition.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_UTILIZADOR} from './assistConfigPortais.utilizador.module.interface';
import {ROLE} from '../../../../../../services/role.const';
import {THttpQueryResponse} from '../../../../../../services/api/api.service.interface';
import {TUserSession} from '../../../../../../services/account/jsonUserApi.interface';

export const MODULE_ASSISTENTE_CONFIG_PORTAIS_UTILIZADOR: IModuleDefinition = {
  name: MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_UTILIZADOR,
  state: {
    url: '/assistenteconfigportais/portalclientaccounts/utilizador',
    component: AssistConfigPortaisUtilizadorModuleComponent,
    data: {
      roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
      pageTitle: 'assistenteconfigportais.pages.utilizador'
    },
    resolve: [
      {
        provide: 'portalRolePCA',
        deps: [AuthService],
        useFactory: (authService: AuthService): Promise<ROLE> => authService.getAndGeneratePortalRole(EPortal.PCA)
      },
      {
        provide: 'empresasGabinete',
        deps: ['session', EntityServiceBuilder],
        useFactory: (session: TUserSession, entityServiceBuilder: EntityServiceBuilder): Promise<Array<IEmpresa>> => {
          const serviceEmpresas: IEntityService<IEmpresa> = entityServiceBuilder.build<IEmpresa>(ENTITY_NAME_EMPRESAS);
          return serviceEmpresas.query({pesquisa: 'EmpresaGabinete=1', params: {centralGestId: session.erp.centralGestId}}).then((response: THttpQueryResponse<IEmpresa>) => response.body.list);
        }
      }
    ]
  }
};
