import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_TIPO_COM_CATEGORIA} from './tipoComCategoria.datasource.interface';
import {ETipoComCategoria} from '../../entities/comcategoria/jsonComCategoria.entity.interface';
import {deepFreeze} from 'pl-comps-angular';

export const DATA_SOURCE_TIPO_COM_CATEGORIA: IDataSource<ETipoComCategoria> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_COM_CATEGORIA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoComCategoria.None, name: 'comcategorias.tipoComCategorias.data.none'},
    {value: ETipoComCategoria.Sistema, name: 'comcategorias.tipoComCategorias.data.sistema'},
    {value: ETipoComCategoria.Utilizador, name: 'comcategorias.tipoComCategorias.data.utilizador'}
  ]
});
