<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'rhEstatisticas.modals.exclusao.title'"></h4>
</div>

<div class="modal-body">
  <pl-multiselect [(model)]="model" [properties]="{rightCaption: 'rhEstatisticas.string.excluidos'}" [source]="source" key="key" [template]="msTemplate"></pl-multiselect>
</div>

<div class="modal-footer" plNavigation>
  <button type="submit" class="btn btn-light" (click)="close()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
