import {Injector} from '@angular/core';
import {IReportInstanceDefinition} from '../core/components/reports/input/reports.input.component.interface';
import {ReportsRegistryService} from '../core/components/reports/reports.registry.service';
import {
  REPORT_ACRESCIMOS_ENCARGOS_FERIAS,
  REPORT_ADIANTAMENTOS,
  REPORT_ANALISE_MARGEM_LUCRO,
  REPORT_ATIVOS,
  REPORT_ATIVOS_MAPAS_OFICIAIS,
  REPORT_AVISOS_COBRANCA,
  REPORT_BALANCETES_ANALITICA,
  REPORT_BALANCETES_COMPARATIVO,
  REPORT_BALANCETES_NORMAL,
  REPORT_CONCILIACAO_BANCARIA,
  REPORT_CONTRATOS,
  REPORT_CTRIRS,
  REPORT_DEBITOS_DIRETOS_SEPA,
  REPORT_DEBITOS_DIRETOS_SEPA_CLIENTE,
  REPORT_DEM_FIN_SNC,
  REPORT_DOCS_COMERCIAIS_ESTATISTICAS,
  REPORT_DOCS_COMERCIAIS_ESTATISTICAS_DOC,
  REPORT_DOCUMENTOS,
  REPORT_ENCAR,
  REPORT_ENCOMENDAS,
  REPORT_EVENTOS_RH,
  REPORT_EXTRATOS,
  REPORT_EXTRATOS_POR_CONTA,
  REPORT_EXTRATOSDT,
  REPORT_FFERPOC,
  REPORT_IMP_AVISOS_COBRANCA,
  REPORT_IMP_DADOS_EMP,
  REPORT_IMPDE119,
  REPORT_IMPDECPE,
  REPORT_INVENTARIOS,
  REPORT_LBANC,
  REPORT_MAPAS_SINDICATOS,
  REPORT_MOVIMENTOS_EM_ABERTO,
  REPORT_PAGAMENTOS,
  REPORT_PROC_SEGU_FOLHA_FERIAS_MAPA,
  REPORT_RECIBOS,
  REPORT_RECSAL,
  REPORT_RH_FOLFER,
  REPORT_RH_IMPVENCT,
  REPORT_RH_PROCE_DMR_AT_MAPA,
  REPORT_RH_PROCE_SEG_SOCIAL_MAPACX,
  REPORT_RH_PROCE_SEG_SOCIAL_MAPACX_RE,
  REPORT_SVAT,
  REPORT_TRIBUT_AUTONOMA_ANALISE
} from '../core/entities/reports/reports';

const REPORTS: Array<IReportInstanceDefinition> = [
  REPORT_ACRESCIMOS_ENCARGOS_FERIAS,
  REPORT_ADIANTAMENTOS,
  REPORT_ATIVOS,
  REPORT_ATIVOS_MAPAS_OFICIAIS,
  REPORT_AVISOS_COBRANCA,
  REPORT_BALANCETES_ANALITICA,
  REPORT_BALANCETES_COMPARATIVO,
  REPORT_BALANCETES_NORMAL,
  REPORT_DOCUMENTOS,
  REPORT_EXTRATOS,
  REPORT_EXTRATOS_POR_CONTA,
  REPORT_IMPDECPE,
  REPORT_MOVIMENTOS_EM_ABERTO,
  REPORT_PAGAMENTOS,
  REPORT_RECIBOS,
  REPORT_RECSAL,
  REPORT_SVAT,
  REPORT_CONCILIACAO_BANCARIA,
  REPORT_RH_PROCE_SEG_SOCIAL_MAPACX,
  REPORT_RH_PROCE_SEG_SOCIAL_MAPACX_RE,
  REPORT_RH_PROCE_DMR_AT_MAPA,
  REPORT_PROC_SEGU_FOLHA_FERIAS_MAPA,
  REPORT_DOCS_COMERCIAIS_ESTATISTICAS,
  REPORT_DOCS_COMERCIAIS_ESTATISTICAS_DOC,
  REPORT_RH_FOLFER,
  REPORT_INVENTARIOS,
  REPORT_LBANC,
  REPORT_RH_IMPVENCT,
  REPORT_CTRIRS,
  REPORT_FFERPOC,
  REPORT_ENCAR,
  REPORT_IMP_AVISOS_COBRANCA,
  REPORT_EVENTOS_RH,
  REPORT_IMP_DADOS_EMP,
  REPORT_DEM_FIN_SNC,
  REPORT_CONTRATOS,
  REPORT_IMPDE119,
  REPORT_MAPAS_SINDICATOS,
  REPORT_ENCOMENDAS,
  REPORT_TRIBUT_AUTONOMA_ANALISE,
  REPORT_DEBITOS_DIRETOS_SEPA,
  REPORT_DEBITOS_DIRETOS_SEPA_CLIENTE,
  REPORT_ANALISE_MARGEM_LUCRO,
  REPORT_EXTRATOSDT
];

export function configReports(injector: Injector): void {
  const reportsRegistryService: ReportsRegistryService = injector.get<ReportsRegistryService>(ReportsRegistryService);
  for (const report of REPORTS) {
    reportsRegistryService.register(report);
  }
}
