import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import type {TValueOrPromise} from '../common/utilities/utilities.interface';

export function plUploadErrorHandlerFactory(): PlUploadErrorHandler {
  return new PlUploadGenericErrorHandler();
}

@Injectable({
  providedIn: 'root',
  useFactory: plUploadErrorHandlerFactory
})
export abstract class PlUploadErrorHandler {
  public abstract parseError(httpErrorResponse: HttpErrorResponse): TValueOrPromise<string>;
}

@Injectable({
  providedIn: 'root'
})
export class PlUploadGenericErrorHandler extends PlUploadErrorHandler {
  public parseError(httpErrorResponse: HttpErrorResponse): TValueOrPromise<string> {
    return httpErrorResponse.message;
  }
}
