import {Subscription} from 'rxjs';
import {Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {EDelphiNumberTypes, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {
  CONTA_CLIENTES_MAX,
  CONTA_CLIENTES_MIN,
  CONTA_FORNECEDORES_MAX,
  CONTA_FORNECEDORES_MIN,
  EModuleTipo,
  IDocComercialEstatisticaItem,
  IDocsComerciasEstatisticasFilters,
  MAX_NCCUSTO,
  MAX_STRING
} from '../../docsComerciaisEstatisticas.module.interface';
import {DocsComerciaisEstatisticasService} from '../../docsComerciaisEstatisticas.module.service';
import {EConfigOptionsInstanceName, IDocsComerciaisEstatisticasConfigOptions, TConfigOptions} from '../../../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../../../config/constants';
import {EGrupoDoc} from '../../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../../../entities/docfas/docFas.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IJsonDocfa} from '../../../../../entities/docfas/jsonDocFa.entity.interface';
import moment from 'moment';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized, IDevExpressPivotGridEventOnExporting} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IApiQueryResponse} from '../../../../../services/api/api.service.interface';
import {HttpResponse} from '@angular/common/http';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import {devExpressGenerateFormatDouble} from '../../../../../components/devexpress/widget/devexpress.widget.utilities';
import {IDevExpressPivotGrid} from '../../../../../components/devexpress/pivotgrid/devexpress.pivotgrid.interface';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';
import {devExpressPivotGridExportToExcel} from '../../../../../components/devexpress/pivotgrid/export/devexpress.pivotgrid.export';
import {TranslateService} from '@ngx-translate/core';
import CustomStore from 'devextreme/data/custom_store';

const ALLOWED_PAGE_SIZES = 10;

@Component({
  selector: 'docs-comerciais-estatisticas',
  templateUrl: './docsComerciaisEstatisticas.component.html'
})
export class DocsComerciaisEstatisticasComponent implements OnInit, OnDestroy {
  @Input() public moduleType: EModuleTipo;
  @Input() public nArtigo: string;
  @Output() public readonly evtFilterChanged: EventEmitter<IDocsComerciasEstatisticasFilters>;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly pivotGridDefinition: IDevExpressPivotGrid;
  public readonly docFaTemplate: string;
  public readonly docFaOutput: string;
  public readonly dataSource: CustomStore;

  public pivotGridDataSource: PivotGridDataSource;
  public filters: IDocsComerciasEstatisticasFilters;
  public tableSource: IApiQueryResponse<IDocComercialEstatisticaItem>;
  public showMoreFilters: boolean;
  public docFaSource: Array<IJsonDocfa>;
  public docfalist: Array<number>;
  public optionShowFilterTipoArtigo: boolean;
  public optionShowFilterGrFamilia: boolean;
  public optionShowFilterFamilia: boolean;
  public optionShowFilterSubFamilia: boolean;
  public optionShowFilterClasseArtigo: boolean;
  public optionShowFilterCategoriaArtigo: boolean;
  public optionShowFilterSoComMovimStock: boolean;
  public optionShowFilterConta: boolean;
  public optionShowFilterVendedor: boolean;
  public optionShowFilterZona: boolean;
  public optionShowFilterCentroCusto: boolean;
  public optionShowFilterProcesso: boolean;
  public optionShowFilterDocfaList: boolean;
  public optionShowFilterGrupoConta: boolean;
  public optionShowFilterClasse: boolean;
  public nContaFilter: string;
  public qtdDocsPesquisa: number;
  public temInputNartigo: boolean;

  private readonly _docFaService: IDocFasEntityService;
  private _firstLoad: boolean;
  private _docsComerciaisEstatisticasOptions: TConfigOptions<boolean, IDocsComerciaisEstatisticasConfigOptions>;
  private _subscription: Subscription;

  private _dataGridInstance: dxDataGrid;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _docsComerciasEstatisticasService: DocsComerciaisEstatisticasService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _translateService: TranslateService
  ) {
    this.evtFilterChanged = new EventEmitter<IDocsComerciasEstatisticasFilters>();
    this.configOptionsInstanceName = EConfigOptionsInstanceName.DOCS_COMERCIAIS_ESTATISTICAS;
    this.configOptionsGroupName = EGroupName.ERP;
    this.docFaTemplate = '{{nDocFa}} - {{nome}}';
    this.docFaOutput = 'nDocFa';
    this.tableSource = null;
    this.docFaSource = [];
    this.docfalist = [];
    this._docFaService = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
    this._firstLoad = true;
    this.qtdDocsPesquisa = 0;
    this.temInputNartigo = false;
    this.dataGridDefinition = {
      columns: [
        {dataField: 'nDocStr', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nDocStr'},
        {dataField: 'docfaNome', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.docfaNome', visible: false},
        {dataField: 'nnumer', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nnumer', visible: false},
        {dataField: 'nArtigo', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nArtigo'},
        {dataField: 'nomeArtigo', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nomeArtigo'},
        {dataField: 'nConta', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nConta'},
        {dataField: 'contaNome', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.contaNome'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'docsComerciaisEstatisticas.fields.dataDoc'},
        {dataField: 'nFactFornec', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nFactFornec', visible: false},
        {dataField: 'dataDocExterno', dataType: 'date', caption: 'docsComerciaisEstatisticas.fields.dataDocExterno', visible: false},
        {dataField: 'liquido', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.liquido', visible: false},
        {dataField: 'valorIva', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.valorIva', visible: false},
        {dataField: 'totalLin', dataType: 'double', caption: 'global.text.total', visible: false},
        {dataField: 'sinal', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.sinal', visible: false},
        {dataField: 'liquidoMovimento', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.liquidoMovimento'},
        {dataField: 'valorIvaMovimento', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.valorIvaMovimento'},
        {dataField: 'totalMovimento', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.totalMovimento'},
        {dataField: 'qtd1', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.qtd1'},
        {dataField: 'prVendaQtd1', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.prVendaQtd1'},
        {dataField: 'd1', dataType: 'double', caption: 'docsComerciaisEstatisticas.fields.d1'},
        {dataField: 'centroCusto', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.centroCusto'},
        {dataField: 'nVendedor', dataType: 'number', caption: 'docsComerciaisEstatisticas.fields.nVendedor', visible: false},
        {dataField: 'nomeVendedor', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nomeVendedor', visible: false},
        {dataField: 'nRefProcesso', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nRefProcesso', visible: false},
        {dataField: 'nomeProcesso', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nomeProcesso', visible: false},
        {dataField: 'sectorId', dataType: 'number', caption: 'docsComerciaisEstatisticas.fields.sectorId', visible: false},
        {dataField: 'nomeSector', dataType: 'string', caption: 'docsComerciaisEstatisticas.fields.nomeSector', visible: false}
      ],
      dataSource: [],
      export: {filename: 'docscomerciais.estatisticas'},
      columnHidingEnabled: false,
      pager: {
        allowedPageSizes: [ALLOWED_PAGE_SIZES],
        displayMode: 'full',
        visible: true
      },
      summary: {
        totalItems: [
          {column: 'totalMovimento', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'valorIvaMovimento', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'liquidoMovimento', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}
        ]
      }
    };
    this.filters = {
      ncontade: '',
      ncontaate: '',
      nartigode: '',
      nartigoate: MAX_STRING,
      tipoartigode: 0,
      tipoartigoate: EDelphiNumberTypes.MaxInteger,
      ngrfamiliade: 0,
      ngrfamiliaate: EDelphiNumberTypes.MaxInteger,
      nfamiliade: 0,
      nfamiliaate: EDelphiNumberTypes.MaxInteger,
      nsubfamiliade: 0,
      nsubfamiliaate: EDelphiNumberTypes.MaxInteger,
      classede: 0,
      classeate: EDelphiNumberTypes.MaxInteger,
      categoriade: 0,
      categoriaate: EDelphiNumberTypes.MaxInteger,
      datade: moment().subtract(1, 'months').startOf('month'),
      dataate: moment().subtract(1, 'months').endOf('month'),
      nvendedorde: 0,
      nvendedorate: EDelphiNumberTypes.MaxSmallInt,
      codzonade: 0,
      codzonaate: EDelphiNumberTypes.MaxSmallInt,
      ccustode: '',
      ccustoate: MAX_NCCUSTO,
      nrefprocessode: '',
      nrefprocessoate: MAX_STRING,
      socommovimstock: false,
      docfalist: '',
      ididechavde: '',
      ididechavate: MAX_STRING,
      cliclde: '',
      cliclate: MAX_STRING
    };

    const storageKeyStr = this.moduleType === EModuleTipo.VENDAS ? 'vol-negocio-venda-artigo-pivotgrid-storing' : 'vol-negocio-compra-artigo-pivotgrid-storing';

    this.showMoreFilters = false;
    this.pivotGridDefinition = {
      allowSortingBySummary: true,
      allowFiltering: true,
      showBorders: true,
      showRowGrandTotals: true,
      showRowTotals: false,
      showColumnGrandTotals: true,
      showColumnTotals: false,
      fieldChooser: {
        enabled: true,
        allowSearch: true,
        layout: 1
      },
      export: {enabled: true},
      scrolling: {
        mode: 'virtual'
      },
      stateStoring: {
        enabled: true,
        type: 'localStorage',
        storageKey: storageKeyStr
      },
      texts: {
        grandTotal: 'Total'
      }
    };
    this.fnPesquisar = this.fnPesquisar.bind(this);
  }

  public ngOnInit(): void {
    this.filters.ncontade = this._getNContaMinMaxConst('MIN');
    this.filters.ncontaate = this._getNContaMinMaxConst('MAX');

    this.nContaFilter = this.moduleType !== EModuleTipo.TODOS ? `nConta=%${this._getNContaMinMaxConst('MIN')}%` : '';

    this._subscription = this._docsComerciasEstatisticasService.getOptions().subscribe((value: TConfigOptions<boolean, IDocsComerciaisEstatisticasConfigOptions>) => {
      this._docsComerciaisEstatisticasOptions = value;
      this.optionShowFilterTipoArtigo = this._docsComerciaisEstatisticasOptions.get('showFilterTipoArtigo').value;
      this.optionShowFilterGrFamilia = this._docsComerciaisEstatisticasOptions.get('showFilterGrFamilia').value;
      this.optionShowFilterFamilia = this._docsComerciaisEstatisticasOptions.get('showFilterFamilia').value;
      this.optionShowFilterSubFamilia = this._docsComerciaisEstatisticasOptions.get('showFilterSubFamilia').value;
      this.optionShowFilterClasseArtigo = this._docsComerciaisEstatisticasOptions.get('showFilterClasseArtigo').value;
      this.optionShowFilterCategoriaArtigo = this._docsComerciaisEstatisticasOptions.get('showFilterCategoriaArtigo').value;
      this.optionShowFilterSoComMovimStock = this._docsComerciaisEstatisticasOptions.get('showFilterSoComMovimStock').value;
      this.optionShowFilterConta = this._docsComerciaisEstatisticasOptions.get('showFilterConta').value;
      this.optionShowFilterVendedor = this._docsComerciaisEstatisticasOptions.get('showFilterVendedor').value;
      this.optionShowFilterZona = this._docsComerciaisEstatisticasOptions.get('showFilterZona').value;
      this.optionShowFilterCentroCusto = this._docsComerciaisEstatisticasOptions.get('showFilterCentroCusto').value;
      this.optionShowFilterProcesso = this._docsComerciaisEstatisticasOptions.get('showFilterProcesso').value;
      this.optionShowFilterDocfaList = this._docsComerciaisEstatisticasOptions.get('showFilterDocfaList').value;
      this.optionShowFilterGrupoConta = this._docsComerciaisEstatisticasOptions.get('showFilterGrupoConta').value;
      this.optionShowFilterClasse = this._docsComerciaisEstatisticasOptions.get('showFilterClasse').value;
    });

    if (this.nArtigo) {
      this.temInputNartigo = true;
      this.dataGridDefinition.dataSource = new CustomStore({
        load: () => {
          return this._loadDofaList();
        }
      });
      this.filters = {...this.filters, nartigode: this.nArtigo, nartigoate: this.nArtigo, datade: moment().startOf('year'), dataate: moment()};
    } else {
      this._loadDofaList();
    }
  }

  public ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  public fnEmitFilter(): void {
    this._emitFilter();
  }

  public ncontadeChanged(value: string): void {
    if (isUndefinedOrNull(value) || value === this._getNContaMinMaxConst('MIN')) {
      this.filters.ncontade = this._getNContaMinMaxConst('MIN');
      this.filters.ncontaate = this._getNContaMinMaxConst('MAX');
    } else {
      this.filters.ncontade = value;
      this.filters.ncontaate = value;
    }
    this._emitFilter();
  }

  public ncontaateChanged(value: string): void {
    if (!value) {
      this.filters.ncontaate = this._getNContaMinMaxConst('MAX');
    } else {
      this.filters.ncontaate = value;
    }
    this._emitFilter();
  }

  public nartigodeChanged(value: string): void {
    if (isUndefinedOrNull(value) || value === '') {
      this.filters.nartigode = '';
      this.filters.nartigoate = MAX_STRING;
    } else {
      this.filters.nartigode = value;
      this.filters.nartigoate = value;
    }
    this._emitFilter();
  }

  public nartigoateChanged(value: string): void {
    if (isUndefinedOrNull(value)) {
      this.filters.nartigoate = MAX_STRING;
    } else {
      this.filters.nartigoate = value;
    }
    this._emitFilter();
  }

  public tipoartigodeChanged(value: number): void {
    if (isUndefinedOrNull(value) || value.toString() === '0') {
      this.filters.tipoartigode = 0;
      this.filters.tipoartigoate = EDelphiNumberTypes.MaxInteger;
    } else {
      this.filters.tipoartigode = value;
      this.filters.tipoartigoate = value;
    }
    this._emitFilter();
  }

  public tipoartigoateChanged(value: number): void {
    if (isUndefinedOrNull(value)) {
      this.filters.tipoartigoate = EDelphiNumberTypes.MaxInteger;
    } else {
      this.filters.tipoartigoate = value;
    }
    this._emitFilter();
  }

  public ngrfamiliadeChanged(value: number): void {
    if (isUndefinedOrNull(value) || value.toString() === '0') {
      this.filters.ngrfamiliade = 0;
      this.filters.ngrfamiliaate = EDelphiNumberTypes.MaxInteger;
    } else {
      this.filters.ngrfamiliade = value;
      this.filters.ngrfamiliaate = value;
    }
    this._emitFilter();
  }

  public ngrfamiliaateChanged(value: number): void {
    if (isUndefinedOrNull(value)) {
      this.filters.ngrfamiliaate = EDelphiNumberTypes.MaxInteger;
    } else {
      this.filters.ngrfamiliaate = value;
    }
    this._emitFilter();
  }

  public nfamiliadeChanged(value: number): void {
    if (isUndefinedOrNull(value) || value.toString() === '0') {
      this.filters.nfamiliade = 0;
      this.filters.nfamiliaate = EDelphiNumberTypes.MaxInteger;
    } else {
      this.filters.nfamiliade = value;
      this.filters.nfamiliaate = value;
    }
    this._emitFilter();
  }

  public nfamiliaateChanged(value: number): void {
    if (isUndefinedOrNull(value)) {
      this.filters.nfamiliaate = EDelphiNumberTypes.MaxInteger;
    } else {
      this.filters.nfamiliaate = value;
    }
    this._emitFilter();
  }

  public nsubfamiliadeChanged(value: number): void {
    if (isUndefinedOrNull(value) || value.toString() === '0') {
      this.filters.nsubfamiliade = 0;
      this.filters.nsubfamiliaate = EDelphiNumberTypes.MaxInteger;
    } else {
      this.filters.nsubfamiliade = value;
      this.filters.nsubfamiliaate = value;
    }
    this._emitFilter();
  }

  public nsubfamiliaateChanged(value: number): void {
    if (isUndefinedOrNull(value)) {
      this.filters.nsubfamiliaate = EDelphiNumberTypes.MaxInteger;
    } else {
      this.filters.nsubfamiliaate = value;
    }
    this._emitFilter();
  }

  public classedeChanged(value: number): void {
    if (isUndefinedOrNull(value) || value.toString() === '0') {
      this.filters.classede = 0;
      this.filters.classeate = EDelphiNumberTypes.MaxInteger;
    } else {
      this.filters.classede = value;
      this.filters.classeate = value;
    }
    this._emitFilter();
  }

  public classeateChanged(value: number): void {
    if (isUndefinedOrNull(value)) {
      this.filters.classeate = EDelphiNumberTypes.MaxInteger;
    } else {
      this.filters.classeate = value;
    }
    this._emitFilter();
  }

  public categoriadeChanged(value: number): void {
    if (isUndefinedOrNull(value) || value.toString() === '0') {
      this.filters.categoriade = 0;
      this.filters.categoriaate = EDelphiNumberTypes.MaxInteger;
    } else {
      this.filters.categoriade = value;
      this.filters.categoriaate = value;
    }
    this._emitFilter();
  }

  public categoriaateChanged(value: number): void {
    if (isUndefinedOrNull(value)) {
      this.filters.categoriaate = EDelphiNumberTypes.MaxInteger;
    } else {
      this.filters.categoriaate = value;
    }
    this._emitFilter();
  }

  public nvendedordeChanged(value: number): void {
    if (isUndefinedOrNull(value) || value.toString() === '0') {
      this.filters.nvendedorde = 0;
      this.filters.nvendedorate = EDelphiNumberTypes.MaxSmallInt;
    } else {
      this.filters.nvendedorde = value;
      this.filters.nvendedorate = value;
    }
    this._emitFilter();
  }

  public nvendedorateChanged(value: number): void {
    if (isUndefinedOrNull(value)) {
      this.filters.nvendedorate = EDelphiNumberTypes.MaxSmallInt;
    } else {
      this.filters.nvendedorate = value;
    }
    this._emitFilter();
  }

  public codzonadeChanged(value: number): void {
    if (isUndefinedOrNull(value) || value.toString() === '0') {
      this.filters.codzonade = 0;
      this.filters.codzonaate = EDelphiNumberTypes.MaxSmallInt;
    } else {
      this.filters.codzonade = value;
      this.filters.codzonaate = value;
    }
    this._emitFilter();
  }

  public codzonaateChanged(value: number): void {
    if (isUndefinedOrNull(value)) {
      this.filters.codzonaate = EDelphiNumberTypes.MaxSmallInt;
    } else {
      this.filters.codzonaate = value;
    }
    this._emitFilter();
  }

  public ccustodeChanged(value: string): void {
    if (isUndefinedOrNull(value) || value === '') {
      this.filters.ccustode = '';
      this.filters.ccustoate = MAX_NCCUSTO;
    } else {
      this.filters.ccustode = value;
      this.filters.ccustoate = value;
    }
    this._emitFilter();
  }

  public ccustoateChanged(value: string): void {
    if (isUndefinedOrNull(value)) {
      this.filters.ccustoate = MAX_NCCUSTO;
    } else {
      this.filters.ccustoate = value;
    }
    this._emitFilter();
  }

  public idIdeChavDeChanged(value: string): void {
    if (isUndefinedOrNull(value) || value === '') {
      this.filters.ididechavde = '';
      this.filters.ididechavate = MAX_STRING;
    } else {
      this.filters.ididechavde = value;
      this.filters.ididechavate = value;
    }
    this._emitFilter();
  }

  public idIdeChavAteChanged(value: string): void {
    if (isUndefinedOrNull(value)) {
      this.filters.ididechavate = MAX_STRING;
    } else {
      this.filters.ididechavate = value;
    }
    this._emitFilter();
  }

  public cliclDeChanged(value: string): void {
    if (isUndefinedOrNull(value) || value === '') {
      this.filters.cliclde = '';
      this.filters.cliclate = MAX_STRING;
    } else {
      this.filters.cliclde = value;
      this.filters.cliclate = value;
    }
    this._emitFilter();
  }

  public cliclAteChanged(value: string): void {
    if (isUndefinedOrNull(value)) {
      this.filters.cliclate = MAX_STRING;
    } else {
      this.filters.cliclate = value;
    }
    this._emitFilter();
  }

  public nrefprocessodeChanged(value: string): void {
    if (isUndefinedOrNull(value) || value === '') {
      this.filters.nrefprocessode = '';
      this.filters.nrefprocessoate = MAX_STRING;
    } else {
      this.filters.nrefprocessode = value;
      this.filters.nrefprocessoate = value;
    }
    this._emitFilter();
  }

  public nrefprocessoateChanged(value: string): void {
    if (isUndefinedOrNull(value)) {
      this.filters.nrefprocessoate = MAX_STRING;
    } else {
      this.filters.nrefprocessoate = value;
    }
    this._emitFilter();
  }

  public changedDocfaList(value: Array<number>): void {
    this.docfalist = value;
    this._emitFilter();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onExporting(event: IDevExpressPivotGridEventOnExporting): void {
    devExpressPivotGridExportToExcel(event, this._translateService);
  }

  public async fnPesquisar(): Promise<void> {
    this._firstLoad = false;
    this.qtdDocsPesquisa = 0;
    await this.getTableSource();
  }

  public async getTableSource(): Promise<void> {
    if (this._firstLoad) {
      await Promise.resolve();
    }

    if (this.docfalist.length === 0) {
      this._plAlertService.error('docsComerciaisEstatisticas.errors.naoExisteTipoDocSelecionadoCheckOpcoes');
      await Promise.reject(this._translateService.instant('docsComerciaisEstatisticas.errors.naoExisteTipoDocSelecionadoCheckOpcoes'));
    }

    const filters: IDocsComerciasEstatisticasFilters = {
      ...this.filters,
      docfalist: this.docfalist.join(',')
    };
    try {
      const response: IApiQueryResponse<IDocComercialEstatisticaItem> = await this._docsComerciasEstatisticasService.getEstatisticasArtigos(filters, this.moduleType);
      if (response.list?.length) {
        this.qtdDocsPesquisa = response.total;
        this._cardPanel.collapse();
      } else {
        this._cardPanel.focusFirstElement();
        this._plAlertService.info('global.text.searchNoData');
      }
      this.dataGridDefinition.dataSource = response.list;
      this._applyPivotGrid(response.list);
    } catch (e) {
      this._cardPanel.focusFirstElement();
    }
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _emitFilter(): void {
    this.evtFilterChanged.emit({...this.filters, docfalist: this.docfalist.join(',')});
  }

  private _getNContaMinMaxConst(c: 'MIN' | 'MAX'): string {
    return c === 'MIN'
      ? this.moduleType === EModuleTipo.VENDAS || this.moduleType === EModuleTipo.TODOS
        ? CONTA_CLIENTES_MIN
        : CONTA_FORNECEDORES_MIN
      : this.moduleType === EModuleTipo.COMPRAS || this.moduleType === EModuleTipo.TODOS
        ? CONTA_FORNECEDORES_MAX
        : CONTA_CLIENTES_MAX;
  }

  private _getGrupoDocfa(): string {
    const filters: Array<string> = [];
    if (this.moduleType === EModuleTipo.VENDAS) {
      filters.push(`grupoDocfa=${EGrupoDoc.VendasEfectivas}`);
      filters.push(`grupoDocfa=${EGrupoDoc.EncomendasClientes}`);
      filters.push(`grupoDocfa=${EGrupoDoc.PropostasAClientes}`);
      filters.push(`grupoDocfa=${EGrupoDoc.GuiasTransporteRemessa}`);
    } else if (this.moduleType === EModuleTipo.COMPRAS) {
      filters.push(`grupoDocfa=${EGrupoDoc.ComprasEfectivas}`);
      filters.push(`grupoDocfa=${EGrupoDoc.EncomendasFornecedores}`);
    }
    return filters.join('|');
  }

  private _loadDofaList(): Promise<void> {
    return this._docFaService.query({pesquisa: this._getGrupoDocfa()}).then((response: HttpResponse<IApiQueryResponse<IJsonDocfa>>) => {
      this.docFaSource = response.body.list;
      this.docfalist = this.docFaSource
        .filter((item: IJsonDocfa) => {
          return this.moduleType !== EModuleTipo.TODOS
            ? this.moduleType === EModuleTipo.VENDAS
              ? item.grupoDocfa === EGrupoDoc.VendasEfectivas
              : item.grupoDocfa === EGrupoDoc.ComprasEfectivas
            : true;
        })
        .map((item: IJsonDocfa) => item.nDocFa);

      this._emitFilter();
      if (this.temInputNartigo) {
        return this.fnPesquisar();
      }
      return Promise.resolve();
    });
  }

  private _applyPivotGrid(store: Array<IDocComercialEstatisticaItem>): void {
    const format: string = devExpressGenerateFormatDouble(2);
    this.pivotGridDataSource = new PivotGridDataSource({
      fields: [
        {
          dataField: 'nomeArtigo',
          area: 'row',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.artigo'),
          width: 150,
          expanded: true
        },
        {
          dataField: 'docfaNome',
          area: 'row',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.docfaNome'),
          width: 150,
          expanded: true
        },
        {
          dataField: 'mesStr',
          area: 'row',
          caption: this._translateService.instant('global.text.month'),
          width: 85,
          expanded: true
        },
        {
          dataField: 'nomeFamilia',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nomeFamilia'),
          area: 'column',
          expanded: true
        },
        {
          dataField: 'liquido',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.liquido')
        },
        {
          dataField: 'valorIva',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.valorIva')
        },
        {
          dataField: 'nDocStr',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nDocStr')
        },
        {
          dataField: 'nArtigo',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nArtigoPivot')
        },
        {
          dataField: 'nConta',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nConta')
        },
        {
          dataField: 'contaNome',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.contaNome')
        },
        {
          dataField: 'dataDoc',
          dataType: 'date',
          format: 'shortDate',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.dataDoc')
        },
        {
          dataField: 'nFactFornec',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nFactFornec')
        },
        {
          dataField: 'dataDocExterno',
          dataType: 'date',
          format: 'shortDate',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.dataDocExterno')
        },
        {
          dataField: 'totalLin',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('global.text.total')
        },
        {
          dataField: 'sinal',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.sinal')
        },
        {
          dataField: 'liquidoMovimento',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.liquidoMovimento'),
          area: 'data'
        },
        {
          dataField: 'valorIvaMovimento',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.valorIvaMovimento')
        },
        {
          dataField: 'totalMovimento',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.totalMovimento')
        },
        {
          dataField: 'qtd1',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.qtd1')
        },
        {
          dataField: 'prVendaQtd1',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.prVendaQtd1')
        },
        {
          dataField: 'd1',
          dataType: 'number',
          summaryType: 'sum',
          format: format,
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.d1')
        },
        {
          dataField: 'centroCusto',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.centroCusto')
        },
        {
          dataField: 'nVendedor',
          dataType: 'number',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nVendedor')
        },
        {
          dataField: 'nomeVendedor',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nomeVendedor')
        },
        {
          dataField: 'nDocfa',
          dataType: 'number',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nDocfa')
        },
        {
          dataField: 'nDepart',
          dataType: 'number',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nDepart')
        },
        {
          dataField: 'nomeDepart',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nomeDepart')
        },
        {
          dataField: 'nFamil',
          dataType: 'number',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nFamil')
        },
        {
          dataField: 'nSubfa',
          dataType: 'number',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nSubfa')
        },
        {
          dataField: 'nomeSubFamilia',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nomeSubFamilia')
        },
        {
          dataField: 'tipoArtigo',
          dataType: 'number',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.tipoArtigo')
        },
        {
          dataField: 'nomeTipoArtigo',
          dataType: 'string',
          caption: this._translateService.instant('docsComerciaisEstatisticas.fields.nomeTipoArtigo')
        },
        {
          dataField: 'ano',
          dataType: 'number',
          caption: this._translateService.instant('global.text.year')
        }
      ],
      store: store,
      retrieveFields: false
    });
  }
}
