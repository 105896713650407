import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, OnInit} from '@angular/core';
import {ENTITY_NAME_DGEMPS_FULL, IDGEMPSFullEntityService} from '../../../portalrh/dgempsfull/dgempsFull.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonDGEMPVencimentoSimulacao, IJsonDGEMPVencimentoSimulacaoABDES, IVencimentoSimulacaoStateParams} from '../simulaValorVencimento.module.interface';
import {IJsonDGEMP} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonMoeda} from '../../../portalcontabilidade/manutencao/moeda/jsonMoeda.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {TDate} from '../../../../../common/dates';
import moment from 'moment';

const DEFAULT_DIAS_UTEIS = 22;

@Component({
  selector: 'simula-vencimento',
  templateUrl: './simulaValorVencimento.module.component.html'
})
export class SimulaValorVencimentoComponent extends ModuloComponent implements OnInit {
  public readonly definition: IDevExpressDataGrid<IJsonDGEMPVencimentoSimulacaoABDES>;

  public dgemp: IJsonDGEMP;
  public moeda: IJsonMoeda;
  public valorLiquido: number;
  public nDiasUteis: number;
  public simulacao: IJsonDGEMPVencimentoSimulacao;
  public editing: boolean;
  public dataSimulacao: TDate;

  private readonly _serviceDGEMPSFull: IDGEMPSFullEntityService;
  private _dataGridInstance: dxDataGrid<IJsonDGEMPVencimentoSimulacaoABDES>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    const params: IVencimentoSimulacaoStateParams = <IVencimentoSimulacaoStateParams>this._transition.params();
    this.dgemp = params.dgemp;
    this.definition = {
      columns: [
        {dataField: 'tipoAbono', dataType: 'string', caption: 'simulacaoVencimento.table.tipoAbono'},
        {dataField: 'codAbDesc', dataType: 'string', caption: 'simulacaoVencimento.table.codAbDesc'},
        {dataField: 'descricao', dataType: 'string', caption: 'simulacaoVencimento.table.descricao'},
        {dataField: 'qtd', dataType: 'number', caption: 'simulacaoVencimento.table.qtd', format: {suppressZeros: true}},
        {dataField: 'valorUnit', dataType: 'double', caption: 'simulacaoVencimento.table.valorUnit', format: {suppressZeros: true}},
        {dataField: 'valor', dataType: 'double', caption: 'simulacaoVencimento.table.valor', format: {suppressZeros: true}},
        {dataField: 'incidencia', dataType: 'number', caption: 'simulacaoVencimento.table.incidencia', format: {suppressZeros: true}},
        {dataField: 'percentagem', dataType: 'number', caption: 'simulacaoVencimento.table.percentagem'}
      ],
      hoverStateEnabled: true,
      pager: {visible: false},
      paging: {enabled: false},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'},
      height: '90vh',
      showBorders: true
    };
    this.nDiasUteis = DEFAULT_DIAS_UTEIS;
    this.valorLiquido = 0;
    this.dataSimulacao = moment();
    this._serviceDGEMPSFull = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS_FULL);
    this.atualizaValorSimulado = this.atualizaValorSimulado.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.editing = Boolean(this.dgemp?.codEmp);
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonDGEMPVencimentoSimulacaoABDES>): void {
    this._dataGridInstance = component;
  }

  public atualizaValorSimulado(): Promise<void> {
    if (!this.dgemp?.codEmp) {
      return Promise.resolve();
    }
    return this._serviceDGEMPSFull
      .simulateValorVencimento(this.dgemp.codEmp, {
        params: {
          valorliq: this.valorLiquido,
          ndiasuteis: this.nDiasUteis,
          cambio: 0,
          datasimulacao: this.dataSimulacao
        }
      })
      .then((response) => {
        this.simulacao = response.body;
        return this._dataGridInstance.refresh();
      });
  }
}
