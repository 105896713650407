import {TDate} from '../../../../../common/dates';

export enum EEstadoDocFaturado {
  NaoEncerrados,
  Encerrados,
  Todos
}

export interface IEstadosDocRadioItem {
  value: EEstadoDocFaturado;
  label: string;
}

export interface IGestaoContratosFaturadosFilterModel {
  nClasseContrato: string;
  nTiposContrato: string;
  dataDocDe: TDate;
  dataDocAte: TDate;
  nContaDe: string;
  nContaAte: string;
  estadoDoc: number;
}
