import {Injectable} from '@angular/core';
import {IJsonRastreabilidadeFaturacao} from './jsonRastreabilidade.interface';
import {ApiService} from '../../../../services/api/api.service';
import {TServiceResponse} from '../../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class DocumentoRastreabilidadeService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/rastreabilidadesfaturacao`;
  }

  public getRastreabilidade(nDocFaDraft: number, nNumerDraft: number, nDocumentoDraft: number): TServiceResponse<Array<IJsonRastreabilidadeFaturacao>> {
    return this._apiService.get<Array<IJsonRastreabilidadeFaturacao>>({
      url: this._path,
      params: {
        ndocfadraft: nDocFaDraft,
        nnumerdraft: nNumerDraft,
        ndocumentodraft: nDocumentoDraft
      }
    });
  }
}
