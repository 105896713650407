import {HttpResponse} from '@angular/common/http';
import {IJsonSegSocialFolhaFerias} from '../jsonRHConfiguracoes.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {RHConfiguracoesService} from '../rhConfiguracoes.module.service';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_RH_SEG_SOCIAL_FOLHA_FERIAS} from './rhSegSocialFolhaFerias.module.interface';
import {RhSegSocialFolhaFeriasModuleComponent} from './components/rhSegSocialFolhaFerias.module.component';

export const MODULE_RH_SEG_SOCIAL_FOLHA_FERIAS: IModuleDefinition = {
  name: MODULE_NAME_RH_SEG_SOCIAL_FOLHA_FERIAS,
  state: {
    url: `/${MODULE_NAME_RH_SEG_SOCIAL_FOLHA_FERIAS}`,
    component: RhSegSocialFolhaFeriasModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.segsocialfolhaferias'
    },
    resolve: [
      {
        provide: 'rhsegsocialfolhaferias',
        deps: [RHConfiguracoesService],
        useFactory: (rhConfiguracoesService: RHConfiguracoesService): Promise<IJsonSegSocialFolhaFerias> => {
          return rhConfiguracoesService.getSegSocialFolhaFerias().then((response: HttpResponse<IJsonSegSocialFolhaFerias>) => response.body);
        }
      }
    ]
  }
};
