import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlNavWizardDefinition, IPlNavWizardEventBeforeChange, IPlNavWizardStep, isObject, KEYCODES, PlAlertService, PlI18nService} from 'pl-comps-angular';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ContabilidadeApuraIvaSaveModalComponent} from '../modals/save/contabilidade.apuraIva.save.modal.component';
import {ContabilidadeApuraIvaService} from '../../../../../services/contabilidade.apuraiva.service';
import {EEntityStateDetailType} from '../../../../../../common/utils/entity.state.utils';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../../docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {EWizardApuramentoIVA, IApuraIvaForms, IApuraIvaModel, TApuraIvaSaveModalResult} from '../contabilidade.apuraIva.module.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonApuraIva, IJsonApuraIvaDefaultFiltro, IJsonExecucaoApuraIva, IJsonPeriodoApuraIva} from '../jsonApuraIva.module.interface';
import {IJsonDiario} from '../../../../../entities/diarios/jsonDiario.entity.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {round} from '../../../../../../common/utils/utils';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {DATA_SOURCE_NAME_DEBITO_CREDITO, EDebitoCredito} from '../../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {IApiQueryResponse} from '../../../../../services/api/api.service.interface';

@Component({
  selector: 'apuraiva',
  templateUrl: './contabilidade.apuraIva.module.component.html'
})
export class ContabilidadeApuraivaComponent extends ModuloComponent implements OnInit {
  @Input() public defaultFiltro: IJsonApuraIvaDefaultFiltro;
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public definition: IPlNavWizardDefinition;
  public diarios: Array<IJsonDiario>;
  public diariosPossiveis: Array<IJsonDiario>;
  public error: string;
  public forms: IApuraIvaForms;
  public model: IApuraIvaModel;
  public outputDiarios: string;
  public outputPeriodo: string;
  public periodos: Array<IJsonPeriodoApuraIva>;
  public selectedStep: IPlNavWizardStep;

  private _errorValue: string;
  private _diariosService: IEntityService<IJsonDiario>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _plI18nService: PlI18nService,
    private readonly _apuraIvaService: ContabilidadeApuraIvaService,
    private readonly _cgModalService: CGModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'docscontabilidade.doc.linhas.nConta'},
        {dataField: 'poc.nome', dataType: 'string', caption: 'docscontabilidade.doc.linhas.poc.nome', width: 150},
        {dataField: 'dc', dataType: 'string', caption: 'docscontabilidade.doc.linhas.dc', lookup: {cgDataSource: DATA_SOURCE_NAME_DEBITO_CREDITO}},
        {dataField: 'valor', dataType: 'double', caption: 'docscontabilidade.doc.linhas.valor'},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'docscontabilidade.doc.linhas.nDocExterno'},
        {dataField: 'nContrib', dataType: 'string', caption: 'docscontabilidade.doc.linhas.nContrib'},
        {dataField: 'descricao', dataType: 'string', caption: 'docscontabilidade.doc.linhas.descricao'},
        {dataField: 'dataVencimento', dataType: 'date', caption: 'docscontabilidade.doc.linhas.dataVencimento'},
        {dataField: 'codIva', dataType: 'string', caption: 'docscontabilidade.doc.linhas.codIva'},
        {dataField: 'valorTaxa', dataType: 'double', caption: 'docscontabilidade.doc.linhas.valorTaxa'},
        {dataField: 'dataDocExt', dataType: 'date', caption: 'docscontabilidade.doc.linhas.dataDocExt'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'docscontabilidade.doc.linhas.dataDoc'},
        {dataField: 'cDecPer', dataType: 'string', caption: 'docscontabilidade.doc.linhas.cDecPer'},
        {dataField: 'cDecAnual', dataType: 'string', caption: 'docscontabilidade.doc.linhas.cDecAnual'}
      ],
      dataSource: [],
      export: {filename: this._state.data.pageTitle},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.defaultFiltro) {
      this.defaultFiltro = {
        descricao: undefined,
        nDescritivo: undefined,
        nDiario: undefined,
        nomeDescritivo: undefined,
        nomeDiario: undefined
      };
    }
    this._diariosService = this._entityServiceBuilder.build<IJsonDiario>('diarios');
    this._errorValue = undefined;
    this.outputDiarios = '{{nDiario}} - {{nome}}';
    this.outputPeriodo = '{{periodo}} - {{nome}}';
    this.periodos = [];
    this.forms = {step1: undefined, step2: undefined, step3: undefined};
    this.diarios = [];
    this.definition = {
      items: []
    };
    this.model = {
      ivaAPagar: 0,
      ivaARecuperar: 0,
      ivaAReembolsar: 0,
      ivaApurado: 0,
      ivaDedutivel: 0,
      ivaLiquidado: 0,
      ivaRegFavEmpresa: 0,
      ivaRegFavEstado: 0,
      ivaRegCalculoProRata: 0,
      ivaVariacoesProRata: 0,
      ivaRegOutrasAnuais: 0,
      ivaRegComunicadasPeloSIVA: 0,
      saldoAnterApuramento: 0,
      saldoAnterIVAAPagar: 0,
      saldoAnterIVAARecuperar: 0,
      saldoAnterIVAAReembolsar: 0,
      aFavorDaEmpresa: undefined,
      descricao: this.defaultFiltro.descricao || undefined,
      descritivo: this.defaultFiltro.nDescritivo || undefined,
      estado: undefined,
      linhas: [],
      nDiario: this.defaultFiltro.nDiario || undefined,
      nDocCriado: undefined,
      nPeriodo: undefined,
      proximoPeriodo: undefined,
      nomeDescritivo: this.defaultFiltro.nomeDescritivo || undefined,
      nomeDiario: this.defaultFiltro.nomeDiario || undefined
    };
    this._getPeriodos();
  }

  public changedPeriodo(value: string | IJsonPeriodoApuraIva): void {
    this.model.nPeriodo = isObject(value) ? (<IJsonPeriodoApuraIva>value).periodo : <string>value;
  }

  public changedValue(): void {
    if (this.selectedStep === this.definition.items[1]) {
      const value: number = round(
        this.model.ivaARecuperar + this.model.ivaAReembolsar - this.model.ivaAPagar - (this.model.saldoAnterIVAARecuperar + this.model.ivaApurado),
        this._configService.configurations.contabilidade.decimais.valor
      );
      this.error = value < 0 ? 'apuraiva.errorShortValue' : (this.error = value > 0 ? 'apuraiva.errorExtraValue' : undefined);
      this._errorValue = this._plI18nService.formatCurrency(Math.abs(value));
    } else {
      this.error = undefined;
    }
  }

  public getTranslatedError(): string {
    return this._translateService.instant(this.error, {value: this._errorValue});
  }

  public changedProximoPeriodo(value: string | IJsonPeriodoApuraIva): void {
    this.model.proximoPeriodo = isObject(value) ? (<IJsonPeriodoApuraIva>value).periodo : <string>value;
  }

  public fnGetRowPeriodo = (periodo: IJsonPeriodoApuraIva): string => this._getRowPeriodo(periodo);

  public fnBeforeStepChange = (event: IPlNavWizardEventBeforeChange): Promise<void> => this._beforeStepChange(event);

  public fnValidatorStep1 = (): Promise<boolean> => this._validatorStep1();

  public fnValidatorStep2 = (): boolean => this._validatorStep2();

  public fnFinalize = (): Promise<void> => this._finalize();

  public keydownAvancar(value: string, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      const button: HTMLElement = document.querySelector('pl-nav-wizard-step button.action-next-step');
      if (button) {
        setTimeout(() => {
          button.focus();
        });
      }
    }
  }

  private _getPeriodos(): void {
    this._apuraIvaService.periodosApuraIVA().then((response: HttpResponse<IApiQueryResponse<IJsonPeriodoApuraIva>>) => {
      this.periodos = response.body.list;
      this.model.nPeriodo = this._configService.configurations.empresa.periodo;
    });
  }

  private _getRowPeriodo(periodo: IJsonPeriodoApuraIva): string {
    let row = this.outputPeriodo;
    if (periodo.apurado) {
      row += ` (${<string>this._translateService.instant('apuraiva.apurado')})`;
    }
    return row;
  }

  private _beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): Promise<void> {
    if (
      currentStep === this.definition.items[EWizardApuramentoIVA.STEP_APURA_PERIODO] &&
      (type === 'next' || (nextStep && nextStep === this.definition.items[EWizardApuramentoIVA.STEP_FIM_PERIODO]))
    ) {
      return this._initStep3();
    }
    return Promise.resolve();
  }

  private async _promptReplace(): Promise<void> {
    const message = this._translateService.instant('apuraiva.promptAlreadyExistsMessage', {periodo: this.model.nPeriodo});
    await this._cgModalService.showOkCancel('apuraiva.promptAlreadyExistsTitle', message);
    await this._apuraIvaService.delete(this.model.nPeriodo);
  }

  private async _validatorStep1(): Promise<boolean> {
    if (!this.forms.step1.valid || !this.model.nPeriodo) {
      return false;
    }
    if (!this._validatePeriodo()) {
      return false;
    }
    const response: HttpResponse<boolean> = await this._apuraIvaService.jaEfectuouApuramentoNoPeriodo(this.model.nPeriodo);
    if (response.body) {
      await this._promptReplace();
    }
    await this._initStep2();
    return true;
  }

  private _initStep2(): Promise<void> {
    return this._apuraIvaService.getData(this.model.nPeriodo).then((response: HttpResponse<IJsonApuraIva>) => {
      const apuraIva: IJsonApuraIva = response.body;
      this.model.ivaAPagar = apuraIva.ivaAPagar || this.model.ivaAPagar || 0;
      this.model.ivaARecuperar = apuraIva.ivaARecuperar || this.model.ivaARecuperar || 0;
      this.model.ivaAReembolsar = apuraIva.ivaAReembolsar || this.model.ivaAReembolsar || 0;
      this.model.ivaApurado = apuraIva.ivaApurado || this.model.ivaApurado || 0;
      this.model.ivaDedutivel = apuraIva.ivaDedutivel || this.model.ivaDedutivel || 0;
      this.model.ivaLiquidado = apuraIva.ivaLiquidado || this.model.ivaLiquidado || 0;
      this.model.ivaRegFavEmpresa = apuraIva.ivaRegFavEmpresa || this.model.ivaRegFavEmpresa || 0;
      this.model.ivaRegFavEstado = apuraIva.ivaRegFavEstado || this.model.ivaRegFavEstado || 0;
      this.model.ivaRegCalculoProRata = apuraIva.ivaRegCalculoProRata || this.model.ivaRegCalculoProRata || 0;
      this.model.ivaVariacoesProRata = apuraIva.ivaVariacoesProRata || this.model.ivaVariacoesProRata || 0;
      this.model.ivaRegOutrasAnuais = apuraIva.ivaRegOutrasAnuais || this.model.ivaRegOutrasAnuais || 0;
      this.model.ivaRegComunicadasPeloSIVA = apuraIva.ivaRegComunicadasPeloSIVA || this.model.ivaRegComunicadasPeloSIVA || 0;
      this.model.saldoAnterApuramento = apuraIva.saldoAnterApuramento || this.model.saldoAnterApuramento || 0;
      this.model.saldoAnterIVAAPagar = apuraIva.saldoAnterIVAAPagar || this.model.saldoAnterIVAAPagar || 0;
      this.model.saldoAnterIVAARecuperar = apuraIva.saldoAnterIVAARecuperar || this.model.saldoAnterIVAARecuperar || 0;
      this.model.saldoAnterIVAAReembolsar = apuraIva.saldoAnterIVAAReembolsar || this.model.saldoAnterIVAAReembolsar || 0;
      this.model.linhas = apuraIva.linhas || this.model.linhas || [];
      for (const linha of this.model.linhas) {
        if (!linha.dc || linha.dc !== EDebitoCredito.Credito) {
          linha.dc = EDebitoCredito.Debito;
        }
      }
      this.dataGridDefinition.dataSource = this.model.linhas;

      const valorAnterior: number = this.model.saldoAnterIVAARecuperar;
      const valorAtual: number = this.model.ivaARecuperar - this.model.ivaAPagar;
      const saldo: number = valorAnterior + valorAtual;

      // É valor a recuperar
      if (Math.round(saldo) > 0) {
        this.model.ivaAPagar = 0;
        this.model.ivaARecuperar = round(saldo, this._configService.configurations.contabilidade.decimais.valor);
      }
      // É valor a pagar
      else {
        this.model.ivaAPagar = round(Math.abs(saldo), this._configService.configurations.contabilidade.decimais.valor);
        this.model.ivaARecuperar = 0;
      }

      this.error = undefined;
      this._errorValue = undefined;
    });
  }

  private _validatorStep2(): boolean {
    return this.forms.step2.valid && !this.error;
  }

  private _initStep3(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const index: number = this.periodos.findIndex((periodo: IJsonPeriodoApuraIva) => periodo.periodo === this.model.nPeriodo);
      if (index !== -1) {
        this.model.proximoPeriodo = this.periodos[index + 1]?.periodo;
      }
      this._diariosService
        .query()
        .then((responseDiariosPossiveis) => {
          const diariosPossiveis: Array<IJsonDiario> = responseDiariosPossiveis.body.list;
          this._apuraIvaService
            .diariosConfigurados()
            .then((responseDiarioConfigurados) => {
              const diariosEncerrados: Array<IJsonDiario> = responseDiarioConfigurados.body.list;
              this.diarios = [];
              this.diariosPossiveis = diariosPossiveis.filter((diarioPossivel: IJsonDiario) => {
                const diario: IJsonDiario = diariosEncerrados.find((diarioEncerrado: IJsonDiario) => {
                  return diarioPossivel.nDiario === diarioEncerrado.nDiario;
                });
                if (diario) {
                  this.diarios.push(diario);
                }
                return !diario;
              });
              resolve();
            })
            .catch((errorDiariosEncerrados: unknown) => {
              this.diariosPossiveis = diariosPossiveis;
              reject(errorDiariosEncerrados);
            });
        })
        .catch((errorDiariosPossiveis: unknown) => {
          reject(errorDiariosPossiveis);
        });
    });
  }

  private _finalize(): Promise<void> {
    return this._apuraIvaService.verificarExisteDocumentosPorIntegrar(this.model.nPeriodo).then((hasDocsToInteg: HttpResponse<boolean>) => {
      const modalInstance = this._cgModalService.showVanilla(ContabilidadeApuraIvaSaveModalComponent, {size: 'lg'});
      const componentInstance: ContabilidadeApuraIvaSaveModalComponent = modalInstance.componentInstance;
      componentInstance.message = hasDocsToInteg.body ? 'apuraiva.docsporintegrar' : '';
      componentInstance.cssMessage = hasDocsToInteg.body ? 'fw-bold text-danger' : '';
      return modalInstance.result.then((result: TApuraIvaSaveModalResult) => {
        return this._apuraIvaService.novoApuramento(this.model.nPeriodo, this.model, this.diarios).then((response: HttpResponse<IJsonExecucaoApuraIva>) => {
          return this._configService
            .refresh()
            .then(() => undefined)
            .catch((reason: unknown) => {
              this._logger.error(reason);
            })
            .finally(() => {
              let promise: Promise<void>;
              if (result !== 'doc') {
                promise = this._stateService.reload().then(() => undefined);
              } else {
                promise = this._cgStateService.redirectToState({
                  stateOrName: ENTITY_NAME_DOCS_CONTABILIDADE,
                  stateType: EEntityStateDetailType.EDIT,
                  params: {
                    id: response.body.extPocCabID,
                    returnState: this._uiRouterGlobals.current.name,
                    returnStateParams: this._transition.params()
                  }
                });
              }
              this._plAlertService.success('apuraiva.success');
              return promise.catch((reason: unknown) => {
                this._logger.error(reason);
              });
            });
        });
      });
    });
  }

  private _validatePeriodo(): boolean {
    const periodoExists: boolean = this.periodos.some((periodo: IJsonPeriodoApuraIva) => periodo.periodo === this.model.nPeriodo);
    if (!periodoExists) {
      const message: string = this._translateService.instant(this.configurations.empresa.periodoIvaTrimestral ? 'apuraiva.errorInvalidPeriodoTrimestral' : 'apuraiva.errorInvalidPeriodoMensal', {
        periodo: this.model.nPeriodo
      });
      this._plAlertService.error(message);
      return false;
    }
    return true;
  }
}
