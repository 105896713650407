import type {Subscription} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {IPlCompsServiceConfig, IPlCompsServiceConfigToolbar} from '../common/interface';
import type {IPlToolbarConfig, IPlToolbarInstance} from './toolbar.interface';
import {isArray, isEmpty, isObject, isString} from '../common/utilities/utilities';
import {PlCompsService} from '../common/service/comps.service';
import {PlToolbarInstance} from './toolbar.instance';

@Injectable({
  providedIn: 'root'
})
export class PlToolbarService implements OnDestroy {
  private readonly _instances: Map<string, IPlToolbarInstance>;
  private readonly _subscriptionConfig: Subscription;
  private _configs: IPlCompsServiceConfigToolbar;

  constructor(private readonly _plCompsService: PlCompsService) {
    this._instances = new Map<string, IPlToolbarInstance>();
    this._subscriptionConfig = this._plCompsService.config().subscribe((config: IPlCompsServiceConfig) => {
      this._configs = config.plToolbar;
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionConfig.unsubscribe();
    this._instances.forEach((instance: IPlToolbarInstance) => {
      instance.cleanup();
    });
  }

  public registerInstance(instanceId: string = this._configs.defaultInstanceId, config?: IPlToolbarConfig): IPlToolbarInstance {
    return this._registerInstance(instanceId, config);
  }

  public unRegisterInstance(instanceOrId: string | IPlToolbarInstance): boolean {
    const instanceId = isString(instanceOrId) ? instanceOrId : instanceOrId.instanceId;
    if (this.isRegistered(instanceId)) {
      const instance: IPlToolbarInstance = this._instances.get(instanceId);
      instance.cleanup();
      this._instances.delete(instanceId);
      return true;
    }
    return false;
  }

  public getInstance(instanceId: string = this._configs.defaultInstanceId, config?: IPlToolbarConfig): IPlToolbarInstance {
    if (!this.isRegistered(instanceId)) {
      return this._registerInstance(instanceId, config);
    }
    const instance: IPlToolbarInstance = this._instances.get(instanceId);
    if (isObject(config)) {
      if (isArray(config.items)) {
        instance.setItems(config.items);
      }
      if (!isEmpty(config.toolbarClass)) {
        instance.config.toolbarClass = config.toolbarClass;
      }
      if (isObject(config.search)) {
        Object.assign(instance.config.search, config.search);
      }
    }
    return instance;
  }

  public isRegistered(instanceId: string): boolean {
    return this._instances.has(instanceId);
  }

  private _registerInstance(instanceId: string = this._configs.defaultInstanceId, config?: IPlToolbarConfig): IPlToolbarInstance {
    const newInstance: IPlToolbarInstance = new PlToolbarInstance(instanceId, config);
    this._instances.set(instanceId, newInstance);
    return newInstance;
  }
}
