import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonAtivosVenda, IJsonAtivoVenda} from './jsonAtivosVenda.module.interface';
import {IJsonAtivos} from '../../../entities/ativos/interfaces/jsonAtivos.entity.interface';
import {IJsonAquisicaoPesquisaContab} from '../ativosaquisicaocontab/jsonAtivosAquisicaoContab.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AtivosVendaService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/ativosvenda`;
  }

  public getAtivosPesquisaContab(): TServiceResponse<Array<IJsonAquisicaoPesquisaContab>> {
    return this._apiService.get<Array<IJsonAquisicaoPesquisaContab>>({
      url: `${this._path}/ativospesquisacontab`
    });
  }

  public getAtivosPorVender(): TServiceResponse<Array<IJsonAtivoVenda>> {
    return this._apiService.get<Array<IJsonAtivoVenda>>({
      url: `${this._path}/ativosporvender`
    });
  }

  public postDadosparaformvenda(doc: IJsonAquisicaoPesquisaContab, codativo: number = 0): TServiceResponse<IJsonAtivosVenda> {
    return this._apiService.post<IJsonAtivosVenda, IJsonAquisicaoPesquisaContab>({
      url: `${this._path}/dadosparaformvenda`,
      body: doc,
      params: {codativo: codativo}
    });
  }

  public postRegistaVenda(venda: IJsonAtivosVenda): TServiceResponse<IJsonAtivos> {
    return this._apiService.post<IJsonAtivos, IJsonAtivosVenda>({
      url: `${this._path}/registavenda`,
      body: venda
    });
  }
}
