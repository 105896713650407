import {Directive, Input, TemplateRef} from '@angular/core';
import {ICGTableTemplateContext, TPlTableAlign} from './table.interface';

@Directive({
  selector: 'ng-template[headerActions]',
  standalone: false
})
export class PlTableHeaderActionsDirective {
  private _actionsAlign: TPlTableAlign;

  constructor(public readonly templateRef: TemplateRef<ICGTableTemplateContext>) {}

  public get actionsAlign(): TPlTableAlign {
    return this._actionsAlign;
  }

  @Input()
  public set headerActionsAlign(value: TPlTableAlign) {
    if (value === 'left' || value === 'right') {
      this._actionsAlign = value;
    }
  }
}
