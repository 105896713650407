import {Component, Input} from '@angular/core';
import {CGModalComponent} from '../cgmodal.component';

@Component({
  selector: 'cg-pdf-modal',
  templateUrl: './cg.pdf.modal.component.html'
})
export class CGPdfModalComponent extends CGModalComponent<void> {
  @Input() public title: string;
  @Input() public pdfUrl: string;
}
