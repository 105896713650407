import {PlTranslateService} from 'pl-comps-angular';
import {DATA_SOURCE_TIPOS_POC} from '../../datasources/tipospoc/tiposPoc.datasource';
import {ENTITY_NAME_POCS, IPOCSEntity} from './pocs.entity.interface';
import {ETipoContaContabilidade} from '../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IDataSourceItem} from '../../components/datasource/datasources.interface';
import {IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonCopyPOCsEmpErroInfo, IJsonCopyPOCsEmpresas, IJsonPOC, IJsonPOCCopy} from './jsonPOC.entity.interface';
import {PocsEditComponent} from './component/edit/pocs.entity.edit.component';
import {PocsListComponent} from './component/list/pocs.entity.list.component';
import {ROLE} from '../../services/role.const';
import {TInjectorResolvable} from '../../../common/injectors/common';
import {ENTITY_NAME_QIVAAS} from '../qivaas/qivaas.entity.interface';
import {ENTITY_NAME_QIVAPS} from '../qivaps/qivaps.entity.interface';
import {ENTITY_NAME_TAXAS_RETENCAO} from '../taxasretencao/taxasRetencao.entity.interface';

const TIPOS_POC: ReadonlyArray<IDataSourceItem<ETipoContaContabilidade>> = DATA_SOURCE_TIPOS_POC.data;

export const ENTITY_POCS: TInjectorResolvable = [
  PlTranslateService,
  (plTranslateService: PlTranslateService): IPOCSEntity => {
    return {
      name: ENTITY_NAME_POCS,
      roles: [ROLE.CONTABILIDADE, ROLE.ERP, ROLE.PCA, ROLE.ATIVOS, ROLE.RH],
      searchPlaceholder: 'pocs.pesquisa',
      pageTitle: 'global.menu.pocs',
      metadata: {
        keyName: 'nConta',
        fields: [
          {name: 'nConta', width: '300px', caption: 'pocs.fields.nConta', placeholder: 'pocs.fields.nContaPlaceholder', validators: {required: true, maxlength: 75}},
          {name: 'nome', caption: 'pocs.fields.nome', placeholder: 'pocs.fields.nomePlaceholder', validators: {required: true, maxlength: 100}},
          {
            name: 'nif',
            caption: 'pocs.fields.nif',
            entity: {name: 'nifs', keyTarget: 'nif', output: 'nContribuinte', outputKey: 'nContribuinte', outputTarget: 'nif', fieldsMap: {nContribuinte: 'nif'}},
            visible: (form) => form.tipo === ETipoContaContabilidade.Movimento
          },
          {
            name: 'tipo',
            type: 'radiogroup',
            caption: 'pocs.fields.tipo',
            value: ETipoContaContabilidade.Movimento,
            validators: {required: true},
            properties: {
              groupItems: TIPOS_POC,
              devExpress: {
                dataGrid: {
                  customizeText: (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => {
                    if (cellInfo.target === 'row') {
                      const tipo = TIPOS_POC[<number>cellInfo.value];
                      if (tipo) {
                        return plTranslateService.translate(tipo.label);
                      }
                    }
                    return cellInfo.valueText;
                  }
                }
              }
            }
          },
          {name: 'cc', type: 'boolean', caption: 'pocs.fields.cc', visible: (form) => form.tipo === ETipoContaContabilidade.Movimento},
          {name: 'temCCusto', type: 'boolean', caption: 'pocs.fields.temCCusto', visible: (form) => form.tipo === ETipoContaContabilidade.Movimento},
          {name: 'temIVA', type: 'boolean', caption: 'pocs.fields.temIVA', visible: (form) => form.tipo === ETipoContaContabilidade.Movimento},
          {name: 'registaRetencao', type: 'boolean', caption: 'pocs.fields.registaRetencao', visible: (form) => form.tipo === ETipoContaContabilidade.Movimento},
          {name: 'temRetencao', type: 'boolean', caption: 'pocs.fields.temRetencao', visible: (form) => form.tipo === ETipoContaContabilidade.Movimento},
          {name: 'bloqueada', type: 'boolean', caption: 'pocs.fields.bloqueada'},
          {name: 'codControloIVADebito', visible: false},
          {
            name: 'codControloIVADebitoNome',
            caption: 'pocs.fields.codControloIVADebito',
            entity: {
              name: 'controloIVA',
              keyTarget: 'codControloIVADebito',
              outputKey: 'codControloIVADebito',
              output: '{{codControloIVACredito}} - {{codControloIVACreditoNome}}',
              outputTarget: 'codControloIVADebitoNome',
              outputDescription: '{{codControloIVA}} - {{descAbreviada}}'
            },
            disabled: (form) => form.tipo === ETipoContaContabilidade.Movimento && form.temIVA
          },
          {name: 'codControloIVACredito', visible: false},
          {
            name: 'codControloIVACreditoNome',
            caption: 'pocs.fields.codControloIVACredito',
            entity: {
              name: 'controloIVA',
              keyTarget: 'codControloIVACredito',
              outputTarget: 'codControloIVACreditoNome',
              output: '{{codControloIVACredito}} - {{codControloIVACreditoNome}}',
              outputDescription: '{{codControloIVA}} - {{descAbreviada}}'
            },
            disabled: (form) => form.tipo === ETipoContaContabilidade.Movimento && form.temIVA
          },
          {
            name: 'dpq06',
            caption: 'pocs.list.dpq06',
            properties: {
              devExpress: {
                dataGrid: {
                  calculateCellValue: (rowData: IJsonPOC) => rowData.dpq06NCampo
                }
              }
            }
          },
          {name: 'dpq06NCampo', visible: false},
          {
            name: 'dpq06NCampoNome',
            caption: 'pocs.fields.dpq06NCampo',
            entity: {
              name: ENTITY_NAME_QIVAPS,
              keyTarget: 'dpq06NCampo',
              outputTarget: 'dpq06NCampoNome',
              output: '{{dpq06NCampo}} - {{dpq06NCampoNome}}',
              outputDescription: '{{nCampo}} - {{nomeCampo}} | Campo Oficial: {{nCampoOficial}}',
              outputKey: 'nCampo'
            },
            disabled: (form) => form.tipo === ETipoContaContabilidade.Movimento && !form.temIVA
          },
          {
            name: 'daqAnual',
            caption: 'pocs.list.daqAnual',
            properties: {
              devExpress: {
                dataGrid: {
                  calculateCellValue: (rowData: IJsonPOC) => (rowData.daq08NCampo ? rowData.daq08NCampo : rowData.daq09NCampo)
                }
              }
            }
          },
          {name: 'daq08NCampo', visible: false},
          {
            name: 'daq08NCampoNome',
            caption: 'pocs.fields.daq08NCampo',
            entity: {
              name: ENTITY_NAME_QIVAAS,
              keyTarget: 'daq08NCampo',
              outputTarget: 'daq08NCampoNome',
              output: '{{daq08NCampo}} - {{daq08NCampoNome}}',
              outputDescription: '{{nCampo}} - {{nQuadro}} - {{nome}}',
              outputKey: 'nCampo'
            },
            disabled: (form) => form.tipo === ETipoContaContabilidade.Movimento && !form.temIVA
          },
          {name: 'daq09NCampo', visible: false},
          {
            name: 'daq09NCampoNome',
            caption: 'pocs.fields.daq09NCampo',
            entity: {
              name: ENTITY_NAME_QIVAAS,
              keyTarget: 'daq09NCampo',
              outputTarget: 'daq09NCampoNome',
              output: '{{daq09NCampo}} - {{daq09NCampoNome}}',
              outputDescription: '{{nCampo}} - {{nQuadro}} - {{nome}}',
              outputKey: 'nCampo'
            },
            disabled: (form) => form.tipo === ETipoContaContabilidade.Movimento && !form.temIVA
          },
          {name: 'codRet', visible: false},
          {
            name: 'nomeRet',
            caption: 'taxasretencao.fields.codRet',
            entity: {name: ENTITY_NAME_TAXAS_RETENCAO, keyTarget: 'codRet', outputTarget: 'nomeRet'},
            visible: (form) => Boolean(form.cc) && Boolean(form.nif)
          }
        ],
        order: 'nConta',
        filterField: 'nConta',
        listFields: 'nConta,nome,tipo,dpq06,daqAnual,cc,temIVA',
        searchFields: 'nConta,nome,tipo,nif,cc,temIVA,temRetencao,registaRetencao',
        detailFields:
          'nConta,nome,tipo,nif,bloqueada,cc,temCCusto,temIVA,temRetencao,registaRetencao,dpq06NCampoNome,daq08NCampoNome,daq09NCampoNome,codControloIVADebitoNome,' +
          'codControloIVACreditoNome,nomeRet',
        newFields:
          'nConta,nome,tipo,nif,bloqueada,cc,temCCusto,temIVA,temRetencao,registaRetencao,dpq06NCampoNome,daq08NCampoNome,daq09NCampoNome,codControloIVADebitoNome,' +
          'codControloIVACreditoNome,nomeRet',
        editFields:
          'nConta,nome,tipo,nif,bloqueada,cc,temCCusto,temIVA,temRetencao,registaRetencao,dpq06NCampoNome,daq08NCampoNome,daq09NCampoNome,codControloIVADebitoNome,' +
          'codControloIVACreditoNome,nomeRet'
      },
      autocomplete: {
        rowTemplate: '{{nConta}} - {{nome}} <strong>{{nif}}</strong>',
        output: '{{nConta}} - {{nome}}',
        outputDescription: 'nome',
        searchFields: 'nConta,nome,nif',
        cacheValues: true
      },
      actions: {
        new: true,
        detail: true,
        edit: true,
        delete: true,
        search: true,
        filter: true
      },
      list: {
        state: {
          component: PocsListComponent,
          data: {
            pageTitle: 'global.menu.pocs'
          }
        }
      },
      detail: {
        state: {
          component: PocsEditComponent,
          params: {
            originalNConta: {
              type: 'string',
              value: '',
              dynamic: true
            }
          },
          data: {
            templateDefault: {
              items: [
                {type: 'field', field: 'nConta', order: 1},
                {type: 'field', field: 'nome', order: 2},
                {type: 'field', field: 'tipo', order: 3},
                {type: 'group', order: 4, fields: [{field: 'bloqueada'}, {field: 'cc'}]},
                {type: 'group', order: 5, fields: [{field: 'temCCusto'}, {field: 'temIVA'}]},
                {type: 'group', order: 6, fields: [{field: 'temRetencao'}, {field: 'registaRetencao'}]},
                {type: 'field', field: 'nif', order: 7},
                {type: 'field', field: 'codControloIVADebitoNome', order: 8},
                {type: 'field', field: 'codControloIVACreditoNome', order: 9},
                {type: 'field', field: 'dpq06NCampoNome', order: 10},
                {type: 'field', field: 'daq08NCampoNome', order: 11},
                {type: 'field', field: 'daq09NCampoNome', order: 12},
                {type: 'field', field: 'nomeRet', order: 13}
              ]
            }
          }
        }
      },
      service: function () {
        this.getPocsCopyList = (nContaDe: string, nContaAte: string) => {
          return this.get<Array<IJsonPOCCopy>>({id: 'listacontas', params: {ncontade: nContaDe, ncontaate: nContaAte}});
        };

        this.postPocsCopyList = (pocsCopyEmpresa: IJsonCopyPOCsEmpresas) => {
          return this.post<Array<IJsonCopyPOCsEmpErroInfo>, IJsonCopyPOCsEmpresas>({url: 'listacontas', body: pocsCopyEmpresa});
        };
      }
    };
  }
];
