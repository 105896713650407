<div class="impfferpoc entity-detail-form">
  <cg-card-panel>
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <pl-group>
          <pl-group>
            <label [translate]="'global.text.listing'"></label>
            <edit>
              <pl-edit type="reports_imp_fferpoc" attrName="listagem" [(model)]="report" plAutoFocus></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'impfferpoc.fields.dataProcessamento'"></label>
            <edit>
              <pl-autocomplete
                [source]="datasProcessamentos"
                [model]="dataProcessamento"
                (modelChange)="changeDataProcessamento($event)"
                [properties]="{events: {keydown: fnKeydownProcessar}}"></pl-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <hr />

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
