import {AssistConfigPortaisEmpregadoModuleComponent} from './components/assistConfigPortais.empregado.module.component';
import {ENTITY_NAME_ERPS} from '../../../../../../entities/erps/erps.entity.interface';
import {EntityServiceBuilder} from '../../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../../services/entity/entity.service.interface';
import {IJsonErp} from '../../../../../../interfaces/jsonUserManager.interface';
import {IModuleDefinition} from '../../../../../../components/module/module.definition.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_EMPREGADO} from './assistConfigPortais.empregado.module.interface';
import {ROLE} from '../../../../../../services/role.const';
import {THttpQueryResponse} from '../../../../../../services/api/api.service.interface';

export const MODULE_ASSISTENTE_CONFIG_PORTAIS_EMPREGADO: IModuleDefinition = {
  name: MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_EMPREGADO,
  state: {
    url: '/assistenteconfigportais/portalclientaccounts/empregado',
    component: AssistConfigPortaisEmpregadoModuleComponent,
    data: {
      roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
      pageTitle: 'assistenteconfigportais.pages.empregado'
    },
    resolve: [
      {
        provide: 'erps',
        deps: [EntityServiceBuilder],
        useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonErp>> => {
          const erps: IEntityService<IJsonErp> = entityServiceBuilder.build<IJsonErp>(ENTITY_NAME_ERPS);
          return erps.query().then((response: THttpQueryResponse<IJsonErp>) => response.body.list);
        }
      }
    ]
  }
};
