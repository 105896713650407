import {IJsonPRHComIntCab, IJsonPRHComIntLin} from '../../../entities/prhcomunicacoesinternas/jsonPRHComunicacaoInterna.entity.interface';
import {IJsonPRHServico} from '../../../entities/prhservicos/jsonPRHServico.entity.interface';
import {TDevExpressFilterExpression} from '../../../components/devexpress/datalayer/filtering/devexpress.datalayer.filtering.interface';

export enum EComunicacaoInternaSystem {
  COMUNICACAO_INTERNA = 1,
  RECRUTAMENTO_INTERNO,
  DECLARACOES,
  RECLAMACOES
}

export interface IComunicacaoInterna extends IJsonPRHComIntCab {
  url?: string;
  linhas?: Array<IComunicacaoInternaLinha>;
  servico?: IJsonPRHServico;
  filterValue?: TDevExpressFilterExpression;
}

export interface IComunicacaoInternaLinha extends IJsonPRHComIntLin {
  nomeEmp: string;
}
