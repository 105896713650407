import {Injectable, Injector, OnDestroy} from '@angular/core';
import {JSONSchema} from '@ngx-pwa/local-storage';
import {ConfigOptionsInstance} from './config.options.instance';
import {
  EConfigOptionsInstanceName,
  IAdminPortaisConfigOptions,
  IArtigosConfigOptions,
  IArtigosListConfigOptions,
  IAvisosCobrancaConfigOptions,
  IBancosExtratoConfigOptions,
  IClientesListConfigOptions,
  IComercialInventariosConfigOptions,
  IConciliacaoBancariaConfigOptions,
  IConfigOptionBoolean,
  IConfigOptionsGroupAdministracao,
  IConfigOptionsGroupAtivos,
  IConfigOptionsGroupColaborador,
  IConfigOptionsGroupContabilidade,
  IConfigOptionsGroupErp,
  IConfigOptionsGroupGestorEmentas,
  IConfigOptionsGroupGestorRh,
  IConfigOptionsGroupGestorServico,
  IConfigOptionsGroupPca,
  IContabDigitalDocumentosDigitaisConfigOptions,
  IContratosConfigOptions,
  IContratosListConfigOptions,
  IContratosPrintConfigOptions,
  IDocContabilidadeConfigOptions,
  IDocsComerciaisEditConfigOptions,
  IDocsComerciaisEstatisticasConfigOptions,
  IDocsComerciaisEstatisticasDocConfigOptions,
  IDocsComerciaisListConfigOptions,
  IEncomendasConfigOptions,
  IExtratosDeContabilidadeConfigOptions,
  IExtratosDTConfigOptions,
  IFamiliasConfigOptions,
  IFaturacaoContratosConfigOptions,
  IFaturacaoEletronicaConfiguracoesConfigOptions,
  IFichaClientesConfigOptions,
  IFornecedoresListConfigOptions,
  IIdadeSaldosConfigOptions,
  ILotesListConfigOptions,
  IMovimentosAbertosConfigOptions,
  IRetencoesConfigOptions,
  schemaAdminPortaisConfigOptions,
  schemaArtigosConfigOptions,
  schemaArtigosListConfigOptions,
  schemaAvisosCobrancaConfigOptions,
  schemaBancosExtratoConfigOptions,
  schemaClientesListConfigOptions,
  schemaComercialInventariosConfigOptions,
  schemaConciliacaoBancariaConfigOptions,
  schemaContabDigitalDocumentosDigitaisConfigOptions,
  schemaContratosConfigOptions,
  schemaContratosListConfigOptions,
  schemaContratosPrintConfigOptions,
  schemaDocContabilidadeConfigOptions,
  schemaDocsComerciaisEditConfigOptions,
  schemaDocsComerciaisEstatisticasConfigOptions,
  schemaDocsComerciaisEstatisticasDocConfigOptions,
  schemaDocsComerciaisListConfigOptions,
  schemaEncomendasConfigOptions,
  schemaExtratosDeContabilidadeConfigOptions,
  schemaExtratosDTConfigOptions,
  schemaFamiliasConfigOptions,
  schemaFaturacaoContratosConfigOptions,
  schemaFaturacaoEletronicaConfiguracoesConfigOptions,
  schemaFichaClientesConfigOptions,
  schemaFornecedoresListConfigOptions,
  schemaIdadeSaldosConfigOptions,
  schemaLotesListConfigOptions,
  schemaMovimentosAbertosConfigOptions,
  schemaMovimentosPendentesEditConfigOptions,
  schemaRetencoesConfigOptions,
  TConfigOptions
} from './config.options.service.interface';
import {EGroupName} from '../../../../config/constants';
import {IConfigOptionsInstance} from './config.options.instance.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigOptionsService implements OnDestroy {
  private readonly _optionsErp: ReadonlyMap<keyof IConfigOptionsGroupErp, IConfigOptionsInstance<boolean>>;
  private readonly _optionsPca: ReadonlyMap<keyof IConfigOptionsGroupPca, IConfigOptionsInstance<boolean>>;
  private readonly _optionsColaborador: ReadonlyMap<keyof IConfigOptionsGroupColaborador, IConfigOptionsInstance<boolean>>;
  private readonly _optionsGestorEmentas: ReadonlyMap<keyof IConfigOptionsGroupGestorEmentas, IConfigOptionsInstance<boolean>>;
  private readonly _optionsGestorRh: ReadonlyMap<keyof IConfigOptionsGroupGestorRh, IConfigOptionsInstance<boolean>>;
  private readonly _optionsGestorServico: ReadonlyMap<keyof IConfigOptionsGroupGestorServico, IConfigOptionsInstance<boolean>>;
  private readonly _optionsContabilidade: ReadonlyMap<keyof IConfigOptionsGroupContabilidade, IConfigOptionsInstance<boolean>>;
  private readonly _optionsAtivos: ReadonlyMap<keyof IConfigOptionsGroupAtivos, IConfigOptionsInstance<boolean>>;
  private readonly _optionsAdministracao: ReadonlyMap<keyof IConfigOptionsGroupAdministracao, IConfigOptionsInstance<boolean>>;

  constructor(private readonly _injector: Injector) {
    this._optionsErp = new Map<keyof IConfigOptionsGroupErp, IConfigOptionsInstance<boolean>>([
      [
        EConfigOptionsInstanceName.DOCS_COMERCIAIS_EDIT,
        this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.DOCS_COMERCIAIS_EDIT, schemaDocsComerciaisEditConfigOptions, this._defaultDocsComercialEdit())
      ],
      [
        EConfigOptionsInstanceName.DOCS_COMERCIAIS_ESTATISTICAS,
        this._buildOptionsInstance<boolean>(
          EGroupName.ERP,
          EConfigOptionsInstanceName.DOCS_COMERCIAIS_ESTATISTICAS,
          schemaDocsComerciaisEstatisticasConfigOptions,
          this._defaultDocsComercialEstatisticas()
        )
      ],
      [
        EConfigOptionsInstanceName.DOCS_COMERCIAIS_ESTATISTICAS_DOC,
        this._buildOptionsInstance<boolean>(
          EGroupName.ERP,
          EConfigOptionsInstanceName.DOCS_COMERCIAIS_ESTATISTICAS_DOC,
          schemaDocsComerciaisEstatisticasDocConfigOptions,
          this._defaultDocsComercialEstatisticasDoc()
        )
      ],
      [
        EConfigOptionsInstanceName.MOVIMENTOS_PENDENTES,
        this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.MOVIMENTOS_PENDENTES, schemaMovimentosPendentesEditConfigOptions, this._defaultMovimentosPendentesEdit())
      ],
      [EConfigOptionsInstanceName.FAMILIAS, this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.FAMILIAS, schemaFamiliasConfigOptions, this._defaultFamilias())],
      [EConfigOptionsInstanceName.ARTIGOS, this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.ARTIGOS, schemaArtigosConfigOptions, this._defaultArtigos())],
      [
        EConfigOptionsInstanceName.ARTIGOS_LIST,
        this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.ARTIGOS_LIST, schemaArtigosListConfigOptions, this._defaultArtigosList())
      ],
      [
        EConfigOptionsInstanceName.FATURACAO_CONTRATOS,
        this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.FATURACAO_CONTRATOS, schemaFaturacaoContratosConfigOptions, this._defaultFaturacaoContratos())
      ],
      [EConfigOptionsInstanceName.CONTRATOS, this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.CONTRATOS, schemaContratosConfigOptions, this._defaultContratos())],
      [
        EConfigOptionsInstanceName.CONTRATOS_LIST,
        this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.CONTRATOS_LIST, schemaContratosListConfigOptions, this._defaultContratosList())
      ],
      [
        EConfigOptionsInstanceName.CONTRATOS_PRINT,
        this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.CONTRATOS_PRINT, schemaContratosPrintConfigOptions, this._defaultContratosPrint())
      ],
      [
        EConfigOptionsInstanceName.FATURACAO_ELETRONICA_CONFIGURACOES,
        this._buildOptionsInstance<boolean>(
          EGroupName.ERP,
          EConfigOptionsInstanceName.FATURACAO_ELETRONICA_CONFIGURACOES,
          schemaFaturacaoEletronicaConfiguracoesConfigOptions,
          this._defaultFaturacaoEletronicaConfiguracoes()
        )
      ],
      [
        EConfigOptionsInstanceName.FICHA_CLIENTES,
        this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.FICHA_CLIENTES, schemaFichaClientesConfigOptions, this._defaultFichaClientes())
      ],
      [
        EConfigOptionsInstanceName.CLIENTES_LIST,
        this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.CLIENTES_LIST, schemaClientesListConfigOptions, this._defaultClientesList())
      ],
      [
        EConfigOptionsInstanceName.FORNECEDORES_LIST,
        this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.FORNECEDORES_LIST, schemaFornecedoresListConfigOptions, this._defaultFornecedoresList())
      ],
      [
        EConfigOptionsInstanceName.AVISOSCOBRANCA,
        this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.AVISOSCOBRANCA, schemaAvisosCobrancaConfigOptions, this._defaultAvisosCobranca())
      ],
      [
        EConfigOptionsInstanceName.INVENTARIOS,
        this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.INVENTARIOS, schemaComercialInventariosConfigOptions, this._defaultInventarios())
      ],
      [EConfigOptionsInstanceName.LOTES_LIST, this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.LOTES_LIST, schemaLotesListConfigOptions, this._defaultLotesList())],
      [EConfigOptionsInstanceName.ENCOMENDAS, this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.ENCOMENDAS, schemaEncomendasConfigOptions, this._defaultEncomendas())],
      [
        EConfigOptionsInstanceName.MOVIMENTOS_ABERTOS,
        this._buildOptionsInstance(EGroupName.ERP, EConfigOptionsInstanceName.MOVIMENTOS_ABERTOS, schemaMovimentosAbertosConfigOptions, this._defaultMovimentosAberto())
      ],
      [EConfigOptionsInstanceName.IDADE_SALDOS, this._buildOptionsInstance(EGroupName.ERP, EConfigOptionsInstanceName.IDADE_SALDOS, schemaIdadeSaldosConfigOptions, this._defaultIdadeSaldos())],
      [
        EConfigOptionsInstanceName.DOCS_COMERCIAIS_LIST,
        this._buildOptionsInstance<boolean>(EGroupName.ERP, EConfigOptionsInstanceName.DOCS_COMERCIAIS_LIST, schemaDocsComerciaisListConfigOptions, this._defaultDocsComercialList())
      ]
    ]);
    this._optionsPca = new Map<keyof IConfigOptionsGroupPca, IConfigOptionsInstance<boolean>>();
    this._optionsColaborador = new Map<keyof IConfigOptionsGroupColaborador, IConfigOptionsInstance<boolean>>();
    this._optionsGestorEmentas = new Map<keyof IConfigOptionsGroupGestorEmentas, IConfigOptionsInstance<boolean>>();
    this._optionsGestorRh = new Map<keyof IConfigOptionsGroupGestorRh, IConfigOptionsInstance<boolean>>();
    this._optionsGestorServico = new Map<keyof IConfigOptionsGroupGestorServico, IConfigOptionsInstance<boolean>>();
    this._optionsContabilidade = new Map<keyof IConfigOptionsGroupContabilidade, IConfigOptionsInstance<boolean>>([
      [
        EConfigOptionsInstanceName.DOC_CONTABILIDADE,
        this._buildOptionsInstance<boolean>(EGroupName.CONTABILIDADE, EConfigOptionsInstanceName.DOC_CONTABILIDADE, schemaDocContabilidadeConfigOptions, this._defaultDocContabilidade())
      ],
      [
        EConfigOptionsInstanceName.CONCILIACAO_BANCARIA,
        this._buildOptionsInstance<boolean>(EGroupName.CONTABILIDADE, EConfigOptionsInstanceName.CONCILIACAO_BANCARIA, schemaConciliacaoBancariaConfigOptions, this._defaultConciliacaoBancaria())
      ],
      [
        EConfigOptionsInstanceName.EXTRATOSDT,
        this._buildOptionsInstance<boolean>(EGroupName.CONTABILIDADE, EConfigOptionsInstanceName.EXTRATOSDT, schemaExtratosDTConfigOptions, this._defaultExtratosDT())
      ],
      [
        EConfigOptionsInstanceName.CONTAB_DIGITAL_DOCUMENTOS_DIGITAIS,
        this._buildOptionsInstance<boolean>(
          EGroupName.CONTABILIDADE,
          EConfigOptionsInstanceName.CONTAB_DIGITAL_DOCUMENTOS_DIGITAIS,
          schemaContabDigitalDocumentosDigitaisConfigOptions,
          this._defaultContabDigitalDocumentosDigitais()
        )
      ],
      [
        EConfigOptionsInstanceName.RETENCOES,
        this._buildOptionsInstance<boolean>(EGroupName.CONTABILIDADE, EConfigOptionsInstanceName.RETENCOES, schemaRetencoesConfigOptions, this._defaultRetencoes())
      ],
      [
        EConfigOptionsInstanceName.BANCOS_EXTRATO,
        this._buildOptionsInstance<boolean>(EGroupName.CONTABILIDADE, EConfigOptionsInstanceName.BANCOS_EXTRATO, schemaBancosExtratoConfigOptions, this._defaultBancosExtrato())
      ],
      [
        EConfigOptionsInstanceName.EXTRATOS_CONTABILIDADE,
        this._buildOptionsInstance<boolean>(
          EGroupName.CONTABILIDADE,
          EConfigOptionsInstanceName.EXTRATOS_CONTABILIDADE,
          schemaExtratosDeContabilidadeConfigOptions,
          this._defaultExtratosDeContabilidade()
        )
      ]
    ]);
    this._optionsAtivos = new Map<keyof IConfigOptionsGroupAtivos, IConfigOptionsInstance<boolean>>();
    this._optionsAdministracao = new Map<keyof IConfigOptionsGroupAdministracao, IConfigOptionsInstance<boolean>>([
      [
        EConfigOptionsInstanceName.ADMIN_PORTAIS,
        this._buildOptionsInstance<boolean>(EGroupName.ADMINITRACAO, EConfigOptionsInstanceName.ADMIN_PORTAIS, schemaAdminPortaisConfigOptions, this._defaultAdminPortais())
      ]
    ]);
  }

  public ngOnDestroy(): void {
    this._cleanup();
  }

  public getGroupOptions(groupName: EGroupName): ReadonlyMap<unknown, IConfigOptionsInstance<boolean>> {
    switch (groupName) {
      case EGroupName.ERP:
        return this.getOptionsErp();
      case EGroupName.PCA:
        return this.getOptionsPca();
      case EGroupName.COLABORADOR:
        return this.getOptionsColaborador();
      case EGroupName.GESTOR_EMENTAS:
        return this.getOptionsGestorEmentas();
      case EGroupName.GESTOR_RH:
        return this.getOptionsGestorRh();
      case EGroupName.GESTOR_SERVICO:
        return this.getOptionsServico();
      case EGroupName.CONTABILIDADE:
        return this.getOptionsContabilidade();
      case EGroupName.ADMINITRACAO:
        return this.getOptionsAdministracao();
      default:
        return undefined;
    }
  }

  public getOptionsErp(): ReadonlyMap<keyof IConfigOptionsGroupErp, IConfigOptionsInstance<boolean>> {
    return this._optionsErp;
  }

  public getOptionsPca(): ReadonlyMap<unknown, IConfigOptionsInstance<boolean>> {
    return this._optionsPca;
  }

  public getOptionsColaborador(): ReadonlyMap<unknown, IConfigOptionsInstance<boolean>> {
    return this._optionsColaborador;
  }

  public getOptionsGestorEmentas(): ReadonlyMap<unknown, IConfigOptionsInstance<boolean>> {
    return this._optionsGestorEmentas;
  }

  public getOptionsGestorRh(): ReadonlyMap<unknown, IConfigOptionsInstance<boolean>> {
    return this._optionsGestorRh;
  }

  public getOptionsServico(): ReadonlyMap<unknown, IConfigOptionsInstance<boolean>> {
    return this._optionsGestorServico;
  }

  public getOptionsContabilidade(): ReadonlyMap<keyof IConfigOptionsGroupContabilidade, IConfigOptionsInstance<boolean>> {
    return this._optionsContabilidade;
  }

  public getOptionsAtivos(): ReadonlyMap<keyof IConfigOptionsGroupAtivos, IConfigOptionsInstance<boolean>> {
    return this._optionsAtivos;
  }

  public getOptionsAdministracao(): ReadonlyMap<keyof IConfigOptionsGroupAtivos, IConfigOptionsInstance<boolean>> {
    return this._optionsAdministracao;
  }

  private _buildOptionsInstance<T>(groupName: EGroupName, instanceName: EConfigOptionsInstanceName, schema: JSONSchema, defaultOptions: TConfigOptions<T>): IConfigOptionsInstance<T> {
    return new ConfigOptionsInstance<T>(this._injector, groupName, instanceName, schema, defaultOptions);
  }

  private _cleanup(): void {
    this._cleanupGroup(this._optionsErp);
    this._cleanupGroup(this._optionsPca);
    this._cleanupGroup(this._optionsColaborador);
    this._cleanupGroup(this._optionsGestorEmentas);
    this._cleanupGroup(this._optionsGestorRh);
    this._cleanupGroup(this._optionsGestorServico);
    this._cleanupGroup(this._optionsContabilidade);
    this._cleanupGroup(this._optionsAtivos);
    this._cleanupGroup(this._optionsAdministracao);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _cleanupGroup(group: ReadonlyMap<unknown, IConfigOptionsInstance<any>>): void {
    group.forEach((configOptionsInstance: IConfigOptionsInstance<unknown>) => {
      configOptionsInstance.cleanup();
    });
  }

  private _defaultDocsComercialEdit(): TConfigOptions<boolean, IDocsComerciaisEditConfigOptions> {
    return new Map<keyof IDocsComerciaisEditConfigOptions, IConfigOptionBoolean>([
      ['showObservacoes', {caption: 'docscomerciais.configs.showObservacoes', value: false}],
      ['showDocExterno', {caption: 'docscomerciais.configs.showDocExterno', value: false}],
      ['showDataDoc', {caption: 'docscomerciais.configs.showDataDoc', value: false}],
      ['showDataVenc', {caption: 'docscomerciais.configs.showDataVenc', value: false}],
      ['showDiferimentos', {caption: 'docscomerciais.configs.showDiferimentos', value: false}],
      ['showCCusto', {caption: 'docscomerciais.configs.showCCusto', value: false}],
      ['showMoeda', {caption: 'docscomerciais.configs.showMoeda', value: false}],
      ['showNRefProcesso', {caption: 'docscomerciais.configs.showNRefProcesso', value: false}],
      ['showNArmazem', {caption: 'docscomerciais.configs.showNArmazem', value: false}],
      ['showNVendedor', {caption: 'docscomerciais.configs.showNVendedor', value: false}],
      ['showValorCIva', {caption: 'docscomerciais.configs.showValorCIva', value: false}],
      ['showCodUnidMov', {caption: 'docscomerciais.configs.showCodUnidMov', value: false}],
      ['showNNumer', {caption: 'docscomerciais.configs.showNNumer', value: false}],
      ['showNRequisicao', {caption: 'docscomerciais.configs.showNRequisicao', value: false}],
      ['showLiquido', {caption: 'docscomerciais.configs.showLiquido', value: false}],
      ['showD2', {caption: 'docscomerciais.configs.showD2', value: false}],
      ['showD3', {caption: 'docscomerciais.configs.showD3', value: false}],
      ['showDescEmValor', {caption: 'docscomerciais.configs.showDescEmValor', value: false}],
      ['showPerguntaSeFinalizaDoc', {caption: 'docscomerciais.configs.showPerguntaSeFinalizaDoc', value: true}],
      ['obtemDadosDocDigital', {caption: 'docscomerciais.configs.obtemDadosDocDigital', value: true}]
    ]);
  }

  private _defaultDocsComercialEstatisticas(): TConfigOptions<boolean, IDocsComerciaisEstatisticasConfigOptions> {
    return new Map<keyof IDocsComerciaisEstatisticasConfigOptions, IConfigOptionBoolean>([
      ['showFilterTipoArtigo', {caption: 'docsComerciaisEstatisticas.configs.showFilterTipoArtigo', value: false}],
      ['showFilterGrFamilia', {caption: 'docsComerciaisEstatisticas.configs.showFilterGrFamilia', value: false}],
      ['showFilterFamilia', {caption: 'docsComerciaisEstatisticas.configs.showFilterFamilia', value: false}],
      ['showFilterSubFamilia', {caption: 'docsComerciaisEstatisticas.configs.showFilterSubFamilia', value: false}],
      ['showFilterClasseArtigo', {caption: 'docsComerciaisEstatisticas.configs.showFilterClasseArtigo', value: false}],
      ['showFilterCategoriaArtigo', {caption: 'docsComerciaisEstatisticas.configs.showFilterCategoriaArtigo', value: false}],
      ['showFilterSoComMovimStock', {caption: 'docsComerciaisEstatisticas.configs.showFilterSoComMovimStock', value: false}],
      ['showFilterConta', {caption: 'docsComerciaisEstatisticas.configs.showFilterConta', value: false}],
      ['showFilterVendedor', {caption: 'docsComerciaisEstatisticas.configs.showFilterVendedor', value: false}],
      ['showFilterZona', {caption: 'docsComerciaisEstatisticas.configs.showFilterZona', value: false}],
      ['showFilterCentroCusto', {caption: 'docsComerciaisEstatisticas.configs.showFilterCentroCusto', value: false}],
      ['showFilterProcesso', {caption: 'docsComerciaisEstatisticas.configs.showFilterProcesso', value: false}],
      ['showFilterDocfaList', {caption: 'docsComerciaisEstatisticas.configs.showFilterDocfaList', value: false}],
      ['showFilterGrupoConta', {caption: 'docsComerciaisEstatisticas.configs.showFilterGrupoConta', value: false}],
      ['showFilterClasse', {caption: 'docsComerciaisEstatisticas.configs.showFilterClasse', value: false}]
    ]);
  }

  private _defaultDocsComercialEstatisticasDoc(): TConfigOptions<boolean, IDocsComerciaisEstatisticasDocConfigOptions> {
    return new Map<keyof IDocsComerciaisEstatisticasDocConfigOptions, IConfigOptionBoolean>([
      ['showFilterConta', {caption: 'docsComerciaisEstatisticas.configs.showFilterConta', value: false}],
      ['showFilterVendedor', {caption: 'docsComerciaisEstatisticas.configs.showFilterVendedor', value: false}],
      ['showFilterZona', {caption: 'docsComerciaisEstatisticas.configs.showFilterZona', value: false}],
      ['showFilterCentroCusto', {caption: 'docsComerciaisEstatisticas.configs.showFilterCentroCusto', value: false}],
      ['showFilterProcesso', {caption: 'docsComerciaisEstatisticas.configs.showFilterProcesso', value: false}],
      ['showFilterDocfaList', {caption: 'docsComerciaisEstatisticas.configs.showFilterDocfaList', value: false}],
      ['showFilterNArtigo', {caption: 'docsComerciaisEstatisticas.configs.showFilterNArtigo', value: false}],
      ['showFilterTipoArtigo', {caption: 'docsComerciaisEstatisticas.configs.showFilterTipoArtigo', value: false}],
      ['showFilterGrandeFamilia', {caption: 'docsComerciaisEstatisticas.configs.showFilterGrFamilia', value: false}],
      ['showFilterFamilia', {caption: 'docsComerciaisEstatisticas.configs.showFilterFamilia', value: false}],
      ['showFilterSubFamilia', {caption: 'docsComerciaisEstatisticas.configs.showFilterSubFamilia', value: false}],
      ['showFilterGrupoConta', {caption: 'docsComerciaisEstatisticas.configs.showFilterGrupoConta', value: false}],
      ['showFilterClasse', {caption: 'docsComerciaisEstatisticas.configs.showFilterClasse', value: false}]
    ]);
  }

  private _defaultMovimentosPendentesEdit(): TConfigOptions<boolean, IDocsComerciaisEditConfigOptions> {
    return new Map<keyof IDocsComerciaisEditConfigOptions, IConfigOptionBoolean>([['showNDocExterno', {caption: 'docscomerciais.configs.showDocExterno', value: false}]]);
  }

  private _defaultDocContabilidade(): TConfigOptions<boolean, IDocContabilidadeConfigOptions> {
    return new Map<keyof IDocContabilidadeConfigOptions, IConfigOptionBoolean>([
      ['valorComIvaIncluido', {caption: 'docscontabilidade.configs.valorComIvaIncluido', value: false}],
      ['obtemDadosDocDigital', {caption: 'docscontabilidade.configs.obtemDadosDocDigital', value: true}],
      ['showFieldPredefinido', {caption: 'docscontabilidade.configs.showFieldPredefinido', value: true}],
      ['showFieldDataVencimento', {caption: 'docscontabilidade.configs.showFieldDataVencimento', value: false}],
      ['showFieldMoeda', {caption: 'docscontabilidade.configs.showFieldMoeda', value: false}],
      ['showFieldLinhaNif', {caption: 'docscontabilidade.configs.showFieldLinhaNif', value: false}],
      ['showFieldLinhaValorTaxa', {caption: 'docscontabilidade.configs.showFieldLinhaValorTaxa', value: false}],
      ['showFieldLinhaDataDoc', {caption: 'docscontabilidade.configs.showFieldLinhaDataDoc', value: false}],
      ['showFieldLinhaDescricao', {caption: 'docscontabilidade.configs.showFieldLinhaDescricao', value: false}],
      ['showFieldLinhaCDecPerAnual', {caption: 'docscontabilidade.configs.showFieldLinhaCDecPerAnual', value: false}],
      ['showFieldLinhaNomeConta', {caption: 'docscontabilidade.configs.showFieldLinhaNomeConta', value: false}],
      ['seletorObtemDadosDocDigital', {caption: 'docscontabilidade.configs.seletorObtemDadosDocDigital', value: true}],
      ['seletorValorComIvaIncluido', {caption: 'docscontabilidade.configs.seletorValorComIvaIncluido', value: true}],
      ['skipDescricao', {caption: 'docscontabilidade.configs.skipDescricao', value: false}],
      ['retainDescription', {caption: 'docscontabilidade.configs.retainDescription', value: false}],
      ['showFormMoeda', {caption: 'docscontabilidade.configs.showFormMoeda', value: true}],
      ['skipPromptSave', {caption: 'docscontabilidade.configs.skipPromptSave', value: false}],
      ['docViewerFooterCollapsed', {caption: '', value: false, invisible: true}]
    ]);
  }

  private _defaultConciliacaoBancaria(): TConfigOptions<boolean, IConciliacaoBancariaConfigOptions> {
    return new Map<keyof IConciliacaoBancariaConfigOptions, IConfigOptionBoolean>([
      ['showColumnDescricao', {caption: 'conciliacaoBancaria.config.showColumnDescricao', value: true}],
      ['showColumnNDocExt', {caption: 'conciliacaoBancaria.config.showColumnNDocExt', value: true}],
      ['showColumnTipo', {caption: 'conciliacaoBancaria.config.showColumnTipo', value: false}],
      ['showColumnSaldo', {caption: 'conciliacaoBancaria.config.showColumnSaldo', value: false}],
      ['showColumnStampUpdate', {caption: 'conciliacaoBancaria.config.showColumnStampUpdate', value: false}],
      ['showColumnDataLanc', {caption: 'conciliacaoBancaria.config.showColumnDataLanc', value: false}],
      ['showColumnData', {caption: 'conciliacaoBancaria.config.showColumnData', value: false}],
      ['showColumnValorSemDC', {caption: 'conciliacaoBancaria.config.showColumnValorSemDC', value: false}],
      ['showColumnBancoValorSemDC', {caption: 'conciliacaoBancaria.config.showColumnBancoValorSemDC', value: false}],
      ['showColumnDC', {caption: 'conciliacaoBancaria.config.showColumnDC', value: false}],
      ['showColumnBancoDC', {caption: 'conciliacaoBancaria.config.showColumnBancoDC', value: false}]
    ]);
  }

  private _defaultExtratosDT(): TConfigOptions<boolean, IExtratosDTConfigOptions> {
    return new Map<keyof IExtratosDTConfigOptions, IConfigOptionBoolean>([
      ['showMoeda', {caption: 'extratosDT.config.showMoeda', value: false}],
      ['showDebitoEmDivisa', {caption: 'extratosDT.config.showDebitoEmDivisa', value: false}],
      ['showCreditoEmDivisa', {caption: 'extratosDT.config.showCreditoEmDivisa', value: false}],
      ['showPorPagarEmDivisa', {caption: 'extratosDT.config.showPorPagarEmDivisa', value: false}],
      ['showCCusto', {caption: 'extratosDT.config.showCCusto', value: false}],
      ['showCambioSegMoeda', {caption: 'extratosDT.config.showCambioSegMoeda', value: false}],
      ['showDebitoSegMoeda', {caption: 'extratosDT.config.showDebitoSegMoeda', value: false}],
      ['showCreditoSegMoeda', {caption: 'extratosDT.config.showCreditoSegMoeda', value: false}],
      ['showPorPagar', {caption: 'extratosDT.config.showPorPagar', value: false}],
      ['showDataVencimento', {caption: 'extratosDT.config.showDataVencimento', value: false}]
    ]);
  }

  private _defaultContabDigitalDocumentosDigitais(): TConfigOptions<boolean, IContabDigitalDocumentosDigitaisConfigOptions> {
    return new Map<keyof IContabDigitalDocumentosDigitaisConfigOptions, IConfigOptionBoolean>([['docViewerFooterCollapsed', {caption: '', value: false, invisible: true}]]);
  }

  private _defaultRetencoes(): TConfigOptions<boolean, IRetencoesConfigOptions> {
    return new Map<keyof IRetencoesConfigOptions, IConfigOptionBoolean>([
      ['showColumnIsContaPocOrigemFornecedor', {caption: 'retencoes.config.showColumnIsContaPocOrigemFornecedor', value: true}],
      ['showColumnNContribuinte', {caption: 'retencoes.config.showColumnNContribuinte', value: true}],
      ['showColumnConta', {caption: 'retencoes.config.showColumnConta', value: true}],
      ['showColumnCodRetencao', {caption: 'retencoes.config.showColumnCodRetencao', value: true}],
      ['showColumnTaxaRetUsada', {caption: 'retencoes.config.showColumnTaxaRetUsada', value: true}],
      ['showColumnTipoRendimento', {caption: 'retencoes.config.showColumnTipoRendimento', value: true}],
      ['showColumnPeriodo', {caption: 'retencoes.config.showColumnPeriodo', value: true}],
      ['showColumnNDiario', {caption: 'retencoes.config.showColumnNDiario', value: true}],
      ['showColumnNDocInterno', {caption: 'retencoes.config.showColumnNDocInterno', value: true}],
      ['showColumnDescricaoDC', {caption: 'retencoes.config.showColumnDescricaoDC', value: true}],
      ['showColumnDataDoc', {caption: 'retencoes.config.showColumnDataDoc', value: true}],
      ['showColumnNDocExterno', {caption: 'retencoes.config.showColumnNDocExterno', value: true}],
      ['showColumnValorOrigem', {caption: 'retencoes.config.showColumnValorOrigem', value: true}],
      ['showColumnValorDestino', {caption: 'retencoes.config.showColumnValorDestino', value: true}],
      ['showColumnValorDestDispo', {caption: 'retencoes.config.showColumnValorDestDispo', value: true}]
    ]);
  }

  private _defaultBancosExtrato(): TConfigOptions<boolean, IBancosExtratoConfigOptions> {
    return new Map<keyof IBancosExtratoConfigOptions, IConfigOptionBoolean>([
      ['autoSuggestDoc', {caption: 'bancosextrato.config.autoSuggestDoc', value: true}],
      ['lancarDocsEmSerie', {caption: '', value: false, invisible: true}],
      ['docViewerFooterCollapsed', {caption: '', value: false, invisible: true}]
    ]);
  }

  private _defaultAvisosCobranca(): TConfigOptions<boolean, IAvisosCobrancaConfigOptions> {
    return new Map<keyof IAvisosCobrancaConfigOptions, IConfigOptionBoolean>([
      ['showVendedor', {caption: 'avisoscobranca.config.showVendedor', value: false}],
      ['showCondPagamento', {caption: 'avisoscobranca.config.showCondPagamento', value: false}]
    ]);
  }

  private _defaultInventarios(): TConfigOptions<boolean, IComercialInventariosConfigOptions> {
    return new Map<keyof IComercialInventariosConfigOptions, IConfigOptionBoolean>([
      ['showTipoArtigo', {caption: 'invec.options.showTipoArtigo', value: false}],
      ['showGrandeFamilia', {caption: 'invec.options.showGrandeFamilia', value: false}],
      ['showSubFamilia', {caption: 'invec.options.showSubFamilia', value: false}],
      ['showClasse', {caption: 'invec.options.showClasse', value: false}],
      ['showCategoria', {caption: 'invec.options.showCategoria', value: false}],
      ['showValorimetria', {caption: 'invec.options.showValorimetria', value: false}]
    ]);
  }

  private _defaultFamilias(): TConfigOptions<boolean, IFamiliasConfigOptions> {
    return new Map<keyof IFamiliasConfigOptions, IConfigOptionBoolean>([['showContasVendaCompra', {caption: 'familias.btn.verContas', value: false}]]);
  }

  private _defaultArtigos(): TConfigOptions<boolean, IArtigosConfigOptions> {
    return new Map<keyof IArtigosConfigOptions, IConfigOptionBoolean>([
      ['showPrecoBaseCusto', {caption: 'artigos.btn.verPrecoBaseCusto', value: false}],
      ['showCategoria', {caption: 'artigos.btn.verCategoria', value: false}],
      ['showPropriedade', {caption: 'artigos.btn.verPropriedade', value: false}]
    ]);
  }

  private _defaultArtigosList(): TConfigOptions<boolean, IArtigosListConfigOptions> {
    return new Map<keyof IArtigosListConfigOptions, IConfigOptionBoolean>([
      ['showApenasDesativado', {caption: 'artigos.btn.verApenasDesativado', value: false}],
      ['showApenasBloqueado', {caption: 'artigos.btn.verApenasBloqueado', value: false}]
    ]);
  }

  private _defaultFaturacaoContratos(): TConfigOptions<boolean, IFaturacaoContratosConfigOptions> {
    return new Map<keyof IFaturacaoContratosConfigOptions, IConfigOptionBoolean>([
      ['showDataInicTransp', {caption: 'faturacaoContratos.config.verDataInicTransp', value: false}],
      ['showHoraInicTransp', {caption: 'faturacaoContratos.config.verHoraInicTransp', value: false}],
      ['showZonaDeAte', {caption: 'faturacaoContratos.config.verZonaDeAte', value: false}],
      ['showErrosContratos', {caption: 'faturacaoContratos.config.verErrosContratos', value: true}],
      ['showContasDeAte', {caption: 'faturacaoContratos.config.verContasDeAte', value: false}],
      ['showDataVenc', {caption: 'faturacaoContratos.config.verDataVenc', value: false}]
    ]);
  }

  private _defaultContratos(): TConfigOptions<boolean, IContratosConfigOptions> {
    return new Map<keyof IContratosConfigOptions, IConfigOptionBoolean>([
      ['showGrupoConta', {caption: 'contratos.config.verGrupoConta', value: false}],
      ['showMoeda', {caption: 'contratos.config.verMoeda', value: false}],
      ['showCondComerciais', {caption: 'contratos.config.verCondComerciais', value: false}],
      ['showRefExterna', {caption: 'contratos.config.verRefExterna', value: false}],
      ['showVendedor', {caption: 'contratos.config.verVendedor', value: false}],
      ['showNCCusto', {caption: 'contratos.config.verNCCusto', value: false}],
      ['showD1', {caption: 'contratos.config.verD1', value: false}],
      ['showArmazem', {caption: 'contratos.config.verArmazem', value: false}],
      ['showUnidadeMovimento', {caption: 'contratos.config.verUnidadeMovimento', value: false}]
    ]);
  }

  private _defaultContratosList(): TConfigOptions<boolean, IContratosListConfigOptions> {
    return new Map<keyof IContratosListConfigOptions, IConfigOptionBoolean>([['showNaoAtivosOnly', {caption: 'contratos.list.config.showNaoAtivosOnly', value: false}]]);
  }

  private _defaultContratosPrint(): TConfigOptions<boolean, IContratosPrintConfigOptions> {
    return new Map<keyof IContratosPrintConfigOptions, IConfigOptionBoolean>([['showClasses', {caption: 'contratos.print.config.showClasses', value: false}]]);
  }

  private _defaultFaturacaoEletronicaConfiguracoes(): TConfigOptions<boolean, IFaturacaoEletronicaConfiguracoesConfigOptions> {
    return new Map<keyof IFaturacaoEletronicaConfiguracoesConfigOptions, IConfigOptionBoolean>([
      ['ambientePreProducao', {caption: 'faturacaoeletronicaconfiguracoes.configOptions.ambientePreProducao', value: false}]
    ]);
  }

  private _defaultFichaClientes(): TConfigOptions<boolean, IFichaClientesConfigOptions> {
    return new Map<keyof IFichaClientesConfigOptions, IConfigOptionBoolean>([
      ['showZona', {caption: 'clientes.showZona', value: false}],
      ['showVendedor', {caption: 'clientes.showVendedor', value: false}],
      ['showSector', {caption: 'clientes.showSetor', value: false}]
    ]);
  }

  private _defaultFornecedoresList(): TConfigOptions<boolean, IFornecedoresListConfigOptions> {
    return new Map<keyof IFornecedoresListConfigOptions, IConfigOptionBoolean>([['showDesativados', {caption: 'fornecedores.showDesativados', value: false}]]);
  }

  private _defaultClientesList(): TConfigOptions<boolean, IClientesListConfigOptions> {
    return new Map<keyof IClientesListConfigOptions, IConfigOptionBoolean>([['showDesativados', {caption: 'clientes.showDesativados', value: false}]]);
  }

  private _defaultAdminPortais(): TConfigOptions<boolean, IAdminPortaisConfigOptions> {
    return new Map<keyof IAdminPortaisConfigOptions, IConfigOptionBoolean>([['showModuleName', {caption: 'portals.text.showModuleName', value: false}]]);
  }

  private _defaultLotesList(): TConfigOptions<boolean, ILotesListConfigOptions> {
    return new Map<keyof ILotesListConfigOptions, IConfigOptionBoolean>([['showSoTerminados', {caption: 'lotes.list.config.showSoTerminados', value: false, persist: false}]]);
  }

  private _defaultEncomendas(): TConfigOptions<boolean, IEncomendasConfigOptions> {
    return new Map<keyof IEncomendasConfigOptions, IConfigOptionBoolean>([
      ['showArtigos', {caption: 'encomendas.actions.showArtigos', value: false}],
      ['showArmazens', {caption: 'encomendas.actions.showArmazens', value: false}],
      ['showDocFas', {caption: 'encomendas.actions.showDocFas', value: false}],
      ['showTextoLivre', {caption: 'encomendas.actions.showTextoLivre', value: false}]
    ]);
  }

  private _defaultExtratosDeContabilidade(): TConfigOptions<boolean, IExtratosDeContabilidadeConfigOptions> {
    return new Map<keyof IExtratosDeContabilidadeConfigOptions, IConfigOptionBoolean>([['showDiario', {caption: 'components.contabilidade.extratosgrid.options.showDiarios', value: false}]]);
  }

  private _defaultMovimentosAberto(): TConfigOptions<boolean, IMovimentosAbertosConfigOptions> {
    return new Map<keyof IMovimentosAbertosConfigOptions, IConfigOptionBoolean>([['showGrupoConta', {caption: 'movimentosaberto.label.showGrupoConta', value: false}]]);
  }

  private _defaultIdadeSaldos(): TConfigOptions<boolean, IIdadeSaldosConfigOptions> {
    return new Map<keyof IIdadeSaldosConfigOptions, IConfigOptionBoolean>([['showGrupoConta', {caption: 'idadesaldos.label.showGrupoConta', value: false}]]);
  }

  private _defaultDocsComercialList(): TConfigOptions<boolean, IDocsComerciaisListConfigOptions> {
    return new Map<keyof IDocsComerciaisListConfigOptions, IConfigOptionBoolean>([
      ['showObservacoes', {caption: 'docscomerciais.configs.showObservacoes', value: false}],
      ['showDocExterno', {caption: 'docscomerciais.configs.showDocExterno', value: false}],
      ['showDataDocExterno', {caption: 'docscomerciais.configs.showDataDocExterno', value: false}],
      ['showDataVenc', {caption: 'docscomerciais.configs.showDataVenc', value: false}],
      ['showCCusto', {caption: 'docscomerciais.configs.showCCusto', value: false}],
      ['showMoeda', {caption: 'docscomerciais.configs.showMoeda', value: false}],
      ['showNRefProcesso', {caption: 'docscomerciais.configs.showNRefProcesso', value: false}],
      ['showNArmazem', {caption: 'docscomerciais.configs.showNArmazem', value: false}],
      ['showNVendedor', {caption: 'docscomerciais.configs.showNVendedor', value: false}],
      ['showValorCIva', {caption: 'docscomerciais.configs.showValorCIva', value: false}],
      ['showCodUnidMov', {caption: 'docscomerciais.configs.showCodUnidMov', value: false}],
      ['showNRequisicao', {caption: 'docscomerciais.configs.showNRequisicao', value: false}],
      ['showLiquido', {caption: 'docscomerciais.configs.showLiquido', value: false}],
      ['showD2', {caption: 'docscomerciais.configs.showD2', value: false}],
      ['showD3', {caption: 'docscomerciais.configs.showD3', value: false}],
      ['showDescEmValor', {caption: 'docscomerciais.configs.showDescEmValor', value: false}]
    ]);
  }
}
