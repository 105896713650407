import {Component} from '@angular/core';
import {MovimentosPendentesBaseComponent} from '../movimentos.pendentes.base.component';
import {IDevExpressDataGridColumn, IDevExpressDataGridSummary} from '../../../devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IJsonPendentesLinha} from '../../../../interfaces/jsonContabilidade.interface';
import {isNumber} from 'pl-comps-angular';

@Component({
  selector: 'movimentos-pendentes-clientes',
  templateUrl: './movimentos.pendentes.clientes.component.html'
})
export class MovimentosPendentesClientesComponent extends MovimentosPendentesBaseComponent {
  public evtDataGridInstanceEmit(dataGridInstance: dxDataGrid): void {
    this.evtDataGridInstance.emit(dataGridInstance);
  }

  protected _buildDefinition(): Array<IDevExpressDataGridColumn> {
    return [
      {dataField: 'nConta', dataType: 'string', caption: 'movimentospendentes.clientes.nConta'},
      {dataField: 'nome', dataType: 'string', caption: 'movimentospendentes.clientes.nome'},
      {dataField: 'tipoDocumento', dataType: 'string', caption: 'movimentospendentes.clientes.tipoDocumento'},
      {dataField: 'numeroDocumento', dataType: 'string', caption: 'movimentospendentes.clientes.numeroDocumento'},
      {dataField: 'dataDoc', dataType: 'date', caption: 'movimentospendentes.clientes.dataDoc', visible: false},
      {dataField: 'dataVencimento', dataType: 'date', caption: 'movimentospendentes.clientes.dataVencimento'},
      {
        dataField: 'valor',
        dataType: 'double',
        alignment: 'right',
        caption: 'movimentospendentes.clientes.valor',
        calculateCellValue: (rowData: IJsonPendentesLinha) => (isNumber(rowData.valor) ? rowData.valor : '')
      },
      {
        dataField: 'valorRecebido',
        dataType: 'double',
        alignment: 'right',
        caption: 'movimentospendentes.clientes.valorRecebido',
        calculateCellValue: (rowData: IJsonPendentesLinha) => (isNumber(rowData.valorRecebido) ? rowData.valorRecebido : '')
      },
      {
        dataField: 'valorPorReceber',
        dataType: 'double',
        alignment: 'right',
        caption: 'movimentospendentes.clientes.valorPorReceber',
        calculateCellValue: (rowData: IJsonPendentesLinha) => (isNumber(rowData.valorPorReceber) ? rowData.valorPorReceber : '')
      },
      {dataField: 'nif', dataType: 'string', caption: 'movimentospendentes.clientes.nif'},
      {dataField: 'nDocExterno', dataType: 'string', caption: 'movimentospendentes.clientes.nDocExterno', visible: false}
    ];
  }

  protected _buildGridSummary(): IDevExpressDataGridSummary {
    return {
      totalItems: [
        {column: 'valor', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
        {column: 'valorRecebido', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
        {column: 'valorPorReceber', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}
      ]
    };
  }

  protected _listFields(): string {
    return 'nConta,nome,nif,tipoDocumento,numeroDocumento,dataDoc,dataVencimento,valor,valorRecebido,valorPorReceber,nDocExterno';
  }
}
