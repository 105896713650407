import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EImportadorSaftDirectTipoMercado, EImportadorSaftDirectTipoMercadoStr, IJsonImportadorSaftDirectCliente} from '../../importadorSaftDirect.module.interface';
import {IJsonClifo} from '../../../../../entities/clifos/jsonClifo.entity.interface';
import {IJsonTxRet} from '../../../../../entities/taxasretencao/jsonTxRet.entity.interface';
import {ImportadorSaftDirectService} from '../../importadorSaftDirect.module.service';

@Component({
  selector: 'importador-saft-direct-tipos-docs-modal',
  templateUrl: './importadorSaftDirect.correspContas.modal.component.html'
})
export class ImportadorSaftDirectCorrespContasModalComponent extends CGModalComponent<IJsonImportadorSaftDirectCliente> implements OnInit {
  @Input() public model: IJsonImportadorSaftDirectCliente;
  @Input() public showCCusto: boolean;
  @Input() public showRepCC: boolean;
  public readonly mercadoSource: Array<{mercado: EImportadorSaftDirectTipoMercado; mercadoStr: EImportadorSaftDirectTipoMercadoStr}>;
  public readonly mercadoTemplate: string;
  public readonly mercadoOutput: string;
  public filterContaDestino: string;

  private _originalModel: IJsonImportadorSaftDirectCliente;

  constructor(
    protected readonly _injector: Injector,
    private readonly _importadorSaftDirectService: ImportadorSaftDirectService
  ) {
    super(_injector);
    this.mercadoSource = [
      {mercado: EImportadorSaftDirectTipoMercado.Nacional, mercadoStr: EImportadorSaftDirectTipoMercadoStr.Nacional},
      {mercado: EImportadorSaftDirectTipoMercado.Comunitario, mercadoStr: EImportadorSaftDirectTipoMercadoStr.Comunitario},
      {mercado: EImportadorSaftDirectTipoMercado.Outro, mercadoStr: EImportadorSaftDirectTipoMercadoStr.Outro}
    ];
    this.mercadoTemplate = '{{mercadoStr}}';
    this.mercadoOutput = 'mercadoStr';
  }

  public ngOnInit(): void {
    this._originalModel = {...this.model};
    this.filterContaDestino = `nContribuint=${this.model.customerTaxID}`;
  }

  public async close(): Promise<void> {
    if (this._originalModel.mercado !== this.model.mercado) {
      await this._importadorSaftDirectService.correspContasMercadoChange(this.model.customerID, this.model.mercado);
    }
    if (this._originalModel.codRetencao !== this.model.codRetencao) {
      await this._importadorSaftDirectService.correspContasAtribuirRetencao(this.model.customerID, this.model.codRetencao);
    }
    if (this._originalModel.contaDestino !== this.model.contaDestino) {
      await this._importadorSaftDirectService.correspContasAtribuirConta([this.model.customerID], this.model.contaDestino);
    }
    if (this._originalModel.nCCusto !== this.model.nCCusto) {
      await this._importadorSaftDirectService.correspContasAtribuirCCusto([this.model.customerID], this.model.nCCusto);
    }
    if (this._originalModel.nCodRepCC !== this.model.nCodRepCC) {
      await this._importadorSaftDirectService.correspContasAtribuirRepCC([this.model.customerID], this.model.nCodRepCC);
    }
    super.close(this.model);
  }

  public changedMercado(value: {item: {mercado: EImportadorSaftDirectTipoMercado; mercadoStr: EImportadorSaftDirectTipoMercadoStr}}): void {
    this.model.mercado = value.item.mercado;
    this.model.mercadoStr = value.item.mercadoStr;
  }

  public changedTaxaRetencao(value: IJsonTxRet): void {
    this.model.codRetencao = value?.codRet;
  }

  public changedContaDestino(item: IJsonClifo): void {
    this.model.contaDestino = item.nConta;
    this.model.nomeContaCG = item.nome;
  }
}
