<div class="modal-header procseguradorafolhaferias-print-modal">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'procseguradorafolhaferias.modal.impressaomapas'"></h5>
</div>

<div class="modal-body">
  <pl-form>
    <pl-group plAutoFocus>
      <label [translate]="'global.text.listing'"></label>
      <edit>
        <pl-edit type="reports_proc_segu_folha_ferias_mapa" attrName="listagem" [model]="reportModel" (modelChange)="reportModel = $event; processar()"></pl-edit>
      </edit>
    </pl-group>
  </pl-form>

  <br />

  <cg-pdf-viewer [pdfSrc]="pdfBlob"></cg-pdf-viewer>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light action-dismiss" (evtClicked)="close()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
