import moment from 'moment';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {isDefinedNotNull, isUndefinedOrNull, KEYCODES, PlAlertService, TEditInputKeyboardEvent} from 'pl-comps-angular';
import {CGExceptionService} from '../../../../../components/exceptions/exceptions.service';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ConciliacaoBancariaService} from '../../conciliacaoBancaria.module.service';
import {DATA_SOURCE_DEBITO_CREDITO} from '../../../../../datasources/debitocredito/debitoCredito.datasource';
import {EDebitoCredito} from '../../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {ENTITY_NAME_PERIODOS, IPeriodosEntityService} from '../../../../../entities/periodos/periodos.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {focusElement} from '../../../../../../common/utils/element.utils';
import {ICGExceptionError} from '../../../../../components/exceptions/exceptions.service.interface';
import {EConciliacaoBancariaMode, IConciliacaoBancariaBancoCab} from '../../conciliacaoBancaria.module.interface';
import {IJsonPeriodo} from '../../../../../entities/periodos/jsonPeriodo.entity.interface';
import {IDataSourceItem} from '../../../../../components/datasource/datasources.interface';

@Component({
  selector: 'conciliacaoBancaria-nova-conciliacao-modal',
  templateUrl: './conciliacaoBancaria.novaConciliacao.modal.component.html'
})
export class ConciliacaoBancariaNovaConciliacaoModalComponent extends CGModalComponent<Partial<IConciliacaoBancariaBancoCab>> implements OnInit {
  @Input() public mode: EConciliacaoBancariaMode;
  @Input() public model: Partial<IConciliacaoBancariaBancoCab>;
  @Input() public primeiraConciliacao: boolean;
  @Input() public nConta: string;
  @Input() public periodo: string;
  @Input() public abrevMoedaEstrangeira: string;

  public readonly pocsOutput: string;
  public readonly pocsFilter: string;
  public readonly modeType: typeof EConciliacaoBancariaMode;
  public readonly debitoCreditoItems: ReadonlyArray<IDataSourceItem<EDebitoCredito>>;
  public modalTitle: string;
  public saveBtnTitle: string;
  public periodoDatas: string;
  public isSaldoInicialEnabled: boolean;
  public isPeriodoDisabled: boolean;

  private readonly _periodosService: IPeriodosEntityService;
  private _loadedExtratoNConta: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _conciliacaoBancariaService: ConciliacaoBancariaService,
    private readonly _translateService: TranslateService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.mode = EConciliacaoBancariaMode.NEW;
    this.pocsOutput = '{{nConta}} - {{nome}}';
    this.pocsFilter = 'nConta=%12%&tipo=0';
    this.modeType = EConciliacaoBancariaMode;
    this.debitoCreditoItems = DATA_SOURCE_DEBITO_CREDITO.data;
    this.periodoDatas = '';
    this._periodosService = this._entityServiceBuilder.build<IJsonPeriodo, IPeriodosEntityService>(ENTITY_NAME_PERIODOS);
    this._loadedExtratoNConta = '';
  }

  public readonly fnCloseModal: () => Promise<void> = () => this._fnCloseModal();
  public readonly fnKeyDownSaldoFinal: TEditInputKeyboardEvent<string> = (value: string, event: KeyboardEvent) => {
    this._fnKeyDownSaldoFinal(event);
  };

  public ngOnInit(): void {
    this.isSaldoInicialEnabled = true;
    if (isUndefinedOrNull(this.model)) {
      this._reset();
    } else {
      this._updatePeriodoDatasLabel();
    }

    this._loadedExtratoNConta = this.model.nConta;
    this.isPeriodoDisabled = this.mode === EConciliacaoBancariaMode.EDIT;
    if (this.mode === EConciliacaoBancariaMode.NEW) {
      this.modalTitle = 'conciliacaoBancaria.novaConciliacao';
      this.saveBtnTitle = 'global.btn.create';
      this._validateNConta();
      this.isPeriodoDisabled = true;
    } else {
      this.modalTitle = 'conciliacaoBancaria.editarConciliacao';
      this.saveBtnTitle = 'global.btn.save';
      this.isSaldoInicialEnabled = this.primeiraConciliacao;
    }
  }

  public onPeriodoChange(periodo: string): Promise<void> {
    this.model.periodo = periodo;
    return new Promise<void>((resolve, reject) => {
      if (this.mode === EConciliacaoBancariaMode.NEW) {
        this._conciliacaoBancariaService
          .getUltimoExtratoPeriodo(this.model.nConta)
          .then((lastPeriodo) => {
            if (isDefinedNotNull(lastPeriodo) && lastPeriodo !== '') {
              this._periodosService.obterProximoPeriodoContab(lastPeriodo).then((newPeriodo) => {
                if (lastPeriodo !== newPeriodo.body.periodo) {
                  this.model.periodo = '';
                  this.periodoDatas = '';
                  reject(this._translateService.instant('conciliacaoBancaria.periodoNaoEValidoDeveIndicarOPeriodpSeg'));
                }
                resolve();
              });
            } else {
              resolve();
            }
          })
          .catch(reject);
      }
    }).then(() => {
      this._updatePeriodoDatasLabel();
    });
  }

  public onNContaChange(nconta: string): void {
    this.model.nConta = nconta;
    if (this.model.nConta !== this._loadedExtratoNConta) {
      this._reset();
      this.model.nConta = nconta;
    }
    this._validateNConta();
  }

  private _fnCloseModal(): Promise<void> {
    if (this.mode === EConciliacaoBancariaMode.NEW) {
      return this._conciliacaoBancariaService
        .novaConciliacao(this.model)
        .then(() => {
          this.close(this.model);
        })
        .catch((reason) => {
          this._handleRequestError(reason);
        });
    }
    return this._conciliacaoBancariaService
      .editarConcilidacao(this.model, this.nConta, this.periodo)
      .then(() => {
        this.close(this.model);
      })
      .catch((reason) => {
        this._handleRequestError(reason);
      });
  }

  private _validateNConta(): void {
    if (this.model.nConta !== '' && this.model.nConta !== undefined) {
      this._conciliacaoBancariaService.getNContaAbrevMoedaEstrangeira(this.model.nConta).then((response) => {
        this.abrevMoedaEstrangeira = isDefinedNotNull(response.body.abrevMoedaEstrangeira) ? response.body.abrevMoedaEstrangeira : '';
      });

      this._conciliacaoBancariaService.getUltimoExtratoPeriodo(this.model.nConta).then((periodo) => {
        if (isDefinedNotNull(periodo) && periodo !== '') {
          this._periodosService.obterProximoPeriodoContab(periodo).then((newPeriodo) => {
            this.isPeriodoDisabled = true;
            this.model.periodo = newPeriodo.body.periodo;
            this._obterSaldoInicialBanco();
          });
        }
      });
    } else {
      this._resetPeriodoField();
    }
  }

  private _obterSaldoInicialBanco(): void {
    if (this.model.nConta !== '') {
      this._conciliacaoBancariaService.obterSaldoInicialBanco(this.model.nConta).then((response) => {
        if (response.existe) {
          this.isSaldoInicialEnabled = false;
          this.model.saldoInicial = response.saldoInicial;
          this.model.saldoInicialDC = response.saldoInicialDC;
          this._focusSaldoFinal();
        }
      });
    }
  }

  private _updatePeriodoDatasLabel(): void {
    if (this.model.periodo) {
      this._periodosService.obterDatasPeriodoTributacao(this.model.periodo).then((response) => {
        this.periodoDatas = this._translateService.instant('conciliacaoBancaria.fromTo', {
          from: moment(response.body.deData).format(this.format.momentDate),
          to: moment(response.body.ateData).format(this.format.momentDate)
        });
      });
    }
  }

  private _reset(): void {
    this.mode = EConciliacaoBancariaMode.NEW;
    this.isSaldoInicialEnabled = true;
    this.isPeriodoDisabled = false;
    this.periodoDatas = '';
    this.model = {
      nConta: '',
      nomeNConta: '',
      periodo: '',
      saldoFinal: 0,
      saldoInicial: 0,
      saldoInicialDC: EDebitoCredito.Credito,
      saldoFinalDC: EDebitoCredito.Credito
    };
  }

  private _resetPeriodoField(): void {
    this.isPeriodoDisabled = true;
    this.model = {
      nConta: '',
      nomeNConta: '',
      periodo: '',
      saldoFinal: 0,
      saldoInicial: 0,
      saldoInicialDC: EDebitoCredito.Credito,
      saldoFinalDC: EDebitoCredito.Credito
    };
  }

  private _handleRequestError(reason: HttpErrorResponse): void {
    if (isDefinedNotNull(reason)) {
      const exception: ICGExceptionError = this._cgExceptionService.get(reason);
      this._plAlertService.error(exception.message);
    }
  }

  private _fnKeyDownSaldoFinal(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      event.preventDefault();
      focusElement(this._element.querySelector<HTMLInputElement>('.action-save'));
    }
  }

  private _focusSaldoFinal(): void {
    const input: HTMLElement = document.querySelector('#conciliacaoBancaria-saldoFinalBanco');
    if (isDefinedNotNull(input)) {
      focusElement(input);
    }
  }
}
