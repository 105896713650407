import {Directive, Input, OnChanges, SimpleChanges, TemplateRef} from '@angular/core';
import {IPlEditInputGroupDirectiveConfig, IPlEditInputGroupTemplateContext} from './edit.input.group.directive.interface';

@Directive({
  selector: 'ng-template[inputGroup]',
  standalone: false
})
export class PlEditInputGroupDirective implements OnChanges {
  @Input() public inputGroup: undefined | '' | Partial<IPlEditInputGroupDirectiveConfig>;

  private _config: IPlEditInputGroupDirectiveConfig;

  constructor(public readonly templateRef: TemplateRef<IPlEditInputGroupTemplateContext>) {
    this._config = {
      klass: 'btn-outline-secondary',
      disabled: undefined,
      raw: false,
      tabindex: undefined,
      title: undefined,
      promise: undefined
    };
  }

  public ngOnChanges({inputGroup}: SimpleChanges): void {
    if (inputGroup) {
      this._config = {
        klass: 'btn-outline-secondary',
        disabled: undefined,
        raw: false,
        tabindex: undefined,
        tooltip: undefined,
        promise: undefined,
        ...inputGroup.currentValue
      };
    }
  }

  public get config(): IPlEditInputGroupDirectiveConfig {
    return this._config;
  }

  public get klass(): string {
    return this._config.klass;
  }

  public get disabled(): boolean {
    return this._config.disabled;
  }

  public get raw(): boolean {
    return this._config.raw;
  }

  public get tabindex(): number {
    return this._config.tabindex;
  }

  public get title(): string {
    return this._config.title;
  }

  public get promise(): Promise<unknown> {
    return this._config.promise;
  }
}
