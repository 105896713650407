<div class="modal-body">
  <ng-container *ngIf="processing">
    <div [translate]="'importadorEfatura.importarEmSerieModalMessage'" style="margin-bottom: 8px"></div>
    <div class="progress" style="height: 4px">
      <div
        class="progress-bar bg-info progress-bar-striped progress-bar-animated"
        role="progressbar"
        [attr.aria-valuenow]="100"
        [attr.aria-valuemin]="0"
        [attr.aria-valuemax]="100"
        style="width: 100%"></div>
    </div>
  </ng-container>

  <ng-container *ngIf="!processing">
    <div class="row importadorEfatura-lancar-em-serie-results">
      <div class="col-md-12">
        <h4 class="importadorEfatura-lancar-em-serie-relatorio">Relatório:</h4>
      </div>
      <div class="col-md-6 text-center lancados" [ngClass]="naoLancadosCount > 0 ? 'col-md-6' : 'col-md-12'">
        <i class="fa fa-check-circle-o" aria-hidden="true"></i>
        <div class="importadorEfatura-lancar-em-serie-result-val">{{ lancadosCount }}</div>
        <div class="importadorEfatura-lancar-em-serie-title">Lançados</div>
      </div>
      <div class="col-md-6 text-center nao-lancados" *ngIf="naoLancadosCount > 0">
        <i class="fa fa-times-circle-o" aria-hidden="true"></i>
        <div class="importadorEfatura-lancar-em-serie-result-val">{{ naoLancadosCount }}</div>
        <div class="importadorEfatura-lancar-em-serie-title">
          Não Lançados<br />
          <small>(erros)</small>
        </div>
      </div>
      <div class="col-md-12 text-center" style="margin-top: 20px" plAutoFocus>
        <button type="button" class="btn btn-success" (click)="close()">Fechar</button>
      </div>
    </div>
  </ng-container>
</div>
