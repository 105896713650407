import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {AtivosVendaService} from '../../ativosVenda.module.service';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IAtivoVendaContab} from '../../ativosVenda.module.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellDblClick,
  IDevExpressDataGridEventOnInitialized
} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonAtivosVenda} from '../../jsonAtivosVenda.module.interface';
import {IJsonDocContabilidade} from '../../../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';

@Component({
  selector: 'modal-ativos-venda-contab',
  templateUrl: './ativosVenda.contab.modal.component.html'
})
export class AtivosVendaContabModalComponent extends CGModalComponent<IJsonAtivosVenda> implements OnInit {
  @Input() public ativoVendaContabList: Array<IAtivoVendaContab>;

  public readonly dataGridDefinition: IDevExpressDataGrid;

  public selected: IAtivoVendaContab;
  public promise: Promise<void>;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;

  private _dataGridInstance: dxDataGrid;
  private _hasContabilidadeDigital: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosVendaService: AtivosVendaService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.dataGridDefinition = {
      columnAutoWidth: true,
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'ativosaquisicaocontab.table.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'ativosaquisicaocontab.table.nome', width: 150},
        {dataField: 'periodo', dataType: 'string', caption: 'ativosaquisicaocontab.table.periodo'},
        {dataField: 'nDiario', dataType: 'number', caption: 'ativosaquisicaocontab.table.nDiario'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'ativosaquisicaocontab.table.nDocInterno'},
        {dataField: 'dCDescription', dataType: 'number', caption: 'ativosaquisicaocontab.table.dCDescription'},
        {dataField: 'valor', dataType: 'double', caption: 'ativosaquisicaocontab.table.valor'},
        {dataField: 'valorCalculado', dataType: 'double', caption: 'ativosaquisicaocontab.table.valorCalculado'}
      ],
      dataSource: [],
      export: {filename: 'ativosaquisicaocontab.modal.ativoaquisicaocontabmodaltitle'},
      keyExpr: 'extPocCabID',
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocContabilidade'},
      remoteOperations: false
    };
    this.ativoVendaContabList = [];
    this.refresh = this.refresh.bind(this);
  }

  public ngOnInit(): void {
    if (!this.ativoVendaContabList) {
      this.ativoVendaContabList = [];
    }
    this.dataGridDefinition.dataSource = this.ativoVendaContabList;
    this._hasContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
  }

  public close(): Promise<void> {
    if (this.promise) {
      return this.promise;
    }
    this.promise = this._ativosVendaService
      .postDadosparaformvenda(this.selected)
      .then((response: HttpResponse<IJsonAtivosVenda>) => {
        super.close(response.body);
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IAtivoVendaContab>): void {
    if (event.rowType !== 'data') {
      return;
    }
    this.selected = event.data;
    devExpressDataGridExpandDetailHandler(event, () =>
      this._serviceDocsContabilidade.get({id: event.data.extPocCabID}).then((response: HttpResponse<IJsonDocContabilidade>) => {
        event.data._docContabilidade = response.body;
        if (this._hasContabilidadeDigital) {
          event.data._contabDigitalDoc = {
            extPocCabID: response.body.extPocCabID,
            periodo: response.body.periodo,
            nDiario: response.body.nDiario,
            nDocInterno: response.body.nDocInterno,
            dataDoc: response.body.dataDoc
          };
        }
      })
    );
  }

  public onCellDblClick({event, rowType, data}: IDevExpressDataGridEventOnCellDblClick<IAtivoVendaContab>): void {
    if (rowType !== 'data') {
      return;
    }
    this.selected = data;
    const target: HTMLElement = <HTMLElement>event.target;
    const row: HTMLElement = target.closest('tbody');
    if (row && this.selected && !this.promise) {
      this.close();
    }
  }

  public refresh(): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    return this._ativosVendaService
      .getAtivosPesquisaContab()
      .then((response: HttpResponse<Array<IAtivoVendaContab>>) => {
        this.dataGridDefinition.dataSource = response.body;
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
  }
}
