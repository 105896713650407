import {Component, Injector, OnInit} from '@angular/core';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IPlFormDefinition, isDefinedNotNull} from 'pl-comps-angular';
import {IJsonPRHTipoCom} from '../../jsonrhtipocom.entity.interface';

@Component({
  selector: 'prhtipocom-edit',
  templateUrl: './prhtipocom.entity.edit.component.html'
})
export class PRHTipoComEntityEditComponent extends ModuloEntityDetailComponent<IJsonPRHTipoCom> implements OnInit {
  public definition: IPlFormDefinition;
  public isDetailState: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.isDetailState = this.type === EEntityStateDetailType.DETAIL;
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    this.isDetailState = stateType === EEntityStateDetailType.DETAIL;
    const entityDef = {...this.entity[stateType].definition};
    let item = entityDef.fields.find((f) => f.name === 'isSystem');
    if (isDefinedNotNull(item)) {
      item.properties.disabled = true;
    }
    if (stateType === EEntityStateDetailType.EDIT && this.model.isSystem) {
      item = entityDef.fields.find((f) => f.name === 'nome');
      if (isDefinedNotNull(item)) {
        item.properties.disabled = true;
      }
    }
    this.definition = entityDef;
    return super.onUpdate(stateType);
  }
}
