import {HttpResponse} from '@angular/common/http';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {IIesStatus} from './ies.module.interface';
import {IesComponent} from './components/ies.module.component';
import {IesService} from './ies.module.service';

export const MODULE_IES: IModuleDefinition = {
  name: 'ies',
  state: {
    url: '/ies',
    component: IesComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.ERP],
      icon: 'fa-upload',
      pageTitle: 'global.menu.ies'
    },
    resolve: [
      {
        provide: 'initStatus',
        deps: [IesService],
        useFactory: (saftService: IesService): Promise<IIesStatus> => {
          return saftService.getStatus().then((response: HttpResponse<IIesStatus>) => response.body);
        }
      }
    ]
  }
};
