import {merge} from 'lodash-es';
import {firstValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {
  LazyLoadResult,
  ParamType,
  ParamTypes,
  ProviderLike,
  RedirectToResult,
  StateDeclaration,
  StateObject,
  StateRegistry,
  StateService,
  TargetState,
  Transition,
  TransitionService,
  UIInjector
} from '@uirouter/core';
import {copy, isArray, isDefinedNotNull, isFunction, isObject, isString, isUndefined, Logger, toJson} from 'pl-comps-angular';
import {AuthService} from '../auth/auth.service';
import {CGStateService} from '../../components/state/cg.state.service';
import {CONFIG_LAST_PORTAL, generatePortalId} from '../../../config/constants';
import {ConfigErpService} from '../configErp.service';
import {ConfigService} from '../config/config.service';
import {RESOLVER_AUTHORIZE, RESOLVER_LAUNCH_MODE, RESOLVER_SESSION, RESOLVER_USER_PORTALS} from '../../../config/uirouter/uirouter.resolvers';
import {EAppLaunchMode, STATE_NAME_SITE} from '../../../common/site';
import {
  EEntityStateDetailType,
  EEntityStateListQueryParam,
  entityStateDetailQueryParam,
  entityStateListQueryParam,
  entityStateNameDetail,
  entityStateNameNew,
  IEntityDetailParams
} from '../../../common/utils/entity.state.utils';
import {EmpresaService} from '../empresa/empresa.service';
import {ENTITY_NAME_PORTALS, IPortalModule} from '../../entities/portal/portal.entity.interface';
import {EntityRegistryService} from '../../components/entity/entity.registry.service';
import {EntityServiceBuilder} from '../entity/entity.service.builder';
import {EPortal} from '../../../common/enums/portals.enums';
import {hasAnyAuthority as sessionHasAnyAuthority, hasAuthority as sessionHasAuthority} from '../../../common/utils/roles.utils';
import {IBlockedPluginModuleStateParams, MODULE_NAME_BLOCKED_PLUGIN} from '../../modules/blockedplugin/blockedPlugin.module.interface';
import {ICGConfigurations, IWritableCGConfigurations} from '../config/config.service.interface';
import {ICGStateDeclaration, IPortalSidebarMenu, IPortalState, IPortalStateDeclaration, IPortalStateDeclarationData, IPortalStates, STATE_NAME_PORTAL, TStatesMap} from './portals.service.interface';
import {IEntityService, TEntityServiceRequestData} from '../entity/entity.service.interface';
import {IEntity} from '../../components/entity/entity.definition.interface';
import {IJsonPortal} from '../../entities/portal/jsonPortal.entity.interface';
import {IJsonUserLogin, TUserSession} from '../account/jsonUserApi.interface';
import {IModule, IModuleHelperLink} from '../../components/module/module.definition.interface';
import {MODULE_NAME_EMPRESAS} from '../../modules/empresas/empresas.module.interface';
import {MODULE_NAME_NOTIFICATION_CENTER} from '../../modules/notificationcenter/notificationCenter.module.interface';
import {ModuleRegistryService} from '../../components/module/module.registry.service';
import {ModuloEntityDetailComponent} from '../../components/module/entitydetail/module.entitydetail.component';
import {ModuloEntityListComponent} from '../../components/module/entitylist/module.entitylist.component';
import {NOTIFICATION_CENTER_ENABLED} from '../notificationcenter/notificationcenter.service.interface';
import {PortalComponent} from '../../components/portal/portal.component';
import {RESOLVER_CONFIGURATIONS} from '../config/config.service.router';
import {ROLE} from '../role.const';
import {STATE_NAME_ADMIN} from '../../states/admin/admin.portal.interface';
import {STATE_NAME_LOGIN} from '../../states/account/login/login.state.interface';
import {STATE_NAME_NO_AUTHORITY} from '../../states/account/noauthority/noauthority.state.interface';
import {THttpQueryResponse, TServiceResponse} from '../api/api.service.interface';
import {UIRouterViewIdentityComponent} from '../../components/uirouter/uirouter.view.identity.component';
import {INoAuthorityModuleStateParams, MODULE_NAME_NO_AUTHORITY} from '../../modules/noauthority/noauthority.module.interface';
import {BlockedPluginError, NoAuthorityError} from '../../../common/errors/router.errors';
import {extractStatePortalStateName} from '../../../common/utils/state.utils';

const STATE_RESOLVERS: ReadonlyArray<ProviderLike> = Object.freeze([RESOLVER_LAUNCH_MODE, RESOLVER_AUTHORIZE, RESOLVER_SESSION, RESOLVER_CONFIGURATIONS]);

@Injectable({
  providedIn: 'root'
})
export class PortalsService implements OnDestroy {
  private readonly _states: TStatesMap;
  private readonly _registeredHooks: Set<Function>;
  private _redirectTo: string;
  private _lastUser: IJsonUserLogin;

  constructor(
    private readonly _stateRegistry: StateRegistry,
    private readonly _stateService: StateService,
    private readonly _transitionService: TransitionService,
    private readonly _logger: Logger,
    private readonly _entityRegistryService: EntityRegistryService,
    private readonly _moduleRegistryService: ModuleRegistryService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgStateService: CGStateService
  ) {
    this._states = new Map<string, ICGStateDeclaration>();
    this._registeredHooks = new Set<Function>();

    let deRegisterFn: Function = this._transitionService.onBefore({from: '**', to: `${STATE_NAME_PORTAL}.**`}, async (transition: Transition): Promise<TargetState> => {
      const to: ICGStateDeclaration = <ICGStateDeclaration>transition.to();
      const session: TUserSession = await transition.injector().getAsync<TUserSession>('session');
      if (Object.hasOwn(to.data, 'roles') && isArray(to.data.roles) && to.data.roles.length > 0 && !sessionHasAnyAuthority(session, to.data.roles)) {
        const portal: EPortal = extractStatePortalStateName(to);
        const params: INoAuthorityModuleStateParams = {pageTitle: to.data.pageTitle, roles: to.data.roles};
        return this._cgStateService.targetState({stateOrName: MODULE_NAME_NO_AUTHORITY, portal: portal, params: params});
      }
      if (
        (isArray(to.data.requiredRoles) && to.data.requiredRoles.length > 0 && !sessionHasAuthority(session, to.data.requiredRoles)) ||
        (isArray(to.data.pluginsRoles) && to.data.pluginsRoles.length > 0 && !sessionHasAnyAuthority(session, to.data.pluginsRoles))
      ) {
        const portal: EPortal = extractStatePortalStateName(to);
        const params: IBlockedPluginModuleStateParams = {pageTitle: to.data.pageTitle, requiredRoles: to.data.requiredRoles, pluginRoles: to.data.pluginsRoles};
        return this._cgStateService.targetState({stateOrName: MODULE_NAME_BLOCKED_PLUGIN, portal: portal, params: params});
      }
      return undefined;
    });
    this._registeredHooks.add(deRegisterFn);

    deRegisterFn = this._transitionService.onError({from: '**', to: `${STATE_NAME_PORTAL}.**`}, (transition: Transition) => {
      const toState = transition.to();
      const error: unknown = transition.error().detail;

      if (error instanceof NoAuthorityError) {
        const params: INoAuthorityModuleStateParams = {
          pageTitle: toState.data.pageTitle,
          roles: toState.data.roles
        };
        const portal: EPortal = extractStatePortalStateName(toState);
        this._cgStateService.redirectToState({stateOrName: MODULE_NAME_NO_AUTHORITY, portal: portal, params: params});
        return;
      }

      if (error instanceof BlockedPluginError) {
        const params: IBlockedPluginModuleStateParams = {
          pageTitle: toState.data.pageTitle,
          requiredRoles: toState.data.requiredRoles,
          pluginRoles: toState.data.pluginsRoles
        };
        const portal: EPortal = extractStatePortalStateName(toState);
        this._cgStateService.redirectToState({stateOrName: MODULE_NAME_BLOCKED_PLUGIN, portal: portal, params: params});
      }
    });
    this._registeredHooks.add(deRegisterFn);
  }

  public ngOnDestroy(): void {
    for (const registeredHook of this._registeredHooks) {
      registeredHook();
    }
  }

  public registerPortal(portal: IJsonPortal): Array<IPortalStateDeclaration> {
    const state: IPortalStateDeclaration = this.portalState();
    const states: Array<ICGStateDeclaration> = [state];
    const parentStateName = `${STATE_NAME_PORTAL}.${portal.url}`;

    delete state.parent;

    state.name = parentStateName;
    state.url = `/${portal.url}`;
    // @ts-expect-error UIRouterViewIdentityComponent is an exception, all modules should extend ModuleComponent
    state.component = UIRouterViewIdentityComponent;
    state.data.portalId = generatePortalId(portal.id);
    state.data.portalUrl = portal.url;
    state.data.pageTitle = portal.name;
    state.data.roles = [<ROLE>state.data.portalId, ...portal.roles];
    state.data.menu = [];

    let firstMenu: string;

    const addMenus = (menuStates: Array<IPortalSidebarMenu>, items: Array<IPortalModule>, firstLevel: boolean = true): void => {
      for (const item of items) {
        addMenu(menuStates, item, firstLevel);
      }
    };

    const addMenu = (menuStates: Array<IPortalSidebarMenu>, module: IPortalModule, firstLevel: boolean): void => {
      const moduleTitle: string = module.sidebarTitle || module.pageTitle || module.title;

      if (module.items) {
        const sidebarMenu: IPortalSidebarMenu = {title: moduleTitle, icon: module.icon, visible: module.visible, state: undefined, menu: []};
        menuStates.push(sidebarMenu);
        addMenus(sidebarMenu.menu, module.items, false);
        return;
      }

      try {
        const portalState: IPortalState = this.registerModule(parentStateName, module.name, module.visible);
        if (portalState) {
          if (moduleTitle) {
            portalState.menu.title = moduleTitle;
          }
          if (!firstLevel) {
            delete portalState.menu.icon;
          } else if (module.icon) {
            portalState.menu.icon = module.icon;
          }
          states.push(portalState.state);
          menuStates.push(portalState.menu);
          if (!firstMenu) {
            firstMenu = portalState.state.name;
          }
        }
      } catch (exception: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        this._logger.error(`${String(exception)}; ${toJson(module)}`);
      }
    };

    addMenus(state.data.menu, portal.data);

    if (firstMenu) {
      state.redirectTo = firstMenu;
    }

    // Register fixed portal stats
    this.registerModule(parentStateName, MODULE_NAME_NO_AUTHORITY, false);
    this.registerModule(parentStateName, MODULE_NAME_BLOCKED_PLUGIN, false);
    if (NOTIFICATION_CENTER_ENABLED) {
      this.registerModule(parentStateName, MODULE_NAME_NOTIFICATION_CENTER, false);
    }

    return states;
  }

  public registerModule(parentState: string, moduleName: string, visible: boolean = true): IPortalState {
    try {
      const module: IModule = this._moduleRegistryService.get(moduleName, false);
      if (!module) {
        return this.registerEntity(parentState, moduleName, visible);
      }
      const state: ICGStateDeclaration = module.state;
      state.name = `${parentState}.${moduleName}`;
      state.resolve = [...STATE_RESOLVERS, ...(state.resolve || [])];
      state.data._moduleName = moduleName;
      state.data._component = state.component;
      if (module.helperLinks) {
        state.data.helperLinks = this._handleModuleHelperLinks(module.helperLinks);
      }
      this._internalRegisterState(state);
      return {
        state: state,
        menu: {
          title: state.data.sidebarTitle || state.data.pageTitle,
          icon: state.data.icon,
          state: state.name,
          menu: undefined,
          visible: visible
        }
      };
    } catch (exception: unknown) {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      this._logger.error(`GetModule error: ${String(exception)}`);
      return undefined;
    }
  }

  public registerEntity(parentState: string, entityName: string, visible: boolean = true): IPortalState {
    const entity: IEntity = this._entityRegistryService.getEntity(entityName, false);
    if (!entity) {
      this._logger.warn(`Entidade "${entityName}" não está registada.`);
      return undefined;
    }
    const states: IPortalStates = {
      list: undefined,
      new: undefined,
      detail: undefined
    };
    states.list = this._generateStateEntityList(parentState, entity, states);
    states.detail = this._generateStateEntityDetail(parentState, entity, states);
    states.new = this._generateStateEntityNew(parentState, entity, states);
    return {
      state: states.list,
      states: states,
      menu: {
        title: entity.sidebarTitle || entity.pageTitle || `global.menu.${entity.name}`,
        icon: entity.icon,
        state: states.list.name,
        menu: undefined,
        visible: visible
      }
    };
  }

  public async loadStates(): Promise<LazyLoadResult> {
    let states: Array<StateDeclaration> = [];

    const servicePortals: IEntityService<IJsonPortal> = this._entityServiceBuilder.build<IJsonPortal>(ENTITY_NAME_PORTALS);
    const response: THttpQueryResponse<IJsonPortal> = await servicePortals.query({pesquisa: 'all=1'});
    for (const portal of response.body.list) {
      try {
        const portalStates = this.registerPortal(portal);
        states = states.concat(portalStates);
      } catch (exception: unknown) {
        this._logger.error(`Error a registar portal [${portal.name}].`, exception);
      }
    }

    // Filter out already registered states
    const registeredStates = this.states;
    states = states.filter((current: StateDeclaration) => isUndefined(registeredStates.get(current.name)));

    // Main state
    const userPortals: Array<StateDeclaration> = states.slice();
    states.unshift(this._mainPortalState(userPortals));

    return {states: states};
  }

  public portalState(): IPortalStateDeclaration {
    return {
      parent: STATE_NAME_SITE,
      // @ts-expect-error PortalComponent is an exception, all modules should extend ModuleComponent
      component: PortalComponent,
      data: {
        roles: [],
        requiredRoles: [],
        pluginsRoles: [],
        pageTitle: '',
        portalId: undefined,
        disableRecover: false,
        helperLinks: [],
        menu: []
      },
      resolve: [...STATE_RESOLVERS, RESOLVER_USER_PORTALS],
      onEnter: async (transition: Transition) => {
        const toState: StateObject = transition.$to();
        const injector: UIInjector = transition.injector();
        const launchMode: EAppLaunchMode = await injector.getAsync<EAppLaunchMode>(RESOLVER_LAUNCH_MODE.provide);
        if (launchMode !== EAppLaunchMode.Hybrid && launchMode !== EAppLaunchMode.HybridPartial) {
          const empresaService: EmpresaService = injector.get<EmpresaService>(EmpresaService);
          const needsConfiguration: boolean = await empresaService.checkStoreCompanyNeedsConfiguration();
          if (needsConfiguration) {
            const cgStateService: CGStateService = injector.get<CGStateService>(CGStateService);
            const state: StateDeclaration = cgStateService.getRedirectState({
              stateOrName: MODULE_NAME_EMPRESAS,
              exactMatch: true,
              portal: EPortal.CONFIGURACOES
            });
            if (state) {
              const targetState: TargetState = this._stateService.target(state);
              if (targetState.name() !== toState.name) {
                return targetState;
              }
            }
          }
        }
        const parent: StateObject = toState.parent;
        if (parent) {
          const configService: ConfigService = injector.get<ConfigService>(ConfigService);
          const configErpService: ConfigErpService = injector.get<ConfigErpService>(ConfigErpService);

          // Save last entered portal
          const portalName: string = parent.name;
          if (configService.configurations.portais.lastPortal !== portalName) {
            configService.setConfigurations((configurations: IWritableCGConfigurations) => {
              configurations.portais.lastPortal = portalName;
            });

            // Ignore promise, so it doesn't stall when transitioning between portals
            configErpService.save({...CONFIG_LAST_PORTAL, value: portalName}, false, false, {reportExceptions: false});
          }

          if (isObject(parent.data)) {
            const stateData: IPortalStateDeclarationData = parent.data;
            const portalId: string = stateData.portalId;
            if (portalId) {
              const authService: AuthService = injector.get<AuthService>(AuthService);
              const hasAnyAuthority: boolean = await authService.hasAuthority(portalId);
              if (!hasAnyAuthority) {
                const session: TUserSession = await authService.identity();
                const stateName: string = session.portais.length ? `${STATE_NAME_PORTAL}.${session.portais[0].url}` : STATE_NAME_NO_AUTHORITY;
                return this._stateService.target(stateName);
              }
            }
          }
        }
        return undefined;
      }
    };
  }

  public get states(): TStatesMap {
    return this._states;
  }

  private _generateStateEntityList(parentState: string, entity: IEntity, states: IPortalStates): ICGStateDeclaration {
    let url: string = entity.name;
    let urlSuffix = '';
    const paramSearch: string = entityStateListQueryParam(entity.name, EEntityStateListQueryParam.Search);
    const paramFilter: string = entityStateListQueryParam(entity.name, EEntityStateListQueryParam.Filter);
    const paramInitialFilter: string = entityStateListQueryParam(entity.name, EEntityStateListQueryParam.InitialFilter);
    const paramSkip: string = entityStateListQueryParam(entity.name, EEntityStateListQueryParam.Skip);
    const paramTake: string = entityStateListQueryParam(entity.name, EEntityStateListQueryParam.Take);
    const paramPage: string = entityStateListQueryParam(entity.name, EEntityStateListQueryParam.Page);
    const paramPerPage: string = entityStateListQueryParam(entity.name, EEntityStateListQueryParam.PerPage);
    const paramDataGridState: string = entityStateListQueryParam(entity.name, EEntityStateListQueryParam.DataGridState);
    const stateParams: ICGStateDeclaration['params'] = {};
    stateParams[paramSearch] = {type: <ParamType>ParamTypes.query, dynamic: true, squash: true, value: ''};
    stateParams[paramFilter] = {type: <ParamType>ParamTypes.query, dynamic: true, squash: true, value: ''};
    stateParams[paramInitialFilter] = {type: <ParamType>ParamTypes.query, dynamic: true, squash: true, value: ''};
    stateParams[paramSkip] = {type: <ParamType>ParamTypes.query, dynamic: true, squash: true, value: ''};
    stateParams[paramTake] = {type: <ParamType>ParamTypes.query, dynamic: true, squash: true, value: ''};
    stateParams[paramPage] = {type: <ParamType>ParamTypes.query, dynamic: true, squash: true, value: ''};
    stateParams[paramPerPage] = {type: <ParamType>ParamTypes.query, dynamic: true, squash: true, value: ''};
    stateParams[paramDataGridState] = {type: <ParamType>ParamTypes.any, dynamic: true, value: null};
    const state: ICGStateDeclaration = {
      component: ModuloEntityListComponent,
      url: undefined,
      name: `${parentState}.${entity.name}`,
      params: stateParams,
      data: {
        roles: entity.roles,
        pluginsRoles: entity.pluginsRoles,
        pageTitle: entity.list.pageTitle,
        _entityName: entity.name,
        _component: ModuloEntityListComponent
      },
      resolve: [...STATE_RESOLVERS, {provide: 'states', useFactory: () => states}, {provide: 'entity', useFactory: () => entity}]
    };
    if (entity.urlSuffix) {
      urlSuffix += entity.urlSuffix;
    }
    if (entity.list.helperLinks) {
      state.data.helperLinks = this._handleModuleHelperLinks(entity.list.helperLinks);
    }
    if (isObject(entity.list.state)) {
      if (entity.list.state.url) {
        url = entity.list.state.url;
      }
      if (entity.list.state.urlSuffix) {
        urlSuffix += entity.list.state.urlSuffix;
      }
      if (entity.list.state.component) {
        state.component = entity.list.state.component;
        state.data._component = state.component;
      }
      if (isObject(entity.list.state.data)) {
        state.data = merge({}, entity.list.state.data, state.data);
      }
      if (isArray(entity.list.state.resolve)) {
        this._concatResolve(state.resolve, entity.list.state.resolve);
      }
      if (entity.list.state.params) {
        state.params = merge({}, state.params, entity.list.state.params);
      }
      if (entity.list.state.redirectTo) {
        state.redirectTo = entity.list.state.redirectTo;
      }
    }
    if (url.startsWith('/')) {
      url = url.slice(1);
    }
    state.url = `/${url}?${paramSearch}&${paramFilter}&${paramInitialFilter}&${paramSkip}&${paramTake}&${paramPage}&${paramPerPage}`;
    if (urlSuffix) {
      state.url += `&${urlSuffix}`;
    }
    if (entity.helperLinks && !state.data.helperLinks) {
      state.data.helperLinks = this._handleModuleHelperLinks(entity.helperLinks);
    }
    this._internalRegisterState(state);
    return state;
  }

  private _generateStateEntityDetail(parentState: string, entity: IEntity, states: IPortalStates): ICGStateDeclaration {
    const stateType: string = entityStateDetailQueryParam(entity.name);
    const stateParams: ICGStateDeclaration['params'] = {
      id: {
        type: 'any',
        value: null,
        dynamic: false,
        squash: true
      },
      model: {
        type: 'json',
        value: null,
        dynamic: false
      }
    };
    stateParams[stateType] = {
      type: <ParamType>ParamTypes.query,
      dynamic: true,
      squash: false,
      value: EEntityStateDetailType.DETAIL
    };
    let url: string = entity.name;
    let urlSuffix = '';
    const state: ICGStateDeclaration = {
      component: ModuloEntityDetailComponent,
      url: undefined,
      name: entityStateNameDetail(`${parentState}.${entity.name}`),
      data: {
        roles: entity.roles,
        pluginsRoles: entity.pluginsRoles,
        pageTitle: entity.detail?.pageTitle,
        _entityName: entity.name,
        _component: ModuloEntityDetailComponent
      },
      resolve: [
        ...STATE_RESOLVERS,
        {provide: 'type', useValue: EEntityStateDetailType.DETAIL},
        {provide: 'states', useFactory: () => states},
        {provide: 'entity', useFactory: () => entity},
        {
          provide: 'model',
          deps: [EntityServiceBuilder, Transition],
          useFactory: async (entityServiceBuilder: EntityServiceBuilder, transition: Transition): Promise<unknown> => {
            const params: IEntityDetailParams = <IEntityDetailParams>transition.params();
            if (params.id || params.id === 0) {
              const service: IEntityService = entityServiceBuilder.build(entity.name);
              const requestConfig: TEntityServiceRequestData = {id: params.id, ...entity.detail?.state?.data?.getRequestConfig};
              let promise: TServiceResponse<unknown>;
              if (isObject(entity.serviceMethodsOverride)) {
                if (isString(entity.serviceMethodsOverride.get) && isFunction(service[entity.serviceMethodsOverride.get])) {
                  promise = service[entity.serviceMethodsOverride.get](requestConfig);
                } else if (isFunction(entity.serviceMethodsOverride.get)) {
                  promise = entity.serviceMethodsOverride.get(requestConfig);
                }
              }
              if (!promise) {
                promise = service.get(requestConfig);
              }
              try {
                const response: HttpResponse<unknown> = await Promise.resolve(promise);
                return response.body;
              } catch (reason: unknown) {
                return reason;
              }
            }
            return undefined;
          }
        }
      ],
      redirectTo: async (transition: Transition): Promise<TargetState> => {
        const model: unknown | HttpErrorResponse = await transition.injector().getAsync('model');
        if (model instanceof HttpErrorResponse) {
          const params: IEntityDetailParams = {id: undefined};
          const id: string | number = (<IEntityDetailParams>transition.params()).id;
          if (id || id === 0) {
            params.model = {};
            entity.setId(params.model, id);
          }
          params[stateType] = EEntityStateDetailType.NEW;
          const stateNewName: string = entityStateNameNew(`${parentState}.${entity.name}`);
          return transition.router.stateService.target(stateNewName, params);
        }
        return undefined;
      },
      params: stateParams
    };
    if (entity.urlSuffix) {
      urlSuffix += entity.urlSuffix;
    }
    if (isObject(entity.detail)) {
      if (entity.detail.helperLinks) {
        state.data.helperLinks = this._handleModuleHelperLinks(entity.detail.helperLinks);
      }
      if (isObject(entity.detail.state)) {
        if (entity.detail.state.url) {
          url = entity.detail.state.url;
        }
        if (entity.detail.state.urlSuffix) {
          urlSuffix += entity.detail.state.urlSuffix;
        }
        if (entity.detail.state.component) {
          state.component = entity.detail.state.component;
          state.data._component = state.component;
        }
        if (isObject(entity.detail.state.data)) {
          state.data = merge({}, entity.detail.state.data, state.data);
        }
        if (isArray(entity.detail.state.resolve)) {
          this._concatResolve(state.resolve, entity.detail.state.resolve);
        }
        if (entity.detail.state.params) {
          state.params = merge({}, state.params, entity.detail.state.params);
        }
        if (entity.detail.state.redirectTo) {
          const fnStateRedirectTo: (transition: Transition) => RedirectToResult = <(transition: Transition) => RedirectToResult>state.redirectTo;
          state.redirectTo = (transition: Transition) => {
            let redirectTo: unknown = entity.detail.state.redirectTo;
            if (isFunction(redirectTo)) {
              redirectTo = redirectTo(transition);
            }
            return Promise.resolve(redirectTo).then((redirectToResult: RedirectToResult) => {
              if (isDefinedNotNull(redirectToResult)) {
                return redirectToResult;
              }
              return fnStateRedirectTo(transition);
            });
          };
        }
      }
    }
    if (url.startsWith('/')) {
      url = url.slice(1);
    }
    state.url = `/${url}/:id?${stateType}`;
    if (urlSuffix) {
      state.url += `&${urlSuffix}`;
    }
    if (entity.helperLinks && !state.data.helperLinks) {
      state.data.helperLinks = this._handleModuleHelperLinks(entity.helperLinks);
    }
    this._internalRegisterState(state);
    return state;
  }

  private _generateStateEntityNew(parentState: string, entity: IEntity, states: IPortalStates): ICGStateDeclaration {
    const state: ICGStateDeclaration = copy<ICGStateDeclaration>(states.detail);
    const stateType: string = entityStateDetailQueryParam(entity.name);
    let url: string = entity.name;
    let urlSuffix = '';
    state.name = entityStateNameNew(`${parentState}.${entity.name}`);
    const typeResolver: ProviderLike = state.resolve.find((resolver: ProviderLike) => resolver.provide === 'type');
    if (typeResolver) {
      typeResolver.useValue = 'new';
    }
    state.params[stateType].value = EEntityStateDetailType.NEW;
    if (entity.urlSuffix) {
      urlSuffix += entity.urlSuffix;
    }
    if (isObject(entity.new)) {
      if (entity.new.helperLinks) {
        state.data.helperLinks = this._handleModuleHelperLinks(entity.new.helperLinks);
      }
      if (isObject(entity.new.state)) {
        if (entity.new.state.url) {
          url = entity.new.state.url;
        }
        if (entity.new.state.urlSuffix) {
          urlSuffix += entity.new.state.urlSuffix;
        }
        if (entity.new.state.component) {
          state.component = entity.new.state.component;
          state.data._component = state.component;
        }
        if (isObject(entity.new.state.data)) {
          if (entity.new.state.data.helperLinks) {
            entity.new.state.data.helperLinks = this._handleModuleHelperLinks(entity.new.state.data.helperLinks);
          }
          state.data = merge({}, entity.new.state.data, state.data);
        }
        if (isArray(entity.new.state.resolve)) {
          this._concatResolve(state.resolve, entity.new.state.resolve);
        }
        if (entity.new.state.params) {
          state.params = merge({}, state.params, entity.new.state.params);
        }
        if (entity.new.state.redirectTo) {
          state.redirectTo = entity.new.state.redirectTo;
        }
      }
    }
    if (url.startsWith('/')) {
      url = url.slice(1);
    }
    state.url = `/${url}_${EEntityStateDetailType.NEW}${urlSuffix}`;
    if (entity.helperLinks && !state.data.helperLinks) {
      state.data.helperLinks = this._handleModuleHelperLinks(entity.helperLinks);
    }
    this._internalRegisterState(state);
    return state;
  }

  private _internalRegisterState(state: ICGStateDeclaration): this {
    if (this._states.has(state.name)) {
      return this;
    }
    if (!isObject(state.data)) {
      state.data = {};
    }
    if (isArray(state.data.roles) && state.data.roles.length) {
      state.data.roles.unshift(ROLE.API);
    }
    // this._handlePluginRoles(state);
    this._states.set(state.name, state);
    this._stateRegistry.register(state);
    return this;
  }

  private _mainPortalState(states: Array<StateDeclaration>): ICGStateDeclaration {
    const state: ICGStateDeclaration = this.portalState();
    state.name = STATE_NAME_PORTAL;
    state.url = `/${STATE_NAME_PORTAL}`;
    state.redirectTo = async (transition: Transition): Promise<RedirectToResult> => {
      const injector: UIInjector = transition.injector();
      const authService: AuthService = injector.get<AuthService>(AuthService);

      const user: IJsonUserLogin = await authService.identity().catch(() => undefined);
      if (!user) {
        this._lastUser = undefined;
        return STATE_NAME_LOGIN;
      }

      // In case the user changed
      if (!this._lastUser || (this._lastUser !== user && states.length)) {
        let doDefaultRedirect = true;

        const configService: ConfigService = injector.get<ConfigService>(ConfigService);
        const configurations: ICGConfigurations = await firstValueFrom(configService.configurationsAsObservable().pipe(take(1)));
        if (configurations.portais.lastPortal) {
          // Find last portal
          const lastPortal: StateDeclaration = states.find((stateDeclaration: StateDeclaration) => stateDeclaration.name === configurations.portais.lastPortal);
          if (lastPortal) {
            const hasAuthority: boolean = sessionHasAuthority(user, lastPortal.data.roles);
            if (hasAuthority) {
              this._redirectTo = lastPortal.name;
              doDefaultRedirect = false;
            }
          }
        }

        if (doDefaultRedirect) {
          this._redirectTo = states.find((portalState: StateDeclaration) => {
            if (portalState.data) {
              const hasAuthority: boolean = sessionHasAuthority(user, portalState.data.roles);
              if (hasAuthority) {
                return true;
              }
            }
            return false;
          })?.name;
        }
      }

      this._lastUser = user;

      if (this._redirectTo) {
        return this._redirectTo;
      }

      const isAdmin: boolean = sessionHasAuthority(user, ROLE.ADMIN);
      return isAdmin ? STATE_NAME_ADMIN : STATE_NAME_NO_AUTHORITY;
    };
    return state;
  }

  private _concatResolve(original: Array<ProviderLike>, toConcat: Array<ProviderLike>): void {
    for (const providerLike of toConcat) {
      const indexInOriginal = original.findIndex((resolver: ProviderLike) => resolver.provide === providerLike.provide);
      if (indexInOriginal > -1) {
        original.splice(indexInOriginal, 1, providerLike);
      } else {
        original.push(providerLike);
      }
    }
  }

  private _handleModuleHelperLinks(helperLinkOrLinks: IModuleHelperLink | Array<IModuleHelperLink>): Array<IModuleHelperLink> {
    if (!helperLinkOrLinks) {
      return undefined;
    }
    const helpersLinks: Array<IModuleHelperLink> = isArray(helperLinkOrLinks) ? helperLinkOrLinks : [helperLinkOrLinks];
    return helpersLinks.filter((helperLink: IModuleHelperLink) => Boolean(helperLink.caption) && Boolean(helperLink.href));
  }

  // @ts-expect-error testing private method
  private _handlePluginRoles(state: ICGStateDeclaration): void {
    if ((!isArray(state.data.pluginsRoles) || !state.data.pluginsRoles.length) && (!isArray(state.data.requiredRoles) || !state.data.requiredRoles.length)) {
      return;
    }
    const deRegisterFn: Function = this._transitionService.onBefore({from: '**', to: state.name}, async (transition: Transition): Promise<TargetState> => {
      const to: ICGStateDeclaration = <ICGStateDeclaration>transition.to();
      const session: TUserSession = await transition.injector().getAsync<TUserSession>('session');
      if (
        (isArray(to.data.requiredRoles) && to.data.requiredRoles.length > 0 && !sessionHasAuthority(session, to.data.requiredRoles)) ||
        (isArray(to.data.pluginsRoles) && to.data.pluginsRoles.length > 0 && !sessionHasAnyAuthority(session, to.data.pluginsRoles))
      ) {
        const params: IBlockedPluginModuleStateParams = {pageTitle: to.data.pageTitle, requiredRoles: to.data.requiredRoles, pluginRoles: to.data.pluginsRoles};
        return this._cgStateService.targetState({stateOrName: MODULE_NAME_BLOCKED_PLUGIN, params: params});
      }
      return undefined;
    });
    this._registeredHooks.add(deRegisterFn);
  }
}
