import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {IJsonConfigRHGozoFerias} from '../../jsonRHConfiguracoes.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RHConfiguracoesService} from '../../rhConfiguracoes.module.service';

@Component({
  selector: 'module-rh-gozo-ferias',
  templateUrl: './rhGozoFerias.module.component.html'
})
export class RHGozoFeriasModuleComponent extends ModuloComponent implements OnInit {
  @Input() public model: IJsonConfigRHGozoFerias;

  public readonly outputAbdes: string;
  public readonly outputMotReg: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhConfiguracoesService: RHConfiguracoesService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.outputAbdes = '{{nCodABDESC}} - {{designacaoBreve}}';
    this.outputMotReg = '{{nMotivoMov}} - {{designaCompl}}';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.model) {
      this.model = {
        usaMarcacaoGozoFerias: false,
        nCodAbdesDescontoGozoFeriasAnoAtual: '',
        nomeNCodAbdesDescontoGozoFeriasAnoAtual: '',
        nCodAbdesDescontoGozoFeriasAnoAnterior: '',
        nomeNCodAbdesDescontoGozoFeriasAnoAnterior: '',
        motivoRegistoFerias: '',
        nomeMotivoRegistoFerias: ''
      };
    }
    this.btnSave.click = this._saveGozoFerias.bind(this);
    this.btnSave.visible = true;
    this.toolbar.addButton({
      order: this.btnSave.order + 1,
      id: 'btnRepor',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-refresh"></i>',
      class: 'btn-light',
      caption: 'rhintegracaosalarios.btn.repor',
      click: this._reporGozoFerias.bind(this)
    });
  }

  private _saveGozoFerias(): Promise<void> {
    return this._rhConfiguracoesService.updateConfigGozoFerias(this.model).then((response: HttpResponse<IJsonConfigRHGozoFerias>) => {
      this.model = response.body;
      this._plAlertService.success('rhintegracaosalarios.message.savedsuccessfully');
    });
  }

  private _reporGozoFerias(): Promise<void> {
    return this._rhConfiguracoesService.getConfigGozoFerias().then((response: HttpResponse<IJsonConfigRHGozoFerias>) => {
      this.model = response.body;
      this._plAlertService.success('rhintegracaosalarios.message.resetsuccessfully');
    });
  }
}
