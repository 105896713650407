import {FimPeriodoEncEditComponent} from './components/edit/fimPeriodoEnc.entity.edit.component';
import {FimPeriodoEncListComponent} from './components/list/fimPeriodoEnc.entity.list.component';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_FIM_PERIODO_ENC, IFimPeriodoEncEntity} from './fimPeriodoEnc.entity.interface';
import {IJsonPeriodo} from '../periodos/jsonPeriodo.entity.interface';

export const ENTITY_FIM_PERIODO_ENC: IFimPeriodoEncEntity = {
  name: ENTITY_NAME_FIM_PERIODO_ENC,
  roles: [ROLE.CONTABILIDADE],
  searchPlaceholder: 'fimperiodoenc.pesquisa',
  metadata: {
    keyName: 'periodo',
    fields: [
      {
        name: 'periodo',
        caption: 'fimperiodoenc.fields.periodo',
        entity: {name: 'periodos', keyTarget: 'periodo', outputTarget: 'nomePeriodo', output: 'periodo', outputKey: 'periodo', outputDescription: 'nome'}
      },
      {name: 'nomePeriodo', caption: 'fimperiodoenc.fields.nomePeriodo', properties: {readonly: true}},
      {name: 'flagRigorAbsoluto', type: 'boolean', caption: 'fimperiodoenc.fields.flagRigorAbsoluto'},
      {name: 'observacoes', caption: 'fimperiodoenc.fields.observacoes', placeholder: 'fimperiodoenc.placeholders.observacoes', validators: {maxlength: 50}}
    ],
    order: 'periodo'
  },
  actions: {
    new: true,
    detail: false,
    edit: true,
    delete: false,
    search: true,
    filter: true
  },
  list: {
    state: {
      component: FimPeriodoEncListComponent
    }
  },
  detail: {
    state: {
      component: FimPeriodoEncEditComponent
    }
  },
  service: function () {
    this.postPerioDisponivel = (listperios: Array<IJsonPeriodo>, periodo: string) => {
      return this.post({url: 'periododisponivel', body: listperios, params: {periodo: periodo}});
    };
  }
};
