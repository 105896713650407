import {TDate} from '../../../../common/dates';
import {ROLE} from '../../../services/role.const';

export enum ERadioFiltrarPesquisa {
  Empregado,
  Outros,
  Ambos
}

export interface IFiltersImpde119 {
  filtrarpesquisa: ERadioFiltrarPesquisa;
  dedata: TDate;
  atedata: TDate;
  decodemp: number;
  atecodemp: number;
  dencontribuinte: string;
  atencontribuinte: string;
  listaempresas: string;
  reportname: string;
}

export interface IFiltrarPesquisa {
  value: ERadioFiltrarPesquisa;
  label: string;
  disabled: boolean;
  validRoles: Array<ROLE>;
}

export const MODULE_NAME_IMPDE119 = 'impde119';
