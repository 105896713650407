<div class="ativosimpressao entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="pesquisar" plPromise>
        <pl-group>
          <pl-group>
            <label [translate]="'ativosimpressao.header.codAtivoDe'"></label>
            <edit class="inputs-text-inline">
              <entity-autocomplete entity="ativos" attrName="codAtivoDe" (selectedKeyChange)="header.codAtivoDe = $event" [output]="ativoOutput"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit class="inputs-text-inline">
              <entity-autocomplete entity="ativos" attrName="codAtivoAte" [model]="ativosAte" (selectedKeyChange)="header.codAtivoAte = $event" [output]="ativoOutput"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'ativosimpressao.header.dataAquisicaoDe'"></label>
            <edit class="inputs-text-inline">
              <pl-edit type="date" [(model)]="header.dataAquisicaoDe" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit class="inputs-text-inline">
              <pl-edit type="date" [(model)]="header.dataAquisicaoAte" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'ativosimpressao.header.nContaDe'"></label>
            <edit>
              <entity-autocomplete entity="pocs" attrName="nContaDe" [model]="contaDe" (selectedKeyChange)="header.nContaDe = $event"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="pocs" attrName="nContaAte" [model]="contaAte" (selectedKeyChange)="header.nContaAte = $event"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'ativosimpressao.header.nCCustoDe'"></label>
            <edit>
              <entity-autocomplete entity="ccustos" attrName="nCCustoDe" (selectedKeyChange)="header.nCCustoDe = $event" [output]="ccustoOutput" [filter]="'encerrado=0'"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="ccustos" attrName="nCCustoAte" [model]="cCustoAte" (selectedKeyChange)="header.nCCustoAte = $event" [output]="ccustoOutput" [filter]="'encerrado=0'">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'ativosimpressao.header.codAmortDe'"></label>
            <edit>
              <entity-autocomplete
                entity="amortizacao"
                attrName="codAmortDe"
                [model]="amortizacaoDe"
                (selectedKeyChange)="header.codAmortDe = $event"
                [properties]="{validators: {maxlength: {value: 4}}}"
                [output]="amortizacaoOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="amortizacao"
                attrName="codAmortAte"
                [model]="amortizacaoAte"
                (selectedKeyChange)="header.codAmortAte = $event"
                [properties]="{validators: {maxlength: {value: 4}, required: {value: true}}}"
                [output]="amortizacaoOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'ativosimpressao.header.codMapaDe'"></label>
            <edit>
              <pl-edit type="mapasOficiais" [model]="mapaamortizacaoDe" (modelChange)="header.codMapaDe = $event"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit type="mapasOficiais" [model]="mapaamortizacaoAte" (modelChange)="header.codMapaAte = $event"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'ativosimpressao.header.datareferencia'"></label>
            <edit>
              <pl-edit type="date" [(model)]="header.dataReferencia" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group [properties]="{disabled: !header.abatido && !header.vendido}">
            <label [translate]="'ativosimpressao.header.dataAbateDe'"></label>
            <edit>
              <pl-edit type="date" [(model)]="header.dataAbateDe" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group [properties]="{disabled: !header.abatido && !header.vendido}">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit type="date" [(model)]="header.dataAbateAte" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group *ngIf="!isMobile"></pl-group>
        </pl-group>
        <div class="d-flex d-inline-block mt-2">
          <pl-group>
            <label [translate]="'ativosimpressao.header.situacao'"></label>
            <edit>
              <pl-edit type="checkbox" attrName="normal" [model]="header.normal" (modelChange)="header.normal = $event" [properties]="{label: 'ativosimpressao.radioOptions.normal'}"></pl-edit>
              <pl-edit type="checkbox" attrName="abatido" [model]="header.abatido" (modelChange)="header.abatido = $event" [properties]="{label: 'ativosimpressao.radioOptions.abatido'}"></pl-edit>
              <pl-edit type="checkbox" attrName="vendido" [model]="header.vendido" (modelChange)="header.vendido = $event" [properties]="{label: 'ativosimpressao.radioOptions.vendido'}"></pl-edit>
              <pl-edit type="checkbox" attrName="dividido" [model]="header.dividido" (modelChange)="header.dividido = $event" [properties]="{label: 'ativosimpressao.radioOptions.dividido'}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'ativosimpressao.header.estadoUso'"></label>
            <edit>
              <pl-edit type="radiogroup" attrName="estado" [model]="header.estadoUso" (modelChange)="header.estadoUso = $event" [properties]="radioGroupTemplateEstadoUso"></pl-edit>
            </edit>
          </pl-group>
        </div>
        <pl-group>
          <edit>
            <pl-button type="submit" klass="btn btn-sm btn-primary"><i class="fa fa-fw fa-search"></i><span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefenition"
    cgDxDataGridInstanceName="ativosImpressao"
    [dataSource]="ativosDepImpressao"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()"
    (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)">
    <div *dxTemplate="let headerInfo of 'headerTemplateSituacao'"><label [translate]="'ativosimpressao.table.sub.situacaoa'"></label> {{ header.dataReferencia | cgcDate }}</div>
  </dx-data-grid>
</div>
