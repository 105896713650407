import {ENTITY_NAME_SITPR} from './sitPr.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonSitPr} from './jsonSitPr.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_SITPR: IEntityDefinition<IJsonSitPr> = {
  name: ENTITY_NAME_SITPR,
  roles: [ROLE.RH],
  searchPlaceholder: 'situacaoprofissional.pesquisa',
  pageTitle: 'global.menu.situacaoprofissional',
  metadata: {
    keyName: 'nSitProfiss',
    fields: [
      {name: 'nSitProfiss', caption: 'situacaoprofissional.fields.nSitProfiss', validators: {required: true, maxlength: 4}},
      {name: 'designaBreve', caption: 'situacaoprofissional.fields.designaBreve', validators: {required: true, maxlength: 20}},
      {name: 'designaComp', caption: 'situacaoprofissional.fields.designaComp', validators: {maxlength: 40}},
      {name: 'codQuadroPessoal', caption: 'situacaoprofissional.fields.codQuadroPessoal', validators: {maxlength: 6}, visible: false},
      {
        name: 'nomeCodQuadroPessoal',
        caption: 'situacaoprofissional.fields.codQuadroPessoal',
        entity: {
          name: 'qpsit',
          keyTarget: 'codQuadroPessoal',
          outputTarget: 'nomeCodQuadroPessoal'
        }
      }
    ],
    order: 'nSitProfiss',
    searchFields: 'nSitProfiss,designaBreve,designaComp,nomeCodQuadroPessoal'
  },
  autocomplete: {
    rowTemplate: '{{nSitProfiss}} - {{designaBreve}}',
    output: 'designaBreve'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  }
};
