import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ApiService} from '../../../services/api/api.service';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {IATFaturasRecibosVerdesDateFilters, IATFaturasRecibosVerdesSituacao, IATFaturasRecibosVerdesTipo, IFRVImportDoc} from './atFaturasRecibosVerdes.module.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocContabilidade, IDocsContabilidadeEntityService} from '../docscontabilidade/docsContabilidade.interface';
import {IJsonDocContabilidade} from '../docscontabilidade/jsonDocContabilidade.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {TDate} from '../../../../common/dates';
import {
  EATFaturasRecibosVerdesSituacao,
  EATFaturasRecibosVerdesTipo,
  IJsonFRVConfig,
  IJsonFRVDocContabilidade,
  IJsonFRVImportDocApiList,
  IJsonFRVNIFConfig,
  IJsonFRVNIFConfigSave
} from './jsonATFaturasRecibosVerdes.interface';

@Injectable({
  providedIn: 'root'
})
export class AtFaturasRecibosVerdesService {
  private readonly _path: string;
  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this._path = `${this._apiService.path.restapi}/atFaturasRecibosVerdes`;
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>('docscontabilidade');
  }

  public getAtList(deData: TDate, ateData: TDate): TServiceResponse<IJsonFRVImportDocApiList> {
    return this._apiService.get<IJsonFRVImportDocApiList>({
      url: this._path,
      params: {
        deData: deData,
        ateData: ateData,
        tipo: undefined,
        situacao: undefined
      },
      reportExceptions: false
    });
  }

  public getAtTiposList(): TServiceResponse<Array<IATFaturasRecibosVerdesTipo>> {
    return this._apiService.get<Array<IATFaturasRecibosVerdesTipo>>({url: `${this._path}/tiposList`});
  }

  public getAtSituacaoList(): TServiceResponse<Array<IATFaturasRecibosVerdesSituacao>> {
    return this._apiService.get<Array<IATFaturasRecibosVerdesSituacao>>({url: `${this._path}/situacaoList`});
  }

  public getExtratoXlsUrl(deData: TDate, ateData: TDate, tipo: EATFaturasRecibosVerdesTipo, situacao: EATFaturasRecibosVerdesSituacao): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/exportToXls`, {
      deData: deData,
      ateData: ateData,
      tipo: tipo,
      situacao: situacao
    });
  }

  public getDocDetailsContabilidade(extPocCabID: string, ano: number = -1): Promise<IDocContabilidade> {
    const contabEnt = this._entityServiceBuilder.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    return this._apiService
      .get<IDocContabilidade>({
        url: `${this._apiService.path.restapi}/${contabEnt.entity.name}/${extPocCabID}`,
        params: {ano: ano}
      })
      .then((response) => response.body);
  }

  public ignorarRecibo(nif: string, numerodocumento: number): TServiceResponse<void> {
    return this._apiService.put<void>({url: `${this._path}/ignorarRecibo?nif=${nif}&numerodocumento=${numerodocumento}`});
  }

  public ignorarSempreNif(nif: string, nome: string): TServiceResponse<void> {
    return this._apiService.put<void>({url: `${this._path}/ignorarPorNif?nif=${nif}&nome=${nome}`});
  }

  public deixarIgnorarRecibo(nif: string, numerodocumento: number): TServiceResponse<unknown> {
    return this._apiService.delete({url: `${this._path}/deixarIgnorarRecibo?nif=${nif}&numerodocumento=${numerodocumento}`});
  }

  public deixarIgnorarNif(nif: string): TServiceResponse<unknown> {
    return this._apiService.delete({url: `${this._path}/deixarIgnorarNif?nif=${nif}`});
  }

  public obterContaDoNif(nif: string): TServiceResponse<string> {
    return this._apiService.get<string>({url: `${this._path}/obterContaDoNIF/${nif}`});
  }

  public getDocContabilidade(extPocCabID: string, ano?: number): TServiceResponse<IDocContabilidade> {
    return this._serviceDocsContabilidade.get({id: extPocCabID, params: {ano: ano || -1}});
  }

  public getDocContabilidadePredefinido(item: IFRVImportDoc): TServiceResponse<IJsonFRVDocContabilidade> {
    return this._apiService.post<IJsonFRVDocContabilidade, IFRVImportDoc>({
      url: `${this._path}/docContabilidade`,
      body: item
    });
  }

  public getDatasSegundoNPeriodo(nperiodo: string, onlyPeriodMonth: boolean = false): Promise<IATFaturasRecibosVerdesDateFilters> {
    return this._apiService
      .get<IATFaturasRecibosVerdesDateFilters>({
        url: `${this._path}/${nperiodo}/getDatasSegundoNPeriodo`,
        params: {onlyPeriodMonth: onlyPeriodMonth}
      })
      .then((response: HttpResponse<IATFaturasRecibosVerdesDateFilters>) => response.body);
  }

  public lancarDocsEmSerie(list: Array<IFRVImportDoc>): Promise<Array<IFRVImportDoc>> {
    return this._apiService.put<Array<IFRVImportDoc>>({url: `${this._path}/lancarDocsEmSerie`, body: list}).then((response: HttpResponse<Array<IFRVImportDoc>>) => response.body);
  }

  public reprocessEFaturaDoc(doc: IFRVImportDoc): TServiceResponse<IFRVImportDoc> {
    return this._apiService.post<IFRVImportDoc>({
      url: `${this._path}/reprocessEfaturaDoc`,
      body: doc
    });
  }

  public getConfig(): Promise<IJsonFRVConfig> {
    return this._apiService.get<IJsonFRVConfig>({url: `${this._path}/getConfig`}).then((response: HttpResponse<IJsonFRVConfig>) => response.body);
  }

  public saveConfig(predefinidoId: number): TServiceResponse<void> {
    return this._apiService.post<void, number>({url: `${this._path}/saveConfig`, body: predefinidoId});
  }

  public getNifConfig(nifAdquirente: string): Promise<IJsonFRVNIFConfig> {
    return this._apiService.get<IJsonFRVNIFConfig>({url: `${this._path}/getNifConfig`, params: {nifAdquirente: nifAdquirente}}).then((response: HttpResponse<IJsonFRVNIFConfig>) => response.body);
  }

  public saveNifConfig(config: IJsonFRVNIFConfigSave): TServiceResponse<void> {
    return this._apiService.post<void, IJsonFRVNIFConfigSave>({url: `${this._path}/saveNifConfig`, body: config});
  }

  public getReciboPDFURL(numDocumento: number): string {
    return `${this._path}/pdf/${numDocumento}`;
  }
}
