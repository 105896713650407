<pl-form class="utl-viat-patronal-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'utlviatpatronal.modal.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <pl-group>
        <label [translate]="'utlviatpatronal.modal.fields.tipoProcessamento'"></label>
        <edit>
          <entity-autocomplete
            entity="rhtipoprocess"
            attrName="tipoProcessamento"
            [model]="utlViatPatronal"
            (selectedKeyChange)="utlViatPatronal.tipoProcessamento = $event"
            (evtSelectedDescriptionChanged)="utlViatPatronal.rhTipoProce = $event"
            [fieldsMap]="{tipoProcessamento: 'tipoProcessamento', descricao: 'rhTipoProce'}"
            [properties]="{validators: {required: {value: true}}}"
            [output]="rhtipoprocessOutput"
            plAutoFocus>
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'utlviatpatronal.modal.fields.codABDESC'"></label>
        <edit>
          <entity-autocomplete
            entity="abonos"
            attrName="codABDESC"
            [model]="utlViatPatronal"
            (selectedKeyChange)="utlViatPatronal.codABDESC = $event"
            (evtSelectedDescriptionChanged)="utlViatPatronal.abdesNome = $event"
            [filter]="filterAbonoCategOutput"
            [fieldsMap]="{codAbDesc: 'codABDESC', designacaoBreve: 'abdesNome'}"
            [properties]="{validators: {required: {value: true}}}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <label [translate]="'utlviatpatronal.modal.fields.codEmp'"></label>
      <edit>
        <entity-autocomplete
          entity="dgempsfull"
          attrName="codEmp"
          [model]="utlViatPatronal"
          (selectedKeyChange)="utlViatPatronal.codEmp = $event"
          (evtSelectedDescriptionChanged)="utlViatPatronal.nome = $event"
          [properties]="{validators: {required: {value: true}}}"
          [filter]="'ativo=true'"
          output="nome">
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'utlviatpatronal.modal.fields.anoProcessamento'"></label>
        <edit>
          <pl-edit
            type="cginteger"
            attrName="anoProcessamento"
            [model]="utlViatPatronal.anoProcessamento"
            (modelChange)="utlViatPatronal.anoProcessamento = $event"
            [properties]="{validators: {required: {value: true}}}">
          </pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'utlviatpatronal.modal.fields.mesInicioProce'"></label>
        <edit>
          <pl-edit type="meses" attrName="mesInicioProce" [model]="utlViatPatronal.mesInicioProce" (modelChange)="utlViatPatronal.mesInicioProce = $event"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'utlviatpatronal.modal.fields.suspende'"></label>
        <edit>
          <pl-edit type="boolean" attrName="suspende" [model]="utlViatPatronal.suspende" (modelChange)="suspendeChanged($event)"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'global.text.date'"></label>
        <edit>
          <pl-edit type="date" attrName="dataSuspensao" [(model)]="utlViatPatronal.dataSuspensao" [properties]="{disabled: !utlViatPatronal.suspende}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'utlviatpatronal.modal.fields.obsSupencao'"></label>
        <edit>
          <pl-edit type="text" attrName="obsSupencao" [(model)]="utlViatPatronal.obsSupencao" [properties]="{disabled: !utlViatPatronal.suspende}"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'utlviatpatronal.modal.fields.vAquisicaoViatura'"></label>
        <edit>
          <pl-edit
            type="number"
            attrName="vAquisicaoViatura"
            [model]="utlViatPatronal.vAquisicaoViatura"
            (modelChange)="utlViatPatronal.vAquisicaoViatura = $event; valorViaturaChanged()"
            [properties]="{validators: {required: {value: true}}, modelOptions: {debounce: 500}}">
          </pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'utlviatpatronal.modal.fields.vMercadoViatura'"></label>
        <edit>
          <pl-edit
            type="number"
            attrName="vMercadoViatura"
            [model]="utlViatPatronal.vMercadoViatura"
            (modelChange)="utlViatPatronal.vMercadoViatura = $event; valorViaturaChanged()"
            [properties]="{validators: {required: {value: true}}, modelOptions: {debounce: 500}}">
          </pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <label [translate]="'utlviatpatronal.modal.fields.obs'"></label>
      <edit>
        <pl-edit type="text" attrName="observacoes" [(model)]="utlViatPatronal.observacoes"></pl-edit>
      </edit>
    </pl-group>

    <fieldset>
      <legend [translate]="'utlviatpatronal.modal.fields.simulacaodesc'"></legend>
      <pl-group>
        <pl-group>
          <label [translate]="'utlviatpatronal.modal.fields.valorIncidenciaSS'"></label>
          <edit>
            <pl-edit type="number" attrName="valorIncidenciaSS" [(model)]="utlViatPatronal.valorIncidenciaSS" [properties]="{disabled: true}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'utlviatpatronal.modal.fields.valorIncidenciaIRS'"></label>
          <edit>
            <pl-edit type="number" attrName="valorIncidenciaIRS" [(model)]="utlViatPatronal.valorIncidenciaIRS" [properties]="{disabled: true}"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>
    </fieldset>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</pl-form>
