<div class="contabdigital-tree-docs-nao-classificados-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'arquivodigital.gestaodocsdigitalizados.messages.modal.treedocsnaoclassificados.escolhapastaadd'"></h5>
  </div>

  <div class="modal-body">
    <ng-container *ngIf="error">
      <pl-alert type="error">{{ error }}</pl-alert>
      <hr />
    </ng-container>

    <contabilidadedigital-treeviewer
      [folderID]="defaultFolderId"
      [folderIdAsUpwards]="folderIdAsUpwards"
      [upwardsUntilFolderId]="upwardsUntilFolderId"
      [showFiles]="false"
      [anoEmCursoIRC]="anoEmCursoIRC"
      [advancedDescription]="true"
      [loading]="loading"
      [disableActions]="changeFolderFile"
      [disableActionDeleteDocs]="true"
      [disableActionDeleteDoc]="true"
      (evtFolderChanged)="changedFolder($event)"
      (evtActionAddDoc)="close()"
      (evtFolderDoubleClicked)="close()"
      (evtResponseErrored)="responseErrored($event)">
    </contabilidadedigital-treeviewer>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-primary" (click)="close()" [disabled]="loading || !canAddFileToFolder">
      <i class="fa fa-fw fa-check"></i>&nbsp;
      <span [translate]="!changeFolderFile ? 'global.btn.ok' : 'arquivodigital.gestaodocsdigitalizados.btn.alterapasta'"></span>
    </button>

    <button type="button" class="btn btn-light" [disabled]="closeDisabled" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
  </div>
</div>
