import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonQQPais} from './jsonQQPais.entity.interface';
import {ENTITY_NAME_QPPAISES} from './qpPaises.entity.interface';

export const ENTITY_QPPAISES: IEntityDefinition<IJsonQQPais> = {
  name: ENTITY_NAME_QPPAISES,
  roles: [ROLE.RH],
  searchPlaceholder: 'qppaises.pesquisa',
  metadata: {
    keyName: 'codPais',
    fields: [
      {name: 'codPais', type: 'string', width: '120px', caption: 'qppaises.fields.codPais', validators: {required: true, maxlength: 4}},
      {name: 'name', caption: 'qppaises.fields.name', validators: {required: true, maxlength: 20}},
      {name: 'abrev', caption: 'qppaises.fields.abrev', validators: {required: true, maxlength: 40}}
    ],

    order: 'codPais',
    searchFields: 'codPais,name',
    listFields: 'codPais,name,abrev',
    detailFields: 'codPais,name,abrev'
  },
  autocomplete: {
    rowTemplate: '{{codPais}} - {{name}}',
    output: 'name',
    searchFields: 'codPais,name'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    search: true,
    delete: false,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.RH],
        pageTitle: 'global.menu.qppaises'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.RH],
        pageTitle: 'global.menu.qppaises'
      }
    }
  },
  new: {
    state: {
      data: {
        roles: [ROLE.RH],
        pageTitle: 'global.menu.qppaises'
      }
    }
  }
};
