import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from '../../../../services/api/api.service';
import {buildSessionUrlWithParams} from '../../../../../common/utils/utils';
import {IListagemDiariosModel} from './listagemDiarios.module.interface';

@Injectable({
  providedIn: 'root'
})
export class ListagemDiariosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/dadoscontabilidade`;
  }

  public getListagemDiarioUrl(listagemDiarios: IListagemDiariosModel): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/listagemdiario`, listagemDiarios);
  }
}
