export enum EAmaliaArticleType {
  Faq = 'FAQ'
}

export enum EAmaliaIntentType {
  Intent = 'INTENT',
  Welcome = 'WELCOME',
  Fallback = 'FALLBACK'
}

export enum EAmaliaMessageChannel {
  Chat = 'CHAT'
}

export enum EAmaliaMessageFrom {
  Client = 'CLIENT',
  Agent = 'AGENT'
}

export enum EAmaliaMessageBodyFaqFormat {
  Text = 'TEXT'
}

export type TAmaliaMessageBody = IAmaliaMessageBodyFaq | IAmaliaMessageBodyIntent | IAmaliaMessageBodyText | IAmaliaMessageBodyUnknown;

export interface IAmaliaIntentRead {
  id: number;
  intent: string;
  description: string;
  tip: string;
  type: EAmaliaIntentType;
  parameters: Array<string>;
  responses: Array<string>;
  module: string;
  similarity: number;
}

export interface IAmaliaMessage {
  messageId: string;
  parentId: string;
  timestamp: string;
  roomId: string;
  userId: string;
  channel: EAmaliaMessageChannel;
  from: EAmaliaMessageFrom;
  body: Array<TAmaliaMessageBody>;
}

export interface IAmaliaMessageBodyFaq {
  title: string;
  format: EAmaliaMessageBodyFaqFormat;
  source: string;
  externalId: string;
}

export interface IAmaliaMessageBodyIntent {
  message: string;
  intent: string;
  parameters: object;
}

export interface IAmaliaMessageBodyText {
  message: string;
}

export interface IAmaliaMessageBodyUnknown {
  message: string;
}

export interface IAmaliaMessageTextWrite {
  messageId: string;
  userId: string;
  roomId: string;
  body: IAmaliaMessageTextWriteBody;
}

export interface IAmaliaMessageTextWriteBody {
  type: EAmaliaMessageBodyFaqFormat;
  message: string;
}

export interface IAmaliaSimilarArticles {
  articleId1: number;
  articleId2: number;
  similarity: number;
}
