import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_TRANSMISSAO_PASSIVA} from './tipoTransmissaoPassiva.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPO_TRANSMISSAO_PASSIVA: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_TRANSMISSAO_PASSIVA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'tipoTransmissaoPassiva.data.notApplicable'},
    {value: 1, name: 'tipoTransmissaoPassiva.data.acquisitionTotalTaxDeductionOthers'},
    {value: 2, name: 'tipoTransmissaoPassiva.data.acquisitionPartialDeductionVATOthers'},
    {value: 3, name: 'tipoTransmissaoPassiva.data.acquisitionPartialDeductionVAT'},
    {value: 4, name: 'tipoTransmissaoPassiva.data.acquisitionPartiallyTotallyDeductibleDeductionTourismVehicles'},
    {value: 5, name: 'tipoTransmissaoPassiva.data.acquisitionGasolineFullyNonDeductibleTax'},
    {value: 6, name: 'tipoTransmissaoPassiva.data.acquisitionTourismTravelTotallyNonDeductibleTaxAcquisition'},
    {value: 7, name: 'tipoTransmissaoPassiva.data.acquisitionTourismTravelFullyNonDeductibleTaxRentOtherExpenses'},
    {value: 8, name: 'tipoTransmissaoPassiva.data.acquisitionGoodsServicesTotallyNonDeductibleTaxOther'},
    {value: 9, name: 'tipoTransmissaoPassiva.data.acquisitionTaxExemptGoodsServices'},
    {value: 10, name: 'tipoTransmissaoPassiva.data.acquisitionGoodsServicesCoveredSchemeRetailingPetitioners'},
    {value: 11, name: 'tipoTransmissaoPassiva.data.acquisitionExemptionVATSubmissionIMT'},
    {value: 12, name: 'tipoTransmissaoPassiva.data.acquisitionEstablishment'},
    {value: 13, name: 'tipoTransmissaoPassiva.data.otherCIVAOutFieldLiabilities'},
    {value: 14, name: 'tipoTransmissaoPassiva.data.acquisitionCivilConstructionServices'},
    {value: 15, name: 'tipoTransmissaoPassiva.data.acquisitionScrap'},
    {value: 16, name: 'tipoTransmissaoPassiva.data.intraCommunityAcquisitionExemptOrigin'},
    {value: 17, name: 'tipoTransmissaoPassiva.data.acquisitionCEESubjectOrigin'},
    {value: 18, name: 'tipoTransmissaoPassiva.data.importAcquisition'},
    {value: 19, name: 'tipoTransmissaoPassiva.data.mNAcquisitionForeignSupplierReverseCharge'},
    {value: 20, name: 'tipoTransmissaoPassiva.data.mNAcquisitionEUResidentsReverseCharge'},
    {value: 21, name: 'tipoTransmissaoPassiva.data.goldAcquisitionReverseCharge'},
    {value: 22, name: 'tipoTransmissaoPassiva.data.acquisitionPropertiesWaiverExemptionReverseCharge'},
    {value: 23, name: 'tipoTransmissaoPassiva.data.emissionGreenhouseGases'},
    {value: 24, name: 'tipoTransmissaoPassiva.data.aquisicaoDeCorticaEOutrosProdutosDeOrigemSilvicola'}
  ]
});
