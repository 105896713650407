import {Component, Injector, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EAnalisadorTesOrigemSaldoInicial, IAnalisadorTesConfiguration} from '../../analisadorTes.module.interface';
import {AnalisadorTesService} from '../../analisadorTes.module.service';

@Component({
  selector: 'analisador-tesouraria-config-modal',
  templateUrl: './analisadorTes.config.modal.component.html'
})
export class AnalisadorTesConfigModalComponent extends CGModalComponent<boolean> implements OnInit {
  public model: IAnalisadorTesConfiguration;
  public promise: Promise<unknown>;
  public origemSaldoInicialSource: Array<{value: number; name: string}>;
  public readonly origemSaldoInicialEnum: typeof EAnalisadorTesOrigemSaldoInicial;

  constructor(
    protected readonly _injector: Injector,
    private readonly _analisadorTesService: AnalisadorTesService
  ) {
    super(_injector);
    this.origemSaldoInicialEnum = EAnalisadorTesOrigemSaldoInicial;
    this.model = {
      origemSaldoInicial: EAnalisadorTesOrigemSaldoInicial.MANUAL,
      origemSaldoInicialContabContaDe: '',
      origemSaldoInicialContabContaAte: '',
      origemSaldoInicialCGBankingContaDe: '',
      origemSaldoInicialCGBankingContaAte: '',
      importLotes: false,
      lotesRubricaPag: -1,
      lotesRubricaRec: -1
    };
    this.origemSaldoInicialSource = [
      {value: EAnalisadorTesOrigemSaldoInicial.MANUAL, name: 'analisadorTes.strings.manual'},
      {value: EAnalisadorTesOrigemSaldoInicial.CONTABILIDADE, name: 'analisadorTes.strings.contabilidade'},
      {value: EAnalisadorTesOrigemSaldoInicial.CGBANKING, name: 'analisadorTes.strings.cgBanking'}
    ];
  }

  public ngOnInit(): void {
    this.promise = this._analisadorTesService.getConfiguration().then((response) => {
      this.model = response.body;
    });
  }

  public async close(): Promise<void> {
    await this._analisadorTesService.saveConfiguration(this.model).then(() => {
      return super.close(false);
    });
  }
}
