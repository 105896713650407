import {DATA_SOURCE_NAME_MOEDA_NORMAS_ISO} from '../../../../datasources/moedanormasiso/moedaNormasIso.datasource.interface';
import {ENTITY_NAME_MOEDA, IMoedaEntity} from './moeda.entity.interface';
import {IJsonMoedaCambio, IJsonMoedaUpdateCambio} from './jsonMoeda.interface';
import {MoedaEditComponent} from './components/moeda.edit.component';
import {ROLE} from '../../../../services/role.const';

export const ENTITY_MOEDA: IMoedaEntity = {
  name: ENTITY_NAME_MOEDA,
  redirectKey: 'codMoeda',
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.FINANCEIRO],
  searchPlaceholder: 'moeda.pesquisa',
  metadata: {
    keyName: 'codMoeda',
    order: 'codMoeda',
    fields: [
      {name: 'codMoeda', type: 'integer', caption: 'moeda.fields.codMoeda'},
      {name: 'nomeMoeda', caption: 'moeda.fields.nomeMoeda'},
      {name: 'abreviaturaMoeda', type: DATA_SOURCE_NAME_MOEDA_NORMAS_ISO, caption: 'moeda.fields.abreviaturaMoeda'},
      {name: 'nDecimais', type: 'integer', caption: 'moeda.fields.nDecimais'},
      {name: 'extenso1Unidade', caption: 'moeda.fields.extenso1Unidade'},
      {name: 'extensoNUnidades', caption: 'moeda.fields.extensoNUnidades'},
      {name: 'extensoCentimos', caption: 'moeda.fields.extensoCentimos'}
    ]
  },
  autocomplete: {
    rowTemplate: '{{codMoeda}} - {{nomeMoeda}}',
    output: 'nomeMoeda',
    searchFields: 'codMoeda,nomeMoeda,abreviaturaMoeda,extenso1Unidade,extensoNUnidades,extensoCentimos'
  },
  actions: {
    new: true,
    edit: true,
    detail: true,
    delete: true,
    duplicate: false,
    search: true,
    filter: true
  },
  detail: {
    state: {
      component: MoedaEditComponent
    }
  },
  service: function () {
    this.updateCambio = (idMoedaCambio: number, cambio: IJsonMoedaUpdateCambio, forceUpdate: boolean) => {
      return this.put<IJsonMoedaCambio, IJsonMoedaUpdateCambio>({url: `updatecambio/${idMoedaCambio}`, body: cambio, params: {forceUpdate: forceUpdate}});
    };
  }
};
