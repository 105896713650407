import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {RhEstatisticasModuleService} from '../../rhEstatisticas.module.service';
import {IJsonRHEstatisticasUserConfig} from '../../rhEstatisticas.module.interface';

@Component({
  selector: 'rhestatisticas-multi-config-modal',
  templateUrl: './rhEstatisticasMulti.config.modal.html'
})
export class RhEstatisticasMultiConfigModalComponent extends CGModalComponent<void> {
  @Input() public config: IJsonRHEstatisticasUserConfig;

  public readonly msTemplate: string = '{{nEmpresa}} - {{nomeEmpresa}}';

  constructor(
    protected readonly _injector: Injector,
    protected readonly _rhEstatisticasModuleService: RhEstatisticasModuleService
  ) {
    super(_injector);
    this.config = {
      selectedEmpresas: [],
      allowedEmpresas: []
    };
  }

  public close(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._rhEstatisticasModuleService
        .saveMultiConfig({allowedEmpresas: [], selectedEmpresas: this.config.selectedEmpresas})
        .then(() => {
          resolve();
          super.close();
        })
        .catch(reject);
    });
  }
}
