import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO40} from './tipoRegularizacaoCampo40.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPO_REGULARIZACAO_CAMPO40: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO40,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'tipoRegularizacaoCampo40.data.notApplicable'},
    {value: 1, name: 'tipoRegularizacaoCampo40.data.art78N2'},
    {value: 2, name: 'tipoRegularizacaoCampo40.data.art78N3'},
    {value: 3, name: 'tipoRegularizacaoCampo40.data.art78N6'},
    {value: 4, name: 'tipoRegularizacaoCampo40.data.art78N7Pre2013ItemA'},
    {value: 5, name: 'tipoRegularizacaoCampo40.data.art78N7Pre2013ItemB'},
    {value: 6, name: 'tipoRegularizacaoCampo40.data.art78N7Pre2013ItemC'},
    {value: 7, name: 'tipoRegularizacaoCampo40.data.art78N7Pre2013ItemD'},
    {value: 8, name: 'tipoRegularizacaoCampo40.data.art78N7Post2013ItemA'},
    {value: 9, name: 'tipoRegularizacaoCampo40.data.art78N7Post2013ItemB'},
    {value: 10, name: 'tipoRegularizacaoCampo40.data.art78N7Post2013ItemC'},
    {value: 11, name: 'tipoRegularizacaoCampo40.data.art78N7Post2013ItemD'},
    {value: 12, name: 'tipoRegularizacaoCampo40.data.art78N8ItemD'},
    {value: 13, name: 'tipoRegularizacaoCampo40.data.art78N8ItemB'},
    {value: 14, name: 'tipoRegularizacaoCampo40.data.art78N8ItemC'},
    {value: 15, name: 'tipoRegularizacaoCampo40.data.art78N8ItemE'},
    {value: 16, name: 'tipoRegularizacaoCampo40.data.art78AN4ItemA'},
    {value: 17, name: 'tipoRegularizacaoCampo40.data.art78AN4ItemB'},
    {value: 18, name: 'tipoRegularizacaoCampo40.data.art78AN4ItemC'},
    {value: 19, name: 'tipoRegularizacaoCampo40.data.art78AN4ItemD'},
    {value: 21, name: 'tipoRegularizacaoCampo40.data.art78AN2ItemA'},
    {value: 22, name: 'tipoRegularizacaoCampo40.data.art78BN4'},
    {value: 23, name: 'tipoRegularizacaoCampo40.data.adjustmentsWhoseCreditDoesNotExceed750VATIncluded'},
    {value: 24, name: 'tipoRegularizacaoCampo40.data.otherRegularizationsNotCoveredArt78RegularizationCoveredArticles23to26'},
    {value: 25, name: 'tipoRegularizacaoCampo40.data.otherRegularizationsNotCoveredByArt78'}
  ]
});
