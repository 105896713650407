import {deepFreeze, EWeekDay} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_DIAS_SEMANA} from './diassemana.datasource.interface';

export const DATA_SOURCE_DIAS_SEMANA: IDataSource<EWeekDay> = deepFreeze({
  name: DATA_SOURCE_NAME_DIAS_SEMANA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: 'name',
    output: 'name'
  },
  data: [
    {value: EWeekDay.Monday, name: 'global.dayOfWeek.monday'},
    {value: EWeekDay.Tuesday, name: 'global.dayOfWeek.tuesday'},
    {value: EWeekDay.Wednesday, name: 'global.dayOfWeek.wednesday'},
    {value: EWeekDay.Thursday, name: 'global.dayOfWeek.thursday'},
    {value: EWeekDay.Friday, name: 'global.dayOfWeek.friday'},
    {value: EWeekDay.Saturday, name: 'global.dayOfWeek.saturday'},
    {value: EWeekDay.Sunday, name: 'global.dayOfWeek.sunday'}
  ]
});
