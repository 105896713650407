import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {IAmaliaMessageBodyIntent, TAmaliaMessageBody} from '../../../services/amalia/api/amalia.api.service.interface';
import moment, {Moment} from 'moment';

const TODAY = moment().startOf('day');

@Component({
  selector: 'amalia-chat-message',
  templateUrl: './amalia.chatmessage.component.html'
})
export class AmaliaChatMessageComponent implements OnChanges {
  @Input() public agentName: string;
  @Input() public agentPicture: string;
  @Input() public messageBody: Array<TAmaliaMessageBody>;
  @Input() public timestamp: string;
  @Input() public sentByAgent: boolean;
  @Input() public timeRead: number;
  @Output() public readonly evtActionIntent: EventEmitter<IAmaliaMessageBodyIntent>;
  @Output() public readonly evtLinkClicked: EventEmitter<string>;

  public messageFaq: boolean;
  public timestampDate: Moment;
  public isToday: boolean;

  constructor() {
    this.evtActionIntent = new EventEmitter<IAmaliaMessageBodyIntent>();
    this.evtLinkClicked = new EventEmitter<string>();
    this.messageFaq = false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.timestamp) {
      this.timestampDate = moment(changes.timestamp.currentValue);
      this.isToday = TODAY.isSame(this.timestampDate, 'date');
    }

    if (changes.messageBody) {
      const messageBody = changes.messageBody.currentValue;
      if (messageBody?.some((item) => item.format)) {
        this.messageFaq = true;
      }
    }
  }

  public actionIntent(intent: IAmaliaMessageBodyIntent): void {
    this.evtActionIntent.emit(intent);
  }

  public handleCustomLinkClick(text: string): void {
    this.evtLinkClicked.emit(text);
  }
}
