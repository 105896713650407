import {ENTITY_NAME_CLIFOEXTRAVALUETIPOS} from './clifoExtraValueTipo.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonClifoExtraValueTipo} from './jsonClifoExtraValueTipo.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_CLIFOEXTRAVALUETIPOS: IEntityDefinition<IJsonClifoExtraValueTipo> = {
  name: ENTITY_NAME_CLIFOEXTRAVALUETIPOS,
  asModule: false,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.ATIVOS],
  metadata: {
    keyName: 'codigo',
    fields: [
      {name: 'codigo', type: 'cgsmallint', caption: 'clifoExtraValueTipos.fields.codigo', validators: {required: true}},
      {name: 'tipo', type: 'cgsmallint', width: '200px', caption: 'clifoExtraValueTipos.fields.tipo', validators: {required: true}},
      {name: 'descricao', caption: 'clifoExtraValueTipos.fields.descricao', validators: {required: true, maxlength: 50}},
      {name: 'natureza', type: 'cgsmallint', caption: 'clifoExtraValueTipos.fields.natureza', validators: {required: true}}
    ],
    order: 'tipo',
    searchFields: 'codigo,tipo,descricao,natureza'
  },
  autocomplete: {
    rowTemplate: '{{tipo}} - {{descricao}}',
    output: 'descricao'
  },
  actions: {
    new: true,
    detail: true,
    edit: false,
    delete: false,
    search: true,
    filter: true
  }
};
