import {InjectionToken} from '@angular/core';
import type {IPlRecaptchaParameters} from './recaptcha.interface';
import type {TValueOrPromise} from '../common/utilities/utilities.interface';

export const RECAPTCHA_SITE_KEY: InjectionToken<TValueOrPromise<string>> = new InjectionToken<TValueOrPromise<string>>('pl-recaptcha-site-key');
export const RECAPTCHA_SITE_KEY_V3: InjectionToken<TValueOrPromise<string>> = new InjectionToken<TValueOrPromise<string>>('pl-recaptcha-site-key-v3');
export const RECAPTCHA_ENTERPRISE: InjectionToken<TValueOrPromise<boolean>> = new InjectionToken<TValueOrPromise<boolean>>('pl-recaptcha-enterprise');
export const RECAPTCHA_BASE_URL: InjectionToken<TValueOrPromise<string>> = new InjectionToken<TValueOrPromise<string>>('pl-recaptcha-base-url');
export const RECAPTCHA_NONCE: InjectionToken<TValueOrPromise<string>> = new InjectionToken<TValueOrPromise<string>>('pl-recaptcha-nonce-tag');
export const RECAPTCHA_LANGUAGE: InjectionToken<TValueOrPromise<string>> = new InjectionToken<TValueOrPromise<string>>('pl-recaptcha-language');
export const RECAPTCHA_PARAMETERS: InjectionToken<TValueOrPromise<IPlRecaptchaParameters>> = new InjectionToken<TValueOrPromise<IPlRecaptchaParameters>>('pl-recaptcha-parameters');
