import CustomStore from 'devextreme/data/custom_store';
import dxDataGrid, {ToolbarItem} from 'devextreme/ui/data_grid';
import {Properties} from 'devextreme/ui/button';
import {Component, Injector, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IPlFormDefinition, IPlFormTemplate, isEmptyObject} from 'pl-comps-angular';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {generateUUID} from '../../../../../common/utils/utils';
import {IDevExpressDataGrid, TDevExpressDataGridToolbar} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IImTabEntityService} from '../../imtab.entity.interface';
import {IJsonDataValor} from '../../../datavalor/jsonDataValor.entity.interface';
import {IJsonImtab} from '../../jsonImtab.entity.interface';
import {ImtabEditDataValorModalComponent} from '../../modals/imtab.edit.dataValor.modal.component';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';

@Component({
  selector: 'amortizacao-edit',
  templateUrl: './imtab.entity.edit.component.html'
})
export class ImtabEditComponent extends ModuloEntityDetailComponent<IJsonImtab, IImTabEntityService> implements OnInit {
  public readonly dataGrid: IDevExpressDataGrid;
  public formDefinition: IPlFormDefinition;
  public formTemplate: IPlFormTemplate;
  public isEditing: boolean;

  private _isNew: boolean;
  private _dataGridInstance: dxDataGrid<IJsonDataValor, IJsonDataValor>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.dataGrid = {
      columns: [
        {dataField: 'dataInicio', dataType: 'date', caption: 'amortizacao.fields.dataInicio'},
        {dataField: 'valor1', dataType: 'double', caption: 'amortizacao.fields.valor1'},
        {dataField: 'valor2', dataType: 'double', caption: 'amortizacao.fields.valor2'},
        {dataField: 'valor3', dataType: 'double', caption: 'amortizacao.fields.valor3'},
        {
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          alignment: 'center',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false,
          width: 150
        }
      ],
      dataSource: new CustomStore({
        load: () => this._getList()
      }),
      allowColumnReordering: false,
      columnFixing: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      remoteOperations: false,
      searchPanel: {visible: true},
      toolbar: this._generateDataGridToolbar(() => this._addLine())
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.formTemplate = <IPlFormTemplate>this.states.detail.data.formTemplate;
    this.isEditing = this.type !== EEntityStateDetailType.DETAIL;
    if (isEmptyObject(this.model)) {
      this.model = {
        nImtab: undefined,
        nome: '',
        tabela: '',
        grupo: '',
        alinea: '',
        artigo: '',
        listaValores: []
      };
    }
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    this._isNew = stateType === EEntityStateDetailType.NEW;
    this.isEditing = stateType !== EEntityStateDetailType.DETAIL;
    this.formDefinition = this.entity[stateType].definition;
    if (this._dataGridInstance) {
      this._evaluateAddRowButtonVisibility();
    }
    return super.onUpdate(stateType);
  }

  public save(): Promise<IJsonImtab> {
    const listaValores = this.model.listaValores;
    if (!this._isNew) {
      delete this.model.listaValores;
    }
    return super.save().then((response: IJsonImtab) => {
      this.model = response;
      this.model.listaValores = listaValores;
      return this.model;
    });
  }

  public onInitialized(event: IDevExpressDataGridEventOnInitialized<IJsonDataValor, IJsonDataValor>): void {
    this._dataGridInstance = event.component;
    this._evaluateAddRowButtonVisibility();
  }

  public fnEditLine = (linhaValor: IJsonDataValor) => (): Promise<void> => this._editLine(linhaValor);

  public fnRemoveLine = (dataValorID: string) => (): Promise<void> => this._removeLine(dataValorID);

  private _getList(): Array<IJsonDataValor> {
    if (!this.model?.listaValores) {
      return [];
    }
    return this.model.listaValores;
  }

  private _addLine(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._addDataValor()
        .result.then((dataValor: IJsonDataValor) => {
          dataValor.keyValue = this.model.nImtab;
          let promise;
          if (!this._isNew) {
            promise = this.service.adicionarLinha(this.model.nImtab, dataValor);
          }
          Promise.resolve(promise)
            .then((response: HttpResponse<IJsonDataValor>) => {
              if (response) {
                dataValor = response.body;
              }
              if (!this.model.listaValores) {
                this.model.listaValores = [];
              }
              if (!dataValor.dataValorID) {
                dataValor.dataValorID = generateUUID(true).toString();
              }
              this.model.listaValores.push(dataValor);
              this._dataGridInstance.refresh();
              resolve();
            })
            .catch((reason: HttpErrorResponse) => {
              reject(reason);
            });
        })
        .catch((reason: unknown) => {
          reject(reason);
        });
    });
  }

  private _editLine(linhaValor: IJsonDataValor): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._editDataValor(linhaValor)
        .result.then((dataValor: IJsonDataValor) => {
          dataValor.keyValue = this.model.nImtab;
          let promise;
          if (!this._isNew) {
            promise = this.service.editarLinha(this.model.nImtab, dataValor);
          }
          Promise.resolve(promise)
            .then(() => {
              const index = this.model.listaValores.findIndex((item) => item.dataValorID === linhaValor.dataValorID);
              this.model.listaValores.splice(index, 1, dataValor);
              this._dataGridInstance.refresh();
              resolve();
            })
            .catch((reason: HttpErrorResponse) => {
              reject(reason);
            });
        })
        .catch((reason: unknown) => {
          reject(reason);
        });
    });
  }

  private _removeLine(dataValorID: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._cgModalService
        .showOkCancel('global.text.confirmation', 'entity.delete.message')
        .then(() => {
          let promise;
          if (!this._isNew) {
            promise = this.service.eliminarLinha(this.model.nImtab, dataValorID);
          }
          Promise.resolve(promise)
            .then(() => {
              const index = this.model.listaValores.findIndex((item) => item.dataValorID === dataValorID);
              if (index !== -1) {
                this.model.listaValores.splice(index, 1);
                this._dataGridInstance.refresh();
              }
              resolve();
            })
            .catch((reason: HttpErrorResponse) => {
              reject(reason);
            });
        })
        .catch((reason: unknown) => {
          reject(reason);
        });
    });
  }

  private _addDataValor(): NgbModalRef {
    return this._editDataValor(undefined, true);
  }

  private _editDataValor(dataValor: IJsonDataValor, isNew: boolean = false): NgbModalRef {
    const modalInstance = this._cgModalService.showVanilla(ImtabEditDataValorModalComponent);
    const componentInstance: ImtabEditDataValorModalComponent = modalInstance.componentInstance;
    componentInstance.new = isNew;
    componentInstance.model = dataValor;
    return modalInstance;
  }

  private _generateDataGridToolbar(callback: () => void): TDevExpressDataGridToolbar {
    return {
      items: [
        {
          name: 'addRowButton',
          location: 'after',
          widget: 'dxButton',
          visible: false,
          options: {
            icon: 'add',
            hint: this._translateService.instant('amortizacao.add'),
            onClick: () => {
              callback();
            }
          } satisfies Properties
        },
        'exportButton',
        'columnChooserButton',
        'searchPanel'
      ]
    };
  }

  private _evaluateAddRowButtonVisibility(): void {
    const addRowButtonIndex = this.dataGrid.toolbar.items.findIndex((toolbarItem: ToolbarItem) => toolbarItem.name === 'addRowButton');
    this._dataGridInstance.option(`toolbar.items[${addRowButtonIndex}].visible`, this.isEditing);
  }
}
