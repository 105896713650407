import moment from 'moment';
import {ProviderLike} from '@uirouter/core';
import {EStoreMode} from '../enums/licenca.enums';
import {hasAuthority} from '../utils/roles.utils';
import {ROLE} from '../../core/services/role.const';
import {TUserSession} from '../../core/services/account/jsonUserApi.interface';

export const CLOUD_PUBLICA_HORAS = 18;

const MAX_FILE_SIZE_CLOUD_PRIVADA = 0;
const MAX_FILE_SIZE_CLOUD_PUBLICA = 20;
const MAX_FILE_SIZE_CLOUD_PUBLICA_PREMIUM = 50;

export function resolverImportacaoSAFTMaxFileSizeFactory(session: TUserSession, licencaStoreMode: EStoreMode): number {
  if (licencaStoreMode !== EStoreMode.Public) {
    return MAX_FILE_SIZE_CLOUD_PRIVADA;
  }
  if (!hasAuthority(session, ROLE.CONTABILIDADEPREMIUM) || moment().hours() < CLOUD_PUBLICA_HORAS) {
    return MAX_FILE_SIZE_CLOUD_PUBLICA;
  }
  return MAX_FILE_SIZE_CLOUD_PUBLICA_PREMIUM;
}

export const RESOLVER_IMPORTACAO_SAFT_MAX_FILE_SIZE: ProviderLike = {
  provide: 'importacaoSAFTMaxFileSize',
  deps: ['session', 'licencaStoreMode'],
  useFactory: resolverImportacaoSAFTMaxFileSizeFactory
};
