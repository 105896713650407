<div class="ativosabate-modal">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'ativos.modal.abatedoativo'"></h5>
    </div>

    <div class="modal-body">
      <pl-group>
        <edit>
          <label class="me-2" [translate]="'global.text.active'"></label>
          <span>{{ ativo.codAtivo }} - {{ ativo.designacao }}</span>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'ativos.modal.dataAbate'"></label>
        <edit>
          <pl-edit type="date" attrName="dataAbate" [(model)]="abate.dataAbate" plAutoFocus></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'ativos.modal.radicalAbate'"></label>
        <edit>
          <entity-autocomplete
            entity="pocs"
            attrName="radicalAbate"
            [model]="abate"
            (selectedKeyChange)="abate.ncontaAbate = $event"
            (evtSelectedDescriptionChanged)="abate.nomecontaAbate = $event"
            [output]="pocsOutput"
            [fieldsMap]="{nConta: 'ncontaAbate', nome: 'nomecontaAbate'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn btn btn-success action-close" [disabled]="closeDisabled" [onClick]="close" data-focus>
        <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span>
      </pl-button>

      <pl-button klass="btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()">
        <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
      </pl-button>
    </div>
  </pl-form>
</div>
