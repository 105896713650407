import {Moment, MomentInput} from 'moment';
import {ECalendarioTipoDia, IJsonCalendario, IJsonCalendarioCab, IJsonCalendarioNDiasUteis} from './jsonCalendarios.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService, TEntityServiceRequestData} from '../../services/entity/entity.service.interface';
import {TDate} from '../../../common/dates';
import {TServiceResponse} from '../../services/api/api.service.interface';

export type TCalendariosEntityServiceRequestData = TEntityServiceRequestData & {params?: ICalendariosGetParams};

export interface ICalendariosEntityService extends IEntityService<IJsonCalendarioCab> {
  get<TResponse = IJsonCalendario>(requestData: number | TCalendariosEntityServiceRequestData): TServiceResponse<TResponse>;

  getDefault(params?: ICalendariosGetParams): TServiceResponse<IJsonCalendario>;

  getCabecalho(calenCabId: number): TServiceResponse<IJsonCalendarioCab>;

  getDefaultCabecalho(): TServiceResponse<IJsonCalendarioCab>;

  getNDiasCalendarioPorTipo(calenCabID: number, dataInicial: TDate, dataFim: TDate, tipoDia: ECalendarioTipoDia, excluiFimdeSemana: boolean): TServiceResponse<IJsonCalendarioNDiasUteis>;

  gerarCalendarioParaAno(calenCabID: number, ano: number): TServiceResponse<void>;

  getHolidaysDates(viewDate: Moment): Promise<Array<MomentInput>>;
}

export interface ICalendariosEntity extends IEntityDefinition<IJsonCalendarioCab, ICalendariosEntityService> {}

export interface ICalendariosGetParams {
  dataDe?: TDate;
  dataAte?: TDate;
  holidaysOnly?: boolean;
  tiposDia?: Array<ECalendarioTipoDia>;
  excludeTiposDia?: Array<ECalendarioTipoDia>;
}

export const ENTITY_NAME_CALENDARIOS = 'calendarios';
