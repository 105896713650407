import {FactoryProvider} from '@angular/core';
import {isObject} from 'pl-comps-angular';
import {AuthService} from '../auth/auth.service';
import {CGInjector} from '../../../common/injectors/cginjector';
import {ClientConnectService} from '../clientconnect/clientconnect.service';
import {ClientConnectUIService} from '../clientconnect/clientconnect.ui.service';
import {ContabilidadeDigitalService} from './contabilidadedigital.service';
import {ContabilidadeDigitalUIService} from './contabilidadedigital.ui.service';
import {IJsonUserRole, TUserSession} from '../account/jsonUserApi.interface';
import {ROLE} from '../role.const';

function isClientConnect(session: TUserSession): boolean {
  return (
    isObject(session) &&
    session.erp.rolesAcess.findIndex((role: IJsonUserRole) => role.role === ROLE.CLIENTCONNECT) > -1 &&
    session.erp.rolesAcess.findIndex((role: IJsonUserRole) => role.role === ROLE.CONTABILIDADE) === -1
  );
}

export function contabilidadeDigitalServiceFactoryProvider(authService: AuthService): ContabilidadeDigitalService {
  const contabilidadeDigitalService: ContabilidadeDigitalService = CGInjector.get<ContabilidadeDigitalService>(ContabilidadeDigitalService);
  const clientConnectService: ClientConnectService = CGInjector.get<ClientConnectService>(ClientConnectService);
  return isClientConnect(authService.identityValue) ? clientConnectService : contabilidadeDigitalService;
}

export function contabilidadeDigitalUIServiceFactoryProvider(authService: AuthService): ContabilidadeDigitalService {
  const contabilidadeDigitalUIService: ContabilidadeDigitalUIService = CGInjector.get<ContabilidadeDigitalUIService>(ContabilidadeDigitalUIService);
  const clientConnectUIService: ClientConnectUIService = CGInjector.get<ClientConnectUIService>(ClientConnectUIService);
  return isClientConnect(authService.identityValue) ? clientConnectUIService : contabilidadeDigitalUIService;
}

export const CONTABILIDADE_DIGITAL_SERVICE_PROVIDER: FactoryProvider = {
  provide: ContabilidadeDigitalService,
  useFactory: contabilidadeDigitalServiceFactoryProvider,
  deps: [AuthService]
};

export const CONTABILIDADE_DIGITAL_SERVICE_UI_PROVIDER: FactoryProvider = {
  provide: ContabilidadeDigitalUIService,
  useFactory: contabilidadeDigitalUIServiceFactoryProvider,
  deps: [AuthService]
};
