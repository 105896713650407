<div class="saft-container ies-container">
  <div class="blocked-by-other-user-container" *ngIf="isBlocked">
    <div class="block-inline">
      <i class="fa fa-lock" aria-hidden="true"></i>
    </div>

    <div class="block-inline">
      <h5><span [translate]="'ies.exportInProcessModalMessageByOtherUser'"></span>:</h5>
      <div class="blocked-by-username">{{ initStatus.userStarted }}</div>
    </div>
  </div>

  <pl-tabs *ngIf="!isBlocked" [theme]="'style3'" [activeId]="tabActiveId" (evtSelected)="onTabChanged($event)">
    <pl-tab caption="ies.tabProcess" [id]="tabProcess">
      <div *plTabContent>
        <ng-container *ngIf="currentStep === iesSteps.Configuration">
          <p class="text-danger"><strong [translate]="'ies.warningMessage'"></strong></p>

          <div class="row">
            <div class="col-md-6 col-lg-4">
              <div class="anexo-container">
                <div>
                  <strong>{{ model.anexos.anexoASNC.name }}</strong>
                </div>
                <div>{{ model.anexos.anexoASNC.description }}</div>
                <pl-edit type="checkbox" [model]="model.anexos.anexoASNC.checked" (modelChange)="model.anexos.anexoASNC.checked = $event" [properties]="{label: ''}"></pl-edit>
              </div>

              <div class="anexo-container">
                <div>
                  <strong>{{ model.anexos.anexoD.name }}</strong>
                </div>
                <div>{{ model.anexos.anexoD.description }}</div>
                <pl-edit type="checkbox" [model]="model.anexos.anexoD.checked" (modelChange)="model.anexos.anexoD.checked = $event" [properties]="{label: ''}"></pl-edit>
              </div>

              <div class="anexo-container">
                <div>
                  <strong>{{ model.anexos.anexoE.name }}</strong>
                </div>
                <div>{{ model.anexos.anexoE.description }}</div>
                <pl-edit type="checkbox" [model]="model.anexos.anexoE.checked" (modelChange)="model.anexos.anexoE.checked = $event" [properties]="{label: ''}"></pl-edit>
              </div>

              <div class="anexo-container">
                <div>
                  <strong>{{ model.anexos.anexoF.name }}</strong>
                </div>
                <div>{{ model.anexos.anexoF.description }}</div>
                <pl-edit type="checkbox" [model]="model.anexos.anexoF.checked" (modelChange)="model.anexos.anexoF.checked = $event" [properties]="{label: ''}"></pl-edit>
              </div>

              <div class="anexo-container">
                <div>
                  <strong>{{ model.anexos.anexoG.name }}</strong>
                </div>
                <div>{{ model.anexos.anexoG.description }}</div>
                <pl-edit type="checkbox" [model]="model.anexos.anexoG.checked" (modelChange)="model.anexos.anexoG.checked = $event" [properties]="{label: ''}"></pl-edit>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="anexo-container">
                <div>
                  <strong>{{ model.anexos.anexoH.name }}</strong>
                </div>
                <div>{{ model.anexos.anexoH.description }}</div>
                <pl-edit type="checkbox" [model]="model.anexos.anexoH.checked" (modelChange)="model.anexos.anexoH.checked = $event" [properties]="{label: ''}"></pl-edit>
              </div>

              <div class="anexo-container">
                <div>
                  <strong>{{ model.anexos.anexoI.name }}</strong>
                </div>
                <div>{{ model.anexos.anexoI.description }}</div>
                <pl-edit type="checkbox" [model]="model.anexos.anexoI.checked" (modelChange)="model.anexos.anexoI.checked = $event" [properties]="{label: ''}"></pl-edit>
              </div>

              <div class="anexo-container">
                <div>
                  <strong>{{ model.anexos.anexoL.name }}</strong>
                </div>
                <div>{{ model.anexos.anexoL.description }}</div>
                <pl-edit type="checkbox" [model]="model.anexos.anexoL.checked" (modelChange)="model.anexos.anexoL.checked = $event" [properties]="{label: ''}"></pl-edit>
              </div>

              <div class="anexo-container">
                <div>
                  <strong>{{ model.anexos.anexoM.name }}</strong>
                </div>
                <div>{{ model.anexos.anexoM.description }}</div>
                <pl-edit type="checkbox" [model]="model.anexos.anexoM.checked" (modelChange)="model.anexos.anexoM.checked = $event" [properties]="{label: ''}"></pl-edit>
              </div>

              <div class="anexo-container">
                <div>
                  <strong>{{ model.anexos.anexoN.name }}</strong>
                </div>
                <div>{{ model.anexos.anexoN.description }}</div>
                <pl-edit type="checkbox" [model]="model.anexos.anexoN.checked" (modelChange)="model.anexos.anexoN.checked = $event" [properties]="{label: ''}"></pl-edit>
              </div>

              <div class="anexo-container">
                <div>
                  <strong>{{ model.anexos.anexoO.name }}</strong>
                </div>
                <div>{{ model.anexos.anexoO.description }}</div>
                <pl-edit type="checkbox" [model]="model.anexos.anexoO.checked" (modelChange)="model.anexos.anexoO.checked = $event" [properties]="{label: ''}"></pl-edit>
              </div>
            </div>
            <div class="col-md-6 col-lg-4">
              <div class="anexo-container">
                <div>
                  <strong>{{ model.anexos.anexoP.name }}</strong>
                </div>
                <div>{{ model.anexos.anexoP.description }}</div>
                <pl-edit type="checkbox" [model]="model.anexos.anexoP.checked" (modelChange)="model.anexos.anexoP.checked = $event" [properties]="{label: ''}"></pl-edit>
              </div>

              <div class="anexo-container">
                <div>
                  <strong>{{ model.anexos.anexoQ.name }}</strong>
                </div>
                <div>{{ model.anexos.anexoQ.description }}</div>
                <pl-edit type="checkbox" [model]="model.anexos.anexoQ.checked" (modelChange)="model.anexos.anexoQ.checked = $event" [properties]="{label: ''}"></pl-edit>
              </div>

              <div class="anexo-container">
                <div>
                  <strong>{{ model.anexos.anexoRSNC.name }}</strong>
                </div>
                <div>{{ model.anexos.anexoRSNC.description }}</div>
                <pl-edit type="checkbox" [model]="model.anexos.anexoRSNC.checked" (modelChange)="model.anexos.anexoRSNC.checked = $event" [properties]="{label: ''}"></pl-edit>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="row" *ngIf="currentStep === iesSteps.Processing">
          <div class="col-md-12">
            <div class="saft-pb-container">
              <div class="saft-pb-label" [translate]="'ies.processingMessage'"></div>

              <div class="progress">
                <div
                  class="progress-bar bg-warning progress-bar-striped progress-bar-animated"
                  style="width: 100%"
                  role="progressbar"
                  [attr.aria-valuenow]="100"
                  [attr.aria-valuemin]="0"
                  [attr.aria-valuemax]="100"></div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="saft-pb-label-op" [translate]="statusDescription"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="currentStep === iesSteps.Errors">
          <div>
            <h4 [translate]="'ies.erros'"></h4>
          </div>

          <pl-alert type="error" [closeable]="false"><i class="fa fa-times-circle" aria-hidden="true"></i>&nbsp;<span [translate]="currentStatus.description"></span></pl-alert>

          <pl-button klass="btn-light" type="button" (evtClicked)="closeErrorPanel()">
            <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
            <span [translate]="'ies.buttons.fechar'"></span>
          </pl-button>
        </div>
      </div>
    </pl-tab>

    <pl-tab caption="ies.tabDownload" [id]="tabDownload" [disabled]="!currentStatus.ficheiroProcessado">
      <div *plTabContent>
        <label class="d-block" [translate]="'ies.fileReady'"></label>

        <div class="btn ficheiroProcessado-inline">
          <span [translate]="'ies.dataCriacao'"></span>:<strong>{{ currentStatus.ficheiroProcessadoDate | cgcDateTime }}</strong>
        </div>

        <div class="btn ficheiroProcessado-inline">
          <span [translate]="'ies.tamanho'"></span>: <strong>{{ formatBytes(currentStatus.ficheiroProcessadoSize) }}</strong>
        </div>

        <a class="btn btn-link" target="_blank" (click)="doDownload()"> <i class="fa fa-download" aria-hidden="true"></i>&nbsp;<span [translate]="'ies.buttons.download'"></span> </a>

        <div class="btn ficheiroProcessado-inline">
          <span [translate]="'ies.utilizador'"></span>: <strong>{{ currentStatus.userStarted }}</strong>
        </div>

        <ng-container *ngIf="currentStatus.errorList.length">
          <hr />
          <div class="alert alert-danger"><i class="fa fa-fw fa-exclamation-circle"></i> <span>Erros na Declaração</span></div>
          <dx-data-grid [cgDxDataGrid]="dataGridDefinition" [dataSource]="dataGridDefinition.dataSource"></dx-data-grid>
        </ng-container>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
