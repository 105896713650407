import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {AuthService} from '../../../services/auth/auth.service';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_EMPRESAS_ERP} from '../../empresas/empresas.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IJsonEmpresaErp} from '../../../interfaces/jsonEmpresa.interface';
import {IJsonPeriodo} from '../../periodos/jsonPeriodo.entity.interface';
import {TUserSession} from '../../../services/account/jsonUserApi.interface';
import {isEmpty} from 'pl-comps-angular';

@Component({
  selector: 'choose-country-modal',
  templateUrl: './fimPeriodoEnc.modal.component.html'
})
export class FimPeriodoEncModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public periodoDisponivel: string;
  @Input() public periodos: Array<IJsonPeriodo>;

  public periodo: string;

  private readonly _empresaService: IEntityService<IJsonEmpresaErp>;
  private _empresa: IJsonEmpresaErp;

  constructor(
    protected readonly _injector: Injector,
    private readonly _authService: AuthService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._empresaService = this._entityServiceBuilder.build<IJsonEmpresaErp>(ENTITY_NAME_EMPRESAS_ERP);
    this._empresa = {
      nempresa: '',
      nome: '',
      nomeAlternativo: '',
      morada: '',
      codpostal: '',
      localidade: '',
      nif: '',
      actividade: '',
      cae: '',
      conservatoria: '',
      registo: '',
      codMoeda: 0,
      codPais: 0,
      nomePais: '',
      nomeMoeda: '',
      telefone: '',
      email: '',
      web: '',
      actividadePrincipal: '',
      codTipoEmpresa: 0,
      tipoEmpresa: '',
      alvara: '',
      capitalSocial: 0,
      codRepFinancas: 0,
      repFinancas: '',
      codTipoContab: 0,
      tipoContab: '',
      codRegimeIRC: 0,
      regimeIRC: '',
      codRegimeIva: 0,
      regimeIva: '',
      codPeriodoIva: 0,
      periodoIva: '',
      anoCursoIRC: 0,
      periodoContabilistico: '',
      textoRelatorio: '',
      nEmpresaGabinete: '',
      empresaGabinete: false,
      tipoAnalitica: 0
    };
  }

  public ngOnInit(): void {
    this._authService.identity().then((session: TUserSession) => {
      this._empresaService.get({id: session.erp.nEmpresa}).then((response: HttpResponse<IJsonEmpresaErp>) => {
        this._empresa = response.body;
        this._getPeriodo(this._empresa.periodoContabilistico);
      });
    });
  }

  public close(): Promise<void> {
    this._empresa.periodoContabilistico = this.periodo;
    return this._empresaService.put({id: this._empresa.nempresa, body: this._empresa}).then(() => {
      super.close();
    });
  }

  private _getPeriodo(periodoContabilistico: string): void {
    if (isEmpty(this.periodoDisponivel)) {
      for (let i = 1; i < this.periodos.length; i++) {
        if (this.periodos[i - 1].periodo === periodoContabilistico) {
          this.periodo = this.periodos[i].periodo;
          break;
        }
      }
    } else {
      this.periodo = this.periodoDisponivel;
    }
  }
}
