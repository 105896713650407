import {ROLE} from '../../services/role.const';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {MODULE_NAME_TRF_BA_RH} from './trfBa.module.interface';
import {TrfBaModuleComponent} from './components/trfBa.module.component';
import {HttpResponse} from '@angular/common/http';
import {TrfBaService} from './trfBa.module.service';
import {IJsonTrfBaRegistoFich} from './jsonTrfBa.module.interface';

export const MODULE_TRF_BA_RH: IModuleDefinition = generateModule(MODULE_NAME_TRF_BA_RH);

// DEPRECATED, this will be removed at a later date
// export const MODULE_TRF_BA: IModuleDefinition = generateModule(MODULE_NAME_TRF_BA);
// export const MODULE_TRF_BA_COMERCIAL: IModuleDefinition = generateModule(MODULE_NAME_TRF_BA_COMERCIAL);

function generateModule(moduleName: string): IModuleDefinition {
  return {
    name: moduleName,
    state: {
      url: `/${moduleName}`,
      component: TrfBaModuleComponent,
      data: {
        roles: [ROLE.RH, ROLE.ERP],
        sidebarTitle: `portals.sidebar.modules.${moduleName}`
      },
      params: {
        moduleName: {
          type: 'string',
          value: moduleName
        }
      },
      resolve: [
        {
          provide: 'trfBaRegistoFich',
          deps: [TrfBaService],
          useFactory: (trfBaService: TrfBaService): Promise<IJsonTrfBaRegistoFich> => {
            return trfBaService.getRegistoFicheiro().then((response: HttpResponse<IJsonTrfBaRegistoFich>) => response.body);
          }
        }
      ]
    }
  };
}
