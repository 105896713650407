import {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthService} from '../../../../services/auth/auth.service';
import {BancosExtratoService} from '../../service/bancosExtrato.module.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonCGBankingLicense} from '../../jsonBancosExtrato.module.interface';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';

@Component({
  selector: 'bancos-extrato-module-activate-license-modal',
  templateUrl: './bancosExtrato.activateLicense.modal.component.html'
})
export class BancosExtratoModuleActivateLicenseModalComponent extends CGModalComponent<void> implements OnInit, OnDestroy {
  @Input() public dadosLicenca: IJsonCGBankingLicense;
  public nEmpresa: string;
  public nomeEmpresa: string;
  public promise: Promise<void>;

  private readonly _subscriptionIdentity: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _authService: AuthService,
    private readonly _bancosExtratoService: BancosExtratoService
  ) {
    super(_injector);
    this.nEmpresa = '';
    this.nomeEmpresa = '';
    this._subscriptionIdentity = this._authService.identityAsObservable().subscribe((session: TUserSession) => {
      this.nEmpresa = session?.erp.nEmpresa || '';
      this.nomeEmpresa = session?.erp.nomeEmpresa || '';
    });
  }

  public ngOnInit(): void {
    this.dadosLicenca = {
      nEmpresasTotal: 0,
      nEmpresasConsumidas: 0,
      nEmpresasPorConsumir: 0,
      ...this.dadosLicenca
    };
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionIdentity.unsubscribe();
  }

  public close(): Promise<void> {
    if (!this.dadosLicenca.nEmpresasPorConsumir) {
      return Promise.resolve();
    }
    this.disableClose();
    return this._bancosExtratoService
      .activateCGBanking()
      .then(() => {
        this.enableClose();
        super.close();
      })
      .catch((reason: HttpErrorResponse) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }
}
