<div class="cg-blocked-plugin">
  <pl-alert *ngIf="!valid" type="error" theme="m-0" [closeable]="false">
    <div class="d-flex gap-2">
      <div *ngIf="moduleMode" class="cg-blocked-plugin-icon fs-3">
        <i class="fa fa-ban" aria-hidden="true"></i>
      </div>

      <div class="cg-blocked-plugin-text">
        <h5 *ngIf="moduleMode" class="mb-2" [translate]="'blockedPlugin.text.noAccessModule'"></h5>
        <h6 *ngIf="!moduleMode" class="mb-2" [translate]="'blockedPlugin.text.noAccess'"></h6>

        <ul>
          <li *ngFor="let error of errors" [innerHTML]="error"></li>
        </ul>
      </div>
    </div>
  </pl-alert>
</div>
