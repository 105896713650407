import {ROLE} from '../../services/role.const';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {VendasAnoMesModuleComponent} from './vendasanomes/vendasAnoMes.module.component';

export const MODULE_LISTAGENS_VENDAS_ANO_MES: IModuleDefinition = {
  name: 'listagensVendasAnoMes',
  state: {
    url: '/listagens/vendas/anomes',
    component: VendasAnoMesModuleComponent,
    data: {
      roles: [ROLE.ERP],
      sidebarTitle: 'portals.sidebar.modules.listagensVendasAnoMes'
    }
  }
};
