<div class="modal-header ativosconsistencia-listaativos-modal">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'ativosconsistencia.modal.listaativostitle'"></h5>
</div>

<div class="modal-body">
  <dx-data-grid [cgDxDataGrid]="definition" [dataSource]="listaAtivos"></dx-data-grid>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
