<div class="progress">
  <div class="progress-bar" role="progressbar" [ngClass]="theme" [attr.aria-valuenow]="value" [attr.aria-valuemin]="min" [attr.aria-valuemax]="max">
    <div class="bar positive" [style.width.%]="value">
      <span [style.width.px]="width">{{value}}%</span>
    </div>

    <div class="bar negative" [style.width.%]="100 - value">
      <span [style.width.px]="width">{{value}}%</span>
    </div>
  </div>
</div>
