import {Component, Injector, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {copy, isArray, isObject, PlAlertService} from 'pl-comps-angular';
import {ENTITY_NAME_USERS} from '../../../../../../utilizadores/utilizadores.interface';
import {EntityServiceBuilder} from '../../../../../../../services/entity/entity.service.builder';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../../../../config/constants';
import {IEmpresa} from '../../../../../../../entities/empresas/empresas.entity.interface';
import {IEntityService} from '../../../../../../../services/entity/entity.service.interface';
import {IJsonErpUser, IJsonUserEmpresa, IJsonUserRole} from '../../../../../../../services/account/jsonUserApi.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS} from '../../../../assistente.configPortais.module.service.interface';
import {ModuloComponent} from '../../../../../../../components/module/module.component';
import {ROLE} from '../../../../../../../services/role.const';

const ROLE_API: ROLE = ROLE.CLIENTCONNECT;

@Component({
  selector: 'module-assistente-config-portais-portal-client-connect-utilizador-existente',
  templateUrl: './assistConfigPortais.portalClientConnect.utilizadorExistente.module.component.html'
})
export class AssistConfigPortaisPortalClientConnectUtilizadorExistenteModuleComponent extends ModuloComponent implements OnInit {
  @Input() public portalRoleClientConnect: ROLE;

  public readonly formInstance: UntypedFormGroup;
  public readonly filterEmpresas: string;
  public readonly centralGestId: number;
  public user: IJsonUserEmpresa;
  public empresa: IEmpresa;

  private readonly _usersService: IEntityService<IJsonUserEmpresa>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.formInstance = new UntypedFormGroup({});
    this.filterEmpresas = 'EmpresaGabinete=0';
    this.centralGestId = this.session.erp.centralGestId;
    this._usersService = this._entityServiceBuilder.build<IJsonUserEmpresa>(ENTITY_NAME_USERS);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnBack.visible = true;
    this.btnSave.click = this._save.bind(this);
    this.btnSave.visible = true;
  }

  public back(): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS, exactMatch: true});
  }

  public changedUser(value: IJsonUserEmpresa): void {
    this.user = value;
  }

  public changedEmpresa(value: IEmpresa): void {
    this.empresa = value;
  }

  private _save(): Promise<void> {
    if (!this.formInstance.valid || !isObject(this.user) || !isObject(this.empresa)) {
      this._plAlertService.error('error.invalidForm');
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    const user: IJsonUserEmpresa = copy<IJsonUserEmpresa>(this.user);

    // Check if already has access to this portal
    const erp: IJsonErpUser = user.erps.find((userErp: IJsonErpUser) => userErp.nEmpresa === this.empresa.nempresa);
    if (!erp) {
      user.erps.push({
        cgID: undefined,
        centralGestId: this.session.erp.centralGestId,
        name: this.session.erp.name,
        apiUrl: this.session.erp.apiUrl,
        nEmpresa: this.empresa.nempresa,
        nUtilizador: undefined,
        ativo: true,
        nomeEmpresa: this.empresa.nome,
        nomeUtilizadorCG: undefined,
        codEmp: undefined,
        nomeEmpregado: undefined,
        rolesAcess: [{role: ROLE_API}, {role: copy(this.portalRoleClientConnect)}],
        rolesNotAcess: []
      });
    } else {
      if (!isArray(erp.rolesAcess)) {
        erp.rolesAcess = [];
      }
      // Check if already has access to roles
      const roleAccessApi: IJsonUserRole = erp.rolesAcess.find((role: IJsonUserRole) => role.role === ROLE_API);
      if (!roleAccessApi) {
        erp.rolesAcess.push({role: ROLE_API});
      }
      const roleAccessPortal: IJsonUserRole = erp.rolesAcess.find((role: IJsonUserRole) => role.role === this.portalRoleClientConnect);
      if (!roleAccessPortal) {
        erp.rolesAcess.push({role: this.portalRoleClientConnect});
      }
    }
    return this._usersService.put<IJsonUserEmpresa>({id: this.user.userId, body: user}).then(() => {
      this._plAlertService.success('assistenteconfigportais.info.utilizadorexistentesuccess');
      return this.back();
    });
  }
}
