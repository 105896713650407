import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {IRHManutencaoCamposField, MODULE_NAME_RH_MANUTENCAO_CAMPOS} from './rhManutencaoCampos.module.interface';
import {ROLE} from '../../../services/role.const';
import {RHManutencaoCamposModuleComponent} from './components/rhManutencaoCampos.module.component';
import {RHManutencaoCamposService} from './rhManutencaoCampos.module.service';

export const MODULE_RH_MANUTENCAO_CAMPOS: IModuleDefinition = {
  name: MODULE_NAME_RH_MANUTENCAO_CAMPOS,
  state: {
    url: `/${MODULE_NAME_RH_MANUTENCAO_CAMPOS}`,
    component: RHManutencaoCamposModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhmanutencaocampos',
      icon: 'fa-magic'
    },
    resolve: [
      {
        provide: 'availableFields',
        deps: [RHManutencaoCamposService],
        useFactory: (service: RHManutencaoCamposService): Promise<Array<IRHManutencaoCamposField>> => {
          return service.getCampos().then((response) => response.body);
        }
      }
    ]
  }
};
