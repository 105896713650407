import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {ContratosPrintModuleComponent} from './contratos.print.module.component';

import {MODULE_NAME_CONTRATOS_PRINT} from './contratos.print.module.interface';
import {ReportsRegistryService} from '../../../components/reports/reports.registry.service';
import {EReport} from '../../../entities/reports/reports.interface';
import {IJsonReport} from '../../../entities/reports/jsonReport.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IJsonClasseContrato} from '../../../entities/classescontratos/jsonClasseContrato.entity.interface';
import {ENTITY_NAME_CLASSES_CONTRATOS} from '../../../entities/classescontratos/classesContratos.entity.interface';

export const MODULE_CONTRATOS_PRINT: IModuleDefinition = {
  name: MODULE_NAME_CONTRATOS_PRINT,
  state: {
    url: `/${MODULE_NAME_CONTRATOS_PRINT}`,
    component: ContratosPrintModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.contratosprint'
    },
    resolve: [
      {
        provide: 'defaultReport',
        deps: [ReportsRegistryService],
        useFactory: async (reportsRegistryService: ReportsRegistryService): Promise<IJsonReport | undefined> => {
          const reports: Array<IJsonReport> = await reportsRegistryService.get(EReport.Contratos).query({pagina: 1, porpagina: 1});
          if (reports.length) {
            return reports[0];
          }
          return undefined;
        }
      },
      {
        provide: 'classesContratos',
        deps: [EntityServiceBuilder],
        useFactory: async (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonClasseContrato>> => {
          const serviceClassesContratos = entityServiceBuilder.build<IJsonClasseContrato>(ENTITY_NAME_CLASSES_CONTRATOS);
          return (await serviceClassesContratos.query()).body.list;
        }
      }
    ]
  }
};
