import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_RET_SERV_NIF} from './tiposRetServNIF.datasource.interface';
import {ETipoRetServNIF} from '../../entities/nifs/jsonNifs.entity.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPOS_RET_SERV_NIF: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_RET_SERV_NIF,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoRetServNIF.AdquirenteServicos, name: 'tiposRetServNIF.data.adquirenteDeServicos'},
    {value: ETipoRetServNIF.PrestadorServicos, name: 'tiposRetServNIF.data.prestadoraDeServicos'}
  ]
});
