import {Component, Injector} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EReciboTipoDistribuicao, IReciboDistribuirValor} from '../../recibos.entity.interface';
import {focusElement} from '../../../../../common/utils/element.utils';

@Component({
  selector: 'recibos-distribuivalor-modal',
  templateUrl: './recibos.distribuiValor.modal.component.html'
})
export class RecibosDistribuivalorModalComponent extends CGModalComponent<IReciboDistribuirValor> {
  public readonly tipoDistribuicao: typeof EReciboTipoDistribuicao;

  public model: IReciboDistribuirValor;

  constructor(protected readonly _injector: Injector) {
    super(_injector);

    this.tipoDistribuicao = EReciboTipoDistribuicao;
    this.model = {
      valor: 0,
      tipoDistribuicao: EReciboTipoDistribuicao.Automatico
    };
  }

  public close(): void {
    super.close(this.model);
  }

  public tipoDistribuicaoChanged(tipo: EReciboTipoDistribuicao): void {
    this.model.tipoDistribuicao = tipo;
    focusElement(this._element.querySelector<HTMLElement>('input[name="valorRecibo"]'));
  }
}
