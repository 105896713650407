<pl-form>
  <div class="trfba-config-modal">
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'trfba.btn.configSEPA'"></h5>
    </div>

    <div class="modal-body" [plPromise]="promise">
      <pl-tabs>
        <pl-tab>
          <div *plTabTitle>
            <span [translate]="'trfba.modal.config.steps.outraidprivadas'"></span>
          </div>

          <div *plTabContent>
            <pl-group>
              <pl-group>
                <label [translate]="'trfba.modal.config.fields.iban'"></label>
                <edit>
                  <pl-edit type="text" [(model)]="iban" plAutoFocus></pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <label [translate]="'trfba.modal.config.fields.outraIDPrivada'"></label>
                <edit>
                  <pl-edit type="text" [(model)]="outraIDPrivada"></pl-edit>
                </edit>
                <actions>
                  <pl-button klass="btn btn-sm btn-primary action-add ms-auto" id="addNewLine" (evtClicked)="addLine()">
                    <i class="fa fa-fw fa-plus"></i>&nbsp;<span [translate]="'global.btn.add'"></span>
                  </pl-button>
                </actions>
              </pl-group>
            </pl-group>

            <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="configSEPA" [dataSource]="dataGridDefinition.dataSource" (onInitialized)="onInitialized($event)">
              <div *dxTemplate="let item of 'cellTemplateBtn'">
                <pl-tooltip [config]="{text: 'trfba.messages.removeline', placement: 'left', container: 'body', tooltipClass: 'tooltip-danger'}">
                  <button type="button" *plTooltipContent class="btn btn-danger btn-xs" (click)="removeIban(item.data)">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </pl-tooltip>
              </div>
            </dx-data-grid>
          </div>
        </pl-tab>

        <pl-tab>
          <div *plTabTitle>
            <span [translate]="'trfba.modal.config.steps.template'"></span>
          </div>

          <div *plTabContent>
            <pl-group>
              <label [translate]="'trfba.modal.config.fields.regTempSal'"></label>
              <edit>
                <pl-edit
                  type="radiogroup"
                  attrName="regTempSal"
                  [model]="trfBaConfig.regTempSal"
                  (modelChange)="trfBaConfig.regTempSal = $event; templateChanged()"
                  [properties]="radioGroupTemplate"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </pl-tab>
      </pl-tabs>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
    </div>
  </div>
</pl-form>
