import {Injectable} from '@angular/core';
import {EMascaraAnaliticaTipo} from './jsonMascaraAnalitica.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IMascaraAnalitica} from './mascaraAnalitica.entity.interface';
import {THttpQueryResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class MascarasAnaliticaService {
  private readonly _service: IEntityService<IMascaraAnalitica>;

  constructor(private readonly _entityServiceBuilder: EntityServiceBuilder) {
    this._service = this._entityServiceBuilder.build<IMascaraAnalitica>('mascarasanalitica');
  }

  public getMascaraPorDefeito(): Promise<IMascaraAnalitica> {
    return new Promise<IMascaraAnalitica>((resolve, reject) => {
      this._service
        .query({pesquisa: `tipo=${EMascaraAnaliticaTipo.FATURACAO}&isDefault=true`})
        .then((response: THttpQueryResponse<IMascaraAnalitica>) => {
          resolve(response.body.list[0]);
        })
        .catch(reject);
    });
  }
}
