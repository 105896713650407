import {Directive, TemplateRef} from '@angular/core';
import {ICGTableItemTemplateContext} from './table.interface';

@Directive({
  selector: 'ng-template[itemDetail]',
  standalone: false
})
export class PlTableItemDetailDirective {
  constructor(public readonly templateRef: TemplateRef<ICGTableItemTemplateContext>) {}
}
