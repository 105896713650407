<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'refeicoes.clonarmodal.title'"></h4>
</div>

<div class="modal-body">
  <div class="entity-detail-form">
    <pl-form>
      <pl-group>
        <label><span [translate]="'refeicoes.clonarmodal.fields.localDestino'"></span>:</label>
        <edit>
          <pl-multiselect [(model)]="locaisSelected" attrName="localDestino" [source]="locais" key="codLocal" [template]="templateMultiSelect"></pl-multiselect>
        </edit>
      </pl-group>

      <pl-group>
        <pl-group>
          <label><span [translate]="'refeicoes.clonarmodal.fields.dataInicio'"></span>:</label>
          <edit>
            <pl-edit-datepicker [(model)]="dataInicio" attrName="dataInicio"></pl-edit-datepicker>
          </edit>
        </pl-group>

        <pl-group>
          <label><span [translate]="'refeicoes.clonarmodal.fields.dataFim'"></span>:</label>
          <edit>
            <pl-edit-datepicker [(model)]="dataFim" attrName="dataFim"></pl-edit-datepicker>
          </edit>
        </pl-group>
      </pl-group>
    </pl-form>
  </div>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary" (evtClicked)="cloneMenu()"><i class="fa fa-fw fa-clone"></i>&nbsp;<span [translate]="'global.btn.clone'"></span></pl-button>

  <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-ban"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
</div>
