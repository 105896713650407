import {Subscription} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';
import {StateService} from '@uirouter/core';
import {AppService} from '../../../services/app/app.service';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {CGStateService} from '../../../components/state/cg.state.service';
import {EAppLaunchMode} from '../../../../common/site';
import {IAppStatus} from '../../../services/app/app.service.interface';
import {PortalChangeFirmModalComponent} from '../../../components/portal/modals/changefirm/portal.changefirm.modal.component';
import {STATE_NAME_LOGIN} from '../login/login.state.interface';

@Component({
  selector: 'no-authority',
  templateUrl: './noauthority.state.component.html'
})
export class NoAuthorityComponent implements OnDestroy {
  public partnerMode: boolean;
  public hybridMode: boolean;

  private readonly _subscriptionStatus: Subscription;

  constructor(
    private readonly _stateService: StateService,
    private readonly _cgStateService: CGStateService,
    private readonly _appService: AppService,
    private readonly _cgModalService: CGModalService
  ) {
    this.back = this.back.bind(this);
    this.mudaEmpresa = this.mudaEmpresa.bind(this);
    this.login = this.login.bind(this);
    this.partnerMode = false;
    this.hybridMode = false;
    this._subscriptionStatus = this._appService.status().subscribe((status: IAppStatus) => {
      this.partnerMode = status.launchMode === EAppLaunchMode.Partner;
      this.hybridMode = status.launchMode === EAppLaunchMode.Hybrid || status.launchMode === EAppLaunchMode.HybridPartial;
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionStatus.unsubscribe();
  }

  public async back(): Promise<void> {
    try {
      await this._cgStateService.goBack();
    } catch (error: unknown) {
      await this._cgStateService.goHome();
    }
  }

  public async mudaEmpresa(): Promise<void> {
    await this._cgModalService.show(PortalChangeFirmModalComponent);
    await this._cgStateService.goHome();
  }

  public async login(): Promise<void> {
    await this._stateService.go(STATE_NAME_LOGIN);
  }
}
