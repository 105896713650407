<div class="contab-digital-config-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'arquivodigital.configs.title'"></h4>
  </div>

  <div class="modal-body">
    <div class="contab-digital-config-modal-header" *ngIf="!comercialDigital && !modoCGOn && !temContabilidadeDigitalClientConnect">
      <button type="button" class="btn btn-sm btn-light me-2" *ngIf="false" [click]="fnGetSynchronizationKey" plPromise>
        <i class="fa fa-fw fa-key"></i>&nbsp;<span [translate]="'arquivodigital.configs.actions.obterChaveSincro'"></span>
      </button>

      <button type="button" class="btn btn-sm btn-primary" *ngIf="!temContabilidadeDigitalClientConnect" [click]="fnActivateClientConnect" plPromise>
        <i class="fa fa-fw fa-refresh"></i>&nbsp;<span [translate]="'arquivodigital.configs.actions.ativarClientConnect'"></span>
      </button>

      <hr />
    </div>

    <pl-form class="entity-detail-form">
      <pl-navpill>
        <pl-navpill-panel caption="arquivodigital.configs.tabs.defgerais.title" *ngIf="!comercialDigital">
          <div *plNavPillPanelContent>
            <fieldset>
              <legend [translate]="'arquivodigital.configs.tabs.defgerais.carddefgerais'"></legend>

              <pl-group>
                <edit>
                  <pl-edit type="checkbox" [(model)]="configs.recolhaAnexaAuto" [properties]="{label: 'arquivodigital.configs.fields.recolhaAnexaAuto'}" plAutoFocus></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <edit>
                  <pl-edit type="checkbox" [(model)]="configs.dataLancamIgualDataDoc" [properties]="{label: 'arquivodigital.configs.fields.dataLancamIgualDataDoc'}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <edit>
                  <pl-edit type="checkbox" [(model)]="configs.abreSempreVisualizador" [properties]="{label: 'arquivodigital.configs.fields.abreSempreVisualizador'}" plAutoFocus></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <edit>
                  <pl-edit type="checkbox" [(model)]="configs.usaDiarioConfigSugestLancam" [properties]="{label: 'arquivodigital.configs.fields.usaDiarioConfigSugestLancam'}" plAutoFocus></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'arquivodigital.configs.fields.ordenacaoVisualizador'"></label>
                <edit>
                  <pl-edit type="tiposOrdenacaoVisualizador" [(model)]="configs.ordenacaoVisualizador"></pl-edit>
                </edit>
              </pl-group>
            </fieldset>
          </div>
        </pl-navpill-panel>

        <pl-navpill-panel caption="arquivodigital.configs.tabs.defgerais.cardconfigpastas">
          <div *plNavPillPanelContent>
            <dx-data-grid
              [cgDxDataGrid]="dataGridDefinitionConfigsPastas"
              cgDxDataGridInstanceName="configsPastas"
              [dataSource]="dataGridSourceConfigsPastas"
              (onInitialized)="onDataGridConfigsPastasInitialized($event)"
              (onDisposing)="onDataGridConfigsPastasDisposing()"
              (onEditingStart)="onDataGridConfigsPastasEditingStart($event)"
              (onInitNewRow)="onDataGridConfigsPastasNewRow($event)"
              (onRowInserting)="onDataGridConfigsPastasRowInserting($event)">
              <div *dxTemplate="let cellInfo of 'editCellDiario'">
                <entity-autocomplete
                  entity="diarios"
                  attrName="diario"
                  [model]="cellInfo.data"
                  (selectedKeyChange)="cellInfo.data.nDiario = $event"
                  (modelChange)="changedConfigPastasCellDiario(cellInfo, $event)"
                  output="key">
                </entity-autocomplete>
              </div>

              <div *dxTemplate="let cellInfo of 'editCellDescritivo'">
                <entity-autocomplete
                  entity="descritivos"
                  attrName="descritivo"
                  [model]="cellInfo.data"
                  (selectedKeyChange)="cellInfo.data.nDescritivo = $event"
                  (modelChange)="changedConfigPastasCellDescritivo(cellInfo, $event)"
                  output="key">
                </entity-autocomplete>
              </div>

              <div *dxTemplate="let cellInfo of 'headerCellTemplateRepcc'">
                <div class="header-cell-template-repcc">
                  <span [translate]="'arquivodigital.configs.fields.nCodRepcc'"></span>
                  <i class="fa fa-fw fa-info-circle" aria-hidden="true" [title]="'arquivodigital.configs.messages.repccinfo' | translate"></i>
                </div>
              </div>

              <div *dxTemplate="let cellInfo of 'editCellRepcc'">
                <entity-autocomplete
                  entity="reparticoesccustosfaturacao"
                  attrName="reparticoesccustos"
                  [model]="cellInfo.data"
                  (modelChange)="changedConfigPastasCellRepcc(cellInfo, $event)"
                  [fieldsMap]="{nCodRepCC: 'nCodRepcc', descricao: 'nomeCodRepcc'}">
                </entity-autocomplete>
              </div>

              <div *dxTemplate="let cellInfo of 'editCellNDocfaFT'">
                <entity-autocomplete
                  entity="docfas"
                  attrName="nDocFaFT"
                  [model]="cellInfo.data"
                  (modelChange)="changedConfigPastasCellDocFa(cellInfo, $event, 'FT')"
                  [fieldsMap]="{nDocFa: 'nDocfaFT'}"
                  [filter]="docfaFilter"
                  output="key">
                </entity-autocomplete>
              </div>

              <div *dxTemplate="let cellInfo of 'editCellNDocfaFR'">
                <entity-autocomplete
                  entity="docfas"
                  attrName="nDocFaFR"
                  [model]="cellInfo.data"
                  (modelChange)="changedConfigPastasCellDocFa(cellInfo, $event, 'FR')"
                  [fieldsMap]="{nDocFa: 'nDocfaFR'}"
                  [filter]="docfaFilter"
                  output="key">
                </entity-autocomplete>
              </div>

              <div *dxTemplate="let cellInfo of 'editCellNDocfaNC'">
                <entity-autocomplete
                  entity="docfas"
                  attrName="nDocFaNC"
                  [model]="cellInfo.data"
                  (modelChange)="changedConfigPastasCellDocFa(cellInfo, $event, 'NC')"
                  [fieldsMap]="{nDocFa: 'nDocfaNC'}"
                  [filter]="docfaFilter"
                  output="key">
                </entity-autocomplete>
              </div>

              <div *dxTemplate="let cellInfo of 'editCellNDocfaNCPagaNoAto'">
                <entity-autocomplete
                  entity="docfas"
                  attrName="nDocFaNCPagaNoAto"
                  [model]="cellInfo.data"
                  (modelChange)="changedConfigPastasCellDocFa(cellInfo, $event, 'NCPagaNoAto')"
                  [fieldsMap]="{nDocFa: 'nDocfaNCPagaNoAto'}"
                  [filter]="docfaFilter"
                  output="key">
                </entity-autocomplete>
              </div>

              <div *dxTemplate="let cellInfo of 'editCellNCCusto'">
                <entity-autocomplete entity="ccustos" attrName="nCCusto" [model]="cellInfo.data" (modelChange)="changedConfigPastasCellCCusto(cellInfo, $event)" [filter]="'encerrado=0'" output="key">
                </entity-autocomplete>
              </div>

              <div *dxTemplate="let cellInfo of 'editCellNRefProcesso'">
                <entity-autocomplete entity="processos" attrName="nRefProcesso" [model]="cellInfo.data" (modelChange)="changedConfigPastasCellRefProcesso(cellInfo, $event)" output="key">
                </entity-autocomplete>
              </div>
            </dx-data-grid>
          </div>
        </pl-navpill-panel>

        <pl-navpill-panel caption="arquivodigital.configs.tabs.efatura.title" *ngIf="!comercialDigital">
          <div *plNavPillPanelContent>
            <fieldset class="mb-3">
              <legend [translate]="'arquivodigital.configs.tabs.efatura.comprasefatura'"></legend>

              <pl-group>
                <edit>
                  <pl-edit type="checkbox" [(model)]="configs.searchEFaturaSoNdocParcial" [properties]="{label: 'arquivodigital.configs.fields.searchEFaturaSoNdocParcial'}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <edit>
                  <pl-edit type="checkbox" [(model)]="configs.searchEFaturaSoMesmoDiario" [properties]="{label: 'arquivodigital.configs.fields.searchEFaturaSoMesmoDiario'}"></pl-edit>
                </edit>
              </pl-group>
            </fieldset>
          </div>
        </pl-navpill-panel>

        <pl-navpill-panel caption="arquivodigital.configs.tabs.lancautorobo.title" *ngIf="!comercialDigital && configs.modoLancarDocsAutoDisponivel">
          <div *plNavPillPanelContent>
            <pl-group>
              <edit>
                <pl-edit type="checkbox" [(model)]="configs.lancaDocsAuto" [properties]="{label: 'arquivodigital.configs.tabs.lancautorobo.lancaDocsAuto'}"></pl-edit>
              </edit>
            </pl-group>

            <fieldset class="mb-3">
              <legend [translate]="'arquivodigital.configs.tabs.lancautorobo.origemPeriodoLancAuto'"></legend>
              <pl-edit type="radiogroup" [(model)]="configs.origemPeriodoLancAuto" [properties]="radioGroupOrigemPeriodoLancAuto"></pl-edit>
            </fieldset>

            <pl-alert type="info" [closeable]="false">
              <p [translate]="'arquivodigital.configs.tabs.lancautorobo.message1'"></p>
              <p [translate]="'arquivodigital.configs.tabs.lancautorobo.message2'"></p>
              <p [translate]="'arquivodigital.configs.tabs.lancautorobo.message3'"></p>
              <p [translate]="'arquivodigital.configs.tabs.lancautorobo.message4'"></p>
            </pl-alert>
          </div>
        </pl-navpill-panel>

        <pl-navpill-panel *ngIf="!comercialDigital">
          <div *plNavPillPanelTitle>
            <span [translate]="'arquivodigital.configs.tabs.opcoesocr.titleSimple'" [attr.title]="'arquivodigital.configs.tabs.opcoesocr.title' | translate"></span>
          </div>
          <div *plNavPillPanelContent>
            <pl-group>
              <edit>
                <pl-edit type="checkbox" [(model)]="configs.removePaginasBrancoAuto" [properties]="{label: 'arquivodigital.configs.tabs.opcoesocr.removePaginasBrancoAuto'}"></pl-edit>
              </edit>
            </pl-group>

            <fieldset>
              <legend [translate]="'arquivodigital.configs.tabs.opcoesocr.cardtitle'"></legend>

              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionOCR" cgDxDataGridInstanceName="opcoesocr" [dataSource]="configs.listaNIFsDuplicarQRCode">
                <div *dxTemplate="let cellInfo of 'templateToolbarAddNIF'">
                  <div class="d-flex align-items-center fw-bold">
                    <span [translate]="'arquivodigital.configs.tabs.opcoesocr.table.nif'" class="me-1"></span>
                    <pl-edit type="text" [(model)]="nContribuinte">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <pl-tooltip [config]="{text: 'global.btn.add'}">
                          <i *plTooltipContent class="fa fa-plus-circle fa-fw text-primary" aria-hidden="true" (click)="addNContribuinte()"></i>
                        </pl-tooltip>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div *dxTemplate="let cellInfo of 'templateToolbarAddEDPVIAVERDE'">
                  <div ngbDropdown container="body" class="col-main-table-actions">
                    <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle>
                      <i class="fa fa-plus-circle me-1"></i>
                      <span [translate]="'arquivodigital.configs.tabs.opcoesocr.table.addedpviaverde'"></span>
                    </button>
                    <div ngbDropdownMenu>
                      <button
                        type="button"
                        class="dropdown-item"
                        [translate]="'arquivodigital.configs.tabs.opcoesocr.table.addedp'"
                        (click)="addEDPViaVerde(regContabDigitalNifEDPViaVerde.EPD)"></button>
                      <button
                        type="button"
                        class="dropdown-item"
                        [translate]="'arquivodigital.configs.tabs.opcoesocr.table.addviaverde'"
                        (click)="addEDPViaVerde(regContabDigitalNifEDPViaVerde.ViaVerde)"></button>
                    </div>
                  </div>
                </div>
              </dx-data-grid>
            </fieldset>
          </div>
        </pl-navpill-panel>
      </pl-navpill>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-primary action-submit" [onClick]="close"><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-light action-cancel" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
