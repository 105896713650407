import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonLocal} from './jsonLocal.entity.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_LOCAL} from './local.entity.interface';

export const ENTITY_LOCAL: IEntityDefinition<IJsonLocal> = {
  name: ENTITY_NAME_LOCAL,
  roles: [ROLE.ATIVOS, ROLE.RH],
  searchPlaceholder: 'local.pesquisa',
  metadata: {
    keyName: 'nLocalidade',
    fields: [
      {name: 'nLocalidade', caption: 'local.fields.nLocalidade', placeholder: 'local.fields.nLocalidadePlaceholder', validators: {required: true, maxlength: 4}},
      {name: 'designaBreve', caption: 'local.fields.designaBreve', placeholder: 'local.fields.designaBrevePlaceholder', validators: {required: true, maxlength: 20}},
      {name: 'designaCompl', caption: 'local.fields.designaCompl', placeholder: 'local.fields.designaComplPlaceholder', validators: {required: true, maxlength: 60}}
    ],
    order: 'nLocalidade',
    searchFields: 'nLocalidade,designaBreve',
    listFields: 'nLocalidade,designaBreve,designaCompl',
    newFields: 'nLocalidade,designaBreve,designaCompl'
  },
  autocomplete: {
    rowTemplate: '{{nLocalidade}} - {{designaBreve}}',
    output: 'designaBreve',
    searchFields: 'nLocalidade,designaBreve'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.local'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.local'
      }
    }
  }
};
