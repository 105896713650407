import {Ng2StateDeclaration} from '@uirouter/angular';
import {LoginComponent} from './login.state.component';
import {RESOLVER_RECAPTCHA_CONFIG} from '../../../services/recaptcha/recaptcha.router';
import {STATE_NAME_ACCOUNT} from '../account.state.interface';
import {STATE_NAME_LOGIN} from './login.state.interface';

export const STATE_LOGIN: Ng2StateDeclaration = {
  parent: STATE_NAME_ACCOUNT,
  name: STATE_NAME_LOGIN,
  url: `/${STATE_NAME_LOGIN}?redirectto`,
  component: LoginComponent,
  data: {
    pageTitle: 'login.title'
  },
  params: {
    redirectto: {
      type: 'string',
      value: '',
      squash: true
    },
    redirectparams: {
      type: 'json',
      value: null
    }
  },
  resolve: [RESOLVER_RECAPTCHA_CONFIG]
};
