import moment from 'moment';
import {asyncScheduler} from 'rxjs';
import {merge, union, uniq} from 'lodash-es';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {
  copy,
  EScreenSize,
  getScreenWidth,
  ICGCTableData,
  IPlDynamicVisualsSecondaryClickAction,
  IPlLifeCycleEvent,
  IPlToolbarItem,
  IPlToolbarMenuItem,
  isArray,
  isDefined,
  isEmpty,
  isFunction,
  isNumber,
  isObject,
  isString,
  PlAlertService,
  PlTranslateService
} from 'pl-comps-angular';
import {CGCardPanelComponent} from '../../../components/cg/cardpanel/cardpanel.component';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {ConciliacaoEFaturasModuleExtratosModalComponent} from '../conciliacaoEFaturas/modal/extratos/conciliacaoEFaturas.module.extratos.modal';
import {ConfigEfaturaDocPorLancModalComponent} from '../../configEfaturaDocPorLancar/modals/configEfaturaDocPorLanc.modal.component';
import {ConfigEfaturaDocPorLancSupplierModalComponent} from '../../configEfaturaDocPorLancar/modals/supplier/configEfaturaDocPorLanc.supplier.modal.component';
import {CreateNewContaCorrenteModalComponent} from './modals/createNewContaCorrente/createNewContaCorrente.modal.component';
import {
  DEV_EXPRESS_DATA_GRID_LOOKUP_INPORTADOR_EFATURA_DOC_DIGITAL,
  EImportadorEFaturaDocDigital,
  IImportadorEFaturaDocumento,
  IImportadorEfaturaFilter,
  IImportadorEfaturaItem,
  IImportadorEFaturaLastDocItem,
  IImportadorEFaturaSource,
  IImportadorResultFindInSource
} from './importadorEfatura.module.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DocContabilidadeService} from '../docscontabilidade/components/doccontabilidade/docContabilidade.service';
import {DocsContabilidadeService} from '../docscontabilidade/service/docsContabilidade.service';
import {EConfigOptionsInstanceName} from '../../../services/config/options/config.options.service.interface';
import {EFATURA_TIPO_FATURA_TODOS, IEFaturaDocumentoLinha, IEFaturaTipoFatura} from './service/importadorEfatura.service.interface';
import {EFaturaConfigDocsService} from '../../configEfaturaDocPorLancar/eFaturaConfigDocs.module.service';
import {EGroupName} from '../../../../config/constants';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocContabilidade, IDocsContabilidadeEntityService, IDocsContabilidadePostParams} from '../docscontabilidade/docsContabilidade.interface';
import {ENTITY_NAME_FORNECEDORES} from '../../../entities/clifos/clifos.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {EXCEPTION_VERIFICA_EFATURAS_CALL_LOGIN} from '../../../../common/exceptions';
import {focusElement} from '../../../../common/utils/element.utils';
import {IArquivoDigitalDocViewerEvtChangedAttachment} from '../../../components/arquivodigital/common/docviewer/arquivodigital.docviewer.component.interface';
import {ICGContabExtratosFilters} from '../../../components/contabilidade/extratos/extratos.interfaces';
import {IContabDigitalDocViewerRecolhaCallback} from '../../../components/arquivodigital/contabilidade/docviewerrecolha/contabilidadedigital.docviewer.recolha.component.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContentReady,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowCollapsed
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDocContabilidadeContabDigitalAttachment, IDocContabilidadeLoadPreDefinidoParams} from '../docscontabilidade/components/doccontabilidade/docContabilidade.interface';
import {IJsonClifo} from '../../../entities/clifos/jsonClifo.entity.interface';
import {IJsonContabDigitalGDocViewerRecolhaSearch} from '../../../services/contabilidadedigital/jsonContabDigital.interface';
import {IJsonDocContabilidade, IJsonDocContabilidadeLinha} from '../docscontabilidade/jsonDocContabilidade.interface';
import {IJsonEFaturaConfigDocs} from '../../configEfaturaDocPorLancar/jsonEFaturaConfigDocs.module.interface';
import {ImportadorEfaturaLancarDocsEmSerieModalComponent} from './modals/lancardocsemserie/importadorEfatura.lancarDocsEmSerie.modal.component';
import {ImportadorEfaturaService} from './service/importadorEfatura.service';
import {ModuloComponent} from '../../../components/module/module.component';
import {SimpleLoginModalComponent} from '../../simplelogin/modals/simpleLogin.modal.component';
import {TDate} from '../../../../common/dates';
import {DevExpressDataGridUIService} from '../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';

const ELEMENT_NAME_FORNECEDORES = 'fornecedores';
const SAVE_PROMPT_IDENTIFIER = 'importador-e-fatura-doc';
const TOOLBAR_ITEM_SAVE_DOC_ID = 'itemToolbarSaveDoc';
const TOOLBAR_ITEM_SAVE_DOC_ID_AND_ATTACH = 'itemToolbarSaveDocAndAttach';
const MAIN_TAB_ID = 'importador-e-fatura-mainTab';
const MAIN_DATAGRID_KEY_ID = 'numerodocumento';
const DATAGRID_INSTANCE_NAME = 'importadorEfaturaDataGrid';

@Component({
  selector: 'importador-e-fatura',
  templateUrl: './importadorEfatura.module.component.html'
})
export class ImportadorEfaturaComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public empresaTemContabilidadeDigital: boolean;
  @Input() public filters: IImportadorEfaturaFilter;
  @Input() public tiposFatura: Array<IEFaturaTipoFatura>;

  public readonly attrNameFornecedores: string;
  public readonly entityFornecedores: string;
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly definitionsDataGrid: {main: IDevExpressDataGrid; detalhes: IDevExpressDataGrid};
  public readonly mainTabId: string;
  public readonly dataGridInstanceName: string;
  public readonly callbackDocViewer: IContabDigitalDocViewerRecolhaCallback;
  public contabilidadeDigital: boolean;
  public lancarDocsEmSerie: boolean;
  public lancarDocsEmSerieCount: number;
  public lastDocsSource: Array<IImportadorEFaturaLastDocItem>;
  public lastDocsTabs: Array<IImportadorEFaturaLastDocItem>;
  public selectAll: boolean;
  public showLastDocs: boolean;
  public tabsActiveId: string;
  public isMediumOrHigherScreen: boolean;
  public contabilidadeDigitalSearch: IJsonContabDigitalGDocViewerRecolhaSearch;
  public tableSource: Array<IImportadorEfaturaItem>;
  public warningsList: Array<string>;
  public errorList: Array<string>;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private readonly _registeredToolbarInstances: Set<string>;
  private readonly _docsModel: Map<string, IJsonDocContabilidade>;
  private readonly _contabilidadeDigitalAttachment: IDocContabilidadeContabDigitalAttachment;
  private readonly _tableFieldDocDigital: IDevExpressDataGridColumn;
  private readonly _tableFieldPreDefinido: IDevExpressDataGridColumn;
  private _eFaturaConfigDocs: IJsonEFaturaConfigDocs;
  private _firstLoad: boolean;
  private _firstContentReady: boolean;
  private _fromCacheList: boolean;
  private _tableCacheSource: Array<IImportadorEfaturaItem>;
  private _lastDocsPromise: Promise<void>;
  private _dataGridInstance: dxDataGrid<IImportadorEfaturaItem, string>;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _importadorEfaturaService: ImportadorEfaturaService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _docContabilidadeService: DocContabilidadeService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _docsContabilidadeService: DocsContabilidadeService,
    private readonly _eFaturaConfigDocsService: EFaturaConfigDocsService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService
  ) {
    super(_injector);
    this.search = this.search.bind(this);
    this.mainTabId = MAIN_TAB_ID;
    this.dataGridInstanceName = DATAGRID_INSTANCE_NAME;
    this.attrNameFornecedores = ELEMENT_NAME_FORNECEDORES;
    this.entityFornecedores = ENTITY_NAME_FORNECEDORES;
    this.configOptionsInstanceName = EConfigOptionsInstanceName.IMPORTADOR_EFATURA;
    this.configOptionsGroupName = EGroupName.CONTABILIDADE;
    this.callbackDocViewer = {};
    this.isMediumOrHigherScreen = getScreenWidth() >= EScreenSize.MEDIUM;
    this._tableFieldDocDigital = {
      dataField: 'docDigital',
      caption: 'docscontabilidade.fields.temDocDigital',
      alignment: 'center',
      lookup: DEV_EXPRESS_DATA_GRID_LOOKUP_INPORTADOR_EFATURA_DOC_DIGITAL,
      width: 80
    };

    this._tableFieldPreDefinido = {
      dataField: 'preDefinido',
      caption: 'importadorEfatura.fields.preDefinido'
    };

    if (!this.isMediumOrHigherScreen) {
      this._tableFieldPreDefinido.hidingPriority = 5;
    }

    this.definitionsDataGrid = {
      main: {
        keyExpr: MAIN_DATAGRID_KEY_ID,
        columns: [
          {dataField: 'nifEmitente', caption: 'importadorEfatura.fields.nifEmitente', cellTemplate: 'cellTemplateNifEmitente'},
          {dataField: 'nomeEmitente', caption: 'importadorEfatura.fields.nomeEmitente', width: 250},
          {dataField: 'numerodocumento', caption: 'conciliacaoEFaturas.fields.numerodocumento', width: 140, hidingPriority: 3},
          {
            dataField: 'dataEmissaoDocumento',
            caption: 'importadorEfatura.fields.dataEmissaoDocumento',
            dataType: 'date',
            width: 120
          },
          {dataField: 'actividadeEmitenteDesc', caption: 'importadorEfatura.fields.actividadeDesc', visible: false, hidingPriority: 2},
          {dataField: 'tipoDocumentoDesc', caption: 'importadorEfatura.fields.tipoDocumentoDesc', hidingPriority: 4},
          {dataField: 'registadoPorStr', caption: 'importadorEfatura.fields.registadoPor', visible: false, hidingPriority: 1},
          {dataField: 'actividadeProfDesc', caption: 'importadorEfatura.fields.actividadeProfDesc', visible: false, hidingPriority: 0},
          {
            dataField: 'valorTotalBaseTributavel',
            caption: 'importadorEfatura.fields.valorTotalBaseTributavel',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          {
            dataField: 'valorTotalIva',
            caption: 'importadorEfatura.fields.valorTotalIva',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          {
            dataField: 'valorTotal',
            caption: 'importadorEfatura.fields.valorTotal',
            dataType: 'number',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          this._tableFieldPreDefinido,
          this._tableFieldDocDigital,
          {
            dataField: 'vf_actions',
            caption: ' ',
            cellTemplate: 'actions',
            allowHiding: false,
            allowSearch: false,
            allowReordering: false,
            allowSorting: false,
            allowFiltering: false,
            allowResizing: false,
            allowHeaderFiltering: false,
            allowGrouping: false,
            allowFixing: false,
            allowEditing: false,
            allowExporting: false,
            showInColumnChooser: false
          }
        ],
        selection: {
          showCheckBoxesMode: 'always'
        },
        masterDetail: {
          enabled: true,
          template: 'detail'
        },
        paging: {
          pageSize: 25
        }
      },
      detalhes: {
        filterRow: {
          visible: false
        },
        headerFilter: {
          visible: false
        },
        searchPanel: {
          visible: false
        },
        columns: [
          {
            dataField: 'valorBaseTributavel',
            caption: 'importadorEfatura.fields.baseTributavel',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          {
            dataField: 'taxaIva',
            caption: 'importadorEfatura.fields.taxa',
            format: "#0.##'%'",
            alignment: 'right'
          },
          {
            dataField: 'valorIva',
            caption: 'importadorEfatura.fields.valorTotalIva',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          },
          {
            dataField: 'valorTotal',
            caption: 'importadorEfatura.fields.total',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: 2
            }
          }
        ],
        pager: {
          visible: false
        },
        paging: {
          pageSize: 25
        }
      }
    };

    this.tableSource = [];
    this.warningsList = [];
    this.errorList = [];
    this.tabsActiveId = MAIN_TAB_ID;
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._registeredToolbarInstances = new Set<string>();
    this._docsModel = new Map<string, IJsonDocContabilidade>();
    this._contabilidadeDigitalAttachment = {gDocId: undefined, gDocFolderId: undefined, docOCRCabID: undefined};
    this._eFaturaConfigDocsService.getEFaturaConfigsDocs().then((response) => {
      this._eFaturaConfigDocs = response.body;
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (!isArray(this.tiposFatura)) {
      this.tiposFatura = [{...EFATURA_TIPO_FATURA_TODOS}];
    }

    this.filters = {
      fromDate: moment(),
      toDate: moment().endOf('month'),
      tipo: this.tiposFatura[0],
      nifFornecedor: '',
      ...this.filters
    };

    this.contabilidadeDigital = this.empresaTemContabilidadeDigital;
    this.lancarDocsEmSerieCount = 0;
    this.showLastDocs = false;
    this.lastDocsSource = [];
    this.lastDocsTabs = [];
    this._tableFieldDocDigital.visible = this.contabilidadeDigital;
    if (!this.empresaTemContabilidadeDigital) {
      this._tableFieldDocDigital.allowHiding = false;
    }
    this._firstLoad = true;
    this._fromCacheList = false;
    this._tableCacheSource = [];

    if (this.empresaTemContabilidadeDigital) {
      this._setContabilidadeDigitalSearch(undefined);
      this.toolbar.addButton({
        id: 'contabilidadeDigital',
        type: 'button',
        order: 3,
        class: !this.contabilidadeDigital ? 'btn-info' : 'btn-info active',
        iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>&nbsp;',
        caption: 'docscontabilidade.toolbar.contabilidadeDigital',
        click: (btnContabilidadeDigital: IPlToolbarItem) => {
          this.contabilidadeDigital = !this.contabilidadeDigital;
          btnContabilidadeDigital.class = !this.contabilidadeDigital ? 'btn-info' : 'btn-info active';
        }
      });
    }

    const btnConfigEntidades: IPlToolbarMenuItem = {
      id: 'configEntidade',
      type: 'button',
      caption: 'importadorEfatura.configPreDefinidosTitle',
      click: () => this.openConfigPreDefinidosSupplierModal()
    };

    this.toolbar.addButton({
      id: 'configPreDefinidosTbBtn',
      type: 'dropdown-split',
      order: 5,
      iconLeft: '<i class="fa fa-fw fa-cog"></i>&nbsp;',
      class: 'btn-light',
      caption: 'global.btn.config',
      click: () => this._openConfigPreDefinidosModal(),
      menu: [btnConfigEntidades]
    });

    this.toolbar.addButton({
      id: 'showLastDocs',
      type: 'button',
      order: 4,
      class: 'btn-primary',
      iconLeft: '<i class="fa fa-fw fa-history"></i>&nbsp;',
      caption: 'importadorEfatura.showLastDocs',
      click: (toolbarItem) => {
        const showLastDocs = !this.showLastDocs;
        const caption: string = showLastDocs ? 'importadorEfatura.hideLastDocs' : 'importadorEfatura.showLastDocs';
        if (showLastDocs) {
          return this._updateLastDocs().then(() => {
            this.showLastDocs = showLastDocs;
            toolbarItem.caption = caption;
          });
        }
        this.showLastDocs = showLastDocs;
        toolbarItem.caption = caption;
        return Promise.resolve();
      }
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    for (const toolbarInstanceId of this._registeredToolbarInstances) {
      this._plToolbarService.unRegisterInstance(toolbarInstanceId);
    }
  }

  public onFromDateChange(value: TDate): void {
    this.filters.fromDate = moment(value);
    this.filters.toDate = moment(value).endOf('month');
  }

  public onToDateChange(value: TDate): void {
    this.filters.toDate = moment(value);
  }

  public getWorkAreaCssClass(): string {
    return this.showLastDocs ? 'col-md-10' : 'col-md-12';
  }

  public lastDocClick(lastDoc: IImportadorEFaturaLastDocItem, event: Event): void {
    event.preventDefault();

    const needLoad = !this.lastDocsTabs.includes(lastDoc);
    if (needLoad) {
      this.lastDocsTabs.push(lastDoc);
    }

    // Set active tab to clicked last doc
    this.tabsActiveId = lastDoc.extPocCabID;

    if (needLoad) {
      // Register toolbar
      if (!lastDoc._toolbarInstanceId) {
        lastDoc._toolbarInstanceId = `${lastDoc.extPocCabID}-toolbar`;
      }
      const toolbarInstance = this._plToolbarService.getInstance(lastDoc._toolbarInstanceId);
      toolbarInstance.setItems([
        {
          id: 'lastDocToolbarSaveDoc',
          order: 1,
          type: 'button',
          class: 'btn-success',
          iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>&nbsp;',
          caption: 'global.btn.save',
          click: () => this._saveDoc(lastDoc.docModel, true, false, lastDoc)
        }
      ]);
      this._registeredToolbarInstances.add(lastDoc._toolbarInstanceId);

      // Load doc contabilidade
      this._importadorEfaturaService.getDocContabilidade(lastDoc.extPocCabID).then((response: HttpResponse<IDocContabilidade>) => {
        lastDoc.docModel = response.body;
      });
    }
  }

  public closedTab(tabId: string): void {
    const index = this.lastDocsTabs.findIndex((lastDoc: IImportadorEFaturaLastDocItem) => lastDoc.extPocCabID === tabId);
    if (index > -1) {
      // Unregister toolbar
      const lastDoc = this.lastDocsTabs[index];
      this._plToolbarService.unRegisterInstance(lastDoc._toolbarInstanceId);
      this._registeredToolbarInstances.delete(lastDoc._toolbarInstanceId);

      // Remove lastDoc tab
      this.lastDocsTabs.splice(index, 1);

      // Set active tab to previous one
      this.tabsActiveId = index > 0 ? this.lastDocsTabs[index - 1].extPocCabID : this.mainTabId;
    }
  }

  public changeFornecedor(fornecedor: IJsonClifo | string): void {
    this.filters.nifFornecedor = isString(fornecedor) ? fornecedor : fornecedor?.nContribuint;
  }

  public lancarDocsEmSerieChange(value: boolean): void {
    this.lancarDocsEmSerie = value;
    this._dataGridInstance.option('selection.mode', value ? 'multiple' : 'none');
  }

  public getErrorString(item: IImportadorEfaturaItem): string {
    let errors: Array<string> = [];
    if (item.erro.length) {
      errors.push(item.erro);
    }
    for (const error of item.listaErros) {
      errors.push(error.errorMessage);
    }
    errors = uniq(errors);
    return errors.join('<br>');
  }

  public itemDocModelChanged(value: IJsonDocContabilidade, item: IImportadorEfaturaItem): void {
    this._setItemDocModel(item, value);
  }

  public docContabilidadeInit(item: IImportadorEfaturaItem, {element}: IPlLifeCycleEvent): void {
    this._docContabilidadeService.listenForSaveEvent({
      identifier: `${SAVE_PROMPT_IDENTIFIER}-${item.idDocumento}`,
      callbackGetDoc: () => item.docModel,
      callbackOnSave: (attachDigitalDocs: boolean) => this._saveTableDoc(item, item.docModel, false, attachDigitalDocs),
      simulation: false,
      contabilidadeDigital: () => this.contabilidadeDigital,
      whenNode: element
    });
  }

  public docContabilidadeDestroy(item: IImportadorEfaturaItem): void {
    this._docContabilidadeService.clearForSaveEventListener(`${SAVE_PROMPT_IDENTIFIER}-${item.idDocumento}`);
  }

  public lastDocContabilidadeInit(lastDocItem: IImportadorEFaturaLastDocItem, {element}: IPlLifeCycleEvent): void {
    this._docContabilidadeService.listenForSaveEvent({
      identifier: `${SAVE_PROMPT_IDENTIFIER}-${lastDocItem.extPocCabID}`,
      callbackGetDoc: () => lastDocItem.docModel,
      callbackOnSave: () => this._saveDoc(lastDocItem.docModel, false, false, lastDocItem),
      simulation: false,
      contabilidadeDigital: false,
      whenNode: element
    });
  }

  public lastDocContabilidadeDestroy(lastDocItem: IImportadorEFaturaLastDocItem): void {
    this._docContabilidadeService.clearForSaveEventListener(`${SAVE_PROMPT_IDENTIFIER}-${lastDocItem.extPocCabID}`);
  }

  public changedContabilidadeDigitalAttachment({folder, attachment}: IArquivoDigitalDocViewerEvtChangedAttachment): void {
    this._contabilidadeDigitalAttachment.gDocId = undefined;
    this._contabilidadeDigitalAttachment.gDocFolderId = undefined;
    this._contabilidadeDigitalAttachment.docOCRCabID = undefined;
    if (this.contabilidadeDigital) {
      if (isObject(folder)) {
        this._contabilidadeDigitalAttachment.gDocFolderId = folder.folderID;
      }
      if (isObject(attachment)) {
        this._contabilidadeDigitalAttachment.gDocId = attachment.cab.docID;
        this._contabilidadeDigitalAttachment.docOCRCabID = attachment.cab.docOCRCabID;
      }
    }
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized<IImportadorEfaturaItem, string>): void {
    this._dataGridInstance = component;
  }

  public async onDataGridRowCollapsed({key}: IDevExpressDataGridEventOnRowCollapsed<IImportadorEfaturaItem, string>): Promise<void> {
    const item: IImportadorEfaturaItem = await this._dataGridInstance.byKey(key);
    if (item.docModel?.linhas?.length > 1) {
      const lastLine: IJsonDocContabilidadeLinha = item.docModel.linhas[item.docModel.linhas.length - 1];
      if (!lastLine.nConta && !lastLine.valor) {
        item.docModel.linhas.pop();
      }
    }
  }

  public onDetalhesDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized, item: IImportadorEfaturaItem): void {
    item.detalhesDataGridInstance = component;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IImportadorEfaturaItem>): void {
    if (event.rowType === 'data') {
      if (event.data.erro?.length || event.data.listaErros?.length) {
        event.cellElement.classList.add('danger');
      }
      if (event.column.dataField === 'docDigital') {
        switch (event.data.docDigital) {
          case EImportadorEFaturaDocDigital.NaoTem:
            event.cellElement.innerHTML = '';
            break;
          case EImportadorEFaturaDocDigital.Tem:
            event.cellElement.innerHTML = `<i class="fa fa-file text-success" title="${this._plTranslateService.translate('importadorEfatura.docDigital.tem')}"></i>`;
            break;
          case EImportadorEFaturaDocDigital.TemSemelhante:
            event.cellElement.innerHTML = `<i class="fa fa-files-o text-warning" title="${this._plTranslateService.translate('importadorEfatura.docDigital.temSemelhante')}"></i>`;
            break;
        }
      }
    }
  }

  public onDataGridCellClick(event: IDevExpressDataGridEventOnCellClick<IImportadorEfaturaItem>): void {
    if (event.column.dataField === 'vf_actions') {
      return;
    }
    devExpressDataGridExpandDetailHandler(event, () => this._onRowExpand(event.data), {collapseOthers: true}).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public onDataGridContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IImportadorEfaturaItem>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(event.row.data);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public onSelectionChanged(): void {
    if (!this.lancarDocsEmSerie) {
      return;
    }
    this.lancarDocsEmSerieCount = this._dataGridInstance.getSelectedRowKeys().length;
  }

  public getWarnings(): string {
    return this.warningsList.join('<br/>');
  }

  public getErrors(): string {
    return this.errorList.join('<br/>');
  }

  public onContentReady({component}: IDevExpressDataGridEventOnContentReady): void {
    if (component && !this._firstLoad && this._firstContentReady && component.getDataSource().items().length === 1) {
      this._firstContentReady = false;
      const item = this._tableCacheSource[0];
      component.beginCustomLoading(undefined);
      this._onRowExpand(item)
        .then(() => component.expandRow(component.getKeyByRowIndex(0)))
        .finally(() => {
          component.endCustomLoading();
        });
    }
  }

  public onResizerValuesChanged(): void {
    if (isObject(this.callbackDocViewer.pdf) && isFunction(this.callbackDocViewer.pdf.updateSize)) {
      setTimeout(this.callbackDocViewer.pdf.updateSize);
    }
  }

  public async openExtratosContaModal(item: IImportadorEfaturaItem): Promise<void> {
    if (isEmpty(item.contaCorrente)) {
      return;
    }
    const nconta = item.contaCorrente;
    let doProcOnInit = false;
    let filters: ICGContabExtratosFilters = {};
    if (nconta.length) {
      if (!item.docModel) {
        await this._loadDocModel(item);
      }
      filters = {
        deConta: nconta,
        ateConta: nconta,
        periodo: item.docModel.periodo
      };
      doProcOnInit = true;
    }
    const modalInstance = this._cgModalService.showVanilla(ConciliacaoEFaturasModuleExtratosModalComponent);
    const componentInstance: ConciliacaoEFaturasModuleExtratosModalComponent = modalInstance.componentInstance;
    componentInstance.filters = filters;
    componentInstance.procOnInit = doProcOnInit;
    await modalInstance.result;
    await this._dataGridInstance.refresh();
  }

  public openCreateNewContaCorrentModal(item: IImportadorEfaturaItem): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(CreateNewContaCorrenteModalComponent);
    const componentInstance: CreateNewContaCorrenteModalComponent = modalInstance.componentInstance;
    componentInstance.importadorEfaturaItem = item;
    return modalInstance.result.then(this._dataGridInstance.refresh);
  }

  public openConfigPreDefinidosSupplierModal(item: IImportadorEfaturaItem = undefined): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConfigEfaturaDocPorLancSupplierModalComponent, {size: 'lg'});
    const componentInstance: ConfigEfaturaDocPorLancSupplierModalComponent = modalInstance.componentInstance;
    componentInstance.nContribuinte = item ? item.nifEmitente : '';
    componentInstance.nomeFornecedor = item ? item.nomeEmitente : '';
    return modalInstance.result.then(() => {
      this._pesquisar();
    });
  }

  public async search(): Promise<void> {
    try {
      await this._pesquisar();
      if (this.tableSource?.length) {
        this._cardPanel.collapse();
      } else {
        this._cardPanel.focusFirstElement();
        this._plAlertService.info('global.text.searchNoData');
      }
    } catch (e) {
      this._cardPanel.focusFirstElement();
    }
  }

  public readonly fnOnLoadPreDefinido =
    (item: IImportadorEfaturaItem) =>
    (params: IDocContabilidadeLoadPreDefinidoParams): Promise<IJsonDocContabilidade> =>
      this._loadPreDefinido(item, params);

  public readonly fnDocContabilidadeAfterInitDocument = (item: IImportadorEfaturaItem) => (): void => {
    if (!item.docLoadFocusField && isFunction(item.docCallback.addLine)) {
      if (item.timeoutAddLine) {
        item.timeoutAddLine.unsubscribe();
      }
      item.timeoutAddLine = asyncScheduler.schedule(() => {
        item.timeoutAddLine = undefined;
        item.docCallback.addLine();
      });
    }
  };

  public readonly fnDoLancarDocsEmSerie = (): Promise<void> => this._doLancarDocsEmSerie();

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _pesquisar(): Promise<void> {
    if (!this._checkInputFields()) {
      return Promise.reject();
    }
    this._firstLoad = false;
    this._fromCacheList = false;
    this.lancarDocsEmSerieCount = 0;
    this.lancarDocsEmSerieChange(false);
    this.selectAll = false;
    return this._getTableSourceMain().then(() => undefined);
  }

  private async _docContabilidadeAfterInitDocument(item: IImportadorEfaturaItem, doc: IJsonDocContabilidade): Promise<void> {
    if (!doc.predefinido) {
      doc.documentoExterno = item.numerodocumento;
      doc.dataDoc = item.dataEmissaoDocumento;
      doc.nContribuinte = item.nifEmitente;
    }
    if (!doc.predefinido || doc.predefinido.isGeneric) {
      await this._docsContabilidadeService.registaNif(doc.nContribuinte);
    }
  }

  private _getTableSourceMain(): Promise<ICGCTableData<IImportadorEfaturaItem>> {
    if (this._firstLoad) {
      return Promise.resolve({list: [], total: 0});
    }
    this._dataGridInstance.collapseAll(-1);
    this._firstContentReady = true;
    if (this._fromCacheList && this._tableCacheSource.length) {
      return Promise.resolve({list: this._tableCacheSource, total: this._tableCacheSource.length});
    }
    this._docsModel.clear();
    return this._getEFaturaDocs().then((response: Array<IImportadorEfaturaItem>) => {
      const source = [];
      for (const item of response) {
        item.preDefinido = '';
        item.showDetails = false;
        this._setItemDocModel(item, undefined);
        item.preDefinidoContab = undefined;
        item.linhas = [];
        item.docCallback = {};

        if (item.preDefinidoId > 0) {
          item.preDefinido = `${item.preDefinidoId} - ${item.preDefinidoStr}`;
        }

        item._toolbarInstanceId = `${item.idDocumento}-toolbar`;
        const toolbarInstance = this._plToolbarService.registerInstance(item._toolbarInstanceId);
        toolbarInstance.setItems([
          {
            id: 'itemToolbarToogleDetails',
            order: 0,
            type: 'button',
            class: 'btn-light',
            iconLeft: '<i class="fa fa-fw fa-file-text-o"></i>&nbsp;',
            caption: 'importadorEfatura.itemShowDetalhesToolbarBtn',
            click: (toolbarItem) => {
              item.showDetails = !item.showDetails;
              if (item.showDetails) {
                toolbarItem.caption = 'importadorEfatura.itemHideDetalhesToolbarBtn';
                if (item.detalhesDataGridInstance) {
                  item.detalhesDataGridInstance.refresh();
                }
              } else {
                toolbarItem.caption = 'importadorEfatura.itemShowDetalhesToolbarBtn';
              }
            }
          },
          {
            id: TOOLBAR_ITEM_SAVE_DOC_ID,
            order: 1,
            type: 'button',
            class: 'btn-primary',
            iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>&nbsp;',
            caption: 'global.btn.save',
            click: () => {
              const docContabilidade: IJsonDocContabilidade = this._docsModel.get(item.idDocumento);
              return this._saveTableDoc(item, docContabilidade, false, true);
            }
          },
          {
            id: TOOLBAR_ITEM_SAVE_DOC_ID_AND_ATTACH,
            order: 2,
            type: 'button',
            class: 'btn-light',
            iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>&nbsp;',
            caption: 'docscontabilidade.toolbar.saveWithoutAttach',
            visible: this.contabilidadeDigital,
            click: () => {
              const docContabilidade: IJsonDocContabilidade = this._docsModel.get(item.idDocumento);
              return this._saveTableDoc(item, docContabilidade, false, false);
            }
          }
        ]);
        this._registeredToolbarInstances.add(item._toolbarInstanceId);

        source.push(item);
      }

      this.tableSource = source;

      if (!this._fromCacheList) {
        this._tableCacheSource = source;
        this._fromCacheList = true;
      }

      return {list: source, total: source.length};
    });
  }

  private _openConfigPreDefinidosModal(): Promise<void> {
    return this._cgModalService.show(ConfigEfaturaDocPorLancModalComponent).then(() => {
      return this._eFaturaConfigDocsService.getEFaturaConfigsDocs().then((response) => {
        this._eFaturaConfigDocs = response.body;
        this._pesquisar();
      });
    });
  }

  private _findInTableSource(item: IImportadorEFaturaDocumento): IImportadorResultFindInSource {
    const result: IImportadorResultFindInSource = {index: -1, item: undefined};
    for (let i = 0; i < this._tableCacheSource.length; i++) {
      if (this._tableCacheSource[i].idDocumento === item.idDocumento) {
        result.item = this._tableCacheSource[i];
        result.index = i;
        break;
      }
    }
    return result;
  }

  private _getEFaturaDocs(): Promise<Array<IImportadorEfaturaItem>> {
    return new Promise<Array<IImportadorEfaturaItem>>((resolve, reject) => {
      const tipo = isDefined(this.filters.tipo) ? this.filters.tipo : undefined;
      this.warningsList = [];
      this._importadorEfaturaService
        .getEFaturaDocs(this.filters.fromDate, this.filters.toDate, tipo, this.filters.nifFornecedor)
        .then((response: HttpResponse<IImportadorEFaturaSource>) => {
          this.warningsList = response.body.warnings;
          this.errorList = response.body.errors;
          resolve(response.body.list);
        })
        .catch((reason) => {
          if (reason instanceof HttpErrorResponse) {
            const cgException = this._cgExceptionService.get(reason);
            if (cgException.class === EXCEPTION_VERIFICA_EFATURAS_CALL_LOGIN) {
              this._cgModalService
                .show(SimpleLoginModalComponent, {size: 'sm', backdrop: 'static'})
                .then(() => {
                  this._plAlertService.success('simpleLogin.messages.successLogin');
                  this._getEFaturaDocs().then(resolve).catch(reject);
                })
                .catch(reject);
            } else {
              this._plAlertService.error(cgException.message);
              reject(reason);
            }
          } else {
            reject(reason);
          }
        });
    });
  }

  private async _saveTableDoc(item: IImportadorEfaturaItem, docContabilidade: IDocContabilidade, editing: boolean, attachDigitalDocs: boolean): Promise<void> {
    const itemIndex = this._tableCacheSource.findIndex((tableSourceItem: IImportadorEfaturaItem) => item.idDocumento === tableSourceItem.idDocumento);
    this._dataGridInstance.beginCustomLoading(undefined);
    try {
      await this._saveDoc(docContabilidade, editing, attachDigitalDocs, item);
      if (this.contabilidadeDigital) {
        this._contabilidadeDigitalAttachment.gDocId = undefined;
        this._contabilidadeDigitalAttachment.gDocFolderId = undefined;
        this._contabilidadeDigitalAttachment.docOCRCabID = undefined;
        this._setContabilidadeDigitalSearch(undefined);
      }
      this._fromCacheList = true;
      this._tableCacheSource = this._tableCacheSource.filter((tblItem) => tblItem.idDocumento !== item.idDocumento);
      this.tableSource = this._tableCacheSource;
      if (this._tableCacheSource.length && itemIndex >= 0 && itemIndex < this._tableCacheSource.length && this._tableCacheSource.length > 1 && this._eFaturaConfigDocs.posicionaAutoNextDoc) {
        await this._onRowExpand(this._tableCacheSource[itemIndex]);
        await this._dataGridInstance.expandRow(this._tableCacheSource[itemIndex][MAIN_DATAGRID_KEY_ID]);
      } else if (!this.tableSource.length) {
        this._focusFornecedor();
      }
    } finally {
      this._dataGridInstance.endCustomLoading();
    }
  }

  private _focusFornecedor(): void {
    const input: HTMLInputElement = this._element.querySelector<HTMLInputElement>(`input[name="${ELEMENT_NAME_FORNECEDORES}"`);
    focusElement(input);
  }

  private _saveDoc(docContabilidade: IJsonDocContabilidade, editing: boolean, attachDigitalDocs: boolean, sourceObj: IImportadorEfaturaItem | IImportadorEFaturaLastDocItem): Promise<void> {
    const saldado = this._docContabilidadeService.isDocumentoSaldado(docContabilidade);
    if (!saldado) {
      this._plAlertService.error('docscontabilidade.erros.notSaldado');
      return Promise.resolve();
    }

    const params: IDocsContabilidadePostParams = {anexarGDocId: undefined, anexarGDocFolderId: undefined};
    if (this.contabilidadeDigital && attachDigitalDocs) {
      params.anexarGDocId = this._contabilidadeDigitalAttachment.gDocId;
      params.anexarGDocFolderId = this._contabilidadeDigitalAttachment.gDocFolderId;
    }
    return (
      !editing
        ? this._serviceDocsContabilidade.post({body: docContabilidade, params: params})
        : this._serviceDocsContabilidade.put({id: docContabilidade.extPocCabID, body: docContabilidade, params: params})
    ).then((responseSave: HttpResponse<IJsonDocContabilidade>) => {
      this._importadorEfaturaService.getDocContabilidade(responseSave.body.extPocCabID).then((response: HttpResponse<IJsonDocContabilidade>) => {
        sourceObj.docModel = response.body;
        this._updateLastDocs();
        this._plAlertService.success(this._plTranslateService.translate('docscontabilidade.saved', {id: response.body.nDocumento}));
      });
    });
  }

  private async _loadPreDefinido(item: IImportadorEfaturaItem, {predefinido, docContabilidade}: IDocContabilidadeLoadPreDefinidoParams): Promise<IJsonDocContabilidade> {
    const doc: IImportadorEfaturaItem = {...item, preDefinidoId: predefinido?.preDefinidosID};
    delete doc.detalhesDataGridInstance;
    let predefinidoChanged = predefinido && item.preDefinidoId !== predefinido.preDefinidosID;
    if (!predefinidoChanged) {
      predefinidoChanged = isObject(docContabilidade.predefinido) ? item.preDefinidoId !== docContabilidade.predefinido.preDefinidosID : isNumber(item.preDefinidoId);
    }
    const response: HttpResponse<IImportadorEFaturaDocumento> = await this._importadorEfaturaService.reprocessEFaturaDoc(doc);
    merge(item, response.body || {});
    if (item.preDefinidoId > 0) {
      item.preDefinido = `${item.preDefinidoId} - ${item.preDefinidoStr}`;
    }
    if (predefinidoChanged) {
      docContabilidade = await this._getDocContabilidadePredefinido(item);
    }
    return docContabilidade;
  }

  private _getDocDetails(item: IImportadorEfaturaItem): Promise<Array<IEFaturaDocumentoLinha>> {
    if (item.linhas?.length) {
      return Promise.resolve(item.linhas);
    }
    return this._importadorEfaturaService.getDocDetails(item.idDocumento, item.dataEmissaoDocumento).then((response) => {
      item.valorTotal = response.body.cab.valorTotal;
      item.valorTotalIva = response.body.cab.valorTotalIva;
      item.valorTotalBaseTributavel = response.body.cab.valorTotalBaseTributavel;
      item.linhas = response.body.list;
      item.listaErros = union(item.listaErros, response.body.errors);
      return item.linhas;
    });
  }

  private _getDocContabilidadePredefinido(item: IImportadorEfaturaItem, forceUseGenericPredefinido: boolean = false): Promise<IJsonDocContabilidade> {
    return this._importadorEfaturaService
      .getDocContabilidadePredefinido(
        {
          dataEmissaoDocumento: item.dataEmissaoDocumento,
          nifEmitente: item.nifEmitente,
          valorTotal: item.valorTotal,
          preDefinidoId: item.preDefinidoId,
          linhas: item.linhas,
          contaCorrente: item.contaCorrente,
          idDocumento: item.idDocumento,
          numerodocumento: item.numerodocumento,
          tipoDocumento: item.tipoDocumento,
          tipoDocumentoDesc: item.tipoDocumentoDesc,
          nDiario: item.docModel?.nDiario,
          periodo: item.docModel?.periodo,
          docDigitalDocID: item.docDigitalDocID,
          docDigitalForderID: item.docDigitalForderID,
          docDigital: item.docDigital
        },
        forceUseGenericPredefinido
      )
      .then((responsePredefinido) => {
        item.listaErros = union(item.listaErros, responsePredefinido.body.errors);
        return responsePredefinido.body.doc;
      });
  }

  private _setItemDocModel(item: IImportadorEfaturaItem, docModel: IJsonDocContabilidade): void {
    if (docModel !== item.docModel) {
      item.docModel = docModel;
      this._docsModel.set(item.idDocumento, item.docModel);
    }
  }

  private _updateLastDocs(): Promise<void> {
    return Promise.resolve(this._lastDocsPromise).finally(() => {
      this._lastDocsPromise = this._importadorEfaturaService
        .getLastDocs()
        .then((response) => {
          this.lastDocsSource = response.body || [];

          // Unregister previous docs tabs
          if (this.lastDocsTabs.length) {
            for (const lastDoc of this.lastDocsTabs) {
              this._plToolbarService.unRegisterInstance(lastDoc._toolbarInstanceId);
              this._registeredToolbarInstances.delete(lastDoc._toolbarInstanceId);
            }
          }

          // Clear previous docs tabs
          this.lastDocsTabs = [];
        })
        .finally(() => {
          this._lastDocsPromise = undefined;
        });
      return this._lastDocsPromise;
    });
  }

  private async _doLancarDocsEmSerie(): Promise<void> {
    if (this.contabilidadeDigital) {
      await this._cgModalService.showOkCancel('global.text.warning', 'importadorEfatura.warningImportarEmSerieContabilidadeDigital');
    }
    const modalInstance = this._cgModalService.showVanilla(ImportadorEfaturaLancarDocsEmSerieModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
    const componentInstance: ImportadorEfaturaLancarDocsEmSerieModalComponent = modalInstance.componentInstance;

    let naoLancadosCount = 0;

    const docs: Array<IImportadorEfaturaItem> = copy(this._dataGridInstance.getSelectedRowsData());

    for (const doc of docs) {
      delete doc.showDetails;
      delete doc.detalhesDataGridInstance;
      delete doc.docCallback;
      delete doc.docModel;
      delete doc.preDefinidoContab;
      delete doc.source;
      delete doc.preDefinido;
      delete doc._toolbarInstanceId;
    }

    const response: Array<IImportadorEFaturaDocumento> = await this._importadorEfaturaService.lancarDocsEmSerie(docs, this.contabilidadeDigital);
    this._fromCacheList = true;
    let lancadosCount = 0;
    for (const item of response) {
      const tblitem: IImportadorResultFindInSource = this._findInTableSource(item);
      if (tblitem.index === -1) {
        continue;
      }

      if (item.listaErros.length) {
        item.listaErros.forEach((error) => {
          this._tableCacheSource[tblitem.index].listaErros.push({
            errorType: error.errorType,
            errorMessage: error.errorMessage
          });
        });
        naoLancadosCount++;
      } else {
        lancadosCount++;
        this._tableCacheSource.splice(tblitem.index, 1);
        this.lancarDocsEmSerieCount--;
      }
    }
    componentInstance.naoLancadosCount = naoLancadosCount;
    componentInstance.lancadosCount = lancadosCount;
    componentInstance.processing = false;
    await modalInstance.result;
    await this._dataGridInstance.refresh();
  }

  private _setContabilidadeDigitalSearch(item: Partial<IImportadorEfaturaItem>): void {
    if (!isObject(item)) {
      item = {};
    }
    const {nifEmitente, numerodocumento, dataEmissaoDocumento, valorTotalBaseTributavel, valorTotalIva, valorTotal}: Partial<IImportadorEfaturaItem> = item;
    this.contabilidadeDigitalSearch = {
      nif: nifEmitente,
      nDocExterno: numerodocumento,
      dataDoc: dataEmissaoDocumento,
      totalBase: valorTotalBaseTributavel,
      totalIVA: valorTotalIva,
      totalDoc: valorTotal
    };
  }

  private async _onRowExpand(item: IImportadorEfaturaItem): Promise<void> {
    if (item.docModel?.predefinido) {
      return;
    }
    await this._loadDocModel(item);
    await this._loadPreDefinido(item, {
      predefinido: item.preDefinidoContab,
      docContabilidade: item.docModel,
      docOCRCab: undefined
    });
    await this._docContabilidadeAfterInitDocument(item, item.docModel);
    if (this.contabilidadeDigital) {
      this._setContabilidadeDigitalSearch(item);
    }
  }

  private async _loadDocModel(item: IImportadorEfaturaItem, forceUseGenericPredefinido: boolean = false): Promise<void> {
    item.linhas = await this._getDocDetails(item);
    const docContabilidade: IJsonDocContabilidade = await this._getDocContabilidadePredefinido(item, forceUseGenericPredefinido);
    this._setItemDocModel(item, docContabilidade);
    item.preDefinidoContab = item.docModel.predefinido;
    if (!item.docModel.isUsingPreDefinido || item.docModel.linhas.length !== 1 || !item.docModel.linhas[0]?.nConta) {
      item.docLoadFocusField = 'linhas';
    }
  }

  private _generateSecondaryClickActions(item: IImportadorEfaturaItem): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'importadorEfatura.actions.configSupplier',
        click: () => this.openConfigPreDefinidosSupplierModal(item),
        icon: 'fa-cog'
      },
      {
        caption: 'importadorEfatura.tooltip.criarNovaContaCorrente',
        click: () => this.openCreateNewContaCorrentModal(item),
        icon: 'fa-asterisk',
        disabled: !isEmpty(item.contaCorrente)
      },
      {
        caption: 'importadorEfatura.tooltip.extratoCorrente',
        click: () => this.openExtratosContaModal(item),
        icon: 'fa-file-text-o',
        disabled: isEmpty(item.contaCorrente)
      }
    ];
  }

  private _checkInputFields(): boolean {
    if (this.filters.fromDate > this.filters.toDate) {
      this._plAlertService.error(this._translateService.instant('importadorEfatura.error.dataFinalInferiorInicial'));
      return false;
    }
    return true;
  }
}
