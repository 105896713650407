import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {IJsonSegSocialFolhaFerias} from '../../jsonRHConfiguracoes.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RHConfiguracoesService} from '../../rhConfiguracoes.module.service';

@Component({
  selector: 'module-rhsegsocialfolhaferias',
  templateUrl: './rhSegSocialFolhaFerias.module.component.html'
})
export class RhSegSocialFolhaFeriasModuleComponent extends ModuloComponent implements OnInit {
  @Input() public rhsegsocialfolhaferias: IJsonSegSocialFolhaFerias;

  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhConfiguracoesService: RHConfiguracoesService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnSave.visible = true;
    this.btnSave.click = this._onSave.bind(this);
  }

  private _onSave(): Promise<void> {
    this.promise = this._rhConfiguracoesService.updateSegSocialFolhaFerias(this.rhsegsocialfolhaferias).then((response: HttpResponse<IJsonSegSocialFolhaFerias>) => {
      this.rhsegsocialfolhaferias = response.body;
      this._plAlertService.success('rhmanutencao.message.savedsuccessfully');
    });
    return this.promise;
  }
}
