export enum EAppLaunchMode {
  Default,
  Hybrid,
  HybridPartial,
  Partner
}

export interface ISiteStateParams {
  returnState?: string;
  returnStateParams?: unknown;
  launchMode?: ISiteLaunchModeParams;
}

export interface ISiteLaunchModeParams {
  mode?: EAppLaunchMode;
  apiUrl?: string;
  apiKey?: string;
}

export const STATE_NAME_SITE = 'site';
