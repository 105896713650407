import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonAtivos} from '../../../entities/ativos/interfaces/jsonAtivos.entity.interface';
import {IAquisicaoAdicionaDocumento, IAtivoAquisicaoPesquisaContab} from '../ativosaquisicaocontab/ativosAquisicaoContab.module.interface';
import {IJsonAquisicaoInvestEmCursoPorDoc} from './jsonAtivosAquisicaoInvestEmCursoPorDoc.module.interface';

@Injectable({
  providedIn: 'root'
})
export class AtivosAquisicaoInvestEmCursoPorDocService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/ativosaquisicaoinvestemcursopordoc`;
  }

  public pesquisaAquisicoesInvestEmCurso(saldosIniciais: boolean): TServiceResponse<Array<IAtivoAquisicaoPesquisaContab>> {
    return this._apiService.get<Array<IAtivoAquisicaoPesquisaContab>>({
      url: `${this._path}/pesquisa`,
      params: {saldosiniciais: saldosIniciais}
    });
  }

  public validateNContaAtivo(nContaAtivo: string, propNContaForn: string): TServiceResponse<void> {
    return this._apiService.get<void>({
      url: `${this._path}/validatencontaativo`,
      params: {
        ncontaativo: nContaAtivo,
        propncontaforn: propNContaForn
      }
    });
  }

  public adicionarDocumentoInvestEmCursoPorDoc(adicionaDoc: IAquisicaoAdicionaDocumento): TServiceResponse<IJsonAquisicaoInvestEmCursoPorDoc> {
    return this._apiService.post<IJsonAquisicaoInvestEmCursoPorDoc, IAquisicaoAdicionaDocumento>({
      url: `${this._path}/adddoc`,
      body: adicionaDoc
    });
  }

  public beforeNovoAtivo(investCursoEListaAtivos: IJsonAquisicaoInvestEmCursoPorDoc): TServiceResponse<IJsonAtivos> {
    return this._apiService.post<IJsonAtivos, IJsonAquisicaoInvestEmCursoPorDoc>({
      url: `${this._path}/beforenovoativo`,
      body: investCursoEListaAtivos
    });
  }

  public saveAtivosAquisicao(investCursoEListaAtivos: IJsonAquisicaoInvestEmCursoPorDoc): TServiceResponse<void> {
    return this._apiService.post<void, IJsonAquisicaoInvestEmCursoPorDoc>({
      url: `${this._path}/saveativosaquisicao`,
      body: investCursoEListaAtivos
    });
  }
}
