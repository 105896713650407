import {IEntityDefinition} from '../../../../components/entity/entity.definition.interface';
import {TServiceResponse} from '../../../../services/api/api.service.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonMoedaCambio, IJsonMoedaUpdateCambio, IMoeda} from './jsonMoeda.interface';

export interface IMoedaEntityService extends IEntityService<IMoeda> {
  updateCambio(idMoedaCambio: number, cambio: IJsonMoedaUpdateCambio, forceUpdate: boolean): TServiceResponse<IJsonMoedaCambio>;
}

export interface IMoedaEntity extends IEntityDefinition<IMoeda, IMoedaEntityService> {}

export const ENTITY_NAME_MOEDA = 'moeda';
