import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_AREAS_REGIONAIS_CONTROLO_IVA, EAreasRegionaisControloIVA} from './areasRegionaisControloIVA.datasource.interface';

export const DATA_SOURCE_AREAS_REGIONAIS_CONTROLO_IVA: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_AREAS_REGIONAIS_CONTROLO_IVA,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EAreasRegionaisControloIVA.NotApplicable, name: 'areasRegionaisControloIVA.data.notApplicable'},
    {value: EAreasRegionaisControloIVA.Continente, name: 'areasRegionaisControloIVA.data.continente'},
    {value: EAreasRegionaisControloIVA.Madeira, name: 'areasRegionaisControloIVA.data.madeira'},
    {value: EAreasRegionaisControloIVA.Acores, name: 'areasRegionaisControloIVA.data.acores'}
  ]
});
