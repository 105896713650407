import {Component, Injector, Input, OnInit} from '@angular/core';
import {PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../cg/modal/cgmodal.component';
import {EGenericImporterType} from '../generic.importer.component.interface';

@Component({
  selector: 'generic-importer-modal',
  templateUrl: './generic.importer.modal.component.html'
})
export class GenericImporterModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public type: EGenericImporterType;
  public textType: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this.textType = this._plTranslateService.translate(`components.genericImporter.types.${this.type}`);
  }
}
