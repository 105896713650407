import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {extractContentType} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {DemFinSNCService} from '../../demFinSNC.module.service';
import {ETipoExport} from '../../../listagens/docscomerciasestatisticas/docsComerciaisEstatisticas.module.interface';
import {IJsonDemFinSNCVarApuramento} from '../../jsonDemFinSNC.module.interface';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {IReportInstanceOptions} from '../../../../components/reports/input/reports.input.component.interface';

@Component({
  selector: 'modal-demfinsnc-preview-pdf',
  templateUrl: './demFinSNC.previewPDF.modal.component.html'
})
export class DemFinSNCPreviewPDFModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public ano: number;
  @Input() public processaFluxos: boolean;
  @Input() public verComDados: boolean;
  @Input() public processaIntercalares: boolean;
  @Input() public periodoIntercalares: string;
  @Input() public idioma: number;
  @Input() public loadedID: number;
  @Input() public varApuramento: IJsonDemFinSNCVarApuramento;
  @Input() public selectedModalImprimirReports: boolean;

  public readonly propertiesReport: IReportInstanceOptions;
  public reportModel: IJsonReport;
  public reportName: string;
  public reportContentType: string;
  public pdfSrc: Blob;
  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _demFinSNCService: DemFinSNCService
  ) {
    super(_injector);
    this._getReports = this._getReports.bind(this);
    this.propertiesReport = {
      rowTemplate: 'title',
      serviceMethodsOverride: {query: this._getReports}
    };
  }

  public ngOnInit(): void {
    this._getReports().then((reports: Array<IJsonReport>) => {
      if (reports.length) {
        this.reportModel = reports[0];
        this.reportName = this.reportModel.name;
        this._previsualizarDemFinSNC();
      }
    });
  }

  public reportChanged(report: IJsonReport): Promise<void> {
    this.reportModel = report;
    this.reportName = report?.name;
    this.reportContentType = undefined;
    return this._previsualizarDemFinSNC();
  }

  private async _getReports(): Promise<Array<IJsonReport>> {
    const response: HttpResponse<Array<IJsonReport>> = await this._demFinSNCService.getReports();
    if (this.selectedModalImprimirReports) {
      return response.body.filter((report: IJsonReport) => report.name === 'DF.FR3');
    }
    return response.body.filter((report: IJsonReport) => report.name !== 'DF.FR3');
  }

  private _previsualizarDemFinSNC(): Promise<void> {
    this.pdfSrc = undefined;
    this.promise = this._demFinSNCService
      .listagemDemFinSNCBlob(
        this.ano,
        this.processaFluxos,
        this.verComDados,
        this.processaIntercalares,
        this.periodoIntercalares,
        this.idioma,
        this.loadedID,
        this.reportName,
        ETipoExport.PDF,
        this.varApuramento
      )
      .then((response: HttpResponse<Blob>) => {
        this.pdfSrc = response.body;
        this.reportContentType = extractContentType(response);
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }
}
