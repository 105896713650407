import {merge} from 'lodash-es';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {isArray, isNumber, isObject, isString} from 'pl-comps-angular';
import {ITree, ITreeCallback, ITreeItem, TTreeNodeOrId} from './treeviewer.interface';

@Component({
  selector: 'tree-viewer',
  templateUrl: './treeviewer.component.html'
})
export class TreeViewerComponent implements OnInit, OnChanges {
  @Input() public tree: ITree;
  @Input() public model: ITreeItem;
  @Input() public disabled: boolean;
  @Input() public collapseOnSelect: boolean;
  @Input() public selectOnCollapse: boolean;
  @Input() public callback: ITreeCallback;
  @Output() public readonly modelChange: EventEmitter<ITreeItem>;
  @Output() public readonly evtDoubleClickedItem: EventEmitter<ITreeItem>;

  private _preventModelChange: boolean;

  constructor() {
    this.tree = {nodes: []};
    this.collapseOnSelect = false;
    this.selectOnCollapse = false;
    this.modelChange = new EventEmitter<ITreeItem>();
    this.evtDoubleClickedItem = new EventEmitter<ITreeItem>();
    this._preventModelChange = false;
  }

  public ngOnInit(): void {
    if (this.model) {
      this._selectNode(this.model);
    }
  }

  public ngOnChanges({tree, callback}: SimpleChanges): void {
    if (tree) {
      this.tree = merge({nodes: []}, tree.currentValue);
      if (this.model) {
        this._preventModelChange = true;
        this._selectNode(this.model);
      }
    }
    if (callback) {
      const cb: ITreeCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.getNode = (nodeOrId: TTreeNodeOrId) => this._getNode(nodeOrId, this.tree.nodes);
        cb.getParentNode = (nodeOrId: TTreeNodeOrId) => this._getParentNode(nodeOrId, this.tree.nodes);
        cb.selectNode = (nodeOrId: TTreeNodeOrId) => {
          this._selectNode(nodeOrId);
        };
      }
    }
  }

  public changedSelectedItem(value: ITreeItem): void {
    this.model = value;
    if (this._preventModelChange) {
      this._preventModelChange = false;
      return;
    }
    this.modelChange.emit(this.model);
  }

  private _getNode(nodeOrId: TTreeNodeOrId, nodes: Array<ITreeItem>): ITreeItem {
    if (!isArray(nodes) || !nodes.length || (!nodeOrId && nodeOrId !== 0)) {
      return undefined;
    }
    const nodeId: string | number = this._getNodeId(nodeOrId);
    const flattenedNodes: Array<ITreeItem> = this._flattenNodes(nodes);
    return flattenedNodes.find((treeItem: ITreeItem) => treeItem.nodeId === nodeId);
  }

  private _getParentNode(nodeOrId: TTreeNodeOrId, nodes: Array<ITreeItem>): ITreeItem {
    if (isArray(nodes) && nodes.length && (nodeOrId || nodeOrId === 0)) {
      const node: ITreeItem = this._getNode(nodeOrId, nodes);
      const nodeParents: Array<ITreeItem> = this._getNodeParents(node, nodes);
      if (nodeParents.length) {
        return nodeParents[nodeParents.length - 1];
      }
    }
    return undefined;
  }

  private _selectNode(nodeOrId: TTreeNodeOrId): void {
    const node: ITreeItem = this._getNode(nodeOrId, this.tree.nodes);
    if (!node) {
      return;
    }
    this.changedSelectedItem(node);
    // Expand all parents of selected node
    const nodeParents: Array<ITreeItem> = this._getNodeParents(node, this.tree.nodes);
    for (const parentNode of nodeParents) {
      parentNode.collapsed = false;
    }
  }

  private _getNodeId(nodeOrId: TTreeNodeOrId): string | number {
    return isString(nodeOrId) || isNumber(nodeOrId) ? nodeOrId : nodeOrId.nodeId;
  }

  private _getNodeParents(nodeOrId: TTreeNodeOrId, nodes: Array<ITreeItem>): Array<ITreeItem> {
    const parents: Array<ITreeItem> = [];
    const parentsMap: WeakMap<ITreeItem, ITreeItem> = new WeakMap<ITreeItem, ITreeItem>();

    // Build a map where we can get a parent node with a node
    const evaluateNodesParents = (nodesToEvaluate: Array<ITreeItem>, parent?: ITreeItem): void => {
      for (const nodeToEvaluate of nodesToEvaluate) {
        if (parent) {
          parentsMap.set(nodeToEvaluate, parent);
        }
        if (isArray(nodeToEvaluate.childNodes)) {
          evaluateNodesParents(nodeToEvaluate.childNodes, nodeToEvaluate);
        }
      }
    };

    if (isArray(nodes)) {
      evaluateNodesParents(nodes);
    }

    let node: ITreeItem = this._getNode(nodeOrId, nodes);
    while (node) {
      const parentNode: ITreeItem = parentsMap.get(node);
      if (parentNode) {
        parents.push(parentNode);
      }
      node = parentNode;
    }

    return parents.reverse();
  }

  private _flattenNodes(nodes: Array<ITreeItem>): Array<ITreeItem> {
    let flattenNodes: Array<ITreeItem> = [];

    const concatNodes = (childNode: ITreeItem): void => {
      if (childNode && !isArray(childNode.childNodes)) {
        return;
      }
      flattenNodes = flattenNodes.concat(childNode.childNodes);
      for (const node of childNode.childNodes) {
        concatNodes(node);
      }
    };

    if (isArray(nodes)) {
      for (const node of nodes) {
        concatNodes(node);
      }
    }

    return flattenNodes;
  }
}
