import moment from 'moment';
import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import ArrayStore from 'devextreme/data/array_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {copy, EDelphiNumberTypes, IPlDynamicVisualsSecondaryClickAction, IPlToolbarItem, isEmpty, isObject, PlAlertService, toInteger} from 'pl-comps-angular';
import {Subscription} from 'rxjs';
import {IJsonBoolean} from '../../../../../../common/interfaces/json';
import {MAX_DATE_CG, MIN_DATE_CG} from '../../../../../../common/utils/utils';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {
  IDevExpressDataGrid,
  IDevExpressDataGridColumnCustomizeTextCellInfo,
  TDevExpressDataGridColumnCustomizeTextFn
} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {IEntityMaintenanceInstance} from '../../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {IModuleMaintenanceInstance} from '../../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {EGroupName} from '../../../../../../config/constants';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ConfigOptionsService} from '../../../../../services/config/options/config.options.service';
import {EConfigOptionsInstanceName, IAvisosCobrancaConfigOptions, TConfigOptions} from '../../../../../services/config/options/config.options.service.interface';
import {DevExpressDataGridUIService} from '../../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {ROLE} from '../../../../../services/role.const';
import {ENTITY_NAME_CLIENTES} from '../../../../../entities/clifos/clifos.entity.interface';
import {IJsonClifo} from '../../../../../entities/clifos/jsonClifo.entity.interface';
import {IJsonDocComercial} from '../../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {DocumentosService} from '../../../../../entities/docscomerciais/service/documentos.entity.service';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocContabilidade, IDocsContabilidadeEntityService} from '../../../docscontabilidade/docsContabilidade.interface';
import {IJsonDocContabilidade, IJsonDocContabilidadeLinha} from '../../../docscontabilidade/jsonDocContabilidade.interface';
import {MODULE_NAME_EXTRATOS_DT_CLIENTES} from '../../../estatistica/extratosdt/extratosDT.module.interface';
import {
  EAvisosCobrancaDataGridLegendColors,
  EAvisosCobrancaDefaultReport,
  EAvisosCobrancaEmissora,
  IImpAvisosCobrancaFilters,
  TABLE_LEGEND_AVISOS_COBRANCA
} from '../../avisosCobranca.module.interface';
import {AvisoscobrancaService} from '../../avisosCobranca.module.service';
import {IAvisoDeCobrancaMovAbGrid, IJsonAvisoDeCobranca, IJsonAvisoDeCobrancaMovAb, IJsonConfigAvisosCobranca} from '../../jsonAvisosCobranca.module.interface';
import {AvisosCobrancaConfigsModalComponent} from '../modals/configs/avisosCobranca.configs.modal.component';
import {AvisosCobrancaPreviewModalComponent} from '../modals/preview/avisosCobranca.preview.modal.component';
import {AvisosCobrancaAlterarEmailPontualModalComponent} from '../modals/alteraremailpontual/avisosCobranca.alterarEmailPontual.modal.component';
import {TTableLegend} from '../../../../../components/tablelegend/tablelegend.component.interface';
import {EMBEmissorasSTR} from '../../../../mbconfigs/mbConfigs.module.interface';
import {IJsonConfigEmissora} from '../../../../mbconfigs/jsonMBConfigs.module.interface';
import {MBConfigsService} from '../../../../mbconfigs/mbConfigs.module.service';

const LENGTH_YEAR = 4;
const EMPTY_DATE_YEAR = 1899;

@Component({
  selector: 'avisos-cobranca-grid',
  templateUrl: './avisosCobrancaGrid.module.component.html'
})
export class AvisosCobrancaGridModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public config: IJsonConfigAvisosCobranca;
  @Input() public proceInterrompido: boolean;
  @Input() public empresaTemContabilidadeDigital: boolean;
  @Input() public empresaAnoEmCursoIRC: number;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly filters: IImpAvisosCobrancaFilters;
  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonAvisoDeCobranca, string>;
  public readonly dataGridDefinitionDetail: IDevExpressDataGrid<IJsonDocContabilidadeLinha>;
  public readonly configOptionsGroupName: EGroupName;
  public selectedRowKeys: Array<string>;
  public showVendedor: boolean;
  public showCondPagamento: boolean;
  public hasContabilidade: boolean;
  public hasErp: boolean;
  public tableLegend: TTableLegend;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private readonly _clifosService: IEntityService<IJsonClifo>;
  private readonly _maintenanceInstanceExtratosGrid: IModuleMaintenanceInstance;
  private readonly _maintenanceInstanceClientes: IEntityMaintenanceInstance;
  private readonly _btnProcessar: IPlToolbarItem;
  private readonly _subscriptionConfigOptions: Subscription;

  private _lastFiltersUsed: IImpAvisosCobrancaFilters;
  private _dataGridInstance: dxDataGrid<IJsonAvisoDeCobranca, string>;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _configOptionsService: ConfigOptionsService,
    private readonly _avisosCobrancaService: AvisoscobrancaService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _authService: AuthService,
    private readonly _mbConfigsService: MBConfigsService,
    private readonly _docsComerciaisService: DocumentosService
  ) {
    super(_injector);
    this.fnPesquisar = this.fnPesquisar.bind(this);
    this.configOptionsInstanceName = EConfigOptionsInstanceName.AVISOSCOBRANCA;
    this.configOptionsGroupName = EGroupName.ERP;
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._authService.hasAuthority(ROLE.CONTABILIDADE).then((result) => {
      this.hasContabilidade = result;
    });
    this._authService.hasAuthority(ROLE.ERP).then((result) => {
      this.hasErp = result;
    });
    this._btnProcessar = {
      id: 'previsualizar',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      class: 'btn-success',
      caption: 'global.btn.processPDF',
      disabled: true,
      tooltip: {
        text: 'avisoscobranca.messages.naoselconta',
        placement: 'bottom',
        disabled: false
      },
      click: () => this._preview()
    };
    this._clifosService = this._entityServiceBuilder.build(ENTITY_NAME_CLIENTES);
    this._maintenanceInstanceExtratosGrid = this._moduleMaintenanceService.build(MODULE_NAME_EXTRATOS_DT_CLIENTES);
    this._maintenanceInstanceClientes = this._entityMaintenanceService.build(ENTITY_NAME_CLIENTES);
    this.filters = {
      ncontade: '211',
      ncontaate: '2119999999',
      datadocde: MIN_DATE_CG,
      datadocate: MAX_DATE_CG,
      datavencimentode: MIN_DATE_CG,
      datavencimentoate: moment(),
      nvendedorde: 0,
      nvendedorate: EDelphiNumberTypes.MaxSmallInt,
      ncondpagamde: 0,
      ncondpagamate: EDelphiNumberTypes.MaxSmallInt,
      dataultavisode: MIN_DATE_CG,
      dataultavisoate: MAX_DATE_CG,
      nzonade: 0,
      nzonaate: EDelphiNumberTypes.MaxSmallInt,
      pesqprocinterrompido: false
    };
    this.dataGridDefinition = {
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'avisoscobranca.fields.nConta'},
        {dataField: 'nContaNome', dataType: 'string', caption: 'avisoscobranca.fields.nContaNome'},
        {dataField: 'valor', dataType: 'double', caption: 'avisoscobranca.fields.valor'},
        {dataField: 'dataUltimoAvisoProc', dataType: 'datetime', caption: 'avisoscobranca.fields.dataUltimoAvisoProc', customizeText: this._fnCustomizeText},
        {dataField: 'dataUltimoRecebimento', dataType: 'date', caption: 'avisoscobranca.fields.dataUltimoRecebimento', customizeText: this._fnCustomizeText},
        {dataField: 'dataPrimeiroVencimento', dataType: 'date', caption: 'avisoscobranca.fields.dataPrimeiroVencimento', customizeText: this._fnCustomizeText},
        {dataField: 'emailDestino', dataType: 'string', caption: 'avisoscobranca.fields.emailDestino', cellTemplate: 'cellTemplateEmailDestino'},
        {dataField: 'nDocsPorRegularizar', dataType: 'number', caption: 'avisoscobranca.fields.nDocsPorRegularizar'},
        {dataField: 'nDiasUltimoRecebimento', dataType: 'number', caption: 'avisoscobranca.fields.nDiasUltimoRecebimento'},
        {dataField: 'dividaVencida', dataType: 'double', caption: 'avisoscobranca.fields.dividaVencida'},
        {dataField: 'clifoNaoPodeFacturar', dataType: 'boolean', caption: 'avisoscobranca.fields.clifoNaoPodeFacturar'},
        {dataField: 'ultimoComentario', dataType: 'string', caption: 'avisoscobranca.fields.ultimoComentario', visible: false, customizeText: this._fnCustomizeText},
        {dataField: 'dataUltimoComentario', dataType: 'date', caption: 'avisoscobranca.fields.dataUltimoComentario', visible: false, customizeText: this._fnCustomizeText},
        {dataField: 'observacoes', dataType: 'string', caption: 'avisoscobranca.fields.observacoes', visible: false},
        {dataField: 'grupoConta', dataType: 'string', caption: 'avisoscobranca.fields.grupoConta', visible: false},
        {dataField: 'grupoContaStr', dataType: 'string', caption: 'avisoscobranca.fields.grupoContaStr', visible: false},
        {type: 'buttons', cellTemplate: 'cellTemplateActions'}
      ],
      dataSource: [],
      masterDetail: {enabled: true, template: 'masterDetailTemplate'},
      remoteOperations: false,
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      summary: {
        totalItems: [
          {column: 'valor', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'dividaVencida', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}
        ]
      }
    };
    this.dataGridDefinitionDetail = {
      columns: [
        {dataField: 'nDocExterno', dataType: 'string', caption: 'avisoscobranca.fields.nDocExterno'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'avisoscobranca.fields.nDocInterno'},
        {dataField: 'nDiario', dataType: 'number', caption: 'avisoscobranca.fields.nDiario'},
        {dataField: 'periodo', dataType: 'string', caption: 'avisoscobranca.fields.periodo'},
        {dataField: 'dataVencimento', dataType: 'date', caption: 'avisoscobranca.fields.dataVencimento', customizeText: this._fnCustomizeText},
        {dataField: 'valorPorPagar', dataType: 'double', caption: 'avisoscobranca.fields.valorPorPagar'},
        {dataField: 'valorPago', dataType: 'double', caption: 'avisoscobranca.fields.valorPago'},
        {dataField: 'valorCredito', dataType: 'double', caption: 'avisoscobranca.fields.valorCredito'},
        {dataField: 'valorDebito', dataType: 'double', caption: 'avisoscobranca.fields.valorDebito'},
        {dataField: 'nConta', dataType: 'string', caption: 'avisoscobranca.fields.nConta'}
      ],
      remoteOperations: false,
      toolbar: {items: [{location: 'before', template: 'toolbarTemplate', locateInMenu: 'auto'}]},
      masterDetail: {enabled: true, template: 'detailTemplateDocComercialContab'},
      summary: {
        totalItems: [
          {column: 'valorCredito', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'valorDebito', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}
        ]
      }
    };
    this.selectedRowKeys = [];
    this._subscriptionConfigOptions = this._configOptionsService
      .getOptionsErp()
      .get(this.configOptionsInstanceName)
      .options()
      .subscribe((configOptions: TConfigOptions<boolean, IAvisosCobrancaConfigOptions>) => {
        this.showVendedor = configOptions.get('showVendedor').value;
        this.showCondPagamento = configOptions.get('showCondPagamento').value;
      });
    this.config = {
      emissoraAtiva: EAvisosCobrancaEmissora.Desativado,
      defaultReport: EAvisosCobrancaDefaultReport.DefaultReport
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.tableLegend = TABLE_LEGEND_AVISOS_COBRANCA;
    this.toolbar.addButton(this._btnProcessar).addButton({
      id: 'config',
      order: this._btnProcessar.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      class: 'btn-light',
      caption: 'global.btn.config',
      click: () => this._openConfig()
    });

    this._avisosCobrancaService.getConfigAvisosCobranca().then((response: HttpResponse<IJsonConfigAvisosCobranca>) => {
      if (response.body) {
        this.config = response.body;
      }
    });
  }

  public ngOnDestroy(): void {
    if (this._subscriptionConfigOptions) {
      this._subscriptionConfigOptions.unsubscribe();
    }
  }

  public onNContaDeChanged(value: string): void {
    this.filters.ncontade = value;
    this._clifosService.get({id: value, reportExceptions: false}).then(() => {
      this.filters.ncontaate = value;
    });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonAvisoDeCobranca, string>): void {
    this._dataGridInstance = component;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonAvisoDeCobranca, string>): void {
    if (event.rowType === 'data') {
      if (event.column.dataField === 'emailDestino' && event.data._emailPontual) {
        event.cellElement.classList.add(EAvisosCobrancaDataGridLegendColors.CampoAlterado);
      }
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonAvisoDeCobranca, string>): Promise<void> {
    if (event.rowType !== 'data' || event.column.dataField === 'emailDestino') {
      return Promise.resolve();
    }
    return devExpressDataGridExpandDetailHandler(event);
  }

  public onCellClickMovAb(event: IDevExpressDataGridEventOnCellClick<IAvisoDeCobrancaMovAbGrid>): void {
    devExpressDataGridExpandDetailHandler(event, () => this._onDetailMovAb(event.data)).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public onContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IJsonAvisoDeCobranca | IJsonAvisoDeCobrancaMovAb, string>): void {
    if (event.target === 'content' && event.row.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActionsGroup(event.row.data);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public onSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IJsonAvisoDeCobranca, string>): void {
    this._changeSelectRow();
    this._evaluateButtons(event.selectedRowsData.length);
  }

  public openCliente(nConta: string): Promise<void> {
    return this._maintenanceInstanceClientes.maintenanceEdit(nConta).then(() => {
      return this._pesquisaAvisosCobranca(this._lastFiltersUsed);
    });
  }

  public openExtratoConta(nConta: string): Promise<void> {
    return this._maintenanceInstanceExtratosGrid.maintenance({params: {nConta: nConta}});
  }

  public async openPDFAvisosCobranca(nContasList: Array<string>, avisoDeCobrancaList: Array<IJsonAvisoDeCobranca> = []): Promise<void> {
    const previewSingle = avisoDeCobrancaList.length === 1;
    if (!previewSingle) {
      avisoDeCobrancaList = await this._dataGridInstance.getSelectedRowsData();
    }
    if (avisoDeCobrancaList?.length < 1 || !nContasList || nContasList.length < 1) {
      this._plAlertService.error('avisoscobranca.messages.naoselconta');
      return Promise.resolve();
    }

    const modalInstance = this._cgModalService.showVanilla(AvisosCobrancaPreviewModalComponent);
    const componentInstance: AvisosCobrancaPreviewModalComponent = modalInstance.componentInstance;
    componentInstance.previewSingle = previewSingle;
    componentInstance.nContasList = nContasList;
    componentInstance.headFilters = this._lastFiltersUsed;
    componentInstance.selectedRowsData = avisoDeCobrancaList;
    componentInstance.reportName = this.config.defaultReport;
    return modalInstance.result.finally(() => {
      if (previewSingle) {
        return Promise.resolve();
      }
      return this._pesquisaAvisosCobranca(this._lastFiltersUsed);
    });
  }

  public openAlterarEmailPontual(item: IJsonAvisoDeCobranca): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(AvisosCobrancaAlterarEmailPontualModalComponent);
    const componentInstance: AvisosCobrancaAlterarEmailPontualModalComponent = modalInstance.componentInstance;
    componentInstance.email = item.emailDestino;
    return modalInstance.result.then((responseEmail: string) => {
      if (item.emailDestino !== responseEmail) {
        item._emailPontual = true;
        item.emailDestino = responseEmail;
      }
    });
  }

  public mudaEstadoNaoPodeFaturar(nConta: string, clifoNaoPodeFacturar: boolean): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    return this._avisosCobrancaService
      .alteraEstadoNaoPodeFaturar(nConta)
      .then(() => {
        this._dataGridInstance.getDataSource().store().update(nConta, {clifoNaoPodeFacturar: !clifoNaoPodeFacturar});
        this._plAlertService.success('avisoscobranca.messages.estadochangedsuccess');
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
  }

  public getAvisosCobranca(pesqProcInterrompido: boolean = false): Promise<void> {
    this.filters.pesqprocinterrompido = pesqProcInterrompido;
    if (!this._checkRadicaisSearch()) {
      this._plAlertService.error('avisoscobranca.messages.erroRadicais');
      return Promise.resolve();
    }
    return this._pesquisaAvisosCobranca();
  }

  public readonly fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this._getPdf(doc);

  public async fnPesquisar(): Promise<void> {
    this._btnProcessar.disabled = true;
    await this.getAvisosCobranca();
    this._evaluateButtons(this.selectedRowKeys.length);
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._docsComerciaisService.getPdf(doc, doc.cab.nDocumento === 0 || !doc.cab.terminado);
  }

  private async _onDetailMovAb(movAb: IAvisoDeCobrancaMovAbGrid): Promise<void> {
    const ano: number = toInteger(movAb.periodo.substring(0, LENGTH_YEAR));
    if (this.hasContabilidade && !movAb._theDocContab) {
      const response: HttpResponse<IJsonDocContabilidade> = await this._serviceDocsContabilidade.get({id: movAb.extPocCabID, params: {ano: ano}});
      movAb._theDocContab = response.body;
      movAb._contabDigitalDoc =
        this.empresaTemContabilidadeDigital && movAb.temDocDigital
          ? {
              extPocCabID: movAb.extPocCabID,
              periodo: movAb.periodo,
              nDiario: movAb.nDiario,
              nDocInterno: movAb.nDocInterno,
              dataDoc: movAb.dataVencimento
            }
          : undefined;
    }
    if (this.hasErp && !movAb._theDocErp) {
      if (movAb.faccbId > 0) {
        const response: HttpResponse<IJsonDocComercial> = await this._docsComerciaisService.getDoc(movAb.faccbId);
        movAb._theDocErp = response.body;
      }
    }
  }

  private _preview(): Promise<void> {
    return this.openPDFAvisosCobranca(this.selectedRowKeys);
  }

  private async _pesquisaAvisosCobranca(filters: IImpAvisosCobrancaFilters = undefined): Promise<void> {
    const clearSelectedRowKeys = !filters;
    filters = clearSelectedRowKeys ? this.filters : filters;

    try {
      const response: HttpResponse<Array<IJsonAvisoDeCobranca>> = await this._avisosCobrancaService.getAvisosCobrancaList(filters);
      const responseProcInterr: HttpResponse<IJsonBoolean> = await this._avisosCobrancaService.checkProcessamentoInterrompido();
      this.proceInterrompido = responseProcInterr.body.value;
      if (clearSelectedRowKeys) {
        this.selectedRowKeys = [];
      }
      this.dataGridDefinition.dataSource = new ArrayStore({data: response.body, key: 'nConta'});
      if (response.body?.length) {
        this._cardPanel.collapse();
      } else {
        this._cardPanel.focusFirstElement();
        this._plAlertService.info('global.text.searchNoData');
      }
      this._lastFiltersUsed = copy(filters);
      if (this._lastFiltersUsed.pesqprocinterrompido && !this.proceInterrompido) {
        this._lastFiltersUsed.pesqprocinterrompido = false;
      }
    } catch (e) {
      this._cardPanel.focusFirstElement();
    }
  }

  private async _openConfig(): Promise<void> {
    const configResponse: HttpResponse<IJsonConfigAvisosCobranca> = await this._avisosCobrancaService.getConfigAvisosCobranca();
    this.config = configResponse.body;
    let hasEasyPay = false;
    try {
      const response: HttpResponse<IJsonConfigEmissora> = await this._mbConfigsService.getConfig(EMBEmissorasSTR.EasyPayOnline);
      hasEasyPay = !isEmpty(response.body.apiId);
    } finally {
      const modalInstance = this._cgModalService.showVanilla(AvisosCobrancaConfigsModalComponent, {size: 'md'});
      const componentInstance: AvisosCobrancaConfigsModalComponent = modalInstance.componentInstance;
      componentInstance.config = this.config;
      componentInstance.hasEasyPay = hasEasyPay;
      await modalInstance.result.then((result: IJsonConfigAvisosCobranca) => {
        if (result) {
          this.config = result;
        }
      });
    }
  }

  private _generateSecondaryClickActionsGroup(linha: IJsonAvisoDeCobranca | IJsonAvisoDeCobrancaMovAb): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'avisoscobranca.actions.cliente',
        click: () => this.openCliente((<IJsonAvisoDeCobranca>linha).nConta)
      },
      {
        caption: 'avisoscobranca.actions.extratoconta',
        click: () => this.openExtratoConta((<IJsonAvisoDeCobranca>linha).nConta)
      },
      {
        divider: true
      },
      {
        caption: (<IJsonAvisoDeCobranca>linha).clifoNaoPodeFacturar ? 'avisoscobranca.actions.podefaturar' : 'avisoscobranca.actions.naopodefaturar',
        click: () => this.mudaEstadoNaoPodeFaturar(linha.nConta, (<IJsonAvisoDeCobranca>linha).clifoNaoPodeFacturar)
      },
      {
        caption: 'avisoscobranca.actions.alterarEmailPontual',
        click: () => this.openAlterarEmailPontual(<IJsonAvisoDeCobranca>linha)
      }
    ];
  }

  private _customizeText(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row' && cellInfo.value) {
      if (moment(cellInfo.value).year() === EMPTY_DATE_YEAR) {
        return '';
      }
    }
    return cellInfo.valueText;
  }

  private _checkRadicaisSearch(): boolean {
    return this.filters.ncontaate?.startsWith('21');
  }

  private _evaluateButtons(selectedCount: number): void {
    this._btnProcessar.disabled = selectedCount <= 0;
    this._btnProcessar.tooltip.disabled = selectedCount > 0;
  }

  private _changeSelectRow(): void {
    this.selectedRowKeys.forEach((item: string) => {
      this._dataGridInstance
        .getDataSource()
        .store()
        .update(item, {select: this.selectedRowKeys.includes(item)});
    });
  }

  private readonly _fnCustomizeText: TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => this._customizeText(cellInfo);
}
