import {DATA_SOURCE_NAME_POC_ALT_TIPO_VALIDACAO} from './pocAltTipoValidacao.datasource.interface';
import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {EPOCAltTipoValidacao} from '../../entities/planoscontasalternativos/jsonPlanoContasAlternativo.entity.interface';

export const DATA_SOURCE_POC_ALT_TIPO_VALIDACAO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_POC_ALT_TIPO_VALIDACAO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EPOCAltTipoValidacao.ValidaGeral, name: 'planoscontasalternativos.datasource.data.validaGeral'},
    {value: EPOCAltTipoValidacao.NaoValida, name: 'planoscontasalternativos.datasource.data.naoValida'},
    {value: EPOCAltTipoValidacao.ValidaAnalitica, name: 'planoscontasalternativos.datasource.data.validaAnalitica'},
    {value: EPOCAltTipoValidacao.ValidaRadicais, name: 'planoscontasalternativos.datasource.data.validaRadicais'}
  ]
});
