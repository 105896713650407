import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_ANALISE_MARGEM_LUCRO} from './analisemargemlucro.module.interface';
import {AnaliseMargemLucroModuleComponent} from './components/analisemargemlucro.module.component';

export const MODULE_ANALISE_MARGEM_LUCRO: IModuleDefinition = {
  name: MODULE_NAME_ANALISE_MARGEM_LUCRO,
  state: {
    url: `/${MODULE_NAME_ANALISE_MARGEM_LUCRO}`,
    component: AnaliseMargemLucroModuleComponent,
    data: {
      roles: [ROLE.ERP, ROLE.FINANCEIRO],
      sidebarTitle: 'portals.sidebar.modules.analisemargemlucro'
    },
    params: {
      group: {
        type: 'any'
      }
    }
  }
};
