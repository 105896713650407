<div class="pl-media-devices-camera-capture-image" [class.disabled]="disabled">
  <button type="button" class="capture-image" [ngSwitch]="hasImage" (click)="change()">
    <div *ngSwitchCase="false" class="capture-image-empty">
      <i [cgcIcon]="icon" aria-hidden="true"></i>
    </div>

    <div *ngSwitchCase="true" class="capture-image-preview">
      <img [src]="imageSafeValue" alt="{{ locale.imageContentAlt }}" (error)="remove()" />
    </div>
  </button>

  <div *ngIf="!disabled && hideActions !== true" class="capture-image-actions">
    <button *ngIf="hideActionChange !== true" type="button" class="btn btn-link p-0 capture-image-action capture-image-action-change" (click)="change()">{{ locale.textChange }}</button>
    <button *ngIf="hasImage && hideActionRemove !== true" type="button" class="btn btn-link p-0 capture-image-action capture-image-action-remove" (click)="remove()">{{ locale.textRemove }}</button>
  </div>
</div>
