import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {fromJson, newLine, PlGlobalEventsService} from 'pl-comps-angular';
import {EStatusCode, GLOBAL_EVENT_HTTP_ERROR} from '../../../config/constants';
import {IAlertDebugError, IAlertDebugErrorException} from './alert.debug.error.component.interface';

@Component({
  selector: 'alert-debug-error',
  templateUrl: './alert.debug.error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertDebugErrorComponent implements OnDestroy {
  public readonly alerts: Array<string>;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _globalEventsService: PlGlobalEventsService
  ) {
    this.alerts = [];
    this._globalEventsService.on(GLOBAL_EVENT_HTTP_ERROR, this._fnOnHttpError);
  }

  public ngOnDestroy(): void {
    this._globalEventsService.off(GLOBAL_EVENT_HTTP_ERROR, this._fnOnHttpError);
  }

  public closeAlert(index: number): void {
    this.alerts.splice(index, 1);
    this._changeDetectorRef.markForCheck();
  }

  private _onHttpError(httpResponse: HttpErrorResponse): void {
    switch (httpResponse.status) {
      // Connection refused and/or server not reachable
      case EStatusCode.NoResponse:
        break;
      case EStatusCode.Unauthorized:
        break;
      default:
        const error: IAlertDebugError = fromJson<IAlertDebugError>(httpResponse.headers.get('exception'));
        let messages = `<strong>Status code:</strong>&nbsp;${httpResponse.status}<br/><strong>URL:</strong>&nbsp;${decodeURIComponent(httpResponse.url)}<br/>`;
        if (error) {
          messages += this._renderError(error.exception);
          if (error.exception.inner) {
            for (const innerException of error.exception.inner) {
              messages += this._renderError(innerException);
            }
          }
        }
        this._addAlert(messages);
        break;
    }
  }

  private _renderError(error: IAlertDebugErrorException): string {
    if (!error) {
      return '';
    }
    const message: Array<string> = [];
    if (error.class) {
      message.push(`<strong>Class name:</strong><pre>${error.class}</pre>`);
    }
    if (error.message) {
      message.push(`<strong>Message:</strong><pre>${newLine(error.message)}</pre>`);
    }
    if (error.sql) {
      message.push(`<strong>Sql:</strong><pre>${error.sql}</pre>`);
    }
    if (error.sqlParams) {
      message.push(`<strong>Sql params:</strong><pre>${error.sqlParams}</pre>`);
    }
    if (error.trace) {
      message.push(`<strong>Stack trace:</strong><pre>${error.trace}</pre>`);
    }
    return message.join('<br/>');
  }

  private _addAlert(message: string): void {
    this.alerts.push(message);
    this._changeDetectorRef.markForCheck();
  }

  private readonly _fnOnHttpError: (error: HttpErrorResponse) => void = (error: HttpErrorResponse) => {
    this._onHttpError(error);
  };
}
