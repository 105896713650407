import {AssociarMenusComponent} from './components/associarMenus.module.component';
import {ENTITY_NAME_PRH_LOCAIS} from '../../../../entities/prhlocais/pRHLocais.entity.interface';
import {ENTITY_NAME_PRH_MENUS} from '../menus/prhMenus.module.interface';
import {ENTITY_NAME_PRH_TIPO_REFEICOES} from '../../../../entities/prhtiporefeicoes/pRHTipoRefeicoes.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IJsonPRHLocal} from '../../../../entities/prhlocais/jsonPRHLocal.entity.interface';
import {IJsonPRHMenu} from '../../../../interfaces/jsonPRHMenu.interface';
import {IJsonPRHTipoRefeicao} from '../../../../entities/prhtiporefeicoes/jsonPRHTipoRefeicao.entity.interface';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ROLE} from '../../../../services/role.const';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';

export const MODULE_REFEICOES_ASSOCIAR_MENUS: IModuleDefinition = {
  name: 'colaboradoresAssociarMenus',
  state: {
    url: '/associarmenus',
    component: AssociarMenusComponent,
    data: {
      roles: [ROLE.GESTOREMENTAS],
      pageTitle: 'global.menu.colaboradoresAssociarMenus'
    },
    resolve: [
      {
        provide: 'locais',
        deps: [EntityServiceBuilder],
        useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonPRHLocal>> => {
          return entityServiceBuilder
            .build(ENTITY_NAME_PRH_LOCAIS)
            .query()
            .then((response: THttpQueryResponse<IJsonPRHLocal>) => response.body.list)
            .catch(() => []);
        }
      },
      {
        provide: 'menus',
        deps: [EntityServiceBuilder],
        useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonPRHMenu>> => {
          return entityServiceBuilder
            .build(ENTITY_NAME_PRH_MENUS)
            .query({pesquisa: 'activo=true'})
            .then((response: THttpQueryResponse<IJsonPRHMenu>) => response.body.list)
            .catch(() => []);
        }
      },
      {
        provide: 'tiposRefeicoes',
        deps: [EntityServiceBuilder],
        useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonPRHTipoRefeicao>> => {
          return entityServiceBuilder
            .build(ENTITY_NAME_PRH_TIPO_REFEICOES)
            .query()
            .then((response: THttpQueryResponse<IJsonPRHTipoRefeicao>) => response.body.list)
            .catch(() => []);
        }
      }
    ]
  }
};
