import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAtivos} from '../../interfaces/jsonAtivos.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {AtivosMudarContaService} from '../../services/ativosMudarConta.service';

@Component({
  selector: 'modal-ativos-mudar-conta-dep-exerc',
  templateUrl: './ativosMudarContaDep.modal.component.html'
})
export class AtivosMudarContaDepModalComponent extends CGModalComponent<IJsonAtivos> implements OnInit {
  @Input() public ativo: IJsonAtivos;
  @Input() public acumulada: boolean;

  public readonly pocsOutput: string;
  public readonly filter: string;

  public nConta: string;
  public canChange: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosMudarContaService: AtivosMudarContaService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.pocsOutput = '{{nConta}} - {{nome}}';
    this.filter = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.nConta = '';
  }

  public ngOnInit(): void {
    this.canChange = this.ativo.depreciacoesLastAno < this.configurations.empresa.anoEmCursoIRC;
  }

  public close(): Promise<void> {
    if (isEmpty(this.nConta)) {
      this._plAlertService.error('ativosmudarcontadep.message.contaVazio');
      return Promise.resolve();
    }

    return this._cgModalService.showOkCancel('global.text.warning', 'ativosmudarcontadep.temcerteza').then(() => {
      if (!this.acumulada) {
        return this._ativosMudarContaService.postRegistaMudancaDeContaDeDepreciacaoDoExercicio(this.ativo, this.nConta).then((response: HttpResponse<IJsonAtivos>) => {
          this._plAlertService.success('ativosmudarcontadep.message.successExercicio');
          super.close(response.body);
        });
      }
      return this._ativosMudarContaService.postRegistaMudancaDeContaDeDepreciacaoAcumulado(this.ativo, this.nConta).then((response: HttpResponse<IJsonAtivos>) => {
        this._plAlertService.success('ativosmudarcontadep.message.successAcumulado');
        super.close(response.body);
      });
    });
  }
}
