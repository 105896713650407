import {firstValueFrom} from 'rxjs';
import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {LoadOptions} from 'devextreme/data';
import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {devExpressDataGridExpandDetailHandler, devExpressDataGridSortToOrder} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DevExpressDatagridPaging} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.paging';
import {IDevExpressDataGrid, IDevExpressDataGridLoadResult} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDocsComerciaisGrid} from '../documentosComerciais.module.interface';
import {IJsonDocComercial, IJsonDocComercialCab} from '../../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {PortalClientAccountService} from '../../../../../services/portalclientaccount/portalclientaccount.service';
import {IApiQueryResponse} from '../../../../../services/api/api.service.interface';

const ORDER = 'dataDoc desc';

@Component({
  selector: 'documentos-comerciais',
  templateUrl: './documentosComerciais.module.component.html'
})
export class DocumentosComerciaisComponent extends ModuloComponent implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public dataSource: CustomStore;

  private _dataGridInstance: dxDataGrid;
  private _dataGridPaging: DevExpressDatagridPaging;

  constructor(
    protected readonly _injector: Injector,
    private readonly _portalClientAccountService: PortalClientAccountService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nDoc', caption: 'docscomerciais.fields.nDoc'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'nif', width: '120px', caption: 'docscomerciais.fields.nif'},
        {dataField: 'estado', caption: 'docscomerciais.fields.estado'},
        {dataField: 'porPagar', dataType: 'double', caption: 'docscomerciais.fields.porPagar'},
        {dataField: 'totalDocumento', dataType: 'double', caption: 'global.text.total'},
        {dataField: 'stampUpdate', dataType: 'datetime', caption: 'docscomerciais.fields.stampUpdate'}
      ],
      headerFilter: {visible: false},
      filterRow: {visible: false},
      export: {filename: 'faturacao'},
      remoteOperations: {
        filtering: false,
        grouping: false,
        groupPaging: false,
        paging: true,
        sorting: true,
        summary: false
      },
      masterDetail: {enabled: true, template: 'detailTemplateDocFaturacao'}
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.dataSource = new CustomStore({
      key: 'nDoc',
      load: this._onLoad.bind(this)
    });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IDocsComerciaisGrid>): void {
    devExpressDataGridExpandDetailHandler(event, () => this._onDetail(event.data));
  }

  public readonly fnGetPdfUrl = (faccbId: number): Promise<string> => this._getPdfUrl(faccbId);

  private _onLoad(loadOptions: LoadOptions): Promise<IDevExpressDataGridLoadResult<IDocsComerciaisGrid>> {
    const order: string = loadOptions.sort ? devExpressDataGridSortToOrder(loadOptions.sort) : ORDER;
    if (!this._dataGridPaging) {
      this._dataGridPaging = new DevExpressDatagridPaging(this._dataGridInstance);
    }
    const {page, perPage} = this._dataGridPaging.paginate(loadOptions);
    return this._portalClientAccountService.getDocumentosComerciais('', page, perPage, order).then((response: HttpResponse<IApiQueryResponse<IJsonDocComercialCab>>) => {
      return this._dataGridPaging.processResult(response);
    });
  }

  private _onDetail(grid: IDocsComerciaisGrid): Promise<void> {
    return this._portalClientAccountService.getDocumento(grid.faccbId).then((response: HttpResponse<IJsonDocComercial>) => {
      grid._thedoc = response.body;
    });
  }

  private _getPdfUrl(faccbId: number): Promise<string> {
    if (!faccbId) {
      return Promise.resolve('');
    }
    return firstValueFrom(this._portalClientAccountService.getPdfUrlDocumento(faccbId));
  }
}
