import {BehaviorSubject, from, Observable, Subscription} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {Injectable, Injector, OnDestroy} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {copy, isNumber, isObject, isUndefined, Logger, skipIf} from 'pl-comps-angular';
import {ApiService} from '../../../services/api/api.service';
import {ConfigOptionsInstanceService} from '../../../services/config/options/config.options.instance.service';
import {ConfigService} from '../../../services/config/config.service';
import {ContabilidadeDigitalService} from '../../../services/contabilidadedigital/contabilidadedigital.service';
import {DocsContabilidadeService} from '../../portalcontabilidade/docscontabilidade/service/docsContabilidade.service';
import {EConfigOptionsInstanceName, IBancosExtratoConfigOptions, IConfigOptionsGroupContabilidade} from '../../../services/config/options/config.options.service.interface';
import {EDebitoCredito} from '../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {EGroupName} from '../../../../config/constants';
import {IApiQueryRequestConfig, IApiRequestConfig, TServiceQueryResponse, TServiceResponse} from '../../../services/api/api.service.interface';
import {ICGConfigurations} from '../../../services/config/config.service.interface';
import {IContabDigitalGDocViewerRecolhaSearchParams} from '../../../services/contabilidadedigital/contabilidadedigital.interface';
import {
  IJsonBancosExtratoConfigs,
  IJsonBancosExtratoConsentsData,
  IJsonBancosExtratoDocSemelhante,
  IJsonBancosExtratoGetTransactionsByConciliacaoBancariaResult,
  IJsonBancosExtratoLancarDocumentosEmSerieData,
  IJsonBancosExtratoPreDefCfg,
  IJsonBancosExtratoSaveRecPagResult,
  IJsonBancosExtratoSuggestDocContabilidadeData,
  IJsonCGBankingLicense
} from '../jsonBancosExtrato.module.interface';
import {IJsonBankAccount} from '../../../interfaces/jsonBankAccount.interface';
import {
  EEntidadeBancariaDocDigital,
  EEntidadeBancariaEstado,
  IJsonBankPreDefCfg,
  IJsonEntidadeBancariaAddNewAccount,
  IJsonEntidadeBancariaRecPag,
  IJsonEntidadeBancariaSaveRecPagData,
  IJsonEntidadeBancariaSearchResult,
  IJsonEntidadeBancariaTransaction
} from '../../../interfaces/jsonEntidadeBancaria.interface';
import {IJsonClifo} from '../../../entities/clifos/jsonClifo.entity.interface';
import {IJsonContabDigitalGDocViewerRecolhaSearchResult} from '../../../services/contabilidadedigital/jsonContabDigital.interface';
import {IJsonDocContabilidade} from '../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {TDate} from '../../../../common/dates';
import {convertLineBreaksToHTML} from '../../../../common/utils/utils';

let subjectConfigs: BehaviorSubject<IJsonBancosExtratoConfigs>;
let observableConfigs: Observable<IJsonBancosExtratoConfigs>;
let promiseConfigs: TServiceResponse<IJsonBancosExtratoConfigs>;

@Injectable({
  providedIn: 'root'
})
export class BancosExtratoService extends ConfigOptionsInstanceService<boolean, IBancosExtratoConfigOptions, IConfigOptionsGroupContabilidade> implements OnDestroy {
  public readonly gDocViewerRecolhaSearchParams: Readonly<IContabDigitalGDocViewerRecolhaSearchParams>;

  protected readonly _path: string;
  protected readonly _logger: Logger;
  protected readonly _apiService: ApiService;
  protected readonly _configService: ConfigService;
  protected readonly _docsContabilidadeService: DocsContabilidadeService;
  protected readonly _contabilidadeDigitalService: ContabilidadeDigitalService;

  private readonly _subscriptionConfigurations: Subscription;
  private _configTemCGBanking: boolean;
  private _configModoCGOn: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector, EGroupName.CONTABILIDADE, EConfigOptionsInstanceName.BANCOS_EXTRATO);
    this.gDocViewerRecolhaSearchParams = Object.freeze({byDataOrValor: true});
    this._logger = this._injector.get<Logger>(Logger);
    this._apiService = this._injector.get<ApiService>(ApiService);
    this._configService = this._injector.get<ConfigService>(ConfigService);
    this._docsContabilidadeService = this._injector.get<DocsContabilidadeService>(DocsContabilidadeService);
    this._contabilidadeDigitalService = this._injector.get<ContabilidadeDigitalService>(ContabilidadeDigitalService);
    this._path = `${this._apiService.path.restapi}/bancosextrato`;
    if (!subjectConfigs) {
      subjectConfigs = new BehaviorSubject<IJsonBancosExtratoConfigs>(undefined);
    }
    this._subscriptionConfigurations = this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      this._configTemCGBanking = configurations.empresa.temCGBanking;
      this._configModoCGOn = configurations.licenca.modoCGOn;
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigurations.unsubscribe();
  }

  public getCGBankingLicense(): TServiceResponse<IJsonCGBankingLicense> {
    return this._apiService.get<IJsonCGBankingLicense>({url: `${this._path}/license`});
  }

  public activateCGBanking(): TServiceResponse<void> {
    return this._apiService.post<void>({url: `${this._path}/activate`}).then((response: HttpResponse<void>) => {
      return this._configService
        .refresh()
        .then(() => response)
        .catch((reason: unknown) => {
          this._logger.error(reason);
          return response;
        });
    });
  }

  public getNewBankAccount(newAccount?: IJsonEntidadeBancariaAddNewAccount): TServiceResponse<string> {
    return this._apiService.post<string, IJsonEntidadeBancariaAddNewAccount>({url: `${this._path}/newbankaccount`, body: newAccount});
  }

  public queryBankAccounts(config?: IApiQueryRequestConfig): TServiceQueryResponse<IJsonBankAccount> {
    return this._apiService.query<IJsonBankAccount>({
      url: `${this._path}/bankaccounts`,
      ...config
    });
  }

  public refreshBankAccount(bankAccountId: string): TServiceResponse<void> {
    return this._apiService.post<void>({url: `${this._path}/bankaccounts/${bankAccountId}/refresh`});
  }

  public updateBankAccount(bankAccount: IJsonBankAccount): TServiceResponse<IJsonBankAccount> {
    return this._apiService.put<IJsonBankAccount>({
      url: `${this._path}/bankaccounts/${bankAccount.bankAccountId}`,
      body: bankAccount
    });
  }

  public deleteBankAccount(bankAccount: IJsonBankAccount): TServiceResponse<IJsonBankAccount> {
    return this._apiService.delete<IJsonBankAccount>({
      url: `${this._path}/bankaccounts/${bankAccount.bankAccountId}`
    });
  }

  public getTransactions(bankAccountId: string, startDate: TDate, endDate: TDate, simpleMode: boolean = false, config?: IApiRequestConfig): TServiceResponse<IJsonEntidadeBancariaSearchResult> {
    return this._apiService
      .get<IJsonEntidadeBancariaSearchResult>({
        url: `${this._path}/transactions`,
        params: {
          bankAccountId: bankAccountId,
          startDate: startDate,
          endDate: endDate,
          simpleMode: simpleMode
        },
        ...config
      })

      .then((response: HttpResponse<IJsonEntidadeBancariaSearchResult>) => {
        this._handleSearchResult(response.body);
        return response;
      });
  }

  public getTransactionsByConciliacaoBancaria(concilBancoCabId: string): TServiceResponse<IJsonBancosExtratoGetTransactionsByConciliacaoBancariaResult> {
    return this._apiService
      .get<IJsonBancosExtratoGetTransactionsByConciliacaoBancariaResult>({
        url: `${this._path}/transactionsbyconciliacaobancaria`,
        params: {concilBancoCabId: concilBancoCabId}
      })
      .then((response: HttpResponse<IJsonBancosExtratoGetTransactionsByConciliacaoBancariaResult>) => {
        this._handleSearchResult(response.body);
        return response;
      });
  }

  public updateTransactionObservacoes(transactionId: string, observacoes: string): TServiceResponse<void> {
    return this._apiService.put<void, string>({
      url: `${this._path}/transactions/${transactionId}/observacoes`,
      body: observacoes
    });
  }

  public ignoreTransactions(transactions: Array<IJsonEntidadeBancariaTransaction>): TServiceResponse<Array<IJsonEntidadeBancariaTransaction>> {
    return this._apiService
      .post<Array<IJsonEntidadeBancariaTransaction>>({
        url: `${this._path}/ignoretransactions`,
        body: transactions
      })
      .then((response: HttpResponse<Array<IJsonEntidadeBancariaTransaction>>) => {
        this._handleTransactions(response.body);
        return response;
      });
  }

  public stopIgnoreTransactions(transactions: Array<IJsonEntidadeBancariaTransaction>): TServiceResponse<Array<IJsonEntidadeBancariaTransaction>> {
    return this._apiService
      .post<Array<IJsonEntidadeBancariaTransaction>>({
        url: `${this._path}/stopignoretransactions`,
        body: transactions
      })
      .then((response: HttpResponse<Array<IJsonEntidadeBancariaTransaction>>) => {
        this._handleTransactions(response.body);
        return response;
      });
  }

  public searchContabilidadeAttachments(date: TDate, ammount: number, nif?: string): TServiceResponse<IJsonContabDigitalGDocViewerRecolhaSearchResult> {
    return this._contabilidadeDigitalService.gDocViewerRecolha.search(
      {
        nif: nif,
        dataDoc: date,
        totalDoc: Math.abs(ammount)
      },
      this.gDocViewerRecolhaSearchParams
    );
  }

  public suggestDocContabilidade(data: IJsonBancosExtratoSuggestDocContabilidadeData): TServiceResponse<IJsonDocContabilidade> {
    return this._apiService
      .post<IJsonDocContabilidade, IJsonBancosExtratoSuggestDocContabilidadeData>({
        url: `${this._path}/suggestdoccontabilidade`,
        body: data
      })
      .then((response: HttpResponse<IJsonDocContabilidade>) => {
        this._docsContabilidadeService.handleCommandResponse(response.body);
        return response;
      });
  }

  public loadTransactionDefaultValues(transaction: IJsonEntidadeBancariaTransaction): TServiceResponse<IJsonEntidadeBancariaTransaction> {
    return this._apiService
      .post<IJsonEntidadeBancariaTransaction>({
        url: `${this._path}/loadtransactiondefaultvalues`,
        body: transaction
      })
      .then((response: HttpResponse<IJsonEntidadeBancariaTransaction>) => {
        this._handleTransaction(response.body);
        return response;
      });
  }

  public loadTransactionsPredefinido(transactions: Array<IJsonEntidadeBancariaTransaction>): TServiceResponse<Array<IJsonEntidadeBancariaTransaction>> {
    return this._apiService
      .post<Array<IJsonEntidadeBancariaTransaction>>({
        url: `${this._path}/loadtransactionspredefinido`,
        body: transactions
      })
      .then((response: HttpResponse<Array<IJsonEntidadeBancariaTransaction>>) => {
        this._handleTransactions(response.body);
        return response;
      });
  }

  public getConfigs(): TServiceResponse<IJsonBancosExtratoConfigs> {
    return this._apiService.get<IJsonBancosExtratoConfigs>({url: `${this._path}/configs`});
  }

  public saveConfigs(configs: IJsonBancosExtratoConfigs, simpleMode: boolean): TServiceResponse<IJsonBancosExtratoConfigs> {
    return this._apiService
      .put<IJsonBancosExtratoConfigs>({url: `${this._path}/configs`, body: configs, params: {simpleMode: simpleMode}})
      .then((response: HttpResponse<IJsonBancosExtratoConfigs>) => {
        subjectConfigs.next(response.body);
        return response;
      });
  }

  public configs(force: boolean = false): Observable<IJsonBancosExtratoConfigs> {
    if (!force && isObject(subjectConfigs.value)) {
      if (!observableConfigs) {
        observableConfigs = subjectConfigs
          .asObservable()
          .pipe(skipIf((configs: IJsonBancosExtratoConfigs) => isUndefined(configs)))
          .pipe(map(copy));
      }
      return observableConfigs;
    }
    if (!promiseConfigs) {
      promiseConfigs = this.getConfigs().finally(() => {
        promiseConfigs = undefined;
      });
    }
    return from<TServiceResponse<IJsonBancosExtratoConfigs>>(promiseConfigs).pipe(
      mergeMap<HttpResponse<IJsonBancosExtratoConfigs>, Observable<IJsonBancosExtratoConfigs>>((response: HttpResponse<IJsonBancosExtratoConfigs>) => {
        subjectConfigs.next(response.body);
        return this.configs(false);
      })
    );
  }

  public getPredefDesc(bankPreDefCfgId: string): TServiceResponse<IJsonBankPreDefCfg> {
    return this._apiService.get<IJsonBankPreDefCfg>({url: `${this._path}/predefdesc/${bankPreDefCfgId}`});
  }

  public insertPredefDesc(bancosExtratoPreDefCfg: IJsonBancosExtratoPreDefCfg): TServiceResponse<IJsonBancosExtratoPreDefCfg> {
    return this._apiService.post<IJsonBancosExtratoPreDefCfg>({
      url: `${this._path}/predefdesc`,
      body: bancosExtratoPreDefCfg
    });
  }

  public updatePredefDesc(bancosExtratoPreDefCfg: IJsonBancosExtratoPreDefCfg): TServiceResponse<IJsonBancosExtratoPreDefCfg> {
    return this._apiService.put<IJsonBancosExtratoPreDefCfg>({
      url: `${this._path}/predefdesc/${bancosExtratoPreDefCfg.preDefCfg.bankPreDefCfgId}`,
      body: bancosExtratoPreDefCfg
    });
  }

  public deletePredefDesc(bankPreDefCfgId: string): TServiceResponse<void> {
    return this._apiService.delete<void>({url: `${this._path}/predefdesc/${bankPreDefCfgId}`});
  }

  public lancarDocumentosEmSerie(data: IJsonBancosExtratoLancarDocumentosEmSerieData): TServiceResponse<void> {
    return this._apiService.post<void, IJsonBancosExtratoLancarDocumentosEmSerieData>({
      url: `${this._path}/lancardocumentosemserie`,
      body: data
    });
  }

  public getDocsSemelhantesJaLancados(transaction: IJsonEntidadeBancariaTransaction, naMesmaData: boolean = true): TServiceResponse<Array<IJsonBancosExtratoDocSemelhante>> {
    return this._apiService.post<Array<IJsonBancosExtratoDocSemelhante>, IJsonEntidadeBancariaTransaction>({
      url: `${this._path}/docssemelhantes`,
      body: transaction,
      params: {naMesmaData: naMesmaData}
    });
  }

  public associarDocSemelhante(nLanc: string, transaction: IJsonEntidadeBancariaTransaction): TServiceResponse<void> {
    return this._apiService.post<void, IJsonEntidadeBancariaTransaction>({
      url: `${this._path}/docssemelhantes/${nLanc}`,
      body: transaction
    });
  }

  public getConsents(): TServiceResponse<IJsonBancosExtratoConsentsData> {
    return this._apiService.get<IJsonBancosExtratoConsentsData>({url: `${this._path}/consents`});
  }

  public deleteConsent(credentialsId: string): TServiceResponse<void> {
    return this._apiService.delete({url: `${this._path}/consents/${credentialsId}`});
  }

  public suggestRecPagNConta(descricao: string, debitoCredito: EDebitoCredito, config?: IApiRequestConfig): TServiceResponse<IJsonClifo> {
    return this._apiService.get<IJsonClifo>({
      url: `${this._path}/suggestrecpagnconta`,
      params: {
        descricao: descricao,
        debitoCredito: debitoCredito
      },
      ...config
    });
  }

  public getRecPagList(nConta: string): TServiceResponse<Array<IJsonEntidadeBancariaRecPag>> {
    return this._apiService.get<Array<IJsonEntidadeBancariaRecPag>>({
      url: `${this._path}/recpag`,
      params: {nConta: nConta}
    });
  }

  public saveRecPag(data: IJsonEntidadeBancariaSaveRecPagData): TServiceResponse<IJsonBancosExtratoSaveRecPagResult> {
    return this._apiService.post<IJsonBancosExtratoSaveRecPagResult, IJsonEntidadeBancariaSaveRecPagData>({
      url: `${this._path}/recpag`,
      body: data
    });
  }

  protected get _temCGBanking(): boolean {
    return this._configTemCGBanking;
  }

  protected get _modoCGOn(): boolean {
    return this._configModoCGOn;
  }

  private _handleSearchResult(searchResult: IJsonEntidadeBancariaSearchResult): void {
    this._handleTransactions(searchResult.transactions);
    this._handleWarnings(searchResult.warnings);
  }

  private _handleTransactions(transactions: Array<IJsonEntidadeBancariaTransaction>): void {
    for (const transaction of transactions) {
      this._handleTransaction(transaction);
    }
  }

  private _handleTransaction(transaction: IJsonEntidadeBancariaTransaction): void {
    if (!isNumber(transaction.estado)) {
      transaction.estado = EEntidadeBancariaEstado.NaoLancado;
    }
    if (!isNumber(transaction.docDigital)) {
      transaction.docDigital = EEntidadeBancariaDocDigital.NaoTem;
    }
  }

  private _handleWarnings(warnings: Array<string>): void {
    for (let i = 0; i < warnings.length; i++) {
      warnings[i] = convertLineBreaksToHTML(warnings[i]);
    }
  }
}
