import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {IAtIESPagamentoItem} from '../../../../../../../common/interfaces/at.interfaces';
import {AtService} from '../../../../../../services/at.service';

@Component({
  selector: 'documentos-fiscais-ies-pagamento',
  templateUrl: './documentosFiscais.iesPagamento.modal.component.html'
})
export class DocumentosFiscaisIesPagamentoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public idPagamento: number;
  public model: IAtIESPagamentoItem;
  public promise: Promise<unknown>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _atService: AtService
  ) {
    super(_injector);
    this.model = {
      valor: '',
      data: '',
      entidade: '',
      referencia: ''
    };
  }

  public ngOnInit(): void {
    this.promise = this._atService.getIESDadosPagamento(this.idPagamento).then((response) => {
      this.model = {...response.body.data};
    });
  }
}
