import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ConciliacaoEFaturasService} from '../../service/conciliacaoEFaturas.service';
import {IJsonEfaturaConfig, IJsonEfaturaConfigDiarioAExcluir} from '../../../../../interfaces/jsonImportadorEfatura.interface';
import {PlAlertService} from 'pl-comps-angular';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'module-verifica-e-faturas-config-modal',
  templateUrl: './conciliacaoEFaturas.module.config.modal.html'
})
export class ConciliacaoEFaturasModuleConfigModalComponent extends CGModalComponent<void> {
  @Input() public config: IJsonEfaturaConfig;

  public readonly dataGridDefinition: IDevExpressDataGrid;

  public diarioAExcluir: IJsonEfaturaConfigDiarioAExcluir;

  constructor(
    protected readonly _injector: Injector,
    private readonly _conciliacaoEFaturasService: ConciliacaoEFaturasService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.config = {nCaracteresAValidar: 3, filtraPorDataDocExterno: true, diariosAExcluir: []};
    this.diarioAExcluir = {nDiario: undefined, nomeDiario: ''};
    this.dataGridDefinition = {
      columns: [
        {dataField: 'nDiario', dataType: 'number', caption: 'conciliacaoEFaturas.modal.config.fields.diario'},
        {dataField: 'nomeDiario', dataType: 'string', caption: 'conciliacaoEFaturas.modal.config.fields.nomeDiario'}
      ],
      keyExpr: 'nDiario',
      editing: {
        allowDeleting: true
      },
      export: {enabled: false},
      filterRow: {visible: false},
      remoteOperations: false,
      searchPanel: {visible: false},
      columnChooser: {enabled: false}
    };
  }

  public async close(): Promise<void> {
    this.disableClose();
    try {
      this.enableClose();
      await this._conciliacaoEFaturasService.saveConfigs(this.config);
      super.close();
    } finally {
      this.enableClose();
    }
  }

  public addDiarioAExcluir(): void {
    const index = this.config.diariosAExcluir.findIndex((item: IJsonEfaturaConfigDiarioAExcluir) => item.nDiario === this.diarioAExcluir.nDiario);
    if (index !== -1) {
      this._plAlertService.error('conciliacaoEFaturas.modal.config.messages.diarioAlreadyInList');
      return;
    }

    this.config.diariosAExcluir.push(this.diarioAExcluir);
    this.diarioAExcluir = {nDiario: undefined, nomeDiario: ''};
  }
}
