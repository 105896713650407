import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {CGCIconDirective} from '../icon';
import {PlAutoFocusModule} from '../autofocus/autofocus.module';
import {PlButtonModule} from '../button/button.module';
import {PlNavigationModule} from '../navigation/navigation.module';
import {PlTranslateModule} from '../translate/translate.module';

import {CGCModalContentDirective} from './components/modal.content.directive';
import {CGCModalHeaderDirective} from './components/modal.header.directive';
import {CGCModalOkCancelComponent} from './components/okcancel/okcancel.modal.component';

export * from './modals.utils';
export * from './components/modal.component';
export * from './components/modal.content.directive';
export * from './components/modal.header.directive';
export * from './components/okcancel/okcancel.modal.component.interface';
export * from './components/okcancel/okcancel.modal.component';
export * from './service/modal.service.interface';
export * from './service/modal.service';

const DECLARATIONS = [CGCModalContentDirective, CGCModalHeaderDirective, CGCModalOkCancelComponent];

@NgModule({
  imports: [CommonModule, NgbModalModule, CGCIconDirective, PlAutoFocusModule, PlButtonModule, PlNavigationModule, PlTranslateModule],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlModalModule {}
