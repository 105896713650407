import {NgModule} from '@angular/core';
import {PlAnimationCollapseModule} from './collapse/animation.collapse.module';
import {PlAnimationRotateModule} from './rotate/animation.rotate.module';
import {PlAnimationShakeModule} from './shake/animation.shake.module';

export * from './collapse/animation.collapse.module';
export * from './rotate/animation.rotate.module';
export * from './shake/animation.shake.module';

@NgModule({
  imports: [
    PlAnimationCollapseModule,
    PlAnimationRotateModule,
    PlAnimationShakeModule
  ],
  exports: [
    PlAnimationCollapseModule,
    PlAnimationRotateModule,
    PlAnimationShakeModule
  ]
})
export class PlAnimationModule {
}
