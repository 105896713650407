import {EModoFuncionamentoAnaliseMargemLucro, IDocfaCustoProveito, IDocfaCustoProveitoSinal} from '../../../modules/grupoDocfaConfiguracoes/grupoDocfaConfiguracoes.module.interface';
import {IJsonDocfaConfiguracao, IJsonGrupoDocfaConfiguracoes} from '../../../modules/grupoDocfaConfiguracoes/jsonGrupoDocfaConfiguracoes.module.interface';
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSaved,
  IDevExpressDataGridEventOnSaving
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IPlToolbarInstance, IPlToolbarItem, isObject, PlAlertService, PlToolbarService, PlTranslateService} from 'pl-comps-angular';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IJsonDocfa} from '../../../entities/docfas/jsonDocFa.entity.interface';
import {EGrupoDoc} from '../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {ENTITY_NAME_DOC_FAS} from '../../../entities/docfas/docFas.entity.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {HttpResponse} from '@angular/common/http';
import {EGroupName} from '../../../../config/constants';
import {GrupoDocfaConfiguracoesService} from '../../../modules/grupoDocfaConfiguracoes/grupoDocfaConfiguracoes.module.service';
import {IGrupoDocfaConfiguracoesCallback} from './grupoDocfaConfiguracoes.component.interface';

@Component({
  selector: 'grupo-docfa-configuracoes',
  templateUrl: './grupoDocfaConfiguracoes.component.html'
})
export class GrupoDocfaConfiguracoesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public toolbarInstanceName: string;
  @Input() public callback: IGrupoDocfaConfiguracoesCallback;

  public readonly configOptionsGroupName: EGroupName;
  public modosFuncionamento: Array<string>;
  public modoSelecionado: string;
  public dataGridDefinitionDocfasCustoEstimadoList: IDevExpressDataGrid<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>;
  public dataGridDefinitionDocfasCustoRealList: IDevExpressDataGrid<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>;
  public dataGridDefinitionDocfasProveitoEstimadoList: IDevExpressDataGrid<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>;
  public dataGridDefinitionDocfasProveitoRealList: IDevExpressDataGrid<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>;
  public dataDocfasCustoEstimadoList: Array<IJsonDocfaConfiguracao>;
  public dataDocfasCustoRealList: Array<IJsonDocfaConfiguracao>;
  public dataDocfasProveitoEstimadoList: Array<IJsonDocfaConfiguracao>;
  public dataDocfasProveitoRealList: Array<IJsonDocfaConfiguracao>;
  public btnSave: IPlToolbarItem;
  private readonly _serviceTiposDocumentos: IEntityService<IJsonDocfa>;
  private readonly _dataSinalList: Array<IDocfaCustoProveitoSinal>;
  private _configuracoes: IJsonGrupoDocfaConfiguracoes;
  private _documentos: Array<IJsonDocfa>;
  private _dataGridInstanceDocfasCustoEstimadoList: dxDataGrid<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>;
  private _dataGridInstanceDocfasCustoRealList: dxDataGrid<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>;
  private _dataGridInstanceDocfasProveitoEstimadoList: dxDataGrid<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>;
  private _dataGridInstanceDocfasProveitoRealList: dxDataGrid<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>;
  private _dataDocfasCustoEstimadoList: Array<IDocfaCustoProveito>;
  private _dataDocfasCustoRealList: Array<IDocfaCustoProveito>;
  private _dataDocfasProveitoEstimadoList: Array<IDocfaCustoProveito>;
  private _dataDocfasProveitoRealList: Array<IDocfaCustoProveito>;
  private _toolbarInstance: IPlToolbarInstance;

  constructor(
    private readonly _grupoDocfaConfiguracoesService: GrupoDocfaConfiguracoesService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plToolbarService: PlToolbarService
  ) {
    this.configOptionsGroupName = EGroupName.FINANCEIRO;

    this.modosFuncionamento = [
      this._plTranslateService.translate('gruposdocfas.configuracoes.modofuncionamento.options.processo'),
      this._plTranslateService.translate('gruposdocfas.configuracoes.modofuncionamento.options.centrocusto')
    ];
    this.modoSelecionado = this.modosFuncionamento[0];

    this._documentos = [];
    this._dataDocfasCustoEstimadoList = [];
    this._dataDocfasCustoRealList = [];
    this._dataDocfasProveitoEstimadoList = [];
    this._dataDocfasProveitoRealList = [];
    this._configuracoes = {
      modoFuncionamento: 0,
      nDocfasCustoEstimado: [],
      nDocfasCustoReal: [],
      nDocfasProveitoEstimado: [],
      nDocfasProveitoReal: []
    };
    this._serviceTiposDocumentos = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
    this._dataSinalList = [
      {id: 0, sinal: this._plTranslateService.translate('gruposdocfas.configuracoes.grids.enums.sinal.mais')},
      {id: 1, sinal: this._plTranslateService.translate('gruposdocfas.configuracoes.grids.enums.sinal.menos')}
    ];
  }

  public ngOnInit(): void {
    if (this.toolbarInstanceName) {
      this._toolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceName);

      this.btnSave = {
        id: 'save',
        order: 1,
        type: 'button',
        iconLeft: '<i class="fa fa-save fa-fw"></i>',
        class: 'btn-primary',
        caption: 'global.btn.save',
        click: () => this._saveConfigs()
      };

      this._toolbarInstance.addButton(this.btnSave);
    }

    this._grupoDocfaConfiguracoesService.getConfiguracoes().then((configuracao) => {
      if (configuracao?.body) {
        this._configuracoes = configuracao?.body;
      }
      this.modoSelecionado = this.modosFuncionamento[this._configuracoes.modoFuncionamento];
      this._serviceTiposDocumentos
        .query({pesquisa: `grupoDocfa=${EGrupoDoc.PropostasAClientes}|grupoDocfa=${EGrupoDoc.ComprasEfectivas}|grupoDocfa=${EGrupoDoc.ConsultasAFornecedores}|grupoDocfa=${EGrupoDoc.VendasEfectivas}`})
        .then((response) => {
          if (response) {
            this._documentos = response.body.list;
            this._fillDataLists();
            this._filterTiposDocumentos();
          }
        });
    });
  }

  public ngOnDestroy(): void {
    if (this._toolbarInstance) {
      if (this.btnSave) {
        this._toolbarInstance.removeButton(this.btnSave);
      }
    }
  }

  public ngOnChanges({callback}: SimpleChanges): void {
    if (callback) {
      const cb: IGrupoDocfaConfiguracoesCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.save = () => this._saveConfigs();
      }
    }
  }

  public onInitializedDefinitionDocfasCustoEstimadoList({component}: IDevExpressDataGridEventOnInitialized<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>): void {
    this._dataGridInstanceDocfasCustoEstimadoList = component;
  }

  public onInitializedDefinitionDocfasCustoRealList({component}: IDevExpressDataGridEventOnInitialized<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>): void {
    this._dataGridInstanceDocfasCustoRealList = component;
  }

  public onInitializedDefinitionDocfasProveitoEstimadoList({component}: IDevExpressDataGridEventOnInitialized<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>): void {
    this._dataGridInstanceDocfasProveitoEstimadoList = component;
  }

  public onInitializedDefinitionDocfasProveitoRealList({component}: IDevExpressDataGridEventOnInitialized<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>): void {
    this._dataGridInstanceDocfasProveitoRealList = component;
  }

  public onSavingConfigDocfas(event: IDevExpressDataGridEventOnSaving<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>): void {
    const eventDataSource: Array<IJsonDocfaConfiguracao> = event.component.getDataSource().items();
    event.changes.forEach((item) => {
      if (eventDataSource.some((value) => value.nDocfa === item.data.nDocfa)) {
        this._plAlertService.warning(this._plTranslateService.translate('gruposdocfas.configuracoes.avisos.tipodocexistente', {id: item.data.nDocfa}));
        event.cancel = true;
      }
    });
  }

  public onSavedConfigDocfasCustoEstimado(event: IDevExpressDataGridEventOnSaved<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>): void {
    this._onSavedConfigDocfasCustoProveito(event, this._dataDocfasCustoEstimadoList, this.dataDocfasCustoEstimadoList, this._dataGridInstanceDocfasCustoEstimadoList);
  }

  public onSavedConfigDocfasCustoReal(event: IDevExpressDataGridEventOnSaved<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>): void {
    this._onSavedConfigDocfasCustoProveito(event, this._dataDocfasCustoRealList, this.dataDocfasCustoRealList, this._dataGridInstanceDocfasCustoRealList);
  }

  public onSavedConfigDocfasProveitoEstimado(event: IDevExpressDataGridEventOnSaved<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>): void {
    this._onSavedConfigDocfasCustoProveito(event, this._dataDocfasProveitoEstimadoList, this.dataDocfasProveitoEstimadoList, this._dataGridInstanceDocfasProveitoEstimadoList);
  }

  public onSavedConfigDocfasProveitoReal(event: IDevExpressDataGridEventOnSaved<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>): void {
    this._onSavedConfigDocfasCustoProveito(event, this._dataDocfasProveitoRealList, this.dataDocfasProveitoRealList, this._dataGridInstanceDocfasProveitoRealList);
  }

  private _configuraGridCustosProveitos(dataList: Array<IDocfaCustoProveito>, descriptionToolbar: string): IDevExpressDataGrid<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao> {
    return {
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      columns: [
        {
          dataField: 'nDocfa',
          dataType: 'number',
          caption: 'gruposdocfas.configuracoes.grids.fields.id',
          showEditorAlways: true,
          lookup: {
            dataSource: dataList,
            valueExpr: 'nDocfa',
            displayExpr: (item: IDocfaCustoProveito) => `${item.nDocfa} - ${item.nome}`
          },
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._plTranslateService.translate('datagrid.column.required')
            }
          ]
        },
        {
          dataField: 'sinal',
          dataType: 'number',
          caption: 'gruposdocfas.configuracoes.grids.fields.sinal',
          showEditorAlways: true,
          lookup: {
            dataSource: this._dataSinalList,
            valueExpr: 'sinal',
            displayExpr: (item: IDocfaCustoProveitoSinal) => item.sinal
          },
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._plTranslateService.translate('datagrid.column.required')
            }
          ]
        }
      ],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        mode: 'cell'
      },
      toolbar: {
        items: [{location: 'before', text: this._plTranslateService.translate(descriptionToolbar)}]
      },
      export: {enabled: false},
      filterRow: {visible: false},
      showBorders: true,
      stateStoring: {enabled: false},
      allowColumnReordering: false,
      remoteOperations: false
    };
  }

  private _onSavedConfigDocfasCustoProveito(
    event: IDevExpressDataGridEventOnSaved<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>,
    dataDocfasList: Array<IDocfaCustoProveito>,
    dataDocfasSavedList: Array<IJsonDocfaConfiguracao>,
    dataGridInstance: dxDataGrid<IJsonDocfaConfiguracao, IJsonDocfaConfiguracao>
  ): void {
    event.changes.forEach((value, index: number) => {
      const docItem = dataDocfasList.find((item) => item.nDocfa === event.changes[index].data.nDocfa);
      if (docItem) {
        dataDocfasSavedList = dataDocfasSavedList.map((item) => {
          if (event.changes[index].data.nDocfa === item.nDocfa) {
            return {...item, nome: docItem.nome};
          }
          return item;
        });
      }
    });
    dataGridInstance.refresh().then(() => undefined);
  }

  private _fillDataLists(): void {
    this.dataDocfasCustoEstimadoList = this._configuracoes?.nDocfasCustoEstimado?.map((value: IJsonDocfaConfiguracao) => {
      return {nDocfa: value.nDocfa, sinal: value.sinal};
    });
    this.dataDocfasCustoEstimadoList = this.dataDocfasCustoEstimadoList ? this.dataDocfasCustoEstimadoList : [];

    this.dataDocfasCustoRealList = this._configuracoes?.nDocfasCustoReal?.map((value: IJsonDocfaConfiguracao) => {
      return {nDocfa: value.nDocfa, sinal: value.sinal};
    });
    this.dataDocfasCustoRealList = this.dataDocfasCustoRealList ? this.dataDocfasCustoRealList : [];

    this.dataDocfasProveitoEstimadoList = this._configuracoes?.nDocfasProveitoEstimado?.map((value: IJsonDocfaConfiguracao) => {
      return {nDocfa: value.nDocfa, sinal: value.sinal};
    });
    this.dataDocfasProveitoEstimadoList = this.dataDocfasProveitoEstimadoList ? this.dataDocfasProveitoEstimadoList : [];

    this.dataDocfasProveitoRealList = this._configuracoes?.nDocfasProveitoReal?.map((value: IJsonDocfaConfiguracao) => {
      return {nDocfa: value.nDocfa, sinal: value.sinal};
    });
    this.dataDocfasProveitoRealList = this.dataDocfasProveitoRealList ? this.dataDocfasProveitoRealList : [];
  }

  private _filterTiposDocumentos(): void {
    this._dataDocfasCustoEstimadoList = this._documentos.reduce((filtered: Array<IDocfaCustoProveito>, value: IJsonDocfa) => {
      if (value.grupoDocfa === EGrupoDoc.ConsultasAFornecedores) {
        filtered.push({nDocfa: value.nDocFa, nome: value.nome});
      }
      return filtered;
    }, []);

    this.dataGridDefinitionDocfasCustoEstimadoList = this._configuraGridCustosProveitos(this._dataDocfasCustoEstimadoList, 'gruposdocfas.configuracoes.gastos.estimado.gridtitle');

    this._dataDocfasCustoRealList = this._documentos.reduce((filtered: Array<IDocfaCustoProveito>, value: IJsonDocfa) => {
      if (value.grupoDocfa === EGrupoDoc.ComprasEfectivas) {
        filtered.push({nDocfa: value.nDocFa, nome: value.nome});
      }
      return filtered;
    }, []);

    this.dataGridDefinitionDocfasCustoRealList = this._configuraGridCustosProveitos(this._dataDocfasCustoRealList, 'gruposdocfas.configuracoes.gastos.real.gridtitle');

    this._dataDocfasProveitoEstimadoList = this._documentos.reduce((filtered: Array<IDocfaCustoProveito>, value: IJsonDocfa) => {
      if (value.grupoDocfa === EGrupoDoc.PropostasAClientes) {
        filtered.push({nDocfa: value.nDocFa, nome: value.nome});
      }
      return filtered;
    }, []);

    this.dataGridDefinitionDocfasProveitoEstimadoList = this._configuraGridCustosProveitos(this._dataDocfasProveitoEstimadoList, 'gruposdocfas.configuracoes.rendimentos.estimado.gridtitle');

    this._dataDocfasProveitoRealList = this._documentos.reduce((filtered: Array<IDocfaCustoProveito>, value: IJsonDocfa) => {
      if (value.grupoDocfa === EGrupoDoc.VendasEfectivas) {
        filtered.push({nDocfa: value.nDocFa, nome: value.nome});
      }
      return filtered;
    }, []);

    this.dataGridDefinitionDocfasProveitoRealList = this._configuraGridCustosProveitos(this._dataDocfasProveitoRealList, 'gruposdocfas.configuracoes.rendimentos.real.gridtitle');
  }

  private _saveConfigs(): Promise<void> {
    if (this.modoSelecionado === this.modosFuncionamento[EModoFuncionamentoAnaliseMargemLucro.Processo]) {
      this._configuracoes.modoFuncionamento = EModoFuncionamentoAnaliseMargemLucro.Processo;
    } else {
      this._configuracoes.modoFuncionamento = EModoFuncionamentoAnaliseMargemLucro.CentroDeCusto;
    }

    this._configuracoes.nDocfasCustoEstimado = this.dataDocfasCustoEstimadoList.map((value: IJsonDocfaConfiguracao) => {
      return {nDocfa: value.nDocfa, sinal: value.sinal};
    });

    this._configuracoes.nDocfasCustoReal = this.dataDocfasCustoRealList.map((value: IJsonDocfaConfiguracao) => {
      return {nDocfa: value.nDocfa, sinal: value.sinal};
    });

    this._configuracoes.nDocfasProveitoEstimado = this.dataDocfasProveitoEstimadoList.map((value: IJsonDocfaConfiguracao) => {
      return {nDocfa: value.nDocfa, sinal: value.sinal};
    });

    this._configuracoes.nDocfasProveitoReal = this.dataDocfasProveitoRealList.map((value: IJsonDocfaConfiguracao) => {
      return {nDocfa: value.nDocfa, sinal: value.sinal};
    });

    return this._grupoDocfaConfiguracoesService.save(this._configuracoes).then((response: HttpResponse<IJsonGrupoDocfaConfiguracoes>) => {
      if (response.body) {
        this._configuracoes = response.body;
        this._plAlertService.success('gruposdocfas.configuracoes.messages.configsavedsucess');
      }
    });
  }
}
