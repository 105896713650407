import {CGModalComponent} from '../../../../cg/modal/cgmodal.component';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {IJsonDocComercialCab} from '../../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {IDevExpressDataGrid} from '../../../../devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'encomendas-simulacao-modal',
  templateUrl: './encomendas.simulacao.modal.component.html'
})
export class EncomendasSimulacaoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public docComercialCab: IJsonDocComercialCab;

  public readonly dataGridDefinition: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'encomendas.modal.simulacao.fields.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'encomendas.modal.simulacao.fields.nome'},
        {dataField: 'totalIva', dataType: 'double', caption: 'encomendas.modal.simulacao.fields.totalIva'},
        {dataField: 'totalLiquido', dataType: 'double', caption: 'encomendas.modal.simulacao.fields.totalLiquido'},
        {dataField: 'totalDocumento', dataType: 'double', caption: 'global.text.total'}
      ],
      dataSource: [],
      export: {enabled: false},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      columnChooser: {enabled: false},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = [this.docComercialCab];
  }
}
