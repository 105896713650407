import {take} from 'rxjs/operators';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {isBoolean, isObject} from 'pl-comps-angular';
import {CGExceptionService} from '../../../../../components/exceptions/exceptions.service';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ContabilidadeDigitalService} from '../../../../../services/contabilidadedigital/contabilidadedigital.service';
import {EFolderDoc} from '../../../../../entities/gdoc/gdoc.entity.interface';
import {ICGExceptionError} from '../../../../../components/exceptions/exceptions.service.interface';
import {
  IContabilidadeDigitalTreeViewerEvtFolderItemChanged,
  IContabilidadeDigitalTreeViewerFolder
} from '../../../../../components/arquivodigital/common/treeviewer/contabilidadedigital.treeviewer.component.interface';
import {IJsonContabDigitalConfigs} from '../../../../../services/contabilidadedigital/jsonContabDigital.interface';

@Component({
  selector: 'modal-docsdigitais-tree-docs-nao-classificados',
  templateUrl: './docsDigitais.treeDocsNaoClassificados.modal.component.html'
})
export class DocsDigitaisTreeDocsNaoClassificadosModalComponent extends CGModalComponent<IContabilidadeDigitalTreeViewerFolder> implements OnInit {
  @Input() public folderID: string;
  @Input() public anoEmCursoIRC: boolean;
  @Input() public changeFolderFile: boolean;
  @Input() public currentFolderId: string;

  public readonly upwardsUntilFolderId: string;
  public loading: boolean;
  public defaultFolderId: string;
  public folderIdAsUpwards: boolean;
  public error: string;
  public canAddFileToFolder: boolean;

  private _selectedFolder: IContabilidadeDigitalTreeViewerFolder;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService
  ) {
    super(_injector);
    this.upwardsUntilFolderId = EFolderDoc.DefaultFolderContabNaoClassificados;
    this.loading = false;
    this.defaultFolderId = EFolderDoc.DefaultFolderContabNaoClassificados;
    this.anoEmCursoIRC = false;
    this.changeFolderFile = false;
    this.folderIdAsUpwards = false;
    this.canAddFileToFolder = false;
  }

  public ngOnInit(): void {
    if (!isBoolean(this.anoEmCursoIRC)) {
      this.anoEmCursoIRC = false;
    }
    if (!isBoolean(this.changeFolderFile)) {
      this.changeFolderFile = false;
    }
    if (!this.folderID) {
      this.loading = true;
      this._contabilidadeDigitalService.configs
        .getConfigs()
        .pipe(take(1))
        .subscribe({
          next: (configs: IJsonContabDigitalConfigs) => {
            if (isObject(configs)) {
              const lastFolder: string = configs.lastFolder;
              if (lastFolder) {
                this.defaultFolderId = lastFolder;
                this.folderIdAsUpwards = true;
              }
            }
          },
          complete: () => {
            this.loading = false;
          }
        });
    } else {
      this.defaultFolderId = this.folderID;
      this.folderIdAsUpwards = true;
    }
  }

  public close(): void {
    if (this.canAddFileToFolder) {
      super.close(this._selectedFolder);
    }
  }

  public changedFolder({folder}: IContabilidadeDigitalTreeViewerEvtFolderItemChanged): void {
    this._selectedFolder = folder;
    this.canAddFileToFolder = isObject(this._selectedFolder) && !this._selectedFolder.hasSubFolders;
    if (this.changeFolderFile && this.currentFolderId && folder.folderID === this.currentFolderId) {
      this.canAddFileToFolder = false;
      this.error = 'arquivodigital.gestaodocsdigitalizados.messages.modal.treedocsnaoclassificados.errorMoveDocSameFolder';
    }
  }

  public responseErrored(error: HttpErrorResponse): void {
    this.error = undefined;
    const exception: ICGExceptionError = this._cgExceptionService.get(error);
    if (exception) {
      this.error = exception.message;
    }
  }
}
