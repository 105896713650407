import {ApiService} from '../../../services/api/api.service';
import {ContabilidadeDigitalConfigStoreService} from '../../../services/contabilidadedigital/helperservices/contabilidadedigital.configstore.service';
import {ContabilidadeDigitalServiceConfigs} from '../../../services/contabilidadedigital/contabilidadedigital.service.configs';

export class ComercialDigitalServiceConfigs extends ContabilidadeDigitalServiceConfigs {
  constructor(
    protected readonly _apiService: ApiService,
    protected readonly _store: ContabilidadeDigitalConfigStoreService,
    basePath: string
  ) {
    super(_apiService, _store, basePath);
  }
}
