import {Subscription} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';
import {APP_THEMES_ITEMS, IAppThemeChangeEvt, IAppThemeItem} from '../../../../../common/themes/themes.interface';
import {AppService} from '../../../../services/app/app.service';

let COUNT = 0;

@Component({
  selector: 'cg-user-skin-chooser',
  templateUrl: './cg.user.skin.chooser.component.html'
})
export class CGUserSkinChooserComponent implements OnDestroy {
  public readonly themes: ReadonlyArray<IAppThemeItem>;
  public readonly count: number;
  public theme: IAppThemeItem;

  private readonly _subscriptionTheme: Subscription;

  constructor(private readonly _appService: AppService) {
    this.themes = APP_THEMES_ITEMS;
    this.count = ++COUNT;

    this._subscriptionTheme = this._appService.theme().subscribe((themeChangeEvt: IAppThemeChangeEvt) => {
      this.theme = this.themes.find((themeItem: IAppThemeItem) => themeItem.id === themeChangeEvt.theme);
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionTheme.unsubscribe();
  }

  public changedTheme(theme: IAppThemeItem): void {
    this._appService.setTheme(theme.id);
  }
}
