<div class="rpt-configs-module">
  <pl-split-view [initialSizeLeft]="30" [initialSizeRight]="70">
    <div *plSplitViewLeftSide>
      <div class="card">
        <div class="card-header text-bg-primary text-light">
          <div class="position-relative">
            <span class="fa fa-cog fa-2x card-icon"></span>
            <span class="card-title" [translate]="'rptconfigs.modulos'"></span>
          </div>
        </div>

        <div class="card-body">
          <dx-data-grid
            [cgDxDataGrid]="gridDefinitionModulos"
            cgDxDataGridInstanceName="modulosTable"
            [dataSource]="modulosSource"
            (onInitialized)="onInitializedModulos($event)"
            (onContentReady)="onContentReadyModulos()"
            (onCellClick)="onCellClick($event)">
          </dx-data-grid>
        </div>
      </div>
    </div>

    <div *plSplitViewRightSide>
      <div class="card">
        <div class="card-header text-bg-primary text-light">
          <span [translate]="'rptconfigs.variaveisRelatorio'"></span>
          <span *ngIf="moduloAtivo"
            ><strong> - {{ moduloAtivo.nome }}</strong></span
          >
        </div>

        <div class="card-body">
          <dx-data-grid
            [cgDxDataGrid]="gridDefinitionVariables"
            cgDxDataGridInstanceName="variablesTable"
            [dataSource]="variableSource"
            (onInitialized)="onInitializedVariables($event)"
            (onContentReady)="onContentReadyVariables()"
            (onCellPrepared)="onCellPrepared($event)">
          </dx-data-grid>
        </div>
      </div>
    </div>
  </pl-split-view>
</div>
