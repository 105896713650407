import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ModuloComponent} from '../../../../components/module/module.component';
import {ETipoCalculoDiferimento, IJsonCalculoDiferimentosConfig} from '../../jsonCalculoDiferimentos.interface';
import {calculoDiferimentosSourceTipos, calculoDiferimentosSourceTiposDataRef, IDiferimentosParams, IDiferimentosSaved, IHeaderCalculoDiferimentos} from '../../diferimentos.module.interface';
import {DiferimentosService} from '../../diferimentos.module.service';
import {CalculoDiferimentosConfigModalComponent} from '../../modals/config/calculoDiferimentos.config.modal.component';
import {copy} from 'pl-comps-angular';

@Component({
  selector: 'module-calculo-diferimentos',
  templateUrl: './calculoDiferimentos.module.component.html'
})
export class CalculoDiferimentosModuleComponent extends ModuloComponent implements OnInit {
  @Input() public diferimentosConfig: IJsonCalculoDiferimentosConfig;

  public readonly diferimentosParams: IDiferimentosParams;
  public header: IHeaderCalculoDiferimentos;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _calculoDiferimentosService: DiferimentosService
  ) {
    super(_injector);
    this.diferimentosParams = <IDiferimentosParams>this._transition.params();
    this.diferimentosConfig = {
      rendimentosRadicais: [],
      rendimentosNContaDiferimentos: '',
      nomeRendimentosNContaDif: '',
      rendimentosNContaAcrescimos: '',
      nomeRendimentosNContaAcresc: '',
      rendimentosTipoCalculo: ETipoCalculoDiferimento.Mensal,
      gastosRadicais: [],
      gastosNContaDiferimentos: '',
      nomeGastosNContaDif: '',
      gastosNContaAcrescimos: '',
      nomeGastosNContaAcresc: '',
      gastosTipoCalculo: ETipoCalculoDiferimento.Mensal,
      nDiario: undefined,
      nomeNDiario: '',
      descritivoAnulacaoInicial: undefined,
      nomeDescritivoAnulacaoInicial: '',
      descritivo: undefined,
      nomeDescritivo: '',
      descritivoAcrescimos: undefined,
      nomeDescritivoAcrescimos: '',
      isEmpresaSNC: false
    };
    this._save = this._save.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnSave.click = this._save;
    this.btnSave.caption = this.diferimentosParams.fromComercial ? 'global.btn.save' : 'calculodiferimentos.btn.lancacontabilidade';
    this.btnSave.visible = this.diferimentosParams.fromComercial || this.maintenanceMode;
    if (!this.diferimentosParams.fromComercial) {
      this.btnSave.disabled = (this.diferimentosParams.docBalanceado && !this.diferimentosParams.valor) || !this.diferimentosParams.docBalanceado;
    }
    const sourceMetodos = calculoDiferimentosSourceTipos(this._translateService);
    const sourceTiposDataRef = calculoDiferimentosSourceTiposDataRef(this._translateService);
    this.header = {
      tipodiferimento: sourceMetodos[this.diferimentosConfig.rendimentosTipoCalculo].value,
      nomeTipodiferimento: sourceMetodos[this.diferimentosConfig.rendimentosTipoCalculo].label,
      tipoDataRefDiferimento: sourceTiposDataRef[0].value,
      nomeTipoDataRefDiferimento: sourceTiposDataRef[0].label,
      dataini: undefined,
      datafim: undefined,
      valor: undefined,
      ncasasarredondamento: undefined
    };
    if (this.maintenanceMode) {
      this.setMaintenanceModeFullscreen(true);
    }

    this.btnConfig.visible = !this.diferimentosParams.fromComercial;
    this.btnConfig.order = this.btnSave.order + 1;
    this.btnConfig.click = () => this._openConfigModal();
  }

  public evtHeaderChanged(header: IHeaderCalculoDiferimentos): void {
    this.header = header;
  }

  public evtOutOffFocusDataFim(): void {
    this.toolbar.focusItem(this.btnSave);
  }

  private async _openConfigModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(CalculoDiferimentosConfigModalComponent);
    const componentInstance: CalculoDiferimentosConfigModalComponent = modalInstance.componentInstance;
    componentInstance.custDif = copy(this.diferimentosConfig);
    this.diferimentosConfig = await modalInstance.result;
  }

  private _save(): Promise<IDiferimentosSaved> {
    let promise: Promise<IDiferimentosSaved>;
    this.btnSave.promise = promise;
    promise = this._calculoDiferimentosService
      .validateDiferimento(this.header.dataini, this.header.datafim)
      .then(() => {
        const diferimentosComercial: Promise<IDiferimentosSaved> = Promise.resolve({
          dataIniDiferimento: this.header.dataini,
          dataFimDiferimento: this.header.datafim,
          tipodiferimento: this.header.tipodiferimento,
          tipoDataRefDiferimento: this.header.tipoDataRefDiferimento
        });
        this._subjectOnSave.next(diferimentosComercial);
        return diferimentosComercial;
      })
      .finally(() => {
        promise = undefined;
      });
    return promise;
  }
}
