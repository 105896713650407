import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_CONCELHOS, IConcelhosEntity, IConcelhosEntityService} from './concelhos.entity.interface';
import {ConcelhosEditComponent} from './components/edit/concelhos.entity.edit.component';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {IJsonConcelho, IJsonConcelhoAT, IJsonDadosDerrama} from './jsonConcelho.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {IJsonModelo22CompDerrama} from '../../modules/portalcontabilidade/modelo22/jsonModelo22.module.interface';
import {ENTITY_NAME_DISTRITOS} from '../distritos/distritos.entity.interface';
import {ENTITY_NAME_QPDCF} from '../qpdcf/qpdcf.entity.interface';

export const ENTITY_CONCELHOS: IConcelhosEntity = {
  name: ENTITY_NAME_CONCELHOS,
  roles: [ROLE.CONTABILIDADE, ROLE.RH, ROLE.ADMINBASIC],
  searchPlaceholder: 'concelhos.pesquisa',
  pageTitle: 'global.menu.concelhos',
  metadata: {
    keyName: 'codConcelho',
    fields: [
      {name: 'codConcelho', type: 'cgsmallint', width: '120px', caption: 'concelhos.fields.codConcelho', validators: {required: true}},
      {name: 'nomeConcelho', caption: 'concelhos.fields.nomeConcelho', validators: {required: true}},
      {name: 'codDistrito', visible: false},
      {
        name: 'nomeDistrito',
        caption: 'concelhos.fields.nomeDistrito',
        validators: {required: true},
        entity: {name: ENTITY_NAME_DISTRITOS, keyTarget: 'codDistrito', outputTarget: 'nomeDistrito'}
      },
      {name: 'codConcelhoAT', visible: false},
      {
        name: 'nomeConcelhoAT',
        caption: 'concelhos.fields.nomeConcelhoAT',
        validators: {required: true},
        entity: {name: ENTITY_NAME_QPDCF, keyTarget: 'codConcelhoAT', outputTarget: 'nomeConcelhoAT'}
      }
    ],
    order: 'nomeConcelho',
    searchFields: 'codConcelho,nomeConcelho,nomeDistrito',
    listFields: 'codConcelho,nomeConcelho,nomeDistrito'
  },
  autocomplete: {
    rowTemplate: '{{codConcelho}} - {{nomeConcelho}}',
    output: 'nomeConcelho',
    searchFields: 'codConcelho,nomeConcelho'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  detail: {
    state: {
      component: ConcelhosEditComponent,
      data: {
        formTemplate: {
          addMissingFields: false,
          items: [{field: 'codConcelho'}, {field: 'nomeConcelho'}]
        }
      },
      resolve: [
        {
          provide: 'anosDerrama',
          deps: [EntityServiceBuilder],
          useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<number>> => {
            const service: IConcelhosEntityService = entityServiceBuilder.build<IJsonConcelho, IConcelhosEntityService>(ENTITY_NAME_CONCELHOS);
            return service.getAnosDerrama().then((response: HttpResponse<Array<number>>) => response.body);
          }
        }
      ]
    }
  },
  service: function () {
    this.getAnosDerrama = () => {
      return this.get<Array<number>>({id: 'anosderrama'});
    };

    this.checkCalculoDerrama = (codConcelho: number, anoDerrama: number) => {
      return this.get<boolean>({id: `${codConcelho}/anoderrama/${anoDerrama}/valida`});
    };

    this.getCalculoDerrama = (codConcelho: number, anoDerrama: number) => {
      return this.get<IJsonModelo22CompDerrama>({id: `${codConcelho}/anoderrama/${anoDerrama}`});
    };

    this.getDadosDerrama = (codConcelho: number, anoDerrama: number) => {
      return this.get<IJsonDadosDerrama>({id: `${codConcelho}/anoderrama/${anoDerrama}/dados`});
    };

    this.getListaConcelhosAT = (codDistrito: number) => {
      return this.get<Array<IJsonConcelhoAT>>({id: 'listaconcelhosat', params: {coddistrito: codDistrito}});
    };

    this.postCalculoDerrama = (compderrama: IJsonModelo22CompDerrama) => {
      return this.post({url: 'calculoderrama', body: compderrama});
    };
  }
};
