import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {Observable} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {IJsonInfoDadosRemuneracao, IJsonRHGestaoFicheirosDMRAT} from './jsonRHGestaoFicheirosDMRAT.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IEmailSendResult} from '../../../../common/interfaces/email.interfaces';

@Injectable({
  providedIn: 'root'
})
export class RHGestaoFicheirosDMRATService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/rhgestaoficheirosdmrat`;
  }

  public getSSDecRemunList(year: number, month: number): TServiceResponse<Array<IJsonRHGestaoFicheirosDMRAT>> {
    return this._apiService.get<Array<IJsonRHGestaoFicheirosDMRAT>>({url: `${this._path}/${year}/${month}`});
  }

  public postDadosInfo(gestaofichdmr: IJsonRHGestaoFicheirosDMRAT): TServiceResponse<IJsonInfoDadosRemuneracao> {
    return this._apiService.post<IJsonInfoDadosRemuneracao, IJsonRHGestaoFicheirosDMRAT>({
      url: `${this._path}/dadosinfo`,
      body: gestaofichdmr
    });
  }

  public getComprovativo(ssDecREmunID: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/comprovativo`, {ssdecremunid: ssDecREmunID});
  }

  public getDocPagamento(ssDecREmunID: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/docpagamento`, {ssdecremunid: ssDecREmunID});
  }

  public getEnviarDocPagamentoEmail(ssDecREmunID: string, email: string): TServiceResponse<IEmailSendResult> {
    return this._apiService.get<IEmailSendResult>({
      url: `${this._path}/enviardocpagamentoemail`,
      params: {
        ssdecremunid: ssDecREmunID,
        email: email
      }
    });
  }

  public verificarEnvios(gestaoFichSelList: Array<IJsonRHGestaoFicheirosDMRAT>): TServiceResponse<Array<IJsonRHGestaoFicheirosDMRAT>> {
    return this._apiService.post<Array<IJsonRHGestaoFicheirosDMRAT>>({
      url: `${this._path}/verificarenvios`,
      body: gestaoFichSelList
    });
  }

  public postGerarFicheiros(gestaoFichSelList: Array<IJsonRHGestaoFicheirosDMRAT>): TServiceResponse<Blob> {
    return this._apiService.post<Blob, Array<IJsonRHGestaoFicheirosDMRAT>>({
      url: `${this._path}/gerarficheiros`,
      responseType: 'blob',
      body: gestaoFichSelList
    });
  }

  public postReenviarWebservice(gestaoFichSelList: Array<IJsonRHGestaoFicheirosDMRAT>): TServiceResponse<Blob> {
    return this._apiService.post<Blob, Array<IJsonRHGestaoFicheirosDMRAT>>({
      url: `${this._path}/reenviarwebservice`,
      responseType: 'blob',
      body: gestaoFichSelList
    });
  }
}
