import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonConcelho, IJsonConcelhoAT, IJsonDadosDerrama} from './jsonConcelho.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonModelo22CompDerrama} from '../../modules/portalcontabilidade/modelo22/jsonModelo22.module.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface IConcelhosEntityService extends IEntityService<IJsonConcelho> {
  getAnosDerrama(): TServiceResponse<Array<number>>;

  checkCalculoDerrama(codconcelho: number, anoderrama: number): TServiceResponse<boolean>;

  getCalculoDerrama(codconcelho: number, anoderrama: number): TServiceResponse<IJsonModelo22CompDerrama>;

  getDadosDerrama(codconcelho: number, anoderrama: number): TServiceResponse<IJsonDadosDerrama>;

  getListaConcelhosAT(codDistrito: number): TServiceResponse<Array<IJsonConcelhoAT>>;

  postCalculoDerrama(compderrama: IJsonModelo22CompDerrama): TServiceResponse<void>;
}

export interface IConcelhosEntity extends IEntityDefinition<IJsonConcelho, IConcelhosEntityService> {}

export const ENTITY_NAME_CONCELHOS = 'concelhos';
