import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {UntypedFormGroup} from '@angular/forms';
import {copy, isObject, PlAlertService} from 'pl-comps-angular';
import {IJsonConfigRHDadosFicha} from '../../jsonRHConfiguracoes.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RHConfiguracoesService} from '../../rhConfiguracoes.module.service';

@Component({
  selector: 'module-rh-dadosconfigficha',
  templateUrl: './rhDadosConfigFicha.module.component.html'
})
export class RHDadosConfigFichaModuleComponent extends ModuloComponent implements OnInit {
  @Input() public dadosFicha: IJsonConfigRHDadosFicha;

  public hasDadosFicha: boolean;
  public error: string;
  public formInstance: UntypedFormGroup;
  public promise: Promise<void>;

  private _editing: boolean;
  private _dadosFichaOriginais: IJsonConfigRHDadosFicha;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhConfiguracoesService: RHConfiguracoesService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._dadosFichaOriginais = copy<IJsonConfigRHDadosFicha>(this.dadosFicha);
    this.btnEdit.click = this._onEdit.bind(this);
    this.btnSave.click = this._onSave.bind(this);
    this.btnCancel.click = this._onCancel.bind(this);
    this.editing = false;
  }

  public applyRecommendedConfiguration(): void {
    const hasDadosFicha: boolean = this.hasDadosFicha;
    this.hasDadosFicha = true;
    this.promise = this._rhConfiguracoesService
      .getDadosFichaRecomendados()
      .then((response: HttpResponse<IJsonConfigRHDadosFicha>) => {
        this.dadosFicha = response.body;
        this.editing = true;
        this.hasDadosFicha = true;
      })
      .catch((reason: HttpErrorResponse) => {
        this._logger.error(reason);
        this.hasDadosFicha = hasDadosFicha;
      });
  }

  public get editing(): boolean {
    return this._editing;
  }

  public set editing(value: boolean) {
    this._editing = value;
    this._evaluateState();
  }

  private _onEdit(): void {
    this.editing = true;
  }

  private _onSave(): Promise<void> {
    this.error = '';
    if (!this.formInstance.valid) {
      this.error = 'entity.state.error';
      return Promise.resolve();
    }
    return this._rhConfiguracoesService.updateDadosFicha(this.dadosFicha).then((response: HttpResponse<IJsonConfigRHDadosFicha>) => {
      this.dadosFicha = response.body;
      this._dadosFichaOriginais = copy<IJsonConfigRHDadosFicha>(this.dadosFicha);
      this._plAlertService.success('rhintegracaosalarios.message.savedsuccessfully');
      this.editing = false;
    });
  }

  private _onCancel(): void {
    this.dadosFicha = copy<IJsonConfigRHDadosFicha>(this._dadosFichaOriginais);
    this.editing = false;
  }

  private _evaluateState(): void {
    this.btnEdit.visible = !this.editing;
    this.btnSave.visible = this.editing;
    this.btnCancel.visible = this.editing;
    this.hasDadosFicha = isObject(this.dadosFicha) && this._rhConfiguracoesService.hasDadosFicha(this.dadosFicha);
  }
}
