import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {downloadStream} from 'pl-comps-angular';
import {TDate} from '../../../common/dates';
import {ApiService} from '../../services/api/api.service';
import {TServiceQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';
import {IJsonTrfBa, IJsonTrfBaConfig, IJsonTrfBaConfigIBAN, IJsonTrfBaItemTree, IJsonTrfBaRegistoFich} from './jsonTrfBa.module.interface';

@Injectable({
  providedIn: 'root'
})
export class TrfBaService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/trfba`;
  }

  public getRegistoFicheiro(): TServiceResponse<IJsonTrfBaRegistoFich> {
    return this._apiService.get<IJsonTrfBaRegistoFich>({url: `${this._path}/registoficheiro`});
  }

  public getConfigFicheiro(): TServiceResponse<IJsonTrfBaConfig> {
    return this._apiService.get<IJsonTrfBaConfig>({url: `${this._path}/configficheiro`});
  }

  public getExpandEstado(estado: boolean): TServiceResponse<Array<IJsonTrfBaItemTree>> {
    return this._apiService.get<Array<IJsonTrfBaItemTree>>({
      url: `${this._path}/expandestado`,
      params: {
        estado: estado
      }
    });
  }

  public getExpandBanco(estado: boolean, formatotransferencia: string, moduleName: string): TServiceResponse<Array<IJsonTrfBaItemTree>> {
    return this._apiService.get<Array<IJsonTrfBaItemTree>>({
      url: `${this._path}/expandbanco`,
      params: {
        estado: estado,
        formatotransferencia: formatotransferencia,
        modulename: moduleName
      }
    });
  }

  public getExpandOperacao(estado: boolean, formatotransferencia: string, operacao: number): TServiceResponse<Array<IJsonTrfBaItemTree>> {
    return this._apiService.get<Array<IJsonTrfBaItemTree>>({
      url: `${this._path}/expandoperacao`,
      params: {
        estado: estado,
        formatotransferencia: formatotransferencia,
        operacao: operacao
      }
    });
  }

  public getExpandData(estado: boolean, formatotransferencia: string, operacao: number, data: string): TServiceResponse<Array<IJsonTrfBaItemTree>> {
    return this._apiService.get<Array<IJsonTrfBaItemTree>>({
      url: `${this._path}/expanddata`,
      params: {
        estado: estado,
        formatotransferencia: formatotransferencia,
        operacao: operacao,
        data: data
      }
    });
  }

  public getDocsTrfBaNibEmi(estado: boolean, formatotransferencia: string, operacao: number, data: string, iban: string): TServiceResponse<Array<IJsonTrfBa>> {
    return this._apiService.get<Array<IJsonTrfBa>>({
      url: `${this._path}/docstrfnib`,
      params: {
        estado: estado,
        formatotransferencia: formatotransferencia,
        operacao: operacao,
        data: data,
        iban: iban
      }
    });
  }

  public adicionarConfigIBAN(trfBaConfigIBAN: IJsonTrfBaConfigIBAN): TServiceResponse<Array<IJsonTrfBaConfigIBAN>> {
    return this._apiService.post<Array<IJsonTrfBaConfigIBAN>, IJsonTrfBaConfigIBAN>({
      url: `${this._path}/adicionaibanconfig`,
      body: trfBaConfigIBAN
    });
  }

  public eliminaConfigIBAN(trfBaConfigIBAN: IJsonTrfBaConfigIBAN): TServiceResponse<Array<IJsonTrfBaConfigIBAN>> {
    return this._apiService.post<Array<IJsonTrfBaConfigIBAN>, IJsonTrfBaConfigIBAN>({
      url: `${this._path}/eliminaibanconfig`,
      body: trfBaConfigIBAN
    });
  }

  public geraFicheiro(
    trfbaids: string,
    datalanc: TDate,
    nomeficheirouploaddgt: string,
    numerosequencial: number,
    prioridade: boolean,
    rgformato: number,
    trfbaregistofich: IJsonTrfBaRegistoFich
  ): TServiceResponse<Blob> {
    return this._apiService.post<Blob, IJsonTrfBaRegistoFich>({
      url: `${this._path}/geraficheiro`,
      responseType: 'blob',
      body: trfbaregistofich,
      params: {
        trfbaids: trfbaids,
        datalanc: datalanc,
        nomeficheirouploaddgt: nomeficheirouploaddgt,
        numerosequencial: numerosequencial,
        prioridade: prioridade,
        rgformato: rgformato
      }
    });
  }

  public geraFicheiroEDescarregar(
    trfbaids: string,
    datalanc: TDate,
    nomeficheirouploaddgt: string,
    numerosequencial: number,
    prioridade: boolean,
    rgformato: number,
    trfbaregistofich: IJsonTrfBaRegistoFich
  ): Promise<void> {
    return this.geraFicheiro(trfbaids, datalanc, nomeficheirouploaddgt, numerosequencial, prioridade, rgformato, trfbaregistofich).then((response: HttpResponse<Blob>) => {
      downloadStream(response);
    });
  }

  public configTemplate(template: number): TServiceResponse<void> {
    return this._apiService.put<void>({
      url: `${this._path}/configtemplate`,
      params: {template: template}
    });
  }

  public editarValor(trfID: number, valor: number): TServiceResponse<void> {
    return this._apiService.put<void>({
      url: `${this._path}/valor`,
      params: {
        trfbaid: trfID,
        valor: valor
      }
    });
  }

  public alterarEstadoDeEnvio(trfbaids: string, deleteallfromfiles: boolean = false): TServiceResponse<void> {
    return this._apiService.put<void>({
      url: `${this._path}/estadodeenvio`,
      params: {
        trfbaids: trfbaids,
        deleteallfromfiles: deleteallfromfiles
      }
    });
  }

  public deleteTransferencias(trfbaids: string): TServiceResponse<void> {
    return this._apiService.delete<void>({
      url: this._path,
      params: {
        trfbaids: trfbaids
      }
    });
  }

  public getTransfBancariaSEPAComFicheiroPorGerar(ncaixa: number, efetuada: boolean, datapagamde: TDate, datapagamate: TDate): TServiceQueryResponse<IJsonTrfBa> {
    return this._apiService.get({
      url: `${this._path}/meioPagamento/${ncaixa}`,
      params: {
        efetuada: efetuada,
        datapagamde: datapagamde,
        datapagamate: datapagamate
      }
    });
  }

  public exportPagamentosSEPA(trfbaids: string): TServiceResponse<Blob> {
    return this._apiService.get({
      url: `${this._path}/sepa/pagamento`,
      responseType: 'blob',
      params: {
        trfbaidlist: trfbaids
      }
    });
  }

  public exportPagamentosSEPAAndDownload(trfbaids: string): Promise<void> {
    return this.exportPagamentosSEPA(trfbaids).then((response: HttpResponse<Blob>) => {
      downloadStream(response);
    });
  }

  public exportFicheiroSepa(trfbaId: number): TServiceResponse<Blob> {
    return this._apiService.get({
      url: `${this._path}/${trfbaId}/sepafile`,
      responseType: 'blob'
    });
  }

  public exportFicheiroSepaAndDownload(trfbaId: number): Promise<void> {
    return this.exportFicheiroSepa(trfbaId).then((response: HttpResponse<Blob>) => {
      downloadStream(response);
    });
  }

  public getTransfenciaBancariaSEPA(extpoccabid: string): TServiceResponse<IJsonTrfBa> {
    return this._apiService.get({url: `${this._path}/sepa/pagamento/doc/${extpoccabid}`});
  }
}
