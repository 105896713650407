import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonProcesso} from './jsonProcesso.entity.interface';
import {ENTITY_NAME_PROCESSOS} from './processos.entity.interface';

export const ENTITY_PROCESSOS: IEntityDefinition<IJsonProcesso> = {
  name: ENTITY_NAME_PROCESSOS,
  roles: [ROLE.ERP, ROLE.ATIVOS],
  searchPlaceholder: 'processos.pesquisa',
  pageTitle: 'global.menu.processos',
  metadata: {
    keyName: 'nRefProcesso',
    fields: [
      {
        name: 'nRefProcesso',
        width: '120px',
        caption: 'processos.fields.nRefProcesso',
        placeholder: 'processos.fields.nRefProcessoPlaceholder',
        validators: {required: true, maxlength: 25}
      },
      {name: 'nome', caption: 'processos.fields.nome', placeholder: 'processos.fields.nomePlaceholder', validators: {required: true, maxlength: 200}},
      {
        name: 'nClifo',
        caption: 'processos.fields.nClifo',
        placeholder: 'processos.fields.nClifoPlaceholder',
        entity: {
          name: 'clifos',
          keyTarget: 'nClifo',
          outputTarget: 'nomeClifo',
          fieldsMap: {nConta: 'nClifo', nome: 'nomeClifo'},
          output: '{{nConta}} - {{nome}}'
        }
      },
      {name: 'nomeClifo', visible: false, caption: 'processos.fields.nomeClifo', placeholder: 'processos.fields.nomeClifoPlaceholder'},
      {name: 'terminado', type: 'boolean', caption: 'processos.fields.terminado'}
    ],
    order: 'nome'
  },
  autocomplete: {
    rowTemplate: '{{nRefProcesso}} - {{nome}}',
    output: 'nome',
    outputKey: 'nRefProcesso',
    outputDescription: 'nome'
  },
  actions: {
    delete: true,
    detail: true,
    duplicate: true,
    edit: true,
    new: true
  }
};
