export enum EGestaoComunicacoesEstadoTipo {
  None,
  SistemaInicial,
  SistemaFinal,
  Utilizador
}

export interface IJsonGestaoComunicacoesEstado {
  _empresaSistema?: boolean;
  comEstadoId: string;
  nome: string;
  tipo: EGestaoComunicacoesEstadoTipo;
  cor: string;
  todasEmpresas: boolean;
  nEmpresa: string;
}

export interface IJsonGestaoComunicacoesEstadoOrdem extends IJsonGestaoComunicacoesEstado {
  ordem: number;
}
