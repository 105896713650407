<div class="faturacaopublica-config-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'faturacaopublica.config.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-tabs [activeId]="activeTabID" (evtSelected)="changedTab($event.nextId)">
      <pl-tab id="saphety" caption="faturacaopublica.tab.saphety">
        <div *plTabContent>
          <pl-group>
            <pl-group>
              <label [translate]="'faturacaopublica.config.utilizador'"></label>
              <edit>
                <pl-edit type="text" attrName="utilizador" [(model)]="configSaphety.configDados.utilizador"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'faturacaopublica.config.senha'"></label>
              <edit>
                <pl-edit type="password" attrName="senha" [(model)]="configSaphety.configDados.senha"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <div class="card" style="display: none">
            <div class="card-header" [translate]="'faturacaopublica.config.estados'"></div>
            <div class="card-body">
              <pl-group>
                <label [translate]="'faturacaopublica.config.estadosConta'"></label>
                <edit>
                  <pl-autocomplete
                    [source]="metodoSourceEstadosConta"
                    attrName="estadosConta"
                    [model]="nomeEstadosContaSaphety"
                    (evtSelected)="metodoEstadosContaChanged($event)"
                    [rowTemplate]="mtdOutput"
                    output="nameMetodo">
                  </pl-autocomplete>
                </edit>
              </pl-group>

              <dx-data-grid
                [cgDxDataGrid]="dataGridDefinition"
                cgDxDataGridInstanceName="configsSafety"
                [dataSource]="configSaphety.configDados.contasEstadoList"
                (onInitialized)="onInitializedSaphety($event)"
                (onContentReady)="onContentReadySaphety()">
                <div *dxTemplate="let cell of 'templateToolbar'">
                  <div class="dx-toolbar-autocomplete-inline">
                    <button type="button" class="btn btn-primary btn-xs action-add-folder" (click)="addLineFatPubConfigEstado()" plPromise>
                      <i class="fa fa-fw fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div *dxTemplate="let item of 'actions'">
                  <button type="button" class="btn btn-warning btn-xs action-edit-line" (click)="editLineFatPubConfigEstado(item.data)" plPromise>
                    <i class="fa fa-fw fa-pencil"></i>
                  </button>
                  <button type="button" class="btn btn-danger btn-xs action-remove-line" (click)="deleteLineFatPubConfigEstado(item.data)" plPromise>
                    <i class="fa fa-fw fa-minus"></i>
                  </button>
                </div>
              </dx-data-grid>
            </div>
          </div>
        </div>
      </pl-tab>
      <pl-tab id="feap" [caption]="'faturacaopublica.tab.feap'">
        <div *plTabContent>
          <pl-group>
            <pl-group>
              <label [translate]="'faturacaopublica.config.utilizador'"></label>
              <edit>
                <pl-edit type="text" attrName="utilizador" [(model)]="configFEAP.configDados.utilizador"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'faturacaopublica.config.senha'"></label>
              <edit>
                <pl-edit type="password" attrName="senha" [(model)]="configFEAP.configDados.senha"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <div class="card" style="display: none">
            <div class="card-header" [translate]="'faturacaopublica.config.estados'"></div>
            <div class="card-body">
              <pl-group>
                <label [translate]="'faturacaopublica.config.estadosConta'"></label>
                <edit>
                  <pl-autocomplete
                    [source]="metodoSourceEstadosConta"
                    attrName="estadosContaFEAP"
                    [model]="nomeEstadosContaFEAP"
                    (evtSelected)="metodoEstadosContaChanged($event)"
                    [rowTemplate]="mtdOutput"
                    output="nameMetodo">
                  </pl-autocomplete>
                </edit>
              </pl-group>

              <dx-data-grid
                [cgDxDataGrid]="dataGridDefinition"
                cgDxDataGridInstanceName="configsSafety"
                [dataSource]="configFEAP.configDados.contasEstadoList"
                (onInitialized)="onInitializedFEAP($event)"
                (onContentReady)="onContentReadyFEAP()">
                <div *dxTemplate="let cell of 'templateToolbar'">
                  <div class="dx-toolbar-autocomplete-inline">
                    <button type="button" class="btn btn-primary btn-xs action-add-folder" (click)="addLineFatPubConfigEstado()" plPromise>
                      <i class="fa fa-fw fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div *dxTemplate="let item of 'actions'">
                  <button type="button" class="btn btn-warning btn-xs action-edit-line" (click)="editLineFatPubConfigEstado(item.data)" plPromise>
                    <i class="fa fa-fw fa-pencil"></i>
                  </button>
                  <button type="button" class="btn btn-danger btn-xs action-remove-line" (click)="deleteLineFatPubConfigEstado(item.data)" plPromise>
                    <i class="fa fa-fw fa-minus"></i>
                  </button>
                </div>
              </dx-data-grid>
            </div>
          </div>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button" plPromise>
    <pl-button klass="btn-success action-save" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
