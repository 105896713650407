import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlAnimationCollapseModule} from '../animation/collapse/animation.collapse.module';

import {PlUITreeDragComponent} from './uitreedrag.component';
import {PlUITreeDragNodeComponent} from './uitreedrag.node.component';
import {PlUITreeDragNodeContentDirective} from './uitreedrag.node.content.directive';

export * from './uitreedrag.interface';
export * from './uitreedrag.component';
export * from './uitreedrag.node.component';
export * from './uitreedrag.node.content.directive';

const DECLARATIONS = [
  PlUITreeDragComponent,
  PlUITreeDragNodeComponent,
  PlUITreeDragNodeContentDirective
];

@NgModule({
  imports: [
    CommonModule,
    PlAnimationCollapseModule
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlUITreeDragModule {
}
