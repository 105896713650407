import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {ETipoContabilidade} from '../../../datasources/tipocontabilidade/tipoContabilidade.datasource.interface';
import {IJsonApuraStockInfoApuramento, IJsonApuraStockParamsDoc, IJsonConfigContas, IJsonTiposContasPoc} from './jsonapurainventperma.module';
import {IJsonDocContabilidadeCab} from '../docscontabilidade/jsonDocContabilidade.interface';
import {IJsonPeriodo} from '../../../entities/periodos/jsonPeriodo.entity.interface';

@Injectable({
  providedIn: 'root'
})
export class ContabilidadeApuraInventPermaService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/apurastock`;
  }

  public getDefaultDadosParamsApuraStock(): TServiceResponse<IJsonApuraStockParamsDoc> {
    return this._apiService.get<IJsonApuraStockParamsDoc>({url: `${this._path}/docsdefaultparamsdados`});
  }

  public getInfoDadosParamsApuraStock(periodo: string): TServiceResponse<IJsonApuraStockParamsDoc> {
    return this._apiService.get<IJsonApuraStockParamsDoc>({url: `${this._path}/docsinfoperiodoparamsdados`, params: {periodo: periodo}});
  }

  public getNextPeriodoApuraStock(): TServiceResponse<IJsonPeriodo> {
    return this._apiService.get<IJsonPeriodo>({url: `${this._path}/loadnextperiodo`});
  }

  public getContasApuraStock(): TServiceResponse<Array<IJsonTiposContasPoc>> {
    return this._apiService.get<Array<IJsonTiposContasPoc>>({url: `${this._path}/loadcontasapurastock`});
  }

  public getInfoApuramento(): TServiceResponse<Array<IJsonApuraStockInfoApuramento>> {
    return this._apiService.get<Array<IJsonApuraStockInfoApuramento>>({url: `${this._path}/loadinfoapuramentos`});
  }

  public getConfigContas(tipoContabilidade?: ETipoContabilidade): TServiceResponse<IJsonConfigContas> {
    return this._apiService.get<IJsonConfigContas>({url: `${this._path}/loadconfigcontas`, params: {tipocontabilidade: tipoContabilidade}});
  }

  public getConfigContasOriginal(tipoContabilidade?: ETipoContabilidade): TServiceResponse<IJsonConfigContas> {
    return this._apiService.get<IJsonConfigContas>({url: `${this._path}/reporvaloresorigem`, params: {tipocontabilidade: tipoContabilidade}});
  }

  public executarApuraStock(periodo: string, apuramentoExtPocList: Array<IJsonDocContabilidadeCab>): TServiceResponse<void> {
    return this._apiService.post<void, Array<IJsonDocContabilidadeCab>>({url: `${this._path}/executarapurastock`, params: {periodo: periodo}, body: apuramentoExtPocList});
  }

  public apagaDocumentosDeApuramentoApuraStock(periodo: string): TServiceResponse<void> {
    return this._apiService.post<void>({url: `${this._path}/apagadocumentosdeapuramento`, params: {periodo: periodo}});
  }

  public simularApuraStock(params: IJsonApuraStockParamsDoc, variaveisApuramento: Array<IJsonTiposContasPoc>): TServiceResponse<Array<IJsonDocContabilidadeCab>> {
    return this._apiService.post<Array<IJsonDocContabilidadeCab>, Array<IJsonTiposContasPoc>>({
      url: `${this._path}/simularapurastock`,
      params: {
        periodo: params.nPeriodo,
        diario: params.nDiario,
        coddescritivo: params.nDescritivo,
        datadoc: params.dataDoc
      },
      body: variaveisApuramento
    });
  }

  public saveConfigContas(config: IJsonConfigContas, tipoContabilidade?: ETipoContabilidade): TServiceResponse<boolean> {
    return this._apiService.put<boolean, IJsonConfigContas>({
      url: `${this._path}/saveconfigcontas`,
      params: {tipocontabilidade: tipoContabilidade},
      body: config
    });
  }
}
