import {Component, Injector} from '@angular/core';
import {IConcilMovAbNContaEmSerie} from '../../concilMovAb.module.interface';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {KEYCODES} from 'pl-comps-angular';

@Component({
  selector: 'modal-concilmovab-justemseriecontas',
  templateUrl: './concilMovAb.justEmSerieContas.modal.component.html'
})
export class ConcilMovAbJustEmSerieContasModalComponent extends CGModalComponent<IConcilMovAbNContaEmSerie> {
  public readonly periodosRowTemplate: string = '{{periodo}} - {{nome}}';
  public concilMovAbNContaEmSerie: IConcilMovAbNContaEmSerie;
  public filterMovimento: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.concilMovAbNContaEmSerie = {
      nContaDe: '21',
      nContaAte: '2299999999999999999999999999999',
      periodoAte: `${this._configService.configurations.empresa.anoEmCursoIRC + 1}121`
    };
    this.filterMovimento = `tipo=${ETipoContaContabilidade.Movimento}`;
  }

  public close(): void {
    super.close(this.concilMovAbNContaEmSerie);
  }

  public keydownPeriodoAte(value: string, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      const button: HTMLElement = document.querySelector('[attrName="justserie"] button');
      if (button) {
        setTimeout(() => {
          button.focus();
        });
      }
    }
  }
}
