<div class="dgempsfull-comment-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'dgempsfull.comments.modalTitle'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'dgempsfull.comments.title'"></label>
      <edit>
        <pl-edit type="text" attrName="titulo" [(model)]="comentario.titulo"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'dgempsfull.comments.text'"></label>
      <edit>
        <pl-edit type="text" attrName="texto" [(model)]="comentario.texto"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-dismiss" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
