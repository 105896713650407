<div class="account-fundo">
  <div class="container account-box">
    <div class="alert alert-danger light" *ngIf="errorCount" [plAnimationShake]="errorCount">
      <button type="button" class="btn-close" aria-label="Close" (click)="setHasError(false)"><span></span></button>
      <div *ngIf="errorMessage" [innerHTML]="errorMessage | translate"></div>
    </div>

    <ui-view></ui-view>

    <div class="account-text">
      <p class="account-empresa" [translate]="'global.centralgestLoginName'"></p>

      <p>© {{ date }} · <span [translate]="'global.allRightsReserved'"></span>.</p>

      <ul class="account-social-icons list-inline">
        <li class="list-inline-item">
          <a href="https://www.facebook.com/CentralGest" target="_blank" rel="noopener noreferrer"><i class="fa fa-fw fa-facebook"></i></a>
        </li>

        <li class="list-inline-item">
          <a href="https://twitter.com/centralgest" target="_blank" rel="noopener noreferrer"><i class="fa fa-fw fa-twitter"></i></a>
        </li>

        <li class="list-inline-item">
          <a href="https://www.youtube.com/channel/UC6GsuXWfr1limRpEwxX0Rnw" target="_blank" rel="noopener noreferrer"><i class="fa fa-fw fa-youtube-play"></i></a>
        </li>

        <li class="list-inline-item">
          <a href="https://pt.linkedin.com/in/centralgest" target="_blank" rel="noopener noreferrer"><i class="fa fa-fw fa-linkedin"></i></a>
        </li>

        <li class="list-inline-item">
          <a href="http://www.centralgest.com" target="_blank" rel="noopener noreferrer"><i class="fa fa-fw fa-globe"></i></a>
        </li>
      </ul>

      <p class="account-version" *ngIf="version">
        <small>{{ version }}</small>
      </p>
    </div>
  </div>
</div>
