import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IControloIVAEntreEmpresas} from './controloIVAEntreEmpresas.module.interface';

@Injectable({
  providedIn: 'root'
})
export class ControloIVAEntreEmpresasService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/controloivaentreempresas`;
  }

  public validaCopiaControlosIVA(listaEmpresas: string, listaControlosIVA: string): TServiceResponse<IControloIVAEntreEmpresas> {
    return this._apiService.get<IControloIVAEntreEmpresas>({
      url: `${this._path}/validacopia`,
      params: {
        listaempresas: listaEmpresas,
        listacontrolosiva: listaControlosIVA
      }
    });
  }

  public efetuarCopia(listaEmpresas: string, listaControlosIVA: string, listaCopiaControlos: IControloIVAEntreEmpresas): TServiceResponse<void> {
    return this._apiService.put({
      url: `${this._path}/efetuarcopia`,
      body: listaCopiaControlos,
      params: {
        listaempresas: listaEmpresas,
        listacontrolosiva: listaControlosIVA
      }
    });
  }
}
