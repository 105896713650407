<div class="controlo-iva-entre-empresas">
  <pl-nav-wizard [definition]="definitionNavWizard" [beforeStepChange]="fnBeforeStepChange" [destroyOnHide]="false">
    <pl-nav-wizard-step [caption]="'controlosIvaEntreEmpresas.navwizard.controlosIva'" icon="fa-th-list" [hidden]="!showSelectorControlos" [validator]="fnValidatorStepControlosIVAValidations">
      <div *plNavWizardStepContent>
        <pl-form [properties]="{validators: {required: {value: true}}}">
          <pl-multiselect [model]="controlosIVA" [source]="controlosIVASource" [template]="controlosIVATemplate" key="codControloIVA"></pl-multiselect>
        </pl-form>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [caption]="'controlosIvaEntreEmpresas.navwizard.empresas'" icon="fa-building" [validator]="fnValidatorStepEmpresasValidations">
      <div *plNavWizardStepContent>
        <pl-form [properties]="{validators: {required: {value: true}}}">
          <pl-multiselect [model]="empresas" [source]="empresasSource" [template]="empresasTemplate" key="nEmpresa"></pl-multiselect>
        </pl-form>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [caption]="'controlosIvaEntreEmpresas.navwizard.erros'" icon="fa-exclamation-triangle">
      <div *plNavWizardStepContent>
        <pl-form [properties]="{validators: {required: {value: true}}}">
          <dx-data-grid [cgDxDataGrid]="dataGridDefinition" [dataSource]="copiaControlos.controloIVAEntreEmpresasList" (onInitialized)="onInitialized($event)" (onContentReady)="onContentReady()">
          </dx-data-grid>
        </pl-form>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [caption]="'controlosIvaEntreEmpresas.navwizard.final'" icon="fa-check" [properties]="{hideFinalize: true}">
      <div *plNavWizardStepContent>
        <pl-form [properties]="{validators: {required: {value: true}}}">
          <span [translate]="'controlosIvaEntreEmpresas.success'"></span>
        </pl-form>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
