export enum ETipoRegime {
  Empregados,
  GerenteMOE,
  PrimeiroEmprego,
  Pensionistas,
  Deficientes,
  TrabEntSFimLuc,
  TrabOutros,
  PraticantesDesportivos,
  ServicoDomesticoConvencional,
  ServicoDomesticoReal
}

export interface IJsonClasseCaixa {
  nclcx: string;
  designacaoBreve: string;
  designacaoCompleta: string;
  naoProcSubNatal: boolean;
  naoProcSubFerias: boolean;
  tipoRegime: ETipoRegime;
  ncrss: number;
  nomeCRSS: string;
  percentPATR: number;
  percentEMPR: number;
  codIntegracao: string;
  naoVaiSegSocial: boolean;
  temLimiteMaximo: boolean;
  limMaxNSalariosMin: number;
  temLimiteMinimo: boolean;
  limMinNSalariosMin: number;
  descricao: string;
}
