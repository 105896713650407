export type TFindContaMovimentoAction = 'previous' | 'next';

export interface IConcilMovAbNContaEmSerie {
  nContaDe: string;
  nContaAte: string;
  periodoAte: string;
}

export interface IConcilMovAbTotaisData {
  name: string;
  debit: number;
  credit: number;
  saldo: number;
}

export const MODULE_NAME_CONCIL_MOV_AB = 'concilmovab';
