import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_MODELO22_TIPO_IDENT_MATRICIAL} from '../modelo22.datasource.interface';

export const DATA_SOURCE_MODELO22_TIPO_IDENT_MATRICIAL: IDataSource<string> = deepFreeze({
  name: DATA_SOURCE_NAME_MODELO22_TIPO_IDENT_MATRICIAL,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{value}} - {{name}}',
    output: '{{value}} - {{name}}'
  },
  data: [
    {value: 'U', name: 'modelo22.datasources.tipoidentmatricial.u'},
    {value: 'R', name: 'modelo22.datasources.tipoidentmatricial.r'},
    {value: 'O', name: 'modelo22.datasources.tipoidentmatricial.o'}
  ]
});
