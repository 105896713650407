import moment from 'moment';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {isDefined} from 'pl-comps-angular';
import {ApiService} from '../../../../services/api/api.service';
import {buildSessionUrlWithParams} from '../../../../../common/utils/utils';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocContabilidade} from '../../docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IConciliacaoEFaturasConnectDocument, IConciliacaoEFaturasFilterTipo, IVerificaEFaturaSource} from '../conciliacaoEFaturas.module.interface';
import {IEFaturaDocDetails, IEFaturaTipoFatura} from '../../importadorefatura/service/importadorEfatura.service.interface';
import {IJsonEfaturaConfig} from '../../../../interfaces/jsonImportadorEfatura.interface';
import {TDate} from '../../../../../common/dates';
import {TServiceResponse} from '../../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ConciliacaoEFaturasService {
  private readonly _path: string;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this._path = `${this._apiService.path.restapi}/verificaEFaturas`;
  }

  public getAtList(deData: TDate, ateData: TDate, tipo?: IConciliacaoEFaturasFilterTipo, nifComerciante?: string): TServiceResponse<IVerificaEFaturaSource> {
    const params = {
      deData: deData,
      ateData: ateData,
      tipo: undefined,
      nifComerciante: undefined
    };

    if (isDefined(tipo)) {
      params.tipo = tipo.id;
    }

    if (isDefined(nifComerciante) && nifComerciante.length) {
      params.nifComerciante = nifComerciante;
    }

    return this._apiService.get<IVerificaEFaturaSource>({url: this._path, params: params, reportExceptions: false});
  }

  public getAtTiposList(): TServiceResponse<Array<IEFaturaTipoFatura>> {
    return this._apiService.get<Array<IEFaturaTipoFatura>>({url: `${this._path}/tiposList`});
  }

  public getConfigs(): TServiceResponse<IJsonEfaturaConfig> {
    return this._apiService.get<IJsonEfaturaConfig>({url: `${this._path}/config`});
  }

  public getDocDetails(ndoc: string, dataEmissaoDocumento: TDate): TServiceResponse<IEFaturaDocDetails> {
    return this._apiService.get<IEFaturaDocDetails>({
      url: `${this._path}/details/${ndoc}`,
      params: {dataEmissaoDocumento: moment(dataEmissaoDocumento).format('YYYY-MM-DD')}
    });
  }

  public getExtratoXlsUrl(deData: TDate, ateData: TDate, tipo: IConciliacaoEFaturasFilterTipo, nifComerciante: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/exportToXls`, {
      deData: deData,
      ateData: ateData,
      tipo: tipo.id,
      nifComerciante: nifComerciante
    });
  }

  public exportToXls(deData: TDate, ateData: TDate, tipo?: IConciliacaoEFaturasFilterTipo, nifComerciante?: string): TServiceResponse<unknown> {
    const params = {
      deData: deData,
      ateData: ateData,
      tipo: undefined,
      nifComerciante: undefined
    };

    if (isDefined(tipo)) {
      params.tipo = tipo.id;
    }

    if (isDefined(nifComerciante) && nifComerciante.length) {
      params.nifComerciante = nifComerciante;
    }

    return this._apiService.get<unknown>({
      url: `${this._path}/exportToXls`,
      params: params
    });
  }

  public getDocDetailsContabilidade(extPocCabID: string, ano?: number): Promise<IDocContabilidade> {
    const contabEnt = this._entityServiceBuilder.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    return new Promise((resolve) => {
      this._apiService
        .get<IDocContabilidade>({
          url: `${this._apiService.path.restapi}/${contabEnt.entity.name}/${extPocCabID}`,
          params: {ano: ano || -1}
        })
        .then((response) => {
          resolve(response.body);
        });
    });
  }

  public associar(periodo: string, nDiario: number, nDocInterno: string, nDocExterno: string, nif: string, nDocumento: string): TServiceResponse<void> {
    return this._apiService.put<void>({
      url: `${
        this._path
      }/associar?nifEmitente=${nif}&numerodocumento=${nDocumento}&periodo=${periodo}&nDiario=${nDiario.toString()}&nDocInterno=${nDocInterno.trim()}&nDocExterno=${nDocExterno.trim()}`
    });
  }

  public associarSemAlterarDocExterno(nifEmitente: string, numerodocumento: string, periodo: string, nDiario: number, nDocInterno: string, ano: number): TServiceResponse<void> {
    return this._apiService.put<void>({
      url: `${
        this._path
      }/associarDocSemAlterarNDocExt?nifEmitente=${nifEmitente}&numerodocumento=${numerodocumento}&periodo=${periodo}&nDiario=${nDiario.toString()}&nDocInterno=${nDocInterno.trim()} &ano=${ano.toString()}`
    });
  }

  public ignorarDocumento(nifEmitente: string, numerodocumento: string): TServiceResponse<void> {
    return this._apiService.put<void>({
      url: `${this._path}/ignorarDoc?nifEmitente=${nifEmitente}&numerodocumento=${numerodocumento}`
    });
  }

  public ignorarSempreFornecedor(nifEmitente: string, nome: string): TServiceResponse<void> {
    return this._apiService.put<void>({
      url: `${this._path}/ignorarFornecedor?nifEmitente=${nifEmitente}&nome=${nome}`
    });
  }

  public removeAssociacao(nifEmitente: string, numerodocumento: string): TServiceResponse<unknown> {
    return this._apiService.delete({url: `${this._path}/associar?nifEmitente=${nifEmitente}&numerodocumento=${numerodocumento}`});
  }

  public deixarIgnorarDoc(nifEmitente: string, numerodocumento: string): TServiceResponse<unknown> {
    return this._apiService.delete({url: `${this._path}/ignorarDoc?nifEmitente=${nifEmitente}&numerodocumento=${numerodocumento}`});
  }

  public deixarIgnorarFornecedor(nifEmitente: string): TServiceResponse<unknown> {
    return this._apiService.delete({url: `${this._path}/ignorarFornecedor?nifEmitente=${nifEmitente}`});
  }

  public obterContaDoNif(nif: string): TServiceResponse<string> {
    return this._apiService.get<string>({url: `${this._path}/obterContaDoNIF/${nif}`});
  }

  public associarTodosDocComUmDocPossivel(list: Array<IConciliacaoEFaturasConnectDocument>): TServiceResponse<void> {
    return this._apiService.put<void, Array<IConciliacaoEFaturasConnectDocument>>({
      url: `${this._path}/associarTodosPossiveis`,
      body: list
    });
  }

  public saveConfigs(config: IJsonEfaturaConfig): TServiceResponse<void> {
    return this._apiService.put<void, IJsonEfaturaConfig>({url: `${this._path}/config`, body: config});
  }
}
