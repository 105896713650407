import {ENTITY_NAME_ID_BANCOS} from './idBancos.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonIDBancos} from './jsonIDBancos.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_ID_BANCOS: IEntityDefinition<IJsonIDBancos> = {
  name: ENTITY_NAME_ID_BANCOS,
  roles: [ROLE.RH],
  searchPlaceholder: 'idbancos.pesquisa',
  pageTitle: 'global.menu.idbancos',
  metadata: {
    keyName: 'idinterBancario',
    fields: [
      {name: 'idinterBancario', caption: 'idbancos.fields.idinterBancario', placeholder: 'idbancos.fields.idinterBancario', validators: {required: true, maxlength: 5}},
      {name: 'nomeInterBancario', caption: 'idbancos.fields.nomeInterBancario', placeholder: 'idbancos.fields.nomeInterBancario', validators: {required: true}},
      {name: 'descricao', caption: 'idbancos.fields.descricao', placeholder: 'idbancos.fields.descricao', validators: {required: true}},
      {name: 'formatoTRFAG', caption: 'idbancos.fields.formatoTRFAG', placeholder: 'idbancos.fields.formatoTRFAG', validators: {required: true}},
      {name: 'formatoTRFREC', caption: 'idbancos.fields.formatoTRFREC', placeholder: 'idbancos.fields.formatoTRFREC'},
      {name: 'inativo', type: 'boolean', caption: 'idbancos.fields.inativo', placeholder: 'idbancos.fields.inativo', properties: {trueValue: 1, falseValue: 0}}
    ],
    order: 'idinterBancario',
    searchFields: 'idinterBancario,nomeInterBancario,descricao,formatoTRFAG,formatoTRFREC'
  },
  autocomplete: {
    rowTemplate: '{{idinterBancario}} - {{nomeInterBancario}}',
    output: '{{idinterBancario}} - {{nomeInterBancario}}',
    outputDescription: 'nomeInterBancario',
    searchFields: 'idinterBancario,nomeInterBancario'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  }
};
