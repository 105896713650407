import {firstValueFrom, of} from 'rxjs';
import {isEmpty, isFile} from 'pl-comps-angular';
import {ENTITY_NAME_PRH_DOCS_PLATA, IPRHDocsPlataEntity} from './pRHDocsPlata.entity.interface';
import {IJsonPRHDocsPlata} from './jsonPRHDocsPlata.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_PRH_DOCS_PLATA: IPRHDocsPlataEntity = {
  name: ENTITY_NAME_PRH_DOCS_PLATA,
  icon: 'fa-exchange',
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  metadata: {
    keyName: 'codPrhDocsPlata',
    descriptionName: 'titulo',
    fields: [
      {
        name: 'codPrhDocsPlata',
        width: '120px',
        caption: 'prhdocsplata.fields.cab.codprhdocsplata',
        placeholder: 'prhdocsplata.fields.cab.codprhdocsplataPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'titulo',
        caption: 'prhdocsplata.fields.cab.titulo',
        placeholder: 'prhdocsplata.fields.cab.tituloPlaceholder',
        validators: {required: true, maxlength: 100}
      }
    ],
    order: 'titulo',
    searchFields: 'codPrhDocsPlata,titulo'
  },
  autocomplete: {
    rowTemplate: '{{codPrhDocsPlata}} - {{.titulo}}',
    output: 'titulo',
    searchFields: 'codPrhDocsPlata,titulo'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: false
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.prhdocsplata'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.prhdocsplata'
      }
    }
  },
  service: function () {
    this.uploadDocUrl = (codPrhDocsPlata: number) => {
      return of(`${this.entityUrl()}/${codPrhDocsPlata}/uploaddoc`);
    };

    this.uploadDoc = (codPrhDocsPlata: number, file: Blob | File, params?: string, filename?: string) => {
      const body: FormData = new FormData();
      if (!isEmpty(params)) {
        body.append('data', params);
      }
      filename = !isEmpty(filename) ? filename : isFile(file) ? file.name : undefined;
      body.append('file', file, filename);
      return this.post<IJsonPRHDocsPlata, FormData>({url: `${codPrhDocsPlata}/uploaddoc`, body: body});
    };

    this.downloadDocUrl = (codPrhDocsPlata: number) => {
      return of(`${this.entityUrl()}/${codPrhDocsPlata}/downloaddoc`);
    };

    this.downloadDoc = (codPrhDocsPlata: number) => {
      return firstValueFrom(this.downloadDocUrl(codPrhDocsPlata)).then((url: string) => {
        return this.get<Blob>({url: url, responseType: 'blob'});
      });
    };
  }
};
