import moment from 'moment';
import {merge} from 'lodash-es';
import {firstValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {IPlEditComponentOptionsInputNumber, KEYCODES, PlAlertService, timeout} from 'pl-comps-angular';
import {BancosExtratoService} from '../../service/bancosExtrato.module.service';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {DATA_SOURCE_NAME_DEBITO_CREDITO} from '../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {ECGBankingDataRegistoRecebimentosPagamentos, IJsonBancosExtratoConfigs, IJsonBancosExtratoSaveRecPagResult} from '../../jsonBancosExtrato.module.interface';
import {entidadeBancariaRecPagObterTotalValorAReter, IJsonEntidadeBancariaRecPag, IJsonEntidadeBancariaTransaction} from '../../../../interfaces/jsonEntidadeBancaria.interface';
import {ENTITY_NAME_CLIENTES, ENTITY_NAME_FORNECEDORES} from '../../../../entities/clifos/clifos.entity.interface';
import {EStatusCode} from '../../../../../config/constants';
import {focusElement} from '../../../../../common/utils/element.utils';
import {IBancosExtratoModuleMovabModalResult} from './bancosExtrato.movab.modal.component.interface';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IEntityAutocompleteOptions} from '../../../../components/entity/entity.autocomplete.definition.interface';
import {IJsonClifo} from '../../../../entities/clifos/jsonClifo.entity.interface';
import {numberBiggerThan, numberEquals, round} from '../../../../../common/utils/utils';
import {TDate} from '../../../../../common/dates';

const DATA_GRID_INSTANCE_NAME = 'bancosextratomodulemovabmodal';
const DATA_GRID_FIELD_VALOR_A_RECEBER = 'valorAReceber';
const DATA_GRID_FIELD_VALOR_DESCONTO = 'valorDesconto';

@Component({
  selector: 'bancos-extrato-module-movab-modal',
  templateUrl: './bancosExtrato.modal.component.html'
})
export class BancosExtratoMovabModalComponent extends CGModalComponent<IBancosExtratoModuleMovabModalResult> implements OnInit {
  @Input() public transaction: IJsonEntidadeBancariaTransaction;
  @Input() public receipt: boolean;

  public readonly dataGrid: IDevExpressDataGrid;
  public readonly dataGridInstanceName: string;
  public readonly outputConta: string;
  public readonly propertiesConta: IEntityAutocompleteOptions;
  public readonly propertiesValorAReceber: Map<string, IPlEditComponentOptionsInputNumber>;
  public readonly propertiesValorDesconto: Map<string, IPlEditComponentOptionsInputNumber>;
  public title: string;
  public titleReceberTudo: string;
  public labelNConta: string;
  public entityNameClifos: string;
  public conta: Partial<IJsonClifo>;
  public valorExtrato: number;
  public valorSelecionado: number;
  public dateRecPag: TDate;

  private readonly _dataGridColumnValorAReceber: IDevExpressDataGridColumn;
  private _dataGridInstance: dxDataGrid;
  private _recPagList: Array<IJsonEntidadeBancariaRecPag>;
  private _promiseSaveEditData: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _bancosExtratoService: BancosExtratoService
  ) {
    super(_injector);
    this.receipt = true;
    this._dataGridColumnValorAReceber = {
      dataField: DATA_GRID_FIELD_VALOR_A_RECEBER,
      dataType: 'double',
      caption: 'bancosextrato.movabmodal.fields.valorAReceber',
      editCellTemplate: 'dataGridTemplateEditCellValorAReceber',
      showEditorAlways: true,
      minWidth: 150
    };
    this.dataGrid = {
      columns: [
        {dataField: 'extPocCabID', dataType: 'string', visible: false, showInColumnChooser: false, allowEditing: false},
        {dataField: 'nDocumento', dataType: 'string', caption: 'bancosextrato.movabmodal.fields.nDocumento', allowEditing: false},
        {dataField: 'descricao', dataType: 'string', caption: 'bancosextrato.movabmodal.fields.descricao', allowEditing: false},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'bancosextrato.movabmodal.fields.nDocExterno', allowEditing: false},
        {dataField: 'dataDoc', dataType: 'date', caption: 'bancosextrato.movabmodal.fields.dataDoc', allowEditing: false},
        {dataField: 'dataVencimento', dataType: 'date', caption: 'bancosextrato.movabmodal.fields.dataVencimento', allowEditing: false, sortOrder: 'asc'},
        {dataField: 'dc', dataType: 'number', caption: 'bancosextrato.movabmodal.fields.dc', allowEditing: false, lookup: {cgDataSource: DATA_SOURCE_NAME_DEBITO_CREDITO}},
        {dataField: 'valor', dataType: 'double', caption: 'bancosextrato.movabmodal.fields.valor', allowEditing: false},
        {dataField: 'valorPago', dataType: 'double', caption: 'bancosextrato.movabmodal.fields.valorPago', allowEditing: false},
        {dataField: 'valorPorPagar', dataType: 'double', caption: 'bancosextrato.movabmodal.fields.valorPorPagar', allowEditing: false},
        this._dataGridColumnValorAReceber,
        {
          dataField: DATA_GRID_FIELD_VALOR_DESCONTO,
          dataType: 'double',
          caption: 'bancosextrato.movabmodal.fields.valorDesconto',
          editCellTemplate: 'dataGridTemplateEditCellValorDesconto',
          showEditorAlways: true
        }
      ],
      dataSource: new CustomStore<IJsonEntidadeBancariaRecPag, string>({
        key: 'extPocCabID',
        load: this._onLoadDataGrid.bind(this),
        update: (extPocCabID: string, values: Partial<IJsonEntidadeBancariaRecPag>) => {
          const recPag: IJsonEntidadeBancariaRecPag = this._recPagList.find((item: IJsonEntidadeBancariaRecPag) => item.extPocCabID === extPocCabID);
          if (recPag) {
            merge(recPag, values);
          }
          return Promise.resolve();
        }
      }),
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      editing: {
        allowAdding: false,
        allowDeleting: false,
        allowUpdating: true,
        mode: 'cell',
        refreshMode: 'repaint',
        selectTextOnEditStart: true
      },
      export: {enabled: false},
      filterRow: {visible: false},
      groupPanel: {visible: false},
      headerFilter: {visible: false},
      pager: {visible: false},
      paging: {enabled: false},
      summary: {
        totalItems: [
          {column: 'valor', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorPago', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorPorPagar', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorAReceber', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorDesconto', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      }
    };
    this.dataGridInstanceName = DATA_GRID_INSTANCE_NAME;
    this.outputConta = '{{nConta}} - {{nome}}';
    this.propertiesConta = {
      allowInvalid: false,
      events: {
        keydown: (value: string, event: KeyboardEvent) => {
          if (event.key === KEYCODES.ENTER) {
            event.stopPropagation();
          }
        }
      }
    };
    this.propertiesValorAReceber = new Map<string, IPlEditComponentOptionsInputNumber>();
    this.propertiesValorDesconto = new Map<string, IPlEditComponentOptionsInputNumber>();
    this.valorExtrato = 0;
    this.valorSelecionado = 0;
    this._recPagList = [];
  }

  public ngOnInit(): void {
    if (this.transaction.nContaCorrente) {
      this.conta = {
        nConta: this.transaction.nContaCorrente,
        nome: this.transaction.nomeContaCorrente
      };
    } else {
      this._bancosExtratoService
        .suggestRecPagNConta(this.transaction.description, this.transaction.dc, {reportExceptions: false})
        .then((response: HttpResponse<IJsonClifo>) => {
          this.changedConta(response.body);
        })
        .catch((reason: unknown) => {
          if (reason instanceof HttpErrorResponse && reason.status !== EStatusCode.NotFound) {
            const exception: ICGExceptionError = this._cgExceptionService.get(reason);
            this._plAlertService.error(exception.message);
          }
        });
    }

    this.valorExtrato = Math.abs(this.transaction.amount);

    firstValueFrom(this._bancosExtratoService.configs().pipe(take(1))).then((configs: IJsonBancosExtratoConfigs) => {
      this.dateRecPag = configs.dataRegistoRecebimentosPagamentos === ECGBankingDataRegistoRecebimentosPagamentos.DataAtual ? moment() : this.transaction.date;
    });

    if (this.receipt) {
      this.title = 'bancosextrato.movabmodal.titleReceipt';
      this.titleReceberTudo = 'bancosextrato.movabmodal.text.receberTudoReceipt';
      this.labelNConta = 'bancosextrato.movabmodal.fields.nContaClient';
      this.entityNameClifos = ENTITY_NAME_CLIENTES;
    } else {
      this.title = 'bancosextrato.movabmodal.titlePayment';
      this.titleReceberTudo = 'bancosextrato.movabmodal.text.receberTudoPayment';
      this.labelNConta = 'bancosextrato.movabmodal.fields.nContaSupplier';
      this.entityNameClifos = ENTITY_NAME_FORNECEDORES;
      this._dataGridColumnValorAReceber.caption = 'bancosextrato.movabmodal.fields.valorAPagar';
    }
  }

  public close(): Promise<void> {
    const totalValorAReter: number = entidadeBancariaRecPagObterTotalValorAReter(this._recPagList, this.transaction.isRecebimento, this.precision);
    if (!numberEquals(this.valorExtrato, totalValorAReter, this.precision)) {
      this._plAlertService.error(
        this._translateService.instant('bancosextrato.movabmodal.errors.recPagAmmountNoMatch', {
          valorSelecionado: totalValorAReter,
          amount: this.valorExtrato
        })
      );
      return Promise.resolve();
    }
    this.disableClose();
    return this._bancosExtratoService
      .saveRecPag({
        transaction: this.transaction,
        recPagList: this._recPagList,
        isRecebimento: this.receipt,
        dateRecPag: this.dateRecPag
      })
      .then((response: HttpResponse<IJsonBancosExtratoSaveRecPagResult>) => {
        this.enableClose();
        this._plAlertService.success(this.receipt ? 'bancosextrato.movabmodal.text.successReceipt' : 'bancosextrato.movabmodal.text.successPayment');
        super.close({
          extPocCabID: response.body.extPocCabID,
          estado: response.body.estado,
          nConta: this.conta.nConta,
          nomeConta: this.conta.nome,
          email: this.conta.email
        });
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }

  public changedConta(value: IJsonClifo): void {
    this.conta = value;
    this._dataGridInstance.refresh().then(() => {
      this._dataGridDoNextCell(0);
    });
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onDataGridCellClick({component, column, rowIndex, rowType}: IDevExpressDataGridEventOnCellClick<IJsonEntidadeBancariaRecPag>): void {
    if (rowType !== 'data' || column.type || column.dataField === DATA_GRID_FIELD_VALOR_DESCONTO) {
      return;
    }
    component.focus(component.getCellElement(rowIndex, DATA_GRID_FIELD_VALOR_A_RECEBER));
  }

  public onDataGridSaved(): void {
    this._refreshValorSelecionado();
  }

  public changedValorAReceber(value: number, rowIndex: number, doNextCell: boolean = true): void {
    this._dataGridInstance.cellValue(rowIndex, DATA_GRID_FIELD_VALOR_A_RECEBER, value);
    this._dataGridSaveEditData().then(() => {
      if (doNextCell) {
        this._dataGridDoNextCell(rowIndex + 1);
      }
    });
  }

  public changedValorDesconto(value: number, rowIndex: number, doNextCell: boolean = true): void {
    this._dataGridInstance.cellValue(rowIndex, DATA_GRID_FIELD_VALOR_DESCONTO, value);
    this._dataGridSaveEditData().then(() => {
      if (doNextCell) {
        this._dataGridDoNextCell(rowIndex + 1);
      }
    });
  }

  public receberTudoLinha(recPag: IJsonEntidadeBancariaRecPag, doNextCell: boolean = false): void {
    const totalValorAReter: number = this._obterTotalValorAReter();
    const transactionAmount: number = this.valorExtrato;
    const valorAReceber: number = numberBiggerThan(totalValorAReter + recPag.valorPorPagar, transactionAmount) ? round(transactionAmount - totalValorAReter, this.precision) : recPag.valorPorPagar;
    const rowIndex = this._dataGridInstance.getRowIndexByKey(recPag.extPocCabID);
    this.changedValorAReceber(valorAReceber, rowIndex, doNextCell);
    if (!doNextCell) {
      this._dataGridFocusCell(rowIndex, DATA_GRID_FIELD_VALOR_A_RECEBER);
    }
  }

  public limparLinha(rowIndex: number): void {
    this.changedValorAReceber(0, rowIndex, false);
    this._dataGridFocusCell(rowIndex, DATA_GRID_FIELD_VALOR_A_RECEBER);
  }

  public get precision(): number {
    return this.configurations.contabilidade.decimais.valor;
  }

  private _onLoadDataGrid(): Promise<Array<IJsonEntidadeBancariaRecPag>> {
    if (!this.conta?.nConta) {
      this._recPagList = [];
      this.propertiesValorAReceber.clear();
      this.propertiesValorDesconto.clear();
      this._refreshValorSelecionado();
      return Promise.resolve(this._recPagList);
    }
    return this._bancosExtratoService.getRecPagList(this.conta.nConta).then((response: HttpResponse<Array<IJsonEntidadeBancariaRecPag>>) => {
      this._recPagList = response.body;
      this.propertiesValorAReceber.clear();
      this.propertiesValorDesconto.clear();
      for (const recPag of this._recPagList) {
        this.propertiesValorAReceber.set(recPag.extPocCabID, {
          disallowClear: true,
          modelOptions: {updateOn: 'blur'},
          events: {
            keydown: (value: number, event: KeyboardEvent) => {
              this._onKeydownValorAReceber(recPag, event);
            }
          }
        });
        this.propertiesValorDesconto.set(recPag.extPocCabID, {
          disallowClear: true,
          modelOptions: {updateOn: 'blur'},
          events: {
            keydown: (value: number, event: KeyboardEvent) => {
              this._onKeydownDesconto(event);
            }
          }
        });
      }
      this._refreshValorSelecionado();
      return this._recPagList;
    });
  }

  private _dataGridSaveEditData(): Promise<void> {
    this._promiseSaveEditData = Promise.resolve(this._promiseSaveEditData)
      .then(() => this._dataGridInstance.saveEditData())
      .then(() => timeout());
    return this._promiseSaveEditData;
  }

  private _dataGridDoNextCell(rowIndex: number): void {
    if (rowIndex < this._recPagList.length) {
      this._dataGridFocusCell(rowIndex, DATA_GRID_FIELD_VALOR_A_RECEBER);
      return;
    }
    timeout().then(() => {
      const btnSubmit: HTMLButtonElement = this._element.querySelector<HTMLButtonElement>('button.action-submit');
      focusElement(btnSubmit);
    });
  }

  private _dataGridFocusCell(rowIndex: number, dataField: string): void {
    const cellElement: HTMLElement = this._dataGridInstance.getCellElement(rowIndex, dataField);
    this._dataGridInstance.focus(cellElement);
  }

  private _refreshValorSelecionado(): void {
    this.valorSelecionado = this._obterTotalValorAReter();
  }

  private _obterTotalValorAReter(): number {
    return entidadeBancariaRecPagObterTotalValorAReter(this._recPagList, this.transaction.isRecebimento, this.precision);
  }

  private _onKeydownValorAReceber(recPag: IJsonEntidadeBancariaRecPag, event: KeyboardEvent): void {
    const element: HTMLInputElement = <HTMLInputElement>event.target;
    switch (event.key) {
      case KEYCODES.ADD:
      case KEYCODES.F9:
        event.preventDefault();
        this.receberTudoLinha(recPag, true);
        break;
      case KEYCODES.ENTER:
        event.preventDefault();
        element.blur();
        break;
    }
  }

  private _onKeydownDesconto(event: KeyboardEvent): void {
    const element: HTMLInputElement = <HTMLInputElement>event.target;
    switch (event.key) {
      case KEYCODES.ENTER:
        event.preventDefault();
        element.blur();
        break;
    }
  }
}
