import {Injectable, Injector} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGModalService} from '../../components/cg/modal/cgmodal.service';
import {ContabilidadeDigitalActivateLicenseModalComponent} from '../../components/arquivodigital/modals/activatelicense/contabilidadedigital.activatelicensemodal.component';
import {ContabilidadeDigitalCheckFoldersCreatedModalComponent} from '../../components/arquivodigital/modals/checkfolderscreated/contabilidadedigital.checkfolderscreated.modal.component';
import {ContabilidadeDigitalService} from './contabilidadedigital.service';
import {EContabilidadeDigitalActivateLicenseType} from '../../components/arquivodigital/modals/activatelicense/contabilidadedigital.activatelicensemodal.component.interface';
import {IContabilidadeDigitalGenericViewerFolder, IContabilidadeDigitalGenericViewerFolderPath} from '../../components/arquivodigital/common/viewers/contabilidadedigital.genericviewer.interface';
import {HttpResponse} from '@angular/common/http';
import {IJsonContabDigitalDadosLicenca} from './jsonContabDigital.interface';

@Injectable({
  providedIn: 'root'
})
export class ContabilidadeDigitalUIService extends ContabilidadeDigitalService {
  protected readonly _plAlertService: PlAlertService;
  protected readonly _plTranslateService: PlTranslateService;
  protected readonly _cgModalService: CGModalService;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._plAlertService = this._injector.get<PlAlertService>(PlAlertService);
    this._plTranslateService = this._injector.get<PlTranslateService>(PlTranslateService);
    this._cgModalService = this._injector.get<CGModalService>(CGModalService);
  }

  public consumeLicense(type: EContabilidadeDigitalActivateLicenseType): Promise<void> {
    if (this._modoCGOn) {
      const message: string = this._plTranslateService.translate(`arquivodigital.activatelicensemodal.types.${type}.errorCGOnMode`);
      this._plAlertService.error(message);
      return Promise.reject(new Error(message));
    }
    return (type === EContabilidadeDigitalActivateLicenseType.ClientConnect ? this.getDadosLicencaClientConnect() : this.getDadosLicenca()).then(
      (response: HttpResponse<IJsonContabDigitalDadosLicenca>) => {
        const dadosLicenca: IJsonContabDigitalDadosLicenca = response.body;
        const modalInstance: NgbModalRef = this._cgModalService.showVanilla(ContabilidadeDigitalActivateLicenseModalComponent, {size: 'lg'});
        const componentInstance: ContabilidadeDigitalActivateLicenseModalComponent = modalInstance.componentInstance;
        componentInstance.type = type;
        componentInstance.dadosLicenca = dadosLicenca;
        return modalInstance.result;
      }
    );
  }

  public checkConsumeLicense(type: EContabilidadeDigitalActivateLicenseType): Promise<boolean> {
    if (type === EContabilidadeDigitalActivateLicenseType.ContabilidadeDigital && this._temContabilidadeDigital) {
      return Promise.resolve(true);
    }
    if (type === EContabilidadeDigitalActivateLicenseType.ClientConnect && this._temContabilidadeDigitalClientConnect) {
      return Promise.resolve(true);
    }
    return this.consumeLicense(type)
      .then(() => true)
      .catch((reason: unknown) => {
        this._logger.error(reason);
        return false;
      });
  }

  public checkFoldersCreated(): Promise<void> {
    return this._cgModalService.show(ContabilidadeDigitalCheckFoldersCreatedModalComponent, {size: 'md'});
  }

  public getFoldersPath(selectedFolder: IContabilidadeDigitalGenericViewerFolder): Array<IContabilidadeDigitalGenericViewerFolderPath> {
    function getFolderPathRecursively(folder: IContabilidadeDigitalGenericViewerFolder, result: Array<IContabilidadeDigitalGenericViewerFolderPath>): void {
      if (!folder?.folderID) {
        return;
      }
      result.push({name: folder.name, folderID: folder.folderID});
      getFolderPathRecursively(folder.parentFolder, result);
    }

    const path: Array<IContabilidadeDigitalGenericViewerFolderPath> = [];
    getFolderPathRecursively(selectedFolder, path);
    return path.reverse();
  }

  public getFoldersNamePath(selectedFolder: IContabilidadeDigitalGenericViewerFolder): Array<string> {
    return this.getFoldersPath(selectedFolder).map((folderPath: IContabilidadeDigitalGenericViewerFolderPath) => folderPath.name);
  }
}
