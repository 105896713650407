import {ESolicTipoTexto, IJsonSOLIC} from './jsonSolic.entity.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IEntityDefinition, IEntityDetailStateParams} from '../../components/entity/entity.definition.interface';
import {IEntityService, TEntityServiceRequestData, TEntityServiceRequestDataWithBody} from '../../services/entity/entity.service.interface';

export type TSOLICRequestData = TEntityServiceRequestData & ISOLICRequest;

export type TSOLICRequestDataWithBody<T = void> = TEntityServiceRequestDataWithBody<T> & {tipotexto: ESolicTipoTexto};

export interface ISOLICEntityService extends IEntityService<IJsonSOLIC> {
  get<TResponse = IJsonSOLIC>(config: TSOLICRequestData): TServiceResponse<TResponse>;

  delete<TResponse = void, TRequest = TResponse>(config?: TSOLICRequestDataWithBody<TRequest>): TServiceResponse<TResponse>;
}

export interface ISOLICEntity extends IEntityDefinition<IJsonSOLIC, ISOLICEntityService> {}

export interface ISOLICRequest {
  tipotexto: ESolicTipoTexto;
}

export interface ISOLICDetailStateParams extends IEntityDetailStateParams {
  id: string;
  tipotexto?: ESolicTipoTexto;
}

export const ENTITY_NAME_SOLIC = 'solic';
