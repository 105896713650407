import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {IJsonGDocEmailConfig} from '../modules/configs/assistenteconfigportais/jsonGdocEmailConfig.module.interface';
import {TServiceResponse} from './api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class EmailsService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/emails`;
  }

  public getConfiguracaoEnvioEmail(nempresa: string): TServiceResponse<IJsonGDocEmailConfig> {
    return this._apiService.get<IJsonGDocEmailConfig>({url: `${this._path}/configenvioemailempresa`, params: {nempresa: nempresa}});
  }
}
