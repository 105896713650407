import {Component, Injector, Input, OnInit} from '@angular/core';
import {EDateMonth, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EmpresasService} from '../../../../services/empresas/empresas.service';
import {IJsonPerioMudaPrimeiroMesContab} from '../../../../entities/empresas/empresas.entity.interface';

@Component({
  selector: 'empresas-periocontabfirstmonth-modal',
  templateUrl: './empresas.perioContabFirstMonth.modal.component.html'
})
export class EmpresasPerioContabFirstMonthModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public perioMudaPrimeiroMesContab: IJsonPerioMudaPrimeiroMesContab;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _empresasService: EmpresasService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this.perioMudaPrimeiroMesContab.primeiroMesContab = this.perioMudaPrimeiroMesContab.primeiroMesContab !== -1 ? this.perioMudaPrimeiroMesContab.primeiroMesContab : EDateMonth.January;
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._empresasService
      .postPerioMudaPrimeiroMesContab(this.perioMudaPrimeiroMesContab)
      .then(() => {
        this._plAlertService.success('empresas.messages.changedperiocontabfirstmonth');
        super.close();
      })
      .finally(() => {
        this.enableClose();
      });
  }

  public mesChanged(value: EDateMonth): void {
    this.perioMudaPrimeiroMesContab.primeiroMesContab = value;
  }
}
