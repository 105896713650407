import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IPlToolbarInstance, IPlToolbarItem, isArray, isNumber, isObject, PlToolbarService} from 'pl-comps-angular';
import {AuthService} from '../../services/auth/auth.service';
import {EGestaoDGEMPSClasses, EGestaoDGEMPSType, EGestaoDGEMPSView, GESTAO_DGEMPS_DEFAULT_VIEW, IGestaoDGEMPSPRHServico, IGestaoDGEMPSToolbarLegend} from './gestaodgemps.interface';
import {ENTITY_NAME_CALENDARIOS, ICalendariosEntityService} from '../../entities/calendarios/calendarios.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {gestaoDGEMPSDefaultViewDate, gestaoDGEMPSTypeToPRHFluxo, gestaoDGEMPSViewUnit} from './gestaodgemps.utilities';
import {GestaoDGEMPSService} from './gestaodgemps.service';
import {IJsonCalendarioCab} from '../../entities/calendarios/jsonCalendarios.entity.interface';
import {IJsonConfigEstrutura, IJsonPRHEventosConfig} from '../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import {IJsonDGEMP} from '../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHFluxo} from '../../entities/prhfluxos/jsonPRHFluxo.entity.interface';
import {IJsonPRHServicoEmpregado} from '../../entities/prhservicoempregados/jsonPRHServicoEmpregado.entity.interface';
import moment, {Moment, MomentInput} from 'moment';
import {TUserSession} from '../../services/account/jsonUserApi.interface';

const TOOLBAR_GROUP_ID = 'gestao-dgemps';
const TOOLBAR_ORDER = 500;

@Component({
  selector: 'gestao-dgemps',
  templateUrl: './gestaodgemps.component.html'
})
export class GestaoDGEMPSComponent implements OnInit, OnChanges {
  @Input() public type: EGestaoDGEMPSType;
  @Input() public manager: boolean;
  @Input() public toolbarInstanceName: string;
  @Input() public configStructure?: IJsonConfigEstrutura;
  @Input() public configEvents?: Array<IJsonPRHEventosConfig>;
  @Input() public fluxos?: Array<IJsonPRHFluxo>;
  @Input() public empregado?: IJsonDGEMP;
  @Input() public holidaysDates?: Array<MomentInput>;
  @Input() public codServico?: number;
  @Input() public colaboradoresPendentes?: Array<number>;
  @Input() public forceDelete?: boolean;
  @Input() public toolbarLegendConfiguration?: Partial<IGestaoDGEMPSToolbarLegend>;

  public readonly moduleTypes: typeof EGestaoDGEMPSType;
  public readonly views: typeof EGestaoDGEMPSView;
  public view: EGestaoDGEMPSView;
  public viewDate: Moment;
  public servicos: Array<IGestaoDGEMPSPRHServico>;
  public servico: IGestaoDGEMPSPRHServico;
  public pendingPrevious: Moment;
  public pendingNext: Moment;
  public disabledPendingPrevious: boolean;
  public disabledPendingNext: boolean;
  public loading: boolean;
  public viewLoading: boolean;
  public selectedColaboradoresKeys: Array<number>;
  public promise: Promise<void>;

  private readonly _cssClasses: typeof EGestaoDGEMPSClasses;
  private readonly _toolbarLegend: IPlToolbarItem;
  private readonly _holidaysDatesCache: Map<number, Array<MomentInput>>;
  private readonly _calendariosService: ICalendariosEntityService;
  private _toolbarInstance: IPlToolbarInstance;
  private _toolbarInstanceOwned: boolean;
  private _toolbarLegendConfiguration: IGestaoDGEMPSToolbarLegend;
  private _loadingPromises: number;

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _plToolbarService: PlToolbarService,
    private readonly _authService: AuthService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _gestaoDGEMPSService: GestaoDGEMPSService
  ) {
    this.moduleTypes = EGestaoDGEMPSType;
    this.views = EGestaoDGEMPSView;
    this.view = GESTAO_DGEMPS_DEFAULT_VIEW;
    this.viewDate = gestaoDGEMPSDefaultViewDate();
    this.servicos = [];
    this.loading = false;
    this.viewLoading = false;
    this._cssClasses = EGestaoDGEMPSClasses;
    this._toolbarLegend = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'gestao-dgemps-legend',
      type: 'html',
      order: TOOLBAR_ORDER,
      align: 'right',
      caption: ''
    };
    this._holidaysDatesCache = new Map<number, Array<MomentInput>>();
    this._calendariosService = this._entityServiceBuilder.build<IJsonCalendarioCab, ICalendariosEntityService>(ENTITY_NAME_CALENDARIOS);
    this._loadingPromises = 0;
  }

  public ngOnInit(): void {
    if (isArray(this.colaboradoresPendentes) && this.colaboradoresPendentes.length) {
      this.selectedColaboradoresKeys = this.colaboradoresPendentes;
    }
    const promises: Array<Promise<unknown>> = [];
    const isFerias: boolean = this.type === EGestaoDGEMPSType.Ferias;
    if (!isObject(this.configStructure)) {
      if (!isFerias) {
        promises.push(
          this._gestaoDGEMPSService.getConfigStructure(this.type).then((configStructure: IJsonConfigEstrutura) => {
            this.configStructure = configStructure;
          })
        );
      } else {
        this.configStructure = {
          tipoEvento: EGestaoDGEMPSType.Ferias,
          listaTipo: [],
          listaTipoProcessamento: [],
          listaAbDes: []
        };
      }
    }
    if (!Array(this.configEvents)) {
      if (!isFerias) {
        promises.push(
          this._gestaoDGEMPSService.getConfigEvents(this.type).then((configEvents: Array<IJsonPRHEventosConfig>) => {
            this.configEvents = configEvents;
          })
        );
      } else {
        this.configEvents = [];
      }
    }
    if (!Array(this.fluxos)) {
      if (!isFerias) {
        promises.push(
          this._gestaoDGEMPSService.getFluxos(gestaoDGEMPSTypeToPRHFluxo(this.type)).then((fluxos: Array<IJsonPRHFluxo>) => {
            this.fluxos = fluxos;
          })
        );
      } else {
        this.fluxos = [];
      }
    }
    if (!isObject(this.empregado)) {
      promises.push(
        this._authService
          .identity()
          .then((session: TUserSession) => this._gestaoDGEMPSService.getEmpregado(session.erp.codEmp))
          .then((dgemp: IJsonDGEMP) => {
            this.empregado = dgemp;
          })
      );
    }
    if (!isArray(this.holidaysDates)) {
      promises.push(this._refreshWeekends());
    } else {
      this._holidaysDatesCache.set(this.viewDate.year(), this.holidaysDates);
    }
    if (promises.length) {
      this._loadingPromises += promises.length;
      this._evaluateLoading();
      Promise.all(promises)
        .then(() => {
          this._handleChanges();
        })
        .finally(() => {
          this._loadingPromises -= promises.length;
          this._evaluateLoading();
        });
    } else {
      this._handleChanges();
    }
  }

  public ngOnChanges({toolbarLegendConfiguration, toolbarInstanceName, type, codServico}: SimpleChanges): void {
    if (toolbarLegendConfiguration && !toolbarLegendConfiguration.isFirstChange()) {
      this._changedToolbarLegendConfiguration(toolbarLegendConfiguration.currentValue);
    }
    if (toolbarInstanceName && !toolbarInstanceName.isFirstChange()) {
      this._changedToolbarInstanceName(toolbarInstanceName.currentValue);
    }
    if (type && !type.isFirstChange()) {
      this._changedType(type.currentValue);
    }
    if (codServico && !codServico.isFirstChange()) {
      this.servico = this._getDefaultServico();
    }
  }

  public setView(value: EGestaoDGEMPSView): void {
    if (!this.manager) {
      return;
    }
    this.view = value;
    this.loading = false;
    this._changedToolbarLegendConfiguration({});
  }

  public setViewDate(value: Moment): void {
    const viewDate: Moment = moment(value);
    if (!viewDate.isSame(this.viewDate, gestaoDGEMPSViewUnit(this.view))) {
      const refreshWeekends = !viewDate.isSame(this.viewDate, 'year');
      this.viewDate = viewDate;
      if (refreshWeekends) {
        if (this._holidaysDatesCache.has(this.viewDate.year())) {
          this.holidaysDates = this._holidaysDatesCache.get(this.viewDate.year());
        } else {
          this._refreshWeekends();
        }
      }
    }
  }

  public onLoadingChange(loading: boolean): void {
    this.viewLoading = loading;
    this._evaluateLoading();
  }

  public onPendingPrevious(): void {
    if (!this.disabledPendingPrevious) {
      this.setViewDate(this.pendingPrevious);
    }
  }

  public onPendingNext(): void {
    if (!this.disabledPendingNext) {
      this.setViewDate(this.pendingNext);
    }
  }

  private _handleChanges(): void {
    this._changedToolbarLegendConfiguration();
    this._changedToolbarInstanceName();
    this._changedType();
  }

  private _changedType(value: EGestaoDGEMPSType = this.type): void {
    this.type = value;
    this.promise = this._loadData();
  }

  private _changedToolbarInstanceName(value: string = this.toolbarInstanceName): void {
    this._cleanupToolbar();
    this.toolbarInstanceName = value;
    this._toolbarInstanceOwned = !this._plToolbarService.isRegistered(this.toolbarInstanceName);
    this._toolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceName);
    this._toolbarInstance.addButton(this._toolbarLegend);
  }

  private _changedToolbarLegendConfiguration(value: Partial<IGestaoDGEMPSToolbarLegend> = this.toolbarLegendConfiguration): void {
    this._toolbarLegendConfiguration = {
      finsSemanaFeriados: true,
      gozadas: this.type === EGestaoDGEMPSType.Ferias,
      marcadas: true,
      aprovadas: true,
      rejeitadas: true,
      integradas: true,
      conflito: this.manager && this.view === EGestaoDGEMPSView.Year && this.type !== EGestaoDGEMPSType.Faltas,
      ...value
    };
    this._evaluateToolbarLegendCaption();
  }

  private async _loadData(): Promise<void> {
    this.servicos =
      !this.empregado?.codEmp || (!this.empregado.codServicoPRH && this.empregado.codServicoPRH !== 0)
        ? [{codServico: 0, nome: 'global.text.notDefined', papeis: new Set<number>()}]
        : !this.manager && this.type !== EGestaoDGEMPSType.Abonos
          ? [{codServico: this.empregado.codServicoPRH, nome: this.empregado.nomeServicoPRH, papeis: new Set<number>()}]
          : await this._getServicosEmpregados();
    this.servico = this._getDefaultServico();
  }

  private _getServicosEmpregados(): Promise<Array<IGestaoDGEMPSPRHServico>> {
    const promise = this._gestaoDGEMPSService
      .getServicosEmpregados(this.empregado.codEmp)
      .then((servicosEmpregado: Array<IJsonPRHServicoEmpregado>) => {
        const servicos: Map<number, IGestaoDGEMPSPRHServico> = new Map<number, IGestaoDGEMPSPRHServico>();
        for (const servicoEmpregado of servicosEmpregado) {
          let servico: IGestaoDGEMPSPRHServico = servicos.get(servicoEmpregado.codServico);
          if (!servico) {
            servico = {
              ...servicoEmpregado.servico,
              papeis: new Set<number>()
            };
            servicos.set(servico.codServico, servico);
          }
          if (!servicoEmpregado.papel.unicoServico) {
            // Preenche os papeis que o empregado atual tem atribuídos para o serviço
            servico.papeis.add(servicoEmpregado.codPapel);
          }
        }
        return Array.from(servicos.values());
      })
      .catch(() => []);
    this._addLoadingPromise(promise);
    return promise;
  }

  private _getDefaultServico(): IGestaoDGEMPSPRHServico {
    return !this.servicos.length
      ? undefined
      : isNumber(this.codServico)
        ? this.servicos.find((item: IGestaoDGEMPSPRHServico) => item.codServico === this.codServico) || this.servicos[0]
        : this.servicos[0];
  }

  private _refreshWeekends(): Promise<void> {
    this.holidaysDates = [];
    const year: number = this.viewDate.year();
    const promise: Promise<void> = this._calendariosService.getHolidaysDates(this.viewDate).then((weekends: Array<MomentInput>) => {
      this._holidaysDatesCache.set(year, weekends);
      this.holidaysDates = weekends;
    });
    this._addLoadingPromise(promise);
    return promise;
  }

  private _addLoadingPromise(value: Promise<unknown>): void {
    this._loadingPromises++;
    this._evaluateLoading();
    Promise.resolve(value).finally(() => {
      this._loadingPromises--;
      this._evaluateLoading();
    });
  }

  private _evaluateLoading(): void {
    this.loading = this.viewLoading || this._loadingPromises > 0;
  }

  private _evaluateToolbarLegendCaption(): void {
    const configuration: IGestaoDGEMPSToolbarLegend = this._toolbarLegendConfiguration;
    const caption: Array<string> = ['<ul class="toolbar-legenda">'];
    if (configuration.finsSemanaFeriados) {
      caption.push(`<li class="toolbar-legenda-item"><div class="circle ${this._cssClasses.Weekend}"></div>${<string>this._translateService.instant('gestaodgemps.text.finsSemanaFeriados')}</li>`);
    }
    if (configuration.marcadas) {
      caption.push(`<li class="toolbar-legenda-item"><div class="circle ${this._cssClasses.Marcadas}"></div>${<string>this._translateService.instant('gestaodgemps.text.marcadas')}</li>`);
    }
    if (configuration.aprovadas) {
      caption.push(`<li class="toolbar-legenda-item"><div class="circle ${this._cssClasses.Aprovadas}"></div>${<string>this._translateService.instant('gestaodgemps.text.aprovadas')}</li>`);
    }
    if (configuration.rejeitadas) {
      caption.push(`<li class="toolbar-legenda-item"><div class="circle ${this._cssClasses.Rejeitadas}"></div>${<string>this._translateService.instant('gestaodgemps.text.rejeitadas')}</li>`);
    }
    if (configuration.integradas) {
      caption.push(`<li class="toolbar-legenda-item"><div class="circle ${this._cssClasses.Integradas}"></div>${<string>this._translateService.instant('gestaodgemps.text.integradas')}</li>`);
    }
    if (configuration.gozadas) {
      caption.push(`<li class="toolbar-legenda-item"><div class="circle ${this._cssClasses.Gozadas}"></div>${<string>this._translateService.instant('gestaodgemps.text.gozadas')}</li>`);
    }
    if (configuration.conflito) {
      caption.push(`<li class="toolbar-legenda-item"><div class="circle ${this._cssClasses.Conflito}"></div>${<string>this._translateService.instant('gestaodgemps.text.conflito')}</li>`);
    }
    this._toolbarLegend.caption = caption.join('');
  }

  private _cleanupToolbar(): void {
    if (this._toolbarInstance) {
      if (this._toolbarInstanceOwned) {
        this._plToolbarService.unRegisterInstance(this._toolbarInstance);
        this._toolbarInstanceOwned = false;
      } else {
        this._toolbarInstance.removeGroupId(TOOLBAR_GROUP_ID);
      }
      this._toolbarInstance = undefined;
    }
  }
}
