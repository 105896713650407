export enum EModoFuncionamentoAnaliseMargemLucro {
  Processo,
  CentroDeCusto
}

export interface IDocfaCustoProveito {
  nDocfa: number;
  nome: string;
}

export interface IDocfaCustoProveitoSinal {
  id: number;
  sinal: string;
}

export const MODULE_NAME_GRUPO_DOCFA_CONFIGURACOES = 'grupodocfaconfiguracoes';
