import {Component, Injector} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {DocsComerciaisModalComponent} from '../docsComerciais.modal.component';

@Component({
  selector: 'doccomerciais-at-modal',
  templateUrl: './documento.comunicarAT.modal.component.html'
})
export class DocumentoComunicarATModalComponent extends DocsComerciaisModalComponent {
  public codAT: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
  }

  public close(): Promise<void> {
    if (!this.codAT) {
      this._plAlertService.error('docscomerciais.erros.emptycodAT');
      return Promise.resolve();
    }
    this.disableClose();
    return this.service
      .put({url: `${this.doc.cab.faccbId}/codigoat/${this.codAT}`})
      .then((response) => {
        if (response.body && response.body.cab.returnCode === 0) {
          this.doc = response.body;
        }
        this.enableClose();
        super.close(this.doc);
      })
      .finally(() => {
        this.enableClose();
      });
  }
}
