import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {IJsonDeclaracao, IJsonRENDI} from '../../jsonRendi.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';

@Component({
  selector: 'nifs-edit',
  templateUrl: './rendis.entity.edit.component.html'
})
export class RendisEditComponent extends ModuloEntityDetailComponent<IJsonRENDI> implements OnInit {
  @Input() public tiposDeclaracao: Array<IJsonDeclaracao>;

  public tipoDeclaracao: IJsonDeclaracao;
  public form: FormGroup;

  public ngOnInit(): void {
    super.ngOnInit();
    this.tiposDeclaracao = this.tiposDeclaracao.filter((declaracao: IJsonDeclaracao) => Boolean(declaracao.cod));
    if (this.type !== this.stateTypes.NEW) {
      this.setCaption(this._translateService.instant('rendis.title_detail', {id: this.model.tipRendiment}));
      this.tipoDeclaracao = {cod: this.model.tipoDeclaracao, descricao: this.model.tipoDeclaracaoStr};
    }
    this.entity.getDeleteMessage = (model: IJsonRENDI, translate: TranslateService) => ({
      title: translate.instant('rendis.deleteModalText', {tipRendiment: model.tipRendiment}),
      message: translate.instant('entity.delete.message')
    });
  }

  public tipoDeclaracaoChanged(item: IJsonDeclaracao): void {
    this.tipoDeclaracao = item;
    this.model.tipoDeclaracao = this.tipoDeclaracao?.cod;
    this.model.tipoDeclaracaoStr = this.tipoDeclaracao?.descricao;
  }
}
