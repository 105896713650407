import moment from 'moment';
import {Subscription, timer} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {copy, isBoolean, isObject} from 'pl-comps-angular';
import {ICGEnumeratorMap} from '../../../../common/interfaces/interfaces';
import {IEntity, IEntityMetadataField} from '../entity.definition.interface';
import {IEntityAutocomplete} from '../entity.autocomplete.definition.interface';

const MAXIMUM_CACHE_ENTRIES = 20;
const PURGE_INTERVAL: number = moment.duration(2, 'minutes').asMilliseconds();

@Injectable({
  providedIn: 'root'
})
export class EntityFilterService implements OnDestroy {
  private readonly _fieldsCache: ICGEnumeratorMap<string, Array<IEntityMetadataField>>;
  private _subscriptionPurgeCache: Subscription;

  constructor(private readonly _translateService: TranslateService) {
    this._fieldsCache = {
      values: new Map<string, Array<IEntityMetadataField>>(),
      keys: []
    };
  }

  public ngOnDestroy(): void {
    if (this._subscriptionPurgeCache) {
      this._subscriptionPurgeCache.unsubscribe();
    }
  }

  public buildFields(entity: IEntity): Array<IEntityMetadataField> {
    const key: string = entity.name;
    let fields: Array<IEntityMetadataField> = this._fieldsCache.values.get(key);
    if (!fields) {
      fields = copy(entity.getMetadata('filter').fields);

      for (const field of fields) {
        if (field.caption) {
          field.caption = this._translateService.instant(field.caption);
        }
        if (isObject(field.properties)) {
          if (isBoolean(field.properties.disabled)) {
            delete field.properties.disabled;
          }
          if (isBoolean(field.properties.readonly)) {
            delete field.properties.readonly;
          }
        }
        const entityData: IEntityAutocomplete = field.entity;
        if (entityData) {
          field.entityData = entityData.name;
          field.type = field.entityData;
          if (entityData.keyTarget) {
            field.name = entityData.keyTarget;
          }
        }
      }

      this._fieldsCache.values.set(key, fields);
      this._fieldsCache.keys.unshift(key);

      if (this._fieldsCache.keys.length > MAXIMUM_CACHE_ENTRIES && !this._subscriptionPurgeCache) {
        this._initPurgeCache();
      }
    }
    return fields.slice();
  }

  private _initPurgeCache(): void {
    if (this._subscriptionPurgeCache) {
      this._subscriptionPurgeCache.unsubscribe();
    }
    this._subscriptionPurgeCache = timer(0, PURGE_INTERVAL).subscribe(() => {
      const startIndex = MAXIMUM_CACHE_ENTRIES - 1;
      const endIndex = this._fieldsCache.keys.length;
      for (let i = startIndex; i < endIndex; i++) {
        this._fieldsCache.values.delete(this._fieldsCache.keys[i]);
      }
      this._fieldsCache.keys.splice(startIndex, endIndex);
    });
  }
}
