import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_ORD_DOCUMENTOS, ETipoOrdDocumentos} from './tiposOrdenacaoDocs.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPO_ORD_DOCUMENTOS: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_ORD_DOCUMENTOS,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoOrdDocumentos.NContribuinteData, name: 'tipoOrdDocumentos.data.nContribuinteData'},
    {value: ETipoOrdDocumentos.DataNContribuinte, name: 'tipoOrdDocumentos.data.dataNContribuinte'},
    {value: ETipoOrdDocumentos.DataRececao, name: 'tipoOrdDocumentos.data.dataRececao'},
    {value: ETipoOrdDocumentos.NomeData, name: 'tipoOrdDocumentos.data.nomeData'},
    {value: ETipoOrdDocumentos.DataNome, name: 'tipoOrdDocumentos.data.dataNome'}
  ]
});
