<div class="impctrirs entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form>
        <pl-group>
          <pl-group>
            <label [translate]="'global.text.listing'"></label>
            <edit>
              <pl-edit type="reports_imp_ctr_irs" attrName="listagem" [(model)]="report"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.fromDate'"></label>
            <edit>
              <pl-edit type="date" [(model)]="filters.deData"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit type="date" [(model)]="filters.ateData"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'impctrirs.fields.deCodEmp'"></label>
            <edit>
              <entity-autocomplete entity="dgempsfull" attrName="deCodEmp" [model]="filters" (selectedKeyChange)="filters.deCodEmp = $event" [fieldsMap]="{codEmp: 'deCodEmp'}" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="dgempsfull"
                attrName="ateCodEmp"
                [model]="filters"
                (selectedKeyChange)="filters.ateCodEmp = $event"
                [fieldsMap]="{codEmp: 'ateCodEmp'}"
                output="key"
                [properties]="{events: {keydown: fnKeydownProcessar}}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-multiselect [model]="empresas" [source]="empresasSource" [template]="empresasTemplate" key="nEmpresa"></pl-multiselect>
      </pl-form>
    </div>
  </cg-card-panel>

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
