import {Component, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'mascaraanalitica-edit-definitionNames-modal',
  templateUrl: './mascarasAnalitica.edit.definitionNames.modal.component.html'
})
export class MascarasAnaliticaEditDefinitionNamesModalComponent extends CGModalComponent<string> {
  @Input() public value: string;
}
