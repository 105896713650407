import {from, Observable} from 'rxjs';
import {PlFileReader} from './filereader';

export class PlReactiveFileReader {
  private readonly _fileReader: PlFileReader;

  constructor(blob: Blob) {
    this._fileReader = new PlFileReader(blob);
  }

  public readAsArrayBuffer(): Observable<ArrayBuffer> {
    return from<Promise<ArrayBuffer>>(this._fileReader.readAsArrayBuffer());
  }

  public readAsDataURL(): Observable<string> {
    return from<Promise<string>>(this._fileReader.readAsDataURL());
  }

  public readAsText(): Observable<string> {
    return from<Promise<string>>(this._fileReader.readAsText());
  }
}
