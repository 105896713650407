import {TDate} from '../../../../../../common/dates';
import {IJsonDocComercial, IJsonDocComercialLinha} from '../../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {IJsonTrDocLinha, IJsonTrDocLinhaLoteSel} from '../../../../../modules/trdocs/jsonTrDoc.module.interface';
import {IJsonLote} from '../../../../../entities/lotes/jsonLote.entity.interface';

export enum ETrDocsCabsWizardNavSteps {
  Pesquisa = 'pesquisa',
  Identificacao = 'identificacao',
  Lotes = 'lotes',
  Resumo = 'resumo'
}

export enum ETrDocsCabsWizardModoAgrupamento {
  CONTA,
  DOCUMENTO
}

export interface IJsonTrDocsCabsWizardCabItem {
  faccbId: number;
  nDoc: string;
  dataDoc: TDate;
  nConta: string;
  nContaNome: string;
  nif: string;
  totalLiquido: number;
  totalIva: number;
  totalDocumento: number;
  nCCusto: string;
  nRefProcesso: string;
  nFactFornec: string;
  transformado: boolean;
  identificaCarga?: boolean;
  linhas?: Array<IJsonDocComercialLinha>;
  _fullJsonDocComercial: IJsonDocComercial;
}

export interface ITrDocsCabsWizardIdentificacaoSourceItem {
  id: string;
  faccbId: number;
  nConta: string;
  nDoc: string;
  dataDoc: TDate;
  nFactFornec: string;
  dataDocExterno: TDate;
  referenciaTextoBase: string;
  documentos: Array<IJsonTrDocsCabsWizardCabItem>;
}

export interface ITrDocsCabsWizardConfigDocumento {
  faccbId: number;
  nConta: string;
  nDoc: string;
  dataDoc: TDate;
  nFactFornec: string;
  dataDocExterno: TDate;
  referenciaTextoBase: string;
  linhas: Array<IJsonTrDocLinha>;
}

export interface ITrDocsCabsWizardConfig {
  nDocfaDestino: number;
  nNumerDestino: number;
  encerraDocumentoOrigem: boolean;
  encerraDocumentoDestino: boolean;
  documentos: Array<ITrDocsCabsWizardConfigDocumento>;
  modoAgrupamento: ETrDocsCabsWizardModoAgrupamento;
}

export interface IJsonTrDocsCabsWizardFilter {
  nDocFaList: Array<number>;
  onlyPossibleToTransform: boolean;
  nConta: string;
  dataDoc: TDate;
  dataDocAte: TDate;
}

export interface IJsonTrDocsCabsWizard {
  docsPesquisa: Array<IJsonTrDocsCabsWizardCabItem>;
  config: ITrDocsCabsWizardConfig;
}

export interface ITrDocsDocComercialLinhaComLote extends IJsonDocComercialLinha {
  nDoc: string;
  faccbId: number;
  error: string;
}

export interface ITrDocsCabsWizardLotesHelperModel {
  currentIndex: number;
  currentKeyValue: string;
  sources: Map<string, Array<ITrDocsDocComercialLinhaComLote>>;
}

export interface ITrDocsCabsWizardLotesHelper {
  lotesCache: Map<string, Array<IJsonLote>>;
  lotesArtigoArmazem: Map<string, Array<IJsonTrDocLinhaLoteSel>>;
  model: ITrDocsCabsWizardLotesHelperModel;
}

export interface IJsonTrDocsCabsWizardCabResumo {
  listaDocsGerados: Array<IJsonDocComercial>;
  listaErros: Array<string>;
}

export interface IJsonTrDocsCabsWizardCabResumoErro {
  erro: string;
}
