import {ColaboradorBoardComponent} from './components/colaboradorBoard.module.component';
import {EGestaoDGEMPSType} from '../../../../components/gestaodgemps/gestaodgemps.interface';
import {GestaoDGEMPSService} from '../../../../components/gestaodgemps/gestaodgemps.service';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ROLE} from '../../../../services/role.const';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {IJsonPRHServicoEmpregado} from '../../../../entities/prhservicoempregados/jsonPRHServicoEmpregado.entity.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {ENTITY_NAME_PRH_SERVICO_EMPREGADOS} from '../../../../entities/prhservicoempregados/pRHServicoEmpregados.entity.interface';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';
import {IJsonPRHServico} from '../../../../entities/prhservicos/jsonPRHServico.entity.interface';

export const MODULE_COLABORADOR_BOARD: IModuleDefinition = {
  name: 'colaboradorboard',
  state: {
    url: '/board',
    component: ColaboradorBoardComponent,
    data: {
      roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO],
      icon: 'fa-home',
      pageTitle: 'global.menu.colaboradorboard'
    },
    resolve: [
      {
        provide: 'configStructureAbonos',
        deps: [GestaoDGEMPSService],
        useFactory: (gestaoDGEMPSService: GestaoDGEMPSService) => gestaoDGEMPSService.getConfigStructure(EGestaoDGEMPSType.Abonos)
      },
      {
        provide: 'configStructureFaltas',
        deps: [GestaoDGEMPSService],
        useFactory: (gestaoDGEMPSService: GestaoDGEMPSService) => gestaoDGEMPSService.getConfigStructure(EGestaoDGEMPSType.Faltas)
      },
      {
        provide: 'servicos',
        deps: ['session', EntityServiceBuilder],
        useFactory: (session: TUserSession, entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonPRHServico>> => {
          const codEmp: number = session.erp.codEmp;
          if (!codEmp) {
            return Promise.resolve([]);
          }
          const servicePRHServicoEmpregados: IEntityService<IJsonPRHServicoEmpregado> = entityServiceBuilder.build(ENTITY_NAME_PRH_SERVICO_EMPREGADOS);
          return servicePRHServicoEmpregados.query({pesquisa: `codEmp=${codEmp}`}).then((response: THttpQueryResponse<IJsonPRHServicoEmpregado>) => {
            return response.body.list.filter((servico: IJsonPRHServicoEmpregado) => !servico.papel.unicoServico).map((servico: IJsonPRHServicoEmpregado) => servico.servico);
          });
        }
      }
    ]
  }
};
