import {Ng2StateDeclaration} from '@uirouter/angular';
import {NoAuthorityComponent} from './noauthority.state.component';
import {STATE_NAME_NO_AUTHORITY} from './noauthority.state.interface';
import {STATE_NAME_SITE} from '../../../../common/site';

export const STATE_NO_AUTHORITY: Ng2StateDeclaration = {
  parent: STATE_NAME_SITE,
  name: STATE_NAME_NO_AUTHORITY,
  url: `/${STATE_NAME_NO_AUTHORITY}`,
  component: NoAuthorityComponent,
  data: {
    pageTitle: 'noauthority.title',
    disableRecover: true
  }
};
