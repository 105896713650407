import {Injectable} from '@angular/core';
import {IEncomendasService} from './encomendas.service.interface';
import {ApiService} from '../../../services/api/api.service';
import {IJsonDocSatEncom, IJsonEncomenda} from '../jsonEncomenda.module.interface';
import {TServiceQueryResponse, TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonDocComercial} from '../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {IParamsPrintEncomendas, MODULE_NAME_ENCOMENDAS} from '../encomendas.module.interface';

@Injectable({
  providedIn: 'root'
})
export class EncomendasService implements IEncomendasService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/${MODULE_NAME_ENCOMENDAS}`;
  }

  public pesquisar(search?: string, order?: string, page?: number, perpage?: number, searchFields?: string): TServiceQueryResponse<IJsonEncomenda> {
    return this._apiService.query<IJsonEncomenda>({
      url: this._path,
      params: {
        pesquisa: search,
        ordena: order,
        pagina: page,
        porpagina: perpage,
        campospesq: searchFields
      }
    });
  }

  public get(id: number): TServiceResponse<IJsonEncomenda> {
    return this._apiService.get<IJsonEncomenda>({
      url: `${this._path}/${id}`
    });
  }

  public getConfiguracoesPorDefeito(nDocfa: number, nConta: string): TServiceResponse<IJsonDocSatEncom> {
    return this._apiService.get<IJsonDocSatEncom>({
      url: `${this._path}/configuracoespordefeito/${nDocfa}/${nConta}`
    });
  }

  public getPdf(params: IParamsPrintEncomendas): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: `${this._path}/pdf`,
      responseType: 'blob',
      params: params
    });
  }

  public processar(docSatEncom: IJsonDocSatEncom, simular: boolean): TServiceResponse<IJsonDocComercial> {
    return this._apiService.post<IJsonDocComercial, IJsonDocSatEncom>({
      url: this._path,
      params: {simular: simular},
      body: docSatEncom
    });
  }

  public processarSatEncomNoDoc(faccbId: number, docSatEncom: IJsonDocSatEncom, simular: boolean): TServiceResponse<IJsonDocComercial> {
    return this._apiService.put<IJsonDocComercial, IJsonDocSatEncom>({
      url: `${this._path}/satisfaznodoc/${faccbId}`,
      params: {simular: simular},
      body: docSatEncom
    });
  }

  public satisfazEncomendaSemFacturar(ids: string): TServiceResponse<void> {
    return this._apiService.post<void>({
      url: `${this._path}/satisfazsemfaturar`,
      params: {
        ids: ids
      }
    });
  }
}
