import {TDate} from '../../../../common/dates';

export enum ERHProceSegSocialStatus {
  Inactive = 'rhpssInactive',
  Scheduled = 'rhpssScheduled',
  Started = 'rhpssStarted',
  Ended = 'rhpssEnded',
  Timeout = 'rhpssTimeout',
  Error = 'rhpssError'
}

export enum ERHProceSegSocialSteps {
  INTRO = 'RH_PROCE_SEG_SOCIAL_INTRO',
  EMPRESAS_SEL = 'RH_PROCE_SEG_SOCIAL_EMPRESAS_SEL',
  PROC_CONFIG = 'RH_PROCE_SEG_SOCIAL_CONFIG',
  PROC = 'RH_PROCE_SEG_SOCIAL_PROC',
  PROC_RESULTS = 'RH_PROCE_SEG_SOCIAL_PROC_RESULTS'
}

export enum ERHProceSegSocialPrintMode {
  MAPA_POR_EMPREGADO,
  SUMMARY
}

export interface IRHProceSegSocialStatus {
  requestDate: TDate;
  userStarted: string;
  userStartedId: number;
  state: ERHProceSegSocialStatus;
  description: string;
  position: number;
  max: number;
}

export interface IRHProceSegSocialModel {
  empresas: Array<string>;
  datas: Array<IRHProceSegSocialDataItem>;
  reProcList: Array<IRHProceSegSocialReProcItem>;
  canReProcExistentes: boolean;
}

export interface IRHProceSegSocialDataItem {
  mes: number;
  ano: number;
  selected: boolean;
  descricao: string;
}

export interface IRHProceSegSocialEmpresa {
  selected: boolean;
  nEmpresa: string;
  nomeEmpresa: string;
  erro: string;
}

export interface IRHProceSegSocialProcessStatus {
  max: number;
  position: number;
  description: string;
}

export interface IRHProceSegSocialSSItem {
  segSocialEmpresa: string;
  segSocialNomeEmpresa: string;
  mes: number;
  ano: number;
  idOrdemFichGerado: number;
  totalRemuneracaoSegSocial: number;
  totalContribuicaoSegSocial: number;
  totalRemuneracaoVencimento: number;
  infoDeclaracao: number;
  erros: number;
}

export interface IRHProceSegSocialProcessExistentesItem {
  _index?: number;
  selected: boolean;
  empresa: string;
  nomeEmpresa: string;
  dataProcessamento: TDate;
  ssMes: number;
  ssAno: number;
  fichGeradoPodeReprocessarFicheiro: boolean;
  tipoProcessamento: number;
  ssInfoEstadoProcessamento: number;
  estadoFicheiro: number;
  idOrdemFichGerado: number;
  tipoProcessamentoDescricao: string;
  estadoFicheiroStr: string;
  ssInfoEstadoProcessamentoStr: string;
}

export interface IRHProceSegSocialProcessExistentesParams {
  empresas: Array<string>;
  datas: Array<IRHProceSegSocialDataItem>;
}

export interface IRHProceSegSocialReProcItem {
  tipoProcessamento: number;
  empresa: string;
  ssMes: number;
  ssAno: number;
}

export interface IRHProceSegSocialErroItem {
  nEmpresa: string;
  erro: string;
  queFazer: string;
}

export interface IRHProceSegSocialItem {
  ssNEMPRESA: string;
  ssNOMEEMPRESA: string;
  mes: number;
  ano: number;
  idOrdemFichGerado: number;
  totalRemuneracaoSegSocial: number;
  totalContribuicaoSegSocial: number;
  totalRemuneracaoVencimento: number;
  infoDeclaracao: number;
  erros: number;
  statusImage?: string;
}

export interface IRHProceSegSocialSegSocialList {
  list: Array<IRHProceSegSocialItem>;
  erros: Array<IRHProceSegSocialErroItem>;
}

export interface IRHProceSegSocialFicheiroEdicaoSave {
  anoRefCodEmp: number;
  mesRefCodEmp: number;
  numSS: string;
  codEmp: number;
  nDiasPRemPerMan: number;
  nDias2FerPagasCess: number;
  nDiasICtRinTermit: number;
}

export interface IRHProceSegSocialFicheiroEdicao extends IRHProceSegSocialFicheiroEdicaoSave {
  nome: string;
  valPRemPerMan: number;
  valRSubRefeicao: number;
  valFSubFerias: number;
  valNSubNatal: number;
  valAAjudasCusto: number;
  valBPremMensal: number;
  valCComissoes: number;
  valDCompCessacao: number;
  valHHonorarios: number;
  valMSubsRegMensal: number;
  valOPrNaoMensal: number;
  valSRSuplementar: number;
  valTTrabNocturno: number;
  valXOutSubsidios: number;
  val2FerPagasCess: number;
  val6DifVencim: number;
  valICtRinTermit: number;
  totalSegSocialRemun: number;
  totalVencRemun: number;
  erro: number;
  erroDescricao: string;
  statusImage?: string;
  taxaAAplicar: number;
}

export interface IRHProceSegSocialFicheiroEdicaoDataGridRecord extends IRHProceSegSocialFicheiroEdicao {
  ano: number;
  mes: number;
}

export interface IRHProceSegSocialSendWebService {
  isEstadoComErros: boolean;
  ano: number;
  mes: number;
}

export const MODULE_NAME_PROCE_SEG_SOCIAL = 'proceSegSocial';
