<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'global.text.attention'"></h5>
</div>

<div class="modal-body">
  <ng-container *ngIf="!loaded">
    <div class="text-center">
      <div class="progress" style="height: 6px">
        <div class="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" [attr.aria-valuemin]="0" [attr.aria-valuemax]="100" style="width: 100%"></div>
      </div>
      <div style="font-size: 12px" [translate]="'gto.loadingLicenseData'"></div>
    </div>
  </ng-container>

  <ng-container *ngIf="loaded">
    <ng-container *ngIf="dadosLicenca.nEmpresasPorConsumir > 0">
      <p [translate]="'gto.licensemodal.license1'"></p>
      <p [translate]="'gto.licensemodal.license2'" [translateParams]="{totalToConsume: dadosLicenca.nEmpresasPorConsumir}"></p>
    </ng-container>
    <ng-container *ngIf="dadosLicenca.nEmpresasPorConsumir === 0">
      <p [translate]="'gto.licensemodal.license4'"></p>
    </ng-container>
    <br />
    <h6 class="mb-2"><span [translate]="'gto.licensemodal.license3'"></span>:</h6>
    <div [translate]="'gto.licensemodal.total'" [translateParams]="{total: dadosLicenca.nEmpresasTotal}"></div>
    <div [translate]="'gto.licensemodal.totalConsumed'" [translateParams]="{totalConsumed: dadosLicenca.nEmpresasConsumidas}"></div>
    <div [translate]="'gto.licensemodal.totalToConsume'" [translateParams]="{totalToConsume: dadosLicenca.nEmpresasPorConsumir}"></div>
  </ng-container>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <ng-container *ngIf="dadosLicenca.nEmpresasPorConsumir > 0">
    <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="close()" plAutoFocus><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.yes'"></span></pl-button>

    <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.no'"></span></pl-button>
  </ng-container>

  <ng-container *ngIf="dadosLicenca.nEmpresasPorConsumir === 0">
    <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </ng-container>
</div>
