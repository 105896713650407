import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonDocContabilidade} from '../../../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';

@Component({
  selector: 'modal-ativos-impressao-doc',
  templateUrl: './ativosImpressao.doc.modal.component.html'
})
export class AtivosImpressaoDocModalComponent extends CGModalComponent<boolean> {
  @Input() public doc: IJsonDocContabilidade;

  public needReload: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.needReload = false;
  }

  public close(): void {
    super.close(this.needReload);
  }

  public readonly fnDocumentoContabilidadeAction: () => void = () => {
    this._onDocumentoContabilidadeAction();
  };

  public readonly fnDocumentoContabilidadeActionDel: () => void = () => {
    this._onDocumentoContabilidadeActionDel();
  };

  private _onDocumentoContabilidadeAction(): void {
    this.needReload = true;
  }

  private _onDocumentoContabilidadeActionDel(): void {
    this.needReload = true;
    this.close();
  }
}
