import {ClassProvider, forwardRef, Injectable} from '@angular/core';
import {DI_AMALIA_INTENT_HANDLERS} from '../../../../services/amalia/intent/amalia.intent.handler.di';
import {IAmaliaIntent, IAmaliaIntentHandler} from '../../../../services/amalia/intent/amalia.intent.handler.interface';
import {CGStateService} from '../../../../components/state/cg.state.service';
import {AMALIA_INTENT_NAME_CONCILIACAO_EFATURAS, IConciliacaoEFaturasParams} from './conciliacaoEFaturas.amalia.interface';
import {MODULE_NAME_CONCILIACAO_EFATURAS} from '../conciliacaoEFaturas.module.interface';
import {EPortal} from '../../../../../common/enums/portals.enums';

export const AMALIA_INTENT_HANDLER_CONCILIACAO_EFATURAS: ClassProvider = {
  provide: DI_AMALIA_INTENT_HANDLERS,
  multi: true,
  useClass: forwardRef(() => MovimentosEmAbertoAmaliaIntentHandler)
};

@Injectable()
export class MovimentosEmAbertoAmaliaIntentHandler implements IAmaliaIntentHandler {
  constructor(private readonly _cgStateService: CGStateService) {}

  public intentName(): string {
    return AMALIA_INTENT_NAME_CONCILIACAO_EFATURAS;
  }

  public async intentHandler(intent: IAmaliaIntent<IConciliacaoEFaturasParams>): Promise<void> {
    return this._cgStateService.redirectToState({
      stateOrName: MODULE_NAME_CONCILIACAO_EFATURAS,
      portal: EPortal.CONTABILIDADE,
      exactMatch: true,
      transitionOptions: {reload: true},
      params: {
        deData: intent.parameters?.deData,
        ateData: intent.parameters?.ateData
      }
    });
  }
}
