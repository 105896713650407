import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {ProceDMRATComponent} from './components/proceDMRAT.module.component';
import {MODULE_NAME_PROCE_DMR_AT} from './proceDMRAT.module.interface';

export const MODULE_PROCE_DMR_AT: IModuleDefinition = {
  name: MODULE_NAME_PROCE_DMR_AT,
  state: {
    url: `/${MODULE_NAME_PROCE_DMR_AT}`,
    component: ProceDMRATComponent,
    data: {
      roles: [ROLE.RH],
      sidebarTitle: 'portals.sidebar.modules.proceDMRAT'
    }
  }
};
