import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_MOVIMENTO} from './tiposMovimento.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPOS_MOVIMENTO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_MOVIMENTO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'tiposMovimento.data.notDefined'},
    {value: 1, name: 'tiposMovimento.data.default'},
    {value: 2, name: 'tiposMovimento.data.squareMeters'},
    {value: 3, name: 'tiposMovimento.data.cubicMeters'},
    {value: 4, name: 'tiposMovimento.data.linearMeters'},
    {value: 5, name: 'tiposMovimento.data.grams'},
    {value: 6, name: 'tiposMovimento.data.kilos'},
    {value: 7, name: 'tiposMovimento.data.tons'},
    {value: 8, name: 'tiposMovimento.data.liters'},
    {value: 9, name: 'tiposMovimento.data.hectoliters'},
    {value: 10, name: 'tiposMovimento.data.perimeter'},
    {value: 11, name: 'tiposMovimento.data.packing'},
    {value: 12, name: 'tiposMovimento.data.hours'}
  ]
});
