import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {
  ESaftContabCommandType,
  ETipoImportacaoSAFTContab,
  IRestCmdSaftContab,
  IRestCmdSaftContabAddContasSemConversaoList,
  IRestCmdSaftContabAddConvertConta,
  IRestCmdSaftContabDeleteConvertConta,
  IRestCmdSaftContabGetAssociacaoDiarioList,
  IRestCmdSaftContabGetIsBalanceteSaldado,
  IRestCmdSaftContabGetTreeDetail,
  IRestCmdSaftContabImport,
  IRestCmdSaftContabLoadContasCorrentes,
  IRestCmdSaftContabLoadPlanoContasList,
  IRestCmdSaftContabLoadState,
  IRestCmdSaftContabMarcarTodosParaCriar,
  IRestCmdSaftContabProcessFile,
  IRestCmdSaftContabReplaceConvertConta,
  IRestCmdSaftContabReplaceConvertExistent,
  IRestCmdSaftContabSearchContaCorrente,
  IRestCmdSaftContabSetAssociacaoDiarioList,
  IRestCmdSaftContabStopJob,
  IRestCmdSaftContabTipoImport,
  IRestCmdSaftContabUpdateContasCorrentes,
  TSaftContabCommandRawResult,
  TSaftContabCommandResponse
} from './saftContab.module.interface';
import {
  IJsonSAFTContabContaCorrente,
  IJsonSAFTContabDiario,
  IJsonSAFTContabObjFullModel,
  IJsonSAFTContabPlanoContas,
  IJsonSAFTContabStatus,
  IJsonSAFTContabTreeDetail
} from './jsonSaftContab.module.interface';
import {Observable, of} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../common/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class SaftContabService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/importadorSaftContab`;
  }

  public balanceteSaldado(): TServiceResponse<void> {
    return this._apiService.get<void>({url: `${this._path}/balancetesaldado`});
  }

  public importGetModeloXLS(planoContas: Array<IJsonSAFTContabPlanoContas> = []): TServiceResponse<Blob> {
    return this._apiService.post<Blob, Array<IJsonSAFTContabPlanoContas>>({
      url: `${this._path}/import/modelo/xls`,
      responseType: 'blob',
      body: planoContas
    });
  }

  public importExecuteMatrizXls(): string {
    return `${this._path}/import/execute/xls`;
  }

  public generateSaftContabUploadUrl(): Observable<string> {
    return buildSessionUrlWithParams(this._path, {filename: 'saftcontab.xml'});
  }

  public getImportConfig(importUrl: string): Observable<string> {
    return of(`${this._path}/${importUrl}`);
  }

  public saftContabExportConfig(): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: `${this._path}/exportConfig`,
      responseType: 'blob'
    });
  }

  public getJobStatus(): Promise<IJsonSAFTContabStatus> {
    return this._apiService.get<IJsonSAFTContabStatus>({url: `${this._path}/status`}).then((response: HttpResponse<IJsonSAFTContabStatus>) => response.body);
  }

  public setTipoImportacao(tipoImportacao: ETipoImportacaoSAFTContab): TSaftContabCommandRawResult<IRestCmdSaftContabTipoImport> {
    const command: IRestCmdSaftContabTipoImport = {
      tipoImportacao: tipoImportacao
    };
    return this._newCommandRaw<IRestCmdSaftContabTipoImport>(ESaftContabCommandType.SetTipoImportacao, command);
  }

  public processFile(fileName: string): TSaftContabCommandRawResult<IRestCmdSaftContabProcessFile> {
    const command: IRestCmdSaftContabProcessFile = {
      filename: fileName
    };
    return this._newCommandRaw<IRestCmdSaftContabProcessFile>(ESaftContabCommandType.ProcessFile, command);
  }

  public loadState(objFullModel: IJsonSAFTContabObjFullModel): TSaftContabCommandRawResult<IRestCmdSaftContabLoadState> {
    const command: IRestCmdSaftContabLoadState = {
      model: objFullModel
    };
    return this._newCommandRaw<IRestCmdSaftContabLoadState>(ESaftContabCommandType.LoadState, command);
  }

  public stopJob(): TSaftContabCommandRawResult<IRestCmdSaftContabStopJob> {
    const command: IRestCmdSaftContabStopJob = {};
    return this._newCommandRaw<IRestCmdSaftContabStopJob>(ESaftContabCommandType.StopJob, command);
  }

  public getAssociacaoDiarioList(list: Array<IJsonSAFTContabDiario>): TSaftContabCommandRawResult<IRestCmdSaftContabGetAssociacaoDiarioList> {
    const command: IRestCmdSaftContabGetAssociacaoDiarioList = {
      list: list
    };
    return this._newCommandRaw<IRestCmdSaftContabGetAssociacaoDiarioList>(ESaftContabCommandType.GetAssociacaoDiarioList, command);
  }

  public setAssociacaoDiarioList(list: Array<IJsonSAFTContabDiario>): TSaftContabCommandRawResult<IRestCmdSaftContabSetAssociacaoDiarioList> {
    const command: IRestCmdSaftContabSetAssociacaoDiarioList = {
      list: list
    };
    return this._newCommandRaw<IRestCmdSaftContabSetAssociacaoDiarioList>(ESaftContabCommandType.SetAssociacaoDiarioList, command);
  }

  // public getContasExistentesList(list: Array<IJsonSAFTContabConvertConta>): TSaftContabCommandRawResult<IRestCmdSaftContabGetContasExistentesList> {
  //   const command: IRestCmdSaftContabGetContasExistentesList = {
  //     list: list
  //   };
  //   return this._newCommandRaw<IRestCmdSaftContabGetContasExistentesList>(ESaftContabCommandType.GetContasExistentesList, command);
  // }

  // public getContasSemConversaoList(list: Array<IJsonSAFTContabContaSemConversao>): TSaftContabCommandRawResult<IRestCmdSaftContabGetContasSemConversaoList> {
  //   const command: IRestCmdSaftContabGetContasSemConversaoList = {
  //     list: list
  //   };
  //   return this._newCommandRaw<IRestCmdSaftContabGetContasSemConversaoList>(ESaftContabCommandType.GetContasSemConversaoList, command);
  // }

  public addConvertConta(
    list: Array<IJsonSAFTContabPlanoContas>,
    listContasSemConv: Array<IJsonSAFTContabPlanoContas>,
    nContaOrigem: string,
    nContaDestino: string
  ): TSaftContabCommandRawResult<IRestCmdSaftContabAddConvertConta> {
    const command: IRestCmdSaftContabAddConvertConta = {
      list: list,
      listContasSemConv: listContasSemConv,
      nContaOrigem: nContaOrigem,
      nContaDestino: nContaDestino
    };
    return this._newCommandRaw<IRestCmdSaftContabAddConvertConta>(ESaftContabCommandType.AddConvertConta, command);
  }

  public replaceConvertConta(
    list: Array<IJsonSAFTContabPlanoContas>,
    listContasSemConv: Array<IJsonSAFTContabPlanoContas>,
    nContaOrigem: string,
    nContaDestino: string
  ): TSaftContabCommandRawResult<IRestCmdSaftContabReplaceConvertConta> {
    const command: IRestCmdSaftContabReplaceConvertConta = {
      list: list,
      listContasSemConv: listContasSemConv,
      nContaOrigem: nContaOrigem,
      nContaDestino: nContaDestino
    };
    return this._newCommandRaw<IRestCmdSaftContabReplaceConvertConta>(ESaftContabCommandType.ReplaceConvertConta, command);
  }

  public deleteConvertConta(
    list: Array<IJsonSAFTContabPlanoContas>,
    listContasSemConv: Array<IJsonSAFTContabPlanoContas>,
    nContaOrigem: string
  ): TSaftContabCommandRawResult<IRestCmdSaftContabDeleteConvertConta> {
    const command: IRestCmdSaftContabDeleteConvertConta = {
      list: list,
      listContasSemConv: listContasSemConv,
      nContaOrigem: nContaOrigem
    };
    return this._newCommandRaw<IRestCmdSaftContabDeleteConvertConta>(ESaftContabCommandType.DeleteConvertConta, command);
  }

  public replaceConvertExistent(list: Array<IJsonSAFTContabPlanoContas>, nContaOrigem: string, nContaDestino: string): TSaftContabCommandRawResult<IRestCmdSaftContabReplaceConvertExistent> {
    const command: IRestCmdSaftContabReplaceConvertExistent = {
      list: list,
      nContaOrigem: nContaOrigem,
      nContaDestino: nContaDestino
    };
    return this._newCommandRaw<IRestCmdSaftContabReplaceConvertExistent>(ESaftContabCommandType.ReplaceConvertExistent, command);
  }

  public addSemConvertConta(
    list: Array<IJsonSAFTContabPlanoContas>,
    nConta: string,
    nomeConta: string,
    criaConta: boolean,
    erro: string,
    codControlIvaDebito: string,
    codControlIvaCredito: string
  ): TSaftContabCommandRawResult<IRestCmdSaftContabAddContasSemConversaoList> {
    const command: IRestCmdSaftContabAddContasSemConversaoList = {
      list: list,
      nConta: nConta,
      nomeConta: nomeConta,
      criaConta: criaConta,
      erro: erro,
      codControlIvaDebito: codControlIvaDebito,
      codControlIvaCredito: codControlIvaCredito
    };
    return this._newCommandRaw<IRestCmdSaftContabAddContasSemConversaoList>(ESaftContabCommandType.AddSemConvertConta, command);
  }

  // public getConvertContaList(list: Array<IJsonSAFTContabConvertConta>): TSaftContabCommandRawResult<IRestCmdSaftContabGetConvertContaList> {
  //   const command: IRestCmdSaftContabGetConvertContaList = {
  //     list: list
  //   };
  //   return this._newCommandRaw<IRestCmdSaftContabGetConvertContaList>(ESaftContabCommandType.GetConvertContaList, command);
  // }

  public getPlanoContas(list: Array<IJsonSAFTContabPlanoContas>): TSaftContabCommandRawResult<IRestCmdSaftContabLoadPlanoContasList> {
    const command: IRestCmdSaftContabLoadPlanoContasList = {
      list: list
    };
    return this._newCommandRaw<IRestCmdSaftContabLoadPlanoContasList>(ESaftContabCommandType.GetPlanoContasList, command);
  }

  public marcarTodosParaCriar(list: Array<IJsonSAFTContabPlanoContas>, cria: boolean): TSaftContabCommandRawResult<IRestCmdSaftContabMarcarTodosParaCriar> {
    const command: IRestCmdSaftContabMarcarTodosParaCriar = {
      list: list,
      cria: cria
    };
    return this._newCommandRaw<IRestCmdSaftContabMarcarTodosParaCriar>(ESaftContabCommandType.MarcarTodosParaCriar, command);
  }

  public loadContasCorrentes(list: Array<IJsonSAFTContabContaCorrente>): TSaftContabCommandRawResult<IRestCmdSaftContabLoadContasCorrentes> {
    const command: IRestCmdSaftContabLoadContasCorrentes = {
      list: list
    };
    return this._newCommandRaw<IRestCmdSaftContabLoadContasCorrentes>(ESaftContabCommandType.LoadContasCorrentes, command);
  }

  public updateContasCorrentes(list: Array<IJsonSAFTContabContaCorrente>, nConta: string, nif: string): TSaftContabCommandRawResult<IRestCmdSaftContabUpdateContasCorrentes> {
    const command: IRestCmdSaftContabUpdateContasCorrentes = {
      list: list,
      nConta: nConta,
      nif: nif
    };
    return this._newCommandRaw<IRestCmdSaftContabUpdateContasCorrentes>(ESaftContabCommandType.UpdateContasCorrentes, command);
  }

  public getIsBalanceteSaldado(): TSaftContabCommandRawResult<IRestCmdSaftContabGetIsBalanceteSaldado> {
    const command: IRestCmdSaftContabGetIsBalanceteSaldado = {
      balanceteSaldado: undefined
    };
    return this._newCommandRaw<IRestCmdSaftContabGetIsBalanceteSaldado>(ESaftContabCommandType.GetIsBalanceteSaldado, command);
  }

  public import(
    apenasContasCorrentesComMovimento: boolean,
    verificaBalancete: boolean,
    trocaGLPostingDateComTransactionDate: boolean,
    nDiarioApuramento: number,
    nDescritivo: number,
    nDescritivoSaldosIniciais: number,
    periodoSaldosIniciais: string,
    nDiarioSaldosIniciais: number,
    justificaContasCorrenteMovAb: boolean,
    efetuaConversaoContasCorrentes: boolean
  ): TSaftContabCommandRawResult<IRestCmdSaftContabImport> {
    const command: IRestCmdSaftContabImport = {
      apenasContasCorrentesComMovimento: apenasContasCorrentesComMovimento,
      verificaBalancete: verificaBalancete,
      trocaGLPostingDateComTransactionDate: trocaGLPostingDateComTransactionDate,
      nDiarioApuramento: nDiarioApuramento,
      nDescritivo: nDescritivo,
      nDescritivoSaldosIniciais: nDescritivoSaldosIniciais,
      periodoSaldosIniciais: periodoSaldosIniciais,
      nDiarioSaldosIniciais: nDiarioSaldosIniciais,
      justificaContasCorrenteMovAb: justificaContasCorrenteMovAb,
      efetuaConversaoContasCorrentes: efetuaConversaoContasCorrentes
    };
    return this._newCommandRaw<IRestCmdSaftContabImport>(ESaftContabCommandType.Import, command);
  }

  public getTreeDetail(treeDetail: IJsonSAFTContabTreeDetail, nconta: string): TSaftContabCommandRawResult<IRestCmdSaftContabGetTreeDetail> {
    const command: IRestCmdSaftContabGetTreeDetail = {
      treeDetail: treeDetail,
      nconta: nconta
    };
    return this._newCommandRaw<IRestCmdSaftContabGetTreeDetail>(ESaftContabCommandType.GetTreeDetail, command);
  }

  public searchContaCorrente(list: Array<IJsonSAFTContabContaCorrente>, text: string): TSaftContabCommandRawResult<IRestCmdSaftContabSearchContaCorrente> {
    const command: IRestCmdSaftContabSearchContaCorrente = {
      list: list,
      text: text
    };
    return this._newCommandRaw<IRestCmdSaftContabSearchContaCorrente>(ESaftContabCommandType.SearchContaCorrente, command);
  }

  private _newCommandRaw<T extends IRestCmdSaftContab>(commandName: ESaftContabCommandType, command: T): TSaftContabCommandRawResult<T> {
    return new Promise<T>((resolve, reject) => {
      this._sendCommand<T>(commandName, command)
        .then((response: HttpResponse<T>) => {
          resolve(response.body);
        })
        .catch((reason: HttpErrorResponse) => {
          reject(reason);
        });
    });
  }

  private _sendCommand<T>(commandName: string, command: IRestCmdSaftContab): TServiceResponse<TSaftContabCommandResponse<T>> {
    return this._apiService.patch<TSaftContabCommandResponse<T>, IRestCmdSaftContab>({
      url: this._path,
      body: command,
      reportExceptions: false,
      params: {command: commandName}
    });
  }
}
