import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {FormGroupDirective, UntypedFormGroup} from '@angular/forms';
import {HttpResponse} from '@angular/common/http';
import {
  IPlLocale,
  IPlNavWizardCallback,
  IPlNavWizardDefinition,
  IPlNavWizardEventBeforeChange,
  IPlNavWizardStep,
  IPlToolbarItem,
  isArray,
  isFunction,
  isNumber,
  isUndefined,
  PlAlertService,
  PlLocaleService
} from 'pl-comps-angular';
import {ContabilidadePredefinidoService} from '../service/contabilidade.predefinido.module.service';
import {ContabilidadePredefinidosService} from '../service/contabilidade.predefinidos.module.service';
import {DocContabilidadeService} from '../../../docscontabilidade/components/doccontabilidade/docContabilidade.service';
import {DocsContabilidadeService} from '../../../docscontabilidade/service/docsContabilidade.service';
import {
  EContabilidadePredefinidosOperation,
  EContabilidadePredefinidosStep,
  ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS,
  IPreDefinidoContab,
  IPreDefinidosOperation,
  IPreDefinidoStateParams
} from '../preDefinidosContab.module.interface';
import {EDocContabilidadeOrigem, IJsonDocContabilidade} from '../../../docscontabilidade/jsonDocContabilidade.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {focusElement} from '../../../../../../common/utils/element.utils';
import {IDocContabilidade, IDocsContabilidadeEntityService} from '../../../docscontabilidade/docsContabilidade.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonErpUser} from '../../../../../services/account/jsonUserApi.interface';
import {IJsonPreDefinidoContab} from '../jsonPreDefinidosContab.module.interface';
import {IPreDefinidoContabCabCallback} from './cabecalho/predefinidocontabcab.component.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {Subscription} from 'rxjs';
import {IDocContabilidadeCallback} from '../../../docscontabilidade/components/doccontabilidade/docContabilidade.interface';

const STEP_INDEX_OPERATION = 0;
const STEP_INDEX_BASIC = 1;
const STEP_INDEX_HEADER = 2;
const STEP_INDEX_LINES = 3;
const STEP_INDEX_PREVIEW = 4;
const STEP_INDEX_COMPANIES = 5;
const SAVE_PROMPT_IDENTIFIER = 'contabilidade-predefinidos-preview';

@Component({
  selector: 'contabilidade-predefinidos',
  templateUrl: './contabilidade.predefinidos.component.html'
})
export class ContabilidadePredefinidosComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly operations: typeof EContabilidadePredefinidosOperation;
  public readonly steps: typeof EContabilidadePredefinidosStep;
  public readonly templateListaEmpresas: string;
  public readonly definition: IPlNavWizardDefinition;
  public readonly groupOperations: Array<IPreDefinidosOperation>;
  public readonly plNavWizard: IPlNavWizardCallback;
  public readonly preDefinidoContabCab: IPreDefinidoContabCabCallback;

  public model: IPreDefinidoContab;
  public docContabilidade: IDocContabilidade;
  public docContabilidadePreview: IDocContabilidade;
  public formStep1: FormGroupDirective;
  public textValidatorRequired: string;
  public isStep3Valid: boolean;
  public isStep4Valid: boolean;
  public listaEmpresas: Array<IJsonErpUser>;
  public empresasSelecionadas: Array<IJsonErpUser>;
  public operation: EContabilidadePredefinidosOperation;
  public preDefinidosIDToCopy: number;
  public titleCompanies: string;
  public titleCompaniesRight: string;
  public step: IPlNavWizardStep;
  public showPreview: boolean;
  public disableForm: boolean;
  public hardEditing: boolean;
  public formInstanceBasic: UntypedFormGroup;
  public currentStepId: EContabilidadePredefinidosStep;
  public docCallback: IDocContabilidadeCallback;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private readonly _toolTitleOperation: IPlToolbarItem;
  private readonly _btnClearDocContab: IPlToolbarItem;
  private readonly _btnSimulateSave: IPlToolbarItem;
  private readonly _entityService: IEntityService<IPreDefinidoContab>;
  private readonly _subscriptionLocale: Subscription;
  private _updating: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _contabilidadePredefinidosService: ContabilidadePredefinidosService,
    private readonly _contabilidadePredefinidoService: ContabilidadePredefinidoService,
    private readonly _plAlertService: PlAlertService,
    private readonly _docsContabilidadeService: DocsContabilidadeService,
    private readonly _docContabilidadeService: DocContabilidadeService
  ) {
    super(_injector);
    this.docCallback = {};
    this.operations = EContabilidadePredefinidosOperation;
    this.steps = EContabilidadePredefinidosStep;
    this.templateListaEmpresas = '{{nEmpresa}} - {{nomeEmpresa}}';
    this.disableForm = false;
    this.hardEditing = false;
    this.plNavWizard = {};
    this.preDefinidoContabCab = {};
    this.definition = {
      items: [],
      force: false
    };
    this.groupOperations = [
      {value: EContabilidadePredefinidosOperation.NEW, label: 'predefinidoscontabilidade.operations.new'},
      {value: EContabilidadePredefinidosOperation.NEW_BASE_DOC, label: 'predefinidoscontabilidade.operations.newBasedOnDoc'},
      {value: EContabilidadePredefinidosOperation.NEW_BASE_PREDEFINIDO, label: 'predefinidoscontabilidade.operations.newBasedOnExisting'},
      {value: EContabilidadePredefinidosOperation.EDIT, label: 'predefinidoscontabilidade.operations.edit'},
      {value: EContabilidadePredefinidosOperation.DELETE, label: 'predefinidoscontabilidade.operations.delete'}
    ];
    this.listaEmpresas = [];
    this.empresasSelecionadas = [];
    this.titleCompaniesRight = 'predefinidoscontabilidade.titles.companiesToSave';
    this.showPreview = false;

    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>('docscontabilidade');
    this._toolTitleOperation = {
      order: 1,
      id: 'titleoperation',
      type: 'title',
      caption: '',
      visible: false
    };
    this._btnSimulateSave = {
      id: 'predefinidossimulatesave',
      type: 'button',
      order: 110,
      class: 'btn-info',
      iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>',
      caption: 'predefinidoscontabilidade.btn.simulate',
      visible: false,
      click: () => this.simulateDocContabSave()
    };
    this._btnClearDocContab = {
      id: 'predefinidoscleandoccontab',
      type: 'button',
      order: 111,
      class: 'btn-primary',
      iconLeft: '<i class="fa fa-fw fa-ban"></i>',
      caption: 'predefinidoscontabilidade.btn.clear',
      visible: false,
      click: () => {
        this._clearDocContab();
      }
    };
    this._entityService = this._entityServiceBuilder.build<IPreDefinidoContab>(ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS);
    this._updating = false;

    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.textValidatorRequired = locale.validators.required;
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.toolbar.addButton(this._toolTitleOperation);
    this.toolbar.addButton(this._btnSimulateSave);
    this.toolbar.addButton(this._btnClearDocContab);

    this._reset();

    const params: IPreDefinidoStateParams = this._transition.params();
    if (isNumber(params.operation)) {
      if (params.operation === EContabilidadePredefinidosOperation.NEW_BASE_DOC && params.docContabilidade) {
        this.operation = EContabilidadePredefinidosOperation.NEW_BASE_DOC;
        this._setPromise(
          new Promise<void>((resolve, reject) => {
            this._loadDocContabilidadeByExtPocCabId(params.docContabilidade)
              .then(() => {
                this.plNavWizard.setStep(this.definition.items[STEP_INDEX_BASIC]).finally(() => {
                  resolve();
                });
              })
              .catch(reject);
          })
        );
      }
    } else if (isNumber(params.editPreDefinidosID) && params.editPreDefinidosID !== 0) {
      this.hardEditing = true;
      this.operation = EContabilidadePredefinidosOperation.EDIT;
      this._setPromise(
        new Promise<void>((resolve, reject) => {
          this._entityService
            .get({id: params.editPreDefinidosID})
            .then((response: HttpResponse<IJsonPreDefinidoContab>) => {
              this.model.preDefinidosID = response.body.preDefinidosID;
              this.model.descricao = response.body.descricao;
              setTimeout(() => {
                this.definition.items[STEP_INDEX_OPERATION].visible = false;
                this.plNavWizard.setStep(this.definition.items[STEP_INDEX_HEADER], true).finally(() => {
                  resolve();
                });
              });
            })
            .catch(reject);
        })
      );
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionLocale.unsubscribe();
    this._clearForSaveEventListener();
  }

  public changedStep(currentStep: IPlNavWizardStep): void {
    this.currentStepId = <EContabilidadePredefinidosStep>currentStep.stepId;
    if (this.currentStepId === EContabilidadePredefinidosStep.Operation) {
      this._reset();
    } else if (this.currentStepId === EContabilidadePredefinidosStep.Companies) {
      this.setCompaniesTitle();
      this._loadEmpresas();
    }
    if (this.currentStepId === EContabilidadePredefinidosStep.Preview) {
      this._docContabilidadeService.listenForSaveEvent({
        identifier: SAVE_PROMPT_IDENTIFIER,
        callbackGetDoc: () => this.docContabilidadePreview,
        callbackOnSave: () => this.simulateDocContabSave(),
        contabilidadeDigital: false,
        simulation: true
      });
    } else {
      this._clearForSaveEventListener();
    }
    this._initStep4();
  }

  public simulateDocContabSave(): Promise<void> {
    return this._docsContabilidadeService
      .simulaGravacao(EDocContabilidadeOrigem.PreDefinidos, this.docContabilidadePreview)
      .then(() => {
        this._plAlertService.success('predefinidoscontabilidade.success.simulate');
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
      });
  }

  public changedEmpresasSelecionadas(): void {
    this.model.empresasSelecionadas = this.empresasSelecionadas.map((empresa: IJsonErpUser) => empresa.nEmpresa);
  }

  public setCompaniesTitle(): void {
    let title;
    if (this.operation === EContabilidadePredefinidosOperation.DELETE) {
      title = 'predefinidoscontabilidade.titles.selectColumnsDelete';
      this.titleCompaniesRight = 'predefinidoscontabilidade.titles.companiesToRemove';
    } else {
      title = this.operation === EContabilidadePredefinidosOperation.EDIT ? 'predefinidoscontabilidade.titles.selectColumnsEdit' : 'predefinidoscontabilidade.titles.selectColumns';
      this.titleCompaniesRight = 'predefinidoscontabilidade.titles.companiesToSave';
    }
    this.titleCompanies = title;
  }

  public changedDocContabPreview(value: IDocContabilidade): void {
    this.docContabilidadePreview = value;
  }

  public readonly fnBeforeChangedStep = (event: IPlNavWizardEventBeforeChange): Promise<boolean> => this._beforeChangedStep(event);

  public readonly fnFinalize: () => Promise<void> = () => this._finalize();

  public readonly fnSuggestCodeMultiEmpresa = (): Promise<void> => this._suggestCode(true);

  public readonly fnValidatorStep2: () => boolean = () => this._validatorStep2();

  public readonly fnValidatorStep3: () => boolean = () => this._validatorStep3();

  public readonly fnValidatorStep4: () => boolean = () => this._validatorStep4();

  private _beforeChangedStep({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (
      currentStep === this.definition.items[STEP_INDEX_BASIC] ||
      (currentStep === this.definition.items[STEP_INDEX_OPERATION] && nextStep === this.definition.items[STEP_INDEX_HEADER] && type === 'set')
    ) {
      if (this.operation === EContabilidadePredefinidosOperation.EDIT) {
        this.definition.force = true;
      }
      if (this.operation !== EContabilidadePredefinidosOperation.DELETE && (!nextStep || nextStep !== this.definition.items[STEP_INDEX_OPERATION])) {
        if (type !== 'previous' && !this._validatorStep2()) {
          return Promise.resolve(false);
        }
        let promise: Promise<unknown>;
        if (type !== 'previous') {
          promise = this._initStep3();
        }
        return Promise.resolve(promise).then((value: boolean) => {
          this.definition.items[STEP_INDEX_HEADER].visible = true;
          this.definition.items[STEP_INDEX_LINES].visible = true;
          this.definition.items[STEP_INDEX_PREVIEW].visible = true;
          this.definition.items[STEP_INDEX_COMPANIES].visible = true;
          return value;
        });
      }
      this.definition.items[STEP_INDEX_HEADER].visible = false;
      this.definition.items[STEP_INDEX_LINES].visible = false;
      this.definition.items[STEP_INDEX_PREVIEW].visible = false;
      this.definition.items[STEP_INDEX_COMPANIES].visible = true;
    } else if (nextStep === this.definition.items[STEP_INDEX_BASIC] || (currentStep === this.definition.items[STEP_INDEX_OPERATION] && type === 'next')) {
      return this._initStep2().then(() => true);
    }
    return Promise.resolve(true);
  }

  private _suggestCode(multiEmpresa: boolean): Promise<void> {
    return (!multiEmpresa ? this._contabilidadePredefinidosService.sugerirCodigo() : this._contabilidadePredefinidosService.sugerirCodigoMultiEmpresa()).then((response: HttpResponse<number>) => {
      this.model.preDefinidosID = Math.max(0, response.body);
    });
  }

  private _setOperationTitle(): void {
    this._toolTitleOperation.caption =
      this.operation !== EContabilidadePredefinidosOperation.EDIT || !this.model || !isNumber(this.model.preDefinidosID) || !this.model.descricao
        ? ''
        : `(${this.model.preDefinidosID} - ${this.model.descricao})`;
    this._toolTitleOperation.visible = Boolean(this._toolTitleOperation.caption);
  }

  private _reset(): void {
    this.operation = EContabilidadePredefinidosOperation.NEW;
    this.model = this._contabilidadePredefinidoService.emptyPredefinido();
    this.definition.force = false;
    this.isStep3Valid = false;
    this.isStep4Valid = false;
    if (this.definition.items.length) {
      this.definition.items[STEP_INDEX_HEADER].visible = false;
      this.definition.items[STEP_INDEX_LINES].visible = false;
      this.definition.items[STEP_INDEX_PREVIEW].visible = false;
      this.definition.items[STEP_INDEX_COMPANIES].visible = false;
    }
    if (isFunction(this.preDefinidoContabCab.resetPanels)) {
      this.preDefinidoContabCab.resetPanels();
    }
    this._setOperationTitle();
  }

  private _loadEmpresas(): void {
    const empresas: Array<IJsonErpUser> = this.session.erps;
    let loaded = 0;
    this.listaEmpresas = empresas;
    if (this.operation === EContabilidadePredefinidosOperation.EDIT || this.operation === EContabilidadePredefinidosOperation.DELETE) {
      if (!isArray(this.empresasSelecionadas)) {
        this.empresasSelecionadas = [];
      }
      for (let i = 0; i < this.empresasSelecionadas.length; i++) {
        const modelEmpresa: IJsonErpUser = this.empresasSelecionadas[i];
        const index = this.listaEmpresas.findIndex((accessEmpresa) => modelEmpresa.cgID === accessEmpresa.cgID);
        if (index === -1) {
          this.empresasSelecionadas.splice(i, 1);
        }
      }
      loaded = this.empresasSelecionadas.length;
    }
    if (!loaded || this.operation === EContabilidadePredefinidosOperation.NEW) {
      this.empresasSelecionadas = [this.session.erp];
    }
    this.listaEmpresas = this.listaEmpresas.filter((empresa) => {
      const selecionada = this.empresasSelecionadas.find((toCompare) => toCompare.cgID === empresa.cgID);
      return isUndefined(selecionada);
    });
    this.changedEmpresasSelecionadas();
  }

  private _clearDocContab(): void {
    this.docContabilidade = this._docContabilidadeService.emptyDoc();
  }

  private _setModel(preDefinidoContab: IPreDefinidoContab): void {
    const cgBanking: boolean = this.model.cgBanking;
    this.model = {
      preDefinidosID: this.model.preDefinidosID,
      descricao: this.model.descricao || preDefinidoContab.descricao,
      revision: preDefinidoContab.revision,
      cgBanking: preDefinidoContab.cgBanking,
      isGeneric: preDefinidoContab.isGeneric,
      cabecalho: preDefinidoContab.cabecalho,
      linhas: preDefinidoContab.linhas,
      empresasSelecionadas: preDefinidoContab.empresasSelecionadas,
      ordemColunas: preDefinidoContab.ordemColunas
    };
    if (this.operation === EContabilidadePredefinidosOperation.EDIT) {
      this.model.preDefinidosID = preDefinidoContab.preDefinidosID;
      this.model.cgBanking = cgBanking;
    }
  }

  private _initStep2(): Promise<void> {
    let promise: Promise<void>;
    if (this.operation !== EContabilidadePredefinidosOperation.EDIT && this.operation !== EContabilidadePredefinidosOperation.DELETE && !isNumber(this.model.preDefinidosID)) {
      promise = this._suggestCode(false);
      this._setPromise(promise);
    } else {
      promise = Promise.resolve();
    }
    Promise.resolve(promise).finally(() => {
      setTimeout(() => {
        const selector: string =
          this.operation === this.operations.EDIT || this.operation === this.operations.DELETE
            ? '.contabilidade-predefinidos-operation-basic input[name="codigo"]'
            : '.contabilidade-predefinidos-operation-basic input[name="descricao"]';
        focusElement(this._element.querySelector<HTMLInputElement>(selector));
      });
    });
    return promise ? promise : Promise.resolve();
  }

  private _initStep3(): Promise<boolean> {
    if (this.operation !== EContabilidadePredefinidosOperation.NEW) {
      if (this.operation === EContabilidadePredefinidosOperation.EDIT) {
        this._setOperationTitle();
      }
      return new Promise<boolean>((resolve) => {
        if (isFunction(this.preDefinidoContabCab.validatePanels)) {
          this.preDefinidoContabCab.validatePanels();
        }

        switch (this.operation) {
          case EContabilidadePredefinidosOperation.NEW_BASE_DOC:
            this._contabilidadePredefinidosService
              .getFromDocContabilidade(this.docContabilidade.extPocCabID)
              .then((response) => {
                this._setModel(response.body);
                resolve(true);
              })
              .catch(() => {
                this._plAlertService.error(
                  this._translateService.instant('predefinidoscontabilidade.errors.invalidDocContab', {
                    doc: this.docContabilidade.extPocCabID
                  })
                );
                resolve(false);
              });
            break;
          case EContabilidadePredefinidosOperation.NEW_BASE_PREDEFINIDO:
          case EContabilidadePredefinidosOperation.EDIT:
            this._updating = this.operation === EContabilidadePredefinidosOperation.EDIT;
            const id: number = this._updating ? this.model.preDefinidosID : this.preDefinidosIDToCopy;
            this._entityService
              .get({id: id})
              .then((response) => {
                this._setModel(response.body);
                resolve(true);
              })
              .catch(() => {
                resolve(false);
              });
            break;
          default:
            resolve(true);
            break;
        }
      });
    }
    return Promise.resolve(true);
  }

  private _initStep4(): void {
    const isStep4: boolean = this.step === this.definition.items[STEP_INDEX_PREVIEW];
    this.showPreview = isStep4;
    this._btnSimulateSave.visible = isStep4;
    this._btnClearDocContab.visible = isStep4;
  }

  private _validatorStep2(): boolean {
    if (this.formInstanceBasic) {
      return this.formInstanceBasic.valid;
    }
    return true;
  }

  private _validatorStep3(): boolean {
    if (!this.isStep3Valid) {
      this._plAlertService.warning('predefinidocontabcab.errorInvalid');
    }
    return this.isStep3Valid;
  }

  private _validatorStep4(): boolean {
    if (!this.isStep4Valid) {
      this._plAlertService.warning('predefinidocontablinhas.errorInvalid');
    }
    return this.isStep4Valid;
  }

  private _finalize(): Promise<void> {
    let promise: Promise<unknown>;

    switch (this.operation) {
      case EContabilidadePredefinidosOperation.EDIT:
        promise = this._entityService.put({id: this.model.preDefinidosID, body: this.model, params: {tipooperacao: this.operation}});
        break;
      case EContabilidadePredefinidosOperation.DELETE:
        promise = this._entityService.delete({id: this.model.preDefinidosID, body: this.model.empresasSelecionadas});
        break;
      default:
        promise = this._entityService.post({body: this.model, params: {tipooperacao: this.operation}});
        break;
    }
    return promise.then(() => {
      const operation = this.operation;
      let reloadPromise: Promise<void>;
      if (!this.maintenanceMode) {
        reloadPromise = this._stateService.reload().then(() => undefined);
      }
      return Promise.resolve(reloadPromise).finally(() => {
        switch (operation) {
          case EContabilidadePredefinidosOperation.EDIT:
            this._plAlertService.success(this._translateService.instant('predefinidoscontabilidade.success.edit', {id: this.model.preDefinidosID}));
            break;
          case EContabilidadePredefinidosOperation.DELETE:
            this._plAlertService.success(this._translateService.instant('predefinidoscontabilidade.success.delete', {id: this.model.preDefinidosID}));
            break;
          default:
            this._plAlertService.success('predefinidoscontabilidade.success.save');
            break;
        }
      });
    });
  }

  private _setPromise(value: Promise<void>): void {
    this.disableForm = true;
    Promise.resolve(value).finally(() => {
      this.disableForm = false;
    });
  }

  private _loadDocContabilidadeByExtPocCabId(extPocCabId): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._serviceDocsContabilidade
        .get({id: extPocCabId})
        .then((response: HttpResponse<IDocContabilidade>) => {
          this.docContabilidade = response.body;
          resolve();
        })
        .catch(reject);
    });
  }

  private _clearForSaveEventListener(): void {
    this._docContabilidadeService.clearForSaveEventListener(SAVE_PROMPT_IDENTIFIER).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }
}
