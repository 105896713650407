import {IJsonFatEletronicaConfDocfa, IJsonFaturacaoEletronicaConfig} from './jsonFaturacaoEletronicaConfiguracoes.module.interface';

export enum ETipoDocumentoStatus {
  SemFaturacaoEletronica = 'semFaturacaoEletronica',
  ComFaturacaoEletronica = 'comFaturacaoEletronica'
}

export enum EFatEletronicaConfTab {
  TiposDocumento = 'tiposdocumento',
  Clientes = 'clientes',
  Certificado = 'certificado'
}

export enum EFatEletronicaConfCertificadoPill {
  Safe = 'safe',
  SAFETestes = 'safetestes',
  DigitalSign = 'digitalsign',
  DigitalSignTestes = 'digitalsigntestes'
}

export enum EFatEletronicaConfCertificadoSafeTab {
  Certificados = 'certificados',
  Pendentes = 'pendentes'
}

export interface IFatEletronicaConfStateParams {
  tab?: EFatEletronicaConfTab;
  certificadoPill?: EFatEletronicaConfCertificadoPill;
  safe?: IFatEletronicaConfStateParamsSAFE;
  safeTestes?: IFatEletronicaConfStateParamsSAFE;
  digitalSign?: IFatEletronicaConfStateParamsDigitalSign;
  digitalSignTestes?: IFatEletronicaConfStateParamsDigitalSign;
}

export interface IFatEletronicaConfStateParamsSAFE {
  nomeDaConta: string;
  accessToken: string;
  safetyKey: string;
  expiresIn: number;
  obterAtributos: boolean;
  ambientePreProducao: boolean;
}

export interface IFatEletronicaConfStateParamsDigitalSign {
  nomeAutorizador: string;
  code: string;
  safetyKey: string;
  authenticate: boolean;
  ambientePreProducao: boolean;
  idAutorizador: string;
  authorizerSecretDigitalSign: string;
}

export interface IFatEletronicaConfDocfa extends IJsonFatEletronicaConfDocfa {
  status?: ETipoDocumentoStatus;
}

export interface IFaturacaoEletronicaConfig extends IJsonFaturacaoEletronicaConfig {
  tiposDocumento: Array<IFatEletronicaConfDocfa>;
}

export interface IFaturacaoEletronicaGuia {
  caption: string;
  info: string;
  url: string;
}

export const MODULE_NAME_FATURACAO_ELETRONICA_CONFIGURACOES = 'faturacaoeletronicaconfiguracoes';
