import {Subscription} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';
import {StateService} from '@uirouter/core';
import {AppService} from '../../../services/app/app.service';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {CGStateService} from '../../../components/state/cg.state.service';
import {ConfigService} from '../../../services/config/config.service';
import {IAppStatus} from '../../../services/app/app.service.interface';
import {PortalChangeFirmModalComponent} from '../../../components/portal/modals/changefirm/portal.changefirm.modal.component';
import {STATE_NAME_LOGIN} from '../login/login.state.interface';
import {EAppLaunchMode} from '../../../../common/site';

@Component({
  selector: 'empresa-bloqueada',
  templateUrl: './empresabloqueada.state.component.html'
})
export class EmpresaBloqueadaComponent implements OnDestroy {
  public motivo: string;
  public partnerMode: boolean;
  public hybridMode: boolean;

  private readonly _subscriptionStatus: Subscription;

  constructor(
    private readonly _stateService: StateService,
    private readonly _cgStateService: CGStateService,
    private readonly _appService: AppService,
    private readonly _configService: ConfigService,
    private readonly _cgModalService: CGModalService
  ) {
    this.partnerMode = false;
    this.hybridMode = false;
    this.motivo = this._configService.configurations.empresa.motivoislocked;
    this._subscriptionStatus = this._appService.status().subscribe((status: IAppStatus) => {
      this.partnerMode = status.launchMode === EAppLaunchMode.Partner;
      this.hybridMode = status.launchMode === EAppLaunchMode.Hybrid || status.launchMode === EAppLaunchMode.HybridPartial;
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionStatus.unsubscribe();
  }

  public readonly fnBack: () => Promise<void> = () => this._back();

  public readonly fnLogin: () => Promise<void> = () => this._login();

  public readonly fnMudaEmpresa: () => Promise<void> = () => this._mudaEmpresa();

  private _back(): Promise<void> {
    return this._cgStateService.goBack();
  }

  private _login(): Promise<void> {
    return this._stateService.go(STATE_NAME_LOGIN).then(() => undefined);
  }

  private _mudaEmpresa(): Promise<void> {
    return this._cgModalService.show(PortalChangeFirmModalComponent).then(() => {
      return this._cgStateService.goHome();
    });
  }
}
