<div class="mb-configs entity-detail-form">
  <pl-alert type="info" [closeable]="false" [seekAttention]="seekAttention">
    <span [innerHTML]="'mbconfigs.messages.infogeral' | translate"></span>
    <br />
    <a class="btn btn-sm btn-primary" href="https://www.easypay.pt/pt/aderir/centralgest" target="_blank" rel="noopener noreferrer" [translate]="'mbconfigs.btn.criareasypay'"></a>
  </pl-alert>

  <div class="form-control-danger mb-2" *ngIf="mbConfigs.stateService">
    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;<span>{{ mbConfigs.stateService }}</span>
  </div>

  <pl-form [properties]="{orientation: 'horizontal'}">
    <pl-group>
      <label [translate]="'mbconfigs.fields.estado'"></label>
      <edit>
        <pl-edit-switch attrName="estado" [model]="mbConfigs.ativo" (modelChange)="mbConfigs.ativo = $event; ativoChanged()" [label]="captionEstado"></pl-edit-switch>
      </edit>
    </pl-group>

    <pl-group [properties]="{disabled: !mbConfigs.ativo}">
      <label [translate]="'mbconfigs.fields.dataInicioRef'"></label>
      <edit>
        <pl-edit-datepicker attrName="dataInicioRef" [(model)]="mbConfigs.dataInicioRef"></pl-edit-datepicker>
      </edit>
    </pl-group>

    <pl-group>
      <label>
        <pl-tooltip class="tooltip-info-label" [config]="{text: 'mbconfigs.messages.nDiasValidadeReferenciaMB', placement: ['bottom', 'left']}">
          <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
        </pl-tooltip>
        <span [translate]="'mbconfigs.fields.nDiasValidadeReferenciaMB'"></span>
      </label>
      <edit>
        <pl-edit type="integer" attrName="nDiasValidadeReferenciaMB" [(model)]="mbConfigs.nDiasValidadeReferenciaMB"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <edit>
        <pl-edit-checkbox
          [(model)]="mbConfigs.geraRefMovimentoAberto"
          attrName="lancarTransfComValorSaldo"
          [label]="'mbconfigs.fields.geraRefMovimentoAberto'"
          [properties]="{disabled: !mbConfigs.ativo}">
        </pl-edit-checkbox>
      </edit>
    </pl-group>

    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="tiposdocpossiveis"
      [dataSource]="dataGridDefinition.dataSource"
      [(selectedRowKeys)]="selectedRowKeys"
      (onContentReady)="onContentReady()"
      (onInitialized)="onInitialized($event)">
      <div *dxTemplate="let item of 'toolbarGridTemplate'">
        <strong><span [translate]="'mbconfigs.messages.selectDocFas'"></span></strong>
      </div>
    </dx-data-grid>

    <hr />

    <h5 [translate]="'mbconfigs.messages.fornecedorRefMult'"></h5>
    <div [translate]="'mbconfigs.messages.functioning'"></div>

    <pl-group [properties]="{disabled: !mbConfigs.ativo}">
      <pl-group>
        <label [translate]="'mbconfigs.fields.accountID'"></label>
        <edit>
          <pl-edit-text attrName="apiId" [(model)]="mbConfigs.apiId"></pl-edit-text>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'mbconfigs.fields.apiKey'"></label>
        <edit>
          <pl-edit-password attrName="apiKey" [(model)]="mbConfigs.apiKey"></pl-edit-password>
        </edit>
      </pl-group>

      <pl-button klass="btn-sm btn-primary" id="btnTestCredentials" [onClick]="testCredentials" [disabled]="!mbConfigs.apiId || !mbConfigs.apiKey">
        <i class="fa fa-fw fa-key"></i>&nbsp;<span [translate]="'mbconfigs.btn.testConnection'"></span>
      </pl-button>
    </pl-group>

    <div class="form-control-info"><i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'mbconfigs.messages.dataEasyPay'"></span></div>

    <br />

    <pl-tooltip [config]="{text: 'mbconfigs.fields.enviarreciboTooltip'}" style="width: 50%">
      <pl-edit-checkbox *plTooltipContent attrName="enviarRecibosEmail" [(model)]="mbConfigs.enviaRecibos" [label]="'mbconfigs.fields.enviarrecibo'" [properties]="{disabled: !mbConfigs.ativo}">
      </pl-edit-checkbox>
    </pl-tooltip>

    <ng-container *ngIf="showAdvancedMode">
      <hr />

      <pl-group [properties]="{disabled: !mbConfigs.ativo}">
        <edit>
          <pl-edit type="boolean" [(model)]="advancedMode" attrName="advancedMode" [properties]="{label: 'mbconfigs.fields.advancedMode'}"></pl-edit>
        </edit>
      </pl-group>

      <div class="form-control-info">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        <span class="btn" [translate]="'mbconfigs.messages.advancedModeInfo'"></span>
      </div>

      <cg-card-panel [caption]="'mbconfigs.titles.pagRec'" [hideThumb]="true" *ngIf="advancedMode">
        <div *cgCardPanelContent>
          <pl-group [properties]="{disabled: !mbConfigs.ativo}">
            <label [translate]="'mbconfigs.fields.contaTransf'"></label>
            <edit>
              <pl-tooltip [config]="{text: 'mbconfigs.tooltips.contaTransferencia'}">
                <entity-autocomplete
                  *plTooltipContent
                  entity="pocs"
                  attrName="contaTransf"
                  [model]="mbConfigs"
                  (selectedKeyChange)="mbConfigs.ncontaTransferencia = $event"
                  (evtSelectedDescriptionChanged)="mbConfigs.nomeContaTransferencia = $event"
                  [fieldsMap]="{nConta: 'ncontaTransferencia', nome: 'nomeContaTransferencia'}">
                </entity-autocomplete>
              </pl-tooltip>
            </edit>
          </pl-group>

          <pl-group [properties]="{disabled: !mbConfigs.ativo}">
            <label [translate]="'mbconfigs.fields.contaComissao'"></label>
            <edit>
              <pl-tooltip [config]="{text: 'mbconfigs.tooltips.contaComissao'}">
                <entity-autocomplete
                  *plTooltipContent
                  entity="pocs"
                  attrName="contaComissao"
                  [model]="mbConfigs"
                  (selectedKeyChange)="mbConfigs.ncontaComissao = $event"
                  (evtSelectedDescriptionChanged)="mbConfigs.nomeContaComissao = $event"
                  [fieldsMap]="{nConta: 'ncontaComissao', nome: 'nomeContaComissao'}">
                </entity-autocomplete>
              </pl-tooltip>
            </edit>
          </pl-group>

          <pl-group [properties]="{disabled: !mbConfigs.ativo}">
            <label [translate]="'mbconfigs.fields.contaTransitoriaTransf'"></label>
            <edit>
              <pl-tooltip [config]="{text: 'mbconfigs.tooltips.contaTransitoria'}">
                <entity-autocomplete
                  *plTooltipContent
                  entity="pocs"
                  attrName="contaTransitoriaTransf"
                  [model]="mbConfigs"
                  (selectedKeyChange)="mbConfigs.ncontaTransTransf = $event"
                  (evtSelectedDescriptionChanged)="mbConfigs.nomeContaTransTransf = $event"
                  [fieldsMap]="{nConta: 'ncontaTransTransf', nome: 'nomeContaTransTransf'}">
                </entity-autocomplete>
              </pl-tooltip>
            </edit>
          </pl-group>

          <pl-group [properties]="{disabled: !mbConfigs.ativo}">
            <label [translate]="'mbconfigs.fields.diario'"></label>
            <edit>
              <entity-autocomplete
                entity="diarios"
                attrName="diario"
                [model]="mbConfigs"
                (selectedKeyChange)="mbConfigs.nDiario = $event"
                (evtSelectedDescriptionChanged)="mbConfigs.nomeDiario = $event"
                [fieldsMap]="{nDiario: 'nDiario', nome: 'nomeDiario'}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group [properties]="{disabled: !mbConfigs.ativo}">
            <label [translate]="'mbconfigs.fields.descritivo'"></label>
            <edit>
              <entity-autocomplete
                entity="descritivos"
                attrName="descritivo"
                [model]="mbConfigs"
                (selectedKeyChange)="mbConfigs.nDescritivo = $event"
                (evtSelectedDescriptionChanged)="mbConfigs.nomeDescritivo = $event"
                [fieldsMap]="{nDescrit: 'nDescritivo', nome: 'nomeDescritivo'}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </div>
      </cg-card-panel>
    </ng-container>
  </pl-form>
</div>
