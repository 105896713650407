import {ROLE} from '../../../services/role.const';
import {ServicosComponent} from './components/servicos.module.component';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';

export const MODULE_COLABORADORES_SERVICOS: IModuleDefinition = {
  name: 'colaboradoresservicos',
  state: {
    url: '/servicos',
    component: ServicosComponent,
    data: {
      roles: [ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS, ROLE.COLABORADOR],
      pageTitle: 'global.menu.colaboradoresservicos'
    }
  }
};
