import {Injectable} from '@angular/core';
import {ContabilidadeDigitalUIService} from '../contabilidadedigital/contabilidadedigital.ui.service';

@Injectable({
  providedIn: 'root'
})
export class ClientConnectUIService extends ContabilidadeDigitalUIService {
  protected _buildPath(): string {
    return `${this._apiService.path.restapi}/clientconnect`;
  }
}
