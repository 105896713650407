import {TDate} from '../../../../common/dates';
import {IJsonDGEMPFull} from '../dgempsfull/jsonDGEMPFull.interface';

export const MODULE_NAME_RH_REGISTAR_EMP_SS = 'rhregistarempregadosss';

export enum ERHRegistarEmpregadosSSEmpSegSocialVinculo {
  NaoVerificado,
  SemVinculo,
  Admitido,
  Cessado,
  NaoProcessadosSegSocial
}

export enum ERHRegistarEmpregadosSSRegistarTabIdEnum {
  Registar,
  Cessar,
  RegistarPeriodoRendimento
}

export enum ERHRegistarEmpregadosSSContratoModalidadeEnum {
  NONE,
  D,
  E,
  EA,
  EB,
  F,
  FA,
  FB,
  G,
  H,
  I,
  J,
  K,
  L,
  M,
  N,
  O,
  P,
  Q,
  R,
  S,
  C,
  B,
  A,
  AA,
  AB,
  BA,
  BB,
  GA,
  GB,
  HA,
  HB
}

export enum ERHRegistarEmpregadosSSContratoMotivoEnum {
  NONE,
  AEAT,
  ATSA,
  CTSD,
  EOPA,
  EXTO,
  IFEE,
  LNAT,
  STAJ,
  STAT,
  STLR,
  STTC
}

export enum ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum {
  P,
  T
}

export enum ERHRegistarEmpregadosSSMotivoCessacao {
  NONE,
  CCAI,
  CCCT,
  CCEE,
  CCFM,
  CCMT,
  CCRI,
  CCRV,
  CDT,
  IECC,
  IEDC,
  IEEX,
  IEIN,
  IEJC,
  IEPE,
  IIAT,
  IIDD,
  IIDE,
  IIJC,
  IISA,
  RADC,
  RANE,
  RAOT,
  RARC,
  RARD,
  RARE,
  RARR,
  TE
}

export interface IRHRegistarEmpregadosSSFilterModel {
  deCodEmp: number;
  ateCodEmp: number;
  dataIni: TDate;
  dataFim: TDate;
}

export interface IRHRegistarEmpregadosSSVinculosEmpregadoItem {
  dataFimVinculo: TDate;
  nometrabalhador: string;
  datanascimento: TDate;
  codemp: number;
  valortaxa: number;
  fimaplicacaotaxa: TDate;
  inicioaplicacaoTaxa: TDate;
  vinculo: string;
  fimdoVinculo: TDate;
  localdetrabalho: string;
  dataIniVinculo: TDate;
  iniciodoVinculo: TDate;
  niss: string;
  vinculocomunicadoem: TDate;
}

export interface IRHRegistarEmpregadosSSContratosBase {
  codemp: number;
  nissTrabalhador: number;
  codmodalidadeContrato: string;
  codprestacaoTrabalho: string;
  inicioContrato: TDate;
  fimContrato: TDate;
  codprofissao: string;
  remuneracaoBase: number;
  diuturnidades: number;
  percentagemTrabalho: number;
  horasTrabalho: number;
  diasTrabalho: number;
  codmotivoContrato: string;
  nissTrabalhadorSubstituir: number;
  nissTrabalhadorSubstituirNome: string;
}

export interface IRHRegistarEmpregadosSSContratosItem extends IRHRegistarEmpregadosSSContratosBase {
  prestacaoTrabalho: string;
  motivoContrato: string;
  nomeTrabalhadorSubstituir: string;
  nomeTrabalhador: string;
  fimInformacaoContrato: TDate;
  inicioInformacaoContrato: TDate;
  modalidadeContrato: string;
  profissao: string;
  isContratoEnviadoParaSS: boolean;
  temErros: boolean;
  descErros: string;
}

export interface IRHRegistarEmpregadosSSContratosDataGridItem extends IRHRegistarEmpregadosSSContratosItem {
  modalidadeString: string;
  prestacaoTrabalhoString: string;
  profissaoString: string;
  motivoString: string;
}

export interface IRHRegistarEmpregadosSSRegistarContratoResult {
  codemp: number;
  isContratoEnviadoParaSS: boolean;
  temErros: boolean;
  descErros: string;
}

export interface IRHRegistarEmpregadosSSRegistarContratoResponse {
  success: boolean;
  errorMessage: string;
  results: Array<IRHRegistarEmpregadosSSRegistarContratoResult>;
}

export interface IRHRegistarEmpregadosSSOperationResponse {
  success: boolean;
  infoErrorMessage: string;
}

export interface IRHRegistarEmpregadosSSRegistarEmpregadoSSData {
  codEmp: number;
  defContratoSegSocial: string;
  motiSS: string;
  prestacaoTrabalhoSS: string;
  vencimentoBase: number;
  diuturnidades: number;
  percTrabalhoCtrSS: number;
  horasTrabalhoCtrSS: number;
  diasTrabalhoCtrSS: number;
  codEmpSubstituir: number;
}

export interface IRHRegistarEmpregadosSSCessarVinculoEmpregadoSSData {
  codEmp: number;
  dataFimVinculo: TDate;
  motivoFimVinculoSS: string;
  comunicacaoDesemprego: boolean;
  fundamentacao: string;
}

export interface IRHRegistarEmpregadosSSRegistarPeriodoRendimentoData {
  codEmp: number;
  novaDataVinculoRendimento: TDate;
  novoVencimento: number;
  novaDiuturnidade: number;
}

export interface IRHRegistarEmpregadosSSConfiguracaoWS {
  ssVinculosTrabalhadoresNISS: string;
  ssVinculosTrabalhadoresPassword: string;
  ssWSConfigurado: boolean;
}

export interface IRHRegistarEmpregadosSSEmpregadoInfo {
  dtAdmissao: TDate;
  niss: string;
  localEstabelec: string;
  tipoContrTrab: string;
  dtAssinadoEm: TDate;
  dtValidoAte: TDate;
  vencimentoBase: number;
  diuturnidades: number;
  modalidadeCtrSS: string;
  motivoCtrSS: string;
  prestacaoTrabalhoSS: string;
  percTrabalhoCtrSS: number;
  horasTrabalhoCtrSS: number;
  diasTrabalhoCtrSS: number;
  empregadoSubsttituitSSctr: number;
  podeRegistar: boolean;
  podeCessar: boolean;
  podeRegistarPeriodoRendimento: boolean;
  podeVerRegistar: boolean;
  podeVerCessar: boolean;
  podeVerRegistarPeriodoRendimento: boolean;
  activePage: number;
  dataFimVinculoCessacao: TDate;
  dtValidoAteVisible: boolean;
  infoEmpregadoRegistado: string;
  motivoCessacao: string;
  ssDataNovoRegistoPeriodo: TDate;
  ssVencimentoRegistoPeriodo: number;
  ssDiuturnidadeRegistoPeriodo: number;
  infoEstadoSegSocial: ERHRegistarEmpregadosSSEmpSegSocialVinculo;
  infoAvisosErros: string;
  modalidadeCtrSSEnum: ERHRegistarEmpregadosSSContratoModalidadeEnum;
  motivoCtrSSEnum: ERHRegistarEmpregadosSSContratoMotivoEnum;
  prestacaoTrabalhoSSEnum: ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum;
  motivoCessacaoEnum: ERHRegistarEmpregadosSSMotivoCessacao;
  comunicaDesemprego: boolean;
  fundamentacao: string;
}

export interface IRHRegistarEmpregadosSSUIHelper {
  motivoCtrSSEnabled: boolean;
  dtValidoAteVisible: boolean;
  percTrabalhoCtrSSEnabled: boolean;
  horasTrabalhoCtrSSEnabled: boolean;
  diasTrabalhoCtrSSEnabled: boolean;
  empregadoSubsttituitSSctrEnabled: boolean;
  fundamentacaoCessacaoEnabled: boolean;
}

export enum ERHRegistarEmpregadosSSConfiguracaoWSSteps {
  STEP_ONE,
  STEP_TWO,
  STEP_TREE
}

export interface IRHRegistarEmpregadosSSEditarContratoModel extends IRHRegistarEmpregadosSSContratosBase {
  codprestacaoTrabalhoEnum: ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum;
  codmotivoContratoEnum: ERHRegistarEmpregadosSSContratoMotivoEnum;
  codmodalidadeContratoRnum: ERHRegistarEmpregadosSSContratoModalidadeEnum;
}

export interface IRHRegistarEmpregadosSSParams {
  dgemp: IJsonDGEMPFull;
  registerTabActive: boolean;
}
