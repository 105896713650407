import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonDocfaNumATConsulta} from '../../jsonDocfaNum.entity.interface';

@Component({
  selector: 'consultar-serie-at-modal',
  templateUrl: './consultaSerieAt.modal.component.html'
})
export class ConsultaSerieAtModalComponent extends CGModalComponent<void> {
  @Input() public readonly model: IJsonDocfaNumATConsulta;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }
}
