import {IConciliacaoBancariaBancoCab} from '../../../modules/portalcontabilidade/conciliacaobancaria/conciliacaoBancaria.module.interface';
import {ITreeCallback} from '../../treeviewer/treeviewer.interface';

export enum EConciliacaoBancariaBancosTreeViewerNodeType {
  Folder,
  Item
}

export interface IConciliacaoBancariaBancosTreeViewerItemData extends IConciliacaoBancariaBancoCab {
  nodeType?: EConciliacaoBancariaBancosTreeViewerNodeType;
}

export interface IConciliacaoBancariaBancosTreeViewerCallback extends ITreeCallback {
  generateNodeId?(nConta: string, periodo?: string): string;

  reload?(): Promise<void>;
}
