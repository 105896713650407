import {Ng2StateDeclaration} from '@uirouter/angular';
import {STATE_NAME_OAUTH2, STATE_URL_OAUTH2} from './oauth2.state.interface';
import {STATE_NAME_PORTAL} from '../../services/portals/portals.service.interface';
import {STATE_NAME_SITE} from '../../../common/site';

export const STATE_OAUTH2: Ng2StateDeclaration = {
  parent: STATE_NAME_SITE,
  name: STATE_NAME_OAUTH2,
  url: `/${STATE_URL_OAUTH2}`,
  redirectTo: STATE_NAME_PORTAL
};
