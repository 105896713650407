import {Injectable} from '@angular/core';
import {isEmpty, isNumber} from 'pl-comps-angular';
import {IJsonBoolean} from '../../../common/interfaces/json';
import {ApiService} from '../../services/api/api.service';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {
  IJsonAssistenteTrabAndMascaras,
  IJsonConfigRHDadosDGEMP,
  IJsonConfigRHDadosFicha,
  IJsonConfigRHDGEMPRequiredFields,
  IJsonConfigRHGozoFerias,
  IJsonConfigRHManutencaoCessacaoContrato,
  IJsonConfigRHTransfBancarias,
  IJsonConfigRMMG,
  IJsonMascaraCCustoPorCategoria,
  IJsonProcessamentoSalariosConfig,
  IJsonSegSocialFolhaFerias
} from './jsonRHConfiguracoes.module.interface';

@Injectable({
  providedIn: 'root'
})
export class RHConfiguracoesService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/configsrh`;
  }

  public getDadosFicha(): TServiceResponse<IJsonConfigRHDadosFicha> {
    return this._apiService.get<IJsonConfigRHDadosFicha>({url: `${this._path}/dadosficha`});
  }

  public getManutencaoCessacaoContrato(): TServiceResponse<IJsonConfigRHManutencaoCessacaoContrato> {
    return this._apiService.get<IJsonConfigRHManutencaoCessacaoContrato>({url: `${this._path}/manutencaocessacaocontrato`});
  }

  public getProcessamentoSalarios(): TServiceResponse<IJsonProcessamentoSalariosConfig> {
    return this._apiService.get<IJsonProcessamentoSalariosConfig>({url: `${this._path}/processamentosalarios`});
  }

  public getDadosFichaRecomendados(): TServiceResponse<IJsonConfigRHDadosFicha> {
    return this._apiService.get<IJsonConfigRHDadosFicha>({url: `${this._path}/dadosficharecomendados`});
  }

  public getDadosDGEMP(): TServiceResponse<IJsonConfigRHDadosDGEMP> {
    return this._apiService.get<IJsonConfigRHDadosDGEMP>({url: `${this._path}/dadosdgemp`});
  }

  public getAssistenteTrabalhadorMascaraAbono(): TServiceResponse<Array<IJsonMascaraCCustoPorCategoria>> {
    return this._apiService.get<Array<IJsonMascaraCCustoPorCategoria>>({url: `${this._path}/assistentetrabalhadormascaraabono`});
  }

  public getAssistenteTrabalhadorMascaraDesconto(): TServiceResponse<Array<IJsonMascaraCCustoPorCategoria>> {
    return this._apiService.get<Array<IJsonMascaraCCustoPorCategoria>>({url: `${this._path}/assistentetrabalhadormascaradesconto`});
  }

  public getRequiredFields(): TServiceResponse<IJsonConfigRHDGEMPRequiredFields> {
    return this._apiService.get<IJsonConfigRHDGEMPRequiredFields>({url: `${this._path}/assistentetrabalhador`});
  }

  public getHasRequiredFields(): TServiceResponse<IJsonBoolean> {
    return this._apiService.get<IJsonBoolean>({url: `${this._path}/hasrequiredfields`});
  }

  public getConfigTransfBancarias(): TServiceResponse<IJsonConfigRHTransfBancarias> {
    return this._apiService.get<IJsonConfigRHTransfBancarias>({url: `${this._path}/transfbacancarias`});
  }

  public getConfigGozoFerias(): TServiceResponse<IJsonConfigRHGozoFerias> {
    return this._apiService.get<IJsonConfigRHGozoFerias>({url: `${this._path}/gozoferias`});
  }

  public getSegSocialFolhaFerias(): TServiceResponse<IJsonSegSocialFolhaFerias> {
    return this._apiService.get<IJsonSegSocialFolhaFerias>({url: `${this._path}/segsocialfolhaferias`});
  }

  public getConfigRMMG(): TServiceResponse<IJsonConfigRMMG> {
    return this._apiService.get<IJsonConfigRMMG>({url: `${this._path}/rmmg`});
  }

  public processarRMMG(): TServiceResponse<void> {
    return this._apiService.get<void>({url: `${this._path}/processar/rmmg`});
  }

  public updateDadosFicha(dadosficha: IJsonConfigRHDadosFicha): TServiceResponse<IJsonConfigRHDadosFicha> {
    return this._apiService.put<IJsonConfigRHDadosFicha>({url: `${this._path}/dadosficha`, body: dadosficha});
  }

  public updateProcessamentoSalarios(processamentoalariosconfig: IJsonProcessamentoSalariosConfig): TServiceResponse<IJsonProcessamentoSalariosConfig> {
    return this._apiService.put<IJsonProcessamentoSalariosConfig>({url: `${this._path}/processamentosalarios`, body: processamentoalariosconfig});
  }

  public updateSegSocialFolhaFerias(segsocialfolhaferiasconfig: IJsonSegSocialFolhaFerias): TServiceResponse<IJsonSegSocialFolhaFerias> {
    return this._apiService.put<IJsonSegSocialFolhaFerias>({url: `${this._path}/segsocialfolhaferias`, body: segsocialfolhaferiasconfig});
  }

  public updateManutencao(configrhmanutencaocessacaocontrato: IJsonConfigRHManutencaoCessacaoContrato): TServiceResponse<IJsonConfigRHManutencaoCessacaoContrato> {
    return this._apiService.put<IJsonConfigRHManutencaoCessacaoContrato>({
      url: `${this._path}/manutencaocessacaocontrato`,
      body: configrhmanutencaocessacaocontrato
    });
  }

  public updateRequiredFields(assistenteTrabAndMascaras: IJsonAssistenteTrabAndMascaras): TServiceResponse<IJsonAssistenteTrabAndMascaras> {
    return this._apiService.put<IJsonAssistenteTrabAndMascaras>({url: `${this._path}/assistentetrabalhadorsave`, body: assistenteTrabAndMascaras});
  }

  public updateConfigTransfBancarias(jsonConfigRHTransfBancarias: IJsonConfigRHTransfBancarias): TServiceResponse<IJsonConfigRHTransfBancarias> {
    return this._apiService.put<IJsonConfigRHTransfBancarias>({url: `${this._path}/transfbacancarias`, body: jsonConfigRHTransfBancarias});
  }

  public updateConfigGozoFerias(jsonConfigRHGozoFerias: IJsonConfigRHGozoFerias): TServiceResponse<IJsonConfigRHGozoFerias> {
    return this._apiService.put<IJsonConfigRHGozoFerias>({url: `${this._path}/gozoferias`, body: jsonConfigRHGozoFerias});
  }

  public updateRMMGConfigs(configrmmg: IJsonConfigRMMG): TServiceResponse<IJsonConfigRMMG> {
    return this._apiService.put<IJsonConfigRMMG>({url: `${this._path}/rmmg`, body: configrmmg});
  }

  public hasDadosFicha({
    motivoRegisto,
    situacaoEmprego,
    nCodABVencimento,
    nCodABSubFerias,
    nCodABSubNatal,
    nCodABSubAlimentacao,
    nCodABDiuturnidades,
    nCodDescIRS,
    localTrabalho,
    tipoHorarioTrabalho,
    nMaxDiasUteisFerias,
    nDiasTemDireitoFerias,
    nMaxDiasUteisNatal,
    nDiasTemDireitoNatal,
    residenciaFiscal
  }: IJsonConfigRHDadosFicha): boolean {
    return (
      !isEmpty(motivoRegisto) &&
      !isEmpty(situacaoEmprego) &&
      !isEmpty(nCodABVencimento) &&
      !isEmpty(nCodABSubFerias) &&
      !isEmpty(nCodABSubNatal) &&
      !isEmpty(nCodABSubAlimentacao) &&
      !isEmpty(nCodABDiuturnidades) &&
      !isEmpty(nCodDescIRS) &&
      isNumber(localTrabalho) &&
      !isEmpty(tipoHorarioTrabalho) &&
      isNumber(nMaxDiasUteisFerias) &&
      isNumber(nDiasTemDireitoFerias) &&
      isNumber(nMaxDiasUteisNatal) &&
      isNumber(nDiasTemDireitoNatal) &&
      isNumber(residenciaFiscal)
    );
  }
}
