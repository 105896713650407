import {IJsonDocComercial, IJsonDocComercialCab} from '../../jsonDocComercial.entity.interface';
import {TTableLegend} from '../../../../components/tablelegend/tablelegend.component.interface';
import {deepFreeze} from 'pl-comps-angular';

export interface IGestaoDocsComerciaisGrid extends IJsonDocComercialCab {
  hasError?: boolean;
  mensagemErro?: string;
  _thedoc?: IJsonDocComercial;
}

export enum EGestaoDocsComerciaisTableLegendColors {
  DocumentoComErro = 'documento-com-erro'
}

export const TABLE_LEGEND_GESTAO_DOCS_COMERCIAIS: TTableLegend = deepFreeze([
  {caption: 'gestaoDocsComerciais.legend.documentoComErro', badgeCSSClass: EGestaoDocsComerciaisTableLegendColors.DocumentoComErro}
]);
