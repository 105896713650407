import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_DASHBOARD_ATIVOS} from './dashboardAtivos.module.interface';
import {DashboardAtivosModuleComponent} from './components/dashboardAtivos.module.component';

export const MODULE_DASHBOARD_ATIVOS: IModuleDefinition = {
  name: MODULE_NAME_DASHBOARD_ATIVOS,
  state: {
    url: '/dashboard',
    component: DashboardAtivosModuleComponent,
    data: {
      roles: [ROLE.ATIVOS],
      pageTitle: 'global.menu.dashboard',
      icon: 'fa-bar-chart'
    }
  }
};
