import {EEstadoRegisto} from '../../../datasources/estadoregisto/estadoRegisto.datasource.interface';
import {EEstadoConta} from '../../../datasources/estadoconta/estadoConta.datasource.interface';

export enum ERHAgenciasTipoInstalacao {
  Sede,
  Agencia
}

export interface IJsonRHAgenciasConta {
  codBanco: string;
  codAgencia: string;
  nib: string;
  nContaPoc: string;
  gestorBanco: string;
  descricao: string;
  nProjecto: string;
  iban: string;
  swift: string;
  debitoDirectoID: string;
  nContaEmprestimos: string;
  codPais: number;
  status: EEstadoConta;
  validaIBAN: boolean;
  nomeProjecto: string;
  nomeNconta: string;
  nomeEmprestimos: string;
  nomePais: string;
  nomeBanco: string;
  nomeAgencia: string;
}

export interface IJsonRHAgenciasAgencia {
  codAgencia: string;
  codBanco: string;
  nome: string;
  designaBreve: string;
  morada: string;
  codPostal: string;
  localidade: string;
  telefone: string;
  fax: string;
  contacto: string;
  nContAgencia: string;
  tipoInstalac: ERHAgenciasTipoInstalacao;
  estado: EEstadoRegisto;
  nomeLocalidade: string;
  nomeCodPostal: string;
  nomeBanco: string;
  contasList: Array<IJsonRHAgenciasConta>;
}
