import {merge} from 'lodash-es';
import {Injector} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {isBoolean, isDefined, isObject, Logger, PlTranslateService} from 'pl-comps-angular';
import {CGModalService} from '../../../cg/modal/cgmodal.service';
import {CGStateService} from '../../../state/cg.state.service';
import {ICGModalOptions} from '../../../cg/modal/cgmodal.interface';
import {ICGStateDeclaration} from '../../../../services/portals/portals.service.interface';
import {IModuleMaintenanceInstance, IModuleMaintenanceModalOptions} from './module.maintenance.interface';
import {IModule} from '../../../module/module.definition.interface';
import {MaintenanceWindowService} from '../maintenance.window.service';
import {ModuleMaintenanceModalComponent} from '../modal/module/module.maintenance.modal.component';

export class ModuleMaintenanceInstance<T = void> implements IModuleMaintenanceInstance<T> {
  private readonly _logger: Logger;
  private readonly _plTranslateService: PlTranslateService;
  private readonly _cgModalService: CGModalService;
  private readonly _cgStateService: CGStateService;
  private readonly _maintenanceWindowService: MaintenanceWindowService;

  private readonly _moduleName: string;
  private _stateMaintenance: ICGStateDeclaration;
  private _maintenanceAllow: boolean;
  private _maintenanceHeader: string;

  constructor(
    private readonly _injector: Injector,
    private readonly _module: IModule,
    private readonly _strictCheck: boolean = true
  ) {
    this._logger = this._injector.get<Logger>(Logger);
    this._plTranslateService = this._injector.get<PlTranslateService>(PlTranslateService);
    this._cgModalService = this._injector.get<CGModalService>(CGModalService);
    this._cgStateService = this._injector.get<CGStateService>(CGStateService);
    this._maintenanceWindowService = this._injector.get<MaintenanceWindowService>(MaintenanceWindowService);
    this._moduleName = this._module.name;
    this._evaluateState();
  }

  public maintenanceRaw(options?: Partial<IModuleMaintenanceModalOptions<T>>): NgbModalRef {
    this._evaluateState();
    const modalOptions: ICGModalOptions<T> = merge({}, {size: 'xxl'}, options?.modalOptions);
    const modalInstance = this._cgModalService.showVanilla(ModuleMaintenanceModalComponent, modalOptions);
    const componentInstance: ModuleMaintenanceModalComponent<T> = modalInstance.componentInstance;
    componentInstance.moduleMaintenanceInstance = this;
    componentInstance.parentMaintenanceModal = this._maintenanceWindowService.peek();
    this._maintenanceWindowService.push(componentInstance);
    componentInstance.toolbarInstanceId = this._maintenanceWindowService.nextId();
    if (isObject(options)) {
      if (isObject(options.params)) {
        componentInstance.params = options.params;
      }
      if (isBoolean(options.preventDocumentKeyup)) {
        componentInstance.preventDocumentKeyup = options.preventDocumentKeyup;
      }
      if (isBoolean(options.closeOnSaved)) {
        componentInstance.closeOnSaved = options.closeOnSaved;
      }
    }
    modalInstance.result.finally(() => {
      this._maintenanceWindowService.pop();
    });
    return modalInstance;
  }

  public maintenance(options?: Partial<IModuleMaintenanceModalOptions<T>>): Promise<T> {
    return this.maintenanceRaw(options).result;
  }

  public get maintenanceHeader(): string {
    return this._maintenanceHeader;
  }

  public get stateMaintenance(): ICGStateDeclaration {
    return this._stateMaintenance;
  }

  public get maintenanceAllow(): boolean {
    return this._maintenanceAllow;
  }

  public get module(): IModule {
    return this._module;
  }

  public get moduleName(): string {
    return this._moduleName;
  }

  private _evaluateState(): void {
    this._stateMaintenance = this._cgStateService.getRedirectState({stateOrName: this._moduleName, exactMatch: true});
    if (!this._stateMaintenance) {
      if (this._strictCheck) {
        this._logger.error(`Module with name [${this._moduleName}] is invalid or is not added to the current portal menu.`);
      }
      this._maintenanceAllow = false;
      this._maintenanceHeader = '';
    } else {
      this._maintenanceAllow = isDefined(this._stateMaintenance);
      this._maintenanceHeader = this._plTranslateService.translate(this._stateMaintenance.data.pageTitle);
    }
  }
}
