import {produce} from 'immer';
import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {IPlNavbarMenu, IPlNavbarMenuDefinition, isString, isUndefined, PlTranslateService} from 'pl-comps-angular';
import {IActivePortal, NAVBAR_ITEM_ID_CHANGE_PORTAL} from '../portals/portals.service.interface';

@Injectable({
  providedIn: 'root'
})
export class PortalService implements OnDestroy {
  private readonly _appMenu: Array<IPlNavbarMenu>;
  private readonly _subjectAppMenu: BehaviorSubject<Array<IPlNavbarMenu>>;
  private readonly _subjectAppPortal: BehaviorSubject<IActivePortal>;
  private readonly _subjectAppFaqsUrl: BehaviorSubject<string>;
  private _queuedAppMenuTitle: string;
  private _appPortal: IActivePortal;
  private _observableAppMenu: Observable<Array<IPlNavbarMenu>>;
  private _observableAppPortal: Observable<IActivePortal>;
  private _observableAppFaqsUrl: Observable<string>;

  constructor(private readonly _plTranslateService: PlTranslateService) {
    this._appMenu = [];
    this._appPortal = {
      portal: undefined,
      portalId: undefined,
      portalTitle: undefined,
      stateName: undefined
    };
    this._subjectAppMenu = new BehaviorSubject<Array<IPlNavbarMenu>>(this._appMenu.slice());
    this._subjectAppPortal = new BehaviorSubject<IActivePortal>(this._appPortal);
    this._subjectAppFaqsUrl = new BehaviorSubject<string>('');
  }

  public ngOnDestroy(): void {
    this._subjectAppMenu.complete();
    this._subjectAppPortal.complete();
    this._subjectAppFaqsUrl.complete();
  }

  public getAppMenu(): Observable<Array<IPlNavbarMenu>> {
    if (!this._observableAppMenu) {
      this._observableAppMenu = this._subjectAppMenu.asObservable();
    }
    return this._observableAppMenu;
  }

  public getAppPortal(): Observable<IActivePortal> {
    if (!this._observableAppPortal) {
      this._observableAppPortal = this._subjectAppPortal.asObservable();
    }
    return this._observableAppPortal;
  }

  public getAppFaqsUrl(): Observable<string> {
    if (!this._observableAppFaqsUrl) {
      this._observableAppFaqsUrl = this._subjectAppFaqsUrl.asObservable();
    }
    return this._observableAppFaqsUrl;
  }

  public addAppMenu(menu: IPlNavbarMenuDefinition, index?: number): void {
    const appMenuIndex = this._appMenu.findIndex((current: IPlNavbarMenu) => current.id === menu.id);
    // Insert
    if (appMenuIndex === -1) {
      if (isUndefined(index)) {
        this._appMenu.push(menu);
      } else {
        this._appMenu.splice(index, 0, menu);
      }
    }
    // Replace
    else {
      this._appMenu.splice(appMenuIndex, 1, menu);
    }
    if (this._queuedAppMenuTitle && this._appMenu.length === 1) {
      this.setAppMenuTitle(this._queuedAppMenuTitle);
      this._queuedAppMenuTitle = undefined;
    }
    this._subjectAppMenu.next(this._appMenu.slice());
  }

  public setAppMenuTitle(value: string): void {
    value = this._plTranslateService.translate(value);
    const appMenuTitleItem: IPlNavbarMenu = this._getAppMenuTitleItem();
    if (appMenuTitleItem && appMenuTitleItem.title !== value) {
      appMenuTitleItem.title = value;
    } else {
      this._queuedAppMenuTitle = value;
    }
  }

  public setAppPortal(value: IActivePortal): void {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._appPortal = produce(value, () => {});
    this._subjectAppPortal.next(this._appPortal);
  }

  public setAppFaqsUrl(value: string): void {
    this._subjectAppFaqsUrl.next(value);
  }

  public removeAppMenu(menuOrId: IPlNavbarMenuDefinition | string): void {
    const id: string = isString(menuOrId) ? menuOrId : menuOrId.id;
    const appMenuIndex = this._appMenu.findIndex((current: IPlNavbarMenu) => current.id === id);
    if (appMenuIndex !== -1) {
      this._appMenu.splice(appMenuIndex, 1);
      this._subjectAppMenu.next(this._appMenu.slice());
    }
  }

  public get appMenuTitle(): string {
    const appMenuTitleItem: IPlNavbarMenu = this._getAppMenuTitleItem();
    return appMenuTitleItem ? appMenuTitleItem.title : undefined;
  }

  private _getAppMenuTitleItem(): IPlNavbarMenu {
    return this._appMenu.find((item: IPlNavbarMenu) => item.id === NAVBAR_ITEM_ID_CHANGE_PORTAL);
  }
}
