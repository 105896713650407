import {Subscription} from 'rxjs';
import {Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {isArray, isEmpty, isObject, isString, Logger, PlDocumentService} from 'pl-comps-angular';
import {
  contabDigitalBaseFoldersToGenericViewerFolders,
  contabDigitalBaseFolderToGenericViewerFolder,
  contabDigitalFoldersWithChildrenToFolders,
  contabDigitalGenerateBaseFolder,
  IContabilidadeDigitalGenericViewerCallback,
  IContabilidadeDigitalGenericViewerEvtFileItemChanged,
  IContabilidadeDigitalGenericViewerEvtFolderItemChanged,
  IContabilidadeDigitalGenericViewerFile,
  IContabilidadeDigitalGenericViewerFolder,
  IContabilidadeDigitalGenericViewerFolderPath
} from './contabilidadedigital.genericviewer.interface';
import {ContabilidadeDigitalUIService} from '../../../../services/contabilidadedigital/contabilidadedigital.ui.service';
import {IJsonContabDigitalFolder, IJsonContabDigitalFolderWithChildren} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';

@Directive()
export abstract class ContabilidadeDigitalGenericViewerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public root: IContabilidadeDigitalGenericViewerFolder;
  @Input() public folderID: string;
  @Input() public folderIdAsUpwards: boolean;
  @Input() public upwardsUntilFolderId: string;
  @Input() public upwardsIncludeSelf: boolean;
  @Input() public showFiles: boolean;
  @Input() public checkHasSubFiles: boolean;
  @Input() public anoEmCursoIRC: boolean;
  @Input() public advancedDescription: boolean;
  @Input() public loading: boolean;
  @Input() public hideBreadcrumbs: boolean;
  @Input() public showCountFolderFiles: boolean;
  @Input() public callback: IContabilidadeDigitalGenericViewerCallback;
  @Output() public readonly evtFolderChanged: EventEmitter<IContabilidadeDigitalGenericViewerEvtFolderItemChanged>;
  @Output() public readonly evtFileChanged: EventEmitter<IContabilidadeDigitalGenericViewerEvtFileItemChanged>;
  @Output() public readonly evtResponseErrored: EventEmitter<HttpErrorResponse>;

  public currentFolderPath: Array<IContabilidadeDigitalGenericViewerFolderPath>;
  public selectedFolder: IContabilidadeDigitalGenericViewerFolder;
  public selectedFile: IContabilidadeDigitalGenericViewerFile;
  public mobile: boolean;
  public promise: Promise<unknown>;

  private readonly _subscriptionIsMobile: Subscription;

  protected constructor(
    protected readonly _logger: Logger,
    protected readonly _plDocumentService: PlDocumentService,
    protected readonly _contabilidadeDigitalUIService: ContabilidadeDigitalUIService
  ) {
    this.folderID = '';
    this.folderIdAsUpwards = false;
    this.upwardsUntilFolderId = '';
    this.upwardsIncludeSelf = false;
    this.showFiles = true;
    this.advancedDescription = false;
    this.loading = false;
    this.hideBreadcrumbs = false;
    this.evtFolderChanged = new EventEmitter<IContabilidadeDigitalGenericViewerEvtFolderItemChanged>();
    this.evtFileChanged = new EventEmitter<IContabilidadeDigitalGenericViewerEvtFileItemChanged>();
    this.evtResponseErrored = new EventEmitter<HttpErrorResponse>();
    this.currentFolderPath = [];
    this.mobile = false;
    this.showCountFolderFiles = false;
    this._subscriptionIsMobile = this._plDocumentService.isMobile().subscribe((isMobile: boolean) => {
      this.mobile = isMobile;
    });
  }

  public abstract selectFolder(value: IContabilidadeDigitalGenericViewerFolder): void;

  public abstract selectFile(value: IContabilidadeDigitalGenericViewerFile): void;

  public ngOnInit(): void {
    if (isObject(this.root)) {
      this._changedRoot();
    } else if (!isEmpty(this.folderID)) {
      this._changedFolderId();
    }
  }

  public ngOnChanges({root, folderID, callback}: SimpleChanges): void {
    if ((root && !root.isFirstChange()) || (folderID && !folderID.isFirstChange())) {
      if (root) {
        if (isObject(root.currentValue)) {
          this._changedRoot(root.currentValue);
        }
      } else if (!isEmpty(folderID.currentValue)) {
        this._changedFolderId(folderID.currentValue);
      }
    }
    if (callback) {
      const cb: IContabilidadeDigitalGenericViewerCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.getFolderItem = (folderIDOrItem: string | IContabilidadeDigitalGenericViewerFolder) => this._getFolderItem(folderIDOrItem);
        cb.setSelectedFolder = (folderIDOrItem: string | IContabilidadeDigitalGenericViewerFolder) => {
          this.setSelectedFolder(folderIDOrItem);
        };
        cb.setSelectedFile = (docIDOrItem: string | IContabilidadeDigitalGenericViewerFile) => {
          this.setSelectedFile(docIDOrItem);
        };
        cb.refreshFolder = (folderIDOrItem: string | IContabilidadeDigitalGenericViewerFolder) => this._externalRefreshFolderItem(folderIDOrItem);
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionIsMobile.unsubscribe();
  }

  public setSelectedFolder(folderIDOrItem: string | IContabilidadeDigitalGenericViewerFolder): void {
    const folder: IContabilidadeDigitalGenericViewerFolder = this._getFolderItem(folderIDOrItem);
    this.selectFolder(folder);
  }

  public setSelectedFile(folderIDOrItem: string | IContabilidadeDigitalGenericViewerFile): void {
    const file: IContabilidadeDigitalGenericViewerFile = this._getFileItem(folderIDOrItem);
    this.selectFile(file);
  }

  protected _changedRoot(value: IContabilidadeDigitalGenericViewerFolder = this.root): void {
    this.root = {...this._generateBaseFolder(), ...value};
    this._evaluateRoot(this.root);
  }

  protected _changedFolderId(folderID: string = this.folderID): void {
    this.promise = this._getFolderStructure(folderID)
      .then((response: IContabilidadeDigitalGenericViewerFolder) => {
        this.root = response;
        this._evaluateRoot(this.root);
      })
      .catch((reason: HttpErrorResponse) => {
        this._logger.error(reason);
        this.evtResponseErrored.emit(reason);
      })
      .finally(() => {
        this.promise = undefined;
      });
  }

  protected _refreshFolderItem(folderItem: IContabilidadeDigitalGenericViewerFolder): Promise<IContabilidadeDigitalGenericViewerFolder> {
    const showFiles: boolean = this.showFiles === true;
    const checkHasSubFiles: boolean = this._evaluateCheckHasSubFiles();
    return this._contabilidadeDigitalUIService
      .getFolderStructure(folderItem.folderID, {
        loadFiles: showFiles,
        checkHasSubFiles: checkHasSubFiles,
        anoEmCursoIRC: this.anoEmCursoIRC
      })
      .then((response: HttpResponse<Array<IJsonContabDigitalFolder>>) => {
        this._updateFolderItemChildFolders(folderItem, response.body);
        this._evaluateHasSubFiles(folderItem);
        return folderItem;
      });
  }

  protected _updateFolderItemChildFolders(folderItem: IContabilidadeDigitalGenericViewerFolder, folders: Array<IJsonContabDigitalFolder>): void {
    if (!isArray(folderItem.folders) || !folderItem.folders.length) {
      folderItem.folders = this._baseFoldersToGenericViewerFolders(folders);
    } else {
      const updatedChildFoldersIds: Array<string> = folders.map((childFolder: IContabilidadeDigitalGenericViewerFolder) => childFolder.folderID);
      folderItem.folders = folderItem.folders.filter((childFolder: IContabilidadeDigitalGenericViewerFolder) => updatedChildFoldersIds.includes(childFolder.folderID));
      for (const updatedChildFolder of folders) {
        const currentChildFolder: IContabilidadeDigitalGenericViewerFolder = folderItem.folders.find((childFolder: IContabilidadeDigitalGenericViewerFolder) => {
          return childFolder.folderID === updatedChildFolder.folderID;
        });
        if (!currentChildFolder) {
          const newFolder: IContabilidadeDigitalGenericViewerFolder = this._baseFolderToGenericViewerFolder(updatedChildFolder);
          newFolder.parentFolder = folderItem;
          folderItem.folders.push(newFolder);
        } else {
          currentChildFolder.folderID = updatedChildFolder.folderID;
          currentChildFolder.folderParentID = updatedChildFolder.folderParentID;
          currentChildFolder.name = updatedChildFolder.name;
          currentChildFolder.hasSubFolders = updatedChildFolder.hasSubFolders;
          currentChildFolder.hasSubFiles = updatedChildFolder.hasSubFiles;
          currentChildFolder.files = updatedChildFolder.files;
        }
      }
    }
    for (const childFolder of folderItem.folders) {
      childFolder.parentFolder = folderItem;
      if (isArray(childFolder.files) && childFolder.files.length) {
        for (const childFolderFile of childFolder.files) {
          childFolderFile.parentFolder = childFolder;
        }
      }
    }
  }

  protected _externalRefreshFolderItem(folderIDOrItem: string | IContabilidadeDigitalGenericViewerFolder): Promise<IContabilidadeDigitalGenericViewerFolder> {
    const folderItem: IContabilidadeDigitalGenericViewerFolder = this._getFolderItem(folderIDOrItem);
    if (!folderItem) {
      return Promise.reject(new Error(`Folder item with id "${isString(folderIDOrItem) ? folderIDOrItem : folderIDOrItem.folderID}" not found.`));
    }
    return this._refreshFolderItem(folderItem);
  }

  protected _getFolderStructure(folderID: string): Promise<IContabilidadeDigitalGenericViewerFolder> {
    const showFiles: boolean = this.showFiles === true;
    const checkHasSubFiles: boolean = this._evaluateCheckHasSubFiles();
    if (!this.folderIdAsUpwards) {
      return this._contabilidadeDigitalUIService
        .getFolderStructure(
          folderID,
          {
            loadFiles: showFiles,
            checkHasSubFiles: checkHasSubFiles,
            anoEmCursoIRC: this.anoEmCursoIRC
          },
          {reportExceptions: false}
        )
        .then((response: HttpResponse<Array<IJsonContabDigitalFolder>>) => {
          const root: IContabilidadeDigitalGenericViewerFolder = {
            ...this._generateBaseFolder(),
            ...this.root,
            folders: this._baseFoldersToGenericViewerFolders(response.body),
            hasSubFolders: response.body.length > 0
          };
          return root;
        });
    }
    return this._contabilidadeDigitalUIService
      .getFolderStructureUpwards(
        folderID,
        this.upwardsUntilFolderId,
        {
          includeSelf: this.upwardsIncludeSelf,
          loadFiles: showFiles,
          checkHasSubFiles: checkHasSubFiles,
          anoEmCursoIRC: this.anoEmCursoIRC
        },
        {reportExceptions: false}
      )
      .then((response: HttpResponse<Array<IJsonContabDigitalFolderWithChildren>>) => {
        const root: IContabilidadeDigitalGenericViewerFolder = {
          ...this._generateBaseFolder(),
          folders: this._foldersWithChildrenToFolders(response.body, folderID),
          hasSubFolders: response.body.length > 0
        };
        return root;
      });
  }

  protected _getFolderItem(folderIDOrItem: string | IContabilidadeDigitalGenericViewerFolder, root?: Array<IContabilidadeDigitalGenericViewerFolder>): IContabilidadeDigitalGenericViewerFolder {
    if (!folderIDOrItem) {
      return undefined;
    }
    if (!root) {
      root = this.root.folders;
    }
    const isItemAString: boolean = isString(folderIDOrItem);
    for (const folderItem of root) {
      if (isItemAString) {
        if (folderIDOrItem === folderItem.folderID) {
          return folderItem;
        }
      } else if ((<IContabilidadeDigitalGenericViewerFolder>folderIDOrItem).folderID === folderItem.folderID) {
        return folderItem;
      }
      if (isArray(folderItem.folders) && folderItem.folders.length) {
        const foundFolderItem: IContabilidadeDigitalGenericViewerFolder = this._getFolderItem(folderIDOrItem, folderItem.folders);
        if (foundFolderItem) {
          return foundFolderItem;
        }
      }
    }
    return undefined;
  }

  protected _getFileItem(docIDOrItem: string | IContabilidadeDigitalGenericViewerFile, root?: Array<IContabilidadeDigitalGenericViewerFolder>): IContabilidadeDigitalGenericViewerFile {
    if (!root) {
      root = this.root.folders;
    }
    for (const folderItem of root) {
      for (const file of folderItem.files) {
        if (file.docID === docIDOrItem) {
          return file;
        }
      }
      if (isArray(folderItem.folders) && folderItem.folders.length) {
        const foundFileItem: IContabilidadeDigitalGenericViewerFile = this._getFileItem(docIDOrItem, folderItem.folders);
        if (foundFileItem) {
          return foundFileItem;
        }
      }
    }
    return undefined;
  }

  protected _evaluateRoot(root: IContabilidadeDigitalGenericViewerFolder): void {
    if (isArray(root.folders)) {
      this._evaluateFolders(root.folders, root);
    }
    if (!this.selectedFolder) {
      this.selectFolder(root);
    }
  }

  protected _evaluateFolders(treeViewerFolders: Array<IContabilidadeDigitalGenericViewerFolder>, parentFolder: IContabilidadeDigitalGenericViewerFolder): void {
    for (const folder of treeViewerFolders) {
      if (!folder.parentFolder) {
        folder.parentFolder = parentFolder;
      }
      if (isArray(folder.files) && folder.files.length) {
        for (const folderFile of folder.files) {
          if (!isObject(folderFile.parentFolder)) {
            folderFile.parentFolder = folder;
          }
        }
      }
      if (isArray(folder.folders)) {
        this._evaluateFolders(folder.folders, folder);
      }
    }
  }

  protected _evaluateHasSubFiles(folderItem: IContabilidadeDigitalGenericViewerFolder): void {
    if (!folderItem) {
      return;
    }
    if (isArray(folderItem.folders) && folderItem.folders.length) {
      let hasSubFiles = false;
      for (const childFolder of folderItem.folders) {
        if (childFolder.hasSubFiles || childFolder.files.length > 0) {
          hasSubFiles = true;
          break;
        }
      }
      if (!hasSubFiles) {
        folderItem.hasSubFiles = false;
      } else if (!folderItem.hasSubFiles) {
        folderItem.hasSubFiles = true;
      }
    }
    this._evaluateHasSubFiles(folderItem.parentFolder);
  }

  protected _evaluateCurrentFolderPath(): void {
    this.currentFolderPath = this._getFoldersPath();
  }

  protected _generateBaseFolder(): IContabilidadeDigitalGenericViewerFolder {
    return contabDigitalGenerateBaseFolder();
  }

  protected _baseFolderToGenericViewerFolder(baseFolder: IJsonContabDigitalFolder): IContabilidadeDigitalGenericViewerFolder {
    return contabDigitalBaseFolderToGenericViewerFolder(baseFolder);
  }

  protected _baseFoldersToGenericViewerFolders(baseFolders: Array<IJsonContabDigitalFolder>): Array<IContabilidadeDigitalGenericViewerFolder> {
    return contabDigitalBaseFoldersToGenericViewerFolders(baseFolders);
  }

  protected _foldersWithChildrenToFolders(foldersWithChildren: Array<IJsonContabDigitalFolderWithChildren>, folderID: string): Array<IContabilidadeDigitalGenericViewerFolder> {
    return contabDigitalFoldersWithChildrenToFolders(foldersWithChildren, (folderWithChildren: IJsonContabDigitalFolderWithChildren, genericViewerFolder: IContabilidadeDigitalGenericViewerFolder) => {
      if (genericViewerFolder.folderID === folderID) {
        this.selectFolder(genericViewerFolder);
      }
    });
  }

  protected _getFoldersPath(): Array<IContabilidadeDigitalGenericViewerFolderPath> {
    return this._contabilidadeDigitalUIService.getFoldersPath(this.selectedFolder);
  }

  protected _evaluateCheckHasSubFiles(): boolean {
    return this.checkHasSubFiles === true || this.showFiles !== false;
  }
}
