import {Component, EventEmitter, Input, Output} from '@angular/core';
import {INotification} from '../../../services/notificationcenter/notificationcenter.service.interface';

@Component({
  selector: 'notification-item',
  templateUrl: './notificationcenter.item.component.html'
})
export class NotificationItemComponent {
  @Input() public notification: INotification;
  @Input() public minimalistic: boolean;
  @Input() public mobile: boolean;
  @Output() public readonly evtSelectedNotification: EventEmitter<INotification>;

  constructor() {
    this.minimalistic = false;
    this.mobile = false;
    this.evtSelectedNotification = new EventEmitter<INotification>();
  }

  public readNotification(notification: INotification): void {
    this.evtSelectedNotification.emit(notification);
  }
}
