import moment from 'moment';
import {firstValueFrom} from 'rxjs';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {copy, IPlUploadCallback, isNumber, PlAlertService} from 'pl-comps-angular';
import {ApiService} from '../../../../services/api/api.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EComunicacaoInternaSystem} from '../comunicacoesInternas.module.interface';
import {EMPTY_GUID} from '../../../../../config/constants';
import {ENTITY_NAME_CATEGORIAS} from '../../../../entities/categorias/categorias.entity.interface';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../../../entities/dgemps/dgemps.entity.interface';
import {ENTITY_NAME_PRH_COMUNICACOES_INTERNAS, IPRHComunicacoesInternasEntityService} from '../../../../entities/prhcomunicacoesinternas/pRHComunicacoesInternas.entity.interface';
import {ENTITY_NAME_PRH_SERVICOS, IPRHServicosEntityService} from '../../../../entities/prhservicos/pRHServicos.entity.interface';
import {ENTITY_NAME_PRHTIPOCOM} from '../../../../entities/prhtipocom/prhtipocom.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonCateg} from '../../../../entities/categorias/jsonCateg.entity.interface';
import {IJsonDGEMP} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHComunicacaoInterna} from '../../../../entities/prhcomunicacoesinternas/jsonPRHComunicacaoInterna.entity.interface';
import {IJsonPRHServico} from '../../../../entities/prhservicos/jsonPRHServico.entity.interface';
import {IJsonPRHTipoCom} from '../../../../entities/prhtipocom/jsonrhtipocom.entity.interface';
import {ITipoComunicacao} from '../../home/home.module.interface';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';

@Component({
  selector: 'colaboradores-comunicacoesinternas-modal',
  templateUrl: './comunicacoesInternas.modal.component.html'
})
export class ComunicacoesInternasModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public answer: boolean;
  @Input() public codEmp: number;
  @Input() public tiposComunicacao: Array<IJsonPRHTipoCom>;

  public home: boolean;
  public comIntCab: number;
  public comInt: IJsonPRHComunicacaoInterna;
  public anexaPDF: boolean;
  public titulo: string;
  public texto: string;
  public tipoComunicacao: ITipoComunicacao;
  public servicos: Array<number>;
  public servicosSource: Array<IJsonPRHServico>;
  public colaboradoresSource: Array<IJsonDGEMP>;
  public colaboradoresSelected: Array<IJsonDGEMP>;
  public categoriasSource: Array<IJsonCateg>;
  public categoriasSelected: Array<IJsonCateg>;
  public uploadUrl: string;
  public isTipoRecrutamento: boolean;
  public plUpload: IPlUploadCallback;
  public servicosPromise: Promise<void>;

  protected readonly _servicePRHComunicacoesInternas: IPRHComunicacoesInternasEntityService;
  protected readonly _serviceDGEMPS: IDGEMPSEntityService;
  protected readonly _servicePRHServicos: IPRHServicosEntityService;
  protected readonly _serviceCategorias: IEntityService<IJsonCateg>;
  protected readonly _servicePRHTipoCom: IEntityService<IJsonPRHTipoCom>;

  constructor(
    protected readonly _injector: Injector,
    protected _entityServiceBuilder: EntityServiceBuilder,
    protected _plAlertService: PlAlertService,
    protected _authService: AuthService,
    protected _apiService: ApiService
  ) {
    super(_injector);
    this.gravarComInt = this.gravarComInt.bind(this);
    this.sendFile = this.sendFile.bind(this);

    this._servicePRHComunicacoesInternas = this._entityServiceBuilder.build<IJsonPRHComunicacaoInterna, IPRHComunicacoesInternasEntityService>(ENTITY_NAME_PRH_COMUNICACOES_INTERNAS);
    this._serviceDGEMPS = this._entityServiceBuilder.build<IJsonDGEMP, IDGEMPSEntityService>(ENTITY_NAME_DGEMPS);
    this._servicePRHServicos = this._entityServiceBuilder.build(ENTITY_NAME_PRH_SERVICOS);
    this._serviceCategorias = this._entityServiceBuilder.build(ENTITY_NAME_CATEGORIAS);
    this._servicePRHTipoCom = this._entityServiceBuilder.build(ENTITY_NAME_PRHTIPOCOM);

    this.answer = false;
    this.home = false;
    this.isTipoRecrutamento = false;
    this.servicosSource = [];
    this.servicos = [];
    this.categoriasSource = [];
    this.categoriasSelected = [];
    this.anexaPDF = false;
    this.plUpload = {};
    this.comInt = {
      cab: {
        comIntCabID: 0,
        titulo: '',
        texto: '',
        data: undefined,
        estadoLido: false,
        childrenVisible: false,
        comIntLinId: 0,
        dgemp: {
          codEmp: undefined,
          nome: undefined,
          apelido: undefined,
          morada: undefined,
          codPostal: undefined,
          nomeLocalida: undefined,
          email: undefined,
          nTelefone: undefined,
          telemovelPart1: undefined,
          codServicoPRH: undefined,
          nomeServicoPRH: undefined,
          categoriaActual: undefined,
          nomeCategoria: undefined,
          rua: undefined,
          ativo: undefined,
          numContrib: undefined
        },
        docID: EMPTY_GUID,
        tipoComInt: EComunicacaoInternaSystem.COMUNICACAO_INTERNA,
        nomeTipoComInt: undefined,
        codEmp: this.codEmp,
        stampInsert: undefined,
        apelidoEmpregado: undefined,
        comIntCabIDPai: 0,
        nomeEmpregado: undefined,
        isPrincipal: false
      },
      codEmpComIntLIn: 0,
      comIntLinID: 0,
      estadoLido: false,
      filhos: [],
      linhas: [],
      temFilhosPorLer: false,
      isSameCodEmp: false
    };
  }

  public ngOnInit(): void {
    this.servicosPromise = this._servicePRHServicos.query().then(async (response) => {
      this.servicosSource = response.body.list;
      this._selectAllServicos();
      await this._loadComunicacoesInternas();
    });

    if (!isNumber(this.codEmp)) {
      this._authService.identity().then((session: TUserSession) => {
        this.codEmp = session.erp.codEmp;
        this.comInt.cab.codEmp = this.codEmp;
      });
    } else {
      this.comInt.cab.codEmp = this.codEmp;
    }
  }

  public selectServicos(servicos: Array<number>): void {
    this.servicos = servicos;
    this.colaboradoresSource = [];
    this.colaboradoresSelected = [];
    if (this.servicos.length) {
      this._servicePRHServicos.getListaEmpregadosServicos(this.servicos).then((response) => {
        this.colaboradoresSource = response.body;
      });
    }
  }

  public fillColaboradoresByCateg(categoriasFilter: string): void {
    this.colaboradoresSelected = [];
    this._serviceDGEMPS.query({pesquisa: categoriasFilter}).then((data) => {
      this.colaboradoresSource = data.body.list;
    });
  }

  public fillColaboradoresRecrutamento(): void {
    let categoriasFilter = '';
    this.colaboradoresSource = [];
    this.colaboradoresSelected = [];
    if (this.categoriasSelected) {
      for (const categoria of this.categoriasSelected) {
        categoriasFilter = !categoriasFilter ? `(dgCategoria=${categoria.nCateg}` : `${categoriasFilter} |dgCategoria=${categoria.nCateg}`;
      }
      if (categoriasFilter) {
        categoriasFilter += ')';
        this.fillColaboradoresByCateg(categoriasFilter);
      }
    }
  }

  public selectTipoComunicacao(value: IJsonPRHTipoCom): void {
    this.tipoComunicacao = value;
    this.isTipoRecrutamento = this.tipoComunicacao?.codPRHTipoCom === EComunicacaoInternaSystem.RECRUTAMENTO_INTERNO;
    this.colaboradoresSource = [];
    this.colaboradoresSelected = [];
    if (this.tipoComunicacao.codPRHTipoCom === EComunicacaoInternaSystem.RECRUTAMENTO_INTERNO) {
      if (!this.categoriasSource.length) {
        this._serviceCategorias.query().then((response) => {
          this.categoriasSource = response.body.list;
        });
      }
    } else {
      this._selectAllServicos();
    }
  }

  public async gravarComInt(): Promise<void> {
    const comunicacaoInterna = copy(this.comInt);
    comunicacaoInterna.cab.tipoComInt = this.tipoComunicacao.codPRHTipoCom;
    comunicacaoInterna.cab.titulo = this.titulo;
    comunicacaoInterna.cab.texto = this.texto;
    comunicacaoInterna.cab.data = moment();
    comunicacaoInterna.cab.codEmp = this.codEmp;
    for (const colaborador of this.colaboradoresSelected) {
      if (colaborador.codEmp === this.codEmp) {
        continue;
      }
      comunicacaoInterna.linhas.push({
        codEmp: colaborador.codEmp,
        estadoLido: false,
        dataHoraLeitura: moment(),
        comIntLinID: undefined,
        dgemp: undefined,
        comIntCabID: undefined
      });
    }
    comunicacaoInterna.linhas.push({
      codEmp: this.codEmp,
      estadoLido: true,
      dataHoraLeitura: moment(),
      comIntLinID: undefined,
      dgemp: undefined,
      comIntCabID: undefined
    });
    const response = await this._servicePRHComunicacoesInternas.post({body: comunicacaoInterna});
    if (this.anexaPDF) {
      this.comIntCab = response.body.cab.comIntCabID;
      this.uploadUrl = await firstValueFrom(this._servicePRHComunicacoesInternas.uploadDocUrl(this.comIntCab, String(this.comIntCab)));
    } else {
      this._plAlertService.success('colaboradores.sentSuccess');
      this.close();
    }
  }

  public async sendFile(): Promise<void> {
    await this.plUpload.uploadAll();
    this._plAlertService.success('colaboradores.uploadSuccess');
    this.close();
  }

  private async _loadComunicacoesInternas(): Promise<void> {
    if (!this.tiposComunicacao?.length) {
      this.tiposComunicacao = (await this._servicePRHTipoCom.query()).body.list;
    }
    const tipoComunicacao: IJsonPRHTipoCom = this.tiposComunicacao.find((tipo: IJsonPRHTipoCom) => tipo.codPRHTipoCom === EComunicacaoInternaSystem.COMUNICACAO_INTERNA);
    if (tipoComunicacao) {
      this.selectTipoComunicacao(tipoComunicacao);
    }
  }

  private _selectAllServicos(): void {
    this.selectServicos(this.servicosSource.map((servico: IJsonPRHServico) => servico.codServico));
  }
}
