import {Injectable, Injector} from '@angular/core';
import {ContabilidadeDigitalService} from '../contabilidadedigital/contabilidadedigital.service';

@Injectable({
  providedIn: 'root'
})
export class ClientConnectService extends ContabilidadeDigitalService {
  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  protected _buildPath(): string {
    return `${this._apiService.path.restapi}/clientconnect`;
  }
}
