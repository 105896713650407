import {EEntityStateDetailType} from '../../../common/utils/entity.state.utils';
import {ENTITY_NAME_PORTALS, IPortalEntity, sortPortals} from './portal.entity.interface';
import {ENTITY_NAME_ROLES} from '../roles/roles.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonPortal, IJsonPortalSortItem} from './jsonPortal.entity.interface';
import {IUtilizadoresUserRole} from '../../modules/utilizadores/utilizadores.interface';
import {PortalEntityEditComponent} from './components/edit/portal.entity.edit.component';
import {PortalEntityListComponent} from './components/list/portal.entity.list.component';
import {ROLE} from '../../services/role.const';
import {THttpQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';

export const ENTITY_PORTAL: IPortalEntity = {
  name: ENTITY_NAME_PORTALS,
  url: 'manager/portals',
  roles: [ROLE.ADMIN],
  icon: 'fa-th-large',
  metadata: {
    keyName: 'id',
    fields: [
      {name: 'id', caption: 'portals.fields.id'},
      {name: 'name', caption: 'portals.fields.name', validators: {required: true}},
      {name: 'description', caption: 'portals.fields.description', validators: {required: true}},
      {name: 'url', caption: 'portals.fields.url', validators: {required: true}},
      {name: 'sortOrder', caption: 'portals.fields.sortOrder', visible: false},
      {name: 'data', caption: 'portals.fields.data'}
    ],
    order: 'id',
    searchFields: 'name,description',
    listFields: 'url,name,description'
  },
  autocomplete: {
    rowTemplate: '{{name}} - {{description}}',
    output: 'description',
    searchFields: 'name,description'
  },
  actions: {
    new: true,
    search: true,
    filter: true,
    detail: true,
    edit: true,
    delete: true
  },
  list: {
    state: {
      component: PortalEntityListComponent
    }
  },
  detail: {
    state: {
      component: PortalEntityEditComponent,
      resolve: [
        {
          provide: 'roles',
          deps: [EntityServiceBuilder],
          useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IUtilizadoresUserRole>> => {
            const serviceRoles: IEntityService<IUtilizadoresUserRole> = entityServiceBuilder.build<IUtilizadoresUserRole>(ENTITY_NAME_ROLES);
            return serviceRoles.query().then((response: THttpQueryResponse<IUtilizadoresUserRole>) => response.body.list);
          }
        },
        {
          provide: 'portals',
          deps: ['type', 'model', EntityServiceBuilder],
          useFactory: (type: EEntityStateDetailType, model: IJsonPortal, entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonPortal>> => {
            const servicePortals: IEntityService<IJsonPortal> = entityServiceBuilder.build<IJsonPortal>(ENTITY_NAME_PORTALS);
            let search: string;
            if (type !== EEntityStateDetailType.NEW && model.id) {
              search = `id<>${model.id}`;
            }
            return servicePortals.query({pesquisa: search}).then((response: THttpQueryResponse<IJsonPortal>) => sortPortals(response.body.list));
          }
        }
      ]
    }
  },
  service: function (): void {
    this.sortPortals = (sortItems: Array<IJsonPortalSortItem>): TServiceResponse<void> => {
      return this.post<void, Array<IJsonPortalSortItem>>({url: 'sort', body: sortItems});
    };
  }
};
