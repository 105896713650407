import {AfterContentChecked, ContentChildren, Directive, Host, Input, OnDestroy, OnInit, Optional, QueryList, SkipSelf} from '@angular/core';
import type {IPlNavigatorItem} from './navigator.interface';
import {isDefined, isNumber, isObject} from '../common/utilities/utilities';
import {PlNavigatorComponent} from './navigator.component';
import {PlNavigatorItemCaptionDirective} from './navigator.item.caption.directive';
import {PlNavigatorItemContentDirective} from './navigator.item.content.directive';
import {PlNavigatorItemHeaderDirective} from './navigator.item.header.directive';

let nextId = 0;

@Directive({
  selector: 'pl-navigator-item',
  standalone: false
})
export class PlNavigatorItemDirective implements IPlNavigatorItem, OnInit, OnDestroy, AfterContentChecked {
  @Input() public id: any;
  @Input() public caption: string;
  @Input() public icon: string;
  @Input() public order: number;
  @Input() public disabled: boolean;
  @Input() public collapsible: boolean;
  @Input() public collapsed: boolean;

  public readonly subItems: Array<PlNavigatorItemDirective>;
  public parentItem: PlNavigatorItemDirective;
  public templateCaption: PlNavigatorItemCaptionDirective;
  public templateHeader: PlNavigatorItemHeaderDirective;
  public templateContent: PlNavigatorItemContentDirective;
  public hasCaption: boolean;

  @ContentChildren(PlNavigatorItemCaptionDirective, {descendants: false}) private readonly _templateCaption: QueryList<PlNavigatorItemCaptionDirective>;
  @ContentChildren(PlNavigatorItemHeaderDirective, {descendants: false}) private readonly _templateHeader: QueryList<PlNavigatorItemHeaderDirective>;
  @ContentChildren(PlNavigatorItemContentDirective, {descendants: false}) private readonly _templateContent: QueryList<PlNavigatorItemContentDirective>;

  constructor(
    @Host() @Optional() private readonly _plNavigatorComponent: PlNavigatorComponent,
    @Host() @Optional() @SkipSelf() private readonly _plNavigatorItemDirective: PlNavigatorItemDirective
  ) {
    this.id = `pl-navigator-item-${nextId++}`;
    this.disabled = false;
    this.collapsible = false;
    this.collapsed = false;
    this.subItems = [];
    this.hasCaption = false;
  }

  public ngOnInit(): void {
    if (this._plNavigatorItemDirective) {
      this._plNavigatorItemDirective.addItem(this);
    } else {
      this._plNavigatorComponent.addItem(this);
    }
  }

  public ngOnDestroy(): void {
    if (this._plNavigatorItemDirective) {
      this._plNavigatorItemDirective.removeItem(this);
    } else {
      this._plNavigatorComponent.removeItem(this);
    }
  }

  public ngAfterContentChecked(): void {
    this.templateCaption = this._templateCaption.first;
    this.templateHeader = this._templateHeader.first;
    this.templateContent = this._templateContent.first;
    this.hasCaption = isDefined(this.templateCaption);
  }

  public addItem(subItem: PlNavigatorItemDirective): void {
    subItem.parentItem = this;
    if (!isNumber(subItem.order) || subItem.order < 0) {
      this.subItems.push(subItem);
    } else {
      for (let i = this.subItems.length - 1; i >= 0; i--) {
        const currentSubItem = this.subItems[i];
        if (subItem.order >= currentSubItem.order) {
          this.subItems.splice(i + 1, 0, subItem);
          return;
        }
      }
      this.subItems.push(subItem);
    }
  }

  public removeItem(subItem: unknown | PlNavigatorItemDirective): void {
    const id: unknown = isObject(subItem) ? (<PlNavigatorItemDirective>subItem).id : subItem;
    const index: number = this.subItems.findIndex((value: PlNavigatorItemDirective) => value.id === id);
    if (index !== -1) {
      this.subItems.splice(1, 0);
    }
  }
}
