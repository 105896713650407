<div class="pl-checkbox form-check" [ngClass]="evaluatedStatus" [class.readonly]="options.readonly" [class.disabled]="options.disabled" (keydown.space)="onKeydownEnter($event)">
  <input
    #inputElement
    type="checkbox"
    class="pl-checkbox-edit form-check-input"
    editEventsHandlerIgnore="click"
    [id]="'pl-checkbox-' + uniqueId"
    [name]="attrName"
    [disabled]="options.disabled"
    [editEventsHandler]="options.events"
    [editEventsHandlerValue]="value"
    (click)="onClick($event)" />

  <label *ngIf="evaluatedLabel" class="pl-checkbox-label form-check-label" [for]="'pl-checkbox-' + uniqueId">{{ evaluatedLabel }}</label>
</div>

<pl-messages *ngIf="validate" [instance]="self" [formControlInstance]="formControl" [modelValue]="value" [validate]="validate" [validators]="validators" [ngFormInstance]="ngFormInstance">
</pl-messages>
