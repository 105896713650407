import {isBoolean} from 'pl-comps-angular';
import {Injector} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {APP_LOCALE, LOCAL_STORAGE_APP_THEME, LOCAL_STORAGE_REFRESHED} from './constants';
import {AppService} from '../core/services/app/app.service';
import {DEFAULT_APP_THEME, EAppTheme} from '../common/themes/themes.interface';

export async function configApplication(injector: Injector): Promise<void> {
  const appService: AppService = injector.get<AppService>(AppService);
  const window = injector.get<Document>(DOCUMENT, null, {optional: true})?.defaultView;

  if (window?.sessionStorage) {
    let refreshed: boolean = JSON.parse(window.sessionStorage.getItem(LOCAL_STORAGE_REFRESHED)) || false;
    if (!isBoolean(refreshed)) {
      refreshed = false;
    }
    if (refreshed === true) {
      appService.setStatus({refreshed: refreshed});
    }
    window.sessionStorage.setItem(LOCAL_STORAGE_REFRESHED, JSON.stringify(false));
  }

  // Load theme
  let theme: EAppTheme;
  if (window?.localStorage) {
    try {
      theme = <EAppTheme>window.localStorage.getItem(LOCAL_STORAGE_APP_THEME);
    } catch (ignored: unknown) {
      window.localStorage.removeItem(LOCAL_STORAGE_APP_THEME);
    }
  }
  if (!theme) {
    theme = DEFAULT_APP_THEME;
  }
  appService.setTheme(theme);

  // Load locale
  await appService.loadLocale(APP_LOCALE.value);
}
