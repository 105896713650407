import {Injector} from '@angular/core';
import {Transition} from '@uirouter/core';
import {EEntityStateDetailType} from '../../../common/utils/entity.state.utils';
import {ENTITY_NAME_ARCLI, IArCliParams} from './arClis.entity.interface';
import {IEntityDefinition, IEntityDeleteMessage} from '../../components/entity/entity.definition.interface';
import {IJsonArCli} from './jsonArCli.entity.interface';
import {ROLE} from '../../services/role.const';
import {TranslateService} from '@ngx-translate/core';
import {ArclisEditComponent} from './component/edit/arclis.entity.edit.component';

export const ENTITY_ARCLI: IEntityDefinition<IJsonArCli> = {
  name: ENTITY_NAME_ARCLI,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.ATIVOS],
  searchPlaceholder: 'arclis.pesquisa',
  metadata: {
    keyName: 'arcliGUIDID',
    fields: [
      {
        name: 'nArtigo',
        caption: 'arclis.fields.nArtigo',
        entity: {name: 'artigos', keyTarget: 'nArtigo', output: 'nArtigo'},
        validators: {required: true, maxlength: 25}
      },
      {
        name: 'nConta',
        caption: 'arclis.fields.nConta',
        validators: {required: true, maxlength: 75},
        entity: {name: 'clifos', keyTarget: 'nConta', output: 'nConta'}
      },
      {name: 'nClifoArtigo', caption: 'arclis.fields.nClifoArtigo', validators: {required: true, maxlength: 25}},
      {name: 'descricaoFornecedor', caption: 'arclis.fields.descricaoFornecedor', validators: {required: true, maxlength: 100}}
    ],
    order: 'nArtigo',
    searchFields: 'nArtigo,nConta,nClifoArtigo,descricaoFornecedor'
  },
  autocomplete: {
    rowTemplate: '{{nClifoArtigo}} - {{descricaoFornecedor}}',
    output: 'descricaoFornecedor',
    searchFields: 'nArtigo,nConta,nClifoArtigo,descricaoFornecedor'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  new: {
    state: {
      component: ArclisEditComponent,
      params: {
        nConta: {
          type: 'string',
          value: ''
        },
        nomeConta: {
          type: 'string',
          value: ''
        }
      }
    }
  },
  serviceInjectable: [
    Injector,
    function (injector: Injector): void {
      this.onInit = (model: IJsonArCli, type: EEntityStateDetailType) => {
        if (type === EEntityStateDetailType.NEW) {
          const transition: Transition = injector.get(Transition, null, {optional: true});
          if (transition) {
            const params: IArCliParams = <IArCliParams>transition.params();
            model.nConta = params.nConta;
          }
        }
      };
    }
  ],
  detail: {
    state: {
      component: ArclisEditComponent
    }
  },
  edit: {
    state: {
      component: ArclisEditComponent
    }
  },
  getDeleteMessage(model: IJsonArCli, translate: TranslateService): IEntityDeleteMessage {
    return {
      title: translate.instant('morals.deleteModalText', {nome: model.nClifoArtigo}),
      message: translate.instant('entity.delete.message')
    };
  }
};
