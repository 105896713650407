import {Directive, ElementRef, HostListener, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[plFallbackSrc]',
  standalone: false
})
export class PlFallbackSrcDirective implements OnChanges, OnDestroy {
  @Input({required: true}) public plFallbackSrc: string;

  private readonly _element: HTMLImageElement;
  private readonly _observerSrc: MutationObserver;
  private _error: boolean;
  private _appliedFallback: boolean;

  constructor(private readonly _elementRef: ElementRef<HTMLImageElement>) {
    this._element = this._elementRef.nativeElement;
    if (this._element.tagName.toLowerCase() !== 'img') {
      throw new Error('[plFallbackSrc] directive can only be used on img elements.');
    }

    this._error = false;
    this._appliedFallback = false;

    this._observerSrc = new MutationObserver(() => {
      if (this._appliedFallback) {
        this._appliedFallback = false;
        return;
      }
      this._error = false;
    });

    this._observerSrc.observe(this._element, {attributes: true, attributeFilter: ['src']});
  }

  public ngOnChanges({plFallbackSrc}: SimpleChanges): void {
    if (plFallbackSrc) {
      this._evaluateSrc();
    }
  }

  public ngOnDestroy(): void {
    this._observerSrc.disconnect();
  }

  @HostListener('error')
  public errorHandler(): void {
    this._error = true;
    this._evaluateSrc();
  }

  private _evaluateSrc(): void {
    if (!this._error) {
      return;
    }
    const src: string = this.plFallbackSrc ? this.plFallbackSrc : '';
    this._element.setAttribute('src', src);
    this._appliedFallback = true;
  }
}
