import {Component, Injector} from '@angular/core';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';

@Component({
  selector: 'check-send-all-mails',
  templateUrl: './checkSendAllMails.modal.component.html'
})
export class CheckSendAllMailsModalComponent extends CGModalComponent<boolean> {
  public sendAll: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.sendAll = false;
  }
}
