<div class="comunicacao-interna-message-box">
  <div class="comunicacao-interna-message-box-header">
    <a href="#" class="comunicacao-interna-message-box-header-left" (click)="toggleChildren(data, item)">
      <i *ngIf="!answer" class="fa fa-fw" [ngClass]="{'fa-envelope': !data.estadoLido || data.temFilhosPorLer, 'fa-envelope-open-o': data.estadoLido && !data.temFilhosPorLer}"></i>
      <i *ngIf="answer" class="fa fa-reply fa-fw" style="transform: rotate(180deg); color: #cccccc"></i>
      <span style="flex: 0 0 180px">{{ getSenderName(data) }}</span>
      <span>{{ data.cab.titulo }}</span>
    </a>
    <div class="comunicacao-interna-message-box-header-right">
      <span>{{ getHumanSentDate(data.cab.stampInsert) }}</span>
      <a class="btn btn-link" [href]="downloadUrl" *ngIf="data.cab.docID !== emptyGuid && data.cab.docID && data.cab.comIntCabID !== 0" download target="_blank" rel="noopener noreferrer">
        <i class="fa fa-file-pdf-o fa-fw text-danger"></i>
      </a>
      <button *ngIf="!isAuthor(data)" type="button" class="btn btn-link btn-xs" (click)="answerComInt(data, true)" title="Responder">
        <i class="fa fa-reply fa-fw text-primary"></i>
      </button>
    </div>
  </div>
  <div class="comunicacao-interna-message-box-body" *ngIf="data.cab.childrenVisible">
    <div class="comunicacao-interna-message-box-body-text">{{ data.cab.texto }}</div>
    <a
      class="btn btn-outline-danger btn-xs mt-1"
      [href]="downloadUrl"
      *ngIf="data.cab.docID !== emptyGuid && data.cab.docID && data.cab.comIntCabID !== 0"
      download
      target="_blank"
      rel="noopener noreferrer">
      <i class="fa fa-file-pdf-o fa-fw"></i><span [translate]="'colaboradores.comunicacoesinternas.anexo'"></span>
    </a>
    <div class="comunicacao-interna-messages" *ngIf="data.cab.childrenVisible">
      <div class="comunicacao-interna-message-row" *ngFor="let dataRecursiva of data.filhos">
        <comunicacaointerna-recursiva (evtComunicacaoSent)="evtComunicacaoSent.emit()" [answer]="true" [data]="dataRecursiva" [iColHome]="iColHome"></comunicacaointerna-recursiva>
      </div>
    </div>
  </div>
</div>
