import moment from 'moment';
import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EDelphiNumberTypes, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {
  ESitEmprego,
  ETipoSubsidio,
  IRecolhaSerieSubsidiosParams,
  MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_ALIMENTACAO,
  MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_FERIAS,
  MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_NATAL
} from '../rhRecolhaSerieSubsidios.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonRHRecolhaSerieSubsidios, IJsonRHRecolhaSerieSubsidiosEmp, IJsonRHRecolhaSerieSubsidiosProcess} from '../jsonRHRecolhaSerieSubsidios.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RHRecolhaSerieSubsidiosService} from '../rhRecolhaSerieSubsidios.module.service';
import {RHRecolhaSerieSubsidiosSubsSavedModalComponent} from '../modals/subssaved/rhRecolhaSerieSubsidios.subsSaved.modal.component';
import {TDate} from '../../../../../common/dates';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';

@Component({
  selector: 'module-rh-recolha-serie-subsidios',
  templateUrl: './rhRecolhaSerieSubsidios.module.component.html'
})
export class RHRecolhaSerieSubsidiosModuleComponent extends ModuloComponent implements OnInit {
  @Input() public rhRecolhaSerieSubsidiosEmpList: Array<IJsonRHRecolhaSerieSubsidiosEmp>;

  public readonly tiposSubsidio: typeof ETipoSubsidio;
  public readonly sitEmprego: typeof ESitEmprego;
  public readonly tipoSubsidio: ETipoSubsidio;
  public readonly tipoProcessFilter: string;
  public readonly tipoProcessOutput: string;
  public readonly dataGrid: IDevExpressDataGrid;

  public selectedEmps: Array<void>;
  public promise: Promise<void>;
  public rhRecolhaSerieSubsidios: IJsonRHRecolhaSerieSubsidios;
  public situacaoEmprego: ESitEmprego;
  public codEmpDe: number;
  public codEmpAte: number;

  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhrecolhaSerieSubsidiosService: RHRecolhaSerieSubsidiosService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.tiposSubsidio = ETipoSubsidio;
    this.sitEmprego = ESitEmprego;
    this.pesquisar = this.pesquisar.bind(this);
    this.rhRecolhaSerieSubsidios = {
      tipoSubsidio: undefined,
      dataParaPagarSubsidio: moment(),
      dataReferencia: moment(),
      nomeTipoProcessamento: '',
      tipoProcessamento: 0,
      nDiasSubsidioAlimentacao: 0
    };
    this.tipoProcessOutput = '{{tipoProcessamento}} - {{descricao}}';
    this.codEmpDe = 0;
    this.codEmpAte = EDelphiNumberTypes.MaxSmallInt;
    this.situacaoEmprego = ESitEmprego.Ativo;

    const params: IRecolhaSerieSubsidiosParams = <IRecolhaSerieSubsidiosParams>this._transition.params();
    const moduleName: string = params.moduleName;
    switch (moduleName) {
      case MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_FERIAS:
        this.tipoProcessFilter = 'subsidioFerias=true';
        this.tipoSubsidio = ETipoSubsidio.SubFerias;
        break;
      case MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_NATAL:
        this.tipoProcessFilter = 'subsidioNatal=true';
        this.tipoSubsidio = ETipoSubsidio.SubNatal;
        break;
      case MODULE_NAME_RH_RECOLHA_SERIE_SUBSIDIOS_ALIMENTACAO:
        this.tipoProcessFilter = 'subsidioRefeicao=true';
        this.tipoSubsidio = ETipoSubsidio.SubAlimentacaoPreProce;
        break;
    }

    this.dataGrid = {
      columns: [
        {dataField: 'codEmp', dataType: 'string', caption: 'rhrecolhaseriesubsidios.empTable.codEmp', allowEditing: false},
        {dataField: 'nomeEmp', dataType: 'string', caption: 'rhrecolhaseriesubsidios.empTable.nomeEmp', allowEditing: false},
        {dataField: 'nBI', dataType: 'string', caption: 'rhrecolhaseriesubsidios.empTable.nBI', allowEditing: false},
        {dataField: 'contribuinte', dataType: 'string', caption: 'rhrecolhaseriesubsidios.empTable.contribuinte', allowEditing: false},
        {dataField: 'dataNascimento', dataType: 'date', caption: 'rhrecolhaseriesubsidios.empTable.dataNascimento', allowEditing: false}
      ],
      keyExpr: 'codEmp',
      allowColumnReordering: false,
      columnFixing: {enabled: false},
      editing: {mode: 'cell', allowUpdating: true},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      remoteOperations: false,
      searchPanel: {visible: true},
      selection: {
        mode: 'multiple',
        showCheckBoxesMode: 'always'
      },
      showBorders: true,
      sorting: {mode: 'none'}
    };

    this.finalize = this.finalize.bind(this);
  }

  public ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.promise = this._rhrecolhaSerieSubsidiosService.getDadosDiaPagamentoDefault(this.tipoSubsidio).then((response: HttpResponse<IJsonRHRecolhaSerieSubsidios>) => {
      this.rhRecolhaSerieSubsidios = response.body;
    });
    return this.promise;
  }

  public async pesquisar(): Promise<void> {
    try {
      const response: HttpResponse<Array<IJsonRHRecolhaSerieSubsidiosEmp>> = await this._rhrecolhaSerieSubsidiosService.getListaEmpregados(this.codEmpDe, this.codEmpAte, this.situacaoEmprego);

      this.rhRecolhaSerieSubsidiosEmpList = response.body;
      if (response.body?.length) {
        this._cardPanel.collapse();
      } else {
        this._cardPanel.focusFirstElement();
        this._plAlertService.info('global.text.searchNoData');
      }
    } catch (e) {
      this._cardPanel.focusFirstElement();
    }
  }

  public changedDataReferencia(value: TDate): void {
    this.rhRecolhaSerieSubsidios.dataReferencia = value;
    if (this.tipoSubsidio !== this.tiposSubsidio.SubAlimentacaoPreProce) {
      return;
    }
    this.promise = this._rhrecolhaSerieSubsidiosService.postDatareferenciachange(this.rhRecolhaSerieSubsidios).then((response: HttpResponse<IJsonRHRecolhaSerieSubsidios>) => {
      this.rhRecolhaSerieSubsidios = response.body;
    });
  }

  public finalize(): Promise<void> {
    if (!this.selectedEmps.length) {
      this._plAlertService.error('rhrecolhaseriesubsidios.messages.selEmp');
      return Promise.resolve();
    }
    if (!this.rhRecolhaSerieSubsidios.tipoProcessamento && this.rhRecolhaSerieSubsidios.tipoProcessamento !== 0) {
      this._plAlertService.error('rhrecolhaseriesubsidios.messages.tipoProcEmpty');
      return Promise.resolve();
    }
    const emplist: string = this.selectedEmps.join(',');
    this.promise = this._rhrecolhaSerieSubsidiosService.postProcessarSubsidio(emplist, this.rhRecolhaSerieSubsidios).then((response: HttpResponse<Array<IJsonRHRecolhaSerieSubsidiosProcess>>) => {
      const modalInstance = this._cgModalService.showVanilla(RHRecolhaSerieSubsidiosSubsSavedModalComponent);
      const componentInstance: RHRecolhaSerieSubsidiosSubsSavedModalComponent = modalInstance.componentInstance;
      componentInstance.rhRecolhaSerieSubsidiosProcessList = response.body;
      componentInstance.tipoSubsidio = this.tipoSubsidio;
      componentInstance.rhRecolhaSerieSubsidios = this.rhRecolhaSerieSubsidios;
      componentInstance.emplist = emplist;
      return modalInstance.result.then(() => this._stateService.reload(this._uiRouterGlobals.current)).then(() => undefined);
    });
    return this.promise;
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }
}
