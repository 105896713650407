import {IJsonDocComercialCab, IJsonDocComercialLinha} from '../../jsonDocComercial.entity.interface';

export enum EPrecoUnitarioFields {
  PRUNITARIOSEMIVAQTDONE,
  PRUNITARIOCOMIVAQTDONE,
  PRUNITARIOSEMIVAQTDLINE,
  PRUNITARIOCOMIVAQTDLINE
}

export interface IPrecoUnitarioModalInput {
  cab: IJsonDocComercialCab;
  linha: IJsonDocComercialLinha;
}

export interface IPrecoUnitarioModalModel {
  prUnitarioSemIvaQtdOne: number;
  prUnitarioComIvaQtdOne: number;
  prUnitarioSemIvaQtdLine: number;
  prUnitarioComIvaQtdLine: number;
}
