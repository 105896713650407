import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_SEXOS} from './sexos.datasource.interface';
import {ESexo} from '../../modules/portalrh/dgempsfull/jsonDGEMPFull.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_SEXOS: IDataSource<ESexo> = deepFreeze({
  name: DATA_SOURCE_NAME_SEXOS,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ESexo.Feminino, name: 'dgemps.sexes.0', label: 'dgemps.sexes.0'},
    {value: ESexo.Masculino, name: 'dgemps.sexes.1', label: 'dgemps.sexes.1'},
    {value: ESexo.NaoDefinido, name: 'dgemps.sexes.2', label: 'dgemps.sexes.2'}
  ]
});
