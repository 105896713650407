import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonRastreabilidadeFaturacao} from './jsonRastreabilidade.interface';
import {IDevExpressDiagramContextMenu, IDevExpressDiagramNodes, IDevExpressDiagramViewToolbar} from '../../../../components/devexpress/diagram/devexpress.diagram.interface';
import {TranslateService} from '@ngx-translate/core';
import {IRastreabilidadeFaturacao} from './rastreabilidade.interface';
import {isEmpty, isNumber} from 'pl-comps-angular';
import {IDocsComerciaisEntityService} from '../../docsComerciais.entity.interface';
import {IJsonDocComercial} from '../../jsonDocComercial.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {HttpResponse} from '@angular/common/http';
import ArrayStore from 'devextreme/data/array_store';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../../modules/portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {IJsonDocContabilidade} from '../../../../modules/portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import dxDiagram, {ContentReadyEvent, InitializedEvent, Item, SelectionChangedEvent} from 'devextreme/ui/diagram';
import {DocumentosService} from '../../service/documentos.entity.service';

@Component({
  selector: 'documento-rastreabilidade-modal',
  templateUrl: './documento.rastreabilidade.modal.component.html'
})
export class DocumentoRastreabilidadeModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public rastreabilidade: Array<IJsonRastreabilidadeFaturacao>;
  @Input() public faccbId: number;

  public readonly diagramNodes: IDevExpressDiagramNodes<IRastreabilidadeFaturacao>;
  public readonly diagramContextMenu: IDevExpressDiagramContextMenu;
  public readonly diagramViewToolbar: IDevExpressDiagramViewToolbar;

  public selected: IRastreabilidadeFaturacao;
  public promise: Promise<void>;

  private readonly _serviceDocsComerciais: IDocsComerciaisEntityService;
  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private readonly _listRastreabilidade: Array<IRastreabilidadeFaturacao>;
  private _diagramInstance: dxDiagram;
  private _itemToSelectedDataSource: IRastreabilidadeFaturacao;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _documentosService: DocumentosService
  ) {
    super(_injector);
    this._listRastreabilidade = [];
    this._serviceDocsComerciais = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>('docscomerciais');
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.diagramNodes = {
      autoLayout: {
        type: 'tree'
      },
      dataSource: [],
      styleExpr: (dataItem: IRastreabilidadeFaturacao) => {
        return {
          stroke: !isEmpty(dataItem.extPocCabID) ? 'rgba(255, 0, 0, 0.5)' : '#2196F3'
        };
      },
      itemsExpr: '_items',
      textExpr: 'descricao',
      keyExpr: '_internalID'
    };
    this.diagramViewToolbar = {
      commands: [
        {
          name: 'zoomLevel',
          items: [],
          text: 'Zoom'
        },
        {
          name: 'fullScreen',
          text: 'Full-Screen'
        },
        {
          name: 'export',
          icon: 'export',
          text: this._translateService.instant('global.btn.export'),
          items: [
            {name: 'exportSvg', text: this._translateService.instant('rastreabilidade.export.svg')},
            {name: 'exportPng', text: this._translateService.instant('rastreabilidade.export.png')},
            {name: 'exportJpg', text: this._translateService.instant('rastreabilidade.export.jpg')}
          ]
        }
      ]
    };
    this.diagramContextMenu = {enabled: false};
  }

  public ngOnInit(): void {
    const dataSource: Array<IRastreabilidadeFaturacao> = this._getDataSource();
    this.diagramNodes.dataSource = new ArrayStore({
      key: '_internalID',
      data: dataSource
    });
  }

  public onInitialized({component}: InitializedEvent): void {
    this._diagramInstance = component;
  }

  public onContentReady({component}: ContentReadyEvent): void {
    setTimeout(() => {
      component.option('autoZoomMode', 'fitContent');
    });
    if (isNumber(this.faccbId) && this.faccbId !== 0) {
      this._getDataSourceItemKey(this._listRastreabilidade);
      if (this._itemToSelectedDataSource) {
        const item: Item = component.getItemByKey(this._itemToSelectedDataSource._internalID);
        if (item) {
          component.setSelectedItems([item]);
          this.promise = this._getDocumentSelected(this._itemToSelectedDataSource);

          Promise.resolve(this.promise)
            .then(() => {
              this.selected = this._itemToSelectedDataSource;
            })
            .finally(() => {
              this.promise = undefined;
            });
        }
      }
    }
  }

  public onResizerValuesChanged(): void {
    const autoZoomMode: string = this._diagramInstance.option('autoZoomMode');
    if (autoZoomMode === 'fitContent' || autoZoomMode === 'fitWidth') {
      setTimeout(() => {
        this._diagramInstance.repaint();
      });
    }
  }

  public onSelectionChanged(event: SelectionChangedEvent): Promise<void> {
    if (event.items.length !== 1) {
      if (this.selected) {
        const item: Item = event.component.getItemByKey(this.selected._internalID);
        event.component.setSelectedItems([item]);
      }
      return Promise.resolve();
    }

    if (event.items[0].itemType === 'connector') {
      return Promise.resolve();
    }

    this.selected = event.items[0].dataItem;
    this.promise = this._getDocumentSelected(event.items[0].dataItem);
    return this.promise;
  }

  public readonly fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this._getPdf(doc);

  private _getDataSource(): Array<IRastreabilidadeFaturacao> {
    let firstNode: IRastreabilidadeFaturacao;
    let idx = 0;
    for (firstNode of this.rastreabilidade) {
      if (firstNode.parentInternalId === 0) {
        firstNode = {...firstNode, _internalID: firstNode.internalId};
        firstNode._items = this.rastreabilidade.filter((value: IJsonRastreabilidadeFaturacao) => value.parentFaccbID === firstNode.faccbID && value.parentInternalId === firstNode.internalId);
        if (!isEmpty(firstNode.extPocCabID)) {
          const itemsExtpoc = this.rastreabilidade.filter(
            (value: IJsonRastreabilidadeFaturacao) => value.parentExtPocCabID === firstNode.extPocCabID && value.parentInternalId === firstNode.internalId
          );
          firstNode._items = firstNode._items.concat(itemsExtpoc);
        }
        this._listRastreabilidade.push(firstNode);
        for (const item of this._listRastreabilidade[idx]._items) {
          item._internalID = item.internalId;
          this._getNodeChilds(item, item.internalId);
        }
      }
      idx++;
    }
    return this._listRastreabilidade;
  }

  private _getNodeChilds(node: IRastreabilidadeFaturacao, id: number): void {
    node._items = this.rastreabilidade.filter((value: IJsonRastreabilidadeFaturacao) => value.parentFaccbID === node.faccbID && value.parentInternalId === id);
    if (!isEmpty(node.extPocCabID)) {
      const itemsExtpoc = this.rastreabilidade.filter((value: IJsonRastreabilidadeFaturacao) => value.parentExtPocCabID === node.extPocCabID && value.parentInternalId === id);
      node._items = node._items.concat(itemsExtpoc);
    }
    for (const item of node._items) {
      item._internalID = item.internalId;
      this._getNodeChilds(item, item.internalId);
    }
  }

  private _getDocumentSelected(dataItem: IRastreabilidadeFaturacao): Promise<void> {
    if (dataItem.extPocCabID) {
      if (!dataItem._docContabilidade) {
        return this._serviceDocsContabilidade
          .get({id: dataItem.extPocCabID})
          .then((response: HttpResponse<IJsonDocContabilidade>) => {
            dataItem._docContabilidade = response.body;
          })
          .finally(() => {
            return undefined;
          });
      }
    } else if (!dataItem._doc) {
      return this._serviceDocsComerciais
        .get({id: dataItem.faccbID})
        .then((response: HttpResponse<IJsonDocComercial>) => {
          dataItem._doc = response.body;
        })
        .finally(() => {
          return undefined;
        });
    }
    return Promise.resolve();
  }

  private _getDataSourceItemKey(list: Array<IRastreabilidadeFaturacao>): void {
    for (const item of list) {
      if (this.faccbId === item.faccbID && isEmpty(item.extPocCabID)) {
        this._itemToSelectedDataSource = item;
      }
      this._getDataSourceItemKey(item._items);
    }
  }

  private _getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc);
  }
}
