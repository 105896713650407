import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonCRSS} from './jsonCRSS.entity.interface';
import {ENTITY_NAME_CRSS} from './crss.entity.interface';
import {CRSSEditComponent} from './components/edit/crss.entity.edit.component';

export const ENTITY_CRSS: IEntityDefinition<IJsonCRSS> = {
  name: ENTITY_NAME_CRSS,
  roles: [ROLE.RH],
  searchPlaceholder: 'crss.pesquisa',
  pageTitle: 'global.menu.crss',
  metadata: {
    keyName: 'ncrss',
    fields: [
      {name: 'ncrss', type: 'cgsmallint', caption: 'crss.fields.ncrss', placeholder: 'crss.fields.ncrss'},
      {name: 'nome', type: 'text', caption: 'crss.fields.nome', placeholder: 'crss.fields.nome'},
      {name: 'designaBreve', type: 'text', caption: 'crss.fields.designaBreve', placeholder: 'crss.fields.designaBreve'},
      {name: 'morada', type: 'text', caption: 'crss.fields.morada', placeholder: 'crss.fields.morada'},
      {name: 'localidade', type: 'text', caption: 'crss.fields.localidade', placeholder: 'crss.fields.localidade'},
      {name: 'npostal', type: 'text', caption: 'crss.fields.npostal', placeholder: 'crss.fields.npostal'},
      {name: 'telefone', type: 'text', caption: 'crss.fields.telefone', placeholder: 'crss.fields.telefone'},
      {name: 'fax', type: 'text', caption: 'crss.fields.fax', placeholder: 'crss.fields.fax'},
      {name: 'nbenefCaixa', type: 'text', caption: 'crss.fields.nbenefCaixa', placeholder: 'crss.fields.nbenefCaixa'},
      {name: 'crssSedeSocial', type: 'boolean', caption: 'crss.fields.crssSedeSocial', placeholder: 'crss.fields.crssSedeSocial'},
      {name: 'idcrss', type: 'cginteger', caption: 'crss.fields.idcrss', placeholder: 'crss.fields.idcrss', visible: false},
      {name: 'nifSegSocial', type: 'text', caption: 'crss.fields.nifSegSocial', placeholder: 'crss.fields.nifSegSocial'},
      {name: 'nifCGA', type: 'text', caption: 'crss.fields.nifCGA', placeholder: 'crss.fields.nifCGA'},
      {name: 'nifADSE', type: 'text', caption: 'crss.fields.nifADSE', placeholder: 'crss.fields.nifADSE'}
    ],
    order: 'ncrss',
    searchFields: 'ncrss,nome',
    listFields: 'ncrss,nome'
  },
  autocomplete: {
    rowTemplate: '{{ncrss}} - {{nome}}',
    output: 'nome',
    searchFields: 'ncrss,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    duplicate: true,
    search: true,
    filter: true
  },
  detail: {
    state: {
      component: CRSSEditComponent,
      data: {
        formTemplate: {
          addMissingFields: false,
          items: [
            {type: 'group', order: 1, fields: [{field: 'ncrss'}, {field: 'nome'}]},
            {type: 'group', order: 2, fields: [{field: 'designaBreve'}, {field: 'morada'}]},
            {type: 'group', order: 3, fields: [{field: 'localidade'}, {field: 'npostal'}]},
            {type: 'group', order: 4, fields: [{field: 'telefone'}, {field: 'fax'}]},
            {type: 'group', order: 5, fields: [{field: 'nbenefCaixa'}, {field: 'crssSedeSocial'}]},
            {type: 'group', order: 6, fields: [{field: 'nifSegSocial'}, {field: 'nifCGA'}, {field: 'nifADSE'}]}
          ]
        }
      }
    }
  }
};
