import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IPlTableDefinition, IPlTableOptions, isDefined, PlTableComponent} from 'pl-comps-angular';
import {ApiService} from '../../../../services/api/api.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ContabilidadeSvatBalanceteModalComponent} from './modals/balancete/contabilidade.svat.balancete.modal.component';
import {ContabilidadeSvatPrintComponent} from './modals/print/contabilidade.svat.print.modal.component';
import {IJsonDfSVAT} from './jsonDfSVAT.module.interface';
import {TServiceResponse} from '../../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class SvatService {
  private readonly _path: string;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _translateService: TranslateService,
    private readonly _cgModalService: CGModalService
  ) {
    this._path = `${_apiService.path.restapi}/svat`;
  }

  public getDfSvat(anoEmCurso: number): TServiceResponse<IJsonDfSVAT> {
    return this._apiService.get<IJsonDfSVAT>({url: `${this._path}/dfsvat`, params: {anoEmCurso: anoEmCurso}});
  }

  public getPdfDfSvat(anoEmCurso: number, reportName: string): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({url: `${this._path}/report/pdf`, responseType: 'blob', params: {anoEmCurso: anoEmCurso, reportName: reportName}});
  }

  public openPrintModal(anoEmCurso: number): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ContabilidadeSvatPrintComponent);
    const componentInstance: ContabilidadeSvatPrintComponent = modalInstance.componentInstance;
    componentInstance.apiPath = this._path;
    componentInstance.modalTitle = this._translateService.instant('svat.modal.title', {anoEmCurso: anoEmCurso});
    componentInstance.anoEmCurso = anoEmCurso;
    return modalInstance.result;
  }

  public openBalanceteModal(settings?: {rubricaTitle?: string; tableDef?: IPlTableDefinition; tableSource?: PlTableComponent['source']; tableOptions?: IPlTableOptions}): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ContabilidadeSvatBalanceteModalComponent);
    const componentInstance: ContabilidadeSvatBalanceteModalComponent = modalInstance.componentInstance;
    componentInstance.modalTitle = 'svat.modal.titleBalancete';
    if (isDefined(settings)) {
      componentInstance.rubricaTitle = settings.rubricaTitle || '';
      componentInstance.tableDef = settings.tableDef || {fields: []};
      componentInstance.tableSource = settings.tableSource || [];
      componentInstance.tableOptions = settings.tableOptions || {};
    }
    return modalInstance.result;
  }
}
