import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {
  EImportadorSaftDirectInvoiceType,
  EImportadorSaftDirectTipoAgrupamento,
  EImportadorSaftDirectTipoAgrupamentoStr,
  EImportadorSaftDirectTipoRetencao,
  EImportadorSaftDirectTipoRetencaoStr,
  IJsonImportadorSaftDirectTipoDocumento
} from '../../importadorSaftDirect.module.interface';
import {isNullOrUndefined} from '@uirouter/core';

@Component({
  selector: 'importador-saft-direct-tipos-docs-modal',
  templateUrl: './importadorSaftDirect.tiposDocs.modal.component.html'
})
export class ImportadorSaftDirectTiposDocsModalComponent extends CGModalComponent<IJsonImportadorSaftDirectTipoDocumento> implements OnInit {
  @Input() public model: IJsonImportadorSaftDirectTipoDocumento;
  @Input() public showCCusto: boolean;
  @Input() public showRepCC: boolean;
  public agrupamentoDocsSource: Array<unknown>;
  public agrupamentoDocsTemplate: string;
  public agrupamentoDocsOutput: string;
  public tipoRetencaoSource: Array<unknown>;
  public tipoRetencaoTemplate: string;
  public tipoRetencaoOutput: string;
  public canChangeAgrupamentoDoc: boolean;
  public readonly invoiceTypes: typeof EImportadorSaftDirectInvoiceType;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.invoiceTypes = EImportadorSaftDirectInvoiceType;
  }

  public ngOnInit(): void {
    this.agrupamentoDocsSource = [
      {agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento.None, agrupamentoDocumentosStr: EImportadorSaftDirectTipoAgrupamentoStr.None},
      {agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento.PorDiaApenasCF, agrupamentoDocumentosStr: EImportadorSaftDirectTipoAgrupamentoStr.PorDiaApenasCF},
      {agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento.PorDiaContribuinte, agrupamentoDocumentosStr: EImportadorSaftDirectTipoAgrupamentoStr.PorDiaContribuinte},
      {agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento.ResumoDiaGlobal, agrupamentoDocumentosStr: EImportadorSaftDirectTipoAgrupamentoStr.ResumoDiaGlobal},
      {agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento.ResumoMensalGlobal, agrupamentoDocumentosStr: EImportadorSaftDirectTipoAgrupamentoStr.ResumoMensalGlobal},
      {agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento.ResumoMensalContribuinte, agrupamentoDocumentosStr: EImportadorSaftDirectTipoAgrupamentoStr.ResumoMensalContribuinte}
    ];
    this.agrupamentoDocsTemplate = '{{agrupamentoDocumentosStr}}';
    this.agrupamentoDocsOutput = 'agrupamentoDocumentosStr';

    this.tipoRetencaoSource = [
      {tipoRetencao: EImportadorSaftDirectTipoRetencao.None, tipoRetencaoStr: EImportadorSaftDirectTipoRetencaoStr.None},
      {tipoRetencao: EImportadorSaftDirectTipoRetencao.Imediata, tipoRetencaoStr: EImportadorSaftDirectTipoRetencaoStr.Imediata},
      {tipoRetencao: EImportadorSaftDirectTipoRetencao.NoPagamento, tipoRetencaoStr: EImportadorSaftDirectTipoRetencaoStr.NoPagamento},
      {tipoRetencao: EImportadorSaftDirectTipoRetencao.ImediataValorCCJaDeduzido, tipoRetencaoStr: EImportadorSaftDirectTipoRetencaoStr.ImediataValorCCJaDeduzido}
    ];
    this.tipoRetencaoTemplate = '{{tipoRetencaoStr}}';
    this.tipoRetencaoOutput = 'tipoRetencaoStr';
    this.canChangeAgrupamentoDoc =
      this.model.invoiceType === EImportadorSaftDirectInvoiceType.FaturaSimplificada ||
      this.model.invoiceType === EImportadorSaftDirectInvoiceType.Fatura ||
      this.model.invoiceType === EImportadorSaftDirectInvoiceType.FaturaRecibo ||
      this.model.invoiceType === EImportadorSaftDirectInvoiceType.VendaDinheiro ||
      this.model.invoiceType === EImportadorSaftDirectInvoiceType.TalaoVenda;
  }

  public changedTipoRetencao(value: {item: {tipoRetencao: EImportadorSaftDirectTipoRetencao; tipoRetencaoStr: EImportadorSaftDirectTipoRetencaoStr}}): void {
    this.model.tipoRetencao = value.item.tipoRetencao;
    this.model.tipoRetencaoStr = value.item.tipoRetencaoStr;
  }

  public fnAgrupamentoDocsChanged(value: {item: {agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento; agrupamentoDocumentosStr: EImportadorSaftDirectTipoAgrupamentoStr}}): void {
    this.model.agrupamentoDocumentos = value.item.agrupamentoDocumentos;
    this.model.agrupamentoDocumentosStr = value.item.agrupamentoDocumentosStr;
  }

  public onFormSubmit(): void {
    if (isNullOrUndefined(this.model.nDiario)) {
      this.model.nDiario = 0;
    }
    if (isNullOrUndefined(this.model.descritivo)) {
      this.model.descritivo = 0;
    }
    this.close(this.model);
  }
}
