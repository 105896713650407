import {Component, Injector} from '@angular/core';
import {EContabDigitalClientConnectType} from '../contabDigital.clientConnect.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';

@Component({
  selector: 'module-contabilidade-digital-client-connect',
  templateUrl: './contabDigital.clientConnect.module.component.html'
})
export class ContabilidadeDigitalClientConnectModuleComponent extends ModuloComponent {
  public readonly clientConnectTypes: typeof EContabDigitalClientConnectType;
  public clientConnectType: EContabDigitalClientConnectType;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.clientConnectTypes = EContabDigitalClientConnectType;
    this.clientConnectType = EContabDigitalClientConnectType.Native;
  }
}
