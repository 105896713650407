import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {IJsonREMDadosValoresUni, IJsonREMDecimaisComponentes, IJsonREMUNFX} from './jsonRHRecolhaDados.entity.interface';
import {IApiQueryResponse, TServiceQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';
import {IJsonNumber} from '../../../common/interfaces/json';

@Injectable({
  providedIn: 'root'
})
export class RHRecolhaDadosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = this._apiService.path.restapi;
  }

  public getDecimaisPorDefeito(entityName: string): TServiceResponse<IJsonREMDecimaisComponentes> {
    return this._apiService.get<IJsonREMDecimaisComponentes>({url: `${this._path}/${entityName}/decimaispordefeito`});
  }

  public getDefaultRecolhaDados(entityName: string): TServiceResponse<IJsonREMUNFX> {
    return this._apiService.get<IJsonREMUNFX>({url: `${this._path}/${entityName}/dadospordefeito`});
  }

  public getAnosComDados(): TServiceQueryResponse<IJsonNumber> {
    return this._apiService.get<IApiQueryResponse<IJsonNumber>>({url: `${this._path}/remun/anoscomdados`});
  }

  public getEmpRecolhaDadosManual(entityName: string, remun: IJsonREMUNFX): TServiceResponse<IJsonREMDadosValoresUni> {
    return this._apiService.post<IJsonREMDadosValoresUni, IJsonREMUNFX>({url: `${this._path}/${entityName}/dadosemp`, body: remun});
  }

  public postValoresUnitariosTableChanged(entityName: string, remvaloruni: IJsonREMDadosValoresUni): TServiceResponse<IJsonREMDadosValoresUni> {
    return this._apiService.post<IJsonREMDadosValoresUni>({url: `${this._path}/${entityName}/valorunitablechanged`, body: remvaloruni});
  }

  public postQuantidadeChanged(entityName: string, remvaloruni: IJsonREMDadosValoresUni): TServiceResponse<IJsonREMDadosValoresUni> {
    return this._apiService.post<IJsonREMDadosValoresUni>({url: `${this._path}/${entityName}/quantidadechanged`, body: remvaloruni});
  }

  public postValorChanged(entityName: string, remvaloruni: IJsonREMDadosValoresUni): TServiceResponse<IJsonREMDadosValoresUni> {
    return this._apiService.post<IJsonREMDadosValoresUni>({url: `${this._path}/${entityName}/valorchanged`, body: remvaloruni});
  }

  public postValorMEChanged(entityName: string, remvaloruni: IJsonREMDadosValoresUni): TServiceResponse<IJsonREMDadosValoresUni> {
    return this._apiService.post<IJsonREMDadosValoresUni>({url: `${this._path}/${entityName}/valormechanged`, body: remvaloruni});
  }

  public postCalculaNDiasSubRefeicao(entityName: string, remun: IJsonREMUNFX): TServiceResponse<IJsonREMUNFX> {
    return this._apiService.post<IJsonREMUNFX>({url: `${this._path}/${entityName}/calculasubrefeicao`, body: remun});
  }

  public postCambioChanged(entityName: string, remvaloruni: IJsonREMDadosValoresUni): TServiceResponse<IJsonREMDadosValoresUni> {
    return this._apiService.post<IJsonREMDadosValoresUni>({url: `${this._path}/${entityName}/cambiochanged`, body: remvaloruni});
  }
}
