export interface IEmpresasStoreWelcomeRequiredFields {
  nif: IEmpresasStoreWelcomeRequiredField;
}

export interface IEmpresasStoreWelcomeRequiredField {
  caption: string;
  invalid: boolean;
}

export interface IEmpresaTarifasModalModel {
  telefone: string;
  telemovel: string;
  mostraTarifasNasListagens: boolean;
}

export const MODULE_NAME_EMPRESAS = 'empresas';

export const EMPRESAS_STORE_WELCOME_REQUIRED_FIELDS: IEmpresasStoreWelcomeRequiredFields = Object.freeze<IEmpresasStoreWelcomeRequiredFields>({
  nif: {caption: 'empresas.storewelcome.required.nif', invalid: false}
});
