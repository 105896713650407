<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'docscomerciais.auxiliares.anexarDocumento'"></h4>
</div>

<div class="modal-body">
  <gdocs-viewer
    [folderId]="folderId"
    [showTreeViewer]="false"
    [treeCollapsed]="false"
    [params]="params"
    [tipoDocEntity]="tipoDocEntity"
    (evtAddingFile)="onGDocsViewerAddingFile($event)"
    (deleteFileEvent)="onGDocsDeleteFile()"
    [callback]="callbackGDocs">
  </gdocs-viewer>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-close"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
