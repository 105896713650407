<pl-alert *ngIf="inconsistContab" type="info" [closeable]="false">
  <span [innerHTML]="'dashboards.ativos.messages.contab' | translate"></span>&nbsp;
  <span class="hyperlink" [translate]="'dashboards.ativos.messages.contabLink'" (click)="redirectToAquisicaoContab()"></span>
</pl-alert>

<pl-alert *ngIf="verificacaoDeConsistenciaVenda.length" type="warning" [closeable]="false">
  <div *ngFor="let inconsistencia of verificacaoDeConsistenciaVenda" [innerHTML]="inconsistencia.error | translate"></div>
  <div class="hyperlink" [translate]="'ativos.verificacaoDeConsistenciaVenda.btn.redirectToVendaSerie'" (click)="redirectToVendaSerie()"></div>
</pl-alert>

<entity-list
  [entityName]="entityName"
  [maintenanceMode]="maintenanceMode"
  [toolbarInstanceId]="instanceName"
  [dataGridState]="dataGridState"
  [searchValue]="searchValue"
  [filter]="filterValue"
  [initialFilter]="initialFilterValue"
  [serviceMethodsOverride]="serviceMethodsOverride"
  [properties]="listOptions"
  (evtOnRefresh)="onRefreshList()"
  (evtDataGridPreparing)="onDataGridPreparing($event)"
  (evtDataGridCellClick)="onDataGridCellClick($event)"
  (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
  (evtDataGridCellPrepared)="onDataGridCellPrepared($event)"
  (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
  (evtDataGridStateChanged)="onDataGridStateChanged($event)"
  (evtDataGridStateCleared)="onDataGridStateCleared()">
</entity-list>

<div class="d-flex mt-3 list-ativos-totals">
  <div class="card">
    <div class="card-header">
      <span class="fa fa-tag float-start"></span>
      <span class="total-listagens float-end" [translate]="'ativos.listTotals.resumo'"></span>
    </div>
    <div class="card-body">
      <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTotals" [dataSource]="ativosTotals"></dx-data-grid>
    </div>
  </div>
</div>
