import {AfterContentChecked, Component, ContentChild, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef} from '@angular/core';
import type {IPlNavPillCallback, IPlNavPillEventSelected} from './navpill.interface';
import {isDefined, isObject} from '../common/utilities/utilities';
import {PlNavPillFooterDirective} from './navpill.footer.directive';
import {PlNavPillPanelDirective} from './navpill.panel.directive';

@Component({
  selector: 'pl-navpill',
  templateUrl: './navpill.component.html',
  standalone: false
})
export class PlNavPillComponent implements OnInit, AfterContentChecked {
  @Input() public attrName: string;
  @Input() public activeId: any;
  @Input() public disabled: boolean;
  @Input() public destroyOnHide: boolean;
  @Input() public callback: IPlNavPillCallback;
  @Output() public readonly activeIdChange: EventEmitter<any>;
  @Output() public readonly evtSelected: EventEmitter<IPlNavPillEventSelected>;

  @ContentChildren(PlNavPillPanelDirective, {descendants: true}) public readonly panels: QueryList<PlNavPillPanelDirective>;
  @ContentChild(PlNavPillFooterDirective, {read: TemplateRef}) public readonly templateRefFooter: TemplateRef<PlNavPillFooterDirective>;

  constructor() {
    this.activeIdChange = new EventEmitter<unknown>();
    this.evtSelected = new EventEmitter<IPlNavPillEventSelected>();
    this.destroyOnHide = true;
  }

  public ngOnInit(): void {
    if (isObject(this.callback)) {
      this.callback.select = (panelId: unknown) => {
        this.select(panelId);
      };
      this.callback.has = (panelId: unknown) => this.has(panelId);
    }
  }

  public ngAfterContentChecked(): void {
    // Auto correct activeId that might have been set incorrectly as input
    const activePanel: PlNavPillPanelDirective = this._getPanel(this.activeId);
    this.activeId = activePanel ? activePanel.id : this.panels.length ? this.panels.first.id : undefined;
  }

  public select(panelId: unknown): void {
    if (this.disabled) {
      return;
    }
    const selectedPanel = this._getPanel(panelId);
    if (selectedPanel && !selectedPanel.disabled && this.activeId !== selectedPanel.id) {
      let defaultPrevented = false;
      this.evtSelected.emit({
        activeId: this.activeId,
        nextId: selectedPanel.id,
        preventDefault: () => {
          defaultPrevented = true;
        }
      });
      if (!defaultPrevented) {
        this.activeId = selectedPanel.id;
        this.activeIdChange.emit(this.activeId);
      }
    }
  }

  public has(panelId: unknown): boolean {
    const panel: PlNavPillPanelDirective = this._getPanel(panelId);
    return isDefined(panel);
  }

  private _getPanel(id: unknown): PlNavPillPanelDirective {
    return this.panels.find((current: PlNavPillPanelDirective) => current.id === id);
  }
}
