import {EMonth} from 'pl-comps-angular';
import moment, {Moment, MomentInput} from 'moment';

export function isMoment(value: unknown): value is Moment {
  return moment.isMoment(value);
}

export function normalizeDate(value: MomentInput): Moment {
  return moment(value).set({hours: 12, minutes: 0, seconds: 0, milliseconds: 0});
}

export function momentDefaultValue(): Moment {
  return moment(0);
}

export function momentDateTime(year: number, month: number | EMonth = EMonth.January, day: number = 1, hours: number = 0, minutes: number = 0, seconds: number = 0, milliseconds: number = 0): Moment {
  const input = `${year}/${month + 1}/${day} ${hours}:${minutes}:${seconds}:${milliseconds}`;
  const format = 'YYYY/M/D H:m:s:S';
  return moment(input, format);
}

const HOURS_IN_A_DAY = 24;

export function momentAllDay(start: MomentInput, end: MomentInput): boolean {
  return Math.ceil(moment(end).diff(moment(start), 'hours', true)) === HOURS_IN_A_DAY;
}
