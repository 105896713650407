import {Component, Injector, Input, OnInit} from '@angular/core';
import {isNumber, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {CloneMenusModalComponent} from '../modals/clonemenusmodal/cloneMenus.modal.component';
import {devExpressDataGridExpandDetailHandler} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {ENTITY_NAME_PRH_REFEICOES} from '../../../../../entities/prhrefeicoes/pRHRefeicoes.entity.interface';
import {ENTITY_NAME_PRH_REL_EMENTAS} from '../../../../../entities/prhrelementas/pRHRelEmentas.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {GESTAO_DGEMPS_KEY_FORMAT, GESTAO_DGEMPS_MARCAR_END_DAY, GESTAO_DGEMPS_MARCAR_START_DAY} from '../../../../../components/gestaodgemps/gestaodgemps.interface';
import {IAssociarMenusMeta, IMenu} from '../associarMenus.module.interface';
import {IDevExpressDataGridEventOnCellClick} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonPRHLocal} from '../../../../../entities/prhlocais/jsonPRHLocal.entity.interface';
import {IJsonPRHMenu} from '../../../../../interfaces/jsonPRHMenu.interface';
import {IJsonPRHRefeicoes} from '../../../../../entities/prhrefeicoes/jsonPRHRefeicoes.entity.interface';
import {IJsonPRHRelEmentas} from '../../../../../entities/prhrelementas/jsonPRHRelEmentas.entity.interface';
import {IJsonPRHTipoRefeicao} from '../../../../../entities/prhtiporefeicoes/jsonPRHTipoRefeicao.entity.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import moment, {Moment} from 'moment';
import {THttpQueryResponse} from '../../../../../services/api/api.service.interface';

/* eslint-disable @typescript-eslint/no-restricted-types */

@Component({
  selector: 'colaboradores-associarmenus',
  templateUrl: './associarMenus.module.component.html'
})
export class AssociarMenusComponent extends ModuloComponent implements OnInit {
  @Input() public locais: Array<IJsonPRHLocal>;
  @Input() public menus: Array<IMenu>;
  @Input() public tiposRefeicoes: Array<IJsonPRHTipoRefeicao>;

  public readonly dataGridDefition: IDevExpressDataGrid<IMenu, number>;
  public readonly view: unknown;
  public local: IJsonPRHLocal;
  public tipoRefeicao: IJsonPRHTipoRefeicao;
  public viewDate: Moment;
  public events: Array<unknown>;
  public cellIsOpen: boolean;

  private readonly _servicePRHRelEmentas: IEntityService<IJsonPRHRelEmentas>;
  private readonly _servicePRHRefeicoes: IEntityService<IJsonPRHRefeicoes>;
  private readonly _actions: Array<unknown>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.dataGridDefition = {
      columns: [
        {dataField: 'titulo', dataType: 'string', caption: 'Menu'},
        {type: 'buttons', cellTemplate: 'dataGridCellTemplateBtn'}
      ],
      keyExpr: 'codMenu',
      masterDetail: {
        enabled: true,
        template: 'dataGridTemplateDetail'
      },
      remoteOperations: false
    };
    this.viewDate = moment();
    this.events = [];
    this.cellIsOpen = false;
    this._actions = [
      {
        label: '<i class="fa fa-fw fa-times"></i>',
        onClick: ({event}: {event: {meta: {codPRHRelEmentas: number}}}): void => {
          this._removeEvent(event);
        }
      }
    ];
    this._servicePRHRelEmentas = this._entityServiceBuilder.build(ENTITY_NAME_PRH_REL_EMENTAS);
    this._servicePRHRefeicoes = this._entityServiceBuilder.build(ENTITY_NAME_PRH_REFEICOES);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.locais.length === 1) {
      this.local = this.locais[0];
    }
    if (this.tiposRefeicoes.length === 1) {
      this.tipoRefeicao = this.tiposRefeicoes[0];
    }
    this._refreshEvents();
  }

  public changedLocal(value: IJsonPRHLocal): void {
    this.local = value;
    this._refreshEvents();
  }

  public changedViewDate(value: Moment): void {
    this.viewDate = value;
    this._refreshEvents();
  }

  public dayClicked(event: {day: {date: Moment; events: Array<unknown>; inMonth: boolean}}): void {
    const date: Moment = moment(event.day.date);
    const day: {date: Moment; events: Array<unknown>; inMonth: boolean} = event.day;
    this.cellIsOpen = day.events.length > 0 && day.inMonth && (!this.cellIsOpen || !moment(day.date).isSame(this.viewDate, 'day'));
    const refresh = !date.isSame(this.viewDate, 'month');
    this.viewDate = event.day.date;
    if (refresh) {
      this._refreshEvents();
    }
  }

  public onDataGridCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonPRHMenu, number>): void {
    devExpressDataGridExpandDetailHandler(event);
  }

  public readonly fnCloneMenus = (): Promise<void> => this._cloneMenus();

  public readonly fnAssociaMenu = (item: IMenu) => (): Promise<void> => this._associaMenu(item);

  private _refreshEvents(): Promise<void> {
    if (!isNumber(this.local?.codLocal)) {
      this.events = [];
      this._evaluateMenus();
      return Promise.resolve();
    }
    const viewDate: Moment = moment(this.viewDate);
    const dataDe: string = viewDate.clone().subtract(1, 'month').date(GESTAO_DGEMPS_MARCAR_START_DAY).format(GESTAO_DGEMPS_KEY_FORMAT);
    const dataAte: string = viewDate.clone().add(1, 'month').date(GESTAO_DGEMPS_MARCAR_END_DAY).format(GESTAO_DGEMPS_KEY_FORMAT);
    return this._servicePRHRelEmentas.query({pesquisa: `codLocal=${String(this.local.codLocal)}&data>=${dataDe}&data<=${dataAte}`}).then((response: THttpQueryResponse<IJsonPRHRelEmentas>) => {
      this.events = response.body.list.map((relEmenta: IJsonPRHRelEmentas) => {
        const horaRefeicao: Moment = moment(relEmenta.tpRefeicao.horaRefeicao);
        const date: Date = moment(relEmenta.data)
          .set({
            hours: horaRefeicao.hours(),
            minutes: horaRefeicao.minutes(),
            seconds: horaRefeicao.seconds(),
            milliseconds: horaRefeicao.milliseconds()
          })
          .toDate();
        return {
          title: relEmenta.menu.titulo,
          start: date,
          end: date,
          color: {
            primary: relEmenta.tpRefeicao.corPrimaria,
            secondary: relEmenta.tpRefeicao.corSecundaria
          },
          actions: this._actions,
          meta: {
            codPRHRelEmentas: relEmenta.codPRHRelEmentas,
            codMenu: relEmenta.codMenu,
            codLocal: relEmenta.codLocal
          }
        };
      });
      this._evaluateMenus();
    });
  }

  private _evaluateMenus(): void {
    const viewDate: Moment = moment(this.viewDate);
    for (const menu of this.menus) {
      menu.alreadyUsed = false;
      for (const event of this.events) {
        const meta: IAssociarMenusMeta = (<{meta: IAssociarMenusMeta}>event).meta;
        if (meta.codMenu === menu.codMenu && meta.codLocal === this.local.codLocal && moment((<{start: Moment}>event).start).isSame(viewDate, 'date')) {
          menu.alreadyUsed = true;
          break;
        }
      }
    }
  }

  private _cloneMenus(): Promise<void> {
    if (!this.local) {
      return Promise.resolve();
    }
    const modalInstance = this._cgModalService.showVanilla(CloneMenusModalComponent);
    const componentInstance: CloneMenusModalComponent = modalInstance.componentInstance;
    componentInstance.codLocal = this.local.codLocal;
    return modalInstance.result.then(() => this._refreshEvents());
  }

  private _associaMenu(item: IMenu): Promise<void> {
    if (!this.tipoRefeicao) {
      return Promise.resolve();
    }
    const horaRefeicao: Moment = moment(this.tipoRefeicao.horaRefeicao);
    const date: Moment = moment(this.viewDate).set({
      hours: horaRefeicao.hours(),
      minutes: horaRefeicao.minutes(),
      seconds: horaRefeicao.seconds(),
      milliseconds: horaRefeicao.milliseconds()
    });
    const relEmentas: Partial<IJsonPRHRelEmentas> = {
      codLocal: this.local.codLocal,
      codMenu: item.codMenu,
      data: date,
      tipoRefeicao: this.tipoRefeicao.tipoRefeicao
    };
    return this._servicePRHRelEmentas.post({body: relEmentas}).then(() => this._refreshEvents());
  }

  private _removeEvent(event: {meta: {codPRHRelEmentas: number}}): Promise<void> {
    return this._servicePRHRefeicoes.query({pesquisa: `codPRHRelEmentas=${event.meta.codPRHRelEmentas}`}).then((response: THttpQueryResponse<IJsonPRHRefeicoes>) => {
      if (response.body.list.length > 0) {
        this._plAlertService.error('prhrelementas.menusAssociados');
        return Promise.resolve();
      }
      return this._servicePRHRelEmentas
        .delete({id: event.meta.codPRHRelEmentas})
        .then(() => this._refreshEvents())
        .catch((reason: unknown) => {
          this._logger.error(reason);
          this._plAlertService.error('prhrelementas.error');
        });
    });
  }
}

/* eslint-enable @typescript-eslint/no-restricted-types */
