import {IDataSourceItem} from '../datasources.interface';
import {IEntityAutocompleteDefinition} from '../../entity/entity.autocomplete.definition.interface';

export interface IDataSource<T, S = IDataSourceItem<T>> {
  readonly name: string;
  readonly autocomplete: IDataSourceAutocomplete;
  readonly data: ReadonlyArray<S>;
}

export interface IDataSourceAutocomplete extends IEntityAutocompleteDefinition {
  readonly valueExpr: string;
}

export const dataSourceInputComponentName = 'inputDataSource';
