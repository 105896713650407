import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {ImportXLSModuleComponent} from './components/importXLS.module.component';
import {MODULE_NAME_IMPORT_XLS} from './importXLS.module.interface';

export const MODULE_IMPORT_XLS: IModuleDefinition = {
  name: MODULE_NAME_IMPORT_XLS,
  state: {
    url: `/${MODULE_NAME_IMPORT_XLS}`,
    component: ImportXLSModuleComponent,
    data: {
      roles: [ROLE.ERP],
      icon: 'fa-download'
    }
  }
};
