import {Directive, Injector, Input, OnInit} from '@angular/core';
import {isBoolean, PlI18nService, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {IGestaoDGEMPSCalendario, IGestaoDGEMPSMarcarModalData} from '../../gestaodgemps.interface';
import {IJsonPRHEventosConfig} from '../../../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';

@Directive()
export abstract class GestaoDGEMPSMarcarAbstractModalComponent extends CGModalComponent<ReadonlySet<IGestaoDGEMPSCalendario>> implements OnInit {
  @Input() public codEmpLanca: number;
  @Input() public managerMode: boolean;
  @Input() public codServico: number;
  @Input() public multiSelect: boolean;
  @Input() public configEvents: Array<IJsonPRHEventosConfig>;
  @Input() public marcarData: IGestaoDGEMPSMarcarModalData;

  public textMarcarDate: string;

  protected readonly _successfulCalendarios: Set<IGestaoDGEMPSCalendario>;

  protected constructor(
    protected readonly _injector: Injector,
    protected readonly _plI18nService: PlI18nService,
    protected readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this.managerMode = false;
    this.multiSelect = false;
    this._successfulCalendarios = new Set<IGestaoDGEMPSCalendario>();
  }

  public ngOnInit(): void {
    if (!isBoolean(this.managerMode)) {
      this.managerMode = false;
    }
    if (!isBoolean(this.multiSelect)) {
      this.multiSelect = false;
    }
    this.textMarcarDate = this.marcarData.startDate.isSame(this.marcarData.endDate, 'day')
      ? this._plTranslateService.translate('gestaodgemps.text.day', {date: this._plI18nService.formatDate(this.marcarData.startDate)})
      : this._plTranslateService.translate('gestaodgemps.text.fromTo', {
          start: this._plI18nService.formatDate(this.marcarData.startDate),
          end: this._plI18nService.formatDate(this.marcarData.endDate)
        });
  }

  public close(): void {
    super.close(this._successfulCalendarios);
  }
}
