import {ENTITY_NAME_TIPOS_REGISTO_CADASTRO} from './tiposRegistoCadastro.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonTiposRegistoCadastro} from './jsonTiposRegistoCadastro.entity';
import {TiposRegistoCadastroEditComponent} from './components/edit/tiposRegistoCadastro.entity.edit.component';
import {ROLE} from '../../services/role.const';

export const ENTITY_TIPOS_REGISTO_CADASTRO: IEntityDefinition<IJsonTiposRegistoCadastro> = {
  name: ENTITY_NAME_TIPOS_REGISTO_CADASTRO,
  roles: [ROLE.RH],
  searchPlaceholder: 'tiposregistocadastro.pesquisa',
  pageTitle: 'global.menu.tiposregistocadastro',
  metadata: {
    keyName: 'nMotivoMov',
    fields: [
      {name: 'nMotivoMov', caption: 'tiposregistocadastro.fields.nMotivoMov', properties: {validators: {required: {value: true}, maxlength: 4}}},
      {name: 'designaBreve', caption: 'tiposregistocadastro.fields.designaBreve', properties: {validators: {required: {value: true}}}},
      {name: 'designaCompl', caption: 'tiposregistocadastro.fields.designaCompl'},
      {name: 'codQPMOT', caption: 'tiposregistocadastro.fields.codQPMOT'},
      {name: 'tipoClasseA', type: 'cgsmallint', caption: 'tiposregistocadastro.fields.tipoClasseA', placeholder: 'tiposregistocadastro.fields.tipoClasseA'},
      {name: 'tipoClasseB', type: 'cgsmallint', caption: 'tiposregistocadastro.fields.tipoClasseB', placeholder: 'tiposregistocadastro.fields.tipoClasseB'},
      {name: 'tipoClasseC', type: 'cgsmallint', caption: 'tiposregistocadastro.fields.tipoClasseC', placeholder: 'tiposregistocadastro.fields.tipoClasseC'},
      {name: 'tipoClasseD', type: 'cgsmallint', caption: 'tiposregistocadastro.fields.tipoClasseD', placeholder: 'tiposregistocadastro.fields.tipoClasseD'},
      {name: 'tipoClasseE', type: 'cgsmallint', caption: 'tiposregistocadastro.fields.tipoClasseE', placeholder: 'tiposregistocadastro.fields.tipoClasseE'},
      {name: 'tipoClasseF', type: 'cgsmallint', caption: 'tiposregistocadastro.fields.tipoClasseF', placeholder: 'tiposregistocadastro.fields.tipoClasseF'}
    ],
    order: 'nMotivoMov',
    searchFields: 'nMotivoMov,designaBreve,designaCompl',
    listFields: 'nMotivoMov,designaBreve,designaCompl'
  },
  autocomplete: {
    rowTemplate: '{{nMotivoMov}} - {{designaBreve}}',
    output: '{{nMotivoMov}} - {{designaBreve}}',
    outputDescription: 'designaBreve',
    searchFields: 'nMotivoMov,designaBreve,designaCompl'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  detail: {
    state: {
      component: TiposRegistoCadastroEditComponent
    }
  }
};
