import {Component, Injector, OnDestroy} from '@angular/core';
import {ConfigOptionsService} from '../../../../services/config/options/config.options.service';
import {MovimentosPendentesModuleComponent} from '../../movimentos.pendentes.module.component';

@Component({
  selector: 'module-movimentos-pendentes-outros-devedores-credores',
  templateUrl: './movimentos.pendentes.outrosDevedoresCredores.module.component.html'
})
export class MovimentosPendentesOutrosDevedoresCredoresModuleComponent extends MovimentosPendentesModuleComponent implements OnDestroy {
  constructor(
    protected readonly _injector: Injector,
    protected readonly _configOptionsService: ConfigOptionsService
  ) {
    super(_injector, _configOptionsService);
  }
}
