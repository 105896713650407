import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonCategoriaArtigo} from './jsonCategoriaArtigo.entity.interface';
import {ENTITY_NAME_CATEGORIAS_ARTIGO} from './categoriaArtigo.entity.interface';

export const ENTITY_CATEGORIAS_ARTIGO: IEntityDefinition<IJsonCategoriaArtigo> = {
  name: ENTITY_NAME_CATEGORIAS_ARTIGO,
  roles: [ROLE.ATIVOS, ROLE.ERP],
  searchPlaceholder: 'categoriaartigo.pesquisa',
  metadata: {
    keyName: 'categoria',
    descriptionName: 'descricao',
    fields: [
      {name: 'categoria', type: 'cginteger', caption: 'categoriaartigo.fields.categoria', placeholder: 'categoriaartigo.fields.categoriaPlaceholder'},
      {name: 'descricao', caption: 'categoriaartigo.fields.descricao', placeholder: 'categoriaartigo.fields.descricaoPlaceholder'}
    ],
    order: 'descricao',
    searchFields: 'categoria,descricao'
  },
  autocomplete: {
    rowTemplate: '{{categoria}} - {{descricao}}',
    output: 'descricao',
    searchFields: 'categoria,descricao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.categoriaartigo'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.categoriaartigo'
      }
    }
  }
};
