import {ENTITY_NAME_PRH_PAPEIS} from './pRHPapeis.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonPRHPapel} from './jsonPRHPapel.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_PRH_PAPEIS: IEntityDefinition<IJsonPRHPapel> = {
  name: ENTITY_NAME_PRH_PAPEIS,
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  searchPlaceholder: 'prhpapeis.pesquisa',
  metadata: {
    keyName: 'codPapel',
    descriptionName: 'nome',
    fields: [
      {
        name: 'codPapel',
        type: 'integer',
        width: '120px',
        caption: 'prhpapeis.fields.codPapel',
        placeholder: 'prhpapeis.fields.codPapelPlaceholder',
        validators: {required: true, min: 1}
      },
      {name: 'nome', caption: 'prhpapeis.fields.nome', placeholder: 'prhpapeis.fields.nomePlaceholder', validators: {required: true, maxlength: 50}},
      {name: 'unicoServico', type: 'boolean', caption: 'prhpapeis.fields.unicoServico', placeholder: 'prhpapeis.fields.unicoServicoPlaceholder'}
    ],
    order: 'codPapel',
    searchFields: 'codPapel,nome,unicoServico'
  },
  autocomplete: {
    rowTemplate: '{{codPapel}} - {{nome}}',
    output: 'nome',
    searchFields: 'codPapel,nome,unicoServico'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.GESTORRH],
        pageTitle: 'global.menu.prhpapeis'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.GESTORRH],
        pageTitle: 'global.menu.prhpapeis'
      }
    }
  }
};
