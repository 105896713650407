import {ETipoAbonoDesconto} from '../../../entities/abdes/abdes.entity.interface';

export enum ERHCopiaAbonosDescontosStep {
  Inicio = 'inicio', //'inicio',
  Selecao = 'selecao', //'selecao',
  Confirmacao = 'confirmacao', //'confirmacao',
  Estado = 'estado' //'estado'
}

export enum EGerarUtilizarGrupo {
  GerarNovo = 0,
  UtilizarExistente = 1
}

export interface IRHCopiaAbonoDescontoHeader {
  abdes: ETipoAbonoDesconto;
  codAbdes: string;
  nomeCodAbdes: string;
  copiaCruzamentos: boolean;
  copiaIntSalarios: boolean;
}

export interface IRHCopiaAbonosDescontosParams {
  abdes?: ETipoAbonoDesconto;
  codAbdes?: string;
  nomeCodAbdes?: string;
}

export const MODULE_NAME_RH_COPIA_ABONOS_DESCONTOS = 'rhcopiaabonosdescontos';
