import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {IDocsComerciaisCabEntityService, IDocsComerciaisGroup} from '../../docsComerciais.entity.interface';
import {IJsonDocfa} from '../../../docfas/jsonDocFa.entity.interface';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../docfas/docFas.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {HttpResponse} from '@angular/common/http';
import {isObject, isUndefined, PlAlertService} from 'pl-comps-angular';
import {TranslateService} from '@ngx-translate/core';
import moment, {MomentInput} from 'moment';
import {EFACCBHASHEstado, IJsonDocComercial, IJsonDocComercialCab} from '../../jsonDocComercial.entity.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowPrepared
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IApiQueryResponse} from '../../../../services/api/api.service.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {MotivoAnulacaoModalComponent} from '../motivoanulacao/motivoAnulacao.modal.component';
import {EGestaoDocsComerciaisTableLegendColors, IGestaoDocsComerciaisGrid, TABLE_LEGEND_GESTAO_DOCS_COMERCIAIS} from './gestaoDocsComerciais.interface';
import {TTableLegend} from '../../../../components/tablelegend/tablelegend.component.interface';
import {IDocsComerciaisGrid} from '../../../../modules/portalclientaccounts/myaccount/documentoscomerciais/documentosComerciais.module.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {DocumentosService} from '../../service/documentos.entity.service';
import {TDate} from '../../../../../common/dates';

@Component({
  selector: 'gestao-docs-comerciais-modal',
  templateUrl: './gestaoDocsComerciais.modal.component.html'
})
export class GestaoDocsComerciaisModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public group: IDocsComerciaisGroup;
  @Input() public service: IDocsComerciaisCabEntityService;
  public readonly dataGridDefinition: IDevExpressDataGrid<IGestaoDocsComerciaisGrid, IGestaoDocsComerciaisGrid>;
  public readonly docfasOutput: string;
  public docsComerciais: Array<IGestaoDocsComerciaisGrid>;
  public selectedRowKeys: Array<string>;
  public docFa: Partial<IJsonDocfa>;
  public docFilter: string;
  public deDataDoc: MomentInput;
  public ateDataDoc: MomentInput;
  public onClickAction: boolean;
  public tableLegend: TTableLegend;

  private readonly _servicosDocFas: IDocFasEntityService;
  private _dataGridInstance: dxDataGrid<IGestaoDocsComerciaisGrid, IGestaoDocsComerciaisGrid>;
  private _isFirstTime: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _documentosService: DocumentosService
  ) {
    super(_injector);
    this._servicosDocFas = this._entityServiceBuilder.build<IJsonDocfa, IDocFasEntityService>(ENTITY_NAME_DOC_FAS);

    this.docfasOutput = '{{nDocFa}} - {{nome}} ({{descricao}})';
    this.docFilter = '';
    this.docFa = {};
    this._isFirstTime = true;
    this.deDataDoc = moment().startOf('month');
    this.ateDataDoc = moment().endOf('month');

    this.dataGridDefinition = {
      columns: [],
      dataSource: [],
      columnHidingEnabled: false,
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocFaturacao'}
    };

    this.onClickAction = false;
    this.tableLegend = TABLE_LEGEND_GESTAO_DOCS_COMERCIAIS;
  }

  public ngOnInit(): void {
    this.docFilter = this.group.grupo ? `grupoDocfa=${this.group.grupo}` : undefined;

    this.dataGridDefinition.columns = [
      {dataField: 'nDoc', dataType: 'string', caption: 'docscomerciais.fields.nDoc'},
      {dataField: 'dataDoc', dataType: 'date', caption: 'global.text.date'},
      {dataField: 'nome', dataType: 'string', caption: `docscomerciais.fields.nome.${this.group.clifos}`},
      {dataField: 'nConta', dataType: 'string', caption: 'docscomerciais.fields.nConta'},
      {dataField: 'mensagemErro', dataType: 'string', caption: 'gestaoDocsComerciais.fields.mensagemErro'}
    ];
    this._setDefaultTipoDoc();
  }

  public onDocFaChanged(docFa: IJsonDocfa): void {
    if (!docFa || isUndefined(docFa.nDocFa)) {
      this._plAlertService.error(this._translateService.instant('docfas.errors.tipoDocNotExist'));
      return;
    }

    if (!this.docFa || this.docFa.nDocFa !== docFa.nDocFa) {
      this.docFa = docFa;
    }
  }

  public onDataDocDeChange(value: TDate): void {
    this.deDataDoc = value ? moment(value) : undefined;
    this.ateDataDoc = this.deDataDoc.clone().endOf('month');
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
    if (this._isFirstTime) {
      this._dataGridInstance.selectAll();
      this._isFirstTime = false;
    }
  }

  public onDataGridRowPrepared({data, rowElement, rowType}: IDevExpressDataGridEventOnRowPrepared<IGestaoDocsComerciaisGrid>): void {
    if (rowType === 'data') {
      let cssClass: string;
      if (data.hasError) {
        cssClass = EGestaoDocsComerciaisTableLegendColors.DocumentoComErro;
      }

      if (cssClass) {
        this._renderer.addClass(rowElement, cssClass);
      } else if (rowElement.style.backgroundColor) {
        this._renderer.removeClass(rowElement, cssClass);
      }
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IDocsComerciaisGrid>): void {
    if (event.row.isExpanded) {
      this._dataGridInstance.collapseAll(-1);
    } else {
      this._dataGridInstance.collapseAll(-1);
      if (!isObject(event.row)) {
        return;
      }
      devExpressDataGridExpandDetailHandler(event, () => this._onDetail(event.data));
    }
  }

  public async encerrarDocsSelecionados(): Promise<void> {
    if (this._dataGridInstance.getSelectedRowsData().length === 0) {
      this._plAlertService.warning(this._translateService.instant('gestaoDocsComerciais.messages.naoExistemDocsSelecionados'));
      return;
    }
    let successfullDocsFromAction = 0;
    let errorDocsFromAction = 0;
    this.onClickAction = true;
    const selectedDocs: Array<IGestaoDocsComerciaisGrid> = await this._dataGridInstance.getSelectedRowsData();
    await Promise.all(
      selectedDocs.map((doc: IGestaoDocsComerciaisGrid) =>
        this.service
          .put<IJsonDocComercial>({url: `${doc.faccbId}/encerrar`, reportExceptions: false})
          .then((response: HttpResponse<IJsonDocComercial>) => {
            const index = this.docsComerciais.findIndex((updatedDoc: IGestaoDocsComerciaisGrid) => updatedDoc.faccbId === doc.faccbId);
            this.docsComerciais[index] = response.body.cab;
            this.docsComerciais[index].hasError = false;
            ++successfullDocsFromAction;
          })
          .catch((reason) => {
            const cgException = this._cgExceptionService.get(reason);
            ++errorDocsFromAction;
            doc.mensagemErro = cgException.message;
            doc.hasError = true;
          })
      )
    );
    if (successfullDocsFromAction > 0 && errorDocsFromAction === 0) {
      this._plAlertService.success(this._translateService.instant('gestaoDocsComerciais.messages.encerrarFullSuccess'));
    }
    if (successfullDocsFromAction > 0 && errorDocsFromAction > 0) {
      this._plAlertService.warning(
        this._translateService.instant('gestaoDocsComerciais.messages.encerrarSuccessWithErrors', {
          nDocsSuccess: successfullDocsFromAction,
          nDocs: selectedDocs.length
        })
      );
    }
    if (successfullDocsFromAction === 0 && errorDocsFromAction > 0) {
      this._plAlertService.error(this._translateService.instant('gestaoDocsComerciais.messages.encerrarError'));
    }
    this.onClickAction = false;
    await this._dataGridInstance.refresh();
  }

  public async anularDocsSelecionados(): Promise<void> {
    if (this._dataGridInstance.getSelectedRowsData().length === 0) {
      this._plAlertService.warning(this._translateService.instant('gestaoDocsComerciais.messages.naoExistemDocsSelecionados'));
      return;
    }
    this.onClickAction = true;
    let successfullDocsFromAction = 0;
    let errorDocsFromAction = 0;
    let motivo = '';
    const selectedDocs = await this._dataGridInstance.getSelectedRowsData();
    if (selectedDocs.filter((value) => value.nDocumento > 0).length) {
      await this._cgModalService
        .showVanilla(MotivoAnulacaoModalComponent)
        .result.then((result) => {
          motivo = result;
        })
        .catch(() => {
          this.onClickAction = false;
        });
    }
    await Promise.all(
      selectedDocs.map((doc: IGestaoDocsComerciaisGrid) =>
        this.service
          .put<IJsonDocComercial>({url: `${doc.faccbId}/anulardoc`, params: {anulacaofisica: doc.nDocumento === 0, motivoanulacao: motivo}, reportExceptions: false})
          .then((response: HttpResponse<IJsonDocComercial>) => {
            const index = this.docsComerciais.findIndex((updatedDoc: IGestaoDocsComerciaisGrid) => updatedDoc.faccbId === doc.faccbId);
            if (doc.nDocumento > 0 && doc.terminado) {
              this.docsComerciais[index] = response.body.cab;
              this.docsComerciais[index].hasError = false;
            } else {
              this.docsComerciais.splice(index, 1);
            }
            ++successfullDocsFromAction;
          })
          .catch((reason) => {
            this._logger.error(reason);
            ++errorDocsFromAction;
            doc.hasError = true;
          })
      )
    );
    if (successfullDocsFromAction > 0 && errorDocsFromAction === 0) {
      this._plAlertService.success(this._translateService.instant('gestaoDocsComerciais.messages.anularFullSuccess'));
    }
    if (successfullDocsFromAction > 0 && errorDocsFromAction > 0) {
      this._plAlertService.warning(
        this._translateService.instant('gestaoDocsComerciais.messages.anularSuccessWithErrors', {
          nDocsSuccess: successfullDocsFromAction,
          nDocs: selectedDocs.length
        })
      );
    }
    if (successfullDocsFromAction === 0 && errorDocsFromAction > 0) {
      this._plAlertService.error(this._translateService.instant('gestaoDocsComerciais.messages.anularError'));
    }
    this.onClickAction = false;
    await this._dataGridInstance.refresh();
  }

  public fnPesquisar = (): Promise<void> => this._pesquisar();
  public readonly fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this._getPdf(doc);

  private _setDefaultTipoDoc(): void {
    if (!this.group.grupo) {
      return;
    }
    if (this.group.nDocFa) {
      const ndocfa = this.group.nDocFa.documentoPorDefeito;
      if (ndocfa > 0) {
        this._servicosDocFas
          .get({id: ndocfa, reportExceptions: false})
          .then((response: HttpResponse<IJsonDocfa>) => {
            this.docFa = response.body;
            this.onDocFaChanged(response.body);
          })
          .catch(() => {
            this.docFa = {};
          });
      }
    }
  }

  private _pesquisar(): Promise<void> {
    const filters =
      `grupoDocfa=${this.group.grupo}&hashEstado<=${EFACCBHASHEstado.DocAssinado}&status=0` +
      `&nDocFa=${this.docFa.nDocFa}&dataDoc>=${moment(this.deDataDoc).toJSON()}&dataDoc<=${moment(this.ateDataDoc).toJSON()}`;
    return this.service
      .query<IJsonDocComercialCab>({pesquisa: filters})
      .then((response: HttpResponse<IApiQueryResponse<IGestaoDocsComerciaisGrid>>) => {
        this.onClickAction = true;
        this.docsComerciais = response.body.list;
      })
      .finally(() => {
        this.onClickAction = false;
      });
  }

  private _onDetail(grid: IGestaoDocsComerciaisGrid): Promise<void> {
    return this.service.get<IJsonDocComercial>({id: grid.faccbId}).then((response) => {
      grid._thedoc = response.body;
    });
  }

  private _getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc);
  }
}
