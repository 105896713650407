import {ModuloComponent} from '../../../components/module/module.component';
import {Component, Injector, OnInit} from '@angular/core';

@Component({
  selector: 'module-grupo-docfa-configuracoes',
  templateUrl: './grupoDocfaConfiguracoes.module.component.html'
})
export class GrupoDocfaConfiguracoesModuleComponent extends ModuloComponent implements OnInit {
  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }
}
