import {deepFreeze} from 'pl-comps-angular';
import {EDebitoCredito} from '../../datasources/debitocredito/debitoCredito.datasource.interface';
import {
  EEntidadeBancariaEstado,
  EEntidadeBancariaProvider,
  IJsonBankPreDefCfg,
  IJsonEntidadeBancariaConsent,
  IJsonEntidadeBancariaSearchResult,
  IJsonEntidadeBancariaTransaction
} from '../../interfaces/jsonEntidadeBancaria.interface';
import {IRadioGroup} from '../../../common/interfaces/interfaces';
import {TDate} from '../../../common/dates';

export enum ECGBankingDataRegistoRecebimentosPagamentos {
  DataAtual,
  DataMovimentoBancario
}

export interface IJsonCGBankingLicense {
  nEmpresasTotal: number;
  nEmpresasConsumidas: number;
  nEmpresasPorConsumir: number;
}

export interface IJsonBancosExtratoGetTransactionsByConciliacaoBancariaResult extends IJsonEntidadeBancariaSearchResult {
  startDate: TDate;
  endDate: TDate;
}

export interface IJsonBancosExtratoSuggestDocContabilidadeData {
  transactions: Array<IJsonEntidadeBancariaTransaction>;
  modoMerge: boolean;
  periodo: string;
  nDiario: number;
  docOCRCabID: string;
}

export interface IJsonBancosExtratoConfigs {
  nDiarioRecebimentos: number;
  nomeDiarioRecebimentos: string;
  nDiarioPagamentos: number;
  nomeDiarioPagamentos: string;
  descricao: string;
  movAbAuto: boolean;
  showDescritivo: boolean;
  dataRegistoRecebimentosPagamentos: ECGBankingDataRegistoRecebimentosPagamentos;
  apiProvider: EEntidadeBancariaProvider;
}

export interface IJsonBancosExtratoDocSemelhante {
  nLanc: string;
  extPocCabID: string;
  periodo: string;
  nDiario: number;
  nomeDiario: string;
  nDocInterno: string;
  nDocExterno: string;
  dataLancamento: TDate;
  dataDocExterno: TDate;
  dc: EDebitoCredito;
  valor: number;
  descricao: string;
  contaCorrente: string;
}

export interface IJsonBancosExtratoPreDefCfg {
  preDefCfg: IJsonBankPreDefCfg;
  transaction: IJsonEntidadeBancariaTransaction;
}

export interface IJsonBancosExtratoLancarDocumentosEmSerieData {
  transactions: Array<IJsonEntidadeBancariaTransaction>;
  modoMerge: boolean;
  contabilidadeDigital: boolean;
}

export interface IJsonBancosExtratoConsentsData {
  externalId: string;
  consents: Array<IJsonEntidadeBancariaConsent>;
}

export interface IJsonBancosExtratoSaveRecPagResult {
  extPocCabID: string;
  estado: EEntidadeBancariaEstado;
}

export const RADIO_GROUP_CG_BANKING_DATA_REGISTO_RECEBIMENTOS_PAGAMENTOS: IRadioGroup<ECGBankingDataRegistoRecebimentosPagamentos> = deepFreeze({
  groupItems: [
    {value: ECGBankingDataRegistoRecebimentosPagamentos.DataAtual, label: 'bancosextrato.dataRegistoRecebimentosPagamentos.dataAtual'},
    {value: ECGBankingDataRegistoRecebimentosPagamentos.DataMovimentoBancario, label: 'bancosextrato.dataRegistoRecebimentosPagamentos.dataMovimentoBancario'}
  ]
});
