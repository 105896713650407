import {isFunction, timeout as timeoutWithPromise} from 'pl-comps-angular';

export type TElementRemoveEventListenerFn = (removeOptions?: boolean | EventListenerOptions) => void;

const focusableQuery = 'input,selectNode,textarea,button,a,[tabindex="0"]';
const focusableQueryArray = focusableQuery.split(',');

export function isFocusable(element: HTMLElement): boolean {
  if (!element) {
    return false;
  }
  if (isFunction(element.matches)) {
    return element.matches(focusableQuery);
  }
  let tagName = element.tagName;
  if (!tagName) {
    return false;
  }
  tagName = tagName.toLowerCase();
  return focusableQueryArray.includes(tagName);
}

export function focusElement(element: HTMLElement, options?: FocusOptions): boolean {
  if (!element) {
    // eslint-disable-next-line no-console
    console.warn('Provided element is not defined');
    return false;
  }
  if (isFocusable(element)) {
    element.focus(options);
    return false;
  }
  element = element.querySelector(focusableQuery);
  if (element) {
    element.focus(options);
    return true;
  }
  return false;
}

export function focusElementWithDelay(element: HTMLElement, timeout?: number, options?: FocusOptions): Promise<boolean> {
  return timeoutWithPromise(timeout).then(() => focusElement(element, options));
}

export function focusAndSelectElement(element: HTMLElement, options?: FocusOptions): boolean {
  if (focusElement(element, options) && isFunction((<HTMLInputElement>element).select)) {
    (<HTMLInputElement>element).select();
    return true;
  }
  return false;
}

export function focusAndSelectElementWithDelay(element: HTMLElement, timeout?: number, options?: FocusOptions): Promise<boolean> {
  return timeoutWithPromise(timeout).then(() => focusAndSelectElement(element, options));
}

export function elementIndex(element: Element): number {
  if (!element?.parentNode) {
    if (!element) {
      // eslint-disable-next-line no-console
      console.warn('Provided element is not defined');
    }
    return -1;
  }
  return Array.from(element.parentNode.children).indexOf(element);
}

export function elementAddEventListener<K extends keyof HTMLElementEventMap>(
  element: Element,
  type: K,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any,
  options?: boolean | AddEventListenerOptions
): TElementRemoveEventListenerFn {
  element.addEventListener(type, listener, options);
  return (removeOptions?: boolean | EventListenerOptions): void => {
    element.removeEventListener(type, listener, removeOptions);
  };
}
