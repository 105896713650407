import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Component, Injector, OnInit} from '@angular/core';
import dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {IApiQueryResponse} from '../../../services/api/api.service.interface';
import {IJsonRptConfigModulo, IJsonRptConfigVariavel} from '../jsonRptConfig.module.interface';
import {RptConfigsService} from '../rptConfigs.module.service';
import {PlAlertService} from 'pl-comps-angular';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';

@Component({
  selector: 'module-rptconfigs',
  templateUrl: './rptConfigs.module.component.html'
})
export class RptConfisModuleComponent extends ModuloComponent implements OnInit {
  public gridDefinitionModulos: IDevExpressDataGrid;
  public gridDefinitionVariables: IDevExpressDataGrid;
  public modulosSource: Array<IJsonRptConfigModulo>;
  public variableSource: Array<IJsonRptConfigVariavel>;
  public moduloAtivo: IJsonRptConfigModulo;

  private _gridInstanceModulos: dxDataGrid;
  private _gridInstanceVariables: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rptConfigsService: RptConfigsService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);

    this.modulosSource = [];
    this.variableSource = [];
    this.moduloAtivo = null;

    this.gridDefinitionModulos = {
      columns: [{dataField: 'nome', dataType: 'string', caption: 'rptconfigs.fields.modulo', allowEditing: false, allowHiding: false}],
      toolbar: {
        visible: false
      }
    };

    this.gridDefinitionVariables = {
      allowColumnReordering: false,
      columns: [
        {dataField: 'descricao', dataType: 'string', caption: 'rptconfigs.fields.descricao', allowEditing: false, allowSorting: false, allowGrouping: false, allowHiding: false},
        {dataField: 'nome', dataType: 'string', caption: 'rptconfigs.fields.nome', allowEditing: false, allowSorting: false, allowGrouping: false, allowHiding: false},
        {dataField: 'valor', dataType: 'number', caption: 'rptconfigs.fields.valor', allowEditing: true, allowSorting: false, allowGrouping: false, allowHiding: false}
      ],
      editing: {
        allowUpdating: true,
        mode: 'cell',
        selectTextOnEditStart: true
      },
      toolbar: {
        visible: false
      }
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnSave.visible = true;
    this.btnSave.disabled = true;
    this.btnSave.click = () => this._saveRptConfigs();
    this._rptConfigsService.getModulos().then((response: HttpResponse<IApiQueryResponse<IJsonRptConfigModulo>>) => {
      if (response) {
        this.modulosSource = response.body.list;
        this._gridInstanceModulos.refresh();
      }
    });
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonRptConfigModulo>): void {
    if (event.rowType !== 'data') {
      return;
    }

    this.btnSave.disabled = true;
    this._rptConfigsService.getVariables(event.data.folder).then((response: HttpResponse<IApiQueryResponse<IJsonRptConfigVariavel>>): void => {
      if (response) {
        this.variableSource = response.body.list;
        this.moduloAtivo = event.data;
        this._gridInstanceVariables.refresh();
        this.btnSave.disabled = this.variableSource.length === 0;
      }
    });
  }

  public onInitializedModulos({component}: IDevExpressDataGridEventOnInitialized): void {
    this._gridInstanceModulos = component;
  }

  public onContentReadyModulos(): void {
    this._gridInstanceModulos.endCustomLoading();
  }

  public onInitializedVariables({component}: IDevExpressDataGridEventOnInitialized): void {
    this._gridInstanceVariables = component;
  }

  public onContentReadyVariables(): void {
    this._gridInstanceVariables.endCustomLoading();
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonRptConfigVariavel>): void {
    if (event.rowType === 'data' && event.column.dataField === 'valor') {
      event.cellElement.classList.add('datagrid-editable-cell');
    }
  }

  private _saveRptConfigs(): Promise<void> {
    return this._rptConfigsService
      .saveRptConfigs(this.moduloAtivo.folder, this.variableSource)
      .then((response: HttpResponse<boolean>) => {
        if (response?.body) {
          this._plAlertService.success('rptconfigs.saveSuccess');
        }
      })
      .catch((error: HttpErrorResponse) => {
        this._plAlertService.error(this._cgExceptionService.get(error).message);
      });
  }
}
