import {HttpErrorResponse} from '@angular/common/http';
import {Component, Injector, OnInit} from '@angular/core';
import {IPlToolbarItem, isEmptyObject, isString, PlAlertService, timeout} from 'pl-comps-angular';
import {focusElement} from '../../../../../common/utils/element.utils';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {DATA_SOURCE_TIPOS_POC} from '../../../../datasources/tipospoc/tiposPoc.datasource';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IJsonNIFs} from '../../../nifs/jsonNifs.entity.interface';
import {ENTITY_NAME_NIFS, INifsEntityService} from '../../../nifs/nifs.entity.interface';
import {RegistarNifModalComponent} from '../../../nifs/modals/registanif/registarNIF.modal.component';
import {IJsonPOC} from '../../jsonPOC.entity.interface';
import {AvisoAlteracaoCCModalComponent} from '../../modals/avisoAlteracaoCC.modal.component';
import {MODULE_NAME_POCS_COPY} from '../../pocs.entity.interface';

@Component({
  selector: 'pocs-edit',
  templateUrl: './pocs.entity.edit.component.html'
})
export class PocsEditComponent extends ModuloEntityDetailComponent<IJsonPOC> implements OnInit {
  public readonly eTipoContaContab: typeof ETipoContaContabilidade;
  public readonly stateType: typeof EEntityStateDetailType;
  public readonly daq08NCampoOutput: string;
  public readonly daq09NCampoOutput: string;
  public readonly dpq06NCampoOutput: string;
  public readonly codControloIVACreditoOutput: string;
  public readonly codControloIVADebitoOutput: string;
  public readonly tipoPoc: ReadonlyArray<IDataSourceItem<ETipoContaContabilidade>>;
  public usaAnaliticaConfig: boolean;
  public promise: Promise<void>;

  private readonly _serviceNifs: INifsEntityService;
  private readonly _btnPocsEntreEmpresas: IPlToolbarItem;
  private readonly _maintenancePocsEntreEmpresas: IModuleMaintenanceInstance;
  private _originalCC: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this._maintenancePocsEntreEmpresas = this._moduleMaintenanceService.build(MODULE_NAME_POCS_COPY);
    this.eTipoContaContab = ETipoContaContabilidade;
    this.stateType = EEntityStateDetailType;
    this.daq08NCampoOutput = '{{nCampo}} - {{nome}}';
    this.daq09NCampoOutput = '{{nCampo}} - {{nome}}';
    this.dpq06NCampoOutput = '{{nCampo}} - {{nomeCampo}}';
    this.codControloIVACreditoOutput = '{{codControloIVA}} - {{descAbreviada}}';
    this.codControloIVADebitoOutput = '{{codControloIVA}} - {{descAbreviada}}';
    this.tipoPoc = DATA_SOURCE_TIPOS_POC.data;
    this.usaAnaliticaConfig = this._configService.configurations.empresa.usaConfigAnalitica;
    this._serviceNifs = this._entityServiceBuilder.build<IJsonNIFs, INifsEntityService>(ENTITY_NAME_NIFS);

    this._btnPocsEntreEmpresas = {
      id: 'btn-controlo-iva-entre-empresas',
      order: 126,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-copy"></i>',
      class: 'btn-primary',
      caption: 'pocs.btns.copiaContasPlanoEmpresa',
      click: () => this._openPocsEntreEmpresas()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (isEmptyObject(this.model)) {
      this.model = {
        bloqueada: false,
        cc: false,
        codControloIVACredito: '',
        codControloIVACreditoNome: '',
        codControloIVADebito: '',
        codControloIVADebitoNome: '',
        codRet: '',
        daq08NCampo: '',
        daq08NCampoNome: '',
        daq09NCampo: '',
        daq09NCampoNome: '',
        dpq06NCampo: '',
        dpq06NCampoNome: '',
        nConta: '',
        nif: '',
        nifNome: '',
        nome: '',
        nomeRet: '',
        registaRetencao: false,
        temCCusto: false,
        temIVA: false,
        temRetencao: false,
        taxonomyDescription: '',
        taxonomyReference: '',
        taxonomyCode: undefined,
        tipo: ETipoContaContabilidade.Movimento,
        ...this.model
      };
    }
    this._originalCC = this.model.cc;
    this.toolbar.addButton(this._btnPocsEntreEmpresas);
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    return super.onUpdate(stateType).then(() => {
      return timeout().then(() => {
        const element: HTMLInputElement = this._element.querySelector<HTMLInputElement>('input[name="nConta"]');
        focusElement(element);
      });
    });
  }

  public save(): Promise<IJsonPOC> {
    if (this.type === EEntityStateDetailType.EDIT && this.model.cc !== this._originalCC) {
      return this._cgModalService.show(AvisoAlteracaoCCModalComponent, {size: 'md'}).then(() =>
        super.save().then((model: IJsonPOC) => {
          this._originalCC = model.cc;
          return model;
        })
      );
    }
    return super.save();
  }

  public duplicate(): Promise<void> {
    const originalNConta: string = this.model.nConta;
    return super.duplicate({params: {originalNConta: originalNConta}});
  }

  public onNContaChange(value: string): void {
    this.model.nConta = value;
    const radicalClientes: string = this._configService.configurations.clientesFornecedores.radicalClientes;
    const radicalFornecedores: string = this._configService.configurations.clientesFornecedores.radicalFornecedores;
    const radicalDevCred: string = this._configService.configurations.clientesFornecedores.radicalOutrosDevedoresCredores;
    if ((radicalClientes && value.startsWith(radicalClientes)) || (radicalFornecedores && value.startsWith(radicalFornecedores)) || (radicalDevCred && value.startsWith(radicalDevCred))) {
      this._plAlertService.warning('pocs.alerts.contaCorrente');
    }
  }

  public onTemControloIVAChange(value: boolean): void {
    this.model.temIVA = value;
    if (!this.model.temIVA) {
      this.model = {
        ...this.model,
        codControloIVACredito: undefined,
        codControloIVACreditoNome: undefined,
        codControloIVADebito: undefined,
        codControloIVADebitoNome: undefined
      };
    }
  }

  public onNContribuinteChange(value: string | IJsonNIFs): void {
    let nif: string = isString(value) ? value : value.nContribuinte;
    this.promise = new Promise<void>(() => {
      let shouldRegister = false;
      let promise: Promise<unknown>;
      if (nif) {
        promise = this._serviceNifs.registaNif(nif);
      }
      Promise.resolve(promise).finally(() => {
        if (nif) {
          promise = this._serviceNifs.get({id: nif, reportExceptions: false});
        }
        Promise.resolve(promise)
          .catch((reason: HttpErrorResponse) => {
            this._logger.error(reason);
            shouldRegister = true;
          })
          .finally(() => {
            if (shouldRegister) {
              promise = new Promise<void>((resolveRegister) => {
                this._cgModalService
                  .showOkCancel(this._translateService.instant('nifs.naoregistado'), this._translateService.instant('nifs.desejaregistar'))
                  .then(() => {
                    const modalInstance = this._cgModalService.showVanilla(RegistarNifModalComponent);
                    const componentInstance: RegistarNifModalComponent = modalInstance.componentInstance;
                    componentInstance.nContribuinte = nif;
                    modalInstance.result
                      .then((response: IJsonNIFs) => {
                        nif = response.nContribuinte;
                      })
                      .finally(() => {
                        resolveRegister();
                      });
                  })
                  .catch(resolveRegister);
              });
            }
            Promise.resolve(promise).finally(() => {
              this.model.nif = nif;
            });
          });
      });
    });
  }

  private _openPocsEntreEmpresas(): Promise<void> {
    if (this.type !== this.stateTypes.NEW) {
      return this._maintenancePocsEntreEmpresas.maintenance({params: {nConta: this.model.nConta}});
    }
    return this._maintenancePocsEntreEmpresas.maintenance();
  }
}
