<ng-template #transpiledContent>
  <div>
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'round'">
    <div class="pl-spinner-round"></div>
    <div>
      <ng-container *ngTemplateOutlet="transpiledContent"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="pl-spinner-3dots">
      <div class="pl-spinner-3dots-bounce1"></div>
      <div class="pl-spinner-3dots-bounce2"></div>
      <div class="pl-spinner-3dots-bounce3"></div>
    </div>
    <div>
      <ng-container *ngTemplateOutlet="transpiledContent"></ng-container>
    </div>
  </ng-container>
</ng-container>
