import {Directive, TemplateRef} from '@angular/core';
import type {IPlNavWizardStepCaption, IPlNavWizardStepTemplateContext} from './navwizard.interface';

@Directive({
  selector: 'ng-template[plNavWizardStepCaption]',
  standalone: false
})
export class PlNavWizardStepCaptionDirective implements IPlNavWizardStepCaption {
  constructor(public readonly templateRef: TemplateRef<IPlNavWizardStepTemplateContext>) {}
}
