<pl-form [submit]="close" [plPromise]="promise">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'efaturalogin.title'"></h4>
  </div>

  <div class="modal-body">
    <img alt="Portal" class="d-block simple-login-logo" [src]="'../../../assets/images/logo-at.webp'" />
    <pl-group>
      <label [translate]="'efaturalogin.fields.tipoComunicacao'"></label>
      <edit>
        <pl-edit type="radiogroup" attrName="tipoConta" [(model)]="model.tipoComunicacao" [properties]="{groupItems: tiposComunicacao, validators: {required: {value: true}}}"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'efaturalogin.fields.username'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.username" plAutoFocus [properties]="{disabled: !isAdmin}"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'efaturalogin.fields.password'"></label>
      <edit>
        <pl-edit type="password" [(model)]="model.password" [properties]="{disabled: !isAdmin}"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation>
    <span *ngIf="!isAdmin" [translate]="'efaturalogin.messages.noPermissions'"></span>

    <button type="submit" class="btn btn-sm btn-primary" [disabled]="!isAdmin"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'efaturalogin.saveTitle'"></span></button>
    <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></button>
  </div>
</pl-form>
