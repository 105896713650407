import {merge} from 'lodash-es';
import {HttpResponse} from '@angular/common/http';
import {Transition} from '@uirouter/core';
import {DATA_SOURCE_NAME_TIPO_ABONODESC} from '../../datasources/tipoabonodesc/tipoAbonoDesc.datasource.interface';
import {ENTITY_NAME_REMFX, ENTITY_NAME_REMUN, IRHRecolhaDadosStateParams} from './rhRecolhaDados.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonREMDecimaisComponentes, IJsonREMUNFX} from './jsonRHRecolhaDados.entity.interface';
import {RHRecolhaDadosEditComponent} from './components/edit/rhRecolhaDados.entity.edit.component';
import {RHRecolhaDadosListComponent} from './components/list/rhRecolhaDados.entity.list.component';
import {RHRecolhaDadosService} from './rhRecolhaDados.entity.service';
import {ROLE} from '../../services/role.const';
import {THttpQueryResponse} from '../../services/api/api.service.interface';
import {IJsonNumber} from '../../../common/interfaces/json';

export const ENTITY_REMUN: IEntityDefinition<IJsonREMUNFX> = generateEntity(ENTITY_NAME_REMUN);

export const ENTITY_REMFX: IEntityDefinition<IJsonREMUNFX> = generateEntity(ENTITY_NAME_REMFX);

function generateEntity(entityName: string): IEntityDefinition<IJsonREMUNFX> {
  return {
    name: entityName,
    roles: [ROLE.RH, ROLE.PCA],
    searchPlaceholder: 'rhrecolhadados.pesquisa',
    sidebarTitle: `portals.sidebar.modules.${entityName}`,
    metadata: {
      keyName: entityName === ENTITY_NAME_REMUN ? 'remUNID' : 'remFXID',
      fields: [
        {name: 'remUNID', visible: false},
        {name: 'remFXID', visible: false},
        {name: 'codEMP', caption: 'rhrecolhadados.fields.codEMP'},
        {
          name: 'nomeEmpr',
          caption: 'rhrecolhadados.fields.nomeEmp',
          entity: {
            name: 'dgemps',
            keyTarget: 'codEMP',
            output: '{{codEmp}} - {{nome}}',
            outputTarget: 'nomeEmpr',
            fieldsMap: {codEmp: 'codEMP', nome: 'nomeEmpr'}
          },
          properties: {
            allowInvalid: false,
            devExpress: {
              dataGrid: {
                calculateCellValue: (rowData: IJsonREMUNFX) => `${rowData.codEMP} - ${rowData.nomeEmpr}`
              }
            }
          }
        },
        {
          name: 'abonoDesc',
          type: DATA_SOURCE_NAME_TIPO_ABONODESC,
          caption: 'rhrecolhadados.fields.abonoDesc',
          placeholder: 'rhrecolhadados.fields.abonoDesc',
          properties: {
            devExpress: {
              dataGrid: {
                lookup: {
                  cgDataSource: DATA_SOURCE_NAME_TIPO_ABONODESC
                }
              }
            }
          }
        },
        {name: 'codABDESC', visible: false},
        {
          name: 'nomeAbonoDesconto',
          type: 'text',
          caption: 'rhrecolhadados.fields.nomeAbonoDesconto',
          placeholder: 'rhrecolhadados.fields.nomeAbonoDesconto',
          properties: {
            allowInvalid: false,
            devExpress: {
              dataGrid: {
                calculateCellValue: (rowData: IJsonREMUNFX) => `${rowData.codABDESC} - ${rowData.nomeAbonoDesconto}`
              }
            }
          }
        },
        {name: 'quantidade', type: 'number', caption: 'rhrecolhadados.fields.quantidade', placeholder: 'rhrecolhadados.fields.quantidade'},
        {name: 'valor', type: 'number', caption: 'rhrecolhadados.fields.valor', placeholder: 'rhrecolhadados.fields.valor'},
        {name: 'tipoProcessamento', visible: false},
        {
          name: 'nomeTipoProcessamento',
          caption: 'rhrecolhadados.fields.nomeTipoProcessamento',
          visible: entityName === ENTITY_NAME_REMUN,
          entity: {
            name: 'rhtipoprocess',
            keyTarget: 'tipoProcessamento',
            output: '{{tipoProcessamento}} - {{descricao}}',
            outputTarget: 'nomeTipoProcessamento',
            fieldsMap: {descricao: 'nomeTipoProcessamento'}
          },
          properties: {
            allowInvalid: false,
            devExpress: {
              dataGrid: {
                calculateCellValue: (rowData: IJsonREMUNFX) => `${rowData.tipoProcessamento} - ${rowData.nomeTipoProcessamento}`
              }
            }
          }
        },
        {
          name: 'data',
          type: 'date',
          caption: entityName === ENTITY_NAME_REMUN ? 'rhrecolhadados.fields.dataDados' : 'rhrecolhadados.fields.dataDadosFixos'
        },
        {
          name: 'dataProcess',
          type: 'date',
          caption: entityName === ENTITY_NAME_REMUN ? 'rhrecolhadados.fields.dataProcess' : 'rhrecolhadados.fields.dataParaProcess'
        }
      ],
      order: 'data desc',
      listFields: 'codEmp,nomeEmpr',
      filterFields: 'data,dataProcess,nomeEmpr,abonoDesc,nomeAbonoDesconto,nomeTipoProcessamento'
    },
    actions: {
      new: true,
      detail: true,
      edit: true,
      delete: true,
      search: true,
      filter: true,
      batchAdd: true
    },
    list: {
      state: {
        component: RHRecolhaDadosListComponent,
        resolve: [
          {
            provide: 'anos',
            deps: [RHRecolhaDadosService],
            useFactory: (rhRecolhaDados: RHRecolhaDadosService) => {
              return rhRecolhaDados.getAnosComDados().then((response: THttpQueryResponse<IJsonNumber>) => response.body);
            }
          }
        ]
      }
    },
    detail: {
      state: {
        component: RHRecolhaDadosEditComponent,
        params: {
          defaultRecolhaDados: {
            type: 'any',
            value: null
          },
          fromBatchAdd: {
            type: 'bool',
            value: false
          },
          isByCodEmp: {
            type: 'bool',
            value: true
          }
        },
        resolve: [
          {
            provide: 'decimalsLimit',
            deps: [RHRecolhaDadosService],
            useFactory: (rhRecolhaDadosService: RHRecolhaDadosService) => {
              return rhRecolhaDadosService.getDecimaisPorDefeito(entityName).then((response: HttpResponse<IJsonREMDecimaisComponentes>) => response.body);
            }
          }
        ]
      }
    },
    new: {
      state: {
        resolve: [
          {
            provide: 'defaultRecolhaDados',
            deps: [Transition, RHRecolhaDadosService],
            useFactory: (transition: Transition, rhRecolhaDadosService: RHRecolhaDadosService) => {
              const params: IRHRecolhaDadosStateParams = <IRHRecolhaDadosStateParams>transition.params();
              return rhRecolhaDadosService.getDefaultRecolhaDados(entityName).then((response: HttpResponse<IJsonREMUNFX>) => {
                return merge({}, response.body, params.defaultRecolhaDados);
              });
            }
          }
        ]
      }
    }
  };
}
