import {Directive, forwardRef, HostListener, Provider} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PlRecaptchaComponent} from '../v2/recaptcha.v2.component';
import type {TPlRecaptchaValueAccessorFnOnChange, TPlRecaptchaValueAccessorFnOnTouched} from '../recaptcha.interface';

export const RECAPTCHA_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PlRecaptchaValueAccessorDirective),
  multi: true
};

@Directive({
  selector: 'pl-recaptcha[formControlName],pl-recaptcha[formControl],pl-recaptcha[ngModel]',
  standalone: false,
  providers: [RECAPTCHA_VALUE_ACCESSOR]
})
export class PlRecaptchaValueAccessorDirective implements ControlValueAccessor {
  public onChange: TPlRecaptchaValueAccessorFnOnChange;
  public onTouched: TPlRecaptchaValueAccessorFnOnTouched;

  constructor(private readonly _plRecaptchaComponent: PlRecaptchaComponent) {}

  public writeValue(value: unknown): void {
    if (!value) {
      this._plRecaptchaComponent.reset();
    }
  }

  public registerOnChange(fn: TPlRecaptchaValueAccessorFnOnChange): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: TPlRecaptchaValueAccessorFnOnTouched): void {
    this.onTouched = fn;
  }

  @HostListener('evtSuccess', ['$event'])
  public onSuccess(token: string): void {
    this._touch();
    this._changedValue(token);
  }

  @HostListener('evtExpired')
  public onExpired(): void {
    this._touch();
    this._changedValue(undefined);
  }

  @HostListener('evtError')
  public onError(): void {
    this._touch();
    this._changedValue(undefined);
  }

  private _changedValue(token: string): void {
    if (this.onChange) {
      this.onChange(token);
    }
  }

  private _touch(): void {
    if (this.onTouched) {
      this.onTouched();
    }
  }
}
