import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: 'ng-template[plTooltipTemplate]',
  standalone: false
})
export class PlTooltipTemplateDirective {
  @Input() public plTooltipTemplate: any;

  constructor(public readonly templateRef: TemplateRef<void>) {}
}
