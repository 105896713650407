import {merge} from 'lodash-es';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isArray, toCamelCase, TValueOrPromise} from 'pl-comps-angular';
import {EConfigsErpDepth, IConfigsErpEditConfigERP, IConfigsErpEditGroup} from './configs.erp.edit.component.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnCellPrepared} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';

@Component({
  selector: 'config-erp',
  templateUrl: './configs.erp.edit.component.html'
})
export class ConfigErpComponent implements OnInit, OnChanges {
  @Input() public source: Array<IConfigsErpEditConfigERP | IConfigsErpEditGroup>;
  @Input() public definition: IDevExpressDataGrid;
  @Input() public depth: EConfigsErpDepth;
  @Input() public onValidate: (value: unknown) => TValueOrPromise<unknown>;

  public readonly depths: typeof EConfigsErpDepth;
  public dataGridDefinition: IDevExpressDataGrid;
  public columns: Array<IDevExpressDataGridColumn>;
  public configs: Array<IConfigsErpEditConfigERP>;
  public groups: Array<IConfigsErpEditGroup>;
  public title: string;

  constructor(private readonly _translateService: TranslateService) {
    this.depths = EConfigsErpDepth;
    this.columns = [];
    this.configs = [];
    this.groups = [];
    this.title = '';
  }

  public ngOnInit(): void {
    this.columns = isArray(this.definition.columns) ? this.definition.columns : this.columns;
    delete this.definition.columns;
    this.dataGridDefinition = merge<object, IDevExpressDataGrid, IDevExpressDataGrid>(
      {},
      {
        editing: {
          mode: 'cell',
          allowUpdating: true
        },
        allowColumnReordering: false,
        allowColumnResizing: true,
        columnChooser: {enabled: false},
        columnFixing: {enabled: false},
        columnHidingEnabled: false,
        export: {enabled: false},
        filterRow: {visible: false},
        grouping: {allowCollapsing: false, contextMenuEnabled: false},
        groupPanel: {allowColumnDragging: false},
        headerFilter: {visible: false},
        hoverStateEnabled: true,
        remoteOperations: false,
        searchPanel: {visible: true},
        showBorders: true,
        showColumnLines: true,
        sorting: {mode: 'none'}
      },
      this.definition
    );
    this._changedSource();
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared): void {
    if (event.rowType === 'data' && event.columnIndex !== 0) {
      event.cellElement.style.paddingLeft = '11px';
    }
  }

  public ngOnChanges({source}: SimpleChanges): void {
    if (source && !source.isFirstChange()) {
      this._changedSource(source.currentValue);
    }
  }

  public loadList(title: string): void {
    this.title = title || this.title || '';
  }

  private _changedSource(value: Array<IConfigsErpEditConfigERP | IConfigsErpEditGroup> = this.source): void {
    if (this.depth <= EConfigsErpDepth.Single) {
      this.configs = <Array<IConfigsErpEditConfigERP>>value;
    } else if (value.length) {
      this.groups = <Array<IConfigsErpEditGroup>>value;
      if (this.groups.length) {
        this._changedGroups();
        this.loadList(this.groups[0].id);
      }
    }
  }

  private _changedGroups(): void {
    for (const group of this.groups) {
      group.title = this._translateService.instant(group.title);
      group.id = toCamelCase(group.title);
    }
  }
}
