import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ENTITY_NAME_CONTTRABBASELEGAL} from './contTrabBaseLegal.entity.interface';
import {IJsonContTrabBaseLegal} from './jsonContTrabBaseLegal.entity.interface';
import {ContTrabBaseLegalEditComponent} from './components/edit/contTrabBaseLegal.entity.edit.component';

export const ENTITY_CONTTRABBASELEGAL: IEntityDefinition<IJsonContTrabBaseLegal> = {
  name: ENTITY_NAME_CONTTRABBASELEGAL,
  roles: [ROLE.RH],
  pageTitle: 'global.menu.conttrabbaselegal',
  searchPlaceholder: 'conttrabbaselegal.pesquisa',
  metadata: {
    keyName: 'contTrabBaseLegal',
    fields: [
      {name: 'contTrabBaseLegal', type: 'cginteger', caption: 'conttrabbaselegal.fields.contTrabBaseLegal', validators: {required: true}},
      {name: 'tipoContrato', type: 'cginteger', caption: 'conttrabbaselegal.fields.tipoContrato', validators: {required: true}},
      {name: 'descricao', type: 'text', caption: 'conttrabbaselegal.fields.descricao'},
      {name: 'duracaoMinima', type: 'cgnumber', caption: 'conttrabbaselegal.fields.duracaoMinima'},
      {name: 'duracaoMaxima', type: 'cgnumber', caption: 'conttrabbaselegal.fields.duracaoMaxima'},
      {name: 'duracaoMaxPrimEmpreg', type: 'cgnumber', caption: 'conttrabbaselegal.fields.duracaoMaxPrimEmpreg'},
      {name: 'dilacaoNovoContrato', type: 'cgnumber', caption: 'conttrabbaselegal.fields.dilacaoNovoContrato'},
      {name: 'periodoExpNormalInd', type: 'cgnumber', caption: 'conttrabbaselegal.fields.periodoExpNormalInd'},
      {name: 'periodoExpTecnicoInd', type: 'cgnumber', caption: 'conttrabbaselegal.fields.periodoExpTecnicoInd'},
      {name: 'periodoExpSuperioInd', type: 'cgnumber', caption: 'conttrabbaselegal.fields.periodoExpSuperioInd'},
      {name: 'periodoExpTermoCerto', type: 'cgnumber', caption: 'conttrabbaselegal.fields.periodoExpTermoCerto'},
      {name: 'preAvisoDenunNormal', type: 'cgnumber', caption: 'conttrabbaselegal.fields.preAvisoDenunNormal'},
      {name: 'preAvisoDenunTecnico', type: 'cgnumber', caption: 'conttrabbaselegal.fields.preAvisoDenunTecnico'},
      {name: 'preAvisoDenunSuperio', type: 'cgnumber', caption: 'conttrabbaselegal.fields.preAvisoDenunSuperio'},
      {name: 'preAvisoDenunTermoC', type: 'cgnumber', caption: 'conttrabbaselegal.fields.preAvisoDenunTermoC'},
      {name: 'numRenovNormal', type: 'cginteger', caption: 'conttrabbaselegal.fields.numRenovNormal'},
      {name: 'numRenovExcepcionais', type: 'cginteger', caption: 'conttrabbaselegal.fields.numRenovExcepcionais'},
      {name: 'feriasPorMesCompl', type: 'cgnumber', caption: 'conttrabbaselegal.fields.feriasPorMesCompl'},
      {name: 'feriasMaximo', type: 'cgnumber', caption: 'conttrabbaselegal.fields.feriasMaximo'},
      {name: 'aviDesvincTrab', type: 'cgnumber', caption: 'conttrabbaselegal.fields.aviDesvincTrab'},
      {name: 'aviCaducidadeTrab', type: 'cgnumber', caption: 'conttrabbaselegal.fields.aviCaducidadeTrab'},
      {name: 'aviCaducidadeEmpresa', type: 'cgnumber', caption: 'conttrabbaselegal.fields.aviCaducidadeEmpresa'},
      {name: 'compCaducidadeEmpre', type: 'cgnumber', caption: 'conttrabbaselegal.fields.compCaducidadeEmpre'},
      {name: 'passagemSemTermo', type: 'cgnumber', caption: 'conttrabbaselegal.fields.passagemSemTermo'},
      {name: 'contTBLNovaSeq', type: 'cginteger', caption: 'conttrabbaselegal.fields.contTBLNovaSeq'}
    ],
    order: 'contTrabBaseLegal',
    searchFields: 'contTrabBaseLegal,descricao',
    listFields: 'contTrabBaseLegal,descricao'
  },
  autocomplete: {
    rowTemplate: '{{contTrabBaseLegal}} - {{descricao}}',
    output: 'contTrabBaseLegal',
    searchFields: 'contTrabBaseLegal,descricao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  detail: {
    state: {
      component: ContTrabBaseLegalEditComponent
    }
  }
};
