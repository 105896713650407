import {ConfigEfaturaDocPorLancModuleComponent} from './components/configEfaturaDocPorLanc.module.component';
import {EFaturaConfigDocsService} from './eFaturaConfigDocs.module.service';
import {IJsonEFaturaConfigDocs} from './jsonEFaturaConfigDocs.module.interface';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {TServiceResponse} from '../../services/api/api.service.interface';

export const MODULE_CONFIG_EFATURA_DOCUMENTOS_POR_LANCAR: IModuleDefinition = {
  name: 'configefaturadocporlancar',
  state: {
    url: '/configefaturadocporlancar',
    component: ConfigEfaturaDocPorLancModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.configefaturadocporlancar',
      icon: 'fa-file'
    }
  }
};

export function resolverEFaturaConfigsDocs(eFaturaConfigDocsService: EFaturaConfigDocsService): TServiceResponse<IJsonEFaturaConfigDocs> {
  return eFaturaConfigDocsService.getEFaturaConfigsDocs();
}
