import {ICGConfigurations} from '../../../services/config/config.service.interface';
import {IImportadorDateFilters, IImportadorEfaturaFilter} from './importadorEfatura.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ImportadorEfaturaComponent} from './importadorEfatura.module.component';
import {ImportadorEfaturaService} from './service/importadorEfatura.service';
import {RESOLVER_CONFIGURATIONS} from '../../../services/config/config.service.router';
import {RESOLVER_EFATURA_TIPOS_FATURA} from './service/importadorEfatura.service.di';
import {RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL} from '../../../services/contabilidadedigital/contabilidadedigital.router.interface';
import {ROLE} from '../../../services/role.const';

export const MODULE_PCA_IMPORTADOR_EFATURA: IModuleDefinition = {
  name: 'importadorEfatura',
  state: {
    url: '/importadorefatura',
    component: ImportadorEfaturaComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.importadorEfatura'
    },
    resolve: [
      RESOLVER_CONFIGURATIONS,
      RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL,
      {
        provide: 'filters',
        deps: [ImportadorEfaturaService, 'configurations'],
        useFactory: async (importadorEfaturaService: ImportadorEfaturaService, configurations: ICGConfigurations): Promise<IImportadorEfaturaFilter> => {
          const response: IImportadorDateFilters = await importadorEfaturaService.getDatasSegundoNPeriodo(configurations.empresa.periodo, true);
          return {
            fromDate: response.dataDe,
            toDate: response.dataAte
          };
        }
      },
      RESOLVER_EFATURA_TIPOS_FATURA
    ]
  }
};
