<div class="modal-header ativosimpressao-doc-modal">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h5 class="modal-title" [translate]="'ativosimpressao.modal.ativoimpressaodocmodaltitle'"></h5>
</div>

<div class="modal-body">
  <documento-contabilidade [(doc)]="doc" [onActionEdited]="fnDocumentoContabilidadeAction" [onActionDeleted]="fnDocumentoContabilidadeActionDel" [maintenanceMode]="true"></documento-contabilidade>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn btn-sm btn-success action-dismiss" (evtClicked)="close()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
