export enum EMtdCalcPrVen {
  MargemPrecoVenda = 1,
  MargemPrecoCompra = 2
}

export enum ECriterio {
  None,
  CustoMedioPonderado,
  Lifo,
  Fifo,
  CustoPadrao,
  PrecoVenda01,
  PrecoVenda02,
  PrecoVenda03,
  PrecoVenda04,
  PrecoVenda05,
  PrecoVenda06,
  PrecoVenda07,
  PrecoVenda08,
  PrecoVenda09,
  PrecoVenda10,
  NIFO,
  CustoMedio,
  LoteEspecifico
}

export interface IMetodoSource {
  valueMetodo: number;
  nameMetodo: string;
}

export interface IJsonArtigosTemplate {
  tipoArtigo: number;
  nDepart: number;
  nFamilia: number;
  nSubfa: number;
  codCalcPrVen: EMtdCalcPrVen;
  codIva: number;
  uniMov: number;
  nContaPoc: string;
  codValoriz: ECriterio;
  nUnidFisicas: number;
  codIvaCompra: number;
  naoPExistNeg: number;
  nContaPocComp: string;
  movimentaStock: boolean;
  nomeTipoArtigo: string;
  nomeDepart: string;
  nomeFamilia: string;
  nomeSubfa: string;
  nomeIva: string;
  nomeIvaCompra: string;
  nomeUniMov: string;
  qtdPorUnid1: number;
  nomeContaPoc: string;
  nomeContaPocComp: string;
}
