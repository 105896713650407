import {Component, Injector, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {Modelo10Service} from '../../modelo10.module.service';
import {HttpResponse} from '@angular/common/http';
import {IJsonString} from '../../../../../../common/interfaces/json';

@Component({
  selector: 'modal-modelo10-config',
  templateUrl: './modelo10.config.modal.component.html'
})
export class Modelo10ConfigModalComponent extends CGModalComponent<void> implements OnInit {
  public radicais: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo10Service: Modelo10Service
  ) {
    super(_injector);
    this.radicais = '';
  }

  public ngOnInit(): void {
    this._modelo10Service.getRadicaisConta().then((response: HttpResponse<IJsonString>) => {
      this.radicais = response.body.value;
    });
  }

  public close(): void {
    this._modelo10Service.saveRadicaisConta(this.radicais).then((response: HttpResponse<IJsonString>) => {
      this.radicais = response.body.value;
    });
    super.close();
  }
}
