import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {HttpResponse} from '@angular/common/http';
import {IJsonApuramentoSaldoInfo, IJsonSaldosContasInventario} from '../../jsonApuramentoResultados.module.interface';
import {ContabilidadeApuramentoResultadosService} from '../../contabilidade.apuramentoResultados.module.service';
import {IJsonString} from '../../../../../../../common/interfaces/json';
import {isObject} from 'pl-comps-angular';
import {IDevExpressDataGrid} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {TranslateService} from '@ngx-translate/core';
import {IDevExpressDataGridEventOnCellPrepared, IDevExpressDataGridEventOnInitialized} from '../../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';

@Component({
  selector: 'modal-saldos-contas-inventario',
  templateUrl: './saldosContasInventario.modal.component.html'
})
export class SaldosContasInventarioModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public saldosContasInventario: IJsonSaldosContasInventario;
  public readonly anosSource: Array<number>;
  public readonly definitionCompras: IDevExpressDataGrid;
  public readonly definitionProdutos: IDevExpressDataGrid;

  public periodosSource: Array<string>;
  public selectedAno: number;
  public promise: Promise<void>;

  private _dataGridInstanceCompras: dxDataGrid<IJsonApuramentoSaldoInfo, IJsonApuramentoSaldoInfo>;
  private _dataGridInstanceProdutos: dxDataGrid<IJsonApuramentoSaldoInfo, IJsonApuramentoSaldoInfo>;
  private _loadedPeriodo: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _apuramentoResultadosService: ContabilidadeApuramentoResultadosService,
    private readonly _translate: TranslateService
  ) {
    super(_injector);
    this.anosSource = [];
    this.periodosSource = [];

    this.definitionCompras = {
      columns: [
        {dataField: 'nomeConta', dataType: 'string', caption: ''},
        {dataField: 'saldoInicial', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.saldoInicial', format: {suppressZeros: true}},
        {dataField: 'compras', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.compras', format: {suppressZeros: true}},
        {dataField: 'devolucao', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.devolucao', format: {suppressZeros: true}},
        {dataField: 'descontos', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.descontos', format: {suppressZeros: true}},
        {dataField: 'totCompras', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.totCompras', format: {suppressZeros: true}},
        {dataField: 'comprasTrf', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.comprasTrf', format: {suppressZeros: true}},
        {dataField: 'difCompras', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.difCompras', format: {suppressZeros: true}},
        {dataField: 'saldoFinal', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.saldoFinal', format: {suppressZeros: true}},
        {dataField: 'regularizacoesRegistadas', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.regularizacoesRegistadas', format: {suppressZeros: true}},
        {dataField: 'regularizacoesTrfConsumo', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.regularizacoesTrfConsumo', format: {suppressZeros: true}},
        {dataField: 'difRegularizacoes', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.difRegularizacoes', format: {suppressZeros: true}},
        {dataField: 'consumo', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.consumo', format: {suppressZeros: true}},
        {dataField: 'consCalculado', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.consCalculado', format: {suppressZeros: true}},
        {dataField: 'difConsumo', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.difConsumo', format: {suppressZeros: true}}
      ],
      dataSource: new CustomStore({
        load: () => this._compras()
      }),
      remoteOperations: false,
      toolbar: {
        items: [{text: this._translate.instant('apuramentoresultados.modal.saldoscontinv.compras'), location: 'before'}, 'exportButton', 'columnChooserButton']
      }
    };
    this.definitionProdutos = {
      columns: [
        {dataField: 'nomeConta', dataType: 'string', caption: ''},
        {dataField: 'saldoInicial', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.saldoInicial', format: {suppressZeros: true}},
        {dataField: 'saldoFinal', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.saldoFinal', format: {suppressZeros: true}},
        {dataField: 'regularizacoesRegistadas', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.regularizacoesRegistadas', format: {suppressZeros: true}},
        {dataField: 'regularizacoesTrfVariacao', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.regularizacoesTrfVariacao', format: {suppressZeros: true}},
        {dataField: 'difRegularizacoesProducao', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.difRegularizacoesProducao', format: {suppressZeros: true}},
        {dataField: 'variacaoProducao', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.variacaoProducao', format: {suppressZeros: true}},
        {dataField: 'varProducaoCalc', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.varProducaoCalc', format: {suppressZeros: true}},
        {dataField: 'difProducao', dataType: 'double', caption: 'apuramentoresultados.modal.saldoscontinv.fields.difProducao', format: {suppressZeros: true}}
      ],
      dataSource: new CustomStore({
        load: () => this._produtos()
      }),
      remoteOperations: false,
      toolbar: {
        items: [{text: this._translate.instant('apuramentoresultados.modal.saldoscontinv.produtos'), location: 'before'}, 'exportButton', 'columnChooserButton']
      }
    };
  }

  public ngOnInit(): void {
    this._loadedPeriodo = this.saldosContasInventario.periodo;
    this.selectedAno = this._configService.configurations.empresa.anoEmCursoIRC;
    this.anosSource.push(this._configService.configurations.empresa.anoEmCursoIRC);
    this.anosSource.push(this._configService.configurations.empresa.anoEmCursoIRC + 1);
    this._loadPeriodos();
  }

  public close(): void {
    super.close();
  }

  public onInitializedCompras(event: IDevExpressDataGridEventOnInitialized<IJsonApuramentoSaldoInfo, IJsonApuramentoSaldoInfo>): void {
    this._dataGridInstanceCompras = event.component;
  }

  public onInitializedProdutos(event: IDevExpressDataGridEventOnInitialized<IJsonApuramentoSaldoInfo, IJsonApuramentoSaldoInfo>): void {
    this._dataGridInstanceProdutos = event.component;
  }

  public async anoChanged(): Promise<void> {
    await this._loadPeriodos();
    const currentPeriodo: string = this.periodosSource.find((periodo: string) => periodo === this._loadedPeriodo);
    if (currentPeriodo) {
      this.saldosContasInventario.periodo = currentPeriodo;
    } else {
      this.saldosContasInventario.periodo = this.periodosSource[0];
    }
    await this.periodoChanged();
  }

  public periodoChanged(): Promise<void> {
    this.promise = this._apuramentoResultadosService
      .getLoadInformacaoSaldosApuramento(this.saldosContasInventario.periodo)
      .then((response: HttpResponse<IJsonSaldosContasInventario>) => {
        this.saldosContasInventario.compras = response.body.compras;
        this.saldosContasInventario.produtos = response.body.produtos;
        this._dataGridInstanceCompras.refresh();
        this._dataGridInstanceProdutos.refresh();
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  public onCellPreparedCompras(event: IDevExpressDataGridEventOnCellPrepared<IJsonApuramentoSaldoInfo, IJsonApuramentoSaldoInfo>): void {
    if (event.rowType === 'data' && isObject(event.data)) {
      switch (event.column.dataField) {
        case 'difCompras':
          if (event.data.difComprasTemErro) {
            event.cellElement.classList.add('saldoscontasinventario-error');
          }
          break;
        case 'saldoFinal':
          if (event.data.saldoFinalTemErro) {
            event.cellElement.classList.add('saldoscontasinventario-error');
          }
          break;
        case 'difRegularizacoes':
          if (event.data.difRegularizacoesTemErro) {
            event.cellElement.classList.add('saldoscontasinventario-error');
          }
          break;
        case 'difConsumo':
          if (event.data.difConsumoTemErro) {
            event.cellElement.classList.add('saldoscontasinventario-error');
          }
          break;
      }
    }
  }

  public onCellPreparedProdutos(event: IDevExpressDataGridEventOnCellPrepared<IJsonApuramentoSaldoInfo, IJsonApuramentoSaldoInfo>): void {
    if (event.rowType === 'data' && isObject(event.data)) {
      switch (event.column.dataField) {
        case 'saldoFinal':
          if (event.data.saldoFinalTemErro) {
            event.cellElement.classList.add('saldoscontasinventario-error');
          }
          break;
        case 'difRegularizacoesProducao':
          if (event.data.difRegularizacoesProducaoTemErro) {
            event.cellElement.classList.add('saldoscontasinventario-error');
          }
          break;
        case 'difProducao':
          if (event.data.difProducaoTemErro) {
            event.cellElement.classList.add('saldoscontasinventario-error');
          }
          break;
      }
    }
  }

  private _compras(): Array<IJsonApuramentoSaldoInfo> {
    return this.saldosContasInventario.compras;
  }

  private _produtos(): Array<IJsonApuramentoSaldoInfo> {
    return this.saldosContasInventario.produtos;
  }

  private _loadPeriodos(): Promise<void> {
    this.periodosSource = [];
    return this._apuramentoResultadosService.loadPeriodos(this.selectedAno).then((response: HttpResponse<Array<IJsonString>>) => {
      for (const perio of response.body) {
        this.periodosSource.push(perio.value);
      }
    });
  }
}
