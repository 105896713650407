import type dxDataGrid from 'devextreme/ui/data_grid';
import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {KEYCODES, PlAlertService, toDecimal} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {devExpressValidatorDigitsOnly} from '../../../../../components/devexpress/validator/devexpress.validators.interface';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnKeyDown} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonModelo25Config, IJsonModelo25ContasAssociadas, IJsonTiposDonativos} from '../../jsonModelo25.module.interface';
import {Modelo25Service} from '../../modelo25.module.service';
import {TDevExpressValidatorRule} from '../../../../../components/devexpress/validator/devexpress.validator.rules.interface';
import {IModelo25DataGridValidation} from '../../modelo25.module.interface';

@Component({
  selector: 'modal-modelo25-config',
  templateUrl: './modelo25.config.modal.component.html'
})
export class Modelo25ConfigModalComponent extends CGModalComponent<IJsonModelo25Config> implements OnInit {
  @Input() public modeloConfig: IJsonModelo25Config;
  @Input() public tiposDonativos: Array<IJsonTiposDonativos>;

  public dataGridDefinitionNumerarioList: IDevExpressDataGrid<IJsonModelo25ContasAssociadas, IJsonModelo25ContasAssociadas>;
  public dataGridDefinitionEspecieList: IDevExpressDataGrid<IJsonModelo25ContasAssociadas, IJsonModelo25ContasAssociadas>;

  private _dataGridInstanceNumerarioList: dxDataGrid<IJsonModelo25ContasAssociadas, IJsonModelo25ContasAssociadas>;
  private _dataGridInstanceEspeciesList: dxDataGrid<IJsonModelo25ContasAssociadas, IJsonModelo25ContasAssociadas>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo25Service: Modelo25Service,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    const validationRules: Array<TDevExpressValidatorRule> = [
      {type: 'required', trim: true, message: this._translateService.instant('datagrid.column.required')},
      devExpressValidatorDigitsOnly(this._translateService.instant('datagrid.column.digitsOnly'))
    ];
    this.dataGridDefinitionNumerarioList = {
      columns: [
        {
          dataField: 'contaDe',
          dataType: 'string',
          caption: 'modelo25.config.contaDe',
          editCellTemplate: 'editCellContaDe',
          showEditorAlways: true,
          validationRules: validationRules
        },
        {
          dataField: 'contaAte',
          dataType: 'string',
          caption: 'modelo25.config.contaAte',
          editCellTemplate: 'editCellContaAte',
          showEditorAlways: true,
          validationRules: validationRules
        },
        {
          dataField: 'codDonativo',
          dataType: 'string',
          caption: 'modelo25.groups.q5.dataGridRegistoList.colunas.codigoDonativo',
          showEditorAlways: true,
          lookup: {
            dataSource: this.tiposDonativos,
            valueExpr: 'cod',
            displayExpr: (item: IJsonTiposDonativos) => `${item.cod} - ${item.descricao}`,
            allowClearing: false
          }
        }
      ],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        mode: 'cell',
        newRowPosition: 'last'
      },
      toolbar: {
        items: [{location: 'before', text: this._translateService.instant('modelo25.config.titleTableDonativosNumerarios')}, 'addRowButton', 'searchPanel']
      },
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      remoteOperations: false,
      searchPanel: {visible: true}
    };

    this.dataGridDefinitionEspecieList = {
      columns: [
        {
          dataField: 'contaDe',
          dataType: 'string',
          caption: 'modelo25.config.contaDe',
          editCellTemplate: 'editCellContaDe',
          showEditorAlways: true,
          validationRules: validationRules
        },
        {
          dataField: 'contaAte',
          dataType: 'string',
          caption: 'modelo25.config.contaAte',
          editCellTemplate: 'editCellContaAte',
          showEditorAlways: true,
          validationRules: validationRules
        },
        {
          dataField: 'codDonativo',
          dataType: 'string',
          caption: 'modelo25.groups.q5.dataGridRegistoList.colunas.codigoDonativo',
          showEditorAlways: true,
          lookup: {
            dataSource: this.tiposDonativos,
            valueExpr: 'cod',
            displayExpr: (item: IJsonTiposDonativos) => `${item.cod} - ${item.descricao}`,
            allowClearing: false
          }
        }
      ],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        mode: 'cell',
        newRowPosition: 'last'
      },
      toolbar: {
        items: [{location: 'before', text: this._translateService.instant('modelo25.config.titleTableDonativosEspecie')}, 'addRowButton', 'searchPanel']
      },
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      remoteOperations: false,
      searchPanel: {visible: true}
    };
  }

  public onInitializedNumerosList({component}: IDevExpressDataGridEventOnInitialized<IJsonModelo25ContasAssociadas, IJsonModelo25ContasAssociadas>): void {
    this._dataGridInstanceNumerarioList = component;
  }

  public onInitializedEspeciesList({component}: IDevExpressDataGridEventOnInitialized<IJsonModelo25ContasAssociadas, IJsonModelo25ContasAssociadas>): void {
    this._dataGridInstanceEspeciesList = component;
  }

  public async close(): Promise<void> {
    if (this._dataGridInstanceNumerarioList.hasEditData() || this._dataGridInstanceEspeciesList.hasEditData()) {
      if (this._dataGridInstanceNumerarioList.hasEditData()) {
        await this._dataGridInstanceNumerarioList.saveEditData();
      }
      if (this._dataGridInstanceEspeciesList.hasEditData()) {
        await this._dataGridInstanceEspeciesList.saveEditData();
      }
      if (this._dataGridInstanceNumerarioList.hasEditData() || this._dataGridInstanceEspeciesList.hasEditData()) {
        this._plAlertService.warning('modelo25.config.alerts.hasEditData');
        return;
      }
    }
    const datagridValidation: IModelo25DataGridValidation = await this._evaluateDataGridValidation();
    if (!datagridValidation.isValid) {
      this._plAlertService.warning(datagridValidation.errorMessage);
      return;
    }
    await this._modelo25Service.saveModelo25Config(this.modeloConfig).then((response: HttpResponse<IJsonModelo25Config>) => {
      this._plAlertService.success('modelo25.config.alerts.success');
      super.close(response.body);
    });
  }

  public onKeyDown({event, component}: IDevExpressDataGridEventOnKeyDown<IJsonModelo25ContasAssociadas, IJsonModelo25ContasAssociadas>): void {
    const keyEnter: boolean = event.key === KEYCODES.ENTER;
    if (keyEnter) {
      event.preventDefault();
      event.stopImmediatePropagation();

      const rowIndex: number = component.option('focusedRowIndex');
      let columnIndex: number = component.option('focusedColumnIndex');
      if (columnIndex >= 0 && columnIndex < component.getVisibleColumns().length - 1) {
        // move to the next column on enter
        columnIndex++;
        const cellElement: HTMLElement = component.getCellElement(rowIndex, columnIndex);
        component.focus(cellElement);
        component.editCell(rowIndex, columnIndex);
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  private async _evaluateDataGridValidation(): Promise<IModelo25DataGridValidation> {
    // validate data from datagrid NumerarioList
    const dataNumerarioList: Array<IJsonModelo25ContasAssociadas> = this._dataGridInstanceNumerarioList.getDataSource().items();
    const resultNumerarioList: boolean = dataNumerarioList.every((obj: IJsonModelo25ContasAssociadas) => !isNaN(toDecimal(obj.contaDe)) && !isNaN(toDecimal(obj.contaAte)));
    if (!resultNumerarioList) {
      return Promise.resolve({
        isValid: false,
        errorMessage: this._translateService.instant('modelo25.errors.errorDadosInseridos', {datagrid: this._translateService.instant('modelo25.config.titleTableDonativosNumerarios')})
      });
    }

    // validate data from datagrid EspeciesList
    const dataEspeciesList: Array<IJsonModelo25ContasAssociadas> = this._dataGridInstanceEspeciesList.getDataSource().items();
    const resultEspeciesList: boolean = dataEspeciesList.every((obj: IJsonModelo25ContasAssociadas) => !isNaN(toDecimal(obj.contaDe)) && !isNaN(toDecimal(obj.contaAte)));
    if (!resultEspeciesList) {
      return Promise.resolve({
        isValid: false,
        errorMessage: this._translateService.instant('modelo25.errors.errorDadosInseridos', {datagrid: this._translateService.instant('modelo25.config.titleTableDonativosEspecie')})
      });
    }

    // validate only numbers are contained in the nContas
    if (!(await this._evaluateListsOnlyHaveDigits(dataNumerarioList, dataEspeciesList))) {
      return Promise.resolve({
        isValid: false,
        errorMessage: this._translateService.instant('modelo25.errors.errorOnlyDigitsAllowed')
      });
    }

    return Promise.resolve({isValid: true});
  }

  private _evaluateListsOnlyHaveDigits(numerarioList: Array<IJsonModelo25ContasAssociadas>, especiesList: Array<IJsonModelo25ContasAssociadas>): Promise<boolean> {
    for (const item of numerarioList) {
      if (!this._isValidNumericContaAssociada(item)) {
        return Promise.resolve(false);
      }
    }

    for (const item of especiesList) {
      if (!this._isValidNumericContaAssociada(item)) {
        return Promise.resolve(false);
      }
    }

    return Promise.resolve(true);
  }

  private _isValidNumericContaAssociada(contaAssociada: IJsonModelo25ContasAssociadas): boolean {
    const isNumeric = (value: string): boolean => /^\d+$/.test(value);
    return isNumeric(contaAssociada.contaDe) && isNumeric(contaAssociada.contaAte);
  }
}
