import {fromPairs} from 'lodash-es';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {copy, isString, isUndefinedOrNull, toJson} from '../utilities/utilities';
import type {ILocationServiceURI, Params, TLocationServiceURIScheme} from './location.service.interface';

const REGEX_URI = /^(https?):\/\/(([a-zA-Z0-9.-]*)(:([0-9]*))?)/;
const URI_INDEX_URL = 0;
const URI_INDEX_SCHEME = 1;
const URI_INDEX_BASE_URL = 2;
const URI_INDEX_HOST = 3;
const URI_INDEX_PORT = 4;

@Injectable({
  providedIn: 'root'
})
export class PlLocationService implements OnDestroy {
  private readonly _queryParamsSubject: BehaviorSubject<Params>;

  constructor(private readonly _locationStrategy: LocationStrategy) {
    this._queryParamsSubject = new BehaviorSubject(this._queryParamsValue());
  }

  public ngOnDestroy(): void {
    this._queryParamsSubject.complete();
  }

  public absUrl(): string {
    return window.location.href;
  }

  public uri(): ILocationServiceURI {
    const absUrl: string = this.absUrl();
    const uri: RegExpExecArray = REGEX_URI.exec(absUrl);
    return {
      baseUrl: uri[URI_INDEX_BASE_URL],
      host: uri[URI_INDEX_HOST],
      port: parseInt(uri[URI_INDEX_PORT], 2),
      scheme: <TLocationServiceURIScheme>uri[URI_INDEX_SCHEME],
      url: uri[URI_INDEX_URL]
    };
  }

  public queryParams(): Observable<any | null> {
    return this._queryParamsSubject.asObservable().pipe(map(copy));
  }

  public queryParamsFirstOnly(): Observable<any | null> {
    return this.queryParams().pipe(take(1));
  }

  public getBaseUrl(): string {
    return [location.protocol, '//', location.host].join('');
  }

  public getUrl(): string {
    const path = [this.getBaseUrl(), location.pathname];
    if (this._hashMode()) {
      path.push('#');
    }
    return path.join('');
  }

  public setBaseUrl(url: string, replaceHistory: boolean = false, data: any = {}, title: string = ''): void {
    const fn = !replaceHistory ? 'pushState' : 'replaceState';
    window.history[fn](data, title, url);
  }

  public getRawQueryParams(): string {
    return this._hashMode() ? document.location.hash : document.location.search;
  }

  public getQueryParams(): Params {
    return this._parseQueryParams(this.getRawQueryParams());
  }

  public setQueryParam(key: string, value: any): void {
    if (!isString(value)) {
      value = toJson(value);
    }
    this._updateQueryStringParam(key, value);
    this._queryParamsSubject.next(this._queryParamsValue());
  }

  public removeQueryParam(key: string): void {
    this.setQueryParam(key, '');
  }

  public clearQueryParams(): void {
    this.setBaseUrl(this.getUrl(), true);
  }

  private _hashMode(): boolean {
    return this._locationStrategy instanceof HashLocationStrategy;
  }

  private _queryParamsValue(): Params {
    return this._parseQueryParams(window.location.href);
  }

  private _parseQueryParams(rawUrl: string): Params {
    const url = rawUrl
      .replace(/(.*)##%2F%3F/g, '')
      .replace(/(.*)\?/g, '')
      .split('&')
      .map((value) => {
        return value.split('=', 2);
      });
    return fromPairs(url);
  }

  private _updateQueryStringParam(key: string, value: string): void {
    const url = this.getUrl();
    const urlQueryString = this.getRawQueryParams().replace('#', '');
    const newParam = `${key}=${value}`;
    let params = `?${newParam}`;

    // If the 'search' string exists, then build params from it
    if (urlQueryString) {
      const updateRegex = new RegExp(`([?&])${key}[^&]*`);
      const removeRegex = new RegExp(`([?&])${key}=[^&;]+[&;]?`);

      if (isUndefinedOrNull(value) || value === '') {
        // Remove param if value is empty
        params = urlQueryString.replace(removeRegex, '$1');
        params = params.replace(/[&;]$/, '');
      } else if (updateRegex.exec(urlQueryString) !== null) {
        // If param exists already, update it
        params = urlQueryString.replace(updateRegex, `$1${newParam}`);
      } else {
        // Otherwise, add it to end of query string
        let char = '?';
        if (urlQueryString.includes(char)) {
          char = '&';
        }
        params = urlQueryString + char + newParam;
      }
    }

    // no parameter was set so we don't need the question mark
    params = params === '?' ? '' : params;

    this.setBaseUrl(url + params, true);
  }
}
