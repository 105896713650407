import {Component, Injector, Input, OnInit} from '@angular/core';
import {IPlToolbarItemDefinition, IPlToolbarMenuItem} from 'pl-comps-angular';
import {EDossierFiscalTipoElemento} from '../../../../services/portalclientaccount/portalclientaccount.service.interface';
import {IDossierFiscalModuleFile} from './dossierFiscal.module.component.interface';
import {IJsonGDocAnexo} from '../../../../interfaces/jsonGDocAnexo.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {PortalClientAccountService} from '../../../../services/portalclientaccount/portalclientaccount.service';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';

@Component({
  selector: 'dossier-fiscal',
  templateUrl: './dossierFiscal.module.component.html'
})
export class DossierFiscalComponent extends ModuloComponent implements OnInit {
  @Input() public tipoDossierFiscal: EDossierFiscalTipoElemento;
  @Input() public anos: Array<number>;

  public pdfUrl: string;
  public promise: Promise<void>;

  private readonly _mnuAnos: IPlToolbarItemDefinition<number>;
  private readonly _mnuFiles: IPlToolbarItemDefinition<IDossierFiscalModuleFile>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _portalClientAccountService: PortalClientAccountService
  ) {
    super(_injector);
    this._mnuAnos = {type: 'dropdown', caption: 'pca.contabilidade.anos', order: 0, menu: []};
    this._mnuFiles = {type: 'dropdown', caption: 'pca.contabilidade.ficheiros', order: 0, menu: []};
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.toolbar.addButton(this._mnuAnos);
    this.toolbar.addButton(this._mnuFiles);

    this._evaluateAnos();
  }

  private _evaluateAnos(): void {
    const hasAnos: boolean = this.anos.length > 0;

    this._mnuAnos.visible = hasAnos;
    this._mnuFiles.visible = hasAnos;

    if (!hasAnos) {
      if (this.pdfUrl) {
        this.pdfUrl = '';
      }
      return;
    }

    this._mnuAnos.menu = this.anos.map<IPlToolbarItemDefinition<number>>((ano: number) => {
      return {
        caption: String(ano),
        data: ano,
        click: (mnu: IPlToolbarMenuItem<number>) => {
          this._mudaAno(mnu);
        }
      };
    });

    this._mudaAno(this._mnuAnos.menu[0]);
  }

  private _getFiles(ano: number): void {
    this.promise = this._portalClientAccountService.getDossierFiscalFiles(this.tipoDossierFiscal, ano).then((response: THttpQueryResponse<IJsonGDocAnexo>) => {
      this._mnuFiles.menu = response.body.list.map((file: IJsonGDocAnexo) => {
        return {
          caption: file.nomeFicheiro,
          data: {ano: ano, file: file},
          click: (menuItem: IPlToolbarMenuItem<IDossierFiscalModuleFile>) => {
            this._mudaFile(menuItem);
          }
        };
      });

      const hasFiles: boolean = this._mnuFiles.menu.length > 0;

      this._mnuFiles.disabled = !hasFiles;

      if (hasFiles) {
        this._mudaFile(this._mnuFiles.menu[0]);
      } else {
        if (this.pdfUrl) {
          this.pdfUrl = '';
        }
        this._mnuFiles.caption = 'pca.contabilidade.semFicheiros';
      }
    });
  }

  private _mudaAno(menuItem: IPlToolbarMenuItem<number>): void {
    this._mnuAnos.caption = menuItem.caption;
    this._getFiles(menuItem.data);
  }

  private _mudaFile(menuItem: IPlToolbarMenuItem<IDossierFiscalModuleFile>): void {
    this._mnuFiles.caption = menuItem.caption;
    this._portalClientAccountService.getDossierFiscalAnexoUrl(this.tipoDossierFiscal, menuItem.data.ano, menuItem.data.file.nomeFicheiro).subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }
}
