import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {IJsonEFaturaLogin} from './eFaturaLogin.service.interface';
import {TServiceResponse} from '../api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class EFaturaLoginService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/efaturalogin`;
  }

  public get(): TServiceResponse<IJsonEFaturaLogin> {
    return this._apiService.get<IJsonEFaturaLogin>({url: this._path});
  }

  public set(data: IJsonEFaturaLogin): TServiceResponse<IJsonEFaturaLogin> {
    return this._apiService.post<IJsonEFaturaLogin>({url: this._path, body: data});
  }
}
