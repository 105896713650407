import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_MODELO22_TIPO_DONATIVO} from '../modelo22.datasource.interface';

export const DATA_SOURCE_MODELO22_TIPO_DONATIVO: IDataSource<string> = deepFreeze({
  name: DATA_SOURCE_NAME_MODELO22_TIPO_DONATIVO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{value}} - {{name}}',
    output: '{{value}} - {{name}}'
  },
  data: [
    {value: '01', name: 'modelo22.datasources.tipodonativo.01'},
    {value: '02', name: 'modelo22.datasources.tipodonativo.02'},
    {value: '03', name: 'modelo22.datasources.tipodonativo.03'},
    {value: '04', name: 'modelo22.datasources.tipodonativo.04'},
    {value: '06', name: 'modelo22.datasources.tipodonativo.06'},
    {value: '07', name: 'modelo22.datasources.tipodonativo.07'},
    {value: '08', name: 'modelo22.datasources.tipodonativo.08'},
    {value: '09', name: 'modelo22.datasources.tipodonativo.09'},
    {value: '10', name: 'modelo22.datasources.tipodonativo.10'},
    {value: '11', name: 'modelo22.datasources.tipodonativo.11'},
    {value: '12', name: 'modelo22.datasources.tipodonativo.12'},
    {value: '13', name: 'modelo22.datasources.tipodonativo.13'},
    {value: '14', name: 'modelo22.datasources.tipodonativo.14'},
    {value: '15', name: 'modelo22.datasources.tipodonativo.15'},
    {value: '16', name: 'modelo22.datasources.tipodonativo.16'},
    {value: '17', name: 'modelo22.datasources.tipodonativo.17'},
    {value: '18', name: 'modelo22.datasources.tipodonativo.18'},
    {value: '19', name: 'modelo22.datasources.tipodonativo.19'},
    {value: '20', name: 'modelo22.datasources.tipodonativo.20'},
    {value: '21', name: 'modelo22.datasources.tipodonativo.21'},
    {value: '22', name: 'modelo22.datasources.tipodonativo.22'},
    {value: '23', name: 'modelo22.datasources.tipodonativo.23'},
    {value: '24', name: 'modelo22.datasources.tipodonativo.24'},
    {value: '25', name: 'modelo22.datasources.tipodonativo.25'}
  ]
});
