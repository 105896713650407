import {PointInteractionInfo} from 'devextreme/viz/chart_components/base_chart';
import dxChart, {chartPointObject, chartSeriesObject} from 'devextreme/viz/chart';
import dxPieChart, {Legend} from 'devextreme/viz/pie_chart';
import {EventInfo} from 'devextreme/events';
import {deepFreeze, PlI18nService} from 'pl-comps-angular';
import {DEVEXPRESS_CHART_FONT_FAMILY, IDevExpressChartLabelCustomizeText} from '../devexpress.charts.interface';
import {BaseWidgetTitle} from 'devextreme/viz/core/base_widget';

const MINIMUM_PERCENTAGE = 0.01;

/* eslint-disable @typescript-eslint/no-deprecated */

export function chartCombineSeriesTooltip(event: EventInfo<dxChart | dxPieChart> & PointInteractionInfo): void {
  event.target.hideTooltip();
  let hovering = false;
  let allPointsZero = true;
  let minimumValuePoint: chartPointObject;
  const series: Array<chartSeriesObject> = <Array<chartSeriesObject>>event.component.getAllSeries();
  for (const seriesItem of series) {
    const seriesItemPoints: Array<chartPointObject> = <Array<chartPointObject>>seriesItem.getPointsByArg(event.target.originalArgument);
    for (const point of seriesItemPoints) {
      if ((!minimumValuePoint || point.originalValue < minimumValuePoint.originalValue) && seriesItem.isVisible()) {
        minimumValuePoint = point;
      }
      if (point.originalValue !== 0 && seriesItem.isVisible()) {
        allPointsZero = false;
      }
      if (point.isHovered()) {
        hovering = true;
      }
    }
  }
  if (minimumValuePoint) {
    if (hovering) {
      minimumValuePoint.showTooltip();
      if (allPointsZero) {
        minimumValuePoint.hideTooltip();
      }
    } else {
      minimumValuePoint.hideTooltip();
    }
  }
}

/* eslint-enable @typescript-eslint/no-deprecated */

// eslint-disable-next-line @typescript-eslint/no-deprecated
export function chartToggleSeriesVisibility(event: {target: chartSeriesObject}): void {
  if (event.target.isVisible()) {
    event.target.hide();
  } else {
    event.target.show();
  }
}

export function chartLabelWithPercent(
  pointInfo: IDevExpressChartLabelCustomizeText,
  i18nService: PlI18nService,
  useChartAutoPercent: boolean = true,
  useNumberDoubleFormat: boolean = true,
  usePercentDecimals: boolean = false,
  percentNumberDecimals: number = 2
): string {
  const percentValue = useChartAutoPercent ? pointInfo.percent : pointInfo.point.data.percent;
  const customAutoPercent = Number((Number(pointInfo.percent) * 100).toFixed(percentNumberDecimals));
  const customPercentNumber: number = Math.abs(Number(customAutoPercent) % 1) < Number.EPSILON ? Math.trunc(customAutoPercent) : customAutoPercent; // remove .00 from 100.00%
  const percent: string =
    percentValue < MINIMUM_PERCENTAGE ? '<1' : useChartAutoPercent && usePercentDecimals ? `${customPercentNumber}%` : useChartAutoPercent ? pointInfo.percentText : `${Math.round(percentValue)}%`;
  const originalValue: string = useNumberDoubleFormat ? i18nService.formatNumber(pointInfo.originalValue) : pointInfo.originalValue.toString();
  return `${originalValue} (${percent})`;
}

// eslint-disable-next-line @typescript-eslint/no-deprecated
export const CHART_TITLE: BaseWidgetTitle = deepFreeze({
  horizontalAlignment: 'center',
  font: {
    size: 18,
    weight: 800,
    family: DEVEXPRESS_CHART_FONT_FAMILY
  }
});

export const CHART_LEGEND: Legend = deepFreeze({
  verticalAlignment: 'top',
  horizontalAlignment: 'center',
  itemTextPosition: 'right',
  font: {
    weight: 700,
    family: DEVEXPRESS_CHART_FONT_FAMILY
  },
  margin: {
    top: 0,
    bottom: 15
  }
});
