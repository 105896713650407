import {firstValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {isArray, isBoolean, isEmpty, isObject} from 'pl-comps-angular';
import {CGExceptionService} from '../../../exceptions/exceptions.service';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {ContabilidadeDigitalService} from '../../../../services/contabilidadedigital/contabilidadedigital.service';
import {EFolderDoc} from '../../../../entities/gdoc/gdoc.entity.interface';
import {ICGExceptionError} from '../../../exceptions/exceptions.service.interface';
import {IContabDigitalDocViewerRecolhaChooseFolderModalResult} from './contabilidadedigital.docviewer.recolha.choosefolder.modal.component.interface';
import {IContabilidadeDigitalTreeViewerEvtFileItemChanged, IContabilidadeDigitalTreeViewerEvtFolderItemChanged} from '../../common/treeviewer/contabilidadedigital.treeviewer.component.interface';
import {
  IJsonContabDigitalConfigs,
  IJsonContabDigitalFile,
  IJsonContabDigitalFolder,
  IJsonContabDigitalGDocViewerRecolhaFolder
} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';

@Component({
  selector: 'contabilidadedigital-docviewer-recolha-choosefolder-modal',
  templateUrl: './contabilidadedigital.docviewer.recolha.choosefolder.modal.component.html'
})
export class ContabilidadeDigitalDocViewerRecolhaChooseFolderModalComponent extends CGModalComponent<IContabDigitalDocViewerRecolhaChooseFolderModalResult> implements OnInit {
  @Input() public loadLastFolder: boolean;
  @Input() public showFiles: boolean;
  @Input() public anoEmCursoIRC: boolean;
  @Input() public fileRequired: boolean;
  public readonly treeViewerUpwardsUntilFolderId: string;
  public treeViewerFolderId: string;
  public treeViewerFolderIdAsUpwards: boolean;
  public title: string;
  public docViewerDocId: string;
  public valid: boolean;
  public loading: boolean;
  public error: string;

  private _selectedFolder: IJsonContabDigitalFolder;
  private _selectedFile: IJsonContabDigitalFile;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService
  ) {
    super(_injector);
    this.treeViewerUpwardsUntilFolderId = EFolderDoc.DefaultFolderContabNaoClassificados;
    this.title = 'arquivodigital.docviewerrecolha.choosefoldermodal.title';
    this.valid = false;
    this.loading = true;
  }

  public ngOnInit(): void {
    if (!isBoolean(this.loadLastFolder)) {
      this.loadLastFolder = true;
    }
    if (!isBoolean(this.showFiles)) {
      this.showFiles = true;
    }
    if (!isBoolean(this.anoEmCursoIRC)) {
      this.anoEmCursoIRC = false;
    }
    if (!isBoolean(this.fileRequired)) {
      this.fileRequired = false;
    }
    if (this.fileRequired) {
      this.title = 'arquivodigital.docviewerrecolha.choosefoldermodal.titleFileRequired';
    }
    this.loading = true;
    let promise: Promise<string>;
    if (this.loadLastFolder) {
      promise = this._loadLastFolder();
    }
    Promise.resolve(promise)
      .then((lastFolder: string) => {
        if (lastFolder) {
          this.treeViewerFolderId = lastFolder;
          this.treeViewerFolderIdAsUpwards = true;
        } else {
          this.treeViewerFolderId = EFolderDoc.DefaultFolderContabNaoClassificados;
          this.treeViewerFolderIdAsUpwards = false;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  public close(): void {
    if (this.valid) {
      super.close({folder: this._selectedFolder, file: this._selectedFile});
    }
  }

  public changedFolder({folder}: IContabilidadeDigitalTreeViewerEvtFolderItemChanged): void {
    this._selectedFolder = folder;
    if (isArray(this._selectedFolder.files) && this._selectedFolder.files.length) {
      this._selectedFile = this._selectedFolder.files[0];
    }
    this.valid = isObject(this._selectedFolder) && !this._selectedFolder.hasSubFolders;
    if (this.valid && this.fileRequired && !this.docViewerDocId) {
      this.valid = false;
    }
  }

  public changedFile({file}: IContabilidadeDigitalTreeViewerEvtFileItemChanged): void {
    this._selectedFile = file;
    this.docViewerDocId = this._selectedFile.docID;
    if (this.fileRequired) {
      this.valid = !isEmpty(this.docViewerDocId);
    }
  }

  public responseErrored(error: HttpErrorResponse): void {
    this.error = undefined;
    const exception: ICGExceptionError = this._cgExceptionService.get(error);
    if (exception) {
      this.error = exception.message;
    }
  }

  private async _loadLastFolder(): Promise<string> {
    const configs: IJsonContabDigitalConfigs = await firstValueFrom(this._contabilidadeDigitalService.configs.getConfigs().pipe(take(1)));
    if (isObject(configs)) {
      const lastFolder: string = configs.lastFolder;
      if (lastFolder) {
        const response: HttpResponse<IJsonContabDigitalGDocViewerRecolhaFolder> = await this._contabilidadeDigitalService.gDocViewerRecolha.folder(lastFolder);
        if (response.body.isFolderFromNaoClassificados) {
          return lastFolder;
        }
      }
    }
    return '';
  }
}
