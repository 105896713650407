import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {IPlToolbarItem} from 'pl-comps-angular';
import {EModuleTipo, IDocsComerciasEstatisticasFilters} from '../docsComerciaisEstatisticas.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DocsComerciaisEstatisticasPrintModalComponent} from './modals/docsComerciaisEstatisticas.print.modal';
import {EConfigOptionsInstanceName} from '../../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../../config/constants';

@Component({
  selector: 'module-docs-comerciais-estatisticas',
  templateUrl: './docsComerciaisEstatisticas.module.component.html'
})
export class DocsComerciaisEstatisticasModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public moduleType: EModuleTipo;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;

  public filters: IDocsComerciasEstatisticasFilters;

  private readonly _btnPrintToolbar: IPlToolbarItem;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.configOptionsInstanceName = EConfigOptionsInstanceName.DOCS_COMERCIAIS_ESTATISTICAS;
    this.configOptionsGroupName = EGroupName.ERP;
    this._btnPrintToolbar = {
      id: 'docsComerciaisEstatisticas-print-toolbar',
      order: 1,
      type: 'button',
      class: 'btn-light',
      iconLeft: '<i class="fa fa-fw fa-print"></i>',
      caption: 'global.btn.print',
      click: () => this._printModal()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnPrintToolbar);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public evtFiltersChanged(filters: IDocsComerciasEstatisticasFilters): void {
    this.filters = filters;
  }

  private _printModal(): Promise<void> {
    const filters: IDocsComerciasEstatisticasFilters = this.filters;
    const modalInstance = this._cgModalService.showVanilla(DocsComerciaisEstatisticasPrintModalComponent, {size: 'xl'});
    const componentInstance: DocsComerciaisEstatisticasPrintModalComponent = modalInstance.componentInstance;
    componentInstance.filters = filters;
    componentInstance.moduleType = this.moduleType;
    return modalInstance.result;
  }
}
