<div class="faturacao-contratos-module entity-detail-form">
  <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>

  <pl-split-view [initialSizeLeft]="78" [initialSizeRight]="22">
    <div *plSplitViewLeftSide>
      <cg-card-panel #cardPanel="cgCardPanel" [collapsed]="false">
        <div *cgCardPanelContent>
          <pl-form>
            <pl-group>
              <pl-group>
                <label [translate]="'faturacaoContratos.caption.classeContrato'"></label>
                <edit>
                  <entity-autocomplete
                    attrName="classescontratosservico"
                    entity="classescontratosservicos"
                    [filter]="classeFilter"
                    [model]="header"
                    [selectedKey]="header.nClasseCtr"
                    (selectedKeyChange)="classeContratoChanged($event)"
                    (evtSelectedDescriptionChanged)="header.nomeClasseCtr = $event"
                    [output]="classeOutPut"
                    plAutoFocus>
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>

            <div class="pl-group-label" style="height: 22px">
              <label [translate]="'faturacaoContratos.caption.tipoClasseContrato'"></label>
            </div>
            <div class="card">
              <div class="card-body">
                <pl-group *ngIf="contratoTipoSource.length > 0">
                  <div *ngFor="let tipoContrato of contratoTipoSource">
                    <edit>
                      <pl-edit type="checkbox" attrName="normal" [(model)]="tipoContrato.selected" [properties]="{label: tipoContrato.nTipoContrato + '-' + tipoContrato.nome}"></pl-edit>
                    </edit>
                  </div>
                </pl-group>
                <div *ngIf="!contratoTipoSource.length">
                  <span [translate]="'faturacaoContratos.messages.semClasseSelecionada'"></span>
                </div>
              </div>
            </div>
            <div class="form-control-info">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
              <span class="btn" [translate]="'faturacaoContratos.messages.infoFaturacaoAuto'" (click)="callModuloSelTiposAvencas()"></span>
            </div>

            <pl-group *ngIf="optionShowZonaDeAte">
              <pl-group>
                <label [translate]="'faturacaoContratos.caption.nZonaDe'"></label>
                <edit>
                  <entity-autocomplete entity="zonas" attrName="nZonaDe" [selectedKey]="header.nZonaDe" output="key" (selectedKeyChange)="nZonaDeChanged($event)"></entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'global.text.to'"></label>
                <edit>
                  <entity-autocomplete entity="zonas" attrName="nZonaAte" [selectedKey]="header.nZonaAte" output="key" (selectedKeyChange)="nZonaAteChanged($event)"></entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group *ngIf="optionShowContasDeAte">
              <pl-group>
                <label [translate]="'faturacaoContratos.caption.nContaDe'"></label>
                <edit>
                  <entity-autocomplete entity="clientes" attrName="nContaDe" [selectedKey]="header.nContaDe" output="key" (modelChange)="nContaDeChanged($event)"></entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'global.text.to'"></label>
                <edit>
                  <entity-autocomplete entity="clientes" attrName="nContaAte" [selectedKey]="header.nContaAte" output="key" (selectedKeyChange)="nContaAteChanged($event)"></entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label>
                  <span [translate]="'faturacaoContratos.caption.dataDoc'"></span>
                  <pl-tooltip class="tooltip-info-label-default" [config]="{text: 'faturacaoContratos.text.dataParaProc', placement: ['bottom', 'left']}">
                    <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                  </pl-tooltip>
                </label>
                <edit>
                  <pl-edit-datepicker [(model)]="header.dataDoc"></pl-edit-datepicker>
                </edit>
              </pl-group>

              <pl-group>
                <label *ngIf="optionShowDataVenc" [translate]="'faturacaoContratos.caption.dataVenc'"></label>
                <edit *ngIf="optionShowDataVenc">
                  <pl-edit-datepicker [(model)]="header.dataVenc" [properties]="{readonly: !header.dataVencActiva}"></pl-edit-datepicker>
                </edit>

                <actions *ngIf="optionShowDataVenc">
                  <pl-edit-checkbox [(model)]="header.dataVencActiva"></pl-edit-checkbox>
                </actions>
              </pl-group>
            </pl-group>

            <pl-group>
              <edit>
                <button class="btn btn-primary" id="btnPesquisar" type="button" (click)="pesquisar()" [plPromise]="promise" data-focus>
                  <i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'faturacaoContratos.btn.search'"></span>
                </button>
              </edit>
            </pl-group>
          </pl-form>
        </div>
      </cg-card-panel>
      <div class="bancos-extrato-module-grid">
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="refCtrCabTable"
          [dataSource]="faturacaoContratosSource"
          (onInitialized)="onInitialized($event)"
          (onContentReady)="onContentReady()"
          (onSelectionChanged)="onSelectionChanged($event)"
          (onRowPrepared)="onDataGridRowPrepared($event)"
          [(selectedRowKeys)]="selectedRowKeys">
          <div *dxTemplate="let row of 'dataGridTemplateDetail'">
            {{ row.data.erroStr }}
          </div>
        </dx-data-grid>
      </div>
    </div>

    <div *plSplitViewRightSide class="listagens">
      <div class="pl-listagens">
        <div class="card">
          <div class="card-header">
            <div class="position-relative">
              <span class="fa fa-cog fa-2x card-icon"></span>
              <span class="total-listagens float-right" [translate]="'faturacaoContratos.info.dadosProcessamento'"></span>
            </div>
          </div>

          <div class="card-footer estatisticasComercial">
            <pl-group *ngIf="optionShowDataInicTransp">
              <label [translate]="'faturacaoContratos.caption.dataInicTransp'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="header.dataInicTransp"></pl-edit-datepicker>
              </edit>
            </pl-group>

            <pl-group *ngIf="optionShowHoraInicTransp">
              <label [translate]="'faturacaoContratos.caption.horaInicTransp'"></label>
              <edit>
                <pl-edit-timepicker [(model)]="header.horaInicTransp"></pl-edit-timepicker>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'faturacaoContratos.caption.origemTxtContrato'"></label>
              <edit>
                <pl-edit
                  type="radiogroup"
                  attrName="rgtipoOrigemTxtContrato"
                  [model]="header.origemTxtContrato"
                  (modelChange)="header.origemTxtContrato = $event"
                  [properties]="{groupItems: tipoOrigemTxtContrato}">
                </pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'faturacaoContratos.caption.txtContrato'"></label>
              <edit>
                <pl-edit-textarea attrName="txtContrato" [(model)]="header.txtContrato" [properties]="{disabled: header.origemTxtContrato === tiposOrigemTxtContrato.TipoDeAvenca}"></pl-edit-textarea>
              </edit>
            </pl-group>

            <pl-group *ngIf="header.origemTxtContrato === tiposOrigemTxtContrato.Manual">
              <div>
                <fieldset class="fieldset-mt-match-input">
                  <legend [translate]="'contratostiposervicos.fields.variables'"></legend>
                  <div class="templates-vars-list">
                    <ng-container *ngFor="let variable of variablesTxtContratoSource">
                      <div class="templates-var-item" (click)="applyVariable(variable)">{{ variable }}</div>
                    </ng-container>
                  </div>
                </fieldset>
              </div>
            </pl-group>

            <pl-group>
              <label [translate]="'faturacaoContratos.caption.localizacao'"></label>
              <edit>
                <pl-tooltip class="tooltip-info-label ms-2" [config]="{text: 'faturacaoContratos.caption.txtCtrNaLinhaTooltip', container: 'body', placement: ['bottom', 'left']}">
                  <pl-edit-checkbox *plTooltipContent [(model)]="header.txtCtrNaLinha" [properties]="{label: 'faturacaoContratos.caption.txtCtrNaLinha'}"></pl-edit-checkbox>
                </pl-tooltip>
              </edit>
            </pl-group>

            <pl-group>
              <edit>
                <pl-tooltip class="tooltip-info-label ms-2" [config]="{text: 'faturacaoContratos.caption.txtCtrNoCabecalhoTooltip', container: 'body', placement: ['bottom', 'left']}">
                  <pl-edit-checkbox *plTooltipContent [(model)]="header.txtCtrNoCabecalho" [properties]="{label: 'faturacaoContratos.caption.txtCtrNoCabecalho'}"></pl-edit-checkbox>
                </pl-tooltip>
              </edit>
            </pl-group>
          </div>
        </div>
      </div>
    </div>
  </pl-split-view>

  <br />

  <div class="bancos-extrato-module-legend">
    <cg-table-legend [legend]="tableLegend"></cg-table-legend>
  </div>
</div>
