<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'abdes.unlockPrompt.title'" [translateParams]="{perfilSistemaAbdesNome: perfilSistemaAbdesNome}"></h4>
</div>

<div class="modal-body">
  <span class="h6 mb-2" [innerHTML]="'abdes.unlockPrompt.message' | translate: {perfilSistemaAbdesNome: perfilSistemaAbdesNome, abonosDescontos: titleAbonosDescontos}"></span>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button type="button" klass="btn btn-sm btn-success action-ok" (evtClicked)="close()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

  <pl-button type="button" klass="btn-light btn-sm action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
</div>
