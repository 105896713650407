import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonTemplateMail, IJsonTemplateVar} from './jsonTemplateConfig.entity.interface';
import {TServiceQueryResponse} from '../../services/api/api.service.interface';

export interface ITemplatesEntityService extends IEntityService<IJsonTemplateMail> {
  getVariaveis(nomeTemplate: string): TServiceQueryResponse<IJsonTemplateVar>;
}

export interface ITemplatesEntity extends IEntityDefinition<IJsonTemplateMail, ITemplatesEntityService> {}

export const ENTITY_NAME_TEMPLATES = 'templates';
