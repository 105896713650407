import {ENTITY_NAME_FREMUN} from './fRemun.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonFRemun} from './jsonFRemun.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_FREMUN: IEntityDefinition<IJsonFRemun> = {
  name: ENTITY_NAME_FREMUN,
  roles: [ROLE.RH],
  searchPlaceholder: 'fremun.pesquisa',
  pageTitle: 'global.menu.fremun',
  metadata: {
    keyName: 'nFRemun',
    fields: [
      {name: 'nFRemun', width: '200px', caption: 'fremun.fields.nFRemun', validators: {required: true, maxlength: 4}},
      {name: 'designacaoBreve', caption: 'fremun.fields.designacaoBreve', validators: {required: true}},
      {name: 'designacaoCompleta', caption: 'fremun.fields.designacaoCompleta', validators: {required: true}}
    ]
  },
  autocomplete: {
    rowTemplate: '{{nFRemun}} - {{designacaoBreve}}',
    output: 'designacaoBreve',
    searchFields: 'nFRemun,designacaoBreve,designacaoCompleta'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  }
};
