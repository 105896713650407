import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import type {
  IPlUITreeDragEvtDragOver,
  IPlUITreeDragEvtDragStart,
  IPlUITreeDragEvtRemoveNode,
  IPlUITreeDragInternalNode,
  IPlUITreeDragNodeContext,
  IPlUITreeDragNodeInstance
} from './uitreedrag.interface';

@Component({
  selector: 'pl-ui-tree-drag-node',
  templateUrl: './uitreedrag.node.component.html',
  standalone: false
})
export class PlUITreeDragNodeComponent implements IPlUITreeDragNodeInstance {
  @Input() public node: IPlUITreeDragInternalNode<any>;
  @Input() public nodeIndex: number;
  @Input() public parentArray: Array<IPlUITreeDragInternalNode<any>>;
  @Input() public parentNode: IPlUITreeDragInternalNode<any>;
  @Input() public disabled: boolean;
  @Input() public templateContent: TemplateRef<IPlUITreeDragNodeContext<any>>;
  @Output() public readonly evtDragStart: EventEmitter<IPlUITreeDragEvtDragStart<any>>;
  @Output() public readonly evtDragOver: EventEmitter<IPlUITreeDragEvtDragOver<any>>;
  @Output() public readonly evtRemoveNode: EventEmitter<IPlUITreeDragEvtRemoveNode<any>>;

  public readonly self: PlUITreeDragNodeComponent;

  constructor() {
    this.evtDragStart = new EventEmitter<IPlUITreeDragEvtDragStart<any>>();
    this.evtDragOver = new EventEmitter<IPlUITreeDragEvtDragOver<any>>();
    this.evtRemoveNode = new EventEmitter<IPlUITreeDragEvtRemoveNode<any>>();
    this.self = this;
  }

  public dragStart(event: MouseEvent, sourceArray: Array<IPlUITreeDragInternalNode>, index: number): void {
    this.evtDragStart.emit({
      event: event,
      sourceArray: sourceArray,
      index: index
    });
  }

  public dragOver(event: MouseEvent, targetArray: Array<IPlUITreeDragInternalNode>, index: number): void {
    this.evtDragOver.emit({
      event: event,
      targetArray: targetArray,
      index: index
    });
  }

  public removeNode(): void {
    this.evtRemoveNode.emit({parentArray: this.parentArray, nodeIndex: this.nodeIndex});
  }
}
