import {deepFreeze} from 'pl-comps-angular';

export const ENTITY_NAME_REP_FINANCAS = 'repfinancas';

export enum ETipoFinancas {
  BairroFiscal = 1,
  Reparticao
}

export const RADIO_GROUP_TIPOFINANCAS = deepFreeze([
  {value: ETipoFinancas.BairroFiscal, label: 'repfinancas.tipo.bairroFiscal'},
  {value: ETipoFinancas.Reparticao, label: 'repfinancas.tipo.reparticao'}
]);
