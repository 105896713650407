import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {TServiceQueryResponse, TServiceResponse} from '../../../services/api/api.service.interface';
import {
  IJsonGestaoComunicacoesConfigEmpresaReg,
  IJsonGestaoComunicacoesEmpresa,
  IJsonGestaoComunicacoesEmpresaEstados,
  IJsonGestaoComunicacoesNewMessages,
  IJsonGestaoComunicacoesTopico,
  IJsonGestaoComunicacoesUtilizadoRes,
  IJsonGestoComunicacoesConfig
} from './jsonGestaoComunicacoesTopico.module.interface';
import {IJsonGestaoComunicacoesMensagem} from './jsonGestaoComunicacoesMensagem.module.interface';
import {IGestaoComunicacoesQueryParamAdditionalFields} from './gestaoComunicacoes.module.interface';

@Injectable({
  providedIn: 'root'
})
export class GestaoComunicacoesTopicoService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/comtopicos`;
  }

  public deleteTopico(topicoId: string): TServiceResponse<void> {
    return this._apiService.delete<void>({
      url: `${this._path}/${topicoId}`
    });
  }

  public getTopico(topicoId: string): TServiceResponse<IJsonGestaoComunicacoesTopico> {
    return this._apiService.get<IJsonGestaoComunicacoesTopico>({
      url: `${this._path}/${topicoId}`
    });
  }

  public getTopicos(params?: IGestaoComunicacoesQueryParamAdditionalFields): TServiceQueryResponse<IJsonGestaoComunicacoesTopico> {
    return this._apiService.query<IJsonGestaoComunicacoesTopico>({
      url: this._path,
      params: {...params}
    });
  }

  public downloadFile(topicoId: string, documentoId: string, alt?: string): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: `${this._path}/${topicoId}/${documentoId}/downloadfile`,
      responseType: 'blob',
      params: {alt: alt}
    });
  }

  public getHasNewMessages(topicoId: string, isContabilista: boolean): TServiceResponse<IJsonGestaoComunicacoesNewMessages> {
    return this._apiService.get<IJsonGestaoComunicacoesNewMessages>({
      url: `${this._path}/${topicoId}/hasnewmessages`,
      params: {iscontabilista: isContabilista}
    });
  }

  public getTopicoMessages(topicoId: string, isContabilista: boolean): TServiceResponse<Array<IJsonGestaoComunicacoesMensagem>> {
    return this._apiService.get<Array<IJsonGestaoComunicacoesMensagem>>({
      url: `${this._path}/${topicoId}/message`,
      params: {iscontabilista: isContabilista}
    });
  }

  public getConfig(): TServiceResponse<IJsonGestoComunicacoesConfig> {
    return this._apiService.get<IJsonGestoComunicacoesConfig>({
      url: `${this._path}/config`
    });
  }

  public getConfigEmpresa(nEmpresa: string): TServiceResponse<IJsonGestaoComunicacoesConfigEmpresaReg> {
    return this._apiService.get<IJsonGestaoComunicacoesConfigEmpresaReg>({
      url: `${this._path}/config/${nEmpresa}`
    });
  }

  public getEmpresas(): TServiceResponse<Array<IJsonGestaoComunicacoesEmpresa>> {
    return this._apiService.get<Array<IJsonGestaoComunicacoesEmpresa>>({
      url: `${this._path}/empresas`
    });
  }

  public getEmpresaEstadosTopicos(nEmpresa: string): TServiceResponse<IJsonGestaoComunicacoesEmpresaEstados> {
    return this._apiService.get<IJsonGestaoComunicacoesEmpresaEstados>({
      url: `${this._path}/empresas/${nEmpresa}/estados`
    });
  }

  public getClienteTopicos(): TServiceResponse<Array<IJsonGestaoComunicacoesTopico>> {
    return this._apiService.get<Array<IJsonGestaoComunicacoesTopico>>({
      url: `${this._path}/cliente/topicos`
    });
  }

  public getUtilizadores(isContabilista: boolean, nEmpresa: string): TServiceResponse<IJsonGestaoComunicacoesUtilizadoRes> {
    return this._apiService.get<IJsonGestaoComunicacoesUtilizadoRes>({
      url: `${this._path}/utilizadores`,
      params: {iscontabilista: isContabilista, nempresa: nEmpresa}
    });
  }

  public createTopico(topico: IJsonGestaoComunicacoesTopico): TServiceResponse<IJsonGestaoComunicacoesTopico> {
    return this._apiService.post<IJsonGestaoComunicacoesTopico>({
      url: this._path,
      body: topico
    });
  }

  public saveConfig(empresasData: IJsonGestoComunicacoesConfig): TServiceResponse<IJsonGestoComunicacoesConfig> {
    return this._apiService.post<IJsonGestoComunicacoesConfig>({
      url: `${this._path}/config`,
      body: empresasData
    });
  }

  public saveConfigEmpresa(nEmpresa: string, data: IJsonGestaoComunicacoesConfigEmpresaReg): TServiceResponse<void> {
    return this._apiService.post<void, IJsonGestaoComunicacoesConfigEmpresaReg>({
      url: `${this._path}/config/${nEmpresa}`,
      body: data
    });
  }

  public createTopicoMessageFile(topicoId: string, file: File, isContabilista: boolean): TServiceResponse<IJsonGestaoComunicacoesMensagem> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this._apiService.post<IJsonGestaoComunicacoesMensagem, FormData>({
      url: `${this._path}/${topicoId}/file`,
      body: formData,
      params: {iscontabilista: isContabilista}
    });
  }

  public createTopicoMessage(topicoId: string, mensagem: IJsonGestaoComunicacoesMensagem, isContabilista: boolean): TServiceResponse<IJsonGestaoComunicacoesMensagem> {
    return this._apiService.post<IJsonGestaoComunicacoesMensagem>({
      url: `${this._path}/${topicoId}/message`,
      body: mensagem,
      params: {iscontabilista: isContabilista}
    });
  }

  public updateTopico(topicoId: string, topico: IJsonGestaoComunicacoesTopico): TServiceResponse<IJsonGestaoComunicacoesTopico> {
    return this._apiService.put<IJsonGestaoComunicacoesTopico>({
      url: `${this._path}/${topicoId}`,
      body: topico
    });
  }

  public reorderTopicos(estadoId: string, topicosList: Array<IJsonGestaoComunicacoesTopico>): TServiceResponse<Array<IJsonGestaoComunicacoesTopico>> {
    return this._apiService.put<Array<IJsonGestaoComunicacoesTopico>>({
      url: `${this._path}/reordenar`,
      params: {comestadoid: estadoId},
      body: topicosList
    });
  }
}
