<div class="ativos-edit">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <ativos [stateType]="ativosStateTypes.EDIT" [readonly]="readonly" [(model)]="model"></ativos>
    </div>
  </entity-detail>
</div>
