import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonPRHGrupoRefeicao} from './jsonPRHGrupoRefeicao.entity.interface';
import {ENTITY_NAME_PRH_GRUPO_REFEICOES} from './pRHGrupoRefeicoes.entity.interface';

export const ENTITY_PRH_GRUPO_REFEICOES: IEntityDefinition<IJsonPRHGrupoRefeicao> = {
  name: ENTITY_NAME_PRH_GRUPO_REFEICOES,
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  searchPlaceholder: 'prhgruporefeicoes.pesquisa',
  metadata: {
    keyName: 'grupo',
    descriptionName: 'nome',
    fields: [
      {
        name: 'grupo',
        width: '120px',
        type: 'integer',
        caption: 'prhgruporefeicoes.fields.grupo',
        placeholder: 'prhgruporefeicoes.fields.grupoPlaceholder',
        validators: {required: true, min: 1}
      },
      {name: 'nome', caption: 'prhgruporefeicoes.fields.nome', placeholder: 'prhgruporefeicoes.fields.nomePlaceholder', validators: {required: true, maxlength: 60}},
      {name: 'permiteAgendamento', type: 'boolean', caption: 'prhgruporefeicoes.fields.permiteAgendamento'},
      {name: 'horaInicio', type: 'time', caption: 'prhgruporefeicoes.fields.horaInicio', placeholder: 'HH:MM:SS'},
      {name: 'horaFim', type: 'time', caption: 'prhgruporefeicoes.fields.horaFim', placeholder: 'HH:MM:SS'}
    ],
    order: 'nome',
    searchFields: 'grupo,nome'
  },
  autocomplete: {
    rowTemplate: '{{grupo}} - {{nome}}',
    output: 'nome',
    searchFields: 'grupo,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.GESTOREMENTAS],
        pageTitle: 'global.menu.prhgruporefeicoes'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.GESTOREMENTAS],
        pageTitle: 'global.menu.prhgruporefeicoes'
      }
    }
  }
};
