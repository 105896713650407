import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonCRNEG} from './jsonCRNEG.entity.interface';
import {ENTITY_NAME_CRNEG} from './crneg.entity.interface';

export const ENTITY_CRNEG: IEntityDefinition<IJsonCRNEG> = {
  name: ENTITY_NAME_CRNEG,
  roles: [ROLE.RH],
  searchPlaceholder: 'crneg.pesquisa',
  pageTitle: 'global.menu.crneg',
  metadata: {
    keyName: 'nCodEmp',
    fields: [
      {name: 'nCodEmp', type: 'cgsmallint', caption: 'crneg.fields.nCodEmp', validators: {required: true}},
      {name: 'nomeEmp', caption: 'crneg.fields.nomeEmp', validators: {required: true}},
      {name: 'nCodOrigem', caption: 'crneg.fields.nCodOrigem', validators: {required: true}},
      {name: 'nCodDestino', caption: 'crneg.fields.nCodDestino', validators: {required: true}},
      {name: 'entSuporte', type: 'cgsmallint', caption: 'crneg.fields.entSuporte', validators: {required: true}}
    ],
    order: 'nCodEmp',
    searchFields: 'nCodEmp,nomeEmp'
  },
  autocomplete: {
    rowTemplate: '{{nCodEmp}} - {{nomeEmp}}',
    output: 'nomeEmp',
    searchFields: 'nCodEmp,nomeEmp'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  }
};
