<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="title"></h5>
</div>

<div class="modal-body">
  <ng-container [ngSwitch]="saldado">
    <ng-container *ngSwitchCase="true">
      <div [translate]="message"></div>
      <div class="mt-2">
        <small>
          <pl-edit-checkbox [(model)]="skipPromptSave" [properties]="{label: 'docscontabilidade.prompt.doNotAskAgain'}"></pl-edit-checkbox>
        </small>
      </div>
    </ng-container>

    <pl-alert type="error" *ngSwitchCase="false" [closeable]="false">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;<span [translate]="'docscontabilidade.erros.notSaldado'"></span>
    </pl-alert>
  </ng-container>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-close" [disabled]="!saldado" (evtClicked)="close()" plAutoFocus [plAutoFocusDisabled]="!saldado">
    <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span>
  </pl-button>

  <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()" plAutoFocus [plAutoFocusDisabled]="saldado">
    <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
  </pl-button>
</div>
