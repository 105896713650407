import {Component, Injector} from '@angular/core';
import {isEmpty, isObject} from 'pl-comps-angular';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {ENTITY_NAME_EMPRESAS_ERP, IEmpresaExtended} from '../../../../../entities/empresas/empresas.entity.interface';
import {TUserSession} from '../../../../../services/account/jsonUserApi.interface';
import {IContabDigitalGestaoDocsDigitalizadosExportFilters} from '../../contabDigital.documentosDigitais.module.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IJsonPeriodo} from '../../../../../entities/periodos/jsonPeriodo.entity.interface';
import {ENTITY_NAME_PERIODOS} from '../../../../../entities/periodos/periodos.entity.interface';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ContabilidadeDigitalService} from '../../../../../services/contabilidadedigital/contabilidadedigital.service';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import moment from 'moment';
import {IJsonDiario} from '../../../../../entities/diarios/jsonDiario.entity.interface';
import {ENTITY_NAME_DIARIOS} from '../../../../../entities/diarios/diarios.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {IApiQueryResponse} from '../../../../../services/api/api.service.interface';
import {EFolderDoc} from '../../../../../entities/gdoc/gdoc.entity.interface';
import {
  IContabilidadeDigitalGenericViewerCallback,
  IContabilidadeDigitalGenericViewerEvtFolderItemChanged,
  IContabilidadeDigitalGenericViewerFolderPath
} from '../../../../../components/arquivodigital/common/viewers/contabilidadedigital.genericviewer.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {DocsDigitaisExportarFolderModalComponent} from '../exportarfolder/docsDigitais.exportarFolder.modal.component';
import {IJsonContabDigitalFolder} from '../../../../../services/contabilidadedigital/jsonContabDigital.interface';

@Component({
  selector: 'docsdigitais-export-contab-digital-modal',
  templateUrl: './docsDigitais.exportar.modal.component.html'
})
export class DocsDigitaisExportarModalComponent extends CGModalComponent<IContabDigitalGestaoDocsDigitalizadosExportFilters> {
  public readonly anosRowTemplate: string = '{{caption}}';
  public readonly anosOutput: string = 'caption';
  public readonly periodosRowTemplate: string = '{{periodo}} - {{nome}}';
  public readonly periodosOutput: string = 'periodo';
  public readonly callback: IContabilidadeDigitalGenericViewerCallback;

  public folderPath: string;
  public defaultFolderId: string;
  public currentFolderPath: Array<IContabilidadeDigitalGenericViewerFolderPath>;
  public filterModel: IContabDigitalGestaoDocsDigitalizadosExportFilters;
  public anosSource: Array<number>;
  public periodosSource: Array<IJsonPeriodo>;
  public modalPromise: Promise<void>;

  private readonly _serviceEmpresas: IEntityService<IEmpresaExtended>;
  private readonly _servicePeriodos: IEntityService<IJsonPeriodo>;
  private readonly _serviceDiarios: IEntityService<IJsonDiario>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _authService: AuthService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this._serviceEmpresas = this._entityServiceBuilder.build<IEmpresaExtended>(ENTITY_NAME_EMPRESAS_ERP);
    this._servicePeriodos = this._entityServiceBuilder.build<IJsonPeriodo>(ENTITY_NAME_PERIODOS);
    this._serviceDiarios = this._entityServiceBuilder.build<IJsonDiario>(ENTITY_NAME_DIARIOS);

    this.callback = {};
    this.folderPath = '';

    this.filterModel = {
      ano: moment().year(),
      periodoDe: '',
      periodoAte: '',
      diarioDe: undefined,
      diarioAte: undefined,
      nifDe: '',
      nifAte: 'ZZZZ',
      folderId: '',
      exportaNaoClassificados: false,
      exportaApenasRelevantes: true
    };

    this.validatePeriodo = this.validatePeriodo.bind(this);
    this.modalPromise = new Promise<void>((resolve, reject) => {
      this._authService
        .identity()
        .then((session: TUserSession) => {
          this._serviceEmpresas
            .get({id: session.erp.nEmpresa})
            .then((empresaResponse) => {
              this.filterModel.ano = empresaResponse.body.anoCursoIRC;
              this._contabilidadeDigitalService.getAnosAnteriores().then((anosResponse) => {
                this.anosSource = anosResponse.body;
                this.anosSource.push(this.filterModel.ano);
                this.anosSource.push(this.filterModel.ano + 1);
                this.anosSource.sort((a: number, b: number) => b - a);
              });
              this._loadPeriodos().then(resolve);
              this._loadDiarios().then(resolve);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  public close(): void {
    super.close(this.filterModel);
  }

  public onYearChange(year: number): void {
    this.filterModel.ano = year;
    this._loadPeriodos();
  }

  public onPeriodoDeChange(item: IJsonPeriodo): void {
    this.filterModel.periodoDe = isObject(item) ? item.periodo : undefined;
  }

  public onPeriodoAteChange(item: IJsonPeriodo): void {
    this.filterModel.periodoAte = isObject(item) ? item.periodo : undefined;
  }

  public selectFolderModal(): Promise<void | string> {
    const modalRef = this._cgModalService.showVanilla(DocsDigitaisExportarFolderModalComponent, {size: 'md'});
    const componentInstance: DocsDigitaisExportarFolderModalComponent = modalRef.componentInstance;
    componentInstance.flagDocumentosClassificados = !this.filterModel.exportaNaoClassificados;
    return modalRef.result.then((folder: IJsonContabDigitalFolder) => {
      this.filterModel.folderId = folder.folderID;
      this.folderPath = folder.folderPath;
    });
  }

  public validatePeriodo(search: string, item: IJsonPeriodo): Promise<IJsonPeriodo> {
    return new Promise<IJsonPeriodo>((resolve, reject) => {
      if (isObject(item)) {
        resolve(item);
      } else if (isEmpty(search)) {
        reject();
      } else {
        const periodo: IJsonPeriodo = this.periodosSource.find((value: IJsonPeriodo) => value.periodo.toLowerCase().includes(search) || value.nome.toLowerCase().includes(search));
        if (periodo) {
          resolve(periodo);
        } else {
          reject();
        }
      }
    });
  }

  public changeNaoClassificadosCheckBox(event: boolean): void {
    this.filterModel.exportaNaoClassificados = event;
    this.resetFolder();
  }

  public changedFolder(event: IContabilidadeDigitalGenericViewerEvtFolderItemChanged): void {
    this.filterModel.folderId = event.folder.folderID;
  }

  public resetFolder(): void {
    this.filterModel.folderId = '';
    this.folderPath = '';
    this.defaultFolderId = this.filterModel.exportaNaoClassificados ? EFolderDoc.DefaultFolderContabNaoClassificados : EFolderDoc.DefaultFolderContabClassificados;
  }

  private _loadPeriodos(): Promise<void> {
    this.periodosSource = [];
    return this._servicePeriodos.query({pesquisa: `loadAllAnos=0&loadOnlyAno=${this.filterModel.ano}`}).then((response) => {
      this.periodosSource = response.body.list;
      if (this.periodosSource.length > 0) {
        this.filterModel.periodoDe = this.periodosSource[0].periodo;
        this.filterModel.periodoAte = this.periodosSource[this.periodosSource.length - 1].periodo;
      }
    });
  }

  private _loadDiarios(): Promise<void> {
    return this._serviceDiarios.query().then((response: HttpResponse<IApiQueryResponse<IJsonDiario>>) => {
      if (response.body.list.length > 0) {
        this.filterModel.diarioDe = response.body.list[0].nDiario;
        this.filterModel.diarioAte = response.body.list[response.body.list.length - 1].nDiario;
      }
    });
  }
}
