<div class="modal-header" xmlns="http://www.w3.org/1999/html">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="modalTitle"></h4>
</div>

<div class="modal-body">
  <pl-form>
    <div class="row">
      <div class="col-md-6">
        <pl-group>
          <label [translate]="'conciliacaoBancaria.fields.nconta'"></label>
          <edit>
            <entity-autocomplete
              entity="pocs"
              [model]="model"
              [selectedKey]="model.nConta"
              (selectedKeyChange)="onNContaChange($event)"
              (evtSelectedDescriptionChanged)="model.nomeNConta = $event"
              [filter]="pocsFilter"
              [showFilter]="false"
              [output]="pocsOutput"
              [fieldsMap]="{nConta: 'nConta', nome: 'nomeNConta'}"
              [properties]="{validators: {required: {value: true}}, disabled: mode === modeType.EDIT}"></entity-autocomplete>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-6">
        <pl-group>
          <label [translate]="'conciliacaoBancaria.fields.periodo'"></label>
          <label class="periodo-datas"><small [innerHTML]="periodoDatas"></small></label>
          <edit>
            <entity-autocomplete
              entity="periodos"
              [selectedKey]="model.periodo"
              (selectedKeyChange)="onPeriodoChange($event)"
              [output]="'key'"
              [outputDescription]="'key'"
              [properties]="{validators: {required: {value: true}}, disabled: isPeriodoDisabled}"></entity-autocomplete>
          </edit>
        </pl-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h4 class="main-title" [translate]="'conciliacaoBancaria.saldoExtratoBancarioPerspetivaBancoTitle'"></h4>
      </div>
      <div class="col-md-6">
        <pl-group [properties]="{disabled: !isSaldoInicialEnabled}">
          <label [translate]="'conciliacaoBancaria.fields.saldoInicialBanco'"></label>
          <edit>
            <pl-edit type="number" [(model)]="model.saldoInicial"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'conciliacaoBancaria.fields.saldoFinalBanco'"></label>
          <edit>
            <pl-edit id="conciliacaoBancaria-saldoFinalBanco" type="number" [(model)]="model.saldoFinal" [properties]="{events: {keydown: fnKeyDownSaldoFinal}}"></pl-edit>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-6">
        <pl-group [properties]="{disabled: !isSaldoInicialEnabled}">
          <label>&nbsp;</label>
          <edit>
            <pl-edit type="radiogroup" [(model)]="model.saldoInicialDC" [properties]="{groupItems: debitoCreditoItems}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label>&nbsp;</label>
          <edit>
            <pl-edit type="radiogroup" [(model)]="model.saldoFinalDC" [properties]="{groupItems: debitoCreditoItems}"></pl-edit>
          </edit>
        </pl-group>
      </div>
    </div>
  </pl-form>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <div *ngIf="abrevMoedaEstrangeira?.length" class="me-auto conciliacaoBancaria-moeda-estangeira">{{ abrevMoedaEstrangeira }}</div>
  <button type="button" class="btn btn-primary action-save" [click]="fnCloseModal" plPromise><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="saveBtnTitle"></span></button>
  <pl-button klass="btn btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
</div>
