<div class="modal-saft-contab-import">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'saftcontab.modals.import.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-upload [url]="uploadUrl" [autoProcessQueue]="false" [maxFiles]="1" [maxFileSize]="20" (evtUploadedFile)="onFileUpload()" acceptedFiles=".xlsx,.xls"></pl-upload>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
