import {Injectable} from '@angular/core';

/* eslint-disable @typescript-eslint/no-magic-numbers */

@Injectable({
  providedIn: 'root'
})
export class CGVatService {
  public config: Array<unknown>;

  private readonly _supportedCountries: Array<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly _countries: {[countryName: string]: {calcs: (...args: Array<any>) => boolean; rules: any}};

  constructor() {
    this._supportedCountries = [
      'AT',
      'BE',
      'BG',
      'HR',
      'CY',
      'CZ',
      'DK',
      'EE',
      'EU',
      'FI',
      'FR',
      'DE',
      'EL',
      'HU',
      'IE',
      'IT',
      'LV',
      'LT',
      'LU',
      'MT',
      'NL',
      'NO',
      'PL',
      'PT',
      'RO',
      'RU',
      'RS',
      'SK',
      'SI',
      'ES',
      'SE',
      'CHE',
      'GB'
    ];
    this._countries = {};
    this._countries.austria = {
      calcs: function (vat: string) {
        let total = 0;
        let temp;
        for (let i = 0; i < 7; i++) {
          temp = Number(vat.charAt(i)) * <number>this.rules.multipliers[i];
          if (temp > 9) {
            total += Math.floor(temp / 10) + (temp % 10);
          } else {
            total += temp;
          }
        }

        total = 10 - ((total + 4) % 10);
        if (total === 10) {
          total = 0;
        }

        return total === Number(vat.slice(7, 8));
      },
      rules: {
        multipliers: [1, 2, 1, 2, 1, 2, 1],
        regex: [/^(AT)U(\d{8})$/]
      }
    };
    this._countries.belgium = {
      calcs: (vat: string) => {
        if (vat.length === 9) {
          vat = `0${vat}`;
        }

        if (Number(vat.slice(1, 2)) === 0) {
          return false;
        }

        const check = 97 - (Number(vat.slice(0, 8)) % 97);
        return check === Number(vat.slice(8, 10));
      },
      rules: {
        regex: [/^(BE)(0?\d{9})$/]
      }
    };
    this._countries.bulgaria = (() => {
      function checkNineLengthVat(vat: string): boolean {
        let total;
        let temp = 0;
        const expect = Number(vat.slice(8));

        for (let i = 0; i < 8; i++) {
          temp += Number(vat.charAt(i)) * (i + 1);
        }

        total = temp % 11;
        if (total !== 10) {
          return total === expect;
        }

        temp = 0;
        for (let j = 0; j < 8; j++) {
          temp += Number(vat.charAt(j)) * (j + 3);
        }

        total = temp % 11;
        if (total === 10) {
          total = 0;
        }

        return total === expect;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function isPhysicalPerson(vat: string, rules: any): boolean {
        let total = 0;
        // 10 digit VAT code - see if it relates to a standard physical person
        if (/^\d\d[0-5]\d[0-3]\d\d{4}$/.test(vat)) {
          // Check month
          const month = Number(vat.slice(2, 4));
          if ((month > 0 && month < 13) || (month > 20 && month < 33) || (month > 40 && month < 53)) {
            total = 0;
            for (let i = 0; i < 9; i++) {
              total += Number(vat.charAt(i)) * rules.multipliers.physical[i];
            }
            // Establish check digit.
            total %= 11;
            if (total === 10) {
              total = 0;
            }

            // Check to see if the check digit given is correct, If not, try next type of person
            if (total === Number(vat.substring(9, 1))) {
              return true;
            }
          }
        }

        return false;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function isForeigner(vat: string, rules: any): boolean {
        let total = 0;
        // Extract the next digit and multiply by the counter.
        for (let i = 0; i < 9; i++) {
          total += Number(vat.charAt(i)) * rules.multipliers.foreigner[i];
        }
        // Check to see if the check digit given is correct, If not, try next type of person
        return total % 10 === Number(vat.substring(9, 1));
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function miscellaneousVAT(vat: string, rules: any): boolean {
        let total = 0;
        // Finally, if not yet identified, see if it conforms to a miscellaneous VAT number

        for (let i = 0; i < 9; i++) {
          total += Number(vat.charAt(i)) * rules.multipliers.miscellaneous[i];
        }

        // Establish check digit.
        total = 11 - (total % 11);
        if (total === 10) {
          return false;
        }
        if (total === 11) {
          total = 0;
        }

        // Check to see if the check digit given is correct, If not, we have an error with the VAT number
        const expect = Number(vat.substring(9, 1));
        return total === expect;
      }

      return {
        calcs: function (vat: string) {
          if (vat.length === 9) {
            return checkNineLengthVat(vat);
          }
          return isPhysicalPerson(vat, this.rules) || isForeigner(vat, this.rules) || miscellaneousVAT(vat, this.rules);
        },
        rules: {
          multipliers: {
            physical: [2, 4, 8, 5, 10, 9, 7, 3, 6],
            foreigner: [21, 19, 17, 13, 11, 9, 7, 3, 1],
            miscellaneous: [4, 3, 2, 7, 6, 5, 4, 3, 2]
          },
          regex: [/^(BG)(\d{9,10})$/]
        }
      };
    })();
    this._countries.croatia = {
      calcs: (vat: string) => {
        // Checks the check digits of a Croatian VAT number using ISO 7064, MOD 11-10 for check digit.
        let product = 10;
        let sum = 0;

        for (let i = 0; i < 10; i++) {
          // Extract the next digit and implement the algorithm
          sum = (Number(vat.charAt(i)) + product) % 10;
          if (sum === 0) {
            sum = 10;
          }
          product = (2 * sum) % 11;
        }

        // Now check that we have the right check digit
        const expect = Number(vat.slice(10, 11));
        return (product + expect) % 10 === 1;
      },
      rules: {
        regex: [/^(HR)(\d{11})$/]
      }
    };
    this._countries.cyprus = {
      calcs: (vat: string) => {
        let total = 0;

        // Not allowed to start with '12'
        if (Number(vat.slice(0, 2)) === 12) {
          return false;
        }

        // Extract the next digit and multiply by the counter.

        for (let i = 0; i < 8; i++) {
          let temp = Number(vat.charAt(i));
          if (i % 2 === 0) {
            switch (temp) {
              case 0:
                temp = 1;
                break;
              case 1:
                temp = 0;
                break;
              case 2:
                temp = 5;
                break;
              case 3:
                temp = 7;
                break;
              case 4:
                temp = 9;
                break;
              default:
                temp = temp * 2 + 3;
            }
          }
          total += temp;
        }

        // Establish check digit using modulus 26, and translate to char. equivalent.
        total %= 26;
        const totalStr = String.fromCharCode(total + 65);

        // Check to see if the check digit given is correct
        const expect = vat.substring(8, 1);
        return totalStr === expect;
      },
      rules: {
        regex: [/^(CY)([0-59]\d{7}[A-Z])$/]
      }
    };
    this._countries.czechRepublic = (() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function isLegalEntities(vat: string, rules: any): boolean {
        let total = 0;
        if (rules.additional[0].test(vat)) {
          // Extract the next digit and multiply by the counter.
          for (let i = 0; i < 7; i++) {
            total += Number(vat.charAt(i)) * rules.multipliers[i];
          }

          // Establish check digit.
          total = 11 - (total % 11);
          if (total === 10) {
            total = 0;
          }
          if (total === 11) {
            total = 1;
          }

          // Compare it with the last character of the VAT number. If it's the same, then it's valid.
          const expect = Number(vat.slice(7, 8));
          return total === expect;
        }

        return false;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function isIndividualType2(vat: string, rules: any): boolean {
        let total = 0;
        if (rules.additional[2].test(vat)) {
          // Extract the next digit and multiply by the counter.
          for (let j = 0; j < 7; j++) {
            total += Number(vat.charAt(j + 1)) * rules.multipliers[j];
          }

          // Establish check digit.
          total = 11 - (total % 11);
          if (total === 10) {
            total = 0;
          }
          if (total === 11) {
            total = 1;
          }

          // Convert calculated check digit according to a lookup table;
          const expect = Number(vat.slice(8, 9));
          return rules.lookup[total - 1] === expect;
        }

        return false;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function isIndividualType3(vat: string, rules: any): boolean {
        if (rules.additional[3].test(vat)) {
          const temp = Number(vat.slice(0, 2) + vat.slice(2, 4) + vat.slice(4, 6) + vat.slice(6, 8) + vat.slice(8));
          const expect = Number(vat) % 11 === 0;
          return Boolean(temp % 11 === 0 && expect);
        }

        return false;
      }

      return {
        calcs: function (vat: string) {
          if (isLegalEntities(vat, this.rules)) {
            return true;
          }
          if (isIndividualType2(vat, this.rules)) {
            return true;
          }
          return isIndividualType3(vat, this.rules);
        },
        rules: {
          multipliers: [8, 7, 6, 5, 4, 3, 2],
          lookup: [8, 7, 6, 5, 4, 3, 2, 1, 0, 9, 10],
          regex: [/^(CZ)(\d{8,10})(\d{3})?$/],
          additional: [/^\d{8}$/, /^[0-5][0-9][0|1|5|6]\d[0-3]\d\d{3}$/, /^6\d{8}$/, /^\d{2}[0-3|5-8]\d[0-3]\d\d{4}$/]
        }
      };
    })();
    this._countries.denmark = {
      calcs: function (vat: string) {
        let total = 0;
        for (let i = 0; i < 8; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }
        return total % 11 === 0;
      },
      rules: {
        multipliers: [2, 7, 6, 5, 4, 3, 2, 1],
        regex: [/^(DK)(\d{8})$/]
      }
    };
    this._countries.estonia = {
      calcs: function (vat: string) {
        let total = 0;

        // Extract the next digit and multiply by the counter.
        for (let i = 0; i < 8; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }

        // Establish check digits using modulus 10.
        total = 10 - (total % 10);
        if (total === 10) {
          total = 0;
        }

        // Compare it with the last character of the VAT number. If it's the same, then it's valid.
        const expect = Number(vat.slice(8, 9));
        return total === expect;
      },
      rules: {
        multipliers: [3, 7, 1, 3, 7, 1, 3, 7],
        regex: [/^(EE)(10\d{7})$/]
      }
    };
    this._countries.europe = {
      calcs: () => {
        // We know little about EU numbers apart from the fact that the first 3 digits represent the
        // country, and that there are nine digits in total.
        return true;
      },
      rules: {
        regex: [/^(EU)(\d{9})$/]
      }
    };
    this._countries.finland = {
      calcs: function (vat: string) {
        let total = 0;

        // Extract the next digit and multiply by the counter.
        for (let i = 0; i < 7; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }

        // Establish check digit.
        total = 11 - (total % 11);
        if (total > 9) {
          total = 0;
        }

        // Compare it with the last character of the VAT number. If it's the same, then it's valid.
        const expect = Number(vat.slice(7, 8));
        return total === expect;
      },
      rules: {
        multipliers: [7, 9, 10, 5, 8, 4, 2],
        regex: [/^(FI)(\d{8})$/]
      }
    };
    this._countries.france = {
      calcs: (vat: string) => {
        // Checks the check digits of a French VAT number.
        if (!/^\d{11}$/.test(vat)) {
          return true;
        }

        // Extract the last nine digits as an integer.
        let total = Number(vat.substring(2));

        // Establish check digit.
        total = (total * 100 + 12) % 97;

        // Compare it with the last character of the VAT number. If it's the same, then it's valid.
        const expect = Number(vat.slice(0, 2));
        return total === expect;
      },
      rules: {
        regex: [/^(FR)(\d{11})$/, /^(FR)([A-HJ-NP-Z]\d{10})$/, /^(FR)(\d[A-HJ-NP-Z]\d{9})$/, /^(FR)([A-HJ-NP-Z]{2}\d{9})$/]
      }
    };
    this._countries.germany = {
      calcs: (vat: string) => {
        // Checks the check digits of a German VAT number.
        let product = 10;
        let sum = 0;

        for (let i = 0; i < 8; i++) {
          // Extract the next digit and implement peculiar algorithm!.
          sum = (Number(vat.charAt(i)) + product) % 10;
          if (sum === 0) {
            sum = 10;
          }
          product = (2 * sum) % 11;
        }

        // Establish check digit.
        const toCheck = 11 - product;
        const checkDigit = toCheck === 10 ? 0 : 11 - product;

        // Compare it with the last two characters of the VAT number. If the same, then it is a valid
        // check digit.
        const expect = Number(vat.slice(8, 9));
        return checkDigit === expect;
      },
      rules: {
        regex: [/^(DE)([1-9]\d{8})$/]
      }
    };
    this._countries.greece = {
      calcs: function (vat: string) {
        // eight character numbers should be prefixed with an 0.
        if (vat.length === 8) {
          vat = `0${vat}`;
        }

        let total = 0;

        // Extract the next digit and multiply by the counter.
        for (let i = 0; i < 8; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }

        // Establish check digit.
        total %= 11;
        if (total > 9) {
          total = 0;
        }

        // Compare it with the last character of the VAT number. If it's the same, then it's valid.
        const expect = Number(vat.slice(8, 9));
        return total === expect;
      },
      rules: {
        multipliers: [256, 128, 64, 32, 16, 8, 4, 2],
        regex: [/^(EL)(\d{9})$/]
      }
    };
    this._countries.hungary = {
      calcs: function (vat: string) {
        let total = 0;

        // Extract the next digit and multiply by the counter.
        for (let i = 0; i < 7; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }

        // Establish check digit.
        total = 10 - (total % 10);
        if (total === 10) {
          total = 0;
        }

        // Compare it with the last character of the VAT number. If it's the same, then it's valid.
        const expect = Number(vat.slice(7, 8));
        return total === expect;
      },
      rules: {
        multipliers: [9, 7, 3, 1, 9, 7, 3],
        regex: [/^(HU)(\d{8})$/]
      }
    };
    this._countries.ireland = {
      calcs: function (vat: string) {
        let total = 0;

        // If the code is type 1 format, we need to convert it to the new before performing the validation.
        if (this.rules.typeFormats.first.test(vat)) {
          vat = `0${vat.substring(2, 7)}${vat.substring(0, 1)}${vat.substring(7, 8)}`;
        }

        // Extract the next digit and multiply by the counter.
        for (let i = 0; i < 7; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }

        // If the number is type 3 then we need to include the trailing A or H in the calculation
        if (this.rules.typeFormats.third.test(vat)) {
          // Add in a multiplier for the character A (1*9=9) or H (8*9=72)
          if (vat.charAt(8) === 'H') {
            total += 72;
          } else {
            total += 9;
          }
        }

        // Establish check digit using modulus 23, and translate to char. equivalent.
        total %= 23;
        const totalStr = total === 0 ? 'W' : String.fromCharCode(total + 64);

        // Compare it with the eighth character of the VAT number. If it's the same, then it's valid.
        const expect = vat.slice(7, 8);
        return totalStr === expect;
      },
      rules: {
        multipliers: [8, 7, 6, 5, 4, 3, 2],
        typeFormats: {
          first: /^\d[A-Z*+]/,
          third: /^\d{7}[A-Z][AH]$/
        },
        regex: [/^(IE)(\d{7}[A-W])$/, /^(IE)([7-9][A-Z*+)]\d{5}[A-W])$/, /^(IE)(\d{7}[A-W][AH])$/]
      }
    };
    this._countries.italy = {
      calcs: function (vat: string) {
        // The last three digits are the issuing office, and cannot exceed more 201, unless 999 or 888
        if (Number(vat.slice(0, 7)) === 0) {
          return false;
        }

        let temp = Number(vat.slice(7, 10));
        if (temp < 1 || (temp > 201 && temp !== 999 && temp !== 888)) {
          return false;
        }

        let total = 0;

        // Extract the next digit and multiply by the appropriate
        for (let i = 0; i < 10; i++) {
          temp = Number(vat.charAt(i)) * this.rules.multipliers[i];
          if (temp > 9) {
            total += Math.floor(temp / 10) + (temp % 10);
          } else {
            total += temp;
          }
        }

        // Establish check digit.
        total = 10 - (total % 10);
        if (total > 9) {
          total = 0;
        }

        // Compare it with the last character of the VAT number. If it's the same, then it's valid.
        const expect = Number(vat.slice(10, 11));
        return total === expect;
      },
      rules: {
        multipliers: [1, 2, 1, 2, 1, 2, 1, 2, 1, 2],
        regex: [/^(IT)(\d{11})$/]
      }
    };
    this._countries.latvia = {
      calcs: function (vat: string) {
        // Differentiate between legal entities and natural bodies. For the latter we simply check that
        // the first six digits correspond to valid DDMMYY dates.
        if (/^[0-3]/.test(vat)) {
          return Boolean(/^[0-3][0-9][0-1][0-9]/.test(vat));
        }

        let total = 0;

        // Extract the next digit and multiply by the counter.
        for (let i = 0; i < 10; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }

        // Establish check digits by getting modulus 11.
        if (total % 11 === 4 && Number(vat[0]) === 9) {
          total -= 45;
        }

        if (total % 11 === 4) {
          total = 4 - (total % 11);
        } else if (total % 11 > 4) {
          total = 14 - (total % 11);
        } else if (total % 11 < 4) {
          total = 3 - (total % 11);
        }

        // Compare it with the last character of the VAT number. If it's the same, then it's valid.
        const expect = Number(vat.slice(10, 11));
        return total === expect;
      },
      rules: {
        multipliers: [9, 1, 4, 8, 3, 10, 2, 5, 7, 6],
        regex: [/^(LV)(\d{11})$/]
      }
    };
    this._countries.lithunia = (() => {
      function extractDigitShort(vat: string, multipliers: Array<number>, key: number): number {
        return Number(vat.charAt(key)) * multipliers[key];
      }

      function doubleCheckCalculation(vat: string, total: number, rules): number {
        if (total % 11 === 10) {
          total = 0;
          for (let i = 0; i < 8; i++) {
            total += extractDigitShort(vat, rules.multipliers.short, i);
          }
        }
        return total;
      }

      function extractDigit(vat, total): number {
        for (let i = 0; i < 8; i++) {
          total += Number(vat.charAt(i)) * (i + 1);
        }
        return total;
      }

      function checkDigit(total): number {
        total %= 11;
        if (total === 10) {
          total = 0;
        }
        return total;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function check9DigitVat(vat: string, rules: any): boolean {
        // 9 character VAT numbers are for legal persons
        let total = 0;
        if (vat.length === 9) {
          // 8th character must be one
          if (!/^\d{7}1/.test(vat)) {
            return false;
          }

          // Extract the next digit and multiply by the counter+1.
          total = extractDigit(vat, total);

          // Can have a double check digit calculation!
          total = doubleCheckCalculation(vat, total, rules);

          // Establish check digit.
          total = checkDigit(total);

          // Compare it with the last character of the VAT number. If it's the same, then it's valid.
          const expect = Number(vat.slice(8, 9));
          return total === expect;
        }
        return false;
      }

      function extractDigit12(vat, total, rules): number {
        for (let k = 0; k < 11; k++) {
          total += extractDigitShort(vat, rules.multipliers.med, k);
        }
        return total;
      }

      function doubleCheckCalculation12(vat, total, rules): number {
        if (total % 11 === 10) {
          total = 0;
          for (let l = 0; l < 11; l++) {
            total += extractDigitShort(vat, rules.multipliers.alt, l);
          }
        }

        return total;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function check12DigitVat(vat: string, rules: any): boolean {
        let total = 0;

        // 12 character VAT numbers are for temporarily registered taxpayers
        if (vat.length === 12) {
          // 11th character must be one
          if (!rules.check.test(vat)) {
            return false;
          }

          // Extract the next digit and multiply by the counter+1.
          total = extractDigit12(vat, total, rules);

          // Can have a double check digit calculation!
          total = doubleCheckCalculation12(vat, total, rules);

          // Establish check digit.
          total = checkDigit(total);

          // Compare it with the last character of the VAT number. If it's the same, then it's valid.
          const expect = Number(vat.slice(11, 12));
          return total === expect;
        }

        return false;
      }

      return {
        calcs: function (vat: string) {
          return check9DigitVat(vat, this.rules) || check12DigitVat(vat, this.rules);
        },
        rules: {
          multipliers: {
            short: [3, 4, 5, 6, 7, 8, 9, 1],
            med: [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2],
            alt: [3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4]
          },
          check: /^\d{10}1/,
          regex: [/^(LT)(\d{9}|\d{12})$/]
        }
      };
    })();
    this._countries.luxembourg = {
      calcs: (vat: string) => {
        const expect = Number(vat.slice(6, 8));
        // Checks the check digits of a Luxembourg VAT number.
        const checkDigit = Number(vat.slice(0, 6)) % 89;
        return checkDigit === expect;
      },
      rules: {
        regex: [/^(LU)(\d{8})$/]
      }
    };
    this._countries.malta = {
      calcs: function (vat: string) {
        let total = 0;

        // Extract the next digit and multiply by the counter.
        for (let i = 0; i < 6; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }

        // Establish check digits by getting modulus 37.
        total = 37 - (total % 37);

        // Compare it with the last character of the VAT number. If it's the same, then it's valid.
        const expect = Number(vat.slice(6, 8));
        return total === expect;
      },
      rules: {
        multipliers: [3, 4, 6, 7, 8, 9],
        regex: [/^(MT)([1-9]\d{7})$/]
      }
    };
    this._countries.netherlands = {
      calcs: function (vat: string) {
        let total = 0;

        // Extract the next digit and multiply by the counter.
        for (let i = 0; i < 8; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }

        // Establish check digits by getting modulus 11.
        total %= 11;
        if (total > 9) {
          total = 0;
        }

        // Compare it with the last character of the VAT number. If it's the same, then it's valid.
        const expect = Number(vat.slice(8, 9));
        return total === expect;
      },
      rules: {
        multipliers: [9, 8, 7, 6, 5, 4, 3, 2],
        regex: [/^(NL)(\d{9})B\d{2}$/]
      }
    };
    this._countries.norway = {
      calcs: function (vat: string) {
        let total = 0;
        // See http://www.brreg.no/english/coordination/number.html

        // Extract the next digit and multiply by the counter.
        for (let i = 0; i < 8; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }

        // Establish check digits by getting modulus 11. Check digits > 9 are invalid
        total = 11 - (total % 11);

        if (total === 11) {
          total = 0;
        }

        // Compare it with the last character of the VAT number. If it's the same, then it's valid.
        const expect = Number(vat.slice(8, 9));
        return total === expect;
      },
      rules: {
        multipliers: [3, 2, 7, 6, 5, 4, 3, 2],
        regex: [/^(NO)(\d{9})$/]
      }
    };
    this._countries.poland = {
      calcs: function (vat: string) {
        let total = 0;

        // Extract the next digit and multiply by the counter.
        for (let i = 0; i < 9; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }

        // Establish check digits subtracting modulus 11 from 11.
        total %= 11;
        if (total > 9) {
          total = 0;
        }

        // Compare it with the last character of the VAT number. If it's the same, then it's valid.
        const expect = Number(vat.slice(9, 10));
        return total === expect;
      },
      rules: {
        multipliers: [6, 5, 7, 2, 3, 4, 5, 6, 7],
        regex: [/^(PL)(\d{10})$/]
      }
    };
    this._countries.portugal = {
      calcs: function (vat: string) {
        let total = 0;

        // Extract the next digit and multiply by the counter.
        for (let i = 0; i < 8; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }

        // Establish check digits subtracting modulus 11 from 11.
        total = 11 - (total % 11);
        if (total > 9) {
          total = 0;
        }

        // Compare it with the last character of the VAT number. If it's the same, then it's valid.
        const expect = Number(vat.slice(8, 9));
        return total === expect;
      },
      rules: {
        multipliers: [9, 8, 7, 6, 5, 4, 3, 2],
        regex: [/^(PT)(\d{9})$/]
      }
    };
    this._countries.romania = {
      calcs: function (vat: string) {
        let total = 0;

        // Extract the next digit and multiply by the counter.
        const vatLength = vat.length;
        const multipliers = this.rules.multipliers.slice(10 - vatLength);

        for (let i = 0; i < vat.length - 1; i++) {
          total += Number(vat.charAt(i)) * multipliers[i];
        }

        // Establish check digits by getting modulus 11.
        total = (10 * total) % 11;
        if (total === 10) {
          total = 0;
        }

        // Compare it with the last character of the VAT number. If it's the same, then it's valid.
        const expect = Number(vat.slice(vat.length - 1, vat.length));
        return total === expect;
      },
      rules: {
        multipliers: [7, 5, 3, 2, 1, 7, 5, 3, 2],
        regex: [/^(RO)([1-9]\d{1,9})$/]
      }
    };
    this._countries.russia = (() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function check10DigitINN(vat: string, rules: any): boolean {
        let total = 0;

        if (vat.length === 10) {
          for (let i = 0; i < 10; i++) {
            total += Number(vat.charAt(i)) * rules.multipliers.m1[i];
          }

          total %= 11;
          if (total > 9) {
            total %= 10;
          }

          // Compare it with the last character of the VAT number. If it is the same, then it's valid
          const expect = Number(vat.slice(9, 10));
          return total === expect;
        }

        return false;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function check12DigitINN(vat: string, rules: any): boolean {
        let total1 = 0;
        let total2 = 0;

        if (vat.length === 12) {
          for (let j = 0; j < 11; j++) {
            total1 += Number(vat.charAt(j)) * rules.multipliers.m2[j];
          }

          total1 %= 11;

          if (total1 > 9) {
            total1 %= 10;
          }

          for (let k = 0; k < 11; k++) {
            total2 += Number(vat.charAt(k)) * rules.multipliers.m3[k];
          }

          total2 %= 11;
          if (total2 > 9) {
            total2 %= 10;
          }

          // Compare the first check with the 11th character and the second check with the 12th and last
          // character of the VAT number. If they're both the same, then it's valid
          const expect = total1 === Number(vat.slice(10, 11));
          const expect2 = total2 === Number(vat.slice(11, 12));
          return expect && expect2;
        }

        return false;
      }

      return {
        calcs: function (vat: string) {
          // See http://russianpartner.biz/test_inn.html for algorithm
          return check10DigitINN(vat, this.rules) || check12DigitINN(vat, this.rules);
        },
        rules: {
          multipliers: {
            m1: [2, 4, 10, 3, 5, 9, 4, 6, 8, 0],
            m2: [7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0],
            m3: [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8, 0]
          },
          regex: [/^(RU)(\d{10}|\d{12})$/]
        }
      };
    })();
    this._countries.serbia = {
      calcs: (vat: string) => {
        let product = 10;
        let sum = 0;

        // Checks the check digits of a Serbian VAT number using ISO 7064, MOD 11-10 for check digit.
        for (let i = 0; i < 8; i++) {
          // Extract the next digit and implement the algorithm
          sum = (Number(vat.charAt(i)) + product) % 10;
          if (sum === 0) {
            sum = 10;
          }
          product = (2 * sum) % 11;
        }

        // Now check that we have the right check digit
        const expect = 1;
        const checkDigit = (product + Number(vat.slice(8, 9))) % 10;
        return checkDigit === expect;
      },
      rules: {
        regex: [/^(RS)(\d{9})$/]
      }
    };
    this._countries.slovakiaRepublic = {
      calcs: (vat: string) => {
        const expect = 0;
        const checkDigit = Number(vat) % 11;
        return checkDigit === expect;
      },
      rules: {
        regex: [/^(SK)([1-9]\d[2346-9]\d{7})$/]
      }
    };
    this._countries.slovenia = {
      calcs: function (vat: string) {
        let total = 0;

        // Extract the next digit and multiply by the counter.
        for (let i = 0; i < 7; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }

        // Establish check digits using modulus 11
        total = 11 - (total % 11);
        if (total === 10) {
          total = 0;
        }

        // Compare the number with the last character of the VAT number. If it is the
        // same, then it's a valid check digit.
        const expect = Number(vat.slice(7, 8));
        return Boolean(total !== 11 && total === expect);
      },
      rules: {
        multipliers: [8, 7, 6, 5, 4, 3, 2],
        regex: [/^(SI)([1-9]\d{7})$/]
      }
    };
    this._countries.spain = {
      calcs: function (vat: string) {
        let i = 0;
        let total = 0;
        // National juridical entities
        if (this.rules.additional[0].test(vat)) {
          // Extract the next digit and multiply by the counter.
          for (i = 0; i < 7; i++) {
            const temp = Number(vat.charAt(i + 1)) * this.rules.multipliers[i];
            if (temp > 9) {
              total += Math.floor(temp / 10) + (temp % 10);
            } else {
              total += temp;
            }
          }
          // Now calculate the check digit itself.
          total = 10 - (total % 10);
          if (total === 10) {
            total = 0;
          }

          // Compare it with the last character of the VAT number. If it's the same, then it's valid.
          const expect = Number(vat.slice(8, 9));
          return total === expect;
        }

        // Juridical entities other than national ones
        if (this.rules.additional[1].test(vat)) {
          // Extract the next digit and multiply by the counter.
          for (i = 0; i < 7; i++) {
            const temp = Number(vat.charAt(i + 1)) * this.rules.multipliers[i];
            if (temp > 9) {
              total += Math.floor(temp / 10) + (temp % 10);
            } else {
              total += temp;
            }
          }

          // Now calculate the check digit itself.
          total = 10 - (total % 10);
          const totalStr = String.fromCharCode(total + 64);

          // Compare it with the last character of the VAT number. If it's the same, then it's valid.
          const expect = vat.slice(8, 9);
          return totalStr === expect;
        }

        // Personal number (NIF) (starting with numeric of Y or Z)
        if (this.rules.additional[2].test(vat)) {
          let tempnumber = vat;
          if (tempnumber.startsWith('Y')) {
            tempnumber = tempnumber.replace(/Y/, '1');
          }
          if (tempnumber.startsWith('Z')) {
            tempnumber = tempnumber.replace(/Z/, '2');
          }
          const expect = 'TRWAGMYFPDXBNJZSQVHLCKE'.charAt(Number(tempnumber.substring(0, 8)) % 23);
          return tempnumber.charAt(8) === expect;
        }

        // Personal number (NIF) (starting with K, L, M, or X)
        if (this.rules.additional[3].test(vat)) {
          const expect = 'TRWAGMYFPDXBNJZSQVHLCKE'.charAt(Number(vat.substring(1, 8)) % 23);
          return vat.charAt(8) === expect;
        }
        return false;
      },
      rules: {
        multipliers: [2, 1, 2, 1, 2, 1, 2],
        regex: [/^(ES)([A-Z]\d{8})$/, /^(ES)([A-HN-SW]\d{7}[A-J])$/, /^(ES)([0-9YZ]\d{7}[A-Z])$/, /^(ES)([KLMX]\d{7}[A-Z])$/],
        additional: [/^[A-HJUV]\d{8}$/, /^[A-H|N-SW]\d{7}[A-J]$/, /^[0-9|YZ]\d{7}[A-Z]$/, /^[KLMX]\d{7}[A-Z]$/]
      }
    };
    this._countries.sweden = {
      calcs: (vat: string) => {
        // Calculate R where R = R1 + R3 + R5 + R7 + R9, and Ri = INT(Ci/5) + (Ci*2) modulo 10
        let R = 0;
        let digit;
        for (let i = 0; i < 9; i += 2) {
          digit = Number(vat.charAt(i));
          R += Math.floor(digit / 5) + ((digit * 2) % 10);
        }

        // Calculate S where S = C2 + C4 + C6 + C8
        let S = 0;
        for (let j = 1; j < 9; j += 2) {
          S += Number(vat.charAt(j));
        }

        const checkDigit = (10 - ((R + S) % 10)) % 10;

        // Compare it with the last character of the VAT number. If it's the same, then it's valid.
        const expect = Number(vat.slice(9, 10));

        return checkDigit === expect;
      },
      rules: {
        regex: [/^(SE)(\d{10}01)$/]
      }
    };
    this._countries.switzerland = {
      calcs: function (vat: string) {
        let total = 0;
        for (let i = 0; i < 8; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }

        // Establish check digit.
        total = 11 - (total % 11);
        if (total === 10) {
          return false;
        }
        if (total === 11) {
          total = 0;
        }

        // Check to see if the check digit given is correct, If not, we have an error with the VAT number
        const expect = Number(vat.substring(8, 1));
        return total === expect;
      },
      rules: {
        multipliers: [5, 4, 3, 2, 7, 6, 5, 4],
        regex: [/^(CHE)(\d{9})(MWST)?$/]
      }
    };
    this._countries.unitedKingdom = {
      calcs: function (vat: string) {
        let total = 0;

        // Government departments
        if (vat.startsWith('GD')) {
          const expect = 500;
          return Number(vat.substring(2, 3)) < expect;
        }

        // Health authorities
        if (vat.startsWith('HA')) {
          const expect = 499;
          return Number(vat.substring(2, 3)) > expect;
        }

        // Standard and commercial numbers

        // 0 VAT numbers disallowed!
        if (Number(vat.slice(0)) === 0) {
          return false;
        }

        // Check range is OK for modulus 97 calculation
        const no = Number(vat.slice(0, 7));

        // Extract the next digit and multiply by the counter.
        for (let i = 0; i < 7; i++) {
          total += Number(vat.charAt(i)) * this.rules.multipliers[i];
        }

        // Old numbers use a simple 97 modulus, but new numbers use an adaptation of that (less 55). Our
        // VAT number could use either system, so we check it against both.

        // Establish check digits by subtracting 97 from total until negative.
        let checkDigit = total;
        while (checkDigit > 0) {
          checkDigit -= 97;
        }

        // Get the absolute value and compare it with the last two characters of the VAT number. If the
        // same, then it is a valid traditional check digit. However, even then the number must fit within
        // certain specified ranges.
        checkDigit = Math.abs(checkDigit);
        if (checkDigit === Number(vat.slice(7, 9)) && no < 9990001 && (no < 100000 || no > 999999) && (no < 9490001 || no > 9700000)) {
          return true;
        }

        // Now try the new method by subtracting 55 from the check digit if we can - else add 42
        checkDigit = checkDigit >= 55 ? checkDigit - 55 : checkDigit + 42;
        const expect = Number(vat.slice(7, 9));
        return Boolean(checkDigit === expect && no > 1000000);
      },
      rules: {
        multipliers: [8, 7, 6, 5, 4, 3, 2],
        regex: [/^(GB)?(\d{9})$/, /^(GB)?(\d{12})$/, /^(GB)?(GD\d{3})$/, /^(GB)?(HA\d{3})$/]
      }
    };
  }

  public checkVAT(vat: string): {value: string; isValid: boolean; country: string} {
    const result: {value: string; isValid: boolean; country: string} = {
      value: this._getPureVAT(vat),
      isValid: false,
      country: ''
    };

    if (!vat) {
      return result;
    }

    for (const countryName of Object.keys(this._countries)) {
      result.isValid = this._checkValidity(result.value, countryName);
      if (result.isValid) {
        result.country = countryName;
        return result;
      }
    }
    // Validar números de contribuinte fora da União Europeia
    if (!result.country && this._supportedCountries.indexOf(result.value.substring(0, 2))) {
      result.isValid = true;
    }
    return result;
  }

  private _validateRegex(vat: string, regex: RegExp): boolean {
    return regex.test(vat);
  }

  private _validateRules(vat: string, regex: RegExp, countryName: string): boolean {
    const parsedNum = regex.exec(vat);
    const vatNum = parsedNum[2];
    return this._countries[countryName].calcs(vatNum);
  }

  private _validate(vat: string, regex: RegExp, countryName: string): boolean {
    let result = false;
    if (this._validateRegex(vat, regex)) {
      result = this._validateRules(vat, regex, countryName);
    }
    return result;
  }

  private _getPureVAT(vat: string): string {
    vat = vat || '';
    return vat
      .toString()
      .toUpperCase()
      .replace(/(\s|-|\.)+/g, '');
  }

  private _isCountryBlocked(config, countryName: string): boolean {
    if (!config || config.length === 0) {
      return false;
    }

    return config.indexOf(countryName) === -1;
  }

  private _checkValidity(vat: string, countryName: string): boolean {
    const regexArr = this._countries[countryName].rules.regex;
    for (const element of regexArr) {
      const isValid = this._validate(vat, element, countryName);
      if (isValid) {
        return isValid && !this._isCountryBlocked(this.config, countryName);
      }
    }
    return false;
  }
}
