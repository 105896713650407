import {Component, Injector, OnInit} from '@angular/core';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonContTrabBaseLegal} from '../../jsonContTrabBaseLegal.entity.interface';

@Component({
  selector: 'conttrabbaselegal-edit',
  templateUrl: './contTrabBaseLegal.entity.edit.component.html'
})
export class ContTrabBaseLegalEditComponent extends ModuloEntityDetailComponent<IJsonContTrabBaseLegal> implements OnInit {
  public haveTipoContrato: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.haveTipoContrato = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.model) {
      this.model = {
        contTrabBaseLegal: 0,
        tipoContrato: 0,
        descricao: '',
        duracaoMinima: 0,
        duracaoMaxima: 0,
        duracaoMaxPrimEmpreg: 0,
        dilacaoNovoContrato: 0,
        periodoExpNormalInd: 0,
        periodoExpTecnicoInd: 0,
        periodoExpSuperioInd: 0,
        periodoExpTermoCerto: 0,
        preAvisoDenunNormal: 0,
        preAvisoDenunTecnico: 0,
        preAvisoDenunSuperio: 0,
        preAvisoDenunTermoC: 0,
        numRenovNormal: 0,
        numRenovExcepcionais: 0,
        feriasPorMesCompl: 0,
        feriasMaximo: 0,
        aviDesvincTrab: 0,
        aviCaducidadeTrab: 0,
        aviCaducidadeEmpresa: 0,
        compCaducidadeEmpre: 0,
        passagemSemTermo: 0,
        contTBLNovaSeq: 0
      };
    }
    this.checkTipoContrato();
  }

  public checkTipoContrato(): void {
    this.haveTipoContrato = !(!this.model.tipoContrato || this.model.tipoContrato === 0);
  }
}
