import {Draft, produce} from 'immer';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {CGCToastsConfigService} from '../config/toasts.config.service';
import {ICGCToast, ICGCToastNoticeProperties, ICGCToastProperties} from '../toasts.interface';
import {ICGCToastOptions} from './toasts.service.interface';
import {isArray, isBoolean, isObject, isString} from '../../common/utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class PlAlertService implements OnDestroy {
  private readonly _subjectToasts: BehaviorSubject<ReadonlyArray<ICGCToast>>;
  private readonly _subscriptionDefaultProperties: Subscription;
  private readonly _subscriptionDefaultOptions: Subscription;
  private _defaultProperties: ICGCToastProperties;
  private _defaultOptions: ICGCToastOptions;
  private _observableToasts: Observable<ReadonlyArray<ICGCToast>>;

  constructor(private readonly _toastsConfigService: CGCToastsConfigService) {
    this._subjectToasts = new BehaviorSubject<ReadonlyArray<ICGCToast>>(Object.freeze([]));

    this._subscriptionDefaultProperties = this._toastsConfigService.defaultProperties.subscribe((defaultProperties: ICGCToastProperties) => {
      this._defaultProperties = defaultProperties;
    });

    this._subscriptionDefaultOptions = this._toastsConfigService.defaultOptions.subscribe((defaultOptions: ICGCToastOptions) => {
      this._defaultOptions = defaultOptions;
    });
  }

  public ngOnDestroy(): void {
    this._subjectToasts.complete();
    this._subscriptionDefaultProperties.unsubscribe();
    this._subscriptionDefaultOptions.unsubscribe();
  }

  public toasts(): Observable<ReadonlyArray<ICGCToast>> {
    if (!this._observableToasts) {
      this._observableToasts = this._subjectToasts.asObservable();
    }
    return this._observableToasts;
  }

  public notice<T>(toastProperties: ICGCToastProperties<T>, toastOptions?: ICGCToastOptions): ICGCToast<T> {
    toastProperties = <ICGCToastProperties<T>>(toastProperties ? {...this._defaultProperties, ...toastProperties} : this._defaultProperties);
    toastOptions = toastOptions ? {...this._defaultOptions, ...toastOptions} : this._defaultOptions;

    if (toastOptions.preventDuplicates) {
      for (const toast of this._subjectToasts.value) {
        if (((toast.text && toast.text === toastProperties.text) || (toast.textTemplate && toast.textTemplate === toastProperties.textTemplate)) && toast.type === toastProperties.type) {
          toast.attention.next();
          if (toast.autoClose) {
            toast.queueClose.next();
          }
          return <ICGCToast<T>>toast;
        }
      }
    }

    const toast: ICGCToast<T> = this._generateToast(toastProperties);

    this._subjectToasts.next(Object.freeze([...this._subjectToasts.value, toast]));

    return toast;
  }

  public success<T>(messageOrProperties: string | ICGCToastNoticeProperties<T>, options?: ICGCToastOptions): ICGCToast<T> {
    const properties: ICGCToastNoticeProperties<T> = this._parseMessageOrProperties(messageOrProperties);
    return this.notice({type: 'success', ...properties}, options);
  }

  public error<T>(messageOrProperties: string | ICGCToastNoticeProperties<T>, options?: ICGCToastOptions): ICGCToast<T> {
    const properties: ICGCToastNoticeProperties<T> = this._parseMessageOrProperties(messageOrProperties);
    return this.notice({type: 'error', ...properties}, options);
  }

  public info<T>(messageOrProperties: string | ICGCToastNoticeProperties<T>, options?: ICGCToastOptions): ICGCToast<T> {
    const properties: ICGCToastNoticeProperties<T> = this._parseMessageOrProperties(messageOrProperties);
    return this.notice({type: 'info', ...properties}, options);
  }

  public warning<T>(messageOrProperties: string | ICGCToastNoticeProperties<T>, options?: ICGCToastOptions): ICGCToast<T> {
    const properties: ICGCToastNoticeProperties<T> = this._parseMessageOrProperties(messageOrProperties);
    return this.notice({type: 'warning', ...properties}, options);
  }

  public closeAll(): void {
    if (!isArray(this._subjectToasts.value) && !this._subjectToasts.value.length) {
      return;
    }
    for (const toast of this._subjectToasts.value) {
      toast.close.next();
    }
  }

  private _parseMessageOrProperties<T>(messageOrProperties: string | ICGCToastNoticeProperties<T>): ICGCToastNoticeProperties<T> {
    if (!isObject(messageOrProperties)) {
      if (!isString(messageOrProperties) || !messageOrProperties) {
        throw new Error('Message must be a non-empty string or an object with properties.');
      }
      messageOrProperties = {text: messageOrProperties};
    }
    return <ICGCToastNoticeProperties<T>>messageOrProperties;
  }

  private _generateToast<T>(toastProperties: ICGCToastProperties<T>): ICGCToast<T> {
    const toast: ICGCToast<T> = <ICGCToast<T>>produce(toastProperties, (toastDraft: Draft<ICGCToast<T>>) => {
      if (!isBoolean(toastDraft.autoOpen)) {
        toastDraft.autoOpen = true;
      }

      const subjectIsOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(toastDraft.autoOpen);
      const subjectOpen: Subject<void> = new Subject<void>();
      const subjectClose: Subject<void> = new Subject<void>();
      const subjectQueueClose: Subject<void> = new Subject<void>();
      const subjectCancelClose: Subject<void> = new Subject<void>();
      const subjectAttention: Subject<void> = new Subject<void>();

      toastDraft.open = subjectOpen;
      toastDraft.close = subjectClose;
      toastDraft.queueClose = subjectQueueClose;
      toastDraft.cancelClose = subjectCancelClose;
      toastDraft.attention = subjectAttention;

      toastDraft.isOpen = () => subjectIsOpen.value;

      const subscriptionOpened: Subscription = subjectOpen.subscribe(() => {
        subjectIsOpen.next(true);
      });

      const subscriptionClosed: Subscription = subjectClose.subscribe(() => {
        subjectIsOpen.next(false);

        // Destroy toast if needed
        toast.destroy();

        // Remove toast from list
        const index: number = this._subjectToasts.value.findIndex((toastItem: ICGCToast) => toastItem === toast);
        if (index > -1) {
          this._subjectToasts.next(Object.freeze([...this._subjectToasts.value.slice(0, index), ...this._subjectToasts.value.slice(index + 1)]));
        }
      });

      let destroyed = false;
      toastDraft.destroy = (): void => {
        if (destroyed) {
          return;
        }
        destroyed = true;

        if (subscriptionOpened) {
          subscriptionOpened.unsubscribe();
        }

        if (subscriptionClosed) {
          subscriptionOpened.unsubscribe();
        }

        if (toast.isOpen()) {
          toast.close.next();
        }

        subjectIsOpen.complete();
        subjectOpen.complete();
        subjectClose.complete();
        subjectQueueClose.complete();
        subjectCancelClose.complete();
        subjectAttention.complete();
      };
    });
    return toast;
  }
}
