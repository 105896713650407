<div class="at-faturas-recibos-verdes">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="search" plPromise>
        <pl-group [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <label><span [translate]="'atFRV.formFields.dataDe'"></span>:</label>
            <edit>
              <pl-edit-datepicker [model]="filters.fromDate" (modelChange)="fromDateChanged($event)" plAutoFocus></pl-edit-datepicker>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit-datepicker [(model)]="filters.toDate"></pl-edit-datepicker>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'atFRV.formFields.tipo'"></label>
            <edit>
              <pl-autocomplete [(model)]="filters.tipo" [source]="tiposFatura" rowTemplate="tipo" output="tipo" [allowInvalid]="false" [allowEmpty]="false"></pl-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'atFRV.formFields.situacao'"></label>
            <edit>
              <pl-autocomplete [(model)]="filters.situacao" [source]="situacoesFatura" rowTemplate="situacao" output="situacao" [allowInvalid]="false" [allowEmpty]="false"></pl-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group class="align-self-end">
          <edit>
            <pl-button klass="btn btn-sm btn-primary" type="submit"><i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <br />

  <pl-tabs [activeId]="selectedTab" (evtSelected)="changeTab($event.nextId)">
    <pl-tab id="tabDocsEFatura" caption="atFRV.tabDocsEFaturaCaption">
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="docsEFatura"
          [dataSource]="tableSource"
          (onInitialized)="onInitialized($event)"
          (onRowCollapsed)="onRowCollapsed($event)"
          (onCellClick)="onCellClick($event)"
          (onCellPrepared)="onCellPrepared($event)"
          (onContextMenuPreparing)="onContextMenuPreparing($event)"
          (onSelectionChanged)="onSelectionChanged($event)">
          <div *dxTemplate="let item of 'templateToolbarLancDocSerie'">
            <pl-edit type="boolean" [model]="lancarDocsEmSerie" (modelChange)="lancarDocsEmSerieChange($event)" [properties]="{label: 'atFRV.lancdocserie'}"></pl-edit>
          </div>

          <div *dxTemplate="let item of 'cellTemplateActions'">
            <div class="d-flex align-items-center">
              <pl-tooltip [config]="{text: item.data.erro, placement: 'left', container: 'body'}" *ngIf="item.data.errorText !== ''">
                <i *plTooltipContent class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              </pl-tooltip>

              <div ngbDropdown container="body" class="col-main-table-actions">
                <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-bars"></i></button>
                <div ngbDropdownMenu>
                  <button type="button" ngbDropdownItem [translate]="'atFRV.btn.dropdown.ignorarDocumento'" (click)="ignoreRecibo(item.data)"></button>
                  <button type="button" ngbDropdownItem [translate]="'atFRV.btn.dropdown.ignorarSempreTodosDocumentos'" (click)="ignoreNif(item.data)"></button>
                  <button type="button" ngbDropdownItem [translate]="'atFRV.btn.dropdown.nifConfig'" (click)="openNifConfigModal(item.data)"></button>
                  <button type="button" ngbDropdownItem [translate]="'atFRV.btn.dropdown.gerarNovaCC'" (click)="gerarNovaCC(item.data)" [disabled]="!item.data.podeGenNovaCC"></button>
                  <div class="dropdown-divider"></div>
                  <button type="button" ngbDropdownItem [translate]="'atFRV.btn.dropdown.pdf'" (click)="reciboPDF(item.data)"></button>
                </div>
              </div>
            </div>
          </div>

          <div *dxTemplate="let item of 'cellTemplateMasterDetail'">
            <pl-alert *ngIf="item.data.errorText" [type]="'error'" [closeable]="false">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;<span [innerHTML]="item.data.errorText"></span>
            </pl-alert>

            <pl-toolbar class="mb-2" [instanceId]="item.data.numDocumento + '-toolbar'"></pl-toolbar>

            <div class="card">
              <div class="card-header" [translate]="'atFRV.createDoc'"></div>
              <div class="card-body">
                <doccontabilidade
                  [model]="item.data.docModel"
                  (modelChange)="itemDocModelChanged($event, item.data)"
                  [toolbarInstanceName]="item.data.numDocumento + '-toolbar'"
                  [onLoadPreDefinido]="fnOnLoadPreDefinido(item.data)"
                  plLifeCycle
                  (evtInit)="docContabilidadeInit(item.data, $event)"
                  (evtDestroy)="docContabilidadeDestroy(item.data)"
                  [onAfterInitDocument]="fnDocContabilidadeAfterInitDocument(item.data)"
                  [onLoadFocusField]="item.data.docLoadFocusField"
                  [callback]="item.data.docCallback">
                </doccontabilidade>
              </div>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab id="tabJaLancados" caption="atFRV.tabJaLancadosCaption">
      <div *plTabContent>
        <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="docsEFaturaJaLancados" [dataSource]="tableSourceLancIgnored" (onInitialized)="onInitialized($event)">
          <div *dxTemplate="let item of 'cellTemplateActions'">
            <div class="d-flex align-items-center">
              <pl-tooltip [config]="{text: 'atFRV.tabJaLancadosCaptionContainer.legends.documentoFornecedorIgnorado', placement: 'left', container: 'body'}">
                <a
                  *plTooltipContent
                  rel="noopener noreferrer"
                  [hidden]="item.data.tipoItemJaLancado !== tiposJaLancado.IgnoradoPorNif"
                  class="badge bg-primary"
                  (click)="deixarIgnorarNif(item.data)"
                  [translate]="'atFRV.tabJaLancadosCaptionContainer.table.badges.naoIgnorar'"></a>
              </pl-tooltip>

              <pl-tooltip [config]="{text: 'atFRV.tabJaLancadosCaptionContainer.legends.documentoIgnorado', placement: 'left', container: 'body'}">
                <a
                  *plTooltipContent
                  rel="noopener noreferrer"
                  [hidden]="item.data.tipoItemJaLancado !== tiposJaLancado.Ignorado"
                  class="badge bg-warning"
                  (click)="deixarIgnorarRecibo(item.data)"
                  [translate]="'atFRV.tabJaLancadosCaptionContainer.table.badges.naoIgnorar'"></a>
              </pl-tooltip>
            </div>
          </div>
        </dx-data-grid>
        <ul class="atFRV-legends">
          <li><label class="badge bg-warning">&nbsp;</label><span [translate]="'atFRV.tabJaLancadosCaptionContainer.legends.documentoIgnorado'"></span></li>
          <li><label class="badge bg-primary">&nbsp;</label><span [translate]="'atFRV.tabJaLancadosCaptionContainer.legends.documentoFornecedorIgnorado'"></span></li>
        </ul>
      </div>
    </pl-tab>
  </pl-tabs>

  <cg-panel-multiple-selection-execute
    *ngIf="lancarDocsEmSerie && lancarDocsEmSerieCount > 0"
    [labelTitle]="'components.panelmultipleselectionexecute.labels.selectedDocs'"
    [labelAction]="'components.panelmultipleselectionexecute.actions.lancarDocs'"
    [count]="lancarDocsEmSerieCount"
    [executeFn]="fnDoLancarDocsEmSerie">
  </cg-panel-multiple-selection-execute>
</div>
