import {Type} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ContabilidadeDigitalDocumentosDigitaisClientConnectModuleComponent} from './clientconnect/contabDigital.documentosDigitais.clientConnect.module.component';
import {ContabilidadeDigitalDocumentosDigitaisModuleComponent} from './components/contabDigital.documentosDigitais.module.component';
import {ContabilidadeDigitalService} from '../../../services/contabilidadedigital/contabilidadedigital.service';
import {IJsonBoolean} from '../../../../common/interfaces/json';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {
  MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS,
  MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS_CLIENT_CONNECT,
  MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS_CONCILIACAO
} from './contabDigital.documentosDigitais.module.interface';
import {RESOLVER_CG_STORE_URL_BACKOFFICE} from '../../../../config/uirouter/uirouter.resolvers';
import {ROLE} from '../../../services/role.const';
import {RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL} from '../../../services/contabilidadedigital/contabilidadedigital.router.interface';
import {ContabilidadeDigitalDocumentosDigitaisConcilicaoModuleComponent} from './conciliacaodocs/contabDigital.documentosDigitais.conciliacao.module.component';
import {EGestaoDocumentosDigitalizadosMode} from '../../../components/arquivodigital/common/gestaodocumentosdigitalizados/arquivoDigital.gestaoDocumentosDigitalizados.component.interface';

export const MODULE_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS: IModuleDefinition = generateModule({
  moduleName: MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS,
  url: `/${MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS}`,
  component: ContabilidadeDigitalDocumentosDigitaisModuleComponent,
  roles: [ROLE.CONTABILIDADE],
  clientConnectMode: false,
  mode: EGestaoDocumentosDigitalizadosMode.Contabilidade
});

export const MODULE_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS_CONCILIACAO: IModuleDefinition = generateModule({
  moduleName: MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS_CONCILIACAO,
  url: `/${MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS_CONCILIACAO}`,
  component: ContabilidadeDigitalDocumentosDigitaisConcilicaoModuleComponent,
  roles: [ROLE.CONTABILIDADE],
  clientConnectMode: false,
  mode: EGestaoDocumentosDigitalizadosMode.Conciliacao,
  sidebarTitle: 'portals.sidebar.modules.documentosdigitaisConciliacao'
});

export const MODULE_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS_CLIENT_CONNECT: IModuleDefinition = generateModule({
  moduleName: MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS_CLIENT_CONNECT,
  url: `/${MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS}`,
  component: ContabilidadeDigitalDocumentosDigitaisClientConnectModuleComponent,
  roles: [ROLE.CLIENTCONNECT],
  clientConnectMode: true,
  mode: EGestaoDocumentosDigitalizadosMode.Contabilidade
});

function generateModule({
  moduleName,
  url,
  component,
  roles,
  sidebarTitle,
  clientConnectMode,
  mode
}: {
  moduleName: string;
  url: string;
  component: Type<ContabilidadeDigitalDocumentosDigitaisModuleComponent>;
  roles: Array<ROLE>;
  clientConnectMode: boolean;
  mode: EGestaoDocumentosDigitalizadosMode;
  sidebarTitle?: string;
}): IModuleDefinition {
  return {
    name: moduleName,
    state: {
      url: url,
      component: component,
      data: {
        roles: roles,
        sidebarTitle: sidebarTitle
      },
      params: {
        clientConnectMode: {
          type: 'bool',
          value: clientConnectMode,
          dynamic: true
        },
        mode: {
          type: 'any',
          value: mode,
          dynamic: true
        },
        nif: {
          type: 'string',
          value: ''
        },
        nDocExterno: {
          type: 'string',
          value: ''
        },
        showDocsClassificados: {
          type: 'bool',
          value: false
        }
      },
      resolve: [
        RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL,
        RESOLVER_CG_STORE_URL_BACKOFFICE,
        {provide: 'clientConnectMode', useFactory: () => clientConnectMode},
        {provide: 'mode', useFactory: () => mode},
        {
          provide: 'temDocumentosDigitais',
          deps: ['empresaTemContabilidadeDigital', ContabilidadeDigitalService],
          useFactory: (empresaTemContabilidadeDigital: boolean, contabilidadeDigitalService: ContabilidadeDigitalService): Promise<boolean> => {
            if (empresaTemContabilidadeDigital || clientConnectMode) {
              return Promise.resolve(true);
            }
            return contabilidadeDigitalService.temDocumentosDigitais().then((response: HttpResponse<IJsonBoolean>) => response.body.value);
          }
        }
      ]
    }
  };
}
