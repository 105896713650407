<div class="clifos-gdocs">
  <gdocs-viewer
    [folderId]="folderID"
    [showTreeViewer]="false"
    [treeCollapsed]="false"
    [params]="params"
    [tipoDocEntity]="tipoDocEntity"
    (evtAddingFile)="onGDocsViewerAddingFile($event)"
    [callback]="callbackGDocs">
  </gdocs-viewer>
</div>
