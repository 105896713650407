<div class="faturacaopublica-observacoes-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'faturacaopublica.modal.comunicacaomanual.title'"></h5>
  </div>

  <div class="modal-body" [plPromise]="promiseDocs">
    <h5 [translate]="'faturacaopublica.modal.comunicacaomanual.anexos'"></h5>
    <ng-container *ngIf="comunicacaoManual.fileNameXml">
      <i class="fa fa-fw fa-file"></i>&nbsp;<span>{{ comunicacaoManual.fileNameXml }}</span>
    </ng-container>
    <br />
    <ng-container *ngIf="comunicacaoManual.fileNamePdf">
      <i class="fa fa-fw fa-file"></i>&nbsp;<span>{{ comunicacaoManual.fileNamePdf }}</span>
    </ng-container>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button" plPromise>
    <button [disabled]="btnDisabled" type="button" class="btn btn-primary action-save" (click)="sendMails()">
      <i class="fa fa-fw fa-envelope-o"></i>&nbsp;<span [translate]="'faturacaopublica.modal.comunicacaomanual.btn.sendmail'"></span>
    </button>

    <button [disabled]="btnDisabled" type="button" class="btn btn-success action-save" (click)="exportFiles()">
      <i class="fa fa-fw fa-download"></i>&nbsp;<span [translate]="'faturacaopublica.modal.comunicacaomanual.btn.export'"></span>
    </button>

    <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</div>
