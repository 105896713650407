import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_ESTADOS_VALIDACAO, EEstadoValidacao} from './estadosValidacao.datasource.interface';

export const DATA_SOURCE_ESTADOS_VALIDACAO: IDataSource<EEstadoValidacao> = deepFreeze({
  name: DATA_SOURCE_NAME_ESTADOS_VALIDACAO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{label}}',
    output: 'label'
  },
  data: [
    {value: EEstadoValidacao.NaoPossivel, label: 'estadosValidacao.data.naoPossivel'},
    {value: EEstadoValidacao.CriadoNaoValidado, label: 'estadosValidacao.data.criadoNaoValidado'},
    {value: EEstadoValidacao.CriadoValidado, label: 'estadosValidacao.data.criadoValidado'}
  ]
});
