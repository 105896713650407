<div class="gestao-comunicacoes-message mt-3" [ngClass]="{'flex-row-reverse': mensagemObj.isMyMessage}">
  <div class="message-container" [ngClass]="{'my-message': mensagemObj.isMyMessage, 'other-message': !mensagemObj.isMyMessage}">
    <div [ngSwitch]="mensagemObj.isMessage">
      <ng-container *ngSwitchCase="true">
        <div class="message-text" [innerHtml]="mensagemObj.message"></div>
      </ng-container>

      <ng-container *ngSwitchCase="false">
        <div class="message-file mb-2" [ngClass]="{'my-message': mensagemObj.isMyMessage}" (click)="onClickDownloadFile()">
          <div class="message-file-outer-container">
            <div class="message-file-icon" [ngSwitch]="fileFormated.extension">
              <ng-container *ngSwitchCase="eFileTypes.PDF"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></ng-container>
              <ng-container *ngSwitchCase="eFileTypes.TXT"><i class="fa fa-file-text-o" aria-hidden="true"></i></ng-container>
              <ng-container *ngSwitchCase="eFileTypes.DOC || eFileTypes.DOCX"><i class="fa fa-file-word-o" aria-hidden="true"></i></ng-container>
              <ng-container *ngSwitchCase="eFileTypes.JPG || eFileTypes.JPEG || eFileTypes.PNG"><i class="fa fa-file-image-o" aria-hidden="true"></i></ng-container>
              <ng-container *ngSwitchCase="eFileTypes.XLS || eFileTypes.XLSX || eFileTypes.CSV"><i class="fa fa-file-excel-o" aria-hidden="true"></i></ng-container>
              <ng-container *ngSwitchDefault><i class="fa fa-file-o" aria-hidden="true"></i></ng-container>
            </div>
            <div class="message-file-data-container">
              <div class="message-file-name">{{ fileFormated.name }}</div>
              <div class="message-file-data-information">
                <span> {{ fileFormated.size }}</span>
                <span> {{ fileFormated.extension }}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="message-date">{{ dateShow }}</div>
  </div>
</div>
