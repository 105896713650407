<div class="ativosgeramapasauto entity-detail-form">
  <pl-tabs>
    <pl-tab id="bens" caption="ativosgeramapasauto.tab.bens">
      <div *plTabContent>
        <pl-navpill attrName="navBens" class="navBens" [activeId]="navPillActiveId" (evtSelected)="changedNavPill($event)">
          <pl-navpill-panel *ngFor="let geraMapa of ativosGeraMapas" [id]="geraMapa.mapa" [plPromise]="promise">
            <div *plNavPillPanelTitle [translate]="'ativosgeramapasauto.nav.mapa'">
              {{ geraMapa.mapa }}
            </div>

            <div *plNavPillPanelContent>
              <dx-data-grid
                [cgDxDataGrid]="dataGridDefinition"
                cgDxDataGridInstanceName="ativosGeraMapasAuto"
                [dataSource]="dataGridDefinition.dataSource"
                (onInitialized)="onInitialized($event)"
                (onContentReady)="onContentReady()">
              </dx-data-grid>
            </div>
          </pl-navpill-panel>
        </pl-navpill>
      </div>
    </pl-tab>

    <pl-tab id="mapasArquivados" caption="ativosgeramapasauto.tab.mapasarquivados">
      <div *plTabContent>
        <gdocs-viewer [treeCollapsed]="false" [folderId]="folderId" [callback]="callbackGDocs" [tipoDocEntity]="tipoDocEntity.IMBEMMapas" [errorFolderMessage]="errorFolderMessage"></gdocs-viewer>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
