import {IRadioGroup} from '../../../../common/interfaces/interfaces';
import {deepFreeze} from 'pl-comps-angular';

export enum ERadioTipoDeclaracao {
  Primeira = 1,
  Substituicao
}

export enum ETiposValidar {
  DEFAULT,
  AT
}

export interface IJsonValidarModeloErrors {
  nomeCampo?: string;
  mensagemOriginal: string;
  mensagem?: string;
  tipoMensagemStr?: string;
}

export interface IJsonModeloErrors {
  tipoValidacao: ETiposValidar;
  mensagemTipoHeader?: string;
  erros: Array<IJsonValidarModeloErrors>;
}

export interface ICodigoImpedimento {
  value: number;
  name: string;
}

export interface ICodigoRendimento {
  cod: number;
  rendimentos: Array<string>;
}

export const DATA_CODIGOS_RENDIMENTOS: Array<ICodigoRendimento> = deepFreeze<Array<ICodigoRendimento>>([
  {cod: 1, rendimentos: ['modelo39.codigosRendimentos.01.0101', 'modelo39.codigosRendimentos.01.0102', 'modelo39.codigosRendimentos.01.0103', 'modelo39.codigosRendimentos.01.0104']},
  {cod: 2, rendimentos: ['modelo39.codigosRendimentos.02.0201']},
  {
    cod: 3,
    rendimentos: [
      'modelo39.codigosRendimentos.03.0301',
      'modelo39.codigosRendimentos.03.0302',
      'modelo39.codigosRendimentos.03.0303',
      'modelo39.codigosRendimentos.03.0304',
      'modelo39.codigosRendimentos.03.0305',
      'modelo39.codigosRendimentos.03.0306'
    ]
  },
  {cod: 4, rendimentos: ['modelo39.codigosRendimentos.04.0401', 'modelo39.codigosRendimentos.04.0402']},
  {cod: 5, rendimentos: ['modelo39.codigosRendimentos.05.0501', 'modelo39.codigosRendimentos.05.0502']},
  {cod: 6, rendimentos: ['modelo39.codigosRendimentos.06.0601', 'modelo39.codigosRendimentos.06.0602']},
  {cod: 7, rendimentos: ['modelo39.codigosRendimentos.07.0701']},
  {cod: 8, rendimentos: ['modelo39.codigosRendimentos.08.0801']},
  {cod: 9, rendimentos: ['modelo39.codigosRendimentos.09.0901']},
  {cod: 10, rendimentos: ['modelo39.codigosRendimentos.10.1001']},
  {cod: 11, rendimentos: ['modelo39.codigosRendimentos.11.1101']},
  {cod: 12, rendimentos: ['modelo39.codigosRendimentos.12.1201']},
  {cod: 13, rendimentos: ['modelo39.codigosRendimentos.13.1301']},
  {cod: 14, rendimentos: ['modelo39.codigosRendimentos.14.1401']},
  {cod: 15, rendimentos: ['modelo39.codigosRendimentos.15.1501']},
  {cod: 16, rendimentos: ['modelo39.codigosRendimentos.16.1601']},
  {cod: 17, rendimentos: ['modelo39.codigosRendimentos.17.1701']},
  {cod: 18, rendimentos: ['modelo39.codigosRendimentos.18.1801']},
  {cod: 19, rendimentos: ['modelo39.codigosRendimentos.19.1901']},
  {cod: 20, rendimentos: ['modelo39.codigosRendimentos.20.2001']},
  {cod: 21, rendimentos: ['modelo39.codigosRendimentos.21.2101']},
  {cod: 22, rendimentos: ['modelo39.codigosRendimentos.22.2201']},
  {cod: 23, rendimentos: ['modelo39.codigosRendimentos.23.2301']},
  {cod: 24, rendimentos: ['modelo39.codigosRendimentos.24.2401']},
  {cod: 25, rendimentos: ['modelo39.codigosRendimentos.25.2501']},
  {cod: 26, rendimentos: ['modelo39.codigosRendimentos.26.2601']},
  {cod: 27, rendimentos: ['modelo39.codigosRendimentos.27.2701']},
  {cod: 28, rendimentos: ['modelo39.codigosRendimentos.28.2801']},
  {cod: 29, rendimentos: ['modelo39.codigosRendimentos.29.2901']},
  {cod: 30, rendimentos: ['modelo39.codigosRendimentos.30.3001']},
  {cod: 31, rendimentos: ['modelo39.codigosRendimentos.31.3101']},
  {cod: 32, rendimentos: ['modelo39.codigosRendimentos.32.3201']},
  {cod: 33, rendimentos: ['modelo39.codigosRendimentos.33.3301']},
  {cod: 34, rendimentos: ['modelo39.codigosRendimentos.34.3401']},
  {cod: 35, rendimentos: ['modelo39.codigosRendimentos.35.3501']}
]);

export const RADIO_GROUP_TIPO_DECLARACAO: IRadioGroup<ERadioTipoDeclaracao> = deepFreeze<IRadioGroup<ERadioTipoDeclaracao>>({
  groupItems: [
    {value: ERadioTipoDeclaracao.Primeira, label: 'modelo39.groups.q5.radioTipoDeclaracao.primeira'},
    {value: ERadioTipoDeclaracao.Substituicao, label: 'modelo39.groups.q5.radioTipoDeclaracao.sub'}
  ]
});

export const MODULE_NAME_MODELO_39 = 'modelo39';
