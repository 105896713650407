<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'configEfaturaDocPorLanc.supplier.title'"></h4>
</div>

<div class="modal-body">
  <config-docs-efatura-supplier [nContribuinte]="nContribuinte" [nomeFornecedor]="nomeFornecedor" (evtLastFieldKeydown)="onConfigDocsEFaturaSupplierKeydown($event)"></config-docs-efatura-supplier>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary btn-sm" [onClick]="close" #elementBtnGravar><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

  <pl-button klass="btn-light btn-sm" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
