import {Component, Input, OnInit} from '@angular/core';
import {IJsonGestaoComunicacoesMensagem} from '../../../modules/portalcontabilidade/gestaocomunicacoes/jsonGestaoComunicacoesMensagem.module.interface';
import {downloadStream} from 'pl-comps-angular';
import {GestaoComunicacoesTopicoService} from '../../../modules/portalcontabilidade/gestaocomunicacoes/gestaoComunicacoesTopico.module.service';
import {HttpResponse} from '@angular/common/http';
import moment from 'moment';
import {convertFileNameToFileData, EGestaoComunicacoesFileType, IGestaoComunicacoesFileData} from '../../../modules/portalcontabilidade/gestaocomunicacoes/gestaoComunicacoes.module.interface';

const TIME_FORMAT = 'HH:mm';

@Component({
  selector: 'cg-message-topico',
  templateUrl: './messageTopico.component.html'
})
export class CGMessageTopicoComponent implements OnInit {
  @Input() public mensagemObj: IJsonGestaoComunicacoesMensagem;

  public readonly eFileTypes: typeof EGestaoComunicacoesFileType;

  public dateShow: string;
  public fileFormated: IGestaoComunicacoesFileData;

  constructor(private readonly _gestaoComunicacoesTopicoService: GestaoComunicacoesTopicoService) {
    this.eFileTypes = EGestaoComunicacoesFileType;
  }

  public ngOnInit(): void {
    this.dateShow = moment(this.mensagemObj.stampCreated).format(TIME_FORMAT);
    if (!this.mensagemObj.isMessage) {
      this.fileFormated = convertFileNameToFileData(this.mensagemObj.fileName, this.mensagemObj.tamanho, this.mensagemObj.mimeType);
    } else {
      this.mensagemObj.message = this.mensagemObj.message.replace(/\n/g, '<br>');
    }
  }

  public async onClickDownloadFile(): Promise<void> {
    await this._gestaoComunicacoesTopicoService.downloadFile(this.mensagemObj.comTopicoId, this.mensagemObj.docId).then((response: HttpResponse<Blob>) => {
      downloadStream(response, this.mensagemObj.fileName);
    });
  }
}
