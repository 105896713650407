<div class="arclis">
  <entity-detail
    entityName="arclis"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL}">
        <pl-group [properties]="{readonly: type !== stateTypes.NEW}">
          <label [translate]="'arclis.fields.nArtigo'"></label>
          <edit>
            <entity-autocomplete
              entity="artigos"
              attrName="nArtigo"
              [model]="model"
              [(selectedKey)]="model.nArtigo"
              [properties]="{validators: {required: {value: true}, maxlength: {value: 25}}}"
              output="key"
              plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group [properties]="{readonly: type !== stateTypes.NEW || nContaReadonlyOnNew}">
          <label [translate]="'arclis.fields.nConta'"></label>
          <edit>
            <entity-autocomplete
              entity="clifos"
              attrName="nConta"
              [model]="model"
              (selectedKeyChange)="model.nConta = $event"
              (evtSelectedDescriptionChanged)="model.nomeConta = $event"
              [properties]="{validators: {required: {value: true}, maxlength: {value: 75}}}"
              [fieldsMap]="{nome: 'nomeConta'}"
              [output]="clifosOutput">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group [properties]="{readonly: type !== stateTypes.NEW}">
          <label [translate]="'arclis.fields.nClifoArtigo'"></label>
          <edit>
            <pl-edit-text attrName="nClifoArtigo" [(model)]="model.nClifoArtigo" [properties]="{validators: {required: {value: true}, maxlength: {value: 25}}}"></pl-edit-text>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'arclis.fields.descricaoFornecedor'"></label>
          <edit>
            <pl-edit-text
              attrName="descricaoFornecedor"
              [(model)]="model.descricaoFornecedor"
              [properties]="{validators: {required: {value: true}, maxlength: {value: 100}}}"
              plAutoFocus
              [plAutoFocusDisabled]="type === stateTypes.NEW"></pl-edit-text>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
