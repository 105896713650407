import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {KEYCODES} from 'pl-comps-angular';

@Component({
  selector: 'modal-demfinsnc-obs',
  templateUrl: './demFinSNC.obs.modal.component.html'
})
export class DemFinSNCObsModalComponent extends CGModalComponent<string> implements OnInit {
  @Input() public observacoes: string;
  public obs: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.obs = '';
  }

  public ngOnInit(): void {
    this.obs = this.observacoes;
  }

  public close(): void {
    super.close(this.obs);
  }

  public keyDownObs(value: string, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      const button: HTMLElement = document.querySelector('button[name="ok"]');
      event.stopPropagation();
      if (button) {
        setTimeout(() => {
          button.focus();
        });
      }
    }
  }
}
