import {interval, Observable} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';
import moment from 'moment';
import {JSONSchema} from '@ngx-pwa/local-storage';
import {deepFreeze} from 'pl-comps-angular';
import {isProduction} from '../../../config/constants';
import {SCHEMA_STRING, SCHEMA_STRING_ARRAY} from '../../../common/schemas';
import {TDate} from '../../../common/dates';

export enum ENotificationType {
  All,
  News,
  Maintenance
}

export enum ENotificationSourceId {
  RSS
}

export type TNotifications = ReadonlyArray<INotification>;

export interface INotification {
  readonly id: string;
  readonly sourceId: ENotificationSourceId;
  readonly type: ENotificationType;
  readonly title: string;
  readonly description: string;
  readonly content: string;
  readonly icon: string;
  readonly visited: boolean;
  readonly read: boolean;
  readonly date: TDate;
  readonly href?: string;
}

export interface INotificationsStates {
  readonly lastFetchTimestamp: TDate;
  readonly visited: Array<string>;
  readonly read: Array<string>;
}

export interface INotificationsUI {
  notificationsNew: ReadonlyArray<INotification>;
  notificationsOld: ReadonlyArray<INotification>;
}

export const NOTIFICATION_CENTER_ENABLED = isProduction();

export const SCHEMA_NOTIFICATIONS_STATES: JSONSchema = deepFreeze({
  type: 'object',
  properties: {
    lastFetchTimestamp: SCHEMA_STRING,
    visited: SCHEMA_STRING_ARRAY,
    read: SCHEMA_STRING_ARRAY
  }
});

export function sortNotifications(notifications: Array<INotification> | TNotifications): Array<INotification> {
  return notifications.slice().sort((a: INotification, b: INotification) => moment(b.date).valueOf() - moment(a.date).valueOf()); // sort notifications by descending date
}

export function notificationsToMap(notifications: Array<INotification> | TNotifications): Map<string, INotification> {
  return new Map<string, INotification>(
    notifications.slice().map<[string, INotification]>((notification: INotification) => {
      return [notification.id, notification];
    })
  );
}

export function notificationsTrackBy(index: number, notification: INotification): string {
  return notification.id;
}

const MARK_AS_VISITED_DELAY = 1000;

export function notificationMarkAsVisitedDelay(closed: Observable<void>, delay: number = MARK_AS_VISITED_DELAY): Observable<void> {
  return interval(delay)
    .pipe(takeUntil(closed))
    .pipe(take(1))
    .pipe(map<number, undefined>(() => undefined));
}
