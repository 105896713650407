export enum ETiposIVA {
  Isenta,
  Reduzida,
  Normal,
  Intermedia,
  NaoDeduz,
  Gasoleo50,
  IsentoCEE,
  IsentoForaCEE
}

export const DATA_SOURCE_NAME_TIPOS_IVA = 'tiposIVA';
