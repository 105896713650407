import {Component, Injector, Input} from '@angular/core';
import {PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'abdes-entity-edit-unlock-prompt-modal-component',
  templateUrl: './abdes.entity.edit.unlockPrompt.modal.component.html'
})
export class AbdesEntityEditUnlockPromptModalComponent extends CGModalComponent<boolean> {
  @Input() public abono: boolean;
  @Input() public perfilSistemaAbdesNome: string;

  public readonly titleAbonosDescontos: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this.titleAbonosDescontos = this.abono ? this._plTranslateService.translate('abdes.abonos').toLowerCase() : this._plTranslateService.translate('abdes.descontos').toLowerCase();
  }

  public close(): void {
    super.close(true);
  }
}
