import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {MODULE_NAME_ANALITICA_CONFIG} from './analiticaConfig.module.interface';
import {AnaliticaConfigService} from './analiticaConfig.module.service';
import {AnaliticaConfigModuleComponent} from './components/analiticaConfig.module.component';

export const MODULE_ANALITICA_CONFIG: IModuleDefinition = {
  name: MODULE_NAME_ANALITICA_CONFIG,
  state: {
    url: `/${MODULE_NAME_ANALITICA_CONFIG}`,
    component: AnaliticaConfigModuleComponent,
    data: {
      roles: [ROLE.ADMIN, ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.analiticaconfig'
    },
    resolve: [
      {
        provide: 'model',
        deps: [AnaliticaConfigService],
        useFactory: (analiticaConfigService: AnaliticaConfigService) => analiticaConfigService.getAnaliticaConfig()
      }
    ]
  }
};
