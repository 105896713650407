import {Injectable} from '@angular/core';
import {ApiService} from '../../../../services/api/api.service';
import {IApuramento} from './contabilidade.apuramentoResultados.module.interface';
import {IJsonDocContabilidadeCab} from '../../docscontabilidade/jsonDocContabilidade.interface';
import {IJsonApuramento, IJsonDocsContasInventario, IJsonSaldosContasInventario, IJsonVariaveisApuramento} from './jsonApuramentoResultados.module.interface';
import {ETipoInventarioApuramento} from '../../../../datasources/tipoinventario/tipoInventario.datasource.interface';
import {IJsonString} from '../../../../../common/interfaces/json';
import {TServiceResponse} from '../../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ContabilidadeApuramentoResultadosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/apuramentoresultados`;
  }

  public variaveisApuramento(tipoInventario: ETipoInventarioApuramento): TServiceResponse<Array<IJsonVariaveisApuramento>> {
    return this._apiService.get<Array<IJsonVariaveisApuramento>>({url: `${this._path}/variaveisapuramento`, params: {tipoinventario: tipoInventario}});
  }

  public valoresApuramento(apuramento: IApuramento, variaveisapuramento: Array<IJsonVariaveisApuramento>): TServiceResponse<Array<IJsonDocContabilidadeCab>> {
    return this._apiService.post<Array<IJsonDocContabilidadeCab>, Array<IJsonVariaveisApuramento>>({
      url: `${this._path}/valoresapuramento`,
      params: apuramento,
      body: variaveisapuramento
    });
  }

  public jaEfectuouApuramentoNoAno(): TServiceResponse<boolean> {
    return this._apiService.get<boolean>({url: `${this._path}/apurado`});
  }

  public loadPeriodos(ano: number): TServiceResponse<Array<IJsonString>> {
    return this._apiService.get<Array<IJsonString>>({url: `${this._path}/listaperiodos`, params: {ano: ano}});
  }

  public getDefaultDocsApuramentoGerar(): TServiceResponse<IJsonApuramento> {
    return this._apiService.get<IJsonApuramento>({url: `${this._path}/docsapuramentogerar`});
  }

  public getLoadInformacaoSaldosApuramento(periodo: string): TServiceResponse<IJsonSaldosContasInventario> {
    return this._apiService.get<IJsonSaldosContasInventario>({url: `${this._path}/informacaosaldosapuramento`, params: {periodo: periodo}});
  }

  public getLoadDocsContasInventario(): TServiceResponse<Array<IJsonDocsContasInventario>> {
    return this._apiService.get<Array<IJsonDocsContasInventario>>({url: `${this._path}/docscontasinventario`});
  }

  public getLoadDocsSaldosErrados(): TServiceResponse<Array<IJsonDocsContasInventario>> {
    return this._apiService.get<Array<IJsonDocsContasInventario>>({url: `${this._path}/docssaldoserrados`});
  }

  public novoApuramento(apuramento: IApuramento, variaveisapuramento: Array<IJsonVariaveisApuramento>): TServiceResponse<Array<IJsonDocContabilidadeCab>> {
    return this._apiService.post<Array<IJsonDocContabilidadeCab>, Array<IJsonVariaveisApuramento>>({
      url: this._path,
      params: apuramento,
      body: variaveisapuramento
    });
  }

  public delete(): TServiceResponse<void> {
    return this._apiService.delete({url: this._path});
  }
}
