<pl-autocomplete
  [attrName]="attrName"
  [model]="value"
  [allowInvalid]="false"
  [allowEmpty]="options.allowEmpty"
  [filterFields]="options.filterFields"
  [showFilter]="false"
  [inputClass]="inputClass"
  [output]="options.output"
  [placeholder]="options.placeholder"
  [rowTemplate]="options.rowTemplate"
  [source]="fnGetData"
  [validateFn]="options.validateFn"
  [properties]="{
    disabled: options.disabled,
    disallowInput: options.disallowInput,
    events: options.events,
    onSelect: fnSelect,
    raw: options.raw,
    readonly: options.readonly,
    validate: options.validate,
    validators: options.validators
  }">
</pl-autocomplete>
