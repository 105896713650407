import {merge} from 'lodash-es';
import {Subject} from 'rxjs';
import {AfterViewInit, Directive, EventEmitter, HostBinding, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {FormControl, FormGroupDirective} from '@angular/forms';
import {generateUniqueID, isBoolean, isEmpty, isEmptyObject, isFunction, isObject} from '../../common/utilities/utilities';
import {IPlEditBaseComponent, IPlEditBaseComponentModelOptions, IPlEditBaseComponentOptions} from '../component/edit.component.interface';
import {Logger} from '../../logger/logger';
import {PlEditGroupToken} from '../components/group/edit.group.token';
import {PlEditToken} from '../component/edit.token';
import {TNgClassSupportedTypes} from '../../common/angular.interface';
import {TValueOrPromise} from '../../common/utilities/utilities.interface';

@Directive()
export abstract class PlEditBaseComponent<T, S extends IPlEditBaseComponentOptions<T> = IPlEditBaseComponentOptions<T>>
  implements OnInit, OnChanges, OnDestroy, AfterViewInit, IPlEditBaseComponent<T>
{
  @Input() public model: T;
  @Input() public modelOptions: IPlEditBaseComponentModelOptions;
  @Input() public attrName: string;
  @Input() public inputClass: TNgClassSupportedTypes;
  @Input() public properties: S;
  @Output() public readonly modelChange: EventEmitter<T>;

  public ngFormInstance: FormGroupDirective;
  public value: T;
  public options: S;
  public validators: S['validators'];
  public validate: boolean;

  protected readonly _destroyed: Subject<void>;
  protected readonly _logger: Logger;
  protected readonly _plGroup: PlEditGroupToken;
  protected readonly _plEdit: PlEditToken<T>;
  protected readonly _document: Document;
  protected readonly _window: Window;
  protected _defaultOptions: S;

  private _hasValidators: boolean;

  protected constructor(protected readonly _injector: Injector) {
    this.modelChange = new EventEmitter<T>();
    this.validate = false;
    this._destroyed = new Subject<void>();
    this._logger = this._injector.get<Logger>(Logger);
    this._plGroup = this._injector.get<PlEditGroupToken>(PlEditGroupToken, null, {optional: true});
    this._plEdit = this._injector.get<PlEditToken<T>>(PlEditToken, null, {optional: true});
    this._document = this._injector.get<Document>(DOCUMENT, null, {optional: true}) ?? undefined;
    this._defaultOptions = <S>Object.freeze<IPlEditBaseComponentOptions<T>>({
      placeholder: undefined,
      visible: true,
      disabled: false,
      readonly: false,
      validate: true,
      validators: {},
      events: {},
      modelOptions: {
        updateOn: 'change',
        debounce: 0
      }
    });
    this._hasValidators = false;
    if (this._document) {
      this._window = this._document.defaultView;
    }
  }

  public ngOnInit(): void {
    if (isEmpty(this.attrName)) {
      this.attrName = generateUniqueID('plEditBase');
    }
    const initialOptions: Partial<IPlEditBaseComponentOptions<T>> = {};
    if (this._plEdit) {
      this._plEdit.addComponent(this);
      if (this._plEdit.options) {
        merge(initialOptions, this._plEdit.options);
      }
      this.ngFormInstance = this._plEdit.ngFormInstance;
    } else if (this._plGroup) {
      this._plGroup.addEdit(this);
      if (this._plGroup.options) {
        merge(initialOptions, this._plGroup.options);
      }
      if (!this.ngFormInstance) {
        this.ngFormInstance = this._plGroup.ngFormInstance;
      }
    }
    this.options = merge({}, this._defaultOptions, initialOptions, this.options, this.properties);
    this.validators = this.options.validators;
    this._hasValidators = !isEmptyObject(this.validators);
    this.value = this.model;
  }

  public ngOnChanges({model, properties}: SimpleChanges): void {
    if (model && !model.isFirstChange()) {
      this.updateValue(model.currentValue);
    }
    if (properties && !properties.isFirstChange()) {
      this.updateComponent(properties.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
    if (this._plEdit) {
      this._plEdit.removeComponent();
    } else if (this._plGroup) {
      this._plGroup.removeEdit(this.attrName);
    }
  }

  public ngAfterViewInit(): void {
    if (!this.ngFormInstance) {
      if (this._plEdit?.ngFormInstance) {
        this.ngFormInstance = this._plEdit.ngFormInstance;
      } else if (this._plGroup?.ngFormInstance) {
        this.ngFormInstance = this._plGroup.ngFormInstance;
      }
    }
  }

  public updateComponent(properties: S): void {
    if (properties && properties !== this.options) {
      this.options = merge({}, this._defaultOptions, this.options, properties, this.properties);
      this.validators = this.options.validators;
      this._hasValidators = !isEmptyObject(this.validators);
    }
  }

  public updateValue(value: T): void {
    this.value = value;
  }

  public render(value: T = this.value): Promise<void> {
    if (value === null) {
      value = undefined;
    }
    let result: TValueOrPromise<void | boolean>;
    if (this.options.events && isFunction(this.options.events.beforeChange)) {
      result = this.options.events.beforeChange(value, this.model);
    }
    return Promise.resolve(result).then((prevent: boolean) => {
      if (prevent === false) {
        return;
      }
      this.model = value;
      this.modelChange.emit(this.model);
      if (this.options.events && isFunction(this.options.events.change)) {
        this.options.events.change(this.model);
      }
      if (!this._plEdit && this._plGroup) {
        this._plGroup.valueChanged(value, this.attrName);
      }
    });
  }

  @HostBinding('attr.data-attr-name')
  public get dataAttrName(): string {
    return this.attrName;
  }

  public get hasValidators(): boolean {
    return this._hasValidators;
  }

  public get formControl(): FormControl<T> {
    return undefined;
  }

  protected _isOptionBoolean(value: string): boolean {
    return isObject(this.options) && isBoolean(this.options[value]);
  }
}
