export interface IReciboImprimirModalParams {
  readonly extPocCabID: string;
  readonly nConta: string;
  readonly nomeConta: string;
  readonly email?: string;
}

export const RECIBO1_ESCOLHA_MOEDA_NAME = 'Recibo1EscolhaMoeda.FR3';

export const RECIBO1_NAME = 'Recibo1.FR3';
