<div class="modal-header">
  <button type="button" (click)="dismiss()" class="btn-close"></button>
  <h4 class="modal-title" [translate]="'configsErp.items.portalColaborador.conflitos.criarConflito'"></h4>
</div>

<div class="modal-body">
  <pl-group>
    <label><span [translate]="'configsErp.items.portalColaborador.conflitos.colaborador1'"></span>:</label>
    <edit>
      <entity-autocomplete
        entity="dgemps"
        [selectedKey]="model.codEmp"
        [properties]="{validators: {required: {value: true}}}"
        (selectedKeyChange)="model.codEmp = $event; colaborador1Changed()"
        output="key"></entity-autocomplete>
    </edit>
  </pl-group>

  <ng-container *ngIf="model.codEmp && model.codEmp > 0">
    <pl-multiselect [model]="dgempsSelecionados" [source]="dgempsDisponiveis" key="codEmp" [template]="templateMultiSelectDgEmp"></pl-multiselect>
  </ng-container>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button type="button" klass="btn-primary btn-sm action-save" [onClick]="fnSave"><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
  <pl-button klass="btn-light btn-sm action-close" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
