import {PlTranslateService} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_FLUXO_MONETARIO} from '../../datasources/fluxomonetario/fluxoMonetario.datasource.interface';
import {DATA_SOURCE_NAME_GRUPO_DOC, EGrupoDoc, IGrupoDoc} from '../../datasources/grupodoc/grupoDoc.datasource.interface';
import {DocfasEditComponent} from './components/edit/docFas.entity.edit.component';
import {DocfasListComponent} from './components/list/docFas.entity.list.component';
import {EDocFasReportLoadType, IJsonDocfa, IJsonDocfaNewFromBase} from './jsonDocFa.entity.interface';
import {ENTITY_NAME_DOC_FAS, IDocFasEntity} from './docFas.entity.interface';
import {GrupoDocService} from '../../services/grupodoc/grupo.doc.service';
import {IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {ROLE} from '../../services/role.const';
import {TInjectorResolvable} from '../../../common/injectors/common';

export const ENTITY_DOC_FAS: TInjectorResolvable = [
  PlTranslateService,
  GrupoDocService,
  (plTranslateService: PlTranslateService, grupoDocService: GrupoDocService): IDocFasEntity => {
    return {
      name: ENTITY_NAME_DOC_FAS,
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.docfas',
      searchPlaceholder: 'docfas.pesquisa',
      metadata: {
        keyName: 'nDocFa',
        fields: [
          {name: 'nDocFa', width: '60px', caption: 'docfas.fields.nDocFa'},
          {name: 'nome', readonly: true, disabled: true, caption: 'docfas.fields.nome'},
          {name: 'descricao', readonly: true, disabled: true, caption: 'docfas.fields.descricao'},
          {
            name: 'codigoSAFTTipoDoc',
            readonly: true,
            disabled: true,
            visible: false,
            caption: 'docfas.fields.codigoSAFTTipoDoc',
            properties: {devExpress: {dataGrid: {allowFiltering: false, allowSearch: false}}}
          },
          {
            name: 'descricaoSAFTTipoDoc',
            readonly: true,
            disabled: true,
            visible: false,
            caption: 'docfas.fields.descricaoSAFTTipoDoc',
            properties: {devExpress: {dataGrid: {allowFiltering: false, allowSearch: false}}}
          },
          {
            name: 'codigoSAFTTipoDocDescStr',
            readonly: true,
            disabled: true,
            caption: 'docfas.fields.codigoSAFTTipoDocDescStr',
            properties: {devExpress: {dataGrid: {allowFiltering: false, allowSearch: false}}}
          },
          {name: 'identificaCarga', type: 'boolean', caption: 'docfas.fields.identificaCarga', properties: {devExpress: {dataGrid: {allowHeaderFiltering: false}}}},
          {name: 'faturaComIvaIncluido', type: 'boolean', caption: 'docfas.fields.faturaComIvaIncluido', properties: {devExpress: {dataGrid: {allowHeaderFiltering: false}}}},
          {name: 'integraStocks', type: 'boolean', caption: 'docfas.fields.integraStocks', properties: {devExpress: {dataGrid: {allowHeaderFiltering: false}}}},
          {name: 'integraNaContabilidade', type: 'boolean', caption: 'docfas.fields.integraNaContabilidade', properties: {devExpress: {dataGrid: {allowHeaderFiltering: false}}}},
          {name: 'integraEmContasCorrentes', type: 'boolean', visible: false, caption: 'docfas.fields.integraEmContasCorrentes', properties: {devExpress: {dataGrid: {allowHeaderFiltering: false}}}},
          {name: 'tipoMovimentoStock', type: 'boolean', caption: 'docfas.fields.tipoMovimentoStock'},
          {name: 'reportDefeito', readonly: true, disabled: true, caption: 'docfas.fields.reportDefeito', properties: {devExpress: {dataGrid: {allowFiltering: false, allowSearch: false}}}},
          {
            name: 'grupoDocfa',
            type: DATA_SOURCE_NAME_GRUPO_DOC,
            caption: 'docfas.fields.grupoDocfa',
            properties: {
              devExpress: {
                dataGrid: {
                  customizeText: (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => {
                    if (cellInfo.target === 'row') {
                      const grupoDoc: IGrupoDoc = grupoDocService.getGrupoDoc(<EGrupoDoc>cellInfo.value);
                      return plTranslateService.translate(grupoDoc.nome);
                    }
                    return cellInfo.valueText;
                  }
                }
              }
            }
          },
          {name: 'fluxoMonetario', type: DATA_SOURCE_NAME_FLUXO_MONETARIO, caption: 'docfas.fields.fluxoMonetario'},
          {name: 'nDocfaDestino', caption: 'docfas.fields.nDocfaDestino'},
          {name: 'obrigatorioMeiosPagam', type: 'boolean', caption: 'docfas.fields.obrigatorioMeiosPagam'}
        ],
        order: 'nDocFa',
        detailFields: 'nDocFa,nome,descricao,codigoSAFTTipoDocDescStr,reportDefeito,identificaCarga,faturaComIvaIncluido,integraStocks,integraNaContabilidade',
        listFields: 'nDocFa,nome,descricao,codigoSAFTTipoDocDescStr,grupoDocfa,reportDefeito,identificaCarga,faturaComIvaIncluido,integraStocks,integraNaContabilidade',
        editFields: 'nDocFa,nome,descricao,codigoSAFTTipoDocDescStr,reportDefeito,identificaCarga,faturaComIvaIncluido,integraStocks,integraNaContabilidade'
      },
      autocomplete: {
        rowTemplate: '{{nDocFa}} - {{nome}} ({{descricao}})',
        output: 'nome',
        searchFields: 'nDocFa,nome,descricao'
      },
      actions: {
        new: true,
        edit: true,
        detail: true,
        delete: false, // não mudar, não tem endpoint na RestApi (questões técnicas relacionadas com a integração na contabilidade)
        search: true,
        filter: true,
        duplicate: true
      },
      list: {
        state: {
          component: DocfasListComponent
        }
      },
      detail: {
        state: {
          component: DocfasEditComponent,
          data: {
            getRequestConfig: {params: {reportloadtype: EDocFasReportLoadType.RptDBAndFixed}}
          }
        }
      },
      service: function () {
        this.reports = (id: number) => this.get({id: `${id}/reports`});

        this.series = (id: number) => this.get({id: `${id}/series`});

        this.duplicateDocfa = (data: IJsonDocfaNewFromBase) => {
          return this.post<IJsonDocfa, IJsonDocfaNewFromBase>({
            url: 'createnewfrombase',
            body: data
          });
        };
      }
    };
  }
];
