<div class="modelo22-anexog-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo22.modal.anexog.titles.' + newLineFor"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <pl-group *ngIf="isQuadro3">
        <label [translate]="'modelo22.modal.anexog.periodo'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="periodo" [(model)]="periodo" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="isQuadro3">
        <pl-group>
          <label [translate]="'modelo22.modal.anexog.montanteDedutiveis'"></label>
          <edit>
            <pl-edit type="number" attrName="montanteDedutiveis" [(model)]="montanteDedutiveis"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'modelo22.modal.anexog.montanteDeduzidos'"></label>
          <edit>
            <pl-edit type="number" attrName="montanteDeduzidos" [(model)]="montanteDeduzidos"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="isQuadro3">
        <label [translate]="'modelo22.modal.anexog.saldo'"></label>
        <edit>
          <pl-edit type="number" attrName="saldo" [(model)]="saldo"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="isQuadro4">
        <label [translate]="'modelo22.modal.anexog.nIdentificacao'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="nIdentificacao" [(model)]="nIdentificacao" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="isQuadro4">
        <pl-group>
          <label [translate]="'modelo22.modal.anexog.paisDeRegisto'"></label>
          <edit>
            <pl-autocomplete [source]="dsPaisRegisto" [model]="paisDeRegistoView" (evtSelected)="evtPaisRegistoChanged($event)" [allowInvalid]="true" [rowTemplate]="dsTemplate" [output]="dsTemplate">
            </pl-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexog.regime'"></label>
          <edit>
            <pl-autocomplete [source]="dsRegime" [model]="regimeView" (evtSelected)="evtRegimeChanged($event)" [allowInvalid]="true" [rowTemplate]="dsTemplate" [output]="dsTemplate">
            </pl-autocomplete>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="isQuadro4">
        <label [translate]="'modelo22.modal.anexog.paisDeGestao'"></label>
        <edit>
          <pl-autocomplete [source]="dsPaisGestao" [model]="paisDeGestaoView" (evtSelected)="evtPaisGestaoChanged($event)" [allowInvalid]="true" [rowTemplate]="dsTemplate" [output]="dsTemplate">
          </pl-autocomplete>
        </edit>
      </pl-group>

      <pl-group *ngIf="isQuadro4">
        <pl-group>
          <label [translate]="'modelo22.modal.anexog.percTripulantes'"></label>
          <edit>
            <pl-edit type="number" attrName="percTripulantes" [(model)]="percTripulantes"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexog.arqueacao'"></label>
          <edit>
            <pl-edit type="number" attrName="arqueacao" [(model)]="arqueacao"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="isQuadro4">
        <pl-group>
          <label [translate]="'modelo22.modal.anexog.percRendimentos'"></label>
          <edit>
            <pl-edit type="number" attrName="percRendimentos" [(model)]="percRendimentos"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexog.numeroDias'"></label>
          <edit>
            <pl-edit type="cginteger" attrName="numeroDias" [(model)]="numeroDias" [properties]="{validate: false}"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="isQuadro4">
        <pl-group>
          <label [translate]="'modelo22.modal.anexog.reducaoMateria'"></label>
          <edit>
            <pl-edit type="number" attrName="reducaoMateria" [(model)]="reducaoMateria"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'modelo22.modal.anexog.materiaColectavel'"></label>
          <edit>
            <pl-edit type="number" attrName="materiaColectavel" [(model)]="materiaColectavel"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
