import {ClassProvider, forwardRef, Injectable} from '@angular/core';
import {DI_AMALIA_INTENT_HANDLERS} from '../../../../services/amalia/intent/amalia.intent.handler.di';
import {IAmaliaIntent, IAmaliaIntentHandler} from '../../../../services/amalia/intent/amalia.intent.handler.interface';
import {AMALIA_INTENT_NAME_CLIENTES_VER_FICHA, IClifosIntentParameters} from '../clifos.amalia.interface';
import {ENTITY_NAME_CLIENTES} from '../../clifos.entity.interface';
import {CGStateService} from '../../../../components/state/cg.state.service';
import {EPortal} from '../../../../../common/enums/portals.enums';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';

export const AMALIA_INTENT_HANDLER_CLIENTES_VER_FICHA: ClassProvider = {
  provide: DI_AMALIA_INTENT_HANDLERS,
  multi: true,
  useClass: forwardRef(() => ClientesVerFichaAmaliaIntentHandler)
};

@Injectable()
export class ClientesVerFichaAmaliaIntentHandler implements IAmaliaIntentHandler {
  constructor(private readonly _cgStateService: CGStateService) {}

  public intentName(): string {
    return AMALIA_INTENT_NAME_CLIENTES_VER_FICHA;
  }

  public async intentHandler(intent: IAmaliaIntent<IClifosIntentParameters>): Promise<void> {
    if (intent.parameters.nConta) {
      return this._cgStateService.redirectToState({
        stateOrName: ENTITY_NAME_CLIENTES,
        stateType: EEntityStateDetailType.DETAIL,
        portal: EPortal.ERP,
        exactMatch: true,
        transitionOptions: {reload: true},
        params: {
          id: intent.parameters.nConta
        }
      });
    }
    return this._cgStateService.redirectToState({
      stateOrName: ENTITY_NAME_CLIENTES,
      portal: EPortal.ERP,
      exactMatch: true,
      transitionOptions: {reload: true},
      params: {
        query: intent.parameters.query,
        nConta: intent.parameters.nConta
      }
    });
  }
}
