import {Component, Injector} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {PreviTesService} from '../../../previTes.module.service';
import {HttpResponse} from '@angular/common/http';
import moment from 'moment';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {EObjTesourariaPeriodicidade, IJsonPreviTes, IJsonPreviTesNew} from '../../../../../../entities/tesrubrica/jsonTesRubrica.entity.interface';

@Component({
  selector: 'modal-new-previ-tes',
  templateUrl: './newpreviTes.modal.component.html'
})
export class NewPreviTesModalComponent extends CGModalComponent<Array<IJsonPreviTes>> {
  public newPrevites: IJsonPreviTesNew;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _previTesService: PreviTesService
  ) {
    super(_injector);
    this.newPrevites = {
      codRubrica: undefined,
      descricao: '',
      data: moment(),
      valor: undefined,
      nLanc: '',
      numOcorr: 0,
      ocorrFinalPeriodo: false,
      periodicidade: EObjTesourariaPeriodicidade.None
    };
  }

  public close(): Promise<void> {
    if (!this.newPrevites.codRubrica) {
      this._plAlertService.error('prevites.manual.new.rubricanull');
      return Promise.resolve();
    }
    if (!this.newPrevites.valor) {
      this._plAlertService.error('prevites.manual.new.valornull');
      return Promise.resolve();
    }
    if (!this.newPrevites.data || moment(this.newPrevites.data).isBefore(moment(), 'day')) {
      this._plAlertService.error('prevites.manual.new.datainf');
      return Promise.resolve();
    }
    if (this.newPrevites.periodicidade !== EObjTesourariaPeriodicidade.None && this.newPrevites.numOcorr < 1) {
      this._plAlertService.error('prevites.manual.new.temdefnocurr');
      return Promise.resolve();
    } else if (this.newPrevites.numOcorr > 0 && this.newPrevites.periodicidade === EObjTesourariaPeriodicidade.None) {
      this._plAlertService.error('prevites.manual.new.temdefocurr');
      return Promise.resolve();
    }
    return this._previTesService.postPrevTesManual(this.newPrevites).then((response: HttpResponse<Array<IJsonPreviTes>>) => {
      this._plAlertService.success('prevites.manual.new.previtessaved');
      super.close(response.body);
    });
  }
}
