import {HttpResponse} from '@angular/common/http';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {IRHRelatorioUnicoStatus} from './rhRelatorioUnico.module.interface';
import {RHRelatorioUnicoComponent} from './components/rhRelatorioUnico.module.component';
import {RHRelatorioUnicoService} from './rhRelatorioUnico.module.service';
import {ROLE} from '../../../services/role.const';

export const MODULE_RH_RELATORIO_UNICO: IModuleDefinition = {
  name: 'rhrelatoriounico',
  state: {
    url: '/rhrelatoriounico',
    component: RHRelatorioUnicoComponent,
    data: {
      roles: [ROLE.RH],
      icon: '',
      pageTitle: 'global.menu.rhrelatoriounico'
    },
    resolve: [
      {
        provide: 'currentStatus',
        deps: [RHRelatorioUnicoService],
        useFactory: (rhrelatoriounicoService: RHRelatorioUnicoService): Promise<IRHRelatorioUnicoStatus> => {
          return rhrelatoriounicoService.getStatus().then((response: HttpResponse<IRHRelatorioUnicoStatus>) => response.body);
        }
      },
      {
        provide: 'anoProcessamento',
        deps: [RHRelatorioUnicoService],
        useFactory: (rhrelatoriounicoService: RHRelatorioUnicoService): Promise<number> => {
          return rhrelatoriounicoService.getAnoProcessamento().then((response: HttpResponse<number>) => response.body);
        }
      }
    ]
  }
};
