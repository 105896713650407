import type dxDataGrid from 'devextreme/ui/data_grid';
import ArrayStore from 'devextreme/data/array_store';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGridEventOnEditorPreparing, IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonProcSeguradoraFolhaFerias, IJsonSeguradoraFolhaFeriasMPCX} from '../../jsonProcSeguradoraFolhaFerias.module.interface';
import {ISeguradoraFFMPCXProcSegSocial, ISeguradoraFolhaFeriasMPCX} from '../../procSeguradoraFolhaFerias.module.interface';
import {ProcSeguradoraFolhaFeriasService} from '../../procSeguradoraFolhaFerias.module.service';

@Component({
  selector: 'modal-procseguradorafolhaferias-info',
  templateUrl: './procSeguradoraFolhaFerias.info.modal.component.html'
})
export class ProcSeguradoraFolhaFeriasInfoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public seguradoraFolhaFeriasMPCX: Array<IJsonSeguradoraFolhaFeriasMPCX>;
  @Input() public procSeguradoraFolhaFerias: IJsonProcSeguradoraFolhaFerias;

  public dataGridDefinition: IDevExpressDataGrid;
  public canSave: boolean;

  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _procSeguradoraFolhaFeriasService: ProcSeguradoraFolhaFeriasService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.canSave = false;
  }

  public ngOnInit(): void {
    const tableSeguradoraFolhaFeriasMPCX: Array<ISeguradoraFolhaFeriasMPCX> = this.seguradoraFolhaFeriasMPCX;
    for (const item of tableSeguradoraFolhaFeriasMPCX) {
      item._canEditNdiasPremperman = !item.ndias_P_remperman;
      item._canEditNdiasIctrintermit = !item.ndias_I_ctrintermit;
      item._canEditNdias2ferpagascess = !item.ndias_2_ferpagascess;
      item._originalNdiasPremperman = !item.ndias_P_remperman ? 0 : item.ndias_P_remperman;
      item._originalNdiasIctrintermit = !item.ndias_I_ctrintermit ? 0 : item.ndias_I_ctrintermit;
      item._originalNdias2ferpagascess = !item.ndias_2_ferpagascess ? 0 : item.ndias_2_ferpagascess;
    }

    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.identificacao',
          fixed: true,
          columns: [
            {dataField: 'numsegsoc', dataType: 'number', caption: 'rhprocsegsocialconsultenvios.table.modal.nss', allowEditing: false},
            {dataField: 'codemp', dataType: 'number', caption: 'rhprocsegsocialconsultenvios.table.modal.nfuncionario', allowEditing: false},
            {dataField: 'nome', dataType: 'string', caption: 'rhprocsegsocialconsultenvios.table.modal.nome', allowEditing: false, width: '250px'}
          ]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.codp',
          columns: [
            {dataField: 'ndias_P_remperman', dataType: 'number', caption: 'rhprocsegsocialconsultenvios.table.modal.ndias', showEditorAlways: true},
            {dataField: 'val_P_remperman', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}
          ]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.codr',
          columns: [{dataField: 'val_R_subrefeicao', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.codsr',
          columns: [{dataField: 'val_SR_subrefsemss', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.codf',
          columns: [{dataField: 'val_F_subferias', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.codn',
          columns: [{dataField: 'val_N_subnatal', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.coda',
          columns: [{dataField: 'val_A_ajudascusto', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.codb',
          columns: [{dataField: 'val_B_premmensal', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.codc',
          columns: [{dataField: 'val_C_comissoes', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.codd',
          columns: [{dataField: 'val_D_compcessacao', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.codh',
          columns: [{dataField: 'val_H_honorarios', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.codm',
          columns: [{dataField: 'val_M_subregmensal', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.codo',
          columns: [{dataField: 'val_O_prnaomensal', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.cods',
          columns: [{dataField: 'val_S_rsuplementar', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.codt',
          columns: [{dataField: 'val_T_trabnocturno', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.codx',
          columns: [{dataField: 'val_X_outsubsidios', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.cod2',
          columns: [
            {dataField: 'ndias_2_ferpagascess', dataType: 'number', caption: 'rhprocsegsocialconsultenvios.table.modal.ndias', showEditorAlways: true},
            {dataField: 'val_2_ferpagascess', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}
          ]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.cod6',
          columns: [{dataField: 'val_6_difvencim', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}]
        },
        {
          caption: 'rhprocsegsocialconsultenvios.table.modal.codi',
          columns: [
            {dataField: 'ndias_I_ctrintermit', dataType: 'number', caption: 'rhprocsegsocialconsultenvios.table.modal.ndias', showEditorAlways: true},
            {dataField: 'val_I_ctrintermit', dataType: 'double', caption: 'rhprocsegsocialconsultenvios.table.modal.valor', allowEditing: false, format: {suppressZeros: true}}
          ]
        }
      ],
      dataSource: new ArrayStore({
        key: 'codemp',
        data: tableSeguradoraFolhaFeriasMPCX,
        onUpdated: () => {
          this._nDiasChanged();
        }
      }),
      editing: {
        allowUpdating: true,
        mode: 'cell',
        refreshMode: 'repaint',
        selectTextOnEditStart: true
      },
      export: {filename: 'rhprocsegsocialconsultenvios.table.modal.title'},
      headerFilter: {visible: false},
      remoteOperations: false
    };
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onEditorPreparing(event: IDevExpressDataGridEventOnEditorPreparing<ISeguradoraFolhaFeriasMPCX>): void {
    if (event.parentType === 'dataRow') {
      switch (event.dataField) {
        case 'ndias_P_remperman':
          event.cancel = event.row.data._canEditNdiasPremperman;
          break;
        case 'ndias_2_ferpagascess':
          event.cancel = event.row.data._canEditNdias2ferpagascess;
          break;
        case 'ndias_I_ctrintermit':
          event.cancel = event.row.data._canEditNdiasIctrintermit;
          break;
      }
    }
  }

  public fnSaveInfoFichFolhaFerias = (): Promise<void> => this._saveInfoFichFolhaFerias();

  private _saveInfoFichFolhaFerias(): Promise<void> {
    return this._dataGridInstance
      .getDataSource()
      .store()
      .load()
      .then((tableSeguradoraFolhaFeriasMPCX: Array<ISeguradoraFolhaFeriasMPCX>) => {
        const seguradoraFFMPCXProcSegSocial: ISeguradoraFFMPCXProcSegSocial = {
          seguradoraffmpcx: tableSeguradoraFolhaFeriasMPCX,
          procsegsocial: this.procSeguradoraFolhaFerias
        };
        return this._procSeguradoraFolhaFeriasService
          .postGuardarInfoSeguradoraFolhaFerias(seguradoraFFMPCXProcSegSocial)
          .then(() => {
            this._plAlertService.success('rhprocsegsocialconsultenvios.messages.infosuccess');
          })
          .catch(() => {
            this._plAlertService.error('rhprocsegsocialconsultenvios.messages.infoerror');
          });
      });
  }

  private _nDiasChanged(): void {
    this.canSave = false;
    this._dataGridInstance
      .getDataSource()
      .store()
      .load()
      .then((tableSeguradoraFolhaFeriasMPCX: Array<ISeguradoraFolhaFeriasMPCX>) => {
        for (const item of tableSeguradoraFolhaFeriasMPCX) {
          if (
            item.ndias_P_remperman !== item._originalNdiasPremperman ||
            item._originalNdiasIctrintermit !== item.ndias_I_ctrintermit ||
            item._originalNdias2ferpagascess !== item.ndias_2_ferpagascess
          ) {
            this.canSave = true;
            break;
          }
        }
      });
  }
}
