import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {IPlToolbarItem} from 'pl-comps-angular';
import {Subscription} from 'rxjs';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {AuthService} from '../../../../../services/auth/auth.service';
import {PortalClientAccountService} from '../../../../../services/portalclientaccount/portalclientaccount.service';
import {ROLE} from '../../../../../services/role.const';
import {PcaContabilidadeService} from '../../pca.contabilidade.module.service';
import {EPcaTipoExportacao, IPcaContabilidadeServiceRefreshedEvent} from '../../pca.contabilidade.module.service.interface';
import {EConfigOptionsInstanceName, IIdadeSaldosConfigOptions, TConfigOptions} from '../../../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../../../config/constants';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {IConfigOptionsInstance} from '../../../../../services/config/options/config.options.instance.interface';
import {ConfigOptionsService} from '../../../../../services/config/options/config.options.service';

@Component({
  selector: 'pca-idade-saldos',
  templateUrl: './idadeSaldos.module.component.html'
})
export class PcaIdadeSaldosComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public tipo: string;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public pdfUrl: string;
  public reportModel: IJsonReport;
  public grupoConta: string;
  public outIdIde = '{{idIdeChav}} - {{descricao}}';
  public outPocs = '{{nConta}} - {{nome}}';
  public nConta: string;
  public filtro: string;
  public optionShowGrupoConta: boolean;

  private readonly _btnProcessXml: IPlToolbarItem;
  private readonly _subscriptionConfigOptions: Subscription;
  private readonly _subscriptionPcaServiceOnRefresh: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _authService: AuthService,
    private readonly _pcaContabilidadeService: PcaContabilidadeService,
    private readonly _portalClientAccountsFactory: PortalClientAccountService,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _configOptionsService: ConfigOptionsService
  ) {
    super(_injector);
    this.refresh = this.refresh.bind(this);
    this.configOptionsInstanceName = EConfigOptionsInstanceName.IDADE_SALDOS;
    this.configOptionsGroupName = EGroupName.ERP;

    this._btnProcessXml = {
      id: 'btn-excel',
      order: 4,
      type: 'download',
      iconLeft: '<i class="fa fa-fw fa-file-excel-o"></i>',
      class: 'btn-success',
      caption: 'global.btn.processExcel',
      disabled: false,
      download: {
        url: undefined
      }
    };

    const configOptionsInstance: IConfigOptionsInstance<boolean, IIdadeSaldosConfigOptions> = this._configOptionsService
      .getGroupOptions(this.configOptionsGroupName)
      .get(this.configOptionsInstanceName);
    this._subscriptionConfigOptions = configOptionsInstance.options().subscribe((configOptions: TConfigOptions<boolean, IIdadeSaldosConfigOptions>) => {
      this.optionShowGrupoConta = configOptions.get('showGrupoConta').value;
    });

    this._subscriptionPcaServiceOnRefresh = this._pcaContabilidadeService.onRefresh().subscribe((event: IPcaContabilidadeServiceRefreshedEvent) => {
      this._onNotify(event);
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnProcessXml);
    this._authService.hasAuthority(ROLE.CONTABILIDADE).then((result: boolean) => {
      this._pcaContabilidadeService.init(this.toolbar, {showCaptionMes: !result});
    });
    this.filtro = 'tipo=0';
    if (this.tipo === 'clientes') {
      this.filtro += '&nconta>=21&nconta<=21699999';
    }
    if (this.tipo === 'fornecedores') {
      this.filtro += '&nconta>=22&nconta<=228999999';
    }
    this._loadDefaultReport();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionConfigOptions.unsubscribe();
    this._subscriptionPcaServiceOnRefresh.unsubscribe();
  }

  public refresh(): void {
    const ano = this._pcaContabilidadeService.ano;
    const periodo = this._pcaContabilidadeService.periodo;
    this._portalClientAccountsFactory
      .getIdadeSaldosUrl(this.tipo, ano.ano, periodo.periodoSimples, EPcaTipoExportacao.Pdf, this.nConta, this.grupoConta, this.reportModel.name)
      .subscribe((url: string) => {
        this.pdfUrl = url;
      });
  }

  private _onNotify({ano, periodo}: IPcaContabilidadeServiceRefreshedEvent): void {
    this._portalClientAccountsFactory.getIdadeSaldosUrl(this.tipo, ano.ano, periodo.periodoSimples, EPcaTipoExportacao.Pdf, '', '', this.reportModel.name).subscribe((url: string) => {
      this.pdfUrl = url;
    });

    this._portalClientAccountsFactory.getIdadeSaldosUrl(this.tipo, ano.ano, periodo.periodoSimples, EPcaTipoExportacao.Xls, '', '', this.reportModel.name).subscribe((url: string) => {
      this._btnProcessXml.download.url = url;
      this._btnProcessXml.disabled = false;
    });
  }

  private _loadDefaultReport(): Promise<void> {
    this.reportModel = {
      title: '',
      name: ''
    };
    return this._reportsRegistryService
      .get(EReport.MovimentosEmAberto)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          let foundDefault = false;
          for (const report of reports) {
            if (report.name.toUpperCase() === this.reportModel.name.toUpperCase()) {
              this.reportModel = {name: report.name, title: report.title};
              foundDefault = true;
              break;
            }
          }

          if (!foundDefault) {
            this.reportModel = {
              name: reports[0].name,
              title: reports[0].title
            };
          }
        }
      });
  }
}
