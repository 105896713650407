import {Component, Injector, Input} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IRHProceSalariosDgEmp, IRHProceSalariosProcExistenteEmpregado} from '../../proceSalarios.module.interface';
import {ProceSalariosService} from '../../proceSalarios.module.service';
import {HttpResponse} from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {TDate} from '../../../../../../common/dates';

@Component({
  selector: 'proce-salarios-empregados-modal',
  templateUrl: './proceSalarios.empregados.modal.component.html'
})
export class ProceSalariosEmpregadosModalComponent extends CGModalComponent<Array<IRHProceSalariosProcExistenteEmpregado>> {
  @Input() public nEmpresa: string;
  @Input() public nProc: number;
  @Input() public tipoProcessamento: number;
  @Input() public dataInicoProc: TDate;
  @Input() public dataFimProce: TDate;
  @Input() public list: Array<IRHProceSalariosProcExistenteEmpregado>;

  public readonly dataGridDefinitionProceSalEmp: IDevExpressDataGrid<IRHProceSalariosDgEmp, number>;

  public selectedKeysDGEmp: Array<number>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _proceSalariosService: ProceSalariosService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinitionProceSalEmp = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'codEmp', dataType: 'number', caption: 'proceSalarios.fields.codEmp'},
        {dataField: 'nome', dataType: 'string', caption: 'proceSalarios.fields.nome'},
        {dataField: 'nBIdentidade', dataType: 'string', caption: 'proceSalarios.fields.nBIdentidade'},
        {dataField: 'nContribuinte', dataType: 'string', caption: 'proceSalarios.fields.nContribuinte'},
        {dataField: 'dataNascimento', dataType: 'date', caption: 'proceSalarios.fields.dataNascimento'}
      ],
      dataSource: new CustomStore({
        key: 'codEmp',
        load: () => this._dgEmpTableDefTableSource()
      }),
      export: {filename: 'proceSalarios.pesqEmpregados'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      remoteOperations: false
    };
    this.selectedKeysDGEmp = [];
  }

  public close(): Promise<void> {
    if (!this.selectedKeysDGEmp.length) {
      this._plAlertService.error('proceSalarios.messages.naoContemEmpSelecionado');
      return Promise.resolve();
    }
    this.disableClose();
    return this._proceSalariosService
      .addEmpregadosProc(this.nProc, this.selectedKeysDGEmp, this.nEmpresa)
      .then((response: HttpResponse<Array<IRHProceSalariosProcExistenteEmpregado>>) => {
        for (const item of response.body) {
          if (!item.nProcessamento) {
            item.nProcessamento = this.nProc;
          }
        }
        super.close(response.body);
      })
      .finally(() => {
        this.enableClose();
      });
  }

  private _dgEmpTableDefTableSource(): Promise<Array<IRHProceSalariosDgEmp>> {
    this.selectedKeysDGEmp = [];
    return this._proceSalariosService.getEmpregados(this.nEmpresa, this.tipoProcessamento, this.dataInicoProc, this.dataFimProce).then((response: Array<IRHProceSalariosDgEmp>) => {
      const dgEmpTableSource: Array<IRHProceSalariosDgEmp> = response.filter(
        (emp: IRHProceSalariosDgEmp) => !this.list.find((obj: IRHProceSalariosProcExistenteEmpregado) => obj.codEmpregado === emp.codEmp)
      );
      for (const item of dgEmpTableSource) {
        this.selectedKeysDGEmp.push(item.codEmp);
      }
      return dgEmpTableSource;
    });
  }
}
