<div class="modelo10 entity-detail-form">
  <pl-form [properties]="{disabled: !!promise}">
    <pl-navigator contentMaxHeight="80vh" [callback]="callbackNav" [plPromise]="promise">
      <pl-navigator-item caption="global.text.attention" [order]="0" [id]="0">
        <div *plNavigatorItemCaption><span class="number-title-box">00</span>&nbsp;<span [translate]="'global.text.attention'"></span></div>
        <div *plNavigatorItemContent>
          <p><span [translate]="'modelo10.groups.atencao.p1'"></span></p>
          <p>
            <b><span [translate]="'modelo10.groups.atencao.p2'"></span></b>
          </p>
          <p>
            <span [translate]="'modelo10.groups.atencao.p3.p3-1'"></span>
            <b><span [translate]="'modelo10.groups.atencao.p3.p3-2'"></span></b>
            <span [translate]="'modelo10.groups.atencao.p3.p3-3'"></span>
            <b><span [translate]="'modelo10.groups.atencao.p3.p3-4'"></span></b>
            <span [translate]="'modelo10.groups.atencao.p3.p3-5'"></span>
          </p>
          <p><span [translate]="'modelo10.groups.atencao.p4'"></span></p>
          <p><span [translate]="'modelo10.groups.atencao.p5'"></span></p>
          <p>
            <span [translate]="'modelo10.groups.atencao.p6.p6-1'"></span>
            <b><span [translate]="'modelo10.groups.atencao.p6.p6-2'"></span></b>
            <span [translate]="'modelo10.groups.atencao.p6.p6-3'"></span>
          </p>
          <p><span [translate]="'modelo10.groups.atencao.p7'"></span></p>
          <p>
            <span [translate]="'modelo10.groups.atencao.p8.p8-1'"></span>
            <b><span [translate]="'modelo10.groups.atencao.p8.p8-2'"></span></b>
            <span [translate]="'modelo10.groups.atencao.p8.p8-3'"></span>
          </p>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo10.groups.financAreaDomi.title" [order]="1" [id]="1">
        <div *plNavigatorItemCaption><span class="number-title-box">01</span>&nbsp;<span class="nav-item-title" [translate]="'modelo10.groups.financAreaDomi.title'"></span></div>
        <div *plNavigatorItemContent>
          <div>
            <label [translate]="'modelo10.groups.financAreaDomi.codservfinan'"></label>
            <div class="d-flex">
              <div class="input-box-numbers">1</div>
              <pl-edit type="text" attrName="quadro1Campo1" [model]="modelo10.quadro1Campo1" [properties]="{disabled: true}"></pl-edit>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo10.groups.nif.title" [order]="2" [id]="2">
        <div *plNavigatorItemCaption><span class="number-title-box">02</span>&nbsp;<span class="nav-item-title" [translate]="'modelo10.groups.nif.title'"></span></div>
        <div *plNavigatorItemContent>
          <div>
            <label [translate]="'modelo10.groups.nif.nif'"></label>
            <div class="d-flex">
              <div class="input-box-numbers">2</div>
              <pl-edit type="text" attrName="quadro2Campo2" [model]="modelo10.quadro2Campo2" [properties]="{disabled: true}"></pl-edit>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="'global.text.year' | translate" [order]="3" [id]="3">
        <div *plNavigatorItemCaption><span class="number-title-box">03</span>&nbsp;<span class="nav-item-title" [translate]="'global.text.year'"></span></div>
        <div *plNavigatorItemContent>
          <div>
            <label [translate]="'global.text.year'"></label>
            <div class="d-flex">
              <div class="input-box-numbers">3</div>
              <pl-edit type="text" attrName="quadro3Campo3" [model]="modelo10.quadro3Campo3" [properties]="{disabled: true}"></pl-edit>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo10.groups.importanciaretidas.title" [order]="4" [id]="4">
        <div *plNavigatorItemCaption><span class="number-title-box">04</span>&nbsp;<span class="nav-item-title" [translate]="'modelo10.groups.importanciaretidas.title'"></span></div>
        <div *plNavigatorItemContent>
          <div class="importanciaretidas">
            <div class="row">
              <div class="col"><label [translate]="'modelo10.groups.importanciaretidas.quadro4.campo1'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">1</div>
                  <pl-edit type="currency" attrName="quadro4Campo1" [model]="modelo10.quadro4.campo01" (modelChange)="modelo10.quadro4.campo01 = $event; calcQuadro4Totals()"></pl-edit>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col"><label [translate]="'modelo10.groups.importanciaretidas.quadro4.campo2'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">2</div>
                  <pl-edit type="currency" attrName="quadro4Campo2" [model]="modelo10.quadro4.campo02" (modelChange)="modelo10.quadro4.campo02 = $event; calcQuadro4Totals()"></pl-edit>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col"><label [translate]="'modelo10.groups.importanciaretidas.quadro4.campo3'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">3</div>
                  <pl-edit type="currency" attrName="quadro4Campo3" [model]="modelo10.quadro4.campo03" (modelChange)="modelo10.quadro4.campo03 = $event; calcQuadro4Totals()"></pl-edit>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col"><label [translate]="'modelo10.groups.importanciaretidas.quadro4.campo4'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">4</div>
                  <pl-edit type="currency" attrName="quadro4Campo4" [model]="modelo10.quadro4.campo04" (modelChange)="modelo10.quadro4.campo04 = $event; calcQuadro4Totals()"></pl-edit>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col"><label [translate]="'modelo10.groups.importanciaretidas.quadro4.campo5'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">5</div>
                  <pl-edit type="currency" attrName="quadro4Campo5" [model]="modelo10.quadro4.campo05" (modelChange)="modelo10.quadro4.campo05 = $event; calcQuadro4Totals()"></pl-edit>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col"><label [translate]="'modelo10.groups.importanciaretidas.quadro4.campo6'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">6</div>
                  <pl-edit type="currency" attrName="quadro4Campo6" [model]="modelo10.quadro4.campo06" (modelChange)="modelo10.quadro4.campo06 = $event; calcQuadro4Totals()"></pl-edit>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col"><label [translate]="'modelo10.groups.importanciaretidas.quadro4.campo7'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">7</div>
                  <pl-edit type="currency" attrName="quadro4Campo7" [model]="modelo10.quadro4.campo07" (modelChange)="modelo10.quadro4.campo07 = $event; calcQuadro4Totals()"></pl-edit>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col"><label [translate]="'modelo10.groups.importanciaretidas.quadro4.campo8'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">8</div>
                  <pl-edit type="currency" attrName="quadro4Campo8" [model]="modelo10.quadro4.campo08" (modelChange)="modelo10.quadro4.campo08 = $event; calcQuadro4Totals()"></pl-edit>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col"><label [translate]="'modelo10.groups.importanciaretidas.quadro4.campo9'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">9</div>
                  <pl-edit type="currency" attrName="quadro4Campo9" [model]="modelo10.quadro4.campo09" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col"><label [translate]="'modelo10.groups.importanciaretidas.quadro4.campo10'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">10</div>
                  <pl-edit type="currency" attrName="quadro4Campo10" [model]="modelo10.quadro4.campo10" (modelChange)="modelo10.quadro4.campo10 = $event; calcQuadro4Totals()"></pl-edit>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col"><label [translate]="'modelo10.groups.importanciaretidas.quadro4.campo11'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">11</div>
                  <pl-edit type="currency" attrName="quadro4Campo11" [model]="modelo10.quadro4.campo11" (modelChange)="modelo10.quadro4.campo11 = $event; calcQuadro4Totals()"></pl-edit>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col"><label [translate]="'modelo10.groups.importanciaretidas.quadro4.campo12'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">12</div>
                  <pl-edit type="currency" attrName="quadro4Campo12" [model]="modelo10.quadro4.campo12" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col"><label [translate]="'modelo10.groups.importanciaretidas.quadro4.campo13'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">13</div>
                  <pl-edit type="currency" attrName="quadro4Campo13" [(model)]="modelo10.quadro4.campo13"></pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo10.groups.reltitrendimentos.title" [order]="5" [id]="5">
        <div *plNavigatorItemCaption><span class="number-title-box">05</span>&nbsp;<span class="nav-item-title" [translate]="'modelo10.groups.reltitrendimentos.title'"></span></div>
        <div *plNavigatorItemContent>
          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinition"
            cgDxDataGridInstanceName="modelo10ReltitRendimentos"
            [dataSource]="dataGridDefinition.dataSource"
            (onInitialized)="onInitialized($event)"
            (onContentReady)="onContentReady()">
            <div *dxTemplate="let headerInfo of 'headerTemplateQuadro5AddLinha'">
              <button type="button" class="btn btn-sm btn-outline-primary" (click)="addLine()">
                <i class="fa fa-plus-circle me-1" aria-hidden="true"></i>
                <span [translate]="'modelo10.groups.reltitrendimentos.quadro5.table.addlinha'"></span>
              </button>
            </div>

            <div *dxTemplate="let cellInfo of 'cellTemplateBtnsQuadro5'">
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-sm btn-warning" (click)="editLine(cellInfo.data)">
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </button>
                <button type="button" class="btn btn-sm btn-danger" (click)="deleteLine(cellInfo.data)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </dx-data-grid>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo10.groups.tipodec.title" [order]="6" [id]="6">
        <div *plNavigatorItemCaption><span class="number-title-box">06</span>&nbsp;<span class="nav-item-title" [translate]="'modelo10.groups.tipodec.title'"></span></div>
        <div *plNavigatorItemContent>
          <div class="d-flex checkbox-align-box-number">
            <div class="input-box-numbers">1</div>
            <pl-edit type="checkbox" attrName="campo01" [model]="modelo10.quadro6.campo01" (modelChange)="setTipoDeclaracaoCampo1($event)" [properties]="{label: 'modelo10.groups.tipodec.campo1'}">
            </pl-edit>
          </div>
          <div class="d-flex checkbox-align-box-number">
            <div class="input-box-numbers">2</div>
            <pl-edit type="checkbox" attrName="campo02" [model]="modelo10.quadro6.campo02" (modelChange)="setTipoDeclaracaoCampo2($event)" [properties]="{label: 'modelo10.groups.tipodec.campo2'}">
            </pl-edit>
          </div>
          <div class="d-flex checkbox-align-box-number">
            <div class="input-box-numbers">3</div>
            <pl-edit
              type="checkbox"
              attrName="campo03"
              [model]="modelo10.quadro6.campo03"
              (modelChange)="modelo10.quadro6.campo03 = $event; evaluateButtons(tipoModelo10)"
              [properties]="{label: 'modelo10.groups.tipodec.campo3'}"></pl-edit>
          </div>
          <div>
            <label [translate]="'modelo10.groups.tipodec.campo4'"></label>
            <div class="d-flex">
              <div class="input-box-numbers">4</div>
              <pl-edit
                type="date"
                attrName="campo04"
                [model]="modelo10.quadro6.campo04"
                (modelChange)="modelo10.quadro6.campo04 = $event; evaluateButtons(tipoModelo10)"
                [properties]="{disabled: !modelo10.quadro6.campo03, validators: {required: {value: modelo10.quadro6.campo03}}}">
              </pl-edit>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo10.groups.identSujPass.title" [order]="7" [id]="7">
        <div *plNavigatorItemCaption><span class="number-title-box">07</span>&nbsp;<span class="nav-item-title" [translate]="'modelo10.groups.identSujPass.title'"></span></div>
        <div *plNavigatorItemContent>
          <div>
            <label [translate]="'modelo10.groups.identSujPass.campo1'"></label>
            <div class="d-flex">
              <div class="input-box-numbers">1</div>
              <pl-edit type="cginteger" attrName="quadro7campo01" [model]="modelo10.quadro7.campo02" (modelChange)="modelo10.quadro7.campo02 = $event; evaluateButtons(tipoModelo10)"></pl-edit>
            </div>
          </div>

          <div>
            <label [translate]="'modelo10.groups.identSujPass.campo2'"></label>
            <div class="d-flex">
              <div class="input-box-numbers">2</div>
              <pl-edit type="cginteger" attrName="quadro7campo02" [model]="modelo10.quadro7.campo01" (modelChange)="modelo10.quadro7.campo01 = $event; evaluateButtons(tipoModelo10)"></pl-edit>
            </div>
          </div>
        </div>
      </pl-navigator-item>
    </pl-navigator>
  </pl-form>
</div>
