import type {Subscription} from 'rxjs';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {isArray, isBoolean, isNumber} from '../common/utilities/utilities';
import type {IPlLocale} from '../common/locale/locales.interface';
import type {IPlPaginationEvtPaginationChanged, IPlPaginationProperties} from './pagination.component.interface';
import {PlLocaleService} from '../common/locale/locale.service';

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 10;
const DEFAULT_TOTAL = 0;
const PER_PAGE_TEN = 10;
const PER_PAGE_TWENTY = 20;
const PER_PAGE_FIFTY = 50;
const PER_PAGE_ONE_HUNDRED = 100;
const HALF = 2;
let uniqueInstanceId = 0;

@Component({
  selector: 'pl-pagination',
  templateUrl: './pagination.component.html',
  standalone: false
})
export class PlPaginationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public instanceId: string;
  @Input() public page: number;
  @Input() public perPage: number;
  @Input() public total: number;
  @Input() public boundaryLinks: boolean;
  @Input() public directionLinks: boolean;
  @Input() public lines: Array<number>;
  @Input() public paginationRange: number;
  @Input() public showPagination: boolean;
  @Input() public useUrl: boolean;
  @Input() public properties: IPlPaginationProperties;
  @Output() public readonly pageChange: EventEmitter<number>;
  @Output() public readonly perPageChange: EventEmitter<number>;
  @Output() public readonly evtPaginationChanged: EventEmitter<IPlPaginationEvtPaginationChanged>;

  public locale: IPlLocale;
  public pages: Array<string | number>;
  public totalPages: number;

  private readonly _subscriptionLocale: Subscription;

  constructor(private readonly _plLocaleService: PlLocaleService) {
    this.pageChange = new EventEmitter<number>();
    this.perPageChange = new EventEmitter<number>();
    this.evtPaginationChanged = new EventEmitter<IPlPaginationEvtPaginationChanged>();
    this.page = DEFAULT_PAGE;
    this.perPage = DEFAULT_PER_PAGE;
    this.total = DEFAULT_TOTAL;
    this.pages = [];
    this.totalPages = 0;
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.locale = locale;
    });
  }

  public ngOnInit(): void {
    this._handleChanges();
    this._refreshPages();
  }

  public ngOnChanges({properties, instanceId, boundaryLinks, directionLinks, lines, paginationRange, showPagination, useUrl, page, perPage, total}: SimpleChanges): void {
    if (properties && !properties.isFirstChange()) {
      this._handleChanges();
    }
    if (instanceId && !instanceId.isFirstChange()) {
      this._changedInstanceId(instanceId.currentValue);
    }
    if (boundaryLinks && !boundaryLinks.isFirstChange()) {
      this._changedBoundaryLinks(boundaryLinks.currentValue);
    }
    if (directionLinks && !directionLinks.isFirstChange()) {
      this._changedDirectionLinks(directionLinks.currentValue);
    }
    if (lines && !lines.isFirstChange()) {
      this._changedLines(lines.currentValue);
    }
    if (paginationRange && !paginationRange.isFirstChange()) {
      this._changedPaginationRange(paginationRange.currentValue);
    }
    if (showPagination && !showPagination.isFirstChange()) {
      this._changedShowPagination(showPagination.currentValue);
    }
    if (useUrl && !useUrl.isFirstChange()) {
      this._changedUseUrl(useUrl.currentValue);
    }
    const changedPage: boolean = page && !page.isFirstChange();
    const changedPerPage: boolean = perPage && !perPage.isFirstChange();
    const changedTotal: boolean = total && !total.isFirstChange();
    if (changedPage || changedPerPage || changedTotal) {
      if (changedPage) {
        let currentPage: number = page.currentValue;
        if (currentPage < DEFAULT_PAGE || currentPage > this.totalPages) {
          currentPage = DEFAULT_PAGE;
        }
        this._changedPage(currentPage);
      }
      if (changedPerPage) {
        this._changedPerPage(perPage.currentValue);
      }
      if (changedTotal) {
        this._changedTotal(total.currentValue);
      }
      this._refreshPages();
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
  }

  public setCurrent(page: string | number): void {
    if (!isNumber(page) || page < DEFAULT_PAGE || page > this.totalPages) {
      return;
    }
    this.page = page;
    this.pageChange.emit(this.page);
    this._refreshPages();
    this.evtPaginationChanged.emit({page: this.page, perPage: this.perPage, total: this.total});
  }

  public setPerPage(value: number): void {
    this.perPage = value;
    this.perPageChange.emit(this.perPage);
    this._refreshPages();
    if (this.page > this.totalPages) {
      this.page = DEFAULT_PAGE;
      this.pageChange.emit(this.page);
      this._refreshPages();
    }
    this.evtPaginationChanged.emit({page: this.page, perPage: this.perPage, total: this.total});
  }

  private _handleChanges(): void {
    this._changedInstanceId();
    this._changedBoundaryLinks();
    this._changedDirectionLinks();
    this._changedLines();
    this._changedPaginationRange();
    this._changedShowPagination();
    this._changedUseUrl();
    this._changedPage();
    this._changedPerPage();
    this._changedTotal();
  }

  private _changedInstanceId(value: string = this.instanceId): void {
    this.instanceId = value || `cgcPagination_${uniqueInstanceId++}`;
  }

  private _changedPage(value: number = this.page): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this.properties?.page;
    }
    if (!isNumber(val)) {
      val = DEFAULT_PAGE;
    }
    this.page = val;
  }

  private _changedPerPage(value: number = this.perPage): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this.properties?.perPage;
    }
    if (!isNumber(val) && this.lines.length) {
      val = this.lines[0];
    }
    if (!isNumber(val)) {
      val = DEFAULT_PER_PAGE;
    }
    this.perPage = val;
  }

  private _changedTotal(value: number = this.total): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this.properties?.total;
    }
    if (!isNumber(val)) {
      val = DEFAULT_TOTAL;
    }
    this.total = val;
  }

  private _changedBoundaryLinks(value: boolean = this.boundaryLinks): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.properties?.boundaryLinks;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.boundaryLinks = val;
  }

  private _changedDirectionLinks(value: boolean = this.directionLinks): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.properties?.directionLinks;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.directionLinks = val;
  }

  private _changedLines(value: Array<number> = this.lines): void {
    let val: Array<number> = value;
    if (!isArray(val)) {
      val = this.properties?.lines;
    }
    if (!isArray(val)) {
      val = [PER_PAGE_TEN, PER_PAGE_TWENTY, PER_PAGE_FIFTY, PER_PAGE_ONE_HUNDRED];
    }
    this.lines = val;
  }

  private _changedPaginationRange(value: number = this.paginationRange): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this.properties?.paginationRange;
    }
    if (!isNumber(val)) {
      val = DEFAULT_PER_PAGE;
    }
    this.paginationRange = val;
  }

  private _changedShowPagination(value: boolean = this.showPagination): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.properties?.showPagination;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.showPagination = val;
  }

  private _changedUseUrl(value: boolean = this.useUrl): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.properties?.useUrl;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.useUrl = val;
  }

  private _refreshPages(): void {
    this.totalPages = Math.ceil(this.total / this.perPage);
    this.pages = this._generatePagesArray(this.page, this.total, this.perPage, this.paginationRange);
  }

  private _generatePagesArray(currentPage: number, collectionLength: number, rowsPerPage: number, paginationRange: number): Array<string | number> {
    const pages: Array<string | number> = [];
    const totalPages = Math.ceil(collectionLength / rowsPerPage);
    const halfWay = Math.ceil(paginationRange / HALF);
    let position: 'start' | 'middle' | 'end';
    if (currentPage <= halfWay) {
      position = 'start';
    } else if (totalPages - halfWay < currentPage) {
      position = 'end';
    } else {
      position = 'middle';
    }
    const ellipsesNeeded = paginationRange < totalPages;
    let i = 1;
    while (i <= totalPages && i <= paginationRange) {
      const pageNumber = this._calculatePageNumber(i, currentPage, paginationRange, totalPages);
      const openingEllipsesNeeded = i === HALF && (position === 'middle' || position === 'end');
      const closingEllipsesNeeded = i === paginationRange - 1 && (position === 'middle' || position === 'start');
      if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
        pages.push('...');
      } else {
        pages.push(pageNumber);
      }
      i++;
    }
    return pages;
  }

  private _calculatePageNumber(i: number, currentPage: number, paginationRange: number, totalPages: number): number {
    const halfWay = Math.ceil(paginationRange / HALF);
    if (i === paginationRange) {
      return totalPages;
    }
    if (i === DEFAULT_PAGE) {
      return i;
    }
    if (paginationRange < totalPages) {
      if (totalPages - halfWay < currentPage) {
        return totalPages - paginationRange + i;
      }
      if (halfWay < currentPage) {
        return currentPage - halfWay + i;
      }
      return i;
    }
    return i;
  }
}
