import {BehaviorSubject, Observable} from 'rxjs';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import type {IPlUITreeDragContext, IPlUITreeDragEvtNode, IPlUITreeDragInstance} from './uitreedrag.interface';
import {transferArrayItem} from '../common/utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class PlUITreeDragInstancesService implements OnDestroy {
  private readonly _defaultContext: Readonly<IPlUITreeDragContext>;
  private readonly _subjectContext: BehaviorSubject<IPlUITreeDragContext>;
  private readonly _instances: Map<HTMLElement, IPlUITreeDragInstance>;
  private readonly _document: Document;
  private _observableContext: Observable<IPlUITreeDragContext>;

  constructor(@Inject(DOCUMENT) document: any) {
    this._defaultContext = Object.freeze<IPlUITreeDragContext>({
      dragging: false,
      dragOriginalElement: undefined,
      dragOriginalIndex: undefined,
      dragOriginalArray: undefined,
      dragOriginalNodeItem: undefined,
      dragOriginalInstance: undefined,
      dragCurrentIndex: undefined,
      dragCurrentArray: undefined,
      dragCurrentNodeItem: undefined,
      dragCurrentInstance: undefined,
      dragPreviousIndex: undefined,
      dragPreviousArray: undefined,
      dragPreviousClientY: undefined,
      deepLevelMenu: undefined,
      clonedNodeItem: false
    });
    this._subjectContext = new BehaviorSubject<IPlUITreeDragContext>(this._defaultContext);
    this._instances = new Map<HTMLElement, IPlUITreeDragInstance>();
    this._document = document;
    if (this._document) {
      this._document.addEventListener('drop', this._fnDrop);
      this._document.addEventListener('dragend', this._fnDragEnd, {passive: true});
    }
  }

  public ngOnDestroy(): void {
    this._subjectContext.complete();
    if (this._document) {
      this._document.removeEventListener('drop', this._fnDrop);
      this._document.removeEventListener('dragend', this._fnDragEnd);
    }
  }

  public registerInstance(instance: IPlUITreeDragInstance): void {
    this._instances.set(instance.elementRef.nativeElement, instance);
  }

  public deRegisterInstance(instance: IPlUITreeDragInstance): void {
    this._instances.delete(instance.elementRef.nativeElement);
  }

  public draggingContext(): Observable<IPlUITreeDragContext> {
    if (!this._observableContext) {
      this._observableContext = this._subjectContext.asObservable();
    }
    return this._observableContext;
  }

  public startDrag(context: IPlUITreeDragContext): void {
    this._subjectContext.next(
      Object.freeze<IPlUITreeDragContext>({
        ...this._defaultContext,
        ...context
      })
    );
  }

  public updateDrag(context: Partial<IPlUITreeDragContext>): void {
    this._subjectContext.next(
      Object.freeze<IPlUITreeDragContext>({
        ...this._subjectContext.value,
        ...context
      })
    );
  }

  public cancelDrag(): void {
    const context: IPlUITreeDragContext = this._subjectContext.value;
    if (!context.clonedNodeItem) {
      transferArrayItem(context.dragCurrentArray, context.dragOriginalArray, context.dragCurrentIndex, context.dragOriginalIndex);
    } else {
      context.dragCurrentArray.splice(context.dragCurrentIndex, 1);
    }
    this.endDrag();
  }

  public endDrag(): void {
    this._subjectContext.next(this._defaultContext);
  }

  public generateEvtNode<T = unknown>(): IPlUITreeDragEvtNode<T> {
    const context: IPlUITreeDragContext<any> = this._subjectContext.value;
    return {
      nodeItem: context.dragCurrentNodeItem,
      source: {
        value: context.dragOriginalArray,
        parent: context.dragOriginalNodeItem
      },
      target: {
        value: context.dragCurrentArray,
        parent: context.dragCurrentNodeItem
      }
    };
  }

  private _drop(event: MouseEvent): void {
    const context: IPlUITreeDragContext = this._subjectContext.value;
    if (!context.dragging || !this._validateEventTarget(event.target)) {
      return;
    }
    const instance: IPlUITreeDragInstance = context.dragCurrentInstance;
    if (instance) {
      event.preventDefault();
      const changedInstance: boolean = instance !== context.dragOriginalInstance;
      if (changedInstance || context.dragOriginalArray !== context.dragCurrentArray) {
        (<any>event).dataTransfer.dropEffect = changedInstance ? 'copy' : 'move';
        this._dragEnd(event);
      }
    }
  }

  private _dragEnd(event: MouseEvent): void {
    const context: IPlUITreeDragContext = this._subjectContext.value;
    if (!context.dragging || !this._validateEventTarget(event.target)) {
      return;
    }

    context.dragCurrentNodeItem._cssClass = '';

    // User cancelled drag
    if ((<any>event).dataTransfer?.dropEffect === 'none') {
      this.cancelDrag();
      return;
    }

    let calledPreventDefault = false;
    context.dragCurrentInstance.evtDropped.emit({
      ...this.generateEvtNode(),
      preventDefault: () => {
        calledPreventDefault = true;
      }
    });

    if (calledPreventDefault) {
      this.cancelDrag();
    } else {
      const changedNodes: boolean = context.dragOriginalArray !== context.dragCurrentArray || context.dragOriginalIndex !== context.dragCurrentIndex;
      if (changedNodes) {
        if (context.dragOriginalInstance !== context.dragCurrentInstance) {
          const changedMenuOriginalInstance: boolean = context.dragOriginalInstance.cloneEnabled ? (context.dragOriginalInstance.noDrop ? false : changedNodes) : changedNodes;
          if (changedMenuOriginalInstance) {
            context.dragOriginalInstance.menuChanged();
          }
        }
        context.dragCurrentInstance.menuChanged();
      }
      this.endDrag();
    }
  }

  private _validateEventTarget(eventTarget: EventTarget): boolean {
    const target: HTMLElement = <HTMLElement>eventTarget;
    for (const element of this._instances.keys()) {
      if (element === target || element.contains(target)) {
        return true;
      }
    }
    return true;
  }

  private readonly _fnDrop = (event: MouseEvent): void => {
    this._drop(event);
  };

  private readonly _fnDragEnd = (event: MouseEvent): void => {
    this._dragEnd(event);
  };
}
