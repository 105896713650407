import {Component, Injector, Input, OnInit} from '@angular/core';
import {isArray, isNumber} from 'pl-comps-angular';
import {EGestaoDGEMPSType} from '../../../../components/gestaodgemps/gestaodgemps.interface';
import {IColaboradoresGestaoDGEMPSModuleStateParams} from '../colaboradores.gestaodgemps.module.interface';
import {IJsonConfigEstrutura, IJsonPRHEventosConfig} from '../../../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import {IJsonDGEMP} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHFluxo} from '../../../../entities/prhfluxos/jsonPRHFluxo.entity.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {MomentInput} from 'moment';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {GestaoDGEMPSPrintModalComponent} from '../../../../components/gestaodgemps/modals/print/gestaodgemps.print.modal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';

@Component({
  selector: 'module-colaboradores-gestao-dgemps',
  templateUrl: './colaboradores.gestaodgemps.module.component.html'
})
export class ColaboradoresGestaoDGEMPSModuleComponent extends ModuloComponent implements OnInit {
  @Input() public moduleType: EGestaoDGEMPSType;
  @Input() public managerMode: boolean;
  @Input() public configStructure: IJsonConfigEstrutura;
  @Input() public configEvents: Array<IJsonPRHEventosConfig>;
  @Input() public fluxos: Array<IJsonPRHFluxo>;
  @Input() public empregado: IJsonDGEMP;
  @Input() public holidaysDates: Array<MomentInput>;

  public codServico: number;
  public colaboradoresPendentes: Array<number>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    const params: IColaboradoresGestaoDGEMPSModuleStateParams = <IColaboradoresGestaoDGEMPSModuleStateParams>this._transition.params();
    if (isNumber(params.servico) && params.servico !== -1) {
      this.codServico = params.servico;
    }
    if (isArray(params.colaboradoresPendentes)) {
      this.colaboradoresPendentes = params.colaboradoresPendentes;
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.managerMode && (this.moduleType === EGestaoDGEMPSType.Faltas || this.moduleType === EGestaoDGEMPSType.Ferias)) {
      this.toolbar.addButton({
        id: 'gestaodgemps-toolbar-print',
        type: 'button',
        order: 1,
        class: 'btn-primary',
        iconLeft: '<i class="fa fa-fw fa-print"></i>',
        caption: 'global.btn.print',
        click: () => this._printModal()
      });
    }
  }

  private _printModal(): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(GestaoDGEMPSPrintModalComponent);
    const componentInstance: GestaoDGEMPSPrintModalComponent = modalInstance.componentInstance;
    componentInstance.type = this.moduleType;
    return modalInstance.result;
  }
}
