import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_DEBITO_CREDITO, EDebitoCredito} from './debitoCredito.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_DEBITO_CREDITO: IDataSource<EDebitoCredito> = deepFreeze({
  name: DATA_SOURCE_NAME_DEBITO_CREDITO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{label}}',
    output: 'label'
  },
  data: [
    {value: EDebitoCredito.Debito, label: 'global.debitCredit.debit'},
    {value: EDebitoCredito.Credito, label: 'global.debitCredit.credit'}
  ]
});
