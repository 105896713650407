import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {AnalisadorTesComponent} from './components/analisadorTes.module.component';
import {MODULE_NAME_ANALISADOR_TES} from './analisadorTes.module.interface';

export const MODULE_ANALISADOR_TES: IModuleDefinition = {
  name: MODULE_NAME_ANALISADOR_TES,
  state: {
    url: '/analisadorTes',
    component: AnalisadorTesComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.ERP, ROLE.FINANCEIRO],
      pluginsRoles: [ROLE.CONTABILIDADEPREMIUM, ROLE.ERPADVANCED],
      icon: 'fa-line-chart',
      pageTitle: 'global.menu.analisadorTes'
    }
  }
};
