import {deepFreeze} from 'pl-comps-angular';
import {ECDigitalOrigPeriodoLancAuto, IJsonContabDigitalConfigs, IJsonContabDigitalConfigsPasta} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressValidationCallbackData} from '../../../devexpress/validator/devexpress.validator.rules.interface';

export enum ERegContabDigitalNifEDPViaVerde {
  EPD,
  ViaVerde
}

export type TArquivoDigitalConfigValidationCallbackData = IDevExpressValidationCallbackData<'custom', dxDataGrid, IJsonContabDigitalConfigsPasta, 'string'>;

export type TConfigPastasCellDocFaType = 'FT' | 'FR' | 'NC' | 'NCPagaNoAto';

export interface IContabDigitalConfigs extends IJsonContabDigitalConfigs {
  syncFolders: Array<IContabDigitalConfigsPasta>;
}

export interface IContabDigitalConfigsPasta extends IJsonContabDigitalConfigsPasta {
  diario?: string;
  descritivo?: string;
  repcc?: string;
  _saved?: boolean;
}

export const RADIO_GROUP_ORIGEM_PERIO_LANC_AUTO_TEMPLATE: IRadioGroup<ECDigitalOrigPeriodoLancAuto> = deepFreeze<IRadioGroup<ECDigitalOrigPeriodoLancAuto>>({
  groupItems: [
    {value: ECDigitalOrigPeriodoLancAuto.TabelaEmpresa, label: 'arquivodigital.configs.tabs.lancautorobo.tabelaempresa'},
    {value: ECDigitalOrigPeriodoLancAuto.DataDoc, label: 'arquivodigital.configs.tabs.lancautorobo.datadoc'}
  ]
});
