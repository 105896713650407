import {IJsonAtivosConsistenciaAnos, IJsonAtivosConsistenciaContab, IJsonAtivosConsistenciaIgnorados} from './jsonAtivosConsistencia.module.interface';
import {IJsonDocContabilidade} from '../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';

export const MODULE_NAME_ATIVOS_CONSISTENCIA = 'ativosconsistencia';

export enum ETipoAtivosContab {
  AtivoBruto,
  DepreciacaoAcumulada,
  DepreciacaoExercicio,
  Outros
}

export interface IAtivosConsistenciaContab extends IJsonAtivosConsistenciaContab {
  _docContabilidade?: IJsonDocContabilidade;
  _selected?: boolean;
}

export interface IAtivosConsistenciaAnos extends IJsonAtivosConsistenciaAnos {
  dataDeFormated: string;
  dataAteFormated: string;
}

export interface IAtivosConsistenciaIgnorados extends IJsonAtivosConsistenciaIgnorados {
  _docContabilidade?: IJsonDocContabilidade;
  _selected?: boolean;
}
