export enum ETipoVisualizacao {
  RetEfeutadas,
  DocsSujeitosRet
}

export enum EEstadoRetencao {
  SujeitoRetencao,
  Retido,
  Ambos
}

export enum EOrigemContasRetencoes {
  Ambos,
  PorTerceiros,
  ATerceiros
}

export interface IRadioItemRet {
  value: number;
  label: string;
}

export interface IRetencoesStateParams {
  nif?: string;
}

export const MODULE_NAME_RETENCOES = 'retencoes';
