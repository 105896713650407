import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {
  IJsonRHProceSalariosResultSingleProcData,
  IRHProceSalariosDgEmp,
  IRHProceSalariosEmpresa,
  IRHProceSalariosMEmpData,
  IRHProceSalariosProcExistente,
  IRHProceSalariosProcExistenteEmpregado,
  IRHProceSalariosProcMultiData,
  IRHProceSalariosProcResultItem,
  IRHProceSalariosProcSingleData,
  IRHProceSalariosReProcResult
} from './proceSalarios.module.interface';
import {HttpResponse} from '@angular/common/http';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {TDate} from '../../../../common/dates';

@Injectable({
  providedIn: 'root'
})
export class ProceSalariosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${_apiService.path.restapi}/rhprocesalarios`;
  }

  public getEmpresas(): Promise<Array<IRHProceSalariosEmpresa>> {
    return new Promise<Array<IRHProceSalariosEmpresa>>((resolve, reject) => {
      this._apiService
        .get<Array<IRHProceSalariosEmpresa>>({
          url: `${this._path}/getempresas`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public processarMulti(data: IRHProceSalariosProcMultiData): TServiceResponse<Array<IRHProceSalariosProcResultItem>> {
    return this._apiService.post({
      url: `${this._path}/processarMulti`,
      body: data
    });
  }

  public getDatas(ano: number): Promise<Array<IRHProceSalariosMEmpData>> {
    return new Promise<Array<IRHProceSalariosMEmpData>>((resolve, reject) => {
      this._apiService
        .get<Array<IRHProceSalariosMEmpData>>({
          url: `${this._path}/getdatas/${ano}`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public getEmpregados(nEmpresa: string, tipoProcessamento: number, dataInicio: TDate, dataFim: TDate): Promise<Array<IRHProceSalariosDgEmp>> {
    return new Promise<Array<IRHProceSalariosDgEmp>>((resolve, reject) => {
      this._apiService
        .get<Array<IRHProceSalariosDgEmp>>({
          url: `${this._path}/get-empregados`,
          params: {
            nEmpresa: nEmpresa,
            tipoProcessamento: tipoProcessamento,
            dataInicoProc: dataInicio,
            dataFimProce: dataFim
          }
        })
        .then((response: HttpResponse<Array<IRHProceSalariosDgEmp>>) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public processarSingle(data: IRHProceSalariosProcSingleData): TServiceResponse<IJsonRHProceSalariosResultSingleProcData> {
    return this._apiService.post({
      url: `${this._path}/processarSingle`,
      body: data
    });
  }

  public getProceExistentes(year: number, month: number, nEmpresa: string): Promise<Array<IRHProceSalariosProcExistente>> {
    return new Promise<Array<IRHProceSalariosProcExistente>>((resolve, reject) => {
      this._apiService
        .get<Array<IRHProceSalariosProcExistente>>({
          url: `${this._path}/get-proc-existentes/${year}/${month}?nEmpresa=${nEmpresa}`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public openProce(nProc: number, nEmpresa: string): Promise<Array<IRHProceSalariosProcExistenteEmpregado>> {
    return new Promise<Array<IRHProceSalariosProcExistenteEmpregado>>((resolve, reject) => {
      this._apiService
        .get<Array<IRHProceSalariosProcExistenteEmpregado>>({
          url: `${this._path}/open-proc/${nProc}?nEmpresa=${nEmpresa}`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public removeEmpregadoProc(nProc: number, codEmpIds: Array<number>, nEmpresa: string): TServiceResponse<void> {
    return this._apiService.delete({
      url: `${this._path}/delete-proc-empregado/${nProc}?nEmpresa=${nEmpresa}`,
      body: codEmpIds
    });
  }

  public addEmpregadosProc(nProc: number, codEmpIds: Array<number>, nEmpresa: string): TServiceResponse<Array<IRHProceSalariosProcExistenteEmpregado>> {
    return this._apiService.put<Array<IRHProceSalariosProcExistenteEmpregado>, Array<number>>({
      url: `${this._path}/add-proc-empregado/${nProc}?nEmpresa=${nEmpresa}`,
      body: codEmpIds
    });
  }

  public reProcEmpregado(nProc: number, codEmp: number, nEmpresa: string): Promise<IRHProceSalariosReProcResult> {
    return new Promise<IRHProceSalariosReProcResult>((resolve, reject) => {
      this._apiService
        .post<IRHProceSalariosReProcResult>({
          url: `${this._path}/re-proc-empregado/${nProc}/${codEmp}?nEmpresa=${nEmpresa}`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public reProcEncargos(nProc: number, codEmp: number, nEmpresa: string): Promise<IRHProceSalariosReProcResult> {
    return new Promise<IRHProceSalariosReProcResult>((resolve, reject) => {
      this._apiService
        .post<IRHProceSalariosReProcResult>({
          url: `${this._path}/re-proc-encargos/${nProc}/${codEmp}?nEmpresa=${nEmpresa}`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public deleteProcess(nProc: number, nEmpresa: string): TServiceResponse<void> {
    return this._apiService.delete({
      url: `${this._path}/delete-proc/${nProc}?nEmpresa=${nEmpresa}`
    });
  }
}
