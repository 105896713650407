<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title">{{ modalTitle }}</h4>
</div>

<div class="modal-body">
  <div [plPromise]="promise">
    <split-viewer-doccontab-digital [(docContabilidade)]="doc" [contabDigitalDoc]="contabDigitalDoc" [showToolbar]="false" [maintenanceMode]="true" [readonlyDigital]="true">
    </split-viewer-doccontab-digital>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
