import {Subscription} from 'rxjs';
import {Component, Input, OnDestroy} from '@angular/core';
import {TNgClassSupportedTypes} from 'pl-comps-angular';
import {AppService} from '../../../../services/app/app.service';
import {EAppTheme, IAppTheme, LOGO_DARK, LOGO_LIGHT} from '../../../../../common/themes/themes.interface';

@Component({
  selector: 'cg-app-logo',
  templateUrl: './cg.app.logo.component.html'
})
export class CGAppLogoComponent implements OnDestroy {
  @Input() public cssClass: TNgClassSupportedTypes;
  public logoSrc: string;

  private readonly _subscriptionTheme: Subscription;

  constructor(private readonly _appService: AppService) {
    this.logoSrc = LOGO_LIGHT;
    this._subscriptionTheme = this._appService.theme().subscribe((theme: IAppTheme) => {
      this._evaluateDefaultLogo(theme);
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionTheme.unsubscribe();
  }

  private _evaluateDefaultLogo({theme, prefersColorSchemeDark}: IAppTheme): void {
    if (theme === EAppTheme.Auto) {
      theme = prefersColorSchemeDark ? EAppTheme.Dark : EAppTheme.Light;
    }
    switch (theme) {
      case EAppTheme.Light:
        this.logoSrc = LOGO_LIGHT;
        break;
      case EAppTheme.Dark:
        this.logoSrc = LOGO_DARK;
        break;
    }
  }
}
