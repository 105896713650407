import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_QPSIT} from './qpsit.entity.interface';
import {IJsonQPSIT} from './jsonQPSIT.entity.interface';

export const ENTITY_QPSIT: IEntityDefinition<IJsonQPSIT> = {
  name: ENTITY_NAME_QPSIT,
  roles: [ROLE.RH],
  pageTitle: 'global.menu.qpsit',
  searchPlaceholder: 'qpsit.pesquisa',
  metadata: {
    keyName: 'codSituacao',
    fields: [
      {name: 'codSituacao', caption: 'qpsit.fields.codsituacao', placeholder: 'qpsit.fields.codsituacao', validators: {required: true, maxlength: 10}},
      {name: 'nome', caption: 'qpsit.fields.nome', placeholder: 'qpsit.fields.nome', validators: {required: true, maxlength: 150}}
    ],
    order: 'codSituacao',
    searchFields: 'codSituacao,nome'
  },
  autocomplete: {
    rowTemplate: '{{codSituacao}} - {{nome}}',
    output: 'nome',
    searchFields: 'codSituacao,nome'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    search: true,
    delete: false,
    filter: true
  }
};
