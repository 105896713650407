import {ENTITY_NAME_PRH_LOGS} from './pRHLogs.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonPRHLog} from './jsonPRHLog.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_PRH_LOGS: IEntityDefinition<IJsonPRHLog> = {
  name: ENTITY_NAME_PRH_LOGS,
  asModule: false,
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  metadata: {
    keyName: 'IdLog',
    descriptionName: 'motivo',
    fields: [{name: 'motivo'}, {name: 'data'}],
    order: 'motivo',
    searchFields: 'idLog,motivo'
  },
  autocomplete: {
    rowTemplate: '{{idLog}} - {{motivo}}',
    output: 'motivo',
    searchFields: 'idLog,motivo'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  }
};
