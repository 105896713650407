import type dxDataGrid from 'devextreme/ui/data_grid';
import {CGModalComponent} from '../../../../cg/modal/cgmodal.component';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {IJsonDocSatEncomLin, IJsonDocSatEncomLinLote} from '../../../../../modules/encomendas/jsonEncomenda.module.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IDevExpressDataGrid} from '../../../../devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonArtigoLoteArmazemStock, IJsonLote} from '../../../../../entities/lotes/jsonLote.entity.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {ENTITY_NAME_LOTES} from '../../../../../entities/lotes/lotes.entity.interface';
import {THttpQueryResponse} from '../../../../../services/api/api.service.interface';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnEditingStart,
  IDevExpressDataGridEventOnFocusedCellChanged,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {StateService} from '@uirouter/core';
import {CUSTO_MEDIO_PONDERADO} from '../../../../../datasources/criteriodisponiveis/criterioDisponiveis.datasource.interface';
import {GrupoDocService} from '../../../../../services/grupodoc/grupo.doc.service';
import {preencheAutomaticamenteLotesNaLinha, temLinhasComLotes} from '../../../../../modules/encomendas/encomendas.module.utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'encomendas-lotes-modal',
  templateUrl: './encomendas.lotes.modal.component.html'
})
export class EncomendasLotesModalComponent extends CGModalComponent<Array<IJsonDocSatEncomLin>> implements OnInit {
  @Input() public linhas: Array<IJsonDocSatEncomLin>;

  public dataGridLinhasDefinition: IDevExpressDataGrid<IJsonDocSatEncomLin, number>;
  public dataGridLotesDefinition: IDevExpressDataGrid<IJsonLote, number>;

  private readonly _lotes: IEntityService<IJsonLote>;
  private readonly _nDecimaisQtd: number;
  private readonly _grupoDocfa: number;

  private _lotesLinha: Array<IJsonDocSatEncomLinLote>;
  private _dataGridLotesInstance: dxDataGrid;
  private _rowIndex: number;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _stateService: StateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _grupoDoc: GrupoDocService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this._lotes = this._entityServiceBuilder.build<IJsonLote>(ENTITY_NAME_LOTES);
    this._lotesLinha = [];

    this.dataGridLinhasDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'facliId', dataType: 'number', caption: 'encomendas.table.facliId', visible: false, allowEditing: false},
        {dataField: 'nDoc', dataType: 'string', caption: 'encomendas.table.nDoc', allowEditing: false},
        {dataField: 'dataDoc', dataType: 'date', caption: 'encomendas.table.dataDoc', allowEditing: false},
        {dataField: 'nArtigo', dataType: 'string', caption: 'encomendas.table.nArtigo', allowEditing: false},
        {dataField: 'nomeArtigo', dataType: 'string', caption: 'encomendas.table.nomeArtigo', allowEditing: false},
        {dataField: 'nArmaz', dataType: 'string', caption: 'encomendas.table.nArmaz', allowEditing: false},
        {dataField: 'nomeArmazem', dataType: 'string', caption: 'encomendas.table.nomeArmaz', visible: false, allowEditing: false},
        {dataField: 'prVendaQtd1', dataType: 'double', caption: 'encomendas.table.prVendaQtd1', allowEditing: false},
        {dataField: 'ligacaoQtd', dataType: 'number', caption: 'encomendas.table.ligacaoQtd', allowEditing: false}
      ],
      dataSource: [],
      keyExpr: 'facliId',
      selection: {mode: 'single', showCheckBoxesMode: 'none'},
      export: {enabled: false},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      columnChooser: {enabled: false},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateBtnToolbar',
            locateInMenu: 'auto'
          }
        ]
      }
    };

    this._nDecimaisQtd = this._configService.configurations.gestaoComercial.decimais.stocks.quantidades;

    this.dataGridLotesDefinition = {
      allowColumnReordering: false,
      columnHidingEnabled: true,
      columnChooser: {mode: 'select'},
      columns: [
        {dataField: 'nLote', dataType: 'number', caption: 'lotes.fields.nLote', visible: false, allowEditing: false, showInColumnChooser: false},
        {dataField: 'nLoteEspecifico', dataType: 'string', caption: 'lotes.fields.nLoteEspecifico', allowEditing: false, showInColumnChooser: false},
        {dataField: 'dataFabrico', dataType: 'date', caption: 'lotes.fields.dataFabrico', visible: false, allowEditing: false},
        {dataField: 'dataValidade', dataType: 'date', caption: 'lotes.fields.dataValidade', visible: false, allowEditing: false},
        {dataField: 'qtdStock', dataType: 'double', caption: 'docscomerciais.doc.linhas.qtdStock', visible: true, format: {decimalsLimit: this._nDecimaisQtd}, allowEditing: false},
        {dataField: 'qtd', dataType: 'double', caption: 'docscomerciais.doc.linhas.qtd', format: {decimalsLimit: this._nDecimaisQtd}, showEditorAlways: true, showInColumnChooser: false}
      ],
      dataSource: [],
      keyExpr: 'nLote',
      toolbar: {visible: true},
      filterRow: {visible: false},
      editing: {allowUpdating: true, mode: 'cell', selectTextOnEditStart: true},
      export: {enabled: false},
      remoteOperations: false,
      keyboardNavigation: {
        enabled: true,
        enterKeyAction: 'moveFocus',
        enterKeyDirection: 'column'
      }
    };
  }

  public ngOnInit(): void {
    const linhas: Array<IJsonDocSatEncomLin> = [];
    for (const linha of this.linhas) {
      if (linha.ligacaoQtd > 0 && linha.temLote && linha.codValoriz === CUSTO_MEDIO_PONDERADO) {
        linhas.push(linha);
      }
    }
    this.dataGridLinhasDefinition.dataSource = linhas;
  }

  public close(): void {
    const idx = this.linhas.findIndex((item: IJsonDocSatEncomLin) => item.ligacaoQtd > 0 && item.temLote && item.codValoriz === CUSTO_MEDIO_PONDERADO && (!item.lotes || item.lotes.length === 0));
    if (idx > 0) {
      this._plAlertService.error('encomendas.modal.lotes.messages.existemLinhasSemLotesAtribuidos');
      return;
    }

    for (const linha of this.linhas) {
      if (linha.ligacaoQtd > 0 && linha.temLote && linha.codValoriz === CUSTO_MEDIO_PONDERADO) {
        let qtdLotes = 0;
        for (const lote of linha.lotes) {
          qtdLotes += lote.qtd;
        }
        if (linha.ligacaoQtd !== qtdLotes) {
          this._plAlertService.error(
            this._translateService.instant('encomendas.modal.lotes.messages.qtdLotesNaoEIgualLinha', {qtdLotes: qtdLotes, nArtigo: linha.nArtigo, ligacaoQtd: linha.ligacaoQtd})
          );
          return;
        }
      }
    }

    super.close(this.linhas);
  }

  public preencheAutomaticamenteLotes(): Promise<void> {
    if (this._grupoDoc.isCompra(this._grupoDocfa)) {
      this._plAlertService.error('encomendas.modal.processamento.messages.notAutoSelLotesEntradaStock');
      return Promise.reject(new Error('encomendas.modal.processamento.messages.notAutoSelLotesEntradaStock'));
    }

    if (this.linhas.length === 0) {
      this._plAlertService.error('encomendas.modal.processamento.messages.naoTemLinhas');
      return Promise.reject(new Error('encomendas.modal.processamento.messages.naoTemLinhas'));
    }

    const isCompra = this._grupoDoc.isCompra(this._grupoDocfa);
    if (!temLinhasComLotes(this.linhas, isCompra)) {
      this._plAlertService.error('encomendas.modal.processamento.messages.naoTemLinhasComLotes');
      return Promise.reject(new Error('encomendas.modal.processamento.messages.naoTemLinhasComLotes'));
    }

    for (const linha of this.linhas) {
      if (linha.ligacaoQtd > 0 && linha.temLote && linha.codValoriz === CUSTO_MEDIO_PONDERADO) {
        if (linha.lotes?.length > 0) {
          for (const lote of linha.lotes) {
            lote.qtd = 0;
          }
          preencheAutomaticamenteLotesNaLinha(linha, this._nDecimaisQtd);
        } else {
          this._getLotesArtigoLinha(linha, true);
        }
      }
    }
    return Promise.resolve();
  }

  public onGridLotesInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridLotesInstance = component;
    this._dataGridLotesInstance.columnOption('qtd', 'showEditorAlways', true);
    this._dataGridLotesInstance.columnOption('qtdStock', 'visible', true);
  }

  public onGridLotesContentReady(): void {
    this._dataGridLotesInstance.endCustomLoading();
    if (this._lotesLinha.length > 0) {
      this._dataGridLotesInstance.columnOption('qtd', 'showEditorAlways', true);
      this._dataGridLotesInstance.editCell(this._rowIndex ? this._rowIndex : 0, 'qtd');
    }
  }

  public onGridLotesFocusedCellChanged(event: IDevExpressDataGridEventOnFocusedCellChanged<IJsonDocSatEncomLinLote>): void {
    this._rowIndex = event.row.rowIndex ? event.row.rowIndex : 0;
  }

  public onGridLotesCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonDocSatEncomLin, number>): void {
    if (event.rowType === 'data' && event.column.dataField === 'qtd') {
      event.cellElement.classList.add('datagrid-editable-cell');
    }
  }

  public onGridLinhasEditorStart(event: IDevExpressDataGridEventOnEditingStart<IJsonDocSatEncomLin, number>): void {
    event.component.selectRows([event.key], false);
  }

  public onGridLinhasSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IJsonDocSatEncomLin>): void {
    for (const facliId of event.currentSelectedRowKeys) {
      const idx = this.linhas.findIndex((item: IJsonDocSatEncomLin) => item.facliId === facliId);
      const linhaEnc = this.linhas[idx];
      if (linhaEnc.lotes?.length > 0) {
        this.dataGridLotesDefinition.dataSource = linhaEnc.lotes;
      } else {
        this._getLotesArtigoLinha(linhaEnc);
      }
    }
  }

  private _getLotesArtigoLinha(linha: IJsonDocSatEncomLin, preencheAutomaticamente: boolean = false): void {
    if (!linha?.nArtigo) {
      this._lotesLinha = [];
      this.dataGridLotesDefinition.dataSource = this._lotesLinha;
      return;
    }
    const search = `nArtigo=${linha.nArtigo}&terminado=0`;
    this._lotes.query({pesquisa: search}).then((response: THttpQueryResponse<IJsonLote>) => {
      if (response) {
        const lotes: Array<IJsonLote> = response.body.list;

        if (lotes.length > 0) {
          if (this._lotesLinha.length > 0) {
            this._lotesLinha = [];
          }
          for (const item of lotes) {
            const stock = item?.stockArmazens?.find((stockArmaz: IJsonArtigoLoteArmazemStock) => stockArmaz?.nArmazem === linha.nArmaz);
            const qtdStock = stock ? stock.qtd : 0;
            this._lotesLinha.push({nLote: item.nLote, dataFabrico: item.dataFabrico, dataValidade: item.dataValidade, nLoteEspecifico: item.nLoteEspecifico, qtd: 0, qtdStock: qtdStock});
          }
          this.dataGridLotesDefinition.dataSource = this._lotesLinha;
          linha.lotes = this._lotesLinha;
          if (preencheAutomaticamente) {
            preencheAutomaticamenteLotesNaLinha(linha, this._nDecimaisQtd);
          }
        }
      }
    });
  }
}
