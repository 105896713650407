import {IJsonUserNewWithAccesses, IJsonUserRole} from '../../services/account/jsonUserApi.interface';
import {ROLE} from '../../services/role.const';

export interface IUtilizadoresUserRole extends IJsonUserRole {
  depends: Array<ROLE>;
  hidden: boolean;
}

export interface IUtilizadoresUserNewWithAccesses extends IJsonUserNewWithAccesses {
  administrator: boolean;
}

export const ENTITY_NAME_USERS = 'users';
