<div class="new-previ-tes-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'prevites.manual.new.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <pl-group>
        <label [translate]="'prevites.manual.new.rubrica'"></label>
        <edit>
          <entity-autocomplete
            entity="tesrubrica"
            attrName="codRubrica"
            [model]="newPrevites"
            (selectedKeyChange)="newPrevites.codRubrica = $event"
            [properties]="{validators: {required: {value: true}}}"
            [fieldsMap]="{tesRubricaID: 'codRubrica'}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <edit>
          <label [translate]="'global.text.date'"></label>
          <pl-edit type="date" attrName="data" [(model)]="newPrevites.data" [properties]="{validators: {required: {value: true}}}"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <edit>
          <label [translate]="'prevites.manual.new.descricao'"></label>
          <pl-edit type="text" attrName="descricao" [(model)]="newPrevites.descricao"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <edit>
          <label [translate]="'prevites.manual.new.valor'"></label>
          <pl-edit type="number" attrName="valor" [(model)]="newPrevites.valor" [properties]="{validators: {required: {value: true}}}"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>

    <div class="card mt-2">
      <div class="card-header fw-bold pesquisa-avançada">
        <span [translate]="'prevites.manual.new.cardtitle'"></span>
      </div>
      <div class="card-body">
        <pl-group>
          <label [translate]="'prevites.manual.new.periodicas'"></label>
          <edit>
            <pl-edit type="boolean" attrName="dataAbateAte" [(model)]="newPrevites.ocorrFinalPeriodo" [properties]="{label: 'prevites.manual.new.ocrrfimperio'}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'prevites.manual.new.periodicidade'"></label>
            <edit>
              <pl-edit type="tesourariaperiodicidade" attrName="periodicidade" [(model)]="newPrevites.periodicidade"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'prevites.manual.new.ocorrencias'"></label>
            <edit>
              <pl-edit type="number" attrName="ocorrencias" [(model)]="newPrevites.numOcorr"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-sm btn-success action-submit" id="save" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-sm btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
