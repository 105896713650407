<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'gto.executarTarefa'"></h4>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <pl-group>
        <label [translate]="'gto.estado'"></label>
        <edit>
          <pl-edit type="radiogroup" [model]="model.state" (modelChange)="onStateChange($event)" [properties]="{groupItems: executeStates, validators: {required: {value: true}}}"></pl-edit>
        </edit>
      </pl-group>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6" *ngIf="model.state !== states.New">
      <pl-group>
        <label [translate]="'gto.valor'"></label>
        <edit>
          <pl-edit type="text" [(model)]="model.valor"></pl-edit>
        </edit>
      </pl-group>
    </div>
    <div class="col-md-6" *ngIf="model.state === states.Concluir || model.state === states.AdicionarJustificacao">
      <pl-group>
        <label [translate]="'gto.dataExecucao'"></label>
        <edit>
          <pl-edit-datepicker [(model)]="model.execDate"></pl-edit-datepicker>
        </edit>
      </pl-group>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12" *ngIf="model.state === states.AdicionarJustificacao">
      <pl-group>
        <label [translate]="'gto.justificacao'"></label>
        <edit>
          <textarea [rows]="2" class="form-control" [(ngModel)]="model.justificacao"></textarea>
        </edit>
      </pl-group>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <pl-group>
        <label [translate]="'global.text.note'"></label>
        <edit>
          <textarea [rows]="2" class="form-control" [(ngModel)]="model.nota"></textarea>
        </edit>
      </pl-group>
    </div>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-primary" [click]="fnExecuteTask" plPromise><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></button>
  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
