import {Component, Injector, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../../components/module/module.component';
import {
  ERHRegistarEmpregadosSSContratoModalidadeEnum,
  ERHRegistarEmpregadosSSContratoMotivoEnum,
  ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum,
  ERHRegistarEmpregadosSSEmpSegSocialVinculo,
  ERHRegistarEmpregadosSSMotivoCessacao,
  ERHRegistarEmpregadosSSRegistarTabIdEnum,
  IRHRegistarEmpregadosSSCessarVinculoEmpregadoSSData,
  IRHRegistarEmpregadosSSContratosBase,
  IRHRegistarEmpregadosSSContratosDataGridItem,
  IRHRegistarEmpregadosSSContratosItem,
  IRHRegistarEmpregadosSSEditarContratoModel,
  IRHRegistarEmpregadosSSEmpregadoInfo,
  IRHRegistarEmpregadosSSFilterModel,
  IRHRegistarEmpregadosSSParams,
  IRHRegistarEmpregadosSSRegistarEmpregadoSSData,
  IRHRegistarEmpregadosSSRegistarPeriodoRendimentoData,
  IRHRegistarEmpregadosSSUIHelper,
  IRHRegistarEmpregadosSSVinculosEmpregadoItem
} from '../rhRegistarEmpregadosSS.module.interface';
import {MAX_DATE_CG, MAX_SMALL_INT, MIN_DATE_CG} from '../../../../../common/utils/utils';
import moment, {Moment} from 'moment';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {RHRegistarEmpregadosSSService} from '../rhRegistarEmpregadosSS.module.service';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {isDefinedNotNull, PlAlertService} from 'pl-comps-angular';
import {IJsonDGEMP} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {RHRegistarEmpregadosSSEditarContratoModalComponent} from '../modals/editarcontrato/rhRegistarEmpregadosSS.editarContrato.modal.component';
import {merge} from 'lodash-es';
import {ConfigLoginWsSSModalComponent} from '../../../segsocial/modals/loginWsSS.modal.component';

const CONSULTAR_VERIFICAR_EMPREGADOS_TAB_ID = 'tabConsultarVerificarEmpregados';
const CONSULTAR_VERIFICAR_CONTRATOS_TAB_ID = 'tabConsultarVerificarContratos';
const REGISTAR_EMPREGADO_TAB_ID_PREFIX = 'tabRegistarEmpregado';
const BTN_CONFIG_ID = 'toolbar-config-ws-button';
const EXCEPTION_CONFIG_WS_CREDENTIALS = 'EObjRHEmpregadosExternalCommunicationsWSCredentials';
const DAY_INTERVAL = 15;
const LETTERS_LEN_FOUR = 4;
const MAIN_CONSULTAR_TAB_ID = 'mainTabConsultar';
const MAIN_REGISTAR_TAB_ID = 'mainTabRegistar';
const UNAUTHORIZED_STRING = 'Unauthorized';

@Component({
  selector: 'rh-registar-empregados-ss-module',
  templateUrl: './rhRegistarEmpregadosSS.module.component.html'
})
export class RHRegistarEmpregadosSSModuleComponent extends ModuloComponent implements OnInit {
  public filtersModel: IRHRegistarEmpregadosSSFilterModel;
  public verificarEmpregadosDataGridDefinition: IDevExpressDataGrid;
  public verificarRegistarContratosDataGridDefinition: IDevExpressDataGrid;
  public verificarEmpregadosDataGridDS: Array<IRHRegistarEmpregadosSSVinculosEmpregadoItem>;
  public verificarRegistarContratosDataGridDS: Array<IRHRegistarEmpregadosSSContratosDataGridItem>;
  public consultarActiveTabId: string;
  public mainActiveTabId: string;
  public consultarVerificarEmpregadosTabId: string;
  public consultarVerificarContratosTabId: string;
  public mainConsultarTabId: string;
  public mainRegistarTabId: string;
  public registarEmpregado: IJsonDGEMP;
  public registarCodEmpFilter: string;
  public registarActiveTabId: string;
  public registarEmpregadosRegistarTabId: string;
  public registarEmpregadosCessarTabId: string;
  public registarEmpregadosPerioRendTabId: string;
  public promiseLoadEmpregado: Promise<unknown>;
  public registarModel: IRHRegistarEmpregadosSSEmpregadoInfo;
  public modalidadeList: Array<{value: ERHRegistarEmpregadosSSContratoModalidadeEnum; name: string}>;
  public motivoCtrList: Array<{value: ERHRegistarEmpregadosSSContratoMotivoEnum; name: string}>;
  public prestacaoTrabalhoList: Array<{value: ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum; name: string}>;
  public ui: IRHRegistarEmpregadosSSUIHelper;
  public motivoCessacaoList: Array<{value: ERHRegistarEmpregadosSSMotivoCessacao; name: string}>;
  private _verificarRegistarContratosDataGridInstance: dxDataGrid;
  private readonly _motivoCtrTermoCertoList: Array<{value: ERHRegistarEmpregadosSSContratoMotivoEnum; name: string}>;
  private readonly _motivoCtrTermoInCertoList: Array<{value: ERHRegistarEmpregadosSSContratoMotivoEnum; name: string}>;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _rhRegistarEmpregadosSSService: RHRegistarEmpregadosSSService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);
    const params: IRHRegistarEmpregadosSSParams = <IRHRegistarEmpregadosSSParams>this._transition.params();

    this.consultarVerificarEmpregadosTabId = CONSULTAR_VERIFICAR_EMPREGADOS_TAB_ID;
    this.consultarVerificarContratosTabId = CONSULTAR_VERIFICAR_CONTRATOS_TAB_ID;
    this.consultarActiveTabId = CONSULTAR_VERIFICAR_EMPREGADOS_TAB_ID;
    this.mainConsultarTabId = MAIN_CONSULTAR_TAB_ID;
    this.mainRegistarTabId = MAIN_REGISTAR_TAB_ID;
    this.mainActiveTabId = isDefinedNotNull(params.registerTabActive) && params.registerTabActive ? MAIN_REGISTAR_TAB_ID : MAIN_CONSULTAR_TAB_ID;
    this.verificarEmpregadosDataGridDS = [];
    this.verificarRegistarContratosDataGridDS = [];
    this.registarEmpregadosRegistarTabId = `${REGISTAR_EMPREGADO_TAB_ID_PREFIX}-${ERHRegistarEmpregadosSSRegistarTabIdEnum.Registar}`;
    this.registarEmpregadosCessarTabId = `${REGISTAR_EMPREGADO_TAB_ID_PREFIX}-${ERHRegistarEmpregadosSSRegistarTabIdEnum.Cessar}`;
    this.registarEmpregadosPerioRendTabId = `${REGISTAR_EMPREGADO_TAB_ID_PREFIX}-${ERHRegistarEmpregadosSSRegistarTabIdEnum.RegistarPeriodoRendimento}`;
    this.filtersModel = {
      dataIni: moment().subtract(DAY_INTERVAL, 'days'),
      dataFim: moment(),
      deCodEmp: isDefinedNotNull(params.dgemp) ? params.dgemp.codEmp : 1,
      ateCodEmp: isDefinedNotNull(params.dgemp) ? params.dgemp.codEmp : MAX_SMALL_INT
    };

    this.registarCodEmpFilter = `nempresa=${this.session.erp.nEmpresa}`;
    this.verificarEmpregadosDataGridDefinition = {
      columns: [
        {
          caption: 'rhregistarempregadosss.strings.dados',
          columns: [
            {dataField: 'codemp', caption: 'rhregistarempregadosss.strings.codemp'},
            {dataField: 'niss', caption: 'rhregistarempregadosss.strings.niss'},
            {dataField: 'nometrabalhador', caption: 'rhregistarempregadosss.strings.nome'},
            {dataField: 'datanascimento', caption: 'rhregistarempregadosss.strings.datanascimento', dataType: 'date'}
          ],
          fixed: true,
          fixedPosition: 'left'
        },
        {
          caption: 'rhregistarempregadosss.strings.vinculo',
          columns: [
            {dataField: 'vinculo', caption: 'rhregistarempregadosss.strings.vinculo'},
            {
              dataField: 'vinculocomunicadoem',
              caption: 'rhregistarempregadosss.strings.vinculocomunicadoem',
              dataType: 'date',
              calculateDisplayValue: (rowData: IRHRegistarEmpregadosSSVinculosEmpregadoItem) => {
                if (!rowData.vinculocomunicadoem) {
                  return '';
                }
                const vinculocomunicadoem: Moment = moment(rowData.vinculocomunicadoem);
                if (vinculocomunicadoem.isSameOrBefore(MIN_DATE_CG, 'date')) {
                  return '';
                }
                return vinculocomunicadoem.format(this.format.momentDate);
              }
            },
            {
              dataField: 'iniciodoVinculo',
              caption: 'rhregistarempregadosss.strings.iniciodoVinculo',
              dataType: 'date',
              calculateDisplayValue: (rowData: IRHRegistarEmpregadosSSVinculosEmpregadoItem) => {
                if (!rowData.iniciodoVinculo) {
                  return '';
                }
                const iniciodoVinculo: Moment = moment(rowData.iniciodoVinculo);
                if (iniciodoVinculo.isSameOrBefore(MIN_DATE_CG, 'date')) {
                  return '';
                }
                return iniciodoVinculo.format(this.format.momentDate);
              }
            },
            {
              dataField: 'fimdoVinculo',
              caption: 'rhregistarempregadosss.strings.fimdoVinculo',
              dataType: 'date',
              calculateDisplayValue: (rowData: IRHRegistarEmpregadosSSVinculosEmpregadoItem) => {
                if (!rowData.dataFimVinculo) {
                  return '';
                }
                const fimdoVinculo: Moment = moment(rowData.fimdoVinculo);
                if (fimdoVinculo.isSameOrBefore(MIN_DATE_CG, 'date')) {
                  return '';
                }
                return fimdoVinculo.format(this.format.momentDate);
              }
            }
          ]
        },
        {
          caption: 'rhregistarempregadosss.strings.taxas',
          columns: [
            {
              dataField: 'inicioaplicacaoTaxa',
              caption: 'rhregistarempregadosss.strings.inicioaplicacaoTaxa',
              dataType: 'date',
              calculateDisplayValue: (rowData: IRHRegistarEmpregadosSSVinculosEmpregadoItem) => {
                if (!rowData.inicioaplicacaoTaxa) {
                  return '';
                }
                const inicioaplicacaoTaxa: Moment = moment(rowData.inicioaplicacaoTaxa);
                if (inicioaplicacaoTaxa.isSameOrBefore(MIN_DATE_CG, 'date')) {
                  return '';
                }
                return inicioaplicacaoTaxa.format(this.format.momentDate);
              }
            },
            {
              dataField: 'fimaplicacaotaxa',
              caption: 'rhregistarempregadosss.strings.fimaplicacaotaxa',
              dataType: 'date',
              calculateDisplayValue: (rowData: IRHRegistarEmpregadosSSVinculosEmpregadoItem) => {
                if (!rowData.fimaplicacaotaxa) {
                  return '';
                }
                const fimaplicacaotaxa: Moment = moment(rowData.fimaplicacaotaxa);
                if (fimaplicacaotaxa.isSameOrBefore(MIN_DATE_CG, 'date')) {
                  return '';
                }
                return fimaplicacaotaxa.format(this.format.momentDate);
              }
            },
            {
              dataField: 'valortaxa',
              caption: 'rhregistarempregadosss.strings.valortaxa',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            }
          ]
        },
        {
          caption: 'rhregistarempregadosss.strings.localTrabalho',
          columns: [{dataField: 'localdetrabalho', caption: 'rhregistarempregadosss.strings.localTrabalho'}]
        }
      ],
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarButton',
            locateInMenu: 'auto'
          }
        ]
      }
    };

    this.verificarRegistarContratosDataGridDefinition = {
      selection: {mode: 'multiple', selectAllMode: 'allPages', showCheckBoxesMode: 'always'},
      columns: [
        {
          caption: 'rhregistarempregadosss.strings.empregado',
          columns: [
            {
              dataField: 'vf_actions',
              caption: ' ',
              cellTemplate: 'actions',
              allowHiding: false,
              allowSearch: false,
              allowReordering: false,
              allowSorting: false,
              allowFiltering: false,
              allowResizing: false,
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowFixing: false,
              allowEditing: false,
              allowExporting: false
            },
            {dataField: 'codemp', caption: 'rhregistarempregadosss.strings.codemp'},
            {
              dataField: 'isContratoEnviadoParaSS',
              caption: 'rhregistarempregadosss.strings.isContratoEnviadoParaSS',
              calculateDisplayValue: (rowData: IRHRegistarEmpregadosSSContratosDataGridItem) => {
                return rowData.isContratoEnviadoParaSS ? 'Enviado' : 'Não Enviado';
              }
            },
            {dataField: 'nissTrabalhador', caption: 'rhregistarempregadosss.strings.niss'},
            {dataField: 'nomeTrabalhador', caption: 'rhregistarempregadosss.strings.nome'},
            {dataField: 'modalidadeString', caption: 'rhregistarempregadosss.strings.modalidade'},
            {dataField: 'temErros', caption: 'rhregistarempregadosss.strings.temErros', dataType: 'boolean'}
          ],
          fixed: true,
          fixedPosition: 'left'
        },
        {
          caption: 'rhregistarempregadosss.strings.contratos',
          columns: [
            {dataField: 'prestacaoTrabalhoString', caption: 'rhregistarempregadosss.strings.prestacaoTrabalho'},
            {
              dataField: 'inicioContrato',
              caption: 'rhregistarempregadosss.strings.inicioContrato',
              dataType: 'date',
              calculateDisplayValue: (rowData: IRHRegistarEmpregadosSSContratosDataGridItem) => {
                if (!rowData.inicioContrato) {
                  return '';
                }
                const inicioContrato: Moment = moment(rowData.inicioContrato);
                if (inicioContrato.isSameOrBefore(MIN_DATE_CG, 'date')) {
                  return '';
                }
                return inicioContrato.format(this.format.momentDate);
              }
            },
            {
              dataField: 'fimContrato',
              caption: 'rhregistarempregadosss.strings.fimContrato',
              dataType: 'date',
              calculateDisplayValue: (rowData: IRHRegistarEmpregadosSSContratosDataGridItem) => {
                if (!rowData.fimContrato) {
                  return '';
                }
                const fimContrato: Moment = moment(rowData.fimContrato);
                if (fimContrato.isSameOrBefore(MIN_DATE_CG, 'date') || fimContrato.isSameOrAfter(MAX_DATE_CG, 'date')) {
                  return '';
                }
                return fimContrato.format(this.format.momentDate);
              }
            },
            {
              dataField: 'inicioInformacaoContrato',
              caption: 'rhregistarempregadosss.strings.inicioInformacao',
              dataType: 'date',
              calculateDisplayValue: (rowData: IRHRegistarEmpregadosSSContratosDataGridItem) => {
                if (!rowData.inicioInformacaoContrato) {
                  return '';
                }
                const inicioInformacaoContrato: Moment = moment(rowData.inicioInformacaoContrato);
                if (inicioInformacaoContrato.isSameOrBefore(MIN_DATE_CG, 'date') || inicioInformacaoContrato.isSameOrAfter(MAX_DATE_CG, 'date')) {
                  return '';
                }
                return inicioInformacaoContrato.format(this.format.momentDate);
              }
            },
            {
              dataField: 'fimInformacaoContrato',
              caption: 'rhregistarempregadosss.strings.fimInformacao',
              dataType: 'date',
              calculateDisplayValue: (rowData: IRHRegistarEmpregadosSSContratosDataGridItem) => {
                if (!rowData.fimInformacaoContrato) {
                  return '';
                }
                const fimInformacaoContrato: Moment = moment(rowData.fimInformacaoContrato);
                if (fimInformacaoContrato.isSameOrBefore(MIN_DATE_CG, 'date') || fimInformacaoContrato.isSameOrAfter(MAX_DATE_CG, 'date')) {
                  return '';
                }
                return fimInformacaoContrato.format(this.format.momentDate);
              }
            }
          ]
        },
        {
          caption: 'rhregistarempregadosss.strings.dados',
          columns: [
            {dataField: 'profissaoString', caption: 'rhregistarempregadosss.strings.profissao'},
            {
              dataField: 'remuneracaoBase',
              caption: 'rhregistarempregadosss.strings.remuneracaoBase',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {
              dataField: 'diuturnidades',
              caption: 'rhregistarempregadosss.strings.diuturnidades',
              dataType: 'currency',
              format: {
                type: 'currency',
                precision: 2
              }
            },
            {dataField: 'percentagemTrabalho', caption: 'rhregistarempregadosss.strings.percentagemTrabalho'},
            {dataField: 'horasTrabalho', caption: 'rhregistarempregadosss.strings.horasTrabalho'},
            {dataField: 'diasTrabalho', caption: 'rhregistarempregadosss.strings.diasTrabalho'},
            {dataField: 'motivoString', caption: 'rhregistarempregadosss.strings.motivoTrabalho'},
            {
              dataField: 'nissTrabalhadorSubstituir',
              caption: 'rhregistarempregadosss.strings.nissTrabalhador',
              calculateDisplayValue: (rowData: IRHRegistarEmpregadosSSContratosDataGridItem) => {
                return rowData.nissTrabalhadorSubstituir === 0 ? '' : rowData.nissTrabalhadorSubstituir;
              }
            },
            {dataField: 'nomeTrabalhadorSubstituir', caption: 'rhregistarempregadosss.strings.nomeTrabalhador'},
            {dataField: 'descErros', caption: 'rhregistarempregadosss.strings.descErros'}
          ]
        }
      ],
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarButton',
            locateInMenu: 'auto'
          }
        ]
      }
    };
    this._resetRegistarModel();
    this.ui = {
      diasTrabalhoCtrSSEnabled: false,
      horasTrabalhoCtrSSEnabled: false,
      percTrabalhoCtrSSEnabled: false,
      dtValidoAteVisible: false,
      motivoCtrSSEnabled: false,
      empregadoSubsttituitSSctrEnabled: false,
      fundamentacaoCessacaoEnabled: false
    };
    this.modalidadeList = [
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.NONE, name: ''},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.A, name: 'rhregistarempregadosss.enums.modalidade.A'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.B, name: 'rhregistarempregadosss.enums.modalidade.B'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.C, name: 'rhregistarempregadosss.enums.modalidade.C'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.D, name: 'rhregistarempregadosss.enums.modalidade.D'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.E, name: 'rhregistarempregadosss.enums.modalidade.E'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.EA, name: 'rhregistarempregadosss.enums.modalidade.EA'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.EB, name: 'rhregistarempregadosss.enums.modalidade.EB'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.F, name: 'rhregistarempregadosss.enums.modalidade.F'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.FA, name: 'rhregistarempregadosss.enums.modalidade.FA'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.FB, name: 'rhregistarempregadosss.enums.modalidade.FB'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.G, name: 'rhregistarempregadosss.enums.modalidade.G'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.H, name: 'rhregistarempregadosss.enums.modalidade.H'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.I, name: 'rhregistarempregadosss.enums.modalidade.I'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.J, name: 'rhregistarempregadosss.enums.modalidade.J'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.K, name: 'rhregistarempregadosss.enums.modalidade.K'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.L, name: 'rhregistarempregadosss.enums.modalidade.L'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.M, name: 'rhregistarempregadosss.enums.modalidade.M'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.N, name: 'rhregistarempregadosss.enums.modalidade.N'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.O, name: 'rhregistarempregadosss.enums.modalidade.O'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.P, name: 'rhregistarempregadosss.enums.modalidade.P'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.Q, name: 'rhregistarempregadosss.enums.modalidade.Q'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.R, name: 'rhregistarempregadosss.enums.modalidade.R'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.S, name: 'rhregistarempregadosss.enums.modalidade.S'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.AA, name: 'rhregistarempregadosss.enums.modalidade.AA'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.AB, name: 'rhregistarempregadosss.enums.modalidade.AB'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.BA, name: 'rhregistarempregadosss.enums.modalidade.BA'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.BB, name: 'rhregistarempregadosss.enums.modalidade.BB'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.GA, name: 'rhregistarempregadosss.enums.modalidade.GA'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.GB, name: 'rhregistarempregadosss.enums.modalidade.GB'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.HA, name: 'rhregistarempregadosss.enums.modalidade.HA'},
      {value: ERHRegistarEmpregadosSSContratoModalidadeEnum.HB, name: 'rhregistarempregadosss.enums.modalidade.HB'}
    ];
    this.motivoCtrList = [];
    this.prestacaoTrabalhoList = [
      {value: ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum.P, name: 'rhregistarempregadosss.enums.prestacaoTrabalhoContrato.P'},
      {value: ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum.T, name: 'rhregistarempregadosss.enums.prestacaoTrabalhoContrato.T'}
    ];
    this._motivoCtrTermoCertoList = [
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.NONE, name: ''},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.AEAT, name: 'rhregistarempregadosss.enums.motivoContrato.AEAT'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.ATSA, name: 'rhregistarempregadosss.enums.motivoContrato.ATSA'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.CTSD, name: 'rhregistarempregadosss.enums.motivoContrato.CTSD'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.EXTO, name: 'rhregistarempregadosss.enums.motivoContrato.EXTO'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.IFEE, name: 'rhregistarempregadosss.enums.motivoContrato.IFEE'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.LNAT, name: 'rhregistarempregadosss.enums.motivoContrato.LNAT'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.STAJ, name: 'rhregistarempregadosss.enums.motivoContrato.STAJ'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.STAT, name: 'rhregistarempregadosss.enums.motivoContrato.STAT'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.STLR, name: 'rhregistarempregadosss.enums.motivoContrato.STLR'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.STTC, name: 'rhregistarempregadosss.enums.motivoContrato.STTC'}
    ];
    this._motivoCtrTermoInCertoList = [
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.NONE, name: ''},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.AEAT, name: 'rhregistarempregadosss.enums.motivoContrato.AEAT'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.ATSA, name: 'rhregistarempregadosss.enums.motivoContrato.ATSA'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.EXTO, name: 'rhregistarempregadosss.enums.motivoContrato.EXTO'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.STAJ, name: 'rhregistarempregadosss.enums.motivoContrato.STAJ'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.STAT, name: 'rhregistarempregadosss.enums.motivoContrato.STAT'},
      {value: ERHRegistarEmpregadosSSContratoMotivoEnum.STLR, name: 'rhregistarempregadosss.enums.motivoContrato.STLR'}
    ];
    this.motivoCessacaoList = [
      {value: ERHRegistarEmpregadosSSMotivoCessacao.NONE, name: ''},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.CCAI, name: 'rhregistarempregadosss.enums.motivoCessar.CCAI'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.CCCT, name: 'rhregistarempregadosss.enums.motivoCessar.CCCT'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.CCEE, name: 'rhregistarempregadosss.enums.motivoCessar.CCEE'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.CCFM, name: 'rhregistarempregadosss.enums.motivoCessar.CCFM'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.CCMT, name: 'rhregistarempregadosss.enums.motivoCessar.CCMT'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.CCRI, name: 'rhregistarempregadosss.enums.motivoCessar.CCRI'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.CCRV, name: 'rhregistarempregadosss.enums.motivoCessar.CCRV'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.CDT, name: 'rhregistarempregadosss.enums.motivoCessar.CDT'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.IECC, name: 'rhregistarempregadosss.enums.motivoCessar.IECC'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.IEDC, name: 'rhregistarempregadosss.enums.motivoCessar.IEDC'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.IEEX, name: 'rhregistarempregadosss.enums.motivoCessar.IEEX'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.IEIN, name: 'rhregistarempregadosss.enums.motivoCessar.IEIN'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.IEJC, name: 'rhregistarempregadosss.enums.motivoCessar.IEJC'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.IEPE, name: 'rhregistarempregadosss.enums.motivoCessar.IEPE'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.IIAT, name: 'rhregistarempregadosss.enums.motivoCessar.IIAT'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.IIDD, name: 'rhregistarempregadosss.enums.motivoCessar.IIDD'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.IIDE, name: 'rhregistarempregadosss.enums.motivoCessar.IIDE'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.IIJC, name: 'rhregistarempregadosss.enums.motivoCessar.IIJC'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.IISA, name: 'rhregistarempregadosss.enums.motivoCessar.IISA'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.RADC, name: 'rhregistarempregadosss.enums.motivoCessar.RADC'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.RANE, name: 'rhregistarempregadosss.enums.motivoCessar.RANE'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.RAOT, name: 'rhregistarempregadosss.enums.motivoCessar.RAOT'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.RARC, name: 'rhregistarempregadosss.enums.motivoCessar.RARC'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.RARD, name: 'rhregistarempregadosss.enums.motivoCessar.RARD'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.RARE, name: 'rhregistarempregadosss.enums.motivoCessar.RARE'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.RARR, name: 'rhregistarempregadosss.enums.motivoCessar.RARR'},
      {value: ERHRegistarEmpregadosSSMotivoCessacao.TE, name: 'rhregistarempregadosss.enums.motivoCessar.TE'}
    ];
    this.onRegistarCodEmpChanged(params.dgemp);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: BTN_CONFIG_ID,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      class: 'btn-light',
      caption: 'Configuração WS',
      click: () => this._configWSButtonClick()
    });
  }

  public onInitializedVerificarRegistarContratos({component}: IDevExpressDataGridEventOnInitialized): void {
    this._verificarRegistarContratosDataGridInstance = component;
  }

  public onRegistarCodEmpChanged(empregado: IJsonDGEMP): void {
    this.registarEmpregado = empregado;
    if (isDefinedNotNull(this.registarEmpregado)) {
      this._loadEmpregadoInfo();
    } else {
      this._resetRegistarModel();
    }
  }

  public getRegisterEmpInfoIcon(): string {
    if (this.registarModel.infoEstadoSegSocial === ERHRegistarEmpregadosSSEmpSegSocialVinculo.SemVinculo) {
      return 'fa-circle';
    } else if (this.registarModel.infoEstadoSegSocial === ERHRegistarEmpregadosSSEmpSegSocialVinculo.Admitido) {
      return 'fa-check-circle';
    } else if (this.registarModel.infoEstadoSegSocial === ERHRegistarEmpregadosSSEmpSegSocialVinculo.Cessado) {
      return 'fa-ban';
    }
    return 'fa-exclamation-triangle';
  }

  public getRegisterEmpInfoColorCssClass(): string {
    if (this.registarModel.infoEstadoSegSocial === ERHRegistarEmpregadosSSEmpSegSocialVinculo.SemVinculo) {
      return 'text-danger';
    } else if (this.registarModel.infoEstadoSegSocial === ERHRegistarEmpregadosSSEmpSegSocialVinculo.Admitido) {
      return 'text-success';
    } else if (this.registarModel.infoEstadoSegSocial === ERHRegistarEmpregadosSSEmpSegSocialVinculo.Cessado) {
      return 'text-warning';
    }
    return 'text-info';
  }

  public getRegisterEmpInfoText(): string {
    return this.registarModel.infoEmpregadoRegistado.length
      ? this.registarModel.infoEmpregadoRegistado.replace(/\r\n|\r|\n/g, '<br>')
      : this._translateService.instant('rhregistarempregadosss.strings.naoVerificado');
  }

  public modalidadeCtrSSChanged(value: ERHRegistarEmpregadosSSContratoModalidadeEnum): void {
    this.registarModel.modalidadeCtrSSEnum = value;
    this.ui.motivoCtrSSEnabled = false;
    this.registarModel.motivoCtrSSEnum = ERHRegistarEmpregadosSSContratoMotivoEnum.NONE;
    if (
      [
        ERHRegistarEmpregadosSSContratoModalidadeEnum.E,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.EA,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.EB,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.F,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.FA,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.FB,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.G,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.GA,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.GB,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.H,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.HA,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.HB,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.O,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.Q,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.N,
        ERHRegistarEmpregadosSSContratoModalidadeEnum.P
      ].includes(this.registarModel.modalidadeCtrSSEnum)
    ) {
      this.ui.motivoCtrSSEnabled = true;
      if (
        [
          ERHRegistarEmpregadosSSContratoModalidadeEnum.E,
          ERHRegistarEmpregadosSSContratoModalidadeEnum.EA,
          ERHRegistarEmpregadosSSContratoModalidadeEnum.EB,
          ERHRegistarEmpregadosSSContratoModalidadeEnum.F,
          ERHRegistarEmpregadosSSContratoModalidadeEnum.FA,
          ERHRegistarEmpregadosSSContratoModalidadeEnum.FB,
          ERHRegistarEmpregadosSSContratoModalidadeEnum.O,
          ERHRegistarEmpregadosSSContratoModalidadeEnum.N
        ].includes(this.registarModel.modalidadeCtrSSEnum)
      ) {
        this.motivoCtrList = this._motivoCtrTermoCertoList;
      } else {
        this.motivoCtrList = this._motivoCtrTermoInCertoList;
      }
    }

    this.ui.percTrabalhoCtrSSEnabled = [
      ERHRegistarEmpregadosSSContratoModalidadeEnum.B,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.BA,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.BB,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.F,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.FA,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.FB,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.H,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.HA,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.HB,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.D,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.R,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.N,
      ERHRegistarEmpregadosSSContratoModalidadeEnum.P
    ].includes(this.registarModel.modalidadeCtrSSEnum);

    this.ui.horasTrabalhoCtrSSEnabled = this.ui.percTrabalhoCtrSSEnabled;
    this.ui.diasTrabalhoCtrSSEnabled = this.ui.percTrabalhoCtrSSEnabled;
  }

  public motivoCtrSSChanged(value: ERHRegistarEmpregadosSSContratoMotivoEnum): void {
    this.registarModel.motivoCtrSSEnum = value;
    if (
      [
        ERHRegistarEmpregadosSSContratoMotivoEnum.STAJ,
        ERHRegistarEmpregadosSSContratoMotivoEnum.STAT,
        ERHRegistarEmpregadosSSContratoMotivoEnum.STLR,
        ERHRegistarEmpregadosSSContratoMotivoEnum.STTC
      ].includes(this.registarModel.motivoCtrSSEnum)
    ) {
      this.ui.empregadoSubsttituitSSctrEnabled = true;
    } else {
      this.registarModel.empregadoSubsttituitSSctr = 0;
      this.ui.empregadoSubsttituitSSctrEnabled = false;
    }
  }

  public motivoCessacaoChanged(value: ERHRegistarEmpregadosSSMotivoCessacao): void {
    this.registarModel.motivoCessacaoEnum = value;
    this.ui.fundamentacaoCessacaoEnabled = [
      ERHRegistarEmpregadosSSMotivoCessacao.RARC,
      ERHRegistarEmpregadosSSMotivoCessacao.RARD,
      ERHRegistarEmpregadosSSMotivoCessacao.RARE,
      ERHRegistarEmpregadosSSMotivoCessacao.RARR
    ].includes(this.registarModel.motivoCessacaoEnum);
  }

  public comunicaDesempregoChanged(value: boolean): void {
    this.registarModel.comunicaDesemprego = value;
    if (this.registarModel.comunicaDesemprego) {
      if (
        [ERHRegistarEmpregadosSSMotivoCessacao.RARC, ERHRegistarEmpregadosSSMotivoCessacao.RARD, ERHRegistarEmpregadosSSMotivoCessacao.RARE, ERHRegistarEmpregadosSSMotivoCessacao.RARR].includes(
          this.registarModel.motivoCessacaoEnum
        )
      ) {
        this.ui.fundamentacaoCessacaoEnabled = true;
      }
    } else {
      this.ui.fundamentacaoCessacaoEnabled = false;
    }
  }

  public editContrato(item: IRHRegistarEmpregadosSSContratosDataGridItem): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(RHRegistarEmpregadosSSEditarContratoModalComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    const componentInstance: RHRegistarEmpregadosSSEditarContratoModalComponent = modalInstance.componentInstance;
    componentInstance.empregadoInfo = `${item.codemp} - ${item.nomeTrabalhador}`;
    componentInstance.nEmpresa = this.session.erp.nEmpresa;
    componentInstance.model = {
      ...item,
      codmodalidadeContratoRnum: ERHRegistarEmpregadosSSContratoModalidadeEnum.NONE,
      codmotivoContratoEnum: ERHRegistarEmpregadosSSContratoMotivoEnum.NONE,
      codprestacaoTrabalhoEnum: ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum.P
    };
    return modalInstance.result.then((newItem: IRHRegistarEmpregadosSSEditarContratoModel) => {
      item.codmotivoContrato = newItem.codmotivoContrato;
      item.nissTrabalhador = newItem.nissTrabalhador;
      item.codmodalidadeContrato = newItem.codmodalidadeContrato;
      item.codprestacaoTrabalho = newItem.codprestacaoTrabalho;
      item.inicioContrato = newItem.inicioContrato;
      item.fimContrato = newItem.fimContrato;
      item.remuneracaoBase = newItem.remuneracaoBase;
      item.diuturnidades = newItem.diuturnidades;
      item.percentagemTrabalho = newItem.percentagemTrabalho;
      item.horasTrabalho = newItem.horasTrabalho;
      item.diasTrabalho = newItem.diasTrabalho;
      item.codmotivoContrato = newItem.codmotivoContrato;
      item.nissTrabalhadorSubstituir = newItem.nissTrabalhadorSubstituir;
      item.nomeTrabalhadorSubstituir = newItem.nissTrabalhadorSubstituirNome;

      const modalidadeItem = this.modalidadeList.find((arrayItem) => arrayItem.value === newItem.codmodalidadeContratoRnum);
      if (isDefinedNotNull(modalidadeItem)) {
        item.modalidadeString = this._translateService.instant(modalidadeItem.name);
      }

      const motivoCtrListAll = merge(this._motivoCtrTermoCertoList, this._motivoCtrTermoInCertoList);
      const motivoItem = motivoCtrListAll.find((arrayItem) => arrayItem.value === newItem.codmotivoContratoEnum);
      if (isDefinedNotNull(motivoItem)) {
        item.motivoString = this._translateService.instant(motivoItem.name);
      }

      const prestacaoItem = this.prestacaoTrabalhoList.find((arrayItem) => arrayItem.value === newItem.codprestacaoTrabalhoEnum);
      if (isDefinedNotNull(prestacaoItem)) {
        item.prestacaoTrabalhoString = this._translateService.instant(prestacaoItem.name);
      }
    });
  }

  public readonly fnConsultarEmpregados = (): Promise<void> => this._consultarEmpregados();
  public readonly fnVerificarContratos = (): Promise<void> => this._verificarContratos();
  public readonly fnAtualizarEstadoEmpregados = (): Promise<void> => this._atualizarEstadoEmpregados();
  public readonly fnRegistarContratos = (): Promise<void> => this._registarContratos();
  public readonly fnDoRegistarEmpregado = (): Promise<void> => this._doRegistarEmpregado();
  public readonly fnDoCessarEmpregado = (): Promise<void> => this._doCessarEmpregado();
  public readonly fnDoRegistarPeriodoRendimento = (): Promise<void> => this._doRegistarPeriodoRendimento();

  private _tranformContratosSourceIntoDataGridDS(source: Array<IRHRegistarEmpregadosSSContratosItem>): Array<IRHRegistarEmpregadosSSContratosDataGridItem> {
    const outputSource: Array<IRHRegistarEmpregadosSSContratosDataGridItem> = [];
    source.forEach((item) => {
      const sourceItem: IRHRegistarEmpregadosSSContratosDataGridItem = {
        ...item,
        modalidadeString: !item.codmodalidadeContrato.length ? '' : `${item.codmodalidadeContrato} - ${item.modalidadeContrato}`,
        prestacaoTrabalhoString: `${item.codprestacaoTrabalho} - ${item.prestacaoTrabalho}`,
        profissaoString: `${item.codprofissao} - ${item.profissao}`,
        motivoString: !item.codmotivoContrato.length ? '' : `${item.codmotivoContrato} - ${item.motivoContrato}`
      };
      outputSource.push(sourceItem);
    });
    return outputSource;
  }

  private _extractLetras(str: string, len: number): string {
    return str.substring(0, len).trim();
  }

  private _configWSButtonClick(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ConfigLoginWsSSModalComponent, {size: 'md'});
    const componentInstance: ConfigLoginWsSSModalComponent = modalInstance.componentInstance;
    componentInstance.nEmpresa = this.configurations.empresa.nEmpresa;
    return modalInstance.result;
  }

  private _handleException(reason: unknown): void {
    if (!(reason instanceof HttpErrorResponse)) {
      return;
    }
    const exception: ICGExceptionError = this._cgExceptionService.get(reason);
    if (exception.class === EXCEPTION_CONFIG_WS_CREDENTIALS) {
      this._configWSButtonClick();
    } else if (exception.message.includes(UNAUTHORIZED_STRING)) {
      this._plAlertService.error('rhregistarempregadosss.strings.authenticationError');
    } else {
      this._plAlertService.error(exception.message);
    }
  }

  private _consultarEmpregados(): Promise<void> {
    return new Promise((resolve) => {
      this._rhRegistarEmpregadosSSService
        .consultarEmpregadosSS(this.filtersModel)
        .then((response) => {
          this.verificarEmpregadosDataGridDS = response.body;
        })
        .catch((reason: unknown) => {
          this._handleException(reason);
        })
        .finally(resolve);
    });
  }

  private _verificarContratos(): Promise<void> {
    return new Promise((resolve) => {
      this._rhRegistarEmpregadosSSService
        .consultarContratosEmpregadosSS(this.filtersModel)
        .then((response) => {
          this.verificarRegistarContratosDataGridDS = this._tranformContratosSourceIntoDataGridDS(response.body);
        })
        .catch((reason: unknown) => {
          this._handleException(reason);
        })
        .finally(resolve);
    });
  }

  private _atualizarEstadoEmpregados(): Promise<void> {
    return this._rhRegistarEmpregadosSSService
      .atualizarEstadoEmpregadosSS(this.filtersModel)
      .then(() => {
        this._plAlertService.info('rhregistarempregadosss.strings.empregadosAtualizadosComSucesso');
      })
      .catch((reason: unknown) => {
        this._handleException(reason);
      });
  }

  private _registarContratos(): Promise<void> {
    const selectedArray: Array<IRHRegistarEmpregadosSSContratosItem> = this._verificarRegistarContratosDataGridInstance.getSelectedRowsData();
    if (selectedArray.length === 0) {
      this._plAlertService.info('rhregistarempregadosss.messages.selectRecords');
      return Promise.resolve();
    }

    for (const item of selectedArray) {
      if (item.temErros) {
        this._plAlertService.error('rhregistarempregadosss.messages.existemEmpregadosComErro');
        return Promise.resolve();
      }
    }

    const clearArray: Array<IRHRegistarEmpregadosSSContratosBase> = [];
    selectedArray.forEach((item) => {
      clearArray.push({
        codemp: item.codemp,
        codmodalidadeContrato: item.codmodalidadeContrato,
        codmotivoContrato: item.codmotivoContrato,
        codprestacaoTrabalho: item.codprestacaoTrabalho,
        codprofissao: item.codprestacaoTrabalho,
        diasTrabalho: item.diasTrabalho,
        diuturnidades: item.diuturnidades,
        fimContrato: item.fimContrato,
        horasTrabalho: item.horasTrabalho,
        inicioContrato: item.inicioContrato,
        nissTrabalhador: item.nissTrabalhador,
        nissTrabalhadorSubstituir: item.nissTrabalhadorSubstituir,
        percentagemTrabalho: item.percentagemTrabalho,
        remuneracaoBase: item.remuneracaoBase,
        nissTrabalhadorSubstituirNome: ''
      });
    });

    return new Promise((resolve) => {
      this._rhRegistarEmpregadosSSService
        .registarContrato(clearArray)
        .then((response) => {
          response.body.results.forEach((resultItem) => {
            const sourceItem = this.verificarRegistarContratosDataGridDS.find((item) => {
              return item.codemp === resultItem.codemp;
            });
            if (isDefinedNotNull(sourceItem)) {
              sourceItem.temErros = resultItem.temErros;
              sourceItem.isContratoEnviadoParaSS = resultItem.isContratoEnviadoParaSS;
              sourceItem.descErros = resultItem.descErros;
            }
          });
          if (!response.body.success) {
            this._plAlertService.error(response.body.errorMessage);
          }
        })
        .catch((reason: unknown) => {
          this._handleException(reason);
        })
        .finally(resolve);
    });
  }

  private _doRegistarEmpregado(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._cgModalService
        .showOkCancel('global.text.confirmation', 'rhregistarempregadosss.promptRegistarEmpregado', {size: 'md'})
        .then(() => {
          const data: IRHRegistarEmpregadosSSRegistarEmpregadoSSData = {
            codEmp: this.registarEmpregado.codEmp,
            defContratoSegSocial:
              this.registarModel.modalidadeCtrSSEnum !== ERHRegistarEmpregadosSSContratoModalidadeEnum.NONE
                ? ERHRegistarEmpregadosSSContratoModalidadeEnum[this.registarModel.modalidadeCtrSSEnum]
                : '',
            motiSS: this.registarModel.motivoCtrSSEnum !== ERHRegistarEmpregadosSSContratoMotivoEnum.NONE ? ERHRegistarEmpregadosSSContratoMotivoEnum[this.registarModel.motivoCtrSSEnum] : '',
            prestacaoTrabalhoSS: ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum[this.registarModel.prestacaoTrabalhoSSEnum],
            percTrabalhoCtrSS: this.registarModel.percTrabalhoCtrSS,
            horasTrabalhoCtrSS: this.registarModel.horasTrabalhoCtrSS,
            diasTrabalhoCtrSS: this.registarModel.diasTrabalhoCtrSS,
            codEmpSubstituir: this.registarModel.empregadoSubsttituitSSctr,
            vencimentoBase: this.registarModel.vencimentoBase,
            diuturnidades: this.registarModel.diuturnidades
          };
          this._rhRegistarEmpregadosSSService
            .registarEmpregadoSS(data)
            .then((response) => {
              if (response.body.success) {
                this._plAlertService.success('rhregistarempregadosss.messages.empregadoRegistadoComSucesso');
                this._loadEmpregadoInfo().finally(resolve);
              } else {
                this._plAlertService.error(response.body.infoErrorMessage.length ? response.body.infoErrorMessage : 'rhregistarempregadosss.messages.erroInesperado');
                resolve();
              }
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  private _doCessarEmpregado(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._cgModalService
        .showOkCancel('global.text.confirmation', 'rhregistarempregadosss.promptCessarEmpregado', {size: 'md'})
        .then(() => {
          const data: IRHRegistarEmpregadosSSCessarVinculoEmpregadoSSData = {
            codEmp: this.registarEmpregado.codEmp,
            dataFimVinculo: this.registarModel.dataFimVinculoCessacao,
            motivoFimVinculoSS:
              this.registarModel.motivoCessacaoEnum !== ERHRegistarEmpregadosSSMotivoCessacao.NONE ? ERHRegistarEmpregadosSSMotivoCessacao[this.registarModel.motivoCessacaoEnum] : '',
            comunicacaoDesemprego: this.registarModel.comunicaDesemprego,
            fundamentacao: this.registarModel.fundamentacao
          };
          this._rhRegistarEmpregadosSSService
            .cessarVinculoEmpregadoSS(data)
            .then((response) => {
              if (response.body.success) {
                this._plAlertService.success('rhregistarempregadosss.messages.empregadoCessadoComSucesso');
                this._loadEmpregadoInfo().finally(resolve);
              } else {
                this._plAlertService.error(response.body.infoErrorMessage.length ? response.body.infoErrorMessage : 'rhregistarempregadosss.messages.erroInesperado');
                resolve();
              }
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  private _doRegistarPeriodoRendimento(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._cgModalService
        .showOkCancel('global.text.confirmation', 'rhregistarempregadosss.promptRegPeriodoRendimento', {size: 'md'})
        .then(() => {
          const data: IRHRegistarEmpregadosSSRegistarPeriodoRendimentoData = {
            codEmp: this.registarEmpregado.codEmp,
            novaDataVinculoRendimento: this.registarModel.ssDataNovoRegistoPeriodo,
            novoVencimento: this.registarModel.ssVencimentoRegistoPeriodo,
            novaDiuturnidade: this.registarModel.ssDiuturnidadeRegistoPeriodo
          };
          this._rhRegistarEmpregadosSSService
            .registarPeriodoRendimentoSS(data)
            .then((response) => {
              if (response.body.success) {
                this._plAlertService.success('rhregistarempregadosss.messages.periodoRendimentoRegistadoComSucesso');
                this._loadEmpregadoInfo().finally(resolve);
              } else {
                this._plAlertService.error(response.body.infoErrorMessage.length ? response.body.infoErrorMessage : 'rhregistarempregadosss.messages.erroInesperado');
                resolve();
              }
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  private _loadEmpregadoInfo(): Promise<unknown> {
    this.promiseLoadEmpregado = this._rhRegistarEmpregadosSSService.getEmpregadoInfo(this.registarEmpregado.codEmp).then((response) => {
      this.registarModel = response.body;
      this.ui.dtValidoAteVisible = this.registarModel.dtValidoAteVisible;

      let letras: string = this._extractLetras(this.registarModel.modalidadeCtrSS, 2);
      if (letras.length) {
        this.registarModel.modalidadeCtrSSEnum = ERHRegistarEmpregadosSSContratoModalidadeEnum[letras];
        this.modalidadeCtrSSChanged(this.registarModel.modalidadeCtrSSEnum);
      }

      letras = this._extractLetras(this.registarModel.motivoCtrSS, LETTERS_LEN_FOUR);
      if (letras.length) {
        this.registarModel.motivoCtrSSEnum = ERHRegistarEmpregadosSSContratoMotivoEnum[letras];
        this.motivoCtrSSChanged(this.registarModel.motivoCtrSSEnum);
      }

      letras = this._extractLetras(this.registarModel.motivoCessacao, LETTERS_LEN_FOUR);
      if (letras.length) {
        this.registarModel.motivoCessacaoEnum = ERHRegistarEmpregadosSSMotivoCessacao[letras];
        this.motivoCessacaoChanged(this.registarModel.motivoCessacaoEnum);
      }
      letras = this._extractLetras(this.registarModel.prestacaoTrabalhoSS, 1);
      if (letras.length) {
        this.registarModel.prestacaoTrabalhoSSEnum = ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum[letras];
      }
      this.registarActiveTabId = `${REGISTAR_EMPREGADO_TAB_ID_PREFIX}-${this.registarModel.activePage}`;
    });
    return this.promiseLoadEmpregado;
  }

  private _resetRegistarModel(): void {
    this.registarModel = {
      activePage: 0,
      dataFimVinculoCessacao: undefined,
      diasTrabalhoCtrSS: 0,
      diuturnidades: 0,
      dtAdmissao: undefined,
      dtAssinadoEm: undefined,
      dtValidoAte: undefined,
      dtValidoAteVisible: false,
      empregadoSubsttituitSSctr: 0,
      horasTrabalhoCtrSS: 0,
      infoAvisosErros: '',
      infoEmpregadoRegistado: '',
      infoEstadoSegSocial: undefined,
      localEstabelec: '',
      modalidadeCtrSS: '',
      motivoCessacao: '',
      motivoCtrSS: '',
      niss: '',
      percTrabalhoCtrSS: 0,
      podeCessar: false,
      podeRegistar: false,
      podeRegistarPeriodoRendimento: false,
      podeVerCessar: false,
      podeVerRegistar: false,
      podeVerRegistarPeriodoRendimento: false,
      prestacaoTrabalhoSS: '',
      ssDataNovoRegistoPeriodo: undefined,
      ssDiuturnidadeRegistoPeriodo: 0,
      ssVencimentoRegistoPeriodo: 0,
      tipoContrTrab: '',
      vencimentoBase: 0,
      modalidadeCtrSSEnum: ERHRegistarEmpregadosSSContratoModalidadeEnum.NONE,
      motivoCtrSSEnum: ERHRegistarEmpregadosSSContratoMotivoEnum.NONE,
      prestacaoTrabalhoSSEnum: ERHRegistarEmpregadosSSContratoPrestacaoTrabalhoEnum.P,
      motivoCessacaoEnum: ERHRegistarEmpregadosSSMotivoCessacao.NONE,
      comunicaDesemprego: false,
      fundamentacao: ''
    };
  }
}
