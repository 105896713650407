import {produce} from 'immer';
import {Subscription} from 'rxjs';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {IPlCookiesConsent, PlCookiesConsentService} from 'pl-comps-angular';
import {AppService} from '../../../services/app/app.service';

@Component({
  selector: 'cg-cookies-consent',
  templateUrl: './cg.cookies.consent.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CGCookiesConsentComponent implements OnDestroy {
  public consentOpened: boolean;

  private readonly _subscriptionCookiesConsentOpen: Subscription;

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly _plCookiesConsentService: PlCookiesConsentService,
    private readonly _appService: AppService
  ) {
    this.consentOpened = false;
    this._subscriptionCookiesConsentOpen = this._appService.cookiesConsentOpen().subscribe((value: boolean) => {
      this.consentOpened = value;
      this._changeDetectorRef.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionCookiesConsentOpen.unsubscribe();
  }

  public consented(value: IPlCookiesConsent): void {
    if (!value.consented) {
      value = produce(value, (consent) => {
        consent.consented = true;
      });
    }
    this._plCookiesConsentService.setConsent(value);
    this._appService.setCookiesConsentOpen(false);
  }
}
