import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {isEmpty, isObject, isString} from '../common/utilities/utilities';
import type {TValueOrPromise} from '../common/utilities/utilities.interface';

export const PL_PDF_ERROR_MESSAGES_TO_SUPPRESS: ReadonlyArray<string> = Object.freeze(['Worker was destroyed']);

export function plPdfErrorHandlerFactory(): PlPdfErrorHandler {
  return new PlPdfGenericErrorHandler();
}

@Injectable({
  providedIn: 'root',
  useFactory: plPdfErrorHandlerFactory
})
export abstract class PlPdfErrorHandler {
  public abstract parseError(error: unknown | HttpErrorResponse): TValueOrPromise<string>;
}

@Injectable()
export class PlPdfGenericErrorHandler extends PlPdfErrorHandler {
  public parseError(error: unknown | HttpErrorResponse): TValueOrPromise<string> {
    let message: string;
    if (isObject(error)) {
      if (!isEmpty((<HttpErrorResponse>error).message)) {
        message = (<HttpErrorResponse>error).message;
      }
    } else if (isString(error)) {
      message = error;
    }
    if (PL_PDF_ERROR_MESSAGES_TO_SUPPRESS.includes(message)) {
      message = undefined;
    }
    return message;
  }
}
