import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonRelatorioLoadAux, IJsonRHCopiaAbonoDesconto} from './jsonrhCopiaAbonosDescontos.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class RHCopiaAbonosDescontosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/rhcopiaabonosdescontos`;
  }

  public getDadosPorEmpresa(abonodesc: number, codabdesc: string, comcruzamento: boolean, nempresas: Array<string>): TServiceResponse<Array<IJsonRHCopiaAbonoDesconto>> {
    return this._apiService.get<Array<IJsonRHCopiaAbonoDesconto>>({
      url: `${this._path}/loaddadosempresa`,
      params: {
        abonodesc: abonodesc,
        codabdesc: codabdesc,
        comcruzamento: comcruzamento,
        nempresas: nempresas.join(',')
      }
    });
  }

  public copiaDados(
    abonodesc: number,
    codabdesc: string,
    comcruzamento: boolean,
    copiaintegracao: boolean,
    nempresas: Array<string>,
    relatorio: Array<IJsonRelatorioLoadAux>
  ): TServiceResponse<Array<IJsonRHCopiaAbonoDesconto>> {
    return this._apiService.put<Array<IJsonRHCopiaAbonoDesconto>, Array<IJsonRelatorioLoadAux>>({
      url: `${this._path}/copiadadosempresa`,
      body: relatorio,
      params: {
        abonodesc: abonodesc,
        codabdesc: codabdesc,
        comcruzamento: comcruzamento,
        copiaintegracao: copiaintegracao,
        nempresas: nempresas.join(',')
      }
    });
  }
}
