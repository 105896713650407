<pl-split-view [initialSizeLeft]="initialSizeLeft" [hideRightSide]="hideRightSide || !hasContabilidadeDigital || !contabDigitalDoc" (evtSizeValuesChanged)="onResizerValuesChanged()">
  <div *plSplitViewLeftSide>
    <documento-contabilidade
      [doc]="docContabilidade"
      [showToolbar]="showToolbar"
      [highlightNLanc]="highlightNLanc"
      [maintenanceMode]="maintenanceMode"
      [showBtnContabDigital]="hasContabilidadeDigital"
      (evtActionEdited)="onActionEdited($event)"
      (evtActionDeleted)="onActionDeleted($event)"
      (docChange)="docContabilidadeChanged($event)"
      (evtBtnContabDigital)="hideRightSide = !hideRightSide">
    </documento-contabilidade>
  </div>

  <div *plSplitViewRightSide class="cg-contab-extratosgrid-contabilidade-digital-docviewer">
    <contabilidadedigital-docviewer-recolha [doc]="contabDigitalDoc" [readonly]="readonlyDigital" [nDocumento]="docContabilidade.nDocumento" [callback]="callbackDocViewer">
    </contabilidadedigital-docviewer-recolha>
  </div>
</pl-split-view>
