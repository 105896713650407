import type {Draft} from 'immer';
import {deepFreeze} from 'pl-comps-angular';
import {EPaisISO3166} from '../../datasources/paisesiso3166/paisesISO3166.datasource.interface';
import {EPCAPeriodo} from '../../modules/portalclientaccounts/contabilidade/pca.contabilidade.module.service.interface';
import {EStoreMode} from '../../../common/enums/licenca.enums';
import {ETipoAnalitica} from '../../datasources/tipoanalitica/tipoAnalitica.datasource.interface';
import {STATE_NAME_PORTAL} from '../portals/portals.service.interface';

/* eslint-disable @typescript-eslint/naming-convention */

export interface ICGConfigurations {
  readonly acessos: ICGConfigAcessos;
  readonly alert: ICGConfigAlert;
  readonly clientesFornecedores: ICGConfigClientesFornecedores;
  readonly contabilidade: ICGConfigContabilidade;
  readonly documentosComerciais: ICGConfigDocumentosComerciais;
  readonly empresa: ICGConfigEmpresa;
  readonly erp: ICGConfigErp;
  readonly formatsettings: ICGConfigFormatSettings;
  readonly geral: ICGConfigGeral;
  readonly gestaoComercial: ICGConfigGestaoComercial;
  readonly home: ICGConfigHome;
  readonly licenca: ICGConfigLicenca;
  readonly pca: ICGConfigPCA;
  readonly portais: ICGConfigPortais;
  readonly rh: ICGConfigRH;
  readonly schedule: ICGConfigSchedule;
  readonly portalcolaborador: ICGConfigPortalColaborador;
}

export interface ICGConfigAcessos {
  readonly erpcloud: ICGConfigAcessosErpCloud;
}

export interface ICGConfigAlert {
  readonly timeout: number;
}

export interface ICGConfigClientesFornecedores {
  readonly contaIdentificacaoAlternativa: string;
  readonly contaIdentificacaoAlternativaFornecedores: string;
  readonly radicalClientes: string;
  readonly radicalFornecedores: string;
  readonly radicalOutrosDevedoresCredores: string;
}

export interface ICGConfigContabilidade {
  readonly recibos: ICGConfigRecibos;
  readonly decimais: ICGConfigDecimais;
  readonly controloIva: ICGConfigControloIva;
  readonly extratos: ICGConfigExtratos;
  readonly balancetes: ICGConfigBalancetes;
  readonly balancetesAnalitica: ICGConfigBalancetesAnalitica;
  readonly movimentosemaberto: ICGConfigMovimentosEmAberto;
  readonly impdecpe: ICGConfigImpdecpe;
  readonly svat: ICGConfigSvat;
  readonly pagamentos: ICGConfigPagamentos;
  readonly diferencaIva: number;
  readonly moeda: string;
  readonly reparticaocc: ICGConfigReparticoesCCusto;
  readonly listagemdiarios: ICGConfigListagemDiarios;
  readonly conciliacaoBancaria: ICGConfigConciliacaoBancaria;
  readonly transferirSaldo: ICGConfigContabilidadeTransferirSaldo;
}

export interface ICGConfigDocumentosComerciais {
  readonly tipos: ICGConfigDocumentosComerciaisTipos;
}

export interface ICGConfigEmpresa {
  readonly anoEmCursoIRC: number;
  readonly codIso3166: EPaisISO3166;
  readonly codMoeda: number;
  readonly demonstracao: boolean;
  readonly ignoreLocked: string;
  readonly islocked: boolean;
  readonly motivoislocked: string;
  readonly ncontribtoc: string;
  readonly ncontribuemp: string;
  readonly nEmpresa: string;
  readonly nome: string;
  readonly periodo: string;
  readonly periodoDaData: string;
  readonly periodoEncerrado: string;
  readonly periodoIvaTrimestral: boolean;
  readonly regimeIva: number;
  readonly tipoAnalitica: ETipoAnalitica;
  readonly usaConfigAnalitica: boolean;
  readonly verificacaoEfetuada: boolean;
  readonly email: string;
  readonly temContabilidadeDigital: boolean;
  readonly temContabilidadeDigitalClientConnect: boolean;
  readonly temCGBanking: boolean;
  readonly abreviaturaMoeda: string;
  readonly naoprocessasalarios: boolean;
  readonly sujeitaAsLeisVigoramEmPortugal: boolean;
  readonly sujeitaAsLeisVigoramEmAngola: boolean;
  readonly sujeitaAsLeisVigoramEmMocambique: boolean;
  readonly cgon: ICGConfigEmpresaCGOn;
  readonly erpcloud: ICGConfigEmpresaErpCloud;
  readonly codPais: number;
  readonly nomePais: string;
  readonly abrevFiscal: string;
}

export interface ICGConfigEmpresaCGOn {
  readonly allowAddFiles: boolean;
  readonly repositorySize: number; // Megabytes
  readonly cgBankingExpired: boolean;
}

export interface ICGConfigEmpresaErpCloud {
  readonly ficheiros: ICGConfigEmpresaErpCloudFicheiros;
}

export interface ICGConfigErp {
  readonly versao: string;
  readonly dataversao: string;
  readonly emManutencao: boolean;
}

export interface ICGConfigFormatSettings {
  readonly date: string;
  readonly decimais: number;
}

export interface ICGConfigGeral {
  readonly contabilidadeDigital: ICGConfigGeralContabilidadeDigital;
}

export interface ICGConfigGeralContabilidadeDigital {
  readonly maxUploadFilesAtATime: number;
}

export interface ICGConfigEmpresaErpCloudFicheiros {
  readonly maxFileSize: number;
}

export interface ICGConfigGestaoComercialFaturaDigitalDocfas {
  readonly modoFuncionamento: number;
}

export interface ICGConfigGestaoComercialFaturaDigitalClientes {
  readonly modoFuncionamento: number;
}

export interface ICGConfigGestaoComercialFaturaDigital {
  readonly docfas: ICGConfigGestaoComercialFaturaDigitalDocfas;
  readonly clientes: ICGConfigGestaoComercialFaturaDigitalClientes;
  readonly tipoCertificado: number;
  readonly ativa: boolean;
}

export interface ICGConfigGestaoComercialAvisosCobranca {
  readonly vendedores: boolean;
  readonly condicaoPagamento: boolean;
}

export interface ICGConfigGestaoComercialDebitosDiretosSEPA {
  readonly reportImpressao: string;
  readonly reportClienteImpressao: string;
}

export interface ICGConfigGestaoComercial {
  readonly Stocks: ICGConfigClassificacaoArtigoTemplate;
  readonly docsComerciasEstatisticas: ICGConfigGestaoComercialDocsComerciasEstatisticas;
  readonly docsComerciasEstatisticasDoc: ICGConfigGestaoComercialDocsComerciasEstatisticasDoc;
  readonly decimais: ICGConfigGestaoComercialDecimais;
  readonly margens: ICGConfigGestaoComercialMargens;
  readonly faturaDigital: ICGConfigGestaoComercialFaturaDigital;
  readonly avisosCobranca: ICGConfigGestaoComercialAvisosCobranca;
  readonly debitosDiretosSEPA: ICGConfigGestaoComercialDebitosDiretosSEPA;
  readonly analiseMargemLucro: ICGConfigGestaoComercialAnaliseMargemLucro;
}

export interface ICGConfigHome {
  readonly erp: ICGConfigHomeErp;
  readonly gestorRH: ICGConfigGestorRH;
  readonly gestorServico: ICGConfigGestorServico;
  readonly colaborador: ICGConfigColaborador;
  readonly clientportalaccount: ICGConfigClientPortalAccount;
}

export interface ICGConfigLicenca {
  readonly licId: number;
  readonly nome: string;
  readonly descricao: string;
  readonly nempresas: number;
  readonly nutilizadores: number;
  readonly portalcolaborador: number;
  readonly clientPortalAccount: number;
  readonly portalContabilidade: number;
  readonly portalRH: number;
  readonly portalAtivos: number;
  readonly modoCGOn: boolean; // TODO: deprecated, remove after all clients are updated
  readonly storeMode: EStoreMode;
  readonly storeModePublic: boolean;
}

export interface ICGConfigPCA {
  readonly periodo: EPCAPeriodo;
  readonly reportrecibosal: string;
}

export interface ICGConfigPortais {
  readonly lastPortal: string;
}

export interface ICGConfigRH {
  readonly refeicoes: ICGConfigRefeicoes;
  readonly proceSegSocial: ICGConfigRHProceSegSocial;
  readonly proceDMRAT: ICGConfigRHProceDMRAT;
  readonly recibosVencimento: ICGConfigRecibosVencimento;
  marcacaoEventos: ICGConfigRHMarcacaoEventos;
}

export interface ICGConfigPortalColaborador {
  readonly reportrecibosal: string;
}

export interface ICGConfigSchedule {
  readonly usaAgendamento: boolean;
}

export interface ICGConfigHomeErp {
  readonly state: string;
  readonly url: string;
}

export interface ICGConfigGestorRH {
  readonly state: string;
  readonly url: string;
}

export interface ICGConfigGestorServico {
  readonly state: string;
  readonly url: string;
}

export interface ICGConfigColaborador {
  readonly state: string;
  readonly url: string;
}

export interface ICGConfigClientPortalAccount {
  readonly state: string;
  readonly url: string;
}

export interface ICGConfigDocumentosComerciaisTipos {
  readonly outros: ICGConfigDocumentoComercialTipo;
  readonly comprasEfectivas: ICGConfigDocumentoComercialTipo;
  readonly consultasaFornecedores: ICGConfigDocumentoComercialTipo;
  readonly encomendasClientes: ICGConfigDocumentoComercialTipo;
  readonly encomendasFornecedores: ICGConfigDocumentoComercialTipo;
  readonly folhasdeObra: ICGConfigDocumentoComercialTipo;
  readonly guiasTransporteRemessa: ICGConfigDocumentoComercialTipo;
  readonly propostasaClientes: ICGConfigDocumentoComercialTipo;
  readonly reservasClientes: ICGConfigDocumentoComercialTipo;
  readonly trfArmazensEntradas: ICGConfigDocumentoComercialTipo;
  readonly trfArmazensSaidas: ICGConfigDocumentoComercialTipo;
  readonly vendasEfectivas: ICGConfigDocumentoComercialTipo;
  readonly consignacoesClientes: ICGConfigDocumentoComercialTipo;
  readonly consignacoesFornecedores: ICGConfigDocumentoComercialTipo;
  readonly recibosClientes: ICGConfigDocumentoComercialTipo;
  readonly pagamentosFornecedores: ICGConfigDocumentoComercialTipo;
  readonly none: ICGConfigDocumentoComercialTipo;
  readonly planoProducao: ICGConfigDocumentoComercialTipo;
  readonly preEncomendasClientes: ICGConfigDocumentoComercialTipo;
  readonly custosAdicionais: ICGConfigDocumentoComercialTipo;
  readonly notaCredito: ICGConfigDocumentoComercialTipo;
  readonly acertoCustoPadrao: ICGConfigDocumentoComercialTipo;
  readonly entradasDiversas: ICGConfigDocumentoComercialTipo;
  readonly saidasDiversas: ICGConfigDocumentoComercialTipo;
  readonly guiasTransporteFornecedores: ICGConfigDocumentoComercialTipo;
}

export interface ICGConfigDocumentoComercialTipo {
  readonly 'doc 1': ICGConfigDocumentoComercial;
  readonly documentoPorDefeito: number;
}

export interface ICGConfigDocumentoComercial {
  readonly visivel: boolean;
}

export interface ICGConfigRecibos {
  readonly meioPagamentoOmissao: number;
}

export interface ICGConfigDecimais {
  readonly desconto: number;
  readonly percDesconto: number;
  readonly valor: number;
  readonly valorDesconto: number;
  readonly valorME: number;
  readonly cambio: number;
}

export interface ICGConfigControloIva {
  readonly CampoOficial40: string;
  readonly CampoOficial41: string;
}

export interface ICGConfigExtratos {
  readonly reportImpressao: string;
}

export interface ICGConfigBalancetes {
  readonly reportImpressao: string;
}

export interface ICGConfigBalancetesAnalitica {
  readonly reportImpressao: string;
}

export interface ICGConfigMovimentosEmAberto {
  readonly reportImpressao: string;
}

export interface ICGConfigImpdecpe {
  readonly reportImpressao: string;
}

export interface ICGConfigSvat {
  readonly reportImpressao: string;
}

export interface ICGConfigConciliacaoBancaria {
  readonly reportImpressao: string;
}

export interface ICGConfigContabilidadeTransferirSaldo {
  readonly manterContaOrigem: boolean;
}

export interface ICGConfigPagamentos {
  readonly meioPagamentoOmissao: number;
}

export interface ICGConfigReparticoesCCusto {
  readonly usadata: boolean;
}

export interface ICGConfigListagemDiarios {
  readonly reportImpressao: string;
}

export interface ICGConfigAcessosErpCloud {
  readonly addons: ICGConfigAcessosErpCloudAddons;
  readonly ativos: ICGConfigAcessosErpCloudAtivos;
  readonly contabilidade: ICGConfigAcessosErpCloudContabilidade;
  readonly gdocs: boolean;
  readonly gestaoComercial: ICGConfigAcessosErpCloudGestaoComercial;
}

export interface ICGConfigAcessosErpCloudAddons {
  readonly bankingAutomation: ICGConfigAcessosErpCloudAddonsBankingAutomation;
}

export interface ICGConfigAcessosErpCloudAtivos {
  readonly fichas: ICGConfigAcessosErpCloudAtivosFichas;
}

export interface ICGConfigAcessosErpCloudAtivosFichas {
  readonly criarFichas: number;
  readonly editarFichas: number;
  readonly apagarFichas: number;
  readonly visualizarFichas: number;
  readonly operacoesAbatesVendas: number;
  readonly calcularAnularDepreciacoes: number;
}

export interface ICGConfigAcessosErpCloudContabilidade {
  readonly documentos: ICGConfigAcessosErpCloudGenericDocumentos;
}

export interface ICGConfigAcessosErpCloudGestaoComercial {
  readonly documentos: ICGConfigAcessosErpCloudGestaoComercialDocumentos;
  readonly editarContaArtigoFamilia: number;
  readonly editarMeioPagamento: number;
}

export interface ICGConfigAcessosErpCloudGestaoComercialDocumentos {
  readonly comprasefetivas: ICGConfigAcessosErpCloudGenericDocumentos;
  readonly vendasefetivas: ICGConfigAcessosErpCloudGenericDocumentos;
  readonly encomendasclientes: ICGConfigAcessosErpCloudGenericDocumentos;
  readonly encomendasfornecedores: ICGConfigAcessosErpCloudGenericDocumentos;
  readonly guiastransporte: ICGConfigAcessosErpCloudGenericDocumentos;
  readonly propostasfornecedores: ICGConfigAcessosErpCloudGenericDocumentos;
  readonly propostasclientes: ICGConfigAcessosErpCloudGenericDocumentos;
  readonly entradasdiversas: ICGConfigAcessosErpCloudGenericDocumentos;
  readonly saidasdiversas: ICGConfigAcessosErpCloudGenericDocumentos;
}

export interface ICGConfigAcessosErpCloudGenericDocumentos {
  readonly criarDocumentos: number;
  readonly editarDocumentos: number;
  readonly anularDocumentos: number;
  readonly visualizarDocumentos: number;
}

export interface ICGConfigClassificacaoArtigoTemplate {
  readonly ClassificacaoArtigoTemplate: number;
}

export interface ICGConfigGestaoComercialDocsComerciasEstatisticas {
  readonly reports: ICGConfigGestaoComercialDocsComerciasEstatisticasReports;
}

export interface ICGConfigGestaoComercialDocsComerciasEstatisticasDoc {
  readonly reports: ICGConfigGestaoComercialDocsComerciasEstatisticasDocReports;
}

export interface ICGConfigGestaoComercialAnaliseMargemLucro {
  readonly reports: ICGConfigGestaoComercialAnaliseMargemLucroReports;
}

export interface ICGConfigGestaoComercialDecimaisStocks {
  readonly quantidades: number;
  readonly valores: number;
}

export interface ICGConfigGestaoComercialDecimais {
  readonly quantidades: number;
  readonly valoresLinha: number;
  readonly valoresUnitarios: number;
  readonly stocks: ICGConfigGestaoComercialDecimaisStocks;
}

export interface ICGConfigGestaoComercialMargens {
  readonly arredManualMax: number;
}

export interface ICGConfigRefeicoes {
  readonly numMax: number;
}

export interface ICGConfigRHProceSegSocial {
  readonly reports: ICGConfigRHProceSegSocialReports;
}

export interface ICGConfigRHProceDMRAT {
  readonly reports: ICGConfigRHProceDMRATReportsMapa;
}

export interface ICGConfigRecibosVencimento {
  readonly reports: ICGConfigRecibosVencimentoReports;
}

export interface ICGConfigRHMarcacaoEventos {
  usaCalenEstabelecimentoEmpregado: boolean;
}

export interface ICGConfigRHProceSegSocialReports {
  readonly Mapacx: ICGConfigRHProceSegSocialReportsMapacx;
  readonly MapacxRE: ICGConfigRHProceSegSocialReportsMapacxRE;
}

export interface ICGConfigGestaoComercialDocsComerciasEstatisticasReports {
  readonly reportImpressao: string;
}

export interface ICGConfigRecibosVencimentoReports {
  readonly RECSAL: ICGConfigRecibosVencimentoReportsRECSAL;
}

export interface ICGConfigGestaoComercialDocsComerciasEstatisticasDocReports {
  readonly reportImpressao: string;
}

export interface ICGConfigGestaoComercialAnaliseMargemLucroReports {
  readonly reportImpressao: string;
  readonly titleReportImpressao: string;
}

export interface ICGConfigRHProceDMRATReportsMapa {
  readonly reportImpressao: string;
}

export interface ICGConfigRHProceSegSocialReportsMapacx {
  readonly reportImpressao: string;
}

export interface ICGConfigRHProceSegSocialReportsMapacxRE {
  readonly reportImpressao: string;
}

export interface ICGConfigRecibosVencimentoReportsRECSAL {
  readonly reportImpressao: string;
}

export interface ICGConfigAcessosErpCloudAddonsBankingAutomation {
  readonly acesso: boolean;
}

export interface IWritableCGConfigurations extends Draft<ICGConfigurations> {}

export const CG_DEFAULT_STORE_NIF = '123456789';
export const CG_DEFAULT_AT_NIF = '999999990';
export const CG_DEFAULT_GERAL_CONTAB_DIGITAL_MAX_UPLOAD_FILES_AT_A_TIME = 20;
export const CG_DEFAULT_MAX_FILE_SIZE = 3;

export const CG_DEFAULT_CONFIGURATIONS: ICGConfigurations = deepFreeze({
  home: {
    erp: {
      state: `${STATE_NAME_PORTAL}.erp`,
      url: 'portal/erp'
    },
    gestorRH: {
      state: 'gestorrh_colaboradoreshome',
      url: 'gestorrh/colaboradoreshome'
    },
    gestorServico: {
      state: 'gestorservico_colaboradoreshome',
      url: 'gestorservico/colaboradoreshome'
    },
    colaborador: {
      state: 'colaborador_colaboradoreshome',
      url: 'colaborador/colaboradoreshome'
    },
    clientportalaccount: {
      state: `${STATE_NAME_PORTAL}.cpa`,
      url: 'portal/cpa'
    }
  },
  alert: {
    timeout: 5000
  },
  formatsettings: {
    date: 'dd-MM-yyyy',
    decimais: 2
  },
  documentosComerciais: {
    tipos: {
      outros: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      comprasEfectivas: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      consultasaFornecedores: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      encomendasClientes: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      encomendasFornecedores: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      folhasdeObra: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      guiasTransporteRemessa: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      propostasaClientes: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      reservasClientes: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      trfArmazensEntradas: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      trfArmazensSaidas: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      vendasEfectivas: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      consignacoesClientes: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      consignacoesFornecedores: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      recibosClientes: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      pagamentosFornecedores: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      none: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      planoProducao: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      preEncomendasClientes: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      custosAdicionais: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      notaCredito: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      acertoCustoPadrao: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      entradasDiversas: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      saidasDiversas: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      },
      guiasTransporteFornecedores: {
        'doc 1': {
          visivel: false
        },
        documentoPorDefeito: 0
      }
    }
  },
  contabilidade: {
    recibos: {
      meioPagamentoOmissao: 1
    },
    decimais: {
      valor: 2,
      valorDesconto: 2,
      percDesconto: 2,
      desconto: 2,
      valorME: 2,
      cambio: 2
    },
    controloIva: {
      CampoOficial40: '40',
      CampoOficial41: '41'
    },
    extratos: {
      reportImpressao: ''
    },
    balancetes: {
      reportImpressao: ''
    },
    balancetesAnalitica: {
      reportImpressao: ''
    },
    movimentosemaberto: {
      reportImpressao: ''
    },
    impdecpe: {
      reportImpressao: 'DecPer.fr3'
    },
    svat: {
      reportImpressao: 'DFSVAT1.fr3'
    },
    conciliacaoBancaria: {
      reportImpressao: 'ConcilMapa01.fr3'
    },
    transferirSaldo: {
      manterContaOrigem: false
    },
    pagamentos: {
      meioPagamentoOmissao: -1
    },
    diferencaIva: 1,
    moeda: '€',
    reparticaocc: {
      usadata: false
    },
    listagemdiarios: {
      reportImpressao: ''
    }
  },
  empresa: {
    anoEmCursoIRC: undefined,
    codIso3166: undefined,
    codMoeda: undefined,
    demonstracao: undefined,
    ignoreLocked: undefined,
    islocked: false,
    motivoislocked: '',
    ncontribtoc: '',
    ncontribuemp: '',
    nEmpresa: '',
    nome: '',
    periodo: '',
    periodoDaData: '',
    periodoEncerrado: '',
    periodoIvaTrimestral: false,
    regimeIva: undefined,
    tipoAnalitica: undefined,
    usaConfigAnalitica: false,
    verificacaoEfetuada: false,
    email: '',
    temContabilidadeDigital: false,
    temContabilidadeDigitalClientConnect: false,
    temCGBanking: false,
    abreviaturaMoeda: '',
    naoprocessasalarios: false,
    sujeitaAsLeisVigoramEmPortugal: true,
    sujeitaAsLeisVigoramEmAngola: false,
    sujeitaAsLeisVigoramEmMocambique: false,
    codPais: undefined,
    nomePais: '',
    abrevFiscal: '',
    cgon: {
      allowAddFiles: false,
      repositorySize: 0, // Megabytes
      cgBankingExpired: false
    },
    erpcloud: {
      ficheiros: {
        maxFileSize: undefined
      }
    }
  },
  licenca: {
    licId: 0,
    nome: 'demo',
    descricao: 'descDemo',
    nempresas: 2147483647,
    nutilizadores: 2147483647,
    portalcolaborador: 2147483647,
    clientPortalAccount: 2147483647,
    portalContabilidade: 2147483647,
    portalRH: 2147483647,
    portalAtivos: 2147483647,
    modoCGOn: false,
    storeMode: EStoreMode.None,
    storeModePublic: false
  },
  erp: {
    versao: '0.0.0.0',
    dataversao: '01-01-1970',
    emManutencao: false
  },
  clientesFornecedores: {
    contaIdentificacaoAlternativa: '',
    contaIdentificacaoAlternativaFornecedores: '',
    radicalClientes: '',
    radicalFornecedores: '',
    radicalOutrosDevedoresCredores: ''
  },
  pca: {
    periodo: EPCAPeriodo.ENCERRADO,
    reportrecibosal: 'RECIBA5.FR3'
  },
  rh: {
    refeicoes: {
      numMax: 99999
    },
    proceSegSocial: {
      reports: {
        Mapacx: {
          reportImpressao: 'AMAPCXA4.fr3'
        },
        MapacxRE: {
          reportImpressao: 'PagSegSocial.fr3'
        }
      }
    },
    proceDMRAT: {
      reports: {
        reportImpressao: 'DeclMensalREmun.FR3'
      }
    },
    recibosVencimento: {
      reports: {
        RECSAL: {
          reportImpressao: 'recibo1.fr3'
        }
      }
    },
    marcacaoEventos: {
      usaCalenEstabelecimentoEmpregado: false
    }
  },
  portais: {
    lastPortal: undefined
  },
  schedule: {
    usaAgendamento: false
  },
  acessos: {
    erpcloud: {
      ativos: {
        fichas: {
          visualizarFichas: 1,
          criarFichas: 1,
          editarFichas: 1,
          apagarFichas: 1,
          calcularAnularDepreciacoes: 1,
          operacoesAbatesVendas: 1
        }
      },
      contabilidade: {
        documentos: {
          visualizarDocumentos: 1,
          criarDocumentos: 1,
          editarDocumentos: 1,
          anularDocumentos: 1
        }
      },
      addons: {
        bankingAutomation: {
          acesso: true
        }
      },
      gdocs: false,
      gestaoComercial: {
        editarContaArtigoFamilia: 0,
        editarMeioPagamento: 0,
        documentos: {
          comprasefetivas: {
            visualizarDocumentos: 1,
            criarDocumentos: 1,
            editarDocumentos: 1,
            anularDocumentos: 1
          },
          encomendasclientes: {
            visualizarDocumentos: 1,
            criarDocumentos: 1,
            editarDocumentos: 1,
            anularDocumentos: 1
          },
          encomendasfornecedores: {
            visualizarDocumentos: 1,
            criarDocumentos: 1,
            editarDocumentos: 1,
            anularDocumentos: 1
          },
          entradasdiversas: {
            visualizarDocumentos: 1,
            criarDocumentos: 1,
            editarDocumentos: 1,
            anularDocumentos: 1
          },
          guiastransporte: {
            visualizarDocumentos: 1,
            criarDocumentos: 1,
            editarDocumentos: 1,
            anularDocumentos: 1
          },
          propostasclientes: {
            visualizarDocumentos: 1,
            criarDocumentos: 1,
            editarDocumentos: 1,
            anularDocumentos: 1
          },
          saidasdiversas: {
            visualizarDocumentos: 1,
            criarDocumentos: 1,
            editarDocumentos: 1,
            anularDocumentos: 1
          },
          vendasefetivas: {
            visualizarDocumentos: 1,
            criarDocumentos: 1,
            editarDocumentos: 1,
            anularDocumentos: 1
          },
          propostasfornecedores: {
            visualizarDocumentos: 1,
            criarDocumentos: 1,
            editarDocumentos: 1,
            anularDocumentos: 1
          }
        }
      }
    }
  },
  gestaoComercial: {
    Stocks: {
      ClassificacaoArtigoTemplate: 0
    },
    docsComerciasEstatisticas: {
      reports: {
        reportImpressao: 'Estatistica.FR3'
      }
    },
    analiseMargemLucro: {
      reports: {
        reportImpressao: 'AnaliseMargemLucro.FR3',
        titleReportImpressao: 'Listagem Análise da Margem de Lucro'
      }
    },
    docsComerciasEstatisticasDoc: {
      reports: {
        reportImpressao: 'DadosCabecalho.FR3'
      }
    },
    debitosDiretosSEPA: {
      reportImpressao: 'DebitosDiretosSEPA01.FR3',
      reportClienteImpressao: 'DDSEPAClifo01.FR3'
    },
    decimais: {
      quantidades: 4,
      valoresLinha: 3,
      valoresUnitarios: 4,
      stocks: {
        valores: 4,
        quantidades: 4
      }
    },
    margens: {
      arredManualMax: 0
    },
    faturaDigital: {
      docfas: {
        modoFuncionamento: 1
      },
      clientes: {
        modoFuncionamento: 1
      },
      tipoCertificado: -1,
      nomeCertificado: '',
      ativa: false
    },
    avisosCobranca: {
      vendedores: false,
      condicaoPagamento: false
    }
  },
  geral: {
    contabilidadeDigital: {
      maxUploadFilesAtATime: undefined
    }
  },
  portalcolaborador: {
    reportrecibosal: 'RECIBA5.FR3'
  }
});
