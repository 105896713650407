import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_ATUALIZA_FERIAS_EMP} from './rhAtualizaFeriasEmp.module.interface';
import {RHAtualizaFeriasEmpModuleComponent} from './components/rhAtualizaFeriasEmp.module.component';

export const MODULE_RH_ATUALIZA_FERIAS_EMP: IModuleDefinition = {
  name: MODULE_NAME_RH_ATUALIZA_FERIAS_EMP,
  state: {
    url: `/${MODULE_NAME_RH_ATUALIZA_FERIAS_EMP}`,
    component: RHAtualizaFeriasEmpModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhatualizaferiasemp'
    }
  }
};
