<div class="ddsepa-container">
  <pl-alert type="info" [closeable]="false" *ngIf="docListEmissao.length">
    <ng-container><i class="fa fa-fw fa-info-circle me-1" aria-hidden="true"></i></ng-container>
    <ng-container>
      <span [translate]="'debDiretosSEPA.messages.existemNdocumentosDisponiveisParaEmissao'" [translateParams]="{count: docListEmissao.length}"></span>
    </ng-container>
  </pl-alert>

  <pl-tabs [activeId]="activeTabId" (activeIdChange)="changedTab($event)" [destroyOnHide]="false">
    <pl-tab [id]="tabPorLiquidarId">
      <div *plTabTitle><i class="fa fa-fw fa-cloud-upload me-1"></i><span [translate]="'debDiretosSEPA.strings.porLiquidar'"></span></div>
    </pl-tab>
    <pl-tab [id]="tabLiquidadoId">
      <div *plTabTitle><i class="fa fa-fw fa-cloud-download me-1"></i><span [translate]="'debDiretosSEPA.strings.liquidado'"></span></div>
    </pl-tab>
    <pl-tab [id]="tabErrosLiquidacaoId">
      <div *plTabTitle>
        <i class="fa fa-fw fa-times-circle me-1"></i><span [translate]="'debDiretosSEPA.strings.erroLiquidacao'"></span>
        <span class="badge ms-2" [translate]="errosLiquidacaoCount"></span>
      </div>
    </pl-tab>
  </pl-tabs>

  <ng-container *ngIf="activeTabId === tabLiquidadoId || activeTabId === tabPorLiquidarId">
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <pl-group>
          <label [translate]="'debDiretosSEPA.strings.ficheirosEmitidos'"></label>
          <edit>
            <pl-autocomplete
              #ficheirosEmitidosAc
              [model]="modelFicheirosEmitidos"
              (modelChange)="ficheirosEmitidosChanged($event)"
              [rowTemplate]="rowTemplateFicheirosEmitidos"
              [properties]="propertiesAutocompleteFicheirosEmitidos"
              [output]="outputFicheirosEmitidos">
              <ng-template plAutocompleteDropdownContent let-autocomplete="component">
                <div class="pl-autocomplete-dropdown ddsepa-ficheiros-emitidos-dropdown">
                  <dx-data-grid
                    [cgDxDataGrid]="dataGridFicheirosEmitidos"
                    [cgDxDataGridInstanceName]="dataGridFicheirosEmitidosInstanceName"
                    [dataSource]="dataGridFicheirosEmitidos.dataSource"
                    (onContentReady)="onDataGridFicheirosEmitidosContentReady()"
                    (onInitialized)="onDataGridFicheirosEmitidosInitialized($event)"
                    (onFocusedRowChanged)="onDataGridFicheirosEmitidosFocusedRowChanged($event)">
                  </dx-data-grid>
                </div>
              </ng-template>
            </pl-autocomplete>
          </edit>
          <actions>
            <pl-button klass="btn btn-sm btn-warning" [onClick]="fnNotificar" *ngIf="modelFicheirosEmitidos?.loteFicheiro">
              <i class="fa fa-fw fa-bell me-1"></i>&nbsp;<span [translate]="'debDiretosSEPA.buttons.notificar'"></span>
            </pl-button>

            <pl-button klass="btn btn-sm btn-danger ms-2" [onClick]="fnAnular" *ngIf="modelFicheirosEmitidos?.loteFicheiro && activeTabId === tabPorLiquidarId">
              <i class="fa fa-fw fa-ban ms-1"></i>&nbsp;<span [translate]="'global.btn.nullify'"></span>
            </pl-button>
          </actions>
        </pl-group>

        <div class="ddsepa-lote-ficheiro-valor" *ngIf="modelFicheirosEmitidos.valor">
          <label class="me-1" [translate]="'debDiretosSEPA.strings.valor'"></label>:<span class="ms-1">{{ modelFicheirosEmitidos.valor | cgcCurrency }}</span>
        </div>
      </div>
    </div>

    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      [dataSource]="dataGridDefinition.dataSource"
      cgDxDataGridInstanceName="ddsepa"
      (onContentReady)="onContentReady()"
      (onInitialized)="onDataGridInitialized($event)"
      (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
      >>
      <div *dxTemplate="let item of 'cellTemplateActions'">
        <span class="ddsepa-row-menu" tabindex="-1" ngbDropdown container="body">
          <span class="mapa-pagamentos-entity-dropdown" ngbDropdownToggle><i class="fa fa-bars" aria-hidden="true"></i></span>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button type="button" ngbDropdownItem (click)="openRecordModal(debDiretosSEPAOpenType.FATURACAO, item.data)">
              <span [translate]="'debDiretosSEPA.strings.documentoFaturacao'"></span>
            </button>
            <button [disabled]="!item.data.importPeriodo?.length" type="button" ngbDropdownItem (click)="openRecordModal(debDiretosSEPAOpenType.RECIBO_LIQUIDACAO, item.data)">
              <span [translate]="'debDiretosSEPA.strings.reciboLiquidacao'"></span>
            </button>
            <button type="button" ngbDropdownItem (click)="openRecordModal(debDiretosSEPAOpenType.CLIENTE, item.data)">
              <span [translate]="'debDiretosSEPA.strings.cliente'"></span>
            </button>
            <button type="button" ngbDropdownItem (click)="openLogModal(item.data)">
              <span [translate]="'debDiretosSEPA.strings.historico'"></span>
            </button>
          </div>
        </span>
      </div>
    </dx-data-grid>
  </ng-container>

  <ng-container *ngIf="activeTabId === tabErrosLiquidacaoId">
    <dx-data-grid
      [cgDxDataGrid]="dataGridErroLiquidacaoDefinition"
      [dataSource]="dataGridErroLiquidacaoDefinition.dataSource"
      cgDxDataGridInstanceName="ddsepaErrosLiquidacao"
      (onInitialized)="onDataGridErroLiquidacaoInitialized($event)"
      (onContextMenuPreparing)="onDataGridErroLiquidacaoContextMenuPreparing($event)">
      <div *dxTemplate="let item of 'cellTemplateActions'">
        <span class="ddsepa-row-menu" tabindex="-1" ngbDropdown container="body">
          <span class="mapa-pagamentos-entity-dropdown" ngbDropdownToggle><i class="fa fa-bars" aria-hidden="true"></i></span>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button type="button" ngbDropdownItem (click)="disponivelParaNovaEmissao(item.data)">
              <span [translate]="'debDiretosSEPA.strings.disponivelParaNovaEmissao'"></span>
            </button>
            <button type="button" ngbDropdownItem (click)="openRecordModal(debDiretosSEPAOpenType.FATURACAO, item.data)">
              <span [translate]="'debDiretosSEPA.strings.documentoFaturacao'"></span>
            </button>
            <button type="button" ngbDropdownItem (click)="openRecordModal(debDiretosSEPAOpenType.CLIENTE, item.data)">
              <span [translate]="'debDiretosSEPA.strings.cliente'"></span>
            </button>
            <button type="button" ngbDropdownItem (click)="openLogModal(item.data)">
              <span [translate]="'debDiretosSEPA.strings.historico'"></span>
            </button>
          </div>
        </span>
      </div>
    </dx-data-grid>
  </ng-container>
</div>
