import {Component, Injector, Input} from '@angular/core';
import {isBoolean} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'dgemps-full-edit-delete-prompt-modal-component',
  templateUrl: './dgempsFull.edit.deletePrompt.modal.component.html'
})
export class DGEMPSFullEditDeletePromptModalComponent extends CGModalComponent<boolean> {
  @Input() public codEmp: number;
  public apenasUltimoHistorico: boolean;
  public invalid: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.invalid = false;
  }

  public close(): void {
    this.invalid = !isBoolean(this.apenasUltimoHistorico);
    if (!this.invalid) {
      super.close(this.apenasUltimoHistorico);
    }
  }
}
