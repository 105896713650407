import {merge} from 'lodash-es';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IPlNavWizardCallback, IPlNavWizardDefinition, IPlNavWizardEventBeforeChange, IPlNavWizardEventStep, PlAlertService, timeout} from 'pl-comps-angular';
import {DDFIXOSFALSE, DDFIXOSTRUE, ENTITY_NAME_REMUN, IRHRecolhaDadosStateParams, VALORABONO} from '../../rhRecolhaDados.entity.interface';
import {ECampoCalculadoME} from '../../../../modules/portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {ETipoAbonoDesconto} from '../../../abdes/abdes.entity.interface';
import {ETipoValorUnitarioRecolher} from '../../../../datasources/tipovalorunitariorecolher/tipoValorUnitarioRecolher.datasource.interface';
import {focusElement} from '../../../../../common/utils/element.utils';
import {IJsonDadosValoresUnitarios, IJsonREMDadosValoresUni, IJsonREMDecimaisComponentes, IJsonREMUNFX} from '../../jsonRHRecolhaDados.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {RHRecolhaDadosService} from '../../rhRecolhaDados.entity.service';
import {SubRefeicaoModalComponent} from '../../modals/subRefeicao.modal.component';
import {TDate} from '../../../../../common/dates';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnEditingStart, IDevExpressDataGridEventOnSaved} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IApiRequestConfigWithBody} from '../../../../services/api/api.service.interface';

const STEP_INDEX_TIPO_RECOLHA = 0;
const STEP_INDEX_DADOS_RECOLHA = 1;
const ID_TIPO_3 = 3;
const ID_TIPO_4 = 4;
const ID_TIPO_8 = 8;

@Component({
  selector: 'rh-recolhadados-edit',
  templateUrl: './rhRecolhaDados.entity.edit.component.html'
})
export class RHRecolhaDadosEditComponent extends ModuloEntityDetailComponent<IJsonREMDadosValoresUni> implements OnInit {
  @Input() public decimalsLimit: IJsonREMDecimaisComponentes;
  @Input() public defaultRecolhaDados: IJsonREMUNFX;

  public readonly tipoAbonoDesconto: typeof ETipoAbonoDesconto;
  public readonly rhtipoprocessOutput: string;
  public readonly definition: IPlNavWizardDefinition;
  public readonly plWizardCallback: IPlNavWizardCallback;

  public dataGridDefinition: IDevExpressDataGrid<IJsonDadosValoresUnitarios>;
  public formProcess: UntypedFormGroup;
  public isRecolhaDadosFixos: number;
  public isByCodEmp: boolean;
  public todosProcessamentos: boolean;
  public dadosValoresUnit: Array<IJsonDadosValoresUnitarios>;
  public valAbono: number;
  public ddFixosFalse: number;
  public ddFixosTrue: number;
  public promise: Promise<void>;

  private readonly _isFromBatchAdd: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _rhRecolhaDadosService: RHRecolhaDadosService
  ) {
    super(_injector);
    this.tipoAbonoDesconto = ETipoAbonoDesconto;
    this.rhtipoprocessOutput = '{{tipoProcessamento}} - {{descricao}}';
    this.definition = {
      items: []
    };
    this.plWizardCallback = {};
    this.dadosValoresUnit = [];
    this.isByCodEmp = true;
    this._isFromBatchAdd = false;
    this.valAbono = VALORABONO;
    this.ddFixosFalse = DDFIXOSFALSE;
    this.ddFixosTrue = DDFIXOSTRUE;
    const params: IRHRecolhaDadosStateParams = <IRHRecolhaDadosStateParams>this._transition.params();
    if (params.fromBatchAdd) {
      this._isFromBatchAdd = params.fromBatchAdd;
      timeout().then(() => {
        this.isByCodEmp = params.isByCodEmp;
        return this.plWizardCallback.setStep(this.definition.items[STEP_INDEX_DADOS_RECOLHA]);
      });
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.isRecolhaDadosFixos = this.entityName === ENTITY_NAME_REMUN ? DDFIXOSFALSE : DDFIXOSTRUE;
    this.entity.getDeleteMessage = (model: IJsonREMDadosValoresUni, translate: TranslateService) => ({
      title: translate.instant('rhrecolhadados.apagarregisto'),
      message: translate.instant('entity.delete.message')
    });
    this._setDefaultModel();
    const cellValor: IDevExpressDataGridColumn = !this.model.rem.empregadoMoedaEstrangeira
      ? {dataField: 'valor', dataType: 'double', caption: 'rhrecolhadados.table.valor'}
      : {dataField: 'valorME', dataType: 'double', caption: 'rhrecolhadados.table.valorme'};
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'seleccionado', dataType: 'boolean', caption: 'rhrecolhadados.table.sel', editCellTemplate: 'editCellTemplateSel', alignment: 'center', showEditorAlways: true},
        {dataField: 'tipoValorUnitarioRecolherStr', dataType: 'string', caption: 'rhrecolhadados.table.tipo', width: 200, allowEditing: false},
        cellValor,
        {dataField: 'percAumentoHoraExtra', dataType: 'double', caption: 'rhrecolhadados.table.horaextra', format: {suppressZeros: true}},
        {dataField: 'perctDescSeguro', dataType: 'double', caption: 'rhrecolhadados.table.pagaseguro'}
      ],
      dataSource: [],
      editing: {mode: 'cell', startEditAction: 'click', selectTextOnEditStart: true, allowUpdating: true, refreshMode: 'repaint'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      paging: {enabled: false, pageSize: 13},
      pager: {visible: false},
      remoteOperations: false,
      toolbar: {visible: false}
    };
  }

  public async save(config?: IApiRequestConfigWithBody<IJsonREMDadosValoresUni>): Promise<IJsonREMDadosValoresUni> {
    if (this.model.rem.temPerdaSubRefeicao) {
      let introduzirDias = true;
      await this._cgModalService.showOkCancel('global.text.warning', this._translateService.instant('rhrecolhadados.modal.avisodesc', {abonodesc: this.model.rem.nomeAbonoDesconto})).catch(() => {
        introduzirDias = false;
      });
      if (introduzirDias) {
        const response: HttpResponse<IJsonREMUNFX> = await this._rhRecolhaDadosService.postCalculaNDiasSubRefeicao(this.entity.name, this.model.rem);
        this.model.rem = response.body;
        this.model.rem.adicionaSubRefeicaoAuto = true;
        const modalInstance: NgbModalRef = this._cgModalService.showVanilla(SubRefeicaoModalComponent, {size: 'md'});
        const componentInstance: SubRefeicaoModalComponent = modalInstance.componentInstance;
        componentInstance.remun = this.model.rem;
        await modalInstance.result.catch(() => undefined);
      }
      if (this.type === this.stateTypes.EDIT) {
        await this._cgModalService.showOkCancel('global.text.confirmation', 'rhrecolhadados.modal.continuar');
      }
    }
    return super.save(config);
  }

  public novo(): Promise<void> {
    const rem: IJsonREMUNFX = this._emptyModelRem();
    const {tipoProcessamento, nomeTipoProcessamento, codEMP, nomeEmpr, abonoDesc, codABDESC, nomeAbonoDesconto, data, dataProcess} = this.model.rem;
    rem.tipoProcessamento = tipoProcessamento;
    rem.nomeTipoProcessamento = nomeTipoProcessamento;
    if (this.isByCodEmp) {
      rem.codEMP = codEMP;
      rem.nomeEmpr = nomeEmpr;
    } else {
      rem.abonoDesc = abonoDesc;
      rem.codABDESC = codABDESC;
      rem.nomeAbonoDesconto = nomeAbonoDesconto;
      rem.data = data;
      rem.dataProcess = dataProcess;
    }
    const params: IRHRecolhaDadosStateParams = {
      defaultRecolhaDados: rem,
      fromBatchAdd: true,
      isByCodEmp: this.isByCodEmp
    };
    return super.novo(params);
  }

  public duplicate(): Promise<void> {
    const rem: IJsonREMUNFX = this._emptyModelRem();
    const {tipoProcessamento, nomeTipoProcessamento, codEMP, nomeEmpr, abonoDesc, codABDESC, nomeAbonoDesconto, data, dataProcess, quantidade, valor} = this.model.rem;
    rem.tipoProcessamento = tipoProcessamento;
    rem.nomeTipoProcessamento = nomeTipoProcessamento;
    rem.codEMP = codEMP;
    rem.nomeEmpr = nomeEmpr;
    rem.nomeAbonoDesconto = nomeAbonoDesconto;
    rem.abonoDesc = abonoDesc;
    rem.codABDESC = codABDESC;
    rem.data = data;
    rem.dataProcess = dataProcess;
    rem.quantidade = quantidade;
    rem.valor = valor;
    const params: IRHRecolhaDadosStateParams = {
      defaultRecolhaDados: rem,
      fromBatchAdd: true,
      isByCodEmp: this.isByCodEmp
    };
    return super.duplicate({params: params});
  }

  public onEditingStart(event: IDevExpressDataGridEventOnEditingStart<IJsonDadosValoresUnitarios>): void {
    if (event.column.dataField === 'percAumentoHoraExtra') {
      if (event.data.idTipo !== ID_TIPO_3 && event.data.idTipo !== ID_TIPO_4 && event.data.idTipo !== ID_TIPO_8) {
        event.cancel = true;
      }
    }
  }

  public onSaved(event: IDevExpressDataGridEventOnSaved<IJsonDadosValoresUnitarios>): Promise<void> {
    return this.selectedChanged(<IJsonDadosValoresUnitarios>event.changes[0].data);
  }

  public getValoresByCodEmp(): Promise<void> {
    if (this.model.rem.codEMP && this.model.rem.codABDESC) {
      this.promise = this._rhRecolhaDadosService.getEmpRecolhaDadosManual(this.entityName, this.model.rem).then((response: HttpResponse<IJsonREMDadosValoresUni>) => {
        this.model.rem = {...this.model.rem, ...response.body.rem};
        this.model.dadosValoresUni = response.body.dadosValoresUni;
      });
      return this.promise;
    }
    return Promise.resolve();
  }

  public abonoDescChanged(): void {
    this.model.rem.codABDESC = '';
    this.model.rem.nomeAbonoDesconto = '';
  }

  public selectedChanged(linha: IJsonDadosValoresUnitarios): Promise<void> {
    for (const line of this.model.dadosValoresUni) {
      if (line.idTipo !== linha.idTipo) {
        line.seleccionado = false;
      }
      if (line.idTipo === linha.idTipo && !linha.seleccionado) {
        line.seleccionado = true;
      }
    }
    this.model.rem.tipoVALUNIT = linha.tipoValorUnitarioRecolher;
    this.promise = this._rhRecolhaDadosService.postValoresUnitariosTableChanged(this.entityName, this.model).then((response: HttpResponse<IJsonREMDadosValoresUni>) => {
      this.model.rem = {...this.model.rem, ...response.body.rem};
      this.model.dadosValoresUni = response.body.dadosValoresUni;
    });
    return this.promise;
  }

  public quantidadeChanged(): Promise<void> {
    if (this.model.rem.codEMP && this.model.rem.codABDESC) {
      this.promise = this._rhRecolhaDadosService.postQuantidadeChanged(this.entityName, this.model).then((response: HttpResponse<IJsonREMDadosValoresUni>) => {
        this.model.rem = {...this.model.rem, ...response.body.rem};
        this.model.dadosValoresUni = response.body.dadosValoresUni;
      });
      return this.promise;
    }
    return Promise.resolve();
  }

  public valorChanged(): Promise<void> {
    if (this.model.rem.codEMP && this.model.rem.codABDESC) {
      const item = this.model.dadosValoresUni.find((dado: IJsonDadosValoresUnitarios) => dado.seleccionado && dado.idTipo === ETipoValorUnitarioRecolher.ValorVariavel);
      if (item) {
        if (this.model.rem.empregadoMoedaEstrangeira) {
          item.valorME = this.model.rem.valor;
        }
        if (!this.model.rem.empregadoMoedaEstrangeira) {
          item.valor = this.model.rem.valor;
        }
      }
      this.promise = this._rhRecolhaDadosService.postValorChanged(this.entityName, this.model).then((response: HttpResponse<IJsonREMDadosValoresUni>) => {
        this.model.rem = {...this.model.rem, ...response.body.rem};
        this.model.dadosValoresUni = response.body.dadosValoresUni;
      });
      return this.promise;
    }
    return Promise.resolve();
  }

  public cambioChanged(): Promise<void> {
    if (this.model.rem.codEMP && this.model.rem.codABDESC) {
      const item = this.model.dadosValoresUni.find((dado: IJsonDadosValoresUnitarios) => dado.seleccionado && dado.idTipo === ETipoValorUnitarioRecolher.ValorVariavel);
      if (item) {
        item.valorME = this.model.rem.cambioMEParaMC;
      }
      this.promise = this._rhRecolhaDadosService.postCambioChanged(this.entityName, this.model).then((response: HttpResponse<IJsonREMDadosValoresUni>) => {
        this.model.rem = {...this.model.rem, ...response.body.rem};
        this.model.dadosValoresUni = response.body.dadosValoresUni;
      });
      return this.promise;
    }
    return Promise.resolve();
  }

  public valorMEChanged(): Promise<void> {
    if (this.model.rem.codEMP && this.model.rem.codABDESC) {
      const item = this.model.dadosValoresUni.find((dado: IJsonDadosValoresUnitarios) => dado.seleccionado && dado.idTipo === ETipoValorUnitarioRecolher.ValorVariavel);
      if (item) {
        item.valorME = this.model.rem.cambioMEParaMC;
      }
      this.promise = this._rhRecolhaDadosService.postValorMEChanged(this.entityName, this.model).then((response: HttpResponse<IJsonREMDadosValoresUni>) => {
        this.model.rem = {...this.model.rem, ...response.body.rem};
        this.model.dadosValoresUni = response.body.dadosValoresUni;
      });
      return this.promise;
    }
    return Promise.resolve();
  }

  public setTodosProcessos(value: boolean): void {
    this.model.rem.todosProcessamentos = value;
    if (this.model.rem.todosProcessamentos) {
      delete this.model.rem.tipoProcessamento;
      delete this.model.rem.nomeTipoProcessamento;
      this.model.rem = {...this.model.rem};
    }
  }

  public getBtnClass(type: ECampoCalculadoME): string {
    return this.isLocked(type) ? 'btn-warning' : 'btn-light';
  }

  public doLock(type: ECampoCalculadoME): void {
    this.model.rem.campoMECalculado = type;
  }

  public getLockClass(type: ECampoCalculadoME): string {
    return this.isLocked(type) ? 'fa-lock' : 'fa-unlock-alt';
  }

  public isLocked(type: ECampoCalculadoME): boolean {
    return type === this.model.rem.campoMECalculado;
  }

  public stepChanged({currentStep}: IPlNavWizardEventStep): void {
    if (currentStep === this.definition.items[STEP_INDEX_DADOS_RECOLHA]) {
      setTimeout(() => {
        let input: HTMLElement;
        if ((!this._isFromBatchAdd && this.isByCodEmp) || (this._isFromBatchAdd && !this.isByCodEmp)) {
          input = this._element.querySelector('input[name="codEMP"]');
        }
        if ((!this._isFromBatchAdd && !this.isByCodEmp) || (this._isFromBatchAdd && this.isByCodEmp)) {
          input = this._element.querySelector('input[name="codABDESC"]');
        }
        if (input) {
          focusElement(input);
        }
      });
    }
  }

  public onDataEventoChange(date: TDate): void {
    this.model.rem.data = date;
    this.model.rem.dataProcess = date;
  }

  public readonly fnBeforeStepChange = (event: IPlNavWizardEventBeforeChange): Promise<boolean> => this._beforeStepChange(event);

  private _setDefaultModel(): void {
    this.model = merge(
      {},
      {
        rem: {
          ...this._emptyModelRem(),
          ...this.defaultRecolhaDados
        },
        dadosValoresUni: []
      },
      this.model
    );
    if (!this.model.rem.tipoProcessamento && this.model.rem.nomeTipoProcessamento) {
      this.model.rem.tipoProcessamento = 0;
    }
  }

  private _emptyModelRem(): IJsonREMUNFX {
    return {
      tipoProcessamento: undefined,
      data: undefined,
      dataProcess: undefined,
      abonoDesc: ETipoAbonoDesconto.Abono,
      codABDESC: undefined,
      ncodABDESC: undefined,
      codEMP: undefined,
      quantidade: undefined,
      valor: undefined,
      codUnidade: undefined,
      vALINCIDESC: undefined,
      tipoVALUNIT: undefined,
      valorUNIT: undefined,
      vALREMSARRED: undefined,
      codMOEDA: undefined,
      iNSMANUAL: undefined,
      cambioMEParaMC: undefined,
      valorME: undefined,
      campoMECalculado: ECampoCalculadoME.Valor,
      contador: undefined,
      descricao: undefined,
      dtReferencia: undefined,
      remUNID: undefined,
      tipoHorario: undefined,
      repartRepSL: '1',
      remFXID: undefined,
      suspende: undefined,
      nomeTipoUnidade: undefined,
      htrabDia: undefined,
      htrabMes: undefined,
      htrabSemana: undefined,
      nomeTipoProcessamento: undefined,
      nomeAbonoDesconto: undefined,
      nomeEmpr: undefined,
      nomeMoeda: undefined,
      empregadoMoedaEstrangeira: undefined,
      adicionaSubRefeicaoAuto: false,
      ndiasSubAlimentacao: undefined,
      temPerdaSubRefeicao: false,
      todosProcessamentos: true,
      unidade: undefined
    };
  }

  private _beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (currentStep === this.definition.items[STEP_INDEX_TIPO_RECOLHA] && (type === 'next' || (nextStep && nextStep === this.definition.items[STEP_INDEX_DADOS_RECOLHA]))) {
      // Se desativo todos os processamentos então não pode ser 0
      if (!this.model.rem.todosProcessamentos && (Number(this.model.rem.tipoProcessamento) === 0 || !this.model.rem.tipoProcessamento)) {
        this._plAlertService.error('rhrecolhadados.error.invalidStepTodosProcessamentos');
        return Promise.resolve(false);
      }

      if (!this.formProcess.valid) {
        this._plAlertService.error('rhrecolhadados.error.invalidStep');
        return Promise.resolve(false);
      }
    }
    return Promise.resolve(true);
  }
}
