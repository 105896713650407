import {Component, Injector, Input, OnInit} from '@angular/core';
import {ERadix, IPlNavWizardCallback, IPlNavWizardEventBeforeChange, isDefinedNotNull, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IEntityServiceMethodsOverride} from '../../../../components/entity/entity.definition.interface';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnEditingStart,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type {default as dxDataGrid, DataChange} from 'devextreme/ui/data_grid';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {TEntityListAfterRequestFn, TEntityListBeforeRequestFn} from '../../../../components/entity/list/entity.list.component.interface';
import {IEntityServiceQueryRequestConfig} from '../../../../services/entity/entity.service.interface';
import {ENTITY_NAME_ARTIGOS, IArtigo, IArtigosEntityService} from '../../../artigos/artigos.entity.interface';
import {IJsonArtigosProcessParams, IJsonArtigoStockArmazens} from '../../../artigos/jsonArtigo.entity.interface';
import {IApiQueryResponse, THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {HttpResponse} from '@angular/common/http';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {
  EChangeArtigoField,
  EChangeArtigosFieldsInSerieCalculoPreco,
  EChangeArtigosFieldsInSerieWizardSteps,
  IChangeArtigo,
  IChangeArtigoFieldResult,
  IChangeArtigoFieldSaveData,
  IChangeArtigoFieldSaveDataItem,
  IChangeArtigoResumo,
  RADIO_GROUP_CALCULO_PRECO,
  TChangeArtigoField,
  TChangeArtigoFieldsUI
} from './changeArtigosFieldsInSerie.interface';
import CustomStore from 'devextreme/data/custom_store';
import {isNullOrUndefined} from '@uirouter/core';
import {TranslateService} from '@ngx-translate/core';
import {ChangeArtigosFieldsInSerieService} from './changeArtigosFieldsInSerie.service';
import type {TDevExpressDataGridColumnDataType} from '../../../../components/devexpress/datagrid/devexpress.datagrid.types.interface';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';

const GROUP_INDEX_0 = 0;
const GROUP_INDEX_1 = 1;
const GROUP_INDEX_2 = 2;
const GROUP_INDEX_3 = 3;
const DYNAMIC_BAND_NAME = 'GRID_DYNAMIC_BAND';

@Component({
  selector: 'change-artigos-fields-serie-modal',
  templateUrl: './changeArtigosFieldsInSerie.modal.component.html'
})
export class ChangeArtigosFieldsInSerieModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public maintenanceMode: boolean;
  @Input() public serviceMethodsOverride: IEntityServiceMethodsOverride;

  public readonly wizardSteps: typeof EChangeArtigosFieldsInSerieWizardSteps;
  public readonly booleanSelectSource: Array<{value: boolean; name: string}>;
  public readonly navWizardCallback: IPlNavWizardCallback;
  public readonly radioGroupCalculoPreco: IRadioGroup<EChangeArtigosFieldsInSerieCalculoPreco>;

  public dataGridProperties: IDevExpressDataGrid;
  public listFields: string;
  public filter: string;
  public firstTime: boolean;
  public artigosSelDataGridDefinition: IDevExpressDataGrid<IChangeArtigo, string>;
  public artigosResumoDataGridDefinition: IDevExpressDataGrid<IChangeArtigoResumo, string>;
  public changableFieldsUI: TChangeArtigoFieldsUI;
  public calculoPrecoModel: EChangeArtigosFieldsInSerieCalculoPreco;

  private readonly _serviceArtigos: IArtigosEntityService;
  private readonly _updatableColumnBand: IDevExpressDataGridColumn;

  private _selectedLines: Array<IArtigo>;
  private _dataGridInstance: dxDataGrid;
  private _artigosSelSource: Array<IChangeArtigo>;
  private _artigosSelDataGridInstance: dxDataGrid;
  private _artigosResumoDataGridInstance: dxDataGrid;
  private _artigosResumoSource: Array<IChangeArtigoResumo>;
  private _resumoData: Array<IChangeArtigoFieldResult>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _translateService: TranslateService,
    private readonly _changeArtigosFieldsInSerieService: ChangeArtigosFieldsInSerieService
  ) {
    super(_injector);
    this._artigosSelSource = [];
    this._artigosResumoSource = [];
    this.navWizardCallback = {};
    this.booleanSelectSource = [
      {value: false, name: 'global.btn.no'},
      {value: true, name: 'global.btn.yes'}
    ];
    this.calculoPrecoModel = EChangeArtigosFieldsInSerieCalculoPreco.PRECO_COM_IVA;
    this.wizardSteps = EChangeArtigosFieldsInSerieWizardSteps;
    this._serviceArtigos = this._entityServiceBuilder.build<IArtigo, IArtigosEntityService>(ENTITY_NAME_ARTIGOS);
    this.firstTime = true;
    this.filter = 'artDesactivado=false&artBloqueado=false';
    this._selectedLines = [];
    this._resetChangableFieldsUI();
    this.radioGroupCalculoPreco = RADIO_GROUP_CALCULO_PRECO;

    this._updatableColumnBand = {
      caption: 'changeArtigosFieldsInSerie.strings.camposAAtualizar',
      columns: [
        {dataField: 'artBloqueado', dataType: 'boolean', caption: 'artigos.fields.artBloqueado', allowEditing: false, visible: false},
        {dataField: 'nFamiliaString', dataType: 'string', caption: 'artigos.fields.nFamilia', allowEditing: false, visible: false},
        {dataField: 'nGrFamiliaString', dataType: 'string', caption: 'artigos.fields.nGrFamilia', allowEditing: false, visible: false},
        {dataField: 'nTpArtString', dataType: 'string', caption: 'artigos.fields.nTpArt', allowEditing: false, visible: false},
        {dataField: 'nSubFaString', dataType: 'string', caption: 'artigos.fields.nSubFa', allowEditing: false, visible: false},
        {dataField: 'unidadeMovString', dataType: 'string', caption: 'artigos.fields.nomeUnidadeMov', allowEditing: false, visible: false},
        {dataField: 'movimStock', dataType: 'boolean', caption: 'artigos.fields.movimStock', allowEditing: false, visible: false},
        {dataField: 'naoPermiteExistNeg', dataType: 'boolean', caption: 'artigos.fields.naoPermiteExistNeg', allowEditing: false, visible: false},
        {dataField: 'temLote', dataType: 'boolean', caption: 'artigos.fields.temLote', allowEditing: false, visible: false},
        {dataField: 'codIvaVendaString', dataType: 'string', caption: 'artigos.fields.nomeIvaVenda', allowEditing: false, visible: false},
        {dataField: 'codIvaCompraString', dataType: 'string', caption: 'artigos.fields.nomeIvaCompra', allowEditing: false, visible: false}
      ]
    };

    this.artigosSelDataGridDefinition = {
      columns: [
        {
          caption: 'changeArtigosFieldsInSerie.strings.artigos',
          fixed: true,
          fixedPosition: 'left',
          columns: [
            {dataField: 'nArtigo', dataType: 'string', caption: 'artigos.fields.nArtigo', allowEditing: false},
            {dataField: 'nome', dataType: 'string', caption: 'artigos.fields.nome', allowEditing: false}
          ]
        },
        this._updatableColumnBand
      ],
      dataSource: new CustomStore({
        key: 'nArtigo',
        load: () => {
          this._artigosSelSource = [];
          this._selectedLines.forEach((artigo) => {
            this._artigosSelSource.push({
              ...artigo,
              nFamiliaString: `${artigo.nFamilia} - ${artigo.nomeFamilia}`,
              nGrFamiliaString: `${artigo.nGrFamilia} - ${artigo.nomeGrFamilia}`,
              nTpArtString: `${artigo.nTpArt} - ${artigo.nomeTpArt}`,
              nSubFaString: `${artigo.nSubFa} - ${artigo.nomeSubFa}`,
              unidadeMovString: `${artigo.unidadeMov} - ${artigo.nomeUnidadeMov}`,
              codIvaVendaString: `${artigo.codIvaVenda} - ${artigo.nomeIvaVenda}`,
              codIvaCompraString: `${artigo.codIvaCompra} - ${artigo.nomeIvaCompra}`
            });
          });
          return this._artigosSelSource;
        }
      })
    };

    this.artigosResumoDataGridDefinition = {
      columns: [
        {
          caption: 'changeArtigosFieldsInSerie.strings.artigos',
          fixed: true,
          fixedPosition: 'left',
          columns: [
            {dataField: 'nArtigo', dataType: 'string', caption: 'artigos.fields.nArtigo', allowEditing: false},
            {dataField: 'nome', dataType: 'string', caption: 'artigos.fields.nome', allowEditing: false}
          ]
        }
      ],
      dataSource: new CustomStore({
        key: 'nArtigo',
        load: () => {
          return this._artigosResumoSource;
        }
      })
    };
  }

  public ngOnInit(): void {
    this.dataGridProperties = {
      stateStoring: {enabled: false},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always', selectAllMode: 'page'},
      editing: {mode: 'batch', startEditAction: 'click', selectTextOnEditStart: true, allowUpdating: false, refreshMode: 'repaint'}
    };
  }

  public onArtigosSelInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._artigosSelDataGridInstance = component;
  }

  public onArtigosResumoInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._artigosResumoDataGridInstance = component;
    this._buildResumoDataGrid();
  }

  public dismiss(): void | Promise<void> {
    this._dataGridInstance.clearGrouping();
    return super.dismiss();
  }

  public onDataGridColumnsPreparing(columns: Array<IDevExpressDataGridColumn>): void {
    for (const column of columns) {
      if (column.dataField === 'nomeTpArt') {
        column.groupIndex = GROUP_INDEX_0;
      }
      if (column.dataField === 'nomeGrFamilia') {
        column.groupIndex = GROUP_INDEX_1;
      }
      if (column.dataField === 'nomeFamilia') {
        column.groupIndex = GROUP_INDEX_2;
      }
      if (column.dataField === 'nomeSubFa') {
        column.groupIndex = GROUP_INDEX_3;
      }
    }
  }

  public onDataGridOnInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    component.columnOption('nomeTpArt', 'visible', true);
    component.columnOption('nomeGrFamilia', 'visible', true);
    component.columnOption('nomeSubFa', 'visible', true);

    component.columnOption('nArtigo', 'allowEditing', false);
    component.columnOption('nome', 'allowEditing', false);
    component.columnOption('nomeFamilia', 'allowEditing', false);
    component.columnOption('nomeTpArt', 'allowEditing', false);
    component.columnOption('nomeGrFamilia', 'allowEditing', false);
    component.columnOption('nomeSubFa', 'allowEditing', false);
    component.columnOption('nomeIvaVenda', 'allowEditing', false);
    component.columnOption('taxaIvaVenda', 'allowEditing', false);
    component.columnOption('precoSemIva', 'allowEditing', false);
    component.columnOption('precoComIva', 'allowEditing', false);
    component.columnOption('movimStock', 'allowEditing', false);
    component.columnOption('temLote', 'allowEditing', false);
    component.columnOption('codvaloriz', 'allowEditing', false);
    component.columnOption('qtdTotal', 'allowEditing', false);

    this._dataGridInstance = component;
  }

  public onDataGridCellPrepared(event: IDevExpressDataGridEventOnCellPrepared): void {
    if (event.rowType === 'data') {
      if (event.column.dataField === 'qtdAdd') {
        event.cellElement.classList.add('datagrid-editable-cell');
      }
    }
  }

  public onDataGridOnSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IArtigo, string>): void {
    this._selectedLines = event.selectedRowsData;
  }

  public onDataGridOnEditingStart(event: IDevExpressDataGridEventOnEditingStart<IArtigo, string>): void {
    if (event?.key) {
      this._dataGridInstance.selectRows([event.key], true);
    }
  }

  public onModelChangeCheckbox(value: boolean, key: string): void {
    this.changableFieldsUI[key].checked = value;

    const col = this._getArtigosSelDataGridChangableColumn(<TChangeArtigoField>key);
    if (isDefinedNotNull(col)) {
      this._artigosSelDataGridInstance.columnOption(col.dataField, 'visible', value);
    }
    if (!value) {
      this.changableFieldsUI[key].entityModel = undefined;
    }
  }

  public splitSizeValuesChanged(): void {
    this._artigosSelDataGridInstance.updateDimensions();
  }

  public readonly fnBeforeRequest: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequest(queryRequestConfig);

  public readonly fnAfterRequest: TEntityListAfterRequestFn<IArtigo> = (response: THttpQueryResponse<IArtigo>) => this._afterRequest(response);

  public readonly fnBeforeChangedStep = (event: IPlNavWizardEventBeforeChange): Promise<boolean> => this._beforeChangedStep(event);

  public readonly fnOnFinalize = (): Promise<void> => this._onFinalize();

  private _beforeChangedStep({currentStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (type === 'set' && currentStep.stepId === EChangeArtigosFieldsInSerieWizardSteps.RELATORIO) {
      return Promise.resolve(false);
    }
    if (currentStep.stepId === EChangeArtigosFieldsInSerieWizardSteps.PESQUISA) {
      if (!this._selectedLines.length) {
        this._plAlertService.error('changeArtigosFieldsInSerie.temSelecionarPeloMenosUmArtigo');
        return Promise.resolve(false);
      }
    } else if (currentStep.stepId === EChangeArtigosFieldsInSerieWizardSteps.ATUALIZACAO) {
      if (type === 'next') {
        const postData: IChangeArtigoFieldSaveData = {
          artigos: this._artigosSelSource.map((o) => o.nArtigo),
          fields: []
        };
        for (const key of Object.keys(this.changableFieldsUI)) {
          const field: TChangeArtigoField = <TChangeArtigoField>key;
          const value = this.changableFieldsUI[field].uiType === 'entity' ? this.changableFieldsUI[field].entityModel : this.changableFieldsUI[field].value;
          if (this.changableFieldsUI[field].checked) {
            if (isNullOrUndefined(value)) {
              this._plAlertService.error(
                this._translateService.instant('changeArtigosFieldsInSerie.valorCampoNaoPodeSerNulo', {
                  campo: this._translateService.instant(this.changableFieldsUI[field].uiLabel)
                })
              );
              return Promise.resolve(false);
            }
            const modelKey: string = this.changableFieldsUI[field].entityModelKey;
            const fieldItem: IChangeArtigoFieldSaveDataItem = {
              artigoField: this._artigoFieldTypeToEnum(field),
              value: this.changableFieldsUI[field].uiType === 'entity' ? this.changableFieldsUI[field].entityModel[modelKey] : this._booleanToInteger(<boolean>this.changableFieldsUI[field].value)
            };
            if (field === 'codIvaVenda') {
              fieldItem.extraData = Number(this.calculoPrecoModel).toString();
            }
            postData.fields.push(fieldItem);
          }
        }
        if (!postData.fields.length) {
          this._plAlertService.error('changeArtigosFieldsInSerie.naoSelecinouNenhumCampoParaAlterar');
          return Promise.resolve(false);
        }

        return this._changeArtigosFieldsInSerieService.process(postData).then((response) => {
          this._resumoData = response.body;
          return true;
        });
      }
    }
    return Promise.resolve(true);
  }

  private _buildResumoDataGrid(): void {
    this._artigosResumoDataGridInstance.deleteColumn(DYNAMIC_BAND_NAME);
    const columns: Array<IDevExpressDataGridColumn> = [];

    this._artigosSelSource.forEach((artigo) => {
      const artigoResumo = this._resumoData.find((a) => a.nArtigo === artigo.nArtigo);
      const artigoResumoSourceItem: IChangeArtigoResumo = {...artigo};
      artigoResumo.changes.forEach((change) => {
        const typeField = this._artigoFieldEnumToType(change.field);
        let dataType: TDevExpressDataGridColumnDataType = 'string';
        if (this.changableFieldsUI[typeField].uiType === 'entity') {
          artigoResumoSourceItem[`${typeField}OldValue`] = `${change.oldValue} - ${change.oldValueDesc}`;
          artigoResumoSourceItem[`${typeField}NewValue`] = `${change.newValue} - ${change.newValueDesc}`;
        } else {
          dataType = 'boolean';
          artigoResumoSourceItem[`${typeField}OldValue`] = parseInt(change.oldValue.toString(), ERadix.Decimal);
          artigoResumoSourceItem[`${typeField}NewValue`] = parseInt(change.newValue.toString(), ERadix.Decimal);
        }
        const colBandName = `${typeField}_BAND`;
        const index = columns.findIndex((c) => c.name === colBandName);
        if (index < 0) {
          columns.push({
            name: colBandName,
            caption: this._translateService.instant(this.changableFieldsUI[typeField].uiLabel),
            alignment: 'center',
            columns: [
              {dataField: `${typeField}OldValue`, dataType: dataType, caption: this._translateService.instant('changeArtigosFieldsInSerie.strings.anterior'), alignment: 'center'},
              {
                dataField: `${typeField}NewValue`,
                dataType: dataType,
                cssClass: 'novo-valor',
                caption: this._translateService.instant('changeArtigosFieldsInSerie.strings.novo'),
                alignment: 'center'
              }
            ]
          });
        }
      });
      this._artigosResumoSource.push(artigoResumoSourceItem);
    });

    this._artigosResumoDataGridInstance.addColumn({
      caption: this._translateService.instant('changeArtigosFieldsInSerie.strings.camposAlterados'),
      name: DYNAMIC_BAND_NAME,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      columns: <any>columns,
      alignment: 'center'
    });
  }

  private _beforeRequest(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    const changes: unknown = this._dataGridInstance.option('editing.changes');
    for (const selectedRow of this._selectedLines) {
      const index = (<Array<DataChange<IArtigo, string>>>changes).findIndex((item: DataChange<IArtigo, string>) => item.key === selectedRow.nArtigo);
      if (index !== -1) {
        selectedRow.qtdAdd = changes[index].data._qtdAdd;
      }
    }
    return queryRequestConfig;
  }

  private async _afterRequest(response: THttpQueryResponse<IArtigo>): Promise<IApiQueryResponse<IArtigo>> {
    const artigosList = [];

    for (const artigo of response.body.list) {
      if (artigo.qtdTotal === 0) {
        artigosList.push(artigo.nArtigo);
      }
    }

    if (artigosList.length === 0) {
      return response.body;
    }

    const artigosProcessParams: IJsonArtigosProcessParams = {nArtigoList: artigosList.toString()};

    await this._serviceArtigos.processaStockArtigosNosArmazens(artigosProcessParams, true).then((resp: HttpResponse<Array<IJsonArtigoStockArmazens>>) => {
      if (resp) {
        for (const stock of resp.body) {
          const idx = response.body.list.findIndex((item) => item.nArtigo === stock.nArtigo);
          if (idx !== -1) {
            response.body.list[idx] = {...response.body.list[idx], qtdTotal: stock.qtdTotal, stockArmazens: stock.list};
          }
        }
      }
    });
    return response.body;
  }

  private _getArtigosSelDataGridChangableColumn(field: TChangeArtigoField): IDevExpressDataGridColumn {
    let col: string = field;
    if (!['artBloqueado', 'movimStock', 'naoPermiteExistNeg', 'temLote'].includes(field)) {
      col = `${field}String`;
    }
    return this._updatableColumnBand.columns.find((item) => item.dataField === col);
  }

  private _artigoFieldTypeToEnum(field: TChangeArtigoField): EChangeArtigoField {
    switch (field) {
      case 'artBloqueado':
        return EChangeArtigoField.BLOQUEADO;
      case 'nFamilia':
        return EChangeArtigoField.NFAMILIA;
      case 'nGrFamilia':
        return EChangeArtigoField.NGRFAMILIA;
      case 'nTpArt':
        return EChangeArtigoField.NTPART;
      case 'nSubFa':
        return EChangeArtigoField.NSUBFA;
      case 'unidadeMov':
        return EChangeArtigoField.UNIDADEMOV;
      case 'movimStock':
        return EChangeArtigoField.MOVIMSTOCK;
      case 'naoPermiteExistNeg':
        return EChangeArtigoField.NAOPERMITEEXISTNEG;
      case 'temLote':
        return EChangeArtigoField.TEMLOTE;
      case 'codIvaVenda':
        return EChangeArtigoField.CODIVAVENDA;
      case 'codIvaCompra':
        return EChangeArtigoField.CODIVACOMPRA;
      default:
        return undefined;
    }
  }

  private _artigoFieldEnumToType(field: EChangeArtigoField): TChangeArtigoField {
    switch (field) {
      case EChangeArtigoField.BLOQUEADO:
        return 'artBloqueado';
      case EChangeArtigoField.NFAMILIA:
        return 'nFamilia';
      case EChangeArtigoField.NGRFAMILIA:
        return 'nGrFamilia';
      case EChangeArtigoField.NTPART:
        return 'nTpArt';
      case EChangeArtigoField.NSUBFA:
        return 'nSubFa';
      case EChangeArtigoField.UNIDADEMOV:
        return 'unidadeMov';
      case EChangeArtigoField.MOVIMSTOCK:
        return 'movimStock';
      case EChangeArtigoField.NAOPERMITEEXISTNEG:
        return 'naoPermiteExistNeg';
      case EChangeArtigoField.TEMLOTE:
        return 'temLote';
      case EChangeArtigoField.CODIVAVENDA:
        return 'codIvaVenda';
      case EChangeArtigoField.CODIVACOMPRA:
        return 'codIvaCompra';
      default:
        return undefined;
    }
  }

  private _booleanToInteger(value: boolean): number {
    return Number(value);
  }

  private _resetChangableFieldsUI(): void {
    this.changableFieldsUI = {
      artBloqueado: {checked: false, value: false, uiType: 'booleanSelect', entityName: undefined, entityModel: undefined, uiLabel: 'artigos.fields.artBloqueado', entityModelKey: undefined},
      nFamilia: {checked: false, value: undefined, uiType: 'entity', entityName: 'familias', entityModel: undefined, uiLabel: 'artigos.fields.nFamilia', entityModelKey: 'nFamilia'},
      nGrFamilia: {checked: false, value: undefined, uiType: 'entity', entityName: 'grandesfamilias', entityModel: undefined, uiLabel: 'artigos.fields.nGrFamilia', entityModelKey: 'nDepart'},
      nTpArt: {checked: false, value: undefined, uiType: 'entity', entityName: 'tiposartigo', entityModel: undefined, uiLabel: 'artigos.fields.nTpArt', entityModelKey: 'codTpArt'},
      nSubFa: {checked: false, value: undefined, uiType: 'entity', entityName: 'subfamilias', entityModel: undefined, uiLabel: 'artigos.fields.nSubFa', entityModelKey: 'nSubFa'},
      unidadeMov: {checked: false, value: undefined, uiType: 'entity', entityName: 'unidadesmovimento', entityModel: undefined, uiLabel: 'artigos.fields.nomeUnidadeMov', entityModelKey: 'codUni'},
      movimStock: {checked: false, value: false, uiType: 'booleanSelect', entityName: undefined, entityModel: undefined, uiLabel: 'artigos.fields.movimStock', entityModelKey: undefined},
      naoPermiteExistNeg: {
        checked: false,
        value: false,
        uiType: 'booleanSelect',
        entityName: undefined,
        entityModel: undefined,
        uiLabel: 'artigos.fields.naoPermiteExistNeg',
        entityModelKey: undefined
      },
      temLote: {checked: false, value: false, uiType: 'booleanSelect', entityName: undefined, entityModel: undefined, uiLabel: 'artigos.fields.temLote', entityModelKey: undefined},
      codIvaVenda: {checked: false, value: undefined, uiType: 'entity', entityName: 'ivas', entityModel: undefined, uiLabel: 'artigos.fields.nomeIvaVenda', entityModelKey: 'codIva'},
      codIvaCompra: {checked: false, value: undefined, uiType: 'entity', entityName: 'ivas', entityModel: undefined, uiLabel: 'artigos.fields.nomeIvaCompra', entityModelKey: 'codIva'}
    };
  }

  private _onFinalize(): Promise<void> {
    this.navWizardCallback.setStep(EChangeArtigosFieldsInSerieWizardSteps.PESQUISA, true, true).then(() => {
      this._selectedLines = [];
      this._artigosSelSource = [];
      this._artigosResumoSource = [];
      this._resumoData = [];
      this._dataGridInstance.clearSelection();
      this._resetChangableFieldsUI();
    });

    return Promise.resolve();
  }
}
