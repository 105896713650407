import {ParamTypeDefinition} from '@uirouter/core';
import {Ng2StateDeclaration} from '@uirouter/angular';
import {ERadix, identity, isEmpty} from 'pl-comps-angular';
import {STATE_NAME_SITE} from '../../common/site';
import moment, {Moment} from 'moment';
import {STATE_NAME_PORTAL} from '../../core/services/portals/portals.service.interface';
import {UIRouterViewIdentityComponent} from '../../core/components/uirouter/uirouter.view.identity.component';

export const STATE_SITE: Ng2StateDeclaration = {
  name: STATE_NAME_SITE,
  url: '?launchMode',
  component: UIRouterViewIdentityComponent,
  params: {
    returnState: {type: 'string', dynamic: true, squash: true, value: null},
    returnStateParams: {type: 'json', dynamic: true, squash: true, value: null},
    launchMode: {type: 'json', dynamic: true, squash: true, value: null}
  },
  redirectTo: STATE_NAME_PORTAL
};

export const UI_ROUTER_URL_TYPE_BOOLEAN: ParamTypeDefinition = {
  decode: (val) => val === 'true',
  encode: (val) => (val ? 'true' : 'false'),
  equals: function (a, b) {
    return this.is(a) && this.is(b) && a === b;
  },
  is: (val) => ['true', 'true', true, false, 0, 1].includes(val),
  pattern: /bool|true|false|0|1/
};

export const UI_ROUTER_URL_TYPE_CG_DATE: ParamTypeDefinition = {
  decode: moment.bind(moment),
  encode: (val: Moment) => (!isEmpty(val) ? val.valueOf().toString(ERadix.Decimal) : ''),
  equals: function (a: Moment, b: Moment) {
    return this.is(a) && this.is(b) && a.isSame(b);
  },
  is: moment.isMoment.bind(moment),
  pattern: /[0-9]+/
};

const REGEX_GUID = new RegExp(/^[{(]?[0-9A-F]{8}[-]?([0-9A-F]{4}[-]?){3}[0-9A-F]{12}[)}]?$/, 'g');
export const UI_ROUTER_URL_TYPE_GUID: ParamTypeDefinition = {
  decode: identity,
  encode: identity,
  pattern: REGEX_GUID,
  equals: (a: string, b: string) => a && b && a.trim() === b.trim(),
  is: (val) => REGEX_GUID.test(val)
};
