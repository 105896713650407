import {Component, Injector, Input} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {ContabilidadeDigitalService} from '../../../../services/contabilidadedigital/contabilidadedigital.service';
import {IJsonDocDigitalizado} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';

@Component({
  selector: 'contabilidadedigital-separar-doc-modal',
  templateUrl: './contabilidadedigital.separardoc.modal.component.html'
})
export class ContabilidadeDigitalSepararDocModalComponent extends CGModalComponent<Array<IJsonDocDigitalizado>> {
  @Input() public docID: string;

  private _docDigitalizado: Array<IJsonDocDigitalizado>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService
  ) {
    super(_injector);
  }

  public close(): void {
    super.close(this._docDigitalizado);
  }

  public actionClose(mantemOriginal: boolean): Promise<void> {
    return this._contabilidadeDigitalService.documents.separarDoc(this.docID, mantemOriginal).then((response: HttpResponse<Array<IJsonDocDigitalizado>>) => {
      this._plAlertService.success('arquivodigital.separardocmodal.success');
      this._docDigitalizado = response.body;
      this.close();
    });
  }
}
