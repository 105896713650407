import moment from 'moment';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EDelphiNumberTypes, isEmpty, isUndefined, PlAlertService} from 'pl-comps-angular';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';
import {ConfigService} from '../../../../services/config/config.service';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DevExpressDataGridUIService} from '../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {docsComerciaisGrupoDocToEntityName, ENTITY_NAME_DOCS_COMERCIAIS, IDocsComerciaisEntityService} from '../../../../entities/docscomerciais/docsComerciais.entity.interface';
import {EGrupoDoc} from '../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {ENTITY_NAME_ARTIGOS} from '../../../../entities/artigos/artigos.entity.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ExtractosArtigosService} from '../../extractosArtigos.module.service';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnInitialized
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumnCustomizeTextCellInfo, TDevExpressDataGridColumnCustomizeTextFn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {IExtractoArtigo, MAX_STRING, MIN_STRING} from './extractosArtigos.component.interface';
import {IJsonArtigo} from '../../../../entities/artigos/jsonArtigo.entity.interface';
import {IJsonDocComercial} from '../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {IJsonExtractoArtigoFilter} from '../../jsonExtractoArtigo.module.interface';
import {TDate} from '../../../../../common/dates';
import {IApiQueryResponse} from '../../../../services/api/api.service.interface';

@Component({
  selector: 'extratos-artigos',
  templateUrl: './extractosArtigos.component.html'
})
export class ExtractosArtigosComponent implements OnInit {
  @Input() public nArtigo: string;
  @Input() public showFilterArtigo: boolean;
  @Output() public readonly evtFilterChanged: EventEmitter<IJsonExtractoArtigoFilter>;

  public readonly dataGrid: IDevExpressDataGrid<IExtractoArtigo, IExtractoArtigo>;
  public hasParam: boolean;
  public filters: IJsonExtractoArtigoFilter;

  private readonly _serviceDocsComerciais: IDocsComerciaisEntityService;
  private _dataGridInstance: dxDataGrid<IExtractoArtigo, IExtractoArtigo>;
  private _maintenanceDocsComerciais: Map<EGrupoDoc, IEntityMaintenanceInstance<IJsonDocComercial>>;
  private _maintenanceArtigos: IEntityMaintenanceInstance<IJsonArtigo>;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    private readonly _plAlertService: PlAlertService,
    private readonly _configService: ConfigService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _extractosArtigosService: ExtractosArtigosService
  ) {
    this.pesquisar = this.pesquisar.bind(this);
    this.nArtigo = '';
    this.evtFilterChanged = new EventEmitter<IJsonExtractoArtigoFilter>();
    this.dataGrid = {
      columns: [
        {dataField: 'nArtigo', caption: 'extractosartigos.fields.nArtigo'},
        {dataField: 'nomeArtigo', caption: 'extractosartigos.fields.nomeArtigo'},
        {dataField: 'dataDoc', caption: 'extractosartigos.fields.dataDoc', dataType: 'date'},
        {dataField: 'nDocAsString', caption: 'extractosartigos.fields.nDocAsString'},
        {dataField: 'nDocfa', caption: 'extractosartigos.fields.nDocfa', dataType: 'number', visible: false},
        {dataField: 'descricaoDocfa', caption: 'extractosartigos.fields.descricaoDocfa', visible: false},
        {dataField: 'nArmazem', caption: 'extractosartigos.fields.nArmazem', dataType: 'number'},
        {dataField: 'nomeArmazem', caption: 'extractosartigos.fields.nomeArmazem', visible: false},
        {dataField: 'nLoteEspecifico', caption: 'extractosartigos.fields.nLoteEspecifico', visible: false},
        {
          dataField: 'preco',
          caption: 'extractosartigos.fields.preco',
          dataType: 'double',
          format: {
            decimalsLimit: this._configService.configurations.gestaoComercial.decimais.valoresUnitarios,
            suppressZeros: false
          },
          customizeText: this._fnCustomizeText
        },
        {
          dataField: 'valorEntrada',
          caption: 'extractosartigos.fields.valorEntrada',
          dataType: 'double',
          format: {
            decimalsLimit: this._configService.configurations.gestaoComercial.decimais.valoresLinha,
            suppressZeros: false
          },
          customizeText: this._fnCustomizeText
        },
        {
          dataField: 'valorSaida',
          caption: 'extractosartigos.fields.valorSaida',
          dataType: 'double',
          format: {
            decimalsLimit: this._configService.configurations.gestaoComercial.decimais.valoresLinha,
            suppressZeros: false
          },
          customizeText: this._fnCustomizeText
        },
        {
          dataField: 'saldoValor',
          caption: 'extractosartigos.fields.saldoValor',
          dataType: 'double',
          format: {
            decimalsLimit: this._configService.configurations.gestaoComercial.decimais.valoresLinha,
            suppressZeros: false
          },
          customizeText: this._fnCustomizeText
        },
        {
          dataField: 'qtdEntrada',
          caption: 'extractosartigos.fields.qtdEntrada',
          dataType: 'double',
          format: {
            decimalsLimit: this._configService.configurations.gestaoComercial.decimais.quantidades,
            suppressZeros: false
          },
          customizeText: this._fnCustomizeText
        },
        {
          dataField: 'qtdSaida',
          caption: 'extractosartigos.fields.qtdSaida',
          dataType: 'double',
          format: {
            decimalsLimit: this._configService.configurations.gestaoComercial.decimais.quantidades,
            suppressZeros: false
          },
          customizeText: this._fnCustomizeText
        },
        {
          dataField: 'saldoQtd',
          caption: 'extractosartigos.fields.saldoQtd',
          dataType: 'double',
          format: {
            decimalsLimit: this._configService.configurations.gestaoComercial.decimais.quantidades,
            suppressZeros: false
          },
          customizeText: this._fnCustomizeText
        },
        {dataField: 'unidadeMov', caption: 'extractosartigos.fields.unidadeMov', dataType: 'number', visible: false},
        {dataField: 'nConta', caption: 'extractosartigos.fields.nConta', visible: false},
        {dataField: 'nomeClifo', caption: 'extractosartigos.fields.nomeClifo', visible: false},
        {type: 'actions', cellTemplate: 'templateActions'}
      ],
      masterDetail: {
        enabled: true,
        template: 'templateMasterDetail'
      }
    };
    this._serviceDocsComerciais = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>(ENTITY_NAME_DOCS_COMERCIAIS);
  }

  public ngOnInit(): void {
    this.hasParam = !isEmpty(this.nArtigo);
    this.showFilterArtigo = isUndefined(this.showFilterArtigo) ? true : this.showFilterArtigo;
    this.filters = {
      nArtigoDe: this.hasParam ? this.nArtigo : MIN_STRING,
      nArtigoAte: this.hasParam ? this.nArtigo : MAX_STRING,
      nArmazemDe: 0,
      nArmazemAte: EDelphiNumberTypes.MaxSmallInt,
      dateDe: moment().subtract(1, 'year').startOf('date'),
      dateAte: moment().startOf('date'),
      calcMovAntesDepoisData: false,
      canProcessXl: true
    };
    this.evtFilterChanged.emit(this.filters);
    if (this.hasParam) {
      this.pesquisar();
    }
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized<IExtractoArtigo, IExtractoArtigo>): void {
    this._dataGridInstance = component;
  }

  public onDataGridCellClick(event: IDevExpressDataGridEventOnCellClick<IExtractoArtigo, IExtractoArtigo>): void {
    devExpressDataGridExpandDetailHandler(event, () => this._loadExtractoArtigoDocComercial(event.data));
  }

  public onDataGridContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IExtractoArtigo, IExtractoArtigo>): void {
    if (event.target === 'content' && event.row?.rowType === 'data') {
      event.event.preventDefault();
      this.openExtractoArtigoContextMenu(<HTMLElement>event.event.target, event.row.data);
    }
  }

  public changedDeNArtigo(value: string): void {
    this.filters.nArtigoDe = value;
    this.filters.nArtigoAte = isUndefined(value) || value === '' ? MAX_STRING : this.filters.nArtigoDe;
  }

  public changedDeNArmazem(value: number): void {
    if (value > EDelphiNumberTypes.MaxSmallInt) {
      value = EDelphiNumberTypes.MaxSmallInt;
      this._plAlertService.info('extractosartigos.info.valormaximonarmazem');
    }
    this.filters.nArmazemDe = value;
    this.filters.nArmazemAte = this.filters.nArmazemDe;
  }

  public changedNArmazemAte(value: number): void {
    if (value > EDelphiNumberTypes.MaxSmallInt) {
      value = EDelphiNumberTypes.MaxSmallInt;
      this._plAlertService.info('extractosartigos.info.valormaximonarmazem');
    }
    this.filters.nArmazemAte = value;
  }

  public changedDateDe(value: TDate): void {
    this.filters.dateDe = moment(value).startOf('date');
  }

  public changedDateAte(value: TDate): void {
    this.filters.dateAte = moment(value).startOf('date');
  }

  public openExtractoArtigoContextMenu(target: HTMLElement | EventTarget, extractoArtigo: IExtractoArtigo): void {
    this._devExpressDataGridUIService.openContextMenu(<HTMLElement>target, [
      {
        caption: 'extractosartigos.contextmenu.openDocCcomercial',
        click: () => this._openMaintenanceDocsComerciais(extractoArtigo)
      },
      {
        caption: 'extractosartigos.contextmenu.openArtigo',
        click: () => this._openMaintenanceArtigos(extractoArtigo)
      }
    ]);
  }

  public async pesquisar(): Promise<void> {
    this.evtFilterChanged.emit(this.filters);
    await this.getExtractosArtigos();
  }

  public getExtractosArtigos(): Promise<void> {
    if (this.filters.dateDe.isAfter(this.filters.dateAte, 'date')) {
      return Promise.reject(new Error('extractosartigos.info.erroDataDeMaiorQueAte'));
    }
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }

    return this._extractosArtigosService
      .getExtractosArtigos(
        this.filters.nArtigoDe,
        this.filters.nArtigoAte,
        this.filters.nArmazemDe,
        this.filters.nArmazemAte,
        this.filters.dateDe,
        this.filters.dateAte,
        this.filters.calcMovAntesDepoisData
      )
      .then((response: HttpResponse<IApiQueryResponse<IExtractoArtigo>>) => {
        if (response?.body.list.length) {
          this.dataGrid.dataSource = response.body.list;
          this._cardPanel.collapse();
          this.filters.canProcessXl = false;
        } else {
          this.dataGrid.dataSource = [];
          this._plAlertService.info('global.text.searchNoData');
          this._cardPanel.focusFirstElement();
          this.filters.canProcessXl = true;
        }
      })
      .finally(() => {
        if (this._dataGridInstance) {
          this._dataGridInstance.endCustomLoading();
        }
        this.evtFilterChanged.emit(this.filters);
      });
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _loadExtractoArtigoDocComercial(extractoArtigo: IExtractoArtigo): Promise<void> {
    if (extractoArtigo.docComercial) {
      return Promise.resolve();
    }
    return this._serviceDocsComerciais.getByDraft(extractoArtigo.nDocfaDraft, extractoArtigo.nNumerDraft, extractoArtigo.nDocumentoDraft).then((response: HttpResponse<IJsonDocComercial>) => {
      extractoArtigo.docComercial = response.body;
    });
  }

  private _openMaintenanceDocsComerciais(extractoArtigo: IExtractoArtigo): Promise<void> {
    return this._loadExtractoArtigoDocComercial(extractoArtigo)
      .then(() => {
        if (!this._maintenanceDocsComerciais) {
          this._maintenanceDocsComerciais = new Map<EGrupoDoc, IEntityMaintenanceInstance<IJsonDocComercial>>();
        }
        const grupoDocfa: EGrupoDoc = extractoArtigo.docComercial.cab.grupoDocfa;
        let maintenanceDocsComerciais = this._maintenanceDocsComerciais.get(grupoDocfa);
        if (!maintenanceDocsComerciais) {
          const entityName: string = docsComerciaisGrupoDocToEntityName(grupoDocfa);
          maintenanceDocsComerciais = this._entityMaintenanceService.build<IJsonDocComercial>(entityName);
          this._maintenanceDocsComerciais.set(grupoDocfa, maintenanceDocsComerciais);
        }
        return maintenanceDocsComerciais.maintenanceEdit(extractoArtigo.docComercial.cab.faccbId);
      })
      .then(() => undefined);
  }

  private _openMaintenanceArtigos(extractoArtigo: IExtractoArtigo): Promise<void> {
    if (!this._maintenanceArtigos) {
      this._maintenanceArtigos = this._entityMaintenanceService.build<IJsonArtigo>(ENTITY_NAME_ARTIGOS);
    }
    return this._maintenanceArtigos.maintenanceEdit(extractoArtigo.nArtigo).then(() => undefined);
  }

  private _customizeText(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row' && cellInfo.value === 0) {
      return '';
    }
    return cellInfo.valueText;
  }

  private readonly _fnCustomizeText: TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => this._customizeText(cellInfo);
}
