import {Component, Injector, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {isDefined, isFunction, isObject, isString, PlEditBaseComponent, PlTranslateService} from 'pl-comps-angular';
import {IEntityAutocompleteOptions} from '../../entity/entity.autocomplete.definition.interface';

@Component({
  selector: 'cg-edit-datasource',
  templateUrl: './datasource.input.component.html'
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class DataSourceInputComponent extends PlEditBaseComponent<any, IEntityAutocompleteOptions> implements OnInit, OnChanges {
  private _outputKey: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._changedProperties();
    this._changedModel();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {model, properties} = changes;
    if (properties && !properties.isFirstChange()) {
      this._changedProperties();
    }
    if (model && !model.isFirstChange()) {
      this._changedModel();
    }
  }

  public updateComponent(properties: IEntityAutocompleteOptions): void {
    super.updateComponent(properties);
    this._changedProperties();
  }

  public updateValue(value: unknown): void {
    super.updateValue(value);
    this._changedModel(value);
  }

  public render(value: unknown = this.value): Promise<void> {
    const toRender = isObject(value) ? value[this._outputKey] : value;
    return super.render(toRender);
  }

  private _changedProperties(): void {
    this._outputKey = this.options.outputKey || 'value';
    this.options.allowInvalid = false;
    this.options.output = this.options.output || 'name';
    this.options.source = (<Array<unknown>>this.options.source).map((item: unknown) => {
      for (const prop of Object.keys(item)) {
        const value: unknown = item[prop];
        if (isString(value) && value.includes('.')) {
          item[prop] = this._plTranslateService.translate(value);
        }
      }
      return item;
    });
    const originalValidateFn = this.options.validateFn;
    this.options.validateFn = (search: string, item: unknown) => {
      if (isFunction(originalValidateFn)) {
        const valid: Promise<unknown> = Promise.resolve(originalValidateFn(search, item));
        if (isDefined(valid)) {
          return valid;
        }
      }
      const error = new Error('Not found.');
      if (isObject(item)) {
        if (!(<Array<unknown>>this.options.source).includes(item)) {
          return Promise.reject(error);
        }
      } else {
        const sourceItemIndex: number = (<Array<unknown>>this.options.source).findIndex((sourceItem: unknown) => {
          return sourceItem[this._outputKey] === search;
        });
        if (sourceItemIndex === -1) {
          return Promise.reject(error);
        }
      }
      return Promise.resolve();
    };
  }

  private _changedModel(value: unknown = this.model): void {
    this.value = (<Array<unknown>>this.options.source).find((item: unknown) => {
      return item[this._outputKey] === value;
    });
  }
}
