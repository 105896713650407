import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {HttpResponse} from '@angular/common/http';
import {MODULE_NAME_ATIVOS_CONSISTENCIA} from './ativosConsistencia.module.interface';
import {IJsonAtivosConsistenciaAnos} from './jsonAtivosConsistencia.module.interface';
import {AtivosConsistenciaService} from './ativosConsistencia.module.service';
import {AtivosConsistenciaModuleComponent} from './components/ativosConsistencia.module.component';

export const MODULE_ATIVOS_CONSISTENCIA: IModuleDefinition = {
  name: MODULE_NAME_ATIVOS_CONSISTENCIA,
  state: {
    url: `/${MODULE_NAME_ATIVOS_CONSISTENCIA}`,
    component: AtivosConsistenciaModuleComponent,
    data: {
      roles: [ROLE.ATIVOS],
      sidebarTitle: 'portals.sidebar.modules.ativosconsistencia'
    },
    resolve: [
      {
        provide: 'anosList',
        deps: [AtivosConsistenciaService],
        useFactory: (ativosConsistenciaService: AtivosConsistenciaService): Promise<Array<IJsonAtivosConsistenciaAnos>> => {
          return ativosConsistenciaService.getCarregaAnos().then((response: HttpResponse<Array<IJsonAtivosConsistenciaAnos>>) => response.body);
        }
      }
    ]
  }
};
