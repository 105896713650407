import {Ng2StateDeclaration} from '@uirouter/angular';
import {AccountComponent} from './account.state.component';
import {STATE_NAME_ACCOUNT} from './account.state.interface';
import {STATE_NAME_LOGIN} from './login/login.state.interface';
import {STATE_NAME_SITE} from '../../../common/site';

export const STATE_ACCOUNT: Ng2StateDeclaration = {
  parent: STATE_NAME_SITE,
  name: STATE_NAME_ACCOUNT,
  component: AccountComponent,
  redirectTo: STATE_NAME_LOGIN,
  data: {
    disableRecover: true
  }
};
