import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {FileListTransformerHandler, IFileListTransformer} from '../../common/pipeline/file/filelist.pipeline';
import type {IPlUploadProperties} from '../upload.component.interface';

export abstract class PlUploadTransformer implements IFileListTransformer {
  public transform(files: Array<File>, next: FileListTransformerHandler, properties?: Partial<IPlUploadProperties>): Observable<Array<File>> {
    return this._doTransformFiles(files, properties).pipe(
      mergeMap((value: Array<File>) => {
        return next.handle(value, properties);
      })
    );
  }

  protected abstract _doTransformFiles(files: Array<File>, properties: Partial<IPlUploadProperties>): Observable<Array<File>>;
}
