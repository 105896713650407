import {Injectable} from '@angular/core';
import {IJsonNumber} from '../../../common/interfaces/json';
import {ApiService} from '../../services/api/api.service';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IJsonReport} from '../../entities/reports/jsonReport.interface';
import {IJsonDemFinSNCInitialData, IJsonDemFinSNCNotas, IJsonDemFinSNCPeriodo, IJsonDemFinSNCRubricaMain, IJsonDemFinSNCSaved, IJsonDemFinSNCVarApuramento} from './jsonDemFinSNC.module.interface';

@Injectable({
  providedIn: 'root'
})
export class DemFinSNCService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/demfinsnc`;
  }

  public getDemFinSNCByIDSaved(loadedID: number): TServiceResponse<IJsonDemFinSNCRubricaMain> {
    return this._apiService.get<IJsonDemFinSNCRubricaMain>({
      url: `${this._path}/idsaved`,
      params: {loadedid: loadedID}
    });
  }

  public getVariaveisApuramento(periodo: string): TServiceResponse<IJsonDemFinSNCVarApuramento> {
    return this._apiService.get<IJsonDemFinSNCVarApuramento>({
      url: `${this._path}/variaveisapuramento`,
      params: {periodo: periodo}
    });
  }

  public getDemFinSNCSavedList(): TServiceResponse<Array<IJsonDemFinSNCSaved>> {
    return this._apiService.get<Array<IJsonDemFinSNCSaved>>({url: `${this._path}/listsaved`});
  }

  public loadDefaultDemFinSNC(): TServiceResponse<IJsonDemFinSNCInitialData> {
    return this._apiService.get<IJsonDemFinSNCInitialData>({url: `${this._path}/dadosiniciais`});
  }

  public getListaPeriodos(): TServiceResponse<Array<IJsonDemFinSNCPeriodo>> {
    return this._apiService.get<Array<IJsonDemFinSNCPeriodo>>({url: `${this._path}/periodos`});
  }

  public getNotasDemFinSNC(): TServiceResponse<IJsonDemFinSNCNotas> {
    return this._apiService.get<IJsonDemFinSNCNotas>({url: `${this._path}/notasdemfinsnc`});
  }

  public getValoresPorDefeitoNotasDemFinSNC(): TServiceResponse<IJsonDemFinSNCNotas> {
    return this._apiService.get<IJsonDemFinSNCNotas>({url: `${this._path}/valdefeitonotasdemfinsnc`});
  }

  public listDemFinSNC(
    ano: number,
    processafluxos: boolean,
    vercomdados: boolean,
    processaIntercalares: boolean,
    periodoIntercalares: string,
    idioma: number,
    varApuramento: IJsonDemFinSNCVarApuramento
  ): TServiceResponse<IJsonDemFinSNCRubricaMain> {
    return this._apiService.post<IJsonDemFinSNCRubricaMain, IJsonDemFinSNCVarApuramento>({
      url: `${this._path}/listdemfinsnc`,
      params: {
        ano: ano,
        processafluxos: processafluxos,
        vercomdados: vercomdados,
        processaintercalares: processaIntercalares,
        periodo: periodoIntercalares,
        idioma: idioma
      },
      body: varApuramento
    });
  }

  public saveDemFinSNC(
    ano: number,
    processaFluxos: boolean,
    verComDados: boolean,
    processaIntercalares: boolean,
    periodoIntercalares: string,
    obs: string,
    idioma: number,
    demFinSNC: IJsonDemFinSNCRubricaMain
  ): TServiceResponse<IJsonNumber> {
    return this._apiService.post<IJsonNumber, IJsonDemFinSNCRubricaMain>({
      url: this._path,
      body: demFinSNC,
      params: {
        ano: ano,
        processafluxos: processaFluxos,
        vercomdados: verComDados,
        processaintercalares: processaIntercalares,
        periodo: periodoIntercalares,
        obs: obs,
        idioma: idioma
      }
    });
  }

  public postNotasDemFinSNC(notas: IJsonDemFinSNCNotas): TServiceResponse<void> {
    return this._apiService.post<void, IJsonDemFinSNCNotas>({url: `${this._path}/notasdemfinsnc`, body: notas});
  }

  public deleteDemFinSNCByIDSaved(loadedID: number): TServiceResponse<void> {
    return this._apiService.delete<void>({
      url: `${this._path}/idsaved`,
      params: {loadedid: loadedID}
    });
  }

  public listagemDemFinSNCBlob(
    ano: number,
    processafluxos: boolean,
    vercomdados: boolean,
    processaIntercalares: boolean,
    periodoIntercalares: string,
    idioma: number,
    loadedID: number,
    reportname: string,
    tipoexportacao: number,
    varApuramento: IJsonDemFinSNCVarApuramento
  ): TServiceResponse<Blob> {
    return this._apiService.post<Blob, IJsonDemFinSNCVarApuramento>({
      url: `${this._path}/report`,
      responseType: 'blob',
      params: {
        ano: ano,
        processafluxos: processafluxos,
        vercomdados: vercomdados,
        processaintercalares: processaIntercalares,
        periodo: periodoIntercalares,
        idioma: idioma,
        loadedid: loadedID,
        reportname: reportname,
        tipoexportacao: tipoexportacao
      },
      body: varApuramento
    });
  }

  public getReports(): TServiceResponse<Array<IJsonReport>> {
    return this._apiService.get<Array<IJsonReport>>({url: `${this._path}/reports`});
  }
}
