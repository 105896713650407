import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_PRH_LOCAIS} from '../../../../../../entities/prhlocais/pRHLocais.entity.interface';
import {ENTITY_NAME_PRH_REL_EMENTAS} from '../../../../../../entities/prhrelementas/pRHRelEmentas.entity.interface';
import {EntityServiceBuilder} from '../../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../../services/entity/entity.service.interface';
import {IJsonPRHLocal} from '../../../../../../entities/prhlocais/jsonPRHLocal.entity.interface';
import {IJsonPRHRelEmentas} from '../../../../../../entities/prhrelementas/jsonPRHRelEmentas.entity.interface';
import {TDate} from '../../../../../../../common/dates';
import {THttpQueryResponse} from '../../../../../../services/api/api.service.interface';

@Component({
  selector: 'colaboradores-clonemenus-modal',
  templateUrl: './cloneMenus.modal.component.html'
})
export class CloneMenusModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public codLocal: number;

  public readonly templateMultiSelect: string;
  public locais: Array<IJsonPRHLocal>;
  public locaisSelected: Array<IJsonPRHLocal>;
  public dataInicio: TDate;
  public dataFim: TDate;

  private readonly _servicePRHLocais: IEntityService<IJsonPRHLocal>;
  private readonly _servicePRHRelElementas: IEntityService<IJsonPRHRelEmentas>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.templateMultiSelect = '{{codLocal}} - {{nome}}';
    this.locais = [];
    this.locaisSelected = [];
    this._servicePRHLocais = this._entityServiceBuilder.build(ENTITY_NAME_PRH_LOCAIS);
    this._servicePRHRelElementas = this._entityServiceBuilder.build(ENTITY_NAME_PRH_REL_EMENTAS);
  }

  public ngOnInit(): void {
    this._servicePRHLocais.query().then((response: THttpQueryResponse<IJsonPRHLocal>) => {
      this.locais = response.body.list;
      for (let i = this.locais.length - 1; i >= 0; i--) {
        if (this.locais[i].codLocal === this.codLocal) {
          this.locais.splice(i, 1);
        }
      }
    });
  }

  public cloneMenu(): Promise<void> {
    const copyToMenus = {
      codLocalOrigem: this.codLocal,
      codLocalListDestino: [],
      dataDe: this.dataInicio,
      dataAte: this.dataFim
    };
    for (const local of this.locaisSelected) {
      copyToMenus.codLocalListDestino.push(local.codLocal);
    }
    return this._servicePRHRelElementas
      .post({url: 'copyto', body: copyToMenus})
      .then(() => {
        this._plAlertService.success('Clonado com sucesso');
        this.close();
      })
      .catch(() => {
        this._plAlertService.error(this._translateService.instant('prhrelementas.error'));
      });
  }
}
