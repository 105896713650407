<div [ngClass]="entityClassName">
  <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName" [hideRevertToDefault]="true"></config-options>
  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [dataGridState]="dataGridState"
    [beforeRequest]="fnBeforeRequest"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [filterCollapsed]="true"
    [toolbarInstanceId]="instanceName"
    [properties]="listOptions"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridColumnsPreparing)="onDataGridColumnsPreparing($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
    <div *entityListItemActions="let item">
      <pl-tooltip *ngIf="dataGridCellClickBlocked" [config]="{text: 'lotes.list.actions.editar'}">
        <button *plTooltipContent type="button" class="btn btn-warning btn-xs" (click)="actionEditar(item.data)">
          <i class="fa fa-fw fa-pencil-square-o"></i>
        </button>
      </pl-tooltip>
      <pl-tooltip *ngIf="!item.data.terminado" [config]="{text: 'lotes.list.actions.terminar'}">
        <button type="button" *plTooltipContent class="btn btn-danger btn-xs" (click)="actionTerminar(item.data)"><i class="fa fa-fw fa-lock"></i></button>
      </pl-tooltip>
    </div>
  </entity-list>
</div>
