import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {isArray, isBoolean, isObject, Logger, PlDocumentService} from 'pl-comps-angular';
import {contabDigitalFoldersWithChildrenToFolders, IContabilidadeDigitalGenericViewerFolder} from '../viewers/contabilidadedigital.genericviewer.interface';
import {ContabilidadeDigitalGenericViewerComponent} from '../viewers/contabilidadedigital.genericviewer.component';
import {ContabilidadeDigitalUIService} from '../../../../services/contabilidadedigital/contabilidadedigital.ui.service';
import {
  IContabilidadeDigitalTreeViewerEvtFileItemChanged,
  IContabilidadeDigitalTreeViewerEvtFolderItemChanged,
  IContabilidadeDigitalTreeViewerFile,
  IContabilidadeDigitalTreeViewerFolder
} from './contabilidadedigital.treeviewer.component.interface';
import {IJsonContabDigitalFolder, IJsonContabDigitalFolderWithChildren} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';

@Component({
  selector: 'contabilidadedigital-treeviewer',
  templateUrl: './contabilidadedigital.treeviewer.component.html'
})
export class ContabilidadeDigitalTreeViewerComponent extends ContabilidadeDigitalGenericViewerComponent implements OnDestroy {
  @Input() public disableActions: boolean;
  @Input() public disableActionAddDoc: boolean;
  @Input() public disableActionCaptureDoc: boolean;
  @Input() public disableActionDeleteDocs: boolean;
  @Input() public disableActionDeleteDoc: boolean;
  @Output() public readonly evtFolderDoubleClicked: EventEmitter<IContabilidadeDigitalTreeViewerEvtFolderItemChanged>;
  @Output() public readonly evtFileDoubleClicked: EventEmitter<IContabilidadeDigitalTreeViewerEvtFileItemChanged>;
  @Output() public readonly evtActionAddDoc: EventEmitter<void>;
  @Output() public readonly evtActionCaptureDoc: EventEmitter<void>;
  @Output() public readonly evtActionDeleteDocs: EventEmitter<void>;
  @Output() public readonly evtActionDeleteDoc: EventEmitter<void>;

  public selectedFolder: IContabilidadeDigitalTreeViewerFolder;
  public selectedFile: IContabilidadeDigitalTreeViewerFile;

  constructor(
    protected readonly _logger: Logger,
    protected readonly _plDocumentService: PlDocumentService,
    protected readonly _contabilidadeDigitalUIService: ContabilidadeDigitalUIService
  ) {
    super(_logger, _plDocumentService, _contabilidadeDigitalUIService);
    this.disableActions = false;
    this.disableActionAddDoc = false;
    this.disableActionCaptureDoc = false;
    this.disableActionDeleteDocs = false;
    this.disableActionDeleteDoc = false;
    this.evtFolderDoubleClicked = new EventEmitter<IContabilidadeDigitalTreeViewerEvtFolderItemChanged>();
    this.evtFileDoubleClicked = new EventEmitter<IContabilidadeDigitalTreeViewerEvtFileItemChanged>();
    this.evtActionAddDoc = new EventEmitter<void>();
    this.evtActionCaptureDoc = new EventEmitter<void>();
    this.evtActionDeleteDocs = new EventEmitter<void>();
    this.evtActionDeleteDoc = new EventEmitter<void>();
  }

  public selectFolder(value: IContabilidadeDigitalTreeViewerFolder): void {
    if (this.selectedFile) {
      this.selectedFile = undefined;
    }
    this.selectedFolder = value;
    this._evaluateCurrentFolderPath();
    if (this.selectedFolder) {
      this.evtFolderChanged.emit({root: this.root, folder: this.selectedFolder});
    }
  }

  public selectFile(value: IContabilidadeDigitalTreeViewerFile): void {
    if (this.selectedFolder) {
      this.selectedFolder = undefined;
    }
    this.selectedFile = value;
    if (this.selectedFile) {
      this.evtFileChanged.emit({root: this.root, file: this.selectedFile});
    }
  }

  public folderDoubleClicked(value: IContabilidadeDigitalTreeViewerFolder): void {
    this.evtFolderDoubleClicked.emit({root: this.root, folder: value});
  }

  public fileDoubleClicked(value: IContabilidadeDigitalTreeViewerFile): void {
    this.evtFileDoubleClicked.emit({root: this.root, file: value});
  }

  public changedFolderItemCollapse(folderItem: IContabilidadeDigitalTreeViewerFolder): Promise<unknown> {
    if ((!this.showFiles && !folderItem.hasSubFolders) || (this.showFiles && !folderItem.hasSubFolders && !folderItem.hasSubFiles && !folderItem.files.length)) {
      return Promise.resolve(folderItem);
    }
    if (folderItem.promise) {
      return folderItem.promise;
    }
    if (folderItem.collapsed) {
      folderItem.promise = this._refreshFolderItem(folderItem).then((folder: IContabilidadeDigitalTreeViewerFolder) => {
        for (const childFolder of folder.folders) {
          childFolder.collapsed = true;
          childFolder.hovering = false;
        }
      });
    }
    return Promise.resolve(folderItem.promise)
      .then(() => {
        folderItem.collapsed = !folderItem.collapsed;
        return folderItem;
      })
      .finally(() => {
        folderItem.promise = undefined;
      });
  }

  public onActionAdd(): void {
    this.evtActionAddDoc.emit();
  }

  public onActionCapture(): void {
    this.evtActionCaptureDoc.emit();
  }

  public onActionDeleteDocs(): void {
    this.evtActionDeleteDocs.emit();
  }

  public onActionDeleteDoc(): void {
    this.evtActionDeleteDoc.emit();
  }

  protected _updateFolderItemChildFolders(folderItem: IContabilidadeDigitalTreeViewerFolder, folders: Array<IJsonContabDigitalFolder>): void {
    if (!isArray(folderItem.folders) || !folderItem.folders.length) {
      folderItem.folders = this._baseFoldersToGenericViewerFolders(folders);
    } else {
      const updatedChildFoldersIds: Array<string> = folders.map((childFolder: IContabilidadeDigitalTreeViewerFolder) => childFolder.folderID);
      folderItem.folders = folderItem.folders.filter((childFolder: IContabilidadeDigitalTreeViewerFolder) => updatedChildFoldersIds.includes(childFolder.folderID));
      for (const updatedChildFolder of folders) {
        const currentChildFolder: IContabilidadeDigitalTreeViewerFolder = folderItem.folders.find((childFolder: IContabilidadeDigitalTreeViewerFolder) => {
          return childFolder.folderID === updatedChildFolder.folderID;
        });
        if (!currentChildFolder) {
          const newFolder: IContabilidadeDigitalTreeViewerFolder = {
            ...updatedChildFolder,
            collapsed: true,
            hovering: false,
            parentFolder: folderItem,
            folders: [],
            promise: undefined
          };
          folderItem.folders.push(newFolder);
        } else {
          currentChildFolder.folderID = updatedChildFolder.folderID;
          currentChildFolder.folderParentID = updatedChildFolder.folderParentID;
          currentChildFolder.name = updatedChildFolder.name;
          currentChildFolder.hasSubFolders = updatedChildFolder.hasSubFolders;
          currentChildFolder.hasSubFiles = updatedChildFolder.hasSubFiles;
          currentChildFolder.files = updatedChildFolder.files;
        }
      }
    }
    for (const childFolder of folderItem.folders) {
      childFolder.parentFolder = folderItem;
      if (isArray(childFolder.files) && childFolder.files.length) {
        for (const childFolderFile of childFolder.files) {
          childFolderFile.hovering = false;
          childFolderFile.parentFolder = childFolder;
        }
      }
    }
  }

  protected _evaluateFolders(treeViewerFolders: Array<IContabilidadeDigitalTreeViewerFolder>, parentFolder: IContabilidadeDigitalGenericViewerFolder): void {
    for (const folder of treeViewerFolders) {
      if (!folder.parentFolder) {
        folder.parentFolder = parentFolder;
      }
      if (!isBoolean(folder.collapsed)) {
        folder.collapsed = true;
      }
      if (!isBoolean(folder.hovering)) {
        folder.hovering = false;
      }
      if (isArray(folder.files) && folder.files.length) {
        for (const folderFile of folder.files) {
          if (!isBoolean(folderFile.hovering)) {
            folderFile.hovering = false;
          }
          if (!isObject(folderFile.parentFolder)) {
            folderFile.parentFolder = folder;
          }
        }
      }
      if (isArray(folder.folders)) {
        this._evaluateFolders(folder.folders, folder);
      }
    }
  }

  protected _foldersWithChildrenToFolders(foldersWithChildren: Array<IJsonContabDigitalFolderWithChildren>, folderID: string): Array<IContabilidadeDigitalGenericViewerFolder> {
    return contabDigitalFoldersWithChildrenToFolders(foldersWithChildren, (folderWithChildren: IJsonContabDigitalFolderWithChildren, genericViewerFolder: IContabilidadeDigitalGenericViewerFolder) => {
      const folderHasChildren: boolean = isArray(folderWithChildren.childFolders) && Boolean(folderWithChildren.childFolders.length);
      const folderHasFiles: boolean = isArray(folderWithChildren.files) && Boolean(folderWithChildren.files.length);
      const treeViewerFolder: IContabilidadeDigitalTreeViewerFolder = {...genericViewerFolder, collapsed: !folderHasChildren || folderHasFiles};
      if (treeViewerFolder.folderID === folderID) {
        this.selectFolder(treeViewerFolder);
      }
      return treeViewerFolder;
    });
  }
}
