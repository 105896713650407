<div class="rhprocsegsocialconsultenvios">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="procSegSocialConsultEnvios"
    [dataSource]="dataGridDefinition.dataSource"
    [selectedRowKeys]="selectedRowKeys"
    (selectedRowKeysChange)="selectedRowKeys = $event; selChanged()"
    (onInitialized)="onInitialized($event)">
    <div *dxTemplate="let item of 'toolbarTemplate'">
      <div class="proc-seg-social-consult-envios-toolbar">
        <span [translate]="'global.text.year'"></span>
        <pl-edit type="select" [(model)]="year" [properties]="{select: {list: yearsSource}, disallowClear: true, validators: {required: {value: true}}}"></pl-edit>

        <span [translate]="'global.text.month'"></span>
        <pl-edit type="select" [(model)]="month" [properties]="{select: {list: mesesSource}, disallowClear: true, validators: {required: {value: true}}}"></pl-edit>

        <pl-button klass="btn-primary btn-sm" (evtClicked)="pesquisaSSDecRemunList()" [disabled]="!!promise">
          <i class="fa fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
        </pl-button>
        <pl-button klass="btn-light btn-sm" (evtClicked)="verificarEnvios()" [disabled]="!havefichSel || !!promise">
          <i class="fa fa-check-square"></i>&nbsp;<span [translate]="'rhprocsegsocialconsultenvios.verificarEnvios'"></span>
        </pl-button>
      </div>
    </div>

    <div *dxTemplate="let item of 'cellTemplateProcFicheiro'">
      <pl-tooltip [callback]="item.data.tooltipCallback" [config]="tooltipProcFicheir">
        <pl-button *plTooltipContent type="button" klass="btn btn-secondary btn-xs" [onClick]="fnProcFicheiro(item.data, templateErrors)">
          <i class="fa fa-file-text-o" aria-hidden="true"></i>
        </pl-button>
      </pl-tooltip>
    </div>

    <div *dxTemplate="let item of 'cellTemplateInfo'">
      <pl-button klass="btn btn-info btn-xs" (evtClicked)="viewInfoSSClick(item.data)">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
      </pl-button>
    </div>

    <div *dxTemplate="let item of 'cellTemplateErros'">
      <i class="fa fa-circle" [ngClass]="{'text-danger': item.data.infoErro !== 0, 'text-success': item.data.infoErro === 0}" aria-hidden="true"></i>
    </div>

    <div *dxTemplate="let item of 'cellTemplateConfig'">
      <pl-tooltip [config]="tooltipConfig">
        <pl-button *plTooltipContent klass="btn btn-warning btn-xs" (evtClicked)="configWsSSLogin(item.data.nEmpresa)">
          <i class="fa fa-cog" aria-hidden="true"></i>
        </pl-button>
      </pl-tooltip>
    </div>

    <div *dxTemplate="let item of 'cellTemplateComprovativo'">
      <pl-button klass="btn btn-secondary btn-xs" [onClick]="fnComprovativo(item.data)" [properties]="{disabled: item.data.estadoFicheiro !== estadoFicheiro.SSEF_EnviadoAceite}">
        <i class="fa fa-file-text-o" aria-hidden="true"></i>
      </pl-button>
    </div>
  </dx-data-grid>
</div>

<ng-template #templateErrors let-errors="errors">
  <ng-container [ngSwitch]="errors?.length > 0">
    <div *ngSwitchDefault [translate]="'rhprocsegsocialconsultenvios.semdados'"></div>

    <div *ngSwitchCase="true">
      <ng-container *ngFor="let error of errors">{{ error }}</ng-container>
    </div>
  </ng-container>
</ng-template>
