import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IJsonExternalNif} from './externalnifs.interface';
import {ApiService} from '../api/api.service';
import {API_CENTRALGEST_HELPER} from '../../../config/constants';
import {EntityServiceBuilder} from '../entity/entity.service.builder';
import {ENTITY_NAME_NIFS, INifsEntityService} from '../../entities/nifs/nifs.entity.interface';
import {IJsonNIFs} from '../../entities/nifs/jsonNifs.entity.interface';

@Injectable({
  providedIn: 'root'
})
export class ExternalNifsService {
  private readonly _serviceNifs: INifsEntityService;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this._serviceNifs = this._entityServiceBuilder.build<IJsonNIFs, INifsEntityService>(ENTITY_NAME_NIFS);
  }

  public get(nif: string): Promise<IJsonExternalNif> {
    return this._apiService
      .get<IJsonExternalNif>({url: `${API_CENTRALGEST_HELPER}/nifs/${nif}`, withCredentials: false})
      .then((response: HttpResponse<IJsonExternalNif>) => {
        if (!response.body) {
          return this._serviceNifs.getExternalNifFromVIES(nif);
        }
        return response;
      })
      .then((responseVIES: HttpResponse<IJsonExternalNif>) => this._transformGetResponse(responseVIES.body));
  }

  private _transformGetResponse(response: IJsonExternalNif): IJsonExternalNif {
    if (!response) {
      return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (response.codigoPostal && response.codigoPostal.length > 9) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      response.codigoPostal = response.codigoPostal.substring(0, 8);
    }
    response.nif = response.nif || response.Nif;
    return response;
  }
}
