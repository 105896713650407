import {Component, Injector, Input, OnInit} from '@angular/core';
import {
  IContabilidadeDigitalGenericViewerCallback,
  IContabilidadeDigitalGenericViewerFolderPath
} from '../../../../../components/arquivodigital/common/viewers/contabilidadedigital.genericviewer.interface';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EFolderDoc} from '../../../../../entities/gdoc/gdoc.entity.interface';
import {IJsonContabDigitalFolder} from '../../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {IContabilidadeDigitalTreeViewerEvtFolderItemChanged} from '../../../../../components/arquivodigital/common/treeviewer/contabilidadedigital.treeviewer.component.interface';

const LEVEL_5 = 5;

@Component({
  selector: 'docsdigitais-export-contab-digital-modal',
  templateUrl: './docsDigitais.exportarFolder.modal.component.html'
})
export class DocsDigitaisExportarFolderModalComponent extends CGModalComponent<IJsonContabDigitalFolder> implements OnInit {
  @Input() public flagDocumentosClassificados: boolean;
  @Input() public startingFolderId: string;

  public readonly callback: IContabilidadeDigitalGenericViewerCallback;
  public upwardsUntilFolderId: string;
  public currentFolderPath: Array<IContabilidadeDigitalGenericViewerFolderPath>;

  public folder: IJsonContabDigitalFolder;
  public flagDisabled: boolean;

  private _selectedFolder: IJsonContabDigitalFolder;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.callback = {};
  }

  public ngOnInit(): void {
    this.flagDisabled = true;
    this.startingFolderId = this.flagDocumentosClassificados ? EFolderDoc.DefaultFolderContabClassificados : EFolderDoc.DefaultFolderContabNaoClassificados;
  }

  public close(): void {
    super.close(this.folder);
  }

  public changedFolder({folder}: IContabilidadeDigitalTreeViewerEvtFolderItemChanged): void {
    this._selectedFolder = folder;
    this.folder = undefined;

    const level: Array<string> = this._selectedFolder.folderPath.split('\\');

    this.flagDisabled = true;

    if (!this._selectedFolder.hasSubFolders || level?.length >= LEVEL_5) {
      this.flagDisabled = false;
      this.folder = folder;
    }
  }
}
