import {Component, Injector, Input, OnInit} from '@angular/core';
import {isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {ApiService} from '../../../../../services/api/api.service';
import {AuthService} from '../../../../../services/auth/auth.service';
import {ComunicacoesInternasModalComponent} from '../../../comunicacoesinternas/modals/comunicacoesInternas.modal.component';
import {ENTITY_NAME_PRH_SERVICO_EMPREGADOS} from '../../../../../entities/prhservicoempregados/pRHServicoEmpregados.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonDGEMP} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHComunicacaoInterna} from '../../../../../entities/prhcomunicacoesinternas/jsonPRHComunicacaoInterna.entity.interface';
import {IJsonPRHServicoEmpregado} from '../../../../../entities/prhservicoempregados/jsonPRHServicoEmpregado.entity.interface';

@Component({
  selector: 'home-colaboradores-comunicacoesinternas-modal',
  templateUrl: '../../../comunicacoesinternas/modals/comunicacoesInternas.modal.component.html'
})
export class HomeComunicacoesInternasModalComponent extends ComunicacoesInternasModalComponent implements OnInit {
  @Input() public item: IJsonPRHComunicacaoInterna;
  @Input() public servicoPrincipal: number;

  private readonly _servicePRHServicoEmpregado: IEntityService<IJsonPRHServicoEmpregado>;

  constructor(
    protected readonly _injector: Injector,
    protected _entityServiceBuilder: EntityServiceBuilder,
    protected _plAlertService: PlAlertService,
    protected _authService: AuthService,
    protected _apiService: ApiService
  ) {
    super(_injector, _entityServiceBuilder, _plAlertService, _authService, _apiService);
    this.home = true;
    this._servicePRHServicoEmpregado = this._entityServiceBuilder.build<IJsonPRHServicoEmpregado>(ENTITY_NAME_PRH_SERVICO_EMPREGADOS);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.comInt.cab.comIntCabIDPai = this.item.cab.comIntCabID;
    this.comInt.cab.tipoComInt = this.item.cab.tipoComInt;
    this.comInt.cab.nomeTipoComInt = this.item.cab.nomeTipoComInt;
    this.texto = undefined;
    this.colaboradoresSource = [];
    this.colaboradoresSelected = [];
    this.anexaPDF = false;
    this.comIntCab = undefined;
    this.tipoComunicacao = {
      codPRHTipoCom: this.item.cab.tipoComInt,
      nome: this.item.cab.nomeTipoComInt,
      count: 0,
      isSystem: undefined
    };
    if (this.answer) {
      this.titulo = `RE: ${this.item.cab.titulo}`;
    }
    if (this.servicoPrincipal) {
      this.colaboradoresSource = [];
    }
  }

  public selectServicos(servicos: Array<number>): void {
    this.servicos = servicos;
    this.colaboradoresSelected = [];
    this.colaboradoresSource = [];
    if (this.servicos.length) {
      let pesqParamValue = '';
      this.servicos.forEach((codServico) => {
        if (pesqParamValue.length) {
          pesqParamValue = `${pesqParamValue}|`;
        }
        pesqParamValue = `${pesqParamValue}codServico=${codServico}`;
      });
      this._servicePRHServicoEmpregado.query({pesquisa: `(${pesqParamValue})`}).then((response) => {
        const source: Array<IJsonDGEMP> = [];
        response.body.list.forEach((empregado) => {
          if (isUndefinedOrNull(source.find((o) => o.codEmp === empregado.codEmp))) {
            source.push(empregado.dgEmp);
          }
        });
        this.colaboradoresSource = source;
      });
    }
  }
}
