import {Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ClifosEditComponent} from '../../edit/clifos.entity.edit.component';
import {copy, IPlFormDefinition, IPlFormTemplate, IPlTabsEventSelected} from 'pl-comps-angular';
import {EEntityStateDetailType} from '../../../../../../common/utils/entity.state.utils';
import {ConfigOptionsService} from '../../../../../services/config/options/config.options.service';
import {EConfigOptionsInstanceName} from '../../../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../../../config/constants';
import {IDevExpressDataGridColumn} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {EntityListComponent} from '../../../../../components/entity/list/entity.list.component';
import {EntityMaintenanceService} from '../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {EGrupoDoc} from '../../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {AuthService} from '../../../../../services/auth/auth.service';
import {TranslateService} from '@ngx-translate/core';

const MAIN_TAB_ID_FICHA = 'ficha';

const TAB_FICHA_ID_DADOS_CONTA = 'dadosConta';
const TAB_FICHA_ID_DEFINICOES_EMAIL = 'definicoesemail';

@Component({
  selector: 'clientes-edit',
  templateUrl: './clientes.entity.edit.component.html'
})
export class ClientesEditComponent extends ClifosEditComponent implements OnInit, OnDestroy {
  public readonly mainTabIdFicha: string;
  public readonly fichaTabIdDadosConta: string;
  public readonly fichaTabIdDefinicoesEmail: string;
  public readonly fieldsRecibos: string;
  public readonly fieldsDocsComerciais: string;
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;

  public formDefinition: IPlFormDefinition;
  public formTemplate: IPlFormTemplate;
  public grupoEncomenda: EGrupoDoc;

  @ViewChild('entityListRecibos') private readonly _entityListRecibos: EntityListComponent;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _configOptionsService: ConfigOptionsService,
    protected readonly _entityMaintenanceService: EntityMaintenanceService,
    protected readonly _authService: AuthService,
    protected readonly _translateService: TranslateService
  ) {
    super(_injector, _configOptionsService, _entityMaintenanceService, _authService, _translateService);
    this.mainTabIdFicha = MAIN_TAB_ID_FICHA;
    this.fichaTabIdDadosConta = TAB_FICHA_ID_DADOS_CONTA;
    this.fichaTabIdDefinicoesEmail = TAB_FICHA_ID_DEFINICOES_EMAIL;
    this.fieldsRecibos = 'numero,data,valor,stampUpdate';
    this.fieldsDocsComerciais = 'nDoc,dataDoc,nif,estado,porPagar,totalDocumento,stampUpdate';
    this.grupoEncomenda = EGrupoDoc.EncomendasClientes;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.formDefinition = this.entity[this.type].definition;
    this.formTemplate = copy(this.entity[this.type].template);
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    return super.onUpdate(stateType).then(() => {
      this.formDefinition = this.entity[stateType].definition;
      this.formTemplate = copy(this.entity[stateType].template);
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigOptions.unsubscribe();
  }

  public changedMainTab({nextId}: IPlTabsEventSelected): void {
    this.btnEdit.disabled = nextId !== this.mainTabIdFicha;
  }

  public onDataGridColumnsPreparing(fields: Array<IDevExpressDataGridColumn>): void {
    for (const field of fields) {
      if (field.dataField === 'porPagar' && field.caption === 'docscomerciais.fields.porPagar') {
        field.caption = 'docscomerciais.fields.porReceber';
      }
    }
  }

  public refreshRecibosList(): void {
    this._entityListRecibos.refresh();
  }
}
