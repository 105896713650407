import {Component, ElementRef, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {CGModalService} from '../../../cg/modal/cgmodal.service';
import {ENTITY_NAME_CLIENTES, ENTITY_NAME_FORNECEDORES, ENTITY_NAME_OUTROS_DEVEDORES_CREDORES, IClifo, IClifosEntityService} from '../../../../entities/clifos/clifos.entity.interface';
import {ENTITY_NAME_NATUREZAS_CONTAB} from '../../../../entities/naturezascontab/naturezasContab.entity.interface';
import {IEntityMaintenanceInstance} from '../../../entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../entity/maintenance/entity/entity.maintenance.service';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ExternalNifsService} from '../../../../services/externalnifs/externalnifs.service';
import {focusElement} from '../../../../../common/utils/element.utils';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonExternalNif} from '../../../../services/externalnifs/externalnifs.interface';
import {IJsonClifo} from '../../../../entities/clifos/jsonClifo.entity.interface';
import {IJsonDocOCRCab} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {IJsonNaturezaContab} from '../../../../entities/naturezascontab/jsonNaturezaContab.entity.interface';
import {ContabilidadeDigitalUIService} from '../../../../services/contabilidadedigital/contabilidadedigital.ui.service';
import {EFacPublicaModelo} from '../../../../modules/faturacaopublica/jsonFaturacaoPublica.module.interface';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';

const DEFAULT_N_DIGITOS_SEQUENCIA = 4;

@Component({
  selector: 'contabilidadedigital-novacontacc-modal',
  templateUrl: './contabilidadedigital.novacontacc.modal.component.html'
})
export class ContabilidadeDigitalNovaContaCCModalComponent extends CGModalComponent<string> implements OnInit {
  @Input() public doc: IJsonDocOCRCab;

  public readonly model: IClifo;
  public info: string;
  public promise: Promise<void>;

  private readonly _serviceNaturezasContab: IEntityService<IJsonNaturezaContab>;
  private _serviceClientes: IClifosEntityService;
  private _serviceFornecedores: IClifosEntityService;
  private _serviceOutrosDevedoresCredores: IClifosEntityService;
  private _maintenanceClientes: IEntityMaintenanceInstance;
  private _maintenanceFornecedores: IEntityMaintenanceInstance;
  private _maintenanceOutrosDevedoresCredores: IEntityMaintenanceInstance;
  private _radical: string;
  private _elementNaturezasContab: HTMLElement;
  private _elementActionSubmit: HTMLElement;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _externalNifsService: ExternalNifsService,
    private readonly _contabilidadeDigitalUIService: ContabilidadeDigitalUIService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.model = {
      id: undefined,
      abrevFiscal: undefined,
      codCondComerciais: undefined,
      codPagamento: undefined,
      codPais: undefined,
      codPostal: undefined,
      codRet: undefined,
      email: undefined,
      fax: undefined,
      iban: undefined,
      localidade: undefined,
      lugar: undefined,
      nConta: undefined,
      nContribuint: undefined,
      nIdAltern: undefined,
      nome: undefined,
      nomeCondComerciais: undefined,
      nomePagamento: undefined,
      nomePais: undefined,
      observacoes: undefined,
      observacoes2: undefined,
      rua: undefined,
      segNome: undefined,
      situacaoActual: undefined,
      telefone: undefined,
      facPublicaModelo: EFacPublicaModelo.ND,
      naturezaContab: {
        idGrContaCorrente: undefined,
        idSubGrContaCorrente: undefined,
        descricao: undefined,
        radical: undefined,
        nDigitosSequencia: undefined,
        tipoContaCorrente: undefined
      },
      telemovel: undefined,
      nomeRet: undefined
    };
    this._serviceNaturezasContab = this._entityServiceBuilder.build<IJsonNaturezaContab>(ENTITY_NAME_NATUREZAS_CONTAB);
  }

  public ngOnInit(): void {
    if (this.doc.nif) {
      this.promise = Promise.all([this._externalNifsService.get(this.doc.nif), this._serviceNaturezasContab.query({pesquisa: `clifoType=${ENTITY_NAME_FORNECEDORES}`})])
        .then(([responseNif, responseNaturezaContab]: [IJsonExternalNif, THttpQueryResponse<IJsonNaturezaContab>]) => {
          this.info = this.doc.nif;
          if (responseNif.nif) {
            this.model.nContribuint = responseNif.nif;
          }
          if (responseNif.entidade) {
            this.model.nome = responseNif.entidade;
            this.info += ` - ${this.model.nome}`;
          }
          if (responseNaturezaContab.body.list.length) {
            this.model.naturezaContab = responseNaturezaContab.body.list[0];
          } else {
            this.model.naturezaContab = {
              ...this.model.naturezaContab,
              radical: this.configurations.clientesFornecedores.radicalFornecedores,
              nDigitosSequencia: DEFAULT_N_DIGITOS_SEQUENCIA
            };
          }
          this.changedNaturezaContabilistica(this.model.naturezaContab);
          return Promise.resolve(this.promise).then(() => {
            if (this.model.naturezaContab && this.model.nContribuint && this.model.nome) {
              focusElement(this._elementActionSubmit);
            } else {
              focusElement(this._elementNaturezasContab);
            }
          });
        })
        .catch((reason: unknown) => {
          this._logger.error(reason);
          this.info = this.doc.nif;
        });
    }
  }

  public changedNaturezaContabilistica(value: IJsonNaturezaContab): void {
    this.model.naturezaContab = value;
    if (this.model.naturezaContab) {
      this._radical = this.model.naturezaContab.radical;
      this.promise = this._service()
        .proximaconta(this._radical, this.model.naturezaContab.nDigitosSequencia)
        .then((response: HttpResponse<string>) => {
          this.model.nConta = response.body;
        });
    } else {
      this._radical = undefined;
    }
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._contabilidadeDigitalUIService.documents
      .novaContaCC(this.model.nConta, this.model.nome, this.doc.nif, this.model.naturezaContab.radical)
      .then((response: HttpResponse<string>) => {
        const type: string = this._maintenanceType();
        const title: string = this._plTranslateService.translate('arquivodigital.novacontaccmodal.successModal.title', {type: type});
        const message: string = this._plTranslateService.translate('arquivodigital.novacontaccmodal.successModal.message', {type: type});
        return this._cgModalService
          .showOkCancel(title, message, {
            btnOkIcon: 'fa-pencil-square-o',
            btnOkText: 'global.btn.edit',
            btnCancelIcon: 'fa-floppy-o',
            btnCancelText: 'global.btn.save'
          })
          .then(() => {
            return this._maintenance()
              .maintenanceEdit(response.body)
              .then(() => undefined)
              .finally(() => {
                this.enableClose();
                super.close(this.model.nome);
              });
          })
          .catch(() => {
            this.enableClose();
            super.close(this.model.nome);
          });
      })
      .catch(() => {
        this.enableClose();
      });
  }

  @ViewChild('elementNaturezasContab', {read: ElementRef})
  public set elementNaturezasContab(value: ElementRef<HTMLElement>) {
    this._elementNaturezasContab = value?.nativeElement;
  }

  @ViewChild('elementActionSubmit', {read: ElementRef})
  public set elementActionSubmit(value: ElementRef<HTMLElement>) {
    this._elementActionSubmit = value?.nativeElement;
  }

  private _service(): IClifosEntityService {
    if (this.configurations.clientesFornecedores.radicalClientes && this._radical.startsWith(this.configurations.clientesFornecedores.radicalClientes)) {
      if (!this._serviceClientes) {
        this._serviceClientes = this._entityServiceBuilder.build<IJsonClifo, IClifosEntityService>(ENTITY_NAME_CLIENTES);
      }
      return this._serviceClientes;
    }
    if (this.configurations.clientesFornecedores.radicalOutrosDevedoresCredores && this._radical.startsWith(this.configurations.clientesFornecedores.radicalOutrosDevedoresCredores)) {
      if (!this._serviceOutrosDevedoresCredores) {
        this._serviceOutrosDevedoresCredores = this._entityServiceBuilder.build<IJsonClifo, IClifosEntityService>(ENTITY_NAME_OUTROS_DEVEDORES_CREDORES);
      }
      return this._serviceOutrosDevedoresCredores;
    }
    if (!this._serviceFornecedores) {
      this._serviceFornecedores = this._entityServiceBuilder.build<IJsonClifo, IClifosEntityService>(ENTITY_NAME_FORNECEDORES);
    }
    return this._serviceFornecedores;
  }

  private _maintenanceType(): string {
    const type: string =
      this.configurations.clientesFornecedores.radicalClientes && this._radical.startsWith(this.configurations.clientesFornecedores.radicalClientes)
        ? 'arquivodigital.novacontaccmodal.successModal.types.cliente'
        : this.configurations.clientesFornecedores.radicalOutrosDevedoresCredores && this._radical.startsWith(this.configurations.clientesFornecedores.radicalOutrosDevedoresCredores)
          ? 'arquivodigital.novacontaccmodal.successModal.types.outroDevedorCredor'
          : 'arquivodigital.novacontaccmodal.successModal.types.fornecedor';
    return this._plTranslateService.translate(type);
  }

  private _maintenance(): IEntityMaintenanceInstance {
    if (this.configurations.clientesFornecedores.radicalClientes && this._radical.startsWith(this.configurations.clientesFornecedores.radicalClientes)) {
      if (!this._maintenanceClientes) {
        this._maintenanceClientes = this._entityMaintenanceService.build(ENTITY_NAME_CLIENTES);
      }
      return this._maintenanceClientes;
    }
    if (this.configurations.clientesFornecedores.radicalOutrosDevedoresCredores && this._radical.startsWith(this.configurations.clientesFornecedores.radicalOutrosDevedoresCredores)) {
      if (!this._maintenanceOutrosDevedoresCredores) {
        this._maintenanceOutrosDevedoresCredores = this._entityMaintenanceService.build(ENTITY_NAME_OUTROS_DEVEDORES_CREDORES);
      }
      return this._maintenanceOutrosDevedoresCredores;
    }
    if (!this._maintenanceFornecedores) {
      this._maintenanceFornecedores = this._entityMaintenanceService.build(ENTITY_NAME_FORNECEDORES);
    }
    return this._maintenanceFornecedores;
  }
}
