import {merge} from 'lodash-es';
import {Component, Injector, OnInit} from '@angular/core';
import {ICGCTableData, ICGTableOnSelect, IPlTableOptions, IPlToolbarItem, IPlToolbarItemDefinition, isArray, isDefined, isNumber} from 'pl-comps-angular';
import {EmpresasService} from '../../../../../services/empresas/empresas.service';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {SvatDataBuilder} from '../contabilidade.svat.builder';
import {SvatService} from '../contabilidade.svat.module.service';
import {IJsonEmpresaAno} from '../../../../../interfaces/jsonEmpresa.interface';
import {THttpQueryResponse} from '../../../../../services/api/api.service.interface';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
  selector: 'contabilidade-svat',
  templateUrl: './contabilidade.svat.module.component.html'
})
export class ContabilidadeSvatComponent extends ModuloComponent implements OnInit {
  public builder: SvatDataBuilder;
  public fieldAnoNCaption: string;
  public fieldAnoN1Caption: string;
  public tableCommonOptions: IPlTableOptions;
  public viewData: Array<any>;
  public anoEmCurso: number;

  private _dropdownAnos: IPlToolbarItemDefinition<IJsonEmpresaAno>;
  private _btnProcessar: IPlToolbarItemDefinition;
  private _btnCallModal: IPlToolbarItemDefinition;

  constructor(
    protected readonly _injector: Injector,
    private readonly _empresasService: EmpresasService,
    private readonly _svatService: SvatService
  ) {
    super(_injector);
    this.anoEmCurso = this._configService.configurations.empresa.anoEmCursoIRC;
    this.viewData = [];
    this.tableCommonOptions = {perPage: 20, suppressEmptyLines: true, hidePagination: true};
    this.updateAnoCaptions();
    this.builder = new SvatDataBuilder(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._dropdownAnos = {
      id: 'dropdownAnos',
      order: 2,
      type: 'dropdown',
      iconLeft: 'Ano: ',
      caption: String(this.anoEmCurso),
      menu: []
    };
    this._btnProcessar = {
      id: 'processar',
      order: 3,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-bolt"></i>',
      class: 'btn-success',
      caption: 'global.btn.process',
      click: () => this.processar()
    };
    this._btnCallModal = {
      id: 'callmodal',
      order: 4,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-print"></i>',
      class: 'btn-light',
      caption: 'global.btn.print',
      click: () => {
        this.callPrintModal();
      }
    };

    this.toolbar.addButton(this._btnProcessar);
    this.toolbar.addButton(this._btnCallModal);
    this.toolbar.addButton(this._dropdownAnos);
    this.loadAnosDisponiveis();
  }

  public updateAnoCaptions(): void {
    this.fieldAnoNCaption = `31/12/${this.anoEmCurso}`;
    this.fieldAnoN1Caption = `31/12/${this.anoEmCurso - 1}`;
  }

  public loadAnosDisponiveis(): void {
    this._dropdownAnos.menu = [];
    this._empresasService.getAnos(this.session.erp.nEmpresa).then((response: THttpQueryResponse<IJsonEmpresaAno>) => {
      if (response.body.list.length) {
        for (const item of response.body.list) {
          this._dropdownAnos.menu.push({
            caption: String(item.ano),
            data: item,
            click: (menuItem) => {
              this.changeYear(menuItem);
            }
          });
        }
      }
    });
  }

  public changeYear(item: IPlToolbarItem<IJsonEmpresaAno>): void {
    this._dropdownAnos.caption = item.caption;
    this.anoEmCurso = item.data.ano;
    this.updateAnoCaptions();
  }

  public callPrintModal(): void {
    this._svatService.openPrintModal(this.anoEmCurso);
  }

  public processar(): Promise<void> {
    return this._svatService.getDfSvat(this.anoEmCurso).then((response) => {
      this.builder.setup();
      this.responseToView(response);
    });
  }

  public responseToView(response: any): void {
    this.viewData = this.builder.build(response);
  }

  public balanceteClick(item: any, index: number): void {
    if (item?.subGroup?.details?.[index]) {
      const detail = item.subGroup.details[index];
      const modalSource: ICGCTableData = {list: [], total: 0, footer: []};
      const source = detail[1].tableSource.list || detail[1].tableSource;

      let anoNTotal = 0;
      let anoN1Total = 0;
      for (const sourceItem of source) {
        anoNTotal += sourceItem.anoN;
        anoN1Total += sourceItem.anoN1;
        modalSource.list.push(sourceItem);
      }

      modalSource.footer.push({
        nConta: modalSource.list.length,
        nomeConta: '&nbsp;',
        anoN: anoNTotal,
        anoN1: anoN1Total
      });

      this._svatService.openBalanceteModal({
        rubricaTitle: item.descricao,
        tableDef: detail[1].tableDef,
        tableSource: modalSource,
        tableOptions: merge({}, this.tableCommonOptions, {perPage: modalSource.list.length})
      });
    }
  }

  public trackMainTabs(index: number, mainTab: any): string {
    return mainTab ? mainTab.id : undefined;
  }

  public trackTabId(index: number, tab: any): string {
    return tab ? tab.id : undefined;
  }

  public getTableCommomOptions(source: any): IPlTableOptions {
    if (isDefined(source)) {
      const len = isArray(source) ? source.length : source.list.length;
      return merge({}, this.tableCommonOptions, {perPage: len});
    }
    return this.tableCommonOptions;
  }

  public getTableInstanceId(): string {
    return this.builder.rand();
  }

  public onSelect({item, columnIndex}: ICGTableOnSelect<unknown>): void {
    if (isNumber(columnIndex) && columnIndex !== -1) {
      item._detailOpen = !item._detailOpen;
    }
  }
}
