import {TDate} from '../../../../../common/dates';
import {IJsonDocComercialLinha} from '../../../../../core/entities/docscomerciais/jsonDocComercial.entity.interface';

export const MODULE_NAME_DEVOLUCOES = 'devolucoes';

export const MIN_STRING = '';
export const MAX_STRING = 'ZZZZ';

export enum ETipoDevolucao {
  None,
  Documento,
  ArtigoValor,
  DocumentoExterno,
  ArtigoQuantidade
}

export enum EStepsDevolucao {
  Documento = 'documento',
  Tipo = 'tipo',
  Devolucao = 'devolucao',
  Preview = 'preview'
}

export interface IDevolucaoLinha extends IJsonDocComercialLinha {
  faccbid: number;
  porDevolver: number;
  aDevolver: number;
  nDocStr: string;
  tipoDevolucao: number;
  nseqDestino?: number;
  totalDevolver?: number;
}

export interface IDevolucaoDocExterno {
  nDocExterno: string;
  dataDoc: TDate;
  valor: number;
  qtd?: number;
  codIva?: number;
  taxaIva?: number;
}
