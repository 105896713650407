export enum EDashboardRhChartType {
  FuncionariosPorSexo,
  FuncionariosPorIdade,
  FuncionariosPorDepartamento,
  FuncionariosPorTipoContrato,
  CustosFuncionarios
}

export interface IFilterFuncionarios {
  tipodivisao?: number;
  sexo?: number;
  idadede?: number;
  idadeate?: number;
}

export interface IDashboardRhPie {
  name: string;
  value: number;
  percent: number;
}

export interface IDashboardRhPieItem {
  codigo: number | string;
  nome: string;
  valor: number;
  percentagem?: number;
}

export const MODULE_NAME_DASHBOARD_RH = 'dashboardrh';
