import {Component, Injector, OnInit} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {PortalClientAccountService} from '../../../../../../services/portalclientaccount/portalclientaccount.service';

@Component({
  selector: 'protecao-password-recibos-modal',
  templateUrl: './protecaoPassword.modal.component.html'
})
export class ProtecaoPasswordModalComponent extends CGModalComponent<void> implements OnInit {
  public tipoPassword: number;
  public initialTipoPassword: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _portalClientAccountService: PortalClientAccountService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this._portalClientAccountService.getTipoPasswordRecibos().then((response) => {
      this.initialTipoPassword = response.body;
      this.tipoPassword = response.body;
    });
  }

  public close(): Promise<void> {
    if (this.initialTipoPassword !== this.tipoPassword) {
      return this._portalClientAccountService.saveTipoPasswordRecibos(this.tipoPassword).then(() => {
        this._plAlertService.success('protecaoPassword.modal.save');
        super.close();
      });
    }
    super.close();
    return Promise.resolve();
  }
}
