import {DashboardFinanceiroModuleComponent} from './components/dashboardFinanceiro.module.component';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {MODULE_NAME_DASHBOARD_FINANCEIRO} from './dashboardFinanceiro.module.interface';
import {RESOLVER_CG_BANKING_ACESSO, RESOLVER_EMPRESA_CGON_CG_BANKING_EXPIRED, RESOLVER_EMPRESA_TEM_CG_BANKING} from '../bancosextrato/bancosExtrato.module.router';
import {RESOLVER_CG_STORE_URL_BACKOFFICE} from '../../../config/uirouter/uirouter.resolvers';
import {RESOLVER_LICENCA_STORE_MODE_PUBLIC} from '../../services/config/config.service.router';
import {ROLE} from '../../services/role.const';

export const MODULE_DASHBOARD_FINANCEIRO: IModuleDefinition = {
  name: MODULE_NAME_DASHBOARD_FINANCEIRO,
  state: {
    url: '/dashboard',
    component: DashboardFinanceiroModuleComponent,
    data: {
      roles: [ROLE.FINANCEIRO],
      pageTitle: 'global.menu.dashboard',
      icon: 'fa-bar-chart'
    },
    resolve: [RESOLVER_LICENCA_STORE_MODE_PUBLIC, RESOLVER_EMPRESA_TEM_CG_BANKING, RESOLVER_CG_BANKING_ACESSO, RESOLVER_EMPRESA_CGON_CG_BANKING_EXPIRED, RESOLVER_CG_STORE_URL_BACKOFFICE]
  }
};
