import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isUndefined, PlAlertService} from 'pl-comps-angular';
import {DocsComerciaisModalComponent} from '../docsComerciais.modal.component';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../docfas/docFas.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IJsonClifo} from '../../../clifos/jsonClifo.entity.interface';
import {IJsonDocfa} from '../../../docfas/jsonDocFa.entity.interface';

@Component({
  selector: 'documento-duplicardocumento-modal',
  templateUrl: './documento.duplicarDocumento.modal.component.html'
})
export class DocumentoDuplicarModalComponent extends DocsComerciaisModalComponent implements OnInit {
  public readonly docfasOutput: string;
  public readonly clifoFilter: string;
  public nConta: string;
  public nIdAltern: number;
  public docFa: IJsonDocfa;
  public nDocFa: string;
  public clifo: IJsonClifo;

  private readonly _docFas: IDocFasEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.docfasOutput = '{{nDocFa}} - {{nome}} ({{descricao}})';
    this.clifoFilter = 'desactivado<>1';
    this._docFas = this._entityServiceBuilder.build<IJsonDocfa, IDocFasEntityService>(ENTITY_NAME_DOC_FAS);
  }

  public ngOnInit(): void {
    this._setTipoDoc(this.doc.cab.nDocFa);
    this.nConta = this.doc.cab.nConta;
    this.nIdAltern = this.doc.cab.nIdAltern;
  }

  public close(): Promise<void> {
    if (!this.nDocFa) {
      this._plAlertService.error('docscomerciais.erros.duplicarDocumentoNDocFaNulo');
      return Promise.reject(new Error('docscomerciais.erros.duplicarDocumentoNDocFaNulo'));
    }

    if (!this.nConta) {
      this._plAlertService.error('docscomerciais.erros.duplicarDocumentoNContaNulo');
      return Promise.reject(new Error('docscomerciais.erros.duplicarDocumentoNContaNulo'));
    }

    this.disableClose();

    return this.service
      .put({
        url: `${this.doc.cab.faccbId}/clone`,
        params: {
          ndocfa: this.nDocFa,
          nnumer: 1,
          nconta: this.nConta,
          nidaltern: this.nIdAltern,
          terminadoc: true,
          memoria: false
        }
      })
      .then((response) => {
        if (response.body) {
          this.doc.cab = response.body.cab;
        }

        this.enableClose();
        super.close(this.doc);
      })
      .finally(() => {
        this.enableClose();
      });
  }

  public onNContaChanged(clifo: IJsonClifo): void {
    if (!clifo || isUndefined(clifo.nConta)) {
      this._plAlertService.error('clifos.errors.contaNaoExiste');
      return;
    }

    this.clifo = clifo;

    this.nConta = clifo.nConta;
    this.nIdAltern = clifo.nIdAltern;
  }

  private _setTipoDoc(nDocfa: number): void {
    if (nDocfa) {
      this.nDocFa = nDocfa.toString();
      if (nDocfa > 0) {
        this._docFas
          .get({id: nDocfa, reportExceptions: false})
          .then((response: HttpResponse<IJsonDocfa>) => {
            // sucesso
            this.docFa = response.body;
          })
          .catch(() => {
            // falha
            this.docFa = undefined;
            this.nDocFa = '';
          });
      }
    }
  }
}
