<div class="contabilidade-digital-documentos-digitais-module entity-detail-form" [ngSwitch]="!temContabilidadeDigital && modoCGOn">
  <pl-alert type="error" [closeable]="false" *ngSwitchCase="true">
    <div [innerHTML]="'arquivodigital.errors.licenseNotActivatedCGOn' | translate: {href: cgStoreUrlBackOffice}"></div>
  </pl-alert>

  <pl-split-view *ngSwitchDefault [initialSizeLeft]="60" [initialSizeRight]="40" (evtSizeValuesChanged)="onResizerValuesChanged()">
    <div *plSplitViewLeftSide>
      <arquivo-digital-documentos-digitalizados
        [clientConnectMode]="clientConnectMode"
        [mode]="mode"
        [nif]="headerParams.nif"
        [nDocExterno]="headerParams.nDocExterno"
        [callback]="callbackContabDigital"
        (evtChangedSelectedLine)="changedSelectedLine($event)"
        (evtCalledAction)="calledAction($event)">
      </arquivo-digital-documentos-digitalizados>
    </div>

    <div
      *plSplitViewRightSide
      class="contabilidade-digital-documentos-digitais-module-docviewer"
      [class.docviewer-footer-collapsed]="docViewerFooterCollapsed"
      [class.docviewer-client-connect-mode]="clientConnectMode">
      <arquivodigital-docviewer
        [documentsService]="documentsService"
        [(docId)]="docID"
        [toolbarInstanceId]="toolbarInstanceId"
        [nDocumento]="nDocumentoContabDigitalClassfied"
        [callback]="callbackDocViewer"
        (evtConfigureToolbar)="configureToolbar($event)">
      </arquivodigital-docviewer>

      <ng-container *ngIf="!clientConnectMode">
        <hr />

        <contabilidade-digital-doc-viewer-recolha-footer
          [docOCR]="docOCR"
          [showCollapse]="!isMobile"
          [collapsed]="docViewerFooterCollapsed"
          (collapsedChange)="changedDocViewerFooterCollapsed($event)">
        </contabilidade-digital-doc-viewer-recolha-footer>
      </ng-container>
    </div>
  </pl-split-view>

  <div class="export-process-container" *ngIf="exportModel.showProcess" [ngSwitch]="!!exportModel.isReady">
    <div class="export-processing" *ngSwitchDefault>
      <div class="progress">
        <div class="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" [attr.aria-valuenow]="100" [attr.aria-valuemin]="0" [attr.aria-valuemax]="100"></div>
      </div>
      <div class="procLabel" [translate]="'arquivodigital.gestaodocsdigitalizados.export.processLabel'"></div>
    </div>

    <div class="export-processed" *ngSwitchCase="true">
      <div class="text-center"><strong [translate]="'arquivodigital.gestaodocsdigitalizados.export.zipReadyText'"></strong></div>
      <div class="export-process-filename">{{ exportModel.fileName }}</div>
      <div class="text-center">
        <pl-tooltip [config]="{text: 'arquivodigital.gestaodocsdigitalizados.export.processDownloadTooltip'}">
          <button *plTooltipContent type="button" class="btn btn-primary export-process-action-download" (click)="exportProcessDownload()">
            <i class="fa fa-download" aria-hidden="true"></i>&nbsp;<span [translate]="'arquivodigital.gestaodocsdigitalizados.export.downloadText'"></span>
          </button>
        </pl-tooltip>

        <pl-tooltip [config]="{text: 'global.btn.close'}">
          <button *plTooltipContent type="button" class="btn btn-light" (click)="exportProcessClose()">
            <i class="fa fa-times" aria-hidden="true"></i>&nbsp;<span [translate]="'global.btn.close'"></span>
          </button>
        </pl-tooltip>
      </div>
    </div>
  </div>
</div>
