import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {ConfigOptionsService} from '../../../../services/config/options/config.options.service';
import {MovimentosPendentesModuleComponent} from '../../movimentos.pendentes.module.component';
import {EGenericImporterType} from '../../../../components/genericimporter/generic.importer.component.interface';
import {GenericImporterService} from '../../../../components/genericimporter/generic.importer.service';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IPlToolbarItem} from 'pl-comps-angular';

@Component({
  selector: 'module-movimentos-pendentes-fornecedores',
  templateUrl: './movimentos.pendentes.fornecedores.module.component.html'
})
export class MovimentosPendentesFornecedoresModuleComponent extends MovimentosPendentesModuleComponent implements OnInit, OnDestroy {
  private _btnImport: IPlToolbarItem;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _configOptionsService: ConfigOptionsService,
    private readonly _genericImporterService: GenericImporterService
  ) {
    super(_injector, _configOptionsService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._btnImport = this._genericImporterService.toolbarButton(EGenericImporterType.CCFornecedores);
    this.toolbar.addButton(this._btnImport);
    const fnClick = this._btnImport.click.bind(this);
    this._btnImport.click = (item: IPlToolbarItem, event: MouseEvent | KeyboardEvent) =>
      Promise.resolve(fnClick(item, event)).then(() => {
        return this._refreshTable();
      });
  }

  public evtDataGridInstance(dataGridInstance: dxDataGrid): void {
    this._dataGridInstance = dataGridInstance;
  }

  private async _refreshTable(): Promise<void> {
    if (this._dataGridInstance) {
      await this._dataGridInstance.refresh();
    }
  }
}
