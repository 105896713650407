import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_PERIOCIDADE_CONTRATO_TIPO, IPeriocidadeContratoTipo} from './periocidadeContratoTipo.datasource.interface';

export const DATA_SOURCE_PERIOCIDADE_CONTRATO_TIPO: IDataSource<number, IPeriocidadeContratoTipo> = {
  name: DATA_SOURCE_NAME_PERIOCIDADE_CONTRATO_TIPO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{descricao}}',
    output: 'descricao',
    outputKey: 'periocidade'
  },
  data: deepFreeze<Array<IPeriocidadeContratoTipo>>([
    {periocidade: 0, descricao: 'periocidadeContratoTipo.data.sempre'},
    {periocidade: 1, descricao: 'periocidadeContratoTipo.data.diario'},
    {periocidade: 2, descricao: 'periocidadeContratoTipo.data.semanal'},
    {periocidade: 3, descricao: 'periocidadeContratoTipo.data.quinzenal'},
    {periocidade: 4, descricao: 'periocidadeContratoTipo.data.mensal'},
    {periocidade: 5, descricao: 'periocidadeContratoTipo.data.bimensal'},
    {periocidade: 6, descricao: 'periocidadeContratoTipo.data.trimestral'},
    {periocidade: 7, descricao: 'periocidadeContratoTipo.data.semestral'},
    {periocidade: 8, descricao: 'periocidadeContratoTipo.data.anual'}
  ])
};
