<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h5 class="modal-title" [innerText]="showTitleWithTab ? titleWithTab : ('rhsaltotrf.imprimir.title' | translate)"></h5>
</div>

<div class="modal-body">
  <div class="entity-detail-form">
    <pl-form>
      <cg-card-panel #cardPanel="cgCardPanel">
        <div *cgCardPanelContent>
          <div class="px-2 py-1">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-xl-3">
                <pl-group>
                  <label [translate]="'rhsaltotrf.fields.codEmpDe'"></label>
                  <edit>
                    <entity-autocomplete entity="dgempsfull" attrName="codEmpDe" [model]="filters" (selectedKeyChange)="filters.codEmpDe = $event" [fieldsMap]="{codEmp: 'codEmpDe'}" output="key">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>

              <div class="col-sm-12 col-md-6 col-xl-3">
                <pl-group>
                  <label [translate]="'global.text.to'"></label>
                  <edit>
                    <entity-autocomplete entity="dgempsfull" attrName="codEmpAte" [model]="filters" (selectedKeyChange)="filters.codEmpAte = $event" [fieldsMap]="{codEmp: 'codEmpAte'}" output="key">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>

              <div class="col-sm-12 col-md-6 col-xl-3">
                <pl-group>
                  <edit>
                    <label [translate]="'rhsaltotrf.fields.dataProcesDE'"></label>
                    <pl-edit type="date" [(model)]="filters.dataProcesDe"></pl-edit>
                  </edit>
                </pl-group>
              </div>

              <div class="col-sm-12 col-md-6 col-xl-3">
                <pl-group>
                  <edit>
                    <label [translate]="'global.text.to'"></label>
                    <pl-edit type="date" [(model)]="filters.dataProcesAte"></pl-edit>
                  </edit>
                </pl-group>
              </div>

              <div class="col-sm-12 col-md-6 col-xl-3">
                <pl-group>
                  <label [translate]="'rhsaltotrf.fields.bancoDe'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="bancos"
                      attrName="bancoDe"
                      [model]="filters"
                      (selectedKeyChange)="filters.bancoDe = $event"
                      [fieldsMap]="{codBanco: 'bancoDe'}"
                      output="key"
                      [properties]="{disabled: !tabVencimento}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>

              <div class="col-sm-12 col-md-6 col-xl-3">
                <pl-group>
                  <label [translate]="'global.text.to'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="bancos"
                      attrName="bancoAte"
                      [model]="filters"
                      (selectedKeyChange)="filters.bancoAte = $event"
                      [fieldsMap]="{codBanco: 'bancoAte'}"
                      output="key"
                      [properties]="{disabled: !tabVencimento}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>

              <div class="col-sm-12 col-md-6 col-xl-3">
                <pl-group>
                  <label [translate]="'rhsaltotrf.fields.fRemuDe'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="fremun"
                      attrName="fRemuDe"
                      [model]="filters"
                      (selectedKeyChange)="filters.fRemuDe = $event"
                      [fieldsMap]="{nFRemun: 'fRemuDe'}"
                      output="key"
                      [properties]="{disabled: !tabVencimento}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>

              <div class="col-sm-12 col-md-6 col-xl-3">
                <pl-group>
                  <label [translate]="'global.text.to'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="fremun"
                      attrName="fRemuAte"
                      [model]="filters"
                      (selectedKeyChange)="filters.fRemuAte = $event"
                      [fieldsMap]="{nFRemun: 'fRemuAte'}"
                      output="key"
                      [properties]="{disabled: !tabVencimento}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </div>
            </div>
          </div>
        </div>
      </cg-card-panel>

      <div class="row mb-3 mt-2">
        <div class="d-flex">
          <div class="flex-grow-1 pe-2">
            <pl-group>
              <label [translate]="'global.text.listing'"></label>
              <edit>
                <pl-autocomplete
                  [source]="validResportsSource"
                  [model]="report"
                  (modelChange)="reportChange($event)"
                  [allowInvalid]="false"
                  [output]="outputReport"
                  [rowTemplate]="rowTemplateReport"></pl-autocomplete>
              </edit>
            </pl-group>
          </div>
          <div class="ps-2 align-self-end">
            <pl-group>
              <edit>
                <button type="button" class="btn btn-sm btn-primary" name="preVisualizar" id="btnPreVisualizar" [click]="preVisualizar" plPromise data-focus>
                  <i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.preview'"></span>
                </button>
              </edit>
            </pl-group>
          </div>
        </div>
      </div>
    </pl-form>
  </div>

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
