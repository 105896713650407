import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ETipoSubsidio} from '../../rhRecolhaSerieSubsidios.module.interface';
import {IJsonBoolean} from '../../../../../../common/interfaces/json';
import {IJsonRHRecolhaSerieSubsidios, IJsonRHRecolhaSerieSubsidiosGravar, IJsonRHRecolhaSerieSubsidiosProcess} from '../../jsonRHRecolhaSerieSubsidios.module.interface';
import {RHRecolhaSerieSubsidiosService} from '../../rhRecolhaSerieSubsidios.module.service';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnRowUpdating} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import ArrayStore from 'devextreme/data/array_store';

const TOOLBAR_INSTANCE = 'toolbar-rhrecolhaseriesubsidios-subssaved';

@Component({
  selector: 'modal-rhrecolhaseriesubsidios-subssaved',
  templateUrl: './rhRecolhaSerieSubsidios.subsSaved.modal.component.html'
})
export class RHRecolhaSerieSubsidiosSubsSavedModalComponent extends CGModalComponent<void> implements OnInit, OnDestroy {
  @Input() public tipoSubsidio: ETipoSubsidio;
  @Input() public emplist: string;
  @Input() public rhRecolhaSerieSubsidios: IJsonRHRecolhaSerieSubsidios;
  @Input() public rhRecolhaSerieSubsidiosProcessList: Array<IJsonRHRecolhaSerieSubsidiosProcess>;

  public readonly eTipoSubsidio: typeof ETipoSubsidio;
  public readonly toolbarInstanceName: string;
  public titleName: string;
  public dataGridSubsidio: IDevExpressDataGrid<IJsonRHRecolhaSerieSubsidiosProcess, number>;

  private _dataGridInstanceSubsidio: dxDataGrid<IJsonRHRecolhaSerieSubsidiosProcess, number>;
  private _cambioVisible: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _rhRecolhaSerieSubsidiosService: RHRecolhaSerieSubsidiosService
  ) {
    super(_injector);
    this.eTipoSubsidio = ETipoSubsidio;
    this.toolbarInstanceName = TOOLBAR_INSTANCE;
  }

  public ngOnInit(): void {
    switch (this.tipoSubsidio) {
      case this.eTipoSubsidio.SubFerias:
        this.titleName = 'ferias';
        break;
      case this.eTipoSubsidio.SubNatal:
        this.titleName = 'natal';
        break;
      case this.eTipoSubsidio.SubAlimentacaoPreProce:
        this.titleName = 'alimentacao';
        break;
    }

    this.dataGridSubsidio = {
      columns: [
        {dataField: 'codEmpregado', dataType: 'number', caption: 'rhrecolhaseriesubsidios.modal.fields.codEmpregado', allowEditing: false},
        {dataField: 'nomeEmpregado', dataType: 'string', caption: 'rhrecolhaseriesubsidios.modal.fields.nomeEmpregado', allowEditing: false},
        {
          dataField: 'dataAdmissao',
          dataType: 'date',
          caption: 'rhrecolhaseriesubsidios.modal.fields.dataAdmissao',
          visible: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          showInColumnChooser: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          allowEditing: false
        },
        {
          dataField: 'nDiasAPagar',
          dataType: 'number',
          caption: 'rhrecolhaseriesubsidios.modal.fields.nDiasAPagar',
          allowEditing: true
        },
        {dataField: 'valorAPagar', dataType: 'double', caption: 'rhrecolhaseriesubsidios.modal.fields.valorAPagar', allowEditing: false},
        {
          dataField: 'nMAxDiasUteisSubsidio',
          dataType: 'double',
          caption: 'rhrecolhaseriesubsidios.modal.fields.nMAxDiasUteisSubsidio',
          visible: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          showInColumnChooser: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          allowEditing: false
        },
        {
          dataField: 'nDiasPagosAnoAnterior',
          dataType: 'double',
          caption: 'rhrecolhaseriesubsidios.modal.fields.nDiasPagosAnoAnterior',
          visible: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          showInColumnChooser: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          allowEditing: false
        },
        {
          dataField: 'valorPagoAnoAnterior',
          dataType: 'double',
          caption: 'rhrecolhaseriesubsidios.modal.fields.valorPagoAnoAnterior',
          visible: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          showInColumnChooser: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          allowEditing: false
        },
        {
          dataField: 'nDiasPorPagarAnoAnterior',
          dataType: 'double',
          caption: 'rhrecolhaseriesubsidios.modal.fields.nDiasPorPagarAnoAnterior',
          visible: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          showInColumnChooser: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          allowEditing: false
        },
        {
          dataField: 'nDiasTrabalhados',
          dataType: 'double',
          caption: 'rhrecolhaseriesubsidios.modal.fields.nDiasTrabalhados',
          visible: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          showInColumnChooser: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          allowEditing: false
        },
        {
          dataField: 'nDiasDescontarPorFaltasEmpregado',
          dataType: 'double',
          caption: 'rhrecolhaseriesubsidios.modal.fields.nDiasDescontarPorFaltasEmpregado',
          visible: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          showInColumnChooser: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          allowEditing: false
        },
        {
          dataField: 'nDiasPagos',
          dataType: 'double',
          caption: 'rhrecolhaseriesubsidios.modal.fields.nDiasPagos',
          visible: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          showInColumnChooser: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          allowEditing: false
        },
        {
          dataField: 'valorEmMoedaEstrangeira',
          dataType: 'double',
          caption: 'rhrecolhaseriesubsidios.modal.fields.valorEmMoedaEstrangeira',
          visible: this._cambioVisible && this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          showInColumnChooser: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          allowEditing: false
        },
        {
          dataField: 'valorCambio',
          dataType: 'double',
          caption: 'rhrecolhaseriesubsidios.modal.fields.valorCambio',
          visible: this._cambioVisible && this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          showInColumnChooser: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          allowEditing: false
        },
        {
          dataField: 'moedaCambio',
          dataType: 'string',
          caption: 'rhrecolhaseriesubsidios.modal.fields.moedaCambio',
          visible: this._cambioVisible && this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          showInColumnChooser: this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce,
          allowEditing: false
        },
        {dataField: 'erros', dataType: 'string', caption: 'rhrecolhaseriesubsidios.modal.fields.info', cellTemplate: 'cellTemplateInfo', allowEditing: false}
      ],
      columnAutoWidth: true,
      hoverStateEnabled: true,
      remoteOperations: false,
      showBorders: true,
      editing: {
        allowDeleting: false,
        allowUpdating: true,
        mode: 'cell',
        selectTextOnEditStart: true,
        startEditAction: 'click'
      }
    };
    this.dataGridSubsidio.dataSource = new ArrayStore({
      key: 'codEmpregado',
      data: this.rhRecolhaSerieSubsidiosProcessList
    });

    this._cambioVisible = this.rhRecolhaSerieSubsidiosProcessList.filter((subsidiosProcess: IJsonRHRecolhaSerieSubsidiosProcess) => subsidiosProcess.cambioVisivel).length > 0;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public onInitializedSubsidio({component}: IDevExpressDataGridEventOnInitialized<IJsonRHRecolhaSerieSubsidiosProcess, number>): void {
    this._dataGridInstanceSubsidio = component;
    if (this._dataGridInstanceSubsidio) {
      this._evaluateColumnsVisibilitySubsidio();
    }
  }

  public onRowUpdatingSubsidio(event: IDevExpressDataGridEventOnRowUpdating<IJsonRHRecolhaSerieSubsidiosProcess, number>): void {
    this.nDiasAPagarChanged(event.oldData, event.newData.nDiasAPagar);
  }

  public close(): Promise<void> {
    this._dataGridInstanceSubsidio
      .getDataSource()
      .store()
      .load()
      .then((itens: Array<IJsonRHRecolhaSerieSubsidiosProcess>) => {
        this.rhRecolhaSerieSubsidiosProcessList = itens;
      });
    const rhrecolhaseriesubsidiosgravar: IJsonRHRecolhaSerieSubsidiosGravar = {
      empList: this.emplist,
      rhRecolhaSerieSubsidiosProcess: this.rhRecolhaSerieSubsidiosProcessList,
      rhRecolhaSerieSubsidios: this.rhRecolhaSerieSubsidios
    };
    return this._rhRecolhaSerieSubsidiosService.postGravarPagamentoSubsidio(rhrecolhaseriesubsidiosgravar).then((response: HttpResponse<IJsonBoolean>) => {
      if (response.body.value) {
        this._plAlertService.success('rhrecolhaseriesubsidios.messages.semdadossave');
      } else {
        this._plAlertService.success('rhrecolhaseriesubsidios.messages.dadossaved');
      }
      super.close();
    });
  }

  public nDiasAPagarChanged(line: IJsonRHRecolhaSerieSubsidiosProcess, value: number): Promise<void> {
    return this._rhRecolhaSerieSubsidiosService.postCalculaValorAPagar(line, value).then((response: HttpResponse<IJsonRHRecolhaSerieSubsidiosProcess>) => {
      this._dataGridInstanceSubsidio.getDataSource().store().update(response.body.codEmpregado, response.body);
    });
  }

  private _evaluateColumnsVisibilitySubsidio(): void {
    this._dataGridInstanceSubsidio.columnOption('dataAdmissao', 'visible', this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce);
    this._dataGridInstanceSubsidio.columnOption('nMAxDiasUteisSubsidio', 'visible', this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce);
    this._dataGridInstanceSubsidio.columnOption('nDiasPagosAnoAnterior', 'visible', this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce);
    this._dataGridInstanceSubsidio.columnOption('valorPagoAnoAnterior', 'visible', this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce);
    this._dataGridInstanceSubsidio.columnOption('nDiasPorPagarAnoAnterior', 'visible', this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce);
    this._dataGridInstanceSubsidio.columnOption('nDiasTrabalhados', 'visible', this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce);
    this._dataGridInstanceSubsidio.columnOption('nDiasDescontarPorFaltasEmpregado', 'visible', this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce);
    this._dataGridInstanceSubsidio.columnOption('nDiasPagos', 'visible', this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce);
    this._dataGridInstanceSubsidio.columnOption('valorEmMoedaEstrangeira', 'visible', this._cambioVisible && this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce);
    this._dataGridInstanceSubsidio.columnOption('valorCambio', 'visible', this._cambioVisible && this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce);
    this._dataGridInstanceSubsidio.columnOption('moedaCambio', 'visible', this._cambioVisible && this.tipoSubsidio !== ETipoSubsidio.SubAlimentacaoPreProce);
  }
}
