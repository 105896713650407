import {ClassProvider, forwardRef, Injectable} from '@angular/core';
import {CGStateService} from '../../../../../components/state/cg.state.service';
import {DI_AMALIA_INTENT_HANDLERS} from '../../../../../services/amalia/intent/amalia.intent.handler.di';
import {EPortal} from '../../../../../../common/enums/portals.enums';
import {IAmaliaIntent, IAmaliaIntentHandler} from '../../../../../services/amalia/intent/amalia.intent.handler.interface';
import {MODULE_NAME_PCA_ESTATISTICA_BALANCETES} from '../contabilidade.balancetes.module.interface';

export interface IBalancetesIntentParameters {
  date: string;
}

export const AMALIA_INTENT_HANDLER_CONTABILIDADE_BALANCETES: ClassProvider = {
  provide: DI_AMALIA_INTENT_HANDLERS,
  multi: true,
  useClass: forwardRef(() => ContabilidadeBalancetesAmaliaIntentHandler)
};

@Injectable()
export class ContabilidadeBalancetesAmaliaIntentHandler implements IAmaliaIntentHandler {
  constructor(private readonly _cgStateService: CGStateService) {}

  public intentName(): string {
    return MODULE_NAME_PCA_ESTATISTICA_BALANCETES;
  }

  public async intentHandler(intent: IAmaliaIntent<IBalancetesIntentParameters>): Promise<void> {
    return this._cgStateService.redirectToState({
      stateOrName: this.intentName(),
      portal: EPortal.CONTABILIDADE,
      params: {
        date: intent.parameters.date
      }
    });
  }
}
