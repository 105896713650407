import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {UntypedFormGroup} from '@angular/forms';
import {ModuloComponent} from '../../../../components/module/module.component';
import {
  downloadStream,
  ICGTableOnSelect,
  IPlNavWizardDefinition,
  IPlNavWizardEventBeforeChange,
  IPlNavWizardEventStep,
  IPlNavWizardStep,
  IPlTableCallback,
  IPlTableDefinition,
  isDefinedNotNull,
  isFunction,
  PlAlertService,
  TPlTableItem
} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {focusElement} from '../../../../../common/utils/element.utils';
import {IJsonErpUser} from '../../../../services/account/jsonUserApi.interface';
import {
  IJsonProcSegSocialProcessExistentesParams,
  IJsonProcSeguradoraEmpresas,
  IJsonProcSeguradoraFolhaFerias,
  IJsonProcSeguradoraFolhaFeriasOuErros,
  IJsonSegSocialErro,
  IJsonSegSocialProcessExistentes,
  IJsonSeguradoraFolhaFeriasMPCX
} from '../jsonProcSeguradoraFolhaFerias.module.interface';
import {IProcSeguradoraFolhaFeriasDataItem, ISegSocialProcessExistentes} from '../procSeguradoraFolhaFerias.module.interface';
import {ProcSeguradoraFolhaFeriasInfoModalComponent} from '../modals/info/procSeguradoraFolhaFerias.info.modal.component';
import {ProcSeguradoraFolhaFeriasPrintModalComponent} from '../modals/print/procSeguradoraFolhaFerias.print.modal.component';
import {ProcSeguradoraFolhaFeriasService} from '../procSeguradoraFolhaFerias.module.service';
import moment from 'moment';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {DATA_SOURCE_NAME_MESES} from '../../../../datasources/meses/meses.datasource.interface';
import {orderBy} from 'lodash-es';

const STEP_INDEX_EMPRESAS = 1;
const STEP_INDEX_CONFIGURACAO = 2;
const STEP_INDEX_RELATORIO = 3;
const TAB_RELATORIO = 'relatorio';
const TAB_ERROS = 'erros';
const TOOLBAR_INSTANCE_ID_PROC_RESULTS = 'toolbar-proc-results';
const TOTAL_MONTHS = 12;

@Component({
  selector: 'module-procseguradorafolhaferias',
  templateUrl: './procSeguradoraFolhaFerias.module.component.html'
})
export class ProcSeguradoraFolhaFeriasModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly definition: IPlNavWizardDefinition;
  public readonly multiDatasTableCallback: IPlTableCallback;
  public readonly multiDatasTableDef: IPlTableDefinition;
  public readonly dataGridDefinitionProcExistentes: IDevExpressDataGrid;
  public readonly dataGridDefinitionFFSeguradora: IDevExpressDataGrid;
  public readonly dataGridDefinitionErros: IDevExpressDataGrid;
  public readonly empresasTemplate: string;

  public procSeguradoraFolhaFeriasOuErros: IJsonProcSeguradoraFolhaFeriasOuErros;
  public seguradoraFolhaFeriasMPCX: Array<IJsonSeguradoraFolhaFeriasMPCX>;
  public empresasSource: Array<IJsonErpUser>;
  public selectedStep: IPlNavWizardStep;
  public empresas: Array<IJsonErpUser>;
  public formConfiguracao: UntypedFormGroup;
  public formRelatorio: UntypedFormGroup;
  public formEmpresas: UntypedFormGroup;
  public toolbarInstIdProcResults: string;
  public activeId: string;
  public selectedYear: number;
  public processaNovamente: boolean;
  public selectedAll: boolean;
  public selectedRowKeys: Array<number>;
  public orderBySelected: string;

  private readonly _multiDatasSourceCache: {[year: number]: Array<IProcSeguradoraFolhaFeriasDataItem>};
  private _procExistentesTableSource: Array<ISegSocialProcessExistentes>;
  private _multiDatasTableSource: Array<IProcSeguradoraFolhaFeriasDataItem>;
  private _isFirstTime: boolean;
  private _dataGridProcExistentesInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _procSeguradoraFolhaFeriasService: ProcSeguradoraFolhaFeriasService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.dataGridDefinitionProcExistentes = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'tipoProcessamento', dataType: 'string', caption: 'procseguradorafolhaferias.fields.tipoProcessamento'},
        {dataField: 'tipoProcessamentoDescricao', dataType: 'string', caption: 'procseguradorafolhaferias.fields.tipoProcessamentoDescricao'},
        {dataField: 'dataProcessamento', dataType: 'date', caption: 'procseguradorafolhaferias.fields.dataProcessamento'},
        {dataField: 'empresa', dataType: 'string', caption: 'procseguradorafolhaferias.fields.empresa'},
        {dataField: 'nomeEmpresa', dataType: 'string', caption: 'procseguradorafolhaferias.fields.nomeEmpresa'},
        {dataField: 'ssAno', dataType: 'number', caption: 'global.text.year'},
        {dataField: 'ssMes', dataType: 'number', caption: 'global.text.month'}
      ],
      dataSource: new CustomStore({
        key: '_index',
        load: () => this._fnProcExistentesTableSource()
      }),
      export: {filename: 'procseguradorafolhaferias.steps.title.titleconfig'},
      selection: {mode: 'multiple', selectAllMode: 'allPages', showCheckBoxesMode: 'always'},
      remoteOperations: false
    };
    this.dataGridDefinitionFFSeguradora = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'ssnEmpresa', dataType: 'string', caption: 'procseguradorafolhaferias.fields.nempresa'},
        {dataField: 'ssnomeEmpresa', dataType: 'string', caption: 'procseguradorafolhaferias.fields.nomeEmpresa'},
        {dataField: 'mes', dataType: 'number', caption: 'global.text.month', lookup: {cgDataSource: DATA_SOURCE_NAME_MESES}},
        {dataField: 'ano', dataType: 'number', caption: 'global.text.year'},
        {dataField: 'totalRemuneracaoSegSocial', dataType: 'double', caption: 'procseguradorafolhaferias.fields.totalRemuneracaoSegSocial'},
        {type: 'buttons', caption: 'procseguradorafolhaferias.fields.email.caption', cellTemplate: 'cellTemplateEmail', minWidth: 250, width: 250, showInColumnChooser: false},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn', showInColumnChooser: false}
      ],
      dataSource: new CustomStore({
        load: () => this._ffSeguradoraTableSource()
      }),
      export: {filename: 'procseguradorafolhaferias.steps.relatorio'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateFFSeguradora',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionErros = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nempresa', dataType: 'string', caption: 'procseguradorafolhaferias.fields.nempresa'},
        {dataField: 'erro', dataType: 'string', caption: 'global.text.error'},
        {dataField: 'queFazer', dataType: 'string', caption: 'procseguradorafolhaferias.fields.queFazer'}
      ],
      dataSource: new CustomStore({
        load: () => this._errorsTableSource()
      }),
      export: {filename: 'procseguradorafolhaferias.steps.title.errossegSocial'},
      remoteOperations: false
    };
    this.selectedRowKeys = [];
    this.selectedYear = moment().subtract(1, 'months').year();
    this.empresasTemplate = '{{nEmpresa}} - {{nomeEmpresa}}';
    this.selectedAll = false;
    this.processaNovamente = false;
    this.empresas = [];
    this._multiDatasTableSource = [];
    this._procExistentesTableSource = [];
    this.seguradoraFolhaFeriasMPCX = [];
    this.multiDatasTableCallback = {};
    this._multiDatasSourceCache = {};
    this.procSeguradoraFolhaFeriasOuErros = {
      erros: [],
      procsegff: []
    };
    this.definition = {
      items: []
    };
    this.multiDatasTableDef = {
      fields: [
        {name: 'selected', caption: 'procseguradorafolhaferias.fields.selected', type: 'boolean'},
        {name: 'descricao', caption: 'procseguradorafolhaferias.fields.mesAno'}
      ]
    };
    this._plToolbarService.registerInstance(this.toolbarInstIdProcResults, {
      items: [
        {
          id: 'imprimir-mapa',
          type: 'button',
          class: 'btn-light',
          iconLeft: '<i class="fa fa-print"></i>&nbsp;',
          caption: 'procseguradorafolhaferias.btn.printmap',
          click: () => {
            this._printMap();
          }
        },
        {
          id: 'gerar-ficheiro-segu',
          type: 'button',
          class: 'btn-light',
          iconLeft: '<i class="fa fa-bolt"></i>&nbsp;',
          caption: 'procseguradorafolhaferias.btn.genfichsegu',
          click: () => this._genFile()
        }
      ]
    });
    this.activeId = TAB_RELATORIO;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._isFirstTime = true;
    this._loadEmpresas();
    this.orderBySelected = this._translateService.instant('procseguradorafolhaferias.btn.dropdown.sortEmpresasByNEmpresa');
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._plToolbarService.unRegisterInstance(this.toolbarInstIdProcResults);
  }

  public setInstanceName(value: string): void {
    super.setInstanceName(value);
    this.toolbarInstIdProcResults = `${this.instanceName}-${TOOLBAR_INSTANCE_ID_PROC_RESULTS}`;
  }

  public stepChanged({currentStep}: IPlNavWizardEventStep): Promise<void> | void {
    if (currentStep === this.definition.items[STEP_INDEX_EMPRESAS]) {
      setTimeout(() => {
        const button: HTMLElement = this._element.querySelector('.nav-wizard-item-content-wrapper.active button.action-next-step');
        if (button) {
          focusElement(button);
        }
      });
    }
    if (currentStep === this.definition.items[STEP_INDEX_CONFIGURACAO]) {
      setTimeout(() => {
        const button: HTMLElement = this._element.querySelector('.nav-wizard-item-content-wrapper.active button.action-next-step');
        if (button) {
          focusElement(button);
        }
      });
    }
    if (currentStep === this.definition.items[STEP_INDEX_RELATORIO]) {
      setTimeout(() => {
        if (this.procSeguradoraFolhaFeriasOuErros.erros.length > 0) {
          this.activeId = TAB_ERROS;
        }
        const button: HTMLElement = this._element.querySelector('.nav-wizard-item-content-wrapper.active button.action-finalize');
        if (button) {
          focusElement(button);
        }
      });
    }
  }

  public onStepChange(step: IPlNavWizardStep): void {
    this.selectedStep = step;
  }

  public onDecYearClick(): void {
    this.selectedYear -= 1;
    this._procExistentesTableSource = [];
    this._refreshDataAndExistences();
  }

  public onIncYearClick(): void {
    this.selectedYear += 1;
    this._procExistentesTableSource = [];
    this._refreshDataAndExistences();
  }

  public onMultiYearChange(year: number): void {
    if (this.selectedYear !== year) {
      this.selectedYear = year;
      this._procExistentesTableSource = [];
      this._refreshDataAndExistences();
    }
  }

  public doSelect(value: boolean, line: TPlTableItem<ISegSocialProcessExistentes>): void {
    line.selected = value;
    const isSelected = this._procExistentesTableSource.find((item: ISegSocialProcessExistentes) => item.selected === false);
    this.selectedAll = isSelected ? isSelected.selected : true;
  }

  public viewInfoClick(item: IJsonProcSeguradoraFolhaFerias): Promise<void> {
    return this._procSeguradoraFolhaFeriasService.postInfoSeguradoraFolhaFerias(item).then((response: Array<IJsonSeguradoraFolhaFeriasMPCX>) => {
      this.seguradoraFolhaFeriasMPCX = response;
      const modalRef = this._cgModalService.showVanilla(ProcSeguradoraFolhaFeriasInfoModalComponent, {size: 'fullscreen'});
      const componentInstance: ProcSeguradoraFolhaFeriasInfoModalComponent = modalRef.componentInstance;
      componentInstance.seguradoraFolhaFeriasMPCX = this.seguradoraFolhaFeriasMPCX;
      componentInstance.procSeguradoraFolhaFerias = item;
    });
  }

  public enviaEmailClick(item: IJsonProcSeguradoraFolhaFerias): Promise<void> {
    return this._procSeguradoraFolhaFeriasService.postEnviarFicheiroSeguradoraFolhaFeriasPorEmail(item).then((response) => {
      if (response.body.sent) {
        this._plAlertService.success('procseguradorafolhaferias.messages.email.enviado');
      } else {
        this._plAlertService.error(response.body.errorMessage.length ? response.body.errorMessage : 'procseguradorafolhaferias.messages.email.naoenviado');
      }
    });
  }

  public onInitializedProcExistentes({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridProcExistentesInstance = component;
  }

  public sortEmpresasByNEmpresa(): void {
    this.empresasSource = orderBy(this.empresasSource, ['nEmpresa', 'asc']);
    this.empresas = orderBy(this.empresas, ['nEmpresa', 'asc']);
    this.orderBySelected = this._translateService.instant('procseguradorafolhaferias.btn.dropdown.sortEmpresasByNEmpresa');
  }

  public sortEmpresasByName(): void {
    this.empresasSource = orderBy(this.empresasSource, ['nomeEmpresa', 'asc']);
    this.empresas = orderBy(this.empresas, ['nomeEmpresa', 'asc']);
    this.orderBySelected = this._translateService.instant('procseguradorafolhaferias.btn.dropdown.sortEmpresasByName');
  }

  public readonly fnFinalize: () => Promise<void> = () => this._finalize();

  public readonly fnMultiDatasTableSource = (): Array<IProcSeguradoraFolhaFeriasDataItem> => this._getMultiDatasTableSource();

  public readonly fnMultiDatasTableOnSelect = (event: ICGTableOnSelect<IProcSeguradoraFolhaFeriasDataItem>): Promise<void> => this._onMultiDatasTableSelect(event);

  public readonly fnBeforeStepChange = (event: IPlNavWizardEventBeforeChange): Promise<boolean> => this._beforeStepChange(event);

  private _beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (currentStep === this.definition.items[STEP_INDEX_EMPRESAS] && (type === 'next' || (nextStep && nextStep === this.definition.items[STEP_INDEX_CONFIGURACAO]))) {
      if (!this.formEmpresas.valid || !this.empresas.length) {
        this._plAlertService.error('procseguradorafolhaferias.messages.invalidStepEmp');
        return Promise.resolve(false);
      }
      return Promise.resolve(true);
    }
    if (currentStep === this.definition.items[STEP_INDEX_CONFIGURACAO] && (type === 'next' || (nextStep && nextStep === this.definition.items[STEP_INDEX_RELATORIO]))) {
      if (!this.formConfiguracao.valid || !this._procExistentesTableSource.length) {
        this._plAlertService.error('procseguradorafolhaferias.messages.invalidStepConf');
        return Promise.resolve(false);
      }
      let promise: Promise<void>;
      const temfich = !this._procExistentesTableSource.find((value: ISegSocialProcessExistentes) => value.temFichGerado === true);
      if (!temfich) {
        promise = this._cgModalService
          .showOkCancel('global.text.confirmation', 'procseguradorafolhaferias.prompt.confMessage', {
            btnCancelText: 'global.btn.no',
            btnOkText: 'global.btn.yes'
          })
          .then(() => {
            this.processaNovamente = true;
          })
          .catch(() => {
            this.processaNovamente = false;
          });
      }
      return new Promise<boolean>((resolve) => {
        Promise.resolve(promise).finally(() => {
          for (const item of this._procExistentesTableSource) {
            item.selected = this.selectedRowKeys.findIndex((index: number) => item._index === index) !== -1;
          }
          this._procSeguradoraFolhaFeriasService
            .postProceSeguradoraFFerias(this._procExistentesTableSource, this.processaNovamente)
            .then((response: IJsonProcSeguradoraFolhaFeriasOuErros) => {
              this.procSeguradoraFolhaFeriasOuErros = response;
              if (this.procSeguradoraFolhaFeriasOuErros.erros.length > 0) {
                this._plAlertService.warning('procseguradorafolhaferias.messages.empresascomerros');
              }
              resolve(true);
            })
            .catch((reason: unknown) => {
              this._logger.error(reason);
              resolve(false);
            });
        });
      });
    }
    return Promise.resolve(true);
  }

  private _refreshDataAndExistences(): void {
    if (isFunction(this.multiDatasTableCallback.refresh)) {
      this.multiDatasTableCallback.refresh();
    }
    if (this._dataGridProcExistentesInstance) {
      this._dataGridProcExistentesInstance.refresh().then(() => {
        return this._dataGridProcExistentesInstance.selectAll();
      });
    }
  }

  private _finalize(): Promise<void> {
    return this._stateService.reload().then(() => undefined);
  }

  private _loadEmpresas(): boolean {
    const empresassel: Array<IJsonErpUser> = [...this.session.erps];
    let idx = -1;
    const foundItem = empresassel.find((item: IJsonErpUser, index: number) => {
      if (item.nEmpresa === this.session.erp.nEmpresa) {
        idx = index;
        return true;
      }
      return false;
    });
    if (idx > -1 && isDefinedNotNull(foundItem)) {
      empresassel.splice(idx, 1);
      this.empresas = [foundItem];
    }
    this.empresasSource = empresassel;
    this.sortEmpresasByNEmpresa();
    return true;
  }

  private _getMultiDatasTableSource(): Array<IProcSeguradoraFolhaFeriasDataItem> {
    for (const item of this._multiDatasTableSource) {
      item.selected = false;
    }
    this._multiDatasTableSource = this._multiDatasSourceCache[this.selectedYear];
    if (this._multiDatasTableSource) {
      if (this._isFirstTime) {
        for (const item of this._multiDatasTableSource) {
          item.selected = false;
        }
        this._isFirstTime = false;
      }
      return this._multiDatasTableSource;
    }
    this._multiDatasTableSource = this._buildDataSource(this.selectedYear);
    if (this._isFirstTime) {
      this._isFirstTime = false;
    }
    this._multiDatasSourceCache[this.selectedYear] = this._multiDatasTableSource;
    return this._multiDatasTableSource;
  }

  private _errorsTableSource(): Array<IJsonSegSocialErro> {
    return this.procSeguradoraFolhaFeriasOuErros.erros;
  }

  private _ffSeguradoraTableSource(): Array<IJsonProcSeguradoraFolhaFerias> {
    return this.procSeguradoraFolhaFeriasOuErros.procsegff;
  }

  private _buildDataSource(year: number): Array<IProcSeguradoraFolhaFeriasDataItem> {
    const source: Array<IProcSeguradoraFolhaFeriasDataItem> = [];
    for (let i = 1; i <= TOTAL_MONTHS; i++) {
      source.push({
        selected: false,
        mes: i,
        ano: year,
        descricao: moment()
          .year(year)
          .month(i - 1)
          .format('MMMM [de] YYYY')
      });
    }
    return source;
  }

  private _onMultiDatasTableSelect(data: ICGTableOnSelect<IProcSeguradoraFolhaFeriasDataItem>): Promise<void> {
    for (const item of this._multiDatasTableSource) {
      item.selected = false;
    }
    data.item.selected = !data.item.selected;
    this.multiDatasTableCallback.rePaintBody();
    if (!data.item.selected) {
      this._procExistentesTableSource = this._procExistentesTableSource.filter((item: ISegSocialProcessExistentes) => item.ssMes !== data.item.mes);
      if (this._dataGridProcExistentesInstance) {
        return this._dataGridProcExistentesInstance.refresh();
      }
      return Promise.resolve();
    }
    return this._loadProceExistentes();
  }

  private _loadProceExistentes(): Promise<void> {
    const params: IJsonProcSegSocialProcessExistentesParams = {
      empresas: this.empresas.map<IJsonProcSeguradoraEmpresas>((empresa: IJsonErpUser) => {
        return {
          nEmpresa: empresa.nEmpresa,
          nomeEmpresa: empresa.nomeEmpresa
        };
      }),
      datas: this._multiDatasTableSource.filter((item: IProcSeguradoraFolhaFeriasDataItem) => item.selected)
    };

    if (!params.datas.length) {
      this._plAlertService.error('procseguradorafolhaferias.messages.temSeleccionarDatas');
      return undefined;
    }
    if (this._dataGridProcExistentesInstance) {
      this._dataGridProcExistentesInstance.beginCustomLoading(undefined);
    }
    return this._procSeguradoraFolhaFeriasService
      .postProcessExistence(params)
      .then((response: Array<IJsonSegSocialProcessExistentes>) => {
        this._procExistentesTableSource = response;
        let index = 0;
        for (const item of this._procExistentesTableSource) {
          item._index = index;
          index++;
        }
        const isSelected = this._procExistentesTableSource.find((value: ISegSocialProcessExistentes) => value.selected === false);
        this.selectedAll = isSelected ? isSelected.selected : true;
        return this._dataGridProcExistentesInstance.refresh().then(() => {
          return this._dataGridProcExistentesInstance.selectAll();
        });
      })
      .finally(() => {
        if (this._dataGridProcExistentesInstance) {
          this._dataGridProcExistentesInstance.endCustomLoading();
        }
      });
  }

  private _fnProcExistentesTableSource(): Array<ISegSocialProcessExistentes> {
    return this._procExistentesTableSource;
  }

  private _printMap(): void {
    const modalInstance = this._cgModalService.showVanilla(ProcSeguradoraFolhaFeriasPrintModalComponent);
    const componentInstance: ProcSeguradoraFolhaFeriasPrintModalComponent = modalInstance.componentInstance;
    componentInstance.procSeguradoraFolhaFerias = this.procSeguradoraFolhaFeriasOuErros.procsegff;
  }

  private _genFile(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._cgModalService
        .showOkCancel('global.text.confirmation', 'procseguradorafolhaferias.prompt.geraFich', {
          btnCancelText: 'global.btn.no',
          btnOkText: 'global.btn.yes'
        })
        .then(() => {
          return this._procSeguradoraFolhaFeriasService.postGerarFicheiroSeguradoraFolhaFerias(this.procSeguradoraFolhaFeriasOuErros.procsegff).then((response: HttpResponse<Blob>) => {
            const doc: Blob = response.body;
            if (!doc?.size) {
              this._plAlertService.success('proceSegSocial.semAlteracaoParaGerar');
            } else {
              downloadStream(response);
            }
            resolve();
          });
        })
        .catch(reject);
    });
  }
}
