import {Injector} from '@angular/core';
import {isArray, Logger} from 'pl-comps-angular';
import {CGInjector} from '../common/injectors/cginjector';
import {TInjectorResolvable} from '../common/injectors/common';
import {IEntityDefinition} from '../core/components/entity/entity.definition.interface';
import {EntityRegistryService} from '../core/components/entity/entity.registry.service';

import {ENTITY_CLIENTES, ENTITY_CLIFOS, ENTITY_FORNECEDORES, ENTITY_OUTROS_DEVEDORES_CREDORES} from '../core/entities/clifos/clifos.entity';
import {ENTITY_PRH_MENUS} from '../core/modules/colaboradores/refeicoes/menus/prhMenus.module';
import {ENTITY_CONFIGS_SITE} from '../core/modules/configs/site/configsSite.module';
import {ENTITY_CONTROLO_IVA} from '../core/entities/controloiva/controloIVA.entity';
import {
  ENTITY_DOCS_COMERCIAIS,
  ENTITY_DOCS_COMERCIAIS_COMPRAS,
  ENTITY_DOCS_COMERCIAIS_COMPRAS_AUTOFATURACAO,
  ENTITY_DOCS_COMERCIAIS_CONSULTASAFORNECEDORES,
  ENTITY_DOCS_COMERCIAIS_ENCOMENDASCLIENTES,
  ENTITY_DOCS_COMERCIAIS_ENCOMENDASFORNECEDORES,
  ENTITY_DOCS_COMERCIAIS_ENTRADASDIVERSAS,
  ENTITY_DOCS_COMERCIAIS_GUIAS,
  ENTITY_DOCS_COMERCIAIS_GUIAS_FORNECEDORES,
  ENTITY_DOCS_COMERCIAIS_PROPOSTASACLIENTES,
  ENTITY_DOCS_COMERCIAIS_SAIDASDIVERSAS,
  ENTITY_DOCS_COMERCIAIS_VENDAS
} from '../core/entities/docscomerciais/docsComerciais.entity';
import {ENTITY_ABDES, ENTITY_ABONOS, ENTITY_DESCONTOS} from '../core/entities/abdes/abdes.entity';
import {ENTITY_ANOS_FATURACAO} from '../core/entities/anosfaturacao/anosFaturacao.entity';
import {ENTITY_ARMAZENS} from '../core/entities/armazens/armazens.entity';
import {ENTITY_ARTIGO_CLASSES} from '../core/entities/artigoclasses/artigoClasses.entity';
import {ENTITY_ARTIGOS} from '../core/entities/artigos/artigos.entity';
import {ENTITY_ATIVOS} from '../core/entities/ativos/ativos.entity';
import {ENTITY_ATIVOS_AQUISICAO} from '../core/entities/ativosaquisicao/ativosAquisicao.entity';
import {ENTITY_ATIVOS_IMPORT} from '../core/entities/ativosimport/ativosImport.entity';
import {ENTITY_AT_SERIES_LOGS} from '../core/entities/atserieslogs/atSeriesLogs.entity';
import {ENTITY_AUDIT_LOGIN} from '../core/entities/auditlogin/auditLogin.entity';
import {ENTITY_BANCOS} from '../core/entities/bancos/bancos.entity';
import {ENTITY_CAE} from '../core/entities/cae/cae.entity';
import {ENTITY_CALENDARIOS} from '../core/entities/calendarios/calendarios.entity';
import {ENTITY_CATEGORIAS_ARTIGO} from '../core/entities/categoriaartigo/categoriaArtigo.entity';
import {ENTITY_CATEGORIAS} from '../core/entities/categorias/categorias.entity';
import {ENTITY_CCT} from '../core/entities/cct/cct.entity';
import {ENTITY_CCUSTOS} from '../core/entities/ccustos/cCustos.entity';
import {ENTITY_CGASI} from '../core/entities/cgasit/cgasit.entity';
import {ENTITY_CLASSES_CAIXA} from '../core/entities/classescaixa/classesCaixa.entity';
import {ENTITY_CLASSES_CONTRATOS_AVENCAS} from '../core/entities/classescontratos/avencas/classesContratosAvencas.entity';
import {ENTITY_CLASSES_CONTRATOS} from '../core/entities/classescontratos/classesContratos.entity';
import {ENTITY_CLIFOEXTRAVALUES} from '../core/entities/clifoextravalue/clifoExtraValue.entity';
import {ENTITY_CLIFOEXTRAVALUETIPOS} from '../core/entities/clifoextravaluetipo/clifoExtraValueTipo.entity';
import {ENTITY_CLTRB} from '../core/entities/cltrb/cltrb.entity';
import {ENTITY_CODIGOS_CONTABILISTICOS} from '../core/entities/codigoscontabilisticos/codigosContabilisticos.entity';
import {ENTITY_COD_POSTAIS} from '../core/entities/codpostais/codPostais.entity';
import {ENTITY_CONCELHOS} from '../core/entities/concelhos/concelhos.entity';
import {ENTITY_CONDICOES_COMERCIAIS} from '../core/entities/condicoescomerciais/condicoesComerciais.entity';
import {ENTITY_CONDICOES_PAGAMENTO} from '../core/entities/condicoespagamento/condicoesPagamento.entity';
import {ENTITY_CONFIGS_ERP} from '../core/entities/configserp/configsErp.entity';
import {ENTITY_AVENCAS} from '../core/entities/avencas/avencas.entity';
import {ENTITY_CONTRATOS} from '../core/entities/contratos/contratos.entity';
import {ENTITY_CONTRATOS_TIPO_AVENCAS} from '../core/entities/contratostipoavencas/contratosTipoAvencas.entity';
import {ENTITY_CONTRATOS_TIPOS} from '../core/entities/contratostipos/contratosTipos.entity';
import {ENTITY_CONTTRABBASELEGAL} from '../core/entities/conttrabbaselegal/contTrabBaseLegal.entity';
import {ENTITY_CONTTRABCAB} from '../core/entities/conttrabcab/contTrabCab.entity';
import {ENTITY_CONTTRABMOTIVO} from '../core/entities/conttrabmotivo/contTrabMotivo.entity';
import {ENTITY_CRABD, ENTITY_CRUZAMENTOS_ABONOS, ENTITY_CRUZAMENTOS_DESCONTOS} from '../core/entities/crabd/crabd.entity';
import {ENTITY_CRNEG} from '../core/entities/crneg/crneg.entity';
import {ENTITY_CRSS} from '../core/entities/crss/crss.entity';
import {ENTITY_DATA_VALOR} from '../core/entities/datavalor/dataValor.entity';
import {ENTITY_DEPTO} from '../core/entities/depto/depto.entity';
import {ENTITY_DESCRITIVOS} from '../core/entities/descritivos/descritivos.entity';
import {ENTITY_DGEMPS} from '../core/entities/dgemps/dgemps.entity';
import {ENTITY_DIARIOS} from '../core/entities/diarios/diarios.entity';
import {ENTITY_DISTRITOS} from '../core/entities/distritos/distritos.entity';
import {ENTITY_DIVISOES_TRABALHO} from '../core/entities/divisoestrabalho/divisoesTrabalho.entity';
import {ENTITY_DOC_FAS} from '../core/entities/docfas/docFas.entity';
import {ENTITY_DOCFAS_NUMS} from '../core/entities/docfasnums/docfasNums.entity';
import {ENTITY_DOCFAS_NUMS_COMUNICACAO_LOGIN} from '../core/entities/docfasnumscomunicacaologin/docFasNumsComunicacaoLogin.entity';
import {ENTITY_DOCS_PARTILHADOS} from '../core/entities/docspartilhados/docsPartilhados.entity';
import {ENTITY_EMPRESAS} from '../core/entities/empresas/empresas.entity';
import {ENTITY_EMPRESAS_ERP} from '../core/entities/empresas/empresasErp.entity';
import {ENTITY_ENTIDADE_EXTERNA} from '../core/entities/entidadeexterna/entidadeExterna.entity';
import {ENTITY_ERPS} from '../core/entities/erps/erps.entity';
import {ENTITY_ERP_USERS} from '../core/entities/erpusers/erpUsers.entity';
import {ENTITY_ERP_UTILIZADORES} from '../core/entities/erputilizadores/erpUtilizadores.entity';
import {ENTITY_ESTABELECIMENTO} from '../core/entities/estabelecimentos/estabelecimentos.entity';
import {ENTITY_ESTADOS} from '../core/entities/estados/estados.entity';
import {ENTITY_FAMILIAS} from '../core/entities/familias/familias.entity';
import {ENTITY_FREGUESIAS} from '../core/entities/freguesias/freguesias.entity';
import {ENTITY_FREMUN} from '../core/entities/fremun/fRemun.entity';
import {ENTITY_FUNCOES} from '../core/entities/funcoes/funcoes.entity';
import {ENTITY_GDOC} from '../core/entities/gdoc/gdoc.entity';
import {ENTITY_GR_ABONOS} from '../core/entities/grabonos/grAbonos.entity';
import {ENTITY_GRANDES_FAMILIAS} from '../core/entities/grandesfamilias/grandesFamilias.entity';
import {ENTITY_GR_DESCONTOS} from '../core/entities/grdescontos/grDescontos.entity';
import {ENTITY_ID_BANCOS} from '../core/entities/idbancos/idBancos.entity';
import {ENTITY_IDIDE} from '../core/entities/idide/idide.entity';
import {ENTITY_INDICE_FLUXOS} from '../core/entities/indicefluxos/indiceFluxos.entity';
import {ENTITY_INVEC} from '../core/entities/inven/invec.entity';
import {ENTITY_IRSDT} from '../core/entities/irsdt/irsDt.entity';
import {ENTITY_IVA_MOTIVOS_TAXA} from '../core/entities/ivamotivostaxa/ivaMotivosTaxa.entity';
import {ENTITY_IVAS} from '../core/entities/ivas/ivas.entity';
import {ENTITY_LOCAL} from '../core/entities/local/local.entity';
import {ENTITY_MAPAS_AMORTIZACAO} from '../core/entities/mapasAmortizacao/mapasAmortizacao.entity';
import {ENTITY_MEIOS_PAGAMENTO} from '../core/entities/meiospagamento/meiosPagamento.entity';
import {ENTITY_MORADAS_FATURACAO} from '../core/entities/moradasfaturacao/moradasFaturacao.entity';
import {ENTITY_NACIONALIDADES} from '../core/entities/nacionalidades/nacionalidades.entity';
import {ENTITY_NATUREZASCONTAB} from '../core/entities/naturezascontab/naturezasContab.entity';
import {ENTITY_NIF} from '../core/entities/nifs/nifs.entity';
import {ENTITY_NIVQL} from '../core/entities/nivql/nivql.entity';
import {ENTITY_PAISES} from '../core/entities/paises/paises.entity';
import {ENTITY_PERIODOS} from '../core/entities/periodos/periodos.entity';
import {ENTITY_POCS} from '../core/entities/pocs/pocs.entity';
import {ENTITY_PRH_COMUNICACOES_INTERNAS} from '../core/entities/prhcomunicacoesinternas/pRHComunicacoesInternas.entity';
import {ENTITY_PRH_DOCS_PLATA} from '../core/entities/prhdocsplata/pRHDocsPlata.entity';
import {ENTITY_PRH_ENTALTERS} from '../core/entities/prhentalters/pRHEntAlers.entity';
import {ENTITY_PRH_EVENTOS_CONFIG} from '../core/entities/prheventosconfig/pRHEventosConfig.entity';
import {ENTITY_PRH_FLUXOS} from '../core/entities/prhfluxos/pRHFluxos.entity';
import {ENTITY_PRH_GRUPO_REFEICOES} from '../core/entities/prhgruporefeicoes/pRHGrupoRefeicoes.entity';
import {ENTITY_PRH_LOCAIS} from '../core/entities/prhlocais/pRHLocais.entity';
import {ENTITY_PRH_LOGS} from '../core/entities/prhlogs/pRHLogs.entity';
import {ENTITY_PRH_PAPEIS} from '../core/entities/prhpapeis/pRHPapeis.entity';
import {ENTITY_PRH_REF_APROVA} from '../core/entities/prhrefaprova/pRHRefAprova.entity';
import {ENTITY_PRH_REFEICOES} from '../core/entities/prhrefeicoes/pRHRefeicoes.entity';
import {ENTITY_PRH_REL_EMENTAS} from '../core/entities/prhrelementas/pRHRelEmentas.entity';
import {ENTITY_PRH_SERVICO_EMPREGADOS} from '../core/entities/prhservicoempregados/pRHServicoEmpregados.entity';
import {ENTITY_PRH_SERVICOS} from '../core/entities/prhservicos/pRHServicos.entity';
import {ENTITY_PRH_TAREFAS_CAB} from '../core/entities/prhtarefascab/pRHTarefasCab.entity';
import {ENTITY_PRH_TIPO_REFEICOES} from '../core/entities/prhtiporefeicoes/pRHTipoRefeicoes.entity';
import {ENTITY_PROCESSOS} from '../core/entities/processos/processos.entity';
import {ENTITY_PROF} from '../core/entities/prof/prof.entity';
import {ENTITY_QIVAAS} from '../core/entities/qivaas/qivaas.entity';
import {ENTITY_QIVAPS} from '../core/entities/qivaps/qivaps.entity';
import {ENTITY_QPCAE} from '../core/entities/qpcae/qpcae.entity';
import {ENTITY_QPCNP} from '../core/entities/qpcnp/qpcnp.entity';
import {ENTITY_QPDCF, ENTITY_QPDCFSERVICOSFINANCAS} from '../core/entities/qpdcf/qpdcf.entity';
import {ENTITY_QPDUR} from '../core/entities/qpdur/qpdur.entity';
import {ENTITY_QPHAB} from '../core/entities/qphab/qphab.entity';
import {ENTITY_QPIRT} from '../core/entities/qpirt/qpirt.entity';
import {ENTITY_QPMTE} from '../core/entities/qpmte/qpmte.entity';
import {ENTITY_QPMTS} from '../core/entities/qpmts/qpmts.entity';
import {ENTITY_QPPAISES} from '../core/entities/qppais/qpPaises.entity';
import {ENTITY_QPSIT} from '../core/entities/qpsit/qpsit.entity';
import {ENTITY_QPTCO} from '../core/entities/qptco/qptco.entity';
import {ENTITY_RENDI} from '../core/entities/rendis/rendis.entity';
import {ENTITY_REPCC, ENTITY_REPCC_FATURACAO, ENTITY_REPCC_SALARIOS} from '../core/entities/reparticoesccusto/reparticoesCCusto.entity';
import {ENTITY_REP_FINANCAS} from '../core/entities/repfinancas/repFinancas.entity';
import {ENTITY_RHPENHORAS} from '../core/entities/rhpenhoras/rhPenhoras.entity';
import {ENTITY_REMFX, ENTITY_REMUN} from '../core/entities/rhrecolhadados/rhRecolhaDados.entity';
import {ENTITY_RH_TIPOS_PROCESSAMENTO} from '../core/entities/rhTiposProcessamento/rhTiposProcessamento.entity';
import {ENTITY_RHUNIDADES} from '../core/entities/rhunidades/rhUnidades.entity';
import {ENTITY_ROLES} from '../core/entities/roles/roles.entity';
import {ENTITY_SECCOES} from '../core/entities/seccoes/seccoes.entity';
import {ENTITY_SEGUR} from '../core/entities/segur/segur.entity';
import {ENTITY_SIND} from '../core/entities/sind/sind.entity';
import {ENTITY_SITPR} from '../core/entities/sitpr/sitPr.entity';
import {ENTITY_SITUACAO_EMPREGADO} from '../core/entities/situacaoempregado/situacaoEmpregado.entity';
import {ENTITY_SOLIC} from '../core/entities/solic/solic.entity';
import {ENTITY_SSCOL} from '../core/entities/sscol/ssCol.entity';
import {ENTITY_WS_SS_LOGIN} from '../core/entities/sslogin/wsSSLogin.entity';
import {ENTITY_SUBDE} from '../core/entities/subde/subDe.entity';
import {ENTITY_SUB_FAMILIAS} from '../core/entities/subfamilias/subFamilias.entity';
import {ENTITY_TAXAS_IVA} from '../core/entities/taxasIva/taxasIva.entity';
import {ENTITY_TAXAS_RETENCAO} from '../core/entities/taxasretencao/taxasRetencao.entity';
import {ENTITY_TAXONOMIAS} from '../core/entities/taxonomias/taxonomias.entity';
import {ENTITY_TES_ESTADOS} from '../core/entities/tesestados/tesEstados.entity';
import {ENTITY_TES_RUBRICA} from '../core/entities/tesrubrica/tesRubrica.entity';
import {ENTITY_TIPO_HORARIO} from '../core/entities/tipohorario/tipoHorario.entity';
import {ENTITY_TIPOS_ARTIGO} from '../core/entities/tiposartigo/tiposArtigo.entity';
import {ENTITY_TIPOS_CONTRATO} from '../core/entities/tiposcontrato/tiposContrato.entity';
import {ENTITY_TIPOS_REGISTO_CADASTRO} from '../core/entities/tiposregistocadastro/tiposRegistoCadastro.entity';
import {ENTITY_TIRS} from '../core/entities/tirs/tirs.entity';
import {ENTITY_TOKENS} from '../core/entities/tokens/tokens.entity';
import {ENTITY_TRDOC_CONFIG} from '../core/entities/trdocconfig/trDocConfig.entity';
import {ENTITY_UNIDADES_MOVIMENTO} from '../core/entities/unidadesmovimento/unidadesMovimento.entity';
import {ENTITY_VENDEDORES} from '../core/entities/vendedores/vendedores.entity';
import {ENTITY_ZONAS} from '../core/entities/zonas/zonas.entity';
import {ENTITY_FIM_PERIODO_ENC} from '../core/entities/fimperiodoenc/fimPeriodoEnc.entity';
import {ENTITY_MASCARAS_ANALITICA} from '../core/entities/mascarasanalitica/mascarasAnalitica.entity';
import {ENTITY_PAGAMENTOS} from '../core/entities/pagamentos/pagamentos.entity';
import {ENTITY_PERFIL_CATEGORIA_ABONO} from '../core/modules/perfilcategoriaabdes/perfisabonos/perfilCategoriaAbono.entity';
import {ENTITY_PERFIL_CATEGORIA_DESCONTO} from '../core/modules/perfilcategoriaabdes/perfisdescontos/perfilCategoriaDesconto.entity';
import {ENTITY_PORTAL} from '../core/entities/portal/portal.entity';
import {ENTITY_AMORTIZACAO} from '../core/entities/amortizacoes/imtab.entity';
import {ENTITY_DOCS_CONTABILIDADE} from '../core/modules/portalcontabilidade/docscontabilidade/docsContabilidade.entity';
import {ENTITY_MOEDA} from '../core/modules/portalcontabilidade/manutencao/moeda/moeda.entity';
import {ENTITY_CONTABILIDADE_PREDEFINIDOS} from '../core/modules/portalcontabilidade/manutencao/predefinidos/contabilidade.predefinidos.module';
import {ENTITY_REEMBOLSOS_IVA} from '../core/entities/reembolsosiva/reembolsosIva.entity';
import {ENTITY_DGEMPS_FULL} from '../core/modules/portalrh/dgempsfull/dgempsFull.entity';
import {ENTITY_RECIBOS} from '../core/entities/recibos/recibos.entity';
import {ENTITY_TEMPLATES} from '../core/entities/templates/templates.entity';
import {ENTITY_TRANSFERENCIAS_ARMAZENS} from '../core/entities/transferenciasarmazens/transferenciasArmazens.entity';
import {ENTITY_UTILIZADORES_BASICO} from '../core/modules/utilizadores/basic/utilizadores.basic.entity';
import {ENTITY_UTILIZADORES} from '../core/modules/utilizadores/utilizadores';
import {ENTITY_LOTES} from '../core/entities/lotes/lotes.entity';
import {ENTITY_CLICLS} from '../core/entities/clicls/clicls.entity';
import {ENTITY_ARCLI} from '../core/entities/arclis/arClis.entity';
import {ENTITY_MORAL} from '../core/entities/morals/morals.entity';
import {ENTITY_ARTAR} from '../core/entities/artars/artars.entity';
import {ENTITY_PLANOS_CONTAS_ALTERNATIVOS} from '../core/entities/planoscontasalternativos/planosContasAlternativos.entity';
import {ENTITY_UNECE} from '../core/entities/unidadesmedidacomerciointernacional/unece.entity';
import {ENTITY_QPISP} from '../core/entities/qpisp/qpisp.entity';
import {ENTITY_CARGVEIC} from '../core/entities/cargveic/cargVeic.entity';
import {ENTITY_QPCATEGORIAS} from '../core/entities/qpcategorias/qpcategorias.entity';
import {ENTITY_QPAPA} from '../core/entities/qpapa/qpapa.entity';
import {ENTITY_QPNJ} from '../core/entities/qpnj/qpnj.entity';
import {ENTITY_SECTORES} from '../core/entities/sectores/sectores.entity';
import {ENTITY_TXTCL} from '../core/entities/txtcl/txtcl.entity';
import {ENTITY_TXTAR} from '../core/entities/txtar/txtar.entity';
import {ENTITY_PRHTIPOCOM} from '../core/entities/prhtipocom/prhtipocom.entity';
import {ENTITY_COM_CATEGORIA} from '../core/entities/comcategoria/comCategoria.entity';

export const ENTITIES: Array<IEntityDefinition | TInjectorResolvable> = [
  ENTITY_ABDES,
  ENTITY_ABONOS,
  ENTITY_ARCLI,
  ENTITY_ARTAR,
  ENTITY_AMORTIZACAO,
  ENTITY_ANOS_FATURACAO,
  ENTITY_ARMAZENS,
  ENTITY_ARTIGO_CLASSES,
  ENTITY_ARTIGOS,
  ENTITY_AT_SERIES_LOGS,
  ENTITY_ATIVOS,
  ENTITY_ATIVOS_AQUISICAO,
  ENTITY_ATIVOS_IMPORT,
  ENTITY_AUDIT_LOGIN,
  ENTITY_AVENCAS,
  ENTITY_BANCOS,
  ENTITY_CAE,
  ENTITY_CALENDARIOS,
  ENTITY_CATEGORIAS,
  ENTITY_CATEGORIAS_ARTIGO,
  ENTITY_CARGVEIC,
  ENTITY_CCT,
  ENTITY_CCUSTOS,
  ENTITY_CGASI,
  ENTITY_CLASSES_CAIXA,
  ENTITY_CLASSES_CONTRATOS,
  ENTITY_CLASSES_CONTRATOS_AVENCAS,
  ENTITY_CLICLS,
  ENTITY_CLIENTES,
  ENTITY_CLIFOEXTRAVALUES,
  ENTITY_CLIFOEXTRAVALUETIPOS,
  ENTITY_CLIFOS,
  ENTITY_CLTRB,
  ENTITY_COD_POSTAIS,
  ENTITY_CODIGOS_CONTABILISTICOS,
  ENTITY_COM_CATEGORIA,
  ENTITY_CONCELHOS,
  ENTITY_CONDICOES_COMERCIAIS,
  ENTITY_CONDICOES_PAGAMENTO,
  ENTITY_CONFIGS_ERP,
  ENTITY_CONFIGS_SITE,
  ENTITY_CONTABILIDADE_PREDEFINIDOS,
  ENTITY_CONTRATOS,
  ENTITY_CONTRATOS_TIPO_AVENCAS,
  ENTITY_CONTRATOS_TIPOS,
  ENTITY_CONTROLO_IVA,
  ENTITY_CONTTRABBASELEGAL,
  ENTITY_CONTTRABCAB,
  ENTITY_CONTTRABMOTIVO,
  ENTITY_CRABD,
  ENTITY_CRNEG,
  ENTITY_CRSS,
  ENTITY_CRUZAMENTOS_ABONOS,
  ENTITY_CRUZAMENTOS_DESCONTOS,
  ENTITY_DATA_VALOR,
  ENTITY_DEPTO,
  ENTITY_DESCONTOS,
  ENTITY_DESCRITIVOS,
  ENTITY_DGEMPS,
  ENTITY_DGEMPS_FULL,
  ENTITY_DIARIOS,
  ENTITY_DISTRITOS,
  ENTITY_DIVISOES_TRABALHO,
  ENTITY_DOC_FAS,
  ENTITY_DOCFAS_NUMS,
  ENTITY_DOCFAS_NUMS_COMUNICACAO_LOGIN,
  ENTITY_DOCS_COMERCIAIS,
  ENTITY_DOCS_COMERCIAIS_COMPRAS,
  ENTITY_DOCS_COMERCIAIS_COMPRAS_AUTOFATURACAO,
  ENTITY_DOCS_COMERCIAIS_CONSULTASAFORNECEDORES,
  ENTITY_DOCS_COMERCIAIS_ENCOMENDASCLIENTES,
  ENTITY_DOCS_COMERCIAIS_ENCOMENDASFORNECEDORES,
  ENTITY_DOCS_COMERCIAIS_ENTRADASDIVERSAS,
  ENTITY_DOCS_COMERCIAIS_GUIAS,
  ENTITY_DOCS_COMERCIAIS_GUIAS_FORNECEDORES,
  ENTITY_DOCS_COMERCIAIS_PROPOSTASACLIENTES,
  ENTITY_DOCS_COMERCIAIS_SAIDASDIVERSAS,
  ENTITY_DOCS_COMERCIAIS_VENDAS,
  ENTITY_DOCS_CONTABILIDADE,
  ENTITY_DOCS_PARTILHADOS,
  ENTITY_EMPRESAS,
  ENTITY_EMPRESAS_ERP,
  ENTITY_ENTIDADE_EXTERNA,
  ENTITY_ERP_USERS,
  ENTITY_ERP_UTILIZADORES,
  ENTITY_ERPS,
  ENTITY_ESTABELECIMENTO,
  ENTITY_ESTADOS,
  ENTITY_FAMILIAS,
  ENTITY_FIM_PERIODO_ENC,
  ENTITY_FORNECEDORES,
  ENTITY_FREGUESIAS,
  ENTITY_FREMUN,
  ENTITY_FUNCOES,
  ENTITY_GDOC,
  ENTITY_GR_ABONOS,
  ENTITY_GR_DESCONTOS,
  ENTITY_GRANDES_FAMILIAS,
  ENTITY_ID_BANCOS,
  ENTITY_IDIDE,
  ENTITY_INDICE_FLUXOS,
  ENTITY_INVEC,
  ENTITY_IRSDT,
  ENTITY_IVA_MOTIVOS_TAXA,
  ENTITY_IVAS,
  ENTITY_LOTES,
  ENTITY_LOCAL,
  ENTITY_MAPAS_AMORTIZACAO,
  ENTITY_MASCARAS_ANALITICA,
  ENTITY_MEIOS_PAGAMENTO,
  ENTITY_MOEDA,
  ENTITY_MORADAS_FATURACAO,
  ENTITY_MORAL,
  ENTITY_NACIONALIDADES,
  ENTITY_NATUREZASCONTAB,
  ENTITY_NIF,
  ENTITY_NIVQL,
  ENTITY_OUTROS_DEVEDORES_CREDORES,
  ENTITY_PAGAMENTOS,
  ENTITY_PAISES,
  ENTITY_PERFIL_CATEGORIA_ABONO,
  ENTITY_PERFIL_CATEGORIA_DESCONTO,
  ENTITY_PERIODOS,
  ENTITY_PLANOS_CONTAS_ALTERNATIVOS,
  ENTITY_POCS,
  ENTITY_PORTAL,
  ENTITY_PRH_COMUNICACOES_INTERNAS,
  ENTITY_PRH_DOCS_PLATA,
  ENTITY_PRH_ENTALTERS,
  ENTITY_PRH_EVENTOS_CONFIG,
  ENTITY_PRH_FLUXOS,
  ENTITY_PRH_GRUPO_REFEICOES,
  ENTITY_PRH_LOCAIS,
  ENTITY_PRH_LOGS,
  ENTITY_PRH_MENUS,
  ENTITY_PRH_PAPEIS,
  ENTITY_PRH_REF_APROVA,
  ENTITY_PRH_REFEICOES,
  ENTITY_PRH_REL_EMENTAS,
  ENTITY_PRH_SERVICO_EMPREGADOS,
  ENTITY_PRH_SERVICOS,
  ENTITY_PRH_TAREFAS_CAB,
  ENTITY_PRH_TIPO_REFEICOES,
  ENTITY_PROCESSOS,
  ENTITY_PROF,
  ENTITY_QIVAAS,
  ENTITY_QIVAPS,
  ENTITY_QPAPA,
  ENTITY_QPCAE,
  ENTITY_QPCATEGORIAS,
  ENTITY_QPDCF,
  ENTITY_QPDCFSERVICOSFINANCAS,
  ENTITY_QPCNP,
  ENTITY_QPDUR,
  ENTITY_QPHAB,
  ENTITY_QPISP,
  ENTITY_QPIRT,
  ENTITY_QPMTE,
  ENTITY_QPMTS,
  ENTITY_QPNJ,
  ENTITY_QPPAISES,
  ENTITY_QPSIT,
  ENTITY_QPTCO,
  ENTITY_RECIBOS,
  ENTITY_REEMBOLSOS_IVA,
  ENTITY_REMFX,
  ENTITY_REMUN,
  ENTITY_RENDI,
  ENTITY_REP_FINANCAS,
  ENTITY_REPCC,
  ENTITY_REPCC_FATURACAO,
  ENTITY_REPCC_SALARIOS,
  ENTITY_RH_TIPOS_PROCESSAMENTO,
  ENTITY_RHPENHORAS,
  ENTITY_RHUNIDADES,
  ENTITY_ROLES,
  ENTITY_SECCOES,
  ENTITY_SECTORES,
  ENTITY_SEGUR,
  ENTITY_SIND,
  ENTITY_SITPR,
  ENTITY_SITUACAO_EMPREGADO,
  ENTITY_SOLIC,
  ENTITY_SSCOL,
  ENTITY_SUB_FAMILIAS,
  ENTITY_SUBDE,
  ENTITY_TAXAS_IVA,
  ENTITY_TAXAS_RETENCAO,
  ENTITY_TAXONOMIAS,
  ENTITY_TEMPLATES,
  ENTITY_TES_ESTADOS,
  ENTITY_TES_RUBRICA,
  ENTITY_TIPO_HORARIO,
  ENTITY_TIPOS_ARTIGO,
  ENTITY_TIPOS_CONTRATO,
  ENTITY_TIPOS_REGISTO_CADASTRO,
  ENTITY_TIRS,
  ENTITY_TOKENS,
  ENTITY_TRANSFERENCIAS_ARMAZENS,
  ENTITY_TRDOC_CONFIG,
  ENTITY_TXTAR,
  ENTITY_TXTCL,
  ENTITY_UNECE,
  ENTITY_UNIDADES_MOVIMENTO,
  ENTITY_UTILIZADORES,
  ENTITY_UTILIZADORES_BASICO,
  ENTITY_VENDEDORES,
  ENTITY_WS_SS_LOGIN,
  ENTITY_ZONAS,
  ENTITY_PRHTIPOCOM
];

export function configEntities(injector: Injector): void {
  const logger: Logger = injector.get<Logger>(Logger);
  const entityRegistryService: EntityRegistryService = injector.get<EntityRegistryService>(EntityRegistryService);
  for (const entity of ENTITIES) {
    try {
      if (isArray(entity)) {
        entityRegistryService.register(CGInjector.invoke(<TInjectorResolvable>entity));
      } else {
        entityRegistryService.register(<IEntityDefinition>entity);
      }
    } catch (exception: unknown) {
      logger.error(`Error while trying to register [${(<IEntityDefinition>entity).name}] entity`, entity, exception);
    }
  }
}
