<div class="module-conciliacao-e-faturas">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="search" plPromise>
        <pl-group>
          <pl-group [properties]="{validators: {required: {value: true}}}">
            <label [translate]="'global.text.fromDate'"></label>
            <edit>
              <pl-edit-datepicker [model]="filters.fromDate" (modelChange)="fromDateChanged($event)" plAutoFocus></pl-edit-datepicker>
            </edit>
          </pl-group>
          <pl-group [properties]="{validators: {required: {value: true}}}">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit-datepicker [(model)]="filters.toDate"></pl-edit-datepicker>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'conciliacaoEFaturas.formFields.tipo'"></label>
            <edit>
              <pl-autocomplete [(model)]="filters.tipo" [source]="tiposFatura" rowTemplate="tipo" output="tipo" [allowInvalid]="false" [allowEmpty]="false"></pl-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'conciliacaoEFaturas.formFields.fornecedor'"></label>
            <edit>
              <entity-autocomplete
                [attrName]="'acFornecedor'"
                [entity]="entityFornecedoresName"
                [model]="filters"
                (modelChange)="changeFornecedor($event)"
                [fieldsMap]="{nContribuinte: 'filters.nifFornecedor'}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group class="align-self-end">
          <edit>
            <pl-button type="submit" klass="btn btn-sm btn-primary"><i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <pl-tabs [(activeId)]="activeTabId" class="mt-3" (activeIdChange)="changedTab()">
    <pl-tab [id]="efaturaTabId" caption="conciliacaoEFaturas.tabDocsEFaturaCaption">
      <div *plTabContent>
        <div class="row">
          <div class="col-md-6">
            <div class="showOnlyWithoutPossibleDocs-switcher-container">
              <pl-edit type="boolean" [model]="showOnlyWithoutPossibleDocs" (modelChange)="switchChange($event)"></pl-edit>
              <label [translate]="'conciliacaoEFaturas.tabDocsEFaturaCaptionContainer.mostradocpossiveis'"></label>
            </div>
          </div>
          <div class="col-md-6 text-right">
            <ng-container *ngIf="isAssociarTodosPossiveisVisible">
              <pl-tooltip [config]="{text: 'conciliacaoEFaturas.tabDocsEFaturaCaptionContainer.tooltip.associarAutTodosPossiveis', placement: 'left', container: 'body'}">
                <button type="button" *plTooltipContent class="btn btn-success btn-xs" (click)="associarTodosPossiveis()">
                  <i class="fa fa-link" aria-hidden="true"></i>&nbsp;<span [translate]="'conciliacaoEFaturas.associarTodosPossiveis'"></span>
                </button>
              </pl-tooltip>
            </ng-container>
          </div>
        </div>

        <dx-data-grid
          [cgDxDataGrid]="definitionsDataGrid.mainDef"
          cgDxDataGridInstanceName="conciliacaoEFaturasTabDocsEFaturaCaption"
          [dataSource]="tableSource"
          (onInitialized)="onDataGridInitialized($event)"
          (onCellPrepared)="onCellPrepared($event)"
          (onCellClick)="onDataGridCellClick($event)"
          (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)">
          <div *dxTemplate="let item of 'numPossiveisDocsTmpl'">
            <span class="badge" [ngClass]="{'bg-success': item.data.numPossiveisDocs > 0}">{{ item.data.numPossiveisDocs }}</span>
          </div>

          <div *dxTemplate="let item of 'numSemelhantesDocsTmpl'">
            <span class="badge" [ngClass]="{'bg-success': item.data.numSemelhantesDocs > 0}">{{ item.data.numSemelhantesDocs }}</span>
          </div>

          <div *dxTemplate="let item of 'docDigitalTmpl'">
            <pl-tooltip [config]="{text: 'conciliacaoEFaturas.messages.docDigital1'}" *ngIf="item.data.docDigital === 1">
              <i *plTooltipContent class="fa fa-fw fa-file-text-o text-success"></i>
            </pl-tooltip>
            <pl-tooltip [config]="{text: 'conciliacaoEFaturas.messages.docDigital2'}" *ngIf="item.data.docDigital === 2">
              <i *plTooltipContent class="fa fa-fw fa-file-text-o text-warning"></i>
            </pl-tooltip>
          </div>

          <div *dxTemplate="let item of 'detail'">
            <div class="row conciliacao-e-faturas-details">
              <div class="col-md-6">
                <div class="conciliacao-e-faturas-group">
                  <h5>Detalhes</h5>
                  <dx-data-grid [cgDxDataGrid]="definitionsDataGrid.detalhes" (onInitialized)="onDetalhesDataGridInitialized($event, item.data)" [dataSource]="item.data.detalhesSource">
                  </dx-data-grid>
                </div>
              </div>
              <div class="col-md-6">
                <div class="conciliacao-e-faturas-group">
                  <h5>Possíveis documentos na contabilidade</h5>
                  <button type="button" (click)="openPesquisaDocPossivelModal(item.data)" style="margin: 17px 10px" class="btn btn-success btn-sm" *ngIf="item.data.possiveisDocs.length === 0">
                    <i class="fa fa-search"></i> Pesquisar Documento Possível
                  </button>

                  <dx-data-grid
                    *ngIf="item.data.possiveisDocs.length > 0"
                    [cgDxDataGrid]="definitionsDataGrid.possiveisDocs"
                    cgDxDataGridInstanceName="PossiveisDocsContab"
                    [dataSource]="item.data.possiveisDocs">
                    <div *dxTemplate="let posItem of 'posLinkAssociar'">
                      <pl-tooltip [config]="{text: 'conciliacaoEFaturas.associarDocSemAlterarDocExterno', container: 'body'}">
                        <button *plTooltipContent type="button" class="btn btn-xs btn-success" (click)="associarDocSemAlterarDocExterno(posItem.data)">Associar</button>
                      </pl-tooltip>
                    </div>

                    <div *dxTemplate="let posItem of 'posActions'">
                      <pl-tooltip [config]="{text: 'conciliacaoEFaturas.associarDocComAlteracaoDocExterno', container: 'body'}">
                        <button type="button" *plTooltipContent class="btn btn-xs btn-primary" (click)="associarDoc(posItem.data)"><i class="fa fa-link"></i></button>
                      </pl-tooltip>
                      &nbsp;
                      <pl-tooltip [config]="{text: 'conciliacaoEFaturas.verDocumento', container: 'body'}">
                        <button type="button" *plTooltipContent class="btn btn-xs btn-warning" (click)="viewDoc(posItem.data)"><i class="fa fa-eye"></i></button>
                      </pl-tooltip>
                    </div>
                  </dx-data-grid>
                </div>

                <div class="conciliacao-e-faturas-group" *ngIf="item.data.semelhantesDocs.length > 0">
                  <h5>Documentos na contabilidade com algumas semelhanças</h5>
                  <dx-data-grid [cgDxDataGrid]="definitionsDataGrid.semelhantesDocs" cgDxDataGridInstanceName="semelhantesDocs" [dataSource]="item.data.semelhantesDocs">
                    <div *dxTemplate="let semDocsItem of 'semDocsLinkAssociar'">
                      <pl-tooltip [config]="{text: 'conciliacaoEFaturas.associarDocSemAlterarDocExterno', container: 'body'}">
                        <button *plTooltipContent type="button" class="btn btn-xs btn-success" (click)="associarDocSemAlterarDocExterno(semDocsItem.data)">Associar</button>
                      </pl-tooltip>
                    </div>

                    <div *dxTemplate="let semDocsItem of 'semDocsActions'">
                      <pl-tooltip [config]="{text: 'conciliacaoEFaturas.associarDocComAlteracaoDocExterno', container: 'body'}">
                        <button type="button" *plTooltipContent class="btn btn-xs btn-primary" (click)="associarDoc(semDocsItem.data)"><i class="fa fa-link"></i></button>
                      </pl-tooltip>
                      &nbsp;
                      <pl-tooltip [config]="{text: 'conciliacaoEFaturas.verDocumento', container: 'body'}">
                        <button type="button" *plTooltipContent class="btn btn-xs btn-warning" (click)="viewDoc(semDocsItem.data)"><i class="fa fa-eye"></i></button>
                      </pl-tooltip>
                    </div>
                  </dx-data-grid>
                </div>
              </div>
            </div>
          </div>

          <div *dxTemplate="let item of 'actions'">
            <div ngbDropdown container="body" class="col-main-table-actions">
              <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-bars"></i></button>
              <div ngbDropdownMenu>
                <button type="button" ngbDropdownItem (click)="ignoreDoc(item.data)">
                  <i class="fa fa-ban me-1" aria-hidden="true"></i><span [translate]="'conciliacaoEFaturas.btn.dropdown.ignorarDocumento'"></span>
                </button>
                <button type="button" ngbDropdownItem (click)="ignoreFornecedor(item.data)">
                  <i class="fa fa-ban me-1" aria-hidden="true"></i><span [translate]="'conciliacaoEFaturas.btn.dropdown.ignorarSempreTodosDocumentos'"></span>
                </button>
                <button type="button" ngbDropdownItem (click)="extratoConta(item.data)">
                  <i class="fa fa-file-text-o me-1" aria-hidden="true"></i><span [translate]="'conciliacaoEFaturas.btn.dropdown.extratosConta'"></span>
                </button>
                <button type="button" ngbDropdownItem (click)="openConfigPreDefinidosSupplierModal(item.data)">
                  <i class="fa fa-cog me-1" aria-hidden="true"></i><span [translate]="'conciliacaoEFaturas.btn.dropdown.configSupplier'"></span>
                </button>
              </div>
            </div>
          </div>
        </dx-data-grid>

        <cg-table-legend [legend]="this.documentosSemRelacaoDataGridLegend"></cg-table-legend>
      </div>
    </pl-tab>

    <pl-tab [id]="jaLancadosTabId" caption="conciliacaoEFaturas.tabJaLancadosCaption">
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="definitionsDataGrid.tableLancIgnored"
          cgDxDataGridInstanceName="tableLancIgnored"
          [dataSource]="tableSourceLancIgnored"
          (onInitialized)="onJaLancDataGridInitialized($event)">
          <div *dxTemplate="let lancIgnoredItem of 'lancIgnoredLinkVerDoc'">
            <button type="button" class="btn btn-xs btn-secondary" [disabled]="!lancIgnoredItem.data.extPocCabID" (click)="viewDoc(lancIgnoredItem.data)">
              <i class="fa fa-eye"></i> <span [translate]="'conciliacaoEFaturas.verDocumento'"></span>
            </button>
          </div>

          <div *dxTemplate="let item of 'docDigitalTmpl'">
            <pl-tooltip [config]="{text: 'conciliacaoEFaturas.messages.docDigital1'}" *ngIf="item.data.docDigital === 1">
              <i *plTooltipContent class="fa fa-fw fa-file-text-o text-success"></i>
            </pl-tooltip>
            <pl-tooltip [config]="{text: 'conciliacaoEFaturas.messages.docDigital2'}" *ngIf="item.data.docDigital === 2">
              <i *plTooltipContent class="fa fa-fw fa-file-text-o text-warning"></i>
            </pl-tooltip>
          </div>

          <div *dxTemplate="let lancIgnoredItem of 'lancIgnoredActions'">
            <div>
              <ng-container *ngIf="isLigacaoDiretaNIFNDocExterno(lancIgnoredItem.data)">
                <pl-tooltip [config]="{text: 'conciliacaoEFaturas.tabJaLancadosCaptionContainer.table.badges.ligacaoDireta', container: 'body'}">
                  <span *plTooltipContent class="badge bg-success"><i class="fa fa-link"></i></span>
                </pl-tooltip>
              </ng-container>

              <pl-tooltip [config]="{text: 'conciliacaoEFaturas.deixarIgnorarPorFornecedor', placement: 'right', container: 'body'}">
                <button type="button" *plTooltipContent [hidden]="isIgnoradoPorFornecedor(lancIgnoredItem.data)" class="btn btn-xs btn-primary" (click)="deixarIgnorarFornecedor(lancIgnoredItem.data)">
                  <i class="fa fa-minus-circle" aria-hidden="true"></i>
                </button>
              </pl-tooltip>

              <pl-tooltip [config]="{text: 'conciliacaoEFaturas.removeAssociacao', placement: 'right', container: 'body'}">
                <button type="button" *plTooltipContent [hidden]="isAssociadoSemNDocExterno(lancIgnoredItem.data)" class="btn btn-xs btn-danger" (click)="removeAssociacao(lancIgnoredItem.data)">
                  <i class="fa fa-minus-circle" aria-hidden="true"></i>
                </button>
              </pl-tooltip>

              <pl-tooltip [config]="{text: 'conciliacaoEFaturas.deixarIgnorarDocumento', placement: 'right', container: 'body'}">
                <button type="button" *plTooltipContent [hidden]="isIgnorado(lancIgnoredItem.data)" class="btn btn-xs btn-warning" (click)="deixarIgnorarDoc(lancIgnoredItem.data)">
                  <i class="fa fa-minus-circle" aria-hidden="true"></i>
                </button>
              </pl-tooltip>
            </div>
          </div>
        </dx-data-grid>

        <cg-table-legend [legend]="this.documentosJaLancadosDataGridLegend"></cg-table-legend>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
