import {ContabilidadeApuraivaComponent} from './components/contabilidade.apuraIva.module.component';
import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ContabilidadeApuraIvaService} from '../../../../services/contabilidade.apuraiva.service';
import {IJsonApuraIvaDefaultFiltro} from './jsonApuraIva.module.interface';

export const MODULE_PCA_MANUTENCAO_APURAIVA: IModuleDefinition = {
  name: 'apuraiva',
  state: {
    url: '/apuraiva',
    component: ContabilidadeApuraivaComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.apuraiva'
    },
    resolve: [
      {
        provide: 'defaultFiltro',
        deps: [ContabilidadeApuraIvaService],
        useFactory: (contabilidadeApuraIvaService: ContabilidadeApuraIvaService): Promise<IJsonApuraIvaDefaultFiltro> => {
          return contabilidadeApuraIvaService.getApuramentoIvaDefaultFiltro();
        }
      }
    ]
  }
};
