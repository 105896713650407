import {default as dxDataGrid, DataChange} from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {isDefinedNotNull, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IDocComercialLinha} from '../../docsComerciais.entity.interface';
import {IEntityServiceMethodsOverride} from '../../../../components/entity/entity.definition.interface';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnEditingStart,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {TEntityListAfterRequestFn, TEntityListBeforeRequestFn} from '../../../../components/entity/list/entity.list.component.interface';
import {IEntityServiceQueryRequestConfig} from '../../../../services/entity/entity.service.interface';
import {TDevExpressDataGridSelectionMode} from '../../../../components/devexpress/datagrid/devexpress.datagrid.types.interface';
import {ENTITY_NAME_ARTIGOS, IArtigo, IArtigosEntityService} from '../../../artigos/artigos.entity.interface';
import {IJsonArtigosProcessParams, IJsonArtigoStockArmazens} from '../../../artigos/jsonArtigo.entity.interface';
import {IApiQueryResponse, THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';

const GROUP_INDEX_0 = 0;
const GROUP_INDEX_1 = 1;
const GROUP_INDEX_2 = 2;
const GROUP_INDEX_3 = 3;

@Component({
  selector: 'documento-artigos-advanced-search-modal',
  templateUrl: './documento.artigosAdvancedSearch.modal.component.html'
})
export class DocumentoArtigosAdvancedSearchModalComponent extends CGModalComponent<Array<IDocComercialLinha> | string> implements OnInit {
  @Input() public emptyLineComercial: IDocComercialLinha;
  @Input() public maintenanceMode: boolean;
  @Input() public serviceMethodsOverride: IEntityServiceMethodsOverride;

  public dataGridProperties: IDevExpressDataGrid;
  public listFields: string;
  public filter: string;
  public firstTime: boolean;
  public captionBtnClose: string;

  private readonly _serviceArtigos: IArtigosEntityService;

  private _selectedLines: Array<IArtigo>;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this._serviceArtigos = this._entityServiceBuilder.build<IArtigo, IArtigosEntityService>(ENTITY_NAME_ARTIGOS);
    this.firstTime = true;
    this.filter = 'artDesactivado=false&artBloqueado=false';
    this._selectedLines = [];
  }

  public ngOnInit(): void {
    const selectionMode: TDevExpressDataGridSelectionMode = !this.emptyLineComercial ? 'single' : 'multiple';
    this.dataGridProperties = {
      stateStoring: {enabled: false},
      selection: {mode: selectionMode, showCheckBoxesMode: !this.emptyLineComercial ? 'none' : 'always', selectAllMode: 'page'},
      editing: {mode: 'batch', startEditAction: 'click', selectTextOnEditStart: true, allowUpdating: isDefinedNotNull(this.emptyLineComercial), refreshMode: 'repaint'}
    };
    this.captionBtnClose = !this.emptyLineComercial ? 'global.btn.ok' : 'global.btn.add';
  }

  public close(): void {
    if (!this._selectedLines?.length) {
      this._plAlertService.error('docscomerciais.erros.noSelArtigos');
      return;
    }

    if (!this.emptyLineComercial) {
      super.close(this._selectedLines[0].nArtigo);
      return;
    }

    const linesToAdd: Array<IDocComercialLinha> = [];
    const changes: unknown = this._dataGridInstance.option('editing.changes');
    for (const selectedRow of this._selectedLines) {
      const index = (<Array<DataChange<IArtigo, string>>>changes).findIndex((item: DataChange<IArtigo, string>) => item.key === selectedRow.nArtigo);
      if (index !== -1) {
        selectedRow.qtdAdd = changes[index].data.qtdAdd;
      }

      linesToAdd.push({...this.emptyLineComercial, nArtigo: selectedRow.nArtigo, nomeArtigo: selectedRow.nome, qtd1: selectedRow.qtdAdd});
    }

    this._dataGridInstance.clearGrouping();
    super.close(linesToAdd);
  }

  public dismiss(): void | Promise<void> {
    this._dataGridInstance.clearGrouping();
    return super.dismiss();
  }

  public onDataGridColumnsPreparing(columns: Array<IDevExpressDataGridColumn>): void {
    for (const column of columns) {
      if (column.dataField === 'nomeTpArt') {
        column.groupIndex = GROUP_INDEX_0;
      }
      if (column.dataField === 'nomeGrFamilia') {
        column.groupIndex = GROUP_INDEX_1;
      }
      if (column.dataField === 'nomeFamilia') {
        column.groupIndex = GROUP_INDEX_2;
      }
      if (column.dataField === 'nomeSubFa') {
        column.groupIndex = GROUP_INDEX_3;
      }
    }
  }

  public onDataGridOnInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    if (isDefinedNotNull(this.emptyLineComercial)) {
      component.addColumn({dataField: 'qtdAdd', cssClass: 'text-center', dataType: 'number', caption: this._translateService.instant('artigos.fields.qtdAdd'), allowEditing: true});
    }

    component.columnOption('nomeTpArt', 'visible', true);
    component.columnOption('nomeGrFamilia', 'visible', true);
    component.columnOption('nomeSubFa', 'visible', true);

    component.columnOption('nArtigo', 'allowEditing', false);
    component.columnOption('nome', 'allowEditing', false);
    component.columnOption('nomeFamilia', 'allowEditing', false);
    component.columnOption('nomeTpArt', 'allowEditing', false);
    component.columnOption('nomeGrFamilia', 'allowEditing', false);
    component.columnOption('nomeSubFa', 'allowEditing', false);
    component.columnOption('nomeIvaVenda', 'allowEditing', false);
    component.columnOption('taxaIvaVenda', 'allowEditing', false);
    component.columnOption('precoSemIva', 'allowEditing', false);
    component.columnOption('precoComIva', 'allowEditing', false);
    component.columnOption('movimStock', 'allowEditing', false);
    component.columnOption('temLote', 'allowEditing', false);
    component.columnOption('codvaloriz', 'allowEditing', false);
    component.columnOption('qtdTotal', 'allowEditing', false);

    this._dataGridInstance = component;
  }

  public onDataGridCellPrepared(event: IDevExpressDataGridEventOnCellPrepared): void {
    if (event.rowType === 'data') {
      if (event.column.dataField === 'qtdAdd') {
        event.cellElement.classList.add('datagrid-editable-cell');
      }
    }
  }

  public onDataGridOnSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IArtigo, string>): void {
    this._selectedLines = event.selectedRowsData;
  }

  public onDataGridOnEditingStart(event: IDevExpressDataGridEventOnEditingStart<IArtigo, string>): void {
    if (event?.key) {
      this._dataGridInstance.selectRows([event.key], true);
    }
  }

  public readonly fnBeforeRequest: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequest(queryRequestConfig);

  public readonly fnAfterRequest: TEntityListAfterRequestFn<IArtigo> = (response: THttpQueryResponse<IArtigo>) => this._afterRequest(response);

  private _beforeRequest(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    const changes: unknown = this._dataGridInstance.option('editing.changes');
    for (const selectedRow of this._selectedLines) {
      const index = (<Array<DataChange<IArtigo, string>>>changes).findIndex((item: DataChange<IArtigo, string>) => item.key === selectedRow.nArtigo);
      if (index !== -1) {
        selectedRow.qtdAdd = changes[index].data._qtdAdd;
      }
    }
    return queryRequestConfig;
  }

  private async _afterRequest(response: THttpQueryResponse<IArtigo>): Promise<IApiQueryResponse<IArtigo>> {
    const artigosList = [];

    for (const artigo of response.body.list) {
      if (artigo.qtdTotal === 0) {
        artigosList.push(artigo.nArtigo);
      }
    }

    if (artigosList.length === 0) {
      return response.body;
    }

    const artigosProcessParams: IJsonArtigosProcessParams = {nArtigoList: artigosList.toString()};

    await this._serviceArtigos.processaStockArtigosNosArmazens(artigosProcessParams, true).then((resp: HttpResponse<Array<IJsonArtigoStockArmazens>>) => {
      if (resp) {
        for (const stock of resp.body) {
          const idx = response.body.list.findIndex((item) => item.nArtigo === stock.nArtigo);
          if (idx !== -1) {
            response.body.list[idx] = {...response.body.list[idx], qtdTotal: stock.qtdTotal, stockArmazens: stock.list};
          }
        }
      }
    });
    return response.body;
  }
}
