import {Observable} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {isObject, Logger} from 'pl-comps-angular';
import {AuthService} from '../auth/auth.service';
import {CG_DEFAULT_STORE_NIF, ICGConfigurations} from '../config/config.service.interface';
import {ConfigService} from '../config/config.service';
import {EmpresasService} from '../empresas/empresas.service';
import {EPaisISO3166} from '../../datasources/paisesiso3166/paisesISO3166.datasource.interface';
import {EPortal} from '../../../common/enums/portals.enums';
import {IJsonEmpresaAno} from '../../interfaces/jsonEmpresa.interface';
import {IJsonPeriodo} from '../../entities/periodos/jsonPeriodo.entity.interface';
import {ROLE} from '../role.const';
import {TServiceQueryResponse, TServiceResponse} from '../api/api.service.interface';
import {TUserSession} from '../account/jsonUserApi.interface';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {
  private _lastCheck: boolean;
  private _lastNEmpresa: string;

  constructor(
    private readonly _logger: Logger,
    private readonly _configService: ConfigService,
    private readonly _authService: AuthService,
    private readonly _empresasService: EmpresasService
  ) {
    this._lastCheck = true;
  }

  public async checkStoreCompanyNeedsConfiguration(): Promise<boolean> {
    try {
      const session: TUserSession = await this._authService.identity();
      if (isObject(session)) {
        const configurations: ICGConfigurations = this._configService.configurations;
        const nEmpresa: string = session.erp.nEmpresa;
        if (this._lastNEmpresa && nEmpresa === this._lastNEmpresa) {
          return this._lastCheck;
        }
        this._lastNEmpresa = nEmpresa;
        const hasRoleAdminBasic: boolean = await this._authService.hasAuthority(ROLE.ADMINBASIC);
        const rolePortalConfiguracoes: ROLE = await this._authService.getAndGeneratePortalRole(EPortal.CONFIGURACOES);
        const hasRolePortalConfiguracoes: boolean = await this._authService.hasAuthority(rolePortalConfiguracoes);
        const hasAdminBasic: boolean = hasRoleAdminBasic && hasRolePortalConfiguracoes;
        const demonstracao: boolean = configurations.empresa.demonstracao;
        const nif: string = configurations.empresa.ncontribuemp;
        this._lastCheck = demonstracao === false && session.idContratoPai && hasAdminBasic && nif === CG_DEFAULT_STORE_NIF;
      } else {
        this._lastCheck = false;
      }
      return this._lastCheck;
    } catch (reason: unknown) {
      this._logger.error(reason);
      this._lastNEmpresa = undefined;
      return false;
    }
  }

  public getLogotipoUrl(): Observable<string> {
    return this._authService
      .identityAsObservable()
      .pipe(take(1))
      .pipe(
        mergeMap<TUserSession, Observable<string>>((session: TUserSession) => {
          return this._empresasService.getLogotipoUrl(session.erp.nEmpresa);
        })
      );
  }

  public getUploadLogotipoUrl(): Observable<string> {
    return this._authService
      .identityAsObservable()
      .pipe(take(1))
      .pipe(
        mergeMap<TUserSession, Observable<string>>((session: TUserSession) => {
          return this._empresasService.getUploadLogotipoUrl(session.erp.nEmpresa);
        })
      );
  }

  public getLogo(): TServiceResponse<Blob> {
    return this._authService.identity().then((session: TUserSession) => {
      return this._empresasService.getLogo(session.erp.nEmpresa);
    });
  }

  public getAnos(): TServiceQueryResponse<IJsonEmpresaAno> {
    return this._authService.identity().then((session: TUserSession) => {
      return this._empresasService.getAnos(session.erp.nEmpresa);
    });
  }

  public getPeriodosIVA(ano: number): TServiceQueryResponse<IJsonPeriodo> {
    return this._authService.identity().then((session: TUserSession) => {
      return this._empresasService.getPeriodosIVA(session.erp.nEmpresa, ano);
    });
  }

  public sujeitaAsLeisVigoramEmPortugal(): Promise<boolean> {
    return this._authService.identity().then((session: TUserSession) => {
      if (isObject(session)) {
        const configurations: ICGConfigurations = this._configService.configurations;
        return configurations.empresa.codIso3166 === EPaisISO3166['00'] || configurations.empresa.codIso3166 === EPaisISO3166.PT;
      }
      return false;
    });
  }

  public sujeitaAsLeisVigoramEmAngola(): Promise<boolean> {
    return this._authService.identity().then((session: TUserSession) => {
      if (isObject(session)) {
        const configurations: ICGConfigurations = this._configService.configurations;
        return configurations.empresa.codIso3166 === EPaisISO3166.AO;
      }
      return false;
    });
  }

  public sujeitaAsLeisVigoramEmMocambique(): Promise<boolean> {
    return this._authService.identity().then((session: TUserSession) => {
      if (isObject(session)) {
        const configurations: ICGConfigurations = this._configService.configurations;
        return configurations.empresa.codIso3166 === EPaisISO3166.MZ;
      }
      return false;
    });
  }
}
