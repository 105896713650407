import {inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PlRTL {
  private readonly _element = inject(DOCUMENT)?.documentElement;

  public isRTL(): boolean {
    return this._element && (this._element.getAttribute('dir') || '').toLowerCase() === 'rtl';
  }
}
