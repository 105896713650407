import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import {generateGestaoComunicacoesDate, IGestaoComunicacoesMensagemGroup} from '../../../modules/portalcontabilidade/gestaocomunicacoes/gestaoComunicacoes.module.interface';
import {IJsonGestaoComunicacoesMensagem} from '../../../modules/portalcontabilidade/gestaocomunicacoes/jsonGestaoComunicacoesMensagem.module.interface';
import {PlTranslateService} from 'pl-comps-angular';
import moment from 'moment';

const DATE_FORMAT = 'DD/MM/YYYY';

@Component({
  selector: 'cg-gestao-comunicacoes-messages-container',
  templateUrl: './messagesContainer.component.html'
})
export class CGGestaoComunincacaoMessageContainerComponent implements OnChanges {
  @Input() public showPaddingTop: boolean;
  @Input() public mensagens: Array<IJsonGestaoComunicacoesMensagem>;

  public messagesGrouped: Array<IGestaoComunicacoesMensagemGroup>;
  @ViewChild('messagesContainer') private readonly _messagesContainer: ElementRef;

  constructor(
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cdr: ChangeDetectorRef
  ) {}

  public ngOnChanges(): void {
    this.groupMessages();
  }

  public readAndAddNewMessage(newMessage: IJsonGestaoComunicacoesMensagem): void {
    // push the new message to array and read all messages
    this.mensagens.push(newMessage);
    for (const message of this.mensagens) {
      message.isRead = true;
    }

    if (this.mensagens.length > 0) {
      const lastGroupMessage: IGestaoComunicacoesMensagemGroup = this.messagesGrouped.at(-1);
      if (lastGroupMessage && moment(lastGroupMessage.date, DATE_FORMAT).isSame(newMessage.stampCreated, 'day')) {
        lastGroupMessage.messages.push(newMessage);
      }
    } else {
      const newGroupMessage: IGestaoComunicacoesMensagemGroup = {
        groupHeader: this._plTranslateService.translate('global.text.today'),
        date: moment(),
        read: true,
        messages: [newMessage]
      };
      this.messagesGrouped.push(newGroupMessage);
    }

    this.groupMessages();
  }

  public groupMessages(): void {
    const groupedMessages: {[key: string]: IGestaoComunicacoesMensagemGroup} = {};
    const unreadMessages: IGestaoComunicacoesMensagemGroup = {
      groupHeader: undefined,
      date: null,
      read: false,
      messages: []
    };

    this.mensagens.forEach((message: IJsonGestaoComunicacoesMensagem) => {
      if (!message.isRead) {
        unreadMessages.messages.push(message);
      } else {
        const dateKey: string = moment(message.stampCreated).format(DATE_FORMAT);
        if (!groupedMessages[dateKey]) {
          groupedMessages[dateKey] = {
            groupHeader: generateGestaoComunicacoesDate(message.stampCreated, false, this._plTranslateService),
            date: dateKey,
            read: true,
            messages: []
          };
        }
        groupedMessages[dateKey].messages.push(message);
      }
    });

    this.messagesGrouped = Object.values(groupedMessages);
    if (unreadMessages.messages.length > 0) {
      this.messagesGrouped.push(unreadMessages);
    }

    this.scrollToBottom();
  }

  public scrollToBottom(): void {
    this._cdr.detectChanges();
    setTimeout(() => {
      this._messagesContainer.nativeElement.scrollTop = this._messagesContainer.nativeElement.scrollHeight;
    }, 0);
  }
}
