<div class="modelo22-contasdef-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo22.modal.rosto.contasdefcopy.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-multiselect [model]="empresasSel" [source]="empresasSource" key="nempresa" [template]="msEmpresasTemplate" (evtChanged)="fnEmpresasChanged()"></pl-multiselect>

    <pl-group>
      <label [translate]="'modelo22.modal.rosto.contasdefcopy.checkbox.deletedestino'"></label>
      <edit>
        <pl-edit type="boolean" attrName="apagacontasempresadestino" [model]="apagacontasempresadestino"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="copiarcontas" [onClick]="close" [disabled]="!temEmpresasSelecionadas">
      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
    </pl-button>
    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
