import {IJsonEntNif} from '../../../interfaces/jsonEntNif.interface';
import {IJsonPreDefinidoContab} from '../manutencao/predefinidos/jsonPreDefinidosContab.module.interface';
import {IJsonExtPocLiga} from '../../../interfaces/jsonExtPocLiga.interface';
import {IJsonPOC} from '../../../entities/pocs/jsonPOC.entity.interface';
import {IJsonExtPocRet} from '../../../interfaces/jsonExtPocRet.interface';
import {EClassificacaoControlo, EClassificacaoControloRetencao} from '../../../../common/enums/contabilidade.enums';
import {EDebitoCredito} from '../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {TDate} from '../../../../common/dates';

export enum ECampoCalculadoME {
  Valor,
  ValorME,
  ValorCambio
}

export enum EDocContabilidadeOrigem {
  None,
  Integracao,
  IntegracaoSalarios,
  Imobilizado,
  PrevTesPlanoPagamentos,
  PrevTesGarantiaBancaria,
  PrevTesEncomenda,
  EmparidadesClientes,
  PreDefinidos,
  ApuramentoResultadosLiquido,
  ApuramentoInventarioPermanente,
  ImportacaoSaftContabilidade,
  GestaoCheques,
  ERPCloud,
  ExecucaoOrcamental,
  AtivosAquisicao,
  AtivosDepreciacao,
  AtivosAbate,
  AtivosSubsidios,
  AtivosMudancaConta,
  GestaoLetras,
  AtivosErrosDepreciacoesAnosAnteriores,
  IntegracaoComErro,
  DocumentoPendenteBanco,
  AtivosReavaliacoesNaoLegais
}

export interface IJsonRegularizacaoCampo40 {
  nDocumento: string;
  valor: number;
  valorIVA: number;
  data: TDate;
  valorRegistado: number;
  valorRegistadoIVA: number;
  linhasRegularizacaoCampo40?: Array<IJsonRegularizacaoCampo40>;
}

export interface IJsonContaAnalitica {
  manalID: number;
  valor: number;
  artigocategoria: number;
  artigoclasse: number;
  ccusto: string;
  codcontabilistico: string;
  conta: string;
  departamento: number;
  familia: number;
  grandefamilia: number;
  processo: string;
  subdepartamento: string;
  subfamilia: number;
  tipoartigo: number;
  vendedor: number;
  zona: number;
}

export interface IJsonDocContabilidadeCab {
  anulado: boolean;
  cambioRef: number;
  campoErroInformativo: string;
  codDescritivo: number;
  codMoeda: number;
  dataDoc: TDate;
  dataLancamento: TDate;
  dataRefIvaCaixa: TDate;
  dataVencimento: TDate;
  descricao: string;
  docExternoFixed: boolean;
  documentoExterno: string;
  estadIvaCaixa: number;
  extPocCabID: string;
  isUsingPreDefinido: boolean;
  nContribuinte: string;
  nDecimaisCambio: number;
  nDecimaisValor: number;
  nDecimaisValorME: number;
  nDiario: number;
  nDocInterno: string;
  nDocSeq: number;
  nDocumento: string;
  nomeDescritivo: string;
  nomeDiario: string;
  nomeMoeda: string;
  nomePeriodo: string;
  origem: EDocContabilidadeOrigem;
  periodo: string;
  refExterna: string;
  temDocDigital: boolean;
  totalCreditoGeral: number;
  totalDebitoGeral: number;
  nUtilizDocFacOk: number;
  nomeUtilizDocFacOk: string;
  stampDocFacOk: TDate;
  docFimAno: boolean;
  isDocDiferimento: boolean;
  isDocPrincipalDiferimento: boolean;
  refDiferimento: string;
  dataIniDiferimento: TDate;
  dataFimDiferimento: TDate;
  tipoDataRefDiferimento: number;
}

export interface IJsonDocContabilidadeLinha {
  cambio: number;
  cDecAnual: string;
  cDecPer: string;
  classificControlo: EClassificacaoControlo;
  classificControloRetencao: EClassificacaoControloRetencao;
  codControloIva: string;
  codIva: number;
  codMoeda: number;
  codMovAberto: boolean;
  codRetencao: string;
  dataLancamento: TDate;
  dataDoc: TDate;
  dataVencimento: TDate;
  dc: EDebitoCredito;
  descricao: string;
  extPocCabID: string;
  extPocLigaIDOrigemRetNaoDispo: string;
  extPocLigaToSave: IJsonExtPocLiga;
  extPocRetToSave: IJsonExtPocRet;
  isRegularizacaoCampo40Art78: boolean;
  listaLancsRetencaoAssociados: Array<string>;
  listaRegularizacaoCampo40: Array<IJsonRegularizacaoCampo40>;
  nConta: string;
  nContaCredito: string;
  nContaDebito: string;
  nContrib: string;
  nDescr: number;
  nDiario: number;
  nDocExterno: string;
  nDocInterno: string;
  nGrupo: number;
  nGrupoRetencao: number;
  nLanc: string;
  nLancDestinoImputacaoCCusto: string;
  nLancDestinoIva: string;
  nLancDestinoRetencao: string;
  nLancImput: string;
  nLancOrigemImputacaoCCusto: string;
  nLancOrigemIva: string;
  nLancOrigemRetencao: string;
  nomeMoeda: string;
  nSeq: number;
  nUtilizador: number;
  periodo: string;
  poc: IJsonPOC;
  preDefinidoContabLinhaIndice: number;
  taxaIvaEmVigor: number;
  taxaRetencaoUsada: number;
  temImputacoes: boolean;
  temMovAberto: boolean;
  tipoRetServNIF: number;
  valor: number;
  valorDesconto: number;
  valorDestinoIva: number;
  valorDestinoRetencao: number;
  valorME: number;
  valorOrigemIva: number;
  valorOrigemRetencao: number;
  valorPago: number;
  valorPagoME: number;
  valorRetencao: number;
  valorTaxa: number;
  valorTaxaME: number;
  campoMeCalculado: ECampoCalculadoME;
  entNIF: Partial<IJsonEntNif>;
  naoPerguntaCC: boolean;
  saldoContaCalculado: boolean;
  saldoConta: number;
  changedDataDoc: boolean;
  baseTributavel: number;
  nLancDescontoRefCC: string;
}

export interface IJsonDocContabilidade extends IJsonDocContabilidadeCab {
  linhas: Array<IJsonDocContabilidadeLinha>;
  revision: string;
  predefinido: IJsonPreDefinidoContab;
  soDeLeitura: boolean;
  natureza?: number;
}
