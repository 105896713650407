<div class="gestao-comunicacoes-messages-chat-input input-group" [ngClass]="{'mb-2': marginBottom, 'mt-3': marginTop}">
  <div class="input-group-prepend" *ngIf="showAttachFile">
    <input type="file" class="d-none" (change)="addFile($event)" #fileInput />
    <span class="input-group-prepend-icon" (click)="fileInput.click(); fileInput.value = ''">
      <i class="fa fa-cloud-upload" aria-hidden="true"></i>
    </span>
  </div>

  <textarea
    #messageInput
    rows="2"
    name="messageInput"
    class="input-group-message form-control"
    [(ngModel)]="message"
    (keydown)="onKeydownEnter($event)"
    [placeholder]="'gestaoComunicacoes.common.typeYourMessage' | translate">
  </textarea>

  <cg-speech-to-text *ngIf="showSpeechToText" (evtSendMessage)="onSendMessageSpeechText($event)"></cg-speech-to-text>

  <div class="input-group-append" *ngIf="showSendButton">
    <button class="btn btn-primary" type="button" (click)="onClickSendMessage()" [disabled]="!message">
      <span class="input-group-append-icon">
        <i class="fa fa-paper-plane" aria-hidden="true"></i>
      </span>
    </button>
  </div>
</div>

<div class="mt-1" *ngIf="showAttachFileMessage && !!file">
  <h6 class="text-success" [translate]="'gestaoComunicacoes.common.ficheiroSelecionadoSucesso'"></h6>
</div>
