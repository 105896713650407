<div class="pl-edit-group" [class.has-error]="linha._invalidNContaCredito">
  <entity-autocomplete
    entity="pocs"
    [attrName]="fieldName"
    inputClass="input-sm"
    output="nConta"
    placeholder="global.text.empty"
    [model]="model"
    (modelChange)="modelChanged($event)"
    [filter]="filter"
    [customActions]="customActionsPoc"
    [properties]="properties">
  </entity-autocomplete>
</div>
