<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div
  class="pl-tooltip"
  [ngbTooltip]="templateTooltip"
  [animation]="config?.animation"
  [autoClose]="shouldAutoClose()"
  [closeDelay]="config?.closeDelay"
  [container]="config?.container || undefined"
  [disableTooltip]="shouldDisableTooltip()"
  [openDelay]="config?.openDelay"
  [placement]="config?.placement || 'auto'"
  [popperOptions]="config?.popperOptions || identity"
  [positionTarget]="config?.positionTarget"
  [tooltipClass]="config?.tooltipClass || undefined"
  [triggers]="config?.triggers || 'hover focus'"
  (hidden)="onHidden()"
  (shown)="onShown()">
  <ng-container *ngTemplateOutlet="templateTooltipContent?.templateRef; context: templateTooltipContent?.plTooltipContent"></ng-container>
</div>

<ng-template #templateTooltip>
  <div class="pl-tooltip-text" [innerHTML]="config?.text | cgcTranslate"></div>
  <ng-container *ngTemplateOutlet="templateTooltipTemplate?.templateRef; context: templateTooltipTemplate?.plTooltipTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="config?.templateRef; context: config?.templateRefContext"></ng-container>
</ng-template>
