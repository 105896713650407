import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_NIVEL_QUALIFICACAO} from './nivelQualificacao.datasource.interface';
import {ENivelQualificacao} from '../../entities/nivql/jsonNivql.entity.interface';

export const DATA_SOURCE_NIVEL_QUALIFICACAO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_NIVEL_QUALIFICACAO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{value}} - {{name}}',
    output: 'name'
  },
  data: [
    {value: ENivelQualificacao.None, name: 'nivql.niveisqualificacao.0'},
    {value: ENivelQualificacao.QdSuperiores, name: 'nivql.niveisqualificacao.1'},
    {value: ENivelQualificacao.QdMedios, name: 'nivql.niveisqualificacao.2'},
    {value: ENivelQualificacao.EncarregMestre, name: 'nivql.niveisqualificacao.3'},
    {value: ENivelQualificacao.ProfiAltaQual, name: 'nivql.niveisqualificacao.4'},
    {value: ENivelQualificacao.ProfiQual, name: 'nivql.niveisqualificacao.5'},
    {value: ENivelQualificacao.ProfiSemiQual, name: 'nivql.niveisqualificacao.6'},
    {value: ENivelQualificacao.ProfiNaoQual, name: 'nivql.niveisqualificacao.7'},
    {value: ENivelQualificacao.Estagiarios, name: 'nivql.niveisqualificacao.8'}
  ]
});
