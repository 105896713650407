import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {TributAutonomasModuleService} from '../../../tributAutonomas.module.service';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {IJsonTributAutonoAnaliseHeader} from '../../../jsonTributAutonomas.module.interface';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';

@Component({
  selector: 'tribut-autonomas-analise-print-modal',
  templateUrl: './tributAutonomas.print.modal.component.html'
})
export class TributAutonomasPrintModalComponent extends CGModalComponent<void> {
  @Input() public header: IJsonTributAutonoAnaliseHeader;

  public report: IJsonReport;
  public pdfUrl: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _tributAutonomasService: TributAutonomasModuleService
  ) {
    super(_injector);
    this._loadDefaultReport();
  }

  public previsualizar(): void {
    this._tributAutonomasService.printAnaliseUrl(this.header, this.report.name).subscribe((url: string) => {
      this.pdfUrl = undefined;
      setTimeout(() => {
        this.pdfUrl = url;
      });
    });
  }

  private _loadDefaultReport(): void {
    this._reportsRegistryService
      .get(EReport.TributAutonomaAnalise)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.report = reports[0];
        }
      })
      .then(() => {
        this.previsualizar();
      });
  }
}
