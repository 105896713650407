import {ITree} from '../../components/treeviewer/treeviewer.interface';

export interface ITiposRegistoCadastro {
  value: string;
  name: string;
  items?: Array<ITiposRegistoCadastro>;
  nivel?: string;
  colapsed?: boolean;
}

export const ENTITY_NAME_TIPOS_REGISTO_CADASTRO = 'tiposregistocadastro';

export const VALORES_DE_MOTIVOQPESSPOAL: Array<string> = [
  '1,1,1,0,0,0',
  '1,1,2,0,0,0',
  '1,1,3,0,0,0',
  '1,2,1,1,0,0',
  '1,2,1,2,0,0',
  '1,2,1,3,0,0',
  '1,2,1,4,0,0',
  '1,2,1,5,1,0',
  '1,2,1,5,2,0',
  '1,2,1,6,0,0',
  '1,2,1,7,0,0',
  '1,2,1,8,0,0',
  '1,2,1,9,0,0',
  '1,2,1,10,0,0',
  '1,2,1,11,0,0'
];

export const TIPOS_REGISTO_CADASTRO_TREE: ITree = {
  nodes: [
    {
      nodeText: 'tiposregistocadastro.nodes.movPessoas',
      collapsed: false,
      selectable: false,
      nodeClass: 'disabled',
      childNodes: [
        {
          nodeText: 'tiposregistocadastro.nodes.entradas',
          collapsed: false,
          selectable: false,
          nodeClass: 'disabled',
          childNodes: [
            {
              nodeText: 'tiposregistocadastro.nodes.contSemTermo',
              nodeId: '1,1,1,0,0,0',
              selectable: true
            },
            {
              nodeText: 'tiposregistocadastro.nodes.contComTermoCerto',
              nodeId: '1,1,2,0,0,0',
              selectable: true
            },
            {
              nodeText: 'tiposregistocadastro.nodes.contComTermoIncerto',
              nodeId: '1,1,3,0,0,0',
              selectable: true
            }
          ]
        },
        {
          nodeText: 'tiposregistocadastro.nodes.saidas',
          collapsed: false,
          selectable: false,
          nodeClass: 'disabled',
          childNodes: [
            {
              nodeText: 'tiposregistocadastro.nodes.contSemTermo',
              collapsed: false,
              selectable: false,
              nodeClass: 'disabled',
              childNodes: [
                {
                  nodeText: 'tiposregistocadastro.nodes.inicTrabalhador',
                  nodeId: '1,2,1,1,0,0',
                  selectable: true
                },
                {
                  nodeText: 'tiposregistocadastro.nodes.mutuoAcordo',
                  nodeId: '1,2,1,2,0,0',
                  selectable: true
                },
                {
                  nodeText: 'tiposregistocadastro.nodes.iniEmpresa',
                  nodeId: '1,2,1,3,0,0',
                  selectable: true
                },
                {
                  nodeText: 'tiposregistocadastro.nodes.despColetivo',
                  nodeId: '1,2,1,4,0,0',
                  selectable: true
                },
                {
                  nodeText: 'tiposregistocadastro.nodes.despedimento',
                  collapsed: false,
                  selectable: false,
                  nodeClass: 'disabled',
                  childNodes: [
                    {
                      nodeText: 'tiposregistocadastro.nodes.comJustaCausa',
                      nodeId: '1,2,1,5,1,0',
                      selectable: true
                    },
                    {
                      nodeText: 'tiposregistocadastro.nodes.semJustaCausa',
                      nodeId: '1,2,1,5,2,0',
                      selectable: true
                    }
                  ]
                },
                {
                  nodeText: 'tiposregistocadastro.nodes.reformaPorInval',
                  nodeId: '1,2,1,6,0,0',
                  selectable: true
                },
                {
                  nodeText: 'tiposregistocadastro.nodes.reformaPorVelh',
                  nodeId: '1,2,1,7,0,0',
                  selectable: true
                },
                {
                  nodeText: 'tiposregistocadastro.nodes.reformaAntec',
                  nodeId: '1,2,1,8,0,0',
                  selectable: true
                },
                {
                  nodeText: 'tiposregistocadastro.nodes.preReforma',
                  nodeId: '1,2,1,9,0,0',
                  selectable: true
                },
                {
                  nodeText: 'tiposregistocadastro.nodes.falecimento',
                  nodeId: '1,2,1,10,0,0',
                  selectable: true
                },
                {
                  nodeText: 'tiposregistocadastro.nodes.impedProlong',
                  nodeId: '1,2,1,11,0,0',
                  selectable: true
                }
              ]
            },
            {
              nodeText: 'tiposregistocadastro.nodes.contComTermo',
              collapsed: false,
              selectable: false,
              nodeClass: 'disabled',
              childNodes: [
                {
                  nodeText: 'tiposregistocadastro.nodes.termoCerto',
                  collapsed: false,
                  selectable: false,
                  nodeClass: 'disabled',
                  childNodes: [
                    {
                      nodeText: 'tiposregistocadastro.nodes.cessacao',
                      nodeId: '1,2,2,1,1,0',
                      selectable: true
                    },
                    {
                      nodeText: 'tiposregistocadastro.nodes.antDaCessacao',
                      nodeId: '1,2,2,1,2,0',
                      selectable: true
                    },
                    {
                      nodeText: 'tiposregistocadastro.nodes.impedProlong',
                      nodeId: '1,2,2,1,3,0',
                      selectable: true
                    }
                  ]
                },
                {
                  nodeText: 'tiposregistocadastro.nodes.termIncerto',
                  collapsed: false,
                  selectable: false,
                  nodeClass: 'disabled',
                  childNodes: [
                    {
                      nodeText: 'tiposregistocadastro.nodes.cessacao',
                      nodeId: '1,2,2,2,1,0',
                      selectable: true
                    },
                    {
                      nodeText: 'tiposregistocadastro.nodes.antDaCessacao',
                      nodeId: '1,2,2,2,2,0',
                      selectable: true
                    },
                    {
                      nodeText: 'tiposregistocadastro.nodes.impedProlong',
                      nodeId: '1,2,2,2,3,0',
                      selectable: true
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      nodeText: 'tiposregistocadastro.nodes.altRegimeContr',
      collapsed: false,
      selectable: false,
      nodeClass: 'disabled',
      childNodes: [
        {
          nodeText: 'tiposregistocadastro.nodes.contComTermo',
          collapsed: false,
          selectable: false,
          nodeClass: 'disabled',
          childNodes: [
            {
              nodeText: 'tiposregistocadastro.nodes.passouASemTermo',
              nodeId: '2,1,1,0,0,0',
              selectable: true
            }
          ]
        }
      ]
    },
    {
      nodeText: 'tiposregistocadastro.nodes.promo',
      collapsed: false,
      selectable: false,
      nodeClass: 'disabled',
      childNodes: [
        {
          nodeText: 'tiposregistocadastro.nodes.porAntigui',
          nodeId: '3,1,0,0,0,0',
          selectable: true
        },
        {
          nodeText: 'tiposregistocadastro.nodes.porMerito',
          nodeId: '3,2,0,0,0,0',
          selectable: true
        },
        {
          nodeText: 'tiposregistocadastro.nodes.porOutroMotiv',
          nodeId: '3,3,0,0,0,0',
          selectable: true
        }
      ]
    },
    {
      nodeText: 'global.text.other',
      collapsed: false,
      selectable: false,
      nodeClass: 'disabled',
      childNodes: [
        {
          nodeText: 'tiposregistocadastro.nodes.reconvReclass',
          nodeId: '4,0,0,0,0,0',
          selectable: true
        },
        {
          nodeText: 'tiposregistocadastro.nodes.aumVencimento',
          nodeId: '5,0,0,0,0,0',
          selectable: true
        },
        {
          nodeText: 'global.text.other',
          nodeId: '6,0,0,0,0,0',
          selectable: true
        }
      ]
    }
  ]
};
