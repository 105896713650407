import {BehaviorSubject} from 'rxjs';
import {API_CENTRALGEST_HELPER, CSS_CLASS_LAUNCH_MODE_HYBRID, CSS_CLASS_LAUNCH_MODE_HYBRID_PARTIAL, LOCAL_STORAGE_APP_LAUNCH_MODE} from '../config/constants';
import {API_URL, DOCKER} from '../../environments/constants';
import {APP_API_KEY} from './app';
import {EAppLaunchMode, ISiteLaunchModeParams} from './site';
import {IAppRuntimeProperties} from './interfaces/interfaces';

export interface IApiPath {
  readonly host: string;
  readonly restapi: string;
  readonly account: string;
  readonly helper: string;
  readonly amalia: string;
}

let host = '';

if (!DOCKER) {
  host = API_URL;
  if (!host) {
    host = window.location.origin;
  }
  if (host.startsWith(':')) {
    host = `${location.protocol}//${location.hostname}${host}`;
  }
}
let forcedApiUrl: string;

export let API_PATH: IApiPath = evaluateApiPath(host);

function evaluateLaunchMode(): EAppLaunchMode {
  let launchMode: EAppLaunchMode = Number(window.sessionStorage.getItem(LOCAL_STORAGE_APP_LAUNCH_MODE)) || EAppLaunchMode.Default;
  if (window) {
    const href: string = window.location.href;
    const indexOfQueryParam: number = href.indexOf('?');
    if (indexOfQueryParam !== -1 && indexOfQueryParam !== href.length - 1) {
      const queryParamsStr: string = href.substring(indexOfQueryParam + 1);
      if (queryParamsStr) {
        const params: URLSearchParams = new URLSearchParams(queryParamsStr);
        try {
          const launchModeParams: ISiteLaunchModeParams = JSON.parse(params.get('launchMode'));
          if (launchModeParams) {
            if (launchModeParams.mode) {
              launchMode = launchModeParams.mode;
            }
            if (launchModeParams.apiUrl) {
              forcedApiUrl = launchModeParams.apiUrl;
            }
            if (launchModeParams.apiKey) {
              APP_API_KEY.next(launchModeParams.apiKey);
            }
          }
        } catch (reason: unknown) {
          console.error(reason);
        }
      }
    }
  }
  return launchMode;
}

export const APP_LAUNCH_MODE: BehaviorSubject<EAppLaunchMode> = new BehaviorSubject<EAppLaunchMode>(evaluateLaunchMode());

APP_LAUNCH_MODE.subscribe((launchMode: EAppLaunchMode) => {
  if (window?.sessionStorage) {
    if (launchMode) {
      window.sessionStorage.setItem(LOCAL_STORAGE_APP_LAUNCH_MODE, String(launchMode));
    } else {
      window.sessionStorage.removeItem(LOCAL_STORAGE_APP_LAUNCH_MODE);
    }
  }

  if (launchMode === EAppLaunchMode.Hybrid || launchMode === EAppLaunchMode.HybridPartial) {
    API_PATH = Object.freeze({
      ...API_PATH,
      restapi: !forcedApiUrl ? `${host}/erp/v1` : forcedApiUrl,
      account: !forcedApiUrl ? `${host}/erp/v1/auth` : `${forcedApiUrl.endsWith('/') ? forcedApiUrl : `${forcedApiUrl}/`}auth`
    });

    if (window?.document?.body) {
      const body: HTMLElement = window.document.body;
      if (launchMode === EAppLaunchMode.Hybrid) {
        body.classList.add(CSS_CLASS_LAUNCH_MODE_HYBRID);
      }
      if (launchMode === EAppLaunchMode.HybridPartial) {
        body.classList.add(CSS_CLASS_LAUNCH_MODE_HYBRID_PARTIAL);
      }
    }
  }
});

export function apiActivateRuntimeProperties(properties: IAppRuntimeProperties): void {
  if (properties?.origin) {
    API_PATH = evaluateApiPath(properties.origin);
  }
}

function evaluateApiPath(origin: string): IApiPath {
  return Object.freeze({
    host: origin,
    restapi: `${origin}/api/v1`,
    account: `${origin}/api/v1/auth`,
    helper: API_CENTRALGEST_HELPER,
    amalia: origin
  });
}
