import {IJsonRHPenhoras} from './jsonRHPenhoras.entity.interface';
import {IDataSourceItem} from '../../components/datasource/datasources.interface';
import {ETipoCategoria} from '../../datasources/tipocategoria/tipoCategoria.datasource.interface';
import {TranslateService} from '@ngx-translate/core';

export enum ETipoEscolhaPenhoras {
  Ativas,
  SuspensasLiquidadas,
  Todas
}

export interface ICategAbono {
  categ: IDataSourceItem<ETipoCategoria>;
  selected: boolean;
}

export interface IRHPenhoras extends IJsonRHPenhoras {
  codAbdes: string;
}

export enum EFormaPagamento {
  None,
  MBRef,
  IBAN,
  MBRefEstado
}

export function dataSourceFormaPagamento(translate: TranslateService): ReadonlyArray<IDataSourceItem<EFormaPagamento>> {
  return [
    {value: EFormaPagamento.None, label: translate.instant('rhpenhoras.enumStr.formaPagamento.none')},
    {value: EFormaPagamento.MBRef, label: translate.instant('rhpenhoras.enumStr.formaPagamento.mbref')},
    {value: EFormaPagamento.IBAN, label: translate.instant('rhpenhoras.enumStr.formaPagamento.iban')},
    {value: EFormaPagamento.MBRefEstado, label: translate.instant('rhpenhoras.enumStr.formaPagamento.mbrefestado')}
  ];
}

export const ENTITY_NAME_RHPENHORAS = 'rhpenhoras';
