<div class="faturacao-eletronica-configuracoes-post-add-safe-certificado-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" *ngIf="!closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="modalTitle"></h5>
  </div>

  <div class="modal-body" [ngSwitch]="closeDisabled">
    <div *ngSwitchCase="true" class="faturacao-eletronica-configuracoes-post-add-safe-certificado-modal-loader">
      <h6 [translate]="'faturacaoeletronicaconfiguracoes.postAddSafeCertificadoModal.message'"></h6>
      <i class="fa fa-spin fa-circle-o-notch" aria-hidden="true"></i>
    </div>

    <div *ngSwitchCase="false" class="faturacao-eletronica-configuracoes-post-add-safe-certificado-modal-error" plNavigation>
      <h6 [translate]="'faturacaoeletronicaconfiguracoes.postAddSafeCertificadoModal.error'"></h6>

      <button type="button" class="btn btn-sm btn-primary action-retry" (click)="execute()" plAutoFocus>
        <i class="fa fa-fw fa-repeat" aria-hidden="true"></i>&nbsp;<span [translate]="'global.btn.retry'"></span>
      </button>

      <button type="button" class="btn btn-sm btn-light action-cancel" (click)="dismiss()">
        <i class="fa fa-fw fa-times" aria-hidden="true"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
      </button>
    </div>
  </div>
</div>
