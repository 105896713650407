import {HttpResponse} from '@angular/common/http';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {MODULE_NAME_FATURACAO_PUBLICA} from './faturacaoPublica.module.interface';
import {FaturacaoPublicaService} from './faturacaoPublica.module.service';
import {IJsonFaturacaoPublica} from './jsonFaturacaoPublica.module.interface';
import {FaturacaoPublicaModuleComponent} from './components/faturacaoPublica.module.component';

export const MODULE_FATURACAO_PUBLICA: IModuleDefinition = {
  name: MODULE_NAME_FATURACAO_PUBLICA,
  state: {
    url: `/${MODULE_NAME_FATURACAO_PUBLICA}`,
    component: FaturacaoPublicaModuleComponent,
    data: {
      roles: [ROLE.ERP],
      sidebarTitle: 'portals.sidebar.modules.faturacaopublica'
    },
    params: {
      faccbID: {
        type: 'int',
        value: 0
      }
    },
    resolve: [
      {
        provide: 'faturacaoPublica',
        deps: [FaturacaoPublicaService],
        useFactory: (faturacaoPublicaService: FaturacaoPublicaService): Promise<IJsonFaturacaoPublica> => {
          return faturacaoPublicaService.loadDocumentos().then((response: HttpResponse<IJsonFaturacaoPublica>) => response.body);
        }
      }
    ]
  }
};
