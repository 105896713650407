import {EMonth} from 'pl-comps-angular';
import moment, {Moment, MomentInput} from 'moment';

export type TDate = MomentInput;

export interface IMomentRange {
  start: Moment;
  end: Moment;
}

const currentDate: Moment = moment();
const currentDateYear: number = currentDate.year();

export function monthName(month: number | EMonth): string {
  return moment.months()[month];
}

export function monthNameShort(month: number | EMonth): string {
  return moment.monthsShort()[month];
}

export function monthNames(): Array<string> {
  return moment.months();
}

export function monthNamesShort(): Array<string> {
  return moment.monthsShort();
}

export function currentYear(): number {
  return currentDateYear;
}

export function monthDateRange(month: EMonth, year: number = currentDateYear): IMomentRange {
  const startDate: Moment = moment({year: year, month: month, date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
  const endDate: Moment = startDate.clone().endOf('month');
  return {start: startDate, end: endDate};
}
