import {Type} from '@angular/core';
import {DocsComerciaisEstatisticasModuleComponent} from './artigos/docsComerciaisEstatisticas.module.component';
import {DocsComerciaisEstatisticasDocComponent} from './docs/docsComerciaisEstatisticasDoc.module.component';
import {
  EModuleTipo,
  MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS,
  MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_COMPRAS,
  MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_DOC,
  MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_DOC_COMPRAS,
  MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_DOC_VENDAS,
  MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_VENDAS,
  MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS,
  MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_COMPRAS,
  MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_DOC,
  MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_DOC_COMPRAS,
  MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_DOC_VENDAS,
  MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_VENDAS
} from './docsComerciaisEstatisticas.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';

export const MODULE_DOCS_COMERCIAIS_ESTATISTICAS_TODOS = generateModule(
  DocsComerciaisEstatisticasModuleComponent,
  MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS,
  MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS,
  'portals.sidebar.modules.docsComerciaisEstatisticas',
  EModuleTipo.TODOS
);

export const MODULE_DOCS_COMERCIAIS_ESTATISTICAS_VENDAS = generateModule(
  DocsComerciaisEstatisticasModuleComponent,
  MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_VENDAS,
  MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_VENDAS,
  'portals.sidebar.modules.docsComerciaisEstatisticas',
  EModuleTipo.VENDAS
);

export const MODULE_DOCS_COMERCIAIS_ESTATISTICAS_COMPRAS = generateModule(
  DocsComerciaisEstatisticasModuleComponent,
  MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_COMPRAS,
  MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_COMPRAS,
  'portals.sidebar.modules.docsComerciaisEstatisticas',
  EModuleTipo.COMPRAS
);

export const MODULE_DOCS_COMERCIAIS_ESTATISTICAS_DOC_TODOS = generateModule(
  DocsComerciaisEstatisticasDocComponent,
  MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_DOC,
  MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_DOC,
  'portals.sidebar.modules.docsComerciaisEstatisticasDoc',
  EModuleTipo.TODOS
);

export const MODULE_DOCS_COMERCIAIS_ESTATISTICAS_DOC_VENDAS = generateModule(
  DocsComerciaisEstatisticasDocComponent,
  MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_DOC_VENDAS,
  MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_DOC_VENDAS,
  'portals.sidebar.modules.docsComerciaisEstatisticasDoc',
  EModuleTipo.VENDAS
);

export const MODULE_DOCS_COMERCIAIS_ESTATISTICAS_DOC_COMPRAS = generateModule(
  DocsComerciaisEstatisticasDocComponent,
  MODULE_NAME_DOCS_COMERCIAIS_ESTATISTICAS_DOC_COMPRAS,
  MODULE_URL_DOCS_COMERCIAIS_ESTATISTICAS_DOC_COMPRAS,
  'portals.sidebar.modules.docsComerciaisEstatisticasDoc',
  EModuleTipo.COMPRAS
);

function generateModule(
  component: Type<DocsComerciaisEstatisticasModuleComponent | DocsComerciaisEstatisticasDocComponent>,
  moduleName: string,
  moduleUrl: string,
  sidebarTitle: string,
  moduleType: EModuleTipo
): IModuleDefinition {
  return {
    name: moduleName,
    state: {
      url: `/${moduleUrl}`,
      component: component,
      data: {
        roles: [ROLE.ERP],
        sidebarTitle: sidebarTitle
      },
      resolve: [{provide: 'moduleType', useFactory: () => moduleType}]
    }
  };
}
