import {HttpResponse} from '@angular/common/http';
import {FaturacaoEletronicaConfiguracoesModuleComponent} from './components/faturacaoEletronicaConfiguracoes.module.component';
import {FaturacaoEletronicaConfiguracoesService} from './service/faturacaoEletronicaConfiguracoes.module.service';
import {IJsonFaturacaoEletronicaConfig} from './jsonFaturacaoEletronicaConfiguracoes.module.interface';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {MODULE_NAME_FATURACAO_ELETRONICA_CONFIGURACOES} from './faturacaoEletronicaConfiguracoes.module.interface';
import {ROLE} from '../../services/role.const';

export const MODULE_FATURACAO_ELETRONICA_CONFIGURACOES: IModuleDefinition = {
  name: MODULE_NAME_FATURACAO_ELETRONICA_CONFIGURACOES,
  state: {
    url: `/${MODULE_NAME_FATURACAO_ELETRONICA_CONFIGURACOES}`,
    component: FaturacaoEletronicaConfiguracoesModuleComponent,
    data: {
      roles: [ROLE.ERP],
      sidebarTitle: 'portals.sidebar.modules.faturacaoeletronicaconfiguracoes'
    },
    params: {
      tab: {type: 'string', value: '', dynamic: true},
      certificadoPill: {type: 'string', value: '', dynamic: true},
      safe: {type: 'any', value: null, dynamic: true},
      safeTestes: {type: 'any', value: null, dynamic: true},
      digitalSign: {type: 'any', value: null, dynamic: true},
      digitalSignTestes: {type: 'any', value: null, dynamic: true}
    },
    resolve: [
      {
        provide: 'fatEletronicaConfig',
        deps: [FaturacaoEletronicaConfiguracoesService],
        useFactory: (faturacaoEletronicaConfiguracoesService: FaturacaoEletronicaConfiguracoesService): Promise<IJsonFaturacaoEletronicaConfig> => {
          return faturacaoEletronicaConfiguracoesService.getConfiguracoes().then((response: HttpResponse<IJsonFaturacaoEletronicaConfig>) => response.body);
        }
      }
    ]
  }
};
