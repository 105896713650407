import {EGestaoDGEMPSType} from '../../components/gestaodgemps/gestaodgemps.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonConfigEstrutura, IJsonPRHEventosConfig} from './jsonPRHEventosConfig.entity.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface IPRHEventosConfigEntityService extends IEntityService<IJsonPRHEventosConfig> {
  getConfiguracoesByTipo(tipo: EGestaoDGEMPSType): TServiceResponse<IJsonConfigEstrutura>;
}

export interface IPRHEventosConfigEntity extends IEntityDefinition<IJsonPRHEventosConfig, IPRHEventosConfigEntityService> {}

export const ENTITY_NAME_PRH_EVENTOS_CONFIG = 'prheventosconfig';
