import {Directive, EventEmitter, Output} from '@angular/core';
import type {IPlTinyMCEEvent} from './tinymce.events.interface';

@Directive()
export class PlTinyMCEEvents {
  @Output() public readonly evtBeforePaste: EventEmitter<IPlTinyMCEEvent<ClipboardEvent>> = new EventEmitter<IPlTinyMCEEvent<ClipboardEvent>>();
  @Output() public readonly evtBlur: EventEmitter<IPlTinyMCEEvent<FocusEvent>> = new EventEmitter<IPlTinyMCEEvent<FocusEvent>>();
  @Output() public readonly evtClick: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtContextMenu: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtCopy: EventEmitter<IPlTinyMCEEvent<ClipboardEvent>> = new EventEmitter<IPlTinyMCEEvent<ClipboardEvent>>();
  @Output() public readonly evtCut: EventEmitter<IPlTinyMCEEvent<ClipboardEvent>> = new EventEmitter<IPlTinyMCEEvent<ClipboardEvent>>();
  @Output() public readonly evtDblclick: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtDrag: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtDragDrop: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtDragEnd: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtDragGesture: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtDragOver: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtDrop: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtFocus: EventEmitter<IPlTinyMCEEvent<FocusEvent>> = new EventEmitter<IPlTinyMCEEvent<FocusEvent>>();
  @Output() public readonly evtFocusIn: EventEmitter<IPlTinyMCEEvent<FocusEvent>> = new EventEmitter<IPlTinyMCEEvent<FocusEvent>>();
  @Output() public readonly evtFocusOut: EventEmitter<IPlTinyMCEEvent<FocusEvent>> = new EventEmitter<IPlTinyMCEEvent<FocusEvent>>();
  @Output() public readonly evtKeyDown: EventEmitter<IPlTinyMCEEvent<KeyboardEvent>> = new EventEmitter<IPlTinyMCEEvent<KeyboardEvent>>();
  @Output() public readonly evtKeyPress: EventEmitter<IPlTinyMCEEvent<KeyboardEvent>> = new EventEmitter<IPlTinyMCEEvent<KeyboardEvent>>();
  @Output() public readonly evtKeyUp: EventEmitter<IPlTinyMCEEvent<KeyboardEvent>> = new EventEmitter<IPlTinyMCEEvent<KeyboardEvent>>();
  @Output() public readonly evtMouseDown: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtMouseEnter: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtMouseLeave: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtMouseMove: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtMouseOut: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtMouseOver: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtMouseUp: EventEmitter<IPlTinyMCEEvent<MouseEvent>> = new EventEmitter<IPlTinyMCEEvent<MouseEvent>>();
  @Output() public readonly evtPaste: EventEmitter<IPlTinyMCEEvent<ClipboardEvent>> = new EventEmitter<IPlTinyMCEEvent<ClipboardEvent>>();
  @Output() public readonly evtSelectionChange: EventEmitter<IPlTinyMCEEvent<Event>> = new EventEmitter<IPlTinyMCEEvent<Event>>();
  @Output() public readonly evtActivate: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtAddUndo: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtBeforeAddUndo: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtBeforeExecCommand: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtBeforeGetContent: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtBeforeRenderUI: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtBeforeSetContent: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtChange: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtClearUndos: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtDeactivate: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtDirty: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtExecCommand: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtGetContent: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtHide: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtInit: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtInitNgModel: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtLoadContent: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtNodeChange: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtPostProcess: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtPostRender: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtPreInit: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtPreProcess: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtProgressState: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtRedo: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtRemove: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtReset: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtResizeEditor: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtSaveContent: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtSetAttrib: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtObjectResizeStart: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtObjectResized: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtObjectSelected: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtSetContent: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtShow: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtSubmit: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtUndo: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
  @Output() public readonly evtVisualAid: EventEmitter<IPlTinyMCEEvent<unknown>> = new EventEmitter<IPlTinyMCEEvent<unknown>>();
}

export const TINY_MCE_VALID_EVENTS: Array<keyof PlTinyMCEEvents> = [
  'evtActivate',
  'evtAddUndo',
  'evtBeforeAddUndo',
  'evtBeforeExecCommand',
  'evtBeforeGetContent',
  'evtBeforeRenderUI',
  'evtBeforeSetContent',
  'evtBeforePaste',
  'evtBlur',
  'evtChange',
  'evtClearUndos',
  'evtClick',
  'evtContextMenu',
  'evtCopy',
  'evtCut',
  'evtDblclick',
  'evtDeactivate',
  'evtDirty',
  'evtDrag',
  'evtDragDrop',
  'evtDragEnd',
  'evtDragGesture',
  'evtDragOver',
  'evtDrop',
  'evtExecCommand',
  'evtFocus',
  'evtFocusIn',
  'evtFocusOut',
  'evtGetContent',
  'evtHide',
  'evtInit',
  'evtKeyDown',
  'evtKeyPress',
  'evtKeyUp',
  'evtLoadContent',
  'evtMouseDown',
  'evtMouseEnter',
  'evtMouseLeave',
  'evtMouseMove',
  'evtMouseOut',
  'evtMouseOver',
  'evtMouseUp',
  'evtNodeChange',
  'evtObjectResizeStart',
  'evtObjectResized',
  'evtObjectSelected',
  'evtPaste',
  'evtPostProcess',
  'evtPostRender',
  'evtPreProcess',
  'evtProgressState',
  'evtRedo',
  'evtRemove',
  'evtReset',
  'evtResizeEditor',
  'evtSaveContent',
  'evtSelectionChange',
  'evtSetAttrib',
  'evtSetContent',
  'evtShow',
  'evtSubmit',
  'evtUndo',
  'evtVisualAid'
];
