<div class="rhgestaoficheirosdmrat-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rhgestaoficheirosdmrat.modal.rhgestaoficheirosdmrattitle'"></h5>
  </div>

  <div class="modal-body">
    <pl-tabs>
      <pl-tab id="dadosAutomaticos" caption="rhgestaoficheirosdmrat.tab.dadosAutomaticos">
        <div *plTabContent>
          <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDadosAutomaticos" cgDxDataGridInstanceName="dadosAutomaticos" [dataSource]="dataGridDefinitionDadosAutomaticos.dataSource">
            <div *dxTemplate="let item of 'cellTemplateErrors'">
              <div *ngIf="item.data.erro === undefined || item.data.erro === 0">
                <i class="fa fa-check-circle icon-ok" aria-hidden="true"></i>
                <span [translate]="'rhgestaoficheirosdmrat.modal.table.ok'"></span>
              </div>
              <div *ngIf="item.data.erro === 1">
                <i class="fa fa-times-circle icon-erro" aria-hidden="true"></i>
                <span [translate]="'global.text.error'"></span>
              </div>
              <div *ngIf="item.data.erro === 2">
                <i class="fa fa-exclamation-circle icon-aviso" aria-hidden="true"></i>
                <span [translate]="'global.text.warning'"></span>
              </div>
            </div>
            <div *dxTemplate="let item of 'detailTemplateResumoDadosFuncionario'">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDadosAutomaticosDetail" [dataSource]="item.data.resumoDadosFuncionario"></dx-data-grid>
            </div>
          </dx-data-grid>
        </div>
      </pl-tab>

      <pl-tab id="dadosManuais" caption="rhgestaoficheirosdmrat.tab.dadosManuais">
        <div *plTabContent>
          <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDadosManuais" cgDxDataGridInstanceName="dadosManuais" [dataSource]="dataGridDefinitionDadosManuais.dataSource"></dx-data-grid>
        </div>
      </pl-tab>

      <pl-tab id="dadosGlobais" caption="rhgestaoficheirosdmrat.tab.dadosGlobais">
        <div *plTabContent>
          <pl-tabs>
            <pl-tab id="controlo" caption="rhgestaoficheirosdmrat.tab.controlo">
              <div *plTabContent>
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDadosGlobaisControlo" cgDxDataGridInstanceName="dadosGlobaisControlo" [dataSource]="dataGridDefinitionDadosGlobaisControlo.dataSource">
                </dx-data-grid>
              </div>
            </pl-tab>

            <pl-tab id="valorRendimentos" caption="rhgestaoficheirosdmrat.tab.valorRendimentos">
              <div *plTabContent>
                <dx-data-grid
                  [cgDxDataGrid]="dataGridDefinitionDadosGlobaisValorRendimentos"
                  cgDxDataGridInstanceName="dadosGlobaisValorRendimentos"
                  [dataSource]="dataGridDefinitionDadosGlobaisValorRendimentos.dataSource">
                </dx-data-grid>
              </div>
            </pl-tab>

            <pl-tab id="retencaoIRS" caption="rhgestaoficheirosdmrat.tab.retencaoIRS">
              <div *plTabContent>
                <dx-data-grid
                  [cgDxDataGrid]="dataGridDefinitionDadosGlobaisRetencaoIRS"
                  cgDxDataGridInstanceName="dadosGlobaisRetencaoIRS"
                  [dataSource]="dataGridDefinitionDadosGlobaisRetencaoIRS.dataSource">
                </dx-data-grid>
              </div>
            </pl-tab>

            <pl-tab id="contribObrigatorias" caption="rhgestaoficheirosdmrat.tab.contribObrigatorias">
              <div *plTabContent>
                <dx-data-grid
                  [cgDxDataGrid]="dataGridDefinitionDadosGlobaisContribObrigatorias"
                  cgDxDataGridInstanceName="dadosGlobaisContribObrigatorias"
                  [dataSource]="dataGridDefinitionDadosGlobaisContribObrigatorias.dataSource">
                </dx-data-grid>
              </div>
            </pl-tab>

            <pl-tab id="quotizacaoSindicais" caption="rhgestaoficheirosdmrat.tab.quotizacaoSindicais">
              <div *plTabContent>
                <dx-data-grid
                  [cgDxDataGrid]="dataGridDefinitionDadosGlobaisQuotizacaoSindicais"
                  cgDxDataGridInstanceName="dadosGlobaisQuotizacaoSindicais"
                  [dataSource]="dataGridDefinitionDadosGlobaisQuotizacaoSindicais.dataSource">
                </dx-data-grid>
              </div>
            </pl-tab>

            <pl-tab id="retencaoSobretaxa" caption="rhgestaoficheirosdmrat.tab.retencaoSobretaxa">
              <div *plTabContent>
                <dx-data-grid
                  [cgDxDataGrid]="dataGridDefinitionDadosGlobaisRetencaoSobretaxa"
                  cgDxDataGridInstanceName="dadosGlobaisRetencaoSobretaxa"
                  [dataSource]="dataGridDefinitionDadosGlobaisRetencaoSobretaxa.dataSource">
                </dx-data-grid>
              </div>
            </pl-tab>

            <pl-tab id="rendiAnosAnt" caption="rhgestaoficheirosdmrat.tab.rendiAnosAnt">
              <div *plTabContent>
                <dx-data-grid
                  [cgDxDataGrid]="dataGridDefinitionDadosGlobaisRendiAnosAnt"
                  cgDxDataGridInstanceName="dadosGlobaisRendiAnosAnt"
                  [dataSource]="dataGridDefinitionDadosGlobaisRendiAnosAnt.dataSource">
                </dx-data-grid>
              </div>
            </pl-tab>
          </pl-tabs>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
