import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonModelo25, IJsonModelo25Config, IJsonTiposDonativos, IJsonValidarModeloErrors} from './jsonModelo25.module.interface';
import {ETipModelo25} from './modelo25.module.interface';

@Injectable({
  providedIn: 'root'
})
export class Modelo25Service {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/modelo25`;
  }

  public getModelo25(tipoModelo: ETipModelo25): TServiceResponse<IJsonModelo25> {
    return this._apiService.get<IJsonModelo25>({
      url: this._path,
      params: {
        tipmodelo: tipoModelo
      }
    });
  }

  public getModelo25Config(): TServiceResponse<IJsonModelo25Config> {
    return this._apiService.get<IJsonModelo25Config>({
      url: `${this._path}/config`
    });
  }

  public saveModelo25Config(config: IJsonModelo25Config): TServiceResponse<IJsonModelo25Config> {
    return this._apiService.post<IJsonModelo25Config>({
      url: `${this._path}/config`,
      body: config
    });
  }

  public getModelo25TiposDonativos(): TServiceResponse<Array<IJsonTiposDonativos>> {
    return this._apiService.get<Array<IJsonTiposDonativos>>({
      url: `${this._path}/tiposdonativo`
    });
  }

  public validarModelo(modelo: IJsonModelo25): TServiceResponse<Array<IJsonValidarModeloErrors>> {
    return this._apiService.post<Array<IJsonValidarModeloErrors>, IJsonModelo25>({
      url: `${this._path}/validar`,
      body: modelo
    });
  }

  public criarFicheiro(modelo: IJsonModelo25): TServiceResponse<Blob> {
    return this._apiService.post<Blob, IJsonModelo25>({
      url: `${this._path}/criarficheiro`,
      body: modelo,
      responseType: 'blob'
    });
  }

  public validarDeclaracaoAT(ficheiro: IJsonModelo25): TServiceResponse<void> {
    return this._apiService.post<void, IJsonModelo25>({
      url: `${this._path}/at/validar`,
      body: ficheiro,
      reportExceptions: false
    });
  }

  public submeterDeclaracaoAT(ficheiro: IJsonModelo25): TServiceResponse<void> {
    return this._apiService.post<void, IJsonModelo25>({
      url: `${this._path}/at/submeter`,
      body: ficheiro,
      reportExceptions: false
    });
  }
}
