import {InjectOptions, Injector, ProviderToken} from '@angular/core';
import {invoke, TInjectorResolvable} from './common';

export class CGInjector {
  private static _injector: Injector;

  public static get<T = unknown>(token: ProviderToken<T>, notFoundValue?: T, options?: InjectOptions): T {
    if (!CGInjector._injector) {
      throw new TypeError('Tried to use injector before it is available');
    }
    return CGInjector._injector.get<T>(token, notFoundValue, options);
  }

  // eslint-disable-next-line consistent-this,@typescript-eslint/no-explicit-any
  public static invoke(fn: TInjectorResolvable, self?: unknown, injector?: Injector): any {
    return invoke(injector ?? CGInjector.injector, fn, self);
  }

  public static get injector(): Injector {
    return CGInjector._injector;
  }

  public static set injector(value: Injector) {
    CGInjector._injector = value;
  }
}
