import {Component, Injector, OnInit} from '@angular/core';
import ArrayStore from 'devextreme/data/array_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {copy, IPlNavWizardCallback, IPlNavWizardDefinition, IPlNavWizardEventBeforeChange, IPlNavWizardStep, PlAlertService} from 'pl-comps-angular';
import {ENTITY_NAME_POCS, IPOCSEntityService} from '../../../entities/pocs/pocs.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonCopyPOCsEmpErroInfo, IJsonCopyPOCsEmpresas, IJsonPOC, IJsonPOCCopy} from '../../../entities/pocs/jsonPOC.entity.interface';
import {IJsonErpUser} from '../../../services/account/jsonUserApi.interface';
import {IPocsStateParams} from '../pocsCopy.module.interface';
import {ModuloComponent} from '../../../components/module/module.component';

const STEP_INDEX_INTRO = 0;
const STEP_INDEX_CONTAS = 1;
const STEP_INDEX_EMPRESAS = 2;
const STEP_INDEX_REPORT = 3;

@Component({
  selector: 'module-pocs-copy',
  templateUrl: './pocsCopy.module.component.html'
})
export class POCSCopyModuleComponent extends ModuloComponent implements OnInit {
  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonPOCCopy, string>;
  public readonly dataGridDefinitionErrorList: IDevExpressDataGrid<IJsonCopyPOCsEmpErroInfo>;
  public readonly definitionWizard: IPlNavWizardDefinition;
  public readonly navWizardCallback: IPlNavWizardCallback;
  public selectedRowKeys: Array<string>;
  public selectedStep: IPlNavWizardStep;
  public empresasSource: Array<IJsonErpUser>;
  public empresasSel: Array<IJsonErpUser>;
  public errorList: Array<IJsonCopyPOCsEmpErroInfo>;
  public nContaDe: string;
  public nContaAte: string;
  public disableSteps: boolean;

  private readonly _pocsService: IPOCSEntityService;
  private readonly _nConta: string;
  private _dataGridInstance: dxDataGrid<IJsonPOCCopy, string>;
  private _auxSelectedRowKeys: Array<string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);

    this.pesquisaContasPOC = this.pesquisaContasPOC.bind(this);
    this.beforeStepChange = this.beforeStepChange.bind(this);
    this.finalize = this.finalize.bind(this);

    this._pocsService = this._entityServiceBuilder.build<IJsonPOC, IPOCSEntityService>(ENTITY_NAME_POCS);

    const params: IPocsStateParams = <IPocsStateParams>this._transition.params();
    this._nConta = params.nConta;

    this.navWizardCallback = {};
    this.definitionWizard = {
      items: []
    };
    this.empresasSel = [];
    this.errorList = [];
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'pocscopy.fields.nconta'},
        {dataField: 'nome', dataType: 'string', caption: 'pocscopy.fields.nome'},
        {dataField: 'tipo', dataType: 'string', caption: 'pocscopy.fields.tipo'},
        {dataField: 'temIVA', dataType: 'boolean', caption: 'pocscopy.fields.temiva'},
        {dataField: 'temCCusto', dataType: 'boolean', caption: 'pocscopy.fields.temccusto'}
      ],
      dataSource: [],
      export: {filename: 'pocscopy.exportcontas'},
      height: '40vh',
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'},
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarNConta',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.dataGridDefinitionErrorList = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nContaNome', dataType: 'string', caption: 'pocscopy.fields.nContaNome', groupIndex: 0},
        {dataField: 'nEmpresaNome', dataType: 'string', caption: 'pocscopy.fields.nEmpresaNome'},
        {dataField: 'erro', dataType: 'string', caption: 'global.text.error'}
      ],
      dataSource: [],
      export: {filename: 'pocscopy.exporterror'},
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'}
    };
    this.selectedRowKeys = [];
    this.empresasSource = [];
    this._auxSelectedRowKeys = [];
    this.nContaDe = '';
    this.nContaAte = '899999999999';
    if (this._nConta) {
      this.nContaDe = this._nConta;
      this.nContaAte = this._nConta;
    }
    this.disableSteps = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.empresasSource = copy(this.session.erps);
    const currentErpIndex = this.empresasSource.findIndex((erp: IJsonErpUser) => erp.nEmpresa === this.session.erp.nEmpresa);
    if (currentErpIndex !== -1) {
      this.empresasSource.splice(currentErpIndex, 1);
    }
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonPOCCopy, string>): void {
    this._dataGridInstance = component;
  }

  public onDataGridContentReady(): void {
    this.selectedRowKeys = this._auxSelectedRowKeys.slice();
  }

  public async pesquisaContasPOC(): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    try {
      const response = await this._pocsService.getPocsCopyList(this.nContaDe, this.nContaAte);
      this.dataGridDefinition.dataSource = new ArrayStore({
        key: 'nConta',
        data: response.body
      });
      this._plAlertService.success('pocscopy.messages.pesqsuccess');
    } finally {
      this._dataGridInstance.endCustomLoading();
    }
  }

  public async beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (currentStep === this.definitionWizard.items[STEP_INDEX_CONTAS] && (type === 'next' || nextStep === this.definitionWizard.items[STEP_INDEX_EMPRESAS])) {
      if (!this.selectedRowKeys?.length) {
        this._plAlertService.error('pocscopy.steps.errorcontassel');
        return false;
      }

      this._auxSelectedRowKeys = this.selectedRowKeys.slice();

      return true;
    }

    if (currentStep === this.definitionWizard.items[STEP_INDEX_EMPRESAS] && (type === 'next' || nextStep === this.definitionWizard.items[STEP_INDEX_REPORT])) {
      if (!this.empresasSel?.length) {
        this._plAlertService.error('pocscopy.steps.errorempresassel');
        return Promise.resolve(false);
      }
      await this._copiaContasParaEmpresas();
      return true;
    }

    if (currentStep === this.definitionWizard.items[STEP_INDEX_REPORT] && this.disableSteps) {
      return false;
    }

    return true;
  }

  public async finalize(): Promise<void> {
    this.definitionWizard.items[STEP_INDEX_REPORT].visited = true;
    await this.navWizardCallback.setStep(this.definitionWizard.items[STEP_INDEX_INTRO], true);
    this._auxSelectedRowKeys = [];
    this.selectedRowKeys = [];
    this.errorList = [];
    this.empresasSel = [];
    this.empresasSource = [];
    this.disableSteps = false;
    this.definitionWizard.items[STEP_INDEX_INTRO].disabled = false;
    this.definitionWizard.items[STEP_INDEX_CONTAS].disabled = false;
    this.definitionWizard.items[STEP_INDEX_EMPRESAS].disabled = false;
    this.definitionWizard.items[STEP_INDEX_REPORT].hidePrevious = false;
  }

  private async _copiaContasParaEmpresas(): Promise<void> {
    const copyPOCsEmpresas: IJsonCopyPOCsEmpresas = {
      nContaList: this.selectedRowKeys,
      nEmpresaList: this.empresasSel.map((value: IJsonErpUser) => value.nEmpresa)
    };
    const response = await this._pocsService.postPocsCopyList(copyPOCsEmpresas);
    this.errorList = response.body;
    this.disableSteps = this.errorList.length < 0;
    this.definitionWizard.items[STEP_INDEX_INTRO].disabled = true;
    this.definitionWizard.items[STEP_INDEX_CONTAS].disabled = true;
    this.definitionWizard.items[STEP_INDEX_EMPRESAS].disabled = true;
  }
}
