import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO41} from './tipoRegularizacaoCampo41.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPO_REGULARIZACAO_CAMPO41: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO41,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'tipoRegularizacaoCampo41.data.notApplicable'},
    {value: 1, name: 'tipoRegularizacaoCampo41.data.art78N3'},
    {value: 2, name: 'tipoRegularizacaoCampo41.data.art78N4'},
    {value: 3, name: 'tipoRegularizacaoCampo41.data.art78N6'},
    {value: 4, name: 'tipoRegularizacaoCampo41.data.art78N7ItemA'},
    {value: 5, name: 'tipoRegularizacaoCampo41.data.art78N7ItemB'},
    {value: 6, name: 'tipoRegularizacaoCampo41.data.art78N7ItemC'},
    {value: 7, name: 'tipoRegularizacaoCampo41.data.art78N7ItemD'},
    {value: 8, name: 'tipoRegularizacaoCampo41.data.art78N8ItemD'},
    {value: 9, name: 'tipoRegularizacaoCampo41.data.art78N12'},
    {value: 10, name: 'tipoRegularizacaoCampo41.data.art78CN1'},
    {value: 11, name: 'tipoRegularizacaoCampo41.data.art78CN3'},
    {value: 12, name: 'tipoRegularizacaoCampo41.data.otherRegularizationsNotCoveredByArt78RegularizationCoveredArticles23to26'},
    {value: 13, name: 'tipoRegularizacaoCampo41.data.otherRegularizationsNotCoveredByArticle78'},
    {value: 14, name: 'tipoRegularizacaoCampo41.data.article6OfDecreeLawN198/90of19June'},
    {value: 15, name: 'tipoRegularizacaoCampo41.data.decreeLawNo19/2017OfFebruary14'}
  ]
});
