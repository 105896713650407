import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {DebDiretosSEPAModuleComponent} from './components/debDiretosSEPA.module.component';
import {AuthService} from '../../services/auth/auth.service';
import {EPortal} from '../../../common/enums/portals.enums';

export const MODULE_DEBITOS_DIRETOS_SEPA: IModuleDefinition = {
  name: 'ddsepa',
  state: {
    url: '/ddsepa',
    component: DebDiretosSEPAModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pluginsRoles: [ROLE.ERPADVANCED],
      icon: 'fa-external-link-square',
      pageTitle: 'Débitos Diretos SEPA'
    },
    resolve: [
      {
        provide: 'acessoContabilidade',
        deps: [AuthService],
        useFactory: (authService: AuthService): Promise<boolean> => {
          return authService.getAndGeneratePortalRole(EPortal.CONTABILIDADE).then((role: ROLE) => authService.hasAuthority(role));
        }
      }
    ]
  }
};
