import {Component, Injector, Input} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {PreviTesService} from '../../../previTes.module.service';
import {HttpResponse} from '@angular/common/http';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {IJsonPreviTesAuto} from '../../../../../../entities/tesrubrica/jsonTesRubrica.entity.interface';
import {TDate} from '../../../../../../../common/dates';

@Component({
  selector: 'modal-new-previ-tes-auto',
  templateUrl: './previTesAuto.codRubrica.modal.component.html'
})
export class PreviTesAutoCodRubricaModalComponent extends CGModalComponent<Array<IJsonPreviTesAuto>> {
  @Input() public readonly previTesAutoList: Array<IJsonPreviTesAuto>;
  @Input() public readonly contaDe: string;
  @Input() public readonly contaAte: string;
  @Input() public readonly dataTesDe: TDate;
  @Input() public readonly dataTesAte: TDate;
  public codrubrica: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _previTesService: PreviTesService
  ) {
    super(_injector);
    this.codrubrica = undefined;
  }

  public close(): Promise<void> {
    if (!this.codrubrica) {
      this._plAlertService.error('prevites.manual.new.rubricanull');
      return Promise.resolve();
    }
    return this._previTesService
      .postProcessarPrevTesAuto(this.contaDe, this.contaAte, this.dataTesDe, this.dataTesAte, this.codrubrica, this.previTesAutoList)
      .then((response: HttpResponse<Array<IJsonPreviTesAuto>>) => {
        this._plAlertService.success('prevites.automatica.messages.procesuccess');
        super.close(response.body);
      });
  }
}
