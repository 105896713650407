import {TDate} from '../../../../common/dates';
import {EDebitoCredito} from '../../../datasources/debitocredito/debitoCredito.datasource.interface';

export enum ETTipodeIntegracao {
  PorEmpregado,
  PorProcessamento
}

export enum EIntegracaoSalariosStatus {
  Inactive = 'rhisInactive',
  Scheduled = 'rhisScheduled',
  Started = 'rhisStarted',
  Ended = 'rhisEnded',
  Timeout = 'rhisTimeout',
  Error = 'rhisError'
}

export enum EIntegracaoSalariosOperation {
  None = 'rhisoNone',
  Integ = 'rhisoInteg',
  Simulation = 'rhisoSimulation',
  RemoveInteg = 'rhisoRemoveInteg',
  LoadingProcs = 'rhisoLoadingProcs'
}

export interface IIntegracaoSalariosStatus {
  requestDate: TDate;
  userStarted: string;
  userStartedId: number;
  state: EIntegracaoSalariosStatus;
  description: string;
  position: number;
  max: number;
  currentOperation: EIntegracaoSalariosOperation;
  exceptionClassType: string;
}

export interface IIntegracaoSalariosConfig {
  nDiario: number;
  nDescritivo: number;
  codEstado: string;
  descricao: string;
  tipoDeIntegracao: ETTipodeIntegracao;
  nContaSegSocialSaldoInsuficiente: string;
  nContaSegSocialSaldoExcedente: string;
  nDocExterno: string;
  nDiarioDesc: string;
  nDescritivoDesc: string;
  codEstadoDesc: string;
  nContaSegSocialSaldoInsuficienteDesc: string;
  nContaSegSocialSaldoExcedenteDesc: string;
  errorList: Array<string>;
  nContaCovid19ApoioFamilia: string;
  nContaCovid19ApoioFamiliaDesc: string;
  nContaCovid19LayoffSimplSuspencao: string;
  nContaCovid19LayoffSimplSuspencaoDesc: string;
  nContaCovid19LayoffSimplParcial: string;
  nContaCovid19LayoffSimplParcialDesc: string;
  nContaCovid19ApoioFamiliaPerc: number;
  nContaCovid19LayoffSimplSuspencaoPerc: number;
  nContaCovid19LayoffSimplParcialPerc: number;
}

export interface IIntegracaoSalariosProc {
  _index?: number;
  nProcessamento: number;
  dataProcessamento: TDate;
  codEmp: number;
  nomeEmpregado: string;
  nomeProcessamento: string;
  tipoProcessamento: number;
  tipoProcessamentoDescricao: string;
  totalVencAbonos: number;
  totalVencDescontos: number;
  totalEncargos: number;
  erro: string;
  proceCabId: string;
  erroImage?: string;
}

export interface IIntegracaoSalariosProcessData {
  ids: Array<string | number>;
  config: IIntegracaoSalariosConfig;
}

export interface IIntegracaoSalariosProcessResult {
  integId?: number;
  temErro: boolean;
  dataValor: TDate;
  nDocExterno: string;
  valor: number;
  cambio: number;
  codMoeda: number;
  nCCusto: string;
  descricao: string;
  nDocInterno: string;
  dataVencimento: TDate;
  nContrib: string;
  nRefProcesso: string;
  contaOrigem: string;
  periodo: string;
  nDescritivo: number;
  nContaCredito: string;
  dataDoc: TDate;
  mascaraOrigem: string;
  valorME: number;
  nConta: string;
  nDiario: number;
  nContaDebito: string;
  dC: EDebitoCredito;
  temErroUI?: string;
  erro: string;
  nomeConta: string;
  nDoc: string;
}

export interface IIntegracaoSalariosSearchModel {
  todosProcs: boolean;
  tipoProce: number;
  codEmpDe: number;
  codEmpAte: number;
  dataDe: TDate;
  dataAte: TDate;
  descricaoTipoProc?: string;
}

export interface IIntegracaoSalariosTipoIntegracaoRadioItem {
  value: ETTipodeIntegracao;
  label: string;
}

export interface IIntegracaoSalariosError {
  tipoVencimentosEncargos: string;
  nCodAbdesc: string;
  codEmpregado: number;
  valor: number;
  data: TDate;
  erro: string;
}

export const MODULE_NAME_INTEGRACAO_SALARIOS = 'integracaoSalarios';
export const EAPIJOBRHINTEGRACAOSALARIOSNOTFOUND = 'EAPIJobRHIntegracaoSalariosNotFound';
