export enum EFileReaderReadyState {
  EMPTY,
  LOADING,
  DONE
}

export interface IPlFileReaderPromise<T> extends Promise<T> {
  readyState(): EFileReaderReadyState;

  abort(): void;
}
