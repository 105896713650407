import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlTranslateModule} from '../translate/translate.module';
import {CGCIconDirective} from '../icon';

import {PlNavigatorComponent} from './navigator.component';
import {PlNavigatorItemDirective} from './navigator.item.directive';
import {PlNavigatorItemCaptionDirective} from './navigator.item.caption.directive';
import {PlNavigatorItemHeaderDirective} from './navigator.item.header.directive';
import {PlNavigatorItemContentDirective} from './navigator.item.content.directive';

export * from './navigator.component';
export * from './navigator.interface';
export * from './navigator.item.caption.directive';
export * from './navigator.item.content.directive';
export * from './navigator.item.directive';
export * from './navigator.item.header.directive';

const DECLARATIONS = [PlNavigatorComponent, PlNavigatorItemDirective, PlNavigatorItemCaptionDirective, PlNavigatorItemHeaderDirective, PlNavigatorItemContentDirective];

@NgModule({
  imports: [CommonModule, PlTranslateModule, CGCIconDirective],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlNavigatorModule {}
