import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {isEmptyObject, isObject, toJson} from 'pl-comps-angular';
import {IAmaliaMessageBodyFaq, IAmaliaMessageBodyIntent, IAmaliaMessageBodyText, IAmaliaMessageBodyUnknown, TAmaliaMessageBody} from '../../../services/amalia/api/amalia.api.service.interface';

@Component({
  selector: 'amalia-chat-message-content',
  templateUrl: './amalia.chatmessagecontent.component.html'
})
export class AmaliaChatMessageContentComponent implements OnChanges {
  @Input() public messageBodyContent: TAmaliaMessageBody;
  @Input() public colapsable: boolean;
  @Input() public sentByAgent: boolean;
  @Input() public lastMessage: boolean;
  @Output() public readonly evtActionIntent: EventEmitter<IAmaliaMessageBodyIntent>;
  @Output() public readonly evtLinkClicked: EventEmitter<string>;

  public bodyMessage: IAmaliaMessageBodyText | IAmaliaMessageBodyUnknown;
  public bodyIntent: IAmaliaMessageBodyIntent;
  public bodyIntentParametersEmpty: boolean;
  public bodyFaq: IAmaliaMessageBodyFaq;
  public messageHTML: string;
  public normalizedMessage: string;
  public isCollapsed: boolean;

  constructor() {
    this.evtActionIntent = new EventEmitter<IAmaliaMessageBodyIntent>();
    this.evtLinkClicked = new EventEmitter<string>();
  }

  public ngOnChanges({messageBodyContent}: SimpleChanges): void {
    if (messageBodyContent) {
      this.isCollapsed = this.colapsable;
      this.bodyMessage = undefined;
      this.bodyIntent = undefined;
      this.bodyIntentParametersEmpty = true;
      this.messageHTML = undefined;

      const messageBody: TAmaliaMessageBody = messageBodyContent.currentValue;

      if (isObject(messageBody)) {
        if ('intent' in messageBody) {
          this.bodyIntent = messageBody;
          this.bodyIntentParametersEmpty = isEmptyObject(this.bodyIntent.parameters) || !this.bodyIntent.parameters;
          if (this.bodyIntent.message.includes('§')) {
            this.bodyIntent.message = this._convertCustomTagsToLinks(this.bodyIntent.message);
          }
        } else if ('format' in messageBody) {
          this.bodyFaq = messageBody;
          this.messageHTML = this._formatTextToOrderedList(this.bodyFaq.source);
        } else if ('message' in messageBody) {
          this.bodyMessage = messageBody;
        }
      }

      this.normalizedMessage = toJson(messageBody);
    }
  }

  public actionIntent(intent: IAmaliaMessageBodyIntent): void {
    this.evtActionIntent.emit(intent);
  }

  public handleCustomLinkClick(event: Event): void {
    event.stopPropagation();
    const target = <HTMLElement>event.target;
    if (target.tagName.toLowerCase() === 'a') {
      const text = target.innerText;
      this.evtLinkClicked.emit(text);
    }
  }

  private _formatTextToOrderedList(text: string): string {
    return text.replaceAll(/\s(\d+\.)/g, '<br/>$1');
  }

  private _convertCustomTagsToLinks(text: string): string {
    const regex = /§s§(.*?)§e§/gs;
    return text.replace(regex, (match, p1: string) => {
      if (p1.includes('<ul>')) {
        return p1.replace(/<li>(.*?)<\/li>/g, (liMatch, liContent: string) => {
          return `<li><a (click)="handleCustomLinkClick($event)">${liContent}</a></li>`;
        });
      }
      return `<a (click)="handleCustomLinkClick($event)">${p1.replace(/\n/g, '<br/>')}</a>`;
    });
  }
}
