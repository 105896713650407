import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonContabAdvancedOperations, IJsonContabAdvancedOperationsDefaults} from './jsonContabAdvancedOperations.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ContabAdvancedOperationsService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/contabadvancedoperations`;
  }

  public processaAlteracao(contabAdvancedOperations: IJsonContabAdvancedOperations): TServiceResponse<IJsonContabAdvancedOperations> {
    return this._apiService.post({
      url: `${this._path}/processa`,
      body: contabAdvancedOperations
    });
  }

  public getDefaults(): TServiceResponse<IJsonContabAdvancedOperationsDefaults> {
    return this._apiService.get({
      url: `${this._path}/defaults`
    });
  }

  public pesquisar(contabAdvancedOperations: IJsonContabAdvancedOperations): TServiceResponse<IJsonContabAdvancedOperations> {
    return this._apiService.get<IJsonContabAdvancedOperations>({
      url: `${this._path}/pesquisa`,
      params: {
        operacao: contabAdvancedOperations.operacao,
        dePeriodo: contabAdvancedOperations.dePeriodo,
        atePeriodo: contabAdvancedOperations.atePeriodo,
        deNDiario: contabAdvancedOperations.deNDiario,
        ateNDiario: contabAdvancedOperations.ateNDiario,
        deNDoc: contabAdvancedOperations.deNDoc,
        ateNDoc: contabAdvancedOperations.ateNDoc,
        periodoDestino: contabAdvancedOperations.periodoDestino,
        diarioDestino: contabAdvancedOperations.diarioDestino
      }
    });
  }
}
