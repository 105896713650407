import type {Subscription} from 'rxjs';
import {combineLatest, fromEvent} from 'rxjs';
import {Inject, Injectable, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {EVENT_SUPPORTED_INPUT} from './keyboard.interface';
import {IPlFormatConfig} from '../common/format/format.service.interface';
import {IPlI18nNumberFormat, PlI18nPlNumberService} from '../i18n';
import {KEYCODES} from '../common/constants';
import {PlFormatService} from '../common/format/format.service';
import {PlGlobalEventsService} from '../common/globalevents/global.events.service';

export const GLOBAL_EVENT_DOCUMENT_CLICK = 'cgcKeyboardService_documentKeyup';

@Injectable({
  providedIn: 'root'
})
export class PlKeyboardService implements OnDestroy {
  private readonly _subscriptionFormat: Subscription;
  private readonly _subscriptionDocumentKeyUp: Subscription;
  private _format: IPlI18nNumberFormat & IPlFormatConfig;
  private _preventDocumentKeyup: boolean;

  constructor(
    @Inject(DOCUMENT) document: unknown,
    private readonly _plFormatService: PlFormatService,
    private readonly _plI18nPlNumberService: PlI18nPlNumberService,
    private readonly _plGlobalEventsService: PlGlobalEventsService
  ) {
    this._subscriptionFormat = combineLatest([this._plFormatService.format, this._plI18nPlNumberService.format]).subscribe(([format, numberFormat]: [IPlFormatConfig, IPlI18nNumberFormat]) => {
      this._format = Object.freeze({...numberFormat, ...format});
    });
    if (document) {
      this._subscriptionDocumentKeyUp = fromEvent(<Document>document, 'keyup', {passive: true}).subscribe((event: Event) => {
        if (!this._preventDocumentKeyup) {
          this._plGlobalEventsService.broadcast(GLOBAL_EVENT_DOCUMENT_CLICK, event);
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionFormat.unsubscribe();
    if (this._subscriptionDocumentKeyUp) {
      this._subscriptionDocumentKeyUp.unsubscribe();
    }
  }

  public preventDocumentKeyup(): void {
    this._preventDocumentKeyup = true;
  }

  public allowDocumentKeyup(): void {
    this._preventDocumentKeyup = false;
  }

  public numericOnly(event: Event, options?: Partial<IPlI18nNumberFormat> & Partial<IPlFormatConfig>): void {
    if (options) {
      options = {...this._format, ...options};
    }
    const allowedKeys: Array<string> = [
      KEYCODES.UNIDENTIFIED,
      KEYCODES.ALT,
      KEYCODES.BACKSPACE,
      KEYCODES.CONTROL,
      KEYCODES.DASH,
      KEYCODES.DECIMAL_POINT,
      KEYCODES.DELETE,
      KEYCODES.END,
      KEYCODES.ENTER,
      KEYCODES.ESC,
      KEYCODES.F5,
      KEYCODES.F11,
      KEYCODES.F12,
      KEYCODES.HOME,
      KEYCODES.INSERT,
      KEYCODES.PAGE_DOWN,
      KEYCODES.PAGE_UP,
      KEYCODES.SUBTRACT,
      KEYCODES.TAB,
      KEYCODES.UP,
      KEYCODES.DOWN,
      KEYCODES.LEFT,
      KEYCODES.RIGHT,
      // Adding decimals separator to allowed keys
      options.decimalsSeparator
    ];
    const target: HTMLInputElement = <HTMLInputElement>event.target;
    const targetValue: string = target.value;
    const cursorPosition = target.selectionStart;
    const selectedAll = cursorPosition === 0 && target.selectionEnd === (targetValue || '').length;

    let eventKey = 'Unidentified';
    let ctrlKey = false;
    let shiftKey = false;
    let metaKey = false;

    if (event instanceof KeyboardEvent) {
      eventKey = event.key;
      ctrlKey = event.ctrlKey;
      shiftKey = event.shiftKey;
      metaKey = event.metaKey;
    } else if (EVENT_SUPPORTED_INPUT && event instanceof InputEvent) {
      eventKey = event.data || eventKey;
    } else {
      return;
    }

    if (eventKey === 'Unidentified') {
      return;
    }

    if (
      allowedKeys.includes(eventKey) ||
      ((ctrlKey || metaKey) &&
        (eventKey === KEYCODES.A ||
          eventKey === KEYCODES.a ||
          eventKey === KEYCODES.X ||
          eventKey === KEYCODES.x ||
          eventKey === KEYCODES.C ||
          eventKey === KEYCODES.c ||
          eventKey === KEYCODES.V ||
          eventKey === KEYCODES.v))
    ) {
      // If user tried to add decimals separator but decimals limit is falsy
      if (!options.decimalsLimit && (eventKey === options.decimalsSeparator || eventKey === KEYCODES.DECIMAL_POINT)) {
        event.preventDefault();
        return;
      }

      // If user tried to add more than one decimals separator
      let index = targetValue.indexOf(options.decimalsSeparator);
      if (index !== -1 && (eventKey === options.decimalsSeparator || eventKey === KEYCODES.DECIMAL_POINT) && !selectedAll) {
        event.preventDefault();
        return;
      }
      // If key pressed is decimal point, switch key to defined decimals separator
      if (eventKey === KEYCODES.DECIMAL_POINT && eventKey !== options.decimalsSeparator) {
        event.preventDefault();

        target.value += options.decimalsSeparator;
        return;
      }

      // If user tried to add more than one dash
      index = targetValue.indexOf('-');
      if ((eventKey === KEYCODES.DASH || eventKey === KEYCODES.SUBTRACT) && (((cursorPosition > 0 || index !== -1) && !selectedAll) || !options.allowNegative)) {
        event.preventDefault();
      }

      return;
    }

    // Ensure that it is a number and stop the keypress
    if (
      shiftKey ||
      (eventKey !== KEYCODES._0 &&
        eventKey !== KEYCODES._1 &&
        eventKey !== KEYCODES._2 &&
        eventKey !== KEYCODES._3 &&
        eventKey !== KEYCODES._4 &&
        eventKey !== KEYCODES._5 &&
        eventKey !== KEYCODES._6 &&
        eventKey !== KEYCODES._7 &&
        eventKey !== KEYCODES._8 &&
        eventKey !== KEYCODES._9)
    ) {
      event.preventDefault();
      return;
    }

    // Check if decimals length has reached it's maximum (only prevent if a selection isn't present)
    const index: number = targetValue.indexOf(options.decimalsSeparator);
    if (index !== -1 && !selectedAll) {
      const decimalsValue = targetValue.substring(index + 1, targetValue.length);
      if (decimalsValue.length >= options.decimalsLimit && cursorPosition > index) {
        event.preventDefault();
      }
    }
  }
}
