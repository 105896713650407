import {TDate} from '../../../common/dates';

export enum ETabEstado {
  PorComunicarManual,
  PorComunicarWebServ,
  ComunicadoEmValidacao,
  ComErros,
  Comunicados
}

export enum EFEAEstado {
  PorComunicar,
  ComunicadoEmValidacao,
  ComunicadoValidado,
  ComunicadoComErro,
  ErroTratado,
  Received,
  Rejected,
  Paid,
  ComunicadoEstruturaEmValidacao
}

export enum EFacPublicaModelo {
  ND,
  CIUS,
  CIUS_IP,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  CIUS_Saphety,
  CIUS_FEAP
}

export interface IJsonFaturacaoPublicaDoc {
  faccbID: number;
  nDocFa: number;
  nDocumento: number;
  nClifo: string;
  nomeClifo: string;
  facPublicaModeloStr: string;
  nIDAltern: number;
  datadoc: TDate;
  selected: boolean;
  ndocAsStringDraftSufixo: string;
  estadoDescricao: string;
  nNumer: number;
}

export interface IJsonFaturacaoPublica {
  documentosPorComunicarManual: Array<IJsonFaturacaoPublicaDoc>;
  documentosPorComunicarWebServ: Array<IJsonFaturacaoPublicaDoc>;
  documentosComErros: Array<IJsonFaturacaoPublicaDoc>;
  documentosEmValidacao: Array<IJsonFaturacaoPublicaDoc>;
}

export interface IJsonFaturacaoPublicaHistorico {
  data: TDate;
  documento: string;
  estado: string;
  tipo: string;
  codigo: string;
  descricao: string;
  nConta: string;
  nomeConta: string;
  nomeUtilizador: string;
}

export interface IJsonFaturacaoPublicaConfig {
  nome: string;
  configurado: boolean;
  configDados: IJsonFaturacaoPublicaConfigDados;
}

export interface IJsonFaturacaoPublicaAnexos {
  fileNameXml: string;
  fileNamePdf: string;
}

export interface IJsonFaturacaoPublicaConfigDados {
  utilizador: string;
  senha: string;
  endereco: string;
  estado: EFEAEstado;
  contasEstadoList: Array<IJsonFaturacaoPublicaConfigEstado>;
}

export interface IJsonFaturacaoPublicaConfigEstado {
  conta: string;
  estado: EFEAEstado;
  modelo: number;
}
