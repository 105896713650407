import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MovimentosPendentesClientesModuleComponent} from './components/movimentos.pendentes.clientes.module.component';
import {ROLE} from '../../../services/role.const';

export const MODULE_LISTAGENS_MOVIMENTOS_PENDENTES_CLIENTES: IModuleDefinition = {
  name: 'listagensMovimentosPendentesClientes',
  state: {
    url: '/listagens/movimentospendentes/clientes',
    component: MovimentosPendentesClientesModuleComponent,
    data: {
      roles: [ROLE.ERP, ROLE.FINANCEIRO],
      sidebarTitle: 'global.text.movimentosPendentes'
    }
  }
};
