<div class="bancos-extrato-module-movab-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="title"></h5>
  </div>

  <div class="modal-body">
    <div class="bancos-extrato-module-movab-modal-heading entity-detail-form">
      <pl-group>
        <pl-group class="bancos-extrato-module-movab-modal-heading-conta">
          <label [translate]="labelNConta"></label>
          <edit>
            <entity-autocomplete [entity]="entityNameClifos" attrName="clifos" [model]="conta" (modelChange)="changedConta($event)" [output]="outputConta" [properties]="propertiesConta" plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group class="bancos-extrato-module-movab-modal-heading-valor">
          <label [translate]="'bancosextrato.movabmodal.fields.valorExtrato'"></label>
          <edit>
            <div class="form-control-align">{{ valorExtrato | cgcDecimal: precision }}</div>
          </edit>
        </pl-group>

        <pl-group class="bancos-extrato-module-movab-modal-heading-valor">
          <label [translate]="'bancosextrato.movabmodal.fields.valorSelecionado'"></label>
          <edit>
            <div class="form-control-align">{{ valorSelecionado | cgcDecimal: precision }}</div>
          </edit>
        </pl-group>
      </pl-group>
    </div>

    <hr />

    <dx-data-grid
      [cgDxDataGrid]="dataGrid"
      [cgDxDataGridInstanceName]="dataGridInstanceName"
      [dataSource]="dataGrid.dataSource"
      (onInitialized)="onDataGridInitialized($event)"
      (onCellClick)="onDataGridCellClick($event)"
      (onSaved)="onDataGridSaved()">
      <div *dxTemplate="let row of 'dataGridTemplateEditCellValorAReceber'">
        <pl-edit-number
          type="number"
          class="bancos-extrato-module-movab-modal-edit-valor-a-receber"
          [attrName]="'valorareceber' + row.rowIndex"
          inputClass="input-sm"
          [model]="row.data.valorAReceber"
          (modelChange)="changedValorAReceber($event, row.rowIndex)"
          [properties]="propertiesValorAReceber.get(row.data.extPocCabID)">
          <div *inputGroup="{klass: 'btn-xs btn-primary action-receber-tudo-linha', title: titleReceberTudo | translate}" (click)="receberTudoLinha(row.data)" data-capture-click>
            <i class="fa fa-fw fa-check" aria-hidden="true"></i>
          </div>
          <div *inputGroup="{klass: 'btn-xs btn-primary action-limpar-linha', title: 'bancosextrato.movabmodal.text.limparValor' | translate}" (click)="limparLinha(row.rowIndex)" data-capture-click>
            <i class="fa fa-fw fa-eraser" aria-hidden="true"></i>
          </div>
        </pl-edit-number>
      </div>

      <div *dxTemplate="let row of 'dataGridTemplateEditCellValorDesconto'">
        <pl-edit-number
          type="number"
          class="bancos-extrato-module-movab-modal-edit-valor-desconto"
          [attrName]="'valordesconto' + row.rowIndex"
          inputClass="input-sm"
          [model]="row.data.valorDesconto"
          (modelChange)="changedValorDesconto($event, row.rowIndex)"
          [properties]="propertiesValorDesconto.get(row.data.extPocCabID)">
        </pl-edit-number>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <div class="toolbar-template-date">
      <label><span [translate]="'bancosextrato.movabmodal.fields.data'"></span>:</label>
      <pl-edit type="date" [(model)]="dateRecPag"></pl-edit>
    </div>

    <pl-button klass="btn-primary action-submit" [disabled]="closeDisabled" [onClick]="close">
      <i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'bancosextrato.movabmodal.save'"></span>
    </pl-button>

    <pl-button klass="btn-light action-cancel" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
