import {ENTITY_NAME_ZONAS} from './zonas.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonZonas} from './jsonZonas.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_ZONAS: IEntityDefinition<IJsonZonas> = {
  name: ENTITY_NAME_ZONAS,
  roles: [ROLE.ERP, ROLE.ATIVOS, ROLE.RH],
  searchPlaceholder: 'zonas.pesquisa',
  metadata: {
    keyName: 'nZona',
    fields: [
      {name: 'nZona', type: 'cgsmallint', caption: 'zonas.fields.nZona', placeholder: 'zonas.fields.nZonaPlaceholder', validators: {required: true, min: 1}},
      {name: 'nome', caption: 'zonas.fields.nome', placeholder: 'zonas.fields.nomePlaceholder', validators: {required: true, maxlength: 50}}
    ],
    order: 'nome',
    searchFields: 'nZona,nome'
  },
  autocomplete: {
    rowTemplate: '{{nZona}} - {{nome}}',
    output: 'nome',
    searchFields: 'nZona,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.zonas'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.zonas'
      }
    }
  }
};
