import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ROLE} from '../../../../services/role.const';
import {MODULE_NAME_PCA_RH_SIMULACAO_VENCIMENTO} from '../../../colaboradores/simulavalorvencimento/simulaValorVencimento.module.interface';
import {SimulaValorVencimentoComponent} from '../../../colaboradores/simulavalorvencimento/components/simulaValorVencimento.module.component';

export const MODULE_PCA_RH_SIMULACAO_VENCIMENTO: IModuleDefinition = {
  name: MODULE_NAME_PCA_RH_SIMULACAO_VENCIMENTO,
  nameAlias: ['simulacao-vencimento'],
  state: {
    url: '/simulacaovencimento',
    component: SimulaValorVencimentoComponent,
    data: {
      roles: [ROLE.PCA, ROLE.RH]
    },
    params: {
      dgemp: {
        type: 'any',
        value: null
      }
    }
  }
};
