import {isEqual} from 'lodash-es';
import {fromEvent, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {Component, Injector, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {HookMatchCriteria, StateDeclaration, StateObject, Transition} from '@uirouter/core';
import {copy, IPlToolbarItem, IPlToolbarItemTemplateContext, IPlToolbarMenuItem, isBoolean, isFunction, isObject, KEYCODES, timeout, viewportScrollToElement} from 'pl-comps-angular';
import {AtivosAquisicaoContabService} from '../../../../ativos/ativosaquisicaocontab/ativosAquisicaoContab.module.service';
import {DocContabilidadeService} from '../doccontabilidade/docContabilidade.service';
import {DocsContabilidadeService} from '../../service/docsContabilidade.service';
import {EDocContabilidadeField, IDocContabilidadeCallback, IDocContabilidadeContabDigitalAttachment, TDocContabilidadeFocusField} from '../doccontabilidade/docContabilidade.interface';
import {EEntityStateDetailType} from '../../../../../../common/utils/entity.state.utils';
import {ENTITY_NAME_CLIENTES, ENTITY_NAME_FORNECEDORES} from '../../../../../entities/clifos/clifos.entity.interface';
import {EntityMaintenanceService} from '../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {ETipoCalculoDiferimento} from '../../../../diferimentos/jsonCalculoDiferimentos.interface';
import {hasAuthority} from '../../../../../../common/utils/roles.utils';
import {IApiRequestConfigWithBody} from '../../../../../services/api/api.service.interface';
import {IAppStatus} from '../../../../../services/app/app.service.interface';
import {IAquisicaoAdicionaDocumento, MODULE_NAME_ATIVOS_AQUISICAO_CONTAB} from '../../../../ativos/ativosaquisicaocontab/ativosAquisicaoContab.module.interface';
import {IContabDigitalDocViewerRecolhaSelectAttachmentOptions} from '../../../../../components/arquivodigital/contabilidade/docviewerrecolha/contabilidadedigital.docviewer.recolha.component.interface';
import {IDiferimentosSaved} from '../../../../diferimentos/diferimentos.module.interface';
import {IDocContabilidade, IDocsContabilidadeEntityService, IDocsContabilidadePostParams, TDocContabilidadeCommandResult} from '../../docsContabilidade.interface';
import {IDocContabilidadeConfigOptions, TConfigOptions} from '../../../../../services/config/options/config.options.service.interface';
import {IEntityMaintenanceInstance} from '../../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {IJsonAquisicaoContabResAddDoc} from '../../../../ativos/ativosaquisicaocontab/jsonAtivosAquisicaoContab.module.interface';
import {IJsonDocContabilidade} from '../../jsonDocContabilidade.interface';
import {IJsonDocOCR} from '../../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {IKeyBindingsHelperAction} from '../../../../../components/keybindings/helper/keybindings.helper.component.interface';
import {IModuleMaintenanceInstance} from '../../../../../components/entity/maintenance/module/module.maintenance.interface';
import {isTest, NAVBAR_TOP_OFFSET_WITH_COMPENSATION} from '../../../../../../config/constants';
import {MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID} from '../../../estatistica/extratosgrid/contabilidade.extratosGrid.module.interface';
import {ModuleMaintenanceService} from '../../../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloEntityDetailComponent} from '../../../../../components/module/entitydetail/module.entitydetail.component';
import moment from 'moment';
import {ROLE} from '../../../../../services/role.const';
import {STATE_NAME_DISCONNECTED} from '../../../../../states/account/disconnected/disconnected.state.interface';
import {STATE_NAME_LOGIN} from '../../../../../states/account/login/login.state.interface';
import {TDate} from '../../../../../../common/dates';
import {IModuleActionDefinition} from '../../../../../components/module/module.definition.interface';
import {DocContabilidadeCalcDiferimentosModalComponent} from '../doccontabilidade/modals/calcdiferimentos/docContabilidade.calcDiferimentos.modal.component';

const SAVE_PROMPT_IDENTIFIER = 'docscontabilidade-edit';
const TOOLBAR_GROUP_NAME = SAVE_PROMPT_IDENTIFIER;

@Component({
  selector: 'docscontabilidade-edit',
  templateUrl: './docsContabilidade.edit.component.html'
})
export class DocsContabilidadeEditComponent extends ModuloEntityDetailComponent<IJsonDocContabilidade, IDocsContabilidadeEntityService> implements OnInit, OnDestroy {
  @Input() public empresaTemContabilidadeDigital: boolean;
  @Input() public contabilidadeDigital: boolean;
  @Input() public cgStoreUrl: boolean;

  public readonly keyboardShortcuts: Array<IKeyBindingsHelperAction>;
  public readonly callbackDocContabilidade: IDocContabilidadeCallback;
  public docContabilidade: IJsonDocContabilidade;
  public editing: boolean;
  public savedDiferimentos: IDiferimentosSaved;
  public contabilidadeDigitalAttachment: IDocContabilidadeContabDigitalAttachment;
  public showLicenseNotActivatedCGOnError: boolean;
  public moduleActionDiferimentos: IModuleActionDefinition;

  private readonly _keyboardShortcutSaveAndAttach: IKeyBindingsHelperAction;
  private readonly _subscriptionOptions: Subscription;
  private readonly _savePromptInstanceId: string;
  private readonly _toolbarNDocumento: IPlToolbarItem;
  private readonly _toolbarFirstDocumento: IPlToolbarItem;
  private readonly _toolbarPreviousDocumento: IPlToolbarItem;
  private readonly _toolbarNextDocumento: IPlToolbarItem;
  private readonly _toolbarLastDocumento: IPlToolbarItem;
  private readonly _btnGoSearch: IPlToolbarItem;
  private readonly _btnSaveWithoutAttach: IPlToolbarItem;
  private readonly _btnRestore: IPlToolbarItem<IDocContabilidade>;
  private readonly _btnClear: IPlToolbarItem;
  private readonly _subscriptionOnDocumentKeydown: Subscription;
  private readonly _subscriptionWindowWidth: Subscription;

  private _inited: boolean;
  private _maintenanceAtivosAquisicaoContab: IModuleMaintenanceInstance;
  private _maintenanceInstanceClientes: IEntityMaintenanceInstance;
  private _maintenanceInstanceFornecedores: IEntityMaintenanceInstance;
  private _maintenanceExtratosGrid: IModuleMaintenanceInstance;
  private _templateKeyboardShortcuts: TemplateRef<IPlToolbarItemTemplateContext>;
  private _deRegisterOnStartFn: Function;
  private _optionRetainDescription: boolean;
  private _originalModel: IJsonDocContabilidade;
  private _modoCGOn: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _docsContabilidadeService: DocsContabilidadeService,
    private readonly _docContabilidadeService: DocContabilidadeService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _ativosAquisicaoContabService: AtivosAquisicaoContabService
  ) {
    super(_injector);
    this._keyboardShortcutSaveAndAttach = {description: 'docscontabilidade.keyboardShortcuts.saveAndAttach', key: KEYCODES.MULTIPLY};
    const textArrowLeft = this._translateService.instant('global.keyboard.arrowLeft');
    const textArrowRight = this._translateService.instant('global.keyboard.arrowRight');
    this.keyboardShortcuts = [
      {description: 'docscontabilidade.keyboardShortcuts.save', key: KEYCODES.ADD},
      this._keyboardShortcutSaveAndAttach,
      {description: 'docscontabilidade.keyboardShortcuts.toggleIva', key: KEYCODES.SUBTRACT},
      {description: 'docscontabilidade.keyboardShortcuts.obterSaldoConta', key: KEYCODES.S},
      {description: 'docscontabilidade.keyboardShortcuts.firstDocumento', combination: ['Ctrl', 'Shift', textArrowLeft]},
      {description: 'docscontabilidade.keyboardShortcuts.lastDocumento', combination: ['Ctrl', 'Shift', textArrowRight]},
      {description: 'docscontabilidade.keyboardShortcuts.previousDocumento', combination: ['Ctrl', textArrowLeft]},
      {description: 'docscontabilidade.keyboardShortcuts.nextDocumento', combination: ['Ctrl', textArrowRight]}
    ];
    this.callbackDocContabilidade = {};
    this.editing = false;
    this.showLicenseNotActivatedCGOnError = false;
    this._subscriptionOptions = this._docContabilidadeService.configOptionsDocContabilidade.options().subscribe((value: TConfigOptions<boolean, IDocContabilidadeConfigOptions>) => {
      // On first load `obtemDadosDocDigital` should always be true
      if (!isBoolean(this._optionRetainDescription) && !value.get('obtemDadosDocDigital').value) {
        this._docContabilidadeService.configOptionsDocContabilidade.setOption('obtemDadosDocDigital', true);
      }
      this._optionRetainDescription = value.get('retainDescription').value;
    });
    this._savePromptInstanceId = `${SAVE_PROMPT_IDENTIFIER}-${this._docContabilidadeService.nextSavePromptInstanceId()}`;
    this._toolbarNDocumento = {
      groupId: TOOLBAR_GROUP_NAME,
      id: 'titleNDocumento',
      type: 'title',
      order: 1,
      caption: '',
      visible: false,
      tooltip: {
        text: 'docscontabilidade.tooltipNDocumento',
        placement: 'bottom'
      }
    };
    this._btnGoSearch = {
      groupId: TOOLBAR_GROUP_NAME,
      id: 'golistmodule',
      type: 'button',
      order: 10,
      class: 'btn-primary',
      iconLeft: '<i class="fa fa-fw fa-search"></i>&nbsp;',
      caption: 'docscontabilidade.goSearch',
      click: () => this._stateService.go(this.states.list.name, {redirect: false})
    };
    this._btnSaveWithoutAttach = {
      groupId: TOOLBAR_GROUP_NAME,
      id: 'saveWithoutAttach',
      type: 'button',
      order: 111,
      class: 'btn-primary',
      iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>&nbsp;',
      caption: 'docscontabilidade.toolbar.saveWithoutAttach',
      visible: false,
      click: () => this.save(undefined, false)
    };
    this._btnClear = {
      groupId: TOOLBAR_GROUP_NAME,
      id: 'clear',
      type: 'button',
      order: 201,
      class: 'btn-primary',
      iconLeft: '<i class="fa fa-fw fa-trash-o"></i>&nbsp;',
      caption: 'docscontabilidade.clearForm',
      visible: false,
      click: () => this.callbackDocContabilidade.reset(this._originalModel)
    };
    this._btnRestore = {
      groupId: TOOLBAR_GROUP_NAME,
      id: 'restore',
      type: 'button',
      order: 203,
      class: 'btn-info',
      iconLeft: '<i class="fa fa-fw fa-undo"></i>&nbsp;',
      caption: 'entity.action.retrieveBackup',
      visible: false,
      click: (item: IPlToolbarItem<IDocContabilidade>) => {
        this._setDocContabilidade(item.data);
        this._btnRestore.visible = false;
      }
    };
    this._toolbarFirstDocumento = {
      id: 'navigation-first',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-angle-double-left"></i>',
      class: 'btn-primary',
      click: () => this._goToFirstDoc(),
      tooltip: {
        text: 'docscontabilidade.tooltipFirstDocumento',
        placement: 'bottom'
      }
    };
    this._toolbarPreviousDocumento = {
      id: 'navigation-previous',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-angle-left"></i>',
      class: 'btn-primary',
      click: () => this._goToPreviousDoc(),
      tooltip: {
        text: 'docscontabilidade.tooltipPreviousDocumento',
        placement: 'bottom'
      }
    };
    this._toolbarNextDocumento = {
      id: 'navigation-next',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-angle-right"></i>',
      class: 'btn-primary',
      click: () => this._goToNextDoc(),
      tooltip: {
        text: 'docscontabilidade.tooltipNextDocumento',
        placement: 'bottom'
      }
    };
    this._toolbarLastDocumento = {
      id: 'navigation-last',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-angle-double-right"></i>',
      class: 'btn-primary',
      click: () => this._goToLastDoc(),
      tooltip: {
        text: 'docscontabilidade.tooltipLastDocumento',
        placement: 'bottom'
      }
    };
    this._inited = false;
    this._modoCGOn = false;
    this._subscriptionOnDocumentKeydown = fromEvent<KeyboardEvent>(this._document, 'keydown', {passive: true, capture: true}).subscribe((event: KeyboardEvent) => {
      this._onDocumentKeydown(event);
    });
  }

  public ngOnInit(): void {
    this._inited = true;
    this.params = this._transition.params();
    this.id = this.params.id;
    if (this.id) {
      this.editing = true;
    } else {
      this._btnSaveWithoutAttach.visible = this.contabilidadeDigital;
    }
    if (this.model) {
      this._originalModel = this._normalizeDocContabilidade(this.model);
    }
    super.ngOnInit();
    this.docContabilidade = this.model;
    if (this.editing) {
      this._addLineAndFocus(this.docContabilidade);
    }

    this._keyboardShortcutSaveAndAttach.visible = this.contabilidadeDigital;
    this._evaluateMaintenanceModeFullscreen();
    this.moduleActionDiferimentos = {
      caption: this._translateService.instant('docscontabilidade.text.calculodiferimentos'),
      action: () => this._calculoDiferimentos(),
      disabled: !this.model.nDiario || !this.model.periodo
    };
    this._initToolbar();
    this.moduleActionDiferimentos.disabled = false;

    this._docContabilidadeService.listenForSaveEvent({
      identifier: this._savePromptInstanceId,
      callbackGetDoc: () => this.model,
      callbackOnSave: (attachDigitalDocs: boolean) => this.save(undefined, attachDigitalDocs),
      contabilidadeDigital: () => !this.editing && this.contabilidadeDigital,
      simulation: false
    });
    setTimeout(() => {
      this._registerOnStart();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscriptionWindowWidth) {
      this._subscriptionWindowWidth.unsubscribe();
    }
    this._subscriptionOnDocumentKeydown.unsubscribe();
    this._docContabilidadeService.clearForSaveEventListener(this._savePromptInstanceId).catch((reason: unknown) => {
      this._logger.error(reason);
    });
    this._deRegisterOnStart();
    this._subscriptionOptions.unsubscribe();
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    if (this._inited) {
      this._evaluateToolbar();
    }
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    return super.onUpdate(stateType).then(() => {
      let caption: string;
      switch (stateType) {
        case EEntityStateDetailType.NEW:
          caption = this._translateService.instant(`${this.entity.name}.title_new`);
          if (this._previousType && stateType !== this._previousType) {
            this.editing = false;
            this._btnSaveWithoutAttach.visible = this.contabilidadeDigital;
            if (isFunction(this.callbackDocContabilidade.resetAndInit)) {
              // Give time to apply 'editing' property change
              setTimeout(() => {
                this.callbackDocContabilidade.resetAndInit(undefined, true);
              });
            }
          }
          break;
        case EEntityStateDetailType.EDIT:
          const id = !this.editing ? '' : this.model.nDocumento;
          caption = this._translateService.instant(`${this.entity.name}.title_edit`, {id: id});
          break;
        case EEntityStateDetailType.DETAIL:
          break;
      }
      this.setCaption(caption);
    });
  }

  public save(config?: IApiRequestConfigWithBody<IJsonDocContabilidade>, attachDigitalDocs: boolean = true): Promise<IJsonDocContabilidade> {
    this.callbackDocContabilidade.clearErroInformativo();
    const saldado = this._docContabilidadeService.isDocumentoSaldado(this.model);
    if (!saldado) {
      const error = 'docscontabilidade.erros.notSaldado';
      this.callbackDocContabilidade.setErroInformativo(error);
      return Promise.reject(new Error(this._translateService.instant(error)));
    }
    let oldDescription = '';
    if (this._optionRetainDescription) {
      oldDescription = this.model.descricao;
    }
    const promise: Promise<IJsonDocContabilidade> = new Promise<IJsonDocContabilidade>((resolve, reject) => {
      this._btnRestore.disabled = true;
      this._deRegisterOnStart();
      const params: IDocsContabilidadePostParams = {anexarGDocId: undefined, anexarGDocFolderId: undefined, tipoDiferimento: ETipoCalculoDiferimento.NotDefined};
      if (this.contabilidadeDigital && attachDigitalDocs && isObject(this.contabilidadeDigitalAttachment)) {
        params.anexarGDocId = this.contabilidadeDigitalAttachment.gDocId;
        params.anexarGDocFolderId = this.contabilidadeDigitalAttachment.gDocFolderId;
      }
      if (this.savedDiferimentos) {
        params.tipoDiferimento = this.savedDiferimentos.tipodiferimento;
        this.model.dataIniDiferimento = this.savedDiferimentos.dataIniDiferimento;
        this.model.dataFimDiferimento = this.savedDiferimentos.dataFimDiferimento;
        this.model.tipoDataRefDiferimento = this.savedDiferimentos.tipoDataRefDiferimento;
      }
      this.callback
        .save({params: params, ...config})
        .then((docContabilidade: IDocContabilidade) => {
          this._docContabilidadeService.calculaTotais(docContabilidade);
          this._btnRestore.disabled = false;
          let promiseAtivos: Promise<unknown>;
          if (hasAuthority(this.session, ROLE.ATIVOS)) {
            promiseAtivos = this._docsContabilidadeService.patchDocumentoDeAtivos(docContabilidade).then((isDocAtivos: HttpResponse<boolean>) => {
              if (isDocAtivos.body) {
                return this._cgModalService.showOkCancel('global.text.confirmation', 'docscontabilidade.text.docativosconfirm').then(() => {
                  const aquisicaoAddDoc: IAquisicaoAdicionaDocumento = {
                    listaContas: [],
                    listaDocumentos: [],
                    listaDocumentosAdicionar: [{...docContabilidade}]
                  };
                  return this._ativosAquisicaoContabService.adicionarDocumento(aquisicaoAddDoc).then((contabAtivos: HttpResponse<IJsonAquisicaoContabResAddDoc>) => {
                    if (!this._maintenanceAtivosAquisicaoContab) {
                      this._maintenanceAtivosAquisicaoContab = this._moduleMaintenanceService.build(MODULE_NAME_ATIVOS_AQUISICAO_CONTAB);
                    }
                    return this._maintenanceAtivosAquisicaoContab.maintenance({params: {isfromconsistencia: true, doc: contabAtivos.body}});
                  });
                });
              }
              return Promise.resolve();
            });
          }
          Promise.resolve(promiseAtivos).finally(() => {
            this.savedDiferimentos = undefined;
            if (!this.editing) {
              let docOCRCabID: string;
              if (this.contabilidadeDigital) {
                const selectedAttachment: IJsonDocOCR = !attachDigitalDocs
                  ? this.callbackDocContabilidade.docViewer.getSelectedAttachment()
                  : this.callbackDocContabilidade.docViewer.getNextAttachment();
                if (selectedAttachment) {
                  docOCRCabID = selectedAttachment.cab.docOCRCabID;
                }
                docContabilidade.isUsingPreDefinido = false;
                docContabilidade.predefinido = undefined;
                this.callbackDocContabilidade.removePreDefinido();
              }
              this._docsContabilidadeService
                .novoDocumento(docContabilidade.periodo, docContabilidade.nDiario, docOCRCabID, docContabilidade)
                .then((docContabilidade1: IDocContabilidade) => {
                  this._setDocContabilidade(docContabilidade1);
                  this._setNDocumento();
                  if (this._optionRetainDescription) {
                    this.model.descricao = oldDescription;
                  }
                  if (!this.contabilidadeDigital) {
                    this.callbackDocContabilidade.prepareFocus('dataDoc');
                    resolve(this.docContabilidade);
                  } else {
                    // eslint-disable-next-line no-async-promise-executor
                    new Promise<void>(async (resolve1) => {
                      if (attachDigitalDocs) {
                        const attachmentOptions: IContabDigitalDocViewerRecolhaSelectAttachmentOptions = {
                          emitEvent: true,
                          emitEventWithDebounce: false,
                          doInitDocumento: false
                        };
                        await this.callbackDocContabilidade.docViewer.deleteSelectedAttachment(false, attachmentOptions);
                      }
                      this.callbackDocContabilidade.prepareFocus();
                      resolve1();
                    }).finally(() => {
                      resolve(this.docContabilidade);
                    });
                  }
                })
                .catch(reject);
            } else {
              this._setDocContabilidade(docContabilidade);
              if (this._optionRetainDescription) {
                this.model.descricao = oldDescription;
              }
              const field: TDocContabilidadeFocusField = !this.contabilidadeDigital ? 'dataDoc' : undefined;
              this.callbackDocContabilidade.prepareFocus(field);
              resolve(this.docContabilidade);
            }
          });
        })
        .catch(reject)
        .finally(() => {
          setTimeout(() => {
            const plAlertError: HTMLElement = this._element.querySelector<HTMLElement>('pl-alert');
            if (plAlertError) {
              viewportScrollToElement(plAlertError, undefined, NAVBAR_TOP_OFFSET_WITH_COMPENSATION);
            }
          });
        });
    });
    let disabled: boolean;
    if (!attachDigitalDocs) {
      this.btnSave.promise = promise;
      disabled = this._btnSaveWithoutAttach.disabled;
      this._btnSaveWithoutAttach.disabled = true;
    } else {
      this._btnSaveWithoutAttach.promise = promise;
      disabled = this.btnSave.disabled;
      this.btnSave.disabled = true;
    }
    this._subjectOnSave.next(promise);
    return promise.finally(() => {
      if (!attachDigitalDocs) {
        this._btnSaveWithoutAttach.disabled = disabled;
      } else {
        this.btnSave.disabled = disabled;
      }
      if (this.editing && this.model.isDocDiferimento && !this.btnSave.disabled) {
        // Timeout because plPromise would replace our disabled value
        timeout().then(() => {
          this.btnSave.disabled = true;
        });
      }
    });
  }

  public modelChanged(docContabilidade: IDocContabilidade): void {
    this.model = docContabilidade;
    if (this.model.nDocumento && !this._originalModel) {
      this._originalModel = this._normalizeDocContabilidade(this.model);
    }
    this._docContabilidadeService.backupDocContabilidade(this.model);
    this._setNDocumento();
  }

  public onChangedCalculouDiferimento(calculouDiferimento: boolean): void {
    if (!calculouDiferimento && this.savedDiferimentos) {
      this.savedDiferimentos = undefined;
    }
    if (this.editing) {
      this.btnSave.disabled = isObject(this.savedDiferimentos);
    }
  }

  @ViewChild('templateKeyboardShortcuts')
  public set templateKeyboardShortcuts(value: TemplateRef<IPlToolbarItemTemplateContext>) {
    this._templateKeyboardShortcuts = value;
    this.setModuleKeyboardShortcuts(this._templateKeyboardShortcuts);
  }

  protected _onConfigurationsChanged(): void {
    if (!this.configurations.empresa.verificacaoEfetuada && !this.hybridMode) {
      this._configService.checkConfiguracaoVerificada();
    }
    this.empresaTemContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
    this._modoCGOn = this.configurations.licenca.modoCGOn;
    this._evaluateMaintenanceModeFullscreen();
  }

  private _setDocContabilidade(value: IDocContabilidade): void {
    this.docContabilidade = value;
    this._originalModel = this._normalizeDocContabilidade(this.docContabilidade);
    if (this.model !== value) {
      this.model = value;
    }
  }

  private _initToolbar(): void {
    this.toolbar.removeGroupId(TOOLBAR_GROUP_NAME);

    const fromName = this._transition.from().name;
    const redirectedFrom = this._transition.redirectedFrom();
    const redirectedFromName = redirectedFrom?.to().name || '';
    this.btnBack.visible =
      fromName &&
      fromName !== this.states.list?.name &&
      fromName !== this.states.new?.name &&
      fromName !== this.states.detail?.name &&
      (!redirectedFromName || (redirectedFromName !== this.states.list?.name && redirectedFromName !== this.states.detail?.name));

    this.toolbar.addButton(this._toolbarNDocumento);
    this.btnSave.menu = [<IPlToolbarMenuItem>this._btnSaveWithoutAttach];
    this._btnClear.visible = this.type === EEntityStateDetailType.NEW;

    if (this.empresaTemContabilidadeDigital || (!this.maintenanceMode && this._modoCGOn)) {
      this.toolbar.addButton({
        groupId: TOOLBAR_GROUP_NAME,
        id: 'contabilidadeDigital',
        type: 'button',
        order: 11,
        class: !this.contabilidadeDigital ? 'btn-info' : 'btn-info active',
        iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>&nbsp;',
        caption: 'docscontabilidade.toolbar.contabilidadeDigital',
        click: (btnContabilidadeDigital: IPlToolbarItem) => {
          if (!this.empresaTemContabilidadeDigital && this._modoCGOn) {
            this.showLicenseNotActivatedCGOnError = true;
            return Promise.resolve();
          }
          this.contabilidadeDigital = !this.contabilidadeDigital;
          this._evaluateMaintenanceModeFullscreen();
          this.callbackDocContabilidade.prepareFocus();
          btnContabilidadeDigital.class = !this.contabilidadeDigital ? 'btn-info' : 'btn-info active';
          this._btnSaveWithoutAttach.visible = this.contabilidadeDigital;
          if (this.isMobile) {
            this.btnSave.type = !this.contabilidadeDigital ? 'button' : 'dropdown-split';
          }
          return this._docContabilidadeService.configOptionsDocContabilidade.setOption('seletorObtemDadosDocDigital', this.contabilidadeDigital);
        }
      });
    }

    if (!this.maintenanceMode) {
      this.toolbar.addButton(this._btnGoSearch);
      if (this.editing) {
        this.toolbar.addButton({
          groupId: TOOLBAR_GROUP_NAME,
          id: 'navigation',
          order: 2,
          type: 'button-group',
          items: [this._toolbarFirstDocumento, this._toolbarPreviousDocumento, this._toolbarNextDocumento, this._toolbarLastDocumento]
        });
      } else {
        this._appService
          .status()
          .pipe(take(1))
          .subscribe((value: IAppStatus) => {
            if (!value.refreshed) {
              return;
            }
            this._docContabilidadeService.getBackup().then((docContabilidade: IDocContabilidade) => {
              if (docContabilidade) {
                this._btnRestore.visible = true;
                this._btnRestore.data = docContabilidade;
              }
            });
          });
      }
    }

    this.btnDelete.click = () => this._deleteDocContabilidade();

    this._evaluateToolbar();
  }

  private _evaluateToolbar(): void {
    const moduleActions: Array<IModuleActionDefinition<unknown>> = [
      {
        caption: this._translateService.instant('entity.maintenance.headerMaintenance', {
          entityName: this._translateService.instant(`${ENTITY_NAME_CLIENTES}.title_plural`).toLowerCase()
        }),
        action: () => {
          if (!this._maintenanceInstanceClientes) {
            this._maintenanceInstanceClientes = this._entityMaintenanceService.build(ENTITY_NAME_CLIENTES);
          }
          return this._maintenanceInstanceClientes.maintenanceSelectAndEdit().then(() => undefined);
        }
      },
      {
        caption: this._translateService.instant('entity.maintenance.headerMaintenance', {
          entityName: this._translateService.instant(`${ENTITY_NAME_FORNECEDORES}.title_plural`).toLowerCase()
        }),
        action: () => {
          if (!this._maintenanceInstanceFornecedores) {
            this._maintenanceInstanceFornecedores = this._entityMaintenanceService.build(ENTITY_NAME_FORNECEDORES);
          }
          return this._maintenanceInstanceFornecedores.maintenanceSelectAndEdit().then(() => undefined);
        }
      },
      this.moduleActionDiferimentos,
      {
        caption: this._translateService.instant('docscontabilidade.text.extratosconta'),
        action: () => {
          if (!this._maintenanceExtratosGrid) {
            this._maintenanceExtratosGrid = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID);
          }
          return this._maintenanceExtratosGrid.maintenance().then(() => undefined);
        }
      }
    ];
    if (!this.isMobile) {
      this._btnGoSearch.caption = 'docscontabilidade.goSearch';
      this.btnSave.type = 'button';

      if (!this.maintenanceMode) {
        if (this.empresaTemContabilidadeDigital) {
          this.toolbar.addButton(this._btnSaveWithoutAttach);
        }

        this._btnClear.class = 'btn-primary';
        this._btnClear.iconLeft = '<i class="fa fa-fw fa-trash-o"></i>&nbsp;';
        if (!this.editing) {
          this.toolbar.addButton(this._btnClear);
        }
      }

      this._btnRestore.class = 'btn-info';
      this._btnRestore.iconLeft = '<i class="fa fa-fw fa-undo"></i>&nbsp;';
      this.toolbar.addButton(this._btnRestore);
    } else {
      this._btnGoSearch.caption = 'docscontabilidade.goSearchMobile';

      if (!this.maintenanceMode) {
        this._btnClear.class = undefined;
        this._btnClear.iconLeft = undefined;
        this.toolbar.removeButton(this._btnClear);

        if (this.contabilidadeDigital) {
          this.btnSave.type = 'dropdown-split';
        }
        this._btnSaveWithoutAttach.class = undefined;
        this.toolbar.removeButton(this._btnSaveWithoutAttach);
      }

      this._btnRestore.class = undefined;
      this._btnRestore.iconLeft = undefined;
      this.toolbar.removeButton(this._btnRestore);

      moduleActions.push(this._btnClear);
      moduleActions.push(this._btnRestore);
    }
    this.setModuleActions(moduleActions);
    this._evaluateToolbarBtnDelete();
  }

  private _evaluateToolbarBtnDelete(): void {
    this.btnDelete.visible = this.editing && this.model.soDeLeitura !== true;
  }

  private _evaluateMaintenanceModeFullscreen(): void {
    if (this.maintenanceMode) {
      this.setMaintenanceModeFullscreen(this.empresaTemContabilidadeDigital && this.contabilidadeDigital);
    }
  }

  private _setNDocumento(): void {
    if (this.editing || !this._toolbarNDocumento) {
      return;
    }
    const docContabilidade: IJsonDocContabilidade = this.model;
    this._toolbarNDocumento.caption = '';
    this._toolbarNDocumento.visible = Boolean(docContabilidade.nDocumento);
    if (this._toolbarNDocumento.visible) {
      this._toolbarNDocumento.caption = `<em>${docContabilidade.nDocumento}</em>`;
    }
  }

  private _setNDocumentoEditing(): void {
    if (!this.editing) {
      return;
    }
    let docContabilidade: IJsonDocContabilidade = this.docContabilidade;
    if (!docContabilidade?.extPocCabID) {
      docContabilidade = this.model;
    }
    const caption: string = this._defaultCaption(docContabilidade.nDocumento);
    this.setCaption(caption);
  }

  private async _deleteDocContabilidade(): Promise<void> {
    if (!this.editing || this.model.soDeLeitura) {
      return;
    }
    await this._docsContabilidadeService.deleteDocContabilidade(this.model);
    await this._stateService.reload();
  }

  private _goToFirstDoc(): Promise<void> {
    return this._navigate(() => this._docsContabilidadeService.firstDoc(this.model));
  }

  private _goToPreviousDoc(): Promise<void> {
    return this._navigate(() => this._docsContabilidadeService.previousDoc(this.model));
  }

  private _goToNextDoc(): Promise<void> {
    return this._navigate(() => this._docsContabilidadeService.nextDoc(this.model));
  }

  private _goToLastDoc(): Promise<void> {
    return this._navigate(() => this._docsContabilidadeService.lastDoc(this.model));
  }

  private _normalizeDocContabilidade(value: IDocContabilidade): IJsonDocContabilidade {
    const normalizedModel: IDocContabilidade = copy<IJsonDocContabilidade>(value);
    this._docContabilidadeService.normalizeDoc(normalizedModel);
    return normalizedModel;
  }

  private async _navigate(callback: () => TDocContabilidadeCommandResult): Promise<void> {
    if (!this.editing) {
      return;
    }
    await this._navigationSafeGuard();
    const newDocContabilidade: IDocContabilidade = await callback();
    this._addLineAndFocus(newDocContabilidade);
    this._setDocContabilidade(newDocContabilidade);
    this._setNDocumentoEditing();
    if (this.contabilidadeDigital) {
      await this.callbackDocContabilidade.docViewer.clear();
    }
    this.btnSave.disabled = this.docContabilidade.isDocDiferimento;
    this._evaluateToolbarBtnDelete();
    await this._stateService.go(this._transition.to(), {id: this.docContabilidade.extPocCabID});
  }

  private _navigationSafeGuard(): Promise<void> {
    if (!isTest()) {
      const normalizedCurrent: IJsonDocContabilidade = this._normalizeDocContabilidade(this.model);
      if ((!this._docContabilidadeService.isDocEmpty(this.model) && !isEqual(normalizedCurrent, this._originalModel)) || this.savedDiferimentos) {
        return this._cgModalService.showOkCancel('docscontabilidade.prompt.exitTitle', 'docscontabilidade.prompt.exitMessage').then(() => {
          if (this.savedDiferimentos) {
            this.savedDiferimentos = undefined;
          }
        });
      }
    }
    return Promise.resolve();
  }

  private _registerOnStart(): void {
    this._deRegisterOnStart();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_LOGIN && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFn = this._transitionService.onStart(criteria, () => this._navigationSafeGuard());
  }

  private _deRegisterOnStart(): void {
    if (this._deRegisterOnStartFn) {
      this._deRegisterOnStartFn();
      this._deRegisterOnStartFn = undefined;
    }
  }

  private _addLineAndFocus(docContabilidade: IJsonDocContabilidade): void {
    docContabilidade.linhas.push(this._docContabilidadeService.emptyLine(docContabilidade));
    setTimeout(() => {
      this.callbackDocContabilidade.focusFieldLinha(EDocContabilidadeField.CONTA_DEBITO, docContabilidade.linhas.length - 1);
    });
  }

  private _onDocumentKeydown(event: KeyboardEvent): void {
    if (this.editing) {
      let stopPropagation = false;
      switch (event.key) {
        case KEYCODES.LEFT:
          if (event.ctrlKey) {
            if (event.shiftKey) {
              this._toolbarFirstDocumento.promise = this._goToFirstDoc();
              stopPropagation = true;
            } else {
              this._toolbarPreviousDocumento.promise = this._goToPreviousDoc();
              stopPropagation = true;
            }
          }
          break;
        case KEYCODES.RIGHT:
          if (event.ctrlKey) {
            if (event.shiftKey) {
              this._toolbarLastDocumento.promise = this._goToLastDoc();
              stopPropagation = true;
            } else {
              this._toolbarNextDocumento.promise = this._goToNextDoc();
              stopPropagation = true;
            }
          }
          break;
      }
      if (stopPropagation) {
        event.stopPropagation();
      }
    }
  }

  private _calculoDiferimentos(): Promise<void> {
    return this._docsContabilidadeService.obterValorADiferirDoDocumento(this.model).then((response: HttpResponse<number>) => {
      const dataIni: TDate = moment(this.model.dataDoc);
      const dataFim: TDate = moment(this.model.dataDoc).add(1, 'year');

      const modalInstance = this._cgModalService.showVanilla(DocContabilidadeCalcDiferimentosModalComponent);
      const componentInstance: DocContabilidadeCalcDiferimentosModalComponent = modalInstance.componentInstance;
      componentInstance.diferimentosParams = {
        fromComercial: false,
        fromContabilidade: true,
        dataIniDiferimento: dataIni,
        dataFimDiferimento: dataFim,
        valor: response.body,
        docBalanceado: this.model.totalDebitoGeral - this.model.totalCreditoGeral === 0
      };
      return modalInstance.result.then((savedDiferimentos: IDiferimentosSaved) => {
        this.savedDiferimentos = savedDiferimentos;
      });
    });
  }
}
