import {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthService} from '../../../../services/auth/auth.service';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {ContabilidadeDigitalService} from '../../../../services/contabilidadedigital/contabilidadedigital.service';
import {EContabilidadeDigitalActivateLicenseType} from './contabilidadedigital.activatelicensemodal.component.interface';
import {IJsonContabDigitalDadosLicenca} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';

@Component({
  selector: 'contabilidadedigital-activate-license-modal',
  templateUrl: './contabilidadedigital.activatelicensemodal.component.html'
})
export class ContabilidadeDigitalActivateLicenseModalComponent extends CGModalComponent<void> implements OnInit, OnDestroy {
  @Input() public type: EContabilidadeDigitalActivateLicenseType;
  @Input() public dadosLicenca: IJsonContabDigitalDadosLicenca;
  public nEmpresa: string;
  public nomeEmpresa: string;
  public textTitle: string;
  public textLimitedReached: string;
  public textLicense1: string;
  public textLicense2: string;
  public textDadosLicenca: string;

  private readonly _subscriptionSession: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _authService: AuthService,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService
  ) {
    super(_injector);
    this.type = EContabilidadeDigitalActivateLicenseType.ContabilidadeDigital;
    this._subscriptionSession = this._authService.identityAsObservable().subscribe((session: TUserSession) => {
      this.nEmpresa = session?.erp.nEmpresa;
      this.nomeEmpresa = session?.erp.nomeEmpresa;
    });
  }

  public ngOnInit(): void {
    this.dadosLicenca = {
      nEmpresasTotal: 0,
      nEmpresasConsumidas: 0,
      nEmpresasPorConsumir: 0,
      ...this.dadosLicenca
    };
    this.textTitle = `arquivodigital.activatelicensemodal.types.${this.type}.title`;
    this.textLimitedReached = `arquivodigital.activatelicensemodal.types.${this.type}.limitReached`;
    this.textLicense1 = `arquivodigital.activatelicensemodal.types.${this.type}.license1`;
    this.textLicense2 = `arquivodigital.activatelicensemodal.types.${this.type}.license2`;
    this.textDadosLicenca = `arquivodigital.activatelicensemodal.types.${this.type}.dadosLicenca`;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionSession.unsubscribe();
  }

  public close(): Promise<void> {
    this.disableClose();
    let promise: Promise<unknown>;
    switch (this.type) {
      case EContabilidadeDigitalActivateLicenseType.ContabilidadeDigital:
        promise = this._contabilidadeDigitalService.activateContabilidadeDigital();
        break;
      case EContabilidadeDigitalActivateLicenseType.ClientConnect:
        promise = this._contabilidadeDigitalService.activateClientConnect();
        break;
      default:
        promise = Promise.resolve();
        break;
    }
    return promise
      .then(() => {
        this.enableClose();
        super.close();
      })
      .catch((reason: HttpErrorResponse) => {
        this._logger.error(reason);
        this.enableClose();
      });
  }
}
