<div class="modal-wrapper">
  <div *ngIf="title" class="modal-header">
    <h5 class="mt-0 modal-title" [innerHTML]="title | cgcTranslate"></h5>
    <ng-container *ngTemplateOutlet="templateHeader; context: modalOptions.templateRefHeaderContext"></ng-container>
    <button type="button" class="btn-close" (click)="dismiss()"></button>
  </div>

  <div class="modal-body">
    <div *ngIf="message" [innerHTML]="message | cgcTranslate"></div>
    <ng-container *ngTemplateOutlet="templateContent; context: modalOptions.templateRefContentContext"></ng-container>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn action-close" plAutoFocus [ngClass]="btnOkCssClass" (click)="close()">
      <i [cgcIcon]="btnOkIcon" [normalizeSize]="false"></i>&nbsp;<span [plTranslate]="btnOkText || localeOk"></span>
    </button>

    <button type="button" class="btn action-dismiss" [ngClass]="btnCancelCssClass" (click)="dismiss()">
      <i [cgcIcon]="btnCancelIcon" [normalizeSize]="false"></i>&nbsp;<span [plTranslate]="btnCancelText || localeCancel"></span>
    </button>
  </div>
</div>
