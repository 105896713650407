import {CGModalComponent} from '../../../../cg/modal/cgmodal.component';
import {Component, Injector, Input} from '@angular/core';
import {IDevExpressDataGrid} from '../../../../devexpress/datagrid/devexpress.datagrid.interface';
import {EGrupoDoc} from '../../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {IJsonDocComercial} from '../../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {PlAlertService} from 'pl-comps-angular';
import {TClifos} from '../../../../../entities/clifos/clifos.entity.interface';

@Component({
  selector: 'encomendas-satisfazer-clifo-modal',
  templateUrl: './encomendas.satisfazerclifo.modal.component.html'
})
export class EncomendasSatisfazerClifoModalComponent extends CGModalComponent<void | IJsonDocComercial> {
  @Input() public grupo: EGrupoDoc;
  @Input() public grupoClifos: TClifos;
  @Input() public nConta: string;
  @Input() public nomeConta: string;
  @Input() public callFromFaturacao: boolean;
  @Input() public doc: IJsonDocComercial;

  public readonly dataGridDefinition: IDevExpressDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
  }

  public onStartProcessarLotes(): void {
    this.disableClose();
  }

  public onErrorProcessarLotes(): void {
    this.enableClose();
  }

  public onProcessarFaccbIdSucess(doc: IJsonDocComercial): Promise<void> {
    if (!doc) {
      this.enableClose();
      this._plAlertService.error('encomendas.errors.processamentonaoefetuado');
      return Promise.reject(new Error('encomendas.errors.processamentonaoefetuado'));
    }
    this.close(doc);
    return Promise.resolve();
  }
}
