import {IDatas} from '../../home/home.module.interface';
import {IJsonConfigAbdes, IJsonConfigEstrutura, IJsonConfigTipo, IJsonConfigTipoProcessamento} from '../../../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import {IJsonDGEMP, IJsonDGEMPDetail, IJsonDGEMPMarcacoes} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHServico} from '../../../../entities/prhservicos/jsonPRHServico.entity.interface';

export interface IColBoard {
  codServico: number;
  codEmpSelected: number;
  servico: IJsonPRHServico;
  colaboradoresList: Array<IColaborador>;
  colaboradorDetail: IColaboradorDetail;
  dgempMarcacoes: IJsonDGEMPMarcacoes;
  numDiasFerias: number;
  abonos: IColAbonos;
  ferias: IColFerias;
  faltas: IColFaltas;
}

export interface IColaborador extends IJsonDGEMP {
  selected?: boolean;
}

export interface IColaboradorDetail extends IJsonDGEMPDetail {
  nome: string;
  servicoPRH: string;
}

export interface IFaltas extends IDatas {
  tipoFalta: string;
  tipoProcessamento: string;
  abono: string;
  motivo: string;
}

export interface IAbonos extends IDatas {
  tipoProcessamento: string;
  abono: string;
  qtd: number;
  motivo: string;
}

export interface IColFerias {
  feriasMarcadas: Array<IDatas>;
  feriasRejeitadas: Array<IDatas>;
  feriasIntegradas: Array<IDatas>;
}

export interface IColAbonos {
  abonosMarcadas: Array<IAbonos>;
  abonosRejeitadas: Array<IAbonos>;
  abonosIntegradas: Array<IAbonos>;
}

export interface IColFaltas {
  faltasMarcadas: Array<IFaltas>;
  faltasRejeitadas: Array<IFaltas>;
  faltasIntegradas: Array<IFaltas>;
}

export interface IColConfigStructureMap {
  tipos: Map<number, IJsonConfigTipo>;
  tiposProcessamento: Map<number, IJsonConfigTipoProcessamento>;
  abdes: Map<string, IJsonConfigAbdes>;
}

export function generateColConfigStructureMap(configStructure: IJsonConfigEstrutura): IColConfigStructureMap {
  const result: IColConfigStructureMap = {
    tipos: new Map<number, IJsonConfigTipo>(),
    tiposProcessamento: new Map<number, IJsonConfigTipoProcessamento>(),
    abdes: new Map<string, IJsonConfigAbdes>()
  };
  for (const tipo of configStructure.listaTipo) {
    result.tipos.set(tipo.codTipo, tipo);
  }
  for (const tipoProcessamento of configStructure.listaTipoProcessamento) {
    result.tiposProcessamento.set(tipoProcessamento.codTipoProcessamento, tipoProcessamento);
  }
  for (const abdes of configStructure.listaAbDes) {
    result.abdes.set(abdes.nCodAbDes, abdes);
  }
  return result;
}
