<cg-entity-filter
  [entity]="entity"
  [collapsed]="filterCollapsed"
  [selectedFilter]="filterField"
  [filterModel]="filterModel"
  (collapsedChange)="filterCollapsed = $event; filterCollapsedChange.emit(filterCollapsed)"
  (evtFiltered)="filtered($event)">
</cg-entity-filter>

<entity-list-toolbar [toolbarInstanceId]="toolbarInstanceId"></entity-list-toolbar>

<ng-container [ngSwitch]="customDataGridTemplate">
  <dx-data-grid
    *ngSwitchCase="false"
    [cgDxDataGrid]="dataGrid"
    [cgDxDataGridColumns]="dataGridColumns"
    [cgDxDataGridInstanceName]="entityName"
    [cgDxDataGridState]="dataGridState"
    [dataSource]="dataSource"
    (onInitialized)="onDataGridInitialized($event)"
    (onContentReady)="onDataGridContentReady($event)"
    (onCellClick)="onDataGridCellClick($event)"
    (onCellDblClick)="onDataGridCellDblClick($event)"
    (onCellPrepared)="onDataGridCellPrepared($event)"
    (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (onRowPrepared)="onDataGridRowPrepared($event)"
    (onSaving)="onDataGridSaving($event)"
    (onEditorPreparing)="onDataGridEditorPreparing($event)"
    (onEditingStart)="onDataGridEditingStart($event)"
    (onSelectionChanged)="onDataGridSelectionChanged($event)"
    (cgDxDataGridStateLoad)="onDataGridStateLoad($event)"
    (cgDxDataGridStateChange)="onDataGridStateChanged($event)"
    (cgDxDataGridStateCleared)="onDataGridStateCleared()">
    <dxo-paging [(pageIndex)]="pageIndex" [(pageSize)]="pageSize"></dxo-paging>

    <dxo-master-detail [enabled]="hasDetail" [template]="dataGridTemplateMasterDetail"></dxo-master-detail>

    <ng-container *ngFor="let entityListDataGridToolbar of templateEntityListDataGridToolbar">
      <div *dxTemplate="let toolbarItem of entityListDataGridToolbar.item.template">
        <ng-container *ngTemplateOutlet="entityListDataGridToolbar.templateRef; context: {$implicit: toolbarItem}"></ng-container>
      </div>
    </ng-container>

    <div *dxTemplate="let item of 'entityListHeaderActions'">
      <ng-container *ngTemplateOutlet="templateEntityListHeaderActions; context: {$implicit: item}"></ng-container>
    </div>

    <div *dxTemplate="let item of 'entityListItemActions'">
      <ng-container *ngTemplateOutlet="templateEntityListItemActions; context: {$implicit: item}"></ng-container>
    </div>

    <div *dxTemplate="let item of 'entityListItemMasterDetail'">
      <ng-container *ngTemplateOutlet="templateEntityListItemDetail; context: {$implicit: item}"></ng-container>
    </div>
  </dx-data-grid>

  <div class="entity-list-custom-data-grid-template" *ngSwitchCase="true">
    <ng-container *ngTemplateOutlet="templateEntityListDataGrid"></ng-container>
  </div>
</ng-container>
