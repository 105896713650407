<div class="adiantamentos-auto-config-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'adiantamentos.modals.autoConfig.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-nav-wizard [definition]="definition" [onFinalize]="close" [beforeStepChange]="fnBeforeStepChange" (modelChange)="selectedStep = $event">
      <pl-nav-wizard-step caption="adiantamentos.modals.autoConfig.steps.intro.title" icon="fa-home">
        <div *plNavWizardStepContent>
          <fieldset>
            <legend [translate]="'adiantamentos.modals.autoConfig.steps.intro.desc'"></legend>
            <p><span [translate]="'adiantamentos.modals.autoConfig.steps.familiaBase.assistente1'"></span>:</p>
            <p class="ms-2" [translate]="'adiantamentos.modals.autoConfig.steps.familiaBase.assistente2'"></p>
            <p class="ms-2" [translate]="'adiantamentos.modals.autoConfig.steps.familiaBase.assistente3'"></p>
            <p class="ms-2" [translate]="'adiantamentos.modals.autoConfig.steps.familiaBase.assistente4'"></p>
          </fieldset>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step caption="adiantamentos.modals.autoConfig.steps.familiaBase.title" icon="fa-list">
        <div *plNavWizardStepContent>
          <fieldset>
            <legend [translate]="'adiantamentos.modals.autoConfig.steps.familiaBase.desc'"></legend>

            <pl-group>
              <label [translate]="'adiantamentos.modals.familia'"></label>
              <edit>
                <entity-autocomplete
                  entity="familias"
                  attrName="familias"
                  [model]="configAdiantamentos"
                  (selectedKeyChange)="configAdiantamentos.familiaBase = $event"
                  (evtSelectedDescriptionChanged)="configAdiantamentos.nomeFamiliaBase = $event"
                  [properties]="{validators: {required: {value: true}}}"
                  [fieldsMap]="{nFamilia: 'familiaBase', nome: 'nomeFamiliaBase'}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </fieldset>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step caption="adiantamentos.modals.autoConfig.steps.familiaArtigos.title" icon="fa-list">
        <div *plNavWizardStepContent>
          <fieldset>
            <legend [translate]="'adiantamentos.modals.autoConfig.steps.familiaArtigos.desc'"></legend>

            <pl-group>
              <label *ngIf="configAdiantamentos.infoFamilia">
                <pl-tooltip class="tooltip-info-label" [config]="{text: configAdiantamentos.infoFamilia, placement: ['bottom', 'right']}">
                  <div *plTooltipContent>
                    <span [translate]="'adiantamentos.modals.familia'"></span>
                    <i class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                  </div>
                </pl-tooltip>
              </label>

              <label *ngIf="!configAdiantamentos.infoFamilia" [translate]="'adiantamentos.modals.familia'"></label>
              <edit>
                <div class="form-control-align">{{ configAdiantamentos.familia }} - {{ configAdiantamentos.nomeFamilia }}</div>
              </edit>
            </pl-group>

            <pl-group>
              <label *ngIf="configAdiantamentos.infoArtigoAdiantamento">
                <pl-tooltip class="tooltip-info-label" [config]="{text: configAdiantamentos.infoArtigoAdiantamento, placement: ['bottom', 'right']}">
                  <div *plTooltipContent>
                    <span [translate]="'adiantamentos.modals.nArtigoAdiantamento'"></span>
                    <i class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                  </div>
                </pl-tooltip>
              </label>

              <label *ngIf="!configAdiantamentos.infoArtigoAdiantamento" [translate]="'adiantamentos.modals.nArtigoAdiantamento'"></label>
              <edit>
                <entity-autocomplete
                  entity="artigos"
                  attrName="artigo"
                  [model]="configAdiantamentos"
                  (modelChange)="artigoChanged($event)"
                  (selectedKeyChange)="configAdiantamentos.nArtigoAdiantamento = $event"
                  (evtSelectedDescriptionChanged)="configAdiantamentos.nomeNArtigoAdiantamento = $event"
                  [properties]="{validators: {required: {value: true}}}"
                  [fieldsMap]="{nArtigo: 'nArtigoAdiantamento', nome: 'nomeNArtigoAdiantamento'}">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label *ngIf="configAdiantamentos.infoArtigoRegAdiantamento">
                <pl-tooltip class="tooltip-info-label" [config]="{text: configAdiantamentos.infoArtigoRegAdiantamento, placement: ['bottom', 'right']}">
                  <div *plTooltipContent>
                    <span [translate]="'adiantamentos.modals.nArtigoRegAdiantamento'"></span>
                    <i class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                  </div>
                </pl-tooltip>
              </label>

              <label *ngIf="!configAdiantamentos.infoArtigoRegAdiantamento" [translate]="'adiantamentos.modals.nArtigoRegAdiantamento'"></label>
              <edit>
                <entity-autocomplete
                  entity="artigos"
                  attrName="artigoReg"
                  [model]="configAdiantamentos"
                  (selectedKeyChange)="configAdiantamentos.nArtigoRegAdiantamento = $event"
                  (evtSelectedDescriptionChanged)="configAdiantamentos.nomeNArtigoRegAdiantamento = $event"
                  [properties]="{validators: {required: {value: true}}}"
                  [fieldsMap]="{nArtigo: 'nArtigoRegAdiantamento', nome: 'nomeNArtigoRegAdiantamento'}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </fieldset>
        </div>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
