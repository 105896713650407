import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonPRHDocsPlata} from './jsonPRHDocsPlata.entity.interface';
import {Observable} from 'rxjs';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface IPRHDocsPlata extends IJsonPRHDocsPlata {
  url: string;
  servico?: string;
  papel?: string;
}

export interface IPRHDocsPlataEntityService extends IEntityService<IJsonPRHDocsPlata> {
  uploadDocUrl(codPrhDocsPlata: number): Observable<string>;

  uploadDoc(codPrhDocsPlata: number, file: Blob | File, params?: string, filename?: string): TServiceResponse<IJsonPRHDocsPlata>;

  downloadDocUrl(codPrhDocsPlata: number): Observable<string>;

  downloadDoc(codPrhDocsPlata: number): TServiceResponse<Blob>;
}

export interface IPRHDocsPlataEntity extends IEntityDefinition<IJsonPRHDocsPlata, IPRHDocsPlataEntityService> {}

export const ENTITY_NAME_PRH_DOCS_PLATA = 'prhdocsplata';
