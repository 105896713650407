import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {
  IJsonAcrescimoEncargoComFerias,
  IJsonAcrescimoEncargoComFeriasAnuais,
  IJsonAcrescimoEncargoComFeriasCabConfig,
  IJsonAcrescimoEncargoComFeriasGerarMultiEmpresas,
  IJsonAcrescimoEncargoComFeriasLin,
  IJsonAcrescimoEncargoComFeriasLinMulti,
  IJsonAcrescimosEncFeriasDocContabIntegrado
} from './jsonAcrescimosEncargosFerias.module.interface';
import {IJsonBoolean} from '../../../common/interfaces/json';
import {Observable} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../common/utils/utils';
import {IAcrescimoEncFeriasPrintHeader} from './acrescimosEncargosFerias.module.interface';

@Injectable({
  providedIn: 'root'
})
export class AcrescimosEncargosFeriasService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/acrescimosencargoscomferias`;
  }

  public getEncargosList(anoEncargo: number, nEmpresa: string): TServiceResponse<IJsonAcrescimoEncargoComFerias> {
    return this._apiService.get<IJsonAcrescimoEncargoComFerias>({
      url: this._path,
      params: {anoencargo: anoEncargo, nempresa: nEmpresa}
    });
  }

  public existeEncargosNoAno(anoEncargo: number, nEmpresa: string): TServiceResponse<IJsonBoolean> {
    return this._apiService.get<IJsonBoolean>({
      url: `${this._path}/existeencargoano`,
      params: {anoencargo: anoEncargo, nempresa: nEmpresa}
    });
  }

  public encargosAnuais(anoEncargo: number, nEmpresa: string): TServiceResponse<IJsonAcrescimoEncargoComFeriasAnuais> {
    return this._apiService.get<IJsonAcrescimoEncargoComFeriasAnuais>({
      url: `${this._path}/encargosanuais`,
      params: {anoencargo: anoEncargo, nempresa: nEmpresa}
    });
  }

  public docsContabInteg(anoEncargo: number, nEmpresa: string): TServiceResponse<Array<IJsonAcrescimosEncFeriasDocContabIntegrado>> {
    return this._apiService.get<Array<IJsonAcrescimosEncFeriasDocContabIntegrado>>({
      url: `${this._path}/docscontabinteg`,
      params: {anoencargo: anoEncargo, nempresa: nEmpresa}
    });
  }

  public atualizarAcrescimosEncargosComFerias(anoEncargo: number, nEmpresa: string): TServiceResponse<IJsonAcrescimoEncargoComFerias> {
    return this._apiService.get<IJsonAcrescimoEncargoComFerias>({
      url: `${this._path}/atualizaracrescimosencargoscomferias`,
      params: {anoencargo: anoEncargo, nempresa: nEmpresa}
    });
  }

  public saveAcrescimosEncargosComFerias(anoEncargo: number, nEmpresa: string, linhasAlteradas: Array<IJsonAcrescimoEncargoComFeriasLin>): TServiceResponse<IJsonAcrescimoEncargoComFerias> {
    return this._apiService.post<IJsonAcrescimoEncargoComFerias, Array<IJsonAcrescimoEncargoComFeriasLin>>({
      url: this._path,
      body: linhasAlteradas,
      params: {anoencargo: anoEncargo, nempresa: nEmpresa}
    });
  }

  public lancaContabilidadeAnual(anoEncargo: number, nEmpresa: string): TServiceResponse<IJsonAcrescimoEncargoComFerias> {
    return this._apiService.post<IJsonAcrescimoEncargoComFerias>({
      url: `${this._path}/lancadocumentos`,
      params: {anoencargo: anoEncargo, nempresa: nEmpresa}
    });
  }

  public editarCabecalhoConfigs(nEmpresa: string, cabConfig: IJsonAcrescimoEncargoComFeriasCabConfig): TServiceResponse<void> {
    return this._apiService.put<void, IJsonAcrescimoEncargoComFeriasCabConfig>({
      url: `${this._path}/editarcabecalhoconfigs`,
      body: cabConfig,
      params: {
        nempresa: nEmpresa
      }
    });
  }

  public removeEncargos(anoEncargo: number, nEmpresa: string): TServiceResponse<void> {
    return this._apiService.delete<void>({
      url: `${this._path}/removeencargos`,
      params: {anoencargo: anoEncargo, nempresa: nEmpresa}
    });
  }

  public apagaLancamentoContabilidade(anoEncargo: number, nEmpresa: string): TServiceResponse<IJsonAcrescimoEncargoComFerias> {
    return this._apiService.delete<IJsonAcrescimoEncargoComFerias>({
      url: `${this._path}/apagalancamento`,
      params: {anoencargo: anoEncargo, nempresa: nEmpresa}
    });
  }

  public apagarAcrescimosEncargosComFerias(anoEncargo: number, nEmpresa: string): TServiceResponse<IJsonAcrescimoEncargoComFerias> {
    return this._apiService.delete<IJsonAcrescimoEncargoComFerias>({
      url: `${this._path}/apagaencargos`,
      params: {anoencargo: anoEncargo, nempresa: nEmpresa}
    });
  }

  public acrescimoEncargoCabConfig(nEmpresa: string, anoEncargo: number): TServiceResponse<IJsonAcrescimoEncargoComFeriasCabConfig> {
    return this._apiService.get<IJsonAcrescimoEncargoComFeriasCabConfig>({
      url: `${this._path}/encargoscabconfig`,
      params: {nempresa: nEmpresa, anoencargo: anoEncargo}
    });
  }

  public getEncargosListMulti(anoEncargo: number): TServiceResponse<Array<IJsonAcrescimoEncargoComFeriasLinMulti>> {
    return this._apiService.get<Array<IJsonAcrescimoEncargoComFeriasLinMulti>>({
      url: `${this._path}/multi`,
      params: {anoencargo: anoEncargo}
    });
  }

  public getEmpresasEncargosListMulti(anoEncargo: number): TServiceResponse<Array<IJsonAcrescimoEncargoComFeriasGerarMultiEmpresas>> {
    return this._apiService.get<Array<IJsonAcrescimoEncargoComFeriasGerarMultiEmpresas>>({
      url: `${this._path}/multi/empresas`,
      params: {anoencargo: anoEncargo}
    });
  }

  public gerarEncargosListMultiEmpresas(empresas: Array<IJsonAcrescimoEncargoComFeriasGerarMultiEmpresas>): TServiceResponse<Array<IJsonAcrescimoEncargoComFeriasLinMulti>> {
    return this._apiService.post<Array<IJsonAcrescimoEncargoComFeriasLinMulti>, Array<IJsonAcrescimoEncargoComFeriasGerarMultiEmpresas>>({
      url: `${this._path}/multi/empresas`,
      body: empresas
    });
  }

  public lancarDocumentoMulti(linhas: Array<IJsonAcrescimoEncargoComFeriasLinMulti>): TServiceResponse<Array<IJsonAcrescimoEncargoComFeriasLinMulti>> {
    return this._apiService.post<Array<IJsonAcrescimoEncargoComFeriasLinMulti>>({
      url: `${this._path}/multi/lancadocs`,
      body: linhas
    });
  }

  public verificarInconsistenciasMulti(linhas: Array<IJsonAcrescimoEncargoComFeriasLinMulti>): TServiceResponse<Array<IJsonAcrescimoEncargoComFeriasLinMulti>> {
    return this._apiService.post<Array<IJsonAcrescimoEncargoComFeriasLinMulti>>({
      url: `${this._path}/multi/verificainconsistencias`,
      body: linhas
    });
  }

  public apagaEncargosMulti(linhas: Array<IJsonAcrescimoEncargoComFeriasLinMulti>): TServiceResponse<Array<IJsonAcrescimoEncargoComFeriasLinMulti>> {
    return this._apiService.delete<Array<IJsonAcrescimoEncargoComFeriasLinMulti>>({
      url: `${this._path}/multi/apagaencargos`,
      body: linhas
    });
  }

  public apagarDocumentoMulti(linhas: Array<IJsonAcrescimoEncargoComFeriasLinMulti>): TServiceResponse<Array<IJsonAcrescimoEncargoComFeriasLinMulti>> {
    return this._apiService.delete<Array<IJsonAcrescimoEncargoComFeriasLinMulti>>({
      url: `${this._path}/multi/apagalancamento`,
      body: linhas
    });
  }

  public getListagemAcrescimosEncFeriasUrl(header: IAcrescimoEncFeriasPrintHeader, reportName: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report`, {
      nempresa: header.nEmpresa,
      anoencargo: header.anoEncargo,
      decodemp: header.deCodEmp,
      atecodemp: header.ateCodEmp,
      deccusto: header.deCCusto,
      ateccusto: header.ateCCusto,
      dereparticao: header.deReparticao,
      atereparticao: header.ateReparticao,
      reportname: reportName
    });
  }
}
