import {Transition} from '@uirouter/core';
import {NoAuthorityModuleComponent} from './components/noauthority.module.component';
import {INoAuthorityModuleStateParams, MODULE_NAME_NO_AUTHORITY} from './noauthority.module.interface';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {STATE_NAME_PORTAL} from '../../services/portals/portals.service.interface';

export const MODULE_NO_AUTHORITY: IModuleDefinition = {
  name: MODULE_NAME_NO_AUTHORITY,
  state: {
    url: '/noauthority',
    component: NoAuthorityModuleComponent,
    data: {
      invisible: true
    },
    params: {
      pageTitle: {
        type: 'string',
        value: ''
      },
      roles: {
        type: 'any',
        value: null
      }
    },
    redirectTo: (transition: Transition): string => {
      const params: INoAuthorityModuleStateParams = <INoAuthorityModuleStateParams>transition.params();
      if (!params.pageTitle?.length || !params.roles?.length) {
        return STATE_NAME_PORTAL;
      }
      return undefined;
    }
  }
};
