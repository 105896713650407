<div *ngIf="!options.readonly" #elementHost class="pl-edit-timepicker" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" (click)="stopCloseDropdown()">
  <div class="input-form" [ngClass]="{'input-group pl-input-group': options.raw === false, 'input-focus': inputFocused, 'input-disabled': inputElement.disabled}">
    <input
      #inputElement
      type="time"
      class="form-control pl-edit-timepicker-input"
      autocapitalize="off"
      autocomplete="off"
      editEventsHandlerIgnore="keyup,focus,blur"
      [formControl]="formControl"
      [attr.name]="attrName"
      [attr.placeholder]="placeholder"
      [class.raw]="options.raw"
      [ngClass]="inputClass"
      [editEventsHandler]="options.events"
      [editEventsHandlerValue]="value"
      (dblclick)="showDropdown()"
      (keyup)="onInputKeyUp($event)"
      (focus)="onInputFocus($event)"
      (blur)="onInputBlur($event)" />

    <button *ngIf="showClear" type="button" class="btn hollow-form" tabindex="-1" (click)="clearViewValue()">
      <i class="pl-icon-close" aria-hidden="true"></i>
    </button>

    <ng-container *ngIf="hasGroup && options.raw !== true">
      <button
        *ngFor="let itemGroup of itemGroups"
        #buttonElement
        type="button"
        class="btn"
        plDelegateEventDownwards
        [ngClass]="itemGroup.klass"
        [class.raw]="options.raw || itemGroup.raw"
        [disabled]="itemGroup.disabled ?? options.disabled ?? false"
        [attr.tabindex]="itemGroup.tabindex"
        [attr.title]="itemGroup.title"
        [plPromise]="itemGroup.promise">
        <ng-container *ngTemplateOutlet="itemGroup.templateRef; context: {button: buttonElement, isMouseIn: isMouseIn}"></ng-container>
      </button>
    </ng-container>

    <button *ngIf="!options.raw && options.showToggle !== false" type="button" class="btn btn-show-timer" [disabled]="options.disabled" (click)="toggleDropdown()">
      <i class="fa fa-clock-o fa-fw"></i>
    </button>
  </div>

  <div
    *ngIf="dropdownOpen"
    #elementDropdown
    class="pl-edit-timepicker-dropdown"
    plLifeCycle
    (evtInit)="initDropdown(elementHost, elementDropdown)"
    (mouseenter)="onWrapperMouseEnter()"
    (mouseleave)="onWrapperMouseLeave()"
    (click)="stopCloseDropdown()">
    <div class="controls">
      <ngb-timepicker #ngbTimepicker [formControl]="timeControl" [meridian]="false" [seconds]="true"></ngb-timepicker>

      <div class="controls-footer">
        <div class="btn-group">
          <button type="button" class="btn btn-primary btn-sm pl-timepicker-action-now" (click)="nowClick()">{{ locales.now }}</button>
          <button type="button" class="btn btn-danger btn-sm pl-timepicker-action-clear" (click)="clearClick()">{{ locales.clear }}</button>
        </div>

        <button #btnCloseTimer type="button" class="btn btn-light btn-sm float-end pl-timepicker-action-close" (click)="closeDropdown()">{{ locales.close }}</button>
      </div>
    </div>
  </div>
</div>

<pl-messages *ngIf="validate" [instance]="self" [formControlInstance]="formControl" [modelValue]="value" [validate]="validate" [validators]="validators" [ngFormInstance]="ngFormInstance">
</pl-messages>

<span *ngIf="options.readonly" class="form-control-align">{{ formControl.value }}</span>
