import {firstValueFrom} from 'rxjs';
import {toJson} from 'pl-comps-angular';
import {buildSessionUrlWithParams} from '../../../common/utils/utils';
import {ENTITY_NAME_PRH_TAREFAS_CAB, IPRHTarefasCabEntity} from './pRHTarefasCab.entity.interface';
import {IJsonPRHAbonos} from '../../interfaces/jsonPRHAbonos.interface';
import {IJsonPRHFaltas} from '../../interfaces/jsonPRHFaltas.interface';
import {IJsonPRHFerias} from '../../interfaces/jsonPRHFerias.interface';
import {IJsonPRHTarefaCab} from './jsonPRHTarefaCab.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_PRH_TAREFAS_CAB: IPRHTarefasCabEntity = {
  name: ENTITY_NAME_PRH_TAREFAS_CAB,
  asModule: false,
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  metadata: {
    keyName: 'idTarefasCab',
    descriptionName: 'tipo',
    fields: [
      {
        name: 'idTarefasCab',
        width: '120px',
        caption: 'prhtarefascab.fields.idTarefasCab',
        placeholder: 'prhtarefascab.fields.idTarefasCabPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'tipo',
        caption: 'prhtarefascab.fields.tipo',
        placeholder: 'prhtarefascab.fields.tipoPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'codEmp',
        caption: 'prhtarefascab.fields.codEmp',
        placeholder: 'prhtarefascab.fields.codEmpPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'idLog',
        caption: 'prhtarefascab.fields.idLog',
        placeholder: 'prhtarefascab.fields.idLogPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'posicao',
        type: 'boolean',
        caption: 'prhtarefascab.fields.posicao',
        placeholder: 'prhtarefascab.fields.posicaoPlaceholder',
        validators: {required: true}
      },
      {
        name: 'dataAprovacao',
        type: 'date',
        caption: 'prhtarefascab.fields.dataAprovacao',
        placeholder: 'prhtarefascab.fields.dataAprovacaoPlaceholder',
        validators: {required: true}
      }
    ],
    order: 'idTarefasCab',
    searchFields: 'idTarefasCab,tipo'
  },
  autocomplete: {
    rowTemplate: '{{idTarefasCab}} - {{tipo}}',
    output: 'tipo',
    searchFields: 'idTarefasCab,tipo'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  },
  service: function () {
    this.mudarPosicao = (idTarefaCab: number, codEmpLanca: number, motivo: string) => {
      return this.put<IJsonPRHTarefaCab, void>({
        url: `${idTarefaCab}/mudarposicao`,
        params: {codemplanca: codEmpLanca, motivo: motivo}
      });
    };

    this.retrocedePosicao = (idTarefaCab: number, codEmpLanca: number, motivo: string) => {
      return this.put<IJsonPRHTarefaCab, void>({
        url: `${idTarefaCab}/retrocedeposicao`,
        params: {codemplanca: codEmpLanca, motivo: motivo}
      });
    };

    this.rejeitar = (idTarefaCab: number, codEmpLanca: number, motivo: string) => {
      return this.put<IJsonPRHTarefaCab, void>({
        url: `${idTarefaCab}/rejeitar`,
        params: {codemplanca: codEmpLanca, motivo: motivo}
      });
    };

    this.updateAbonos = (idTarefaCab: number, abonos: Array<IJsonPRHAbonos>) => {
      return this.put<void, Array<IJsonPRHAbonos>>({
        url: `${idTarefaCab}/actualizalinhasabono`,
        body: abonos
      });
    };

    this.updateFaltas = (idTarefaCab: number, faltas: Array<IJsonPRHFaltas>) => {
      return this.put<void, Array<IJsonPRHFaltas>>({
        url: `${idTarefaCab}/actualizalinhasfaltas`,
        body: faltas
      });
    };

    this.updateFerias = (idTarefaCab: number, ferias: Array<IJsonPRHFerias>) => {
      return this.put<void, Array<IJsonPRHFerias>>({
        url: `${idTarefaCab}/actualizalinhasferias`,
        body: ferias
      });
    };

    this.uploadDoc = async (idTarefaCab: number, file: Blob, filename?: string) => {
      const url: string = await this.uploadDocUrl(idTarefaCab, filename);
      const formData: FormData = new FormData();
      formData.append('file', file, filename);
      return this.apiService.upload<IJsonPRHTarefaCab>({
        url: url,
        body: formData,
        params: {filename: filename}
      });
    };

    this.uploadDocUrl = (idTarefaCab: number, filename?: string) => {
      return firstValueFrom(buildSessionUrlWithParams(`${this.entityUrl()}/${idTarefaCab}/uploaddoc`, {filename: filename}));
    };

    this.uploadDocTarefas = async (idsTarefasCab: Array<number>, file: Blob, filename?: string) => {
      const url: string = await this.uploadDocTarefasUrl(filename);
      const formData: FormData = new FormData();
      formData.append('data', toJson(idsTarefasCab));
      formData.append('file', file, filename);
      return this.apiService.upload<void>({
        url: url,
        body: formData,
        params: {filename: filename}
      });
    };

    this.uploadDocTarefasUrl = (filename?: string) => {
      return firstValueFrom(buildSessionUrlWithParams(`${this.entityUrl()}/uploaddoc`, {filename: filename}));
    };

    this.downloadDoc = (idTarefaCab: number) => {
      const url: string = this.downloadDocUrl(idTarefaCab);
      return this.apiService.get<Blob>({url: url, responseType: 'blob'});
    };

    this.downloadDocUrl = (idTarefaCab: number) => {
      return `${this.entityUrl()}/${idTarefaCab}/downloaddoc`;
    };

    this.deleteTarefaDoc = (idTarefaCab: number) => {
      return this.delete({id: `${idTarefaCab}/doc`});
    };
  }
};
