export enum ERendiTipoDeclaracao {
  None,
  Modelo10,
  Modelo22,
  Modelo30,
  Modelo39
}

export interface IJsonRENDI {
  tipRendiment: string;
  nome: string;
  tipRendimentID: number;
  tipoDeclaracao: ERendiTipoDeclaracao;
  tipoDeclaracaoStr: string;
}

export interface IJsonDeclaracao {
  cod: ERendiTipoDeclaracao;
  descricao: string;
}
