import {merge} from 'lodash-es';
import {Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {Directive, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {AsyncValidatorFn, FormControl, ValidatorFn} from '@angular/forms';
import type {IPlEditComponentOptionsInput} from '../../component/edit.component.interface';
import {isDefinedNotNull, isFunction, isNumber, isObject, timeout} from '../../../common/utilities/utilities';
import {PlEditBaseComponent} from '../edit.base.component';
import {PlTranslateService} from '../../../translate/translate.service';
import {skipIf} from '../../../common/extensions/operators/skipif.operator';

@Directive()
export abstract class PlEditInputComponent<T, S extends IPlEditComponentOptionsInput<T> = IPlEditComponentOptionsInput<T>> extends PlEditBaseComponent<T, S> implements OnInit, OnDestroy {
  @Input() public placeholder: string;
  @Output() public readonly evtInputValueChanged: EventEmitter<string>;

  public readonly self: PlEditInputComponent<T, S>;
  public isMouseIn: boolean;
  public inputFocused: boolean;
  public showClear: boolean;

  protected readonly _renderer: Renderer2;
  protected readonly _plTranslateService: PlTranslateService;
  protected _subscriptionValueChanges: Subscription;
  protected _subscriptionStatusChanges: Subscription;

  private _formControl: FormControl<T>;
  private _valueChangesPrevented: boolean;

  protected constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._renderer = this._injector.get<Renderer2>(Renderer2);
    this._plTranslateService = this._injector.get<PlTranslateService>(PlTranslateService);
    this.evtInputValueChanged = new EventEmitter<string>();
    this.self = this;
    this.isMouseIn = false;
    this.inputFocused = false;
    this.showClear = false;
    this._defaultOptions = Object.freeze<S>(
      merge({}, this._defaultOptions, {
        disallowInput: false,
        raw: false
      })
    );
    this.allowValueChanges();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.evaluateValidate();
    this._setPlaceholder();
    this._formControl = this.generateFormControl();
    this.configureFormControl();
    if (this._plEdit) {
      this._plEdit.addControl(this.attrName, this._formControl);
    } else if (this._plGroup) {
      this._plGroup.addControl(this.attrName, this._formControl);
    }
    this._subscriptionValueChanges = this._observeValueChanges().subscribe((value: T) => {
      this.inputValueChanged(value);
    });
    this._subscriptionStatusChanges = this._formControl.statusChanges.subscribe(() => {
      this._statusChanged();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscriptionValueChanges) {
      this._subscriptionValueChanges.unsubscribe();
    }
    if (this._subscriptionStatusChanges) {
      this._subscriptionStatusChanges.unsubscribe();
    }
  }

  public updateComponent(properties: S): void {
    super.updateComponent(properties);
    if (properties && properties !== this.options) {
      const oldValidate: boolean = this.validate;
      this.evaluateValidate();
      if (this.validate || this.validate !== oldValidate) {
        this.runValidators();
      } else {
        this.setHasError(false);
      }
      this.configureFormControl();
      this._checkShowClear();
    }
  }

  public updateValue(value: T): void {
    super.updateValue(value);
    this._checkShowClear();
    this.preventValueChanges();
    this.viewValue = this.value;
    this.allowValueChanges();
  }

  public render(value: T = this.value): Promise<void> {
    return super.render(value).then(() => {
      this.runValidators();
    });
  }

  public inputValueChanged(value: T): void {
    this.evtInputValueChanged.emit(<any>value);
    this.render(value);
  }

  public debounceAfter(): number {
    let debounceAfter = 0;
    if (isObject(this.modelOptions) && this.modelOptions.debounce) {
      debounceAfter = this.modelOptions.debounce;
    }
    if (isObject(this.options.modelOptions) && this.options.modelOptions.debounce) {
      debounceAfter = this.options.modelOptions.debounce;
    }
    return debounceAfter;
  }

  public generateFormControl(): FormControl<T> {
    this._checkShowClear();
    return new FormControl<T>(this.value, {
      updateOn: this.options.modelOptions.updateOn || 'change'
    });
  }

  public configureFormControl(value: FormControl<T> = this._formControl): void {
    if (!value) {
      return;
    }
    if (value.enabled && (this.options.disabled || this.options.disallowInput)) {
      this.preventValueChanges();
      value.disable();
      this.allowValueChanges();
    } else if (value.disabled && !this.options.disabled && !this.options.disallowInput) {
      this.preventValueChanges();
      value.enable();
      this.allowValueChanges();
    }
  }

  public clearViewValue(): Promise<void> {
    return timeout().then(() => {
      this.value = undefined;
      this.showClear = false;
      this.preventValueChanges();
      this.viewValue = undefined;
      this.allowValueChanges();
      return this.render();
    });
  }

  public onMouseEnter(): void {
    this.isMouseIn = true;
  }

  public onMouseLeave(): void {
    this.isMouseIn = false;
  }

  public onInputFocus(event: FocusEvent): void {
    this.inputFocused = true;
    if (this.options.events && isFunction(this.options.events.focus)) {
      this.options.events.focus(this.value, event);
    }
  }

  public onInputBlur(event: FocusEvent): void {
    this.inputFocused = false;
    if (this.options.events && isFunction(this.options.events.blur)) {
      this.options.events.blur(this.value, event);
    }
  }

  public setHasError(hasError: boolean): void {
    if (this._plEdit) {
      this._plEdit.setHasError(hasError);
    } else if (this._plGroup) {
      this._plGroup.setHasError(hasError);
    }
  }

  public setValidators(validators: ValidatorFn | Array<ValidatorFn>): void {
    this._formControl.setValidators(validators);
    this.runValidators();
  }

  public setAsyncValidators(validators: AsyncValidatorFn | Array<AsyncValidatorFn>): void {
    this._formControl.setAsyncValidators(validators);
    this.runValidators();
  }

  public runValidators(): void {
    this.preventValueChanges();
    this._formControl.updateValueAndValidity();
    this.allowValueChanges();
  }

  public preventValueChanges(): void {
    this._valueChangesPrevented = true;
  }

  public allowValueChanges(): void {
    this._valueChangesPrevented = false;
  }

  public evaluateValidate(): void {
    this.validate = this.options.validate !== false && Boolean(this.options.readonly) === false && Boolean(this.options.disabled) === false && this.hasValidators;
  }

  public get formControl(): FormControl<T> {
    return this._formControl;
  }

  public get valueChangesPrevented(): boolean {
    return this._valueChangesPrevented;
  }

  public get viewValue(): any {
    return this._formControl.value;
  }

  public set viewValue(value: any) {
    this._formControl.setValue(value);
  }

  protected _observeValueChanges(): Observable<T> {
    let observer: Observable<T> = this._formControl.valueChanges
      .pipe(distinctUntilChanged())
      .pipe(
        tap((value: T) => {
          this._checkShowClear(value);
        })
      )
      .pipe(
        skipIf(() => {
          return this.valueChangesPrevented;
        })
      );
    const debounceAfter = this.debounceAfter();
    if (debounceAfter) {
      observer = observer.pipe(debounceTime(debounceAfter));
    }
    return observer;
  }

  protected _statusChanged(): void {
    if (this.validate) {
      this.setHasError(this._formControl.invalid);
    } else {
      this.setHasError(false);
    }
  }

  protected _checkShowClear(value: unknown = this.value): void {
    this.showClear =
      isDefinedNotNull(value) &&
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      (String(value).length > 0 || isNumber(value)) &&
      this.options.readonly !== true &&
      this.options.disabled !== true &&
      this.options.disallowInput !== true &&
      this.options.raw !== true &&
      this.options.disallowClear !== true;
  }

  protected _setPlaceholder(): void {
    const placeholder = this.placeholder ? this.placeholder : this.options.placeholder ? this.options.placeholder : '';
    this.placeholder = this.options.raw ? '' : this._plTranslateService.translate(placeholder);
  }
}
