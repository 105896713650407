import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ERadix, IPlUploadCallback, isDefinedNotNull, isObject, isString, PlAlertService, transferArrayItem} from 'pl-comps-angular';
import {
  IJsonMapaCreatePagamManual,
  IJsonMapaEditPagamManual,
  IJsonMapaPagamEmpresaPagamentoItem,
  IJsonMapaPagamEmpresaSimpleItem,
  IJsonMapaPagamLoadResults,
  IJsonMapaPagamPagManuaisItem,
  IMapaPagamentoModalResult,
  IMapaPagamentoResponsavel,
  IMapaPagamManualMesesModel
} from '../../mapaPagamentos.module.interface';
import {MapaPagamentosService} from '../../mapaPagamentos.module.service';
import {monthName} from '../../../../../../common/dates';
import moment from 'moment';
import {TServiceResponse} from '../../../../../services/api/api.service.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {MapaPagamentosApagarPromptModalComponent} from '../apagar/mapaPagamentos.apagar.prompt.modal.component';
import {EMPTY_GUID} from '../../../../../../config/constants';
import {MapaPagamentosDocumentosModalComponent} from '../documentos/mapaPagamentos.documentos.modal.component';

@Component({
  selector: 'module-mapa-pagamentos-pagamento-modal',
  templateUrl: './mapaPagamentos.pagamento.modal.component.html'
})
export class MapaPagamentosPagamentoModalComponent extends CGModalComponent<IMapaPagamentoModalResult> implements OnInit {
  @Input() public sourceResponsaveis: Array<IMapaPagamentoResponsavel>;
  @Input() public storeModePublic: boolean;
  @Input() public empresasList: Array<IJsonMapaPagamEmpresaSimpleItem>;
  @Input() public nEmpresa: string;
  @Input() public ano: number;
  @Input() public mes: number;
  @Input() public nomeEmpresa: string;
  @Input() public pagamento: IJsonMapaPagamEmpresaPagamentoItem;
  @Input() public onEditPagMan: boolean;

  public model: IJsonMapaEditPagamManual | IJsonMapaCreatePagamManual;
  public modelMeses: IMapaPagamManualMesesModel;
  public promise: Promise<unknown>;
  public mesesSource: Array<{month: number; name: string}>;
  public paramMesNome: string;
  public modalTitle: string;
  public empresaTop: string;
  public isEditMode: boolean;
  public empresasSel: Array<IJsonMapaPagamEmpresaSimpleItem>;
  public todasEmpresasFlag: boolean;
  public todosMesesFlag: boolean;
  public duracaoAnos: number;
  public duracaoAnosSource: Array<number>;
  public usersTemplate: string;
  public uploadUrl: string;
  public readonly msEmpresasTemplate = '{{nEmpresa}} - {{nomeEmpresa}}';
  public readonly plUploadCallback: IPlUploadCallback;
  public haveAttachment: boolean;
  public pagManSource: Array<IJsonMapaPagamPagManuaisItem>;
  public selectedPagMan: IJsonMapaPagamPagManuaisItem;
  public anosSource: Array<number>;
  public pagManYear: number;

  private _oldName: string;
  private _allEmpresas: Array<IJsonMapaPagamEmpresaSimpleItem>;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _mapaPagamentosService: MapaPagamentosService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.plUploadCallback = {};
    this._oldName = '';
    this.paramMesNome = '';
    this.modalTitle = '';
    this.empresasList = [];
    this.empresasSel = [];
    this.todasEmpresasFlag = true;
    this.todosMesesFlag = true;
    this.duracaoAnos = 1;
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    this.duracaoAnosSource = [1, 2, 3];
    this.haveAttachment = false;
    this.model = {
      ano: moment().year(),
      descricao: '',
      nResponsavel: undefined,
      nome: '',
      meses: [],
      dadosPagamento: {
        refPag: '',
        dataLimitePag: moment().endOf('month'),
        montante: 0,
        pago: false
      },
      anos: [],
      mes: moment().month() + 1,
      gDocId: EMPTY_GUID
    };
    this.mesesSource = [];
    this.modelMeses = {};
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    for (let i = 1; i <= 12; i++) {
      this.modelMeses[i] = {checked: false};
      this.mesesSource.push({
        name: monthName(i - 1),
        month: i
      });
    }
    this.uploadUrl = '';
    this.isEditMode = false;
    this.pagamento = undefined;
    this.onEditPagMan = false;
    this.pagManSource = [];
    this.selectedPagMan = undefined;
    this.anosSource = [];
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    for (let i = moment().subtract(3, 'year').year(); i <= moment().year(); i++) {
      this.anosSource.push(i);
    }
    this.pagManYear = moment().year();
    this._allEmpresas = [];
  }

  public ngOnInit(): void {
    this._allEmpresas = [...this.empresasList];
    this.usersTemplate = !this.storeModePublic ? '{{nResponsavel}} - {{nomeResponsavel}}' : '{{nomeResponsavel}}';
    this.empresaTop = `${this.nEmpresa} - ${this.nomeEmpresa}`;
    this.pagManYear = this.ano;
    if (!this.onEditPagMan) {
      this.isEditMode = isDefinedNotNull(this.pagamento);
    } else {
      this._loadPagManuais();
      this.todasEmpresasFlag = false;
      this.todosMesesFlag = false;
    }
    this.empresasSel = [];
    if (this.isEditMode) {
      this.modalTitle = 'mapapagam.texts.editarPagamento';

      this.model = {
        mes: this.mes,
        ano: this.ano,
        dadosPagamento: {
          refPag: '',
          dataLimitePag: moment().endOf('month'),
          montante: 0,
          pago: false
        },
        descricao: '',
        nome: '',
        nResponsavel: 0,
        gDocId: EMPTY_GUID
      };

      this.paramMesNome = monthName(this.mes - 1);
      this.model.dadosPagamento.dataLimitePag = moment()
        .month(this.mes - 1)
        .endOf('month');

      this._mapaPagamentosService.getAttachDocumentUrl(this.nEmpresa, this.pagamento.pagamentoGUID, this.ano, this.mes).subscribe((value: string) => {
        this.uploadUrl = value;
      });
    } else {
      this.modalTitle = this.onEditPagMan ? 'mapapagam.texts.editarPagamento' : 'mapapagam.texts.criarPagamento';
      this.model = {
        dadosPagamento: {
          refPag: '',
          dataLimitePag: moment().endOf('month'),
          montante: 0,
          pago: false
        },
        descricao: '',
        nome: '',
        nResponsavel: undefined,
        anos: [],
        meses: [],
        empresas: []
      };
    }

    if (isDefinedNotNull(this.pagamento)) {
      this.promise = this._mapaPagamentosService.getPagamentoManual(this.nEmpresa, this.pagamento.pagamentoGUID, this.ano, this.mes).then((response) => {
        this.model = response.body;
        this.haveAttachment = this.model.gDocId !== EMPTY_GUID;
        if (this.model.nResponsavel < 1) {
          this.model.nResponsavel = undefined;
        }
        this._oldName = this.model.nome;
      });
    }
  }

  public promptDelete(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(MapaPagamentosApagarPromptModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
    const componentInstance: MapaPagamentosApagarPromptModalComponent = modalInstance.componentInstance;
    componentInstance.nEmpresa = this.nEmpresa;
    componentInstance.ano = this.ano;
    componentInstance.mes = this.mes;
    componentInstance.pagamentoGUID = this.pagamento.pagamentoGUID;
    return modalInstance.result.then(() => {
      this.close({results: undefined, oldPagName: this.model.nome, newPagName: this.model.nome, isDelete: true});
    });
  }

  public userSelect(user: string | IMapaPagamentoResponsavel): void {
    let nResponsavel: number;
    let nomeResponsavel: string;
    if (isString(user)) {
      nResponsavel = Number(user);
      if (Number.isNaN(nResponsavel)) {
        nResponsavel = -1;
      } else {
        const responsavel: IMapaPagamentoResponsavel = this.sourceResponsaveis.find((item: IMapaPagamentoResponsavel) => item.nResponsavel === nResponsavel);
        if (responsavel) {
          user = responsavel;
        }
      }
    }
    if (isObject(user)) {
      user = <IMapaPagamentoResponsavel>user;
      nResponsavel = user.nResponsavel;
      nomeResponsavel = user.nomeResponsavel;
    }

    this.model.nResponsavel = nResponsavel;
    this.model.nomeResponsavel = nomeResponsavel;
  }

  public selectedPagManChange(item: IJsonMapaPagamPagManuaisItem): void {
    this.selectedPagMan = item;
    this.model.nome = '';
    this.empresasList = [];
    this.empresasSel = [];
    if (isDefinedNotNull(this.selectedPagMan)) {
      this.empresasList = [...this._allEmpresas];
      this.model.nome = this.selectedPagMan.nomePagamento;

      this.selectedPagMan.empresas.forEach((nEmpresa) => {
        const index = this.empresasList.findIndex((empresa) => empresa.nEmpresa === nEmpresa);
        if (index > -1) {
          transferArrayItem(this.empresasList, this.empresasSel, index, this.empresasSel.length);
        }
      });

      for (const mes of Object.keys(this.modelMeses)) {
        this.modelMeses[mes].checked = false;
      }

      this.selectedPagMan.meses.forEach((month) => {
        this.modelMeses[month].checked = true;
      });
    }
  }

  public pagManYearChanged(item: number): void {
    this.pagManYear = item;
    this.selectedPagManChange(undefined);
    this._loadPagManuais();
  }

  public removerAnexo(): void {
    (<IJsonMapaEditPagamManual>this.model).gDocId = EMPTY_GUID;
    this.haveAttachment = false;
  }

  public readonly fnVerAnexo: () => Promise<unknown> = () => this._verAnexo();

  public readonly fnSaveModal = (): Promise<unknown> => this._saveModal();

  private _verAnexo(): Promise<unknown> {
    const modalInstance = this._cgModalService.showVanilla(MapaPagamentosDocumentosModalComponent, {size: 'xl', backdrop: 'static', keyboard: false});
    const componentInstance: MapaPagamentosDocumentosModalComponent = modalInstance.componentInstance;
    componentInstance.nEmpresa = this.nEmpresa;
    componentInstance.pagamentoGUID = this.pagamento.pagamentoGUID;
    componentInstance.pagamentoNome = this.pagamento.nome;
    componentInstance.impostoEnum = this.pagamento.impostoEnum;
    componentInstance.documentos = this.pagamento.documentos;
    componentInstance.ano = this.ano;
    componentInstance.mes = this.mes;
    return modalInstance.result;
  }

  private _saveModal(): Promise<unknown> {
    if (this.model.nome.length === 0) {
      this._plAlertService.error('mapapagam.messages.temQuePreencherNomePagamento');
      return undefined;
    }

    if (!this.isEditMode) {
      (<IJsonMapaCreatePagamManual>this.model).meses = [];
      (<IJsonMapaCreatePagamManual>this.model).empresas = [];

      if (!this.todosMesesFlag) {
        for (const month of Object.keys(this.modelMeses)) {
          if (this.modelMeses[month].checked) {
            (<IJsonMapaCreatePagamManual>this.model).meses.push(parseInt(month, ERadix.Decimal));
          }
        }

        if ((<IJsonMapaCreatePagamManual>this.model).meses.length === 0) {
          this._plAlertService.error('mapapagam.messages.temQueSelecionarPeloMenosUmMes');
          return undefined;
        }
      }

      if (!this.todasEmpresasFlag) {
        (<IJsonMapaCreatePagamManual>this.model).empresas = this.empresasSel.map((item) => item.nEmpresa);

        if ((<IJsonMapaCreatePagamManual>this.model).empresas.length === 0) {
          this._plAlertService.error('mapapagam.messages.temSeleccionarEmpresa');
          return undefined;
        }
      }

      (<IJsonMapaCreatePagamManual>this.model).anos = [];
      for (let i = 0; i < this.duracaoAnos; i++) {
        (<IJsonMapaCreatePagamManual>this.model).anos.push(moment().add(i, 'year').year());
      }
    }

    if (this.onEditPagMan) {
      return this._mapaPagamentosService.savePagMan(this.pagManYear, this.model.nome, (<IJsonMapaCreatePagamManual>this.model).empresas, (<IJsonMapaCreatePagamManual>this.model).meses).then(() => {
        this._plAlertService.success('mapapagam.messages.pagManGuardadoSucesso');
        this.close();
      });
    }

    let promise: TServiceResponse<IJsonMapaPagamLoadResults>;
    if (this.isEditMode) {
      promise = this._mapaPagamentosService.editPagamentoManual(this.nEmpresa, this.pagamento.pagamentoGUID, this.ano, this.mes, <IJsonMapaEditPagamManual>this.model);
    } else {
      this._oldName = this.model.nome;
      promise = this._mapaPagamentosService.createPagamentoManual(<IJsonMapaCreatePagamManual>this.model);
    }

    return promise.then((response) => {
      if (this.isEditMode && this.plUploadCallback.getQueuedFiles().length) {
        this.plUploadCallback.uploadAll().then(() => {
          this.close({results: response.body, oldPagName: this._oldName, newPagName: this.model.nome, isDelete: false});
        });
      } else {
        this.close({results: response.body, oldPagName: this._oldName, newPagName: this.model.nome, isDelete: false});
      }
    });
  }

  private _loadPagManuais(): void {
    this.promise = this._mapaPagamentosService.getPagMan(this.pagManYear).then((response) => {
      this.pagManSource = response.body.data;
    });
  }
}
