import moment from 'moment';
import {Workbook, Worksheet} from 'exceljs';
import 'jspdf-autotable';
import {exportPivotGrid} from 'devextreme/excel_exporter';
import {TranslateService} from '@ngx-translate/core';
import {downloadStream, isEmpty, isFunction, isString} from 'pl-comps-angular';
import {IDevExpressPivotGridExportExcelProps} from './devexpress.pivotgrid.export.interface';
import {IDevExpressPivotGridEventOnExporting} from '../../datagrid/events/devexpress.datagrid.events.interface';

const FILENAME_FORMAT = 'YYYYMMDD';
const FILENAME_INVALID_CHARACTERS = /[<>:"\\/|?*]/g;
const EXCEL_WORKSHEET_NAME_INVALID_CHARACTERS = /[*?:\\/[\]]/g;
const EXCEL_WORKSHEET_NAME_MAXIMUM_LENGTH = 31;

export async function devExpressPivotGridExportToExcel(
  event: IDevExpressPivotGridEventOnExporting,
  translateService: TranslateService,
  properties?: IDevExpressPivotGridExportExcelProps
): Promise<void> {
  // Disable deprecated built-in export functionality
  event.cancel = true;

  let filename: string;
  if (!isEmpty(properties?.filename)) {
    filename = await (isFunction(properties?.filename) ? properties?.filename() : properties?.filename);
    filename = translateService.instant(filename);
    if (!filename.endsWith('xls') && !filename.endsWith('xlsx')) {
      filename += '.xlsx';
    }
  } else {
    filename = `Pivotgrid-${moment().format(FILENAME_FORMAT)}.xlsx`;
  }
  filename = filename.replace(FILENAME_INVALID_CHARACTERS, '').trim();
  const worksheetName: string = (isString(properties?.worksheetName) ? properties.worksheetName : filename.substring(0, filename.lastIndexOf('.'))) // filename without extension
    .replace(EXCEL_WORKSHEET_NAME_INVALID_CHARACTERS, '')
    .trim()
    .slice(0, EXCEL_WORKSHEET_NAME_MAXIMUM_LENGTH);

  const workbook: Workbook = new Workbook();
  const worksheet: Worksheet = workbook.addWorksheet(worksheetName);
  await exportPivotGrid({
    worksheet: worksheet,
    component: event.component,
    encodeExecutableContent: true,
    ...properties
  });
  const buffer: BlobPart = await workbook.xlsx.writeBuffer(properties?.xlsxWriteOptions);
  downloadStream(new Blob([buffer], {type: 'application/octet-stream'}), filename);
}
