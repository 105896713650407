import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IJsonGTOLicenseInfo} from '../../jsonGTO.module.interface';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {GtoService} from '../../gto.module.service';

@Component({
  selector: 'gto-license-modal',
  templateUrl: './gto.license.modal.component.html'
})
export class GtoLicenseModalComponent extends CGModalComponent<void> implements OnInit {
  public dadosLicenca: IJsonGTOLicenseInfo;
  public nEmpresa: string;
  public nomeEmpresa: string;
  public loaded: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _gtoService: GtoService
  ) {
    super(_injector);
    this.loaded = false;
    this.dadosLicenca = {
      haveContabDigitalLicense: false,
      haveGTOLicense: false,
      nEmpresasTotal: 0,
      nEmpresasConsumidas: 0,
      nEmpresasPorConsumir: 0
    };
  }

  public ngOnInit(): void {
    this.disableClose();
    this._gtoService
      .getLicenseInfo()
      .then((response: HttpResponse<IJsonGTOLicenseInfo>) => {
        this.dadosLicenca = response.body;
        this.loaded = true;
      })
      .finally(() => {
        this.enableClose();
      });
  }
}
