<div class="row">
  <div class="sortable-card-header d-flex justify-content-between align-items-center w-100">
    <div class="sortable-card-header-category" [ngStyle]="{'background-color': categoryBackgroundColor, color: categoriaCor}">{{ categoria }}</div>
    <div class="sortable-card-header-icons">
      <div class="sortable-card-header-message-icon">
        <i class="fa fa-commenting-o" aria-hidden="true"></i>
        <div *ngIf="numberUnreadMessages > 0" class="unread-dot"></div>
      </div>

      <div ngbDropdown>
        <span class="sortable-card-header-actions-icon" (click)="onClickOpenDropdownActions($event)" ngbDropdownToggle> <i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>

        <div ngbDropdownMenu>
          <button type="button" ngbDropdownItem (click)="onClickOpenTopico($event, topicoId)">
            <i class="fa fa-fw fa-pencil" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaoComunicacoes.modal.topico.editMessage'"></span>
          </button>

          <button type="button" ngbDropdownItem (click)="onClickDeleteTopico($event, topicoId)">
            <i class="fa fa-fw fa-trash" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaoComunicacoes.modal.topico.deleteMessage'"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="sortable-card-body">
    <div class="sortable-card-body-title" [innerText]="name"></div>
    <div class="sortable-card-body-sub-title" [innerHtml]="subTitle"></div>
  </div>
</div>
<div class="row">
  <hr class="sortable-card-footer-line" />
  <div class="sortable-card-footer d-flex justify-content-between w-100">
    <div class="sortable-card-author" [innerText]="author"></div>
    <div class="sortable-card-date">{{ date | cgcDate }}</div>
  </div>
</div>
