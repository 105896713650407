import CustomStore from 'devextreme/data/custom_store';
import {Component, Injector} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {ENTITY_NAME_PRH_SERVICO_EMPREGADOS} from '../../../../entities/prhservicoempregados/pRHServicoEmpregados.entity.interface';
import {ENTITY_NAME_PRH_SERVICOS} from '../../../../entities/prhservicos/pRHServicos.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../config/constants';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonDGEMP} from '../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHPapel} from '../../../../entities/prhpapeis/jsonPRHPapel.entity.interface';
import {IJsonPRHServicoEmpregado} from '../../../../entities/prhservicoempregados/jsonPRHServicoEmpregado.entity.interface';
import {IJsonPRHServico} from '../../../../entities/prhservicos/jsonPRHServico.entity.interface';
import {IServico} from '../servicos.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';

@Component({
  selector: 'colaboradores-servicos',
  templateUrl: './servicos.module.component.html'
})
export class ServicosComponent extends ModuloComponent {
  public readonly dataGridDefinition: IDevExpressDataGrid<IServico>;
  public readonly dataGridDefinitionDetail: IDevExpressDataGrid<IJsonPRHServicoEmpregado>;
  public readonly filterPapeis: string;

  private readonly _servicePRHServicos: IEntityService<IJsonPRHServico>;
  private readonly _servicePRHServicoEmpregados: IEntityService<IJsonPRHServicoEmpregado>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columns: [
        {dataField: 'codServico', dataType: 'string', caption: 'prhservicos.fields.codServico', sortOrder: 'asc', width: 100},
        {dataField: 'nome', dataType: 'string', caption: 'prhservicos.fields.nome'}
      ],
      dataSource: new CustomStore({
        load: () => this._getServicos()
      }),
      masterDetail: {enabled: true, template: 'masterDetailTemplate'},
      remoteOperations: false
    };
    this.dataGridDefinitionDetail = {
      columns: [
        {dataField: 'dgEmp.codEmp', dataType: 'string', caption: 'gestorrh.servicos.fields.colaborador', sortOrder: 'asc', width: 130, allowGrouping: false},
        {dataField: 'dgEmp.nome', dataType: 'string', caption: 'gestorrh.servicos.fields.nome', allowGrouping: false},
        {dataField: 'papel.nome', dataType: 'string', caption: 'gestorrh.servicos.fields.papel'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnDetail', width: 50, allowHiding: false}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.filterPapeis = 'unicoServico=0';
    this._servicePRHServicos = this._entityServiceBuilder.build(ENTITY_NAME_PRH_SERVICOS);
    this._servicePRHServicoEmpregados = this._entityServiceBuilder.build(ENTITY_NAME_PRH_SERVICO_EMPREGADOS);
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IServico>): void {
    devExpressDataGridExpandDetailHandler(event, () => {
      return this._servicePRHServicoEmpregados.query({pesquisa: `codServico=${event.row.data.codServico}`}).then((response: THttpQueryResponse<IJsonPRHServicoEmpregado>) => {
        event.row.data.empregados = response.body.list.filter((servicoEmpregado: IJsonPRHServicoEmpregado) => !servicoEmpregado.papel.unicoServico);
      });
    }).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public onInitializedDetail({component}: IDevExpressDataGridEventOnInitialized, servico: IServico): void {
    servico._dataGridInstanceDetail = component;
  }

  public readonly fnGravarServicoEmpregado: (item: IServico) => () => Promise<void> = (item: IServico) => (): Promise<void> => this._gravarServicoEmpregado(item);

  public readonly fnApagarServicoEmpregado: (item: IServico, codServicoEmpregado: number) => () => Promise<void> = (item: IServico, codServicoEmpregado: number) => (): Promise<void> =>
    this._apagarServicoEmpregado(item, codServicoEmpregado);

  private _getServicos(): Promise<Array<IServico>> {
    return this._servicePRHServicos.query().then((response: THttpQueryResponse<IJsonPRHServico>) => {
      return response.body.list.map<IServico>((servico: IJsonPRHServico) => {
        return {
          ...servico,
          dgemp: undefined,
          papel: undefined,
          empregados: []
        };
      });
    });
  }

  private _gravarServicoEmpregado(item: IServico): Promise<void> {
    if (!item?.dgemp || !item.papel) {
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    if (item.papel.unicoServico) {
      return Promise.resolve();
    }

    const newServicoEmpregado: IJsonPRHServicoEmpregado = {
      codServicoEmpregado: undefined,
      codServico: item.codServico,
      codEmp: item.dgemp.codEmp,
      codPapel: item.papel.codPapel,
      papel: undefined,
      servico: undefined,
      dgEmp: undefined
    };
    const dgemp: IJsonDGEMP = item.dgemp;
    const papel: IJsonPRHPapel = item.papel;

    return this._servicePRHServicoEmpregados.post({body: newServicoEmpregado}).then((response: HttpResponse<IJsonPRHServicoEmpregado>) => {
      item.empregados.push({
        ...response.body,
        dgEmp: dgemp,
        papel: papel
      });
      item.dgemp = undefined;
      item.papel = undefined;
      this._plAlertService.success(this._plTranslateService.translate('prhservicoempregados.saved', {id: response.body.codServicoEmpregado}));
    });
  }

  private _apagarServicoEmpregado(item: IServico, codServicoEmpregado: number): Promise<void> {
    return this._servicePRHServicoEmpregados.delete({id: codServicoEmpregado}).then(() => {
      for (let i = 0; i < item.empregados.length; i++) {
        if (item.empregados[i].codServicoEmpregado === codServicoEmpregado) {
          item.empregados.splice(i, 1);
          break;
        }
      }
    });
  }
}
