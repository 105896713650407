<div class="rh-dados-config-ficha">
  <pl-form formInstanceName="rhdadosconfigficha" [properties]="{readonly: readonly, validators: {required: {value: required}}}" (formInstanceChange)="changedFormInstance($event)">
    <div class="card-deck">
      <div class="card">
        <div class="card-header">
          <div class="card-title" [translate]="'rhdadosconfigficha.titles.registo'"></div>
        </div>

        <div class="card-body">
          <pl-group>
            <label [translate]="'rhdadosconfigficha.fields.motivoRegisto'"></label>
            <edit>
              <entity-autocomplete
                entity="tiposregistocadastro"
                attrName="tiposregistocadastro"
                [model]="model"
                (selectedKeyChange)="model.motivoRegisto = $event"
                (evtSelectedDescriptionChanged)="model.designaComplMotivoRegisto = $event"
                [fieldsMap]="{nMotivoMov: 'motivoRegisto', designaCompl: 'designaComplMotivoRegisto'}"
                [outputDescription]="'designaCompl'"
                [rowTemplate]="outputMotReg"
                [output]="outputMotReg">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <div class="card-title" [translate]="'rhdadosconfigficha.titles.situacaoemprego'"></div>
        </div>

        <div class="card-body">
          <pl-group>
            <label [translate]="'rhdadosconfigficha.fields.situacaoEmprego'"></label>
            <edit>
              <entity-autocomplete
                entity="situacaoempregado"
                attrName="situacaoempregado"
                [model]="model"
                (selectedKeyChange)="model.situacaoEmprego = $event"
                (evtSelectedDescriptionChanged)="model.designaBreveSituacaoEmprego = $event"
                [fieldsMap]="{nSitEmpreg: 'situacaoEmprego', designaBreve: 'designaBreveSituacaoEmprego'}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </div>
      </div>
    </div>

    <div class="card-deck">
      <div class="card">
        <div class="card-header">
          <div class="card-title" [translate]="'rhdadosconfigficha.titles.dadosrenumeracao'"></div>
        </div>

        <div class="card-body">
          <pl-group>
            <pl-group>
              <label [translate]="'rhdadosconfigficha.fields.nCodABVencimento'"></label>
              <edit>
                <entity-autocomplete
                  entity="abonos"
                  attrName="vencimento"
                  [model]="model"
                  (selectedKeyChange)="model.nCodABVencimento = $event"
                  (evtSelectedDescriptionChanged)="model.designaBreveABVencimento = $event"
                  [fieldsMap]="{nCodABDESC: 'nCodABVencimento', designacaoBreve: 'designaBreveABVencimento'}"
                  [rowTemplate]="outputAbdes"
                  [output]="outputAbdes"
                  [filter]="filterVencimento"
                  outputKey="nCodABDESC">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'rhdadosconfigficha.fields.nCodABSubAlimentacao'"></label>
              <edit>
                <entity-autocomplete
                  entity="abonos"
                  attrName="subalimentacao"
                  [model]="model"
                  (selectedKeyChange)="model.nCodABSubAlimentacao = $event"
                  (evtSelectedDescriptionChanged)="model.designaBreveABSubAlimentacao = $event"
                  [fieldsMap]="{nCodABDESC: 'nCodABSubAlimentacao', designacaoBreve: 'designaBreveABSubAlimentacao'}"
                  [rowTemplate]="outputAbdes"
                  [output]="outputAbdes"
                  [filter]="filterSubsAlimentacao"
                  outputKey="nCodABDESC">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'rhdadosconfigficha.fields.nCodABSubFerias'"></label>
              <edit>
                <entity-autocomplete
                  entity="abonos"
                  attrName="subferias"
                  [model]="model"
                  (selectedKeyChange)="model.nCodABSubFerias = $event"
                  (evtSelectedDescriptionChanged)="model.designaBreveABSubFerias = $event"
                  [fieldsMap]="{nCodABDESC: 'nCodABSubFerias', designacaoBreve: 'designaBreveABSubFerias'}"
                  [rowTemplate]="outputAbdes"
                  [output]="outputAbdes"
                  [filter]="filterSubsFerias"
                  outputKey="nCodABDESC">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'rhdadosconfigficha.fields.nCodABSubNatal'"></label>
              <edit>
                <entity-autocomplete
                  entity="abonos"
                  attrName="subnatal"
                  [model]="model"
                  (selectedKeyChange)="model.nCodABSubNatal = $event"
                  (evtSelectedDescriptionChanged)="model.designaBreveABSubNatal = $event"
                  [fieldsMap]="{nCodABDESC: 'nCodABSubNatal', designacaoBreve: 'designaBreveABSubNatal'}"
                  [rowTemplate]="outputAbdes"
                  [output]="outputAbdes"
                  [filter]="filterSubsNatal"
                  outputKey="nCodABDESC">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'rhdadosconfigficha.fields.nCodABDiuturnidades'"></label>
              <edit>
                <entity-autocomplete
                  entity="abonos"
                  attrName="diuturnidades"
                  [model]="model"
                  (selectedKeyChange)="model.nCodABDiuturnidades = $event"
                  (evtSelectedDescriptionChanged)="model.designaBreveABDiuturnidades = $event"
                  [fieldsMap]="{nCodABDESC: 'nCodABDiuturnidades', designacaoBreve: 'designaBreveABDiuturnidades'}"
                  [rowTemplate]="outputAbdes"
                  [output]="outputAbdes"
                  [filter]="filterDiuturnidades"
                  outputKey="nCodABDESC">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'rhdadosconfigficha.fields.nCodABFerias'"></label>
              <edit>
                <entity-autocomplete
                  entity="abonos"
                  attrName="ferias"
                  [model]="model"
                  (selectedKeyChange)="model.nCodABFerias = $event"
                  (evtSelectedDescriptionChanged)="model.designaBreveABFerias = $event"
                  [fieldsMap]="{nCodABDESC: 'nCodABFerias', designacaoBreve: 'designaBreveABFerias'}"
                  [rowTemplate]="outputAbdes"
                  [output]="outputAbdes"
                  [filter]="filterFerias"
                  outputKey="nCodABDESC">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <label [translate]="'rhdadosconfigficha.fields.nCodDescIRS'"></label>
            <edit>
              <entity-autocomplete
                entity="descontos"
                attrName="descirs"
                [model]="model"
                (selectedKeyChange)="model.nCodDescIRS = $event"
                (evtSelectedDescriptionChanged)="model.designaBreveDescIRS = $event"
                [fieldsMap]="{nCodABDESC: 'nCodDescIRS', designacaoBreve: 'designaBreveDescIRS'}"
                [rowTemplate]="outputAbdes"
                [output]="outputAbdes"
                [filter]="filterIRS"
                outputKey="nCodABDESC">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </div>
      </div>
    </div>

    <div class="card-deck">
      <div class="card">
        <div class="card-header">
          <div class="card-title" [translate]="'rhdadosconfigficha.titles.outrosdados'"></div>
        </div>

        <div class="card-body">
          <pl-group>
            <pl-group>
              <label [translate]="'rhdadosconfigficha.fields.localTrabalho'"></label>
              <edit>
                <entity-autocomplete
                  entity="estabelecimento"
                  attrName="estabelecimento"
                  [model]="model"
                  (selectedKeyChange)="model.localTrabalho = $event"
                  (evtSelectedDescriptionChanged)="model.nomeLocalTrabalho = $event"
                  [fieldsMap]="{nEstabElec: 'localTrabalho', nome: 'nomeLocalTrabalho'}">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'rhdadosconfigficha.fields.localObtencaoRendimentos'"></label>
              <edit>
                <pl-edit [(model)]="model.localObterRendimento" [type]="dataSourceNameContinentes"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'rhdadosconfigficha.fields.tipoHorarioTrabalho'"></label>
              <edit>
                <entity-autocomplete
                  entity="tipohorario"
                  attrName="tipohorariotrabalho"
                  [model]="model"
                  (selectedKeyChange)="model.tipoHorarioTrabalho = $event"
                  (evtSelectedDescriptionChanged)="model.designaBreveTipoHorarioTrabalho = $event"
                  [fieldsMap]="{nTipoHorario: 'tipoHorarioTrabalho', designaBreve: 'designaBreveTipoHorarioTrabalho'}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
        </div>
      </div>
    </div>

    <div class="card-deck">
      <div class="card">
        <div class="card-header">
          <div class="card-title" [translate]="'rhdadosconfigficha.titles.subsidioferias'"></div>
        </div>

        <div class="card-body">
          <pl-group>
            <label [translate]="'rhdadosconfigficha.fields.nMaxDiasUteisFerias'"></label>
            <edit>
              <pl-edit type="integer" attrName="nMaxDiasUteisFerias" [(model)]="model.nMaxDiasUteisFerias" [properties]="{allowNegative: false}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'rhdadosconfigficha.fields.nDiasTemDireitoFerias'"></label>
            <edit>
              <pl-edit type="integer" attrName="nDiasTemDireitoFerias" [(model)]="model.nDiasTemDireitoFerias" [properties]="{allowNegative: false}"></pl-edit>
            </edit>
          </pl-group>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <div class="card-title" [translate]="'rhdadosconfigficha.titles.subsidionatal'"></div>
        </div>

        <div class="card-body">
          <pl-group>
            <label [translate]="'rhdadosconfigficha.fields.nMaxDiasUteisNatal'"></label>
            <edit>
              <pl-edit type="integer" attrName="nMaxDiasUteisNatal" [(model)]="model.nMaxDiasUteisNatal" [properties]="{allowNegative: false}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'rhdadosconfigficha.fields.nDiasTemDireitoNatal'"></label>
            <edit>
              <pl-edit type="integer" attrName="nDiasTemDireitoNatal" [(model)]="model.nDiasTemDireitoNatal" [properties]="{allowNegative: false}"></pl-edit>
            </edit>
          </pl-group>
        </div>
      </div>
    </div>

    <div class="card-deck">
      <div class="card">
        <div class="card-header">
          <div class="card-title" [translate]="'rhdadosconfigficha.titles.residenciafiscal'"></div>
        </div>

        <div class="card-body">
          <pl-group>
            <edit>
              <pl-edit type="radiogroup" class="residencia-fiscal" attrName="residenciaFiscal" [(model)]="model.residenciaFiscal" [properties]="{groupItems: groupResidenciaFiscal}"></pl-edit>
            </edit>
          </pl-group>
        </div>
      </div>
    </div>
  </pl-form>
</div>
