import {Component, OnInit} from '@angular/core';
import {IPlDynamicVisualsSecondaryClickAction, isArray, isEmpty, PlDynamicVisualsSecondaryClickComponent} from 'pl-comps-angular';

const CSS_CLASS_HOVER = 'dx-state-hover';

@Component({
  selector: 'dev-express-centralgest-context-menu',
  templateUrl: './devexpress.centralgest.contextmenu.component.html'
})
export class DevExpressCentralGestContextMenuComponent extends PlDynamicVisualsSecondaryClickComponent implements OnInit {
  public hasIcon: boolean;

  public ngOnInit(): void {
    super.ngOnInit();
    this.hasIcon = isArray(this.actions) && Boolean(this.actions.find((action: IPlDynamicVisualsSecondaryClickAction) => !isEmpty(action.icon)));
  }

  public onMouseEnterMenuItem(event: MouseEvent): void {
    const element = <HTMLElement>event.target;
    element.classList.add(CSS_CLASS_HOVER);
  }

  public onMouseLeaveMenuItem(event: MouseEvent): void {
    const element = <HTMLElement>event.target;
    element.classList.remove(CSS_CLASS_HOVER);
  }
}
