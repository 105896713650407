import type dxDataGrid from 'devextreme/ui/data_grid';
import ArrayStore from 'devextreme/data/array_store';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowUpdated,
  IDevExpressDataGridEventOnRowUpdating
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonDocComercial, IJsonDocResumoIvaItem} from '../../jsonDocComercial.entity.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IDocResumoIvaItemGrid, IDocResumoIvaTotais} from '../../docsComerciais.entity.interface';
import {IDevExpressDataGridStoreChange} from '../../../../components/devexpress/datagrid/store/devexpress.datagrid.store.interface';
import {round} from '../../../../../common/utils/utils';

@Component({
  selector: 'arredondamento-manual-modal',
  templateUrl: './documento.arredondamentoManual.modal.component.html'
})
export class DocumentoArredondamentoManualModalComponent extends CGModalComponent<Array<IJsonDocResumoIvaItem>> implements OnInit {
  @Input() public docComercial: IJsonDocComercial;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly dataGridResumoDefinition: IDevExpressDataGrid;
  public totalIva: number;
  public totalIncidencia: number;
  public total: number;
  public temRet: boolean;
  public resumoIvaTotais: Array<IDocResumoIvaTotais>;

  private readonly _dataGridStore: ArrayStore;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this._dataGridStore = new ArrayStore({key: 'index'});
    this.dataGridDefinition = {
      dataSource: this._dataGridStore,
      columns: [
        {
          caption: 'arredondamentoManual.gridHeader.documento',
          columns: [
            {
              dataField: 'codIva',
              dataType: 'number',
              caption: 'arredondamentoManual.gridFields.codIva',
              allowFiltering: false,
              allowHeaderFiltering: false,
              allowReordering: false,
              allowSorting: false,
              allowEditing: false
            },
            {
              dataField: 'taxaIva',
              dataType: 'double',
              caption: 'arredondamentoManual.gridFields.taxaIva',
              allowFiltering: false,
              allowHeaderFiltering: false,
              allowReordering: false,
              allowSorting: false,
              allowEditing: false
            },
            {
              dataField: 'liquidoComArredAuto',
              dataType: 'double',
              caption: 'arredondamentoManual.gridFields.prVenda1',
              allowFiltering: false,
              allowHeaderFiltering: false,
              allowReordering: false,
              allowSorting: false,
              allowEditing: false
            },
            {
              dataField: 'ivaComArredAuto',
              dataType: 'double',
              caption: 'arredondamentoManual.gridFields.valorIva',

              allowFiltering: false,
              allowHeaderFiltering: false,
              allowReordering: false,
              allowSorting: false,
              allowEditing: false
            },
            {
              dataField: 'total',
              dataType: 'double',
              caption: 'global.text.total',
              allowFiltering: false,
              allowHeaderFiltering: false,
              allowReordering: false,
              allowSorting: false,
              allowEditing: false
            }
          ]
        },
        {
          caption: 'arredondamentoManual.gridHeader.arredondamento',
          columns: [
            {
              dataField: 'arredLiquidoManualEditavel',
              dataType: 'double',
              caption: 'arredondamentoManual.gridFields.arredLiquidoManualEditavel',
              allowFiltering: false,
              allowHeaderFiltering: false,
              allowReordering: false,
              allowSorting: false,
              allowEditing: true
            },
            {
              dataField: 'incidenciaTotalEditavel',
              dataType: 'double',
              caption: 'arredondamentoManual.gridFields.incidenciaTotalEditavel',
              allowFiltering: false,
              allowHeaderFiltering: false,
              allowReordering: false,
              allowSorting: false,
              allowEditing: true
            },
            {
              dataField: 'arredIVAManualEditavel',
              dataType: 'double',
              caption: 'arredondamentoManual.gridFields.arredIVAManualEditavel',
              allowFiltering: false,
              allowHeaderFiltering: false,
              allowReordering: false,
              allowSorting: false,
              allowEditing: true
            },
            {
              dataField: 'ivaTotalEditavel',
              dataType: 'double',
              caption: 'arredondamentoManual.gridFields.ivaTotalEditavel',
              allowFiltering: false,
              allowHeaderFiltering: false,
              allowReordering: false,
              allowSorting: false,
              allowEditing: true
            }
          ]
        }
      ],
      filterRow: {visible: false},
      editing: {allowUpdating: true, mode: 'cell', selectTextOnEditStart: true, startEditAction: 'click'}
    };

    this.dataGridResumoDefinition = {
      columns: [
        {
          dataField: 'totalIncidencia',
          dataType: 'double',
          caption: 'arredondamentoManual.resumo.totalIncidencia',
          width: 120,
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false
        },
        {
          dataField: 'totalIva',
          dataType: 'double',
          caption: 'arredondamentoManual.resumo.totalIva',
          width: 120,
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false
        },
        {
          dataField: 'total',
          dataType: 'double',
          caption: 'global.text.total',
          width: 120,
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false
        }
      ],
      allowColumnReordering: false,
      allowColumnResizing: false,
      columnHidingEnabled: false,
      headerFilter: {visible: false},
      columnChooser: {enabled: false},
      filterRow: {visible: false},
      export: {allowExportSelectedData: false, enabled: false}
    };

    this.resumoIvaTotais = [];
    this.resumoIvaTotais.push({
      total: 0,
      totalIncidencia: 0,
      totalIva: 0
    });
  }

  public ngOnInit(): void {
    this.temRet = !isEmpty(this.docComercial.cab.codRet);
    if (this.docComercial.resumoIva?.length) {
      this._dataGridStore.push(
        this.docComercial.resumoIva.map<IDevExpressDataGridStoreChange<IDocResumoIvaItemGrid>>((resumoIva: IJsonDocResumoIvaItem, index: number) => {
          const liquidoComArredAuto = resumoIva.valorLiquido + resumoIva.arredLiquido;
          const iVAComArredAuto = resumoIva.valorIva + resumoIva.arredIvaManual;
          const totalIva = iVAComArredAuto + resumoIva.arredIvaManual;
          const totalLiquido = liquidoComArredAuto + resumoIva.arredLiquidoManual;
          return {
            type: 'insert',
            data: {
              ...resumoIva,
              index: index,
              total: totalIva + totalLiquido,
              totalIva: iVAComArredAuto + resumoIva.arredIvaManual,
              totalLiquido: liquidoComArredAuto + resumoIva.arredLiquido,
              incidenciaTotalEditavel: liquidoComArredAuto + resumoIva.arredLiquidoManual,
              arredIVAManualEditavel: resumoIva.arredIvaManual,
              arredLiquidoManualEditavel: resumoIva.arredLiquidoManual,
              ivaTotalEditavel: iVAComArredAuto + resumoIva.arredIvaManual,
              ivaComArredAuto: iVAComArredAuto,
              liquidoComArredAuto: liquidoComArredAuto
            }
          };
        })
      );
    }
  }

  public close(): void {
    const resumoIva: Array<IDocResumoIvaItemGrid> = this._getResumoIva();
    super.close(
      resumoIva.map<IJsonDocResumoIvaItem>((resumoIvaGridItem: IDocResumoIvaItemGrid) => {
        return {
          codIva: resumoIvaGridItem.codIva,
          taxaIva: resumoIvaGridItem.taxaIva,
          valorIliquido: resumoIvaGridItem.valorIliquido,
          valorLiquido: resumoIvaGridItem.valorLiquido,
          valorIva: resumoIvaGridItem.valorIva,
          valorIvaIncluido: resumoIvaGridItem.valorIvaIncluido,
          desconto: resumoIvaGridItem.desconto,
          arredLiquido: resumoIvaGridItem.arredLiquido,
          arredIva: resumoIvaGridItem.arredIva,
          arredIvaIncluido: resumoIvaGridItem.arredIvaIncluido,
          arredLiquidoManual: resumoIvaGridItem.arredLiquidoManual,
          arredIvaManual: resumoIvaGridItem.arredIvaManual,
          arredIvaIncluidoManu: resumoIvaGridItem.arredIvaIncluidoManu
        };
      })
    );
  }

  public onRowUpdating(event: IDevExpressDataGridEventOnRowUpdating<IDocResumoIvaItemGrid>): void {
    const {newData, oldData}: IDevExpressDataGridEventOnRowUpdating<IDocResumoIvaItemGrid> = event;
    const messageErrorMaxValue = this._translateService.instant('arredondamentoManual.messages.warningMaxValue', {
      value: this._configService.configurations.gestaoComercial.margens.arredManualMax
    });
    if (Object.prototype.hasOwnProperty.call(newData, 'arredIVAManualEditavel')) {
      if (!this._validaMaximoPermitido(newData.arredIVAManualEditavel)) {
        event.cancel = true;
        this._plAlertService.error(messageErrorMaxValue);
        return;
      }
      newData.arredIvaManual = newData.arredIVAManualEditavel;
      newData.arredIvaIncluidoManu = oldData.arredLiquidoManual + newData.arredIVAManualEditavel;
      newData.ivaTotalEditavel = newData.arredIVAManualEditavel + oldData.ivaComArredAuto;
    } else if (Object.prototype.hasOwnProperty.call(newData, 'arredLiquidoManualEditavel')) {
      if (!this._validaMaximoPermitido(newData.arredLiquidoManualEditavel)) {
        event.cancel = true;
        this._plAlertService.error(messageErrorMaxValue);
        return;
      }
      newData.arredLiquidoManual = newData.arredLiquidoManualEditavel;
      newData.arredIvaIncluidoManu = newData.arredLiquidoManualEditavel + oldData.arredLiquidoManual;
      newData.incidenciaTotalEditavel = newData.arredLiquidoManualEditavel + oldData.liquidoComArredAuto;
    } else if (Object.prototype.hasOwnProperty.call(newData, 'incidenciaTotalEditavel')) {
      const newValue = round(newData.incidenciaTotalEditavel - oldData.liquidoComArredAuto);
      if (!this._validaMaximoPermitido(newValue)) {
        event.cancel = true;
        this._plAlertService.error(messageErrorMaxValue);
        return;
      }
      newData.arredLiquidoManual = newValue;
      newData.arredIvaIncluidoManu = newValue + oldData.arredIvaManual;
    } else if (Object.prototype.hasOwnProperty.call(newData, 'ivaTotalEditavel')) {
      const newValue = round(newData.ivaTotalEditavel - oldData.ivaComArredAuto);
      if (!this._validaMaximoPermitido(newValue)) {
        event.cancel = true;
        this._plAlertService.error(messageErrorMaxValue);
        return;
      }
      newData.arredIvaManual = newValue;
      newData.arredIvaIncluidoManu = newValue + oldData.arredLiquidoManual;
    }
  }

  public onRowUpdated(event: IDevExpressDataGridEventOnRowUpdated<IDocResumoIvaItemGrid>): void {
    const partialChanges = {
      ivaComArredAuto: event.data.valorIva + event.data.arredIva,
      liquidoComArredAuto: event.data.valorLiquido + event.data.arredLiquido,
      totalIva: event.data.valorIva + event.data.arredIva + event.data.arredIvaManual,
      total: event.data.valorIva + event.data.arredIva + event.data.arredIvaManual + event.data.arredLiquido + event.data.valorLiquido + event.data.arredLiquidoManual,
      totalLiquido: event.data.valorLiquido + event.data.arredLiquido + event.data.arredLiquidoManual
    };
    const changes: Array<IDevExpressDataGridStoreChange<IDocResumoIvaItemGrid>> = [];
    changes.push({type: 'update', key: event.key, data: {...partialChanges}});
    this._dataGridStore.push(changes);
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._getTotais();
    this._dataGridInstance.endCustomLoading();
  }

  public delete(): void {
    this._cgModalService.showOkCancel('Eliminar arredondamento', 'Tem a certeza que pretende eliminar o arredondamento manual?').then(() => {
      for (const resumoIvaItem of this.docComercial.resumoIva) {
        resumoIvaItem.arredLiquidoManual = 0;
        resumoIvaItem.arredIvaManual = 0;
        resumoIvaItem.arredIvaIncluidoManu = 0;
      }
    });
  }

  private _getTotais(): void {
    this.resumoIvaTotais[0].total = 0;
    this.resumoIvaTotais[0].totalIncidencia = 0;
    this.resumoIvaTotais[0].totalIva = 0;
    const resumoIva: Array<IDocResumoIvaItemGrid> = this._getResumoIva();
    for (const resumoIvaItem of resumoIva) {
      this.resumoIvaTotais[0].total += resumoIvaItem.total;
      this.resumoIvaTotais[0].totalIncidencia += resumoIvaItem.totalLiquido;
      this.resumoIvaTotais[0].totalIva += resumoIvaItem.totalIva;
    }
  }

  private _validaMaximoPermitido(valor: number): boolean {
    return this._configService.configurations.gestaoComercial.margens.arredManualMax >= valor;
  }

  private _getResumoIva(): Array<IDocResumoIvaItemGrid> {
    return this._dataGridInstance.getDataSource().items();
  }
}
