import {TDate} from '../../../../common/dates';

export enum ERHRelatorioUnicoState {
  Inactive = 'rusInactive',
  Started = 'rusStarted',
  Ended = 'rusEnded',
  Timeout = 'rusTimeout',
  Error = 'rusError'
}

export interface IRHRelatorioUnicoError {
  nome: string;
  descricao: string;
}

export interface IRHRelatorioUnicoStatus {
  userStarted: string;
  requestDate: TDate;
  plannedStartDate: TDate;
  position: number;
  description: string;
  state: ERHRelatorioUnicoState;
  currentOp: number;
  userStartedId: number;
  filename: string;
  max: number;
  ficheiroProcessado: boolean;
  errorList: Array<IRHRelatorioUnicoError>;
  ficheiroProcessadoDate: TDate;
  ficheiroProcessadoSize: number;
}

export enum ERHRelatorioUnicoStep {
  Configuration,
  Processing,
  Errors
}
