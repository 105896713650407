import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonExternalNif} from '../../services/externalnifs/externalnifs.interface';
import {IJsonNIFs} from './jsonNifs.entity.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface INifsEntityService extends IEntityService<IJsonNIFs> {
  getExternalNifFromVIES(nContribuinte: string): TServiceResponse<IJsonExternalNif>;

  registaNif(nContribuinte: string): TServiceResponse<void>;
}

export interface INifsEntity extends IEntityDefinition<IJsonNIFs, INifsEntityService> {}

export const ENTITY_NAME_NIFS = 'nifs';
