import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonSAFTContabContaBase, IJsonSAFTContabContaBaseDoc, IJsonSAFTContabContaCorrente, IJsonSAFTContabConvertConta, IJsonSAFTContabPlanoContas} from '../../jsonSaftContab.module.interface';
import {isDefinedNotNull, isEmpty, isObject, PlAlertService} from 'pl-comps-angular';
import {SaftContabService} from '../../saftContab.module.service';
import {
  IRestCmdSaftContabAddConvertConta,
  IRestCmdSaftContabReplaceConvertConta,
  IRestCmdSaftContabSearchContaCorrente,
  ISAFTContabConta,
  ISAFTContabPlanoContas,
  ISAFTContabTreeDetail,
  JOB_NOT_FOUND_ERROR
} from '../../saftContab.module.interface';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {HttpErrorResponse} from '@angular/common/http';
import {EStatusCode} from '../../../../../config/constants';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnCellPrepared} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {SelectionChangedEvent} from 'devextreme/ui/tree_list';

@Component({
  selector: 'saft-contab-convert-contas-modal',
  templateUrl: './saftContab.convertContas.modal.component.html'
})
export class SaftContabConvertContasModalComponent extends CGModalComponent<Array<IJsonSAFTContabPlanoContas>> implements OnInit {
  @Input() public convertConta: IJsonSAFTContabConvertConta;
  @Input() public planoConta: ISAFTContabPlanoContas;
  @Input() public treeDetail: ISAFTContabTreeDetail;
  @Input() public listSemConv: Array<IJsonSAFTContabPlanoContas>;
  @Input() public nConta: string;
  @Input() public contaExistente: boolean;
  @Input() public contaSemConvert: boolean;

  public readonly dataGridDefinitionContasSemConvertPlano: IDevExpressDataGrid<IJsonSAFTContabContaBase>;
  public readonly dataGridDefinitionContasDoc: IDevExpressDataGrid<IJsonSAFTContabContaBaseDoc>;

  public saftPOC: IJsonSAFTContabContaCorrente;
  public promise: Promise<void>;
  public pocsOutput: string;
  public filterMovimento: string;
  public convertContaTemplate: string;
  public disableOrigem: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _saftContabService: SaftContabService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.treeDetail = {
      treeSaft: [],
      contasBase: [],
      treeCG: []
    };
    this.convertConta = {
      nContaOrigem: undefined,
      nomeContaOrigem: undefined,
      nContaDestino: '',
      nomeContaDestino: '',
      erro: ''
    };
    this.dataGridDefinitionContasSemConvertPlano = {
      columns: [
        {dataField: 'documento', dataType: 'string', caption: 'saftcontab.steps.fields.documento'},
        {dataField: 'valorBase', dataType: 'double', caption: 'saftcontab.steps.fields.valorBase'},
        {dataField: 'valorIvaCalculado', dataType: 'double', caption: 'saftcontab.steps.fields.valorIvaCalculado'},
        {dataField: 'taxaCalculada', dataType: 'double', caption: 'saftcontab.steps.fields.taxaCalculada'},
        {dataField: 'nContasBase', dataType: 'number', caption: 'saftcontab.steps.fields.nContasBase', alignment: 'left'},
        {dataField: 'nContasIVA', dataType: 'number', caption: 'saftcontab.steps.fields.nContasIVA', alignment: 'left'},
        {dataField: 'valorBaseDoc', dataType: 'double', caption: 'saftcontab.steps.fields.valorBaseDoc'},
        {dataField: 'valorIVADoc', dataType: 'double', caption: 'saftcontab.steps.fields.valorIVADoc'},
        {dataField: 'taxaSobreTotalIVA', dataType: 'double', caption: 'saftcontab.steps.fields.taxaSobreTotalIVA'}
      ],
      dataSource: [],
      export: {filename: 'saftcontab.steps.contasSemConvert.step'},
      height: '60vh',
      columnHidingEnabled: false,
      masterDetail: {enabled: true, autoExpandAll: false, template: 'masterDetailContasSemConvertPlano'},
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'}
    };
    this.dataGridDefinitionContasDoc = {
      columns: [
        {dataField: 'contaDebito', dataType: 'string', caption: 'saftcontab.steps.fields.contaDebito'},
        {dataField: 'contaCredito', dataType: 'string', caption: 'saftcontab.steps.fields.contaCredito'},
        {dataField: 'descricao', dataType: 'string', caption: 'saftcontab.steps.fields.descricao'},
        {dataField: 'valor', dataType: 'double', caption: 'saftcontab.steps.fields.valor'}
      ],
      dataSource: [],
      columnHidingEnabled: false,
      remoteOperations: false
    };
    this.pocsOutput = '{{nConta}} - {{nome}}';
    this.convertContaTemplate = '{{nConta}} - {{nome}}';
    this.filterMovimento = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.disableOrigem = false;
    this.contaSemConvert = false;
    this.listSemConv = [];
    this.saftPOCSource = this.saftPOCSource.bind(this);
  }

  public ngOnInit(): void {
    this.saftPOC = {nConta: this.convertConta.nContaOrigem, nome: this.convertConta.nomeContaOrigem, erro: '', nif: ''};
    if (this.planoConta) {
      this.convertConta = {
        nContaOrigem: this.planoConta.nContaOrigem,
        nomeContaOrigem: this.planoConta.nomeContaOrigem,
        nContaDestino: this.planoConta.nContaDestino,
        nomeContaDestino: this.planoConta.nomeContaDestino,
        erro: this.planoConta.erro
      };
    }
    this.disableOrigem = !isEmpty(this.convertConta.nContaOrigem);
  }

  public close(): Promise<void> {
    if (isEmpty(this.convertConta.nContaOrigem)) {
      this._plAlertService.error('saftcontab.messages.contaOrigemVazia');
      return Promise.resolve();
    }
    if (isEmpty(this.convertConta.nContaDestino)) {
      this._plAlertService.error('saftcontab.messages.contaDestinoVazia');
      return Promise.resolve();
    }
    if (this.contaExistente) {
      return this._saftContabService
        .addConvertConta([], this.listSemConv, this.convertConta.nContaOrigem, this.convertConta.nContaDestino)
        .then((response: IRestCmdSaftContabAddConvertConta) => {
          super.close(response.list);
        })
        .catch((reason: HttpErrorResponse) => {
          this._handleError(reason);
        });
    }

    if (this.disableOrigem && !this.contaSemConvert) {
      return this._saftContabService
        .replaceConvertConta([], this.listSemConv, this.convertConta.nContaOrigem, this.convertConta.nContaDestino)
        .then((response: IRestCmdSaftContabReplaceConvertConta) => {
          super.close(response.list);
        })
        .catch((reason: HttpErrorResponse) => {
          this._handleError(reason);
        });
    }
    return this._saftContabService
      .addConvertConta([], this.listSemConv, this.convertConta.nContaOrigem, this.convertConta.nContaDestino)
      .then((response: IRestCmdSaftContabAddConvertConta) => {
        super.close(response.list);
      })
      .catch((reason: HttpErrorResponse) => {
        this._handleError(reason);
      });
  }

  public saftPOCSource(search: string): Promise<Array<IJsonSAFTContabContaCorrente>> {
    return this._saftContabService.searchContaCorrente([], search).then((response: IRestCmdSaftContabSearchContaCorrente) => response.list);
  }

  public convertContaChanged(event: IJsonSAFTContabContaCorrente): void {
    if (isObject(event)) {
      this.convertConta.nContaOrigem = event.nConta;
      this.convertConta.nomeContaOrigem = event.nome;

      this.saftPOC.nConta = event.nConta;
      this.saftPOC.nome = event.nome;
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonSAFTContabContaBase>): void {
    if (event.rowType === 'data') {
      devExpressDataGridExpandDetailHandler(event);
    }
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonSAFTContabContaBaseDoc>): void {
    if (event.rowType === 'data' && (event.data.contaCredito === this.nConta || event.data.contaDebito === this.nConta)) {
      event.cellElement.classList.add('text-danger');
    }
  }

  public onSelectionChangedTreeList(event: SelectionChangedEvent<ISAFTContabConta>): void {
    const selectedTreeItem: ISAFTContabConta = event.selectedRowsData[0];
    this.convertConta = {...this.convertConta, nContaDestino: selectedTreeItem.nconta, nomeContaDestino: selectedTreeItem.nome};
  }

  public onCellDblClick(): Promise<void> {
    return this.close();
  }

  private _handleError(reason: HttpErrorResponse): void {
    if (isDefinedNotNull(reason)) {
      const exception: ICGExceptionError = this._cgExceptionService.get(reason);
      if (exception.status === EStatusCode.InternalServerError && exception.message === JOB_NOT_FOUND_ERROR) {
        this._plAlertService.error('saftcontab.messages.errorocur');
        return;
      }
      this._plAlertService.error(exception.message);
    }
  }
}
