import {Component, Injector, Input, OnInit} from '@angular/core';
import {isObject} from 'pl-comps-angular';
import {ESubTipoMovimentoSAFT, IDocsComerciaisCabEntityService, IDocsComerciaisEntityService, IJsonDocComercialPesqDocGlobais, IPesquisaDocsFilters} from '../../docsComerciais.entity.interface';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../docfas/docFas.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IJsonDocComercial, IJsonDocComercialCab} from '../../jsonDocComercial.entity.interface';
import {IJsonDocfa} from '../../../docfas/jsonDocFa.entity.interface';
import moment from 'moment';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDocsComerciaisGrid} from '../../../../modules/portalclientaccounts/myaccount/documentoscomerciais/documentosComerciais.module.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'documento-pesqDocsGlobais-modal',
  templateUrl: './documento.pesqDocsGlobais.modal.component.html'
})
export class DocumentoPesqDocsGlobaisModalComponent extends CGModalComponent<IJsonDocComercialCab> implements OnInit {
  @Input() public doc: IJsonDocComercial;

  public readonly docFaTemplate: string = '{{nDocFa}} - {{nome}}';
  public readonly docFaOutput: string = 'nDocFa';
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public filters: IPesquisaDocsFilters;
  public docFaSource: Array<IJsonDocfa>;
  public docsList: Array<IJsonDocComercialPesqDocGlobais>;
  public selectedDoc: IJsonDocComercial;
  public differentGrupoFa: boolean;
  public hasDocFaList: boolean;
  public selectedDocCab: IJsonDocComercialPesqDocGlobais;

  private readonly _docFaService: IDocFasEntityService;
  private readonly _docsComerciaisService: IDocsComerciaisEntityService;
  private readonly _docsComerciaisCabService: IDocsComerciaisCabEntityService;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._docsComerciaisService = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>('docscomerciais');
    this._docsComerciaisCabService = this._entityServiceBuilder.build<IJsonDocComercialCab, IDocsComerciaisCabEntityService>('docscomerciais');

    this.dataGridDefinition = {
      columns: [
        {
          dataField: '_selected',
          cellTemplate: 'selCheckboxTemplate',
          caption: ' ',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false
        },
        {dataField: 'nDoc', dataType: 'string', width: '80px', caption: 'docscomerciais.fields.nDoc'},
        {dataField: 'nome', dataType: 'string', caption: 'docscomerciais.doc.cab.nome'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'global.text.date'},
        {dataField: 'estado', alignment: 'center', dataType: 'string', caption: 'docscomerciais.fields.estado'},
        {dataField: 'porPagar', alignment: 'right', dataType: 'number', caption: 'docscomerciais.fields.porPagar'},
        {dataField: 'totalDocumentoME', alignment: 'right', dataType: 'number', caption: 'global.text.total'},
        {dataField: 'abreviaturaMoeda', alignment: 'right', dataType: 'number', caption: 'docscomerciais.fields.nomeMoeda'}
      ],
      dataSource: [],
      remoteOperations: {
        filtering: false,
        grouping: false,
        groupPaging: false,
        paging: true,
        sorting: false,
        summary: false
      },
      scrolling: {
        rowRenderingMode: 'virtual'
      },
      height: '50vh',
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocFaturacao'}
    };

    this.filters = {
      deConta: `${this._configService.configurations.clientesFornecedores.radicalClientes}1`,
      ateConta: `${this._configService.configurations.clientesFornecedores.radicalClientes}9999999`,
      docfalist: [],
      dataDe: moment(),
      dataAte: moment()
    };

    this.docFaSource = [];
    this.filters.docfalist = [];
    this.docsList = [];
    this._docFaService = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
    this.differentGrupoFa = false;
    this.hasDocFaList = false;
    this.selectedDocCab = undefined;
  }

  public ngOnInit(): void {
    this._docFaService.query({filtro: `saftSubTipoMovimento=${ESubTipoMovimentoSAFT.DocumentoGlobal}`}).then((response) => {
      this.docFaSource = response.body.list;
      this.filters.docfalist = this.docFaSource.map((item) => item.nDocFa);
      this.hasDocFaList = true;
      this._refresh();
    });
  }

  public readonly fnSelectDocument: () => void = () => {
    this._selectDocument();
  };

  public readonly fnPesquisar: () => void = () => {
    this._pesquisar();
  };

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IDocsComerciaisGrid>): void {
    if (event.column.dataField !== '_selected') {
      if (event.row.isExpanded) {
        this._dataGridInstance.collapseAll(-1);
      } else {
        this._dataGridInstance.collapseAll(-1);
        if (!isObject(event.row)) {
          return;
        }
        devExpressDataGridExpandDetailHandler(event, () => this._onDetail(event.data));
      }
    }
  }

  public onSelCheckboxChanged(value: boolean, data: IDocsComerciaisGrid): void {
    this.docsList.forEach((item) => {
      item._selected = item.faccbId === data.faccbId ? value : false;
    });
    this.selectedDocCab = value ? data : undefined;
  }

  private _onDetail(grid: IDocsComerciaisGrid): Promise<void> {
    return this._docsComerciaisService.get({id: grid.faccbId}).then((response) => {
      grid._thedoc = response.body;
      this.selectedDoc = response.body;
      this.differentGrupoFa = this.selectedDoc.cab.grupoDocfa !== this.doc.cab.grupoDocfa;
    });
  }

  private _refresh(): void {
    if (this.filters && this.hasDocFaList) {
      const querySearchParam = this._generateQueryParams();
      this._docsComerciaisCabService.query({pesquisa: querySearchParam}).then((response) => {
        this.docsList = response.body.list;
      });
    }
  }

  private _pesquisar(): void {
    this._refresh();
  }

  private _selectDocument(): void {
    this.close(this.selectedDocCab);
  }

  private _generateQueryParams(): string {
    let tipoDocQueryParam = '';
    let paramsNDocFa = '';
    const dataDe: string = this.filters.dataDe ? moment(this.filters.dataDe).toISOString() : undefined;
    const dataAte: string = this.filters.dataAte ? moment(this.filters.dataAte).toISOString() : undefined;
    if (dataDe) {
      tipoDocQueryParam = `dataDoc>=${dataDe}`;
    }
    if (dataAte) {
      tipoDocQueryParam = tipoDocQueryParam ? `${tipoDocQueryParam}&&dataDoc<=${dataAte}` : `dataDoc<=${dataAte}`;
    }
    if (this.filters.deConta) {
      tipoDocQueryParam = tipoDocQueryParam ? `${tipoDocQueryParam}&&nconta>=${this.filters.deConta}` : `nconta>=${this.filters.deConta}`;
    }
    if (this.filters.ateConta) {
      tipoDocQueryParam = tipoDocQueryParam ? `${tipoDocQueryParam}&&nconta<=${this.filters.ateConta}` : `nconta<=${this.filters.ateConta}`;
    }
    let n = 0;
    if (this.filters.docfalist.length > 0 && tipoDocQueryParam !== '') {
      tipoDocQueryParam += '&&(';
    }
    while (n < this.filters.docfalist.length) {
      paramsNDocFa = n === 0 ? `ndocfa=${this.filters.docfalist[n].toString()}` : `${paramsNDocFa}||ndocfa=${this.filters.docfalist[n].toString()}`;
      ++n;
    }
    tipoDocQueryParam += paramsNDocFa;
    if ((dataDe || dataAte) && this.filters.docfalist.length > 0) {
      tipoDocQueryParam += ')';
    }
    return tipoDocQueryParam;
  }
}
