<div class="amalia-chat-message" [class.left]="!sentByAgent" [class.right]="sentByAgent">
  <div class="d-flex" [class.flex-row]="!sentByAgent" [class.flex-row-reverse]="sentByAgent">
    <div class="amalia-chat-img d-flex align-self-end">
      <img *ngIf="!sentByAgent" ngSrc="../../assets/images/amalia.png" alt="logo-amalia" [width]="30" [height]="30" />
      <!--      <i *ngIf="sentByAgent" cgcIcon="fa-user-circle-o" aria-hidden="true"></i>-->
    </div>

    <div class="amalia-chat-message-content-wrapper" [class.amalia-agent-message-container]="sentByAgent" [class.amalia-faq-message]="messageFaq">
      <amalia-chat-message-content
        *ngFor="let messageBodyContent of messageBody; let last = last"
        [messageBodyContent]="messageBodyContent"
        [colapsable]="messageBody.length > 2"
        [sentByAgent]="sentByAgent"
        [lastMessage]="last"
        (evtActionIntent)="actionIntent($event)"
        (evtLinkClicked)="handleCustomLinkClick($event)"></amalia-chat-message-content>

      <div *ngIf="!sentByAgent" class="amalia-message-feedback d-flex gap-1 mt-2 mb-1">
        <div class="amalia-message-feedback-icon-container">
          <a><i class="fa fa-thumbs-o-down"></i></a>
        </div>
        <div class="amalia-message-feedback-icon-container">
          <a><i class="fa fa-thumbs-o-up"></i></a>
        </div>
      </div>
    </div>
  </div>

  <div class="amalia-chat-infos clearfix">
    <span class="amalia-chat-timestamp" [class.float-start]="!sentByAgent" [class.float-end]="sentByAgent">
      <span *ngIf="isToday">{{ timestampDate.format('HH:mm') }}</span>
      <span *ngIf="!isToday">{{ timestampDate.format('DD/MM/YYYY, HH:mm') }}</span>
      <!--      <span *ngIf="sentByAgent" [class]="(!timeRead ? 'un-' : '') + 'read-icon'">-->
      <!--        <i cgcIcon="fa-check-circle" aria-hidden="true"></i>-->
      <!--      </span>-->
    </span>
  </div>
</div>
