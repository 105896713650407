import {merge} from 'lodash-es';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isObject} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_VALOR_PENHORA, ETipoValorPenhora} from '../../../../datasources/tipovalorpenhora/tipoValorPenhora.datasource.interface';
import {DATA_SOURCE_TIPOS_CATEGORIA_ABONOS} from '../../../../datasources/tipocategoria/tipoCategoria.datasource';
import {EMPTY_GUID} from '../../../../../config/constants';
import {ENTITY_NAME_DESCONTOS, ETipoAbonoDesconto, toCodAbonoDesconto} from '../../../abdes/abdes.entity.interface';
import {ETipoCategoriaDesc} from '../../../../datasources/tipocategoriadesc/tipoCategoriaDesc.datasource.interface';
import {ETipoCategoria} from '../../../../datasources/tipocategoria/tipoCategoria.datasource.interface';
import {dataSourceFormaPagamento, EFormaPagamento, ICategAbono, IRHPenhoras} from '../../rhPenhoras.entity.interface';
import {IJsonPenhoraDecimais, IJsonPenhoraDescontos} from '../../jsonRHPenhoras.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import moment from 'moment';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';

const MB_REFERENCIA_MAX_LENGTH_9 = 9;
const MB_REFERENCIA_MAX_LENGTH_15 = 15;

@Component({
  selector: 'rhpenhoras-edit',
  templateUrl: './rhPenhoras.entity.edit.component.html'
})
export class RhPenhorasEditComponent extends ModuloEntityDetailComponent<IRHPenhoras> implements OnInit {
  @Input() public decimalsLimit: IJsonPenhoraDecimais;
  @Input() public descontosList: Array<IJsonPenhoraDescontos>;

  public readonly rhtipoprocessOutput: string;
  public readonly descontosTemplate: string;
  public readonly dtTipoValorPenhora: string;
  public readonly formaPagamentoEnum: typeof EFormaPagamento;
  public readonly formaPagamentoDataSource: ReadonlyArray<IDataSourceItem<EFormaPagamento>>;

  public promise: Promise<void>;
  public temValorLimiteMinimoImpenhoravel: boolean;
  public abdesComp: string;
  public categAbono: Array<ICategAbono>;
  public isPenhoraPensaoAlimentos: boolean;
  public formaPagamentoData: IDataSourceItem<EFormaPagamento>;
  public mbReferenciaMaxLength: number;

  private readonly _tipoCategoria: typeof ETipoCategoriaDesc;
  private readonly _entityMaintenanceInstanceDescontos: IEntityMaintenanceInstance;
  private _initialAbdesComp: string;
  private _initialModel: IRHPenhoras;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityMaintenanceService: EntityMaintenanceService
  ) {
    super(_injector);
    this.rhtipoprocessOutput = '{{tipoProcessamento}} - {{descricao}}';
    this.abdesComp = '';
    this._initialAbdesComp = '';
    this.temValorLimiteMinimoImpenhoravel = false;
    this.categAbono = [];
    this.descontosTemplate = '{{codAbdesc}} - {{designaBreve}}';
    this._tipoCategoria = ETipoCategoriaDesc;
    this.isPenhoraPensaoAlimentos = false;
    this.dtTipoValorPenhora = DATA_SOURCE_NAME_TIPO_VALOR_PENHORA;
    this._initialModel = undefined;
    this._entityMaintenanceInstanceDescontos = this._entityMaintenanceService.build(ENTITY_NAME_DESCONTOS);
    this.formaPagamentoEnum = EFormaPagamento;
    this.formaPagamentoDataSource = dataSourceFormaPagamento(this._translateService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.entity.getDeleteMessage = (model: unknown, translate: TranslateService) => ({
      title: translate.instant('rhpenhoras.apagarregisto'),
      message: translate.instant('entity.delete.message')
    });
    this._setDefaultModel();

    if (this.model.formaPagamento !== undefined) {
      this.formaPagamentoData = dataSourceFormaPagamento(this._translateService)[this.model.formaPagamento];
    } else {
      this.formaPagamentoData = dataSourceFormaPagamento(this._translateService)[EFormaPagamento.None];
    }
  }

  public cancel(): Promise<void> {
    this.abdesComp = this._initialAbdesComp;
    this.model = {...this._initialModel};
    this._setDesconto();
    this.formaPagamentoData = dataSourceFormaPagamento(this._translateService)[this.model.formaPagamento];
    return super.cancel();
  }

  public save(): Promise<IRHPenhoras> {
    this.model.calcLiqCategNaoIncl = this.model.calcLiqTodasCateg
      ? ''
      : this.categAbono
          .filter((categoria: ICategAbono) => !categoria.selected)
          .map<string>((categoria: ICategAbono) => String(categoria.categ.value))
          .join(',');

    switch (this.formaPagamentoData.value) {
      case EFormaPagamento.None: {
        this.model.iban = '';
        this.model.mbEntidade = '';
        this.model.mbReferencia = '';
        break;
      }
      case EFormaPagamento.IBAN: {
        this.model.referenciaPagamento = '';
        this.model.mbEntidade = '';
        this.model.mbReferencia = '';
        break;
      }
      case EFormaPagamento.MBRef: {
        this.model.referenciaPagamento = '';
        this.model.iban = '';
        break;
      }
      case EFormaPagamento.MBRefEstado: {
        this.model.referenciaPagamento = '';
        this.model.iban = '';
        this.model.mbEntidade = '';
        break;
      }
      default: {
        this.model.referenciaPagamento = '';
        this.model.iban = '';
        this.model.mbEntidade = '';
        this.model.mbReferencia = '';
        break;
      }
    }

    return super.save();
  }

  public setTodosProcessos(value: boolean): void {
    this.model.todosTiposProce = value;
    if (this.model.todosTiposProce) {
      delete this.model.tipoProcessamento;
      delete this.model.nomeTipoProcessamento;
      this.model = {...this.model};
    }
  }

  public evtCodAbdesChanged(value: {inputValue: string; item: IJsonPenhoraDescontos}): void {
    if (isObject(value.item)) {
      this.model.codAbdes = value.item.codAbdesc;
      this.model.nomeAbdes = value.item.designaBreve;
      this._setDesconto();
      this.abdesComp = `${this.model.codAbdes} - ${this.model.nomeAbdes}`;
      this.model.ncodABDESC = toCodAbonoDesconto({kind: ETipoAbonoDesconto.Desconto, value: this.model.codAbdes});
    } else {
      const index = this.descontosList.findIndex((source: IJsonPenhoraDescontos) => source.codAbdesc === value.inputValue);
      if (index !== -1) {
        const desconto: Array<IJsonPenhoraDescontos> = this.descontosList.slice();
        this.model.codAbdes = desconto[index].codAbdesc;
        this.model.nomeAbdes = desconto[index].designaBreve;
        this._setDesconto();
        this.abdesComp = `${this.model.codAbdes} - ${this.model.nomeAbdes}`;
        this.model.ncodABDESC = toCodAbonoDesconto({kind: ETipoAbonoDesconto.Desconto, value: this.model.codAbdes});
      }
    }
  }

  public categAbonoChanged(): void {
    if (this.categAbono.find((value) => value.selected === false)) {
      this.model.calcLiqTodasCateg = false;
    }
  }

  public calcLiqTodasCategChanged(): void {
    for (const iCategAbono of this.categAbono) {
      iCategAbono.selected = this.model.calcLiqTodasCateg;
    }
  }

  public openDescontosMaintenance(): Promise<void> {
    return this._entityMaintenanceInstanceDescontos.maintenanceEdit(this.model.codAbdes).then(() => undefined);
  }

  public formaPagamentoChanged(item: IDataSourceItem<EFormaPagamento>): void {
    if (isObject(item)) {
      this.formaPagamentoData = item;
      this.model.formaPagamento = this.formaPagamentoData.value;
      this.mbReferenciaMaxLength = this.formaPagamentoData.value === EFormaPagamento.MBRef ? MB_REFERENCIA_MAX_LENGTH_9 : MB_REFERENCIA_MAX_LENGTH_15;
      if (this.formaPagamentoData.value === EFormaPagamento.MBRef) {
        this.model.mbReferencia = this.model.mbReferencia.substring(0, this.mbReferenciaMaxLength);
      }
    }
  }

  private _setDefaultModel(): void {
    this.model = merge({}, {...this._emptyModelRem()}, this.model);
    if (!this.model.tipoProcessamento && this.model.nomeTipoProcessamento) {
      this.model.tipoProcessamento = 0;
    }
    if (this.model.ncodABDESC) {
      this.model.codAbdes = this.model.ncodABDESC.substring(1);
    }
    this._setDesconto();
    this._initialModel = {...this.model};
    this._initialAbdesComp = this.abdesComp;
  }

  private _setDesconto(): void {
    if (this.model.codAbdes) {
      this.abdesComp = `${this.model.codAbdes} - ${this.model.nomeAbdes}`;
      const categAliment = this.descontosList.findIndex((value) => value.codAbdesc === this.model.codAbdes);
      this.isPenhoraPensaoAlimentos = this.descontosList[categAliment].categoria === this._tipoCategoria.PenhoraPensaoAlimentos;
    }
    this._setCategoriaAbono(this.isPenhoraPensaoAlimentos);
  }

  private _setCategoriaAbono(isPensaoALim: boolean = false): void {
    this.categAbono = [];
    if (!isPensaoALim) {
      const categNaoIncl: Array<string> = this.model.calcLiqCategNaoIncl ? this.model.calcLiqCategNaoIncl.split(',') : [];
      for (const datum of DATA_SOURCE_TIPOS_CATEGORIA_ABONOS.data) {
        if (datum.value === ETipoCategoria.Virtual) {
          continue;
        }
        const selected: boolean = this.model.calcLiqTodasCateg ? true : !categNaoIncl.includes(String(datum.value));
        this.categAbono.push({
          categ: datum,
          selected: selected
        });
      }
    } else {
      this.model.calcLiqCategNaoIncl = '';
      this.model.calcLiqTodasCateg = true;
      this.model.tipoValorPenhora = ETipoValorPenhora.ValorAbsoluto;
      for (const datum of DATA_SOURCE_TIPOS_CATEGORIA_ABONOS.data) {
        if (datum.value === ETipoCategoria.Virtual) {
          continue;
        }
        this.categAbono.push({
          categ: datum,
          selected: true
        });
      }
    }
  }

  private _emptyModelRem(): IRHPenhoras {
    return {
      rhpenhorasID: EMPTY_GUID,
      ncodABDESC: '',
      codEMP: 0,
      tipoValorPenhora: 0,
      valor: 0,
      dataInicio: moment().startOf('month'),
      tipoProcessamento: 0,
      calcLiqTodasCateg: true,
      calcLiqCategNaoIncl: '',
      valorTotalPenhora: 0,
      obsPenhora: '',
      suspende: false,
      obsSupencao: '',
      valorExternoPenhora: 0,
      dataSuspensao: moment(),
      penhoraPorInsolvenci: false,
      valorLimImpenhoravel: 0,
      referenciaPagamento: '',
      totalValorPenhoraPaga: 0,
      identificacaoPenhora: '',
      nomeEmpr: '',
      nomeTipoProcessamento: 'Todos Processamentos',
      nomeAbdes: '',
      todosTiposProce: true,
      codAbdes: '',
      iban: '',
      mbEntidade: '',
      mbReferencia: '',
      formaPagamento: EFormaPagamento.None
    };
  }
}
