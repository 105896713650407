<div class="user-accesses">
  <!-- GESTÃO COMERCIAL -->
  <div class="user-accesses-gestao-comercial" *ngIf="policy.groupsVisibility.get(categories.Comercial)">
    <div class="card">
      <div class="card-header user-accesses-group-title" [translate]="'usersbasic.accesses.comercial.title'"></div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-sm table-borderless">
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th *ngFor="let header of configsGroupComercial.headers">
                  <div class="user-access-config d-flex align-items-center">
                    <span [translate]="header.name"></span>
                    <pl-edit
                      type="checkbox"
                      attrName="{{ attrName }}-user-access-config-gestao-comercial-{{ header.name }}"
                      [model]="header.selectedAll"
                      (modelChange)="changedHeaderSelectAll($event, header, configsGroupComercial)"
                      [properties]="{disabled: disabled, indeterminate: header.indeterminate}">
                    </pl-edit>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of configsGroupComercial.rows">
                <td>
                  <span [translate]="row.description"></span>
                </td>
                <td class="user-access-config">
                  <pl-edit
                    type="checkbox"
                    class="ms-auto"
                    attrName="{{ attrName }}-user-access-config-gestao-comercial-{{ row.name }}"
                    [model]="row.selectedAll"
                    (modelChange)="changedRowSelectAll($event, row, configsGroupComercial)"
                    [properties]="{disabled: disabled, indeterminate: row.indeterminate}">
                  </pl-edit>
                </td>
                <td *ngFor="let configErp of row.configsErp">
                  <div class="d-flex justify-content-end">
                    <cg-user-accesses-item [config]="configErp" [attrName]="attrName" [disabled]="disabled" (evtValueChanged)="changedTableConfigValue($event, configErp, configsGroupComercial)">
                    </cg-user-accesses-item>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- CONTABILIDADE -->
  <div class="user-accesses-contabilidade" *ngIf="policy.groupsVisibility.get(categories.Contabilidade)">
    <div class="card">
      <div class="card-header user-accesses-group-title" [translate]="'usersbasic.accesses.contabilidade.title'"></div>
      <div class="card-body">
        <cg-user-accesses-item
          *ngFor="let configErp of configsGroupContabilidade"
          [attrName]="attrName"
          [config]="configErp"
          [label]="configErp.name"
          [disabled]="disabled"
          (evtValueChanged)="changedConfigValue($event, configErp)">
        </cg-user-accesses-item>
      </div>
    </div>
  </div>

  <!-- ATIVOS -->
  <div class="user-accesses-ativos" *ngIf="policy.groupsVisibility.get(categories.Ativos)">
    <div class="card">
      <div class="card-header user-accesses-group-title" [translate]="'usersbasic.accesses.ativos.title'"></div>
      <div class="card-body">
        <cg-user-accesses-item
          *ngFor="let configErp of configsGroupAtivos"
          [attrName]="attrName"
          [config]="configErp"
          [label]="configErp.name"
          [disabled]="disabled"
          (evtValueChanged)="changedConfigValue($event, configErp)">
        </cg-user-accesses-item>
      </div>
    </div>
  </div>

  <!-- ADDONS -->
  <div class="user-accesses-addons" *ngIf="policy.groupsVisibility.get(categories.Addons)">
    <div class="card">
      <div class="card-header user-accesses-group-title" [translate]="'usersbasic.accesses.addons.title'"></div>
      <div class="card-body">
        <cg-user-accesses-item
          *ngFor="let configErp of configsGroupAddons"
          [attrName]="attrName"
          [config]="configErp"
          [label]="configErp.name"
          [disabled]="disabled"
          (evtValueChanged)="changedConfigValue($event, configErp)">
        </cg-user-accesses-item>
      </div>
    </div>
  </div>
</div>
