import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'modal-avisos-cobranca-erros',
  templateUrl: './impAvisosCobranca.erros.modal.component.html'
})
export class ImpAvisosCobrancaErrosModalComponent extends CGModalComponent<void> {
  @Input() public erros: Array<string>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }
}
