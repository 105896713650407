<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h4 class="modal-title" [translate]="'gto.config'"></h4>
</div>

<div class="modal-body">
  <pl-form>
    <pl-group>
      <label [translate]="'gto.empresa'"></label>
      <edit>
        <pl-autocomplete
          [source]="empresasSource"
          [model]="model"
          (modelChange)="nEmpresaChanged($event)"
          [rowTemplate]="empresasTemplate"
          [properties]="{allowEmpty: false, disallowClear: true}"
          [output]="empresasTemplate">
        </pl-autocomplete>
      </edit>
    </pl-group>

    <pl-tabs [theme]="'style3'">
      <pl-tab caption="gto.tabs.title.ativarTarefas">
        <div *plTabContent>
          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinition"
            cgDxDataGridInstanceName="gtoConfig"
            [dataSource]="dataGridDefinition.dataSource"
            [(selectedRowKeys)]="selectedRowKeys"
            (onInitialized)="onDataGridInitialized($event)"
            (onSelectionChanged)="onDataGridSelectionChanged($event)">
            <div *dxTemplate="let cell of 'editCellTemplateResponsavel'">
              <pl-autocomplete [source]="responsaveis" [model]="cell.data" (modelChange)="changedResponsavel(cell, $event)" [rowTemplate]="usersTemplate" [output]="usersTemplate"></pl-autocomplete>
            </div>
          </dx-data-grid>
        </div>
      </pl-tab>

      <pl-tab caption="gto.tabs.title.configuracoes">
        <div *plTabContent [plPromise]="promiseConfig">
          <div class="row">
            <div class="col-md-6">
              <fieldset>
                <legend [translate]="'gto.ativarGTONaEmpresa'"></legend>
                <pl-group>
                  <label [translate]="'gto.inicio'"></label>
                  <edit>
                    <pl-edit-datepicker [(model)]="model.empConfig.startGTO"></pl-edit-datepicker>
                  </edit>
                </pl-group>
              </fieldset>
            </div>
            <div class="col-md-6" *ngIf="!storeModePublic">
              <fieldset>
                <legend [translate]="'gto.alertasNaApp'"></legend>
                <pl-group>
                  <label [translate]="'gto.inicio'"></label>
                  <edit>
                    <pl-edit type="cginteger" [(model)]="model.empConfig.alertNDias" [properties]="{validators: {min: {value: 0}}}"></pl-edit>
                  </edit>
                </pl-group>
              </fieldset>
            </div>
          </div>
          <div class="col-md-12 text-end">
            <br />
            <button type="button" class="btn btn-sm btn-primary" [click]="fnSaveEmpresaConfig" plPromise><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'gto.saveConfig'"></span></button>
          </div>
        </div>
      </pl-tab>

      <pl-tab caption="gto.tabs.title.configuracoesMulti">
        <div *plTabContent [plPromise]="promiseGlobalConfig">
          <div class="row">
            <div class="col-md-6">
              <fieldset>
                <legend [translate]="'gto.ativarGTONaEmpresa'"></legend>
                <pl-group>
                  <label [translate]="'gto.inicio'"></label>
                  <edit>
                    <pl-edit-datepicker [(model)]="model.globalConfig.startGTO"></pl-edit-datepicker>
                  </edit>
                </pl-group>
              </fieldset>
            </div>
            <div class="col-md-6" *ngIf="!storeModePublic">
              <fieldset>
                <legend [translate]="'gto.alertasNaApp'"></legend>
                <pl-group>
                  <label [translate]="'gto.inicio'"></label>
                  <edit>
                    <pl-edit type="cginteger" [(model)]="model.globalConfig.alertNDias" [properties]="{validators: {min: {value: 0}}}"></pl-edit>
                  </edit>
                </pl-group>
              </fieldset>
            </div>
          </div>
          <div class="col-md-12 text-end">
            <br />
            <button type="button" class="btn btn-sm btn-primary" [click]="fnSaveGlobalConfig" plPromise><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'gto.saveConfig'"></span></button>
          </div>
        </div>
      </pl-tab>
    </pl-tabs>
  </pl-form>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light btn-sm" (click)="close()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
