import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {TDate} from '../../../../../common/dates';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {AnaliseMargemLucroService} from '../analisemargemlucro.module.service';
import {EReport} from '../../../../entities/reports/reports.interface';
import {EModoFuncionamentoAnaliseMargemLucro} from '../../../grupoDocfaConfiguracoes/grupoDocfaConfiguracoes.module.interface';

@Component({
  selector: 'analise-margem-lucro-print-modal',
  templateUrl: './analisemargemlucro.print.modal.html'
})
export class AnaliseMargemLucroPrintModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public modo: EModoFuncionamentoAnaliseMargemLucro;
  @Input() public dataDe: TDate;
  @Input() public dataAte: TDate;
  @Input() public estado: number;
  @Input() public codigo?: string;

  public reportModel: IJsonReport;
  public pdfUrl: string;
  public reportType: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _analiseMargemLucroService: AnaliseMargemLucroService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this.reportType = EReport.AnaliseMargemLucro;
    this._loadDefaultReport();
  }

  public onReportChange(report: IJsonReport): void {
    this.reportModel = report;
  }

  public async loadPdf(): Promise<void> {
    this.pdfUrl = await this._getPdfUrl();
  }

  private _getPdfUrl(): Promise<string> {
    const detalhe = this._configService.configurations.gestaoComercial.analiseMargemLucro.reports.reportImpressao.toUpperCase() !== this.reportModel.name.toUpperCase();
    return this._analiseMargemLucroService.getPdfUrl(this.reportModel.name, this.modo, this.dataDe, this.dataAte, this.estado, this.codigo, detalhe);
  }

  private _loadDefaultReport(): Promise<void> {
    this.reportModel = {
      name: this._configService.configurations.gestaoComercial.analiseMargemLucro.reports.reportImpressao,
      title: this._configService.configurations.gestaoComercial.analiseMargemLucro.reports.titleReportImpressao
    };

    return this._reportsRegistryService
      .get(EReport.AnaliseMargemLucro)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          let foundDefault = false;
          for (const report of reports) {
            if (report.name.toUpperCase() === this.reportModel.name.toUpperCase()) {
              this.reportModel = {name: report.name, title: report.title};
              foundDefault = true;
              break;
            }
          }

          if (!foundDefault) {
            this.reportModel = {
              name: reports[0].name,
              title: reports[0].title
            };
          }
        }
      });
  }
}
