import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {$sleek, ISleek} from '@centralgest/sleekplan-sdk';
import {APP_USER_FEEDBACK_TOKEN} from '../../../common/app';
import {AuthService} from '../auth/auth.service';
import {CGLocalStorageGroupService} from '../storage/localstoragegroup.service';
import {ConfigSiteService} from '../configsite.service';
import {EGroupName, LOCAL_STORAGE_USER_FEEDBACK_TOKEN} from '../../../config/constants';
import {IJsonUserFeedbackConfig} from '../../interfaces/jsonUserfeedback.interface';
import {IUserFeedbackConfig} from './userfeedback.service.interface';
import {SCHEMA_STRING} from '../../../common/schemas';
import {TUserSession} from '../account/jsonUserApi.interface';

@Injectable({
  providedIn: 'root'
})
export class UserFeedbackService implements OnDestroy {
  private readonly _subjectActive: BehaviorSubject<boolean>;
  private readonly _subjectConfig: BehaviorSubject<IUserFeedbackConfig>;
  private readonly _subscription: Subscription;
  private _observableActive: Observable<boolean>;

  constructor(
    private readonly _authService: AuthService,
    private readonly _configSiteService: ConfigSiteService,
    private readonly _localStorageService: CGLocalStorageGroupService
  ) {
    this._subjectActive = new BehaviorSubject<boolean>(false);
    this._subjectConfig = new BehaviorSubject<IUserFeedbackConfig>(undefined);
    this._subscription = combineLatest([APP_USER_FEEDBACK_TOKEN, this._authService.identityAsObservable(), this._configSiteService.userFeedbackConfig()]).subscribe(
      ([userFeedbackToken, identity, config]: [string, TUserSession, IJsonUserFeedbackConfig]) => {
        this._subjectConfig.next({...config, token: userFeedbackToken});

        const active = Boolean(userFeedbackToken) && identity && config?.active;

        // Wait for the configuration to be loaded before initializing Sleek
        if (active && config.productId) {
          const sleek: ISleek = this.sleek();
          if (userFeedbackToken) {
            this._localStorageService.setItem(LOCAL_STORAGE_USER_FEEDBACK_TOKEN, userFeedbackToken, SCHEMA_STRING, EGroupName.GLOBAL).subscribe();
            if (!sleek.initialized) {
              sleek.init(userFeedbackToken);
            } else {
              sleek.setUser({token: userFeedbackToken});
            }
          } else {
            this._localStorageService.removeItem(LOCAL_STORAGE_USER_FEEDBACK_TOKEN, EGroupName.GLOBAL).subscribe();
            if (sleek.initialized) {
              sleek.resetUser();
            }
          }
        }

        this._subjectActive.next(active);
      }
    );

    // Load user feedback token from storage if not already set
    this._localStorageService.getItem(LOCAL_STORAGE_USER_FEEDBACK_TOKEN, SCHEMA_STRING, EGroupName.GLOBAL).subscribe((userFeedbackToken: string) => {
      if (userFeedbackToken && !APP_USER_FEEDBACK_TOKEN.value) {
        APP_USER_FEEDBACK_TOKEN.next(userFeedbackToken);
      }
    });
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public active(): Observable<boolean> {
    if (!this._observableActive) {
      this._observableActive = combineLatest([APP_USER_FEEDBACK_TOKEN, this._configSiteService.userFeedbackConfig()]).pipe(
        map(([userFeedbackToken, config]: [string, IJsonUserFeedbackConfig]) => Boolean(userFeedbackToken) && config?.active)
      );
    }
    return this._observableActive;
  }

  public config(): Observable<IUserFeedbackConfig> {
    return this._subjectConfig;
  }

  public sleek(): ISleek {
    return $sleek;
  }
}
