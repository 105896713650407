import {fromEvent, lastValueFrom, Subscription} from 'rxjs';
import {Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpProgressEvent, HttpRequest, HttpResponse} from '@angular/common/http';
import {capitalize, interpolate, isArray, isBlob, isBoolean, isDefined, isDefinedNotNull, isFunction, isNumber, isObject, isString, isUndefined} from '../common/utilities/utilities';
import {
  DEFAULT_FILE_SIZE_BASE,
  DEFAULT_MAX_FILE_SIZE,
  DEFAULT_MAX_THUMBNAIL_FILE_SIZE,
  DEFAULT_PARALLEL_UPLOADS,
  DEFAULT_REPORT_PROGRESS,
  DEFAULT_RESIZE_BEFORE_ACCEPT,
  DEFAULT_RESIZE_METHOD,
  DEFAULT_RESIZE_QUALITY,
  DEFAULT_RESPONSE_TYPE,
  DEFAULT_THUMBNAIL_HEIGHT,
  DEFAULT_THUMBNAIL_METHOD,
  DEFAULT_THUMBNAIL_WIDTH,
  DEFAULT_ZIP_MIN_SIZE,
  EPlUploadStatus,
  IPlUploadCallback,
  IPlUploadDataBlock,
  IPlUploadEventTotalProgressChanged,
  IPlUploadFile,
  IPlUploadHeaders,
  IPlUploadParams,
  IPlUploadProperties,
  IPlUploadVisualFile,
  TPlUploadAcceptFn,
  TPlUploadMethod,
  TPlUploadRenameFileFn,
  TPlUploadResizeFn,
  TPlUploadResizeMethod,
  TPlUploadResponseType,
  TPlUploadThumbnailMethod
} from './upload.component.interface';
import {fileExtensionToMimeType, toFormData} from '../common/files/files';
import {FileListPipelineHandler} from '../common/pipeline/file/filelist.pipeline';
import {IPlImageResizerProperties, ONE_KIBIBYTE} from '../common/imageresizer/image.resizer.interface';
import type {IPlLocale, IPlLocaleUploaderUnits} from '../common/locale/locales.interface';
import {Logger} from '../logger/logger';
import {PlImageResizer} from '../common/imageresizer/image.resizer';
import {PlLocaleService} from '../common/locale/locale.service';
import {PlUploadConfigService} from './upload.config.service';
import {PlUploadErrorHandler} from './upload.errorhandler';
import type {TValueOrPromise} from '../common/utilities/utilities.interface';

const UPLOADER_UNITS: Array<keyof IPlLocaleUploaderUnits> = ['b', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

@Component({
  selector: 'pl-upload',
  templateUrl: './upload.component.html',
  standalone: false
})
export class PlUploadComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public url: string;
  @Input() public accept: TPlUploadAcceptFn;
  @Input() public acceptedFiles: string; // MIME types separated by comma
  @Input() public autoProcessQueue: boolean;
  @Input() public autoQueue: boolean;
  @Input() public droppable: boolean;
  @Input() public clickable: boolean;
  @Input() public createImageThumbnails: boolean;
  @Input() public fileSizeBase: number;
  @Input() public headers: IPlUploadHeaders;
  @Input() public hideGlobalActions: boolean;
  @Input() public hideActions: boolean;
  @Input() public hideActionCancel: boolean;
  @Input() public hideActionRemoveAll: boolean;
  @Input() public hideActionRemove: boolean;
  @Input() public hideActionRetry: boolean;
  @Input() public hideActionUploadAll: boolean;
  @Input() public hideActionUpload: boolean;
  @Input() public maxFiles: number;
  @Input() public maxFileSize: number; // In megabytes
  @Input() public maxThumbnailFileSize: number; // In megabytes
  @Input() public method: TPlUploadMethod;
  @Input() public parallelUploads: number;
  @Input() public paramName: string;
  @Input() public params: IPlUploadParams;
  @Input() public formData: object | FormData;
  @Input() public renameFile: TPlUploadRenameFileFn;
  @Input() public resizeWidth: number;
  @Input() public resizeHeight: number;
  @Input() public resizeTargetSize: number; // In megabytes
  @Input() public resizeTargetSizeStep: number; // In pixels
  @Input() public resizeMimeType: string;
  @Input() public resizeQuality: number;
  @Input() public resizeMethod: TPlUploadResizeMethod;
  @Input() public resizeFn: TPlUploadResizeFn;
  @Input() public resizeBeforeAccept: boolean;
  @Input() public reportProgress: boolean;
  @Input() public responseType: TPlUploadResponseType;
  @Input() public thumbnailHeight: number;
  @Input() public thumbnailMethod: TPlUploadThumbnailMethod;
  @Input() public thumbnailWidth: number;
  @Input() public uploadMultiple: boolean;
  @Input() public withCredentials: boolean;
  @Input() public zip: boolean;
  @Input() public zipMinSize: number; // In megabytes, ignored if `zipStandalone` is false
  @Input() public zipStandalone: boolean;
  @Input() public properties: Partial<IPlUploadProperties>;
  @Input() public callback: IPlUploadCallback;
  @Output() public readonly evtDragEnter: EventEmitter<Event>;
  @Output() public readonly evtDragLeave: EventEmitter<Event>;
  @Output() public readonly evtDragOver: EventEmitter<Event>;
  @Output() public readonly evtDropped: EventEmitter<Event>;
  @Output() public readonly evtAcceptedFile: EventEmitter<IPlUploadFile>;
  @Output() public readonly evtAcceptedFiles: EventEmitter<Array<IPlUploadFile>>;
  @Output() public readonly evtRejectedFile: EventEmitter<IPlUploadFile>;
  @Output() public readonly evtRejectedFiles: EventEmitter<Array<IPlUploadFile>>;
  @Output() public readonly evtRemovedFile: EventEmitter<IPlUploadFile>;
  @Output() public readonly evtRemovedFiles: EventEmitter<Array<IPlUploadFile>>;
  @Output() public readonly evtCanceledFileUpload: EventEmitter<IPlUploadFile>;
  @Output() public readonly evtCanceledFilesUpload: EventEmitter<Array<IPlUploadFile>>;
  @Output() public readonly evtTotalProgressChanged: EventEmitter<IPlUploadEventTotalProgressChanged>;
  @Output() public readonly evtUploadedFile: EventEmitter<IPlUploadFile>;
  @Output() public readonly evtUploadErrored: EventEmitter<IPlUploadFile>;
  @Output() public readonly evtUploadedFiles: EventEmitter<Array<IPlUploadFile>>;
  @Output() public readonly evtUploadErrors: EventEmitter<Array<IPlUploadFile>>;
  @Output() public readonly evtFinishedUpload: EventEmitter<Array<IPlUploadFile>>;

  public readonly enumStatus: typeof EPlUploadStatus;
  public locale: IPlLocale;
  public uploadFiles: Array<IPlUploadVisualFile>;
  public needsClick: boolean;
  public draggingOver: boolean;
  public textMessage: string;
  public textValidators: string;
  public totalUploadProgress: number;
  public uploadingAll: boolean;
  public queuedFiles: number;

  private readonly _element: HTMLElement;
  private readonly _subscriptionLocale: Subscription;
  private readonly _subscriptionConfigOptions: Subscription;
  private readonly _thumbnailQueue: Array<IPlUploadVisualFile>;
  private _hiddenFileInput: HTMLInputElement;
  private _configProperties: IPlUploadProperties;
  private _options: IPlUploadProperties;
  private _promiseProcessingThumbnail: Promise<void>;
  private _autoQueue: boolean;
  private _subscriptionFileInputChange: Subscription;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _renderer: Renderer2,
    private readonly _httpClient: HttpClient,
    private readonly _logger: Logger,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plUploadConfigService: PlUploadConfigService,
    private readonly _fileListPipelineHandler: FileListPipelineHandler,
    private readonly _plUploadErrorHandler: PlUploadErrorHandler
  ) {
    this.evtDragEnter = new EventEmitter<Event>();
    this.evtDragLeave = new EventEmitter<Event>();
    this.evtDragOver = new EventEmitter<Event>();
    this.evtDropped = new EventEmitter<Event>();
    this.evtAcceptedFile = new EventEmitter<IPlUploadFile>();
    this.evtAcceptedFiles = new EventEmitter<Array<IPlUploadFile>>();
    this.evtRejectedFile = new EventEmitter<IPlUploadFile>();
    this.evtRejectedFiles = new EventEmitter<Array<IPlUploadFile>>();
    this.evtRemovedFile = new EventEmitter<IPlUploadFile>();
    this.evtRemovedFiles = new EventEmitter<Array<IPlUploadFile>>();
    this.evtCanceledFileUpload = new EventEmitter<IPlUploadFile>();
    this.evtCanceledFilesUpload = new EventEmitter<Array<IPlUploadFile>>();
    this.evtTotalProgressChanged = new EventEmitter<IPlUploadEventTotalProgressChanged>();
    this.evtUploadedFile = new EventEmitter<IPlUploadFile>();
    this.evtUploadErrored = new EventEmitter<IPlUploadFile>();
    this.evtUploadedFiles = new EventEmitter<Array<IPlUploadFile>>();
    this.evtUploadErrors = new EventEmitter<Array<IPlUploadFile>>();
    this.evtFinishedUpload = new EventEmitter<Array<IPlUploadFile>>();
    this.enumStatus = EPlUploadStatus;
    this.uploadFiles = [];
    this.needsClick = true;
    this.draggingOver = false;
    this.totalUploadProgress = 0;
    this.uploadingAll = false;
    this.queuedFiles = 0;
    this._element = this._elementRef.nativeElement;
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.locale = locale;
      this._formatMessage();
      this._formatValidators();
    });
    this._subscriptionConfigOptions = this._plUploadConfigService.get().subscribe((configProperties: IPlUploadProperties) => {
      const firstTime: boolean = isUndefined(this._configProperties);
      this._configProperties = configProperties;
      if (!firstTime) {
        this._handleChanges();
      }
    });
    this._thumbnailQueue = [];
    this._promiseProcessingThumbnail = undefined;
    this._autoQueue = true;
  }

  public ngOnInit(): void {
    this._handleChanges();
    this._formatMessage();
    this._setupHiddenFileInput();
  }

  public ngOnChanges({
    callback,
    properties,
    url,
    method,
    withCredentials,
    parallelUploads,
    uploadMultiple,
    maxFileSize,
    paramName,
    createImageThumbnails,
    maxThumbnailFileSize,
    thumbnailWidth,
    thumbnailHeight,
    thumbnailMethod,
    resizeWidth,
    resizeHeight,
    resizeTargetSize,
    resizeTargetSizeStep,
    resizeMimeType,
    resizeQuality,
    resizeMethod,
    resizeFn,
    resizeBeforeAccept,
    fileSizeBase,
    maxFiles,
    headers,
    hideGlobalActions,
    hideActions,
    hideActionCancel,
    hideActionRemoveAll,
    hideActionRemove,
    hideActionRetry,
    hideActionUploadAll,
    hideActionUpload,
    droppable,
    clickable,
    acceptedFiles,
    autoProcessQueue,
    autoQueue,
    renameFile,
    accept,
    params,
    formData,
    zip,
    zipMinSize,
    zipStandalone,
    reportProgress,
    responseType
  }: SimpleChanges): void {
    if (callback && isObject(callback.currentValue)) {
      const uploadCallback: IPlUploadCallback = callback.currentValue;
      uploadCallback.getFilesWithStatus = (status: EPlUploadStatus | Array<EPlUploadStatus>) => this.getFilesWithStatus(status);
      uploadCallback.getQueuedFiles = () => this.getQueuedFiles();
      uploadCallback.getUploadingFiles = () => this.getUploadingFiles();
      uploadCallback.getAddedFiles = () => this.getAddedFiles();
      uploadCallback.getAcceptedFiles = () => this.getAcceptedFiles();
      uploadCallback.getRejectedFiles = () => this.getRejectedFiles();
      uploadCallback.getActiveFiles = () => this.getActiveFiles();
      uploadCallback.getErroredFiles = () => this.getErroredFiles();
      uploadCallback.getSuccessFiles = () => this.getSuccessFiles();
      uploadCallback.getPendingFiles = () => this.getPendingFiles();
      uploadCallback.addFiles = (fileList: FileList) => this.addFiles(fileList);
      uploadCallback.addFile = (file: File) => this.addFile(file);
      uploadCallback.removeAllFiles = (cancelIfNecessary?: boolean) => {
        this.removeAllFiles(cancelIfNecessary);
      };
      uploadCallback.removeFile = (uploadFile: IPlUploadVisualFile) => this.removeFile(uploadFile);
      uploadCallback.uploadAll = () => this.uploadAll();
      uploadCallback.processQueue = () => this.processQueue();
      uploadCallback.cancelFileUpload = (uploadFile: IPlUploadFile) => this.cancelFileUpload(uploadFile);
      uploadCallback.openFileDialog = () => this.openFileDialog();
    }
    if (properties && !properties.isFirstChange()) {
      this._handleChanges();
    }
    if (url && !url.isFirstChange()) {
      this._changedUrl(url.currentValue);
    }
    if (method && !method.isFirstChange()) {
      this._changedMethod(method.currentValue);
    }
    if (withCredentials && !withCredentials.isFirstChange()) {
      this._changedWithCredentials(withCredentials.currentValue);
    }
    if (withCredentials && !withCredentials.isFirstChange()) {
      this._changedWithCredentials(withCredentials.currentValue);
    }
    if (parallelUploads && !parallelUploads.isFirstChange()) {
      this._changedParallelUploads(parallelUploads.currentValue);
    }
    if (uploadMultiple && !uploadMultiple.isFirstChange()) {
      this._changedUploadMultiple(uploadMultiple.currentValue);
      this._setupHiddenFileInput();
    }
    if (maxFileSize && !maxFileSize.isFirstChange()) {
      this._changedMaxFileSize(maxFileSize.currentValue);
    }
    if (paramName && !paramName.isFirstChange()) {
      this._changedParamName(paramName.currentValue);
    }
    if (createImageThumbnails && !createImageThumbnails.isFirstChange()) {
      this._changedCreateImageThumbnails(createImageThumbnails.currentValue);
    }
    if (maxThumbnailFileSize && !maxThumbnailFileSize.isFirstChange()) {
      this._changedMaxThumbnailFileSize(maxThumbnailFileSize.currentValue);
    }
    if (thumbnailWidth && !thumbnailWidth.isFirstChange()) {
      this._changedThumbnailWidth(thumbnailWidth.currentValue);
    }
    if (thumbnailHeight && !thumbnailHeight.isFirstChange()) {
      this._changedThumbnailHeight(thumbnailHeight.currentValue);
    }
    if (thumbnailMethod && !thumbnailMethod.isFirstChange()) {
      this._changedThumbnailMethod(thumbnailMethod.currentValue);
    }
    if (resizeWidth && !resizeWidth.isFirstChange()) {
      this._changedResizeWidth(resizeWidth.currentValue);
    }
    if (resizeHeight && !resizeHeight.isFirstChange()) {
      this._changedResizeHeight(resizeHeight.currentValue);
    }
    if (resizeTargetSize && !resizeTargetSize.isFirstChange()) {
      this._changedResizeTargetSize(resizeTargetSize.currentValue);
    }
    if (resizeTargetSizeStep && !resizeTargetSizeStep.isFirstChange()) {
      this._changedResizeTargetSizeStep(resizeTargetSizeStep.currentValue);
    }
    if (resizeMimeType && !resizeMimeType.isFirstChange()) {
      this._changedResizeMimeType(resizeMimeType.currentValue);
    }
    if (resizeQuality && !resizeQuality.isFirstChange()) {
      this._changedResizeQuality(resizeQuality.currentValue);
    }
    if (resizeMethod && !resizeMethod.isFirstChange()) {
      this._changedResizeMethod(resizeMethod.currentValue);
    }
    if (resizeFn && !resizeFn.isFirstChange()) {
      this._changedResizeFn(resizeFn.currentValue);
    }
    if (resizeBeforeAccept && !resizeBeforeAccept.isFirstChange()) {
      this._changedResizeBeforeAccept(resizeBeforeAccept.currentValue);
    }
    if (fileSizeBase && !fileSizeBase.isFirstChange()) {
      this._changedFileSizeBase(fileSizeBase.currentValue);
    }
    if (maxFiles && !maxFiles.isFirstChange()) {
      this._changedMaxFiles(maxFiles.currentValue);
      this._setupHiddenFileInput();
    }
    if (headers && !headers.isFirstChange()) {
      this._changedHeaders(headers.currentValue);
    }
    if (hideGlobalActions && !hideGlobalActions.isFirstChange()) {
      this._changedHideGlobalActions(hideGlobalActions.currentValue);
    }
    if (hideActions && !hideActions.isFirstChange()) {
      this._changedHideActions(hideActions.currentValue);
    }
    if (hideActionCancel && !hideActionCancel.isFirstChange()) {
      this._changedHideActionCancel(hideActionCancel.currentValue);
    }
    if (hideActionRemoveAll && !hideActionRemoveAll.isFirstChange()) {
      this._changedHideActionRemoveAll(hideActionRemoveAll.currentValue);
    }
    if (hideActionRemove && !hideActionRemove.isFirstChange()) {
      this._changedHideActionRemove(hideActionRemove.currentValue);
    }
    if (hideActionRetry && !hideActionRetry.isFirstChange()) {
      this._changedHideActionRetry(hideActionRetry.currentValue);
    }
    if (hideActionUploadAll && !hideActionUploadAll.isFirstChange()) {
      this._changedHideActionUploadAll(hideActionUploadAll.currentValue);
    }
    if (hideActionUpload && !hideActionUpload.isFirstChange()) {
      this._changedHideActionUpload(hideActionUpload.currentValue);
    }
    if (droppable && !droppable.isFirstChange()) {
      this._changedDroppable(droppable.currentValue);
      this._formatMessage();
    }
    if (clickable && !clickable.isFirstChange()) {
      this._changedClickable(clickable.currentValue);
      this._formatMessage();
    }
    if (acceptedFiles && !acceptedFiles.isFirstChange()) {
      this._changedAcceptedFiles(acceptedFiles.currentValue);
      this._setupHiddenFileInput();
    }
    if (autoProcessQueue && !autoProcessQueue.isFirstChange()) {
      this._changedAutoProcessQueue(autoProcessQueue.currentValue);
    }
    if (autoQueue && !autoQueue.isFirstChange()) {
      this._changedAutoQueue(autoQueue.currentValue);
    }
    if (renameFile && !renameFile.isFirstChange()) {
      this._changedRenameFile(renameFile.currentValue);
    }
    if (accept && !accept.isFirstChange()) {
      this._changedAccept(accept.currentValue);
    }
    if (params && !params.isFirstChange()) {
      this._changedParams(params.currentValue);
    }
    if (formData && !formData.isFirstChange()) {
      this._changedFormData(formData.currentValue);
    }
    if (zip && !zip.isFirstChange()) {
      this._changedZip(zip.currentValue);
    }
    if (zipMinSize && !zipMinSize.isFirstChange()) {
      this._changedZipMinSize(zipMinSize.currentValue);
    }
    if (zipStandalone && !zipStandalone.isFirstChange()) {
      this._changedZipStandalone(zipStandalone.currentValue);
    }
    if (reportProgress && !reportProgress.isFirstChange()) {
      this._changedReportProgress(reportProgress.currentValue);
    }
    if (responseType && !responseType.isFirstChange()) {
      this._changedResponseType(responseType.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
    this._subscriptionConfigOptions.unsubscribe();
    this.removeAllFiles(true);
    this._resetHiddenFileInput();
  }

  public getFilesWithStatus(status: EPlUploadStatus | Array<EPlUploadStatus>): Array<IPlUploadFile> {
    const statuses: Array<EPlUploadStatus> = isArray(status) ? status : [status];
    return this.uploadFiles.filter((uploadFile: IPlUploadFile) => statuses.includes(uploadFile.status));
  }

  public getQueuedFiles(): Array<IPlUploadFile> {
    return this.getFilesWithStatus(EPlUploadStatus.Queued);
  }

  public getUploadingFiles(): Array<IPlUploadFile> {
    return this.getFilesWithStatus(EPlUploadStatus.Uploading);
  }

  public getAddedFiles(): Array<IPlUploadFile> {
    return this.getFilesWithStatus(EPlUploadStatus.Added);
  }

  public getAcceptedFiles(): Array<IPlUploadFile> {
    return this.uploadFiles.filter((uploadFile: IPlUploadFile) => uploadFile.accepted);
  }

  public getRejectedFiles(): Array<IPlUploadFile> {
    return this.uploadFiles.filter((uploadFile: IPlUploadFile) => !uploadFile.accepted);
  }

  public getActiveFiles(): Array<IPlUploadFile> {
    return this.getFilesWithStatus([EPlUploadStatus.Queued, EPlUploadStatus.Uploading]);
  }

  public getErroredFiles(): Array<IPlUploadFile> {
    return this.getFilesWithStatus(EPlUploadStatus.Error);
  }

  public getSuccessFiles(): Array<IPlUploadFile> {
    return this.getFilesWithStatus(EPlUploadStatus.Success);
  }

  public getPendingFiles(): Array<IPlUploadFile> {
    return this.getFilesWithStatus([EPlUploadStatus.Added, EPlUploadStatus.Queued, EPlUploadStatus.Uploading]);
  }

  public addFiles(fileList: FileList): Promise<void> {
    const files: Array<File> = [];
    for (let i = 0; i < fileList.length; i++) {
      const file: File = fileList.item(i);
      if (file) {
        files.push(file);
      }
    }
    this._autoQueue = false;
    const doAddFiles: () => Promise<void> = () => {
      const file = files.shift();
      if (!file) {
        return Promise.resolve();
      }
      return this.addFile(file)
        .then(() => {
          return doAddFiles();
        })
        .catch((reason: unknown) => {
          this._logger.error(reason);
          return doAddFiles();
        });
    };
    return doAddFiles().finally(() => {
      const acceptedFiles: Array<IPlUploadFile> = this.getAcceptedFiles();
      if (acceptedFiles.length) {
        this.evtAcceptedFiles.emit(acceptedFiles);
      }
      const rejectedFiles: Array<IPlUploadFile> = this.getRejectedFiles();
      if (rejectedFiles.length) {
        this.evtRejectedFiles.emit(rejectedFiles);
      }
      this._autoQueue = true;
      if (this.autoQueue) {
        for (const acceptedFile of acceptedFiles) {
          this._enqueueFile(acceptedFile).catch((reason: unknown) => {
            this._logger.error(reason);
          });
        }
      }
    });
  }

  public addFile(file: File): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const newFile: IPlUploadVisualFile = {
        file: undefined,
        status: EPlUploadStatus.Added,
        statusMessage: '',
        accepted: false,
        rejected: false,
        captionName: file.name,
        captionSize: undefined,
        thumbnail: {
          preview: undefined,
          width: 0,
          height: 0
        },
        upload: {
          progress: 0,
          total: 0,
          bytesSent: 0,
          filename: file.name,
          response: undefined
        },
        subscription: undefined
      };
      this.uploadFiles.push(newFile);
      const filePromise: Promise<File> = !this.resizeBeforeAccept ? Promise.resolve(file) : this._resizeFile(file);
      Promise.resolve(filePromise)
        .then((resizedFile: File) => {
          file = resizedFile;
          newFile.file = file;
          newFile.captionSize = this._formatFileSize(file.size);
          newFile.upload.total = file.size;
          this._renameFile(file)
            .then((filename: string) => {
              newFile.captionName = filename;
              newFile.upload.filename = filename;
              this._generateThumbnail(newFile).catch((reason: unknown) => {
                this._logger.error('Failed to generate a thumbnail.', reason);
              });
              this._accept(newFile.file)
                .then(() => {
                  newFile.accepted = true;
                  newFile.rejected = false;
                  this.evtAcceptedFile.emit(newFile);
                  if (this.autoQueue && this._autoQueue) {
                    // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
                    this._enqueueFile(newFile).then(resolve).catch(reject);
                  } else {
                    resolve();
                  }
                })
                // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
                .catch((reason: boolean | string) => {
                  newFile.accepted = false;
                  newFile.rejected = true;
                  newFile.status = EPlUploadStatus.Error;
                  newFile.statusMessage = isBoolean(reason) ? '' : reason;
                  this.evtRejectedFile.emit(newFile);
                  // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
                  reject(reason);
                });
            })
            // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
            .catch(reject);
        })
        // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
        .catch(reject);
    });
  }

  public removeAllFiles(cancelIfNecessary: boolean = false): void {
    const removedFiles: Array<IPlUploadFile> = [];
    for (const file of this.uploadFiles.slice()) {
      if (file.status !== EPlUploadStatus.Uploading || cancelIfNecessary) {
        const removed: boolean = this.removeFile(file, false);
        if (removed) {
          removedFiles.push(file);
        }
      }
    }
    if (removedFiles.length) {
      this._updateTotalUploadProgress();
      this._setupHiddenFileInput();
      this.evtRemovedFiles.emit(removedFiles);
    }
  }

  public removeFile(uploadFile: IPlUploadVisualFile, refreshView: boolean = true): boolean {
    const index = this.uploadFiles.indexOf(uploadFile);
    if (index === -1) {
      return false;
    }
    if (uploadFile.status === EPlUploadStatus.Uploading) {
      this.cancelFileUpload(uploadFile).catch((reason: unknown) => {
        this._logger.error(reason);
      });
    }
    this.uploadFiles.splice(index, 1);
    if (refreshView) {
      this._updateTotalUploadProgress();
      this._setupHiddenFileInput();
    }
    this.evtRemovedFile.emit(uploadFile);
    return true;
  }

  public uploadAll(): Promise<void> {
    this.uploadingAll = true;
    return this.processQueue().finally(() => {
      this.uploadingAll = false;
    });
  }

  public processQueue(): Promise<void> {
    const parallelUploads: number = this.parallelUploads;
    const processingLength: number = this.getUploadingFiles().length;
    let i = processingLength;
    if (processingLength >= parallelUploads) {
      return Promise.resolve();
    }
    const queuedFiles = this.getQueuedFiles();
    if (!queuedFiles.length) {
      return Promise.resolve();
    }
    if (this.uploadMultiple) {
      // The files should be uploaded in one request
      return this.processFiles(queuedFiles.slice(0, parallelUploads - processingLength));
    }
    const promises: Array<Promise<void>> = [];
    while (i < parallelUploads) {
      if (!queuedFiles.length) {
        break;
      }
      promises.push(this.processFiles(queuedFiles.shift()));
      i++;
    }
    return Promise.all(promises).then(() => {
      return undefined;
    });
  }

  public processFiles(uploadFileOrFiles: IPlUploadFile | Array<IPlUploadFile>): Promise<void> {
    const uploadFiles: Array<IPlUploadFile> = isArray(uploadFileOrFiles) ? uploadFileOrFiles : [uploadFileOrFiles];
    for (const uploadFile of uploadFiles) {
      uploadFile.status = EPlUploadStatus.Uploading;
    }
    return this._uploadFiles(uploadFiles);
  }

  public cancelFileUpload(uploadFile: IPlUploadFile): Promise<void> {
    if (uploadFile.status === EPlUploadStatus.Uploading) {
      if (isDefined(uploadFile.subscription)) {
        uploadFile.subscription.unsubscribe();
        const groupedFiles: Array<IPlUploadFile> = this._getFilesWithSubscription(uploadFile.subscription);
        for (const groupedFile of groupedFiles) {
          groupedFile.status = EPlUploadStatus.Canceled;
          this.evtCanceledFileUpload.emit(groupedFile);
        }
        if (this.uploadMultiple && groupedFiles.length) {
          this.evtCanceledFilesUpload.emit(groupedFiles);
        }
      }
    } else if (uploadFile.status === EPlUploadStatus.Added || uploadFile.status === EPlUploadStatus.Queued) {
      uploadFile.status = EPlUploadStatus.Canceled;
      this.evtCanceledFileUpload.emit(uploadFile);
    }
    return this.autoProcessQueue ? this.processQueue() : Promise.resolve();
  }

  public clickedDropZone(): void {
    if (this.clickable) {
      this.openFileDialog();
    }
  }

  public openFileDialog(): boolean {
    if (this._hiddenFileInput) {
      this._hiddenFileInput.click();
      return true;
    }
    return false;
  }

  public readonly fnUploadAll = (): Promise<void> => this.uploadAll();

  public readonly fnProcessFiles = (uploadFileOrFiles: IPlUploadFile | Array<IPlUploadFile>) => (): Promise<void> => this.processFiles(uploadFileOrFiles);

  public readonly fnCancelFileUpload = (uploadFile: IPlUploadFile) => (): Promise<void> => this.cancelFileUpload(uploadFile);

  @HostListener('dragenter', ['$event'])
  public onDragEnter(event: Event): void {
    this._noPropagation(event);
    this.draggingOver = true;
    this.evtDragEnter.emit(event);
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: Event): void {
    this.draggingOver = false;
    this.evtDragLeave.emit(event);
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(event: Event): void {
    let effect: string;
    try {
      effect = (<any>event).dataTransfer.effectAllowed;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (ignored: unknown) {
      // ignored
    }
    (<any>event).dataTransfer.dropEffect = effect === 'move' || effect === 'linkMove' ? 'move' : 'copy';
    this._noPropagation(event);
    this.draggingOver = true;
    this.evtDragOver.emit(event);
  }

  @HostListener('drop', ['$event'])
  public onDropped(event: Event): void {
    this._noPropagation(event);
    if (!this.droppable || !(<any>event).dataTransfer) {
      return;
    }
    this.draggingOver = false;
    this.addFiles((<any>event).dataTransfer.files).catch((reason: unknown) => {
      this._logger.error(reason);
    });
    this.evtDropped.emit(event);
  }

  private _handleChanges(): void {
    this._changedProperties();
    this._changedUrl();
    this._changedMethod();
    this._changedWithCredentials();
    this._changedParallelUploads();
    this._changedUploadMultiple();
    this._changedMaxFileSize();
    this._changedParamName();
    this._changedCreateImageThumbnails();
    this._changedMaxThumbnailFileSize();
    this._changedThumbnailWidth();
    this._changedThumbnailHeight();
    this._changedThumbnailMethod();
    this._changedResizeWidth();
    this._changedResizeHeight();
    this._changedResizeTargetSize();
    this._changedResizeTargetSizeStep();
    this._changedResizeMimeType();
    this._changedResizeQuality();
    this._changedResizeMethod();
    this._changedResizeFn();
    this._changedResizeBeforeAccept();
    this._changedFileSizeBase();
    this._changedMaxFiles();
    this._changedHeaders();
    this._changedHideGlobalActions();
    this._changedHideActions();
    this._changedHideActionCancel();
    this._changedHideActionRemoveAll();
    this._changedHideActionRemove();
    this._changedHideActionRetry();
    this._changedHideActionUploadAll();
    this._changedHideActionUpload();
    this._changedDroppable();
    this._changedClickable();
    this._changedAcceptedFiles();
    this._changedAutoProcessQueue();
    this._changedAutoQueue();
    this._changedRenameFile();
    this._changedAccept();
    this._changedParams();
    this._changedFormData();
    this._changedZip();
    this._changedZipMinSize();
    this._changedZipStandalone();
    this._changedReportProgress();
    this._changedResponseType();
  }

  private _changedProperties(value: Partial<IPlUploadProperties> = this.properties): void {
    this._options = {...this._configProperties, ...this._options, ...value};
  }

  private _changedUrl(value: string = this.url): void {
    this.url = value || this._options.url || '';
  }

  private _changedMethod(value: TPlUploadMethod = this.method): void {
    this.method = value || this._options.method || 'post';
  }

  private _changedWithCredentials(value: boolean = this.withCredentials): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.withCredentials;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.withCredentials = val;
  }

  private _changedParallelUploads(value: number = this.parallelUploads): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this._options.parallelUploads;
    }
    if (!isNumber(val)) {
      val = DEFAULT_PARALLEL_UPLOADS;
    }
    this.parallelUploads = val;
  }

  private _changedUploadMultiple(value: boolean = this.uploadMultiple): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.uploadMultiple;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.uploadMultiple = val;
  }

  private _changedMaxFileSize(value: number = this.maxFileSize): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this._options.maxFileSize;
    }
    if (!isNumber(val)) {
      val = DEFAULT_MAX_FILE_SIZE;
    }
    // In megabytes
    this.maxFileSize = val;
  }

  private _changedParamName(value: string = this.paramName): void {
    this.paramName = value || this._options.paramName || 'file';
  }

  private _changedCreateImageThumbnails(value: boolean = this.createImageThumbnails): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.createImageThumbnails;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.createImageThumbnails = val;
  }

  private _changedMaxThumbnailFileSize(value: number = this.maxThumbnailFileSize): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this._options.maxThumbnailFileSize;
    }
    if (!isNumber(val)) {
      val = DEFAULT_MAX_THUMBNAIL_FILE_SIZE;
    }
    // In megabytes
    this.maxThumbnailFileSize = val;
  }

  private _changedThumbnailWidth(value: number = this.thumbnailWidth): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this._options.thumbnailWidth;
    }
    if (!isNumber(val)) {
      val = DEFAULT_THUMBNAIL_WIDTH;
    }
    this.thumbnailWidth = val;
  }

  private _changedThumbnailHeight(value: number = this.thumbnailHeight): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this._options.thumbnailHeight;
    }
    if (!isNumber(val)) {
      val = this.thumbnailWidth;
    }
    if (!isNumber(val)) {
      val = DEFAULT_THUMBNAIL_HEIGHT;
    }
    this.thumbnailHeight = val;
  }

  private _changedThumbnailMethod(value: TPlUploadThumbnailMethod = this.thumbnailMethod): void {
    this.thumbnailMethod = value || this._options.thumbnailMethod || DEFAULT_THUMBNAIL_METHOD;
  }

  private _changedResizeWidth(value: number = this.resizeWidth): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this._options.resizeWidth;
    }
    if (!isNumber(val)) {
      val = undefined;
    }
    this.resizeWidth = val;
  }

  private _changedResizeHeight(value: number = this.resizeHeight): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this._options.resizeHeight;
    }
    if (!isNumber(val)) {
      val = undefined;
    }
    this.resizeHeight = val;
  }

  private _changedResizeTargetSize(value: number = this.resizeTargetSize): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this._options.resizeTargetSize;
    }
    if (!isNumber(val)) {
      val = undefined;
    }
    this.resizeTargetSize = val;
  }

  private _changedResizeTargetSizeStep(value: number = this.resizeTargetSizeStep): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this._options.resizeTargetSizeStep;
    }
    if (!isNumber(val)) {
      val = undefined;
    }
    this.resizeTargetSizeStep = val;
  }

  private _changedResizeMimeType(value: string = this.resizeMimeType): void {
    this.resizeMimeType = value || this._options.resizeMimeType || undefined;
  }

  private _changedResizeQuality(value: number = this.resizeQuality): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this._options.resizeQuality;
    }
    if (!isNumber(val)) {
      val = DEFAULT_RESIZE_QUALITY;
    }
    this.resizeQuality = val;
  }

  private _changedResizeMethod(value: TPlUploadResizeMethod = this.resizeMethod): void {
    this.resizeMethod = value || this._options.resizeMethod || DEFAULT_RESIZE_METHOD;
  }

  private _changedResizeFn(value: TPlUploadResizeFn = this.resizeFn): void {
    this.resizeFn = value || this._options.resizeFn || undefined;
  }

  private _changedResizeBeforeAccept(value: boolean = this.resizeBeforeAccept): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.resizeBeforeAccept;
    }
    if (!isBoolean(val)) {
      val = DEFAULT_RESIZE_BEFORE_ACCEPT;
    }
    this.resizeBeforeAccept = val;
  }

  private _changedFileSizeBase(value: number = this.fileSizeBase): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this._options.fileSizeBase;
    }
    if (!isNumber(val)) {
      val = DEFAULT_FILE_SIZE_BASE;
    }
    this.fileSizeBase = val;
  }

  private _changedMaxFiles(value: number = this.maxFiles): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this._options.maxFiles;
    }
    if (!isNumber(val)) {
      val = undefined;
    }
    this.maxFiles = val;
  }

  private _changedHeaders(value: IPlUploadHeaders = this.headers): void {
    const headers = value || this._options.headers || undefined;
    this.headers = {...headers};
  }

  private _changedHideGlobalActions(value: boolean = this.hideGlobalActions): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.hideGlobalActions;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.hideGlobalActions = val;
  }

  private _changedHideActions(value: boolean = this.hideActions): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.hideActions;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.hideActions = val;
  }

  private _changedHideActionCancel(value: boolean = this.hideActionCancel): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.hideActionCancel;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.hideActionCancel = val;
  }

  private _changedHideActionRemoveAll(value: boolean = this.hideActionRemoveAll): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.hideActionRemoveAll;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.hideActionRemoveAll = val;
  }

  private _changedHideActionRemove(value: boolean = this.hideActionRemove): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.hideActionRemove;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.hideActionRemove = val;
  }

  private _changedHideActionRetry(value: boolean = this.hideActionRetry): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.hideActionRetry;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.hideActionRetry = val;
  }

  private _changedHideActionUploadAll(value: boolean = this.hideActionUploadAll): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.hideActionUploadAll;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.hideActionUploadAll = val;
  }

  private _changedHideActionUpload(value: boolean = this.hideActionUpload): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.hideActionUpload;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.hideActionUpload = val;
  }

  private _changedDroppable(value: boolean = this.droppable): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.droppable;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.droppable = val;
  }

  private _changedClickable(value: boolean = this.clickable): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.clickable;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.clickable = val;
  }

  private _changedAcceptedFiles(value: string = this.acceptedFiles): void {
    this.acceptedFiles = value || this._options.acceptedFiles || '';
    if (this.acceptedFiles.includes('.')) {
      this.acceptedFiles = this.acceptedFiles
        .split(',')
        .map((acceptedFile: string) => {
          acceptedFile = acceptedFile.trim();
          if (acceptedFile.startsWith('.')) {
            acceptedFile = fileExtensionToMimeType(acceptedFile.slice(1));
          }
          return acceptedFile;
        })
        .join(',');
    }
    this.textValidators = this._formatValidators();
  }

  private _changedAutoProcessQueue(value: boolean = this.autoProcessQueue): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.autoProcessQueue;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.autoProcessQueue = val;
  }

  private _changedAutoQueue(value: boolean = this.autoQueue): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.autoQueue;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.autoQueue = val;
  }

  private _changedRenameFile(value: TPlUploadRenameFileFn = this.renameFile): void {
    this.renameFile = value || this._options.renameFile || undefined;
  }

  private _changedAccept(value: TPlUploadAcceptFn = this.accept): void {
    this.accept = value || this._options.accept || undefined;
  }

  private _changedParams(value: IPlUploadParams = this.params): void {
    this.params = value || this.params || this._options.params || undefined;
  }

  private _changedFormData(value: object | FormData = this.formData): void {
    this.formData = value || this.params || this._options.formData || undefined;
  }

  private _changedZip(value: boolean = this.zip): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.zip;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.zip = val;
  }

  private _changedZipMinSize(value: number = this.zipMinSize): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this._options.zipMinSize;
    }
    if (!isNumber(val)) {
      val = DEFAULT_ZIP_MIN_SIZE;
    }
    this.zipMinSize = val;
  }

  private _changedZipStandalone(value: boolean = this.zipStandalone): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.zipStandalone;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.zipStandalone = val;
  }

  private _changedReportProgress(value: boolean = this.reportProgress): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this._options.reportProgress;
    }
    if (!isBoolean(val)) {
      val = DEFAULT_REPORT_PROGRESS;
    }
    this.reportProgress = val;
  }

  private _changedResponseType(value: TPlUploadResponseType = this.responseType): void {
    this.responseType = value || this._options.responseType || DEFAULT_RESPONSE_TYPE;
  }

  private _setupHiddenFileInput(): void {
    this._resetHiddenFileInput();
    this._hiddenFileInput = document.createElement<'input'>('input');
    this._hiddenFileInput.setAttribute('type', 'file');
    this._hiddenFileInput.setAttribute('name', this._getParamName());
    this._hiddenFileInput.classList.add('invisible');
    if (this.uploadMultiple && (!isNumber(this.maxFiles) || this.maxFiles > 1)) {
      this._hiddenFileInput.setAttribute('multiple', 'multiple');
    }
    if (this.acceptedFiles) {
      this._hiddenFileInput.setAttribute('accept', this.acceptedFiles);
    }
    this._renderer.appendChild(this._element, this._hiddenFileInput);
    this._subscriptionFileInputChange = fromEvent(this._hiddenFileInput, 'change', {passive: true}).subscribe(() => {
      this._onHiddenFileInputChange();
    });
  }

  private async _onHiddenFileInputChange(): Promise<void> {
    try {
      await this.addFiles(this._hiddenFileInput.files);
    } catch (reason) {
      this._logger.error(reason);
    } finally {
      this._setupHiddenFileInput();
    }
  }

  private _resetHiddenFileInput(): void {
    if (this._hiddenFileInput) {
      if (this._subscriptionFileInputChange) {
        this._subscriptionFileInputChange.unsubscribe();
        this._subscriptionFileInputChange = undefined;
      }
      this._hiddenFileInput.parentNode.removeChild(this._hiddenFileInput);
      this._hiddenFileInput = undefined;
    }
  }

  private _noPropagation(event: Event): void {
    event.stopPropagation();
    if (isFunction(event.preventDefault)) {
      event.preventDefault();
    }
  }

  private _getFilesWithSubscription(subscription: Subscription): Array<IPlUploadFile> {
    return subscription ? this.uploadFiles.filter((uploadFile: IPlUploadFile) => uploadFile.subscription === subscription) : [];
  }

  private _formatMessage(): void {
    const value: Array<string> = [];
    if (this.droppable) {
      value.push(this.locale.uploader.dropHere);
    }
    if (this.clickable) {
      if (value.length) {
        value.push(this.locale.text.or);
      }
      value.push(this.locale.uploader.clickToUpload);
    }
    let output: string = value.join(' ');
    if (output.length) {
      output = `${capitalize(output)}.`;
    }
    this.textMessage = output;
  }

  private _formatValidators(): string {
    const value: Array<string> = [];
    if (this.acceptedFiles) {
      let acceptedFiles = this.acceptedFiles.split(',');
      if (acceptedFiles.length) {
        acceptedFiles = acceptedFiles.map((acceptedFile: string) => this.locale.mimeTypes[acceptedFile]);
        let message: string = this.locale.uploader.acceptedFileTypes;
        const toInterpolate: {acceptedFiles: string | Array<string>} = {acceptedFiles: acceptedFiles};
        if (acceptedFiles.length > 1) {
          const last: string = acceptedFiles.pop();
          toInterpolate.acceptedFiles = `${acceptedFiles.join(', ')} ${this.locale.text.and} ${last}`;
        } else {
          message = this.locale.uploader.acceptedFileType;
        }
        value.push(interpolate(message)(toInterpolate));
      }
    }
    if (isNumber(this.maxFileSize) && this.maxFileSize > 0) {
      const toInterpolate: unknown = {maximumFileSize: this._formatFileSize(this.maxFileSize * this.fileSizeBase * this.fileSizeBase)};
      const message = !this.uploadMultiple ? this.locale.uploader.maximumFileSize : this.locale.uploader.maximumPerFileSize;
      value.push(interpolate(message)(toInterpolate));
    }
    return value.join(' ');
  }

  private _formatFileSize(bytes: number): string {
    const thresh: number = this.fileSizeBase;
    let selectedUnit: keyof IPlLocaleUploaderUnits = 'b';
    if (Math.abs(bytes) >= thresh) {
      let i = -1;
      do {
        bytes /= thresh;
        i++;
      } while (Math.abs(bytes) >= thresh && i < UPLOADER_UNITS.length - 1);
      selectedUnit = UPLOADER_UNITS[i];
    }
    const fractionDigits: number = Math.round(bytes) === bytes ? 0 : 1;
    const selectedSize = bytes.toFixed(fractionDigits);
    return `<strong>${selectedSize}</strong> ${this.locale.uploader.units[selectedUnit]}`;
  }

  private _renameFile(file: File): Promise<string> {
    if (isFunction(this.renameFile)) {
      return Promise.resolve(this.renameFile(file));
    }
    return Promise.resolve<string>(file.name);
  }

  private _resizeFile(file: File): Promise<File> {
    const properties: IPlUploadProperties = this._generateProperties();
    if (isFunction(this.resizeFn)) {
      return Promise.resolve(this.resizeFn(file, properties));
    }
    return lastValueFrom(PlImageResizer.resizeImageToFile(file, properties));
  }

  private _generateThumbnail(visualFile: IPlUploadVisualFile): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.createImageThumbnails) {
        if (/image.*/.exec(visualFile.file.type) && visualFile.file.size <= this.maxThumbnailFileSize * ONE_KIBIBYTE * ONE_KIBIBYTE) {
          this._thumbnailQueue.push(visualFile);
          this._processThumbnailQueue()
            .then(() => {
              resolve();
            })
            // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
            .catch(reject);
        } else {
          visualFile.thumbnail = {
            preview: this._plUploadConfigService.getThumbnail(visualFile.file.type),
            width: this.thumbnailWidth,
            height: this.thumbnailHeight
          };
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  private _processThumbnailQueue(): Promise<void> {
    if (this._promiseProcessingThumbnail) {
      return this._promiseProcessingThumbnail;
    } else if (!this._thumbnailQueue.length) {
      return Promise.resolve();
    }
    this._promiseProcessingThumbnail = new Promise<void>((resolve, reject) => {
      const doProcess: () => Promise<void> = () => {
        const visualFile: IPlUploadVisualFile = this._thumbnailQueue.shift();
        if (!visualFile) {
          return Promise.resolve();
        }
        return this._createThumbnail(visualFile).then(() => {
          return doProcess();
        });
      };
      doProcess()
        .then(() => {
          resolve();
        })
        // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
        .catch(reject)
        .finally(() => {
          this._promiseProcessingThumbnail = undefined;
        });
    });
    return this._promiseProcessingThumbnail;
  }

  private _createThumbnail(visualFile: IPlUploadVisualFile): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const resizeProperties: Partial<IPlImageResizerProperties> = {
        resizeWidth: this.thumbnailWidth,
        resizeHeight: this.thumbnailHeight,
        resizeMethod: this.thumbnailMethod
      };
      PlImageResizer.resizeImageToBlob(visualFile.file, resizeProperties).subscribe({
        next: (result: Blob) => {
          const fileReader: FileReader = new FileReader();
          const cleanUp = (): void => {
            fileReader.removeEventListener<'load'>('load', onLoad);
            fileReader.removeEventListener<'error'>('error', onError);
          };
          const onLoad = (): void => {
            visualFile.thumbnail = {
              preview: <string>fileReader.result,
              width: this.thumbnailWidth,
              height: this.thumbnailHeight
            };
            cleanUp();
            resolve();
          };
          const onError = (event: ProgressEvent): void => {
            cleanUp();
            // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
            reject(event);
          };
          fileReader.addEventListener<'load'>('load', onLoad);
          fileReader.addEventListener<'error'>('error', onError);
          fileReader.readAsDataURL(result);
        },
        error: reject
      });
    });
  }

  private _accept(file: File): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const validate = (): void => {
        if (this.maxFileSize && file.size > this.maxFileSize * ONE_KIBIBYTE * ONE_KIBIBYTE) {
          reject(new Error(interpolate(this.locale.uploader.fileTooBig)({maxFilesize: this.maxFileSize})));
        } else if (!this._isValidFile(file, this.acceptedFiles)) {
          reject(new Error(this.locale.uploader.invalidFileType));
        } else if (this.maxFiles > 0 && this.getAcceptedFiles().length >= this.maxFiles) {
          const message = this.maxFiles === 1 ? this.locale.uploader.maxFileExceeded : this.locale.uploader.maxFilesExceeded;
          reject(new Error(interpolate(message)({maxFiles: this.maxFiles})));
        } else {
          resolve();
        }
      };
      if (isFunction(this.accept)) {
        Promise.resolve(this.accept(file))
          .then((response: boolean | string) => {
            if (isDefinedNotNull(response)) {
              if (isBoolean(response)) {
                if (response) {
                  resolve();
                  return;
                }
                reject(new Error('File rejected.'));
                return;
              } else if (isString(response)) {
                reject(new Error(response));
                return;
              }
            }
            validate();
          })
          // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
          .catch(reject);
      } else {
        validate();
      }
    });
  }

  private _isValidFile(file: File, acceptedFiles: string): boolean {
    // If there are no accepted mime types, it's OK
    if (!acceptedFiles) {
      return true;
    }
    const accepted: Array<string> = acceptedFiles.split(',');
    let mimeType = file.type;
    if (!mimeType) {
      const extensionIndex = file.name.lastIndexOf('.');
      if (extensionIndex !== -1) {
        const fileExtension = file.name.substring(extensionIndex + 1);
        mimeType = fileExtensionToMimeType(fileExtension);
      }
    }
    const baseMimeType = mimeType ? mimeType.replace(/\/.*$/, '') : '';
    for (let validType of accepted) {
      validType = validType.trim();
      // File extension
      if (validType.startsWith('.')) {
        if (file.name.toLowerCase().includes(validType.toLowerCase(), file.name.length - validType.length)) {
          return true;
        }
      }
      // Something like a image/* mime type
      else if (validType.endsWith('/*')) {
        if (baseMimeType && baseMimeType === validType.replace(/\/.*$/, '')) {
          return true;
        }
      }
      // Mime type
      else if (mimeType === validType) {
        return true;
      }
    }
    return false;
  }

  private _enqueueFile(uploadFile: IPlUploadFile): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (uploadFile.status === EPlUploadStatus.Added && uploadFile.accepted === true) {
        uploadFile.status = EPlUploadStatus.Queued;
        this._updateQueuedFiles();
        if (this.autoProcessQueue) {
          if (this.uploadMultiple && this.getAddedFiles().length > 0) {
            resolve();
          } else {
            // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
            this.processQueue().then(resolve).catch(reject);
          }
        } else {
          resolve();
        }
      } else {
        reject(new Error("This file can't be queued because it has already been processed or was rejected."));
      }
    });
  }

  private _uploadFiles(uploadFiles: Array<IPlUploadFile>): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const properties: IPlUploadProperties = this._generateProperties();
      this._fileListPipelineHandler
        .handle(
          uploadFiles.map((fileToUpload: IPlUploadFile) => fileToUpload.file),
          properties
        )
        .subscribe({
          next: (files: Array<File>) => {
            const dataBlocks: Array<IPlUploadDataBlock> = files
              .map<IPlUploadFile>((file: File, index: number) => {
                // Avoid mutating `this.uploadFiles` objects
                return {...uploadFiles[index], file: file};
              })
              .map<IPlUploadDataBlock>((uploadFile: IPlUploadFile) => {
                return {
                  name: this._getParamName(),
                  data: uploadFile.file,
                  filename: uploadFile.file.name
                };
              });
            // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
            this._uploadData(uploadFiles, dataBlocks).then(resolve).catch(reject);
          },
          error: reject
        });
    });
  }

  private _uploadData(uploadFiles: Array<IPlUploadFile>, dataBlocks: Array<IPlUploadDataBlock>): Promise<void> {
    const promiseMethod: TValueOrPromise<'post' | 'put'> = isFunction(this.method) ? this.method() : this.method;
    return new Promise<void>((resolve, reject) => {
      Promise.resolve(promiseMethod)
        .then((method: 'post' | 'put') => {
          const formData: FormData = toFormData(this.formData);
          for (const dataBlock of dataBlocks) {
            if (isBlob(dataBlock.data)) {
              formData.append(dataBlock.name, dataBlock.data, dataBlock.filename);
            } else {
              formData.append(dataBlock.name, dataBlock.data);
            }
          }
          let response: HttpResponse<unknown> | HttpErrorResponse;
          const subscription: Subscription = this._httpClient
            .request(
              new HttpRequest<FormData>(method, this.url, formData, {
                headers: new HttpHeaders(this.headers),
                params: new HttpParams({fromObject: this.params}),
                reportProgress: this.reportProgress,
                withCredentials: this.withCredentials,
                responseType: this.responseType
              })
            )
            .subscribe({
              next: (event: HttpEvent<unknown>) => {
                this._updateFilesUploadProgress(uploadFiles, <HttpProgressEvent>event);
                if (event instanceof HttpResponse) {
                  response = event;
                }
              },
              error: (errorResponse: HttpErrorResponse) => {
                response = errorResponse;
                this._handleUploadError(uploadFiles, errorResponse)
                  .catch((reason: unknown) => {
                    this._logger.error(reason);
                  })
                  .finally(() => {
                    // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
                    this._finishedUploading(uploadFiles, response).then(resolve).catch(reject);
                  });
              },
              complete: () => {
                // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
                this._finishedUploading(uploadFiles, response).then(resolve).catch(reject);
              }
            });
          for (const uploadFile of uploadFiles) {
            uploadFile.subscription = subscription;
          }
        })
        // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
        .catch(reject);
    });
  }

  private _finishedUploading(uploadFiles: Array<IPlUploadFile>, responseOrError: HttpResponse<unknown> | HttpErrorResponse): Promise<void> {
    this._updateFilesUploadProgress(uploadFiles);
    const uploadedFiles: Array<IPlUploadFile> = [];
    const uploadErrors: Array<IPlUploadFile> = [];
    for (const uploadFile of uploadFiles) {
      uploadFile.upload.response = responseOrError;
      if (uploadFile.status !== EPlUploadStatus.Canceled && uploadFile.status !== EPlUploadStatus.Error) {
        uploadFile.status = EPlUploadStatus.Success;
        uploadedFiles.push(uploadFile);
        this.evtUploadedFile.emit(uploadFile);
      } else {
        uploadErrors.push(uploadFile);
        this.evtUploadErrored.emit(uploadFile);
      }
    }
    this._updateQueuedFiles();
    this.evtUploadedFiles.emit(uploadedFiles);
    this.evtUploadErrors.emit(uploadErrors);
    if (!this.getPendingFiles().length) {
      this.evtFinishedUpload.emit(uploadFiles);
    }
    return this.autoProcessQueue || this.uploadingAll ? this.processQueue() : Promise.resolve();
  }

  private _handleUploadError(uploadFiles: Array<IPlUploadFile>, httpErrorResponse: HttpErrorResponse): Promise<void> {
    for (const file of uploadFiles) {
      file.status = EPlUploadStatus.Error;
    }
    this._updateQueuedFiles();
    return Promise.resolve(this._plUploadErrorHandler.parseError(httpErrorResponse)).then((parsedError: string) => {
      for (const file of uploadFiles) {
        file.statusMessage = parsedError;
      }
    });
  }

  private _getParamName(): string {
    return this.paramName;
  }

  private _updateFilesUploadProgress(uploadFiles: Array<IPlUploadFile>, event?: HttpProgressEvent): void {
    // Upload still on going
    if (isDefined(event)) {
      let progress = (100 * event.loaded) / event.total;
      if (!isNumber(progress) || Number.isNaN(progress)) {
        progress = 0;
      }
      progress = Math.trunc(progress);
      for (const file of uploadFiles) {
        file.upload.progress = progress;
        file.upload.total = event.total;
        file.upload.bytesSent = event.loaded;
      }
    }
    // Finished file upload
    else {
      for (const file of uploadFiles) {
        file.upload.progress = 100;
        file.upload.bytesSent = file.upload.total;
      }
    }
    this._updateTotalUploadProgress();
  }

  private _updateTotalUploadProgress(): void {
    let totalBytesSent = 0;
    let totalBytes = 0;
    const activeFiles: Array<IPlUploadFile> = this.getActiveFiles();
    if (activeFiles.length) {
      for (const file of activeFiles) {
        totalBytesSent += file.upload.bytesSent;
        totalBytes += file.upload.total;
      }
      this.totalUploadProgress = (100 * totalBytesSent) / totalBytes;
    } else {
      this.totalUploadProgress = 100;
    }
    this.evtTotalProgressChanged.emit({progress: this.totalUploadProgress, totalBytes: totalBytes, totalSentBytes: totalBytesSent});
  }

  private _updateQueuedFiles(): void {
    this.queuedFiles = this.getQueuedFiles().length;
  }

  private _generateProperties(): IPlUploadProperties {
    return {
      accept: undefined,
      acceptedFiles: undefined,
      autoProcessQueue: this.autoProcessQueue,
      autoQueue: this.autoQueue,
      clickable: this.clickable,
      createImageThumbnails: this.createImageThumbnails,
      droppable: this.droppable,
      fileSizeBase: this.fileSizeBase,
      headers: {...this.headers},
      hideGlobalActions: this.hideGlobalActions,
      hideActions: this.hideActions,
      hideActionCancel: this.hideActionCancel,
      hideActionRemoveAll: this.hideActionRemoveAll,
      hideActionRemove: this.hideActionRemove,
      hideActionRetry: this.hideActionRetry,
      hideActionUploadAll: this.hideActionUploadAll,
      hideActionUpload: this.hideActionUpload,
      maxFiles: this.maxFiles,
      maxFileSize: this.maxFileSize,
      maxThumbnailFileSize: this.maxThumbnailFileSize,
      method: undefined,
      parallelUploads: this.parallelUploads,
      paramName: this.paramName,
      params: this.params,
      formData: this.formData,
      renameFile: undefined,
      reportProgress: this.reportProgress,
      resizeWidth: this.resizeWidth,
      resizeHeight: this.resizeHeight,
      resizeTargetSize: this.resizeTargetSize,
      resizeTargetSizeStep: this.resizeTargetSizeStep,
      resizeMimeType: this.resizeMimeType,
      resizeQuality: this.resizeQuality,
      resizeMethod: this.resizeMethod,
      resizeFn: this.resizeFn,
      resizeBeforeAccept: this.resizeBeforeAccept,
      responseType: this.responseType,
      thumbnailWidth: this.thumbnailWidth,
      thumbnailHeight: this.thumbnailHeight,
      thumbnailMethod: this.thumbnailMethod,
      uploadMultiple: this.uploadMultiple,
      url: this.url,
      withCredentials: this.withCredentials,
      zip: this.zip,
      zipMinSize: this.zipMinSize,
      zipStandalone: this.zipStandalone
    };
  }
}
