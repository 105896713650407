<pl-form (evtSubmitted)="onFormSubmit()">
  <div class="modal-header">
    <h4 class="modal-title" [translate]="'importadorSaftDirect.strings.modalTitleEdit'"></h4>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <pl-group>
          <label [translate]="'importadorSaftDirect.fields.nDiario'"></label>
          <edit>
            <entity-autocomplete entity="diarios" [(selectedKey)]="model.nDiario" output="key"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'importadorSaftDirect.fields.descritivo'"></label>
          <edit>
            <entity-autocomplete entity="descritivos" [model]="model" [fieldsMap]="{nDescrit: 'descritivo'}" (selectedKeyChange)="model.descritivo = $event" [output]="'key'"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group [properties]="{disabled: !model.lancaCaixa, validators: {required: {value: true}}}">
          <label [translate]="'importadorSaftDirect.fields.contaCaixa'"></label>
          <edit>
            <entity-autocomplete entity="pocs" [model]="model" [fieldsMap]="{nConta: 'contaCaixa'}" (selectedKeyChange)="model.contaCaixa = $event" [output]="'key'"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group [properties]="{disabled: model.invoiceType !== invoiceTypes.Recibo}">
          <label [translate]="'importadorSaftDirect.fields.contaDescRecibo'"></label>
          <edit>
            <entity-autocomplete entity="pocs" [model]="model" [fieldsMap]="{nConta: 'contaDescRec'}" (selectedKeyChange)="model.contaDescRec = $event" [output]="'key'"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group [properties]="{disabled: model.invoiceType === invoiceTypes.Recibo}">
          <edit>
            <pl-edit type="boolean" [(model)]="model.lancaCaixa" [properties]="{label: 'importadorSaftDirect.fields.lancaCaixa'}"></pl-edit>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-6">
        <pl-group *ngIf="showCCusto">
          <label [translate]="'importadorSaftDirect.fields.nCCusto'"></label>
          <edit>
            <entity-autocomplete entity="ccustos" [model]="model" [(selectedKey)]="model.nCCusto" [output]="'key'" [filter]="'encerrado=0'" [properties]="{allowInvalid: false}"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group *ngIf="showRepCC">
          <label [translate]="'importadorSaftDirect.fields.nCodRepCC'"></label>
          <edit>
            <entity-autocomplete entity="reparticoesccustosfaturacao" [model]="model" [(selectedKey)]="model.nCodRepCC" [output]="'key'" [properties]="{allowInvalid: false}"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group [properties]="{disabled: !canChangeAgrupamentoDoc}">
          <label [translate]="'importadorSaftDirect.fields.agrupamentoDocumentos'"></label>
          <edit>
            <pl-select
              [model]="model"
              [source]="agrupamentoDocsSource"
              [rowTemplate]="agrupamentoDocsTemplate"
              [output]="agrupamentoDocsOutput"
              (evtChanged)="fnAgrupamentoDocsChanged($event)"
              [disabled]="!canChangeAgrupamentoDoc"></pl-select>
            <div *ngIf="!canChangeAgrupamentoDoc" class="text-danger" [translate]="'importadorSaftDirect.messages.SoPodeAgruparDocsTipoFaturaSimple'"></div>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'importadorSaftDirect.fields.tipoRetencao'"></label>
          <edit>
            <pl-select [model]="model" [source]="tipoRetencaoSource" [rowTemplate]="tipoRetencaoTemplate" [output]="tipoRetencaoOutput" (evtChanged)="changedTipoRetencao($event)"></pl-select>
          </edit>
        </pl-group>
        <pl-group [properties]="{disabled: model.invoiceType === invoiceTypes.Recibo}">
          <edit>
            <pl-edit type="boolean" [(model)]="model.lancaContaCorrente" [properties]="{label: 'importadorSaftDirect.fields.lancaContaCorrente'}"></pl-edit>
          </edit>
        </pl-group>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn-primary btn-sm action-close" plAutoFocus><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalSave'"></span></pl-button>
    <pl-button klass="btn-sm btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalCancel'"></span></pl-button>
  </div>
</pl-form>
