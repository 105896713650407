import {ROLE} from '../../services/role.const';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {MODULE_NAME_POCS_COPY} from '../../entities/pocs/pocs.entity.interface';
import {POCSCopyModuleComponent} from './components/pocsCopy.module.component';

export const MODULE_POCS_COPY: IModuleDefinition = {
  name: MODULE_NAME_POCS_COPY,
  state: {
    url: `/${MODULE_NAME_POCS_COPY}`,
    component: POCSCopyModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.ERP, ROLE.PCA, ROLE.ATIVOS, ROLE.RH],
      sidebarTitle: 'portals.sidebar.modules.pocscopy'
    },
    params: {
      nConta: {
        type: 'any',
        value: undefined
      }
    }
  }
};
