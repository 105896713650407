import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {KEYCODES} from 'pl-comps-angular';

@Component({
  selector: 'cg-gestao-comunicacoes-message-input',
  templateUrl: './messageInput.component.html'
})
export class CGGestaoComunincacaoMessageInputComponent {
  @Input() public showAttachFile: boolean;
  @Input() public showSpeechToText: boolean;
  @Input() public showSendButton: boolean;
  @Input() public showAttachFileMessage: boolean;
  @Input() public marginBottom: boolean;
  @Input() public marginTop: boolean;
  @Output() public readonly evtSubmitMessage: EventEmitter<string>;
  @Output() public readonly evtSubmitFile: EventEmitter<File>;

  @ViewChild('messageInput') public messageInput: ElementRef;
  public message: string;
  public file: File;

  constructor() {
    this.evtSubmitMessage = new EventEmitter<string>();
    this.evtSubmitFile = new EventEmitter<File>();
  }

  public onSendMessageSpeechText(messageText: string): void {
    this.message = messageText;
  }

  public addFile(event: Event): void {
    this.file = (<HTMLInputElement>event.target).files[0];
    this.evtSubmitFile.emit(this.file);
  }

  public onKeydownEnter(event: KeyboardEvent): void {
    if (!this.showSendButton) {
      this.onClickSendMessage();
      return;
    }

    if (event.key === KEYCODES.ENTER && !event.shiftKey) {
      event.preventDefault();

      if (this.message.trim()) {
        this.onClickSendMessage();
      }
    }
  }

  public onClickSendMessage(): void {
    this.evtSubmitMessage.emit(this.message);
    if (this.showSendButton) {
      this.message = '';
      this.file = undefined;
    }
  }

  public focusMessageInput(): void {
    this.messageInput.nativeElement.focus();
  }
}
