import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlToolbarItem, IPlTooltipConfig, KEYCODES, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ComplementosExcepcoesModalComponent} from '../../rhcessacontratotrabalho/modals/complementosexpcecoes/complementosExcepcoes.modal.component';
import {IExcluidosAbonosDescontos} from '../../rhcessacontratotrabalho/rhCessaContratoTrabalho.module.interface';
import {IJsonErpUser} from '../../../../services/account/jsonUserApi.interface';
import {IJsonListaExcl, IJsonListaExclProcessamento} from '../../rhfolfer/jsonRHFolfer.module.interface';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {IRHImpVenctHeader} from '../rhImpVenct.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {RhImpVenctService} from '../rhImpVenct.module.service';
import {TDate} from '../../../../../common/dates';
import {TRHFolFerComplementosExcepcoesFrom} from '../../rhfolfer/rhFolfer.module.interface';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';

const BTN_PROCESS_PDF = 'processarPDF';

@Component({
  selector: 'module-rhimpvenct',
  templateUrl: './rhImpVenct.module.component.html'
})
export class RhImpVenctModuleComponent extends ModuloComponent implements OnInit {
  @Input() public header: IRHImpVenctHeader;
  @Input() public defaultReport: IJsonReport;

  public readonly empresasTemplate: string;
  public readonly tooltipExcepcoesAbono: IPlTooltipConfig;
  public readonly tooltipExcepcoesDesconto: IPlTooltipConfig;
  public readonly tooltipExcepcoesProcessamento: IPlTooltipConfig;

  public empresas: Array<IJsonErpUser>;
  public empresasSource: Array<IJsonErpUser>;
  public exclProcessamento: IJsonListaExclProcessamento;
  public report: IJsonReport;
  public promiseProcessamento: Promise<void>;
  public pdfUrl: string;

  private readonly _btnProcessPdf: IPlToolbarItem;
  private _cardPanel: CGCardPanelComponent;
  constructor(
    protected readonly _injector: Injector,
    private readonly _rhImpVenctService: RhImpVenctService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.tooltipExcepcoesAbono = {text: 'rhimpvenct.infos.tooltipExcecpoesAbono', placement: 'bottom', container: 'body'};
    this.tooltipExcepcoesDesconto = {text: 'rhimpvenct.infos.tooltipExcecpoesDesconto', placement: 'bottom', container: 'body'};
    this.tooltipExcepcoesProcessamento = {text: 'rhimpvenct.infos.tooltipExcepcoesProcessamento', placement: 'bottom', container: 'body'};
    this.empresasTemplate = '{{nEmpresa}} - {{nomeEmpresa}}';
    this.empresas = [];
    this._btnProcessPdf = {
      id: BTN_PROCESS_PDF,
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-file-pdf-o fa-fw"></i>',
      class: 'btn-success',
      caption: 'global.btn.processPDF',
      click: () => {
        this._preVisualizar();
      }
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.exclProcessamento = {
      ateData: undefined,
      deData: undefined,
      listaExcluidos: [],
      listaPossiveis: []
    };
    this.header.listaExclDescontoExcluidos = [];
    this.header.listaExclAbonoExcluidos = [];
    this.toolbar.addButton(this._btnProcessPdf);
    this._loadEmpresas();
    if (this.defaultReport) {
      this.report = this.defaultReport;
    }
  }

  public openExclusionsProcessamento(): Promise<void> {
    if (this.empresas.length !== 1 || (this.empresas.length === 1 && this.empresas[0].nEmpresa !== this.session.erp.nEmpresa)) {
      this._plAlertService.error('rhimpvenct.messages.exclprocessamentoemp');
      return Promise.resolve();
    }
    this.exclProcessamento.deData = this.header.dataProcesDe;
    this.exclProcessamento.ateData = this.header.dataProcesAte;
    this.promiseProcessamento = this._rhImpVenctService.postExclusoesProcessamento(this.exclProcessamento).then((response: HttpResponse<IJsonListaExclProcessamento>) => {
      this.exclProcessamento.listaPossiveis = response.body.listaPossiveis;
      this.exclProcessamento.listaExcluidos = response.body.listaExcluidos;
      return this.openExclusions('processamento');
    });
    return this.promiseProcessamento.finally(() => {
      this.promiseProcessamento = undefined;
    });
  }

  public dateDropDownChanged(date: TDate, isAbono: boolean): void {
    if (isAbono) {
      this.header.dataProcesDe = date;
    } else {
      this.header.dataProcesAte = date;
    }
  }

  public openExclusions(isFrom: TRHFolFerComplementosExcepcoesFrom): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ComplementosExcepcoesModalComponent);
    const componentInstance: ComplementosExcepcoesModalComponent = modalInstance.componentInstance;
    switch (isFrom) {
      case 'abono':
        componentInstance.excludedListPossiveis = this.header.listaExclAbono.slice();
        componentInstance.excludedListExcluidos = this.header.listaExclAbonoExcluidos.slice();
        break;
      case 'desconto':
        componentInstance.excludedListPossiveis = this.header.listaExclDesconto.slice();
        componentInstance.excludedListExcluidos = this.header.listaExclDescontoExcluidos.slice();
        break;
      case 'processamento':
        componentInstance.excludedListPossiveis = this.exclProcessamento.listaPossiveis.slice();
        componentInstance.excludedListExcluidos = this.exclProcessamento.listaExcluidos.slice();
        break;
    }
    return modalInstance.result.then((result: IExcluidosAbonosDescontos) => {
      switch (isFrom) {
        case 'abono':
          this.header.listaExclAbono = result.possiveis;
          this.header.listaExclAbonoExcluidos = result.excluidos;
          break;
        case 'desconto':
          this.header.listaExclDesconto = result.possiveis;
          this.header.listaExclDescontoExcluidos = result.excluidos;
          break;
        case 'processamento':
          this.exclProcessamento.listaPossiveis = result.possiveis;
          this.exclProcessamento.listaExcluidos = result.excluidos;
          break;
      }
    });
  }

  public readonly fnKeydownProcessar = (value: string, event: KeyboardEvent): void => {
    this._keydownProcessar(event);
  };

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _keydownProcessar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      this.toolbar.focusItem(BTN_PROCESS_PDF);
    }
  }

  private _preVisualizar(): void {
    const listEmpresas: string = !this.empresas.length ? this.session.erp.nEmpresa : this.empresas.map((value: IJsonErpUser) => value.nEmpresa).join('-');
    const listExclProcessamento: string =
      this.empresas.length !== 1 && this.empresas[0].nEmpresa !== this.session.erp.nEmpresa ? '' : this.exclProcessamento.listaExcluidos.map((value: IJsonListaExcl) => value.value).join('-');
    const listExclAbono: string = this.header.listaExclAbonoExcluidos.map((value: IJsonListaExcl) => value.value).join('-');
    const listExclDesconto: string = this.header.listaExclDescontoExcluidos.map((value: IJsonListaExcl) => value.value).join('-');
    this._rhImpVenctService.getListagemImpVenctUrl(this.header, listEmpresas, listExclProcessamento, listExclAbono, listExclDesconto, this.report.name).subscribe((url: string) => {
      this.pdfUrl = url;
      this._cardPanel.collapse();
    });
  }

  private _loadEmpresas(): void {
    const empresasSource: Array<IJsonErpUser> = this.session.erps.slice();
    let itemIndex = -1;
    const item: IJsonErpUser = empresasSource.find((empresa: IJsonErpUser, index: number) => {
      if (empresa.nEmpresa === this.session.erp.nEmpresa) {
        itemIndex = index;
        return true;
      }
      return false;
    });
    if (itemIndex > -1) {
      empresasSource.splice(itemIndex, 1);
      this.empresas = [item];
    }
    this.empresasSource = empresasSource;
  }
}
