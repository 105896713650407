export enum EContratoLockedReason {
  None,
  MigratingInstallation
}

export const CONTRATO_LOCKED_REASON_STR: ReadonlyMap<EContratoLockedReason, string> = Object.freeze(
  new Map([
    [EContratoLockedReason.None, 'N/D'],
    [EContratoLockedReason.MigratingInstallation, 'MIGRATING_INSTALLATION']
  ])
);

export const CONTRATO_LOCKED_REASON_OF_STR: ReadonlyMap<string, EContratoLockedReason> = Object.freeze(
  new Map([
    ['N/D', EContratoLockedReason.None],
    ['MIGRATING_INSTALLATION', EContratoLockedReason.MigratingInstallation]
  ])
);
