import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {TPortalClientAccountDashboard, TPortalClientAccountDashboardResult} from '../../services/portalclientaccount/portalclientaccount.service.interface';

@Injectable({
  providedIn: 'root'
})
export class DashboardContabilidadeService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/dashboardcontabilidade`;
  }

  public getDashboard<T extends TPortalClientAccountDashboard>(
    nome: TPortalClientAccountDashboard,
    tipo: number,
    ano: number,
    periodo: string,
    force: boolean = false
  ): TServiceResponse<TPortalClientAccountDashboardResult<T>> {
    return this._apiService.get<TPortalClientAccountDashboardResult<T>>({
      url: `${this._path}/${nome}`,
      params: {
        tipo: tipo,
        ano: ano,
        periodo: periodo,
        force: force
      }
    });
  }
}
