import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IFctPedidoReembolsoEfetuadoDataGridItem} from '../../fct.module.interface';

@Component({
  selector: 'fct-pedidos-reembolso-efetuados-modal',
  templateUrl: './fct.pedidosReembolsoEfetuados.modal.component.html'
})
export class FctPedidosReembolsoEfetuadosModalComponent extends CGModalComponent<void> {
  @Input() public source: Array<IFctPedidoReembolsoEfetuadoDataGridItem>;
  @Input() public modalTitle: string;

  public dataGridDefinition: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.source = [];
    this.dataGridDefinition = {
      keyExpr: 'niss',
      columns: [
        {dataField: 'niss', caption: 'fct.strings.niss'},
        {dataField: 'nomeEmpregado', caption: 'fct.tableDefinition.nomeEmp'},
        {dataField: 'valorEstimadoReembolso', caption: 'fct.tableDefinition.valorEstimadoReembolso'}
      ]
    };
  }
}
