import {copy, isObject, TValueOrPromise} from 'pl-comps-angular';
import {EEntityStateDetailType} from '../../../../common/utils/entity.state.utils';
import {IEntity, IEntityEventField, IEntityEventFields, IEntityFieldEvents} from '../entity.definition.interface';
import {IEntityFormDefinition, IEntityFormDefinitionField} from '../entity.form.definition.interface';

const fieldEventsMap: WeakMap<IEntity, IEntityFieldEvents<unknown>> = new WeakMap<IEntity, IEntityFieldEvents<unknown>>();
const eventFieldsMap: WeakMap<IEntity, IEntityEventFields> = new WeakMap<IEntity, IEntityEventFields>();

export function entityFieldEvents(entity: IEntity, fieldName: string): IEntityFieldEvents<unknown> {
  let fieldEvents: IEntityFieldEvents<unknown> = fieldEventsMap.get(entity);
  if (!fieldEvents) {
    const delegate = (event: string, callback: (...args: Array<any>) => void): IEntityFieldEvents<unknown> => delegator(entity, fieldEvents, fieldName, event, callback);
    fieldEvents = {
      blur: (callback: (value: unknown, event: FocusEvent) => void) => delegate('blur', callback),
      focus: (callback: (value: unknown, event: FocusEvent) => void) => delegate('focus', callback),
      beforeChange: (callback: (newValue: unknown, oldValue: unknown) => TValueOrPromise<void | boolean>) => delegate('beforeChange', callback),
      change: (callback: (value: unknown) => void) => delegate('change', callback),
      keydown: (callback: (value: unknown, event: KeyboardEvent) => void) => delegate('keydown', callback),
      keypress: (callback: (value: unknown, event: KeyboardEvent) => void) => delegate('keypress', callback),
      keyup: (callback: (value: unknown, event: KeyboardEvent) => void) => delegate('keyup', callback),
      mousedown: (callback: (value: unknown, event: MouseEvent) => void) => delegate('mousedown', callback),
      mouseenter: (callback: (value: unknown, event: MouseEvent) => void) => delegate('mouseenter', callback),
      mouseleave: (callback: (value: unknown, event: MouseEvent) => void) => delegate('mouseleave', callback),
      mousemove: (callback: (value: unknown, event: MouseEvent) => void) => delegate('mousemove', callback),
      mouseover: (callback: (value: unknown, event: MouseEvent) => void) => delegate('mouseover', callback),
      mouseup: (callback: (value: unknown, event: MouseEvent) => void) => delegate('mouseup', callback),
      click: (callback: (value: unknown, event: MouseEvent) => void) => delegate('click', callback),
      clearEvents: (field: string) => clearEvents(entity, fieldEvents, field),
      build: (type: EEntityStateDetailType) => builder(entity, type)
    };
    fieldEventsMap.set(entity, fieldEvents);
  }
  return fieldEvents;
}

function delegator<T>(entity: IEntity, fieldEvents: IEntityFieldEvents<T>, fieldName: string, event: string, callback: (...args: Array<any>) => void): IEntityFieldEvents<T> {
  let eventFields: IEntityEventFields = eventFieldsMap.get(entity);
  if (!eventFields) {
    eventFields = {};
    eventFieldsMap.set(entity, eventFields);
  }
  if (!isObject(eventFields[fieldName])) {
    eventFields[fieldName] = {};
  }
  eventFields[fieldName][event] = callback;
  return fieldEvents;
}

function clearEvents<T>(entity: IEntity, fieldEvents: IEntityFieldEvents<T>, fieldName?: string): IEntityFieldEvents<T> {
  // If `fieldName` is not supplied we clear all the events
  if (!fieldName) {
    eventFieldsMap.delete(entity);
  } else {
    const eventFields: IEntityEventFields = eventFieldsMap.get(entity);
    if (eventFields) {
      delete eventFields[fieldName];
    }
  }
  return fieldEvents;
}

function builder(entity: IEntity, type: EEntityStateDetailType): IEntityFormDefinition {
  let entityFormDefinition: IEntityFormDefinition = entity[type].definition;
  const eventFields: IEntityEventFields = eventFieldsMap.get(entity);
  if (!eventFields) {
    return entityFormDefinition;
  }
  entityFormDefinition = copy(entity[type].definition);
  for (const eventField of Object.keys(eventFields)) {
    const fieldEvents: IEntityEventField = eventFields[eventField];
    const fieldEventsKeys: Array<string> = Object.keys(fieldEvents);
    if (!fieldEventsKeys.length) {
      continue;
    }
    const field: IEntityFormDefinitionField = entityFormDefinition.fields.find((entityField: IEntityFormDefinitionField) => entityField.name === eventField);
    if (!field) {
      continue;
    }
    if (!isObject(field.events)) {
      field.events = {};
    }
    for (const eventName of fieldEventsKeys) {
      // Event callback
      field.events[eventName] = fieldEvents[eventName];
    }
  }
  return entityFormDefinition;
}
