<div class="proce-seg-social">
  <div *ngIf="isBlocked" class="blocked-by-other-user-container">
    <div class="block-inline">
      <i class="fa fa-lock" aria-hidden="true"></i>
    </div>
    <div class="block-inline">
      <h5><span [translate]="'importadorSaftDirect.messages.importInProcessModalMessageByOtherUser'"></span>:</h5>
      <div class="blocked-by-username">{{ currentStatus.userStarted }}</div>
    </div>
  </div>

  <pl-nav-wizard *ngIf="!isBlocked" [definition]="definitionNavWizard" [onFinalize]="fnFinalize" [properties]="propertiesNavWizard" [callback]="plWizardCallback">
    <pl-nav-wizard-step icon="fa-home" caption="proceSegSocial.tabIntroCaption" [stepId]="proceSegSocialSteps.INTRO">
      <div *plNavWizardStepContent>
        <h4 [translate]="'proceSegSocial.tabIntroTitle'"></h4>
        <p [translate]="'proceSegSocial.tabIntroSubTitle'"></p>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-th-list" caption="proceSegSocial.tabEmpresasCaption" [stepId]="proceSegSocialSteps.EMPRESAS_SEL" [validator]="fnValidateStepEmpresasSel">
      <div *plNavWizardStepContent>
        <div class="item-group-dropdown" tabindex="-1" ngbDropdown container="body" [placement]="['right-bottom', 'bottom-right', 'right', 'auto']">
          <button type="button" class="btn btn-sm btn-info dropdown-toggle" ngbDropdownToggle>
            <span [translate]="'proceSegSocial.btn.ordenar'" [translateParams]="{orderby: orderBySelected}" class="caret"></span>
          </button>

          <div class="dropdown-menu" ngbDropdownMenu>
            <button type="button" class="dropdown-item" [translate]="'proceSegSocial.btn.dropdown.sortEmpresasByNEmpresa'" (click)="sortEmpresasByNEmpresa()"></button>
            <button type="button" class="dropdown-item" [translate]="'proceSegSocial.btn.dropdown.sortEmpresasByName'" (click)="sortEmpresasByName()"></button>
          </div>
        </div>
        <pl-multiselect class="tab-empresas" [model]="empresasSel" [source]="empresasSource" key="nEmpresa" [template]="msEmpresasTemplate" (evtChanged)="empresasChanged()"></pl-multiselect>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-cog" caption="proceSegSocial.tabConfigCaption" [stepId]="proceSegSocialSteps.PROC_CONFIG" [validator]="fnValidateStepProcConfig">
      <div class="row" *plNavWizardStepContent>
        <div class="col-md-3">
          <div class="panel panel-warning">
            <div class="year-navi-container">
              <button type="button" class="btn btn-light btn-sm left" (click)="onDecYearClick()"><i class="fa fa-angle-left" aria-hidden="true"></i></button>
              <input class="form-control year-input" type="number" [ngModel]="selectedYear" (ngModelChange)="onMultiYearChange($event)" />
              <button type="button" class="btn btn-light btn-sm right" (click)="onIncYearClick()"><i class="fa fa-angle-right" aria-hidden="true"></i></button>
            </div>
          </div>

          <pl-table
            [definition]="multiDatasTableDef"
            [source]="fnMultiDatasTableSource"
            [callback]="multiDatasTableCallback"
            [properties]="multiDatasTableOptions"
            (evtSelect)="onSelectMultiDatasTable($event)">
          </pl-table>
        </div>

        <div class="col-md-9">
          <div class="alert alert-warning">
            <div [translate]="'proceSegSocial.procAlertMsg1'"></div>
            <div [translate]="'proceSegSocial.procAlertMsg2'"></div>
          </div>

          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinitionProcExistentes"
            cgDxDataGridInstanceName="procExistentesTable"
            [dataSource]="dataGridDefinitionProcExistentes.dataSource"
            [selectedRowKeys]="selectedRowKeys"
            (selectedRowKeysChange)="selectedRowKeys = $event; onSelectProcExistentes()"
            (onInitialized)="onInitializedProcExistentes($event)">
            <div *dxTemplate="let item of 'toolbarTemplateProcExistentes'">
              <pl-edit type="checkbox" [model]="vizProcExistentes" (modelChange)="onVizProcExistentesChange($event)" [properties]="{label: 'proceSegSocial.vizProcExistentes'}"></pl-edit>
            </div>
          </dx-data-grid>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-bolt" caption="proceSegSocial.tabProcCaption" [stepId]="proceSegSocialSteps.PROC" [hideNext]="true" [hidePrevious]="true">
      <div class="proc-container" *plNavWizardStepContent>
        <div class="progress">
          <div class="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" [attr.aria-valuemin]="0" [attr.aria-valuemax]="100" style="width: 100%"></div>
        </div>
        <div class="procLabel" [translate]="pbProcLabel"></div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-flag-checkered" caption="proceSegSocial.tabResultsCaption" [stepId]="proceSegSocialSteps.PROC_RESULTS">
      <pl-tabs *plNavWizardStepContent [callback]="procResultsTabsCallback">
        <pl-tab caption="proceSegSocial.geracaoImpressao" [id]="tabIdRrocResults">
          <div *plTabContent>
            <dx-data-grid
              [cgDxDataGrid]="dataGridDefinitionProcResults"
              cgDxDataGridInstanceName="procResultsTable"
              [dataSource]="dataGridDefinitionProcResults.dataSource"
              (onInitialized)="onInitializedProcResults($event)">
              <div *dxTemplate="let item of 'toolbarTemplateProcResults'">
                <pl-toolbar [instanceId]="toolbarInstIdProcResults"></pl-toolbar>
              </div>

              <div *dxTemplate="let item of 'cellTemplateStatusImage'">
                <i aria-hidden="true" [ngClass]="item.data.statusImage"></i>
              </div>

              <div *dxTemplate="let item of 'cellTemplateBtnProcResults'">
                <pl-tooltip [config]="{text: 'proceSegSocial.configWsSSTooltip', placement: 'left', container: 'body'}">
                  <pl-button *plTooltipContent type="button" klass="btn btn-warning btn-xs" (evtClicked)="configWsSSLogin(item.data)">
                    <i class="fa fa-cog" aria-hidden="true"></i>
                  </pl-button>
                </pl-tooltip>
                &nbsp;
                <pl-button type="button" klass="btn btn-info btn-xs" (evtClicked)="viewInfoSSClick(item.data)"><i class="fa fa-info-circle" aria-hidden="true"></i></pl-button>
              </div>
            </dx-data-grid>
          </div>
        </pl-tab>

        <pl-tab caption="proceSegSocial.erros" [id]="tabIdRrocResultsErrors">
          <div *plTabContent>
            <dx-data-grid
              [cgDxDataGrid]="dataGridDefinitionProcResultsErrors"
              cgDxDataGridInstanceName="procResultsErrorsTable"
              [dataSource]="dataGridDefinitionProcResultsErrors.dataSource"
              (onInitialized)="onInitializedProcResultsErrors($event)">
            </dx-data-grid>
          </div>
        </pl-tab>
      </pl-tabs>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
