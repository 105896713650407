import {DATA_SOURCE_NAME_AREAS_REGIONAIS} from '../../datasources/areasregionais/areasRegionais.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_DEDUCAO_TAXA} from '../../datasources/tipodeducaotaxa/tipoDeducaoTaxa.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_TAXA_IVA} from '../../datasources/tipotaxaiva/tipoTaxaIva.datasource.interface';
import {ENTITY_NAME_IVAS} from './ivas.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonIva} from './jsonIva.entity.interface';
import {IvasEditComponent} from './components/edit/ivas.entity.edit.component';
import {ROLE} from '../../services/role.const';
import {AuthService} from '../../services/auth/auth.service';
import {ENTITY_NAME_IVA_MOTIVOS_TAXA} from '../ivamotivostaxa/ivaMotivosTaxa.entity.interface';

export const ENTITY_IVAS: IEntityDefinition<IJsonIva> = {
  name: ENTITY_NAME_IVAS,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE],
  searchPlaceholder: 'ivas.pesquisa',
  metadata: {
    keyName: 'codIva',
    fields: [
      {
        name: 'codIva',
        type: 'cginteger',
        width: '120px',
        caption: 'ivas.fields.codIva',
        placeholder: 'ivas.fields.codIvaPlaceholder',
        validators: {required: true, min: 0}
      },
      {
        name: 'nome',
        caption: 'ivas.fields.nome',
        placeholder: 'ivas.fields.nomePlaceholder',
        validators: {required: true, maxlength: 20}
      },
      {
        name: 'taxaActual',
        type: 'number',
        caption: 'ivas.fields.taxaActual',
        placeholder: 'ivas.fields.taxaActualPlaceholder',
        properties: {decimalsLimit: 2},
        validators: {required: true, max: 100}
      },
      {name: 'dataTaxaActual', type: 'date', caption: 'ivas.fields.dataTaxaActual', placeholder: 'ivas.fields.dataTaxaActualPlaceholder'},
      {
        name: 'percDedGasoleo',
        type: 'number',
        caption: 'ivas.fields.percDedGasoleo',
        placeholder: 'ivas.fields.percDedGasoleoPlaceholder',
        value: 100,
        properties: {decimalsLimit: 2},
        validators: {max: 100}
      },
      {
        name: 'percDedProRata',
        caption: 'ivas.fields.percDedProRataPlaceholder',
        validators: {required: true, max: 100},
        type: 'number',
        value: 100,
        properties: {decimalsLimit: 2}
      },
      {name: 'codAlternInfac', type: 'string', caption: 'ivas.fields.codAlternInfac', validators: {maxlength: 3}},
      {name: 'tipoTaxa', type: DATA_SOURCE_NAME_TIPO_TAXA_IVA, caption: 'controloIVA.fields.tipoTaxa', placeholder: 'controloIVA.fields.tipoTaxa'},
      {name: 'areaRegional', type: DATA_SOURCE_NAME_AREAS_REGIONAIS, caption: 'ivas.fields.areaRegional', placeholder: 'ivas.fields.areaRegional'},
      {
        name: 'tipoDeducao',
        caption: 'ivas.fields.tipoDeducao',
        placeholder: 'ivas.fields.tipoDeducao',
        type: DATA_SOURCE_NAME_TIPO_DEDUCAO_TAXA
      },
      {name: 'ivaMotivosTaxaId', visible: false},
      {
        name: 'ivaMotivosTaxaDescricao',
        caption: 'ivas.fields.ivaMotivosTaxaId',
        placeholder: 'ivamotivostaxa.fields.ivaMotivosTaxaIdPlaceholder',
        entity: {name: ENTITY_NAME_IVA_MOTIVOS_TAXA, keyTarget: 'ivaMotivosTaxaId'}
      },
      {name: 'ivaMotivosTaxaCod', visible: false},
      {name: 'ivaMotivosTaxaNormaAplicavel', visible: false},
      {
        name: 'activo',
        type: 'boolean',
        caption: 'global.text.active',
        placeholder: 'global.text.active'
      }
    ],
    order: 'codIva',
    searchFields: 'codIva,nome',
    detailFields: 'codIva,nome,taxaActual,dataTaxaActual,percDedGasoleo,percDedProRata,tipoTaxa,areaRegional,tipoDeducao,ivaMotivosTaxaId,activo',
    listFields: 'codIva,nome,taxaActual,activo',
    newFields: 'codIva,nome,taxaActual,percDedGasoleo,percDedProRata,tipoTaxa,areaRegional,tipoDeducao,ivaMotivosTaxaId,activo',
    editFields: 'codIva,nome,taxaActual,percDedGasoleo,percDedProRata,tipoTaxa,areaRegional,tipoDeducao,ivaMotivosTaxaId,activo'
  },
  autocomplete: {
    rowTemplate: '{{codIva}} - {{nome}}',
    output: 'nome',
    searchFields: 'codIva,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    duplicate: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.ivas'
      }
    }
  },
  detail: {
    state: {
      component: IvasEditComponent,
      resolve: [
        {
          provide: 'contabilidade',
          deps: [AuthService],
          useFactory: (authService: AuthService) => authService.hasAuthority(ROLE.CONTABILIDADE)
        }
      ]
    }
  }
};
