import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {isEmpty, isString, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {IDevExpressDataGrid, IDevExpressDataGridEditCellTemplateData} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IJsonPOC} from '../../../../entities/pocs/jsonPOC.entity.interface';
import {ETipoCalculoDiferimento, IJsonCalculoDiferimentosConfig, IJsonCalculoDiferimentosRadicalList} from '../../jsonCalculoDiferimentos.interface';
import {calculoDiferimentosSourceTipos} from '../../diferimentos.module.interface';
import {DiferimentosService} from '../../diferimentos.module.service';

const DIFERIMENTOSNCONTARENDIMENTOSSUBTOTAL = '282';
const DIFERIMENTOSNCONTARENDIMENTOSACRESCIMOSSUBTOTAL2721 = '2721';
const DIFERIMENTOSNCONTAGASTOSSUBTOTAL = '281';
const DIFERIMENTOSNCONTAGASTOSACRESCIMOSSUBTOTAL2722 = '2722';

@Component({
  selector: 'calculo-diferimentos-config-modal',
  templateUrl: './calculoDiferimentos.config.modal.component.html'
})
export class CalculoDiferimentosConfigModalComponent extends CGModalComponent<IJsonCalculoDiferimentosConfig> implements OnInit {
  @Input() public custDif: IJsonCalculoDiferimentosConfig;

  public readonly sourceMetodos: Array<IDataSourceItem<ETipoCalculoDiferimento>>;
  public readonly filterMovimento: string;
  public dataGridDefinitionRadicaisRendimentos: IDevExpressDataGrid<IJsonCalculoDiferimentosRadicalList, IJsonCalculoDiferimentosRadicalList>;
  public dataGridDefinitionRadicaisGastos: IDevExpressDataGrid<IJsonCalculoDiferimentosRadicalList, IJsonCalculoDiferimentosRadicalList>;

  public nomeTipodiferimentoRendimentos: string;
  public nomeTipodiferimentoGastos: string;
  public pocsOutput: string;
  public rendiContaDiferFilter: string;
  public rendiContaAcresFilter: string;
  public gastContaDiferFilter: string;
  public gastContaAcresFilter: string;

  private _dataGridInstanceRendimentos: dxDataGrid<IJsonCalculoDiferimentosRadicalList, IJsonCalculoDiferimentosRadicalList>;
  private _dataGridInstanceGastos: dxDataGrid<IJsonCalculoDiferimentosRadicalList, IJsonCalculoDiferimentosRadicalList>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _calculoDiferimentosService: DiferimentosService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.pocsOutput = '{{nConta}} - {{nome}}';
    this.filterMovimento = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.custDif = {
      rendimentosRadicais: [],
      rendimentosNContaDiferimentos: '',
      nomeRendimentosNContaDif: '',
      rendimentosNContaAcrescimos: '',
      nomeRendimentosNContaAcresc: '',
      rendimentosTipoCalculo: ETipoCalculoDiferimento.Mensal,
      gastosRadicais: [],
      gastosNContaDiferimentos: '',
      nomeGastosNContaDif: '',
      gastosNContaAcrescimos: '',
      nomeGastosNContaAcresc: '',
      gastosTipoCalculo: ETipoCalculoDiferimento.Mensal,
      nDiario: undefined,
      nomeNDiario: '',
      descritivoAnulacaoInicial: undefined,
      nomeDescritivoAnulacaoInicial: '',
      descritivo: undefined,
      nomeDescritivo: '',
      descritivoAcrescimos: undefined,
      nomeDescritivoAcrescimos: '',
      isEmpresaSNC: false
    };
    this.sourceMetodos = calculoDiferimentosSourceTipos(this._translateService);
    const dataGridDefinition: IDevExpressDataGrid<IJsonCalculoDiferimentosRadicalList, IJsonCalculoDiferimentosRadicalList> = {
      columns: [
        {
          dataField: 'radical',
          dataType: 'string',
          caption: 'calculodiferimentos.modals.config.fields.radicalrendimentos',
          allowGrouping: false,
          validationRules: [
            {
              type: 'required',
              trim: true,
              message: this._translateService.instant('datagrid.column.required')
            }
          ]
        },
        {
          dataField: 'nContaAcrescimos',
          dataType: 'string',
          caption: 'calculodiferimentos.modals.config.fields.contaAcrescimos',
          allowGrouping: false,
          editCellTemplate: 'editCellAcrescimos',
          showEditorAlways: true
        },
        {
          dataField: 'nContaDiferimentos',
          dataType: 'string',
          caption: 'calculodiferimentos.modals.config.fields.contaDiferimentos',
          allowGrouping: false,
          editCellTemplate: 'editCellDiferimentos',
          showEditorAlways: true
        }
      ],
      editing: {allowAdding: true, allowUpdating: true, allowDeleting: true, mode: 'cell'},
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      groupPanel: {visible: false},
      allowColumnReordering: false,
      export: {enabled: false},
      filterRow: {visible: false},
      remoteOperations: false,
      headerFilter: {visible: false},
      grouping: {allowCollapsing: false},
      sorting: {mode: 'none'},
      hoverStateEnabled: true
    };
    this.dataGridDefinitionRadicaisRendimentos = {
      ...dataGridDefinition
    };
    this.dataGridDefinitionRadicaisGastos = {
      ...dataGridDefinition
    };
  }

  public ngOnInit(): void {
    this.rendiContaDiferFilter = this.custDif.isEmpresaSNC ? `temCC=0&nConta=%${DIFERIMENTOSNCONTARENDIMENTOSSUBTOTAL}%` : 'temCC=0';
    this.rendiContaAcresFilter = this.custDif.isEmpresaSNC ? `temCC=0&nConta=%${DIFERIMENTOSNCONTARENDIMENTOSACRESCIMOSSUBTOTAL2721}%` : 'temCC=0';
    this.gastContaDiferFilter = this.custDif.isEmpresaSNC ? `temCC=0&nConta=%${DIFERIMENTOSNCONTAGASTOSSUBTOTAL}%` : 'temCC=0';
    this.gastContaAcresFilter = this.custDif.isEmpresaSNC ? `temCC=0&nConta=%${DIFERIMENTOSNCONTAGASTOSACRESCIMOSSUBTOTAL2722}%` : 'temCC=0';

    this.nomeTipodiferimentoRendimentos = this._translateService.instant(this.sourceMetodos[this.custDif.rendimentosTipoCalculo].label);
    this.nomeTipodiferimentoGastos = this._translateService.instant(this.sourceMetodos[this.custDif.gastosTipoCalculo].label);
  }

  public async close(): Promise<void> {
    if (isEmpty(this.custDif.rendimentosNContaDiferimentos) || isEmpty(this.custDif.gastosNContaDiferimentos)) {
      this._plAlertService.error('calculodiferimentos.modals.config.messages.nContaDiferimentosEmpty');
      return;
    }
    if (isEmpty(this.custDif.rendimentosNContaAcrescimos) || isEmpty(this.custDif.gastosNContaAcrescimos)) {
      this._plAlertService.error('calculodiferimentos.modals.config.messages.nContaAcrescimosEmpty');
      return;
    }
    if (isEmpty(this.custDif.nDiario)) {
      this._plAlertService.error('calculodiferimentos.modals.config.messages.nDiarioEmpty');
      return;
    }
    if (isEmpty(this.custDif.descritivoAnulacaoInicial) || isEmpty(this.custDif.descritivo) || isEmpty(this.custDif.descritivoAcrescimos)) {
      this._plAlertService.error('calculodiferimentos.modals.config.messages.descritivoEmpty');
      return;
    }
    await this._calculoDiferimentosService.saveConfigsCustDif(this.custDif);
    super.close(this.custDif);
  }

  public onInitializedRendimentos({component}: IDevExpressDataGridEventOnInitialized<IJsonCalculoDiferimentosRadicalList, IJsonCalculoDiferimentosRadicalList>): void {
    this._dataGridInstanceRendimentos = component;
  }

  public onInitializedGastos({component}: IDevExpressDataGridEventOnInitialized<IJsonCalculoDiferimentosRadicalList, IJsonCalculoDiferimentosRadicalList>): void {
    this._dataGridInstanceGastos = component;
  }

  public setTipoDiferimento(value: IDataSourceItem<ETipoCalculoDiferimento>, fromGastos: boolean): void {
    if (fromGastos) {
      this.custDif.gastosTipoCalculo = value?.value;
      this.nomeTipodiferimentoGastos = value?.label;
    } else {
      this.custDif.rendimentosTipoCalculo = value?.value;
      this.nomeTipodiferimentoRendimentos = value?.label;
    }
  }

  public changedCellContaAcrescimos(cellInfo: IDevExpressDataGridEditCellTemplateData<IJsonCalculoDiferimentosRadicalList, IJsonCalculoDiferimentosRadicalList>, value: string | IJsonPOC): void {
    let nConta: string;
    let nomeConta: string;
    if (isString(value)) {
      nConta = value || null;
      nomeConta = null;
    } else {
      nConta = value?.nConta || null;
      nomeConta = value?.nome || null;
    }
    cellInfo.setValue(nConta, nConta);
    cellInfo.component.cellValue(cellInfo.rowIndex, 'nomeContaAcrescimos', nomeConta);
  }

  public changedCellContaDiferimentos(cellInfo: IDevExpressDataGridEditCellTemplateData<IJsonCalculoDiferimentosRadicalList, IJsonCalculoDiferimentosRadicalList>, value: string | IJsonPOC): void {
    let nConta: string;
    let nomeConta: string;
    if (isString(value)) {
      nConta = value || null;
      nomeConta = null;
    } else {
      nConta = value?.nConta || null;
      nomeConta = value?.nome || null;
    }
    cellInfo.setValue(nConta, nConta);
    cellInfo.component.cellValue(cellInfo.rowIndex, 'nomeContaDiferimentos', nomeConta);
  }

  public rendimentosInputchanged(): void {
    this._dataGridInstanceRendimentos.refresh();
  }

  public gastosInputchanged(): void {
    this._dataGridInstanceGastos.refresh();
  }
}
