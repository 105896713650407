import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonCondicaoComercial} from './jsonCondicaoComercial.entity.interface';
import {DATA_SOURCE_NAME_TIPO_RESUMO_MENSAL} from '../../datasources/tiporesumomensal/tipoResumoMensal.datasource.interface';
import {DATA_SOURCE_NAME_ORIGEM_DATA_VENCIMENTO} from '../../datasources/origemdatavencimento/origemDataVencimento.datasource.interface';
import {DATA_SOURCE_NAME_ORIGEM_COND_PAGAMENTO} from '../../datasources/origemcondpagamento/origemCondPagamento.datasource.interface';
import {CondicoescomerciaisEditComponent} from './components/edit/condicoesComerciais.entity.edit.component';
import {ENTITY_NAME_CONDICOES_COMERCIAIS} from './condicoesComerciais.entity.interface';

const condComFields = 'codCondComerciais,descricao,origemCondPagamento,nomeCondPagamento,origemDataVencimento,numDiasDataVenc,tipoResumoMensal';
const condComFieldsList = 'codCondComerciais,descricao';

export const ENTITY_CONDICOES_COMERCIAIS: IEntityDefinition<IJsonCondicaoComercial> = {
  name: ENTITY_NAME_CONDICOES_COMERCIAIS,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE],
  searchPlaceholder: 'condicoescomerciais.pesquisa',
  metadata: {
    keyName: 'codCondComerciais',
    fields: [
      {
        name: 'codCondComerciais',
        type: 'cgsmallint',
        width: '120px',
        caption: 'condicoescomerciais.fields.codCondComerciais',
        placeholder: 'condicoescomerciais.fields.codCondComerciaisPlaceholder',
        validators: {required: true}
      },
      {
        name: 'descricao',
        caption: 'condicoescomerciais.fields.descricao',
        placeholder: 'condicoescomerciais.fields.descricaoPlaceholder',
        validators: {required: true, maxlength: 50}
      },
      {
        name: 'nomeCondPagamento',
        caption: 'condicoescomerciais.fields.nomeCondPagamento',
        placeholder: 'condicoescomerciais.fields.nomeCondPagamentoPlaceholder',
        entity: {name: 'condicoespagamento', keyTarget: 'condPagamento', outputTarget: 'nomeCondPagamento'}
      },
      {
        name: 'numDiasDataVenc',
        type: 'cgsmallint',
        caption: 'condicoescomerciais.fields.numDiasDataVenc',
        placeholder: 'condicoescomerciais.fields.numDiasDataVencPlaceholder',
        validators: {min: 0}
      },
      {
        name: 'tipoResumoMensal',
        type: DATA_SOURCE_NAME_TIPO_RESUMO_MENSAL,
        caption: 'condicoescomerciais.fields.tipoResumoMensal'
      },
      {
        name: 'nomeCondComTabRegCab',
        caption: 'condicoescomerciais.fields.nomeCondComTabRegCab',
        placeholder: 'condicoescomerciais.fields.nomeCondComTabRegCabPlaceholder',
        visible: false,
        validators: {min: 1}
      },
      {
        name: 'origemCondPagamento',
        type: DATA_SOURCE_NAME_ORIGEM_COND_PAGAMENTO,
        caption: 'condicoescomerciais.fields.origemCondPagamento',
        placeholder: 'condicoescomerciais.fields.origemCondPagamentoPlaceholder',
        visible: true,
        validators: {required: true}
      },
      {
        name: 'origemDataVencimento',
        type: DATA_SOURCE_NAME_ORIGEM_DATA_VENCIMENTO,
        caption: 'condicoescomerciais.fields.origemDataVencimento',
        placeholder: 'condicoescomerciais.fields.origemDataVencimentoPlaceholder',
        visible: true,
        validators: {required: true}
      }
    ],
    order: 'codCondComerciais',
    searchFields: 'codCondComerciais,descricao',
    detailFields: condComFields,
    listFields: condComFieldsList,
    newFields: condComFields,
    editFields: condComFields
  },
  autocomplete: {
    rowTemplate: '{{codCondComerciais}} - {{descricao}}',
    output: 'descricao',
    searchFields: 'codCondComerciais,descricao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.condicoescomerciais'
      }
    }
  },
  detail: {
    state: {
      component: CondicoescomerciaisEditComponent
    }
  },
  new: {
    state: {
      component: CondicoescomerciaisEditComponent
    }
  }
};
