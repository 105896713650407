import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isArray, isEmpty, PlLocationService} from 'pl-comps-angular';
import {ApiService} from '../api/api.service';
import {IApiRequestConfig, IApiRequestConfigWithBody, TServiceResponse} from '../api/api.service.interface';
import {IJsonConfigERPUser} from '../../entities/configserp/jsonConfigERP.entity.interface';
import {IJsonUserLicenca} from './jsonUserLicensa.interface';
import {IJsonUserLoginRequest, IJsonUserResetPassword, TRecaptchaTokenType, TUserSession} from './jsonUserApi.interface';
import {STATE_NAME_CHANGE_PASSWORD} from '../../states/account/changepassword/changepassword.interface';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private _accountPromise: TServiceResponse<TUserSession>;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _plLocationService: PlLocationService,
    private readonly _translateService: TranslateService
  ) {}

  public login(
    username: string,
    password: string,
    recaptchaType: TRecaptchaTokenType,
    recaptchaToken: string,
    config?: IApiRequestConfigWithBody<IJsonUserLoginRequest>
  ): TServiceResponse<TUserSession> {
    return this._apiService.post<TUserSession, IJsonUserLoginRequest>({
      ...config,
      url: `${this._apiService.path.account}/login`,
      body: {username: username, password: password},
      params: {recaptchaType: recaptchaType, recaptchaToken: recaptchaToken}
    });
  }

  public logout(): TServiceResponse<void> {
    return this._apiService.get<void>({url: `${this._apiService.path.account}/logout`});
  }

  public getMe(force: boolean = false, config?: IApiRequestConfig): TServiceResponse<TUserSession> {
    if (force || !this._accountPromise) {
      const doForce = force ? 1 : 0;
      this._accountPromise = this._apiService.get<TUserSession>({url: `${this._apiService.path.account}/me`, params: {force: String(doForce)}, ...config}).finally(() => {
        this._accountPromise = undefined;
      });
    }
    return this._accountPromise;
  }

  public licenca(): TServiceResponse<IJsonUserLicenca> {
    return this._apiService.get<IJsonUserLicenca>({url: `${this._apiService.path.account}/licenca`});
  }

  public accesses(empresaOuEmpresas?: string | Array<string>): TServiceResponse<Array<IJsonConfigERPUser>> {
    const empresas: Array<string> = isEmpty(empresaOuEmpresas) ? [] : isArray(empresaOuEmpresas) ? empresaOuEmpresas : [empresaOuEmpresas];
    const empresasAsParam: string = empresas.join(',');
    return this._apiService.get<Array<IJsonConfigERPUser>>({url: `${this._apiService.path.account}/accesses`, params: {empresas: empresasAsParam}});
  }

  public resetPassword(username: string): TServiceResponse<void> {
    return this._apiService.get<void>({
      url: `${this._apiService.path.account}/resetpassword`,
      params: {
        url: `${this._plLocationService.getUrl()}/${STATE_NAME_CHANGE_PASSWORD}`,
        usernameoremail: username,
        templatename: 'ResetPassword'
      }
    });
  }

  public changePassword(password: string, confirmPassword: string, token: string): TServiceResponse<void> {
    if (password !== confirmPassword) {
      return Promise.reject(new Error(this._translateService.instant('changepassword.erro.nao.coincidem')));
    }
    const promise = this._apiService.post<void, IJsonUserResetPassword>({
      url: `${this._apiService.path.account}/setpassword`,
      body: {token: token, newPassword: password}
    });
    promise.then(
      (response) => {
        return response;
      },
      (error) => {
        return error;
      }
    );
    return promise;
  }

  public sendInstructionsNewUser(usernameOrEmail: string): TServiceResponse<void> {
    return this._apiService.get<void>({
      url: `${this._apiService.path.account}/resetpassword`,
      params: {
        url: `${this._plLocationService.getUrl()}/${STATE_NAME_CHANGE_PASSWORD}`,
        usernameoremail: usernameOrEmail,
        templatename: 'NovoUtilizador'
      }
    });
  }

  public changeEmpresa(cgId: number): TServiceResponse<TUserSession> {
    return this._apiService.post<TUserSession, void>({
      url: `${this._apiService.path.account}/changeempresa`,
      params: {cgId: cgId}
    });
  }

  public loginEmpresa(nEmpresa: string): TServiceResponse<TUserSession> {
    return this._apiService.get<TUserSession>({
      url: `${this._apiService.path.account}/loginEmpresa`,
      params: {nEmpresa: nEmpresa}
    });
  }
}
