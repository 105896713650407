import {isEqual, merge, orderBy} from 'lodash-es';
import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {copy, isArray, isBoolean, isDefined, isEmpty, isFunction, isObject, isString, KEYCODES, moveItemInArray, PlI18nPlNumberService} from 'pl-comps-angular';
import {
  CONTABILIDADE_PREDEFINIDOS_DEFAULT_LINHA_VISUAL_PROPERTIES,
  IPreDefinidoContabFormula,
  IPreDefinidoContabFormulaTableBodyDefinition,
  IPreDefinidoContabFormulaTableBodyDefinitionGroup,
  IPreDefinidoContabFormulaTableBodyDefinitionGroupField,
  IPreDefinidoContabFormulaTableBodyDefinitionGroups,
  IPreDefinidoContabFormulaTableHeaderDefinition,
  IPreDefinidoContabFormulaTableHeaderDefinitionLine,
  IPreDefinidoContabFormulaVaiImputarLinha,
  IPreDefinidoContabFormulaVisualProperty,
  TPreDefinidoContabFormulaTableBodyDefinitionGroups,
  TPreDefinidoContabHeader
} from './predefinidocontablinhas.component.interface';
import {CONTABILIDADE_PREDEFINIDOS_DEFAULT_ORDEM_COLUNAS, IPreDefinidoContabLinha} from '../../preDefinidosContab.module.interface';
import {ContabilidadePredefinidoService} from '../../service/contabilidade.predefinido.module.service';
import {DATA_SOURCE_DEBITO_CREDITO} from '../../../../../../datasources/debitocredito/debitoCredito.datasource';
import {
  EPreDefinidoContabOrigemDataDoc,
  EPreDefinidoContabTipoConta,
  EPreDefinidoContabTipoValorDescricao,
  EPreDefinidoContabTipoValorDescritivo,
  EPreDefinidoContabTipoValorLinha,
  IJsonPreDefinidoContab,
  IJsonPreDefinidoContabItemDefault,
  IJsonPreDefinidoContabItemVisibilidadeProperties,
  IJsonPreDefinidoContabLinha,
  TPredefinidoVisibilidadeField
} from '../../jsonPreDefinidosContab.module.interface';
import {ETipoContaContabilidade} from '../../../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {focusElement} from '../../../../../../../common/utils/element.utils';

const ALLOWS_FORMULA: Array<IPreDefinidoContabFormula> = [
  {name: 'valor', valueProperty: 'tipoValor', property: 'visibleValorFormula', propertyValue: 'valorFormula', value: 2},
  {name: 'descricao', valueProperty: 'tipoValorDescricao', property: 'visibleValorFormula', propertyValue: 'valorFormula', value: 2}
];

@Component({
  selector: 'predefinidocontablinhas',
  templateUrl: './predefinidocontablinhas.component.html'
})
export class PredefinidoContabLinhasComponent implements OnInit, OnChanges {
  @Input() public predefinido: IJsonPreDefinidoContab;
  @Input() public valid: boolean;
  @Output() public readonly validChange: EventEmitter<boolean>;

  public readonly fieldToFocus: string;
  public tableHeaderDefinition: Array<IPreDefinidoContabFormulaTableHeaderDefinition>;
  public tableBodyDefinition: Array<IPreDefinidoContabFormulaTableBodyDefinition>;
  public linhas: Array<IPreDefinidoContabLinha>;
  public changedTableOrder: boolean;
  public selectedLine: IPreDefinidoContabLinha;
  public selectedCell: IPreDefinidoContabFormulaTableBodyDefinition;
  public visualProperties: Array<IPreDefinidoContabFormulaVisualProperty>;
  public titleProperties: string;

  private readonly _element: HTMLElement;
  private _lineId: number;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _translateService: TranslateService,
    private readonly _plI18nPlNumberService: PlI18nPlNumberService,
    private readonly _contabilidadePredefinidoService: ContabilidadePredefinidoService
  ) {
    this.valid = false;
    this.validChange = new EventEmitter<boolean>();
    this.fieldToFocus = 'valorFixo';
    this.linhas = [];
    this.changedTableOrder = false;
    this.titleProperties = '';
    this._element = this._elementRef.nativeElement;
    this._lineId = 0;
  }

  public ngOnInit(): void {
    this._initDefinitions();
    this._handleChanges();
  }

  public ngOnChanges({predefinido, valid}: SimpleChanges): void {
    if (predefinido && !predefinido.isFirstChange()) {
      this._changedPredefinido(predefinido.currentValue);
    }
    if (valid && !valid.isFirstChange()) {
      this._changedValid(valid.currentValue);
    }
  }

  public addEmptyLine(): void {
    this._addLine(this._getEmptyLine());
  }

  public duplicateLine(): void {
    if (this.selectedLine) {
      const line: IPreDefinidoContabLinha = copy<IPreDefinidoContabLinha>(this.selectedLine);
      line._id = this._getNextLineId();
      line._selected = false;
      this._addLine(line);
    }
  }

  public moveLineUp(): void {
    if (this.selectedLine) {
      const index: number = this.linhas.findIndex((line: IPreDefinidoContabLinha) => line._id === this.selectedLine._id);
      if (index > 0) {
        moveItemInArray(this.linhas, index, index - 1);
        this._evaluateLinhasIndex();
        this._resetLine(this.tableBodyDefinition, this.linhas[this.selectedLine._index]);
      }
    }
  }

  public moveLineDown(): void {
    if (this.selectedLine) {
      const index: number = this.linhas.findIndex((line: IPreDefinidoContabLinha) => line._id === this.selectedLine._id);
      if (index !== -1 && index < this.linhas.length - 1) {
        moveItemInArray(this.linhas, index, index + 1);
        this._evaluateLinhasIndex();
        this._resetLine(this.tableBodyDefinition, this.linhas[this.selectedLine._index]);
      }
    }
  }

  public removeLine(): void {
    if (this.selectedLine) {
      const index = this.linhas.findIndex((line) => line._id === this.selectedLine._id);
      if (index !== -1) {
        this.linhas.splice(index, 1);
        this._evaluateLinhasIndex();
        if (this.linhas.length) {
          if (index <= this.linhas.length - 1) {
            if (index >= 0) {
              this.selectLine(this.linhas[index]);
            } else {
              this.selectLine(this.linhas[0]);
            }
          } else {
            this.selectLine(this.linhas[this.linhas.length - 1]);
          }
        } else {
          this.selectLine(undefined);
          this.selectCell(undefined);
        }
      }
    }
  }

  public selectLine(line: IPreDefinidoContabLinha): void {
    if (this.selectedLine) {
      this.selectedLine._selected = false;
    }
    if (line) {
      line._selected = true;
    }
    this._setSelectedLine(line);
    this._setTitleProperties();
  }

  public selectCell(cell: IPreDefinidoContabFormulaTableBodyDefinition): void {
    if (this.selectedCell) {
      this.selectedCell.selected = false;
    }
    if (cell) {
      cell.selected = true;
    }
    this._setSelectedCell(cell);
    if (this.selectedCell?.groups) {
      if (this.selectedLine && this.linhas?.length) {
        for (const group of Object.keys(this.selectedCell.groups)) {
          const fields = (<IPreDefinidoContabFormulaTableBodyDefinitionGroup>this.selectedCell.groups[group]).fields;
          for (const field of fields) {
            if (isFunction(field.onInit)) {
              field.onInit(this.linhas[this.selectedLine._index], fields);
            }
          }
        }
      }
      if (isFunction(this.selectedCell.click)) {
        this.selectedCell.click();
      }
    }
    this._setTitleProperties();
  }

  public getCellValue(cell: IPreDefinidoContabFormulaTableBodyDefinition, line: number): string {
    if (!this.selectedLine) {
      return '';
    }
    const model = this.linhas[line];
    const value: Array<string> = [];
    switch (cell.name) {
      case 'conta':
        if (cell.id !== model.conta.dC) {
          return '';
        }
        if (model.conta.valorFixo) {
          value.push(model.conta.valorFixo);
        }
        if (model.conta.tipoConta === EPreDefinidoContabTipoConta.ContaCorrente) {
          value.push('C.C.');
        } else if (model.conta.tipoConta === EPreDefinidoContabTipoConta.BaseTributavel) {
          value.push('B.T.');
        }
        if (!value.length) {
          value.push(this._translateService.instant('predefinidocontablinhas.titles.ask'));
        }
        break;
      case 'valor':
        switch (model.valor.tipoValor) {
          case EPreDefinidoContabTipoValorLinha.Fixo:
            if (model.valor.valorFixo || model.valor.valorFixo === 0) {
              value.push(this._plI18nPlNumberService.formatPlNumber(model.valor.valorFixo));
            }
            break;
          case EPreDefinidoContabTipoValorLinha.Formula:
            value.push(model.valor.visibleValorFormula || '');
            break;
          case EPreDefinidoContabTipoValorLinha.SaldaDoc:
            value.push(this._translateService.instant('predefinidocontablinhas.titles.salda'));
            break;
          case EPreDefinidoContabTipoValorLinha.ArredondamentoeFatura:
            value.push(this._translateService.instant('predefinidocontablinhas.titles.arredEFatura'));
            break;
          default:
            break;
        }
        if (!value.length) {
          value.push(this._translateService.instant('predefinidocontablinhas.titles.ask'));
        }
        break;
      case 'descricao':
        if (model.descricao.tipoValorDescricao === EPreDefinidoContabTipoValorDescricao.Fixo) {
          value.push(model.descricao.valorFixo);
        } else if (model.descricao.tipoValorDescricao === EPreDefinidoContabTipoValorDescricao.Formula) {
          value.push(model.descricao.visibleValorFormula || '');
        }
        break;
      case 'descritivo':
        if (model.descritivo.tipoValorDescritivo === EPreDefinidoContabTipoValorDescritivo.ObtidoPorUltimoDocumento && isDefined(model.descritivo.valorFixo)) {
          value.push(String(model.descritivo.valorFixo));
        }
        break;
      default:
        break;
    }
    return value.join('<br/>');
  }

  public isFieldVisible(groupOrField: IPreDefinidoContabFormulaTableBodyDefinitionGroup | IPreDefinidoContabFormulaTableBodyDefinitionGroupField): boolean {
    if (!groupOrField || !this.selectedLine) {
      return true;
    }
    if (isFunction(groupOrField.visible)) {
      try {
        const result: boolean = groupOrField.visible(this.linhas[this.selectedLine._index]);
        return isBoolean(result) ? result : true;
      } catch (e: unknown) {
        return true;
      }
    } else if (groupOrField.visible === false) {
      return false;
    }
    return true;
  }

  public fieldChanged(
    field: IPreDefinidoContabFormulaTableBodyDefinitionGroupField,
    groupFields?: Array<IPreDefinidoContabFormulaTableBodyDefinitionGroupField>,
    model?: object,
    newValue?: unknown
  ): void {
    if (model) {
      model[field.name] = newValue;
    }
    if (isFunction(field.onChange)) {
      field.onChange(this.linhas[this.selectedLine._index], groupFields, newValue);
    }
  }

  public fieldChangedVisual(field: IPreDefinidoContabFormulaVisualProperty, visibilidadeProperties: IJsonPreDefinidoContabItemVisibilidadeProperties, newValue: boolean | number): void {
    if (field.name === 'visible') {
      for (const linha of this.linhas) {
        (<IJsonPreDefinidoContabItemDefault>linha[this.selectedCell.name]).visibilidadeProperties[field.name] = <boolean>newValue;
      }
    } else {
      // @ts-expect-error This error is a typescript limitation
      visibilidadeProperties[field.name] = newValue;
    }
    this.fieldChanged(field);
  }

  public getGroupFieldModel(groupName: keyof IPreDefinidoContabFormulaTableBodyDefinitionGroups): object {
    if (!this.selectedLine || !this.selectedCell) {
      return {};
    }
    try {
      return groupName === 'base' ? this.linhas[this.selectedLine._index][this.selectedCell.name] : this.linhas[this.selectedLine._index][this.selectedCell.name][groupName];
    } catch (e: unknown) {
      return {};
    }
  }

  public resetTableOrder(): void {
    this.changedTableOrder = false;
    this._handleOrderBy(CONTABILIDADE_PREDEFINIDOS_DEFAULT_ORDEM_COLUNAS);
  }

  /* TODO: reimplement drag/drop
    public sortUpdate(ui: CdkDragSortEvent): void {
      const from: number = ui.previousIndex;
      const to: number = ui.currentIndex;
      this.changedTableOrder = true;
      const previousColumn = this.tableBodyDefinition[from];
      const toHeader = this.tableBodyDefinition[to].header;
      const toIndex = previousColumn.index;
      const previousHeader = previousColumn.header;
      const previousIndex = this.tableBodyDefinition[to].index;
      this.tableBodyDefinition[from] = this.tableBodyDefinition[to];
      this.tableBodyDefinition[from].header = previousHeader;
      this.tableBodyDefinition[from].index = toIndex;
      this.tableBodyDefinition[to] = previousColumn;
      this.tableBodyDefinition[to].header = toHeader;
      this.tableBodyDefinition[to].index = previousIndex;
      this._assignFormulaValues(toHeader, previousHeader);
    }

    public buildOrderBy(): void {
      const definition: Array<IPreDefinidoContabFormulaTableBodyDefinition> = orderBy(this.tableBodyDefinition, 'id');
      const ordemColunas: Array<number> = [];
      for (const item of definition) {
        ordemColunas.push(item.index);
      }
      this.predefinido.ordemColunas = ordemColunas;
    }
  */

  public assignFormulaValue(newHeader: string, oldHeader: string, value: string): string {
    if (value && newHeader !== oldHeader) {
      let newValue = '';
      for (const char of value) {
        if (char !== newHeader && char !== oldHeader) {
          newValue += char;
        } else if (char === newHeader) {
          newValue += oldHeader;
        } else if (char === oldHeader) {
          newValue += newHeader;
        }
      }
      return newValue;
    }
    return value;
  }

  private _handleChanges(): void {
    this._changedPredefinido();
    this._changedValid();
  }

  private _changedPredefinido(value: IJsonPreDefinidoContab = this.predefinido): void {
    this.predefinido = value;
    if (isObject(this.predefinido)) {
      this.linhas = isArray(this.predefinido.linhas) ? this.predefinido.linhas : [];
      this._reset();
      this._changedLinhas(this.linhas);
      this._handleOrderBy(this.predefinido.ordemColunas);
      if (this.linhas.length) {
        if (!this.selectedLine) {
          this.selectLine(this.linhas[0]);
        }
        if (!this.selectedCell) {
          this.selectCell(this.tableBodyDefinition[0]);
        }
      }
    } else {
      this.linhas = [];
    }
  }

  private _changedValid(value: boolean = this.valid): void {
    let val: boolean = value;
    if (isBoolean(val)) {
      val = this._evaluateIsValid();
    }
    this.valid = val;
  }

  private _changedLinhas(linhas: Array<IPreDefinidoContabLinha>): void {
    for (let i = 0; i < linhas.length; i++) {
      linhas[i] = merge(this._getEmptyLine(), linhas[i], {_index: i});
    }
  }

  private _reset(): void {
    this._lineId = 0;
    this._setSelectedCell(undefined);
    this._setSelectedLine(undefined);
  }

  private _initDefinitions(): void {
    this.tableHeaderDefinition = [
      {
        lines: [
          {rowspan: 2, locked: true}, // nContaDebito
          {colspan: 2, text: 'A', locked: true}, // nContaCredito
          {text: 'B'}, // valor
          {text: 'C'}, // valorTaxa
          {text: 'D'}, // poc
          {text: 'E'}, // descricao
          {text: 'F', visible: false}, // nDocExterno
          {text: 'G', visible: false}, // nomeDescritivo
          {text: 'H'}, // dataDoc
          {text: 'I', visible: false}, // dataVencimento
          {text: 'J', visible: false}, // unknown
          {text: 'L'} // nomeConta
        ]
      },
      {
        lines: [
          {id: 0, index: 0, text: 'docscontabilidade.doc.linhas.nContaDebito', field: 'conta', locked: true}, // A
          {id: 1, index: 1, text: 'docscontabilidade.doc.linhas.nContaCredito', field: 'conta', locked: true}, // A
          {id: 2, index: 2, text: 'docscontabilidade.doc.linhas.valor', field: 'valor'}, // B
          {id: 3, index: 3, text: 'docscontabilidade.doc.linhas.valorTaxa', field: 'valorIva'}, // C
          {id: 4, index: 4, text: 'docscontabilidade.doc.linhas.poc.nif', field: 'nContribuinte'}, // D
          {id: 5, index: 5, text: 'docscontabilidade.doc.linhas.descricao', field: 'descricao'}, // E
          {id: 6, index: 6, text: 'docscontabilidade.doc.linhas.nDocExterno', field: 'nDocExterno', visible: false}, // F
          {id: 7, index: 7, text: 'docscontabilidade.doc.cab.nomeDescritivo', field: 'descritivo', visible: false}, // G
          {id: 8, index: 8, text: 'docscontabilidade.doc.linhas.dataDoc', field: 'dataDoc'}, // H
          {id: 9, index: 9, text: 'docscontabilidade.doc.linhas.dataVencimento', field: 'dataVencimento', visible: false}, // I
          {id: 10, index: 10, text: undefined, field: undefined, visible: false}, // J is unknown
          {id: 11, index: 11, text: 'docscontabilidade.doc.linhas.poc.nomePlaceholder', field: 'nomeConta'} // L
        ]
      }
    ];
    this.tableBodyDefinition = [
      {
        id: 0,
        index: 0,
        name: 'conta',
        header: 'A',
        originalHeader: 'A',
        groups: undefined,
        click: () => {
          this._clickConta();
        }
      },
      {
        id: 1,
        index: 1,
        name: 'conta',
        header: 'A',
        originalHeader: 'A',
        groups: undefined,
        click: () => {
          this._clickConta();
        }
      },
      {
        id: 2,
        index: 2,
        name: 'valor',
        header: 'B',
        originalHeader: 'B',
        groups: undefined,
        allowComplexFormula: true,
        click: () => {
          this._clickValor();
        }
      },
      {
        id: 3,
        index: 3,
        name: 'valorIva',
        header: 'C',
        originalHeader: 'C',
        groups: undefined,
        visual: 'visible'
      },
      {
        id: 4,
        index: 4,
        name: 'nContribuinte',
        header: 'D',
        originalHeader: 'D',
        groups: undefined
      },
      {
        id: 5,
        index: 5,
        name: 'descricao',
        header: 'E',
        originalHeader: 'E',
        groups: undefined,
        allowSimpleFormula: true
      },
      {
        id: 6,
        index: 6,
        name: 'nDocExterno',
        header: 'F',
        originalHeader: 'F',
        groups: undefined,
        visible: false
      },
      {
        id: 7,
        index: 7,
        name: 'descritivo',
        header: 'G',
        originalHeader: 'G',
        groups: undefined,
        visible: false
      },
      {
        id: 8,
        index: 8,
        name: 'dataDoc',
        header: 'H',
        originalHeader: 'H',
        groups: undefined
      },
      {
        id: 9,
        index: 9,
        name: 'dataVencimento',
        header: 'I',
        originalHeader: 'I',
        groups: undefined,
        visible: false
      },
      {
        id: 10,
        index: 10,
        name: undefined,
        header: 'J',
        originalHeader: 'J',
        groups: undefined,
        visible: false
      },
      {
        id: 11,
        index: 11,
        name: 'nomeConta',
        header: 'L',
        originalHeader: 'L',
        groups: undefined,
        visual: 'visible'
      }
    ];
    for (const definition of this.tableBodyDefinition) {
      definition.groups = this._getDefinition(definition.name);
    }
  }

  private _getDefinition(field: keyof IJsonPreDefinidoContabLinha): TPreDefinidoContabFormulaTableBodyDefinitionGroups {
    const result: TPreDefinidoContabFormulaTableBodyDefinitionGroups = new Map<keyof IPreDefinidoContabFormulaTableBodyDefinitionGroups, IPreDefinidoContabFormulaTableBodyDefinitionGroup>();
    let groups: IPreDefinidoContabFormulaTableBodyDefinitionGroups;
    switch (field) {
      case 'conta':
        groups = {
          base: {
            fields: [
              {
                name: 'dC',
                type: 'radiogroup',
                caption: 'predefinidocontablinhas.fields.dC',
                properties: {groupItems: DATA_SOURCE_DEBITO_CREDITO.data},
                onChange: (model: IPreDefinidoContabLinha, fields: Array<IPreDefinidoContabFormulaTableBodyDefinitionGroupField>) => {
                  this._setPocFilter(model, fields);
                }
              },
              {
                name: 'valorFixo',
                type: 'pocs',
                caption: 'predefinidocontabcab.fields.valorFixoConta',
                properties: {
                  allowInvalid: false,
                  allowEmpty: true,
                  inlineMode: true,
                  entity: {keyTarget: 'nConta', output: 'nConta'},
                  events: {keydown: this._keyDownContaFixa.bind(this)}
                },
                onInit: (model: IPreDefinidoContabLinha, fields: Array<IPreDefinidoContabFormulaTableBodyDefinitionGroupField>) => {
                  this._setPocFilter(model, fields);
                },
                onChange: (model: IPreDefinidoContabLinha, fields: Array<IPreDefinidoContabFormulaTableBodyDefinitionGroupField>, newValue: string) => {
                  if (isString(newValue) && (newValue.startsWith('11') || newValue.startsWith('12'))) {
                    model.conta.tipoConta = EPreDefinidoContabTipoConta.CGBanking;
                  }
                }
              },
              {
                name: 'radical',
                type: 'text',
                caption: 'predefinidocontablinhas.fields.radical',
                properties: {
                  events: {keydown: this._keyDownRadical.bind(this)}
                },
                onChange: (model: IPreDefinidoContabLinha, fields: Array<IPreDefinidoContabFormulaTableBodyDefinitionGroupField>) => {
                  this._setPocFilter(model, fields);
                }
              },
              {
                name: 'tipoConta',
                type: 'radiogroup',
                caption: 'predefinidocontablinhas.fields.tipoConta',
                properties: {
                  groupItems: [
                    {value: EPreDefinidoContabTipoConta.NaoDefinido, label: 'predefinidocontablinhas.enums.tipoConta.0'},
                    {value: EPreDefinidoContabTipoConta.ContaCorrente, label: 'predefinidocontablinhas.enums.tipoConta.1'},
                    {value: EPreDefinidoContabTipoConta.BaseTributavel, label: 'predefinidocontablinhas.enums.tipoConta.2'},
                    {value: EPreDefinidoContabTipoConta.CGBanking, label: 'predefinidocontablinhas.enums.tipoConta.3'}
                  ]
                },
                onChange: (model: IPreDefinidoContabLinha, fields: Array<IPreDefinidoContabFormulaTableBodyDefinitionGroupField>) => {
                  this._setPocFilter(model, fields);
                }
              }
            ]
          },
          baseTributavelProperties: {
            caption: 'predefinidocontablinhas.titles.baseTributavelProperties',
            fields: [{name: 'codRepCC', type: 'ccustos', caption: 'predefinidocontablinhas.fields.codRepCC'}],
            visible: () => false
          },
          contaCorrenteProperties: {
            caption: 'predefinidocontablinhas.titles.contaCorrenteProperties',
            fields: [
              {name: 'temImputacao', type: 'boolean', caption: 'predefinidocontablinhas.fields.temImputacao'},
              {
                name: 'vaiImputarALinha',
                type: 'select',
                caption: 'predefinidocontablinhas.fields.vaiImputarALinha',
                properties: {select: {list: () => this._getVaiImputarALinha()}},
                onChange: (model: IPreDefinidoContabLinha, groupFields: Array<IPreDefinidoContabFormulaTableBodyDefinitionGroupField>, newValue: number) => {
                  if (!newValue) {
                    model.valor.valorFormula = undefined;
                    model.valor.visibleValorFormula = '';
                  } else {
                    model.valor.tipoValor = EPreDefinidoContabTipoValorLinha.Formula;
                    model.valor.valorFormula = this._generateFormula('predefinidocontabformula.extraVariables.valorPorPagar', newValue);
                    model.valor.visibleValorFormula = model.valor.valorFormula;
                  }
                },
                reset: (model: IPreDefinidoContabLinha) => {
                  if (model) {
                    model.conta.contaCorrenteProperties.vaiImputarALinha = undefined;
                  }
                }
              }
            ],
            visible: (model: IPreDefinidoContabLinha) => {
              if (model) {
                return model.conta.tipoConta === 1;
              }
              return false;
            }
          }
        };
        break;
      case 'valor':
        groups = {
          base: {
            fields: [
              {name: 'colocaValorEFaturaDocDigital', type: 'boolean', caption: 'predefinidocontablinhas.fields.colocaValorEFaturaDocDigital'},
              {
                name: 'tipoValor',
                type: 'select',
                caption: 'predefinidocontablinhas.fields.tipoValor',
                properties: {
                  select: {
                    list: [
                      {value: 0, name: 'predefinidocontablinhas.enums.tipoValor.0'},
                      {value: 1, name: 'predefinidocontablinhas.enums.tipoValor.1'},
                      {value: 2, name: 'predefinidocontablinhas.enums.tipoValor.2'},
                      {value: 3, name: 'predefinidocontablinhas.enums.tipoValor.3'},
                      {value: 4, name: 'predefinidocontablinhas.enums.tipoValor.4'}
                    ]
                  }
                },
                onChange: (model: IPreDefinidoContabLinha) => {
                  const property = this.visualProperties.find((visualProperty) => visualProperty.name === 'readonly');
                  if (property) {
                    property.visible = model.valor.tipoValor === EPreDefinidoContabTipoValorLinha.Pergunta;
                    if (model.valor.tipoValor > EPreDefinidoContabTipoValorLinha.Pergunta) {
                      model.valor.visibilidadeProperties.readonly = true;
                    }
                  }
                }
              },
              {
                name: 'valorFixo',
                type: 'number',
                caption: 'predefinidocontabcab.fields.valorFixo',
                visible: (model: IPreDefinidoContabLinha) => {
                  if (model) {
                    return model.valor.tipoValor === EPreDefinidoContabTipoValorLinha.Fixo;
                  }
                  return false;
                }
              },
              {name: 'valorFormula', type: 'predefinidocontabformula', caption: 'predefinidocontablinhas.fields.valorFormula', visible: false},
              {
                name: 'visibleValorFormula',
                type: 'predefinidocontabformula',
                caption: 'predefinidocontablinhas.fields.valorFormula',
                properties: {formulaType: 'valor', disallowInput: true},
                visible: (model: IPreDefinidoContabLinha) => model.valor.tipoValor === EPreDefinidoContabTipoValorLinha.Formula,
                onChange: (model: IPreDefinidoContabLinha) => {
                  const item = this.tableBodyDefinition.find((definition) => definition.name === 'valor');
                  let value: string = model.valor.visibleValorFormula;
                  if (isDefined(item)) {
                    value = this.assignFormulaValue(item.originalHeader, item.header, value);
                  }
                  model.valor.valorFormula = value;
                }
              }
            ]
          },
          baseTributavelProperties: {
            caption: 'predefinidocontablinhas.titles.baseTributavelProperties',
            fields: [
              {name: 'importEFaturaContaUnicaSemDeducao', type: 'boolean', caption: 'predefinidocontablinhas.fields.importEFaturaContaUnicaSemDeducao'},
              {name: 'importEFaturaSuportaTaxaIsenta', type: 'boolean', caption: 'predefinidocontablinhas.fields.importEFaturaSuportaTaxaIsenta'},
              {name: 'importEFaturaValorImpSelo', type: 'boolean', caption: 'predefinidocontablinhas.fields.importEFaturaValorImpSelo'},
              {name: 'importFRVSuportaTaxaIsenta', type: 'boolean', caption: 'predefinidocontablinhas.fields.importFRVSuportaTaxaIsenta'},
              {name: 'importFRVValorImpSelo', type: 'boolean', caption: 'predefinidocontablinhas.fields.importFRVValorImpSelo'},
              {
                name: 'tipoValorBaseTributavel',
                type: 'radiogroup',
                caption: 'predefinidocontablinhas.fields.tipoValorBaseTributavel',
                properties: {
                  groupItems: [
                    {value: 0, label: 'predefinidocontablinhas.enums.tipoValorBaseTributavel.0'},
                    {value: 1, label: 'predefinidocontablinhas.enums.tipoValorBaseTributavel.1'},
                    {value: 2, label: 'predefinidocontablinhas.enums.tipoValorBaseTributavel.2'}
                  ]
                }
              }
            ],
            visible: (model) => model.conta.tipoConta === EPreDefinidoContabTipoConta.BaseTributavel
          }
        };
        break;
      case 'descricao':
        groups = {
          base: {
            fields: [
              {
                name: 'tipoValorDescricao',
                type: 'radiogroup',
                caption: 'predefinidocontabcab.fields.origemValor',
                properties: {
                  groupItems: [
                    {value: 0, label: 'predefinidocontabcab.enums.valorDescricao.0'},
                    {value: 1, label: 'predefinidocontabcab.enums.valorDescricao.1'},
                    {value: 2, label: 'predefinidocontabcab.enums.valorDescricao.2'}
                  ]
                }
              },
              {
                name: 'valorFixo',
                type: 'text',
                caption: 'predefinidocontabcab.fields.valorFixoDescricao',
                visible: (model) => model.descricao.tipoValorDescricao === EPreDefinidoContabTipoValorDescricao.Fixo
              },
              {
                name: 'valorFormula',
                type: 'predefinidocontabformula',
                caption: 'predefinidocontablinhas.fields.valorFormula',
                visible: false
              },
              {
                name: 'visibleValorFormula',
                type: 'predefinidocontabformula',
                caption: 'predefinidocontablinhas.fields.valorFormula',
                properties: {formulaType: 'descricao'},
                visible: (model: IPreDefinidoContabLinha) => model.descricao.tipoValorDescricao === EPreDefinidoContabTipoValorDescricao.Formula,
                onChange: (model: IPreDefinidoContabLinha) => {
                  const item = this.tableBodyDefinition.find((definition) => definition.name === 'descricao');
                  let value: string = model.descricao.visibleValorFormula;
                  if (isDefined(item)) {
                    value = this.assignFormulaValue(item.originalHeader, item.header, value);
                  }
                  model.descricao.valorFormula = value;
                }
              }
            ]
          }
        };
        break;
      case 'nDocExterno':
        groups = {
          base: {
            fields: [
              {name: 'incrementaAutomaticamente', type: 'boolean', caption: 'predefinidocontablinhas.fields.incrementaAutomaticamente'},
              {
                name: 'valorFixo',
                type: 'text',
                caption: 'predefinidocontabcab.fields.valorFixo',
                visible: (model) => !model.nDocExterno.incrementaAutomaticamente
              }
            ]
          }
        };
        break;
      case 'descritivo':
        groups = {
          base: {
            fields: [
              {
                name: 'tipoValorDescritivo',
                type: 'radiogroup',
                caption: 'predefinidocontabcab.fields.origemValor',
                properties: {
                  groupItems: [
                    {value: 0, label: 'predefinidocontablinhas.enums.tipoValorDescritivo.0'},
                    {value: 1, label: 'predefinidocontablinhas.enums.tipoValorDescritivo.1'},
                    {value: 2, label: 'predefinidocontablinhas.enums.tipoValorDescritivo.2'}
                  ]
                }
              },
              {
                name: 'valorFixo',
                type: 'descritivos',
                caption: 'predefinidocontabcab.fields.valorFixo',
                visible: (model) => model.descritivo.tipoValorDescritivo === EPreDefinidoContabTipoValorDescritivo.Fixo
              }
            ]
          }
        };
        break;
      case 'dataDoc':
        groups = {
          base: {
            fields: [
              {
                name: 'origem',
                type: 'radiogroup',
                caption: 'predefinidocontabcab.fields.origem',
                properties: {
                  groupItems: [
                    {value: EPreDefinidoContabOrigemDataDoc.NaoDefinido, label: 'predefinidocontabcab.enums.origemDataDoc.0'},
                    {value: EPreDefinidoContabOrigemDataDoc.DataDocEFatura, label: 'predefinidocontabcab.enums.origemDataDoc.1'}
                  ]
                }
              }
            ]
          }
        };
        break;
      case 'dataVencimento':
        groups = {
          base: {
            fields: [
              {
                name: 'origem',
                type: 'radiogroup',
                caption: 'predefinidocontabcab.fields.origem',
                properties: {
                  groupItems: [
                    {value: 0, label: 'predefinidocontabcab.enums.origemDataVencimento.0'},
                    {value: 1, label: 'predefinidocontabcab.enums.origemDataVencimento.1'}
                  ]
                }
              }
            ]
          }
        };
        break;
      default:
        break;
    }
    if (groups) {
      for (const groupName of Object.keys(groups)) {
        result.set(<keyof IPreDefinidoContabFormulaTableBodyDefinitionGroups>groupName, groups[groupName]);
      }
    }
    return result;
  }

  private _getEmptyLine(): IPreDefinidoContabLinha {
    return {
      ...this._contabilidadePredefinidoService.emptyPredefinidoLine(),
      _index: isArray(this.linhas) ? this.linhas.length : 0,
      _id: this._getNextLineId(),
      _selected: false
    };
  }

  private _addLine(line: IPreDefinidoContabLinha): void {
    this.linhas.push(line);
    if (this.linhas.length === 1) {
      if (!this.selectedLine) {
        this.selectLine(this.linhas[0]);
      }
      if (!this.selectedCell) {
        this.selectCell(this.tableBodyDefinition[0]);
      }
    }
    this._evaluateLinhasIndex();
    this._setIsValid(this._evaluateIsValid());
  }

  private _handleOrderBy(ordemColunas: Array<number> | ReadonlyArray<number>): void {
    function fnFindIndex(index: number): (definition: IPreDefinidoContabFormulaTableHeaderDefinitionLine | IPreDefinidoContabFormulaTableBodyDefinition) => boolean {
      return (definition: IPreDefinidoContabFormulaTableHeaderDefinitionLine | IPreDefinidoContabFormulaTableBodyDefinition) => definition.id === index;
    }

    if (isArray(ordemColunas)) {
      if (!isEqual(ordemColunas, CONTABILIDADE_PREDEFINIDOS_DEFAULT_ORDEM_COLUNAS)) {
        this.changedTableOrder = true;
      }

      // Header definition
      for (let i = 0; i < ordemColunas.length; i++) {
        const index: number = ordemColunas[i];
        const definitionIndex = this.tableHeaderDefinition[1].lines.findIndex(fnFindIndex(i));
        if (definitionIndex !== -1) {
          this.tableHeaderDefinition[1].lines[definitionIndex].index = index;
        }
      }
      this.tableHeaderDefinition[1].lines = orderBy(this.tableHeaderDefinition[1].lines, 'index');

      // Body definition
      for (let i = 0; i < ordemColunas.length; i++) {
        let index: number = ordemColunas[i];
        const definitionIndex = this.tableBodyDefinition.findIndex(fnFindIndex(i));
        if (definitionIndex !== -1) {
          const definition = this.tableBodyDefinition[definitionIndex];
          definition.index = index;
          if (index === 0) {
            index++;
          }
          const line = this.tableHeaderDefinition[0].lines[index];
          if (line) {
            definition.header = <TPreDefinidoContabHeader>line.text;
          }
        }
      }
      this.tableBodyDefinition = orderBy<IPreDefinidoContabFormulaTableBodyDefinition>(this.tableBodyDefinition, 'index');
    }

    // Set visibleValorFormula
    for (const linha of this.linhas) {
      for (const allowFormula of ALLOWS_FORMULA) {
        const definition = this.tableBodyDefinition.find((bodyDefinition: IPreDefinidoContabFormulaTableBodyDefinition) => bodyDefinition.name === allowFormula.name);
        if (isDefined(definition)) {
          linha[allowFormula.name][allowFormula.property] = this.assignFormulaValue(definition.header, definition.originalHeader, linha[allowFormula.name][allowFormula.propertyValue]);
        }
      }
    }
  }

  private _getNextLineId(): number {
    const id = this._lineId;
    this._lineId++;
    return id;
  }

  private _evaluateLinhasIndex(): void {
    for (let index = 0; index < this.linhas.length; index++) {
      this.linhas[index]._index = index;
    }
  }

  private _setTitleProperties(): void {
    let title = '';
    if (this.selectedLine && this.selectedCell) {
      const field = this._translateService.instant(`predefinidocontablinhas.fields.${this.selectedCell.name}`);
      const cell = this.selectedCell.header + String(this.selectedLine._index + 1);
      title = this._translateService.instant('predefinidocontablinhas.titles.fieldProperties', {field: field, cell: cell});
    }
    this.titleProperties = title;
  }

  private _getVaiImputarALinha(): Array<IPreDefinidoContabFormulaVaiImputarLinha> {
    if (!this.selectedLine) {
      return [];
    }
    const lines: Array<IPreDefinidoContabFormulaVaiImputarLinha> = [{value: undefined, name: 'global.text.notDefined'}];
    for (let i = 0; i < this.selectedLine._index; i++) {
      const line = this.linhas[i];
      if (line.conta.tipoConta === EPreDefinidoContabTipoConta.ContaCorrente) {
        lines.push({value: i + 1, name: String(i + 1)});
      }
    }
    return lines;
  }

  private _clickConta(): void {
    if (this.selectedCell) {
      setTimeout(() => {
        this.selectedCell.groups.get('contaCorrenteProperties').fields[1].properties = {select: {list: () => this._getVaiImputarALinha()}};
      });
    }
  }

  private _clickValor(): void {
    if (this.selectedCell) {
      setTimeout(() => {
        const property = this.visualProperties.find((visualProperty) => visualProperty.name === 'readonly');
        if (property) {
          property.visible = this.linhas[this.selectedLine._index].valor.tipoValor === EPreDefinidoContabTipoValorLinha.Pergunta;
        }
      });
    }
  }

  private _resetLine(line: object, model: IPreDefinidoContabLinha): void {
    if (isArray(line)) {
      for (const item of line) {
        this._resetLine(item, model);
      }
    } else if (isObject(line)) {
      const groupField: IPreDefinidoContabFormulaTableBodyDefinitionGroupField = <IPreDefinidoContabFormulaTableBodyDefinitionGroupField>line;
      if (isFunction(groupField.reset)) {
        groupField.reset(model, groupField);
      } else {
        for (const property in line) {
          if (Object.prototype.hasOwnProperty.call(line, property)) {
            this._resetLine(line[property], model);
          }
        }
      }
    }
  }

  /* TODO: reimplement drag/drop
  private _assignFormulaValues(newHeader: string, oldHeader: string): void {
    if (newHeader !== oldHeader) {
      for (const modelLine of this.linhas) {
        for (const item of ALLOWS_FORMULA) {
          if (modelLine[item.name][item.valueProperty] === item.value) {
            modelLine[item.name][item.property] = this.assignFormulaValue(newHeader, oldHeader, modelLine[item.name][item.property]);
          }
        }
      }
    }
  }
  */

  private _generateFormula(content: string, index: number): string {
    return `={${<string>this._translateService.instant(content)}${index}}`;
  }

  private _setSelectedLine(value: IPreDefinidoContabLinha): void {
    this.selectedLine = value;
  }

  private _setSelectedCell(value: IPreDefinidoContabFormulaTableBodyDefinition): void {
    this.selectedCell = value;
    this._setVisualProperties(this.selectedCell);
  }

  private _setVisualProperties(field: IPreDefinidoContabFormulaTableBodyDefinition): void {
    let properties: Array<IPreDefinidoContabFormulaVisualProperty>;
    if (!isObject(field) || isEmpty(field.visual)) {
      properties = copy(CONTABILIDADE_PREDEFINIDOS_DEFAULT_LINHA_VISUAL_PROPERTIES.slice());
    } else {
      properties = copy(
        field.visual
          .split(',')
          .map<IPreDefinidoContabFormulaVisualProperty>((visualPropertyKey: TPredefinidoVisibilidadeField) => {
            return CONTABILIDADE_PREDEFINIDOS_DEFAULT_LINHA_VISUAL_PROPERTIES.find((visual: IPreDefinidoContabFormulaVisualProperty) => {
              return visual.name === visualPropertyKey;
            });
          })
          .filter(isDefined)
      );
    }
    this.visualProperties = properties;
  }

  private _evaluateIsValid(): boolean {
    return this.linhas.length > 0;
  }

  private _setIsValid(value: boolean): void {
    this.valid = value;
    this.validChange.emit(this.valid);
  }

  private _setPocFilter(model: IPreDefinidoContabLinha, fields: Array<IPreDefinidoContabFormulaTableBodyDefinitionGroupField>): void {
    let filterToUse = `tipo=${ETipoContaContabilidade.Movimento}`;
    const radical = model.conta.radical;
    if (radical) {
      filterToUse += `&nConta=%${radical}%`;
    }
    switch (model.conta.tipoConta) {
      case EPreDefinidoContabTipoConta.ContaCorrente:
        filterToUse += '&cc=true';
        break;
      case EPreDefinidoContabTipoConta.BaseTributavel:
        filterToUse += '&temIVA=true';
        break;
      default:
        break;
    }
    fields[1].properties = {...fields[1].properties, filter: filterToUse};
  }

  private _keyDownContaFixa(value: string, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      const input: HTMLElement = this._element.querySelector('input[name="radical"]');
      if (input) {
        event.stopImmediatePropagation();
        focusElement(input);
      }
    }
  }

  private _keyDownRadical(value: string, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      const button: HTMLElement = document.querySelector('button.action-next-step');
      if (button) {
        event.stopImmediatePropagation();
        setTimeout(() => {
          button.focus();
        });
      }
    }
  }
}
