import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_PROC_SEGURADORA_FOLHA_FERIAS} from './procSeguradoraFolhaFerias.module.interface';
import {ProcSeguradoraFolhaFeriasModuleComponent} from './components/procSeguradoraFolhaFerias.module.component';

export const MODULE_PROC_SEGURADORA_FOLHA_FERIAS: IModuleDefinition = {
  name: MODULE_NAME_PROC_SEGURADORA_FOLHA_FERIAS,
  state: {
    url: `/${MODULE_NAME_PROC_SEGURADORA_FOLHA_FERIAS}`,
    component: ProcSeguradoraFolhaFeriasModuleComponent,
    data: {
      roles: [ROLE.RH],
      sidebarTitle: 'portals.sidebar.modules.procseguradorafolhaferias'
    }
  }
};
