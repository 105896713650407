import {merge} from 'lodash-es';
import type {Subscription} from 'rxjs';
import {Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl, ValidatorFn} from '@angular/forms';
import {cgcFilter} from '../../../pipes/filter';
import type {IEditMultiSelectEvent, IEditMultiSelectItemEvent, IEditMultiSelectRowTemplates, IPlEditMultiSelectCallback, IPlEditMultiSelectOptions} from './edit.multiselect.component.interface';
import {interpolate, isArray, isDefinedNotNull, isFunction, isNumber, isObject, isString} from '../../../common/utilities/utilities';
import type {IPlLocale, IPlLocaleMultiselect} from '../../../common/locale/locales.interface';
import {PlEditInputComponent} from '../../generic/input/edit.input.component';
import {PlLocaleService} from '../../../common/locale/locale.service';

@Component({
  selector: 'pl-multiselect',
  templateUrl: './edit.multiselect.component.html',
  standalone: false,
  exportAs: 'cgcEditMultiselect'
})
export class PlEditMultiSelectComponent extends PlEditInputComponent<Array<any>, IPlEditMultiSelectOptions> implements OnInit, OnChanges, OnDestroy {
  @Input() public source: Array<any>;
  @Input() public template: string;
  @Input() public key: string;
  @Input() public callback: IPlEditMultiSelectCallback;
  @Output() public readonly evtChanged: EventEmitter<IEditMultiSelectEvent<any>>;
  @Output() public readonly evtAllLeft: EventEmitter<IEditMultiSelectEvent<any>>;
  @Output() public readonly evtAllRight: EventEmitter<IEditMultiSelectEvent<any>>;
  @Output() public readonly evtSelectedLeft: EventEmitter<IEditMultiSelectItemEvent<any>>;
  @Output() public readonly evtSelectedRight: EventEmitter<IEditMultiSelectItemEvent<any>>;

  public readonly lefts: UntypedFormControl;
  public readonly rights: UntypedFormControl;
  public search: string;
  public filteredSource: Array<any>;
  public filteredValue: Array<any>;
  public leftSearch: string;
  public rightSearch: string;
  public leftCaption: string;
  public rightCaption: string;
  public leftPlaceholder: string;
  public rightPlaceholder: string;
  public rows: IEditMultiSelectRowTemplates;

  private readonly _subscriptionLocale: Subscription;
  private _locale: IPlLocaleMultiselect;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plLocaleService: PlLocaleService
  ) {
    super(_injector);
    this.lefts = new UntypedFormControl();
    this.rights = new UntypedFormControl();
    this._defaultOptions = Object.freeze<IPlEditMultiSelectOptions>(
      merge({}, this._defaultOptions, {
        leftCaption: '',
        rightCaption: ''
      })
    );
    this.evtChanged = new EventEmitter<IEditMultiSelectEvent<any>>();
    this.evtAllLeft = new EventEmitter<IEditMultiSelectEvent<any>>();
    this.evtAllRight = new EventEmitter<IEditMultiSelectEvent<any>>();
    this.evtSelectedLeft = new EventEmitter<IEditMultiSelectItemEvent<any>>();
    this.evtSelectedRight = new EventEmitter<IEditMultiSelectItemEvent<any>>();
    this.filteredSource = [];
    this.filteredValue = [];
    this.rows = {};
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.search = locale.btn.search;
      this._locale = locale.plMultiSelect;
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._handleSource();
    this._handleProperties();
    this.setValidators([this._validatorRequired(), this._validatorMinSelected(), this._validatorMaxSelected()]);
    if (isObject(this.callback)) {
      this.callback.allLeft = (force?: boolean) => {
        this.allLeft(force);
      };
      this.callback.allRight = (force?: boolean) => {
        this.allRight(force);
      };
    }
    if (this.value) {
      this._handleModel();
    }
    this.filteredValue = this.value || [];
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const {source} = changes;
    if (source && !source.isFirstChange()) {
      this._handleSource();
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionLocale.unsubscribe();
  }

  public updateComponent(properties: IPlEditMultiSelectOptions): void {
    super.updateComponent(properties);
    this._handleProperties();
  }

  public updateValue(value: Array<any>): void {
    super.updateValue(value);
    this._handleModel();
  }

  public getKey(item: any): string {
    return isObject(item) && this.key ? item[this.key] : item;
  }

  public selectedRight(item?: any, force: boolean = false): void {
    if (!force && this.options.disabled) {
      return;
    }
    let items = this.rights.value;
    if (item) {
      items = [item];
    }
    this._move(this.source, items, this.value);
    this._changedItems();
    this.evtChanged.emit({model: this.value, source: this.source});
    this.evtSelectedRight.emit({model: this.value, source: this.source, selected: items});
    if (this.options.events && isFunction(this.options.events.selectedRight)) {
      this.options.events.selectedRight(items, this.model, this.source);
    }
  }

  public allRight(force: boolean = false): void {
    if (!force && this.options.disabled) {
      return;
    }
    this._moveAll(this.source, this.value);
    this._changedItems();
    const event: IEditMultiSelectEvent<any> = {model: this.value, source: this.source};
    this.evtChanged.emit(event);
    this.evtAllRight.emit(event);
    if (this.options.events && isFunction(this.options.events.allRight)) {
      this.options.events.allRight(this.model, this.source);
    }
  }

  public selectedLeft(item?: any, force: boolean = false): void {
    if (!force && this.options.disabled) {
      return;
    }
    let items = this.lefts.value;
    if (item) {
      items = [item];
    }
    this._move(this.value, items, this.source);
    this._changedItems();
    this.evtChanged.emit({model: this.value, source: this.source});
    this.evtSelectedLeft.emit({model: this.value, source: this.source, selected: items});
    if (this.options.events && isFunction(this.options.events.selectedLeft)) {
      this.options.events.selectedLeft(items, this.model, this.source);
    }
  }

  public allLeft(force: boolean = false): void {
    if (!force && this.options.disabled) {
      return;
    }
    this._moveAll(this.value, this.source);
    this._changedItems();
    const event: IEditMultiSelectEvent<any> = {model: this.value, source: this.source};
    this.evtChanged.emit(event);
    this.evtAllLeft.emit(event);
    if (this.options.events && isFunction(this.options.events.allLeft)) {
      this.options.events.allLeft(this.model, this.source);
    }
  }

  public getRow(item: any): string {
    if (isString(this.template)) {
      if (this.template.includes('{{')) {
        return interpolate(this.template)(item);
      }
      return item[this.template];
    }
    return item;
  }

  public getLeftCaption(): string {
    return this._plTranslateService.translate(this.options.leftCaption) || this._locale.available;
  }

  public getRightCaption(): string {
    return this._plTranslateService.translate(this.options.rightCaption) || this._locale.selected;
  }

  public getLeftPlaceholder(): string {
    return `\uf002 ${this.search} ${this.leftCaption.toLowerCase()}`;
  }

  public getRightPlaceholder(): string {
    return `\uf002 ${this.search} ${this.rightCaption.toLowerCase()}`;
  }

  public filterLeft(value: string): void {
    this.leftSearch = value;
    this._changedSource();
  }

  public filterRight(value: string): void {
    this.rightSearch = value;
    this._changedModel();
  }

  private _handleProperties(): void {
    this.leftCaption = this.getLeftCaption();
    this.rightCaption = this.getRightCaption();
    this.leftPlaceholder = this.getLeftPlaceholder();
    this.rightPlaceholder = this.getRightPlaceholder();
    this.options.disabled = this.options.disabled || this.options.readonly;
    this.configureFormControl(this.lefts);
    this.configureFormControl(this.rights);
  }

  private _handleSource(): void {
    if (!this.source) {
      this.source = [];
    } else {
      for (let i = 0; i < this.source.length; i++) {
        const item = this.source[i];
        if (this._indexInModel(this.source[i]) !== -1) {
          this.source.splice(i, 1);
          i--;
        } else if (isObject(item)) {
          this.rows[item[this.key]] = this.getRow(item);
        } else {
          this.rows[i] = item;
        }
      }
    }
    this._changedSource();
  }

  private _handleModel(): void {
    if (isDefinedNotNull(this.model) && !isArray(this.model)) {
      this.model = [this.model];
    }
    this.value = isArray(this.model) ? this.model : [];
    for (let i = 0; i < this.value.length; i++) {
      const item = this.value[i];
      if (isObject(item)) {
        this.rows[item[this.key]] = this.getRow(item);
      } else {
        this.rows[i] = item;
      }
    }
    this._changedModel();
  }

  private _indexInModel(item: any): number {
    return this._indexInList(this.value, item);
  }

  private _indexInList(list: Array<any>, item: any): number {
    return this._find(list, this.getKey(item));
  }

  private _find(list: Array<any>, key: string): number {
    if (!isObject(key)) {
      key = String(key);
    }
    for (let i = 0; i < list.length; i++) {
      let item = this.getKey(list[i]);
      if (!isObject(item)) {
        item = String(item);
      }
      if (item === key) {
        return i;
      }
    }
    return -1;
  }

  private _moveAll(from: Array<any>, to: Array<any>): void {
    if (!isArray(from) || !from.length) {
      return;
    }
    const fromSize = from.length;
    for (let i = 0; i < fromSize; i++) {
      to.push(from.splice(0, 1)[0]);
    }
    this.render();
  }

  private _move(left: Array<any>, items: Array<any>, right: any, force: boolean = false): void {
    if (!left?.length || !items || (!force && this.options.disabled)) {
      return;
    }
    if (this.formControl.pristine || this.formControl.untouched) {
      this.formControl.markAsDirty();
      this.formControl.markAsTouched();
    }
    for (let key of items) {
      if (isObject(key)) {
        key = this.getKey(key);
      }
      const idx = this._find(left, key);
      if (idx !== -1) {
        const item = left[idx];
        right.push(item);
        left.splice(idx, 1);
      }
    }
    this.render();
  }

  private _changedItems(): void {
    this._changedSource();
    this._changedModel();
  }

  private _changedSource(): void {
    const sourceFilter = isString(this.leftSearch) ? this.leftSearch.trim() : undefined;
    if (sourceFilter || this.filteredSource.length !== this.source.length) {
      this.filteredSource = cgcFilter(this.source, sourceFilter) || [];
    } else {
      this.filteredSource = (this.source || []).slice();
    }
  }

  private _changedModel(): void {
    const modelFilter = isString(this.rightSearch) ? this.rightSearch.trim() : undefined;
    if (modelFilter || this.filteredValue.length !== this.value.length) {
      this.filteredValue = cgcFilter(this.value, modelFilter) || [];
    } else {
      this.filteredValue = (this.value || []).slice();
    }
    this.runValidators();
  }

  private _validatorRequired(): ValidatorFn {
    return () => {
      if (this.validate && this.options.validators.required?.value) {
        return !this.value.length ? {required: true} : undefined;
      }
      return undefined;
    };
  }

  private _validatorMinSelected(): ValidatorFn {
    return () => {
      if (this.validate && isNumber(this.options.validators.minselected?.value)) {
        return this.value.length < this.options.validators.minselected.value ? {minselected: true} : undefined;
      }
      return undefined;
    };
  }

  private _validatorMaxSelected(): ValidatorFn {
    return () => {
      if (this.validate && isNumber(this.options.validators.maxselected?.value)) {
        return this.value.length > this.options.validators.maxselected.value ? {maxselected: true} : undefined;
      }
      return undefined;
    };
  }
}
