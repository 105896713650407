<div class="tree-viewer" [ngSwitch]="!!tree.columns">
  <!-- Regular nodes -->
  <ul class="list-inline tree-viewer-nodes" *ngSwitchCase="false">
    <li *ngFor="let node of tree.nodes">
      <tree-viewer-node
        [treeItem]="node"
        [selectedItem]="model"
        [disabled]="disabled"
        [collapseOnSelect]="collapseOnSelect"
        [selectOnCollapse]="selectOnCollapse"
        (evtSelectedItem)="changedSelectedItem($event)"
        (evtDoubleClickedItem)="evtDoubleClickedItem.emit($event)">
      </tree-viewer-node>
    </li>
  </ul>

  <!-- Columns -->
  <ng-container *ngSwitchCase="true">
    <div class="tree-viewer-column" *ngFor="let column of tree.columns">
      {{ column.nodeHeader }}
      <ul class="tree-viewer-nodes">
        <li *ngFor="let node of column.nodes">
          <tree-viewer-node
            [treeItem]="node"
            [selectedItem]="model"
            [disabled]="disabled"
            [collapseOnSelect]="collapseOnSelect"
            [selectOnCollapse]="selectOnCollapse"
            (evtSelectedItem)="changedSelectedItem($event)"
            (evtDoubleClickedItem)="evtDoubleClickedItem.emit($event)">
          </tree-viewer-node>
        </li>
      </ul>
    </div>
  </ng-container>
</div>
