import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {TServiceQueryResponse} from '../../../services/api/api.service.interface';
import {IJsonAnaliseMargemLucro, IJsonAnaliseMargemLucroDetail} from './jsonAnalisemargemlucro.module.interface';
import {TDate} from '../../../../common/dates';
import {firstValueFrom} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';

@Injectable({
  providedIn: 'root'
})
export class AnaliseMargemLucroService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/analisemargemlucro`;
  }

  public get(
    modo: number,
    dataDe: TDate,
    dateAte: TDate,
    estado: number,
    codigo: string = '',
    search: string = '',
    order: string = '',
    page: number = -1,
    perPage: number = -1,
    searchFields: string = ''
  ): TServiceQueryResponse<IJsonAnaliseMargemLucro> {
    return this._apiService.query<IJsonAnaliseMargemLucro>({
      url: this._path,
      params: {
        modo: modo,
        codigo: codigo,
        datade: dataDe,
        dataate: dateAte,
        estado: estado,
        pagina: page,
        porpagina: perPage,
        pesquisa: search,
        ordena: order,
        campospesq: searchFields,
        force: true
      }
    });
  }

  public getDetalhe(modo: number, codigo: string, dataDe: TDate, dataAte: TDate): TServiceQueryResponse<IJsonAnaliseMargemLucroDetail> {
    return this._apiService.query<IJsonAnaliseMargemLucroDetail>({
      url: `${this._path}/detalhe`,
      params: {
        modo: modo,
        codigo: codigo,
        datade: dataDe,
        dataate: dataAte,
        force: true
      }
    });
  }

  public getPdfUrl(reportName: string, modo: number, dataDe: TDate, dateAte: TDate, estado: number, codigo: string = '', detalhe: boolean = false): Promise<string> {
    return firstValueFrom(
      buildSessionUrlWithParams(`${this._path}/pdf`, {
        reportname: reportName,
        detalhe: detalhe,
        modo: modo,
        codigo: codigo,
        datade: dataDe,
        dataate: dateAte,
        estado: estado,
        force: true
      })
    );
  }
}
