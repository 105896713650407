import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IJsonCodPostalHelper} from '../interfaces/jsonCodPostalHelper.interface';
import {ApiService} from './api/api.service';
import {API_CENTRALGEST_HELPER} from '../../config/constants';

@Injectable({
  providedIn: 'root'
})
export class ExternalCodPostaisService {
  constructor(private readonly _apiService: ApiService) {}

  public get(codPostal: string): Promise<Array<IJsonCodPostalHelper>> {
    return this._apiService
      .get<Array<IJsonCodPostalHelper>>({url: `${API_CENTRALGEST_HELPER}/postal/${codPostal}`, withCredentials: false})
      .then((response: HttpResponse<Array<IJsonCodPostalHelper>>) => response.body);
  }
}
