import {Component, Injector, Input, OnInit} from '@angular/core';
import {EDebDiretosSEPAEstadoItem, IDebDiretosSEPAAcessos, IJsonDebDiretosSEPAConfiguracao, IJsonDebDiretosSEPAMeiosPagamento} from '../debDiretosSEPA.module.interface';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {isDefinedNotNull, isUndefinedOrNull, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {IJsonMeioPagamento} from '../../../entities/meiospagamento/jsonMeioPagamento.entity.interface';
import {DebDiretosSEPAModuleService} from '../debDiretosSEPA.module.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ICGExceptionError} from '../../../components/exceptions/exceptions.service.interface';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {ENTITY_NAME_MEIOS_PAGAMENTO} from '../../../entities/meiospagamento/meiosPagamento.entity.interface';
import {IEntityService} from '../../../services/entity/entity.service.interface';

@Component({
  selector: 'ddsepa-conta-credora-modal',
  templateUrl: './debDiretosSEPA.contaCredora.modal.component.html'
})
export class DebDiretosSEPAContaCredoraModalComponent extends CGModalComponent<IJsonDebDiretosSEPAMeiosPagamento> implements OnInit {
  @Input() public model: IJsonDebDiretosSEPAMeiosPagamento;
  @Input() public userAcessos: IDebDiretosSEPAAcessos;

  public readonly meiosPagamentoOutput: string = '{{nCaixa}} - {{nome}}';
  public readonly pocsOutput: string = '{{nConta}} - {{nome}}';

  public isEditMode: boolean;
  public showCodMeioPagamentoMessage: boolean;
  public inCustomMeioPagamEdition: boolean;
  public inCustomIbanEdition: boolean;
  public meioPagamentoEditMessage: string;
  public iBanEditMessage: string;
  public forceDisabledNContaBanco: boolean;
  public iBanDiffMeioPagamento: boolean;

  private readonly _entityMeiosPagamento: IEntityService<IJsonMeioPagamento>;

  private _originalModel: IJsonDebDiretosSEPAMeiosPagamento;
  private _jsonMeioPagamento: IJsonMeioPagamento;
  private _config: IJsonDebDiretosSEPAConfiguracao;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _plTranslateService: PlTranslateService,
    protected readonly _debDiretosSEPAModuleService: DebDiretosSEPAModuleService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.isEditMode = false;
    this.showCodMeioPagamentoMessage = false;
    this.inCustomMeioPagamEdition = false;
    this.inCustomIbanEdition = false;
    this.meioPagamentoEditMessage = '';
    this.iBanEditMessage = '';
    this.forceDisabledNContaBanco = false;
    this.iBanDiffMeioPagamento = false;
    this._entityMeiosPagamento = this._entityServiceBuilder.build(ENTITY_NAME_MEIOS_PAGAMENTO);
  }

  public ngOnInit(): void {
    if (isDefinedNotNull(this.model)) {
      this.isEditMode = true;
      this.meioPagamentoEditMessage = this._plTranslateService.translate('debDiretosSEPA.messages.alteracaoMeioPagamentoMsg', {
        codMeioPagamento: this.model.codMeioPagamento,
        nomeMeioPagamento: this.model.nomeMeioPagamento
      });
      this.iBanEditMessage = this._plTranslateService.translate('debDiretosSEPA.messages.alteracaoIbanMsg', {
        codMeioPagamento: this.model.codMeioPagamento,
        nomeMeioPagamento: this.model.nomeMeioPagamento,
        iban: this.model.iBAN
      });
      this._entityMeiosPagamento.get({id: this.model.codMeioPagamento}).then((response) => {
        this._jsonMeioPagamento = response.body;
        this._checkIBanDiffMeioPagamentoButtonVisibility();
      });
    } else {
      this.model = {
        codMeioPagamento: undefined,
        estado: EDebDiretosSEPAEstadoItem.New,
        estadoSTR: '',
        iBAN: '',
        iBANMasked: '',
        idDebitoDireto: '',
        nContaBanco: '',
        nDescritivo: 0,
        nDiario: 0,
        nomeDescritivo: '',
        nomeDiario: '',
        nomeMeioPagamento: '',
        nomePOC: ''
      };
    }

    this._debDiretosSEPAModuleService.getConfiguracaoContaCredora().then((response: HttpResponse<IJsonDebDiretosSEPAConfiguracao>) => {
      this._config = response.body;

      if (isDefinedNotNull(this._config) && (!this.userAcessos.acessoContabilidade || !this.isEditMode)) {
        this.model = {
          ...this.model,
          nDescritivo: this._config.defaults.nDescritivo,
          nDiario: this._config.defaults.nDiario,
          nomeDescritivo: this._config.defaults.nomeDescritivo,
          nomeDiario: this._config.defaults.nomeDiario
        };
      }

      this._originalModel = {...this.model};
    });
  }

  public readonly fnSaveCustomMeioPagamento = (): Promise<void> => this._saveCustomMeioPagamento();
  public readonly fnSaveCustomIban = (): Promise<void> => this._saveCustomIban();

  public codMeioPagamentoChange(item: IJsonMeioPagamento): void {
    this._jsonMeioPagamento = item;
    this.model = {...this.model, iBAN: item.nib, nContaBanco: item.nConta, nomePOC: item.nomeConta};
    this.forceDisabledNContaBanco = this.model.nContaBanco?.length > 0;
    this.showCodMeioPagamentoMessage = isDefinedNotNull(this._config.listaContasCredoras.find((itemExistente) => itemExistente.codMeioPagamento === item.nCaixa));
  }

  public async closeModal(): Promise<void> {
    if (isUndefinedOrNull(this.model.codMeioPagamento)) {
      this._plAlertService.error('debDiretosSEPA.messages.codMeioPagamentoObrigatorio');
      return;
    }

    if (this.isEditMode) {
      this.model.estado = EDebDiretosSEPAEstadoItem.Changed;
    }

    if (!this.model.idDebitoDireto.length || isUndefinedOrNull(this.model.nDiario) || this.model.nDiario === 0 || isUndefinedOrNull(this.model.nDescritivo) || this.model.nDescritivo === 0) {
      await this._cgModalService.showOkCancel('global.text.confirmation', 'debDiretosSEPA.strings.haCamposNaoPreenchidos', {
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no'
      });
    }

    this.close(this.model);
  }

  public editMeioPagamento(): void {
    this.inCustomMeioPagamEdition = true;
    this.inCustomIbanEdition = false;
  }

  public editIban(): void {
    this.inCustomIbanEdition = true;
    this.inCustomMeioPagamEdition = false;
  }

  public updateIbanFromMeioPagamento(): void {
    this.model.iBAN = this._jsonMeioPagamento.nib;
    this.editIban();
    this._checkIBanDiffMeioPagamentoButtonVisibility();
  }

  public cancelCustomEdition(): void {
    if (this.inCustomIbanEdition || this.inCustomMeioPagamEdition) {
      this.model = {...this._originalModel};
      this.inCustomIbanEdition = false;
      this.inCustomMeioPagamEdition = false;
      this._checkIBanDiffMeioPagamentoButtonVisibility();
    }
  }

  public getBanDiffMeioPagamentoTooltip(): string {
    return this._plTranslateService.translate('debDiretosSEPA.tooltips.ibanDiffDoMeioPagamento', {
      iban: this.model.iBAN,
      codMeioPagamento: this.model.codMeioPagamento,
      nomeMeioPagamento: this.model.nomeMeioPagamento,
      ibanMeioPagamento: this._jsonMeioPagamento.nib
    });
  }

  private _saveCustomMeioPagamento(): Promise<void> {
    return new Promise((resolve, reject) => {
      const msg = this._plTranslateService.translate('debDiretosSEPA.messages.clienteComDDAlterados', {
        countClientes: this._config.listaClientes.filter((i) => i.codMeioPagamento === this.model.codMeioPagamento).length,
        iban: this._originalModel.iBAN,
        newIban: this.model.iBAN
      });
      this._cgModalService
        .showOkCancel('global.text.confirmation', msg, {
          size: 'md',
          backdrop: 'static',
          showCloseBtn: false,
          keyboard: false
        })
        .then(() => {
          this._debDiretosSEPAModuleService
            .changeCodMeioPagamento(this._originalModel.codMeioPagamento, this.model.codMeioPagamento)
            .then(() => {
              this._originalModel = {...this.model};
              this.cancelCustomEdition();
              resolve();
            })
            .catch((reason: HttpErrorResponse) => {
              this._logger.error(reason);
              if (isDefinedNotNull(reason)) {
                const exception: ICGExceptionError = this._cgExceptionService.get(reason);
                this._plAlertService.error(exception.message);
              }
              reject();
            });
        })
        .catch(reject);
    });
  }

  private _saveCustomIban(): Promise<void> {
    return new Promise((resolve, reject) => {
      const msg = this._plTranslateService.translate('debDiretosSEPA.messages.clienteComDDAlterados', {
        countClientes: this._config.listaClientes.filter((i) => i.codMeioPagamento === this.model.codMeioPagamento).length,
        iban: this._originalModel.iBAN,
        newIban: this.model.iBAN
      });
      this._cgModalService
        .showOkCancel('global.text.confirmation', msg, {
          size: 'md',
          backdrop: 'static',
          showCloseBtn: false,
          keyboard: false
        })
        .then(() => {
          this._debDiretosSEPAModuleService
            .changeIbanMeioPagamento(this.model.codMeioPagamento, this.model.iBAN)
            .then(() => {
              this._originalModel.iBAN = this.model.iBAN;
              this.cancelCustomEdition();
              resolve();
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  private _checkIBanDiffMeioPagamentoButtonVisibility(): void {
    this.iBanDiffMeioPagamento = this.model.iBAN !== this._jsonMeioPagamento.nib;
  }
}
