import {Component, Injector} from '@angular/core';
import type {IPlEditComponentOptionsInputSwitch} from '../switch/edit.switch.component.interface';
import {PlEditInputsGroupComponent} from '../../generic/inputgroup/edit.inputs.group.component';

@Component({
  selector: 'pl-edit-switch-group',
  templateUrl: './edit.switch.group.component.html',
  standalone: false,
  exportAs: 'cgcEditSwitchGroup'
})
export class PlEditSwitchGroupComponent extends PlEditInputsGroupComponent<any, IPlEditComponentOptionsInputSwitch> {
  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }
}
