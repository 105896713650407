import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {ImpCtrIrsModuleComponent} from './components/impCtrIrs.module.component';
import {MODULE_NAME_IMPCTRIRS} from './impCtrIrs.module.interface';

export const MODULE_IMPCTRIRS: IModuleDefinition = {
  name: MODULE_NAME_IMPCTRIRS,
  state: {
    url: `/${MODULE_NAME_IMPCTRIRS}`,
    component: ImpCtrIrsModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.impctrirs'
    }
  }
};
