<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'configEfaturaDocPorLanc.global.title'"></h4>
</div>

<div class="modal-body">
  <config-docs-efatura-global #configDocsEFaturaGlobal></config-docs-efatura-global>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary" [onClick]="close"><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
  <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
