import {NgModule} from '@angular/core';
import {PlRecaptchaComponent} from './recaptcha.v2.component';

export * from './recaptcha.v2.component';

const DECLARATIONS = [
  PlRecaptchaComponent
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlRecaptchaV2Module {
}
