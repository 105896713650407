<div class="complementosexcepcoes-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rhcessacontratotrabalho.modal.complementosexcepcoes.title'"></h5>
  </div>

  <div class="modal-body">
    <h5 class="mb-2" [translate]="'rhcessacontratotrabalho.modal.complementosexcepcoes.indiqueexcluidos'"></h5>
    <div class="complementosexcepcoes-wrapper">
      <div class="complementosexcepcoes-table text-nowrap table-responsive">
        <div>
          <h6><span [translate]="'rhcessacontratotrabalho.modal.complementosexcepcoes.possiveis'"></span>:</h6>
        </div>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          [dataSource]="dataGridDefinition.dataSource"
          [filterValue]="['status', '=', 'possivel']"
          (onInitialized)="onInitialized($event, true)"
          (onContentReady)="onContentReady(true)"
          (onCellClick)="onCellClick($event)"
          (onCellDblClick)="onCellDblClick($event)">
          <dxo-row-dragging group="statusGroup" data="possivel" [onAdd]="fnOnAddExclusaoTable"></dxo-row-dragging>
        </dx-data-grid>
      </div>

      <div class="complementosexcepcoes-btn-actions">
        <button type="button" class="btn btn-primary btn-sm" [disabled]="emptyPossiveisTable" (click)="allRight()">
          <i class="fa fa-fw fa-angle-double-right"></i>
        </button>

        <button type="button" class="btn btn-primary btn-sm" [disabled]="!selectedLinePossivel" (click)="selectedRight()">
          <i class="fa fa-fw fa-angle-right"></i>
        </button>

        <button type="button" class="btn btn-primary btn-sm" [disabled]="!selectedLineExcluido" (click)="selectedLeft()">
          <i class="fa fa-fw fa-angle-left"></i>
        </button>

        <button type="button" class="btn btn-primary btn-sm" [disabled]="emptyExcluidosTable" (click)="allLeft()">
          <i class="fa fa-fw fa-angle-double-left"></i>
        </button>
      </div>

      <div class="complementosexcepcoes-table text-nowrap table-responsive">
        <div>
          <h6><span [translate]="'rhcessacontratotrabalho.modal.complementosexcepcoes.excluidos'"></span>:</h6>
        </div>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          [dataSource]="dataGridDefinition.dataSource"
          [filterValue]="['status', '=', 'excluido']"
          (onInitialized)="onInitialized($event, false)"
          (onContentReady)="onContentReady(false)"
          (onCellClick)="onCellClick($event)"
          (onCellDblClick)="onCellDblClick($event)">
          <dxo-row-dragging group="statusGroup" data="excluido" [onAdd]="fnOnAddExclusaoTable"></dxo-row-dragging>
        </dx-data-grid>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-save" (evtClicked)="close()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

    <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
