<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'integracaoSalarios.configModalTitle'"></h5>
  </div>

  <div class="modal-body">
    <pl-tabs>
      <pl-tab caption="integracaoSalarios.configModalTipoIntegTabCaption" [id]="tipoIntegTabId">
        <div *plTabContent>
          <pl-group>
            <label [translate]="'integracaoSalarios.configModalTipoIntegRadioGroupLabel'"></label>
            <edit>
              <pl-edit
                type="radiogroup"
                attrName="tipo"
                [(model)]="configModel.tipoDeIntegracao"
                [properties]="{groupItems: tiposIntegracaoSource, disabled: showNDocExt, validators: {required: {value: true}}}">
              </pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'integracaoSalarios.diarioLabel'"></label>
            <edit>
              <entity-autocomplete entity="diarios" [model]="configModel" [fieldsMap]="{nDiario: 'nDiario', nome: 'nDiarioDesc'}" [(selectedKey)]="configModel.nDiario"></entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'integracaoSalarios.descritivoLabel'"></label>
            <edit>
              <entity-autocomplete
                entity="descritivos"
                [model]="configModel"
                [fieldsMap]="{nDescrit: 'nDescritivo', nome: 'nDescritivoDesc'}"
                [(selectedKey)]="configModel.nDescritivo"></entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group *ngIf="showNDocExt">
            <label [translate]="'integracaoSalarios.nDocExtLabel'"></label>
            <edit>
              <pl-edit type="text" [(model)]="configModel.nDocExterno"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'integracaoSalarios.descricaoLabel'"></label>
            <edit>
              <pl-edit type="text" [(model)]="configModel.descricao"></pl-edit>
            </edit>
          </pl-group>
        </div>
      </pl-tab>

      <pl-tab *ngIf="configModel.tipoDeIntegracao === tiposDeIntegracao.PorProcessamento" caption="integracaoSalarios.configModalConfigAcertosSegSocTabCaption" [id]="configAcertosSegSocTabId">
        <div *plTabContent>
          <h4 class="mainTitle" [translate]="'integracaoSalarios.contasContrapartidaAcertoSSTitle'"></h4>

          <pl-group>
            <label [translate]="'integracaoSalarios.contaGastoAcertoLabel'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                [model]="configModel"
                [fieldsMap]="{nConta: 'nContaSegSocialSaldoInsuficiente', nome: 'nContaSegSocialSaldoInsuficienteDesc'}"
                [(selectedKey)]="configModel.nContaSegSocialSaldoInsuficiente"></entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'integracaoSalarios.contaRendimentoAcertoLabel'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                [model]="configModel"
                [fieldsMap]="{nConta: 'nContaSegSocialSaldoExcedente', nome: 'nContaSegSocialSaldoExcedenteDesc'}"
                [(selectedKey)]="configModel.nContaSegSocialSaldoExcedente"></entity-autocomplete>
            </edit>
          </pl-group>
        </div>
      </pl-tab>

      <pl-tab caption="rhintegracaosalarios.configApoiosCOVID19" [id]="configApoiosCOVID19">
        <div *plTabContent>
          <fieldset class="mb-3">
            <legend [translate]="'rhintegracaosalarios.configApoiosCOVID19Fields.card.apoioexcepcionalfamilia'"></legend>

            <pl-group>
              <label [translate]="'rhintegracaosalarios.configApoiosCOVID19Fields.nContaCovid19ApoioFamilia'"></label>
              <edit>
                <entity-autocomplete
                  entity="pocs"
                  [model]="configModel"
                  [fieldsMap]="{nConta: 'nContaCovid19ApoioFamilia', nome: 'nContaCovid19ApoioFamiliaDesc'}"
                  [(selectedKey)]="configModel.nContaCovid19ApoioFamilia">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'rhintegracaosalarios.configApoiosCOVID19Fields.nContaCovid19ApoioFamiliaPerc'"></label>
                <edit>
                  <pl-edit type="cgnumber" [(model)]="configModel.nContaCovid19ApoioFamiliaPerc"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label>&nbsp;</label>
                <edit>
                  <pl-edit></pl-edit>
                </edit>
              </pl-group>
            </pl-group>
          </fieldset>

          <fieldset class="mb-3">
            <legend [translate]="'rhintegracaosalarios.configApoiosCOVID19Fields.card.apoioLayoffSimplificadoPSuspensaoTrabalho'"></legend>

            <pl-group>
              <label [translate]="'rhintegracaosalarios.configApoiosCOVID19Fields.nContaCovid19LayoffSimplSuspencao'"></label>
              <edit>
                <entity-autocomplete
                  entity="pocs"
                  [model]="configModel"
                  [fieldsMap]="{nConta: 'nContaCovid19LayoffSimplSuspencao', nome: 'nContaCovid19LayoffSimplSuspencaoDesc'}"
                  [(selectedKey)]="configModel.nContaCovid19LayoffSimplSuspencao">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'rhintegracaosalarios.configApoiosCOVID19Fields.nContaCovid19LayoffSimplSuspencaoPerc'"></label>
                <edit>
                  <pl-edit type="cgnumber" [(model)]="configModel.nContaCovid19LayoffSimplSuspencaoPerc"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label>&nbsp;</label>
                <edit>
                  <pl-edit></pl-edit>
                </edit>
              </pl-group>
            </pl-group>
          </fieldset>

          <fieldset>
            <legend [translate]="'rhintegracaosalarios.configApoiosCOVID19Fields.card.apoioLayoffSimplificadoPReducaoTrabalho'"></legend>

            <pl-group>
              <label [translate]="'rhintegracaosalarios.configApoiosCOVID19Fields.nContaCovid19LayoffSimplParcial'"></label>
              <edit>
                <entity-autocomplete
                  entity="pocs"
                  [model]="configModel"
                  [fieldsMap]="{nConta: 'nContaCovid19LayoffSimplParcial', nome: 'nContaCovid19LayoffSimplParcialDesc'}"
                  [(selectedKey)]="configModel.nContaCovid19LayoffSimplParcial"
                  output="key">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'rhintegracaosalarios.configApoiosCOVID19Fields.nContaCovid19LayoffSimplParcialPerc'"></label>
                <edit>
                  <pl-edit type="cgnumber" [(model)]="configModel.nContaCovid19LayoffSimplParcialPerc"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>
          </fieldset>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-success" [disabled]="closeDisabled" [onClick]="close" data-focus><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="okBtnCaption"></span></pl-button>

    <pl-button klass="btn btn-light" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-close"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</pl-form>
