import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlToolbarItem, IPlToolbarMenuItem, isFunction, isNumber, isObject} from 'pl-comps-angular';
import {IContabDigitalDocViewerRecolhaCallback} from '../../../../../components/arquivodigital/contabilidade/docviewerrecolha/contabilidadedigital.docviewer.recolha.component.interface';
import {IContabDigitalGDocViewerRecolhaDoc} from '../../../../../services/contabilidadedigital/contabilidadedigital.interface';
import {IDevExpressDataGridColumn} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDocsContabilidadeEntityService} from '../../docsContabilidade.interface';
import {TEntityListBeforeRequestFn} from '../../../../../components/entity/list/entity.list.component.interface';
import {IJsonDocContabilidade, IJsonDocContabilidadeCab} from '../../jsonDocContabilidade.interface';
import {IJsonEmpresaAno} from '../../../../../interfaces/jsonEmpresa.interface';
import {IModuleEntityListOnDataGridCellClick} from '../../../../../components/module/entitylist/module.entitylist.interface';
import {ModuloEntityListComponent} from '../../../../../components/module/entitylist/module.entitylist.component';
import moment from 'moment';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnContentReady} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IEntityServiceQueryRequestConfig} from '../../../../../services/entity/entity.service.interface';
import {IArquivoDigitalDocViewerEvtChangedAttachment} from '../../../../../components/arquivodigital/common/docviewer/arquivodigital.docviewer.component.interface';

export interface IDocContabilidadeCab extends IJsonDocContabilidadeCab {
  _thedoc: IJsonDocContabilidade;
  _docdigital: IContabDigitalGDocViewerRecolhaDoc;
}

@Component({
  selector: 'docs-contabilidade-list',
  templateUrl: './docsContabilidade.list.component.html'
})
export class DocsContabilidadeListComponent extends ModuloEntityListComponent<IJsonDocContabilidadeCab, IDocsContabilidadeEntityService> implements OnInit {
  @Input() public anosEmpresa: Array<IJsonEmpresaAno>;

  public readonly callbackDocViewer: IContabDigitalDocViewerRecolhaCallback;
  public selectedYear: number;
  public contabilidadeDigitalReadonly: boolean;

  private readonly _mnuAnos: IPlToolbarItem<number>;
  private _anoEmCursoIRC: number;
  private _temContabilidadeDigital: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.callbackDocViewer = {};
    this.contabilidadeDigitalReadonly = false;
    this._mnuAnos = {order: 101, id: 'docsContabilidadeListMnuAnos', type: 'dropdown', menu: []};
    this._temContabilidadeDigital = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnNovo.caption = 'docscontabilidade.text.recolha';
    const currentYear: number = moment().year();
    let addedCurrentYear = false;
    const anos: Array<IPlToolbarMenuItem<number>> = [];
    for (const anoEmpresa of this.anosEmpresa) {
      if (anoEmpresa.ano === currentYear) {
        addedCurrentYear = true;
      }
      anos.push({
        caption: String(anoEmpresa.ano),
        data: anoEmpresa.ano,
        click: (menuItem: IPlToolbarMenuItem<number>) => {
          this._anosChanged(menuItem);
        }
      });
    }
    if (!addedCurrentYear) {
      anos.unshift({
        caption: String(currentYear),
        data: currentYear,
        click: (menuItem: IPlToolbarMenuItem<number>) => {
          this._anosChanged(menuItem);
        }
      });
    }
    this._mnuAnos.menu = anos;
    this.toolbar.addButton(this._mnuAnos);
  }

  public onDetail(event: IModuleEntityListOnDataGridCellClick<IDocContabilidadeCab>): Promise<void> {
    const {data} = event;
    return this.service
      .get({
        id: data.extPocCabID,
        params: {
          ano: this.selectedYear,
          contabilidadeDigital: this._temContabilidadeDigital
        }
      })
      .then((response: HttpResponse<IJsonDocContabilidade>) => {
        data._thedoc = response.body;
        data._docdigital = this._temContabilidadeDigital
          ? {
              extPocCabID: data._thedoc.extPocCabID,
              periodo: data._thedoc.periodo,
              nDiario: data._thedoc.nDiario,
              nDocInterno: data._thedoc.nDocInterno,
              dataDoc: data._thedoc.dataDoc
            }
          : undefined;
        return super.onDetail(event);
      });
  }

  public onDataGridColumnsPreparing(fields: Array<IDevExpressDataGridColumn>): void {
    const fieldTemDocDigital: IDevExpressDataGridColumn = fields.find((field: IDevExpressDataGridColumn) => field.dataField === 'temDocDigital');
    if (fieldTemDocDigital) {
      fieldTemDocDigital.visible = this._temContabilidadeDigital;
      fieldTemDocDigital.showInColumnChooser = this._temContabilidadeDigital;
    }
  }

  public changedDocAttachment(item: IDocContabilidadeCab, attachment: IArquivoDigitalDocViewerEvtChangedAttachment): void {
    item.temDocDigital = isObject(attachment) && isObject(attachment.attachment);
  }

  public onResizerValuesChanged(): void {
    if (isObject(this.callbackDocViewer.pdf) && isFunction(this.callbackDocViewer.pdf.updateSize)) {
      setTimeout(this.callbackDocViewer.pdf.updateSize);
    }
  }

  public onDataGridOnContentReady(event: IDevExpressDataGridEventOnContentReady<IJsonDocContabilidadeCab>): void {
    event.component.endCustomLoading();
  }

  public onChangedLoading(component: dxDataGrid, loading: boolean): void {
    if (loading) {
      component.beginCustomLoading(undefined);
    } else {
      component.endCustomLoading();
    }
  }

  public readonly fnBeforeRequest: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequest(queryRequestConfig);

  public readonly fnOnActionEdited: () => Promise<void> = () => this._onActionEdited();

  public readonly fnOnActionDeleted: () => Promise<void> = () => this._onActionDeleted();

  public readonly fnOnActionAnulouDiferimentos: () => Promise<void> = () => this._onActionAnulouDiferimentos();

  protected _onConfigurationsChanged(): void {
    const firstTime = !isNumber(this._anoEmCursoIRC);
    this._anoEmCursoIRC = this.configurations.empresa.anoEmCursoIRC;
    this._temContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
    if (firstTime) {
      this._setAnoEmCurso(this.configurations.empresa.anoEmCursoIRC);
      this._setAnoCaption();
    } else if (this.entityListComponent?.dataGridInstance) {
      this.entityListComponent.dataGridInstance.columnOption('temDocDigital', 'visible', this._temContabilidadeDigital);
      this.entityListComponent.dataGridInstance.columnOption('temDocDigital', 'showInColumnChooser', this._temContabilidadeDigital);
    }
  }

  private _anosChanged(menuItem: IPlToolbarMenuItem<number>): void {
    if (menuItem.data !== this.selectedYear) {
      this._setAnoEmCurso(menuItem.data);
      this._setAnoCaption();
      this.refreshList();
    }
  }

  private _setAnoEmCurso(value: number): void {
    this.selectedYear = value;
    this.contabilidadeDigitalReadonly = this.selectedYear < this._anoEmCursoIRC;
  }

  private _setAnoCaption(): void {
    this._mnuAnos.caption = this._translateService.instant('toolbar.year', {value: String(this.selectedYear)});
  }

  private _beforeRequest(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    if (this.selectedYear) {
      if (!queryRequestConfig.pesquisa) {
        queryRequestConfig.pesquisa = '';
      } else {
        queryRequestConfig.pesquisa += '&';
      }
      queryRequestConfig.pesquisa += `ano=${this.selectedYear}`;
    }
    return queryRequestConfig;
  }

  private _onActionEdited(): Promise<void> {
    if (!this.maintenanceMode) {
      return Promise.resolve();
    }
    return this.refreshList();
  }

  private _onActionDeleted(): Promise<void> {
    return this.refreshList();
  }

  private _onActionAnulouDiferimentos(): Promise<void> {
    return this.refreshList();
  }
}
