import {ConfigsSiteModuleComponent} from './components/configsSite.module.component';
import {
  CONFIG_SITE_DATABASE_VERSION,
  CONFIG_SITE_MAIL_SMTP_PASSWORD,
  CONFIG_SITE_MAIL_SMTP_USE_SSL,
  EConfigSiteEditType,
  EConfigSiteGroup,
  ENTITY_NAME_CONFIGS_SITE,
  IConfigSiteValue,
  MODULE_NAME_CONFIGS_SITE
} from './configsSite.module.interface';
import {EMPTY_PASSWORD} from '../../../../config/constants';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IEntityDefinition} from '../../../components/entity/entity.definition.interface';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IJsonConfigValue} from '../../../interfaces/jsonConfigValue.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';

export const MODULE_CONFIGS_SITE: IModuleDefinition<IJsonConfigValue> = {
  name: MODULE_NAME_CONFIGS_SITE,
  state: {
    url: `/${MODULE_NAME_CONFIGS_SITE}`,
    component: ConfigsSiteModuleComponent,
    data: {
      pageTitle: 'configsSite.title_plural',
      roles: [ROLE.ADMIN]
    },
    resolve: [
      {
        provide: 'configsEmail',
        deps: [EntityServiceBuilder],
        useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IConfigSiteValue>> => {
          const serviceConfigsSite: IEntityService<IJsonConfigValue> = entityServiceBuilder.build<IJsonConfigValue>(ENTITY_NAME_CONFIGS_SITE);
          return serviceConfigsSite.query().then((response: THttpQueryResponse<IJsonConfigValue>) =>
            response.body.list.map<IConfigSiteValue>((item: IJsonConfigValue) => {
              const configId: string = item.configId.toLowerCase();
              return {
                configId: item.configId,
                value: configId === CONFIG_SITE_MAIL_SMTP_PASSWORD ? EMPTY_PASSWORD : item.value,
                readonly: configId === CONFIG_SITE_DATABASE_VERSION,
                group: configId.startsWith('mail.smtp') ? EConfigSiteGroup.Email : EConfigSiteGroup.Geral,
                type: configId === CONFIG_SITE_MAIL_SMTP_PASSWORD ? EConfigSiteEditType.Password : configId === CONFIG_SITE_MAIL_SMTP_USE_SSL ? EConfigSiteEditType.Boolean : EConfigSiteEditType.Text
              };
            })
          );
        }
      }
    ]
  }
};

export const ENTITY_CONFIGS_SITE: IEntityDefinition<IJsonConfigValue> = {
  name: ENTITY_NAME_CONFIGS_SITE,
  url: 'configs/site',
  asModule: false,
  icon: 'fa-cog',
  searchPlaceholder: 'pesquisar',
  metadata: {
    keyName: 'configId',
    fields: [
      {name: 'configId', type: 'string', caption: 'configsSite.fields.configId'},
      {name: 'value', type: 'string', caption: 'configsSite.fields.value'}
    ],
    order: 'configId',
    searchFields: 'configId,value'
  },
  autocomplete: {
    rowTemplate: '{{configId}} - {{value}}',
    output: 'value',
    searchFields: 'configId,value'
  }
};
