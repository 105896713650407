<div class="entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'clifos.avisodadosrepetidosmodal.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <h5 [translate]="'clifos.avisodadosrepetidosmodal.bodyMessage'"></h5>

      <dx-data-grid
        [cgDxDataGrid]="dataGridDefinition"
        cgDxDataGridInstanceName="clifosDadosRepetidos"
        [dataSource]="dataGridDefinition.dataSource"
        (onInitialized)="onInitialized($event)"
        (onContentReady)="onContentReady()">
      </dx-data-grid>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-save" (evtClicked)="close()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

    <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
