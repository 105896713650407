<pl-form [properties]="{validators: {required: {value: true}}}">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'moeda.editCambio'" [translateParams]="{date: getDateValue(myModel.dataReferencia)}"></h4>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <pl-group>
          <label [translate]="'moeda.fields.dataReferencia'"></label>
          <edit>
            <pl-edit-datepicker [disabledDates]="disabledDates" [(model)]="myModel.dataReferencia"></pl-edit-datepicker>
          </edit>
        </pl-group>

        <div class="card mt-2">
          <div class="card-header">
            <div [innerHTML]="'moeda.titles.cambioMoedaEmpresaEmMoedaEstrangeira' | cgcTranslate"></div>
          </div>
          <div class="card-body">
            <pl-group>
              <label [translate]="'moeda.fields.nDecimais'"></label>
              <edit>
                <pl-edit type="integer" class="sm-edit" [(model)]="myModel.nDecimaisCambio1"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'moeda.fields.exchange'"></label>
              <edit>
                <pl-edit type="number" class="md-edit" [(model)]="myModel.cambioMoedEmpEmEstr" [properties]="{decimalsLimit: myModel.nDecimaisCambio1}"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>

        <div class="card mt-2">
          <div class="card-header">
            <div [innerHTML]="'moeda.titles.cambioMoedaEstrangeiraEmMoedaEmpresa' | cgcTranslate"></div>
          </div>
          <div class="card-body">
            <pl-group>
              <label [translate]="'moeda.fields.nDecimais'"></label>
              <edit>
                <pl-edit type="integer" class="sm-edit" [(model)]="myModel.nDecimaisCambio2"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'moeda.fields.exchange'"></label>
              <edit>
                <pl-edit type="number" class="md-edit" [(model)]="myModel.cambioMoedEstrEmEmp" [properties]="{decimalsLimit: myModel.nDecimaisCambio2}"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success btn-sm" (evtClicked)="close(myModel)" focus><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.apply'"></span></pl-button>

    <pl-button klass="btn-light btn-sm" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</pl-form>
