import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {Observable} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {IJsonImpFFERPOC} from './jsonImpFFERPOC.module.interface';
import {TDate} from '../../../../common/dates';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ImpFFERPOCService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/impfferpoc`;
  }

  public getListagemImpFFERPOCUrl(filters: IJsonImpFFERPOC): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report`, {
      dataprocessamento: filters.dataProcessamento,
      reportname: filters.reportName
    });
  }

  public getDatasProcessamentos(): TServiceResponse<Array<TDate>> {
    return this._apiService.get<Array<TDate>>({url: `${this._path}/datasprocessamentos`});
  }
}
