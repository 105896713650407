import {Component, Injector, Input} from '@angular/core';
import {IJsonCARGR} from '../jsonCargVeic.entity.interface';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {isDefinedNotNull, PlAlertService, PlTranslateService} from 'pl-comps-angular';

@Component({
  selector: 'modal-cargveic-reboques',
  templateUrl: './cargVeic.reboque.modal.component.html'
})
export class CargVeicReboqueModalComponent extends CGModalComponent<IJsonCARGR> {
  @Input() public reboquesList: Array<IJsonCARGR>;

  public model: IJsonCARGR;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this.model = {
      matriculaReboque: '',
      pesoTara: 0,
      isDeleted: false
    };
  }

  public close(): void {
    const found = this.reboquesList.find((item) => item.matriculaReboque === this.model.matriculaReboque);
    if (isDefinedNotNull(found)) {
      this._plAlertService.error(this._plTranslateService.translate('cargveic.reboqueJaExiste', {matricula: this.model.matriculaReboque}));
      return;
    }
    super.close(this.model);
  }
}
