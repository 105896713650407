<!-- eslint-disable @angular-eslint/template/no-call-expression -->
<div class="pl-upload">
  <div
    class="pl-upload-dropzone"
    tabindex="0"
    [class.pl-upload-dropzone-clickable]="clickable"
    [class.pl-upload-dropzone-drag-over]="draggingOver"
    (click)="clickedDropZone()"
    (keyup.enter)="clickedDropZone()"
    (keyup.space)="clickedDropZone()">
    <div class="pl-upload-dropzone-empty" [innerHTML]="textMessage"></div>
    <div class="pl-upload-dropzone-footer">
      <div *ngIf="textValidators" class="pl-upload-dropzone-footer-validators" [innerHTML]="textValidators"></div>
    </div>
  </div>

  <div *ngIf="uploadFiles.length" class="pl-upload-files-wrapper">
    <div class="pl-upload-files-header">{{ locale.uploader.files }} ({{ uploadFiles.length }})</div>

    <div *ngIf="!hideGlobalActions" class="pl-upload-files-global-actions">
      <div *ngIf="uploadFiles.length > 1" class="pl-upload-file-action">
        <button *ngIf="!hideActionUploadAll" type="button" class="btn btn-link" plPromise [disabled]="!queuedFiles" [click]="fnUploadAll">
          <i class="fa fa-upload" aria-hidden="true"></i>&nbsp;<span>{{ locale.uploader.uploadAll }}</span>
        </button>
      </div>

      <div *ngIf="uploadFiles.length > 1" class="pl-upload-file-action">
        <button *ngIf="!hideActionRemoveAll" type="button" class="btn btn-link" (click)="removeAllFiles()">
          <i class="fa fa-trash fa-upload" aria-hidden="true"></i>&nbsp;<span>{{ locale.uploader.removeAllFiles }}</span>
        </button>
      </div>
    </div>

    <div class="pl-upload-files">
      <div *ngFor="let uploadFile of uploadFiles" class="pl-upload-file" [class.pl-upload-file-accepted]="uploadFile.accepted" [class.pl-upload-file-rejected]="uploadFile.rejected">
        <div *ngIf="uploadFile.status === enumStatus.Added && !uploadFile.file" class="pl-upload-file-loading">
          <div class="circle-spinner">
            <div class="spinner"></div>
          </div>
        </div>

        <div *ngIf="uploadFile.thumbnail && uploadFile.thumbnail.preview" class="pl-upload-file-thumbnail">
          <img [src]="uploadFile.thumbnail.preview" alt="{{ uploadFile.file.name }}" />
        </div>

        <div class="pl-upload-file-data">
          <div class="pl-upload-file-info">
            <div class="pl-upload-file-info-title">{{ uploadFile.captionName }}</div>
            <div class="pl-upload-file-info-size" [innerHTML]="uploadFile.captionSize"></div>
          </div>

          <div class="pl-upload-file-progress" [ngSwitch]="uploadFile.status">
            <div *ngSwitchCase="enumStatus.Uploading" class="pl-upload-file-progress-uploader">
              <pl-progress [value]="uploadFile.upload.progress"></pl-progress>
            </div>

            <div *ngSwitchCase="enumStatus.Success" class="pl-upload-file-progress-success">
              <span class="text-success">{{ locale.uploader.uploadSuccess }}</span>
            </div>

            <div *ngSwitchCase="enumStatus.Error" class="pl-upload-file-progress-error" [ngSwitch]="uploadFile.accepted">
              <div *ngSwitchCase="true">
                <span class="text-danger">{{ locale.uploader.uploadError }}</span
                >:&nbsp;<span class="progress-message" [innerHTML]="uploadFile.statusMessage"></span>
              </div>
              <div *ngSwitchCase="false">
                <span class="text-danger">{{ locale.uploader.rejected }}</span
                >:&nbsp;<span class="progress-message" [innerHTML]="uploadFile.statusMessage"></span>
              </div>
            </div>
          </div>

          <div *ngIf="!hideActions" class="pl-upload-file-actions">
            <div *ngIf="!hideActionUpload && uploadFile.accepted && uploadFile.status === enumStatus.Queued" class="pl-upload-file-action hyperlink pl-upload-file-action-upload">
              <button type="button" class="btn btn-link" plPromise [click]="fnProcessFiles(uploadFile)">
                <i class="fa fa-upload" aria-hidden="true"></i>&nbsp;<span>{{ locale.uploader.upload }}</span>
              </button>
            </div>

            <div *ngIf="!hideActionRetry && uploadFile.accepted && uploadFile.status === enumStatus.Error" class="pl-upload-file-action pl-upload-file-action-retry">
              <button type="button" class="btn btn-link" plPromise [click]="fnProcessFiles(uploadFile)">
                <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;<span>{{ locale.uploader.retry }}</span>
              </button>
            </div>

            <div *ngIf="!hideActionCancel && uploadFile.status === enumStatus.Uploading && !!uploadFile.subscription" class="pl-upload-file-action pl-upload-file-action-cancel">
              <button type="button" class="btn btn-link" plPromise [click]="fnCancelFileUpload(uploadFile)">
                <i class="fa fa-ban" aria-hidden="true"></i>&nbsp;<span>{{ locale.uploader.cancelUpload }}</span>
              </button>
            </div>

            <div *ngIf="!hideActionRemove && uploadFile.file && uploadFile.status !== enumStatus.Uploading" class="pl-upload-file-action pl-upload-file-action-remove">
              <button type="button" class="btn btn-link" (click)="removeFile(uploadFile)">
                <i class="fa fa-trash" aria-hidden="true"></i>&nbsp;<span>{{ locale.uploader.removeFile }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
