import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonQIVAA} from './jsonQIVAA.entity.interface';
import {ENTITY_NAME_QIVAAS} from './qivaas.entity.interface';

export const ENTITY_QIVAAS: IEntityDefinition<IJsonQIVAA> = {
  name: ENTITY_NAME_QIVAAS,
  roles: [ROLE.CONTABILIDADE, ROLE.RH],
  searchPlaceholder: 'qivaas.pesquisa',
  sidebarTitle: 'portals.sidebar.modules.qivaas',
  metadata: {
    keyName: 'nCampo',
    fields: [
      {name: 'nCampo', width: '120px', caption: 'qivaas.fields.nCampo', placeholder: 'qivaas.fields.nCampoPlaceholder', validators: {required: true, maxlength: 4}},
      {name: 'nome', caption: 'qivaas.fields.nome', placeholder: 'qivaas.fields.nomePlaceholder', validators: {required: true, maxlength: 50}},
      {name: 'nQuadro', caption: 'qivaas.fields.nQuadro', placeholder: 'qivaas.fields.nQuadroPlaceholder', validators: {required: true, maxlength: 4}}
    ],
    order: 'nCampo',
    searchFields: 'nCampo,nome,nQuadro',
    listFields: 'nCampo,nome,nQuadro'
  },
  autocomplete: {
    rowTemplate: '{{nCampo}} - {{nQuadro}} - {{nome}}',
    output: 'nome',
    searchFields: 'nCampo,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP, ROLE.CONTABILIDADE],
        pageTitle: 'global.menu.qivaas'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP, ROLE.CONTABILIDADE],
        pageTitle: 'global.menu.qivaas'
      }
    }
  }
};
