import type {Observable} from 'rxjs';
import type {IPipelineHandler} from '../pipeline';

export interface IFileListTransformer {
  transform(file: Array<File>, next: FileListPipelineHandler, properties?: any): Observable<Array<File>>;
}

export abstract class FileListPipelineHandler implements IPipelineHandler<Array<File>, Array<File>> {
  public abstract handle(value: Array<File>, properties?: any): Observable<Array<File>>;
}
