import {take} from 'rxjs/operators';
import {ProviderLike} from '@uirouter/core';
import {ConfigService} from './config.service';
import {ICGConfigurations} from './config.service.interface';
import {TUserSession} from '../account/jsonUserApi.interface';
import {firstValueFrom} from 'rxjs';
import {EStoreMode} from '../../../common/enums/licenca.enums';

export function resolverConfigurationsFactory(session: TUserSession, configService: ConfigService): Promise<ICGConfigurations> {
  if (!session) {
    return Promise.reject(new Error('Session not initialized'));
  }
  return configService.getConfigurations(false, true);
}

export const RESOLVER_CONFIGURATIONS: ProviderLike = {
  provide: 'configurations',
  deps: ['session', ConfigService],
  useFactory: resolverConfigurationsFactory
};

export const RESOLVER_LICENCA_STORE_MODE: ProviderLike = {
  provide: 'licencaStoreMode',
  deps: [ConfigService],
  useFactory: async (configService: ConfigService): Promise<EStoreMode> => {
    const configurations: ICGConfigurations = await firstValueFrom(configService.configurationsAsObservable().pipe(take(1)));
    return configurations.licenca.storeMode;
  }
};

export const RESOLVER_LICENCA_STORE_MODE_PUBLIC: ProviderLike = {
  provide: 'licencaStoreModePublic',
  deps: [ConfigService],
  useFactory: async (configService: ConfigService): Promise<boolean> => {
    const configurations: ICGConfigurations = await firstValueFrom(configService.configurationsAsObservable().pipe(take(1)));
    return configurations.licenca.storeModePublic;
  }
};

export const RESOLVER_EMPRESA_ANO_EM_CURSO_IRC: ProviderLike = {
  provide: 'empresaAnoEmCursoIRC',
  deps: [ConfigService],
  useFactory: async (configService: ConfigService): Promise<number> => {
    const configurations: ICGConfigurations = await firstValueFrom(configService.configurationsAsObservable().pipe(take(1)));
    return configurations.empresa.anoEmCursoIRC;
  }
};
