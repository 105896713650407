import {ConfigsErpComercialComponent} from './components/configs.comercial.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_CONFIGS_COMERCIAL} from './configs.comercial.module.interface';
import {ROLE} from '../../../../services/role.const';

export const MODULE_CONFIGS_COMERCIAL: IModuleDefinition = {
  name: MODULE_NAME_CONFIGS_COMERCIAL,
  state: {
    url: '/configs/erp/comercial',
    component: ConfigsErpComercialComponent,
    data: {
      roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
      pageTitle: 'configsErp.items.documentosComerciaisTitle'
    }
  }
};
