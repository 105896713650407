<div class="modal-body">
  <img alt="Multibanco" class="d-block docs-fiscais-mb-logo mb-3" src="../../../assets/images/logo-mb-full.webp" />

  <div [plPromise]="promise">
    <div class="d-flex">
      <label style="width: 80px">Entidade:</label>
      <span>{{ model.entidade }}</span>
    </div>

    <div class="d-flex">
      <label style="width: 80px">Referência:</label>
      <span>{{ model.referencia }}</span>
    </div>

    <div class="d-flex">
      <label style="width: 80px">Valor:</label>
      <span>{{ model.valor }}</span>
    </div>

    <div class="d-flex">
      <label style="width: 80px">Data:</label>
      <span>{{ model.data }}</span>
    </div>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light btn-sm" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
