import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../cgmodal.component';
import {isBoolean, isEmpty, TNgClassSupportedTypes} from 'pl-comps-angular';
import {TCGOkCancelModalIcon, TCGOkCancelModalType} from '../cgmodal.interface';

const OK_ICONS: ReadonlyMap<TCGOkCancelModalType, string> = new Map<TCGOkCancelModalType, string>([
  ['prompt', 'fa-check'],
  ['success', 'fa-check'],
  ['danger', 'fa-trash-o'],
  ['delete', 'fa-trash-o']
]);

const OK_CSS_CLASSES: ReadonlyMap<TCGOkCancelModalType, string> = new Map<TCGOkCancelModalType, string>([
  ['prompt', 'btn-primary'],
  ['success', 'btn-success'],
  ['danger', 'btn-danger'],
  ['delete', 'btn-danger']
]);

const CANCEL_ICONS: ReadonlyMap<TCGOkCancelModalType, string> = new Map<TCGOkCancelModalType, string>([
  ['prompt', 'fa-times'],
  ['success', 'fa-times'],
  ['danger', 'fa-times'],
  ['delete', 'fa-times']
]);

const CANCEL_CSS_CLASSES: ReadonlyMap<TCGOkCancelModalType, string> = new Map<TCGOkCancelModalType, string>([
  ['prompt', 'btn-light'],
  ['success', 'btn-light'],
  ['danger', 'btn-light'],
  ['delete', 'btn-light']
]);

@Component({
  selector: 'ok-cancel-modal',
  templateUrl: './okcancel.modal.component.html'
})
export class CGModalOkCancelComponent extends CGModalComponent<void> implements OnInit {
  @Input() public title: string;
  @Input() public message: string;
  @Input() public type: TCGOkCancelModalType;
  @Input() public icon: TCGOkCancelModalIcon;
  @Input() public showCloseBtn: boolean;
  @Input() public showOkBtn: boolean;
  @Input() public btnOkText: string;
  @Input() public btnOkIcon: string;
  @Input() public btnOkCssClass: TNgClassSupportedTypes;
  @Input() public showCancelBtn: boolean;
  @Input() public btnCancelText: string;
  @Input() public btnCancelIcon: string;
  @Input() public btnCancelCssClass: TNgClassSupportedTypes;

  public iconClass: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnInit(): void {
    if (isEmpty(this.title)) {
      this.title = 'global.text.confirmation';
    }
    if (isEmpty(this.type)) {
      this.type = 'prompt';
    }
    if (!isEmpty(this.icon)) {
      switch (this.icon) {
        case 'info':
          this.iconClass = 'fa-info-circle text-info';
          break;
        case 'warning':
          this.iconClass = 'fa-exclamation-triangle text-warning';
          break;
        case 'error':
          this.iconClass = 'fa-times-circle text-error';
          break;
      }
    }
    if (!isBoolean(this.showCloseBtn)) {
      this.showCloseBtn = true;
    }
    if (!isBoolean(this.showOkBtn)) {
      this.showOkBtn = true;
    }
    if (isEmpty(this.btnOkText)) {
      this.btnOkText = 'global.btn.ok';
    }
    if (isEmpty(this.btnOkIcon)) {
      this.btnOkIcon = OK_ICONS.get(this.type);
    }
    if (isEmpty(this.btnOkCssClass)) {
      this.btnOkCssClass = OK_CSS_CLASSES.get(this.type);
    }
    if (!isBoolean(this.showCancelBtn)) {
      this.showCancelBtn = true;
    }
    if (isEmpty(this.btnCancelText)) {
      this.btnCancelText = 'global.btn.cancel';
    }
    if (isEmpty(this.btnCancelIcon)) {
      this.btnCancelIcon = CANCEL_ICONS.get(this.type);
    }
    if (isEmpty(this.btnCancelCssClass)) {
      this.btnCancelCssClass = CANCEL_CSS_CLASSES.get(this.type);
    }
  }
}
