import {Component, Injector, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {isArray, isEmpty, isNumber, isObject, isString, isUndefined, isUndefinedOrNull, PlAlertService, PlCompsService, PlI18nService, toJson} from 'pl-comps-angular';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {CGLocalStorageGroupService} from '../../../../services/storage/localstoragegroup.service';
import {EGroupName, FORM_INVALID_CANNOT_SUBMIT} from '../../../../../config/constants';
import {ICGConfigurations} from '../../../../services/config/config.service.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {PagamentosService} from '../../service/pagamentos.entity.service';
import {
  EPagamentoTipoDistribuicao,
  IPagamento,
  IPagamentoDistribuirValor,
  IPagamentoLinha,
  IPagamentosParams,
  NDECIMAIS_CAMBIO_PAGAMENTOS_DEFAULT,
  pagamentoDefault,
  schemaIJsonPagamento
} from '../../pagamentos.entity.interface';
import {IPagamentosSaveModalResult} from '../../modals/savemodal/pagamentos.save.modal.component.interface';
import {TServiceResponse} from '../../../../services/api/api.service.interface';
import {TrfBaService} from '../../../../modules/trfba/trfBa.module.service';
import {IReciboLinha} from '../../../recibos/recibos.entity.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonDocComercial} from '../../../docscomerciais/jsonDocComercial.entity.interface';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {IDocsComerciaisEntityService} from '../../../docscomerciais/docsComerciais.entity.interface';
import {DocumentosService} from '../../../docscomerciais/service/documentos.entity.service';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnEditorPreparing,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSaved
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Properties as NumberBoxOptions} from 'devextreme/ui/number_box';
import {SCHEMA_STRING} from '../../../../../common/schemas';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import moment, {Moment, MomentInput} from 'moment';
import {IJsonClifo} from '../../../clifos/jsonClifo.entity.interface';
import {round} from '../../../../../common/utils/utils';

const STORAGE_KEY = 'pagamento';
const FIVE_DECIMAL_FORMATOR = '#,##0.00000';
const TWO_DECIMAL_FORMATOR = '#,##0.00';
const DOCUMENTO_CONTABILIDADE = 'Documento contabilidade';

@Component({
  selector: 'pagamentos-edit',
  templateUrl: './pagamentos.entity.edit.component.html'
})
export class PagamentosEditComponent extends ModuloEntityDetailComponent<IPagamento> implements OnInit {
  public readonly config: ICGConfigurations;
  public readonly tipoDistribuicao: typeof EPagamentoTipoDistribuicao;

  public dataGridDefinition: IDevExpressDataGrid<IReciboLinha>;
  public form: UntypedFormGroup;
  public isloading: boolean;
  public extPocCabID: string;
  public codMoedaEmpresa: number;
  public abreviaturaMoedaEmpresa: string;
  public abreviaturaMoedaLinha: string;
  public isME: boolean;
  public invalidHeaderDate: boolean;
  public fornecedorOutput: string;
  public modelClifo: Partial<IJsonClifo>;
  public totalNaoVencido: number;
  public totalVencido: number;
  public modelPagamentoDistribuir: IPagamentoDistribuirValor;
  public valorDistribuir: number;

  private readonly _pagamentosParams: IPagamentosParams;
  private readonly _docsComerciaisService: IDocsComerciaisEntityService;

  private _savedmodel: IPagamento;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _pagamentosService: PagamentosService,
    private readonly _cgLocalStorageGroupService: CGLocalStorageGroupService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _plCompsService: PlCompsService,
    private readonly _plI18nService: PlI18nService,
    private readonly _plAlertService: PlAlertService,
    private readonly _trfBaService: TrfBaService,
    private readonly _documentosService: DocumentosService
  ) {
    super(_injector);
    this.config = this._configService.configurations;
    this.isME = false;
    this.fornecedorOutput = '{{nConta}} - {{nome}}';
    this._docsComerciaisService = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>('docscomerciais');
    this._pagamentosParams = <IPagamentosParams>this._transition.params();
    this.tipoDistribuicao = EPagamentoTipoDistribuicao;
    this.valorDistribuir = 0;
    this.totalNaoVencido = 0;
    this.totalVencido = 0;
    this.modelPagamentoDistribuir = {
      valor: 0,
      tipoDistribuicao: EPagamentoTipoDistribuicao.Automatico
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.model = {
      ...pagamentoDefault(),
      ...this.model
    };
    if (this._pagamentosParams.nConta.length) {
      this.model.cab.nConta = this._pagamentosParams.nConta;
      this.model.cab.nome = this._pagamentosParams.nome;
    } else {
      // Código de load
      this._cgLocalStorageGroupService.getItem<IPagamento>(`${this.session.erp.nEmpresa}.pagamento`, schemaIJsonPagamento, EGroupName.GLOBAL).subscribe((value: IPagamento) => {
        if (!isEmpty(value)) {
          this._savedmodel = value;
          if (isObject(this._savedmodel) && this._savedmodel.cab.nConta !== '') {
            this.model.cab.nConta = this._savedmodel.cab.nConta;
            this.model.cab.nome = this._savedmodel.cab.nome;
            this.changedNConta({nConta: this.model.cab.nConta, nome: this.model.cab.nome});
          }
        }
      });
    }
    if (this.model.cab.nConta) {
      this.changedNConta({nConta: this.model.cab.nConta, nome: this.model.cab.nome});
    }
  }

  public async save(): Promise<IPagamento> {
    if (this.invalidHeaderDate) {
      return Promise.reject();
    }
    await this._dataGridInstance.saveEditData();
    if (!this.validaDados()) {
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    const promise = (async () => {
      const {type, pagamento}: IPagamentosSaveModalResult = await this._pagamentosService.saveModal(this.model, this.callback.save, [], this.abreviaturaMoedaLinha);
      this._cgLocalStorageGroupService.removeItem(STORAGE_KEY, EGroupName.ERP);
      this.extPocCabID = pagamento.cab.extPocCabID;
      if (type === 'pdf') {
        this._pagamentosService.getPdf({
          extPocCabID: pagamento.cab.extPocCabID,
          nConta: pagamento.cab.nConta,
          nomeConta: pagamento.cab.nome,
          email: pagamento.cab.email,
          trfbaId: undefined
        });
      }
      if (type === 'saveCreateSepa') {
        this._trfBaService.getTransfenciaBancariaSEPA(pagamento.cab.extPocCabID).then((response) => {
          this._pagamentosService.getPdf({
            extPocCabID: pagamento.cab.extPocCabID,
            nConta: pagamento.cab.nConta,
            nomeConta: pagamento.cab.nome,
            email: pagamento.cab.email,
            trfbaId: response.body.trfBaID
          });
        });
      }
      this.callback.new();
      this.loadLinhas();
      return pagamento;
    })();
    this._subjectOnSave.next(promise);
    return promise;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IPagamentoLinha>): void {
    if (event.rowType === 'data' && event.column.command === 'expand') {
      if (event.row.isExpanded) {
        event.component.collapseAll(-1);
      } else {
        event.component.collapseAll(-1);
        if (!isObject(event.row)) {
          return;
        }
        if (isNumber(event.data.faccbid) && event.data.faccbid !== 0) {
          devExpressDataGridExpandDetailHandler(event, () => this._onDetail(event.data));
        }
      }
    }
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IReciboLinha>): void {
    if (event.rowType === 'data') {
      if (event.column.command === 'expand' && event.data.tipoDocumento === DOCUMENTO_CONTABILIDADE) {
        (<HTMLElement>event.cellElement.childNodes[0]).classList.remove('dx-datagrid-group-closed');
        event.cellElement.classList.remove('dx-datagrid-expand');
      }

      if (event.column.dataField === 'cambioPagamento' || event.column.dataField === 'percDesconto' || event.column.dataField === 'valorDesconto' || event.column.dataField === 'valorAPagar') {
        if (
          (event.column.dataField === 'cambioPagamento' && (event?.row?.data?.valor < 0 || event?.row?.data?.cambioOrigem === 1)) ||
          ((event.column.dataField === 'percDesconto' || event.column.dataField === 'valorDesconto') && event?.row?.data?.valor < 0)
        ) {
          event.cellElement.classList.add('table-not-editable-cell');
        } else {
          event.cellElement.classList.add('datagrid-editable-cell');
        }
      }
    }
  }

  public onEditorPreparing(event: IDevExpressDataGridEventOnEditorPreparing<IPagamentoLinha>): void {
    if (event.dataField === 'cambioPagamento' && (event?.row?.data?.valor < 0 || event?.row?.data?.cambioOrigem === 1)) {
      event.cancel = true;
    }

    if ((event.dataField === 'percDesconto' || event.dataField === 'valorDesconto') && event?.row?.data?.valor < 0) {
      event.cancel = true;
    }
  }

  public onSaved(event: IDevExpressDataGridEventOnSaved<IPagamentoLinha>): void {
    if (event.changes[0]) {
      const dataItem: IPagamentoLinha = <IPagamentoLinha>event.changes[0].data;
      const index = this.model.linhas.findIndex((value: IPagamentoLinha) => value.nlanc === dataItem.nlanc);
      if (index !== -1) {
        this.cambioPagamentoChanged(dataItem, index);
      }
      this.percentagemDescontoChanged(dataItem, false);
      this.valorDescontoChanged(dataItem, false);
      this.valorAPagarChanged(dataItem, true, false);
    }
  }

  public cambioPagamentoChanged(linha: IPagamentoLinha, idx: number): void {
    if (isUndefinedOrNull(linha.cambioPagamento)) {
      for (let i = 0; i < this.model.linhas.length; i++) {
        const item = this.model.linhas[i];
        if (item.codMoeda === linha.codMoeda && idx !== i && item.cambioPagamento !== item.cambioOrigem) {
          item.cambioPagamento = item.cambioOrigem;
        }
      }
      return;
    }

    for (let i = 0; i < this.model.linhas.length; i++) {
      const item = this.model.linhas[i];
      if (item.codMoeda === linha.codMoeda && idx !== i) {
        item.cambioPagamento = linha.cambioPagamento;
      }
    }
  }

  public percentagemDescontoChanged(linha: IPagamentoLinha, calcTotais: boolean = true): void {
    // atribui valorDesconto
    this._pagamentosService.calculaValorDesconto(linha, false);
    if (calcTotais) {
      this._calculaTotais(linha);
    }
  }

  public valorDescontoChanged(linha: IPagamentoLinha, calcTotais: boolean = true): void {
    this._pagamentosService.calculaPercentagemDesconto(linha, false);
    if (calcTotais) {
      this._calculaTotais(linha);
    }
  }

  public valorAPagarChanged(linha: IPagamentoLinha, calcTotais: boolean = true, calcValorDesc: boolean = true): void {
    // atribui valorDesconto
    this._pagamentosService.calculaValorRetencao(linha);

    if (calcValorDesc) {
      this._pagamentosService.calculaValorDesconto(linha, false);
    }

    if (calcTotais) {
      this._calculaTotais(linha);
      this._pagamentosService.calculaTotais(this.model);
    }
  }

  public changedNConta(nContaOrClifo: string | Partial<IJsonClifo>): void {
    this.model.linhas = [];
    if (isEmpty(nContaOrClifo)) {
      this.model.cab.nConta = undefined;
      this.model.cab.nome = undefined;
      this.isME = false;
      this.modelClifo = {};
    } else if (isString(nContaOrClifo)) {
      this.model.cab.nConta = undefined;
      this.model.cab.nome = undefined;
      this.isME = false;
      this.modelClifo = {
        nConta: '',
        nome: ''
      };
    } else {
      this.model.cab.nConta = nContaOrClifo.nConta;
      this.model.cab.nome = nContaOrClifo.nome;
      this.isME = nContaOrClifo.codMoeda !== this.codMoedaEmpresa;
      this.modelClifo = {
        id: nContaOrClifo.nIdAltern ? `${nContaOrClifo.nConta}_${nContaOrClifo.nIdAltern}` : nContaOrClifo.nConta,
        nConta: nContaOrClifo.nConta,
        nome: nContaOrClifo.nome
      };
    }
    if (!isEmpty(this.model.cab.nConta)) {
      this.changed('nConta');
    }
  }

  public changedData(value: MomentInput): void {
    this.model.cab.data = value;

    this.changed('data');

    this.invalidHeaderDate = false;
    const dateYearAfter: Moment = moment().add(1, 'year');
    if (moment(value).isAfter(dateYearAfter)) {
      this.invalidHeaderDate = true;
    }
  }

  public changed(name: string): void {
    if (this.model.cab.nConta && name === 'nConta') {
      this.loadLinhas().then(() => {
        this._cgLocalStorageGroupService.setItem(STORAGE_KEY, toJson(this.model), SCHEMA_STRING, EGroupName.ERP).subscribe();
      });
    }
  }

  public validaDados(): boolean {
    const v = this._pagamentosService.validaDados(this.model);
    if (this.valorDistribuir > this.model.cab.total) {
      v.push({fieldname: 'error', message: 'pagamentos.erros.faltaDistribuirValor'});
    }
    if (v.length > 0) {
      this.callback.setFieldError(v);
    }
    return v.length === 0;
  }

  public async loadLinhas(): Promise<void> {
    this.isloading = true;
    try {
      this.model = await this._pagamentosService.loadLinhas(this.model);
      if (this.model.linhas?.length) {
        this._applyDataGridDefinition();
        this.totalNaoVencido = 0;
        this.totalVencido = 0;

        this.model.linhas.forEach((linha: IPagamentoLinha) => {
          if (moment(moment()).isBefore(linha.dataVencimento)) {
            this.totalNaoVencido += linha.valorPorPagar;
          } else {
            this.totalVencido += linha.valorPorPagar;
          }
        });
      }
    } catch (error: unknown) {
      this._logger.error(error);
      if (error instanceof HttpErrorResponse) {
        const exception: ICGExceptionError = this._cgExceptionService.get(error);
        if (exception) {
          this.callback.messages().error.push(exception.message);
          if (isArray(exception.fields) && exception.fields.length > 0) {
            this.callback.setFieldError(exception.fields[0]);
          }
        } else {
          this.callback.messages().error.push(this._translateService.instant('pagamentos.erros.errodesconhecido'));
        }
      }
    } finally {
      this.isloading = false;
    }
  }

  public limpaLinha(item: IPagamentoLinha): void {
    const index = this.model.linhas.findIndex((value: IPagamentoLinha) => value.nlanc === item.nlanc);
    if (index !== -1) {
      this.model.linhas[index].valorAPagar = 0;
      this.model.linhas[index].valorDesconto = 0;
      if (this.abreviaturaMoedaLinha !== this.abreviaturaMoedaEmpresa) {
        this.abreviaturaMoedaLinha = this.abreviaturaMoedaEmpresa;
      }
      this._calculaTotais(this.model.linhas[index]);
    }
  }

  public limpaValoresLinhas(): void {
    for (const linha of this.model.linhas) {
      this.limpaLinha(linha);
    }
  }

  public pagarTudoLinha(item: IPagamentoLinha): void {
    const index = this.model.linhas.findIndex((value: IPagamentoLinha) => value.nlanc === item.nlanc);
    if (index !== -1) {
      if (this.model.cab.total === 0) {
        this.abreviaturaMoedaLinha = this.model.linhas[index].abreviaturaMoeda;
      } else if (this.abreviaturaMoedaLinha !== this.model.linhas[index].abreviaturaMoeda) {
        this._plAlertService.error(this._translateService.instant('pagamentos.erros.moedasdiferentesnosrecibos'));
        return;
      }
      if (this.modelPagamentoDistribuir.tipoDistribuicao === EPagamentoTipoDistribuicao.Manual) {
        if (this.model.linhas[index].valorPorPagar === this.model.linhas[index].valorAPagar) {
          return;
        }
        if (this.model.linhas[index].valorAPagar > 0) {
          this.valorDistribuir += this.model.linhas[index].valorAPagar - this.model.linhas[index].valorDesconto;
          this.model.linhas[index].valorAPagar = 0;
        }
        let valorRecebido: number = this.model.linhas[index].valorPorPagar;
        if (this.model.linhas[index].percDesconto > 0) {
          valorRecebido = round(this.valorDistribuir / ((100 - this.model.linhas[index].percDesconto) / 100), this.config.contabilidade.decimais.valor);
          if (this.model.linhas[index].valorPorPagar < valorRecebido) {
            valorRecebido = this.model.linhas[index].valorPorPagar;
          }
        } else if (this.model.linhas[index].valorPorPagar > this.valorDistribuir) {
          valorRecebido = this.valorDistribuir;
        }
        this.model.linhas[index].valorAPagar = valorRecebido;
      } else {
        this.model.linhas[index].valorAPagar = this.model.linhas[index].valorPorPagar;
      }
      this._pagamentosService.calculaValorDesconto(this.model.linhas[index], this.model.percDescSobreValorIVA);
      this._calculaTotais(item);
    }
  }

  public pagarTudo(): void {
    for (const linha of this.model.linhas) {
      this.pagarTudoLinha(linha);
    }
  }

  public aplicarValorPagar(valor: number): void {
    if (isUndefined(valor) || valor === 0) {
      return;
    }

    // Limpar linhas
    this.limpaValoresLinhas();

    for (const linha of this.model.linhas) {
      if (this.model.cab.total === 0) {
        this.abreviaturaMoedaLinha = linha.abreviaturaMoeda;
      } else if (this.abreviaturaMoedaLinha !== linha.abreviaturaMoeda) {
        continue;
      }

      // Adicionar notas de crédito
      if (linha.valorPorPagar < 0) {
        const v = this._plI18nService.formatNumber(valor + this._plCompsService.parseNumber(String(Math.abs(linha.valorPorPagar))), this.config.contabilidade.decimais.valor);
        valor = this._plCompsService.parseNumber(v);
      }

      // Consumir valores
      if (Math.abs(linha.valorPorPagar) > 0) {
        if (valor > 0) {
          if (valor >= linha.valorPorPagar) {
            const v = this._plI18nService.formatNumber(valor - linha.valorPorPagar, this.config.contabilidade.decimais.valor);
            valor = this._plCompsService.parseNumber(v);
            linha.valorAPagar = linha.valorPorPagar;
            this.valorAPagarChanged(linha);
            valor += linha.valorDesconto;
          } else {
            linha.valorAPagar = this._calcValorDesc(valor, linha.percDesconto);
            this.valorAPagarChanged(linha);
            valor = 0;
          }
        } else if (linha.valorPorPagar < 0) {
          linha.valorAPagar = linha.valorPorPagar;
        }
      }
    }

    this._pagamentosService.calculaTotais(this.model);
  }

  public distribuiValor(): Promise<void> {
    this.modelPagamentoDistribuir = {
      valor: 0,
      tipoDistribuicao: EPagamentoTipoDistribuicao.Automatico
    };
    this.valorDistribuir = 0;
    this.limpaValoresLinhas();

    return this._pagamentosService.distribuiValorModal().then((distribuirValorModal: IPagamentoDistribuirValor) => {
      if (distribuirValorModal.tipoDistribuicao === EPagamentoTipoDistribuicao.Automatico) {
        this.aplicarValorPagar(distribuirValorModal.valor);
      } else {
        this.modelPagamentoDistribuir = {
          valor: this.totalVencido + this.totalNaoVencido > distribuirValorModal.valor ? distribuirValorModal.valor : this.totalVencido + this.totalNaoVencido,
          tipoDistribuicao: distribuirValorModal.tipoDistribuicao
        };
        this.model.cab.totalPagar = this.modelPagamentoDistribuir.valor;
        this.valorDistribuir = this.model.cab.totalPagar;
      }
    });
  }

  public sendEmail(extPocCabID: string, nconta: string, email: string): TServiceResponse<void> {
    return this._pagamentosService.enviaPdfPorEmail(extPocCabID, nconta, email);
  }

  public readonly fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this._getPdf(doc);

  protected _onConfigurationsChanged(): void {
    if (!this._configService.configurations.empresa.verificacaoEfetuada && !this.hybridMode) {
      this._configService.checkConfiguracaoVerificada();
    }
    this.codMoedaEmpresa = this._configService.configurations.empresa.codMoeda;
    this.abreviaturaMoedaEmpresa = this._configService.configurations.empresa.abreviaturaMoeda;
    this.abreviaturaMoedaLinha = this._configService.configurations.empresa.abreviaturaMoeda;
  }

  private _onDetail(item: IPagamentoLinha): Promise<void> {
    if (!item._thedoc) {
      return this._docsComerciaisService.get({id: item.faccbid}).then((response: HttpResponse<IJsonDocComercial>) => {
        item._thedoc = response.body;
      });
    }
    return Promise.resolve();
  }

  private _getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc);
  }

  private _calcValorDesc(valor: number, desc: number): number {
    const d = this._plI18nService.formatNumber(desc / 100, this.config.contabilidade.decimais.percDesconto);
    const v = this._plI18nService.formatNumber(valor / (1 - this._plCompsService.parseNumber(d)), this.config.contabilidade.decimais.valor);
    return this._plCompsService.parseNumber(v);
  }

  private _calculaTotais(linha: IPagamentoLinha = undefined): void {
    this._pagamentosService.calculaTotais(this.model);

    if (this.modelPagamentoDistribuir.tipoDistribuicao === EPagamentoTipoDistribuicao.Manual) {
      this.model.cab.totalPagar = this.modelPagamentoDistribuir.valor + this.model.cab.totalDescontos;
      this.valorDistribuir = this.modelPagamentoDistribuir.valor - this.model.cab.total;
    }

    if (linha) {
      if (this.valorDistribuir < 0) {
        this._plAlertService.error('pagamentos.erros.valorUltrapassado');
        linha.valorAPagar = 0;
        this.valorAPagarChanged(linha);
      }
    }
  }

  private _applyDataGridDefinition(): void {
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'tipoDocumento', dataType: 'string', caption: 'pagamentos.pagamento.linhas.tipoDocumento', allowEditing: false},
        {dataField: 'numeroDocumento', dataType: 'string', caption: 'pagamentos.pagamento.linhas.numeroDocumento', visible: true, allowEditing: false},
        {dataField: 'dataDoc', dataType: 'date', caption: 'pagamentos.pagamento.linhas.dataDoc', allowEditing: false},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'pagamentos.pagamento.linhas.nDocExterno', allowEditing: false},
        {dataField: 'dataDocExterno', dataType: 'date', caption: 'pagamentos.pagamento.linhas.dataDocExterno', visible: false, allowEditing: false},
        {dataField: 'dataVencimento', dataType: 'date', caption: 'pagamentos.pagamento.linhas.dataVencimento', allowEditing: false},
        {dataField: 'valor', dataType: 'double', caption: 'pagamentos.pagamento.linhas.valorDoc', visible: false, allowEditing: false, cellTemplate: 'cellTemplateValor'},
        {dataField: 'valorPorPagar', dataType: 'double', caption: 'pagamentos.pagamento.linhas.valorPorPagar', cellTemplate: 'cellTemplateValorPorPagar', allowEditing: false},
        {dataField: 'descricao', dataType: 'string', caption: 'pagamentos.pagamento.linhas.descricao', allowEditing: false, visible: false},
        {
          dataField: 'montanteRetido',
          dataType: 'double',
          caption: 'pagamentos.pagamento.linhas.montanteRetido',
          format: {decimalsLimit: this.config.contabilidade.decimais.valor},
          cellTemplate: 'cellTemplateMontanteRetido',
          allowEditing: false,
          visible: this.model.cab.temRetencao,
          showInColumnChooser: this.model.cab.temRetencao
        },
        {
          dataField: 'cambioOrigem',
          dataType: 'double',
          caption: 'recibos.recibo.linhas.cambioOrigem',
          visible: this.isME,
          showInColumnChooser: this.isME,
          format: {decimalsLimit: NDECIMAIS_CAMBIO_PAGAMENTOS_DEFAULT},
          allowEditing: false
        },
        {
          dataField: 'cambioPagamento',
          dataType: 'double',
          caption: 'recibos.recibo.linhas.cambioPagam',
          visible: this.isME,
          showInColumnChooser: this.isME,
          format: {decimalsLimit: NDECIMAIS_CAMBIO_PAGAMENTOS_DEFAULT},
          allowSorting: false,
          editorOptions: {format: FIVE_DECIMAL_FORMATOR} satisfies NumberBoxOptions
        },
        {
          dataField: 'percDesconto',
          dataType: 'double',
          caption: 'pagamentos.pagamento.linhas.percDesconto',
          allowSorting: false,
          editorOptions: {step: 0.01, format: TWO_DECIMAL_FORMATOR} satisfies NumberBoxOptions
        },
        {
          dataField: 'valorDesconto',
          dataType: 'double',
          caption: 'pagamentos.pagamento.linhas.valorDesconto',
          allowSorting: false,
          editorOptions: {step: 0.01, format: {type: TWO_DECIMAL_FORMATOR}} satisfies NumberBoxOptions
        },
        {
          dataField: 'valorAPagar',
          dataType: 'double',
          caption: 'pagamentos.pagamento.linhas.valorAPagar',
          allowSorting: false,
          editorOptions: {step: 0.01, format: TWO_DECIMAL_FORMATOR} satisfies NumberBoxOptions
        },
        {type: 'buttons', cellTemplate: 'cellTemplateBtns', headerCellTemplate: 'headerCellTemplateBtns', showInColumnChooser: false}
      ],
      editing: {mode: 'cell', startEditAction: 'click', selectTextOnEditStart: true, allowUpdating: true, refreshMode: 'repaint'},
      export: {filename: 'global.menu.pagamentos'},
      height: '60vh',
      masterDetail: {enabled: true, template: 'masterDetailTemplate'},
      paging: {enabled: false},
      pager: {visible: false},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'}
    };
  }
}
