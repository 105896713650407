<pl-form class="trdocs-linhas-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'trDocsCabsWizard.captions.transformacaoDocumentos'"></h4>
  </div>

  <div class="modal-body">
    <trdocs-cabs-wizard-component [docType]="docType" [grupoDocfa]="grupoDocfa" [embed]="true" [wizardHideFooter]="true" (evtNavWizardSet)="navWizardSetEvent($event)"></trdocs-cabs-wizard-component>
  </div>

  <div class="modal-footer" plNavigation>
    <pl-nav-wizard-actions [navWizardInstance]="navWizardInst"></pl-nav-wizard-actions>

    <button type="button" class="btn btn-sm btn-light action-dismiss" (click)="close()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</pl-form>
