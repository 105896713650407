import type {Subscription} from 'rxjs';
import type {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {IMAGE_RESIZER_DEFAULT_RESIZE_QUALITY, IPlImageResizerProperties, TPlImageResizerMethod} from '../common/imageresizer/image.resizer.interface';
import type {TValueOrPromise} from '../common/utilities/utilities.interface';

export enum EPlUploadStatus {
  Added,
  Queued,
  Uploading,
  Canceled,
  Error,
  Success
}

export type TPlUploadAcceptFn = (file: File) => TValueOrPromise<undefined | boolean | string>;

export type TPlUploadMethodFn = () => TValueOrPromise<'post' | 'put'>;

export type TPlUploadRenameFileFn = (file: File) => TValueOrPromise<string>;

export type TPlUploadMethod = 'post' | 'put' | TPlUploadMethodFn;

export type TPlUploadResizeMethod = TPlImageResizerMethod;

export type TPlUploadResizeFn = (file: File, properties: Partial<IPlUploadProperties>) => TValueOrPromise<File>;

export type TPlUploadResponseType = 'arraybuffer' | 'blob' | 'json' | 'text';

export type TPlUploadThumbnailMethod = TPlImageResizerMethod;

export interface IPlUploadHeaders {
  [key: string]: string | Array<string>;
}

export interface IPlUploadParams {
  [param: string]: string | ReadonlyArray<string>;
}

export interface IPlUploadProperties extends IPlImageResizerProperties {
  accept: TPlUploadAcceptFn;
  acceptedFiles: string;
  autoProcessQueue: boolean;
  autoQueue: boolean;
  clickable: boolean;
  createImageThumbnails: boolean;
  droppable: boolean;
  fileSizeBase: number;
  headers: IPlUploadHeaders;
  hideGlobalActions: boolean;
  hideActions: boolean;
  hideActionCancel: boolean;
  hideActionRemoveAll: boolean;
  hideActionRemove: boolean;
  hideActionRetry: boolean;
  hideActionUploadAll: boolean;
  hideActionUpload: boolean;
  maxFiles: number;
  maxFileSize: number; // In megabytes
  maxThumbnailFileSize: number;
  method: TPlUploadMethod;
  parallelUploads: number;
  paramName: string;
  params: IPlUploadParams;
  formData: object | FormData;
  renameFile: TPlUploadRenameFileFn;
  reportProgress: boolean;
  resizeMethod: TPlUploadResizeMethod;
  resizeFn: TPlUploadResizeFn;
  resizeBeforeAccept: boolean;
  responseType: TPlUploadResponseType;
  thumbnailWidth: number;
  thumbnailHeight: number;
  thumbnailMethod: TPlUploadThumbnailMethod;
  uploadMultiple: boolean;
  url: string;
  withCredentials: boolean;
  zip: boolean;
  zipMinSize: number; // In megabytes, ignored if `zipStandalone` is false
  zipStandalone: boolean;
}

export interface IPlUploadThumbnails {
  pdf: string;
  txt: string;
  doc: string;
  docx: string;
  xls: string;
  xlsx: string;
  jpg: string;
  zip: string;
  xml: string;
  generic: string;
}

export interface IPlUploadFile {
  file: File;
  upload: IPlUploadFileUpload;
  status: EPlUploadStatus;
  statusMessage: string;
  accepted: boolean;
  rejected: boolean;
  subscription: Subscription;
}

export interface IPlUploadFileUpload {
  progress: number;
  total: number;
  bytesSent: number;
  filename: string;
  response: HttpResponse<any> | HttpErrorResponse;
}

export interface IPlUploadVisualFile extends IPlUploadFile {
  captionSize: string;
  captionName: string;
  thumbnail: IPlUploadVisualFileImage;
}

export interface IPlUploadVisualFileImage {
  preview: string;
  width: number;
  height: number;
}

export interface IPlUploadEventTotalProgressChanged {
  progress: number;
  totalBytes: number;
  totalSentBytes: number;
}

export interface IPlUploadDataBlock {
  name: string;
  data: string | Blob;
  filename: string;
}

export interface IPlUploadCallback {
  getFilesWithStatus?(status: EPlUploadStatus | Array<EPlUploadStatus>): Array<IPlUploadFile>;

  getQueuedFiles?(): Array<IPlUploadFile>;

  getUploadingFiles?(): Array<IPlUploadFile>;

  getAddedFiles?(): Array<IPlUploadFile>;

  getAcceptedFiles?(): Array<IPlUploadFile>;

  getRejectedFiles?(): Array<IPlUploadFile>;

  getActiveFiles?(): Array<IPlUploadFile>;

  getErroredFiles?(): Array<IPlUploadFile>;

  getSuccessFiles?(): Array<IPlUploadFile>;

  getPendingFiles?(): Array<IPlUploadFile>;

  addFiles?(fileList: FileList): Promise<void>;

  addFile?(file: File): Promise<void>;

  removeAllFiles?(cancelIfNecessary?: boolean): void;

  removeFile?(uploadFile: IPlUploadVisualFile): boolean;

  uploadAll?(): Promise<void>;

  processQueue?(): Promise<void>;

  cancelFileUpload?(uploadFile: IPlUploadFile): Promise<void>;

  openFileDialog?(): boolean;
}

export const DEFAULT_PARALLEL_UPLOADS = 2;
export const DEFAULT_MAX_FILE_SIZE = 256; // In megabytes
export const DEFAULT_MAX_THUMBNAIL_FILE_SIZE = 10; // In megabytes
export const DEFAULT_RESIZE_QUALITY = IMAGE_RESIZER_DEFAULT_RESIZE_QUALITY;
export const DEFAULT_RESIZE_METHOD: TPlUploadResizeMethod = 'contain';
export const DEFAULT_RESIZE_BEFORE_ACCEPT = false;
export const DEFAULT_REPORT_PROGRESS = true;
export const DEFAULT_RESPONSE_TYPE: TPlUploadResponseType = 'blob';
export const DEFAULT_THUMBNAIL_WIDTH = 65;
export const DEFAULT_THUMBNAIL_HEIGHT = 0;
export const DEFAULT_THUMBNAIL_METHOD: TPlUploadThumbnailMethod = DEFAULT_RESIZE_METHOD;
export const DEFAULT_FILE_SIZE_BASE = 1000;
export const DEFAULT_ZIP_MIN_SIZE = 0; // In megabytes
