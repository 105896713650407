import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonQPAPA} from './jsonqpapa.entity.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_QPAPA} from './qpapa.entity.interface';

export const ENTITY_QPAPA: IEntityDefinition<IJsonQPAPA> = {
  name: ENTITY_NAME_QPAPA,
  roles: [ROLE.RH],
  searchPlaceholder: 'qpapa.pesquisa',
  metadata: {
    keyName: 'codAssocPatronal',
    fields: [
      {
        name: 'codAssocPatronal',
        caption: 'qpapa.fields.codAssocPatronal',
        placeholder: 'qpapa.fields.codAssocPatronalPlaceholder',
        validators: {required: true, maxlength: 5}
      },
      {name: 'nomeAssocPatronal', caption: 'qpapa.fields.nomeAssocPatronal', placeholder: 'qpapa.fields.nomeAssocPatronalPlaceholder', validators: {required: true, maxlength: 150}},
      {name: 'morada', caption: 'qpapa.fields.morada', placeholder: 'qpapa.fields.moradaPlaceholder', validators: {maxlength: 60}},
      {name: 'codPostal', caption: 'qpapa.fields.codPostal', placeholder: 'qpapa.fields.codPostalPlaceholder', validators: {maxlength: 20}},
      {name: 'localidade', caption: 'qpapa.fields.localidade', placeholder: 'qpapa.fields.localidadePlaceholder', validators: {maxlength: 60}}
    ],
    order: 'codAssocPatronal',
    searchFields: 'codAssocPatronal,nomeAssocPatronal',
    listFields: 'codAssocPatronal,nomeAssocPatronal,morada,codPostal,localidade'
  },
  autocomplete: {
    rowTemplate: '{{codAssocPatronal}} - {{nomeAssocPatronal}}',
    output: 'nomeAssocPatronal',
    searchFields: 'codAssocPatronal,nomeAssocPatronal'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    search: true,
    delete: false,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.qpapa'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.qpapa'
      }
    }
  }
};
