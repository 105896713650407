<div class="modal-header">
  <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'global.menu.account.changeEmpresa'"></h4>
</div>

<div class="modal-body">
  <dx-data-grid
    [cgDxDataGrid]="dataGrid"
    [dataSource]="dataGrid.dataSource"
    (onContentReady)="onContentReady()"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onRowClick)="onDataGridRowClick($event)">
  </dx-data-grid>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-sm btn-light" [disabled]="closeDisabled" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
</div>
