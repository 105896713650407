<div class="rh-relatorio-unico-config-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'rhrelatoriounico.modal.config.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <pl-group>
        <label [translate]="'rhrelatoriounico.modal.config.idEntidade'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="idEntidade" [(model)]="ruConfig.idEntidade"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'empresas.fields.codNatJuridica'"></label>
        <edit>
          <entity-autocomplete
            entity="qpnj"
            attrName="codNatJuridica"
            [model]="ruConfig"
            (selectedKeyChange)="ruConfig.codNatJuridica = $event"
            (evtSelectedDescriptionChanged)="ruConfig.nomeNatJuridica = $event"
            [fieldsMap]="{codNatJuridica: 'codNatJuridica', nome: 'nomeNatJuridica'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <pl-group>
        <label [translate]="'empresas.fields.codAssocPatronal'"></label>
        <edit>
          <entity-autocomplete
            entity="qpapa"
            attrName="codAssocPatronal"
            [model]="ruConfig"
            (selectedKeyChange)="ruConfig.codAssocPatronal = $event"
            (evtSelectedDescriptionChanged)="ruConfig.nomeAssocPatronal = $event"
            [fieldsMap]="{codAssocPatronal: 'codAssocPatronal', nomeAssocPatronal: 'nomeAssocPatronal'}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'empresas.fields.codddccff'"></label>
        <edit>
          <entity-autocomplete
            entity="qpdcf"
            attrName="codddccff"
            [model]="ruConfig"
            (selectedKeyChange)="ruConfig.codddccff = $event"
            (evtSelectedDescriptionChanged)="ruConfig.nomeCodddccff = $event"
            [fieldsMap]="{codDistConcFreg: 'codddccff', nome: 'nomeCodddccff'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>

    <div class="mt-3">
      <dx-data-grid
        [cgDxDataGrid]="dataGridDefinition"
        cgDxDataGridInstanceName="RUEstabelecimentos"
        [dataSource]="dataGridDefinition.dataSource"
        (onInitialized)="onInitialized($event)"></dx-data-grid>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success btn-sm action-save" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
    <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
