<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h5 class="modal-title" [translate]="title"></h5>
</div>

<div class="modal-body">
  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light action-close" (evtClicked)="close()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
