import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {IJsonModelo22, IJsonModelo22Rosto, IJsonModelo22RostoItemPeriodoMontante} from '../../../../jsonModelo22.module.interface';
import {IRostoItemNIFValorPeriodo, IRostoItemValorNIF} from '../../../../modelo22.module.interface';
import {Modelo22Service} from '../../../../modelo22.module.service';

@Component({
  selector: 'modal-modelo22-rosto',
  templateUrl: './modelo22.rosto.modal.component.html'
})
export class Modelo22RostoModalComponent extends CGModalComponent<IJsonModelo22> implements OnInit {
  @Input() public modelo22: IJsonModelo22;
  @Input() public newLineFor: string;

  public promise: Promise<void>;
  public prejuizo: number;
  public nif: number;
  public periodo: number;
  public montante: number;
  public valPerio: number;
  public retFonte: number;

  public is396: boolean;
  public is398: boolean;
  public is309: boolean;
  public is320: boolean;
  public is331: boolean;
  public is397A: boolean;
  public is397B: boolean;
  public is309B: boolean;
  public isQuadro12List: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.prejuizo = 0;
    this.nif = undefined;
    this.periodo = undefined;
    this.montante = 0;
    this.valPerio = 0;
    this.retFonte = 0;
  }

  public ngOnInit(): void {
    this.is396 = this.newLineFor === '396';
    this.is398 = this.newLineFor === '398';
    this.is309 = this.newLineFor === '309';
    this.is320 = this.newLineFor === '320';
    this.is331 = this.newLineFor === '331';
    this.is397A = this.newLineFor === '397A';
    this.is397B = this.newLineFor === '397B';
    this.is309B = this.newLineFor === '309B';
    this.isQuadro12List = this.newLineFor === 'quadro12List';
  }

  public async close(): Promise<void> {
    if (this.is396) {
      if (!this.prejuizo || this.prejuizo === 0) {
        this._plAlertService.error('modelo22.messages.prejEmpty');
        return Promise.resolve();
      }
      if (this.nif && this.nif > 0) {
        if (this.rosto.quadro09.campo396List.find((value: IRostoItemNIFValorPeriodo) => value.nif === this.nif)) {
          this._plAlertService.error('modelo22.messages.nifAlredyExists');
          return Promise.resolve();
        }
        await this._modelo22Service.getValidateNif(this.nif).then(undefined);
      }
      this.rosto.quadro09.campo396List.push({nif: this.nif, valor: this.prejuizo, periodo: this.periodo});
      return this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
        super.close(response.body);
      });
    }
    if (this.is398) {
      if (!this.prejuizo || this.prejuizo === 0) {
        this._plAlertService.error('modelo22.messages.prejEmpty');
        return Promise.resolve();
      }
      if (this.nif && this.nif > 0) {
        if (this.rosto.quadro09.campo398List.find((value: IRostoItemNIFValorPeriodo) => value.nif === this.nif)) {
          this._plAlertService.error('modelo22.messages.nifAlredyExists');
          return Promise.resolve();
        }
        await this._modelo22Service.getValidateNif(this.nif).then(undefined);
      }
      this.rosto.quadro09.campo398List.push({nif: this.nif, valor: this.prejuizo, periodo: this.periodo});
      return this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
        super.close(response.body);
      });
    }
    if (this.is309) {
      if (this.rosto.quadro09.campo309List.find((value: IJsonModelo22RostoItemPeriodoMontante) => value.periodo === this.periodo)) {
        this._plAlertService.error('modelo22.messages.periodoAlredyExists');
        return Promise.resolve();
      }
      this.rosto.quadro09.campo309List.push({periodo: this.periodo, montante: this.montante});
      return this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
        super.close(response.body);
      });
    }
    if (this.is320) {
      if (this.rosto.quadro09.campo320List.find((value: IJsonModelo22RostoItemPeriodoMontante) => value.periodo === this.periodo)) {
        this._plAlertService.error('modelo22.messages.periodoAlredyExists');
        return Promise.resolve();
      }
      this.rosto.quadro09.campo320List.push({periodo: this.periodo, montante: this.montante});
      return this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
        super.close(response.body);
      });
    }
    if (this.is331) {
      if (this.rosto.quadro09.campo331List.find((value: IJsonModelo22RostoItemPeriodoMontante) => value.periodo === this.periodo)) {
        this._plAlertService.error('modelo22.messages.periodoAlredyExists');
        return Promise.resolve();
      }
      this.rosto.quadro09.campo331List.push({periodo: this.periodo, montante: this.montante});
      return this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
        super.close(response.body);
      });
    }
    if (this.is397A) {
      if (this.rosto.quadro09.campo397AList.find((value: IRostoItemNIFValorPeriodo) => value.nif === this.nif)) {
        this._plAlertService.error('modelo22.messages.nifAlredyExists');
        return Promise.resolve();
      }
      this.rosto.quadro09.campo397AList.push({periodo: this.periodo, valor: this.valPerio, nif: this.nif});
      return this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
        super.close(response.body);
      });
    }
    if (this.is397B) {
      if (this.rosto.quadro09.campo397BList.find((value: IRostoItemNIFValorPeriodo) => value.nif === this.nif)) {
        this._plAlertService.error('modelo22.messages.nifAlredyExists');
        return Promise.resolve();
      }
      this.rosto.quadro09.campo397BList.push({periodo: this.periodo, valor: this.valPerio, nif: this.nif});
      return this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
        super.close(response.body);
      });
    }
    if (this.is309B) {
      if (this.rosto.quadro09.campo309BList.find((value: IRostoItemNIFValorPeriodo) => value.nif === this.nif)) {
        this._plAlertService.error('modelo22.messages.nifAlredyExists');
        return Promise.resolve();
      }
      this.rosto.quadro09.campo309BList.push({periodo: this.periodo, valor: this.montante, nif: this.nif});
      return this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
        super.close(response.body);
      });
    }
    if (this.isQuadro12List) {
      return this._modelo22Service.getValidateNif(this.nif).then(() => {
        if (this.rosto.quadro12.list.find((value: IRostoItemValorNIF) => value.nif === this.nif)) {
          this._plAlertService.error('modelo22.messages.nifAlredyExists');
          return Promise.resolve();
        }
        this.rosto.quadro12.list.push({nif: this.nif, valor: this.retFonte});
        return this._modelo22Service.evaluateModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
          super.close(response.body);
        });
      });
    }
    return Promise.resolve();
  }

  public get rosto(): IJsonModelo22Rosto {
    return this.modelo22.rosto;
  }

  public set rosto(value: IJsonModelo22Rosto) {
    this.modelo22.rosto = value;
  }
}
