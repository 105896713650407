import moment, {Moment} from 'moment';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IPlValidator, IPlValidatorValidateParams} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ImportadorSaftDirectService} from '../../importadorSaftDirect.module.service';
import {TDate} from '../../../../../../common/dates';

@Component({
  selector: 'importador-saft-direct-datas-docs-edit-modal',
  templateUrl: './importadorSaftDirect.datasDocumentos.edit.modal.component.html'
})
export class ImportadorSaftDirectDatasDocumentosEditModalComponent extends CGModalComponent<TDate> implements OnInit {
  @Input() public model: TDate;
  @Input() public dataUltimoDoc: TDate;

  private _ano: number;
  private _mes: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _importadorSaftDirectService: ImportadorSaftDirectService
  ) {
    super(_injector);
    this.checkDateValidator = this.checkDateValidator.bind(this);
  }

  public ngOnInit(): void {
    const modelMom = moment(this.model);
    this._ano = modelMom.year();
    this._mes = modelMom.month();
  }

  public async close(): Promise<void> {
    this.disableClose();
    try {
      await this._importadorSaftDirectService.datasDocumentosSetDataLanc(this.model, this.dataUltimoDoc);
      this.enableClose();
      super.close(this.model);
    } finally {
      this.enableClose();
    }
  }

  public checkDateValidator(): IPlValidator<string> {
    return {
      message: ({formControlValue}: IPlValidatorValidateParams<string>) => {
        const date: Moment = moment(formControlValue, this.format.momentDate);
        return this._translateService.instant('importadorSaftDirect.messages.datasDocumentosDataLancInvalid', {
          data: date.format(this.format.momentDate),
          ano: this._ano,
          mes: this._mes + 1
        });
      },
      validate: ({formControlValue}: IPlValidatorValidateParams<string>) => {
        const date: Moment = moment(formControlValue, this.format.momentDate);
        return date.year() === this._ano && date.month() === this._mes;
      }
    };
  }
}
