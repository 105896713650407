<div class="gestao-comunicacoes-config-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="estadoTitle"></h4>
  </div>

  <div class="modal-body">
    <pl-form [(ngForm)]="form">
      <pl-group>
        <label [translate]="'global.text.name'"></label>
        <edit>
          <pl-edit-text attrName="nomeEstado" [model]="estadoDetail?.nome" (modelChange)="estadoDetail.nome = $event" plAutoFocus [properties]="{validators: {required: {value: true}}}"></pl-edit-text>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'gestaoComunicacoes.modal.empresaConfig.empresaConfigEstados.detail.cor'"></label>
        <edit>
          <dx-color-box [value]="estadoDetail?.cor" (valueChange)="estadoDetail.cor = $event" [applyValueMode]="'instantly'" [acceptCustomValue]="false" [openOnFieldClick]="true"></dx-color-box>
        </edit>
      </pl-group>

      <pl-group>
        <edit>
          <pl-edit-checkbox
            class="mt-2"
            attrName="todasEmpresas"
            [model]="estadoDetail?.todasEmpresas"
            (modelChange)="estadoDetail.todasEmpresas = $event"
            [properties]="{label: 'gestaoComunicacoes.modal.empresaConfig.empresaConfigEstados.detail.todasEmpresas'}">
          </pl-edit-checkbox>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-primary btn-sm action-submit" (evtClicked)="close()" [disabled]="closeDisabled || form?.invalid"
      ><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
    </pl-button>
    <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
