<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()" aria-hidden="true" [disabled]="closeDisabled"></button>
  <h4 class="modal-title" [translate]="'recibos.saveModal.title'"></h4>
</div>

<div class="modal-body" [plPromise]="promise">
  <div [plAnimationShake]="alertErrorMsg.length" *ngIf="alertErrorMsg.length">
    <pl-alert (evtClosed)="clearErros()">
      <div *ngFor="let errorMsg of alertErrorMsg" [translate]="errorMsg"></div>
    </pl-alert>
  </div>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="recibosSaveModalPaymentMean"
    [dataSource]="recibo.meiosPagamento"
    (onSaved)="onSaved()"
    (onInitialized)="onInitialized($event)"
    (onKeyDown)="onKeyDown($event)">
    <div *dxTemplate="let item of 'cellTemplateBtns'">
      <button type="button" class="btn btn-primary btn-xs" [attr.title]="'recibos.saveModal.payInFull' | translate" (click)="receberTudoLinha(item.data)" [disabled]="closeDisabled">
        <i class="fa fa-fw fa-check"></i>
      </button>

      <button type="button" class="btn btn-primary btn-xs" [attr.title]="'recibos.saveModal.clearValue' | translate" (click)="limpaLinha(item.data)" [disabled]="closeDisabled">
        <i class="fa fa-fw fa-eraser"></i>
      </button>
    </div>
  </dx-data-grid>

  <div class="mt-3 d-flex align-items-start gap-3">
    <div class="d-flex align-items-center" style="flex-grow: 1">
      <div class="fw-semibold" [translate]="'recibos.fields.descricao'"></div>
      &nbsp;
      <pl-edit-text [(model)]="recibo.cab.descricao" attrName="descricao" style="flex-basis: 300px"></pl-edit-text>
    </div>

    <div class="resumo">
      <dl class="page-invoice-amount">
        <dt><span [translate]="'pagamentos.saveModal.document'"></span>:</dt>
        <dd>{{ recibo.cab.total | cgcCurrency: valorDecimais : abreviaturaMoeda : 'code' }}</dd>

        <dt><span [translate]="'recibos.recibo.totalCaixa'"></span>:</dt>
        <dd>{{ recibo.totalCaixa | cgcCurrency: valorDecimais : abreviaturaMoeda : 'code' }}</dd>
      </dl>
    </div>
  </div>

  <div
    *ngIf="!canSave"
    class="mt-2 text-danger"
    [translate]="'recibos.erros.invalidValorAPagar'"
    [translateParams]="{
      valorEntregue: recibo.totalCaixa | cgcCurrency: valorDecimais : abreviaturaMoeda : 'code',
      valorDocumento: recibo.cab.total | cgcCurrency: valorDecimais : abreviaturaMoeda : 'code'
    }"></div>
</div>

<div class="modal-footer" plNavigation>
  <button [id]="saveButtonId" type="button" class="btn btn-sm btn-primary action-save" [click]="fnSave('save')" plPromise [disabled]="closeDisabled || !canSave">
    <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'recibos.saveModal.save'"></span>
  </button>

  <button type="button" class="btn btn-sm btn-warning action-save-pdf" [click]="fnSave('pdf')" plPromise [disabled]="closeDisabled || !canSave">
    <i class="fa fa-fw fa-file-pdf-o"></i>&nbsp;<span [translate]="'recibos.saveModal.saveCheck'"></span>
  </button>

  <button type="button" class="btn btn-sm btn-light action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
    <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
  </button>
</div>
