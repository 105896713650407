import {findLastIndex, isEqual, merge} from 'lodash-es';
import {Component, Injector, OnInit} from '@angular/core';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {copy, IPlFormDefinition, isNumber, isObject, moveItemInArray, PlAlertService, toInteger} from 'pl-comps-angular';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {EMascaraAnaliticaListNameType, IMascaraAnalitica, IMascaraAnaliticaDefinition, IMascaraAnaliticaPocNomes} from '../../mascaraAnalitica.entity.interface';
import {EMascaraAnaliticaTipo} from '../../jsonMascaraAnalitica.entity.interface';
import {EPocsClasse} from '../../../pocs/pocs.entity.interface';
import {MascarasAnaliticaEditDefinitionNamesModalComponent} from '../../modals/definitionnames/mascarasAnalitica.edit.definitionNames.modal.component';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';

const PREFIX = EPocsClasse.ANALITICA;
const SEPARATOR_INDICES_SUB_TOTAIS = ',';
const INDEX_LIST_NAME_POC_GERAL = 1;

@Component({
  selector: 'mascaras-analitica-edit',
  templateUrl: './mascarasAnalitica.entity.edit.component.html'
})
export class MascarasAnaliticaEditComponent extends ModuloEntityDetailComponent<IMascaraAnalitica> implements OnInit {
  public readonly definitionFaturacao: Array<IMascaraAnaliticaDefinition>;
  public readonly definitionSalarios: Array<IMascaraAnaliticaDefinition>;
  public readonly definitionImobilizado: Array<IMascaraAnaliticaDefinition>;
  public readonly nomesFaturacao: Array<IMascaraAnaliticaDefinition>;
  public readonly nomesSalarios: Array<IMascaraAnaliticaDefinition>;
  public readonly nomesImobilizado: Array<IMascaraAnaliticaDefinition>;
  public listDefinition: Array<IMascaraAnaliticaDefinition>;
  public formDefinition: IPlFormDefinition;
  public definitionModel: Array<IMascaraAnaliticaDefinition>;
  public nameModel: Array<IMascaraAnaliticaPocNomes>;
  public listNames: Array<IMascaraAnaliticaPocNomes>;

  private _originalModel: IMascaraAnalitica;
  private _originalMascara: string;
  private _possibleListNames: Array<IMascaraAnaliticaDefinition>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    const definition: Array<IMascaraAnaliticaDefinition> = [
      {char: '0', name: 'mascarasanalitica.enum.definition.0', isConstant: true},
      {char: '1', name: 'mascarasanalitica.enum.definition.1', isConstant: true},
      {char: '2', name: 'mascarasanalitica.enum.definition.2', isConstant: true},
      {char: '3', name: 'mascarasanalitica.enum.definition.3', isConstant: true},
      {char: '4', name: 'mascarasanalitica.enum.definition.4', isConstant: true},
      {char: '5', name: 'mascarasanalitica.enum.definition.5', isConstant: true},
      {char: '6', name: 'mascarasanalitica.enum.definition.6', isConstant: true},
      {char: '7', name: 'mascarasanalitica.enum.definition.7', isConstant: true},
      {char: '8', name: 'mascarasanalitica.enum.definition.8', isConstant: true},
      {char: '9', name: 'mascarasanalitica.enum.definition.9', isConstant: true}
    ];
    this.definitionFaturacao = definition.concat([
      {char: 'C', name: 'mascarasanalitica.enum.definitionFaturacao.ccusto'},
      {char: 'Z', name: 'mascarasanalitica.enum.definitionFaturacao.zone'},
      {char: 'D', name: 'mascarasanalitica.enum.definitionFaturacao.department'},
      {char: 'S', name: 'mascarasanalitica.enum.definitionFaturacao.subDepartment'},
      {char: 'F', name: 'mascarasanalitica.enum.definitionFaturacao.family'},
      {char: 'G', name: 'mascarasanalitica.enum.definitionFaturacao.bigFamily'},
      {char: 'K', name: 'mascarasanalitica.enum.definitionFaturacao.subFamily'},
      {char: 'B', name: 'mascarasanalitica.enum.definitionFaturacao.accountingCode'},
      {char: 'T', name: 'mascarasanalitica.enum.definitionFaturacao.itemType'},
      {char: 'X', name: 'mascarasanalitica.enum.definitionFaturacao.class'},
      {char: 'Y', name: 'mascarasanalitica.enum.definitionFaturacao.category'},
      {char: 'V', name: 'mascarasanalitica.enum.definitionFaturacao.seller'},
      {char: 'P', name: 'mascarasanalitica.enum.definitionFaturacao.process'}
    ]);
    this.definitionSalarios = definition.concat([
      {char: 'C', name: 'mascarasanalitica.enum.definitionSalarios.ccusto'},
      {char: 'B', name: 'mascarasanalitica.enum.definitionSalarios.accountingCode'},
      {char: 'X', name: 'mascarasanalitica.enum.definitionSalarios.workerClass'},
      {char: 'F', name: 'mascarasanalitica.enum.definitionSalarios.roleName'},
      {char: 'D', name: 'mascarasanalitica.enum.definitionSalarios.employeeCCusto'},
      {char: 'P', name: 'mascarasanalitica.enum.definitionSalarios.categoryFamily'},
      {char: 'Z', name: 'mascarasanalitica.enum.definitionSalarios.zone'},
      {char: 'V', name: 'mascarasanalitica.enum.definitionSalarios.department'}
    ]);
    this.definitionImobilizado = definition.concat([
      {char: 'C', name: 'mascarasanalitica.enum.definitionImobilizado.ccusto'},
      {char: 'D', name: 'mascarasanalitica.enum.definitionImobilizado.department'},
      {char: 'F', name: 'mascarasanalitica.enum.definitionImobilizado.family'},
      {char: 'G', name: 'mascarasanalitica.enum.definitionImobilizado.bigFamily'},
      {char: 'T', name: 'mascarasanalitica.enum.definitionImobilizado.itemType'},
      {char: 'B', name: 'mascarasanalitica.enum.definitionImobilizado.accountingCode'}
    ]);
    this.nomesFaturacao = [
      {char: ' ', name: 'mascarasanalitica.enum.nomes.poc'},
      {char: 'C', name: 'mascarasanalitica.enum.nomesFaturacao.ccusto'},
      {char: 'Z', name: 'mascarasanalitica.enum.nomesFaturacao.zone'},
      {char: 'D', name: 'mascarasanalitica.enum.nomesFaturacao.department'},
      {char: 'S', name: 'mascarasanalitica.enum.nomesFaturacao.subDepartment'},
      {char: 'F', name: 'mascarasanalitica.enum.nomesFaturacao.family'},
      {char: 'G', name: 'mascarasanalitica.enum.nomesFaturacao.bigFamily'},
      {char: 'K', name: 'mascarasanalitica.enum.nomesFaturacao.subFamily'},
      {char: 'B', name: 'mascarasanalitica.enum.nomesFaturacao.accountingCode'},
      {char: 'T', name: 'mascarasanalitica.enum.nomesFaturacao.itemType'},
      {char: 'X', name: 'mascarasanalitica.enum.nomesFaturacao.class'},
      {char: 'Y', name: 'mascarasanalitica.enum.nomesFaturacao.category'},
      {char: 'V', name: 'mascarasanalitica.enum.nomesFaturacao.seller'},
      {char: 'P', name: 'mascarasanalitica.enum.nomesFaturacao.process'}
    ];
    this.nomesSalarios = [
      {char: ' ', name: 'mascarasanalitica.enum.nomes.poc'},
      {char: 'C', name: 'mascarasanalitica.enum.nomesSalarios.ccusto'},
      {char: 'B', name: 'mascarasanalitica.enum.nomesSalarios.accountingCode'},
      {char: 'X', name: 'mascarasanalitica.enum.nomesSalarios.workerClass'},
      {char: 'F', name: 'mascarasanalitica.enum.nomesSalarios.roleName'},
      {char: 'D', name: 'mascarasanalitica.enum.nomesSalarios.employeeCCusto'},
      {char: 'P', name: 'mascarasanalitica.enum.nomesSalarios.categoryFamily'},
      {char: 'Z', name: 'mascarasanalitica.enum.nomesSalarios.zone'},
      {char: 'V', name: 'mascarasanalitica.enum.nomesSalarios.department'}
    ];
    this.nomesImobilizado = [
      {char: ' ', name: 'mascarasanalitica.enum.nomes.poc'},
      {char: 'C', name: 'mascarasanalitica.enum.nomesImobilizado.ccusto'},
      {char: 'D', name: 'mascarasanalitica.enum.nomesImobilizado.department'},
      {char: 'F', name: 'mascarasanalitica.enum.nomesImobilizado.family'},
      {char: 'G', name: 'mascarasanalitica.enum.nomesImobilizado.bigFamily'},
      {char: 'T', name: 'mascarasanalitica.enum.nomesImobilizado.itemType'},
      {char: 'B', name: 'mascarasanalitica.enum.nomesImobilizado.accountingCode'}
    ];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.model = merge(
      {
        manalID: undefined,
        mascara: PREFIX,
        descricao: '',
        manalPocNomes: [],
        tipo: 0,
        nCaracteresGeral: 50,
        isDefault: false,
        subTotaisDaGeral: true,
        indicesSubTotais: '',
        contaContrapartida: '',
        nomeContaContrapartida: ''
      },
      this.model
    );
    this._originalModel = copy(this.model);
    this._originalMascara = copy(this.model.mascara);
    this._setList(this.model.tipo);
    this._fromMascara();
    if (this.type === EEntityStateDetailType.NEW) {
      this.addListName(this.listNames[INDEX_LIST_NAME_POC_GERAL]);
    }
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    this.formDefinition = this.entity
      .fieldEvents('tipo')
      .beforeChange((newValue: EMascaraAnaliticaTipo) => this._beforeChangeType(newValue))
      .change((value: EMascaraAnaliticaTipo) => {
        this._changeType(value);
      })
      .build(stateType);
    return super.onUpdate(stateType);
  }

  public cancel(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      super
        .cancel()
        .then(() => {
          this.definitionModel = [];
          this.nameModel = [];
          this._fromMascara(this._originalMascara);
        })
        .catch(reject);
    });
  }

  public save(): Promise<IMascaraAnalitica> {
    this.model.manalPocNomes = this.nameModel;
    return super.save();
  }

  public evaluateDelete(value: IMascaraAnaliticaDefinition, notEditing: boolean = false): boolean {
    if (!isObject(value) || (!this.callback.isEditing() && !notEditing)) {
      return false;
    }
    const valueIndex = this.definitionModel.findIndex((item) => item.id === value.id);
    const previousValue = this.definitionModel[valueIndex - 1];
    return !previousValue || previousValue.char !== value.char;
  }

  public changeSubTotal(): void {
    const indicesSubTotais: Array<string> = [];
    for (const item of this.definitionModel) {
      if (item.isIndice && !indicesSubTotais.includes(item.char)) {
        indicesSubTotais.push(item.char);
      }
    }
    const indicesSubTotaisAsIndexes: Array<number> = indicesSubTotais.map<number>((char: string) => {
      return findLastIndex(this.definitionModel, (model: IMascaraAnaliticaDefinition) => model.char === char);
    });
    this.model.indicesSubTotais = indicesSubTotaisAsIndexes.join(SEPARATOR_INDICES_SUB_TOTAIS);
  }

  public remove(value: IMascaraAnaliticaDefinition): void {
    const index = this.definitionModel.findIndex((modelItem) => modelItem.id === value.id);
    const start = index;
    let end = 1;
    for (let i = index + 1; i < this.definitionModel.length; i++) {
      if (this.definitionModel[i].char === value.char) {
        end++;
      } else {
        break;
      }
    }
    this.definitionModel.splice(start, end);
    this._toMascara();
  }

  public removeName(index: number): void {
    this.nameModel.splice(index, 1);
  }

  public addListName(value: IMascaraAnaliticaPocNomes): void {
    this.nameModel.push(value);
    this._reIndexNameModel();
  }

  public changeText(value: IMascaraAnaliticaPocNomes): Promise<IMascaraAnaliticaPocNomes> {
    const modalInstance = this._cgModalService.showVanilla(MascarasAnaliticaEditDefinitionNamesModalComponent);
    const componentInstance: MascarasAnaliticaEditDefinitionNamesModalComponent = modalInstance.componentInstance;
    componentInstance.value = value.nomeEstatico;
    return modalInstance.result.then((response: string) => {
      value.content = response;
      value.nomeEstatico = response;
      return value;
    });
  }

  public dropped(event: CdkDragDrop<Array<IMascaraAnaliticaDefinition>>): void {
    if (event.container === event.previousContainer) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this._orderModel();
      if (this.type === EEntityStateDetailType.NEW) {
        this._orderNameModel();
      }
    } else {
      const newItem: IMascaraAnaliticaDefinition = copy<IMascaraAnaliticaDefinition>(event.item.data);
      if (event.container.data.length && !this._evaluateAdd(newItem, event.currentIndex)) {
        return;
      }
      const definitionSameSection: boolean = this._evaluateDefinitionSameSection(newItem, event.currentIndex);
      newItem.id = event.container.data.length;
      newItem.isConstant = Boolean(newItem.isConstant);
      newItem.isIndice = false;
      event.container.data.splice(event.currentIndex, 0, newItem);
      this._orderModel();
      if (this.type === EEntityStateDetailType.NEW && !definitionSameSection) {
        const listName: IMascaraAnaliticaPocNomes = this.listNames.find((name: IMascaraAnaliticaPocNomes) => name.char === newItem.char);
        if (listName) {
          this.addListName(listName);
          this._orderNameModel();
        }
      }
    }
  }

  public droppedListNames(event: CdkDragDrop<Array<IMascaraAnaliticaPocNomes>>): void {
    if (event.container === event.previousContainer) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this._reIndexNameModel();
    } else {
      const value: IMascaraAnaliticaPocNomes = copy<IMascaraAnaliticaPocNomes>(event.item.data);
      event.container.data.splice(event.currentIndex, 0, value);
      if (value && value.tipoCampo === -1) {
        this.changeText(value).then(() => {
          this._reIndexNameModel();
        });
      } else {
        this._reIndexNameModel();
      }
    }
  }

  public addDefinition(value: IMascaraAnaliticaDefinition): void {
    if (this._evaluateAdd(value)) {
      const lastChar: string = this.definitionModel.length ? this.definitionModel[this.definitionModel.length - 1].char : undefined;
      this.definitionModel.push({
        id: this.definitionModel.length,
        char: value.char,
        name: value.name,
        isConstant: Boolean(value.isConstant),
        isIndice: true
      });
      this._toMascara();
      if (this.type === EEntityStateDetailType.NEW && value.char !== lastChar) {
        const listName: IMascaraAnaliticaPocNomes = this.listNames.find((name: IMascaraAnaliticaPocNomes) => name.char === value.char);
        if (listName) {
          this.addListName(listName);
          this._orderNameModel();
        }
      }
    }
  }

  private _setList(tipo: EMascaraAnaliticaTipo = this.model.tipo): void {
    let listDefinition: Array<IMascaraAnaliticaDefinition>;
    let listNames: Array<IMascaraAnaliticaDefinition>;
    switch (tipo) {
      case EMascaraAnaliticaTipo.SALARIOS:
        listDefinition = this.definitionSalarios;
        listNames = this.nomesSalarios;
        break;
      case EMascaraAnaliticaTipo.IMOBILIZADO:
        listDefinition = this.definitionImobilizado;
        listNames = this.nomesImobilizado;
        break;
      default:
        listDefinition = this.definitionFaturacao;
        listNames = this.nomesFaturacao;
        break;
    }
    this.listDefinition = listDefinition;
    this._possibleListNames = listNames;
    this.definitionModel = [];
    this.nameModel = [];
  }

  private _beforeChangeType(newValue: EMascaraAnaliticaTipo): Promise<void> {
    if (this.model.mascara !== PREFIX && newValue !== this.model.tipo && !isEqual(this.model, this._originalModel)) {
      return this._cgModalService.showOkCancel('mascarasanalitica.changeTypePrompt.title', 'mascarasanalitica.changeTypePrompt.message');
    }
    return Promise.resolve();
  }

  private _changeType(value: EMascaraAnaliticaTipo): void {
    this._setList(value);
    this._toMascara();
    this.addListName(this.listNames[INDEX_LIST_NAME_POC_GERAL]);
  }

  private _toMascara(): void {
    let mascara: string = PREFIX;
    let lastChar: string;
    for (let i = 0; i < this.definitionModel.length; i++) {
      const item = this.definitionModel[i];
      const char = item.char;

      // If constant simply add it's char
      if (item.isConstant) {
        if (lastChar) {
          mascara += ']';
          lastChar = undefined;
        }
        mascara += char;
        continue;
      }

      // If new group
      if (!lastChar) {
        mascara += '[';
        lastChar = char;
      }

      // If still same group
      if (char === lastChar) {
        mascara += char;
      }
      // If starting new group
      else {
        mascara += `][${char}`;
      }

      // If last char and a group is still open we have to close it
      if (i === this.definitionModel.length - 1) {
        if (lastChar) {
          mascara += ']';
        }
      } else {
        lastChar = char;
      }
    }
    this.model.mascara = mascara;
    this._handleListNames();
  }

  private _fromMascara(mascara: string = this.model.mascara): void {
    // Definition
    for (let i = 1; i < mascara.length; i++) {
      const char: string = mascara[i];
      const definition: IMascaraAnaliticaDefinition = this.listDefinition.find((definitionItem: IMascaraAnaliticaDefinition) => definitionItem.char === char);
      if (definition) {
        this.addDefinition(definition);
      }
    }

    // Init `indicesSubTotais` booleans
    const positions: Array<number> = this.model.indicesSubTotais
      .split(SEPARATOR_INDICES_SUB_TOTAIS)
      .map(toInteger)
      .filter((value: number) => !Number.isNaN(value));
    for (const position of positions) {
      const item: IMascaraAnaliticaDefinition = this.definitionModel[position];
      if (item) {
        const index = this.definitionModel.findIndex((model: IMascaraAnaliticaDefinition) => model.char === item.char);
        if (index !== -1) {
          this.definitionModel[index].isIndice = true;
        }
      }
    }

    this._handleListNames();

    // Init nameModel (manalPocNomes)
    this._initNameModel();
  }

  private _orderModel(): void {
    // Since only first node of group can be dragged, we have to reorder list
    const checked = [];
    for (let i = 0; i < this.definitionModel.length; i++) {
      let groupChar;
      let lastGroupCharIndex = -1;
      let didSkip = false;
      for (let j = i; j < this.definitionModel.length; j++) {
        const item = this.definitionModel[j];
        const char = item.char;
        if (checked.includes(char)) {
          continue;
        }
        if (!groupChar) {
          groupChar = char;
        }
        if (char === groupChar) {
          if (!didSkip) {
            lastGroupCharIndex = j;
          } else if (lastGroupCharIndex !== -1) {
            moveItemInArray(this.definitionModel, j, lastGroupCharIndex);
          }
        } else {
          didSkip = true;
        }
      }
      if (groupChar) {
        checked.push(groupChar);
      }
    }
    this._toMascara();
  }

  private _evaluateAdd(value: IMascaraAnaliticaDefinition, targetIndex: number = this.definitionModel.length - 1): boolean {
    if (!isObject(value)) {
      return false;
    }
    if (value.isConstant || targetIndex === -1) {
      return true;
    }
    const index: number = findLastIndex(this.definitionModel, (modelItem: IMascaraAnaliticaDefinition) => modelItem.char === value.char);
    if (index !== -1) {
      if (!this._evaluateDefinitionSameSection(value, targetIndex)) {
        this._plAlertService.error('mascarasanalitica.errorAdd');
        return false;
      }
    }
    return true;
  }

  private _evaluateDefinitionSameSection(value: IMascaraAnaliticaDefinition, targetIndex: number): boolean {
    const previous: IMascaraAnaliticaDefinition = this.definitionModel[targetIndex - 1];
    const current: IMascaraAnaliticaDefinition = this.definitionModel[targetIndex];
    const next: IMascaraAnaliticaDefinition = this.definitionModel[targetIndex + 1];
    return (previous && previous.char === value.char) || (current && current.char === value.char) || (next && next.char === value.char);
  }

  private _handleListNames(): void {
    const checked: Array<string> = [];
    this.listNames = [];
    for (let index = 0; index < this._possibleListNames.length; index++) {
      const listName = this._possibleListNames[index];
      if (index === 0 || (this.model.mascara.includes(listName.char) && !checked.includes(listName.char))) {
        this.listNames.push({
          char: listName.char,
          content: listName.name,
          idManal: this.model.manalID,
          type: EMascaraAnaliticaListNameType.FIELD,
          nomeEstatico: '',
          tipoCampo: index,
          manalPocNomesID: undefined,
          indice: undefined
        });
        checked.push(listName.char);
      }
    }
    this.listNames.unshift({
      char: undefined,
      content: EMascaraAnaliticaListNameType.TEXT,
      idManal: this.model.manalID,
      type: EMascaraAnaliticaListNameType.TEXT,
      nomeEstatico: '',
      tipoCampo: -1,
      manalPocNomesID: undefined,
      indice: undefined
    });
  }

  private _initNameModel(): void {
    for (let index = 0; index < this.model.manalPocNomes.length; index++) {
      const manalPocNome = this.model.manalPocNomes[index];
      this.nameModel.push({
        ...manalPocNome,
        char: manalPocNome.tipoCampo !== -1 ? this._possibleListNames[manalPocNome.tipoCampo].char : undefined,
        content: manalPocNome.tipoCampo !== -1 ? this._possibleListNames[manalPocNome.tipoCampo].name : manalPocNome.nomeEstatico,
        idManal: manalPocNome.idManal,
        type: manalPocNome.tipoCampo !== -1 ? EMascaraAnaliticaListNameType.FIELD : EMascaraAnaliticaListNameType.TEXT,
        nomeEstatico: manalPocNome.nomeEstatico,
        tipoCampo: manalPocNome.tipoCampo,
        indice: index
      });
    }
  }

  private _reIndexNameModel(): void {
    for (let i = 0; i < this.nameModel.length; i++) {
      this.nameModel[i].indice = i;
    }
  }

  private _orderNameModel(): void {
    let maxOrder = -1;
    const modelOrder: Map<string, number> = new Map<string, number>();
    for (let i = 0; i < this.definitionModel.length; i++) {
      const item = this.definitionModel[i];
      if (!modelOrder.has(item.char)) {
        modelOrder.set(item.char, i);
        if (i > maxOrder) {
          maxOrder = i + 1;
        }
      }
    }
    // `Nome da Conta Geral de Origem` is always last
    modelOrder.set(' ', maxOrder + 1);
    this.nameModel.sort((a: IMascaraAnaliticaPocNomes, b: IMascaraAnaliticaPocNomes) => {
      if (a.type === EMascaraAnaliticaListNameType.TEXT || b.type === EMascaraAnaliticaListNameType.TEXT) {
        return 0;
      }
      const orderA: number = modelOrder.get(a.char);
      const orderB: number = modelOrder.get(b.char);
      if (!isNumber(orderA) || !isNumber(orderB)) {
        return 0;
      }
      return orderA - orderB;
    });
  }
}
