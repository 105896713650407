import {HttpResponse} from '@angular/common/http';
import {Component, Injector, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {copy, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGridEventOnCellClick} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IJsonConfigTransfArmazem} from '../../jsonTransferenciasArmazens.entity.interface';
import {TransferenciasArmazensConfigModalComponent} from '../../modals/config/transferenciasArmazens.config.modal.component';
import {IDocTransfArmazemCabWithData} from '../../transferenciasArmazens.entity.interface';
import {TransferenciasArmazensService} from '../../transferenciasArmazens.entity.service';

@Component({
  selector: 'transferencias-armazens-list',
  templateUrl: './transferenciasArmazens.entity.list.component.html'
})
export class TransferenciasArmazensListComponent extends ModuloEntityListComponent<IDocTransfArmazemCabWithData> implements OnInit {
  private _configTransfArmazem: IJsonConfigTransfArmazem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _transferenciasArmazensService: TransferenciasArmazensService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this._transferenciasArmazensService.getConfiguracoesTransfArmazens().then((response: HttpResponse<IJsonConfigTransfArmazem>) => {
      this._configTransfArmazem = response.body;
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'config',
      order: 101,
      type: 'button',
      iconLeft: '<i class="fa fa-cog fa-fw"></i>',
      class: 'btn-light',
      caption: 'global.btn.config',
      click: () => this._configModal()
    });
  }

  public onDetail({data}: IDevExpressDataGridEventOnCellClick<IDocTransfArmazemCabWithData>): Promise<void> {
    return this._transferenciasArmazensService.getDocDetail(data.nDocfaOrigem, data.nNumerOrigem, data.nDocOrigem).then((response) => {
      data._doc = response.body;
    });
  }

  private _configModal(): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(TransferenciasArmazensConfigModalComponent);
    const componentInstance: TransferenciasArmazensConfigModalComponent = modalInstance.componentInstance;
    componentInstance.configTransfArmazem = copy(this._configTransfArmazem);
    return modalInstance.result.then(() => {
      this._plAlertService.success('transferenciasarmazens.messages.configGuardadasSucesso');
    });
  }
}
