import {ENTITY_NAME_PRH_LOCAIS} from './pRHLocais.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonPRHLocal} from './jsonPRHLocal.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_PRH_LOCAIS: IEntityDefinition<IJsonPRHLocal> = {
  name: ENTITY_NAME_PRH_LOCAIS,
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  searchPlaceholder: 'prhlocais.pesquisa',
  metadata: {
    keyName: 'codLocal',
    descriptionName: 'nome',
    fields: [
      {name: 'codLocal', width: '120px', caption: 'prhlocais.fields.codLocal', placeholder: 'prhlocais.fields.codLocalPlaceholder', validators: {required: true, min: 1}},
      {name: 'nome', caption: 'prhlocais.fields.nome', placeholder: 'prhlocais.fields.nomePlaceholder', validators: {required: true, maxlength: 50}}
    ],
    order: 'nome',
    searchFields: 'codLocal,nome'
  },
  autocomplete: {
    rowTemplate: '{{codLocal}} - {{nome}}',
    output: 'nome',
    searchFields: 'codLocal,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.prhlocais'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.prhlocais'
      }
    }
  }
};
