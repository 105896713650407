import {Injectable} from '@angular/core';
import moment, {Moment} from 'moment';
import {TDate} from '../../../common/dates';

@Injectable({
  providedIn: 'root'
})
export class DGEMPSService {
  public isDateDisabled(date: TDate, dtMotMov: TDate): boolean {
    const value: Moment = this.normalizeDataCadastro(date);
    const dataCadastro: Moment = this.normalizeDataCadastro(dtMotMov);
    return value.isSameOrBefore(dataCadastro, 'day');
  }

  public isDateMarked(date: TDate, datasDadosHistoricos: Array<string>): boolean {
    const normalizedDate: Moment = this.normalizeDataCadastro(date);
    return datasDadosHistoricos.includes(normalizedDate.toISOString());
  }

  public normalizeDataCadastro(value: TDate): Moment {
    return moment(value).startOf('day');
  }
}
