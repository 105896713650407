import {Component, Injector, Input} from '@angular/core';
import {KEYCODES} from 'pl-comps-angular';
import {DocsComerciaisMeiosPagamentoService} from '../../components/meiospagamento/docsComerciais.meiosPagamento.service';
import {DocsComerciaisModalComponent} from '../docsComerciais.modal.component';
import {focusElement} from '../../../../../common/utils/element.utils';
import {IDocsComerciaisMeiosPagamentoEvtChangedStatus, IDocsComerciaisMeiosPagamentoEvtMeioValorKeydown} from '../../components/meiospagamento/docsComerciais.meiosPagamento.component.interface';

@Component({
  selector: 'documento-meiosPagamento-modal',
  templateUrl: './documento.meiosPagamento.modal.html'
})
export class DocumentoMeiosPagamentoModalComponent extends DocsComerciaisModalComponent {
  @Input() public codMoeda: number;
  @Input() public abreviaturaMoeda: string;
  @Input() public emEdicao: boolean;

  public canSave: boolean;

  private _totalEntregue: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _docsComerciaisMeiosPagamentoService: DocsComerciaisMeiosPagamentoService
  ) {
    super(_injector);
  }

  public close(): void {
    const result: boolean = this._docsComerciaisMeiosPagamentoService.evaluateSaveMeiosPagamento({
      doc: this.doc,
      codMoeda: this.codMoeda,
      totalEntregue: this._totalEntregue,
      canSave: this.canSave
    });
    if (result) {
      super.close(this.doc);
    }
  }

  public changedStatus({totalEntregue, canSave}: IDocsComerciaisMeiosPagamentoEvtChangedStatus): void {
    this._totalEntregue = totalEntregue;
    this.canSave = canSave;
  }

  public meioValorKeydown({event, meioPagamentoIndex}: IDocsComerciaisMeiosPagamentoEvtMeioValorKeydown): void {
    if (event.key === KEYCODES.ENTER && meioPagamentoIndex === this.doc.meiosPagamento.length - 1) {
      const button: HTMLElement = this._element.querySelector('.action-submit');
      focusElement(button);
    }
  }
}
