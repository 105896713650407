<div class="rh-estatisticas">
  <pl-tabs [theme]="'style3'" [(activeId)]="activeTab" [destroyOnHide]="false" (activeIdChange)="activeIdChanged($event)">
    <pl-tab [id]="tabIdPorEmpresa" caption="rhEstatisticas.porEmpresa">
      <div *plTabContent>
        <rh-estatisticas-por-empresa [nEmpresa]="nEmpresa" [callback]="porEmpresaCallback"></rh-estatisticas-por-empresa>
      </div>
    </pl-tab>

    <pl-tab [id]="tabIdMultiEmpresa" caption="rhEstatisticas.multiEmpresa">
      <div *plTabContent>
        <cg-card-panel #cardPanelMulti="cgCardPanel" [caption]="'global.text.searchfilter'">
          <div *cgCardPanelContent>
            <pl-form class="px-2 py-1" formInstanceName="formSearch" (formInstanceChange)="formSearch = $event">
              <pl-group>
                <pl-group>
                  <label [translate]="'global.text.year'"></label>
                  <edit>
                    <pl-autocomplete [source]="anosList" [(model)]="multiFilters.ano" [allowInvalid]="false" [properties]="{allowEmpty: false, disallowClear: true}"></pl-autocomplete>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'global.text.month'"></label>
                  <edit>
                    <pl-edit type="meses" attrName="mes" [(model)]="multiFilters.mes" [properties]="{disallowClear: true}"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group class="align-self-end">
                  <edit>
                    <pl-button klass="btn-sm btn-primary action-register-value" [onClick]="fnPesquisar"
                      ><i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
                    </pl-button>
                  </edit>
                </pl-group>
              </pl-group>
            </pl-form>
          </div>
        </cg-card-panel>

        <dx-data-grid
          cgDxDataGridInstanceName="rhEstatisticasMultiDataGridV2"
          [cgDxDataGrid]="dataGridMultiDefinition"
          [dataSource]="dataGridMultiDefinition.dataSource"
          (onInitialized)="onDataGridMultiInitialized($event)">
          <div *dxTemplate="let item of 'cellTemplateActions'">
            <pl-button klass="btn btn-xs btn-primary" [onClick]="fnViewMultiEmpresa(item.data.nEmpresa, item.data.nome)">
              <i class="fa fa-fw fa-bar-chart" aria-hidden="true"></i>
            </pl-button>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
