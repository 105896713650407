import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ENTITY_NAME_CONTTRABMOTIVO} from './contTrabMotivo.entity.interface';
import {IJsonContTrabMotivo} from './jsonContTrabMotivo.entity.interface';

export const ENTITY_CONTTRABMOTIVO: IEntityDefinition<IJsonContTrabMotivo> = {
  name: ENTITY_NAME_CONTTRABMOTIVO,
  roles: [ROLE.RH],
  searchPlaceholder: 'conttrabmotivo.pesquisa',
  metadata: {
    keyName: 'codContTrabMotivo',
    fields: [
      {
        name: 'codContTrabMotivo',
        type: 'text',
        caption: 'conttrabmotivo.fields.codContTrabMotivo',
        placeholder: 'conttrabmotivo.fields.codContTrabMotivo',
        validators: {required: {value: true}, maxlength: 4}
      },
      {name: 'descrBreve', type: 'text', caption: 'conttrabmotivo.fields.descrBreve', placeholder: 'conttrabmotivo.fields.descrBreve', validators: {required: {value: true}}},
      {name: 'descrCompleta', type: 'textarea', caption: 'conttrabmotivo.fields.descrCompleta', placeholder: 'conttrabmotivo.fields.descrCompleta', properties: {style: {wordBreak: true}}},
      {name: 'propriedadeMotivo', type: 'motivoContrato', caption: 'conttrabmotivo.fields.propriedadeMotivo', placeholder: 'conttrabmotivo.fields.propriedadeMotivo'}
    ],
    order: 'codContTrabMotivo',
    listFields: 'codContTrabMotivo,descrBreve,propriedadeMotivo'
  },
  autocomplete: {
    rowTemplate: '{{codContTrabMotivo}} - {{descrBreve}}',
    output: 'codContTrabMotivo',
    searchFields: 'codContTrabMotivo,descrBreve,descrCompleta'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  }
};
