<div class="erp-check-module">
  <h4 class="erp-check-module-title">
    <span class="pe-2" *ngIf="state === states.GenerateDefaultData || state === states.Validating || state === states.Repair">
      <i class="fa fa-cog fa-spin"></i>
    </span>
    <span [translate]="state"></span>
    <span *ngIf="state === states.Validating || state === states.Repair">: {{ activeItem.description }}</span>
  </h4>

  <div class="erp-check-module-configs mt-2">
    <div class="erp-check-module-config" *ngFor="let item of list; last as last" [class.mb-1]="!last">
      <button type="button" class="btn btn-link m-0 p-0 erp-check-module-config-title" (click)="clickedItem(item)">
        <i
          class="fa fa-fw"
          [ngClass]="{
            'fa-circle': !item._state,
            'fa-cog fa-spin': item._state === itemStates.Working,
            'fa-times text-danger': item._state === itemStates.Error,
            'fa-check text-success': item._state === itemStates.Success
          }"></i>
        <span class="erp-check-module-config-title-description">{{ item.description }}</span>
        <span *ngIf="item._state === itemStates.Error">
          <i class="fa fa-fw fa-angle-right" [plAnimationRotate]="item._showDetail"></i>
        </span>
      </button>

      <pl-animation-collapse [collapsed]="!item._showDetail">
        <div class="well">
          <div class="font-monospace">
            <span class="text-danger erp-check-module-config-error" *ngIf="item.error">{{ item.error }}</span>
            <button
              type="button"
              class="btn btn-link m-0 p-0 erp-check-module-config-validate-item"
              *ngIf="(state === states.Validate || state === states.Finished) && item._state !== itemStates.Working"
              [translate]="'configsERPCheck.actions.validate'"
              (click)="validateItem(item)"></button>
          </div>

          <div class="mb-2" *ngIf="item.repairHint">
            <strong><span [translate]="'configsERPCheck.text.correction'"></span>: </strong><span class="erp-check-module-config-repair-hint font-monospace">{{ item.repairHint }}</span>
          </div>

          <button
            type="button"
            class="btn btn-link m-0 mt-2 p-0 erp-check-module-config-repair-item"
            *ngIf="state !== states.Repair && item._state !== itemStates.PendingValidate && item.canRepair"
            [translate]="'configsERPCheck.actions.repair'"
            (click)="repairItem(item)"></button>
        </div>
      </pl-animation-collapse>
    </div>
  </div>
</div>
