import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {
  AfterContentChecked,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {UIRouterGlobals} from '@uirouter/core';
import {FOCUSABLE_QUERY_SELECTOR, isBoolean} from 'pl-comps-angular';
import {AuthService} from '../../../services/auth/auth.service';
import {CGCardPanelContentDirective} from './cardpanel.content.directive';
import {CGLocalStorageService} from '../../../services/storage/localstorage.service';
import {focusAndSelectElement} from '../../../../common/utils/element.utils';
import {ICGCardPanelSetCollapsedOptions} from './cardpanel.component.interface';
import {SCHEMA_BOOLEAN} from '../../../../common/schemas';
import {TUserSession} from '../../../services/account/jsonUserApi.interface';

@Component({
  selector: 'cg-card-panel',
  templateUrl: './cardpanel.component.html',
  exportAs: 'cgCardPanel'
})
export class CGCardPanelComponent implements OnInit, OnChanges, AfterContentChecked, AfterViewInit, OnDestroy {
  @Input() public collapsed: boolean;
  @Input() public caption: string;
  @Input() public instanceID: string;
  @Input() public hintThumbCardBody: string;
  @Input() public hideThumb: boolean;
  @Input() public autoFocus: boolean;
  @Output() public readonly collapsedChange: EventEmitter<boolean>;

  @ViewChild('elementBody') public readonly elementBody: ElementRef<HTMLElement>;
  public keyStoreInstance: string;
  public templateContent: CGCardPanelContentDirective;

  @ContentChildren(CGCardPanelContentDirective, {descendants: false}) private readonly _templateContent: QueryList<CGCardPanelContentDirective>;
  private _fixedCardBody: boolean;
  private _subscriptionZone: Subscription;

  constructor(
    private readonly _ngZone: NgZone,
    private readonly _cgLocalStorageService: CGLocalStorageService,
    private readonly _authService: AuthService,
    private readonly _uiRouterGlobals: UIRouterGlobals
  ) {
    this.collapsedChange = new EventEmitter<boolean>();
    this.caption = 'global.text.searchfilter';
    this.hintThumbCardBody = 'global.text.keeppanel';
    this.instanceID = '';
    this.hideThumb = false;
    this.autoFocus = true;
    this._fixedCardBody = false;
  }

  public ngOnInit(): void {
    if (!isBoolean(this.collapsed)) {
      this.setCollapsed(false, {focus: false});
    }
    if (!isBoolean(this.hideThumb)) {
      this.hideThumb = false;
    }
    if (!isBoolean(this.autoFocus)) {
      this.autoFocus = true;
    }
    if (!this.hideThumb) {
      this._generateStateStoreInstanceId().then((response: string) => {
        this.keyStoreInstance = this._generateKey(response);
        this._cgLocalStorageService.getItem(this.keyStoreInstance, SCHEMA_BOOLEAN).subscribe((cardThumb: boolean) => {
          this._fixedCardBody = cardThumb;
        });
      });
    } else {
      this._fixedCardBody = false;
    }
  }

  public ngOnChanges({collapsed}: SimpleChanges): void {
    if (collapsed && !collapsed.firstChange) {
      this.setCollapsed(collapsed.currentValue, {emitChange: false});
    }
  }

  public ngAfterContentChecked(): void {
    this.templateContent = this._templateContent.first;
  }

  public ngAfterViewInit(): void {
    if (!this.collapsed && this.autoFocus !== false) {
      this.focusFirstElement();
    }
  }

  public ngOnDestroy(): void {
    if (this._subscriptionZone) {
      this._subscriptionZone.unsubscribe();
    }
  }

  public setCollapsed(value: boolean, options?: ICGCardPanelSetCollapsedOptions): void {
    this.collapsed = value;
    const {emitChange, focus} = options || {};
    if (emitChange !== false) {
      this.collapsedChange.emit(this.collapsed);
    }
    if (focus !== false && !this.collapsed && this.autoFocus !== false) {
      this.focusFirstElement();
    }
  }

  public collapse(): void {
    if (!this.collapsed && !this._fixedCardBody) {
      this.setCollapsed(true);
    }
  }

  public expand(): void {
    if (this.collapsed) {
      this.setCollapsed(false);
    }
  }

  public toggleCollapse(): void {
    this.setCollapsed(!this.collapsed);
  }

  public toggleThumb(): void {
    this._setFixedThumb(!this._fixedCardBody);
    this._persistState();
  }

  public focusFirstElement(): void {
    if (this._subscriptionZone) {
      this._subscriptionZone.unsubscribe();
    }
    this._subscriptionZone = this._ngZone.onStable.pipe(take(1)).subscribe(() => {
      this._subscriptionZone = undefined;
      if (this.elementBody?.nativeElement) {
        const element: HTMLElement = this.elementBody.nativeElement;
        if (element) {
          const toFocus: HTMLElement = element.querySelector<HTMLElement>(FOCUSABLE_QUERY_SELECTOR);
          if (toFocus) {
            focusAndSelectElement(toFocus);
          }
        }
      }
    });
  }

  public get fixedCardBody(): boolean {
    return this._fixedCardBody;
  }

  private _generateKey(instanceId: string): string {
    return `cg_card_panel-${instanceId}-${this._uiRouterGlobals.current.name}`;
  }

  private async _generateStateStoreInstanceId(): Promise<string> {
    const session: TUserSession = await this._authService.identity();
    if (!session) {
      return '';
    }
    return this.instanceID ? `${session.userId}.${session.erp.nEmpresa}.${this.instanceID}` : `${session.userId}.${session.erp.nEmpresa}`;
  }

  private _setFixedThumb(value: boolean): void {
    this._fixedCardBody = value;
    this._persistState();
  }

  private _persistState(): void {
    this._cgLocalStorageService.setItem(this.keyStoreInstance, this._fixedCardBody, SCHEMA_BOOLEAN).subscribe();
  }
}
