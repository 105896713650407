import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../cg/modal/cgmodal.component';
import {ContabilidadeDigitalServiceDocuments} from '../../../../../../services/contabilidadedigital/contabilidadedigital.service.documents';
import {IArquivoDigitalDocViewerEvtConfigureToolbar} from '../../../../common/docviewer/arquivodigital.docviewer.component.interface';

const TOOLBAR_ID = 'contabilidade-digital-client-connect-inspectdoc-modal-toolbar';
let TOOLBAR_ID_COUNT = 0;

@Component({
  selector: 'contabilidade-digital-client-connect-inspectdoc-modal',
  templateUrl: './contabilidadedigital.clientconnect.inspectdocmodal.component.html'
})
export class ContabilidadeDigitalClientConnectInspectDocModalComponent extends CGModalComponent<void> {
  @Input() public documentsService: ContabilidadeDigitalServiceDocuments;
  @Input() public docID: string;

  public readonly toolbarInstanceId: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.toolbarInstanceId = `${TOOLBAR_ID}-${TOOLBAR_ID_COUNT++}`;
  }

  public configureToolbar(event: IArquivoDigitalDocViewerEvtConfigureToolbar): void {
    event.btnPrint.visible = true;
  }
}
