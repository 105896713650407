import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IPlEditComponentOptionsInput, IPlToolbarItem, IPlToolbarMenuItem, isEmpty, isNumber, KEYCODES, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {ConcilMovAbJustEmSerieContasModalComponent} from '../modal/justemseriecontas/concilMovAb.justEmSerieContas.modal.component';
import {ConcilMovAbModuleService} from '../concilMovAb.module.service';
import {ConcilMovAbProgressBarModalComponent} from '../modal/progressbar/concilMovAb.progressBar.modal.component';
import {EConcilMovAbStatus, IJsonConcilMovAb, IJsonConcilMovAbConta, IJsonConcilMovAbJobStatus} from '../jsonConcilMovAb.module.interface';
import {ETipoContaContabilidade} from '../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {ETipoRegimeIva} from '../../../../common/enums/empresa.enums';
import {IConcilMovAbNContaEmSerie, IConcilMovAbTotaisData, TFindContaMovimentoAction} from '../concilMovAb.module.interface';
import {IDevExpressDataGrid, IDevExpressDataGridCellTemplateData, IDevExpressDataGridEditCellTemplateData} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnKeyDown
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {round} from '../../../../common/utils/utils';
import {DATA_SOURCE_NAME_DEBITO_CREDITO, EDebitoCredito} from '../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {Subscription} from 'rxjs';
import {HookMatchCriteria, StateDeclaration, StateObject, Transition, TransitionService} from '@uirouter/core';
import {STATE_NAME_LOGIN} from '../../../states/account/login/login.state.interface';
import {STATE_NAME_DISCONNECTED} from '../../../states/account/disconnected/disconnected.state.interface';
import {isTest} from '../../../../config/constants';
import {ENTITY_NAME_MOEDA} from '../../portalcontabilidade/manutencao/moeda/moeda.entity.interface';

const INTERVAL_TIMEOUT = 1000;
const TOOLBAR_GROUP_RESPONSIVE = 'module-btns-responsive';

@Component({
  selector: 'module-concil-mov-ab',
  templateUrl: './concilMovAb.module.component.html'
})
export class ConcilMovAbModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonConcilMovAb, string>;
  public readonly dataGridTotais: IDevExpressDataGrid;
  public readonly filterMovimento: string;
  public readonly pocsOutput: string;
  public readonly propertiesInput: IPlEditComponentOptionsInput<unknown>;
  public readonly periodosRowTemplate: string = '{{periodo}} - {{nome}}';
  public conta: IJsonConcilMovAbConta;
  public totaisData: Array<IConcilMovAbTotaisData>;
  public periodoAte: string;

  private readonly _btnJustificaValores: IPlToolbarItem | IPlToolbarMenuItem;
  private readonly _btnJustificaValoresSerie: IPlToolbarItem | IPlToolbarMenuItem;
  private readonly _dropdownJustificar: IPlToolbarItem;
  private readonly _btnSave: IPlToolbarItem;
  private _status: IJsonConcilMovAbJobStatus;
  private _pendingPromises: number;
  private _importProgressModalRef: NgbModalRef;
  private _dataGridInstance: dxDataGrid<IJsonConcilMovAb, string>;
  private _selectedRowKey: string;
  private _intervalId: number;
  private _subscriptionWindowWidth: Subscription;
  private _deRegisterOnStartFn: Function;
  private _msStarted: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _concilMovAbService: ConcilMovAbModuleService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    protected readonly _transitionService: TransitionService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columns: [
        {dataField: 'periodo', caption: 'concilmovab.table.periodo', dataType: 'string', allowEditing: false},
        {dataField: 'nDiario', caption: 'concilmovab.table.nDiario', dataType: 'number', allowEditing: false},
        {dataField: 'nDoc', caption: 'concilmovab.table.nDoc', dataType: 'string', allowEditing: false},
        {dataField: 'nDocExterno', caption: 'concilmovab.table.nDocExterno', dataType: 'string', allowEditing: false, width: 150},
        {dataField: 'dataDocExt', caption: 'concilmovab.table.dataDocExt', dataType: 'date', allowEditing: false},
        {dataField: 'descricao', caption: 'concilmovab.table.descricao', dataType: 'string', allowEditing: false, width: 150},
        {
          dataField: 'isDocumentoOrigemRegimeDeIvaDeCaixa',
          caption: 'concilmovab.table.isDocumentoOrigemRegimeDeIvaDeCaixa',
          dataType: 'boolean',
          visible: this._configService.configurations.empresa.regimeIva === ETipoRegimeIva.RegimeIvaCaixa,
          allowEditing: false
        },
        {dataField: 'valor', caption: 'concilmovab.table.valor', dataType: 'double', allowEditing: false},
        {dataField: 'porPagar', caption: 'concilmovab.table.porPagar', dataType: 'double', allowEditing: false},
        {dataField: 'dc', caption: 'concilmovab.table.dc', dataType: 'string', allowEditing: false, lookup: {cgDataSource: DATA_SOURCE_NAME_DEBITO_CREDITO}},
        {
          dataField: 'aImputar',
          caption: 'concilmovab.table.aImputar',
          dataType: 'double',
          allowEditing: true,
          editCellTemplate: 'editCellTemplateAJustificar',
          showEditorAlways: true
        },
        {dataField: 'dataVenc', caption: 'concilmovab.table.dataVenc', dataType: 'date', allowEditing: false},
        {dataField: ENTITY_NAME_MOEDA, caption: 'concilmovab.table.moeda', dataType: 'string', allowEditing: false},
        {dataField: 'valorActualAImputar', caption: 'concilmovab.table.valorActualAImputar', dataType: 'double', visible: false},
        {dataField: 'montanteAReter', caption: 'concilmovab.table.montanteAReter', dataType: 'double', visible: false}
      ],
      dataSource: [],
      export: {filename: 'global.menu.concilmovab'},
      height: 550,
      headerFilter: {visible: true},
      columnHidingEnabled: false,
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      repaintChangesOnly: true,
      remoteOperations: false,
      keyExpr: 'nLanc',
      columnAutoWidth: true,
      editing: {
        allowDeleting: false,
        allowUpdating: true,
        mode: 'cell',
        selectTextOnEditStart: true,
        startEditAction: 'click'
      },
      showBorders: true,
      scrolling: {rowRenderingMode: 'virtual'},
      keyboardNavigation: {editOnKeyPress: false, enterKeyAction: 'moveFocus', enterKeyDirection: 'column'},
      toolbar: {
        items: ['exportButton', 'columnChooserButton']
      }
    };
    this.dataGridTotais = {
      columns: [
        {dataField: 'name', caption: '', dataType: 'string'},
        {dataField: 'debit', caption: 'global.debitCredit.debit', dataType: 'double'},
        {dataField: 'credit', caption: 'global.debitCredit.credit', dataType: 'double'},
        {dataField: 'saldo', caption: 'global.text.saldo', dataType: 'double'}
      ],
      export: {enabled: false},
      filterRow: {visible: false},
      headerFilter: {visible: false, search: {enabled: false}},
      showBorders: false,
      columnFixing: {enabled: false},
      groupPanel: {visible: false},
      grouping: {contextMenuEnabled: false},
      sorting: {mode: 'none'},
      columnChooser: {enabled: false}
    };
    this.propertiesInput = {modelOptions: {debounce: 500}};
    this.filterMovimento = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.pocsOutput = '{{nConta}} - {{nome}}';
    this.conta = {
      nConta: '',
      nome: '',
      concilMovAb: []
    };
    this.totaisData = [
      {
        name: this._translateService.instant('concilmovab.totals.valajustificar'),
        debit: 0,
        credit: 0,
        saldo: 0
      },
      {
        name: this._translateService.instant('concilmovab.totals.totconta'),
        debit: 0,
        credit: 0,
        saldo: 0
      }
    ];
    this._btnJustificaValores = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'justificarvalores',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-check fa-fw"></i>',
      class: 'btn-light',
      caption: 'concilmovab.btn.justvalauto',
      disabled: true,
      click: () => this._justificarValoresAuto()
    };
    this._btnJustificaValoresSerie = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'justificarvaloresserie',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-check-square-o fa-fw"></i>',
      class: 'btn-light',
      caption: 'concilmovab.btn.justvalautoserie',
      click: () => this._justificarValoresAutoEmSerie()
    };
    this._dropdownJustificar = {
      order: 3,
      id: 'dropdownJustificar',
      caption: 'concilmovab.btn.justDropdownTitle',
      type: 'dropdown',
      iconLeft: '<i class="fa fa-check fa-fw"></i>',
      visible: true,
      menu: [<IPlToolbarMenuItem>this._btnJustificaValores, <IPlToolbarMenuItem>this._btnJustificaValoresSerie]
    };
    this._btnSave = {
      id: 'save',
      order: 4,
      type: 'button',
      iconLeft: '<i class="fa fa-save fa-fw"></i>',
      class: 'btn-primary',
      caption: 'global.btn.save',
      click: () => this._justificacaoDeMovimentosAb()
    };
    this._selectedRowKey = '';
    this._pendingPromises = 0;
    this._msStarted = false;
    this.periodoAte = `${this._configService.configurations.empresa.anoEmCursoIRC + 1}121`;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._subscriptionWindowWidth = this._plDocumentService.isMobile().subscribe((mobile: boolean) => {
      this.toolbar.removeGroupId(TOOLBAR_GROUP_RESPONSIVE, false);
      this._dropdownJustificar.visible = mobile;
      if (!mobile) {
        this._btnJustificaValores.class = this._btnJustificaValoresSerie.class = 'btn btn-sm btn-light';
        this._btnJustificaValores.iconLeft = '<i class="fa fa-check fa-fw"></i>';
        this._btnJustificaValoresSerie.iconLeft = '<i class="fa fa-check-square-o fa-fw"></i>';
        this.toolbar.addButton(this._btnJustificaValores).addButton(this._btnJustificaValoresSerie);
      } else {
        this._btnJustificaValores.class = this._btnJustificaValores.iconLeft = undefined;
        this._btnJustificaValoresSerie.class = this._btnJustificaValoresSerie.iconLeft = undefined;
      }
    });
    this.toolbar.addButton(this._dropdownJustificar).addButton(this._btnSave);
    setTimeout(() => {
      this._registerOnStart();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscriptionWindowWidth) {
      this._subscriptionWindowWidth.unsubscribe();
    }
    this._deRegisterOnStart();
    this._concilMovAbService.justValAutoEmSerieStopJob();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonConcilMovAb, string>): void {
    this._dataGridInstance = component;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonConcilMovAb, string>): void {
    if (event.rowType === 'data') {
      event.cellElement.classList.add('text-danger');
    }
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
    this._calculateTotals();
  }

  public async onKeyDown({component, event}: IDevExpressDataGridEventOnKeyDown<IJsonConcilMovAb, string>): Promise<void> {
    if (event.key === KEYCODES.F9 || event.key === KEYCODES.ADD) {
      const items: Array<IJsonConcilMovAb> = component.getDataSource().items();
      for (const item of items) {
        if (item.nLanc === this._selectedRowKey) {
          item.aImputar = item.porPagar;
          component.saveEditData();
          break;
        }
      }
    }

    if (event.key === KEYCODES.ENTER || event.key === KEYCODES.UP || event.key === KEYCODES.DOWN || event.key === KEYCODES.F9 || event.key === KEYCODES.ADD) {
      const indexRow = component.getRowIndexByKey(this._selectedRowKey);
      if (isNumber(indexRow) && indexRow !== -1) {
        const newIndex = event.key === KEYCODES.UP ? indexRow - 1 : indexRow + 1;
        await component.selectRowsByIndexes([newIndex]);
        if (component.getSelectedRowsData().length) {
          this._selectedRowKey = component.getSelectedRowsData()[0].nLanc;

          const element: HTMLElement = component.getCellElement(newIndex, 'aImputar');
          component.focus(element);

          const inputName = `input[name=aImputar${newIndex}]`;
          const input: HTMLElement = element.querySelector(inputName);
          event.stopPropagation();
          if (input) {
            setTimeout(() => {
              input.focus();
            });
          }
        } else {
          this._selectedRowKey = '';
          if (event.key !== KEYCODES.UP || event.key !== KEYCODES.DOWN) {
            await this._justificacaoDeMovimentosAb();
          }
        }
      }
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonConcilMovAb, string>): void {
    if (event.rowType === 'data') {
      this._selectedRowKey = event.data.nLanc;
      event.component.selectRows([this._selectedRowKey], false);
    }
  }

  public aImputarChanged(cellInfo: IDevExpressDataGridEditCellTemplateData<IJsonConcilMovAb, string>, value: number): void {
    if (isNumber(value) && value > cellInfo.data.porPagar) {
      value = cellInfo.data.porPagar;
      this._plAlertService.error('concilmovab.messages.valimputnaopodsermaior');
    }
    cellInfo.setValue((cellInfo.data.aImputar = isNumber(value) ? value : 0), undefined);
    cellInfo.component.saveEditData();
  }

  public async justificarValorCell(cellInfo: IDevExpressDataGridCellTemplateData<IJsonConcilMovAb, string>): Promise<void> {
    cellInfo.value = cellInfo.data.aImputar = cellInfo.data.porPagar;
    await cellInfo.component.saveEditData();
  }

  public pesquisaListaMovimentos(): Promise<void> {
    if (isEmpty(this.conta.nConta)) {
      this.dataGridDefinition.dataSource = [];
      this._btnJustificaValores.disabled = true;
      return Promise.resolve();
    }
    this._dataGridInstance.beginCustomLoading(undefined);
    const promise: Promise<void> = this._concilMovAbService
      .getListaMovimentos(this.conta.nConta, this.periodoAte)
      .then((response: HttpResponse<Array<IJsonConcilMovAb>>) => {
        this.conta.concilMovAb = response.body;
        this.dataGridDefinition.dataSource = response.body;
        return this._dataGridInstance.refresh();
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
    this._addLoadingPromise(promise);
    return promise;
  }

  public findContaMovimento(action: TFindContaMovimentoAction): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    const promise: Promise<void> = this._concilMovAbService
      .findContaMovimento(this.conta.nConta, action, this.periodoAte)
      .then((response: HttpResponse<IJsonConcilMovAbConta>) => {
        this.conta = response.body;
        this.dataGridDefinition.dataSource = response.body.concilMovAb;
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
    this._addLoadingPromise(promise);
    return promise;
  }

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._appService.sendBeacon(this._concilMovAbService.justValAutoEmSerieStopJobUrl());
  }

  private _justificarValoresAuto(): Promise<void> {
    this._dataGridInstance.beginCustomLoading(undefined);
    const promise: Promise<void> = this._concilMovAbService
      .getJustificarValoresAuto(this.conta.nConta, this.periodoAte)
      .then((response: HttpResponse<Array<IJsonConcilMovAb>>) => {
        this.dataGridDefinition.dataSource = response.body;
        const index: number = response.body.findIndex((item: IJsonConcilMovAb) => item.aImputar && item.aImputar !== 0);
        if (index !== -1) {
          this._plAlertService.success('concilmovab.messages.valjustautosuccess');
        } else {
          this._plAlertService.info('concilmovab.messages.noValjustauto');
        }
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
    this._addLoadingPromise(promise);
    return promise;
  }

  private _justificarValoresAutoEmSerie(): Promise<void> {
    const promise: Promise<void> = this._cgModalService.show(ConcilMovAbJustEmSerieContasModalComponent).then(async ({nContaDe, nContaAte, periodoAte}: IConcilMovAbNContaEmSerie) => {
      this._dataGridInstance.beginCustomLoading(undefined);
      try {
        await this._concilMovAbService.getJustificarValoresAutoEmSerie(nContaDe, nContaAte, periodoAte);
        this._msStarted = true;
        this._startImportChecker();
        this._concilMovAbProgressModal('concilmovab.messages.aprepararjustvalautoserie');
        this.conta = {
          nConta: '',
          concilMovAb: [],
          nome: ''
        };
        this.dataGridDefinition.dataSource = [];
      } finally {
        this._dataGridInstance.endCustomLoading();
      }
    });
    this._addLoadingPromise(promise);
    return promise;
  }

  private async _justificacaoDeMovimentosAb(): Promise<void> {
    await this._cgModalService.showOkCancel('global.text.confirmation', 'concilmovab.messages.desejaefetuarjustmovi');
    this._calculateTotals();
    if (this.totaisData[0].saldo !== 0) {
      this._plAlertService.error('concilmovab.messages.saldomovinvalido');
      return;
    }
    const listaMovimentos: Array<IJsonConcilMovAb> = this._dataGridInstance?.getDataSource().items();
    const promise: Promise<void> = this._concilMovAbService
      .postJustificacaoDeMovimentosAb(this.conta.nConta, listaMovimentos)
      .then(() => {
        this._plAlertService.success('concilmovab.messages.savjustautosuccess');
        return this.findContaMovimento('next');
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
    this._addLoadingPromise(promise);
    await promise;
  }

  private _calculateTotals(): void {
    const listMovimentos: Array<IJsonConcilMovAb> = this._dataGridInstance?.getDataSource().items();
    let totDebitoConta = 0;
    let totCreditoConta = 0;
    let valorImputadoDebito = 0;
    let valorImputadoCredito = 0;

    for (const movimento of listMovimentos) {
      if (movimento.dc === EDebitoCredito.Debito) {
        totDebitoConta = totDebitoConta + movimento.valor - movimento.valorPago;
        valorImputadoDebito += movimento.aImputar;
      } else {
        totCreditoConta = totCreditoConta + movimento.valor - movimento.valorPago;
        valorImputadoCredito += movimento.aImputar;
      }
    }

    for (let i = 0; i < this.totaisData.length; i++) {
      this.totaisData[i].debit = round(i === 0 ? valorImputadoDebito : totDebitoConta);
      this.totaisData[i].credit = round(i === 0 ? valorImputadoCredito : totCreditoConta);
      this.totaisData[i].saldo = round(i === 0 ? valorImputadoDebito - valorImputadoCredito : totDebitoConta - totCreditoConta);
    }
  }

  private _startImportChecker(): void {
    this._clearImportChecker();
    this._intervalId = window.setInterval(() => {
      this._concilMovAbService.justValAutoEmSerieStatus().then((response: HttpResponse<IJsonConcilMovAbJobStatus>) => {
        this._status = response.body;
        if (this._status.state === EConcilMovAbStatus.Timeout) {
          this._clearImportChecker();
          this._cgModalService.showOkCancel('concilmovab.messages.jobTimeoutModalTitle', 'concilmovab.messages.jobTimeoutModalMessage', {
            size: 'md',
            showCancelBtn: false,
            backdrop: 'static',
            keyboard: false
          });
        } else if (this._status.state === EConcilMovAbStatus.Error) {
          this._clearImportChecker();
          this._plAlertService.error(this._status.description);
          this._closeImportProgressModal();
        } else if (this._status.state === EConcilMovAbStatus.Inactive) {
          this._clearImportChecker();
          this._closeImportProgressModal();
        } else if (this._status.state === EConcilMovAbStatus.Ended) {
          this._clearImportChecker(false);
          this._closeImportProgressModal();
          this._plAlertService.success('concilmovab.messages.successjustvalautoserie');
        } else {
          const message: string = isEmpty(this._status.description) ? 'concilmovab.messages.aefetuarjustvalautoserie' : this._status.description;
          this._concilMovAbProgressModal(message);
        }
      });
    }, INTERVAL_TIMEOUT);
  }

  private _clearImportChecker(closeModal: boolean = true): void {
    this._msStarted = false;
    if (isNumber(this._intervalId)) {
      window.clearTimeout(this._intervalId);
      this._intervalId = undefined;
    }
    if (closeModal) {
      this._closeImportProgressModal();
    }
  }

  private _closeImportProgressModal(): void {
    if (this._importProgressModalRef) {
      this._importProgressModalRef.dismiss();
      this._importProgressModalRef = undefined;
    }
  }

  private _concilMovAbProgressModal(description: string): void {
    if (this._importProgressModalRef) {
      return;
    }
    this._importProgressModalRef = this._cgModalService.showVanilla(ConcilMovAbProgressBarModalComponent, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
    const componentInstance: ConcilMovAbProgressBarModalComponent = this._importProgressModalRef.componentInstance;
    componentInstance.pbProcLabel = description;
  }

  private _addLoadingPromise(promise: Promise<unknown>): void {
    this._pendingPromises++;
    this._evaluateLoading();
    promise.finally(() => {
      this._pendingPromises--;
      this._evaluateLoading();
    });
  }

  private _evaluateLoading(): void {
    let loading: boolean = this._pendingPromises > 0;
    this._btnJustificaValores.disabled = loading;
    this._btnJustificaValoresSerie.disabled = loading;
    this._btnSave.disabled = loading;
    if (!loading) {
      loading = this.conta.concilMovAb.length === 0;
    }
    this._btnJustificaValores.disabled = loading;
  }

  private _registerOnStart(): void {
    this._deRegisterOnStart();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_LOGIN && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFn = this._transitionService.onStart(criteria, () => this._navigationSafeGuard());
  }

  private _deRegisterOnStart(): void {
    if (this._deRegisterOnStartFn) {
      this._deRegisterOnStartFn();
      this._deRegisterOnStartFn = undefined;
    }
  }

  private _navigationSafeGuard(): Promise<void> {
    if (!isTest()) {
      if (this._msStarted) {
        return this._cgModalService.showOkCancel('concilmovab.leavePromptTitle', 'concilmovab.leavePromptMessage', {
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          btnOkText: 'global.btn.yes',
          btnCancelText: 'global.btn.no'
        });
      }
    }
    return Promise.resolve();
  }
}
