import {EEntidadeBancariaProvider, IEntidadeBancariaAddNewAccountState} from '../../../interfaces/jsonEntidadeBancaria.interface';

export interface IStateEntidadeBancariaParams extends Omit<IEntidadeBancariaResult, 'provider'> {
  state: string | IEntidadeBancariaAddNewAccountState;
}

export interface IEntidadeBancariaResultParams {
  entidadeBancaria: IEntidadeBancariaResult;
}

export interface IEntidadeBancariaResult {
  provider: EEntidadeBancariaProvider;
  code: string;
  credentialsId: string;
  error: string;
  message: string;
  credentials: string;
}

export const STATE_NAME_TINK_LINK = 'tinklink';

export const STATE_URL_ENTIDADE_BANCARIA = STATE_NAME_TINK_LINK;
