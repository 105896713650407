import {Component} from '@angular/core';
import {ClientConnectService} from '../../../../services/clientconnect/clientconnect.service';
import {ClientConnectUIService} from '../../../../services/clientconnect/clientconnect.ui.service';
import {ContabilidadeDigitalDocumentosDigitaisModuleComponent} from '../components/contabDigital.documentosDigitais.module.component';
import {ContabilidadeDigitalService} from '../../../../services/contabilidadedigital/contabilidadedigital.service';
import {ContabilidadeDigitalUIService} from '../../../../services/contabilidadedigital/contabilidadedigital.ui.service';

@Component({
  selector: 'module-contabilidade-digital-documentos-digitais-client-connect',
  templateUrl: '../components/contabDigital.documentosDigitais.module.component.html',
  providers: [
    {provide: ContabilidadeDigitalService, useExisting: ClientConnectService},
    {provide: ContabilidadeDigitalUIService, useExisting: ClientConnectUIService}
  ]
})
export class ContabilidadeDigitalDocumentosDigitaisClientConnectModuleComponent extends ContabilidadeDigitalDocumentosDigitaisModuleComponent {}
