import {Component, Injector, Input, OnInit} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {RHQPIRCTService} from '../../rhQPIRCT.module.service';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonRHQPIRCT, IJsonRHQPIRCTImpCSV, IJsonRHQPIRCTImpQPIRCT} from '../../jsonRHQPIRCT.module.interface';
import {HttpResponse} from '@angular/common/http';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'modal-rhqpirct-imported-list',
  templateUrl: './rhQPIRCT.importedList.modal.component.html'
})
export class RHQPIRCTImportedListModalComponent extends CGModalComponent<Array<IJsonRHQPIRCT>> implements OnInit {
  @Input() public qpirctImp: IJsonRHQPIRCTImpQPIRCT;

  public readonly tableDefinition: IDevExpressDataGrid;
  public promise: Promise<void>;
  public originalCodIRCT: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _rhQPIRCTService: RHQPIRCTService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.tableDefinition = {
      columns: [
        {dataField: 'codIRCT', caption: 'rhqpirct.modal.table.codIRCT', dataType: 'string'},
        {dataField: 'nome', caption: 'rhqpirct.modal.table.nome', dataType: 'string'}
      ],
      dataSource: new CustomStore({
        load: () => this._getTableSource()
      }),
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false},
      headerFilter: {visible: false, search: {enabled: false}},
      showBorders: true,
      sorting: {mode: 'none'}
    };
  }

  public ngOnInit(): void {
    if (!this.qpirctImp) {
      this.qpirctImp = {
        listImpCSV: [],
        codIRCT: '',
        nome: '',
        alreadyExists: false
      };
    }
    this.originalCodIRCT = this.qpirctImp.codIRCT;
  }

  public close(): void {
    let promise: Promise<void>;
    if (this.qpirctImp.alreadyExists && this.qpirctImp.codIRCT === this.originalCodIRCT) {
      promise = this._cgModalService.showOkCancel('global.text.warning', 'rhqpirct.messages.irctexists');
    }

    this.promise = Promise.resolve(promise).then(() => {
      this._rhQPIRCTService.postSaveImportCSV(this.qpirctImp).then((response: HttpResponse<Array<IJsonRHQPIRCT>>) => {
        this._plAlertService.success('rhqpirct.messages.importedsucess');
        super.close(response.body);
      });
    });
  }

  private _getTableSource(): Array<IJsonRHQPIRCTImpCSV> {
    return this.qpirctImp.listImpCSV;
  }
}
