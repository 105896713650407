<form class="recaptcha-v2-modal" [formGroup]="formGroup">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'recaptcha.v2modal.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-recaptcha formControlName="recaptcha"></pl-recaptcha>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</form>
