<pl-form [(formInstance)]="form" [(ngForm)]="ngForm" [properties]="{orientation: 'horizontal'}" (evtSubmitted)="close()">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'docscontabilidade.changeregularizacaocampo40Title'"></h4>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'docscontabilidade.nDocumento'"></label>
      <edit>
        <pl-edit type="text" attrName="nDocumento" [(model)]="model.nDocumento" [properties]="{validators: {maxlength: {value: 60}}}" plAutoFocus #elementNDocumento></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'docscontabilidade.doc.cab.dataLancamento'"></label>
      <edit>
        <pl-edit type="date" attrName="dataLancamento" [(model)]="model.data"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'docscontabilidade.doc.linhas.valor'"></label>
      <edit>
        <pl-edit type="number" attrName="valor" [model]="model.valor" (modelChange)="valorChanged($event)" [properties]="{validators: {min: {value: 0}, max: {value: maxValue}}}"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'docscontabilidade.doc.linhas.valorTaxa'"></label>
      <edit>
        <pl-edit type="number" attrName="valorTaxa" [model]="model.valorIVA" (modelChange)="valorIVAChanged($event)" [properties]="{disabled: true}">
          <div *inputGroup="{klass: 'btn-light'}">
            <i class="fa fa-pencil" plDelegateEventDownwardsClick (click)="changeValorTaxa()" (keydown)="changeValorTaxa($event)" tabindex="0" plPromise></i>
          </div>
        </pl-edit>
      </edit>
      <actions>
        <pl-button klass="btn-primary" attrName="registar" (evtClicked)="registarValor()" data-focus><i class="fa fa-fw fa-check"></i><span [translate]="'global.btn.register'"></span></pl-button>
      </actions>
    </pl-group>

    <pl-group>
      <edit>
        <div class="text-error" *ngIf="ngForm?.submitted && !model.linhasRegularizacaoCampo40.length" [translate]="'docscontabilidade.changeTitleInvalidLines'"></div>
      </edit>
    </pl-group>

    <dx-data-grid
      class="mt-4"
      *ngIf="model.linhasRegularizacaoCampo40.length"
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="regularizacaesDoSujeitoPassivoDataGrid"
      [dataSource]="model.linhasRegularizacaoCampo40">
      <div *dxTemplate="let linha of 'actions'">
        <pl-button klass="btn-xs btn-danger action-remove-line" (evtClicked)="removeLine(linha.rowIndex)">
          <i class="fa fa-fw fa-trash-o"></i>
        </pl-button>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn-sm btn-primary action-save" #elementSubmit><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.apply'"></span></pl-button>
    <pl-button klass="btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</pl-form>
