import type {PDFDataRangeTransport} from 'pdfjs-dist';
import type {DocumentInitParameters} from 'pdfjs-dist/types/src/display/api';
import type {IPlToolbarInstance, IPlToolbarItem, IPlToolbarMenuItem} from '../toolbar/toolbar.interface';

export type TPlPdfSource = string | Blob | File | ArrayBuffer | Uint8Array | PDFDataRangeTransport | DocumentInitParameters;

export type TPlPdfZoomLevel = 'auto' | 'page-actual' | 'page-height' | 'page-fit' | 'page-width' | number;

// eslint-disable-next-line @typescript-eslint/naming-convention
export type IPlPdfSource = DocumentInitParameters;

export interface IPlPdfOptions {
  withCredentials: boolean;
}

export interface IPlPdfEventPageRendered {
  cssTransform: boolean;
  pageNumber: number;
  source: any;
  timestamp: number;
}

export interface IPlPdfCallback {
  zoom?(zoomLevel: TPlPdfZoomLevel): void;

  zoomIn?(): void;

  zoomOut?(): void;

  print?(): void;

  refresh?(refetch?: boolean): Promise<void>;

  updateSize?(): void;
}

export interface IPlPdfEvtConfigureToolbar {
  toolbarInstance: IPlToolbarInstance;
  mnuBtnPrevious: IPlToolbarMenuItem;
  mnuBtnNext: IPlToolbarMenuItem;
  mnuNav: IPlToolbarItem;
  mnuPages: IPlToolbarItem;
  mnuZoom: IPlToolbarItem;
  mnuBtnZoomIn: IPlToolbarMenuItem;
  mnuBtnZoomFit: IPlToolbarMenuItem;
  mnuBtnZoomOut: IPlToolbarMenuItem;
  groupZoom: IPlToolbarItem;
  btnPresentationMode: IPlToolbarItem;
  btnDownload: IPlToolbarItem;
  btnPrint: IPlToolbarItem;
}

export const PL_PDF_TOOLBAR_GROUP_ID = 'TOOLBAR_CGC_PDF';
export const PL_PDF_TOOLBAR_ORDER_BASE = 300;
export const PL_PDF_TOOLBAR_ORDER_STEP = 10;
export const PL_PDF_TOOLBAR_ID_GROUP_NAV = 'pdfNav';
export const PL_PDF_TOOLBAR_ID_BTN_PREVIOUS = 'pdfPrev';
export const PL_PDF_TOOLBAR_ID_BTN_NEXT = 'pdfNext';
export const PL_PDF_TOOLBAR_ID_PAGES = 'pdfPages';
export const PL_PDF_TOOLBAR_ID_MENU_ZOOM = 'pdfZoom';
export const PL_PDF_TOOLBAR_ID_GROUP_ZOOM = 'pdfGroupZoom';
export const PL_PDF_TOOLBAR_ID_BTN_ZOOM_IN = 'pdfZoomIn';
export const PL_PDF_TOOLBAR_ID_BTN_ZOOM_FIT = 'pdfZoomFit';
export const PL_PDF_TOOLBAR_ID_BTN_ZOOM_OUT = 'pdfZoomOut';
export const PL_PDF_TOOLBAR_ID_BTN_PRESENTATION_MODE = 'pdfPresentationMode';
export const PL_PDF_TOOLBAR_ID_BTN_DOWNLOAD = 'pdfDownload';
export const PL_PDF_TOOLBAR_ID_BTN_PRINT = 'pdfPrint';
