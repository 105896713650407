import {forkJoin, from, Observable, of, Subscriber} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {JSONSchema, StorageMap} from '@ngx-pwa/local-storage';
import {copy} from 'pl-comps-angular';
import {AuthService} from '../auth/auth.service';
import {CGLocalStorageService} from './localstorage.service';
import {EGroupName, storeName} from '../../../config/constants';
import {TUserSession} from '../account/jsonUserApi.interface';

@Injectable({
  providedIn: 'root'
})
export class CGLocalStorageGroupService {
  constructor(
    private readonly _storageMap: StorageMap,
    private readonly _localStorageService: CGLocalStorageService,
    private readonly _authService: AuthService
  ) {}

  public getItem<T = unknown>(key: string, schema: JSONSchema, groupName?: EGroupName): Observable<T> {
    return this._generateKey(key, groupName).pipe(mergeMap<string, Observable<T>>((generatedKey: string) => this._localStorageService.getItem<T>(generatedKey, schema)));
  }

  public setItem(key: string, data: unknown, schema: JSONSchema, groupName?: EGroupName): Observable<void> {
    let generatedKey: string;
    return this._generateKey(key, groupName)
      .pipe(
        map<string, unknown>((value: string) => {
          generatedKey = value;
          return this._localStorageService.transformData(copy(data));
        })
      )
      .pipe(
        mergeMap<unknown, Observable<void>>((transformedData) => {
          return this._localStorageService.setItem(generatedKey, transformedData, schema);
        })
      );
  }

  public removeItem(key: string, groupName?: EGroupName): Observable<void> {
    return this._generateKey(key, groupName).pipe(
      mergeMap<string, Observable<void>>((generatedKey: string) => {
        return this._localStorageService.removeItem(generatedKey);
      })
    );
  }

  public has(key: string, groupName?: EGroupName): Observable<boolean> {
    return this._generateKey(key, groupName).pipe(
      mergeMap<string, Observable<boolean>>((generatedKey: string) => {
        return this._localStorageService.has(generatedKey);
      })
    );
  }

  public clear(groupName?: EGroupName): Observable<void> {
    if (!groupName) {
      return this._localStorageService.clear();
    }
    return (
      this._storeName(groupName)
        // Transform observable of keys to array of keys
        .pipe(
          mergeMap<string, Observable<Array<string>>>((value: string) => {
            return new Observable<Array<string>>((subscriber: Subscriber<Array<string>>) => {
              const keys: Array<string> = [];
              this._storageMap.keys().subscribe({
                next: (key: string) => {
                  if (key.startsWith(value)) {
                    keys.push(key);
                  }
                },
                error: (error: unknown) => {
                  subscriber.error(error);
                },
                complete: () => {
                  subscriber.next(keys);
                  subscriber.complete();
                }
              });
            });
          })
        )
        // Try to remove each of the provided keys
        .pipe(
          mergeMap<Array<string>, Observable<Array<void>>>((keys: Array<string>) => {
            if (!keys.length) {
              return of([]);
            }
            return forkJoin(
              keys.map<Observable<void>>((key: string) => {
                return this.removeItem(key);
              })
            );
          })
        )
        .pipe(map(() => undefined))
    );
  }

  private _generateKey(key: string, groupName?: string): Observable<string> {
    if (!groupName) {
      return this._storeName(key);
    }
    return this._storeName(groupName).pipe(
      map((value: string) => {
        return `${value}.${key}`;
      })
    );
  }

  private _storeName(name: string): Observable<string> {
    return from(this._authService.identity()).pipe(map<TUserSession, string>((session: TUserSession) => storeName(session, name)));
  }
}
