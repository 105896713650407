<div class="gdocs">
  <div class="row">
    <div class="col-3" *ngIf="showTreeViewer">
      <tree-viewer [tree]="treeItem" (modelChange)="loadFolderFiles($event)"></tree-viewer>
    </div>
    <div [ngClass]="showTreeViewer ? 'col-9' : 'col-12'">
      <file-viewer
        [filesView]="fileItem"
        [urlPdf]="pdfUrl"
        [loading]="isLoading"
        [showList]="showList"
        [showBox]="showBox"
        [readOnly]="readOnly"
        (evtSelectedFile)="generateUrl($event)"
        (evtFileDelete)="deleteFile($event)"
        (evtFileUpload)="addFile($event)">
      </file-viewer>
    </div>
  </div>
</div>
