import {orderBy} from 'lodash-es';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {IPlValidator, IPlValidatorValidateParams, isEmpty, isFunction, isString, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {ContabilidadePredefinidosService} from '../../../service/contabilidade.predefinidos.module.service';
import {IPreDefinidoContab, IPreDefinidoContabLinha} from '../../../preDefinidosContab.module.interface';
import {
  IPreDefinidoContabExtraVariables,
  IPredefinidoContabFormulaDialogParams,
  IPreDefinidoContabHeaderVariables,
  IPreDefinidoContabOperator,
  IPreDefinidoContabVariable,
  TPreDefinidoContabFormulaTransformer
} from '../predefinidocontabformula.component.interface';
import {IPreDefinidoContabFormulaTableBodyDefinition} from '../../linhas/predefinidocontablinhas.component.interface';

const START_FORMULA = '=';
const START_GROUP = '{';
const END_GROUP = '}';

@Component({
  selector: 'predefinidocontabformula-dialog-modal',
  templateUrl: './predefinidocontabformula.dialog.modal.component.html'
})
export class PredefinidoContabFormulaDialogModalComponent extends CGModalComponent<string> implements OnInit {
  @Input() public formula: string;
  @Input() public params: IPredefinidoContabFormulaDialogParams;
  @Input() public complex: boolean;
  @Input() public predefinido: IPreDefinidoContab;
  @Input() public linhas: Array<IPreDefinidoContabLinha>;
  @Input() public selectedLine: IPreDefinidoContabLinha;
  @Input() public headings: Array<IPreDefinidoContabFormulaTableBodyDefinition>;
  @Input() public transformer: TPreDefinidoContabFormulaTransformer;

  public readonly operators: Array<IPreDefinidoContabOperator>;
  public readonly validatorFormula: IPlValidator<string>;
  public form: UntypedFormGroup;
  public variablesClass: string;
  public variables: Array<IPreDefinidoContabVariable>;
  public promise: Promise<void>;

  private readonly _headerVariables: IPreDefinidoContabHeaderVariables;
  private readonly _extraVariables: IPreDefinidoContabExtraVariables;
  private _items: Array<string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _predefinidosContabilidadeService: ContabilidadePredefinidosService
  ) {
    super(_injector);
    this.operators = [
      {caption: '+', click: this._fnClickOperator, operator: true},
      {caption: '-', click: this._fnClickOperator, operator: true},
      {caption: '/', click: this._fnClickOperator, operator: true},
      {caption: '*', click: this._fnClickOperator, operator: true},
      {caption: '7', click: this._fnClickOperator},
      {caption: '8', click: this._fnClickOperator},
      {caption: '9', click: this._fnClickOperator},
      {caption: '(', click: this._fnClickOperator},
      {caption: '4', click: this._fnClickOperator},
      {caption: '5', click: this._fnClickOperator},
      {caption: '6', click: this._fnClickOperator},
      {caption: ')', click: this._fnClickOperator},
      {caption: '1', click: this._fnClickOperator},
      {caption: '2', click: this._fnClickOperator},
      {caption: '3', click: this._fnClickOperator},
      {caption: '.', click: this._fnClickOperator, operator: true},
      {caption: '0', click: this._fnClickOperator},
      {caption: 'DEL', click: this._fnDeleteOperator},
      {caption: 'C', click: this._fnClearOperator}
    ];
    this.validatorFormula = {
      message: 'predefinidocontabformula.validator',
      validate: ({formControlValue}: IPlValidatorValidateParams<string>) => {
        if (!this.complex && !isEmpty(formControlValue)) {
          return true;
        }
        return this._items.length > 0;
      }
    };
    this.variables = [];
    this._headerVariables = {
      periodo: this._plTranslateService.translate('predefinidocontabformula.headerVariables.periodo'),
      nomePeriodo: this._plTranslateService.translate('predefinidocontabformula.headerVariables.nomePeriodo')
    };
    this._extraVariables = {
      valorPorPagar: this._plTranslateService.translate('predefinidocontabformula.extraVariables.valorPorPagar')
    };
    this._items = [];
  }

  public ngOnInit(): void {
    if (!isString(this.formula)) {
      this.formula = '';
    }
    this._loadFormula();
    if (!this.complex) {
      this.variablesClass = 'col-sm-12';
      this._buildSimpleVariables();
    } else {
      this.variablesClass = 'col-sm-7 col-md-8';
      this._buildComplexVariables();
    }
  }

  public close(): Promise<void> {
    if (this.form.valid || this.form.disabled) {
      const linha = this.selectedLine._index;
      const tipo = this.complex ? 0 : 1;
      const formula = this._validate(this.formula);
      return new Promise<void>((resolve, reject) => {
        this._predefinidosContabilidadeService
          .validarFormula(linha, tipo, formula, this.predefinido)
          .then(() => {
            resolve();
            super.close(this.formula);
          })
          .catch(reject);
      });
    }
    return Promise.resolve();
  }

  public readonly fnBlurFormula = (value: string): void => {
    this._blurFormula(value);
  };

  private _loadFormula(): void {
    if (!this.formula?.length) {
      this._items = [];
      return;
    }
    this._items = [];
    if (!this.formula.includes(START_GROUP)) {
      this._items = this.formula.split('');
      if (this._items[0] === START_FORMULA) {
        this._items.splice(0, 1);
      }
    } else {
      let value = '';
      let i = 0;
      if (this.formula.startsWith(START_FORMULA)) {
        i++;
      }
      for (i; i < this.formula.length; i++) {
        const char = this.formula[i];
        if (char !== START_GROUP && !value) {
          this._items.push(char);
        } else if (char !== END_GROUP) {
          value += char;
        } else {
          value += char;
          this._items.push(value);
          value = '';
        }
      }
    }
  }

  private _buildFormula(): void {
    let formula = START_FORMULA;
    formula += this._items.join('');
    this.formula = formula;
  }

  private _clickOperator(item: IPreDefinidoContabOperator): void {
    const lastItem = this._items[this._items.length - 1];
    const toCompare = this.operators.find((operator) => {
      return operator.caption === lastItem;
    });
    if ((item.operator && (toCompare?.operator || !this._items.length)) || (this.complex && item.caption.includes('{') && lastItem?.includes('{'))) {
      return;
    }
    this._items.push(item.caption);
    this._buildFormula();
  }

  private _deleteOperator(): void {
    this._items.splice(this._items.length - 1, 1);
    this._buildFormula();
  }

  private _clearOperator(): void {
    this._items = [];
    this._buildFormula();
  }

  private _addVariable(content: string): void {
    this.variables.push({
      caption: START_GROUP + content + END_GROUP,
      click: this._fnClickOperator
    });
  }

  private _orderVariables(): void {
    this.variables = orderBy(this.variables, 'caption');
  }

  private _buildComplexVariables(): void {
    const lines = this.predefinido.linhas.filter((line) => {
      return line !== this.selectedLine;
    });

    const hValor = this.headings.find((heading) => heading.name === 'valor');
    const hValorIva = this.headings.find((heading) => heading.name === 'valorIva');

    this.variables = [];
    for (const line of lines) {
      const i = String(line._index + 1);

      // valor && valorIva
      this._addVariable(hValor.header + i);
      this._addVariable(hValorIva.header + i);

      // valorPorPagar
      if (line.conta.tipoConta === 1) {
        this._addVariable(this._extraVariables.valorPorPagar + i);
      }
    }

    this._orderVariables();
  }

  private _buildSimpleVariables(): void {
    const lines = this.predefinido.linhas;
    const headings = this.headings.filter((heading) => heading.visible !== false);
    const orderSeparator = 'zz';

    for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
      const line = lines[lineIndex];
      const i = String(line._index + 1);

      for (let j = 1; j < headings.length; j++) {
        const heading = headings[j];
        if (heading.allowSimpleFormula && this.selectedLine._index === lineIndex) {
          continue;
        }
        this._addVariable(headings[j].header + i);
      }

      // valorPorPagar
      if (line.conta.tipoConta === 1) {
        this._addVariable(orderSeparator + this._extraVariables.valorPorPagar + i);
      }
    }

    // Variáveis cabeçalho
    for (const variable of Object.values(this._headerVariables)) {
      this._addVariable(orderSeparator + <string>variable);
    }

    this._orderVariables();

    for (const variable of this.variables) {
      variable.caption = variable.caption.replace(orderSeparator, '');
    }
  }

  private _validate(formula: string): string {
    if (isFunction(this.transformer)) {
      return this.transformer(formula, this.complex);
    }
    return formula;
  }

  private _blurFormula(value: string): void {
    if (value) {
      if (!value.startsWith(START_FORMULA)) {
        value = START_FORMULA + value;
      }
      if (!value.endsWith(' ')) {
        value += ' ';
      }
    }
    this.formula = value;
    this._loadFormula();
  }

  private readonly _fnClickOperator: (operator: IPreDefinidoContabOperator) => void = (operator: IPreDefinidoContabOperator) => {
    this._clickOperator(operator);
  };

  private readonly _fnDeleteOperator: () => void = () => {
    this._deleteOperator();
  };

  private readonly _fnClearOperator: () => void = () => {
    this._clearOperator();
  };
}
