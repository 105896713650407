import {Component, Injector, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonCCusto} from '../../jsonCCusto.entity.interface';

const NUMBER_10 = 10;
const REGEX_CCUSTO = new RegExp('\\[(C+)]');
const REGEX_GROUP_INDEX_CCUSTO = 1;

@Component({
  selector: 'ccustos-new',
  templateUrl: './cCustos.entity.new.component.html'
})
export class CCustosNewComponent extends ModuloEntityDetailComponent<IJsonCCusto> implements OnInit {
  @Input() public mascaraCC: string;

  public formInstance: UntypedFormGroup;
  public nccustoMaxLength: number;

  private _manalMode: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.nccustoMaxLength = NUMBER_10;
    this.model = {
      nCCusto: '',
      nome: '',
      encerrado: 0
    };
    this._manalMode = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const regCCusto: RegExpMatchArray = REGEX_CCUSTO.exec(this.mascaraCC);
    if (regCCusto?.length) {
      const ccusto: string = regCCusto[REGEX_GROUP_INDEX_CCUSTO];
      this.nccustoMaxLength = ccusto.length;
      this._manalMode = true;
    }
  }

  public nCCustoChanged(): void {
    if (this._manalMode) {
      const nZeros: number = this.nccustoMaxLength - this.model.nCCusto.length;
      if (nZeros > 0) {
        const nCCustoWithZeros: Array<string> = [this.model.nCCusto];
        for (let i = 0; i < nZeros; i++) {
          nCCustoWithZeros.unshift('0');
        }
        this.model.nCCusto = nCCustoWithZeros.join('');
      }
    }
  }
}
