export enum ETipoCategoriaDesc {
  SegGrupo,
  IRS,
  SegSocial,
  CGA,
  ImpostoSelo,
  Sindicato,
  Seguro,
  Falta,
  Ferias,
  Punicao,
  RetImpTerceiros,
  SubsidioRefeicao,
  Outra,
  SobretaxaExtra,
  ADSE,
  Penhora,
  FCT,
  FGCT,
  PenhoraPensaoAlimentos,
  IndemnizacaoFaltaAvisoPrevio
}

export const DATA_SOURCE_NAME_TIPOS_CATEGORIA_DESCONTOS = 'tiposCategoriaDesc';
