import {isArray, isElement, isEmpty} from '../../common/utilities/utilities';
import {PlTinyMCEEvents} from '../events/tinymce.events';
import type {TPlTinyMCEAllowedModelEvents, TPlTinyMCEModelEvents} from '../tinymce.interface';

export function getTinyMCE(): any | undefined {
  return (<any>window)?.tinymce;
}

export function parseStringProperty(property: keyof PlTinyMCEEvents | Array<keyof PlTinyMCEEvents>, defaultValue: Array<keyof PlTinyMCEEvents>): Array<keyof PlTinyMCEEvents> {
  if (typeof property === 'string') {
    return <Array<keyof PlTinyMCEEvents>>property.split(',').map((value: string) => value.trim());
  }
  if (isArray(property)) {
    return property;
  }
  return defaultValue;
}

export function isTextarea(element?: Element): element is HTMLTextAreaElement {
  return isElement(element) && element.tagName.toLowerCase() === 'textarea';
}

export function normalizePluginArray(plugins?: string | Array<string>): Array<string> {
  if (isEmpty(plugins)) {
    return [];
  }
  return isArray(plugins) ? plugins : plugins.split(' ');
}

export function mergePlugins(initPlugins: string | Array<string>, inputPlugins?: string | Array<string>): Array<string> {
  return normalizePluginArray(initPlugins).concat(normalizePluginArray(inputPlugins));
}

export function normalizeModelEvents(modelEvents: TPlTinyMCEModelEvents): string {
  const modelEventsAsArray: Array<string | TPlTinyMCEAllowedModelEvents> = isArray(modelEvents) ? <Array<TPlTinyMCEAllowedModelEvents>>modelEvents : modelEvents.split(' ');
  return modelEventsAsArray
    .filter((modelEvent: string | TPlTinyMCEAllowedModelEvents) => {
      return modelEvent === 'change' || modelEvent === 'input' || modelEvent === 'undo' || modelEvent === 'redo';
    })
    .join(' ');
}
