import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {IJsonApuraIva, IJsonApuraIvaDefaultFiltro, IJsonExecucaoApuraIva, IJsonPeriodoApuraIva} from '../modules/portalcontabilidade/manutencao/apuraiva/jsonApuraIva.module.interface';
import {IJsonDiario} from '../entities/diarios/jsonDiario.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {TServiceQueryResponse, TServiceResponse} from './api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ContabilidadeApuraIvaService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/apuraiva`;
  }

  public getData(periodo: string): TServiceResponse<IJsonApuraIva> {
    return this._apiService.get({url: `${this._path}/apuramento/${periodo}`});
  }

  public jaEfectuouApuramentoNoPeriodo(periodo: string): TServiceResponse<boolean> {
    return this._apiService.get<boolean>({url: `${this._path}/apuramento/${periodo}/apurado`});
  }

  public verificarExisteDocumentosPorIntegrar(periodo: string): TServiceResponse<boolean> {
    return this._apiService.get<boolean>({url: `${this._path}/apuramento/${periodo}/docsporintegrar`});
  }

  public novoApuramento(periodo: string, apuramento: IJsonApuraIva, diariosAEncerrar: Array<IJsonDiario>): TServiceResponse<IJsonExecucaoApuraIva> {
    return this._apiService.post<IJsonExecucaoApuraIva>({
      url: `${this._path}/apuramento`,
      body: {
        periodo: periodo,
        apuramento: apuramento,
        diariosAEncerrar: diariosAEncerrar,
        extPocCabID: undefined
      }
    });
  }

  public delete(periodo: string): TServiceResponse<void> {
    return this._apiService.delete({url: `${this._path}/apuramento/${periodo}`});
  }

  public periodosApuraIVA(): TServiceQueryResponse<IJsonPeriodoApuraIva> {
    return this._apiService.query<IJsonPeriodoApuraIva>({url: `${this._path}/periodos`});
  }

  public diariosConfigurados(): TServiceQueryResponse<IJsonDiario> {
    return this._apiService.query<IJsonDiario>({url: `${this._path}/periodos/diariosconfigurados`});
  }

  public getApuramentoIvaDefaultFiltro(): Promise<IJsonApuraIvaDefaultFiltro> {
    return this._apiService.get<IJsonApuraIvaDefaultFiltro>({url: `${this._path}/periodos/dadosdefaultfiltro`}).then((response: HttpResponse<IJsonApuraIvaDefaultFiltro>) => response.body);
  }
}
