import {NgModule} from '@angular/core';
import {PlPromisesDirective} from './promises.directive';

export * from './promises.service.interface';
export * from './promises.service';
export * from './promises.directive';

const DECLARATIONS = [
  PlPromisesDirective
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlPromisesModule {
}
