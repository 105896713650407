import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {PlUploadErrorHandler} from 'pl-comps-angular';
import {CGExceptionService} from '../core/components/exceptions/exceptions.service';
import {ICGExceptionError} from '../core/components/exceptions/exceptions.service.interface';

@Injectable()
export class FileUploaderErrorHandler extends PlUploadErrorHandler {
  constructor(private readonly _cgExceptionService: CGExceptionService) {
    super();
  }

  public parseError(httpErrorResponse: HttpErrorResponse): Promise<string> | string {
    const exception: ICGExceptionError = this._cgExceptionService.get(httpErrorResponse);
    return exception.message || httpErrorResponse.message;
  }
}
