import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_DESCONTO_PROPORCIONAL_ABDES, ETipoDescontoProporcionalABDES} from './tipoDescontoProporcionalABDES.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPOS_DESCONTO_PROPORCIONAL_ABDES: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_DESCONTO_PROPORCIONAL_ABDES,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoDescontoProporcionalABDES.NaoProcessa, name: 'tiposDescontoProporcionalABDES.data.naoProcessa'},
    {value: ETipoDescontoProporcionalABDES.PorQuantidade, name: 'tiposDescontoProporcionalABDES.data.porQuantidade'},
    {value: ETipoDescontoProporcionalABDES.PorNHorasDia, name: 'tiposDescontoProporcionalABDES.data.porNHorasDia'}
  ]
});
