import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonRHUnidades} from './jsonRHUnidades.entity.interface';
import {ENTITY_NAME_RHUNIDADES} from './rhUnidades.entity.interface';

export const ENTITY_RHUNIDADES: IEntityDefinition<IJsonRHUnidades> = {
  name: ENTITY_NAME_RHUNIDADES,
  roles: [ROLE.RH],
  pageTitle: 'global.menu.rhunidades',
  searchPlaceholder: 'rhunidades.pesquisa',
  metadata: {
    keyName: 'codUnidade',
    fields: [
      {name: 'codUnidade', type: 'cginteger', caption: 'rhunidades.fields.codUnidade', validators: {required: true}},
      {name: 'nome', type: 'text', caption: 'rhunidades.fields.nome', validators: {required: true}},
      {name: 'nomeAbreviado', type: 'text', caption: 'rhunidades.fields.nomeAbreviado'},
      {name: 'tipoUnidade', type: 'tipoUnidades', caption: 'rhunidades.fields.tipoUnidade'}
    ],
    order: 'codUnidade',
    searchFields: 'codUnidade,nome,nomeAbreviado',
    listFields: 'codUnidade,nome,nomeAbreviado'
  },
  autocomplete: {
    rowTemplate: '{{codUnidade}} - {{nome}}',
    output: 'codUnidade',
    searchFields: 'codUnidade,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  }
};
