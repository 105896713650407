<div class="exportacao-compras-module entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="fnPesquisar" plPromise>
        <pl-group>
          <pl-group>
            <label [translate]="'global.text.fromDate'"></label>
            <edit>
              <pl-edit type="date" attrName="dataDe" [(model)]="filters.datade"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit type="date" attrName="dataAte" [(model)]="filters.dataate"></pl-edit>
            </edit>
          </pl-group>
          <pl-group class="align-self-end">
            <edit>
              <pl-edit type="checkbox" attrName="documentosPorExportar" [(model)]="filters.documentosporexportar" [properties]="{label: 'exportacaoCompras.fields.documentosPorExportar'}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group class="align-self-end">
          <edit>
            <button type="submit" class="btn btn-sm btn-primary" name="pesquisar" id="btnPesquisar"><i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span></button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="exportacaocomprasgeofolia"
    [dataSource]="this.compras"
    [(selectedRowKeys)]="selectedRowKeys"
    (onInitialized)="onInitialized($event)"
    (onSelectionChanged)="onSelectionChanged()"
    (onContextMenuPreparing)="onContextMenuPreparing($event)">
  </dx-data-grid>
</div>
