import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';

import {PlAlertModule} from './alert/alert.module';
import {PlAnimationModule} from './animation/animation.module';
import {PlAutoFocusModule} from './autofocus/autofocus.module';
import {PlButtonModule} from './button/button.module';
import {PlCalendarModule} from './calendar/calendar.module';
import {PlCookiesConsentModule, PlRGPDModule} from './rgpd/rgpd.module';
import {PlDashboardModule} from './dashboard/dashboard.module';
import {PlDatepickerModule} from './datepicker/datepicker.module';
import {PlDelegateEventModule} from './delegateevent/delegateevent.module';
import {PlDynamicVisualsModule} from './dynamicvisuals/dynamicvisuals.module';
import {PlEditModule} from './edit/edit.module';
import {PlEventsModule} from './events/events.module';
import {PlFallbackSrcModule} from './fallbacksrc/fallback.src.module';
import {PlIconModule, provideIconPrefixes} from './icon/icon.module';
import {PlKeyboardModule} from './keyboard/keyboard.module';
import {PlLifecycleModule} from './lifecycle/lifecycle.module';
import {PlLoggerModule} from './logger/logger.module';
import {PlMediaDevicesModule} from './mediadevices/mediadevices.module';
import {PlModalModule} from './modal/modal.module';
import {PlNavbarModule} from './navbar/navbar.module';
import {PlNavigationModule} from './navigation/navigation.module';
import {PlNavigatorModule} from './navigator/navigator.module';
import {PlNavPillModule} from './navpill/navpill.module';
import {PlNavWizardModule} from './navwizard/navwizard.module';
import {PlPageWrapperModule} from './pagewrapper/pagewrapper.module';
import {PlPaginationModule} from './pagination/pagination.module';
import {PlPdfModule} from './pdf/pdf.module';
import {PlPipesModule} from './pipes/pipes.module';
import {PlProgressModule} from './progress/progress.module';
import {PlPromisesModule} from './promises/promises.module';
import {PlRecaptchaModule} from './recaptcha/recaptcha.module';
import {PlSchedulerModule} from './scheduler/scheduler.module';
import {PlScrollingModule} from './scrolling/scrolling.module';
import {PlSelectModule} from './select/select.module';
import {PlSidebarModule} from './sidebar/sidebar.module';
import {PlSpinnerModule} from './spinner/spinner.module';
import {PlSplitViewModule} from './splitview/splitview.module';
import {PlTableModule} from './table/table.module';
import {PlTabsModule} from './tabs/tabs.module';
import {PlTinyMCEModule} from './tinymce/tinymce.module';
import {CGCToastsModule} from './toasts/toasts.module';
import {PlToolbarModule} from './toolbar/toolbar.module';
import {PlTooltipModule} from './tooltip/tooltip.module';
import {PlTranslateModule} from './translate/translate.module';
import {PlUITreeDragModule} from './uitreedrag/uitreedrag.module';
import {PlUploadModule, provideUploadFileListPipeline} from './upload/upload.module';

const CG_MODULES = [
  PlAlertModule,
  PlAnimationModule,
  PlAutoFocusModule,
  PlButtonModule,
  PlCalendarModule,
  PlCookiesConsentModule,
  PlDashboardModule,
  PlDatepickerModule,
  PlDelegateEventModule,
  PlDynamicVisualsModule,
  PlEditModule,
  PlEventsModule,
  PlFallbackSrcModule,
  PlIconModule,
  PlKeyboardModule,
  PlLifecycleModule,
  PlLoggerModule,
  PlMediaDevicesModule,
  PlModalModule,
  PlNavbarModule,
  PlNavigationModule,
  PlNavigatorModule,
  PlNavPillModule,
  PlNavWizardModule,
  PlPageWrapperModule,
  PlPaginationModule,
  PlPdfModule,
  PlPipesModule,
  PlProgressModule,
  PlPromisesModule,
  PlRecaptchaModule,
  PlRGPDModule,
  PlSchedulerModule,
  PlScrollingModule,
  PlSelectModule,
  PlSidebarModule,
  PlSpinnerModule,
  PlSplitViewModule,
  PlTableModule,
  PlTabsModule,
  PlTinyMCEModule,
  CGCToastsModule,
  PlToolbarModule,
  PlTooltipModule,
  PlTranslateModule,
  PlUITreeDragModule,
  PlUploadModule
];

@NgModule({
  imports: CG_MODULES,
  exports: CG_MODULES
})
export class CGCompsModule {
  constructor(@Optional() @SkipSelf() parentModule?: CGCompsModule) {
    if (parentModule) {
      throw new Error('CGCompsModule is already loaded. Import it in the AppModule only');
    }
  }

  public static forRoot(): ModuleWithProviders<CGCompsModule> {
    return {
      ngModule: CGCompsModule,
      providers: [provideIconPrefixes(), provideUploadFileListPipeline()]
    };
  }
}
