import {
  IJsonAquisicaoContabAdicionaDoc,
  IJsonAquisicaoContabDoc,
  IJsonAquisicaoContabResAddDoc,
  IJsonAquisicaoContabResumo,
  IJsonAquisicaoPesquisaContab
} from './jsonAtivosAquisicaoContab.module.interface';
import {IJsonDocContabilidade} from '../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IJsonAtivos} from '../../../entities/ativos/interfaces/jsonAtivos.entity.interface';
import {IAquisicaoInvestEmCursoPorDocItem} from '../ativosaquisicaoinvestemcurso/ativosAquisicaoInvestEmCursoPorDoc.module.interface';
import {IContabDigitalGDocViewerRecolhaDoc} from '../../../services/contabilidadedigital/contabilidadedigital.interface';

export interface IAquisicaoAdicionaDocumento {
  listaContas: Array<IAquisicaoContabAdicionaDoc | IAquisicaoInvestEmCursoPorDocItem>;
  listaDocumentos: Array<IJsonAquisicaoContabDoc>;
  listaDocumentosAdicionar: Array<IJsonAquisicaoContabDoc>;
}

export interface IAquisicaoContabAdicionaDoc extends IJsonAquisicaoContabAdicionaDoc {
  _resumo?: IJsonAquisicaoContabResumo;
  _docContabilidade?: IJsonDocContabilidade;
  _contabDigitalDoc?: IContabDigitalGDocViewerRecolhaDoc;
}

export interface IContabAdicionaDocEListaAtivos {
  listaAtivos: Array<IJsonAtivos>;
  conta: IJsonAquisicaoContabAdicionaDoc;
}

export interface IAtivosAquisicaoContabParams {
  isfromconsistencia: boolean;
  doc: IJsonAquisicaoContabResAddDoc;
}

export interface IAtivoAquisicaoPesquisaContab extends IJsonAquisicaoPesquisaContab {
  _docContabilidade?: IJsonDocContabilidade;
  _contabDigitalDoc?: IContabDigitalGDocViewerRecolhaDoc;
}

export const MODULE_NAME_ATIVOS_AQUISICAO_CONTAB = 'ativosaquisicaocontab';
