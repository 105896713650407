import type {IPlLocale} from './locales.interface';

export enum ECGCDateType {
  DATE = 'date',
  DATETIME = 'datetime',
  TIME = 'time'
}

export type TPlLocales = Map<string, IPlLocale>;

export const CGC_GLOBAL_EVENT_CHANGED_LOCALE = 'plLocale:appliedLocale';
