import {Component, Injector, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../components/module/module.component';

@Component({
  selector: 'module-trdocs-cabs-wizard',
  templateUrl: './trDocsCabsWizard.module.component.html'
})
export class TrDocsCabsWizardModuleComponent extends ModuloComponent implements OnInit {
  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }
}
