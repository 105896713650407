import moment from 'moment';
import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isDefined} from 'pl-comps-angular';
import {ApiService} from '../../../../services/api/api.service';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IDocContabilidade, IDocsContabilidadeEntityService} from '../../docscontabilidade/docsContabilidade.interface';
import {IEFaturaDocDetailsWithCab, IEFaturaTipoFatura} from './importadorEfatura.service.interface';
import {
  IImportadorDateFilters,
  IImportadorDocContabilidade,
  IImportadorEFaturaDocumento,
  IImportadorEfaturaFilterTipo,
  IImportadorEFaturaLastDocItem,
  IImportadorEFaturaSimplifiedAtItem,
  IImportadorEFaturaSource
} from '../importadorEfatura.module.interface';
import {IJsonDocContabilidade} from '../../docscontabilidade/jsonDocContabilidade.interface';
import {TDate} from '../../../../../common/dates';
import {TServiceResponse} from '../../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ImportadorEfaturaService {
  private readonly _path: string;
  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this._path = `${this._apiService.path.restapi}/importadorEFaturas`;
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>('docscontabilidade');
  }

  public getEFaturaDocs(deData: TDate, ateData: TDate, tipo?: IImportadorEfaturaFilterTipo, nifComerciante?: string): TServiceResponse<IImportadorEFaturaSource> {
    const params = {
      deData: deData,
      ateData: ateData,
      tipo: undefined,
      nifComerciante: undefined
    };
    if (isDefined(tipo)) {
      params.tipo = tipo.id;
    }
    if (isDefined(nifComerciante) && nifComerciante.length) {
      params.nifComerciante = nifComerciante;
    }
    return this._apiService.get<IImportadorEFaturaSource>({url: this._path, params: params, reportExceptions: false});
  }

  public getAtTiposList(): TServiceResponse<Array<IEFaturaTipoFatura>> {
    return this._apiService.get<Array<IEFaturaTipoFatura>>({url: `${this._path}/tiposList`});
  }

  public getDocDetails(ndoc: string, dataEmissaoDocumento: TDate): TServiceResponse<IEFaturaDocDetailsWithCab> {
    return this._apiService.get({
      url: `${this._path}/details/${ndoc}`,
      params: {
        dataEmissaoDocumento: moment(dataEmissaoDocumento).format('YYYY-MM-DD')
      }
    });
  }

  public getLastDocs(): TServiceResponse<Array<IImportadorEFaturaLastDocItem>> {
    return this._apiService.get({url: `${this._path}/lastDocs`});
  }

  public getDocContabilidade(extPocCabID: string, ano?: number): TServiceResponse<IDocContabilidade> {
    return this._serviceDocsContabilidade.get({id: extPocCabID, params: {ano: ano || -1}});
  }

  public getDocContabilidadePredefinido(item: IImportadorEFaturaSimplifiedAtItem, forceUseGenericPredefinido: boolean = false): TServiceResponse<IImportadorDocContabilidade> {
    return this._apiService
      .post<IImportadorDocContabilidade, IImportadorEFaturaSimplifiedAtItem>({
        url: `${this._path}/docContabilidade`,
        body: item,
        params: {
          forceusegenericpredefinido: forceUseGenericPredefinido
        }
      })
      .then((response: HttpResponse<IImportadorDocContabilidade>) => {
        if (response.body.doc.predefinido && !response.body.doc.predefinido.preDefinidosID) {
          response.body.doc.predefinido.preDefinidosID = 0;
        }
        return response;
      });
  }

  public getDatasSegundoNPeriodo(nperiodo: string, onlyPeriodMonth: boolean = false): Promise<IImportadorDateFilters> {
    return this._apiService.get<IImportadorDateFilters>({url: `${this._path}/${nperiodo}/getDatasSegundoNPeriodo`, params: {onlyPeriodMonth: onlyPeriodMonth}}).then((response) => response.body);
  }

  public lancarDocsEmSerie(list: Array<IImportadorEFaturaDocumento>, contabilidadeDigital: boolean = false): Promise<Array<IImportadorEFaturaDocumento>> {
    return this._apiService
      .put<Array<IImportadorEFaturaDocumento>>({
        url: `${this._path}/lancarDocsEmSerie`,
        body: list,
        params: {contabilidadedigital: contabilidadeDigital}
      })
      .then((response: HttpResponse<Array<IImportadorEFaturaDocumento>>) => response.body);
  }

  public reprocessEFaturaDoc(doc: IImportadorEFaturaDocumento): TServiceResponse<IImportadorEFaturaDocumento> {
    return this._apiService.post<IImportadorEFaturaDocumento>({
      url: `${this._path}/reprocessEfaturaDoc`,
      body: doc
    });
  }
}
