import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IJsonAdiantamentoConfig, IJsonAdiantamentoReg, IJsonAdiantamentosRegisto} from './jsonAdiantamentos.module.interface';
import {IJsonBoolean, IJsonNumber} from '../../../common/interfaces/json';
import {IAdiantamentosParams, IHeaderAdiantamentos} from './adiantamentos.module.interface';
import {Observable} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../common/utils/utils';
import {IDocComercialLinha} from '../../entities/docscomerciais/docsComerciais.entity.interface';

@Injectable({
  providedIn: 'root'
})
export class AdiantamentosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/adiantamentos`;
  }

  public printAdiantamentosUrl(headerAdiantamentos: IHeaderAdiantamentos, porRegularizar: boolean, modoRegularizacao: boolean, reportName: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report`, {
      ncontade: headerAdiantamentos.clifoDe,
      ncontaate: headerAdiantamentos.clifoAte,
      datadocde: headerAdiantamentos.dataDe,
      datadocate: headerAdiantamentos.dataAte,
      modefunc: headerAdiantamentos.modeFunc,
      dataref: headerAdiantamentos.dataRef,
      porregularizar: porRegularizar,
      modoregularizacao: modoRegularizacao,
      reportname: reportName
    });
  }

  public adiantamentosConfig(): TServiceResponse<IJsonAdiantamentoConfig> {
    return this._apiService.get<IJsonAdiantamentoConfig>({
      url: `${this._path}/config`
    });
  }

  public loadAdiantamentos(headerAdiantamentos: IHeaderAdiantamentos, porRegularizar: boolean, modoRegularizacao: boolean): TServiceResponse<Array<IJsonAdiantamentoReg>> {
    return this._apiService.get<Array<IJsonAdiantamentoReg>>({
      url: `${this._path}/porregularizar`,
      params: {
        ncontade: headerAdiantamentos.clifoDe,
        ncontaate: headerAdiantamentos.clifoAte,
        datadocde: headerAdiantamentos.dataDe,
        datadocate: headerAdiantamentos.dataAte,
        modefunc: headerAdiantamentos.modeFunc,
        dataref: headerAdiantamentos.dataRef,
        porregularizar: porRegularizar,
        modoregularizacao: modoRegularizacao
      }
    });
  }

  public regularizarAdiantamentos(headerAdiantamentos: IHeaderAdiantamentos, adiantamentosParams: IAdiantamentosParams, adiantamentos: Array<IJsonAdiantamentoReg>): TServiceResponse<IJsonNumber> {
    return this._apiService.post<IJsonNumber, Array<IJsonAdiantamentoReg>>({
      url: this._path,
      body: adiantamentos,
      params: {
        ncontade: headerAdiantamentos.clifoDe,
        ncontaate: headerAdiantamentos.clifoAte,
        datadocde: headerAdiantamentos.dataDe,
        datadocate: headerAdiantamentos.dataAte,
        ndocfa: adiantamentosParams.ndocfa,
        nnumer: adiantamentosParams.nnumer,
        faccbid: adiantamentosParams.faccbId
      }
    });
  }

  public loadAutoConfigArtigos(configAdiantamentos: IJsonAdiantamentoConfig): TServiceResponse<IJsonAdiantamentoConfig> {
    return this._apiService.post<IJsonAdiantamentoConfig>({
      url: `${this._path}/loadconfigauto`,
      body: configAdiantamentos
    });
  }

  public autoConfigArtigos(configAdiantamentos: IJsonAdiantamentoConfig): TServiceResponse<void> {
    return this._apiService.post<void, IJsonAdiantamentoConfig>({
      url: `${this._path}/configauto`,
      body: configAdiantamentos
    });
  }

  public registarAdiantamento(adiantamentosregisto: IJsonAdiantamentosRegisto): TServiceResponse<IJsonNumber> {
    return this._apiService.post<IJsonNumber, IJsonAdiantamentosRegisto>({
      url: `${this._path}/regista`,
      body: adiantamentosregisto
    });
  }

  public validaLinhasDocComercial(linhas: Array<IDocComercialLinha>, regularizacao: boolean): TServiceResponse<IJsonBoolean> {
    return this._apiService.post<IJsonBoolean, Array<IDocComercialLinha>>({
      url: `${this._path}/validatelinhasdoc`,
      body: linhas,
      params: {
        regularizacao: regularizacao
      }
    });
  }

  public saveAdiantamentosConfig(configAdiantamentos: IJsonAdiantamentoConfig): TServiceResponse<void> {
    return this._apiService.put<void, IJsonAdiantamentoConfig>({
      url: `${this._path}/config`,
      body: configAdiantamentos
    });
  }
}
