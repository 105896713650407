import {ExtractosartigosModuleComponent} from './components/extractosArtigos.module.component';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {MODULE_NAME_EXTRACTOSARTIGOS} from './jsonExtractoArtigo.module.interface';
import {ROLE} from '../../services/role.const';

export const MODULE_EXTRACTOSARTIGOS: IModuleDefinition = {
  name: MODULE_NAME_EXTRACTOSARTIGOS,
  state: {
    url: `/${MODULE_NAME_EXTRACTOSARTIGOS}`,
    component: ExtractosartigosModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.extractosartigos'
    },
    params: {
      nArtigo: {
        type: 'string',
        value: ''
      }
    }
  }
};
