<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h4 class="modal-title">{{ nomeTipo }}</h4>
</div>

<div class="modal-body">
  <div class="entity-detail-form">
    <pl-form>
      <pl-group>
        <pl-group>
          <label><span [translate]="'prhfluxos.fields.tipo'"></span>:</label>
          <edit>
            <pl-autocomplete attrName="tipo" [source]="indicesFluxos" [(model)]="selectedIndice" rowTemplate="nome" output="nome" [properties]="{allowInvalid: false, allowEmpty: false}">
            </pl-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label><span [translate]="'prhfluxos.fields.papel'"></span>:</label>
          <edit>
            <pl-autocomplete attrName="tipo" [source]="fnSourcePapeis" [(model)]="selectedPapel" rowTemplate="nome" output="nome" [properties]="{allowInvalid: false, allowEmpty: false}">
            </pl-autocomplete>
          </edit>
        </pl-group>
      </pl-group>

      <pl-button klass="btn-primary btn-sm action-save" [disabled]="!selectedIndice || !selectedPapel" [onClick]="fnCreateFluxo">
        <i class="fa fa-save fa-fw"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
      </pl-button>

      <hr />

      <dx-data-grid [cgDxDataGrid]="definitionTiposFluxosModal" [dataSource]="definitionTiposFluxosModal.dataSource" (onInitialized)="onDataGridInitializedTiposFluxosModal($event)">
        <div *dxTemplate="let item of 'actions'" class="text-center">
          <div class="btn-group btn-group-xs">
            <button type="button" class="btn btn-primary action-prev-pos" [disabled]="item.data.prevPosDisabled" [click]="fnUpdateFluxo(item.data, -1)" plPromise>
              <i class="fa fa-fw fa-chevron-up"></i>
            </button>
            <button type="button" class="btn btn-success action-next-pos" [disabled]="item.data.nextPosDisabled" [click]="fnUpdateFluxo(item.data, 1)" plPromise>
              <i class="fa fa-fw fa-chevron-down"></i>
            </button>
            <button type="button" class="btn btn-danger action-delete" [click]="fnDeleteFluxo(item.data)" plPromise>
              <i class="fa fa-fw fa-trash"></i>
            </button>
          </div>
        </div>
      </dx-data-grid>
    </pl-form>
  </div>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light action-close" (evtClicked)="close()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
