import {Component, Injector, Input} from '@angular/core';
import {EDebDiretosSEPAEstadoItem, IDebDiretosSEPAAcessos, IJsonDebDiretosSEPACliente} from '../debDiretosSEPA.module.interface';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {DebDiretosSEPAModuleService} from '../debDiretosSEPA.module.service';
import {IDevExpressDataGrid, IDevExpressDataGridColumnButtonOnClickEvent, IDevExpressDataGridLoadResult} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {DebDiretosSEPAClienteModalComponent} from './debDiretosSEPA.cliente.modal.component';
import dxDataGrid from 'devextreme/ui/data_grid';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {PlAlertService} from 'pl-comps-angular';
import {Properties} from 'devextreme/ui/button';
import {TranslateService} from '@ngx-translate/core';
import {confirm} from 'devextreme/ui/dialog';
import {LoadOptions} from 'devextreme/data';
import {DevExpressDatagridPaging} from '../../../components/devexpress/datagrid/utilities/devexpress.datagrid.paging';
import {devExpressDataGridFiltersToQueryFilter, devExpressDataGridSortToOrder} from '../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';

@Component({
  selector: 'ddsepa-clientes-modal',
  templateUrl: './debDiretosSEPA.clientes.modal.component.html'
})
export class DebDiretosSEPAClientesModalComponent extends CGModalComponent<void> {
  @Input() public userAcessos: IDebDiretosSEPAAcessos;

  public dataGridClientesDefinition: IDevExpressDataGrid<IJsonDebDiretosSEPACliente, string>;
  public debDiretosSEPAEstadoItem: typeof EDebDiretosSEPAEstadoItem;
  public semAutorizacaoDebitosDiretos: boolean;
  public countNaoTemAutorizacao: number;
  public countTodos;

  private _listaClientes: Array<IJsonDebDiretosSEPACliente>;
  private _dataGridClientesInstance: dxDataGrid<IJsonDebDiretosSEPACliente, string>;
  private _dataGridPaging: DevExpressDatagridPaging<IJsonDebDiretosSEPACliente, string>;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _debDiretosSEPAModuleService: DebDiretosSEPAModuleService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.debDiretosSEPAEstadoItem = EDebDiretosSEPAEstadoItem;
    this.countNaoTemAutorizacao = 0;
    this.countTodos = 0;
    this.dataGridClientesDefinition = {
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'debDiretosSEPA.fields.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'debDiretosSEPA.fields.nome'},
        {dataField: 'emailDD', dataType: 'string', caption: 'debDiretosSEPA.fields.emailDD', allowFiltering: false, allowHeaderFiltering: false, allowSearch: false},
        {dataField: 'tipoAsSTR', dataType: 'string', caption: 'debDiretosSEPA.fields.tipo', allowFiltering: false, allowHeaderFiltering: false, allowSearch: false},
        {dataField: 'nAutorizacao', dataType: 'string', caption: 'debDiretosSEPA.fields.nAutorizacao'},
        {dataField: 'temAutorizacaoDebitosDiretos', dataType: 'boolean', caption: 'debDiretosSEPA.fields.autorizacao', allowFiltering: false, allowHeaderFiltering: false, allowSearch: false},
        {dataField: 'dataInicio', dataType: 'date', caption: 'debDiretosSEPA.fields.inicio'},
        {dataField: 'dataFim', dataType: 'date', caption: 'debDiretosSEPA.fields.fim'},
        {dataField: 'contratosAsSTR', dataType: 'string', caption: 'debDiretosSEPA.fields.contrato', visible: false, allowFiltering: false, allowHeaderFiltering: false, allowSearch: false},
        {dataField: 'firstEnviado', dataType: 'boolean', caption: 'debDiretosSEPA.fields.firstEnviado', visible: false, allowFiltering: false, allowHeaderFiltering: false, allowSearch: false},
        {dataField: 'codMeioPagamento', dataType: 'number', caption: 'debDiretosSEPA.fields.codMeioPagamento', visible: false},
        {
          dataField: 'vf_actions',
          fixed: true,
          fixedPosition: 'right',
          caption: ' ',
          cellTemplate: 'cellTemplateActions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false
        }
      ],
      dataSource: new CustomStore({
        key: 'nConta',
        load: (options: LoadOptions<IJsonDebDiretosSEPACliente>) => this._clientesTableSource(options)
      }),
      pager: {visible: true},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateAlert',
            locateInMenu: 'auto'
          },
          {
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'add',
              hint: this._translateService.instant('global.btn.addLine'),
              onClick: () => this._addNewCliente()
            } satisfies Properties
          },
          'exportButton',
          'columnChooserButton'
        ]
      },
      remoteOperations: {
        filtering: true,
        sorting: true,
        grouping: false,
        summary: false,
        paging: true,
        groupPaging: false
      }
    };
    this._listaClientes = [];
    this.semAutorizacaoDebitosDiretos = false;
  }

  public onClientesInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonDebDiretosSEPACliente, string>): void {
    this._dataGridClientesInstance = component;
  }

  public async editCliente(data: IJsonDebDiretosSEPACliente): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DebDiretosSEPAClienteModalComponent, {size: 'lg', backdrop: 'static'});
    const componentInstance: DebDiretosSEPAClienteModalComponent = modalInstance.componentInstance;
    componentInstance.model = data;
    componentInstance.editMode = true;
    componentInstance.listaClientes = this._listaClientes;
    await modalInstance.result;
    await this._dataGridClientesInstance?.refresh();
  }

  public async deleteCliente(event: IDevExpressDataGridColumnButtonOnClickEvent<IJsonDebDiretosSEPACliente, string>): Promise<void> {
    const confirmDelete: boolean = await confirm(this._translateService.instant('entity.delete.message'), this._translateService.instant('global.btn.delete'));
    if (confirmDelete) {
      await this._deleteCliente(event.row.data);
    }
  }

  public async filtrarPorNaoTemAutorizacao(): Promise<void> {
    this.semAutorizacaoDebitosDiretos = !this.semAutorizacaoDebitosDiretos;
    await this._dataGridClientesInstance?.refresh();
  }

  private async _addNewCliente(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DebDiretosSEPAClienteModalComponent, {size: 'lg', backdrop: 'static'});
    const componentInstance: DebDiretosSEPAClienteModalComponent = modalInstance.componentInstance;
    componentInstance.listaClientes = this._listaClientes;
    await modalInstance.result;
    await this._dataGridClientesInstance?.refresh();
  }

  private _clientesTableSource(options: LoadOptions<IJsonDebDiretosSEPACliente>): Promise<IDevExpressDataGridLoadResult<IJsonDebDiretosSEPACliente>> {
    if (!this._dataGridPaging) {
      this._dataGridPaging = new DevExpressDatagridPaging(this._dataGridClientesInstance);
    }
    options.requireTotalCount = true;
    const {page, perPage} = this.semAutorizacaoDebitosDiretos ? {page: 1, perPage: 10} : this._dataGridPaging.paginate(options);
    const search = options.filter ? devExpressDataGridFiltersToQueryFilter(this._dataGridClientesInstance, options.filter) : '';
    const order: string = options.sort ? devExpressDataGridSortToOrder(options.sort) : undefined;
    // this._options = options;
    return this._debDiretosSEPAModuleService.getConfiguracaoClientes(page, perPage, search, order, this.semAutorizacaoDebitosDiretos).then((response) => {
      this._listaClientes = response.body.list;
      if (!this.semAutorizacaoDebitosDiretos) {
        this.countTodos = response.body.total;
        this._debDiretosSEPAModuleService.getTotalClientesSemAutorizacaoAnexada().then((result) => {
          this.countNaoTemAutorizacao = result.body ? result.body : 0;
        });
      }
      return this._dataGridPaging.processResult(response);
    });
  }

  private async _deleteCliente(item: IJsonDebDiretosSEPACliente): Promise<void> {
    item.estado = EDebDiretosSEPAEstadoItem.Deleted;
    const changedList: Array<IJsonDebDiretosSEPACliente> = [];
    changedList.push(item);
    await this._debDiretosSEPAModuleService.saveConfiguracaoClientes(changedList);
    this._plAlertService.success('debDiretosSEPA.strings.configClienteDeleted');
    await this._dataGridClientesInstance?.refresh();
  }
}
