import {deepFreeze} from 'pl-comps-angular';

export enum EAppTheme {
  Light = 'LIGHT',
  Dark = 'DARK',
  Auto = 'AUTO'
}

export interface IAppTheme {
  readonly theme: EAppTheme;
  readonly prefersColorSchemeDark: boolean;
}

export interface IAppThemeItem {
  readonly id: EAppTheme;
  readonly icon: string;
  readonly title: string;
}

export interface IAppThemeChangeEvt extends IAppTheme {
  readonly previousTheme: EAppTheme;
}

export const APP_THEMES: ReadonlyArray<EAppTheme> = deepFreeze([EAppTheme.Light, EAppTheme.Dark, EAppTheme.Auto]);

export const APP_THEMES_ITEMS: ReadonlyArray<IAppThemeItem> = deepFreeze([
  {id: EAppTheme.Light, icon: 'fa-sun-o', title: 'themes.light'},
  {id: EAppTheme.Dark, icon: 'fa-moon-o', title: 'themes.dark'},
  {id: EAppTheme.Auto, icon: 'fa-adjust', title: 'themes.auto'}
]);

export const DEFAULT_APP_THEME: EAppTheme = EAppTheme.Light;

export const DEFAULT_APP_THEME_ITEM: IAppThemeItem = APP_THEMES_ITEMS.find((theme: IAppThemeItem) => theme.id === DEFAULT_APP_THEME);

export const LOGO_LIGHT = 'logo.webp';

export const LOGO_DARK = 'logo-light.webp';
