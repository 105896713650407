import {IJsonUserFeedbackConfig} from '../../interfaces/jsonUserfeedback.interface';

export interface IUserFeedbackConfig extends IJsonUserFeedbackConfig {
  readonly token: string;
}

export const USER_FEEDBACK_REDIRECT = 'userfeedback';

export function userFeedbackUrlWithUser(origin: string, ssoToken: string): string {
  return `${origin}?sso=${ssoToken}`;
}
