<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'docscontabilidade.title_singular'"></h4>
</div>

<div class="modal-body">
  <pl-form [properties]="{orientation: 'horizontal'}" [submit]="fnSendEmail" plPromise>
    <div>
      <cg-pdf-viewer [pdfSrc]="url"></cg-pdf-viewer>
    </div>

    <pl-group>
      <label [translate]="'docscontabilidadeimprimir.label.email'"></label>
      <edit>
        <pl-edit type="email" attrName="email" [(model)]="email" [properties]="{validators: {required: {value: true}}}"></pl-edit>
      </edit>
      <actions>
        <button type="submit" class="btn btn-primary action-send-email"><i class="fa fa-fw fa-envelope-o"></i>&nbsp;<span [translate]="'impdoc.label.enviar'"></span></button>
      </actions>
    </pl-group>
  </pl-form>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light action-close" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
