<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'selecaoLotes.title'"></h4>
  </div>

  <div class="modal-body">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      [dataSource]="dataGridDefinition.dataSource"
      cgDxDataGridInstanceName="selecaoLotesGrid"
      (onInitialized)="onInitialized($event)"
      (onContentReady)="onContentReady()"
      (onFocusedCellChanged)="onFocusedCellChanged($event)"
      (cgDxDataGridKeydownLastRow)="onKeydownLastRow($event)">
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-primary btn-sm me-auto" style="margin-right: auto" name="btnAddLote" *ngIf="!detail" (click)="novoLote()" [disabled]="closeDisabled">
      <i class="fa fa-plus-circle"></i>&nbsp;<span [translate]="'global.btn.new'"></span>
    </button>

    <button type="button" class="btn btn-success btn-sm action-save" name="btnSaveLotes" *ngIf="!detail" [disabled]="closeDisabled" #btnClose (click)="close()">
      <i class="fa fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span>
    </button>

    <button type="button" class="btn btn-light btn-sm action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
      <i class="fa fa-times"></i>&nbsp;<span [translate]="detail ? 'global.btn.close' : 'global.btn.cancel'"></span>
    </button>
  </div>
</pl-form>
