import {Component, Injector, Input} from '@angular/core';
import {isEmpty, KEYCODES} from 'pl-comps-angular';
import {DocContabilidadeFieldConta} from '../docContabilidade.field.conta';
import {EDebitoCredito} from '../../../../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {EDocContabilidadeField} from '../../docContabilidade.interface';
import {IJsonPOC} from '../../../../../../../entities/pocs/jsonPOC.entity.interface';

@Component({
  selector: 'doccontabilidade-ncontadebito',
  templateUrl: './docContabilidade.nContaDebito.component.html'
})
export class DocContabilidadeNContaDebitoComponent extends DocContabilidadeFieldConta {
  @Input() public disableAutoFocus: boolean;

  constructor(protected readonly _injector: Injector) {
    super(EDocContabilidadeField.CONTA_DEBITO, _injector);
  }

  public modelChanged(value: IJsonPOC): void {
    if (isEmpty(value) && this.linha.dc !== EDebitoCredito.Debito) {
      return;
    }
    super.modelChanged(value);
    this.evtModelChanged.emit({
      linha: this.linha,
      value: value,
      oldValue: this.previousModel,
      type: EDebitoCredito.Debito
    });
  }

  protected _keydown(event: KeyboardEvent): void {
    super._keydown(event);
    if (event.key === KEYCODES.ENTER) {
      const inputValue: string = (<HTMLInputElement>event.target).value;
      if (this.linha.poc?.cc && inputValue && inputValue === this.linha.nContaDebito) {
        this.modelChanged(this.linha.poc);
      }
    }
  }

  protected _notValidDc(): boolean {
    return (
      this.docContabilidade.isUsingPreDefinido &&
      this.predefinido &&
      !this.predefinido.isGeneric &&
      this.preDefinidoContabLinha &&
      !this.preDefinidoContabLinha.fake &&
      this.preDefinidoContabLinha.conta.dC === EDebitoCredito.Credito
    );
  }
}
