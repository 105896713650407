<div class="modelo22-anexoG">
  <pl-form>
    <pl-navigator contentMaxHeight="80vh">
      <pl-navigator-item caption="modelo22.anexoG.quadro03.title">
        <div *plNavigatorItemCaption><span class="number-title-box">03</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoG.quadro03.title'"></span></div>

        <div *plNavigatorItemContent>
          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">031</div>
            </div>

            <div class="ms-2">
              <div class="inputs-currency-inline mt-2">
                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoG.quadro03.field1'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">1</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoGQuadro03Campo1"
                        [model]="anexoG.quadro03.campo01"
                        (modelChange)="anexoG.quadro03.campo01 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoG.quadro03.field2'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">2</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoGQuadro03Campo2"
                        [model]="anexoG.quadro03.campo02"
                        (modelChange)="anexoG.quadro03.campo02 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoG.quadro03.field3'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">3</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoGQuadro03Campo3"
                        [model]="anexoG.quadro03.campo03"
                        (modelChange)="anexoG.quadro03.campo03 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">032</div>
            </div>

            <div class="ms-2">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionLista032" cgDxDataGridInstanceName="lista032" [dataSource]="dataGridDefinitionLista032.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtnLista032'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoGQuadro3(item.rowIndex)">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="row mt-2">
                <div class="col"><label [translate]="'modelo22.anexoG.quadro03.field10'"></label></div>
                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers">10</div>
                    <pl-edit type="currency" attrName="anexoGQuadro03Campo10" [model]="anexoG.quadro03.campo10" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoG.quadro04.title">
        <div *plNavigatorItemCaption><span class="number-title-box">04</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoG.quadro04.title'"></span></div>

        <div *plNavigatorItemContent>
          <hr />
          <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoG.quadro04.desc'"></span></div>
          <hr />

          <div class="mt-2">
            <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro04" cgDxDataGridInstanceName="quadro04" [dataSource]="dataGridDefinitionQuadro04.dataSource">
              <div *dxTemplate="let item of 'cellTemplateBtnQuadro04'">
                <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoGQuadro4(item.rowIndex)">
                  <i class="fa fa-fw fa-trash"></i>
                </button>
              </div>
            </dx-data-grid>
          </div>

          <div class="inputs-currency-inline mt-4">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoG.quadro04.field11'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">11</div>
                  <pl-edit type="currency" attrName="anexoGQuadro04Campo11" [model]="anexoG.quadro04.campo11" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoG.quadro05.title">
        <div *plNavigatorItemCaption><span class="number-title-box">05</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoG.quadro05.title'"></span></div>

        <div *plNavigatorItemContent>
          <div><span class="fw-bold" [translate]="'modelo22.anexoG.quadro05.desc'"></span></div>
          <div class="inputs-currency-inline mt-2">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoG.quadro05.field1'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">1</div>
                  <pl-edit
                    type="cginteger"
                    attrName="anexoGQuadro05Campo1"
                    [model]="anexoG.quadro05.campo01"
                    (modelChange)="anexoG.quadro05.campo01 = $event; emitModelo22()"
                    [properties]="{validate: false}">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoG.quadro05.field2'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">2</div>
                  <pl-edit
                    type="cginteger"
                    attrName="anexoGQuadro05Campo2"
                    [model]="anexoG.quadro05.campo02"
                    (modelChange)="anexoG.quadro05.campo02 = $event; emitModelo22()"
                    [properties]="{validate: false}">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoG.quadro05.field3'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">3</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoGQuadro05Campo3"
                    [model]="anexoG.quadro05.campo03"
                    (modelChange)="anexoG.quadro05.campo03 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoG.quadro05.field4'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">4</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoGQuadro05Campo4"
                    [model]="anexoG.quadro05.campo04"
                    (modelChange)="anexoG.quadro05.campo04 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoG.quadro06.title">
        <div *plNavigatorItemCaption><span class="number-title-box">06</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoG.quadro06.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline mt-4">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoG.quadro06.field1'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">1</div>
                  <pl-edit type="date" attrName="anexoGQuadro06Campo1" [model]="anexoG.quadro06.campo01" (modelChange)="anexoG.quadro06.campo01 = $event; emitModelo22()"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoG.quadro06.field2-3'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">2</div>
                  <pl-edit
                    type="radio"
                    attrName="anexoGQuadro06Campo2"
                    [model]="anexoG.quadro06.campo0203"
                    (modelChange)="anexoG.quadro06.campo0203 = $event; emitModelo22()"
                    [properties]="{value: true, label: 'modelo22.anexoG.quadro06.field2'}">
                  </pl-edit>
                </div>
                <div class="d-flex">
                  <div class="input-box-numbers">3</div>
                  <pl-edit
                    type="radio"
                    attrName="anexoGQuadro06Campo3"
                    [model]="anexoG.quadro06.campo0203"
                    (modelChange)="anexoG.quadro06.campo0203 = $event; emitModelo22()"
                    [properties]="{value: false, label: 'modelo22.anexoG.quadro06.field3'}">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>
    </pl-navigator>
  </pl-form>
</div>
