import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {Component, Injector, OnDestroy} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlDynamicVisualsSecondaryClickAction, PlAlertService} from 'pl-comps-angular';
import {BancosExtratoService} from '../../service/bancosExtrato.module.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DevExpressDataGridUIService} from '../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {IBancosExtratoConsent} from '../../bancosExtrato.module.interface';
import {IDevExpressDataGridEventOnContextMenuPreparing, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonBancosExtratoConsentsData} from '../../jsonBancosExtrato.module.interface';
import {IJsonEntidadeBancariaConsent} from '../../../../interfaces/jsonEntidadeBancaria.interface';

const DATA_GRID_INSTANCE_NAME = 'bancosextratomoduleauthorizationsmodal';

@Component({
  selector: 'bancos-extrato-module-authorizations-modal',
  templateUrl: './bancosExtrato.authorizations.modal.component.html'
})
export class BancosExtratoModuleAuthorizationsModalComponent extends CGModalComponent<void> implements OnDestroy {
  public readonly dataGrid: IDevExpressDataGrid;
  public readonly dataGridSource: CustomStore<IBancosExtratoConsent, number>;
  public readonly dataGridInstanceName: string;
  public externalId: string;

  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _bancosExtratoService: BancosExtratoService
  ) {
    super(_injector);
    this.dataGrid = {
      columns: [
        {dataField: 'accountsId', dataType: 'string', caption: 'bancosextrato.authorizationsmodal.fields.accountsId'},
        {dataField: 'credentialsId', dataType: 'string', caption: 'bancosextrato.authorizationsmodal.fields.credentialsId'},
        {dataField: 'providerName', dataType: 'string', caption: 'bancosextrato.authorizationsmodal.fields.providerName'},
        {dataField: 'status', dataType: 'string', caption: 'bancosextrato.authorizationsmodal.fields.status'},
        {dataField: 'errorMessage', dataType: 'string', caption: 'bancosextrato.authorizationsmodal.fields.errorMessage'},
        {dataField: 'errorType', dataType: 'string', caption: 'bancosextrato.authorizationsmodal.fields.errorType'},
        {dataField: 'errorReason', dataType: 'string', caption: 'bancosextrato.authorizationsmodal.fields.errorReason'},
        {dataField: 'statusUpdated', dataType: 'datetime', caption: 'global.text.date'},
        {dataField: 'sessionExpiryDate', dataType: 'datetime', caption: 'bancosextrato.authorizationsmodal.fields.sessionExpiryDate'},
        {
          name: 'actions',
          cellTemplate: 'dataGridTemplateActions',
          alignment: 'center',
          width: 48,
          allowExporting: false,
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSorting: false,
          showInColumnChooser: false
        }
      ],
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      pager: {visible: false},
      paging: {
        enabled: false,
        pageSize: 50
      },
      scrolling: {
        rowRenderingMode: 'virtual',
        preloadEnabled: true
      }
    };
    this.dataGridSource = new CustomStore<IBancosExtratoConsent, number>({
      key: 'credentialsId',
      load: this._onLoadDataGrid.bind(this)
    });
    this.dataGridInstanceName = DATA_GRID_INSTANCE_NAME;
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onDataGridContextMenuPreparing({target, event, row}: IDevExpressDataGridEventOnContextMenuPreparing<IJsonEntidadeBancariaConsent, number>): void {
    if (target !== 'content' || !row || row.rowType !== 'data') {
      return;
    }
    event.preventDefault();
    const actions: Array<IPlDynamicVisualsSecondaryClickAction> = [
      {caption: 'bancosextrato.authorizationsmodal.text.deleteConsent', icon: 'fa-trash', click: () => this._deleteConsent(row.data.credentialsId)}
    ];
    this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.target, actions).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public readonly fnDeleteConsent = (credentialsId: string) => (): Promise<void> => this._deleteConsent(credentialsId);

  private _onLoadDataGrid(): Promise<Array<IBancosExtratoConsent>> {
    return this._bancosExtratoService.getConsents().then((response: HttpResponse<IJsonBancosExtratoConsentsData>) => {
      this.externalId = response.body.externalId;
      return response.body.consents.map<IBancosExtratoConsent>((consent: IJsonEntidadeBancariaConsent) => {
        let accountsId = '';
        for (let i = 0; i < consent.accountIds.length; i++) {
          if (i > 0) {
            accountsId += ' ; ';
          }
          accountsId += consent.accountIds[i];
        }
        return {
          ...consent,
          accountsId: accountsId
        };
      });
    });
  }

  private async _deleteConsent(credentialsId: string): Promise<void> {
    await this._cgModalService.showOkCancel('bancosextrato.authorizationsmodal.text.deleteConsent', 'bancosextrato.authorizationsmodal.text.deleteConsentMessage', {size: 'md'});
    await this._bancosExtratoService.deleteConsent(credentialsId);
    this._plAlertService.success('bancosextrato.authorizationsmodal.text.successDeleteConsent');
    await this._dataGridInstance.refresh();
  }
}
