import {Component, Injector, Input} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_USERS} from '../../utilizadores.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../config/constants';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonUserEmpresa, IJsonUserPassword} from '../../../../services/account/jsonUserApi.interface';
import {IUserNewPassword} from './mudapassword.modal.component.interface';

@Component({
  selector: 'muda-password-modal',
  templateUrl: './mudapassword.modal.component.html'
})
export class MudaPasswordModalComponent extends CGModalComponent<void> {
  @Input() public userId: number;

  public model: IUserNewPassword;
  public form: FormGroupDirective;

  private readonly _serviceUsers: IEntityService<IJsonUserEmpresa>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.model = {password: '', confirm: ''};
    this._serviceUsers = this._entityServiceBuilder.build<IJsonUserEmpresa>(ENTITY_NAME_USERS);
    this.close = this.close.bind(this);
  }

  public close(): Promise<void> {
    if (!this.form.valid || this.model.password !== this.model.confirm) {
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    return new Promise<void>((resolve, reject) => {
      this._serviceUsers
        .put<void, IJsonUserPassword>({url: `${this.userId}/password`, body: {password: this.model.password}})
        .then(() => {
          this._plAlertService.success('users.passwordchanged');
          resolve();
          super.close();
        })
        .catch(reject);
    });
  }
}
