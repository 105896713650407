import {TDate} from '../../../../common/dates';

export enum EIntegracaoSalariosMultiStatus {
  Inactive = 'rhismInactive',
  Scheduled = 'rhismScheduled',
  Started = 'rhismStarted',
  Ended = 'rhismEnded',
  Timeout = 'rhismTimeout',
  Error = 'rhismError'
}

export enum EIntegracaoSalariosMultiSteps {
  INTRO = 'INTRO',
  EMPRESAS_SEL = 'RHEMPRESA_SEL',
  PROC = 'RHPROC',
  PROC_RESULTS = 'RHPROC_RESULTS'
}

export interface IIntegracaoSalariosMultiStatus {
  requestDate: TDate;
  userStarted: string;
  userStartedId: number;
  state: EIntegracaoSalariosMultiStatus;
  description: string;
  position: number;
  max: number;
}

export interface IIntegracaoSalariosMultiEmpresa {
  sel: boolean;
  nEmpresa: string;
  nomeEmpresa: string;
  configuracoes: boolean;
}

export interface IIntegracaoSalariosMultiProcResult {
  icon?: string;
  nDocInterno: string;
  descricaoErro: string;
  codEmpresa: string;
  nomeEmpresa: string;
  errorType: number;
}

export interface IIntegracaoSalariosMultiProcResultTableItem {
  icon?: string;
  codEmpresa: string;
  empresa: string;
  messages: Array<IIntegracaoSalariosMultiProcResult>;
}

export const MODULE_NAME_INTEGRACAO_SALARIOS_MULTI = 'integracaoSalariosMulti';
