import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IPlNavWizardDefinition, IPlNavWizardEventBeforeChange, isDefinedNotNull} from 'pl-comps-angular';
import {Subscription} from 'rxjs';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {IJsonErpUser, TUserSession} from '../../../services/account/jsonUserApi.interface';
import {AuthService} from '../../../services/auth/auth.service';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {ENTITY_NAME_CONTROLO_IVA} from '../../../entities/controloiva/controloIVA.entity.interface';
import {IJsonControloIVA} from '../../../entities/controloiva/jsonControloIVA.entity.interface';
import {IControloIVAEntreEmpresas, IControloIVAEntreEmpresasStateParams} from '../controloIVAEntreEmpresas.module.interface';
import {ControloIVAEntreEmpresasService} from '../controloIVAEntreEmpresas.module.service';

const STEP_INDEX_EMPRESAS = 1;
const STEP_INDEX_ERROS = 2;
const STEP_INDEX_FINAL = 3;

@Component({
  selector: 'controlo-iva-entre-empresas',
  templateUrl: './controloIVAEntreEmpresas.module.component.html'
})
export class ControloIVAEntreEmpresasComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly empresasTemplate: string;
  public readonly controlosIVATemplate: string;
  public copiaControlos: IControloIVAEntreEmpresas;
  public showSelectorControlos: boolean;
  public empresas: Array<IJsonErpUser>;
  public empresasSource: Array<IJsonErpUser>;
  public controlosIVA: Array<IJsonControloIVA>;
  public controlosIVASource: Array<IJsonControloIVA> | undefined;
  public definitionNavWizard: IPlNavWizardDefinition;

  private readonly _subscriptionSession: Subscription;
  private readonly _controloIVAService: IEntityService<IJsonControloIVA>;
  private readonly _codControloIVA: string;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _authService: AuthService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _controloIVAEntreEmpresasService: ControloIVAEntreEmpresasService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.showSelectorControlos = true;
    this.copiaControlos = {
      controloIVAEntreEmpresasList: []
    };
    this.empresasTemplate = '{{nEmpresa}} - {{nomeEmpresa}}';
    this.controlosIVATemplate = '{{codControloIVA}} - {{descAbreviada}}';
    this.controlosIVA = [];
    this.empresas = [];

    const params: IControloIVAEntreEmpresasStateParams = <IControloIVAEntreEmpresasStateParams>this._transition.params();
    this._codControloIVA = params.codControloIVA;

    this.dataGridDefinition = {
      columns: [
        {dataField: 'nEmpresa', dataType: 'string', caption: 'controlosIvaEntreEmpresas.grid.fields.nEmpresa', allowEditing: false},
        {dataField: 'nomeEmpresa', dataType: 'string', caption: 'controlosIvaEntreEmpresas.grid.fields.nomeEmpresa', allowEditing: false},
        {dataField: 'codControloIVA', dataType: 'string', caption: 'controlosIvaEntreEmpresas.grid.fields.codControloIVA', allowEditing: false},
        {dataField: 'nomeControloIVA', dataType: 'string', caption: 'controlosIvaEntreEmpresas.grid.fields.nomeControloIVA', allowEditing: false},
        {dataField: 'erros', dataType: 'string', caption: 'controlosIvaEntreEmpresas.grid.fields.erros', allowEditing: false},
        {dataField: 'copiar', dataType: 'boolean', caption: 'controlosIvaEntreEmpresas.grid.fields.copiar', allowEditing: true}
      ],
      editing: {allowUpdating: true, mode: 'cell'},
      toolbar: {visible: false}
    };

    this._controloIVAService = this._entityServiceBuilder.build<IJsonControloIVA>(ENTITY_NAME_CONTROLO_IVA);
    this._controloIVAService.query().then((response) => {
      this.controlosIVASource = response.body?.list;
      if (this._codControloIVA) {
        const controloIVASel: Array<IJsonControloIVA> = [...this.controlosIVASource];
        let idx = -1;
        const foundItem = controloIVASel.find((item: IJsonControloIVA, index: number) => {
          if (item.codControloIVA === this._codControloIVA) {
            idx = index;
            return true;
          }
          return false;
        });
        if (idx > -1 && isDefinedNotNull(foundItem)) {
          this.controlosIVA = [foundItem];
          controloIVASel.splice(idx, 1);
        }
        this.controlosIVASource = controloIVASel;
      }
    });

    this._subscriptionSession = this._authService.identityAsObservable().subscribe((session: TUserSession) => {
      const empresassel: Array<IJsonErpUser> = [...session.erps];
      let idx = -1;
      const foundItem = empresassel.find((item: IJsonErpUser, index: number) => {
        if (item.nEmpresa === session.erp.nEmpresa) {
          idx = index;
          return true;
        }
        return false;
      });
      if (idx > -1 && isDefinedNotNull(foundItem)) {
        empresassel.splice(idx, 1);
      }
      this.empresasSource = empresassel;
      return true;
    });

    this.definitionNavWizard = {
      items: []
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this._codControloIVA) {
      if (this.controlosIVASource) {
        const controloIVASel: Array<IJsonControloIVA> = [...this.controlosIVASource];
        let idx = -1;
        const foundItem = controloIVASel.find((item: IJsonControloIVA, index: number) => {
          if (item.codControloIVA === this._codControloIVA) {
            idx = index;
            return true;
          }
          return false;
        });
        if (idx > -1 && isDefinedNotNull(foundItem)) {
          this.controlosIVA = [foundItem];
          controloIVASel.splice(idx, 1);
        }
        this.controlosIVASource = controloIVASel;
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionSession.unsubscribe();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public readonly fnBeforeStepChange = (event: IPlNavWizardEventBeforeChange): Promise<boolean> => this._beforeStepChange(event);
  public readonly fnValidatorStepEmpresasValidations: () => boolean = () => this._validatorStepEmpresasValidations();
  public readonly fnValidatorStepControlosIVAValidations: () => boolean = () => this._validatorStepControlosIVAValidations();

  private _beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (currentStep === this.definitionNavWizard.items[STEP_INDEX_EMPRESAS] && (type === 'next' || (nextStep && nextStep === this.definitionNavWizard.items[STEP_INDEX_ERROS]))) {
      return this._validaCopiaControlosIVA().then(() => true);
    }
    if (currentStep === this.definitionNavWizard.items[STEP_INDEX_ERROS] && (type === 'next' || (nextStep && nextStep === this.definitionNavWizard.items[STEP_INDEX_FINAL]))) {
      return this._cgModalService.showOkCancelVanilla('global.text.confirmation', 'controlosIvaEntreEmpresas.confirmacaoOkCancel.message').result.then(() => {
        return this._efetuaCopia().then(() => true);
      });
    }
    return Promise.resolve(true);
  }

  private _validaCopiaControlosIVA(): Promise<void> {
    const listEmpresas = !this.empresas.length ? this.session.erp.nEmpresa : this.empresas.map((value: IJsonErpUser) => value.nEmpresa).join(',');
    const controlosIVAList = !this.controlosIVA.length ? '' : this.controlosIVA.map((value: IJsonControloIVA) => value.codControloIVA).join(',');
    const controlosIVA = this.maintenanceMode && this._codControloIVA ? this._codControloIVA : controlosIVAList;
    return this._controloIVAEntreEmpresasService.validaCopiaControlosIVA(listEmpresas, controlosIVA).then((response) => {
      this.copiaControlos = response.body;
    });
  }

  private _efetuaCopia(): Promise<void> {
    const listEmpresas = !this.empresas.length ? this.session.erp.nEmpresa : this.empresas.map((value: IJsonErpUser) => value.nEmpresa).join(',');
    const controlosIVAList = !this.controlosIVA.length ? '' : this.controlosIVA.map((value: IJsonControloIVA) => value.codControloIVA).join(',');
    const controlosIVA = this.maintenanceMode && this._codControloIVA ? this._codControloIVA : controlosIVAList;
    return this._controloIVAEntreEmpresasService.efetuarCopia(listEmpresas, controlosIVA, this.copiaControlos).then();
  }

  private _validatorStepEmpresasValidations(): boolean {
    return this.empresas.length > 0;
  }

  private _validatorStepControlosIVAValidations(): boolean {
    return this.controlosIVA.length > 0;
  }
}
