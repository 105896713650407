import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_TIPO_REGIME} from './tipoRegime.datasource.interface';
import {ETipoRegime} from '../../entities/classescaixa/jsonClasseCaixa.entity.interface';

export const DATA_SOURCE_TIPO_REGIME: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_REGIME,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoRegime.Empregados, name: 'tipoRegime.empregados'},
    {value: ETipoRegime.GerenteMOE, name: 'tipoRegime.gerenteMOE'},
    {value: ETipoRegime.PrimeiroEmprego, name: 'tipoRegime.primeiroEmprego'},
    {value: ETipoRegime.Pensionistas, name: 'tipoRegime.pensionistas'},
    {value: ETipoRegime.Deficientes, name: 'tipoRegime.deficientes'},
    {value: ETipoRegime.TrabEntSFimLuc, name: 'tipoRegime.trabEntSFimLuc'},
    {value: ETipoRegime.TrabOutros, name: 'tipoRegime.trabOutros'},
    {value: ETipoRegime.PraticantesDesportivos, name: 'tipoRegime.praticantesDesportivos'},
    {value: ETipoRegime.ServicoDomesticoConvencional, name: 'tipoRegime.servicoDomesticoConvencional'},
    {value: ETipoRegime.ServicoDomesticoReal, name: 'tipoRegime.servicoDomesticoReal'}
  ]
});
