import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {isObject, isUndefined, PlAlertService} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_PERIOCIDADE_CONTRATO_TIPO} from '../../../../datasources/periocidadecontratotipo/periocidadeContratoTipo.datasource.interface';
import {EConfigOptionsInstanceName} from '../../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../../config/constants';
import {IJsonClasseContrato} from '../../../classescontratos/jsonClasseContrato.entity.interface';
import {IJsonContratoTipo} from '../../jsonContratoTipo.entity.interface';
import {IJsonDocfa} from '../../../docfas/jsonDocFa.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonDocfaNum} from '../../../docfasnums/jsonDocfaNum.entity.interface';
import moment from 'moment';
import {HttpResponse} from '@angular/common/http';
import {IEntityService} from '../../../../services/entity/entity.service.interface';

import {ENTITY_NAME_DOCFAS_NUMS} from '../../../docfasnums/docfasNums.entity.interface';

@Component({
  selector: 'contratos-tipos-edit',
  templateUrl: './contratosTipos.entity.edit.component.html'
})
export class ContratosTiposEditComponent extends ModuloEntityDetailComponent<IJsonContratoTipo> implements OnInit, OnDestroy {
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly dataSourceNamePeriocidadeContratoTipo: string = DATA_SOURCE_NAME_PERIOCIDADE_CONTRATO_TIPO;
  public readonly docfasOutput: string;
  public docFa: unknown;
  public docfaNumFilter: string;

  private readonly _serviceDocFaNum: IEntityService<IJsonDocfaNum>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._serviceDocFaNum = this._entityServiceBuilder.build<IJsonDocfaNum>(ENTITY_NAME_DOCFAS_NUMS);
    this.docfasOutput = '{{nDocFa}} - {{nome}} ({{descricao}})';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.docfaNumFilter = isObject(this.model) && this.model.nome ? `nDocFa=${this.model.nDocfa}&encerrado=0&visivelERPCloud=1` : 'nDocFa=0&encerrado=0&visivelERPCloud=1';
  }

  public onClasseContratoChanged(classeContrato: IJsonClasseContrato): void {
    if (!classeContrato || isUndefined(classeContrato.nClasseCtr)) {
      return;
    }

    this.model.nClasseCtr = classeContrato.nClasseCtr;
    this.model.classeTipo = classeContrato.classeTipo;
  }

  public onDocFaChanged(docFa: IJsonDocfa): void {
    if (!docFa || isUndefined(docFa.nDocFa)) {
      this._plAlertService.error(this._translateService.instant('docfas.errors.tipoDocNotExist'));
      this.docfaNumFilter = 'nDocFa=0&encerrado=0&visivelERPCloud=1';
      return;
    }

    this.docfaNumFilter = `nDocFa=${docFa.nDocFa}&encerrado=0&visivelERPCloud=1`;

    this.model.nNumer = null;
    this.model.nDocfa = docFa.nDocFa;
    this.model.nomeDocfa = docFa.nome;
    this.docFa = docFa;
  }

  public addNumer(): Promise<void> {
    const nnumer: IJsonDocfaNum = {
      idDocfaNum: 0,
      nDocfa: this.model.nDocfa ? this.model.nDocfa : 1,
      nNumer: null,
      num: 0,
      descNumer: 'Nova Série',
      data: moment(),
      numDraft: 0,
      nConta: '',
      encerrado: false,
      atcud: '',
      visivelERPCloud: true
    };

    return this._serviceDocFaNum.post({body: nnumer}).then((response: HttpResponse<IJsonDocfaNum>) => {
      if (response.body) {
        this.model.nNumer = response.body.nNumer;
      }
    });
  }
}
