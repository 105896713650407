import {Injectable} from '@angular/core';
import {IJsonPreviTes, IJsonPreviTesAuto, IJsonPreviTesAutoHeader, IJsonPreviTesNew} from '../../../entities/tesrubrica/jsonTesRubrica.entity.interface';
import {TDate} from '../../../../common/dates';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {ApiService} from '../../../services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class PreviTesService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/prevites`;
  }

  public getPesquisaPrevTesManual(): TServiceResponse<Array<IJsonPreviTes>> {
    return this._apiService.get<Array<IJsonPreviTes>>({url: this._path});
  }

  public getPesquisaPrevTesAuto(contade: string, contaate: string, datavencde: TDate, datavencate: TDate): TServiceResponse<Array<IJsonPreviTesAuto>> {
    return this._apiService.get<Array<IJsonPreviTesAuto>>({
      url: `${this._path}/auto`,
      params: {
        contade: contade,
        contaate: contaate,
        datavencde: datavencde,
        datavencate: datavencate
      }
    });
  }

  public getPreviTesAutoHeaderDefault(): TServiceResponse<IJsonPreviTesAutoHeader> {
    return this._apiService.get<IJsonPreviTesAutoHeader>({url: `${this._path}/auto/defaultheader`});
  }

  public postPrevTesManual(previtesnew: IJsonPreviTesNew): TServiceResponse<Array<IJsonPreviTes>> {
    return this._apiService.post<Array<IJsonPreviTes>, IJsonPreviTesNew>({
      url: this._path,
      body: previtesnew
    });
  }

  public saveAllPrevTesManual(linhas: Array<IJsonPreviTes>): TServiceResponse<Array<IJsonPreviTes>> {
    return this._apiService.post<Array<IJsonPreviTes>, Array<IJsonPreviTes>>({
      url: `${this._path}/saveall`,
      body: linhas
    });
  }

  public postProcessarPrevTesAuto(
    contade: string,
    contaate: string,
    datavencde: TDate,
    datavencate: TDate,
    codrubrica: number,
    listprevitesAuto: Array<IJsonPreviTesAuto>
  ): TServiceResponse<Array<IJsonPreviTesAuto>> {
    return this._apiService.post<Array<IJsonPreviTesAuto>>({
      url: `${this._path}/auto`,
      body: listprevitesAuto,
      params: {
        contade: contade,
        contaate: contaate,
        datavencde: datavencde,
        datavencate: datavencate,
        codrubrica: codrubrica
      }
    });
  }

  public deletePrevTesManual(teslancoids: Array<string>): TServiceResponse<void> {
    return this._apiService.delete<void, Array<string>>({url: this._path, body: teslancoids});
  }
}
