import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IExcludedAbDesc, IExcluidosAbonosDescontos, TExcluidosAbonosDescontos} from '../../rhCessaContratoTrabalho.module.interface';
import {IJsonListaExcl} from '../../../rhfolfer/jsonRHFolfer.module.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellDblClick,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventRowDraggingOnAdd
} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import ArrayStore from 'devextreme/data/array_store';

@Component({
  selector: 'modal-complementosexcepcoes',
  templateUrl: './complementosExcepcoes.modal.component.html'
})
export class ComplementosExcepcoesModalComponent extends CGModalComponent<IExcluidosAbonosDescontos> implements OnInit {
  @Input() public excludedListPossiveis: Array<IJsonListaExcl>;
  @Input() public excludedListExcluidos: Array<IJsonListaExcl>;

  public readonly dataGridDefinition: IDevExpressDataGrid<IExcludedAbDesc>;

  public excludedList: Array<IExcludedAbDesc>;
  public selectedLinePossivel: IExcludedAbDesc;
  public selectedLineExcluido: IExcludedAbDesc;
  public emptyPossiveisTable: boolean;
  public emptyExcluidosTable: boolean;

  private _dataGridInstancePossivel: dxDataGrid<IExcludedAbDesc>;
  private _dataGridInstanceExcluido: dxDataGrid<IExcludedAbDesc>;
  private _dataGridStore: ArrayStore;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.dataGridDefinition = {
      columns: [
        {dataField: 'value', dataType: 'string', caption: 'rhcessacontratotrabalho.modal.complementosexcepcoes.fields.codABDESC'},
        {dataField: 'designacao', dataType: 'string', caption: 'rhcessacontratotrabalho.modal.complementosexcepcoes.fields.designaBreve'},
        {dataField: 'status', dataType: 'string', visible: false, showInColumnChooser: false, allowSearch: false}
      ],
      height: '60vh',
      headerFilter: {visible: false},
      columnHidingEnabled: false,
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      repaintChangesOnly: true,
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'}
    };
    this.excludedList = [];
    this.selectedLinePossivel = undefined;
    this.selectedLineExcluido = undefined;
  }

  public ngOnInit(): void {
    this.emptyPossiveisTable = true;
    this.emptyExcluidosTable = true;
    for (const possivel of this.excludedListPossiveis) {
      this.excludedList.push({...possivel, status: 'possivel'});
      this.emptyPossiveisTable = false;
    }
    for (const excluido of this.excludedListExcluidos) {
      this.excludedList.push({...excluido, status: 'excluido'});
      this.emptyExcluidosTable = false;
    }

    this._dataGridStore = new ArrayStore({
      key: 'value',
      data: this.excludedList
    });
    this.dataGridDefinition.dataSource = {
      store: this._dataGridStore,
      reshapeOnPush: true
    };
  }

  public close(): void {
    const excluidos: IExcluidosAbonosDescontos = {
      possiveis: this._dataGridInstancePossivel.getDataSource().items(),
      excluidos: this._dataGridInstanceExcluido.getDataSource().items()
    };
    super.close(excluidos);
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IExcludedAbDesc>, possivel: boolean): void {
    if (possivel) {
      this._dataGridInstancePossivel = component;
    } else {
      this._dataGridInstanceExcluido = component;
    }
  }

  public onContentReady(possivel: boolean): void {
    if (possivel) {
      this.emptyPossiveisTable = !this._dataGridInstancePossivel.totalCount();
    } else {
      this.emptyExcluidosTable = !this._dataGridInstanceExcluido.totalCount();
    }
    this._dataGridInstancePossivel.endCustomLoading();
    this._dataGridInstanceExcluido.endCustomLoading();
  }

  public onCellClick({data}: IDevExpressDataGridEventOnCellClick<IExcludedAbDesc>): void {
    if (data.status === 'possivel') {
      this.selectedLinePossivel = data;
    } else {
      this.selectedLineExcluido = data;
    }
  }

  public onCellDblClick({data}: IDevExpressDataGridEventOnCellDblClick<IExcludedAbDesc>): Promise<void> {
    if (data.status === 'possivel') {
      this.selectedLinePossivel = data;
      return this.selectedRight();
    }

    this.selectedLineExcluido = data;
    return this.selectedLeft();
  }

  public allRight(): Promise<void> {
    this._dataGridInstancePossivel.beginCustomLoading(undefined);
    this._dataGridInstanceExcluido.beginCustomLoading(undefined);
    const dataSourcePossivel: Array<IExcludedAbDesc> = this._dataGridInstancePossivel.getDataSource().items();
    const values: Partial<IExcludedAbDesc> = {status: 'excluido'};
    for (const possivel of dataSourcePossivel) {
      this._updateDataGridStoreStatus(possivel.value, values);
    }
    this.selectedLinePossivel = undefined;
    return Promise.resolve();
  }

  public selectedRight(): Promise<void> {
    if (this.selectedLinePossivel) {
      this._dataGridInstancePossivel.beginCustomLoading(undefined);
      this._dataGridInstanceExcluido.beginCustomLoading(undefined);
      const values: Partial<IExcludedAbDesc> = {status: 'excluido'};
      this._updateDataGridStoreStatus(this.selectedLinePossivel.value, values);
      this.selectedLinePossivel = undefined;
    }
    return Promise.resolve();
  }

  public selectedLeft(): Promise<void> {
    if (this.selectedLineExcluido) {
      this._dataGridInstancePossivel.beginCustomLoading(undefined);
      this._dataGridInstanceExcluido.beginCustomLoading(undefined);
      const values: Partial<IExcludedAbDesc> = {status: 'possivel'};
      this._updateDataGridStoreStatus(this.selectedLineExcluido.value, values);
      this.selectedLineExcluido = undefined;
    }
    return Promise.resolve();
  }

  public allLeft(): Promise<void> {
    this._dataGridInstancePossivel.beginCustomLoading(undefined);
    this._dataGridInstanceExcluido.beginCustomLoading(undefined);
    const dataSourceExcluido: Array<IExcludedAbDesc> = this._dataGridInstanceExcluido.getDataSource().items();
    const values: Partial<IExcludedAbDesc> = {status: 'possivel'};
    for (const excluido of dataSourceExcluido) {
      this._updateDataGridStoreStatus(excluido.value, values);
    }
    this.selectedLineExcluido = undefined;
    return Promise.resolve();
  }

  public readonly fnOnAddExclusaoTable = (event: IDevExpressDataGridEventRowDraggingOnAdd<TExcluidosAbonosDescontos, IExcludedAbDesc>): void => {
    this._onAddExclusaoTable(event);
  };

  private _onAddExclusaoTable(event: IDevExpressDataGridEventRowDraggingOnAdd<TExcluidosAbonosDescontos, IExcludedAbDesc>): void {
    const key: string = event.itemData.value;
    const values: Partial<IExcludedAbDesc> = {status: event.toData};
    this._updateDataGridStoreStatus(key, values);
  }

  private _updateDataGridStoreStatus(key: string, values: Partial<IExcludedAbDesc>): void {
    this._dataGridStore.update(key, values).then(() => {
      this._dataGridStore.push([
        {
          type: 'update',
          key,
          data: values
        }
      ]);
    });
  }
}
