import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_ANEXO, ETipoAnexo} from './tiposAnexo.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPOS_ANEXO: IDataSource<ETipoAnexo> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_ANEXO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoAnexo.Continente, name: 'tiposanexo.continente'},
    {value: ETipoAnexo.Madeira, name: 'tiposanexo.madeira'},
    {value: ETipoAnexo.Acores, name: 'tiposanexo.acores'},
    {value: ETipoAnexo.DecRecapitulativa, name: 'tiposanexo.decRecapitulativa'},
    {value: ETipoAnexo.Campo40, name: 'tiposanexo.campo40'},
    {value: ETipoAnexo.Campo41, name: 'tiposanexo.campo41'}
  ]
});
