<div class="modelo22-anexoC">
  <pl-form>
    <pl-navigator contentMaxHeight="80vh">
      <pl-navigator-item caption="modelo22.anexoC.quadro04.title">
        <div *plNavigatorItemCaption><span class="number-title-box">04</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoC.quadro04.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro04.varglob'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro04.field6'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">6</div>
                  <pl-edit type="currency" attrName="anexoCQuadro04Campo6" [model]="anexoC.quadro04.campo6" (modelChange)="anexoC.quadro04.campo6 = $event; emitModelo22()" [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro04.field6A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">6-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro04Campo6A"
                    [model]="anexoC.quadro04.campo6A"
                    (modelChange)="anexoC.quadro04.campo6A = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro04.field7A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">7-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro04Campo7A"
                    [model]="anexoC.quadro04.campo7A"
                    (modelChange)="anexoC.quadro04.campo7A = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro04.field7B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">7-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro04Campo7B"
                    [model]="anexoC.quadro04.campo7B"
                    (modelChange)="anexoC.quadro04.campo7B = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro04.field7C'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">7-C</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro04Campo7C"
                    [model]="anexoC.quadro04.campo7C"
                    (modelChange)="anexoC.quadro04.campo7C = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro04.ram'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro04.field8A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">8-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro04Campo8A"
                    [model]="anexoC.quadro04.campo8A"
                    (modelChange)="anexoC.quadro04.campo8A = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro04.field8B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">8-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro04Campo8B"
                    [model]="anexoC.quadro04.campo8B"
                    (modelChange)="anexoC.quadro04.campo8B = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro04.field8C'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">8-C</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro04Campo8C"
                    [model]="anexoC.quadro04.campo8C"
                    (modelChange)="anexoC.quadro04.campo8C = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro04.raa'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro04.field9A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">9-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro04Campo9A"
                    [model]="anexoC.quadro04.campo9A"
                    (modelChange)="anexoC.quadro04.campo9A = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro04.field9B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">9-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro04Campo9B"
                    [model]="anexoC.quadro04.campo9B"
                    (modelChange)="anexoC.quadro04.campo9B = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro04.field9C'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">9-C</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro04Campo9C"
                    [model]="anexoC.quadro04.campo9C"
                    (modelChange)="anexoC.quadro04.campo9C = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro04.continente'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro04.field10A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">10-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro04Campo10A"
                    [model]="anexoC.quadro04.campo10A"
                    (modelChange)="anexoC.quadro04.campo10A = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro04.field10B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">10-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro04Campo10B"
                    [model]="anexoC.quadro04.campo10B"
                    (modelChange)="anexoC.quadro04.campo10B = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro04.field10C'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">10-C</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro04Campo10C"
                    [model]="anexoC.quadro04.campo10C"
                    (modelChange)="anexoC.quadro04.campo10C = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoC.quadro05.title">
        <div *plNavigatorItemCaption><span class="number-title-box">05</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoC.quadro05.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro05.field11'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">11</div>
                  <pl-edit type="currency" attrName="anexoCQuadro05Campo11" [model]="anexoC.quadro05.campo11" (modelChange)="anexoC.quadro05.campo11 = $event; calcCamp12()" [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro05.coleta'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">21</div>
                  <pl-edit type="currency" attrName="anexoCQuadro05Campo21" [model]="anexoC.quadro05.campo21" (modelChange)="anexoC.quadro05.campo21 = $event; calcCamp12()" [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">12</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro05Campo12"
                    [model]="anexoC.quadro05.campo12"
                    (modelChange)="anexoC.quadro05.campo12 = $event; emitModelo22()"
                    [inputClass]="'text-end'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro05.field13'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">13</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro05Campo13"
                    [model]="anexoC.quadro05.campo13"
                    (modelChange)="anexoC.quadro05.campo13 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro05.field14'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">14</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro05Campo14"
                    [model]="anexoC.quadro05.campo14"
                    (modelChange)="anexoC.quadro05.campo14 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro05.field15'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">15</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro05Campo15"
                    [model]="anexoC.quadro05.campo15"
                    (modelChange)="anexoC.quadro05.campo15 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoC.quadro06.title">
        <div *plNavigatorItemCaption><span class="number-title-box">06</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoC.quadro06.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro06.varglob'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06.field16'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">16</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06Campo16"
                    [model]="anexoC.quadro06.campo16"
                    (modelChange)="anexoC.quadro06.campo16 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06.field17A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">17-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06Campo17A"
                    [model]="anexoC.quadro06.campo17A"
                    (modelChange)="anexoC.quadro06.campo17A = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06.field17B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">17-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06Campo17B"
                    [model]="anexoC.quadro06.campo17B"
                    (modelChange)="anexoC.quadro06.campo17B = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro06.ram'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06.field18A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">18-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06Campo18A"
                    [model]="anexoC.quadro06.campo18A"
                    (modelChange)="anexoC.quadro06.campo18A = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06.field18B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">18-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06Campo18B"
                    [model]="anexoC.quadro06.campo18B"
                    (modelChange)="anexoC.quadro06.campo18B = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro06.raa'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06.field19A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">19-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06Campo19A"
                    [model]="anexoC.quadro06.campo19A"
                    (modelChange)="anexoC.quadro06.campo19A = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06.field19B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">19-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06Campo19B"
                    [model]="anexoC.quadro06.campo19B"
                    (modelChange)="anexoC.quadro06.campo19B = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro06.continente'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06.field20A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">20-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06Campo20A"
                    [model]="anexoC.quadro06.campo20A"
                    (modelChange)="anexoC.quadro06.campo20A = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06.field20B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">20-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06Campo20B"
                    [model]="anexoC.quadro06.campo20B"
                    (modelChange)="anexoC.quadro06.campo20B = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoC.quadro06A.title">
        <div *plNavigatorItemCaption><span class="number-title-box">06-A</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoC.quadro06A.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro06A.varglob'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06A.field22'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">22</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06ACampo22"
                    [model]="anexoC.quadro06A.campo22"
                    (modelChange)="anexoC.quadro06A.campo22 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06A.field22A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">22-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06ACampo22A"
                    [model]="anexoC.quadro06A.campo22A"
                    (modelChange)="anexoC.quadro06A.campo22A = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06A.field22B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">22-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06ACampo22B"
                    [model]="anexoC.quadro06A.campo22B"
                    (modelChange)="anexoC.quadro06A.campo22B = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro06A.ram'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06A.field23A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">23-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06ACampo23A"
                    [model]="anexoC.quadro06A.campo23A"
                    (modelChange)="anexoC.quadro06A.campo23A = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06A.field23B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">23-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06ACampo23B"
                    [model]="anexoC.quadro06A.campo23B"
                    (modelChange)="anexoC.quadro06A.campo23B = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro06A.raa'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06A.field24A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">24-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06ACampo24A"
                    [model]="anexoC.quadro06A.campo24A"
                    (modelChange)="anexoC.quadro06A.campo24A = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro06A.field24B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">24-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro06ACampo24B"
                    [model]="anexoC.quadro06A.campo24B"
                    (modelChange)="anexoC.quadro06A.campo24B = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoC.quadro07.title">
        <div *plNavigatorItemCaption><span class="number-title-box">07</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoC.quadro07.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro07.varglob'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro07.field1'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers">1</div>
                  <pl-edit type="currency" attrName="anexoCQuadro07Campo1" [model]="anexoC.quadro07.campo1" (modelChange)="anexoC.quadro07.campo1 = $event; emitModelo22()" [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro07.field2A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">2-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro07Campo2A"
                    [model]="anexoC.quadro07.campo2A"
                    (modelChange)="anexoC.quadro07.campo2A = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro07.field2B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">2-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro07Campo2B"
                    [model]="anexoC.quadro07.campo2B"
                    (modelChange)="anexoC.quadro07.campo2B = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro07.ram'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro07.field3A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">3-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro07Campo3A"
                    [model]="anexoC.quadro07.campo3A"
                    (modelChange)="anexoC.quadro07.campo3A = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro07.field3B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">3-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro07Campo3B"
                    [model]="anexoC.quadro07.campo3B"
                    (modelChange)="anexoC.quadro07.campo3B = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro07.raa'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro07.field4A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">4-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro07Campo4A"
                    [model]="anexoC.quadro07.campo4A"
                    (modelChange)="anexoC.quadro07.campo4A = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro07.field4B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">4-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro07Campo4B"
                    [model]="anexoC.quadro07.campo4B"
                    (modelChange)="anexoC.quadro07.campo4B = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />
            <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoC.quadro07.continente'"></span></div>
            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro07.field5A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">5-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro07Campo5A"
                    [model]="anexoC.quadro07.campo5A"
                    (modelChange)="anexoC.quadro07.campo5A = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoC.quadro07.field5B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">5-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoCQuadro07Campo5B"
                    [model]="anexoC.quadro07.campo5B"
                    (modelChange)="anexoC.quadro07.campo5B = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>
    </pl-navigator>
  </pl-form>
</div>
