import {Component, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonDGEMPComentario} from '../../jsonDGEMPFull.interface';

@Component({
  selector: 'modal-dgempsfull-comment',
  templateUrl: './dgempsFull.comment.modal.component.html'
})
export class DGEMPSFullCommentModalComponent extends CGModalComponent<IJsonDGEMPComentario> implements OnInit {
  @Input() public comentario: IJsonDGEMPComentario;

  public ngOnInit(): void {
    if (!this.comentario) {
      this.comentario = {
        comentarioID: '',
        comentarioParentID: '',
        docID: '',
        titulo: '',
        texto: '',
        utilizador: '',
        data: undefined
      };
    }
  }

  public close(): void {
    super.close(this.comentario);
  }
}
