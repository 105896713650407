import {ConfigsErpPortalColaboradorModuleComponent} from './components/configs.portalcolaborador.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_CONFIGS_PORTAL_COLABORADOR} from './configs.portalcolaborador.module.interface';
import {ROLE} from '../../../../services/role.const';

export const MODULE_CONFIGS_PORTAL_COLABORADOR: IModuleDefinition = {
  name: MODULE_NAME_CONFIGS_PORTAL_COLABORADOR,
  state: {
    url: '/configs/erp/portalcolaborador',
    component: ConfigsErpPortalColaboradorModuleComponent,
    data: {
      roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
      pageTitle: 'configsErp.items.portalColaborador.title'
    }
  }
};
