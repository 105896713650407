export const EVENT_SUPPORTED_BEFORE_INPUT = (function () {
  try {
    const beforeInputEvent = new InputEvent('beforeinput', {
      data: 'xyz',
      inputType: 'deleteContentForward'
    });

    let support = false;

    const element: HTMLInputElement = document.createElement<'input'>('input');

    element.addEventListener('beforeinput', (event: InputEvent) => {
      if (event.inputType === 'deleteContentForward') {
        support = true;
      }
    });

    element.dispatchEvent(beforeInputEvent);

    return support;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error: unknown) {
    return false;
  }
})();

export const EVENT_SUPPORTED_INPUT = (function () {
  try {
    const inputEvent = new InputEvent('input', {
      data: 'xyz',
      inputType: 'deleteContentForward'
    });

    let support = false;

    const element: HTMLInputElement = document.createElement<'input'>('input');

    element.addEventListener<'input'>('input', (event: Event) => {
      if ((<InputEvent>event).inputType === 'deleteContentForward') {
        support = true;
      }
    });

    element.dispatchEvent(inputEvent);

    return support;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error: unknown) {
    return false;
  }
})();
