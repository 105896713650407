import {ApplicationRef, ComponentRef, createComponent, EmbeddedViewRef, Inject, Injectable, Injector, OnDestroy, TemplateRef, Type} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import type {IPlDynamicVisualsReferenceOptions, IPlDynamicVisualsStack, TPlDynamicVisualsStackOpenContent} from '../dynamicvisuals.stacks.interface';
import {isString} from '../../../common/utilities/utilities';
import {PlDynamicVisualsActiveReference} from './reference/dynamicvisuals.active.reference';
import {PlDynamicVisualsContentReference} from './reference/dynamicvisuals.content.reference';
import {PlDynamicVisualsReference} from './reference/dynamicvisuals.reference';
import {PlDynamicVisualsStacksService} from '../dynamicvisuals.stacks.service';

@Injectable({
  providedIn: 'root'
})
export class PlDynamicVisualsStackService implements OnDestroy, IPlDynamicVisualsStack {
  private readonly _document: Document;
  private readonly _references: Set<PlDynamicVisualsReference>;

  constructor(
    @Inject(DOCUMENT) document: any,
    private readonly _applicationRef: ApplicationRef,
    private readonly _plDynamicVisualsStacksService: PlDynamicVisualsStacksService
  ) {
    this._document = document;
    this._references = new Set<PlDynamicVisualsReference>();
    this._plDynamicVisualsStacksService.registerStack(this);
  }

  public ngOnDestroy(): void {
    this._plDynamicVisualsStacksService.deregisterStack(this);
    this.dismissAll();
  }

  public dismissAll(reason?: unknown): void {
    this._references.forEach((stack: PlDynamicVisualsReference) => {
      stack.dismiss(reason);
    });
    this._references.clear();
  }

  public hasOpenReferences(): boolean {
    return this._references.size > 0;
  }

  public open<TResult = void>(contentInjector: Injector, content: TPlDynamicVisualsStackOpenContent, options: IPlDynamicVisualsReferenceOptions): PlDynamicVisualsReference<TResult> {
    const containerElement: HTMLElement =
      options?.container instanceof HTMLElement
        ? options.container
        : isString(options?.container) && Boolean(options.container)
          ? this._document.querySelector(options.container)
          : this._document.body;
    let reference: PlDynamicVisualsReference<TResult>;
    const activeReference: PlDynamicVisualsActiveReference<TResult> = new PlDynamicVisualsActiveReference<TResult>();
    const contentReference: PlDynamicVisualsContentReference = this._getContentRef(options?.injector || contentInjector, content, activeReference);
    this._applicationRef.attachView(contentReference.viewRef);
    containerElement.appendChild(contentReference.componentRef.location.nativeElement);
    // eslint-disable-next-line prefer-const
    reference = new PlDynamicVisualsReference(contentReference, options?.beforeDismiss);
    this._registerReference(reference);
    activeReference.closed = () => reference.closed();
    activeReference.close = (result: TResult) => {
      reference.close(result);
    };
    activeReference.dismiss = (reason: unknown) => {
      reference.dismiss(reason);
    };
    return reference;
  }

  private _getContentRef(contentInjector: Injector, content: TPlDynamicVisualsStackOpenContent, activeReference: PlDynamicVisualsActiveReference): PlDynamicVisualsContentReference {
    if (!content) {
      return new PlDynamicVisualsContentReference([]);
    } else if (content instanceof TemplateRef) {
      return this._createFromTemplateRef(content, activeReference);
    } else if (isString(content)) {
      return this._createFromString(content);
    }
    return this._createFromComponent(contentInjector, content, activeReference);
  }

  private _createFromTemplateRef(content: TemplateRef<unknown>, activeReference: PlDynamicVisualsActiveReference): PlDynamicVisualsContentReference {
    const context: unknown = {
      $implicit: activeReference,
      close: (result: unknown) => {
        activeReference.close(result);
      },
      dismiss: (reason: unknown) => {
        activeReference.dismiss(reason);
      }
    };
    const viewRef: EmbeddedViewRef<unknown> = content.createEmbeddedView(context);
    this._applicationRef.attachView(viewRef);
    return new PlDynamicVisualsContentReference([viewRef.rootNodes], viewRef);
  }

  private _createFromString(content: string): PlDynamicVisualsContentReference {
    const component: Text = this._document.createTextNode(content);
    return new PlDynamicVisualsContentReference([[component]]);
  }

  private _createFromComponent(contentInjector: Injector, componentType: Type<unknown>, activeReference: PlDynamicVisualsActiveReference): PlDynamicVisualsContentReference {
    const elementInjector = Injector.create({providers: [{provide: PlDynamicVisualsActiveReference, useValue: activeReference}], parent: contentInjector});
    const componentRef: ComponentRef<unknown> = createComponent(componentType, {environmentInjector: this._applicationRef.injector, elementInjector: elementInjector});
    const componentNativeElement: HTMLElement = componentRef.location.nativeElement;
    this._applicationRef.attachView(componentRef.hostView);
    return new PlDynamicVisualsContentReference([[componentNativeElement]], componentRef.hostView, componentRef);
  }

  private _registerReference(reference: PlDynamicVisualsReference): void {
    if (!this._references.has(reference)) {
      this._references.add(reference);
      reference.result.finally(() => {
        this._deregisterReference(reference);
      });
    }
  }

  private _deregisterReference(reference: PlDynamicVisualsReference): void {
    this._references.delete(reference);
  }
}
