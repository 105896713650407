export interface IChangePasswordStateParams {
  token: string;
}

export interface IChangePasswordRequest {
  password: string;
  confirmPassword: string;
  recaptcha: string;
}

export const STATE_NAME_CHANGE_PASSWORD = 'changepassword';
