import {Component, Injector, Input} from '@angular/core';
import {ICGContabExtratosCallback, ICGContabExtratosFilters} from '../../../../../components/contabilidade/extratos/extratos.interfaces';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'module-conciliacao-e-faturas-extratos-modal',
  templateUrl: './conciliacaoEFaturas.module.extratos.modal.html'
})
export class ConciliacaoEFaturasModuleExtratosModalComponent extends CGModalComponent<void> {
  @Input() public filters: ICGContabExtratosFilters;
  @Input() public procOnInit: boolean;

  public readonly callback: ICGContabExtratosCallback;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.callback = {};
  }

  public process(): void {
    this.callback.processar();
  }
}
