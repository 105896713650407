import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {MODULE_NAME_RPTCONFIGS} from './rptConfigs.module.interface';
import {RptConfisModuleComponent} from './components/rptConfigs.module.component';

export const MODULE_RPTCONFIGS: IModuleDefinition = {
  name: MODULE_NAME_RPTCONFIGS,
  state: {
    url: `/${MODULE_NAME_RPTCONFIGS}`,
    component: RptConfisModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.rptconfigs'
    }
  }
};
