<div class="pl-navigator" [class.pl-navigator-open]="open">
  <div class="pl-navigator-nav-wrapper" [style.max-height]="maxHeightNav">
    <div class="pl-navigator-nav">
      <div class="pl-navigator-nav-item" *ngFor="let item of items" [class.active-parent]="item.id === activeParentId">
        <div
          id="pl-navigator-nav-item-{{ item.id }}"
          class="pl-navigator-nav-item-link"
          [class.active]="item.id === activeId"
          [class.disabled]="item.disabled"
          [class.item-parent]="!!item.subItems.length"
          [class.collapsible]="item.collapsible"
          [class.collapsed]="item.collapsed"
          [attr.aria-controls]="'pl-navigator-content-item-' + item.id"
          [attr.aria-expanded]="item.id === activeId"
          [attr.aria-disabled]="item.disabled"
          [ngSwitch]="item.hasCaption"
          (click)="$event.preventDefault(); select(item.id)">
          <div class="pl-navigator-nav-item-title" *ngSwitchCase="false" [attr.title]="item.caption | cgcTranslate">
            <i class="pl-navigator-nav-item-icon" *ngIf="item.icon" [cgcIcon]="item.icon"></i>
            <span class="pl-navigator-nav-item-title-content" [plTranslate]="item.caption"></span>
          </div>

          <div class="pl-navigator-nav-item-title" *ngSwitchCase="true" [attr.title]="item.caption | cgcTranslate">
            <ng-container *ngTemplateOutlet="item.templateCaption?.templateRef"></ng-container>
          </div>
        </div>

        <div class="pl-navigator-nav-sub-items" *ngIf="!!item.subItems.length && !item.collapsed">
          <div class="pl-navigator-nav-sub-item" *ngFor="let subItem of item.subItems">
            <div
              id="pl-navigator-nav-sub-item-{{ subItem.id }}"
              class="pl-navigator-nav-sub-item-link"
              [class.active]="subItem.id === activeId"
              [class.disabled]="subItem.disabled"
              [attr.aria-controls]="'pl-navigator-content-sub-item-' + subItem.id"
              [attr.aria-expanded]="subItem.id === activeId"
              [attr.aria-disabled]="subItem.disabled"
              [ngSwitch]="subItem.hasCaption"
              (click)="$event.preventDefault(); select(subItem.id)">
              <div class="pl-navigator-nav-sub-item-title" *ngSwitchCase="false" [attr.title]="subItem.caption | cgcTranslate">
                <i class="pl-navigator-nav-sub-item-icon" *ngIf="subItem.icon" [cgcIcon]="subItem.icon"></i>
                <span class="pl-navigator-nav-sub-item-title-content" [plTranslate]="subItem.caption"></span>
              </div>

              <div class="pl-navigator-nav-sub-item-title" *ngSwitchCase="true" [attr.title]="subItem.caption | cgcTranslate">
                <ng-container *ngTemplateOutlet="subItem.templateCaption?.templateRef"></ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pl-navigator-content" #plNavigatorContent [style.max-height]="maxHeight">
    <div
      *ngFor="let item of items"
      id="pl-navigator-content-item-{{ item.id }}"
      class="pl-navigator-content-item"
      [class.active]="item.id === activeId"
      [attr.aria-labelledby]="'pl-navigator-nav-item-' + item.id"
      [attr.aria-expanded]="item.id === activeId"
      (focusin)="focusedElement(item, $event)"
      (focusout)="bluredElement()">
      <div class="pl-navigator-item-header">
        <ng-container [ngSwitch]="item.hasCaption">
          <div class="pl-navigator-nav-item-title" *ngSwitchCase="false" [attr.title]="item.caption | cgcTranslate">
            <i class="pl-navigator-nav-item-icon" *ngIf="item.icon" [cgcIcon]="item.icon"></i>
            <span class="pl-navigator-nav-item-title-content" [plTranslate]="item.caption"></span>
          </div>

          <div class="pl-navigator-nav-item-title" *ngSwitchCase="true" [attr.title]="item.caption | cgcTranslate">
            <ng-container *ngTemplateOutlet="item.templateCaption?.templateRef"></ng-container>
          </div>
        </ng-container>

        <ng-container *ngTemplateOutlet="item.templateHeader?.templateRef"></ng-container>
      </div>

      <div class="pl-navigator-item-content">
        <ng-container *ngTemplateOutlet="item.templateContent?.templateRef"></ng-container>

        <div class="pl-navigator-content-sub-items" *ngIf="!!item.subItems.length">
          <div
            *ngFor="let subItem of item.subItems"
            id="pl-navigator-content-sub-item-{{ subItem.id }}"
            class="pl-navigator-content-sub-item"
            [class.active]="subItem.id === activeId"
            [attr.aria-labelledby]="'pl-navigator-nav-subItem-' + subItem.id"
            [attr.aria-expanded]="subItem.id === activeId"
            (focusin)="focusedElement(subItem, $event, item)"
            (focusout)="bluredElement()">
            <div class="pl-navigator-sub-item-header">
              <i class="pl-navigator-nav-sub-item-icon" *ngIf="subItem.icon" [cgcIcon]="subItem.icon"></i>
              <span class="pl-navigator-nav-sub-item-title-content" [plTranslate]="subItem.caption"></span>
              <ng-container *ngTemplateOutlet="subItem.templateHeader?.templateRef"></ng-container>
            </div>

            <div class="pl-navigator-sub-item-content">
              <ng-container *ngTemplateOutlet="subItem.templateContent?.templateRef"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pl-navigator-nav-toggle" *ngIf="isMobile" (click)="openNav()">
    <i class="fa-bars" aria-hidden="true"></i>
  </div>

  <div class="pl-navigator-nav-overlay" (click)="closeNav()"></div>
</div>
