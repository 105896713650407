<pl-tooltip [config]="tooltip" [ngSwitch]="type">
  <div *plTooltipContent>
    <button
      *ngSwitchDefault
      #elementBtn
      type="button"
      class="btn"
      [attr.name]="attrName"
      [ngClass]="klass"
      [disabled]="disabled"
      [class.disabled]="disabled"
      [attr.title]="attrTitle"
      [plPromise]="promise"
      (click)="doClick($event)"
      (keydown)="doKeydown($event)">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>

    <button
      *ngSwitchCase="'submit'"
      #elementBtn
      type="submit"
      class="btn"
      [attr.name]="attrName"
      [ngClass]="klass"
      [disabled]="disabled"
      [class.disabled]="disabled"
      [attr.title]="attrTitle"
      [plPromise]="promise">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
  </div>
</pl-tooltip>

<ng-template #content>
  <span #fallback><ng-content></ng-content></span>
  <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
  <span *ngIf="!fallback.childNodes.length || !fallback.textContent.trim().length">{{ text }}</span>
</ng-template>
