import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonMoradaFaturacao} from './jsonMoradaFaturacao.entity.interface';
import {ENTITY_NAME_MORADAS_FATURACAO} from './moradasFaturacao.entity.interface';

export const ENTITY_MORADAS_FATURACAO: IEntityDefinition<IJsonMoradaFaturacao> = {
  name: ENTITY_NAME_MORADAS_FATURACAO,
  roles: [ROLE.ERP],
  searchPlaceholder: 'moradasFaturacao.pesquisa',
  metadata: {
    keyName: 'moralId',
    fields: [
      {name: 'nConta', width: '60px', caption: 'moradasFaturacao.fields.nConta'},
      {name: 'nome', caption: 'moradasFaturacao.fields.nome'},
      {name: 'rua', caption: 'moradasFaturacao.fields.rua'},
      {name: 'codPostal', caption: 'moradasFaturacao.fields.codPostal'},
      {name: 'localidade', caption: 'moradasFaturacao.fields.localidade'},
      {name: 'nipc', caption: 'moradasFaturacao.fields.nipc'},
      {name: 'id', caption: 'moradasFaturacao.fields.id'},
      {name: 'observacoes', caption: 'moradasFaturacao.fields.observacoes'},
      {name: 'morada', caption: 'moradasFaturacao.fields.morada'},
      {name: 'codPais', caption: 'moradasFaturacao.fields.codPais'},
      {name: 'moralId', caption: 'moradasFaturacao.fields.moralId'},
      {name: 'nIdAltern', caption: 'moradasFaturacao.fields.nIdAltern'}
    ],
    order: 'id',
    searchFields: 'nConta,nome,rua,codPostal,localidade,id'
  },
  autocomplete: {
    rowTemplate: '{{nConta}} - {{nome}} ({{id}}, {{rua}}, {{codPostal}}-{{localidade}})',
    output: 'nome',
    searchFields: 'nConta,nome,rua,codPostal,localidade,id'
  },
  actions: {
    new: false,
    edit: false,
    detail: false,
    delete: false,
    search: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.moradasFaturacao'
      }
    }
  }
};
