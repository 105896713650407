import {EnvironmentProviders, makeEnvironmentProviders} from '@angular/core';
import {FileListPipelineHandler} from '../common/pipeline/file/filelist.pipeline.interface';
import {PlUploadTransformationHandler} from './transform/upload.transform.service';
import {FileListPipelineFinisher} from '../common/pipeline/file/filelist.pipeline.finisher';
import {NoopFileListPipelineHandler} from '../common/pipeline/file/filelist.pipeline.noop.handler';

export function provideUploadFileListPipeline(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {provide: FileListPipelineHandler, useClass: PlUploadTransformationHandler},
    {provide: FileListPipelineFinisher, useClass: NoopFileListPipelineHandler}
  ]);
}
