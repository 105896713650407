import {Component, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'analisador-tesouraria-progress-modal',
  templateUrl: './analisadorTes.progress.modal.html'
})
export class AnalisadorTesProgressModalComponent extends CGModalComponent<void> {
  @Input() public processMessage: string;
}
