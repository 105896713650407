import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_DOCFAS_NUMS, IDOCFASNUMSEntity, TDocfaNumERequestDataWithBody} from './docfasNums.entity.interface';

const ENTITY_FIELDS = 'nDocfa, nNumer, num, descNumer, data, numDraft, nConta, encerrado, atcud, naoVisivelERPCloud';

export const ENTITY_DOCFAS_NUMS: IDOCFASNUMSEntity = {
  name: ENTITY_NAME_DOCFAS_NUMS,
  roles: [ROLE.ERP],
  searchPlaceholder: 'docfasNums.pesquisa',
  metadata: {
    keyName: 'idDocfaNum',
    fields: [
      {name: 'idDocfaNum', caption: 'docfasNums.fields.idDocfaNum'},
      {name: 'nDocfa', caption: 'docfasNums.fields.nDocfa'},
      {name: 'nNumer', caption: 'docfasNums.fields.nNumer'},
      {name: 'num', caption: 'docfasNums.fields.num'},
      {name: 'DescNumer', caption: 'docfasNums.fields.DescNumer'},
      {name: 'data', type: 'date', caption: 'global.text.date'},
      {name: 'numDraft', caption: 'docfasNums.fields.numDraft'},
      {name: 'nConta', caption: 'docfasNums.fields.nConta'},
      {name: 'encerrado', type: 'boolean', caption: 'docfasNums.fields.encerrado'},
      {name: 'atcud', caption: 'docfasNums.fields.atcud'},
      {name: 'naoVisivelERPCloud', type: 'boolean', caption: 'docfasNums.fields.naoVisivelERPCloud'}
    ],
    order: 'nDocFa,nNumer',
    detailFields: ENTITY_FIELDS,
    listFields: ENTITY_FIELDS,
    editFields: ENTITY_FIELDS
  },
  autocomplete: {
    rowTemplate: '{{nNumer}} {{descNumer}}',
    output: 'nNumer',
    searchFields: 'nDocfa,nNumer,descNumer'
  },
  actions: {
    new: true,
    edit: true,
    detail: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.docfasNums'
      }
    }
  },
  service: function () {
    const fnPost = this.post.bind(this);

    this.post = (config: TDocfaNumERequestDataWithBody<unknown>, paraTodosDoGrupo?: boolean) => {
      config = {params: {paraTodosDoGrupo: paraTodosDoGrupo}, ...config};
      return fnPost(config);
    };
  }
};
