import {Component, Injector, Input} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {ContabilidadeDigitalService} from '../../../../services/contabilidadedigital/contabilidadedigital.service';
import {IJsonDocOCRCab} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';

@Component({
  selector: 'contabilidadedigital-copiar-doc-modal',
  templateUrl: './contabilidadedigital.copiardoc.modal.component.html'
})
export class ContabilidadeDigitalCopiarDocModalComponent extends CGModalComponent<IJsonDocOCRCab> {
  @Input() public doc: IJsonDocOCRCab;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService
  ) {
    super(_injector);
  }

  public close(): Promise<void> {
    return this._contabilidadeDigitalService.documents.copiarDoc(this.doc.docID).then((response: HttpResponse<IJsonDocOCRCab>) => {
      this._plAlertService.success(
        this._plTranslateService.translate('arquivodigital.copiardocmodal.success', {
          name: response.body.gDoc.nomeFicheiro
        })
      );
      super.close(response.body);
    });
  }
}
