import {ENTITY_NAME_ARTAR, IARTAREntity} from './artars.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_ARTAR: IARTAREntity = {
  name: ENTITY_NAME_ARTAR,
  roles: [ROLE.ERP],
  searchPlaceholder: 'artars.pesquisa',
  metadata: {
    keyName: 'nArtigo',
    fields: [
      {
        name: 'nArtigo',
        caption: 'artars.fields.nArtigo',
        entity: {name: 'artigos', keyTarget: 'nArtigo', output: 'nArtigo'}
      },
      {
        name: 'nArmazem',
        caption: 'artars.fields.nArmazem',
        entity: {name: 'armazens', keyTarget: 'nArmazem', output: 'nArmazem'}
      },
      {name: 'stockMinimo', type: 'double', caption: 'artars.fields.stockMinimo'},
      {name: 'stockMaxIMO', type: 'double', caption: 'artars.fields.stockMaxIMO'},
      {name: 'stockReposic', type: 'double', caption: 'artars.fields.stockReposic'},
      {name: 'qtdMinEncome', type: 'double', caption: 'artars.fields.qtdMinEncome'},
      {name: 'qtdMaxEncome', type: 'double', caption: 'artars.fields.qtdMaxEncome'},
      {name: 'qtdRepEncome', type: 'double', caption: 'artars.fields.qtdRepEncome'},
      {name: 'despPorEncom', type: 'double', caption: 'artars.fields.despPorEncom'},
      {name: 'txCustoPosse', type: 'double', caption: 'artars.fields.txCustoPosse'},
      {name: 'nClifoHabitual', caption: 'artars.fields.nClifoHabitual'},
      {name: 'nDiasValidade', type: 'double', caption: 'artars.fields.nDiasValidade'},
      {name: 'nSectorLocal', caption: 'artars.fields.nSectorLocal'},
      {name: 'qtdLimArmazenamento', type: 'double', caption: 'artars.fields.qtdLimArmazenamento'},
      {name: 'localExcedentes', caption: 'artars.fields.localExcedentes'},
      {name: 'stockAlerta', type: 'double', caption: 'artars.fields.stockAlerta'}
    ],
    order: 'nArtigo',
    searchFields: 'nArtigo,nArmazem'
  },
  autocomplete: {
    rowTemplate: 'Artigo: {{nArtigo}} - Armazém: {{nArmazem}}',
    output: 'Artigo: {{nArtigo}} - Armazém: {{nArmazem}}',
    searchFields: 'nArtigo,nArmazem'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    search: true,
    delete: false,
    filter: true
  },
  service: function () {
    this.atualizaQuantidadesStock = (nArtigo: string, nArmazem: number, qtdMax: number, qtdMin: number) => {
      return this.put<void>({
        url: 'atualizaqtdstock',
        params: {
          nartigo: nArtigo,
          narmazem: nArmazem,
          qtdmax: qtdMax,
          qtdmin: qtdMin
        }
      });
    };
  }
};
