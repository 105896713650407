<div class="assistente-config-portais-portal-client-connect-utilizador-module entity-detail-form">
  <pl-form [formInstance]="formInstance">
    <pl-nav-wizard [definition]="navWizardDefinition" [onFinalize]="fnOnFinalize">
      <!--UTILIZADOR-->
      <pl-nav-wizard-step caption="assistenteconfigportais.steps.stepUtilizador" icon="fa-user">
        <pl-form *plNavWizardStepContent formInstanceName="utilizador" [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <pl-group>
              <label [translate]="'assistenteconfigportais.fields.nomeutilizador'"></label>
              <edit>
                <pl-edit type="text" attrName="username" [(model)]="model.username" plAutoFocus></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'assistenteconfigportais.fields.email'"></label>
              <edit>
                <pl-edit type="email" attrName="email" [(model)]="model.email"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'assistenteconfigportais.fields.nome'"></label>
              <edit>
                <pl-edit type="text" attrName="firstName" [(model)]="model.firstName"></pl-edit>
              </edit>
            </pl-group>

            <pl-group [properties]="{validators: {required: {value: false}}}">
              <label [translate]="'assistenteconfigportais.fields.apelido'"></label>
              <edit>
                <pl-edit type="text" attrName="lastName" [(model)]="model.lastName"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <label [translate]="'assistenteconfigportais.fields.enviaEmail'"></label>
            <edit>
              <pl-edit type="boolean" attrName="enviaEmail" [(model)]="model.sendEmail"></pl-edit>
            </edit>
          </pl-group>
        </pl-form>
      </pl-nav-wizard-step>

      <!--EMPRESA-->
      <pl-nav-wizard-step caption="assistenteconfigportais.steps.stepEmpresa" icon="fa-building-o">
        <pl-form *plNavWizardStepContent formInstanceName="empresa" [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <label [translate]="'assistenteconfigportais.fields.empresa'"></label>
            <edit>
              <entity-autocomplete
                entity="empresas"
                attrName="empresa"
                (modelChange)="changedEmpresa($event)"
                [filter]="filterEmpresas"
                [getDataConfig]="{params: {centralGestId: centralGestId}}"
                [properties]="{allowInvalid: false}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-form>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </pl-form>
</div>
