<div class="entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <pl-group>
          <label [translate]="'global.text.listing'"></label>
          <edit>
            <pl-edit type="reports_extratos_por_conta" attrName="listagem" [(model)]="reportModel" plAutoFocus></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'components.contabilidade.listagens.fromAccount'"></label>
            <edit>
              <entity-autocomplete entity="pocs" attrName="daConta" [selectedKey]="deConta" (selectedKeyChange)="deContaChanged($event)" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="pocs" attrName="ateConta" [selectedKey]="ateConta" (selectedKeyChange)="ateContaChanged($event)" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'components.contabilidade.listagens.fromPeriod'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="dePeriodo"
                [model]="dePeriodo"
                (modelChange)="dePeriodoChanged($event)"
                [filter]="'loadAllAnos=' + loadAllAnos + '&loadOnlyAno=' + loadOnlyAno">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="atePeriodo"
                [model]="atePeriodo"
                (modelChange)="atePeriodoChanged($event)"
                [filter]="'loadAllAnos=' + loadAllAnos + '&loadOnlyAno=' + loadOnlyAno"
                [properties]="{events: {keydown: fnKeydownProcessar}}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>
</div>

<hr />

<cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
