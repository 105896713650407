import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_TIPOS_CONTABILIDADE, ETipoContabilidade} from './tipoContabilidade.datasource.interface';

export const DATA_SOURCE_TIPOS_CONTABILIDADE: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_CONTABILIDADE,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoContabilidade.None, name: 'tipoContabilidade.data.none'},
    {value: ETipoContabilidade.OrganizadaComPOCDL35, name: 'tipoContabilidade.data.organizadaPOCDL35'},
    {value: ETipoContabilidade.NaoOrganizadaComPOCDL35, name: 'tipoContabilidade.data.naoOrganizadaPOCDL35'},
    {value: ETipoContabilidade.OrganizadaComSNC, name: 'tipoContabilidade.data.organizadaSNC'},
    {value: ETipoContabilidade.NaoOrganizadaComSNC, name: 'tipoContabilidade.data.naoOrganizadaSNC'},
    {value: ETipoContabilidade.OrganizadaComPOCIPSS, name: 'tipoContabilidade.data.organizadaPOCIPSS'},
    {value: ETipoContabilidade.OrganizadaComPOCAL, name: 'tipoContabilidade.data.organizadaPOCAL'},
    {value: ETipoContabilidade.OrganizadaComPOCP, name: 'tipoContabilidade.data.organizadaPOCP'},
    {value: ETipoContabilidade.OrganizadaComPOCMZ, name: 'tipoContabilidade.data.organizadaPOCMZ'},
    {value: ETipoContabilidade.NaoOrganizadaComPOCMZ, name: 'tipoContabilidade.data.naoOrganizadaPOCMZ'},
    {value: ETipoContabilidade.OrganizadaComPOCKZ, name: 'tipoContabilidade.data.organizadaPGCAN'},
    {value: ETipoContabilidade.NaoOrganizadaComPOCKZ, name: 'tipoContabilidade.data.naoOrganizadaPGCAN'},
    {value: ETipoContabilidade.OrganizadaNIRFMZ, name: 'tipoContabilidade.data.planoGeralContasNIRFMZ'},
    {value: ETipoContabilidade.OrganizadaComSNCIPSS, name: 'tipoContabilidade.data.organizadaSNCIPSS'},
    {value: ETipoContabilidade.OrganizadaComSNCPE, name: 'tipoContabilidade.data.organizadaSNCPE'},
    {value: ETipoContabilidade.OrganizadaComSNCME, name: 'tipoContabilidade.data.organizadaSNCME'},
    {value: ETipoContabilidade.OrganizadaESNL, name: 'tipoContabilidade.data.organizadaEntSectorNLucrativo'},
    {value: ETipoContabilidade.NaoOrganizadaArtigo50, name: 'tipoContabilidade.data.naoOrganizadaArt50CodIVA'},
    {value: ETipoContabilidade.OrganizadaSNCAP, name: 'tipoContabilidade.data.organizadaSNCAP'}
  ]
});
