<div class="trfba-geraficheiro-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="title"></h5>
  </div>

  <div class="modal-body">
    <pl-group *ngIf="showDadosFicheiroUploadDGT">
      <label [translate]="'trfba.modal.geraficheiro.fields.nomeFicheiroUploadDGT'"></label>
      <edit>
        <pl-edit type="text" [(model)]="nomeFicheiroUploadDGT"></pl-edit>
      </edit>
    </pl-group>

    <pl-group *ngIf="showDadosFicheiroUploadDGT">
      <label [translate]="'trfba.modal.geraficheiro.fields.numeroSequencial'"></label>
      <edit>
        <pl-edit type="number" [(model)]="numeroSequencial"></pl-edit>
      </edit>
    </pl-group>

    <pl-group *ngIf="showDataLanc">
      <label [translate]="'trfba.modal.geraficheiro.fields.dataLanc'"></label>
      <edit>
        <pl-edit type="date" [(model)]="dataLanc"></pl-edit>
      </edit>
    </pl-group>

    <pl-group *ngIf="showPrioridade">
      <label [translate]="'trfba.modal.geraficheiro.fields.prioridade'"></label>
      <edit>
        <pl-autocomplete [source]="metodoSourcePrioridade" attrName="prioridade" [model]="nomePrioridade" (evtSelected)="metodoPrioridadeChanged($event)" [rowTemplate]="mtdOutput" output="nameMetodo">
        </pl-autocomplete>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
