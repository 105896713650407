<div class="gestao-comercial-meios-pagamento">
  <pl-alert *ngIf="alertErrorMsg" (evtClosed)="alertErrorMsg = ''" [plAnimationShake]="alertErrorMsg.length">
    <span [translate]="alertErrorMsg"></span>
  </pl-alert>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="gestaoComercialMeiosPagamento"
    [dataSource]="dataGridDefinition.dataSource"
    (onCellPrepared)="onCellPrepared($event)"
    (onSaved)="onSaved($event)">
    <div *dxTemplate="let item of 'cellTemplateNomeCaixa'">{{ item.data.nomeCaixa }} ({{ item.data.abreviaturaMoeda }})</div>

    <div *dxTemplate="let item of 'cellTemplateBtnsActions'">
      <button type="button" class="btn btn-primary btn-xs" [attr.title]="'recibos.saveModal.payInFull' | translate" [disabled]="!emEdicao" (click)="addTudoLinha(item.data)">
        <i class="fa fa-fw fa-check"></i>
      </button>

      <button type="button" class="btn btn-primary btn-xs" [attr.title]="'recibos.saveModal.clearValue' | translate" [disabled]="!emEdicao" (click)="limpaLinha(item.data)">
        <i class="fa fa-fw fa-eraser"></i>
      </button>
    </div>
  </dx-data-grid>

  <div class="resumo-wrapper">
    <div class="resumo">
      <h4 class="page-header-cg" [translate]="'documentoFaturacao.summary'"></h4>
      <dl>
        <dt><span [translate]="'pagamentos.saveModal.document'"></span>:</dt>
        <dd>{{ totalDocumento | cgcCurrency: 2 : abreviaturaMoeda : 'code' }}</dd>

        <dt><span [translate]="'meiosPagamento.info.entregue'"></span>:</dt>
        <dd>{{ totalEntregue | cgcCurrency: 2 : abreviaturaMoeda : 'code' }}</dd>

        <dt><span [translate]="'meiosPagamento.info.troco'"></span>:</dt>
        <dd>{{ troco | cgcCurrency: 2 : abreviaturaMoeda : 'code' }}</dd>
      </dl>
    </div>
  </div>

  <div
    class="text-danger error-invalid-valor-a-pagar"
    *ngIf="!canSave"
    [translate]="'recibos.erros.invalidValorAPagar'"
    [translateParams]="{
      valorEntregue: totalEntregue | cgcCurrency: 2 : abreviaturaMoeda : 'code',
      valorDocumento: totalDocumento | cgcCurrency: 2 : abreviaturaMoeda : 'code'
    }"></div>
</div>
