import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_TIPO_CLASSE_CONTRATO, ITipoClasseContrato} from './tipoClasseContrato.datasource.interface';

export const DATA_SOURCE_TIPO_CLASSE_CONTRATO: IDataSource<number, ITipoClasseContrato> = {
  name: DATA_SOURCE_NAME_TIPO_CLASSE_CONTRATO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{descricao}}',
    output: 'descricao',
    outputKey: 'classeTipo'
  },
  data: deepFreeze<Array<ITipoClasseContrato>>([
    {classeTipo: 0, descricao: 'tipoClasseContrato.data.contratoServico'},
    {classeTipo: 1, descricao: 'tipoClasseContrato.data.contratoPrecoCliente'},
    {classeTipo: 2, descricao: 'tipoClasseContrato.data.contratoPrecoFornecedor'},
    {classeTipo: 3, descricao: 'tipoClasseContrato.data.contratoSubContratacao'},
    {classeTipo: 4, descricao: 'tipoClasseContrato.data.contratoFornecimentoCliente'},
    {classeTipo: 5, descricao: 'tipoClasseContrato.data.contratoFornecimentoFornecedor'},
    {classeTipo: 6, descricao: 'tipoClasseContrato.data.contratoAlocacaoRecurso'}
  ])
};
