<div class="ativosaquisicaocontab-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'ativosaquisicaocontab.modal.ativoaquisicaocontabmodaltitle'"></h5>
  </div>

  <div class="modal-body">
    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="ativosAquisicaoContabModal"
      [dataSource]="aquisicaoContabList"
      [(selectedRowKeys)]="nLancsList"
      (onInitialized)="onInitialized($event)"
      (onCellClick)="onCellClick($event)">
      <div *dxTemplate="let item of 'detailTemplateDocContabilidade'">
        <split-viewer-doccontab-digital
          [(docContabilidade)]="item.data._docContabilidade"
          [contabDigitalDoc]="item.data._contabDigitalDoc"
          [initialSizeLeft]="60"
          [showToolbar]="true"
          [maintenanceMode]="true"
          [readonlyDigital]="true">
        </split-viewer-doccontab-digital>
      </div>

      <div *dxTemplate="let item of 'templateToolbar'">
        <div class="d-flex gap-2 align-items-center">
          <pl-button klass="btn-primary btn-xs" id="processar" [disabled]="closeDisabled" [onClick]="searchAquisicaoContab">
            <i class="fa fa-fw fa-refresh"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
          </pl-button>

          <pl-edit type="checkbox" [(model)]="saldosIniciais" [properties]="{label: 'ativosaquisicaocontab.modal.saldosIniciais'}"></pl-edit>
        </div>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-dismiss" [disabled]="closeDisabled || nLancsList?.length <= 0" [onClick]="close" [promise]="promise">
      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span>
    </pl-button>

    <pl-button klass="btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
