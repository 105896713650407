import {ContabAdvancedOperationsModuleComponent} from './components/contabAdvancedOperations.module.component';
import {ROLE} from '../../../services/role.const';
import {
  EContabTipoOperacao,
  MODULE_NAME_CONTAB_ADVANCED_OPERATIONS_ANULAR,
  MODULE_NAME_CONTAB_ADVANCED_OPERATIONS_DIARIO,
  MODULE_NAME_CONTAB_ADVANCED_OPERATIONS_PERIODO
} from './contabAdvancedOperations.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';

export const MODULE_ADVANCEDOPERATIONS_PERIODO = moduleDefinitionGenerator(
  MODULE_NAME_CONTAB_ADVANCED_OPERATIONS_PERIODO,
  MODULE_NAME_CONTAB_ADVANCED_OPERATIONS_PERIODO,
  'global.menu.contabAdvancedOperationsPeriodo',
  EContabTipoOperacao.Periodo
);
export const MODULE_ADVANCEDOPERATIONS_DIARIO = moduleDefinitionGenerator(
  MODULE_NAME_CONTAB_ADVANCED_OPERATIONS_DIARIO,
  MODULE_NAME_CONTAB_ADVANCED_OPERATIONS_DIARIO,
  'global.menu.contabAdvancedOperationsDiario',
  EContabTipoOperacao.Diario
);
export const MODULE_ADVANCEDOPERATIONS_ANULACAO = moduleDefinitionGenerator(
  MODULE_NAME_CONTAB_ADVANCED_OPERATIONS_ANULAR,
  MODULE_NAME_CONTAB_ADVANCED_OPERATIONS_ANULAR,
  'global.menu.contabAdvancedOperationsAnular',
  EContabTipoOperacao.Anulacao
);

function moduleDefinitionGenerator(moduleName: string, moduleUrl: string, pageTitle: string, moduleType: EContabTipoOperacao): IModuleDefinition {
  return {
    name: moduleName,
    state: {
      url: `/${moduleUrl}`,
      component: ContabAdvancedOperationsModuleComponent,
      data: {
        roles: [ROLE.CONTABILIDADE],
        pageTitle: pageTitle
      },
      resolve: [{provide: 'tipoOperacao', useFactory: () => moduleType}]
    }
  };
}
