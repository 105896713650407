import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {isNumber} from 'pl-comps-angular';
import {IJsonModelo22, IJsonModelo22AnexoC} from '../../../jsonModelo22.module.interface';
import {round} from '../../../../../../../common/utils/utils';

@Component({
  selector: 'modelo22-anexos-anexoc',
  templateUrl: './modelo22.anexos.anexoC.component.html'
})
export class Modelo22AnexosAnexoCComponent implements OnChanges {
  @Input() public modelo22: IJsonModelo22;
  @Output() public readonly evtChangedModelo22: EventEmitter<IJsonModelo22>;

  constructor() {
    this.evtChangedModelo22 = new EventEmitter<IJsonModelo22>();
  }

  public ngOnChanges({modelo22}: SimpleChanges): void {
    if (modelo22 && !modelo22.isFirstChange()) {
      this.modelo22 = modelo22.currentValue;
    }
  }

  public calcCamp12(): void {
    const f11 = isNumber(this.anexoC.quadro05.campo11) ? this.anexoC.quadro05.campo11 : 0;
    const f21 = isNumber(this.anexoC.quadro05.campo21) ? this.anexoC.quadro05.campo21 : 0;
    this.anexoC.quadro05.campo12 = round(f11 * (f21 / 100));
    this.emitModelo22();
  }

  public emitModelo22(): void {
    this.evtChangedModelo22.emit(this.modelo22);
  }

  public get anexoC(): IJsonModelo22AnexoC {
    return this.modelo22.anexoC;
  }

  public set anexoC(value: IJsonModelo22AnexoC) {
    this.modelo22.anexoC = value;
  }
}
