import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPO_CALC_DUODECIMOS} from './tipoCalcDuodecimos.datasource.interface';
import {EDHEmpTipoCalcDuodecimos} from '../../../modules/portalrh/dgempsfull/jsonDGEMPFull.interface';
import {IDataSource} from '../../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPO_CALC_DUODECIMOS: IDataSource<EDHEmpTipoCalcDuodecimos> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPO_CALC_DUODECIMOS,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EDHEmpTipoCalcDuodecimos.SubNatalSubFerias, name: 'dgemps.twelfthsCalculationType.0', label: 'dgemps.twelfthsCalculationType.0'},
    {value: EDHEmpTipoCalcDuodecimos.SubNatal, name: 'dgemps.twelfthsCalculationType.1', label: 'dgemps.twelfthsCalculationType.1'},
    {value: EDHEmpTipoCalcDuodecimos.SubFerias, name: 'dgemps.twelfthsCalculationType.2', label: 'dgemps.twelfthsCalculationType.2'},
    {value: EDHEmpTipoCalcDuodecimos.NaoEfectuaProcessamento, name: 'dgemps.twelfthsCalculationType.3', label: 'dgemps.twelfthsCalculationType.3'}
  ]
});
