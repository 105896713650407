import {DocumentosFiscaisComponent} from './components/documentosFiscais.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_DOCUMENTOS_FISCAIS} from './documentosFiscais.module.interface';
import {RESOLVER_CG_STORE_URL_BACKOFFICE} from '../../../../../config/uirouter/uirouter.resolvers';
import {RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL} from '../../../../services/contabilidadedigital/contabilidadedigital.router.interface';
import {RESOLVER_LICENCA_STORE_MODE_PUBLIC} from '../../../../services/config/config.service.router';
import {ROLE} from '../../../../services/role.const';

export const MODULE_PCA_DOCUMENTOS_FISCAIS: IModuleDefinition = {
  name: MODULE_NAME_DOCUMENTOS_FISCAIS,
  state: {
    url: `/${MODULE_NAME_DOCUMENTOS_FISCAIS}`,
    component: DocumentosFiscaisComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.documentosFiscais'
    },
    params: {
      menu: {
        type: 'int',
        value: 0
      }
    },
    resolve: [RESOLVER_LICENCA_STORE_MODE_PUBLIC, RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL, RESOLVER_CG_STORE_URL_BACKOFFICE]
  }
};
