import {Component, Injector} from '@angular/core';
import {ERefeicoesSchedulerMode} from '../../../../../components/refeicoes/scheduler/refeicoes.scheduler.component.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';

@Component({
  selector: 'module-colaboradores-marcar-refeicoes',
  templateUrl: './marcacaoRefeicoes.module.component.html'
})
export class ColaboradoresMarcacaoRefeicoesModuleComponent extends ModuloComponent {
  public readonly mode: ERefeicoesSchedulerMode;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.mode = ERefeicoesSchedulerMode.Mark;
  }
}
