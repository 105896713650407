import {TDate} from '../../common/dates';

export enum EMovimentosEmAbertoDC {
  Debito,
  Credito
}

export interface IJsonMovimentosEmAberto {
  nLanc: string;
  nConta: string;
  nomeConta: string;
  dC: EMovimentosEmAbertoDC;
  nDocumento: string;
  nDocumentoFac: string;
  dataDoc: TDate;
  dataVenc: TDate;
  valor: number;
  jaPago: number;
  valorAPagar: number;
  nDocExterno: string;
  nRequisicao: string;
  descricao: string;
  descritivo: string;
  idGrupoContabDigital: string;
  grupoContabDigitalUnico: boolean;
  valorGrupoContabDigital: number;
  valorDesconto: number;
}

export interface IMovimentosEmAberto extends IJsonMovimentosEmAberto {
  _valorAPagarOrigem?: number;
  _focused?: boolean;
}
