import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_FATURACAO_PUBLICA_ESTADO} from './faturacaoPublicaEstado.datasource.interface';
import {EFEAEstado} from '../../modules/faturacaopublica/jsonFaturacaoPublica.module.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_FATURACAO_PUBLICA_ESTADO: IDataSource<EFEAEstado> = deepFreeze({
  name: DATA_SOURCE_NAME_FATURACAO_PUBLICA_ESTADO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EFEAEstado.PorComunicar, name: 'faturacaopublica.estado.porComunicar'},
    {value: EFEAEstado.ComunicadoEmValidacao, name: 'faturacaopublica.estado.comunicadoEmValidacao'},
    {value: EFEAEstado.ComunicadoValidado, name: 'faturacaopublica.estado.comunicadoValidado'},
    {value: EFEAEstado.ComunicadoComErro, name: 'faturacaopublica.estado.comunicadoComErro'},
    {value: EFEAEstado.ErroTratado, name: 'faturacaopublica.estado.erroTratado'},
    {value: EFEAEstado.Received, name: 'faturacaopublica.estado.received'},
    {value: EFEAEstado.Rejected, name: 'faturacaopublica.estado.rejected'},
    {value: EFEAEstado.Paid, name: 'faturacaopublica.estado.paid'},
    {value: EFEAEstado.ComunicadoEstruturaEmValidacao, name: 'faturacaopublica.estado.comunicadoEstruturaEmValidacao'}
  ]
});
