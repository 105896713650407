<nav class="pl-pagination">
  <ul *ngIf="showPagination || pages.length > 1" class="pagination pagination-sm">
    <li *ngIf="boundaryLinks" class="page-item pagination-first" [class.disabled]="page === 1">
      <button type="button" class="page-link" (click)="setCurrent(1)">
        <i class="fa fa-angle-double-left" aria-hidden="true"></i>
      </button>
    </li>

    <li *ngIf="directionLinks" class="page-item pagination-previous" [class.disabled]="page === 1">
      <button type="button" class="page-link" (click)="setCurrent(page - 1)">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
      </button>
    </li>

    <li *ngFor="let pageNumber of pages" class="page-item pagination-number" [class.active]="pageNumber === page" [class.disabled]="pageNumber === '...'">
      <button type="button" class="page-link" (click)="setCurrent(pageNumber)">{{ pageNumber }}</button>
    </li>

    <li *ngIf="directionLinks" class="page-item pagination-next" [class.disabled]="page === totalPages">
      <button type="button" class="page-link" (click)="setCurrent(page + 1)">
        <i class="fa fa-angle-right" aria-hidden="true"></i>
      </button>
    </li>

    <li *ngIf="boundaryLinks" class="page-item pagination-last" [class.disabled]="page === totalPages">
      <button type="button" class="page-link" (click)="setCurrent(totalPages)">
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
      </button>
    </li>
  </ul>

  <ul class="pl-pagination-lines">
    <li class="pagination-per-page">
      <label [attr.for]="'pagination-select-' + instanceId">{{ locale.text.lines }}:</label>
      <select class="form-control pl-pagination-select" [attr.id]="'pagination-select-' + instanceId" [ngModel]="perPage" (ngModelChange)="setPerPage($event)">
        <option *ngFor="let line of lines" [ngValue]="line">{{ line }}</option>
      </select>
    </li>
  </ul>
</nav>
