<div class="modal-saft-contab-convert-contas" plFormNavigation>
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'saftcontab.modals.convertcontas.title'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'saftcontab.modals.convertcontas.contaorigem'"></label>
      <edit>
        <pl-autocomplete
          [source]="saftPOCSource"
          [model]="saftPOC"
          (modelChange)="convertContaChanged($event)"
          [properties]="{disabled: disableOrigem}"
          [modelOptions]="{debounce: 500}"
          [rowTemplate]="convertContaTemplate"
          [output]="convertContaTemplate">
        </pl-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'saftcontab.modals.convertcontas.contadestino'"></label>
      <edit>
        <entity-autocomplete
          entity="pocs"
          attrName="nContaDestino"
          [model]="convertConta"
          (selectedKeyChange)="convertConta.nContaDestino = $event"
          (evtSelectedDescriptionChanged)="convertConta.nomeContaDestino = $event"
          [fieldsMap]="{nConta: 'nContaDestino', nome: 'nomeContaDestino'}"
          [filter]="filterMovimento"
          [output]="pocsOutput"
          plAutoFocus>
        </entity-autocomplete>
      </edit>
    </pl-group>

    <hr />

    <pl-split-view [initialSizeLeft]="50">
      <div *plSplitViewLeftSide>
        <h5 class="text-primary" [translate]="'saftcontab.strings.planoSaft'"></h5>
        <dx-tree-list
          id="treeListSaft"
          [cacheEnabled]="false"
          [dataSource]="treeDetail.treeSaft"
          [showBorders]="true"
          [showColumnHeaders]="false"
          [autoExpandAll]="true"
          [columnAutoWidth]="true"
          keyExpr="nconta"
          parentIdExpr="parent">
          <dxi-column dataField="nconta"></dxi-column>
          <dxi-column dataField="nome"></dxi-column>
        </dx-tree-list>
      </div>

      <div *plSplitViewRightSide>
        <h5 class="text-primary" [translate]="'saftcontab.strings.planoCG'"></h5>
        <dx-tree-list
          id="treeListCG"
          [cacheEnabled]="false"
          [dataSource]="treeDetail.treeCG"
          [showBorders]="true"
          [showColumnHeaders]="false"
          [autoExpandAll]="true"
          [columnAutoWidth]="true"
          [selection]="{mode: 'single'}"
          (onSelectionChanged)="onSelectionChangedTreeList($event)"
          (onCellDblClick)="onCellDblClick()"
          keyExpr="nconta"
          parentIdExpr="parent">
          <dxi-column dataField="nconta"></dxi-column>
          <dxi-column dataField="nome"></dxi-column>
        </dx-tree-list>
      </div>
    </pl-split-view>

    <dx-data-grid [cgDxDataGrid]="dataGridDefinitionContasSemConvertPlano" [dataSource]="treeDetail.contasBase" (onCellClick)="onCellClick($event)">
      <div *dxTemplate="let itemDetail of 'masterDetailContasSemConvertPlano'">
        <dx-data-grid [cgDxDataGrid]="dataGridDefinitionContasDoc" [dataSource]="itemDetail.data.docList" (onCellPrepared)="onCellPrepared($event)"></dx-data-grid>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-sm btn-primary action-ok" [disabled]="closeDisabled" [onClick]="close" data-focus>
      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span>
    </pl-button>

    <pl-button klass="btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
