import {StateDeclaration} from '@uirouter/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IEntity} from '../../entity.definition.interface';
import {ModuloEntityListComponent} from '../../../module/entitylist/module.entitylist.component';
import {ModuloEntityDetailComponent} from '../../../module/entitydetail/module.entitydetail.component';
import {IApiRequestConfigWithBody} from '../../../../services/api/api.service.interface';
import {ICGModalOptions} from '../../../cg/modal/cgmodal.interface';

export enum EEntityMaintenanceListModalOnSelect {
  SELECT,
  EDIT
}

export enum EEntityMaintenanceListModalResult {
  UNDEFINED = 'entity-maintenance-list-modal-result-0',
  ACTION_NEW = 'entity-maintenance-list-modal-result-1'
}

export enum EEntityMaintenanceEditMode {
  Default = 0,
  ReadOnly = 1,
  EditOnly
}

export type TEntityMaintenanceActionMaintenanceListFn<T extends object = object> = () => T | Promise<T>;

export type TEntityMaintenanceActionMaintenanceEditFn<T extends object = object> = (id: string | number) => T | Promise<T>;

export interface IEntityMaintenanceInstance<T extends object = object> {
  maintenanceHeader: string;
  maintenanceHeaderList: string;
  maintenanceHeaderEdit: string;
  maintenanceAllowList: boolean;
  maintenanceAllowNew: boolean;
  maintenanceAllowEdit: boolean;
  stateMaintenance: StateDeclaration;
  entity: IEntity;
  entityName: string;

  maintenanceList<S extends ModuloEntityListComponent<T>>(options?: Partial<IEntityMaintenanceListModalOptions<T, S>>): Promise<T>;

  maintenanceNew<S extends ModuloEntityDetailComponent<T>>(options?: Partial<IEntityMaintenanceEditModalOptions<T, S>>): Promise<T>;

  maintenanceEdit<S extends ModuloEntityDetailComponent<T>>(id: string | number, options?: Partial<IEntityMaintenanceEditModalOptions<T, S>>): Promise<T>;

  maintenanceSelectAndEdit<S extends ModuloEntityListComponent<T>>(options?: Partial<IEntityMaintenanceListModalOptions<T, S>>): Promise<T>;

  evaluateStates(): void;
}

export interface IEntityMaintenanceInstanceProperties<T extends object = object> {
  actionMaintenanceList?: TEntityMaintenanceActionMaintenanceListFn<T>;
  actionMaintenanceEdit?: TEntityMaintenanceActionMaintenanceEditFn<T>;
  captionMaintenanceList?: string;
  captionMaintenanceEdit?: string;
}

export interface IEntityMaintenanceModalOptions<T> {
  params?: object;
  preventDocumentKeyup?: boolean;
  modalOptions?: ICGModalOptions<T>;
}

export interface IEntityMaintenanceListModalOptions<T extends object = object, S extends ModuloEntityListComponent<T> = ModuloEntityListComponent<T>> extends IEntityMaintenanceModalOptions<T> {
  filter?: string;
  initialFilter?: string;
  onSelect?: EEntityMaintenanceListModalOnSelect;

  onMaintenanceReady?(maintenance: IEntityMaintenanceOnMaintenanceListReady<T, S>): void;
}

export interface IEntityMaintenanceEditModalOptions<T extends object = object, S extends ModuloEntityDetailComponent<T> = ModuloEntityDetailComponent<T>> extends IEntityMaintenanceModalOptions<T> {
  mode?: EEntityMaintenanceEditMode;

  onMaintenanceSave?(event: IEntityMaintenanceOnMaintenanceSave<T, S>): Promise<T>;
}

export interface IEntityMaintenanceOnMaintenanceListReady<T extends object = object, S extends ModuloEntityListComponent<T> = ModuloEntityListComponent<T>> {
  modalInstance: NgbModalRef;
  componentRefInstance: S;
  result: Promise<T>;
}

export interface IEntityMaintenanceOnMaintenanceSave<T extends object = object, S extends ModuloEntityDetailComponent<T> = ModuloEntityDetailComponent<T>> {
  config: IApiRequestConfigWithBody<T>;
  modalInstance: NgbModalRef;
  componentRefInstance: S;
  result: Promise<T>;
}
