<div class="pl-split-view" [class.pl-split-view-hide-left-side]="hideLeftSide" [class.pl-split-view-hide-right-side]="hideRightSide">
  <div *ngIf="!hideLeftSide" #elementLeftSide class="pl-split-view-left-side" [style.width]="sizeLeftWidth">
    <ng-container *ngTemplateOutlet="leftSideDirective?.templateRef"></ng-container>
  </div>

  <pl-split-view-resizer *ngIf="!hideLeftSide && !hideRightSide" (evtMouseDowned)="mouseDowned($event)" (evtResizerMoved)="resizerMoved($event)" (evtMouseUpped)="mouseUpped()">
  </pl-split-view-resizer>

  <div *ngIf="!hideRightSide" #elementRightSide class="pl-split-view-right-side" [style.width]="sizeRightWidth">
    <ng-container *ngTemplateOutlet="rightSideDirective?.templateRef"></ng-container>
  </div>
</div>
