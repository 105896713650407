import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonMapasAmortizacao} from './jsonMapasAmortizacao.entity.interface';
import {ENTITY_NAME_MAPAS_AMORTIZACAO} from './mapasAmortizacao.entity.interface';

export const ENTITY_MAPAS_AMORTIZACAO: IEntityDefinition<IJsonMapasAmortizacao> = {
  name: ENTITY_NAME_MAPAS_AMORTIZACAO,
  roles: [ROLE.ATIVOS],
  searchPlaceholder: 'mapasamortizacao.pesquisa',
  sidebarTitle: 'portals.sidebar.modules.mapasamortizacao',
  metadata: {
    keyName: 'nImmap',
    fields: [
      {
        name: 'nImmap',
        width: '120px',
        caption: 'mapasamortizacao.fields.nImmap',
        placeholder: 'mapasamortizacao.fields.nImmapPlaceholder',
        validators: {required: true, maxlength: 5}
      },
      {name: 'nome', caption: 'mapasamortizacao.fields.nome', placeholder: 'mapasamortizacao.fields.nomePlaceholder', validators: {required: true, maxlength: 50}}
    ],
    order: 'nome',
    searchFields: 'nImmap,nome'
  },
  autocomplete: {
    rowTemplate: '{{nImmap}} - {{nome}}',
    output: '{{nImmap}} - {{nome}}',
    outputDescription: 'nome',
    searchFields: 'nImmap,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.mapasamortizacao'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.mapasamortizacao'
      }
    }
  }
};
