import moment from 'moment';
import {Workbook, Worksheet} from 'exceljs';
import {jsPDF} from 'jspdf';
import 'jspdf-autotable';
import {exportDataGrid as exportDataGridToExcel} from 'devextreme/excel_exporter';
import {exportDataGrid as exportDataGridToPdf} from 'devextreme/pdf_exporter';
import {TranslateService} from '@ngx-translate/core';
import {downloadStream, IPlDynamicVisualsSecondaryClickAction, isEmpty, isFunction, isString} from 'pl-comps-angular';
import {IDevExpressDataGridEventOnExporting} from '../events/devexpress.datagrid.events.interface';
import {IDevExpressDataGridExportExcelProps, IDevExpressDataGridExportPdfProps, IExcelExportDataGridProps, IPdfExportDataGridProps} from './devexpress.datagrid.export.interface';
import {IJsPDFOptions} from '../../../../../common/interfaces/jspdf.interface';

const FILENAME_FORMAT = 'YYYYMMDD';
const FILENAME_INVALID_CHARACTERS = /[<>:"\\/|?*]/g;
const EXCEL_WORKSHEET_NAME_INVALID_CHARACTERS = /[*?:\\/[\]]/g;
const EXCEL_WORKSHEET_NAME_MAXIMUM_LENGTH = 31;

export async function devExpressDataGridExportToExcel(event: IDevExpressDataGridEventOnExporting, translateService: TranslateService, properties?: IDevExpressDataGridExportExcelProps): Promise<void> {
  // Disable deprecated built-in export functionality
  event.cancel = true;

  let filename: string;
  if (!isEmpty(properties?.filename)) {
    filename = await (isFunction(properties?.filename) ? properties?.filename() : properties?.filename);
    filename = translateService.instant(filename);
    if (!filename.endsWith('xls') && !filename.endsWith('xlsx')) {
      filename += '.xlsx';
    }
  } else {
    filename = `Datagrid-${moment().format(FILENAME_FORMAT)}.xlsx`;
  }
  filename = filename.replace(FILENAME_INVALID_CHARACTERS, '').trim();

  const worksheetName: string = (isString(properties?.worksheetName) ? properties.worksheetName : filename.substring(0, filename.lastIndexOf('.'))) // filename without extension
    .replace(EXCEL_WORKSHEET_NAME_INVALID_CHARACTERS, '')
    .trim()
    .slice(0, EXCEL_WORKSHEET_NAME_MAXIMUM_LENGTH);

  const workbook: Workbook = new Workbook();
  const worksheet: Worksheet = workbook.addWorksheet(worksheetName);

  const exportOptions: IExcelExportDataGridProps = {
    worksheet: worksheet,
    component: event.component,
    encodeExecutableContent: true,
    ...properties
  };

  if (isFunction(properties.customizeWorksheet)) {
    await properties.customizeWorksheet(exportOptions);
  }

  await exportDataGridToExcel(exportOptions);

  const buffer: BlobPart = await workbook.xlsx.writeBuffer(properties?.xlsxWriteOptions);
  downloadStream(new Blob([buffer], {type: 'application/octet-stream'}), filename);
}

export async function devExpressDataGridExportToPdf(event: IDevExpressDataGridEventOnExporting, translateService: TranslateService, properties?: IDevExpressDataGridExportPdfProps): Promise<void> {
  // Disable deprecated built-in export functionality
  event.cancel = true;

  let filename: string;
  if (!isEmpty(properties?.filename)) {
    filename = await (isFunction(properties?.filename) ? properties?.filename() : properties?.filename);
    filename = translateService.instant(filename);
    if (!filename.endsWith('pdf')) {
      filename += '.pdf';
    }
  } else {
    filename = `Documento-${moment().format(FILENAME_FORMAT)}.pdf`;
  }
  filename = filename.replace(FILENAME_INVALID_CHARACTERS, '').trim();

  const jsPDFOptions: IJsPDFOptions = {
    orientation: 'landscape',
    ...properties?.jsPDFOptions
  };
  const jsPDFDocument: jsPDF = new jsPDF(jsPDFOptions);

  const exportOptions: IPdfExportDataGridProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    jsPDFDocument: <any>jsPDFDocument,
    component: event.component,
    ...properties
  };

  if (isFunction(properties.customizePdf)) {
    await properties.customizePdf(exportOptions);
  }

  await exportDataGridToPdf(exportOptions);

  jsPDFDocument.save(filename);
}

export function devExpressDataGridGenerateExportMenuActionExcel(
  event: IDevExpressDataGridEventOnExporting,
  translateService: TranslateService,
  properties?: IDevExpressDataGridExportExcelProps
): IPlDynamicVisualsSecondaryClickAction {
  return {
    caption: 'global.export.toExcel',
    icon: 'fa-file-excel-o',
    click: () => devExpressDataGridExportToExcel(event, translateService, properties)
  };
}

export function devExpressDataGridGenerateExportMenuActionPdf(
  event: IDevExpressDataGridEventOnExporting,
  translateService: TranslateService,
  properties?: IDevExpressDataGridExportPdfProps
): IPlDynamicVisualsSecondaryClickAction {
  return {
    caption: 'global.export.toPDF',
    icon: 'fa-file-pdf-o',
    click: () => devExpressDataGridExportToPdf(event, translateService, properties)
  };
}
