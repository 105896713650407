import {ContratosService} from '../../../contratos/contratos.entity.service';
import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IContratoCabWithData, IContratoEditStateParams, IJsonContrato, IJsonContratoCab} from '../../../contratos/jsonContrato.entity.interface';
import {IModuleEntityListOnDataGridCellClick} from '../../../../components/module/entitylist/module.entitylist.interface';
import {HttpResponse} from '@angular/common/http';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ConfigOptionsService} from '../../../../services/config/options/config.options.service';
import {EConfigOptionsInstanceName, IContratosListConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../../config/constants';
import {Subscription} from 'rxjs';
import moment from 'moment';
import {IConfigChanged} from '../../../../components/configoptions/config.options.component.interface';
import {MODULE_NAME_FATURACAO_CONTRATOS} from '../../../../modules/faturacaocontratos/faturacaoContratos.module.interface';
import {AvencasSelecionarTipoModalComponent} from '../../modal/selecionartipo/avencas.selecionar.tipo.modal.component';
import {GestaoAvencasModalComponent} from '../../../../modules/faturacaocontratos/modals/gestaoavencasfaturadas/gestaoAvencas.modal.component';
import {ENTITY_NAME_CLASSES_CONTRATOS_AVENCAS} from '../../../classescontratos/classesContratos.entity.interface';

import {ENTITY_NAME_CONTRATOS_TIPO_AVENCAS} from '../../../contratostipoavencas/contratosTipoAvencas.entity.interface';

const BAR_BUTTON_FATURACAO_AVENCAS = 201;

@Component({
  selector: 'avencas-list',
  templateUrl: './avencas.entity.list.component.html'
})
export class AvencasListComponent extends ModuloEntityListComponent<IJsonContratoCab> implements OnInit, OnDestroy {
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public dataGridProperties: IDevExpressDataGrid;

  private readonly _filterNaoAtivos: string;
  private _optionShowNaoAtivosOnly: boolean;
  private _subscriptionConfigOptions: Subscription;
  private _searchAux: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _contratosService: ContratosService,
    private readonly _configOptionsService: ConfigOptionsService
  ) {
    super(_injector);
    this.dataGridProperties = {allowColumnResizing: true};
    this._contratosService.isAvenca = true;
    this.configOptionsInstanceName = EConfigOptionsInstanceName.CONTRATOS_LIST;
    this.configOptionsGroupName = EGroupName.ERP;
    const dataFim = moment().subtract(1, 'days');
    this._filterNaoAtivos = `terminado=1||dataFim<=${dataFim.toJSON()}`;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.toolbar.addButton({
      id: 'btnFaturacaoAvencas',
      type: 'button',
      order: BAR_BUTTON_FATURACAO_AVENCAS,
      class: 'btn-warning',
      iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
      caption: 'contratosservicos.modulos.faturacao',
      click: () => this._callModuloFaturacaoAvencas()
    });

    this.setModuleActions([
      {
        caption: 'contratosservicos.modulos.classes',
        iconLeft: '<i class="fa fa-fw fa-list-alt"></i>',
        order: 203,
        action: () => this._callModuloClassesContratos()
      },
      {
        caption: 'contratosservicos.modulos.tipos',
        iconLeft: '<i class="fa fa-fw fa-indent"></i>',
        order: 204,
        action: () => this._callModuloTiposAvencas()
      },
      {
        caption: 'faturacaoContratos.btn.gestaoAvencas',
        iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
        order: 205,
        action: () => this._gestaoAvencas()
      },
      {
        caption: 'contratosservicos.modal.faturacaoAuto.title',
        iconLeft: '<i class="fa fa-fw fa-cog"></i>',
        order: 206,
        action: () => this._callModuloSelTiposAvencas()
      }
    ]);

    this._searchAux = this.searchValue;
    this._subscriptionConfigOptions = this._configOptionsService
      .getGroupOptions(this.configOptionsGroupName)
      .get(this.configOptionsInstanceName)
      .options()
      .subscribe((configOptions: TConfigOptions<boolean, IContratosListConfigOptions>) => {
        this._optionShowNaoAtivosOnly = configOptions.get('showNaoAtivosOnly').value;
      });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._configOptionsService.getGroupOptions(this.configOptionsGroupName).get(this.configOptionsInstanceName).setDefaultOptions();
    if (this._subscriptionConfigOptions) {
      this._subscriptionConfigOptions.unsubscribe();
    }
  }

  public onDetail(event: IModuleEntityListOnDataGridCellClick<IContratoCabWithData>): Promise<void> {
    const {data} = event;
    return this.service.get<IJsonContrato>({id: data.refCtrCab}).then((response: HttpResponse<IJsonContrato>) => {
      data._contrato = response.body;
      return super.onDetail(event);
    });
  }

  public onConfigChange(value: IConfigChanged): Promise<void> {
    this._setNaoAtivosConfigChange(value.value);
    return this.refreshList();
  }

  public onRevertConfigOptionsToDefault(): Promise<void> {
    this._setNaoAtivosConfigChange(this._optionShowNaoAtivosOnly);
    return this.refreshList();
  }

  public onSearchValueChange(searchValue: string): Promise<void> {
    this.searchValue = '';
    if (this._optionShowNaoAtivosOnly) {
      this.searchValue = searchValue ? `${searchValue}&${this._filterNaoAtivos}` : this._filterNaoAtivos;
    } else {
      this.searchValue = searchValue;
      this._searchAux = this.searchValue;
    }
    return this.refreshList();
  }

  public readonly fnEditAvenca = (avenca: IJsonContrato): Promise<void> => this._editAvenca(avenca);

  public readonly fnReabrirAvenca = (contrato: IJsonContrato): Promise<IJsonContrato> => this._reabrirAvenca(contrato);

  public readonly fnTerminarAvenca = (contrato: IJsonContrato): Promise<IJsonContrato> => this._terminarAvenca(contrato);

  public readonly fnApagarAvenca = (contrato: IJsonContrato): Promise<void> => this._apagarAvenca(contrato);

  private _editAvenca(avenca: IJsonContrato): Promise<void> {
    const paramAvenca: IJsonContrato = {
      ...avenca
    };
    const stateParams: IContratoEditStateParams = {id: paramAvenca.cab.refCtrCab, contrato: paramAvenca};
    return this._stateService.go(this.states.detail.name, stateParams).then(() => undefined);
  }

  private _reabrirAvenca(contrato: IJsonContrato): Promise<IJsonContrato> {
    const message = this._translateService.instant('contratosservicos.message.temCertezaReabrir', {avenca: contrato.cab.nContratoSemVersaoAsString});
    return this._cgModalService.showOkCancel('contratosservicos.info.reabrir', message, {size: 'md'}).then(() =>
      this._contratosService.reabrirContrato(contrato).then((result) => {
        this.refreshList();
        return result;
      })
    );
  }

  private _terminarAvenca(contrato: IJsonContrato): Promise<IJsonContrato> {
    const message = this._translateService.instant('contratosservicos.message.temCertezaTerminar', {avenca: contrato.cab.nContratoSemVersaoAsString});
    return this._cgModalService.showOkCancel('contratosservicos.info.terminar', message, {size: 'md'}).then(() =>
      this._contratosService.terminarContrato(contrato).then((result) => {
        this.refreshList();
        return result;
      })
    );
  }

  private _apagarAvenca(contrato: IJsonContrato): Promise<void> {
    const message = this._translateService.instant('contratosservicos.message.temCertezaApagar', {avenca: contrato.cab.nContratoSemVersaoAsString});
    return this._cgModalService.showOkCancel('contratosservicos.info.apagar', message, {size: 'md'}).then(() =>
      this._contratosService.apagarContrato(contrato.cab.refCtrCab).then((result) => {
        this.refreshList();
        return result;
      })
    );
  }

  private _setNaoAtivosConfigChange(value: boolean): void {
    if (value) {
      this.searchValue = this._searchAux ? `${this._searchAux}&${this._filterNaoAtivos}` : this._filterNaoAtivos;
    } else {
      this.searchValue = this._searchAux;
    }
  }

  private _callModuloFaturacaoAvencas(): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_FATURACAO_CONTRATOS, params: {moduleNameRetroceder: this.entity.name}});
  }

  private _callModuloClassesContratos(): Promise<void> {
    const maintenanceClassesContratos = this._entityMaintenanceService.build(ENTITY_NAME_CLASSES_CONTRATOS_AVENCAS);
    return maintenanceClassesContratos.maintenanceSelectAndEdit().then(() => undefined);
  }

  private _callModuloTiposAvencas(): Promise<void> {
    const maintenanceTiposAvencas = this._entityMaintenanceService.build(ENTITY_NAME_CONTRATOS_TIPO_AVENCAS);
    return maintenanceTiposAvencas.maintenanceSelectAndEdit().then(() => undefined);
  }

  private _callModuloSelTiposAvencas(): Promise<void> {
    const instance = this._cgModalService.showVanilla(AvencasSelecionarTipoModalComponent, {size: 'md'});
    return instance.result.then((response: string) => {
      if (response) {
        const maintenanceTiposAvencas = this._entityMaintenanceService.build(ENTITY_NAME_CONTRATOS_TIPO_AVENCAS);
        return maintenanceTiposAvencas.maintenanceEdit(response).then(() => undefined);
      }
      return undefined;
    });
  }

  private _gestaoAvencas(): Promise<void> {
    return new Promise<void>((resolve) => {
      this._cgModalService.showVanilla(GestaoAvencasModalComponent, {size: 'xxl'});
      resolve();
    });
  }
}
