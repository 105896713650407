import {firstValueFrom} from 'rxjs';
import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {EDelphiNumberTypes, IPlToolbarItem, KEYCODES, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {IImpAvisosCobrancaFilters} from '../../avisosCobranca.module.interface';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {ImpAvisosCobrancaErrosModalComponent} from '../modal/impAvisosCobranca.erros.modal.component';
import {AvisoscobrancaService} from '../../avisosCobranca.module.service';
import {MAX_DATE_CG, MIN_DATE_CG} from '../../../../../../common/utils/utils';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonClifo} from '../../../../../entities/clifos/jsonClifo.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {ENTITY_NAME_CLIENTES} from '../../../../../entities/clifos/clifos.entity.interface';
import {CheckSendAllMailsModalComponent} from '../../../../../components/emailsCliFo/modal/checkSendAll/checkSendAllMails.modal.component';
import {EmailClifoSendMailModalComponent} from '../../../../../components/emailsCliFo/modal/emailClifo.modal.component';
import {IJsonEmailClifo} from '../../../../../components/emailsCliFo/jsonEmailCliFo.interface';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';

const BTN_PROCESS_PDF = 'processPDF';

@Component({
  selector: 'imp-avisos-cobranca',
  templateUrl: './impAvisosCobranca.module.component.html'
})
export class ImpAvisosCobrancaModuleComponent extends ModuloComponent implements OnInit {
  public readonly filters: IImpAvisosCobrancaFilters;
  public readonly promise: Promise<void>;
  public report: IJsonReport;
  public pdfUrl: string;

  private readonly _clifosService: IEntityService<IJsonClifo>;
  private readonly _btnProcessPDF: IPlToolbarItem;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _impAvisosCobrancaService: AvisoscobrancaService,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _cgModalService: CGModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this._clifosService = this._entityServiceBuilder.build(ENTITY_NAME_CLIENTES);
    this.filters = {
      ncontade: '211',
      ncontaate: '2119999999',
      datadocde: MIN_DATE_CG,
      datadocate: MAX_DATE_CG,
      datavencimentode: MIN_DATE_CG,
      datavencimentoate: MAX_DATE_CG,
      nvendedorde: 0,
      nvendedorate: EDelphiNumberTypes.MaxSmallInt,
      ncondpagamde: 0,
      ncondpagamate: EDelphiNumberTypes.MaxSmallInt
    };
    this.promise = this._loadDefaultReport();
    this._btnProcessPDF = {
      id: BTN_PROCESS_PDF,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      class: 'btn-success',
      caption: 'global.btn.processPDF',
      click: () => this._preview()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnProcessPDF).addButton({
      id: 'sendmail',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-envelope-o"></i>',
      class: 'btn-success',
      caption: 'emailclifos.sendMail.btn.sendMail',
      click: () => this._sendMail()
    });
  }

  public onNContaDeChanged(value: string): void {
    this.filters.ncontade = value;
    this._clifosService.get({id: value, reportExceptions: false}).then(() => {
      this.filters.ncontaate = value;
    });
  }

  public readonly fnKeydownProcessar = (value: string, event: KeyboardEvent): void => {
    this._keydownProcessar(event);
  };

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _keydownProcessar(event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();
      this.toolbar.focusItem(BTN_PROCESS_PDF);
    }
  }

  private _preview(): Promise<void> {
    return firstValueFrom(this._impAvisosCobrancaService.getAvisosCobrancaPDFUrl(this.filters, this.report.name)).then((url: string) => {
      this.pdfUrl = url;
      this._cardPanel.collapse();
    });
  }

  private _sendMail(): Promise<void> {
    return this._impAvisosCobrancaService.getEmailsToSend(this.filters).then((result) => {
      if (result.body.emails.length > 0) {
        this._sendEmailRecursive(0, result.body.emails.length, result.body.emails, result.body.errors, true);
      } else {
        this._plAlertService.error('avisoscobranca.modal.erros.noEmails');
      }
    });
  }

  private _sendEmailRecursive(numEmail: number, emailCount: number, emails: Array<IJsonEmailClifo>, errors: Array<string>, firstEmail: boolean): void {
    const modalInstance = this._cgModalService.showVanilla(EmailClifoSendMailModalComponent);
    const componentInstance: EmailClifoSendMailModalComponent = modalInstance.componentInstance;
    componentInstance.emailContent = emails[numEmail];
    componentInstance.numEmails = emailCount;
    modalInstance.result.then((emailContentRec: IJsonEmailClifo) => {
      ++numEmail;
      if (firstEmail && emailContentRec && emailCount > 1) {
        firstEmail = false;
        const instanceModalCheckSendAll = this._cgModalService.showVanilla(CheckSendAllMailsModalComponent, {size: 'md'});
        instanceModalCheckSendAll.result.then((sendAll: boolean) => {
          if (!sendAll) {
            this._impAvisosCobrancaService.sendEMail(this.filters, emailContentRec, this.report.name, emailContentRec.nConta).then(() => {
              this._plAlertService.success(this._plTranslateService.translate('emailclifos.sendMail.messageSuccess', {emailList: emailContentRec.emails}));
            });
            this._sendEmailRecursive(numEmail, emailCount, emails, errors, firstEmail);
          } else {
            if (errors.length !== 0) {
              const modalInstanceCobrancaErros = this._cgModalService.showVanilla(ImpAvisosCobrancaErrosModalComponent);
              const componentInstanceCobrancaErros: ImpAvisosCobrancaErrosModalComponent = modalInstanceCobrancaErros.componentInstance;
              componentInstanceCobrancaErros.erros = errors;
            }
            this._impAvisosCobrancaService.sendEMail(this.filters, emailContentRec, this.report.name).then(() => {
              this._plAlertService.success('emailclifos.sendMail.sendAllMessage');
            });
          }
        });
      } else if (emailContentRec) {
        this._impAvisosCobrancaService.sendEMail(this.filters, emailContentRec, this.report.name, emailContentRec.nConta).then(() => {
          this._plAlertService.success(this._plTranslateService.translate('emailclifos.sendMail.messageSuccess', {emailList: emailContentRec.emails}));
        });
        if (numEmail < emailCount) {
          this._sendEmailRecursive(numEmail, emailCount, emails, errors, firstEmail);
        } else if (errors.length !== 0) {
          const modalInstanceCobrancaErros = this._cgModalService.showVanilla(ImpAvisosCobrancaErrosModalComponent);
          const componentInstanceCobrancaErros: ImpAvisosCobrancaErrosModalComponent = modalInstanceCobrancaErros.componentInstance;
          componentInstanceCobrancaErros.erros = errors;
        }
      } else if (numEmail < emailCount) {
        this._sendEmailRecursive(numEmail, emailCount, emails, errors, firstEmail);
      } else if (errors.length !== 0) {
        const modalInstanceCobrancaErros = this._cgModalService.showVanilla(ImpAvisosCobrancaErrosModalComponent);
        const componentInstanceCobrancaErros: ImpAvisosCobrancaErrosModalComponent = modalInstanceCobrancaErros.componentInstance;
        componentInstanceCobrancaErros.erros = errors;
      }
    });
  }

  private _loadDefaultReport(): Promise<void> {
    return this._reportsRegistryService
      .get(EReport.ImpAvisosCobranca)
      .query({pagina: 1, porpagina: 1})
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.report = reports[0];
        }
      });
  }
}
