import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {PortalClientAccountService} from '../../../../../services/portalclientaccount/portalclientaccount.service';
import {EPcaTipoExportacao, IPcaContabilidadeServiceOptions} from '../../pca.contabilidade.module.service.interface';
import {HttpResponse} from '@angular/common/http';
import moment from 'moment';
import {IJsonPOC} from '../../../../../entities/pocs/jsonPOC.entity.interface';
import {ETipoContaContabilidade} from '../../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IMovimentosAbertoClifo} from '../movimentosAberto.module.interface';
import {IJsonBalanceteAno} from '../../../../portalcontabilidade/estatistica/balancetes/jsonContabilidade.balancetes.module.interface';
import {isBoolean} from 'pl-comps-angular';

@Component({
  selector: 'modal-movimentos-aberto',
  templateUrl: './movimentosAberto.modal.component.html'
})
export class MovimentosAbertoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public nConta: string;
  @Input() public nomeConta: string;
  @Input() public showNconta: boolean;
  @Input() public tipo: string;

  public readonly pocOutput: string;

  public pdfUrl: string;
  public reportModel: IJsonReport;
  public options: IPcaContabilidadeServiceOptions;
  public filtro: string;
  public model: IMovimentosAbertoClifo;

  private _ano: number;
  private _periodo: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _portalclientaccountsFactory: PortalClientAccountService,
    private readonly _reportsRegistryService: ReportsRegistryService
  ) {
    super(_injector);

    this.reportModel = {
      name: '',
      title: ''
    };

    this.pocOutput = '{{nConta}} - {{nome}}';
  }

  public ngOnInit(): void {
    this._ano = moment().year();
    this._periodo = this._getPeriodo();

    this._loadDefaultReport().then(() => {
      this.filtro = `tipo=${ETipoContaContabilidade.Movimento}`;
      if (this.tipo === 'cliente') {
        this.filtro += '&nconta>=21&nconta<=21699999';
      }
      if (this.tipo === 'fornecedor') {
        this.filtro += '&nconta>=22&nconta<=228999999';
      }
      this.model = {nConta: this.nConta, nomeConta: this.nomeConta};
      this.showNconta = isBoolean(this.showNconta) ? this.showNconta : Boolean(this.nConta);

      this._portalclientaccountsFactory.getAnosContabilidade().then((response: HttpResponse<Array<IJsonBalanceteAno>>) => {
        if (response) {
          const ano = response.body.find((item: IJsonBalanceteAno) => item.encerrado === false).ano;
          this._ano = ano ? ano : this._ano;
          this._periodo = this._getPeriodo();
          this._getMovimentosEmAberto();
        }
      });
    });
  }

  public onNContaChange(poc: IJsonPOC): void {
    if (poc) {
      this.nConta = poc.nConta;
      this.nomeConta = poc.nome;
      this.model = {nConta: this.nConta, nomeConta: this.nomeConta};
      this._getMovimentosEmAberto();
    }
  }

  public onReportChange(report: IJsonReport): void {
    this.reportModel = report;
    this._getMovimentosEmAberto();
  }

  private _getPeriodo(): string {
    const month = `${moment().month() + 1}`;
    return `${this._ano}${month.padStart(2, '0')}1`;
  }

  private _getMovimentosEmAberto(): void {
    this._portalclientaccountsFactory.getMovimentosAbertoUrl(this._ano, this._periodo, this.nConta, EPcaTipoExportacao.Pdf, this.reportModel.name).subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }

  private _loadDefaultReport(): Promise<void> {
    const reportModel = this._configService.configurations.contabilidade.movimentosemaberto.reportImpressao;
    return this._reportsRegistryService
      .get(EReport.MovimentosEmAberto)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          let foundDefault = false;
          for (const report of reports) {
            if (report.name === reportModel) {
              this.reportModel = report;
              foundDefault = true;
              break;
            }
          }
          if (!foundDefault) {
            this.reportModel = reports[0];
          }
        }
      });
  }
}
