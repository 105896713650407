import type {Subscription} from 'rxjs';
import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import type {IPlFormatConfig} from '../common/format/format.service.interface';
import {PlFormatService} from '../common/format/format.service';
import {PlKeyboardService} from './keyboard.service';

@Directive({
  selector: 'input[plNumericOnly],textarea[plNumericOnly]',
  standalone: false
})
export class PlNumericOnlyDirective implements OnInit, OnChanges, OnDestroy {
  @Input() public plNumericOnly: '' | Partial<IPlFormatConfig>;

  private readonly _element: HTMLInputElement;
  private readonly _subscriptionFormat: Subscription;
  private _format: IPlFormatConfig;
  private _options: IPlFormatConfig;

  constructor(
    private readonly _elementRef: ElementRef<HTMLInputElement>,
    private readonly _plFormatService: PlFormatService,
    private readonly _plKeyboardService: PlKeyboardService
  ) {
    this._element = this._elementRef.nativeElement;
    this._element.addEventListener<'keydown'>('keydown', this._fnKeyDown);
    this._subscriptionFormat = this._plFormatService.format.subscribe((format: IPlFormatConfig) => {
      this._format = format;
    });
  }

  public ngOnInit(): void {
    this._changedProperties();
  }

  public ngOnChanges({plNumericOnly}: SimpleChanges): void {
    if (plNumericOnly && !plNumericOnly.isFirstChange()) {
      this._changedProperties(plNumericOnly.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._element.removeEventListener<'keydown'>('keydown', this._fnKeyDown);
    this._subscriptionFormat.unsubscribe();
  }

  private _changedProperties(value: '' | Partial<IPlFormatConfig> = this.plNumericOnly): void {
    this._options = {...this._format, ...value};
  }

  private _keyDown(event: KeyboardEvent): void {
    this._plKeyboardService.numericOnly(event, this._options);
  }

  private readonly _fnKeyDown: (event: KeyboardEvent) => void = (event: KeyboardEvent) => {
    this._keyDown(event);
  };
}
