import {NgModule} from '@angular/core';
import {PlPaginationComponent} from './pagination.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

export * from './pagination.component.interface';
export * from './pagination.component';

const DECLARATIONS = [
  PlPaginationComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlPaginationModule {
}
