import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {IDocfasNumsComunicacaoLogin} from './docfasNumsComunicacaoLogin.service.interface';
import {TServiceResponse} from '../api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class DocfasNumsComunicacaoLoginService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/docfasnumscomunicacaologin`;
  }

  public get(): TServiceResponse<IDocfasNumsComunicacaoLogin> {
    return this._apiService.get<IDocfasNumsComunicacaoLogin>({url: this._path});
  }

  public set(data: IDocfasNumsComunicacaoLogin): TServiceResponse<IDocfasNumsComunicacaoLogin> {
    return this._apiService.post<IDocfasNumsComunicacaoLogin>({url: this._path, body: data});
  }
}
