import {Component, Injector} from '@angular/core';
import {IPlUploadCallback, IPlUploadFile, PlAlertService, toJson} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EMPTY_GUID} from '../../../../../config/constants';
import {ENTITY_NAME_PRH_DOCS_PLATA, IPRHDocsPlataEntityService} from '../../../../entities/prhdocsplata/pRHDocsPlata.entity.interface';
import {ENTITY_NAME_PRH_PAPEIS} from '../../../../entities/prhpapeis/pRHPapeis.entity.interface';
import {ENTITY_NAME_PRH_SERVICOS, IPRHServicosEntityService} from '../../../../entities/prhservicos/pRHServicos.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IDocsPlataMulti} from '../docsPlataforma.module.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonPRHDocsPlata} from '../../../../entities/prhdocsplata/jsonPRHDocsPlata.entity.interface';
import {IJsonPRHPapel} from '../../../../entities/prhpapeis/jsonPRHPapel.entity.interface';
import {IJsonPRHServico} from '../../../../entities/prhservicos/jsonPRHServico.entity.interface';
import moment from 'moment';

const DEFAULT_COD_PRH_DOCS_PLATA = 0;

@Component({
  selector: 'colaboradores-docsplataforma-modal',
  templateUrl: './docsPlataforma.modal.component.html'
})
export class DocsPlataformaModalComponent extends CGModalComponent<void> {
  public readonly templateServicosSelected: string;
  public readonly templatePapeisSelected: string;
  public readonly callback: IPlUploadCallback;
  public docsPlata: IDocsPlataMulti;
  public servicos: Array<IJsonPRHServico>;
  public papeis: Array<IJsonPRHPapel>;

  private readonly _service: IPRHDocsPlataEntityService;
  private readonly _prhServicos: IPRHServicosEntityService;
  private readonly _prhPapeis: IEntityService<IJsonPRHPapel>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.templateServicosSelected = '{{codServico}} - {{nome}}';
    this.templatePapeisSelected = '{{codPapel}} - {{nome}}';
    this.callback = {};
    this.docsPlata = {
      codPrhDocsPlata: undefined,
      codServico: undefined,
      codPapel: undefined,
      data: moment(),
      dataValidade: moment(),
      docId: EMPTY_GUID,
      titulo: undefined,
      servicosSelected: [],
      papeisSelected: []
    };
    this.servicos = [];
    this.papeis = [];
    this._service = this._entityServiceBuilder.build<IJsonPRHDocsPlata, IPRHDocsPlataEntityService>(ENTITY_NAME_PRH_DOCS_PLATA);
    this._prhServicos = this._entityServiceBuilder.build(ENTITY_NAME_PRH_SERVICOS);
    this._prhServicos.query().then((response) => {
      this.servicos = response.body.list;
    });
    this._prhPapeis = this._entityServiceBuilder.build(ENTITY_NAME_PRH_PAPEIS);
    this._prhPapeis.query().then((response) => {
      this.papeis = response.body.list;
    });
  }

  public readonly fnFinalize = (): Promise<void> => this._finalize();

  private _finalize(): Promise<void> {
    const files: Array<IPlUploadFile> = this.callback.getAcceptedFiles();
    if (!files.length) {
      this._plAlertService.warning('colaboradores.docsplata.modal.errorFiles');
      return Promise.resolve();
    }
    if (!this.docsPlata.servicosSelected.length) {
      this._plAlertService.warning('colaboradores.docsplata.modal.errorServicosSelected');
      return Promise.resolve();
    }
    if (!this.docsPlata.papeisSelected.length) {
      this._plAlertService.warning('colaboradores.docsplata.modal.errorPapeisSelected');
      return Promise.resolve();
    }
    const file: File = files[0].file;
    return this._service
      .uploadDoc(DEFAULT_COD_PRH_DOCS_PLATA, file, toJson(this.docsPlata))
      .then(() => {
        this.close();
        this._plAlertService.success('colaboradores.uploadSuccess');
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
        this._plAlertService.warning('colaboradores.uploadError');
      });
  }
}
