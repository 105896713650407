import {TranslateLoader, TranslateModuleConfig} from '@ngx-translate/core';
import {Logger} from 'pl-comps-angular';
import {CGTranslationLoader} from './translation.loader';

export function translateLoaderFactory(logger: Logger): TranslateLoader {
  return new CGTranslationLoader(logger);
}

export const TRANSLATE_MODULE_CONFIG: TranslateModuleConfig = {
  loader: {
    provide: TranslateLoader,
    deps: [Logger],
    useFactory: translateLoaderFactory
  }
};
