import {BehaviorSubject, firstValueFrom, Observable, skipWhile} from 'rxjs';
import {take} from 'rxjs/operators';
import {IMutableContext, UnleashClient} from 'unleash-proxy-client';
import {Injectable, OnDestroy} from '@angular/core';
import {isNumber, isUndefined} from 'pl-comps-angular';
import {APP_RUNTIME_PROPERTIES} from '../../../common/app';
import {CGLocalStorageService} from '../storage/localstorage.service';
import {FeatureFlagsRepository} from './featureflag.flags.repository';
import {IAppRuntimeProperties} from '../../../common/interfaces/interfaces';
import {IFeatureFlagsContext, IFeatureFlagsRepository} from './featureflag.service.interface';

const STORAGE_KEY = 'featureflags';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService implements OnDestroy {
  private readonly _flags: IFeatureFlagsRepository;
  private readonly _subjectRepository: BehaviorSubject<IFeatureFlagsRepository>;
  private _client: UnleashClient;
  private _context: IFeatureFlagsContext;
  private _pendingContext: IMutableContext;
  private _observableRepository: Observable<IFeatureFlagsRepository>;

  constructor(private readonly _localStorageService: CGLocalStorageService) {
    this._flagsChanged = this._flagsChanged.bind(this);
    this._getFlags = this._getFlags.bind(this);
    this._saveFlags = this._saveFlags.bind(this);

    this._flags = new FeatureFlagsRepository(() => this._client);
    this._subjectRepository = new BehaviorSubject<IFeatureFlagsRepository>(this._flags);

    APP_RUNTIME_PROPERTIES.pipe(skipWhile(isUndefined))
      .pipe(take(1))
      .subscribe((properties: IAppRuntimeProperties) => {
        this._client = new UnleashClient({
          url: properties.featureFlagsAddress,
          clientKey: properties.featureFlagsClientKey,
          appName: 'erpcloud-front-end',
          storageProvider: {
            get: this._getFlags,
            save: this._saveFlags
          }
        });

        this._client.start().finally(() => {
          this._client.on('update', this._flagsChanged);
        });

        if (this._pendingContext) {
          this._client.updateContext(this._pendingContext);
          this._pendingContext = undefined;
        }
      });
  }

  public ngOnDestroy(): void {
    if (this._client) {
      this._client.off('update', this._flagsChanged);
      this._client.stop();
    }
  }

  public flags(): Observable<IFeatureFlagsRepository> {
    if (!this._observableRepository) {
      this._observableRepository = this._subjectRepository.asObservable();
    }
    return this._observableRepository;
  }

  public updateContext(context: IFeatureFlagsContext): void {
    this._context = {...this._context, ...context};

    const userId: string | undefined = isNumber(this._context.licId) && isNumber(this._context.userId) ? `${this._context.licId}.${this._context.userId}` : undefined;
    const sessionId = this._context.sessionId || undefined;

    const newContext: IMutableContext = {userId: userId};
    if (sessionId) {
      newContext.sessionId = sessionId;
    }

    if (this._client) {
      this._client.updateContext(newContext);
    } else {
      this._pendingContext = newContext;
    }
  }

  private _flagsChanged(): void {
    this._subjectRepository.next(this._subjectRepository.value);
  }

  private _getFlags(name: string): Promise<unknown> {
    return firstValueFrom(this._localStorageService.getItem(`${STORAGE_KEY}.${name}`, undefined));
  }

  private _saveFlags(name: string, data: unknown): Promise<void> {
    return firstValueFrom(this._localStorageService.setItem(`${STORAGE_KEY}.${name}`, data, undefined));
  }
}
