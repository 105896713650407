import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_IMP_FFERPOC} from './impFFERPOC.module.interface';
import {ImpFFERPOCModuleComponent} from './components/impFFERPOC.module.component';

export const MODULE_IMP_FFERPOC: IModuleDefinition = {
  name: MODULE_NAME_IMP_FFERPOC,
  state: {
    url: `/${MODULE_NAME_IMP_FFERPOC}`,
    component: ImpFFERPOCModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.impfferpoc'
    }
  }
};
