import {Component, Injector, Input} from '@angular/core';
import {EGrupoDoc, EGrupoDocType} from '../../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {DocsComerciaisModalComponent} from '../../../../../entities/docscomerciais/modals/docsComerciais.modal.component';
import {IPlNavWizardInstance} from 'pl-comps-angular';

@Component({
  selector: 'trdocs-cabs-modal',
  templateUrl: './trdocs.cabs.modal.component.html'
})
export class TrdocsCabsModalComponent extends DocsComerciaisModalComponent {
  @Input() public grupoDocfa: EGrupoDoc;
  @Input() public docType: EGrupoDocType;

  public showEditDocBtn: boolean;
  public navWizardInst: IPlNavWizardInstance;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.showEditDocBtn = false;
  }

  public close(): void {
    super.close();
  }

  public navWizardSetEvent(inst: IPlNavWizardInstance): void {
    this.navWizardInst = inst;
  }
}
