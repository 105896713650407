import type dxDataGrid from 'devextreme/ui/data_grid';
import {ECodigoAnexoI, ETipoAbrevPaisIntra, ETipoOperacaoAnexoI, IJsonDecIvaPeriodicaValoresLocal, IJsonDecIvaPerioExtratoCampo} from '../../../../interfaces/jsonDeclaracaoIva.interface';
import {IJsonDocContabilidade} from '../../docscontabilidade/jsonDocContabilidade.interface';
import {IDevExpressValidationCallbackData} from '../../../../components/devexpress/validator/devexpress.validator.rules.interface';
import {IContabDigitalGDocViewerRecolhaDoc} from '../../../../services/contabilidadedigital/contabilidadedigital.interface';

export enum ENavAnexosDecIvaPerio {
  Rosto = 'rosto',
  AnexoRContinente = 'anexoRContinente',
  AnexoRAcores = 'anexoRAcores',
  AnexoRMadeira = 'anexoRMadeira',
  AnexoCampo40 = 'anexoCampo40',
  AnexoCampo41 = 'anexoCampo41',
  Anexos = 'anexos'
}

export enum ETypePeriodoIva {
  DEFAULT = 0,
  MENSAL = 1,
  TRIMESTRAL = 2
}

export type TDeclaracaoDeIvaRocValidationCallbackData = IDevExpressValidationCallbackData<'custom', dxDataGrid, INumeroIdentificacaoROC, string>;

export interface INumeroIdentificacaoROC {
  id: number;
  nif: string;
}

export interface IMetodoSourcePrazoDecl {
  valueMetodo: boolean;
  nameMetodo: string;
}

export interface IMetodoSourcePaisDest {
  valueMetodo: ETipoAbrevPaisIntra;
  nameMetodo: string;
  abrevitura: string;
  nomePaisDest: string;
}

export interface IMetodoSourceTipoOperacaoAnexoI {
  valueMetodo: ETipoOperacaoAnexoI;
  nameMetodo: string;
}

export interface IMetodoSourceCodigoAnexoI {
  valueMetodo: ECodigoAnexoI;
  nameMetodo: string;
}

export interface IDecIvaPerioExtratoCampo extends IJsonDecIvaPerioExtratoCampo {
  _docContabilidade?: IJsonDocContabilidade;
  _docContabDigital?: IContabDigitalGDocViewerRecolhaDoc;
}

export interface IDecIvaPerioOperacoesIguaisSede {
  isValid: boolean;
  nomeAnexo: string;
  campo1: string;
  campo2: string;
}

export interface IDecIvaPerioOperacoesIguaisSedeIndividual {
  isValid: boolean;
  anexo1Invalid?: boolean;
  anexo2Invalid?: boolean;
}

export interface IDecIvaPerioOperacoesIguaisSedeValoresLocal {
  local: IJsonDecIvaPeriodicaValoresLocal;
  nomeAnexo: string;
}

export const MODULE_NAME_PCA_MANUTENCAO_DECLARACAOIVA = 'declaracaoiva';

export const MODULE_NAME_PCA_MANUTENCAO_DECLARACAOIVA_RECAPITULATIVA = 'declaracaoivarecapitulativa';
