import {IRadioGroup} from '../../../common/interfaces/interfaces';
import {deepFreeze} from 'pl-comps-angular';
import {EModeloTributAutonoma} from './jsonTributAutonomas.module.interface';

export enum ETributAutonomaIRCInternal {
  NaoSeAplica,
  DespesasNaoDocumentadas,
  DespesasRepresentacao,
  EncargosDedutiveisAjudasCusto,
  ImportanciasPagasDevidasEntidades,
  LucrosDistribuidosEntidadesSujeitas,
  EncargosViaturasAntigaRedacao,
  EncargosViaturas,
  IndemnizacoesCessacaoFuncoes,
  GastosEncargosRelativos,
  EncargosNaoDedutiveis,
  EncargosViaturasMenor,
  EncargosViaturasMaiorIgualMenor,
  EncargosViaturasMaiorIgual,
  EncargosViaturasHibridasMenor,
  EncargosViaturasHibridasMaiorIgualMenor,
  EncargosViaturasHibridasMaiorIgual,
  EncargosViaturasGPLMenor,
  EncargosViaturasGPLMaiorIgualMenor,
  EncargosViaturasGPLMaiorIgual,
  EncargosViaturasHibridasNaoCumpreRequisitos,
  EncargosViaturasGNVNaoCumpreRequisitos
}

export interface ITributAutonomasParams {
  nConta: string;
}

export const RADIO_GROUP_TRIBUTACAO_APLICADA: IRadioGroup<EModeloTributAutonoma> = deepFreeze<IRadioGroup<EModeloTributAutonoma>>({
  groupItems: [
    {value: EModeloTributAutonoma.IRC, label: `tributAutonomas.modeloTributAutonoma.modelo${EModeloTributAutonoma.IRC}`},
    {value: EModeloTributAutonoma.IRCAcores, label: `tributAutonomas.modeloTributAutonoma.modelo${EModeloTributAutonoma.IRCAcores}`},
    {value: EModeloTributAutonoma.IRCMadeira, label: `tributAutonomas.modeloTributAutonoma.modelo${EModeloTributAutonoma.IRCMadeira}`}
  ]
});

export const MODULE_NAME_MANUT_TRIBUT_AUTONOMAS = 'manuttributautonomas';
export const MODULE_NAME_TRIBUT_AUTONOMAS = 'tributautonomas';
