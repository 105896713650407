import {AssistConfigPortaisPortalClientConnectUtilizadorExistenteModuleComponent} from './components/assistConfigPortais.portalClientConnect.utilizadorExistente.module.component';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {EPortal} from '../../../../../../../common/enums/portals.enums';
import {IModuleDefinition} from '../../../../../../components/module/module.definition.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR_EXISTENTE} from './assistConfigPortais.portalClientConnect.utilizadorExistente.module.interface';
import {ROLE} from '../../../../../../services/role.const';

export const MODULE_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR_EXISTENTE: IModuleDefinition = {
  name: MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS_PORTAL_CLIENT_CONNECT_UTILIZADOR_EXISTENTE,
  state: {
    url: '/assistenteconfigportais/portalclientconnect/utilizadorexistente',
    component: AssistConfigPortaisPortalClientConnectUtilizadorExistenteModuleComponent,
    data: {
      roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
      pageTitle: 'global.menu.assistenteConfigPortaisPortalClientConnectUtilizadorExistente'
    },
    resolve: [
      {
        provide: 'portalRoleClientConnect',
        deps: [AuthService],
        useFactory: (authService: AuthService): Promise<ROLE> => authService.getAndGeneratePortalRole(EPortal.CLIENTCONNECT)
      }
    ]
  }
};
