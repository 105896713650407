import {Component, Injector} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {TDate} from '../../../../../common/dates';
import moment from 'moment';

@Component({
  selector: 'modal-rhatualizaferiasemp-datacadastro',
  templateUrl: './rhAtualizaFeriasEmp.dataCadastro.modal.component.html'
})
export class RHAtualizaFeriasEmpDataCadastroModalComponent extends CGModalComponent<TDate> {
  public date: TDate;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.date = moment();
  }

  public close(): void {
    super.close(this.date);
  }
}
