<div class="docfas">
  <entity-detail
    entityName="docfas"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL}">
        <pl-group>
          <label [translate]="'docfas.fields.nDocFa'"></label>
          <edit>
            <pl-edit type="cginteger" [(model)]="model.nDocFa" attrName="nDocfa" [properties]="{readonly: true}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'docfas.fields.nome'"></label>
          <edit>
            <pl-edit-text [(model)]="model.nome" attrName="nome" [properties]="{readonly: true}"></pl-edit-text>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'docfas.fields.descricao'"></label>
          <edit>
            <pl-edit-text [(model)]="model.descricao" attrName="descricao" [properties]="{readonly: true}"></pl-edit-text>
          </edit>
        </pl-group>
        <div class="alert alert-info alert-sm" *ngIf="isVisibleExtraMessage"><i class="fa fa-fw fa-info-circle" aria-hidden="true"></i>&nbsp;<span [translate]="extraMessage"></span></div>

        <div style="padding-top: 10px">
          <div class="card">
            <div class="card-header" [translate]="'docfas.captions.dadosAT'"></div>
            <div class="card-body">
              <pl-group>
                <pl-group>
                  <label [translate]="'docfas.fields.sujeitoAHASHStr'"></label>
                  <edit>
                    <pl-edit type="text" [(model)]="model.sujeitoAHASHStr" [properties]="{readonly: true}"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'docfas.fields.codigoSAFTTipoDocDescStr'"></label>
                  <edit>
                    <pl-edit type="text" [(model)]="model.codigoSAFTTipoDocDescStr" [properties]="{readonly: true}"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <label>
                    <span [translate]="'docfas.fields.cae'"></span>
                    <pl-tooltip class="tooltip-info-label" [config]="{text: 'docfas.tooltip.cae', placement: 'right'}">
                      <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                    </pl-tooltip>
                  </label>
                  <edit>
                    <entity-autocomplete entity="qpcae" attrName="cae" [model]="model" (modelChange)="caeChanged($event)" [fieldsMap]="{codcae: 'codCae', nome: 'nomeCae'}"></entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>
            </div>
          </div>
        </div>

        <div style="padding-top: 10px">
          <div class="card">
            <div class="card-header" [translate]="'docfas.captions.dadosGerais'"></div>

            <div class="card-body">
              <pl-group>
                <pl-group>
                  <label [translate]="'docfas.fields.identificaCarga'"></label>
                  <edit>
                    <pl-edit-switch [(model)]="model.identificaCarga" attrName="identificaCarga"></pl-edit-switch>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'docfas.fields.faturaComIvaIncluido'"></label>
                  <edit>
                    <pl-edit-switch [(model)]="model.faturaComIvaIncluido" attrName="faturaComIvaIncluido"></pl-edit-switch>
                  </edit>
                </pl-group>

                <pl-group>
                  <div class="container">
                    <div class="row">
                      <div class="col-3">
                        <pl-group>
                          <label [translate]="'docfas.fields.movimentaStock'"></label>
                          <edit>
                            <pl-edit-switch [(model)]="model.integraStocks" attrName="integraStocks"></pl-edit-switch>
                          </edit>
                        </pl-group>
                      </div>

                      <div class="col-9">
                        <pl-group>
                          <label [translate]="'docfas.fields.tipoMovimento'"></label>
                          <edit>
                            <pl-edit type="text" [model]="movStockInfo" [properties]="{readonly: true}"></pl-edit>
                          </edit>
                        </pl-group>
                      </div>
                    </div>
                  </div>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'docfas.fields.obrigatorioMeiosPagam'"></label>
                  <edit>
                    <pl-edit-switch [(model)]="model.obrigatorioMeiosPagam" attrName="obrigatorioMeiosPagam"></pl-edit-switch>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'docfas.fields.nViasDefeito'"></label>
                  <edit class="col-3">
                    <pl-edit-select
                      [(model)]="model.nViasDefeito"
                      attrName="nViasDefeito"
                      [source]="nViasDefeitoSource"
                      [properties]="{disallowClear: true, select: {valueProp: 'value', labelProp: 'name'}}"></pl-edit-select>
                  </edit>
                </pl-group>

                <pl-group>
                  <label></label>
                  <edit></edit>
                </pl-group>
              </pl-group>
            </div>
          </div>
        </div>

        <div *ngIf="temRoleContabilidade" style="padding-top: 10px">
          <div class="card">
            <div class="card-header" [translate]="'docfas.captions.integraContabilidade'"></div>
            <div class="card-body">
              <pl-group>
                <pl-group>
                  <label [translate]="'docfas.fields.integraNaContabilidade'"></label>
                  <edit>
                    <pl-edit-switch [(model)]="model.integraNaContabilidade" attrName="integraNaContabilidade"></pl-edit-switch>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'docfas.fields.diario'"></label>
                  <edit>
                    <entity-autocomplete entity="diarios" attrName="diario" [model]="model" (modelChange)="diarioChanged($event)" [fieldsMap]="{nDiario: 'ndiarioContab', nome: 'nomeDiario'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'docfas.fields.descriti'"></label>
                  <edit>
                    <entity-autocomplete entity="descritivos" [model]="model" (modelChange)="descritiChanged($event)" [fieldsMap]="{nDescrit: 'ndescritiContab', nome: 'nomeDescriti'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>
            </div>
          </div>
        </div>

        <div style="padding-top: 10px">
          <div class="card">
            <div class="card-header inline-block">
              <span [translate]="'docfas.captions.numeracoes'"></span>
              <ng-container *ngIf="haveDataGridChanges">
                <span class="ms-2 text-warning fw-bold"> <i class="fa fa-fw fa-exclamation-triangle" aria-hidden="true"></i><span [translate]="'docfas.warnings.temAlteracoes'"></span> </span>
              </ng-container>
              <button type="button" class="btn btn-success btn-xs float-end" title="Adicionar nova série" (click)="addNovaSerie()" [disabled]="type === stateTypes.DETAIL">
                <i class="fa fa-fw fa-plus"></i>
              </button>
            </div>

            <dx-data-grid
              [cgDxDataGrid]="definitionsDataGrid"
              [dataSource]="definitionsDataGrid?.dataSource"
              (onInitialized)="onInitialized($event)"
              (onContentReady)="onContentReady()"
              (onCellPrepared)="onCellPrepared($event)"
              (onSaved)="onDataGridSaved($event)">
              <div *dxTemplate="let item of 'nContaTmpl'">
                <entity-autocomplete
                  entity="fornecedores"
                  attrName="fornecedores"
                  [model]="item.data"
                  [selectedKey]="item.data.nConta"
                  (selectedKeyChange)="rowNContaChanged($event, item.data)"
                  (evtSelectedDescriptionChanged)="item.data.nomeClifo = $event"
                  [fieldsMap]="{nConta: 'nConta', nome: 'nomeClifo'}"
                  [filter]="fornecedoresFilter"
                  [output]="fornecedoresOutput"
                  [properties]="{validators: {maxlength: {value: 75}, required: {value: true}}, disabled: type === stateTypes.DETAIL || item.data.atEstadoSerie > 0}">
                </entity-autocomplete>
              </div>

              <div *dxTemplate="let item of 'actions'">
                <pl-tooltip [config]="{text: 'docfasNums.btn.comunicarSeries', container: 'body'}">
                  <button type="button" *plTooltipContent class="btn btn-xs btn-success" (click)="comunicarSerie(item.data)"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
                </pl-tooltip>
                &nbsp;
                <pl-tooltip [config]="{text: 'docfasNums.btn.logSeries', container: 'body'}">
                  <button type="button" *plTooltipContent class="btn btn-xs btn-info" (click)="logSerie(item.data)"><i class="fa fa-list" aria-hidden="true"></i></button>
                </pl-tooltip>
                &nbsp;
                <pl-tooltip [config]="{text: 'docfasNums.btn.consultarSeries', container: 'body'}">
                  <button type="button" *plTooltipContent class="btn btn-xs btn-primary" (click)="consultarSerie(item.data)"><i class="fa fa-search" aria-hidden="true"></i></button>
                </pl-tooltip>
              </div>
              <dxo-editing mode="cell" startEditAction="click" [selectTextOnEditStart]="true" [allowUpdating]="true"></dxo-editing>
            </dx-data-grid>
          </div>
        </div>

        <div style="padding-top: 10px">
          <div class="card">
            <div class="card-header inline-block">
              <span [translate]="'docfas.captions.reports'"></span>
              <ng-container *ngIf="haveGridReportsChanges">
                <span class="ms-2 text-warning fw-bold"> <i class="fa fa-fw fa-exclamation-triangle" aria-hidden="true"></i><span [translate]="'docfas.warnings.temAlteracoes'"></span> </span>
              </ng-container>
            </div>
            <dx-data-grid [cgDxDataGrid]="definitionGridReports" [dataSource]="definitionGridReports?.dataSource" (onInitialized)="onInitializedRpt($event)" (onSaved)="onDataGridSavedRpt($event)">
              <div *dxTemplate="let item of 'tipoTemplate'">
                <i *ngIf="item.data.tipo === reportKind.System" class="fa fa-fw fa-desktop mr-1" aria-hidden="true"></i>
                <i *ngIf="item.data.tipo === reportKind.User" class="fa fa-fw fa-user-circle mr-1" aria-hidden="true"></i>
                <i *ngIf="item.data.tipo === reportKind.UserEmpresa" class="fa fa-fw fa-users mr-1" aria-hidden="true"></i>
                <span> {{ item.data.tipoDescricao }} </span>
              </div>
              <dxo-editing mode="cell" startEditAction="click" [selectTextOnEditStart]="true" [allowUpdating]="true"></dxo-editing>
            </dx-data-grid>
          </div>
        </div>
      </pl-form>
    </div>
  </entity-detail>
</div>
