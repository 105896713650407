import {BehaviorSubject, Observable} from 'rxjs';
import {Inject, Injectable, LOCALE_ID, OnDestroy} from '@angular/core';
import {getLocaleCurrencyCode} from '@angular/common';
import {IPlFormatConfig} from './format.service.interface';

@Injectable({
  providedIn: 'root'
})
export class PlFormatService implements OnDestroy {
  private readonly _defaultFormat: IPlFormatConfig;
  private readonly _subjectFormat: BehaviorSubject<IPlFormatConfig>;
  private _observableFormat: Observable<IPlFormatConfig>;

  constructor(@Inject(LOCALE_ID) locale: string) {
    this._defaultFormat = Object.freeze<IPlFormatConfig>({
      date: 'dd/MM/yyyy',
      datetime: 'dd/MM/yyyy HH:mm:ss',
      time: 'HH:mm:ss',
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      currencyCode: getLocaleCurrencyCode(locale),
      thousandsSeparator: ',',
      decimalsSeparator: '.',
      decimalsLimit: 2,
      digitsInfo: '1.0-3',
      digitsInfoCurrency: '1.2-3',
      digitsInfoInteger: '1.0-0',
      digitsInfoFloat: '1.2-2',
      momentDate: 'DD-MM-YYYY',
      momentDatetime: 'DD-MM-YYYY HH:mm:ss'
    });
    this._subjectFormat = new BehaviorSubject<IPlFormatConfig>(this._defaultFormat);
  }

  public ngOnDestroy(): void {
    this._subjectFormat.complete();
  }

  public setFormat(format: Partial<IPlFormatConfig>): void {
    this._subjectFormat.next(Object.freeze({...this._defaultFormat, ...this._subjectFormat.value, ...format}));
  }

  public get format(): Observable<IPlFormatConfig> {
    if (!this._observableFormat) {
      this._observableFormat = this._subjectFormat.asObservable();
    }
    return this._observableFormat;
  }
}
