import {Component, Injector, Input, OnInit} from '@angular/core';
import {IPlFormDefinition, IPlFormTemplate, isEmptyObject, isObject} from 'pl-comps-angular';
import {IJsonConcelho, IJsonConcelhoAT, IJsonDadosDerrama} from '../../jsonConcelho.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {ENTITY_NAME_CONCELHOS, IConcelhosEntityService} from '../../concelhos.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {IJsonModelo22CompDerrama} from '../../../../modules/portalcontabilidade/modelo22/jsonModelo22.module.interface';
import {Modelo22DerramaModalComponent} from '../../../../modules/portalcontabilidade/modelo22/components/anexos/modal/modelo22.derrama.modal.component';
import {ConcelhosDadosDerramaModalComponent} from '../../modals/concelhos.dadosDerrama.modal.component';

@Component({
  selector: 'concelhos-edit',
  templateUrl: './concelhos.entity.edit.component.html'
})
export class ConcelhosEditComponent extends ModuloEntityDetailComponent<IJsonConcelho> implements OnInit {
  @Input() public anosDerrama: Array<number>;

  public readonly concelhoATTemplate: string;
  public formDefinition: IPlFormDefinition;
  public formTemplate: IPlFormTemplate;
  public promiseAnoDerrama: Promise<void>;
  public promise: Promise<void>;
  public entityState: typeof EEntityStateDetailType;
  public listConcelhosAT: Array<IJsonConcelhoAT>;
  public isNew: boolean;
  public anoDerrama: number;

  private readonly _concelhoService: IConcelhosEntityService;
  private _showModalDerrama: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._concelhoService = this._entityServiceBuilder.build<IJsonConcelho, IConcelhosEntityService>(ENTITY_NAME_CONCELHOS);
    this.entityState = EEntityStateDetailType;
    this.listConcelhosAT = [];
    this.concelhoATTemplate = '{{nomeConcelhoAT}}';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.formTemplate = <IPlFormTemplate>this.states.detail.data.formTemplate;
    this.formDefinition = this.entity[this.type].definition;
    if (isEmptyObject(this.model)) {
      this.model = {
        codConcelho: undefined,
        nomeConcelho: '',
        codDistrito: undefined,
        nomeDistrito: '',
        codConcelhoAT: '',
        nomeConcelhoAT: ''
      };
    }
    this.anoDerrama = this.anosDerrama.length > 0 ? this.anosDerrama[this.anosDerrama.length - 1] : undefined;
    if (this.type !== EEntityStateDetailType.NEW) {
      this._checkCalculoDerrama();
    }
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    this.isNew = stateType === EEntityStateDetailType.NEW;
    this.formDefinition = this.entity[stateType].definition;
    return super.onUpdate(stateType).then(() => {
      if (stateType === EEntityStateDetailType.EDIT) {
        return this.loadListaConcelhosAT();
      }
      return Promise.resolve();
    });
  }

  public anoDerramaChanged(value: number): Promise<void> {
    this.anoDerrama = value;
    if (this.promiseAnoDerrama) {
      return Promise.resolve();
    }
    return this._checkCalculoDerrama();
  }

  public loadDerrama(): Promise<void> {
    let promise: Promise<void>;

    if (this._showModalDerrama) {
      promise = Promise.resolve(
        this._concelhoService.getCalculoDerrama(this.model.codConcelho, this.anoDerrama).then((response: HttpResponse<IJsonModelo22CompDerrama>) => {
          const modalInstance = this._cgModalService.showVanilla(Modelo22DerramaModalComponent);
          const componentInstance: Modelo22DerramaModalComponent = modalInstance.componentInstance;
          componentInstance.derrama = response.body;
          componentInstance.fromConcelhos = true;
          return modalInstance.result.then(() => {
            this._showModalDerrama = false;
          });
        })
      );
    }
    return Promise.resolve(promise).then(() => {
      this._concelhoService.getDadosDerrama(this.model.codConcelho, this.anoDerrama).then((response: HttpResponse<IJsonDadosDerrama>) => {
        const modalInstance = this._cgModalService.showVanilla(ConcelhosDadosDerramaModalComponent);
        const componentInstance: ConcelhosDadosDerramaModalComponent = modalInstance.componentInstance;
        componentInstance.dadosDerrama = response.body;
      });
    });
  }

  public evtConcelhoATChanged(value: {inputValue: string; item: IJsonConcelhoAT}): void {
    if (isObject(value.item)) {
      this.model.codConcelhoAT = value.item.codConcelhoAT;
      this.model.nomeConcelhoAT = value.item.nomeConcelhoAT;
    }
  }

  public loadListaConcelhosAT(clearConcelhoAt: boolean = false): Promise<void> {
    if (clearConcelhoAt) {
      this.model.codConcelhoAT = '';
      this.model.nomeConcelhoAT = '';
    }
    if (!this.model.codDistrito) {
      return Promise.resolve();
    }
    this.promise = this._concelhoService
      .getListaConcelhosAT(this.model.codDistrito)
      .then((response: HttpResponse<Array<IJsonConcelhoAT>>) => {
        this.listConcelhosAT = response.body;
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  private _checkCalculoDerrama(): Promise<void> {
    this.promiseAnoDerrama = this._concelhoService
      .checkCalculoDerrama(this.model.codConcelho, this.anoDerrama)
      .then((response: HttpResponse<boolean>) => {
        this._showModalDerrama = response.body;
      })
      .finally(() => {
        this.promiseAnoDerrama = undefined;
      });
    return this.promiseAnoDerrama;
  }
}
