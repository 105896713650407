import {HttpResponse} from '@angular/common/http';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonDocComercial} from '../docscomerciais/jsonDocComercial.entity.interface';
import {IJsonRecibo, IJsonReciboCab, IJsonReciboLinha} from './jsonRecibo.entity.interface';
import {IApiQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';
import moment from 'moment';

export type TServiceHttpQueryResponseRecibos<T> = HttpResponse<IEntityServiceQueryResponseRecibos<T>>;

export interface IRecibosCabEntityService extends IEntityService<IJsonReciboCab> {
  sendEmail(extpoccabID: string, nconta: string, email: string, ncopias: number): TServiceResponse<IJsonRecibo>;
}

export interface IRecibosEntityService extends IEntityService<IJsonRecibo> {
  sendEmail(extpoccabID: string, nconta: string, email: string, ncopias: number): TServiceResponse<IJsonRecibo>;
}

export interface IRecibosEntity extends IEntityDefinition<IJsonRecibo, IRecibosEntityService> {}

export interface IEntityServiceQueryResponseRecibos<T> extends IApiQueryResponse<T> {
  sum: number;
}

export interface IRecibo extends IJsonRecibo {
  totalCaixa?: number;
  troco?: number;
  linhas: Array<IReciboLinha>;
}

export interface IReciboCabWithData extends IJsonReciboCab {
  _recibo?: IJsonRecibo;
}

export interface IReciboLinha extends IJsonReciboLinha {
  _thedoc?: IJsonDocComercial;
}

export interface IRecibosParams {
  nConta: string;
  nome: string;
}

export enum EReciboTipoDistribuicao {
  Automatico,
  Manual
}

export interface IReciboDistribuirValor {
  valor: number;
  tipoDistribuicao: EReciboTipoDistribuicao;
}

export const ENTITY_NAME_RECIBOS = 'recibos';

export const NDECIMAIS_CAMBIO_DEFAULT = 5;

export function reciboDefault(): Required<IRecibo> {
  return {
    cab: {
      extPocCabID: '',
      numero: '',
      nConta: '',
      nome: '',
      nif: '',
      email: '',
      data: moment(),
      valor: 0,
      stampUpdate: moment(),
      totalDescontos: 0,
      totalReceber: 0,
      total: 0,
      origem: 0,
      totalRetencao: 0,
      temRetencao: false,
      descricao: '',
      codMoeda: 0,
      abreviaturaMoeda: '',
      valorEntregueCredito: 0,
      nDocAsStr: ''
    },
    linhas: [],
    meiosPagamento: [],
    percDescSobreValorIVA: false,
    totalCaixa: 0,
    troco: 0
  };
}
