import {IConfigsErpEditConfigERP, IConfigsErpEditGroup} from '../components/configedit/configs.erp.edit.component.interface';

export interface IConfigsErpComercialEditGroup extends IConfigsErpEditGroup {
  items: Array<IConfigsErpComercialConfigERP>;
  pagedItems: Array<IConfigsErpComercialConfigERP>;
  documentoPorDefeito: number;
}

export interface IConfigsErpComercialConfigERP extends IConfigsErpEditConfigERP {
  nDoc: number;
  visible: boolean;
}

export const MODULE_NAME_CONFIGS_COMERCIAL = 'configsComercial';
