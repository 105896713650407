import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {ETipoEscolhaUtlViatPatronal, ETipoOrdenacao, IJsonUtlViatPatronal, IJsonUtlViatPatronalIncidencias} from './jsonUtlViatPatronal.module.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class UtlViatPatronalService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/utlviatpatronal`;
  }

  public getViatPatronal(estado: ETipoEscolhaUtlViatPatronal, tipoOrdenacao: ETipoOrdenacao): TServiceResponse<Array<IJsonUtlViatPatronal>> {
    return this._apiService.get<Array<IJsonUtlViatPatronal>>({
      url: this._path,
      params: {
        estado: estado,
        tipoordenacao: tipoOrdenacao
      }
    });
  }

  public calculaIncidencias(valorAquisicao: number, valorMercado: number): TServiceResponse<IJsonUtlViatPatronalIncidencias> {
    return this._apiService.get<IJsonUtlViatPatronalIncidencias>({
      url: `${this._path}/calculaincidencias`,
      params: {
        valoraquisicao: valorAquisicao,
        valormercado: valorMercado
      }
    });
  }

  public postUtlViatPatronal(estado: ETipoEscolhaUtlViatPatronal, tipoordenacao: ETipoOrdenacao, utlViatPatronal: IJsonUtlViatPatronal): TServiceResponse<Array<IJsonUtlViatPatronal>> {
    return this._apiService.post<Array<IJsonUtlViatPatronal>, IJsonUtlViatPatronal>({
      url: this._path,
      body: utlViatPatronal,
      params: {
        estado: estado,
        tipoordenacao: tipoordenacao
      }
    });
  }

  public deleteUtlViatPatronal(utlviatpatronalid: string): TServiceResponse<void> {
    return this._apiService.delete<void>({url: this._path, params: {utlviatpatronalid: utlviatpatronalid}});
  }
}
