import {Component, Injector, Input, OnInit} from '@angular/core';
import {IPlEditComponentOptionsInputNumber, KEYCODES, PlAlertService, TEditInputKeyboardEvent} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAtivosImpListCalc, IJsonAtivosImport, IJsonAtivosImportAndListCalc} from '../../jsonAtivosImport.entity.interface';
import {AtivosImportService} from '../../ativosImport.service';
import {HttpResponse} from '@angular/common/http';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnEditingStart, IDevExpressDataGridEventOnRowUpdated} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'modal-ativos-import-atvaloranualman',
  templateUrl: './ativosImport.atValorAnualMan.modal.component.html'
})
export class AtivosImportAtValorAnualManModalComponent extends CGModalComponent<IJsonAtivosImport> implements OnInit {
  @Input() public ativosImportAndListCalc: IJsonAtivosImportAndListCalc;

  public readonly definition: IDevExpressDataGrid;
  public readonly propertiesAjusteContab: Array<IPlEditComponentOptionsInputNumber>;
  public readonly propertiesValorFiscal: Array<IPlEditComponentOptionsInputNumber>;

  public ativoimport: IJsonAtivosImport;
  public listcalc: Array<IJsonAtivosImpListCalc>;
  public promise: Promise<void>;
  public dadosiguaiscontab: boolean;
  public difContab: number;
  public difFiscal: number;
  public temQueTerValorAtribuido: boolean;
  public ultAno: number;

  private _importlistcalc: IJsonAtivosImportAndListCalc;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosImportService: AtivosImportService,
    private readonly _plAlertService: PlAlertService,
    private readonly _translate: TranslateService
  ) {
    super(_injector);
    this._importlistcalc = undefined;
    this.ultAno = this._configService.configurations.empresa.anoEmCursoIRC - 1;
    this.propertiesAjusteContab = [];
    this.propertiesValorFiscal = [];

    this.definition = {
      keyExpr: 'ano',
      columns: [
        {dataField: 'ano', dataType: 'number', caption: 'global.text.year'},
        {dataField: 'valor', dataType: 'double', caption: 'ativosimport.modal.table.depcontab'},
        {dataField: 'ajusteContab', dataType: 'double', caption: 'ativosimport.modal.table.ajuste', allowEditing: true},
        {dataField: 'valorContabTotal', dataType: 'double', caption: 'global.text.total'},
        {dataField: 'valorFiscal', dataType: 'double', caption: 'ativosimport.modal.table.depfiscal', allowEditing: true}
      ],
      searchPanel: {visible: false},
      grouping: {contextMenuEnabled: false},
      columnFixing: {enabled: false},
      export: {enabled: false},
      columnChooser: {enabled: false},
      hoverStateEnabled: true,
      editing: {mode: 'cell', allowUpdating: true},
      headerFilter: {visible: false, search: {enabled: false}},
      filterRow: {visible: false},
      toolbar: {
        visible: true,
        items: [{location: 'before', text: this._translate.instant('ativosimport.modal.valoresemcadano')}]
      }
    };
  }

  public ngOnInit(): void {
    this.ativoimport = this.ativosImportAndListCalc.ativosImport;
    this.listcalc = this.ativosImportAndListCalc.listCalc;
    this.difContab = this.ativosImportAndListCalc.diferencaContab;
    this.difFiscal = this.ativosImportAndListCalc.diferencaFiscal;
    this.temQueTerValorAtribuido = this.ativosImportAndListCalc.temQueTerValorAtribuido;
    this.dadosiguaiscontab = this._setdadosiguais();
    for (let i = 0; i < this.listcalc.length; i++) {
      this.propertiesAjusteContab.push({
        alignRight: true,
        events: {
          keydown: (value: number, event: KeyboardEvent) => {
            this._keydownAjusteContab(i, event);
          }
        },
        modelOptions: {
          updateOn: 'blur'
        }
      });
      this.propertiesValorFiscal.push({
        alignRight: true,
        events: {
          keydown: (value: number, event: KeyboardEvent) => {
            this._keydownValorFiscal(i, event);
          }
        },
        modelOptions: {
          updateOn: 'blur'
        }
      });
    }
  }

  public onRowUpdatedValoresCadaAno(event: IDevExpressDataGridEventOnRowUpdated<IJsonAtivosImpListCalc, number>): void {
    this.onChangeAtivosImportDep(event.data);
  }

  public onEditingStartValoresCadaAno(event: IDevExpressDataGridEventOnEditingStart<IJsonAtivosImpListCalc, number>): void {
    event.cancel = !(event.column.dataField === 'ajusteContab' || (event.column.dataField === 'valorFiscal' && !this.dadosiguaiscontab));
  }

  public close(): Promise<void> {
    this._setImportListCalc();
    return this._ativosImportService.postGuardaAtribuirValoresManualmente(this._importlistcalc).then((response: HttpResponse<IJsonAtivosImport>) => {
      this._plAlertService.success('ativosimport.atpercred');
      super.close(response.body);
    });
  }

  public aplicarPerc(): Promise<void> {
    this._setImportListCalc();
    this.promise = this._ativosImportService.postAplicarPerc(this._importlistcalc).then((result: HttpResponse<IJsonAtivosImportAndListCalc>) => {
      this.listcalc = result.body.listCalc;
      this.difContab = result.body.diferencaContab;
      this.difFiscal = result.body.diferencaFiscal;
      this.temQueTerValorAtribuido = result.body.temQueTerValorAtribuido;
    });
    return this.promise;
  }

  public onChangeAtivosImportDep(linha: IJsonAtivosImpListCalc): Promise<void> {
    linha.valorContabTotal = linha.valor + linha.ajusteContab;
    this.promise = this.dadosIguaisContabChange();
    return this.promise;
  }

  public aplicarPercFiscal(): Promise<void> {
    this._setImportListCalc();
    this.promise = this._ativosImportService.postAplicarPercFiscal(this._importlistcalc).then((result: HttpResponse<IJsonAtivosImportAndListCalc>) => {
      this.listcalc = result.body.listCalc;
      this.difContab = result.body.diferencaContab;
      this.difFiscal = result.body.diferencaFiscal;
      this.temQueTerValorAtribuido = result.body.temQueTerValorAtribuido;
    });
    return this.promise;
  }

  public dadosIguaisContabChange(): Promise<void> {
    this._setImportListCalc();
    this.promise = this._ativosImportService.postOnChangeAtivosImportDep(this._importlistcalc, this.dadosiguaiscontab).then((result: HttpResponse<IJsonAtivosImportAndListCalc>) => {
      this.listcalc = result.body.listCalc;
      this.difContab = result.body.diferencaContab;
      this.difFiscal = result.body.diferencaFiscal;
      this.temQueTerValorAtribuido = result.body.temQueTerValorAtribuido;
    });
    return this.promise;
  }

  public readonly fnKeydownAjusteContab: (index: number) => TEditInputKeyboardEvent<string> =
    (index: number) =>
    (value: string, event: KeyboardEvent): void => {
      this._keydownAjusteContab(index, event);
    };

  public readonly fnKeydownValorFiscal: (index: number) => TEditInputKeyboardEvent<string> =
    (index: number) =>
    (value: string, event: KeyboardEvent): void => {
      this._keydownValorFiscal(index, event);
    };

  private _setdadosiguais(): boolean {
    return (
      this.ativoimport &&
      this.ativoimport.taxaAmortizacao === this.ativoimport.fiscalTaxaAmortiza &&
      this.ativoimport.depreciacaoAcumulada === this.ativoimport.fiscalDepAcumulada &&
      this.ativoimport.percReducao === this.ativoimport.fiscalPercReducao
    );
  }

  private _setImportListCalc(): void {
    this._importlistcalc = {
      ativosImport: this.ativoimport,
      listCalc: this.listcalc,
      diferencaContab: this.difContab,
      diferencaFiscal: this.difFiscal,
      temQueTerValorAtribuido: this.temQueTerValorAtribuido
    };
  }

  private _keydownAjusteContab(index: number, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();

      if (this.dadosiguaiscontab) {
        const nextIndex = index++;
        const ajustecontab = `ajusteContab${nextIndex}`;
        const inputAjusteContab: HTMLElement = document.querySelector(`.vendas-input-table [name="${ajustecontab}"]`);
        if (inputAjusteContab) {
          setTimeout(() => {
            inputAjusteContab.focus();
          });
        }
      } else {
        const inputValorFiscal: HTMLElement = document.querySelector(`.valorFiscal-input-table [name="valorFiscal${index}"]`);
        if (inputValorFiscal) {
          setTimeout(() => {
            inputValorFiscal.focus();
          });
        }
      }
    }
  }

  private _keydownValorFiscal(index: number, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      event.stopPropagation();

      const nextIndex = index++;
      const ajustecontab = `ajusteContab${nextIndex}`;
      const inputAjusteContab: HTMLElement = document.querySelector(`.vendas-input-table [name="${ajustecontab}"]`);
      if (inputAjusteContab) {
        setTimeout(() => {
          inputAjusteContab.focus();
        });
      }
    }
  }
}
