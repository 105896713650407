import {deepFreeze, EDateMonth} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_MESES_ABREVIADO} from './mesesAbreviado.datasource.interface';

export const DATA_SOURCE_MESES_ABREVIADO: IDataSource<EDateMonth> = deepFreeze({
  name: DATA_SOURCE_NAME_MESES_ABREVIADO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: 'name',
    output: 'name'
  },
  data: [
    {value: EDateMonth.January, name: 'global.monthsShort.january'},
    {value: EDateMonth.February, name: 'global.monthsShort.february'},
    {value: EDateMonth.March, name: 'global.monthsShort.march'},
    {value: EDateMonth.April, name: 'global.monthsShort.april'},
    {value: EDateMonth.May, name: 'global.monthsShort.may'},
    {value: EDateMonth.June, name: 'global.monthsShort.june'},
    {value: EDateMonth.July, name: 'global.monthsShort.july'},
    {value: EDateMonth.August, name: 'global.monthsShort.august'},
    {value: EDateMonth.September, name: 'global.monthsShort.september'},
    {value: EDateMonth.October, name: 'global.monthsShort.october'},
    {value: EDateMonth.November, name: 'global.monthsShort.november'},
    {value: EDateMonth.December, name: 'global.monthsShort.december'}
  ]
});
