import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {ETaxonomiasTab} from './taxonomias.module.interface';
import {
  IJsonInfoLegal,
  IJsonPlanoOficialPublicado,
  IJsonRelacaoContasTaxonomia,
  IJsonValidacoesLancamentos,
  IJsonValidacoesPlano,
  IJsonValidacoesSVAT
} from '../../entities/taxonomias/jsonTaxonomias.entity.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class TaxonomiasService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/taxonomias`;
  }

  public getRelacaoContas(): TServiceResponse<Array<IJsonRelacaoContasTaxonomia>> {
    return this._apiService.get<Array<IJsonRelacaoContasTaxonomia>>({url: `${this._path}/relacaocontas`});
  }

  public getInfoLegal(): TServiceResponse<Array<IJsonInfoLegal>> {
    return this._apiService.get<Array<IJsonInfoLegal>>({url: `${this._path}/infolegal`});
  }

  public getPlanoOficial(): TServiceResponse<Array<IJsonPlanoOficialPublicado>> {
    return this._apiService.get<Array<IJsonPlanoOficialPublicado>>({url: `${this._path}/planooficial`});
  }

  public getValidacoesSvat(): TServiceResponse<Array<IJsonValidacoesSVAT>> {
    return this._apiService.get<Array<IJsonValidacoesSVAT>>({url: `${this._path}/validacoessvat`});
  }

  public getValidacoesPlano(): TServiceResponse<Array<IJsonValidacoesPlano>> {
    return this._apiService.get<Array<IJsonValidacoesPlano>>({url: `${this._path}/validacoesplano`});
  }

  public getValidacoesLancamentos(): TServiceResponse<Array<IJsonValidacoesLancamentos>> {
    return this._apiService.get<Array<IJsonValidacoesLancamentos>>({url: `${this._path}/validacoeslancamentos`});
  }

  public exportToXlsUrl(tab: ETaxonomiasTab): Observable<string> {
    return of(`${this._path}/exportToXls/${tab}`);
  }

  public criarContaTerceiro(nconta: string): TServiceResponse<void> {
    return this._apiService.post<void, string>({url: `${this._path}/contaterceiro`, body: nconta});
  }

  public aplicarCorrecoes(errorlist: Array<IJsonValidacoesPlano>): TServiceResponse<void> {
    return this._apiService.post<void, Array<IJsonValidacoesPlano>>({url: `${this._path}/aplicarcorrecoes`, body: errorlist});
  }

  public aplicarCorrecoesDatas(): TServiceResponse<Array<IJsonValidacoesLancamentos>> {
    return this._apiService.post<Array<IJsonValidacoesLancamentos>, void>({url: `${this._path}/aplicarcorrecoesdatas`});
  }

  public aplicarCorrecoesAutomaticasDocsValor0(): TServiceResponse<Array<IJsonValidacoesLancamentos>> {
    return this._apiService.post<Array<IJsonValidacoesLancamentos>, void>({url: `${this._path}/aplicarcorrecoesdocvalor`});
  }

  public aplicarCorrecoesAutomaticasAnalitica(nconta: string): TServiceResponse<Array<IJsonValidacoesLancamentos>> {
    return this._apiService.post<Array<IJsonValidacoesLancamentos>, string>({url: `${this._path}/aplicarcorrecoesanalitica`, body: nconta});
  }

  public aplicarCorrecoesAutomaticasClasse0(nconta: string): TServiceResponse<Array<IJsonValidacoesLancamentos>> {
    return this._apiService.post<Array<IJsonValidacoesLancamentos>, string>({url: `${this._path}/aplicarcorrecoesclasse`, body: nconta});
  }

  public aplicarCorrecoesAutomaticasDocsSemLinhas(nconta: string): TServiceResponse<Array<IJsonValidacoesLancamentos>> {
    return this._apiService.post<Array<IJsonValidacoesLancamentos>, string>({url: `${this._path}/aplicarcorrecoesdocssemlinhas`, body: nconta});
  }
}
