import {EGestaoDGEMPSType} from '../../components/gestaodgemps/gestaodgemps.interface';
import {ENTITY_NAME_PRH_EVENTOS_CONFIG, IPRHEventosConfigEntity} from './pRHEventosConfig.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_PRH_EVENTOS_CONFIG: IPRHEventosConfigEntity = {
  name: ENTITY_NAME_PRH_EVENTOS_CONFIG,
  icon: 'fa-cog',
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  searchPlaceholder: 'prheventosconfig.pesquisa',
  metadata: {
    keyName: 'codPRHEventosConfig',
    descriptionName: 'descricao',
    fields: [
      {
        name: 'codPRHEventosConfig',
        width: '120px',
        caption: 'prheventosconfig.fields.codPRHEventosConfig',
        placeholder: 'prheventosconfig.fields.codPRHEventosConfigPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'tipoEvento',
        caption: 'prheventosconfig.fields.tipoEvento',
        placeholder: 'prheventosconfig.fields.tipoEventoPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'tipo',
        caption: 'prheventosconfig.fields.tipo',
        placeholder: 'prheventosconfig.fields.tipoPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'ncodAbdes',
        caption: 'prheventosconfig.fields.nCodAbdes',
        placeholder: 'prheventosconfig.fields.nCodAbdesPlaceholder',
        validators: {required: true, maxlength: 5}
      },
      {
        name: 'descricao',
        caption: 'prheventosconfig.fields.descricao',
        placeholder: 'prheventosconfig.fields.descricaoPlaceholder',
        validators: {required: true, maxlength: 100}
      },
      {
        name: 'tipoProcessamento',
        caption: 'prheventosconfig.fields.tipoProcessamento',
        placeholder: 'prheventosconfig.fields.tipoProcessamentoPlaceholder',
        validators: {required: true, min: 1}
      }
    ],
    order: 'codPRHEventosConfig',
    searchFields: 'codPRHEventosConfig,descricao'
  },
  autocomplete: {
    rowTemplate: '{{codPRHEventosConfig}} - {{descricao}}',
    output: 'descricao',
    searchFields: 'codLocal,descricao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
        pageTitle: 'global.menu.prheventosconfig'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
        pageTitle: 'global.menu.prheventosconfig'
      }
    }
  },
  service: function () {
    this.getConfiguracoesByTipo = (tipo: EGestaoDGEMPSType) => {
      return this.get({
        id: '-1/configuracoesbytipo',
        params: {
          tipo: tipo
        }
      });
    };
  }
};
