<div class="pl-filter-panel">
  <button *ngIf="showCaption" type="button" class="btn btn-link p-0" (click)="toggleCollapsed()">
    <span [innerHTML]="options.showLabel | cgcBindHtml"></span>
    <span>
      <i aria-hidden="true" [ngClass]="options.iconClass" [plAnimationRotate]="!collapsed"></i>
    </span>
  </button>

  <pl-animation-collapse [collapsed]="collapsed">
    <div class="pl-filter">
      <div class="input-group pl-filter-form">
        <div class="input-group-text" ngbDropdown>
          <button type="button" class="btn dropdown-toggle" ngbDropdownToggle [disabled]="!fields || !fields.length">
            <span *ngIf="!fields || !fields.length">{{ locale.text.noFilters }}</span>
            <ng-container *ngIf="selectedFilter"><span [plTranslate]="selectedFilter.field.caption"></span>&nbsp;<span class="caret"></span></ng-container>
          </button>

          <div class="dropdown-menu" ngbDropdownMenu>
            <button *ngFor="let field of fields" type="button" class="dropdown-item" [plTranslate]="field.caption" (click)="selectFilter(field)"></button>
          </div>
        </div>

        <pl-edit
          class="pl-filter-panel-edit"
          attrName="pl-filter-panel-edit"
          [type]="selectedFilterType"
          [model]="selectedFilter?.value"
          [properties]="selectedFilterProperties"
          (modelChange)="addFilter($event)">
        </pl-edit>
      </div>

      <div *ngIf="appliedFilters.length">
        <hr class="pl-filter-separator" />
        <ul class="pl-filter-items list-inline">
          <li class="list-inline-item">{{ locale.text.activeFilters }}:</li>
          <li *ngFor="let appliedFilter of appliedVisibleFilters; index as i" class="list-inline-item">
            <div class="pl-filter-item badge bg-primary">
              <div class="pl-filter-item-content">
                <span class="pl-filter-item-caption" [plTranslate]="appliedFilter.field.caption"></span>:&nbsp;<strong class="pl-filter-item-value">{{ appliedFilter.prettyValue }}</strong>
              </div>
              <button *ngIf="!appliedFilter.field.persistent" type="button" class="btn-close btn-close-white" (click)="removeFilter(appliedFilter)"></button>
            </div>
          </li>
          <li *ngIf="appliedFilters.length" class="list-inline-item">
            <button type="button" class="btn btn-link" (click)="clearFilters()">{{ locale.text.clearFilters }}</button>
          </li>
        </ul>
      </div>
    </div>
  </pl-animation-collapse>
</div>
