<div class="modal-body">
  <div [translate]="'proceSalarios.processModalMessage'" style="margin-bottom: 8px"></div>
  <div class="progress" style="height: 4px">
    <div
      class="progress-bar bg-info progress-bar-striped progress-bar-animated"
      role="progressbar"
      [attr.aria-valuenow]="100"
      [attr.aria-valuemin]="0"
      [attr.aria-valuemax]="100"
      style="width: 100%"></div>
  </div>
  <div class="text-center" style="margin-top: 5px; font-size: 10px">
    <span style="font-weight: bold" [translate]="'proceSalarios.estimativa'"></span>:&nbsp;<span [translate]="tempoEstimado"></span>
  </div>
</div>
