<div class="colaboradores-aprovacoes-refeicoes">
  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="colaboradoresAprovacoesRefeicoes" [dataSource]="dataGridDefinition.dataSource" (onInitialized)="onInitialized($event)">
    <div *dxTemplate="let item of 'cellTemplateBtn'">
      <pl-button klass="btn-success btn-xs" [onClick]="fnAprovaRefeicao(item.data)">
        <i class="fa fa-fw fa-check"></i>
      </pl-button>

      <pl-button klass="btn-danger btn-xs" [onClick]="fnRejeitaRefeicao(item.data)">
        <i class="fa fa-fw fa-times"></i>
      </pl-button>
    </div>
  </dx-data-grid>
</div>
