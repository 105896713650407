<div class="documentos-partilhados-module">
  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="documentosPartilhados"
    [dataSource]="dataGridDefinition.dataSource"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()">
    <div *dxTemplate="let item of 'actions'">
      <ng-container [ngSwitch]="item.data.isPdf">
        <button type="button" class="btn btn-xs btn-primary" *ngSwitchCase="true" [click]="fnGetPdf(item.data)" plPromise>
          <i class="fa fa-fw fa-file-pdf-o"></i>
        </button>

        <a [href]="item.data.url" class="btn btn-xs btn-link" *ngSwitchCase="false" target="_blank">
          <i class="fa fa-fw fa-download"></i>
        </a>
      </ng-container>

      <button type="button" class="btn btn-xs" [ngClass]="{'btn-success': item.data.isChecked, 'btn-light': !item.data.isChecked}" (click)="itemCheck(item.data)">
        <i class="fa fa-fw" [ngClass]="{'fa-check': item.data.isChecked, 'fa-square-o': !item.data.isChecked}"></i>
      </button>
    </div>
  </dx-data-grid>
</div>
