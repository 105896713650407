import {ENTITY_NAME_CONFIGS_ERP} from './configsErp.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonConfigERP} from './jsonConfigERP.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_CONFIGS_ERP: IEntityDefinition<IJsonConfigERP> = {
  name: ENTITY_NAME_CONFIGS_ERP,
  url: 'configs/erp',
  metadata: {
    keyName: 'name',
    fields: [
      {name: 'name', caption: 'configsErp.fields.name', validators: {required: {value: true}}},
      {name: 'description', caption: 'configsErp.fields.description', properties: {disabled: true}},
      {name: 'value', caption: 'configsErp.fields.value', validators: {required: {value: true}}}
    ],
    order: 'name',
    searchFields: 'name,description,value'
  },
  autocomplete: {
    rowTemplate: '{{name}} - {{value}}',
    output: 'description',
    searchFields: 'name,description,value'
  },
  actions: {
    new: false,
    search: true,
    detail: true,
    edit: true,
    delete: false
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ADMIN],
        pageTitle: 'global.menu.configsErp'
      }
    }
  }
};
