import {ENTITY_NAME_PRH_TIPO_REFEICOES} from './pRHTipoRefeicoes.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonPRHTipoRefeicao} from './jsonPRHTipoRefeicao.entity.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_PRH_GRUPO_REFEICOES} from '../prhgruporefeicoes/pRHGrupoRefeicoes.entity.interface';

export const ENTITY_PRH_TIPO_REFEICOES: IEntityDefinition<IJsonPRHTipoRefeicao> = {
  name: ENTITY_NAME_PRH_TIPO_REFEICOES,
  roles: [ROLE.COLABORADOR, ROLE.GESTORRH, ROLE.GESTORSERVICO, ROLE.GESTOREMENTAS],
  searchPlaceholder: 'prhtiporefeicoes.pesquisa',
  metadata: {
    keyName: 'tipoRefeicao',
    descriptionName: 'nome',
    fields: [
      {
        name: 'tipoRefeicao',
        width: '120px',
        caption: 'prhtiporefeicoes.fields.tipoRefeicao',
        placeholder: 'prhtiporefeicoes.fields.tipoRefeicaoPlaceholder',
        validators: {required: true, min: 1}
      },
      {
        name: 'nome',
        caption: 'prhtiporefeicoes.fields.nome',
        placeholder: 'prhtiporefeicoes.fields.nomePlaceholder',
        validators: {required: true, maxlength: 60}
      },
      {name: 'horaRefeicao', type: 'time', caption: 'prhtiporefeicoes.fields.horaRefeicao', placeholder: 'HH:MM:SS'},
      {
        name: 'numMinutosMarcacao',
        caption: 'prhtiporefeicoes.fields.numMinutosMarcacao',
        placeholder: 'prhtiporefeicoes.fields.numMinutosMarcacaoPlaceholder'
      },
      {
        name: 'corPrimaria',
        type: 'color',
        caption: 'prhtiporefeicoes.fields.corPrimaria',
        placeholder: 'prhtiporefeicoes.fields.corPrimariaPlaceholder',
        validators: {required: true, maxlength: 25},
        properties: {okButton: true, cancelButton: true}
      },
      {
        name: 'corSecundaria',
        type: 'color',
        caption: 'prhtiporefeicoes.fields.corSecundaria',
        placeholder: 'prhtiporefeicoes.fields.corSecundariaPlaceholder',
        validators: {required: true, maxlength: 25},
        properties: {okButton: true, cancelButton: true}
      },
      {name: 'grupoRefeicao', visible: false},
      {
        name: 'nomeGrupo',
        caption: 'prhtiporefeicoes.fields.grupoRefeicao',
        placeholder: 'prhtiporefeicoes.fields.grupoRefeicaoPlaceholder',
        entity: {name: ENTITY_NAME_PRH_GRUPO_REFEICOES, keyTarget: 'grupoRefeicao', outputTarget: 'nomeGrupo'},
        validators: {required: true}
      }
    ],
    order: 'nome',
    searchFields: 'tipoRefeicao,nome',
    filterFields: 'tipoRefeicao,nome,horaRefeicao,corPrimaria,corSecundaria,nomeGrupo',
    detailFields: 'tipoRefeicao,nome,horaRefeicao,corPrimaria,corSecundaria,nomeGrupo',
    listFields: 'tipoRefeicao,nome,horaRefeicao,corPrimaria,corSecundaria,nomeGrupo',
    newFields: 'tipoRefeicao,nome,horaRefeicao,corPrimaria,corSecundaria,nomeGrupo',
    editFields: 'tipoRefeicao,nome,horaRefeicao,corPrimaria,corSecundaria,nomeGrupo'
  },
  autocomplete: {
    rowTemplate: '{{tipoRefeicao}} - {{nome}}',
    output: 'nome',
    searchFields: 'tipoRefeicao,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.GESTOREMENTAS],
        pageTitle: 'global.menu.prhtiporefeicoes'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.GESTOREMENTAS],
        pageTitle: 'global.menu.prhtiporefeicoes'
      }
    },
    template: {
      items: [
        {type: 'group', order: 1, fields: [{field: 'tipoRefeicao'}, {field: 'nome'}]},
        {type: 'field', order: 2, field: 'horaRefeicao'},
        {type: 'group', order: 3, fields: [{field: 'corPrimaria'}, {field: 'corSecundaria'}]},
        {type: 'field', order: 4, field: 'nomeGrupo'}
      ]
    }
  },
  new: {
    state: {
      data: {
        roles: [ROLE.GESTOREMENTAS],
        pageTitle: 'global.menu.prhtiporefeicoes'
      }
    },
    template: {
      items: [
        {type: 'field', field: 'tipoRefeicao', order: 1},
        {type: 'group', order: 1, fields: [{field: 'nome'}, {field: 'horaRefeicao'}]},
        {type: 'group', order: 3, fields: [{field: 'corPrimaria'}, {field: 'corSecundaria'}]},
        {type: 'field', order: 4, field: 'nomeGrupo'}
      ]
    }
  }
};
