import {ENTITY_NAME_IDIDE} from './idide.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonIdide} from './jsonIdide.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_IDIDE: IEntityDefinition<IJsonIdide> = {
  name: ENTITY_NAME_IDIDE,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE],
  searchPlaceholder: 'idide.pesquisa',
  pageTitle: 'global.menu.idide',
  metadata: {
    keyName: 'idIdeChav',
    fields: [
      {name: 'idIdeChav', width: '200px', caption: 'idide.fields.idIdeChav', validators: {required: true, maxlength: 4}},
      {name: 'descricao', caption: 'idide.fields.descricao', validators: {required: true}},
      {name: 'tipoCred', type: 'number', caption: 'idide.fields.tipoCred', visible: false, properties: {devExpress: {dataGrid: {visible: false, showInColumnChooser: false}}}},
      {name: 'credito1', caption: 'idide.fields.credito1', type: 'boolean'},
      {name: 'credito2', caption: 'idide.fields.credito2', type: 'boolean'},
      {name: 'credito3', caption: 'idide.fields.credito3', type: 'boolean'},
      {name: 'credito4', caption: 'idide.fields.credito4', type: 'boolean'},
      {name: 'credito5', caption: 'idide.fields.credito5', type: 'boolean'}
    ],
    order: 'idIdeChav',
    searchFields: 'idIdeChav,descricao'
  },
  autocomplete: {
    rowTemplate: '{{idIdeChav}} - {{descricao}}',
    output: 'descricao',
    searchFields: 'idIdeChav,descricao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP, ROLE.CONTABILIDADE],
        pageTitle: 'global.menu.idide'
      }
    },
    template: {
      items: [
        {type: 'field', field: 'idIdeChav', order: 1},
        {type: 'field', field: 'descricao', order: 2},
        {type: 'group', order: 3, fields: [{field: 'credito1'}, {field: 'credito2'}, {field: 'credito3'}, {field: 'credito4'}, {field: 'credito5'}]}
      ]
    }
  }
};
