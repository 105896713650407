import {DocumentosPartilhadosComponent} from './components/documentosPartilhados.module.component';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';

export const MODULE_DOCUMENTOS_PARTILHADOS: IModuleDefinition = {
  name: 'documentosPartilhados',
  state: {
    url: '/documentospartilhados',
    component: DocumentosPartilhadosComponent,
    data: {
      roles: [ROLE.PCA],
      icon: 'fa-files-o',
      pageTitle: 'global.menu.documentosPartilhados'
    }
  }
};
