import ArrayStore from 'devextreme/data/array_store';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {isEmpty} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGridEventOnSelectionChanged} from '../../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDocumentosFiscaisModule} from '../../documentosFiscais.module.interface';
import {TDate} from '../../../../../../../common/dates';

@Component({
  selector: 'documentos-fiscais-associar-documentos-contabdigital-modal',
  templateUrl: './documentosFiscais.associarDocumentosContabDigital.modal.component.html'
})
export class DocumentosFiscaisAssociarDocumentosContabDigitalModalComponent<T extends object> extends CGModalComponent<Array<T>> implements OnInit {
  @Input() public module: IDocumentosFiscaisModule;
  @Input() public date: TDate;
  @Input() public documento: T;
  @Input() public documentos: Array<T>;
  @Input() public ano: number;
  @Input() public folderName: string;

  public dataGrid: IDevExpressDataGrid<T, T>;
  public selectedDocumentos: Array<T>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.selectedDocumentos = [];
  }

  public ngOnInit(): void {
    this.selectedDocumentos = [this.documento];
    this.dataGrid = {
      columns: this.module.dataGridDefinition.columns.filter((column) => isEmpty(column.cellTemplate)),
      dataSource: new ArrayStore<T, T>({
        data: this.documentos
      }),
      remoteOperations: false,
      paging: {
        enabled: true
      },
      selection: {
        mode: 'multiple',
        showCheckBoxesMode: 'always'
      },
      selectedRowKeys: this.selectedDocumentos,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarDate',
            locateInMenu: 'never'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
  }

  public close(): void {
    super.close(this.selectedDocumentos);
  }

  public onDataGridSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<T, T>): void {
    this.selectedDocumentos = event.selectedRowsData || [];
  }
}
