<div class="ativos-import-atpercreducao-modal" [plPromise]="promise">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'ativosimport.modal.atpercreducao'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'ativosimport.modal.fields.todosanos'"></label>
      <edit>
        <pl-edit type="boolean" [(model)]="todosanos" [properties]="{validators: {required: {value: true}}}"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'global.text.year'"></label>
      <edit>
        <pl-edit type="cginteger" [(model)]="ano" [properties]="{validators: {required: {value: false}}, disabled: todosanos}"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'ativosimport.modal.fields.perc'"></label>
      <edit>
        <pl-edit type="cgnumber" [(model)]="perc" [properties]="{validators: {required: {value: true}}}"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-dismiss" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
