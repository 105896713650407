<div class="modelo10-config-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h5 class="modal-title" [translate]="'modelo30.verificarLancamentos.title'"></h5>
  </div>

  <div class="modal-body">
    <ng-container [ngSwitch]="showTabs">
      <div *ngSwitchCase="false">
        <pl-alert [plAnimationShake]="true" [closeable]="false" [type]="'warning'">
          <div [innerHTML]="'modelo30.verificarLancamentos.mensagens.semDados' | translate"></div>
        </pl-alert>
      </div>
      <ng-container *ngSwitchCase="true">
        <pl-tabs [destroyOnHide]="false">
          <pl-tab caption="modelo30.verificarLancamentos.contribuintesNaoPTComRetencao" [id]="'contribuintesNaoPtComRetencao'" *ngIf="contribuintesNaoPtComRetencao.length">
            <div *plTabContent>
              <dx-data-grid
                [cgDxDataGrid]="dataGridDefinitionContribuintesNaoPtComRetencao"
                [dataSource]="contribuintesNaoPtComRetencao"
                (onInitialized)="onInitializedContribuintesNaoPtComRetencao($event)"
                (onContentReady)="onContentReadyContribuintesNaoPtComRetencao()"
                (onSelectionChanged)="onSelectionChangedContribuintesNaoPtComRetencao($event)">
              </dx-data-grid>
            </div>
          </pl-tab>

          <pl-tab caption="modelo30.verificarLancamentos.contribuintesNaoPTSemRetencao" [id]="'contribuintesNaoPtSemRetencao'" [disabled]="false">
            <div *plTabContent>
              <dx-data-grid
                [cgDxDataGrid]="dataGridDefinitionContribuintesNaoPtSemRetencao"
                [dataSource]="contribuintesNaoPtSemRetencao"
                (onInitialized)="onInitializedContribuintesNaoPtSemRetencao($event)"
                (onContentReady)="onContentReadyContribuintesNaoPtSemRetencao()"
                (onSelectionChanged)="onSelectionChangedContribuintesNaoPtSemRetencao($event)">
              </dx-data-grid>
            </div>
          </pl-tab>
        </pl-tabs>
      </ng-container>
    </ng-container>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success action-dismiss" (evtClicked)="close()" [disabled]="closeDisabled || !showTabs"
      ><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.apply'"></span>
    </pl-button>
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
