import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {ProcSeguradoraFolhaFeriasService} from '../../procSeguradoraFolhaFerias.module.service';
import {IJsonProcSeguradoraFolhaFerias} from '../../jsonProcSeguradoraFolhaFerias.module.interface';

@Component({
  selector: 'modal-print-procseguradorafolhaferias',
  templateUrl: './procSeguradoraFolhaFerias.print.modal.component.html'
})
export class ProcSeguradoraFolhaFeriasPrintModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public procSeguradoraFolhaFerias: Array<IJsonProcSeguradoraFolhaFerias>;
  public readonly pdfToolbarId: string;
  public reportModel: IJsonReport;
  public pdfBlob: ArrayBuffer;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _procSeguradoraFolhaFeriasService: ProcSeguradoraFolhaFeriasService
  ) {
    super(_injector);
    this.pdfToolbarId = 'procseguradorafolhaferias-print-modal-toolbar';
  }

  public ngOnInit(): void {
    this._loadDefaultReport();
  }

  public processar(): Promise<void> {
    return this._procSeguradoraFolhaFeriasService.postListagemSeguradoraFolhaFerias(this.procSeguradoraFolhaFerias, this.reportModel.name).then((response: HttpResponse<ArrayBuffer>) => {
      this.pdfBlob = response.body;
    });
  }

  private _loadDefaultReport(): void {
    this._reportsRegistryService
      .get(EReport.ProcSeguFolhaFeriasMapa)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.reportModel = reports[0];
          this.processar();
        }
      });
  }
}
