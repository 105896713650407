import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IClifo, IClifoGrupo, IClifosEntityService} from '../../../clifos.entity.interface';
import {EEntityStateDetailType} from '../../../../../../common/utils/entity.state.utils';
import {IEntityListServiceMethodsOverride} from '../../../../../components/entity/entity.definition.interface';
import {IPlMediaDevicesCameraCaptureProperties, IPlNavigatorCallback, isString} from 'pl-comps-angular';
import {ExternalNifsService} from '../../../../../services/externalnifs/externalnifs.service';
import {SITUACAO_ATUAL_ATIVA, SITUACAO_VIES_NOT_OK, SITUACAO_VIES_OK} from '../../../../../services/externalnifs/externalnifs.interface';
import {CG_DEFAULT_AT_NIF} from '../../../../../services/config/config.service.interface';
import {IEntityMaintenanceInstance} from '../../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {ENTITY_NAME_MORAL} from '../../../../morals/morals.entity.interface';
import {EntityMaintenanceService} from '../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {TranslateService} from '@ngx-translate/core';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IJsonMorAl} from '../../../../morals/jsonMoral.entity.interface';
import {ENIFSituacaoTipo, IJsonNIFs} from '../../../../nifs/jsonNifs.entity.interface';
import {ENTITY_NAME_NIFS} from '../../../../nifs/nifs.entity.interface';
import {HttpResponse} from '@angular/common/http';
import {EStatusCode} from '../../../../../../config/constants';
import {IJsonClifo} from '../../../jsonClifo.entity.interface';
import {IClifoFichaCallback} from './clifos.ficha.interface';

const NAV_TIPOS_EMAIL = 6;
const RESIZE_WIDTH = 150;
const RESIZE_HEIGHT = 150;
const MAX_WIDTH = 500;

@Component({
  selector: 'clifos-ficha',
  templateUrl: './clifos.ficha.component.html'
})
export class ClifosFichaComponent implements OnInit {
  @Input() public model: IClifo;
  @Input() public grupo: IClifoGrupo;
  @Input() public type: EEntityStateDetailType;
  @Input() public maintenanceMode: boolean;
  @Input() public optionShowZona: boolean;
  @Input() public optionShowVendedor: boolean;
  @Input() public optionShowSector: boolean;
  @Input() public serviceMethodsOverride: IEntityListServiceMethodsOverride;
  @Input() public callback: IClifoFichaCallback;
  @Output() public readonly evtNifValidStateChange: EventEmitter<boolean>;

  public readonly callbackNav: IPlNavigatorCallback;

  public filterNaturezasContab: string;
  public entityStateType: typeof EEntityStateDetailType;
  public nifInvalido: boolean;
  public promiseClifoPicture: Promise<void>;
  public clifoPicture: File;
  public propertiesCaptureImage: IPlMediaDevicesCameraCaptureProperties;
  public originalClifoPicture: File;

  private readonly _maintenanceInstanceMoral: IEntityMaintenanceInstance;
  private readonly _moralsService: IEntityService<IJsonMorAl>;
  private readonly _nifService: IEntityService<IJsonNIFs>;
  private _serviceClifo: IClifosEntityService;

  private _dataGridInstanceMoral: dxDataGrid;
  private _changedClifoPicture: boolean;
  private _deletedClifoPicture: boolean;

  constructor(
    private readonly _externalNifsService: ExternalNifsService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _cgModalService: CGModalService,
    private readonly _translateService: TranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this.evtNifValidStateChange = new EventEmitter<boolean>();
    this.entityStateType = EEntityStateDetailType;
    this.callbackNav = {};
    this.callback = {};
    this._maintenanceInstanceMoral = this._entityMaintenanceService.build(ENTITY_NAME_MORAL);
    this._moralsService = this._entityServiceBuilder.build<IJsonMorAl>(ENTITY_NAME_MORAL);
    this._nifService = this._entityServiceBuilder.build<IJsonNIFs>(ENTITY_NAME_NIFS);
    this.novoMoral = this.novoMoral.bind(this);
    this.nifInvalido = false;
    this._resetPictureVars();
  }

  public ngOnInit(): void {
    this.filterNaturezasContab = `clifoType=${this.grupo.name}`;
    this._serviceClifo = this._entityServiceBuilder.build<IJsonClifo, IClifosEntityService>(this.grupo.name);
    this.propertiesCaptureImage = {
      resizeWidth: RESIZE_WIDTH,
      resizeHeight: RESIZE_HEIGHT,
      resizeMethod: 'crop',
      defaultFileName: this._translateService.instant('artigos.text.fileNamePicture', {nConta: this.model.nConta}),
      videoWidth: MAX_WIDTH,
      uploadProperties: {
        maxFileSize: 2,
        acceptedFiles: '.jpg,.gif,.png,.bmp,.jpeg'
      },
      resizeOverlay: true
    };

    this.promiseClifoPicture = this._getClifoPicture(this.model.nConta).then((value: File) => {
      this.clifoPicture = value;
      this._changedClifoPicture = false;
      this._deletedClifoPicture = false;
      if (!this.originalClifoPicture) {
        this.originalClifoPicture = this.clifoPicture;
      }
    });

    this.callback.savePicture = (): Promise<void> => {
      return this._savePicture();
    };
  }

  public changedClifoPicture(value: File): void {
    this.clifoPicture = value;
    this._changedClifoPicture = true;
    if (!this.clifoPicture && this.originalClifoPicture) {
      this._deletedClifoPicture = true;
    }
  }

  public onDataGridEventOnInitializedMoral({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstanceMoral = component;
  }

  public selectNavTiposEmails(): void {
    setTimeout(() => {
      this.callbackNav.select(NAV_TIPOS_EMAIL);
    });
  }

  public validaNifSituacao(): void {
    if (!this.model.nContribuint) {
      this.model.situacaoActual = '';
      this.model.nifSituacaoViesDescricao = '';
      return;
    }

    this._externalNifsService.get(this.model.nContribuint).then((response) => {
      if (response) {
        this.model.situacaoActual = response.situacaoActual;
        this.model.nifSituacaoViesDescricao =
          response.situacaoActual === SITUACAO_ATUAL_ATIVA || response.situacaoActual === ''
            ? SITUACAO_VIES_OK
            : this.model.nContribuint === CG_DEFAULT_AT_NIF || this.model.nContribuint === this.model.abrevFiscal.concat(CG_DEFAULT_AT_NIF)
              ? ''
              : SITUACAO_VIES_NOT_OK;
      } else {
        this.model.situacaoActual = '';
        this.model.nifSituacaoViesDescricao = this.model.nContribuint === CG_DEFAULT_AT_NIF || this.model.nContribuint === this.model.abrevFiscal.concat(CG_DEFAULT_AT_NIF) ? '' : SITUACAO_VIES_NOT_OK;
      }
    });
  }

  public novoMoral(): Promise<void> {
    return this._maintenanceInstanceMoral.maintenanceNew({params: {nConta: this.model.nConta, nomeConta: this.model.nome}}).then(() => {
      return this._dataGridInstanceMoral.refresh();
    });
  }

  public nContribuinteChanged(nif: string | IJsonNIFs): void {
    if (!isString(nif)) {
      this.model.nContribuint = nif.nContribuinte;
      this._setNifInvalido(nif.tipoNif === ENIFSituacaoTipo.NIFInvalidoInexistente);
    } else {
      this.model.nContribuint = nif;
      this._setNifInvalido(false);
      if (this.model.nContribuint.length >= CG_DEFAULT_AT_NIF.length) {
        this._nifService
          .get<boolean>({
            url: 'validate',
            params: {
              nContribuinte: this.model.nContribuint,
              abrevFiscal: this.model.abrevFiscal
            }
          })
          .then((response: HttpResponse<boolean>) => {
            this._setNifInvalido(!response.body);
          });
      }
    }
  }

  public readonly fnEditarMoral = (item: IJsonMorAl) => (): Promise<void> => this._editarMoral(item);

  public readonly fnEliminarMoral = (item: IJsonMorAl) => (): Promise<void> => this._eliminarMoral(item);

  private _setNifInvalido(value: boolean): void {
    this.nifInvalido = value;
    this.evtNifValidStateChange.emit(value);
  }

  private _editarMoral(item: IJsonMorAl): Promise<void> {
    return this._maintenanceInstanceMoral.maintenanceEdit(item.moralID).then(() => {
      return this._dataGridInstanceMoral.refresh();
    });
  }

  private _eliminarMoral(item: IJsonMorAl): Promise<void> {
    return this._cgModalService
      .showOkCancel(this._translateService.instant('entity.delete.title', {id: item.nConta}), 'entity.delete.message', {
        size: 'md',
        icon: 'warning',
        btnOkIcon: 'fa-trash-o',
        btnOkText: 'global.btn.delete'
      })
      .then(() => {
        return this._moralsService.delete({id: item.moralID}).then(() => {
          this._dataGridInstanceMoral.refresh();
        });
      });
  }

  private _getClifoPicture(nConta: string): Promise<File> {
    return this._serviceClifo.getFoto(nConta, {reportExceptions: false}).then((response: HttpResponse<File>) => {
      if (response.status === EStatusCode.OK) {
        return response.body;
      }
      return undefined;
    });
  }

  private _savePicture(): Promise<void> {
    let promise = Promise.resolve();
    if (this.type === EEntityStateDetailType.EDIT && this._deletedClifoPicture) {
      promise = this._serviceClifo.deleteFoto(this.model.nConta).then(() => undefined);
    } else if (this._changedClifoPicture && this.clifoPicture) {
      promise = this._serviceClifo.setFoto(this.model.nConta, this.clifoPicture).then(() => undefined);
    }
    return promise.then(() => {
      this._resetPictureVars();
    });
  }

  private _resetPictureVars(): void {
    this._changedClifoPicture = false;
    this._deletedClifoPicture = false;
  }
}
