import {EDebitoCredito} from '../../../datasources/debitocredito/debitoCredito.datasource.interface';

export enum ERadioTipoDeclaracao {
  Primeira = 'P',
  Substituicao = 'S'
}

export enum ETiposValidar {
  DEFAULT,
  AT
}

export interface IJsonRegistoLinhaList {
  periodo: string;
  nDiario: number;
  nDocInterno: string;
  nConta: string;
  valor: number;
  valorPago: number;
  dc: EDebitoCredito;
  nomePOC: string;
}

export interface IJsonRegistoList {
  entidade: string;
  codigo: string;
  valorDonativoNumerario: number;
  valorDonativoEspecie: number;
  donativoStr: string;
  linhas: Array<IJsonRegistoLinhaList>;
}

export interface IJsonModelo25 {
  nifDeclarante: string;
  nomeDeclarante: string;
  ano: number;
  codReparticaoFinancas: number;
  tipoDeclaracao: string;
  registoList: Array<IJsonRegistoList>;
}

export interface IJsonModeloErrors {
  tipoValidacao: ETiposValidar;
  mensagemTipoHeader?: string;
  erros: Array<IJsonValidarModeloErrors>;
}

export interface IJsonValidarModeloErrors {
  nomeCampo?: string;
  mensagemOriginal: string;
  mensagem?: string;
  tipoMensagemStr?: string;
}

export interface IJsonModelo25ContasAssociadas {
  contaDe: string;
  contaAte: string;
  codDonativo: string;
}

export interface IJsonModelo25Config {
  contaAssociadaDonativoNumerarioList: Array<IJsonModelo25ContasAssociadas>;
  contaAssociadaDonativoEspecieList: Array<IJsonModelo25ContasAssociadas>;
}

export interface IJsonTiposDonativos {
  cod: string;
  descricao: string;
}
