<div class="documentoscomerciais entity-detail-form">
  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="extratosContas" [dataSource]="dataGridDefinition.dataSource" (onInitialized)="onDataGridInitialized($event)">
    <div *dxTemplate="let item of 'actions'">
      <div *ngIf="!item.data.haveUser && item.data.email">
        <pl-tooltip [config]="{text: 'Criar utilizador plataforma', placement: 'left'}">
          <button type="button" *plTooltipContent class="btn btn-primary btn-xs" [click]="fnCreateUser(item.data)" plPromise>
            <i class="fa fa-fw fa-user" aria-hidden="true"></i>
          </button>
        </pl-tooltip>
      </div>

      <div *ngIf="!item.data.email">
        <pl-tooltip [config]="{text: 'Não tem email definido', placement: 'left'}">
          <button type="button" *plTooltipContent class="btn btn-danger btn-xs">
            <i class="fa fa-fw fa-user" aria-hidden="true"></i>
          </button>
        </pl-tooltip>
      </div>
    </div>
  </dx-data-grid>
</div>
