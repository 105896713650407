import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {EDpIvaDocPagamento, IDocumentosFiscaisDpIvaDocPagementoModel, ITipoDpIvaDocPagamento} from '../../documentosFiscais.module.interface';
import {isDefinedNotNull} from 'pl-comps-angular';

@Component({
  selector: 'documentos-fiscais-dpiva-doc-pagamento',
  templateUrl: './documentosFiscais.dpIvaDocPagamento.modal.component.html'
})
export class DocumentosFiscaisDpIvaDocPagamentoModalComponent extends CGModalComponent<IDocumentosFiscaisDpIvaDocPagementoModel> implements OnInit {
  @Input() public modalTitle: string;
  @Input() public ivaEntregar: number;
  public model: IDocumentosFiscaisDpIvaDocPagementoModel;
  public tipoGroupItems: Array<ITipoDpIvaDocPagamento>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.model = {
      type: 0,
      ivaEntregar: 0,
      ivaRecuperar: 0,
      ivaPago: 0
    };
    this.tipoGroupItems = [
      {
        value: EDpIvaDocPagamento.PrimeiraDeclaracao,
        label: 'documentosFiscais.strings.primeiraDeclaracao'
      },
      {
        value: EDpIvaDocPagamento.DeclaracaoSubstituicao,
        label: 'documentosFiscais.strings.declaracaoSubstituicao'
      }
    ];
  }

  public ngOnInit(): void {
    this.model.ivaEntregar = isDefinedNotNull(this.ivaEntregar) ? this.ivaEntregar : 0;
  }
}
