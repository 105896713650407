<div class="documentos-fiscais-associar-documentos-contabdigital-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'documentosFiscais.strings.associarDocumentosContabDigital'"></h5>
    <h5 class="modal-title">
      - <strong> {{ folderName }}</strong>
    </h5>
  </div>

  <div class="modal-body">
    <dx-data-grid [cgDxDataGrid]="dataGrid" [dataSource]="dataGrid.dataSource" (onSelectionChanged)="onDataGridSelectionChanged($event)">
      <div *dxTemplate="let toolbarItem of 'templateToolbarDate'" class="d-flex align-items-center" style="column-gap: 1rem">
        <h6 *ngIf="date">
          <span [translate]="'global.text.year'"></span>: <strong>{{ date | cgcDate: 'yyyy' }}</strong>
        </h6>
        <h6 *ngIf="date">
          <span [translate]="'global.text.month'"></span>: <strong>{{ date | cgcDate: 'MMMM' }}</strong>
        </h6>
        <h6 *ngIf="ano">
          <span [translate]="'global.text.year'"></span>: <strong>{{ ano }}</strong>
        </h6>
      </div>
    </dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-primary" [disabled]="closeDisabled || !selectedDocumentos.length" (click)="close()">
      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span>
    </button>

    <button type="button" class="btn btn-light" [disabled]="closeDisabled" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
  </div>
</div>
