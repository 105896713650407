<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h5 class="modal-title" [translate]="'inventarios.print.modalTitle'"></h5>
</div>

<div class="modal-body">
  <div class="entity-detail-form">
    <pl-form>
      <pl-group>
        <label [translate]="'global.text.listing'"></label>
        <edit>
          <pl-edit type="reports_inventarios" attrName="listagem" [model]="report" (modelChange)="reportChange($event)" [properties]="{entity: {outputKey: 'name', outputDescription: 'title'}}">
          </pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>
  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
