import {NgModule} from '@angular/core';
import {CGCToastsComponent} from './components/toasts/toasts.component';

export * from './components/toast/toast.component';
export * from './components/toasts/toasts.component';
export * from './config/toasts.config.di';
export * from './config/toasts.config.service';
export * from './service/toasts.service.interface';
export * from './service/toasts.service';
export * from './toasts.interface';

const DECLARATIONS = [CGCToastsComponent];

@NgModule({
  imports: DECLARATIONS,
  exports: DECLARATIONS
})
export class CGCToastsModule {}
