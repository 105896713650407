import {MODULE_NAME_PREVI_TES} from '../previTes.module.interface';
import {HttpResponse} from '@angular/common/http';
import {PreviTesService} from '../previTes.module.service';
import {PreviTesModuleComponent} from './components/previTes.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ROLE} from '../../../../services/role.const';
import {IJsonPreviTes} from '../../../../entities/tesrubrica/jsonTesRubrica.entity.interface';

export const MODULE_PREVI_TES: IModuleDefinition = {
  name: MODULE_NAME_PREVI_TES,
  state: {
    url: `/${MODULE_NAME_PREVI_TES}`,
    component: PreviTesModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.ERP],
      pluginsRoles: [ROLE.CONTABILIDADEPREMIUM, ROLE.ERPADVANCED],
      pageTitle: 'global.menu.prevites'
    },
    resolve: [
      {
        provide: 'previTesList',
        deps: [PreviTesService],
        useFactory: (previTesService: PreviTesService): Promise<Array<IJsonPreviTes>> => {
          return previTesService.getPesquisaPrevTesManual().then((response: HttpResponse<Array<IJsonPreviTes>>) => response.body);
        }
      }
    ]
  }
};
