import {HttpResponse} from '@angular/common/http';
import {IJsonIvaListagem} from '../../../entities/ivas/jsonIva.entity.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ListagensService} from '../../../services/listagens.service';
import {MODULE_NAME_LISTAGENS_RESUMO_IVA} from './resumoIva.module.interface';
import moment from 'moment';
import {ResumoIvaModuleComponent} from './components/resumoIva.module.component';
import {ROLE} from '../../../services/role.const';

export const MODULE_LISTAGENS_RESUMO_IVA: IModuleDefinition = {
  name: MODULE_NAME_LISTAGENS_RESUMO_IVA,
  state: {
    url: '/listagens/resumoIva',
    component: ResumoIvaModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.resumoiva'
    },
    resolve: [
      {
        provide: 'listagensIva',
        deps: [ListagensService],
        useFactory: (listagensService: ListagensService): Promise<IJsonIvaListagem> => {
          const startOfYear = moment().startOf('year');
          const endOfYear = moment().endOf('year');
          return listagensService.listagensIva(startOfYear, endOfYear).then((response: HttpResponse<IJsonIvaListagem>) => response.body);
        }
      }
    ]
  }
};
