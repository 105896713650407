import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isObject, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IPagamentoImprimirModalParams} from './pagamento.imprimir.modal.component.interface';
import {IPagamentosService} from '../../service/pagamentos.entity.service.interface';
import {TrfBaService} from '../../../../modules/trfba/trfBa.module.service';
import {IJsonReport} from '../../../reports/jsonReport.interface';
import {EReport} from '../../../reports/reports.interface';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {HttpResponse} from '@angular/common/http';
import {IJsonConfigERP} from '../../../configserp/jsonConfigERP.entity.interface';
import {ConfigErpService} from '../../../../services/configErp.service';

const REPORT_DEFAULT_NAME = 'Pagamentos.FR3';

@Component({
  selector: 'pagamento-imprimir-modal',
  templateUrl: './pagamento.imprimir.modal.component.html'
})
export class PagamentoImprimirModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public params: IPagamentoImprimirModalParams;
  @Input() public pagamentosService: IPagamentosService;

  public readonly rowTemplateListagem: string;

  public reportsSource: Array<IJsonReport>;
  public report: IJsonReport;
  public extPocCabID: string;
  public nConta: string;
  public nomeConta: string;
  public email: string;
  public url: string;
  public nvias: number;
  public preview: boolean;
  public view: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _configErpService: ConfigErpService,
    private readonly _trfbaService: TrfBaService
  ) {
    super(_injector);
    this.reportsSource = [];
    this.rowTemplateListagem = '{{name}} - {{title}}';
    this.sendEmail = this.sendEmail.bind(this);
  }

  public ngOnInit(): void {
    this.nvias = 1;
    this.preview = false;
    this.view = 'vias';
    if (isObject(this.params)) {
      this.extPocCabID = this.params.extPocCabID;
      this.nConta = this.params.nConta;
      this.nomeConta = this.params.nomeConta;
      this.email = this.params.email;
    }
    this._loadReports();
  }

  public switchView(): void {
    if (this.view === 'vias') {
      this.view = 'pdf';
      this._getPdfUrl();
    } else {
      this.view = 'vias';
    }
  }

  public downloadSEPAFile(): Promise<void> {
    return this._trfbaService.exportFicheiroSepaAndDownload(this.params.trfbaId);
  }

  public sendEmail(): Promise<void> {
    return this.pagamentosService
      .enviaPdfPorEmail(this.extPocCabID, this.nConta, this.email)
      .then(() => {
        this._plAlertService.success(this._translateService.instant('pagamentos.modal.pagamentoimprimir.email.emailEnviado', {email: this.email}));
      })
      .catch(() => {
        this._plAlertService.error(this._translateService.instant('pagamentos.modal.pagamentoimprimir.email.emailErro', {email: this.email}));
      });
  }

  public reportChanged(value: {inputValue: string; item: IJsonReport}): void {
    if (isObject(value.item)) {
      this.report = value.item;
    }
  }

  private _getPdfUrl(): void {
    this.pagamentosService.getPdfUrl(this.extPocCabID, this.report?.name, this.nConta, this.nvias).subscribe((url: string) => {
      this.url = url;
    });
  }

  private async _loadReports(): Promise<void> {
    this.reportsSource = await this._reportsRegistryService.get(EReport.Pagamentos).query();
    if (this.reportsSource.length) {
      this._configErpService.getConfiguration('contabilidade.pagamentos.reportImpressao').then((result: HttpResponse<IJsonConfigERP>) => {
        const defaultReport = !result.body ? REPORT_DEFAULT_NAME : <string>result.body.value;

        this.reportsSource.forEach((report: IJsonReport) => {
          if (report.name === defaultReport) {
            this.report = report;
          }
        });
        if (!this.report) {
          this.report = this.reportsSource[0];
        }
      });
    }
  }
}
