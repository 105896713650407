import {IJsonConfigEstrutura, IJsonPRHEventosConfig} from '../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import {IJsonDGEMPCalendario} from '../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHAbonos, IJsonPRHMarcarAbonos} from '../../interfaces/jsonPRHAbonos.interface';
import {IJsonPRHFaltas, IJsonPRHMarcarFaltas} from '../../interfaces/jsonPRHFaltas.interface';
import {IJsonPRHFerias, IJsonPRHMarcarFerias} from '../../interfaces/jsonPRHFerias.interface';
import {IJsonPRHServico} from '../../entities/prhservicos/jsonPRHServico.entity.interface';
import {Moment} from 'moment';
import {TDate} from '../../../common/dates';

export enum EGestaoDGEMPSType {
  Abonos = 1,
  Faltas = 300,
  Ferias = 100
}

export enum EGestaoDGEMPSView {
  Year,
  Month
}

export enum EGestaoDGEMPSClasses {
  Weekend = 'calendar-holiday',
  Marcadas = 'calendar-marcada',
  Aprovadas = 'calendar-aprovada',
  AprovadasParcialmente = 'calendar-aprovada-parcialmente',
  Integradas = 'calendar-integrada',
  Rejeitadas = 'calendar-rejeitada',
  Gozadas = 'calendar-gozada',
  Conflito = 'calendar-conflito'
}

export enum EGestaoDGEMPSOutlineClasses {
  Marcadas = 'calendar-marcada-outline',
  Aprovadas = 'calendar-aprovada-outline',
  AprovadasParcialmente = 'calendar-aprovada-parcialmente-outline',
  Integradas = 'calendar-integrada-outline',
  Gozadas = 'calendar-gozada-outline',
  Rejeitadas = 'calendar-rejeitada-outline'
}

export enum EGestaoDGEMPSColors {
  Weekend = '#E4EAEC',
  Marcadas = '#F2A654',
  Aprovadas = '#57C7D4',
  AprovadasParcialmente = '#57CED9',
  Integradas = '#46BE8A',
  Rejeitadas = '#F96868',
  Gozadas = '#2F805D',
  Conflito = '#CCCCFF'
}

export interface IPrintEventsFilters {
  deData: TDate;
  ateData: TDate;
  codEmpDe: number;
  codEmpAte: number;
  soAtivos: boolean;
}

export interface IGestaoDGEMPSToolbarLegend {
  readonly finsSemanaFeriados: boolean;
  readonly gozadas: boolean;
  readonly marcadas: boolean;
  readonly aprovadas: boolean;
  readonly rejeitadas: boolean;
  readonly integradas: boolean;
  readonly conflito: boolean;
}

export interface IGestaoDGEMPSPRHServico extends IJsonPRHServico {
  papeis?: Set<number>;
}

export interface IGestaoDGEMPSCalendario {
  codEmp: number;
  nomeEmp: string;
  nomeCompleto: string;
  listaMarcadas: Array<IJsonPRHMarcarAbonos | IJsonPRHMarcarFaltas | IJsonPRHMarcarFerias>;
  listaIntegradas: Array<IJsonPRHAbonos | IJsonPRHFaltas | IJsonPRHFerias>;
  listaCalendario: Array<IJsonDGEMPCalendario>;
  selected?: boolean;
}

export interface IGestaoDGEMPSEvent {
  idTarefaCab: number;
  tipoEvento: EGestaoDGEMPSType;
  codEvento: number;
  nomeEvento: string;
  codIntegracao: string | number;
  codEmp: number;
  nomeEmp: string;
  posicao: number;
  tipo: string;
  processamento: string;
  abdes: string;
  date: Moment;
  horaInicio: Moment;
  horaFim: Moment;
  qtd: number;
  motivo: string;
  allDay: boolean;
  status: IGestaoDGEMPSEventStatus;
  docID: string;
  downloadUrl: string;
}

export interface IGestaoDGEMPSEventStatus {
  marcado: boolean;
  aprovado: boolean;
  aprovadoParcialmente: boolean;
  rejeitado: boolean;
  integrado: boolean;
  pendente: boolean;
  gozado: boolean;
  allowApproveReject: boolean;
  rewindable: boolean;
  desmarcavel: boolean;
  conflito: boolean;
}

export interface IGestaoDGEMPSMarcarData {
  startDate: Moment;
  endDate: Moment;
  events: Array<IGestaoDGEMPSEvent>;
}

export interface IGestaoDGEMPSMarcarModalData extends IGestaoDGEMPSMarcarData {
  configStructure: IJsonConfigEstrutura;
  configEvents: Array<IJsonPRHEventosConfig>;
  calendarios: Array<IGestaoDGEMPSCalendario>;
  viewDate: Moment;
}

export const GESTAO_DGEMPS_DEFAULT_VIEW: EGestaoDGEMPSView = EGestaoDGEMPSView.Year;
export const GESTAO_DGEMPS_KEY_FORMAT = 'YYYY-MM-DD';
export const GESTAO_DGEMPS_MARCAR_START_DAY = 21;
export const GESTAO_DGEMPS_MARCAR_END_DAY = 7;
