import {findLast} from 'lodash-es';
import {Component, Injector, Input, OnInit} from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_PRH_FLUXOS, IPRHFluxosEntityService} from '../../../../../entities/prhfluxos/pRHFluxos.entity.interface';
import {ENTITY_NAME_PRH_PAPEIS} from '../../../../../entities/prhpapeis/pRHPapeis.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {EPRHFluxoIndice, EPRHFluxoIndiceTipo, IJsonPRHFluxo} from '../../../../../entities/prhfluxos/jsonPRHFluxo.entity.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IFluxo} from './fluxos.ficha.modal.interface';
import {IJsonPRHIndiceFluxo} from '../../fluxos.module.interface';
import {IJsonPRHPapel} from '../../../../../entities/prhpapeis/jsonPRHPapel.entity.interface';
import {THttpQueryResponse} from '../../../../../services/api/api.service.interface';

@Component({
  selector: 'colaboradores-fluxo-ficha-modal',
  templateUrl: './fluxos.ficha.modal.component.html'
})
export class FluxosFichaModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public indicesFluxos: Array<IJsonPRHIndiceFluxo>;
  @Input() public tipo: EPRHFluxoIndiceTipo;
  @Input() public nomeTipo: string;

  public readonly definitionTiposFluxosModal: IDevExpressDataGrid<IFluxo, number>;
  public selectedIndice: IJsonPRHIndiceFluxo;
  public selectedPapel: IJsonPRHPapel;

  private readonly _servicePRHPapeis: IEntityService<IJsonPRHPapel>;
  private readonly _servicePRHFluxos: IPRHFluxosEntityService;
  private readonly _nomesIndices: Map<number, string>;
  private readonly _maxPosicaoByIndice: Map<EPRHFluxoIndice, number>;
  private _papeis: Array<IJsonPRHPapel>;
  private _fluxos: Array<IFluxo>;
  private _maxPos: number;
  private _dataGridInstance: dxDataGrid<IFluxo, number>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.definitionTiposFluxosModal = {
      columns: [
        {dataField: 'posicao', dataType: 'number', caption: 'prhfluxos.fields.posicao'},
        {dataField: 'nomeIndice', dataType: 'string', caption: 'indicefluxos.fields.tipo'},
        {dataField: 'papel.nome', dataType: 'string', caption: 'prhfluxos.fields.papel'},
        {dataField: 'integraCG', dataType: 'boolean', caption: 'prhfluxos.fields.integraCG'},
        {
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false,
          width: 150
        }
      ],
      dataSource: new CustomStore({
        key: 'idFluxo',
        load: () => this._sourceFluxos()
      }),
      searchPanel: {visible: false},
      filterRow: {visible: false},
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true,
      grouping: {contextMenuEnabled: false},
      columnFixing: {enabled: false},
      groupPanel: {
        allowColumnDragging: false,
        visible: false
      }
    };
    this._servicePRHPapeis = this._entityServiceBuilder.build<IJsonPRHPapel>(ENTITY_NAME_PRH_PAPEIS);
    this._servicePRHFluxos = this._entityServiceBuilder.build<IJsonPRHFluxo, IPRHFluxosEntityService>(ENTITY_NAME_PRH_FLUXOS);
    this._nomesIndices = new Map<number, string>();
    this._maxPosicaoByIndice = new Map<EPRHFluxoIndice, number>();
    this._papeis = [];
    this._fluxos = [];
    this._maxPos = -1;
  }

  public ngOnInit(): void {
    for (const indiceFluxo of this.indicesFluxos) {
      if (!this._nomesIndices.has(indiceFluxo.indice)) {
        this._nomesIndices.set(indiceFluxo.indice, indiceFluxo.nome);
      }
    }
  }

  public onDataGridInitializedTiposFluxosModal(event: IDevExpressDataGridEventOnInitialized<IFluxo, number>): void {
    this._dataGridInstance = event.component;
  }

  public readonly fnSourcePapeis: () => Promise<Array<IJsonPRHPapel>> = () => this._sourcePapeis();

  public readonly fnCreateFluxo: () => Promise<void> = () => this._createFluxo();

  public readonly fnUpdateFluxo: (item: IFluxo, inc: number) => () => Promise<void> = (item: IFluxo, inc: number) => (): Promise<void> => this._updateFluxo(item, inc);

  public readonly fnDeleteFluxo: (item: IFluxo) => () => Promise<void> = (item: IFluxo) => (): Promise<void> => this._deleteFluxo(item);

  private _sourcePapeis(): Promise<Array<IJsonPRHPapel>> {
    if (this._papeis.length) {
      return Promise.resolve(this._papeis);
    }
    return this._servicePRHPapeis.query().then((response: THttpQueryResponse<IJsonPRHPapel>) => {
      this._papeis = response.body.list;
      return this._papeis;
    });
  }

  private _sourceFluxos(): Promise<Array<IFluxo>> {
    return this._servicePRHFluxos.query({pesquisa: `tipo=${this.tipo}`}).then((response: THttpQueryResponse<IJsonPRHFluxo>) => {
      this._maxPosicaoByIndice.clear();
      this._fluxos = response.body.list
        .filter((fluxo: IJsonPRHFluxo) => fluxo.posicao !== -1)
        .map<IFluxo>((fluxo: IJsonPRHFluxo, index: number, fluxos: Array<IJsonPRHFluxo>) => {
          if (!this._maxPosicaoByIndice.has(fluxo.indice) || fluxo.posicao > this._maxPosicaoByIndice.get(fluxo.indice)) {
            this._maxPosicaoByIndice.set(fluxo.indice, fluxo.posicao);
          }
          return {
            ...fluxo,
            nomeIndice: this._nomesIndices.get(fluxo.indice),
            prevPosDisabled: fluxo.posicao === 1 || (index > 1 && fluxos[index - 1].indice !== fluxo.indice),
            nextPosDisabled: index === fluxos.length - 1 || fluxos[index + 1].indice !== fluxo.indice
          };
        });
      this._maxPos = this._fluxos[this._fluxos.length - 1]?.posicao || -1;
      return this._fluxos;
    });
  }

  private _createFluxo(): Promise<void> {
    if (!this.selectedIndice || !this.selectedPapel) {
      return Promise.resolve();
    }
    let posicao = 0;
    if (this._fluxos.length) {
      if (this.selectedIndice.integraCG) {
        posicao = this._fluxos[this._fluxos.length - 1].posicao;
      } else {
        for (let i = this._fluxos.length - 1; i >= 0; i--) {
          const fluxo: IJsonPRHFluxo = this._fluxos[i];
          if (fluxo.indice === this.selectedIndice.indice && fluxo.posicao > posicao) {
            posicao = fluxo.posicao;
          }
        }
        if (!posicao || posicao === -1) {
          posicao = this._maxPosicaoByIndice.get(this.selectedIndice.indice);
        }
        if ((!posicao || posicao === -1) && this.selectedIndice.indice > 1) {
          posicao = findLast(this._fluxos, ['indice', this.selectedIndice.indice - 1])?.posicao;
        }
        if (!posicao || posicao === -1) {
          posicao = this._fluxos[this._fluxos.length - 1].posicao;
        }
      }
    }
    posicao++;
    const newPrhFluxo: Partial<IJsonPRHFluxo> = {
      posicao: posicao,
      indice: this.selectedIndice.indice,
      codPapel: this.selectedPapel.codPapel,
      tipo: this.selectedIndice.tipo
    };
    return this._servicePRHFluxos.post({body: newPrhFluxo}).then(() => this._dataGridInstance.refresh());
  }

  private _updateFluxo(item: IFluxo, inc: number): Promise<void> {
    const newPosicao: number = item.posicao + inc;
    if (newPosicao < 1 || newPosicao > this._maxPos) {
      return Promise.resolve();
    }
    return this._servicePRHFluxos.mudarPosicao(item.idFluxo, inc).then(() => this._dataGridInstance.refresh());
  }

  private _deleteFluxo(item: IFluxo): Promise<void> {
    return this._servicePRHFluxos.delete({id: item.idFluxo}).then(() => this._dataGridInstance.refresh());
  }
}
