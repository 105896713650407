import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {PlRecaptchaV2Module} from '../v2/recaptcha.v2.module';

import {PlRecaptchaValueAccessorDirective} from './recaptcha.value.accessor.directive';

export * from './recaptcha.value.accessor.directive';

const DECLARATIONS = [
  PlRecaptchaValueAccessorDirective
];

@NgModule({
  imports: [
    FormsModule,
    PlRecaptchaV2Module
  ],
  declarations: DECLARATIONS,
  exports: [
    PlRecaptchaV2Module,
    DECLARATIONS
  ]
})
export class PlRecaptchaFormsModule {
}
