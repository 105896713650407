<div class="modal-header">
  <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'gestaodgemps.faltas.marcarmodal.title'"></h4>
</div>

<div class="modal-body">
  <p>{{ textMarcarDate }}</p>

  <pl-form [properties]="{disabled: hasTarefa || closeDisabled}">
    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.tipo'"></span>:</label>
      <edit>
        <pl-edit-select
          attrName="tipoFalta"
          [source]="configEvents"
          [model]="tipoFalta?.tipo"
          (modelChange)="changedTipoFalta($event)"
          [properties]="{select: {valueProp: 'tipo', labelProp: 'descricao'}}">
        </pl-edit-select>
      </edit>
    </pl-group>

    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.motivo'"></span>:</label>
      <edit>
        <pl-edit type="text" [(model)]="motivo" attrName="motivo"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.allDay'"></span>:</label>
      <edit>
        <pl-edit type="boolean" [(model)]="allDay" attrName="allDay"></pl-edit>
      </edit>
    </pl-group>

    <pl-group *ngIf="!allDay">
      <pl-group>
        <label><span [translate]="'gestaodgemps.fields.horaInicio'"></span>:</label>
        <edit>
          <pl-edit-timepicker [(model)]="horaInicio" attrName="horaInicio"></pl-edit-timepicker>
        </edit>
      </pl-group>

      <pl-group>
        <label><span [translate]="'gestaodgemps.fields.horaFim'"></span>:</label>
        <edit>
          <pl-edit-timepicker [(model)]="horaFim" attrName="horaFim"></pl-edit-timepicker>
        </edit>
      </pl-group>
    </pl-group>

    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.anexaFicheiro'"></span>:</label>
      <edit>
        <pl-edit type="boolean" [(model)]="anexaFicheiro" attrName="anexaFicheiro"></pl-edit>
        <pl-upload
          *ngIf="anexaFicheiro"
          acceptedFiles=".bmp,.png,.jpeg,.jpg,.pdf,.docx,.doc,.csv,.xlsx,.xls"
          [autoProcessQueue]="false"
          [hideGlobalActions]="true"
          [hideActionUpload]="true"
          [maxFiles]="1"
          [maxFileSize]="5"
          [callback]="uploadCallback"
          (evtAcceptedFile)="file = $event.file"
          (evtRemovedFile)="file = undefined">
        </pl-upload>
      </edit>
    </pl-group>
  </pl-form>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-submit" [disabled]="closeDisabled" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.mark'"></span></pl-button>

  <pl-button klass="btn-light action-cancel" [disabled]="closeDisabled" (evtClicked)="dismiss()">
    <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="!hasTarefa ? 'global.btn.cancel' : 'global.btn.close'"></span>
  </pl-button>
</div>
