import {Ng2StateDeclaration} from '@uirouter/angular';
import {ResetPasswordComponent} from './resetpassword.state.component';
import {RESOLVER_RECAPTCHA_CONFIG} from '../../../services/recaptcha/recaptcha.router';
import {STATE_NAME_ACCOUNT} from '../account.state.interface';
import {STATE_NAME_RESET_PASSWORD} from './resetpassword.state.interface';

export const STATE_RESET_PASSWORD: Ng2StateDeclaration = {
  parent: STATE_NAME_ACCOUNT,
  name: STATE_NAME_RESET_PASSWORD,
  url: `/${STATE_NAME_RESET_PASSWORD}`,
  component: ResetPasswordComponent,
  data: {
    pageTitle: 'resetpassword.title',
    disableRecover: true
  },
  resolve: [RESOLVER_RECAPTCHA_CONFIG]
};
