import {Type} from '@angular/core';
import {EGenericImporterType, IGenericImporterExecutor} from '../../components/genericimporter/generic.importer.component.interface';
import {IEntityDefinition, IEntityMetadata} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonClifo, IJsonClifoBasic} from './jsonClifo.entity.interface';
import {IJsonNaturezaContab} from '../naturezascontab/jsonNaturezaContab.entity.interface';
import {ModuloEntityDetailComponent} from '../../components/module/entitydetail/module.entitydetail.component';
import {ModuloEntityListComponent} from '../../components/module/entitylist/module.entitylist.component';
import {IApiQueryResponse, IApiRequestConfig, TServiceResponse} from '../../services/api/api.service.interface';

export type TClifos = 'clifos' | 'clientes' | 'fornecedores' | 'outrosdevedorescredores';

export interface IClifosEntityService extends IEntityService<IClifo>, IGenericImporterExecutor {
  proximaconta(radical: string, ndigitosseq: number): TServiceResponse<string>;

  getNomeNifDuplicado(nome: string, ncontribuint: string): TServiceResponse<IApiQueryResponse<IJsonClifoBasic>>;

  getFirst(): TServiceResponse<IJsonClifo>;

  getNext(id: string): TServiceResponse<IJsonClifo>;

  getPrev(id: string): TServiceResponse<IJsonClifo>;

  getLast(): TServiceResponse<IJsonClifo>;

  getSaldoEmAberto(id: string): TServiceResponse<number>;

  deleteFoto(nConta: string): TServiceResponse<void>;

  getFoto(nConta: string, config?: IApiRequestConfig): TServiceResponse<Blob>;

  setFoto(nConta: string, file: File, config?: IApiRequestConfig): TServiceResponse<void>;
}

export interface IClifosEntity extends IEntityDefinition<IClifo, IClifosEntityService> {}

export interface IClifo extends IJsonClifo {
  naturezaContab: IJsonNaturezaContab;
  active?: number;
}

export interface IClifoGrupo {
  name: string;
  searchPlaceholder: string;
  metadata: Partial<IEntityMetadata>;
  listComponent: Type<ModuloEntityListComponent<IJsonClifo>>;
  detailComponent: Type<ModuloEntityDetailComponent<IJsonClifo, IClifosEntityService>>;
  importerType: EGenericImporterType;
}

export const ENTITY_NAME_CLIFOS = 'clifos';

export const ENTITY_NAME_CLIENTES = 'clientes';

export const ENTITY_NAME_FORNECEDORES = 'fornecedores';

export const ENTITY_NAME_OUTROS_DEVEDORES_CREDORES = 'outrosdevedorescredores';
