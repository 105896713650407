import {EReport} from '../../../entities/reports/reports.interface';
import {IJsonReport} from '../../../entities/reports/jsonReport.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ImpDadosEmpModuleComponent} from './components/impDadosEmp.module.component';
import {MODULE_NAME_IMP_DADOS_EMP} from './impDadosEmp.module.interface';
import {ReportsRegistryService} from '../../../components/reports/reports.registry.service';
import {ROLE} from '../../../services/role.const';

export const MODULE_IMP_DADOS_EMP: IModuleDefinition = {
  name: MODULE_NAME_IMP_DADOS_EMP,
  state: {
    url: `/${MODULE_NAME_IMP_DADOS_EMP}`,
    component: ImpDadosEmpModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.RH],
      pageTitle: 'global.menu.impdadosemp'
    },
    resolve: [
      {
        provide: 'report',
        deps: [ReportsRegistryService],
        useFactory: (reportsRegistryService: ReportsRegistryService): Promise<IJsonReport> => {
          return reportsRegistryService
            .get(EReport.ImpDadosEmp)
            .query()
            .then((reports: Array<IJsonReport>) => reports[0])
            .catch(() => undefined);
        }
      }
    ]
  }
};
