import {ENTITY_NAME_PRH_SERVICO_EMPREGADOS} from './pRHServicoEmpregados.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonPRHServicoEmpregado} from './jsonPRHServicoEmpregado.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_PRH_SERVICO_EMPREGADOS: IEntityDefinition<IJsonPRHServicoEmpregado> = {
  name: ENTITY_NAME_PRH_SERVICO_EMPREGADOS,
  icon: 'fa-question-circle-o',
  roles: [ROLE.GESTORRH],
  pageTitle: 'global.menu.prhservicoempregados',
  searchPlaceholder: 'prhservicoempregados.pesquisa',
  metadata: {
    keyName: 'codServicoEmpregado',
    descriptionName: 'codServico',
    fields: [
      {
        name: 'codServicoEmpregado',
        width: '120px',
        caption: 'prhservicoempregados.fields.codServicoEmpregado',
        visible: false,
        placeholder: 'prhservicoempregados.fields.codServicoEmpregadoPlaceholder',
        validators: {min: 1}
      },
      {
        name: 'codServico',
        caption: 'prhservicoempregados.fields.codServico',
        placeholder: 'prhservicoempregados.fields.codServicoPlaceholder',
        entity: {name: 'prhservicos', keyTarget: 'codServico', outputTarget: 'nome'},
        validators: {required: true, min: 1}
      },
      {
        name: 'codPapel',
        caption: 'prhservicoempregados.fields.codPapel',
        placeholder: 'prhservicoempregados.fields.codPapelPlaceholder',
        entity: {name: 'prhpapeis', keyTarget: 'codPapel', outputTarget: 'nome'},
        validators: {required: true, min: 1}
      },
      {
        name: 'codEmp',
        caption: 'prhservicoempregados.fields.codEmp',
        placeholder: 'prhservicoempregados.fields.codEmpPlaceholder',
        entity: {name: 'dgemps', keyTarget: 'codEmp', outputTarget: 'nome'},
        validators: {required: true, min: 1}
      }
    ],
    order: 'CodServicoEmpregado',
    searchFields: 'CodServicoEmpregado,codServico',
    listFields: 'codServico,codPapel,codEmp'
  },
  autocomplete: {
    rowTemplate: '{{CodServicoEmpregado}} - {{codServico}}',
    output: 'codServico',
    searchFields: 'CodServicoEmpregado,codServico'
  },
  actions: {
    new: false,
    detail: false,
    edit: false,
    delete: false,
    search: true
  }
};
