<div class="notification-center" [class.dropdown-menu]="!mobile">
  <button type="button" class="btn-close" (click)="close()"></button>

  <div class="notification-center-items" [class.no-notifications]="noNotifications">
    <div class="notification-center-items-new" *ngIf="notifications.notificationsNew.length > 0">
      <div
        class="notification-center-items-title"
        [translate]="notifications.notificationsNew.length === 1 ? 'notificationcenter.newNotification' : 'notificationcenter.newNotifications'"
        [translateParams]="{count: notifications.notificationsNew.length}"></div>
      <div class="notification-center-items-notifications">
        <notification-item
          *ngFor="let notification of notifications.notificationsNew; trackBy: notificationsTrackByFn"
          [notification]="notification"
          [minimalistic]="true"
          [mobile]="mobile"
          (evtSelectedNotification)="selectedNotification(notification)">
        </notification-item>
      </div>
    </div>

    <div class="notification-center-items-old" *ngIf="notifications.notificationsOld.length > 0">
      <div class="notification-center-items-title" [translate]="'notificationcenter.old'"></div>
      <div class="notification-center-items-notifications">
        <notification-item
          *ngFor="let notification of notifications.notificationsOld; trackBy: notificationsTrackByFn"
          [notification]="notification"
          [minimalistic]="true"
          [mobile]="mobile"
          (evtSelectedNotification)="selectedNotification(notification)">
        </notification-item>
      </div>
    </div>

    <notification-center-no-notifications *ngIf="noNotifications" class="notification-center-no-notifications" [minimalistic]="true"></notification-center-no-notifications>
  </div>

  <div class="notification-center-open-all" *ngIf="!noNotifications">
    <hr />
    <a class="hyperlink" [uiSref]="allNotificationsHref" [uiParams]="allNotificationsParams" [translate]="'notificationcenter.openAll'" (click)="close()"> </a>
  </div>
</div>
