<pl-form class="entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h4 class="modal-title" [translate]="'modal.txtFaturacao.title'"></h4>
  </div>
  <div class="modal-body">
    <pl-group>
      <label>{{ clifo.nome }}</label>
    </pl-group>
    <pl-group>
      <edit>
        <pl-edit-textarea [model]="clifo.textofaturacao" [properties]="{disabled: true}"></pl-edit-textarea>
      </edit>
    </pl-group>
  </div>
</pl-form>
