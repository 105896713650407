import {NgModule} from '@angular/core';
import {PlAutofocusDirective} from './autofocus.directive';

export * from './autofocus.directive';

const DECLARATIONS = [
  PlAutofocusDirective
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlAutoFocusModule {
}
