import {ROLE} from '../../../services/role.const';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {MODULE_NAME_RH_CESSA_CONTRATO_TRABALHO} from './rhCessaContratoTrabalho.module.interface';
import {RhCessaContratoTrabalhoModuleComponent} from './components/rhCessaContratoTrabalho.module.component';
import {RHConfiguracoesService} from '../../rhconfiguracoes/rhConfiguracoes.module.service';
import {HttpResponse} from '@angular/common/http';
import {IJsonConfigRHManutencaoCessacaoContrato} from '../../rhconfiguracoes/jsonRHConfiguracoes.module.interface';

export const MODULE_RH_CESSA_CONTRATO_TRABALHO: IModuleDefinition = {
  name: MODULE_NAME_RH_CESSA_CONTRATO_TRABALHO,
  state: {
    url: `/${MODULE_NAME_RH_CESSA_CONTRATO_TRABALHO}`,
    component: RhCessaContratoTrabalhoModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.rhcessacontratotrabalho'
    },
    resolve: [
      {
        provide: 'cessacaoContratoDefault',
        deps: [RHConfiguracoesService],
        useFactory: (rhConfiguracoesService: RHConfiguracoesService): Promise<IJsonConfigRHManutencaoCessacaoContrato> => {
          return rhConfiguracoesService.getManutencaoCessacaoContrato().then((response: HttpResponse<IJsonConfigRHManutencaoCessacaoContrato>) => response.body);
        }
      }
    ]
  }
};
