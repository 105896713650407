<div class="ativosopjustovalorabp-modal">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'ativos.ativosopjustovalorabp.title'"></h5>
    </div>

    <div class="modal-body">
      <pl-group>
        <edit>
          <label [translate]="'ativos.fields.codAtivo'" class="pe-2"></label>
          <span>{{ ativo.codAtivo }} - {{ ativo.designacao }}</span>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'ativos.ativosopjustovalorabp.dataOperacao'"></label>
        <edit>
          <pl-edit type="date" [(model)]="dataOperacao" plAutoFocus></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'ativos.ativosopjustovalorabp.valorOperacao'"></label>
        <edit>
          <pl-edit type="number" [(model)]="valorOperacao"></pl-edit>
        </edit>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn btn-success action-dismiss" [onClick]="close" data-focus><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

      <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
    </div>
  </pl-form>
</div>
