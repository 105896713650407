import {Component, Injector, Input} from '@angular/core';
import {IJsonDocComercial, IJsonDocContabImputacao} from '../../jsonDocComercial.entity.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {DocumentosService} from '../../service/documentos.entity.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'documento-imputacoes-doc-contab-modal',
  templateUrl: './documento.imputacoesDocContab.modal.component.html'
})
export class DocumentoImputacoesDocContabModalComponent extends CGModalComponent<IJsonDocComercial> {
  @Input() public list: Array<IJsonDocContabImputacao>;
  @Input() public faccbId: number;
  @Input() public actionAnular: boolean;

  public dataGridDefinition: IDevExpressDataGrid<IJsonDocContabImputacao, string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _documentosService: DocumentosService
  ) {
    super(_injector);
    this.actionAnular = false;
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [{dataField: 'nDocAsString', dataType: 'string', caption: 'docscomerciais.auxiliares.nDocAsString', allowEditing: false, showInColumnChooser: false}],
      dataSource: new CustomStore({
        load: () => this.list
      }),
      toolbar: {visible: false},
      filterRow: {visible: false},
      headerFilter: {visible: false}
    };
  }

  public getPromptMessage(): string {
    return this.actionAnular ? 'docscomerciais.auxiliares.recibosPagamRelComDocSeraoAnulados' : 'docscomerciais.auxiliares.recibosPagamRelComDocFinalizadoSeraoAnulados';
  }

  public readonly fnConfirm = (): Promise<void> => this._confirm();

  private _confirm(): Promise<void> {
    return this._documentosService.apagaImputacoesDocContab(this.faccbId).then(() => {
      this.close();
    });
  }
}
