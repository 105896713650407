<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'conciliacaoBancaria.importarExtratoBancario'"></h4>
</div>

<div class="modal-body" [plPromise]="mainPromise">
  <pl-tabs [callback]="tabCallback">
    <pl-tab [id]="importadorTabId" caption="conciliacaoBancaria.importTab">
      <div *plTabContent>
        <div class="row">
          <div class="col-md-6">
            <pl-group [properties]="{disabled: true}">
              <label [translate]="'conciliacaoBancaria.fields.nconta'"></label>
              <edit>
                <pl-edit type="text" [model]="nomeConta"></pl-edit>
              </edit>
            </pl-group>
          </div>
          <div class="col-md-6">
            <pl-group>
              <label [translate]="'conciliacaoBancaria.fields.paramImport'"></label>
              <edit>
                <pl-edit
                  type="select"
                  [properties]="{select: {list: model.list}, validators: {required: {value: true}}}"
                  [model]="model.bancoTemplate"
                  (modelChange)="bancoTemplateChanged($event)"></pl-edit>
              </edit>
              <actions>
                <pl-tooltip [config]="{text: 'conciliacaoBancaria.configImportTooltip', placement: 'top', container: 'body'}">
                  <button *plTooltipContent type="button" class="btn btn-light" (click)="fnConfigImportClick()"><i class="fa fa-fw fa-cog"></i></button>
                </pl-tooltip>
              </actions>
            </pl-group>
          </div>
          <div class="col-md-12" *ngIf="readyForUpload">
            <pl-upload
              [url]="uploadUrl"
              [maxFiles]="1"
              [callback]="plUploadCallback"
              [hideActionCancel]="true"
              [zip]="true"
              [uploadMultiple]="false"
              [maxFileSize]="50"
              (evtAcceptedFile)="fnOnAcceptedFile()"
              (evtUploadedFile)="fnOnFileUploaded($event)"
              acceptedFiles=".xls,.xlsx,.csv,.txt">
            </pl-upload>
          </div>
        </div>
      </div>
    </pl-tab>
    <pl-tab [id]="importadorResultsTabId" caption="conciliacaoBancaria.importResultsTab">
      <div *plTabContent>
        <div class="col-md-12" *ngIf="errorMessage !== ''">
          <pl-alert type="error"><i class="fa fa-times-circle" aria-hidden="true"></i>&nbsp;<span [translate]="errorMessage"></span></pl-alert>
        </div>
        <div class="col-md-12">
          <h4 class="main-title" [translate]="'conciliacaoBancaria.saldosNaPerspetivaDoBanco'"></h4>
        </div>
        <div class="col-md-12">
          <table class="saldo-table" [ngClass]="{saldoInvalido: !isSaldoFinalImportadoValido}">
            <thead>
              <tr>
                <th></th>
                <th>Inicial</th>
                <th>Final</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Conciliação:</td>
                <td [innerHTML]="getSaldoString(model.saldoInicial)"></td>
                <td [innerHTML]="getSaldoString(model.saldoFinal)"></td>
              </tr>
              <tr>
                <td>Extrato Importado:</td>
                <td [innerHTML]="getSaldoString(model.saldoInicialImportado)"></td>
                <td [innerHTML]="getSaldoString(model.saldoFinalImportado)"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr />
        <pl-table [definition]="tableDefinition" [source]="fnGetTableSource" [callback]="tableCallback" [properties]="tableOptions"></pl-table>
      </div>
    </pl-tab>
  </pl-tabs>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-success" (click)="save()" [disabled]="!canSave"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></button>
  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
