import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {PcaRHMapasService} from '../../pcaRHMapas.module.service';
import {PortalClientAccountService} from '../../../../../services/portalclientaccount/portalclientaccount.service';
import {Subscription} from 'rxjs';
import {IPCARHMapasServiceRefreshedEvent} from '../../pcaRHMapas.module.service.interface';

@Component({
  selector: 'mapa-fundoscompensacao',
  templateUrl: './mapaFundosCompensacao.module.component.html'
})
export class MapaFundosCompensacaoComponent extends ModuloComponent implements OnInit, OnDestroy {
  public pdfUrl: string;
  private _subscription: Subscription;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _entityServiceBuilder: EntityServiceBuilder,
    protected readonly _cgModalService: CGModalService,
    private readonly _pcaRHMapasService: PcaRHMapasService,
    private readonly _portalClientAccountsFactory: PortalClientAccountService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._subscription = this._pcaRHMapasService.onRefresh().subscribe((event: IPCARHMapasServiceRefreshedEvent) => {
      this.onNotify(event);
    });
    this._pcaRHMapasService.init(this.toolbar);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  public onNotify(event: IPCARHMapasServiceRefreshedEvent): void {
    this._portalClientAccountsFactory.getMapaFundosCompensacaoUrl(event.ano, event.mes).subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }
}
