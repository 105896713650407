<div class="gestao-comunicacoes entity-detail-form" [ngClass]="{loading: promiseLoading}" [plPromise]="promiseLoading">
  <!--  empresa selector widget-->
  <div class="gestao-comunicacoes-widget">
    <div class="gestao-comunicacoes-widget-header p-2">
      <span class="gestao-comunicacoes-widget-header-title" [translate]="'gestaoComunicacoes.messageSidebarSelector.clienteMessage'"></span>
      <div class="d-flex gap-2">
        <pl-edit
          type="text"
          class="gestao-comunicacoes-widget-header-input"
          [model]="filterClienteTopicos"
          (modelChange)="filterClienteTopicosChanged($event)"
          [properties]="{placeholder: 'gestaoComunicacoes.common.filtrarPorPalavraChave', modelOptions: {debounce: 500}, disallowClear: true}"></pl-edit>
        <div class="gestao-comunicacoes-widget-header-add-button" (click)="onClickAddTopico()">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </div>
      </div>
    </div>
    <div *ngFor="let topico of clienteTopicos; let lastMessage = last">
      <cg-message-empresa
        [contabilistaView]="false"
        [empresa]="undefined"
        [topico]="topico"
        [lastMessage]="lastMessage"
        [itemSelected]="topico.comTopicoId === topicoSelected?.comTopicoId"
        (evtChangedClienteTopico)="handleClienteTopicoChange(topico)"></cg-message-empresa>
    </div>
    <div *ngIf="clienteTopicos.length < 1">
      <div class="gestao-comunicacoes-widget-no-results text-center">
        <span [translate]="'global.text.searchNoData'"></span>
      </div>
    </div>
  </div>

  <ng-container [ngSwitch]="!!topicoSelected">
    <ng-container *ngSwitchCase="true">
      <div class="gestao-comunicacoes-widget full-width">
        <div class="gestao-comunicacoes-widget-header cliente-view">
          <div class="gestao-comunicacoes-widget-header-left flex-column">
            <span class="gestao-comunicacoes-widget-header-title cliente-view" [innerText]="topicoSelected.assunto"></span>
            <span class="gestao-comunicacoes-widget-header-title subtitle cliente-view" [innerText]="topicoSelected._utilizadorShow"></span>
          </div>
          <div class="d-flex align-items-center gap-3">
            <!--        <div (click)="onClickTopicoDelete()" class="gestao-comunicacoes-widget-header-icon">-->
            <!--          <i class="fa fa-trash d-flex align-items-center" aria-hidden="true"></i>-->
            <!--        </div>-->
            <div (click)="onClickTopicoClose()" class="gestao-comunicacoes-widget-header-icon">
              <i class="fa fa-times d-flex align-items-center" aria-hidden="true"></i>
            </div>
          </div>
        </div>

        <div class="gestao-comunicacoes-messages">
          <cg-gestao-comunicacoes-messages-container #containerMessages [mensagens]="mensagens"></cg-gestao-comunicacoes-messages-container>

          <cg-gestao-comunicacoes-message-input
            #inputMessage
            [showAttachFile]="true"
            [showSpeechToText]="true"
            [showSendButton]="true"
            [marginTop]="true"
            (evtSubmitFile)="evtSubmitFile($event)"
            (evtSubmitMessage)="evtSubmitedMessage($event)"></cg-gestao-comunicacoes-message-input>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="cg-logo-default-view">
        <img alt="cg-logo" class="cg-logo" [src]="'../../../assets/images/logo-compact-cloud.webp'" />
        <h5 class="mb-1 cg-logo-title" [translate]="'gestaoComunicacoes.common.selectComunicacao'"></h5>
        <h6 [translate]="'gestaoComunicacoes.common.nothingSelected'"></h6>
      </div>
    </ng-container>
  </ng-container>
</div>
