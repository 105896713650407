<div class="faturacao-publica entity-detail-form">
  <pl-tabs [callback]="tabCallback" (evtSelected)="changedTab($event.nextId)" [activeId]="activeTab" [plPromise]="promise">
    <pl-tab [id]="tabEstadoComunicacao.PorComunicarManual" [caption]="captionTabDocsPorComunicarManual">
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="docsPorComunicaManual"
          [dataSource]="faturacaoPublica.documentosPorComunicarManual"
          (onInitialized)="onInitializedDocsPorComunicarManual($event)"
          (onContentReady)="onContentReadyDocsPorComunicarManual()"
          (onSelectionChanged)="onSelectionChangedDocsPorComunicarManual($event)">
          <div *dxTemplate="let item of 'actions'">
            <div ngbDropdown container="body" class="col-main-table-actions">
              <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-cog"></i></button>
              <div ngbDropdownMenu>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.consutCliente'" (click)="consultarCliente(item.data)"></button>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.historico'" (click)="historicoDoc(item.data)"></button>

                <div class="dropdown-divider"></div>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.comunicacaomanual'" (click)="comunicacaoManual(item.data)"></button>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.comunicamanual'" (click)="comunicarManualDoc(item.data)"></button>
              </div>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab [id]="tabEstadoComunicacao.PorComunicarWebServ" [caption]="captionTabDocsPorComunicarWebServ">
      <div *plTabContent>
        <pl-group>
          <div class="toolbar-item ng-star-inserted">
            <button id="btnConfigFatPublica" type="button" class="btn btn-sm btn-light" (click)="configFaturacaoPublica()">
              <i class="fa fa-cog fa-fw"></i>&nbsp;<span [translate]="'global.btn.config'"></span>
            </button>
          </div>
        </pl-group>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="docsPorComunicarWebServ"
          [dataSource]="faturacaoPublica.documentosPorComunicarWebServ"
          (onInitialized)="onInitializedDocsPorComunicarWebServ($event)"
          (onContentReady)="onContentReadyDocsPorComunicarWebServ()">
          <div *dxTemplate="let item of 'actions'">
            <div ngbDropdown container="body" class="col-main-table-actions">
              <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-cog"></i></button>
              <div ngbDropdownMenu>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.consutCliente'" (click)="consultarCliente(item.data)"></button>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.historico'" (click)="historicoDoc(item.data)"></button>

                <div class="dropdown-divider"></div>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.comunicacaomanual'" (click)="comunicacaoManual(item.data)"></button>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.comunicamanual'" (click)="comunicarManualDoc(item.data)"></button>
              </div>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab [id]="tabEstadoComunicacao.ComunicadoEmValidacao" [caption]="captionTabDocsEmValidacao">
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="docsEmValidacao"
          [dataSource]="faturacaoPublica.documentosEmValidacao"
          (onInitialized)="onInitializedDocsEmValidacao($event)"
          (onContentReady)="onContentReadyDocsEmValidacao()">
          <div *dxTemplate="let item of 'actions'">
            <div ngbDropdown container="body" class="col-main-table-actions">
              <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-cog"></i></button>
              <div ngbDropdownMenu>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.consutCliente'" (click)="consultarCliente(item.data)"></button>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.consultDoc'" (click)="openDocModal(item.data)"></button>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.historico'" (click)="historicoDoc(item.data)"></button>

                <div class="dropdown-divider"></div>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.comunicacaomanual'" (click)="comunicacaoManual(item.data)"></button>

                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.comunicamanual'" (click)="comunicarManualDoc(item.data)"></button>
              </div>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab [id]="tabEstadoComunicacao.ComErros" [caption]="captionTabDocsComErro">
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="docsComErros"
          [dataSource]="faturacaoPublica.documentosComErros"
          (onInitialized)="onInitializedDocsComErro($event)"
          (onContentReady)="onContentReadyDocsComErro()">
          <div *dxTemplate="let item of 'actions'">
            <div class="col-main-table-actions" ngbDropdown container="body">
              <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-cog"></i></button>
              <div ngbDropdownMenu>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.consutCliente'" (click)="consultarCliente(item.data)"></button>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.consultDoc'" (click)="openDocModal(item.data)"></button>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.historico'" (click)="historicoDoc(item.data)"></button>

                <div class="dropdown-divider"></div>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.comunicacaomanual'" (click)="comunicacaoManual(item.data)"></button>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.comunicamanual'" (click)="comunicarManualDoc(item.data)"></button>
              </div>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab [id]="tabEstadoComunicacao.Comunicados" [caption]="'faturacaopublica.tab.comunicados'">
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinitionComunicados"
          cgDxDataGridInstanceName="comunicados"
          [dataSource]="dataGridDefinitionComunicados.dataSource"
          (onInitialized)="onInitializedComunicados($event)"
          (onContentReady)="onContentReadyComunicados()">
          <div *dxTemplate="let item of 'actions'">
            <div ngbDropdown container="body" class="col-main-table-actions">
              <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-cog"></i></button>
              <div ngbDropdownMenu>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.consutCliente'" (click)="consultarCliente(item.data)"></button>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.consultDoc'" (click)="openDocModal(item.data)"></button>
                <button type="button" class="dropdown-item" [translate]="'faturacaopublica.menu.historico'" (click)="historicoDoc(item.data)"></button>
              </div>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
