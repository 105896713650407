import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonModelo39} from './jsonModelo39.module.interface';

@Injectable({
  providedIn: 'root'
})
export class Modelo39Service {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/modelo39`;
  }

  public getModelo(prepareTipoRendimentoCapitais: boolean, ano: string): TServiceResponse<IJsonModelo39> {
    return this._apiService.get<IJsonModelo39>({
      url: this._path,
      params: {
        prepareTipoRendimentoCapitais: prepareTipoRendimentoCapitais,
        ano: ano
      }
    });
  }

  public getAnosAnteriores(): TServiceResponse<Array<number>> {
    return this._apiService.get<Array<number>>({
      url: `${this._path}/anosanteriores`
    });
  }

  public criarFicheiro(modelo: IJsonModelo39): TServiceResponse<Blob> {
    return this._apiService.post<Blob, IJsonModelo39>({
      url: `${this._path}/criarficheiro`,
      body: modelo,
      responseType: 'blob'
    });
  }

  public validarDeclaracaoAT(ficheiro: IJsonModelo39): TServiceResponse<void> {
    return this._apiService.post<void, IJsonModelo39>({
      url: `${this._path}/at/validar`,
      body: ficheiro,
      reportExceptions: false
    });
  }

  public submeterDeclaracaoAT(ficheiro: IJsonModelo39): TServiceResponse<void> {
    return this._apiService.post<void, IJsonModelo39>({
      url: `${this._path}/at/submeter`,
      body: ficheiro,
      reportExceptions: false
    });
  }
}
