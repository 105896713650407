<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'configsERPCheck.validateAllModal.title'"></h5>
</div>

<div class="modal-body">
  <pl-edit type="boolean" [(model)]="generateDefaultData" [properties]="{label: 'configsERPCheck.validateAllModal.message'}"></pl-edit>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-close" (evtClicked)="close()" plAutoFocus><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

  <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
</div>
