<!-- Depth 1 -->
<div *ngIf="depth <= depths.Single">
  <ng-container *ngTemplateOutlet="templateDataGrid; context: {dataSource: configs}"></ng-container>
</div>
<!-- /Depth 1 -->

<!-- Depth 2 -->
<pl-navpill attrName="configs" *ngIf="depth > depths.Single" (evtSelected)="loadList($event.nextId)">
  <pl-navpill-panel *ngFor="let group of groups" [id]="group.id" [caption]="group.title">
    <div *plNavPillPanelContent>
      <ng-container *ngTemplateOutlet="templateDataGrid; context: {dataSource: group.items}"></ng-container>
    </div>
  </pl-navpill-panel>
</pl-navpill>
<!-- Depth 2 -->

<ng-template #templateDataGrid let-dataSource="dataSource">
  <dx-data-grid [cgDxDataGrid]="dataGridDefinition" [dataSource]="dataSource" [twoWayBindingEnabled]="false" (onCellPrepared)="onCellPrepared($event)">
    <dxi-column
      *ngFor="let column of columns; first as firstColumn"
      [dataField]="column.dataField"
      [dataType]="column.dataType"
      [alignment]="column.alignment"
      [caption]="column.caption | translate"
      [editCellTemplate]="'cellTemplateValues'"
      [allowEditing]="!firstColumn"
      [showEditorAlways]="!firstColumn"
      [width]="100 / columns.length + '%'">
    </dxi-column>

    <div *dxTemplate="let cellTemplateInfo of 'cellTemplateValues'">
      <ng-container [ngSwitch]="cellTemplateInfo.columnIndex === 0">
        <span *ngSwitchCase="true">{{ cellTemplateInfo.text }}</span>
        <config-value *ngSwitchDefault [object]="cellTemplateInfo.data" [onValidate]="onValidate"></config-value>
      </ng-container>
    </div>
  </dx-data-grid>
</ng-template>
