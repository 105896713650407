export enum EClifoExtraValueTipo {
  None,
  EmailFatura,
  EmailDebitosDiretos,
  EmailAvisosCobranca,
  NEmpresaGabinete,
  EmailRecibosCliente,
  EmailPagamentosFornecedor,
  EmailCirculares
}

export enum EClifoExtraValueNatureza {
  Outros,
  Email
}

export interface IJsonClifoExtraValueTipo {
  codigo: number;
  tipo: EClifoExtraValueTipo;
  descricao: string;
  natureza: EClifoExtraValueNatureza;
}
