import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IJsonModelo22, IJsonModelo22AnexoA} from '../../../jsonModelo22.module.interface';
import {IAnexoAQuadro4ListaATotais, IAnexoAQuadro4ListaBTotais, IAnexoAQuadro4ListaCTot, IAnexoAQuadro4ListaDTot} from '../../../modelo22.module.interface';
import {copy, isNumber} from 'pl-comps-angular';
import {CGModalService} from '../../../../../../components/cg/modal/cgmodal.service';
import {Modelo22AnexoAModalComponent} from './modals/modelo22.anexoA.modal.component';
import {merge} from 'lodash-es';
import {IDevExpressDataGrid, TDevExpressDataGridToolbar} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {Properties as ButtonOptions} from 'devextreme/ui/button';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'modelo22-anexos-anexoa',
  templateUrl: './modelo22.anexos.anexoA.component.html'
})
export class Modelo22AnexosAnexoAComponent implements OnInit, OnChanges {
  @Input() public modelo22: IJsonModelo22;
  @Output() public readonly evtChangedModelo22: EventEmitter<IJsonModelo22>;

  public readonly dataGridDefinitionListaA: IDevExpressDataGrid;
  public readonly dataGridDefinitionListaB: IDevExpressDataGrid;
  public readonly dataGridDefinitionListaC: IDevExpressDataGrid;
  public readonly dataGridDefinitionListaD: IDevExpressDataGrid;

  public readonly dataGridDefinitionQuadro04Lista04ASomaControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro04Lista04BSomaControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro04Lista04CSomaControlo: IDevExpressDataGrid;
  public readonly dataGridDefinitionQuadro04Lista04DSomaControlo: IDevExpressDataGrid;

  public promise: Promise<void>;

  public anexoAQuadro4ListaATotArray: Array<IAnexoAQuadro4ListaATotais>;
  public anexoAQuadro4ListaBTotArray: Array<IAnexoAQuadro4ListaBTotais>;
  public anexoAQuadro4ListaCTotArray: Array<IAnexoAQuadro4ListaCTot>;
  public anexoAQuadro4ListaDTotArray: Array<IAnexoAQuadro4ListaDTot>;

  private readonly _anexoAQuadro4ListaATot: IAnexoAQuadro4ListaATotais;
  private readonly _anexoAQuadro4ListaBTot: IAnexoAQuadro4ListaBTotais;
  private readonly _anexoAQuadro4ListaCTot: IAnexoAQuadro4ListaCTot;
  private readonly _anexoAQuadro4ListaDTot: IAnexoAQuadro4ListaDTot;

  constructor(
    private readonly _cgModalService: CGModalService,
    private readonly _translateService: TranslateService
  ) {
    this.evtChangedModelo22 = new EventEmitter<IJsonModelo22>();
    this.dataGridDefinitionListaA = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'distrito', dataType: 'number', caption: 'modelo22.anexoA.quadro4.sect04-A.table.fields.field1'},
        {dataField: 'taxaDerama', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-A.table.fields.field2'},
        {dataField: 'massaSalarial', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-A.table.fields.field3'},
        {dataField: 'racioReparticao', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-A.table.fields.field4'},
        {dataField: 'derramaCalculada', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-A.table.fields.field5'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnListaA'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoA.quadro4.sect04-A.title'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoAQuadro4('A'))
    };
    this.dataGridDefinitionListaB = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'distrito', dataType: 'number', caption: 'modelo22.anexoA.quadro4.sect04-B.table.fields.field1'},
        {dataField: 'taxaDerama', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-B.table.fields.field2'},
        {dataField: 'massaSalarial', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-B.table.fields.field3'},
        {dataField: 'racioReparticao', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-B.table.fields.field4'},
        {dataField: 'derramaCalculada', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-B.table.fields.field5'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnListaB'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoA.quadro4.sect04-B.title'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoAQuadro4('B'))
    };
    this.dataGridDefinitionListaC = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'primeiroAno', dataType: 'boolean', caption: 'modelo22.anexoA.quadro4.sect04-C.table.fields.field10'},
        {dataField: 'distrito', dataType: 'number', caption: 'modelo22.anexoA.quadro4.sect04-C.table.fields.field1'},
        {dataField: 'taxaDerama', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-C.table.fields.field2'},
        {dataField: 'massaSalarial', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-C.table.fields.field3'},
        {dataField: 'areaInstalacao', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-C.table.fields.field4'},
        {dataField: 'potencia', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-C.table.fields.field5'},
        {dataField: 'totalProduzido', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-C.table.fields.field6'},
        {dataField: 'racioMunicipio', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-C.table.fields.field7'},
        {dataField: 'derramaCalculada', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-C.table.fields.field8'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnListaC'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoA.quadro4.sect04-C.title'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoAQuadro4('C'))
    };
    this.dataGridDefinitionListaD = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: ''},
        {dataField: 'primeiroAno', dataType: 'boolean', caption: 'modelo22.anexoA.quadro4.sect04-D.table.fields.field9'},
        {dataField: 'distrito', dataType: 'number', caption: 'modelo22.anexoA.quadro4.sect04-D.table.fields.field1'},
        {dataField: 'taxaDerama', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-D.table.fields.field2'},
        {dataField: 'massaSalarial', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-D.table.fields.field3'},
        {dataField: 'areaInstalacao', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-D.table.fields.field4'},
        {dataField: 'potencia', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-D.table.fields.field5'},
        {dataField: 'racioMunicipio', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-D.table.fields.field6'},
        {dataField: 'derramaCalculada', dataType: 'double', caption: 'modelo22.anexoA.quadro4.sect04-D.table.fields.field7'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnListaD'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.anexoA.quadro4.sect04-D.title'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: '_index',
      remoteOperations: false,
      toolbar: this._generateDataGridToolbar(() => this.addLineAnexoAQuadro4('D'))
    };

    this.dataGridDefinitionQuadro04Lista04ASomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: 'massaSalarial', dataType: 'double', caption: 'modelo22.anexoA.quadro4.massasaltot'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionQuadro04Lista04BSomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: 'massaSalarial', dataType: 'double', caption: 'modelo22.anexoA.quadro4.massasaltot'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionQuadro04Lista04CSomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: 'massasalprestmun', dataType: 'double', caption: 'modelo22.anexoA.quadro4.massasalprestmun'},
        {dataField: 'areintexplomun', dataType: 'double', caption: 'modelo22.anexoA.quadro4.areintexplomun'},
        {dataField: 'poteinstmun', dataType: 'double', caption: 'modelo22.anexoA.quadro4.poteinstmun'},
        {dataField: 'toteletrprodmun', dataType: 'double', caption: 'modelo22.anexoA.quadro4.toteletrprodmun'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };
    this.dataGridDefinitionQuadro04Lista04DSomaControlo = {
      columns: [
        {dataField: 'title', dataType: 'string', caption: ''},
        {dataField: 'massasalprestmun', dataType: 'double', caption: 'modelo22.anexoA.quadro4.massasalprestmun'},
        {dataField: 'areintexplomun', dataType: 'double', caption: 'modelo22.anexoA.quadro4.areintexplomun'},
        {dataField: 'poteinstmun', dataType: 'double', caption: 'modelo22.anexoA.quadro4.prodbocaminamun'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      headerFilter: {visible: false},
      remoteOperations: false
    };

    const totalsTitle: string = this._translateService.instant('modelo22.anexoA.quadro4.somacontr');
    this._anexoAQuadro4ListaATot = {
      title: totalsTitle,
      massaSalarial: 0
    };
    this._anexoAQuadro4ListaBTot = {
      title: totalsTitle,
      massaSalarial: 0
    };
    this._anexoAQuadro4ListaCTot = {
      title: totalsTitle,
      massasalprestmun: 0,
      areintexplomun: 0,
      poteinstmun: 0,
      toteletrprodmun: 0
    };
    this._anexoAQuadro4ListaDTot = {
      title: totalsTitle,
      massasalprestmun: 0,
      areintexplomun: 0,
      prodbocaminamun: 0
    };

    this.anexoAQuadro4ListaATotArray = [this._anexoAQuadro4ListaATot];
    this.anexoAQuadro4ListaBTotArray = [this._anexoAQuadro4ListaBTot];
    this.anexoAQuadro4ListaCTotArray = [this._anexoAQuadro4ListaCTot];
    this.anexoAQuadro4ListaDTotArray = [this._anexoAQuadro4ListaDTot];
  }

  public ngOnInit(): void {
    this._applyAnexoA(this.anexoA, false);
  }

  public ngOnChanges({modelo22}: SimpleChanges): void {
    if (modelo22 && !modelo22.isFirstChange()) {
      this.modelo22 = modelo22.currentValue;
      this._setListIndexes();
    }
  }

  public addLineAnexoAQuadro4(field: string): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo22AnexoAModalComponent);
    const componentInstance: Modelo22AnexoAModalComponent = modalInstance.componentInstance;
    componentInstance.anexoA = copy(this.anexoA);
    componentInstance.newLineFor = field;
    return modalInstance.result.then((result: IJsonModelo22AnexoA) => {
      this._applyAnexoA(result);
    });
  }

  public deleteLineAnexoAQuadro4(line: number, field: string): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      if (field === 'A') {
        this.anexoA.quadro04.listaA.splice(line, 1);
      }
      if (field === 'B') {
        this.anexoA.quadro04.listaB.splice(line, 1);
      }
      if (field === 'C') {
        this.anexoA.quadro04.listaC.splice(line, 1);
      }
      if (field === 'D') {
        this.anexoA.quadro04.listaD.splice(line, 1);
      }
      this._applyAnexoA(this.anexoA);
    });
  }

  public emitModelo22(): void {
    this.evtChangedModelo22.emit(this.modelo22);
  }

  public get anexoA(): IJsonModelo22AnexoA {
    return this.modelo22.anexoA;
  }

  public set anexoA(value: IJsonModelo22AnexoA) {
    this.modelo22.anexoA = value;
  }

  private _generateDataGridToolbar(callback: () => void): TDevExpressDataGridToolbar {
    return {
      items: [
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'add',
            hint: this._translateService.instant('modelo22.comum.addline'),
            onClick: () => {
              callback();
            }
          } satisfies ButtonOptions
        },
        'exportButton',
        'columnChooserButton'
      ]
    };
  }

  private _applyAnexoA(anexoA: IJsonModelo22AnexoA, emit: boolean = true): void {
    this.anexoA = merge(this.anexoA, anexoA);
    this._calcTotals();
    this._setListIndexes();
    if (emit) {
      this.emitModelo22();
    }
    this.dataGridDefinitionListaA.dataSource = this.anexoA.quadro04.listaA;
    this.dataGridDefinitionListaB.dataSource = this.anexoA.quadro04.listaB;
    this.dataGridDefinitionListaC.dataSource = this.anexoA.quadro04.listaC;
    this.dataGridDefinitionListaD.dataSource = this.anexoA.quadro04.listaD;
  }

  private _setListIndexes(): void {
    let indexA = 1;
    let indexB = 1;
    let indexC = 1;
    let indexD = 1;
    for (const listElement of this.anexoA.quadro04.listaA) {
      listElement._index = indexA;
      indexA++;
    }
    this.anexoA.quadro04.listaA = this.anexoA.quadro04.listaA.slice();

    for (const listElement of this.anexoA.quadro04.listaB) {
      listElement._index = indexB;
      indexB++;
    }
    this.anexoA.quadro04.listaB = this.anexoA.quadro04.listaB.slice();

    for (const listElement of this.anexoA.quadro04.listaC) {
      listElement._index = indexC;
      indexC++;
    }
    this.anexoA.quadro04.listaC = this.anexoA.quadro04.listaC.slice();

    for (const listElement of this.anexoA.quadro04.listaD) {
      listElement._index = indexD;
      indexD++;
    }
    this.anexoA.quadro04.listaD = this.anexoA.quadro04.listaD.slice();
  }

  private _calcTotals(): void {
    this._anexoAQuadro4ListaATot.massaSalarial = 0;
    for (const itemA of this.anexoA.quadro04.listaA) {
      const value = isNumber(itemA.massaSalarial) ? itemA.massaSalarial : 0;
      this._anexoAQuadro4ListaATot.massaSalarial += value;
    }
    this.anexoAQuadro4ListaATotArray = this.anexoAQuadro4ListaATotArray.slice();

    this._anexoAQuadro4ListaATot.massaSalarial = 0;
    for (const itemB of this.anexoA.quadro04.listaB) {
      const value = isNumber(itemB.massaSalarial) ? itemB.massaSalarial : 0;
      this._anexoAQuadro4ListaATot.massaSalarial += value;
    }
    this.anexoAQuadro4ListaBTotArray = this.anexoAQuadro4ListaBTotArray.slice();

    this._anexoAQuadro4ListaCTot.massasalprestmun = 0;
    this._anexoAQuadro4ListaCTot.areintexplomun = 0;
    this._anexoAQuadro4ListaCTot.poteinstmun = 0;
    this._anexoAQuadro4ListaCTot.toteletrprodmun = 0;
    for (const itemC of this.anexoA.quadro04.listaC) {
      const valueMassa = isNumber(itemC.massaSalarial) ? itemC.massaSalarial : 0;
      const valueArea = isNumber(itemC.areaInstalacao) ? itemC.areaInstalacao : 0;
      const valuePot = isNumber(itemC.potencia) ? itemC.potencia : 0;
      const valueProd = isNumber(itemC.totalProduzido) ? itemC.totalProduzido : 0;
      this._anexoAQuadro4ListaCTot.massasalprestmun += valueMassa;
      this._anexoAQuadro4ListaCTot.areintexplomun += valueArea;
      this._anexoAQuadro4ListaCTot.poteinstmun += valuePot;
      this._anexoAQuadro4ListaCTot.toteletrprodmun += valueProd;
    }
    this.anexoAQuadro4ListaCTotArray = this.anexoAQuadro4ListaCTotArray.slice();

    this._anexoAQuadro4ListaDTot.massasalprestmun = 0;
    this._anexoAQuadro4ListaDTot.areintexplomun = 0;
    this._anexoAQuadro4ListaDTot.prodbocaminamun = 0;
    for (const itemD of this.anexoA.quadro04.listaD) {
      const valueMassa = isNumber(itemD.massaSalarial) ? itemD.massaSalarial : 0;
      const valueArea = isNumber(itemD.areaInstalacao) ? itemD.areaInstalacao : 0;
      const valueProd = isNumber(itemD.producao) ? itemD.producao : 0;
      this._anexoAQuadro4ListaDTot.massasalprestmun += valueMassa;
      this._anexoAQuadro4ListaDTot.areintexplomun += valueArea;
      this._anexoAQuadro4ListaDTot.prodbocaminamun += valueProd;
    }
    this.anexoAQuadro4ListaDTotArray = this.anexoAQuadro4ListaDTotArray.slice();
  }
}
