<div class="extratos-artigos-component">
  <cg-card-panel [collapsed]="hasParam" #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="pesquisar" plPromise>
        <pl-group *ngIf="showFilterArtigo">
          <pl-group>
            <label><span [translate]="'extractosartigos.caption.deCodArtigo'"></span>:</label>
            <edit>
              <entity-autocomplete
                entity="artigos"
                attrName="artigode"
                [model]="filters.nArtigoDe"
                [filter]="'artDesactivado=false'"
                [selectedKey]="filters.nArtigoDe"
                (selectedKeyChange)="changedDeNArtigo($event)"
                [helperMode]="true"
                output="key"
                plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label><span [translate]="'global.text.to'"></span>:</label>
            <edit>
              <entity-autocomplete
                entity="artigos"
                attrName="armazemate"
                [model]="filters.nArtigoAte"
                [filter]="'artDesactivado=false'"
                [selectedKey]="filters.nArtigoAte"
                (selectedKeyChange)="filters.nArtigoAte = $event"
                [helperMode]="true"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label><span [translate]="'extractosartigos.caption.deNArmazem'"></span>:</label>
            <edit>
              <entity-autocomplete
                entity="armazens"
                attrName="armazemde"
                [model]="filters.nArmazemDe"
                [selectedKey]="filters.nArmazemDe"
                (selectedKeyChange)="changedDeNArmazem($event)"
                [helperMode]="true"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label><span [translate]="'global.text.to'"></span>:</label>
            <edit>
              <entity-autocomplete
                entity="armazens"
                attrName="artigoate"
                [model]="filters.nArmazemAte"
                [selectedKey]="filters.nArmazemAte"
                (selectedKeyChange)="changedNArmazemAte($event)"
                [helperMode]="true"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label><span [translate]="'extractosartigos.caption.deData'"></span>:</label>
            <edit class="input-group">
              <pl-edit-datepicker attrName="dateDe" [model]="filters.dateDe" (modelChange)="changedDateDe($event)"></pl-edit-datepicker>
            </edit>
          </pl-group>

          <pl-group>
            <label><span [translate]="'global.text.to'"></span>:</label>
            <edit class="input-group">
              <pl-edit-datepicker attrName="dateAte" [model]="filters.dateAte" (modelChange)="changedDateAte($event)"></pl-edit-datepicker>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <edit class="form-check-pl-checkbox">
            <pl-edit-checkbox [(model)]="filters.calcMovAntesDepoisData" [properties]="{label: 'extractosartigos.caption.calcMovAntesDepoisData'}"></pl-edit-checkbox>
          </edit>
        </pl-group>

        <pl-group class="pl-group-button">
          <button type="submit" class="btn btn-sm btn-primary" name="pesquisar" id="btnPesquisar">
            <i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'extractosartigos.pesquisa'"></span>
          </button>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-data-grid
    [cgDxDataGrid]="dataGrid"
    [dataSource]="dataGrid.dataSource"
    cgDxDataGridInstanceName="extratosArtigosGrid"
    (onInitialized)="onDataGridInitialized($event)"
    (onCellClick)="onDataGridCellClick($event)"
    (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)">
    <div *dxTemplate="let item of 'templateActions'">
      <button type="button" class="btn btn-link p-0" (click)="openExtractoArtigoContextMenu($event.target, item.data)"><i class="fa fa-cog"></i></button>
    </div>

    <div *dxTemplate="let item of 'templateMasterDetail'">
      <documento-faturacao [doc]="item.data.docComercial"></documento-faturacao>
    </div>
  </dx-data-grid>
</div>
