import {Component, Injector, Input, OnInit} from '@angular/core';
import {IPlNavWizardDefinition, IPlNavWizardEventBeforeChange, PlAlertService} from 'pl-comps-angular';
import {AssistenteConfigPortaisService} from '../../../../assistente.configPortais.module.service';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../../../../config/constants';
import {IJsonDGEMP} from '../../../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonErpUser, IJsonUserEmpresa} from '../../../../../../../services/account/jsonUserApi.interface';
import {IJsonErp} from '../../../../../../../interfaces/jsonUserManager.interface';
import {ModuloComponent} from '../../../../../../../components/module/module.component';

const STEP_INDEX_UTILIZADOR = 0;
const STEP_INDEX_EMPRESA = 1;

@Component({
  selector: 'module-assist-config-portais-empregado',
  templateUrl: './assistConfigPortais.empregado.module.component.html'
})
export class AssistConfigPortaisEmpregadoModuleComponent extends ModuloComponent implements OnInit {
  @Input() public erps: Array<IJsonErp>;

  public readonly navWizardDefinition: IPlNavWizardDefinition;
  public empresas: Array<IJsonErpUser>;
  public centralGestId: number;
  public nEmpresa: string;
  public utilizadorSel: IJsonUserEmpresa;
  public empregadoSel: IJsonDGEMP;
  public nEmpresaEmpregadoFilter: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _assistenteConfigPortaisService: AssistenteConfigPortaisService
  ) {
    super(_injector);
    this.navWizardDefinition = {
      items: []
    };
    this.empresas = [];
    this.nEmpresaEmpregadoFilter = '';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnBack.visible = true;
    this.btnBack.click = () => this._goAssistenteConfiguracaoSel(this._assistenteConfigPortaisService.getModuleSelectPortal());
    this.centralGestId = this.session.erp.centralGestId;
  }

  public onUtilizadorChanged(utilizador: IJsonUserEmpresa): void {
    this.utilizadorSel = utilizador;
    this.empresas = utilizador.erps.filter((erp: IJsonErpUser) => !erp.codEmp);
    if (this.empresas.length === 1 && !this.nEmpresa) {
      this.onEmpresaChange(this.empresas[0].nEmpresa);
    }
  }

  public onEmpresaChange(nEmpresa: string): void {
    this.nEmpresa = nEmpresa;
    this.nEmpresaEmpregadoFilter = `nempresa=${this.nEmpresa}`;
  }

  public onEmpregadoChanged(empregado: IJsonDGEMP): void {
    this.empregadoSel = empregado;
  }

  public readonly fnBeforeChangedStep = (event: IPlNavWizardEventBeforeChange): boolean => this._beforeChangedStep(event);

  public readonly fnFinalize: () => Promise<void> = () => this._finalize();

  private _goAssistenteConfiguracaoSel(moduleName: string): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: moduleName});
  }

  private _dataUtilizadorValidate(): boolean {
    let error = '';
    if (!this.utilizadorSel) {
      error = 'assistenteconfigportais.info.utilizadornaosel';
    }
    if (!this.nEmpresa) {
      error = 'assistenteconfigportais.info.empresanaosel';
    }
    if (!this.empregadoSel) {
      error = 'assistenteconfigportais.info.empregadonaosel';
    }
    if (error) {
      this._plAlertService.error(error);
      return false;
    }
    return true;
  }

  private _beforeChangedStep({previousStep, currentStep, type, nextStep}: IPlNavWizardEventBeforeChange): boolean {
    if (!currentStep) {
      return true;
    }
    if (currentStep.stepId === this.navWizardDefinition.items[STEP_INDEX_UTILIZADOR].stepId && !previousStep && !nextStep && type !== 'previous') {
      if (!this.empresas.length) {
        this._plAlertService.error('assistenteconfigportais.info.naotemempresasparaconfigurar');
        return false;
      }
    }
    if (
      currentStep.stepId === this.navWizardDefinition.items[STEP_INDEX_EMPRESA].stepId &&
      previousStep.stepId !== this.navWizardDefinition.items[STEP_INDEX_EMPRESA].stepId &&
      !nextStep &&
      type !== 'previous' &&
      !this.nEmpresa
    ) {
      this._plAlertService.error('assistenteconfigportais.info.empresanaoselec');
      return false;
    }
    return true;
  }

  private _finalize(): Promise<void> {
    if (!this._dataUtilizadorValidate()) {
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    if (this.utilizadorSel.erp.nEmpresa === this.nEmpresa) {
      this.utilizadorSel.erp.codEmp = this.empregadoSel.codEmp;
      this.utilizadorSel.erp.nomeEmpregado = this.empregadoSel.nome;
    }
    for (const erp of this.utilizadorSel.erps) {
      if (erp.nEmpresa === this.nEmpresa) {
        erp.codEmp = this.empregadoSel.codEmp;
        erp.nomeEmpregado = this.empregadoSel.nome;
        break;
      }
    }
    return this._assistenteConfigPortaisService.associateEmpregadoToUser(this.utilizadorSel.userId, this.utilizadorSel).then(() => {
      this._plAlertService.success('assistenteconfigportais.info.associateempsucess');
      this._goAssistenteConfiguracaoSel('assistenteConfigPortais');
    });
  }
}
