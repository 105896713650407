import {Component, Injector, Input, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../components/module/module.component';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDataSourceItem} from '../../../components/datasource/datasources.interface';
import {ETipoEscolhaRendHabitPerm, ETipoOrdenacao} from '../jsonRendHabitPerm.module.interface';
import {IPlToolbarItem, IPlToolbarMenuItem} from 'pl-comps-angular';
import {IRendHabitPerm} from '../rendHabitPerm.module.interface';
import moment from 'moment';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {RendHabitPermModalComponent} from '../modals/rendHabitPerm.modal.component';
import {RendHabitPermService} from '../rendHabitPerm.module.service';
import {HttpResponse} from '@angular/common/http';

@Component({
  selector: 'rend-habit-perm',
  templateUrl: './rendHabitPerm.module.component.html'
})
export class RendHabitPermModuleComponent extends ModuloComponent implements OnInit {
  @Input() public rendHabitPermList: Array<IRendHabitPerm>;

  public readonly dataGridDefinition: IDevExpressDataGrid;

  private readonly _dsTipoUtlViatPatronal: Array<IDataSourceItem<ETipoEscolhaRendHabitPerm>>;
  private _tiposRendHabitPerm: Array<IPlToolbarMenuItem>;
  private _dataGridInstance: dxDataGrid;
  private _lastTipoRendHabitPerm: ETipoEscolhaRendHabitPerm;
  private _selectedTipoRendHabitPerm: IPlToolbarMenuItem;
  private _mnuTipoRendHabitPerm: IPlToolbarItem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _rendHabitPermService: RendHabitPermService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      groupPanel: {visible: true},
      grouping: {autoExpandAll: false},
      columns: [
        {dataField: '_empDesc', dataType: 'string', caption: 'rendhabitperm.fields.codEmp', groupIndex: 0},
        {dataField: '_abonoDesc', dataType: 'string', caption: 'rendhabitperm.fields.codAbdes', groupIndex: 1},
        {dataField: 'anoProcessamento', dataType: 'number', caption: 'rendhabitperm.fields.anoProcessamento'},
        {dataField: 'valorRenda', dataType: 'double', caption: 'rendhabitperm.fields.valorRend'},
        {dataField: 'tipoProcessamentoStr', dataType: 'string', caption: 'rendhabitperm.fields.rhTipoProce'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnsRendHbitPerm'}
      ],
      dataSource: [],
      export: {filename: 'rendhabitperm.filename'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: 'rendHabitPermId',
      remoteOperations: false
    };
    this._tiposRendHabitPerm = [];
    this._dsTipoUtlViatPatronal = [
      {
        value: ETipoEscolhaRendHabitPerm.Ativas,
        name: this._translateService.instant('rendhabitperm.tipoRendHabitPerm.ativas')
      },
      {
        value: ETipoEscolhaRendHabitPerm.Inativas,
        name: this._translateService.instant('rendhabitperm.tipoRendHabitPerm.inativas')
      },
      {
        value: ETipoEscolhaRendHabitPerm.Todas,
        name: this._translateService.instant('rendhabitperm.tipoRendHabitPerm.todas')
      }
    ];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'saveRendHabitPerm',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-plus-circle fa-fw"></i>',
      class: 'btn-primary',
      caption: 'global.btn.new',
      click: () => this._openRendHabitPermModal(this._emptyRendHabitPerm())
    });
    this._lastTipoRendHabitPerm = ETipoEscolhaRendHabitPerm.Ativas;
    this._tiposRendHabitPerm = this._dsTipoUtlViatPatronal.map<IPlToolbarMenuItem>((item: IDataSourceItem<ETipoEscolhaRendHabitPerm>) => {
      const tipo: ETipoEscolhaRendHabitPerm = item.value;
      const itemEscolha: IPlToolbarMenuItem = {
        id: String(tipo),
        caption: item.name,
        active: tipo === this._lastTipoRendHabitPerm,
        click: (menuItem: IPlToolbarMenuItem) => {
          this._tipoRendHabitPermChanged(menuItem);
        }
      };
      if (itemEscolha.active) {
        this._selectedTipoRendHabitPerm = itemEscolha;
      }
      return itemEscolha;
    });
    this._mnuTipoRendHabitPerm = {
      order: 101,
      id: 'rendHabitPermListMnuTipo',
      caption: this._translateService.instant('rendhabitperm.toolbar.tipoRendHabitPerm', {nomeTipo: this._dsTipoUtlViatPatronal[0].name}),
      type: 'dropdown',
      menu: this._tiposRendHabitPerm
    };
    this.toolbar.addButton(this._mnuTipoRendHabitPerm);
    this._applyDataSourceRendHabitPerm();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public readonly fnEditRendHabitPerm: (item: IRendHabitPerm) => () => Promise<void> = (item: IRendHabitPerm) => (): Promise<void> => this._openRendHabitPermModal(item);

  public readonly fnDeleteRendHabitPerm: (item: IRendHabitPerm) => () => Promise<void> = (item: IRendHabitPerm) => (): Promise<void> => this._deleteRendHabitPerm(item);

  private _applyDataSourceRendHabitPerm(): void {
    for (const rendHabitPerm of this.rendHabitPermList) {
      rendHabitPerm._empDesc = `${rendHabitPerm.codEmp} - ${rendHabitPerm.nome}`;
      rendHabitPerm._abonoDesc = `${rendHabitPerm.codAbdes} - ${rendHabitPerm.abdesNome}`;
    }
    this.dataGridDefinition.dataSource = this.rendHabitPermList;
  }

  private _tipoRendHabitPermChanged(menuItem: IPlToolbarMenuItem): void {
    if (menuItem.id !== String(this._lastTipoRendHabitPerm)) {
      if (this._selectedTipoRendHabitPerm) {
        this._selectedTipoRendHabitPerm.active = false;
      }
      this._selectedTipoRendHabitPerm = menuItem;
      this._selectedTipoRendHabitPerm.active = true;
      this._lastTipoRendHabitPerm = Number(menuItem.id);
      this._mnuTipoRendHabitPerm.caption = this._getTipoRendHabitPermlCaption();
      this._dataGridInstance.beginCustomLoading(undefined);
      this._rendHabitPermService
        .getRendHabitPerm(Number(this._selectedTipoRendHabitPerm.id), ETipoOrdenacao.CodEmp)
        .then((response: HttpResponse<Array<IRendHabitPerm>>) => {
          this.rendHabitPermList = response.body;
          this._applyDataSourceRendHabitPerm();
        })
        .finally(() => {
          this._dataGridInstance.endCustomLoading();
        });
    }
  }

  private _getTipoRendHabitPermlCaption(): string {
    const nomeTipoEmCurso = this._tiposRendHabitPerm[this._lastTipoRendHabitPerm].caption;
    return this._translateService.instant('rendhabitperm.toolbar.tipoRendHabitPerm', {nomeTipo: nomeTipoEmCurso});
  }

  private _openRendHabitPermModal(rendhabitPerm: IRendHabitPerm): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(RendHabitPermModalComponent, {size: 'xxl'});
    const componentInstance: RendHabitPermModalComponent = modalInstance.componentInstance;
    componentInstance.estado = Number(this._selectedTipoRendHabitPerm.id);
    componentInstance.tipoOrdenacao = ETipoOrdenacao.CodEmp;
    componentInstance.rendHabitPerm = rendhabitPerm;
    return modalInstance.result.then((result: Array<IRendHabitPerm>) => {
      this.rendHabitPermList = result;
      this._applyDataSourceRendHabitPerm();
    });
  }

  private _deleteRendHabitPerm(rendHabitPerm: IRendHabitPerm): Promise<void> {
    return this._rendHabitPermService.deleteRendHabitPerm(rendHabitPerm.rendHabitPermId).then(() => {
      const index: number = this.rendHabitPermList.findIndex((item: IRendHabitPerm) => item.rendHabitPermId === rendHabitPerm.rendHabitPermId);
      if (index !== -1) {
        this.rendHabitPermList.splice(index, 1);
        this._applyDataSourceRendHabitPerm();
      }
    });
  }

  private _emptyRendHabitPerm(): IRendHabitPerm {
    return {
      rendHabitPermId: '',
      codAbdes: '',
      abdesNome: '',
      codEmp: '',
      nome: '',
      valorRenda: 0,
      valorRendaMaxMuni: 0,
      anoProcessamento: moment().year(),
      mesInicioProce: moment().month() + 1,
      observacoes: '',
      suspende: false,
      dataSuspensao: moment(),
      obsSuspensao: '',
      tipoProcessamento: undefined,
      valorIncidendiaIRS: 0,
      tipoProcessamentoStr: '',
      _empDesc: '',
      _abonoDesc: ''
    };
  }
}
