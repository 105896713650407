<div class="tabs-config-portais">
  <pl-tabs [pills]="true">
    <pl-tab *ngFor="let tab of portalsList" [id]="tab.name">
      <div *plTabTitle>
        <i [ngClass]="tab.icon"></i> <br />
        <span [translate]="tab.title"></span>
      </div>

      <div *plTabContent>
        <div class="configportais-operation">
          <h3 class="d-block d-md-none text-center text-primary page-header-cg" [translate]="tab.title"></h3>
          <table class="table table-striped table-sm table-hover">
            <thead>
              <tr>
                <th><h4 [translate]="'predefinidoscontabilidade.titles.chooseOperation'"></h4></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let operation of tab.operations">
                <td (click)="executeOperation(operation)"><i class="fa fa-fw fa-arrow-circle-right"></i>&nbsp;<span [translate]="operation.title"></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
