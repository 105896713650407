import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_FORMA_INCREMENTO_NDOC} from './formaIncrementoNDoc.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_FORMA_INCREMENTO_NDOC: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_FORMA_INCREMENTO_NDOC,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: 0, name: 'formaIncrementoNDoc.data.numberingRestartsEachPeriod'},
    {value: 1, name: 'formaIncrementoNDoc.data.sequentialNumbering'}
  ]
});
