<div class="impde119 entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <pl-group>
          <label [translate]="'global.text.listing'"></label>
          <edit>
            <pl-edit type="reports_impde119" attrName="listagem" [(model)]="report"></pl-edit>
          </edit>
        </pl-group>
        <label><span [translate]="'impde119.filters.filtrarPor'"></span>:</label>
        <pl-group>
          <edit>
            <pl-edit type="radiogroup" attrName="tipoDeclaracao" [(model)]="filters.filtrarpesquisa" [properties]="{groupItems: filtrarPesquisa}"></pl-edit>
          </edit>
        </pl-group>
        <hr />
        <pl-group>
          <pl-group>
            <edit>
              <label [translate]="'impde119.filters.dedata'"></label>
              <pl-edit type="date" [(model)]="filters.dedata"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <edit>
              <label [translate]="'global.text.to'"></label>
              <pl-edit type="date" [(model)]="filters.atedata"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'impde119.filters.decodemp'"></label>
            <edit>
              <entity-autocomplete
                entity="dgempsfull"
                attrName="codEmpDe"
                [model]="filters"
                (selectedKeyChange)="filters.decodemp = $event"
                [fieldsMap]="{codEmp: 'decodemp'}"
                output="key"
                [properties]="{disabled: filters.filtrarpesquisa === eTiposDeclaracao.Outros}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="dgempsfull"
                attrName="codEmpAte"
                [model]="filters"
                (selectedKeyChange)="filters.atecodemp = $event"
                [fieldsMap]="{codEmp: 'atecodemp'}"
                output="key"
                [properties]="{disabled: filters.filtrarpesquisa === eTiposDeclaracao.Outros}">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'impde119.filters.dencontribuinte'"></label>
            <edit>
              <entity-autocomplete
                entity="nifs"
                attrName="dencontribuinte"
                [(selectedKey)]="filters.dencontribuinte"
                [properties]="{disabled: filters.filtrarpesquisa === eTiposDeclaracao.Empregado}"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="nifs"
                attrName="atencontribuinte"
                [(selectedKey)]="filters.atencontribuinte"
                [properties]="{disabled: filters.filtrarpesquisa === eTiposDeclaracao.Empregado, events: {keydown: fnKeydownProcessar}}"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-multiselect [model]="empresas" [source]="empresasSource" [template]="empresasTemplate" key="nEmpresa"></pl-multiselect>
      </pl-form>
    </div>
  </cg-card-panel>

  <!--  <hr/>-->

  <cg-pdf-viewer *ngIf="showPdfViewer" class="mt-4" [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
