export type TPlImageResizerMethod = 'contain' | 'crop';

export interface IPlImageResizerProperties {
  resizeWidth: number;
  resizeHeight: number;
  resizeTargetSize: number; // In megabytes
  resizeTargetSizeStep: number; // In pixels
  resizeMimeType: string;
  resizeQuality: number;
  resizeMethod: TPlImageResizerMethod;
}

export interface IPlResizedImage {
  dataURL: string;
  canvas: HTMLCanvasElement;
  width: number;
  height: number;
}

export interface IPlResizedImageInfo {
  srcX: number;
  srcY: number;
  srcWidth: number;
  srcHeight: number;
  targetWidth: number;
  targetHeight: number;
}

export const ONE_KIBIBYTE = 1024;
export const IMAGE_RESIZER_DEFAULT_RESIZE_QUALITY = 0.8;
