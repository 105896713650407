import {Component, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlFormDefinition} from 'pl-comps-angular';
import {EFaturaConfigDocsUIService} from '../../eFaturaConfigDocs.module.ui.service';
import {IJsonEFaturaConfigDocs} from '../../jsonEFaturaConfigDocs.module.interface';

@Component({
  selector: 'config-docs-efatura-global',
  templateUrl: './configDocsEfatura.global.component.html'
})
export class ConfigDocsEFaturaGlobalComponent implements OnInit {
  @Input() public model: IJsonEFaturaConfigDocs;
  public readonly formDefinition: IPlFormDefinition;
  public promise: Promise<void>;

  constructor(private readonly _eFaturaConfigDocsUIService: EFaturaConfigDocsUIService) {
    this.formDefinition = {
      fields: [
        {
          name: 'criterioOrdenacao',
          type: 'select',
          caption: 'configEfaturaDocPorLanc.global.fields.criterioOrdenacao',
          properties: {
            select: {
              list: [
                {value: 0, name: 'configEfaturaDocPorLanc.global.criterioOrdenacao.dataNome'},
                {value: 1, name: 'configEfaturaDocPorLanc.global.criterioOrdenacao.nomeData'}
              ]
            }
          }
        },
        {name: 'posicionaAutoNextDoc', type: 'boolean', caption: 'configEfaturaDocPorLanc.global.fields.posicionaAutoNextDoc'},
        {name: 'mostraDocsFornIgnorados', type: 'boolean', caption: 'configEfaturaDocPorLanc.global.fields.mostraDocsFornIgnorados'},
        {name: 'autoFixATDocWithDiffOneCent', type: 'boolean', caption: 'configEfaturaDocPorLanc.global.fields.autoFixATDocWithDiffOneCent'}
      ]
    };
  }

  public ngOnInit(): void {
    if (!this.model) {
      this.model = {
        autoFixATDocWithDiffOneCent: false,
        criterioOrdenacao: undefined,
        docConferencia: undefined,
        docConferenciaDesc: undefined,
        fatura: undefined,
        faturaDesc: undefined,
        faturaEstrangeiro: undefined,
        faturaEstrangeiroDesc: undefined,
        faturaRecibo: undefined,
        faturaReciboDesc: undefined,
        faturaSimplificada: undefined,
        faturaSimplificadaDesc: undefined,
        mostraDocsFornIgnorados: false,
        notaCredito: undefined,
        notaCreditoDesc: undefined,
        notaDebito: undefined,
        notaDebitoDesc: undefined,
        posicionaAutoNextDoc: false,
        reciboVerde: undefined,
        reciboVerdeDesc: undefined
      };
      this.promise = this._eFaturaConfigDocsUIService.getEFaturaConfigsDocs().then((response: HttpResponse<IJsonEFaturaConfigDocs>) => {
        this.model = response.body;
      });
    }
  }

  public save(): Promise<HttpResponse<IJsonEFaturaConfigDocs>> {
    return this._eFaturaConfigDocsUIService.saveEFaturaConfigDocs(this.model);
  }
}
