import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Logger, PlDocumentService, PlTranslateService} from 'pl-comps-angular';
import {ContabilidadeDigitalGenericViewerComponent} from '../viewers/contabilidadedigital.genericviewer.component';
import {ContabilidadeDigitalUIService} from '../../../../services/contabilidadedigital/contabilidadedigital.ui.service';
import {
  IContabilidadeDigitalGenericViewerEvtFolderItemChanged,
  IContabilidadeDigitalGenericViewerFile,
  IContabilidadeDigitalGenericViewerFolder,
  IContabilidadeDigitalGenericViewerFolderPath
} from '../viewers/contabilidadedigital.genericviewer.interface';

@Component({
  selector: 'contabilidadedigital-flatviewer',
  templateUrl: './contabilidadedigital.flatviewer.component.html'
})
export class ContabilidadeDigitalFlatViewerComponent extends ContabilidadeDigitalGenericViewerComponent {
  @Input() public selectedView: IContabilidadeDigitalGenericViewerFolder;
  @Input() public loadingView: boolean;
  @Output() public readonly evtSelectedViewChanged: EventEmitter<IContabilidadeDigitalGenericViewerEvtFolderItemChanged>;

  constructor(
    protected readonly _logger: Logger,
    protected readonly _plDocumentService: PlDocumentService,
    protected readonly _contabilidadeDigitalUIService: ContabilidadeDigitalUIService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_logger, _plDocumentService, _contabilidadeDigitalUIService);
    this.loadingView = false;
    this.evtSelectedViewChanged = new EventEmitter<IContabilidadeDigitalGenericViewerEvtFolderItemChanged>();
  }

  public selectFolder(value: IContabilidadeDigitalGenericViewerFolder): void {
    if (this.selectedFile) {
      this.selectedFile = undefined;
    }
    this.selectedFolder = value;
    this._evaluateCurrentFolderPath();
    if (this.selectedFolder) {
      this.evtFolderChanged.emit({root: this.root, folder: this.selectedFolder});
    }
  }

  public selectFile(value: IContabilidadeDigitalGenericViewerFile): void {
    this.selectedFile = value;
    if (this.selectedFile) {
      this.evtFileChanged.emit({root: this.root, file: this.selectedFile});
    }
  }

  public async selectView(folder: IContabilidadeDigitalGenericViewerFolder): Promise<void> {
    if (folder.promise) {
      return folder.promise;
    }
    this.loadingView = true;
    folder.promise = this._refreshFolderItem(folder).then(() => undefined);
    await folder.promise;
    folder.promise = undefined;
    this.selectFolder(folder);
    this.loadingView = false;
    this.selectedView = folder;
    this.evtSelectedViewChanged.emit({root: this.root, folder: this.selectedView});
    return Promise.resolve();
  }

  public setSelectedView(folderIDOrItem: string | IContabilidadeDigitalGenericViewerFolder): Promise<unknown> {
    const folder: IContabilidadeDigitalGenericViewerFolder = folderIDOrItem ? this._getFolderItem(folderIDOrItem) : this.root;
    return this.selectView(folder);
  }

  protected _evaluateRoot(root: IContabilidadeDigitalGenericViewerFolder): void {
    super._evaluateRoot(root);
    if (!this.selectedView) {
      if (this.selectedFolder) {
        this.selectedView = this.selectedFolder;
        this._evaluateCurrentFolderPath();
      } else {
        this.selectedView = root;
        this.selectFolder(this.selectedView);
      }
    } else {
      this.selectFolder(this.selectedView);
    }
  }

  protected _getFoldersPath(): Array<IContabilidadeDigitalGenericViewerFolderPath> {
    const root: IContabilidadeDigitalGenericViewerFolderPath = {
      folderID: undefined,
      name: this._plTranslateService.translate('arquivodigital.genericviewer.text.root')
    };
    if (!this.selectedFolder || this.selectedFolder === this.root) {
      return [root];
    }
    const foldersPath: Array<IContabilidadeDigitalGenericViewerFolderPath> = super._getFoldersPath();
    foldersPath.unshift(root);
    return foldersPath;
  }
}
