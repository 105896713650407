import {PlTranslateService} from 'pl-comps-angular';
import moment, {Moment} from 'moment';
import {TDate} from '../../../../common/dates';
import {IJsonGestaoComunicacoesMensagem} from './jsonGestaoComunicacoesMensagem.module.interface';
import {formatFileBytes} from '../../../../common/utils/file.utils';
import {IApiQueryParams} from '../../../services/api/api.service.interface';
import {IJsonGestaoComunicacoesConfigUtilizador, IJsonGestaoComunicacoesTopico} from './jsonGestaoComunicacoesTopico.module.interface';

export enum EGestaoComunicacoesFileType {
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  XLS = 'xls',
  XLSX = 'xlsx',
  PPT = 'ppt',
  PPTX = 'pptx',
  TXT = 'txt',
  CSV = 'csv',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png'
}

export interface IGestaoComunicacoesEstadoDados {
  estadoCor: string;
  estadoNome: string;
}

export interface IGestaoComunicacoesMensagemGroup {
  groupHeader: string;
  date: TDate;
  read: boolean;
  messages: Array<IJsonGestaoComunicacoesMensagem>;
}

export interface IGestaoComunicacoesFileData {
  name: string;
  extension: EGestaoComunicacoesFileType;
  size: string;
}

export interface IGestaoComunicacoesQueryParamAdditionalFields extends IApiQueryParams {
  additionalfields?: string;
}

export interface IGestaoComunicacoesConfigUtilizador extends IJsonGestaoComunicacoesConfigUtilizador {
  id?: number;
  inherited?: boolean;
}

export const GESTAO_COMUNICACAO_COLOR_DEFAULT = '#62A8EA';
export const GESTAO_COMUNICACAO_COLOR_PERCENT = 85;
export const MODULE_NAME_GESTAO_COMUNICACOES_CONTABILISTA = 'gestaocomunicacoes';
export const MODULE_NAME_GESTAO_COMUNICACOES_CLIENTE = 'gestaocomunicacoescliente';

/* eslint-disable */
export function lightenColor(hex: string, percent: number): string {
  const {r, g, b} = parseHexColor(hex);
  const newR: number = adjustColorChannel(r, percent, true);
  const newG: number = adjustColorChannel(g, percent, true);
  const newB: number = adjustColorChannel(b, percent, true);
  return `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
}

export function darkenColor(hex: string, percent: number): string {
  const {r, g, b} = parseHexColor(hex);
  const newR: number = adjustColorChannel(r, percent, false);
  const newG: number = adjustColorChannel(g, percent, false);
  const newB: number = adjustColorChannel(b, percent, false);
  return `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
}

export function darkColor(hex: string): boolean {
  const {r, g, b} = parseHexColor(hex);
  const luminance: number = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  return luminance < 128;
}

function parseHexColor(hex: string): {r: number; g: number; b: number} {
  hex = hex.replace(/^#/, '');
  return {
    r: parseInt(hex.substring(0, 2), 16),
    g: parseInt(hex.substring(2, 4), 16),
    b: parseInt(hex.substring(4, 6), 16)
  };
}

function adjustColorChannel(value: number, percent: number, lighten: boolean): number {
  const adjustment: number = ((255 - value) * percent) / 100;
  return lighten ? Math.min(255, Math.floor(value + adjustment)) : Math.max(0, Math.floor(value - adjustment));
}

export function convertFileNameToFileData(fileName: string, fileSize: number, mimeType: string): IGestaoComunicacoesFileData {
  const fileType: EGestaoComunicacoesFileType = getFileTypeFromMimeType(mimeType);
  const lastDotIndex: number = fileName.lastIndexOf('.');
  if (lastDotIndex === -1) {
    return {name: fileName, extension: fileType, size: formatFileBytes(fileSize)};
  }
  const name: string = fileName.substring(0, lastDotIndex);
  return {name: name, extension: fileType, size: formatFileBytes(fileSize)};
}

export function getFileTypeFromMimeType(mimeType: string): EGestaoComunicacoesFileType | undefined {
  const mimeTypeMap: {[key: string]: EGestaoComunicacoesFileType} = {
    'application/pdf': EGestaoComunicacoesFileType.PDF,
    'application/msword': EGestaoComunicacoesFileType.DOC,
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': EGestaoComunicacoesFileType.DOCX,
    'application/vnd.ms-excel': EGestaoComunicacoesFileType.XLS,
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': EGestaoComunicacoesFileType.XLSX,
    'application/vnd.ms-powerpoint': EGestaoComunicacoesFileType.PPT,
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': EGestaoComunicacoesFileType.PPTX,
    'text/plain': EGestaoComunicacoesFileType.TXT,
    'text/csv': EGestaoComunicacoesFileType.CSV,
    'image/jpeg': EGestaoComunicacoesFileType.JPG,
    'image/png': EGestaoComunicacoesFileType.PNG
  };

  return mimeTypeMap[mimeType];
}

/* eslint-enable */

export function generateGestaoComunicacoesDate(date: TDate, returnTime: boolean, plTranslateService: PlTranslateService): string {
  const MAX_NUMBER_YEARS = 20;
  if (date) {
    const timestampDate: Moment = moment(date);
    const today: Moment = moment().startOf('day');
    const yesterday: Moment = moment().subtract(1, 'days').startOf('day');
    const twentyYearsAgo: Moment = moment().subtract(MAX_NUMBER_YEARS, 'years').startOf('day');

    if (today.isSame(timestampDate, 'day')) {
      if (returnTime) {
        return timestampDate.format('HH:mm');
      }
      return plTranslateService.translate('global.text.today');
    } else if (yesterday.isSame(timestampDate, 'day')) {
      return plTranslateService.translate('gestaoComunicacoes.messageSidebarSelector.ontem');
    } else if (!timestampDate.isBefore(twentyYearsAgo)) {
      return timestampDate.format('DD-MM-YYYY');
    }
  }
  return '';
}

export function generateUtilizadorShow(topico: IJsonGestaoComunicacoesTopico, contabilistaView: boolean): string {
  if ((contabilistaView && topico.isContabilista) || (!contabilistaView && !topico.isContabilista)) {
    return topico.utilizadoresPara[0]?.nome;
  }
  return topico.utilizadorDe.nome;
}
