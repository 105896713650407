import {EMascaraAnaliticaListNameType} from './mascaraAnalitica.entity.interface';

export enum EMascaraAnaliticaTipo {
  FATURACAO,
  SALARIOS,
  IMOBILIZADO
}

export interface IJsonManal {
  manalID: number;
  mascara: string;
  descricao: string;
  tipo: EMascaraAnaliticaTipo;
  nCaracteresGeral: number;
  isDefault: boolean;
  subTotaisDaGeral: boolean;
  indicesSubTotais: string;
  contaContrapartida: string;
  nomeContaContrapartida: string;
}

export interface IJsonManalPocNomes {
  manalPocNomesID: number;
  idManal: number;
  indice: number;
  tipoCampo: number;
  nomeEstatico: string | EMascaraAnaliticaListNameType;
}
