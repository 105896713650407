// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ROLE {
  ANY = 'ANY',
  REGEX_ROLE = '#RegexRole',
  ADMIN = 'ADMIN',
  ADMINBASIC = 'ADMINBASIC',
  API = 'API',
  ATIVOS = 'ATIVOS',
  CLIENTCONNECT = 'CLIENTCONNECT',
  COLABORADOR = 'COLABORADOR',
  CONTABILIDADE = 'CONTABILIDADE',
  CONTABILIDADEPREMIUM = 'CONTABILIDADEPREMIUM',
  ERP = 'ERP',
  ERPADVANCED = 'ERPADVANCED',
  FINANCEIRO = 'FINANCEIRO',
  GESTOREMENTAS = 'GESTOREMENTAS',
  GESTORRH = 'GESTORRH',
  GESTORSERVICO = 'GESTORSERVICO',
  PARTNERMILLENNIUM = 'PARTNERMILLENNIUM',
  PCA = 'PCA',
  RH = 'RH'
}

export enum ELicenseRole {
  ERP = 'ERP',
  PCA = 'PCA',
  COLABORADOR = 'COLABORADOR',
  CONTABILIDADE = 'CONTABILIDADE',
  RH = 'RH',
  ATIVOS = 'ATIVOS'
}

export type TRelationsLicenseRoleToRoles = {
  [licenseRole in ELicenseRole]: Array<ROLE>;
};

export type TRelationsRoleToLicenseRole = {
  [role in ROLE]: ELicenseRole;
};

/* eslint-disable @typescript-eslint/naming-convention */

export const RELATIONS_LICENSE_ROLE_TO_ROLES: TRelationsLicenseRoleToRoles = {
  ERP: [ROLE.ERP],
  PCA: [ROLE.PCA],
  COLABORADOR: [ROLE.COLABORADOR, ROLE.GESTOREMENTAS, ROLE.GESTORRH, ROLE.GESTORSERVICO],
  CONTABILIDADE: [ROLE.CONTABILIDADE],
  RH: [ROLE.RH],
  ATIVOS: [ROLE.ATIVOS]
};

/* eslint-enable @typescript-eslint/naming-convention */

export const RELATIONS_ROLE_TO_LICENSE_ROLE: TRelationsRoleToLicenseRole = {
  [ROLE.ANY]: undefined,
  [ROLE.REGEX_ROLE]: undefined,
  [ROLE.ADMIN]: undefined,
  [ROLE.ADMINBASIC]: undefined,
  [ROLE.API]: undefined,
  [ROLE.ATIVOS]: ELicenseRole.ATIVOS,
  [ROLE.CLIENTCONNECT]: undefined,
  [ROLE.COLABORADOR]: ELicenseRole.COLABORADOR,
  [ROLE.CONTABILIDADE]: ELicenseRole.CONTABILIDADE,
  [ROLE.CONTABILIDADEPREMIUM]: undefined,
  [ROLE.ERP]: ELicenseRole.ERP,
  [ROLE.ERPADVANCED]: undefined,
  [ROLE.FINANCEIRO]: undefined,
  [ROLE.GESTOREMENTAS]: ELicenseRole.COLABORADOR,
  [ROLE.GESTORRH]: ELicenseRole.COLABORADOR,
  [ROLE.GESTORSERVICO]: ELicenseRole.COLABORADOR,
  [ROLE.PARTNERMILLENNIUM]: undefined,
  [ROLE.PCA]: ELicenseRole.PCA,
  [ROLE.RH]: ELicenseRole.RH
};
