import {Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges} from '@angular/core';
import {COMMA_SEPARATOR, TCGCExpression} from '../common/interface';
import {getNodePath, isFunction, parseExpressionToString, timeout, toInteger} from '../common/utilities/utilities';
import type {IPlTableNavigationProperties, IPlTableNavigationSelectors, TPlTableNavigationCheckEvent} from './table.navigation.directive.interface';
import {KEYCODES} from '../common/constants';
import {Logger} from '../logger/logger';

const focusableQuery = 'input,select,textarea,input:disabled,select:disabled,textarea:disabled';

@Directive({
  selector: '[plTableNavigation]',
  standalone: false
})
export class PlTableNavigationDirective implements OnChanges {
  @Input() public plTableNavigation: void | '' | Promise<unknown>;
  @Input() public plTableNavigationProperties: IPlTableNavigationProperties;
  @Input() public async: boolean;
  @Input() public onLastCell: TPlTableNavigationCheckEvent;
  @Input() public onCheckLine: TPlTableNavigationCheckEvent;
  @Input() public onRemoveLine: TPlTableNavigationCheckEvent;
  @Input() public stepOver: TCGCExpression;
  @Input() public selectorOverrides: Partial<IPlTableNavigationSelectors>;

  private readonly _element: JQuery;
  private readonly _defaultOptions: IPlTableNavigationProperties;
  private readonly _defaultSelectors: IPlTableNavigationSelectors;
  private _elementsToSkip: Array<string>;
  private _options: IPlTableNavigationProperties;
  private _selectors: IPlTableNavigationSelectors;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _logger: Logger
  ) {
    this._element = $(this._elementRef.nativeElement);
    this._defaultOptions = Object.freeze<IPlTableNavigationProperties>({
      onEnterAndMissingNextDoBlur: false,
      onBlurAndMissingNextDoFocus: false
    });
    this._defaultSelectors = {
      tr: 'tr',
      td: 'td'
    };
    this._elementsToSkip = [];
    this._options = {...this._defaultOptions};
    this._selectors = {...this._defaultSelectors};
  }

  public ngOnChanges({plTableNavigationProperties, stepOver, selectorOverrides}: SimpleChanges): void {
    if (plTableNavigationProperties) {
      this._options = {...this._defaultOptions, ...this._options, ...plTableNavigationProperties.currentValue};
    }
    if (stepOver) {
      this._elementsToSkip = stepOver.currentValue ? parseExpressionToString(stepOver.currentValue).split(COMMA_SEPARATOR) || [] : [];
    }
    if (selectorOverrides) {
      this._selectors = {...this._defaultSelectors, ...selectorOverrides.currentValue};
    }
  }

  @HostListener('keydown', ['$event'])
  public eventHandler(event: KeyboardEvent): void {
    if (event.altKey) {
      return;
    }
    event.stopPropagation();
    const target = getNodePath(<HTMLInputElement>event.target);
    Promise.resolve(this.plTableNavigation)
      .then(async () => {
        if (this.async) {
          await timeout();
        }
        this._check(target, event);
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
        this.plTableNavigation = undefined;
      });
  }

  private _check(target: string, event: KeyboardEvent): void {
    const $table: JQuery = this._element;
    const $active: JQuery = $(target);
    const position: number = toInteger(String($active.closest(this._selectors.td).index())) + 1;
    let $next: JQuery;
    switch (event.key) {
      case KEYCODES.ESC:
      case KEYCODES.LEFT:
        $next = this._findHorizontal($active, 'prev');
        break;
      case KEYCODES.UP:
        $next = this._findVertical($active, position, 'prev');
        break;
      case KEYCODES.RIGHT:
        $next = this._findHorizontal($active, 'next');
        break;
      case KEYCODES.DOWN:
        $next = this._findVertical($active, position, 'next');
        break;
      case KEYCODES.ENTER:
        $next = this._findHorizontal($active, 'next', true);
        break;
    }

    if ($next?.length && !event.defaultPrevented) {
      event.stopPropagation();
    }

    this._checkLine($table, $active, $next, event).then((wasLineFocused: void | boolean) => {
      if (event.defaultPrevented) {
        return;
      }
      if ($next?.length) {
        if (!wasLineFocused) {
          $next.trigger('focus');
          setTimeout(() => {
            $next.trigger('select');
          });
        }
      } else if (this._options.onEnterAndMissingNextDoBlur && event.key === KEYCODES.ENTER) {
        const inputTarget: HTMLInputElement = <HTMLInputElement>event.target;
        if (isFunction(inputTarget.blur)) {
          inputTarget.blur();
          if (this._options.onBlurAndMissingNextDoFocus && isFunction(inputTarget.focus)) {
            inputTarget.focus();
            if (isFunction(inputTarget.select)) {
              setTimeout(() => {
                inputTarget.select();
              });
            }
          }
        }
      }
    });
  }

  private _shouldStepOver(element: JQuery): boolean {
    if (this._elementsToSkip.includes(element.attr('name'))) {
      for (const elementToSkip of this._elementsToSkip) {
        const $input = element.closest(this._selectors.tr).find(`input[name="${elementToSkip}"]`);
        if ($input.length && $input.val().toString().trim() !== '') {
          return true;
        }
      }
    }
    return false;
  }

  private _findHorizontal($active: JQuery, type: 'prev' | 'next', doSkip?: boolean): JQuery {
    let element = $active.closest(this._selectors.td)[type]().find(focusableQuery);
    // eslint-disable-next-line no-unmodified-loop-condition
    while (element.is(':disabled') || !element.is(':visible') || (doSkip && this._shouldStepOver(element))) {
      element = element.closest(this._selectors.td)[type]().find(focusableQuery);
      if (((element.is(':enabled') && element.is(':visible')) || !element.length) && (!doSkip || !this._shouldStepOver(element))) {
        break;
      }
    }
    return element;
  }

  private _findVertical($active: JQuery, position: number, type: 'prev' | 'next', doSkip?: boolean): JQuery {
    let element = $active.closest(this._selectors.tr)[type]().find(`${this._selectors.td}:nth-child(${position})`).find(focusableQuery);
    // eslint-disable-next-line no-unmodified-loop-condition
    while (element.is(':disabled') || !element.is(':visible') || (doSkip && this._shouldStepOver(element))) {
      element = element.closest(this._selectors.tr)[type]().find(`${this._selectors.td}:nth-child(${position})`).find(focusableQuery);
      if (((element.is(':enabled') && element.is(':visible')) || !element.length) && (!doSkip || !this._shouldStepOver(element))) {
        break;
      }
    }
    return element;
  }

  private _findNotSkipped(element: JQuery): JQuery {
    let name = element.attr('name');
    while (this._elementsToSkip.includes(name) || element.is(':disabled') || !element.is(':visible')) {
      element = element.closest(this._selectors.td).prev(this._selectors.td).find('input');
      name = element.attr('name');
      if (!element.length) {
        break;
      }
    }
    return element;
  }

  /**
   * @return This method and the ``onCheckLine()`` method should return
   *    a promise with a boolean value regarding whether a ``$().trigger('focus')``
   *    method was called. This is to ensure the called focus isn't going to be overruled
   *    by the end of execution of ``eventKeydown()`` method.
   */
  private _checkLine($table: JQuery, $active: JQuery, $next: JQuery, event: KeyboardEvent): Promise<void | boolean> {
    return new Promise<void | boolean>((resolve) => {
      if (isFunction(this.onCheckLine)) {
        event.stopPropagation();
        const next = $next?.length ? getNodePath($next.get(0)) : undefined;
        Promise.resolve(this.onCheckLine(event, next)).then((checkLineValue: void | boolean) => {
          this._checkAddNew(checkLineValue, $table, $active, $next, event).then((checkAddNewValue: void | boolean) => {
            resolve(checkAddNewValue || checkLineValue);
          });
        });
      } else {
        this._checkAddNew(false, $table, $active, $next, event).then((value: void | boolean) => {
          resolve(value);
        });
      }
    });
  }

  private _checkAddNew(checkLineValue: void | boolean, $table: JQuery, $active: JQuery, $next: JQuery, event: KeyboardEvent): Promise<void | boolean> {
    return new Promise<void | boolean>((resolve) => {
      let valid = false;

      // On key down or enter
      if (event.key === KEYCODES.DOWN || event.key === KEYCODES.ENTER) {
        let $lastRow = $table.find(this._selectors.tr).last();

        // If last line is disabled we must go back until an enabled one is found
        if (this._isLineDisabled($lastRow)) {
          for (;;) {
            $lastRow = $lastRow.prev(this._selectors.tr);
            if (!this._isLineDisabled($lastRow) || !$lastRow.length) {
              break;
            }
          }
        }

        // Find last input not disabled or skipped
        const $lastElement = $lastRow.find('input').last();
        const $lastNotSkippedElement = this._findNotSkipped($lastRow.find('input').last());

        /* Call onLastCell in case one of the two following scenarios are true:
         *   - ENTER key was pressed and the currently active input is the last horizontal one;
         *   - DOWN key was pressed and the currently active input is part of the last enabled row.
         * NOTE: ``event.stopPropagation()`` must be called in order to disable plFormNavigate behavior
         */
        if ((event.key === KEYCODES.ENTER && ($lastElement.is($active) || $lastNotSkippedElement.is($active))) || (event.key === KEYCODES.DOWN && $lastRow.is($active.closest(this._selectors.tr)))) {
          event.stopPropagation();

          // Execute add new line
          if (isFunction(this.onLastCell)) {
            valid = true;
            const next = $next?.length ? getNodePath($next.get(0)) : undefined;
            Promise.resolve(this.onLastCell(event, next)).then((value) => {
              if (!value) {
                event.preventDefault();
                event.stopPropagation();
                resolve(true);
                // To focus newly created element, use plAutofocus directive
              } else {
                resolve(value || checkLineValue);
              }
            });
          }
        } else if (event.key === KEYCODES.ENTER && !$next.length) {
          let $row = $active.closest(this._selectors.tr);
          for (;;) {
            $row = $row.next(this._selectors.tr);
            if (!this._isLineDisabled($row) || !$row.length) {
              break;
            }
          }
          if ($row.length) {
            const input = $row.find(this._selectors.td).first().find('input');
            input.trigger('focus');
            setTimeout(() => {
              input.trigger('select');
            });
            valid = true;
            resolve(checkLineValue || true);
          }
        }
      }
      // On key up calls onRemoveLine
      else if (event.key === KEYCODES.UP) {
        if (isFunction(this.onRemoveLine)) {
          const next = $next?.length ? getNodePath($next.get(0)) : undefined;
          Promise.resolve(this.onRemoveLine(event, next)).then(
            () => {
              resolve(false);
            },
            () => {
              resolve(false);
            }
          );
        }
      }
      if (!valid) {
        resolve(checkLineValue || false);
      }
    });
  }

  private _isLineDisabled($row: JQuery): boolean {
    const inputs = $row.find('input');
    return inputs.length === $row.find('input:disabled').length;
  }
}
