export enum ETipoErroLoad {
  Erro,
  Ok,
  Warning,
  Info
}

export enum ETipoAcaoLoad {
  None,
  GeraNovo,
  ReutilizaIdentico,
  Mantem
}

export enum ETipoErroCopia {
  None,
  Abono,
  Desconto,
  GrAbono,
  GrDesconto,
  CategoriaAbono,
  CategoriaDesconto,
  JaExiste,
  ErroCruzamentos,
  CopiaSeguranca
}

export interface IJsonRHCopiaAbonoDesconto {
  copiaAbDes: IJsonCopiaAbDes;
  relatorioLoad: IJsonRelatorioLoad;
}

export interface IJsonCopiaAbDes {
  empresa: string;
  nomeEmpresa: string;
  abonoDesconto: string;
  nomeAbonoDesconto: string;
  selecionado: boolean;
  novo: boolean;
  alteracao: boolean;
  temCruzamento: boolean;
  categoria: string;
  grupo: string;
  descricaoErro: string;
  permiteCopia: ETipoErroLoad;
  mensagem: string;
  tipoErro: ETipoErroCopia;
  copiouABDES: boolean;
  copiouGrupo: boolean;
  copiouCruzamento: boolean;
  codAbdesDestino: string;
  nomeAbdesDestino: string;
  copiouIntegracaoSalarios: boolean;
  geraNovoGrupoDestino: boolean;
}

export interface IJsonRelatorioLoad {
  mensagemHtml: string;
  codEmpresaDestino: string;
  nomeEmpresaDestino: string;
  codEmpresaOrigem: string;
  nomeEmpresaOrigem: string;
  grupoSemelhanteDestino: string;
  abdesCopia: string;
  geraNovoGrupoDestino: boolean;
  codGrupoSemelhanteDestino: number;
  tipoGrupoABDES: number;
  situacaoCategoria: ETipoAcaoLoad;
}

export interface IJsonRelatorioLoadAux {
  geraNovoGrupoDestino: boolean;
  codGrupoSemelhanteDestino: number;
}
