import {IJsonDocfa} from '../../entities/docfas/jsonDocFa.entity.interface';
import {IJsonClifo} from '../../entities/clifos/jsonClifo.entity.interface';
import {deepFreeze} from 'pl-comps-angular';
import {TDate} from '../../../common/dates';

export enum EDebDiretosSEPAClienteTipoNAutorizacao {
  Manual,
  Automatico
}

export enum EDebDiretosSEPATipoDebitoDirecto {
  None,
  B2B,
  CORE
}

export interface IJsonDebDiretosSEPAMeiosPagamento {
  estado: EDebDiretosSEPAEstadoItem;
  estadoSTR: string;
  codMeioPagamento: number;
  iBAN: string;
  idDebitoDireto: string;
  nDiario: number;
  nDescritivo: number;
  nContaBanco: string;
  nomeMeioPagamento: string;
  nomePOC: string;
  nomeDiario: string;
  nomeDescritivo: string;
  iBANMasked: string;
}

export interface IJsonDebDiretosSEPATipoDocumento extends IJsonDocfa {
  estado: string;
}

export interface IJsonDebDiretosSEPAClienteContrato {
  clifoDDContratosID: string;
  contratoAsSTR: string;
  contratoDescricao: string;
  refCtrCAB: number;
}

export interface IJsonDebDiretosSEPACliente extends Partial<IJsonClifo> {
  emailDD: string;
  estado: EDebDiretosSEPAEstadoItem;
  estadoSTR: string;
  temAutorizacaoDebitosDiretos: boolean;
  tipoAsSTR: string;
  codMeioPagamento: number;
  nAutorizacao: string;
  dataInicio: TDate;
  dataFim: TDate;
  contratosAsSTR: string;
  firstEnviado: boolean;
  titularConta: string;
  ibanDD: string;
  tipoNAutorizacao: EDebDiretosSEPAClienteTipoNAutorizacao;
  contratos: Array<number>;
}

export interface IJsonDebDiretosSEPAConfiguracaoDefaults {
  nDiario: number;
  nDescritivo: number;
  nomeDiario: string;
  nomeDescritivo: string;
}

export interface IJsonDebDiretosSEPAConfiguracao {
  tipoDataLiquidacao: EDebDiretosSEPADataLiquidacao;
  dataLiquidacaoNDiasFRST: number;
  dataLiquidacaoNDiasRCUR: number;
  nContaIntermedia: string;
  nContaIntermediaNome: string;
  codCalendario?: number;
  codCalendarioNome?: string;
  listaContasCredoras: Array<IJsonDebDiretosSEPAMeiosPagamento>;
  listaTiposDocumento: Array<IJsonDebDiretosSEPATipoDocumento>;
  listaTiposDocumentoNaoSelecionados: Array<IJsonDebDiretosSEPATipoDocumento>;
  listaClientes?: Array<IJsonDebDiretosSEPACliente>;
  chavesAutorizacao?: Array<IJsonDebDiretosSEPAChaveAutorizacao>;
  defaults?: IJsonDebDiretosSEPAConfiguracaoDefaults;
}

export interface IJsonDebDiretosSEPAAcesso {
  geral: boolean;
  bancos: boolean;
  clientes: boolean;
  tiposDocumento: boolean;
}

export interface IDebDiretosSEPAAcessos extends IJsonDebDiretosSEPAAcesso {
  acessoContabilidade: boolean;
}

export enum EDebDiretosSEPAEstadoItem {
  None,
  New,
  Changed,
  Deleted
}

export enum EDebDiretosSEPADataLiquidacao {
  DataVencimento,
  NumeroDias
}

export enum EDebDiretosSEPAConfigTabs {
  ConfigGerais,
  ContasCredoras,
  TiposDocumento,
  Clientes
}

export enum EDebDiretosSEPATipoChave {
  Texto,
  Conta,
  ClasseContrato,
  TipoContrato,
  NContrato,
  IBANCredor,
  IBANCliente,
  Ano,
  Mes,
  Dia,
  Hora,
  Minutos,
  Sequencial
}

export interface IDebDiretosSEPAAutorizacaoTipoChaveProperty {
  value: EDebDiretosSEPATipoChave;
  caption: string;
  maxDigits: number;
  defaultText: string;
  defaultDigits: number;
}

export const DEB_DIRETOS_AUTORIZACAO_TIPO_ARRAY: ReadonlyArray<IDebDiretosSEPAAutorizacaoTipoChaveProperty> = deepFreeze([
  {value: EDebDiretosSEPATipoChave.Texto, caption: 'Texto', defaultText: '', maxDigits: 24, defaultDigits: 1},
  {value: EDebDiretosSEPATipoChave.Conta, caption: 'Conta', defaultText: 'NCONTA', maxDigits: 20, defaultDigits: 10},
  {value: EDebDiretosSEPATipoChave.ClasseContrato, caption: 'Classe Contrato', defaultText: 'NCLASSECTR', maxDigits: 2, defaultDigits: 2},
  {value: EDebDiretosSEPATipoChave.TipoContrato, caption: 'Tipo Contrato', defaultText: 'TIPO', maxDigits: 2, defaultDigits: 2},
  {value: EDebDiretosSEPATipoChave.NContrato, caption: 'Nº Contrato', defaultText: 'NCONTRATO', maxDigits: 2, defaultDigits: 2},
  {value: EDebDiretosSEPATipoChave.IBANCredor, caption: 'IBAN Credor', defaultText: 'IBANCREDOR', maxDigits: 10, defaultDigits: 10},
  {value: EDebDiretosSEPATipoChave.IBANCliente, caption: 'IBAN Cliente', defaultText: 'IBANCLIENTE', maxDigits: 10, defaultDigits: 10},
  {value: EDebDiretosSEPATipoChave.IBANCliente, caption: 'IBAN Cliente', defaultText: 'IBANCLIENTE', maxDigits: 10, defaultDigits: 10},
  {value: EDebDiretosSEPATipoChave.Ano, caption: 'Ano', defaultText: 'AAAA', maxDigits: 4, defaultDigits: 4},
  {value: EDebDiretosSEPATipoChave.Mes, caption: 'Mês', defaultText: 'MM', maxDigits: 2, defaultDigits: 2},
  {value: EDebDiretosSEPATipoChave.Dia, caption: 'Dia', defaultText: 'DD', maxDigits: 2, defaultDigits: 2},
  {value: EDebDiretosSEPATipoChave.Hora, caption: 'Hora', defaultText: 'HH', maxDigits: 2, defaultDigits: 2},
  {value: EDebDiretosSEPATipoChave.Minutos, caption: 'Minutos', defaultText: 'MM', maxDigits: 2, defaultDigits: 2},
  {value: EDebDiretosSEPATipoChave.Sequencial, caption: 'Sequencial', defaultText: 'NNN', maxDigits: 10, defaultDigits: 3}
]);

export interface IJsonDebDiretosSEPAChaveAutorizacao {
  tipo: EDebDiretosSEPATipoChave;
  valor: string;
  nDigitos: number;
  tipoStr: string;
}

export enum EDebDiretosTitularClifo {
  NOMECLIFO,
  OUTRO
}

export interface IJsonDebDiretosSEPARecordLog {
  stampInsert: TDate;
  nomeUtilizador: string;
  tipoAsSTR: string;
  observacao: string;
}

export interface IJsonDebDiretosSEPARecord {
  nDocAsString: string;
  dataDoc: TDate;
  docContabilidadeAsSTR: string;
  docContabilidadeImportAsSTR: string;
  nConta: string;
  nContaNome: string;
  valorDD: number;
  liquidado: boolean;
  dataLiquidacao: TDate;
  liquidadoComErro: boolean;
  faccbId: number;
  enviadoEmail: boolean;
  xmlGerado: boolean;
  extPocCabID: string;
  loteFicheiro: string;
  lotePagamento: string;
  dataEmissaoFicheiro: TDate;
  idMontante: string;
  tipoDD: number;
  grupoDocfa: number;
  importPeriodo: string;
  nDocAsStringSAFT: string;
  nReciboSAFTAsSTR: string;
}

export interface IJsonDebDiretosSEPAErroLiquidacaoRecord {
  nDocAsStringSAFT: string;
  dataDoc: TDate;
  nConta: string;
  nContaNome: string;
  valorDD: number;
  enviadoEmail: boolean;
  nLoteFicheiro: string;
  faccbId: number;
  erro: string;
}

export enum EDebDiretosSEPAFicheiroEmitidosType {
  PorLiquidar,
  Liquidado
}

export enum EDebDiretosSEPAOpenType {
  FATURACAO,
  RECIBO_LIQUIDACAO,
  CLIENTE
}

export interface IJsonDebDiretosSEPAEmissaoListaAIntervirItem {
  nDocFa: number;
  nNumer: number;
  nDocumento: number;
  dataDoc: TDate;
  dataVencimento: TDate;
  nConta: string;
  nContaNome: string;
  valorEmAberto: number;
  enviadoEmail: boolean;
  xmlGerado: boolean;
  nomeMeioPagamento: string;
  ibanMasked: string;
  nContaBancoMasked: string;
  nomeTitularIBAN: string;
  tipoFicheiro: string;
  erro: boolean;
  erroAvisoDescricao: string;
  aviso: boolean;
  faccbId: number;
  nDocAsStringSAFT: string;
  emailDD: string;
}

export interface IJsonDebDiretosSEPAEmissaoResumoProcessaItem {
  nConta: string;
  emailDD: string;
  nome: string;
  valor: number;
}

export interface IJsonDebDiretosSEPANotificarItem extends IJsonDebDiretosSEPAEmissaoResumoProcessaItem {
  enviadoEmail: boolean;
}

export interface IJsonDebDiretosSEPAEmissaoListaFicheirosGeradosItem {
  nLoteFicheiro: string;
  nLotePagamento: string;
  nRegistos: number;
  valor: number;
  dataLiquidacao: TDate;
  firstAsSTR: string;
  fileName: string;
}

export interface IDebDiretosSEPAEmissaoModel {
  vencimentoAte: TDate;
  dataLiquidacaoFRST: TDate;
  dataLiquidacaoRCUR: TDate;
}

export interface IJsonDebDiretosSEPAEmissaoPreload {
  dataLiquidacaoFRST: TDate;
  dataLiquidacaoRCUR: TDate;
}

export interface IJsonEmissaoGeraFicheirosXMLData {
  selList: Array<number>;
  dataLiquidacaoFRST: TDate;
  dataLiquidacaoRCUR: TDate;
}

export interface IJsonDebDiretosSEPAErrorsAndWarningItem {
  nomeCampo: string;
  tipoMsg: number;
  mensagemOriginal: string;
  mensagem: string;
  tipoMensagemStr: string;
}

export interface IJsonGerarFicheirosXMLResults {
  listaGerados: Array<IJsonDebDiretosSEPAEmissaoListaFicheirosGeradosItem>;
  listaErrosEAvisos: Array<IJsonDebDiretosSEPAErrorsAndWarningItem>;
}

export interface IDebDiretosSEPAIbanCredorItem {
  ibanDD: string;
  ibanMasked: string;
  codMeioPagamento: number;
}

export interface IDebDiretosSEPAImpressaoFilters {
  dataLiquidacaoDe: TDate;
  dataLiquidacaoAte: TDate;
}

export interface IJsonDebDiretosSEPAImportRecord {
  nDocAsString: string;
  docContabilidadeAsSTR: string;
  docContabilidadeImportAsSTR: string;
  importValor: number;
  dataDoc: TDate;
  totalDocumento: number;
  nConta: string;
  nomeConta: string;
  erro: boolean;
  erroAvisoDescricao: string;
}

export interface IJsonDebDiretosSEPAImportResumo {
  nRegistosTotal: number;
  nRegistosOKLiquidacao: number;
  nRegistosOKDevolucao: number;
  nRegistosErro: number;
  valorTotal: number;
  valorErro: number;
  valorOKLiquidacao: number;
  valorOKDevolucao: number;
  nErros: number;
  nErrosCG: number;
}

export interface IJsonDebDiretosSEPAImportResults {
  list: Array<IJsonDebDiretosSEPAImportRecord>;
  errorList: Array<IJsonDebDiretosSEPAImportRecord>;
  resumo: IJsonDebDiretosSEPAImportResumo;
  isReembolso: boolean;
  canImport: boolean;
}

export interface IJsonDebDiretosSEPAImportProcessRecord {
  nDocAsString: string;
  docContabilidadeAsSTR: string;
  docContabilidadeImportAsSTR: string;
  dataDoc: TDate;
  totalDocumento: number;
  importValorImputado: number;
  importValorEmAberto: number;
  nConta: string;
  nomeConta: string;
}

export interface IJsonDebDiretosSEPAImportProcessResumo {
  nRegistosLiquidados: number;
  nRegistosDevolvidos: number;
  nRegistosNaoLiquidado: number;
  nRegistosTotal: number;
  valoresImputado: number;
  valoresEmAberto: number;
  valoresDevolvido: number;
  valoresNaoLiquidado: number;
  valoresTotal: number;
}

export interface IJsonDebDiretosSEPAImportProcessResults {
  list: Array<IJsonDebDiretosSEPAImportProcessRecord>;
  resumo: IJsonDebDiretosSEPAImportProcessResumo;
}

export interface IJsonDebDiretosSEPAFicheirosEmitidosRecord {
  loteFicheiro: string;
  nDocumentos: number;
  valor: number;
  dataEmissao: TDate;
  dataLiquidacao: TDate;
  enviadoEmail: boolean;
}

export interface IJsonDebDiretosSEPAFicheirosEmitidosAllRecord extends IJsonDebDiretosSEPAFicheirosEmitidosRecord {
  uid?: string;
  liquidado: boolean;
}

export interface IJsonDebDiretosSEPAFicheirosEmitidosRecordUI extends IJsonDebDiretosSEPAFicheirosEmitidosRecord {
  ficheiroEmitidosType: EDebDiretosSEPAFicheiroEmitidosType;
  temDocsPorNotificar: boolean;
}
