import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {isEmpty, isObject, isString, Logger, PL_PDF_ERROR_MESSAGES_TO_SUPPRESS, PlPdfErrorHandler, PlPdfFetchService} from 'pl-comps-angular';
import {ApiService} from '../core/services/api/api.service';
import {CGExceptionService} from '../core/components/exceptions/exceptions.service';
import {ICGExceptionError} from '../core/components/exceptions/exceptions.service.interface';
import {isProduction, isTest} from './constants';
import {TServiceResponse} from '../core/services/api/api.service.interface';

@Injectable()
export class PdfFetchHandler extends PlPdfFetchService {
  constructor(private readonly _apiService: ApiService) {
    super();
  }

  public async fetchDocument(url: string): TServiceResponse<Uint8Array> {
    const response: HttpResponse<Uint8Array> = await this._apiService.get({
      url: url,
      withCredentials: !isProduction(),
      responseType: 'arraybuffer',
      reportExceptions: false
    });
    return response.clone({body: new Uint8Array(response.body)});
  }
}

@Injectable()
export class PdfErrorHandler extends PlPdfErrorHandler {
  constructor(
    private readonly _logger: Logger,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super();
  }

  public parseError(error: unknown | HttpErrorResponse): string {
    let message: string;
    if (error instanceof HttpErrorResponse) {
      const exception: ICGExceptionError = this._cgExceptionService.get(error);
      message = exception.message || error.message;
    } else if (isObject(error)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!isEmpty((<any>error).message)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message = (<any>error).message;
      }
    } else if (isString(error)) {
      message = error;
    }
    if (PL_PDF_ERROR_MESSAGES_TO_SUPPRESS.includes(message)) {
      message = undefined;
    }
    if (isTest()) {
      this._logger.error(message);
    }
    return message;
  }
}
