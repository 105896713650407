<div class="modal-header">
  <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'atFRV.configModalTitle'"></h4>
</div>

<div class="modal-body" [plPromise]="promise">
  <entity-autocomplete
    entity="predefinidoscontabilidade"
    attrName="predefinido"
    [model]="model"
    (selectedKeyChange)="onPreDefinidoChanged($event)"
    (evtSelectedDescriptionChanged)="model.preDefinidoStr = $event"
    [customActions]="customActionPreDefinido"
    [fieldsMap]="{preDefinidosID: 'preDefinidoId', descricao: 'preDefinidoStr'}">
  </entity-autocomplete>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-close" [disabled]="closeDisabled" [onClick]="close"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

  <pl-button klass="btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
</div>
