<div class="pl-nav-wizard" [ngClass]="definition.type">
  <div class="pl-nav-wizard-header">
    <div *ngIf="definition.vertical && definition.caption" class="pl-nav-wizard-caption">
      <h4 [plTranslate]="definition.caption"></h4>
    </div>

    <div class="pl-nav-wizard-steps">
      <ng-container *ngIf="!definition.vertical">
        <div class="pl-nav-wizard-progress">
          <div class="pl-nav-wizard-progress-line" [style.width.px]="separatorSize"></div>
        </div>

        <div
          *ngFor="let step of definition.items"
          class="pl-nav-wizard-step"
          [hidden]="!step.visible"
          [ngClass]="definition.type"
          [class.active]="step.stepId === activeId"
          [class.invalid]="!step.valid"
          [class.complete]="step.complete"
          [class.disabled]="(options.disableNavigation || step.disabled) && step.stepId !== activeId"
          [style.width.%]="itemSize"
          [attr.data-id]="step.stepId"
          (click)="setStep(step)">
          <div class="pl-nav-wizard-step-icon">
            <i [cgcIcon]="step.icon"></i>
          </div>
          <p class="pl-nav-wizard-step-content">
            <ng-container *ngTemplateOutlet="step.templateCaption?.templateRef || defaultTemplateCaption; context: {step: step, definition: definition}"></ng-container>
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="definition.vertical">
        <div
          *ngFor="let step of definition.items"
          class="pl-nav-wizard-step"
          [hidden]="!step.visible"
          [ngClass]="definition.type"
          [class.active]="step.stepId === activeId"
          [class.invalid]="!step.valid"
          [class.complete]="step.complete"
          [class.disabled]="(options.disableNavigation || step.disabled) && step.stepId !== activeId"
          (click)="setStep(step)">
          <div class="pl-nav-wizard-step-content">
            <ng-container *ngTemplateOutlet="step.templateCaption?.templateRef || defaultTemplateCaption; context: {step: step, definition: definition}"></ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="pl-nav-wizard-content" [plPromise]="promise" [plPromiseProperties]="promiseProperties">
    <div class="pl-nav-wizard-steps">
      <ng-container *ngFor="let step of definition.items">
        <div *ngIf="!destroyOnHide || step.stepId === activeId" class="pl-nav-wizard-step" [class.active]="step.stepId === activeId" [attr.data-item-id]="step.stepId">
          <ng-container *ngTemplateOutlet="step.templateContent?.templateRef"></ng-container>
        </div>
      </ng-container>
    </div>

    <div *ngIf="hideFooter !== true && options.hideFooter !== true && selected?.hideFooter !== true && selected?.options.hideFooter !== true" class="pl-nav-wizard-footer">
      <div class="pl-nav-wizard-footer-separator"></div>
      <pl-nav-wizard-actions [navWizardInstance]="self"></pl-nav-wizard-actions>
    </div>
  </div>
</div>

<ng-template #defaultTemplateCaption let-step="step" let-definition="definition">
  <!-- eslint-disable-next-line @angular-eslint/template/no-call-expression -->
  <div [innerHTML]="getItemContent(step)"></div>
</ng-template>
