import moment from 'moment';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {
  EEstadoAcrescimoEncFerias,
  ETipoAcrescimoEncFerias,
  IJsonAcrescimoEncargoComFeriasCab,
  IJsonAcrescimoEncargoComFeriasCabConfig,
  IJsonAcrescimoEncargoComFeriasConfig
} from '../../jsonAcrescimosEncargosFerias.module.interface';
import {AcrescimosEncargosFeriasService} from '../../acrescimosEncargosFerias.module.service';
import {ITipoAcrescimoEncFerias} from '../../acrescimosEncargosFerias.module.interface';
import {isNumber} from 'pl-comps-angular';

@Component({
  selector: 'modal-acrescimos-encargos-ferias-config',
  templateUrl: './acrescimosEncargosFerias.config.modal.component.html'
})
export class AcrescimosEncargosFeriasConfigModalComponent extends CGModalComponent<boolean> implements OnInit {
  @Input() public anoEncargo: number;
  @Input() public cabEncargos: IJsonAcrescimoEncargoComFeriasCab;
  @Input() public configEncargos: IJsonAcrescimoEncargoComFeriasConfig;
  @Input() public nEmpresa: string;

  public tipoAcrescimoEncFeriasSource: Array<ITipoAcrescimoEncFerias>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _acrescimosEncargosService: AcrescimosEncargosFeriasService
  ) {
    super(_injector);
    this.tipoAcrescimoEncFeriasSource = [
      {
        valueMetodo: ETipoAcrescimoEncFerias.Anual,
        nameMetodo: 'acrescimosencargosferias.tipoAcrescimoEncFeriasSource.anual'
      },
      {
        valueMetodo: ETipoAcrescimoEncFerias.Mensal,
        nameMetodo: 'acrescimosencargosferias.tipoAcrescimoEncFeriasSource.mensal'
      }
    ];

    this.nEmpresa = this.configurations.empresa.nEmpresa;
  }

  public ngOnInit(): void {
    if (!this.cabEncargos?.anoEncargo) {
      this.cabEncargos = {
        anoEncargo: isNumber(this.anoEncargo) ? this.anoEncargo : moment().year(),
        tipo: ETipoAcrescimoEncFerias.Anual,
        tipoDesc: 'Anual',
        descricao: '',
        percSeguradora: 1.5,
        estado: EEstadoAcrescimoEncFerias.None,
        estadoDesc: '',
        dataIntegracao: undefined
      };
    }
  }

  public close(): Promise<void> {
    this.disableClose();

    this.configEncargos.percActTrabalho = this.cabEncargos.percSeguradora;
    const cabConfig: IJsonAcrescimoEncargoComFeriasCabConfig = {cab: this.cabEncargos, config: this.configEncargos};

    return this._acrescimosEncargosService
      .editarCabecalhoConfigs(this.nEmpresa, cabConfig)
      .then(() => {
        super.close(true);
      })
      .finally(() => {
        this.enableClose();
      });
  }

  public setTipoAcrescimoEncFeriasSource(value: ITipoAcrescimoEncFerias): void {
    this.cabEncargos.tipo = value.valueMetodo;
    this.cabEncargos.tipoDesc = value.nameMetodo;
  }
}
