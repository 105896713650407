<div class="ativosmudartaxasdep-modal">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'ativosmudartaxasdep.title.mudartaxasdep'"></h5>
    </div>

    <div class="modal-body">
      <div class="card mb-4">
        <div class="card-header" [translate]="'ativosmudartaxasdep.title.depcontab'"></div>
        <div class="card-body">
          <div class="center-taxa">
            <div class="taxa-atual">
              <label class="me-2"><span [translate]="'ativosmudartaxasdep.fields.taxaatual'"></span>:</label>
              <span>{{ ativo.aquisicao.contabTaxaAmortiza }}</span>
            </div>
            <div class="taxa-nova">
              <label class="me-2" [translate]="'ativosmudartaxasdep.fields.taxanovo'"></label>
              <pl-edit attrName="taxadepcontab" type="number" [(model)]="contabTaxaAmortiza" [properties]="{validators: {required: {value: true}}}" plAutoFocus></pl-edit>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header" [translate]="'ativosmudartaxasdep.title.depfiscal'"></div>
        <div class="card-body">
          <div class="center-taxa">
            <div class="taxa-atual">
              <label class="me-2"><span [translate]="'ativosmudartaxasdep.fields.taxaatual'"></span>:</label>
              <span>{{ ativo.aquisicao.fiscalTaxaAmortiza }}</span>
            </div>
            <div class="taxa-nova">
              <label class="me-2" [translate]="'ativosmudartaxasdep.fields.taxanovo'"></label>
              <pl-edit attrName="taxadepfiscal" type="number" [(model)]="fiscalTaxaAmortiza" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn btn-success action-dismiss" [onClick]="close" data-focus><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>

      <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
    </div>
  </pl-form>
</div>
