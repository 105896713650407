import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {
  IJsonDashboardRhContratos,
  IJsonDashboardRhCustosFuncionarios,
  IJsonDashboardRhFuncionario,
  IJsonDashboardRhFuncionariosDepartamento,
  IJsonDashboardRhFuncionariosTipoContrato,
  IJsonDashboardRhTotal
} from './jsonDashboardRh.module.interface';
import {IFilterFuncionarios} from './dashboardRh.module.interface';

@Injectable({
  providedIn: 'root'
})
export class DashboardRhService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/dashboardrh`;
  }

  public getTotalEntradasUltimosMeses(meses: number, force: boolean): TServiceResponse<IJsonDashboardRhTotal> {
    return this._apiService.get<IJsonDashboardRhTotal>({
      url: `${this._path}/totalentradasultimosmeses`,
      params: {meses: meses, force: force}
    });
  }

  public getTotalSaidasUltimosMeses(meses: number, force: boolean): TServiceResponse<IJsonDashboardRhTotal> {
    return this._apiService.get<IJsonDashboardRhTotal>({
      url: `${this._path}/totalsaidasultimosmeses`,
      params: {meses: meses, force: force}
    });
  }

  public getTotalFuncionariosAtivos(filters: IFilterFuncionarios, force: boolean): TServiceResponse<Array<IJsonDashboardRhFuncionario>> {
    return this._apiService.get<Array<IJsonDashboardRhFuncionario>>({
      url: `${this._path}/funcionarios`,
      params: {
        tipodivisao: filters?.tipodivisao,
        sexo: filters?.sexo,
        idadede: filters?.idadede,
        idadeate: filters?.idadeate,
        force: force
      }
    });
  }

  public getFuncionariosPorDepartamento(force: boolean): TServiceResponse<Array<IJsonDashboardRhFuncionariosDepartamento>> {
    return this._apiService.get<Array<IJsonDashboardRhFuncionariosDepartamento>>({
      url: `${this._path}/funcionariospordepartamento`,
      params: {force: force}
    });
  }

  public getFuncionariosPorTipoContrato(force: boolean): TServiceResponse<Array<IJsonDashboardRhFuncionariosTipoContrato>> {
    return this._apiService.get<Array<IJsonDashboardRhFuncionariosTipoContrato>>({
      url: `${this._path}/funcionariosportipocontrato`,
      params: {force: force}
    });
  }

  public getContratosTerminar(dias: number, force: boolean): TServiceResponse<Array<IJsonDashboardRhContratos>> {
    return this._apiService.get<Array<IJsonDashboardRhContratos>>({
      url: `${this._path}/contratosaterminar`,
      params: {dias: dias, force: force}
    });
  }

  public getContratosRenovar(dias: number, force: boolean): TServiceResponse<Array<IJsonDashboardRhContratos>> {
    return this._apiService.get<Array<IJsonDashboardRhContratos>>({
      url: `${this._path}/contratosarenovar`,
      params: {dias: dias, force: force}
    });
  }

  public getCustosFuncionariosMensalmente(force: boolean): TServiceResponse<Array<IJsonDashboardRhCustosFuncionarios>> {
    return this._apiService.get<Array<IJsonDashboardRhCustosFuncionarios>>({
      url: `${this._path}/custoscomfuncionariosmensal`,
      params: {force: force}
    });
  }
}
