import {EmpresasComponent} from './components/empresas.module.component';
import {ENTITY_NAME_EMPRESAS_ERP, IEmpresaExtended} from '../../entities/empresas/empresas.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {MODULE_NAME_EMPRESAS} from './empresas.module.interface';
import {ROLE} from '../../services/role.const';
import {TUserSession} from '../../services/account/jsonUserApi.interface';
import {HttpResponse} from '@angular/common/http';

export const MODULE_EMPRESAS: IModuleDefinition = {
  name: MODULE_NAME_EMPRESAS,
  state: {
    url: '/empresas',
    component: EmpresasComponent,
    data: {
      roles: [ROLE.ADMINBASIC, ROLE.CONTABILIDADE],
      icon: 'fa-building',
      pageTitle: 'global.menu.empresas'
    },
    resolve: [
      {
        provide: 'empresa',
        deps: ['session', EntityServiceBuilder],
        useFactory: (session: TUserSession, entityServiceBuilder: EntityServiceBuilder): Promise<IEmpresaExtended> => {
          return entityServiceBuilder
            .build<IEmpresaExtended>(ENTITY_NAME_EMPRESAS_ERP)
            .get({id: session.erp.nEmpresa})
            .then((response: HttpResponse<IEmpresaExtended>) => response.body);
        }
      }
    ]
  }
};
