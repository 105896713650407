import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {HookMatchCriteria, StateDeclaration, StateObject, Transition, TransitionService} from '@uirouter/core';
import {ModuloComponent} from '../../../../components/module/module.component';
import {IPlToolbarMenuItem, PlAlertService} from 'pl-comps-angular';
import {AtivosAquisicaoContabModalComponent} from '../modal/ativosAquisicaoContab.modal.component';
import {AtivosAquisicaoContabService} from '../ativosAquisicaoContab.module.service';
import {AtivosNewComponent} from '../../../../entities/ativos/components/new/ativos.entity.new.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ENTITY_NAME_ATIVOS, IAtivosNewStateParams} from '../../../../entities/ativos/interfaces/ativos.entity.interface';
import {IEntityMaintenanceInstance, IEntityMaintenanceOnMaintenanceSave} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {IAquisicaoContabAdicionaDoc, IAtivoAquisicaoPesquisaContab, IAtivosAquisicaoContabParams, IContabAdicionaDocEListaAtivos} from '../ativosAquisicaoContab.module.interface';
import {
  IJsonAquisicaoBeforeNovoAtivo,
  IJsonAquisicaoContabAdicionaDoc,
  IJsonAquisicaoContabDoc,
  IJsonAquisicaoContabResAddDoc,
  IJsonAquisicaoContabResumo,
  IJsonAquisicaoContasEAtivo
} from '../jsonAtivosAquisicaoContab.module.interface';
import {IJsonAtivos} from '../../../../entities/ativos/interfaces/jsonAtivos.entity.interface';
import {isTest} from '../../../../../config/constants';
import {STATE_NAME_DISCONNECTED} from '../../../../states/account/disconnected/disconnected.state.interface';
import {STATE_NAME_LOGIN} from '../../../../states/account/login/login.state.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {IJsonDocContabilidade} from '../../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';

@Component({
  selector: 'module-ativos-aquisicao-contab',
  templateUrl: './ativosAquisicaoContab.module.component.html'
})
export class AtivosAquisicaoContabModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public empresaTemContabilidadeDigital: boolean;

  public readonly dataGridDefinitionContasAquisicao: IDevExpressDataGrid<IAquisicaoContabAdicionaDoc, string>;
  public readonly dataGridDefinitionAtivos: IDevExpressDataGrid<IJsonAtivos, string>;

  public cab: IJsonAquisicaoContabResumo;
  public beforeNovoAtivoconst: IJsonAquisicaoBeforeNovoAtivo;

  private readonly _entityMaintenanceInstanceAtivos: IEntityMaintenanceInstance<IJsonAtivos>;
  private readonly _ativosAquisicaoContabParams: IAtivosAquisicaoContabParams;
  private readonly _mapDocsContabilidade: Map<string, IJsonDocContabilidade>;
  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;

  private _deRegisterOnStartFn: Function;
  private _btnAddSearch: IPlToolbarMenuItem;
  private _btnSave: IPlToolbarMenuItem;
  private _dataGridInstanceContasAquisicao: dxDataGrid<IAquisicaoContabAdicionaDoc, string>;
  private _dataGridInstanceAtivos: dxDataGrid<IJsonAtivos, string>;
  private _listaDocumentos: Array<IJsonAquisicaoContabDoc>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _ativosAquisicaoContabService: AtivosAquisicaoContabService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _plAlertService: PlAlertService,
    private readonly _transitionService: TransitionService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._mapDocsContabilidade = new Map<string, IJsonDocContabilidade>();
    this.dataGridDefinitionContasAquisicao = {
      keyExpr: 'extPocCabID',
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'ativosaquisicaocontab.table.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'ativosaquisicaocontab.table.nome', width: 200},
        {dataField: 'valor', dataType: 'double', caption: 'ativosaquisicaocontab.table.valor'},
        {dataField: 'valorCalculado', dataType: 'double', caption: 'ativosaquisicaocontab.table.valorCalculado'},
        {dataField: 'valorCalculado2', dataType: 'double', caption: 'ativosaquisicaocontab.table.valorCalculado2'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnsContasAquisicao'}
      ],
      dataSource: [],
      columnHidingEnabled: false,
      export: {filename: 'ativosaquisicaocontab.half.contasAquisicao'},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocContabilidade'},
      remoteOperations: false
    };
    this.dataGridDefinitionAtivos = {
      keyExpr: 'codAtivo',
      columns: [
        {dataField: 'codAtivo', dataType: 'string', caption: 'ativosaquisicaocontab.table.codAtivo'},
        {dataField: 'designacao', dataType: 'string', caption: 'ativosaquisicaocontab.table.designacao', width: 200},
        {dataField: 'aquisicao.nContaDoAtivo', dataType: 'string', caption: 'ativosaquisicaocontab.table.nContaDoAtivo'},
        {dataField: 'aquisicao.contabValorAquisicao', dataType: 'double', caption: 'ativosaquisicaocontab.table.contabValorAquisicao'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnsAtivos'}
      ],
      dataSource: [],
      columnHidingEnabled: false,
      export: {filename: 'ativosaquisicaocontab.half.ativos'},
      remoteOperations: false
    };
    this.beforeNovoAtivoconst = {
      extPocCabID: '',
      nContaAtivo: '',
      lastCodAtivo: 0,
      valorAquisicao: 0
    };
    this._entityMaintenanceInstanceAtivos = this._entityMaintenanceService.build(ENTITY_NAME_ATIVOS);
    this._ativosAquisicaoContabParams = <IAtivosAquisicaoContabParams>this._transition.params();
    this._listaDocumentos = [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.cab) {
      this._emptyLineCab();
    }
    this._btnAddSearch = {
      id: 'pesquisaAdiciona',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-search fa-fw"></i>',
      caption: 'global.btn.search',
      class: 'btn-primary',
      click: () => this._openMaintenanceDocsContabilidade()
    };
    this._btnSave = {
      id: 'guardarAtivos',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-save fa-fw"></i>',
      caption: 'ativosaquisicaocontab.btn.guardarAtivos',
      class: 'btn-primary',
      disabled: true,
      click: () => this._saveAtivos()
    };
    this.toolbar.addButton(this._btnAddSearch);
    this.toolbar.addButton(this._btnSave);
    if (this._ativosAquisicaoContabParams.isfromconsistencia) {
      this.cab = this._ativosAquisicaoContabParams.doc.resumo;
      this.dataGridDefinitionContasAquisicao.dataSource = this._ativosAquisicaoContabParams.doc.listaContas;
      this._listaDocumentos = this._ativosAquisicaoContabParams.doc.listaDocumentos;
    }
    setTimeout(() => {
      this._registerOnStart();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._deRegisterOnStart();
  }

  public onInitializedContasAquisicao({component}: IDevExpressDataGridEventOnInitialized<IAquisicaoContabAdicionaDoc, string>): void {
    this._dataGridInstanceContasAquisicao = component;
  }

  public onContentReadyContasAquisicao(): void {
    this._dataGridInstanceContasAquisicao.endCustomLoading();
  }

  public onCellClickContasAquisicao(event: IDevExpressDataGridEventOnCellClick<IAquisicaoContabAdicionaDoc, string>): void {
    devExpressDataGridExpandDetailHandler(event, async () => {
      const extPocCabID: string = event.data.extPocCabID;
      let docContabilidade: IJsonDocContabilidade = this._mapDocsContabilidade.get(extPocCabID);
      if (!docContabilidade) {
        const response: HttpResponse<IJsonDocContabilidade> = await this._serviceDocsContabilidade.get({id: extPocCabID});
        docContabilidade = response.body;
        this._mapDocsContabilidade.set(extPocCabID, docContabilidade);
      }
      if (!event.data._docContabilidade) {
        event.data._docContabilidade = docContabilidade;
        if (this.empresaTemContabilidadeDigital) {
          event.data._contabDigitalDoc = {
            extPocCabID: docContabilidade.extPocCabID,
            periodo: docContabilidade.periodo,
            nDiario: docContabilidade.nDiario,
            nDocInterno: docContabilidade.nDocInterno,
            dataDoc: docContabilidade.dataDoc
          };
        }
      }
    });
  }

  public onInitializedAtivos({component}: IDevExpressDataGridEventOnInitialized<IJsonAtivos, string>): void {
    this._dataGridInstanceAtivos = component;
  }

  public onContentReadyAtivos(): void {
    this._dataGridInstanceAtivos.endCustomLoading();
  }

  public readonly fnNovoAtivo: (item: IJsonAquisicaoContabAdicionaDoc) => () => Promise<void> = (item: IJsonAquisicaoContabAdicionaDoc) => (): Promise<void> => this._novoAtivo(item);

  public readonly fnRemoveDocumento: (item: IJsonAquisicaoContabAdicionaDoc) => () => Promise<void> = (item: IJsonAquisicaoContabAdicionaDoc) => (): Promise<void> => this._removeDocumento(item);

  public readonly fnRemoveAtivo: (itemAtivo: IJsonAtivos) => () => Promise<void> = (itemAtivo: IJsonAtivos) => (): Promise<void> => this._removeAtivo(itemAtivo);

  private async _openMaintenanceDocsContabilidade(): Promise<void> {
    let promise: Promise<void>;
    this._btnAddSearch.promise = promise;
    this._btnSave.promise = promise;
    promise = this._ativosAquisicaoContabService
      .getPesquisaAquisicaoContab()
      .then((response: HttpResponse<Array<IAtivoAquisicaoPesquisaContab>>) => {
        const docsAdicionados: Array<IAquisicaoContabAdicionaDoc> = this._dataGridInstanceContasAquisicao.getDataSource().items();
        const modalInstance = this._cgModalService.showVanilla(AtivosAquisicaoContabModalComponent);
        const componentInstance: AtivosAquisicaoContabModalComponent = modalInstance.componentInstance;
        componentInstance.empresaTemContabilidadeDigital = this.empresaTemContabilidadeDigital;
        componentInstance.docsAddedList = docsAdicionados;
        componentInstance.docsList = this._listaDocumentos;
        componentInstance.aquisicaoContabList = response.body;

        return modalInstance.result.then((resAddDoc: IJsonAquisicaoContabResAddDoc) => {
          this.cab = resAddDoc.resumo;
          this.dataGridDefinitionContasAquisicao.dataSource = resAddDoc.listaContas;
          this.dataGridDefinitionAtivos.dataSource = [];
          this._mapDocsContabilidade.clear();
          this._listaDocumentos = resAddDoc.listaDocumentos;
          this._refreshBtnAddSearch(resAddDoc.listaContas.length);
        });
      })
      .finally(() => {
        promise = undefined;
      });
    return promise;
  }

  private _saveAtivos(): Promise<void> {
    let totalDocumentos = 0;
    let totalValorAssociado = 0;
    const docsAdicionados: Array<IAquisicaoContabAdicionaDoc> = this._dataGridInstanceContasAquisicao.getDataSource().items();

    for (const doc of docsAdicionados) {
      totalDocumentos += doc.valor;
      totalValorAssociado = totalValorAssociado + doc.valorCalculado + doc.valorCalculado2;
    }

    if (totalDocumentos !== totalValorAssociado) {
      this._plAlertService.error(this._translateService.instant('ativosaquisicaocontab.error.somainvalida', {valorAssociado: totalValorAssociado, documentos: totalDocumentos}));
      return Promise.resolve();
    }

    const listas: IJsonAquisicaoContasEAtivo = {
      listaContas: docsAdicionados,
      listaAtivos: this._dataGridInstanceAtivos.getDataSource().items(),
      listaDocumentos: this._listaDocumentos
    };

    this._dataGridInstanceContasAquisicao.beginCustomLoading(undefined);
    this._dataGridInstanceAtivos.beginCustomLoading(undefined);
    return this._ativosAquisicaoContabService
      .postSaveAtivosAquisicao(this.cab.valorFornecedor, totalDocumentos, listas)
      .then(() => {
        this.dataGridDefinitionContasAquisicao.dataSource = [];
        this.dataGridDefinitionAtivos.dataSource = [];
        this._mapDocsContabilidade.clear();
        this._listaDocumentos = [];
        this._btnSave.disabled = true;
        this._emptyLineCab();
        this._refreshBtnAddSearch();
        this._plAlertService.success('ativosaquisicaocontab.message.ativoguardado');
      })
      .finally(() => {
        this._dataGridInstanceContasAquisicao.endCustomLoading();
        this._dataGridInstanceAtivos.endCustomLoading();
      });
  }

  private _refreshBtnAddSearch(contasAquisicaoCount: number = 0): void {
    this._btnAddSearch.iconLeft = contasAquisicaoCount < 1 ? '<i class="fa fa-search fa-fw"></i>' : '<i class="fa fa-plus fa-fw"></i>';

    this._btnAddSearch.caption = contasAquisicaoCount < 1 ? 'ativosaquisicaocontab.btn.pesquisa' : 'ativosaquisicaocontab.btn.adiciona';

    this._btnAddSearch.class = contasAquisicaoCount < 1 ? 'btn btn-sm btn-primary' : 'btn btn-sm btn-success';
  }

  private _novoAtivo(item: IJsonAquisicaoContabAdicionaDoc): Promise<void> {
    const auxListAtivos: Array<IJsonAtivos> = this._dataGridInstanceAtivos.getDataSource().items();
    const contabAdicionaDocEListaAtivos: IContabAdicionaDocEListaAtivos = {
      conta: item,
      listaAtivos: auxListAtivos
    };
    return this._ativosAquisicaoContabService.postBeforeNovoAtivo(contabAdicionaDocEListaAtivos).then((response: HttpResponse<IJsonAtivos>) => {
      const params: IAtivosNewStateParams = {
        defaultData: response.body,
        contabValorAquisicao: response.body.aquisicao.contabValorAquisicao.toString(),
        docExtPocCabId: contabAdicionaDocEListaAtivos.conta.extPocCabID
      };
      return this._entityMaintenanceInstanceAtivos.maintenanceNew({params: params, onMaintenanceSave: this._fnAdicionaAtivo}).then(() => {
        return Promise.resolve();
      });
    });
  }

  private _removeDocumento(item: IJsonAquisicaoContabAdicionaDoc): Promise<void> {
    if (this._dataGridInstanceContasAquisicao.totalCount() === 1) {
      this.dataGridDefinitionContasAquisicao.dataSource = [];
      this.dataGridDefinitionAtivos.dataSource = [];
      this._mapDocsContabilidade.clear();
      this._listaDocumentos = [];
      this._emptyLineCab();
      return Promise.resolve();
    }

    const listaAtivos: Array<IJsonAtivos> = this._dataGridInstanceAtivos.getDataSource().items();
    const removeAtivos: Array<IJsonAtivos> = listaAtivos.filter((value: IJsonAtivos) => value.aquisicao.nContaDoAtivo === item.nConta);

    for (const removeAtivo of removeAtivos.slice().reverse()) {
      const index: number = listaAtivos.findIndex((value: IJsonAtivos) => value.codAtivo === removeAtivo.codAtivo);
      if (index !== -1) {
        listaAtivos.splice(index, 1);
      }
    }

    const aquisicaoContasEAtivo: IJsonAquisicaoContasEAtivo = {listaContas: [], listaAtivos: listaAtivos, listaDocumentos: this._listaDocumentos};
    this._dataGridInstanceContasAquisicao.beginCustomLoading(undefined);
    this._dataGridInstanceAtivos.beginCustomLoading(undefined);
    return this._ativosAquisicaoContabService
      .atualizaListaContasAquisicao(item.extPocCabID, aquisicaoContasEAtivo)
      .then((response: HttpResponse<IJsonAquisicaoContabResAddDoc>) => {
        this.cab = response.body.resumo;
        this.dataGridDefinitionContasAquisicao.dataSource = response.body.listaContas;
        this.dataGridDefinitionAtivos.dataSource = listaAtivos;
        this._addPropNlancToLista(this._listaDocumentos, response.body.listaDocumentos);
      })
      .finally(() => {
        this._dataGridInstanceContasAquisicao.endCustomLoading();
        this._dataGridInstanceAtivos.endCustomLoading();
      });
  }

  private _removeAtivo(itemAtivo: IJsonAtivos): Promise<void> {
    let addExtPocCabID = false;
    let listAtivosSupl: Array<IJsonAtivos> = this._dataGridInstanceAtivos.getDataSource().items();
    const docsAdicionados: Array<IAquisicaoContabAdicionaDoc> = this._dataGridInstanceContasAquisicao.getDataSource().items();
    const listContasSupl: Array<IAquisicaoContabAdicionaDoc> = [];

    if (listAtivosSupl && listAtivosSupl.length === 1) {
      listAtivosSupl = [];
    } else {
      const indexAtivo = listAtivosSupl.findIndex((item: IJsonAtivos) => item.codAtivo === itemAtivo.codAtivo);
      if (indexAtivo !== -1) {
        listAtivosSupl.splice(indexAtivo, 1);
      }
    }

    for (const doc of docsAdicionados) {
      addExtPocCabID = true;
      for (const docSupl of listContasSupl) {
        if (doc.extPocCabID === docSupl.extPocCabID) {
          addExtPocCabID = false;
        }
      }
      if (addExtPocCabID) {
        listContasSupl.push(doc);
      }
    }

    const aquisicaoContasEAtivo: IJsonAquisicaoContasEAtivo = {listaContas: listContasSupl, listaAtivos: listAtivosSupl, listaDocumentos: this._listaDocumentos};
    this._dataGridInstanceContasAquisicao.beginCustomLoading(undefined);
    this._dataGridInstanceAtivos.beginCustomLoading(undefined);
    return this._ativosAquisicaoContabService
      .postAtualizaValorNovosAtivosNasContas(aquisicaoContasEAtivo)
      .then((response: HttpResponse<IJsonAquisicaoContasEAtivo>) => {
        this.dataGridDefinitionContasAquisicao.dataSource = response.body.listaContas;
        this.dataGridDefinitionAtivos.dataSource = response.body.listaAtivos;
        this._mapDocsContabilidade.clear();
        this._listaDocumentos = response.body.listaDocumentos;
        this._btnSave.disabled = !response.body.listaAtivos.length;
      })
      .finally(() => {
        this._dataGridInstanceContasAquisicao.endCustomLoading();
        this._dataGridInstanceAtivos.endCustomLoading();
      });
  }

  private _adicionaAtivo({config}: IEntityMaintenanceOnMaintenanceSave<IJsonAtivos, AtivosNewComponent>): Promise<IJsonAtivos> {
    const listAtivosSupl: Array<IJsonAtivos> = this._dataGridInstanceAtivos.getDataSource().items();
    listAtivosSupl.push(config.body);

    const docsAdicionados: Array<IAquisicaoContabAdicionaDoc> = this._dataGridInstanceContasAquisicao.getDataSource().items();
    const listContasSupl: Array<IAquisicaoContabAdicionaDoc> = [];
    let addExtPocCabID = false;
    for (const doc of docsAdicionados) {
      addExtPocCabID = true;
      for (const docSupl of listContasSupl) {
        if (doc.extPocCabID === docSupl.extPocCabID) {
          addExtPocCabID = false;
        }
      }
      if (addExtPocCabID) {
        listContasSupl.push(doc);
      }
    }

    const aquisicaoContasEAtivo: IJsonAquisicaoContasEAtivo = {listaContas: listContasSupl, listaAtivos: listAtivosSupl, listaDocumentos: this._listaDocumentos};
    this._dataGridInstanceContasAquisicao.beginCustomLoading(undefined);
    this._dataGridInstanceAtivos.beginCustomLoading(undefined);
    return this._ativosAquisicaoContabService
      .postAtualizaValorNovosAtivosNasContas(aquisicaoContasEAtivo)
      .then((response: HttpResponse<IJsonAquisicaoContasEAtivo>) => {
        this.dataGridDefinitionContasAquisicao.dataSource = response.body.listaContas;
        this.dataGridDefinitionAtivos.dataSource = response.body.listaAtivos;
        this._mapDocsContabilidade.clear();
        this._listaDocumentos = response.body.listaDocumentos;
        this._btnSave.disabled = !response.body.listaAtivos.length;
        return undefined;
      })
      .finally(() => {
        this._dataGridInstanceContasAquisicao.endCustomLoading();
        this._dataGridInstanceAtivos.endCustomLoading();
      });
  }

  private _emptyLineCab(): void {
    this.cab = {contaFornecedor: '-', jaAssociado: 0, totalIVA: 0, valorFornecedor: 0};
  }

  private _navigationSafeGuard(): Promise<void> {
    if (!isTest() && (this.cab.contaFornecedor !== '-' || this._dataGridInstanceAtivos.totalCount() > 0)) {
      return this._cgModalService.showOkCancel('ativosaquisicaocontab.prompt.exitTitle', 'ativosaquisicaocontab.prompt.exitMessage');
    }
    return Promise.resolve();
  }

  private _registerOnStart(): void {
    this._deRegisterOnStart();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_LOGIN && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFn = this._transitionService.onStart(criteria, () => this._navigationSafeGuard());
  }

  private _deRegisterOnStart(): void {
    if (this._deRegisterOnStartFn) {
      this._deRegisterOnStartFn();
      this._deRegisterOnStartFn = undefined;
    }
  }

  private _addPropNlancToLista(listaDocumentos: Array<IJsonAquisicaoContabDoc>, responseListaDocumentos: Array<IJsonAquisicaoContabDoc>): void {
    responseListaDocumentos.forEach((responseDocumento: IJsonAquisicaoContabDoc) => {
      const linhaToChange: IJsonAquisicaoContabDoc = listaDocumentos.find(
        (documento: IJsonAquisicaoContabDoc) =>
          documento.extPocCabID === responseDocumento.extPocCabID &&
          documento.periodo === responseDocumento.periodo &&
          documento.nDocInterno === responseDocumento.nDocInterno &&
          documento.nDiario === responseDocumento.nDiario
      );
      if (linhaToChange) {
        responseDocumento.nConta = linhaToChange.nConta;
        responseDocumento.nLanc = linhaToChange.nLanc;
      }
    });
    this._mapDocsContabilidade.clear();
    this._listaDocumentos = responseListaDocumentos;
  }

  private readonly _fnAdicionaAtivo = (event: IEntityMaintenanceOnMaintenanceSave<IJsonAtivos, AtivosNewComponent>): Promise<IJsonAtivos> => {
    return this._adicionaAtivo(event);
  };
}
