import {Observable, Subject} from 'rxjs';
import {Directive, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {StateRegistry, TransitionService} from '@uirouter/core';
import {isEmpty, isObject} from 'pl-comps-angular';
import {EEntityStateDetailType, entityStateDetailQueryParam} from '../../../common/utils/entity.state.utils';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {ICGStateDeclaration, IPortalStates} from '../../services/portals/portals.service.interface';
import {IEntity, IEntityServiceMethodsOverride} from '../entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {ModuloComponent} from './module.component';

@Directive({
  selector: 'modulo-detail'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class ModuloDetailComponent<TJson extends object, TEntityService extends IEntityService<TJson> = IEntityService<TJson>> extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public type: EEntityStateDetailType;
  @Input() public states: IPortalStates;
  @Input() public entity: IEntity;
  @Input() public model: TJson;

  public readonly stateTypes: typeof EEntityStateDetailType;
  public entityName: string;
  public entityClassName: string;
  public service: TEntityService;
  public serviceMethodsOverride: IEntityServiceMethodsOverride;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public params: any;
  public id: number;

  protected readonly _stateRegistry: StateRegistry;
  protected readonly _transitionService: TransitionService;
  protected readonly _entityServiceBuilder: EntityServiceBuilder;

  protected readonly _subjectOnUpdate: Subject<EEntityStateDetailType>;
  protected readonly _subjectOnSave: Subject<Promise<TJson>>;
  protected readonly _subjectOnEdit: Subject<void>;
  protected readonly _subjectOnCancel: Subject<Promise<void>>;
  protected readonly _subjectOnNew: Subject<void>;
  protected readonly _subjectOnDelete: Subject<Promise<void>>;
  protected readonly _subjectOnDuplicate: Subject<Promise<void>>;
  protected readonly _subjectOnRefresh: Subject<void>;
  protected _entityState: string;
  protected _previousType: EEntityStateDetailType;

  private _observableOnEdit: Observable<void>;
  private _observableOnCancel: Observable<Promise<void>>;
  private _observableOnNew: Observable<void>;
  private _observableOnDelete: Observable<Promise<void>>;
  private _observableOnDuplicate: Observable<Promise<void>>;
  private _observableOnRefresh: Observable<void>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.stateTypes = EEntityStateDetailType;

    this._stateRegistry = this._injector.get<StateRegistry>(StateRegistry);
    this._transitionService = this._injector.get<TransitionService>(TransitionService);
    this._entityServiceBuilder = this._injector.get<EntityServiceBuilder>(EntityServiceBuilder);

    this._subjectOnUpdate = new Subject<EEntityStateDetailType>();
    this._subjectOnEdit = new Subject<void>();
    this._subjectOnCancel = new Subject<Promise<void>>();
    this._subjectOnNew = new Subject<void>();
    this._subjectOnDelete = new Subject<Promise<void>>();
    this._subjectOnDuplicate = new Subject<Promise<void>>();
    this._subjectOnRefresh = new Subject<void>();
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.entityName = this.entity.name;
    this.entityClassName = `${this.entityName}-detail`;
    this.params = this._transition.params();
    this.id = this.params.id;

    this._entityState = entityStateDetailQueryParam(this.entityName);
    this.type = this.params[this._entityState] || this.type;
    if (this.type === EEntityStateDetailType.DETAIL && !this.entity.actions.detail && this.entity.actions.edit) {
      this.type = EEntityStateDetailType.EDIT;
      this.btnCancel.visible = false;
    }

    if (!this.model || this.model instanceof HttpErrorResponse) {
      if (this.model instanceof HttpErrorResponse) {
        console.error(this.model);
      }
      if (isObject(this.params.model)) {
        this.model = this.params.model;
      } else {
        (<object>this.model) = {};
      }
    }

    this.service = this._entityServiceBuilder.build<TJson, TEntityService>(this.entityName);
    this.serviceMethodsOverride = this.entity.serviceMethodsOverride;
    this.btnBack.visible = Boolean(this.states.list);
    this.btnBack.click = () => this.back();

    this.onUpdate(this.type);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subjectOnUpdate.complete();
    this._subjectOnEdit.complete();
    this._subjectOnCancel.complete();
    this._subjectOnNew.complete();
    this._subjectOnDelete.complete();
    this._subjectOnDuplicate.complete();
    this._subjectOnRefresh.complete();
  }

  public onUpdate(stateType: EEntityStateDetailType): void {
    this._previousType = this.type;
    if (!stateType) {
      stateType = this.type;
    }
    this.type = stateType;
    switch (stateType) {
      case EEntityStateDetailType.DETAIL:
        this.btnEdit.visible = Boolean(this.entity.actions.edit);
        this.btnCancel.visible = false;
        this.btnSave.visible = false;
        this.btnDelete.visible = Boolean(this.entity.actions.delete);
        this.btnDuplicate.visible = Boolean(this.entity.actions.duplicate);
        break;
      case EEntityStateDetailType.EDIT:
      case EEntityStateDetailType.NEW:
        this.btnNovo.visible = false;
        this.btnEdit.visible = false;
        this.btnCancel.visible = stateType === EEntityStateDetailType.EDIT && this.entity.actions.detail;
        this.btnSave.visible = true;
        this.btnDelete.visible = false;
        this.btnDuplicate.visible = false;
        break;
    }

    if (this.states[stateType]?.data?.helperLinks) {
      this.setModuleHelperLinks(this.states[stateType].data.helperLinks);
    }

    this.setCaption();
    this._subjectOnUpdate.next(this.type);
  }

  public back(): Promise<void> {
    let params: ICGStateDeclaration['params'];
    const promise: Promise<void> = (async () => {
      if (!this.params.returnState || !this._stateRegistry.get(this.params.returnState)) {
        const previous: ICGStateDeclaration = <ICGStateDeclaration>this._transition.from();
        if (previous && previous.name === this.states.list.name) {
          params = this._transition.params('from');
        }
        await this._stateService.go(this.states.list.name, params).then(() => undefined);
      } else {
        params = isObject(this.params.returnStateParams) ? this.params.returnStateParams : {};
        await this._stateService.go(this.params.returnState, params).then(() => undefined);
      }
    })();
    this._subjectOnBack.next(promise);
    return promise;
  }

  public setCaption(caption?: string): void {
    if (!caption) {
      caption = this._defaultCaption();
    }
    super.setCaption(caption);
  }

  public resetModel(): void {
    this.model = this._buildModel();
  }

  public evtOnSave<T = TJson>(): Observable<Promise<T>> {
    return super.evtOnSave();
  }

  public evtOnEdit(): Observable<void> {
    if (!this._observableOnEdit) {
      this._observableOnEdit = this._subjectOnEdit.asObservable();
    }
    return this._observableOnEdit;
  }

  public evtOnCancel(): Observable<Promise<void>> {
    if (!this._observableOnCancel) {
      this._observableOnCancel = this._subjectOnCancel.asObservable();
    }
    return this._observableOnCancel;
  }

  public evtOnNew(): Observable<void> {
    if (!this._observableOnNew) {
      this._observableOnNew = this._subjectOnNew.asObservable();
    }
    return this._observableOnNew;
  }

  public evtOnDelete(): Observable<Promise<void>> {
    if (!this._observableOnDelete) {
      this._observableOnDelete = this._subjectOnDelete.asObservable();
    }
    return this._observableOnDelete;
  }

  public evtOnDuplicate(): Observable<Promise<void>> {
    if (!this._observableOnDuplicate) {
      this._observableOnDuplicate = this._subjectOnDuplicate.asObservable();
    }
    return this._observableOnDuplicate;
  }

  public evtOnRefresh(): Observable<void> {
    if (!this._observableOnRefresh) {
      this._observableOnRefresh = this._subjectOnRefresh.asObservable();
    }
    return this._observableOnRefresh;
  }

  protected _buildModel(): TJson {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return <TJson>{};
  }

  protected _defaultCaption(id?: string | number): string {
    if (isEmpty(id)) {
      id = this.entity.getDescriptionName(this.model);
      if (isEmpty(id)) {
        id = this.entity.getId(this.model);
      }
      if (isEmpty(id)) {
        id = this.id;
      }
      if (isEmpty(id)) {
        id = '';
      }
    }
    return this.entity.getCaption(this.type, id);
  }
}
