import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonDGEMP} from '../dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHServico, IJsonPRHServicoDatasMarcacoesPendentes, IJsonPRHServicoMarcacoes} from './jsonPRHServico.entity.interface';
import {Moment} from 'moment';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {EPRHFluxoIndiceTipo} from '../prhfluxos/jsonPRHFluxo.entity.interface';

export interface IPRHServicosEntityService extends IEntityService<IJsonPRHServico> {
  getListaEmpregados(codServico: number): TServiceResponse<Array<IJsonDGEMP>>;

  getListaEmpregadosServicos(listaCodServico: Array<number>): TServiceResponse<Array<IJsonDGEMP>>;

  getMarcacoesFerias(codServico: number, dataDe: Moment, dataAte: Moment, dataContext?: Moment): TServiceResponse<IJsonPRHServicoMarcacoes>;

  getMarcacoesFaltas(codServico: number, dataDe: Moment, dataAte: Moment, dataContext?: Moment): TServiceResponse<IJsonPRHServicoMarcacoes>;

  getMarcacoesAbonos(codServico: number, dataDe: Moment, dataAte: Moment, dataContext?: Moment): TServiceResponse<IJsonPRHServicoMarcacoes>;

  getDatasMarcacoesPendentes(tipo: EPRHFluxoIndiceTipo, codServico: number, dataContext: Moment): TServiceResponse<IJsonPRHServicoDatasMarcacoesPendentes>;
}

export interface IPRHServicosEntity extends IEntityDefinition<IJsonPRHServico, IPRHServicosEntityService> {}

export const ENTITY_NAME_PRH_SERVICOS = 'prhservicos';
