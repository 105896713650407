<div class="configdocs-efatura-supplier entity-detail-form" [plPromise]="promise">
  <pl-group>
    <pl-group>
      <label [translate]="'arquivodigital.predefinidosconfig.nif'"></label>
      <edit>
        <entity-autocomplete
          entity="nifs"
          attrName="nif"
          [selectedKey]="nContribuinte"
          (selectedKeyChange)="nContribuinteChanged($event)"
          [properties]="{events: {keydown: fnKeydownNContribuinte}}"
          plAutoFocus
          output="key">
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'arquivodigital.predefinidosconfig.nomeFornecedor'"></label>
      <edit>
        <pl-edit type="text" attrName="nomeFornecedor" [(model)]="nomeFornecedor" [properties]="{disabled: true}"></pl-edit>
      </edit>
    </pl-group>
  </pl-group>

  <hr />

  <config-docs-efatura-header [model]="model" (evtLastFieldKeydown)="onConfigDocsEFaturaHeaderLastFieldKeydown($event)"></config-docs-efatura-header>

  <hr />

  <pl-form>
    <pl-group>
      <label [translate]="'configEfaturaDocPorLanc.supplier.fields.contaCorrente'"></label>
      <edit>
        <entity-autocomplete
          entity="pocs"
          [model]="model"
          (selectedKeyChange)="model.contaCorrente = $event"
          (evtSelectedDescriptionChanged)="model.contaCorrenteDesc = $event"
          [fieldsMap]="{nConta: 'contaCorrente', nome: 'contaCorrenteDesc'}"
          [filter]="filterContaCorrente"
          [properties]="{events: {keydown: lastFieldKeydown}}"
          #elementContaAUtilizar>
        </entity-autocomplete>
      </edit>
    </pl-group>
  </pl-form>
</div>
