import {HttpResponse} from '@angular/common/http';
import {IJsonProcessamentoSalariosConfig} from '../jsonRHConfiguracoes.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {RHConfiguracoesService} from '../rhConfiguracoes.module.service';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_RH_PROCESSAMENTO_SALARIOS} from './rhProcessamentoSalarios.module.interface';
import {RHProcessamentoSalariosModuleComponent} from './components/rhProcessamentoSalarios.module.component';

export const MODULE_RH_PROCESSAMENTO_SALARIOS: IModuleDefinition = {
  name: MODULE_NAME_RH_PROCESSAMENTO_SALARIOS,
  state: {
    url: `/${MODULE_NAME_RH_PROCESSAMENTO_SALARIOS}`,
    component: RHProcessamentoSalariosModuleComponent,
    data: {
      roles: [ROLE.RH],
      sidebarTitle: 'portals.sidebar.modules.rhprocessamentosalarios'
    },
    resolve: [
      {
        provide: 'processamentosalariosconfig',
        deps: [RHConfiguracoesService],
        useFactory: (rhConfiguracoesService: RHConfiguracoesService): Promise<IJsonProcessamentoSalariosConfig> => {
          return rhConfiguracoesService.getProcessamentoSalarios().then((response: HttpResponse<IJsonProcessamentoSalariosConfig>) => response.body);
        }
      }
    ]
  }
};
