import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {TServiceResponse} from '../api/api.service.interface';
import {IJsonUtilsValidateIban} from './apiutils.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ApiUtilsService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/utils`;
  }

  public validateIban(iban: string): TServiceResponse<IJsonUtilsValidateIban> {
    return this._apiService.get({url: `${this._path}/validate/iban/${iban}`});
  }
}
