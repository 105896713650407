import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_ATIVOS_AQUISICAO_CONTAB} from './ativosAquisicaoContab.module.interface';
import {AtivosAquisicaoContabModuleComponent} from './components/ativosAquisicaoContab.module.component';
import {RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL} from '../../../services/contabilidadedigital/contabilidadedigital.router.interface';

export const MODULE_ATIVOS_AQUISICAO_CONTAB: IModuleDefinition = {
  name: MODULE_NAME_ATIVOS_AQUISICAO_CONTAB,
  state: {
    url: `/${MODULE_NAME_ATIVOS_AQUISICAO_CONTAB}`,
    component: AtivosAquisicaoContabModuleComponent,
    data: {
      roles: [ROLE.ATIVOS, ROLE.CONTABILIDADE],
      icon: 'fa-fast-forward',
      sidebarTitle: 'portals.sidebar.modules.ativosaquisicaocontab'
    },
    params: {
      isfromconsistencia: {
        type: 'bool',
        value: false
      },
      doc: {
        type: 'any',
        value: null
      }
    },
    resolve: [RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL]
  }
};
