<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'efatura.inexistenciaFaturacaoModalTitle'"></h4>
</div>

<div class="modal-body">
  <div class="mb-4"><img alt="" src="../../../../../../assets/images/logo-efatura-full-bg-white.webp" /></div>

  <div class="mb-2" style="display: flex; gap: 15px">
    <div [translate]="'efatura.mesDeclaracao'"></div>
    <div><b style="padding: 4px 8px; background: #efefef; border-radius: 4px; font-size: 12px" [innerHTML]="anoMes"></b></div>
  </div>

  <pl-group>
    <edit>
      <pl-edit type="checkbox" [(model)]="confirmCheck" [properties]="{label: checkLabelCaption}"></pl-edit>
    </edit>
  </pl-group>
</div>

<div class="modal-footer" plNavigation>
  <pl-button type="button" klass="btn btn-primary btn-sm action-submit" [onClick]="fnClick" [disabled]="!confirmCheck">
    <i class="fa fa-fw fa-copy"></i>&nbsp;<span [translate]="'efatura.btnComunicar'"></span>
  </pl-button>

  <button type="button" (click)="dismiss()" class="btn btn-light btn-sm action-cancel"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
