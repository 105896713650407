import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {ImpencarModuleComponent} from './components/impEncar.module.component';
import {MODULE_NAME_IMPENCAR} from './impEncar.module.interface';

export const MODULE_IMPENCAR: IModuleDefinition = {
  name: MODULE_NAME_IMPENCAR,
  state: {
    url: `/${MODULE_NAME_IMPENCAR}`,
    component: ImpencarModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.impencar'
    }
  }
};
