import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {KEYCODES} from 'pl-comps-angular';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_MEIOS_PAGAMENTO} from '../../../meiospagamento/meiosPagamento.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonMeioPagamento} from '../../../meiospagamento/jsonMeioPagamento.entity.interface';
import {IJsonRecibo, IJsonReciboMeioPagamento} from '../../jsonRecibo.entity.interface';
import {IRecibosSaveModalResult, TRecibosSaveModalSaveFn, TRecibosSaveModalSaveType} from './recibos.save.modal.component.interface';
import {IRecibo} from '../../recibos.entity.interface';
import {round} from '../../../../../common/utils/utils';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {Properties as NumberOptions} from 'devextreme/ui/number_box';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnKeyDown} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {DocFasNumsComunicacaoLoginModalComponent} from '../../../../modules/docfasnumscomunicacaologin/docFasNumsComunicacaoLogin.modal.component';
import {IDocfasNumsComunicacaoLogin} from '../../../../services/docfasNumsComunicacaoLogin/docfasNumsComunicacaoLogin.service.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';

const TWO_DECIMAL_FORMATOR = '#,##0.00';
const AT_SERIES_EXCEPTION_CLASS_NAME = 'EDaoRecibosSerieAT';

@Component({
  selector: 'recibos-save-modal',
  templateUrl: './recibos.save.modal.component.html'
})
export class RecibosSaveModalComponent extends CGModalComponent<IRecibosSaveModalResult> implements OnInit {
  @Input() public recibo: IRecibo;
  @Input() public saveFn: TRecibosSaveModalSaveFn;
  @Input() public abreviaturaMoeda: string;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly alertErrorMsg: Array<string>;
  public readonly codMoedaEmpresa: number;
  public readonly abreviaturaMoedaEmpresa: string;
  public readonly valorDecimais: number;
  public readonly meioPagamentoOmissao: number;
  public readonly saveButtonId: string;

  public canSave: boolean;
  public promise: Promise<void>;

  private readonly _serviceMeiosPagamento: IEntityService<IJsonMeioPagamento>;

  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.alertErrorMsg = [];
    this.codMoedaEmpresa = this._configService.configurations.empresa.codMoeda;
    this.abreviaturaMoedaEmpresa = this._configService.configurations.empresa.abreviaturaMoeda;
    this.valorDecimais = this._configService.configurations.contabilidade.decimais.valor;
    this.meioPagamentoOmissao = this._configService.configurations.contabilidade.recibos.meioPagamentoOmissao;
    this.saveButtonId = 'reciboModalSaveButton';
    this.canSave = true;
    this._serviceMeiosPagamento = this._entityServiceBuilder.build(ENTITY_NAME_MEIOS_PAGAMENTO);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nome', dataType: 'string', caption: 'recibos.saveModal.paymentMean', allowEditing: false},
        {dataField: 'valor', dataType: 'double', caption: 'recibos.saveModal.paymentValue', editorOptions: {format: TWO_DECIMAL_FORMATOR} satisfies NumberOptions, allowSorting: false},
        {type: 'buttons', cellTemplate: 'cellTemplateBtns', showInColumnChooser: false}
      ],
      editing: {mode: 'cell', startEditAction: 'click', selectTextOnEditStart: true, allowUpdating: true, refreshMode: 'repaint'},
      export: {filename: 'global.menu.recibos'},
      filterRow: {visible: false},
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'},
      toolbar: {visible: false}
    };
  }

  public ngOnInit(): void {
    // Valores por defeito em meios de pagamento
    this.recibo.meiosPagamento = [];
    this.recibo.totalCaixa = 0;
    this.recibo.troco = 0;

    this.promise = this._serviceMeiosPagamento.query({pesquisa: 'tipomovimento=0|tipomovimento=1'}).then((response: THttpQueryResponse<IJsonMeioPagamento>) => {
      // Setting default values to 0
      this.recibo.meiosPagamento = response.body.list.map<IJsonReciboMeioPagamento>((meioPagamento: IJsonMeioPagamento) => {
        return {nCaixa: meioPagamento.nCaixa, nome: meioPagamento.nome, valor: 0};
      });

      // Se tem um valor por omissão ou se tem apenas 1
      if (this.meioPagamentoOmissao || response.body.total === 1) {
        this._setValorOmissao();
      }

      this.calcularResumo();
    });
  }

  public onSaved(): void {
    this.calcularResumo();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public async onKeyDown({event, component}: IDevExpressDataGridEventOnKeyDown): Promise<void> {
    if (event.key !== KEYCODES.ENTER) {
      return;
    }
    // Prevent plFormNavigation from messing up UX
    event.preventDefault();
    event.stopPropagation();
    await component.saveEditData();
    const button: HTMLButtonElement = this._element.querySelector<HTMLButtonElement>(`#${this.saveButtonId}`);
    if (button) {
      setTimeout(() => {
        button.focus();
      });
    }
  }

  public clearErros(): void {
    this.alertErrorMsg.splice(0, this.alertErrorMsg.length);
  }

  public receberTudoLinha(item: IJsonReciboMeioPagamento): void {
    const index: number = this.recibo.meiosPagamento.findIndex((value: IJsonReciboMeioPagamento) => value.nCaixa === item.nCaixa);
    if (index !== -1) {
      const meioPagamento: IJsonReciboMeioPagamento = this.recibo.meiosPagamento[index];
      if (meioPagamento && this.recibo.totalCaixa < this.recibo.cab.total) {
        meioPagamento.valor = round(this.recibo.cab.total - this.recibo.totalCaixa + meioPagamento.valor, this.valorDecimais);
        this.calcularResumo();
      }
    }
  }

  public limpaLinha(item: IJsonReciboMeioPagamento): void {
    const index: number = this.recibo.meiosPagamento.findIndex((value: IJsonReciboMeioPagamento) => value.nCaixa === item.nCaixa);
    if (index !== -1) {
      const meioPagamento: IJsonReciboMeioPagamento = this.recibo.meiosPagamento[index];
      if (meioPagamento) {
        meioPagamento.valor = 0;
        this.calcularResumo();
      }
    }
  }

  public calcularResumo(): void {
    this.recibo.totalCaixa = 0;
    this.recibo.troco = 0;

    for (const meioPagamento of this.recibo.meiosPagamento) {
      if (meioPagamento.valor) {
        this.recibo.totalCaixa += round(meioPagamento.valor, this.valorDecimais);
      }
    }

    // Calcular troco
    this.canSave = this.recibo.totalCaixa >= this.recibo.cab.total;
    if (this.canSave) {
      this.recibo.troco = this.recibo.totalCaixa - this.recibo.cab.total;
    }
  }

  public readonly fnSave = (type: TRecibosSaveModalSaveType) => (): Promise<void> => this._save(type);

  private _showError(errorMessage: string): void {
    this.alertErrorMsg.push(errorMessage);
  }

  private _setValorOmissao(): void {
    if (this.meioPagamentoOmissao) {
      this.recibo.meiosPagamento = this.recibo.meiosPagamento.map((meio) => {
        if (this.meioPagamentoOmissao === meio.nCaixa) {
          meio.valor = this.recibo.cab.total;
        }
        return meio;
      });
    } else if (this.recibo.meiosPagamento[0]) {
      this.recibo.meiosPagamento[0].valor = this.recibo.cab.total;
    }
    this.calcularResumo();
  }

  private async _save(type: TRecibosSaveModalSaveType): Promise<void> {
    await this._dataGridInstance.saveEditData();
    this.calcularResumo();
    this.clearErros();
    this.disableClose();
    return Promise.resolve(this.promise)
      .then(() => {
        return Promise.resolve(this.saveFn())
          .then((response: IJsonRecibo) => {
            this.enableClose();
            this.close({type: type, recibo: response});
          })
          .catch((reason: HttpErrorResponse) => {
            this.enableClose();
            this._logger.error(reason);
            const exception: ICGExceptionError = this._cgExceptionService.get(reason);
            if (exception.message) {
              this._showError(exception.message);
              if (exception.class === AT_SERIES_EXCEPTION_CLASS_NAME) {
                this._showLoginAtModal().then((result: IDocfasNumsComunicacaoLogin) => {
                  if (result.username.length && result.password.length) {
                    return this._save(type);
                  }
                  return undefined;
                });
              }
            }
          });
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }

  private _showLoginAtModal(): Promise<IDocfasNumsComunicacaoLogin> {
    const instance = this._cgModalService.showVanilla(DocFasNumsComunicacaoLoginModalComponent, {size: 'sm'});
    return instance.result;
  }
}
