import {firstValueFrom} from 'rxjs';
import {map} from 'rxjs/operators';
import moment, {Moment, MomentInput} from 'moment';
import {Injectable} from '@angular/core';
import {UIRouterGlobals} from '@uirouter/core';
import {fromJson, isArray, isDefinedNotNull, isEmpty, isFunction, isNumber, isObject, Logger, toJson} from 'pl-comps-angular';
import {CGLocalStorageService} from '../../../../services/storage/localstorage.service';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../devexpress.datagrid.interface';
import {IDevExpressDataGridState} from './devexpress.datagrid.state.interface';
import {isTest} from '../../../../../config/constants';
import {SCHEMA_STRING} from '../../../../../common/schemas';

const IS_TEST: boolean = isTest();

@Injectable({
  providedIn: 'root'
})
export class DevExpressDataGridStateStoringService {
  constructor(
    private readonly _logger: Logger,
    private readonly _uiRouterGlobals: UIRouterGlobals,
    private readonly _cgLocalStorageService: CGLocalStorageService
  ) {}

  public customLoad(instanceId: string, definition: IDevExpressDataGrid): Promise<IDevExpressDataGridState> {
    if (!instanceId || IS_TEST) {
      return Promise.resolve(undefined);
    }
    const key: string = this._generateKey(instanceId);
    return firstValueFrom(
      this._cgLocalStorageService.getItem<string>(key, SCHEMA_STRING).pipe(
        map<string, IDevExpressDataGridState>((state: string) => {
          return this._adaptStateFromLoad(definition, state);
        })
      )
    );
  }

  public customSave(instanceId: string, definition: IDevExpressDataGrid, state: IDevExpressDataGridState): Promise<void> {
    if (!state || IS_TEST) {
      return Promise.resolve();
    }
    if (isNumber(definition.stateStoring?.version)) {
      state.version = definition.stateStoring.version;
    }
    const key: string = this._generateKey(instanceId);
    if (isFunction(definition.stateStoring?.beforeStore)) {
      state = definition.stateStoring.beforeStore(state);
    }
    return firstValueFrom(this._cgLocalStorageService.setItem(key, this._adaptStateToSave(state), SCHEMA_STRING));
  }

  public clear(instanceId: string): Promise<void> {
    const key: string = this._generateKey(instanceId);
    return firstValueFrom(this._cgLocalStorageService.removeItem(key));
  }

  private _generateKey(instanceId: string): string {
    return `dev_express_data_grid-${instanceId}-${this._uiRouterGlobals.current.name}`;
  }

  private _adaptStateFromLoad(definition: IDevExpressDataGrid, state: string): IDevExpressDataGridState {
    if (state) {
      try {
        const dataGridState: IDevExpressDataGridState = fromJson(state);
        if (isNumber(definition.stateStoring?.version) && (!isNumber(dataGridState.version) || dataGridState.version < definition.stateStoring.version)) {
          return undefined;
        }
        if (isArray(dataGridState.columns)) {
          const columns: Map<string, IDevExpressDataGridColumn> = this._generateColumnsMap(definition);
          for (const stateColumn of dataGridState.columns) {
            stateColumn.filterType = null;
            stateColumn.filterValue = null;
            stateColumn.filterValues = null;
            stateColumn.selectedFilterOperation = null;

            const column = columns.get(stateColumn.name || stateColumn.dataField);
            if (column) {
              if (isDefinedNotNull(column.filterType)) {
                stateColumn.filterType = column.filterType;
              }
              if (isDefinedNotNull(column.filterValue)) {
                stateColumn.filterValue = column.filterValue;
              }
              if (isDefinedNotNull(column.filterValues)) {
                stateColumn.filterValues = column.filterValues;
              }
              if (isDefinedNotNull(column.selectedFilterOperation)) {
                stateColumn.selectedFilterOperation = column.selectedFilterOperation;
              }
            }

            if (stateColumn.dataType === 'date' || (stateColumn.dataType === 'datetime' && !isEmpty(stateColumn.filterValue))) {
              const date: Moment = moment(<MomentInput>stateColumn.filterValue);
              stateColumn.filterValue = date.isValid() ? date.toDate() : null;
            }
          }
        }
        delete dataGridState.allowedPageSizes;
        if (isDefinedNotNull(definition.filterValue)) {
          dataGridState.filterValue = definition.filterValue;
        } else {
          delete dataGridState.filterValue;
        }
        delete dataGridState.focusedRowKey;
        delete dataGridState.skip;
        delete dataGridState.take;
        delete dataGridState.pageIndex;
        delete dataGridState.pageSize;
        delete dataGridState.searchText;
        delete dataGridState.selectedRowKeys;
        return dataGridState;
      } catch (exception: unknown) {
        this._logger.error(exception);
      }
    }
    return undefined;
  }

  private _adaptStateToSave(state: IDevExpressDataGridState): string {
    if (isObject(state)) {
      try {
        for (const column of state.columns) {
          column.filterType = null;
          column.filterValue = null;
          column.filterValues = null;
          column.selectedFilterOperation = null;
        }
        delete state.allowedPageSizes;
        delete state.filterValue;
        delete state.focusedRowKey;
        delete state.skip;
        delete state.take;
        delete state.pageIndex;
        delete state.pageSize;
        delete state.searchText;
        delete state.selectedRowKeys;
        return toJson(state);
      } catch (exception: unknown) {
        this._logger.error(exception);
      }
    }
    return '';
  }

  private _generateColumnsMap(definition: IDevExpressDataGrid): Map<string, IDevExpressDataGridColumn> {
    return new Map<string, IDevExpressDataGridColumn>(
      isArray(definition.columns)
        ? definition.columns.map<[string, IDevExpressDataGridColumn]>((column: IDevExpressDataGridColumn) => {
            return [column.name || column.dataField, column];
          })
        : []
    );
  }
}
