import {TargetState, Transition} from '@uirouter/core';
import {Ng2StateDeclaration} from '@uirouter/angular';
import {ILockedContractStateParams, STATE_NAME_LOCKED_CONTRACT} from './lockedcontract.state.interface';
import {LockedContractStateComponent} from './lockedcontract.state.component';
import {STATE_NAME_SITE} from '../../../../common/site';
import {UNDEFINED_ID} from '../../../../config/constants';

export const STATE_LOCKED_CONTRACT: Ng2StateDeclaration = {
  parent: STATE_NAME_SITE,
  name: STATE_NAME_LOCKED_CONTRACT,
  url: `/${STATE_NAME_LOCKED_CONTRACT}`,
  component: LockedContractStateComponent,
  data: {
    disableRecover: true
  },
  params: {
    lockedReason: {
      type: 'int',
      value: UNDEFINED_ID
    }
  },
  onEnter: (transition: Transition): TargetState | boolean => {
    const {lockedReason}: ILockedContractStateParams = <ILockedContractStateParams>transition.params();
    if (!lockedReason || lockedReason === UNDEFINED_ID) {
      return transition.router.stateService.target(STATE_NAME_SITE);
    }
    return true;
  }
};
