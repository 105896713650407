<pl-form (evtSubmitted)="close()">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'dgempsfull.saveCadastro.title'"></h4>
  </div>

  <div class="modal-body">
    <h6 class="mb-2" [translate]="'dgempsfull.saveCadastro.message'"></h6>

    <pl-edit type="radio" attrName="savecadastromode" [(model)]="saveCadastroMode" [properties]="{value: dgempsSaveCadastroMode.NewCadastro, label: 'dgempsfull.saveCadastro.newCadastro'}"></pl-edit>

    <pl-edit
      type="radio"
      attrName="savecadastromode"
      [(model)]="saveCadastroMode"
      [properties]="{value: dgempsSaveCadastroMode.UpdateLatestCadastro, label: 'dgempsfull.saveCadastro.updateLatestCadastro'}">
    </pl-edit>

    <div class="text-danger" [translate]="'dgempsfull.deletePrompt.invalid'" *ngIf="invalid"></div>

    <pl-group *ngIf="saveCadastroMode === dgempsSaveCadastroMode.NewCadastro">
      <label [translate]="'dgempsfull.saveCadastro.dataRegisto'"></label>
      <edit>
        <pl-edit-datepicker
          attrName="dtMotMov"
          [model]="dataRegisto"
          (modelChange)="changedDataRegisto($event)"
          [dateDisabled]="fnIsDateDisabled"
          [dateMarked]="fnIsDateMarked"
          [properties]="{validators: {required: {value: true}}}">
        </pl-edit-datepicker>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn-primary action-save" plAutoFocus><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button type="button" klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</pl-form>
