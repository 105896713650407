import {Component, Injector, OnInit} from '@angular/core';
import {IPlFilterPanelEvtFiltered, IPlFilterPanelFilters} from 'pl-comps-angular';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IJsonTirs} from '../../jsonTirs.entity.interface';

@Component({
  selector: 'tirs-list',
  templateUrl: './tirs.entity.list.component.html'
})
export class TIRSListComponent extends ModuloEntityListComponent<IJsonTirs> implements OnInit {
  private _firstTime: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.onFiltered = this.onFiltered.bind(this);
    this.filterValue = 'anoVigor=2024';
    this._firstTime = true;
  }

  public onFiltered(event: IPlFilterPanelEvtFiltered<string>): IPlFilterPanelEvtFiltered<string> {
    if (this._firstTime) {
      this._firstTime = false;
      this.filterValue = '';
      event.filters = <IPlFilterPanelFilters>(<unknown>[]);
      event.serializedFilters = '';
    }
    return {...event};
  }
}
