import {IPlTableNavigationSelectors} from 'pl-comps-angular';
import {ETypeKind, IJsonConfigERP} from '../core/entities/configserp/jsonConfigERP.entity.interface';

export const REGEX_ONLY_NUMBERS = new RegExp('^[0-9]+$', 'g');
export const TABLE_FLEX_NAVIGATION_OVERRIDES: Partial<IPlTableNavigationSelectors> = Object.freeze<Partial<IPlTableNavigationSelectors>>({
  tr: '.table-flex-tr',
  td: '.table-flex-td'
});
export const DIGITS_INFO_TWO_DECIMALS = '1.2-2';

export function normalize(value: string): string {
  return (
    value
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, (str: string) => str.toUpperCase())
  );
}

export function mapToObject(map: Map<string, unknown>): object {
  const mapObject: object = {};
  for (const [key, value] of map) {
    mapObject[key] = value;
  }
  return mapObject;
}

export function setToArray(set: Set<unknown>): Array<unknown> {
  return Array.from(set.values());
}

export function configErpBooleanToValue(configErp: IJsonConfigERP, value: boolean): unknown {
  switch (configErp.kind) {
    case ETypeKind.Integer:
      return Number(value);
    case ETypeKind.Enumeration:
      return value;
    default:
      return value;
  }
}
