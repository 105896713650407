import {cloneDeep, template} from 'lodash-es';
import {COMMA_SEPARATOR, ISerializable, TCGCExpression} from '../interface';
import {EBrowserDetectionTypes, TBrowserDetection} from './utilities.interface';
import {ERadix} from '../enums';

const toString = Object.prototype.toString;
const TYPED_ARRAY_REGEXP = /^(Uint8|Uint8Clamped|Uint16|Uint32|Int8|Int16|Int32|Float32|Float64)Array$/;
const TIMERS: {[id: string]: number} = {};

export const FOCUSABLE_QUERY: ReadonlyArray<string> = Object.freeze<Array<string>>([
  'input',
  'select:not(.pl-pagination-select)',
  'textarea, button[focus]',
  'button[data-focus]',
  'button[type="submit"]',
  'pl-button[focus] button',
  'pl-button[data-focus] button',
  'pl-edit-checkbox > .pl-checkbox > .pl-checkbox-edit > i',
  'pl-edit-switch > .pl-switch > .pl-switch-edit > .pl-switch-slider',
  'pl-edit-radio > .pl-radio > .pl-radio-edit > i'
]);

export const FOCUSABLE_QUERY_PERMISSIVE: ReadonlyArray<string> = Object.freeze<Array<string>>([...FOCUSABLE_QUERY, 'button', 'pl-button[focus] button']);

export const FOCUSABLE_QUERY_EXCEPTIONS: ReadonlyArray<string> = Object.freeze<Array<string>>(['[tabindex="-1"]', ':disabled']);

export const FOCUSABLE_QUERY_SELECTORS_MAP_FN = (query: string): string => {
  for (const exception of FOCUSABLE_QUERY_EXCEPTIONS) {
    query += `:not(${exception})`;
  }
  return query;
};

export const FOCUSABLE_QUERY_PERMISSIVE_SELECTORS_MAP_FN = (query: string): string => {
  for (const exception of FOCUSABLE_QUERY_EXCEPTIONS) {
    query += `:not(${exception})`;
  }
  return query;
};

export const FOCUSABLE_QUERY_SELECTORS: ReadonlyArray<string> = Object.freeze<Array<string>>(FOCUSABLE_QUERY.map(FOCUSABLE_QUERY_SELECTORS_MAP_FN));

export const FOCUSABLE_QUERY_PERMISSIVE_SELECTORS: ReadonlyArray<string> = Object.freeze<Array<string>>(FOCUSABLE_QUERY_PERMISSIVE.map(FOCUSABLE_QUERY_PERMISSIVE_SELECTORS_MAP_FN));

export const FOCUSABLE_QUERY_SELECTOR: string = FOCUSABLE_QUERY_SELECTORS.join(',');

export const FOCUSABLE_QUERY_PERMISSIVE_SELECTOR: string = FOCUSABLE_QUERY_PERMISSIVE_SELECTORS.join(',');

export function isUndefinedOrNull(value: any): value is undefined | null {
  return typeof value === 'undefined' || value === null;
}

export function isDefinedNotNull(value: any): boolean {
  return typeof value !== 'undefined' && value !== null;
}

export function isUndefined(value: any): value is undefined {
  return typeof value === 'undefined';
}

export function isNull(value: any): value is null {
  return value === null;
}

export function isDefined(value: any): boolean {
  return typeof value !== 'undefined';
}

export function isObject(value: any): boolean {
  return value !== null && typeof value === 'object';
}

export function isBlankObject(value: any): boolean {
  return value !== null && typeof value === 'object' && !Object.getPrototypeOf(value);
}

export function isEmptyObject(value: any): boolean {
  return value !== null && typeof value === 'object' && Object.keys(value).length === 0;
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function isNumber(value: any): value is number {
  return typeof value === 'number';
}

// eslint-disable-next-line @typescript-eslint/no-restricted-types
export function isDate(value: any): value is Date {
  return String(value) === '[object Date]';
}

export function isArray(value: any): value is Array<any> {
  return Array.isArray(value);
}

export function isError(value: any): value is Error {
  const tag = String(value);
  switch (tag) {
    case '[object Error]':
      return true;
    case '[object Exception]':
      return true;
    case '[object DOMException]':
      return true;
    default:
      return value instanceof Error;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export function isFunction(value: any): value is Function {
  return typeof value === 'function';
}

export function isRegExp(value: any): value is RegExp {
  return String(value) === '[object RegExp]';
}

export function isWindow(value: any): value is Window {
  return value && value.window === value;
}

export function isBlob(value: any): value is Blob {
  return isObject(value) && String(value) === '[object Blob]';
}

export function isFile(value: any): value is File {
  return isObject(value) && String(value) === '[object File]';
}

export function isFormData(value: any): value is FormData {
  return isObject(value) && String(value) === '[object FormData]';
}

export function isBoolean(value: any): value is boolean {
  return typeof value === 'boolean';
}

export function isPromiseLike(value: any): value is PromiseLike<any> {
  return value && isFunction(value.then);
}

export function isTypedArray(value: any): value is Uint8Array | Uint8ClampedArray | Uint16Array | Uint32Array | Int8Array | Int16Array | Int32Array | Float32Array | Float64Array {
  return isObject(value) && isNumber(value.length) && TYPED_ARRAY_REGEXP.test(value.constructor.name);
}

export function isArrayBuffer(value: any): value is ArrayBuffer {
  return String(value) === '[object ArrayBuffer]';
}

export function isElement(node: any): node is Element {
  return Boolean(node && (node.nodeName || (node.prop && node.attr && node.find)));
}

export function isEvent(value: any): value is Event {
  return String(value) === '[object Event]';
}

export function isEmpty(value: any): boolean {
  return isUndefinedOrNull(value) || value === '';
}

export function isArrayLike(value: object): boolean {
  // `null`, `undefined` and `window` are not array-like
  if (value === null || isWindow(value)) {
    return false;
  }

  // arrays, strings and jQuery/jqLite objects are array like
  // * jqLite is either the jQuery or jqLite constructor export function
  // * we have to check the existence of jqLite first as this method is called
  //   via the forEach method when constructing the jqLite object in the first place
  if (isArray(value) || isString(value) || (jQuery && value instanceof jQuery)) {
    return true;
  }

  // Support: iOS 8.2 (not reproducible in simulator)
  // "length" in value used to prevent JIT error (gh-11508)
  const length = 'length' in Object(value) && (<any>value).length;

  // NodeList objects (with `item` method) and
  // other objects with suitable length characteristics are array-like
  return isNumber(length) && ((length >= 0 && length - 1 in value) || typeof (<any>value).item === 'function');
}

export function isIterable(value: object): value is Iterable<any> {
  return value !== null && typeof value[Symbol.iterator] === 'function';
}

export function isSerializable(value: any): value is ISerializable {
  return isObject(value) && isFunction((<ISerializable>value).toJSON);
}

export function hasCustomToString(value: object): boolean {
  return isFunction(value.toString) && value.toString !== toString;
}

export function interpolate(value: string): (obj: any) => string {
  return (obj: any) => {
    try {
      return template(value, {interpolate: /{{([\s\S]*?)}}/g})(obj);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error: unknown) {
      return '';
    }
  };
}

export function copy<T>(value: T): T {
  return cloneDeep<T>(value);
}

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function fromJson<T>(value: string): T {
  return <T>JSON.parse(value);
}

export function toJson(value: any): string {
  return JSON.stringify(value);
}

export function toInteger(value: string): number {
  return parseInt(value, ERadix.Decimal);
}

export function toDecimal(value: string): number {
  return toInteger(value);
}

export function lowercase(value: any): string {
  if (isEmpty(value)) {
    return '';
  }
  return String(value).toLowerCase();
}

export function uppercase(value: string): string {
  if (isEmpty(value)) {
    return value;
  }
  return String(value).toUpperCase();
}

export function capitalize(value: string): string {
  if (isEmpty(value)) {
    return value;
  }
  value = String(value);
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function identity<T>(value?: T): T {
  return value;
}

export function splitAt<T extends string | Array<any>>(index: number): (value: T) => [T, T] {
  return (value: T): [T, T] => {
    const first: T = <T>value.slice(0, index);
    const second: T = <T>value.slice(index);
    return [first, second];
  };
}

export function stripCharacters(value: string | number, format: {decimalsSeparator: string; decimalsLimit: number}): string {
  const decimalsSeparator = format.decimalsSeparator;
  // Removing all characters but 0-9 numbers or decimals separator (if using decimals)
  if (isNumber(value) && value % 1 !== 0) {
    value = String(value).replace(/\./g, decimalsSeparator);
  }
  if (!isString(value)) {
    value = String(value);
  }
  const toReplace = notNumberRegex(format.decimalsLimit, decimalsSeparator);
  return value.replace(toReplace, '');
}

export function parseEditNumber(value: string | number, format: {decimalsSeparator: string; decimalsLimit: number}): number {
  if (isUndefinedOrNull(value)) {
    return undefined;
  }
  value = stripCharacters(value, format);
  value = value.replace(new RegExp(`\\${format.decimalsSeparator}`, 'g'), '.');
  return parseFloat(value);
}

export function notNumberRegex(decimalsLimit?: number, decimalsSeparator?: string): RegExp {
  return new RegExp(!isNumber(decimalsLimit) || decimalsLimit <= 0 ? '[^0-9\\-]' : `[^0-9\\${decimalsSeparator}\\-]`, 'g');
}

export function clamp(value: number, max: number): number {
  return Math.max(0, Math.min(max, value));
}

export function moveItemInArray<T>(array: Array<T>, fromIndex: number, toIndex: number): void {
  const from: number = clamp(fromIndex, array.length - 1);
  const to: number = clamp(toIndex, array.length - 1);
  if (from === to) {
    return;
  }
  const target: T = array[from];
  const delta = to < from ? -1 : 1;
  for (let i = from; i !== to; i += delta) {
    array[i] = array[i + delta];
  }
  array[to] = target;
}

export function transferArrayItem<T>(currentArray: Array<T>, targetArray: Array<T>, currentIndex: number, targetIndex: number): void {
  const from: number = clamp(currentIndex, currentArray.length - 1);
  const to: number = clamp(targetIndex, targetArray.length);
  if (currentArray.length) {
    targetArray.splice(to, 0, currentArray.splice(from, 1)[0]);
  }
}

export function copyArrayItem<T>(currentArray: Array<T>, targetArray: Array<T>, currentIndex: number, targetIndex: number, clone: boolean = false): void {
  const to: number = clamp(targetIndex, targetArray.length);
  if (currentArray.length) {
    const item: T = clone ? copy(currentArray[currentIndex]) : currentArray[currentIndex];
    targetArray.splice(to, 0, item);
  }
}

export function deepFreeze<T>(object: T): T {
  if (Object.isFrozen(object)) {
    return object;
  }
  for (const property of Object.getOwnPropertyNames(object)) {
    const value = object[property];
    object[property] = value && typeof value === 'object' ? deepFreeze<T>(value) : value;
  }
  return Object.freeze<T>(object);
}

export function timeout(milliseconds?: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

export function nodeForEach<T extends Node>(nodeList: NodeListOf<T>, callback: (value: T, index: number, source: Array<T>) => void): void {
  Array.from(nodeList).forEach(callback);
}

export function nodeListIndex<T extends Node>(nodeList: NodeListOf<T>, nodeToFindIndex: T): number {
  let foundIndex = -1;
  nodeForEach(nodeList, (node: T, index: number) => {
    if (node === nodeToFindIndex && foundIndex === -1) {
      foundIndex = index;
    }
  });
  return foundIndex;
}

export function generateUniqueID(prefix: string = ''): string {
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  return `${prefix}_${Math.random().toString(36).substring(2, 9)}`;
  /* eslint-enable @typescript-eslint/no-magic-numbers */
}

export function generateName(original: string, name: string): string {
  if (isUndefinedOrNull(original) || !original.toString().trim()) {
    return generateUniqueID(name);
  }
  return original;
}

export function toCamelCase(value: string): string {
  return value.replace(/^\w|[A-Z]|\b\w/g, (letter, index) => (!index ? letter.toLowerCase() : letter.toUpperCase())).replace(/\s+/g, '');
}

export function removeAllNotLast(value: string, token: string): string {
  const parts: Array<string> = value.split(token);
  if (isUndefined(parts[1])) {
    return value;
  }
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  return parts.slice(0, -1).join('') + token + parts.slice(-1);
}

export function getNodePath(node: Node): string {
  if (!node) {
    return undefined;
  }
  const stack = [];
  while (node.parentNode) {
    let siblingCount = 0;
    let siblingIndex = 0;

    nodeForEach(node.parentNode.childNodes, (sibling: Node) => {
      if (sibling.nodeName.toLowerCase() === node.nodeName.toLowerCase()) {
        if (sibling === node) {
          siblingIndex = siblingCount;
        }
        siblingCount++;
      }
    });
    const nodeName = node.nodeName.toLowerCase();
    if ((<HTMLElement>node).hasAttribute('id') && (<HTMLElement>node).id) {
      stack.unshift(`${nodeName}#${escapeCSS((<HTMLElement>node).id)}`);
    } else if (siblingCount > 1) {
      stack.unshift(`${nodeName}:nth-of-type(${siblingIndex + 1})`);
    } else {
      stack.unshift(nodeName);
    }
    node = node.parentNode;
  }
  return stack.slice(1).join('>');
}

export function parseExpressionToStr(value: TCGCExpression, previous?: string, ...args: Array<any>): string {
  previous = previous || '';
  if (isString(value)) {
    previous += COMMA_SEPARATOR + value;
  } else if (isArray(value)) {
    for (const element of value) {
      previous = parseExpressionToStr(element, previous);
    }
  } else if (isObject(value)) {
    for (const property of Object.keys(value)) {
      const myValue: any = value[property];
      if (myValue === true || (isFunction(myValue) && myValue(...args))) {
        previous = parseExpressionToStr(property, previous);
      }
    }
  }
  return previous;
}

export function parseExpressionToString(value: TCGCExpression, ...args: Array<any>): string {
  value = parseExpressionToStr(value, undefined, args);
  if (value.startsWith(COMMA_SEPARATOR)) {
    value = value.substring(1);
  }
  return value;
}

export function getPathValue(root: object, path: string): object {
  const segments = path.split('.');
  while (segments.length > 1) {
    const pathStep = segments.shift();
    if (isUndefinedOrNull(root[pathStep])) {
      root[pathStep] = {};
    }
    root = root[pathStep];
  }
  return root;
}

export function normalizeAccents(value: string): string {
  return String(value)
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export function elementIndex(element: Element): number {
  if (!element) {
    return undefined;
  }
  let index = -1;
  do {
    index++;
    element = element.previousElementSibling;
  } while (element !== null);
  return index;
}

export function nodeIndex(node: Node): number {
  if (!node) {
    return undefined;
  }
  let index = 0;
  node = node.previousSibling;
  while (node !== null) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (node && node.nodeType !== 3) {
      index++;
    }
    node = node.previousSibling;
  }
  return index;
}

export function debounce(callback: (...args) => any, ms: number, id: string): number {
  if (!id) {
    throw new Error('Debounce requires an unique ID to avoid timer collisions.');
  }
  if (TIMERS[id]) {
    window.clearTimeout(TIMERS[id]);
  }
  TIMERS[id] = window.setTimeout(callback, ms);
  return TIMERS[id];
}

export function debouncePromise(ms: number, id: string): {timerId: number; result: Promise<void>} {
  if (!id) {
    throw new Error('Debounce requires an unique ID to avoid timer collisions.');
  }
  const promise = new Promise<void>((resolve) => {
    if (TIMERS[id]) {
      window.clearTimeout(TIMERS[id]);
    }
    TIMERS[id] = window.setTimeout(resolve, ms);
  });
  return {timerId: TIMERS[id], result: promise};
}

export function escapeRegExp(value: string): string {
  return String(value).replace(/[\\^$*+?.()|[\]{}]/g, '\\$&');
}

export function newEscapedRegExp(value: string | RegExp, flags?: string): RegExp {
  if (isString(value)) {
    return new RegExp(escapeRegExp(value), flags);
  }
  return new RegExp(value, flags);
}

export function browserDetection(): TBrowserDetection {
  /* eslint-disable */
  const isOpera: boolean = (!!(<any>window).opr && !!(<any>window).opr.addons) || !!(<any>window).opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  // @ts-expect-error InstallTrigger is non standard
  const isFirefox: boolean = typeof InstallTrigger !== 'undefined';
  const isSafari: boolean =
    /constructor/i.test((<any>window).HTMLElement) ||
    (function (p) {
      return p.toString() === '[object SafariRemoteNotification]';
    })(!(<any>window).safari?.pushNotification);
  const isIE: boolean = /*@cc_on!@*/ !!(<any>window.document).documentMode;
  const isEdge: boolean = !isIE && !!(<any>window).StyleMedia;
  const isChrome: boolean = !!(<any>window).chrome && (!!(<any>window).chrome.webstore || !!(<any>window).chrome.runtime);
  const isBlink: boolean = (isChrome || isOpera) && !!(<any>window).CSS;
  const isIOS: boolean =
    (isDefined(navigator) && !!navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent)) ||
    (/Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2);
  const isAndroid: boolean = isDefined(navigator) && !!navigator.userAgent && /Android/.test(navigator.userAgent);
  return {
    [EBrowserDetectionTypes.Blink]: isBlink,
    [EBrowserDetectionTypes.Chrome]: isChrome,
    [EBrowserDetectionTypes.Edge]: isEdge,
    [EBrowserDetectionTypes.Firefox]: isFirefox,
    [EBrowserDetectionTypes.InternetExplorer]: isIE,
    [EBrowserDetectionTypes.Opera]: isOpera,
    [EBrowserDetectionTypes.Safari]: isSafari,
    [EBrowserDetectionTypes.IOS]: isIOS,
    [EBrowserDetectionTypes.Android]: isAndroid
  };
  /* eslint-enable */
}

export function browserDetectionByType(type: EBrowserDetectionTypes): boolean {
  return browserDetection()[type];
}

export function normalizeInjectedValue<T>(toNormalize: Array<T | Array<T>>): Array<T> {
  if (!isArray(toNormalize) || !toNormalize.length) {
    return [];
  }
  return toNormalize.reduce<Array<T>>((previousValue: Array<T>, currentValue: T | Array<T>) => {
    let value: Array<T> = isArray(currentValue) ? currentValue : [currentValue];
    if (previousValue) {
      value = previousValue.concat(value);
    }
    return value;
  }, undefined);
}

export function escapeCSS(value: string): string {
  if (!value) {
    return '';
  }
  value = String(value);
  const length: number = value.length;
  let index = -1;
  let codeUnit: number;
  let result = '';
  const firstCodeUnit = value.charCodeAt(0);
  // eslint-disable no-magic-numbers
  while (++index < length) {
    codeUnit = value.charCodeAt(index);
    // Note: there’s no need to special-case astral symbols, surrogate
    // pairs, or lone surrogates.

    // If the character is NULL (U+0000), then the REPLACEMENT CHARACTER
    // (U+FFFD).
    if (codeUnit === 0x0000) {
      result += '\uFFFD';
      continue;
    }

    /* eslint-disable @typescript-eslint/no-magic-numbers */
    if (
      // If the character is in the range [\1-\1F] (U+0001 to U+001F) or is
      // U+007F, […]
      (codeUnit >= 0x0001 && codeUnit <= 0x001f) ||
      codeUnit === 0x007f ||
      // If the character is the first character and is in the range [0-9]
      // (U+0030 to U+0039), […]
      (index === 0 && codeUnit >= 0x0030 && codeUnit <= 0x0039) ||
      // If the character is the second character and is in the range [0-9]
      // (U+0030 to U+0039) and the first character is a `-` (U+002D), […]
      (index === 1 && codeUnit >= 0x0030 && codeUnit <= 0x0039 && firstCodeUnit === 0x002d)
    ) {
      // https://drafts.csswg.org/cssom/#escape-a-character-as-code-point
      result += `\\${codeUnit.toString(ERadix.Hexadecimal)} `;
      continue;
    }

    if (
      // If the character is the first character and is a `-` (U+002D), and
      // there is no second character, […]
      index === 0 &&
      length === 1 &&
      codeUnit === 0x002d
    ) {
      result += `\\${value.charAt(index)}`;
      continue;
    }

    // If the character is not handled by one of the above rules and is
    // greater than or equal to U+0080, is `-` (U+002D) or `_` (U+005F), or
    // is in one of the ranges [0-9] (U+0030 to U+0039), [A-Z] (U+0041 to
    // U+005A), or [a-z] (U+0061 to U+007A), […]
    if (
      codeUnit >= 0x0080 ||
      codeUnit === 0x002d ||
      codeUnit === 0x005f ||
      (codeUnit >= 0x0030 && codeUnit <= 0x0039) ||
      (codeUnit >= 0x0041 && codeUnit <= 0x005a) ||
      (codeUnit >= 0x0061 && codeUnit <= 0x007a)
    ) {
      // the character itself
      result += value.charAt(index);
      continue;
    }

    // Otherwise, the escaped character.
    // https://drafts.csswg.org/cssom/#escape-a-character
    result += `\\${value.charAt(index)}`;
  }
  /* eslint-enable @typescript-eslint/no-magic-numbers */
  return result;
}

export function isContainedIn(element: HTMLElement, array: Array<HTMLElement>): boolean {
  return isArray(array) ? array.some((item: HTMLElement) => item.contains(element)) : false;
}

export function matchesSelectorIfAny(element: HTMLElement, selector?: string): boolean {
  return Boolean(!selector || element?.closest(selector));
}

export function noop(): void {
  return undefined;
}

export function randomBetween(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1) + min);
}
