import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {IPlFormDefinition} from 'pl-comps-angular';
import {EntityRegistryService} from '../../../components/entity/entity.registry.service';
import {IEntity, IEntityState} from '../../../components/entity/entity.definition.interface';
import {ENTITY_NAME_TAXAS_IVA, IJsonTaxasIva} from '../../../entities/taxasIva/taxasIva.entity.interface';
import {EEntityStateDetailType} from '../../../../common/utils/entity.state.utils';

@Component({
  selector: 'taxas-iva',
  templateUrl: './taxasIva.component.html'
})
export class TaxasIvaComponent implements OnChanges, OnInit {
  @Input() public model: IJsonTaxasIva;
  @Input() public type: EEntityStateDetailType;
  @Input() public definition: IPlFormDefinition;
  @Input() public disableCodIva: boolean;
  @Input() public disableDataRef: boolean;

  public readonly stateTypes: typeof EEntityStateDetailType;
  public formDefinition: IPlFormDefinition;

  private readonly _entity: IEntity<IJsonTaxasIva>;

  constructor(private readonly _entityRegistryService: EntityRegistryService) {
    this._entity = this._entityRegistryService.getEntity<IJsonTaxasIva>(ENTITY_NAME_TAXAS_IVA);
    this.stateTypes = EEntityStateDetailType;
  }

  public ngOnInit(): void {
    if (this.disableCodIva && this.formDefinition) {
      this.formDefinition.fields[0].properties = {readonly: true};
    }
    if (this.disableDataRef && this.formDefinition) {
      this.formDefinition.fields[1].properties = {readonly: true};
    }
  }

  public ngOnChanges({type}: SimpleChanges): void {
    if (type) {
      const entityType: IEntityState = this._entity[type.currentValue];
      this.formDefinition = entityType.definition;
    }
  }
}
