import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {FaturacaoPublicaService} from '../../faturacaoPublica.module.service';
import {HttpResponse} from '@angular/common/http';
import {downloadStream, PlAlertService} from 'pl-comps-angular';
import {IJsonFaturacaoPublicaAnexos} from '../../jsonFaturacaoPublica.module.interface';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {TranslateService} from '@ngx-translate/core';

export const EXCEPTION_DIGITAL_SIGN = 'EObjDigitalSign';
export const EXCEPTION_SAFE = 'EObjSAFE';

@Component({
  selector: 'modal-faturacaopublica-observacoes',
  templateUrl: './faturacaoPublica.comunicacaoManual.modal.component.html'
})
export class FaturacaoPublicaComunicacaoManualModalComponent extends CGModalComponent<IJsonFaturacaoPublicaAnexos> implements OnInit {
  @Input() public faccbID: number;
  @Input() public nDoc: string;

  public comunicacaoManual: IJsonFaturacaoPublicaAnexos;
  public promiseDocs: Promise<void>;
  public btnDisabled: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _faturacaoPublicaService: FaturacaoPublicaService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);

    this.comunicacaoManual = {
      fileNameXml: '',
      fileNamePdf: ''
    };
    this.btnDisabled = true;
  }

  public ngOnInit(): void {
    this.promiseDocs = this._faturacaoPublicaService
      .getFilesNames(this.faccbID)
      .then((response: HttpResponse<IJsonFaturacaoPublicaAnexos>) => {
        if (response?.body) {
          this.comunicacaoManual = response.body;
          this.btnDisabled = false;
        }
      })
      .catch((reason) => {
        const exception = this._cgExceptionService.get(reason);
        if (exception) {
          if (exception.class === EXCEPTION_DIGITAL_SIGN || exception.class === EXCEPTION_SAFE) {
            this._plAlertService.error(this._translateService.instant('faturacaopublica.errors.faturacaoeletronicanaoconfigurada'));
          } else {
            this._plAlertService.error(`Doc.: ${this.nDoc}: ${exception.message}`);
          }
        } else {
          this._plAlertService.error(this._translateService.instant('faturacaopublica.errors.errogerarficheiros'));
        }
        this.close();
      });
  }

  public async exportFiles(): Promise<void> {
    const files: Array<[Blob, string]> = await Promise.all([
      this._faturacaoPublicaService.getAnexosAsFile(this.faccbID, 0).then((response: HttpResponse<Blob>) => [response.body, this.comunicacaoManual.fileNamePdf]) satisfies Promise<[Blob, string]>,
      this._faturacaoPublicaService.getAnexosAsFile(this.faccbID, 1).then((response: HttpResponse<Blob>) => [response.body, this.comunicacaoManual.fileNameXml]) satisfies Promise<[Blob, string]>
    ]);
    for (const [file, filename] of files) {
      downloadStream(file, filename);
    }
    const fileBlobPdf: HttpResponse<Blob> = await this._faturacaoPublicaService.getAnexosAsFile(this.faccbID, 0);
    downloadStream(fileBlobPdf.body, this.comunicacaoManual.fileNamePdf);

    const fileBlobXml: HttpResponse<Blob> = await this._faturacaoPublicaService.getAnexosAsFile(this.faccbID, 1);
    downloadStream(fileBlobXml.body, this.comunicacaoManual.fileNameXml);
  }

  public sendMails(): void {
    this.close(this.comunicacaoManual);
  }
}
