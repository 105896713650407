import {ContabilidadeDigitalServiceDocuments} from '../../../services/contabilidadedigital/contabilidadedigital.service.documents';
import {ApiService} from '../../../services/api/api.service';

export class ComercialDigitalServiceDocuments extends ContabilidadeDigitalServiceDocuments {
  constructor(
    protected readonly _apiService: ApiService,
    basePath: string
  ) {
    super(_apiService, basePath);
  }
}
