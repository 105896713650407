<div class="predefinidocontabformula">
  <pl-tooltip [config]="tooltip" *ngIf="options.readonly !== true" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
    <div *plTooltipContent>
      <div class="input-form" [ngClass]="{'input-group pl-input-group': options.raw === false && (hasGroup || showClear), 'input-focus': inputFocused, 'input-disabled': inputElement.disabled}">
        <input
          type="text"
          class="form-control"
          autocapitalize="off"
          autocomplete="off"
          #inputElement
          [formControl]="formControl"
          [attr.name]="attrName"
          [attr.placeholder]="placeholder"
          [class.raw]="options.raw"
          [ngClass]="inputClass"
          (dblclick)="openDialog()"
          [editEventsHandler]="options.events"
          [editEventsHandlerValue]="value"
          editEventsHandlerIgnore="focus,blur"
          (focus)="onInputFocus($event)"
          (blur)="onInputBlur($event)" />
        <button type="button" class="btn hollow-form" tabindex="-1" *ngIf="showClear" [ngClass]="{'collapse-size': !isMouseIn}" (click)="clearViewValue()">
          <i class="pl-icon-close" aria-hidden="true"></i>
        </button>
        <ng-container *ngIf="hasGroup && options.raw !== true">
          <button
            type="button"
            class="btn"
            *ngFor="let itemGroup of itemGroups"
            [ngClass]="itemGroup.klass"
            [class.raw]="options.raw || itemGroup.raw"
            [disabled]="options.disabled || itemGroup.disabled"
            [attr.tabindex]="itemGroup.tabindex"
            [attr.title]="itemGroup.title"
            [plPromise]="itemGroup.promise"
            plDelegateEventDownwards
            #buttonElement>
            <ng-container *ngTemplateOutlet="itemGroup.templateRef; context: {button: buttonElement, isMouseIn: isMouseIn}"></ng-container>
          </button>
        </ng-container>
        <div class="input-group-text" *ngIf="!options.raw">
          <button type="button" class="btn" [disabled]="options.disabled" (click)="openDialog()">
            <i class="fa fa-fw fa-calculator"></i>
          </button>
        </div>
      </div>
    </div>
  </pl-tooltip>
</div>

<pl-messages [instance]="self" [formControlInstance]="formControl" [validate]="validate" [validators]="validators" [ngFormInstance]="ngFormInstance" *ngIf="validate !== false"></pl-messages>

<span class="form-control-align" *ngIf="options.readonly">{{ formControl.value }}</span>
