import {ETipoAcrescimoEncFerias} from './jsonAcrescimosEncargosFerias.module.interface';

export interface ITipoAcrescimoEncFerias {
  valueMetodo: ETipoAcrescimoEncFerias;
  nameMetodo: string;
}

export interface IAcrescimoEncargoComFeriasAnuaisChart {
  caption: string;
  value: number;
}

export interface IAcrescimoEncFeriasPrintHeader {
  deCodEmp: number;
  ateCodEmp: number;
  deCCusto: string;
  ateCCusto: string;
  deReparticao: number;
  ateReparticao: number;
  nEmpresa: string;
  anoEncargo: number;
}

export interface IAcrescimoEncFeriasSingleCallback {
  carregar?(anoEncargo: number): Promise<void>;

  anular?(): Promise<void>;
}

export const MODULE_NAME_ACRESCIMO_ENCARGOS_COM_FERIAS = 'acrescimosencargoscomferias';
