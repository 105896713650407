import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {IJsonDGEMP} from '../../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {EDelphiNumberTypes, isEmpty, PlAlertService} from 'pl-comps-angular';
import {PortalClientAccountService} from '../../../../../../services/portalclientaccount/portalclientaccount.service';
import {HttpResponse} from '@angular/common/http';
import {IRecibosVencimento} from '../../recibosVencimentos.module.interface';

@Component({
  selector: 'modal-recibosVencimentos-email',
  templateUrl: './recibosVencimentos.email.modal.component.html'
})
export class RecibosVencimentosEmailModalComponent extends CGModalComponent<IRecibosVencimento> {
  @Input() public reportName: string;
  @Input() public nProcessamento: number;

  @Input() public dgemp: IJsonDGEMP;
  @Input() public todosColaboradores: boolean;

  public byTargetEmail: boolean;
  public targetEmail: string;

  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _portalClientAccountService: PortalClientAccountService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.byTargetEmail = false;
    this.targetEmail = this._configService.configurations.empresa.email;
  }

  public close(): Promise<void> {
    if (this.byTargetEmail && isEmpty(this.targetEmail)) {
      this._plAlertService.error('recibosvencimentos.email.insiraemail');
      return Promise.resolve();
    }
    const deEmp: number = this.todosColaboradores ? 0 : this.dgemp.codEmp;
    const ateEmp: number = this.todosColaboradores ? EDelphiNumberTypes.MaxSmallInt : this.dgemp.codEmp;
    this.targetEmail = this.byTargetEmail ? this.targetEmail : '';
    this.promise = this._portalClientAccountService.getSendEmailReciboVencimentoPdf(deEmp, ateEmp, this.nProcessamento, this.reportName, this.targetEmail).then((response: HttpResponse<boolean>) => {
      if (!response) {
        this._plAlertService.error('recibosvencimentos.email.emailNaoEnviado');
      } else {
        this._plAlertService.success('recibosvencimentos.email.emailEnviado');
        const recibosVencimento: IRecibosVencimento = {
          dgemp: this.dgemp,
          todosColaboradores: this.todosColaboradores
        };
        super.close(recibosVencimento);
      }
    });
    return this.promise;
  }

  public setEmailAlternativo(): void {
    this.targetEmail = this.byTargetEmail ? this._configService.configurations.empresa.email : '';
  }
}
