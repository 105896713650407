import {ENTITY_NAME_INDICE_FLUXOS} from '../../../entities/indicefluxos/indiceFluxos.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {FluxosComponent} from './components/fluxos.module.component';
import {IJsonPRHIndiceFluxo} from './fluxos.module.interface';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';
import {IEntityService} from '../../../services/entity/entity.service.interface';

export const MODULE_FLUXOS: IModuleDefinition = {
  name: 'colaboradoresfluxos',
  state: {
    url: '/fluxos',
    component: FluxosComponent,
    data: {
      roles: [ROLE.GESTORRH],
      pageTitle: 'global.menu.colaboradoresfluxos'
    },
    resolve: [
      {
        provide: 'indicesFluxos',
        deps: [EntityServiceBuilder],
        useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonPRHIndiceFluxo>> => {
          const servicePRHIndiceFluxos: IEntityService<IJsonPRHIndiceFluxo> = entityServiceBuilder.build<IJsonPRHIndiceFluxo>(ENTITY_NAME_INDICE_FLUXOS);
          return servicePRHIndiceFluxos.query().then((response: THttpQueryResponse<IJsonPRHIndiceFluxo>) => response.body.list);
        }
      }
    ]
  }
};
