import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgbCollapseModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {PlPipesModule} from '../pipes/pipes.module';
import {PlTranslateModule} from '../translate/translate.module';
import {CGCIconDirective} from '../icon';
import {PlNavbarComponent} from './navbar.component';
import {PlNavbarBrandComponent} from './components/navbar.brand.component';
import {PlNavbarSubBrandComponent} from './components/navbar.sub.brand.component';

export * from './navbar.interface';
export * from './navbar.component';
export * from './components/navbar.brand.component';
export * from './components/navbar.sub.brand.component';

const DECLARATIONS = [PlNavbarComponent, PlNavbarBrandComponent, PlNavbarSubBrandComponent];

@NgModule({
  imports: [CommonModule, NgbDropdownModule, NgbCollapseModule, PlPipesModule, PlTranslateModule, CGCIconDirective],
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlNavbarModule {}
