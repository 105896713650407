import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {isObject} from 'pl-comps-angular';
import {ContabilidadeDigitalService} from '../../../../services/contabilidadedigital/contabilidadedigital.service';
import {
  IContabilidadeDigitalTreeViewerEvtActionFile,
  IContabilidadeDigitalTreeViewerEvtActionFolder,
  IContabilidadeDigitalTreeViewerFile,
  IContabilidadeDigitalTreeViewerFolder
} from './contabilidadedigital.treeviewer.component.interface';

@Component({
  selector: 'contabilidadedigital-treeviewer-node',
  templateUrl: './contabilidadedigital.treeviewer.node.component.html'
})
export class ContabilidadeDigitalTreeViewerNodeComponent implements OnChanges {
  @Input() public folderItem: IContabilidadeDigitalTreeViewerFolder;
  @Input() public selectedFolder: IContabilidadeDigitalTreeViewerFolder;
  @Input() public selectedFile: IContabilidadeDigitalTreeViewerFile;
  @Input() public showFiles: boolean;
  @Input() public advancedDescription: boolean;
  @Input() public mobile: boolean;
  @Input() public disableActions: boolean;
  @Input() public disableActionAddDoc: boolean;
  @Input() public disableActionCaptureDoc: boolean;
  @Input() public disableActionDeleteDocs: boolean;
  @Input() public disableActionDeleteDoc: boolean;
  @Input() public showCountFolderFiles: boolean;
  @Output() public readonly evtSelectedFolder: EventEmitter<IContabilidadeDigitalTreeViewerFolder>;
  @Output() public readonly evtFolderCollapseChanged: EventEmitter<IContabilidadeDigitalTreeViewerFolder>;
  @Output() public readonly evtFolderDoubleClicked: EventEmitter<IContabilidadeDigitalTreeViewerFolder>;
  @Output() public readonly evtFileDoubleClicked: EventEmitter<IContabilidadeDigitalTreeViewerFile>;
  @Output() public readonly evtSelectedFile: EventEmitter<IContabilidadeDigitalTreeViewerFile>;
  @Output() public readonly evtActionAddDoc: EventEmitter<IContabilidadeDigitalTreeViewerEvtActionFolder>;
  @Output() public readonly evtActionCaptureDoc: EventEmitter<IContabilidadeDigitalTreeViewerEvtActionFolder>;
  @Output() public readonly evtActionDeleteDocs: EventEmitter<IContabilidadeDigitalTreeViewerEvtActionFolder>;
  @Output() public readonly evtActionDeleteDoc: EventEmitter<IContabilidadeDigitalTreeViewerEvtActionFile>;

  public allowSendFiles: boolean;
  public allowDeleteFiles: boolean;

  constructor(private readonly _contabilidadeDigitalService: ContabilidadeDigitalService) {
    this.evtSelectedFolder = new EventEmitter<IContabilidadeDigitalTreeViewerFolder>();
    this.evtFolderCollapseChanged = new EventEmitter<IContabilidadeDigitalTreeViewerFolder>();
    this.evtFolderDoubleClicked = new EventEmitter<IContabilidadeDigitalTreeViewerFolder>();
    this.evtFileDoubleClicked = new EventEmitter<IContabilidadeDigitalTreeViewerFile>();
    this.evtSelectedFile = new EventEmitter<IContabilidadeDigitalTreeViewerFile>();
    this.evtActionAddDoc = new EventEmitter<IContabilidadeDigitalTreeViewerEvtActionFolder>();
    this.evtActionCaptureDoc = new EventEmitter<IContabilidadeDigitalTreeViewerEvtActionFolder>();
    this.evtActionDeleteDocs = new EventEmitter<IContabilidadeDigitalTreeViewerEvtActionFolder>();
    this.evtActionDeleteDoc = new EventEmitter<IContabilidadeDigitalTreeViewerEvtActionFile>();
    this.showFiles = true;
    this.advancedDescription = false;
    this.mobile = false;
    this.disableActions = false;
    this.disableActionAddDoc = false;
    this.disableActionCaptureDoc = false;
    this.disableActionDeleteDocs = false;
    this.disableActionDeleteDoc = false;
    this.allowSendFiles = false;
    this.allowDeleteFiles = false;
    this.showCountFolderFiles = false;
  }

  public ngOnChanges({folderItem}: SimpleChanges): void {
    if (folderItem) {
      const folder: IContabilidadeDigitalTreeViewerFolder = folderItem.currentValue;
      this.allowSendFiles = isObject(folder) && !folder.hasSubFolders && !this._contabilidadeDigitalService.isFolderFromDocsClassificados(folder);
      this.allowDeleteFiles = this.allowSendFiles && (folder.hasSubFiles || folder.files.length > 0);
    }
  }

  public selectFolder(folderItem: IContabilidadeDigitalTreeViewerFolder): void {
    if (this.selectedFile) {
      this.selectedFile = undefined;
    }
    this.selectedFolder = folderItem;
    this.evtSelectedFolder.emit(this.selectedFolder);
  }

  public selectFile(fileItem: IContabilidadeDigitalTreeViewerFile): void {
    if (this.selectedFolder) {
      this.selectedFolder = undefined;
    }
    this.selectedFile = fileItem;
    this.evtSelectedFile.emit(this.selectedFile);
  }

  public toggleFolderItemCollapse(folderItem: IContabilidadeDigitalTreeViewerFolder): void {
    this.evtFolderCollapseChanged.emit(folderItem);
  }

  public folderDoubleClicked(folderItem: IContabilidadeDigitalTreeViewerFolder): void {
    this.evtFolderDoubleClicked.emit(folderItem);
  }

  public fileDoubleClicked(fileItem: IContabilidadeDigitalTreeViewerFile): void {
    this.evtFileDoubleClicked.emit(fileItem);
  }

  public changedSelectedFolder(folderItem: IContabilidadeDigitalTreeViewerFolder): void {
    this.selectFolder(folderItem);
  }

  public changedFolderItemCollapse(folderItem: IContabilidadeDigitalTreeViewerFolder): void {
    this.evtFolderCollapseChanged.emit(folderItem);
  }

  public changedSelectedFile(fileItem: IContabilidadeDigitalTreeViewerFile): void {
    this.selectFile(fileItem);
  }

  public actionAdd(event: MouseEvent, folderItem: IContabilidadeDigitalTreeViewerFolder): void {
    if (folderItem.promise) {
      return;
    }
    event.stopPropagation();
    if (folderItem !== this.selectedFolder) {
      this.selectFolder(folderItem);
    }
    this.evtActionAddDoc.emit({event: event, folder: folderItem});
  }

  public actionCapture(event: MouseEvent, folderItem: IContabilidadeDigitalTreeViewerFolder): void {
    if (folderItem.promise) {
      return;
    }
    event.stopPropagation();
    if (folderItem !== this.selectedFolder) {
      this.selectFolder(folderItem);
    }
    this.evtActionCaptureDoc.emit({event: event, folder: folderItem});
  }

  public actionDeleteDocs(event: MouseEvent, folderItem: IContabilidadeDigitalTreeViewerFolder): void {
    if (folderItem.promise) {
      return;
    }
    event.stopPropagation();
    if (folderItem !== this.selectedFolder) {
      this.selectFolder(folderItem);
    }
    this.evtActionDeleteDocs.emit({event: event, folder: folderItem});
  }

  public actionDeleteDoc(event: MouseEvent, fileItem: IContabilidadeDigitalTreeViewerFile): void {
    if (fileItem !== this.selectedFile) {
      this.selectFile(fileItem);
    }
    this.evtActionDeleteDoc.emit({event: event, file: fileItem});
  }
}
