import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ICGModalOptions} from '../../../cg/modal/cgmodal.interface';
import {ICGStateDeclaration} from '../../../../services/portals/portals.service.interface';
import {IModule} from '../../../module/module.definition.interface';
import {RESOLVER_CONFIGURATIONS} from '../../../../services/config/config.service.router';
import {RESOLVER_LAUNCH_MODE} from '../../../../../config/uirouter/uirouter.resolvers';

export interface IModuleMaintenanceInstance<T = void> {
  maintenanceHeader: string;
  maintenanceAllow: boolean;
  stateMaintenance: ICGStateDeclaration;
  module: IModule;
  moduleName: string;

  maintenanceRaw(options?: Partial<IModuleMaintenanceModalOptions<T>>): NgbModalRef;

  maintenance(options?: Partial<IModuleMaintenanceModalOptions<T>>): Promise<T>;
}

export interface IModuleMaintenanceModalOptions<T> {
  params?: object;
  preventDocumentKeyup?: boolean;
  closeOnSaved?: boolean;
  modalOptions?: ICGModalOptions<T>;
}

export const MODULE_MAINTENANCE_IGNORED_RESOLVE_PROVIDERS: ReadonlyArray<string> = Object.freeze([RESOLVER_CONFIGURATIONS.provide, RESOLVER_LAUNCH_MODE.provide]);
