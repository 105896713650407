export enum EGrupoDoc {
  Outros,
  ComprasEfectivas,
  ConsultasAFornecedores,
  EncomendasClientes,
  EncomendasFornecedores,
  FolhasDeObra,
  GuiasTransporteRemessa,
  PropostasAClientes,
  ReservasClientes,
  TrfArmazensEntradas,
  TrfArmazensSaidas,
  VendasEfectivas,
  ConsignacoesClientes,
  ConsignacoesFornecedores,
  RecibosClientes,
  PagamentosFornecedores,
  None,
  PlanoProducao,
  PreEncomendasClientes,
  CustosAdicionais,
  NotaCredito, // Diogo Simões -> Este GRUPO não existe
  AcertoCustoPadrao,
  EntradasDiversas,
  SaidasDiversas,
  GuiasTransporteFornecedores
}

export enum EGrupoDocType {
  Venda = 1,
  Compra = 2,
  Outros = 3,
  EntradasDiversas = 4,
  SaidasDiversas = 5
}

export interface IGrupoDoc {
  readonly id: EGrupoDoc;
  readonly nome: string;
  readonly tipo: EGrupoDocType;
}

export const DATA_SOURCE_NAME_GRUPO_DOC = 'grupoDoc';

export function grupoDocToDocType(grupoDoc: EGrupoDoc): EGrupoDocType {
  switch (grupoDoc) {
    case EGrupoDoc.Outros:
      return EGrupoDocType.Outros;
    case EGrupoDoc.ComprasEfectivas:
      return EGrupoDocType.Compra;
    case EGrupoDoc.ConsultasAFornecedores:
      return EGrupoDocType.Outros;
    case EGrupoDoc.EncomendasClientes:
      return EGrupoDocType.Venda;
    case EGrupoDoc.EncomendasFornecedores:
      return EGrupoDocType.Compra;
    case EGrupoDoc.FolhasDeObra:
      return EGrupoDocType.Venda;
    case EGrupoDoc.GuiasTransporteRemessa:
      return EGrupoDocType.Venda;
    case EGrupoDoc.PropostasAClientes:
      return EGrupoDocType.Venda;
    case EGrupoDoc.ReservasClientes:
      return EGrupoDocType.Venda;
    case EGrupoDoc.TrfArmazensEntradas:
      return EGrupoDocType.Outros;
    case EGrupoDoc.TrfArmazensSaidas:
      return EGrupoDocType.Outros;
    case EGrupoDoc.VendasEfectivas:
      return EGrupoDocType.Venda;
    case EGrupoDoc.ConsignacoesClientes:
      return EGrupoDocType.Venda;
    case EGrupoDoc.ConsignacoesFornecedores:
      return EGrupoDocType.Compra;
    case EGrupoDoc.RecibosClientes:
      return EGrupoDocType.Venda;
    case EGrupoDoc.PagamentosFornecedores:
      return EGrupoDocType.Compra;
    case EGrupoDoc.None:
      return EGrupoDocType.Outros;
    case EGrupoDoc.PlanoProducao:
      return EGrupoDocType.Outros;
    case EGrupoDoc.PreEncomendasClientes:
      return EGrupoDocType.Venda;
    case EGrupoDoc.CustosAdicionais:
      return EGrupoDocType.Venda;
    case EGrupoDoc.NotaCredito:
      return EGrupoDocType.Venda;
    case EGrupoDoc.AcertoCustoPadrao:
      return EGrupoDocType.Outros;
    case EGrupoDoc.EntradasDiversas:
      return EGrupoDocType.EntradasDiversas;
    case EGrupoDoc.SaidasDiversas:
      return EGrupoDocType.SaidasDiversas;
    case EGrupoDoc.GuiasTransporteFornecedores:
      return EGrupoDocType.Compra;
    default:
      throw new Error(`Provided grupoDoc is invalid: ${String(grupoDoc)}`);
  }
}
