import {Component, Injector, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'modal-concilmovab-progressbar',
  templateUrl: './concilMovAb.progressBar.modal.component.html'
})
export class ConcilMovAbProgressBarModalComponent extends CGModalComponent<void> implements OnChanges {
  @Input() public pbProcLabel: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.pbProcLabel = '';
  }

  public ngOnChanges({pbProcLabel}: SimpleChanges): void {
    if (pbProcLabel) {
      this.pbProcLabel = pbProcLabel.currentValue;
    }
  }
}
