import {Component, ElementRef, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {isDefinedNotNull, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {EFacPublicaModelo} from '../../../../faturacaopublica/jsonFaturacaoPublica.module.interface';
import {ENTITY_NAME_CLIENTES, ENTITY_NAME_FORNECEDORES, IClifo, IClifosEntityService} from '../../../../../entities/clifos/clifos.entity.interface';
import {ENTITY_NAME_NATUREZAS_CONTAB} from '../../../../../entities/naturezascontab/naturezasContab.entity.interface';
import {EntityMaintenanceService} from '../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {ExternalNifsService} from '../../../../../services/externalnifs/externalnifs.service';
import {focusElement} from '../../../../../../common/utils/element.utils';
import {IEntityMaintenanceInstance} from '../../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonClifo} from '../../../../../entities/clifos/jsonClifo.entity.interface';
import {IJsonExternalNif} from '../../../../../services/externalnifs/externalnifs.interface';
import {IJsonNaturezaContab} from '../../../../../entities/naturezascontab/jsonNaturezaContab.entity.interface';
import {THttpQueryResponse} from '../../../../../services/api/api.service.interface';

const DEFAULT_N_DIGITOS_SEQUENCIA = 4;

@Component({
  selector: 'at-frv-novacontacc-modal',
  templateUrl: './atFaturasRecibosVerdes.novaContaCorrente.modal.html'
})
export class AtFaturasRecibosVerdesNovaContaCorrenteModalComponent extends CGModalComponent<string> implements OnInit {
  @Input() public nif: string;
  @Input() public nome: string;

  public readonly model: IClifo;
  public info: string;
  public promise: Promise<void>;

  private readonly _serviceNaturezasContab: IEntityService<IJsonNaturezaContab>;
  private _serviceFornecedores: IClifosEntityService;
  private _maintenanceFornecedores: IEntityMaintenanceInstance;
  private _radical: string;
  private _elementNaturezasContab: HTMLElement;
  private _elementActionSubmit: HTMLElement;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _externalNifsService: ExternalNifsService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.model = {
      id: undefined,
      abrevFiscal: undefined,
      codCondComerciais: undefined,
      codPagamento: undefined,
      codPais: undefined,
      codPostal: undefined,
      codRet: undefined,
      email: undefined,
      fax: undefined,
      iban: undefined,
      localidade: undefined,
      lugar: undefined,
      nConta: undefined,
      nContribuint: undefined,
      nIdAltern: undefined,
      nome: undefined,
      nomeCondComerciais: undefined,
      nomePagamento: undefined,
      nomePais: undefined,
      observacoes: undefined,
      observacoes2: undefined,
      rua: undefined,
      segNome: undefined,
      situacaoActual: undefined,
      telefone: undefined,
      facPublicaModelo: EFacPublicaModelo.ND,
      naturezaContab: {
        idGrContaCorrente: undefined,
        idSubGrContaCorrente: undefined,
        descricao: undefined,
        radical: undefined,
        nDigitosSequencia: undefined,
        tipoContaCorrente: undefined
      },
      telemovel: undefined,
      nomeRet: undefined
    };
    this._serviceNaturezasContab = this._entityServiceBuilder.build<IJsonNaturezaContab>(ENTITY_NAME_NATUREZAS_CONTAB);
  }

  public ngOnInit(): void {
    if (this.nif) {
      this.promise = Promise.all([this._externalNifsService.get(this.nif), this._serviceNaturezasContab.query({pesquisa: `clifoType=${ENTITY_NAME_CLIENTES}`})])
        .then(([responseNif, responseNaturezaContab]: [IJsonExternalNif, THttpQueryResponse<IJsonNaturezaContab>]) => {
          this.info = this.nif;
          if (isDefinedNotNull(responseNif)) {
            if (responseNif.nif) {
              this.model.nContribuint = responseNif.nif;
            }
            if (responseNif.entidade) {
              this.model.nome = responseNif.entidade;
              this.info += ` - ${this.model.nome}`;
            }
          } else {
            this.model.nContribuint = this.nif;
            this.model.nome = this.nome;
          }
          if (responseNaturezaContab.body.list.length) {
            this.model.naturezaContab = responseNaturezaContab.body.list[0];
          } else {
            this.model.naturezaContab = {
              ...this.model.naturezaContab,
              radical: this.configurations.clientesFornecedores.radicalFornecedores,
              nDigitosSequencia: DEFAULT_N_DIGITOS_SEQUENCIA
            };
          }
          this.changedNaturezaContabilistica(this.model.naturezaContab);
          return Promise.resolve(this.promise).then(() => {
            if (this.model.naturezaContab && this.model.nContribuint && this.model.nome) {
              focusElement(this._elementActionSubmit);
            } else {
              focusElement(this._elementNaturezasContab);
            }
          });
        })
        .catch((reason: unknown) => {
          this._logger.error(reason);
          this.info = this.nif;
        });
    }
  }

  public changedNaturezaContabilistica(value: IJsonNaturezaContab): void {
    this.model.naturezaContab = value;
    if (this.model.naturezaContab) {
      this._radical = this.model.naturezaContab.radical;
      this.promise = this._service()
        .proximaconta(this._radical, this.model.naturezaContab.nDigitosSequencia)
        .then((response: HttpResponse<string>) => {
          this.model.nConta = response.body;
        });
    } else {
      this._radical = undefined;
    }
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._service()
      .post({body: this.model})
      .then((response: HttpResponse<IJsonClifo>) => {
        const title: string = this._plTranslateService.translate('atFRV.novacontaccmodal.successModal.title');
        const message: string = this._plTranslateService.translate('atFRV.novacontaccmodal.successModal.message');
        return this._cgModalService
          .showOkCancel(title, message, {
            btnOkIcon: 'fa-pencil-square-o',
            btnOkText: 'global.btn.edit',
            btnCancelIcon: 'fa-floppy-o',
            btnCancelText: 'global.btn.save'
          })
          .then(() => {
            return this._maintenance()
              .maintenanceEdit(response.body.id)
              .then(() => undefined)
              .finally(() => {
                this.enableClose();
                super.close(this.model.nome);
              });
          })
          .catch(() => {
            this.enableClose();
            super.close(this.model.nome);
          });
      })
      .catch((reason: HttpErrorResponse) => {
        this._logger.error(reason);
        this.enableClose();
      });
  }

  @ViewChild('elementNaturezasContab', {read: ElementRef})
  public set elementNaturezasContab(value: ElementRef<HTMLElement>) {
    this._elementNaturezasContab = value?.nativeElement;
  }

  @ViewChild('elementActionSubmit', {read: ElementRef})
  public set elementActionSubmit(value: ElementRef<HTMLElement>) {
    this._elementActionSubmit = value?.nativeElement;
  }

  private _service(): IClifosEntityService {
    if (!this._serviceFornecedores) {
      this._serviceFornecedores = this._entityServiceBuilder.build<IJsonClifo, IClifosEntityService>(ENTITY_NAME_FORNECEDORES);
    }
    return this._serviceFornecedores;
  }

  private _maintenance(): IEntityMaintenanceInstance {
    if (!this._maintenanceFornecedores) {
      this._maintenanceFornecedores = this._entityMaintenanceService.build(ENTITY_NAME_FORNECEDORES);
    }
    return this._maintenanceFornecedores;
  }
}
