import {deepFreeze, EDateMonth} from 'pl-comps-angular';
import {TDate} from '../../../../common/dates';
import {IRadioGroup} from '../../../../common/interfaces/interfaces';

export enum EAtivosMapasOficiaisMetodo {
  QuotasConstantes = 0,
  QuotasDecrescentes = 1,
  Qualquer = 2
}

export enum EAtivosMapasOficiaisEstadoUso {
  Indiferente = 0,
  Usado = 1,
  Novo = 2
}

export interface IAtivosMapasOficiais {
  nMapa: string;
  reportName: string;
  anoEconomico: number;
  mes: EDateMonth;
  codAtivoDe: number;
  codAtivoAte: number;
  nContaDe: string;
  nContaAte: string;
  nCCustoDe: string;
  nCCustoAte: string;
  codAmortDe: string;
  codAmortAte: string;
  normal: boolean;
  abatido: boolean;
  vendido: boolean;
  dataAquisicaoDe: TDate;
  dataAquisicaoAte: TDate;
  dataAbateDe: TDate;
  dataAbateAte: TDate;
  natureza: number;
  metodo: EAtivosMapasOficiaisMetodo;
  tipoExportacao: number;
  radioAll: boolean;
  estadouso: EAtivosMapasOficiaisEstadoUso;
}

export const RADIO_GROUP_ATIVOS_MAPAS_OFICIAIS_METODO: IRadioGroup<EAtivosMapasOficiaisMetodo> = deepFreeze<IRadioGroup<EAtivosMapasOficiaisMetodo>>({
  groupItems: [
    {value: EAtivosMapasOficiaisMetodo.Qualquer, label: 'ativosmapasoficiais.radioOptions.qualquer'},
    {value: EAtivosMapasOficiaisMetodo.QuotasConstantes, label: 'ativosmapasoficiais.radioOptions.quotasConstantes'},
    {value: EAtivosMapasOficiaisMetodo.QuotasDecrescentes, label: 'ativosmapasoficiais.radioOptions.quotasDecrescentes'}
  ]
});

export const RADIO_GROUP_ATIVOS_MAPAS_OFICIAIS_ESTADO_USO: IRadioGroup<EAtivosMapasOficiaisEstadoUso> = deepFreeze<IRadioGroup<EAtivosMapasOficiaisEstadoUso>>({
  groupItems: [
    {value: EAtivosMapasOficiaisEstadoUso.Indiferente, label: 'ativosmapasoficiais.radioOptions.indiferente'},
    {value: EAtivosMapasOficiaisEstadoUso.Usado, label: 'ativosmapasoficiais.radioOptions.usado'},
    {value: EAtivosMapasOficiaisEstadoUso.Novo, label: 'ativosmapasoficiais.radioOptions.novo'}
  ]
});

export const MODULE_NAME_ATIVOS_MAPAS_OFICIAIS = 'ativosmapasoficiais';
