import {HttpResponse} from '@angular/common/http';
import {DATA_SOURCE_TIPOS_DECLARACAO} from '../../datasources/tiposdeclaracao/tiposDeclaracao.datasource';
import {ENTITY_NAME_RENDIS, IRendisEntity, IRendisEntityService} from './rendis.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {IJsonDeclaracao, IJsonRENDI} from './jsonRendi.entity.interface';
import {RendisEditComponent} from './component/edit/rendis.entity.edit.component';
import {ROLE} from '../../services/role.const';

export const ENTITY_RENDI: IRendisEntity = {
  name: ENTITY_NAME_RENDIS,
  roles: [ROLE.RH],
  searchPlaceholder: 'rendis.pesquisa',
  metadata: {
    keyName: 'tipRendimentID',
    fields: [
      {name: 'tipRendiment', type: 'text', caption: 'rendis.fields.tipRendiment', validators: {required: true, maxlength: 5}},
      {name: 'nome', type: 'text', caption: 'rendis.fields.nome', validators: {required: true, maxlength: 240}},
      {
        name: 'tipoDeclaracao',
        type: 'number',
        caption: 'rendis.fields.tipoDeclaracao',
        align: 'left',
        properties: {
          devExpress: {
            dataGrid: {
              lookup: {
                dataSource: DATA_SOURCE_TIPOS_DECLARACAO.data,
                valueExpr: 'value',
                displayExpr: 'name'
              }
            }
          }
        }
      },
      {name: 'tipRendimentID', type: 'cginteger', visible: false}
    ],
    order: 'tipRendiment',
    searchFields: 'tipRendiment,nome',
    listFields: 'tipRendiment,nome,tipoDeclaracao'
  },
  autocomplete: {
    rowTemplate: '{{tipRendiment}} - {{nome}}',
    output: 'tipRendiment',
    searchFields: 'tipRendiment,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true
  },
  detail: {
    state: {
      component: RendisEditComponent,
      resolve: [
        {
          provide: 'tiposDeclaracao',
          deps: [EntityServiceBuilder],
          useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonDeclaracao>> => {
            const service: IRendisEntityService = entityServiceBuilder.build<IJsonRENDI, IRendisEntityService>(ENTITY_NAME_RENDIS);
            return service.getDeclaracoesList().then((response: HttpResponse<Array<IJsonDeclaracao>>) => response.body);
          }
        }
      ]
    }
  },
  service: function () {
    this.getDeclaracoesList = () => {
      return this.get<Array<IJsonDeclaracao>>({id: 'declaracoeslist'});
    };
  }
};
