<div class="modal-header">
  <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'gestaodgemps.ferias.marcarmodal.title'"></h4>
</div>

<div class="modal-body">
  <div [translate]="'gestaodgemps.ferias.marcarmodal.message'"></div>
  <div>{{ textMarcarDate }}</div>

  <pl-form>
    <pl-group>
      <label><span [translate]="'gestaodgemps.fields.allDay'"></span>:</label>
      <edit>
        <pl-edit type="boolean" [(model)]="allDay" attrName="allDay"></pl-edit>
      </edit>
    </pl-group>

    <pl-group *ngIf="!allDay">
      <pl-group>
        <label><span [translate]="'gestaodgemps.fields.horaInicio'"></span>:</label>
        <edit>
          <pl-edit-timepicker [(model)]="horaInicio" attrName="horaInicio"></pl-edit-timepicker>
        </edit>
      </pl-group>

      <pl-group>
        <label><span [translate]="'gestaodgemps.fields.horaFim'"></span>:</label>
        <edit>
          <pl-edit-timepicker [(model)]="horaFim" attrName="horaFim"></pl-edit-timepicker>
        </edit>
      </pl-group>
    </pl-group>
  </pl-form>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary action-submit" [disabled]="closeDisabled" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.mark'"></span></pl-button>

  <pl-button klass="btn-light action-cancel" [disabled]="closeDisabled" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
</div>
