<div class="procseguradorafolhaferias">
  <pl-nav-wizard [definition]="definition" [beforeStepChange]="fnBeforeStepChange" [onFinalize]="fnFinalize" (evtStepChange)="stepChanged($event)" (modelChange)="onStepChange($event)">
    <pl-nav-wizard-step caption="procseguradorafolhaferias.steps.introducao" icon="fa-home">
      <div *plNavWizardStepContent>
        <h4><label [translate]="'procseguradorafolhaferias.steps.title.titleintro'"></label></h4>
        <p [translate]="'procseguradorafolhaferias.steps.title.titleintro'"></p>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step caption="procseguradorafolhaferias.steps.empresas" icon="fa-th-list">
      <pl-form *plNavWizardStepContent (formInstanceChange)="formEmpresas = $event">
        <h5><label [translate]="'procseguradorafolhaferias.steps.title.titleempresas'"></label></h5>
        <hr />
        <div class="item-group-dropdown" tabindex="-1" ngbDropdown container="body" [placement]="['right-bottom', 'bottom-right', 'right', 'auto']">
          <button type="button" class="btn btn-sm btn-info dropdown-toggle" ngbDropdownToggle>
            <span [translate]="'procseguradorafolhaferias.btn.ordenar'" [translateParams]="{orderby: orderBySelected}" class="caret"></span>
          </button>

          <div class="dropdown-menu" ngbDropdownMenu>
            <button type="button" class="dropdown-item" [translate]="'procseguradorafolhaferias.btn.dropdown.sortEmpresasByNEmpresa'" (click)="sortEmpresasByNEmpresa()"></button>
            <button type="button" class="dropdown-item" [translate]="'procseguradorafolhaferias.btn.dropdown.sortEmpresasByName'" (click)="sortEmpresasByName()"></button>
          </div>
        </div>
        <pl-multiselect class="tab-empresas" [model]="empresas" [source]="empresasSource" key="nEmpresa" [template]="empresasTemplate"></pl-multiselect>
      </pl-form>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step caption="procseguradorafolhaferias.steps.configuracao" icon="fa-cog">
      <pl-form *plNavWizardStepContent (formInstanceChange)="formConfiguracao = $event">
        <h5><label [translate]="'procseguradorafolhaferias.steps.title.titleconfig'"></label></h5>
        <hr />
        <div class="row">
          <div class="col-md-3">
            <div class="panel panel-warning">
              <div class="year-navi-container">
                <button type="button" class="btn btn-light btn-sm left" (click)="onDecYearClick()"><i class="fa fa-angle-left" aria-hidden="true"></i></button>
                <input class="form-control year-input" type="number" [ngModel]="selectedYear" (ngModelChange)="onMultiYearChange($event)" />
                <button type="button" class="btn btn-light btn-sm right" (click)="onIncYearClick()"><i class="fa fa-angle-right" aria-hidden="true"></i></button>
              </div>
            </div>
            <pl-table
              [definition]="multiDatasTableDef"
              [source]="fnMultiDatasTableSource"
              [callback]="multiDatasTableCallback"
              [onSelect]="fnMultiDatasTableOnSelect"
              [properties]="{perPage: 12, suppressEmptyLines: true, hidePagination: true}">
            </pl-table>
          </div>
          <div class="col-md-9">
            <dx-data-grid
              [cgDxDataGrid]="dataGridDefinitionProcExistentes"
              cgDxDataGridInstanceName="procExistentesTable"
              [dataSource]="dataGridDefinitionProcExistentes.dataSource"
              (selectedRowKeysChange)="selectedRowKeys = $event"
              (onInitialized)="onInitializedProcExistentes($event)">
            </dx-data-grid>
          </div>
        </div>
      </pl-form>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step caption="procseguradorafolhaferias.steps.relatorio" icon="fa-flag-checkered">
      <pl-form *plNavWizardStepContent (formInstanceChange)="formRelatorio = $event">
        <pl-tabs [(activeId)]="activeId">
          <pl-tab id="relatorio" caption="procseguradorafolhaferias.steps.relatorio">
            <div *plTabContent>
              <h5><label [translate]="'procseguradorafolhaferias.steps.title.titlerelatorio'"></label></h5>

              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionFFSeguradora" cgDxDataGridInstanceName="ffSeguradoraTable" [dataSource]="dataGridDefinitionFFSeguradora.dataSource">
                <div *dxTemplate="let item of 'toolbarTemplateFFSeguradora'">
                  <pl-toolbar [instanceId]="toolbarInstIdProcResults"></pl-toolbar>
                </div>
                <div *dxTemplate="let item of 'cellTemplateEmail'">
                  <pl-inline-edit type="text" [(model)]="item.data.email" [properties]="{placeholder: 'procseguradorafolhaferias.fields.email.placeholder'}"></pl-inline-edit>
                </div>
                <div *dxTemplate="let item of 'cellTemplateBtn'">
                  <pl-button type="button" klass="btn btn-primary btn-xs" (evtClicked)="enviaEmailClick(item.data)" [tooltip]="{text: 'procseguradorafolhaferias.btn.enviaEmail'}">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                  </pl-button>
                  &nbsp;
                  <pl-button type="button" klass="btn btn-info btn-xs" (evtClicked)="viewInfoClick(item.data)">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                  </pl-button>
                </div>
              </dx-data-grid>
            </div>
          </pl-tab>

          <pl-tab id="erros" caption="procseguradorafolhaferias.steps.title.erros" [disabled]="!procSeguradoraFolhaFeriasOuErros.erros.length">
            <div *plTabContent>
              <h5><label [translate]="'procseguradorafolhaferias.steps.title.errossegSocial'"></label></h5>
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionErros" cgDxDataGridInstanceName="errosTable" [dataSource]="dataGridDefinitionErros.dataSource"></dx-data-grid>
            </div>
          </pl-tab>
        </pl-tabs>
      </pl-form>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
