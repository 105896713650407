import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {ProceSalariosComponent} from './components/proceSalarios.module.component';
import {MODULE_NAME_PROCE_SALARIOS} from './proceSalarios.module.interface';

export const MODULE_PROCE_SALARIOS: IModuleDefinition = {
  name: MODULE_NAME_PROCE_SALARIOS,
  state: {
    url: `/${MODULE_NAME_PROCE_SALARIOS}`,
    component: ProceSalariosComponent,
    data: {
      roles: [ROLE.RH],
      sidebarTitle: 'portals.sidebar.modules.proceSalarios'
    }
  }
};
