<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'trdocconfig.title'"></h4>
  </div>

  <div class="modal-body">
    <pl-group>
      <label [translate]="'trdocconfig.fields.nDocfaDestino'"></label>
      <edit>
        <entity-autocomplete
          entity="docfas"
          attrName="nDocfaDestino"
          [model]="docFa"
          (modelChange)="onDocFaChanged($event)"
          [output]="docfasOutput"
          outputKey="nDocFa"
          outputDescription="nome"
          plAutoFocus>
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <label [translate]="'trdocconfig.fields.nNumerDestino'"></label>
      <edit>
        <entity-autocomplete
          attrName="nNumerDestino"
          entity="docfasnums"
          [filter]="docfaNumFilter"
          [model]="model.nNumerDestino"
          [output]="'nNumer'"
          [outputKey]="'nNumer'"
          [(selectedKey)]="model.nNumerDestino">
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group [properties]="{readonly: model.dataDocOrigem === true}">
      <label [translate]="'trdocconfig.fields.dataDoc'"></label>
      <edit>
        <pl-edit-datepicker [(model)]="model.dataDoc" [attrName]="'dataDoc'"></pl-edit-datepicker>
      </edit>
    </pl-group>

    <pl-group>
      <pl-edit-checkbox [(model)]="model.dataDocOrigem" [properties]="{label: 'trdocconfig.fields.dataDocOrigem'}" [attrName]="'dataDocOrigem'"></pl-edit-checkbox>
    </pl-group>

    <pl-group [properties]="{readonly: model.dataVencCondVenda === true}">
      <label [translate]="'trdocconfig.fields.dataVenc'"></label>
      <edit>
        <pl-edit-datepicker [(model)]="model.dataVenc" [attrName]="'dataVenc'"></pl-edit-datepicker>
      </edit>
    </pl-group>

    <pl-group>
      <pl-edit-checkbox [(model)]="model.dataVencCondVenda" [properties]="{label: 'trdocconfig.fields.dataVencCondVenda'}" [attrName]="'dataVencCondVenda'"></pl-edit-checkbox>
    </pl-group>

    <pl-group [properties]="{readonly: model.nFactFornecOrigem === true}">
      <label [translate]="'trdocconfig.fields.nFactFornec'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.nFactFornec" [attrName]="'nFactFornec'"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <pl-edit-checkbox [(model)]="model.nFactFornecOrigem" [properties]="{label: 'trdocconfig.fields.nFactFornecOrigem'}" [attrName]="'nFactFornecOrigem'"></pl-edit-checkbox>
    </pl-group>

    <pl-group [properties]="{readonly: model.dataDocExternoOrigem === true}">
      <label [translate]="'trdocconfig.fields.dataDocExterno'"></label>
      <edit>
        <pl-edit-datepicker [(model)]="model.dataDocExterno" [attrName]="'dataDocExterno'"></pl-edit-datepicker>
      </edit>
    </pl-group>

    <pl-group>
      <pl-edit-checkbox [(model)]="model.dataDocExternoOrigem" [properties]="{label: 'trdocconfig.fields.dataDocExternoOrigem'}" [attrName]="'dataDocExternoOrigem'"></pl-edit-checkbox>
    </pl-group>

    <pl-group [properties]="{readonly: model.nRefProcessoOrigem === true}">
      <label [translate]="'trdocconfig.fields.nRefProcesso'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.nRefProcesso" [attrName]="'nRefProcesso'"></pl-edit>
      </edit>
    </pl-group>

    <pl-group>
      <pl-edit-checkbox [(model)]="model.nRefProcessoOrigem" [properties]="{label: 'trdocconfig.fields.nRefProcessoOrigem'}" [attrName]="'nRefProcessoOrigem'"></pl-edit-checkbox>
    </pl-group>

    <pl-group [properties]="{readonly: model.nArmazemOrigem === true}">
      <label [translate]="'trdocconfig.fields.nArmazem'"></label>
      <edit>
        <entity-autocomplete entity="armazens" attrName="nArmazem" [model]="armazem" (modelChange)="onArmazemChanged($event)" [output]="armazensOutput" outputKey="nArmazem" outputDescription="nome">
        </entity-autocomplete>
      </edit>
    </pl-group>

    <pl-group>
      <pl-edit-checkbox [(model)]="model.nArmazemOrigem" [properties]="{label: 'trdocconfig.fields.nArmazemOrigem'}" [attrName]="'nArmazemOrigem'"></pl-edit-checkbox>
    </pl-group>

    <pl-group>
      <pl-group>
        <pl-edit-checkbox [(model)]="model.encerraDocumentoOrigem" [properties]="{label: 'trdocconfig.fields.encerraDocumentoOrigem'}" [attrName]="'encerraDocumentoOrigem'"></pl-edit-checkbox>
      </pl-group>

      <pl-group>
        <pl-edit-checkbox [(model)]="model.encerraDocumentoDestino" [properties]="{label: 'trdocconfig.fields.encerraDocumentoDestino'}" [attrName]="'encerraDocumentoDestino'"></pl-edit-checkbox>
      </pl-group>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-primary btn-sm action-transformar-doc" [click]="close" plPromise data-focus>
      <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'trdocconfig.saveTitle'"></span>
    </button>

    <button id="btnCancel" type="button" class="btn btn-light action-dismiss" (click)="dismiss()">
      <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'trdocconfig.actions.cancel'"></span>
    </button>
  </div>
</pl-form>
