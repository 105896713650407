import {EDMRFRubrica} from '../../../datasources/dmrfrubrica/dmrfRubrica.datasource.interface';
import {EDMRFZona} from '../../../datasources/dmrfzona/dmrfZona.datasource.interface';

export enum EDMRFState {
  Inactive = 'dmrfsInactive',
  Scheduled = 'dmrfsScheduled',
  Started = 'dmrfsStarted',
  Ended = 'dmrfsEnded',
  Timeout = 'dmrfsTimeout',
  Error = 'dmrfsError'
}

export interface IDMRFItemBase {
  dmrfId: string;
  periodo: string;
  nContrib: string;
  nDocExterno: string;
  rubrica: EDMRFRubrica;
  zona: EDMRFZona;
  valorRetencao: number;
  frestran: boolean;
}

export interface IDMRFItem extends IDMRFItemBase {
  codRet: string;
  nLanc: string;
}

export interface IDMRFSummaryItem {
  zona: string;
  rubrica: string;
  valor: number;
}

export interface IDMRFLoadResults {
  data: Array<IDMRFItem>;
  summary: Array<IDMRFSummaryItem>;
}

export interface IDMRFUI {
  periodo: string;
  nome: string;
  frestran: boolean;
}

export const MODULE_NAME_DMRF = 'dmrf';
