import type {IPlImageResizerProperties} from '../../../common/imageresizer/image.resizer.interface';
import type {IPlUploadProperties} from '../../../upload/upload.component.interface';

export enum EPlMediaDevicesCameraCaptureImageModalTab {
  Upload = 'upload',
  Capture = 'capture'
}

export interface IPlMediaDevicesCameraCaptureProperties extends Partial<IPlImageResizerProperties> {
  uploadProperties?: Partial<IPlUploadProperties>;
  title?: string;
  titleTabUpload?: string;
  titleTabCapture?: string;
  defaultFileName?: string;
  videoWidth?: number;
  videoHeight?: number;
  maxVideoWidth?: number;
  maxVideoHeight?: number;
  resizeOverlay?: boolean;
}
