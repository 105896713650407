import {TDate} from '../../../common/dates';

export interface IJsonArtigoArmazem {
  nArmazem: number;
  nSectorLocal: string;
}

export interface IJsonArtigoArmazemStock {
  nArmazem: number;
  qtd: number;
  qtdStockPotencial?: number;
  qtdStockEncCliente?: number;
  qtdStockEncForn?: number;
  nomeArmazem?: string;
  qtdStockMinimo?: number;
  qtdStockMaximo?: number;
}

export interface IJsonArtigoStockArmazens {
  nArtigo: string;
  list: Array<IJsonArtigoArmazemStock>;
  qtdTotal?: number;
  qtdTotalStockPotencial?: number;
  qtdTotalStockEncCliente?: number;
  qtdTotalStockEncForn?: number;
}

export interface IJsonArtigosProcessParams {
  nArtigoList: string;
}

export interface IJsonArtigo {
  nArtigo: string;
  nome: string;
  nFamilia: number;
  nomeFamilia: string;
  nTpArt: number;
  nomeTpArt: string;
  nGrFamilia: number;
  nomeGrFamilia: string;
  nSubFa: number;
  nomeSubFa: string;
  codCalcPrecoVenda: number;
  nomeCalcPrecoVenda: string;
  codIvaVenda: number;
  nomeIvaVenda: string;
  taxaIvaVenda: number;
  codIvaCompra: number;
  nomeIvaCompra: string;
  taxaIvaCompra: number;
  unidadeMov: number;
  nomeUnidadeMov: string;
  qtdPorUnid1: number;
  movimStock: boolean;
  temLote: boolean;
  nIec: number;
  nomeIec: string;
  codvaloriz: number;
  nomecodvaloriz: string;
  precoComIva: number;
  precoSemIva: number;
  stockArmazens: Array<IJsonArtigoArmazemStock>;
  qtdTotal: number;
  temRetencaoNaFonte: boolean;
  qtdPorUnid2: number;
  stampUpdate: TDate;
  armazens: Array<IJsonArtigoArmazem>;
  classe: number;
  descricaoClasse: string;
  categoria: number;
  descricaoCategoria: string;
  segNome: string;
  segNArtigo: string;
  nContaPoc: string;
  nomeContaPoc: string;
  nContaPocCompra: string;
  nomeContaPocCompra: string;
  pesoPorUnida: number;
  volumePorUnida: number;
  grupoArtigo: EGrupoArtigo;
  artDesactivado: boolean;
  artBloqueado: boolean;
  precoBaseCusto: number;
  nDecimaisPrecos?: number;
  precoCompUlt: number;
  dataPrCompra: TDate;
  prVendaUlti: number;
  dataPrVendUl: TDate;
  naoPermiteExistNeg: boolean;
  temDataValidade: boolean;
  qtdTotalStockPotencial: number;
  qtdTotalStockEncCliente: number;
  qtdTotalStockEncForn: number;
  diasValidade: number;
}

export interface IJsonArtigosPrecosCalc {
  precounitario: number;
  d1: number;
  d2: number;
  d3: number;
  d4: number;
  d5: number;
  d6: number;
  r1lin: number;
  r2lin: number;
  r3lin: number;
  r1per: number;
  r2per: number;
  r3per: number;
  d1fin: number;
  d2fin: number;
  codcampanha: number;
  textolivre: string;
}

export enum EGrupoArtigo {
  Geral,
  Mercadoria,
  Produto,
  MateriaPrima,
  Servico,
  ServicoExterno,
  Componente,
  Equipamento,
  Ferramenta,
  MaoDeObra,
  ArtigoReferencia,
  MaterialSubsidiario,
  Embalagem,
  Veiculo,
  Motorista,
  TipoTarefa,
  TarefaEspecifica,
  TarefaGeral,
  Transportadora,
  MaquinaMotriz,
  Configuracao,
  ArtigoTara,
  Infraestrutura,
  Utensilio,
  ParteInfraestrutura,
  Adiantamentos,
  AlienacaoDeImobilizado
}
