<div class="encomendas-satisfazer-clifo-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h4 class="modal-title" [translate]="'encomendas.modal.satisfazerclifo.title'"></h4>
  </div>

  <div class="modal-body">
    <encomendas
      [nConta]="nConta"
      [nomeConta]="nomeConta"
      [grupoClifos]="grupoClifos"
      [grupo]="grupo"
      [modalClifo]="true"
      [callFromFaturacao]="callFromFaturacao"
      [doc]="doc"
      (evtStartProcessarLotes)="onStartProcessarLotes()"
      (evtErrorProcessarLotes)="onErrorProcessarLotes()"
      (evtProcessarFaccbIdSucess)="onProcessarFaccbIdSucess($event)"></encomendas>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-sm btn-light action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
      <i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span>
    </button>
  </div>
</div>
