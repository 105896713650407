<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'global.text.confirmation'"></h4>
  </div>

  <div class="modal-body">
    <div class="mb-2" [translate]="getPromptMessage()"></div>
    <dx-data-grid [cgDxDataGrid]="dataGridDefinition" [dataSource]="dataGridDefinition.dataSource"></dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-danger btn-sm" [click]="fnConfirm" plPromise><i class="fa fa-check"></i>&nbsp;<span [translate]="'global.btn.yes'"></span></button>

    <button type="button" class="btn btn-light btn-sm action-dismiss" (click)="dismiss()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.no'"></span></button>
  </div>
</pl-form>
