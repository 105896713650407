import {ENTITY_NAME_QPHAB} from './qphab.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonQPHAB} from './jsonQPHAB.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_QPHAB: IEntityDefinition<IJsonQPHAB> = {
  name: ENTITY_NAME_QPHAB,
  url: 'habilitacoes',
  entityUrl: 'nivelqualifica/niveishabilitacoes',
  roles: [ROLE.RH],
  searchPlaceholder: 'qphab.pesquisa',
  pageTitle: 'global.menu.qphab',
  metadata: {
    keyName: 'codHabilitacao',
    fields: [
      {name: 'codHabilitacao', caption: 'qphab.fields.codHabilitacao', validators: {required: true, maxlength: 5}},
      {name: 'nome', caption: 'qphab.fields.nome', validators: {required: true, maxlength: 100}}
    ],
    order: 'codHabilitacao'
  },
  autocomplete: {
    rowTemplate: '{{codHabilitacao}} - {{nome}}',
    output: 'nome',
    searchFields: 'codHabilitacao,nome'
  },
  actions: {
    new: false,
    detail: false,
    edit: false,
    search: true,
    delete: false,
    filter: true
  }
};
