import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MovimentosAbertoComponent} from './components/movimentosAberto.module.component';

export const MODULE_PCA_CONTABILIDADE_MOVIMENTOS_ABERTO_CLIENTE: IModuleDefinition = {
  name: 'pcaContabilidadeMovimentosAbertoCliente',
  state: {
    url: '/movimentosemaberto/clientes',
    component: MovimentosAbertoComponent,
    data: {
      roles: [ROLE.PCA, ROLE.ERP, ROLE.FINANCEIRO],
      sidebarTitle: 'portals.sidebar.modules.pcaContabilidadeMovimentosAbertoCliente'
    },
    resolve: [{provide: 'tipo', useValue: 'cliente'}]
  }
};

export const MODULE_PCA_CONTABILIDADE_MOVIMENTOS_ABERTO_FORNECEDOR: IModuleDefinition = {
  name: 'pcaContabilidadeMovimentosAbertoFornecedor',
  state: {
    url: '/movimentosemaberto/fornecedores',
    component: MovimentosAbertoComponent,
    data: {
      roles: [ROLE.PCA, ROLE.ERP, ROLE.FINANCEIRO],
      sidebarTitle: 'portals.sidebar.modules.pcaContabilidadeMovimentosAbertoFornecedor'
    },
    resolve: [{provide: 'tipo', useValue: 'fornecedor'}]
  }
};
