import {Subject, Subscription} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {isFunction} from '../utilities/utilities';
import type {IPlGlobalEvent, IPlGlobalEventsListener, TPlGlobalEventsListenerFn} from './global.events.service.interface';

@Injectable({
  providedIn: 'root'
})
export class PlGlobalEventsService implements OnDestroy {
  private readonly _subjectEvents: Subject<IPlGlobalEvent>;
  private readonly _registeredListeners: IPlGlobalEventsListener;
  private readonly _subscriptionEvents: Subscription;

  constructor() {
    this._subjectEvents = new Subject<IPlGlobalEvent>();
    this._registeredListeners = {};
    this._subscriptionEvents = this._subjectEvents.asObservable().subscribe(({name, args}: IPlGlobalEvent) => {
      const listeners: Array<(...args1: Array<any>) => void> = this._registeredListeners[name];
      if (listeners) {
        for (const listener of listeners) {
          if (isFunction(listener)) {
            listener(...args);
          }
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this._subscriptionEvents.unsubscribe();
    this._subjectEvents.complete();
  }

  public on(name: string, listener: TPlGlobalEventsListenerFn): (...args: Array<any>) => void {
    if (!this._registeredListeners[name]) {
      this._registeredListeners[name] = [];
    }

    /* Avoid registering the same listener twice to avoid memory leakage or memory waste */
    const indexOf = this._registeredListeners[name].indexOf(listener);
    if (indexOf !== -1) {
      return this._registeredListeners[name][indexOf];
    }

    this._registeredListeners[name].push(listener);
    return this._registeredListeners[name][this._registeredListeners[name].length - 1];
  }

  public off(name: string, listenerReference: TPlGlobalEventsListenerFn): boolean {
    const listeners = this._registeredListeners[name];
    if (listeners) {
      const index = listeners.findIndex((listener) => listener === listenerReference);
      if (index !== -1) {
        listeners.splice(index, 1);
        return true;
      }
    }
    return false;
  }

  public broadcast(name: string, ...args: Array<any>): void {
    this._subjectEvents.next({name, args});
  }
}
