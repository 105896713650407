import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_ATIVOS_IMPRESSAO} from './ativosImpressao.module.interface';
import {AtivosImpressaoModuleComponent} from './components/ativosImpressao.module.component';

export const MODULE_ATIVOS_IMPRESSAO: IModuleDefinition = {
  name: MODULE_NAME_ATIVOS_IMPRESSAO,
  state: {
    url: `/${MODULE_NAME_ATIVOS_IMPRESSAO}`,
    component: AtivosImpressaoModuleComponent,
    data: {
      roles: [ROLE.ATIVOS],
      pageTitle: 'global.menu.ativosimpressao'
    }
  }
};
