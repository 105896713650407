import {IJsonReconstrucaoAcum} from '../../../../interfaces/jsonAcumulados.interface';

export enum EReconstrucaoAcumTipoConta {
  Todas,
  Especifica
}

export interface IReconstrucaoAcum extends IJsonReconstrucaoAcum {
  tipoPoc: EReconstrucaoAcumTipoConta;
}
