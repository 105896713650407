import {ConfigsErpEmpresaModuleComponent} from './components/configs.empresa.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_CONFIGS_EMPRESA} from './configs.empresa.module.interface';
import {ROLE} from '../../../../services/role.const';

export const MODULE_CONFIGS_EMPRESA: IModuleDefinition = {
  name: MODULE_NAME_CONFIGS_EMPRESA,
  state: {
    url: '/configs/erp/empresa',
    component: ConfigsErpEmpresaModuleComponent,
    data: {
      roles: [ROLE.ADMIN, ROLE.ADMINBASIC],
      pageTitle: 'configsErp.items.empresaTitle'
    }
  }
};
