import {uniqBy} from 'lodash-es';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {copy, IPlNavWizardDefinition, isEmpty, isObject, PlAlertService, PlLocationService} from 'pl-comps-angular';
import {EContratoLockedReason} from '../../../../../../../../common/enums/contratolockedreason.enum';
import {ENTITY_NAME_USERS} from '../../../../../../utilizadores/utilizadores.interface';
import {EntityServiceBuilder} from '../../../../../../../services/entity/entity.service.builder';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../../../../config/constants';
import {IEmpresa} from '../../../../../../../entities/empresas/empresas.entity.interface';
import {IEntityService} from '../../../../../../../services/entity/entity.service.interface';
import {emptyUserNew, IJsonErpUser, IJsonUserEmpresa, IJsonUserNew} from '../../../../../../../services/account/jsonUserApi.interface';
import {MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS} from '../../../../assistente.configPortais.module.service.interface';
import {ModuloComponent} from '../../../../../../../components/module/module.component';
import {ROLE} from '../../../../../../../services/role.const';
import {STATE_NAME_CHANGE_PASSWORD} from '../../../../../../../states/account/changepassword/changepassword.interface';

@Component({
  selector: 'module-assistente-config-portais-portal-client-connect-utilizador',
  templateUrl: './assistConfigPortais.portalClientConnect.utilizador.module.component.html'
})
export class AssistConfigPortaisPortalClientConnectUtilizadorModuleComponent extends ModuloComponent implements OnInit {
  @Input() public portalRoleClientConnect: ROLE;

  public readonly formInstance: UntypedFormGroup;
  public readonly filterEmpresas: string;
  public readonly navWizardDefinition: IPlNavWizardDefinition;
  public readonly centralGestId: number;
  public readonly model: IJsonUserNew;

  private readonly _usersService: IEntityService<IJsonUserEmpresa>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plLocationService: PlLocationService,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.formInstance = new UntypedFormGroup({});
    this.filterEmpresas = 'EmpresaGabinete=0';
    this.navWizardDefinition = {
      items: []
    };
    this.centralGestId = this.session.erp.centralGestId;
    const erp: IJsonErpUser = {
      cgID: undefined,
      centralGestId: this.session.erp.centralGestId,
      name: this.session.erp.name,
      apiUrl: this.session.erp.apiUrl,
      nEmpresa: undefined,
      nUtilizador: undefined,
      ativo: true,
      nomeEmpresa: undefined,
      nomeUtilizadorCG: undefined,
      codEmp: undefined,
      nomeEmpregado: undefined,
      rolesAcess: [{role: ROLE.CLIENTCONNECT}, {role: copy(this.portalRoleClientConnect)}],
      rolesNotAcess: []
    };
    this.model = {
      ...emptyUserNew(),
      lockedReason: EContratoLockedReason.None,
      erps: [erp],
      erp: erp
    };
    this._usersService = this._entityServiceBuilder.build<IJsonUserEmpresa>(ENTITY_NAME_USERS);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnBack.visible = true;
  }

  public back(): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_ASSISTENTE_CONFIG_PORTAIS, exactMatch: true});
  }

  public changedEmpresa(value: IEmpresa): void {
    if (isObject(value)) {
      this.model.erp.nEmpresa = value.nempresa;
      this.model.erp.nomeEmpresa = value.nome;
    } else {
      this.model.erp.nEmpresa = undefined;
      this.model.erp.nomeEmpresa = undefined;
    }
  }

  public readonly fnOnFinalize: () => Promise<void> = () => this._onFinalize();

  private _onFinalize(): Promise<void> {
    if (!this.formInstance.valid) {
      this._plAlertService.error('error.invalidForm');
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    const model: IJsonUserNew = copy(this.model);
    model.erps = model.erps.filter((erp: IJsonErpUser) => !isEmpty(erp.nEmpresa));
    model.erps = uniqBy(model.erps, 'nEmpresa');
    const url = `${this._plLocationService.getUrl()}/${STATE_NAME_CHANGE_PASSWORD}`;
    return this._usersService.post<IJsonUserEmpresa, IJsonUserNew>({body: model, params: {url: url}}).then(() => {
      this._plAlertService.success('assistenteconfigportais.info.savesucess');
      return this.back();
    });
  }
}
