import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EComunicaInexistenciaModalModo} from '../../eFatura.module.interface';
import {EFaturaService} from '../../eFatura.module.service';
import {PlAlertService} from 'pl-comps-angular';
import {SimpleLoginModalComponent} from '../../../simplelogin/modals/simpleLogin.modal.component';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ESimpleLoginType} from '../../../simplelogin/simpleLogin.module.interface';

const EXCEPTION_OBJEFATURALOGIN = 'EOBJEFATURALOGIN';

@Component({
  selector: 'comunica-inexistencia-modal',
  templateUrl: './comunicaInexistencia.modal.component.html'
})
export class ComunicaInexistenciaModalComponent extends CGModalComponent<Array<number>> implements OnInit {
  @Input() public modo: EComunicaInexistenciaModalModo;
  @Input() public ano: number;
  @Input() public mes: number;

  public anoMes: string;
  public confirmCheck: boolean;
  public checkLabelCaption: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _efaturaService: EFaturaService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.anoMes = '';
    this.confirmCheck = false;
    this.checkLabelCaption = '';
  }

  public ngOnInit(): void {
    this.checkLabelCaption = this.modo === EComunicaInexistenciaModalModo.COMUNICACAO ? 'efatura.confirmComunicacaoCheckLabel' : 'efatura.confirmAnulacaoComunicacaoCheckLabel';
    this.anoMes = `${this.ano}-${this.mes.toString().padStart(2, '0')}`;
  }

  public readonly fnClick: () => Promise<unknown> = () => this._click();

  private _click(): Promise<unknown> {
    const promise = this._isComunicacao() ? this._efaturaService.comunicacaoInexistencia(this.ano, this.mes) : this._efaturaService.anularComunicacaoInexistencia(this.ano, this.mes);

    promise
      .then((response) => {
        if (response.body.success) {
          this._plAlertService.success(this._isComunicacao() ? 'efatura.comunicacaoSuccessMsg' : 'efatura.anulacaoComunicacaoSuccessMsg');
          this.close();
        } else {
          this._plAlertService.error(response.body.errorMessage);
        }
      })
      .catch((reason) => {
        const cgException = this._cgExceptionService.get(reason);
        if (cgException.class.toUpperCase() === EXCEPTION_OBJEFATURALOGIN) {
          const modalInstance = this._cgModalService.showVanilla(SimpleLoginModalComponent, {size: 'sm', backdrop: 'static'});
          const componentInstance: SimpleLoginModalComponent = modalInstance.componentInstance;
          componentInstance.loginType = ESimpleLoginType.EFATURA;
          modalInstance.result.then(() => {
            this._click();
          });
        }
        this._plAlertService.error(cgException.message);
      });

    return promise;
  }

  private _isComunicacao(): boolean {
    return this.modo === EComunicaInexistenciaModalModo.COMUNICACAO;
  }
}
