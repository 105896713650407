import {IJsonDocumFac, IJsonIntFac, IJsonListaInteg} from './jsonIntFac.module.interface';
import {IJsonDocContabilidade} from '../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {TTableLegend} from '../../components/tablelegend/tablelegend.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {EDataGridLegendCellColors} from '../../components/devexpress/datagrid/configuration/devexpress.datagrid.configuration';

export interface IIntFac extends IJsonIntFac {
  _errorList: Array<IListInteg>;
  _docToolbarId: string;
  _hasDetail: boolean;
  _docContab?: IJsonDocContabilidade;
}

export interface IListInteg extends IJsonListaInteg {
  _hasDetail: boolean;
  _index: number;
}

export interface IDocumFac extends IJsonDocumFac {
  _label: string;
  _selected: boolean;
}

export const MODULE_NAME_INTEGRACAO_DOCUMENTOS = 'intfac';

export const TABLE_LEGEND_INT_FAC_LEGEND: TTableLegend = deepFreeze([
  {
    caption: 'intfac.modal.legend.legendErro',
    badgeCSSClass: EDataGridLegendCellColors.RedLegendSolidColor
  }
]);
