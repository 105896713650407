<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'debDiretosSEPA.strings.modalTitleConfiguracao'"></h4>
</div>

<div class="modal-body">
  <pl-nav-wizard [onFinalize]="fnFinalize">
    <pl-nav-wizard-step stepId="configGerais" icon="fa-cog fa-fw" [caption]="getTabCaption(debDiretosSEPAConfigTabs.ConfigGerais)">
      <div *plNavWizardStepContent>
        <ng-container *ngIf="!userAcessos.geral">
          <pl-alert type="warning" [closeable]="false">
            <ng-container><i class="fa fa-fw fa-exclamation-triangle me-1" aria-hidden="true"></i></ng-container>
            <ng-container>
              <span [translate]="'debDiretosSEPA.strings.naoTemAcessoEstaConfiguracao'"></span>
            </ng-container>
          </pl-alert>
        </ng-container>

        <ng-container *ngIf="userAcessos.geral">
          <fieldset class="mb-3">
            <legend [translate]="'debDiretosSEPA.strings.dataLiquidacao'"></legend>
            <div class="d-flex align-items-center gap-2">
              <div>
                <div class="d-flex align-items-center gap-1">
                  <input
                    id="dataVencimento-radio"
                    type="radio"
                    name="dataLiquidacaoRadio"
                    [value]="debDiretosSEPADataLiquidacao.DataVencimento"
                    [checked]="model.tipoDataLiquidacao === debDiretosSEPADataLiquidacao.DataVencimento"
                    (click)="setDataLiquidacao(debDiretosSEPADataLiquidacao.DataVencimento)" />
                  <label for="dataVencimento-radio" [translate]="'debDiretosSEPA.strings.dataVencimento'"></label>
                </div>
              </div>
              <div>
                <div class="d-flex align-items-center gap-1">
                  <input
                    id="numeroDias-radio"
                    type="radio"
                    name="dataLiquidacaoRadio"
                    [value]="debDiretosSEPADataLiquidacao.NumeroDias"
                    [checked]="model.tipoDataLiquidacao === debDiretosSEPADataLiquidacao.NumeroDias"
                    (click)="setDataLiquidacao(debDiretosSEPADataLiquidacao.NumeroDias)" />
                  <label for="numeroDias-radio" [translate]="'debDiretosSEPA.strings.nDiasPrimeiraComunicacao'"></label>
                  <pl-edit
                    style="max-width: 60px"
                    type="cginteger"
                    [(model)]="model.dataLiquidacaoNDiasFRST"
                    [properties]="{
                      disallowClear: true,
                      disabled: model.tipoDataLiquidacao === debDiretosSEPADataLiquidacao.DataVencimento,
                      validators: {min: {value: 0}}
                    }">
                  </pl-edit>
                  <span [translate]="'debDiretosSEPA.strings.seguintes'"></span>
                  <pl-edit
                    style="max-width: 60px"
                    type="cginteger"
                    [(model)]="model.dataLiquidacaoNDiasRCUR"
                    [properties]="{
                      disallowClear: true,
                      disabled: model.tipoDataLiquidacao === debDiretosSEPADataLiquidacao.DataVencimento,
                      validators: {min: {value: 0}}
                    }"></pl-edit>
                </div>
              </div>
            </div>

            <pl-alert class="mb-2 mt-2" type="info" [closeable]="false">
              <ng-container><i class="fa fa-fw fa-info-circle me-1" aria-hidden="true"></i></ng-container>
              <ng-container>
                <span [translate]="getConfigGeraisDataLiquidacaoMessage()"></span>&nbsp;
                <span *ngIf="model.tipoDataLiquidacao === debDiretosSEPADataLiquidacao.NumeroDias" [translate]="'debDiretosSEPA.strings.dataParaLiquidacaoSeraCalcPelosDiasConfig2'"></span>
              </ng-container>
            </pl-alert>
          </fieldset>

          <div class="row" *ngIf="userAcessos.acessoContabilidade">
            <div class="col-12">
              <pl-group>
                <label>
                  <span class="me-1" [translate]="'debDiretosSEPA.fields.contaIntermedia'"></span>
                  <pl-tooltip [config]="{text: 'debDiretosSEPA.strings.contaIntermediaParaLiquidacao', container: 'body'}">
                    <i *plTooltipContent class="fa fa-info-circle" aria-hidden="true"></i>
                  </pl-tooltip>
                </label>
                <edit>
                  <entity-autocomplete
                    entity="pocs"
                    [model]="model"
                    (selectedKeyChange)="model.nContaIntermedia = $event"
                    (evtSelectedDescriptionChanged)="model.nContaIntermediaNome = $event"
                    [properties]="{allowInvalid: false}"
                    [fieldsMap]="{nConta: 'nContaIntermedia', nome: 'nContaIntermediaNome'}">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </div>
          </div>
        </ng-container>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step stepId="contasCredoras" icon="fa-building-o fa-fw" [caption]="getTabCaption(debDiretosSEPAConfigTabs.ContasCredoras)">
      <div *plNavWizardStepContent>
        <ng-container *ngIf="!userAcessos.bancos">
          <pl-alert type="warning" [closeable]="false">
            <ng-container><i class="fa fa-fw fa-exclamation-triangle me-1" aria-hidden="true"></i></ng-container>
            <ng-container>
              <span [translate]="'debDiretosSEPA.strings.naoTemAcessoEstaConfiguracao'"></span>
            </ng-container>
          </pl-alert>
        </ng-container>

        <ng-container *ngIf="userAcessos.bancos">
          <dx-data-grid
            [cgDxDataGrid]="dataGridContasCredorasDefinition"
            [dataSource]="dataGridContasCredorasDefinition.dataSource"
            cgDxDataGridInstanceName="contasCredoras"
            (onInitialized)="onContasCredorasInitialized($event)"
            (onRowRemoving)="onRowRemoving($event)">
            <div *dxTemplate="let item of 'cellTemplateEstado'">
              <span class="ddsepa-estado-circle" [ngClass]="{unsaved: item.data.estado !== debDiretosSEPAEstadoItem.None}"></span>
            </div>

            <div *dxTemplate="let item of 'cellTemplateActions'">
              <dx-button class="dx-button-icon-fontawesome" [hint]="'global.btn.edit' | translate" (click)="editContaCredora(item.data)" icon="fa fa-fw fa-pencil" [stylingMode]="'text'"></dx-button>
              <dx-button class="dx-button-icon-devexpress" [hint]="'global.btn.delete' | translate" (click)="deleteContaCredora(item)" icon="trash" [stylingMode]="'text'"></dx-button>
            </div>
          </dx-data-grid>
        </ng-container>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step stepId="tiposDocumento" icon="fa-file-text-o fa-fw" [caption]="getTabCaption(debDiretosSEPAConfigTabs.TiposDocumento)">
      <div *plNavWizardStepContent>
        <ng-container *ngIf="!userAcessos.tiposDocumento">
          <div class="alert alert-warning">
            <i class="fa fa-fw fa-exclamation-triangle me-1" aria-hidden="true"></i><span [translate]="'debDiretosSEPA.strings.naoTemAcessoEstaConfiguracao'"></span>
          </div>
        </ng-container>

        <ng-container *ngIf="userAcessos.tiposDocumento">
          <pl-multiselect
            [model]="model.listaTiposDocumento"
            [source]="listaTiposDocumentoDisponiveis"
            key="nDocFa"
            [template]="templateMultiSelectTiposDocumento"
            [properties]="{
              leftCaption: 'debDiretosSEPA.strings.semDebitosDiretos',
              rightCaption: 'debDiretosSEPA.strings.comDebitosDiretos'
            }">
          </pl-multiselect>
        </ng-container>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
