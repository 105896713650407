import moment from 'moment';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {TDate} from '../../../../../common/dates';

@Component({
  selector: 'inventarios-new-modal',
  templateUrl: './inventarios.new.modal.component.html'
})
export class InventariosNewModalComponent extends CGModalComponent<string> implements OnInit {
  @Input() public data: TDate;

  public formattedData: string;
  public nome: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.nome = '';
  }

  public ngOnInit(): void {
    this.formattedData = moment(this.data).format(this.format.momentDate);
  }

  public close(): void {
    super.close(this.nome);
  }
}
