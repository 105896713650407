import {firstValueFrom} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isEmpty} from 'lodash-es';
import {downloadStream, IPlToolbarInstance, IPlToolbarItem, PlAlertService, PlToolbarService, TPlToolbarItem} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {ICGOkCancelModalOptions} from '../../../../../../components/cg/modal/cgmodal.interface';
import {CGModalService} from '../../../../../../components/cg/modal/cgmodal.service';
import {ReportsRegistryService} from '../../../../../../components/reports/reports.registry.service';
import {IJsonReport} from '../../../../../../entities/reports/jsonReport.interface';
import {EReport} from '../../../../../../entities/reports/reports.interface';
import {IImpAvisosCobrancaFilters} from '../../../avisosCobranca.module.interface';
import {AvisoscobrancaService} from '../../../avisosCobranca.module.service';
import {IJsonAvisoDeCobranca, IJsonAvisoDeCobrancaEmailTemplate} from '../../../jsonAvisosCobranca.module.interface';
import {AvisosCobrancaEmailModalComponent} from '../email/avisosCobranca.email.modal.component';

@Component({
  selector: 'modal-avisos-cobranca-preview',
  templateUrl: './avisosCobranca.preview.modal.component.html'
})
export class AvisosCobrancaPreviewModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public previewSingle: boolean;
  @Input() public headFilters: IImpAvisosCobrancaFilters;
  @Input() public nContasList: Array<string>;
  @Input() public selectedRowsData: Array<IJsonAvisoDeCobranca>;
  @Input() public reportName: string;

  public toolbarInstanceId: string;
  public pdfUrl: string;
  public report: IJsonReport;
  public toolbarInstance: IPlToolbarInstance;

  private readonly _btnEnviaMail: IPlToolbarItem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _avisosCobrancaService: AvisoscobrancaService,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _plToolbarService: PlToolbarService
  ) {
    super(_injector);
    this.toolbarInstanceId = 'avisos_cobranca_preview';
    this.toolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceId);
    this._btnEnviaMail = {
      id: 'sendmail',
      type: 'button',
      order: 1000,
      iconLeft: '<i class="fa fa-fw fa-envelope-o"></i>',
      class: 'btn-success',
      caption: 'emailclifos.sendMail.btn.sendMail',
      click: () => {
        this._sendMail();
      }
    };
    this.toolbarInstance.addButton(this._btnEnviaMail);
  }

  public ngOnInit(): void {
    this._btnEnviaMail.visible = !this.previewSingle;
    this._loadDefaultReport().then(() => {
      this.getURL();
    });
  }

  public getURL(): Promise<void> {
    const ncontas: string = this.nContasList.join(',');
    return firstValueFrom(this._avisosCobrancaService.getAvisosCobrancaPDFUrlList(this.headFilters, ncontas, this.report.name)).then((url: string) => {
      this.pdfUrl = url;
      if (this.selectedRowsData?.length > 1 || this.nContasList?.length > 1) {
        const pdfDownload: TPlToolbarItem = this.toolbarInstance.find('pdfDownload');
        pdfDownload.type = 'button';
        pdfDownload.click = () => this._multiFileAvisoCobPDFList();
      }
    });
  }

  private _loadDefaultReport(): Promise<void> {
    return this._reportsRegistryService
      .get(EReport.AvisosCobranca)
      .query()
      .then((reports: Array<IJsonReport>) => {
        for (const report of reports) {
          if (report.name === this.reportName) {
            this.report = report;
          }
        }
      });
  }

  private async _sendMail(): Promise<void> {
    if (!this.nContasList || this.nContasList.length < 1) {
      this._plAlertService.error('avisoscobranca.messages.naoselconta');
      return;
    }

    const itemsWithoutEmail: Array<string> = [];
    const itemsWithEmail: Array<string> = [];
    for (const item of this.selectedRowsData) {
      if (isEmpty(item.emailDestino)) {
        itemsWithoutEmail.push(`${item.nConta} - ${item.nContaNome}`);
      } else {
        itemsWithEmail.push(item.nConta);
      }
    }

    if (itemsWithoutEmail.length > 0) {
      let message: string = this._translateService.instant('avisoscobranca.messages.contaSemMail1');
      message += '<br>';
      for (const conta of itemsWithoutEmail) {
        message += `<br> <b>${conta}</b>`;
      }
      message += '<br> <br>';

      if (itemsWithoutEmail.length === this.nContasList.length) {
        message += this._translateService.instant('avisoscobranca.messages.allContasSemMail');
        const options: ICGOkCancelModalOptions = {showOkBtn: false};
        await this._cgModalService.showOkCancel('global.text.warning', message, options);
      }
      message += this._translateService.instant('avisoscobranca.messages.contaSemMail2');
      await this._cgModalService.showOkCancel('global.text.warning', message);
    }

    await this._avisosCobrancaService.getEmailTemplate(this.headFilters, itemsWithEmail.join(',')).then((response: HttpResponse<IJsonAvisoDeCobrancaEmailTemplate>) => {
      const modalInstance = this._cgModalService.showVanilla(AvisosCobrancaEmailModalComponent);
      const componentInstance: AvisosCobrancaEmailModalComponent = modalInstance.componentInstance;
      componentInstance.selectedRowsList = this.selectedRowsData;
      componentInstance.subject = response.body.subject;
      componentInstance.body = response.body.body;
      componentInstance.reportName = this.report.name;
      return modalInstance.result.then(() => {
        this._plAlertService.success('avisoscobranca.messages.emailSentSuccessfully');
      });
    });
  }

  private async _multiFileAvisoCobPDFList(): Promise<void> {
    const ncontas: string = this.nContasList.join(',');
    const responseFicheiro: HttpResponse<Blob> = await this._avisosCobrancaService.multiFileAvisoCobPDFList(this.headFilters, ncontas, this.report.name);
    downloadStream(responseFicheiro);
  }
}
