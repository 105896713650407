import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocContabilidade, IDocsContabilidadeEntityService} from '../../../../docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../../../../services/entity/entity.service.builder';
import {IJsonDocContabilidade} from '../../../../jsonDocContabilidade.interface';

@Component({
  selector: 'docs-contabilidade-view-doc-modal',
  templateUrl: './docsContabilidade.viewdoc.modal.component.html'
})
export class DocsContabilidadeViewDocModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public extPocCabID: string;

  public doc: IDocContabilidade;
  public promise: Promise<void>;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
  }

  public ngOnInit(): void {
    if (this.extPocCabID) {
      this.promise = this._serviceDocsContabilidade.get({id: this.extPocCabID}).then((response) => {
        this.doc = response.body;
      });
    }
  }
}
