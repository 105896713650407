<div class="repfinancas">
  <entity-detail
    entityName="repfinancas"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL}">
        <pl-group>
          <label [translate]="'repfinancas.fields.codFiscal'"></label>
          <edit>
            <pl-edit type="string" attrName="codFiscal" [(model)]="model.codFiscal" [properties]="{disabled: type === stateTypes.EDIT, validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'repfinancas.fields.nome'"></label>
          <edit>
            <pl-edit type="string" attrName="nome" [(model)]="model.nome" [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'repfinancas.fields.tipo'"></label>
          <edit>
            <pl-edit type="radiogroup" attrName="tipo" [(model)]="model.tipo" [properties]="{groupItems: radioGroupTipoFinancas, validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'repfinancas.fields.morada'"></label>
          <edit>
            <pl-edit type="string" attrName="morada" [(model)]="model.morada"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'repfinancas.fields.codPostal'"></label>
          <edit>
            <entity-autocomplete entity="codpostais" attrName="codPostal" [model]="model" (selectedKeyChange)="model.codPostal = $event"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'repfinancas.fields.nomePais'"></label>
          <edit>
            <entity-autocomplete entity="paises" attrName="nomePais" [model]="model" (selectedKeyChange)="model.codPais = $event" (evtSelectedDescriptionChanged)="model.nomePais = $event">
            </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'repfinancas.fields.nomeDistrito'"></label>
          <edit>
            <entity-autocomplete entity="distritos" attrName="nomeDistrito" [model]="model" (modelChange)="onDistritoChanged($event)"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'repfinancas.fields.codFinancasAT'"></label>
          <edit>
            <entity-autocomplete
              entity="qpdcfservicosfinancas"
              attrName="codFinancasAT"
              [model]="model"
              [fieldsMap]="{codServicoFinancas: 'codFinancasAT', nomeServicoFinancas: 'nomeFinancasAT'}"
              [output]="qpdcfOutput"
              (selectedKeyChange)="model.codFinancasAT = $event"
              (evtSelectedDescriptionChanged)="model.nomeFinancasAT = $event"
              [filter]="filterCodFinancasAT"
              [properties]="{disabled: model.codDistritoAT === ''}">
            </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'repfinancas.fields.telefone'"></label>
          <edit>
            <pl-edit type="string" attrName="telefone" [(model)]="model.telefone"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'repfinancas.fields.fax'"></label>
          <edit>
            <pl-edit type="string" attrName="fax" [(model)]="model.fax"></pl-edit>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
