<div class="listagem-diarios entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <pl-group plAutoFocus>
          <label [translate]="'global.text.listing'"></label>
          <edit>
            <pl-edit type="reports_documentos" attrName="listagem" [(model)]="reportModel"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'listagemdiarios.header.doPeriodo'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="doPeriodo"
                [model]="header"
                (selectedKeyChange)="changedDePeriodo($event)"
                [filter]="'loadAllAnos=1' + header.dePeriodoAno"
                [properties]="{validators: {required: {value: true}}}"
                (evtInputValueChanged)="header.dePeriodoAno = ''"
                [fieldsMap]="{nome: 'periododeNome', periodo: 'periodode'}"
                [helperMode]="true"
                plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="atePeriodo"
                [model]="header"
                (selectedKeyChange)="changedAtePeriodo($event)"
                [filter]="'loadAllAnos=' + header.loadAllAnos + '&loadOnlyAno=' + header.loadOnlyAno"
                [properties]="{validators: {required: {value: true}}}"
                [fieldsMap]="{nome: 'periodoateNome', periodo: 'periodoate'}"
                [helperMode]="true">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'listagemdiarios.header.doDiario'"></label>
            <edit>
              <entity-autocomplete entity="diarios" attrName="doDiario" [(selectedKey)]="header.ndiariode" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="diarios" attrName="ateDiario" [(selectedKey)]="header.ndiarioate" [properties]="{validators: {required: {value: true}}}" [helperMode]="true" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'listagemdiarios.header.docInterDo'"></label>
            <edit>
              <pl-edit type="text" attrName="docInterDo" [(model)]="header.ndocinternode"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit type="text" attrName="docInterAte" [(model)]="header.ndocinternoate"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'listagemdiarios.header.datadocde'"></label>
            <edit>
              <pl-edit attrName="datadocde" type="date" [(model)]="header.datadocde"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit attrName="datadocate" type="date" [(model)]="header.datadocate"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'listagemdiarios.header.fromAccount'"></label>
            <edit>
              <entity-autocomplete entity="pocs" attrName="daConta" [filter]="filter" [(selectedKey)]="header.ncontade" [helperMode]="true" output="key"></entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                attrName="ateConta"
                [filter]="filter"
                [(selectedKey)]="header.ncontaate"
                [helperMode]="true"
                output="key"
                [properties]="{events: {keydown: fnKeydownProcessar}}"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>
</div>

<br />

<cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
