import {ContabilidadePredefinidosComponent} from './components/contabilidade.predefinidos.component';
import {ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS, MODULE_NAME_CONTABILIDADE_PREDEFINIDOS} from './preDefinidosContab.module.interface';
import {IEntityDefinition} from '../../../../components/entity/entity.definition.interface';
import {IJsonPreDefinidoContab} from './jsonPreDefinidosContab.module.interface';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ROLE} from '../../../../services/role.const';

export const MODULE_CONTABILIDADE_PREDEFINIDOS: IModuleDefinition<IJsonPreDefinidoContab> = {
  name: MODULE_NAME_CONTABILIDADE_PREDEFINIDOS,
  state: {
    url: `/${MODULE_NAME_CONTABILIDADE_PREDEFINIDOS}`,
    component: ContabilidadePredefinidosComponent,
    data: {
      roles: [ROLE.CONTABILIDADE, ROLE.ATIVOS],
      sidebarTitle: 'portals.sidebar.modules.predefinidoscontabilidade'
    },
    params: {
      operation: {
        type: 'int',
        value: null
      },
      docContabilidade: {
        type: 'string',
        value: ''
      },
      editPreDefinidosID: {
        type: 'int',
        value: 0
      }
    }
  }
};

export const ENTITY_CONTABILIDADE_PREDEFINIDOS: IEntityDefinition<IJsonPreDefinidoContab> = {
  name: ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS,
  url: 'predefinidoscontabilidade/predefinidos',
  asModule: false,
  metadata: {
    keyName: 'preDefinidosID',
    fields: [
      {name: 'preDefinidosID', type: 'integer', caption: 'predefinidoscontabilidade.fields.preDefinidosID', visible: false},
      {name: 'descricao', caption: 'predefinidoscontabilidade.fields.description'},
      {name: 'dados', caption: 'predefinidoscontabilidade.fields.data', visible: false},
      {name: 'revision', type: 'cginteger', caption: 'predefinidoscontabilidade.fields.revision', visible: false},
      {name: 'cgBanking', type: 'boolean', caption: 'predefinidoscontabilidade.fields.cgBanking', visible: false}
    ],
    order: 'descricao',
    searchFields: 'preDefinidosID,descricao,cgBanking'
  },
  autocomplete: {
    rowTemplate: '{{preDefinidosID}} - {{descricao}}',
    output: '{{preDefinidosID}} - {{descricao}}',
    outputDescription: 'descricao',
    searchFields: 'preDefinidosID,descricao'
  }
};
