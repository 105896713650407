import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MapaFaltasComponent} from './components/mapaFaltas.module.component';

export const MODULE_PCA_RH_MAPA_FALTAS: IModuleDefinition = {
  name: 'pcaMapaFaltas',
  state: {
    url: '/rh/mapa/faltas',
    component: MapaFaltasComponent,
    data: {
      roles: [ROLE.PCA],
      pageTitle: 'global.menu.pcaMapaFaltas'
    }
  }
};
