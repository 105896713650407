import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../components/module/module.component';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnInitialized
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {FctService} from '../fct.module.service';
import {downloadStream, IPlDynamicVisualsSecondaryClickAction, IPlToolbarMenuItem, isObject, isUndefinedOrNull, KEYCODES, PlAlertService, TPlToolbarItem} from 'pl-comps-angular';
import {
  EFctLoginStatus,
  EFctMessageType,
  EFctSearchTipoDocumento,
  EFctTipoDocumento,
  IFctCredentials,
  IFctDocumentoItem,
  IFctEmitirDocPromptResult,
  IFctEmpresasDTItem,
  IFctSearchFilters,
  IFctSearchFiltersNEmpresa,
  IFctValueName
} from '../fct.module.interface';
import {ICGExceptionError} from '../../../components/exceptions/exceptions.service.interface';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {HttpErrorResponse} from '@angular/common/http';
import moment from 'moment';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {FctCredentialsModalComponent} from '../modals/credentials/fct.credentials.modal.component';
import {FctPromptModalComponent} from '../modals/prompt/fct.prompt.modal.component';
import {monthName} from '../../../../common/dates';
import {DevExpressDataGridUIService} from '../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {FctDadosTodosTrabComFCModalComponent} from '../modals/dadostodostrabcomfc/fct.dadosTodosTrabComFC.modal.component';
import {FctPedidoReembolsoModalComponent} from '../modals/pedidoreembolso/fct.pedidoReembolso.modal.component';
import {cloneDeep} from 'lodash-es';

export const EXCEPTION_FCT_CREDENTIALS = 'EObjFCTCredentials';
export const EXCEPTION_AT_SERVICE = 'EATServiceException';
export const FCT_INCORRECT_CREDENTIALS_MESSAGE = 'Dados de acesso incorretos';
export const HTTP_STATUS_BAD_REQUEST = 500;

const TOOLBAR_INSTANCE_ID_EMPRESAS = 'TOOLBAR_EMPRESAS';
const FCT_DOCUMENTO_EMITIDO_MESSAGE = 'O Documento de Pagamento já foi Emitido';
const FCT_DOCUMENTO_LIQUIDADO_MESSAGE = 'O Documento de Pagamento já foi Liquidado';
const FCT_SITE = 'https://www.fundoscompensacao.pt/sso/login?service=https%3A%2F%2Fwww.fundoscompensacao.pt%2Ffc%2Fcaslogin';
const NOME_EMPRESA_MAX_LENGTH = 15;

@Component({
  selector: 'fct',
  templateUrl: './fct.module.component.html'
})
export class FctComponent extends ModuloComponent implements OnInit, OnDestroy {
  public toolbarEmpresasId: string;
  public searchFilters: IFctSearchFilters;
  public searchFiltersTiposDoc: Array<IFctValueName>;
  public searchFiltersNEmpresa: Array<IFctSearchFiltersNEmpresa>;
  public empresasPromise: Promise<unknown>;
  public emitirDocumentoSource: Array<IFctValueName>;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly dataGridDocsDefinition: IDevExpressDataGrid;
  public readonly loginStatus: typeof EFctLoginStatus;
  public readonly messageType: typeof EFctMessageType;
  public readonly searchFiltersNEmpresaTemplate: string;

  private _dataGridInstance: dxDataGrid;
  private _dataGridDocsInstance: dxDataGrid;
  private _empresasSource: Array<IFctEmpresasDTItem>;
  private readonly _comSelecionadosToolbarBtn: TPlToolbarItem;
  private readonly _obterValorToolbarBtn: IPlToolbarMenuItem;
  private readonly _emitirDocumentoToolbarBtn: IPlToolbarMenuItem;
  private readonly _reEnviarEmailToolbarBtn: IPlToolbarMenuItem;
  private readonly _downloadComprovativoToolbarBtn: IPlToolbarMenuItem;
  private readonly _viewDadosTodosTrabalhadoresComFCTToolbarBtn: TPlToolbarItem;
  private readonly _pedirReembolsoToolbarBtn: TPlToolbarItem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _fctService: FctService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _cgModalService: CGModalService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService
  ) {
    super(_injector);
    this.searchFiltersNEmpresaTemplate = '{{nEmpresa}} - {{nome}}';
    this.messageType = EFctMessageType;
    this.loginStatus = EFctLoginStatus;
    this._empresasSource = [];
    this.searchFiltersNEmpresa = [];
    this.searchFiltersTiposDoc = [
      {value: EFctSearchTipoDocumento.NONE, name: 'fct.strings.searchTipoDocTodos'},
      {value: EFctSearchTipoDocumento.ADEF, name: 'fct.strings.searchTipoDocADEF'},
      {value: EFctSearchTipoDocumento.ADEG, name: 'fct.strings.searchTipoDocADEG'},
      {value: EFctSearchTipoDocumento.DOCP, name: 'fct.strings.searchTipoDocDOCP'}
    ];
    this.searchFilters = {
      startDate: moment().startOf('month'),
      endDate: moment().endOf('month'),
      tipoDocumento: EFctSearchTipoDocumento.DOCP,
      nempresa: this.session.erp.nEmpresa,
      nome: this.session.erp.nomeEmpresa
    };

    const fctMonthName: string = monthName(moment().month());

    this.emitirDocumentoSource = [
      {value: EFctTipoDocumento.DEFAULT, name: ''},
      {value: EFctTipoDocumento.PARTIAL, name: `Mês - ${fctMonthName}`},
      {value: EFctTipoDocumento.TOTAL, name: 'fct.strings.valorTotal'}
    ];

    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {
          dataField: 'nEmpresa',
          dataType: 'string',
          caption: 'fct.tableDefinition.nempresa',
          visible: false,
          allowEditing: false
        },
        {
          dataField: 'nome',
          dataType: 'string',
          caption: 'fct.tableDefinition.nome',
          allowEditing: false,
          sortOrder: 'asc',
          fixed: true,
          fixedPosition: 'left',
          width: 250
        },
        {
          width: 150,
          dataField: 'valorEmAtrasoCent',
          dataType: 'currency',
          caption: 'fct.tableDefinition.valorEmAtraso',
          allowEditing: false,
          format: {
            type: 'currency',
            precision: 2
          }
        },
        {
          width: 120,
          dataField: 'valor',
          dataType: 'currency',
          caption: `FCT ${fctMonthName}`,
          allowEditing: false,
          format: {
            type: 'currency',
            precision: 2
          }
        },
        {
          width: 120,
          dataField: 'valorFCTNoCG',
          dataType: 'currency',
          caption: `CG ${fctMonthName}`,
          allowEditing: false,
          format: {
            type: 'currency',
            precision: 2
          }
        },
        {
          width: 130,
          dataField: 'emitirTipoDocumento',
          caption: 'fct.tableDefinition.valorAPagar',
          editCellTemplate: 'emitirTipoDocumento',
          allowEditing: true,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: true,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false,
          showEditorAlways: true
        },
        {
          dataField: 'email',
          dataType: 'string',
          caption: 'fct.tableDefinition.email',
          showEditorAlways: true,
          minWidth: 200,
          cssClass: 'fct-col-empresa-nome-max-width'
        },
        {
          dataField: 'messageCellTemplate',
          cellTemplate: 'messageCellTemplate',
          dataType: 'string',
          caption: 'fct.tableDefinition.mensagem',
          allowEditing: false,
          cssClass: 'fct-col-empresa-nome-max-width'
        },
        {
          width: 120,
          dataField: 'actions',
          caption: '',
          fixed: true,
          fixedPosition: 'right',
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: true,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false
        }
      ],
      editing: {
        allowUpdating: true,
        mode: 'cell'
      },
      selection: {
        mode: 'multiple',
        showCheckBoxesMode: 'always'
      },
      dataSource: [],
      keyExpr: 'nEmpresa',
      remoteOperations: false
    };

    this.dataGridDocsDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'dataEmissao', dataType: 'date', caption: 'fct.tableDefinition.dataEmissao'},
        {dataField: 'documento', dataType: 'string', caption: 'fct.tableDefinition.documento'},
        {dataField: 'dataPagamento', dataType: 'date', caption: 'fct.tableDefinition.dataPagamento'},
        {dataField: 'tipoPagamento', dataType: 'string', caption: 'fct.tableDefinition.valorPago'},
        {
          width: 150,
          dataField: 'actions',
          caption: 'fct.tableDefinition.pdf',
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: true,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false
        }
      ],
      dataSource: [],
      remoteOperations: false
    };

    this._comSelecionadosToolbarBtn = {
      id: `${TOOLBAR_INSTANCE_ID_EMPRESAS}-com-selecionados`,
      type: 'dropdown',
      disabled: true,
      class: 'btn-primary',
      iconLeft: '<i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;',
      caption: 'fct.toolbar.comSelecionados',
      tooltip: {
        text: 'fct.tooltips.comSelecionados',
        container: 'body'
      },
      menu: []
    };

    this._obterValorToolbarBtn = {
      id: `${TOOLBAR_INSTANCE_ID_EMPRESAS}-obter-valor`,
      iconLeft: '<i class="fa fa-fw fa-cloud-download" aria-hidden="true"></i>&nbsp;',
      caption: 'fct.toolbar.obterValores',
      tooltip: {
        text: 'fct.tooltips.obterValores',
        container: 'body'
      },
      click: () => {
        return this._obterValores();
      }
    };

    this._emitirDocumentoToolbarBtn = {
      id: `${TOOLBAR_INSTANCE_ID_EMPRESAS}-emitir-documento`,
      iconLeft: '<i class="fa fa-fw fa-bolt" aria-hidden="true"></i>&nbsp;',
      caption: 'fct.toolbar.emitirDocumento',
      tooltip: {
        text: 'fct.tooltips.emitirDocumento',
        container: 'body'
      },
      click: () => {
        return this._emitirDocumento();
      }
    };

    this._reEnviarEmailToolbarBtn = {
      id: `${TOOLBAR_INSTANCE_ID_EMPRESAS}-re-enviar-mail`,
      iconLeft: '<i class="fa fa-fw fa-envelope-o" aria-hidden="true"></i>&nbsp;',
      caption: 'fct.strings.enviarEmail',
      tooltip: {
        text: 'fct.tooltips.enviarEmail',
        container: 'body'
      },
      click: () => {
        return this._enviarEmailSelecionados();
      }
    };

    this._downloadComprovativoToolbarBtn = {
      id: `${TOOLBAR_INSTANCE_ID_EMPRESAS}-download-comprovativo`,
      iconLeft: '<i class="fa fa-fw fa-download" aria-hidden="true"></i>&nbsp;',
      caption: 'fct.toolbar.downloadDoc',
      tooltip: {
        text: 'fct.tooltips.downloadDoc',
        container: 'body'
      },
      click: () => {
        return this._donwloadDocSelecionados();
      }
    };

    this._viewDadosTodosTrabalhadoresComFCTToolbarBtn = {
      id: `${TOOLBAR_INSTANCE_ID_EMPRESAS}-view-todos-trabalhadores-com-fct`,
      type: 'button',
      class: 'btn-info',
      iconLeft: '<i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;',
      caption: 'fct.toolbar.viewTodosTrabComFct',
      tooltip: {
        text: 'fct.tooltips.viewTodosTrabComFct',
        container: 'body'
      },
      click: () => {
        return this._viewDadosTodosTrabalhadoresComFC();
      }
    };

    this._pedirReembolsoToolbarBtn = {
      id: `${TOOLBAR_INSTANCE_ID_EMPRESAS}-pedir-reelmbolso-fct`,
      type: 'button',
      disabled: true,
      class: 'btn-warning',
      iconLeft: '<i class="fa fa-bolt" aria-hidden="true"></i>&nbsp;',
      caption: 'fct.toolbar.pedirReembolso',
      tooltip: {
        text: 'fct.tooltips.pedirReembolso',
        container: 'body'
      },
      click: () => {
        return this._viewPedidoReembolsoModal();
      }
    };

    this._comSelecionadosToolbarBtn.menu = [this._obterValorToolbarBtn, this._emitirDocumentoToolbarBtn, this._reEnviarEmailToolbarBtn, this._downloadComprovativoToolbarBtn];

    const toolbarInstance = this._plToolbarService.getInstance(this.toolbarEmpresasId);
    toolbarInstance.setItems([this._comSelecionadosToolbarBtn, this._viewDadosTodosTrabalhadoresComFCTToolbarBtn, this._pedirReembolsoToolbarBtn]);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'fctSite',
      order: 1,
      type: 'link',
      iconLeft: '<i class="fa fa-info-circle"></i>&nbsp;',
      class: 'btn-light',
      caption: 'FCT',
      href: {
        value: FCT_SITE,
        target: '_blank'
      }
    });
    this.empresasPromise = this._fctService.getEmpresas().then((response) => {
      this._empresasSource = [];
      response.body.forEach((item) => {
        const sourceItem: IFctEmpresasDTItem = {
          ...item,
          emitido: false,
          mensagens: [],
          valor: 0,
          valorEmAtrasoCent: 0,
          emitirDocumento: EFctTipoDocumento.DEFAULT,
          loginStatus: EFctLoginStatus.UNKNOWN,
          _canViewDetails: false,
          _canSendEmail: false
        };
        if (sourceItem.lastMessages?.length) {
          sourceItem.mensagens = cloneDeep(sourceItem.lastMessages);
          sourceItem.mensagens.forEach((msgItem) => {
            if (msgItem.msgType === EFctMessageType.CREDENTIALS_WARNING) {
              sourceItem.loginStatus = EFctLoginStatus.REQUIRE;
            } else if (msgItem.msg.toLowerCase().includes(FCT_DOCUMENTO_EMITIDO_MESSAGE.toLowerCase()) || msgItem.msg.toLowerCase().includes(FCT_DOCUMENTO_LIQUIDADO_MESSAGE.toLowerCase())) {
              sourceItem._canSendEmail = true;
            }
          });
        }
        this._empresasSource.push(sourceItem);
        this.searchFiltersNEmpresa.push({nEmpresa: item.nEmpresa, nome: item.nome});
      });
      this._comSelecionadosToolbarBtn.disabled = !this._empresasSource.length;
      this._pedirReembolsoToolbarBtn.disabled = !this._empresasSource.length;
      this.dataGridDefinition.dataSource = this._empresasSource;
    });
  }

  public setInstanceName(value: string): void {
    super.setInstanceName(value);
    this.toolbarEmpresasId = `${this.instanceName}-${TOOLBAR_INSTANCE_ID_EMPRESAS}`;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._plToolbarService.unRegisterInstance(this.toolbarEmpresasId);
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
    this._dataGridInstance.beginCustomLoading('');
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onDocsInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridDocsInstance = component;
  }

  public onDocsContentReady(): void {
    this._dataGridDocsInstance.endCustomLoading();
  }

  public searchDocs(): Promise<void> {
    if (isUndefinedOrNull(this.searchFilters.nempresa)) {
      this._plAlertService.error('fct.messages.selecionaEmpresa');
      return Promise.resolve();
    }
    if (this._dataGridDocsInstance) {
      this._dataGridDocsInstance.beginCustomLoading(undefined);
    }
    return this._fctService
      .obterDocumentos(this.searchFilters.nempresa, this.searchFilters.startDate, this.searchFilters.endDate, this.searchFilters.tipoDocumento)
      .then((response) => {
        if (response.body.success) {
          this.dataGridDocsDefinition.dataSource = response.body.data;
        } else {
          this._plAlertService.error(response.body.errorMessage);
        }
      })
      .catch((reason) => {
        this.dataGridDocsDefinition.dataSource = [];
        const exception: ICGExceptionError = this._cgExceptionService.get(reason);
        this._plAlertService.error(exception.message);
      })
      .finally(() => {
        if (this._dataGridDocsInstance) {
          this._dataGridDocsInstance.endCustomLoading();
        }
      });
  }

  public getMessageCellContent(item: IFctEmpresasDTItem): string {
    if (!item.mensagens.length) {
      return '';
    }

    const messages: Array<string> = [];
    item.mensagens.forEach((messageItem) => {
      let icon = '';
      if (messageItem.msgType === EFctMessageType.ERROR) {
        icon = '<i class="fa fa-exclamation-circle text-danger" aria-hidden="true"></i>&nbsp;';
      } else if (messageItem.msgType === EFctMessageType.WARNING || messageItem.msgType === EFctMessageType.CREDENTIALS_WARNING) {
        icon = '<i class="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i>&nbsp;';
      } else {
        icon = '<i class="fa fa-info-circle text-success" aria-hidden="true"></i>&nbsp;';
      }

      messages.push(`${icon}${messageItem.msg}`);
    });
    return messages.join('<br/>');
  }

  public getTooltipMessage(item: IFctEmpresasDTItem): string {
    if (!item.mensagens.length) {
      return '';
    }
    const messages: Array<string> = item.mensagens.map((msg) => {
      return msg.msg;
    });
    return messages.join('<br/>');
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IFctDocumentoItem>): void {
    if (event.rowType === 'data') {
      if (event.column.dataField === 'dataPagamento' && event.data.dataPagamento?.length === 0) {
        event.cellElement.innerHTML = this._translateService.instant('fct.messages.porPagar');
      }
    }
  }

  public onMainCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IFctEmpresasDTItem>): void {
    if (event.rowType === 'data') {
      if (event.data.loginStatus === EFctLoginStatus.UNKNOWN) {
        if (event.data.valor !== event.data.valorFCTNoCG) {
          event.cellElement.classList.add('valorDiffCssClass');
        }
      }
    }
  }

  public searchFiltersNEmpresaChanged(item: IFctSearchFiltersNEmpresa): void {
    this.searchFilters.nempresa = item.nEmpresa;
    this.searchFilters.nome = item.nome;
  }

  public addContextMenuItems(event: IDevExpressDataGridEventOnContextMenuPreparing<IFctEmpresasDTItem>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();

      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = [
        {
          icon: 'fa fa-cog',
          caption: this._translateService.instant('fct.messages.configurarCredentials'),
          click: () => this._configCredentials(event.row.data)
        }
      ];

      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
    }
  }

  public keydownStartDate(value: string, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      const control: HTMLElement = document.querySelector('[data-attr-name="endDate"] input');
      if (control) {
        setTimeout(() => {
          control.focus();
        });
      }
    }
  }

  public keydownEndDate(value: string, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ENTER) {
      event.stopPropagation();
      const control: HTMLElement = document.querySelector('[data-attr-name="tipoDocumento"] select');
      if (control) {
        setTimeout(() => {
          control.focus();
        });
      }
    }
  }

  public readonly fnObterDocumento = (item: IFctDocumentoItem) => (): Promise<unknown> => this._obterDocumento(item);
  public readonly fnObterDetalhes = (item: IFctEmpresasDTItem) => (): Promise<unknown> => this._obterDetalhes(item);
  public readonly fnConfigCredentials = (item: IFctEmpresasDTItem) => (): Promise<unknown> => this._configCredentials(item);
  public readonly fnEnviarEmail = (line: IFctEmpresasDTItem) => (): Promise<void> => this._enviarEmail(line);

  private _obterValores(): Promise<unknown> {
    this._empresasSource.forEach((item) => {
      item.mensagens = [];
    });
    this._updateGridDimensions();

    const selectedEmpList: Array<IFctEmpresasDTItem> = this._dataGridInstance.getSelectedRowsData();
    if (!selectedEmpList.length) {
      this._plAlertService.info('fct.messages.selectRecords');
      return Promise.resolve();
    }
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    const promises: Array<Promise<unknown>> = [];
    selectedEmpList.forEach((item) => {
      const promise = this._fctService
        .obterValor(item.nEmpresa)
        .then((response) => {
          item._canSendEmail = false;

          if (response.body.success) {
            item.valor = response.body.data.valorCent > 0 ? response.body.data.valorCent / 100 : response.body.data.valorCent;
            item.valorEmAtrasoCent = response.body.data.valorEmAtrasoCent > 0 ? response.body.data.valorEmAtrasoCent / 100 : response.body.data.valorEmAtrasoCent;

            item.loginStatus = EFctLoginStatus.OK;
            item._canViewDetails = true;
            item.emitirDocumento = EFctTipoDocumento.TOTAL;
            this._addEmpresaMessage(item, this._translateService.instant('fct.messages.valorObtidoComSucesso'), EFctMessageType.SUCCESS);
          }
          if (response.body.errorMessage.length) {
            item._canViewDetails = false;
            this._addEmpresaMessage(item, response.body.errorMessage, EFctMessageType.ERROR);
            if (response.body.errorMessage !== FCT_INCORRECT_CREDENTIALS_MESSAGE) {
              item.loginStatus = EFctLoginStatus.OK;
              if (
                response.body.errorMessage.toLowerCase().includes(FCT_DOCUMENTO_EMITIDO_MESSAGE.toLowerCase()) ||
                response.body.errorMessage.toLowerCase().includes(FCT_DOCUMENTO_LIQUIDADO_MESSAGE.toLowerCase())
              ) {
                item._canSendEmail = true;
              }
            } else {
              item.loginStatus = EFctLoginStatus.REQUIRE;
              this._addEmpresaMessage(item, response.body.errorMessage, EFctMessageType.WARNING);
            }
          }
        })
        .catch((reason) => {
          this._handleException(reason, item);
        });
      promises.push(promise);
    });

    return Promise.allSettled(promises).finally(() => {
      if (this._dataGridInstance) {
        this._dataGridInstance.endCustomLoading();
      }
    });
  }

  private _emitirDocumento(): Promise<unknown> {
    this._empresasSource.forEach((item) => {
      item.mensagens = [];
    });
    this._updateGridDimensions();

    const selectedEmpList: Array<IFctEmpresasDTItem> = this._dataGridInstance.getSelectedRowsData();
    if (!selectedEmpList.length) {
      this._plAlertService.info('fct.messages.selectRecords');
      return Promise.resolve();
    }

    const modalInstance = this._cgModalService.showVanilla(FctPromptModalComponent, {size: 'md'});
    const componentInstance: FctPromptModalComponent = modalInstance.componentInstance;
    componentInstance.numSelectedRecords = selectedEmpList.length;

    return new Promise((resolve) => {
      modalInstance.result
        .then((options: IFctEmitirDocPromptResult) => {
          if (this._dataGridInstance) {
            this._dataGridInstance.beginCustomLoading(undefined);
          }
          const promises: Array<Promise<unknown>> = [];
          selectedEmpList.forEach((item) => {
            let tipoDoc = item.emitirDocumento;
            if (item.valorEmAtrasoCent === 0 && tipoDoc !== EFctTipoDocumento.DEFAULT) {
              tipoDoc = EFctTipoDocumento.DEFAULT;
            } else if (item.valorEmAtrasoCent > 0 && tipoDoc === EFctTipoDocumento.DEFAULT) {
              tipoDoc = EFctTipoDocumento.TOTAL;
            }
            const promise = this._fctService
              .emitirDocumento(item.nEmpresa, tipoDoc)
              .then((blob) => {
                item._canViewDetails = false;
                this._addEmpresaMessage(item, this._translateService.instant('fct.messages.emitidoComSucesso'), EFctMessageType.SUCCESS);
                if (options.downloadDoc) {
                  const dateStr = moment().subtract(1, 'months').format('YYYY[_]MM');
                  downloadStream(blob, `${item.nome.substring(0, NOME_EMPRESA_MAX_LENGTH).toUpperCase()}_${item.nEmpresa}_FCT_REF_${dateStr}.pdf`);
                }
                if (options.sendEmail) {
                  promises.push(this._enviarEmail(item));
                }
              })
              .catch((reason) => {
                this._handleException(reason, item);
              });
            promises.push(promise);
          });

          return Promise.allSettled(promises).finally(() => {
            if (this._dataGridInstance) {
              this._dataGridInstance.endCustomLoading();
            }
            resolve(undefined);
          });
        })
        .catch(() => {
          resolve(undefined);
        });
    });
  }

  private _handleException(reason: HttpErrorResponse, item: IFctEmpresasDTItem): void {
    const exception: ICGExceptionError = this._cgExceptionService.get(reason);
    if (exception.class === EXCEPTION_FCT_CREDENTIALS || exception.message === FCT_INCORRECT_CREDENTIALS_MESSAGE) {
      item.loginStatus = EFctLoginStatus.REQUIRE;
      this._addEmpresaMessage(item, exception.message, EFctMessageType.WARNING);
    } else if (exception.class !== EXCEPTION_AT_SERVICE && exception.status === HTTP_STATUS_BAD_REQUEST) {
      this._addEmpresaMessage(item, this._translateService.instant('fct.messages.httpErrorTenteNovamente'), EFctMessageType.ERROR);
    } else {
      this._addEmpresaMessage(item, exception.message, EFctMessageType.ERROR);
    }
  }

  private _obterDocumento(item: IFctDocumentoItem): Promise<unknown> {
    return this._fctService
      .obterDocumento(this.searchFilters.nempresa, this.searchFilters.startDate, this.searchFilters.endDate, this.searchFilters.tipoDocumento, item.searchIdDoc, item.hash)
      .then((blob) => {
        if (!blob) {
          this._plAlertService.error(this._translateService.instant('fct.messages.erroDownload'));
        } else {
          const dateStr = moment().subtract(1, 'months').format('YYYY[_]MM');
          downloadStream(blob, `${this.searchFilters.nome.substring(0, NOME_EMPRESA_MAX_LENGTH).toUpperCase()}_${this.searchFilters.nempresa}_FCT_REF_${dateStr}.pdf`);
        }
      });
  }

  private _obterDetalhes(item: IFctEmpresasDTItem): Promise<unknown> {
    return this._fctService
      .obterDetalhes(item.nEmpresa)
      .then((response) => {
        if (response.body.success) {
          const blob = new Blob([response.body.data.stream], {type: 'text/csv'});
          downloadStream(blob, response.body.data.filename);
        } else {
          this._plAlertService.error(response.body.errorMessage);
        }
      })
      .catch((reason) => {
        this._handleException(reason, item);
      });
  }

  private _configCredentials(item: IFctEmpresasDTItem): Promise<void> {
    return new Promise((resolve, reject) => {
      const modalInstance = this._cgModalService.showVanilla(FctCredentialsModalComponent, {size: 'sm', backdrop: 'static'});
      const componentInstance: FctCredentialsModalComponent = modalInstance.componentInstance;
      componentInstance.nomeEmpresa = `${item.nEmpresa} - ${item.nome}`;
      modalInstance.result
        .then((model: IFctCredentials) => {
          if (model.password.length && model.password.length) {
            this._fctService
              .setFCTCredentials(item.nEmpresa, model.niss, model.password)
              .then(() => {
                item.loginStatus = EFctLoginStatus.UNKNOWN;
                item.mensagens = [];
                this._updateGridDimensions();
                resolve();
              })
              .catch(reject);
          }
        })
        .catch(reject);
    });
  }

  private _addEmpresaMessage(item: IFctEmpresasDTItem, messageText: string, type: EFctMessageType, clear: boolean = true): void {
    if (clear) {
      item.mensagens = [];
    }
    item.mensagens.push({msg: messageText, msgType: type});
    this._updateGridDimensions();
  }

  private _updateGridDimensions(): void {
    setTimeout(() => {
      this._dataGridInstance.updateDimensions();
    });
  }

  private _enviarEmail(line: IFctEmpresasDTItem): Promise<void> {
    if (!line.email?.length) {
      this._plAlertService.error('fct.messages.verificaEmail');
      return Promise.resolve();
    }

    return this._fctService
      .enviarDocPagamentoEmail(line.nEmpresa, line.email)
      .then((response) => {
        if (response.body.success) {
          this._addEmpresaMessage(line, this._translateService.instant('fct.messages.emailEnviado'), EFctMessageType.SUCCESS, false);
        } else {
          this._addEmpresaMessage(line, this._translateService.instant('fct.messages.emailNaoEnviado'), EFctMessageType.ERROR, false);
        }
      })
      .catch((reason) => {
        this._handleException(reason, line);
      });
  }

  private _enviarEmailSelecionados(): Promise<unknown> {
    const selectedEmpList: Array<IFctEmpresasDTItem> = this._dataGridInstance.getSelectedRowsData();
    if (!selectedEmpList.length) {
      this._plAlertService.info('fct.messages.selectRecords');
      return Promise.resolve();
    }
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    const promises: Array<Promise<unknown>> = [];
    selectedEmpList.forEach((item) => {
      promises.push(this._enviarEmail(item));
    });

    return Promise.allSettled(promises).finally(() => {
      if (this._dataGridInstance) {
        this._dataGridInstance.endCustomLoading();
      }
    });
  }

  private _donwloadDocSelecionados(): Promise<unknown> {
    const selectedEmpList: Array<IFctEmpresasDTItem> = this._dataGridInstance.getSelectedRowsData();
    if (!selectedEmpList.length) {
      this._plAlertService.info('fct.messages.selectRecords');
      return Promise.resolve();
    }
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    const promises: Array<Promise<unknown>> = [];

    selectedEmpList.forEach((item) => {
      const promise = this._fctService.obterDocumentoLast(item.nEmpresa).then((blob) => {
        if (!blob) {
          this._addEmpresaMessage(item, this._translateService.instant('fct.messages.erroDownload'), EFctMessageType.ERROR);
        } else {
          const dateStr = moment().subtract(1, 'months').format('YYYY[_]MM');
          downloadStream(blob, `${item.nome.substring(0, NOME_EMPRESA_MAX_LENGTH).toUpperCase()}_${item.nEmpresa}_FCT_REF_${dateStr}.pdf`);
        }
      });

      promises.push(promise);
    });

    return Promise.allSettled(promises).finally(() => {
      if (this._dataGridInstance) {
        this._dataGridInstance.endCustomLoading();
      }
    });
  }

  private _viewDadosTodosTrabalhadoresComFC(): Promise<void> {
    return this._cgModalService.show(FctDadosTodosTrabComFCModalComponent, {size: 'fullscreen'});
  }

  private _viewPedidoReembolsoModal(): Promise<void> {
    return this._cgModalService.show(FctPedidoReembolsoModalComponent, {size: 'xxl', backdrop: 'static', keyboard: false});
  }
}
