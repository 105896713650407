import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {copy, PlAlertService} from 'pl-comps-angular';
import {ModuloComponent} from '../../../../components/module/module.component';
import {ETipoCategoria} from '../../../../datasources/tipocategoria/tipoCategoria.datasource.interface';
import {IJsonConfigRHManutencaoCessacaoContrato, IJsonConfigRMMG} from '../../jsonRHConfiguracoes.module.interface';
import {RHConfiguracoesService} from '../../rhConfiguracoes.module.service';

@Component({
  selector: 'module-rhmanutencao',
  templateUrl: './rhManutencao.module.component.html'
})
export class RhManutencaoModuleComponent extends ModuloComponent implements OnInit {
  @Input() public cessacaoContrato: IJsonConfigRHManutencaoCessacaoContrato;
  @Input() public configRMMG: IJsonConfigRMMG;

  public readonly outputAbdes: string;
  public readonly filterCaducidade: string;
  public readonly filterDespedimento: string;
  public readonly filterGlobal: string;
  public readonly filterSubFerias: string;
  public readonly filterFerias: string;
  public tooltipProcessarMsg: string;
  public promise: Promise<void>;
  public processarRMMGDisabled: boolean;

  private _cessacaoContratoOriginal: IJsonConfigRHManutencaoCessacaoContrato;
  private _configRMMGOriginal: IJsonConfigRMMG;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _translateService: TranslateService,
    private readonly _rhConfiguracoesService: RHConfiguracoesService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.processarRMMGDisabled = true;
    this.outputAbdes = '{{codAbDesc}} - {{designacaoBreve}}';
    this.filterCaducidade = `grupoAbonoCategoria=${ETipoCategoria.INDEMNIZACAO_CADUCIDADE}`;
    this.filterDespedimento = `grupoAbonoCategoria=${ETipoCategoria.INDEMNIZACAO_DESPEDIMENTO}`;
    this.filterGlobal = `grupoAbonoCategoria=${ETipoCategoria.INDEMNIZACAO_GLOBAL}`;
    this.filterSubFerias = `grupoAbonoCategoria=${ETipoCategoria.SUB_FERIAS}`;
    this.filterFerias = `grupoAbonoCategoria=${ETipoCategoria.FERIAS_PAGAS}`;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._cessacaoContratoOriginal = copy<IJsonConfigRHManutencaoCessacaoContrato>(this.cessacaoContrato);
    this.btnSave.visible = true;
    this.btnSave.click = this._onSave.bind(this);
    this.btnCancel.visible = true;
    this.btnCancel.click = this._onCancel.bind(this);
    this.processarRMMGDisabled = !this.configRMMG.gerenteSelecionado;
    this._configRMMGOriginal = copy<IJsonConfigRMMG>(this.configRMMG);
    this._evaluateTooltipMessage();
  }

  public readonly fnProcessRMMG: () => Promise<void> = () => this._processRMMG();

  public onGerentesSelecionadoChanged(value: boolean): void {
    this.configRMMG.gerenteSelecionado = value;
    this.processarRMMGDisabled = !(this.configRMMG.gerenteSelecionado && this._configRMMGOriginal.gerenteSelecionado);
    this._evaluateTooltipMessage();
  }

  private async _onSave(): Promise<void> {
    await this._rhConfiguracoesService.updateManutencao(this.cessacaoContrato).then((response: HttpResponse<IJsonConfigRHManutencaoCessacaoContrato>) => {
      this.cessacaoContrato = response.body;
      this._cessacaoContratoOriginal = copy<IJsonConfigRHManutencaoCessacaoContrato>(this.cessacaoContrato);
      this._plAlertService.success('rhmanutencao.message.savedsuccessfully');
    });
    await this._rhConfiguracoesService.updateRMMGConfigs(this.configRMMG).then((response) => {
      this.configRMMG = response.body;
      this._configRMMGOriginal = copy<IJsonConfigRMMG>(this.configRMMG);
      if (this.configRMMG.gerenteSelecionado) {
        this.processarRMMGDisabled = false;
      }
      this._evaluateTooltipMessage();
      this._plAlertService.success('rhmanutencao.rmmg.saved');
    });
  }

  private _onCancel(): void {
    this.cessacaoContrato = copy<IJsonConfigRHManutencaoCessacaoContrato>(this._cessacaoContratoOriginal);
  }

  private _processRMMG(): Promise<void> {
    return this._rhConfiguracoesService.processarRMMG().then(() => {
      this._plAlertService.success('Processado com sucesso');
    });
  }

  private _evaluateTooltipMessage(): void {
    if (!this.configRMMG.gerenteSelecionado && !this._configRMMGOriginal.gerenteSelecionado) {
      this.tooltipProcessarMsg = this._translateService.instant('rhmanutencao.rmmg.tooltip.needSaveAndCheck');
    }
    if (!this.configRMMG.gerenteSelecionado && this._configRMMGOriginal.gerenteSelecionado) {
      this.tooltipProcessarMsg = this._translateService.instant('rhmanutencao.rmmg.tooltip.needCheck');
    }
    if (this.configRMMG.gerenteSelecionado && !this._configRMMGOriginal.gerenteSelecionado) {
      this.tooltipProcessarMsg = this._translateService.instant('rhmanutencao.rmmg.tooltip.needSave');
    }
  }
}
