<div
  *ngIf="showToast"
  #elementToast
  class="cgc-toast alert"
  [ngClass]="[alertClass, toast.cssClass || '']"
  [class.cgc-toast-clickable]="toast.closeOnClick"
  [@shake]="attentionSeeker"
  [@animation]="{value: true, params: {toastSize: toastSize}}"
  (@animation.done)="animationDone()">
  <div *ngIf="icon || toast.iconTemplate" class="cgc-toast-icon">
    <i *ngIf="icon" [cgcIcon]="icon"></i>

    <div *ngIf="toast.iconTemplate" class="cgc-toast-icon-template">
      <ng-container *ngTemplateOutlet="toast.iconTemplate; context: {$implicit: toast, toast: toast}"></ng-container>
    </div>
  </div>

  <div class="cgc-toast-content">
    <div *ngIf="toast.title || toast.titleTemplate" class="cgc-toast-title">
      <span *ngIf="toast.title" [innerHTML]="toast.title | cgcTranslate"></span>

      <div *ngIf="toast.titleTemplate" class="cgc-toast-title-template">
        <ng-container *ngTemplateOutlet="toast.titleTemplate; context: {$implicit: toast, toast: toast}"></ng-container>
      </div>
    </div>

    <div *ngIf="text || toast.textTemplate" class="cgc-toast-text">
      <span *ngIf="text" [innerHTML]="text | cgcTranslate"></span>

      <div *ngIf="toast.textTemplate" class="cgc-toast-text-template">
        <ng-container *ngTemplateOutlet="toast.textTemplate; context: {$implicit: toast, toast: toast}"></ng-container>
      </div>
    </div>
  </div>
</div>
