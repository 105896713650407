import {Observable} from 'rxjs';
import {IDocComercial} from '../docsComerciais.entity.interface';
import {IDocumentoFaturacao} from '../../../components/documento/facturacao/documento.facturacao.component.interface';
import {IJsonDocComercial, IJsonDocComercialClifo, IJsonDocUltimoDocGlobalCab} from '../jsonDocComercial.entity.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {EFacPublicaModelo} from '../../../modules/faturacaopublica/jsonFaturacaoPublica.module.interface';

export enum EDocsComerciaisImportadorLinhasExtraField {
  NOMEARTIGO,
  CUNIMO,
  NARMAZ,
  NVENDEDOR,
  NCCUSTO,
  NREFPROCESSO
}

export interface IDocumentosService {
  getPdf(doc: IJsonDocComercial, preview: boolean): Promise<void> | void;

  getPdfUrl(faccbId: number, nvias: number, rptname: string, segundavia: boolean, preview: boolean, forceoriginal: boolean, assinar: boolean, geranovo: boolean): Observable<string>;

  enviaPdfPorEmail(faccbId: number, nvias: number, rptname: string, segundavia: boolean, email: string, forceoriginal: boolean, emitirNovaFatEletronica: boolean): TServiceResponse<void>;

  comunicarDocWebServiceAT(faccabid: number, assincrono: boolean): Promise<IJsonDocComercial>;

  comunicarDocCirculacaoAT(faccabid: number): Promise<IJsonDocComercial>;

  inserirCodigoTransporteAT(doc: IJsonDocComercial): Promise<IJsonDocComercial>;

  anularDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial>;

  notaCreditoDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> | Promise<boolean>;

  transformarDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> | Promise<boolean>;

  getDoc(id: number): TServiceResponse<IJsonDocComercial>;

  postDoc(doc: IJsonDocComercial, memoria: boolean, terminaDoc: boolean, anexargdocid: string, anexargdocfolderid: string): Promise<IJsonDocComercial> | TServiceResponse<IJsonDocComercial>;

  getStoredDocument(grupo: string): Promise<IDocComercial>;

  storeDocument(grupo: string, value: IDocComercial): Promise<void>;

  clearStoredDocument(grupo: string): Promise<void>;

  getGDoc(doc: IJsonDocComercial): Promise<IJsonDocComercial>;

  getMeiosPagamentoComercial(doc: IJsonDocComercial): Promise<IJsonDocComercial>;

  patchDoc(doc: IJsonDocComercial): Promise<IJsonDocComercial> | TServiceResponse<IJsonDocComercial>;

  encerrarDocumento(doc: IJsonDocComercial, confirmModal: boolean): Promise<IJsonDocComercial>;

  getModeloImportacaoDocLinhas(extraFields: Array<EDocsComerciaisImportadorLinhasExtraField>): TServiceResponse<Blob>;

  importModeloDocLinhasUrl(extraFields: Array<EDocsComerciaisImportadorLinhasExtraField>): Observable<string>;

  obterUltimoDocumentoGlobalCriado(): TServiceResponse<IJsonDocUltimoDocGlobalCab>;
}

export function docComercialDefault(): Required<IDocumentoFaturacao> {
  return {
    cab: {
      docActions: []
    },
    clifo: {
      id: undefined,
      nConta: undefined,
      nIdAltern: undefined,
      nome: undefined,
      segNome: undefined,
      rua: undefined,
      lugar: undefined,
      codPostal: undefined,
      localidade: undefined,
      telefone: undefined,
      fax: undefined,
      codPais: undefined,
      nomePais: undefined,
      abrevFiscal: undefined,
      nContribuint: undefined,
      email: undefined,
      codRet: undefined,
      situacaoActual: undefined,
      codCondComerciais: undefined,
      nomeCondComerciais: undefined,
      codPagamento: undefined,
      nomePagamento: undefined,
      observacoes: undefined,
      observacoes2: undefined,
      facPublicaModelo: undefined,
      iban: undefined,
      telemovel: undefined,
      nomeRet: undefined
    },
    moradaCarga: {
      nConta: undefined,
      nome: undefined,
      rua: undefined,
      codPostal: undefined,
      localidade: undefined,
      nipc: undefined,
      id: undefined,
      observacoes: undefined,
      morada: undefined,
      codPais: undefined,
      nomePais: undefined,
      moralId: undefined
    },
    moradaDescarga: {
      nConta: undefined,
      nome: undefined,
      rua: undefined,
      codPostal: undefined,
      localidade: undefined,
      nipc: undefined,
      id: undefined,
      observacoes: undefined,
      morada: undefined,
      codPais: undefined,
      nomePais: undefined,
      moralId: undefined
    },
    transportador: {
      nGuiaTransporte: undefined,
      dataGuiaTransporte: undefined,
      matricula: undefined,
      matriculaReboque: undefined,
      nomeTransportador: undefined,
      nomeViatura: undefined,
      nomeMotorista: undefined,
      moradaMotorista: undefined,
      codPostalMotorista: undefined,
      codPaisMotorista: undefined
    },
    meiosPagamento: [],
    resumoIva: [],
    linhas: [],
    _downloadUrl: undefined,
    _pdfUrl: undefined,
    nCCustoOutput: '',
    nDocFaOutput: '',
    nCondPagOutput: '',
    moedaOutput: '',
    identificaCarga: false
  };
}

export function docComercialEmptyClifo(): IJsonDocComercialClifo {
  return {
    id: '',
    nConta: '',
    nIdAltern: 0,
    nome: '',
    segNome: '',
    rua: '',
    lugar: '',
    codPostal: '',
    localidade: '',
    telefone: '',
    fax: '',
    codPais: 0,
    nomePais: '',
    abrevFiscal: '',
    nContribuint: '',
    email: '',
    codRet: '',
    situacaoActual: '',
    codCondComerciais: 0,
    nomeCondComerciais: '',
    codPagamento: 0,
    nomePagamento: '',
    observacoes: '',
    observacoes2: '',
    iban: '',
    action: 0,
    codMoeda: 0,
    nomeMoeda: '',
    facPublicaModelo: EFacPublicaModelo.ND,
    telemovel: '',
    nomeRet: ''
  };
}
