import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {copy, downloadStream, IPlNavigatorCallback, IPlToolbarItem, IPlToolbarMenuItem, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ETipModelo10, IQuadro5} from '../modelo10.module.interface';
import {HttpResponse} from '@angular/common/http';
import {IJsonModelo10} from '../jsonModelo10.module.interface';
import {MIN_DATE_CGD} from '../../../../../common/utils/utils';
import {Modelo10ConfigModalComponent} from '../modal/config/modelo10.config.modal.component';
import {Modelo10RetencaoMod10ModalComponent} from '../modal/retencaomod10/modelo10.retencaoMod10.modal.component';
import {Modelo10Service} from '../modelo10.module.service';
import {ModuloComponent} from '../../../../components/module/module.component';
import moment from 'moment';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {MODULE_NAME_RETENCOES} from '../../retencoes/retencoes.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {ModeloConfigwsModalComponent} from '../../modeloconfigwsmodal/modals/modelo.configWS.modal.component';

const TOOLBAR_GROUP_RESPONSIVE = 'module-btns-responsive';

@Component({
  selector: 'module-modelo10',
  templateUrl: './modelo10.module.component.html'
})
export class Modelo10ModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public modelo10: IJsonModelo10;
  public readonly dataGridDefinition: IDevExpressDataGrid<IQuadro5, IQuadro5>;
  public readonly tiposModelo10: typeof ETipModelo10;
  public readonly callbackNav: IPlNavigatorCallback;
  public tipoModelo10: ETipModelo10;

  private readonly _maintenanceInstanceRetencoes: IModuleMaintenanceInstance;
  private readonly _btnNovo: IPlToolbarMenuItem;
  private readonly _btnCriarFicheiro: IPlToolbarMenuItem;
  private readonly _btnValidaAt: IPlToolbarItem;
  private readonly _btnSumbmitAt: IPlToolbarMenuItem;
  private readonly _btnConfigWS: IPlToolbarMenuItem;
  private readonly _btnRetencoes: IPlToolbarMenuItem;
  private _dataGridInstance: dxDataGrid<IQuadro5, IQuadro5>;
  private _promise: Promise<void>;
  private _modelo10Initial: IJsonModelo10;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo10Service: Modelo10Service,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this._maintenanceInstanceRetencoes = this._moduleMaintenanceService.build(MODULE_NAME_RETENCOES);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_index', dataType: 'number', caption: '', allowHeaderFiltering: false, showInColumnChooser: false},
        {dataField: 'nif', dataType: 'number', caption: 'modelo10.groups.reltitrendimentos.quadro5.table.sujpassivo'},
        {
          caption: 'modelo10.groups.reltitrendimentos.quadro5.table.rendAnoAnt',
          alignment: 'center',
          allowHeaderFiltering: false,
          columns: [
            {dataField: 'rendimentosAnterioresValores', dataType: 'double', caption: 'modelo10.groups.reltitrendimentos.quadro5.table.valores'},
            {dataField: 'rendimentosAnterioresNAnos', dataType: 'number', caption: 'modelo10.groups.reltitrendimentos.quadro5.table.nrAnos'},
            {dataField: 'rendimentosAnterioresAno', dataType: 'number', caption: 'global.text.year'}
          ]
        },
        {dataField: 'rendimentosDoAno', dataType: 'double', caption: 'modelo10.groups.reltitrendimentos.quadro5.table.rendAno'},
        {dataField: 'tipoRendimentos', dataType: 'string', caption: 'modelo10.groups.reltitrendimentos.quadro5.table.tipoRend'},
        {dataField: 'localObtencao', dataType: 'string', caption: 'modelo10.groups.reltitrendimentos.quadro5.table.locobtrend'},
        {dataField: 'retencoesIRSIRC', dataType: 'double', caption: 'modelo10.groups.reltitrendimentos.quadro5.table.retIrsIrc'},
        {dataField: 'contribuicoes', dataType: 'double', caption: 'modelo10.groups.reltitrendimentos.quadro5.table.contribObr'},
        {dataField: 'quotizacoes', dataType: 'double', caption: 'modelo10.groups.reltitrendimentos.quadro5.table.quotSindicais'},
        {dataField: 'retencaoSobretaxa', dataType: 'double', caption: 'modelo10.groups.reltitrendimentos.quadro5.table.retSobreTaxa'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnsQuadro5', allowHeaderFiltering: false, showInColumnChooser: false, fixed: true, fixedPosition: 'right'}
      ],
      summary: {
        totalItems: [
          {column: 'rendimentosAnterioresValores', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'rendimentosDoAno', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'retencoesIRSIRC', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'contribuicoes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'quotizacoes', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'retencaoSobretaxa', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      },
      dataSource: [],
      export: {filename: 'modelo10.groups.reltitrendimentos.title'},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'headerTemplateQuadro5AddLinha',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };

    this.tiposModelo10 = ETipModelo10;
    this.callbackNav = {};
    this.tipoModelo10 = ETipModelo10.NewFromDB;
    this._btnNovo = {
      id: 'novo',
      order: 1,
      type: 'dropdown',
      iconLeft: '<i class="fa fa-fw fa-plus-circle"></i>',
      class: 'btn-primary',
      caption: 'global.btn.new',
      menu: [
        {
          id: 'newfromdb',
          caption: 'modelo10.btn.valorescarre',
          click: () => this._getModelo10(ETipModelo10.NewFromDB)
        },
        {
          id: 'newfrommod10',
          caption: 'modelo10.btn.valoresauto',
          click: () => this._getModelo10(ETipModelo10.NewFromMOD10)
        },
        {type: 'divider'},
        {
          id: 'newclear',
          caption: 'modelo10.btn.limpo',
          click: () => this._getModelo10(ETipModelo10.NewClean)
        }
      ]
    };
    this._btnCriarFicheiro = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'criarficheiro',
      type: 'button',
      order: this.btnDelete.order + 1,
      iconLeft: '<i class="fa fa-fw fa-file-text-o"></i>',
      class: 'btn-primary',
      caption: 'modelo10.btn.criarficheiro',
      disabled: false,
      tooltip: {
        disabled: true,
        placement: 'bottom-right',
        container: 'body',
        text: 'modelo10.message.temDeGuardar'
      },
      promise: this.promise,
      click: () => this._criarFicheiro()
    };
    this._btnSumbmitAt = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'submeterNaAT',
      type: 'button',
      caption: 'modelo10.btn.btnSubmeterAt',
      iconLeft: '<i class="fa fa-fw fa-upload"></i>',
      click: () => this._submeterNaAT(),
      disabled: false
    };
    this._btnConfigWS = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'configWS',
      type: 'button',
      caption: 'modelo10.btn.btnConfigWS',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      click: () => this._callConfigWS(),
      disabled: false
    };
    this._btnValidaAt = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'validarNaAT',
      order: this._btnCriarFicheiro.order + 1,
      type: 'dropdown-split',
      class: 'btn-primary',
      caption: 'modelo10.btn.btnValidarAt',
      iconLeft: '<i class="fa fa-fw fa-globe"></i>',
      click: () => this._validarNaAT(),
      disabled: false,
      menu: [this._btnSumbmitAt, this._btnConfigWS]
    };
    this._btnRetencoes = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'retencoes',
      order: this._btnValidaAt.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-bars"></i>',
      class: 'btn-light',
      caption: 'modelo10.btn.retencoes',
      promise: this.promise,
      click: () => this._openModalRetencoes()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._applyModelo10({
      quadro1Campo1: '',
      quadro2Campo2: undefined,
      quadro3Campo3: undefined,
      quadro4: {
        campo01: undefined,
        campo02: undefined,
        campo03: undefined,
        campo04: undefined,
        campo05: undefined,
        campo06: undefined,
        campo07: undefined,
        campo08: undefined,
        campo09: undefined,
        campo10: undefined,
        campo12: undefined,
        campo11: undefined,
        campo13: undefined
      },
      quadro5: [],
      quadro6: {
        campo01: false,
        campo02: false,
        campo03: false,
        campo04: undefined
      },
      quadro7: {
        campo01: undefined,
        campo02: undefined
      },
      ...this.modelo10
    });
    this._modelo10Initial = copy(this.modelo10);

    for (const item of this.modelo10.quadro5) {
      item._isManuallyAdded = false;
    }

    this.dropdownActions.menu = [this.btnDelete, this._btnCriarFicheiro, this._btnSumbmitAt, this._btnConfigWS, this._btnValidaAt, this._btnRetencoes, this.btnConfig];
    this._buildToolbarInitBtns();
    this._buildToolbarResponsive(this.isMobile);
    this.toolbar.addButton(this._btnNovo).addButton(this.btnSave).addButton(this.dropdownActions);

    // Wait for page to render html before scrolling to item
    setTimeout(() => {
      this.callbackNav.select(1);
    });

    this.calcQuadro4Totals();
    this.evaluateButtons(this.tipoModelo10);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    if (this.toolbar) {
      this._buildToolbarResponsive(value);
    }
  }

  public addLine(): void {
    const modalInstance = this._cgModalService.showVanilla(Modelo10RetencaoMod10ModalComponent);
    const componentInstance: Modelo10RetencaoMod10ModalComponent = modalInstance.componentInstance;
    componentInstance.modelo10 = copy(this.modelo10);
    modalInstance.result.then((result: IJsonModelo10) => {
      this._applyModelo10(result);
      this.calcQuadro4Totals();
      this.evaluateButtons(this.tipoModelo10);
    });
  }

  public deleteLine(line: IQuadro5): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'entity.delete.message').then(() => {
      const index = line._index - 1;
      if (index !== -1) {
        this.modelo10.quadro5.splice(index, 1);
        this.dataGridDefinition.dataSource = this.modelo10.quadro5;
      }
      this.promise = this._modelo10Service.deleteQuadro05Line(this.modelo10).then((response: HttpResponse<IJsonModelo10>) => {
        this._applyModelo10(response.body);
        this.calcQuadro4Totals();
        this.evaluateButtons(this.tipoModelo10);
      });
      return this.promise;
    });
  }

  public editLine(line: IQuadro5): void {
    const modalInstance = this._cgModalService.showVanilla(Modelo10RetencaoMod10ModalComponent);
    const componentInstance: Modelo10RetencaoMod10ModalComponent = modalInstance.componentInstance;
    componentInstance.itemQuadro5 = copy(line);
    componentInstance.modelo10 = this.modelo10;
    modalInstance.result.then((result: IJsonModelo10) => {
      this._applyModelo10(result);
      this.calcQuadro4Totals();
      this.evaluateButtons(this.tipoModelo10);
    });
  }

  public calcQuadro4Totals(): void {
    const campo01 = isNaN(this.modelo10.quadro4.campo01) ? 0 : this.modelo10.quadro4.campo01;
    const campo02 = isNaN(this.modelo10.quadro4.campo02) ? 0 : this.modelo10.quadro4.campo02;
    const campo03 = isNaN(this.modelo10.quadro4.campo03) ? 0 : this.modelo10.quadro4.campo03;
    const campo05 = isNaN(this.modelo10.quadro4.campo05) ? 0 : this.modelo10.quadro4.campo05;
    const campo06 = isNaN(this.modelo10.quadro4.campo06) ? 0 : this.modelo10.quadro4.campo06;
    const campo07 = isNaN(this.modelo10.quadro4.campo07) ? 0 : this.modelo10.quadro4.campo07;
    const campo08 = isNaN(this.modelo10.quadro4.campo08) ? 0 : this.modelo10.quadro4.campo08;
    const campo09 = isNaN(this.modelo10.quadro4.campo09) ? 0 : this.modelo10.quadro4.campo09;
    const campo10 = isNaN(this.modelo10.quadro4.campo10) ? 0 : this.modelo10.quadro4.campo10;
    const campo04 = isNaN(this.modelo10.quadro4.campo04) ? 0 : this.modelo10.quadro4.campo04;
    const campo11 = isNaN(this.modelo10.quadro4.campo11) ? 0 : this.modelo10.quadro4.campo11;
    this.modelo10.quadro4.campo09 = campo01 + campo02 + campo03 + campo05 + campo06 + campo07 + campo08;
    this.modelo10.quadro4.campo12 = campo09 + campo10 + campo04 - campo11;
    this.evaluateButtons(this.tipoModelo10);
  }

  public setTipoDeclaracaoCampo1(value: boolean): void {
    this.modelo10.quadro6.campo01 = value ? value : undefined;
    if (this.modelo10.quadro6.campo01) {
      this.modelo10.quadro6.campo02 = undefined;
    }
    this.evaluateButtons(this.tipoModelo10);
  }

  public setTipoDeclaracaoCampo2(value: boolean): void {
    this.modelo10.quadro6.campo02 = value ? value : undefined;
    if (this.modelo10.quadro6.campo02) {
      this.modelo10.quadro6.campo01 = undefined;
    }
    this.evaluateButtons(this.tipoModelo10);
  }

  public evaluateButtons(tipModelo10: ETipModelo10, forceDelete?: boolean): void {
    if (!this.modelo10.quadro6.campo03) {
      this.modelo10.quadro6.campo04 = undefined;
    }
    const isQ6C4Filled = Boolean(this.modelo10.quadro6.campo04);

    this.btnSave.disabled = JSON.stringify(this.modelo10) === JSON.stringify(this._modelo10Initial);
    this._changeDisableBtns(!this.btnSave.disabled, this.btnSave.disabled, true);

    if (this.modelo10.quadro6.campo03 && !isQ6C4Filled) {
      this._changeDisableBtns(false, false);
      this.btnSave.tooltip.disabled = false;
    }

    if (tipModelo10 === ETipModelo10.NewFromDB && JSON.stringify(this.modelo10) === JSON.stringify(this._modelo10Initial)) {
      this._changeDisableBtns(false, true, true);
    }

    if (forceDelete) {
      this._changeDisableBtns(true, false, true);
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IQuadro5, IQuadro5>): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public get promise(): Promise<void> {
    return this._promise;
  }

  public set promise(value: Promise<void>) {
    this._promise = value;
    this._promise.finally(() => {
      this._promise = undefined;
    });
  }

  private _getModelo10(tipModelo10: ETipModelo10, showAlert: boolean = true): Promise<void> {
    this.tipoModelo10 = tipModelo10;
    this.promise = this._modelo10Service.getModelo10(tipModelo10).then((response: HttpResponse<IJsonModelo10>) => {
      if (showAlert) {
        const message = tipModelo10 === ETipModelo10.NewClean ? 'modelo10.message.dadoscarregadosClean' : 'modelo10.message.dadoscarregados';
        this._plAlertService.success(message);
      }
      if (tipModelo10 !== ETipModelo10.NewFromDB && !response.body.quadro6.campo01 && !response.body.quadro6.campo02) {
        response.body.quadro6.campo01 = true;
      }
      for (const quadro5 of this.modelo10.quadro5) {
        quadro5._isManuallyAdded = false;
      }
      this._applyModelo10(response.body);
      this._modelo10Initial = copy(this.modelo10);
      this.evaluateButtons(this.tipoModelo10);
    });
    return this.promise;
  }

  private _indexItensQuadro05(): void {
    let index = 1;
    for (const quadro5 of this.modelo10.quadro5) {
      quadro5._index = index;
      index++;
    }
    this.dataGridDefinition.dataSource = this.modelo10.quadro5;
  }

  private _openConfigRadicaisConta(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(Modelo10ConfigModalComponent, {size: 'lg'});
    return modalInstance.result;
  }

  private _openModalRetencoes(): Promise<void> {
    this.promise = this._maintenanceInstanceRetencoes.maintenance();
    return this.promise;
  }

  private _saveModelo10DB(): Promise<void> {
    this.promise = this._modelo10Service.saveModelo10(this.modelo10).then(() => {
      this._plAlertService.success('modelo10.message.dadosguardados');
      this.tipoModelo10 = ETipModelo10.NewFromDB;
      this._modelo10Initial = copy(this.modelo10);
      this.evaluateButtons(this.tipoModelo10);
    });
    return this.promise;
  }

  private _deleteModelo10(): Promise<void> {
    this.promise = this._modelo10Service.deleteModelo10().then((response: HttpResponse<IJsonModelo10>) => {
      this._plAlertService.success('modelo10.message.registosdel');
      this.tipoModelo10 = ETipModelo10.NewClean;
      this._applyModelo10(response.body);
      this.evaluateButtons(this.tipoModelo10, true);
    });
    return this.promise;
  }

  private _applyModelo10(modelo10: IJsonModelo10): void {
    for (const i of this.modelo10.quadro5) {
      for (const j of modelo10.quadro5) {
        if (i.nif === j.nif && i.tipoRendimentos === j.tipoRendimentos && i.localObtencao === j.localObtencao) {
          j._isManuallyAdded = i._isManuallyAdded;
          break;
        }
      }
    }
    this.modelo10 = modelo10;
    if (moment(MIN_DATE_CGD).isSame(this.modelo10.quadro6.campo04, 'date')) {
      this.modelo10.quadro6.campo04 = undefined;
    }
    if (!this.modelo10.quadro6.campo01 && !this.modelo10.quadro6.campo02) {
      this.modelo10.quadro6.campo01 = true;
    }
    this._indexItensQuadro05();
  }

  private _criarFicheiro(): Promise<void> {
    return this._modelo10Service.criarFicheiro(this.modelo10).then((response: HttpResponse<Blob>) => {
      downloadStream(response);
    });
  }

  private async _validarNaAT(): Promise<void> {
    const response = await this._modelo10Service.validarDeclaracaoAT(this.modelo10);
    if (response) {
      this._plAlertService.success('modelo10.message.servicoAT.validarAtSuccess');
    }
  }

  private async _submeterNaAT(): Promise<void> {
    const response = await this._modelo10Service.submeterDeclaracaoAT(this.modelo10);
    if (response) {
      this._plAlertService.success('modelo10.message.servicoAT.submeterAtSuccess');
    }
  }

  private _callConfigWS(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ModeloConfigwsModalComponent);
    return modalInstance.result;
  }

  private _changeDisableBtns(canSave: boolean, canCreate: boolean, clean?: boolean): void {
    this.btnSave.disabled = !canSave;
    this._btnCriarFicheiro.disabled = !canCreate;
    this._btnCriarFicheiro.tooltip.disabled = canCreate;
    this._btnValidaAt.disabled = !canCreate;
    this._btnSumbmitAt.disabled = !canCreate;
    this._btnConfigWS.disabled = !canCreate;

    if (clean) {
      this.btnSave.tooltip.disabled = true;
    }
  }

  private _buildToolbarInitBtns(): void {
    this.btnSave.visible = this.btnDelete.visible = this.btnConfig.visible = true;
    this.btnSave.promise = this.btnDelete.promise = this.btnConfig.promise = this.promise;
    this.btnDelete.groupId = this.btnConfig.groupId = TOOLBAR_GROUP_RESPONSIVE;

    this.btnSave.order = this._btnNovo.order + 1;
    this.btnSave.disabled = true;
    this.btnSave.tooltip = {
      disabled: true,
      placement: 'bottom-right',
      container: 'body',
      text: 'modelo10.message.q6c4NotNull'
    };
    this.btnSave.click = () => this._saveModelo10DB();

    this.btnDelete.order = this.btnSave.order + 1;
    this.btnDelete.click = () => this._deleteModelo10();

    this.btnConfig.order = this._btnRetencoes.order + 1;
    this.btnConfig.click = () => this._openConfigRadicaisConta();
  }

  private _buildToolbarResponsive(isMobile: boolean): void {
    this.toolbar.removeGroupId(TOOLBAR_GROUP_RESPONSIVE, false);
    this.dropdownActions.visible = isMobile;
    this._btnValidaAt.type = isMobile ? 'button' : 'dropdown-split';
    if (!isMobile) {
      this.btnDelete.class = 'btn-danger';
      this._btnCriarFicheiro.class = this._btnValidaAt.class = 'btn-primary';
      this._btnRetencoes.class = this.btnConfig.class = 'btn-light';
      this.toolbar.addButton(this.btnDelete).addButton(this._btnCriarFicheiro).addButton(this._btnValidaAt).addButton(this._btnRetencoes).addButton(this.btnConfig);
    } else {
      this.btnDelete.class = this._btnCriarFicheiro.class = this._btnValidaAt.class = this._btnRetencoes.class = this.btnConfig.class = undefined;
    }
  }
}
