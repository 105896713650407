import {Observable} from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import {FileListPipelineHandler, FileListTransformerHandler, IFileListTransformer} from '../../common/pipeline/file/filelist.pipeline';
import {FILE_TRANSFORMERS} from '../../common/pipeline/file/filelist.pipeline.di';
import {FileListPipelineFinisher} from '../../common/pipeline/file/filelist.pipeline.finisher';
import type {IPlUploadProperties} from '../upload.component.interface';

@Injectable()
export class PlUploadTransformationHandler implements FileListPipelineHandler {
  private _chain: FileListPipelineHandler;

  constructor(private readonly _filePipelineFinisher: FileListPipelineFinisher, private readonly _injector: Injector) {}

  public handle(value: Array<File>, properties?: Partial<IPlUploadProperties>): Observable<Array<File>> {
    if (!this._chain) {
      const transformers: Array<IFileListTransformer> = this._injector.get(FILE_TRANSFORMERS, [], {optional: true});
      this._chain = transformers.reduceRight((next: FileListPipelineHandler, transformer: IFileListTransformer) => new FileListTransformerHandler(next, transformer), this._filePipelineFinisher);
    }
    return this._chain.handle(value, properties);
  }
}
