import {ENTITY_NAME_CONTRATOS, IListagemContratosFilters} from './contrato.entity.interface';
import {buildSessionUrlWithParams} from '../../../common/utils/utils';
import {ApiService} from '../../services/api/api.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonContrato} from './jsonContrato.entity.interface';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';

@Injectable({
  providedIn: 'root'
})
export class ContratosService {
  public isAvenca: boolean = false;

  private readonly _path: string;
  private readonly _entityService: IEntityService<IJsonContrato>;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this._path = `${this._apiService.path.restapi}/contratos`;
    this._entityService = this._entityServiceBuilder.build<IJsonContrato>(ENTITY_NAME_CONTRATOS);
  }

  public getContratosPDFUrl(filters: IListagemContratosFilters): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report`, {...filters});
  }

  public reabrirContrato(contrato: IJsonContrato): Promise<IJsonContrato> {
    if (!contrato) {
      const error: string = this.isAvenca ? this._plTranslateService.translate('contratosservicos.errors.notSelected') : this._plTranslateService.translate('contratos.errors.notSelected');
      this._plAlertService.error(error);
      return Promise.reject(new Error(error));
    }

    if (!contrato.cab.terminado) {
      const error: string = this.isAvenca
        ? this._plTranslateService.translate('contratosservicos.errors.notTerminado', {avenca: contrato.cab.nContratoSemVersaoAsString})
        : this._plTranslateService.translate('contratos.errors.notTerminado', {contrato: contrato.cab.nContratoSemVersaoAsString});
      this._plAlertService.error(error);
      return Promise.reject(new Error(error));
    }

    return this._entityService.put({url: `${contrato.cab.refCtrCab}/reabrir`}).then((response) => response.body);
  }

  public terminarContrato(contrato: IJsonContrato): Promise<IJsonContrato> {
    if (!contrato) {
      const error: string = this.isAvenca ? this._plTranslateService.translate('contratosservicos.errors.notSelected') : this._plTranslateService.translate('contratos.errors.notSelected');
      this._plAlertService.error(error);
      return Promise.reject(new Error(error));
    }

    if (contrato.cab.terminado) {
      const error: string = this.isAvenca
        ? this._plTranslateService.translate('contratosservicos.errors.terminado', {avenca: contrato.cab.nContratoSemVersaoAsString})
        : this._plTranslateService.translate('contratos.errors.terminado', {contrato: contrato.cab.nContratoSemVersaoAsString});
      this._plAlertService.error(error);
      return Promise.reject(new Error(error));
    }

    return this._entityService.put({url: `${contrato.cab.refCtrCab}/terminar`}).then((response) => response.body);
  }

  public apagarContrato(refCtrCab: number): Promise<void> {
    if (!refCtrCab || refCtrCab <= 0) {
      const error: string = this.isAvenca ? this._plTranslateService.translate('contratosservicos.errors.notSelected') : this._plTranslateService.translate('contratos.errors.notSelected');
      this._plAlertService.error(error);
      return Promise.reject(new Error(error));
    }

    return this._entityService.delete({id: `${refCtrCab}`}).then(() => undefined);
  }
}
