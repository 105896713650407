import {Component, Input} from '@angular/core';
import {TTableLegend} from './tablelegend.component.interface';

@Component({
  selector: 'cg-table-legend',
  templateUrl: './tablelegend.component.html'
})
export class CGTableLegendComponent {
  @Input() public legend: TTableLegend;
}
