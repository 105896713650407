import {firstValueFrom} from 'rxjs';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {EDateMonth, IPlToolbarItem, PlI18nService} from 'pl-comps-angular';
import {IAnosCalculo} from '../../ativosdepreciacao/ativosDepreciacao.module.interface';
import {
  EAtivosMapasOficiaisEstadoUso,
  EAtivosMapasOficiaisMetodo,
  IAtivosMapasOficiais,
  RADIO_GROUP_ATIVOS_MAPAS_OFICIAIS_ESTADO_USO,
  RADIO_GROUP_ATIVOS_MAPAS_OFICIAIS_METODO
} from '../ativosMapasOficiais.module.interface';
import {IJsonAnosCalculo} from '../../ativosdepreciacao/jsonAtivosDepreciacao.module.interface';
import {IJsonImtab} from '../../../../entities/amortizacoes/jsonImtab.entity.interface';
import {IReportInstance} from '../../../../components/reports/input/reports.input.component.interface';
import {minDateCG} from '../../../../../common/utils/utils';
import {ModuloComponent} from '../../../../components/module/module.component';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {AtivosMapasOficiaisService} from '../ativosMapasOficiais.module.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IJsonCCusto} from '../../../../entities/ccustos/jsonCCusto.entity.interface';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {EReport} from '../../../../entities/reports/reports.interface';
import {IJsonAtivos} from '../../../../entities/ativos/interfaces/jsonAtivos.entity.interface';
import {IJsonPOC} from '../../../../entities/pocs/jsonPOC.entity.interface';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import moment from 'moment';
import {TDate} from '../../../../../common/dates';

@Component({
  selector: 'module-ativos-mapas-oficiais',
  templateUrl: './ativosMapasOficiais.module.component.html'
})
export class AtivosMapasOficiaisModuleComponent extends ModuloComponent implements OnInit {
  @Input() public anosCalculo: Array<IJsonAnosCalculo>;

  public readonly metodoSource: Array<IAnosCalculo>;
  public readonly anoDeCalculoTemplate: string;
  public readonly mapaamortizacaoOutput: string;
  public readonly amortizacaoOutput: string;
  public readonly ccustoOutput: string;
  public readonly ativoOutput: string;
  public readonly ativosAte: Partial<IJsonAtivos>;
  public readonly contaDe: Partial<IJsonPOC>;
  public readonly contaAte: Partial<IJsonPOC>;
  public readonly cCustoAte: Partial<IJsonCCusto>;
  public readonly amortizacaoDe: Partial<IJsonImtab>;
  public readonly amortizacaoAte: Partial<IJsonImtab>;
  public readonly radioGroupMetodo: IRadioGroup<EAtivosMapasOficiaisMetodo>;
  public readonly radioGroupEstadoUso: IRadioGroup<EAtivosMapasOficiaisEstadoUso>;

  public reportModel: IJsonReport;
  public mesCalculo: EDateMonth;
  public pdfUrl: string;
  public mapaOficial: string;
  public header: IAtivosMapasOficiais;

  private readonly _previsualizarBtn: IPlToolbarItem;
  private readonly _reportInstance: IReportInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plI18nService: PlI18nService,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _cgModalService: CGModalService,
    private readonly _ativosMapasOficiaisService: AtivosMapasOficiaisService
  ) {
    super(_injector);
    this.anoDeCalculoTemplate =
      `<b>${<string>this._translateService.instant('global.text.year')}:</b> {{ano}} <b>| ${<string>this._translateService.instant('global.text.fromDate')}:</b>` +
      `{{dataDeFormated}} <b>${<string>this._translateService.instant('global.text.toDate')}:</b> {{dataAteFormated}}`;
    this.metodoSource = [];
    this.ativosAte = {codAtivo: 2147483647, designacao: this._translateService.instant('global.text.all')};
    this.ativoOutput = '{{codAtivo}} - {{designacao}}';
    this.contaDe = {nConta: '1', nome: 'MEIOS FINANCEIROS LÍQUIDOS'};
    this.contaAte = {nConta: '9999999999', nome: this._translateService.instant('global.text.all')};
    this.cCustoAte = {nCCusto: '9999999999', nome: this._translateService.instant('global.text.all')};
    this.ccustoOutput = '{{nCCusto}} - {{nome}}';
    this.mapaamortizacaoOutput = '{{nImmap}}';
    this.amortizacaoDe = {nImtab: '0000', nome: ''};
    this.amortizacaoAte = {nImtab: '9999', nome: this._translateService.instant('global.text.all')};
    this.amortizacaoOutput = '{{nImtab}} - {{nome}}';
    this.pdfUrl = '';
    this.mesCalculo = EDateMonth.December;
    this._reportInstance = this._reportsRegistryService.get(EReport.AtivosMapasOficiais);
    this.radioGroupMetodo = RADIO_GROUP_ATIVOS_MAPAS_OFICIAIS_METODO;
    this.radioGroupEstadoUso = RADIO_GROUP_ATIVOS_MAPAS_OFICIAIS_ESTADO_USO;
    this._previsualizarBtn = {
      id: 'processPDF',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-file-pdf-o fa-fw"></i>',
      class: 'btn-success',
      caption: 'global.btn.processPDF',
      disabled: false,
      click: () => this._previsualizarModal()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._setSourceAnosCalculo();
    if (!this.header) {
      const anoEmCurso: number = this._configService.configurations.empresa.anoEmCursoIRC;
      const dateAte: TDate =
        anoEmCurso < moment().year()
          ? moment()
              .year(anoEmCurso + 1)
              .endOf('year')
          : moment().year(anoEmCurso).endOf('year');
      this.header = {
        nCCustoAte: '9999999999',
        abatido: true,
        anoEconomico: anoEmCurso,
        codAmortAte: '9999',
        codAmortDe: '0000',
        codAtivoAte: 2147483647,
        codAtivoDe: 0,
        dataAbateDe: minDateCG(),
        dataAbateAte: dateAte,
        dataAquisicaoDe: minDateCG(),
        dataAquisicaoAte: dateAte,
        mes: EDateMonth.December,
        metodo: EAtivosMapasOficiaisMetodo.Qualquer,
        nCCustoDe: '',
        nContaAte: '9999999999',
        nContaDe: '1',
        nMapa: '32',
        natureza: 0,
        normal: true,
        reportName: '32.FR3',
        tipoExportacao: 0,
        vendido: true,
        radioAll: true,
        estadouso: EAtivosMapasOficiaisEstadoUso.Indiferente
      };
    }
    this.loadReport();
    this.toolbar.addButton(this._previsualizarBtn);
  }

  public changedMapa(value: string): void {
    this.header.nMapa = value;
    this.loadReport();
  }

  public radioAllChanged(value: boolean): void {
    switch (value) {
      case true:
        this.header.normal = true;
        this.header.vendido = true;
        this.header.abatido = true;
        break;
      case false:
        this.header.normal = false;
        this.header.vendido = false;
        this.header.abatido = false;
        break;
    }
  }

  public normalAbatidoVendidoChanged(value: boolean): void {
    if (!value && this.header.radioAll) {
      this.header.radioAll = false;
    }
    if (this.header.normal && this.header.vendido && this.header.abatido) {
      this.header.radioAll = true;
    }
  }

  public anoEconomicoChanged(value: IAnosCalculo): void {
    this.header.anoEconomico = value.ano;
    this.evaluateDatesTo();
  }

  public evaluateDatesTo(): void {
    this.header.dataAbateAte = this.header.dataAquisicaoAte = moment().year(this.header.anoEconomico).month(this.header.mes).subtract(1, 'months').endOf('month');
  }

  public loadReport(): void {
    this._previsualizarBtn.disabled = true;
    this._reportInstance
      .subfolder(this.header.nMapa)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.reportModel = reports[0];
          this.header.reportName = this.reportModel.name;
        }
      })
      .finally(() => {
        this._previsualizarBtn.disabled = false;
      });
  }

  public reportChanged(report: IJsonReport): void {
    this.reportModel = report;
    this.header.reportName = report.name;
  }

  private async _previsualizarModal(): Promise<void> {
    const pdfUrl = await firstValueFrom(this._ativosMapasOficiaisService.getListagemAtivosUrl(this.header));
    this._cgModalService.showPdf('ativosmapasoficiais.modal.title', pdfUrl);
  }

  private _setSourceAnosCalculo(): void {
    if (this.anosCalculo) {
      for (const source of this.anosCalculo) {
        this.metodoSource.push({
          ano: source.ano,
          dataDe: source.dataDe,
          dataAte: source.dataAte,
          dataDeFormated: this._plI18nService.formatDate(source.dataDe),
          dataAteFormated: this._plI18nService.formatDate(source.dataAte)
        });
      }
    }
  }
}
