import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_PERIODOS, IPeriodosEntity} from './periodos.entity.interface';
import {IJsonPeriodo, IJsonPeriodoDatasPeriodoTributacao} from './jsonPeriodo.entity.interface';
import {TDate} from '../../../common/dates';

export const ENTITY_PERIODOS: IPeriodosEntity = {
  name: ENTITY_NAME_PERIODOS,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.ATIVOS],
  searchPlaceholder: 'periodos.pesquisa',
  sidebarTitle: 'portals.sidebar.modules.periodos',
  metadata: {
    keyName: 'periodo',
    fields: [
      {name: 'periodo', width: '120px', caption: 'periodos.fields.periodo', placeholder: 'periodos.fields.periodoPlaceholder', validators: {required: true, min: 1}},
      {name: 'nome', caption: 'periodos.fields.nome', placeholder: 'periodos.fields.nomePlaceholder', validators: {required: true, maxlength: 25}}
    ],
    order: 'periodo',
    searchFields: 'periodo,nome',
    listFields: 'periodo,nome'
  },
  autocomplete: {
    rowTemplate: '{{periodo}} - {{nome}}',
    output: '{{periodo}} - {{nome}}',
    outputDescription: 'nome',
    searchFields: 'periodo,nome'
  },
  actions: {
    new: false,
    edit: false,
    detail: false,
    delete: false,
    duplicate: false,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP, ROLE.CONTABILIDADE],
        pageTitle: 'global.menu.periodos'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.ERP, ROLE.CONTABILIDADE],
        pageTitle: 'global.menu.periodos'
      }
    }
  },
  service: function () {
    this.obterDatasPeriodoTributacao = (nPeriodo: string) => {
      return this.get<IJsonPeriodoDatasPeriodoTributacao>({id: `obterDatasPeriodoTributacao/${nPeriodo}`});
    };

    this.obterProximoPeriodoContab = (nPeriodo: string) => {
      return this.get<IJsonPeriodo>({id: `obterProximoPeriodoContab/${nPeriodo}`});
    };

    this.obterAnoDosPeriodos = (dePeriodo: string, atePeriodo: string) => this.get<number>({id: `obterAnoDosPeriodos/${dePeriodo}/${atePeriodo}`});

    this.obterPeriodoFromDate = (date: TDate) => this.get<IJsonPeriodo>({id: 'obterperiodofromdate', params: {date: date}});
  }
};
