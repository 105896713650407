import moment, {Duration} from 'moment';
import type {IMomentLocaleData, IMomentLocaleDataRelativeTime} from './moment.duration.format.interface';
import {isArray, isString} from '../utilities/utilities';

const UNITS: ReadonlyArray<[keyof Duration, keyof IMomentLocaleDataRelativeTime]> = Object.freeze([
  ['years', 'y'],
  ['asYears', 'y'],
  ['months', 'M'],
  ['asMonths', 'M'],
  ['weeks', 'w'],
  ['asWeeks', 'w'],
  ['days', 'd'],
  ['asDays', 'd'],
  ['hours', 'h'],
  ['asHours', 'h'],
  ['minutes', 'm'],
  ['asMinutes', 'm'],
  ['seconds', 's']
]);

export function momentDurationFormat(duration: Duration, units?: string | Array<keyof Duration> | ReadonlyArray<keyof Duration>): string {
  if (!moment.isDuration(duration)) {
    return '';
  }
  if (isString(units) && units) {
    units = <Array<keyof Duration>>units.split(',');
  }
  const hasUnits = isArray(units);
  let result = '';
  for (const [unit, arg] of UNITS) {
    if (hasUnits && !units.includes(unit)) {
      continue;
    }
    const unitDuration: number = Math.max(0, Math.floor((<() => number>duration[unit])()));
    if (!unitDuration) {
      continue;
    }
    const plural = unitDuration > 1;
    if (result.length > 0) {
      result += ' ';
    }
    result += String(unitDuration) + localize(arg, plural);
  }
  return result;
}

function localize(arg: keyof IMomentLocaleDataRelativeTime, plural: boolean): string {
  if (plural) {
    arg += arg.at(0);
  }
  const keys: Array<string> = (<IMomentLocaleData>(<unknown>moment.localeData()))._relativeTime[arg].split(' ');
  if (keys.length > 1) {
    return keys[1].at(0) || '';
  }
  return keys[0].at(0) || '';
}
