import {NgModule} from '@angular/core';
import {PlAnimationRotateDirective} from './animation.rotate.directive';

export * from './animation.rotate.directive';

const DECLARATIONS = [
  PlAnimationRotateDirective
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlAnimationRotateModule {
}
