import {IJsonGDFile} from '../gdoc/JsonGDoc.entity.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface IDocPartilhado extends IJsonGDFile {
  isChecked: boolean;
  isPdf: boolean;
  url: string;
}

export interface IDocsPartilhadosEntityService extends IEntityService<IJsonGDFile> {
  uploadDoc(file: Array<Blob> | Array<File>, gdFile?: IJsonGDFile): TServiceResponse<void>;
}

export interface IDocsPartilhadosEntity extends IEntityDefinition<IJsonGDFile, IDocsPartilhadosEntityService> {}

export const ENTITY_NAME_DOCS_PARTILHADOS = 'docspartilhados';
