import {Component, Injector, OnInit} from '@angular/core';
import {IPlFormDefinition, IPlFormFieldValueChangedEvent, IPlFormTemplate, IPlNavWizardDefinition, IPlToolbarItem} from 'pl-comps-angular';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {ICGConfigControloIva} from '../../../../services/config/config.service.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {MODULE_NAME_CONTROLO_IVA_ENTRE_EMPRESAS} from '../../../../modules/controloivaentreempresas/controloIVAEntreEmpresas.module.interface';
import {DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO40} from '../../../../datasources/tiporegularizacaocampo40/tipoRegularizacaoCampo40.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO41} from '../../../../datasources/tiporegularizacaocampo41/tipoRegularizacaoCampo41.datasource.interface';
import {IJsonIva} from '../../../ivas/jsonIva.entity.interface';
import {IJsonPOC} from '../../../pocs/jsonPOC.entity.interface';
import {IJsonQIVAP} from '../../../qivaps/jsonQIVAP.entity.interface';
import {EDeclaracaoPeriodicaInfo, IControloIVA} from '../../controloIVA.entity.interface';
import {EControloIvaTipoOperacao} from '../../jsonControloIVA.entity.interface';
import {ETipoLiquidacao} from '../../../../datasources/tipoliquidacao/tipoLiquidacao.datasource.interface';
import {ETipoTransmissaoActiva} from '../../../../datasources/tipotransmissaoactiva/tipoTransmissaoActiva.datasource.interface';
import {ETipoTransmissaoPassiva} from '../../../../datasources/tipotransmissaopassiva/tipoTransmissaoPassiva.datasource.interface';
import {ENTITY_NAME_QIVAPS} from '../../../qivaps/qivaps.entity.interface';

const CAPTION_TIPO_REGULARIZACAO_CAMPO41 = 'controloIVA.fields.tipoRegularizacaoCampo41';
const CAPTION_TIPO_REGULARIZACAO_CAMPO40 = 'controloIVA.fields.tipoRegularizacaoCampo40';
const CAPTION_TIPO_REGULARIZACAO = 'controloIVA.fields.tipoRegularizacao';
const ISO00 = 0;
const ISOPT = 183;

@Component({
  selector: 'controloiva-edit',
  templateUrl: './controloIVA.entity.edit.component.html'
})
export class ControloIVAEditComponent extends ModuloEntityDetailComponent<IControloIVA> implements OnInit {
  public readonly qivapsOutput: string = '{{nCampo}} - {{nomeCampo}}';
  public readonly qivaasOutput: string = '{{nCampo}} - {{nome}}';
  public readonly ivasOutput: string = '{{codIva}} - {{nome}}';
  public readonly pocsOutput: string = '{{nConta}} - {{nome}}';
  public readonly config: ICGConfigControloIva;
  public readonly sujeitaAsLeisVigoramEmAngola: boolean;
  public readonly dataSourceNameTipoRegularizacaoCampo40: string = DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO40;
  public readonly dataSourceNameTipoRegularizacaoCampo41: string = DATA_SOURCE_NAME_TIPO_REGULARIZACAO_CAMPO41;
  public readonly tipoOperacao: typeof EControloIvaTipoOperacao;
  public readonly navWizardDefinition: IPlNavWizardDefinition;
  public definition: IPlFormDefinition;
  public isNewState: boolean;
  public isDetailState: boolean;
  public stickyTemplate: IPlFormTemplate;
  public templateOperacaoIva: IPlFormTemplate;
  public templateCamposIVA: IPlFormTemplate;
  public regularizacaoCampoEnabled40: boolean;
  public regularizacaoCampoEnabled41: boolean;
  public regularizacaoCampoCaption: string;
  public regularizacaoCampoEnabledRCH40: boolean;
  public regularizacaoCampoEnabledRCH41: boolean;
  public regularizacaoCampoCaptionRCH: string;

  private readonly _qivapService: IEntityService<IJsonQIVAP>;
  private readonly _btnControloIVAEntreEmpresas: IPlToolbarItem;
  private readonly _maintenanceControloIvaEntreEmpresas: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this._maintenanceControloIvaEntreEmpresas = this._moduleMaintenanceService.build(MODULE_NAME_CONTROLO_IVA_ENTRE_EMPRESAS);
    this.regularizacaoCampoCaptionRCH = CAPTION_TIPO_REGULARIZACAO;
    this.regularizacaoCampoCaption = CAPTION_TIPO_REGULARIZACAO;
    this.config = this._configService.configurations.contabilidade.controloIva;
    this.sujeitaAsLeisVigoramEmAngola = this._configService.configurations.empresa.sujeitaAsLeisVigoramEmAngola;
    this.tipoOperacao = EControloIvaTipoOperacao;
    this.navWizardDefinition = {
      items: []
    };
    this.finalize = this.finalize.bind(this);
    this._qivapService = this._entityServiceBuilder.build<IJsonQIVAP>(ENTITY_NAME_QIVAPS);
    this._btnControloIVAEntreEmpresas = {
      id: 'btn-controlo-iva-entre-empresas',
      order: 126,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-copy"></i>',
      class: 'btn-primary',
      caption: 'controloIVA.btn.controloIVAEntreEmpresas',
      click: () => this._openControloIvaEntreEmpresas()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.isNewState = this.type === EEntityStateDetailType.NEW;
    this.isDetailState = this.type === EEntityStateDetailType.DETAIL;
    this.model = {
      areaRegional: 0,
      codControloIVA: '',
      codIVA: undefined,
      codIVANome: '',
      decANUBASETRIBUT: '',
      decANUBASETRIBUTNome: '',
      decANUIVADEDLIQREG: '',
      decANUIVALIQUIDRCH: '',
      decANUIVANAODEDUT: '',
      decANUIVANDEDPROR: '',
      decANUVTLIQUIDRCH: '',
      decANUVTNAODEDUT: '',
      decANUVTNDEDPROR: '',
      decPERBASETRIBUT: '',
      decPERBASETRIBUTNome: '',
      decPERIVADEDLIQREG: '',
      decPERIVALIQUIDRCH: '',
      decPERIVANAODEDUT: '',
      decPERIVANDEDPROR: '',
      decPERVTLIQUIDRCH: '',
      decPERVTNAODEDUT: '',
      decPERVTNDEDPROR: '',
      descAbreviada: '',
      descCompleta: '',
      inversaoSujPassivo: 0,
      isRegimeIvaCaixa: false,
      mercado: 0,
      nCONTAIVADEDLIQREGNome: '',
      nCONTAIVALIQUIDRCHNome: '',
      nCONTAIVANAODEDUTNome: '',
      nCONTAIVANDEDPRORNome: '',
      nCONTAVTLIQUIDRCHNome: '',
      nCONTAVTNAODEDUTNome: '',
      nCONTAVTNDEDPRORNome: '',
      nCampoOficial: '',
      nContaIVADEDLIQREG: '',
      nContaIVALIQUIDRCH: '',
      nContaIVANAODEDUT: '',
      nContaIVANDEDPROR: '',
      nContaVTLIQUIDRCH: '',
      nContaVTNAODEDUT: '',
      nContaVTNDEDPROR: '',
      qivapsNCampoOficial: '',
      regularizacoesC40: 0,
      regularizacoesC40RCH: 0,
      regularizacoesC41: 0,
      regularizacoesC41RCH: 0,
      tipoBem: 0,
      tipoDeducao: 0,
      tipoLiquidacao: 0,
      tipoOperacao: undefined,
      tipoTaxa: 0,
      tipoTransmissaoAct: 0,
      tipoTransmissaoPas: 0,
      tipoValidacaoNIF: undefined,
      usaIVANAODEDUTOrigem: false,
      usaIVANDEDPROROrigem: false,
      usaVTNAODEDUTOrigem: false,
      usaVTNDEDPROROrigem: false,
      taxaAtualIva: 0,
      percDedGasoleo: 0,
      percDedProRata: 0,
      decPERVTLIQUIDRCHNome: '',
      nContaIvaCativo: '',
      nContaIvaCativoNome: '',
      ...this.model
    };
    this.stickyTemplate = {
      addMissingFields: false,
      items: [{type: 'group', order: 1, fields: [{field: 'codControloIVA'}, {field: 'descAbreviada'}]}]
    };
    this.templateOperacaoIva = {
      addMissingFields: false,
      items: [
        {type: 'group', order: 1, fields: [{field: 'tipoOperacao'}, {field: 'areaRegional'}]},
        {type: 'group', order: 2, fields: [{field: 'mercado'}, {field: 'inversaoSujPassivo'}]},
        {type: 'group', order: 3, fields: [{field: 'tipoLiquidacao'}, {field: 'tipoDeducao'}]},
        {type: 'group', order: 4, fields: [{field: 'tipoTransmissaoAct'}, {field: 'tipoTransmissaoPas'}]},
        {type: 'group', order: 5, fields: [{field: 'tipoBem'}, {field: 'tipoTaxa'}]},
        {type: 'group', order: 6, fields: [{field: 'tipoValidacaoNIF'}, {field: 'isRegimeIvaCaixa'}]}
      ]
    };
    this.templateCamposIVA = {
      addMissingFields: false,
      items: [
        {type: 'group', order: 1, fields: [{field: 'decPERBASETRIBUTNome'}, {field: 'decANUBASETRIBUTNome'}]},
        {type: 'group', order: 2, fields: [{field: 'codIVANome'}, {field: 'nCONTAIVADEDLIQREGNome'}]},
        {type: 'group', order: 3, fields: [{field: 'nCONTAVTNAODEDUTNome'}, {field: 'usaVTNAODEDUTOrigem'}]},
        {type: 'group', order: 4, fields: [{field: 'nCONTAIVANAODEDUTNome'}, {field: 'usaIVANAODEDUTOrigem'}]},
        {type: 'group', order: 5, fields: [{field: 'nCONTAVTNDEDPRORNome'}, {field: 'usaVTNDEDPROROrigem'}]},
        {type: 'group', order: 6, fields: [{field: 'nCONTAIVANDEDPRORNome'}, {field: 'usaIVANDEDPROROrigem'}]},
        {type: 'group', order: 6, fields: [{field: 'nCONTAVTLIQUIDRCHNome'}, {field: 'nCONTAIVALIQUIDRCHNome'}]},
        {type: 'group', order: 6, fields: [{field: 'regularizacoesC40'}, {field: 'regularizacoesC41'}]}
      ]
    };

    if (this.model.decPERIVADEDLIQREG) {
      this._qivapService.get({id: this.model.decPERIVADEDLIQREG}).then((result) => {
        this.model.qivapsNCampoOficial = result.body.nCampoOficial;
        this._verificaRegularizacaoCampo4041();
      });
    }

    if (this.model.decPERIVALIQUIDRCH) {
      this._qivapService.get({id: this.model.decPERIVALIQUIDRCH}).then((result) => {
        this.model.qivapsNCampoOficial = result.body.nCampoOficial;
        this._verificaRegularizacaoCampo4041RCH();
      });
    }
    this.toolbar.addButton(this._btnControloIVAEntreEmpresas);
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    this.isNewState = stateType === EEntityStateDetailType.NEW;
    this.isDetailState = stateType === EEntityStateDetailType.DETAIL;
    this.definition = this.entity[stateType].definition;
    return super.onUpdate(stateType).then(() => {
      this.navWizardDefinition.force = this.isDetailState = stateType === EEntityStateDetailType.DETAIL;
      if (this.type === EEntityStateDetailType.NEW) {
        this.btnSave.visible = false;
      } else {
        for (const step of this.navWizardDefinition.items) {
          step.complete = true;
        }
      }
    });
  }

  public onFieldValueChange(fieldChanged: IPlFormFieldValueChangedEvent<number>): void {
    if (fieldChanged.field === 'tipoOperacao') {
      this.model.tipoOperacao = fieldChanged.value;
      if (this.model.decPERIVADEDLIQREG) {
        this._qivapService.get({id: this.model.decPERIVADEDLIQREG}).then((result) => {
          this.model.qivapsNCampoOficial = result.body.nCampoOficial;
          this._verificaRegularizacaoCampo4041();
        });
      }
    }
    if (fieldChanged.field === 'tipoOperacao' || fieldChanged.field === 'tipoLiquidacao' || fieldChanged.field === 'tipoTransmissaoAct' || fieldChanged.field === 'tipoTransmissaoPas') {
      this._evaluateCampoDeclaracaoPeriodica();
    }
  }

  public changedDecPERBASETRIBUT(value: IJsonQIVAP): void {
    this.model.nCampoOficial = value.nCampoOficial;
  }

  public changedDecPERVTLIQUIDRCH(value: IJsonQIVAP): void {
    this.model.decPERVTLIQUIDRCH = value.nCampo;
  }

  public changeCodIva(value: IJsonIva): void {
    this.model.taxaAtualIva = value.taxaActual;
    this.model.percDedGasoleo = value.percDedGasoleo;
    this.model.percDedProRata = value.percDedProRata;
  }

  public changenContaIVADEDLIQREG(value: IJsonPOC): void {
    this.model.decPERIVADEDLIQREG = value.dpq06NCampo;
    this.model.decANUIVADEDLIQREG = value.daq08NCampo ? value.daq08NCampo : value.daq09NCampo;
    if (this.model.decPERIVADEDLIQREG) {
      this._qivapService.get({id: this.model.decPERIVADEDLIQREG}).then((result) => {
        this.model.qivapsNCampoOficial = result.body.nCampoOficial;
        this._verificaRegularizacaoCampo4041();
      });
    } else {
      this.model.qivapsNCampoOficial = '';
      this._verificaRegularizacaoCampo4041();
    }
  }

  public changenContaVTNAODEDUT(value: IJsonPOC): void {
    this.model.decPERVTNAODEDUT = value.dpq06NCampo;
    this.model.decANUVTNAODEDUT = value.daq08NCampo ? value.daq08NCampo : value.daq09NCampo;
  }

  public changenContaIVANAODEDUT(value: IJsonPOC): void {
    this.model.decPERIVANAODEDUT = value.dpq06NCampo;
    this.model.decANUIVANAODEDUT = value.daq08NCampo ? value.daq08NCampo : value.daq09NCampo;
  }

  public changenContaVTNDEDPROR(value: IJsonPOC): void {
    this.model.decPERVTNDEDPROR = value.dpq06NCampo;
    this.model.decANUVTNDEDPROR = value.daq08NCampo ? value.daq08NCampo : value.daq09NCampo;
  }

  public changenContaIVANDEDPROR(value: IJsonPOC): void {
    this.model.decPERIVANDEDPROR = value.dpq06NCampo;
    this.model.decANUIVANDEDPROR = value.daq08NCampo ? value.daq08NCampo : value.daq09NCampo;
  }

  public changenContaVTLIQUIDRCH(value: IJsonPOC): void {
    this.model.decPERVTLIQUIDRCH = value.dpq06NCampo;
    this.model.decANUVTLIQUIDRCH = value.daq08NCampo ? value.daq08NCampo : value.daq09NCampo;
  }

  public changenContaIVALIQUIDRCH(value: IJsonPOC): void {
    this.model.decPERIVALIQUIDRCH = value.dpq06NCampo;
    this.model.decANUIVALIQUIDRCH = value.daq08NCampo ? value.daq08NCampo : value.daq09NCampo;
    if (this.model.decPERIVALIQUIDRCH) {
      this._qivapService.get({id: this.model.decPERIVALIQUIDRCH}).then((result) => {
        this.model.qivapsNCampoOficial = result.body.nCampoOficial;
        this._verificaRegularizacaoCampo4041RCH();
      });
    } else {
      this.model.qivapsNCampoOficial = '';
      this._verificaRegularizacaoCampo4041RCH();
    }
  }

  public changedNContaIvaCativo(value: IJsonPOC): void {
    this.model.nContaIvaCativo = value.nConta;
    this.model.nContaIvaCativoNome = value.nome;
  }

  public finalize(): Promise<void> {
    return this.save().then(() => undefined);
  }

  private _verificaRegularizacaoCampo4041(): void {
    if (this._configService.configurations.empresa.codIso3166 === ISO00 || this._configService.configurations.empresa.codIso3166 === ISOPT) {
      if (this.model.decPERIVADEDLIQREG && (this.model.tipoOperacao === this.tipoOperacao.RegularizacaoAFavorEstado || this.model.tipoOperacao === this.tipoOperacao.RegularizacaoAFavorEmpresa)) {
        if (this.model.qivapsNCampoOficial === this.config.CampoOficial40 || this.model.qivapsNCampoOficial === this.config.CampoOficial41) {
          if (this.model.tipoOperacao === this.tipoOperacao.RegularizacaoAFavorEstado && this.model.qivapsNCampoOficial === this.config.CampoOficial41) {
            this.regularizacaoCampoEnabled41 = true;
            this.regularizacaoCampoEnabled40 = false;
            this.regularizacaoCampoCaption = CAPTION_TIPO_REGULARIZACAO_CAMPO41;
          } else if (this.model.tipoOperacao === this.tipoOperacao.RegularizacaoAFavorEmpresa && this.model.qivapsNCampoOficial === this.config.CampoOficial40) {
            this.regularizacaoCampoEnabled40 = true;
            this.regularizacaoCampoEnabled41 = false;
            this.regularizacaoCampoCaption = CAPTION_TIPO_REGULARIZACAO_CAMPO40;
          } else {
            this.regularizacaoCampoCaption = CAPTION_TIPO_REGULARIZACAO;
            this.regularizacaoCampoEnabled40 = false;
            this.regularizacaoCampoEnabled41 = false;
          }
        } else {
          this.regularizacaoCampoCaption = CAPTION_TIPO_REGULARIZACAO;
          this.regularizacaoCampoEnabled40 = false;
          this.regularizacaoCampoEnabled41 = false;
        }
      } else {
        this.regularizacaoCampoCaption = CAPTION_TIPO_REGULARIZACAO;
        this.regularizacaoCampoEnabled40 = false;
        this.regularizacaoCampoEnabled41 = false;
      }
    }
  }

  private _verificaRegularizacaoCampo4041RCH(): void {
    if (this._configService.configurations.empresa.codIso3166 === ISO00 || this._configService.configurations.empresa.codIso3166 === ISOPT) {
      if (this.model.decPERIVALIQUIDRCH !== '' && (this.model.qivapsNCampoOficial === this.config.CampoOficial40 || this.model.qivapsNCampoOficial === this.config.CampoOficial41)) {
        if (this.model.qivapsNCampoOficial === this.config.CampoOficial41) {
          this.regularizacaoCampoEnabledRCH41 = true;
          this.regularizacaoCampoEnabledRCH40 = false;
          this.regularizacaoCampoCaptionRCH = CAPTION_TIPO_REGULARIZACAO_CAMPO41;
        } else if (this.model.qivapsNCampoOficial === this.config.CampoOficial40) {
          this.regularizacaoCampoEnabledRCH40 = true;
          this.regularizacaoCampoEnabledRCH41 = false;
          this.regularizacaoCampoCaptionRCH = CAPTION_TIPO_REGULARIZACAO_CAMPO40;
        } else {
          this.regularizacaoCampoCaptionRCH = CAPTION_TIPO_REGULARIZACAO;
          this.regularizacaoCampoEnabledRCH40 = false;
          this.regularizacaoCampoEnabledRCH41 = false;
        }
      } else {
        this.regularizacaoCampoCaptionRCH = CAPTION_TIPO_REGULARIZACAO;
        this.regularizacaoCampoEnabledRCH40 = false;
        this.regularizacaoCampoEnabledRCH41 = false;
      }
    }
  }

  private _openControloIvaEntreEmpresas(): Promise<void> {
    if (this.type !== this.stateTypes.NEW) {
      return this._maintenanceControloIvaEntreEmpresas.maintenance({params: {codControloIVA: this.model.codControloIVA}});
    }
    return this._maintenanceControloIvaEntreEmpresas.maintenance();
  }

  private _evaluateCampoDeclaracaoPeriodica(): void {
    if (
      this.model.tipoOperacao === EControloIvaTipoOperacao.Passiva &&
      this.model.tipoLiquidacao === ETipoLiquidacao.ComLiquidacao &&
      this.model.tipoTransmissaoAct === ETipoTransmissaoActiva.OpLocalizadasCaPorFornecResidentesUEComReverseChargeIvaDevidoPeloAdquirente
    ) {
      this.model.declaracaoPeriodica = EDeclaracaoPeriodicaInfo.SQUADROACAMPO97;
    } else if (
      this.model.tipoOperacao === EControloIvaTipoOperacao.Passiva &&
      this.model.tipoLiquidacao === ETipoLiquidacao.ComLiquidacao &&
      this.model.tipoTransmissaoAct === ETipoTransmissaoActiva.OpLocalizadasMNPorFornecEstrangeiroComReverseChargeIvaDevidoPeloAdquirente
    ) {
      this.model.declaracaoPeriodica = EDeclaracaoPeriodicaInfo.SQUADROACAMPO98;
    } else if (
      this.model.tipoOperacao === EControloIvaTipoOperacao.Passiva &&
      this.model.tipoLiquidacao === ETipoLiquidacao.ComLiquidacao &&
      this.model.tipoTransmissaoAct === ETipoTransmissaoActiva.TransmissaoOuroDL362de99ComReverseChargeIvaDevidoPeloAdquirente
    ) {
      this.model.declaracaoPeriodica = EDeclaracaoPeriodicaInfo.SQUADROBCAMPO99;
    } else if (
      this.model.tipoOperacao === EControloIvaTipoOperacao.Passiva &&
      this.model.tipoLiquidacao === ETipoLiquidacao.ComLiquidacao &&
      this.model.tipoTransmissaoAct === ETipoTransmissaoActiva.TransmissaoDeImoveisComRenunciaIsencaoComReverseChargeIvaDevidoPeloAdquirente
    ) {
      this.model.declaracaoPeriodica = EDeclaracaoPeriodicaInfo.SQUADROBCAMPO100;
    } else if (
      this.model.tipoOperacao === EControloIvaTipoOperacao.Passiva &&
      this.model.tipoLiquidacao === ETipoLiquidacao.ComLiquidacao &&
      this.model.tipoTransmissaoAct === ETipoTransmissaoActiva.TransmissaoDeSucatasComReverseChargeIvaDevidoPeloAdquirente
    ) {
      this.model.declaracaoPeriodica = EDeclaracaoPeriodicaInfo.SQUADROBCAMPO101;
    } else if (
      this.model.tipoOperacao === EControloIvaTipoOperacao.Passiva &&
      this.model.tipoLiquidacao === ETipoLiquidacao.ComLiquidacao &&
      this.model.tipoTransmissaoAct === ETipoTransmissaoActiva.ServicosDeContrucaoCivilComReverseChargeIvaDevidoPeloAdquirente
    ) {
      this.model.declaracaoPeriodica = EDeclaracaoPeriodicaInfo.SQUADROBCAMPO102;
    } else if (
      this.model.tipoOperacao === EControloIvaTipoOperacao.Activa &&
      this.model.tipoLiquidacao === ETipoLiquidacao.ComLiquidacao &&
      this.model.tipoTransmissaoAct === ETipoTransmissaoActiva.OpDeAfectacaoAUsoProprioTransmissoesGratuitasEAfectacaoAUmSectorActividadeIsento
    ) {
      this.model.declaracaoPeriodica = EDeclaracaoPeriodicaInfo.SQUADROCCAMPO103;
    } else if (
      this.model.tipoOperacao === EControloIvaTipoOperacao.Activa &&
      this.model.tipoLiquidacao === ETipoLiquidacao.ComLiquidacao &&
      (this.model.tipoTransmissaoAct === ETipoTransmissaoActiva.OpDeActividadeBancaria ||
        this.model.tipoTransmissaoAct === ETipoTransmissaoActiva.OpDeActividadeSeguradora ||
        this.model.tipoTransmissaoAct === ETipoTransmissaoActiva.OpDeActividadeDeLocacaoDeBensImoveis ||
        this.model.tipoTransmissaoAct === ETipoTransmissaoActiva.TransmissaoIsentasDeIvaPorSujeicaoAIMTArt9N30)
    ) {
      this.model.declaracaoPeriodica = EDeclaracaoPeriodicaInfo.SQUADRODCAMPO104;
    } else if (
      this.model.tipoOperacao === EControloIvaTipoOperacao.Passiva &&
      this.model.tipoLiquidacao === ETipoLiquidacao.ComLiquidacao &&
      this.model.tipoTransmissaoAct === ETipoTransmissaoActiva.EmissoesGasesEfeitoEstufaIvaDevidoPeloAdquirente
    ) {
      this.model.declaracaoPeriodica = EDeclaracaoPeriodicaInfo.SQUADROBCAMPO105;
    } else if (
      this.model.tipoOperacao === EControloIvaTipoOperacao.Passiva &&
      this.model.tipoLiquidacao === ETipoLiquidacao.ComLiquidacao &&
      this.model.tipoTransmissaoPas === ETipoTransmissaoPassiva.AquisicaoDeCorticaEOutrosProdutosDeOrigemSilvicola
    ) {
      this.model.declaracaoPeriodica = EDeclaracaoPeriodicaInfo.SQUADROBCAMPO107;
    } else {
      this.model.declaracaoPeriodica = EDeclaracaoPeriodicaInfo.NA;
    }
  }
}
