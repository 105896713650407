import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {IPlToolbarItem} from 'pl-comps-angular';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {EExtratosDTTipo, IExtratosDTCallback, IExtratosDTOutputParams, IExtratosDTStateParams} from '../extratosDT.module.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ExtratosDTPrintModalComponent} from '../modals/print/extratosDT.print.modal.component';

const BTN_PROCESS_PDF = 'processar';

@Component({
  selector: 'extratosDT',
  templateUrl: './extratosDT.module.component.html'
})
export class ExtratosDTModuleComponent extends ModuloComponent implements OnInit {
  @Input() public moduleType: EExtratosDTTipo;
  @Input() public moduleName: string;
  @Input() public empresaTemContabilidadeDigital: boolean;
  @Input() public empresaAnoEmCursoIRC: number;

  public readonly callback: IExtratosDTCallback;
  public params: IExtratosDTStateParams;
  public form: FormGroupDirective;

  private readonly _btnProcessPdf: IPlToolbarItem;
  private _pesquisaFilters: IExtratosDTOutputParams;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.params = this._transition.params();
    this.callback = {};

    this._btnProcessPdf = {
      id: BTN_PROCESS_PDF,
      order: 1,
      type: 'button',
      class: 'btn-success',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      caption: 'global.btn.processPDF',
      disabled: true,
      tooltip: {
        text: 'global.text.pleaseSearchFirst',
        placement: ['bottom', 'bottom-left']
      },
      click: () => this._modalExtratosPreviewPDF()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._evaluateMaintenanceModeFullscreen();
    this.toolbar.addButton(this._btnProcessPdf);
  }

  public pesquisarChanged(output: IExtratosDTOutputParams): void {
    if (output) {
      this._pesquisaFilters = output;
      this._btnProcessPdf.disabled = this._pesquisaFilters.semResgistos;
      this._btnProcessPdf.tooltip.disabled = !this._btnProcessPdf.disabled;
      if (!this._pesquisaFilters.semResgistos) {
        this._btnProcessPdf.disabled = false;
        setTimeout(() => {
          this.toolbar.focusItem(BTN_PROCESS_PDF);
        }, 0);
      } else {
        this._btnProcessPdf.disabled = true;
      }
    }
  }

  public onParamsChanged(): void {
    this._btnProcessPdf.disabled = true;
    this._btnProcessPdf.tooltip.disabled = !this._btnProcessPdf.disabled;
  }

  protected _onConfigurationsChanged(): void {
    this.empresaTemContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
    this.empresaAnoEmCursoIRC = this.configurations.empresa.anoEmCursoIRC;
    this._evaluateMaintenanceModeFullscreen();
  }

  private _evaluateMaintenanceModeFullscreen(): void {
    if (this.maintenanceMode) {
      this.setMaintenanceModeFullscreen(this.empresaTemContabilidadeDigital);
    }
  }

  private _modalExtratosPreviewPDF(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ExtratosDTPrintModalComponent);
    const componentInstance: ExtratosDTPrintModalComponent = modalInstance.componentInstance;
    componentInstance.filters = this._pesquisaFilters;
    return modalInstance.result;
  }
}
