import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {ConfigSiteService} from '../configsite.service';
import {IApiRequestConfigWithBody, TServiceResponse} from '../api/api.service.interface';
import {IJsonTermsAccepted} from './jsonTermsAccepted';

@Injectable({
  providedIn: 'root'
})
export class TermsAcceptedService {
  private readonly _path: string;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _configSiteService: ConfigSiteService
  ) {
    this._path = `${this._apiService.path.restapi}/manager/termsaccepted`;
  }

  public termsRGPDVersion(force: boolean = false): Promise<string> {
    return this._configSiteService.termsRGPDVersion(force);
  }

  public acceptTerms(version: string, config?: IApiRequestConfigWithBody<IJsonTermsAccepted>): TServiceResponse<IJsonTermsAccepted> {
    return this._apiService.post<IJsonTermsAccepted>({url: `${this._path}/${version}`, ...config});
  }
}
