import {Component, Injector, OnInit} from '@angular/core';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {EDelphiNumberTypes} from 'pl-comps-angular';
import {PrevTesObrasService} from '../prevTesObras.module.service';
import {HttpResponse} from '@angular/common/http';
import {IJsonPrevTesObra} from '../jsonPrevTesObras.module.interface';
import ArrayStore from 'devextreme/data/array_store';
import {PrevTesObrasRubricaModalComponent} from '../modals/rubrica/prevTesObras.rubrica.modal.component';
import {ModuloComponent} from '../../../../components/module/module.component';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';

@Component({
  selector: 'module-prev-tes-obras',
  templateUrl: './prevTesObras.module.component.html'
})
export class PrevTesObrasModuleComponent extends ModuloComponent implements OnInit {
  public dataGridDefinition: IDevExpressDataGrid;
  public codTipoPlaneamentoDe: number;
  public codTipoPlaneamentoAte: number;
  public codEstadoObraDe: string;
  public codEstadoObraAte: string;

  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _prevTesObrasService: PrevTesObrasService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'cabCodigo', dataType: 'string', caption: 'prevtesobras.cabCodigo'},
        {dataField: 'cabDescricao', dataType: 'string', caption: 'prevtesobras.cabDescricao'},
        {dataField: 'codPlaneamenTipo', dataType: 'number', caption: 'prevtesobras.codPlaneamenTipo'},
        {dataField: 'descricao', dataType: 'string', caption: 'prevtesobras.descricao'},
        {dataField: 'codEstado', dataType: 'string', caption: 'prevtesobras.codEstado'},
        {dataField: 'codEstadoDesc', dataType: 'string', caption: 'prevtesobras.codEstadoDesc'}
      ],
      dataSource: [],
      export: {filename: 'global.menu.prevtesobras'},
      selection: {mode: 'multiple', selectAllMode: 'allPages', showCheckBoxesMode: 'always'},
      remoteOperations: false
    };
    this.codTipoPlaneamentoDe = 0;
    this.codTipoPlaneamentoAte = EDelphiNumberTypes.MaxSmallInt;
    this.codEstadoObraDe = '';
    this.codEstadoObraAte = 'ZZZZ';
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'search',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-search fa-fw"></i>',
      class: 'btn-primary',
      caption: 'global.btn.search',
      click: () => this._pesquisar()
    });
    this.toolbar.addButton({
      id: 'import',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-files-o fa-fw"></i>',
      class: 'btn-secondary',
      caption: 'global.btn.import',
      click: () => this._import()
    });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  private _pesquisar(): Promise<void> {
    return this._prevTesObrasService
      .getListaPrevTesObras(this.codTipoPlaneamentoDe, this.codTipoPlaneamentoAte, this.codEstadoObraDe, this.codEstadoObraAte)
      .then((response: HttpResponse<Array<IJsonPrevTesObra>>) => {
        this.dataGridDefinition.dataSource = new ArrayStore({
          data: response.body
        });
      });
  }

  private _import(): Promise<void> {
    const selectedItems: Array<IJsonPrevTesObra> = this._dataGridInstance.getSelectedRowsData();
    return this._dataGridInstance
      .getDataSource()
      .store()
      .load()
      .then((store: Array<IJsonPrevTesObra>) => {
        for (const selectedItem of selectedItems) {
          store.find(
            (value: IJsonPrevTesObra) =>
              value.codPlaneamenTipo === selectedItem.codPlaneamenTipo &&
              value.tipoOrc === selectedItem.tipoOrc &&
              value.versaoOrc === selectedItem.versaoOrc &&
              value.nOrcamGeralID === selectedItem.nOrcamGeralID
          ).selecionado = true;
        }
        const modalInstance = this._cgModalService.showVanilla(PrevTesObrasRubricaModalComponent, {size: 'md'});
        const componentInstance: PrevTesObrasRubricaModalComponent = modalInstance.componentInstance;
        componentInstance.prevTesObrasList = store;
        componentInstance.codTipoPlaneamentoDe = this.codTipoPlaneamentoDe;
        componentInstance.codTipoPlaneamentoAte = this.codTipoPlaneamentoAte;
        componentInstance.codEstadoObraDe = this.codEstadoObraDe;
        componentInstance.codEstadoObraAte = this.codEstadoObraAte;
        modalInstance.result.then((result: Array<IJsonPrevTesObra>) => {
          this.dataGridDefinition.dataSource = new ArrayStore({
            data: result
          });
        });
      });
  }
}
