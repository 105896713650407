import {Component, Injector, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {IRadioGroup} from '../../../../../../../common/interfaces/interfaces';
import {RADIO_GROUP_TIPO_ARREDONDAMENTO_TEMPLATE} from '../../mapaExploracao.module.interface';
import {ContabilidadeEstatisticaService} from '../../../contabilidadeEstatistica.module.service';
import {ETipoArredondamento, IJsonConfigMapaExploracao} from '../../jsonMapaExploracao.module.interface';

@Component({
  selector: 'modal-mapaExploracao.config',
  templateUrl: './mapaExploracao.config.modal.component.html'
})
export class MapaExploracaoConfigModalComponent extends CGModalComponent<void> implements OnInit {
  public readonly radioGroupTemplateTipoArredondamento: IRadioGroup<ETipoArredondamento>;
  public promise: Promise<void>;
  public tipoArredondamento: ETipoArredondamento;
  private _configMapaExploracao: IJsonConfigMapaExploracao;

  constructor(
    protected readonly _injector: Injector,
    private readonly _contabilidadeEstatisticaService: ContabilidadeEstatisticaService
  ) {
    super(_injector);
    this.radioGroupTemplateTipoArredondamento = RADIO_GROUP_TIPO_ARREDONDAMENTO_TEMPLATE;
    this.tipoArredondamento = ETipoArredondamento.ArredondamentoAoEuro;
  }

  public ngOnInit(): void {
    this._contabilidadeEstatisticaService
      .getConfigMapaExploracao()
      .then((response) => {
        this._configMapaExploracao = response.body;
        this.tipoArredondamento = response.body.tipoArredondamento ?? ETipoArredondamento.SemArredondamento;
      })
      .finally(() => {
        this.promise = undefined;
      });
  }

  public close(): Promise<void> {
    this._configMapaExploracao.tipoArredondamento = this.tipoArredondamento ?? ETipoArredondamento.SemArredondamento;
    this.promise = this._contabilidadeEstatisticaService.saveConfigMapaExploracao(this._configMapaExploracao).then((response) => {
      this._configMapaExploracao = response.body;
      super.close();
    });
    return this.promise;
  }
}
