import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlNavWizardCallback, IPlNavWizardDefinition, IPlNavWizardEventBeforeChange, IPlNavWizardEventStep, IPlNavWizardStep, PlAlertService, TPlNavWizardBeforeStepChangeFn} from 'pl-comps-angular';
import {ModuloComponent} from '../../../../components/module/module.component';
import {IJsonRHCalculaRendimentoLiquido, IJsonRHCalculaRendimentoLiquidoEmpProce, IJsonRHCalculaRendimentoLiquidoProcessamento} from '../jsonRHCalculoRendimentoLiquido.module.interface';
import {RhCalculoRendimentoLiquidoService} from '../rhCalculoRendimentoLiquido.module.service';
import {focusElement} from '../../../../../common/utils/element.utils';
import {PortalClientAccountService} from '../../../../services/portalclientaccount/portalclientaccount.service';
import moment from 'moment';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnRowClick, IDevExpressDataGridEventOnRowDblClick} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';

const STEP_INDEX_PROCESSOS = 0;
const STEP_INDEX_EMPREGADOS = 1;
const STEP_INDEX_CALCULO = 2;
const SUBSTRACT_YEARS = 10;

@Component({
  selector: 'module-rhcalculorendimentoliquido',
  templateUrl: './rhCalculoRendimentoLiquido.module.component.html'
})
export class RhCalculoRendimentoLiquidoModuleComponent extends ModuloComponent implements OnInit {
  public readonly definition: IPlNavWizardDefinition;
  public readonly plWizardCallback: IPlNavWizardCallback;
  public readonly outputAbdes: string;

  public readonly definitionProcessos: IDevExpressDataGrid;
  public readonly definitionEmpregados: IDevExpressDataGrid;

  public selectedStep: IPlNavWizardStep;
  public yearsSource: Array<number>;
  public promise: Promise<void>;
  public year: number;
  public identificacaoEmp: string;
  public pdfUrl: string;
  public rhCalculaRendimentoLiquidoProcessamentoList: Array<IJsonRHCalculaRendimentoLiquidoProcessamento>;
  public rhCalculaRendimentoLiquidoEmpProceList: Array<IJsonRHCalculaRendimentoLiquidoEmpProce>;
  public rhCalculaRendimentoLiquido: IJsonRHCalculaRendimentoLiquido;

  private _rhCalculaRendimentoLiquidoProcessamentoSelected: IJsonRHCalculaRendimentoLiquidoProcessamento;
  private _rhCalculaRendimentoLiquidoEmpProceSelected: IJsonRHCalculaRendimentoLiquidoEmpProce;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhcalculoRendimentoLiquidoService: RhCalculoRendimentoLiquidoService,
    private readonly _plAlertService: PlAlertService,
    private readonly _portalClientAccountService: PortalClientAccountService
  ) {
    super(_injector);
    this.definition = {
      items: []
    };
    this.definitionProcessos = {
      columns: [
        {dataField: 'nProcessamento', dataType: 'number', caption: 'rhcalculorendimentoliquido.processo.nProcessamento'},
        {dataField: 'dataProcessamento', dataType: 'date', caption: 'rhcalculorendimentoliquido.processo.dataProcessamento'},
        {dataField: 'tipoProcessamento', dataType: 'number', caption: 'rhcalculorendimentoliquido.processo.tipoProcessamento'},
        {dataField: 'proceDescricao', dataType: 'string', caption: 'rhcalculorendimentoliquido.processo.proceDescricao'},
        {dataField: 'dataInicoProc', dataType: 'date', caption: 'rhcalculorendimentoliquido.processo.dataInicoProc'},
        {dataField: 'dataFimProce', dataType: 'date', caption: 'rhcalculorendimentoliquido.processo.dataFimProce'}
      ],
      keyExpr: 'nProcessamento',
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarTemplateProce',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    this.definitionEmpregados = {
      columns: [
        {dataField: 'codEmpregado', dataType: 'number', caption: 'rhcalculorendimentoliquido.empregado.codEmpregado'},
        {dataField: 'nomeEmpregado', dataType: 'string', caption: 'rhcalculorendimentoliquido.empregado.nomeEmpregado'},
        {dataField: 'strEstadoProcEmpregado', dataType: 'string', caption: 'rhcalculorendimentoliquido.empregado.strEstadoProcEmpregado'},
        {dataField: 'nProcessamento', dataType: 'number', caption: 'rhcalculorendimentoliquido.empregado.nProcessamento'},
        {dataField: 'nRecibo', dataType: 'number', caption: 'rhcalculorendimentoliquido.empregado.nRecibo'},
        {dataField: 'dataProcessamento', dataType: 'date', caption: 'rhcalculorendimentoliquido.empregado.dataProcessamento'},
        {dataField: 'identificacaoEmpregado', dataType: 'string', caption: 'rhcalculorendimentoliquido.empregado.identificacaoEmpregado'}
      ],
      keyExpr: 'codEmpregado',
      remoteOperations: false
    };

    this._clearCalculaRendimentoLiquido();
    this.outputAbdes = '{{nCodABDESC}} - {{designacaoBreve}}';
    this.identificacaoEmp = '';
    this.pdfUrl = '';
    this.yearsSource = [];
    this.rhCalculaRendimentoLiquidoProcessamentoList = [];
    this.rhCalculaRendimentoLiquidoEmpProceList = [];
    this.plWizardCallback = {};
    this._rhCalculaRendimentoLiquidoProcessamentoSelected = undefined;
    this._rhCalculaRendimentoLiquidoEmpProceSelected = undefined;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._setYears();
    this.year = moment().year();
    this._listaProcessamentos();
  }

  public onRowClickProcessos(event: IDevExpressDataGridEventOnRowClick<IJsonRHCalculaRendimentoLiquidoProcessamento, number>): void {
    this._rhCalculaRendimentoLiquidoProcessamentoSelected = event.data;
    this._rhCalculaRendimentoLiquidoEmpProceSelected = undefined;
  }

  public onRowDblClickProcessos(event: IDevExpressDataGridEventOnRowDblClick<IJsonRHCalculaRendimentoLiquidoProcessamento, number>): void {
    this._rhCalculaRendimentoLiquidoProcessamentoSelected = event.data;
    this._rhCalculaRendimentoLiquidoEmpProceSelected = undefined;
    this.nextStepEmpregados();
  }

  public onRowClickEmpregados(event: IDevExpressDataGridEventOnRowClick<IJsonRHCalculaRendimentoLiquidoEmpProce, number>): void {
    this._rhCalculaRendimentoLiquidoEmpProceSelected = event.data;
    this.identificacaoEmp = this._rhCalculaRendimentoLiquidoEmpProceSelected.identificacaoEmpregado;
    this._setPdfRecibo();
  }

  public onRowDblClickEmpregados(event: IDevExpressDataGridEventOnRowDblClick<IJsonRHCalculaRendimentoLiquidoEmpProce, number>): void {
    this._rhCalculaRendimentoLiquidoEmpProceSelected = event.data;
    this.identificacaoEmp = this._rhCalculaRendimentoLiquidoEmpProceSelected.identificacaoEmpregado;
    this._setPdfRecibo();
    this.nextStepCalcular();
  }

  public yearChanged(year: number): Promise<void> {
    if (this.year === year) {
      this.promise = Promise.resolve();
      return this.promise;
    }
    this.year = year;
    this.promise = this._listaProcessamentos();
    return this.promise;
  }

  public stepChanged({currentStep}: IPlNavWizardEventStep): Promise<void> | void {
    if (currentStep === this.definition.items[STEP_INDEX_CALCULO]) {
      setTimeout(() => {
        const input: HTMLElement = this._element.querySelector('.nav-wizard-item-content-wrapper.active button.action-finalize');
        if (input) {
          focusElement(input);
        }
      });
    }
  }

  public nextStepEmpregados(): void {
    this.plWizardCallback.nextStep();
  }

  public nextStepCalcular(): void {
    this.plWizardCallback.nextStep();
  }

  public onStepChange(step: IPlNavWizardStep): void {
    this.selectedStep = step;
  }

  public readonly fnFinalize: () => Promise<void> = () => this._finalize();

  public readonly fnBeforeStepChange: TPlNavWizardBeforeStepChangeFn = (event: IPlNavWizardEventBeforeChange) => this._beforeStepChange(event);

  private _clearCalculaRendimentoLiquido(): void {
    this.rhCalculaRendimentoLiquido = {
      adicionaRecolha: false,
      codEmp: 0,
      nCodAbdesc: '',
      valorLiquido: 0,
      nProcessamento: 0
    };
  }

  private _setYears(): void {
    const minYear = moment().subtract(SUBSTRACT_YEARS, 'years').year();
    const maxYear = moment().add(1, 'years').year();
    for (let i = minYear; i <= maxYear; i++) {
      this.yearsSource.push(i);
    }
  }

  private _listaProcessamentos(): Promise<void> {
    return this._rhcalculoRendimentoLiquidoService.getListaProcessamentos(this.year).then((response: HttpResponse<Array<IJsonRHCalculaRendimentoLiquidoProcessamento>>) => {
      this.rhCalculaRendimentoLiquidoProcessamentoList = response.body;
    });
  }

  private _listaEmpregados(): Promise<void> {
    return this._rhcalculoRendimentoLiquidoService
      .postListaEmpregadosProce(this._rhCalculaRendimentoLiquidoProcessamentoSelected)
      .then((response: HttpResponse<Array<IJsonRHCalculaRendimentoLiquidoEmpProce>>) => {
        this.rhCalculaRendimentoLiquidoEmpProceList = response.body;
        this._rhCalculaRendimentoLiquidoEmpProceSelected = undefined;
      });
  }

  private _finalize(): Promise<void> {
    this.rhCalculaRendimentoLiquido.codEmp = this._rhCalculaRendimentoLiquidoEmpProceSelected.codEmpregado;
    this.rhCalculaRendimentoLiquido.nProcessamento = this._rhCalculaRendimentoLiquidoEmpProceSelected.nProcessamento;
    return this._rhcalculoRendimentoLiquidoService.postCalculaRendimentoLiquido(this.rhCalculaRendimentoLiquido).then(() => {
      this._plAlertService.success('rhcalculorendimentoliquido.success');
      this._clearCalculaRendimentoLiquido();
      this.rhCalculaRendimentoLiquido.nProcessamento = this._rhCalculaRendimentoLiquidoEmpProceSelected.nProcessamento;
      this.rhCalculaRendimentoLiquido.codEmp = this._rhCalculaRendimentoLiquidoEmpProceSelected.codEmpregado;
      this._setPdfRecibo();
      return Promise.resolve();
    });
  }

  private _beforeStepChange({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (currentStep === this.definition.items[STEP_INDEX_PROCESSOS] && (type === 'next' || (nextStep && nextStep === this.definition.items[STEP_INDEX_EMPREGADOS]))) {
      if (!this._rhCalculaRendimentoLiquidoProcessamentoSelected) {
        this._plAlertService.error('rhcalculorendimentoliquido.messages.invalidStepProc');
        return Promise.resolve(false);
      }
      this._listaEmpregados().then(() => undefined);
      if (
        this.rhCalculaRendimentoLiquidoEmpProceList?.length &&
        this.rhCalculaRendimentoLiquidoEmpProceList[0].nProcessamento === this._rhCalculaRendimentoLiquidoProcessamentoSelected.nProcessamento
      ) {
        return Promise.resolve(true);
      }
      return Promise.resolve(true);
    }
    if (currentStep === this.definition.items[STEP_INDEX_EMPREGADOS] && (type === 'next' || (nextStep && nextStep === this.definition.items[STEP_INDEX_CALCULO]))) {
      if (!this._rhCalculaRendimentoLiquidoEmpProceSelected) {
        this._plAlertService.error('rhcalculorendimentoliquido.messages.invalidStepEmp');
        return Promise.resolve(false);
      }
      return Promise.resolve(true);
    }
    if (currentStep === this.definition.items[STEP_INDEX_PROCESSOS] && (type === 'next' || (nextStep && nextStep === this.definition.items[STEP_INDEX_CALCULO]))) {
      if (!this._rhCalculaRendimentoLiquidoEmpProceSelected) {
        this._plAlertService.error('rhcalculorendimentoliquido.messages.invalidStepEmp');
        return Promise.resolve(false);
      }
      return Promise.resolve(true);
    }
    return Promise.resolve(true);
  }

  private _setPdfRecibo(): void {
    const deEmp: number = this._rhCalculaRendimentoLiquidoEmpProceSelected.codEmpregado;
    const ateEmp: number = this._rhCalculaRendimentoLiquidoEmpProceSelected.codEmpregado;
    const reportName: string = this._configService.configurations.rh.recibosVencimento.reports.RECSAL.reportImpressao;
    const nprocessamento: number = this._rhCalculaRendimentoLiquidoEmpProceSelected.nProcessamento;
    this._portalClientAccountService.getRecibosVencimentosUrl(deEmp, ateEmp, nprocessamento, reportName).subscribe((url: string) => {
      this.pdfUrl = undefined;
      setTimeout(() => {
        this.pdfUrl = url;
      });
    });
  }
}
