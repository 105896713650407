import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonModelo10, IJsonModelo10Quadro5Item} from './jsonModelo10.module.interface';
import {IJsonString} from '../../../../common/interfaces/json';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class Modelo10Service {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/modelo10`;
  }

  public getModelo10(tipModelo10: string): TServiceResponse<IJsonModelo10> {
    return this._apiService.get<IJsonModelo10>({
      url: this._path,
      params: {
        tipmodelo: tipModelo10
      }
    });
  }

  public getRadicaisConta(): TServiceResponse<IJsonString> {
    return this._apiService.get<IJsonString>({
      url: `${this._path}/radicaisconta`
    });
  }

  public saveRadicaisConta(radicais: string): TServiceResponse<IJsonString> {
    return this._apiService.post<IJsonString>({
      url: `${this._path}/radicaisconta`,
      params: {
        radicais: radicais
      }
    });
  }

  public saveModelo10(modelo10: IJsonModelo10): TServiceResponse<IJsonModelo10> {
    return this._apiService.post<IJsonModelo10>({
      url: `${this._path}/guardarmodelo10`,
      body: modelo10
    });
  }

  public criarFicheiro(modelo10: IJsonModelo10): TServiceResponse<Blob> {
    return this._apiService.post<Blob, IJsonModelo10>({
      url: `${this._path}/criarficheiro`,
      body: modelo10,
      responseType: 'blob'
    });
  }

  public quadro05AddEditLine(modelo10: IJsonModelo10Quadro5Item, isNewLine: boolean, indexLine: number): TServiceResponse<IJsonModelo10> {
    return this._apiService.post<IJsonModelo10, IJsonModelo10Quadro5Item>({
      url: `${this._path}/quadro05addedit`,
      body: modelo10,
      params: {
        isnewline: isNewLine,
        indexline: indexLine
      }
    });
  }

  public deleteModelo10(): TServiceResponse<IJsonModelo10> {
    return this._apiService.delete<IJsonModelo10>({url: this._path});
  }

  public deleteQuadro05Line(modelo10: IJsonModelo10): TServiceResponse<IJsonModelo10> {
    return this._apiService.delete<IJsonModelo10>({
      url: `${this._path}/linhaquadro5`,
      body: modelo10
    });
  }

  public validarDeclaracaoAT(modelo10: IJsonModelo10): TServiceResponse<void> {
    return this._apiService.post<void, IJsonModelo10>({
      url: `${this._path}/at/validar`,
      body: modelo10
    });
  }

  public submeterDeclaracaoAT(modelo10: IJsonModelo10): TServiceResponse<void> {
    return this._apiService.post<void, IJsonModelo10>({
      url: `${this._path}/at/submeter`,
      body: modelo10
    });
  }
}
