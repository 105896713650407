<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="modalTitle"></h4>
</div>

<div class="modal-body">
  <rh-estatisticas-por-empresa [nEmpresa]="nEmpresa" [ano]="ano" [mes]="mes" [callback]="porEmpresaCallback"></rh-estatisticas-por-empresa>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  <pl-button klass="btn-primary" [onClick]="close"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
</div>
