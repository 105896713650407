<div class="company-status container-fluid">
  <div class="row">
    <div class="company-status-wrapper col-12 col-sm-8 col-md-6 col-lg-4">
      <div class="company-status-header">
        <h5 [innerHTML]="header"></h5>
        <h3>
          <strong>{{ taskName }}</strong>
        </h3>
      </div>

      <div class="company-status-actions">
        <button type="button" class="btn btn-light" [click]="goBack" plPromise><i class="fa fa-fw fa-chevron-left"></i>&nbsp;<span [translate]="'companystatus.text.back'"></span></button>

        <button type="button" class="btn btn-primary" [click]="changeEmpresa" plPromise>
          <i class="fa fa-fw fa-institution"></i>&nbsp;<span [translate]="'global.menu.account.changeEmpresa'"></span>
        </button>

        <button type="button" class="btn btn-primary" [click]="changeUser" plPromise><i class="fa fa-fw fa-user"></i>&nbsp;<span [translate]="'global.menu.account.changeUser'"></span></button>

        <button type="button" class="btn btn-danger" *ngIf="cancelable" [click]="cancel" plPromise><i class="fa fa-fw fa-ban"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
      </div>

      <div class="card bg-light status-messages">
        <div class="card-header" *ngIf="textDescription">
          <div [innerHTML]="textDescription"></div>
          <div *ngIf="textScheduled">
            <hr />
            <div [innerHTML]="textScheduled"></div>
          </div>
        </div>

        <div class="card-body">
          <div class="status-messages-loading" *ngIf="!!promise">
            <i class="fa fa-fw fa-spin fa-circle-o-notch" aria-hidden="true"></i>
          </div>

          <p class="card-text status-message">
            <span>[{{ statusMessage.timestampFormatted }}] - {{ statusMessage.content }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
