<div class="modelo22-anexoD">
  <pl-form>
    <pl-navigator contentMaxHeight="80vh" [plPromise]="promise">
      <pl-navigator-item caption="modelo22.anexoD.quadro03.title">
        <div *plNavigatorItemCaption><span class="number-title-box">03</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoD.quadro03.title'"></span></div>

        <div *plNavigatorItemContent>
          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">031</div>
              <label [translate]="'modelo22.anexoD.quadro03.desc31'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <div class="inputs-currency-inline mt-4">
                <div class="row">
                  <div class="col"></div>
                  <div class="col-lg-3 text-center"><label [translate]="'modelo22.anexoD.quadro03.rendliqu'"></label></div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field301'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">301</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo301"
                        [model]="anexoD.quadro03.campo301"
                        (modelChange)="anexoD.quadro03.campo301 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field302'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">302</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo302"
                        [model]="anexoD.quadro03.campo302"
                        (modelChange)="anexoD.quadro03.campo302 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field303'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">303</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo303"
                        [model]="anexoD.quadro03.campo303"
                        (modelChange)="anexoD.quadro03.campo303 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field313'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">313</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo313"
                        [model]="anexoD.quadro03.campo313"
                        (modelChange)="anexoD.quadro03.campo313 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field314'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">314</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo314"
                        [model]="anexoD.quadro03.campo314"
                        (modelChange)="anexoD.quadro03.campo314 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field316'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">316</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo316"
                        [model]="anexoD.quadro03.campo316"
                        (modelChange)="anexoD.quadro03.campo316 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field304'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">304</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo304"
                        [model]="anexoD.quadro03.campo304"
                        (modelChange)="anexoD.quadro03.campo304 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>
              </div>

              <div class="box-title mt-4">
                <div class="input-box-numbers-title">031-A</div>
                <label [translate]="'modelo22.anexoD.quadro03.desc31A'" class="input-box-numbers-label"></label>
              </div>

              <div class="ms-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinition31A" cgDxDataGridInstanceName="campo031AList" [dataSource]="dataGridDefinition31A.dataSource" (onRowRemoved)="onDataGridRowRemoved()">
                  <div *dxTemplate="let item of 'cellTemplateBtn31A'">
                    <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro3(item.rowIndex, '31A')">
                      <i class="fa fa-fw fa-trash"></i>
                    </button>
                  </div>
                </dx-data-grid>
              </div>

              <div class="row mt-4">
                <div class="box-title">
                  <div class="input-box-numbers-title">031-B</div>
                  <label [translate]="'modelo22.anexoD.quadro03.desc31B'" class="input-box-numbers-label"></label>
                </div>
              </div>

              <div class="ms-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinition31B" cgDxDataGridInstanceName="campo031BList" [dataSource]="dataGridDefinition31B.dataSource">
                  <div *dxTemplate="let item of 'cellTemplateBtn31B'">
                    <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro3(item.rowIndex, '31B')">
                      <i class="fa fa-fw fa-trash"></i>
                    </button>
                  </div>
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">032</div>
              <label [translate]="'modelo22.anexoD.quadro03.desc32'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <div class="inputs-currency-inline mt-4">
                <div class="row">
                  <div class="col"></div>
                  <div class="col-lg-3 text-center"><label [translate]="'modelo22.anexoD.quadro03.rendliqu'"></label></div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field305'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">305</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo305"
                        [model]="anexoD.quadro03.campo305"
                        (modelChange)="anexoD.quadro03.campo305 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field306'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">306</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo306"
                        [model]="anexoD.quadro03.campo306"
                        (modelChange)="anexoD.quadro03.campo306 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field307'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">307</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo307"
                        [model]="anexoD.quadro03.campo307"
                        (modelChange)="anexoD.quadro03.campo307 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field308'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">308</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo308"
                        [model]="anexoD.quadro03.campo308"
                        (modelChange)="anexoD.quadro03.campo308 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field309'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">309</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo309"
                        [model]="anexoD.quadro03.campo309"
                        (modelChange)="anexoD.quadro03.campo309 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field310'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">310</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo310"
                        [model]="anexoD.quadro03.campo310"
                        (modelChange)="anexoD.quadro03.campo310 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field311'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">311</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo311"
                        [model]="anexoD.quadro03.campo311"
                        (modelChange)="anexoD.quadro03.campo311 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field315'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">315</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo315"
                        [model]="anexoD.quadro03.campo315"
                        (modelChange)="anexoD.quadro03.campo315 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field317'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">317</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo317"
                        [model]="anexoD.quadro03.campo317"
                        (modelChange)="anexoD.quadro03.campo317 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro03.field312'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">312</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro03Campo312"
                        [model]="anexoD.quadro03.campo312"
                        (modelChange)="anexoD.quadro03.campo312 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>
              </div>

              <div class="box-title mt-4">
                <div class="input-box-numbers-title">032-A</div>
                <label [translate]="'modelo22.anexoD.quadro03.desc32A'" class="input-box-numbers-label"></label>
              </div>

              <div class="ms-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinition32A" cgDxDataGridInstanceName="campo032AList" [dataSource]="dataGridDefinition32A.dataSource">
                  <div *dxTemplate="let item of 'cellTemplateBtn32A'">
                    <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro3(item.rowIndex, '32A')">
                      <i class="fa fa-fw fa-trash"></i>
                    </button>
                  </div>
                </dx-data-grid>
              </div>

              <div class="box-title mt-4">
                <div class="input-box-numbers-title">032-B</div>
                <label [translate]="'modelo22.anexoD.quadro03.desc32B'" class="input-box-numbers-label"></label>
              </div>

              <div class="ms-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinition32B" cgDxDataGridInstanceName="campo032BList" [dataSource]="dataGridDefinition32B.dataSource">
                  <div *dxTemplate="let item of 'cellTemplateBtn32B'">
                    <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro3(item.rowIndex, '32B')">
                      <i class="fa fa-fw fa-trash"></i>
                    </button>
                  </div>
                </dx-data-grid>
              </div>
            </div>
          </section>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoD.quadro04.title">
        <div *plNavigatorItemCaption><span class="number-title-box">04</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoD.quadro04.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline mt-4">
            <div class="row">
              <div class="col text-center"><label [translate]="'modelo22.anexoD.quadro04.normalegal'"></label></div>
              <div class="col-lg-3 text-center"><label [translate]="'modelo22.anexoD.quadro04.dedefetuada'"></label></div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field401'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">401</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo401"
                    [model]="anexoD.quadro04.campo401"
                    (modelChange)="anexoD.quadro04.campo401 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field402'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">402</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo402"
                    [model]="anexoD.quadro04.campo402"
                    (modelChange)="anexoD.quadro04.campo402 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field403'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">403</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo403"
                    [model]="anexoD.quadro04.campo403"
                    (modelChange)="anexoD.quadro04.campo403 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field404'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">404</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo404"
                    [model]="anexoD.quadro04.campo404"
                    (modelChange)="anexoD.quadro04.campo404 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field405'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">405</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo405"
                    [model]="anexoD.quadro04.campo405"
                    (modelChange)="anexoD.quadro04.campo405 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field406'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">406</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo406"
                    [model]="anexoD.quadro04.campo406"
                    (modelChange)="anexoD.quadro04.campo406 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field426'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">426</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo426"
                    [model]="anexoD.quadro04.campo426"
                    (modelChange)="anexoD.quadro04.campo426 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field427'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">427</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo427"
                    [model]="anexoD.quadro04.campo427"
                    (modelChange)="anexoD.quadro04.campo427 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field428'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">428</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo428"
                    [model]="anexoD.quadro04.campo428"
                    (modelChange)="anexoD.quadro04.campo428 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field432'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">432</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo432"
                    [model]="anexoD.quadro04.campo432"
                    (modelChange)="anexoD.quadro04.campo432 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field407'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">407</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo407"
                    [model]="anexoD.quadro04.campo407"
                    (modelChange)="anexoD.quadro04.campo407 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field408'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">408</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo408"
                    [model]="anexoD.quadro04.campo408"
                    (modelChange)="anexoD.quadro04.campo408 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field409'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">409</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo409"
                    [model]="anexoD.quadro04.campo409"
                    (modelChange)="anexoD.quadro04.campo409 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field412'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">412</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo412"
                    [model]="anexoD.quadro04.campo412"
                    (modelChange)="anexoD.quadro04.campo412 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field413'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">413</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo413"
                    [model]="anexoD.quadro04.campo413"
                    (modelChange)="anexoD.quadro04.campo413 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field414'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">414</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo414"
                    [model]="anexoD.quadro04.campo414"
                    (modelChange)="anexoD.quadro04.campo414 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field415'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">415</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo415"
                    [model]="anexoD.quadro04.campo415"
                    (modelChange)="anexoD.quadro04.campo415 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field416'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">416</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo416"
                    [model]="anexoD.quadro04.campo416"
                    (modelChange)="anexoD.quadro04.campo416 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field417'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">417</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo417"
                    [model]="anexoD.quadro04.campo417"
                    (modelChange)="anexoD.quadro04.campo417 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field418'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">418</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo418"
                    [model]="anexoD.quadro04.campo418"
                    (modelChange)="anexoD.quadro04.campo418 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field419'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">419</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo419"
                    [model]="anexoD.quadro04.campo419"
                    (modelChange)="anexoD.quadro04.campo419 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field420'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">420</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo420"
                    [model]="anexoD.quadro04.campo420"
                    (modelChange)="anexoD.quadro04.campo420 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field421'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">421</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo421"
                    [model]="anexoD.quadro04.campo421"
                    (modelChange)="anexoD.quadro04.campo421 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field422'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">422</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo422"
                    [model]="anexoD.quadro04.campo422"
                    (modelChange)="anexoD.quadro04.campo422 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field423'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">423</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo423"
                    [model]="anexoD.quadro04.campo423"
                    (modelChange)="anexoD.quadro04.campo423 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field424'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">424</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo424"
                    [model]="anexoD.quadro04.campo424"
                    (modelChange)="anexoD.quadro04.campo424 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field425'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">425</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo425"
                    [model]="anexoD.quadro04.campo425"
                    (modelChange)="anexoD.quadro04.campo425 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field429'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">429</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo429"
                    [model]="anexoD.quadro04.campo429"
                    (modelChange)="anexoD.quadro04.campo429 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field430'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">430</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo430"
                    [model]="anexoD.quadro04.campo430"
                    (modelChange)="anexoD.quadro04.campo430 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field431'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">431</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo431"
                    [model]="anexoD.quadro04.campo431"
                    (modelChange)="anexoD.quadro04.campo431 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field433'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">433</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo433"
                    [model]="anexoD.quadro04.campo433"
                    (modelChange)="anexoD.quadro04.campo433 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field434'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">434</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo434"
                    [model]="anexoD.quadro04.campo434"
                    (modelChange)="anexoD.quadro04.campo434 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field435'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">435</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo435"
                    [model]="anexoD.quadro04.campo435"
                    (modelChange)="anexoD.quadro04.campo435 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field436'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">436</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo436"
                    [model]="anexoD.quadro04.campo436"
                    (modelChange)="anexoD.quadro04.campo436 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field437'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">437</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo437"
                    [model]="anexoD.quadro04.campo437"
                    (modelChange)="anexoD.quadro04.campo437 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field438'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">438</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo438"
                    [model]="anexoD.quadro04.campo438"
                    (modelChange)="anexoD.quadro04.campo438 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field439'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">439</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo439"
                    [model]="anexoD.quadro04.campo439"
                    (modelChange)="anexoD.quadro04.campo439 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field410'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">410</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro04Campo410"
                    [model]="anexoD.quadro04.campo410"
                    (modelChange)="anexoD.quadro04.campo410 = $event; calcQuadro4()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field411'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">411</div>
                  <pl-edit type="currency" attrName="anexoDQuadro04Campo411" [model]="anexoD.quadro04.campo411" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>
          </div>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">04-A</div>
              <label [translate]="'modelo22.anexoD.quadro04.desc4A'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition04A" cgDxDataGridInstanceName="campo04AList" [dataSource]="dataGridDefinition04A.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn04A'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro4(item.rowIndex, '04A')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">04-B</div>
              <label [translate]="'modelo22.anexoD.quadro04.desc4B'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <div class=""><label [translate]="'modelo22.anexoD.quadro04.radio1-2'"></label></div>
              <div class="d-flex">
                <div class="d-flex">
                  <div class="input-box-numbers">1</div>
                  <pl-edit
                    type="radio"
                    attrName="anexoDQuadro04Radio1"
                    [model]="anexoD.quadro04.campo04B12"
                    (modelChange)="anexoD.quadro04.campo04B12 = $event; emitModelo22()"
                    [properties]="{value: true, label: 'modelo22.comum.sim'}">
                  </pl-edit>
                </div>
                <div class="d-flex">
                  <div class="input-box-numbers">2</div>
                  <pl-edit
                    type="radio"
                    attrName="anexoDQuadro04Radio2"
                    [model]="anexoD.quadro04.campo04B12"
                    (modelChange)="anexoD.quadro04.campo04B12 = $event; emitModelo22()"
                    [properties]="{value: false, label: 'modelo22.comum.nao'}">
                  </pl-edit>
                </div>
              </div>

              <div class="mt-4"><span class="fw-bold" [translate]="'modelo22.anexoD.quadro04.casoafirma'"></span></div>

              <div class="inputs-currency-inline">
                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field3'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">3</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro04Campo3"
                        [model]="anexoD.quadro04.campo04B3"
                        (modelChange)="anexoD.quadro04.campo04B3 = $event; emitModelo22()"
                        [inputClass]="'text-right'"
                        [properties]="{disabled: !anexoD.quadro04.campo04B12}">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field4'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">4</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro04Campo4"
                        [model]="anexoD.quadro04.campo04B4"
                        (modelChange)="anexoD.quadro04.campo04B4 = $event; emitModelo22()"
                        [inputClass]="'text-right'"
                        [properties]="{disabled: !anexoD.quadro04.campo04B12}">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro04.field5'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">5</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro04Campo5"
                        [model]="anexoD.quadro04.campo04B5"
                        (modelChange)="anexoD.quadro04.campo04B5 = $event; emitModelo22()"
                        [inputClass]="'text-right'"
                        [properties]="{disabled: !anexoD.quadro04.campo04B12}">
                      </pl-edit>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">041</div>
              <label [translate]="'modelo22.anexoD.quadro04.desc41'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition041" cgDxDataGridInstanceName="campo041List" [dataSource]="dataGridDefinition041.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn041'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro4(item.rowIndex, '041')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>
            </div>
          </section>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoD.quadro05.title">
        <div *plNavigatorItemCaption><span class="number-title-box">05</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoD.quadro05.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro05.field501'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">501</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro05Campo501"
                    [model]="anexoD.quadro05.campo501"
                    (modelChange)="anexoD.quadro05.campo501 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro05.field502'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">502</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro05Campo502"
                    [model]="anexoD.quadro05.campo502"
                    (modelChange)="anexoD.quadro05.campo502 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoD.quadro06.title">
        <div *plNavigatorItemCaption><span class="number-title-box">06</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoD.quadro06.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field601'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">601</div>
                  <pl-edit type="date" attrName="anexoDQuadro06Campo601" [model]="anexoD.quadro06.campo601" (modelChange)="anexoD.quadro06.campo601 = $event; emitModelo22()"></pl-edit>
                </div>
              </div>
            </div>

            <section>
              <div class="box-title">
                <div class="input-box-numbers-title">604</div>
                <label [translate]="'modelo22.anexoD.quadro06.desc604'" class="input-box-numbers-label"></label>
              </div>

              <div class="ms-3 mt-2">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionCampo604List" cgDxDataGridInstanceName="quadro6Campo604List" [dataSource]="cods604List">
                  <div *dxTemplate="let item of 'cellTemplateBtn604'">
                    <button type="button" class="btn btn-sm btn-danger" (click)="deleteLineAnexoDQuadro6(item.rowIndex, '604')">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </dx-data-grid>
              </div>
            </section>

            <section>
              <div class="box-title">
                <div class="input-box-numbers-title">605</div>
                <label [translate]="'modelo22.anexoD.quadro06.desc605'" class="input-box-numbers-label"></label>
              </div>

              <div class="ms-3 mt-2">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionCampo605List" cgDxDataGridInstanceName="quadro6Campo605List" [dataSource]="cods605List">
                  <div *dxTemplate="let item of 'cellTemplateBtn605'">
                    <button type="button" class="btn btn-sm btn-danger" (click)="deleteLineAnexoDQuadro6(item.rowIndex, '605')">
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </dx-data-grid>
              </div>
            </section>

            <div class="row mt-2">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field602'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">602</div>
                  <pl-edit
                    type="cginteger"
                    attrName="anexoDQuadro06Campo602"
                    [model]="anexoD.quadro06.campo602"
                    (modelChange)="anexoD.quadro06.campo602 = $event; emitModelo22()"
                    [properties]="{validate: false}">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="mt-4"><span class="fw-bold" [translate]="'modelo22.anexoD.quadro06.nrpostocriadmantidos'"></span></div>
            <div class="ms-3 mt-2">
              <div class="row">
                <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field606'"></label></div>
                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">606</div>
                    <pl-edit
                      type="cginteger"
                      attrName="anexoDQuadro06Campo606"
                      [model]="anexoD.quadro06.campo606"
                      (modelChange)="anexoD.quadro06.campo606 = $event; emitModelo22()"
                      [properties]="{validate: false}">
                    </pl-edit>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field607'"></label></div>
                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">607</div>
                    <pl-edit
                      type="cginteger"
                      attrName="anexoDQuadro06Campo607"
                      [model]="anexoD.quadro06.campo607"
                      (modelChange)="anexoD.quadro06.campo607 = $event; emitModelo22()"
                      [properties]="{validate: false}">
                    </pl-edit>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field603'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">603</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro06Campo603"
                    [model]="anexoD.quadro06.campo603"
                    (modelChange)="anexoD.quadro06.campo603 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <section>
              <div class="box-title"><label [translate]="'modelo22.anexoD.quadro06.desc603'" class="input-box-numbers-label"></label></div>

              <div class="ms-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinition603" cgDxDataGridInstanceName="campo603List" [dataSource]="dataGridDefinition603.dataSource">
                  <div *dxTemplate="let item of 'cellTemplateBtn603'">
                    <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro6(item.rowIndex, '603')">
                      <i class="fa fa-fw fa-trash"></i>
                    </button>
                  </div>
                </dx-data-grid>
              </div>
            </section>
          </div>

          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">06.1</div>
              <label [translate]="'modelo22.anexoD.quadro06.desc06-1'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div class="mt-4"><span class="fw-bold" [translate]="'modelo22.anexoD.quadro06.benefdif'"></span></div>
              <div class="ms-3 mt-2">
                <div class="inputs-currency-inline">
                  <div class="row">
                    <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field608'"></label></div>
                    <div class="col-lg-3">
                      <div class="d-flex">
                        <div class="input-box-numbers-hundred">608</div>
                        <pl-edit
                          type="currency"
                          attrName="anexoDQuadro06Campo608"
                          [model]="anexoD.quadro06.campo608"
                          (modelChange)="anexoD.quadro06.campo608 = $event; emitModelo22()"
                          [inputClass]="'text-right'">
                        </pl-edit>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field609'"></label></div>
                    <div class="col-lg-3">
                      <div class="d-flex">
                        <div class="input-box-numbers-hundred">609</div>
                        <pl-edit
                          type="currency"
                          attrName="anexoDQuadro06Campo609"
                          [model]="anexoD.quadro06.campo609"
                          (modelChange)="anexoD.quadro06.campo609 = $event; emitModelo22()"
                          [inputClass]="'text-right'">
                        </pl-edit>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field610'"></label></div>
                    <div class="col-lg-3">
                      <div class="d-flex">
                        <div class="input-box-numbers-hundred">610</div>
                        <pl-edit
                          type="currency"
                          attrName="anexoDQuadro06Campo610"
                          [model]="anexoD.quadro06.campo610"
                          (modelChange)="anexoD.quadro06.campo610 = $event; emitModelo22()"
                          [inputClass]="'text-right'">
                        </pl-edit>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field611'"></label></div>
                    <div class="col-lg-3">
                      <div class="d-flex">
                        <div class="input-box-numbers-hundred">611</div>
                        <pl-edit
                          type="currency"
                          attrName="anexoDQuadro06Campo611"
                          [model]="anexoD.quadro06.campo611"
                          (modelChange)="anexoD.quadro06.campo611 = $event; emitModelo22()"
                          [inputClass]="'text-right'">
                        </pl-edit>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="inputs-currency-inline mt-2">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field612'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">612</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro06Campo612"
                    [model]="anexoD.quadro06.campo612"
                    (modelChange)="anexoD.quadro06.campo612 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field613'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">613</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro06Campo613"
                    [model]="anexoD.quadro06.campo613"
                    (modelChange)="anexoD.quadro06.campo613 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field614'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">614</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro06Campo614"
                    [model]="anexoD.quadro06.campo614"
                    (modelChange)="anexoD.quadro06.campo614 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field615'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">615</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro06Campo615"
                    [model]="anexoD.quadro06.campo615"
                    (modelChange)="anexoD.quadro06.campo615 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field616'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">616</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro06Campo616"
                    [model]="anexoD.quadro06.campo616"
                    (modelChange)="anexoD.quadro06.campo616 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field617'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">617</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro06Campo617"
                    [model]="anexoD.quadro06.campo617"
                    (modelChange)="anexoD.quadro06.campo617 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro06.field618'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">618</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro06Campo618"
                    [model]="anexoD.quadro06.campo618"
                    (modelChange)="anexoD.quadro06.campo618 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoD.quadro07.title">
        <div *plNavigatorItemCaption><span class="number-title-box">07</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoD.quadro07.title'"></span></div>

        <div *plNavigatorItemContent>
          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">071</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc71'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition71" cgDxDataGridInstanceName="campo071List" [dataSource]="dataGridDefinition71.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn71'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7(item.rowIndex, '71')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro07Lista071SomaControlo" cgDxDataGridInstanceName="Modelo22Quadro07Lista071SomaControlo" [dataSource]="quadro7List71Totais">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">071-A</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc71A'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition71A" cgDxDataGridInstanceName="campo071AList" [dataSource]="dataGridDefinition71A.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn71A'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7(item.rowIndex, '71A')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro07Lista071ASomaControlo" cgDxDataGridInstanceName="Modelo22Quadro07Lista071ASomaControlo" [dataSource]="quadro7List71ATotais">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">072</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc72'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div class="row">
                <div class="col-lg-3">
                  <div class="text-center"><label [translate]="'modelo22.anexoD.quadro07.field705'"></label></div>
                  <div class="d-flex justify-content-center">
                    <div class="input-box-numbers-hundred">705</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro07Campo705"
                      [model]="anexoD.quadro07.campo705"
                      (modelChange)="anexoD.quadro07.campo705 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="text-center"><label [translate]="'modelo22.anexoD.quadro07.field706'"></label></div>
                  <div class="d-flex justify-content-center">
                    <div class="input-box-numbers-hundred">706</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro07Campo706"
                      [model]="anexoD.quadro07.campo706"
                      (modelChange)="anexoD.quadro07.campo706 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="text-center"><label [translate]="'modelo22.anexoD.quadro07.field707'"></label></div>
                  <div class="d-flex justify-content-center">
                    <div class="input-box-numbers-hundred">707</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro07Campo707"
                      [model]="anexoD.quadro07.campo707"
                      (modelChange)="anexoD.quadro07.campo707 = $event; calcQuadro7()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="text-center"><label [translate]="'modelo22.anexoD.quadro07.field708'"></label></div>
                  <div class="d-flex justify-content-center">
                    <div class="input-box-numbers-hundred">708</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro07Campo708"
                      [model]="anexoD.quadro07.campo708"
                      (modelChange)="anexoD.quadro07.campo708 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">073</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc73'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition73" cgDxDataGridInstanceName="campo073List" [dataSource]="dataGridDefinition73.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn73'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7(item.rowIndex, '73')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro07Lista073SomaControlo" cgDxDataGridInstanceName="Modelo22Quadro07Lista073SomaControlo" [dataSource]="quadro7List73Totais">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">073-A</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc73A'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition73A" cgDxDataGridInstanceName="campo073AList" [dataSource]="dataGridDefinition73A.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn73A'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7(item.rowIndex, '73A')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro07Lista073ASomaControlo" cgDxDataGridInstanceName="Modelo22Quadro07Lista073ASomaControlo" [dataSource]="quadro7List73ATotais">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">074</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc74'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition74" cgDxDataGridInstanceName="campo74List" [dataSource]="dataGridDefinition74.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn74'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7(item.rowIndex, '74')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro07Lista074SomaControlo" cgDxDataGridInstanceName="Modelo22Quadro07Lista074SomaControlo" [dataSource]="quadro7List74Totais">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">074-A</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc74A'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition74A" cgDxDataGridInstanceName="campo74List" [dataSource]="dataGridDefinition74A.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn74A'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7(item.rowIndex, '74A')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro07Lista074ASomaControlo" cgDxDataGridInstanceName="Modelo22Quadro07Lista074ASomaControlo" [dataSource]="quadro7List74ATotais">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">076</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc76'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition76" cgDxDataGridInstanceName="campo76List" [dataSource]="dataGridDefinition76.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn76'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7(item.rowIndex, '76')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro07Lista076SomaControlo" cgDxDataGridInstanceName="Modelo22Quadro07Lista076SomaControlo" [dataSource]="quadro7List76Totais">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">076-A</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc76A'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition76A" cgDxDataGridInstanceName="campo76AList" [dataSource]="dataGridDefinition76A.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn76A'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7(item.rowIndex, '76A')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro07Lista076ASomaControlo" cgDxDataGridInstanceName="Modelo22Quadro07Lista076ASomaControlo" [dataSource]="quadro7List76ATotais">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">079</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc79'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition79" cgDxDataGridInstanceName="campo79List" [dataSource]="dataGridDefinition79.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn79'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7(item.rowIndex, '79')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro07Lista079SomaControlo" cgDxDataGridInstanceName="Modelo22Quadro07Lista079SomaControlo" [dataSource]="quadro7List79Totais">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">079-A</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc79A'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div class="inputs-currency-inline">
                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.tipoviat'"></label></div>
                  <div class="col-lg-3"><label [translate]="'modelo22.anexoD.quadro07.montencarg'"></label></div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field1'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">1</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo1"
                        [model]="anexoD.quadro07.campo079A1"
                        (modelChange)="anexoD.quadro07.campo079A1 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field2'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">2</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo2"
                        [model]="anexoD.quadro07.campo079A2"
                        (modelChange)="anexoD.quadro07.campo079A2 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field3'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">3</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo3"
                        [model]="anexoD.quadro07.campo079A3"
                        (modelChange)="anexoD.quadro07.campo079A3 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field4'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">4</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo4"
                        [model]="anexoD.quadro07.campo079A4"
                        (modelChange)="anexoD.quadro07.campo079A4 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field5'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">5</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo5"
                        [model]="anexoD.quadro07.campo079A5"
                        (modelChange)="anexoD.quadro07.campo079A5 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field6'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">6</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo6"
                        [model]="anexoD.quadro07.campo079A6"
                        (modelChange)="anexoD.quadro07.campo079A6 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field7'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">7</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo7"
                        [model]="anexoD.quadro07.campo079A7"
                        (modelChange)="anexoD.quadro07.campo079A7 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field8'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">8</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo8"
                        [model]="anexoD.quadro07.campo079A8"
                        (modelChange)="anexoD.quadro07.campo079A8 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field9'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">9</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo9"
                        [model]="anexoD.quadro07.campo079A9"
                        (modelChange)="anexoD.quadro07.campo079A9 = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">0710</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc710'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div class="inputs-currency-inline">
                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field710A'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">A</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo710A"
                        [model]="anexoD.quadro07.campo0710A"
                        (modelChange)="anexoD.quadro07.campo0710A = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field710B'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">B</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo710B"
                        [model]="anexoD.quadro07.campo0710B"
                        (modelChange)="anexoD.quadro07.campo0710B = $event; emitModelo22()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition710" cgDxDataGridInstanceName="campo710List" [dataSource]="dataGridDefinition710.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn710'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7(item.rowIndex, '710')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro07Lista0710SomaControlo" cgDxDataGridInstanceName="Modelo22Quadro07Lista0710SomaControlo" [dataSource]="quadro7List710Totais">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">0710-A</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc710A'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition710A" cgDxDataGridInstanceName="campo710AList" [dataSource]="dataGridDefinition710A.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn710A'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7(item.rowIndex, '710A')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro07Lista0710ASomaControlo" cgDxDataGridInstanceName="Modelo22Quadro07Lista0710ASomaControlo" [dataSource]="quadro7List710ATotais">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">075</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc75'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div class="inputs-currency-inline">
                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.normlegal'"></label></div>
                  <div class="col-lg-3"><label [translate]="'modelo22.anexoD.quadro07.dedefet'"></label></div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field717'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">717</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo717"
                        [model]="anexoD.quadro07.campo717"
                        (modelChange)="anexoD.quadro07.campo717 = $event; calcQuadro7()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field726'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">726</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo726"
                        [model]="anexoD.quadro07.campo726"
                        (modelChange)="anexoD.quadro07.campo726 = $event; calcQuadro7()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field718'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">718</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo718"
                        [model]="anexoD.quadro07.campo718"
                        (modelChange)="anexoD.quadro07.campo718 = $event; calcQuadro7()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field719'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">719</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo719"
                        [model]="anexoD.quadro07.campo719"
                        (modelChange)="anexoD.quadro07.campo719 = $event; calcQuadro7()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field727'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">727</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo727"
                        [model]="anexoD.quadro07.campo727"
                        (modelChange)="anexoD.quadro07.campo727 = $event; calcQuadro7()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field728'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">728</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo728"
                        [model]="anexoD.quadro07.campo728"
                        (modelChange)="anexoD.quadro07.campo728 = $event; calcQuadro7()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field720'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">720</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo720"
                        [model]="anexoD.quadro07.campo720"
                        (modelChange)="anexoD.quadro07.campo720 = $event; calcQuadro7()"
                        [inputClass]="'text-right'">
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"><label [translate]="'modelo22.anexoD.quadro07.field721'"></label></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">721</div>
                      <pl-edit
                        type="currency"
                        attrName="anexoDQuadro07Campo721"
                        [model]="anexoD.quadro07.campo721"
                        (modelChange)="anexoD.quadro07.campo721 = $event; calcQuadro7()"
                        [inputClass]="'text-right'"
                        [properties]="{disabled: false}">
                      </pl-edit>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">077</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc77'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition77" cgDxDataGridInstanceName="campo77List" [dataSource]="dataGridDefinition77.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn77'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7(item.rowIndex, '77')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="mt-3">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionQuadro07Lista077SomaControlo" cgDxDataGridInstanceName="Modelo22Quadro07Lista077SomaControlo" [dataSource]="quadro7List77Totais">
                </dx-data-grid>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">078</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc78'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition78" cgDxDataGridInstanceName="campo78List" [dataSource]="dataGridDefinition78.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn78'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7Tabelas78(item.rowIndex, '78')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>

              <div class="inputs-currency-inline">
                <div class="row">
                  <div class="col"><span [translate]="'modelo22.anexoD.quadro07.radio1-2'"></span></div>
                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers">1</div>
                      <pl-edit
                        type="radio"
                        attrName="anexoDQuadro07Campo78_1"
                        [model]="anexoD.quadro07.campo078_12"
                        (modelChange)="anexoD.quadro07.campo078_12 = $event; emitModelo22()"
                        [properties]="{value: true, label: 'modelo22.comum.sim'}">
                      </pl-edit>
                    </div>
                    <div class="d-flex">
                      <div class="input-box-numbers">2</div>
                      <pl-edit
                        type="radio"
                        attrName="anexoDQuadro07Campo78_2"
                        [model]="anexoD.quadro07.campo078_12"
                        (modelChange)="anexoD.quadro07.campo078_12 = $event; emitModelo22()"
                        [properties]="{value: false, label: 'modelo22.comum.nao'}">
                      </pl-edit>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">078-A</div>
              <label [translate]="'modelo22.anexoD.quadro07.desc78A'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <section class="mt-4">
                <div class="box-title">
                  <div class="input-box-numbers-title">078-A1</div>
                  <label [translate]="'modelo22.anexoD.quadro07.desc78-A1'" class="input-box-numbers-label"></label>
                </div>

                <div class="ms-3">
                  <dx-data-grid [cgDxDataGrid]="dataGridDefinition78A1" cgDxDataGridInstanceName="campo78A1List" [dataSource]="dataGridDefinition78A1.dataSource">
                    <div *dxTemplate="let item of 'cellTemplateBtn78A1'">
                      <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7Tabelas78(item.rowIndex, '78A1')">
                        <i class="fa fa-fw fa-trash"></i>
                      </button>
                    </div>
                  </dx-data-grid>
                </div>
              </section>

              <section class="mt-4">
                <div class="box-title">
                  <div class="input-box-numbers-title">078-A2</div>
                  <label [translate]="'modelo22.anexoD.quadro07.desc78-A2'" class="input-box-numbers-label"></label>
                </div>

                <div class="ms-3">
                  <dx-data-grid [cgDxDataGrid]="dataGridDefinition78A2" cgDxDataGridInstanceName="campo78A2List" [dataSource]="dataGridDefinition78A2.dataSource">
                    <div *dxTemplate="let item of 'cellTemplateBtn78A2'">
                      <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7Tabelas78(item.rowIndex, '78A2')">
                        <i class="fa fa-fw fa-trash"></i>
                      </button>
                    </div>
                  </dx-data-grid>
                </div>
              </section>

              <section class="mt-4">
                <div class="box-title">
                  <div class="input-box-numbers-title">078-A3</div>
                  <label [translate]="'modelo22.anexoD.quadro07.desc78-A3'" class="input-box-numbers-label"></label>
                </div>

                <div class="ms-3">
                  <dx-data-grid [cgDxDataGrid]="dataGridDefinition78A3" cgDxDataGridInstanceName="campo78A3List" [dataSource]="dataGridDefinition78A3.dataSource">
                    <div *dxTemplate="let item of 'cellTemplateBtn78A3'">
                      <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro7Tabelas78(item.rowIndex, '78A3')">
                        <i class="fa fa-fw fa-trash"></i>
                      </button>
                    </div>
                  </dx-data-grid>
                </div>
              </section>
            </div>
          </section>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoD.quadro08.title">
        <div *plNavigatorItemCaption><span class="number-title-box">08</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoD.quadro08.title'"></span></div>

        <div *plNavigatorItemContent>
          <dx-data-grid [cgDxDataGrid]="dataGridDefinition08" cgDxDataGridInstanceName="quadro08Lista" [dataSource]="dataGridDefinition08.dataSource">
            <div *dxTemplate="let item of 'cellTemplateBtn08'">
              <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro8(item.rowIndex)">
                <i class="fa fa-fw fa-trash"></i>
              </button>
            </div>
          </dx-data-grid>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoD.quadro09.title">
        <div *plNavigatorItemCaption><span class="number-title-box">09</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoD.quadro09.title'"></span></div>

        <div *plNavigatorItemContent>
          <hr />
          <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoD.quadro09.desc1'"></span></div>
          <hr />

          <div class="inputs-currency-inline mt-4">
            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field901'"></label></div>
              <div class="col-lg-3">
                <pl-edit
                  type="cginteger"
                  attrName="anexoDQuadro09Campo901_1"
                  [model]="anexoD.quadro09.campo901_1"
                  (modelChange)="anexoD.quadro09.campo901_1 = $event; emitModelo22()"
                  [properties]="{validate: false}">
                </pl-edit>
              </div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">901</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo901_2"
                    [model]="anexoD.quadro09.campo901_2"
                    (modelChange)="anexoD.quadro09.campo901_2 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field902'"></label></div>
              <div class="col-lg-3">
                <pl-edit
                  type="cginteger"
                  attrName="anexoDQuadro09Campo902_1"
                  [model]="anexoD.quadro09.campo902_1"
                  (modelChange)="anexoD.quadro09.campo902_1 = $event; emitModelo22()"
                  [properties]="{validate: false}">
                </pl-edit>
              </div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">902</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo902_2"
                    [model]="anexoD.quadro09.campo902_2"
                    (modelChange)="anexoD.quadro09.campo902_2 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoD.quadro09.desc2'"></span></div>
          <hr />

          <div class="inputs-currency-inline mt-4">
            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field903'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">903</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo903"
                    [model]="anexoD.quadro09.campo903"
                    (modelChange)="anexoD.quadro09.campo903 = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="text-center"><span class="fw-bold" [translate]="'modelo22.anexoD.quadro09.desc3'"></span></div>
          <hr />

          <div class="inputs-currency-inline mt-4">
            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field904A'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">904-A</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo904A"
                    [model]="anexoD.quadro09.campo904A"
                    (modelChange)="anexoD.quadro09.campo904A = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field904B'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">904-B</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo904B"
                    [model]="anexoD.quadro09.campo904B"
                    (modelChange)="anexoD.quadro09.campo904B = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field904C'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">904-C</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo904C"
                    [model]="anexoD.quadro09.campo904C"
                    (modelChange)="anexoD.quadro09.campo904C = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field904J'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">904-J</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo904J"
                    [model]="anexoD.quadro09.campo904J"
                    (modelChange)="anexoD.quadro09.campo904J = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field904D'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">904-D</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo904D"
                    [model]="anexoD.quadro09.campo904D"
                    (modelChange)="anexoD.quadro09.campo904D = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field904E'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">904-E</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo904E"
                    [model]="anexoD.quadro09.campo904E"
                    (modelChange)="anexoD.quadro09.campo904E = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field904F'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">904-F</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo904F"
                    [model]="anexoD.quadro09.campo904F"
                    (modelChange)="anexoD.quadro09.campo904F = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field904G'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">904-G</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo904G"
                    [model]="anexoD.quadro09.campo904G"
                    (modelChange)="anexoD.quadro09.campo904G = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field904H'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">904-H</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo904H"
                    [model]="anexoD.quadro09.campo904H"
                    (modelChange)="anexoD.quadro09.campo904H = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field904I'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">904-I</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo904I"
                    [model]="anexoD.quadro09.campo904I"
                    (modelChange)="anexoD.quadro09.campo904I = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field904K'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred-letter">904-K</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo904K"
                    [model]="anexoD.quadro09.campo904K"
                    (modelChange)="anexoD.quadro09.campo904K = $event; emitModelo22(true)"
                    [properties]="{modelOptions: {updateOn: 'blur'}}"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field904'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">904</div>
                  <pl-edit type="currency" attrName="anexoDQuadro09Campo904" [model]="anexoD.quadro09.campo904" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field905'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">905</div>
                  <pl-edit type="currency" attrName="anexoDQuadro09Campo905" [model]="anexoD.quadro09.campo905" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-right"><label [translate]="'modelo22.anexoD.quadro09.field906'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">906</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro09Campo906"
                    [model]="anexoD.quadro09.campo906"
                    (modelChange)="anexoD.quadro09.campo906 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4"><span class="fw-bold" [translate]="'modelo22.anexoD.quadro09.desc4'"></span></div>

          <div class="mt-3">
            <dx-data-grid [cgDxDataGrid]="dataGridDefinitionCampo907List" cgDxDataGridInstanceName="quadro9Campo907List" [dataSource]="nifs907List">
              <div *dxTemplate="let item of 'cellTemplateBtn907'">
                <button type="button" class="btn btn-sm btn-danger" (click)="deleteLineAnexoDQuadro9(item.rowIndex)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoD.quadro10.title">
        <div *plNavigatorItemCaption><span class="number-title-box">10</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoD.quadro10.title'"></span></div>

        <div *plNavigatorItemContent>
          <div class="inputs-currency-inline">
            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro10.invelegiveis'"></label></div>
              <div class="col-lg-3">
                <div class="text-center"><label [translate]="'modelo22.anexoD.quadro10.field1001'"></label></div>
                <div class="d-flex">
                  <div class="input-box-numbers-thousand">1001</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro10Campo1001"
                    [model]="anexoD.quadro10.campo1001"
                    (modelChange)="anexoD.quadro10.campo1001 = $event; calcQuadro10()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="text-center"><label [translate]="'modelo22.anexoD.quadro10.field1002'"></label></div>
                <div class="d-flex">
                  <div class="input-box-numbers-thousand">1002</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro10Campo1002"
                    [model]="anexoD.quadro10.campo1002"
                    (modelChange)="anexoD.quadro10.campo1002 = $event; calcQuadro10()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="text-center"><label [translate]="'modelo22.anexoD.quadro10.field1003'"></label></div>
                <div class="d-flex">
                  <div class="input-box-numbers-thousand">1003</div>
                  <pl-edit type="currency" attrName="anexoDQuadro10Campo1003" [model]="anexoD.quadro10.campo1003" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                </div>
              </div>
            </div>

            <div class="mt-4"><span class="fw-bold" [translate]="'modelo22.anexoD.quadro10.desc1'"></span></div>
            <div class="ms-3 mt-2">
              <div class="row">
                <div class="col"><label [translate]="'modelo22.anexoD.quadro10.field1004'"></label></div>
                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-thousand">1004</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro10Campo1004"
                      [model]="anexoD.quadro10.campo1004"
                      (modelChange)="anexoD.quadro10.campo1004 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'modelo22.anexoD.quadro10.majoracaodep'"></label></div>
                <div class="col-lg-3">
                  <div class="text-center"><label [translate]="'modelo22.anexoD.quadro10.field1005'"></label></div>
                  <div class="d-flex">
                    <div class="input-box-numbers-thousand">1005</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro10Campo1005"
                      [model]="anexoD.quadro10.campo1005"
                      (modelChange)="anexoD.quadro10.campo1005 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="text-center"><label [translate]="'modelo22.anexoD.quadro10.field1006'"></label></div>
                  <div class="d-flex">
                    <div class="input-box-numbers-thousand">1006</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro10Campo1006"
                      [model]="anexoD.quadro10.campo1006"
                      (modelChange)="anexoD.quadro10.campo1006 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="text-center"><label [translate]="'modelo22.anexoD.quadro10.field1007'"></label></div>
                  <div class="d-flex">
                    <div class="input-box-numbers-thousand">1007</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro10Campo1007"
                      [model]="anexoD.quadro10.campo1007"
                      (modelChange)="anexoD.quadro10.campo1007 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'modelo22.anexoD.quadro10.majoracaoencsegsoc'"></label></div>
                <div class="col-lg-3">
                  <div class="text-center"><label [translate]="'modelo22.anexoD.quadro10.field1008'"></label></div>
                  <div class="d-flex">
                    <div class="input-box-numbers-thousand">1008</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro10Campo1008"
                      [model]="anexoD.quadro10.campo1008"
                      (modelChange)="anexoD.quadro10.campo1008 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="text-center"><label [translate]="'modelo22.anexoD.quadro10.field1009'"></label></div>
                  <div class="d-flex">
                    <div class="input-box-numbers-thousand">1009</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro10Campo1009"
                      [model]="anexoD.quadro10.campo1009"
                      (modelChange)="anexoD.quadro10.campo1009 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="text-center"><label [translate]="'modelo22.anexoD.quadro10.field1010'"></label></div>
                  <div class="d-flex">
                    <div class="input-box-numbers-thousand">1010</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro10Campo1010"
                      [model]="anexoD.quadro10.campo1010"
                      (modelChange)="anexoD.quadro10.campo1010 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'modelo22.anexoD.quadro10.field1011'"></label></div>
                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-thousand">1011</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro10Campo1011"
                      [model]="anexoD.quadro10.campo1011"
                      (modelChange)="anexoD.quadro10.campo1011 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'global.text.other'"></label></div>
                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-thousand">1012</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro10Campo1012"
                      [model]="anexoD.quadro10.campo1012"
                      (modelChange)="anexoD.quadro10.campo1012 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'modelo22.anexoD.quadro10.field1013'"></label></div>
                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-thousand">1013</div>
                    <pl-edit type="currency" attrName="anexoDQuadro10Campo1013" [model]="anexoD.quadro10.campo1013" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro10.field1014'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-thousand">1014</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro10Campo1014"
                    [model]="anexoD.quadro10.campo1014"
                    (modelChange)="anexoD.quadro10.campo1014 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro10.field1015'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-thousand">1015</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro10Campo1015"
                    [model]="anexoD.quadro10.campo1015"
                    (modelChange)="anexoD.quadro10.campo1015 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'modelo22.anexoD.quadro10.field1016'"></label></div>
              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-thousand">1016</div>
                  <pl-edit
                    type="currency"
                    attrName="anexoDQuadro10Campo1016"
                    [model]="anexoD.quadro10.campo1016"
                    (modelChange)="anexoD.quadro10.campo1016 = $event; emitModelo22()"
                    [inputClass]="'text-right'">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoD.quadro11.title">
        <div *plNavigatorItemCaption><span class="number-title-box">11</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoD.quadro11.title'"></span></div>

        <div *plNavigatorItemContent>
          <section>
            <div class="box-title">
              <div class="input-box-numbers-title">111</div>
              <label [translate]="'modelo22.anexoD.quadro11.desc111'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div class="row">
                <div class="col-lg-3">
                  <div class="text-center"><label [translate]="'modelo22.anexoD.quadro11.field1111'"></label></div>
                  <div class="d-flex justify-content-center">
                    <div class="input-box-numbers-thousand">1111</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro11Campo1111"
                      [model]="anexoD.quadro11.campo1111"
                      (modelChange)="anexoD.quadro11.campo1111 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="text-center"><label [translate]="'modelo22.anexoD.quadro11.field1112'"></label></div>
                  <div class="d-flex justify-content-center">
                    <div class="input-box-numbers-thousand">1112</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro11Campo1112"
                      [model]="anexoD.quadro11.campo1112"
                      (modelChange)="anexoD.quadro11.campo1112 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="text-center"><label [translate]="'modelo22.anexoD.quadro11.field1113'"></label></div>
                  <div class="d-flex justify-content-center">
                    <div class="input-box-numbers-thousand">1113</div>
                    <pl-edit
                      type="currency"
                      attrName="anexoDQuadro11Campo1113"
                      [model]="anexoD.quadro11.campo1113"
                      (modelChange)="anexoD.quadro11.campo1113 = $event; emitModelo22()"
                      [inputClass]="'text-right'">
                    </pl-edit>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="text-center"><label [translate]="'modelo22.anexoD.quadro11.field1114'"></label></div>
                  <div class="d-flex justify-content-center">
                    <div class="input-box-numbers-thousand">1114</div>
                    <pl-edit type="currency" attrName="anexoDQuadro11Campo1114" [model]="anexoD.quadro11.campo1114" [inputClass]="'text-right'" [properties]="{disabled: true}"></pl-edit>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoD.quadro11A.title">
        <div *plNavigatorItemCaption><span class="number-title-box">11-A</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoD.quadro11A.title'"></span></div>

        <div *plNavigatorItemContent>
          <div><span class="fw-bold" [translate]="'modelo22.anexoD.quadro11A.desc'"></span></div>

          <div class="mt-3">
            <dx-data-grid [cgDxDataGrid]="dataGridDefinitionCampo03List" cgDxDataGridInstanceName="quadro11ACampo03List" [dataSource]="nifs03List">
              <div *dxTemplate="let item of 'cellTemplateBtn03'">
                <button type="button" class="btn btn-sm btn-danger" (click)="deleteLineAnexoDQuadro11A(item.rowIndex)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </pl-navigator-item>

      <pl-navigator-item caption="modelo22.anexoD.quadro12.title">
        <div *plNavigatorItemCaption><span class="number-title-box">12</span>&nbsp;<span class="nav-item-title" [translate]="'modelo22.anexoD.quadro12.title'"></span></div>

        <div *plNavigatorItemContent>
          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">12.1</div>
              <label [translate]="'modelo22.anexoD.quadro12.desc121'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinition121" cgDxDataGridInstanceName="quadro12Lista121" [dataSource]="dataGridDefinition121.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn121'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro12(item.rowIndex, '121')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">12.2</div>
              <label [translate]="'modelo22.anexoD.quadro12.desc122'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div class="inputs-currency-inline">
                <div class="d-flex">
                  <div class="input-box-numbers">1</div>
                  <pl-edit
                    type="checkbox"
                    attrName="anexoDQuadro12Campo122"
                    [model]="anexoD.quadro12.campo122"
                    (modelChange)="anexoD.quadro12.campo122 = $event; emitModelo22()"
                    [properties]="{label: 'modelo22.anexoD.quadro12.campo122'}">
                  </pl-edit>
                </div>
              </div>

              <dx-data-grid [cgDxDataGrid]="dataGridDefinition122" cgDxDataGridInstanceName="quadro12Lista122" [dataSource]="dataGridDefinition122.dataSource">
                <div *dxTemplate="let item of 'cellTemplateBtn122'">
                  <button type="button" class="btn btn-danger btn-xs" (click)="deleteLineAnexoDQuadro12(item.rowIndex, '122')">
                    <i class="fa fa-fw fa-trash"></i>
                  </button>
                </div>
              </dx-data-grid>
            </div>
          </section>
        </div>
      </pl-navigator-item>
    </pl-navigator>
  </pl-form>
</div>
