<pl-form [submit]="close" plPromise>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'arquivodigital.configs.synchronizationKey.title'"></h4>
  </div>

  <div class="modal-body entity-detail-form">
    <pl-group>
      <label [translate]="'arquivodigital.configs.synchronizationKey.key'"></label>
      <edit>
        <div class="form-control-align">{{ key }}</div>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-close" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</pl-form>
