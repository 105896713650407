import {IJsonDashboard, IJsonPieDashboard} from '../../modules/portalclientaccounts/dashboards/dashBoards.module.interface';

export enum EDossierFiscalTipoElemento {
  Outro = 0, // 0
  Imobilizado = 1,
  Balanco = 2,
  DemonstracaoResultadosNatureza = 3,
  RelatorioDeGestao = 4,
  ParecerDoConselhoFiscal = 5, // 5
  DemonstracaoResultadosPorFuncoes = 6,
  DemonstracaoDeFluxosDeCaixa = 7,
  DemonstracaoDasAlteracoesDoCapitalProprio = 8,
  AnexoAsDemonstracoesFinanceiras = 9,
  DocumentosDeCertificacaoLegalDeContas = 10, // 10
  AtaDeAprovacaoDeContas = 11,
  DeclaracaoModelo22 = 12,
  DemonstracaoDeLiquidacaoDeIRC = 13,
  IES = 14,
  MapaDoCalculoDoQuadro07DoModelo22EJustificativos = 15, // 15
  BalancetesDoRazaoEAnaliticoAntesEDepoisDoApuramentoResultados = 16,
  ListaDeDocumentosComprovativosDeCreditosIncobraveis = 17,
  MapaDoModeloOficialDeProvisoesEPerdasPorImparidade = 18,
  DemonstracaoDoValorDe40PorcentoDeAumentoDasDepreciacoes = 19,
  MapaDeModeloOficialDasMaisValiasEMenosValias = 20, // 20
  DemonstracaoDeDepreciacoesNaoAceitesComoGastos = 21,
  DeclaracaoRetencaoNaFonteDeIRCIRSPorTerceiros = 22,
  ComprovativosDosPagamentosPorConta = 23,
  ImpostosDiferidos = 24,
  DeclaracaoModelo10 = 25, // 25
  DeclaracaoModelo30 = 26,
  DeclaracaoModelo39 = 27,
  CorreccoesAoValorDeTransmissaoDeDireitosReaisSobreBensImoveis = 28,
  RegimeTransitorioAdocaoSNC = 29,
  MapaDeCalculoDaTributacaoAutonoma = 30, // 30
  CalculosRelacionadosComOAluguerDeViaturas = 31,
  CertidoesDeInexistenciaDeDividasATeSegurancaSocial = 32,
  MapaDeControloDePrejuizosFiscais = 33,
  MapaDeApuramentoDoLucroTributavel = 34,
  DeclaracaoDoOrgaoDeGestao = 35, // 35
  MapaDeResponsabilidadesNoBancoDePortugal = 36,
  MapaDasContasAbertasNoBancoDePortugal = 37,
  DeclaracaesPeriodicasDeIVA = 38,
  ResumoDoPortaleFaturaDeDocumentosComunicados = 39,
  CertidoesPrediaisNaAT = 40, // 40
  CertidoesPrediaisNaConservatoria = 41,
  FolhaDeCaixa = 42,
  ReconciliacoesBancarias = 43,
  OutrosDocumentosMencionadosNosCodigos = 44,
  ConstituicaoDaSociedade = 45, // 45
  ContratoDePrestacaoComCC = 46,
  ContratoDePrestacaoDeServicosComROC = 47
}

export type TPortalClientAccountDashboard = 'VendasServicos' | 'TesourariaLiquida' | 'Rendimentos' | 'GastosCompras';

export type TPortalClientAccountDashboardResult<T extends TPortalClientAccountDashboard> = T extends 'VendasServicos' | 'TesourariaLiquida' ? IJsonDashboard : IJsonPieDashboard;
