import {IJsonProcSeguradoraFolhaFerias, IJsonSegSocialProcessExistentes, IJsonSeguradoraFolhaFeriasMPCX} from './jsonProcSeguradoraFolhaFerias.module.interface';

export const MODULE_NAME_PROC_SEGURADORA_FOLHA_FERIAS = 'procseguradorafolhaferias';

export interface IProcSeguradoraFolhaFeriasDataItem {
  mes: number;
  ano: number;
  selected: boolean;
  descricao: string;
}

export interface ISeguradoraFolhaFeriasMPCX extends IJsonSeguradoraFolhaFeriasMPCX {
  _canEditNdiasPremperman?: boolean;
  _canEditNdiasIctrintermit?: boolean;
  _canEditNdias2ferpagascess?: boolean;
  _originalNdiasPremperman?: number;
  _originalNdiasIctrintermit?: number;
  _originalNdias2ferpagascess?: number;
}

export interface ISeguradoraFFMPCXProcSegSocial {
  seguradoraffmpcx: Array<ISeguradoraFolhaFeriasMPCX>;
  procsegsocial: IJsonProcSeguradoraFolhaFerias;
}

export interface ISegSocialProcessExistentes extends IJsonSegSocialProcessExistentes {
  _index?: number;
}
