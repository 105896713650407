import {IJsonIvaListagemDocumento} from '../../../entities/ivas/jsonIva.entity.interface';

export type TIvaListagemDocumentoKeyValor = 'valorLiquido' | 'valorIVA';

export interface IIvaListagemDocumento extends IJsonIvaListagemDocumento {
  [column: string]: unknown;

  tipoDoc: string;
}

export const MODULE_NAME_LISTAGENS_RESUMO_IVA = 'listagensResumoIva';
