import {AbstractControl, FormGroup} from '@angular/forms';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function findFormControl<T extends AbstractControl = AbstractControl>(name: string, root: FormGroup, ignoreDisabled: boolean = false): T {
  if (root instanceof FormGroup) {
    if (Object.prototype.hasOwnProperty.call(root.controls, name)) {
      const control: AbstractControl = root.controls[name];
      if (!ignoreDisabled || !control.disabled) {
        return <T>control;
      }
      return undefined;
    }
    for (const controlName of Object.keys(root.controls)) {
      const control: AbstractControl = findFormControl<T>(name, <FormGroup>root.controls[controlName]);
      if (control) {
        if (!ignoreDisabled || !control.disabled) {
          return <T>control;
        }
        return undefined;
      }
    }
  }
  return undefined;
}
