import {Component, Injector, Input, OnInit} from '@angular/core';
import {IPlValidator, isEmpty} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EConfigRHEstadoItem, IJsonConfigRHTrfbaTicket} from '../../jsonRHConfiguracoes.module.interface';

@Component({
  selector: 'modal-transferenciabancaria',
  templateUrl: './transferenciaBancaria.modal.component.html'
})
export class TransferenciaBancariaModalComponent extends CGModalComponent<IJsonConfigRHTrfbaTicket> implements OnInit {
  @Input() public transferencia: IJsonConfigRHTrfbaTicket;
  @Input() public edit: boolean;

  public readonly validatorBanco: IPlValidator<string>;
  public valid: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.validatorBanco = {
      message: 'rhtransferenciasbancarias.modal.bancoValidator',
      validate: () => this.valid
    };
    this.valid = false;
  }

  public ngOnInit(): void {
    if (!this.transferencia) {
      this.transferencia = {
        codBanco: '',
        nomeBanco: '',
        codOpTrfBa: '',
        nClienteBanco: '',
        nCartaoTrfBaIBAN: false,
        cartaoDifBanco: false,
        configTrfBaTicket: false,
        tipoFichTrf: 0,
        estado: EConfigRHEstadoItem.None
      };
    }
    if (this.edit) {
      this.valid = true;
    }
  }

  public close(): void {
    if (!this.valid) {
      return;
    }
    this.transferencia.estado = this.edit ? EConfigRHEstadoItem.Modified : EConfigRHEstadoItem.Added;
    super.close(this.transferencia);
  }

  public changedCodBanco(value: string): void {
    if (this.edit) {
      return;
    }
    this.transferencia.codBanco = value;
    this.valid = !isEmpty(this.transferencia.codBanco);
  }
}
