import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isObject, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {EGestaoDGEMPSType} from '../../../../components/gestaodgemps/gestaodgemps.interface';
import {ENTITY_NAME_PRH_EVENTOS_CONFIG, IPRHEventosConfigEntityService} from '../../../../entities/prheventosconfig/pRHEventosConfig.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IConfigEventos, ITipoEvento} from '../configuracoesEventos.module.interface';
import {
  IJsonConfigAbdes,
  IJsonConfigEstrutura,
  IJsonConfigTipo,
  IJsonConfigTipoProcessamento,
  IJsonPRHEventosConfig
} from '../../../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';

@Component({
  selector: 'modal-configuracoes-eventos',
  templateUrl: './configuracoesEventos.modal.component.html'
})
export class ConfiguracoesEventosModalComponent extends CGModalComponent<IConfigEventos> implements OnInit {
  @Input() public listaConfig: IJsonConfigEstrutura;
  @Input() public editing: boolean;
  @Input() public tipoEvento: ITipoEvento;
  @Input() public configEvento: IConfigEventos;

  public readonly tipos: typeof EGestaoDGEMPSType;
  public cfgEvent: IConfigEventos;

  private readonly _servicePRHEventosConfig: IPRHEventosConfigEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.tipos = EGestaoDGEMPSType;
    this.editing = false;
    this._servicePRHEventosConfig = this._entityServiceBuilder.build<IJsonPRHEventosConfig, IPRHEventosConfigEntityService>(ENTITY_NAME_PRH_EVENTOS_CONFIG);
  }

  public ngOnInit(): void {
    this.cfgEvent = {
      codPRHEventosConfig: -1,
      tipoEvento: this.tipoEvento.cod,
      tipo: this.listaConfig.listaTipo.length > 0 ? this.listaConfig.listaTipo[0].codTipo : undefined,
      ncodAbdes: this.listaConfig.listaAbDes[0].nCodAbDes,
      descricao: '',
      tipoProcessamento: this.listaConfig.listaTipoProcessamento[0].codTipoProcessamento,
      abdes: this.listaConfig.listaAbDes[0].designacao,
      tipoFalta: this.listaConfig.listaTipo.length > 0 ? this.listaConfig.listaTipo[0].descricaoTipo : '',
      processamento: this.listaConfig.listaTipoProcessamento[0].descricaoTipoProcessamento
    };
    if (this.editing) {
      this.cfgEvent = {...this.configEvento};
    }
  }

  public close(): Promise<void> {
    if (!this.cfgEvent.descricao || (this.tipoEvento.cod === EGestaoDGEMPSType.Faltas && !this.cfgEvent.tipoFalta) || !this.cfgEvent.abdes || !this.cfgEvent.tipoProcessamento) {
      this._plAlertService.error('prheventosconfig.messages.fieldsUnfiled');
      return Promise.resolve();
    }

    const prhConfig: IJsonPRHEventosConfig = {
      codPRHEventosConfig: this.cfgEvent.codPRHEventosConfig,
      tipoEvento: this.cfgEvent.tipoEvento,
      tipo: this.cfgEvent.tipo,
      ncodAbdes: this.cfgEvent.ncodAbdes,
      descricao: this.cfgEvent.descricao,
      tipoProcessamento: this.cfgEvent.tipoProcessamento
    };

    if (this.editing) {
      return this._servicePRHEventosConfig.put({id: prhConfig.codPRHEventosConfig, body: prhConfig}).then(() => {
        this._plAlertService.success('prheventosconfig.messages.editsuccess');
        super.close(this.cfgEvent);
      });
    }

    return this._servicePRHEventosConfig.post({body: prhConfig}).then((response: HttpResponse<IJsonPRHEventosConfig>) => {
      this.cfgEvent.codPRHEventosConfig = response.body.codPRHEventosConfig;
      this._plAlertService.success('prheventosconfig.messages.addsuccess');
      super.close(this.cfgEvent);
    });
  }

  public evtTipoFaltaChanged(value: {inputValue: string; item: IJsonConfigTipo}): void {
    if (isObject(value.item)) {
      this.cfgEvent.tipoFalta = value.item.descricaoTipo;
      this.cfgEvent.tipo = value.item.codTipo;
    }
  }

  public evtTipoProcessamentoChanged(value: {inputValue: string; item: IJsonConfigTipoProcessamento}): void {
    if (isObject(value.item)) {
      this.cfgEvent.processamento = value.item.descricaoTipoProcessamento;
      this.cfgEvent.tipoProcessamento = value.item.codTipoProcessamento;
    }
  }

  public evtABDESChanged(value: {inputValue: string; item: IJsonConfigAbdes}): void {
    if (isObject(value.item)) {
      this.cfgEvent.abdes = value.item.designacao;
      this.cfgEvent.ncodAbdes = value.item.nCodAbDes;
    }
  }
}
