import {Directive, TemplateRef} from '@angular/core';
import type {IPlNavWizardStepContent, IPlNavWizardStepTemplateContext} from './navwizard.interface';

@Directive({
  selector: 'ng-template[plNavWizardStepContent]',
  standalone: false
})
export class PlNavWizardStepContentDirective implements IPlNavWizardStepContent {
  constructor(public readonly templateRef: TemplateRef<IPlNavWizardStepTemplateContext>) {}
}
