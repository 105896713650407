import {HttpResponse} from '@angular/common/http';
import {DATA_SOURCE_NAME_PERIODOS_IVA} from '../../datasources/periodosiva/periodosIva.datasource.interface';
import {DATA_SOURCE_NAME_REG_IRC} from '../../datasources/regirc/regIRC.datasource.interface';
import {DATA_SOURCE_NAME_TIPOS_CONTABILIDADE} from '../../datasources/tipocontabilidade/tipoContabilidade.datasource.interface';
import {DATA_SOURCE_NAME_TIPOS_EMPRESA} from '../../datasources/tiposempresa/tiposEmpresa.datasource.interface';
import {EntidadeExternaEditComponent} from './component/edit/entidadeExterna.entity.edit.component';
import {ENTITY_NAME_ENTIDADE_EXTERNA} from './entidadeExterna.entity.interface';
import {GtoService} from '../../modules/portalcontabilidade/gto/gto.module.service';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonEmpresaErpBasic} from '../../interfaces/jsonEmpresa.interface';
import {IJsonEntidadeExterna} from './jsonEntidadeExterna.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_ENTIDADE_EXTERNA: IEntityDefinition<IJsonEntidadeExterna> = {
  name: ENTITY_NAME_ENTIDADE_EXTERNA,
  roles: [ROLE.CONTABILIDADE, ROLE.ERP],
  pageTitle: 'global.menu.entidadeexterna',
  searchPlaceholder: 'entidadeexterna.pesquisa',
  metadata: {
    keyName: 'entidadeExternaID',
    fields: [
      {name: 'entidadeExternaID', type: 'string', caption: 'entidadeexterna.fields.entidadeExternaID', visible: false},
      {name: 'codigo', type: 'string', caption: 'entidadeexterna.fields.codigo', disabled: true},
      {name: 'nEmpresaGabinete', type: 'string', caption: 'entidadeexterna.fields.nEmpresaGabinete', validators: {required: true, maxlength: 150}},
      {name: 'nome', type: 'string', caption: 'entidadeexterna.fields.nome', validators: {required: true, maxlength: 150}},
      {name: 'morada', type: 'string', caption: 'entidadeexterna.fields.morada', validators: {maxlength: 150}},
      {name: 'morada2', type: 'string', caption: 'entidadeexterna.fields.morada2', validators: {maxlength: 150}},
      {name: 'nContribuinte', type: 'string', caption: 'entidadeexterna.fields.nContribuinte', validators: {maxlength: 15}},
      {name: 'contacto', type: 'string', caption: 'entidadeexterna.fields.contacto', validators: {maxlength: 50}},
      {name: 'email', type: 'string', caption: 'entidadeexterna.fields.email', validators: {maxlength: 150}},
      {name: 'tipoEmpresa', type: DATA_SOURCE_NAME_TIPOS_EMPRESA, caption: 'entidadeexterna.fields.tipoEmpresa'},
      {name: 'tipoContabilidade', type: DATA_SOURCE_NAME_TIPOS_CONTABILIDADE, caption: 'entidadeexterna.fields.tipoContabilidade'},
      {name: 'periodoIVA', type: DATA_SOURCE_NAME_PERIODOS_IVA, caption: 'entidadeexterna.fields.periodoIVA'},
      {name: 'regimeIRC', type: DATA_SOURCE_NAME_REG_IRC, caption: 'entidadeexterna.fields.regimeIRC'}
    ],
    order: 'nome',
    searchFields: 'codigo,nEmpresaGabinete,nome',
    listFields: 'codigo,nome,nEmpresaGabinete'
  },
  autocomplete: {
    rowTemplate: '{{codigo}} - {{nome}}',
    output: 'nome',
    searchFields: 'codigo,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  detail: {
    state: {
      component: EntidadeExternaEditComponent,
      data: {
        formTemplate: {
          addMissingFields: false,
          items: [
            {field: 'codigo', order: 1},
            {type: 'group', order: 2, fields: [{field: 'nome'}, {field: 'nContribuinte'}]},
            {type: 'group', order: 3, fields: [{field: 'morada'}, {field: 'morada2'}]},
            {type: 'group', order: 4, fields: [{field: 'email'}, {field: 'contacto'}]},
            {type: 'group', order: 5, fields: [{field: 'tipoEmpresa'}, {field: 'tipoContabilidade'}]},
            {type: 'group', order: 6, fields: [{field: 'periodoIVA'}, {field: 'regimeIRC'}]}
          ]
        }
      },
      resolve: [
        {
          provide: 'empresasGabinete',
          deps: [GtoService],
          useFactory: (gtoService: GtoService) => gtoService.getEmpresasGabinete().then((response: HttpResponse<Array<IJsonEmpresaErpBasic>>) => response.body)
        }
      ]
    }
  }
};
