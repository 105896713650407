import {Component, Injector, Input, OnInit} from '@angular/core';
import {ERendiTipoDeclaracao} from '../../../rendis/jsonRendi.entity.interface';
import {IJsonRegimesTributacao} from '../../../../modules/portalcontabilidade/modelo30/jsonModelo30.module.interface';
import {IJsonTxRet} from '../../jsonTxRet.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';

@Component({
  selector: 'tx-ret-edit',
  templateUrl: './taxasRetencao.entity.edit.component.html'
})
export class TaxasRetencaoEditComponent extends ModuloEntityDetailComponent<IJsonTxRet> implements OnInit {
  @Input() public regimesTributacao: Array<IJsonRegimesTributacao>;

  public showRegTrib: boolean;
  public regTrib: IJsonRegimesTributacao;
  public filterTiposRendimento: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.showRegTrib = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.regTrib = this.regimesTributacao.find((item: IJsonRegimesTributacao) => item.cod === this.model.codRegTribCod);
    this._evaluateFilterTipoRendimentos();
    this._evaluateShowRegTrib();
  }

  public changedTipoDeclaracao(): void {
    this._evaluateFilterTipoRendimentos();
    this._evaluateShowRegTrib();
    this._clearInputs();
  }

  public changedRegTrib(value: IJsonRegimesTributacao): void {
    this.regTrib = value;
    this.model.codRegTribCod = this.regTrib?.cod;
  }

  private _evaluateFilterTipoRendimentos(): void {
    this.filterTiposRendimento = this.model.tipoDeclaracao ? `tipoDeclaracao=${this.model.tipoDeclaracao}` : undefined;
  }

  private _evaluateShowRegTrib(): void {
    this.showRegTrib = this.model.tipoDeclaracao === ERendiTipoDeclaracao.Modelo30;
  }

  private _clearInputs(): void {
    this.model.tipRendiment = undefined;
    this.model.nomeTipRendiment = undefined;
    this.regTrib = undefined;
    this.model.zona = 0;
    this.model.rubrica = 0;
    this.model = {...this.model};
  }
}
