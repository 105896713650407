<div class="login">
  <div class="account-title">
    <h3 [translate]="'login.header'"></h3>
  </div>

  <form class="account-form account-panel" rel="noopener noreferrer" novalidate [formGroup]="form" [submit]="fnLogin" plPromise>
    <div class="account-panel-body">
      <div class="account-logo">
        <cg-app-logo></cg-app-logo>
      </div>

      <label class="visually-hidden" for="username" [translate]="'global.form.username'"></label>
      <input
        type="text"
        id="username"
        class="form-control account-empty"
        autocapitalize="off"
        autocomplete="off"
        formControlName="username"
        [attr.placeholder]="'&#xF007;&nbsp;' + ('global.form.usernameemail.placeholder' | translate)"
        maxlength="120"
        plAutoFocus />

      <label for="password" class="visually-hidden" [translate]="'login.form.password'"></label>
      <input
        type="password"
        id="password"
        class="form-control account-empty"
        autocapitalize="off"
        autocomplete="off"
        formControlName="password"
        [attr.placeholder]="'&#xF084;&nbsp;' + ('login.form.password.placeholder' | translate)"
        maxlength="50" />

      <p class="pass-recovery">
        <a uiSref="resetpassword" [translate]="'login.form.resetpassword'"></a>
      </p>

      <p *ngIf="recaptchaActive">
        <recaptcha-branding></recaptcha-branding>
      </p>

      <button type="submit" class="btn btn-primary btn-block action-submit"><i class="fa fa-fw fa-sign-in"></i> <span [translate]="'login.form.button'"></span></button>
    </div>
  </form>
</div>
