<div class="pl-media-devices-camera-capture-image-change-modal">
  <div class="modal-header">
    <h5 class="modal-title" [plTranslate]="title"></h5>
    <button type="button" class="btn-close" (click)="dismiss()"></button>
  </div>

  <div class="modal-body">
    <pl-tabs [activeId]="activeTab" (evtSelected)="changedTab($event)">
      <pl-tab [id]="tabs.Upload">
        <div *plTabTitle [plTranslate]="titleTabUpload"></div>

        <div *plTabContent>
          <pl-upload
            [properties]="properties.uploadProperties"
            [autoProcessQueue]="false"
            [maxFiles]="1"
            [callback]="callbackUpload"
            (evtAcceptedFile)="onAcceptedFile($event)"
            (evtUploadedFile)="onFileUpload($event)">
          </pl-upload>
        </div>
      </pl-tab>

      <pl-tab [id]="tabs.Capture" [disabled]="disableTabCapture">
        <div *plTabTitle>
          <pl-tooltip [config]="tooltipTabCapture">
            <div *plTooltipContent [plTranslate]="titleTabCapture"></div>
          </pl-tooltip>
        </div>

        <div *plTabContent>
          <div class="capture-image" [plPromise]="promiseTabCapture" [ngSwitch]="!!errorTabCapture">
            <div *ngSwitchCase="false" class="capture-image-preview">
              <div class="capture-image-preview-video">
                <video #elementVideo class="img-thumbnail"></video>
                <div *ngIf="showOverlay" #elementVideoOverlay class="capture-image-preview-video-resize-overlay"></div>
              </div>
              <div class="capture-image-preview-controls">
                <button type="button" class="btn btn-link capture-image-preview-control control-capture" [plTranslate]="locale.textCapture" (click)="capture()"></button>
                <button *ngIf="!paused" type="button" class="btn btn-link capture-image-preview-control control-pause" [plTranslate]="locale.textPause" (click)="pause()"></button>
                <button *ngIf="paused" type="button" class="btn btn-link capture-image-preview-control control-resume" [plTranslate]="locale.textResume" (click)="resume()"></button>
              </div>
            </div>

            <div *ngSwitchCase="true" class="capture-image-error">
              <div class="capture-image-error-message" [plTranslate]="errorTabCapture"></div>
              <div class="capture-image-error-retry">
                <button type="button" class="btn btn-link" [plTranslate]="locale.textRetry" (click)="initMediaStream()"></button>
              </div>
            </div>
          </div>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-light action-close" (click)="dismiss()"><i class="fa fa-times fa-fw"></i>&nbsp;<span [plTranslate]="localeBtn.close"></span></button>
  </div>
</div>
