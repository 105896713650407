<div class="site-standalone-container">
  <h1 [translate]="'disconnected.title'"></h1>

  <div class="alert alert-danger" plAnimationShake [translate]="'disconnected.message'"></div>

  <div class="site-standalone-container-actions">
    <button type="button" class="btn btn-light" [click]="back" plPromise><i class="fa fa-fw fa-angle-left"></i>&nbsp;<span [translate]="'entity.action.back'"></span></button>

    <a [uiSref]="stateNameLogin" class="btn btn-primary" *ngIf="!partnerMode && !hybridMode"> <i class="fa fa-fw fa-sign-in"></i>&nbsp;<span [translate]="'global.menu.account.login'"></span> </a>
  </div>
</div>
