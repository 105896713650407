import {TDate} from '../../../../../common/dates';

export type TPcaRhGestaoDGEMPSIntegradoItem = IPcaRhGestaoDGEMPSAbonoIntegrado | IPcaRhGestaoDGEMPSFaltaIntegrada | IPcaRhGestaoDGEMPSFeriaIntegrada;

export interface IPcaRhGestaoDGEMPSAbonoIntegrado {
  data: TDate;
  nome: string;
  descricaoTipo: string;
  qtd: number;
}

export interface IPcaRhGestaoDGEMPSFaltaIntegrada {
  data: TDate;
  nome: string;
  motivo: string;
}

export interface IPcaRhGestaoDGEMPSFeriaIntegrada {
  data: TDate;
  nome: string;
}

export const MODULE_NAME_PCA_RH_GESTAO_DGEMPS_ABONOS = 'pcaVerAbonos';
export const MODULE_NAME_PCA_RH_GESTAO_DGEMPS_FALTAS = 'pcaVerFaltas';
export const MODULE_NAME_PCA_RH_GESTAO_DGEMPS_FERIAS = 'pcaVerFerias';
