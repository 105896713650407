import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {FctComponent} from './components/fct.module.component';

export const MODULE_FCT: IModuleDefinition = {
  name: 'fct',
  state: {
    url: '/fct',
    component: FctComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.fct'
    }
  }
};
