import {Component, Injector, Input, OnInit} from '@angular/core';
import {DocsComerciaisModalComponent} from '../docsComerciais.modal.component';
import {EPrecoUnitarioFields, IPrecoUnitarioModalInput, IPrecoUnitarioModalModel} from './docsComerciais.precoUnitario.interface';
import {ConfigService} from '../../../../services/config/config.service';
import {round} from '../../../../../common/utils/utils';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../docfas/docFas.entity.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {focusElement} from '../../../../../common/utils/element.utils';

@Component({
  selector: 'docs-comerciais-preco-unitario-modal',
  templateUrl: './docsComerciais.precoUnitario.modal.component.html'
})
export class DocsComerciaisPrecoUnitarioModalComponent extends DocsComerciaisModalComponent implements OnInit {
  @Input() public modalInput: IPrecoUnitarioModalInput;

  public model: IPrecoUnitarioModalModel;
  public decimals: number;
  public promise: Promise<void>;

  protected readonly _configService: ConfigService;
  private readonly _docFaService: IDocFasEntityService;
  private _precoComIvaIncluido: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this._docFaService = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
    this._configService = this._injector.get<ConfigService>(ConfigService);
    this._precoComIvaIncluido = false;
    this.model = {
      prUnitarioComIvaQtdOne: 0,
      prUnitarioSemIvaQtdOne: 0,
      prUnitarioComIvaQtdLine: 0,
      prUnitarioSemIvaQtdLine: 0
    };
  }

  public ngOnInit(): void {
    this.decimals = this._configService.configurations.gestaoComercial.decimais.valoresUnitarios;
    this.promise = this._docFaService.get({id: this.modalInput.cab.nDocFa}).then((response) => {
      this._precoComIvaIncluido = response.body.faturaComIvaIncluido;
      const elementAttrName = this._precoComIvaIncluido ? 'prUnitarioSemIvaQtdOne' : 'prUnitarioComIvaQtdOne';
      if (this._precoComIvaIncluido) {
        this.model = {
          prUnitarioComIvaQtdOne: this.modalInput.linha.prVenda1,
          prUnitarioSemIvaQtdOne: round(this.modalInput.linha.prVenda1 / (1 + this.modalInput.linha.taxaIva / 100), this.decimals),
          prUnitarioComIvaQtdLine: this.modalInput.linha.liquido,
          prUnitarioSemIvaQtdLine: round(this.modalInput.linha.liquido / (1 + this.modalInput.linha.taxaIva / 100), this.decimals)
        };
      } else {
        this.model = {
          prUnitarioComIvaQtdOne: round(this.modalInput.linha.prVenda1 * (1 + this.modalInput.linha.taxaIva / 100), this.decimals),
          prUnitarioSemIvaQtdOne: this.modalInput.linha.prVenda1,
          prUnitarioComIvaQtdLine: round(this.modalInput.linha.liquido * (1 + this.modalInput.linha.taxaIva / 100), this.decimals),
          prUnitarioSemIvaQtdLine: this.modalInput.linha.liquido
        };
      }
      setTimeout(() => {
        focusElement(this._element.querySelector<HTMLInputElement>(`docs-comerciais-preco-unitario-modal input[name="${elementAttrName}"]`));
      });
    });
  }

  public close(): void {
    this.modalInput.linha.prVenda1 = this._precoComIvaIncluido ? this.model.prUnitarioComIvaQtdOne : this.model.prUnitarioSemIvaQtdOne;
    super.close();
  }

  public prUnitarioSemIvaQtdOneChanged(value: number): void {
    this.model.prUnitarioSemIvaQtdOne = value ?? 0;
    this._calcula(EPrecoUnitarioFields.PRUNITARIOSEMIVAQTDONE);
  }

  public prUnitarioComIvaQtdOneChanged(value: number): void {
    this.model.prUnitarioComIvaQtdOne = value ?? 0;
    this._calcula(EPrecoUnitarioFields.PRUNITARIOCOMIVAQTDONE);
  }

  public prUnitarioSemIvaQtdLineChanged(value: number): void {
    this.model.prUnitarioSemIvaQtdLine = value ?? 0;
    this._calcula(EPrecoUnitarioFields.PRUNITARIOSEMIVAQTDLINE);
  }

  public prUnitarioComIvaQtdLineChanged(value: number): void {
    this.model.prUnitarioComIvaQtdLine = value ?? 0;
    this._calcula(EPrecoUnitarioFields.PRUNITARIOCOMIVAQTDLINE);
  }

  private _calcula(currentField: EPrecoUnitarioFields): void {
    switch (currentField) {
      case EPrecoUnitarioFields.PRUNITARIOSEMIVAQTDONE: {
        this.model.prUnitarioComIvaQtdOne = round(this.model.prUnitarioSemIvaQtdOne * (1 + this.modalInput.linha.taxaIva / 100), this.decimals);
        this.model.prUnitarioSemIvaQtdLine = this._calcLiquido(this.model.prUnitarioSemIvaQtdOne);
        this.model.prUnitarioComIvaQtdLine = this._calcLiquido(this.model.prUnitarioComIvaQtdOne);
        break;
      }
      case EPrecoUnitarioFields.PRUNITARIOCOMIVAQTDONE: {
        this.model.prUnitarioSemIvaQtdOne = round(this.model.prUnitarioComIvaQtdOne / (1 + this.modalInput.linha.taxaIva / 100), this.decimals);
        this.model.prUnitarioSemIvaQtdLine = this._calcLiquido(this.model.prUnitarioSemIvaQtdOne);
        this.model.prUnitarioComIvaQtdLine = this._calcLiquido(this.model.prUnitarioComIvaQtdOne);
        break;
      }
      case EPrecoUnitarioFields.PRUNITARIOSEMIVAQTDLINE: {
        this.model.prUnitarioSemIvaQtdOne = this._calPrVUnitario(this.model.prUnitarioSemIvaQtdLine);
        this.model.prUnitarioComIvaQtdOne = round(this.model.prUnitarioSemIvaQtdOne * (1 + this.modalInput.linha.taxaIva / 100), this.decimals);
        this.model.prUnitarioComIvaQtdLine = round(this.model.prUnitarioSemIvaQtdLine * (1 + this.modalInput.linha.taxaIva / 100), this.decimals);
        break;
      }
      case EPrecoUnitarioFields.PRUNITARIOCOMIVAQTDLINE: {
        this.model.prUnitarioComIvaQtdOne = this._calPrVUnitario(this.model.prUnitarioComIvaQtdLine);
        this.model.prUnitarioSemIvaQtdOne = round(this.model.prUnitarioComIvaQtdOne / (1 + this.modalInput.linha.taxaIva / 100), this.decimals);
        this.model.prUnitarioSemIvaQtdLine = round(this.model.prUnitarioComIvaQtdLine / (1 + this.modalInput.linha.taxaIva / 100), this.decimals);
        break;
      }
    }
  }

  private _calPrVUnitario(liquido: number): number {
    return round(liquido / (this.modalInput.linha.qtdPorUnidade1 * this.modalInput.linha.qtd1), this.decimals);
  }

  private _calcLiquido(pvUn: number): number {
    return round(pvUn * this.modalInput.linha.qtdPorUnidade1 * this.modalInput.linha.qtd1, this.decimals);
  }
}
