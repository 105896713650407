import {IJsonDocContabilidade} from '../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IJsonContabDigitalAssociaDocSemelhante, IJsonContabDigitalSemDocs, IJsonDocDigitalizado} from '../../../services/contabilidadedigital/jsonContabDigital.interface';
import {TDate} from '../../../../common/dates';
import {IDocContabilidadeCallback} from '../../portalcontabilidade/docscontabilidade/components/doccontabilidade/docContabilidade.interface';
import {IDocComercialCallback} from '../../../entities/docscomerciais/components/component/docsComerciais.component.interface';
import {IDocComercial} from '../../../entities/docscomerciais/docsComerciais.entity.interface';

export enum EArquivoDigitalGestaoDocsDigitalizadosTypeAction {
  AdicionarDoc,
  MudarDocPasta,
  ReaplicarOCR,
  ObterDadosEFatura,
  AtribuirTipoDocumento,
  UnirDocs,
  SepararDoc,
  CopiarDoc,
  RemovePages,
  Apagar,
  VerDoc,
  NovaContaCC,
  ExportarArquivo,
  PreDefinidos,
  DocsContabSemContabDig,
  Robo,
  ExtratoConta,
  AssociarDocsAuto,
  PossiveisDocsRepetidos,
  AssociarDocsAutoInclAnulados
}

export enum EContabDigitalGestaoDocsDigitalizadosExportState {
  Inactive = 'cdjsInactive',
  Scheduled = 'cdjsScheduled',
  Started = 'cdjsStarted',
  Ended = 'cdjsEnded',
  Timeout = 'cdjsTimeout',
  Error = 'cdjsError'
}

export interface IDocDigitalizado extends IJsonDocDigitalizado {
  _changed?: boolean;
  _legendNif?: string;
  _legendTemNifEmp?: string;
  _legendFiscalmenteRelevante?: string;
  _legendInvoiceType?: string;
  _legendDataDoc?: string;
  _legendNDocumento?: string;
  _legendTotalBase?: string;
  _legendTotalIva?: string;
  _legendTotal?: string;
  _legendWaitingOCR?: string;
  _classified?: boolean;
  _docContabilidade?: IJsonDocContabilidade;
  _docContabilidadeToolbarInstanceId?: string;
  _docContabilidadeCallback?: IDocContabilidadeCallback;
  _detailExpanded?: boolean;
  _docsSemelhantes?: Array<IContabDigitalAssociaDocSemelhante>;
  _docComercial?: IDocComercial;
  _docComercialToolbarInstanceId?: string;
  _docComercialCallback?: IDocComercialCallback;
}

export interface IDocsDigitalizadosHeader {
  nifDe: string;
  nifAte: string;
  docExternoDe: string;
  docExternoAte: string;
  dataDocExternoDe: TDate;
  dataDocExternoAte: TDate;
  dataRececaoDe: TDate;
  dataRececaoAte: TDate;
  mostrarDocClassificados: boolean;
  mostraApenasLancRobot: boolean;
}

export interface IContabDigitalGestaoDocsDigitalizadosCallback {
  save?(): Promise<void>;

  hasLineChanges?(): boolean;
}

export interface IContabDigitalGestaoDocsDigitalizadosExportFilters {
  ano: number;
  periodoDe: string;
  periodoAte: string;
  diarioDe: number;
  diarioAte: number;
  nifDe: string;
  nifAte: string;
  folderId: string;
  exportaNaoClassificados: boolean;
  exportaApenasRelevantes: boolean;
}

export interface IContabDigitalGestaoDocsDigitalizadosExportZipControl {
  currentState?: IContabDigitalGestaoDocsDigitalizadosExportStatus;
  isReady: boolean;
  fileName: string;
  showProcess: boolean;
}

export interface IContabDigitalGestaoDocsDigitalizadosExportStatus {
  requestDate: TDate;
  userStarted: string;
  userStartedId: number;
  state: EContabDigitalGestaoDocsDigitalizadosExportState;
  description: string;
  plannedStartDate: TDate;
  filename: string;
  isReady: boolean;
  ano: number;
  periodoDe: string;
  periodoAte: string;
}

export interface IContabDigitalSemDocs extends IJsonContabDigitalSemDocs {
  _docContabilidade?: IJsonDocContabilidade;
}

export interface IContabDigitalAssociaDocSemelhante extends IJsonContabDigitalAssociaDocSemelhante {
  _docContabilidade?: IJsonDocContabilidade;
}

export interface IAssociarDocsAutoDropDownOption {
  typeAction: EArquivoDigitalGestaoDocsDigitalizadosTypeAction;
  caption: string;
}

export interface IContabDigitalPreDefinidoConfig {
  docs: Array<IDocDigitalizado>;
  docsPreDefinidoReload: Array<IJsonDocDigitalizado>;
}

export const MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS = 'documentosdigitais';

export const MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS_CONCILIACAO = 'documentosdigitaisConciliacao';

export const MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS_CLIENT_CONNECT = 'documentosDigitaisClientConnect';
