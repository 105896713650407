import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {copy} from '../../../../common/utilities/utilities';
import type {IPlDynamicVisualsSecondaryClickReferenceOptions} from '../dynamicvisuals.secondaryclick.interface';

@Injectable({
  providedIn: 'root'
})
export class PlDynamicVisualsSecondaryClickConfigService implements OnDestroy {
  private readonly _subjectConfig: BehaviorSubject<Required<IPlDynamicVisualsSecondaryClickReferenceOptions>>;
  private _properties: Required<IPlDynamicVisualsSecondaryClickReferenceOptions>;
  private _observableConfig: Observable<IPlDynamicVisualsSecondaryClickReferenceOptions>;

  constructor() {
    this._properties = {
      container: 'body',
      injector: undefined,
      beforeDismiss: undefined,
      autoClose: true,
      keyboard: true,
      placement: undefined,
      cssClass: undefined,
      persistent: false
    };
    this._subjectConfig = new BehaviorSubject<Required<IPlDynamicVisualsSecondaryClickReferenceOptions>>(this._clone());
  }

  public ngOnDestroy(): void {
    this._subjectConfig.complete();
  }

  public get(): Observable<IPlDynamicVisualsSecondaryClickReferenceOptions> {
    if (!this._observableConfig) {
      this._observableConfig = this._subjectConfig.asObservable();
    }
    return this._observableConfig;
  }

  public set(config: Partial<IPlDynamicVisualsSecondaryClickReferenceOptions>): void {
    this._properties = {...this._properties, ...config};
    this._subjectConfig.next(this._clone());
  }

  private _clone(): Required<IPlDynamicVisualsSecondaryClickReferenceOptions> {
    return copy(this._properties);
  }
}
