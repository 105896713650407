import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {AdiantamentosService} from '../../adiantamentos.module.service';
import {IHeaderAdiantamentos} from '../../adiantamentos.module.interface';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {EReport} from '../../../../entities/reports/reports.interface';

@Component({
  selector: 'modal-adiantamentos-print',
  templateUrl: './adiantamentos.print.modal.component.html'
})
export class AdiantamentosPrintModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public headerAdiantamentos: IHeaderAdiantamentos;
  @Input() public porRegularizar: boolean;
  @Input() public modoRegularizacao: boolean;

  public report: IJsonReport;
  public pdfUrl: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _adiantamentosService: AdiantamentosService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this._loadDefaultReport();
  }

  public previsualizar(): void {
    this._adiantamentosService.printAdiantamentosUrl(this.headerAdiantamentos, this.porRegularizar, this.modoRegularizacao, this.report.name).subscribe((url: string) => {
      this.pdfUrl = undefined;
      setTimeout(() => {
        this.pdfUrl = url;
      });
    });
  }

  private _loadDefaultReport(): void {
    this._reportsRegistryService
      .get(EReport.Adiantamentos)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.report = reports[0];
        }
      })
      .then(() => {
        this.previsualizar();
      });
  }
}
