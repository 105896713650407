import {Component, Injector, OnInit} from '@angular/core';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {DocfasNumsService} from '../../../docfasNums.entity.service';
import {IDevExpressDataGridEventOnCellPrepared, IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonDocfanumPorComunicarAT, IJsonDocfanumPorComunicarATSel} from '../../../jsonDocfaNum.entity.interface';
import {PlAlertService} from 'pl-comps-angular';
import {CGExceptionService} from '../../../../../components/exceptions/exceptions.service';
import {HttpErrorResponse} from '@angular/common/http';
import {DocFasNumsComunicacaoLoginModalComponent} from '../../../../../modules/docfasnumscomunicacaologin/docFasNumsComunicacaoLogin.modal.component';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {AT_WEB_SERVICE_CLASS, ERROR_AUTH_SUBSTRING} from '../../../../../services/docfasNumsComunicacaoLogin/docfasNumsComunicacaoLogin.service.interface';

@Component({
  selector: 'seriesPorComunicarAt-modal',
  templateUrl: './seriesPorComunicarAt.modal.component.html'
})
export class SeriesPorComunicarATModalComponent extends CGModalComponent<void> implements OnInit {
  public onClickAction: boolean;
  public seriesPorComunicar: Array<IJsonDocfanumPorComunicarATSel>;
  public selectedRowKeys: Array<number>;
  public readonly dataGridDefinition: IDevExpressDataGrid;
  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _docfasNumsService: DocfasNumsService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);

    this.seriesPorComunicar = [];
    this.selectedRowKeys = [];

    this.dataGridDefinition = {
      keyExpr: 'idDocfaNum',
      columns: [
        {dataField: 'nDocfa', dataType: 'number', caption: 'docfasNums.modal.seriesPorComunicarAT.fields.nDocfa', allowEditing: false},
        {dataField: 'nNumer', dataType: 'number', caption: 'docfasNums.modal.seriesPorComunicarAT.fields.nNumer', allowEditing: false},
        {dataField: 'nome', dataType: 'string', caption: 'docfasNums.modal.seriesPorComunicarAT.fields.nome', allowEditing: false},
        {dataField: 'tipoDocSaft', dataType: 'string', caption: 'docfasNums.modal.seriesPorComunicarAT.fields.tipoDocSaft', allowEditing: false},
        {dataField: 'numAtual', dataType: 'number', caption: 'docfasNums.modal.seriesPorComunicarAT.fields.numAtual', allowEditing: false},
        {
          dataField: 'numInicial',
          dataType: 'number',
          caption: 'docfasNums.modal.seriesPorComunicarAT.fields.numInicial',
          allowSorting: false,
          allowSearch: false,
          allowHeaderFiltering: false,
          cellTemplate: 'cellTemplateComuncicacoesSerieAtNumInicial',
          allowEditing: true
        },
        {
          dataField: 'dataInicio',
          dataType: 'date',
          caption: 'docfasNums.modal.seriesPorComunicarAT.fields.dataInicio',
          allowSorting: false,
          allowSearch: false,
          allowHeaderFiltering: false,
          cellTemplate: 'cellTemplateComuncicacoesSerieAtDataInicio',
          allowEditing: true
        }
      ],
      editing: {mode: 'cell', startEditAction: 'click', selectTextOnEditStart: true, allowUpdating: true, refreshMode: 'repaint'},
      dataSource: [],
      remoteOperations: false,
      pager: {
        displayMode: 'full'
      },
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'}
    };
  }

  public ngOnInit(): void {
    this._docfasNumsService.getSeriesPorComunicarAT().then((result) => {
      this.dataGridDefinition.dataSource = result.body.list;
      this.seriesPorComunicar = result.body.list;
    });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    const rows: Array<IJsonDocfanumPorComunicarATSel> = this._dataGridInstance.getDataSource().items();
    const rowsToSelect: Array<number> = rows
      .filter((docfanumPorComunicar: IJsonDocfanumPorComunicarATSel) => docfanumPorComunicar.numAtual !== 0)
      .map((docfanumPorComunicar: IJsonDocfanumPorComunicarATSel) => docfanumPorComunicar.idDocfaNum);
    this._dataGridInstance.selectRows(rowsToSelect, true);
    this._dataGridInstance.endCustomLoading();
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonDocfanumPorComunicarAT>): void {
    if (event.rowType === 'data') {
      if (event.column.dataField === 'numInicial' || event.column.dataField === 'dataInicio') {
        event.cellElement.classList.add('datagrid-editable-cell');
      }
    }
  }

  public onSelectedRowKeysChange(): void {
    for (const serie of this.seriesPorComunicar) {
      serie.selecionado = false;
    }

    for (const selectedKey of this.selectedRowKeys) {
      const index: number = this.seriesPorComunicar.findIndex((item: IJsonDocfanumPorComunicarATSel) => item.idDocfaNum === selectedKey);
      if (index !== -1) {
        this.seriesPorComunicar[index].selecionado = true;
      }
    }
  }

  public readonly fnComunicarSeriesAT = (): Promise<void> => this._comunicarSeriesAT();

  private _comunicarSeriesAT(): Promise<void> {
    const seriesPorComunicarSel: Array<IJsonDocfanumPorComunicarAT> = [];
    for (const serie of this.seriesPorComunicar) {
      if (serie.selecionado) {
        seriesPorComunicarSel.push(serie);
      }
    }

    return this._docfasNumsService
      .comunicarSeriesAT(seriesPorComunicarSel)
      .then((result) => {
        if (result) {
          for (let i = 0; i < this.seriesPorComunicar.length; i++) {
            if (this.seriesPorComunicar[i].selecionado) {
              const idx = result.body.findIndex((item: IJsonDocfanumPorComunicarAT) => item.idDocfaNum === this.seriesPorComunicar[i].idDocfaNum);
              if (idx > -1 && result.body[idx].codValidacaoSerie !== '') {
                this.seriesPorComunicar.splice(i, 1);
              }
            }
          }
          this._dataGridInstance.refresh();
        }
      })
      .catch((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          const exception = this._cgExceptionService.get(error);
          if (exception?.message) {
            if (exception.class === AT_WEB_SERVICE_CLASS && exception.message.includes(ERROR_AUTH_SUBSTRING)) {
              this._cgModalService.showVanilla(DocFasNumsComunicacaoLoginModalComponent, {size: 'sm'}).result.then(() => {
                return this._comunicarSeriesAT();
              });
            } else {
              this._plAlertService.error(exception.message);
            }
          }
        }
      });
  }
}
