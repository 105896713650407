export enum ETipoCategoria {
  NA,
  VENCIMENTO,
  DIUTURNIDADE,
  PREMIOS,
  COMISSOES,
  SUB_REFEICAO,
  AJUDA_CUSTO,
  HORA_EXTRA,
  FERIAS,
  SUB_FERIAS,
  FERIAS_PAGAS,
  SUB_NATAL,
  TRAB_NOCTURNO,
  ISENCAOHORARIO,
  SUB_TURNO,
  FALTAS_PAGAS,
  INDEMNIZACAO_CADUCIDADE,
  INDEMNIZACAO_DESPEDIMENTO,
  INDEMNIZACAO_GLOBAL,
  DESLOCACAO,
  REM_PROF_LIVRE,
  IVA,
  Virtual,
  SuplementoRemuneratorio,
  TicketValesRefeicao,
  PagamentosEmEspecie,
  ValesEducacao,
  UtlViaturaPatronal,
  GratificacoesBombeiros,
  CartaoOferta,
  ApoioRegimeLayoff = 35,
  DespesasTeletrabalho = 37,
  UtilizHabitaPropriaPermanente,
  GratificacaoBalancoArt236
}

export const DATA_SOURCE_NAME_TIPOS_CATEGORIA = 'tiposCategoria';
