import {Component, Injector, OnInit} from '@angular/core';
import {IPlFormDefinition, IPlFormTemplate, isArray} from 'pl-comps-angular';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonCRSCL, IJsonCRSSDetailed} from '../../jsonCRSS.entity.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {ENTITY_NAME_CLASSES_CAIXA} from '../../../classescaixa/classesCaixa.entity.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import ArrayStore from 'devextreme/data/array_store';
import {IJsonClasseCaixa} from '../../../classescaixa/jsonClasseCaixa.entity.interface';

@Component({
  selector: 'crss-edit',
  templateUrl: './crss.entity.edit.component.html'
})
export class CRSSEditComponent extends ModuloEntityDetailComponent<IJsonCRSSDetailed> implements OnInit {
  public readonly definition: IDevExpressDataGrid;

  public formDefinition: IPlFormDefinition;
  public formTemplate: IPlFormTemplate;

  private readonly _maintenanceInstanceCLCX: IEntityMaintenanceInstance<IJsonClasseCaixa>;

  private _dataGridInstance: dxDataGrid<IJsonCRSCL, string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityMaintenanceService: EntityMaintenanceService
  ) {
    super(_injector);
    this._maintenanceInstanceCLCX = this._entityMaintenanceService.build<IJsonClasseCaixa>(ENTITY_NAME_CLASSES_CAIXA);
    this.definition = {
      columns: [
        {dataField: 'nclcx', dataType: 'string', caption: 'crss.table.nclcx'},
        {dataField: 'descricao', dataType: 'string', caption: 'crss.table.nomeregime'},
        {dataField: 'percentPatr', dataType: 'number', caption: 'crss.table.percentPatr'},
        {dataField: 'percentEmpr', dataType: 'number', caption: 'crss.table.percentEmpr'},
        {dataField: 'codIntegracao', dataType: 'string', caption: 'crss.table.codIntegracao'},
        {dataField: 'naoVaiSegSocial', dataType: 'string', caption: 'crss.table.naoVaiSegSocial', customizeText: this.customizeLabelCRSSFn},
        {
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false,
          width: 150
        }
      ],
      dataSource: [],
      searchPanel: {visible: true},
      grouping: {contextMenuEnabled: false},
      columnFixing: {enabled: false},
      export: {enabled: false},
      columnChooser: {enabled: false},
      hoverStateEnabled: true
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.formTemplate = <IPlFormTemplate>this.states.detail.data.formTemplate;
    this.formDefinition = this.entity[this.type].definition;
    if (!this.model) {
      this.model = {
        ncrss: 0,
        nome: '',
        designaBreve: '',
        morada: '',
        localidade: '',
        npostal: '',
        telefone: '',
        fax: '',
        nbenefCaixa: '',
        crssSedeSocial: false,
        idcrss: 0,
        nifSegSocial: '',
        nifCGA: '',
        nifADSE: '',
        crscl: []
      };
    }
    if (!isArray(this.model.crscl)) {
      this.model.crscl = [];
    }
    this.setModuleActions([
      {
        caption: this._translateService.instant('entity.maintenance.headerMaintenance', {
          entityName: this._translateService.instant(`${ENTITY_NAME_CLASSES_CAIXA}.title_plural`).toLowerCase()
        }),
        action: () => this._maintenanceInstanceCLCX.maintenanceSelectAndEdit().then(() => undefined)
      }
    ]);
    this.definition.dataSource = new ArrayStore({
      key: 'nclcx',
      data: this.model.crscl
    });
  }

  public readonly customizeLabelCRSSFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string => this._customizeTextBoolean(cellInfo);

  public onDataGridInitializedCRSS(event: IDevExpressDataGridEventOnInitialized<IJsonCRSCL, string>): void {
    this._dataGridInstance = event.component;
  }

  public editarlinha(item: IJsonCRSCL): Promise<void> {
    return this._maintenanceInstanceCLCX.maintenanceEdit(item.nclcx).then((classeCaixa: IJsonClasseCaixa) => {
      const crscl: IJsonCRSCL = {
        ...item,
        percentPatr: classeCaixa.percentPATR,
        percentEmpr: classeCaixa.percentEMPR,
        codIntegracao: classeCaixa.codIntegracao,
        naoVaiSegSocial: classeCaixa.naoVaiSegSocial ? '1' : '0',
        temLimiteMaximo: classeCaixa.temLimiteMaximo,
        limMaxNSalariosMin: classeCaixa.limMaxNSalariosMin,
        temLimiteMinimo: classeCaixa.temLimiteMinimo,
        limMinNSalariosMin: classeCaixa.limMinNSalariosMin,
        descricao: classeCaixa.descricao
      };
      this._dataGridInstance
        .getDataSource()
        .store()
        .update(item.nclcx, crscl)
        .then(() => {
          this._dataGridInstance.getDataSource().reload();
        });
    });
  }

  private _customizeTextBoolean(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.value === '1') {
      return this._translateService.instant('global.btn.no');
    }
    return this._translateService.instant('global.btn.yes');
  }
}
