import {ENTITY_NAME_REP_FINANCAS, RADIO_GROUP_TIPOFINANCAS} from './repFinancas.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonReparticaoFinancas} from './jsonReparticaoFinancas.entity.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_DISTRITOS} from '../distritos/distritos.entity.interface';
import {ENTITY_NAME_PAISES} from '../paises/paises.entity.interface';
import {ENTITY_NAME_QPDCFSERVICOSFINANCAS} from '../qpdcf/qpdcf.entity.interface';
import {RepFinancasEditComponent} from './components/edit/repFinancas.entity.edit.component';

export const ENTITY_REP_FINANCAS: IEntityDefinition<IJsonReparticaoFinancas> = {
  name: ENTITY_NAME_REP_FINANCAS,
  roles: [ROLE.CONTABILIDADE, ROLE.RH],
  searchPlaceholder: 'repfinancas.pesquisa',
  metadata: {
    keyName: 'codFiscal',
    fields: [
      {name: 'codFiscal', width: '120px', caption: 'repfinancas.fields.codFiscal', validators: {required: true}},
      {name: 'nome', caption: 'repfinancas.fields.nome', validators: {required: true}},
      {
        name: 'tipo',
        type: 'radiogroup',
        caption: 'repfinancas.fields.tipo',
        validators: {required: true},
        properties: {
          groupItems: RADIO_GROUP_TIPOFINANCAS,
          devExpress: {
            dataGrid: {
              lookup: {
                dataSource: RADIO_GROUP_TIPOFINANCAS,
                valueExpr: 'value',
                displayExpr: 'label'
              },
              allowSearch: false,
              allowFiltering: false
            }
          }
        }
      },
      {
        name: 'morada',
        caption: 'repfinancas.fields.morada',
        properties: {
          devExpress: {
            dataGrid: {
              allowSearch: false,
              allowFiltering: false
            }
          }
        }
      },
      {
        name: 'codPostal',
        caption: 'repfinancas.fields.codPostal',
        entity: {name: 'codpostais', keyTarget: 'codPostal'},
        properties: {
          devExpress: {
            dataGrid: {
              allowSearch: false,
              allowFiltering: false
            }
          }
        }
      },
      {
        name: 'codDistrito',
        visible: false,
        properties: {
          devExpress: {
            dataGrid: {
              allowSearch: false,
              allowFiltering: false
            }
          }
        }
      },
      {
        name: 'nomeDistrito',
        caption: 'repfinancas.fields.nomeDistrito',
        entity: {name: ENTITY_NAME_DISTRITOS, keyTarget: 'codDistrito', outputTarget: 'nomeDistrito'},
        properties: {
          devExpress: {
            dataGrid: {
              allowSearch: false,
              allowFiltering: false
            }
          }
        }
      },
      {
        name: 'codPais',
        visible: false,
        properties: {
          devExpress: {
            dataGrid: {
              allowSearch: false,
              allowFiltering: false
            }
          }
        }
      },
      {
        name: 'nomePais',
        caption: 'repfinancas.fields.nomePais',
        entity: {name: ENTITY_NAME_PAISES, keyTarget: 'codPais', outputTarget: 'nomePais'},
        properties: {
          devExpress: {
            dataGrid: {
              allowSearch: false,
              allowFiltering: false
            }
          }
        }
      },
      {
        name: 'telefone',
        caption: 'repfinancas.fields.telefone',
        properties: {
          devExpress: {
            dataGrid: {
              allowSearch: false,
              allowFiltering: false
            }
          }
        }
      },
      {
        name: 'fax',
        caption: 'repfinancas.fields.fax',
        properties: {
          devExpress: {
            dataGrid: {
              allowSearch: false,
              allowFiltering: false
            }
          }
        }
      },
      {
        name: 'codFinancasAT',
        caption: 'repfinancas.fields.codFinancasAT',
        entity: {name: ENTITY_NAME_QPDCFSERVICOSFINANCAS, keyTarget: 'codFinancasAT', fieldsMap: {codServicoFinancas: 'codFinancasAT'}},
        properties: {
          devExpress: {
            dataGrid: {
              allowSearch: false,
              allowFiltering: false
            }
          }
        }
      }
    ],
    order: 'codFiscal',
    searchFields: 'codFiscal,nome'
  },
  autocomplete: {
    rowTemplate: '{{codFiscal}} - {{nome}}',
    output: 'nome',
    searchFields: 'codFiscal,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.CONTABILIDADE],
        icon: '',
        pageTitle: 'global.menu.repfinancas'
      }
    }
  },
  detail: {
    state: {
      data: {
        roles: [ROLE.CONTABILIDADE],
        icon: '',
        pageTitle: 'global.menu.repfinancas'
      },
      component: RepFinancasEditComponent
    }
  }
};
