import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {
  IJsonAtivosConsistencia,
  IJsonAtivosConsistenciaAjustes,
  IJsonAtivosConsistenciaAnos,
  IJsonAtivosConsistenciaContab,
  IJsonAtivosConsistenciaIgnorados,
  IJsonAtivosConsistenciaPeriodo
} from './jsonAtivosConsistencia.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AtivosConsistenciaService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/ativosconsistencia`;
  }

  public getPesquisaConsistencias(ano: number, mes: number): TServiceResponse<IJsonAtivosConsistencia> {
    return this._apiService.get<IJsonAtivosConsistencia>({
      url: `${this._path}/pesquisa`,
      params: {
        ano: ano,
        mes: mes
      }
    });
  }

  public getCarregaAnos(): TServiceResponse<Array<IJsonAtivosConsistenciaAnos>> {
    return this._apiService.get<Array<IJsonAtivosConsistenciaAnos>>({
      url: `${this._path}/carregaanos`
    });
  }

  public getCarregaPeriodoDefault(): TServiceResponse<IJsonAtivosConsistenciaPeriodo> {
    return this._apiService.get<IJsonAtivosConsistenciaPeriodo>({
      url: `${this._path}/carregaperiodo`
    });
  }

  public getLancaAjustes(ano: number, mes: number, periodo: string): TServiceResponse<IJsonAtivosConsistenciaAjustes> {
    return this._apiService.get<IJsonAtivosConsistenciaAjustes>({
      url: `${this._path}/lancaajustes`,
      params: {
        ano: ano,
        mes: mes,
        periodo: periodo
      }
    });
  }

  public postApagaIgnorados(ano: number, mes: number, listaignorados: Array<IJsonAtivosConsistenciaIgnorados>): TServiceResponse<IJsonAtivosConsistencia> {
    return this._apiService.post<IJsonAtivosConsistencia, Array<IJsonAtivosConsistenciaIgnorados>>({
      url: `${this._path}/apagaignorados`,
      body: listaignorados,
      params: {
        ano: ano,
        mes: mes
      }
    });
  }

  public postIgnoraDocumentosSemRelacao(ano: number, mes: number, listacontab: Array<IJsonAtivosConsistenciaContab>): TServiceResponse<IJsonAtivosConsistencia> {
    return this._apiService.post<IJsonAtivosConsistencia, Array<IJsonAtivosConsistenciaContab>>({
      url: `${this._path}/ignoradocumentos`,
      body: listacontab,
      params: {
        ano: ano,
        mes: mes
      }
    });
  }
}
