import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonDate} from '../../../common/interfaces/json';
import {IJsonTirs, IJsonTIRSDT} from './jsonTirs.entity.interface';
import {TDate} from '../../../common/dates';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface ITIRSEntityService extends IEntityService<IJsonTirs> {
  distinctDatasEscaloes(nTabIrs: string): TServiceResponse<Array<IJsonDate>>;

  tableIRSEscaloesByDate(nTabIrs: string, dtulTabela: TDate): TServiceResponse<Array<IJsonTIRSDT>>;
}

export interface ITIRSEntity extends IEntityDefinition<IJsonTirs, ITIRSEntityService> {}

export interface ITIRSDT extends IJsonTIRSDT {
  _index?: number;
}

export interface IDataEscalao extends IJsonDate {
  label?: string;
}

export const ENTITY_NAME_TIRS = 'tirs';
