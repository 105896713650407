import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonRHProcSegSocialConsultEnvios, IJsonRHProcSSConsultEnvComSemErro, IJsonString} from './jsonRHProcCegCocialConsultEnvios.module.interface';
import {Observable} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {IRHProceSegSocialFicheiroEdicao, IRHProceSegSocialFicheiroEdicaoSave} from '../../colaboradores/procesegsocial/proceSegSocial.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class RhProcSegSocialConsultEnviosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/rhprocsegsocialconsultenvios`;
  }

  public getSSDecRemunList(year: number, month: number): TServiceResponse<Array<IJsonRHProcSegSocialConsultEnvios>> {
    return this._apiService.get<Array<IJsonRHProcSegSocialConsultEnvios>>({url: `${this._path}/${year}/${month}`});
  }

  public postProcFicheiroMsg(procsegsoc: IJsonRHProcSegSocialConsultEnvios): TServiceResponse<Array<IJsonString>> {
    return this._apiService.post<Array<IJsonString>, IJsonRHProcSegSocialConsultEnvios>({
      url: `${this._path}/procficheiro`,
      body: procsegsoc
    });
  }

  public getComprovativo(ssDecREmunID: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/comprovativo`, {ssdecremunid: ssDecREmunID});
  }

  public getPdfUrl(reportname: string, nibadebitar: string, ssdecremunids: Array<string>): TServiceResponse<Blob> {
    return this._apiService.post<Blob, Array<string>>({
      url: `${this._path}/report/pdf`,
      params: {
        reportname: reportname,
        nibadebitar: nibadebitar
      },
      responseType: 'blob',
      body: ssdecremunids
    });
  }

  public postGerarFicheiroDRI(procSSConsultEnv: Array<IJsonRHProcSegSocialConsultEnvios>): TServiceResponse<Blob> {
    return this._apiService.post<Blob, Array<IJsonRHProcSegSocialConsultEnvios>>({
      url: `${this._path}/gerarficheirodri`,
      responseType: 'blob',
      body: procSSConsultEnv
    });
  }

  public postReprocessarSegSocial(procSSConsultEnv: Array<IJsonRHProcSegSocialConsultEnvios>): TServiceResponse<IJsonRHProcSSConsultEnvComSemErro> {
    return this._apiService.post<IJsonRHProcSSConsultEnvComSemErro, Array<IJsonRHProcSegSocialConsultEnvios>>({
      url: `${this._path}/reprocessarsegsocial`,
      body: procSSConsultEnv
    });
  }

  public postVerificarEnvios(procSSConsultEnv: Array<IJsonRHProcSegSocialConsultEnvios>): TServiceResponse<IJsonRHProcSSConsultEnvComSemErro> {
    return this._apiService.post<IJsonRHProcSSConsultEnvComSemErro, Array<IJsonRHProcSegSocialConsultEnvios>>({
      url: `${this._path}/verificarenvios`,
      body: procSSConsultEnv
    });
  }

  public postEnviarFicheiroWebService(procSSConsultEnv: Array<IJsonRHProcSegSocialConsultEnvios>): TServiceResponse<IJsonRHProcSSConsultEnvComSemErro> {
    return this._apiService.post<IJsonRHProcSSConsultEnvComSemErro, Array<IJsonRHProcSegSocialConsultEnvios>>({
      url: `${this._path}/enviarficheirowebservice`,
      body: procSSConsultEnv
    });
  }

  public getViewInfoSS(nempresa: string, ano: number, mes: number, idOrdemFichGerado: number): Promise<Array<IRHProceSegSocialFicheiroEdicao>> {
    return new Promise<Array<IRHProceSegSocialFicheiroEdicao>>((resolve, reject) => {
      this._apiService
        .get<Array<IRHProceSegSocialFicheiroEdicao>>({
          url: `${this._path}/get-info-ss/${nempresa}/${ano}/${mes}/${idOrdemFichGerado}`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public saveInfoSS(nempresa: string, ano: number, mes: number, idOrdemFichGerado: number, item: IRHProceSegSocialFicheiroEdicaoSave): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/save-info-ss/${nempresa}/${ano}/${mes}/${idOrdemFichGerado}`,
      body: item
    });
  }

  public gerarEstorno(nempresa: string, ano: number, mes: number, idOrdemFichGerado: number): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/estorno/gerar/${nempresa}/${ano}/${mes}/${idOrdemFichGerado}`
    });
  }

  public apagarEstorno(nempresa: string, ano: number, mes: number, idOrdemFichGerado: number): TServiceResponse<void> {
    return this._apiService.delete({
      url: `${this._path}/estorno/apagar/${nempresa}/${ano}/${mes}/${idOrdemFichGerado}`
    });
  }
}
