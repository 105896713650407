import {Component, ElementRef, Injector, Input, ViewChild} from '@angular/core';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {IDiferimentosParams, IDiferimentosSaved, IHeaderCalculoDiferimentos} from '../../../../../../diferimentos/diferimentos.module.interface';
import {HttpResponse} from '@angular/common/http';
import {IJsonCalculoDiferimentosConfig} from '../../../../../../diferimentos/jsonCalculoDiferimentos.interface';
import {CalculoDiferimentosConfigModalComponent} from '../../../../../../diferimentos/modals/config/calculoDiferimentos.config.modal.component';
import {CGModalService} from '../../../../../../../components/cg/modal/cgmodal.service';
import {DiferimentosService} from '../../../../../../diferimentos/diferimentos.module.service';
import {focusElement} from '../../../../../../../../common/utils/element.utils';

@Component({
  selector: 'doccontabilidade-calcdiferimentos-modal',
  templateUrl: './docContabilidade.calcDiferimentos.modal.component.html'
})
export class DocContabilidadeCalcDiferimentosModalComponent extends CGModalComponent<IDiferimentosSaved> {
  @Input() public diferimentosParams: IDiferimentosParams;

  public header: IHeaderCalculoDiferimentos;

  private _btnAplicarContabElement: HTMLElement;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _calculoDiferimentosService: DiferimentosService
  ) {
    super(_injector);
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._calculoDiferimentosService
      .validateDiferimento(this.header.dataini, this.header.datafim)
      .then(() => {
        super.close({
          dataIniDiferimento: this.header.dataini,
          dataFimDiferimento: this.header.datafim,
          tipodiferimento: this.header.tipodiferimento,
          tipoDataRefDiferimento: this.header.tipoDataRefDiferimento
        });
      })
      .finally(() => {
        this.enableClose();
      });
  }

  public evtHeaderChanged(header: IHeaderCalculoDiferimentos): void {
    this.header = header;
  }

  public evtOutOffFocusDataFim(): void {
    if (this._btnAplicarContabElement) {
      focusElement(this._btnAplicarContabElement);
    }
  }

  public openConfigModal(): Promise<void> {
    return this._calculoDiferimentosService.getConfigsCustDif().then((response: HttpResponse<IJsonCalculoDiferimentosConfig>) => {
      const modalInstance = this._cgModalService.showVanilla(CalculoDiferimentosConfigModalComponent);
      const componentInstance: CalculoDiferimentosConfigModalComponent = modalInstance.componentInstance;
      componentInstance.custDif = response.body;
    });
  }

  @ViewChild('aplicarContab', {read: ElementRef})
  public set aplicarContab(value: ElementRef<HTMLElement>) {
    this._btnAplicarContabElement = value?.nativeElement;
  }
}
