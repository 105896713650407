import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_PCA_RH_RECIBOS_VENCIMENTOS} from './recibosVencimentos.module.interface';
import {RecibosVencimentosModuleComponent} from './components/recibosVencimentos.module.component';

export const MODULE_PCA_RH_RECIBOS_VENCIMENTOS: IModuleDefinition = {
  name: MODULE_NAME_PCA_RH_RECIBOS_VENCIMENTOS,
  state: {
    url: '/rh/recibosvencimentos',
    component: RecibosVencimentosModuleComponent,
    data: {
      roles: [ROLE.PCA, ROLE.RH],
      icon: 'fa-file-text-o'
    },
    params: {
      codEmp: {
        type: 'int',
        value: -1
      },
      nProcessamento: {
        type: 'int',
        value: -1
      }
    }
  }
};
