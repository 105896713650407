<div class="tribut-autonomas">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="search" plPromise>
        <pl-group>
          <label [translate]="'tributAutonomas.header.modeloTributAutonoma'"></label>
          <edit>
            <pl-edit-radio-group attrName="modeloTributAutonoma" [(model)]="header.modeloTributAutonoma" [properties]="propertiesModeloTributAutonoma"></pl-edit-radio-group>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'global.text.year'"></label>
            <edit>
              <pl-select [source]="header.anosSource" [model]="header.ano" (modelChange)="header.ano = $event" [clearable]="false" plAutoFocus></pl-select>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'tributAutonomas.header.contasDe'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                attrName="contasDe"
                [model]="header"
                (selectedKeyChange)="header.contasDe = $event"
                [filter]="filterMovimento"
                [fieldsMap]="{nConta: 'contasDe'}"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                attrName="contasAte"
                [model]="header"
                (selectedKeyChange)="header.contasAte = $event"
                [filter]="filterMovimento"
                [fieldsMap]="{nConta: 'contasAte'}"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <edit>
            <pl-button type="submit" klass="btn btn-sm btn-primary"><i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="tributAutonomas"
    [dataSource]="dataGridDefinition.dataSource"
    (onInitialized)="onInitialized($event)"
    (onContextMenuPreparing)="onContextMenuPreparing($event)">
    <div *dxTemplate="let item of 'descricaoEditCellTemplate'" class="text-center">
      <pl-select [source]="tributAutonoTaxasSource" [model]="item.data.descricao" (evtChanged)="tributAutonoTaxasChanged($event.item, item.data)" rowTemplate="descricao" output="descricao">
      </pl-select>
    </div>
    <div *dxTemplate="let item of 'cellTemplateActions'" class="text-center">
      <div ngbDropdown container="body" class="col-main-table-actions">
        <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-fw fa-bars"></i></button>
        <div ngbDropdownMenu>
          <button type="button" class="dropdown-item" [translate]="'tributAutonomas.actions.poc'" (click)="openPOC(item.data.nConta)"></button>
        </div>
      </div>
    </div>
  </dx-data-grid>
</div>
