import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonQpdcf, IJsonQpdcfServicoFinancas} from './jsonQpdcf.entity.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_QPDCF, ENTITY_NAME_QPDCFSERVICOSFINANCAS} from './qpdcf.entity.interface';

export const ENTITY_QPDCF: IEntityDefinition<IJsonQpdcf> = {
  name: ENTITY_NAME_QPDCF,
  roles: [ROLE.ATIVOS, ROLE.RH],
  searchPlaceholder: 'qpdcf.pesquisa',
  metadata: {
    keyName: 'codDistConcFreg',
    descriptionName: 'codRelatorioUnico',
    fields: [
      {
        name: 'codDistConcFreg',
        caption: 'qpdcf.fields.codDistConcFreg',
        placeholder: 'qpdcf.fields.codDistConcFregPlaceholder',
        visible: false,
        validators: {required: true, maxlength: 6}
      },
      {name: 'codRelatorioUnico', caption: 'qpdcf.fields.codDistConcFreg', placeholder: 'qpdcf.fields.codDistConcFregPlaceholder', visible: true},
      {name: 'nome', caption: 'qpdcf.fields.nome', placeholder: 'qpdcf.fields.nomePlaceholder', validators: {required: true, maxlength: 60}},
      {name: 'codDistrito', caption: 'qpdcf.fields.codDistrito', placeholder: 'qpdcf.fields.codDistritoPlaceholder', validators: {required: true, maxlength: 2}},
      {name: 'codConcelho', caption: 'qpdcf.fields.codConcelho', placeholder: 'qpdcf.fields.codConcelhoPlaceholder', validators: {required: true, maxlength: 2}},
      {name: 'codFreguesia', caption: 'qpdcf.fields.codFreguesia', placeholder: 'qpdcf.fields.codFreguesiaPlaceholder', validators: {required: true, maxlength: 2}}
    ],
    order: 'codRelatorioUnico',
    searchFields: 'codRelatorioUnico,nome',
    listFields: 'codRelatorioUnico,nome,codDistrito,codConcelho,codFreguesia'
  },
  autocomplete: {
    rowTemplate: '{{codRelatorioUnico}} - {{nome}}',
    output: 'nome',
    searchFields: 'codRelatorioUnico,nome',
    outputKey: 'codRelatorioUnico'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    search: true,
    delete: false,
    filter: true
  },
  list: {
    state: {
      data: {
        pageTitle: 'global.menu.qpdcf'
      }
    }
  },
  detail: {
    state: {
      data: {
        pageTitle: 'global.menu.qpdcf'
      }
    }
  }
};

export const ENTITY_QPDCFSERVICOSFINANCAS: IEntityDefinition<IJsonQpdcfServicoFinancas> = {
  name: ENTITY_NAME_QPDCFSERVICOSFINANCAS,
  roles: [ROLE.ATIVOS, ROLE.RH],
  searchPlaceholder: 'qpdcf.pesquisa',
  metadata: {
    keyName: 'codServicoFinancas',
    descriptionName: 'nomeServicoFinancas',
    fields: [
      {name: 'codDistrito', caption: 'qpdcf.fields.codDistrito', placeholder: 'qpdcf.fields.codDistritoPlaceholder', visible: false},
      {name: 'codServicoFinancas', caption: 'qpdcf.fields.codServicoFinancas', placeholder: 'qpdcf.fields.codServicoFinancasPlaceholder', visible: false},
      {name: 'nomeServicoFinancas', caption: 'qpdcf.fields.nomeServicoFinancas', placeholder: 'qpdcf.fields.nomeServicoFinancasPlaceholder', visible: false}
    ],
    order: 'codServicoFinancas',
    searchFields: 'codServicoFinancas,nomeServicoFinancas',
    listFields: 'codDistrito,codServicoFinancas,nomeServicoFinancas'
  },
  autocomplete: {
    rowTemplate: '{{codServicoFinancas}} - {{nomeServicoFinancas}}',
    output: 'nomeServicoFinancas',
    searchFields: 'codServicoFinancas,nomeServicoFinancas',
    outputKey: 'codServicoFinancas'
  },
  actions: {
    new: false,
    detail: false,
    edit: false,
    search: false,
    delete: false,
    filter: false
  }
};
