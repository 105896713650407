import {Observable, Subject} from 'rxjs';
import {DxDataGridComponent} from 'devextreme-angular';
import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {copy, EMouseEventButton, IPlDynamicVisualsSecondaryClickAction, isEmpty, isNumber, isObject, toInteger} from 'pl-comps-angular';
import {devExpressDataGridExpandDetailHandler} from '../../devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DevExpressDataGridUIService} from '../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {EEntityStateListQueryParam, entityStateListQueryParam} from '../../../../common/utils/entity.state.utils';
import {EntityListComponent} from '../../entity/list/entity.list.component';
import {EntityMaintenanceService} from '../../entity/maintenance/entity/entity.maintenance.service';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IDevExpressDataGridEventOnCellDblClick, IDevExpressDataGridEventOnContextMenuPreparing} from '../../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGridState} from '../../devexpress/datagrid/state/devexpress.datagrid.state.interface';
import {IEntity, IEntityDevExpressDataGrid, IEntityServiceMethodsOverride} from '../../entity/entity.definition.interface';
import {IEntityListProperties} from '../../entity/list/entity.list.component.interface';
import {IEntityMaintenanceInstance} from '../../entity/maintenance/entity/entity.maintenance.interface';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IModuleEntityListOnDataGridCellClick, IModuleEntityListStateParams} from './module.entitylist.interface';
import {IPortalStates} from '../../../services/portals/portals.service.interface';
import {IRefreshList} from '../../../../common/interfaces/tables.interface';
import {isTest, UNDEFINED_ID} from '../../../../config/constants';
import {ModuloComponent} from '../module.component';
import {skipTakeToPagePerPage} from '../../../../common/utils/utils';

const IS_TEST: boolean = isTest();

@Component({
  selector: 'modulo-entity-list',
  templateUrl: './module.entitylist.component.html'
})
export class ModuloEntityListComponent<TJson extends object, TEntityService extends IEntityService<TJson> = IEntityService<TJson>>
  extends ModuloComponent
  implements OnInit, OnDestroy, IRefreshList<void>
{
  @Input() public states: IPortalStates;
  @Input() public entity: IEntity;

  public entityName: string;
  public entityClassName: string;
  public listOptions: IEntityListProperties;
  public searchValue: string;
  public filterValue: string;
  public initialFilterValue: string;
  public serviceMethodsOverride: IEntityServiceMethodsOverride;
  public dataGridState: IDevExpressDataGridState;

  protected readonly _entityServiceBuilder: EntityServiceBuilder;
  protected readonly _entityMaintenanceService: EntityMaintenanceService;
  protected readonly _devExpressDataGridUIService: DevExpressDataGridUIService;

  protected readonly _subjectOnSelect: Subject<IModuleEntityListOnDataGridCellClick<TJson>>;
  protected readonly _subjectOnDetail: Subject<IModuleEntityListOnDataGridCellClick<TJson>>;
  protected readonly _subjectOnRefresh: Subject<void>;
  protected readonly _subjectOnNew: Subject<void>;
  protected readonly _subjectOnDataGridCellDblClick: Subject<IDevExpressDataGridEventOnCellDblClick<TJson>>;
  protected _service: TEntityService;
  protected _maintenanceInstance: IEntityMaintenanceInstance<TJson>;
  protected _disableNavigation: boolean;
  protected _navigating: boolean;

  private _observableOnSelect: Observable<IModuleEntityListOnDataGridCellClick<TJson>>;
  private _observableOnDetail: Observable<IModuleEntityListOnDataGridCellClick<TJson>>;
  private _observableOnRefresh: Observable<void>;
  private _observableOnNew: Observable<void>;
  private _observableOnDataGridCellDblClick: Observable<IDevExpressDataGridEventOnCellDblClick<TJson>>;
  private _entityListComponent: EntityListComponent;

  constructor(protected readonly _injector: Injector) {
    super(_injector);

    this._entityServiceBuilder = this._injector.get<EntityServiceBuilder>(EntityServiceBuilder);
    this._entityMaintenanceService = this._injector.get<EntityMaintenanceService>(EntityMaintenanceService);
    this._devExpressDataGridUIService = this._injector.get<DevExpressDataGridUIService>(DevExpressDataGridUIService);

    this._subjectOnSelect = new Subject<IModuleEntityListOnDataGridCellClick<TJson>>();
    this._subjectOnDetail = new Subject<IModuleEntityListOnDataGridCellClick<TJson>>();
    this._subjectOnRefresh = new Subject<void>();
    this._subjectOnNew = new Subject<void>();
    this._subjectOnDataGridCellDblClick = new Subject<IDevExpressDataGridEventOnCellDblClick<TJson>>();
    this._disableNavigation = false;
    this._navigating = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.entityName = this.entity.name;
    this.entityClassName = `${this.entityName}-list`;
    this.serviceMethodsOverride = this.entity.serviceMethodsOverride;
    this._service = this._entityServiceBuilder.build<TJson, TEntityService>(this.entityName);
    this._maintenanceInstance = this._entityMaintenanceService.build<TJson>(this.entity.name);

    this.btnNovo.visible = this.entity.actions.new;
    this.btnNovo.click = () => this.actionAdd();

    if (this.entity.actions.newMultiple) {
      this.btnNovo.type = 'dropdown-split';
      this.btnNovo.menu = [];
    }

    // Restore list state
    if (this._transition) {
      const stateParams: object = this._transition.params();

      const paramFilter: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.Filter);
      const stateParamFilter: string = stateParams[paramFilter];
      const hasParamFilter = !isEmpty(stateParamFilter);
      if (hasParamFilter) {
        this.filterValue = stateParamFilter;
      }

      const paramInitialFilter: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.InitialFilter);
      const stateParamInitialFilter: string = stateParams[paramInitialFilter];
      if (!isEmpty(stateParamInitialFilter)) {
        this.initialFilterValue = stateParamInitialFilter;
      }

      if (!isObject(this.dataGridState)) {
        const paramDataGridState: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.DataGridState);
        const stateParamDataGridState: IDevExpressDataGridState = stateParams[paramDataGridState];
        if (isObject(stateParamDataGridState)) {
          this.dataGridState = stateParamDataGridState;
        } else {
          const paramSearch: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.Search);
          const paramPage: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.Page);
          const paramPerPage: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.PerPage);
          const paramTake: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.Take);
          const paramSkip: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.Skip);
          const stateParamSearch: string = stateParams[paramSearch];
          const stateParamPage: number = toInteger(stateParams[paramPage]);
          const stateParamPerPage: number = toInteger(stateParams[paramPerPage]);
          const stateParamTake: number = toInteger(stateParams[paramTake]);
          const stateParamSkip: number = toInteger(stateParams[paramSkip]);
          const hasParamSearch = !isEmpty(stateParamSearch);
          const hasParamPage = isNumber(stateParamPage) && stateParamPage > 0;
          const hasParamPerPage = isNumber(stateParamPage) && stateParamPerPage > 0;
          const hasParamTake = isNumber(stateParamTake) && stateParamTake > UNDEFINED_ID;
          const hasParamSkip = isNumber(stateParamSkip) && stateParamSkip > UNDEFINED_ID;
          if (hasParamSearch || /*hasParamFilter || */ hasParamPage || hasParamPerPage || hasParamTake || hasParamSkip) {
            this.dataGridState = {};
            if (hasParamSearch) {
              this.dataGridState.searchText = stateParamSearch;
            }
            // State param filter has to be deserialized to a filter expression
            // if (hasParamFilter) {
            //   this.dataGridState.filterValue = stateParamFilter;
            // }
            if (this.entity.actions.modernPagination) {
              if (hasParamSkip && hasParamTake) {
                const [page, pageSize] = skipTakeToPagePerPage(stateParamSkip, stateParamTake);
                // Pages start with 1 but pageIndex is zero based indexed
                this.dataGridState.pageIndex = page - 1;
                this.dataGridState.pageSize = pageSize;
              }
            } else {
              if (hasParamPage) {
                this.dataGridState.pageIndex = stateParamPage;
              }
              if (hasParamPerPage) {
                this.dataGridState.pageSize = stateParamPerPage;
              }
            }
          }
        }
      }
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subjectOnSelect.complete();
    this._subjectOnDetail.complete();
    this._subjectOnRefresh.complete();
    this._subjectOnNew.complete();
    this._subjectOnDataGridCellDblClick.complete();
  }

  public refreshList(): Promise<void> {
    return this.entityListComponent?.refresh();
  }

  public onRefreshList(): void {
    this._subjectOnRefresh.next();
  }

  public updateStateParams(stateParams: IModuleEntityListStateParams): Promise<void> {
    if (IS_TEST || this.maintenanceMode || this._navigating) {
      return Promise.resolve();
    }
    const params: object = {};
    if (Object.prototype.hasOwnProperty.call(stateParams, 'search')) {
      const paramSearch: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.Search);
      params[paramSearch] = stateParams.search;
    }
    if (Object.prototype.hasOwnProperty.call(stateParams, 'filter')) {
      const paramFilter: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.Filter);
      params[paramFilter] = stateParams.filter;
    }
    if (this.entity.actions.modernPagination) {
      if (Object.prototype.hasOwnProperty.call(stateParams, 'skip')) {
        const paramSkip: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.Skip);
        params[paramSkip] = stateParams.skip;
      }
      if (Object.prototype.hasOwnProperty.call(stateParams, 'take')) {
        const paramTake: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.Take);
        params[paramTake] = stateParams.take;
      }
    }
    if (!this.entity.actions.modernPagination || (!isNumber(stateParams.skip) && !isNumber(stateParams.take))) {
      if (Object.prototype.hasOwnProperty.call(stateParams, 'page')) {
        const paramPage: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.Page);
        params[paramPage] = stateParams.page;
      }
      if (Object.prototype.hasOwnProperty.call(stateParams, 'perPage')) {
        const paramPerPage: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.PerPage);
        params[paramPerPage] = stateParams.perPage;
      }
    }
    if (Object.prototype.hasOwnProperty.call(stateParams, 'dataGridState')) {
      const paramDataGridState: string = entityStateListQueryParam(this.entityName, EEntityStateListQueryParam.DataGridState);
      params[paramDataGridState] = stateParams.dataGridState;
    }
    return this._stateService.go(this.states.list.name, params, {supercede: false}).then(() => undefined);
  }

  public actionAdd(): Promise<void> {
    this._navigating = true;
    return this._stateService
      .go(this.states.new.name)
      .then(() => {
        this._subjectOnNew.next();
      })
      .finally(() => {
        this._navigating = false;
      });
  }

  public onDataGridPreparing(dataGrid: IEntityDevExpressDataGrid<TJson>): void {
    if (this.entity.actions.search) {
      dataGrid.searchPanel.focusOnInit = true;
    }
  }

  public async onDataGridCellClick(eventCellClick: IModuleEntityListOnDataGridCellClick<TJson>): Promise<void> {
    this._subjectOnSelect.next(eventCellClick);
    const {column, component, data, event, rowType, params} = eventCellClick;
    if (event.defaultPrevented || rowType !== 'data' || !isObject(data)) {
      return;
    }
    if (!this.entity.actions.detail || column.type === 'detailExpand') {
      const masterDetail: DxDataGridComponent['masterDetail'] = <DxDataGridComponent['masterDetail']>component.option('masterDetail');
      if (!masterDetail.enabled) {
        return;
      }
      await devExpressDataGridExpandDetailHandler(eventCellClick, () => this.onDetail(eventCellClick));
    } else if (!column?.type && (<MouseEvent>event).button === EMouseEventButton.Main && !this.maintenanceMode) {
      const id = this.entity.getId(data);
      if ((<MouseEvent>event).ctrlKey || (<MouseEvent>event).shiftKey) {
        this.openDetailStateInNewTabOrWindow(id, params);
        return;
      }
      component.beginCustomLoading(undefined);
      try {
        await this.openDetailState(id, params);
      } catch (reason) {
        this._logger.error(reason);
      } finally {
        component.endCustomLoading();
      }
    }
  }

  public onDataGridCellDblClick(event: IDevExpressDataGridEventOnCellDblClick<TJson>): void {
    this._subjectOnDataGridCellDblClick.next(event);
  }

  public onDataGridContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<TJson>): void {
    if (this.entity.actions.edit && event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();
      const id: string | number = this.entity.getId(event.row.data);
      if (isNumber(id) || !isEmpty(id)) {
        const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateContextMenuActions(id);
        this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
      }
    }
  }

  public onDataGridStateChanged(event: IDevExpressDataGridState): void {
    if (this.entity.actions.detail && !this.maintenanceMode) {
      this.updateStateParams({
        search: event?.searchText,
        filter: undefined,
        skip: event?.skip,
        take: event?.take,
        page: event?.pageIndex,
        perPage: event?.pageSize,
        dataGridState: copy(event)
      });
    }
  }

  public onDataGridStateCleared(): void {
    if (!this.maintenanceMode) {
      this.updateStateParams({dataGridState: undefined}).then(() => this._stateService.reload(this.states.list));
    }
  }

  public onDetail(event: IModuleEntityListOnDataGridCellClick<TJson>): Promise<void> {
    this._subjectOnDetail.next(event);
    return Promise.resolve();
  }

  public openDetailState(id: string | number, params?: object): Promise<void> {
    if (this._disableNavigation) {
      return Promise.resolve();
    }
    this._navigating = true;
    return this._stateService
      .go(this.states.detail.name, {...params, id: id})
      .then(() => undefined)
      .finally(() => {
        this._navigating = false;
      });
  }

  public openDetailStateInNewTabOrWindow(id: string | number, params?: object): void {
    if (!this._disableNavigation) {
      this._cgStateService.openStateInNewTabOrWindow(this.states.detail.name, {...params, id: id});
    }
  }

  public openDetailStateAsModal(id: string | number, params?: object): Promise<TJson> {
    if (this._disableNavigation) {
      return Promise.reject(new Error('Navigation is disabled.'));
    }
    return this._maintenanceInstance.maintenanceEdit(id, {params: params});
  }

  public evtOnDataGridCellClick(): Observable<IModuleEntityListOnDataGridCellClick<unknown>> {
    if (!this._observableOnSelect) {
      this._observableOnSelect = this._subjectOnSelect.asObservable();
    }
    return this._observableOnSelect;
  }

  public evtOnDetail(): Observable<IModuleEntityListOnDataGridCellClick<unknown>> {
    if (!this._observableOnDetail) {
      this._observableOnDetail = this._subjectOnDetail.asObservable();
    }
    return this._observableOnDetail;
  }

  public evtOnRefresh(): Observable<void> {
    if (!this._observableOnRefresh) {
      this._observableOnRefresh = this._subjectOnRefresh.asObservable();
    }
    return this._observableOnRefresh;
  }

  public evtOnNew(): Observable<void> {
    if (!this._observableOnNew) {
      this._observableOnNew = this._subjectOnNew.asObservable();
    }
    return this._observableOnNew;
  }

  public evtOnDataGridCellDblClick(): Observable<IDevExpressDataGridEventOnCellDblClick<TJson>> {
    if (!this._observableOnDataGridCellDblClick) {
      this._observableOnDataGridCellDblClick = this._subjectOnDataGridCellDblClick.asObservable();
    }
    return this._observableOnDataGridCellDblClick;
  }

  public setDisableNavigation(value: boolean): void {
    this._disableNavigation = value;
  }

  public get entityListComponent(): EntityListComponent {
    return this._entityListComponent;
  }

  @ViewChild(EntityListComponent, {static: true})
  public set entityListComponent(value: EntityListComponent) {
    this._entityListComponent = value;
  }

  protected _faqsName(): string {
    return this.entity.name;
  }

  protected _generateContextMenuActions(id: string | number): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'entity.list.secondaryClick.menu.openAsModal',
        icon: 'fa-file-o',
        click: () => this.openDetailStateAsModal(id)
      },
      {
        caption: 'entity.list.secondaryClick.menu.openAsNewTabOrWindow',
        icon: 'fa-window-restore',
        click: () => {
          this.openDetailStateInNewTabOrWindow(id);
        }
      }
    ];
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected get service(): TEntityService {
    return this._service;
  }
}
