import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {CGCCalendarMonthViewModule} from './month/calendar.month.view.module';
import {CGCCalendarYearViewModule} from './year/calendar.year.view.module';

export * from './month/calendar.month.view.module';
export * from './year/calendar.year.view.module';

@NgModule({
  imports: [CommonModule, CGCCalendarMonthViewModule, CGCCalendarYearViewModule],
  exports: [CGCCalendarMonthViewModule, CGCCalendarYearViewModule]
})
export class PlCalendarModule {}
