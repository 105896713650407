<pl-form class="entity-detail-form devolucoes-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h4 class="modal-title" [translate]="'documentoFaturacao.btn.notaCredito'"></h4>
  </div>

  <div class="modal-body">
    <pl-nav-wizard
      #navWizard="navWizard"
      [hideFooter]="true"
      [definition]="definitionWizard"
      [callback]="navWizardCallback"
      (evtStepChange)="stepChanged($event)"
      [beforeStepChange]="fnBeforeChangedStep"
      [onFinalize]="fnFinalize">
      <pl-nav-wizard-step [stepId]="stepsDevolucao.Documento" caption="docscomerciais.auxiliares.documento" icon="fa fa-cog fa-fw" [hidePrevious]="true" [visible]="temDocDestino">
        <div *plNavWizardStepContent>
          <h5 class="devolucoes-step-title" [translate]="'devolucao.caption.documentoDestino'"></h5>
          <pl-group>
            <pl-group>
              <label [translate]="'devolucao.caption.tipoDocumento'"></label>
              <edit>
                <entity-autocomplete attrName="docfa" entity="docfas" [(model)]="modelDocfa" [filter]="nDocfaFilter" [output]="'{{nDocFa}} - {{nome}}'" plAutoFocus></entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'docscomerciais.fields.nNumer'"></label>
              <edit>
                <entity-autocomplete attrName="nnumer" entity="docfasnums" [(model)]="devolucao.nNumerDraftDestino" [filter]="docfaNumFilter" [output]="'nNumer'" [outputKey]="'nNumer'">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
        </div>
      </pl-nav-wizard-step>
      <pl-nav-wizard-step [stepId]="stepsDevolucao.Tipo" caption="devolucao.caption.stepTipo" icon="fa fa-list fa-fw" [visible]="!modoEdicao">
        <div *plNavWizardStepContent>
          <div class="px-2 py-1">
            <h5 class="devolucoes-step-title"><span [translate]="'devolucao.messages.escolherTipo'"></span>:</h5>
            <div class="row">
              <div class="col-md-6">
                <h5 class="devolucoes-sub-title" [translate]="'devolucao.caption.emQuantidade'"></h5>
                <div class="d-flex" style="gap: 10px">
                  <input
                    id="tipoDevolucaoArtigoQuantidade"
                    type="radio"
                    name="tipoDevolucao"
                    [checked]="tipoSelecionado === tipoDevolucao.ArtigoQuantidade"
                    (change)="tipoDevolucaoChanged(tipoDevolucao.ArtigoQuantidade)" />
                  <label for="tipoDevolucaoArtigoQuantidade">
                    <i class="fa fa-shopping-cart fa-fw me-1"></i><span [translate]="'devolucao.caption.artigoQuantidade'"></span>
                    <pl-tooltip class="tooltip-info-label" [config]="{text: 'devolucao.tooltip.artigoQuantidade', placement: 'right'}">
                      <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                    </pl-tooltip>
                  </label>
                </div>
              </div>
              <div class="col-md-6">
                <h5 class="devolucoes-sub-title" [translate]="'devolucao.caption.emValor'"></h5>
                <div class="d-flex" style="gap: 10px" *ngIf="!modoEdicao">
                  <input
                    id="tipoDevolucaoDocumento"
                    type="radio"
                    name="tipoDevolucao"
                    [checked]="tipoSelecionado === tipoDevolucao.Documento"
                    (change)="tipoDevolucaoChanged(tipoDevolucao.Documento)" />
                  <label for="tipoDevolucaoDocumento">
                    <i class="fa fa-usd fa-fw me-1"></i><span [translate]="'devolucao.caption.documentoValor'"></span>
                    <pl-tooltip class="tooltip-info-label" [config]="{text: 'devolucao.tooltip.documentoValor', placement: 'right'}">
                      <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                    </pl-tooltip>
                  </label>
                </div>
                <div class="d-flex" style="gap: 10px">
                  <input
                    id="tipoDevolucaoArtigoValor"
                    type="radio"
                    name="tipoDevolucao"
                    [checked]="tipoSelecionado === tipoDevolucao.ArtigoValor"
                    (change)="tipoDevolucaoChanged(tipoDevolucao.ArtigoValor)" />
                  <label for="tipoDevolucaoArtigoValor">
                    <i class="fa fa-usd fa-fw me-1"></i><span [translate]="'devolucao.caption.artigoValor'"></span>
                    <pl-tooltip class="tooltip-info-label" [config]="{text: 'devolucao.tooltip.artigoValor', placement: 'right'}">
                      <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                    </pl-tooltip>
                  </label>
                </div>
                <div *ngIf="!doc?.cab?.terminado" class="d-flex" style="gap: 10px">
                  <input
                    id="tipoDevolucaoDocumentoExterno"
                    type="radio"
                    name="tipoDevolucao"
                    [checked]="tipoSelecionado === tipoDevolucao.DocumentoExterno"
                    (change)="tipoDevolucaoChanged(tipoDevolucao.DocumentoExterno)" />
                  <label for="tipoDevolucaoDocumentoExterno">
                    <i class="fa fa-upload fa-fw me-1"></i><span [translate]="'devolucao.caption.documentoExterno'"></span>
                    <pl-tooltip class="tooltip-info-label" [config]="{text: 'devolucao.tooltip.documentoExterno', placement: 'right-bottom'}">
                      <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                    </pl-tooltip>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step [stepId]="stepsDevolucao.Devolucao" caption="devolucao.caption.devolucao" icon="fa fa-file fa-fw" [hidePrevious]="modoEdicao">
        <div *plNavWizardStepContent>
          <h5 class="devolucoes-step-title">{{ tipoSelecionadoStr }}</h5>
          <cg-card-panel #cardPanel="cgCardPanel" *ngIf="!doc.cab.terminado && doc.cab.nDocumento === 0 && tipoSelecionado !== tipoDevolucao.DocumentoExterno && !modoEdicao">
            <div *cgCardPanelContent>
              <div class="px-2 py-1">
                <pl-group>
                  <label [translate]="'devolucao.caption.tipoDocOrigem'"></label>
                  <edit>
                    <entity-autocomplete attrName="docfaOrigem" entity="docfas" [(model)]="modelDocfaOrigem" [filter]="nDocfaFilterOrigem" [output]="'{{nDocFa}} - {{nome}}'" plAutoFocus>
                    </entity-autocomplete>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'docscomerciais.fields.nome.clientes'"></label>
                  <edit>
                    <entity-autocomplete entity="clifos" [(model)]="doc.clifo" [properties]="{readonly: true}" [output]="'{{nConta}} - {{nome}}'"></entity-autocomplete>
                  </edit>
                </pl-group>
                <pl-group>
                  <pl-group>
                    <label [translate]="'devolucao.caption.deArtigo'"></label>
                    <edit>
                      <entity-autocomplete entity="artigos" [selectedKey]="deNArtigo" (selectedKeyChange)="nartigoDeChanged($event)" output="key"></entity-autocomplete>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'global.text.to'"></label>
                    <edit>
                      <entity-autocomplete entity="artigos" [selectedKey]="ateNArtigo" (selectedKeyChange)="nartigoAteChanged($event)" output="key"></entity-autocomplete>
                    </edit>
                  </pl-group>
                </pl-group>
                <pl-group>
                  <pl-group>
                    <label [translate]="'devolucao.caption.desdeDataDoc'"></label>
                    <edit>
                      <pl-edit-datepicker attrName="dataDoc" [(model)]="dataDoc"></pl-edit-datepicker>
                    </edit>
                  </pl-group>
                  <pl-group></pl-group>
                </pl-group>
                <pl-group>
                  <edit>
                    <button type="button" class="btn btn-sm btn-primary" id="btnPesquisar" (click)="pesquisaLinhasDevolucao()" plPromise data-focus>
                      <i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
                    </button>
                  </edit>
                </pl-group>
              </div>
            </div>
          </cg-card-panel>
          <pl-group *ngIf="tipoSelecionado === tipoDevolucao.DocumentoExterno">
            <pl-group>
              <label [translate]="'devolucao.caption.docExternoOrigem'"></label>
              <edit>
                <pl-edit
                  type="text"
                  [(model)]="modelDocExt.nDocExterno"
                  [properties]="{validators: {required: {value: true}}, placeholder: 'devolucao.caption.docExternoPlaceholder'}"
                  attrName="docExterno"
                  plAutoFocus
                  [plAutoFocusDisabled]="tipoSelecionado !== tipoDevolucao.DocumentoExterno"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'devolucao.caption.dataDocExternoOrigem'"></label>
              <edit>
                <pl-edit-datepicker [(model)]="modelDocExt.dataDoc"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </pl-group>
          <pl-group>
            <pl-group>
              <label [translate]="'docscomerciais.auxiliares.motivo'"></label>
              <edit>
                <pl-autocomplete
                  [source]="motivos"
                  [model]="motivoSelecionado"
                  (modelChange)="motivoChanged($event)"
                  attrName="motivo"
                  rowTemplate="motivoStr"
                  [output]="motivosOutput"
                  maxlength="100"
                  plAutoFocus
                  [plAutoFocusDisabled]="!doc.cab.terminado"
                  [properties]="{validators: {required: {value: true}}, placeholder: 'devolucao.placeholder.motivo' | translate}"
                  #comboMotivos>
                  <actions>
                    <div *inputGroup="{klass: 'hollow-form'}" style="padding: 0">
                      <span class="btn btn-sm btn-primary" (click)="adicionarMotivo()" style="border-radius: 0; height: 28px">
                        <pl-tooltip [config]="{text: 'devolucao.tooltip.adicionarMotivo'}">
                          <i *plTooltipContent class="fa fa-plus fa-lg"></i>
                        </pl-tooltip>
                      </span>
                    </div>
                  </actions>
                </pl-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <pl-group *ngIf="tipoSelecionado !== tipoDevolucao.ArtigoQuantidade">
                <label [translate]="'devolucao.caption.artigoDesconto'"></label>
                <edit>
                  <entity-autocomplete
                    entity="artigos"
                    attrName="artigo"
                    [model]="artigoDevolucao"
                    [quickCreateEnabled]="true"
                    (modelChange)="artigoChanged($event)"
                    filter="MovimStock=false"
                    [properties]="{validators: {required: {value: true}}, placeholder: 'devolucao.placeholder.artigoParaDesconto' | translate}">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>
          </pl-group>
          <pl-group *ngIf="tipoSelecionado === tipoDevolucao.DocumentoExterno">
            <pl-group>
              <label [translate]="'devolucao.caption.valorDocExterno'"></label>
              <edit>
                <pl-edit type="number" [(model)]="modelDocExt.valor" attrName="valorDocExt" [properties]="{decimalsLimit: decimaisValoresUnitarios}"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'devolucao.caption.quantidade'"></label>
              <edit>
                <pl-edit type="number" attrName="qtd" [(model)]="modelDocExt.qtd" [properties]="{validators: {min: {value: 1}}, decimalsLimit: decimaisQtds}"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'devolucao.caption.codIva'"></label>
              <edit>
                <entity-autocomplete
                  entity="ivas"
                  attrName="iva"
                  [model]="modelDocExt"
                  (modelChange)="onIvaChanged($event)"
                  (selectedKeyChange)="modelDocExt.codIva = $event"
                  [fieldsMap]="{taxaActual: 'taxaIva'}"
                  outputKey="codIva"
                  [output]="ivasOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
          <div *ngIf="tipoSelecionado !== tipoDevolucao.DocumentoExterno">
            <dx-data-grid
              [cgDxDataGrid]="dataGridLinhasDefinition"
              [dataSource]="dataSource"
              (onCellPrepared)="onCellPrepared($event)"
              (onInitialized)="onInitialized($event)"
              (onRowUpdated)="onRowUpdated($event)"
              (onSelectionChanged)="onSelectionChanged($event)"
              (onEditorPreparing)="onEditorPreparing($event)">
              <div *ngIf="tipoSelecionado !== tipoDevolucao.Documento">
                <div *dxTemplate="let item of 'headerCellTemplateBtns'">
                  <dx-button
                    class="dx-button-icon-fontawesome"
                    [hint]="'devolucao.tooltip.devolveTudo' | translate"
                    (click)="receberTudo()"
                    icon="fa fa-fw fa-check"
                    [stylingMode]="'text'"></dx-button>
                  <dx-button class="dx-button-icon-fontawesome" [hint]="'devolucao.tooltip.limpaTudo' | translate" (click)="limpaTudo()" icon="fa fa-fw fa-eraser" [stylingMode]="'text'"></dx-button>
                </div>
              </div>

              <div *dxTemplate="let item of 'cellTemplateBtns'">
                <dx-button
                  class="dx-button-icon-fontawesome"
                  [hint]="'devolucao.tooltip.devolveTudo' | translate"
                  (click)="receberTudoLinha(item.data)"
                  icon="fa fa-fw fa-check"
                  [stylingMode]="'text'"></dx-button>

                <dx-button
                  class="dx-button-icon-fontawesome"
                  [hint]="'devolucao.tooltip.limpaDevolvido' | translate"
                  (click)="limpaLinha(item.data)"
                  icon="fa fa-fw fa-eraser"
                  [stylingMode]="'text'"></dx-button>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </pl-nav-wizard-step>
      <pl-nav-wizard-step [stepId]="stepsDevolucao.Preview" caption="documentoFaturacao.summary" icon="fas fa-eye" [hidePrevious]="true" [hideFinalize]="true" *ngIf="showPreview" [hideNext]="true">
        <div *plNavWizardStepContent>
          <documento-faturacao [doc]="doc"></documento-faturacao>
        </div>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </div>

  <div class="modal-footer" plNavigation>
    <pl-nav-wizard-actions [navWizardInstance]="navWizard"></pl-nav-wizard-actions>

    <button type="button" class="btn btn-warning btn-sm action-editar-documento" *ngIf="showPreview" (click)="editarDocumento()">
      <i class="fa fa-fw fa-pencil-square-o"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.editarDocumento'"></span>
    </button>
    <button type="button" class="btn btn-sm btn-light action-dismiss" (click)="dismiss()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</pl-form>
