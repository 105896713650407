<div class="modal-documento-artigos-advanced-search">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'docscomerciais.artigosAdvancedSearch.title'"></h4>
  </div>

  <div class="sticky modal-body">
    <entity-list
      entityName="artigos"
      [maintenanceMode]="maintenanceMode"
      [serviceMethodsOverride]="serviceMethodsOverride"
      (evtDataGridColumnsPreparing)="onDataGridColumnsPreparing($event)"
      (evtDataGridOnInitialized)="onDataGridOnInitialized($event)"
      (evtDataGridCellPrepared)="onDataGridCellPrepared($event)"
      (evtDataGridOnSelectionChanged)="onDataGridOnSelectionChanged($event)"
      (evtDataGridOnEditingStart)="onDataGridOnEditingStart($event)"
      [beforeRequest]="fnBeforeRequest"
      [afterRequest]="fnAfterRequest"
      [filter]="filter"
      [dataGridProperties]="dataGridProperties">
    </entity-list>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-primary btn-sm" name="btnAddLines" [disabled]="closeDisabled" [click]="close" plPromise>
      <i class="fa fa-fw" [ngClass]="{'fa-check': !this.emptyLineComercial, 'fa-plus': this.emptyLineComercial}"></i>&nbsp;<span [translate]="captionBtnClose"></span>
    </button>

    <button type="button" class="btn btn-light btn-sm action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
      <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span>
    </button>
  </div>
</div>
