import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {Directive, Injector, OnDestroy} from '@angular/core';
import {ConfigErpService} from '../../../services/configErp.service';
import {EConfigsErpDepth, IConfigsErpEditConfigERP, IConfigsErpEditGroup} from './components/configedit/configs.erp.edit.component.interface';
import {IJsonConfigERP} from '../../../entities/configserp/jsonConfigERP.entity.interface';
import {ModuloComponent} from '../../../components/module/module.component';

@Directive()
export abstract class ConfigsErpBaseModuleComponent extends ModuloComponent implements OnDestroy {
  public configList: Array<IConfigsErpEditConfigERP | IConfigsErpEditGroup>;

  protected readonly _configErpService: ConfigErpService;

  private readonly _subscriptionConfigs: Subscription;

  protected constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._configErpService = this._injector.get<ConfigErpService>(ConfigErpService);
    this.configList = [];
    this._subscriptionConfigs = this._configService
      .rawAsObservable()
      .pipe(take(1))
      .subscribe((rawConfigurations: Array<IJsonConfigERP>) => {
        this.configList = this._parseList(rawConfigurations);
      });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionConfigs.unsubscribe();
  }

  public saveField(configItem: IJsonConfigERP): Promise<IJsonConfigERP> {
    return this._configErpService.save(configItem);
  }

  public readonly fnSaveField: (configItem: IJsonConfigERP) => Promise<IJsonConfigERP> = (configItem: IJsonConfigERP) => this.saveField(configItem);

  public abstract get depthLevel(): EConfigsErpDepth;

  protected abstract _parseList(rawConfigurations: Array<IJsonConfigERP>): Array<IConfigsErpEditConfigERP | IConfigsErpEditGroup>;
}
