import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {
  IRHProceSegSocialFicheiroEdicao,
  IRHProceSegSocialFicheiroEdicaoSave,
  IRHProceSegSocialModel,
  IRHProceSegSocialProcessExistentesItem,
  IRHProceSegSocialProcessExistentesParams,
  IRHProceSegSocialProcessStatus,
  IRHProceSegSocialSegSocialList,
  IRHProceSegSocialSendWebService,
  IRHProceSegSocialStatus
} from './proceSegSocial.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ProceSegSocialService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${_apiService.path.restapi}/rhprocesegsocial`;
  }

  public getJobStatus(): Promise<IRHProceSegSocialStatus> {
    return new Promise<IRHProceSegSocialStatus>((resolve, reject) => {
      this._apiService
        .get<IRHProceSegSocialStatus>({
          url: `${this._path}/status`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public stopJob(): TServiceResponse<void> {
    return this._apiService.post({
      url: this.stopJobUrl(),
      body: null
    });
  }

  public stopJobUrl(): string {
    return `${this._path}/stop-job`;
  }

  public getProcessStatus(): Promise<IRHProceSegSocialProcessStatus> {
    return new Promise<IRHProceSegSocialProcessStatus>((resolve, reject) => {
      this._apiService
        .get<IRHProceSegSocialProcessStatus>({
          url: `${this._path}/get-process-status`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public getProcessExistentes(params: IRHProceSegSocialProcessExistentesParams): Promise<Array<IRHProceSegSocialProcessExistentesItem>> {
    return new Promise<Array<IRHProceSegSocialProcessExistentesItem>>((resolve, reject) => {
      this._apiService
        .post<Array<IRHProceSegSocialProcessExistentesItem>, IRHProceSegSocialProcessExistentesParams>({
          url: `${this._path}/get-proc-existentes`,
          body: params
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public processar(model: IRHProceSegSocialModel): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/processar`,
      body: model
    });
  }

  public getProcResults(): Promise<IRHProceSegSocialSegSocialList> {
    return new Promise<IRHProceSegSocialSegSocialList>((resolve, reject) => {
      this._apiService
        .get<IRHProceSegSocialSegSocialList>({
          url: `${this._path}/get-proc-results`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public verificarEnvios(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._apiService
        .get({
          url: `${this._path}/verificar-envios`
        })
        .then(() => {
          resolve();
        })
        .catch(reject);
    });
  }

  public generateAndDownloadFileUrl(): string {
    return `${this._path}/generate-file`;
  }

  public sendFileWebService(): Promise<IRHProceSegSocialSendWebService> {
    return new Promise<IRHProceSegSocialSendWebService>((resolve, reject) => {
      this._apiService
        .post<IRHProceSegSocialSendWebService>({
          url: `${this._path}/send-file-webservice`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public genDRIFile(): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: this.generateAndDownloadFileUrl(),
      responseType: 'blob'
    });
  }

  public getViewInfoSS(nempresa: string, ano: number, mes: number, idOrdemFichGerado: number): Promise<Array<IRHProceSegSocialFicheiroEdicao>> {
    return new Promise<Array<IRHProceSegSocialFicheiroEdicao>>((resolve, reject) => {
      this._apiService
        .get<Array<IRHProceSegSocialFicheiroEdicao>>({
          url: `${this._path}/get-info-ss/${nempresa}/${ano}/${mes}/${idOrdemFichGerado}`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public saveInfoSS(item: IRHProceSegSocialFicheiroEdicaoSave): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/save-info-ss`,
      body: item
    });
  }
}
