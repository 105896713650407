<div class="site-standalone-container" style="max-width: 25vw">
  <h1 [translate]="title"></h1>

  <div class="alert alert-danger" plAnimationShake [translate]="message"></div>

  <div class="site-standalone-container-actions">
    <button type="button" class="btn btn-light" [click]="back" plPromise><i class="fa fa-fw fa-angle-left"></i>&nbsp;<span [translate]="'entity.action.back'"></span></button>

    <button *ngIf="!partnerMode && !hybridMode" type="button" class="btn btn-primary" [click]="login" plPromise>
      <i class="fa fa-fw fa-sign-in"></i>&nbsp;<span [translate]="'global.menu.account.login'"></span>
    </button>
  </div>
</div>
