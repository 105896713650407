import {Injectable, Injector} from '@angular/core';
import {isObject, isString, isUndefined} from 'pl-comps-angular';
import {EntityMaintenanceInstance} from './entity.maintenance.instance';
import {EntityRegistryService} from '../../entity.registry.service';
import {IEntityMaintenanceInstance, IEntityMaintenanceInstanceProperties} from './entity.maintenance.interface';
import {IEntity} from '../../entity.definition.interface';

@Injectable({
  providedIn: 'root'
})
export class EntityMaintenanceService {
  private readonly _instances: Map<string, IEntityMaintenanceInstance>;

  constructor(
    private readonly _injector: Injector,
    private readonly _entityRegistryService: EntityRegistryService
  ) {
    this._instances = new Map<string, IEntityMaintenanceInstance>();
  }

  public build<T extends object = object>(entityOrName: string | IEntity, properties?: IEntityMaintenanceInstanceProperties<T>): IEntityMaintenanceInstance<T> {
    let entity: IEntity;
    let entityName: string;
    if (isString(entityOrName)) {
      entityName = entityOrName;
      if (this._instances.has(entityName)) {
        const instance: IEntityMaintenanceInstance<T> = <IEntityMaintenanceInstance<T>>this._instances.get(entityName);
        instance.evaluateStates();
        return instance;
      }
      entity = this._entityRegistryService.getEntity(entityName);
    } else if (isObject(entityOrName)) {
      entity = entityOrName;
      if (entity?.name) {
        entityName = entity.name;
        if (this._instances.has(entityName)) {
          const instance: IEntityMaintenanceInstance<T> = <IEntityMaintenanceInstance<T>>this._instances.get(entityName);
          instance.evaluateStates();
          return instance;
        }
      }
    }
    if (isUndefined(entity)) {
      throw new Error('Provided entity or entity name is invalid.');
    } else if (entity.asModule === false) {
      throw new Error('Provided entity does not support maintenance.');
    }
    const instance: IEntityMaintenanceInstance<T> = new EntityMaintenanceInstance<T>(this._injector, entity, properties);
    entityName = entity.name;
    this._instances.set(entityName, instance);
    return instance;
  }
}
