<div class="utilizadores-edit entity-detail-form">
  <pl-nav-wizard [beforeStepChange]="fnBeforeChangedStep" (evtStepChange)="changedStep($event)" [onFinalize]="fnFinalize">
    <!--EMPRESA-->
    <pl-nav-wizard-step [stepId]="navWizardStepIdEmpresa" caption="assistenteconfigportais.steps.stepEmpresa" icon="fa-building-o">
      <pl-form *plNavWizardStepContent>
        <pl-group [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <label [translate]="'erputilizadores.companies.title'"></label>
            <edit>
              <pl-multiselect
                [model]="empresasSelecionadas"
                [source]="empresasDisponiveis"
                key="nEmpresa"
                [template]="templateMultiSelectEmpresas"
                [properties]="{leftCaption: 'erputilizadores.companies.available', rightCaption: 'erputilizadores.companies.selected'}">
              </pl-multiselect>
            </edit>
          </pl-group>
        </pl-group>
      </pl-form>
    </pl-nav-wizard-step>

    <!--E-MAIL-->
    <pl-nav-wizard-step [stepId]="navWizardStepIdEmail" caption="assistenteconfigportais.steps.stepEmail" icon="fas fa-at">
      <pl-form *plNavWizardStepContent>
        <pl-group>
          <pl-group>
            <edit>
              <pl-edit type="radiogroup" attrName="tipoEmail" [model]="tipoEmail" (modelChange)="changedTipoEMail($event)" [properties]="{groupItems: tiposEmail}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <label [translate]="'assistenteconfigportais.fields.contaemail'"></label>
            <edit>
              <pl-edit type="string" name="email" [(model)]="model.contaEmail"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <label [translate]="'assistenteconfigportais.fields.password'"></label>
            <edit>
              <pl-edit type="password" name="password" [(model)]="model.password"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <label [translate]="'assistenteconfigportais.fields.smtp'"></label>
            <edit>
              <pl-edit type="string" name="smtp" [(model)]="model.smtp"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <label [translate]="'assistenteconfigportais.fields.portasmtp'"></label>
            <edit>
              <pl-edit type="string" name="portasmtp" [(model)]="model.smtpPort"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label [translate]="'assistenteconfigportais.fields.ssl'"></label>
          <edit>
            <pl-edit type="boolean" name="ssl" [(model)]="model.ssl"></pl-edit>
          </edit>
        </pl-group>
      </pl-form>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
