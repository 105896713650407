import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_AUSENCIAS, ETipoAusencias} from './tipoAusencias.datasource.interface';

export const DATA_SOURCE_TIPOS_AUSENCIAS: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_AUSENCIAS,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: ETipoAusencias.NA, name: 'tiposAusencias.data.na'},
    {value: ETipoAusencias.PorFerias, name: 'tiposAusencias.data.porFerias'},
    {value: ETipoAusencias.PorDoencaImprevista, name: 'tiposAusencias.data.porDoencaImprevista'},
    {value: ETipoAusencias.PorDoencaProf, name: 'tiposAusencias.data.porDoencaProf'},
    {value: ETipoAusencias.PorDoencaProlongada, name: 'tiposAusencias.data.porDoencaProlongada'},
    {value: ETipoAusencias.PorTribunal, name: 'tiposAusencias.data.porTribunal'},
    {value: ETipoAusencias.PorServicoMilitar, name: 'tiposAusencias.data.porServicoMilitar'},
    {value: ETipoAusencias.PorAssistenciaFamiliar, name: 'tiposAusencias.data.porAssistenciaFamiliar'},
    {value: ETipoAusencias.PorPaternidadeMaternidade, name: 'tiposAusencias.data.porPaternidadeMaternidade'},
    {value: ETipoAusencias.PorLicencaAurorizada, name: 'tiposAusencias.data.porLicencaAurorizada'},
    {value: ETipoAusencias.PorPenaDisciplinar, name: 'tiposAusencias.data.porPenaDisciplinar'},
    {value: ETipoAusencias.PorLicencaSabatica, name: 'tiposAusencias.data.porLicencaSabatica'},
    {value: ETipoAusencias.PorCasamento, name: 'tiposAusencias.data.porCasamento'},
    {value: ETipoAusencias.PorNascimentoFamiliar, name: 'tiposAusencias.data.porNascimentoFamiliar'},
    {value: ETipoAusencias.PorFalecimentoFamiliar, name: 'tiposAusencias.data.porFalecimentoFamiliar'},
    {value: ETipoAusencias.PorTrabalhadorEstudante, name: 'tiposAusencias.data.porTrabalhadorEstudante'},
    {value: ETipoAusencias.PorGreveParalizacao, name: 'tiposAusencias.data.porGreveParalizacao'},
    {value: ETipoAusencias.PorConsultaMedico, name: 'tiposAusencias.data.porConsultaMedico'},
    {value: ETipoAusencias.Outras, name: 'tiposAusencias.data.outras'},
    {value: ETipoAusencias.PorFaltaInjustificada, name: 'tiposAusencias.data.porFaltaInjustificada'},
    {value: ETipoAusencias.ActividadeSindical, name: 'tiposAusencias.data.actividadeSindical'},
    {value: ETipoAusencias.AcidenteTrabalho, name: 'tiposAusencias.data.acidenteTrabalho'}
  ]
});
