<div class="pl-dynamic-visuals-secondary-click" [ngClass]="cssClass">
  <div class="pl-dynamic-visuals-secondary-menu dropdown-menu show">
    <ng-container *ngFor="let action of actions" [ngSwitch]="action.divider">
      <div *ngSwitchDefault class="dropdown-item pl-dynamic-visuals-secondary-menu-item" [class.disabled]="action.disabled" (click)="clickedAction(action, $event)">
        <div class="pl-dynamic-visuals-secondary-menu-item-content">
          <i *ngIf="action.icon" aria-hidden="true" [cgcIcon]="action.icon"></i>
          <span [plTranslate]="action.caption"></span>
        </div>

        <div class="pl-dynamic-visuals-secondary-menu-item-loader">
          <i *ngIf="action.promise" class="fa fa-fw fa-circle-o-notch fa-spin" aria-hidden="true"></i>
        </div>
      </div>

      <div *ngSwitchCase="true" class="dropdown-divider"></div>
    </ng-container>
  </div>
</div>
