import {Component, Injector, Input, OnInit} from '@angular/core';
import {IPlUploadCallback, IPlUploadFile, IPlUploadParams, isBoolean, timeout} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../cg/modal/cgmodal.component';
import {ContabilidadeDigitalServiceDocuments} from '../../../../../../services/contabilidadedigital/contabilidadedigital.service.documents';
import {CG_DEFAULT_GERAL_CONTAB_DIGITAL_MAX_UPLOAD_FILES_AT_A_TIME, CG_DEFAULT_MAX_FILE_SIZE} from '../../../../../../services/config/config.service.interface';

@Component({
  selector: 'contabilidade-digital-client-connect-adddocs-modal',
  templateUrl: './contabilidadedigital.clientconnect.adddocsmodal.component.html'
})
export class ContabilidadeDigitalClientConnectAddDocsModalComponent extends CGModalComponent<boolean> implements OnInit {
  @Input() public documentsService: ContabilidadeDigitalServiceDocuments;
  @Input() public folderId: string;
  @Input() public foldersNamePath: Array<string>;
  @Input() public doOCR: boolean;
  @Input() public openFileDialogOnInit: boolean;

  public readonly callback: IPlUploadCallback;
  public maxFiles: number;
  public maxFileSize: number;
  public url: string;
  public params: IPlUploadParams;
  public uploadedSome: boolean;

  private _needsRefresh: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.openFileDialogOnInit = false;
    this.callback = {};
    this.params = {};
    this.uploadedSome = false;
    this._needsRefresh = false;
  }

  public ngOnInit(): void {
    if (!isBoolean(this.doOCR)) {
      this.doOCR = true;
    }
    this.documentsService.addUrl(this.doOCR).subscribe((url: string) => {
      this.url = url;
    });
    this.params = {folderId: this.folderId};
    if (this.openFileDialogOnInit) {
      timeout().then(() => {
        this.callback.openFileDialog();
      });
    }
  }

  public close(): void {
    super.close(this._needsRefresh);
  }

  public dismiss(): void {
    this.close();
  }

  public evaluateAllowClose(): void {
    const successFiles: Array<IPlUploadFile> = this.callback.getSuccessFiles();
    const pendingFiles: Array<IPlUploadFile> = this.callback.getPendingFiles();
    if (pendingFiles.length > 0) {
      if (!this._needsRefresh) {
        this._needsRefresh = true;
      }
      this.disableClose();
    } else {
      if (successFiles.length > 0 && !this._needsRefresh) {
        this._needsRefresh = true;
      }
      this.enableClose();
    }
  }

  public uploadedFile(): void {
    if (!this.uploadedSome) {
      this.uploadedSome = true;
    }
  }

  public finishedUpload(): void {
    this.evaluateAllowClose();
    if (!this.closeDisabled) {
      const erroredFiles: Array<IPlUploadFile> = this.callback.getErroredFiles();
      if (!erroredFiles.length) {
        this.close();
      }
    }
  }

  protected _onChangedConfigurations(): void {
    super._onChangedConfigurations();
    this.maxFiles = this.configurations.geral.contabilidadeDigital.maxUploadFilesAtATime ?? CG_DEFAULT_GERAL_CONTAB_DIGITAL_MAX_UPLOAD_FILES_AT_A_TIME;
    this.maxFileSize = this.configurations.empresa.erpcloud.ficheiros.maxFileSize ?? CG_DEFAULT_MAX_FILE_SIZE;
  }
}
