import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {AuthService} from '../../../services/auth/auth.service';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_EMPRESAS_ERP} from '../../../entities/empresas/empresas.entity.interface';
import {ENTITY_NAME_WS_SS_LOGIN, IWsSSLoginEntityService} from '../../../entities/sslogin/wsSSLogin.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IJsonEmpresaErp} from '../../../interfaces/jsonEmpresa.interface';
import {ILoginWsSS} from '../loginWsSS.module.interface';
import {isDefinedNotNull, isEmpty, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {TUserSession} from '../../../services/account/jsonUserApi.interface';

@Component({
  selector: 'login-ws-ss',
  templateUrl: './loginWsSS.modal.component.html'
})
export class ConfigLoginWsSSModalComponent extends CGModalComponent<ILoginWsSS> implements OnInit {
  @Input() public nEmpresa: string;

  public model: ILoginWsSS;
  public promise: Promise<void>;
  public isDisabledNissEmpresa: boolean;
  public showWarning: boolean;
  public warningTooltipText: string;

  private readonly _serviceWsSSLogin: IWsSSLoginEntityService;
  private readonly _empresaService: IEntityService<IJsonEmpresaErp>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _authService: AuthService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this._serviceWsSSLogin = this._entityServiceBuilder.build<ILoginWsSS, IWsSSLoginEntityService>(ENTITY_NAME_WS_SS_LOGIN);
    this._empresaService = this._entityServiceBuilder.build<IJsonEmpresaErp>(ENTITY_NAME_EMPRESAS_ERP);
    this.model = {nissEmpresa: '', passwordEmpresa: '', nissMandatario: '', passwordMandatario: ''};
    this.isDisabledNissEmpresa = false;
    this.showWarning = false;
    this.warningTooltipText = '';
  }

  public ngOnInit(): void {
    this.promise = (async () => {
      let loginNEmpresa = this.nEmpresa;
      if (isEmpty(loginNEmpresa)) {
        const session: TUserSession = await this._authService.identity();
        loginNEmpresa = session.erp.nEmpresa;
      }

      const [empresaResponse, loginResponse]: [HttpResponse<IJsonEmpresaErp>, HttpResponse<ILoginWsSS>] = await Promise.all([
        this._empresaService.get({id: loginNEmpresa}),
        this._serviceWsSSLogin.getFor(loginNEmpresa)
      ]);

      this.model.nissEmpresa = empresaResponse.body.nContribSegSocial;
      this.model.passwordEmpresa = loginResponse.body.passwordEmpresa ? loginResponse.body.passwordEmpresa : '';
      this.model.nissMandatario = loginResponse.body.nissMandatario ? loginResponse.body.nissMandatario : '';
      this.model.passwordMandatario = loginResponse.body.passwordMandatario ? loginResponse.body.passwordMandatario : '';

      if (!isEmpty(this.model.nissEmpresa)) {
        this.isDisabledNissEmpresa = true;
        this.showWarning = loginResponse.body.nissEmpresa !== '' && loginResponse.body.nissEmpresa !== this.model.nissEmpresa;
        if (this.showWarning) {
          this.warningTooltipText = this._plTranslateService.translate('loginWsSS.nissAutenticacaoDiferenteFichaEmpresa', {
            nissAuth: loginResponse.body.nissEmpresa,
            nissEmpresa: this.model.nissEmpresa
          });
        }
      } else {
        this.model.nissEmpresa = loginResponse.body.nissEmpresa || '';
      }
    })();
  }

  public close(): Promise<void> {
    if (!this.model.nissEmpresa) {
      this._plAlertService.error('loginWsSS.messages.errorNISSEmpresaEmpty');
      return Promise.resolve();
    }
    if (!this.model.passwordEmpresa) {
      this._plAlertService.error('loginWsSS.messages.errorPasswordEmpresaEmpty');
      return Promise.resolve();
    }

    if (isDefinedNotNull(this.model.nissMandatario) && this.model.nissMandatario.length && isDefinedNotNull(this.model.passwordMandatario) && !this.model.passwordMandatario.length) {
      this._plAlertService.error('loginWsSS.messages.errorPasswordMandatarioEmpty');
      return Promise.resolve();
    }

    this.promise = new Promise((resolve, reject) => {
      const p = this.nEmpresa !== '' ? this._serviceWsSSLogin.postFor(this.nEmpresa, this.model) : this._serviceWsSSLogin.post({body: this.model});
      p.then((response: HttpResponse<ILoginWsSS>) => {
        this.model.nissEmpresa = response.body.nissEmpresa;
        this.model.passwordEmpresa = response.body.passwordEmpresa;
        this.model.nissMandatario = response.body.nissMandatario;
        this.model.passwordMandatario = response.body.passwordMandatario;
        this._plAlertService.success('loginWsSS.messages.credentialsSaved');
        resolve();
        super.close(this.model);
      }).catch(reject);
    });

    return this.promise;
  }
}
