import {ConfigsErpLicencaModuleComponent} from './components/configs.licenca.module.component';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_CONFIGS_LICENCA} from './configs.licenca.module.interface';
import {ROLE} from '../../../../services/role.const';

export const MODULE_CONFIGS_LICENCA: IModuleDefinition = {
  name: MODULE_NAME_CONFIGS_LICENCA,
  state: {
    url: '/configs/licenca/configsErp/licenca',
    component: ConfigsErpLicencaModuleComponent,
    data: {
      roles: [ROLE.ADMIN],
      pageTitle: 'configsErp.items.licencaTitle'
    }
  }
};
