import {Component, Injector, Input, OnInit} from '@angular/core';
import {merge} from 'lodash-es';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {EEstadoConta} from '../../../../../../../datasources/estadoconta/estadoConta.datasource.interface';
import {IJsonRHAgenciasConta} from '../../../../jsonRHAgencias.module.interface';
import {RHAgenciasService} from '../../../../rhAgencias.module.service';

@Component({
  selector: 'modal-rhagencias-conta',
  templateUrl: './rhAgencias.conta.modal.component.html'
})
export class RHAgenciasContaModalComponent extends CGModalComponent<IJsonRHAgenciasConta> implements OnInit {
  @Input() public conta: Partial<IJsonRHAgenciasConta>;

  public model: IJsonRHAgenciasConta;
  public isUpdate: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhAgenciasService: RHAgenciasService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.model = {
      codBanco: undefined,
      codAgencia: undefined,
      nib: undefined,
      nContaPoc: undefined,
      gestorBanco: undefined,
      descricao: undefined,
      nProjecto: undefined,
      iban: undefined,
      swift: undefined,
      debitoDirectoID: undefined,
      nContaEmprestimos: undefined,
      codPais: undefined,
      status: EEstadoConta.Activa,
      validaIBAN: undefined,
      nomeProjecto: undefined,
      nomeNconta: undefined,
      nomeEmprestimos: undefined,
      nomePais: undefined,
      nomeBanco: undefined,
      nomeAgencia: undefined
    };
  }

  public ngOnInit(): void {
    this.model = merge<IJsonRHAgenciasConta, Partial<IJsonRHAgenciasConta>>(this.model, this.conta);
    this.isUpdate = !isEmpty(this.conta.iban);
  }

  public close(): Promise<void> {
    if (this.isUpdate) {
      return this._rhAgenciasService.updateConta(this.model.codBanco, this.model.codAgencia, this.model.iban, this.model).then(() => {
        this._plAlertService.success('rhagencias.messages.updateSuccConta');
        super.close(this.model);
      });
    }

    return this._rhAgenciasService.saveConta(this.model.codBanco, this.model.codAgencia, this.model).then(() => {
      this._plAlertService.success('rhagencias.messages.newSuccConta');
      super.close(this.model);
    });
  }
}
