import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {IJsonREMUNFX} from '../jsonRHRecolhaDados.entity.interface';

@Component({
  selector: 'modal-subrefeicao',
  templateUrl: './subRefeicao.modal.component.html'
})
export class SubRefeicaoModalComponent extends CGModalComponent<void> {
  @Input() public remun: IJsonREMUNFX;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }
}
