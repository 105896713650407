import {ROLE} from '../../services/role.const';
import {IEntityDefinition, IEntityDeleteMessage} from '../../components/entity/entity.definition.interface';
import {ENTITY_NAME_MORAL} from './morals.entity.interface';
import {IJsonMorAl} from './jsonMoral.entity.interface';
import {TranslateService} from '@ngx-translate/core';
import {MoralsEditComponent} from './component/edit/morals.entity.edit.component';
import {ENTITY_NAME_PAISES} from '../paises/paises.entity.interface';

export const ENTITY_MORAL: IEntityDefinition<IJsonMorAl> = {
  name: ENTITY_NAME_MORAL,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.ATIVOS],
  searchPlaceholder: 'morals.pesquisa',
  metadata: {
    keyName: 'moralID',
    fields: [
      {
        name: 'nConta',
        caption: 'morals.fields.nConta',
        validators: {required: true, maxlength: 75},
        entity: {name: 'clifos', keyTarget: 'nConta', outputTarget: 'nConta', output: 'nConta'}
      },
      {name: 'nome', caption: 'morals.fields.nome', validators: {required: true, maxlength: 25}},
      {name: 'rua', caption: 'morals.fields.rua', validators: {maxlength: 25}},
      {
        name: 'cPost',
        caption: 'morals.fields.cPost',
        entity: {name: 'codpostais', keyTarget: 'cPost'},
        properties: {validators: {maxlength: 20}}
      },
      {name: 'localidade', caption: 'morals.fields.localidade'},
      {name: 'codPais', visible: false},
      {
        name: 'nomePais',
        caption: 'morals.fields.codPais',
        entity: {name: ENTITY_NAME_PAISES, keyTarget: 'codPais', outputTarget: 'nomePais'}
      },
      {name: 'desativado', caption: 'morals.fields.desativado', type: 'boolean'},
      {name: 'contactoNome', caption: 'morals.fields.contactoNome'},
      {
        name: 'contactoTelefone',
        type: 'mask',
        caption: 'morals.fields.contactoTelefone',
        properties: {mask: '[\\+][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9][9]'},
        validators: {minlength: 9, maxlength: 20}
      }
    ],
    order: 'nConta',
    searchFields: 'nConta,nome'
  },
  autocomplete: {
    rowTemplate: '{{nConta}} - {{nome}}',
    output: 'nome',
    searchFields: 'nConta,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  detail: {
    state: {
      component: MoralsEditComponent,
      params: {
        nConta: {
          type: 'string',
          value: ''
        },
        nomeConta: {
          type: 'string',
          value: ''
        }
      }
    }
  },
  getDeleteMessage(model: IJsonMorAl, translate: TranslateService): IEntityDeleteMessage {
    return {
      title: translate.instant('morals.deleteModalText', {nome: model.nome}),
      message: translate.instant('entity.delete.message')
    };
  }
};
