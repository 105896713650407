import {Component, Injector, OnInit} from '@angular/core';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonMorAl} from '../../jsonMoral.entity.interface';
import {IMoralParams} from '../../morals.entity.interface';
import {IJsonCodPostal} from '../../../codpostais/jsonCodPostal.entity.interface';
import {isObject} from 'pl-comps-angular';
import {ExternalCodPostaisService} from '../../../../services/external.cod.postais.service';
import {IJsonCodPostalHelper} from '../../../../interfaces/jsonCodPostalHelper.interface';

@Component({
  selector: 'morals-edit',
  templateUrl: './morals.entity.edit.component.html'
})
export class MoralsEditComponent extends ModuloEntityDetailComponent<IJsonMorAl> implements OnInit {
  public clifosOutput: string;
  public paisOutput: string;
  public nContaReadonlyOnNew: boolean;

  private readonly _moralParams: IMoralParams;

  constructor(
    protected readonly _injector: Injector,
    private readonly _externalCodPostaisService: ExternalCodPostaisService
  ) {
    super(_injector);
    this.clifosOutput = '{{nConta}} - {{nome}}';
    this.paisOutput = '{{codPais}} - {{nomePais}}';
    this._moralParams = <IMoralParams>this._transition.params();
    this.nContaReadonlyOnNew = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.type === this.stateTypes.NEW) {
      if (this._moralParams?.nConta !== '') {
        this.model.nConta = this._moralParams.nConta;
        this.model.nomeConta = this._moralParams.nomeConta;
        this.nContaReadonlyOnNew = true;
      }
    }
  }

  public codPostalChanged(event: IJsonCodPostal | string): void | Promise<void> {
    if (!event) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    const codPostal: string = isObject(event) ? (<IJsonCodPostal>event).codPostal : String(event);
    this.model.cPost = codPostal;

    if (isObject(event) && !this.model.codPais) {
      this.model.codPais = (<IJsonCodPostal>event).codPais;
      this.model.nomePais = (<IJsonCodPostal>event).nomePais;
    }

    setTimeout(() => {
      this._externalCodPostaisService.get(codPostal).then((response: Array<IJsonCodPostalHelper>) => {
        if (!response?.length) {
          return;
        }
        const helperCodPostal = response[0];
        if (helperCodPostal.rua && !this.model.rua) {
          this.model.rua = helperCodPostal.rua;
        }
        if (helperCodPostal.designacao) {
          this.model.localidade = helperCodPostal.designacao;
        }
      });
    });
  }
}
