import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonDataValor} from './jsonDataValor.entity.interface';
import {ENTITY_NAME_DATA_VALOR} from './dataValor.entity.interface';

export const ENTITY_DATA_VALOR: IEntityDefinition<IJsonDataValor> = {
  name: ENTITY_NAME_DATA_VALOR,
  roles: [ROLE.ATIVOS],
  searchPlaceholder: 'datavalor.pesquisa',
  metadata: {
    keyName: 'dataValorID',
    fields: [
      {name: 'dataValorID', visible: false},
      {name: 'tipo', type: 'integer', caption: 'datavalor.fields.tipo', placeholder: 'datavalor.fields.tipoPlaceholder'},
      {
        name: 'keyValue',
        caption: 'datavalor.fields.keyValue',
        placeholder: 'datavalor.fields.keyValuePlaceholder',
        validators: {required: true, maxlength: 20}
      },
      {name: 'valor1', type: 'number', caption: 'datavalor.fields.valor1', placeholder: 'datavalor.fields.valor1'},
      {name: 'valor2', type: 'number', caption: 'datavalor.fields.valor2', placeholder: 'datavalor.fields.valor2'},
      {name: 'valor3', type: 'number', caption: 'datavalor.fields.valor3', placeholder: 'datavalor.fields.valor3'},
      {name: 'dataInicio', type: 'date', caption: 'datavalor.fields.dataInicio', placeholder: 'datavalor.fields.dataInicio'}
    ],
    order: 'keyValue'
  },
  autocomplete: {
    rowTemplate: '{{dataValorID}} - {{keyValue}}',
    output: 'keyValue',
    searchFields: 'keyValue'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true
  },
  detail: {
    template: {
      items: [
        {order: 1, field: 'dataInicio'},
        {order: 2, field: 'valor1'},
        {order: 3, field: 'valor2'},
        {order: 4, field: 'valor3'}
      ],
      addMissingFields: false
    }
  }
};
