import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {IJsonHealth} from './health.service.interface';
import {IApiRequestConfig, TServiceResponse} from '../api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class HealthService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/health`;
  }

  public live(config?: IApiRequestConfig): TServiceResponse<IJsonHealth> {
    return this._apiService.get<IJsonHealth>({url: `${this._path}/live`, ...config});
  }
}
