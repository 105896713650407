import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {ERhTransSalTab} from '../../rhSalToTrf.module.interface';
import {TranslateService} from '@ngx-translate/core';
import {RHSalToTrfService} from '../../rhSalToTrf.module.service';
import {IRHSalToTrfHeader} from '../../../rhfolfer/rhFolfer.module.interface';
import {copy, isDefinedNotNull, PlAlertService} from 'pl-comps-angular';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';
import {EReport} from '../../../../../entities/reports/reports.interface';

const REPORT_NAME_LBANC_TICKET = 'LBANCTicket.FR3';

@Component({
  selector: 'modal-rhsaltotrf-imprimir',
  templateUrl: './rhSalToTrf.imprimir.modal.component.html'
})
export class RhSalToTrfImprimirModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public selectedTab: ERhTransSalTab;
  @Input() public filters: IRHSalToTrfHeader;
  @Input() public tabVencimento: boolean;
  @Input() public searched: boolean;

  public readonly rowTemplateReport: string;
  public readonly outputReport: string;
  public report: IJsonReport;
  public pdfUrl: string;
  public showTitleWithTab: boolean;
  public titleWithTab: string;
  public validResportsSource: Array<IJsonReport>;

  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _rhSalToTrfService: RHSalToTrfService,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.preVisualizar = this.preVisualizar.bind(this);
    this.pdfUrl = '';
    this.validResportsSource = [];
    this.rowTemplateReport = '{{name}} - {{title}}';
    this.outputReport = 'title';
    this.showTitleWithTab = false;
  }

  public async ngOnInit(): Promise<void> {
    await this._loadDefaultReport();
    this.titleWithTab = this._translateService.instant('rhsaltotrf.imprimir.titleWithTab', {activeTab: this._translateService.instant(`rhsaltotrf.tabs.${this.selectedTab}`)});
    if (isDefinedNotNull(this.selectedTab)) {
      this.showTitleWithTab = true;
    }
    if (this.searched) {
      this._cardPanel.collapse();
    }
  }

  public reportChange(reportItem: IJsonReport): void {
    this.report = reportItem;
    this._preVisualizarReport();
  }

  public preVisualizar(): void {
    this._preVisualizarReport();
    if (!this._cardPanel.collapsed) {
      this._cardPanel.collapse();
    }
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private async _loadDefaultReport(): Promise<void> {
    const reports: Array<IJsonReport> = await this._reportsRegistryService.get(EReport.LBanc).query();
    this._evaluateValidReports(reports);
    if (this.validResportsSource.length) {
      this.report = this.validResportsSource[0];
      this.reportChange(this.report);
    }
  }

  private _preVisualizarReport(): void {
    this._rhSalToTrfService.getReport(this.filters, this.report.name).subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }

  private _evaluateValidReports(reports: Array<IJsonReport>): void {
    if (!reports.length) {
      this._plAlertService.error('reports.messages.noReports');
    }
    const validTicketReports: Array<IJsonReport> = reports.filter((report: IJsonReport) => report.name === REPORT_NAME_LBANC_TICKET);
    const validReports: Array<IJsonReport> =
      this.selectedTab === ERhTransSalTab.RhTransBancariaTicketsRefeicao ? validTicketReports : reports.filter((report: IJsonReport) => !validTicketReports.includes(report));
    this.validResportsSource = copy(validReports);
  }
}
