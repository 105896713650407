<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="modalTitle"></h4>
</div>

<div class="modal-body">
  <pl-form>
    <div class="p-2 bg-primary text-white text-center" style="margin: -1rem -1rem 1rem -1rem" [translate]="empresaTitle"></div>
    <div class="row">
      <div class="col-md-4">
        <pl-group>
          <label [translate]="'global.text.year'"></label>
          <edit>
            <pl-select [(model)]="model.ano" [source]="anosSource" (modelChange)="onYearChange($event)" [clearable]="false"></pl-select>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-8">
        <pl-group>
          <label [translate]="'gto.nomeTarefa'"></label>
          <edit>
            <pl-edit type="text" [(model)]="model.nomeTarefa" [properties]="{validators: {maxlength: {value: 20}}}"></pl-edit>
          </edit>
        </pl-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <pl-group>
          <label [translate]="'gto.tipoTarefa'"></label>
          <edit>
            <pl-edit type="select" [properties]="{select: {list: tipoTarefaSource}, disallowClear: true}" [(model)]="model.tipoTarefa"></pl-edit>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-6">
        <pl-group>
          <label [translate]="'gto.dataInicioPeriodo'"></label>
          <edit>
            <pl-edit type="select" [properties]="{select: {list: dataIniPerioSource}, disallowClear: true}" [(model)]="model.dataIniPerio"></pl-edit>
          </edit>
        </pl-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <pl-group>
          <label>{{ monthNames[months.January] }}</label>
          <edit>
            <pl-autocomplete
              [(model)]="monthsModel.m1.selectedDay"
              [rowTemplate]="monthRowTemplate"
              [output]="monthOutput"
              [source]="monthsModel.m1.source"
              [properties]="monthsModel.m1.properties"></pl-autocomplete>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label>{{ monthNames[months.February] }}</label>
          <edit>
            <pl-autocomplete
              [(model)]="monthsModel.m2.selectedDay"
              [rowTemplate]="monthRowTemplate"
              [output]="monthOutput"
              [source]="monthsModel.m2.source"
              [properties]="monthsModel.m2.properties"></pl-autocomplete>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label>{{ monthNames[months.March] }}</label>
          <edit>
            <pl-autocomplete
              [(model)]="monthsModel.m3.selectedDay"
              [rowTemplate]="monthRowTemplate"
              [output]="monthOutput"
              [source]="monthsModel.m3.source"
              [properties]="monthsModel.m3.properties"></pl-autocomplete>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label>{{ monthNames[months.April] }}</label>
          <edit>
            <pl-autocomplete
              [(model)]="monthsModel.m4.selectedDay"
              [rowTemplate]="monthRowTemplate"
              [output]="monthOutput"
              [source]="monthsModel.m4.source"
              [properties]="monthsModel.m4.properties"></pl-autocomplete>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label>{{ monthNames[months.May] }}</label>
          <edit>
            <pl-autocomplete
              [(model)]="monthsModel.m5.selectedDay"
              [rowTemplate]="monthRowTemplate"
              [output]="monthOutput"
              [source]="monthsModel.m5.source"
              [properties]="monthsModel.m5.properties"></pl-autocomplete>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label>{{ monthNames[months.June] }}</label>
          <edit>
            <pl-autocomplete
              [(model)]="monthsModel.m6.selectedDay"
              [rowTemplate]="monthRowTemplate"
              [output]="monthOutput"
              [source]="monthsModel.m6.source"
              [properties]="monthsModel.m6.properties"></pl-autocomplete>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label>{{ monthNames[months.July] }}</label>
          <edit>
            <pl-autocomplete
              [(model)]="monthsModel.m7.selectedDay"
              [rowTemplate]="monthRowTemplate"
              [output]="monthOutput"
              [source]="monthsModel.m7.source"
              [properties]="monthsModel.m7.properties"></pl-autocomplete>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label>{{ monthNames[months.August] }}</label>
          <edit>
            <pl-autocomplete
              [(model)]="monthsModel.m8.selectedDay"
              [rowTemplate]="monthRowTemplate"
              [output]="monthOutput"
              [source]="monthsModel.m8.source"
              [properties]="monthsModel.m8.properties"></pl-autocomplete>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label>{{ monthNames[months.September] }}</label>
          <edit>
            <pl-autocomplete
              [(model)]="monthsModel.m9.selectedDay"
              [rowTemplate]="monthRowTemplate"
              [output]="monthOutput"
              [source]="monthsModel.m9.source"
              [properties]="monthsModel.m9.properties"></pl-autocomplete>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label>{{ monthNames[months.October] }}</label>
          <edit>
            <pl-autocomplete
              [(model)]="monthsModel.m10.selectedDay"
              [rowTemplate]="monthRowTemplate"
              [output]="monthOutput"
              [source]="monthsModel.m10.source"
              [properties]="monthsModel.m10.properties"></pl-autocomplete>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label>{{ monthNames[months.November] }}</label>
          <edit>
            <pl-autocomplete
              [(model)]="monthsModel.m11.selectedDay"
              [rowTemplate]="monthRowTemplate"
              [output]="monthOutput"
              [source]="monthsModel.m11.source"
              [properties]="monthsModel.m11.properties"></pl-autocomplete>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-3">
        <pl-group>
          <label>{{ monthNames[months.December] }}</label>
          <edit>
            <pl-autocomplete
              [(model)]="monthsModel.m12.selectedDay"
              [rowTemplate]="monthRowTemplate"
              [output]="monthOutput"
              [source]="monthsModel.m12.source"
              [properties]="monthsModel.m12.properties"></pl-autocomplete>
          </edit>
        </pl-group>
      </div>
    </div>
    <div class="col-md-12" *ngIf="!storeModePublic && mode === modes.EDIT">
      <div class="form-group">
        <pl-edit type="checkbox" [(model)]="model.applyAllEmpresas" [properties]="{label: 'gto.applyAllEmpresas'}"></pl-edit>
      </div>
    </div>
  </pl-form>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-danger float-start" [click]="fnDeleteTask" *ngIf="mode === modes.EDIT && !model.isSystem" plPromise>
    <i class="fa fa-fw fa-trash-o"></i>&nbsp;<span [translate]="'global.btn.delete'"></span>
  </button>
  <button type="button" class="btn btn-sm btn-primary" [click]="fnCloseModal" plPromise><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></button>
  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
