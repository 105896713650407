import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {Component, Injector, Input} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {IPlDynamicVisualsSecondaryClickAction} from 'pl-comps-angular';
import {BancosExtratoService} from '../../service/bancosExtrato.module.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {DATA_SOURCE_NAME_DEBITO_CREDITO} from '../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {DevExpressDataGridUIService} from '../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {IBancosExtratoDocSemelhante} from '../../bancosExtrato.module.interface';
import {
  IDevExpressDataGridEventOnCellDblClick,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnFocusedRowChanged,
  IDevExpressDataGridEventOnInitialized
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonBancosExtratoDocSemelhante} from '../../jsonBancosExtrato.module.interface';
import {IJsonEntidadeBancariaTransaction} from '../../../../interfaces/jsonEntidadeBancaria.interface';

const DATA_GRID_INSTANCE_NAME = 'bancosextratomoduledocssemelhantesmodal';

@Component({
  selector: 'bancos-extrato-module-docs-semelhantes-modal',
  templateUrl: './bancosExtrato.docssemelhantes.modal.component.html'
})
export class BancosExtratoModuleDocsSemelhantesModalComponent extends CGModalComponent<void> {
  @Input() public transaction: IJsonEntidadeBancariaTransaction;

  public readonly dataGrid: IDevExpressDataGrid;
  public readonly dataGridInstanceName: string;
  public mostrarOutrasDatas: boolean;
  public focusedDocSemelhante: IJsonBancosExtratoDocSemelhante;

  private _dataGridInstance: dxDataGrid<IBancosExtratoDocSemelhante, string>;
  private _maintenanceDocsContabilidade: IEntityMaintenanceInstance;
  private _associouDocSemelhante: boolean;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _translateService: TranslateService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _bancosExtratoService: BancosExtratoService
  ) {
    super(_injector);
    this.dataGrid = {
      columns: [
        {dataField: 'periodo', dataType: 'string', caption: 'bancosextrato.docssemelhantesmodal.fields.periodo'},
        {dataField: 'diario', dataType: 'string', caption: 'bancosextrato.docssemelhantesmodal.fields.diario'},
        {dataField: 'nDocInterno', dataType: 'string', caption: 'bancosextrato.docssemelhantesmodal.fields.nDocInterno'},
        {dataField: 'dataLancamento', dataType: 'date', caption: 'bancosextrato.docssemelhantesmodal.fields.dataLancamento'},
        {dataField: 'dataDocExterno', dataType: 'date', caption: 'bancosextrato.docssemelhantesmodal.fields.dataDocExterno'},
        {dataField: 'dc', dataType: 'number', caption: 'bancosextrato.docssemelhantesmodal.fields.dc', lookup: {cgDataSource: DATA_SOURCE_NAME_DEBITO_CREDITO}},
        {dataField: 'valor', dataType: 'number', caption: 'bancosextrato.docssemelhantesmodal.fields.valor'},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'bancosextrato.docssemelhantesmodal.fields.nDocExterno'},
        {dataField: 'descricao', dataType: 'string', caption: 'bancosextrato.docssemelhantesmodal.fields.descricao'},
        {dataField: 'contaCorrente', dataType: 'string', caption: 'bancosextrato.docssemelhantesmodal.fields.contaCorrente'},
        {
          name: 'actions',
          cellTemplate: 'dataGridTemplateActions',
          alignment: 'center',
          width: 48,
          allowExporting: false,
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSorting: false,
          showInColumnChooser: false
        }
      ],
      dataSource: new CustomStore<IBancosExtratoDocSemelhante, string>({
        key: 'extPocCabID',
        load: this._onLoadDataGrid.bind(this)
      }),
      columnHidingEnabled: false,
      focusedRowEnabled: true
    };
    this.dataGridInstanceName = DATA_GRID_INSTANCE_NAME;
    this.mostrarOutrasDatas = false;
  }

  public close(): void {
    if (this._associouDocSemelhante) {
      super.close();
    } else {
      super.dismiss();
    }
  }

  public dismiss(reason?: unknown): void {
    if (this._associouDocSemelhante) {
      super.close();
    } else {
      super.dismiss(reason);
    }
  }

  public changedMostrarOutrasDatas(value: boolean): void {
    this.mostrarOutrasDatas = value;
    this._dataGridInstance.refresh().catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized<IBancosExtratoDocSemelhante, string>): void {
    this._dataGridInstance = component;
  }

  public onDataGridFocusedRowChanged(event: IDevExpressDataGridEventOnFocusedRowChanged<IBancosExtratoDocSemelhante, string>): void {
    this.focusedDocSemelhante = event.row.rowType === 'data' ? event.row.data : undefined;
  }

  public onDataGridContextMenuPreparing({target, event, row}: IDevExpressDataGridEventOnContextMenuPreparing<IBancosExtratoDocSemelhante, string>): void {
    if (target !== 'content' || !row || row.rowType !== 'data') {
      return;
    }
    event.preventDefault();
    const actions: Array<IPlDynamicVisualsSecondaryClickAction> = [{caption: 'bancosextrato.actions.viewDocument', click: () => this._openDoc(row.data.extPocCabID)}];
    this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.target, actions).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public onDataGridCellDblClick(event: IDevExpressDataGridEventOnCellDblClick<IBancosExtratoDocSemelhante, string>): void {
    if (event.rowType !== 'data' || event.column.type || !this.focusedDocSemelhante) {
      return;
    }
    const docSemelhante: IBancosExtratoDocSemelhante = event.row.data;
    this._openDoc(docSemelhante.extPocCabID);
  }

  public readonly fnDataGridOpenDoc = (transaction: IJsonEntidadeBancariaTransaction) => (): Promise<void> => this._openDoc(transaction.extPocCabID);

  public readonly fnAssociarDocSemelhante = (): Promise<void> => this._associarDocSemelhante();

  private _onLoadDataGrid(): Promise<Array<IBancosExtratoDocSemelhante>> {
    return this._bancosExtratoService.getDocsSemelhantesJaLancados(this.transaction, !this.mostrarOutrasDatas).then((response: HttpResponse<Array<IJsonBancosExtratoDocSemelhante>>) => {
      return response.body.map<IBancosExtratoDocSemelhante>((docSemelhante: IJsonBancosExtratoDocSemelhante) => {
        return {
          ...docSemelhante,
          diario: docSemelhante.nDiario ? `${docSemelhante.nDiario} - ${docSemelhante.nomeDiario}` : undefined
        };
      });
    });
  }

  private _openDoc(extPocCabID: string): Promise<void> {
    if (!this._maintenanceDocsContabilidade) {
      this._maintenanceDocsContabilidade = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    }
    return this._maintenanceDocsContabilidade
      .maintenanceEdit(extPocCabID)
      .then(() => undefined)
      .catch((reason: unknown) => {
        this._logger.error(reason);
      });
  }

  private _associarDocSemelhante(): Promise<void> {
    if (!this.focusedDocSemelhante) {
      return Promise.resolve();
    }
    this.disableClose();
    return this._bancosExtratoService
      .associarDocSemelhante(this.focusedDocSemelhante.nLanc, this.transaction)
      .then(() => {
        this.enableClose();
        if (!this._associouDocSemelhante) {
          this._associouDocSemelhante = true;
        }
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }
}
