import {Component} from '@angular/core';
import {IDevExpressDataGridColumn, IDevExpressDataGridSummary} from '../../../devexpress/datagrid/devexpress.datagrid.interface';
import {MovimentosPendentesBaseComponent} from '../movimentos.pendentes.base.component';
import type dxDataGrid from 'devextreme/ui/data_grid';

@Component({
  selector: 'movimentos-pendentes-fornecedores',
  templateUrl: './movimentos.pendentes.fornecedores.component.html'
})
export class MovimentosPendentesFornecedoresComponent extends MovimentosPendentesBaseComponent {
  public evtDataGridInstanceEmit(dataGridInstance: dxDataGrid): void {
    this.evtDataGridInstance.emit(dataGridInstance);
  }

  protected _buildDefinition(): Array<IDevExpressDataGridColumn> {
    return [
      {dataField: 'nConta', dataType: 'string', caption: 'movimentospendentes.fornecedores.nConta'},
      {dataField: 'nome', dataType: 'string', caption: 'movimentospendentes.fornecedores.nome'},
      {dataField: 'tipoDocumento', dataType: 'string', caption: 'movimentospendentes.fornecedores.tipoDocumento'},
      {dataField: 'numeroDocumento', dataType: 'string', caption: 'movimentospendentes.fornecedores.numeroDocumento'},
      {dataField: 'dataDoc', dataType: 'date', caption: 'movimentospendentes.fornecedores.dataDoc', visible: false},
      {dataField: 'dataVencimento', dataType: 'date', caption: 'movimentospendentes.fornecedores.dataVencimento'},
      {dataField: 'valor', dataType: 'double', alignment: 'right', caption: 'movimentospendentes.fornecedores.valor'},
      {dataField: 'valorRecebido', dataType: 'double', alignment: 'right', caption: 'movimentospendentes.fornecedores.valorRecebido'},
      {dataField: 'valorPorReceber', dataType: 'double', alignment: 'right', caption: 'movimentospendentes.fornecedores.valorPorReceber'},
      {dataField: 'nif', dataType: 'string', caption: 'movimentospendentes.fornecedores.nif'},
      {dataField: 'nDocExterno', dataType: 'string', caption: 'movimentospendentes.fornecedores.nDocExterno', visible: false}
    ];
  }

  protected _buildGridSummary(): IDevExpressDataGridSummary {
    return {
      totalItems: [
        {column: 'valor', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
        {column: 'valorRecebido', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
        {column: 'valorPorReceber', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}
      ]
    };
  }

  protected _listFields(): string {
    return 'nome,nif,tipoDocumento,numeroDocumento,dataDoc,dataVencimento,valor,valorRecebido,valorPorReceber,nDocExterno';
  }
}
