import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_AREAS_REGIONAIS, EAreasRegionais} from './areasRegionais.datasource.interface';

export const DATA_SOURCE_AREAS_REGIONAIS: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_AREAS_REGIONAIS,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EAreasRegionais.Continente, name: 'areasRegionais.data.continente'},
    {value: EAreasRegionais.Madeira, name: 'areasRegionais.data.madeira'},
    {value: EAreasRegionais.Acores, name: 'areasRegionais.data.acores'}
  ]
});
