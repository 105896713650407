import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IRHRelatorioUnicoStatus} from './rhRelatorioUnico.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonRHRelatorioUnicoConfig} from './jsonRHRelatorioUnico.module.interface';

@Injectable({
  providedIn: 'root'
})
export class RHRelatorioUnicoService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${_apiService.path.restapi}/rhrelatoriounico`;
  }

  public downloadFile(): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({url: `${this._path}/download`, responseType: 'blob'});
  }

  public getStatus(): TServiceResponse<IRHRelatorioUnicoStatus> {
    return this._apiService.get<IRHRelatorioUnicoStatus>({url: `${this._path}/status`});
  }

  public getAnoProcessamento(): TServiceResponse<number> {
    return this._apiService.get<number>({url: `${this._path}/anoprocessamento`});
  }

  public getRelatorioUnicoConfig(): TServiceResponse<IJsonRHRelatorioUnicoConfig> {
    return this._apiService.get<IJsonRHRelatorioUnicoConfig>({url: `${this._path}/config`});
  }

  public processar(): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/processar`,
      params: {}
    });
  }

  public cancelProcess(): TServiceResponse<void> {
    return this._apiService.post({url: this.cancelProcessUrl()});
  }

  public cancelProcessUrl(): string {
    return `${this._path}/stop-ms`;
  }

  public saveRelatorioUnicoConfig(config: IJsonRHRelatorioUnicoConfig): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/config`,
      body: config.estabelecimentos,
      params: {
        identidade: config.idEntidade,
        codnatjuridica: config?.codNatJuridica,
        nomenatjuridica: config?.nomeNatJuridica,
        codassocpatronal: config?.codAssocPatronal,
        nomeassocpatronal: config?.nomeAssocPatronal,
        codddccff: config?.codddccff,
        nomecodddccff: config?.nomeCodddccff
      }
    });
  }
}
