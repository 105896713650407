import {IJsonConfigERP} from '../../../entities/configserp/jsonConfigERP.entity.interface';
import {IJsonErp, IJsonRole} from '../../../interfaces/jsonUserManager.interface';
import {IJsonPortal} from '../../../entities/portal/jsonPortal.entity.interface';

export interface IUserInstallationsData {
  installations: Array<IJsonErp>;
  roles: Array<IJsonRole>;
  portals: Array<IJsonPortal>;
  configsErp: Array<IJsonConfigERP>;
}

export interface IUserInstallationsCallback {
  fromModelToInstallations?(): void;

  fromInstallationsToModel?(): void;

  reset?(): void;
}

export const USER_INSTALLATIONS_PORTAL_GLOBAL = '$P0';

export const USER_INSTALLATIONS_COMPANY_GLOBAL = '000';
