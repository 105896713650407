import {Subscription} from 'rxjs';
import dxChart, {InitializedEvent, LegendClickEvent, PointHoverChangedEvent} from 'devextreme/viz/chart';
import dxPieChart, {InitializedEvent as PieChartInitializedEvent} from 'devextreme/viz/pie_chart';
import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EPlDashboardType, IPlDashboard, PlI18nService} from 'pl-comps-angular';
import {chartCombineSeriesTooltip, chartLabelWithPercent, chartToggleSeriesVisibility} from '../../../../components/devexpress/charts/utilities/devexpress.chart.utilities';
import {
  EChartType,
  IDashboardChartGastosCompras,
  IDashboardChartRendimentos,
  IDashboardChartTesourariaLiquida,
  IDashboardChartVendas,
  IJsonDashboard,
  IJsonDashboardData,
  IJsonDashboardPieData,
  IJsonPieDashboard
} from '../dashBoards.module.interface';
import {IDevExpressChart, IDevExpressChartLabelCustomizeText} from '../../../../components/devexpress/charts/devexpress.charts.interface';
import {IDevExpressPieChart} from '../../../../components/devexpress/charts/pie/devexpress.pie.chart.interface';
import {IPcaContabilidadeServiceRefreshedEvent} from '../../contabilidade/pca.contabilidade.module.service.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {PcaContabilidadeService} from '../../contabilidade/pca.contabilidade.module.service';
import {PortalClientAccountService} from '../../../../services/portalclientaccount/portalclientaccount.service';

const TIPO = 2;
const PRIMARY_COLOR = '#62A8EA';
const GREEN_COLOR = '#46BE8A';
const FONT_FAMILY = 'Nunito Sans';

@Component({
  selector: 'pca-dashboards',
  templateUrl: './dashBoards.module.component.html'
})
export class PCADashboardsComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly chartVendas: IDevExpressChart<'BarSeries'>;
  public readonly chartTesourariaLiquida: IDevExpressChart<'BarSeries'>;
  public readonly chartRendimentos: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly chartGastosCompras: IDevExpressPieChart<'DoughnutSeries'>;
  public vendas: Array<IDashboardChartVendas>;
  public tesourariaLiquida: Array<IDashboardChartTesourariaLiquida>;
  public rendimentos: Array<IDashboardChartRendimentos>;
  public gastosCompras: Array<IDashboardChartGastosCompras>;
  public rendimentosTable: Promise<IPlDashboard<EPlDashboardType.Table>>;
  public gastosComprasTable: Promise<IPlDashboard<EPlDashboardType.Table>>;

  private readonly _subscriptionOnRefresh: Subscription;
  private _chartVendasInstance: dxChart;
  private _chartTesourariaLiquidaInstance: dxChart;
  private _chartRendimentosInstance: dxPieChart;
  private _chartGastosComprasInstance: dxPieChart;
  private _ano: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _pcaContabilidadeService: PcaContabilidadeService,
    private readonly _portalClientAccountService: PortalClientAccountService,
    private readonly _plI18nService: PlI18nService
  ) {
    super(_injector);
    this.chartVendas = {
      commonSeriesSettings: {
        argumentField: 'month',
        type: 'bar',
        ignoreEmptyPoints: true
      },
      title: {text: this._translateService.instant('dashboards.erp.pageTitle.vendas'), font: {size: 18, weight: 800, family: FONT_FAMILY}, horizontalAlignment: 'center'},
      series: [{valueField: 'valueVenda', color: PRIMARY_COLOR, name: this._translateService.instant('dashboards.erp.table.legends.vendasAno')}],
      tooltip: {
        enabled: true,
        location: 'edge',
        paddingLeftRight: 15,
        paddingTopBottom: 10,
        arrowLength: 10,
        contentTemplate: 'customTooltip'
      }
    };
    this.chartTesourariaLiquida = {
      commonSeriesSettings: {
        argumentField: 'month',
        type: 'bar',
        ignoreEmptyPoints: true,
        hoverMode: 'allArgumentPoints'
      },
      title: {text: this._translateService.instant('dashboards.erp.pageTitle.tesourariaLiquida'), font: {size: 18, weight: 800, family: FONT_FAMILY}, horizontalAlignment: 'center'},
      series: [
        {valueField: 'valueDisponibilidade', color: PRIMARY_COLOR, name: this._translateService.instant('dashboards.erp.table.legends.tesourariaLiquidaDisponibilidade')},
        {valueField: 'valueFinanciamento', color: GREEN_COLOR, name: this._translateService.instant('dashboards.erp.table.legends.tesourariaLiquidaFinanciamento')}
      ],
      tooltip: {
        location: 'edge',
        paddingLeftRight: 15,
        paddingTopBottom: 10,
        contentTemplate: 'customTooltip',
        shared: true
      },
      stickyHovering: true
    };
    this.chartRendimentos = {
      type: 'doughnut',
      title: {
        text: this._translateService.instant('dashboards.erp.pageTitle.rendimentos')
      },
      series: [
        {
          argumentField: 'name',
          valueField: 'value',
          label: {
            visible: true,
            customizeText: this.customizeLabelRendimentosAndGastosFn,
            connector: {
              visible: true
            }
          }
        }
      ]
    };
    this.chartGastosCompras = {
      type: 'doughnut',
      title: {
        text: this._translateService.instant('dashboards.erp.pageTitle.gastosCompras')
      },
      series: [
        {
          argumentField: 'name',
          valueField: 'value',
          label: {
            visible: true,
            customizeText: this.customizeLabelRendimentosAndGastosFn,
            connector: {
              visible: true
            }
          }
        }
      ]
    };
    this.vendas = [];
    this.tesourariaLiquida = [];
    this.rendimentos = [];
    this.gastosCompras = [];
    this._subscriptionOnRefresh = this._pcaContabilidadeService.onRefresh().subscribe((event: IPcaContabilidadeServiceRefreshedEvent) => {
      this._loadData(event.ano.ano, false);
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._pcaContabilidadeService.hideDropdownPeriodos();
    this._pcaContabilidadeService.init(this.toolbar).then(() => {
      this.btnRefresh.visible = true;
      this.btnRefresh.click = () => {
        if (!this._ano) {
          return Promise.resolve();
        }
        return this._loadData(this._ano, true);
      };
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionOnRefresh.unsubscribe();
  }

  public onInitializedChartVendas(event: InitializedEvent): void {
    this._chartVendasInstance = event.component;
    this._chartVendasInstance.showLoadingIndicator();
  }

  public onInitializedChartTesourariaLiquida(event: InitializedEvent): void {
    this._chartTesourariaLiquidaInstance = event.component;
    this._chartTesourariaLiquidaInstance.showLoadingIndicator();
  }

  public onInitializedChartRendimentos(event: PieChartInitializedEvent): void {
    this._chartRendimentosInstance = event.component;
    this._chartRendimentosInstance.showLoadingIndicator();
  }

  public onInitializedChartGastosCompras(event: PieChartInitializedEvent): void {
    this._chartGastosComprasInstance = event.component;
    this._chartGastosComprasInstance.showLoadingIndicator();
  }

  public onLegendClickChartTesourariaLiquida(event: LegendClickEvent): void {
    chartToggleSeriesVisibility(event);
  }

  public onPointHoverChangedChartTesourariaLiquida(event: PointHoverChangedEvent): void {
    chartCombineSeriesTooltip(event);
  }

  public readonly customizeLabelRendimentosAndGastosFn = (pointInfo: IDevExpressChartLabelCustomizeText): string => chartLabelWithPercent(pointInfo, this._plI18nService, true);

  private _loadData(ano: number, force: boolean): Promise<void> {
    this._ano = ano;

    const promises: Array<Promise<unknown>> = [];

    this._chartVendasInstance.showLoadingIndicator();
    promises.push(
      this._portalClientAccountService.getDashboard<'VendasServicos'>('VendasServicos', TIPO, ano, force).then((response: HttpResponse<IJsonDashboard>) => {
        if (this.destroyed) {
          return;
        }
        this._formatChartDataArray(EChartType.Vendas, response.body.data);
        this._chartVendasInstance.option('title.text', response.body.title);
        this._chartVendasInstance.hideLoadingIndicator();
      })
    );

    this._chartRendimentosInstance.showLoadingIndicator();
    this.rendimentosTable = <Promise<IPlDashboard<EPlDashboardType.Table>>>this._portalClientAccountService
      .getDashboard<'Rendimentos'>('Rendimentos', TIPO, ano, force)
      .then((response: HttpResponse<IJsonPieDashboard>) => {
        if (!this.destroyed) {
          this._formatPieChartData(EChartType.Rendimentos, response.body.data);
          this._chartRendimentosInstance.option('title.text', response.body.title);
        }
        return {
          type: EPlDashboardType.Table,
          title: response.body.title,
          data: response.body.data
        };
      })
      .finally(() => {
        if (!this.destroyed) {
          this._chartRendimentosInstance.hideLoadingIndicator();
        }
      });
    promises.push(this.rendimentosTable);

    this._chartTesourariaLiquidaInstance.showLoadingIndicator();
    promises.push(
      this._portalClientAccountService.getDashboard<'TesourariaLiquida'>('TesourariaLiquida', TIPO, ano, force).then((response: HttpResponse<IJsonDashboard>) => {
        if (this.destroyed) {
          return;
        }
        this._formatChartDataArray(EChartType.TesourariaLiquida, response.body.data);
        this._chartTesourariaLiquidaInstance.option('title.text', response.body.title);
        this._chartTesourariaLiquidaInstance.hideLoadingIndicator();
      })
    );

    this._chartGastosComprasInstance.showLoadingIndicator();
    this.gastosComprasTable = <Promise<IPlDashboard<EPlDashboardType.Table>>>this._portalClientAccountService
      .getDashboard<'GastosCompras'>('GastosCompras', TIPO, ano, force)
      .then((response: HttpResponse<IJsonPieDashboard>) => {
        if (!this.destroyed) {
          this._formatPieChartData(EChartType.GastosCompras, response.body.data);
          this._chartGastosComprasInstance.option('title.text', response.body.title);
        }

        return {
          type: EPlDashboardType.Table,
          title: response.body.title,
          data: response.body.data
        };
      })
      .finally(() => {
        this._chartGastosComprasInstance.hideLoadingIndicator();
      });
    promises.push(this.gastosComprasTable);

    return Promise.all(promises).then(() => undefined);
  }

  private _formatPieChartData(type: EChartType, data: Array<IJsonDashboardPieData>): void {
    if (type === EChartType.Rendimentos) {
      this.rendimentos = data.map<IDashboardChartRendimentos>((dataItem: IJsonDashboardPieData) => {
        return {
          name: dataItem.Nome,
          value: dataItem.Total
        };
      });
    } else if (type === EChartType.GastosCompras) {
      this.gastosCompras = data.map<IDashboardChartGastosCompras>((dataItem: IJsonDashboardPieData) => {
        return {
          name: dataItem.Nome,
          value: dataItem.Total
        };
      });
    }
  }

  private _formatChartDataArray(type: EChartType, data: Array<IJsonDashboardData>): void {
    if (type === EChartType.Vendas) {
      const fetchedDataSeriesOne = data.filter((e) => e.serie === 1);
      this.vendas = fetchedDataSeriesOne.map<IDashboardChartVendas>((dataItem: IJsonDashboardData) => {
        return {
          month: dataItem.label,
          valueVenda: dataItem.value ?? 0
        };
      });
    } else if (type === EChartType.TesourariaLiquida) {
      const fetchedDataSeriesOne = data.filter((e) => e.serie === 1);
      const fetchedDataSeriesTwo = data.filter((e) => e.serie === 2);
      if (fetchedDataSeriesOne.length === fetchedDataSeriesTwo.length) {
        this.tesourariaLiquida = fetchedDataSeriesOne.map<IDashboardChartTesourariaLiquida>((dataItem: IJsonDashboardData, index: number) => {
          return {
            month: dataItem.label,
            valueDisponibilidade: dataItem.value ?? 0,
            valueFinanciamento: fetchedDataSeriesTwo[index].value ?? 0
          };
        });
      }
    }
  }
}
