import {Ng2StateDeclaration} from '@uirouter/angular';
import {APP_LAUNCH_MODE} from '../../../common/api';
import {EAppLaunchMode} from '../../../common/site';
import {LoadModuleStateComponent} from './loadmodule.state.component';
import {STATE_NAME_LOAD_MODULE, STATE_URL_LOAD_MODULE} from './loadmodule.state.interface';
import {STATE_NAME_PORTAL} from '../../services/portals/portals.service.interface';

export const STATE_LOAD_MODULE: Ng2StateDeclaration = {
  name: STATE_NAME_LOAD_MODULE,
  url: `/${STATE_URL_LOAD_MODULE}?launchMode&moduleName&moduleParams`,
  component: LoadModuleStateComponent,
  params: {
    launchMode: {
      type: 'json',
      value: null,
      squash: true
    },
    moduleName: {
      type: 'string',
      value: '',
      squash: true
    },
    moduleParams: {
      type: 'json',
      value: null,
      squash: true
    },
    loaded: {
      type: 'bool',
      value: false
    }
  },
  redirectTo: (): string | undefined => {
    const launchMode: EAppLaunchMode = APP_LAUNCH_MODE.value;
    if (launchMode !== EAppLaunchMode.Hybrid && launchMode !== EAppLaunchMode.HybridPartial) {
      return STATE_NAME_PORTAL;
    }
    return undefined;
  }
};
