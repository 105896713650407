import {ConciliacaoEFaturasModuleComponent} from './conciliacaoEFaturas.module.component';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {RESOLVER_CONCILIACAO_EFATURAS_TIPOS_FATURA} from './service/conciliacaoEFaturas.service.di';
import {ROLE} from '../../../services/role.const';
import {RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL} from '../../../services/contabilidadedigital/contabilidadedigital.router.interface';
import {MODULE_NAME_CONCILIACAO_EFATURAS} from './conciliacaoEFaturas.module.interface';

export const MODULE_CONCILIACAO_EFATURAS: IModuleDefinition = {
  name: MODULE_NAME_CONCILIACAO_EFATURAS,
  state: {
    url: '/conciliacaoefaturas',
    component: ConciliacaoEFaturasModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.conciliacaoEfaturas'
    },
    params: {
      deData: {
        type: 'string',
        value: ''
      },
      ateData: {
        type: 'string',
        value: ''
      }
    },
    resolve: [RESOLVER_CONCILIACAO_EFATURAS_TIPOS_FATURA, RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL]
  }
};
