import moment from 'moment';
import {HttpResponse} from '@angular/common/http';
import {Component, Injector, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import ArrayStore from 'devextreme/data/array_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IPlToolbarItem, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {TDate} from '../../../../common/dates';
import {MAX_DATE_CG, MIN_DATE_CG} from '../../../../common/utils/utils';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {
  IDevExpressDataGrid,
  IDevExpressDataGridColumnCustomizeTextCellInfo,
  IDevExpressDataGridSummaryCalculateCustomSummaryOptions
} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {CheckSendAllMailsModalComponent} from '../../../components/emailsCliFo/modal/checkSendAll/checkSendAllMails.modal.component';
import {EmailClifoSendMailModalComponent} from '../../../components/emailsCliFo/modal/emailClifo.modal.component';
import {ModuloComponent} from '../../../components/module/module.component';
import {IApiQueryResponse} from '../../../services/api/api.service.interface';
import {ConfigErpService} from '../../../services/configErp.service';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {ENTITY_NAME_FORNECEDORES, IClifosEntityService} from '../../../entities/clifos/clifos.entity.interface';
import {IJsonClifo} from '../../../entities/clifos/jsonClifo.entity.interface';
import {IJsonMeioPagamento} from '../../../entities/meiospagamento/jsonMeioPagamento.entity.interface';
import {ENTITY_NAME_MEIOS_PAGAMENTO, ETipo, ETipoPagamento} from '../../../entities/meiospagamento/meiosPagamento.entity.interface';
import {PagamentoImprimirModalComponent} from '../../../entities/pagamentos/modals/imprimirmodal/pagamento.imprimir.modal.component';
import {IJsonPagamentoEmail, IJsonPagamentoEmailContent} from '../../../entities/pagamentos/jsonPagamento.entity.interface';
import {PagamentosService} from '../../../entities/pagamentos/service/pagamentos.entity.service';
import {ImpAvisosCobrancaErrosModalComponent} from '../../portalcontabilidade/avisoscobranca/impavisoscobranca/modal/impAvisosCobranca.erros.modal.component';
import {ETrfBaRGFormato, IJsonTrfBa, IJsonTrfBaRegistoFich} from '../../trfba/jsonTrfBa.module.interface';
import {TrfBaGeraFicheiroModalComponent} from '../../trfba/modal/geraficheiro/trfBa.geraFicheiro.modal.component';
import {ITrfGerarFicheiroModalParams, ITrfOpenGerarFicheiroModalParams} from '../../trfba/trfBa.module.interface';
import {TrfBaService} from '../../trfba/trfBa.module.service';
import {ESTADOS_NOTIFICACAO_PT_STR} from '../gestaoFicheirosSepa.module.interface';

const TAB_PORENVIAR_ID = 'porEnviar';
const TAB_ENVIADOS_ID = 'enviados';

@Component({
  selector: 'gestao-ficheiros-sepa',
  templateUrl: './gestaoFicheirosSepa.module.component.html'
})
export class GestaoFicheirosSepaModuleComponent extends ModuloComponent implements OnInit {
  public readonly dataGridDefinitionPorEnviar: IDevExpressDataGrid;
  public readonly dataGridDefinitionEnviados: IDevExpressDataGrid;
  public readonly tabEnviadosID: string;
  public readonly tabPorEnviarID: string;
  public readonly filterMeiosPagamento: string;
  public readonly formInstance: UntypedFormGroup;
  public activeTab: string;
  public ficheirosEnviadosList: Array<IJsonTrfBa>;
  public ficheirosPorEnviarList: Array<IJsonTrfBa>;
  public meioPagamento: IJsonMeioPagamento;
  public selectedRowKeys: Array<number>;
  public dataPagamDe: TDate;
  public dataPagamAte: TDate;

  private readonly _btnGenerateSEPA: IPlToolbarItem;
  private readonly _serviceFornecedores: IClifosEntityService;
  private readonly _serviceMeiosPagamento: IEntityService<IJsonMeioPagamento>;
  private _dataGridInstanceEnviados: dxDataGrid;
  private _dataGridInstancePorEnviar: dxDataGrid;
  private _valorTotalPagamento: number;
  private _doSelecteAll: boolean;
  private _meiosPagamentos: Array<IJsonMeioPagamento>;
  private _meioPagamentoDefeito: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _trfBaService: TrfBaService,
    private readonly _pagamentosService: PagamentosService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgModalService: CGModalService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _configErpService: ConfigErpService
  ) {
    super(_injector);
    this._serviceFornecedores = this._entityServiceBuilder.build<IJsonClifo, IClifosEntityService>(ENTITY_NAME_FORNECEDORES);
    this._serviceMeiosPagamento = this._entityServiceBuilder.build<IJsonMeioPagamento>(ENTITY_NAME_MEIOS_PAGAMENTO);

    this.formInstance = new UntypedFormGroup({});
    this.selectedRowKeys = [];
    this.activeTab = TAB_PORENVIAR_ID;
    this.tabEnviadosID = TAB_ENVIADOS_ID;
    this.tabPorEnviarID = TAB_PORENVIAR_ID;

    this.filterMeiosPagamento = `tipo=${ETipo.TrfBancaria}&&(tipoMovimento=${ETipoPagamento.Indiferente}||tipoMovimento=${ETipoPagamento.Pagamento})`;

    this._btnGenerateSEPA = {
      id: 'btnGenerateSEPA',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file-code-o"></i>',
      class: 'btn-success',
      caption: 'gestaoFicheirosSepa.btn.gerarFicheiroSEPA',
      click: () => this._gerarFicheiroSepa()
    };

    this.meioPagamento = {
      nCaixa: 0,
      nome: '',
      tipo: 0,
      nConta: '',
      nomeConta: '',
      tipoMovimento: 0,
      codMoeda: 0,
      swift: '',
      nib: ''
    };
    this.dataPagamDe = moment().startOf('month');
    this.dataPagamAte = moment().endOf('month');

    this.dataGridDefinitionPorEnviar = {
      columns: [
        {dataField: 'trfBaID', dataType: 'number', visible: false, showInColumnChooser: false},
        {dataField: 'nContaForn', dataType: 'string', caption: 'gestaoFicheirosSepa.grid.fields.nContaForn'},
        {dataField: 'nomeBeneficiario', dataType: 'string', caption: 'gestaoFicheirosSepa.grid.fields.nomeBeneficiario'},
        {dataField: 'ibanContaDest', dataType: 'string', caption: 'gestaoFicheirosSepa.grid.fields.ibanContaDest'},
        {dataField: 'swiftDest', dataType: 'string', caption: 'gestaoFicheirosSepa.grid.fields.swiftDest'},
        {dataField: 'valor', dataType: 'double', caption: 'gestaoFicheirosSepa.grid.fields.valor'},
        {dataField: 'dataLanc', dataType: 'date', caption: 'gestaoFicheirosSepa.grid.fields.dataLanc'}
      ],
      selection: {mode: 'multiple', selectAllMode: 'page', showCheckBoxesMode: 'always'},
      summary: {
        calculateCustomSummary: this._calculateCustomSummary.bind(this),
        totalItems: [{column: 'valor', summaryType: 'custom', name: 'valorTotalPagamentos', displayFormat: '{0}', valueFormat: 'decimal'}]
      },
      dataSource: []
    };

    this.dataGridDefinitionEnviados = {
      columns: [
        {dataField: 'trfBaID', dataType: 'number', visible: false, showInColumnChooser: false},
        {dataField: 'nContaForn', dataType: 'string', caption: 'gestaoFicheirosSepa.grid.fields.nContaForn'},
        {dataField: 'nomeBeneficiario', dataType: 'string', caption: 'gestaoFicheirosSepa.grid.fields.nomeBeneficiario'},
        {dataField: 'ibanContaDest', dataType: 'string', caption: 'gestaoFicheirosSepa.grid.fields.ibanContaDest'},
        {dataField: 'swiftDest', dataType: 'string', caption: 'gestaoFicheirosSepa.grid.fields.swiftDest'},
        {
          dataField: 'nomeFicheiro',
          dataType: 'string',
          caption: 'gestaoFicheirosSepa.grid.fields.nomeFicheiro',
          groupIndex: 0,
          allowGrouping: false,
          groupCellTemplate: 'nomeFicheiroGroupRowTemplate'
        },
        {dataField: 'valor', dataType: 'double', caption: 'gestaoFicheirosSepa.grid.fields.valor'},
        {dataField: 'dataLanc', dataType: 'date', caption: 'gestaoFicheirosSepa.grid.fields.dataLanc'},
        {dataField: 'estadoNotificacao', dataType: 'number', caption: 'gestaoFicheirosSepa.grid.fields.estadoNotificacao', customizeText: this._customizeText.bind(this)},
        {
          dataField: 'vf_actions',
          caption: ' ',
          cellTemplate: 'actions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false,
          showInColumnChooser: false
        }
      ],
      groupPanel: {
        allowColumnDragging: false,
        visible: true
      },
      dataSource: []
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._loadMeioPagamento();
    this.toolbar.addButton(this._btnGenerateSEPA);
  }

  public onInitializedPorEnviar({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstancePorEnviar = component;
  }

  public onContentReadyPorEnviar(): void {
    if (this._doSelecteAll) {
      this._dataGridInstancePorEnviar.selectAll();
    }
    this._dataGridInstancePorEnviar.endCustomLoading();
  }

  public onInitializedEnviados({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstanceEnviados = component;
  }

  public onContentReadyEnviados(): void {
    this._dataGridInstanceEnviados.endCustomLoading();
  }

  public onSelectedRowKeysChange(): void {
    this._valorTotalPagamento = 0;
    for (const trfbaId of this.selectedRowKeys) {
      const index = this.ficheirosPorEnviarList.findIndex((value) => value.trfBaID === trfbaId);
      this._valorTotalPagamento += this.ficheirosPorEnviarList[index].valor;
    }
    this._doSelecteAll = false;
    this._dataGridInstancePorEnviar.option('summary.totalItems[0].totalValue', this._valorTotalPagamento);
  }

  public changeTab(tabId: string): void {
    this._btnGenerateSEPA.visible = tabId === TAB_PORENVIAR_ID;
  }

  public transferirFicheiro(nomeFicheiro: string): Promise<void> {
    const trfba = this.ficheirosEnviadosList.find((value) => value.nomeFicheiro === nomeFicheiro);
    return this._trfBaService.exportFicheiroSepaAndDownload(trfba.trfBaID).then(() => {
      return this._pesquisar();
    });
  }

  public imprimirNotificar(trfba: IJsonTrfBa): Promise<void> {
    return this._serviceFornecedores.get({id: trfba.nContaForn}).then((response) => {
      const params = {
        extPocCabID: trfba.extPocCabId,
        nConta: trfba.nContaForn,
        nomeConta: trfba.nomeBeneficiario,
        email: response.body.email,
        trfbaId: trfba.trfBaID
      };
      const modalInstance = this._cgModalService.showVanilla(PagamentoImprimirModalComponent);
      const componentInstance: PagamentoImprimirModalComponent = modalInstance.componentInstance;
      componentInstance.params = params;
      componentInstance.pagamentosService = this._pagamentosService;
      return modalInstance.result.then(() => {
        return this._pesquisar();
      });
    });
  }

  public retrocederPorEnviar(nomeFicheiro: string): Promise<void> {
    const trfba = this.ficheirosEnviadosList.find((value) => value.nomeFicheiro === nomeFicheiro);
    return this._trfBaService.alterarEstadoDeEnvio(String(trfba.trfBaID), true).then(() => {
      return this._pesquisar();
    });
  }

  public readonly fnPesquisar: () => Promise<void> | void = () => this._pesquisar();

  private async _gerarFicheiroSepa(): Promise<void> {
    const trfbaregistofich: IJsonTrfBaRegistoFich = {
      registoFicheiroDeTransferenciasNoFormatoPS2: false,
      registoFicheiroDeTransferenciaDGTNoFormatoSEPA: false,
      registoFicheiroTicketRefeicaoNoFormatoSEPA: false,
      registoFicheiroExportacaoCGDTXTSeparacaoNibs: false
    };

    const trfbaIds = this.selectedRowKeys.toString();

    if (trfbaIds === '') {
      this._plAlertService.error('trfba.messages.temselecionar');
      return Promise.resolve();
    }

    const inputs: ITrfGerarFicheiroModalParams = {
      trfBaIDS: trfbaIds,
      trfBaRegistoFich: trfbaregistofich,
      rgFormato: ETrfBaRGFormato.PS2XML
    };

    inputs.showDataLanc = true;
    inputs.showPrioridade = true;
    inputs.dataLanc = moment();
    return this._gerarFicheiroModal({inputs: inputs}).then(() => {
      this._cgModalService.showOkCancelVanilla('pagamentos.list.notifyTitle', 'pagamentos.list.notifyMessage').result.then(() => {
        this._getEmailsContent(trfbaIds);
      });
      return this._pesquisar();
    });
  }

  private async _gerarFicheiroModal({inputs, showOkCancel, showOkCancelMessage}: ITrfOpenGerarFicheiroModalParams): Promise<void> {
    if (showOkCancel) {
      await this._cgModalService.showOkCancel('global.text.confirmation', showOkCancelMessage);
    }
    const instance: NgbModalRef = this._cgModalService.showVanilla(TrfBaGeraFicheiroModalComponent, {size: 'sm'});
    for (const key of Object.keys(inputs)) {
      instance.componentInstance[key] = inputs[key];
    }
    await instance.result;
  }

  private _pesquisar(): Promise<void> {
    if (!this.formInstance.valid) {
      return Promise.reject(new Error('gestaoFicheirosSepa.error.pesquisar'));
    }
    const efetuada = this.activeTab === TAB_ENVIADOS_ID;
    return this._getTrfBaSEPA(efetuada);
  }

  private _getTrfBaSEPA(efetuada: boolean): Promise<void> {
    const dataPagamDe = efetuada ? this.dataPagamDe : moment(MIN_DATE_CG);
    const dataPagamAte = efetuada ? this.dataPagamAte : moment(MAX_DATE_CG);

    return this._trfBaService.getTransfBancariaSEPAComFicheiroPorGerar(this.meioPagamento.nCaixa, efetuada, dataPagamDe, dataPagamAte).then((response) => {
      if (efetuada) {
        this.ficheirosEnviadosList = response.body.list;
        this.dataGridDefinitionEnviados.dataSource = new ArrayStore({
          key: 'trfBaID',
          data: this.ficheirosEnviadosList
        });
      } else {
        this.ficheirosPorEnviarList = response.body.list;
        this._valorTotalPagamento = 0;
        for (const ficheiro of this.ficheirosPorEnviarList) {
          this._valorTotalPagamento += ficheiro.valor;
        }
        this.dataGridDefinitionPorEnviar.dataSource = new ArrayStore({
          key: 'trfBaID',
          data: this.ficheirosPorEnviarList
        });
        this._doSelecteAll = true;
      }
    });
  }

  private _calculateCustomSummary(summary: IDevExpressDataGridSummaryCalculateCustomSummaryOptions<number>): void {
    if (summary.name === 'valorTotalPagamentos' && summary.summaryProcess === 'finalize') {
      summary.totalValue = this._valorTotalPagamento;
    }
  }

  private _customizeText(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row' && cellInfo.value) {
      return ESTADOS_NOTIFICACAO_PT_STR[cellInfo.valueText];
    }
    return cellInfo.valueText;
  }

  private _getEmailsContent(selectedTrfBaId: string): Promise<void> {
    return this._pagamentosService.getEmailsToSend(selectedTrfBaId).then((result) => {
      if (result.body.emails.length > 0) {
        this._sendEmailRecursive(0, result.body.emails.length, result.body.emails, result.body.errors, true);
      } else {
        this._plAlertService.error('impavisoscobranca.modal.erros.noEmails');
      }
    });
  }

  private _sendEmailRecursive(numEmail: number, emailCount: number, emails: Array<IJsonPagamentoEmailContent>, errors: Array<string>, firstEmail: boolean): void {
    const modalInstance = this._cgModalService.showVanilla(EmailClifoSendMailModalComponent);
    const componentInstance: EmailClifoSendMailModalComponent = modalInstance.componentInstance;
    componentInstance.emailContent = emails[numEmail];
    componentInstance.anexos = emails[numEmail].anexos;
    componentInstance.numEmails = emailCount;
    modalInstance.result.then((emailContentRec: IJsonPagamentoEmailContent) => {
      ++numEmail;
      if (firstEmail && emailContentRec && emailCount > 1) {
        firstEmail = false;
        const instanceModalCheckSendAll = this._cgModalService.showVanilla(CheckSendAllMailsModalComponent, {size: 'md'});
        instanceModalCheckSendAll.result.then((sendAll: boolean) => {
          if (!sendAll) {
            const emailContent: IJsonPagamentoEmail = {emails: [emailContentRec], errors: []};
            this._pagamentosService.sendEMail(emailContent).then(() => {
              this._plAlertService.success(this._plTranslateService.translate('emailclifos.sendMail.messageSuccess', {emailList: emailContentRec.emails}));
            });
            this._sendEmailRecursive(numEmail, emailCount, emails, errors, firstEmail);
          } else {
            if (errors.length !== 0) {
              const modalInstanceCobrancaErros = this._cgModalService.showVanilla(ImpAvisosCobrancaErrosModalComponent);
              const componentInstanceCobrancaErros: ImpAvisosCobrancaErrosModalComponent = modalInstanceCobrancaErros.componentInstance;
              componentInstanceCobrancaErros.erros = errors;
            }
            emails[0] = emailContentRec;
            const emailContent: IJsonPagamentoEmail = {emails: emails, errors: []};
            this._pagamentosService.sendEMail(emailContent).then(() => {
              this._plAlertService.success('emailclifos.sendMail.sendAllMessage');
            });
          }
        });
      } else if (emailContentRec) {
        const emailContent: IJsonPagamentoEmail = {emails: [emailContentRec], errors: []};
        this._pagamentosService.sendEMail(emailContent).then(() => {
          this._plAlertService.success(this._plTranslateService.translate('emailclifos.sendMail.messageSuccess', {emailList: emailContentRec.emails}));
        });
        if (numEmail < emailCount) {
          this._sendEmailRecursive(numEmail, emailCount, emails, errors, firstEmail);
        } else if (errors.length !== 0) {
          const modalInstanceCobrancaErros = this._cgModalService.showVanilla(ImpAvisosCobrancaErrosModalComponent);
          const componentInstanceCobrancaErros: ImpAvisosCobrancaErrosModalComponent = modalInstanceCobrancaErros.componentInstance;
          componentInstanceCobrancaErros.erros = errors;
        }
      } else if (numEmail < emailCount) {
        this._sendEmailRecursive(numEmail, emailCount, emails, errors, firstEmail);
      } else if (errors.length !== 0) {
        const modalInstanceCobrancaErros = this._cgModalService.showVanilla(ImpAvisosCobrancaErrosModalComponent);
        const componentInstanceCobrancaErros: ImpAvisosCobrancaErrosModalComponent = modalInstanceCobrancaErros.componentInstance;
        componentInstanceCobrancaErros.erros = errors;
      }
    });
  }

  private async _loadMeioPagamento(): Promise<void> {
    this._meioPagamentoDefeito = <number>(await this._configErpService.getConfiguration('contabilidade.pagamentos.meioPagamentoOmissao')).body.value;
    const response: HttpResponse<IApiQueryResponse<IJsonMeioPagamento>> = await this._serviceMeiosPagamento.query({pesquisa: this.filterMeiosPagamento});
    if (response.body.list.length === 1) {
      this._meiosPagamentos = response.body.list;
      const defaultMeioPagamento: IJsonMeioPagamento = this._meiosPagamentos.find((meioPagamento: IJsonMeioPagamento) => meioPagamento.nCaixa === this._meioPagamentoDefeito);
      if (defaultMeioPagamento) {
        this.meioPagamento = defaultMeioPagamento;
      } else {
        this.meioPagamento = response.body.list[0];
      }
    }
  }
}
