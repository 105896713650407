import {Injectable} from '@angular/core';
import {filterPanelFiltersFromUrl, filterPanelFiltersToUrl} from './filter.panel.utilities';
import type {IPlFilterPanelAdapterDeserializeData, IPlFilterPanelAdapterSerializeData} from './filter.panel.adapter.interface';
import {IPlFilterPanelField, IPlFilterPanelFilters} from './filter.panel.interface';
import {PlCompsService} from '../common/service/comps.service';

export function plFilterPanelAdapterFactory(plCompsService: PlCompsService): PlFilterPanelAdapter<string> {
  return new PlFilterPanelUrlAdapter(plCompsService);
}

@Injectable({
  providedIn: 'root',

  deps: [PlCompsService],
  useFactory: plFilterPanelAdapterFactory
})
export abstract class PlFilterPanelAdapter<T> {
  public abstract serialize(data: IPlFilterPanelAdapterSerializeData): T;

  public abstract deserialize(data: IPlFilterPanelAdapterDeserializeData<T>): IPlFilterPanelFilters;

  public abstract prettyPrintFilterValue(field: IPlFilterPanelField, value: unknown): string;
}

@Injectable()
export class PlFilterPanelUrlAdapter extends PlFilterPanelAdapter<string> {
  constructor(private readonly _plCompsService: PlCompsService) {
    super();
  }

  public serialize({filters}: IPlFilterPanelAdapterSerializeData): string {
    return filterPanelFiltersToUrl(filters);
  }

  public deserialize({serializedFilters, fields}: IPlFilterPanelAdapterDeserializeData<string>): IPlFilterPanelFilters {
    return filterPanelFiltersFromUrl(serializedFilters, fields);
  }

  public prettyPrintFilterValue(field: IPlFilterPanelField, value: unknown): string {
    return this._plCompsService.prettyPrintValue(value, field.type, undefined, field.properties);
  }
}
