import {merge} from 'lodash-es';
import {Subscription} from 'rxjs';
import {DxChartComponent} from 'devextreme-angular';
import {FormatObject} from 'devextreme/localization';
import {Directive, Host, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {IPlFormatConfig, isNumber, isObject} from 'pl-comps-angular';
import {AppService} from '../../../../services/app/app.service';
import {devExpressGenerateFormatDouble} from '../../widget/devexpress.widget.utilities';
import {IDevExpressChart} from '../devexpress.charts.interface';

@Directive({
  selector: '[cgDxChart]'
})
export class DevExpressChartCentralGestDirective implements OnInit, OnChanges, OnDestroy {
  @Input() public cgDxChart: IDevExpressChart;

  private readonly _subscriptionFormat: Subscription;
  private _chart: IDevExpressChart;
  private _formatDouble: string;

  constructor(
    private readonly _appService: AppService,
    @Host() private readonly _chartComponent: DxChartComponent
  ) {
    this._subscriptionFormat = this._appService.format().subscribe((format: IPlFormatConfig) => {
      const decimalDigits = isNumber(format.decimalsLimit) ? format.decimalsLimit : 2;
      this._formatDouble = devExpressGenerateFormatDouble(decimalDigits);
    });
  }

  public ngOnInit(): void {
    this._changedChart(this.cgDxChart);
    this._applyConfiguration();
  }

  public ngOnChanges({cgDxChart}: SimpleChanges): void {
    if (cgDxChart && !cgDxChart.isFirstChange()) {
      this._applyConfiguration();
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionFormat.unsubscribe();
  }

  private _changedChart(dataGrid: IDevExpressChart): void {
    this._chart = this._setChartDefaultConfiguration(dataGrid);
  }

  private _setChartDefaultConfiguration(chart: IDevExpressChart): IDevExpressChart {
    chart = merge<object, IDevExpressChart>({}, chart);
    this._extendFormat(chart);
    return chart;
  }

  private _applyConfiguration(): void {
    for (const key of Object.keys(this._chart)) {
      let value: unknown = this._chart[key];
      if (key === 'dataSource') {
        continue;
      }
      if (isObject(value) && isObject(this._chartComponent.instance.option(key))) {
        value = merge({}, this._chartComponent.instance.option(key), <object>value);
      }
      this._chartComponent.instance.option(key, value);
    }
  }

  private _extendFormat(chart: IDevExpressChart): void {
    if (chart.argumentAxis?.label?.format === 'double') {
      chart.argumentAxis.label.format = this._formatDouble;
      // eslint-disable-next-line @typescript-eslint/no-deprecated
    } else if (isObject(chart.argumentAxis?.label?.format) && (<FormatObject>chart.argumentAxis?.label?.format).type === 'double') {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      (<FormatObject>chart.argumentAxis.label.format).type = this._formatDouble;
    }

    if (isObject(chart.crosshair?.horizontalLine)) {
      if (chart.crosshair.horizontalLine.label?.format === 'double') {
        chart.crosshair.horizontalLine.label.format = this._formatDouble;
        // eslint-disable-next-line @typescript-eslint/no-deprecated
      } else if (isObject(chart.crosshair?.horizontalLine?.label?.format) && (<FormatObject>chart.crosshair.horizontalLine.label.format).type === 'double') {
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        (<FormatObject>chart.crosshair.horizontalLine.label.format).type = this._formatDouble;
      }
    }

    if (chart.crosshair?.label?.format === 'double') {
      chart.crosshair.label.format = this._formatDouble;
      // eslint-disable-next-line @typescript-eslint/no-deprecated
    } else if (isObject(chart.crosshair?.label?.format) && (<FormatObject>chart.crosshair?.label?.format).type === 'double') {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      (<FormatObject>chart.crosshair.label.format).type = this._formatDouble;
    }

    if (isObject(chart.crosshair?.verticalLine)) {
      if (chart.crosshair.verticalLine.label?.format === 'double') {
        chart.crosshair.verticalLine.label.format = this._formatDouble;
        // eslint-disable-next-line @typescript-eslint/no-deprecated
      } else if (isObject(chart.crosshair.verticalLine.label?.format) && (<FormatObject>chart.crosshair.verticalLine.label.format).type === 'double') {
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        (<FormatObject>chart.crosshair.verticalLine.label.format).type = this._formatDouble;
      }
    }

    if (chart.tooltip.format === 'double') {
      chart.tooltip.format = this._formatDouble;
      // eslint-disable-next-line @typescript-eslint/no-deprecated
    } else if (isObject(chart.tooltip.format) && (<FormatObject>chart.tooltip.format).type === 'double') {
      // eslint-disable-next-line @typescript-eslint/no-deprecated
      (<FormatObject>chart.tooltip.format).type = this._formatDouble;
    }
  }
}
