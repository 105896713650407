import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EDateMonth, IPlDynamicVisualsSecondaryClickAction, IPlTabsCallback, isObject, PlAlertService, PlI18nService} from 'pl-comps-angular';
import {AtivosAquisicaoContabService} from '../../ativosaquisicaocontab/ativosAquisicaoContab.module.service';
import {AtivosConsistenciaLancaDifModalComponent} from '../modal/lancadif/ativosConsistencia.lancaDif.modal.component';
import {AtivosConsistenciaListaAtivosModalComponent} from '../modal/listaativos/ativosConsistencia.listaAtivos.modal.component';
import {AtivosConsistenciaService} from '../ativosConsistencia.module.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {DevExpressDataGridUIService} from '../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {ENTITY_NAME_POCS} from '../../../../entities/pocs/pocs.entity.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IAquisicaoAdicionaDocumento, MODULE_NAME_ATIVOS_AQUISICAO_CONTAB} from '../../ativosaquisicaocontab/ativosAquisicaoContab.module.interface';
import {IAtivosConsistenciaAnos, IAtivosConsistenciaContab, IAtivosConsistenciaIgnorados} from '../ativosConsistencia.module.interface';
import {IContabilidadeExtratosGridParams, MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID} from '../../../portalcontabilidade/estatistica/extratosgrid/contabilidade.extratosGrid.module.interface';
import {
  IDevExpressDataGrid,
  IDevExpressDataGridColumn,
  IDevExpressDataGridColumnCustomizeTextCellInfo,
  IDevExpressDataGridSummary,
  TDevExpressDataGridColumnCustomizeTextFn
} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnInitialized
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonAquisicaoContabResAddDoc} from '../../ativosaquisicaocontab/jsonAtivosAquisicaoContab.module.interface';
import {
  IJsonAtivosConsistencia,
  IJsonAtivosConsistenciaAjustes,
  IJsonAtivosConsistenciaAjustesCab,
  IJsonAtivosConsistenciaAnos,
  IJsonAtivosConsistenciaAnosErrados,
  IJsonAtivosConsistenciaErros,
  IJsonAtivosConsistenciaPeriodoDiferente,
  IJsonAtivosConsistenciaResumoAndAnaliseConta,
  IJsonAtivosConsistenciaVendaDiferente
} from '../jsonAtivosConsistencia.module.interface';
import {IJsonDocContabilidade} from '../../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloComponent} from '../../../../components/module/module.component';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';

export const TAB_ID_ATIVOS_CONTAB = 'ativosconsistenciaContab';
export const TAB_ID_ATIVOS_RESUMO = 'ativosconsistenciaResumo';
export const TAB_ID_ATIVOS_ANALISE_CONTA = 'ativosconsistenciaAnaliseConta';
export const TAB_ID_ATIVOS_PERIODO_DIFERENTE = 'ativosconsistenciaPeriodoDiferente';
export const TAB_ID_ATIVOS_ANOS_ERRADOS = 'ativosconsistenciaAnosErrados';
export const TAB_ID_ATIVOS_VENDA_DIFERENTE = 'ativosconsistenciaVendaDiferente';
export const TAB_ID_ATIVOS_IGNORADOS = 'ativosconsistenciaIgnorados';
export const TAB_ID_ATIVOS_AJUSTES = 'ativosconsistenciaAjustes';

export const TABLE_NAME_ATIVOS_CONTAB = 'ativosconsistenciaContab';
export const TABLE_NAME_RESUMO = 'ativosconsistenciaResumo';
export const TABLE_NAME_ANALISE_CONTA = 'ativosConsistenciaAnaliseContaBruto';
export const TABLE_NAME_ATIVOS_IGNORADOS = 'ativosconsistenciaIgnorados';

@Component({
  selector: 'module-ativos-consistencia',
  templateUrl: './ativosConsistencia.module.component.html'
})
export class AtivosConsistenciaModuleComponent extends ModuloComponent implements OnInit {
  @Input() public anosList: Array<IJsonAtivosConsistenciaAnos>;

  public readonly tabCallback: IPlTabsCallback;
  public readonly anoTemplate: string;
  public readonly anosSource: Array<IAtivosConsistenciaAnos>;

  public dataGridDefinition: IDevExpressDataGrid;
  public dataGridFieldsContab: Array<IDevExpressDataGridColumn>;
  public dataGridFieldsResumo: Array<IDevExpressDataGridColumn>;
  public dataGridFieldsAnalise: Array<IDevExpressDataGridColumn>;
  public dataGridFieldsPerioDif: Array<IDevExpressDataGridColumn>;
  public dataGridFieldsAnosErrados: Array<IDevExpressDataGridColumn>;
  public dataGridFieldsVendaDif: Array<IDevExpressDataGridColumn>;
  public dataGridFieldsIgnorados: Array<IDevExpressDataGridColumn>;
  public dataGridFieldsAjustes: Array<IDevExpressDataGridColumn>;
  public dataGridFieldsAjustesDetail: Array<IDevExpressDataGridColumn>;
  public dataGridFieldsResumoErros: Array<IDevExpressDataGridColumn>;

  public listaContab: Array<IAtivosConsistenciaContab>;
  public listaResumoErros: Array<IJsonAtivosConsistenciaErros>;
  public listaPeriodoDiferente: Array<IJsonAtivosConsistenciaPeriodoDiferente>;
  public listaAnosErrados: Array<IJsonAtivosConsistenciaAnosErrados>;
  public listaVendaDiferente: Array<IJsonAtivosConsistenciaVendaDiferente>;
  public listaIgnorados: Array<IAtivosConsistenciaIgnorados>;
  public listaAjustes: Array<IJsonAtivosConsistenciaAjustesCab>;
  public listaResumo: Array<IJsonAtivosConsistenciaResumoAndAnaliseConta>;
  public listaAnaliseConta: Array<IJsonAtivosConsistenciaResumoAndAnaliseConta>;
  public selectedKeysIgnorados: Array<string>;
  public selectedKeysContab: Array<string>;
  public summaryResumo: Partial<IDevExpressDataGridSummary>;
  public summaryAnalise: Partial<IDevExpressDataGridSummary>;
  public summaryPerioDif: Partial<IDevExpressDataGridSummary>;
  public summaryVendaDif: Partial<IDevExpressDataGridSummary>;
  public summaryIgnorados: Partial<IDevExpressDataGridSummary>;
  public summaryContab: Partial<IDevExpressDataGridSummary>;

  public activeTab: string;
  public ano: number;
  public mes: number;
  public temContab: boolean;
  public temAjustes: boolean;
  public temPeriodoDiferente: boolean;
  public temAnosErrados: boolean;
  public temVendaDiferente: boolean;
  public temIgnorados: boolean;
  public resumoLancaAjustes: boolean;
  public ativosConsistTableIDContab: string;
  public ativosConsistTableIDResumo: string;
  public ativosConsistTableIDAnaliseConta: string;
  public ativosConsistTableIDPeriodoDiferente: string;
  public ativosConsistTableIDAnosErrados: string;
  public ativosConsistTableIDVendaDiferente: string;
  public ativosConsistTableIDIgnorados: string;
  public ativosConsistTableIDAjustes: string;
  public resumoErrosHint: string;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private readonly _maintenanceInstancePocs: IEntityMaintenanceInstance;
  private readonly _maintenanceInstanceExtratosGrid: IModuleMaintenanceInstance;
  private readonly _devExpressDataGridUIService: DevExpressDataGridUIService;
  private _dataGridInstance: dxDataGrid;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosConsistenciaService: AtivosConsistenciaService,
    private readonly _cgModalService: CGModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plI18nService: PlI18nService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _ativosAquisicaoContabService: AtivosAquisicaoContabService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.pesquisaConsistencias = this.pesquisaConsistencias.bind(this);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._maintenanceInstancePocs = this._entityMaintenanceService.build(ENTITY_NAME_POCS);
    this._maintenanceInstanceExtratosGrid = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID);
    this.activeTab = TAB_ID_ATIVOS_RESUMO;
    this._setDataGridDefinition();
    this._devExpressDataGridUIService = this._injector.get<DevExpressDataGridUIService>(DevExpressDataGridUIService);
    this.dataGridFieldsResumo = this.dataGridFieldsAnalise = [
      {
        customizeText: this._fnCustomizeTextDCAsStr,
        dataField: 'tipo',
        dataType: 'number',
        allowGrouping: false,
        caption: 'ativosconsistencia.btn.tipo.ativobruto',
        groupCellTemplate: 'groupCellTemplateTipo',
        groupIndex: 0,
        showInColumnChooser: false
      },
      {
        dataField: 'erro',
        dataType: 'boolean',
        caption: 'global.text.error',
        allowHeaderFiltering: false,
        allowReordering: false,
        allowSearch: false,
        allowSorting: false,
        width: 50
      },
      {dataField: 'nConta', caption: 'ativosconsistencia.table.nConta'},
      {dataField: 'nomeConta', caption: 'ativosconsistencia.table.nomeConta', width: 220},
      {dataField: 'valorContabilidade', caption: 'ativosconsistencia.table.valorContabilidade', dataType: 'double'},
      {dataField: 'valorAtivos', caption: 'ativosconsistencia.table.valorAtivos', dataType: 'double'},
      {dataField: 'diferenca', caption: 'ativosconsistencia.table.diferenca', dataType: 'double'}
    ];
    this.dataGridFieldsPerioDif = this.dataGridFieldsVendaDif = [
      {dataField: 'codAtivo', caption: 'ativosconsistencia.table.codAtivo', dataType: 'number'},
      {dataField: 'designacao', caption: 'ativosconsistencia.table.designacao'},
      {dataField: 'nContaDoAtivo', caption: 'ativosconsistencia.table.nContaDoAtivo'},
      {dataField: 'dataAquisicao', caption: 'ativosconsistencia.table.dataAquisicao', dataType: 'date'},
      {dataField: 'virtualString1', caption: 'ativosconsistencia.table.virtualString1'},
      {dataField: 'virtualString2', caption: 'ativosconsistencia.table.virtualString2'},
      {dataField: 'contabValorAquisicao', caption: 'ativosconsistencia.table.contabValorAquisicao', dataType: 'double'}
    ];
    this.dataGridFieldsAnosErrados = [
      {dataField: 'codAtivo', caption: 'ativosconsistencia.table.codAtivo', dataType: 'number'},
      {dataField: 'designacao', caption: 'ativosconsistencia.table.designacao'}
    ];
    this.dataGridFieldsResumoErros = [
      {dataField: 'codErro', caption: 'ativosconsistencia.table.codErro', dataType: 'number'},
      {dataField: 'erroStr', caption: 'global.text.error'}
    ];
    this.dataGridFieldsIgnorados = [
      {dataField: 'nConta', caption: 'ativosconsistencia.table.nConta'},
      {dataField: 'nomeConta', caption: 'ativosconsistencia.table.nomeConta'},
      {dataField: 'periodo', caption: 'ativosconsistencia.table.periodo'},
      {dataField: 'nDiario', caption: 'ativosconsistencia.table.nDiario', dataType: 'number'},
      {dataField: 'nDocInterno', caption: 'ativosconsistencia.table.nDocInterno'},
      {dataField: 'valorContabilidade', caption: 'ativosconsistencia.table.valorContabilidade', dataType: 'double'},
      {dataField: 'dCAsStr', caption: 'ativosconsistencia.table.dCAsStr', customizeText: this._fnCustomizeTextDCAsStr},
      {dataField: 'extPocCabId', allowSearch: false, visible: false, showInColumnChooser: false}
    ];
    this.dataGridFieldsAjustes = [
      {dataField: 'periodo', caption: 'ativosconsistencia.table.periodo'},
      {dataField: 'nDiario', caption: 'ativosconsistencia.table.nDiario', dataType: 'number'},
      {dataField: 'nDocInterno', caption: 'ativosconsistencia.table.nDocInterno'},
      {dataField: 'extPocCabId', allowSearch: false, visible: false, showInColumnChooser: false}
    ];
    this.dataGridFieldsAjustesDetail = [
      {dataField: 'nContaDebito', caption: 'ativosconsistencia.table.nContaDebito'},
      {dataField: 'nContaCredito', caption: 'ativosconsistencia.table.nContaCredito'},
      {dataField: 'valor', caption: 'ativosconsistencia.table.valor', dataType: 'double'},
      {dataField: 'descricao', caption: 'ativosconsistencia.table.descricao'}
    ];
    this.dataGridFieldsContab = [
      {
        dataField: 'tipo',
        caption: 'ativosconsistencia.btn.tipo.ativobruto',
        dataType: 'number',
        groupIndex: 0,
        allowGrouping: false,
        showInColumnChooser: false,
        groupCellTemplate: 'groupCellTemplateTipo'
      },
      {dataField: 'nConta', caption: 'ativosconsistencia.table.nConta'},
      {dataField: 'nomeConta', caption: 'ativosconsistencia.table.nomeConta'},
      {dataField: 'periodo', caption: 'ativosconsistencia.table.periodo'},
      {dataField: 'nDiario', caption: 'ativosconsistencia.table.nDiario', dataType: 'number'},
      {dataField: 'nDocInterno', caption: 'ativosconsistencia.table.nDocInterno'},
      {dataField: 'valorContabilidade', caption: 'ativosconsistencia.table.valorContabilidade', dataType: 'double'},
      {dataField: 'dCAsStr', caption: 'ativosconsistencia.table.dCAsStr', customizeText: this._fnCustomizeTextDCAsStr},
      {dataField: 'extPocCabId', allowSearch: false, visible: false, showInColumnChooser: false}
    ];
    this.summaryResumo = this.summaryAnalise = this.summaryContab = {groupItems: []};
    this.summaryPerioDif = this.summaryVendaDif = this.summaryIgnorados = {totalItems: []};
    this.resumoLancaAjustes = false;
    this.ativosConsistTableIDContab = TAB_ID_ATIVOS_CONTAB;
    this.ativosConsistTableIDResumo = TAB_ID_ATIVOS_RESUMO;
    this.ativosConsistTableIDAnaliseConta = TAB_ID_ATIVOS_ANALISE_CONTA;
    this.ativosConsistTableIDPeriodoDiferente = TAB_ID_ATIVOS_PERIODO_DIFERENTE;
    this.ativosConsistTableIDAnosErrados = TAB_ID_ATIVOS_ANOS_ERRADOS;
    this.ativosConsistTableIDVendaDiferente = TAB_ID_ATIVOS_VENDA_DIFERENTE;
    this.ativosConsistTableIDIgnorados = TAB_ID_ATIVOS_IGNORADOS;
    this.ativosConsistTableIDAjustes = TAB_ID_ATIVOS_AJUSTES;
    this.anoTemplate =
      `<b>${<string>this._translateService.instant('global.text.year')}:</b> {{ano}} <b>| ${<string>this._translateService.instant('global.text.fromDate')}:</b>` +
      `{{dataDeFormated}} <b>${<string>this._translateService.instant('global.text.toDate')}:</b> {{dataAteFormated}}`;
    this.ano = this._configService.configurations.empresa.anoEmCursoIRC;
    this.mes = EDateMonth.December;
    this.anosSource = [];
    this.selectedKeysIgnorados = [];
    this.selectedKeysContab = [];
    this.summaryResumo.groupItems = this.summaryAnalise.groupItems = [
      {
        column: 'valorContabilidade',
        displayFormat: '{0}',
        showInGroupFooter: true,
        skipEmptyValues: true,
        summaryType: 'sum',
        valueFormat: 'double'
      },
      {
        column: 'valorAtivos',
        displayFormat: '{0}',
        showInGroupFooter: true,
        skipEmptyValues: true,
        summaryType: 'sum',
        valueFormat: 'double'
      },
      {
        column: 'diferenca',
        displayFormat: '{0}',
        showInGroupFooter: true,
        skipEmptyValues: true,
        summaryType: 'sum',
        valueFormat: 'double'
      }
    ];
    this.summaryPerioDif.totalItems = this.summaryVendaDif.totalItems = [
      {
        column: 'contabValorAquisicao',
        displayFormat: '{0}',
        skipEmptyValues: true,
        summaryType: 'sum',
        valueFormat: 'double'
      }
    ];
    this.summaryIgnorados.totalItems = [
      {
        column: 'valorContabilidade',
        displayFormat: '{0}',
        skipEmptyValues: true,
        summaryType: 'sum',
        valueFormat: 'double'
      }
    ];
    this.summaryContab.groupItems = [
      {
        column: 'valorContabilidade',
        displayFormat: '{0}',
        showInGroupFooter: true,
        skipEmptyValues: true,
        summaryType: 'sum',
        valueFormat: 'double'
      }
    ];
    this.tabCallback = {};
    this.temPeriodoDiferente = false;
    this.temAnosErrados = false;
    this.temVendaDiferente = false;
    this.temContab = false;
    this.temAjustes = false;
    this.temIgnorados = false;
    this.resumoErrosHint = '';
    this.onDocumentoContabilidadeAction = this.onDocumentoContabilidadeAction.bind(this);
    this._initListas();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._setSourceAnos();
    this.pesquisaConsistencias();
    if (this.activeTab === this.ativosConsistTableIDResumo) {
      this.dataGridDefinition.grouping.autoExpandAll = true;
      this.dataGridDefinition.grouping.contextMenuEnabled = false;
      this.dataGridDefinition.grouping.expandMode = 'rowClick';
      this.dataGridDefinition.groupPanel.visible = false;
      this.dataGridDefinition.toolbar = {
        items: [
          {
            location: 'after',
            template: 'templateResumoAtivosLancDifAjustes',
            locateInMenu: 'auto',
            visible: this.activeTab === this.ativosConsistTableIDResumo
          },
          'exportButton',
          'columnChooserButton'
        ]
      };
    }
  }

  public changedTab(tabId: string | number): void {
    this.activeTab = <string>tabId;
    this._setDataGridDefinition();
  }

  public openListaAtivosByResumo(item: IJsonAtivosConsistenciaResumoAndAnaliseConta): void {
    const modalInstance = this._cgModalService.showVanilla(AtivosConsistenciaListaAtivosModalComponent);
    const componentInstance: AtivosConsistenciaListaAtivosModalComponent = modalInstance.componentInstance;
    componentInstance.listaAtivos = item.listaAtivos;
  }

  public async lancaAjustes(): Promise<void> {
    await this._cgModalService.showOkCancel('global.text.warning', 'ativosconsistencia.message.temcertezaajustes');
    const instance = this._cgModalService.showVanilla(AtivosConsistenciaLancaDifModalComponent);
    const periodo: string = await instance.result;
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    try {
      const response: HttpResponse<IJsonAtivosConsistenciaAjustes> = await this._ativosConsistenciaService.getLancaAjustes(this.ano, this.mes, periodo);
      this._plAlertService.success('ativosconsistencia.message.successLanca');
      this.listaAjustes = response.body.consistenciaAjustesCab;
      this._loadTablesSourceByTipo(response.body.ativosConsistencia);
    } finally {
      if (this._dataGridInstance) {
        this._dataGridInstance.endCustomLoading();
      }
    }
  }

  public openListaAtivosByAnalise(item: IJsonAtivosConsistenciaResumoAndAnaliseConta): void {
    const modalInstance = this._cgModalService.showVanilla(AtivosConsistenciaListaAtivosModalComponent);
    const componentInstance: AtivosConsistenciaListaAtivosModalComponent = modalInstance.componentInstance;
    componentInstance.listaAtivos = item.listaAtivos;
  }

  public anoChanged(value: {inputValue: string; item: IAtivosConsistenciaAnos}): void {
    if (isObject(value.item) && value.item.ano !== this.ano) {
      this.ano = value.item.ano;
    }
  }

  public async ignorarDocumento(isSingleItem: boolean, item: IAtivosConsistenciaContab = undefined): Promise<void> {
    const listaContab: Array<IAtivosConsistenciaContab> = [];
    if (!isSingleItem) {
      for (const selectedKeyContab of this.selectedKeysContab) {
        const indexContab: number = this.listaContab.findIndex((value: IAtivosConsistenciaContab) => value.extPocCabId === selectedKeyContab);
        if (indexContab !== -1) {
          listaContab.push(this.listaContab[indexContab]);
        }
      }
    } else if (item) {
      listaContab.push(item);
    }

    await this._cgModalService.showOkCancel('global.text.warning', 'ativosconsistencia.message.temcertezaignora');
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    try {
      const response: HttpResponse<IJsonAtivosConsistencia> = await this._ativosConsistenciaService.postIgnoraDocumentosSemRelacao(this.ano, this.mes, listaContab);
      this._loadTablesSourceByTipo(response.body);
    } finally {
      if (this._dataGridInstance) {
        this._dataGridInstance.endCustomLoading();
      }
    }
  }

  public async apagarIgnorados(isSingleItem: boolean, item: IAtivosConsistenciaIgnorados = undefined): Promise<void> {
    const listaIgnorados: Array<IAtivosConsistenciaIgnorados> = [];

    if (!isSingleItem) {
      for (const selectedKeyIgnorado of this.selectedKeysIgnorados) {
        const indexIgnorado: number = this.listaIgnorados.findIndex((value: IAtivosConsistenciaIgnorados) => value.extPocCabId === selectedKeyIgnorado);
        if (indexIgnorado !== -1) {
          listaIgnorados.push(this.listaIgnorados[indexIgnorado]);
        }
      }
    } else if (item) {
      listaIgnorados.push(item);
    }

    await this._cgModalService.showOkCancel('global.text.warning', 'ativosconsistencia.message.temcertezaignora');
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    try {
      const response: HttpResponse<IJsonAtivosConsistencia> = await this._ativosConsistenciaService.postApagaIgnorados(this.ano, this.mes, listaIgnorados);
      this._loadTablesSourceByTipo(response.body);
    } finally {
      if (this._dataGridInstance) {
        this._dataGridInstance.endCustomLoading();
      }
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonAtivosConsistenciaResumoAndAnaliseConta | IAtivosConsistenciaIgnorados | IAtivosConsistenciaContab>): void {
    if (
      event.rowType === 'data' &&
      (this.activeTab === this.ativosConsistTableIDResumo || this.activeTab === this.ativosConsistTableIDAnaliseConta) &&
      (<IJsonAtivosConsistenciaResumoAndAnaliseConta>event.data).erro
    ) {
      event.cellElement.classList.add('text-danger');
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IAtivosConsistenciaIgnorados | IAtivosConsistenciaContab>): void {
    if (!isObject(event.row) || event.row.rowType !== 'data' || this.activeTab === this.ativosConsistTableIDAjustes || event.column.cssClass?.includes('dx-command-select')) {
      return;
    }

    if (this.activeTab === this.ativosConsistTableIDIgnorados || this.activeTab === this.ativosConsistTableIDContab) {
      if (!event.row.isExpanded) {
        let promise: Promise<void>;
        if (!event.data._docContabilidade) {
          if (this._dataGridInstance) {
            this._dataGridInstance.beginCustomLoading(undefined);
          }
          promise = this._serviceDocsContabilidade
            .get({id: event.data.extPocCabId})
            .then((response: HttpResponse<IJsonDocContabilidade>) => {
              if (this.activeTab === this.ativosConsistTableIDIgnorados) {
                this.listaIgnorados.find((value: IAtivosConsistenciaIgnorados) => value.nConta === event.data.nConta)._docContabilidade = response.body;
              }
              if (this.activeTab === this.ativosConsistTableIDContab) {
                this.listaContab.find((value: IAtivosConsistenciaContab) => value.nConta === event.data.nConta)._docContabilidade = response.body;
              }
              event.data._docContabilidade = response.body;
            })
            .finally(() => {
              if (this._dataGridInstance) {
                this._dataGridInstance.endCustomLoading();
              }
              promise = undefined;
            });
        }
        Promise.resolve(promise).then(() => {
          event.component.expandRow(event.key);
        });
      } else {
        event.component.collapseRow(event.key);
      }
    }
  }

  public onDataGridContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IJsonAtivosConsistenciaResumoAndAnaliseConta>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      if (
        this.activeTab === this.ativosConsistTableIDContab ||
        this.activeTab === this.ativosConsistTableIDResumo ||
        this.activeTab === this.ativosConsistTableIDAnaliseConta ||
        this.activeTab === this.ativosConsistTableIDIgnorados
      ) {
        event.event.preventDefault();
        const tableName: string =
          this.activeTab === this.ativosConsistTableIDContab
            ? TABLE_NAME_ATIVOS_CONTAB
            : this.activeTab === this.ativosConsistTableIDResumo
              ? TABLE_NAME_RESUMO
              : this.activeTab === this.ativosConsistTableIDAnaliseConta
                ? TABLE_NAME_ANALISE_CONTA
                : this.activeTab === this.ativosConsistTableIDIgnorados
                  ? TABLE_NAME_ATIVOS_IGNORADOS
                  : '';
        const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(tableName, event.row.data);
        this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
      }
    }
  }

  public selectedRowKeysChanged(selectedKeys: Array<string>): void {
    if (this.activeTab === this.ativosConsistTableIDContab) {
      this.selectedKeysContab = selectedKeys;
    }

    if (this.activeTab === this.ativosConsistTableIDIgnorados) {
      this.selectedKeysIgnorados = selectedKeys;
    }
  }

  public async onDocumentoContabilidadeAction(): Promise<void> {
    await this.pesquisaConsistencias();
  }

  public async pesquisaConsistencias(changeTab: boolean = true): Promise<void> {
    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    try {
      const response: HttpResponse<IJsonAtivosConsistencia> = await this._ativosConsistenciaService.getPesquisaConsistencias(this.ano, this.mes);
      this._loadTablesSourceByTipo(response.body, changeTab);

      if (response.body.analiseConta?.length) {
        this._cardPanel?.collapse();
      } else {
        this._cardPanel?.focusFirstElement();
        this._plAlertService.info('global.text.searchNoData');
      }
    } finally {
      if (this._dataGridInstance) {
        this._dataGridInstance.endCustomLoading();
      }
    }
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _generateSecondaryClickActions(
    tableName: string,
    item: IAtivosConsistenciaContab | IJsonAtivosConsistenciaResumoAndAnaliseConta | IAtivosConsistenciaIgnorados
  ): Array<IPlDynamicVisualsSecondaryClickAction> {
    if (tableName === TABLE_NAME_ATIVOS_CONTAB) {
      const contab: IAtivosConsistenciaContab = <IAtivosConsistenciaContab>item;
      return [
        {
          caption: 'ativosconsistencia.btn.dropdown.ignorardocumento',
          click: () => this.ignorarDocumento(true, contab)
        },
        {
          caption: 'ativosconsistencia.btn.dropdown.aquisicaobydocumento',
          click: () => this._aquisicaoByDocumento(contab)
        }
      ];
    }

    if (tableName === TABLE_NAME_RESUMO) {
      const resumo: IJsonAtivosConsistenciaResumoAndAnaliseConta = <IJsonAtivosConsistenciaResumoAndAnaliseConta>item;
      return [
        {
          caption: 'ativosconsistencia.btn.dropdown.listaativos',
          click: () => {
            this.openListaAtivosByResumo(resumo);
          }
        },
        {
          caption: 'ativosconsistencia.btn.dropdown.planocontas',
          click: () => this._maintenanceInstancePocs.maintenanceEdit(resumo.nConta)
        },
        {
          caption: 'ativosconsistencia.btn.dropdown.extratocontas',
          click: () => this._maintenanceExtratosGrid(resumo.nConta)
        }
      ];
    }

    if (tableName === TABLE_NAME_ATIVOS_IGNORADOS) {
      return [
        {
          caption: 'ativosconsistencia.btn.dropdown.apagarIgnorados',
          click: () => this.apagarIgnorados(true, <IAtivosConsistenciaIgnorados>item)
        }
      ];
    }

    if (tableName === TABLE_NAME_ANALISE_CONTA) {
      const analiseConta: IJsonAtivosConsistenciaResumoAndAnaliseConta = <IJsonAtivosConsistenciaResumoAndAnaliseConta>item;
      return [
        {
          caption: 'ativosconsistencia.btn.dropdown.listaativos',
          click: () => {
            this.openListaAtivosByAnalise(analiseConta);
          }
        },
        {
          caption: 'ativosconsistencia.btn.dropdown.planocontas',
          click: () => this._maintenanceInstancePocs.maintenanceEdit(analiseConta.nConta)
        },
        {
          caption: 'ativosconsistencia.btn.dropdown.extratocontas',
          click: () => this._maintenanceExtratosGrid(analiseConta.nConta)
        }
      ];
    }

    return [];
  }

  private _loadTablesSourceByTipo(ativosConsistencia: IJsonAtivosConsistencia, changeTab: boolean = true): void {
    this.listaResumo = ativosConsistencia.resumo;
    this.listaAnaliseConta = ativosConsistencia.analiseConta;
    this.listaPeriodoDiferente = ativosConsistencia.periodoDiferente;
    this.listaAnosErrados = ativosConsistencia.anosErrados;
    this.listaVendaDiferente = ativosConsistencia.vendaDiferente;
    this.listaIgnorados = ativosConsistencia.ignorados;
    this.listaContab = ativosConsistencia.contab;
    this.listaResumoErros = ativosConsistencia.erros;
    this.resumoLancaAjustes = this.listaResumo.findIndex((value: IJsonAtivosConsistenciaResumoAndAnaliseConta) => value.erro === true) !== -1;
    this._validateTabs(ativosConsistencia, changeTab);
  }

  private _initListas(): void {
    this.listaContab = [];
    this.listaResumo = [];
    this.listaAnaliseConta = [];
    this.listaPeriodoDiferente = [];
    this.listaAnosErrados = [];
    this.listaVendaDiferente = [];
    this.listaIgnorados = [];
    this.listaAjustes = [];
    this.listaResumoErros = [];
  }

  private _validateTabs(ativosConsistencia: IJsonAtivosConsistencia, changeTab: boolean = true): void {
    this.temPeriodoDiferente = ativosConsistencia.periodoDiferente.length > 0;
    this.temAnosErrados = ativosConsistencia.anosErrados.length > 0;
    this.temVendaDiferente = ativosConsistencia.vendaDiferente.length > 0;
    this.temContab = ativosConsistencia.contab.length > 0;
    this.temIgnorados = ativosConsistencia.ignorados.length > 0;
    this.temAjustes = this.listaAjustes.length > 0;
    if (changeTab) {
      if (this.temAjustes) {
        this.activeTab = TAB_ID_ATIVOS_AJUSTES;
        this._setDataGridDefinition();
        this.tabCallback.select(this.activeTab);
      } else if (this.temContab) {
        this.activeTab = TAB_ID_ATIVOS_CONTAB;
        this._setDataGridDefinition();
        this.tabCallback.select(this.activeTab);
      }
    }
    this.resumoErrosHint = !this.listaResumoErros?.length
      ? this._translateService.instant('ativosconsistencia.message.semerros')
      : this._translateService.instant('ativosconsistencia.message.comerros');
  }

  private _setSourceAnos(): void {
    for (const source of this.anosList) {
      this.anosSource.push({
        ano: source.ano,
        dataDe: source.dataDe,
        dataAte: source.dataAte,
        dataDeFormated: this._plI18nService.formatDate(source.dataDe),
        dataAteFormated: this._plI18nService.formatDate(source.dataAte)
      });
    }
  }

  private _maintenanceExtratosGrid(nconta: string): Promise<void> {
    let params: IContabilidadeExtratosGridParams;
    if (nconta.length) {
      params = {nConta: nconta};
    }
    return this._maintenanceInstanceExtratosGrid.maintenance({params: params});
  }

  private _aquisicaoByDocumento(item: IAtivosConsistenciaContab): Promise<void> {
    const adicionaDoc: IAquisicaoAdicionaDocumento = {
      listaContas: [],
      listaDocumentos: [],
      listaDocumentosAdicionar: [{nDiario: item.nDiario, nDocInterno: item.nDocInterno, periodo: item.periodo, extPocCabID: item.extPocCabId}]
    };
    return this._ativosAquisicaoContabService.adicionarDocumento(adicionaDoc).then((response: HttpResponse<IJsonAquisicaoContabResAddDoc>) => {
      return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_ATIVOS_AQUISICAO_CONTAB, params: {isfromconsistencia: true, doc: response.body}});
    });
  }

  private _setDataGridDefinition(): void {
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      export: {filename: 'global.menu.ativosconsistencia'},
      grouping: {},
      groupPanel: {},
      height: '70vh',
      masterDetail: {enabled: false},
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      repaintChangesOnly: true,
      scrolling: {columnRenderingMode: 'virtual', rowRenderingMode: 'virtual'},
      selection: {}
    };
    if (this.activeTab === this.ativosConsistTableIDResumo || this.activeTab === this.ativosConsistTableIDAnaliseConta || this.activeTab === this.ativosConsistTableIDContab) {
      this.dataGridDefinition.grouping.autoExpandAll = true;
      this.dataGridDefinition.grouping.contextMenuEnabled = false;
      this.dataGridDefinition.grouping.expandMode = 'rowClick';
      this.dataGridDefinition.groupPanel.visible = false;
    }
    if (this.activeTab === this.ativosConsistTableIDIgnorados || this.activeTab === this.ativosConsistTableIDContab) {
      this.dataGridDefinition.masterDetail.enabled = true;
      this.dataGridDefinition.masterDetail.template = 'detailTemplateDocContabilidade';
      this.dataGridDefinition.selection.mode = 'multiple';
      this.dataGridDefinition.selection.showCheckBoxesMode = 'always';
    }
    if (this.activeTab === this.ativosConsistTableIDAjustes) {
      this.dataGridDefinition.masterDetail.enabled = true;
      this.dataGridDefinition.masterDetail.template = 'detailTemplateAjustes';
      this.dataGridDefinition.selection.mode = 'single';
    }
    this.dataGridDefinition.toolbar = {
      items: [
        {
          location: 'after',
          template: 'templateResumoAtivosLancDifAjustes',
          locateInMenu: 'auto',
          visible: this.activeTab === this.ativosConsistTableIDResumo
        },
        {
          location: 'before',
          template: 'templateAtivosConsistIDIgnoradosToolbar',
          locateInMenu: 'auto',
          visible: this.activeTab === this.ativosConsistTableIDIgnorados
        },
        {
          location: 'before',
          template: 'templateAtivosConsistContabToolbar',
          locateInMenu: 'auto',
          visible: this.activeTab === this.ativosConsistTableIDContab
        },
        'exportButton',
        'columnChooserButton'
      ]
    };
  }

  private _customizeTextDCAsStr(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row' && cellInfo.value) {
      return this._translateService.instant(cellInfo.value === 'C' ? 'ativosconsistencia.table.credito' : 'ativosconsistencia.table.debito');
    }
    return cellInfo.valueText;
  }

  private readonly _fnCustomizeTextDCAsStr: TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => this._customizeTextDCAsStr(cellInfo);
}
