import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {MODULE_NAME_FATURACAO_CONTRATOS} from './faturacaoContratos.module.interface';
import {FaturacaoContratosComponent} from './components/faturacaoContratos.module.component';

export const MODULE_FATURACAO_CONTRATOS: IModuleDefinition = {
  name: MODULE_NAME_FATURACAO_CONTRATOS,
  state: {
    url: `/${MODULE_NAME_FATURACAO_CONTRATOS}`,
    component: FaturacaoContratosComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.faturacaocontratos'
    },
    params: {
      moduleNameRetroceder: {
        type: 'string',
        value: ''
      }
    }
  }
};
