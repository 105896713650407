import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {ExportacaoComprasModuleComponent} from './components/exportacaoCompras.module.component';
import {ROLE} from '../../../../services/role.const';
import {MODULE_NAME_EXPORTACAO_COMPRAS} from './exportacaoCompras.module.interface';

export const MODULE_EXPORTACAO_COMPRAS: IModuleDefinition = {
  name: MODULE_NAME_EXPORTACAO_COMPRAS,
  state: {
    url: '/exportacaocompras',
    component: ExportacaoComprasModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pluginsRoles: [ROLE.ERPADVANCED]
    }
  }
};
