import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isNumber} from 'pl-comps-angular';
import {AuthService} from '../../../services/auth/auth.service';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../../entities/dgemps/dgemps.entity.interface';
import {ENTITY_NAME_PRH_LOCAIS} from '../../../entities/prhlocais/pRHLocais.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {ERefeicoesSchedulerMode, refeicoesSchedulerDefaultViewDate} from './refeicoes.scheduler.component.interface';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IJsonDGEMP} from '../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHLocal} from '../../../entities/prhlocais/jsonPRHLocal.entity.interface';
import moment, {MomentInput} from 'moment';
import {TUserSession} from '../../../services/account/jsonUserApi.interface';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';

@Component({
  selector: 'refeicoes-scheduler',
  templateUrl: './refeicoes.scheduler.component.html'
})
export class RefeicoesSchedulerComponent implements OnInit, OnChanges {
  @Input() public mode: ERefeicoesSchedulerMode;

  public readonly modes: typeof ERefeicoesSchedulerMode;
  public events: Array<unknown>;
  public view: unknown;
  // eslint-disable-next-line @typescript-eslint/no-restricted-types
  public viewDate: Date;
  public local: IJsonPRHLocal;
  public localPreferido: IJsonPRHLocal;
  public cellIsOpen: boolean;
  public loading: boolean;
  public selectedDay: unknown;
  public promise: Promise<void>;

  private readonly _servicePRHLocais: IEntityService<IJsonPRHLocal>;
  private readonly _serviceDGEMPS: IDGEMPSEntityService;
  private _codEmp: number;
  private _loadingPromises: number;

  constructor(
    private readonly _authService: AuthService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this.modes = ERefeicoesSchedulerMode;
    this.events = [];
    this.viewDate = refeicoesSchedulerDefaultViewDate().toDate();
    this.cellIsOpen = false;
    this.loading = false;
    this._servicePRHLocais = this._entityServiceBuilder.build<IJsonPRHLocal>(ENTITY_NAME_PRH_LOCAIS);
    this._serviceDGEMPS = this._entityServiceBuilder.build<IJsonDGEMP, IDGEMPSEntityService>(ENTITY_NAME_DGEMPS);
    this._loadingPromises = 0;
    this._addLoadingPromise(this._loadData());
  }

  public ngOnInit(): void {
    this._handleChanges();
  }

  public ngOnChanges({mode}: SimpleChanges): void {
    if (mode && !mode.isFirstChange()) {
      this._changedMode(mode.currentValue);
      this._refreshCalendar();
    }
  }

  public changedLocal(event: IJsonPRHLocal): void {
    this.local = event;
    this._refreshCalendar();
  }

  public setLocalPorDefeito(): Promise<void> {
    if (!this.local) {
      return Promise.resolve();
    }
    const localPreferido: IJsonPRHLocal = this.local;
    const promise: Promise<void> = this._serviceDGEMPS.definirLocalPorDefeito(this._codEmp, localPreferido.codLocal).then(() => {
      this.localPreferido = localPreferido;
    });
    this._addLoadingPromise(promise);
    return promise;
  }

  public setViewDate(date: MomentInput): void {
    this.viewDate = moment(date).toDate();
    if (this.cellIsOpen) {
      this.cellIsOpen = false;
    }
    this._refreshCalendar();
  }

  private _handleChanges(): void {
    this._changedMode();
  }

  private _changedMode(value: ERefeicoesSchedulerMode = this.mode): void {
    let val: ERefeicoesSchedulerMode = value;
    if (!isNumber(val)) {
      val = ERefeicoesSchedulerMode.View;
    }
    this.mode = val;
  }

  private async _loadData(): Promise<void> {
    this._codEmp = await this._authService.identity().then((session: TUserSession) => session.erp.codEmp);
    this.localPreferido = await this._serviceDGEMPS.getLocalDefeito(this._codEmp).then((response: HttpResponse<IJsonPRHLocal>) => response.body);
    const locais: THttpQueryResponse<IJsonPRHLocal> = await this._servicePRHLocais.query({pagina: 1, porpagina: 1});
    if (locais.body.total === 1 && locais.body.list.length) {
      this.changedLocal(locais.body.list[0]);
    }
  }

  private _refreshCalendar(): Promise<void> {
    this.events = [];
    this.selectedDay = undefined;
    if (!this.local) {
      return Promise.resolve();
    }
    return this.promise;
  }

  private _addLoadingPromise(value: Promise<unknown>): void {
    this._loadingPromises++;
    this._evaluateLoading();
    Promise.resolve(value).finally(() => {
      this._loadingPromises--;
      this._evaluateLoading();
    });
  }

  private _evaluateLoading(): void {
    this.loading = this._loadingPromises > 0;
  }
}
