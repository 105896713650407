import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {Observable} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {IJsonRHImpVenctHeader} from './jsonRHImpVenct.module.interface';
import {IJsonListaExclProcessamento} from '../rhfolfer/jsonRHFolfer.module.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class RhImpVenctService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/rhimpvenct`;
  }

  public getLoadDefaultHeader(): TServiceResponse<IJsonRHImpVenctHeader> {
    return this._apiService.get<IJsonRHImpVenctHeader>({
      url: `${this._path}/header`
    });
  }

  public getListagemImpVenctUrl(
    listagem: IJsonRHImpVenctHeader,
    listaEmpresas: string,
    listProcessamento: string,
    listExclAbono: string,
    listExclDesconto: string,
    reportName: string
  ): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report`, {
      docodnum: listagem.doCodNum,
      atecodnum: listagem.ateCodNum,
      dezona: listagem.deZona,
      atezona: listagem.ateZona,
      dedepto: listagem.deDepto,
      atedepto: listagem.ateDepto,
      desubdepto: listagem.deSubDepto,
      atesubdepto: listagem.ateSubDepto,
      deccusto: listagem.deCCusto,
      ateccusto: listagem.ateCCusto,
      deabono: listagem.deAbono,
      ateabono: listagem.ateAbono,
      dedesconto: listagem.deDesconto,
      atedesconto: listagem.ateDesconto,
      deseccao: listagem.deSeccao,
      ateseccao: listagem.ateSeccao,
      mostraabonosvirtuais: listagem.mostraAbonosVirtuais,
      dataprocesde: listagem.dataProcesDe,
      dataprocesate: listagem.dataProcesAte,
      listaempresas: listaEmpresas,
      slexcprocessamento: listProcessamento,
      slexcab: listExclAbono,
      slexcdes: listExclDesconto,
      reportname: reportName,
      tipoexportacao: 0
    });
  }

  public postExclusoesProcessamento(listaexclprocessamento: IJsonListaExclProcessamento): TServiceResponse<IJsonListaExclProcessamento> {
    return this._apiService.post<IJsonListaExclProcessamento>({
      url: `${this._path}/exclusoesprocessamento`,
      body: listaexclprocessamento
    });
  }
}
