import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {EMPTY_GUID} from '../../../../../../config/constants';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../../../../entities/dgemps/dgemps.entity.interface';
import {ENTITY_NAME_PRH_COMUNICACOES_INTERNAS, IPRHComunicacoesInternasEntityService} from '../../../../../entities/prhcomunicacoesinternas/pRHComunicacoesInternas.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {HomeComunicacoesInternasModalComponent} from '../../modals/comunicacaointerna/home.comunicacaoInterna.modal.component';
import {IColaboradoresHome, ITipoComunicacao, ITipoComunicacaoHome} from '../../home.module.interface';
import {IJsonDGEMP} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHComunicacaoInterna} from '../../../../../entities/prhcomunicacoesinternas/jsonPRHComunicacaoInterna.entity.interface';
import {minDateCG} from '../../../../../../common/utils/utils';
import moment from 'moment';
import {TDate} from '../../../../../../common/dates';
import {EComunicacaoInternaSystem} from '../../../comunicacoesinternas/comunicacoesInternas.module.interface';
import {copy, PlTranslateService} from 'pl-comps-angular';

const DATE_FORMAT = 'DD/MM/YYYY HH:mm';
const DIFF_DAYS = 3;

@Component({
  selector: 'comunicacaointerna-recursiva',
  templateUrl: './comunicacaoInternaRecursiva.component.html'
})
export class ComunicacaoInternaRecursivaComponent implements OnInit {
  @Input() public iColHome: IColaboradoresHome;
  @Input() public data: IJsonPRHComunicacaoInterna;
  @Input() public item: ITipoComunicacaoHome;
  @Input() public answer: boolean;
  @Output() public readonly evtComunicacaoSent: EventEmitter<void>;

  public emptyGuid: string;
  public downloadUrl: string;

  private readonly _serviceDGEMPS: IDGEMPSEntityService;
  private readonly _servicePRHComunicacoesInternas: IPRHComunicacoesInternasEntityService;

  constructor(
    private readonly _cgModalService: CGModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plTranslateService: PlTranslateService
  ) {
    this.answer = false;
    this.evtComunicacaoSent = new EventEmitter<void>();
    this.emptyGuid = EMPTY_GUID;
    this._serviceDGEMPS = this._entityServiceBuilder.build<IJsonDGEMP, IDGEMPSEntityService>(ENTITY_NAME_DGEMPS);
    this._servicePRHComunicacoesInternas = this._entityServiceBuilder.build<IJsonPRHComunicacaoInterna, IPRHComunicacoesInternasEntityService>(ENTITY_NAME_PRH_COMUNICACOES_INTERNAS);
  }

  public ngOnInit(): void {
    if (this.data.cab.comIntCabID) {
      this._servicePRHComunicacoesInternas.downloadDocUrl(this.data.cab.comIntCabID).subscribe((downloadUrl: string) => {
        this.downloadUrl = downloadUrl;
      });
    }
  }

  public toggleChildren(data: IJsonPRHComunicacaoInterna, item: ITipoComunicacaoHome): void {
    const visible = data.cab.childrenVisible;
    if (data.cab.isPrincipal) {
      for (const cabecalho of item.cabecalhosComInt) {
        cabecalho.cab.childrenVisible = false;
      }
    }
    data.cab.childrenVisible = !visible;
    this.marcarComIntComoLida(data, item);
  }

  public answerComInt(item: IJsonPRHComunicacaoInterna, answer: boolean): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(HomeComunicacoesInternasModalComponent);
    const componentInstance: HomeComunicacoesInternasModalComponent = modalInstance.componentInstance;
    componentInstance.item = item;
    componentInstance.answer = answer;
    componentInstance.servicoPrincipal = this.iColHome.servicoPrincipal;
    componentInstance.codEmp = this.iColHome.codEmp;
    componentInstance.tiposComunicacao = this.getListTiposComunicacao();
    return modalInstance.result.then(() => {
      this.evtComunicacaoSent.emit();
    });
  }

  public novaComInt(item: IJsonPRHComunicacaoInterna): void {
    const newItem = copy(this._getEmptyComunicacaoInterna());
    newItem.cab.tipoComInt = item.cab.tipoComInt;
    newItem.cab.isPrincipal = true;
    newItem.cab.data = moment();
    this.answerComInt(newItem, false);
  }

  public getListTiposComunicacao(): Array<ITipoComunicacao> {
    if (!this.iColHome.tpComunicacao) {
      return [];
    }
    const aData = moment();
    const dataDe = minDateCG();
    const dataAte = aData.clone().add(1, 'month').date(1);
    this.iColHome.tpComunicacao.forEach((item) => {
      this._serviceDGEMPS.getCountComunicacoesPorLer(this.iColHome.codEmp, dataDe, dataAte, item.codPRHTipoCom).then((data) => {
        item.count = data.body.value;
      });
    });
    return this.iColHome.tpComunicacao;
  }

  public marcarComIntComoLida(comInt: IJsonPRHComunicacaoInterna, item: ITipoComunicacaoHome): Promise<void> {
    if (!comInt.estadoLido && comInt.comIntLinID && this.iColHome.codEmp === comInt.codEmpComIntLIn) {
      return this._serviceDGEMPS.marcarComunicacaoComoLida(this.iColHome.codEmp, comInt.comIntLinID).then((response) => {
        comInt.estadoLido = response.body.estadoLido;
        if (this.iColHome.newComunicationsCount > 0) {
          this.iColHome.newComunicationsCount--;
        }
        item.count--;
        for (const cabecalho of item.cabecalhosComInt) {
          if (cabecalho.temFilhosPorLer) {
            cabecalho.temFilhosPorLer = this.verificaSeTemParaLer(cabecalho);
          }
        }
      });
    }
    return Promise.resolve();
  }

  public verificaSeTemParaLer(item: IJsonPRHComunicacaoInterna): boolean {
    if (!item.estadoLido && item.cab.codEmp !== this.iColHome.codEmp) {
      return true;
    }
    if (item.filhos.length) {
      return this.verificaSeTemParaLer(item.filhos[0]);
    }
    return false;
  }

  public getHumanSentDate(stampInsert: TDate): string {
    const m = moment(stampInsert);
    const diffDays = m.diff(moment(), 'days');
    return diffDays > DIFF_DAYS ? m.format(DATE_FORMAT) : m.fromNow();
  }

  public getSenderName(data: IJsonPRHComunicacaoInterna): string {
    return this.isAuthor(data) ? this._plTranslateService.translate('colaboradores.comunicacoesinternas.you') : data.cab.dgemp.nome;
  }

  public isAuthor(data: IJsonPRHComunicacaoInterna): boolean {
    return this.iColHome.codEmp === data.cab.dgemp.codEmp;
  }

  private _getEmptyComunicacaoInterna(): IJsonPRHComunicacaoInterna {
    return {
      cab: {
        comIntCabID: 0,
        titulo: '',
        texto: '',
        data: undefined,
        estadoLido: false,
        childrenVisible: false,
        comIntLinId: 0,
        dgemp: undefined,
        docID: '',
        tipoComInt: EComunicacaoInternaSystem.COMUNICACAO_INTERNA,
        nomeTipoComInt: undefined,
        codEmp: undefined,
        stampInsert: undefined,
        apelidoEmpregado: undefined,
        comIntCabIDPai: undefined,
        nomeEmpregado: undefined,
        isPrincipal: false
      },
      codEmpComIntLIn: 0,
      comIntLinID: 0,
      estadoLido: false,
      filhos: undefined,
      linhas: undefined,
      temFilhosPorLer: false,
      isSameCodEmp: false
    };
  }
}
