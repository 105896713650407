import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {AssociarServicosComponent} from './components/associaServicos.module.component';

export const MODULE_COLABORADORES_ASSOCIA_SERVICOS: IModuleDefinition = {
  name: 'colaboradoresassociaservicos',
  state: {
    url: '/associaservicos',
    component: AssociarServicosComponent,
    data: {
      roles: [ROLE.GESTORRH],
      pageTitle: 'global.menu.colaboradoresassociaservicos'
    }
  }
};
