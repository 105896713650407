<div [ngClass]="entityClassName">
  <config-options
    [instanceName]="configOptionsInstanceName"
    [groupName]="configOptionsGroupName"
    [toolbarInstanceName]="instanceName"
    (configChange)="onConfigChange($event)"
    (revertOptionsToDefault)="onRevertConfigOptionsToDefault()">
  </config-options>
  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [dataGridProperties]="dataGridProperties"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [toolbarInstanceId]="instanceName"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [properties]="listOptions"
    (evtOnRefresh)="onRefreshList()"
    (searchValueChange)="onSearchValueChange($event)"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
    <div *entityListItemDetail="let item">
      <contratos-detail
        [contrato]="item.data._contrato"
        [actionEditarContrato]="fnEditContrato"
        [actionReabrirContrato]="fnReabrirContrato"
        [actionTerminarContrato]="fnTerminarContrato"
        [actionApagarContrato]="fnApagarContrato"
        (evtRefresh)="refreshList()">
      </contratos-detail>
    </div>
  </entity-list>
</div>
