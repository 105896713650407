import {Directive, TemplateRef} from '@angular/core';
import type {IPlUITreeDragNodeContext} from './uitreedrag.interface';

@Directive({
  selector: 'ng-template[plUITreeDragNodeContent]',
  standalone: false
})
export class PlUITreeDragNodeContentDirective {
  constructor(public readonly templateRef: TemplateRef<IPlUITreeDragNodeContext<any>>) {}
}
