import {Component, Injector} from '@angular/core';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'aviso-alteracao-cc-modal',
  templateUrl: './avisoAlteracaoCC.modal.component.html'
})
export class AvisoAlteracaoCCModalComponent extends CGModalComponent<void> {
  public tomeiConhecimento: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.tomeiConhecimento = false;
  }
}
