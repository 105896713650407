import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpEvent, HttpHandlerFn, HttpRequest, HttpResponse} from '@angular/common/http';
import {isObject} from 'pl-comps-angular';
import {IJsonResponse} from '../common/interfaces/json';

export function interceptorResponse(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  return next(request).pipe(
    map((response: HttpResponse<unknown>) => {
      if (isObject(response.body)) {
        const jsonResponse: IJsonResponse<object> = <IJsonResponse<object>>response.body;
        if (
          Object.prototype.hasOwnProperty.call(jsonResponse, 'status') &&
          Object.prototype.hasOwnProperty.call(jsonResponse, 'message') &&
          Object.prototype.hasOwnProperty.call(jsonResponse, 'data') &&
          jsonResponse.status === 0
        ) {
          response = response.clone({body: jsonResponse.data});
        }
      }
      return response;
    })
  );
}
