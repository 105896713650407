export enum EClassificacaoControlo {
  NA,
  BaseTributavel,
  IvaDedutivel,
  ValorTributavel,
  IvaLiquidado,
  IvaNaoDedutivelProrrata,
  IvaNaoDedutivel,
  ValorTributavelNaoDedutivelProrrata,
  ValorTributavelNaoDedutivel,
  SemControloIvaAbateImobilizado,
  SemControloIvaConversaoImobilizado
}

export enum EClassificacaoControloRetencao {
  RetidoDisponivel,
  RetidoNaoDisponivel,
  PorTransferencia
}
