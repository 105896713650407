import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonFaturacaoPublicaHistorico} from '../../jsonFaturacaoPublica.module.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';

@Component({
  selector: 'modal-faturacaopublica-historico',
  templateUrl: './faturacaoPublica.historico.modal.component.html'
})
export class FaturacaoPublicaHistoricoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public historico: Array<IJsonFaturacaoPublicaHistorico>;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  private _dataGridInstance: dxDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.historico = [];
    this.dataGridDefinition = {
      columns: [
        {dataField: 'data', dataType: 'datetime', caption: 'faturacaopublica.historico.data'},
        {dataField: 'documento', dataType: 'string', caption: 'faturacaopublica.historico.documento'},
        {dataField: 'estado', dataType: 'string', caption: 'faturacaopublica.historico.estado'},
        {dataField: 'tipo', dataType: 'string', caption: 'faturacaopublica.historico.tipo'},
        {dataField: 'codigo', dataType: 'string', caption: 'faturacaopublica.historico.codigo'},
        {dataField: 'descricao', dataType: 'string', caption: 'faturacaopublica.historico.descricao'},
        {dataField: 'nConta', dataType: 'string', caption: 'faturacaopublica.historico.nConta'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'faturacaopublica.historico.nomeConta'},
        {dataField: 'nomeUtilizador', dataType: 'string', caption: 'faturacaopublica.historico.nomeUtilizador'}
      ],
      dataSource: [],
      height: '60vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      columnHidingEnabled: false
    };
  }

  public ngOnInit(): void {
    this.dataGridDefinition.dataSource = this.historico;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }
}
