import {IJsonApiPartnerMillenniumAssociate} from '../../../services/partners/millennium/partnermillennium.service.interface';

export enum EPartnerMillenniumEventMeToMBCP {
  CredentialsTyped = 'CredentialsTyped', // Quando o utilizador insere os dados de autenticação
  C4TokensDelivered = 'C4TokensDelivered', // Quando o utilizador é autenticado com sucesso e são retornados os tokens para o frontend do MBCP
  AssociationComplete = 'AssociationComplete', // Quando a entidade foi associada com sucesso
  EntityAssociated = 'EntityAssociated', // Quando a entidade já está associada
  C4OauthError = 'C4OauthError' // Quando existe erro (ex: erro de autenticação) vindo o erro na propriedade "error"
}

export enum EPartnerMillenniumEventMBCPToMe {
  SubmitCredentials = 'SubmitCredentials'
}

export enum EPartnerMillenniumAssociateError {
  InvalidRequest = 'invalid_request', // Parâmetros de entrada incorretos (JWT inválido)
  InvalidScope = 'invalid_scope', // Não utilizado
  AccessDenied = 'access_denied', // Utilizador ou password errada
  UnauthorizedUser = 'unauthorized_user', // Utilizador autenticado, mas não pode associar empresa (normalmente será se a empresa já estiver associada)
  InternalServerError = 'internal_server_error' // Erro interno
}

export interface IPartnerMillenniumStateParams {
  token: string;
}

export interface IPartnerMillenniumMessageMeToMBCP {
  message: EPartnerMillenniumEventMeToMBCP;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  access_token?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  refresh_token?: string;
  error?: EPartnerMillenniumAssociateError;
}

export interface IPartnerMillenniumMessageMBCPToMe {
  message: EPartnerMillenniumEventMBCPToMe;
}

export interface IPartnerMillenniumFormValue extends IJsonApiPartnerMillenniumAssociate {
  recaptcha: string;
}

export const STATE_NAME_PARTNER_MILLENNIUM = 'partnermillennium';
