import {deepFreeze} from 'pl-comps-angular';
import {DATA_SOURCE_NAME_TIPOS_POC, ETipoContaContabilidade} from './tiposPoc.datasource.interface';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';

export const DATA_SOURCE_TIPOS_POC: IDataSource<ETipoContaContabilidade> = deepFreeze({
  name: DATA_SOURCE_NAME_TIPOS_POC,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{label}}',
    output: 'label'
  },
  data: [
    {value: ETipoContaContabilidade.Movimento, label: 'tipoConta.data.movement'},
    {value: ETipoContaContabilidade.SubTotal, label: 'tipoConta.data.subtotal'},
    {value: ETipoContaContabilidade.Razao, label: 'tipoConta.data.reason'},
    {value: ETipoContaContabilidade.Classe, label: 'tipoConta.data.class'}
  ]
});
