import {Component, Injector} from '@angular/core';
import {CGModalComponent} from '../../../../../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'docscontabilidade-edit-reguralizacaocampo40-cancel-modal',
  templateUrl: './docsContabilidade.edit.regularizacaoCampo40.cancel.modal.component.html'
})
export class DocsContabilidadeEditReguralizacaoCampo40CancelModalComponent extends CGModalComponent<void> {
  public confirmed: boolean;
  public hasError: boolean;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public close(): void {
    if (!this.confirmed) {
      this.hasError = true;
      return;
    }
    super.close();
  }

  public changed(confirmed: boolean): void {
    this.confirmed = confirmed;
    this.hasError = false;
  }
}
