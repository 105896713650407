import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {MODULE_NAME_MODULES} from './modules.module.interface';
import {ModulesModuleComponent} from './components/modules.module.component';
import {ROLE} from '../../services/role.const';

export const MODULE_MODULES: IModuleDefinition = {
  name: MODULE_NAME_MODULES,
  state: {
    url: '/modules',
    component: ModulesModuleComponent,
    data: {
      roles: [ROLE.ADMIN],
      pageTitle: 'global.menu.modules'
    }
  }
};
