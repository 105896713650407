import {Injectable} from '@angular/core';
import {isArray, isEmpty} from 'pl-comps-angular';
import {ApiService} from '../api/api.service';
import {IApiQueryParams, TServiceQueryResponse, TServiceResponse} from '../api/api.service.interface';
import {IJsonBoolean} from '../../../common/interfaces/json';
import {IJsonConfigERPUser} from '../../entities/configserp/jsonConfigERP.entity.interface';
import {IJsonEmpresaErp} from '../../interfaces/jsonEmpresa.interface';
import {IJsonErp, IJsonRole} from '../../interfaces/jsonUserManager.interface';
import {IJsonErpUser, IJsonUserEmpresa, IJsonUserEmpresaWithAccesses, IJsonUserNew, IJsonUserNewWithAccesses, IJsonUserPassword} from '../account/jsonUserApi.interface';
import {IJsonERPUtilizador} from '../../interfaces/jsonERPUtilizador.interface';
import {IJsonGDocEmailConfig} from '../../modules/configs/assistenteconfigportais/jsonGdocEmailConfig.module.interface';
import {IJsonUserLicenca} from '../account/jsonUserLicensa.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthManagerService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/manager`;
  }

  public queryUsers(params?: IApiQueryParams): TServiceQueryResponse<IJsonUserEmpresa> {
    return this._apiService.query<IJsonUserEmpresa>({url: `${this._path}/users`, params: params});
  }

  public getUser(userId: number): TServiceResponse<IJsonUserEmpresa> {
    return this._apiService.get<IJsonUserEmpresa>({url: `${this._path}/users/${userId}`});
  }

  public newUser(user: IJsonUserNew, load?: boolean, url?: string): TServiceResponse<IJsonUserEmpresa> {
    return this._apiService.post<IJsonUserEmpresa>({url: `${this._path}/users`, body: user, params: {load: load, url: url}});
  }

  public updateUser(userId: number, user: IJsonUserEmpresa, load?: boolean): TServiceResponse<IJsonUserEmpresa> {
    return this._apiService.put<IJsonUserEmpresa>({
      url: `${this._path}/users/${userId}`,
      body: user,
      params: {
        load: load
      }
    });
  }

  public deleteUser(userId: number): TServiceResponse<void> {
    return this._apiService.delete({url: `${this._path}/users/${userId}`});
  }

  public usersPostERP(user: IJsonUserNew, erpName: string, nEmpresa: string, load?: boolean, url?: string): TServiceResponse<IJsonUserEmpresa> {
    return this._apiService.post<IJsonUserEmpresa>({
      url: `${this._path}/users/newusererp/erp/${erpName}/empresa/${nEmpresa}`,
      body: user,
      params: {load: load, url: url}
    });
  }

  public usersPostGabineteERP(user: IJsonUserNew, erpName: string, nEmpresaGabinete: string, nEmpresaCliente: string, load?: boolean, url?: string): TServiceResponse<IJsonUserEmpresa> {
    return this._apiService.post<IJsonUserEmpresa>({
      url: `${this._path}/users/newusererp/erp/${erpName}/gabinete/${nEmpresaGabinete}/cliente/${nEmpresaCliente}`,
      body: user,
      params: {load: load, url: url}
    });
  }

  public changePassword(userId: number, password: IJsonUserPassword): TServiceResponse<void> {
    return this._apiService.put<void, IJsonUserPassword>({url: `${this._path}/users/${userId}/password`, body: password});
  }

  public queryUsersWithAccesses(params?: IApiQueryParams): TServiceQueryResponse<IJsonUserEmpresa> {
    return this._apiService.query<IJsonUserEmpresa>({url: `${this._path}/userswithaccesses`, params: params});
  }

  public getUserWithAccesses(userId: number): TServiceResponse<IJsonUserEmpresaWithAccesses> {
    return this._apiService.get<IJsonUserEmpresaWithAccesses>({url: `${this._path}/userswithaccesses/${userId}`});
  }

  public newUserWithAccesses(newUser: IJsonUserNewWithAccesses, url?: string): TServiceResponse<IJsonUserEmpresaWithAccesses> {
    return this._apiService.post<IJsonUserEmpresaWithAccesses, IJsonUserNewWithAccesses>({url: `${this._path}/userswithaccesses`, body: newUser, params: {url: url}});
  }

  public updateUserWithAccesses(userId: number, user: IJsonUserEmpresaWithAccesses): TServiceResponse<IJsonUserEmpresaWithAccesses> {
    return this._apiService.put<IJsonUserEmpresaWithAccesses>({url: `${this._path}/userswithaccesses/${userId}`, body: user});
  }

  public queryRoles(params?: IApiQueryParams): TServiceQueryResponse<IJsonRole> {
    return this._apiService.query<IJsonRole>({url: `${this._path}/roles`, params: params});
  }

  public queryErps(params?: IApiQueryParams): TServiceQueryResponse<IJsonErp> {
    return this._apiService.query<IJsonErp>({url: `${this._path}/erps`, params: params});
  }

  public getErp(erp: string): TServiceResponse<IJsonErp> {
    return this._apiService.get<IJsonErp>({url: `${this._path}/erps/${erp}`});
  }

  public queryEmpresas(params?: IApiQueryParams, centralGestId?: number): TServiceQueryResponse<IJsonEmpresaErp> {
    return this._apiService.query<IJsonEmpresaErp>({url: `${this._path}/empresas`, params: {...params, centralGestId: centralGestId}});
  }

  public queryErpUsers(params?: IApiQueryParams, centralGestId?: number): TServiceQueryResponse<IJsonERPUtilizador> {
    return this._apiService.query<IJsonERPUtilizador>({url: `${this._path}/erpusers`, params: {...params, centralGestId: centralGestId}});
  }

  public getUserLicenca(userId: number): TServiceResponse<IJsonUserLicenca> {
    return this._apiService.get<IJsonUserLicenca>({url: `${this._path}/users/${userId}/licenca`});
  }

  public getUserAccesses(userId: number, empresaOuEmpresas?: string | Array<string>): TServiceResponse<Array<IJsonConfigERPUser>> {
    const empresas: Array<string> = isEmpty(empresaOuEmpresas) ? [] : isArray(empresaOuEmpresas) ? empresaOuEmpresas : [empresaOuEmpresas];
    const empresasAsParam: string = empresas.join(',');
    return this._apiService.get<Array<IJsonConfigERPUser>>({
      url: `${this._apiService.path.restapi}/manager/users/${userId}/accesses`,
      params: {empresas: empresasAsParam}
    });
  }

  public putEmpresaCliConfig(centralgestid: number, erpName: string, nEmpresaGabinete: string, nEmpresaCliente: string, load?: boolean, url?: string): TServiceResponse<IJsonEmpresaErp> {
    return this._apiService.put<IJsonEmpresaErp>({
      url: `${this._path}/empresas/newconfigempresacli/erp/${erpName}/gabinete/${nEmpresaGabinete}/cliente/${nEmpresaCliente}`,
      body: undefined,
      params: {
        centralgestid: centralgestid,
        load: load,
        url: url
      }
    });
  }

  public putEmpresaEmailConfig(centralgestid: number, erpName: string, nEmpresa: string, gDocEmailConfigs: Array<IJsonGDocEmailConfig>): TServiceResponse<IJsonBoolean> {
    return this._apiService.put<IJsonBoolean, Array<IJsonGDocEmailConfig>>({
      url: `${this._path}/empresas/newconfigemailempresa`,
      body: gDocEmailConfigs,
      params: {
        centralgestid: centralgestid,
        erpname: erpName,
        nempresa: nEmpresa
      }
    });
  }

  public mudarNomeEmpresa(model: IJsonErpUser): TServiceResponse<IJsonErpUser> {
    return this._apiService.put<IJsonErpUser>({
      url: `${this._path}/erpusers/erp/${model.name}`,
      body: model,
      params: {
        centralgestid: model.centralGestId,
        nempresa: model.nEmpresa
      }
    });
  }
}
