import {ENTITY_NAME_QPCATEGORIAS} from './qpcategorias.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonQPCategoria} from './jsonQPCategorias.entity.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_QPCATEGORIAS: IEntityDefinition<IJsonQPCategoria> = {
  name: ENTITY_NAME_QPCATEGORIAS,
  asModule: false,
  roles: [ROLE.RH],
  metadata: {
    keyName: 'codCategoria',
    fields: [
      {name: 'codCategoria', caption: 'qpcategorias.fields.codCategoria'},
      {name: 'nome', caption: 'qpcategorias.fields.nome'}
    ],
    order: 'codCategoria',
    searchFields: 'codCategoria,nome',
    filterFields: 'codCategoria,nome'
  },
  autocomplete: {
    rowTemplate: '{{codCategoria}} - {{nome}}',
    output: 'nome',
    searchFields: 'codCategoria,nome,codIrct'
  },
  actions: {
    new: false,
    detail: true,
    edit: false,
    search: true,
    delete: false,
    filter: true
  }
};
